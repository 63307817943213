export const COMPANY_VERTICAL_OPTIONS = ['Ecommerce', 'SaaS', 'Agency', 'Education', 'Other'];
export const COMPANY_SIZE_OPTIONS = ['1-20', '21-50', '51-100', '101-1000', '1001+'];
export const COMPANY_ROLE_OPTIONS = [
  'Marketing',
  'Sales',
  'Product',
  'Engineering',
  'Executive',
  'Support',
  'Operations',
  'HR',
  'Other',
];

export const COMPANY_USE_CASE_OPTIONS = ['Videos on website', 'Video messaging'];

export const COMPANY_MONTHLY_TRAFFIC_OPTIONS = [
  '0-10K',
  '10K-50K',
  '50K-100K',
  '100K-500K',
  '500k+',
];

export const ECOMMERCE = 'Ecommerce';
