import React from 'react';

const FilledBagIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.625 6.75V4.875C5.625 2.492 7.578.5 10 .5c2.383 0 4.375 1.992 4.375 4.375V6.75h2.5c1.016 0 1.875.86 1.875 1.875v8.125c0 2.07-1.68 3.75-3.75 3.75H5c-2.11 0-3.75-1.68-3.75-3.75V8.625c0-1.016.82-1.875 1.875-1.875h2.5Zm1.875 0h5V4.875c0-1.367-1.133-2.5-2.5-2.5a2.492 2.492 0 0 0-2.5 2.5V6.75ZM3.125 8.625v8.125A1.85 1.85 0 0 0 5 18.625h10c1.016 0 1.875-.82 1.875-1.875V8.625h-2.5v2.188c0 .546-.43.937-.938.937-.546 0-.937-.39-.937-.938V8.626h-5v2.188c0 .546-.43.937-.938.937-.546 0-.937-.39-.937-.938V8.626h-2.5Z"
        fill="#fff"
      />
    </svg>
  );
};

export default FilledBagIcon;
