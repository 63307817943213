import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import DefaultHeader from 'shared/react/components/basic/basic_select/DefaultHeader';
import DefaultItems from 'shared/react/components/basic/basic_select/DefaultItems';
import Menu from 'shared/react/components/basic/Menu';
import { BREAKPOINT_OPTIONS } from 'shared/react/constants/menu.constants';

function BasicSelect({
  items,
  value,
  onChange,
  inputBackground,
  inputPadding,
  inputBoxSizing,
  renderHeader,
  customHeight,
  loading,
  itemsPadding,
  error,
  borderRadius = 4,
  isDisabled,
  onKeyDown,
  setNextRef,
  showSearch = false,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const itemsRef = useRef();
  const menuRef = useRef();

  const handleClick = menuItem => {
    if (!menuItem.disabled) {
      setIsOpen(false);
      onChange && onChange(menuItem);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleSearch = e => {
    setSearchTerm(e.target.value);
  };

  const filterItems = useMemo(() => {
    return items?.filter(item => {
      if (item.name === value) {
        return false;
      }

      if (item.id && item.id === value?.id) {
        return false;
      }

      if (item === value) {
        return false;
      }

      if (searchTerm && !item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
        return false;
      }

      return true;
    });
  }, [items, value, searchTerm]);

  return (
    <BasicSelectContainer {...props} ref={menuRef}>
      <DefaultHeader
        inputBackground={inputBackground}
        inputPadding={inputPadding}
        inputBoxSizing={inputBoxSizing}
        renderHeader={renderHeader}
        loading={loading}
        toggleIsOpen={loading ? null : toggleIsOpen}
        itemsLength={filterItems.length}
        value={value}
        customHeight={customHeight}
        isOpen={isOpen}
        error={error}
        borderRadius={borderRadius}
        isDisabled={isDisabled}
        shouldHideBorder={props?.shouldHideHeaderBorder}
        setNextRef={setNextRef}
        onKeyDown={onKeyDown}
      />
      <Menu
        anchorEl={menuRef.current}
        open={isOpen}
        onClose={handleClose}
        breakpoint={BREAKPOINT_OPTIONS.moveToTop}
      >
        {showSearch && (
          <SearchInput
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearch}
          />
        )}
        <ItemsContainer
          width={menuRef?.current?.clientWidth}
          borderRadius={borderRadius}
          ref={itemsRef}
        >
          <DefaultItems
            onClick={handleClick}
            items={filterItems}
            customPadding={itemsPadding}
            fontColor={props?.itemFontColor}
            fontSize={props?.itemFontSize}
            itemWhiteSpace={props?.itemWhiteSpace}
            itemOverflow={props?.itemOverflow}
            setNextRef={setNextRef}
            onKeyDown={onKeyDown}
          />
        </ItemsContainer>
      </Menu>
    </BasicSelectContainer>
  );
}

export default BasicSelect;

const BasicSelectContainer = styled.div`
  width: 100%;
  position: relative;
`;

const ItemsContainer = styled.div`
  transition: 0.3s;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray3};
  width: ${({ width }) => width}px;
  max-height: 250px;
  overflow: auto;
  border-radius: 0 0 ${({ borderRadius }) => `${borderRadius}px ${borderRadius}px`};
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
`;
