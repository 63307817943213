import React, { useState } from 'react';
import styled from 'styled-components';
import { useApps } from 'app/src/context/AppsStore';
import { DASHBOARD_TABLET_MAX_WIDTH } from 'shared/react/utils/mediaQuery';
import WixEmbed from 'app/src/pages/modals/publish/embed/WixEmbed';
import EmbedPreview from 'app/src/pages/modals/publish/previews/EmbedPreview';
import SnackBar from 'app/src/basic_components/SnackBar';
import EmbedStepper from 'app/src/pages/modals/publish/embed/EmbedStepper';
import { FEATURE_WIDGET_CAROUSEL_SETTINGS } from 'shared/react/constants/features.constants';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { PUBLISH_METHODS } from 'shared/react/constants/tolstoy.constants';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import PublishEmbedCarouselPreview from 'app/src/pages/modals/publish/previews/PublishEmbedCarouselPreview';
import PublishPreviewContainer from 'app/src/pages/modals/publish/previews/PublishPreviewContainer';
import PublishStoriesPreview from 'app/src/pages/modals/publish/previews/PublishStoriesPreview';
import FeedEmbedWidgetsStepper from 'app/src/pages/modals/publish/embed/feed_embed_widgets/FeedEmbedWidgetsStepper';
import { PUBLISH_PREVIEW_SIZE } from 'app/src/constants/ui.constants';
import { Gap16HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextSmall } from 'shared/react/components/complex/Text';
import LinkIconV2 from 'shared/react/images/LinkIconV2';
import Utils from 'app/src/utils';
import BlockFreeUserPublishMessage from 'app/src/pages/modals/publish/BlockFreeUserPublishMessage';
import { useBilling } from 'app/src/hooks/useBillings';
import { PUBLISH_URL } from 'app/src/config/app.config';
import { publishMethodOptions } from 'app/src/types/entities';

function Embed({ project, widgetType }) {
  const [apps] = useApps();
  const url = project ? `${PUBLISH_URL}/${project.publishId}` : '';
  const [, { getFeatureEnabled }] = useFeatures();
  const isFeedCarouselEnabled = getFeatureEnabled(FEATURE_WIDGET_CAROUSEL_SETTINGS);
  const isFeed = project?.feed;
  const [successBar, setSuccessBar] = useState(false);
  const { hidePublishContent } = useBilling();

  const getHeight = () => {
    if (project?.verticalOrientation) {
      return '711px';
    }

    return '540px';
  };

  const getMaxWidth = () => {
    if (project?.verticalOrientation) {
      return '400px';
    }

    return '960px';
  };

  const getCode = () => {
    return `
      <div id="tolstoy-container" style="line-height:0;overflow:hidden;height:100%;width:100%;text-align:center">
        <iframe id="tolstoy" src="${url}?host"
                style="width:100%;height:${getHeight()};max-width:${getMaxWidth()}"
                scrolling="no" frameborder="0" allow="autoplay *; clipboard-write *;camera *; microphone *; encrypted-media *; fullscreen *; display-capture *;">
        </iframe>
        <script src="https://widget.gotolstoy.com/script.js" defer></script>
      </div>
    `;
  };

  const getPreview = () => {
    if (!isFeed || !isFeedCarouselEnabled) {
      return <EmbedPreview project={project} isVertical={!!project?.verticalOrientation} />;
    }

    const parsedWidgetSettings = JSON.parse(project.widgetSettings || '{}');
    if (widgetType === PUBLISH_METHODS[publishMethodOptions.carousel].widgetType) {
      return (
        <PublishPreviewContainer>
          <PublishEmbedCarouselPreview
            project={project}
            widgetSettings={parsedWidgetSettings}
            size={PUBLISH_PREVIEW_SIZE}
          />
        </PublishPreviewContainer>
      );
    }

    return (
      <PublishPreviewContainer>
        <PublishStoriesPreview
          project={project}
          {...parsedWidgetSettings}
          size={PUBLISH_PREVIEW_SIZE}
        />
      </PublishPreviewContainer>
    );
  };

  const getEmbedStepper = () => {
    if (hidePublishContent) {
      return <BlockFreeUserPublishMessage type={widgetType.text || 'Embed'} />;
    }

    if (isFeed && isFeedCarouselEnabled) {
      return <FeedEmbedWidgetsStepper project={project} widgetType={widgetType} />;
    }

    if (apps?.wix && project) {
      return (
        <WixEmbed
          getCode={getCode}
          publishId={project.publishId}
          appUrl={apps.wix?.appUrl}
          project={project}
        />
      );
    }

    return <EmbedStepper project={project} getCode={getCode} />;
  };

  const onGetHelpClick = () => {
    if (widgetType === PUBLISH_METHODS[publishMethodOptions.carousel].widgetType) {
      Utils.openInNewTab(
        'https://help.gotolstoy.com/en/articles/6510985-how-to-add-a-tolstoy-carousel-to-your-site'
      );
    }

    if (widgetType === PUBLISH_METHODS[publishMethodOptions.stories].widgetType) {
      Utils.openInNewTab(
        'https://help.gotolstoy.com/en/articles/6697057-how-to-use-the-stories-feature'
      );
    }
  };

  return (
    <LayoutRoot>
      <LeftContainer>{getEmbedStepper()}</LeftContainer>
      <RightContainer>
        {getPreview()}
        {isFeed && (
          <HelpAndTips onClick={onGetHelpClick}>
            <HelpAndTipsText>Get help & tips</HelpAndTipsText>
            <LinkIconV2 />
          </HelpAndTips>
        )}
      </RightContainer>
      <SnackBar
        text="The code copied to your clipboard!"
        open={successBar}
        setOpen={setSuccessBar}
        severity="success"
      />
    </LayoutRoot>
  );
}

export default Embed;

const LayoutRoot = styled(Gap16HorizontalFlex)`
  width: 100%;
  overflow-y: auto;
  padding: 4px 4px 4px 0;

  @media (${DASHBOARD_TABLET_MAX_WIDTH}) {
    flex-wrap: wrap;
    overflow: auto;
  }
`;

const LeftContainer = styled.div`
  max-height: 400px;
  flex-shrink: 0;
  max-width: 550px;
  width: 100%;

  @media (${DASHBOARD_TABLET_MAX_WIDTH}) {
    width: 100%;
    max-width: unset;
  }
`;

const HelpAndTips = styled(HorizontalFlex)`
  gap: 4px;
  cursor: pointer;
`;

const HelpAndTipsText = styled(TextSmall)`
  font-weight: 600;
`;

const RightContainer = styled(Gap16VerticalFlex)`
  align-items: center;
  width: 100%;
  position: sticky;
  top: 0;
  flex-grow: 0;
  @media (${DASHBOARD_TABLET_MAX_WIDTH}) {
    display: none;
  }
`;
