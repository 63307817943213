import React from 'react';

const TopDirectionIcon = () => {
  return (
    <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.656 1.965h9.188a.671.671 0 0 0 0-1.34H.656c-.383 0-.656.3-.656.684 0 .355.273.656.656.656ZM2.215 8.09l2.379-2.516v6.645c0 .383.273.656.656.656a.648.648 0 0 0 .656-.656V5.574L8.258 8.09c.137.137.3.191.492.191.137 0 .3-.054.438-.164a.653.653 0 0 0 .027-.93l-3.5-3.718a.66.66 0 0 0-.957 0l-3.5 3.719a.653.653 0 0 0 .027.93.678.678 0 0 0 .93-.028Z"
        fill="#50545E"
      />
    </svg>
  );
};

export default TopDirectionIcon;
