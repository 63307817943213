import TeamMembersTable from '../team_members/TeamMembersTable';
import {
  EMAIL_KEY,
  PAYMENT_ROLE_KEY,
  PAYMENT_ROLE_PRO_KEY,
  STATUS_ACCEPTED,
  STATUS_KEY,
} from 'app/src/constants/teamMembers.constants';
import StandardModal from './StandardModal';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAccount } from 'app/src/context/AccountStore';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextBody } from 'shared/react/components/complex/Text';
import Button from 'shared/react/components/complex/Button';
import Utils from '../../utils';
import { usePackages } from 'app/src/context/PackagesStore';
import { MODAL_Z_INDEX } from 'app/src/constants/ui.constants';
import { track } from '../../helpers/Tracker';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import { useBilling } from '../../hooks/useBillings';
import { prepareTeamMembersToUpdateMap } from 'app/src/utils/pricing.utils';
import { navigateToRoot } from 'app/src/utils/navigation.utils';
import { useHistory } from 'react-router-dom';
import { useApps } from 'app/src/context/AppsStore';
import { useUserActions } from 'app/src/context/userStore/UserStore';

const COLUMN_KEYS = [EMAIL_KEY, STATUS_KEY, PAYMENT_ROLE_KEY];

const getProTeamMember = teamMember => {
  return {
    ...teamMember,
    paymentRole: PAYMENT_ROLE_PRO_KEY,
  };
};

const getProTeamMembers = (teamMembers = []) => {
  return teamMembers.map(getProTeamMember);
};

export const getPackageSeatPrice = ({ details } = {}) => {
  const { seatPrice } = details || {};

  return seatPrice || 10;
};

export const getPackageFreeSeats = ({ details } = {}) => {
  const { freeSeats } = details || {};

  return freeSeats;
};

const PaymentRoleModal = ({ onClose, packageId }) => {
  const [, { upgradePackage }] = usePackages();
  const [{ teamMembers }, { updatePaymentRole }] = useAccount();
  const [{ shopify: isShopify }] = useApps();
  const { getUser } = useUserActions();
  const user = getUser();

  const history = useHistory();

  const { getPackageActionText, handleTrackOpenSubscriptionPage } = useBilling();
  const [loading, setLoading] = useState(false);
  const [freeTeamMembers, setFreeTeamMembers] = useState(new Set());

  const open = !!packageId && !isShopify;
  const proTeamMembers = getProTeamMembers(teamMembers);

  const onUpdateTeamMember = ({ teamMember, paymentRole }) => {
    if (paymentRole === PAYMENT_ROLE_PRO_KEY) {
      freeTeamMembers.delete(teamMember.id);
    } else {
      freeTeamMembers.add(teamMember.id);
    }
    setFreeTeamMembers(Utils.copySet(freeTeamMembers));
  };

  const getTeamMembersToUpdate = () => {
    if (isShopify) {
      return teamMembers.filter(({ userId }) => userId === user.owner);
    }
    return teamMembers.filter(({ id }) => !freeTeamMembers.has(id));
  };

  const onSubmit = async () => {
    setLoading(true);

    const teamMembersToUpdate = getTeamMembersToUpdate();
    const teamMembersToUpdateMap = prepareTeamMembersToUpdateMap(teamMembersToUpdate);
    const numOfPendingProTeamMembers =
      Object.keys(teamMembersToUpdateMap).length - getNumberOfProSeats();

    track('Update Team Members Payment Role', {
      numOfProTeamMembers: getNumberOfProSeats(),
      numOfPendingProTeamMembers,
    });

    await updatePaymentRole({ teamMembersToUpdateMap });

    const link = await upgradePackage(packageId);

    if (!link) {
      return navigateToRoot(history);
    }

    handleTrackOpenSubscriptionPage();

    setLoading(false);

    document.location.href = link;
  };

  const getNumberOfProSeats = () => {
    return teamMembers.filter(getIsProTeamMember).length;
  };

  const getIsProTeamMember = ({ id, status }) => {
    return !freeTeamMembers.has(id) && status === STATUS_ACCEPTED;
  };

  const submitDisabled = getNumberOfProSeats() === 0;

  useEffect(() => {
    if (open) {
      track('Payment Role Modal Opened');
    }
  }, [open]);

  useEffect(() => {
    if (packageId && isShopify) {
      onSubmit();
    }
  }, [packageId, isShopify]);

  return (
    <StandardModal onClose={onClose} open={open} zIndex={MODAL_Z_INDEX}>
      <StyledPaymentRoleModal>
        <PaymentRoleModalHeader>
          <PaymentRoleModalTitle>Select roles for your team member</PaymentRoleModalTitle>
          <PaymentRoleModalSubtitle>
            Select the teammates you wish to upgrade, to unlock premium features and enable
            unlimited usage.
          </PaymentRoleModalSubtitle>
        </PaymentRoleModalHeader>
        <ModalTeamMembersTable
          teamMembers={proTeamMembers}
          columnKeys={COLUMN_KEYS}
          onTeamMemberPaymentRoleUpdate={onUpdateTeamMember}
          viewOnly={true}
        />
        <PaymentRoleFooter>
          <PaymentRoleSubmitButton loading={loading} onClick={onSubmit} disabled={submitDisabled}>
            {getPackageActionText(packageId)}
          </PaymentRoleSubmitButton>
        </PaymentRoleFooter>
      </StyledPaymentRoleModal>
    </StandardModal>
  );
};

export default PaymentRoleModal;

const StyledPaymentRoleModal = styled(VerticalFlex)`
  width: 60vw;
  display: grid;
  row-gap: 48px;
  grid-template-rows: auto minmax(auto, 352px) auto;
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 100%;
  }
`;

const PaymentRoleModalHeader = styled(Gap8VerticalFlex)``;

const PaymentRoleModalTitle = styled(TextBody)`
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.black};
`;

const ModalTeamMembersTable = styled(TeamMembersTable)`
  font-size: 16px;
`;

const PaymentRoleModalSubtitle = styled(TextBody)`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.gray2};
`;

const PaymentRoleFooter = styled(Gap16VerticalFlex)`
  align-items: end;
`;

const PaymentRoleSubmitButton = styled(Button)`
  width: max-content;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
`;
