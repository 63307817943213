import React from 'react';
import styled from 'styled-components';
import Tooltip from 'app/src/basic_components/tooltip-v2/Tooltip';
import { DesignSizes } from 'app/src/types/design-system';
import { TopBarButtonProps } from './types/TopBar.types';

const TopBarActionButton = ({
  children,
  onClick,
  tooltipText,
  iconOnly,
  buttonComponent: ButtonComponent,
  hidden,
  disabled,
  isLoading,
  dataTestId,
}: TopBarButtonProps) => {
  if (hidden) {
    return null;
  }

  const button = (
    <ButtonComponent
      onClick={onClick}
      iconOnly={iconOnly}
      size={DesignSizes.SMALL}
      disabled={disabled}
      isLoading={isLoading}
      data-test-id={dataTestId}
    >
      {children}
    </ButtonComponent>
  );

  if (!tooltipText) {
    return button;
  }

  return (
    <StyledTooltip text={tooltipText} placement="bottom">
      {button}
    </StyledTooltip>
  );
};

const StyledTooltip = styled(Tooltip)`
  background: ${({ theme }) => theme.colors.white};

  & div {
    color: ${({ theme }) => theme.colors.black};
  }
`;

export default TopBarActionButton;
