import React from 'react';
import styled from 'styled-components';
import ChoosePublishMethod from '../publish/choose_publish_method/ChoosePublishMethod';

const PublishPhase = ({ project, live, setLive }) => {
  return (
    <LayoutRoot>
      <ChoosePublishMethod
        project={project}
        onPublishMethodChanged={() => {}}
        showOnlyWidget={true}
        live={live}
        onSetLive={() => setLive(!live)}
      />
    </LayoutRoot>
  );
};

export default PublishPhase;

const LayoutRoot = styled.div`
  width: 50%;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    box-sizing: border-box;
    margin-bottom: 8px;
  } ;
`;
