import React from 'react';
import { useTheme } from 'styled-components';

function GoToNextAnswerIcon({ fill, ...props }) {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="12"
      fill="none"
      viewBox="0 0 14 12"
      {...props}
    >
      <path
        fill={fill || theme.colors.ghostDark}
        d="M13.688 6.719l-5 5A.97.97 0 018 12a.99.99 0 01-.719-.281.964.964 0 010-1.406L10.563 7H1c-.563 0-1-.438-1-1 0-.531.438-1 1-1h9.563L7.28 1.719a.964.964 0 010-1.407.964.964 0 011.407 0l5 5a.964.964 0 010 1.407z"
      ></path>
    </svg>
  );
}

export default GoToNextAnswerIcon;
