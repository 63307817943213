import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import React from 'react';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import styled from 'styled-components';
import EditorHeader from '../shared/EditorHeader';
import EditorMainContainer from '../shared/EditorMainContainer';
import FeedMultipleProductEditor from './FeedMultipleProductEditor';

const FeedMultiProductLayoutEditor = () => {
  const {
    customizationSettings: {
      playerSettings: { multipleProductsSettings },
    },
    setPlayerSettingsProperty,
  } = useLookAndFeelContext();

  return (
    <LayoutRoot>
      <EditorHeader title="Multi-Product Page" />
      <EditorMainContainer>
        <FeedMultipleProductEditor
          multipleProductsSettings={multipleProductsSettings}
          setPlayerSettingsProperty={setPlayerSettingsProperty}
        />
      </EditorMainContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

export default FeedMultiProductLayoutEditor;
