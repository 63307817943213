import React from 'react';

const CaptionsIcon = () => (
  <svg width={14} height={11} viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.25.25H1.75C.906.25.25.93.25 1.75v7.5c0 .844.656 1.5 1.5 1.5h10.5c.82 0 1.5-.656 1.5-1.5v-7.5c0-.82-.68-1.5-1.5-1.5Zm.375 9c0 .21-.188.375-.375.375H1.75a.37.37 0 0 1-.375-.375v-7.5c0-.188.164-.375.375-.375h10.5c.188 0 .375.188.375.375v7.5ZM5.781 4.727c.211.21.586.21.797 0 .211-.235.211-.586 0-.797a2.235 2.235 0 0 0-3.187 0 2.232 2.232 0 0 0 0 3.164 2.215 2.215 0 0 0 1.593.656c.586 0 1.149-.21 1.594-.656.211-.211.211-.563 0-.797a.58.58 0 0 0-.797 0 1.118 1.118 0 0 1-1.593 0 1.103 1.103 0 0 1 0-1.57 1.118 1.118 0 0 1 1.593 0Zm4.5 0a.58.58 0 0 0 .797 0c.211-.235.211-.586 0-.797a2.235 2.235 0 0 0-3.187 0 2.232 2.232 0 0 0 0 3.164 2.215 2.215 0 0 0 1.593.656c.586 0 1.149-.21 1.594-.656.211-.211.211-.563 0-.797a.58.58 0 0 0-.797 0 1.118 1.118 0 0 1-1.594 0 1.103 1.103 0 0 1 0-1.57 1.118 1.118 0 0 1 1.594 0Z"
      fill="#50545E"
    />
  </svg>
);

export default CaptionsIcon;
