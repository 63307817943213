import RedirectToLink from 'app/src/images/RedirectToLink';
import React from 'react';
import styled from 'styled-components';
import { TextSmall } from 'shared/react/components/complex/Text';
import { useBilling } from '../../hooks/useBillings';

const PreviewOnSampleSitePricing = ({ onClick }) => {
  const { isFreePackage } = useBilling();
  if (isFreePackage) {
    return null;
  }

  return (
    <StyledPreviewOnSampleSiteContainer>
      <LinkSpan onClick={onClick}>
        Preview on a sample site
        <RedirectIconContainer>
          <RedirectToLink />
        </RedirectIconContainer>
      </LinkSpan>
      Dont forget to save before!
    </StyledPreviewOnSampleSiteContainer>
  );
};

export default PreviewOnSampleSitePricing;

const StyledPreviewOnSampleSiteContainer = styled(TextSmall)`
  align-self: end;
  justify-self: end;
  align-items: end;
  font-style: italic;
  display: flex; ;
`;

const RedirectIconContainer = styled.span`
  margin: 0 8px 0 4px;
`;

const LinkSpan = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  transition: 0.3s;
  cursor: pointer;
  font-style: normal;
  display: flex;

  &:hover {
    color: ${({ theme }) => theme.colors.primaryHover};
  }
`;
