import React from 'react';

const FilledReportIcon = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.4609 0.5C19.8672 0.5 20.9609 1.63281 20.9609 3V14.1719C20.9609 15.5 19.8281 16.6328 18.4609 16.6328H12.8359L7.95312 20.3047C7.64062 20.5 7.21094 20.3047 7.21094 19.9141V16.6719H3.46094C2.05469 16.6719 0.960938 15.5781 0.960938 14.2109V3C0.960938 1.63281 2.05469 0.5 3.46094 0.5H18.4609ZM19.75 14.25V3C19.75 2.33594 19.1641 1.75 18.5 1.75H3.5C2.79688 1.75 2.25 2.33594 2.25 3V14.25C2.25 14.9531 2.79688 15.5 3.5 15.5H7.875C8.1875 15.5 8.5 15.8125 8.5 16.125V18.4688L12.0938 15.7734C12.3281 15.6172 12.5625 15.5 12.8359 15.5H18.5C19.1641 15.5 19.75 14.9531 19.75 14.25ZM10.9609 10.5C10.6484 10.5 10.375 10.2266 10.375 9.875V4.25C10.375 3.9375 10.6484 3.625 11 3.625C11.3125 3.625 11.625 3.9375 11.625 4.25V9.875C11.625 10.2266 11.3125 10.5 10.9609 10.5ZM11 11.4375C11.5078 11.4375 11.9375 11.8672 11.9375 12.375C11.9375 12.9219 11.5078 13.3125 11 13.3125C10.4531 13.3125 10.0625 12.9219 10.0625 12.375C10.0625 11.8672 10.4531 11.4375 11 11.4375Z"
        fill="#E2506D"
      />
    </svg>
  );
};

export default FilledReportIcon;
