import { useState } from 'react';
import { useUserActions } from 'src/context/userStore/UserStore';
import { project as Project } from 'app/src/types/entities';
import { useToggleProjectLive } from 'src/hooks/projects';
import { ARCHIVE_FOLDER } from 'src/constants/folders';

const useSetProjectLive = (project: Project) => {
  const [isLoading, setIsLoading] = useState(false);
  const { isProjectLive } = useUserActions();
  const toggleLive = useToggleProjectLive();
  const isLive = isProjectLive(project);

  const toggleWidget = async (newState?: boolean) => {
    if (newState === isLive) {
      return;
    }

    setIsLoading(true);

    if (typeof newState !== 'boolean') {
      newState = undefined;
    }

    const isArchived = project.folder === ARCHIVE_FOLDER.id;
    const nextIsLive = newState === undefined ? !isLive : newState;
    const shouldMoveFromArchive = isArchived && nextIsLive;
    const newProject = shouldMoveFromArchive ? { ...project, folder: null } : project;

    await toggleLive(newProject, newState);

    setIsLoading(false);
  };

  return { toggleWidget, isLoading };
};

export default useSetProjectLive;
