import React from 'react';

const TolstoyIcon = props => (
  <svg
    className="tolstoy"
    width={57}
    height={34}
    viewBox="0 0 57 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.326 7.307h.052c7.111 0 12.878 5.826 12.878 13.013 0 7.188-5.765 13.013-12.878 13.013-6.846 0-12.443-5.397-12.854-12.21H.5v-3.427c0-1.105.137-2.178.394-3.203C2.24 8.05 6.926 2.697 13.326.667v6.64Zm30.348 19.386h-.052c-7.113 0-12.878-5.828-12.878-13.013 0-7.188 5.765-13.013 12.878-13.013 6.845 0 12.443 5.397 12.854 12.209h.024v3.428c0 1.105-.138 2.178-.395 3.201-1.346 6.443-6.03 11.797-12.43 13.828v-6.64Z"
      fill="#50545E"
    />
  </svg>
);

export default TolstoyIcon;
