import React from 'react';

const ProductsAnswersIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#D3F8EC" />
      <path
        d="M16.5 9.25h-1.688v-.938A2.82 2.82 0 0 0 12 5.5a2.806 2.806 0 0 0-2.813 2.813v.937H7.5a.755.755 0 0 0-.75.75v5.625A1.85 1.85 0 0 0 8.625 17.5h6.75c1.031 0 1.875-.82 1.875-1.875V10a.771.771 0 0 0-.75-.75Zm-6.188-.938c0-.914.75-1.687 1.688-1.687.914 0 1.688.773 1.688 1.688v.937h-3.376v-.938Zm-.562 3a.542.542 0 0 1-.563-.562c0-.305.235-.563.563-.563a.57.57 0 0 1 .563.563.555.555 0 0 1-.563.563Zm4.5 0a.542.542 0 0 1-.563-.562c0-.305.235-.563.563-.563a.57.57 0 0 1 .563.563.555.555 0 0 1-.563.563Z"
        fill="#01BE81"
      />
    </svg>
  );
};

export default ProductsAnswersIcon;
