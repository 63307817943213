import React, { useEffect, useState } from 'react';
import Portal from 'shared/react/components/basic/Portal';
import { useDrawer } from 'src/context/ui_store/DrawerStore';
import styled, { RuleSet, css } from 'styled-components';
import GlobalDrawerContent from './GlobalDrawerContent';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import XIcon from 'shared/react/images/XIcon';
import { DRAWER_ANIMATION_DURATION } from 'app/src/constants/drawer.constants';

const GlobalDrawer = () => {
  const [{ currentDrawer, drawerStyles = {} }, { clearDrawerState }] = useDrawer();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleCloseDrawerClick = () => {
    setIsDrawerOpen(false);

    setTimeout(() => {
      clearDrawerState();
    }, DRAWER_ANIMATION_DURATION);
  };

  useEffect(() => {
    if (!currentDrawer) {
      setIsDrawerOpen(false);
      return;
    }

    setIsDrawerOpen(true);
  }, [currentDrawer]);

  const drawerStylesCss = css(drawerStyles);
  return (
    <Portal>
      <LayoutRoot isDrawerOpen={isDrawerOpen} drawerStylesCss={drawerStylesCss}>
        <Content isCurrentDrawer={!!currentDrawer}>
          <CloseButtonContainer onClick={handleCloseDrawerClick}>
            <StyledXIcon />
          </CloseButtonContainer>
          <GlobalDrawerContent />
        </Content>
      </LayoutRoot>
    </Portal>
  );
};

const LayoutRoot = styled.div<{ isDrawerOpen: boolean; drawerStylesCss: RuleSet<object> }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: -8px 8px 12px 0px rgba(0, 0, 0, 0.05);
  border-left: 1px solid ${({ theme }) => theme.colors.neutralLighter};
  transition: transform ${DRAWER_ANIMATION_DURATION}ms;
  transform: ${({ isDrawerOpen }) => (isDrawerOpen ? 'translateX(0)' : 'translateX(100%)')};
  ${({ drawerStylesCss }) => drawerStylesCss};
`;

const Content = styled(VerticalFlex)<{ isCurrentDrawer: boolean }>`
  position: relative;
  display: ${({ isCurrentDrawer }) => (isCurrentDrawer ? '' : 'none')};
  height: 100%;
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 16px;
  z-index: 1;
  cursor: pointer;
`;

const StyledXIcon = styled(XIcon)`
  width: 16px;
  height: 16px;

  path {
    fill: ${({ theme }) => theme.colors.neutralGray};
  }
`;

export default GlobalDrawer;
