import { useState } from 'react';
import { vodAsset as VodAsset } from 'app/src/types/entities';
import { useDebounce } from 'src/hooks/useDebounce';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import {
  FEATURE_DYNAMIC_HIDE_VOD_ASSET,
  FEATURE_ORDER_FEED_VIDEOS,
} from 'shared/react/constants/features.constants';
import { useDynamicConditionsContext } from '../components/dynamic-conditions/context/DynamicConditionsContext';
import { VodAssetPayload } from '../types';
import { useDynamicTypeVideos } from './videos/useDynamicTypeVideos';
import { useDynamicTypeProducts } from './products/useDynamicTypeProducts';
import { useDynamicTypeActions, UseDynamicActions } from './useDynamicTypeActions';
import { DynamicRuleTypes } from '../components/dynamic-conditions/constants/dynamicConditions.constants';

const DEFAULT_SEARCH_DEBOUNCE_TIME = 600;

export type UseDynamicTypeContentReturnType = UseDynamicActions & {
  isLoading: boolean;
  isSearchDisabled: boolean;
  products: any[];
  videos: VodAssetPayload[];
  vodAssets?: VodAsset[];
  searchTerm: string;
  canHide: boolean;
  canLock: boolean;
  canOrder: boolean;
  canAddManualVideo: boolean;
  canRemoveManualVideo: boolean;
  isDerivedProject: boolean;
  setSearchTerm: (value: string) => void;
  moveVideo: (params: { index: number; draggingFromIndex: number; productId?: string }) => void;
  removeManualVideo: (params: { videoId: string; externalProductId: string }) => Promise<any>;
  addManualVideo: (params: {
    videoId: string;
    provider?: string;
    productId?: string;
    externalProductId?: string;
  }) => Promise<any>;
};

const useDynamicTypeContent = ({
  project,
  isDerivedProject = false,
  isProjectProductPage = false,
}): UseDynamicTypeContentReturnType => {
  const { getFeatureEnabled } = useFeatureActions();
  const { videoRules, rules } = useDynamicConditionsContext();
  const canHide =
    videoRules && getFeatureEnabled(FEATURE_DYNAMIC_HIDE_VOD_ASSET) && !isDerivedProject;
  const canOrder = getFeatureEnabled(FEATURE_ORDER_FEED_VIDEOS) && !isDerivedProject;
  const canLock = !isDerivedProject;
  const canAddManualVideo = !isDerivedProject;
  const canRemoveManualVideo = !isDerivedProject;
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, DEFAULT_SEARCH_DEBOUNCE_TIME);
  const allowNonTaggedVideos = rules?.some(
    rule => rule.type === DynamicRuleTypes.allowNonTaggedVideos
  );
  const {
    videos,
    filteredVodAssets,
    moveNonPDPVideo,
    addManualNonPDPVideo,
    removeManualNonPDPVideo,
  } = useDynamicTypeVideos({ project, isProjectProductPage, searchTerm: debouncedSearchValue });
  const {
    isLoading,
    isSearchDisabled: isProductSearchDisabled,
    products,
    movePDPVideo,
    addManualPDPVideo,
    removeManualPDPVideo,
  } = useDynamicTypeProducts({
    videos,
    project,
    isProjectProductPage,
    allowNonTaggedVideos,
    searchTerm: debouncedSearchValue,
    isDerivedProject,
  });
  const {
    hideVideo,
    showVideo,
    lockVideo,
    unlockVideo,
    loadMore,
    openProductsModal,
    openVideoPickerModal,
  } = useDynamicTypeActions();
  const isSearchDisabled = isProjectProductPage && isProductSearchDisabled;

  const moveVideo = ({ index, draggingFromIndex, productId }) => {
    if (!canOrder) {
      return;
    }

    if (isProjectProductPage) {
      movePDPVideo({ index, draggingFromIndex, productId });
    } else {
      moveNonPDPVideo({ index, draggingFromIndex });
    }
  };

  const addManualVideo = async ({ videoId, provider, productId, externalProductId }) => {
    if (isProjectProductPage) {
      return addManualPDPVideo({ videoId, provider, productId, externalProductId });
    }

    return addManualNonPDPVideo(videoId);
  };

  const removeManualVideo = async ({ videoId, externalProductId }) => {
    if (isProjectProductPage) {
      return removeManualPDPVideo({ videoId, externalProductId });
    }

    return removeManualNonPDPVideo(videoId);
  };

  return {
    isLoading,
    isSearchDisabled,
    isDerivedProject,
    products,
    videos,
    searchTerm: searchValue,
    canHide,
    canLock,
    canOrder,
    canAddManualVideo,
    canRemoveManualVideo,
    loadMore,
    hideVideo,
    showVideo,
    lockVideo,
    unlockVideo,
    moveVideo,
    addManualVideo,
    removeManualVideo,
    openProductsModal,
    openVideoPickerModal,
    setSearchTerm: setSearchValue,
    vodAssets: filteredVodAssets,
  };
};

export default useDynamicTypeContent;
