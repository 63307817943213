import styled from 'styled-components';
import React, { useState } from 'react';
import { DangerButton, PrimaryButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import ThinVIcon from 'shared/react/images/ThinVIcon';
import InputWithIcon from 'app/src/basic_components/input/InputWithIcon';
import { useSynthesia } from 'app/src/context/SynthesiaStore';
import { useApps } from 'app/src/context/AppsStore';
import useIntegrationsVideos from 'app/src/hooks/useIntegrationsVideos';
import { useSnackBarActions } from 'app/src/context/ui_store/SnackBarStore';

type Props = {
  isRedButton?: boolean;
};

const SynthesiaEmptyStateButton = ({ isRedButton = false }: Props) => {
  const [isInputShown, setIsInputShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [, { fetchSynthesia }] = useSynthesia();
  const { synthesia, handleSynthesiaConnect } = useIntegrationsVideos();
  const { setErrorSnackbar } = useSnackBarActions();

  const toggleIsShown = () => {
    setIsInputShown(!isInputShown);
  };

  const handleConnect = async () => {
    if (isLoading || !inputValue) {
      return;
    }

    setIsLoading(true);
    await handleSaveSynthesiaApiKey(inputValue);
    setIsLoading(false);
  };

  const handleSaveSynthesiaApiKey = async apiKey => {
    const connected = await fetchSynthesia(apiKey);

    if (connected) {
      await handleSynthesiaConnect(apiKey);
      return;
    }

    setErrorSnackbar('There has been an error with this key');
  };

  if (synthesia && !isLoading) {
    return null;
  }

  const CurrentButton = isRedButton ? DangerButton : PrimaryButton;

  if (!isInputShown) {
    return <CurrentButton onClick={toggleIsShown}>Connect</CurrentButton>;
  }

  return (
    <LayoutRoot>
      <Input
        value={inputValue}
        placeholder="Synthesia API key"
        onChange={e => setInputValue(e.target.value)}
      />
      <CurrentButton
        disabled={isInputShown && !inputValue}
        isLoading={isLoading}
        onClick={handleConnect}
      >
        <ThinVIcon />
      </CurrentButton>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlex)`
  path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const Input = styled(InputWithIcon)`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.neutralGray};
  border-radius: 4px;

  height: 32px;
`;

export default SynthesiaEmptyStateButton;
