import React from 'react';
import StickyTopBar from '../../dashboard/components/top-bar/StickyTopBar';
import TeamMembersTitle from './TeamMembersTitle';
import TeamMembersHeaderButtons from './TeamMembersHeaderButtons';

type Props = {
  teamMembersCount: string;
  openInviteButtonClick: () => void;
  inviteDisabled: boolean;
};

const TeamMembersTopBar = ({ teamMembersCount, openInviteButtonClick, inviteDisabled }: Props) => {
  const dirtyTitle = `Team Members (${teamMembersCount})`;

  const leftContent = <TeamMembersTitle teamMembersCount={teamMembersCount} />;

  const rightContent = (
    <TeamMembersHeaderButtons
      openInviteButtonClick={openInviteButtonClick}
      inviteDisabled={inviteDisabled}
    />
  );

  return (
    <StickyTopBar leftContent={leftContent} rightContent={rightContent} dirtyTitle={dirtyTitle} />
  );
};

export default TeamMembersTopBar;
