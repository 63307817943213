import React, { useEffect } from 'react';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { track } from '../../helpers/Tracker';
import { useBilling } from 'app/src/hooks/useBillings';
import BillingPackagesRouter from 'app/src/complex_components/billings/BillingPackagesV2/BillingPackagesRouter';
import {
  PlanAddons,
  PlansUsage,
  PlansPageHeader,
} from 'src/complex_components/plans'
import StickyTopBar from '../dashboard/components/top-bar/StickyTopBar';

const PlansPage = () => {
  const { isFreePackage, isShopifySubscription } = useBilling();

  useEffect(() => {
    track('Plans Page Loaded');
  }, []);

  return (
    <LayoutRoot>
      <StickyTopBar leftContent="Plans" />
      <StyledPlansPage isPlansHeaderShown={!isShopifySubscription || isFreePackage}>
        <PlansPageHeader isShopify={isShopifySubscription} />
        <PlansUsage />
        <BillingPackagesRouter />
        <PlanAddons />
      </StyledPlansPage>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

const StyledPlansPage = styled(VerticalFlex)`
  padding: 24px;
  gap: ${({ isPlansHeaderShown }) => (isPlansHeaderShown ? '28px' : '')};
  height: 100%;
  width: 100%;
  flex-grow: 1;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    flex-direction: column;
    display: flex;
    min-width: unset;
    padding: 0;
  }
`;

export default PlansPage;
