import React, { useEffect, useRef, useState } from 'react';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import SharedUtils from 'shared/react/utils/utils';
import styled from 'styled-components';
import ResetToDefaultInputWrapper from 'app/src/complex_components/ResetToDefaultInputWrapper';
import FontMenuToggle from '../FontMenuToggle';
import FontsMenu from './FontsMenu';

function BrandingFonts({
  font = {},
  isFontUploadEnabled = false,
  loading,
  disabled,
  weight,
  onChange = () => {},
  onResetFont,
}) {
  const { family, sources } = font || {};
  const openButtonRef = useRef(null);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [menuTopPosition, setMenuTopPosition] = useState(0);

  useEffect(() => {
    const { height } = openButtonRef?.current?.getBoundingClientRect() || {};

    setMenuTopPosition(height);
  }, [openButtonRef]);

  useEffect(() => {
    if (family && sources) {
      SharedUtils.loadFont(sources, family);
    }
  }, [family]);

  const handleToggleMenuClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  return (
    <LayoutRoot>
      <ResetToDefaultInputWrapper onClick={onResetFont} disabled={disabled}>
        <FontMenuToggle
          value={family || 'System default'}
          ref={openButtonRef}
          isOpen={isMenuOpen}
          onClick={handleToggleMenuClick}
          disabled={disabled}
        />
      </ResetToDefaultInputWrapper>

      {isMenuOpen && (
        <FontsMenu
          selectedFontFamily={family}
          loading={loading}
          topPosition={menuTopPosition}
          openButtonRef={openButtonRef}
          isFontUploadEnabled={isFontUploadEnabled}
          onChange={onChange}
          onClose={handleCloseMenu}
          weight={weight}
          isMenuOpen={isMenuOpen}
        />
      )}
    </LayoutRoot>
  );
}

const LayoutRoot = styled(VerticalFlex)`
  position: relative;
`;

export default BrandingFonts;
