import React from 'react';

const FuegoIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 77 77" width="19px" height="19px">
      <path d="M40 38C40 49.4772 35.8109 71.437 50.0039 76.2971C52.8455 77.2701 56.0439 77.0038 59 77C62.938 76.9949 66.6787 77.0937 69.9568 74.5664C79.1377 67.488 76 52.1969 76 42C76 32.6343 78.9964 16.9897 73.3472 9.01543C68.5391 2.22841 61.5557 2.00049 54 2L21 2C14.6912 2.00243 8.85064 2.19147 4.21373 7.10802C-6.49156 18.459 1.73759 37.7891 17 37.9961C24.6637 38.1001 32.3355 38 40 38z" />

      <path
        style={{ fill: '#fff' }}
        d="M40 29C40 22.6934 39.8144 16.9968 42 11L23 11C19.3888 11.0058 15.2664 10.7113 12.1335 12.858C6.89437 16.448 7.66071 24.7712 13.108 27.821C19.6635 31.4912 32.6218 29 40 29M49 29C52.5778 29 56.4697 29.419 59.9992 28.7716C76.7899 25.6919 61.0805 1.53194 50.858 14.5332C49.8343 15.8352 49.4179 17.4049 49.189 19.0147C48.7256 22.2745 49 25.7144 49 29M0 28L0 77L50 77C47.073 74.1278 43.9006 71.7512 42 67.9961C37.7756 59.6501 40 47.1218 40 38C31.3987 38 19.0491 40.2506 11 36.9907C6.50468 35.1702 3.56408 31.08 0 28M67 36C61.3485 38.7197 55.1485 38 49 38C49 45.1008 46.3595 58.92 50.6034 64.8912C54.5692 70.4712 63.5029 70.0796 66.6821 63.956C69.7341 58.0775 69.5184 42.0016 67 36M75 67C73.0031 70.848 70.5282 73.4928 67 76C69.8897 77.2129 72.8741 76.9983 76 77C75.9996 73.5856 76.3274 70.1633 75 67z"
      />
    </svg>
  );
};

export default FuegoIcon;
