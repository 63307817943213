import React from 'react';
import DynamicProductsTable from '../dynamic-pdp/DynamicProductsTable';
import { useRules } from 'app/src/context/RulesStore';
import { project as Project, rule as RuleType } from 'app/src/types/entities';
import DynamicDiscover from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-discover/DynamicDiscover';
import DynamicNonPDP from '../dynamic-non-pdp/DynamicNonPDPV2';
import { VodAssetPayload } from '../../types';
import { vodAsset as VodAsset } from 'app/src/types/entities';

type Props = {
  project: Project;
  rules: RuleType[];
  isProjectProductPage: boolean;
  videoRules: RuleType[];
  containerRef: React.RefObject<HTMLDivElement>;
  setVideoRules: (rules: RuleType[]) => void;
  loadMoreProducts?: ({ startIndex, stopIndex }: { startIndex: number; stopIndex: number }) => void;
  products: any;
  videos: VodAssetPayload[];
  vodAssets?: VodAsset[];
  canHide?: boolean;
  canOrder?: boolean;
  canLock?: boolean;
  canAddManualVideo?: boolean;
  canRemoveManualVideo?: boolean;
  isLoading?: boolean;
  hideVideo: (videoId: string, productId?: string) => void;
  showVideo: (videoId: string, order: number, productId?: string) => void;
  lockVideo: (videoId: string, order: number, productId?: string) => void;
  unlockVideo: (videoId: string, order: number, productId?: string) => void;
  moveVideo: (params: { index: number; draggingFromIndex: number; productId?: string }) => void;
  removeManualVideo: (params: { videoId: string; externalProductId: string }) => Promise<any>;
  addManualVideo: (params: {
    videoId: string;
    provider?: string;
    productId?: string;
    externalProductId?: string;
  }) => Promise<any>;
  openProductsModal: ({ video, analyticsData }) => void;
  openVideoPickerModal: ({ onVideoPicked, onModalClosed }: any) => void;
};

const DynamicContent = ({
  project,
  videoRules,
  rules,
  products,
  videos,
  vodAssets,
  isProjectProductPage,
  containerRef,
  canHide = false,
  canOrder = false,
  canLock = true,
  canAddManualVideo = true,
  canRemoveManualVideo = true,
  isLoading = false,
  setVideoRules,
  loadMoreProducts,
  hideVideo,
  showVideo,
  lockVideo,
  unlockVideo,
  moveVideo,
  removeManualVideo,
  addManualVideo,
  openVideoPickerModal,
  openProductsModal,
}: Props) => {
  const [{ loading }] = useRules();

  if (project.discover) {
    return (
      <DynamicDiscover
        rules={rules}
        loading={loading}
        project={project}
        videoRules={videoRules}
        setVideoRules={setVideoRules}
        vodAssets={vodAssets}
      />
    );
  }

  if (isProjectProductPage) {
    return (
      <DynamicProductsTable
        products={products}
        project={project}
        containerRef={containerRef}
        canHide={canHide}
        canLock={canLock}
        canAddManualVideo={canAddManualVideo}
        canRemoveManualVideo={canRemoveManualVideo}
        isLoading={isLoading}
        loadMoreProducts={loadMoreProducts}
        hideVideo={hideVideo}
        showVideo={showVideo}
        lockVideo={lockVideo}
        unlockVideo={unlockVideo}
        moveVideo={moveVideo}
        addManualVideo={addManualVideo}
        removeManualVideo={removeManualVideo}
        openVideoPickerModal={openVideoPickerModal}
        openProductsModal={openProductsModal}
      />
    );
  }

  return (
    <DynamicNonPDP
      videos={videos}
      canHide={canHide}
      canOrder={canOrder}
      canLock={canLock}
      canAddManualVideo={canAddManualVideo}
      canRemoveManualVideo={canRemoveManualVideo}
      hideVideo={hideVideo}
      showVideo={showVideo}
      lockVideo={lockVideo}
      unlockVideo={unlockVideo}
      moveVideo={moveVideo}
      isLoading={isLoading}
      addManualVideo={addManualVideo}
      removeManualVideo={removeManualVideo}
      openVideoPickerModal={openVideoPickerModal}
      openProductsModal={openProductsModal}
    />
  );
};

export default DynamicContent;
