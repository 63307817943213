import { useBilling } from 'app/src/hooks/useBillings';
import { useFeatures } from 'app/src/context/FeaturesStore';
import {
  FEATURE_APP_LIMITS,
  FEATURE_QUIZ_LIMITS,
  FEATURE_QUIZ_V2,
} from 'shared/react/constants/features.constants';
import { useMemo } from 'react';
import { useProjects } from 'app/src/context/ProjectsStore';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { CREATION_FLOW_MODAL_KEY } from 'app/src/constants/modals.constants';
import Utils from 'app/src/utils';
import { PRODUCTS_RESULT } from 'app/src/constants/editStep.constants';

const useAddStep = project => {
  const steps = project?.steps?.items || [];
  const [, { getSettingsByKey }] = useFeatures();
  const [, { setCurrentModal, setModalProps }] = useModal();
  const { maxNumberOfPartsInTolstoy = 30 } = getSettingsByKey(FEATURE_APP_LIMITS);
  const productsStepLimit = getSettingsByKey(FEATURE_QUIZ_V2)?.productStepLimit;
  const { maxNumberOfPartsInTolstoy: maxQuizNumberOfPartsInTolstoy = 5, isEnabled = true } =
    getSettingsByKey(FEATURE_QUIZ_LIMITS);
  const [, { getQuizOptionDisabled }] = useProjects();
  const { isFreePackage, isLiteRolePayingSelfServicePackage } = useBilling();

  const isQuizOptionDisabled = useMemo(() => {
    return (
      !Utils.isQuiz(project?.tolstoyType, 2) &&
      getQuizOptionDisabled(project, isEnabled, maxQuizNumberOfPartsInTolstoy - 1, 100)
    );
  }, [project]);

  const isQuizProductsStepDisabled = () => {
    const filteredSteps = steps.filter(step => step.type === PRODUCTS_RESULT);
    return filteredSteps.length >= productsStepLimit;
  };

  const isNormalStepsDisabled = () => {
    const filteredSteps = steps.filter(step => step.type !== PRODUCTS_RESULT);
    return filteredSteps.length >= maxNumberOfPartsInTolstoy;
  };

  const getMaxNumberOfParts = (selectedProject = project) => {
    const isQuiz = Utils.isQuiz(selectedProject?.tolstoyType);

    if (isQuiz) {
      return Utils.isQuiz(project?.tolstoyType, 2)
        ? maxNumberOfPartsInTolstoy
        : maxQuizNumberOfPartsInTolstoy;
    }

    return maxNumberOfPartsInTolstoy;
  };

  const isDisabled = isNormalStepsDisabled();

  const isLocked = isDisabled || isQuizOptionDisabled;

  const shouldHide = !isFreePackage && !isLiteRolePayingSelfServicePackage && isDisabled;

  const openCreationFlowModal = (modalProps = {}) => {
    setModalProps(modalProps);
    setCurrentModal(CREATION_FLOW_MODAL_KEY);
  };

  return {
    isLocked,
    shouldHide,
    isQuizOptionDisabled,
    maxNumberOfPartsInTolstoy,
    getMaxNumberOfParts,
    openCreationFlowModal,
    quizProductsStepLimitPassed: isQuizProductsStepDisabled(),
  };
};

export default useAddStep;
