import React, { useRef } from 'react';
import styled, { useTheme } from 'styled-components';
import VIcon from 'app/src/images/VIcon';
import SettingsIcon from 'app/src/images/SettingsIcon';
import useOnClickOutside from 'shared/react/hooks/useOutsideClick';
import Routes from 'app/src/helpers/Routes';
import { useHistory } from 'react-router-dom';
import { postRequest } from 'app/src/helpers/API';
import { useInAppNotifications } from 'app/src/context/InAppNotificationsStore';
import XThickIcon from 'app/src/images/XThickIcon';
import OptionMenuItem from 'app/src/complex_components/notifications/OptionMenuItem';

function OptionMenu({ teamMemberId, onToggleMenu, onClosePanel }) {
  const theme = useTheme();
  const history = useHistory();
  const containerRef = useRef({});
  const [, { setAllNotificationsRead, clearState }] = useInAppNotifications();

  const handleMarkAllAsReadClick = () => {
    onToggleMenu();
    setAllNotificationsRead();
    postRequest('in-app-notifications', '/mark-all-notifications-read', {
      body: {
        teamMemberId,
      },
    });
  };

  const handleClearNotifications = () => {
    onToggleMenu();
    clearState();
    postRequest('in-app-notifications', '/clear-notifications', {
      body: {
        teamMemberId,
      },
    });
  };

  const handleNotificationSettingsClick = () => {
    onToggleMenu();
    onClosePanel();
    history.push(Routes.getTeamMemberNotificationsRoute());
  };

  const vIcon = <VIcon fill={theme.colors.black} width="12px" />;
  const clearIcon = <XThickIcon fill={theme.colors.black} width="12px" />;
  const settingsIcon = <SettingsIcon fill={theme.colors.black} width="12px" />;

  useOnClickOutside([containerRef], onToggleMenu);

  return (
    <LayoutRoot ref={containerRef}>
      <OptionMenuItem icon={clearIcon} onClick={handleClearNotifications}>
        Clear notifications
      </OptionMenuItem>
      <OptionMenuItem icon={vIcon} onClick={handleMarkAllAsReadClick}>
        Mark all as read
      </OptionMenuItem>
      <OptionMenuItem icon={settingsIcon} onClick={handleNotificationSettingsClick}>
        Notification settings
      </OptionMenuItem>
    </LayoutRoot>
  );
}

export default OptionMenu;

const LayoutRoot = styled.div`
  grid-row: 2;
  grid-column: 1;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
  display: flex;
  flex-direction: column;
  background: white;
  height: fit-content;
  margin: 0 8px;
  padding: 8px;
  z-index: 1;
`;
