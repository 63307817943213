import React from 'react';
import styled, { useTheme } from 'styled-components';
import { TextSmall } from 'shared/react/components/complex/Text';
import CheckIcon from 'app/src/images/Check';
import NewIcon from 'app/src/images/New';
import RepliedIcon from 'app/src/images/Replied';
import ArchiveIcon from 'app/src/images/Archive';
import {
  STATUS_ALL,
  STATUS_ARCHIVE,
  STATUS_IS_SPAM,
  STATUS_NEW,
  STATUS_REPLIED,
} from 'app/src/constants/responsesInbox.constants';
import { INBOX_STATUS_CONTAINER_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import LinkButton from 'app/src/complex_components/LinkButton';
import SpamIcon from 'app/src/images/SpamIcon';

const STATUS = [
  {
    id: STATUS_ALL,
    name: 'All',
    icon: CheckIcon,
  },
  {
    id: STATUS_NEW,
    name: 'Unread',
    icon: NewIcon,
  },
  {
    id: STATUS_REPLIED,
    name: 'Replied',
    icon: RepliedIcon,
  },
  {
    id: STATUS_ARCHIVE,
    name: 'Archive',
    icon: ArchiveIcon,
  },
  {
    id: STATUS_IS_SPAM,
    name: 'Spam',
    icon: SpamIcon,
  },
];

const InboxStatus = ({ status, onStatusChange }) => {
  const theme = useTheme();
  const getStatusIconColor = active => (active ? theme.colors.blue7 : theme.colors.gray2);

  return (
    <StyledStatus>
      <TextSmall fontWeight="700" textColor={theme.colors.ghostDark}>
        Status
      </TextSmall>
      <StatusContainer data-test-id={INBOX_STATUS_CONTAINER_TEST_ID}>
        {STATUS.map(({ id, name, icon: StatusIcon, tooltip }) => {
          const active = status.id === id;
          return (
            <LinkButton
              key={id}
              active={active}
              tooltip={tooltip}
              text={name}
              onClick={() => onStatusChange({ id, name })}
              icon={<StatusIcon color={getStatusIconColor(active)} />}
            />
          );
        })}
      </StatusContainer>
    </StyledStatus>
  );
};

export default InboxStatus;

const StyledStatus = styled.div`
  grid-area: status;
  padding: 24px 8px 10px 24px;
  box-shadow: inset -1px 0px 0px ${({ theme }) => theme.colors.gray3};
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;
`;
