import { createActionsHook, createContainer, createHook, createStore } from 'react-sweet-state';
import { API, graphqlOperation } from 'aws-amplify';
import { getRequest, graphqlRequest, postRequest } from '../helpers/API';
import { onCreateOrUpdateVodAssetByAppKey } from '../graphql/subscriptions';
import { uploadToS3 } from '../helpers/S3Upload';
import { getVodAsset, videosByAppKey } from '../graphql/queries';
import { videosByAppKey as videosByAppKeyWithoutConnections } from '../graphql/custom_queries';
import { createVodAsset, updateVodAsset } from '../graphql/mutations';
import { StockVideos } from './StockVideosData';
import Utils from '../utils';
import SharedUtils from 'shared/react/utils/utils';
import Routes from '../helpers/Routes';
import {
  POSTER_EXTENSION,
  STORIES_IMAGE_EXTENSION,
} from 'app/src/constants/vodAssetExtensions.constants';
import { VIDEO_PROVIDERS } from 'shared/react/constants/videoProviders.constants';
import { VIDEO_STATUS } from 'shared/react/constants/video.constants';
import { getDefaultVideoFilesFilters } from 'app/src/utils/filters.utils';
import { isNotInvalid } from 'app/src/utils/video.utils';
import { VideoRenditionsService, AssetsUtilsService } from 'shared/react/services/assets';
import { FEATURE_APP_LOAD_CONNECTIONS_WITH_PAGINATION } from 'shared/react/constants/features.constants';

const initialState = {
  data: [],
  trendingVideos: [],
  loading: false,
  error: null,
  initialized: false,
  subscription: null,
  videoFilesPageFilters: {},
  selectedTeamMember: {},
};

const actions = {
  fetch:
    () =>
    async ({ getState, setState }, { getFeatureEnabled }) => {
      const appKey = Utils.getAppKey();

      if (getState().loading || !appKey) return;
      setState({ loading: true });
      let nextToken = 1;
      const videos = [];
      const trendingVideos = [];

      while (nextToken) {
        const request = getFeatureEnabled(FEATURE_APP_LOAD_CONNECTIONS_WITH_PAGINATION)
          ? videosByAppKey
          : videosByAppKeyWithoutConnections;
        try {
          const response = await graphqlRequest(
            graphqlOperation(request, {
              appKey,
              limit: 500,
              nextToken: nextToken === 1 ? null : nextToken,
              sortDirection: 'DESC',
            })
          );
          nextToken = response.data.videosByAppKey.nextToken;
          videos.push(...response.data.videosByAppKey.items);
        } catch (error) {
          console.error(error);
          Utils.logError('Failed to fetch videos', error);
          if (!error.data?.videosByAppKey) {
            nextToken = null;
            setState({ error, loading: false });
            return;
          }

          nextToken = error.data.videosByAppKey.nextToken || null;
          videos.push(...error.data.videosByAppKey.items);
        }
      }

      try {
        const response = await getRequest(
          'user-actions',
          `/actions/users/trending-videos?appKey=${appKey}`
        );
        if (response && response.length > 0) {
          trendingVideos.push(...response);
        }
      } catch (error) {
        console.error(error);
      }

      const filteredVideos = videos.filter(isNotInvalid);
      setState({ data: filteredVideos, trendingVideos, initialized: true, loading: false });
      return videos;
    },
  updateVideo:
    input =>
    async ({ getState, setState }, { getFeatureEnabled }) => {
      setState({ loading: true });
      try {
        delete input.updatedAt;
        delete input.createdAt;
        if (!getFeatureEnabled(FEATURE_APP_LOAD_CONNECTIONS_WITH_PAGINATION)) {
          delete input.vodConnections;
        }

        const response = await graphqlRequest(graphqlOperation(updateVodAsset, { input: input }));
        const item = response.data.updateVodAsset;
        const items = getState().data;
        const data = items.map(video => {
          if (video.id === item.id) {
            return item;
          }

          return video;
        });
        setState({ data, loading: false });
      } catch (error) {
        console.error(error);
        setState({ error, loading: false });
      }
    },
  uploadVideoAsset: (history, data, onProgressChanged) => async () => {
    const { uuid, blob, video, videoInvalid } = data;
    let { owner: ownerId } = data;

    if (!ownerId) {
      ownerId = Utils.getOwner();
    }

    try {
      await uploadToS3('video', `public/${ownerId}/${uuid}.webm`, blob, progress => {
        onProgressChanged?.(uuid, progress + '%');
        if (progress === 'Infinity%') {
          return { video };
        }
      });
      return {
        data: {
          uuid,
          video,
          videoInvalid,
        },
      };
    } catch (error) {
      return {
        error,
        video,
      };
    }
  },
  navigateToEditStep: (history, projectId, stepId, state) => () => {
    const baseRoute = `${Routes.getEditStepBaseRoute()}/${projectId}`;

    history.push({
      pathname: stepId ? baseRoute + '/' + stepId : baseRoute,
      state,
    });
  },
  getVideo:
    (id, originalVod = {}) =>
    async ({ getState, setState }) => {
      const response = await graphqlRequest(
        graphqlOperation(getVodAsset, {
          id,
        })
      );
      const videos = getState().data;
      const newVideo = response.data.getVodAsset;

      if (newVideo.status === originalVod?.status) {
        return newVideo;
      }

      const newVideos = videos.map(video => {
        if (video.id === id) {
          return newVideo;
        }

        return video;
      });

      setState({ data: newVideos });
      return newVideo;
    },
  createVideo:
    input =>
    async ({ getState, setState }) => {
      try {
        if (!input.owner) {
          input.owner = Utils.getOwner();
        }

        if (!input.appKey) {
          input.appKey = Utils.getAppKey();
        }

        if (!input.name) {
          input.name = '';
        }

        const response = await graphqlRequest(graphqlOperation(createVodAsset, { input }));
        const newVodAsset = response.data.createVodAsset;
        const items = getState().data;
        items.unshift(newVodAsset);
        setState({ data: items, loading: false });
        return newVodAsset;
      } catch (error) {
        console.error(error);
        setState({ error, loading: false });
      }
    },
  createStockVideo:
    stockAsset =>
    async ({ dispatch, getState }) => {
      const videoAsset = {
        name: 'Stock Video',
        status: VIDEO_STATUS.done,
        uploadType: 'stock',
        stockAsset: {
          videoUrl: stockAsset.videoUrl,
          posterUrl: stockAsset.posterUrl,
        },
      };

      const items = getState().data;
      const existingVideo = items.find(
        video =>
          video.stockAsset?.videoUrl === videoAsset.stockAsset.videoUrl &&
          video.status === VIDEO_STATUS.done
      );
      if (existingVideo) {
        return existingVideo;
      } else {
        return dispatch(actions.createVideo(videoAsset));
      }
    },
  deleteVideo:
    input =>
    async ({ getState, setState }) => {
      setState({ loading: true });
      try {
        const body = { vodAssetId: input.id, appKey: Utils.getAppKey() };
        await postRequest('video-actions', '/videos/delete', { body });
        const videos = getState().data;
        const newVideos = videos.filter(v => v.id !== input.id);
        setState({ data: newVideos, loading: false });
      } catch (error) {
        console.error(error);
        setState({ error, loading: false });
      }
    },
  getVideoCreator: video => () => {
    if (!video || !video.stockAsset?.videoUrl) {
      return null;
    }

    let creator = video.stockAsset.creator;
    if (creator) {
      return creator;
    }

    return StockVideos.find(x => x.videoUrl === video.stockAsset.videoUrl)?.creator;
  },
  getVideoCreatorPageUrl: video => () => {
    if (!video || !video.stockAsset?.videoUrl) {
      return null;
    }

    let creatorPageUrl = video.stockAsset.creatorPageUrl;
    if (creatorPageUrl) {
      return creatorPageUrl;
    }

    return StockVideos.find(x => x.videoUrl === video.stockAsset.videoUrl)?.creatorPageUrl;
  },
  downloadVideo:
    video =>
    async ({ setState, dispatch }) => {
      setState({ loading: true });
      const videoUrl = dispatch(actions.getVideoUrl(video));
      fetch(videoUrl)
        .then(response => response.blob())
        .then(blob => {
          const blobURL = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = blobURL;
          a.download = video.name;
          a.click();
          setState({ loading: false });
        });
    },

  getVideoUrl: (video, resolution) => () => {
    return VideoRenditionsService.getVideoUrl(video, resolution);
  },
  getBasicVideoUrl: (video, resolution) => () => {
    return VideoRenditionsService.getBasicVideoUrl(video, resolution);
  },
  getBasicVideoPreviewUrl: video => () => {
    return `${VideoRenditionsService.getOwnerPath(video)}/${video.id}/${video.id}_preview.mp4`;
  },
  handleVideoLoadError:
    (video, videoUrl, setVideoUrl, isPreview) =>
    ({ dispatch }) => {
      if (!video?.id) {
        return;
      }

      if (SharedUtils.getIsShopifyCDN(videoUrl)) {
        if (isPreview) {
          setVideoUrl(dispatch(actions.getBasicVideoPreviewUrl(video)));
        }

        setVideoUrl(dispatch(actions.getBasicVideoUrl(video)));
      }

      const body = { videoId: video.id, appKey: Utils.getAppKey() };
      return postRequest('video-actions', '/videos/remove-vod-asset-from-cdn', {
        body,
      });
    },
  getSubtitlesUrl: step => () => {
    return `${VideoRenditionsService.getOwnerPath(step)}/${step.videoId}/${step.videoId}.vtt`;
  },
  getSubtitlesUrlByVideo: video => () => {
    return `${VideoRenditionsService.getOwnerPath(video)}/${video.id}/${video.id}.vtt`;
  },
  trimVideo: body => async () => {
    return postRequest('video-actions', '/videos/trim', {
      body,
    });
  },
  uploadSubtitles:
    ({ projectId, videoId, subtitles }) =>
    () => {
      return postRequest('project-actions', '/actions/projects/subtitles', {
        body: {
          projectId,
          videoId,
          text: subtitles,
          appKey: Utils.getAppKey(),
        },
      });
    },
  getVideoPoster: video => () => VideoRenditionsService.getVideoPoster(video),
  getVideoAvatar: video => () => {
    if (!video) {
      return null;
    }

    const { stockAsset } = video;
    if (stockAsset?.posterUrl || stockAsset?.avatarUrl) {
      return getAvatarUrlFromStockAsset(stockAsset);
    }

    return `${VideoRenditionsService.getOwnerPath(video)}/${video.id}/${
      video.id
    }${STORIES_IMAGE_EXTENSION}`;
  },
  getVideoPosterLarge: video => () => {
    if (video.stockAsset?.posterUrl) {
      return video.stockAsset.posterUrl;
    }

    return `${VideoRenditionsService.getOwnerPath(video)}/${video.id}/${video.id}.0000000.jpg`;
  },
  getVideoPreview:
    video =>
    ({ dispatch }) => {
      if (!video?.id) {
        return '';
      }

      if (video.stockAsset?.videoUrl) {
        return video.stockAsset.videoUrl;
      }

      return dispatch(actions.getBasicVideoPreviewUrl(video));
    },
  getVideoGif:
    ({ stockAsset, id, owner }) =>
    () => {
      if (!id) {
        return '';
      }

      if (stockAsset && !stockAsset?.hasOriginal) {
        return stockAsset.gifUrl ? stockAsset.gifUrl : stockAsset.posterUrl;
      }

      return `${VideoRenditionsService.getOwnerPath({ owner })}/${id}/${id}.mp4.gif`;
    },
  getBaseUrl: step => () => {
    return VideoRenditionsService.getOwnerPath(step);
  },
  getVideoById:
    id =>
    ({ getState }) => {
      const { data, trendingVideos } = getState();
      const videoInData = data?.find(video => video.id === id);

      if (videoInData) {
        return videoInData;
      }

      return trendingVideos?.find(video => video.id === id);
    },
  getFirstVideoByProject:
    project =>
    ({ dispatch }) => {
      const { stepsOrder, steps } = project || {};
      const firstStep = steps?.items?.find(step => step?.name === stepsOrder[0]);
      return dispatch(actions.getVideoById(firstStep?.videoId));
    },
  getVideoByExternalId:
    externalId =>
    ({ getState }) => {
      const { data } = getState();
      return data?.find(video => video.externalId === externalId);
    },
  addVodConnectionToVodAsset:
    vodConnection =>
    ({ getState, setState }) => {
      const videos = getState().data;
      const newVideos = videos.map(video => {
        if (video.id !== vodConnection.vodAssetId) {
          return video;
        }

        return {
          ...video,
          vodConnections: { items: [...(video?.vodConnections?.items || []), vodConnection] },
        };
      });

      setState({ data: newVideos });
    },
  removeVodConnectionToVodAsset:
    vodConnection =>
    ({ getState, setState }) => {
      const videos = getState().data;
      const newVideos = videos.map(video => {
        if (video.id !== vodConnection.vodAssetId) {
          return video;
        }

        const vodConnections = video.vodConnections.items.filter(
          ({ id }) => id !== vodConnection.id
        );

        return { ...video, vodConnections: { items: vodConnections } };
      });

      setState({ data: newVideos });
    },
  updateVodConnectionToVodAsset:
    vodConnection =>
    ({ getState, setState }) => {
      const videos = getState().data;
      const newVideos = videos.map(video => {
        if (video.id !== vodConnection.vodAssetId) {
          return video;
        }

        const vodConnections = video.vodConnections.items.map(item => {
          if (item.id !== vodConnection.id) {
            return item;
          }

          return vodConnection;
        });

        return { ...video, vodConnections: { items: vodConnections } };
      });

      setState({ data: newVideos });
    },
  subscribeVideos:
    () =>
    ({ setState, getState }, { appKey }) => {
      try {
        console.log('Creating vod socket');

        const subscription = API.graphql(
          graphqlOperation(onCreateOrUpdateVodAssetByAppKey, { appKey })
        ).subscribe({
          next: data => {
            const video = data?.value?.data?.onCreateOrUpdateVodAssetByAppKey;

            if (!video) {
              return;
            }

            const { data: videos = [] } = getState();

            const oldVideo = videos?.find(({ id }) => id === video.id);
            if (!oldVideo) {
              console.log('Subscription new video', video);
              setState({ data: [...videos, video] });
            }

            console.log('Subscription update video', video);
            const newVideos = updateVideo(videos, video);
            setState({ data: newVideos });
          },
          error: ({ error: { errors = [] } = {} }) => {
            console.error(
              `Error in onCreateOrUpdateVodAssetByAppKey subscribe ${JSON.stringify(errors)}`
            );
          },
        });

        setState({ subscription });
      } catch (error) {
        console.log('Error ', error);
        Utils.logError('Cannot subscribe to videos', error);
      }
    },
  importMultipleVideos: body => async () => {
    const response = await postRequest('video-scraper', `/video/handle-multiple-videos-import`, {
      body,
    });

    return response;
  },
  unsubscribeVideos:
    () =>
    ({ getState, setState }) => {
      console.log('Closing vod socket ');

      getState().subscription?.unsubscribe();

      setState({ subscription: null });
    },
  clearVideosStore:
    () =>
    ({ setState }) => {
      const { subscription, ...newState } = initialState;

      setState(newState);
    },
  setVideoFilesPageFilters:
    videoFilesPageFilters =>
    ({ setState }) => {
      setState({ videoFilesPageFilters });
    },
  setSelectedTeamMember:
    selectedTeamMember =>
    ({ setState }) => {
      setState({ selectedTeamMember });
    },
  resetVideoFilesPageFilters:
    isAccountOwner =>
    ({ dispatch }) => {
      const defaultFilters = getDefaultVideoFilesFilters({ isAccountOwner });
      dispatch(actions.setVideoFilesPageFilters({ ...defaultFilters }));
      dispatch(actions.setSelectedTeamMember({}));
    },
};

const updateVideo = (videos, video) => {
  return videos.map(vodAsset => {
    if (vodAsset.id !== video.id) {
      return vodAsset;
    }

    return {
      ...vodAsset,
      ...video,
      externalProviderData: vodAsset.externalProviderData,
      externalId: vodAsset.externalId,
      uploadType: video.uploadType || vodAsset.uploadType,
    };
  });
};

const selectVodAssets = state => state.data;
const selectVodAssetsCount = state => state.data.length;

const selectGalleryAssetsCount = state =>
  state.data.filter(AssetsUtilsService.isGalleryAsset).filter(AssetsUtilsService.isSavedAsset)
    .length;
const selectImageAssetsCount = (state, videos) =>
  (videos || state.data)
    .filter(AssetsUtilsService.isImageAsset)
    .filter(AssetsUtilsService.isSavedAsset).length;
const selectVideoAssetsCount = (state, videos) =>
  (videos || state.data)
    .filter(AssetsUtilsService.isVideoAsset)
    .filter(AssetsUtilsService.isSavedAsset).length;

const selectTreandingVodAssets = state => state.trendingVideos;
const selectTreandingVodAssetsCount = state => state.trendingVideos?.length;

const videosSelector = (state, id) => {
  if (!id) {
    return state;
  }

  return { ...state, video: state.data.find(video => video.id === id) };
};

const getAvatarUrlFromStockAsset = ({ avatarUrl, posterUrl }) => {
  if (avatarUrl) {
    return avatarUrl;
  }

  if (VIDEO_PROVIDERS.some(provider => posterUrl.includes(provider))) {
    return posterUrl;
  }

  return posterUrl.replace(POSTER_EXTENSION, STORIES_IMAGE_EXTENSION);
};

const VideosStore = createStore({ initialState, actions, name: 'Videos' });

const videosByIdsSelector = (state, ids) => state.data.filter(video => ids.includes(video.id));

export const useVideosByIds = createHook(VideosStore, { selector: videosByIdsSelector });

export const useVideos = createHook(VideosStore, { selector: videosSelector });
export const useVodAssets = createHook(VideosStore, { selector: selectVodAssets });
export const useVodAssetsCount = createHook(VideosStore, { selector: selectVodAssetsCount });
export const useVideoAssetsCount = createHook(VideosStore, { selector: selectVideoAssetsCount });
export const useImageAssetsCount = createHook(VideosStore, { selector: selectImageAssetsCount });
export const useGalleryAssetsCount = createHook(VideosStore, {
  selector: selectGalleryAssetsCount,
});
export const useTrendingVodAssets = createHook(VideosStore, { selector: selectTreandingVodAssets });
export const useTrendingVodAssetsCount = createHook(VideosStore, {
  selector: selectTreandingVodAssetsCount,
});

export const useVideoActions = createActionsHook(VideosStore);

const videoByIdSelector = (state, id) => {
  return state.data.find(video => video.id === id);
};

export const useVideoById = createHook(VideosStore, { selector: videoByIdSelector });

export const VideosContainer = createContainer(VideosStore, {
  onInit:
    () =>
    ({ dispatch }) => {
      dispatch(actions.subscribeVideos());
      dispatch(actions.fetch());
    },
  onCleanup:
    () =>
    ({ dispatch }) => {
      dispatch(actions.clearVideosStore());
      dispatch(actions.unsubscribeVideos());
    },
});
