import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { parseInt } from 'lodash';
import { Button } from '@tolstoy/ui-library/core';
import SubtitlePart from 'src/pages/subtitles_editor/SubtitlePart';
import { Plus } from 'lucide-react';

const TIME_REGEX =
  /[0-9]{1,2}:[0-9]{1,2}:[0-9]{1,2}.[0-9]* --> [0-9]{1,2}:[0-9]{1,2}:[0-9]{1,2}.[0-9]*/g;

type SubtitleItem = {
  times: string;
  text: string;
  key: string;
};

type Props = {
  subtitles?: string;
  setSubtitlesBlob?: (blob: Blob) => void;
  uploadedVTTContent: string | null;
};

const CaptionsEditorContent = ({ subtitles, setSubtitlesBlob, uploadedVTTContent }: Props) => {
  const [subtitleArray, setSubtitleArray] = useState<SubtitleItem[]>([]);

  const handlePartChanged = (i: number, text: string, times: string) => {
    const arr = [...subtitleArray];
    arr[i] = {
      text,
      times,
      key: arr[i].key,
    };
    setBlobAndParts(arr);
  };

  const handlePartDeleted = (i: number) => {
    const arr = [...subtitleArray];
    arr.splice(i, 1);
    setBlobAndParts(arr);
  };

  const addNewPart = () => {
    const arr = [...subtitleArray];
    const lastElement = arr.length
      ? arr[arr.length - 1]
      : { times: '00:00:00.000 --> 00:00:00.000' };
    const [, to] = lastElement.times.split(' --> ');

    arr.push({
      text: '',
      times: prepareNewTimes(to),
      key: uuidv4(),
    });
    setSubtitleArray(arr);
  };

  const prepareNewTimes = (to: string) => {
    const newFrom = addSecondToTime(to);
    return `${newFrom} --> ${addSecondToTime(newFrom)}`;
  };

  const addSecondToTime = (time: string) => {
    const [hours, minutes, seconds] = time.split('.')[0].split(':');
    let formattedMinutes = minutes;
    let formattedSeconds = parseInt(seconds) < 59 ? `${parseInt(seconds) + 1}` : '00';

    if (formattedSeconds === '00') {
      formattedMinutes = `${parseInt(minutes) + 1}`;
    }

    formattedSeconds = formattedSeconds.length > 1 ? formattedSeconds : `0${formattedSeconds}`;
    formattedMinutes = formattedMinutes.length > 1 ? formattedMinutes : `0${formattedMinutes}`;

    const milliseconds = time.split('.')[1];

    return `${hours}:${formattedMinutes}:${formattedSeconds}.${milliseconds}`;
  };

  const setBlobAndParts = (arr: SubtitleItem[]) => {
    const parts = arr.map(({ text, times }) => `${times}\n${text}`);
    const subtitles = `WEBVTT\n\n${parts.join('\n\n')}`;

    const blob = new Blob([subtitles], { type: 'text/vtt' });
    setSubtitleArray(arr);
    setSubtitlesBlob?.(blob);
  };

  const parseVTT = (vttContent: string): SubtitleItem[] => {
    const lines = vttContent.trim().split('\n');
    const subtitleParts: SubtitleItem[] = [];
    let currentPart: Partial<SubtitleItem> = { times: '', text: '' };

    for (let i = 1; i < lines.length; i++) {
      const line = lines[i].trim();
      if (line.match(TIME_REGEX)) {
        if (currentPart.times) {
          subtitleParts.push({ ...currentPart, key: uuidv4() } as SubtitleItem);
          currentPart = { times: '', text: '' };
        }

        currentPart.times = line;
      } else if (line !== '') {
        currentPart.text += (currentPart.text ? '\n' : '') + line;
      }
    }

    if (currentPart.times) {
      subtitleParts.push({ ...currentPart, key: uuidv4() } as SubtitleItem);
    }

    return subtitleParts;
  };

  useEffect(() => {
    let parsedSubtitles: SubtitleItem[] = [];
    if (uploadedVTTContent) {
      parsedSubtitles = parseVTT(uploadedVTTContent);
    } else if (subtitles) {
      parsedSubtitles = parseVTT(subtitles);
      if (parsedSubtitles.length === 0) {
        addNewPart();
        return;
      }
    }

    setSubtitleArray(parsedSubtitles);
    setBlobAndParts(parsedSubtitles);
  }, [subtitles, uploadedVTTContent]);

  return (
    <Container>
      <SubtitlesContainer>
        {subtitleArray.map(({ text, times, key }, i) => (
          <SubtitlePart
            key={key}
            index={i}
            onPartChanged={handlePartChanged}
            onPartDeleted={handlePartDeleted}
            text={text}
            times={times}
          />
        ))}
        <Button className="w-[70px] pl-1 pr-2" variant="ghost" onClick={addNewPart}>
          <Plus className="mr-2 h-4 w-4" /> Add
        </Button>
      </SubtitlesContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  overflow: scroll;
`;

const SubtitlesContainer = styled.div`
  display: grid;
  gap: 8px;
  border-radius: 8px;
  align-items: center;
  align-self: start;
  align-content: center;
  width: 100%;
`;

export default CaptionsEditorContent;
