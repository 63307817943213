import React from 'react';
import AnalyticsOverviewAovTooltip from './AnalyticsOverviewAovTooltip';
import ChartBox from '../chart/ChartBox';
import ChartHeader from '../chart/ChartHeader';
import ComposedChart from 'app/src/complex_components/analytics/chart/ComposedChart';
import useAnalyticsOverview from 'app/src/hooks/useAnalyticsOverview';
import Utils from 'app/src/utils';
import { ChartStats } from '../chart/ChartStats';
import {
  ANALYTICS_OVERVIEW_BAR_CHARTS,
  ANALYTICS_OVERVIEW_STATS,
} from 'app/src/constants/analytics.constants';

const AnalyticsOverviewAov = () => {
  const {
    aovOvertimeShouldShowEmptyState,
    aovOvertimeShouldShowLoader,
    currency,
    formattedBaselineOrdersAov,
    formattedTolstoyOrdersAov,
    ordersAovOvertimeData,
  } = useAnalyticsOverview();
  const { title, bars, lines } = ANALYTICS_OVERVIEW_BAR_CHARTS.AVG_AOV;

  const statsRows = [
    {
      name: ANALYTICS_OVERVIEW_STATS.BASELINE_TOLSTOY_CONVERSION_RATE_USERS,
      formattedValue: formattedBaselineOrdersAov,
    },
  ];

  const yTickFormatter = (value: string) => {
    return Utils.formatNumber({ value, currency });
  };

  return (
    <ChartBox shouldShowLoader={aovOvertimeShouldShowLoader}>
      <ChartHeader
        subtitle={formattedTolstoyOrdersAov}
        title={title}
        titleTooltipText={ANALYTICS_OVERVIEW_STATS.AVG_AOV_TOOLTIP}
      />
      <ChartStats rows={statsRows} shouldShowEmptyState={aovOvertimeShouldShowEmptyState} />
      <ComposedChart
        data={ordersAovOvertimeData}
        bars={bars}
        lines={lines}
        renderCustomTooltip={AnalyticsOverviewAovTooltip}
        shouldShowEmptyState={aovOvertimeShouldShowEmptyState}
        yTickFormatter={yTickFormatter}
      />
    </ChartBox>
  );
};

export default AnalyticsOverviewAov;
