import React from 'react';
import styled from 'styled-components';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';
import { TextBody, TextH5 } from 'shared/react/components/complex/Text';
import { getFeedActionButtonOnClick } from 'shared/react/components/complex/feed/feed_utils/feedActionButton.utils';
import { DESCRIPTION_SIDE_PANEL_TYPE } from 'shared/react/constants/feed.constants';
import useTracker from 'shared/react/components/complex/context/hooks/useTracker';
import FeedBrandedButton from 'shared/react/components/complex/feed/feed_buttons/FeedBrandedButton';
import {
  FEED_DESCRIPTION_TEXT_TEST_ID,
  FEED_SECONDARY_BUTTON_TEST_ID,
} from 'shared/react/constants/dataTestIds.constants';
import {
  FEED_DESCRIPTION_CONTENT_DATA_TYPE,
  FEED_DESCRIPTION_DATA_TYPE,
  FEED_DESCRIPTION_TEXT_DATA_TYPE,
  FEED_DESCRIPTION_TITLE_DATA_TYPE,
} from 'shared/react/constants/feedPreviewDataTypes.constants';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import useFeedFeatures from 'shared/react/components/complex/context/hooks/useFeedFeatures';
import { FEATURE_FEED_OPEN_DESCRIPTION_CTA_IN_SAME_TAB } from 'shared/react/constants/features.constants';
import UrlHelper from 'shared/react/utils/urlHelper';

const SHOULD_NOT_SHOW_KEY_WORDS = ['.mp4', 'recorded video', '.mov'];

const FeedDescription = ({ currentStepKey }) => {
  const [, { track }] = useTracker();
  const [{ isAppPreview }, { getCurrentStep }] = useProjectConfig();
  const currentStep = getCurrentStep(currentStepKey);
  const [, { getFeatureSettingsByKey }] = useFeedFeatures();
  const { isOpenDescriptionCtaInSameTab } = getFeatureSettingsByKey(
    FEATURE_FEED_OPEN_DESCRIPTION_CTA_IN_SAME_TAB
  );
  const {
    question,
    description,
    feedSidePanelDescription,
    feedDescriptionActionButton,
    videoDescription,
  } = currentStep;
  const {
    text,
    type,
    value,
    isOpenInANewTab,
    enabled: isButtonEnabled,
  } = feedDescriptionActionButton || {};
  const titleIncludesKeywords = SHOULD_NOT_SHOW_KEY_WORDS.some(keyword =>
    question?.toLocaleLowerCase().includes(keyword)
  );
  const shouldShowTitle = !titleIncludesKeywords && question !== videoDescription;

  const getIsOpenInaNewTab = () => {
    if (isOpenDescriptionCtaInSameTab) {
      return false;
    }

    return isOpenInANewTab;
  };

  const onClick = () => {
    track(`${DESCRIPTION_SIDE_PANEL_TYPE}ActionButtonClick`, { type });

    const utmParams = UrlHelper.getUtmParams();
    const url = UrlHelper.appendSearchParamsToUrl(value, utmParams);

    getFeedActionButtonOnClick({
      type,
      value: url,
      isOpenInANewTab: getIsOpenInaNewTab(),
    });
  };

  const getIsAppEmptyState = (...values) => {
    if (!isAppPreview) {
      return false;
    }

    return values.every(value => !value);
  };

  const isDescriptionEmptyState = getIsAppEmptyState(feedSidePanelDescription);
  const getDescription = () => {
    if (isDescriptionEmptyState) {
      return 'Add cool description here. but longer.';
    }

    return feedSidePanelDescription || videoDescription;
  };

  const getDescriptionHeader = () => {
    if (getIsAppEmptyState(question, description)) {
      return 'Add video name';
    }

    if (!shouldShowTitle) {
      return '';
    }

    return question;
  };

  const shouldShowFeedBrandedButton = isButtonEnabled && value;

  return (
    <LayoutRoot data-type={FEED_DESCRIPTION_DATA_TYPE}>
      <Content data-type={FEED_DESCRIPTION_CONTENT_DATA_TYPE}>
        <TextH5 data-type={FEED_DESCRIPTION_TITLE_DATA_TYPE}>{getDescriptionHeader()}</TextH5>
        <StyledTextBody
          data-type={FEED_DESCRIPTION_TEXT_DATA_TYPE}
          data-test-id={FEED_DESCRIPTION_TEXT_TEST_ID}
          isDescriptionEmptyState={isDescriptionEmptyState}
        >
          {getDescription()}
        </StyledTextBody>
      </Content>
      {shouldShowFeedBrandedButton && (
        <StyledFeedBrandedButton
          onClick={onClick}
          buttonText={text || 'See more'}
          data-test-id={FEED_SECONDARY_BUTTON_TEST_ID}
        />
      )}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  gap: 32px;
  width: 100%;
  height: 100%;
  max-width: 25vw;
  box-sizing: border-box;
  padding: 24px 0;
  background: ${({ theme }) => theme.colors.white};
`;

const Content = styled(Gap16VerticalFlex)`
  overflow: hidden;
  text-align: left;

  & > * {
    padding: 0 32px;
  }
`;

const StyledTextBody = styled(TextBody)`
  overflow-y: auto;
  color: ${({ theme, isDescriptionEmptyState }) =>
    isDescriptionEmptyState ? theme.colors.gray2 : ''};
  white-space: break-spaces;
  word-break: break-word;
`;

const StyledFeedBrandedButton = styled(FeedBrandedButton)`
  padding: 16px;
  margin: 0 32px;
`;

export default FeedDescription;
