import React from 'react';
import AnalyticsTopConvertingVideoItem from './AnalyticsTopConvertingVideoItem';
import ComponentWithLoader from 'shared/react/components/basic/ComponentWithLoader';
import styled from 'styled-components';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';

const AnalyticsTopConvertingVideoContent = ({
  isEmpty,
  isLoading,
  videoMetrics,
  videoInsights,
}) => {
  if (isEmpty) {
    return <EmptyStateContent />;
  }

  return (
    <ComponentWithLoader isLoading={isLoading}>
      {videoMetrics.map(metrics => (
        <AnalyticsTopConvertingVideoItem
          key={metrics.id}
          metrics={metrics}
          insights={videoInsights[metrics.id]}
        />
      ))}
    </ComponentWithLoader>
  );
};

const EmptyStateContent = () => {
  return (
    <EmptyContainer>
      <TextTiny>No data available</TextTiny>
    </EmptyContainer>
  );
};

const EmptyContainer = styled(HorizontalFlexCentered)`
  min-height: 326px;
  width: 100%;
`;

export default AnalyticsTopConvertingVideoContent;
