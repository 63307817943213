import React from 'react';

const HelpIcon = () => {
  return (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.07.75H4.89C2.587.75.75 2.625.75 4.93v.82c0 .352.273.625.625.625A.642.642 0 0 0 2 5.75v-.82C2 3.328 3.29 2 4.89 2h4.18A2.951 2.951 0 0 1 12 4.93c0 1.054-.586 2.03-1.523 2.578l-3.79 2.11a1.917 1.917 0 0 0-.937 1.64v1.367c0 .352.273.664.625.664.313 0 .625-.312.625-.664v-1.367c0-.235.117-.43.313-.547l3.789-2.149C12.43 7.86 13.25 6.454 13.25 4.93A4.184 4.184 0 0 0 9.07.75ZM6.375 15.125c-.703 0-1.25.586-1.25 1.25 0 .703.547 1.25 1.25 1.25.664 0 1.25-.547 1.25-1.25 0-.664-.586-1.25-1.25-1.25Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default HelpIcon;
