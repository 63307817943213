import React from 'react';
import styled from 'styled-components';
import EditorSliderItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorSliderItem';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import {
  CAROUSEL_PLAY_BUTTON_BACKGROUND_PROPERTY_KEY,
  CAROUSEL_PLAY_BUTTON_BACKGROUND_COLOR_PROPERTY_KEY,
  CAROUSEL_PLAY_BUTTON_BORDER_PROPERTY_KEY,
  CAROUSEL_PLAY_BUTTON_BORDER_COLOR_PROPERTY_KEY,
  CAROUSEL_PLAY_BUTTON_TRANSPARENCY_PROPERTY_KEY,
} from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import ResetToDefaultInputWrapper from 'app/src/complex_components/ResetToDefaultInputWrapper';
import { DEFAULT_CAROUSEL_EMBED_PLAY_BUTTON_OPACITY } from 'shared/react/constants/feature-constants/playButtonCustomization.constants';
import EditorHeader from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorHeader';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Separator from 'shared/react/components/basic/Separator';
import EditorMainContainer from '../shared/EditorMainContainer';
import EditorToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorToggleItem';
import EditorColorInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorColorInputItem';

const FeedCarouselPlayButtonEditor = ({ disabled }) => {
  const {
    customizationSettings: {
      widgetSettings: {
        carouselPlayButtonBackground,
        carouselPlayButtonBackgroundColor,
        carouselPlayButtonBorder,
        carouselPlayButtonBorderColor,
        carouselPlayButtonOpacity,
      },
    },
    setWidgetSettingsProperty,
  } = useLookAndFeelContext();

  const onOpacityChange = (event, value) => {
    setWidgetSettingsProperty(CAROUSEL_PLAY_BUTTON_TRANSPARENCY_PROPERTY_KEY, value);
  };

  const onBackgroundChange = value => {
    setWidgetSettingsProperty(CAROUSEL_PLAY_BUTTON_BACKGROUND_PROPERTY_KEY, value);
  };

  const onBackgroundColorChange = value => {
    setWidgetSettingsProperty(CAROUSEL_PLAY_BUTTON_BACKGROUND_COLOR_PROPERTY_KEY, value);
  };

  const onBorderChange = value => {
    setWidgetSettingsProperty(CAROUSEL_PLAY_BUTTON_BORDER_PROPERTY_KEY, value);
  };

  const onBorderColorChange = value => {
    setWidgetSettingsProperty(CAROUSEL_PLAY_BUTTON_BORDER_COLOR_PROPERTY_KEY, value);
  };

  const onResetOpacity = () => {
    setWidgetSettingsProperty(
      CAROUSEL_PLAY_BUTTON_TRANSPARENCY_PROPERTY_KEY,
      DEFAULT_CAROUSEL_EMBED_PLAY_BUTTON_OPACITY
    );
  };

  const transparency = (
    <CustomResetToDefaultInputWrapper onClick={onResetOpacity}>
      Transparency
    </CustomResetToDefaultInputWrapper>
  );

  return (
    <LayoutRoot>
      <EditorHeader title="Play button" disabled={disabled} proFeature />
      <EditorMainContainer disabled={disabled}>
        <EditorToggleItem
          text="Background"
          onChange={onBackgroundChange}
          checked={carouselPlayButtonBackground}
          disabled={disabled}
        />
        <EditorColorInputItem
          text="Background color"
          color={carouselPlayButtonBackgroundColor}
          onChange={onBackgroundColorChange}
          disabled={disabled || !carouselPlayButtonBackground}
        />

        <Separator />

        <EditorToggleItem
          text="Border"
          onChange={onBorderChange}
          checked={carouselPlayButtonBorder}
          disabled={disabled}
        />
        <EditorColorInputItem
          text="Border color"
          color={carouselPlayButtonBorderColor}
          onChange={onBorderColorChange}
          disabled={disabled || !carouselPlayButtonBorder}
        />

        <Separator />

        <EditorSliderItem
          onChange={onOpacityChange}
          text={transparency}
          value={carouselPlayButtonOpacity}
          min={0}
          step={0.05}
          max={1}
          disabled={disabled}
        />
      </EditorMainContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

const CustomResetToDefaultInputWrapper = styled(ResetToDefaultInputWrapper)`
  opacity: 1;
  left: 100%;
  top: 50%;
`;

export default FeedCarouselPlayButtonEditor;
