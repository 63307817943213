import React from 'react';
import styled from 'styled-components';
import Tooltip from 'app/src/basic_components/Tooltip';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextH3, TextTiny } from 'shared/react/components/basic/text/TextV2';

type Props = {
  subtitle?: React.ReactNode;
  title: React.ReactNode;
  titleTooltipText?: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const ChartHeader = ({ subtitle, title, titleTooltipText = '', ...props }: Props) => {
  return (
    <LayoutRoot>
      <Tooltip text={titleTooltipText} placement="top">
        <Title {...props}>{title}</Title>
      </Tooltip>
      <SubTitle>{subtitle}</SubTitle>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlex)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Title = styled(TextTiny)`
  color: ${({ theme, color }) => color || theme.colors.neutralDark};
  line-height: 18px;
  text-transform: uppercase;
  text-align: right;
  width: fit-content;
`;

const SubTitle = styled(TextH3)`
  color: ${({ theme }) => theme.colors.neutralBlack};
  font-weight: 700;
`;

export default ChartHeader;
