import React from 'react';
import styled from 'styled-components';
import { cleanStoreName } from 'app/src/utils/shopify.utils';
import { SyncTagsMode } from 'src/constants/shop.constants';
import { CheckboxV2 } from 'app/src/complex_components/Checkbox';
import CrownIcon from 'src/images/dashboard_v2/CrownIcon';
import { TextSmall } from 'shared/react/components/complex/Text';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

const ShopifyAppMultiSelectItem = ({ app, selected = false, onChange = () => {} }) => {
  const isPrimary = app.syncTagsMode === SyncTagsMode.primary;

  return (
    <StyledShopifyAppsMultiSelectItem onClick={onChange}>
      <Checkbox checked={selected} />
      <ItemText>{cleanStoreName(app.appUrl)}</ItemText>
      {isPrimary && <StyledCrownIcon width={16} height={16} />}
    </StyledShopifyAppsMultiSelectItem>
  );
};

const StyledShopifyAppsMultiSelectItem = styled(HorizontalFlex)`
  align-items: center;
  padding: 8px;
  cursor: pointer;
  gap: 4px;
`;

const Checkbox = styled(CheckboxV2)`
  padding: 4px;
`;

const ItemText = styled(TextSmall)`
  font-weight: 500;
`;

const StyledCrownIcon = styled(CrownIcon)`
  margin-left: auto;
`;

export default ShopifyAppMultiSelectItem;
