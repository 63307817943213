import { TABLE_COLUMN_KEYS } from 'app/src/constants/cube.constants';
import React from 'react';
import { formatPrice, numberComparator } from '../analyticsTable.utils';
import Utils from 'app/src/utils';
import { project as Project, publishMethodOptions } from 'app/src/types/entities';
import AnalyticsTableCell from 'app/src/complex_components/analytics/AnalyticsTableCell';
import {
  ANALYTICS_TABLE_COLUMN_CLASSNAMES,
  ANALYTICS_TABLE_COLUMN_GROUPS,
  SPACER_COLUMN_ATTRIBUTES,
} from 'app/src/constants/analytics.constants';
import { formatShopifyPageName } from 'src/utils/shopify.utils';
import FormattedLink from 'src/basic_components/FormattedLink';
import SharedUtils from 'shared/react/utils/utils';

type Props = {
  projects: Project[];
  getTolstoyName: (publishId: string) => string;
  getProjectAnalyticsRouteByPublishId: (publishId: string) => string;
};

export const getOnsiteTableColumns = ({
  projects,
  getTolstoyName,
  getProjectAnalyticsRouteByPublishId,
}: Props) => {
  const isVideoPage = projects?.every(p => p.publishMethod === publishMethodOptions.videoPage);

  return [
    {
      field: TABLE_COLUMN_KEYS.parentUrl,
      headerName: 'Page',
      valueGetter: ({ value }) => formatShopifyPageName(value),
      renderCell: ({ row }) => {
        return <FormattedLink url={row.parentUrl} text={formatShopifyPageName(row.parentUrl)} />;
      },
      width: 150,
      group: ANALYTICS_TABLE_COLUMN_GROUPS.general,
    },
    {
      field: TABLE_COLUMN_KEYS.publishId,
      headerName: 'Tolstoy',
      valueGetter: ({ value }) => getTolstoyName(value) || `Deleted Tolstoy (${value})`,
      renderCell: ({ row }) => {
        const { publishId } = row;
        const tolstoyName = getTolstoyName(publishId);

        return (
          <FormattedLink
            url={getProjectAnalyticsRouteByPublishId(publishId)}
            text={tolstoyName || `Deleted Tolstoy (${publishId})`}
            isDisabled={!tolstoyName}
          />
        );
      },
      width: 150,
      group: ANALYTICS_TABLE_COLUMN_GROUPS.general,
    },
    {
      field: 'conversionStart',
      group: `${ANALYTICS_TABLE_COLUMN_GROUPS.conversion}-${ANALYTICS_TABLE_COLUMN_GROUPS.spacer}`,
      ...SPACER_COLUMN_ATTRIBUTES,
    },
    {
      field: TABLE_COLUMN_KEYS.numOfOrders,
      headerName: 'Total orders',
      flex: 1,
      renderCell: ({ value }) => {
        return <AnalyticsTableCell text={Number(value).toLocaleString()} />;
      },
      sortComparator: numberComparator,
      group: ANALYTICS_TABLE_COLUMN_GROUPS.conversion,
      groupName: 'Conversion',
    },
    {
      field: TABLE_COLUMN_KEYS.inVideoRevenue,
      headerName: 'In-video revenue',
      flex: 1,
      renderCell: ({ row }) => {
        return <AnalyticsTableCell text={formatPrice(row.inVideoRevenue, row.currency)} />;
      },
      sortComparator: numberComparator,
      group: ANALYTICS_TABLE_COLUMN_GROUPS.conversion,
      groupName: 'Conversion',
    },
    {
      field: TABLE_COLUMN_KEYS.postVideoRevenue,
      headerName: 'Post-video revenue',
      flex: 1,
      renderCell: ({ row }) => {
        return <AnalyticsTableCell text={formatPrice(row.postVideoRevenue, row.currency)} />;
      },
      sortComparator: numberComparator,
      group: ANALYTICS_TABLE_COLUMN_GROUPS.conversion,
      groupName: 'Conversion',
    },
    {
      field: TABLE_COLUMN_KEYS.indirectRevenue,
      headerName: 'Indirect revenue',
      flex: 1,
      renderCell: ({ row }) => {
        return <AnalyticsTableCell text={formatPrice(row.indirectRevenue, row.currency)} />;
      },
      sortComparator: numberComparator,
      group: ANALYTICS_TABLE_COLUMN_GROUPS.conversion,
      groupName: 'Conversion',
    },
    {
      field: TABLE_COLUMN_KEYS.conversionRate,
      headerName: 'Conversion rate',
      flex: 1,
      valueGetter: ({ row }) => Utils.getPercentage(row.numOfOrders, row.plays, false, 1),
      renderCell: ({ value }) => {
        return <AnalyticsTableCell text={`${value}%`} />;
      },
      group: ANALYTICS_TABLE_COLUMN_GROUPS.conversion,
      groupName: 'Conversion',
    },
    {
      field: 'engagementStart',
      group: `${ANALYTICS_TABLE_COLUMN_GROUPS.engagement}-${ANALYTICS_TABLE_COLUMN_GROUPS.spacer}`,
      ...SPACER_COLUMN_ATTRIBUTES,
    },
    {
      field: TABLE_COLUMN_KEYS.averageTimeOnTolstoy,
      headerName: 'Average time on Tolstoy',
      flex: 1,
      valueFormatter: ({ value }) => SharedUtils.millisecondsToTime(value, true, true),
      sortComparator: numberComparator,
      group: ANALYTICS_TABLE_COLUMN_GROUPS.engagement,
      groupName: 'Engagement',
    },
    {
      field: TABLE_COLUMN_KEYS.percentageOnTolstoy,
      headerName: '% time of session',
      flex: 1,
      valueGetter: ({ row }) =>
        `${Utils.getPercentage(
          row.averageTimeOnTolstoy,
          row.averageTimeOnPageWhenInteractingTolstoy
        )}%`,
      renderCell: ({ value }) => {
        return <AnalyticsTableCell text={value} disabled={isVideoPage} />;
      },
      group: ANALYTICS_TABLE_COLUMN_GROUPS.engagement,
      groupName: 'Engagement',
    },
    {
      field: TABLE_COLUMN_KEYS.plays,
      headerName: 'Plays',
      flex: 1,
      sortComparator: numberComparator,
      renderCell: ({ value }) => {
        return <AnalyticsTableCell text={Number(value).toLocaleString()} />;
      },
      group: ANALYTICS_TABLE_COLUMN_GROUPS.engagement,
      groupName: 'Engagement',
    },
    {
      field: TABLE_COLUMN_KEYS.avgVideosWatched,
      headerName: 'Avg. videos watched',
      flex: 1,
      sortComparator: numberComparator,
      valueGetter: ({ row }) => +(+row.numOfVideosWatched / +row.plays).toFixed(1),
      renderCell: ({ value }) => {
        return <AnalyticsTableCell text={value} />;
      },
      group: ANALYTICS_TABLE_COLUMN_GROUPS.engagement,
      groupName: 'Engagement',
    },
    {
      field: TABLE_COLUMN_KEYS.playRate,
      headerName: 'Play rate',
      flex: 1,
      headerClassName: ANALYTICS_TABLE_COLUMN_CLASSNAMES.lastColumn,
      cellClassName: ANALYTICS_TABLE_COLUMN_CLASSNAMES.lastColumn,
      valueGetter: ({ row }) => Utils.getPercentage(row.plays, row.numOfEmbedViews, false, 1),
      renderCell: ({ value }) => {
        return <AnalyticsTableCell text={`${value}%`} disabled={isVideoPage} />;
      },
      group: ANALYTICS_TABLE_COLUMN_GROUPS.engagement,
      groupName: 'Engagement',
    },
  ];
};
