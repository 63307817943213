import React from 'react';
import VideoModal from 'app/src/complex_components/VideoModal';
import { PUBLISH_URL, TOLSTOY_SHOPIFY_REVIEW_ID } from 'app/src/config/app.config';

const ShopifyReviewModal = ({ open, closeModal, userEmail, ...props }) => {
  const src = `${PUBLISH_URL}/${TOLSTOY_SHOPIFY_REVIEW_ID}?host&email=${userEmail}`;
  return <VideoModal onClose={closeModal} open={open} src={src} {...props} />;
};

export default ShopifyReviewModal;
