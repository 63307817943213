import React from 'react';
import styled from 'styled-components';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextBody } from 'shared/react/components/complex/Text';
import Utils from 'shared/react/utils/utils';
import PricingTooltip from 'app/src/complex_components/PricingTooltip';

const VideoTimer = ({ recordedVideoTimer, recordingMaxTime }) => {
  const shouldChangeToRed = recordingMaxTime - recordedVideoTimer <= 0;
  return (
    <LayoutRoot>
      <PricingTooltip
        header="Increase time limit"
        placement="top"
        text="On the free plan you get 5 minutes limit. Click the button below to upgrade and increase your limit."
      >
        <TimerContainer shouldChangeToRed={shouldChangeToRed}>
          <CurrentTime>{Utils.secondsToTime(recordedVideoTimer || 0, false, true)}</CurrentTime>
          <Separator>/</Separator>
          <MaxTime>{Utils.secondsToTime(recordingMaxTime, false)}</MaxTime>
        </TimerContainer>
      </PricingTooltip>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)`
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 2;
  position: absolute;
  right: 16px;
  bottom: 16px;
`;

const TimerContainer = styled(HorizontalFlex)`
  background: ${({ theme, shouldChangeToRed }) =>
    shouldChangeToRed ? theme.colors.danger : theme.getHexOpacity(theme.colors.black, 25)};
  border-radius: 16px;
  padding: 8px 12px;
  margin: 16px;
`;

const CurrentTime = styled(TextBody)`
  line-height: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white};
`;

const MaxTime = styled(CurrentTime)`
  opacity: 0.5;
`;

const Separator = styled(MaxTime)`
  margin: 0 4px;
`;

export default VideoTimer;
