import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Utils from 'app/src/utils';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { useVideos } from 'app/src/context/VideosStore';
import {
  DELETE_STEP_TEST_ID,
  STEP_IMAGE_CONTAINER_DATA_TEST_ID,
  STEP_PART_CONTAINER_TEST_ID,
} from 'app/src/constants/dataTestIds.constants';
import BorderedImage from 'app/src/basic_components/BorderedImage';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { LightTextSmall } from 'shared/react/components/complex/Text';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';
import { PRODUCTS_RESULT } from 'app/src/constants/editStep.constants';
import ProductsCarousel from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/products_result_preview/ProductsCarousel';
import OutlinedCrossIcon from 'app/src/images/creation_method_icons/OutlinedCrossIcon';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import { VIDEO_STATUS } from 'shared/react/constants/video.constants';
import OverlaySpinner from 'app/src/basic_components/OverlaySpinner';
import { useModalActions } from 'src/context/ui_store/ModalStore';
import { DELETE_STEP_MODAL_KEY } from 'src/constants/modals.constants';
import { publishMethodOptions } from 'src/types/entities';
import DeleteIconFilled from 'src/images/DeleteIconFilled';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { AssetRenditionsService } from 'shared/react/services/assets';

function Step({
  step,
  order,
  project,
  onEditStepClick,
  isSelected,
  draggingOver,
  loadingIndex,
  onAddNewPart,
}) {
  const isMobile = Utils.isMobile();
  const [{ data: videos, video }] = useVideos(step.videoId);
  const { setModalProps, setCurrentModal } = useModalActions();
  const isEmptyState = step.emptyState;
  const imageRef = useRef();
  const counter = useRef(0);

  useEffect(() => {
    if (step?.id) {
      counter.current = 0;
    }
  }, [step?.id]);

  function getVideo() {
    return videos.find(v => v.id === step.videoId);
  }

  function handleEditClick() {
    if (step.emptyState) {
      onAddNewPart();
      return;
    }
    onEditStepClick(step, getVideo(), '', order);
  }

  useEffect(() => {
    if (video?.status === VIDEO_STATUS.done && imageRef.current) {
      imageRef.current.src = AssetRenditionsService.getAssetPoster(getVideo());
    }
  }, [video, imageRef]);

  const getIcon = () => {
    if (isEmptyState) {
      return (
        <EmptyStateIconContainer>
          <OutlinedCrossIcon />
        </EmptyStateIconContainer>
      );
    }

    return (
      <Image
        borderRadius="8px"
        ref={imageRef}
        draggable={false}
        height="80px"
        placeholderHeight="80px"
        src={AssetRenditionsService.getAssetPoster(getVideo())}
      />
    );
  };

  const getStepPoster = () => {
    if (step.type === PRODUCTS_RESULT) {
      return <ProductsCarousel size={6} step={step} width={140} height={80} />;
    }
    return (
      <ImageContainer data-test-id={STEP_IMAGE_CONTAINER_DATA_TEST_ID}>{getIcon()}</ImageContainer>
    );
  };

  const onDelete = event => {
    event.stopPropagation();
    setModalProps({ stepId: step.id, project });
    setCurrentModal(DELETE_STEP_MODAL_KEY);
  };

  return (
    <LayoutRoot draggable={false} data-test-id={STEP_PART_CONTAINER_TEST_ID}>
      <StyledCard
        isSelected={isSelected}
        draggable={!isMobile}
        draggingOver={draggingOver}
        onClick={handleEditClick}
        shadowAnimationsEnabled
      >
        <OverlaySpinner size={24} isLoading={loadingIndex === order}>
          {project.publishMethod === publishMethodOptions.shopApp && (
            <DeleteContainer data-test-id={DELETE_STEP_TEST_ID} onClick={onDelete}>
              <DeleteIconFilled />
            </DeleteContainer>
          )}
          {getStepPoster()}
        </OverlaySpinner>
        <EllipsisContainer>
          <StepName isSelected={isSelected}>
            <StepNumber>{order + 1}</StepNumber>
            {step.description}
          </StepName>
        </EllipsisContainer>
      </StyledCard>
    </LayoutRoot>
  );
}

const DeleteContainer = styled(HorizontalFlexCentered)`
  position: absolute;
  top: 4px;
  right: 4px;
  width: 28px;
  height: 28px;
  cursor: pointer;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};
  & path {
    fill: ${({ theme }) => theme.colors.danger};
  }
`;

const LayoutRoot = styled(VerticalFlex)`
  display: flex;
  flex-direction: column;
`;

const ActionButtonsContainer = styled(Gap8VerticalFlex)`
  border-radius: ${({ answersLength }) => (answersLength ? '16px 16px 0 0' : '16px')};
  z-index: 100;
  grid-row: 1;
  grid-column: 1;
  background: ${({ theme }) => theme.getHexOpacity(theme.colors.black, 40)};
  align-items: end;
  padding: 8px;
  visibility: collapse;
  opacity: 0;
  transition: opacity 0.25s;
`;

const StyledCard = styled(VerticalFlex)`
  cursor: grab;
  background: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.primary : theme.colors.white};
  border-radius: 8px;
  overflow: hidden;
  border: 2px solid
    ${props => (props.draggingOver ? props.theme.colors.primaryHover : 'transparent')};
  box-shadow: 0 2px 10px rgba(50, 50, 93, 0.1), 0 1px 3px -1px rgba(0, 0, 0, 0.05);
  transition: 0.3s;
  max-width: 144px;

  ${DeleteContainer} {
    display: none;
  }

  &:hover {
    background: ${({ theme, isSelected, isEmptyState }) =>
      isEmptyState
        ? theme.colors.successLightHover
        : isSelected
        ? theme.colors.primary
        : theme.colors.primaryLightHover};

    ${DeleteContainer} {
      display: flex;
    }
  }
`;

const Image = styled(BorderedImage)`
  object-fit: contain;
  grid-row: 1;
  grid-column: 1;
  width: 140px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.black};
`;

const ImageContainer = styled.div`
  display: grid;

  &:hover ${ActionButtonsContainer} {
    visibility: visible;
    opacity: 1;
  }

  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`;

const StepName = styled(LightTextSmall)`
  padding: 8px;
  color: ${({ theme, isSelected }) => (isSelected ? theme.colors.white : theme.colors.black2)};
  transition: 0.3s;
  font-weight: 500;
`;

const EllipsisContainer = styled(EllipsisTextOverflowWrapper)`
  place-self: ${({ isEmptyState }) => (isEmptyState ? 'center' : '')};
`;

const StepNumber = styled.span`
  font-weight: 500;
  margin-right: 4px;
  padding: 0;
`;

const EmptyStateIconContainer = styled(HorizontalFlexWrap)`
  height: 80px;
  width: 140px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.ghostLight};
  & svg {
    width: 24px;
    height: 24px;
  }

  & path {
    fill: ${({ theme }) => theme.colors.success};
  }
`;

export default Step;
