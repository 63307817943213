import React, { useState } from 'react';

import {
  CardContainer,
  CardImageWrapper,
  ContextMenuIcon,
} from 'src/pages/dashboard/components/card/Card';

import { publishMethodOptions } from 'src/types/entities';
import CardControls from 'src/pages/dashboard/components/card/CardControls';
import OffsiteCardDetails from './OffsiteCardDetails';
import useHasResourcePermissions from 'src/hooks/useHasResourcePermissions';
import { useAccount } from 'src/context/AccountStore';
import { projectTabs } from 'src/helpers/Routes';
import { track } from 'src/helpers/Tracker';
import Utils from 'src/utils';
import usePlayerUrl from 'src/hooks/usePlayerUrl';
import { useSnackBar } from 'src/context/ui_store/SnackBarStore';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import useNavigation from 'src/hooks/useNavigation';
import ProjectMenu from 'src/pages/dashboard/components/menus/ProjectMenu';
import ThreeVerticalDotsIcon from 'src/images/dashboard_v2/ThreeVerticalDotsIcon';
import LinkIcon from 'src/images/dashboard_v2/LinkIcon';
import { CARD_LAYOUT_TEST_ID } from 'src/constants/dataTestIds.constants';
import OffsiteCardImage from './OffsiteCardImage';
import styled from 'styled-components';

const OffsiteCard = ({ project, toggleSelected, isSelected, isSelectionMode }) => {
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement>(null);
  const [, { setSnackbar }] = useSnackBar();
  const [{ isAccountOwner }] = useAccount();
  const hasMenuPermissions = useHasResourcePermissions(project, isAccountOwner);
  const { playerUrl } = usePlayerUrl(project?.id);
  const { navigateToProjectTab } = useNavigation();

  const navigateToProject = () => {
    if (isSelectionMode) {
      toggleSelected(project.id);
      return;
    }

    track('Project Card Click');

    const isVideoCollectionCampaign =
      project.publishMethod === publishMethodOptions.videoCollectionCampaign;

    return navigateToProjectTab(project, isVideoCollectionCampaign ? projectTabs.design : '');
  };

  const copyLink = e => {
    e.stopPropagation();
    track('Copy Sharable Link Click', { location: 'Project Card' });
    Utils.copyToClipboard(playerUrl);
    setSnackbar('Link Copied to Clipboard');
  };

  const openMenu = e => {
    e.stopPropagation();
    track('Project Card Menu Opened');
    setMenuAnchor(e.currentTarget);
  };

  return (
    <CardContainer data-test-id={CARD_LAYOUT_TEST_ID} onClick={navigateToProject}>
      <ImageWrapper>
        <OffsiteCardImage project={project} />
        <CardControls
          isSelected={isSelected}
          toggleSelected={() => toggleSelected(project.id)}
          isSelectionMode={isSelectionMode}
        >
          <Gap8VerticalFlex>
            {hasMenuPermissions && (
              <ContextMenuIcon onClick={openMenu}>
                <ThreeVerticalDotsIcon />
              </ContextMenuIcon>
            )}
            <ContextMenuIcon onClick={copyLink}>
              <LinkIcon />
            </ContextMenuIcon>
          </Gap8VerticalFlex>
        </CardControls>
      </ImageWrapper>

      <OffsiteCardDetails project={project} />

      <ProjectMenu
        project={project}
        anchorElement={menuAnchor}
        setAnchorElement={setMenuAnchor}
        isOnsite={false}
      />
    </CardContainer>
  );
};

const ImageWrapper = styled(CardImageWrapper)`
  height: 160px;
  background: ${({ theme }) => theme.colors.neutralLighter};
`;

export default OffsiteCard;
