import {
  EMAIL_AUTOMATION_TOOLS,
  EMAIL_DOWNLOAD_GIF,
  EMAIL_SINGLE_SEND,
} from 'app/src/constants/share.constants';
import { PROJECT_ID, PUBLISH, SECTION, SHARE } from 'app/src/constants/searchParams.constants';

export const getIsEmailSingleSendSelected = selectedType => selectedType === EMAIL_SINGLE_SEND;

export const getIsEmailAutomationToolsSelected = selectedType =>
  selectedType === EMAIL_AUTOMATION_TOOLS;

export const getIsEmailDownloadGifSelected = selectedType => selectedType === EMAIL_DOWNLOAD_GIF;

export const prepareShareParam = type => {
  return `?${SHARE}=${type}`;
};

export const preparePublishParam = type => {
  return `?${PUBLISH}=${type}`;
};

export const prepareBrandingParams = (projectId, section) => {
  return `?${SECTION}=${section}&${PROJECT_ID}=${projectId}`;
};
