import React from 'react';

const BrandedIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 0C14.0938 0 15 0.90625 15 2V14C15 15.125 14.0938 16 13 16H3C1.875 16 1 15.125 1 14V2C1 0.90625 1.875 0 3 0H13ZM13.5 14V2C13.5 1.75 13.25 1.5 13 1.5H3C2.71875 1.5 2.5 1.75 2.5 2V14C2.5 14.2812 2.71875 14.5 3 14.5H13C13.25 14.5 13.5 14.2812 13.5 14ZM11.5 12C11.75 12 12 12.25 12 12.5C12 12.7812 11.75 13 11.5 13H4.5C4.21875 13 4 12.7812 4 12.5C4 12.25 4.21875 12 4.5 12H11.5ZM8 10.25C5.90625 10.25 4.25 8.59375 4.25 6.5C4.25 4.4375 5.90625 2.75 8 2.75C10.0625 2.75 11.75 4.4375 11.75 6.5C11.75 8.59375 10.0625 10.25 8 10.25ZM10.6875 6C10.5312 5.15625 9.96875 4.4375 9.21875 4.0625C9.4375 4.59375 9.5625 5.28125 9.59375 6H10.6875ZM9.59375 7C9.5625 7.75 9.40625 8.40625 9.21875 8.96875C9.96875 8.5625 10.5312 7.875 10.6875 7H9.59375ZM8 3.84375C7.78125 4.09375 7.4375 4.84375 7.375 6H8.625C8.53125 4.84375 8.1875 4.09375 8 3.84375ZM8.625 7H7.375C7.4375 8.1875 7.78125 8.9375 8 9.1875C8.1875 8.9375 8.53125 8.1875 8.625 7ZM6.75 4.0625C6 4.4375 5.4375 5.15625 5.28125 6H6.375C6.40625 5.28125 6.53125 4.59375 6.75 4.0625ZM6.375 7H5.28125C5.4375 7.875 6 8.5625 6.75 8.96875C6.53125 8.40625 6.40625 7.75 6.375 7Z"
        fill="#2F3137"
      />
    </svg>
  );
};

export default BrandedIcon;
