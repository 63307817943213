export const PLAYER_EVENTS = {
  pageView: "pageView",
  sessionStart: "sessionStart",
  sessionEnd: "sessionEnd",
  videoPause: "videoPause",
  videoStart: "videoStart",
  videoResume: "videoResume",
  videoReplay: "videoReplay",
  clickCta: "clickCta",
  submitInput: "submitInput",
  collectInfo: "collectInfo",
  videoResponse: "videoResponse",
  audioResponse: "audioResponse",
  imageResponse: "imageResponse",
  tolstoyClick: "tolstoyClick",
  chapterSelected: "chapterSelected",
  chapterPickerOpened: "chapterPickerOpened",
  videoSeeked: "videoSeeked",
  shareClick: "shareClick",
  quizResult: "quizResult",
  autoplayStart: "autoplayStart",
};

export const PLAYER_EVENT_NAMES = {
  sessionStart: "tolstoyStarted",
  sessionEnd: "tolstoyReachedEnd",
  clickCta: "tolstoyAnswerClicked",
  submitInput: "tolstoyInputSubmit",
  collectInfo: "tolstoyLeadFormSubmit",
  videoResponse: "tolstoyVideoSubmit",
  audioResponse: "tolstoyAudioSubmit",
  imageResponse: "tolstoyImageSubmit",
  openGorgias: "tolstoyOpenGorgias",
  hideGorgias: "tolstoyHideGorgias",
  openIntercom: "tolstoyOpenIntercom",
  hideIntercom: "tolstoyHideIntercom",
  openTawkTo: "tolstoyOpenTawkTo",
  hideTawkTo: "tolstoyHideTawkTo",
  openLiveChat: "tolstoyOpenLiveChat",
  hideLiveChat: "tolstoyHideLiveChat",
  openHubSpot: "tolstoyOpenHubSpot",
  hideHubSpot: "tolstoyHideHubSpot",
  openHelpScout: "tolstoyOpenHelpScout",
  hideHelpScout: "tolstoyHideHelpScout",
  openDrift: "tolstoyOpenDrift",
  hideDrift: "tolstoyHideDrift",
  openZendesk: "tolstoyOpenZendesk",
  hideZendesk: "tolstoyHideZendesk",
};
