import React from 'react';
import { useTheme } from 'styled-components';

function PlusIcon({ fill, size }) {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || '11'}
      height={size || '11'}
      fill="none"
      viewBox="0 0 11 11"
    >
      <path
        fill={fill || theme.colors.white}
        d="M10.375 5.5a.57.57 0 00-.563-.563h-3.75v-3.75A.57.57 0 005.5.625a.555.555 0 00-.563.563v3.75h-3.75a.555.555 0 00-.562.562c0 .328.234.563.563.563h3.75v3.75c0 .328.234.562.562.562a.555.555 0 00.563-.563v-3.75h3.75a.555.555 0 00.562-.562z"
      />
    </svg>
  );
}

export default PlusIcon;
