import React from 'react';
import { TextH3Bold } from 'shared/react/components/basic/text/TextV2';
import InstallationMethodToggle from 'app/src/pages/project/pages/installation/components/shared/InstallationMethodToggle';
import styled from 'styled-components';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

type Props = {
  showManualInstallCode?: boolean;
  setShowManualInstallCode?: (showManualInstallCode: boolean) => void;
  isAccountInstallationCompleted?: boolean;
};

const InstallationTitle = ({
  showManualInstallCode,
  setShowManualInstallCode,
  isAccountInstallationCompleted,
}: Props) => {
  const onInstallationMethodToggle = () => {
    if (!setShowManualInstallCode) {
      return;
    }

    setShowManualInstallCode(!showManualInstallCode);
  };

  const isDisabled = !isAccountInstallationCompleted;

  return (
    <TitleWrapper>
      <TitleTextStyled isDisabled={isDisabled}>Set live</TitleTextStyled>
      {setShowManualInstallCode && (
        <InstallationMethodToggle
          isDisabled={isDisabled}
          onInstallationMethodToggle={onInstallationMethodToggle}
          showManualInstallCode={showManualInstallCode}
        />
      )}
    </TitleWrapper>
  );
};

const TitleWrapper = styled(HorizontalFlex)`
  justify-content: space-between;
  padding: 8px 16px 8px 0;
`;

const TitleTextStyled = styled(TextH3Bold)`
  color: ${({ isDisabled, theme }) =>
    isDisabled ? theme.colors.neutralGray : theme.colors.neutralBlack};
`;

export default InstallationTitle;
