import React from 'react';

const GuideIcon = () => (
  <svg width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 1.5h7.5c.398 0 .75.352.75.75v7.5c0 .422-.352.75-.75.75V12c.398 0 .75.352.75.75 0 .422-.352.75-.75.75H4a2.25 2.25 0 0 1-2.25-2.25v-7.5A2.25 2.25 0 0 1 4 1.5m0 9a.755.755 0 0 0-.75.75c0 .422.328.75.75.75h6v-1.5zm.75-5.625c0 .21.164.375.375.375h4.5A.385.385 0 0 0 10 4.875a.4.4 0 0 0-.375-.375h-4.5a.385.385 0 0 0-.375.375M5.125 6a.385.385 0 0 0-.375.375c0 .21.164.375.375.375h4.5A.385.385 0 0 0 10 6.375.4.4 0 0 0 9.625 6z"
      fill="#2F3137"
    />
  </svg>
);

export default GuideIcon;
