import { PLAYER_EVENTS } from 'player-events';
import { REACT_APP_API_BASE_URL } from 'player/src/config/player.config';
import HttpService from 'shared/react/services/httpService';
import { projectBuilder } from '../builders/project.builder';
import { projectStepBuilder } from '../builders/projectStep.builder';
import { TYPE_RECORDER } from '../constants/tolstoy.constants';
import { track } from '../tracker/Tracker';
import Utils from '../utils';
import { createProject, publishProject } from './project.actions';
import { uploadVideo } from './video.actions';

export const createVideoResponse = async ({
  uuid,
  blob,
  accountId,
  onProgress,
  type,
  onComplete,
  cancelledRequests,
  isSideBar,
  recordingType,
  replyProjectId,
  recordedFrom,
  body,
  appKey,
  mirror,
  answerKey,
}) => {
  const { fileId, filePath } = await uploadVideo({
    blob,
    accountId,
    onProgress,
    recordingType,
    body,
    appKey,
    mirror,
  });

  if (!cancelledRequests.includes(uuid)) {
    const data = {
      videoKey: filePath,
      text: fileId,
      type,
      answerKey,
    };

    if (isSideBar) {
      data.next = 'sidebar';
    }

    if (replyProjectId) {
      data.replyProjectId = replyProjectId;
    }

    if (recordedFrom) {
      data.location = recordedFrom;
    }

    track(PLAYER_EVENTS.videoResponse, data);
    onComplete(blob);
  }
};

const prepareProjectStepsData = ({ filePublicPath }) => {
  const step = projectStepBuilder({
    videoUrl: filePublicPath,
    defaultResponsesEnabled: false,
    answers: [],
    overlayText: 'New Message!',
  });

  return [step];
};

export const createProjectFromResponse = async ({
  token,
  blob,
  accountId,
  onProgress,
  onComplete,
  recordingType,
  body,
}) => {
  const { filePublicPath } = await uploadVideo({
    blob,
    accountId,
    onProgress,
    recordingType,
    body,
    mirror: true,
  });

  const project = projectBuilder({
    name: 'New Tolstoy',
    collectFields: [{ type: 'email', color: '#0400ff' }],
  });
  const steps = prepareProjectStepsData({ filePublicPath });

  const { id, gifUrl, tolstoy } = await createProject({
    project,
    steps,
    token,
  });
  await publishProject({ projectId: id, token });

  window.parent.postMessage(
    { name: 'tolstoyCreated', value: { tolstoy, gifUrl, pi: Utils.getPlayerId() } },
    '*'
  );

  onComplete();
};

export const getUploadByProjectType = type => {
  if (type === TYPE_RECORDER) {
    return createProjectFromResponse;
  }
  return createVideoResponse;
};

const isResponsesResInvalid = res => {
  return res === 'No responses found' || res === 'Session not found';
};

export const getResponses = async (sessionId, replyProjectId, optimistic = false) => {
  try {
    const res = await HttpService.get(
      `${REACT_APP_API_BASE_URL}/responses/actions/session?sessionId=${sessionId}&replyProjectId=${replyProjectId}&optimistic=${optimistic}`
    );

    if (isResponsesResInvalid(res?.data)) {
      return;
    }

    return res?.data;
  } catch (err) {
    Utils.logError('Responses error', err);
  }
};
