import React from 'react';
import styled from 'styled-components';
import { FEED_CART_QUANTITY_DATA_TYPE } from '../../../../constants/feedPreviewDataTypes.constants';
import { TextBody } from '../../Text';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';

const FeedCartItemsQuantity = ({ quantity }) => {
  const [, { t }] = useTranslation('feed.cart');

  if (!quantity || quantity < 1) {
    return null;
  }

  const text = quantity > 1 ? t('items') : t('item');

  return (
    <LayoutRoot data-type={FEED_CART_QUANTITY_DATA_TYPE}>
      {quantity} {text}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(TextBody)`
  font-weight: 500;
`;

export default FeedCartItemsQuantity;
