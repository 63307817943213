import React, { FC } from 'react';
import styled from 'styled-components';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextSmall } from 'shared/react/components/complex/Text';

type Props = {
  title: string;
  disabled?: boolean;
  helper?: string;
};

const EditorSubheader: FC<Props> = ({ title, helper, disabled }) => {
  return (
    <HeaderContainer disabled={disabled}>
      <SubheaderText>{title}</SubheaderText>
      {helper && <SubheaderHelper>{helper}</SubheaderHelper>}
    </HeaderContainer>
  );
};

const HeaderContainer = styled(Gap8HorizontalFlex)`
  opacity: ${({ disabled }) => (disabled ? '0.4' : '')};
`;

const SubheaderText = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.black};
  font-weight: 700;
`;

const SubheaderHelper = styled(SubheaderText)`
  color: ${({ theme }) => theme.colors.neutralGray};
`;

export default EditorSubheader;
