import React from 'react';
import AnalyticsTableCell from '../AnalyticsTableCell';
import { dateComparator, numberComparator, stringComparator } from '../analyticsTable.utils';
import { getShortMonthAndDate } from 'app/src/utils/date.utils';
import { TABLE_COLUMN_KEYS } from 'app/src/constants/cube.constants';

type Props = {
  hideColumns?: string[];
};

const PLACEHOLDER = '—';

export const HEADER_LEFT_ALIGN_CLASS_NAME = 'analytics-table-header-left-align';

export const getAnalyticsEmailRecipientsColumns = ({ hideColumns = [] }: Props) => {
  return [
    {
      field: TABLE_COLUMN_KEYS.firstSeen,
      headerName: 'First seen',
      flex: 1,
      renderCell: ({ value }) => {
        return <AnalyticsTableCell text={value ? getShortMonthAndDate(value) : PLACEHOLDER} />;
      },
      sortComparator: dateComparator,
    },
    {
      field: TABLE_COLUMN_KEYS.email,
      headerName: 'Email',
      headerClassName: HEADER_LEFT_ALIGN_CLASS_NAME,
      flex: 1,
      renderCell: ({ value }) => {
        return <AnalyticsTableCell align="left" text={value || PLACEHOLDER} />;
      },
      sortComparator: stringComparator,
    },
    {
      field: TABLE_COLUMN_KEYS.campaign,
      headerName: 'Campaign',
      headerClassName: HEADER_LEFT_ALIGN_CLASS_NAME,
      flex: 1,
      renderCell: ({ value }) => {
        return <AnalyticsTableCell align="left" text={value || PLACEHOLDER} />;
      },
      sortComparator: stringComparator,
    },
    {
      field: TABLE_COLUMN_KEYS.numOfEmailOpens,
      headerName: 'Opened',
      flex: 1,
      renderCell: ({ value }) => {
        return <AnalyticsTableCell text={Number(value).toLocaleString()} />;
      },
      sortComparator: numberComparator,
    },
    {
      field: TABLE_COLUMN_KEYS.numOfEmailClicks,
      headerName: 'Clicked',
      flex: 1,
      renderCell: ({ value }) => {
        return <AnalyticsTableCell text={Number(value).toLocaleString()} />;
      },
      sortComparator: numberComparator,
    },
    {
      field: TABLE_COLUMN_KEYS.numOfOrders,
      headerName: 'Converted',
      flex: 1,
      renderCell: ({ value }) => {
        return <AnalyticsTableCell text={Number(value).toLocaleString()} />;
      },
      sortComparator: numberComparator,
    },
  ].filter(column => !hideColumns.includes(column.field));
};
