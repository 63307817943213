import React from 'react';

function NotificationInteractedIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" viewBox="0 0 40 40">
      <rect width="40" height="40" fill="#F8F3E7" rx="10" />
      <path
        fill="#652401"
        d="M25.5 19c-.313 0-.563.094-.781.25-.157-.719-.75-1.25-1.469-1.25-.563 0-1.031.313-1.281.75-.157-.719-.75-1.25-1.469-1.25-.406 0-.75.156-1 .406V13.25c0-.688-.563-1.25-1.25-1.25-.719 0-1.25.563-1.25 1.25v10.094L15.25 21c-.25-.313-.625-.5-1.031-.5-.656 0-1.219.563-1.219 1.281 0 .25.063.5.25.719l2.844 3.813C16.875 27.375 18.156 28 19.5 28h3c2.469 0 4.5-2 4.5-4.5v-3c0-.813-.688-1.5-1.5-1.5zm-5 5.5c0 .281-.25.5-.5.5a.494.494 0 01-.5-.5v-3c0-.25.219-.5.5-.5.25 0 .5.25.5.5v3zm2 0c0 .281-.25.5-.5.5a.494.494 0 01-.5-.5v-3c0-.25.219-.5.5-.5.25 0 .5.25.5.5v3zm2 0c0 .281-.25.5-.5.5a.494.494 0 01-.5-.5v-3c0-.25.219-.5.5-.5.25 0 .5.25.5.5v3z"
      />
    </svg>
  );
}

export default NotificationInteractedIcon;
