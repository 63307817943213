import React, { FC } from 'react';

type Props = {
  className?: string;
};

const DeleteIconFilled: FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.914.922A.721.721 0 0 1 4.57.5h2.836c.282 0 .54.164.657.422l.187.328h2.25c.398 0 .75.352.75.75 0 .422-.352.75-.75.75h-9A.74.74 0 0 1 .75 2c0-.398.328-.75.75-.75h2.25l.164-.328ZM10.5 3.5l-.516 7.945c-.023.61-.515 1.055-1.125 1.055H3.117c-.61 0-1.101-.445-1.125-1.055L1.5 3.5h9Z" />
    </svg>
  );
};

export default DeleteIconFilled;
