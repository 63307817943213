import React, { useState } from 'react';
import BasicRadio from '../../../basic/BasicRadio';
import styled, { useTheme } from 'styled-components';
import { Gap8HorizontalFlex } from '../../flex_layouts/HorizontalFlex';
import { TextBodyBold, TextTiny } from 'shared/react/components/basic/text/TextV2';
import FeedButton from '../feed_buttons/FeedButton';
import Gap8VerticalFlex from '../../flex_layouts/Gap8VerticalFlex';

const REPORT_REASONS = {
  sexual: 'Sexual Content',
  violent: 'Violent or repulsive content',
  abusive: 'Harmful or abusive content',
  harassment: 'Harassment or bullying',
  dangerous: 'Harmfull or dangerous content',
  misinformation: 'Misinformation',
  childAbuse: 'Child abuse',
  promotesTorrorism: 'Promotes terrorism',
  spam: 'Spam or misleading',
  legalIssue: 'Legal issue',
  captionIssue: 'Caption Issue',
};

const ReportForm = ({ onCancelForm, onFormSubmit }) => {
  const [selectedOption, setSelectedOption] = useState({});
  const theme = useTheme();

  const handleFormItemClick = (key, description) => {
    setSelectedOption({ key, description });
  };

  const handleCancelSubmit = () => {
    onCancelForm();
  };

  const handleSubmit = () => {
    onFormSubmit(selectedOption);
  };

  return (
    <FormWrapper>
      <Title>Report Video</Title>
      <Description>
        Your report is anonymous, except if you’re reporting an intellectual property infringement.
        If someone is in immediate danger, call the local emergency services - don’t wait.
      </Description>
      {Object.entries(REPORT_REASONS).map(([key, value]) => {
        return (
          <Container key={key} onClick={() => handleFormItemClick(key, value)}>
            <BasicRadio checked={key === selectedOption?.key} checkedColor={theme.colors.primary} />
            <TextTiny>{value}</TextTiny>
          </Container>
        );
      })}
      <ButtonsWrapper>
        <NextButton isDisabled={!selectedOption?.key} buttonText="Next" onClick={handleSubmit} />
        <CancelButton
          buttonText="Cancel"
          onClick={handleCancelSubmit}
          buttonTextColor={theme.colors.black}
        />
      </ButtonsWrapper>
    </FormWrapper>
  );
};

const FormWrapper = styled(Gap8VerticalFlex)`
  overflow: scroll;
  flex: 1;
`;

const Container = styled(Gap8HorizontalFlex)`
  cursor: pointer;
  align-items: center;
  flex: 1;
`;

const Title = styled(TextBodyBold)`
  text-align: left;
`;

const Description = styled(TextTiny)`
  text-align: left;
`;

const NextButton = styled(FeedButton)`
  background-color: ${({ isDisabled, theme }) =>
    isDisabled ? theme.colors.neutralGray : theme.colors.primary};
`;

const CancelButton = styled(FeedButton)`
  background-color: ${({ theme }) => theme.colors.white};
`;

const ButtonsWrapper = styled.div`
  margin-top: 20px;
`;

export default ReportForm;
