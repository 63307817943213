import { useMemo } from 'react';
import { useInstagramApps } from 'app/src/context/AppsStore';
import { checkUgcPermissionsGranted } from 'src/utils/ugc/permissions';

export const useUgcApps = () => {
  const [{ instagramApps, loading }] = useInstagramApps();

  const ugcApps = useMemo(() => instagramApps.filter(checkUgcPermissionsGranted), [instagramApps]);

  return { ugcApps, loading };
};

export default useUgcApps;
