import React from 'react';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { FEED_SIDE_PANEL_PRODUCT_HEADER_TEST_ID } from 'shared/react/constants/dataTestIds.constants';
import {
  PRODUCT_DETAILS_DATA_TYPE,
  PRODUCT_HEADER_DATA_TYPE,
} from 'shared/react/constants/feedPreviewDataTypes.constants';
import FeedProductDetails from 'shared/react/components/complex/feed/feed_products/FeedProductDetails';
import FeedProductReviews from './feed_product_review/FeedProductReviews';
import useFeedProducts from 'shared/react/components/complex/context/hooks/useFeedProducts';
import { getHasOptions } from 'shared/react/components/complex/feed/feed_utils/feed.utils';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';
import useIsMilk from 'shared/react/hooks/useIsMilk';
import useIsFenty from 'shared/react/hooks/useIsFenty';
import useKeyboardNavigation from 'player/src/hooks/useKeyboardNavigation';
import HorizontalFlex from '../../flex_layouts/HorizontalFlex';
import FeedLikeButton from '../feed_controls/FeedLikeButton';
import useIsPrincessPolly from 'shared/react/hooks/useIsPrincessPolly';
import { getDesignCss } from 'shared/react/utils/addSharedCss';
import { TextBody } from 'shared/react/components/basic/text/TextV2';

const FeedProductContent = ({
  product,
  variantId,
  selectedVariant,
  isMultipleProductDetails,
  hideVariantSubtitle,
  onClick,
  onTitleClick,
  vodAssetId,
  ...props
}) => {
  const [, { getProductExtraData }] = useFeedProducts();
  const { onKeyDown, setNextRef } = useKeyboardNavigation();
  const { player, feedDesign } = useDesignSettingsContext();
  const { product_subtitle: productSubtitle } = getProductExtraData(product?.id) || {};
  const hasOptions = getHasOptions(product);
  const isMilk = useIsMilk();
  const isFenty = useIsFenty();
  const isPP = useIsPrincessPolly();
  const shouldShowVariantTitle =
    !hideVariantSubtitle && variantId && hasOptions && !!selectedVariant?.title;
  const subtitle = shouldShowVariantTitle ? selectedVariant?.title : productSubtitle;

  return (
    <LayoutRoot
      {...props}
      onClick={onClick}
      role="button"
      aria-label={isMultipleProductDetails && `View product: ${product.title}`}
      tabIndex="0"
      onKeyDown={onKeyDown}
      ref={setNextRef}
    >
      <ProductDetails data-type={PRODUCT_DETAILS_DATA_TYPE}>
        <TopContainer>
          <HeaderContainer>
            <ProductHeader
              $isMilk={isMilk}
              $design={feedDesign?.inFeedPDP?.productHeader}
              as="h2"
              data-type={PRODUCT_HEADER_DATA_TYPE}
              data-test-id={FEED_SIDE_PANEL_PRODUCT_HEADER_TEST_ID}
              headerColorText={player.feedCards.text.headerColorText}
              onClick={onTitleClick}
              $isFenty={isFenty}
              $isPP={isPP}
            >
              {product.title}
            </ProductHeader>
            {subtitle && (
              <ProductSubHeader $isPP={isPP} $isFenty={isFenty} $isMilk={isMilk}>
                {subtitle}
              </ProductSubHeader>
            )}
          </HeaderContainer>
          {!isMultipleProductDetails && (
            <FeedLikeButton product={product} vodAssetId={vodAssetId} backdrop={false} />
          )}
        </TopContainer>
        <ProductsContainer>
          <FeedProductReviews
            isMultipleProductDetails={isMultipleProductDetails}
            product={product}
          />
          <FeedProductDetails
            isMultipleProductDetails={isMultipleProductDetails}
            product={product}
            selectedVariant={selectedVariant}
          />
        </ProductsContainer>
      </ProductDetails>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  gap: 32px;
  cursor: pointer;
  height: 100%;
`;

const TopContainer = styled(HorizontalFlex)`
  justify-content: space-between;
  align-items: center;
`;

const ProductHeader = styled(TextBody)`
  text-align: left;
  color: ${({ headerColorText, theme }) => headerColorText || theme.colors.black};
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;

  ${({ $design }) => getDesignCss($design)}
  font-size: ${({ $isFenty, $isPP }) => ($isFenty ? '16px' : $isPP ? '18px' : '')};
  font-weight: ${({ $isFenty, $isPP }) => ($isFenty ? '700' : $isPP ? '500' : '')};
  line-height: ${({ $isMilk, $isFenty, $isPP }) =>
    $isPP ? '20px' : $isFenty ? '20px' : $isMilk ? '26px' : ''};
`;

const ProductSubHeader = styled(ProductHeader)`
  font-size: ${({ $isMilk }) => ($isMilk ? '16px' : '14px')};
  line-height: ${({ $isMilk }) => ($isMilk ? '20px' : '16px')};
  font-weight: 400;
`;

const ProductDetails = styled(Gap8VerticalFlex)`
  gap: 6px;
`;

const HeaderContainer = styled(VerticalFlex)`
  gap: 4px;
`;

const ProductsContainer = styled(ProductDetails)``;

export default FeedProductContent;
