import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import FeedMuteButton from 'shared/react/components/complex/feed/feed_controls/FeedMuteButton';
import { DESCRIPTION_SIDE_PANEL_TYPE } from 'shared/react/constants/feed.constants';
import FeedSmallDescriptionView from 'shared/react/components/complex/feed/feed_overlay_mobile/FeedSmallDescriptionView';
import FeedDurationBar from 'shared/react/components/complex/feed/feed_controls/FeedDurationBar';
import FeedNavigationArrows from 'shared/react/components/complex/feed/feed_overlay/FeedNavigationArrows';
import SmallProductWrapper from 'shared/react/components/complex/feed/feed_overlay_mobile/SmallProductWrapper';
import useFeedProducts from 'shared/react/components/complex/context/hooks/useFeedProducts';
import FeedShareButton from 'shared/react/components/complex/feed/feed_share/FeedShareButton';
import FeedCartButton from 'shared/react/components/complex/feed/feed_controls/FeedCartButton';
import FeedMenuButton from 'shared/react/components/complex/feed/feed_controls/FeedMenuButton';
import FeedTopTabs from 'shared/react/components/complex/feed/feed_controls/FeedTopTabs';
import Gap8VerticalFlex from '../../flex_layouts/Gap8VerticalFlex';
import useFeedFeatures from '../../context/hooks/useFeedFeatures';
import { FEATURE_WHITE_LABEL } from '../../../../constants/features.constants';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';
import CustomerLoginBanner from '../../customer_login/CustomerLoginBanner';
import FeedViewProductsButton from 'shared/react/components/complex/feed/feed_overlay_mobile/FeedViewProductsButton';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import useCustomerLogin from '../../context/hooks/useCustomerLogin';
import FeedSearchButton from 'shared/react/components/complex/feed/feed_search/FeedSearchButton';
import FeedBackButton from 'shared/react/components/complex/feed/feed_search/FeedBackButton';
import FeedAiTag from 'shared/react/components/complex/feed/feed_common/FeedAiTag';
import { TextBody } from 'shared/react/components/basic/text/TextV2';
import FeedCaptionsAndHandle from 'shared/react/components/complex/feed/feed_overlay_mobile/FeedCaptionsAndHandle';
import FeedCarouselNavigation from '../feed_overlay/FeedCarouselNavigation';

const MobileFeedNavigation = ({
  nextStepName,
  videoRef,
  audioRef,
  step,
  handlePlayPause,
  isMobilePreview,
  handleNavigateToStep,
  isSingleStepFeed,
  currentCaptions,
  scrollLeft,
  scrollRight,
  ...props
}) => {
  const [, { getProductsData }] = useFeedProducts();
  const [, { getFeatureSettingsByKey }] = useFeedFeatures();
  const [{ isShoppable, dynamic, showDescriptionIfNoProducts }] = useProjectConfig();
  const [{ shouldShowLoginBanner }] = useCustomerLogin();
  const [isProductsExpanded, setIsProductsExpanded] = useState(true);
  const [isDescriptionLineClamp, setDescriptionLineClamp] = useState(true);
  const { isMultipleProducts, product } = getProductsData(step);
  const isProductStep = isShoppable && (!!product || isMultipleProducts);
  const isDescriptionSidePanel = step.feedSidePanelType === DESCRIPTION_SIDE_PANEL_TYPE;
  const whiteLabelSettings = getFeatureSettingsByKey(FEATURE_WHITE_LABEL) || {};
  const { hidePoweredBy } = whiteLabelSettings;

  const getDynamicContent = () => {
    if (isProductStep) {
      return (
        <SmallProductWrapper
          step={step}
          isMobilePreview={isMobilePreview}
          hidePoweredBy
          isProductsExpanded={isProductsExpanded}
        />
      );
    }

    if (step.videoDescription && showDescriptionIfNoProducts) {
      return (
        <FeedSmallDescriptionView
          step={step}
          isDescriptionLineClamp={isDescriptionLineClamp}
          setDescriptionLineClamp={setDescriptionLineClamp}
        />
      );
    }

    return '';
  };

  const getContent = () => {
    if (dynamic) {
      return getDynamicContent();
    }

    if (isDescriptionSidePanel) {
      return (
        <FeedSmallDescriptionView
          step={step}
          isDescriptionLineClamp={isDescriptionLineClamp}
          setDescriptionLineClamp={setDescriptionLineClamp}
        />
      );
    }

    if (!isShoppable) {
      return null;
    }

    return (
      <SmallProductWrapper
        step={step}
        isMobilePreview={isMobilePreview}
        hidePoweredBy
        isProductsExpanded={isProductsExpanded}
      />
    );
  };

  const shouldHideViewProducts = !isShoppable || isDescriptionSidePanel || !step.products?.length;

  return (
    <LayoutRoot {...props}>
      <StyledFeedAiTag step={step} />
      <CustomerLoginBanner videoRef={videoRef} />
      <CustomFeedBackButton />
      <StyledFeedDurationBar videoRef={videoRef} />
      <FeedTopTabs videoRef={videoRef} audioRef={audioRef} currentStepKey={step.key} isMobile />

      <CustomFeedSearchButton
        videoRef={videoRef}
        currentStepKey={step.key}
        videoId={step.videoId}
        isMobile
      />
      <ControlsContainer
        isDescriptionLineClamp={isDescriptionLineClamp}
        isMultipleProducts={isMultipleProducts}
        hidePoweredBy={hidePoweredBy}
        onClick={handlePlayPause}
        isShoppable={isShoppable}
      >
        <SideButtonsContainer
          isMultipleProducts={isMultipleProducts}
          shouldShowLoginBanner={shouldShowLoginBanner}
        >
          <CustomFeedMenuButton
            videoRef={videoRef}
            audioRef={audioRef}
            isMobile={true}
            currentStepKey={step.key}
            videoId={step.videoId}
          />
          <CustomFeedCartButton
            videoRef={videoRef}
            audioRef={audioRef}
            isMobile={true}
            currentStepKey={step.key}
          />
          <CustomShareButton />
          <MuteViewProductsContainer shouldHideViewProducts={shouldHideViewProducts}>
            <FeedViewProductsButton
              shouldHide={shouldHideViewProducts}
              setIsProductsExpanded={setIsProductsExpanded}
              isProductsExpanded={isProductsExpanded}
            />
            <FeedCarouselNavigation
              scrollLeft={scrollLeft}
              scrollRight={scrollRight}
              step={step}
              isFullWidth={true}
            ></FeedCarouselNavigation>
            {isDescriptionLineClamp && (
              <FeedCaptionsAndHandle
                currentCaptions={currentCaptions}
                step={step}
                audioRef={audioRef}
              />
            )}
            <FeedMuteButton
              videoRef={videoRef}
              audioRef={audioRef}
              isMobilePreview={isMobilePreview}
              step={step}
            />
          </MuteViewProductsContainer>
        </SideButtonsContainer>
        {!isSingleStepFeed && (
          <FeedNavigationArrows
            handleNavigateToStep={handleNavigateToStep}
            isMobilePreview={isMobilePreview}
          />
        )}
        {getContent()}
      </ControlsContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  justify-content: space-between;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  z-index: 1;
  pointer-events: none !important;

  svg {
    width: 18px;
    height: 18px;
  }
`;

const StyledFeedDurationBar = styled(FeedDurationBar)`
  pointer-events: none;
  height: 8px;

  & .input-range {
    height: 100%;
  }

  & .input-range__slider {
    display: none;
  }

  & .input-range__track {
    background: ${({ theme }) => theme.getHexOpacity(theme.colors.white, 20)};
    height: 100%;
    border-radius: 0;
    transition: left 0.3s ease-out, width 0.3s ease-out;
  }

  & .input-range__track--active {
    background: ${({ theme }) => theme.colors.white};
    height: 100%;
    border-radius: 0 56px 56px 0;
  }

  &:hover .input-range__slider {
    display: none;
  }

  & .input-range__track--background {
    top: 0;
    margin: 0;
  }
`;

const StyledCaptions = styled(TextBody)`
  display: ${({ isHidden }) => (isHidden ? 'none' : 'unset')};
  background-color: rgba(0, 0, 0, 0.6);
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  width: 80%;
  margin: 0 20px;
`;

const FocusCss = css`
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    ${({ theme }) => theme.getHexOpacity(theme.colors.black, 70)} 70%
  );
  backdrop-filter: blur(1px);
`;

const ControlsContainer = styled(VerticalFlex)`
  gap: 12px;
  padding: ${({ isMultipleProducts, hidePoweredBy, isShoppable }) =>
    isMultipleProducts && isShoppable
      ? '8px 0 0 0'
      : `8px 8px ${hidePoweredBy && isShoppable ? '8px' : '32px'} 8px`};
  ${({ isDescriptionLineClamp }) => !isDescriptionLineClamp && FocusCss};
  overflow: ${({ isDescriptionLineClamp }) => (isDescriptionLineClamp ? '' : 'hidden')};
  flex-grow: 1;
  justify-content: flex-end;
  height: 100%;
  position: relative;
`;

const SideButtonsContainer = styled(Gap8VerticalFlex)`
  flex-grow: 1;
  z-index: 11;
  align-items: flex-end;
  padding: ${({ isMultipleProducts }) => (isMultipleProducts ? '0 8px' : '')};
  margin-top: ${({ shouldShowLoginBanner }) => (shouldShowLoginBanner ? '55px' : '')};
`;

const MuteViewProductsContainer = styled(HorizontalFlex)`
  justify-content: ${({ shouldHideViewProducts }) =>
    shouldHideViewProducts ? 'flex-end' : 'space-between'};
  width: 100%;
  margin-top: auto;
  align-items: flex-end;
`;

const CustomShareButton = styled(FeedShareButton)``;

const CustomFeedCartButton = styled(FeedCartButton)``;

const CustomFeedMenuButton = styled(FeedMenuButton)``;

const CustomFeedSearchButton = styled(FeedSearchButton)`
  top: 16px;
`;

const CustomFeedBackButton = styled(FeedBackButton)`
  position: absolute;
  top: 64px;
  left: 8px;
`;

const StyledFeedAiTag = styled(FeedAiTag)`
  top: 75px;
  left: 8px;
`;

const StyledFeedTabsWrapper = styled(FeedTopTabs)`
  justify-content: center;
  margin-top: 8px;
  padding: 8px;
  color: white;
  z-index: 13;
`;

export default MobileFeedNavigation;
