/* NOTE - This file is being used in other places (like widget) too. This
 * needs to be as lightweight as possible. So don't add any other
 * dependencies. */

import { animatedCss } from "./css";
import { ANIMATED } from "./themes";

const {
  addAnimation,
  addCaptionsCss,
  addStyleToTextElement,
  addToCaptionsContainerCss,
} = animatedCss;

class VideoAnimatedCaptions {
  constructor({ videoElement, captionsUrl, theme = ANIMATED, className = "" }) {
    this.className = className;
    this.captions = [];
    this.words = [];
    this.theme = theme;
    this.defaultColors = this.theme.defaultColors;
    this.videoElement = videoElement;
    this.captionsContainer = this.addCaptionsElement();
    this.fetchCaptions(captionsUrl).then(() => {
      this.getCaptionGroups();
      this.setCaptionInterval();
    });
  }

  hide() {
    this.captionsContainer.style.display = "none";
  }

  show() {
    this.captionsContainer.style.display = "flex";
  }

  getCaptionGroups = () => {
    let maxCaptionGroup = 3;
    const captionsGroup = [];
    let currentGroup = { items: [] };
    for (const caption of this.captions) {
      const randomNumber = captionsGroup.length % this.theme.maxNumberOfWords;
      if (caption.type === "punctuation") {
        continue;
      }

      currentGroup.items.push(caption);

      if (!currentGroup.startTime) {
        currentGroup.startTime = caption.start_time;
        currentGroup.color = this.defaultColors[randomNumber];
      }

      if (currentGroup.items.length === maxCaptionGroup) {
        currentGroup.endTime = caption.end_time;
        captionsGroup.push(currentGroup);
        maxCaptionGroup = randomNumber + 1;
        currentGroup = { items: [] };
      }
    }

    if (currentGroup.items.length) {
      currentGroup.endTime = this.videoElement.duration || 99999;
      captionsGroup.push(currentGroup);
    }

    this.captionGroups = captionsGroup;
  };

  setCaptionInterval = () => {
    if (!this.captionGroups?.length) {
      return;
    }

    const interval = setInterval(() => {
      if (!this.videoElement) {
        clearInterval(interval);
        return;
      }

      this.captionGroups.forEach((caption) => {
        const currentTime = this.videoElement.currentTime;
        if (caption.startTime > currentTime || caption.endTime < currentTime) {
          return;
        }

        this.styleWordIfNeeded(caption, currentTime);

        const currentSentence = caption.items
          .map((item) => item.alternatives[0].content)
          .join(" ");

        if (this.captionsText !== currentSentence) {
          this.setText(currentSentence, caption.color);
        }
      });
    }, 50);

    const stopInterval = () => {
      clearInterval(interval);
    };

    const startInterval = () => {
      this.setCaptionInterval();
    };

    if (this.videoElement.paused) {
      stopInterval();
    }

    this.videoElement.addEventListener("pause", stopInterval, { once: true });
    this.videoElement.addEventListener("play", startInterval, { once: true });
  };

  styleWordIfNeeded = (caption, currentTime) => {
    caption.items.forEach((item, i) => {
      if (!this.words[i]) {
        return;
      }

      if (item.start_time <= currentTime && item.end_time >= currentTime) {
        this.words[i].style.color = caption.color;
        return;
      }

      this.words[i].style.color = this.theme.text.color;
    });
  };

  fetchCaptions = async (src) => {
    try {
      if (this.captions.length > 0) {
        return;
      }
      const subtitleJson = await fetch(src.replace(".vtt", ".json"));
      const subtitleData = await subtitleJson.json();
      this.captions = subtitleData.results.items;
    } catch (err) {
      // no catch
    }
  };

  addCaptionsElement = () => {
    const captionsContainer = document.createElement("div");
    captionsContainer.className = this.className;

    this.videoElement.parentElement.appendChild(captionsContainer);
    addCaptionsCss(captionsContainer);

    this.captionsTextContainer = document.createElement("div");
    addToCaptionsContainerCss(this.captionsTextContainer, this.videoElement);

    captionsContainer.append(this.captionsTextContainer);
    addAnimation(this.captionsTextContainer, this.videoElement, this.theme);

    return captionsContainer;
  };

  createTextElement = (text, i, color) => {
    const textElement = document.createElement("span");
    textElement.innerText = text;
    addStyleToTextElement(textElement, i, color, this.theme);
    return textElement;
  };

  setText = (text, color) => {
    this.captionsTextContainer.innerHTML = "";
    this.words = [];
    if (!text) {
      return;
    }

    this.captionsText = text;
    let i = 0;
    for (const word of text.split(" ")) {
      const textElement = this.createTextElement(word, i, color);
      this.captionsTextContainer.appendChild(textElement);
      this.words.push(textElement);
      i++;
    }
  };
}

export { VideoAnimatedCaptions };
