import { useEffect, useState } from 'react';
import { FEATURE_DYNAMIC_FEED } from 'src/constants/appFeatures.constants';
import { useFeatureActions } from 'src/context/FeaturesStore';
import { useVodConnection } from 'src/context/VodConnectionStore';
import { project as ProjectType, VodConnectionType } from 'src/types/entities';
import { getVodAssetIdsFromDynamicRules } from 'app/src/hooks/use-dynamic-video-ids-by-project/checkRules';
import useVodSourceVodConnections from 'src/hooks/use-dynamic-video-ids-by-project/useVodSourceVodConnections';
import useVodTrendingVodConnections from 'app/src/hooks/use-dynamic-video-ids-by-project/useVodTrendingVodConnections';
import { useVodAssets } from 'src/context/VideosStore';
import useHostProjectParams from 'src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/hooks/useHostProjectParams';
import getVodTypeVodConnections from 'app/src/hooks/use-dynamic-video-ids-by-project/getVodTypeVodConnections';

const PERMITTED_VOD_CONNECTION_TYPES = [
  VodConnectionType.vodLabelId,
  VodConnectionType.vodProjectId,
];

const useDynamicVideoIdsByProject = (project: ProjectType, ruleGroup, isDynamic) => {
  const [videoIds, setVideoIds] = useState([]);
  const [videos] = useVodAssets();
  const { getFeatureEnabled } = useFeatureActions();
  const [{ vodConnections }, { getVodConnectionByProductId }] = useVodConnection();
  const isDynamicEnabled = getFeatureEnabled(FEATURE_DYNAMIC_FEED);
  const getVodSourceConnections = useVodSourceVodConnections();
  const getVodTrendingConnections = useVodTrendingVodConnections();
  const { isTargetSpecificProductPage, targetExternalProductId } = useHostProjectParams();

  const getVodAssetIdsFromConnections = (project: ProjectType, ruleGroup) => {
    if (isTargetSpecificProductPage) {
      const productVodConnections = getVodConnectionByProductId(targetExternalProductId);

      const filteredProductVodConnections = productVodConnections.filter(({ appUrl }) => {
        return appUrl === project?.appUrl;
      });

      return filteredProductVodConnections.map(({ vodAssetId }) => vodAssetId);
    }

    const filteredVodConnections = vodConnections.filter(({ type, appUrl }) => {
      if (PERMITTED_VOD_CONNECTION_TYPES.includes(type)) {
        return true;
      }

      return appUrl === project?.appUrl;
    });

    const vodSourceConnections = getVodSourceConnections(ruleGroup);
    const vodTypeVodConnections = getVodTypeVodConnections(ruleGroup, videos);
    const vodTrendingConnections = getVodTrendingConnections(ruleGroup);

    if (ruleGroup?.enabled) {
      return getVodAssetIdsFromDynamicRules({
        project,
        ruleGroup,
        videos,
        vodConnections: [
          ...filteredVodConnections,
          ...vodSourceConnections,
          ...vodTypeVodConnections,
          ...vodTrendingConnections,
        ],
      });
    }

    return filteredVodConnections.map(({ vodAssetId }) => vodAssetId);
  };

  const getVideoIdsByProject = (project: ProjectType, ruleGroup) => {
    if (!project.dynamic) {
      return project.steps.items.map(step => step.videoId);
    }

    return getVodAssetIdsFromConnections(project, ruleGroup);
  };

  const refreshVideoIds = () => {
    const videoIds = getVideoIdsByProject(project, ruleGroup);
    setVideoIds(videoIds);
  };

  const shouldNotGetVideos = (!project?.dynamic && !isDynamic) || !isDynamicEnabled;

  useEffect(() => {
    if (shouldNotGetVideos) {
      return;
    }

    refreshVideoIds();
  }, [
    ruleGroup,
    project,
    vodConnections,
    shouldNotGetVideos,
    isTargetSpecificProductPage,
    targetExternalProductId,
  ]);

  return { videoIds, getVideoIdsByProject };
};

export default useDynamicVideoIdsByProject;
