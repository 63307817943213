import React from 'react';
import styled from 'styled-components';
import { TextBody } from 'shared/react/components/complex/Text';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import PublishPreviewContainer from 'app/src/pages/modals/publish/previews/PublishPreviewContainer';
import RadioButtonV2 from 'src/basic_components/radio-button/RadioButtonV2';

const PublishPreview = ({ header, preview, onClick, liveButton, isSelected = false }) => {
  return (
    <LayoutRoot onClick={onClick}>
      <HeaderContainer>
        <RadioButtonV2 checked={isSelected} />
        <Header>{header}</Header>
        {liveButton}
      </HeaderContainer>

      <PublishPreviewContainer isSelected={isSelected} isScaleOnHover>
        {preview}
      </PublishPreviewContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)`
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-height: 312px;
  width: 160px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    height: auto;
  }

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    width: auto;
  }
`;

const Header = styled(TextBody)`
  font-weight: 800;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
  }
`;

const HeaderContainer = styled(HorizontalFlex)`
  gap: 12px;
`;

export default PublishPreview;
