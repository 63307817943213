import React from 'react';
import styled from 'styled-components';
import BillingPackageRow from 'app/src/complex_components/billings/BillingPackagesV2/BillingPackageRow';
import BillingPackageFeatures from 'app/src/complex_components/billings/BillingPackagesV2/BillingPackageFeatures';
import BillingPackageButton from 'app/src/complex_components/billings/BillingPackagesV2/BillingPackageButton';
import { usePackages } from 'app/src/context/PackagesStore';
import BillingPackageSeparator from 'app/src/complex_components/billings/BillingPackagesV2/BillingPackageSeparator';
import BillingPackageBasePrice from 'app/src/complex_components/billings/BillingPackagesV2/BillingPackageBasePrice';
import BillingPackageViewsPrice from 'app/src/complex_components/billings/BillingPackagesV2/BillingPackageViewsPrice';
import { useIsMobile, useIsTablet } from 'shared/react/hooks/useIsMobile';
import Utils from 'app/src/utils';

export const getPackageDetails = ({ id, name, type, details = {} }) => {
  return { id, name, type, ...details };
};

const splitPackageDetails = ({ packages, numOfColumns }) => {
  const packageDetails = packages?.map(getPackageDetails);

  return Utils.splitArrayIntoChunks({ array: packageDetails, chunkSize: numOfColumns });
};

const getNumOfColumns = ({ isTablet, isMobile, packages }) => {
  if (isMobile) {
    return 1;
  }

  if (isTablet) {
    return Math.min(packages.length, 2);
  }

  return Math.min(packages.length, 4);
};

const BillingPackagesV2 = () => {
  const [{ packages }] = usePackages();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  if (!packages?.length) {
    return null;
  }

  const numOfColumns = getNumOfColumns({ isTablet, isMobile, packages });
  const packageDetails = splitPackageDetails({ packages, numOfColumns });

  return (
    <BillingPackagesV2Container>
      {packageDetails?.map((details, index) => (
        <StyledBillingPackagesV2 key={index} numOfColumns={details.length}>
          {PACKAGE_ROWS.map(({ key, Component }) => (
            <BillingPackageRow key={key} type={key} Component={Component} packages={details} />
          ))}
        </StyledBillingPackagesV2>
      ))}
    </BillingPackagesV2Container>
  );
};

export default BillingPackagesV2;

const BillingPackagesV2Container = styled.div`
  padding-left: 24px;
  padding-right: 24px;
`;

export const BannerDiv = styled.div`
  position: absolute;
  visibility: hidden;
`;

const StyledBillingPackagesV2 = styled.div`
  display: grid;
  grid-template-columns: ${({ numOfColumns }) => `repeat(${numOfColumns}, 1fr)`};
  grid-column-gap: 24px;
  padding-top: 0px;

  &:only-child {
    padding-top: 20px;
  }

  & + div {
    padding-top: 20px;
  }

  & + div:has(${BannerDiv}) {
    padding-top: 50px;
  }
`;

export const PackagesRow = styled.div`
  display: contents;

  & > div {
    background-color: ${({ theme }) => theme.colors.white};
    padding-left: 16px;
    padding-right: 16px;
  }

  &:first-child > div {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding-top: 16px;
  }

  &:last-child > div {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    padding-bottom: 16px;
  }

  & > ${BannerDiv} + div {
    border-left: 2px solid ${({ theme }) => theme.colors.blue7};
    border-right: 2px solid ${({ theme }) => theme.colors.blue7};
  }

  &:first-child > ${BannerDiv} + div {
    position: relative;
    border-top: 2px solid ${({ theme }) => theme.colors.blue7};
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    &:before {
      content: 'Early supporters discount, ends Jan 1st';
      position: absolute;
      transform: translateY(-100%);
      height: 30px;
      top: 0;
      display: grid;
      place-content: center;
      background: ${({ theme }) => theme.colors.blue7};
      border-radius: 8px 8px 0px 0px;
      color: ${({ theme }) => theme.colors.white};
      left: -2px;
      right: -2px;
      text-align: center;
      font-weight: 800;
      font-size: 10px;
      line-height: 14px;
    }
  }

  &:last-child > ${BannerDiv} + div {
    border-bottom: 2px solid ${({ theme }) => theme.colors.blue7};
  }
`;

const PackageTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.black1};
  padding-bottom: 19px;
`;

const PackageDescription = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray36};
  padding-bottom: 35px;
`;

const PackageFeaturesTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black1};
  padding-bottom: 12px;
`;

const PACKAGE_ROWS = [
  { key: 'title', Component: PackageTitle },
  { key: 'description', Component: PackageDescription },
  { key: 'basePrice', Component: BillingPackageBasePrice },
  { key: 'separator', Component: BillingPackageSeparator },
  { key: 'viewsPrice', Component: BillingPackageViewsPrice },
  { key: 'buttonText', Component: BillingPackageButton },
  { key: 'featuresTitle', Component: PackageFeaturesTitle },
  { key: 'features', Component: BillingPackageFeatures },
];
