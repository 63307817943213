import React from 'react';

function NotificationConversionIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" viewBox="0 0 40 40">
      <rect width="40" height="40" fill="#EEECFA" rx="10" />
      <path
        fill="#6351D0"
        d="M26 17h-2.25v-1.25A3.761 3.761 0 0020 12a3.74 3.74 0 00-3.75 3.75V17H14c-.563 0-1 .469-1 1v7.5c0 1.406 1.094 2.5 2.5 2.5h9c1.375 0 2.5-1.094 2.5-2.5V18c0-.531-.469-1-1-1zm-8.25-1.25c0-1.219 1-2.25 2.25-2.25 1.219 0 2.25 1.031 2.25 2.25V17h-4.5v-1.25zm-.75 4a.722.722 0 01-.75-.75.74.74 0 01.75-.75.76.76 0 01.75.75.74.74 0 01-.75.75zm6 0a.722.722 0 01-.75-.75.74.74 0 01.75-.75.76.76 0 01.75.75.74.74 0 01-.75.75z"
      />
    </svg>
  );
}

export default NotificationConversionIcon;
