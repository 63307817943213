import React from 'react';
import styled from 'styled-components';
import OnOffToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/OnOffToggleItem';
import EditorHeader from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorHeader';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import {
  STORIES_BORDER_COLOR_PROPERTY_KEY,
  STORIES_BORDER_ENABLED_PROPERTY_KEY,
  STORIES_BORDER_OPACITY_PROPERTY_KEY,
  STORIES_BORDER_TYPE_PROPERTY_KEY,
  STORIES_BORDER_TYPES,
  STORIES_BORDER_WIDTH_PROPERTY_KEY,
} from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import EditorSliderItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorSliderItem';
import EditorColorInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorColorInputItem';
import EditorButtonGroupItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorButtonGroupItem';
import Utils from 'app/src/utils';
import EditorMainContainer from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorMainContainer';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const FeedStoriesBorderEditor = () => {
  const {
    customizationSettings: {
      widgetSettings: {
        storiesBorderEnabled,
        storiesBorderWidth,
        storiesBorderColor,
        storiesBorderOpacity,
        storiesBorderType,
      },
    },
    setWidgetSettingsProperty,
  } = useLookAndFeelContext();

  const onEnableBorder = () => {
    setWidgetSettingsProperty(STORIES_BORDER_ENABLED_PROPERTY_KEY, !storiesBorderEnabled);
  };

  const onBorderWidthChange = (event, value) => {
    setWidgetSettingsProperty(STORIES_BORDER_WIDTH_PROPERTY_KEY, value);
  };

  const onColorChange = value => {
    setWidgetSettingsProperty(STORIES_BORDER_COLOR_PROPERTY_KEY, value);
  };

  const onOpacityChange = (event, value) => {
    setWidgetSettingsProperty(STORIES_BORDER_OPACITY_PROPERTY_KEY, value);
  };

  const getBorderTypeButton = borderType => {
    return {
      text: Utils.upperCaseFirstChar(borderType),
      isEnabled: storiesBorderType === borderType,
      onClick: () => {
        setWidgetSettingsProperty(STORIES_BORDER_TYPE_PROPERTY_KEY, borderType);
      },
    };
  };

  const borderTypeButtons = [
    getBorderTypeButton(STORIES_BORDER_TYPES.solid),
    getBorderTypeButton(STORIES_BORDER_TYPES.dashed),
  ];

  return (
    <LayoutRoot>
      <EditorHeader title="Border" isWhite={!storiesBorderEnabled}>
        <OnOffToggleItem checked={storiesBorderEnabled} onChange={onEnableBorder} />
      </EditorHeader>
      <EditorMainContainer shouldHide={!storiesBorderEnabled}>
        <EditorSliderItem
          onChange={onBorderWidthChange}
          text="Border thickness"
          value={storiesBorderWidth}
          min={1}
          max={10}
        />
        <EditorColorInputItem
          text="Border color"
          color={storiesBorderColor}
          onChange={onColorChange}
        />
        <EditorButtonGroupItem text="Border type" buttons={borderTypeButtons} />
        <EditorSliderItem
          onChange={onOpacityChange}
          text="Transparency"
          value={storiesBorderOpacity}
          min={0}
          step={0.05}
          max={1}
        />
      </EditorMainContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

export default FeedStoriesBorderEditor;
