import { PAYMENT_ROLE_PRO_KEY } from 'app/src/constants/teamMembers.constants';

export const prepareTeamMembersToUpdateMap = teamMembersToUpdate => {
  const teamMembersToUpdateMap = {};

  for (const { id } of teamMembersToUpdate) {
    teamMembersToUpdateMap[id] = PAYMENT_ROLE_PRO_KEY;
  }

  return teamMembersToUpdateMap;
};
