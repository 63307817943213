import React from 'react';
import styled, { useTheme } from 'styled-components';
import PublishMethod from './PublishMethod';
import { PUBLISH_METHODS } from 'shared/react/constants/tolstoy.constants';
import ThreeVerticalDotsIcon from 'app/src/images/dashboard_v2/ThreeVerticalDotsIcon';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';

const PublishMethodBadge = ({ publishMethod, showDots, ...props }) => {
  const theme = useTheme();
  if (!publishMethod || !PUBLISH_METHODS[publishMethod]) {
    return null;
  }

  return (
    <LayoutRoot {...props}>
      <PublishMethod publishMethod={publishMethod} fontSize={12} dotSize={18} />
      {showDots && (
        <IconContainer>
          <ThreeVerticalDotsIcon fill={theme.colors.gray2} />
        </IconContainer>
      )}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlexWrap)`
  gap: 4px;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : '')};
  cursor: ${({ disabled }) => (disabled ? '' : 'pointer')};
`;

const IconContainer = styled(HorizontalFlexCentered)`
  width: 12px;
  height: 12px;
`;

export default PublishMethodBadge;
