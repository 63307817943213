import React from 'react';

const Arrow = props => (
  <svg
    width={134}
    height={130}
    viewBox="0 0 134 130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m129.476 129.108 3.633-3.514-3.476-3.594"
      stroke="#090A0B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.106 1c0 39.905 39.811 125.469 129.011 124.524"
      stroke="#090A0B"
      strokeLinecap="round"
      strokeDasharray="2 2"
    />
  </svg>
);

export default Arrow;
