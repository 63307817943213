import React from 'react';

const ProductIcon = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.75 5.28125C16.4062 6.9375 16.4062 9.59375 14.75 11.25L11.25 14.7812C10.9688 15.0938 10.5 15.0938 10.1875 14.8125C9.90625 14.5 9.90625 14.0312 10.1875 13.75L13.6875 10.1875C14.75 9.125 14.75 7.40625 13.6875 6.34375L9.6875 2.28125C9.40625 2 9.40625 1.53125 9.71875 1.21875C10 0.9375 10.4688 0.9375 10.75 1.25L14.75 5.28125ZM4.5 4.5C4.5 5.0625 4.03125 5.5 3.5 5.5C2.9375 5.5 2.5 5.0625 2.5 4.5C2.5 3.96875 2.9375 3.5 3.5 3.5C4.03125 3.5 4.5 3.96875 4.5 4.5ZM12.8125 6.84375C13.5938 7.625 13.5938 8.90625 12.8125 9.6875L8.65625 13.8438C7.875 14.625 6.59375 14.625 5.8125 13.8438L0.5625 8.59375C0.1875 8.21875 0 7.71875 0 7.1875V2.5C0 1.6875 0.65625 1 1.5 1H6.15625C6.6875 1 7.1875 1.21875 7.5625 1.59375L12.8125 6.84375ZM1.5 2.5V7.1875C1.5 7.3125 1.53125 7.4375 1.625 7.53125L6.875 12.7812C7.0625 13 7.40625 13 7.59375 12.7812L11.75 8.625C11.9688 8.4375 11.9688 8.09375 11.75 7.90625L6.5 2.65625C6.40625 2.5625 6.28125 2.5 6.15625 2.5H1.5Z"
        fill="#2F3137"
      />
    </svg>
  );
};

export default ProductIcon;
