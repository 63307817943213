import React, { useState } from 'react';
import styled from 'styled-components';
import Stepper from 'app/src/complex_components/Stepper';
import CopyCodeButtonPricing from 'app/src/complex_components/pricing/CopyCodeButtonPricing';
import CodeContainerPricing from 'app/src/complex_components/pricing/CodeContainerPricing';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextSmall } from 'shared/react/components/complex/Text';
import InputWithIcon from 'app/src/basic_components/InputWithIcon';
import EarthIcon from 'app/src/images/EarthIcon';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';
import Utils from 'shared/react/utils/utils';

const SET_YOUR_SITES_HEADER = "Set your site's header";
const INSPECT_HEADER_COPY_HEADER_ID =
  'Inspect header → Copy header’s element ID → Paste ID in input';
const ENTER_YOUR_SITES_HEADER_ID = "Enter your site's header ID";

const PublishVideoPageNonShopifyStepper = ({ getCode, onClickCopyCode, setHeaderId }) => {
  const [isHeaderIdError, setIsHeaderIdError] = useState(false);
  const [, { setErrorSnackbar }] = useSnackBar();

  const onHeaderIdChange = e => {
    const value = e.target.value;
    const isValidId = Utils.isValidHTMLElementId(value);
    setIsHeaderIdError(!isValidId);

    setHeaderId(value);
  };

  const handleClickCopyCode = () => {
    if (isHeaderIdError) {
      setErrorSnackbar('Invalid site header ID. Please edit and try again.');
      return;
    }

    onClickCopyCode();
  };

  return (
    <>
      <Stepper
        stepComponents={[
          <Step key={1} removeStepHeader={true}>
            <StepHeader>{SET_YOUR_SITES_HEADER}</StepHeader>
            <StepContent>
              <TextSmall>{INSPECT_HEADER_COPY_HEADER_ID}</TextSmall>
              <StyledInputWithIcon
                placeholder={ENTER_YOUR_SITES_HEADER_ID}
                isError={isHeaderIdError}
                onChange={onHeaderIdChange}
                icon={<EarthIcon />}
              />
            </StepContent>
          </Step>,
          <Step key={2} removeStepHeader={true}>
            <StepHeader>Copy code and paste on an empty page</StepHeader>
            <StepContent>
              <CustomCodeContainer>
                <CodeContainerPricing getCode={getCode} />
                <CopyCodeButtonPricing
                  onClick={handleClickCopyCode}
                  location="VideoPage"
                  tooltipHeader="video page"
                />
              </CustomCodeContainer>
            </StepContent>
          </Step>,
        ]}
      />
    </>
  );
};

const Step = styled(Gap8VerticalFlex)`
  font-weight: 800;
  font-size: 16px;
`;

const StepHeader = styled(Gap8HorizontalFlexWrap)`
  align-items: center;
`;

const StepContent = styled(Gap8VerticalFlex)`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

const CustomCodeContainer = styled(Gap8HorizontalFlex)`
  align-items: center;
  & > * {
    width: auto;
  }
`;

const StyledInputWithIcon = styled(InputWithIcon)`
  min-width: 275px;
  border: 1px solid ${({ isError, theme }) => (isError ? theme.colors.error : theme.colors.gray3)};
  border-radius: 10px;

  & input {
    border: none;
  }
`;

export default PublishVideoPageNonShopifyStepper;
