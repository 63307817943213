import React from 'react';
import styled from 'styled-components';
import CalendlyIcon from 'app/src/images/calendars/CalendlyIcon';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import { TextSmall } from 'shared/react/components/complex/Text';

function CalendarInviteContent() {
  return (
    <LayoutRoot>
      <CalendlyIcon />
      <TextSmall>Calendar invitation scheduled!</TextSmall>
    </LayoutRoot>
  );
}

export default CalendarInviteContent;

const LayoutRoot = styled(HorizontalFlexWrap)`
  gap: 8px;
`;
