import React from 'react';

import Colors from 'shared/react/constants/colors';
import EditorSubheader from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorSubheader';
import EditorSliderItem from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorSliderItem';
import EditorButtonGroupItem from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorButtonGroupItem';

type Props = {
  settings?: any;
  features?: any;
  disabled?: boolean;
  onChange?: any;
};

const CARD_TEXT_COLOR_LIST = [
  {
    text: 'Black',
    value: Colors.black,
  },
  {
    text: 'White',
    value: Colors.white,
  },
];

const ELLIPSIS_AFTER_LIST = [
  {
    text: '1 Line',
    value: 1,
  },
  {
    text: '2 Lines',
    value: 2,
  },
];

const InvideoProductCardEditor = ({
  settings = {},
  disabled = false,
  onChange = () => {},
}: Props) => {
  const { opacity, textColor } = settings;
  const updateSettings = update => onChange({ ...settings, ...update });

  const handleCardOpacityChange = (event, opacity) => updateSettings({ opacity });
  const handleCardTextColorChange = textColor => updateSettings({ textColor });
  const handleEllipsisProductTitleAfterChange = productTitleNumOfLines =>
    updateSettings({ productTitleNumOfLines });
  const handleEllipsisVariantSubtitleAfterChange = variantSubtitleNumOfLines =>
    updateSettings({ variantSubtitleNumOfLines });

  const getCardTextColorButton = ({ text, value }) => ({
    text,
    isEnabled: textColor === value,
    onClick: () => handleCardTextColorChange(value),
  });
  const cardTextColorButtons = CARD_TEXT_COLOR_LIST.map(getCardTextColorButton);

  const getEllipsisProductTitleAfterButton = ({ text, value }) => ({
    text,
    isEnabled: settings.productTitleNumOfLines === value,
    onClick: () => handleEllipsisProductTitleAfterChange(value),
  });
  const ellipsisProductTitleAfterButtons = ELLIPSIS_AFTER_LIST.map(
    getEllipsisProductTitleAfterButton
  );

  const getEllipsisVariantSubtitleAfterButton = ({ text, value }) => ({
    text,
    isEnabled: settings.variantSubtitleNumOfLines === value,
    onClick: () => handleEllipsisVariantSubtitleAfterChange(value),
  });
  const ellipsisVariantSubtitleAfterButtons = ELLIPSIS_AFTER_LIST.map(
    getEllipsisVariantSubtitleAfterButton
  );

  return (
    <>
      <EditorSubheader title="In-video product card" />

      <EditorButtonGroupItem
        text="Clip product title after"
        buttons={ellipsisProductTitleAfterButtons}
        disabled={disabled}
      />

      <EditorButtonGroupItem
        text="Clip variant subtitle after"
        buttons={ellipsisVariantSubtitleAfterButtons}
        disabled={disabled}
      />

      <EditorSliderItem
        onChange={handleCardOpacityChange}
        text="Card opacity"
        value={opacity}
        min={0}
        max={1}
        step={0.01}
        disabled={disabled}
      />
    </>
  );
};

export default InvideoProductCardEditor;
