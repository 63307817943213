import FallbackImage from 'shared/react/images/fallback_image.png';

export default {
  id: 'id',
  title: 'Your product',
  variants: [
    {
      productId: '7503091859668',
      id: 42479517630676,
      price: '100.00',
      selectedOptions: {
        1: 'ex. size 8',
        2: 'ex. Blue',
        3: null,
      },
      isVariantAvailableForSale: true,
    },
  ],
  options: {
    1: {
      values: ['ex. size 8'],
      selectedValue: 'ex. size 8',
      name: 'Option 1',
    },
    2: {
      values: ['ex. Blue'],
      selectedValue: 'ex. Blue',
      name: 'Option 2',
    },
  },
  images: [
    {
      id: 36457568796884,
      src: FallbackImage,
    },
    {
      id: 36457568796886,
      src: FallbackImage,
    },
    {
      id: 36457568796885,
      src: FallbackImage,
    },
  ],
  currencyCode: 'USD',
  currencySymbol: '$',
};
