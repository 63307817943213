import React from 'react';
import EditorSubheader from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorSubheader';
import EditorToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorToggleItem';
import EditorTextInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorTextInputItem';

type Props = {
  settings?: any;
  features?: any;
  disabled?: boolean;
  onChange?: any;
};

const AdvancedSettingsEditor = ({
  settings = {},
  features = {},
  disabled = false,
  onChange = () => {},
}: Props) => {
  const { showAfterpay, hiddenVariantIds = '', moveToPDPOnTitleClick = false } = settings;
  const { isVariantsCustomizationEnabled } = features;
  const updateSettings = update => onChange({ ...settings, ...update });
  const handleShowAfterpayUpdate = setting => updateSettings({ showAfterpay: setting });
  const handleMoveToPDPOnTitleClick = setting => updateSettings({ moveToPDPOnTitleClick: setting });
  const handleHiddenVariantIdsChange = hiddenVariantIds => updateSettings({ hiddenVariantIds });

  return (
    <>
      <EditorSubheader title="Advanced" />

      {isVariantsCustomizationEnabled && (
        <EditorTextInputItem
          text="Variants to hide"
          disabled={disabled}
          placeholder="Comma-separated variant ids"
          value={hiddenVariantIds}
          onChange={handleHiddenVariantIdsChange}
          maxWidth="303px"
          maxLength={10000}
        />
      )}
      <EditorToggleItem
        disabled={disabled}
        text="Move to pdp on product click"
        checked={moveToPDPOnTitleClick}
        onChange={handleMoveToPDPOnTitleClick}
      />
      <EditorToggleItem
        disabled={disabled}
        text="Show Afterpay"
        checked={showAfterpay}
        onChange={handleShowAfterpayUpdate}
      />
    </>
  );
};

export default AdvancedSettingsEditor;
