import { getIsFeed, useProjects } from 'app/src/context/ProjectsStore';
import { useParams } from 'react-router-dom';
import Utils from '../utils';

const useProjectType = id => {
  const { projectId = id } = useParams();
  const [{ project }] = useProjects({ projectId });

  const isFeed = getIsFeed(project);
  const projectDisplayType = isFeed ? 'feed' : 'Tolstoy';

  return {
    projectDisplayType,
    projectDisplayTypeTitle: Utils.upperCaseFirstChar(projectDisplayType),
  };
};

export default useProjectType;
