import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TextH5, TextSmall } from 'shared/react/components/complex/Text';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import CalendarField from './CalendarField';
import PencilButton from 'app/src/complex_components/PencilButton';
import StandardModal from '../../modals/StandardModal';
import EditCalendarModalContent from './EditCalendarModalContent';
import { useUser } from 'app/src/context/userStore/UserStore';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import CalendlyBlueIcon from 'app/src/images/calendars/CalendlyBlueIcon';
import ChilliPiperIcon from 'app/src/images/calendars/ChilliPiperIcon';
import Gap16HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap16HorizontalFlexWrap';

const Calendars = () => {
  const [, { getUser }] = useUser();
  const user = getUser();
  const calendars = user?.calendars;
  const [name, setName] = useState(calendars?.[0]?.name);
  const [url, setUrl] = useState(calendars?.[0]?.url);
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  const handleCloseModal = () => {
    setEditModalOpen(false);
  };

  useEffect(() => {
    if (!calendars) {
      return;
    }
    setName(calendars?.[0]?.name);
    setUrl(calendars?.[0]?.url);
  }, [calendars]);

  return (
    <LayoutRoot>
      <TopContainer>
        <TextH5>Calendar</TextH5>
        <PencilButton onClickFunction={() => setEditModalOpen(true)} />
      </TopContainer>
      <CalendarField isDefault={false} name={name} url={url} />
      <CalendarIconsContainer>
        <ChilliPiperIcon />
        <CalendlyBlueIcon />
        <TextSmall>Or any other calendar</TextSmall>
      </CalendarIconsContainer>
      <StandardModal onClose={handleCloseModal} open={isEditModalOpen}>
        <EditCalendarModalContent onClose={handleCloseModal} />
      </StandardModal>
    </LayoutRoot>
  );
};

export default Calendars;

const LayoutRoot = styled(Gap16VerticalFlex)`
  max-width: 420px;
`;

const TopContainer = styled(HorizontalFlexWrap)`
  justify-content: space-between;
  align-items: center;
`;

const CalendarIconsContainer = styled(Gap16HorizontalFlexWrap)`
  align-items: end;
`;
