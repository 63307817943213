import React from 'react';
import styled from 'styled-components';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import CalendarIcon from 'app/src/images/calendars/CalendarIcon';
import { TextH6 } from 'shared/react/components/complex/Text';
import ItemTextValue from '../ItemTextValue';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { ConfigContainer } from 'app/src/basic_components/commonStyles';

const CalendarField = ({ isDefault, name = 'My Calendar', url = '' }) => {
  return (
    <LayoutRoot>
      <TopContainer>
        <CalendarIcon />
        <TextH6>{name}</TextH6>
      </TopContainer>
      <CalendarUrl>
        {isDefault && <DefaultBadge>Default</DefaultBadge>}
        <StyledConfigContainer>
          <StyledItemTextValue value={url} placeholder="My Calendar URL">
            {url}
          </StyledItemTextValue>
        </StyledConfigContainer>
      </CalendarUrl>
    </LayoutRoot>
  );
};

export default CalendarField;

const LayoutRoot = styled(Gap8VerticalFlex)``;

const TopContainer = styled(Gap8HorizontalFlexWrap)`
  align-items: center;
`;

const StyledConfigContainer = styled(ConfigContainer)`
  padding: 12px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray3};
  margin-top: 8px;
`;

const CalendarUrl = styled.div`
  position: relative;
`;

const DefaultBadge = styled.div`
  color: white;
  font-weight: 700;
  font-size: 12px;
  background: ${({ theme }) => theme.colors.blue12};
  border-radius: 12px;
  width: 58px;
  padding: 2px;
  text-align: center;
  top: 2px;
  right: 16px;
  position: absolute;
`;

const StyledItemTextValue = styled(ItemTextValue)`
  color: ${({ value, theme }) => (value ? '' : theme.colors.gray8)};
  word-break: break-all;
`;
