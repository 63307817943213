import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';

import {
  PrimaryButton,
  SecondaryButton,
} from 'shared/react/components/basic/button-v2/BasicButton';
import CrossIcon from 'src/images/dashboard_v2/CrossIcon';
import ComponentWithLoader from 'src/complex_components/ComponentWithLoader';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

type Props = {
  videoId: string;
  isSubmitting: boolean;
  rejectSuggestion: (videoId: string) => void;
  acceptSuggestion: (videoId: string) => void;
};

const VideoActions: FC<Props> = ({ videoId, isSubmitting, rejectSuggestion, acceptSuggestion }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onAcceptVideo = async event => {
    event.stopPropagation();

    setIsLoading(true);
    await acceptSuggestion(videoId);
    setIsLoading(false);
  };

  const onRejectVideo = event => {
    event.stopPropagation();
    rejectSuggestion(videoId);
  };

  return (
    <Container>
      <VideoAcceptButton onClick={onAcceptVideo} disabled={isSubmitting}>
        <ComponentWithLoader isLoading={isLoading} size={12}>
          Approve
        </ComponentWithLoader>
      </VideoAcceptButton>
      <VideoCancelButton onClick={onRejectVideo} disabled={isSubmitting}>
        <CrossIcon />
      </VideoCancelButton>
    </Container>
  );
};

const Container = styled(HorizontalFlex)`
  align-items: center;
  background-color: ${({ theme }) => rgba(theme.colors.white, 0.25)};
  backdrop-filter: blur(4px);
  padding: 4px;
  border-radius: 8px;
  gap: 4px;
`;

const VideoAcceptButton = styled(PrimaryButton)`
  height: 24px;
  flex-grow: 1;
`;

const VideoCancelButton = styled(SecondaryButton)`
  height: 24px;
  width: 42px;
`;

export default VideoActions;
