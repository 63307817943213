import React from 'react';
import styled from 'styled-components';
import Expander from 'shared/react/components/complex/expanders/Expander';
import {
  EXPANDER_HEADER_DATA_TYPE,
  EXPANDER_TITLE_DATA_TYPE,
} from 'shared/react/components/complex/expanders/expander.constants';

const BrandingExpander = props => {
  return <StyledExpander {...props} />;
};

const StyledExpander = styled(Expander)`
  & [data-type=${EXPANDER_TITLE_DATA_TYPE}] {
    font-size: 14px;
    line-height: 22px;
    font-weight: 700;
    padding: 8px 0 8px 8px;
  }

  & [data-type=${EXPANDER_HEADER_DATA_TYPE}] {
    grid-template-columns: 1fr auto;
  }
`;

export default BrandingExpander;
