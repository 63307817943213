import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontFamilyCss } from 'player/src/components/player/FontFamilyCss';
import SharedUtils from 'shared/react/utils/utils';
import PlayerGlobalModal from 'shared/react/components/complex/PlayerGlobalModal';
import FeedGlobalStyle from 'player/src/components/feed/FeedGlobalStyle';
import FeedGlobalSnackbar from 'player/src/components/feed/FeedGlobalSnackbar';
import PlayerVideoContent from './PlayerVideoContent';
import { useIsMobile } from 'shared/react/hooks/useIsMobile';
import { TOLSTOY_CUSTOM_HEADING_FONT_FAMILY } from 'shared/react/constants/playerSettings.constants';

const Interactive = ({ state, config, shouldShowChatLandingPage }) => {
  const [customFontFamily, setCustomFontFamily] = useState('');
  const [customHeadingFontFamily, setCustomHeadingFontFamily] = useState('');

  const { landingPage, pause, playlist, layout, verticalOrientation, customLayout, isReply } =
    state;
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!config) {
      return;
    }

    const { font: fontCustomization, fontHeading: fontHeadingCustomization } =
      config.design.branding.typography;

    SharedUtils.reloadFont(fontCustomization, setCustomFontFamily);
    SharedUtils.reloadFont(
      fontHeadingCustomization,
      setCustomHeadingFontFamily,
      TOLSTOY_CUSTOM_HEADING_FONT_FAMILY
    );
  }, [config]);

  return (
    <div
      className={classNames('player-main-container', {
        'player-main-container-vertical': verticalOrientation,
        'player-main-container-horizontal': !verticalOrientation,
        'landing-app': landingPage,
        'landing-app-horizontal': landingPage && !verticalOrientation,
        app: !landingPage,
        'player-main-container-reply': isReply,
        'player-position-not-relative': !state.landingPage,
        'player-main-container-vertical-chat':
          state.chatLandingPage && verticalOrientation && !isMobile,
        'player-main-container-chat': state.chatLandingPage,
      })}
    >
      <LayoutRoot
        customHeadingFontFamily={customHeadingFontFamily}
        customFontFamily={customFontFamily}
      >
        <StyledPlayerVideoContent
          config={config}
          playlist={playlist}
          verticalOrientation={verticalOrientation}
          landingPage={landingPage}
          isReply={isReply}
          state={state}
          pause={pause}
          isMobile={isMobile}
          customLayout={customLayout}
          customFontFamily={customFontFamily}
          shouldShowChatLandingPage={shouldShowChatLandingPage}
          layout={layout}
        />
        <PlayerGlobalModal />
        <FeedGlobalSnackbar />
        <FeedGlobalStyle customFontFamily={customFontFamily} />
      </LayoutRoot>
    </div>
  );
};

const LayoutRoot = styled.div`
  display: grid;
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  flex-wrap: nowrap;
  font-family: ${({ customFontFamily }) =>
    customFontFamily ? customFontFamily : 'Nunito Sans, sans-serif'};
  ${FontFamilyCss};
`;

const StyledPlayerVideoContent = styled(PlayerVideoContent)`
  grid-row: 1;
  grid-column: 1;
  z-index: 1;
`;

export default Interactive;
