import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useProductActions } from 'app/src/context/ProductsStore';
import Utils from 'app/src/utils';
import { track } from 'app/src/helpers/Tracker';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';
import useGetSearchProducts from 'app/src/hooks/useGetSearchProducts';
import ProductSearchInput from 'app/src/complex_components/search-products/ProductSearchInput';
import ProductsSearchMenu from 'app/src/complex_components/search-products/ProductsSearchMenu';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import {
  FEATURE_PRODUCTS_SEARCH_LIMIT,
  FEATURE_SHOPIFY_TAGS,
} from 'app/src/constants/appFeatures.constants';
import { SHOPIFY } from 'src/constants/intergrations.constants';

const SearchProducts = ({
  selectedProducts,
  selectedApp,
  isSaving,
  setSelectedProducts,
  onProductSelected,
  location = '',
  isSingleSelect = false,
  disabledProductIds = [],
  disabledTooltipText = '',
  hasVodConnection = false,
  onHasNoPermissions = undefined,
  getProductComponent = undefined,
  isLoading = undefined,
  analyticsData = undefined,
  productsInputPlaceholder = undefined,
  onTagClick = undefined,
  selectedTags = [],
  autoFocus = false,
  vodConnections = [],
  setIsSelectProductPopupOpened,
  ...props
}) => {
  const { fetchMetaDataFromUrl } = useProductActions();
  const [, { setErrorSnackbar }] = useSnackBar();
  const { getSettingsByKey, getFeatureEnabled } = useFeatureActions();
  const getSearchProducts = useGetSearchProducts(selectedApp);
  const [searchProducts, setSearchProducts] = useState([]);
  const [value, setValue] = useState('');
  const [iconSrc, setIconSrc] = useState('');
  const [loading, setLoading] = useState(false);
  const ref = useRef();
  const timeoutRef = useRef(null);
  const currentInputValue = useRef('');
  const { customLimit } = getSettingsByKey(FEATURE_PRODUCTS_SEARCH_LIMIT) || {};
  const shopifyTagsEnabled =
    getFeatureEnabled(FEATURE_SHOPIFY_TAGS) && selectedApp?.app === SHOPIFY;

  const setInputValue = _value => {
    setValue(_value);
    currentInputValue.current = _value;
  };

  const showEmptyState = () => {
    setInputValue('');
    setSearchProducts([]);
  };

  const fetchProducts = async term => {
    track(`${location} Search Products Container Selected`, analyticsData);

    if (loading && currentInputValue.current !== term) {
      return;
    }

    if (isLoading || isSaving) {
      return;
    }

    if (!selectedApp) {
      return;
    }

    setLoading(true);

    const body = {
      term,
      appUrl: selectedApp.appUrl,
      appKey: Utils.getAppKey(),
      customLimit,
      hasVodConnection,
    };

    try {
      const { searchProducts: filteredProducts } = await getSearchProducts(body);

      if (!filteredProducts) {
        setErrorSnackbar('There have been an error getting your products. please try again later.');
        return;
      }

      if (currentInputValue.current === term) {
        setSearchProducts(filteredProducts);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      if (err.response.status === 401 || err.response.status === 402) {
        onHasNoPermissions?.(true);
      }
    }

    setLoading(false);
  };

  const fetchProductFromUrl = async url => {
    if (!Utils.isValidUrl(url)) {
      return;
    }

    setLoading(true);

    const product = await fetchMetaDataFromUrl(url);
    if (product.err) {
      setErrorSnackbar(product.err);
      setLoading(false);
      return;
    }

    handleProductSelected(product);

    setLoading(false);
  };

  const onSelectValueChange = e => {
    const { value } = e.target;
    clearTimeout(timeoutRef.current);
    setInputValue(value);
    setIconSrc('');

    timeoutRef.current = setTimeout(() => {
      fetchProducts(value);
    }, 600);
  };

  const onInputValueChange = e => {
    const { value } = e.target;
    clearTimeout(timeoutRef.current);
    setInputValue(value);

    timeoutRef.current = setTimeout(() => {
      fetchProductFromUrl(value);
    }, 400);
  };

  const handleProductSelected = product => {
    // id appears at half of searched items and bring inconsistency only
    onProductSelected?.({ ...product, id: undefined });
    if (isSingleSelect) {
      setInputValue(product?.title);
      setIconSrc(product?.imageUrl);
      setSearchProducts([]);
    }
  };

  const onClearAll = () => {
    const filteredProducts = selectedProducts.filter(
      ({ productId }) => !searchProducts.find(product => product.productId === productId)
    );

    setSelectedProducts(filteredProducts);
  };

  const onSelectAllFilter = product => {
    if (disabledProductIds?.length && disabledProductIds.includes(product.productId)) {
      return false;
    }

    return !selectedProducts.find(({ productId }) => productId === product.productId);
  };

  const onSelectAll = () => {
    const filteredProducts = searchProducts.filter(onSelectAllFilter);
    const formattedProducts = filteredProducts.map(product => ({
      ...product,
      id: undefined,
    }));

    setSelectedProducts([...selectedProducts, ...formattedProducts]);
  };

  const onRemoveProduct = productId => {
    const filteredProducts = selectedProducts.filter(product => {
      return product.productId !== productId;
    });

    setSelectedProducts(filteredProducts);
  };

  const isMenuOpen = Boolean(
    (!!searchProducts.length && !!ref.current) || (shopifyTagsEnabled && onTagClick && value)
  );

  useEffect(() => {
    setIsSelectProductPopupOpened(isMenuOpen);
  }, [isMenuOpen, setIsSelectProductPopupOpened]);

  return (
    <LayoutRoot {...props} open={isMenuOpen}>
      <SearchContainer ref={ref}>
        <ProductSearchInput
          onSelectValueChange={onSelectValueChange}
          onInputValueChange={onInputValueChange}
          isMenuOpen={isMenuOpen}
          value={value}
          isLoading={isLoading || loading}
          autoFocus={autoFocus}
          fetchProducts={fetchProducts}
          selectedApp={selectedApp}
          iconSrc={iconSrc}
          productsInputPlaceholder={productsInputPlaceholder}
        />
      </SearchContainer>
      <ProductsSearchMenu
        onClose={showEmptyState}
        isOpen={isMenuOpen}
        getProductComponent={getProductComponent}
        handleProductSelected={handleProductSelected}
        searchProducts={searchProducts}
        searchInputRef={ref}
        selectedProducts={selectedProducts}
        onSelectAll={onSelectAll}
        onClearAll={onClearAll}
        onRemoveProduct={onRemoveProduct}
        isSingleSelect={isSingleSelect}
        disabledProductIds={disabledProductIds}
        disabledTooltipText={disabledTooltipText}
        searchValue={value}
        selectedTags={selectedTags}
        appUrl={selectedApp?.appUrl}
        onTagClick={onTagClick}
        vodConnections={vodConnections}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  display: grid;
  box-shadow: ${({ open }) => (open ? '0px 0px 0px 3px rgba(64, 117, 255, 0.35);' : '')};
`;

const SearchContainer = styled.div`
  align-items: center;
  justify-items: center;
`;

export default SearchProducts;
