import { useCustomerLoginContext } from 'shared/react/components/complex/context/CustomerLoginContext';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';
import { CUSTOMER_LOGIN_STATE } from 'shared/react/constants/feature-constants/customerLogin.constants';
import {
  verifyCustomerEmail,
  getMultipassUrl,
  getStoredCodeId,
  storeCodeId,
} from 'shared/react/utils/customerLogin.utils.js';
import useFeedFeatures from './useFeedFeatures';
import useFeedModal from './useFeedModal';
import { CUSTOMER_LOGIN_MODAL } from 'shared/react/constants/modals.constants';
import { FEATURE_CUSTOMER_LOGIN } from 'shared/react/constants/features.constants';

const useCustomerLogin = () => {
  const [{ appUrl, customerId }] = useProjectConfig();
  const [, { getFeatureEnabled }] = useFeedFeatures();
  const [{ currentModal }] = useFeedModal();

  const {
    customerLoginState,
    setCustomerLoginState,
    customerLoginStatusCode,
    setCustomerLoginStatusCode,
  } = useCustomerLoginContext();

  const isCustomerLoginEnabled = getFeatureEnabled(FEATURE_CUSTOMER_LOGIN);

  const actions = {
    setCustomerLoginState,
    setCustomerLoginStatusCode,
    handleSubmitEmail: async emailInput => {
      const codeId = await verifyCustomerEmail(emailInput, appUrl);
      if (!codeId) {
        setCustomerLoginState(CUSTOMER_LOGIN_STATE.UNAUTHENTICATED);
        return;
      }

      storeCodeId(codeId);
      setCustomerLoginState(CUSTOMER_LOGIN_STATE.CODE_VALIDATION);
    },

    handleSubmitVerificationCode: async codeInput => {
      const REDIRECT_PATH = '/';
      const codeId = getStoredCodeId();

      const multipassUrl = await getMultipassUrl(codeInput, codeId, REDIRECT_PATH, appUrl);

      if (!multipassUrl) {
        setCustomerLoginState(CUSTOMER_LOGIN_STATE.CODE_VALIDATION);
        return;
      }

      return multipassUrl;
    },
  };

  const getShouldShowLoginBanner = () => {
    if (!isCustomerLoginEnabled) {
      return false;
    }

    if (customerId) {
      return false;
    }

    if (currentModal?.key === CUSTOMER_LOGIN_MODAL) {
      return false;
    }

    if (customerLoginState === CUSTOMER_LOGIN_STATE.LOGGED_IN) {
      return false;
    }

    return true;
  };

  const shouldShowLoginBanner = getShouldShowLoginBanner();

  return [{ customerLoginState, customerLoginStatusCode, shouldShowLoginBanner }, actions];
};

export default useCustomerLogin;
