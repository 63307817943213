import { css } from 'styled-components';

export const HEIGHT_BREAKPOINT = `max-height:${811}px`;

export const rightSectionSize = css`
  position: relative;
  max-height: 685px;
  min-height: 685px;
  flex: 1;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 100%;
    height: 100%;
    max-height: none;
  }
`;
