import React from 'react';
import styled, { useTheme } from 'styled-components';
import FeedButton from 'shared/react/components/complex/feed/feed_buttons/FeedButton';
import {
  PRIMARY_DARK_BUTTON_TYPE,
  PRIMARY_LIGHT_BUTTON_TYPE,
  SECONDARY_BUTTON_TYPE,
} from 'shared/react/constants/feed.constants';
import { FEED_QUICK_ADD_BUTTON_PLUS_ICON_DATA_TYPE } from 'shared/react/constants/feedPreviewDataTypes.constants';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';
import Utils from 'shared/react/utils/utils';
import {
  FEED_BUTTONS_DEFAULT_SETTINGS,
  FEED_PRIMARY_BACKGROUND_COLOR_TYPE,
  FEED_PRIMARY_BORDER_COLOR_TYPE,
  FEED_PRIMARY_BUTTON_BORDER_THICKNESS_TYPE,
  FEED_PRIMARY_BUTTON_BORDER_TYPE,
  FEED_SECONDARY_BACKGROUND_COLOR_TYPE,
  FEED_SECONDARY_BORDER_COLOR_TYPE,
  FEED_SECONDARY_BUTTON_BORDER_THICKNESS_TYPE,
  FEED_SECONDARY_BUTTON_BORDER_TYPE,
} from '../../../../constants/feature-constants/feedButtonsCustomization.constants';
import { getShouldContrastColor } from '../../../../utils/color';

const FeedBrandedButton = ({ type = PRIMARY_DARK_BUTTON_TYPE, hide, ...props }) => {
  const theme = useTheme();
  const { player } = useDesignSettingsContext();
  const {
    primary: {
      backgroundColor: primaryBackgroundColor,
      textColor: primaryTextColor,
      border: {
        enabled: primaryButtonBorderEnabled,
        color: primaryBorderColor,
        thickness: primaryButtonBorderThickness,
        radius: borderRadius
      }
    },
    secondary: {
      backgroundColor: secondaryBackgroundColor,
      textColor: secondaryTextColor,
      border: {
        enabled: secondaryButtonBorderEnabled,
        color: secondaryBorderColor,
        thickness: secondaryButtonBorderThickness
      }
    }
  } = player.feedCards.buttonsDesign;
  const {
    includeProductPrice: primaryHasProductPrice,
    hasQuantityPicker: primaryHasQuantityPicker,
  } = player.feedCards.productDetailsCard.primaryButton.settings;

  const getPrimaryButtonColors = () => {
    const backgroundColor =
      primaryBackgroundColor ?? FEED_BUTTONS_DEFAULT_SETTINGS[FEED_PRIMARY_BACKGROUND_COLOR_TYPE];
    const shouldContrastColor = getShouldContrastColor(backgroundColor);
    const defaultTextColor = shouldContrastColor ? theme.colors.white : theme.colors.black;

    return {
      buttonTextColor: primaryTextColor ?? defaultTextColor,
      backgroundColor,
      buttonBorderEnabled:
        primaryButtonBorderEnabled ??
        FEED_BUTTONS_DEFAULT_SETTINGS[FEED_PRIMARY_BUTTON_BORDER_TYPE],
      buttonBorderThickness:
        primaryButtonBorderThickness ??
        FEED_BUTTONS_DEFAULT_SETTINGS[FEED_PRIMARY_BUTTON_BORDER_THICKNESS_TYPE],
      borderColor:
        primaryBorderColor ?? FEED_BUTTONS_DEFAULT_SETTINGS[FEED_PRIMARY_BORDER_COLOR_TYPE],
    };
  };

  const getSecondaryButtonColors = () => {
    const backgroundColor =
      secondaryBackgroundColor ??
      FEED_BUTTONS_DEFAULT_SETTINGS[FEED_SECONDARY_BACKGROUND_COLOR_TYPE];
    const shouldContrastColor = getShouldContrastColor(backgroundColor);
    const defaultTextColor = shouldContrastColor ? theme.colors.white : theme.colors.black;

    return {
      buttonTextColor: secondaryTextColor ?? defaultTextColor,
      backgroundColor,
      buttonBorderEnabled:
        secondaryButtonBorderEnabled ??
        FEED_BUTTONS_DEFAULT_SETTINGS[FEED_SECONDARY_BUTTON_BORDER_TYPE],
      buttonBorderThickness:
        secondaryButtonBorderThickness ??
        FEED_BUTTONS_DEFAULT_SETTINGS[FEED_SECONDARY_BUTTON_BORDER_THICKNESS_TYPE],
      borderColor:
        secondaryBorderColor ?? FEED_BUTTONS_DEFAULT_SETTINGS[FEED_SECONDARY_BORDER_COLOR_TYPE],
    };
  };

  const getButtonColors = () => {
    switch (type) {
      case PRIMARY_LIGHT_BUTTON_TYPE:
      case PRIMARY_DARK_BUTTON_TYPE:
        return getPrimaryButtonColors();
      case SECONDARY_BUTTON_TYPE:
        return getSecondaryButtonColors();
    }
  };

  const {
    backgroundColor,
    buttonTextColor,
    borderColor,
    buttonBorderEnabled,
    buttonBorderThickness,
  } = getButtonColors();

  const borderCss = buttonBorderEnabled ? `${buttonBorderThickness}px solid ${borderColor}` : '';

  if (hide) {
    return null;
  }

  return (
    <StyledFeedButton
      backgroundColor={backgroundColor}
      buttonTextColor={buttonTextColor}
      borderRadius={borderRadius}
      borderCss={borderCss}
      primaryHasProductPrice={primaryHasProductPrice}
      primaryHasQuantityPicker={primaryHasQuantityPicker}
      {...props}
    />
  );
};

const StyledFeedButton = styled(FeedButton)`
  background: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${({ borderRadius }) => borderRadius ?? ''}px;
  border: ${({ borderCss }) => borderCss};

  & ${Utils.getDataType(FEED_QUICK_ADD_BUTTON_PLUS_ICON_DATA_TYPE)} {
    & path {
      fill: ${({ buttonTextColor }) => buttonTextColor};
    }
  }
`;

export default FeedBrandedButton;
