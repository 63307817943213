import React from 'react';

const AppsAndWebsites = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5 2.25H2.5C1.65625 2.25 1 2.92969 1 3.75V11.25C1 12.0938 1.65625 12.75 2.5 12.75H11.5C12.3203 12.75 13 12.0938 13 11.25V3.75C13 2.92969 12.3203 2.25 11.5 2.25ZM4.1875 9.375H3.0625C2.73438 9.375 2.5 9.14062 2.5 8.8125C2.5 8.50781 2.73438 8.25 3.0625 8.25H4.1875C4.49219 8.25 4.75 8.50781 4.75 8.8125C4.75 9.14062 4.49219 9.375 4.1875 9.375ZM4.1875 7.125H3.0625C2.73438 7.125 2.5 6.89062 2.5 6.5625C2.5 6.25781 2.73438 6 3.0625 6H4.1875C4.49219 6 4.75 6.25781 4.75 6.5625C4.75 6.89062 4.49219 7.125 4.1875 7.125ZM4.1875 4.875H3.0625C2.73438 4.875 2.5 4.64062 2.5 4.3125C2.5 4.00781 2.73438 3.75 3.0625 3.75H4.1875C4.49219 3.75 4.75 4.00781 4.75 4.3125C4.75 4.64062 4.49219 4.875 4.1875 4.875ZM11.875 11.25C11.875 11.4609 11.6875 11.625 11.5 11.625H6.25V3.375H11.5C11.6875 3.375 11.875 3.5625 11.875 3.75V11.25Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default AppsAndWebsites;
