import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { TextOverline, TextTiny } from 'shared/react/components/complex/Text';
import UpgradeButton from 'app/src/complex_components/billings/UpgradeButton';
import { useBilling } from 'app/src/hooks/useBillings';
import Badge from '../../badge/Badge';

const LIGHT_VARIANT = 'light';

const FloatingPricingBadge = ({ leftText, centerText, analyticsData, variant }) => {
  const theme = useTheme();
  const [textColor, setTextColor] = useState(theme.colors.white);
  const [backgroundColor, setBackgroundColor] = useState(theme.colors.gray2);
  const { isPricingEnabled } = useBilling();

  useEffect(() => {
    switch (variant) {
      case LIGHT_VARIANT:
        setTextColor(theme.colors.gray2);
        setBackgroundColor(theme.colors.white);
        break;
      default:
        setTextColor(theme.colors.white);
        setBackgroundColor(theme.colors.gray2);
    }
  }, [variant]);

  if (!isPricingEnabled) {
    return null;
  }

  return (
    <BadgeContainer backgroundColor={backgroundColor}>
      <LeftText textColor={textColor}>{leftText}</LeftText>
      <CenterText textColor={textColor}>{centerText}</CenterText>
      <UpgradeButton analyticsData={analyticsData} />
    </BadgeContainer>
  );
};

export default FloatingPricingBadge;

const BadgeContainer = styled(Badge)`
  background: ${({ backgroundColor }) => backgroundColor};

  @media (${({ theme }) => theme.breakpoints.dashboardTabletMax}) {
    display: none;
  }
`;

const LeftText = styled(TextOverline)`
  color: ${({ textColor }) => textColor};
  font-size: 12px;
`;

const CenterText = styled(TextTiny)`
  color: ${({ textColor }) => textColor};
`;
