import { matchPath } from 'react-router-dom';
import { createContainer, createHook, createStore } from 'react-sweet-state';
import { getRequest, graphqlRequest } from '../helpers/API';
import Routes from '../helpers/Routes';
import { getUrlWithParams } from '../utils/url.utils';
import {
  EMAIL_FILTER,
  NOT_ANONYMOUS,
  STATUS_ARCHIVE,
  STATUS_IS_SPAM,
  STATUS_NEW,
} from '../constants/responsesInbox.constants';
import { API, graphqlOperation } from 'aws-amplify';
import { createSession, updateSession } from '../graphql/mutations';
import { onCreateOrUpdateSessionByAppKey } from '../graphql/subscriptions';
import Utils from '../utils';
import { ALL_COUNTERS_KEY } from '../constants/sessions.constants';
import { getEnabledFeatureSettings } from './FeaturesStore';
import { FEATURE_LIMIT_RESPONSES } from 'app/src/constants/appFeatures.constants';

const LOCAL_STORAGE_INBOX_FILTER_KEY = 'inbox-filters';

const getIsInboxSession = ({
  email,
  audioResponse,
  textResponse,
  videoResponse,
  formResponse,
} = {}) => {
  if (!email) {
    return false;
  }

  return audioResponse || textResponse || videoResponse || formResponse;
};

const getShouldUpdateInboxSession = ({ projectId, session, allCounter, addition }) => {
  if (projectId === ALL_COUNTERS_KEY || !getIsInboxSession(session)) {
    return false;
  }

  return allCounter + addition >= 0;
};

const getFilteredSessionsByOwner = async ({
  filters,
  nextPage,
  email,
  id,
  projectId,
  assignee,
}) => {
  const params = getSessionsParams({ filters, nextPage, email, id, projectId, assignee });
  const url = getUrlWithParams('/actions/sessions', params);
  return getRequest('response-actions', url);
};

const getSessionsParams = ({ filters = [], ...params } = {}) => {
  params.appKey = Utils.getAppKey();

  if (params.assignee === null) {
    params.assigned = false;
  }

  if (params.id) {
    return params;
  }

  filters.forEach(filter => {
    if (filter === STATUS_NEW) {
      params[filter] = false;
      return;
    }

    if (filter === NOT_ANONYMOUS) {
      params[NOT_ANONYMOUS] = true;
      params[EMAIL_FILTER] = true;
      return;
    }

    params[filter] = true;
  });

  if (!filters.includes(STATUS_ARCHIVE)) {
    params[STATUS_ARCHIVE] = false;
  }

  if (!filters.includes(STATUS_IS_SPAM)) {
    params[STATUS_IS_SPAM] = false;
  }

  return params;
};

const filterSessionIfNeeded = (session, filters) => {
  if (!filters) {
    return session;
  }

  let shouldBeFiltered = false;
  for (let [filter, value] of Object.entries(filters)) {
    if (session[filter] !== value) {
      shouldBeFiltered = true;
      break;
    }
  }

  return shouldBeFiltered ? [] : session;
};

const getFormattedSessionDate = sessionDate => {
  if (Utils.getIsCurrentYear(sessionDate)) {
    return `${Utils.getShortMonthName(sessionDate)} ${sessionDate.getDate()}`;
  }

  return `${sessionDate.getMonth() + 1}/${sessionDate.getDate()}/${sessionDate.getFullYear()}`;
};

export const getSessionDateOrTime = (date, isToday) => {
  const sessionDate = new Date(date);
  if (isToday) {
    const hours = sessionDate.getHours();
    const minutes = Utils.formatDateMinutes(sessionDate);
    return `${hours}:${minutes}`;
  }

  return getFormattedSessionDate(sessionDate);
};

function getHasReachedLimit(features) {
  return getEnabledFeatureSettings(features, FEATURE_LIMIT_RESPONSES)?.hasReachedLimit;
}

export const isExistingSession = (currSession, sessions) => {
  if (!currSession?.id || !sessions?.length) {
    return false;
  }

  return sessions.some(({ id }) => id === currSession.id);
};

const initialState = {
  counters: {},
  sessions: [],
  nextPage: null,
  loading: false,
  doneFetching: false,
  newSession: null,
  selectedSession: null,
  responsesFilters: new Set(),
  total: 0,
  inboxFilters: JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_INBOX_FILTER_KEY) ||
      '{"assignee":"","email":"","archived":false,"projectId":""}'
  ),
};

const getUpdatedSessions = (sessions, updatedSession, dispatch) => {
  return sessions.map(session => {
    if (session.id !== updatedSession.id) {
      return session;
    }

    const addition = updatedSession.read ? -1 : 1;
    dispatch(actions.updateUnreadResponseCounter(addition, session, session.projectId));
    session.read = updatedSession.read;
    return session;
  });
};

const shouldSessionBeFiltered = (session, responsesFilters, inboxFilters) => {
  if (matchPath(location.pathname, { path: Routes.getResponsesRoute() })) {
    return [...responsesFilters.values()].some(filter => !session[filter]);
  }

  if (matchPath(location.pathname, { path: Routes.getInboxWithSessionIdRoute() })) {
    return Object.entries(inboxFilters).some(
      ([key, value]) => value !== '' && session[key] !== value
    );
  }

  return false;
};

const actions = {
  fetchSessions:
    ({ filters, email, sessionId, projectId, assignee }) =>
    async ({ getState, setState }, { features }) => {
      if (getHasReachedLimit(features)) {
        return;
      }

      setState({ loading: true });

      const { sessions, nextPage, newSession } = getState();

      const {
        items = [],
        nextPage: newNextPage,
        total,
      } = await getFilteredSessionsByOwner({
        filters,
        nextPage,
        email,
        id: sessionId,
        projectId,
        assignee,
      });

      const newSessions = [...sessions, ...items];

      if (newSession && !newSessions.some(({ id }) => id === newSession.id)) {
        newSessions.unshift(newSession);
      }

      setState({
        sessions: newSessions,
        nextPage: newNextPage,
        loading: false,
        doneFetching: !newNextPage,
        total: total || newSessions?.length,
      });
    },
  fetchUsersSessions:
    ({ email, archived, assignee, projectId, sessionId }) =>
    async ({ dispatch }) => {
      const filters = [NOT_ANONYMOUS];
      if (archived && !sessionId) {
        filters.push(STATUS_ARCHIVE);
      }

      dispatch(actions.fetchSessions({ filters, email, sessionId, projectId, assignee }));
    },
  fetchNewSessions:
    ({ email, assignee, projectId, archived = false }) =>
    async ({ getState, setState, dispatch }, { features }) => {
      if (getHasReachedLimit(features)) {
        return;
      }

      const filters = [NOT_ANONYMOUS];
      if (archived) {
        filters.push(STATUS_ARCHIVE);
      }

      const { items, total } = await getFilteredSessionsByOwner({
        filters,
        nextPage: 0,
        email,
        projectId,
        assignee,
      });

      const { sessions = [], nextPage } = getState();

      if (!items.length || sessions[0]?.id === items[0]?.id) {
        return;
      }

      const newSessions = [];
      let newItems = 0;
      for (let newSession of items) {
        if (sessions.some(({ id }) => id === newSession.id)) {
          break;
        }

        newSessions.push(newSession);
        newItems++;
        if (!newSession.read) {
          dispatch(actions.updateUnreadResponseCounter(1, newSession, newSession.projectId));
        }
      }

      setState({
        sessions: newSessions.concat(sessions),
        nextPage: nextPage + newItems,
        total: total || sessions.length,
      });
    },
  fetchProjectResponseCounters:
    () =>
    async ({ setState }) => {
      try {
        const params = getSessionsParams();
        const url = getUrlWithParams('/actions/sessions/counters', params);
        const counters = await getRequest('response-actions', url);

        let hasResponses = false;
        for (const { total } of Object.values(counters)) {
          if (total) {
            hasResponses = true;
            break;
          }
        }

        setState({ counters, hasResponses });
      } catch (error) {
        console.error(error);
        Utils.logError('Error fetching project response counters', error);
      }
    },
  updateUnreadResponseCounter:
    (addition, session, projectId = ALL_COUNTERS_KEY) =>
    ({ getState, setState }) => {
      if (session.archived || session.isSpam) {
        return;
      }

      const { counters } = getState();

      if (!Object.keys(counters).includes(projectId)) {
        projectId = ALL_COUNTERS_KEY;
      }

      if (addition < 0 && !counters[projectId].unread) {
        return;
      }

      counters[projectId].unread += addition;

      if (
        getShouldUpdateInboxSession({
          projectId,
          session,
          allCounter: counters[ALL_COUNTERS_KEY].unread,
          addition,
        })
      ) {
        counters[ALL_COUNTERS_KEY].unread += addition;
      }

      setState({
        counters: { ...counters },
      });
    },
  getProjectTotalResponses:
    projectId =>
    ({ getState }) => {
      const { counters } = getState();

      return counters?.[projectId]?.total || 0;
    },
  getProjectUnreadResponses:
    (projectId = ALL_COUNTERS_KEY) =>
    ({ getState }) => {
      const { counters } = getState();

      if (!counters?.[projectId]) {
        return 0;
      }

      if (counters?.[projectId].unread > 0) {
        return counters[projectId].unread;
      }

      return 0;
    },
  updateSessionProperties:
    (id, properties, filters) =>
    async ({ getState, setState, dispatch }) => {
      const input = { id, ...properties };
      const { sessions, total } = getState();

      await graphqlRequest(graphqlOperation(updateSession, { input }));

      const newSessions = sessions.flatMap(session => {
        if (session.id !== id) {
          return session;
        }

        if (properties.read !== undefined) {
          const addition = properties.read ? -1 : 1;
          dispatch(actions.updateUnreadResponseCounter(addition, session, session.projectId));
          session.read = properties.read;
          return session;
        }

        return filterSessionIfNeeded({ ...session, ...properties }, filters);
      });
      const newTotal = total - (sessions.length - newSessions.length);
      setState({ sessions: newSessions, total: newTotal });
    },
  createSession:
    input =>
    async ({ setState }, { appKey }) => {
      input.lastEventAt = new Date().toISOString();
      input.owner = Utils.getOwner();
      input.appKey = appKey;

      const response = await graphqlRequest(
        graphqlOperation(createSession, {
          input,
        })
      );

      setState({ newSession: response.data.createSession });
      return response.data.createSession.id;
    },
  getSession:
    sessionId =>
    ({ getState }) => {
      const { sessions } = getState();

      return sessions?.find(({ id }) => id === sessionId);
    },
  clearSessionsStore:
    () =>
    ({ setState }) => {
      setState({
        sessions: [],
        selectedSession: null,
        nextPage: null,
        loading: false,
        doneFetching: false,
        newSession: null,
        total: 0,
      });
    },
  clearResponsesStore:
    () =>
    ({ setState }) => {
      setState({ counters: {} });
    },
  setSelectedSession:
    session =>
    ({ setState }) => {
      setState({ selectedSession: session });
    },
  subscribeSessions:
    () =>
    ({ setState, getState, dispatch }, { appKey }) => {
      try {
        const subscription = API.graphql(
          graphqlOperation(onCreateOrUpdateSessionByAppKey, { appKey })
        ).subscribe({
          next: data => {
            const updatedSession = data?.value?.data?.onCreateOrUpdateSessionByAppKey;
            const { sessions, total, responsesFilters, inboxFilters } = getState();

            if (shouldSessionBeFiltered(updatedSession, responsesFilters, inboxFilters)) {
              return null;
            }

            let newSessions;
            let newTotal;

            if (!isExistingSession(updatedSession, sessions)) {
              newSessions = [updatedSession, ...sessions];
              newTotal = total + 1;
            } else {
              newSessions = getUpdatedSessions(sessions, updatedSession, dispatch);
              newTotal = total;
            }

            setState({ sessions: newSessions, total: newTotal });
          },
          error: ({ error: { errors = [] } = {} }) => {
            Utils.logErrorMessage(
              errors[0]?.message || 'Error in onCreateOrUpdateSessionByAppKey subscribe'
            );
          },
        });

        setState({ subscription });
      } catch (error) {
        Utils.logError('Cannot subscribe to session', error);
      }
    },
  unsubscribeSessions:
    () =>
    ({ getState, setState }) => {
      getState().subscription?.unsubscribe();

      setState({ subscription: null });
    },
  setResponsesFilters:
    responsesFilters =>
    ({ setState }) => {
      setState({ responsesFilters });
    },
  setInboxFilters:
    filters =>
    ({ getState, setState }) => {
      const { inboxFilters } = getState();
      const newInboxFilters = { ...inboxFilters, ...filters };
      localStorage.setItem(LOCAL_STORAGE_INBOX_FILTER_KEY, JSON.stringify(newInboxFilters));
      setState({ inboxFilters: { ...newInboxFilters } });
    },
};

const SessionsStore = createStore({ initialState, actions, name: 'Sessions' });

export const useSessions = createHook(SessionsStore);

export const SessionsContainer = createContainer(SessionsStore, {
  onInit:
    () =>
    ({ dispatch }) => {
      dispatch(actions.subscribeSessions());
    },
  onCleanup:
    () =>
    ({ dispatch }) => {
      dispatch(actions.clearSessionsStore());
      dispatch(actions.unsubscribeSessions());
    },
});
