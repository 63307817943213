import React, { useState } from 'react';
import styled from 'styled-components';

const TOOLTIP_MARGIN = 36;
const TOOLTIP_ARROW_SIZE = 6;
const TOOLTIP_BACKGROUND_COLOR = 'black';

const Tooltip = ({ children, direction, text }) => {
  const [showMessage, setShowMessage] = useState(false);

  const onMouseEnter = () => {
    setShowMessage(true);
  };

  const onMouseLeave = () => {
    setShowMessage(false);
  };

  const getDirectionValue = (centerValue, leftValue, rightValue) => {
    const directions = {
      center: centerValue,
      left: leftValue,
      right: rightValue,
    };

    return directions[direction] || centerValue;
  };

  const getLeft = () => {
    return getDirectionValue('50%', '20px', '80%');
  };

  const getTranslateX = () => {
    return getDirectionValue('-50%', '-20%', '-80%');
  };

  const getMessage = () => {
    if (typeof text === 'object') {
      return text;
    }

    return <TooltipMessage>{text}</TooltipMessage>;
  };

  return (
    <StyledTooltip onMouseEnter={onMouseEnter} onClick={onMouseLeave} onMouseLeave={onMouseLeave}>
      <TooltipMessageContainer show={showMessage} left={getLeft()} translateX={getTranslateX()}>
        {getMessage()}
      </TooltipMessageContainer>
      {children}
    </StyledTooltip>
  );
};

const StyledTooltip = styled.div`
  display: inline-block;
  position: relative;
`;

const TooltipMessageContainer = styled.div`
  :before {
    content: ' ';
    left: ${({ left }) => left};
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: ${`${TOOLTIP_ARROW_SIZE}px`};
    margin-left: ${`${TOOLTIP_ARROW_SIZE * -1}px`};
    bottom: 100%;
    border-bottom-color: ${`${TOOLTIP_BACKGROUND_COLOR}`};
  }
  display: ${({ show }) => (show ? 'initial' : 'none')};
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translate(${({ translateX }) => translateX}, ${TOOLTIP_MARGIN}px);
  padding: 6px;
  color: ${({ theme }) => theme.colors.white};
  background: ${`${TOOLTIP_BACKGROUND_COLOR}`};
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;
`;

const TooltipMessage = styled.p`
  margin: 0;
`;

export default Tooltip;
