import React from 'react';

const OutlinedDollarSignIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.625 4.625c.313.031.625.125.906.219.063 0 .125.031.188.031.344.125.562.5.469.844-.094.375-.47.562-.844.468-.031 0-.094-.03-.125-.03l-.656-.188c-.438-.063-.907-.032-1.282.125-.343.156-.625.531-.25.781.313.188.688.281 1.032.375.093.031.156.063.218.063.5.156 1.125.312 1.594.656.594.406.875 1.093.75 1.812-.125.688-.625 1.156-1.188 1.375a2.41 2.41 0 0 1-.812.219v.469a.64.64 0 0 1-.656.656c-.375 0-.688-.281-.688-.656v-.563a10.77 10.77 0 0 1-1.343-.375.662.662 0 0 1-.407-.844.643.643 0 0 1 .844-.437.58.58 0 0 0 .219.063c.375.124.718.25 1.093.312.532.094.938.031 1.22-.094.374-.125.562-.594.187-.844-.313-.218-.688-.312-1.063-.406-.094-.031-.156-.031-.219-.062-.468-.125-1.062-.313-1.5-.594-.625-.375-.937-1.031-.78-1.75.124-.688.655-1.125 1.218-1.375.156-.094.344-.125.531-.188v-.5c0-.375.313-.687.688-.687.375 0 .656.313.656.688v.437ZM16 8c0 4.438-3.594 8-8 8-4.438 0-8-3.563-8-8 0-4.406 3.563-8 8-8 4.406 0 8 3.594 8 8ZM8 1.5C4.406 1.5 1.5 4.438 1.5 8c0 3.594 2.906 6.5 6.5 6.5 3.563 0 6.5-2.906 6.5-6.5 0-3.563-2.938-6.5-6.5-6.5Z"
        fill="#684BD8"
      />
    </svg>
  );
};

export default OutlinedDollarSignIcon;
