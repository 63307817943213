import React from 'react';
import styled, { useTheme } from 'styled-components';
import { shadeColor } from 'shared/react/utils/color';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import ThinPlusIcon from 'shared/react/images/ThinPlusIcon';
import ThinVIcon from 'shared/react/images/ThinVIcon';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';

const FilterButton = ({ filterKey, text, active, onClick, icon, dataTestId }) => {
  const theme = useTheme();

  const getIcon = () => {
    if (icon) {
      return icon;
    }

    if (active) {
      return <ThinVIcon />;
    }

    return <ThinPlusIcon />;
  };

  return (
    <LayoutRoot
      active={active}
      backgroundColor={active ? theme.colors.white : theme.colors.gray5}
      onClick={e => onClick(e, filterKey, active)}
      data-test-id={dataTestId}
    >
      {getIcon()}
      <EllipsisContainer>
        <TextContainer>{text}</TextContainer>
      </EllipsisContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlexWrap)`
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 12px 24px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.ghostLight};
  transition: 0.2s;
  gap: 6px;
  align-items: center;
  flex-wrap: nowrap;
  max-width: 30ch;

  :hover {
    background-color: ${({ backgroundColor }) => shadeColor(backgroundColor, -1)};
  }

  :active {
    background-color: ${({ backgroundColor }) => shadeColor(backgroundColor, -2)};
  }
  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    width: max-content;
  } ;
`;

const EllipsisContainer = styled(EllipsisTextOverflowWrapper)`
  width: max-content;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  font-weight: 600;
  line-height: 13px;
`;

export default FilterButton;
