import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import PromotionBadges from 'app/src/complex_components/billings/badges/PromotionBadges';
import ContactsList from './ContactsList';
import InboxConversation from '../project/pages/inbox/InboxConversation';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';
import { useProjects } from 'app/src/context/ProjectsStore';
import { isExistingSession, useSessions } from 'app/src/context/SessionsStore';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { navigateToNextSession, navigateToSession } from './contacts.utils';
import { track } from '../../helpers/Tracker';
import { navigateToEmptyInbox } from '../../utils/navigation.utils';
import { useResponses } from 'app/src/context/ResponsesStore';
import { useAccount } from 'app/src/context/AccountStore';
import { useUser } from 'app/src/context/userStore/UserStore';
import SessionViewNotification from './SessionViewNotification';
import ContactBar from './ContactBar';
import SessionsFilters from './SessionsFilters';
import Utils from '../../utils';
import { STATUS_ACCEPTED } from 'app/src/constants/teamMembers.constants';
import { useBilling } from '../../hooks/useBillings';
import PricingBanner from 'app/src/complex_components/billings/badges/PricingBanner';
import { NOT_ANONYMOUS } from 'app/src/constants/responsesInbox.constants';

const getFetchSessionParams = ({ sessionView, sessionId, ...params }) => {
  if (sessionView) {
    params.sessionId = sessionId;
  }

  return params;
};

const getInitialArchivedValue = (archived, email, sessionView) => {
  if (archived !== null) {
    return archived;
  }
  if (!sessionView || email) {
    return false;
  }

  return archived;
};

const Contacts = () => {
  const history = useHistory();
  const location = useLocation();
  const { sessionId } = useParams();
  const contactsRef = useRef();
  const sessionViewRef = useRef(false);
  const refreshInterval = useRef(null);
  const [isFiltersOpened, toggleFilters] = useState(false);
  const [selectedResponses, setSelectedResponses] = useState(null);
  const [project, setProject] = useState(null);
  const [, { fetchResponsesBySessionId }] = useResponses();
  const [{ projects }, { getOrFetchProjectById }] = useProjects();
  const { hasReachedResponsesLimit } = useBilling();

  const [
    { sessions, loading, doneFetching, inboxFilters },
    {
      fetchUsersSessions,
      fetchNewSessions,
      clearSessionsStore,
      updateSessionProperties,
      getSession,
      setInboxFilters,
    },
  ] = useSessions();

  const [{ teamMembers }] = useAccount();
  const [{ user }] = useUser();
  const session = getSession(sessionId);

  const clearState = () => {
    sessionViewRef.current = false;
    clearSessionsStore();
    setSelectedResponses(null);
  };

  const fetchMore = async () => {
    if (hasReachedResponsesLimit) {
      return;
    }

    return fetchUsersSessions(inboxFilters);
  };

  const { setDoneScroll } = useInfiniteScroll(contactsRef, fetchMore);

  useEffect(() => {
    setInboxFilters({ [NOT_ANONYMOUS]: true });

    return () => {
      clearState();
    };
  }, []);

  useEffect(() => {
    if (loading) {
      return;
    }

    const isSessionInSessions = isExistingSession({ id: sessionId }, sessions);

    const handleSessions = async () => {
      if (sessions?.length && !isSessionInSessions) {
        await openSession(sessions[0]);
        return;
      }

      if (!sessions?.length && sessionId) {
        // If sessionViewRef is true, set it to false and navigate to empty inbox
        if (sessionViewRef.current) {
          sessionViewRef.current = false;
          setInboxFilters({ archived: false });
          navigateToEmptyInbox(history);
        }
        // If sessionViewRef is false and allSessions is not in history's state, set it to true
        else if (!history.location.state?.allSessions) {
          sessionViewRef.current = true;
        }
      }
    };

    handleSessions();
  }, [sessions, sessionId, loading]);

  useEffect(() => {
    if (hasReachedResponsesLimit) {
      return;
    }

    setSelectedResponses(null);

    const fetchParams = getFetchSessionParams({
      ...inboxFilters,
      sessionId,
      sessionView: sessionViewRef.current,
    });
    fetchUsersSessions(fetchParams);
    fetchNewSessions(inboxFilters);

    return () => {
      clearInterval(refreshInterval.current);
    };
  }, [inboxFilters.email, inboxFilters.archived, inboxFilters.assignee, inboxFilters.projectId]);

  useEffect(() => {
    setDoneScroll(doneFetching);
  }, [doneFetching]);

  useEffect(() => {
    const getResponses = async () => {
      setSelectedResponses(null);
      if (session?.id) {
        const responses = await fetchResponsesBySessionId(session?.id);
        setSelectedResponses(responses);
      }
    };

    getResponses();
  }, [session?.id, location.state]);

  useEffect(() => {
    fetchProject(session?.projectId);
  }, [session, location.state]);

  const fetchProject = async projectId => {
    const response = await getOrFetchProjectById(projectId);
    setProject(response);
  };

  const onSessionClick = session => {
    if (hasReachedResponsesLimit) {
      return;
    }

    track('Contacts view contact click');
    openSession(session);
  };

  const openSession = async session => {
    if (hasReachedResponsesLimit) {
      return;
    }

    navigateToSession(history, session.id, { allSessions: true });

    if (!session.read && !Utils.isGhostAccount()) {
      await updateSessionProperties(session.id, { read: true });
    }
  };

  const resetInbox = () => {
    clearState();
    navigateToEmptyInbox(history);
  };

  const onStatusChange = archived => {
    setInboxFilters({ archived });
    resetInbox();
  };

  const onEmailChange = email => {
    if (hasReachedResponsesLimit) {
      return;
    }

    track('Inbox filter email changed');

    setInboxFilters({ email });
    resetInbox();
  };

  const onAssigneeChange = assignee => {
    track('Inbox filter assignee changed');

    setInboxFilters({ assignee });
    resetInbox();
  };

  const onTolstoyChange = projectId => {
    track('Inbox filter tolstoy changed');

    setInboxFilters({ projectId });
    resetInbox();
  };

  const onFiltersClicked = () => {
    if (hasReachedResponsesLimit) {
      return;
    }
    toggleFilters(!isFiltersOpened);
  };

  const onUpdateSessionStatus = async session => {
    await updateSessionProperties(
      session.id,
      { archived: !inboxFilters.archived },
      { archived: inboxFilters.archived }
    );

    if (session.id !== sessionId) {
      return;
    }

    setSelectedResponses(null);
    return navigateToNextSession(history, { sessions, session });
  };

  const approvedTeamMembers = teamMembers.filter(member => member.status === STATUS_ACCEPTED);

  return (
    <ContentContainer hasReachedResponsesLimit={hasReachedResponsesLimit}>
      {!hasReachedResponsesLimit && <PromotionBadges tab="inbox" />}
      {hasReachedResponsesLimit && (
        <BadgeContainer>
          <PricingBanner
            text="INBOX"
            analyticsData={Utils.getAnalyticsLocationObject('Inbox Banner')}
          />
        </BadgeContainer>
      )}
      <LayoutRoot
        hasReachedResponsesLimit={hasReachedResponsesLimit}
        isFiltersOpened={isFiltersOpened}
        isBlur={hasReachedResponsesLimit}
      >
        <ContactBar
          hide={!sessions.length}
          session={session}
          teamMembers={approvedTeamMembers}
          user={user}
          loading={loading}
          onUpdateSessionStatus={onUpdateSessionStatus}
        />
        <SessionsFilters
          isOpened={isFiltersOpened}
          projects={projects}
          teamMembers={approvedTeamMembers}
          user={user}
          onEmailChange={onEmailChange}
          onAssigneeChange={onAssigneeChange}
          inboxFilters={inboxFilters}
          onTolstoyChange={onTolstoyChange}
          setInboxFilters={setInboxFilters}
        />
        <ContactsList
          sessions={sessions}
          selectedSessionId={sessionId}
          onEmailChange={onEmailChange}
          loading={loading}
          onFiltersClicked={onFiltersClicked}
          onSessionClick={onSessionClick}
          contactsRef={contactsRef}
          status={getInitialArchivedValue(
            inboxFilters.archived,
            inboxFilters.email,
            sessionViewRef.current
          )}
          onStatusChange={onStatusChange}
          onUpdateSessionStatus={onUpdateSessionStatus}
          teamMembers={approvedTeamMembers}
          user={user}
          inboxFilters={inboxFilters}
          isBlur={hasReachedResponsesLimit}
        >
          <SessionViewNotification
            sessionView={sessionViewRef.current}
            onClearFilters={() => onStatusChange(false)}
          />
        </ContactsList>
        <InboxConversation
          isInbox={true}
          loading={loading}
          project={project}
          conversation={session}
          responses={selectedResponses}
        />
      </LayoutRoot>
    </ContentContainer>
  );
};

export default Contacts;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  pointer-events: ${({ hasReachedResponsesLimit }) => (hasReachedResponsesLimit ? 'none' : '')};
  height: 100%;
  overflow: hidden;
`;

const BadgeContainer = styled.div`
  margin: 0 16px;
`;

const LayoutRoot = styled.div`
  display: grid;
  grid-template-areas: ${({ isFiltersOpened }) =>
    isFiltersOpened ? "'filters sessions conversation'" : "'sessions conversation'"};
  grid-template-columns: ${({ isFiltersOpened }) =>
    isFiltersOpened ? '312px 312px auto' : '312px auto'};
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  pointer-events: ${({ hasReachedResponsesLimit }) => (hasReachedResponsesLimit ? 'none' : '')};
`;
