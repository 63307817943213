import React from 'react';
import styled from 'styled-components';

import SearchIcon from 'shared/react/images/feed/SearchIcon';
import useFeedModal from 'shared/react/components/complex/context/hooks/useFeedModal';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';
import { TextSmall } from 'shared/react/components/basic/text/TextV2';
import { SEARCH_MODAL_KEY } from 'shared/react/constants/modals.constants';
import { FEED_MENU_BUTTON_DATA_TYPE } from 'shared/react/constants/feedPreviewDataTypes.constants';
import useFeedFeatures from 'shared/react/components/complex/context/hooks/useFeedFeatures';
import { FEATURE_FEED_VIDEO_SEARCH } from 'shared/react/constants/features.constants';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import useKeyboardNavigation from 'player/src/hooks/useKeyboardNavigation';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';

const FeedSearchButton = ({ currentStepKey, videoId, videoRef, isMobile, ...props }) => {
  const [{ isAppPreview, dynamic: isDynamic }] = useProjectConfig();
  const [, { pushModal }] = useFeedModal();
  const [, { getFeatureEnabled }] = useFeedFeatures();
  const { onKeyDown, setNextRef } = useKeyboardNavigation();
  const [, { t }] = useTranslation('feed.search');

  const shouldHide = !(isDynamic && getFeatureEnabled(FEATURE_FEED_VIDEO_SEARCH));

  const handleClickSearch = e => {
    e.stopPropagation();
    if (isAppPreview) {
      return;
    }

    videoRef.current.pause();

    pushModal({
      key: SEARCH_MODAL_KEY,
      props: {
        isMobile,
        currentStepKey,
        currentVideoId: videoId,
        currentVideoRef: videoRef,
        currentVideoUrl: videoRef?.current?.src,
      },
    });
  };

  if (shouldHide) {
    return null;
  }

  return (
    <LayoutRoot
      onClick={handleClickSearch}
      dataType={FEED_MENU_BUTTON_DATA_TYPE}
      {...props}
      role="button"
      aria-label="Find related content"
      ref={setNextRef}
      onKeyDown={onKeyDown}
      tabIndex="0"
    >
      <StyledFilledMenuIcon />
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  position: absolute;
  max-width: 170px;
  z-index: 40;
  left: 92%;
  transform: translate(-50%, 0);
`;

const InputWrapper = styled(HorizontalFlexCentered)`
  justify-content: flex-start;
  cursor: pointer;
  width: 100%;
  height: 28px;
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  padding: 5px;
  margin: 0 auto;
`;

const StyledFilledMenuIcon = styled(SearchIcon)`
  position: relative;
  height: 20px;
  width: 20px;
  margin: 8px;
  cursor: pointer;
  & path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const InputText = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.white};
`;

export default FeedSearchButton;
