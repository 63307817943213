import React from 'react';
import styled from 'styled-components';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import breadcrumbs_arrow from 'app/src/images/dashboard_v2/breadcrumbs_arrow.svg';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';

const Breadcrumbs = ({ breadcrumbs, ...props }) => {
  return (
    <LayoutRoot {...props}>
      {breadcrumbs.map(({ text, onClick }) => (
        <Crumb onClick={onClick} isClickable={!!onClick} key={text}>
          <EllipsisTextOverflowWrapper>
            <div>{text}</div>
          </EllipsisTextOverflowWrapper>
        </Crumb>
      ))}
    </LayoutRoot>
  );
};

const Crumb = styled.div`
  font-size: 20px;
  font-weight: 600;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  display: flex;
  align-items: center;
  max-width: 30ch;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    max-width: 20ch;
  }
`;

const LayoutRoot = styled(Gap8HorizontalFlexWrap)`
  align-items: center;
  flex-shrink: 0;

  ${Crumb}:not(:last-child)::after {
    display: inline-block;
    content: ' ';
    background-image: url('${breadcrumbs_arrow}');
    background-size: 12px 12px;
    height: 12px;
    width: 12px;
    margin-left: 8px;
    cursor: auto;
  }

  & > :last-child {
    color: ${({ theme }) => theme.colors.black};
    font-weight: 800;
    font-size: 20px;
  }
`;

export default Breadcrumbs;
