import { DataGrid } from '@mui/x-data-grid';
import AnalyticsTableGroupsHeader from 'app/src/complex_components/analytics/AnalyticsTableGroupsHeader';
import ExportButton from 'app/src/complex_components/analytics/conversion/ExportButton';
import {
  ANALYTICS_TABLE_COLUMN_CLASSNAMES,
  ANALYTICS_TABLE_COLUMN_GROUPS,
} from 'app/src/constants/analytics.constants';
import { useSnackBarActions } from 'app/src/context/ui_store/SnackBarStore';
import Utils from 'app/src/utils';
import React from 'react';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import SharedUtils from 'shared/react/utils/utils';
import styled from 'styled-components';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';

const NEW_LINE = '\n';

const AnalyticsTable = ({
  rows,
  columns,
  title,
  isExportEnabled,
  isLoading,
  setLoading,
  onDownloadCsv,
  onSortModelChange = undefined,
  sortModel = undefined,
  tableName,
  onRowClick,
  className = undefined,
  rowHeight = 64,
  headerHeight = undefined,
}) => {
  const { setSnackbar, setErrorSnackbar } = useSnackBarActions();

  const getValueGetters = () => {
    const valueGetters = {};

    columns.forEach(({ field, valueGetter }) => {
      if (valueGetter) {
        valueGetters[field] = valueGetter;
      }
    });

    return valueGetters;
  };

  const stripValueFromCsvSeparators = value => {
    if (!value || typeof value !== 'string') {
      return value;
    }

    return value.replaceAll(',', ' ').replaceAll(NEW_LINE, ' ');
  };

  const getCsvRow = (row, valueGetters) => {
    return columns.map(({ valueGetterField, field }) => {
      const valueGetter = valueGetters[field];
      const originalValue = row[valueGetterField || field];

      const value =
        valueGetter?.({
          value: originalValue,
          row,
        }) || originalValue;

      return stripValueFromCsvSeparators(value);
    });
  };

  const getCsvContent = rows => {
    const valueGetters = getValueGetters();

    return rows.map(row => {
      const csvRow = getCsvRow(row, valueGetters);

      return csvRow.join(',');
    });
  };

  const createContentCSV = rows => {
    const headers = columns.map(({ headerName, field }) => headerName || field).join(',');

    const allContent = getCsvContent(rows);

    return [headers, ...allContent].join(NEW_LINE);
  };

  const downloadCsv = () => {
    setLoading(true);

    onDownloadCsv();

    try {
      const fileData = createContentCSV(rows);
      SharedUtils.downloadText(fileData, tableName, 'text/csv', '.csv');
      setSnackbar('Download succeeded');
    } catch (error) {
      console.log(error);
      Utils.logError('Error generating CSV');
      setErrorSnackbar('Download failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <LayoutRoot className={className} isRowClickable={!!onRowClick}>
      <AnalyticsTableGroupsHeader columns={columns} />
      {isExportEnabled && (
        <ExportButton isEnabled={isExportEnabled} isLoading={isLoading} onClick={downloadCsv} />
      )}
      <TextTiny>{title}</TextTiny>
      <DataGrid
        autoHeight={false}
        headerHeight={headerHeight}
        rowHeight={rowHeight}
        pageSize={10}
        disableSelectionOnClick
        disableColumnMenu
        rows={rows || []}
        columns={columns || []}
        loading={isLoading}
        onRowClick={onRowClick ? onRowClick : undefined}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
      />
    </LayoutRoot>
  );
};

export default AnalyticsTable;

const LayoutRoot = styled(Gap8VerticalFlex)`
  position: relative;
  height: 85vh;
  width: 100%;
  margin-bottom: 0;

  & .MuiDataGrid-root {
    border: none;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 20px;

    & .${ANALYTICS_TABLE_COLUMN_GROUPS.spacer} {
      background-color: ${({ theme }) => theme.colors.gray5};
      width: 100% !important;
      height: 100% !important;
      padding: 0 !important;
      border-bottom: none !important;
    }

    & .MuiDataGrid-iconButtonContainer {
      position: absolute;
      right: 4px;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      background: ${({ theme }) => theme.getHexOpacity(theme.colors.white, 50)};
    }

    & .MuiDataGrid-columnHeader {
      height: 100%;
    }

    & .MuiDataGrid-columnHeader:hover .MuiDataGrid-iconButtonContainer {
      opacity: 1;
    }

    & .MuiDataGrid-columnHeaderWrapper {
      height: 100%;
    }

    & .MuiDataGrid-columnHeader:focus {
      outline: none;
    }

    & .MuiDataGrid-columnsContainer {
      border-bottom: 1px solid ${({ theme }) => theme.getHexOpacity(theme.colors.gray2, 40)};
      height: 100%;
    }

    & .MuiDataGrid-columnHeaderTitle {
      font-size: 12px;
      line-height: 16px;
      color: ${({ theme }) => theme.colors.gray2};
      text-align: center;
      white-space: pre-wrap;
      text-overflow: unset;
    }

    & .MuiDataGrid-columnHeaderTitleContainer {
      justify-content: center;
      padding: 0;
    }

    & .MuiDataGrid-cell {
      font-size: 12px;
      line-height: 16px;
      color: #000000;
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray35};
      text-align: center;
      padding: 8px 16px;
      outline: none;
    }

    & .MuiDataGrid-columnHeader,
    & .MuiDataGrid-cell {
      &:not(.${ANALYTICS_TABLE_COLUMN_GROUPS.spacer},
          .${ANALYTICS_TABLE_COLUMN_CLASSNAMES.lastColumn},
          :has(+ .${ANALYTICS_TABLE_COLUMN_GROUPS.spacer})) {
        border-right: 1px solid ${({ theme }) => theme.colors.gray35};
      }
    }

    & .MuiDataGrid-columnSeparator {
      display: none;
    }

    & .MuiDataGrid-footerContainer {
      display: flex;
      justify-content: center;
    }

    & .MuiDataGrid-row {
      cursor: ${({ isRowClickable }) => (isRowClickable ? 'pointer' : 'default')};
    }
  }
`;
