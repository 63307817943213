import React from 'react';

const WhatsappIcon = ({ fill = '#65CF72', width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.313 4.594a10.24 10.24 0 0 0-7.36-3.094C6.234 1.5 1.547 6.188 1.547 11.906c0 1.875.516 3.656 1.406 5.203L1.5 22.5l5.484-1.406c1.547.797 3.235 1.265 4.97 1.265 5.765 0 10.546-4.687 10.546-10.406 0-2.812-1.219-5.39-3.188-7.36Zm-7.36 15.984a8.667 8.667 0 0 1-4.406-1.219l-.281-.187-3.282.89.891-3.187-.234-.328c-.844-1.406-1.313-3-1.313-4.64 0-4.735 3.89-8.626 8.672-8.626 2.297 0 4.453.89 6.094 2.531 1.64 1.641 2.625 3.797 2.625 6.141 0 4.735-3.985 8.625-8.766 8.625Zm4.781-6.469a58.26 58.26 0 0 0-1.78-.843c-.235-.094-.423-.141-.61.14-.14.235-.656.844-.797 1.031-.188.141-.328.188-.563.047-1.546-.75-2.53-1.359-3.562-3.093-.281-.47.281-.422.75-1.407.094-.187.047-.328 0-.468-.047-.141-.61-1.407-.797-1.922-.234-.516-.422-.469-.61-.469h-.468a.88.88 0 0 0-.703.328c-.235.281-.89.89-.89 2.156 0 1.313.89 2.532 1.03 2.72.141.14 1.829 2.765 4.454 3.89 1.64.75 2.296.797 3.14.656.469-.047 1.5-.61 1.735-1.219.234-.61.234-1.125.14-1.219-.047-.14-.234-.187-.469-.328Z"
        fill={fill}
      />
    </svg>
  );
};
export default WhatsappIcon;
