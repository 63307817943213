import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import styled, { css } from 'styled-components';
import { DesignSizes, DesignWeights, DesignStates } from 'shared/react/theme/DesignSystem';

const DEFAULT_WEIGHT = DesignWeights.SOLID;

const blackText = css`
  color: ${({ theme }) => theme.colors.black};
`;

const getColorByWeight = (weight = DEFAULT_WEIGHT, solidColor, color) => {
  switch (weight) {
    case DesignWeights.SOLID:
      return solidColor;
    default:
      return color;
  }
};

const smallBadgeCss = css`
  padding: 3px 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  gap: 4px;
`;

const normalBadgeCss = css`
  padding: 5px 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  gap: 6px;
`;

const largeBadgeCss = css`
  padding: 2px 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  gap: 8px;
`;

const badgeSizesCssMap = {
  [DesignSizes.SMALL]: smallBadgeCss,
  [DesignSizes.NORMAL]: normalBadgeCss,
  [DesignSizes.LARGE]: largeBadgeCss,
};

export const badgeSizesCss = css`
  ${({ size = DesignSizes.SMALL }) => badgeSizesCssMap[size]}
`;

const badgeLightCss = css`
  border-radius: 16px;
`;
const badgeSolidCss = css`
  border-radius: 16px;
`;
const badgeSquareCss = css`
  border-radius: 4px;
`;

const badgeWeightsCssMap = {
  [DesignWeights.SOLID]: badgeSolidCss,
  [DesignWeights.LIGHT]: badgeLightCss,
  [DesignWeights.SQUARE]: badgeSquareCss,
};

export const badgeWeightCss = css`
  ${({ weight = DEFAULT_WEIGHT }) => badgeWeightsCssMap[weight]}
`;

const darkBadgeCss = css`
  background: ${({ theme, weight }) =>
    getColorByWeight(weight, theme.colors.black, theme.colors.neutralGray)};
  color: ${({ theme, weight }) => getColorByWeight(weight, theme.colors.white, theme.colors.black)};
`;

const lightBadgeCss = css`
  background: ${({ theme, weight }) =>
    getColorByWeight(weight, theme.colors.white, theme.colors.neutralLighter)};
  ${blackText};
`;

const primaryBadgeCss = css`
  background: ${({ theme, weight }) =>
    getColorByWeight(weight, theme.colors.primary, theme.colors.powerBlueLight)};
  color: ${({ theme, weight }) =>
    getColorByWeight(weight, theme.colors.white, theme.colors.powerBlueDark)};
`;

const badgeStateCssMap = {
  [DesignStates.DARK]: darkBadgeCss,
  [DesignStates.LIGHT]: lightBadgeCss,
  [DesignStates.PRIMARY]: primaryBadgeCss,
};

export const badgeStateCss = css`
  ${({ state = DesignStates.PRIMARY }) => badgeStateCssMap[state]}
`;

export const Badge = styled(HorizontalFlexCentered)`
  width: max-content;

  ${badgeSizesCss};
  ${badgeWeightCss};
  ${badgeStateCss};
`;
