import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TextBody } from 'shared/react/components/basic/text/TextV2';
import {
  AUTOMATION_TOOL_OTHER,
  AUTOMATION_TOOL_OUTREACH,
  EMAIL_PLATFORM_GMAIL,
} from 'app/src/constants/share.constants';
import Stepper from 'app/src/complex_components/Stepper';
import BasicSelect from 'shared/react/components/basic/basic_select/BasicSelect';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { useUser } from 'app/src/context/userStore/UserStore';
import { useApps } from 'app/src/context/AppsStore';
import AUTOMATION_TOOLS from './emailAutomationToolsList';
import CopyCodeButton from './CopyCodeButton';
import EmailShareViaOutreach from './EmailShareViaOutreach';
import { EMAIL_LAYOUT_TYPE_OPTIONS } from './email-layout-type.constants';
import { getNotAutomationTool } from './notAutomationTool';

const EmailAutomationTools = ({
  isCopyDisabled,
  onCopyClick,
  onEmailLayoutTypeChange,
  project,
  emailLayoutType,
}) => {
  const [selectedTool, setSelectedTool] = useState(AUTOMATION_TOOLS[0]);
  const [{ user }, { updateUser }] = useUser();
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [isEmailTrackingEnabled, setIsEmailTrackingEnabled] = useState(false);
  const [isEmailPixelTrackingEnabled, setIsEmailPixelTrackingEnabled] = useState(true);
  const [apps, { createOutreachResource }] = useApps();
  const isOutreach = !!apps.outreach;

  const handleSelectedToolChanged = async selectedTool => {
    setLoading(true);
    setSelectedTool(selectedTool);
    await updateUser({ id: user.id, emailAutomationTool: selectedTool.id });
    setLoading(false);
  };

  useEffect(() => {
    if (user?.emailAutomationTool) {
      const currentTool = AUTOMATION_TOOLS.find(tool => tool.id === user.emailAutomationTool);

      if (!currentTool) {
        return;
      }

      setSelectedTool(currentTool);
    }
  }, [user]);

  const firstStep = (
    <Step key={1}>
      <EmailStepperText>Select email distribution system</EmailStepperText>
      <StyledBasicSelect
        items={AUTOMATION_TOOLS}
        customHeight="40"
        value={selectedTool.name || selectedTool}
        onChange={handleSelectedToolChanged}
        loading={loading}
        inputPadding="4px 12px 4px 4px"
      />
    </Step>
  );

  const secondStep = project.feed ? (
    <Step key={2}>
      <EmailStepperText>Select layout</EmailStepperText>
      <StyledBasicSelect
        items={EMAIL_LAYOUT_TYPE_OPTIONS}
        customHeight="40"
        value={emailLayoutType}
        onChange={onEmailLayoutTypeChange}
        loading={loading}
      />
    </Step>
  ) : null;

  const normalSteps = [
    <Step key={3}>
      <EmailStepperText>Copy Code</EmailStepperText>
      <CopyCodeButton
        disabled={isCopyDisabled}
        onClick={() =>
          onCopyClick({
            email: '',
            selectedTool: selectedTool.id,
            location: 'Email Automation Tools',
            pixelTracking: isEmailPixelTrackingEnabled,
          })
        }
      >
        Copy code
      </CopyCodeButton>
    </Step>,
    <EmailStepperText key={3}>Paste into your tool.</EmailStepperText>,
  ];

  const notAutomationTool = getNotAutomationTool(
    setIsOpen,
    isOpen,
    isEmailTrackingEnabled,
    setIsEmailTrackingEnabled,
    email,
    setEmail,
    isEmailPixelTrackingEnabled,
    setIsEmailPixelTrackingEnabled,
    isCopyDisabled,
    onCopyClick,
    selectedTool
  );

  const isAutomationTool =
    selectedTool.id !== EMAIL_PLATFORM_GMAIL && selectedTool.id !== AUTOMATION_TOOL_OTHER;
  const stepComponents = [
    firstStep,
    secondStep,
    ...(isAutomationTool ? normalSteps : notAutomationTool),
  ].filter(Boolean);

  return (
    <StyledEmailAutomationTools>
      {isOutreach && selectedTool.id === AUTOMATION_TOOL_OUTREACH ? (
        <EmailShareViaOutreach
          token={apps.outreach.token}
          appId={apps.outreach.id}
          createOutreachResource={createOutreachResource}
          project={project}
          firstStep={firstStep}
        />
      ) : (
        <Stepper stepsGap={24} stepComponents={stepComponents} />
      )}
    </StyledEmailAutomationTools>
  );
};

export default EmailAutomationTools;

const StyledEmailAutomationTools = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
  height: 400px;
  max-height: 400px;
`;

const StyledBasicSelect = styled(BasicSelect)`
  max-width: 430px;
`;

const Step = styled(VerticalFlex)`
  gap: 12px;
`;

const EmailStepperText = styled(TextBody)`
  font-weight: 800;
  color: ${({ theme }) => theme.colors.black};
`;
