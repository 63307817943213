import CopyInput from 'app/src/complex_components/CopyInput';
import { track } from 'app/src/helpers/Tracker';
import React from 'react';
import styled from 'styled-components';
import { useAccount } from 'app/src/context/AccountStore';

const AppKey = () => {
  const [, { getAccountAppKey }] = useAccount();

  return (
    <LayoutRoot>
      <StyledCopyInput
        value={getAccountAppKey() || ''}
        onClick={() => {
          track('Copy App Key Click');
        }}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div``;

const StyledCopyInput = styled(CopyInput)`
  width: 420px;
`;

export default AppKey;
