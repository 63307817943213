import React from 'react';
import styled from 'styled-components';
import { ANALYTICS_OVERVIEW_BAR_CHARTS } from 'app/src/constants/analytics.constants';

const AnalyticsOverviewEmptyState = () => {
  return (
    <LayoutRoot>
      <ChartsContainer>
        {Object.values(ANALYTICS_OVERVIEW_BAR_CHARTS).map(
          ({ id, blurSrc }) =>
            blurSrc && <BarChartImage key={`bar-chart-blur-${id}`} src={blurSrc} />
        )}
      </ChartsContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div``;

const ChartsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 412px));
  gap: 24px;
  width: 100%;
`;

const BarChartImage = styled.img`
  border-radius: 16px;
`;

export default AnalyticsOverviewEmptyState;
