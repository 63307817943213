import React from 'react';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

type CellAlign = 'left' | 'center' | 'right';

type Props = {
  text: string;
  align?: CellAlign;
  disabled?: boolean;
};

const getAlignItems = (align: CellAlign) => {
  switch (align) {
    case 'left':
      return 'flex-start';
    case 'right':
      return 'flex-end';
    default:
      return 'center';
  }
};

const AnalyticsTableCell = ({ text, align, disabled }: Props) => {
  const formattedText = disabled ? '—' : text;

  return <LayoutRoot $align={align}>{formattedText}</LayoutRoot>;
};

const LayoutRoot = styled(VerticalFlex)<{ $align?: CellAlign }>`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: ${({ $align }) => getAlignItems($align)};
`;

export default AnalyticsTableCell;
