import React from 'react';
import styled from 'styled-components';

function OrientationPreviewOverlay() {
  return (
    <LayoutRoot>
      <Overlay />
      <RightOverlay />
    </LayoutRoot>
  );
}

export default OrientationPreviewOverlay;

const LayoutRoot = styled.div`
  width: 100%;
  display: grid;
  z-index: 1;
  grid-template-columns: 7fr 6fr 7fr;
  border-radius: 16px;

  @media (max-width: 450px) {
    display: none;
    grid-template-columns: repeat(3, auto);
    justify-self: center;
  }
`;

const Overlay = styled.div`
  grid-row: 1;
  grid-column: 1;
  background: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(18px);
  backdrop-filter: blur(18px);
  border-radius: 10px 0 0 10px;
  overflow: hidden;
`;

const RightOverlay = styled(Overlay)`
  grid-column: 3;
  border-radius: 0 10px 10px 0;
`;
