import React from 'react';
import styled from 'styled-components';
import CustomInput from 'app/src/basic_components/Input';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import { hexToRgb, rgbToHex } from './colorPickerTranslator.service';

const RGB_MAX_NUMBER = 255;

type Props = {
  value: string;
  onChange: (hex: string) => void;
};

const ColorPickerTranlsator = ({ value, onChange }: Props) => {
  const rgbValue = hexToRgb(value);

  const onRChange = e => {
    const r = e.target.value;
    const hex = rgbToHex(r, rgbValue.g, rgbValue.b);
    onChange(hex);
  };

  const onGChange = e => {
    const g = e.target.value;
    const hex = rgbToHex(rgbValue.r, g, rgbValue.b);
    onChange(hex);
  };

  const onBChange = e => {
    const b = e.target.value;
    const hex = rgbToHex(rgbValue.r, rgbValue.g, b);
    onChange(hex);
  };

  const onHexChange = e => {
    const hex = e.target.value;
    onChange(hex);
  };

  const RGB_INPUTS = [
    { value: rgbValue.r, onChange: onRChange, text: 'R' },
    { value: rgbValue.g, onChange: onGChange, text: 'G' },
    { value: rgbValue.b, onChange: onBChange, text: 'B' },
  ];

  return (
    <LayoutRoot>
      <InputContainer>
        <InputHeader>Hex</InputHeader>
        <HexColorInput value={value} onChange={onHexChange} />
      </InputContainer>
      {RGB_INPUTS.map(({ value, text, onChange }) => (
        <InputContainer key={text}>
          <InputHeader>{text}</InputHeader>
          <Input type="number" value={value} onChange={onChange} max={RGB_MAX_NUMBER} />
        </InputContainer>
      ))}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlex)`
  .MuiOutlinedInput-input {
    padding: 12px 16px;
  }
`;

const InputContainer = styled(VerticalFlex)``;

const InputHeader = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralDark};
`;

const HexColorInput = styled(CustomInput)`
  width: 100%;
`;

const Input = styled(CustomInput)`
  width: 64px;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    display: none;
  }
`;

export default ColorPickerTranlsator;
