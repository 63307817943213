import React from 'react';
import styled from 'styled-components';
import AnalyticsFilters from './filters/AnalyticsFilters';
import { ANALYTICS_HEADER_CLASS_NAME, ENGAGEMENT_KEY } from 'app/src/constants/analytics.constants';

type AnalyticsHeaderProps = {
  isAnalyticsOverviewEnabled: boolean;
  selectedTab: string;
  setSelectedTab: (v: string) => void;
  shouldBlur: boolean;
};

const AnalyticsHeader = ({
  selectedTab,
  shouldBlur,
  isAnalyticsOverviewEnabled,
  setSelectedTab,
}: AnalyticsHeaderProps) => {
  const isEngagement = selectedTab === ENGAGEMENT_KEY;

  return (
    <StyledAnalyticsHeader className={ANALYTICS_HEADER_CLASS_NAME}>
      <FirstFilterContainer>
        <AnalyticsFilters
          isEngagement={isEngagement}
          showFilter={!shouldBlur}
          isAnalyticsOverviewEnabled={isAnalyticsOverviewEnabled}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </FirstFilterContainer>
    </StyledAnalyticsHeader>
  );
};

export default AnalyticsHeader;

const StyledAnalyticsHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  background: ${({ theme }) => theme.colors.white};
  padding: 14px 32px;
`;

const FirstFilterContainer = styled.div`
  display: flex;
  flex-grow: 0;
  justify-content: space-between;
`;
