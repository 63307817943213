import React from 'react';
import styled, { useTheme } from 'styled-components';
import DeleteIcon from 'app/src/images/DeleteIcon';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import {
  MOVE_TOLSTOY_MODAL_KEY,
  DELETE_TOLSTOY_MODAL_KEY,
} from 'app/src/constants/modals.constants';
import { track } from 'app/src/helpers/Tracker';
import OpenFolder from 'app/src/images/folders/OpenFolder';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import Utils from 'app/src/utils';
import { useAccount } from 'app/src/context/AccountStore';
import Tooltip from 'app/src/basic_components/Tooltip';
import { useProjects } from 'src/context/ProjectsStore';

const BadgeButtons = ({ selectedTolstoys, onCancel, isFeed }) => {
  const [, { setCurrentModal, setModalProps }] = useModal();
  const [{ isAccountOwner }] = useAccount();
  const [{ projects }] = useProjects();

  const selectedProjects = selectedTolstoys.map(id => {
    return projects.find(project => {
      return project.id === id;
    });
  });

  const canDeleteTolstoys =
    selectedProjects.every(project => {
      return project.owner === Utils.getOwner();
    }) || isAccountOwner;

  const theme = useTheme();

  const onDelete = () => {
    if (!canDeleteTolstoys) {
      return;
    }

    track('Multiple Tolstoys Delete Click', { numberOfVideos: selectedTolstoys.length });
    setCurrentModal(DELETE_TOLSTOY_MODAL_KEY);
    setModalProps({ ids: selectedTolstoys });
    onCancel();
  };

  const moveToFolder = async () => {
    track('Multiple Tolstoys Move Click', { numberOfVideos: selectedTolstoys.length });
    setCurrentModal(MOVE_TOLSTOY_MODAL_KEY);
    setModalProps({ ids: selectedTolstoys });
    onCancel();
  };

  return (
    <>
      <Tooltip text={canDeleteTolstoys ? '' : 'Only owner of the Tolstoy can delete it'}>
        <DeleteButton disabled={!canDeleteTolstoys} onClick={onDelete}>
          <DeleteIcon fill={theme.colors.white} />
        </DeleteButton>
      </Tooltip>
      {!isFeed && (
        <Button onClick={moveToFolder}>
          <OpenFolder fill={theme.colors.white} />
        </Button>
      )}
    </>
  );
};

const Button = styled(HorizontalFlexWrap)`
  background: ${({ theme }) => theme.colors.gray2};
  border-radius: 12px;
  height: 24px;
  width: 32px;
  align-items: center;
  justify-content: center;
  cursor: ${({ disabled }) => (disabled ? '' : 'pointer')};
  & svg {
    width: 14px;
    height: 14px;
  }
`;

const DeleteButton = styled(Button)`
  background: ${({ theme }) => theme.colors.danger};

  opacity: ${({ disabled }) => (disabled ? '0.8' : '')};
`;

export default BadgeButtons;
