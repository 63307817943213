import * as React from 'react';

const QuizDeleteIcon = ({ ...props }) => (
  <svg
    width={14}
    height={16}
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.25 2.5h-2.344L9.844.75C9.563.281 9.094 0 8.563 0H5.405c-.531 0-1 .281-1.281.75L3.062 2.5H.75a.74.74 0 0 0-.75.75c0 .438.313.75.75.75H1l.656 10.594c.032.812.688 1.406 1.5 1.406h7.656c.813 0 1.47-.594 1.5-1.406L13 4h.25a.74.74 0 0 0 .75-.75.76.76 0 0 0-.75-.75Zm-7.844-1h3.157l.593 1H4.812l.594-1Zm5.407 13H3.155L2.5 4h8.969l-.656 10.5Z"
      fill="#FE2C55"
    />
  </svg>
);

export default QuizDeleteIcon;
