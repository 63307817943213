import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

import { useProjects } from 'app/src/context/ProjectsStore';
import { useDashboard } from 'app/src/context/ui_store/DashboardStore';
import { project } from 'app/src/types/entities';
import filterEntities from 'app/src/pages/dashboard/utils/filterEntities';
import useSortEntities from 'app/src/pages/dashboard/hooks/useSortEntities';

const useOffsiteFilters = () => {
  const [, { getDisplayedProjects }] = useProjects();
  const [{ offsiteFilters }, { setOffsiteFilters }] = useDashboard();
  const { folderId } = useParams();

  useEffect(() => {
    if (folderId && folderId !== 'all') {
      setOffsiteFilters({ folder: { eq: folderId } });
      return;
    }

    setOffsiteFilters({ folder: null });
  }, [folderId]);

  const sortedVideos = useSortEntities<project>(getDisplayedProjects(), 'updatedAt', true);

  return filterEntities<project>(sortedVideos, offsiteFilters);
};

export default useOffsiteFilters;
