import React, { useEffect, useState } from 'react';
import { useModal } from 'src/context/ui_store/ModalStore';
import useGetSearchProducts from 'src/hooks/useGetSearchProducts';
import styled from 'styled-components';
import SuggestedProduct from './components/suggested-prodcut/SuggestedProduct';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import MagicIcon from '../icons/MagicIcon';
import ComponentWithLoader from 'src/complex_components/ComponentWithLoader';
import useSuggestionsByVodAssetId from 'src/hooks/vod-connection-suggestions/useSuggestionsByVodAssetId';
import { useCurrentStoreApp } from 'app/src/context/helper/useCurrentStoreApp';

type Props = {
  vodAssetId: string;
};

const SuggestedProducts = ({ vodAssetId }: Props) => {
  const { app: selectedApp } = useCurrentStoreApp();

  const getSearchProducts = useGetSearchProducts(selectedApp);
  const [{ currentIds: vodAssetIds }] = useModal();
  const {
    pendingVodConnectionSuggestions,
    handleSuggestionRejected,
    handleSuggestionAccepted,
    shouldShowSuggestions,
  } = useSuggestionsByVodAssetId({ vodAssetId });
  const [suggestedSearchProducts, setSuggestedSearchProducts] = useState([]);
  const [isLoadingSearchProducts, setIsLoadingSearchProducts] = useState(true);

  const isMultipleTagging = vodAssetIds.length > 1;

  const fetchSuggestedSearchProducts = async () => {
    const ids = pendingVodConnectionSuggestions.map(({ externalProductId }) => externalProductId);

    const body = {
      ids,
      appUrl: selectedApp.appUrl,
      appKey: selectedApp.appKey,
      isSearchByIds: true,
      customLimit: ids.length,
    };

    const { searchProducts } = await getSearchProducts(body);

    // pendingVodConnectionSuggestions are already sorted by score
    // so we need to sort searchProducts by the same order
    searchProducts.sort((a, b) => {
      return ids.indexOf(a.productId) - ids.indexOf(b.productId);
    });

    setSuggestedSearchProducts(searchProducts);
    setIsLoadingSearchProducts(false);
  };

  const getVodConnectionSuggestionId = externalProductId => {
    const suggestion = pendingVodConnectionSuggestions.find(
      ({ externalProductId: suggestionExternalProductId }) =>
        suggestionExternalProductId === externalProductId
    );

    return suggestion?.id;
  };

  useEffect(() => {
    if (!pendingVodConnectionSuggestions.length || !selectedApp) {
      return;
    }

    fetchSuggestedSearchProducts();
  }, [pendingVodConnectionSuggestions.length, selectedApp]);

  if (!shouldShowSuggestions || isMultipleTagging) {
    return null;
  }

  return (
    <LayoutRoot>
      <Header>
        <MagicIcon />
        <StyledTextTiny>
          Suggested ({suggestedSearchProducts.length || pendingVodConnectionSuggestions.length})
        </StyledTextTiny>
      </Header>
      <ComponentWithLoader isLoading={isLoadingSearchProducts}>
        <Content>
          {suggestedSearchProducts.map(({ productId, title, imageUrl, handle }) => {
            return (
              <SuggestedProduct
                key={productId}
                productId={productId}
                title={title}
                handle={handle}
                imageUrl={imageUrl}
                onSuggestionRejected={handleSuggestionRejected}
                onSuggestionAccepted={handleSuggestionAccepted}
                vodAssetId={vodAssetId}
                vodConnectionSuggestionId={getVodConnectionSuggestionId(productId)}
              />
            );
          })}
        </Content>
      </ComponentWithLoader>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)``;

const Header = styled(Gap8HorizontalFlex)`
  align-items: center;
`;

const StyledTextTiny = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.grapePurple};
`;

const Content = styled(VerticalFlex)`
  gap: 4px;
`;

export default SuggestedProducts;
