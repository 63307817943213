import { INTERCOM_EVENT_NAMES } from 'src/constants/intercom.constants';
import { useOnboarding } from 'src/context/ui_store/OnboardingStore';
import { track } from 'src/helpers/Tracker';
import useNavigation from 'src/hooks/useNavigation';
import { AnalyticsDataProps } from 'src/types/common/common';
import { trackIntercomEvent } from 'src/utils/intercom.utils';
import {
  ONBOARDING_TEMPLATES,
  OnboardingTemplateEndStageFunctionKey,
  OnboardingTemplateKey,
} from '../templates/constants/onboardingTemplates.constants';
import useAccountOnboarding from './useAccountOnboarding';
import { useProjects } from 'app/src/context/ProjectsStore';
import { publishMethodOptions } from 'app/src/types/entities';
import { useApps } from 'app/src/context/AppsStore';
import { useMemo } from 'react';
import { getRules } from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/helpers/getRulesArray';
import useDynamicVideoIdsByProject from 'app/src/hooks/use-dynamic-video-ids-by-project/useDynamicVideoIdsByProject';
import {
  MatchConditions,
  VIDEO_RULES,
} from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/constants/dynamicConditions.constants';
import { useRuleGroupByProjectId } from 'app/src/context/RulesStore';
import { defaultStepData } from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/editStepDefaultData';
import { ONSITE_TARGET_PAGES } from 'app/src/pages/dashboard/constants/onsiteTargetPages.constants';
import { DesignStates } from 'shared/react/theme/DesignSystem';

type useAccountOnboardingTemplatesType = {
  getTemplateProgressText: () => string;
  handleBackButtonClick: () => void;
  handleNextButtonClick: () => Promise<void>;
  handleTemplateCreateClick: (templateKey: string) => void;
  getNextButtonText: () => string;
  getTemplateAnalyticsData: () => AnalyticsDataProps;
  getStageProps: () => {
    [key: string]: any;
  };
  getNextButtonProps: () => {
    text: string;
    type: string;
  };
  updateTemplateProgress: () => Promise<void>;
};

const useAccountOnboardingTemplates = (): useAccountOnboardingTemplatesType => {
  const [
    { activeTemplateKey, activeTemplateStageIndex, activeTemplateProjectId },
    { setActiveTemplateStageIndex, setActiveTemplateKey, setIsTemplatesNextStepBlocked },
  ] = useOnboarding();
  const {
    navigateToOnboardingTemplates,
    navigateToOnboardingTemplateStage,
    navigateToProjectVideos,
  } = useNavigation();
  const { onboardingStatuses, updateOnboardingStatuses, handleCreateTemplateProject } =
    useAccountOnboarding();
  const [{ projects }, { publishToShopApp, createProjectStep }] = useProjects();
  const [{ selectedAppUrl }] = useApps();

  const shopAppProjects = projects.filter(
    ({ publishMethod, appUrl }) =>
      appUrl === selectedAppUrl && publishMethod === publishMethodOptions.shopApp
  );
  const shopAppPdpProject = shopAppProjects.find(
    ({ targetPage }) => targetPage === ONSITE_TARGET_PAGES.ProductPages
  );
  const shopAppHomePageProject = shopAppProjects.find(
    ({ targetPage }) => targetPage === ONSITE_TARGET_PAGES.HomePages
  );

  const [projectRuleGroup] = useRuleGroupByProjectId(shopAppPdpProject?.id);
  const rules = projectRuleGroup?.rules;
  const matchConditions = rules?.length > 1 ? MatchConditions.matchAny : MatchConditions.matchAll;
  const currentRules = rules?.flatMap(rule => rule) || [];
  const normalRules = currentRules.filter(({ type }) => !VIDEO_RULES.includes(type));
  const videoRules = currentRules.filter(({ type }) => VIDEO_RULES.includes(type));
  const matchConditionsRuleGroup = useMemo(() => {
    return {
      enabled: true,
      rules: getRules({ rules: normalRules, matchConditions, videoRules }),
      appKey: shopAppPdpProject?.appKey,
    };
  }, [projectRuleGroup?.rules, matchConditions]);

  const { videoIds: dynamicShopAppProjectVideoIds } = useDynamicVideoIdsByProject(
    shopAppPdpProject,
    matchConditionsRuleGroup,
    shopAppPdpProject?.dynamic
  );

  const END_STAGE_FUNCTIONS = {
    [OnboardingTemplateEndStageFunctionKey.finalize]: () => {
      navigateToProjectVideos(activeTemplateProjectId);
    },
    [OnboardingTemplateEndStageFunctionKey.publishShopProjects]: () => {
      // Add videos to manual shop app project
      const videoIdsToAdd = dynamicShopAppProjectVideoIds.filter(
        videoId => !shopAppHomePageProject.steps.items.find(step => videoId === step.videoId)
      );

      videoIdsToAdd.forEach(videoId => {
        const newStep = {
          ...defaultStepData(shopAppHomePageProject.feed, true),
          videoId,
          projectId: shopAppHomePageProject.id,
        };
        createProjectStep(newStep, shopAppHomePageProject);
      });

      // Publish both shop app projects
      shopAppProjects.forEach(({ publishId }) => publishToShopApp(publishId));
    },
    [OnboardingTemplateEndStageFunctionKey.finalizeShopApp]: navigateToOnboardingTemplates,
  };

  const getTemplateAnalyticsData = (): AnalyticsDataProps => {
    const currentTemplate = ONBOARDING_TEMPLATES[activeTemplateKey];
    const currentStageKey = currentTemplate?.stages[activeTemplateStageIndex];

    return {
      templateKey: activeTemplateKey,
      stageKey: currentStageKey,
    };
  };

  const getStageProps = () => {
    if (!activeTemplateKey) {
      return {};
    }

    const currentTemplate = ONBOARDING_TEMPLATES[activeTemplateKey];

    const currentStageKey = currentTemplate.stages[activeTemplateStageIndex];

    return currentTemplate.stageProps[currentStageKey] || {};
  };

  const getTemplateProgressText = (): string => {
    const numOfTemplateStages = ONBOARDING_TEMPLATES[activeTemplateKey].stages.length;

    return `${activeTemplateStageIndex + 1}/${numOfTemplateStages}`;
  };

  const getInitialTemplateStageKey = (templateKey: string): string => {
    return ONBOARDING_TEMPLATES[templateKey].stages[0];
  };

  const handleBackButtonClick = (): void => {
    setIsTemplatesNextStepBlocked(false);

    track('Onboarding Template Back Click', getTemplateAnalyticsData());

    if (activeTemplateStageIndex > 0) {
      const previousStageIndex = activeTemplateStageIndex - 1;
      const previousStage = ONBOARDING_TEMPLATES[activeTemplateKey].stages[previousStageIndex];
      setActiveTemplateStageIndex(previousStageIndex);
      navigateToOnboardingTemplateStage(previousStage);
      return;
    }

    navigateToOnboardingTemplates();
    setActiveTemplateStageIndex(null);
    setActiveTemplateKey(null);
  };

  const updateTemplateProgress = async () => {
    const { stageIndex = 0, isDone: hasCompletedTemplate } =
      onboardingStatuses.templates[activeTemplateKey];

    if (hasCompletedTemplate) {
      return;
    }

    const currentTemplate = ONBOARDING_TEMPLATES[activeTemplateKey];

    const isDone = stageIndex >= currentTemplate.stages.length - 1;

    const isDoneTimestamp = isDone ? new Date().toISOString() : undefined;

    const onboardingStatusesPayload = {
      stageIndex: isDone ? currentTemplate.stages.length - 1 : stageIndex + 1,
      isDone,
      isDoneTimestamp,
    };

    if (isDone) {
      trackIntercomEvent(INTERCOM_EVENT_NAMES.onboardingTemplateCompleted, {
        templateKey: activeTemplateKey,
        timestamp: isDoneTimestamp,
      });
    }

    return updateOnboardingStatuses(activeTemplateKey, onboardingStatusesPayload);
  };

  const handleNextButtonClick = async () => {
    const { stageIndex = 0 } = onboardingStatuses.templates[activeTemplateKey];

    const currentTemplate = ONBOARDING_TEMPLATES[activeTemplateKey];

    const nextStageIndex = activeTemplateStageIndex + 1;
    const nextStageKey = currentTemplate.stages[nextStageIndex];
    if (nextStageKey) {
      setActiveTemplateStageIndex(nextStageIndex);
      navigateToOnboardingTemplateStage(nextStageKey);
    }

    const currentStageKey = currentTemplate.stages[activeTemplateStageIndex];

    const endStageFunctionKey = currentTemplate.stageProps[currentStageKey]?.nextButtonFunctionKey;

    END_STAGE_FUNCTIONS[endStageFunctionKey]?.();

    const isDone = stageIndex >= currentTemplate.stages.length - 1;

    const analyticsData = getTemplateAnalyticsData();

    if (isDone) {
      analyticsData.isDone = true;
    }

    track('Onboarding Template Next Click', analyticsData);

    return updateTemplateProgress();
  };

  const handleTemplateCreateClick = async (templateKey: OnboardingTemplateKey) => {
    setActiveTemplateKey(templateKey);

    track('Onboarding Template Create Click', {
      templateKey,
    });

    navigateToOnboardingTemplateStage(getInitialTemplateStageKey(templateKey));

    await handleCreateTemplateProject(templateKey);
  };

  const getNextButtonText = () => {
    const currentTemplate = ONBOARDING_TEMPLATES[activeTemplateKey];

    const templateStageKey = currentTemplate.stages[activeTemplateStageIndex];

    return currentTemplate.stageProps[templateStageKey]?.nextButtonText || 'Next';
  };

  const getNextButtonProps = () => {
    const currentTemplate = ONBOARDING_TEMPLATES[activeTemplateKey];

    const templateStageKey = currentTemplate.stages[activeTemplateStageIndex];

    return {
      text: currentTemplate.stageProps[templateStageKey]?.nextButtonText || 'Next',
      type: currentTemplate.stageProps[templateStageKey]?.nextButtonType || DesignStates.PRIMARY,
    };
  };

  return {
    handleTemplateCreateClick,
    getTemplateProgressText,
    handleBackButtonClick,
    handleNextButtonClick,
    getNextButtonText,
    getTemplateAnalyticsData,
    getStageProps,
    getNextButtonProps,
    updateTemplateProgress,
  };
};

export default useAccountOnboardingTemplates;
