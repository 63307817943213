import { useEffect, useRef, useState } from 'react';

import { getVideoBaseUrl } from 'player/src/components/player/player.utils';
import { useFeedIsMobile } from 'shared/react/hooks/useIsMobile';
import { VideoAnimatedCaptions } from 'shared/video-captions/animated';

const useVideoCaptions = ({ isSubtitlesEnabled, currentVideoRef, step }) => {
  const { videoOwner, videoId } = step;

  const isMobile = useFeedIsMobile();
  const baseUrl = getVideoBaseUrl(videoOwner);
  const trackRef = useRef();
  const [currentCaptions, setCurrentCaptions] = useState();
  const [animatedCaptions, setAnimatedCaptions] = useState();

  const trackSrc = `${baseUrl}/${videoId}/${videoId}.vtt`;

  const onTrackLoaded = () => {
    const tracks = currentVideoRef?.current?.textTracks?.[0];

    if (!tracks) {
      return;
    }

    tracks.mode = 'hidden';

    tracks.oncuechange = () => {
      const activeCueText = tracks?.activeCues?.[0]?.text;
      setCurrentCaptions(activeCueText);
    };
  };

  useEffect(() => {
    if (
      !currentVideoRef?.current ||
      (!isSubtitlesEnabled && !step?.videoCaptions?.enabled) ||
      animatedCaptions
    ) {
      return;
    }

    if (step?.videoCaptions?.enabled) {
      const captions = new VideoAnimatedCaptions({
        videoElement: currentVideoRef.current,
        captionsUrl: trackSrc,
      });
      setAnimatedCaptions(captions);
      return;
    }

    trackRef.current.onload = onTrackLoaded;
    onTrackLoaded();
  }, [currentVideoRef?.current, isSubtitlesEnabled, isMobile]);

  return { trackRef, trackSrc, currentCaptions };
};

export default useVideoCaptions;
