import React from 'react';

function VIcon({ fill, width, height }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '23'}
      height={height || '17'}
      fill="none"
      viewBox="0 0 23 17"
    >
      <path
        fill={fill || '#fff'}
        d="M21.75 3.797c.75-.75.75-1.922 0-2.672-.75-.703-1.922-.703-2.625 0L8.437 11.813 3.75 7.125c-.75-.703-1.922-.703-2.625 0-.75.75-.75 1.922 0 2.672l6 6c.328.375.844.562 1.313.562a1.85 1.85 0 001.312-.562l12-12z"
      />
    </svg>
  );
}

export default VIcon;
