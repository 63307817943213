import React from 'react';

const CameraIcon = () => (
  <svg width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.547 1.406c-.39-.195-.899-.195-1.29.078l-4.023 2.813a.665.665 0 0 0-.156.898c.195.274.586.313.899.117L22 2.5v10l-4.023-2.813a.665.665 0 0 0-.899.157.665.665 0 0 0 .156.898l4.024 2.813c.234.156.469.195.742.195.195 0 .39-.04.547-.117.43-.235.703-.664.703-1.133v-10c0-.469-.273-.86-.703-1.094ZM13.25 0h-10a2.492 2.492 0 0 0-2.5 2.5v10c0 1.406 1.094 2.5 2.5 2.5h10c1.367 0 2.5-1.094 2.5-2.5v-10c0-1.367-1.133-2.5-2.5-2.5Zm1.25 12.5c0 .703-.586 1.25-1.25 1.25h-10c-.703 0-1.25-.547-1.25-1.25v-10c0-.664.547-1.25 1.25-1.25h10c.664 0 1.25.586 1.25 1.25v10Z"
      fill="#090A0B"
    />
  </svg>
);

export default CameraIcon;
