import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import HorizontalFlexWrap from '../flex_layouts/HorizontalFlexWrap';
import ReplyBarButtons from './ReplyBarButtons';
import SendIcon from '../../../images/SendIcon';
import LoadingIndicator from '../../basic/LoadingIndicator';
import { REPLY_TEXT_DATA_TEST_ID } from '../../../constants/dataTestIds.constants';
import useIsMounted from '../../../hooks/useIsMounted';

const INPUT_HEIGHT = 26;
const TEXT_INPUT_MAX_LENGTH = 1000;

const getElementHeight = (element, pseudo = '') => {
  const elementStyles = window.getComputedStyle(element, pseudo);
  return +elementStyles.height.replace('px', '');
};

const ReplyBar = ({
  session,
  onVideoReply,
  onScreenRecording,
  onMacroReply,
  onTextReply,
  onProductReply,
  onAudioReply,
  textReplyInput,
  shouldShowToolbar,
  shouldInputAutoFocus = true,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [textReply, setTextReply] = useState('');
  const [inputHeight, setInputHeight] = useState(INPUT_HEIGHT);
  const textareaRef = useRef();
  const isMounted = useIsMounted();

  useEffect(() => {
    if (textReplyInput) {
      return;
    }
    handleInput('');
  }, [session]);

  const onInput = e => {
    handleInput(e.target.value);
  };

  const handleInput = value => {
    if (value.length > TEXT_INPUT_MAX_LENGTH || !textareaRef?.current) {
      return;
    }

    textareaRef.current.parentNode.dataset.replicatedValue = value;
    setTextReply(value);

    const inputHeight = getElementHeight(textareaRef.current);
    const pseudoHeight = getElementHeight(textareaRef.current.parentNode, ':after');

    if (value.length > textReply.length && pseudoHeight < inputHeight) {
      return;
    }

    if (value.length < textReply.length && inputHeight < pseudoHeight) {
      return;
    }

    setInputHeight(pseudoHeight);
  };

  const handleTextReply = async () => {
    if (textReply.length || textReplyInput) {
      setLoading(true);
      await onTextReply(textReply);
      if (isMounted()) {
        setLoading(false);
        handleInput('');
        textareaRef?.current?.focus();
      }
    }
  };

  const handleClear = () => {
    handleInput('');
  };

  const getInput = () => {
    if (textReplyInput) {
      return textReplyInput;
    }

    return (
      <StyledInputContainer multiLine={multiLine}>
        <StyledInput
          autoFocus={shouldInputAutoFocus}
          value={textReply}
          placeholder="Reply with text"
          onInput={onInput}
          ref={textareaRef}
          inputHeight={inputHeight}
        >
          <span>{textReply}</span>
        </StyledInput>
      </StyledInputContainer>
    );
  };

  const multiLine = inputHeight !== INPUT_HEIGHT;

  return (
    <StyledReplyBar {...props}>
      <Container>
        {!shouldShowToolbar && (
          <ReplyBarButtons
            onVideoReply={onVideoReply}
            onMacroReply={onMacroReply}
            onScreenRecording={onScreenRecording}
            onProductReply={onProductReply}
            onAudioReply={onAudioReply}
            onClear={handleClear}
          />
        )}
        <TextSendContainer data-test-id={REPLY_TEXT_DATA_TEST_ID}>
          {getInput()}
          <SendContainer
            onClick={handleTextReply}
            multiLine={multiLine}
            inputHeight={inputHeight}
            disabled={!textReply.length}
          >
            {loading ? <LoadingIndicator /> : <SendIcon />}
          </SendContainer>
        </TextSendContainer>
      </Container>
    </StyledReplyBar>
  );
};

export default ReplyBar;

const StyledReplyBar = styled(HorizontalFlexWrap)`
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 16px;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
  flex-wrap: nowrap;
`;

const StyledInput = styled.textarea`
  border: none;
  resize: none;
  outline: none;
  height: ${({ inputHeight }) => `${inputHeight}px`};
  padding: 0;

  ::placeholder {
    color: unset;
  }

  &:focus::placeholder {
    color: transparent;
  }
`;

const StyledInputContainer = styled.div`
  display: grid;
  flex-grow: 1;
  border-radius: ${({ multiLine }) => (multiLine ? '10px' : '56px')};
  background: ${({ theme }) => theme.colors.gray5};
  padding-right: ${({ multiLine }) => (multiLine ? '50px' : '16px')};
  min-height: 32px;
  padding: 3px 16px;
  align-items: center;

  &::after {
    content: attr(data-replicated-value) ' ';
    visibility: hidden;
    padding: 1px 0;
  }

  &::after,
  & > textarea {
    padding-right: 10px;
    word-break: break-word;
    grid-area: 1 / 1 / 2 / 2;
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    max-height: 80px;
    color: ${({ theme }) => theme.colors.black};

    white-space: pre-wrap;
    background: transparent;
  }
`;

const SendContainer = styled.div`
  display: grid;
  place-content: center;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? '0.8' : 'initial')};
`;

const TextSendContainer = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  gap: 8px;
`;
