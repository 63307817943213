import React from 'react';

const StarHalfFilled = ({ color = 'black', opacity = 1 }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_24498_5704)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27V2Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 17.27V2L14.81 8.63L22 9.24L16.54 13.97L18.18 21L12 17.27Z"
          fill={color}
          opacity={opacity}
        />
      </g>
    </svg>
  );
};

export default StarHalfFilled;
