import { VIDEOS_PROD } from 'src/constants/analytics.constants';
import { CUBE_PROPERTY_KEYS } from 'src/constants/cube.constants';
import {
  GetVideoMetricsProps,
  VideoMetric,
  ConsolidatedVideoMetric,
  CurrencyData,
  RevenueByCurrency,
} from './types/getVideoMetrics.types';
import { ResultSet } from '@cubejs-client/core';
import { Rate } from './types/cube-common.types';

const formatRawVideoMetrics = (resultSet: ResultSet): VideoMetric[] => {
  return resultSet?.tablePivot().map(data => ({
    id: `${data[`${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.id}`]}`,
    bounceRate: +data[`${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.bounceRate}`],
    averageWatchPercentage: +data[`${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.averageWatchPercentage}`],
    duration: +data[`${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.duration}`],
    plays: +data[`${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.plays}`],
    conversionRate: +data[`${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.conversionRate}`],
    averageOrderValue: +data[`${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.averageOrderValue}`],
    watchedAllPercentage: +data[`${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.watchedAllPercentage}`],
    inVideoRevenue: +data[`${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.inVideoRevenue}`],
    postVideoRevenue: +data[`${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.postVideoRevenue}`],
    indirectRevenue: +data[`${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.indirectRevenue}`],
    totalRevenue: +data[`${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.totalRevenue}`],
    currency: `${data[`${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.currency}`]}`,
    numOfOrders: +data[`${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.numOfOrders}`],
  }));
};

const consolidateVideoMetrics = (videoMetrics: VideoMetric[]): ConsolidatedVideoMetric[] => {
  const consolidatedMetrics: { [key: string]: ConsolidatedVideoMetric } = {};

  videoMetrics.forEach(metric => {
    if (!consolidatedMetrics[metric.id]) {
      consolidatedMetrics[metric.id] = {
        ...metric,
        revenueByCurrency: {},
      };
    }

    const consolidated = consolidatedMetrics[metric.id];

    if (!consolidated.revenueByCurrency[metric.currency]) {
      consolidated.revenueByCurrency[metric.currency] = {
        inVideoRevenue: 0,
        postVideoRevenue: 0,
        indirectRevenue: 0,
        totalRevenue: 0,
      };
    }

    const currencyData = consolidated.revenueByCurrency[metric.currency];
    currencyData.inVideoRevenue += metric.inVideoRevenue;
    currencyData.postVideoRevenue += metric.postVideoRevenue;
    currencyData.indirectRevenue += metric.indirectRevenue;
    currencyData.totalRevenue += metric.totalRevenue;
  });

  return Object.values(consolidatedMetrics);
};

const convertRevenuesToCommonCurrency = (
  revenueByCurrency: RevenueByCurrency,
  commonCurrency: string,
  rates: Rate[]
): CurrencyData & { currency: string } => {
  const convertedRevenues = Object.entries(revenueByCurrency).reduce(
    (acc, [currency, data]) => {
      const conversionRate =
        currency === commonCurrency ? 1 : rates[currency] / rates[commonCurrency];

      acc.inVideoRevenue += data.inVideoRevenue * conversionRate;
      acc.postVideoRevenue += data.postVideoRevenue * conversionRate;
      acc.indirectRevenue += data.indirectRevenue * conversionRate;
      acc.totalRevenue += data.totalRevenue * conversionRate;

      return acc;
    },
    { inVideoRevenue: 0, postVideoRevenue: 0, indirectRevenue: 0, totalRevenue: 0 }
  );

  return {
    ...convertedRevenues,
    currency: commonCurrency,
  };
};

export const getVideoMetrics = ({
  resultSet,
  currency,
  rates,
}: GetVideoMetricsProps): VideoMetric[] => {
  const formattedVideoMetrics = formatRawVideoMetrics(resultSet);
  const consolidatedMetrics = consolidateVideoMetrics(formattedVideoMetrics);

  return consolidatedMetrics.map(metric => {
    const processedMetric = convertRevenuesToCommonCurrency(
      metric.revenueByCurrency,
      currency,
      rates
    );

    // omit revenueByCurrency from the final result
    const { revenueByCurrency, ...metricWithoutRevenueByCurrency } = metric;

    return {
      ...metricWithoutRevenueByCurrency,
      ...processedMetric,
      averageOrderValue: processedMetric.totalRevenue / metric.numOfOrders,
    };
  });
};
