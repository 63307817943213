import React from 'react';
import { ShareTypesContainer } from './ShareTypesStore';
import { DashboardContainer } from './DashboardStore';
import { ExtensionContainer } from './ExtensionStore';
import { SnackBarContainer } from 'app/src/context/ui_store/SnackBarStore';
import { ModalContainer } from 'app/src/context/ui_store/ModalStore';
import { DrawerContainer } from './DrawerStore';

const UIStore = ({ children }) => {
  return (
    <DashboardContainer>
      <ExtensionContainer>
        <ModalContainer>
          <DrawerContainer>
            <ShareTypesContainer>
              <SnackBarContainer>{children}</SnackBarContainer>
            </ShareTypesContainer>
          </DrawerContainer>
        </ModalContainer>
      </ExtensionContainer>
    </DashboardContainer>
  );
};

export default UIStore;
