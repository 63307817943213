import React, { useState } from 'react';
import MenuWithItems from 'app/src/complex_components/menu_items/MenuWithItems';
import { AnalyticsDataProps, AnchorElementProps } from 'app/src/types/common/common';
import { useVodLabels } from 'app/src/context/VodLabelsStore';
import XIconWithCircle from 'app/src/images/XIconWithCricle';
import { useVodConnectionActions } from 'app/src/context/VodConnectionStore';
import styled from 'styled-components';
import { track } from 'src/helpers/Tracker';

type Props = AnchorElementProps & {
  labels: string[];
  onClose: () => void;
  vodAssetId: string;
  analyticsData?: AnalyticsDataProps;
};

const VideoTaggedLabelsMenu = ({
  labels,
  anchorElement,
  onClose,
  vodAssetId,
  analyticsData,
}: Props) => {
  const [isLoadingKey, setIsLoadingKey] = useState('');
  const [, { getVodLabelById }] = useVodLabels();
  const { deleteConnectionByVodLabel } = useVodConnectionActions();
  const currentLabels = labels.map(getVodLabelById);

  const onDelete = async (vodLabelId: string) => {
    track('Video Label Deleted', analyticsData);
    setIsLoadingKey(vodLabelId);
    await deleteConnectionByVodLabel({ vodAssetId, vodLabelId });
    setIsLoadingKey('');
  };

  const menuItems = currentLabels.filter(Boolean).map(label => {
    return {
      text: label.name,
      endIcon: <XIconWithCircle onClick={() => onDelete(label.id)} />,
      showEndIconOnHover: true,
      isLoading: isLoadingKey === label.id,
    };
  });

  return (
    <MenuItems
      direction={{ vertical: 'top', horizontal: 'center' }}
      anchorElement={anchorElement}
      onClose={onClose}
      contentEndLocation="top"
      menuItems={menuItems}
    />
  );
};

const MenuItems = styled(MenuWithItems)`
  width: 202px;
`;

export default VideoTaggedLabelsMenu;
