import React from 'react';

function VideoResponseButtonIcon({ fill, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="24"
      viewBox="0 0 28 24"
      fill="none"
      {...props}
    >
      <path
        fill={fill || '#FFAAC9'}
        d="M18.5 5.25C18.5 4.031 17.469 3 16.25 3H2.75A2.25 2.25 0 00.5 5.25v13.5c0 1.266.984 2.25 2.25 2.25h13.5a2.25 2.25 0 002.25-2.25V5.25zm9 .75c0-1.172-1.406-1.875-2.39-1.219L20 8.344v7.36l5.11 3.562c.984.656 2.39-.047 2.39-1.22V6z"
      ></path>
    </svg>
  );
}

export default VideoResponseButtonIcon;
