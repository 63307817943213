import React from 'react';
import styled from 'styled-components';
import PublishMethodBadge from 'app/src/complex_components/publish_methods/PublishMethodBadge';
import { PUBLISH_METHODS } from 'shared/react/constants/tolstoy.constants';

const ProjectCardPublishMethodBadge = ({ publishMethod }) => {
  if (!publishMethod || !PUBLISH_METHODS[publishMethod]) {
    return <BadgePlaceholder />;
  }

  return <StyledPublishMethodBadge publishMethod={publishMethod} />;
};

const BadgePlaceholder = styled.div`
  min-height: 20px;
`;

export default ProjectCardPublishMethodBadge;

const StyledPublishMethodBadge = styled(PublishMethodBadge)`
  border: 1px solid ${({ theme }) => theme.colors.gray35};
  border-radius: 20px;
  height: 20px;
  padding: 0 8px 0 2px;
`;
