export const ALL_VIDEOS_PLAYLIST = {
  id: 'ALL_VIDEOS',
  name: 'All videos',
};

export const ALL_IMAGES_PLAYLIST = {
  id: 'ALL_IMAGES',
  name: 'All images',
};

export const TRENDING_VIDEOS_PLAYLIST = {
  id: 'TRENDING',
  name: 'Trending videos',
  tooltip: 'Available after connecting Instagram or TikTok.',
};

export const GALLERIES_PLAYLIST = {
  id: 'GALLERIES',
  name: 'Carousels',
};
