import React from 'react';
import styled from 'styled-components';
import { track } from '../../helpers/Tracker';
import { Select, MenuItem } from '@material-ui/core';
import { CONTACTS_STATUS_CLOSE, CONTACTS_STATUS_OPEN } from 'app/src/constants/contacts.constants';
import { TextH6 } from 'shared/react/components/complex/Text';
import Filters from 'app/src/images/Filters';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import { useSessions } from 'app/src/context/SessionsStore';
import { INBOX_FILTERS_CONTAINER_TEST_ID } from 'app/src/constants/dataTestIds.constants';

const getStatusValue = status => {
  return status ? CONTACTS_STATUS_CLOSE : CONTACTS_STATUS_OPEN;
};

const getTotalText = total => {
  if (!total) {
    return '';
  }

  return `(${total})`;
};

const SessionsFiltersBar = ({ status, onFiltersClicked, onStatusChange, inboxFilters }) => {
  const [{ total }] = useSessions();

  const onStatusFilterChange = e => {
    const newStatus = e.target.value === CONTACTS_STATUS_CLOSE;
    track(`Inbox Status Changed To ${e.target.value}`);

    onStatusChange(newStatus);
  };

  const title = `Inbox ${getTotalText(total)}`;
  const isFilterUsed = inboxFilters.assignee || inboxFilters.projectId;
  return (
    <StyledInboxFilters>
      <TitleContainer>
        <FiltersContainer isFilterUsed={isFilterUsed} onClick={onFiltersClicked} data-test-id={INBOX_FILTERS_CONTAINER_TEST_ID}>
          <FiltersIcon />
        </FiltersContainer>
        <Title>{title}</Title>
      </TitleContainer>
      <StatusSelect
        labelId="status-label"
        id="status"
        value={getStatusValue(status)}
        disableUnderline
        onChange={onStatusFilterChange}
      >
        <MenuItem value={CONTACTS_STATUS_OPEN}>Open</MenuItem>
        <MenuItem value={CONTACTS_STATUS_CLOSE}>Closed</MenuItem>
      </StatusSelect>
    </StyledInboxFilters>
  );
};

export default SessionsFiltersBar;

const StyledInboxFilters = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 12px;
`;

const StatusSelect = styled(Select)`
  border: none;
  width: min-content;
  margin-right: 5px;

  .MuiSelect-select:focus {
    background: none;
  }
`;

const TitleContainer = styled(Gap8HorizontalFlexWrap)``;

const FiltersContainer = styled.div`
  cursor: pointer;

  & path {
    fill: ${({ isFilterUsed, theme }) => isFilterUsed && theme.colors.white};
  }

  & rect {
    fill: ${({ isFilterUsed, theme }) => isFilterUsed && theme.colors.black};
  }
`;

const Title = styled(TextH6)`
  line-height: 40px;
`;

const FiltersIcon = styled(Filters)`
  width: 30px;
  height: 30px;
`;
