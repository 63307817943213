import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useVideos } from 'app/src/context/VideosStore';
import { track } from 'app/src/helpers/Tracker';
import Utils from 'app/src/utils';
import PreviewOnSampleSitePricing from 'app/src/complex_components/pricing/PreviewOnSampleSitePricing';
import WidgetStaticPreview from 'app/src/pages/modals/publish/previews/static_preview_items/WidgetStaticPreview';

function WidgetPreview({
  widgetPosition,
  isVertical,
  centeredContent,
  project,
  widgetHeader,
  shape,
  widgetSize,
  notificationBadge,
  border,
  themeColor,
  themeTextColor,
  bubbleText,
  bubbleTextEnabled,
  shouldShowPreviewOnSampleSite,
  bubbleObjectPosition,
  bubbleMarginBottom,
  bubbleMarginSide,
}) {
  const containerRef = useRef();
  const bubbleRef = useRef();
  const firstStep = project?.steps.items.find(step => step.name === project.stepsOrder[0]);
  const [{ video }, { getVideoUrl, handleVideoLoadError }] = useVideos(firstStep?.videoId);
  const [videoUrl, setVideoUrl] = useState(getVideoUrl(video));
  const containVideo = firstStep?.videoContain ?? false;

  const getSampleUrl = () => {
    track('Preview Sample Site Click');
    Utils.openInNewTab(`https://rubkik.myshopify.com/?widget=${project.publishId}`);
  };

  function getVerticalPosition() {
    switch (widgetPosition) {
      case 'bottomRight':
        return 'flex-end';
      case 'bottomLeft':
        return 'flex-end';
      default:
        return 'flex-end';
    }
  }

  function getHorizontalPosition() {
    switch (widgetPosition) {
      case 'bottomRight':
        return 'flex-end';
      case 'bottomLeft':
        return 'flex-start';
      default:
        return 'flex-end';
    }
  }

  const getCircleSize = () => {
    switch (widgetSize) {
      case 's':
        return '40';
      case 'm':
        return '60';
      case 'l':
        return '80';
      default:
        return '60';
    }
  };

  function getBadgeMarginTopRight() {
    if (shape !== 'circle') {
      return 0;
    }

    switch (widgetSize) {
      case 's':
        return '4px';
      case 'm':
        return '8px';
      case 'l':
        return '12px';
      default:
        return '0px';
    }
  }

  const horizontalPosition = centeredContent ? 'center' : getHorizontalPosition();

  const setBorderRadius = () => {
    if (widgetSize !== 's' || shape !== 'circle') {
      return '12px';
    }

    switch (widgetPosition) {
      case 'bottomRight':
        return '12px 12px 0 12px';
      case 'bottomLeft':
        return '12px 12px 12px 0';
      default:
        return '12px';
    }
  };

  const getWidgetContainerFlexDirection = () => {
    const isSmallCircle = shape === 'circle' && widgetSize === 's';
    if (!isSmallCircle) {
      return 'column';
    }

    if (widgetPosition === 'bottomLeft') {
      return 'row-reverse';
    }

    return 'row';
  };

  const isSmallCircle = shape === 'circle' && widgetSize === 's';

  const getVideoPosition = () => {
    let side = bubbleMarginSide ?? 35;
    let bottom = bubbleMarginBottom ?? 30;
    const container = containerRef.current;

    if (bottom + bubbleRef.current?.clientHeight >= container?.clientHeight) {
      bottom = container?.clientHeight - bubbleRef.current?.clientHeight - 10;
    }

    if (side + bubbleRef.current?.clientWidth >= container?.clientWidth) {
      side = container?.clientWidth - bubbleRef.current?.clientWidth - 10;
    }

    switch (widgetPosition) {
      case 'bottomRight':
        return `right: ${side}px; bottom:${bottom}px`;
      case 'bottomLeft':
        return `bottom: ${bottom}px;left: ${side}px`;
      default:
        return '8px';
    }
  };

  return (
    <LayoutRoot ref={containerRef}>
      <WidgetStaticPreview />
      <OverlayItemsContainer
        position={getVideoPosition()}
        ref={bubbleRef}
        verticalPosition={centeredContent ? 'center' : getVerticalPosition()}
      >
        <WidgetContainer
          flexDirection={getWidgetContainerFlexDirection()}
          horizontalPosition={horizontalPosition}
          circleSize={getCircleSize()}
          isSmallCircle={isSmallCircle}
        >
          {bubbleTextEnabled && bubbleText && (
            <BubbleTextContainer
              background={themeColor}
              borderRadius={setBorderRadius()}
              isSmallCircle={isSmallCircle}
            >
              <BubbleText color={themeTextColor}>{bubbleText}</BubbleText>
            </BubbleTextContainer>
          )}
          <BubbleContainer>
            {notificationBadge && (
              <Badge marginTopRight={getBadgeMarginTopRight()} shape={shape}>
                1
              </Badge>
            )}
            <VideoContainer
              shape={shape}
              borderColor={border && themeColor}
              circleSize={getCircleSize()}
              videoContain={containVideo}
              isVertical={isVertical}
            >
              <WidgetTextContainer
                circleSize={getCircleSize()}
                shape={shape}
                widgetHeader={widgetHeader}
              >
                <WidgetText circleSize={widgetSize} shape={shape}>
                  {widgetHeader}
                </WidgetText>
              </WidgetTextContainer>
              <Video
                shape={shape}
                circleSize={getCircleSize()}
                src={videoUrl}
                onError={() => handleVideoLoadError(video, videoUrl, setVideoUrl)}
                isVertical={isVertical}
                autoPlay
                muted
                loop
                autoplay
                playsInline
                allowFullScreen={false}
                disablePictureInPicture={true}
                controls={false}
                videoContain={containVideo}
                objectPosition={`${bubbleObjectPosition?.x}% ${bubbleObjectPosition?.y}%`}
              />
            </VideoContainer>
          </BubbleContainer>
        </WidgetContainer>
      </OverlayItemsContainer>
      {shouldShowPreviewOnSampleSite && <PreviewOnSampleSitePricing onClick={getSampleUrl} />}
    </LayoutRoot>
  );
}

export default WidgetPreview;

const LayoutRoot = styled.div`
  display: grid;
  height: 100%;
  width: 100%;
  max-width: 160px;
  gap: 8px;
  position: relative;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 100%;
  }
`;

const BubbleContainer = styled.div`
  position: relative;
`;

const OverlayItemsContainer = styled.div`
  align-self: ${({ verticalPosition }) => (!verticalPosition ? 'center' : verticalPosition)};
  position: absolute;
  ${({ position }) => position}
`;

const Video = styled.video`
  background-color: black;
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 3px;
  object-position: ${({ objectPosition }) => objectPosition};

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 100px;
    height: 100px;
    border-radius: 100px;
  }
`;

const WidgetTextContainer = styled.div`
  display: grid;
  z-index: 1;
  background: ${({ widgetHeader }) =>
    widgetHeader === '' || widgetHeader === null
      ? 'rgba(0,0,0,0)'
      : 'linear-gradient(180deg, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.3) 100%)'};
  justify-content: center;
  align-self: center;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
  position: absolute;
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    border-radius: 100px;
    width: 100px;
    height: 100px;
    overflow: hidden;
    font-size: 13px;
  }
`;

const WidgetText = styled.div`
  z-index: 2147483651;
  font-size: 10px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  color: white;
  overflow-wrap: anywhere;
`;

const Badge = styled.div`
  background: ${({ theme }) => theme.colors.notificationBadge};
  position: absolute;
  width: 15px;
  height: 15px;
  font-size: 9px;
  z-index: 1;
  right: 0;
  display: flex;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  justify-self: end;
  color: white;
  border-radius: 100%;
  margin-top: ${({ marginTopRight }) => marginTopRight};
  margin-right: ${({ marginTopRight }) => marginTopRight};

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    top: 0;
    right: 0;
  }
`;

const VideoContainer = styled.div`
  max-width: 100%;
  ${({ borderColor }) => (borderColor ? `border: 2px solid ${borderColor}` : '')};
  position: relative;
  object-fit: cover;
  height: ${({ shape, circleSize, isVertical }) =>
    shape === 'circle' ? circleSize : isVertical ? '106.67' : '90'}px;
  width: ${({ shape, circleSize, isVertical }) =>
    shape === 'circle' ? circleSize : isVertical ? '90' : '120'}px;

  border-radius: ${({ shape, circleSize }) => (shape === 'circle' ? circleSize : '8')}px;
  overflow: hidden;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 100px;
    height: 100px;
    border-radius: 100px;
  }
`;

const WidgetContainer = styled.div`
  position: relative;
  display: flex;
  max-width: 100%;
  justify-self: ${props => (!props.horizontalPosition ? 'center' : props.horizontalPosition)};
  width: fit-content;
  gap: 4px;
  flex-direction: ${({ flexDirection }) => flexDirection};
  height: ${({ isSmallCircle, circleSize }) => (isSmallCircle ? circleSize : 'fit-content')};
`;

const BubbleTextContainer = styled.div`
  padding: 3px 8px;
  background: ${({ background }) => background};
  opacity: 0.67;
  max-width: 95px;
  height: fit-content;
  align-self: ${({ isSmallCircle }) => (isSmallCircle ? 'flex-start' : 'center')};
  border-radius: ${({ borderRadius }) => borderRadius};
  box-shadow: 0px 6px 40px -2px rgba(50, 50, 93, 0.3), 0px 3px 10px -3px rgba(0, 0, 0, 0.1);
`;

const BubbleText = styled.div`
  font-size: 6px;
  font-weight: bold;
  color: ${({ color }) => color};
  white-space: pre-line;
  overflow-wrap: break-word;
`;
