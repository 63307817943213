import React from 'react';
import styled from 'styled-components';
import FeedProductOptions from 'shared/react/components/complex/feed/feed_products/FeedProductOptions';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import XIcon from 'shared/react/images/XIcon';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import FeedBuyNowButton from 'shared/react/components/complex/feed/feed_products/feed_buy_now_button/FeedBuyNowButton';
import {
  PRODUCT_OPTION_DATA_TYPE,
  PRODUCT_OPTION_HEADER_DATA_TYPE,
  SELECT_INPUT_CONTAINER_DATA_TYPE,
} from 'shared/react/constants/feedPreviewDataTypes.constants';
import Utils from 'shared/react/utils/utils';
import FeedMultipleProductsButtonCss from 'shared/react/components/complex/feed/feed_products/feed_multiple_products/FeedMultipleProductsButtonCss';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';
import useIsPrincessPolly from 'shared/react/hooks/useIsPrincessPolly';
import { getDesignCss } from 'shared/react/utils/addSharedCss';

const FeedMultipleProductOptions = ({
  isShowingOptions,
  setIsShowingOptions,
  options,
  hasOptions,
  selectedVariant,
  appUrl,
  currentStepKey,
  handleProductOptionSelected,
  productHandle,
  ecomPlatform,
  productId,
  primaryHasQuantityPicker,
  design,
}) => {
  const { player } = useDesignSettingsContext();
  const { raidus: borderRadius } = player.feedCards.buttonsDesign.primary.border;
  const isPP = useIsPrincessPolly();

  if (!hasOptions) {
    return null;
  }

  const { id: selectedVariantId, isVariantAvailableForSale } = selectedVariant || {};
  return (
    <LayoutRoot isShowingOptions={isShowingOptions}>
      <OptionsContainer>
        <StyledFeedProductOptions
          productOptions={options}
          handleProductOptionSelected={handleProductOptionSelected}
          $design={design}
        />
      </OptionsContainer>
      <ButtonsContainer>
        <XButton onClick={() => setIsShowingOptions(false)} borderRadius={borderRadius}>
          <XIcon />
        </XButton>
        <StyledFeedBuyNowButton
          selectedVariantId={selectedVariantId}
          isVariantAvailableForSale={isVariantAvailableForSale}
          isDisabled={!isVariantAvailableForSale}
          appUrl={appUrl}
          currentStepKey={currentStepKey}
          productHandle={productHandle}
          ecomPlatform={ecomPlatform}
          productId={productId}
          $primaryHasQuantityPicker={primaryHasQuantityPicker}
          $isPP={isPP}
          $design={design?.secondaryCtaButton}
        />
      </ButtonsContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)`
  position: absolute;
  display: ${({ isShowingOptions }) => (isShowingOptions ? '' : 'none')};
  background-color: ${({ theme }) => theme.colors.white};
  height: 100%;
  width: 100%;
  transition: 0.3s;
  justify-content: space-between;
`;

const OptionsContainer = styled.div`
  overflow-y: auto;
  padding-right: 8px;
`;

const StyledFeedProductOptions = styled(FeedProductOptions)`
  gap: 8px;

  & ${Utils.getDataType(PRODUCT_OPTION_DATA_TYPE)} {
    gap: 2px;
  }

  & ${Utils.getDataType(PRODUCT_OPTION_HEADER_DATA_TYPE)} {
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    ${({ $design }) => getDesignCss($design?.inFeedPDP?.variantTitle)};
  }

  & ${Utils.getDataType(SELECT_INPUT_CONTAINER_DATA_TYPE)} {
    font-size: 14px;
    line-height: 14px;
    padding: 12px 16px;
    ${({ $design }) => getDesignCss($design?.inFeedPDP?.variantDropdown)};
  }
`;

const ButtonsContainer = styled(Gap8HorizontalFlexWrap)`
  flex-wrap: nowrap;
  align-items: center;
`;

const XButton = styled(VerticalFlex)`
  border-radius: ${({ borderRadius }) => borderRadius}px;
  width: 40px;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.colors.ghostLight};
  flex-shrink: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

const StyledFeedBuyNowButton = styled(FeedBuyNowButton)`
  width: 100%;
  ${FeedMultipleProductsButtonCss};
`;

export default FeedMultipleProductOptions;
