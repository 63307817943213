import React from 'react';

const WarningMessageIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 0c4.406 0 8 3.594 8 8 0 4.438-3.594 8-8 8-4.438 0-8-3.563-8-8 0-4.406 3.563-8 8-8Zm0 15c3.844 0 7-3.125 7-7 0-3.844-3.156-7-7-7-3.875 0-7 3.156-7 7 0 3.875 3.125 7 7 7Zm0-5.5a.494.494 0 0 1-.5-.5V4c0-.25.219-.5.5-.5.25 0 .5.25.5.5v5c0 .281-.25.5-.5.5Zm0 1.25a.76.76 0 0 1 .75.75.74.74 0 0 1-.75.75.722.722 0 0 1-.75-.75.74.74 0 0 1 .75-.75Z"
        fill="#2F3137"
      />
    </svg>
  );
};

export default WarningMessageIcon;
