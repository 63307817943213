import { SELECT_PUBLISH_METHOD_MODAL_KEY } from 'src/constants/modals.constants';
import { useModalActions } from 'src/context/ui_store/ModalStore';
import { project as Project } from 'app/src/types/entities';
import useNavigation from 'src/hooks/useNavigation';
import { publishMethodOptions } from 'src/types/entities';

const useSelectPublishMethodModal = (project: Project) => {
  const { setCurrentModal, setModalProps } = useModalActions();
  const { navigateToPreviousPage } = useNavigation();

  const openSelectPublishMethodModalIfNeeded = () => {
    if (project?.publishMethod === publishMethodOptions.unassigned) {
      setCurrentModal(SELECT_PUBLISH_METHOD_MODAL_KEY);
      setModalProps({
        project,
        onCancelSelectPublishMethod: navigateToPreviousPage,
      });
    }
  };

  return { openSelectPublishMethodModalIfNeeded };
};

export default useSelectPublishMethodModal;
