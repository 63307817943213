import React, { useEffect, useRef } from 'react';
import { FeedAssetRef } from 'player/src/helpers/assets';
import { AssetsUtilsService } from 'shared/react/services/assets';
import FeedImage from 'player/src/components/feed/feed_player/FeedImage';
import FeedVideo from 'player/src/components/feed/feed_player/FeedVideo';
import useVideoTracking from 'player/src/hooks/useVideoTracking';
import FeedCarousel from 'player/src/components/feed/feed_player/FeedCarousel';

const FeedAsset = ({
  step,
  index,
  videoRef,
  nextStepName,
  numOfSteps,
  videosObserver,
  isPlaying,
  isCurrentVideo,
  isLoadingVideo,
  isPlayerPreview,
  isStepOutOfView,
  isPlayerVisible,
  setIsLoadingVideo,
  isSubtitlesEnabled,
  handlePlayPause,
  handleNavigateToStep,
}) => {
  const outerVideoRef = useRef(null);
  const isImage = AssetsUtilsService.isImageAsset(step);
  const isGallery = AssetsUtilsService.isGalleryAsset(step);

  const { trackVideoLoop } = useVideoTracking({
    videoRef,
    isCurrentVideo,
    videoId: step.videoId,
    isDisabled: !isPlayerVisible,
  });

  useEffect(() => {
    if (
      !outerVideoRef.current ||
      isStepOutOfView ||
      isCurrentVideo ||
      !isPlayerVisible ||
      !videosObserver
    ) {
      return;
    }

    const element = outerVideoRef.current;

    videosObserver.observe(element);

    return () => {
      videosObserver.unobserve(element);
    };
  }, [index, outerVideoRef.current, isPlayerVisible, videosObserver]);

  useEffect(() => {
    if (!isCurrentVideo) {
      return;
    }

    videoRef.current = new FeedAssetRef(outerVideoRef.current, {
      isImage,
      isGallery,
      name: step.videoName,
    });

    return () => {
      videoRef.current.pause();
    };
  }, [outerVideoRef.current, isCurrentVideo]);

  useEffect(() => {
    if (!videoRef.current || !isCurrentVideo || !isPlayerVisible) {
      return;
    }

    if (videoRef.current.paused === !isPlaying) {
      return;
    }

    if (isPlaying) {
      videoRef.current.play().catch(error => {
        console.log('Can not play ', error);
      });
      return;
    }

    videoRef.current.pause();
  }, [isPlaying, isCurrentVideo, isPlayerVisible, isImage, videoRef.current]);

  if (isImage) {
    return (
      <FeedImage
        step={step}
        index={index}
        ref={outerVideoRef}
        videoRef={videoRef}
        numOfSteps={numOfSteps}
        nextStepName={isCurrentVideo ? nextStepName : null}
        isPlaying={isPlaying}
        isCurrentVideo={isCurrentVideo}
        isPlayerPreview={isPlayerPreview}
        isPlayerVisible={isPlayerVisible}
        handlePlayPause={handlePlayPause}
        handleNavigateToStep={handleNavigateToStep}
        isStepOutOfView={isStepOutOfView}
      />
    );
  }
  if (isGallery) {
    return (
      <FeedCarousel
        step={step}
        index={index}
        ref={outerVideoRef}
        videoRef={videoRef}
        numOfSteps={numOfSteps}
        nextStepName={isCurrentVideo ? nextStepName : null}
        isPlaying={isPlaying}
        isCurrentVideo={isCurrentVideo}
        isPlayerPreview={isPlayerPreview}
        isPlayerVisible={isPlayerVisible}
        handlePlayPause={handlePlayPause}
        handleNavigateToStep={handleNavigateToStep}
        isStepOutOfView={isStepOutOfView}
      />
    );
  }
  return (
    <FeedVideo
      step={step}
      ref={outerVideoRef}
      numOfSteps={numOfSteps}
      trackVideoLoop={trackVideoLoop}
      handleNavigateToStep={handleNavigateToStep}
      nextStepName={isCurrentVideo ? nextStepName : null}
      isStepOutOfView={isStepOutOfView}
      index={index}
      videosObserver={videosObserver}
      isCurrentVideo={isCurrentVideo}
      isLoadingVideo={isLoadingVideo}
      setIsLoadingVideo={setIsLoadingVideo}
      isSubtitlesEnabled={isSubtitlesEnabled}
    />
  );
};

export default FeedAsset;
