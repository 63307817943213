import React from 'react';
import { TextH1, TextH4 } from 'shared/react/components/basic/text/TextV2';
import { VerticalFlexCentered } from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import styled from 'styled-components';

type Props = {
  header: string | React.ReactNode;
  subheader: string;
};

const HeaderAndSubheader = ({ header, subheader }: Props) => {
  return (
    <LayoutRoot>
      <TextH1>{header}</TextH1>
      {subheader && <Subheader>{subheader}</Subheader>}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlexCentered)`
  gap: 8px;
`;

const Subheader = styled(TextH4)`
  color: ${({ theme }) => theme.colors.neutralDark};
  text-align: center;
  white-space: pre;
`;

export default HeaderAndSubheader;
