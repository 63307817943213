import React from 'react';
import styled from 'styled-components';
import FeedSidePanel from 'shared/react/components/complex/feed/FeedSidePanel';
import { MOBILE_VIDEO_WIDTH } from 'app/src/constants/editStep.constants';
import MOCK_PRODUCT from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/feed/mock-product';
import { FEED_SIDE_PANEL_CONTAINER_TEST_ID } from 'shared/react/constants/dataTestIds.constants';
import useFeedProducts from 'shared/react/components/complex/context/hooks/useFeedProducts';

const FeedSidePanelContainer = ({ step, isMobilePreview, isDescriptionSidePanel }) => {
  const [, { getProductsData }] = useFeedProducts();

  if (isDescriptionSidePanel && isMobilePreview) {
    return null;
  }

  const { product = MOCK_PRODUCT, isMultipleProducts } = getProductsData(step);
  return (
    <LayoutRoot
      isMobilePreview={isMobilePreview}
      data-test-id={FEED_SIDE_PANEL_CONTAINER_TEST_ID}
      onClick={e => e.stopPropagation()}
    >
      <FeedSidePanel
        product={product}
        currentStepKey={step.name}
        isMultipleProducts={isMultipleProducts}
        isFullWidth
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  background: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  border-radius: ${({ isMobilePreview }) => (isMobilePreview ? '16px' : '0 13px 13px 0')};
  grid-row: 1;
  max-width: ${({ isMobilePreview }) => (isMobilePreview ? `${MOBILE_VIDEO_WIDTH}px` : '')};
  width: 100%;
`;

export default FeedSidePanelContainer;
