import { getVodConnectionTypeKey } from 'app/src/utils/vodConnections.utils';
import { VodConnectionType } from 'app/src/types/entities';
import { DynamicRuleTypes } from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/constants/dynamicConditions.constants';

const getVodTypeVodConnections = (ruleGroup, videos) => {
  const types =
    ruleGroup?.rules?.flat().flatMap(rule => {
      if (rule.type !== DynamicRuleTypes.vodType) {
        return [];
      }

      return rule.value;
    }) || [];

  return videos.flatMap(video => {
    const vodType = video.type;

    if (!vodType || !types?.includes(vodType)) {
      return [];
    }

    return {
      vodAssetId: video.id,
      typeKey: getVodConnectionTypeKey({ appKey: video.appKey, id: vodType }),
      type: VodConnectionType.vodType,
    };
  });
};

export default getVodTypeVodConnections;
