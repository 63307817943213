import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAccount } from 'app/src/context/AccountStore';
import TeamMembersTable from './TeamMembersTable';
import TeamMembersEmptyState from './TeamMembersEmptyState';
import TeamMembersInviteModal from './TeamMembersInviteModal';
import { DASHBOARD_TABLET_MAX_WIDTH } from 'shared/react/utils/mediaQuery';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { FEATURE_TEAM_ACCOUNTS } from 'app/src/constants/appFeatures.constants';
import { track } from '../../helpers/Tracker';
import { useHistory, useLocation } from 'react-router-dom';
import {
  ACTIONS_KEY,
  DATE_ADDED_KEY,
  EMAIL_KEY,
  PAYMENT_ROLE_KEY,
  PAYMENT_ROLE_PRO_KEY,
  ROLE_KEY,
  STATUS_KEY,
} from 'app/src/constants/teamMembers.constants';
import { useBilling } from '../../hooks/useBillings';
import useQuery from '../../hooks/useQuery';
import UpgradeToProModal from 'app/src/complex_components/billings/modal/UpgradeToProModal';
import Utils from '../../utils';
import ComponentWithLoader from 'app/src/complex_components/ComponentWithLoader';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import TeamMembersTopBar from './team_members_components/TeamMembersTopBar';
import useUpdateDirtyForm from 'app/src/hooks/useUpdateDirtyForm';

const COLUMN_KEYS = [
  EMAIL_KEY,
  ROLE_KEY,
  STATUS_KEY,
  DATE_ADDED_KEY,
  PAYMENT_ROLE_KEY,
  ACTIONS_KEY,
];

const TeamMembersPage = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alreadyApprovedUserEmail, setAlreadyApprovedUserEmail] = useState(false);
  const [{ teamMembers }, { deleteTeamMember, updatePaymentRole, findUser }] = useAccount();
  const query = useQuery();
  const history = useHistory();
  const userId = query.get('userId');
  const [, { getFeatureEnabled, getSettingsByKey }] = useFeatures();
  const { isPayingSelfServicePackage } = useBilling();
  const [invitationModalOpen, setInvitationModalOpen] = useState(location.state?.openModal);
  const teamMembersEnabled = getFeatureEnabled(FEATURE_TEAM_ACCOUNTS);
  const { maxTeamMembers } = getSettingsByKey(FEATURE_TEAM_ACCOUNTS);
  const teamMembersCount = teamMembers.length;
  const [teamMembersToUpdateMap, setTeamMembersToUpdateMap] = useState({});
  const [isUnsaved, setUnsaved] = useState(false);
  const [isRedirecting, setRedirecting] = useState(false);

  const getTeamMembersCount = () => {
    if (!maxTeamMembers) {
      return teamMembersCount;
    }

    return `${teamMembersCount}/${maxTeamMembers}`;
  };

  const onTeamMemberPaymentRoleUpdate = ({ teamMember, paymentRole }) => {
    setUnsaved(true);
    setTeamMembersToUpdateMap({
      ...teamMembersToUpdateMap,
      ...{ [teamMember.id]: paymentRole },
    });
  };

  const onSave = async () => {
    setLoading(true);
    setRedirecting(true);

    const returnUrl = await updatePaymentRole({ teamMembersToUpdateMap });
    setUnsaved(false);
    if (returnUrl) {
      return Utils.openInTheSameTab(returnUrl);
    }

    setRedirecting(false);
    setLoading(false);
  };

  const openInvitationModal = () => {
    track('Invite Team Members Click', {
      location: 'page',
    });
    setInvitationModalOpen(true);
  };

  const closeInvitationModal = () => {
    setInvitationModalOpen(false);
  };

  const onTeamMemberRemove = async invite => {
    await deleteTeamMember(invite);

    track('Team Member Removed', {
      inviteStatus: invite.status,
    });
  };

  const getColumnKeys = () => {
    if (isPayingSelfServicePackage) {
      return COLUMN_KEYS;
    }
    return COLUMN_KEYS.filter(columnKey => columnKey !== PAYMENT_ROLE_KEY);
  };

  const approveTeamMember = async () => {
    const teamMember = findUser(userId);
    if (teamMember.paymentRole === PAYMENT_ROLE_PRO_KEY) {
      setAlreadyApprovedUserEmail(teamMember.email);
    } else {
      await onTeamMemberPaymentRoleUpdate({ teamMember, paymentRole: PAYMENT_ROLE_PRO_KEY });
    }
    setIsModalOpen(true);
    history.replace(history.location.pathname);
  };

  const onSaveClickCallback = useCallback(onSave, [teamMembersToUpdateMap]);

  useEffect(() => {
    if (!userId || !teamMembers.length || !isPayingSelfServicePackage) {
      return;
    }

    approveTeamMember(userId);
  }, [teamMembers]);

  useEffect(() => {
    if (!teamMembers.length) {
      return;
    }

    setLoading(false);
  }, [teamMembers]);

  useUpdateDirtyForm(isUnsaved, {
    isLoading: loading,
    hideCancel: true,
    onSaveClick: onSaveClickCallback,
  });

  return (
    <LayoutRoot>
      <TeamMembersTopBar
        teamMembersCount={getTeamMembersCount()}
        openInviteButtonClick={openInvitationModal}
        inviteDisabled={maxTeamMembers <= teamMembersCount}
      />
      <Content>
        <UpgradeToProModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          isApprove={true}
          userEmail={alreadyApprovedUserEmail}
        />
        <StyledTeamMembers>
          <ComponentWithLoader isLoading={loading || isRedirecting}>
            {!teamMembersCount && <TeamMembersEmptyState />}
            <TeamMembersTable
              teamMembers={teamMembers}
              onTeamMemberRemove={onTeamMemberRemove}
              columnKeys={getColumnKeys()}
              onTeamMemberPaymentRoleUpdate={onTeamMemberPaymentRoleUpdate}
              viewOnly={!teamMembersEnabled}
            />
            <TeamMembersInviteModal open={invitationModalOpen} closeModal={closeInvitationModal} />
          </ComponentWithLoader>
        </StyledTeamMembers>
      </Content>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

const Content = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.gray5};
  border-radius: 16px 0 0 0;
  padding: 32px;
  overflow-x: auto;
  overflow-y: hidden;
  flex-grow: 1;

  @media (${DASHBOARD_TABLET_MAX_WIDTH}) {
    margin: 0;
    height: 100%;
  }
`;

const StyledTeamMembers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
`;

export default TeamMembersPage;
