import React from 'react';

const SynthesiaSmallIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#synthesia)" fill="#B3B6BC">
        <path d="M3.2 4.36c-.24 0-.52-.08-.68-.28-.2-.16-.28-.4-.32-.68 0-.24.08-.52.24-.72.16-.2.4-.32.68-.32 1.6-.16 3.08-.92 4.2-2.08.08-.08.2-.16.32-.24.08 0 .2-.04.36-.04.16 0 .24 0 .4.08.12.04.24.12.32.2.08.08.16.2.24.32.04.12.08.24.08.36s0 .24-.08.4c-.04.12-.12.24-.2.32a8.632 8.632 0 0 1-5.44 2.68H3.2Zm9.6 7.28c.24 0 .52.08.68.28.16.2.28.4.32.68 0 .24-.08.52-.24.72-.16.2-.4.32-.68.32-1.6.16-3.08.92-4.2 2.08-.08.08-.2.16-.32.24-.12.04-.24.08-.36.08s-.24 0-.4-.08a.871.871 0 0 1-.32-.2c-.08-.08-.16-.2-.24-.32-.04-.12-.08-.24-.08-.36s0-.24.08-.4c.08-.16.12-.24.2-.32a8.632 8.632 0 0 1 5.44-2.68c.08-.04.08-.04.12-.04ZM11.64 3.2c0-.24.08-.52.28-.68.16-.2.4-.28.68-.32.24 0 .52.08.72.24.2.16.32.4.32.68.16 1.6.92 3.08 2.08 4.2.08.08.16.2.24.32.04.12.08.24.08.36s0 .24-.08.4c-.04.12-.12.24-.2.32-.08.08-.2.16-.32.24-.12.04-.24.08-.36.08s-.24 0-.4-.08a.871.871 0 0 1-.32-.2 8.632 8.632 0 0 1-2.68-5.44c-.04-.04-.04-.08-.04-.12Zm-7.28 9.6c0 .24-.08.52-.28.68-.16.2-.4.28-.68.32-.24 0-.52-.08-.72-.24-.2-.16-.32-.4-.32-.68-.16-1.6-.92-3.08-2.08-4.2-.08-.08-.16-.2-.24-.32 0-.08-.04-.2-.04-.32s0-.24.08-.4c.04-.12.12-.24.2-.32.08-.08.2-.16.32-.24C.72 7 .84 7 .96 7s.24 0 .4.08c.12.04.24.12.32.2a8.632 8.632 0 0 1 2.68 5.44v.08Z" />
        <path d="M7.2 2.04c-.2-.16-.28-.44-.32-.68-.04-.24.08-.52.24-.68.16-.2.4-.32.68-.32.24 0 .52.08.72.24 1.24 1 2.84 1.56 4.44 1.52.12 0 .24 0 .4.08.12.04.24.12.32.2.08.08.16.2.24.32.04.12.08.24.08.36s0 .24-.08.4c-.04.12-.12.24-.2.32-.08.08-.2.16-.32.24-.12.04-.24.08-.36.08-2.08.04-4.12-.64-5.72-1.96l-.12-.12Zm1.64 11.92c.2.16.28.44.32.68 0 .24-.08.52-.24.68-.16.2-.4.32-.68.32-.24 0-.52-.08-.72-.24-1.24-1-2.84-1.56-4.44-1.52-.12 0-.24 0-.4-.08a.871.871 0 0 1-.32-.2c-.08-.08-.16-.2-.24-.32-.04-.12-.08-.24-.08-.36s0-.24.08-.4c.04-.12.12-.24.2-.32.08-.08.2-.16.32-.24.12-.04.24-.08.36-.08 2.08-.04 4.12.64 5.72 1.96.04.08.08.12.12.12ZM14 7.16c.16-.2.44-.28.68-.32.24-.04.52.08.68.24.2.16.32.4.32.68 0 .24-.08.52-.24.72-1 1.24-1.56 2.84-1.52 4.44 0 .12 0 .24-.08.4-.04.12-.12.24-.2.32-.08.08-.2.16-.32.24-.12.04-.24.08-.36.08s-.24 0-.4-.08a.871.871 0 0 1-.32-.2c-.08-.08-.16-.2-.24-.32-.04-.12-.08-.24-.08-.36-.04-2.08.64-4.12 1.96-5.72l.12-.12ZM2.04 8.84c-.16.2-.44.28-.68.32-.24 0-.52-.08-.68-.24-.2-.16-.32-.4-.32-.68 0-.24.08-.52.24-.72 1-1.24 1.56-2.84 1.52-4.44 0-.12 0-.24.08-.4.04-.12.12-.24.2-.32 0-.08.12-.16.24-.24.12-.04.24-.08.36-.08s.24 0 .4.08c.12.04.24.12.32.2.12.08.16.2.24.32.04.12.08.24.08.36.04 2.08-.64 4.12-1.96 5.72 0 .04-.04.08-.04.12Z" />
      </g>
      <defs>
        <clipPath id="synthesia">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SynthesiaSmallIcon;
