import React from 'react';
import Utils from 'shared/react/utils/utils';
import { formatPrice, numberComparator } from '../analyticsTable.utils';
import AnalyticsTableEmailCell from '../AnalyticsTableEmailCell';

export const getOrdersTableColumns = () => {
  return [
    {
      field: 'createdAt',
      headerName: 'Date',
      flex: 0,
      valueFormatter: ({ value }) => new Date(value).toLocaleDateString(),
    },
    {
      field: 'orderId',
      headerName: 'Order #',
      flex: 1,
      sortComparator: numberComparator,
      valueGetter: ({ row }) => row.orderName || row.orderId,
    },
    {
      field: 'email',
      headerName: 'User',
      flex: 1,
      renderCell: ({ value }) => {
        return <AnalyticsTableEmailCell email={value} />;
      },
    },
    {
      field: 'inVideoRevenue',
      headerName: 'In-video revenue',
      flex: 1,
      sortComparator: numberComparator,
      valueFormatter: ({ row }) => formatPrice(row.inVideoRevenue, row.currency),
    },
    {
      field: 'postVideoRevenue',
      headerName: 'Post-video revenue',
      flex: 1,
      sortComparator: numberComparator,
      valueFormatter: ({ row }) => formatPrice(row.postVideoRevenue, row.currency),
    },
    {
      field: 'indirectRevenue',
      headerName: 'Indirect revenue',
      flex: 1,
      sortComparator: numberComparator,
      valueFormatter: ({ row }) => formatPrice(row.indirectRevenue, row.currency),
    },
    {
      field: 'timeOnTolstoy',
      headerName: 'Time on Tolstoy',
      flex: 1,
      valueFormatter: ({ value }) => Utils.millisecondsToTime(value, true, true),
      sortComparator: numberComparator,
    },
  ];
};
