import React from 'react';
import styled from 'styled-components';
import ComponentWithLoader from 'shared/react/components/basic/ComponentWithLoader';
import {
  FEED_BUTTON_DATA_TYPE,
  FEED_BUTTON_LOADER_DATA_TYPE,
  FEED_BUTTON_TEXT_DATA_TYPE,
} from 'shared/react/constants/feedPreviewDataTypes.constants';
import FeedQuantityPicker from '../feed_quantity_picker/FeedQuantityPicker';
import FeedButtonPrice from '../feed_button_price/FeedButtonPrice';
import { HorizontalFlexCentered } from '../../flex_layouts/HorizontalFlex';
import { milkConditionCss } from 'shared/react/components/clientsCss.constants';
import useIsMilk from 'shared/react/hooks/useIsMilk';
import useKeyboardNavigation from 'player/src/hooks/useKeyboardNavigation';

const FeedButton = ({
  onClick,
  isLoading,
  buttonText,
  buttonTextColor,
  isDisabled,
  dataTestId,
  isAddToCart,
  quantityPickerState,
  price,
  currencySymbol,
  isMultipleProductDetails,
  primaryHasProductPrice,
  primaryHasQuantityPicker,
  productTitle,
  ...props
}) => {
  const isMilk = useIsMilk();
  const { onKeyDown, setNextRef } = useKeyboardNavigation();

  const hasPrice = isAddToCart && primaryHasProductPrice && !isMultipleProductDetails;
  const hasQuantityPicker = isAddToCart && primaryHasQuantityPicker;
  const ariaLabel = productTitle ? `${buttonText}: ${productTitle}` : buttonText;

  return (
    <LayoutRoot
      role="button"
      aria-label={ariaLabel}
      aria-disabled={isDisabled}
      ref={setNextRef}
      onKeyDown={onKeyDown}
      tabIndex="0"
      isDisabled={isDisabled}
      isLoading={isLoading}
      hasQuantityPicker={hasQuantityPicker}
      data-type={FEED_BUTTON_DATA_TYPE}
      onClick={e => (isLoading ? {} : onClick?.(e))}
      {...props}
    >
      <StyledComponentWithLoader
        isLoading={isLoading}
        data-type={FEED_BUTTON_LOADER_DATA_TYPE}
        hasQuantityPicker={hasQuantityPicker}
      >
        <FeedQuantityPicker quantityPickerState={quantityPickerState} isAddToCart={isAddToCart} />
        <ButtonText
          buttonTextColor={buttonTextColor}
          hasPrice={hasPrice}
          hasQuantityPicker={hasQuantityPicker}
          data-type={FEED_BUTTON_TEXT_DATA_TYPE}
          data-test-id={dataTestId}
          isMilk={isMilk}
        >
          {buttonText}
          <FeedButtonPrice
            isAddToCart={isAddToCart}
            price={price}
            currencySymbol={currencySymbol}
            isMultipleProductDetails={isMultipleProductDetails}
          />
        </ButtonText>
      </StyledComponentWithLoader>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlexCentered)`
  background: ${({ theme }) => theme.colors.black};
  padding: ${({ hasQuantityPicker }) => (hasQuantityPicker ? '0' : '16px')};
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid transparent;
  pointer-events: ${({ isDisabled, isLoading }) => (isDisabled || isLoading ? 'none' : '')};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.8 : 1)};
  transition: 0.3s;
  overflow: hidden;
`;

const ButtonText = styled(HorizontalFlexCentered)`
  justify-content: ${({ hasPrice }) => (hasPrice ? 'space-between' : 'center')};
  padding: ${({ hasQuantityPicker }) => (hasQuantityPicker ? '16px' : '0')};
  line-height: 16px;
  color: ${({ theme, buttonTextColor }) => buttonTextColor || theme.colors.white};
  flex-grow: 1;
  font-weight: 600;
  font-size: 18px;
  ${milkConditionCss};
`;

const StyledComponentWithLoader = styled(ComponentWithLoader)`
  width: 12px;
  height: 12px;
  margin: ${({ hasQuantityPicker }) => (hasQuantityPicker ? '16px' : '0')};
  border-top: 2px solid ${({ theme }) => theme.colors.white};
`;

export default FeedButton;
