import React from 'react';

const FilledBottomArrow = () => {
  return (
    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.688 1.719a1.01 1.01 0 0 0 .218-1.094A1.014 1.014 0 0 0 10 0H2c-.406 0-.781.25-.938.625a1.01 1.01 0 0 0 .22 1.094l4 4A.99.99 0 0 0 6 6c.25 0 .5-.094.688-.281l4-4Z"
        fill="#50545E"
      />
    </svg>
  );
};

export default FilledBottomArrow;
