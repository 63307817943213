import { CircularProgress } from '@material-ui/core';
import { useSelectedProductsContext } from 'app/src/complex_components/global_modal/video/add_products_modal/SelectedProductsContext';
import AddProductsModalButtons from 'app/src/complex_components/global_modal/video/add_products_modal/components/add-products-modal-buttons/AddProductsModalButtons';
import AddProductsModalContent from 'app/src/complex_components/global_modal/video/add_products_modal/components/add-products-modal-content/AddProductsModalContent';
import { TagTabs } from 'app/src/complex_components/global_modal/video/add_products_modal/constants/addProductsModal.constants';
import { LOADING_DATA_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import { useAppActions } from 'app/src/context/AppsStore';
import { useProductActions } from 'app/src/context/ProductsStore';
import { useVideos } from 'app/src/context/VideosStore';
import { useVodConnection, useVodConnectionByTagNames } from 'app/src/context/VodConnectionStore';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { useSnackBarActions } from 'app/src/context/ui_store/SnackBarStore';
import StandardModalV2 from 'app/src/pages/modals/StandardModalV2';
import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import ComponentWithOverlayLoader from 'shared/react/components/basic/ComponentWithOverlayLoader';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { VIDEO_STATUS } from 'shared/react/constants/video.constants';
import useIsMounted from 'shared/react/hooks/useIsMounted';
import styled from 'styled-components';
import { ModalAssetPreview } from '../../common/assets';
import AddProductModalTabs from './components/add-products-modal-tabs/AddProductModalTabs';
import AddProductsModalTopContainer from './components/add-products-modal-top-container/AddProductsModalTopContainer';
import { track } from 'src/helpers/Tracker';
import {
  RightButton,
  LeftButton,
} from 'app/src/pages/dashboard/pages/videos/components/video_card/video-switcher/buttons';
import { useVideoSwitcherInModal } from 'app/src/pages/dashboard/pages/videos/components/video_card/useVideoSwitcherInModal';
import { useLocation } from 'react-router-dom';
import Routes from 'app/src/helpers/Routes';
import { useKeydownEvent } from 'app/src/complex_components/global_modal/video/useKeydownEvent';

interface AddProductsModalProps {
  closeModal: () => void;
}

const AddProductsModal: React.FC<AddProductsModalProps> = ({ closeModal }) => {
  const [{ currentIds: vodAssetIds, modalProps }] = useModal();
  const { videoUrl, analyticsData, thumbnail } = modalProps;
  const { getAppUsingUrl, getProductsCatalogApp } = useAppActions();
  const [{ video }, { getVideoUrl }] = useVideos(vodAssetIds[0]);
  const { getProducts } = useProductActions();
  const vodConnectionStore = useVodConnection({ vodAssetId: vodAssetIds[0] });
  const [
    {
      vodConnectionProductIds: vodConnectionProductIdsFromStore,
      vodConnectionVariantsByProductIds,
      selectedAppUrl,
      isConnectionsWithPagination,
      vodConnectionByVodAssetId,
    },
    { setSelectedAppUrl },
  ] = vodConnectionStore;
  const [initialTags] = useVodConnectionByTagNames(vodAssetIds[0]);
  const { setSnackbar } = useSnackBarActions();
  const { pathname } = useLocation();
  const isVideosDashboard = Routes.isVideosDashboard(pathname);
  const isMounted = useIsMounted();
  const [selectedTab, setSelectedTab] = useState(TagTabs.Products);
  const videoRef = useRef(null);
  const selectedApp = getAppUsingUrl(selectedAppUrl);
  const numberOfConnections = vodAssetIds.length;
  const isMultipleTagging = numberOfConnections > 1;
  const productsCatalogApp = getProductsCatalogApp();
  const [
    { initialProducts, initialVariantsByProducts, isDirty, isLoading, isSaving },
    { init, reset, setIsLoading, save },
  ] = useSelectedProductsContext();

  /**
   * shouldInterceptKeyboardEvent is 'true' when the product select pop-up opened
   */
  const [isSelectProductPopupOpened, setIsSelectProductPopupOpened] = useState<boolean>(false);
  const { moveToVideo } = useVideoSwitcherInModal({
    shouldMove: useCallback(
      () => !isSelectProductPopupOpened && !isDirty && !isSaving && !isLoading,
      [isDirty, isLoading, isSaving, isSelectProductPopupOpened]
    ),
  });

  const getVodConnectionProductIds = () => {
    if (!isConnectionsWithPagination) {
      const sortedProductIds = (vodConnectionByVodAssetId[vodAssetIds[0]] || [])
        .filter(connection => {
          return connection.appUrl === selectedAppUrl && connection.productId;
        })
        .sort((a, b) => a.orderIndex - b.orderIndex)
        .map(({ productId }) => productId);

      return sortedProductIds;
    }

    return video?.vodConnections?.items
      .sort((a, b) => a.orderIndex - b.orderIndex)
      .map(connection => {
        vodConnectionVariantsByProductIds[connection.productId] = connection.variantIds;
        return connection.appUrl === selectedAppUrl && connection.productId;
      })
      .filter(Boolean);
  };

  const vodConnectionProductIds = useMemo(getVodConnectionProductIds, [
    isConnectionsWithPagination,
    vodConnectionProductIdsFromStore,
    video,
    vodConnectionVariantsByProductIds,
    selectedAppUrl,
  ]);

  const getProductsData = async () => {
    setIsLoading(true);
    const existingProducts = await getProducts(vodConnectionProductIds);

    init({
      initialProducts: existingProducts,
      initialTags,
      selectedProducts: existingProducts,
      selectedTags: initialTags,
      initialVariantsByProducts: vodConnectionVariantsByProductIds,
      variantsByProducts: vodConnectionVariantsByProductIds,
    });

    setIsLoading(false);
  };

  const onSave = async () => {
    await save();

    if (!isMounted()) {
      return;
    }

    setSnackbar('Products tagged successfully!');
  };

  useEffect(() => {
    const hasVodConnections = vodConnectionProductIds?.length || initialTags?.length;
    if (isMultipleTagging || !hasVodConnections || isSaving) {
      return;
    }

    getProductsData();
  }, [
    vodAssetIds,
    vodConnectionProductIds,
    vodConnectionVariantsByProductIds,
    isMultipleTagging,
    isSaving,
    selectedAppUrl,
    initialTags,
  ]);

  useKeydownEvent(
    'Escape',
    useCallback(
      e => {
        if (isSelectProductPopupOpened) {
          // do not close modal window for now
          // TODO: close product select pop-up
          return;
        }

        e.preventDefault();
        closeModal();
      },
      [closeModal, isSelectProductPopupOpened]
    )
  );

  useEffect(() => {
    if (isMultipleTagging) {
      return;
    }

    reset({
      selectedTags: initialTags,
      selectedProducts: initialProducts,
      variantsByProducts: initialVariantsByProducts,
    });
  }, [initialTags, isMultipleTagging]);

  useEffect(() => {
    if (!productsCatalogApp || selectedAppUrl) {
      return;
    }

    setSelectedAppUrl(productsCatalogApp?.appUrl);
  }, [productsCatalogApp, selectedAppUrl]);

  useEffect(() => {
    if (video?.status !== VIDEO_STATUS.done || !videoRef.current || videoUrl) {
      return;
    }

    videoRef.current.src = getVideoUrl(video, '') || videoUrl;
    videoRef.current.play();
  }, [video]);

  useEffect(() => {
    track('Add Products Modal Opened', {
      vodAssetIds,
    });
  }, []);

  return (
    <StyledStandardModal open={true} onClose={closeModal}>
      <LayoutRoot>
        {isVideosDashboard && <LeftButton moveToVideo={moveToVideo} />}
        <ModalAssetPreview thumbnail={thumbnail} asset={video} shouldHide={isMultipleTagging} />
        <ProductsContainer>
          <AddProductsModalTopContainer
            video={video}
            selectedApp={selectedApp}
            analyticsData={analyticsData}
            setIsSelectProductPopupOpened={setIsSelectProductPopupOpened}
          />
          <AddProductModalTabs
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            selectedApp={selectedApp}
          />
          <StyledComponentWithOverlayLoader
            isLoading={isLoading}
            customLoader={<CircularProgress data-test-id={LOADING_DATA_TEST_ID} size={24} />}
          >
            <AddProductsModalContent selectedTab={selectedTab} />
          </StyledComponentWithOverlayLoader>
          <AddProductsModalButtons onDiscard={closeModal} onSave={onSave} />
        </ProductsContainer>
        {isVideosDashboard && <RightButton moveToVideo={moveToVideo} />}
      </LayoutRoot>
    </StyledStandardModal>
  );
};

const StyledStandardModal = styled(StandardModalV2)`
  height: 100%;
`;

const LayoutRoot = styled(HorizontalFlex)`
  max-height: 533px;
`;

const ProductsContainer = styled(Gap16VerticalFlex)`
  overflow: hidden;
  padding: 24px 0;
  width: 448px;

  & > * {
    padding: 0 24px;
  }

  &:last-child {
    padding-bottom: 16px;
  }

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 100%;
  }
`;

const StyledComponentWithOverlayLoader = styled(ComponentWithOverlayLoader)`
  justify-content: flex-start;
  overflow: auto;
  pointer-events: ${({ isLoading }) => (isLoading ? 'none' : 'auto')};
`;

export default AddProductsModal;
