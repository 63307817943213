import React from 'react';
import styled from 'styled-components';

import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import CodeSection from '../CodeSection';
import { getIsTolstoyCodeAddedAutomatically } from '../helper';

type Props = {
  widgetCode: string;
  integration?: string;
};

const DefaultTolstoyCodeInstallation: React.FC<Props> = ({ widgetCode, integration }) => {
  const isCodeAddedAutomatically = getIsTolstoyCodeAddedAutomatically(integration);
  const codeSectionDescription = 'Copy and paste the following code in your <head> section';
  const automaticSectionDescription = 'This code will be added automatically to your website';

  return (
    <LayoutRoot>
      <ManualInstallationWrapper>
        <CodeSection
          code={widgetCode}
          displayCode={widgetCode}
          codeSectionDescription={codeSectionDescription}
          automaticSectionDescription={automaticSectionDescription}
          isCodeAddedAutomatically={isCodeAddedAutomatically}
        />
      </ManualInstallationWrapper>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)`
  position: relative;
`;

const ManualInstallationWrapper = styled(Gap16VerticalFlex)`
  width: 50%;
  padding-right: 32px;
`;

export default DefaultTolstoyCodeInstallation;
