import {
  endOfDay,
  endOfMonth,
  format,
  getYear,
  startOfDay,
  startOfMonth,
  subDays,
  subHours,
  subMonths,
} from 'date-fns';
import {
  DATE_FILTER_LAST_24_HOURS,
  DATE_FILTER_LAST_30_DAYS,
  DATE_FILTER_LAST_3_DAYS,
  DATE_FILTER_LAST_60_DAYS,
  DATE_FILTER_LAST_7_DAYS,
  DATE_FILTER_LAST_90_DAYS,
  DATE_FILTER_LAST_MONTH,
  DATE_FILTER_THIS_MONTH,
  DATE_FILTER_TODAY,
  DATE_FILTER_YESTERDAY,
} from 'app/src/constants/analytics.constants';

type DATE_WINDOW_RANGE_GETTER = (dt: Date) => [Date, Date];

const DATE_WINDOWS: Record<string, DATE_WINDOW_RANGE_GETTER> = {
  [DATE_FILTER_TODAY]: dt => [startOfDay(dt), endOfDay(dt)],
  [DATE_FILTER_YESTERDAY]: dt => {
    const prevDay = subDays(dt, 1);
    return [startOfDay(prevDay), endOfDay(prevDay)];
  },
  [DATE_FILTER_LAST_24_HOURS]: dt => [subHours(dt, 24), dt],
  [DATE_FILTER_LAST_3_DAYS]: dt => [subDays(dt, 3), dt],
  [DATE_FILTER_LAST_7_DAYS]: dt => [subDays(dt, 7), dt],
  [DATE_FILTER_LAST_30_DAYS]: dt => [subDays(dt, 30), dt],
  [DATE_FILTER_LAST_60_DAYS]: dt => [subDays(dt, 60), dt],
  [DATE_FILTER_LAST_90_DAYS]: dt => [subDays(dt, 90), dt],
  [DATE_FILTER_LAST_MONTH]: dt => {
    const lastMonth = subMonths(dt, 1);
    return [startOfMonth(lastMonth), endOfMonth(lastMonth)];
  },
  [DATE_FILTER_THIS_MONTH]: dt => [startOfMonth(dt), dt],
};

export const formatRelativeDateWindow = (dtWindow: string) => {
  const range = DATE_WINDOWS[dtWindow]?.(new Date());
  if (!range) {
    console.error(`Unknown date window ${dtWindow}`);
    return dtWindow;
  }
  return formatDateRange(range);
};

export const formatDateRange = (range: [Date, Date]) => {
  const [start, end] = range;
  if (getYear(start) === getYear(end)) {
    return `${format(start, 'MMM d')} - ${format(end, 'MMM d')}, ${format(start, 'y')}`;
  }
  return `${format(start, 'MMM d, y')} - ${format(end, 'MMM d, y')}`;
};
