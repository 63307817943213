import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { TextBody, TextTiny } from 'shared/react/components/basic/text/TextV2';
import { PrimaryButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { PublishModalBaseCss } from 'app/src/pages/project/components/project-top-bar/components/set-live-button/css';
import HorizontalFlex, {
  Gap8HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import CopyCode from 'app/src/complex_components/CopyCode';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import ArrowTopRightWithLineIcon from 'app/src/images/ArrowTopRightWithLineIcon';
import { project as Project, publishMethodOptions } from 'app/src/types/entities';
import Utils from 'app/src/utils';
import TVStep1Src from 'app/src/pages/project/pages/installation/components/tv/images/tv-step1.png';
import TVStep2Src from 'app/src/pages/project/pages/installation/components/tv/images/tv-step2.png';
import TVStep3Src from 'app/src/pages/project/pages/installation/components/tv/images/tv-step3.png';
import TVStep4Src from 'app/src/pages/project/pages/installation/components/tv/images/tv-step4.png';
import TVStep5Src from 'app/src/pages/project/pages/installation/components/tv/images/tv-optional.png';
import GenericCarouselStep2Src from 'app/src/pages/project/pages/installation/components/stories_and_carousel/images/carousel-step2.png';
import GenericCarouselStep3Src from 'app/src/pages/project/pages/installation/components/stories_and_carousel/images/carousel-step3.png';
import GenericStoriesStep2Src from 'app/src/pages/project/pages/installation/components/stories_and_carousel/images/stories-step2.png';
import GenericStoriesStep3Src from 'app/src/pages/project/pages/installation/components/stories_and_carousel/images/stories-step3.png';
import FuegoStep1Src from 'app/src/pages/project/pages/installation/components/fuego/images/fuego-step1.png';
import FuegoStep3Src from 'app/src/pages/project/pages/installation/components/fuego/images/fuego-step3.png';
import UseCodeInstead from 'app/src/pages/project/components/project-top-bar/components/set-live-button/UseCodeInstead';
import { FlexDirection } from 'app/src/types/common/common';
import CopyToClipboardIcon from 'app/src/images/dashboard_v2/CopyToClipboardIcon';
import { useApps } from 'app/src/context/AppsStore';
import {
  cases,
  CaseType,
  deprecatedCases,
  DeprecatedCaseType,
} from 'app/src/pages/dashboard/components/cases/cases.constants';
import { useDynamicConditionsContext } from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/context/DynamicConditionsContext';
import useNavigation from 'app/src/hooks/useNavigation';
import { useProductPageSettingsActions } from 'app/src/context/ProductPageSettingsStore';
import useDynamicTypeContent from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/hooks/useDynamicTypeContent';
import { SHOPIFY_PUBLISHING_TUTORIAL_URL } from 'app/src/constants/publish.constants';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';
import { getProjectEmailSettings } from 'app/src/utils/project.utils';
import { CAMPAIGN_TILE_ACTIONS } from 'app/src/constants/campaignTileActions.constants';
import AUTOMATION_TOOLS from 'app/src/pages/share/email/emailAutomationToolsList';
import { AUTOMATION_TOOL_KLAVIYO } from 'src/constants/share.constants';
import { getPublishAllPlatrofmsSteps } from './components/getPublishAllPlatrofmsSteps';
import { getPublishKlaviyoFlowSteps } from './components/getPublishKlaviyoFlowSteps';
import PublishModalActionButtons from './components/PublishModalActionButtons';
import { EmailPublishStepsFactoryFunction } from 'app/src/pages/project/components/project-top-bar/components/set-live-button/components/publish-modal/components/EmailPublishStepsFactoryFunction';
import { getStepsForTapcartPDP } from 'app/src/pages/project/components/project-top-bar/components/set-live-button/components/publish-modal/components/TapcartPDPSteps';
import { getStepsForTapcartTV } from 'app/src/pages/project/components/project-top-bar/components/set-live-button/components/publish-modal/components/getTapcartTVSteps';

interface Step {
  title: string | ReactElement;
  body?: ReactElement;
  optional?: boolean;
  note?: string;
}

type Props = {
  project: Project;
  isLoading: boolean;
  toggleWidget: (newState?: boolean) => void;
  onClose: () => void;
  handleCancel: () => void;
  justPublished: boolean;
};

const PublishModal = ({
  project,
  isLoading,
  toggleWidget,
  onClose,
  handleCancel,
  justPublished,
}: Props) => {
  const { navigateToProjectTab } = useNavigation();
  const { publishId, publishMethod, widgetSettings, useCaseName } = project;
  const [{ shopify: isShopify }, { createShopifyTvPage }] = useApps();
  const { vodAssetIds } = useDynamicConditionsContext();
  const minimumNumberOfTiles = JSON.parse(widgetSettings)?.minimumNumberOfTiles || 0;
  const { isProductPage } = useProductPageSettingsActions();
  const isProjectProductPage = isProductPage(project);
  const { products } = useDynamicTypeContent({ project, isProjectProductPage });
  const [, { setErrorSnackbar }] = useSnackBar();

  const [emailSnippet, setEmailSnippet] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [isTvPageCreated, setIsTvPageCreated] = useState(false);
  const [automationTool, setAutomationTool] = useState(() => {
    return AUTOMATION_TOOLS.find(tool => tool.id === AUTOMATION_TOOL_KLAVIYO);
  });

  const isEmail = publishMethod === publishMethodOptions.email;
  const isTrendingVideosEmail = isEmail && useCaseName === cases[CaseType.EMAIL_TRENDING].name;
  const isAbandonmentRevivalEmail =
    isEmail && useCaseName === cases[CaseType.EMAIL_ABANDONMENT_REVIVAL].name;
  const isPostPurchaseEmail =
    isEmail && useCaseName === deprecatedCases[DeprecatedCaseType.EMAIL_POST_PURCHASE].name;
  const isProductLaunchEmail = isEmail && useCaseName === cases[CaseType.EMAIL_PRODUCT_LAUNCH].name;
  const isOffsiteEmailPublish =
    isTrendingVideosEmail ||
    isProductLaunchEmail ||
    isAbandonmentRevivalEmail ||
    isPostPurchaseEmail;

  const emailSettings = getProjectEmailSettings(project);
  const emailTileAction = emailSettings?.tileAction || CAMPAIGN_TILE_ACTIONS.openTolstoyTvPage;
  const shouldCreateTvPage =
    isEmail &&
    isShopify &&
    emailTileAction === CAMPAIGN_TILE_ACTIONS.openTolstoyTvPage &&
    !project?.widgetUrls?.length;

  const isTV = [publishMethodOptions.tvChannel, publishMethodOptions.videoPage].includes(
    publishMethod
  );
  const step2GenericImageSrc =
    publishMethod === publishMethodOptions.stories
      ? GenericStoriesStep2Src
      : GenericCarouselStep2Src;
  const step3GenericImageSrc =
    publishMethod === publishMethodOptions.stories
      ? GenericStoriesStep3Src
      : GenericCarouselStep3Src;

  const createProjectTvPage = async () => {
    try {
      await createShopifyTvPage(project.publishId, { withHeader: true });
      setIsTvPageCreated(true);
    } catch (error) {
      setErrorSnackbar('Failed to create Tolstoy TV page, please try again later');
      console.error(error);
    }
  };

  useEffect(() => {
    if (shouldCreateTvPage) {
      createProjectTvPage();
    }
  }, [shouldCreateTvPage]);

  const handleOpenDeepLink = async () => {
    const deepLink = Utils.getAddTolstoyBlockDeepLink(project);

    Utils.openInNewTab(deepLink);
  };

  const handleOpenShopifyTutorialLink = () => {
    Utils.openInNewTab(SHOPIFY_PUBLISHING_TUTORIAL_URL);
  };

  const handlePauseClick = () => {
    toggleWidget(false);
    onClose();
  };

  const handleEmailFlowDone = () => {
    if (shouldCreateTvPage && !isTvPageCreated) {
      return;
    }

    toggleWidget(true);
  };

  const isMinimumNumberOfTilesReached = () => {
    if (!isProductPage(project) || !products.length) {
      return vodAssetIds.length >= minimumNumberOfTiles;
    }

    for (const product of products) {
      if (product.videos.length >= minimumNumberOfTiles) {
        return true;
      }
    }

    return false;
  };

  const stepsTV: Step[] = [
    {
      title: 'Create a new template',
      body: (
        <Gap8VerticalFlex>
          <TextTiny>
            Go to Online store → Themes → Customize → Pages → Create template → “Video page”
          </TextTiny>
          <img src={TVStep1Src} width={500} alt="Tolsty TV installation" />
        </Gap8VerticalFlex>
      ),
    },
    {
      title: 'Create a new video page',
      body: (
        <Gap8VerticalFlex>
          <TextTiny>
            Go to Online Store → Pages → Add page → Theme template → Video page* → Page name
          </TextTiny>
          <img src={TVStep2Src} width={500} alt="Tolsty TV installation" />
          <HighlightedDescription>
            * Make sure to assign page to new “Video page” template
          </HighlightedDescription>
        </Gap8VerticalFlex>
      ),
    },
    {
      title: 'Add section to video page',
      body: (
        <Gap8VerticalFlex>
          <TextTiny>
            Go to Online store → Themes → Customize → Pages → “Video page” → Add sections → “Tolstoy
            TV page”
          </TextTiny>
          <img src={TVStep3Src} width={500} alt="Tolsty TV installation" />
        </Gap8VerticalFlex>
      ),
    },
    {
      title: 'Paste ID section',
      body: (
        <Gap8VerticalFlex>
          <TextTiny>
            Copy the FeedID and paste in the Publish ID in your “Tolstoy TV page” section on Shopify
            themes
          </TextTiny>
          <HorizontalFlex>
            <CopyCode codeToCopy={publishId} buttonText="Copy ID" padding="8px 16px" />
          </HorizontalFlex>
          <img src={TVStep4Src} width={500} alt="Tolsty TV installation" />
        </Gap8VerticalFlex>
      ),
    },
    {
      optional: true,
      title: 'Set menu item',
      body: (
        <Gap8VerticalFlex>
          <TextTiny>
            Go to → Online Store → Navigation → Main menu → Add menu item→ Select “Video page” for
            link
          </TextTiny>
          <img src={TVStep5Src} width={500} alt="Tolsty TV installation" />
        </Gap8VerticalFlex>
      ),
    },
  ];
  const stepsForStoriesAndCarousels: Step[] = [
    {
      title: `Copy publish ID`,
      body: (
        <HorizontalFlex>
          <CopyCode
            direction={FlexDirection.row}
            codeToCopy={publishId}
            padding="8px"
            buttonText={<CopyToClipboardIcon />}
          />
        </HorizontalFlex>
      ),
    },
    {
      title: 'Add block to your PDP template',
      body: (
        <HorizontalFlex>
          <PrimaryButton onClick={handleOpenDeepLink}>
            Add block
            <ArrowTopRightWithLineIcon />
          </PrimaryButton>
        </HorizontalFlex>
      ),
    },
    {
      title: 'Click on added block in Shopify theme, paste publish ID, and click save',
      body: (
        <Gap8VerticalFlex>
          {!isMinimumNumberOfTilesReached() && (
            <TextBodyNeutralDark>
              {`This widget is set to only appear when there are at least ${minimumNumberOfTiles} videos. Please select more videos in this widget, or change the minimum in `}
              <OverrideLinkText onClick={() => navigateToProjectTab(project, 'design')}>
                design tab
              </OverrideLinkText>
            </TextBodyNeutralDark>
          )}
          <StepImage src={step3GenericImageSrc} width={500} alt="Shopify add publishId"></StepImage>
        </Gap8VerticalFlex>
      ),
    },
  ];

  const stepsGeneric: Step[] = [
    {
      title: `Add Tolstoy ${publishMethod} to your theme`,
      body: (
        <HorizontalFlex>
          <PrimaryButton onClick={handleOpenDeepLink}>
            Add block
            <ArrowTopRightWithLineIcon />
          </PrimaryButton>
        </HorizontalFlex>
      ),
    },
    {
      title: 'Click on the Tolstoy block that was added',
      body: (
        <HorizontalFlex>
          <StepImage
            src={step2GenericImageSrc}
            width={500}
            alt="Shopify click on Tolstoy block"
          ></StepImage>
        </HorizontalFlex>
      ),
    },
    {
      title: 'Paste this publish ID into the block text box & click save',
      body: (
        <Gap8VerticalFlex>
          <HorizontalFlex>
            <CopyCode
              direction={FlexDirection.row}
              codeToCopy={publishId}
              padding="8px"
              buttonText={<CopyToClipboardIcon />}
            />
          </HorizontalFlex>
          {!isMinimumNumberOfTilesReached() && (
            <TextBodyNeutralDark>
              {`This widget is set to only appear when there are at least ${minimumNumberOfTiles} videos. Please select more videos in this widget, or change the minimum in `}
              <OverrideLinkText onClick={() => navigateToProjectTab(project, 'design')}>
                design tab
              </OverrideLinkText>
            </TextBodyNeutralDark>
          )}
          <StepImage src={step3GenericImageSrc} width={500} alt="Shopify add publishId"></StepImage>
        </Gap8VerticalFlex>
      ),
    },
  ];

  const stepsFuego: Step[] = [
    {
      title: "Navigate to Fuego's Tolstoy integration",
      body: (
        <Gap8VerticalFlex>
          <StyledFuegoPrimaryButton
            onClick={() => Utils.openInNewTab('https://fuego.io/integrations')}
          >
            Go to Feugo’s Integrations
          </StyledFuegoPrimaryButton>
          <img src={FuegoStep1Src} width={500} alt="Tolsty Fuego installation" />
        </Gap8VerticalFlex>
      ),
    },
    {
      title: 'Add the publish ID and click “Connect”',
      body: (
        <HorizontalFlex>
          <CopyCode
            codeToCopy={publishId}
            direction={FlexDirection.row}
            buttonText="Copy ID"
            padding="8px 16px"
          />
        </HorizontalFlex>
      ),
    },
    {
      title: 'Open the Editor and drag the Tolstoy Product Story block into a product screen.',
      body: (
        <Gap8VerticalFlex>
          <img src={FuegoStep3Src} width={500} alt="Tolsty Fuego installation" />
        </Gap8VerticalFlex>
      ),
    },
  ];

  const isTapcartUseCase = project?.useCaseName === cases[CaseType.TAPCART_APP].name;
  const isTapcartPDPUseCase =
    project?.useCaseName === cases[CaseType.TAPCART_APP_PDP_CAROUSEL].name;
  const isFuegoUseCase = project?.useCaseName === cases[CaseType.FUEGO_PDP].name;
  const isCarouselOrSpotlight = [
    cases[CaseType.HOMEPAGE_SPOTLIGHT].name,
    cases[CaseType.PRODUCT_PAGE_STORIES].name,
  ].includes(useCaseName);

  const isTopPublishingInstruction =
    isShopify && !isTapcartUseCase && !isTV && !isEmail && !isFuegoUseCase;
  const showWidgetSnippet =
    !project.discover && !isEmail && !isFuegoUseCase && !isTapcartPDPUseCase && !isTapcartUseCase;

  const getOffsiteEmailPublishStepsFactory = (): EmailPublishStepsFactoryFunction => {
    if (isTrendingVideosEmail || isProductLaunchEmail) {
      return getPublishAllPlatrofmsSteps;
    }

    if (isAbandonmentRevivalEmail || isPostPurchaseEmail) {
      return getPublishKlaviyoFlowSteps;
    }

    return () => stepsGeneric;
  };

  const getSteps = () => {
    if (!isShopify) {
      return [];
    }

    if (isCarouselOrSpotlight) {
      return stepsForStoriesAndCarousels;
    }

    if (isFuegoUseCase) {
      return stepsFuego;
    }

    if (isOffsiteEmailPublish) {
      const publishStepsFactory = getOffsiteEmailPublishStepsFactory();
      return publishStepsFactory({
        automationTool,
        onSnippetChange: setEmailSnippet,
        project,
        snippet: emailSnippet,
        emailSnippet,
        isCopyCodeLoading: shouldCreateTvPage && !isTvPageCreated,
        setAutomationTool,
      });
    }

    if (isTapcartPDPUseCase) {
      return getStepsForTapcartPDP(project);
    }

    if (isTapcartUseCase) {
      return getStepsForTapcartTV(
        project,
        selectedLocation,
        setSelectedLocation,
        selectedType,
        setSelectedType
      );
    }

    if (isTV) {
      return stepsTV;
    }

    return stepsGeneric;
  };

  const shouldShowPublishTutorial =
    isTopPublishingInstruction && !isTapcartUseCase && !isTapcartPDPUseCase;
  return (
    <LayoutRoot>
      <CodeContainer>
        {shouldShowPublishTutorial && (
          <HeaderText>
            Watch a{' '}
            <HeaderLinkText onClick={handleOpenShopifyTutorialLink}>
              publishing tutorial
            </HeaderLinkText>{' '}
            or see steps below:
          </HeaderText>
        )}
        <Steps>
          {getSteps().map(({ title, body, optional, note, isTip }, index) => (
            <Step key={title}>
              {note && <StepHeaderNote>{note}</StepHeaderNote>}
              <StepHeaderContainer>
                <StepHeader>{isTip ? 'Tip' : `Step ${index + 1}`}</StepHeader>
                <TextBody>{title}</TextBody>
                {optional && <OptionalText>(Optional)</OptionalText>}
              </StepHeaderContainer>
              {body && <StepBody>{body}</StepBody>}
            </Step>
          ))}
        </Steps>
        {showWidgetSnippet && <UseCodeInstead project={project} noSpoiler={!isShopify} />}
      </CodeContainer>
      <PublishModalActionButtons
        disabled={isLoading}
        justPublished={justPublished}
        handlePauseClick={handlePauseClick}
        handleDoneClick={onClose}
        isEmail={isEmail}
        closeModal={onClose}
        handleCancel={handleCancel}
        isTrendingVideosEmail={isTrendingVideosEmail}
        onEmailFlowDone={handleEmailFlowDone}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  ${PublishModalBaseCss};
  top: 58px;
  width: 550px;
  max-height: calc(100vh - 128px);
  overflow: hidden;
  background: ${({ theme }) => theme.colors.neutralPale};
`;

const CodeContainer = styled(VerticalFlex)`
  max-height: 65vh;
`;

const ButtonsContainer = styled(Gap8HorizontalFlex)`
  justify-content: flex-end;
  background: white;
  margin: 0 -16px -16px -16px;
  padding: 8px 16px;
  box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.05);
`;

const Steps = styled(VerticalFlex)`
  margin-bottom: 16px;
  overflow-y: auto;
  max-height: 65vh;
`;

const Step = styled(VerticalFlex)`
  &:not(:first-of-type) {
    padding-top: 8px;
    border-top: 1px solid ${({ theme }) => theme.colors.neutralLight};
  }
`;

const StepHeaderContainer = styled(Gap8HorizontalFlex)`
  padding: 16px 0;
  align-items: baseline;
`;

const StepHeader = styled(TextBody)`
  padding-right: 8px;
  border-right: 1px solid ${({ theme }) => theme.colors.neutralLight};
  color: ${({ theme }) => theme.colors.neutralDark};
  flex-shrink: 0;
`;

const StepBody = styled.div`
  padding-top: 8px;
  padding-bottom: 20px;
`;

const OptionalText = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralDark};
`;

const HighlightedDescription = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.grapePurple};
`;

const StepImage = styled.img`
  border: 1px solid ${({ theme }) => theme.colors.neutralLight};
`;

const StepHeaderNote = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralDark};
  font-style: italic;
`;

const TextBodyNeutralDark = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.error};
`;

const OverrideLinkText = styled(TextTiny)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.error};
  cursor: pointer;
  text-decoration-line: underline;
`;

const HeaderText = styled(TextTiny)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.neutralGrey};
`;

const HeaderLinkText = styled(HeaderText)`
  cursor: pointer;
  text-decoration-line: underline;
`;

const StyledFuegoPrimaryButton = styled(PrimaryButton)`
  margin-bottom: 5px;
  margin-top: -10px;
  width: fit-content;
`;

export default PublishModal;
