import React from 'react';

const CopyIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="13" viewBox="0 0 10 13" fill="none">
    <path
      d="M5 .5c-.984 0-1.828.633-2.133 1.5H1.625C.992 2 .5 2.516.5 3.125v8.25A1.11 1.11 0 0 0 1.625 12.5h6.75c.61 0 1.125-.492 1.125-1.125v-8.25A1.14 1.14 0 0 0 8.375 2H7.109A2.258 2.258 0 0 0 5 .5Zm0 3a.74.74 0 0 1-.75-.75c0-.398.328-.75.75-.75.398 0 .75.352.75.75 0 .422-.352.75-.75.75ZM3.125 5h3.75c.188 0 .375.188.375.375 0 .21-.188.375-.375.375h-3.75a.37.37 0 0 1-.375-.375c0-.188.164-.375.375-.375Z"
      fill="#fff"
    />
  </svg>
);

export default CopyIcon;
