export const StockVideos = [
  {
    videoUrl:
      'https://player.vimeo.com/external/294394804.hd.mp4?s=d8437c0fc1b6b8bf04089697cb0d68bd7072e9b1&profile_id=174&oauth2_token_id=57447761',
    lowQualityVideoUrl:
      'https://player.vimeo.com/external/294394804.sd.mp4?s=0ae63fad00aa2702fd154632d9ed93fb7d7ee543&profile_id=164&oauth2_token_id=57447761',
    posterUrl:
      'https://images.pexels.com/videos/1494284/free-video-1494284.jpg?fit=crop&w=1200&h=630&auto=compress&cs=tinysrgb',
    gifUrl: 'https://myvodstreams-dev-output-ymzl1geb.s3.amazonaws.com/public/stock/1.gif',
    creator: 'Visually Us',
    creatorPageUrl: 'https://www.pexels.com/@visuallyus',
  },
  {
    videoUrl:
      'https://player.vimeo.com/external/296210754.hd.mp4?s=08c03c14c04f15d65901f25b542eb2305090a3d7&profile_id=175&oauth2_token_id=57447761',
    lowQualityVideoUrl:
      'https://player.vimeo.com/external/296210754.sd.mp4?s=9db41d71fa61a2cc19757f656fc5c5c5ef9f69ec&profile_id=164&oauth2_token_id=57447761',
    posterUrl:
      'https://images.pexels.com/videos/1526909/free-video-1526909.jpg?fit=crop&w=1200&h=630&auto=compress&cs=tinysrgb',
    gifUrl: 'https://myvodstreams-dev-output-ymzl1geb.s3.amazonaws.com/public/stock/2.gif',
    creator: 'Ruvim Miksanskiy',
    creatorPageUrl: 'https://www.pexels.com/@digitech',
  },
  {
    videoUrl:
      'https://player.vimeo.com/external/295482071.hd.mp4?s=8716a11fe2222114283a29f8fe49ce8071017332&profile_id=175&oauth2_token_id=57447761',
    lowQualityVideoUrl:
      'https://player.vimeo.com/external/295482071.sd.mp4?s=238ca35e1e9ae91fd08ce5e290c1f168c7f1fe1b&profile_id=164&oauth2_token_id=57447761',
    posterUrl:
      'https://images.pexels.com/videos/1510090/free-video-1510090.jpg?fit=crop&w=1200&h=630&auto=compress&cs=tinysrgb',
    gifUrl: 'https://myvodstreams-dev-output-ymzl1geb.s3.amazonaws.com/public/stock/3.gif',
    creator: 'Oleg Magni',
    creatorPageUrl: 'https://www.pexels.com/@oleg-magni',
  },
];
