import React from 'react';
import QuizRule from 'app/src/pages/project/pages/quiz/quiz_result/flow/rule/QuizRule';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';

function QuizRuleList({ quizResultId, flow }) {
  if (!flow.rules?.length) {
    return null;
  }

  return (
    <Gap16VerticalFlex>
      {flow.rules?.map((rule, i) => (
        <QuizRule
          key={rule.stepId}
          quizResultId={quizResultId}
          flowId={flow.id}
          rule={rule}
          ruleIndex={i}
        />
      ))}
    </Gap16VerticalFlex>
  );
}

export default QuizRuleList;
