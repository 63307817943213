import React from 'react';
import VideosSidebar, {
  VideosSidebarProps,
} from 'app/src/pages/dashboard/pages/videos/components/side-bar/VideosSidebar';
import {
  CountQueryFilters,
  useVodAssetCountQuery,
} from 'app/src/pages/dashboard/pages/videos/hooks/search/useVodAssetCountQuery';
import { useTrendingVideos } from 'app/src/pages/dashboard/pages/videos/hooks/useTrendingVideos';

type Props = Omit<VideosSidebarProps, 'assetCounts'>;

export const VideosSidebarWithLazyCounts = ({ ...otherProps }: Props) => {
  const { count: videosCount } = useVodAssetCountQuery(CountQueryFilters.videos);
  const { count: imagesCount } = useVodAssetCountQuery(CountQueryFilters.images);
  const { count: galleriesCount } = useVodAssetCountQuery(CountQueryFilters.gallery);
  const { trendingVideos } = useTrendingVideos();

  const assetCounts = {
    videosCount,
    imagesCount,
    galleriesCount,
    trendingVideosCount: trendingVideos?.length || 0,
  };

  return <VideosSidebar assetCounts={assetCounts} {...otherProps}></VideosSidebar>;
};
