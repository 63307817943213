import React, { useEffect, useRef, useState } from 'react';
import Quiz from 'app/src/pages/project/pages/quiz/quiz_v1/Quiz';
import { useParams } from 'react-router-dom';
import { useProjects } from 'app/src/context/ProjectsStore';
import { useQuiz } from 'app/src/context/QuizStore';
import styled from 'styled-components';
import ToolBar from 'app/src/pages/project/pages/quiz/quiz_v1/ToolBar';

const QuizPage = () => {
  const { projectId } = useParams();
  const [{ project }] = useProjects({ projectId });
  const [
    { quiz, isLoading: isQuizLoading },
    { getOrCreateQuiz, publishQuiz, clearQuizState, makeNewQuiz },
  ] = useQuiz();
  const [isLoading, setIsLoading] = useState(false);
  const [currQuiz, setCurrQuiz] = useState(quiz);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const inputRef = useRef();

  const saveQuizChanges = async () => {
    await saveQuiz();
  };

  const saveQuiz = async () => {
    setIsLoading(true);
    await publishQuiz(project.publishId, currQuiz, project);
    await makeNewQuiz(project.publishId);
    setIsLoading(false);
  };

  const onMultipleChange = ev => {
    const { value } = ev.target;
    const copy = [...quiz];
    selectedKeys.forEach(key => {
      copy[key][currQuiz[0].length - 1] = value;
    });
    setCurrQuiz(copy);
  };

  useEffect(() => {
    inputRef?.current?.focus();
  }, [selectedKeys]);

  useEffect(() => {
    if (project) {
      setIsLoading(true);
      getOrCreateQuiz(project, setIsLoading);
    }

    return clearQuizState;
  }, [project]);

  useEffect(() => {
    if (!quiz) {
      return;
    }
    setCurrQuiz(quiz);
  }, [quiz]);

  return (
    <>
      <CustomToolBar
        selectedKeys={selectedKeys}
        inputRef={inputRef}
        isSaveLoading={isLoading}
        onMultipleChange={onMultipleChange}
        projectId={projectId}
        setSelectedKeys={setSelectedKeys}
        updateQuiz={() => {}}
        isLoading={isLoading}
      />
      <Quiz
        saveChanges={saveQuizChanges}
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
        project={project}
        loadingState={isLoading || isQuizLoading}
        quiz={currQuiz}
        setCurrQuiz={setCurrQuiz}
        isLoading={isLoading || isQuizLoading}
      />
    </>
  );
};

const CustomToolBar = styled(ToolBar)`
  margin: 0px 24px 0 0px;
`;

export default QuizPage;
