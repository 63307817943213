import React, { useEffect, useRef } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import * as Sentry from '@sentry/react';
import Routes from '../helpers/Routes';
import { group as analyticsGroup, identify as analyticsIdentify } from '../helpers/Tracker';
import { changeWidgetEmail, hideWidget, identify as widgetIdentify } from '../helpers/Widget';
import Utils from '../utils';
import UserRoutes from '../routes/User';
import AuthRoutes from '../routes/Auth';
import { getIsAuthRoute, navigateToRoot } from '../utils/navigation.utils';
import { useUser } from 'app/src/context/userStore/UserStore';
import { useApps } from 'app/src/context/AppsStore';
import InstallExtensionModal from './popup/InstallExtensionModal';
import Canvas from './canvas/Canvas';
import PricingOnboardingModal from './modals/PricingOnboardingModal';
import AppModals from './AppModals';
import GlobalSnackBar from 'app/src/complex_components/GlobalSnackBar';
import CloseWindow from 'app/src/basic_components/CloseWindow';
import ErrorPage from 'app/src/pages/error-page/ErrorPage';
import { AppTheme } from 'app/src/types/design-system';
import Root from 'app/src/pages/root/Root';
import { intercomBoot } from 'app/src/utils/intercom.utils';
import APP_CONFIG from 'app/src/config/app.config';
import {
  INSTAGRAM_GRAPH,
  INSTAGRAM_GRAPH_ERROR_CODE,
} from 'app/src/constants/intergrations.constants';
import { useModalActions } from 'app/src/context/ui_store/ModalStore';
import { INSTAGRAM_GRAPH_KEY } from 'app/src/constants/modals.constants';
import 'src/services/init-singleton-services';

const shouldNavigateToRoot = ({ isAuthenticated, history }) => {
  if (isAuthenticated === null) {
    return false;
  }
  const isAuthRoute = getIsAuthRoute(history);
  if (isAuthenticated) {
    return isAuthRoute;
  }

  return !isAuthRoute && !Utils.isGhostAccount();
};

export default function App({ callbackHref }) {
  const history = useHistory();
  const location = useLocation();
  const [{ initialized: appsInitialized }, { getAppsName, getAppsUrls, getIsShopifyPlus }] =
    useApps();
  const [{ account, accountLogoSettings, storageUser, user, isAuthenticated }] = useUser();
  const { setCurrentModal } = useModalActions();

  let isHideToolbar = false;
  const ghostAccount = Utils.isGhostAccount();
  const isMessageAlreadySent = useRef(false);
  const shouldShowInstagramOnLoadError =
    callbackHref.includes(INSTAGRAM_GRAPH) &&
    callbackHref.includes(INSTAGRAM_GRAPH_ERROR_CODE) &&
    !isMessageAlreadySent.current;

  if (shouldShowInstagramOnLoadError) {
    isMessageAlreadySent.current = true;
    setCurrentModal(INSTAGRAM_GRAPH_KEY, { error: INSTAGRAM_GRAPH_ERROR_CODE });
  }

  Routes.getRoutesWithoutToolbar().forEach(routeWithBackButton => {
    if (location.pathname.includes(routeWithBackButton)) {
      isHideToolbar = true;
    }
  });

  useEffect(() => {
    if (ghostAccount) {
      return;
    }

    if (isAuthenticated && user && appsInitialized) {
      let platform = 'none';
      const appNames = getAppsName();
      if (appNames.includes('shopify')) {
        platform = 'shopify';
      } else if (appNames.includes('wix')) {
        platform = 'wix';
      } else if (appNames.includes('bigcommerce')) {
        platform = 'bigcommerce';
      }

      const integrations = appNames.join();
      const appUrls = getAppsUrls().join();
      const isShopifyPlus = getIsShopifyPlus();
      const userId = user.owner;
      const userInformation = {
        email: user.email,
        source: user.source,
        appKey: user.appKey,
        isShopifyPlus,
        integrations,
        platform,
        appUrls,
      };

      if (Utils.getCookieValue('tolstoy_gclid')) {
        userInformation.gclid = Utils.getCookieValue('tolstoy_gclid');
      }
      if (Utils.getCookieValue('tolstoy_fbclid')) {
        userInformation.fbclid = Utils.getCookieValue('tolstoy_fbclid');
      }
      if (Utils.getCookieValue('tolstoy_liclid')) {
        userInformation.liclid = Utils.getCookieValue('tolstoy_liclid');
      }
      if (Utils.getCookieValue('tolstoy_ttclid')) {
        userInformation.ttclid = Utils.getCookieValue('tolstoy_ttclid');
      }
      if (Utils.getCookieValue('tolstoy_utm_source')) {
        userInformation.utm_source = Utils.getCookieValue('tolstoy_utm_source');
      }
      if (Utils.getCookieValue('tolstoy_utm_medium')) {
        userInformation.utm_medium = Utils.getCookieValue('tolstoy_utm_medium');
      }
      if (Utils.getCookieValue('tolstoy_utm_campaign')) {
        userInformation.utm_campaign = Utils.getCookieValue('tolstoy_utm_campaign');
      }

      if (APP_CONFIG.IS_DEV) {
        console.log('userInformation ', userInformation);
        return;
      }

      analyticsIdentify(userId, userInformation);

      window.LogRocket?.identify(userId, userInformation);

      Sentry.setUser({ email: user.email });
      Sentry.setContext('account', {
        appKey: user.appKey,
      });

      intercomBoot({
        user_id: userId,
        ...userInformation,
      });

      widgetIdentify(user, { source: user.source });
    }
  }, [user, isAuthenticated, appsInitialized, ghostAccount]);

  useEffect(() => {
    if (!account || ghostAccount) {
      return;
    }
    const email =
      JSON.parse(localStorage.getItem('user') || '{}').email || storageUser?.email || '';
    const website = email.split('@')[1] || '';
    const name = website.split('.')[0];

    analyticsIdentify(account.owner, {
      companySize: account.companySize,
      companyVertical: account.companyVertical,
      useCase: account.useCase,
      monthlyTraffic: account.monthlyTraffic,
      company: account.appKey,
    });

    analyticsGroup(account.appKey, {
      name: account.name || name,
      website: account.url || website,
      avatar: accountLogoSettings.logo,
      employees: account.companySize,
      industry: account.companyVertical,
      useCase: account.useCase,
      monthlyTraffic: account.monthlyTraffic,
      createdAt: account.createdAt,
    });
  }, [account]);

  useEffect(() => {
    const shouldNotShow = [...Routes.getAuthRoutes(), '/'];
    if (Utils.isMobile() || shouldNotShow.includes(location.pathname)) {
      return hideWidget();
    }
  }, [location.pathname, isAuthenticated]);

  useEffect(() => {
    window.tolstoyUser = storageUser?.email;
    if (storageUser && isAuthenticated) {
      changeWidgetEmail(storageUser.email);
    }

    if (shouldNavigateToRoot({ isAuthenticated, history })) {
      Utils.setInviteDetails();
      navigateToRoot(history);
    }
  }, [storageUser?.id, storageUser?.email, isAuthenticated]);

  useEffect(() => {
    if (APP_CONFIG.IS_PROD) {
      return;
    }

    document.title = '[DEV] Tolstoy App';
  }, []);

  return (
    <LayoutRoot>
      <ThemeProvider theme={AppTheme}>
        <GlobalSnackBar />
        <MainContainer id="app" isHideToolbar={isHideToolbar}>
          <ContentContainer>
            <InstallExtensionModal />
            <Switch>
              <Route path={Routes.getRootRoute()} exact>
                <Root />
              </Route>
              <Route path={Routes.getCanvasRoute()} exact>
                <Canvas />
              </Route>
              <Route path={Routes.getErrorRoute()} exact>
                <ErrorPage />
              </Route>
              <Route path={Routes.closeWindowAfterIntegrationRoute()} exact>
                <CloseWindow />
              </Route>
              {isAuthenticated ? <UserRoutes /> : <AuthRoutes />}
            </Switch>
          </ContentContainer>
        </MainContainer>
        <AppModals />
        <PricingOnboardingModal />
      </ThemeProvider>
    </LayoutRoot>
  );
}

const LayoutRoot = styled.div`
  display: grid;
  height: 100%;
`;

const MainContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.gray5};
  height: 100%;
  overflow-y: hidden;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    overflow-y: auto;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-self: stretch;
  overflow-y: hidden;
`;
