import React from 'react';
import TeamMembersInviteModal from 'app/src/pages/team_members/TeamMembersInviteModal';
import { useModal } from 'app/src/context/ui_store/ModalStore';

const TeamMemberInviteModal = () => {
  const [, { clearModalState }] = useModal();
  return <TeamMembersInviteModal open={true} closeModal={clearModalState} />;
};

export default TeamMemberInviteModal;
