const MuteIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.508.867C12.313.79 12.156.79 12 .79c-.313 0-.625.078-.86.313L5.868 5.75H2.625C1.57 5.75.75 6.61.75 7.625v3.71c0 1.017.82 1.837 1.875 1.837h3.242l5.274 4.687c.234.196.546.313.859.313.156 0 .313-.04.508-.117.43-.196.703-.664.703-1.133V2c0-.469-.274-.938-.703-1.133Zm-1.133 14.766-4.766-4.258H2.625v-3.71h3.984l4.766-4.259v12.227ZM20.789 9.5l1.836-1.836c.39-.39.39-.976 0-1.328-.352-.39-.938-.39-1.328 0L19.5 8.21l-1.875-1.875c-.39-.352-.977-.352-1.328 0-.39.39-.39.976 0 1.328L18.133 9.5l-1.836 1.836c-.39.39-.39.976 0 1.328.351.39.937.39 1.328 0l1.875-1.836 1.836 1.836c.352.39.937.39 1.289 0 .39-.351.39-.937 0-1.328L20.789 9.5Z"
      fill="#fff"
    />
  </svg>
);

export default MuteIcon;
