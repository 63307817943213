import React from 'react';
import { useTheme } from 'styled-components';
import Utils from 'app/src/utils';
import { useAnalyticsChannel } from 'app/src/context/AnalyticsStore';
import {
  ANALYTICS_OVERVIEW_STATS,
  CHANNEL_FILTER_OPTIONS,
} from 'app/src/constants/analytics.constants';
import { getShortMonthAndDate } from 'app/src/utils/date.utils';
import ChartTooltip from 'app/src/complex_components/analytics/chart-tooltip/ChartTooltip';
import { TooltipPayload } from 'app/src/complex_components/analytics/chart-tooltip/ChartTooltip.types';

type AnalyticsOverviewViewersTooltipProps = {
  active: boolean;
  payload: [TooltipPayload, TooltipPayload];
  label: string;
};

const AnalyticsOverviewViewersTooltip = ({
  active,
  payload,
  label,
}: AnalyticsOverviewViewersTooltipProps) => {
  const theme = useTheme();
  const [channel] = useAnalyticsChannel();
  const isTapcartChannel = channel === CHANNEL_FILTER_OPTIONS.tapcart;
  if (!active || !payload?.length) {
    return null;
  }

  const [usersMetricsData] = payload;
  const { payload: usersMetrics } = usersMetricsData;

  const lineGroups = [
    {
      key: ANALYTICS_OVERVIEW_STATS.TOLSTOY_CONVERSION_RATE_USERS,
      header: ANALYTICS_OVERVIEW_STATS.TOLSTOY_CONVERSION_RATE_USERS,
      bulletColor: theme.colors.successDark,
      value: Utils.formatNumber({ value: usersMetrics.sessionStarts }),
    },
    {
      key: ANALYTICS_OVERVIEW_STATS.BASELINE_TOLSTOY_CONVERSION_RATE_USERS,
      header: ANALYTICS_OVERVIEW_STATS.BASELINE_TOLSTOY_CONVERSION_RATE_USERS,
      bulletColor: theme.colors.neutralLight,
      value: Utils.formatNumber({ value: usersMetrics.pageViews }),
      hidden: isTapcartChannel,
    },
  ];

  return <ChartTooltip label={getShortMonthAndDate(label)} lineGroups={lineGroups} />;
};

export default AnalyticsOverviewViewersTooltip;
