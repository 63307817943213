import XIcon from 'app/src/images/XIcon';
import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import Pencil from 'app/src/images/Pencil';
import VIcon from 'app/src/images/VIcon';

function EditAnswersTimeButton({
  isEditing,
  onChangeTime,
  handleCancelEditTime,
  isVideoReady,
  rounded,
  ...props
}) {
  const theme = useTheme();

  return (
    <LayoutRoot {...props}>
      <ButtonContainer
        isEditing={isEditing}
        onClick={onChangeTime}
        isVideoReady={isVideoReady}
        rounded={rounded}
      >
        {isEditing ? <VIcon fill={theme.colors.successDark} width="12" height="12" /> : <Pencil />}
      </ButtonContainer>
      {isEditing && (
        <ButtonContainer
          onClick={handleCancelEditTime}
          isCancel={true}
          isVideoReady={isVideoReady}
          rounded={rounded}
        >
          <XIcon fill={theme.colors.danger} size="12" />
        </ButtonContainer>
      )}
    </LayoutRoot>
  );
}

export default EditAnswersTimeButton;

const LayoutRoot = styled.div`
  display: flex;
  gap: 8px;
`;

const buttonBackground = css`
  background: ${({ theme, isEditing, isCancel }) => {
    if (isCancel) {
      return theme.colors.pink6;
    }
    if (isEditing) {
      return theme.colors.successLight;
    }
    return theme.colors.gray3;
  }};

  &:hover,
  &:focus,
  &:active {
    background: ${({ theme, isEditing, isCancel }) => {
      if (isCancel) {
        return theme.colors.dangerLight;
      }
      if (isEditing) {
        return theme.colors.successLightHover;
      }
      return theme.colors.gray3Hover;
    }};
  }
`;

const ButtonContainer = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  opacity: ${({ isVideoReady }) => (isVideoReady ? 1 : 0.4)};
  pointer-events: ${({ isVideoReady }) => (isVideoReady ? '' : 'none')};
  ${buttonBackground};
  transition: 0.3s;
  color: ${({ theme }) => theme.colors.warningDark};

  &:hover svg {
    transform: scale(1.1);
  }

  padding: 0;
  height: 24px;
  width: 24px;
  border-radius: ${({ rounded }) => (rounded ? '100%' : '4px')};
`;
