import React from 'react';

const ShoppableVideoIcon = () => {
  return (
    <svg width="36" height="40" viewBox="0 0 36 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.125 12.5V12.75H27.375H33C34.19 12.75 35.25 13.81 35.25 15V33.75C35.25 37.1255 32.5515 39.75 29.25 39.75H6.75C3.37245 39.75 0.75 37.1275 0.75 33.75V15C0.75 13.8049 1.73686 12.75 3 12.75H8.625H8.875V12.5V9.375C8.875 4.35546 12.905 0.25 18 0.25C23.0181 0.25 27.125 4.35682 27.125 9.375V12.5ZM23.625 12.75H23.875V12.5V9.375C23.875 6.19005 21.185 3.5 18 3.5C14.7347 3.5 12.125 6.19231 12.125 9.375V12.5V12.75H12.375H23.625ZM4.25 16H4V16.25V33.75C4 35.2943 5.20568 36.5 6.75 36.5H29.25C30.7111 36.5 32 35.2993 32 33.75V16.25V16H31.75H27.375H27.125V16.25V20.625C27.125 21.5739 26.3844 22.25 25.5 22.25C24.5443 22.25 23.875 21.5807 23.875 20.625V16.25V16H23.625H12.375H12.125V16.25V20.625C12.125 21.5739 11.3844 22.25 10.5 22.25C9.54432 22.25 8.875 21.5807 8.875 20.625V16.25V16H8.625H4.25Z"
        fill="url(#paint0_linear_29355_74618)"
        stroke="white"
        strokeWidth="0.5"
      />
      <defs>
        <linearGradient
          id="paint0_linear_29355_74618"
          x1="0.5"
          y1="40"
          x2="35.5"
          y2="40"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4558D0" />
          <stop offset="0.5" stopColor="#C852BE" />
          <stop offset="1" stopColor="#FBC276" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ShoppableVideoIcon;
