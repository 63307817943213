import React, { useState } from 'react';
import styled from 'styled-components';
import Button from 'shared/react/components/complex/Button';
import AreYouSureModal from '../../modals/AreYouSureModal';
import Utils from '../../../utils';
import { useAccount } from 'app/src/context/AccountStore';
import DeleteIcon from 'app/src/images/DeleteIcon';
import Tooltip from 'app/src/basic_components/Tooltip';
import { STATUS_ACCEPTED } from 'app/src/constants/teamMembers.constants';
import Sizes from 'shared/react/theme/Sizes';

const TeamMemberActions = ({ hidden, teamMember, onRemove, loading }) => {
  const [{ isAccountOwner }] = useAccount();
  const [showWarningModal, setShowWarningModal] = useState(false);

  if (hidden || (!isAccountOwner && teamMember.owner !== Utils.getOwner())) {
    return null;
  }

  const hideModal = () => {
    setShowWarningModal(false);
  };

  const handleDelete = () => {
    onRemove(teamMember);
    hideModal();
  };

  const getTooltipText = () => {
    if (teamMember.status === STATUS_ACCEPTED) {
      return 'Remove from workspace';
    }
    return 'Remove invite';
  };

  return (
    <StyledTeamMemberActions>
      <Tooltip placement="bottom" text={getTooltipText()}>
        <div>
          <TeamMemberDeleteButton
            onClick={() => setShowWarningModal(true)}
            loading={loading}
            size={Sizes.FitContent}
            transparentBackground
          >
            <DeleteIcon />
          </TeamMemberDeleteButton>
        </div>
      </Tooltip>
      <AreYouSureModal
        open={showWarningModal}
        loading={loading}
        titleText={`${
          teamMember?.email || 'Team member'
        } will be deleted permanently. Are you sure?`}
        secondaryButtonClicked={hideModal}
        actionButtonClicked={handleDelete}
      />
    </StyledTeamMemberActions>
  );
};

export default TeamMemberActions;

const StyledTeamMemberActions = styled.div``;

const TeamMemberDeleteButton = styled(Button)`
  display: grid;
  align-self: end;
`;
