import React, { useState } from 'react';
import styled from 'styled-components';
import BackdropButton from '../../BackdropButton';
import FilledMenuIcon from 'shared/react/images/feed/FilledMenuIcon';
import useFeedModal from '../../context/hooks/useFeedModal';
import { MENU_MODAL_KEY } from 'shared/react/constants/modals.constants';
import { FEED_MENU_BUTTON_DATA_TYPE } from '../../../../constants/feedPreviewDataTypes.constants';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';
import useFeedFeatures from 'shared/react/components/complex/context/hooks/useFeedFeatures';
import { FEATURE_FEED_REPORT_BUTTON } from 'shared/react/constants/features.constants';
import MoreOptionsMenu from '../feed_menu/MoreOptionsMenu';

const FeedMenuButton = ({ currentStepKey, videoId, videoRef, audioRef, isMobile, ...props }) => {
  const [{ isAppPreview }] = useProjectConfig();
  const [{ currentModal }, { pushModal, popModal }] = useFeedModal();
  const [, { getFeatureEnabled }] = useFeedFeatures();
  const [anchorEl, setAnchorEl] = useState(null);

  const shouldHide = !getFeatureEnabled(FEATURE_FEED_REPORT_BUTTON);

  const onCloseMenuModal = () => {
    if (isMobile) {
      videoRef.current.play();
      audioRef?.current?.play();
    }

    return popModal();
  };

  const handleClickMenu = e => {
    e.stopPropagation();
    if (isAppPreview) {
      return;
    }

    if (currentModal?.key === MENU_MODAL_KEY) {
      return popModal();
    }

    if (isMobile) {
      videoRef.current.pause();
      audioRef?.current?.pause();

      pushModal({
        key: MENU_MODAL_KEY,
        props: {
          isMobile,
          onClose: onCloseMenuModal,
          currentStepKey,
          videoId,
        },
      });
      return;
    }

    setAnchorEl(e.currentTarget);
  };

  if (shouldHide) {
    return null;
  }

  return (
    <>
      <BackdropButton
        onClick={handleClickMenu}
        dataType={FEED_MENU_BUTTON_DATA_TYPE}
        {...props}
        ariaLabel="Open more options menu"
      >
        <StyledFilledMenuIcon />
      </BackdropButton>
      <MoreOptionsMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        isMobile={false}
        currentStepKey={currentStepKey}
        videoRef={videoRef}
        videoId={videoId}
      />
    </>
  );
};

const StyledFilledMenuIcon = styled(FilledMenuIcon)`
  height: 20px;
  width: 20px;

  & path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export default FeedMenuButton;
