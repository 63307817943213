export enum SpotlightSizingMode {
  Fixed = 'fixed',
  Responsive = 'responsive'
}

export enum MainTilePosition {
  Start = 'start',
  Center = 'center'
}

export enum PlayMode {
  Fixed = 'fixed',
  Dynamic = 'dynamic'
}

export enum ScrollMode {
  Infinite = 'infinite',
  Finite = 'finite',
}

export interface ControlButtonSettings {
  enabled?: boolean;
  backgroundEnabled?: boolean;
  backgroundColor?: string;
  borderEnabled?: boolean;
  borderColor?: string;
  opacity?: number;
}

export interface ControlSettings {
  play?: ControlButtonSettings;
  mute?: ControlButtonSettings;
}

export interface SpotlightDeviceSettings {
  marginX?: number;
  sizingMode?: SpotlightSizingMode;
  mainTilePosition?: MainTilePosition; // 'start' | 'center'
  playMode?: PlayMode;
  fallbackText?: string;
  contentTileType?: string; // 'product' | 'videoName' | 'none'
  scrollMode?: ScrollMode;

  // how much of "out of view" tiles should be visible
  // relevant only for responsive,
  // other uses what's left from container after static size tiles are placed
  peekWidth?: number;
  tilesAmount?: number; // only for responsive
  aspectRatioW?: number; // only for responsive
  aspectRatioH?: number; // only for responsive

  shouldShowArrows?: boolean;
  shouldShowPlusCTA?: boolean;

  product?: {
    height: number;
  };

  // responsive uses this one for both (item and centerItem/mainItem)
  item?: {
    paddingY: number;
    paddingX: number;
  };
  // relevant only for non-responsive
  centerItem?: {
    paddingY: number;
    paddingX: number;
  };

  // for non-responsive
  video?: {
    width: number;
    height: number;
  };
  centerVideo: {
    width: number;
    height: number;
  };

  controls?: ControlSettings;
}

export interface SpotlightSettingsType {
  mobile?: SpotlightDeviceSettings;
  desktop?: SpotlightDeviceSettings;
}
