import { FEATURE_PRODUCTS_SEARCH_LIMIT } from 'app/src/constants/appFeatures.constants';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import { useProductActions } from 'app/src/context/ProductsStore';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';
import { track } from 'app/src/helpers/Tracker';
import useGetSearchProducts from 'app/src/hooks/useGetSearchProducts';
import ProductSearchInput from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/products/ProductSearchInput';
import ProductsSearchMenu from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/products/ProductsSearchMenu';
import Utils from 'app/src/utils';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';

const SearchProductsContainer = ({
  selectedProducts,
  onProductSelected,
  selectedApp,
  onHasNoPermissions,
  getProductComponent,
  isLoading,
  location = '',
  analyticsData,
  ...props
}) => {
  const { filterExistingProducts, fetchMetaDataFromUrl } = useProductActions();
  const [, { setErrorSnackbar }] = useSnackBar();
  const { getSettingsByKey } = useFeatureActions();
  const getSearchProducts = useGetSearchProducts(selectedApp);
  const ref = useRef();
  const [searchProducts, setSearchProducts] = useState([]);
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const timeoutRef = useRef(null);
  const { customLimit } = getSettingsByKey(FEATURE_PRODUCTS_SEARCH_LIMIT) || {};

  const showEmptyState = () => {
    setValue('');
    setSearchProducts([]);
  };

  const fetchProducts = async value => {
    track(`${location} Search Products Container Selected`, analyticsData);

    if (loading || isLoading) {
      return;
    }

    if (!selectedApp) {
      return;
    }

    setLoading(true);

    const body = {
      term: value,
      appUrl: selectedApp.appUrl,
      appKey: Utils.getAppKey(),
      customLimit,
    };

    try {
      const { searchProducts: products } = await getSearchProducts(body);

      const filteredProducts = filterExistingProducts(selectedProducts, products);
      setSearchProducts(filteredProducts);
    } catch (err) {
      console.log(err);
      setLoading(false);
      if (err.response.status === 401 || err.response.status === 402) {
        onHasNoPermissions?.(true);
      }
    }
    setLoading(false);
  };

  const fetchProductFromUrl = async url => {
    if (!Utils.isValidUrl(url)) {
      return;
    }
    setLoading(true);

    const product = await fetchMetaDataFromUrl(url);
    if (product.err) {
      setErrorSnackbar(product.err);
      setLoading(false);
      return;
    }
    handleProductSelected(product);

    setLoading(false);
  };

  const onSelectValueChange = e => {
    const { value } = e.target;
    clearTimeout(timeoutRef.current);
    setValue(value);

    timeoutRef.current = setTimeout(() => {
      fetchProducts(value);
    }, 600);
  };

  const onInputValueChange = e => {
    const { value } = e.target;
    clearTimeout(timeoutRef.current);
    setValue(value);

    timeoutRef.current = setTimeout(() => {
      fetchProductFromUrl(value);
    }, 400);
  };

  const handleProductSelected = product => {
    showEmptyState();
    onProductSelected?.(product);
  };

  const isMenuOpen = !!searchProducts.length && !!ref.current;
  return (
    <LayoutRoot {...props} open={isMenuOpen}>
      <SearchContainer ref={ref}>
        <ProductSearchInput
          onSelectValueChange={onSelectValueChange}
          onInputValueChange={onInputValueChange}
          isMenuOpen={isMenuOpen}
          value={value}
          isLoading={isLoading || loading}
          fetchProducts={fetchProducts}
          selectedApp={selectedApp}
        />
      </SearchContainer>
      <ProductsSearchMenu
        onClose={showEmptyState}
        isOpen={isMenuOpen}
        getProductComponent={getProductComponent}
        handleProductSelected={handleProductSelected}
        searchProducts={searchProducts}
        searchInputRef={ref}
        selectedApp={selectedApp}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  display: grid;
  grid-template-rows: repeat(2, auto) 1fr;
  filter: ${({ open }) =>
    open
      ? 'drop-shadow(0px 10px 25px rgba(50, 50, 93, 0.1))\n    drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.05))'
      : ''};
`;

const SearchContainer = styled.div`
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-items: center;
`;

export default SearchProductsContainer;
