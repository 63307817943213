import React from 'react';
import styled, { useTheme } from 'styled-components';
import EditorOnOffToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorOnOffToggleItem';
import AsteriskIcon from 'shared/react/images/look_and_feel/AsteriskIcon';
import IconButton from 'app/src/complex_components/IconButton';
import { useLeadFormEditorGroupContext } from 'app/src/pages/project/pages/look_and_feel/editor/editors/lead_form_editor/LeadFormEditorGroupContext';
import { REQUIRED_PROPERTY_KEY } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import Tooltip from 'app/src/basic_components/Tooltip';
import DeleteIcon from 'app/src/images/DeleteIcon';
import EditorItemContent from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorItemContent';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

function CollectField({
  id,
  text,
  renderTextLayout,
  checked,
  required,
  removeCustomField,
  onChange,
}) {
  const theme = useTheme();
  const { handleCollectFieldPropertyChanged } = useLeadFormEditorGroupContext();

  const handleToggleRequired = () => {
    handleCollectFieldPropertyChanged(id, REQUIRED_PROPERTY_KEY, !required);
  };

  const getFieldContent = () => {
    if (removeCustomField) {
      return (
        <EditorItemContent renderTextLayout={renderTextLayout}>
          <DeleteContainer onClick={removeCustomField}>
            <DeleteIcon fill={theme.colors.danger} />
          </DeleteContainer>
        </EditorItemContent>
      );
    }

    return (
      <EditorOnOffToggleItem
        text={text}
        renderTextLayout={renderTextLayout}
        checked={checked}
        onChange={onChange}
      >
        {text}
      </EditorOnOffToggleItem>
    );
  };

  return (
    <LayoutRoot>
      {getFieldContent()}
      <Tooltip text="Determine whether filling this field is required." placement="right">
        <IconButton onClick={handleToggleRequired} width="28px" height="28px">
          <AsteriskIcon fill={required ? theme.colors.primary : theme.colors.gray4} />
        </IconButton>
      </Tooltip>
    </LayoutRoot>
  );
}

export default CollectField;

const LayoutRoot = styled.div`
  display: grid;
  gap: 16px;
  align-items: center;
  grid-template-columns: 1fr auto;
`;

const DeleteContainer = styled(HorizontalFlex)`
  cursor: pointer;
`;
