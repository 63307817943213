import StandardModal from '../modals/StandardModal';
import React, { useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { TextBody, TextH5 } from 'shared/react/components/complex/Text';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import Button from 'shared/react/components/complex/Button';
import SendInvitationIcon from 'app/src/images/SendInvitation';
import { getEmailsArray } from './teamMembers.utils';
import { getEmailsErrorMessage } from './teamMembers.actions';
import { useAccount } from 'app/src/context/AccountStore';
import { track } from '../../helpers/Tracker';
import { FEATURE_TEAM_ACCOUNTS } from 'app/src/constants/appFeatures.constants';
import { useFeatures } from 'app/src/context/FeaturesStore';
import DropDown from 'app/src/basic_components/DropDown';
import { getFormattedPaymentRole } from './team_members_components/TeamMemberPaymentRole';
import {
  PAYMENT_ROLE_LITE_KEY,
  PAYMENT_ROLE_PRO_KEY,
} from 'app/src/constants/teamMembers.constants';
import { useBilling } from '../../hooks/useBillings';
import { getPackageFreeSeats, getPackageSeatPrice } from '../modals/PaymentRoleModal';
import Utils from 'src/utils';

const PAYMENT_ROLE_KEYS = [PAYMENT_ROLE_LITE_KEY, PAYMENT_ROLE_PRO_KEY];

const TeamMembersInviteModalFooterText = ({ errorMessage, paymentRole }) => {
  const theme = useTheme();
  const { currentPackage } = useBilling();
  const price = getPackageSeatPrice(currentPackage);
  const freeSeats = getPackageFreeSeats(currentPackage);

  if (!errorMessage && paymentRole !== PAYMENT_ROLE_PRO_KEY) {
    return null;
  }

  if (errorMessage) {
    return (
      <TextBody textColor={theme.colors.error} fontWeight="700">
        {errorMessage}
      </TextBody>
    );
  }

  const getText = () => {
    const prefix = `You will be charged an additional $${price}/month`;

    if (!freeSeats) {
      return `${prefix} per team member.`;
    }

    return `${prefix} for any business `;
  };

  return (
    <TextBody textColor={theme.colors.black} fontWeight="700">
      {getText()}
    </TextBody>
  );
};

const ON_INPUT_CHANGE_TIMEOUT = 300;

const TeamMembersInviteModal = ({ open, closeModal }) => {
  const [{ teamMembers }, { fetchTeamMembers, sendInvites }] = useAccount();
  const [, { getSettingsByKey }] = useFeatures();
  const { isProPackage } = useBilling();
  const { maxTeamMembers } = getSettingsByKey(FEATURE_TEAM_ACCOUNTS);
  const [message, setMessage] = useState('');
  const [inviteErrorMessage, setInviteErrorMessage] = useState('');
  const [emails, setEmails] = useState([]);
  const [inviteLoading, setInviteLoading] = useState(false);
  const [paymentRole, setPaymentRole] = useState(PAYMENT_ROLE_LITE_KEY);
  const validateInputTimeout = useRef();

  const onInviteEmailsChange = e => {
    const emailsArray = getEmailsArray(e.target.value);

    setEmails(emailsArray);

    const newInviteErrorMessage = getEmailsErrorMessage({
      teamMembers,
      emails: emailsArray,
      maxTeamMembers,
    });
    setInviteErrorMessage(newInviteErrorMessage);
  };

  const scheduleOnInviteEmailsChange = e => {
    clearTimeout(validateInputTimeout.current);
    validateInputTimeout.current = setTimeout(
      () => onInviteEmailsChange(e),
      ON_INPUT_CHANGE_TIMEOUT
    );
  };

  const sendInvitesAndUpdate = async invites => {
    setInviteLoading(true);
    await sendInvites({ emails, ...invites });
    await fetchTeamMembers();
    setInviteLoading(false);

    track('Team Member Invites', {
      numOfInvites: emails.length,
      paymentRole,
    });
    handleClose();
  };

  const onMessageChange = e => {
    setMessage(e.target.value);
  };

  const handleClose = () => {
    setEmails([]);
    setInviteErrorMessage('');
    closeModal();
  };

  const onSend = () => {
    const cleanMessage = Utils.removeHtmlTags(message);
    sendInvitesAndUpdate({ message: cleanMessage, paymentRole });
  };

  return (
    <StandardModal onClose={handleClose} open={open ? open : false} width="90%" maxWidth="920px">
      <StyledTeamMembersInviteModal>
        <TextH5>Invite new members</TextH5>
        <InputContainer>
          <InputLabel>Email addresses</InputLabel>
          <EmailAndPaymentRoleContainer isProPackage={isProPackage}>
            <input
              disabled={inviteLoading}
              autoFocus
              placeholder="To add multiple invites enter emails separated by comma"
              onChange={scheduleOnInviteEmailsChange}
            />
            <PaymentRole
              menuItems={PAYMENT_ROLE_KEYS.map(paymentRole => {
                return {
                  key: paymentRole,
                  title: getFormattedPaymentRole(paymentRole),
                  removeStartIconSpacing: true,
                  onClick: () => setPaymentRole(paymentRole),
                };
              })}
            >
              {getFormattedPaymentRole(paymentRole)}
            </PaymentRole>
          </EmailAndPaymentRoleContainer>
        </InputContainer>
        <InputContainer>
          <InputLabel>Message</InputLabel>
          <textarea
            disabled={inviteLoading}
            placeholder="Hello there 👋 I’m inviting you to Tolstoy so we can create videos for our .... "
            onChange={onMessageChange}
          />
        </InputContainer>
        <InviteModalFooter>
          <TeamMembersInviteModalFooterText
            errorMessage={inviteErrorMessage}
            paymentRole={paymentRole}
          />
          <SendInvitationButton
            rightIcon={<SendInvitationIcon />}
            showRightIcon={true}
            onClick={onSend}
            loading={inviteLoading}
            disabled={inviteErrorMessage || !emails.length}
          >
            Send Invitation
          </SendInvitationButton>
        </InviteModalFooter>
      </StyledTeamMembersInviteModal>
    </StandardModal>
  );
};

export default TeamMembersInviteModal;

const StyledTeamMembersInviteModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  > * {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
  }

  > textarea {
    padding: 16px;
    border: 1px solid ${({ theme }) => theme.colors.gray3};
    box-sizing: border-box;
    border-radius: 10px;
    height: 96px;
  }
`;

const InputLabel = styled(TextBody)`
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: 1px solid ${({ theme }) => theme.colors.black};
`;

const PaymentRole = styled(DropDown)`
  background: ${({ theme }) => theme.colors.gray5};
  height: 100%;
  border: none;
  width: 210px;
  padding: 16px;
  color: ${({ theme }) => theme.colors.gray2};
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  justify-content: space-between;
  margin-left: 2px;
`;

const EmailAndPaymentRoleContainer = styled(HorizontalFlexWrap)`
  height: 48px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray3};

  & input {
    flex-grow: 1;
    background: ${({ theme }) => theme.colors.white};
    border: none;
    padding-left: 16px;
    padding-right: 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: ${({ isProPackage }) => (isProPackage ? '' : '10px')};
    border-bottom-right-radius: ${({ isProPackage }) => (isProPackage ? '' : '10px')};
  }

  & ${PaymentRole} {
    display: ${({ isProPackage }) => (isProPackage ? 'flex' : 'none')};
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

const InviteModalFooter = styled.div`
  display: flex;
  align-items: center;
`;

const SendInvitationButton = styled(Button)`
  margin-left: auto;
`;
