import React from 'react';
import styled from 'styled-components';
import { nanoid } from 'nanoid';
import BasicMenu from 'app/src/complex_components/BasicMenu';
import AddRuleButton from 'app/src/pages/project/pages/rules_and_triggers/editor/shared/AddRuleButton';
import { TextSmall } from 'shared/react/components/complex/Text';
import Tooltip from 'app/src/basic_components/Tooltip';

import {
  dateConditionMenuItems,
  dateConditionTypes,
  generalConditionTypesMenuItems,
  numberConditionMenuItems,
  numberConditionTypes,
  stringConditionTypes,
  whoVisitorAttributes,
} from 'app/src/pages/project/pages/rules_and_triggers/Rule.constants';
import Tabs from 'app/src/basic_components/tabs/Tabs';
import { useRulesContext } from 'app/src/pages/project/pages/rules_and_triggers/RulesContext';
import EmptyState from 'app/src/pages/project/pages/rules_and_triggers/editor/audience/EmptyState';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import RuleGroupContainer from 'app/src/pages/project/pages/rules_and_triggers/editor/shared/RuleGroupContainer';
import OpenLinkIcon from 'app/src/images/dashboard_v2/OpenLinkIcon';
import Utils from 'app/src/utils';

const USERS_WHO_TYPE = 'Users';
const VISITORS_WHO_TYPE = 'Visitors';
const TABS = [{ key: VISITORS_WHO_TYPE }, { key: USERS_WHO_TYPE }];

function RulesAudienceEditor() {
  const {
    visitorModeEnabled,
    whoAttributes,
    setWhoAttributes,
    ruleGroup,
    setRuleGroup,
    addRule,
    setUnsaved,
    getWhoAndConditions,
  } = useRulesContext();

  const andConditions = getWhoAndConditions();

  function getCondition(type) {
    if (stringConditionTypes.includes(type)) {
      return generalConditionTypesMenuItems[0].key;
    }
    return (
      numberConditionTypes.includes(type) ? numberConditionMenuItems : dateConditionMenuItems
    )[0].key;
  }

  function getDefaultValue(type) {
    if (numberConditionTypes.includes(type)) {
      return 0;
    }
    if (dateConditionTypes.includes(type)) {
      const [date] = new Date().toISOString().split('T');
      return date;
    }
    return '';
  }

  function handleSelectedWhoTypeChange(selectedTab) {
    const selectedWhoType = selectedTab.toLowerCase();
    const newRuleGroup = { ...ruleGroup };
    newRuleGroup.visitorModeEnabled = selectedWhoType === VISITORS_WHO_TYPE.toLowerCase();
    setRuleGroup(newRuleGroup);
    setUnsaved(true);
  }

  const getSelectedTab = () => {
    if (!visitorModeEnabled) {
      return TABS[1].key;
    }
    return TABS[0].key;
  };

  function getSpecificWhoAttributes() {
    if (!visitorModeEnabled) {
      return whoAttributes;
    }

    return whoAttributes.filter(whoAttribute =>
      whoVisitorAttributes.some(whoVisitorAttribute => whoVisitorAttribute.key === whoAttribute.key)
    );
  }

  const handleCustomAttributesClick = () => {
    Utils.openInNewTab('https://developers.gotolstoy.com/javascript-sdk/identify');
  };

  return (
    <LayoutRoot>
      <WhoTitleContainer>
        <Tabs
          isSmall={true}
          tabs={TABS}
          selectedTab={getSelectedTab()}
          setSelectedTab={handleSelectedWhoTypeChange}
          location="RulesAudienceEditor"
        />
        <BasicMenu
          menuItems={getSpecificWhoAttributes()}
          menuItemCustomPadding="12px 16px"
          customFooter={
            <Tooltip text="Learn more about how you can create and use custom attributes!">
              <CustomAttributesButtonContainer onClick={handleCustomAttributesClick}>
                <CustomAttributesButtonText>Custom attributes</CustomAttributesButtonText>
                <OpenLinkIcon />
              </CustomAttributesButtonContainer>
            </Tooltip>
          }
          onMenuItemClick={({ key: type }) =>
            addRule({
              key: nanoid(5),
              condition: getCondition(type),
              behaviors: ['visibility'],
              type,
              value: getDefaultValue(type),
            })
          }
        >
          <AddRuleButton />
        </BasicMenu>
      </WhoTitleContainer>

      <RuleGroupContainer
        andConditions={andConditions}
        emptyState={<EmptyState />}
        attributes={whoAttributes}
        setAttributes={setWhoAttributes}
      />
    </LayoutRoot>
  );
}

export default RulesAudienceEditor;

const LayoutRoot = styled(Gap8VerticalFlex)`
  padding-top: 8px;
`;

const WhoTitleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 8px;
  align-items: center;
`;

const CustomAttributesButtonContainer = styled.div`
  cursor: pointer;
  border-top: 1px solid ${({ theme }) => theme.colors.ghostLight};
  color: ${({ theme }) => theme.colors.blue7};
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  padding: 13px 16px 8px;
`;

const CustomAttributesButtonText = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.blue7};
`;
