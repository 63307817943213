export const ANSWER_BUTTON_DATA_TEST_ID = 'answer-button';
export const VIDEO_CONTAINER_DATA_TEST_ID = 'video-container';
export const DURATION_BAR_CONTAINER_DATA_TEST_ID = 'duration-bar';
export const CHAPTERS_BUTTON_DATA_TEST_ID = 'chapters-button';
export const BACK_BUTTON_DATA_TEST_ID = 'back-button';
export const CONTROLS_HEADER_DATA_TEST_ID = 'controls-header';
export const ANSWERS_CONTAINER_DATA_TEST_ID = 'answers-container';
export const OVERLAY_TEXT_DATA_TEST_ID = 'overlay-text';
export const CONTROLS_CONTAINER_DATA_TEST_ID = 'overlay-controls-container';
export const END_SCREEN_DATA_TEST_ID = 'end-screen';
export const PLAY_BUTTON_DATA_TEST_ID = 'play-button';
export const INPUT_FIELD_DATA_TEST_ID = 'input-field';
export const NEXT_BUTTON_DATA_TEST_ID = 'next-button';
export const RECORD_BUTTON_DATA_TEST_ID = 'record-button';
export const USE_VIDEO_BUTTON_DATA_TEST_ID = 'use-video';
export const DONT_USE_VIDEO_BUTTON_DATA_TEST_ID = 'dont-use-video';
export const ERROR_MESSAGE_DATA_TEST_ID = 'error-message';
export const END_TEXT_DATA_TEST_ID = 'end-text-all-done';
export const FREE_TEXT_DATA_TEST_ID = 'free-text-input';
export const SINGLE_BUTTON_MODAL_DATA_TEST_ID = 'submit-button';
export const AUTOPLAY_UNMUTE_DATA_TEST_ID = 'autoplay-unmute';
export const VIDEO_WASNT_FOUND_DATA_TEST_ID = 'video-wasnt-found';
