export const ADD_TO_CART = 'tolstoyAddToCart';
export const BUY_NOW = 'buyNow';
export const VIEW_PRODUCT = 'viewProduct';
export const REMOVE_FROM_CART = 'removeFromCart';
export const CART_PRODUCT_CLEAR = 'cartProductClear';
export const CART_ITEM_QUANTITY_CHANGE = 'tolstoyCartItemQuantityChange';
export const ADD_TO_CART_SUCCESS_MESSAGE = 'tolstoyAddToCartSuccess';
export const ADD_TO_CART_ERROR_MESSAGE = 'tolstoyAddToCartError';
export const ADD_TO_CART_SOLD_OUT_MESSAGE = 'tolstoyAddToCartSoldOut';
export const CART_ITEM_QUANTITY_CHANGE_SUCCESS_MESSAGE = 'tolstoyCartItemQuantityChangeSuccess';
export const CART_ITEM_QUANTITY_CHANGE_ERROR_MESSAGE = 'cartItemQuantityChangeError';
export const CART_DATA_RESPONSE_MESSAGE = 'tolstoyCartDataResponse';
export const CART_DATA_REQUEST_MESSAGE = 'tolstoyCartDataRequest';
export const PRODUCT_RECOMMENDATIONS_REQUEST_MESSAGE = 'tolstoyProductRecommendationsRequest';
export const PRODUCT_RECOMMENDATIONS_RESPONSE_MESSAGE = 'tolstoyProductRecommendationsResponse';
export const LOGIN_WITH_MULTIPASS_URL_REQUEST_MESSAGE = 'tolstoyLoginWithMultipassUrl';
export const LOGIN_WITH_MULTIPASS_URL_RESPONSE_MESSAGE =
  'tolstoyLoginWithMultipassUrlResponseMessage';
