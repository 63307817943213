import React from 'react';
import styled, { css } from 'styled-components';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

const Tab = ({ tab, selectedTabKey, onTabChanged }) => {
  const isSelected = tab.key === selectedTabKey;

  return (
    <LayoutRoot onClick={() => onTabChanged(tab)}>
      <TextContainer isSelected={isSelected}>{tab.name}</TextContainer>
    </LayoutRoot>
  );
};

export default Tab;

const LayoutRoot = styled(HorizontalFlex)`
  transition: 0.3s;
  border-radius: 8px;
  max-lines: 1;
  white-space: nowrap;
  gap: 4px;
  width: 40px;
  justify-content: center;
`;

const TextContainer = styled.div`
  display: flex;
  font-size: 16px;
  transition: width ease-in-out 0.3s;
  position: relative;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black};
  ${({ isSelected, theme }) =>
    isSelected
      ? css`
          font-weight: 800;
          border-bottom: ${theme.colors.primaryActive} 3px solid;
          bottom: 0;
        `
      : css`
          font-weight: 400;
          border: none;
          padding-bottom: 3px;

          &:hover {
            border: none;
          }

          &:after {
            content: '';
            position: absolute;
            width: 0px;
            height: 3px;
            left: 50%;
            bottom: 0;
            background-color: ${theme.colors.gray14};
            transition: all ease-in-out 0.2s;
          }

          &:hover::after {
            width: 100%;
            left: 0;
          }
        `};
`;
