import React from 'react';

const UploadFileIcon = ({ fill = '#50545E' }) => {
  return (
    <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.25 11C12.8125 11 12.5 11.3438 12.5 11.75V14C12.5 14.8438 11.8125 15.5 11 15.5H3C2.15625 15.5 1.5 14.8438 1.5 14V11.75C1.5 11.3438 1.15625 11 0.75 11C0.3125 11 0 11.3438 0 11.75V14C0 15.6875 1.3125 17 3 17H11C12.6562 17 14 15.6875 14 14V11.75C14 11.3438 13.6562 11 13.25 11ZM6.46875 1.21875L2.21875 5.21875C1.90625 5.5 1.90625 5.96875 2.1875 6.28125C2.46875 6.59375 2.9375 6.59375 3.25 6.3125L6.25 3.5V11.2812C6.25 11.6875 6.5625 12 7 12C7.40625 12 7.75 11.6875 7.75 11.2812V3.5L10.7188 6.3125C11.0312 6.59375 11.5 6.59375 11.7812 6.28125C11.9062 6.125 12 5.9375 12 5.78125C12 5.5625 11.9062 5.375 11.75 5.21875L7.5 1.21875C7.21875 0.9375 6.75 0.9375 6.46875 1.21875Z"
        fill={fill}
      />
    </svg>
  );
};

export default UploadFileIcon;
