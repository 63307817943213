import React, { useMemo } from 'react';
import styled from 'styled-components';
import { getSteps } from 'app/src/utils/project.utils';
import { project as Project } from 'app/src/types/entities';
import { useBuilderFormContext } from 'app/src/context/BuilderFormStore';
import { useForm } from 'react-hook-form';
import { useVideoActions } from 'app/src/context/VideosStore';
import TableVideoCard from '../../dynamic-type-content/components/video-card/TableVideoCard';

type Props = {
  project: Project;
};

const DerivedProjectGrid = ({ project }: Props) => {
  const { steps: builderFormSteps } = useBuilderFormContext();
  const { getVideoById } = useVideoActions();
  const methods = useForm({ defaultValues: { stepsOrder: project?.stepsOrder || [] } });
  const { watch } = methods;
  const stepsOrderFormValues = watch();

  const steps = useMemo(() => {
    const projectSteps = getSteps(project, stepsOrderFormValues.stepsOrder);
    return projectSteps.map(step => builderFormSteps[step.id]?.step || step);
  }, [project?.steps, project?.stepsOrder, builderFormSteps, stepsOrderFormValues]);

  return (
    <LayoutRoot>
      {steps.map(step => {
        return (
          <StyledVideoCard
            key={step.id}
            canLock={false}
            canOpenProductsModal={false}
            project={project}
            video={getVideoById(step.videoId)}
          />
        );
      })}
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  grid-template-columns: repeat(auto-fill, minmax(171px, 1fr));
  display: grid;
  gap: 16px;
  padding: 8px 0;
`;

const StyledVideoCard = styled(TableVideoCard)`
  cursor: default;
`;

export default DerivedProjectGrid;
