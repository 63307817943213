import React from 'react';
import '../../../assets/input_multi_modal.scss';
import DynamicComponent from '../../common/DynamicComponent';
import styled from 'styled-components';
import Utils from '../../../utils';

const CustomForm = ({ values, setValues, inputs, ...props }) => {
  const onChange = name => value => {
    setValues({ ...values, [name]: value });
  };

  return (
    <ItemsContainer>
      {inputs.map(input => (
        <ItemContainer key={input.name} theme={input.theme}>
          <DynamicComponent
            {...props}
            onChange={onChange(input.name)}
            value={values[input.name]}
            disabled={input.connectedComponent ? !values[input.connectedComponent] : false}
            {...input}
          />
        </ItemContainer>
      ))}
    </ItemsContainer>
  );
};

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media (max-width: 450px) {
    overflow-y: auto;
    padding-top: 20px;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: ${({ theme }) => (Utils.isInnerTheme(theme) ? '-6px' : '0px')}; ;
`;

export default CustomForm;
