import React from 'react';
import GhostsEmptyState from 'src/complex_components/GhostsEmptyState';
import StraightArrowRightIcon from 'src/images/creation_method_icons/StraightArrowRightIcon';
import styled from 'styled-components';
import useAccountOnboardingTemplates from '../../../hooks/useAccountOnboardingTemplates';

type Props = {
  header: string;
  subheader: string;
};

const OnboardingTemplatesReviewProductsEmptyState = ({ header, subheader }: Props) => {
  const { handleBackButtonClick } = useAccountOnboardingTemplates();

  const buttonContent = (
    <>
      <StyledArrowIcon />
      Back to previous task
    </>
  );

  return (
    <GhostsEmptyState
      onClick={handleBackButtonClick}
      buttonContent={buttonContent}
      header={header}
      subheader={subheader}
      textContainerWidth="565px"
    />
  );
};

const StyledArrowIcon = styled(StraightArrowRightIcon)`
  transform: rotate(180deg);
`;

export default OnboardingTemplatesReviewProductsEmptyState;
