import { isImageAsset, getStepImageUrl, isGalleryAsset } from 'widget/src/utils/assets.utils.js';
import { handleLinkString } from 'widget/src/widgets/EmbedWidgets/SpotlightCarousel/components/handleLinkString';
import { getHexOpacity } from '../../../common/color.js';
import { getPlayButtonSvg, leftArrowSvg } from '../../../common/svgs.js';
import { VIDEO_OUTPUT } from '../../../config/widget.config.js';
import { WEBP_EXTENSION } from '../../../constants/extensions.js';
import {
  FEATURE_CAROUSEL_SHOW_DOTS,
  FEATURE_CAROUSEL_VIDEOS
} from '../../../constants/features.constants.js';
import { createElement } from '../../../utils/dom.utils.js';
import { getPublicId, getVideoBaseUrl } from '../embedWidgets.utils.js';
import style from './carousel.module.css';
import { expandSvg, muteSvg, unmuteSvg } from './carousel.svgs.js';
import {
  CAROUSEL_TILE_NAME_LOCATION_TYPES,
  PUBLIC_CLASSES,
  SPACING_HORIZONTAL,
  TILE_NAME_HEIGHT
} from './carouselConstants.js';

let isCustomFont = false;

const setCustomFontFamilyIfNeeded = element => {
  if (!isCustomFont) {
    return;
  }

  element.style.fontFamily = 'tolstoy-custom-font-family';
};

const getImageUrl = ({ videoOwner, stockAsset, videoId }, extension, posterSettings) => {
  if (!videoId) {
    return '';
  }

  const posterUrl = stockAsset?.posterUrl;
  const hasOriginal = stockAsset?.hasOriginal;
  const shopifyPosterUrl = stockAsset?.shopifyPosterUrl;

  if (shopifyPosterUrl && posterSettings?.useShopifyPoster) {
    const searchParams = new URLSearchParams(posterSettings.shopifyAttributes || {});
    return `${shopifyPosterUrl}&${searchParams.toString()}`;
  }

  if (posterUrl?.endsWith(extension) || (posterUrl && !hasOriginal)) {
    return posterUrl;
  }

  return `${VIDEO_OUTPUT}/public/${videoOwner}/${videoId}/${videoId}${extension}`;
};

const getTitleElement = (
  {
    carouselFontSize,
    carouselFontColor,
    carouselTitle,
    carouselTitleText,
    carouselTitleFontWeight
  },
  publishId
) => {
  if (!carouselTitle || !carouselTitleText) {
    return '';
  }

  const titleElement = document.createElement('div');
  titleElement.classList.add(style.titleContainer, PUBLIC_CLASSES.title);
  titleElement.dataset.tolstoyElement = getPublicId(PUBLIC_CLASSES.title, publishId);
  titleElement.setAttribute('role', 'heading');
  titleElement.setAttribute('aria-level', '2');
  titleElement.setAttribute('aria-label', `Tolstoy carousel header: ${carouselTitleText}`);
  titleElement.style.fontSize = `${carouselFontSize}px`;
  titleElement.style.fontWeight = carouselTitleFontWeight;
  titleElement.style.color = carouselFontColor;
  setCustomFontFamilyIfNeeded(titleElement);
  titleElement.append(carouselTitleText);

  return titleElement;
};

const getTileNameTextElement = ({
  text,
  carouselTileNameTextColor: color,
  carouselTileNameTextSize: fontSize
}) => {
  const tileNameTextElement = document.createElement('div');
  tileNameTextElement.classList.add(style.tileNameText, PUBLIC_CLASSES.tileNameText);
  tileNameTextElement.style.color = color;
  tileNameTextElement.style.fontSize = `${fontSize}px`;
  tileNameTextElement.style.lineHeight = `${fontSize * 1.2}px`;
  setCustomFontFamilyIfNeeded(tileNameTextElement);
  tileNameTextElement.append(text);

  return tileNameTextElement;
};

const getTileNameElement = ({
  text = '',
  carouselTileNameEnabled,
  carouselTileNameTextSize,
  carouselTileNameTextColor,
  carouselTileNameBackgroundEnabled,
  carouselTileNameBackgroundColor,
  carouselTileNameBackgroundTransparancy,
  carouselBorderRadius,
  isOver
}) => {
  if (!carouselTileNameEnabled || !text) {
    return;
  }

  const tileNameContainerElement = document.createElement('div');
  tileNameContainerElement.classList.add(style.tileNameContainer, PUBLIC_CLASSES.tileNameContainer);
  tileNameContainerElement.style.height = `${TILE_NAME_HEIGHT}px`;
  tileNameContainerElement.style.borderRadius = `0 0 ${carouselBorderRadius}px ${carouselBorderRadius}px`;
  if (isOver && carouselTileNameBackgroundEnabled) {
    tileNameContainerElement.style.background = getHexOpacity(
      carouselTileNameBackgroundColor,
      carouselTileNameBackgroundTransparancy / 100
    );
  }

  tileNameContainerElement.append(
    getTileNameTextElement({
      text,
      carouselTileNameTextColor,
      carouselTileNameTextSize
    })
  );

  return tileNameContainerElement;
};

const getTileVideoHtml = ({
  partName,
  videoUrl,
  carouselShape,
  videoRadius,
  posterImage,
  publishId,
  step,
  featureSettings
}) => {
  const classNames = [style.video, PUBLIC_CLASSES.video];

  const attributes = {
    playsinline: '',
    muted: '',
    'aria-label': partName,
    'data-tolstoy-element': getPublicId(PUBLIC_CLASSES.video, publishId),
    'data-tolstoy-is-sound-allowed': step.isSoundAllowed,
    'data-tolstoy-video-id': step.videoId,
    poster: posterImage
  };

  if (featureSettings?.[FEATURE_CAROUSEL_VIDEOS]?.preload) {
    attributes.preload = featureSettings[FEATURE_CAROUSEL_VIDEOS]?.preload;
  }

  const styleAttributes = {
    'z-index': 1,
    'aspect-ratio': carouselShape,
    'border-radius': `${videoRadius}px`
  };

  const videoElement = createElement({
    tagName: 'video',
    classNames,
    attributes,
    src: videoUrl,
    style: styleAttributes
  });

  return videoElement.outerHTML;
};

const getStepElement = ({
  carouselEmbed,
  publishId,
  index: stepIndex,
  featureSettings,
  showCreatorProfileLink,
  carouselCreatorProfileLinkPosition
}) => {
  const {
    carouselSteps,
    carouselBorderColor,
    carouselBorderWidth,
    carouselBorder,
    carouselBorderRadius,
    carouselPlayButtonBackground = true,
    carouselPlayButtonBackgroundColor = '#000',
    carouselPlayButtonBorder = false,
    carouselPlayButtonBorderColor = '#fff',
    carouselPlayButtonOpacity,
    carouselShape,
    carouselMotion,
    carouselTileNameEnabled = false,
    carouselTileNameLocation = CAROUSEL_TILE_NAME_LOCATION_TYPES.under,
    carouselTileNameTextSize = 16,
    carouselTileNameTextColor = 'black',
    carouselTileNameBackgroundEnabled = false,
    carouselTileNameBackgroundColor = 'black',
    carouselTileNameBackgroundTransparancy = 50,
    skeleton = false,
    loadAll = false,
    carouselPlayInTileFirst
  } = carouselEmbed;
  const step = carouselSteps[stepIndex];
  const stepElement = document.createElement('div');
  const posterSettings = featureSettings?.['widget-loading']?.posterSettings;
  const isImage = isImageAsset(step);
  const isGallery = isGalleryAsset(step);

  const videoUrl =
    isImage || isGallery
      ? ''
      : getVideoBaseUrl({
          step,
          embedMotion: carouselMotion,
          loadAll,
          isCarouselPlayInTileFirst: carouselPlayInTileFirst
        });
  const partName = step.partName || `Carousel video ${stepIndex + 1} preview`;
  const fileExtension = WEBP_EXTENSION;
  const posterImage = isImage
    ? getStepImageUrl(step)
    : getImageUrl(step, fileExtension, posterSettings);

  const playButtonStyleString = `
    opacity:${carouselPlayButtonOpacity};
    background: ${carouselPlayButtonBackground ? carouselPlayButtonBackgroundColor : 'transparent'};
    border: ${carouselPlayButtonBorder ? 2 : 0}px solid ${carouselPlayButtonBorderColor};
  `;

  const borderWidth = carouselBorder ? carouselBorderWidth : 0;
  const borderColor = carouselBorder && carouselBorderColor;
  const borderString = carouselBorder ? `border: ${borderWidth}px solid ${borderColor}` : '';
  const backgroundString = borderColor ? `background:${borderColor}` : '';
  const videoRadius = carouselBorderRadius - borderWidth;
  const playButtonSvg = getPlayButtonSvg(
    carouselPlayButtonBorder ? carouselPlayButtonBorderColor : undefined
  );

  const tileVideoHtml = getTileVideoHtml({
    partName,
    videoUrl,
    carouselShape,
    videoRadius,
    posterImage,
    publishId,
    step,
    featureSettings
  });

  const overElement = createElement({
    tagName: 'div',
    classNames: [style.video],
    styleString: 'background:transparent;display:flex;width:fit-content;z-index:2;'
  });

  const playButtonElement =
    isImage || isGallery
      ? ''
      : `
    <button
      aria-label="Open Tolstoy widget"
      type="button"
      class="
        ${style.playButtonContainer}
        ${skeleton ? style.playButtonSkeleton : ''}
        ${PUBLIC_CLASSES.playButtonContainer}
      "
      data-tolstoy-element="${getPublicId(PUBLIC_CLASSES.playButtonContainer, publishId)}"
      style="${playButtonStyleString}"
    >
      ${playButtonSvg}
    </button>
  `;

  const muteButtonElement = isImage
    ? ''
    : `
    <button
      role="button"
      aria-label="Mute/unmute video sound"
      type="button"
      class="${style.controlButton}"
      data-tolstoy-element="${getPublicId(PUBLIC_CLASSES.muteButton, publishId)}"
      data-tolstoy-video-id="${step.videoId}"
    >
      ${unmuteSvg}
      ${muteSvg}
    </button>
  `;

  const handleLink = handleLinkString({
    step,
    showCreatorProfileLink,
    carouselCreatorProfileLinkPosition
  });

  stepElement.innerHTML = `
    <div
      style="border-radius: ${carouselBorderRadius}px;${borderString};${backgroundString}"
      class="${style.videoContainer} ${PUBLIC_CLASSES.videoContainer}"
      ${skeleton ? style.skeleton : ''}
      data-tolstoy-element="${getPublicId(PUBLIC_CLASSES.videoContainer, publishId)}"
    >
      ${tileVideoHtml}
      ${overElement.outerHTML}
      ${playButtonElement}
      ${handleLink}

      <div
        class="${style.playInTileControlsContainer} ${PUBLIC_CLASSES.controlsContainer}"
        data-tolstoy-element="${getPublicId(PUBLIC_CLASSES.controlsContainer, publishId)}"
      >
        <button
          class="${style.controlButton}"
          data-tolstoy-element="${getPublicId(PUBLIC_CLASSES.expandButton, publishId)}"
          aria-label="Open Tolstoy widget"
          type="button"
        >
          ${expandSvg}
        </button>

        ${muteButtonElement}
      </div>
    </div>
  `;

  const isOver = carouselTileNameLocation === CAROUSEL_TILE_NAME_LOCATION_TYPES.over;

  stepElement.classList.add(style.tileContainer, PUBLIC_CLASSES.tileContainer);
  stepElement.dataset.tolstoyElement = getPublicId(PUBLIC_CLASSES.tileContainer, publishId);

  const tileNameElement = getTileNameElement({
    text: step.partName,
    carouselTileNameEnabled,
    carouselTileNameTextSize,
    carouselTileNameTextColor,
    carouselTileNameBackgroundEnabled,
    carouselTileNameBackgroundColor,
    carouselTileNameBackgroundTransparancy,
    carouselBorderRadius,
    isOver
  });

  if (tileNameElement) {
    stepElement.append(tileNameElement);
  }

  return stepElement;
};

const getStepsElement = ({
  carouselEmbed,
  publishId,
  featureSettings,
  showCreatorProfileLink,
  carouselCreatorProfileLinkPosition
}) => {
  const {
    carouselSteps,
    carouselSpacingHorizontal = SPACING_HORIZONTAL,
    carouselTileNameLocation = CAROUSEL_TILE_NAME_LOCATION_TYPES.under,
    skeleton = false
  } = carouselEmbed;
  const stepsContainerElement = document.createElement('div');
  stepsContainerElement.classList.add(style.videosContainer, PUBLIC_CLASSES.videosContainer);

  if (window?.safari) {
    stepsContainerElement.classList.add(style.videosContainerSafariWorkaround);
  }

  if (skeleton) {
    stepsContainerElement.classList.add(style.videosContainerSkeleton);
  }

  stepsContainerElement.dataset.tolstoyElement = getPublicId(
    PUBLIC_CLASSES.videosContainer,
    publishId
  );
  stepsContainerElement.style.gap = `${carouselSpacingHorizontal}px`;
  stepsContainerElement.style.gridTemplateColumns = `repeat(${carouselSteps.length}, calc(25% - ${carouselSpacingHorizontal}px))`;
  stepsContainerElement.id = getPublicId(PUBLIC_CLASSES.videosContainer, publishId);
  stepsContainerElement.role = 'group';
  stepsContainerElement.ariaLabel = 'Tolstoy Carousel';
  stepsContainerElement.dataset.carouselMuted = false;
  stepsContainerElement.dataset.carouselTileNameLocation = carouselTileNameLocation;

  for (let index = 0; index < carouselSteps.length; index++) {
    const stepElement = getStepElement({
      carouselEmbed,
      publishId,
      index,
      featureSettings,
      showCreatorProfileLink,
      carouselCreatorProfileLinkPosition
    });
    stepsContainerElement.append(stepElement);
  }

  return stepsContainerElement;
};

const getTileNameHeightElement = ({
  carouselTileNameEnabled: enabled,
  carouselTileNameLocation: location,
  carouselSteps
}) => {
  if (!enabled || location !== CAROUSEL_TILE_NAME_LOCATION_TYPES.under) {
    return;
  }

  const hasNoStepWithPartName = carouselSteps.every(step => !step.partName);
  if (hasNoStepWithPartName) {
    return;
  }

  const tileNameHeightElement = document.createElement('div');
  tileNameHeightElement.classList.add(
    style.tileNameHeightPlaceholder,
    PUBLIC_CLASSES.tileNameHeightPlaceholder
  );
  tileNameHeightElement.style.height = `${TILE_NAME_HEIGHT}px`;

  return tileNameHeightElement;
};

const getArrowButtonElement = (isNextButton, tileNameHeightPlaceholderElement, publishId) => {
  const arrowButtonContainerElement = document.createElement('div');
  arrowButtonContainerElement.classList.add(
    style.arrowButtonContainer,
    PUBLIC_CLASSES.arrowButtonContainer
  );

  const arrowButtonElement = document.createElement('div');
  arrowButtonElement.classList.add(style.arrowButton, PUBLIC_CLASSES.arrowButton);

  const publicClass = isNextButton ? PUBLIC_CLASSES.nextButton : PUBLIC_CLASSES.previousButton;

  const iconContainerElement = document.createElement('button');
  iconContainerElement.type = 'button';
  iconContainerElement.dataset.tolstoyElement = getPublicId(publicClass, publishId);
  iconContainerElement.classList.add(publicClass, style.previousButtonContainer);

  if (isNextButton) {
    iconContainerElement.classList.add(style.nextButtonContainer);
    iconContainerElement.setAttribute('aria-label', 'Tolstoy carousel: next button');
  } else {
    iconContainerElement.setAttribute('aria-label', 'Tolstoy carousel: previous button');
  }

  iconContainerElement.innerHTML = leftArrowSvg;
  arrowButtonElement.append(iconContainerElement);
  arrowButtonContainerElement.append(arrowButtonElement);
  if (tileNameHeightPlaceholderElement) {
    arrowButtonContainerElement.append(tileNameHeightPlaceholderElement);
  }

  return arrowButtonContainerElement;
};

const getDotsContainerElement = (featureSettings, publishId) => {
  if (!featureSettings?.[FEATURE_CAROUSEL_SHOW_DOTS]) {
    return '';
  }

  const dotsContainerElement = document.createElement('div');
  dotsContainerElement.classList.add(style.dotsContainer, PUBLIC_CLASSES.dotsContainer);
  dotsContainerElement.dataset.tolstoyElement = getPublicId(
    PUBLIC_CLASSES.dotsContainer,
    publishId
  );

  return dotsContainerElement;
};

const getHTML = ({
  carouselEmbed,
  publishId,
  featureSettings,
  design,
  showCreatorProfileLink,
  carouselCreatorProfileLinkPosition
}) => {
  const { carouselPaddingHorizontal = 0, carouselPaddingVertical = 0 } = carouselEmbed;
  isCustomFont = Boolean(design?.branding?.typography?.font?.family);
  const title = getTitleElement(carouselEmbed, publishId)?.outerHTML || '';
  const steps =
    getStepsElement({
      carouselEmbed,
      publishId,
      featureSettings,
      showCreatorProfileLink,
      carouselCreatorProfileLinkPosition
    })?.outerHTML || '';

  const tileNameHeightElement = getTileNameHeightElement(carouselEmbed);
  const previousButton =
    getArrowButtonElement(false, tileNameHeightElement, publishId)?.outerHTML || '';
  const nextButton = getArrowButtonElement(true, tileNameHeightElement, publishId)?.outerHTML || '';
  const dotsContainer = getDotsContainerElement(featureSettings, publishId).outerHTML || '';

  return `
    <div
      v-pre
      class="${style.carouselContainer} ${PUBLIC_CLASSES.carouselContainer}"
      data-tolstoy-element="${getPublicId(PUBLIC_CLASSES.carouselContainer, publishId)}"
      style="padding: ${carouselPaddingVertical}px ${carouselPaddingHorizontal}px;"
    >
      ${title}
      <div
        class="${style.carouselVideoContainer} ${PUBLIC_CLASSES.videoCarouselContainer}"
        data-tolstoy-element="${getPublicId(PUBLIC_CLASSES.videoCarouselContainer, publishId)}"
      >
        ${previousButton}
        ${steps}
        ${nextButton}
      </div>
      ${dotsContainer}
    </div>`;
};

export default getHTML;
