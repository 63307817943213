// This file contains message sent using the old messaging system
// if you want to add a new message, please use the new messaging system
// new events should be added to internalEvents.constants.js

export const TOLSTOY_ESCAPE_KEY_PRESSED_MESSAGE = 'tolstoyEscapeKeyPressed';
export const TOLSTOY_WATCHED_PRODUCT_IDS_MESSAGE = 'tolstoyWatchedProductIds';
export const SHOW_FEED_PRODUCT_MODAL_MESSAGE = 'showFeedProductModal';
export const WIDGETS_MESSAGES = 'tolstoyWidgets';
export const SHOW_FEED_CART_MOBILE_MESSAGE = 'showFeedCartMobile';
export const TOLSTOY_MOVE_TO_URL = 'tolstoyMoveToUrl';
export const TOLSTOY_CLOSE_MODAL_MESSAGE = 'tolstoyCloseModalMessage';
export const TOLSTOY_PLAYER_READY_MESSAGE = 'tolstoyPlayerReady';

export const WIDGET_MESSAGES_EVENTS = {
  OPEN: 'open',
  CLOSE: 'close',
  PLAY: 'play',
  PAUSE: 'pause'
};

export const ACTIVE_WIDGET_MESSAGES_EVENTS = [
  WIDGET_MESSAGES_EVENTS.OPEN,
  WIDGET_MESSAGES_EVENTS.PLAY
];

export const INACTIVE_WIDGET_MESSAGES_EVENTS = [
  WIDGET_MESSAGES_EVENTS.CLOSE,
  WIDGET_MESSAGES_EVENTS.PAUSE
];

export const PRE_CONFIG_MESSAGES = {
  ready: 'tolstoyPreConfigMessengerReady',
  vodAssetIds: 'tolstoyVodAssetIds'
};

export const RECHARGE_MESSAGING = 'rechargeMessaging';
export const RECHARGE_WIDGET_DATA_MESSAGE = 'tolstoyRechargeWidgetData';
export const REQUEST_RECHARGE_WIDGET_DATA_MESSAGE = 'tolstoyRequestRechargeWidgetData';
export const REQUEST_RECHARGE_ADD_TO_CART_MESSAGE = 'tolstoyRequestRechargeAddToCart';
export const RECHARGE_ADD_TO_CART_RESPONSE_MESSAGE = 'tolstoyRechargePostToCartResponse';
