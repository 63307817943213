import React from 'react';
import styled from 'styled-components';
import { useQuizContext } from 'app/src/pages/project/pages/quiz/QuizContext';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import QuizResultExpander from 'app/src/pages/project/pages/quiz/quiz_result/QuizResultExpander';

function QuizResultList() {
  const { quizResults } = useQuizContext();

  return (
    <LayoutRoot>
      {quizResults
        .filter(({ disabled }) => !disabled)
        .map((quizResult, index) => (
          <QuizResultExpander key={quizResult.id} quizResult={quizResult} index={index} />
        ))}
    </LayoutRoot>
  );
}

export default QuizResultList;

const LayoutRoot = styled(Gap16VerticalFlex)`
  padding-bottom: 16px;
`;
