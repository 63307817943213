import React from 'react';
import { useSnackBarActions } from 'app/src/context/ui_store/SnackBarStore';
import useNavigation from 'app/src/hooks/useNavigation';

const ErrorPage = () => {
  const { setErrorSnackbar } = useSnackBarActions();
  const { navigateToRoot } = useNavigation();
  setErrorSnackbar('Something went wrong, please try again later or contact support.');
  navigateToRoot();
  return null;
};

export default ErrorPage;
