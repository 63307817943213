import { getThumbnailFromVideoPublicPath } from '../actions/video.actions';

export const projectStepBuilder = ({ videoUrl, defaultResponsesEnabled, answers, overlayText }) => {
  return {
    video: {
      url: videoUrl,
      thumbnail: getThumbnailFromVideoPublicPath(videoUrl),
      mirror: true,
    },
    defaultResponsesEnabled,
    answers,
    overlayText,
  };
};
