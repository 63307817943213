import React from 'react';
import styled, { useTheme } from 'styled-components';

import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { app, project as Project } from 'app/src/types/entities';
import BasicAccordionV2 from 'src/basic_components/BasicAccordionV2';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import { TextH6, TextSmall } from 'shared/react/components/basic/text/TextV2';
import { PrimaryButton } from 'shared/react/components/basic/button-v2/BasicButton';
import InstallationIcon from 'src/images/InstallationIcon';
import { useApps } from 'src/context/AppsStore';
import GreenCheckCircleIcon from 'src/images/dashboard_v2/GreenCheckCircleIcon';
import useAccountInstallation from 'src/hooks/useAccountInstallation';
import useNavigation from 'src/hooks/useNavigation';
import { track } from 'src/helpers/Tracker';
import AccountInstallationShopify from 'app/src/pages/project/pages/installation/components/shared/account/AccountInstallationShopify';

type Props = {
  project: Project;
  currentApp: app;
};

const getInstallationTexts = (isShopify: boolean) => {
  if (isShopify) {
    return {
      stepTitle: 'Add Tolstoy app to store',
      stepSubtitle: '(Once per store)',
    };
  }

  return {
    stepTitle: 'Add Tolstoy app to site',
    stepSubtitle: '(Once per site)',
  };
};

const AccountInstallationSection = ({ project, currentApp }: Props) => {
  const theme = useTheme();
  const [{ shopify: isShopify }] = useApps();
  const { navigateToAccountInstall } = useNavigation();

  const [{ isInstallationCompleted }] = useAccountInstallation(currentApp?.id);

  const onAccountInstallationClick = () => {
    const accountQueryString = `?appUrl=${project?.appUrl}`;

    track('Navigate To Account Installation Click');
    navigateToAccountInstall(accountQueryString);
  };

  const { stepTitle, stepSubtitle } = getInstallationTexts(isShopify);

  if (isShopify) {
    return (
      <AccountInstallationShopify
        isInstallationCompleted={isInstallationCompleted}
        appUrl={project.appUrl}
        currentApp={currentApp}
      />
    );
  }

  return (
    <LayoutRoot>
      <BasicAccordionV2
        startExpanded={!isInstallationCompleted}
        header={
          <>
            {isInstallationCompleted && <GreenCheckCircleIcon />}
            <HeaderTitle>{stepTitle}</HeaderTitle>
            <TextSmallGrey>{stepSubtitle}</TextSmallGrey>
          </>
        }
      >
        <Content>
          <TextSmall>
            Before you can install this Tolstoy, please complete your account installation first.
          </TextSmall>
          <AccountInstallationButton onClick={onAccountInstallationClick}>
            <InstallationIcon fill={theme.colors.white} />
            Account Installation
          </AccountInstallationButton>
        </Content>
      </BasicAccordionV2>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)``;

const Content = styled(Gap16VerticalFlex)``;

const HeaderTitle = styled(TextH6)`
  padding: 0 8px;
`;

const AccountInstallationButton = styled(PrimaryButton)`
  width: 180px;

  & > svg {
    width: 16px;
    height: 16px;
  }
`;

const TextSmallGrey = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.neutralGray};
`;

export default AccountInstallationSection;
