import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Utils from 'shared/react/utils/utils';
import { assetType } from 'app/src/types/entities';
import ImageIcon from 'shared/react/images/ImageIcon';
import { TextXTiny } from 'shared/react/components/basic/text/TextV2';
import { VIDEO_STATUS } from 'shared/react/constants/video.constants';
import { ContextMenuIcon } from 'app/src/pages/dashboard/components/card/Card';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import ThreeVerticalDotsIcon from 'app/src/images/dashboard_v2/ThreeVerticalDotsIcon';
import AssetRenditionsService from 'shared/react/services/assets/AssetRenditionsService';
import { VideosPageVodAsset } from 'app/src/pages/dashboard/pages/videos/types/videos.types';
import VideoCardMenuItems from 'app/src/pages/dashboard/pages/videos/components/video_card/VideoCardMenuItems';

type Props = {
  hashtag: string;
  asset: VideosPageVodAsset;
  isPending: boolean;
  isApproved: boolean;
};

export const UgcMediaPreview = ({ hashtag, asset, isPending, isApproved }: Props) => {
  const [isMenuOpen, setMenuOpen] = useState(null);
  const [duration, setDuration] = useState(0);
  const isImportedAsset = asset.status === VIDEO_STATUS.done;

  const getMediaSideItem = () => {
    if (asset.type === assetType.image) {
      return <ImageIcon />;
    }

    return Utils.secondsToTimeString(duration, false);
  };

  const onVideoLoad = event => {
    setDuration(Math.round(event.target.duration));
  };

  const elementRef = useRef();

  const getPosterUrl = () => {
    return isImportedAsset
      ? AssetRenditionsService.getAssetPosterLarge(asset)
      : asset?.stockAsset?.posterUrl;
  };

  const getMediaElement = () => {
    if ([assetType.image, assetType.gallery].includes(asset.type) || isImportedAsset) {
      return <Image ref={elementRef} src={getPosterUrl() || asset?.stockAsset?.posterUrl} />;
    }

    return <Video onLoadedMetadata={onVideoLoad} src={asset?.stockAsset?.videoUrl} />;
  };

  const onOpenMenu = event => {
    event.stopPropagation();
    setMenuOpen(event.currentTarget);
  };

  return (
    <LayoutRoot>
      {getMediaElement()}
      <MediaTypeContainer>
        <Hashtag>{getMediaSideItem()}</Hashtag>
      </MediaTypeContainer>
      <HashtagContainer>
        <Hashtag>{hashtag}</Hashtag>
      </HashtagContainer>
      {(isPending || isApproved) && (
        <>
          <MenuContainer onClick={onOpenMenu}>
            <ThreeVerticalDotsIcon />
          </MenuContainer>
          {/*
            TODO: This component gets 'isPending' and 'isApproved' only to hide most and show some actions for UGC
            1. move all actions in this component to a hook
            2. create a custom MenuItems for UGC (combine hook and MenuItems component)
          */}
          <VideoCardMenuItems
            video={asset}
            isMenuOpen={isMenuOpen}
            setMenuOpen={setMenuOpen}
            method={undefined}
            onSelectedVideosAction={undefined}
            isPending={isPending}
            isApproved={isApproved}
          />
        </>
      )}
    </LayoutRoot>
  );
};

const MenuContainer = styled(ContextMenuIcon)`
  position: absolute;
  right: 8px;
  top: 8px;
  display: none;
`;

const LayoutRoot = styled(VerticalFlex)`
  position: relative;

  &:hover ${MenuContainer} {
    display: block;
  }
`;

const Image = styled.img`
  height: 130px;
  width: 100%;
  object-fit: contain;
  background: ${({ theme }) => theme.colors.black};
  border-radius: 4px 4px 0 0;
`;

const Video = styled.video`
  height: 130px;
  width: 100%;
  object-fit: contain;
  background: ${({ theme }) => theme.colors.black};
`;

const HashtagContainer = styled(VerticalFlex)`
  position: absolute;
  border-radius: 4px;
  background: ${({ theme }) => theme.getHexOpacity(theme.colors.black, 50)};
  bottom: 8px;
  left: 8px;
  gap: 4px;
  padding: 2px 4px;
`;

const MediaTypeContainer = styled(HashtagContainer)`
  right: 8px;
  left: unset;
`;

const Hashtag = styled(TextXTiny)`
  color: ${({ theme }) => theme.colors.white};
`;

export default UgcMediaPreview;
