import styled from 'styled-components';

const Line = styled.div`
  border-radius: 4px;
  width: 100%;
  height: 5px;
`;

export const GrayLine = styled(Line)`
  background: ${({ theme }) => theme.colors.gray5};
`;

export const DarkerGrayLine = styled(Line)`
  background: ${({ theme }) => theme.colors.ghostLight};
`;

export const SmallSphere = styled(DarkerGrayLine)`
  width: 22px;
  height: 15px;
  border-radius: 30px;
`;

export const PreviewBox = styled(GrayLine)`
  width: 100%;
  height: 136px;
`;
