import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import BasicButton from 'shared/react/components/basic/button/button/BasicButton';
import { uploadLogoFile } from 'app/src/helpers/Image';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { useUser } from 'app/src/context/userStore/UserStore';
import { GhostButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { TextSmall, TextTiny } from 'shared/react/components/basic/text/TextV2';
import EditorLogoPreview from 'app/src/pages/account/branding/editors/general/EditorLogoPreview';

const UploadLogo = ({
  imageSrc,
  uploading,
  onClear,
  onUpdate,
  setLogo,
  clearErrors,
  type,
  setUploading,
  loading,
  disabled,
  shouldHide,
  name,
  showButtonWithoutBackground,
}) => {
  const [{ account }] = useUser();
  const [errorMessage, setErrorMessage] = useState('');
  const [localImage, setLocalImage] = useState(imageSrc);
  const logoInputRef = useRef();
  const Button = showButtonWithoutBackground ? LinkButton : LightButton;
  const text = imageSrc ? 'Clear' : 'Upload';
  const isDisabled = disabled || loading || uploading;

  const onUploadImage = async e => {
    const target = e.currentTarget;
    const file = target.files[0];

    setUploading(true);
    const logoUrl = await uploadLogoFile(file, type, setErrorMessage, account.appKey);
    setUploading(false);

    if (logoUrl) {
      setLocalImage(URL.createObjectURL(file));
      setLogo(logoUrl);
      onUpdate?.();
    } else {
      target.value = '';
    }
  };

  const onButtonClick = () => {
    if (disabled) {
      return;
    }

    setErrorMessage('');
    if (imageSrc) {
      setLocalImage(null);
      onClear(setLogo);
      return;
    }
    logoInputRef.current.click();
  };

  useEffect(() => {
    if (clearErrors) {
      setErrorMessage('');
    }
  }, [clearErrors]);

  useEffect(() => {
    if (imageSrc && !localImage) {
      setLocalImage(imageSrc);
    }
  }, [imageSrc, localImage]);

  if (shouldHide) {
    return null;
  }

  return (
    <LayoutRoot disabled={disabled}>
      <LogoNameAndUpload>
        <HeaderText>{name}</HeaderText>

        <Button disabled={isDisabled} onClick={onButtonClick}>
          {text}
        </Button>
      </LogoNameAndUpload>
      <EditorLogoPreview
        userImageSrc={localImage}
        loading={uploading}
        errorMessage={errorMessage}
      />

      <TextContainer>
        <SubtitleText errorMessage={errorMessage} />
      </TextContainer>
      <Input ref={logoInputRef} type="file" accept=".png" onChange={onUploadImage} />
    </LayoutRoot>
  );
};

const SubtitleText = ({ errorMessage }) => {
  if (errorMessage) {
    return <ErrorText>{errorMessage}</ErrorText>;
  }

  return null;
};

const LayoutRoot = styled(VerticalFlex)`
  gap: 16px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : '')};
`;

const Input = styled.input`
  display: none;
`;

const ErrorText = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.error};
`;

const TextContainer = styled(VerticalFlex)`
  gap: 4px;
`;

const LogoNameAndUpload = styled(HorizontalFlex)`
  justify-content: space-between;
  align-items: center;
`;

const LightButton = styled(BasicButton)`
  background: ${({ theme }) => theme.colors.primaryLight};
  color: ${({ theme }) => theme.colors.primary};

  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const LinkButton = styled(GhostButton)`
  border: none;
  color: ${({ theme }) => theme.colors.primary};
  padding: 6px 10px;

  :hover {
    border: none;
    color: ${({ theme }) => theme.colors.primaryHover};
  }
`;

const HeaderText = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.neutralDarker};
`;

export default UploadLogo;
