import React from 'react';

const XWithCircles = props => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.625 5.656a.53.53 0 0 1 .719 0L8 7.313l1.625-1.657a.53.53 0 0 1 .719 0 .53.53 0 0 1 0 .719L8.688 8l1.656 1.656a.53.53 0 0 1 0 .719.53.53 0 0 1-.719 0L8 8.719l-1.656 1.656a.53.53 0 0 1-.719 0 .53.53 0 0 1 0-.719L7.281 8 5.625 6.375a.53.53 0 0 1 0-.719M16 8c0 4.438-3.594 8-8 8-4.437 0-8-3.562-8-8 0-4.406 3.563-8 8-8 4.406 0 8 3.594 8 8M8 1C4.125 1 1 4.156 1 8c0 3.875 3.125 7 7 7 3.844 0 7-3.125 7-7 0-3.844-3.156-7-7-7"
        fill="#B3B6BC"
      />
    </svg>
  );
};

export default XWithCircles;
