import IntegrationSetLive from 'app/src/pages/modals/publish/widget/IntegrationSetLive';
import WidgetStepper from 'app/src/pages/modals/publish/widget/WidgetStepper';
import React from 'react';
import BlockFreeUserPublishMessage from 'app/src/pages/modals/publish/BlockFreeUserPublishMessage';
import { useBilling } from 'app/src/hooks/useBillings';

const LeftContainerContent = ({
  isStoreApp,
  isIntegrationApiSetWidget,
  showButtons,
  loading,
  live,
  onSetLive,
  handlePublishAppChange,
  appActive,
  appUrl,
  project,
}) => {
  const { hidePublishContent } = useBilling();

  if (hidePublishContent) {
    return <BlockFreeUserPublishMessage type={'Widget'} />;
  }

  if (isStoreApp) {
    return (
      <IntegrationSetLive
        showSetLive={showButtons}
        isIntegrationApiSetWidget={isIntegrationApiSetWidget}
        loading={loading}
        live={live}
        onSetLive={onSetLive}
        handlePublishAppChange={handlePublishAppChange}
        appActive={appActive}
        appUrl={appUrl}
      />
    );
  }

  return <WidgetStepper loading={loading} live={live} onSetLive={onSetLive} project={project} />;
};

export default LeftContainerContent;
