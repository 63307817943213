import React from 'react';

const OutreachIcon = props => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.673.691c3.676 0 9.23 1.132 11.815 3.728C23.143 5.077 24 6.366 24 8.254c0 3.356-2.694 8.506-5.668 11.493-1.537 1.544-3.983 3.562-7.273 3.562-3.216 0-5.673-2.193-6.384-2.908C1.951 17.666 0 13.514 0 9.643 0 7.2.693 5.064 2.463 3.288 4.1 1.643 6.925.691 10.673.691Zm4.55 13.386c1.24-1.246 1.575-2.782 1.575-3.627 0-.612-.16-1.51-.924-2.276-1.17-1.176-2.466-1.507-3.876-1.507-1.787 0-2.987.566-3.786 1.37-.627.629-1.169 1.597-1.169 2.971s.73 2.702 1.525 3.5c.734.737 1.889 1.283 3.051 1.283 1.163 0 2.363-.467 3.604-1.714Z"
      fill="#5952FF"
    />
  </svg>
);

export default OutreachIcon;
