import React from 'react';
import styled from 'styled-components';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import TextTiny from 'shared/react/components/basic/Text';
import UpgradeButton from '../UpgradeButton';
import ProIcon from 'app/src/images/ProIcon';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const PricingBadge = ({
  text,
  subtitle,
  analyticsData,
  shouldShowProIcon,
  borderRadius,
  padding,
  onButtonClick,
  ...props
}) => {
  return (
    <LayoutRoot borderRadius={borderRadius} padding={padding} {...props}>
      <LeftContainer isSubtitle={!!subtitle}>
        <HeaderContainer>
          {shouldShowProIcon && (
            <ProBadgeContainer>
              <ProIcon />
            </ProBadgeContainer>
          )}
          <Header>{text}</Header>
        </HeaderContainer>
        <Subtitle>{subtitle}</Subtitle>
      </LeftContainer>
      <UpgradeButton onButtonClick={onButtonClick} analyticsData={analyticsData} />
    </LayoutRoot>
  );
};

export default PricingBadge;

const LayoutRoot = styled(HorizontalFlexWrap)`
  border-radius: ${({ borderRadius }) => borderRadius || '58px'};
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.gray2};
  padding: ${({ padding }) => padding};
  align-items: center;
  box-shadow: 0px 3px 15px ${({ theme }) => theme.colors.blackBoxShadow};
  flex-wrap: nowrap;
  gap: 8px;
`;

const Header = styled(TextTiny)`
  font-weight: 800;
  white-space: nowrap;
  max-width: 56px;
  color: ${({ theme }) => theme.colors.white};
  line-height: 13px;
`;

const LeftContainer = styled(VerticalFlex)`
  gap: ${({ isSubtitle }) => (isSubtitle ? '4px' : '')};
`;

const HeaderContainer = styled(HorizontalFlexWrap)`
  gap: 8px;
`;

const Subtitle = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.white};
`;

const ProBadgeContainer = styled.div`
  display: flex;
`;
