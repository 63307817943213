import React from 'react';
import styled from 'styled-components';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import { project as Project } from 'src/types/entities';
import AppEmbedImage from './images/app-embed.png';
import TvButtonImage from './images/tv-button.png';
import { VerticalFlexCentered } from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { LIQUID_FILENAMES } from 'shared/react/constants/tolstoy.constants';
import Utils from 'src/utils';
import { PrimaryButton } from 'shared/react/components/basic/button-v2/BasicButton';
import ShopifyLogoIcon from 'src/images/creation_method_icons/ShopifyLogoIcon';
import StraightArrowRightIcon from 'src/images/creation_method_icons/StraightArrowRightIcon';

type Props = {
  project: Project;
};

const TvChannelInstallation = ({ project }: Props) => {
  const handleOpenDeepLink = () => {
    const liquidFilename = LIQUID_FILENAMES.tvChannelButton;
    const shopifyDomain = project.appUrl;
    const deepLink = Utils.getAppEmbedDeepLink(shopifyDomain, liquidFilename);

    Utils.openInNewTab(deepLink);
  };

  return (
    <LayoutRoot>
      <div>Click below to enable your Discover feed on your Shopify theme.</div>
      <DeepLinkButton onClick={handleOpenDeepLink}>
        <ShopifyLogoIcon />
        Go To Shopify
        <StraightArrowRightIcon />
      </DeepLinkButton>
      <ImageContainer>
        <ImagePreview src={AppEmbedImage} />
      </ImageContainer>
      <div>
        Once enabled, a link to your Discover feed will appear on your homepage. It will look like
        this:
      </div>
      <ImageContainer>
        <ImagePreview src={TvButtonImage} />
      </ImageContainer>
    </LayoutRoot>
  );
};

const ImageContainer = styled(VerticalFlexCentered)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.neutralLightest};
  padding: 16px;
  border-radius: 4px;
`;

const DeepLinkButton = styled(PrimaryButton)`
  margin-right: auto;

  & svg {
    width: 14px;
    height: 14px;
  }

  & path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const LayoutRoot = styled(Gap16VerticalFlex)``;

const ImagePreview = styled.img`
  width: 300px;
`;

export default TvChannelInstallation;
