import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CarouselSlider, {
  CarouselSliderItemsContainer,
} from 'shared/react/components/complex/carousel_slider/CarouselSlider';
import ImageWithLoader from 'shared/react/components/complex/ImageWithLoader';
import useTracker from 'shared/react/components/complex/context/hooks/useTracker';
import Utils from 'shared/react/utils/utils';
import { PLAYER_TYPES } from 'shared/react/constants/tolstoy.constants';
import { getVariantsImagesSourcesMap, getProductImages } from '../feed_utils/feed.utils';
import { useFeedIsMobile } from 'shared/react/hooks/useIsMobile';
import FeedProductImagesModal from './FeedProductImagesModal';
import useFeedState from '../../context/hooks/useFeedState';

const FeedProductImagesCarousel = ({ product, selectedVariant }) => {
  const [, { track }] = useTracker();
  const isMobile = useFeedIsMobile();
  const [carouselScrollToIndex, setCarouselScrollToIndex] = useState(0);
  const [imagesSources, setImagesSources] = useState([]);
  const [imageIndexMap, setImageIndexMap] = useState({});
  const [modalImageSrc, setModalImageSrc] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVariantImagesExclusively, setIsVariantImagesExclusively] = useState(false);
  const [{ isPlayerPreview }] = useFeedState();

  const handleImageModalOpen = src => {
    if (!src || isPlayerPreview) {
      return;
    }

    setModalImageSrc(src);
    setIsModalOpen(true);
  };
  const handleImageModalClose = () => {
    setModalImageSrc(null);
    setIsModalOpen(false);
  };

  const handleRenderImage = src => {
    const onClick = () => handleImageModalOpen(src);

    return <StyledImageWithLoader src={src} onClick={onClick} />;
  };

  const handleArrowClick = direction => {
    track('imageCarouselArrowClick', {
      ...Utils.getAnalyticsLocationObject(PLAYER_TYPES.feed),
      direction,
    });
  };

  useEffect(() => {
    const variantImageIndex = imageIndexMap[selectedVariant?.id];
    if (Utils.isNullOrUndefined(variantImageIndex) || isVariantImagesExclusively) {
      setCarouselScrollToIndex(0);
      return;
    }

    setCarouselScrollToIndex(variantImageIndex);
  }, [selectedVariant?.id, imageIndexMap, isVariantImagesExclusively]);

  useEffect(() => {
    const { images, isVariantImagesExclusively: isVariantImages } = getProductImages({
      product,
      selectedVariant,
    });

    setIsVariantImagesExclusively(isVariantImages);

    const srcMap = getVariantsImagesSourcesMap({ images, variants: product?.variants });

    const imgSources = images.map(({ src }) => Utils.getShopifyImageAndSetHeight(src, 330));

    setImageIndexMap(srcMap);

    setImagesSources(imgSources);
  }, [product, selectedVariant]);

  return (
    <LayoutRoot>
      <StyledCarousel
        items={imagesSources}
        carouselScrollToIndex={carouselScrollToIndex}
        onArrowClick={handleArrowClick}
        renderItem={handleRenderImage}
        isMobile={isMobile}
      />
      <FeedProductImagesModal
        imageSrc={modalImageSrc}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        onClose={handleImageModalClose}
        isMobile={isMobile}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div``;

const StyledCarousel = styled(CarouselSlider)`
  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    width: 100vw;
  }

  ${CarouselSliderItemsContainer} {
    padding: 0;
    margin: 0 4px;
    width: auto;
  }

  direction: ltr;
`;

const StyledImageWithLoader = styled(ImageWithLoader)`
  width: 100%;
  height: 100%;
  transition: 0.3s;
  cursor: zoom-in;

  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 1;
    border-radius: 0;
  }
`;

export default FeedProductImagesCarousel;
