import React from 'react';

const DisconnectIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5 0c-.281 0-.5.25-.5.5 0 .281.219.5.5.5h3.781L6.625 8.656a.53.53 0 0 0 0 .719.53.53 0 0 0 .719 0L15 1.719V5.5c0 .281.219.5.5.5.25 0 .5-.219.5-.5v-5c0-.25-.25-.5-.5-.5h-5ZM2 2C.875 2 0 2.906 0 4v10c0 1.125.875 2 2 2h10c1.094 0 2-.875 2-2V9.5c0-.25-.25-.5-.5-.5-.281 0-.5.25-.5.5V14c0 .563-.469 1-1 1H2c-.563 0-1-.438-1-1V4c0-.531.438-1 1-1h4.5c.25 0 .5-.219.5-.5 0-.25-.25-.5-.5-.5H2Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default DisconnectIcon;
