import { INSTAGRAM_GRAPH, TIKTOK } from 'app/src/constants/intergrations.constants';
import { RECORDINGS, SCREEN_RECORDING, UPLOAD } from 'app/src/constants/recordingTypes.constants';

export const SOURCES_NAME_BY_KEY = {
  [TIKTOK]: 'TikTok',
  [INSTAGRAM_GRAPH]: 'Instagram',
  [UPLOAD]: 'uploaded',
  [RECORDINGS]: 'recorded',
  [SCREEN_RECORDING]: 'recorded',
};
