import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TextSmall } from 'shared/react/components/complex/Text';
import ConversationBoxHeaderIcons from 'app/src/pages/project/pages/inbox/ConversationBoxHeaderIcons';
import { getSessionDateOrTime } from 'app/src/context/SessionsStore';
import Utils from 'app/src/utils';

const ConversationBox = ({
  id,
  read,
  archived,
  isSpam,
  email,
  name,
  phone,
  lastEventAt,
  totalEvents,
  sessionTime,
  selected,
  onClick,
  updateConversation,
  isToday,
}) => {
  const title = email || name || phone || 'Anonymous';
  totalEvents = totalEvents || 0;
  sessionTime = sessionTime || 0;
  lastEventAt = lastEventAt || Date.now();
  const clicksText = `Clicks: ${totalEvents}  Duration: ${sessionTime} sec`;
  const conversationDateString = getSessionDateOrTime(lastEventAt, isToday);
  const [currentRead, setCurrentRead] = useState(read);

  useEffect(() => {
    if (selected && !Utils.isGhostAccount()) {
      onActionClick({ read: true });
    }
  }, [selected]);

  const onConversationBoxClick = () => {
    if (!selected) {
      onClick();
    }
  };

  const onReadChange = newRead => {
    setCurrentRead(newRead);
  };

  const onActionClick = properties => {
    if (!properties.hasOwnProperty('read')) {
      updateConversation(id, properties);

      return;
    }

    if (properties.read === currentRead) {
      return;
    }

    onReadChange(properties.read);
    updateConversation(id, properties);
  };

  return (
    <StyledConversationBox read={currentRead} onClick={onConversationBoxClick} selected={selected}>
      <ConversationBoxHeader>
        <ConversationBoxHeaderText read={currentRead} selected={selected} title={title}>
          {title}
        </ConversationBoxHeaderText>
        <ConversationBoxHeaderIcons
          id={id}
          read={currentRead}
          isSpam={isSpam}
          archived={archived}
          onActionClick={onActionClick}
          onReadChange={onReadChange}
        />
      </ConversationBoxHeader>
      <ConversationDate read={currentRead} selected={selected}>
        {conversationDateString}
      </ConversationDate>
      <ConversationClicks title={clicksText}>{clicksText}</ConversationClicks>
    </StyledConversationBox>
  );
};

export default ConversationBox;

const StyledConversationBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  background: ${({ read, selected, theme }) =>
    selected ? theme.colors.primaryLight : read ? theme.colors.gray29 : theme.colors.white};
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.gray29};
  padding: 10px 16px 6px;
  cursor: ${({ selected }) => (selected ? 'default' : 'pointer')};
`;

const ConversationBoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ConversationBoxHeaderText = styled(TextSmall)`
  color: ${({ selected, read }) => (selected ? '#2A3CEF' : read ? '#7D8087' : 'black')};
  font-weight: ${({ read }) => (read ? 600 : 700)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 4px;
`;

const ConversationDate = styled(TextSmall)`
  color: ${({ read, selected }) => (read || selected ? '#7D8087' : 'black')};
  font-weight: ${({ read, selected }) => (!read && !selected ? 'bold' : 400)};
`;

const ConversationClicks = styled(TextSmall)`
  color: #7d8087;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
