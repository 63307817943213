import React from 'react';

const Replied = ({ color }) => {
  return (
    <svg width="20" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 1H3C1.875 1 1 1.90625 1 3V13C1 14.125 1.875 15 3 15H13C14.0938 15 15 14.125 15 13V3C15 1.90625 14.0938 1 13 1ZM13.5 13C13.5 13.2812 13.25 13.5 13 13.5H3C2.71875 13.5 2.5 13.2812 2.5 13V3C2.5 2.75 2.71875 2.5 3 2.5H13C13.25 2.5 13.5 2.75 13.5 3V13ZM10.4688 5.46875L7 8.96875L5.5 7.46875C5.1875 7.1875 4.71875 7.1875 4.4375 7.46875C4.125 7.78125 4.125 8.25 4.4375 8.53125L6.4375 10.5312C6.59375 10.6875 6.78125 10.75 7 10.75C7.1875 10.75 7.375 10.6875 7.5 10.5312L11.5 6.53125C11.8125 6.25 11.8125 5.78125 11.5 5.5C11.2188 5.1875 10.75 5.1875 10.4688 5.46875Z"
        fill={color}
      />
    </svg>
  );
};

export default Replied;
