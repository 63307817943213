import React from 'react';
import styled from 'styled-components';
import useNavigation from 'app/src/hooks/useNavigation';
import { Link } from '@material-ui/core';
import { project as Project } from 'app/src/types/entities';
import { projectTabs } from 'app/src/helpers/Routes';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';

type Props = {
  project: Project;
  sourceProject: Project | null;
} & React.HTMLAttributes<HTMLDivElement>;

const SourceProjectNotice = ({ project, sourceProject, ...props }: Props) => {
  const navigation = useNavigation();

  if (!sourceProject) {
    return null;
  }

  const handleSourceProjectClick = e => {
    e.preventDefault();
    navigation.navigateToProjectVideos(sourceProject.id);
  };

  const handleSettingsClick = e => {
    e.preventDefault();
    navigation.navigateToProjectTab(project, projectTabs.settings);
  };

  return (
    <LayoutRoot {...props}>
      <Text>Showing videos from&nbsp;</Text>
      <StyledLink onClick={handleSourceProjectClick}>{sourceProject.name}.</StyledLink>
      <Text>&nbsp;Change source in&nbsp;</Text>
      <StyledLink onClick={handleSettingsClick}>settings.</StyledLink>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  padding: 8px 0;
`;

const Text = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralDark};
  display: inline;
`;

const StyledLink = styled(Link)`
  cursor: pointer;
  display: inline;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
`;

export default SourceProjectNotice;
