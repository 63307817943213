import React from 'react';
import SearchProductsContainer from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/products/SearchProductsContainer';
import { useParams } from 'react-router-dom';
import useQuery from 'app/src/hooks/useQuery';

const SearchProductsWrapper = ({ ...props }) => {
  const { projectId, stepId } = useParams();
  const publish = useQuery().get('publish');

  const analyticsData = {
    projectId: projectId || props.projectId,
    publish,
    stepId,
  };

  return <SearchProductsContainer analyticsData={analyticsData} {...props} />;
};

export default SearchProductsWrapper;
