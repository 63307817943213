import React from 'react';
import styled from 'styled-components';
import EditorGroup from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorGroup';
import {
  FEED_PRODUCT_CARDS_KEY,
  FONT_KEY,
  IN_FEED_PDP_KEY,
  LOGO_KEY,
} from '../../branding.constants';
import { FeedDesign } from 'app/src/types/design';
import FeedProductCardsEditor from 'app/src/pages/account/branding/editors/feed/FeedProductCardsEditor';
import FeedInFeedPDP from 'app/src/pages/account/branding/editors/feed/FeedInFeedPDP';

type Props = {
  settings: FeedDesign;
  features: any;
  defaultSettings: FeedDesign;
  onChange: (settings: FeedDesign, options: any) => void;
};

const FeedEditorGroup = ({ settings = {}, onChange = () => {}, fonts }: Props) => {
  const updateSettings = (update, options) => onChange({ ...settings, ...update }, options);

  return (
    <StyledEditorGroup
      editors={[
        {
          key: FEED_PRODUCT_CARDS_KEY,
          component: (
            <FeedProductCardsEditor
              settings={settings.feedProductCards}
              fonts={fonts}
              onChange={(settings, options) =>
                updateSettings({ feedProductCards: settings }, options)
              }
            />
          ),
        },
        {
          key: IN_FEED_PDP_KEY,
          component: (
            <FeedInFeedPDP
              settings={settings.inFeedPDP}
              onChange={(settings, options) => updateSettings({ inFeedPDP: settings }, options)}
              fonts={fonts}
            />
          ),
        },
      ]}
    />
  );
};

const StyledEditorGroup = styled(EditorGroup)`
  padding: 0;

  & > * {
    padding: 0;
  }
`;

export default FeedEditorGroup;
