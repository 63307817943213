import React from 'react';
import styled from 'styled-components';
import OverlaySpinner from 'app/src/basic_components/OverlaySpinner';
import OffsiteCard from 'app/src/pages/dashboard/pages/offsite/components/offsite_card/OffsiteCard';
import { useDashboard } from 'app/src/context/ui_store/DashboardStore';

const project = {
  id: 'loading-state',
  updatedAt: new Date(),
  name: 'Creating new Tolstoy...',
};

const OffsiteDuplicateCard = () => {
  const [{ isCreatingDuplication }] = useDashboard();

  if (!isCreatingDuplication) {
    return null;
  }

  return (
    <ComponentWithOverlayLoader size={30} isLoading={true}>
      <OffsiteCard
        project={project}
        toggleSelected={() => {}}
        isSelected={false}
        isSelectionMode={false}
      />
    </ComponentWithOverlayLoader>
  );
};

const ComponentWithOverlayLoader = styled(OverlaySpinner)`
  pointer-events: none;
`;

export default OffsiteDuplicateCard;
