import React from 'react';
import styled, { useTheme } from 'styled-components';
import HorizontalFlexWrap from '../flex_layouts/HorizontalFlexWrap';
import VideoIcon from '../../../images/VideoIcon';
import ScreenRecordingIcon from '../../../images/ScreenRecordingIcon';
import MacroIcon from '../../../images/MacroIcon';
import RightArrow from '../../../images/RightArrow';
import ProductIcon from '../../../images/ProductIcon';
import { REPLY_MACRO_DATA_TEST_ID } from '../../../constants/dataTestIds.constants';
import AudioIcon from '../../../images/AudioIcon';

const ReplyBarButtons = ({
  multiLine,
  onVideoReply,
  onProductReply,
  onMacroReply,
  onScreenRecording,
  onClear,
  onAudioReply,
}) => {
  const theme = useTheme();

  if (multiLine) {
    return (
      <ButtonWithBackground onClick={onClear}>
        <RightArrow />
      </ButtonWithBackground>
    );
  }

  return (
    <StyledReplyBarButtons>
      {onVideoReply && (
        <ReplyBarIconButton onClick={onVideoReply}>
          <VideoIcon />
        </ReplyBarIconButton>
      )}
      {onProductReply && (
        <ButtonWithBackground onClick={onProductReply}>
          <ProductIcon />
        </ButtonWithBackground>
      )}
      {onScreenRecording && (
        <ReplyBarIconButton onClick={onScreenRecording}>
          <ScreenRecordingIcon />
        </ReplyBarIconButton>
      )}
      {onMacroReply && (
        <ReplyBarIconButton onClick={onMacroReply} data-test-id={REPLY_MACRO_DATA_TEST_ID}>
          <MacroIcon />
        </ReplyBarIconButton>
      )}
      {onAudioReply && (
        <ReplyBarIconButton onClick={onAudioReply}>
          <AudioIcon
            width={32}
            height={32}
            iconFill={theme.colors.gray2}
            circleFill={theme.colors.gray5}
          />
        </ReplyBarIconButton>
      )}
    </StyledReplyBarButtons>
  );
};

export default ReplyBarButtons;

const StyledReplyBarButtons = styled(HorizontalFlexWrap)`
  align-items: center;
  gap: 8px;
  flex-shrink: 0;

  & > * {
    cursor: pointer;
  }
`;

const ReplyBarIconButton = styled.button`
  display: grid;
  place-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0;
  transition: all 0.3s;
  transform-origin: 50% 50%;

  &:hover {
    transform: scale(1.2);
  }
`;

const ButtonWithBackground = styled(ReplyBarIconButton)`
  flex-shrink: 0;
  border-radius: 50px;
  width: 32px;
  height: 32px;
  background-color: ${({ theme }) => theme.colors.gray5};
`;
