import React from 'react';
import styled from 'styled-components';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import PreviewMobileNotch from 'app/src/pages/modals/publish/previews/static_preview_items/PreviewMobileNotch';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import {
  GrayLine,
  PreviewBox,
  SmallSphere,
} from 'app/src/pages/modals/publish/previews/static_preview_items/commonPreviewItems';

const StoriesStaticPreview = ({ children }) => {
  return (
    <LayoutRoot>
      <TopContainer>
        <PreviewMobileNotch />
        <PreviewBox />
      </TopContainer>
      <BottomContainer>
        <StaticItemsContainer>
          <SmallSphere />
          <MediumGrayLine />
        </StaticItemsContainer>
        {children}
        <LineAndLargeSphere />
        <LineAndLargeSphere />
      </BottomContainer>
    </LayoutRoot>
  );
};

const LineAndLargeSphere = () => {
  return (
    <StaticItemsContainer>
      <ShortGrayLine />
      <LargeSphere />
    </StaticItemsContainer>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)`
  padding: 10px 12px;
  background: ${({ theme }) => theme.colors.white};
  max-width: 160px;
  width: 100%;
  border-radius: 24px;
`;

const TopContainer = styled(VerticalFlex)`
  gap: 10px;
`;

const BottomContainer = styled(Gap8VerticalFlex)``;

const StaticItemsContainer = styled(VerticalFlex)`
  gap: 4px;
`;

const MediumGrayLine = styled(GrayLine)`
  width: 72px;
`;

const ShortGrayLine = styled(GrayLine)`
  width: 30px;
`;

const LargeSphere = styled(GrayLine)`
  border-radius: 8px;
  height: 13px;
`;

export default StoriesStaticPreview;
