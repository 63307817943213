import React from 'react';

const IntegrationsOutlinedIcon = () => {
  return (
    <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.25.5c.313 0 .625.313.625.625v3.75h-1.25v-3.75c0-.313.273-.625.625-.625Zm7.5 0c.313 0 .625.313.625.625v3.75h-1.25v-3.75c0-.313.273-.625.625-.625Zm3.125 5.625c.313 0 .625.313.625.625a.642.642 0 0 1-.625.625h-.625v2.5c0 3.242-2.5 5.938-5.625 6.25v3.75A.642.642 0 0 1 8 20.5a.617.617 0 0 1-.625-.625v-3.75c-3.164-.313-5.625-3.008-5.625-6.25v-2.5h-.625A.617.617 0 0 1 .5 6.75c0-.313.273-.625.625-.625h13.75ZM8 14.875c2.734 0 5-2.227 5-5v-2.5H3v2.5c0 2.773 2.227 5 5 5Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default IntegrationsOutlinedIcon;
