import React, { FC } from 'react';
import styled from 'styled-components';
import { VerticalFlexCentered } from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import { project as Project, publishMethodOptions } from 'app/src/types/entities';
import StoriesEmbedInstallationSrc from 'app/src/images/StoriesEmbedInstallation.png';
import CarouselEmbedInstallationSrc from 'app/src/images/CarouselEmbedInstallation.png';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextBody, TextTiny } from 'shared/react/components/basic/text/TextV2';

type AddBlockManuallyStepsProps = {
  project: Project;
};

const AddBlockManuallySteps: FC<AddBlockManuallyStepsProps> = ({ project }) => {
  const { publishMethod } = project;
  const stepImage =
    publishMethod === publishMethodOptions.stories
      ? StoriesEmbedInstallationSrc
      : CarouselEmbedInstallationSrc;

  return (
    <Gap16VerticalFlex>
      <SubStepTitle>
        <SubStepId>Step A</SubStepId>
        <SubStepName>
          Go to Shopify themes → Customize theme → Page template → Add section or block → Tolstoy{' '}
          {publishMethod}
        </SubStepName>
      </SubStepTitle>
      <ImageContainer>
        <StepAImg src={stepImage} />
      </ImageContainer>
    </Gap16VerticalFlex>
  );
};

const StepAImg = styled.img`
  max-width: 100%;
  width: 480px;
`;

const ImageContainer = styled(VerticalFlexCentered)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.neutralLightest};
  padding: 16px;
  border-radius: 4px;
`;

const SubStepTitle = styled(HorizontalFlex)`
  align-items: center;
`;

const SubStepId = styled(TextBody)`
  padding-right: 8px;
  margin-right: 8px;
  border-right: 1px solid ${({ theme }) => theme.colors.neutralLight};
  color: ${({ theme }) => theme.colors.neutralDarker};
`;

const SubStepName = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralDark};
`;

export default AddBlockManuallySteps;
