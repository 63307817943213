import React from 'react';
import { OrangeButton, PrimaryButton } from 'shared/react/components/basic/button-v2/BasicButton';
import styled from 'styled-components';

type Props = {
  isLoading: boolean;
  isLive: boolean;
  onSetLive: () => void;
  onSetUnLive: () => void;
  disabled?: boolean;
};

const SetLiveButton = ({ isLoading, isLive, onSetLive, onSetUnLive, disabled }: Props) => {
  if (!isLive) {
    return (
      <SuccessButtonStyled onClick={onSetLive} disabled={disabled} isLoading={isLoading}>
        Publish
      </SuccessButtonStyled>
    );
  }

  return (
    <OrangeButtonStyled onClick={onSetUnLive} isLoading={isLoading}>
      Pause
    </OrangeButtonStyled>
  );
};

const SuccessButtonStyled = styled(PrimaryButton)`
  width: 100px;
`;

const OrangeButtonStyled = styled(OrangeButton)`
  width: 100px;
`;

export default SetLiveButton;
