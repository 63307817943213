import styled from 'styled-components';
import ImageWithFallback from 'shared/react/images/ImageWithFallback';

export const SmallProductImage = styled(ImageWithFallback)`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  object-fit: cover;
  border: 1px solid ${({ theme }) => theme.colors.neutralGray};
`;
