import React from 'react';
import {
  COMPANY_SIZE_OPTIONS,
  COMPANY_USE_CASE_OPTIONS,
  COMPANY_VERTICAL_OPTIONS,
} from 'app/src/constants/accountSettings.constants';
import styled, { useTheme } from 'styled-components';
import BasicInput from 'app/src/basic_components/BasicInput';
import BasicSelect from 'shared/react/components/basic/basic_select/BasicSelect';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

import { TextH5, TextSubtitle } from 'shared/react/components/complex/Text';
import InfoIconWithTooltip from 'app/src/complex_components/InfoIconWithTooltip';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';

const CompanyInformation = ({
  accountName,
  accountUrl,
  numberOfEmployees,
  companyVertical,
  companyUseCase,
  updateNumberOfEmployees,
  updateCompanyVertical,
  updateAccountName,
  updateAccountUrl,
  updateCompanyUseCase,
  loading,
  onUpdate,
}) => {
  const theme = useTheme();
  const form = [
    {
      title: 'Company name',
      input: 'input',
      tooltipText:
        "Your company's name will be displayed on all email notifications to your respondents.",
      inputPlaceholder: 'Name',
      value: accountName,
      onChange: updateAccountName,
    },
    {
      title: 'Company URL',
      input: 'input',
      tooltipText:
        "Your company's website will be displayed on all email notifications to your respondents.",
      inputPlaceholder: 'URL',
      value: accountUrl,
      onChange: updateAccountUrl,
    },
    {
      title: 'Number of employees',
      input: 'select',
      value: numberOfEmployees,
      onChange: updateNumberOfEmployees,
      items: COMPANY_SIZE_OPTIONS,
    },
    {
      title: 'Company vertical',
      input: 'select',
      value: companyVertical,
      onChange: updateCompanyVertical,
      items: COMPANY_VERTICAL_OPTIONS,
    },
    {
      title: 'Company use case',
      input: 'select',
      value: companyUseCase,
      onChange: updateCompanyUseCase,
      items: COMPANY_USE_CASE_OPTIONS,
    },
  ];

  return (
    <LayoutRoot>
      <TitleContainer>
        <TextH5>Company information</TextH5>
      </TitleContainer>
      <InfoItemsContainer>
        {form.map(({ title, input, tooltipText, inputPlaceholder, value, onChange, items }) => {
          return (
            <InfoItem key={title}>
              <InfoItemTitleContainer>
                <InfoItemTitle>{title}</InfoItemTitle>
                <InfoIconWithTooltip title={tooltipText} />
              </InfoItemTitleContainer>
              {input === 'input' && (
                <StyledBasicInput
                  placeholder={inputPlaceholder}
                  value={value}
                  disabled={loading}
                  onChange={e => {
                    onChange(e.target.value);
                    onUpdate();
                  }}
                />
              )}
              {input === 'select' && (
                <StyledBasicSelect
                  items={items.map(option => {
                    return { id: option, name: option };
                  })}
                  inputBackground={theme.colors.white}
                  value={value}
                  disabled={loading}
                  onChange={value => {
                    onChange(value.name);
                    onUpdate();
                  }}
                />
              )}
            </InfoItem>
          );
        })}
      </InfoItemsContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)``;

const TitleContainer = styled.div``;

const InfoItem = styled(VerticalFlex)`
  gap: 4px;
  align-items: start;
`;

const InfoItemTitleContainer = styled(Gap8HorizontalFlexWrap)`
  min-width: 120px;
`;

const InfoItemTitle = styled(TextSubtitle)``;

const InfoItemsContainer = styled(Gap16VerticalFlex)``;

const StyledBasicInput = styled(BasicInput)`
  border: 1px solid ${({ theme }) => theme.colors.gray3};
  background: ${({ theme }) => theme.colors.white};
  padding: 12px;
  border-radius: 10px;
  max-width: 412px;
  width: 100%;
`;

const StyledBasicSelect = styled(BasicSelect)`
  max-width: 412px;
`;

export default CompanyInformation;
