import React from 'react';

const CreateNewIteractiveVideoIcon = ({ fill = '#FA536E', ...props }) => {
  return (
    <svg
      width={16}
      height={12}
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.58 2.539h.02c2.54 0 4.599 2.08 4.599 4.647s-2.06 4.648-4.6 4.648c-2.445 0-4.444-1.928-4.59-4.36H0V6.248c0-.395.049-.778.14-1.144A6.585 6.585 0 0 1 4.58.167v2.372Zm10.84 6.923h-.02c-2.54 0-4.599-2.081-4.599-4.647 0-2.567 2.06-4.648 4.6-4.648 2.444 0 4.444 1.928 4.59 4.36H20v1.225c0 .394-.05.778-.141 1.143a6.584 6.584 0 0 1-4.44 4.939V9.462Z"
        fill={fill || '#fff'}
      />
    </svg>
  );
};

export default CreateNewIteractiveVideoIcon;
