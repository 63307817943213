import React from 'react';
import styled, { useTheme } from 'styled-components';
import VideoIconV2 from 'app/src/images/dashboard_v2/VideoIconV2';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import CreateNewFeedIcon from 'app/src/images/create_new_content/CreateNewFeedIcon';
import CreateNewIteractiveVideoIcon from 'app/src/images/create_new_content/CreateNewIteractiveVideoIcon';
import CreateNewQuizIcon from 'app/src/images/create_new_content/CreateNewQuizIcon';
import { FEED_ID, QUIZ_ID, TOLSTOY_ID } from 'app/src/constants/CreateNewTolstoy.constants';

const CreateNewTolstoyButtonIcon = ({ disabled, id, className }) => {
  const theme = useTheme();

  if (id === FEED_ID) {
    return (
      <Button
        disabled={disabled}
        backgroundColor={theme.colors.primaryLight}
        hoverBackgroundColor={theme.getHexOpacity(theme.colors.primaryLight, 90)}
        className={className}
      >
        <CreateNewFeedIcon />
      </Button>
    );
  }

  if (id === QUIZ_ID) {
    return (
      <Button
        disabled={disabled}
        backgroundColor={theme.colors.warningLight}
        hoverBackgroundColor={theme.getHexOpacity(theme.colors.warningLight, 90)}
        className={className}
      >
        <CreateNewQuizIcon />
      </Button>
    );
  }

  if (id === TOLSTOY_ID) {
    return (
      <Button
        disabled={disabled}
        hoverBackgroundColor={theme.getHexOpacity(theme.colors.dangerLight, 90)}
        backgroundColor={theme.colors.dangerLight}
        className={className}
      >
        <CreateNewIteractiveVideoIcon fill={theme.colors.danger} />
      </Button>
    );
  }

  return (
    <Button
      hoverBackgroundColor={theme.colors.pink2}
      disabled={disabled}
      backgroundColor={theme.colors.danger}
      className={className}
    >
      <VideoIcon />
    </Button>
  );
};

const Button = styled(HorizontalFlexWrap)`
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  transition: 0.3s;

  &:hover {
    background-color: ${({ disabled, hoverBackgroundColor }) =>
      !disabled ? hoverBackgroundColor : ''};
  }
`;

const VideoIcon = styled(VideoIconV2)`
  width: 20px;
`;

export default CreateNewTolstoyButtonIcon;
