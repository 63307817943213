import React from 'react';
import styled from 'styled-components';

const PublishPreviewContainer = ({ isScaleOnHover, children, isSelected = false }) => {
  return (
    <LayoutRoot isScaleOnHover={isScaleOnHover} isSelected={isSelected}>
      {children}
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  box-shadow: ${({ theme, isSelected }) =>
    isSelected
      ? `0 16px 38px ${theme.getHexOpacity(theme.colors.black, 9)} ,0 0 0 2px ${
          theme.colors.primary
        }`
      : ` 0 16px 38px ${theme.getHexOpacity(theme.colors.black, 9)}`};
  transition: 0.3s;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  max-width: 160px;
  max-height: 320px;

  &:hover {
    transform: ${({ isScaleOnHover }) => (isScaleOnHover ? 'scale(1.06)' : '')};
    box-shadow: ${({ isScaleOnHover, theme, isSelected }) =>
      isScaleOnHover && !isSelected
        ? `0px 4px 4px ${theme.getHexOpacity(theme.colors.black, 25)}}`
        : ''};
  }
`;

export default PublishPreviewContainer;
