import { createActionsHook, createContainer, createHook, createStore } from 'react-sweet-state';
import {
  DrawerStyles,
  GlobalDrawerType,
  SetCurrentDrawerArgs,
} from 'src/complex_components/global_drawer/types/globalDrawer.types';

type State = {
  currentDrawer: GlobalDrawerType;
  drawerProps: any;
  drawerStyles?: DrawerStyles;
};

const initialState: State = {
  currentDrawer: null,
  drawerProps: {},
};

const actions = {
  setCurrentDrawer:
    ({ currentDrawer, drawerProps = {}, drawerStyles }: SetCurrentDrawerArgs) =>
    ({ setState }) => {
      setState({
        currentDrawer,
        drawerProps,
        drawerStyles,
      });
    },
  clearDrawerState:
    () =>
    ({ setState }) => {
      setState({
        ...initialState,
      });
    },
};

type Actions = typeof actions;

const DrawerStore = createStore({ initialState, actions });

export const useDrawer = createHook<State, Actions, State, void>(DrawerStore);

export const useDrawerActions = createActionsHook(DrawerStore);

export const DrawerContainer = createContainer(DrawerStore);
