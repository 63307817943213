import React from 'react';
import styled from 'styled-components';
import { useQuizContext } from 'app/src/pages/project/pages/quiz/QuizContext';
import { useQuizFlowContext } from 'app/src/pages/project/pages/quiz/quiz_result/flow/QuizFlowContext';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import { BolderTextSmall } from 'shared/react/components/complex/Text';
import QuizChoiceList from 'app/src/pages/project/pages/quiz/quiz_result/flow/rule/choices/QuizChoiceList';
import { useProjects } from 'app/src/context/ProjectsStore';
import QuizRuleChip from 'app/src/pages/project/pages/quiz/quiz_result/flow/rule/shared/QuizRuleChip';

function QuizRule({ quizResultId, flowId, rule, ruleIndex }) {
  const { quizResults, setQuizResults, setUnsaved, project } = useQuizContext();
  const { setPartMenuItems, getPartOptions } = useQuizFlowContext();
  const [, { getStepByName, getStepDescription }] = useProjects();

  const handleDeleteRule = (quizResultId, flowId, stepId) => {
    const newQuizResults = [...quizResults];
    const quizResult = newQuizResults.find(quizResult => quizResult.id === quizResultId);
    const flow = quizResult.flows.find(flow => flow.id === flowId);

    flow.rules = flow.rules.filter(rule => rule.stepId !== stepId);

    setQuizResults(newQuizResults);
    setPartMenuItems(getPartOptions());
    setUnsaved(true);
  };

  function getLetterFromIndex() {
    return String.fromCharCode(ruleIndex + 'A'.charCodeAt(0));
  }

  return (
    <LayoutRoot>
      <Letter>{getLetterFromIndex()}</Letter>
      <Content>
        <QuizRuleChip
          onClick={() => handleDeleteRule(quizResultId, flowId, rule.stepId)}
          tooltipText="Click to delete this rule"
        >
          {getStepDescription(project, getStepByName(rule.stepId, project))}
        </QuizRuleChip>
        <BolderTextSmall>is</BolderTextSmall>
        <QuizChoiceList quizResultId={quizResultId} rule={rule} flowId={flowId} />
      </Content>
    </LayoutRoot>
  );
}

export default QuizRule;

const LayoutRoot = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: auto 1fr;
`;

const Letter = styled.div`
  display: grid;
  align-content: center;
  justify-content: center;
  color: white;
  background: black;
  height: 16px;
  width: 16px;
  border-radius: 100%;
  font-size: 11px;
  font-weight: 700;
`;

const Content = styled(Gap8HorizontalFlexWrap)`
  align-items: center;
`;
