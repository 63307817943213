import React from 'react';
import styled from 'styled-components';
import { BREAKPOINT_OPTIONS } from 'shared/react/constants/menu.constants';
import MenuProduct from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/products/MenuProduct';
import Menu from 'shared/react/components/basic/Menu';

const ProductsSearchMenu = ({
  onClose,
  isOpen,
  getProductComponent,
  handleProductSelected,
  searchProducts,
  searchInputRef,
  selectedApp,
}) => {
  return (
    <Menu
      onClose={onClose}
      open={isOpen}
      anchorEl={searchInputRef.current}
      breakpoint={BREAKPOINT_OPTIONS.moveToTop}
    >
      <SearchProductsList width={searchInputRef?.current?.clientWidth} open={isOpen}>
        {searchProducts.map(product => {
          return (
            getProductComponent?.(product, onClose) || (
              <MenuProduct
                key={product.productId}
                title={product.title}
                currencySymbol={product.currencySymbol}
                price={product.price}
                imageUrl={product.imageUrl}
                handle={product.handle}
                onClick={() => handleProductSelected(product)}
                selectedApp={selectedApp}
              />
            )
          );
        })}
      </SearchProductsList>
    </Menu>
  );
};

const SearchProductsList = styled.div`
  height: fit-content;
  width: ${({ width }) => width}px;
  min-width: fit-content;
  border-radius: 0 0 16px 16px;
  max-height: 276px;
  overflow: auto;
  z-index: 10;
  background: ${({ theme }) => theme.colors.white};
  filter: ${({ open }) =>
    open
      ? 'drop-shadow(0px 10px 25px rgba(50, 50, 93, 0.1)) drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.05))'
      : ''};
`;

export default ProductsSearchMenu;
