import React from 'react';

const ShoppingBag = ({ width = 16, height = 17, fill = '#3D4FE0' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.25 5.125H10.2812V4.03125C10.2812 2.22656 8.80469 0.75 7 0.75C5.16797 0.75 3.71875 2.22656 3.71875 4.03125V5.125H1.75C1.25781 5.125 0.875 5.53516 0.875 6V12.5625C0.875 13.793 1.83203 14.75 3.0625 14.75H10.9375C12.1406 14.75 13.125 13.793 13.125 12.5625V6C13.125 5.53516 12.7148 5.125 12.25 5.125ZM5.03125 4.03125C5.03125 2.96484 5.90625 2.0625 7 2.0625C8.06641 2.0625 8.96875 2.96484 8.96875 4.03125V5.125H5.03125V4.03125ZM4.375 7.53125C3.99219 7.53125 3.71875 7.25781 3.71875 6.875C3.71875 6.51953 3.99219 6.21875 4.375 6.21875C4.73047 6.21875 5.03125 6.51953 5.03125 6.875C5.03125 7.25781 4.73047 7.53125 4.375 7.53125ZM9.625 7.53125C9.24219 7.53125 8.96875 7.25781 8.96875 6.875C8.96875 6.51953 9.24219 6.21875 9.625 6.21875C9.98047 6.21875 10.2812 6.51953 10.2812 6.875C10.2812 7.25781 9.98047 7.53125 9.625 7.53125Z"
        fill={fill}
      />
    </svg>
  );
};

export default ShoppingBag;
