import React from 'react';

const TapcartIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26" viewBox="0 0 30 26" fill="none">
      <path
        d="M28.1919 9.59883L20.0291 1.43605C19.5756 0.982559 19.0465 0.604651 18.4419 0.377907C17.8372 0.151163 17.2326 0 16.5523 0C15.8721 0 15.2674 0.151163 14.6628 0.377907C14.0581 0.604651 13.5291 0.982559 13.0756 1.43605L9.44767 5.06395C11.3372 5.36628 13.0756 6.34884 14.3605 7.78488C15.6453 9.22093 16.3256 11.1105 16.3256 13C16.3256 14.8895 15.6453 16.7791 14.3605 18.2151C13.0756 19.6512 11.4128 20.6337 9.44767 20.936L13.0756 24.564C13.5291 25.0174 14.0581 25.3953 14.6628 25.6221C15.2674 25.8488 15.8721 26 16.5523 26C17.2326 26 17.8372 25.8488 18.4419 25.6221C19.0465 25.3953 19.5756 25.0174 20.0291 24.564L28.1919 16.4012C29.0988 15.4942 29.6279 14.2093 29.6279 12.9244C29.6279 11.7907 29.0988 10.5058 28.1919 9.59883Z"
        fill="#2F3137"
      />
      <path
        d="M9.29651 5.21512L9.37209 5.13953C8.23837 4.98837 7.02907 4.98837 5.89535 5.36628C4.76163 5.6686 3.70349 6.27325 2.87209 7.02907C1.96512 7.78488 1.28488 8.76744 0.755814 9.82558C0.302326 10.8837 0 12.0174 0 13.2267C0 14.3605 0.226744 15.5698 0.755814 16.6279C1.2093 17.686 1.96512 18.6686 2.87209 19.4244C3.77907 20.1802 4.83721 20.7093 5.89535 21.0872C7.02907 21.3895 8.23837 21.4651 9.37209 21.3139L9.29651 21.2384C7.18023 19.1221 6.04651 16.3256 6.04651 13.3779C6.04651 10.1279 7.25581 7.25582 9.29651 5.21512Z"
        fill="#2F3137"
      />
    </svg>
  );
};

export default TapcartIcon;
