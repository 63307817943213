import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AnswerTextField from 'app/src/basic_components/AnswerTextField';
import { TextSmall } from 'shared/react/components/complex/Text';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { useDebounce } from 'app/src/hooks/useDebounce';
import { DEBOUNCE_TIME } from 'app/src/constants/builder.constants';

const FeedAnswerTextEditor = ({
  error,
  onChange,
  property,
  value,
  maxLength,
  placeholder,
  header,
  type,
  maxTextareaRows,
  dataTestId,
}) => {
  const [inputValue, setInputValue] = useState(value || '');
  const debouncedInputValue = useDebounce(inputValue, DEBOUNCE_TIME);

  const handleValueChanged = e => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    onChange({ value: debouncedInputValue, property });
  }, [debouncedInputValue]);

  return (
    <LayoutRoot error={error}>
      {!!header && <Header>{header}</Header>}
      <AnswerTextField
        onChange={handleValueChanged}
        value={inputValue}
        maxLength={maxLength}
        placeholder={placeholder}
        type={type}
        maxTextareaRows={maxTextareaRows}
        dataTestId={dataTestId}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)`
  width: 100%;
  position: relative;

  ::after {
    content: ${({ error }) => (error ? `"${error.message}"` : '')};
    color: ${({ theme }) => theme.colors.danger};
    padding: 4px;
    font-size: 12px;
    position: absolute;
    top: 100%;
  }
`;

const Header = styled(TextSmall)``;

export default FeedAnswerTextEditor;
