import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import {
  CREATE_TOLSTOY_BUTTON_DATA_TEST_ID,
  CREATE_TOLSTOY_MENU_DATA_TEST_ID,
} from 'app/src/constants/dataTestIds.constants';
import { track } from 'app/src/helpers/Tracker';
import { useDashboard } from 'app/src/context/ui_store/DashboardStore';
import CreateTolstoyTooltipV2 from 'app/src/pages/dashboard/pages/libraries/dashboard_toolbar/CreateTolstoyTooltipV2';
import ButtonWithRightSelect from 'app/src/complex_components/ButtonWithRightSelect';
import CreateNewTolstoyButtonIcon from 'app/src/pages/create_new_tolstoy/CreateNewTolstoyButtonIcon';
import { CircularProgress } from '@material-ui/core';
import useCanCreateProject from 'app/src/pages/dashboard/hooks/useCanCreateProject';
import CreateNewTolstoyButtonMenu from 'app/src/pages/dashboard/pages/libraries/CreateNewTolstoyButtonMenu';

const CreateNewTolstoyButton = ({ location }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [{ selectedFolder }] = useDashboard();
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isDisabled = useCanCreateProject();

  const onExtraOptionsClicked = e => {
    if (isDisabled) {
      return;
    }

    track('Create New Click');
    setAnchorEl(e.target);
  };

  const getLeftText = () => {
    return 'New Tolstoy';
  };

  const ButtonIcon = loading ? (
    <CircularProgress size={32} />
  ) : (
    <CreateNewTolstoyButtonIcon isDisabled={isDisabled} />
  );

  return (
    <>
      <StyledCreateTolstoyTooltipV2 isLocked={isDisabled}>
        <LayoutRoot>
          <StyledButtonWithRightSelect
            isDisabled={isDisabled}
            onExtraOptionsClicked={onExtraOptionsClicked}
            isLeftButtonLocked={isDisabled}
            isRightButtonLocked={!!selectedFolder || isDisabled}
            leftContainerTestId={CREATE_TOLSTOY_BUTTON_DATA_TEST_ID}
            rightContainerTestId={CREATE_TOLSTOY_MENU_DATA_TEST_ID}
            onLeftContainerClick={() => {}}
            leftText={getLeftText()}
            textColor={theme.colors.black}
            icon={ButtonIcon}
            isOpen={!!anchorEl}
            useLayoutClick={true}
            showArrow={false}
            showTextInMobile={true}
          />
        </LayoutRoot>
      </StyledCreateTolstoyTooltipV2>
      <CreateNewTolstoyButtonMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        setLoading={setLoading}
        location={location}
      />
    </>
  );
};

const LayoutRoot = styled.div``;

const StyledButtonWithRightSelect = styled(ButtonWithRightSelect)`
  gap: 12px;
  width: 216px;
  opacity: ${({ isOpen }) => (isOpen ? 0.5 : 1)};

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    padding: 8px 16px 8px 8px;
  }
`;

const StyledCreateTolstoyTooltipV2 = styled(CreateTolstoyTooltipV2)`
  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    align-self: baseline;
  } ;
`;

export default CreateNewTolstoyButton;
