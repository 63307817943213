import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { MoreVert as MoreIcon } from '@material-ui/icons';
import classnames from 'classnames';
import useStyles from './styles';

export default function ResetPasswordWidget({
  children,
  title,
  noBodyPadding,
  bodyClass,
  disableWidgetMenu,
  header,
  editClick,
  deleteClick,
}) {
  const classes = useStyles();

  // local
  const [moreButtonRef, setMoreButtonRef] = useState(null);
  const [isMoreMenuOpen, setMoreMenuOpen] = useState(false);

  function handleEditClick() {
    setMoreMenuOpen(false);
    editClick();
  }

  function handleDeleteClick() {
    setMoreMenuOpen(false);
    deleteClick();
  }

  return (
    <div>
      <div>
        {header ? (
          header
        ) : (
          <React.Fragment>
            <Typography variant="h5" color="textSecondary">
              {title}
            </Typography>
            {!disableWidgetMenu && (
              <IconButton
                color="primary"
                classes={{ root: classes.moreButton }}
                aria-owns="widget-menu"
                aria-haspopup="true"
                onClick={() => setMoreMenuOpen(true)}
                buttonRef={setMoreButtonRef}
              >
                <MoreIcon />
              </IconButton>
            )}
          </React.Fragment>
        )}
      </div>
      <div
        className={classnames(classes.widgetBody, {
          [classes.noPadding]: noBodyPadding,
          [bodyClass]: bodyClass,
        })}
      >
        {children}
      </div>
      <Menu
        id="widget-menu"
        open={isMoreMenuOpen}
        anchorEl={moreButtonRef}
        onClose={() => setMoreMenuOpen(false)}
        disableAutoFocusItem
      >
        {editClick && (
          <MenuItem onClick={handleEditClick}>
            <Typography>Edit</Typography>
          </MenuItem>
        )}
        {deleteClick && (
          <MenuItem onClick={handleDeleteClick}>
            <Typography>Delete</Typography>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
