import { track } from 'app/src/helpers/Tracker';
import Utils from 'app/src/utils';
import { useApps } from 'app/src/context/AppsStore';
import { useUser } from 'app/src/context/userStore/UserStore';
import { useTikTok } from 'app/src/context/TikTokStore';
import { useInstagram } from 'app/src/context/InstagramStore';
import { useSynthesiaActions } from 'app/src/context/SynthesiaStore';
import { SYNTHESIA } from 'app/src/constants/intergrations.constants';
import { useGoogleDriveActions } from 'app/src/context/GoogleDriveStore';
import APP_CONFIG from 'app/src/config/app.config';
import { useYoutubeActions } from 'app/src/context/YoutubeStore';
import { useModalActions } from 'app/src/context/ui_store/ModalStore';
import { INSTAGRAM_CONNECT_MODAL_KEY } from 'app/src/constants/modals.constants';

const useIntegrationsVideos = location => {
  const [{ account }] = useUser();
  const [
    {
      instagramApps,
      instagram,
      instagramGraph,
      tiktok,
      tiktokApps,
      synthesia,
      googleDrive,
      youtube,
    },
    { deleteApp, createApp },
  ] = useApps();
  const [, { clearStore: clearTiktokStore }] = useTikTok();
  const [, { clearStore: clearInstagramGraphStore }] = useInstagram();
  const { setCurrentModal } = useModalActions();
  const { clearGoogleDrive } = useGoogleDriveActions();
  const { clearSynthesia } = useSynthesiaActions();
  const { clearYoutube } = useYoutubeActions();

  const instagramProfile = JSON.parse(instagramGraph?.data || 'null');
  const googleDriveProfile = JSON.parse(googleDrive?.data || 'null');
  const youtubeProfile = JSON.parse(youtube?.data || 'null');
  const allTiktokAppsHaveUserInfo = tiktokApps.every(app => app.data.name);

  const handleTiktokConnect = () => {
    track('Connect To Tiktok Click', Utils.getAnalyticsLocationObject(location));
    const { appKey, owner: userId } = account;
    const integrationUrl = `${APP_CONFIG.INSTALL_TIKTOK_URL}?userId=${userId}&appKey=${appKey}`;
    Utils.openInNewTab(integrationUrl);
  };

  const handleGoogleDriveConnect = () => {
    track('Connect To Google Drive Click', Utils.getAnalyticsLocationObject(location));
    const { appKey } = account;
    const integrationUrl = `${APP_CONFIG.INSTALL_GOOGLE_DRIVE_URL}?appKey=${appKey}`;
    Utils.openInNewTab(integrationUrl);
  };

  const handleInstagramGraphConnect = async () => {
    const onConnectInstagramGraph = async () => {
      track('Connect To Instagram Click', Utils.getAnalyticsLocationObject(location));

      const { appKey } = account;
      const integrationUrl = `${APP_CONFIG.INSTALL_INSTAGRAM_GRAPH_URL}?appKey=${appKey}`;

      if (instagram) {
        await deleteApp(instagram);
      }

      Utils.openInNewTab(integrationUrl);
    };

    setCurrentModal(INSTAGRAM_CONNECT_MODAL_KEY, { onConnectInstagramGraph });
  };

  const handleSynthesiaConnect = async apiKey => {
    await createApp(SYNTHESIA, apiKey);
  };

  const handleYoutubeConnect = () => {
    track('Connect To Instagram Click', Utils.getAnalyticsLocationObject(location));
    const { appKey } = account;
    const integrationUrl = `${APP_CONFIG.INSTALL_YOUTUBE_URL}?appKey=${appKey}`;

    Utils.openInNewTab(integrationUrl);
  };

  const handleYoutubeDisconnect = async () => {
    track('Disconnect Youtube Click', Utils.getAnalyticsLocationObject(location));
    await deleteApp(youtube);
    clearYoutube();
  };

  const handleSynthesiaDisconnect = async () => {
    track('Disconnect Synthesia Click', Utils.getAnalyticsLocationObject(location));
    await deleteApp(synthesia);
    clearSynthesia();
  };

  const handleTikTokDisconnect = async tiktokApp => {
    track('Disconnect Tiktok Click', Utils.getAnalyticsLocationObject(location));

    const appToDisconnect = tiktokApp || instagramGraph;

    if (!appToDisconnect) {
      return;
    }

    await deleteApp(appToDisconnect);
    clearTiktokStore();
  };

  const handleInstagramGraphDisconnect = async instagramApp => {
    track('Disconnect Instagram Click', Utils.getAnalyticsLocationObject(location));

    const appToDisconnect = instagramApp || instagramGraph;

    if (!appToDisconnect) {
      return;
    }

    await deleteApp(appToDisconnect);
    clearInstagramGraphStore();
  };

  const disconnectInstagramByAppExternalId = async appExternalId => {
    const app = instagramApps.find(app => app.appExternalId === appExternalId);

    if (!app) {
      return;
    }

    return handleInstagramGraphDisconnect(app);
  };

  const handleGoogleDriveDisconnect = async () => {
    track('Disconnect Google Drive Click', Utils.getAnalyticsLocationObject(location));
    await deleteApp(googleDrive);
    clearGoogleDrive();
  };

  const getInstagramUsername = ({ appExternalId } = {}) => {
    if (!appExternalId) {
      return instagramProfile?.userName;
    }

    const app = instagramApps.find(app => app.appExternalId === appExternalId);

    return app?.data?.name || instagramProfile?.userName;
  };

  const getTiktokUsername = ({ appExternalId } = {}) => {
    const app = tiktokApps.find(app => app.appExternalId === appExternalId);

    return app?.data?.name || instagramProfile?.userName;
  };

  return {
    handleTikTokDisconnect,
    handleInstagramDisconnect: handleInstagramGraphDisconnect,
    handleInstagramConnect: handleInstagramGraphConnect,
    handleTiktokConnect,
    handleSynthesiaConnect,
    handleSynthesiaDisconnect,
    handleGoogleDriveConnect,
    handleGoogleDriveDisconnect,
    handleYoutubeConnect,
    handleYoutubeDisconnect,
    disconnectInstagramByAppExternalId,
    getInstagramUsername,
    getTiktokUsername,
    instagramApps,
    instagramProfile,
    hasOldInstagramIntegration: !!instagram,
    instagram: instagramGraph,
    allTiktokAppsHaveUserInfo,
    tiktok,
    tiktokApps,
    synthesia,
    googleDrive,
    googleDriveProfile,
    youtube,
    youtubeProfile,
  };
};

export default useIntegrationsVideos;
