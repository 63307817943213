import Utils from 'src/utils';
import { uploadToS3 } from 'src/helpers/S3Upload';
import APP_CONFIG from 'app/src/config/app.config';
import { S3UploadAssetType } from 'src/constants/assets.constants';

export const baseUrl = ({ owner }: { owner?: string }) =>
  `${APP_CONFIG.VIDEO_OUTPUT}/public/${owner}`;

export type UploadNewImageOptions = {
  id: string;
  name: string;
  owner?: string;
};

export const uploadAsset = async (file: File, options: UploadNewImageOptions) => {
  const ext = file.name.split('.').pop();
  const { id, name, owner = Utils.getOwner() } = options;
  const path = `public/${owner}/${id}/${name}.${ext}`;

  await uploadToS3(S3UploadAssetType.Output, path, file);
  return path;
};

export default {
  uploadAsset,
};
