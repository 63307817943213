import React from 'react';

const MenuIcon = () => (
  <svg width={17} height={13} viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 7h14.4M1 1.8h14.4M1 11.8h14.4"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MenuIcon;
