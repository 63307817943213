import React from 'react';
import styled from 'styled-components';
import HorizontalFlexWrap from './HorizontalFlexWrap';

function Gap8HorizontalFlexWrap({ children, ...props }) {
  return <LayoutRoot {...props}>{children}</LayoutRoot>;
}

export default Gap8HorizontalFlexWrap;

const LayoutRoot = styled(HorizontalFlexWrap)`
  gap: 8px;
`;
