import React, { useState } from 'react';
import styled from 'styled-components';
import { App } from 'src/types/app';
import { getUgcMessage } from 'src/utils/ugc';
import TextArea from 'src/basic_components/TextArea';
import Tooltip from 'src/basic_components/tooltip-v2/Tooltip';
import { TextH3Bold, TextSmall } from 'shared/react/components/basic/text/TextV2';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { ModalAssetPreview } from 'src/complex_components/global_modal/common/assets';
import { VideosPageVodAsset } from 'src/pages/dashboard/pages/videos/types/videos.types';
import { PrimaryButton, WhiteButton } from 'shared/react/components/basic/button-v2/BasicButton';
import HorizontalFlex, {
  Gap8HorizontalFlex,
  Gap16HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { UgcRequestStep, LoadState } from './UgcRequest.constants';
import { getPrimaryButtonText, parseMessage, getApprovalLink } from './UgcRequest.utils';

type Props = {
  step: UgcRequestStep;
  video: VideosPageVodAsset;
  username: string;
  selectedApp: App;
  loadState: LoadState | null;
  onRequest: (text: string) => void;
  onGoToPost: () => void;
  onAddToLibrary: () => void;
};

const UGCRequest = ({
  step,
  video,
  selectedApp,
  username,
  loadState,
  onRequest,
  onGoToPost,
  onAddToLibrary,
}: Props) => {
  const [inputValue, setInputValue] = useState(getUgcMessage(selectedApp));

  const isPending = step === UgcRequestStep.Pending;
  const primaryButtonText = getPrimaryButtonText({ step, username });
  const approvalLink = getApprovalLink({
    appKey: selectedApp.appKey,
    externalId: video.externalId,
  });

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => setInputValue(e.target.value);

  const handlePrimaryClick = async () => {
    const message = parseMessage(inputValue, username);
    const textWithLink = `${message}\n${approvalLink}`;

    switch (step) {
      case UgcRequestStep.Pending:
      case UgcRequestStep.Request:
        return onRequest(textWithLink);
      default:
        return '';
    }
  };

  return (
    <LayoutRoot>
      <ModalAssetPreview asset={video} shouldHide={false} />
      <RightContent>
        <TextH3Bold>Ready to request usage rights?</TextH3Bold>

        <TextSmall>
          We’ll send the creator the following <b>direct message</b> from your account so they can
          grant you rights through the link. Once they approve, we’ll add it to your Tolstoy
          library.
        </TextSmall>

        <Comment>
          <TextSmall>Direct Message</TextSmall>
          <TextAreaInput
            value={parseMessage(inputValue, username)}
            onChange={onChange}
            disabled={isPending}
            style={{ height: '80px' }}
          />
          <ButtonsContainer>
            <StyledWhiteButton onClick={onGoToPost}>View post</StyledWhiteButton>
            {primaryButtonText && (
              <PrimaryButton isLoading={loadState !== null} onClick={handlePrimaryClick}>
                {primaryButtonText}
              </PrimaryButton>
            )}
          </ButtonsContainer>
        </Comment>
        <AddToLibraryContainer>
          <AddToLibraryLabel>Already have usage rights?</AddToLibraryLabel>
          <Tooltip
            text="Add content to your library if you already have usage rights from the creator"
            placement="top"
          >
            <AddToLibraryButton
              isLoading={loadState === LoadState.Importing}
              onClick={onAddToLibrary}
            >
              Add to Tolstoy library
            </AddToLibraryButton>
          </Tooltip>
        </AddToLibraryContainer>
      </RightContent>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)``;

const RightContent = styled(VerticalFlex)`
  gap: 24px;
  padding: 24px;
  width: 100%;
`;

const Comment = styled(VerticalFlex)`
  gap: 4px;
  flex: 1;
`;

const TextAreaInput = styled(TextArea)`
  resize: none;
  width: 100%;
  padding: 8px 12px 12px 12px;
`;

const ButtonsContainer = styled(Gap8HorizontalFlex)`
  justify-content: flex-end;
  margin-top: 20px;
`;

const AddToLibraryContainer = styled(Gap16HorizontalFlex)`
  gap: 8px;
  align-items: center;
`;

const AddToLibraryLabel = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.gray2};
`;

const StyledWhiteButton = styled(WhiteButton)`
  border: unset;
`;

const AddToLibraryButton = styled(StyledWhiteButton)`
  padding: 0;
  text-decoration: underline;
`;

export default UGCRequest;
