import React, { forwardRef } from 'react';
import styled from 'styled-components';

const VerticalFlex = forwardRef(({ children, isVisible = true, ...props }, ref) => {
  if (!isVisible) {
    return null;
  }

  return (
    <LayoutRoot ref={ref} {...props}>
      {children}
    </LayoutRoot>
  );
});

const LayoutRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

VerticalFlex.displayName = 'VerticalFlex';

export const VerticalFlexCentered = styled(VerticalFlex)`
  align-items: center;
  justify-content: center;
`;

export default VerticalFlex;
