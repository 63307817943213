import React, { useRef } from 'react';
import HeaderAndSubheader from 'src/basic_components/HeaderAndSubheader';
import ComponentWithLoader from 'src/complex_components/ComponentWithLoader';
import { ONBOARDING_LOCATION } from 'src/constants/trackingLocations.constants';
import { useVideos } from 'src/context/VideosStore';
import { useVodLabels } from 'src/context/VodLabelsStore';
import { useVirtualizedList } from 'src/hooks/useInfiniteScroll';
import Loading from 'src/pages/dashboard/components/loading/Loading';
import Utils from 'src/utils';
import styled from 'styled-components';
import NumberOfEntities from '../../../../../videos/components/number-of-entities/NumberOfEntities';
import VideoGrid from '../../../../../videos/components/video_grid/VideoGrid';
import useAccountOnboardingTemplates from '../../../hooks/useAccountOnboardingTemplates';
import useAccountOnboardingVideos from '../../../hooks/useAccountOnboardingVideos';
import { ONBOARDING_TEMPLATES_PRE_MADE_LABELS } from '../../constants/onboardingTemplates.constants';

const OnboardingTemplatesTagProducts = () => {
  const [{ data: videos = [], initialized }] = useVideos();
  const [{ vodLabels }] = useVodLabels();
  const { getTemplateAnalyticsData, getStageProps } = useAccountOnboardingTemplates();
  const {
    allVideos,
    isLoading: isLoadingVideos,
    onSelectedVideosAction,
    onScrollEndCallback,
  } = useAccountOnboardingVideos();
  const containerRef = useRef();
  const { renderCount, isLoading, isDone } = useVirtualizedList(
    containerRef,
    videos.length,
    onScrollEndCallback,
    null
  );

  const currentVideos = allVideos.slice(0, renderCount);
  const numberOfVideosText = `${currentVideos.length} videos shown`;

  const analyticsData = {
    ...Utils.getAnalyticsLocationObject(ONBOARDING_LOCATION),
    ...getTemplateAnalyticsData(),
  };

  const {
    header,
    subheader,
    isAddTagProductsTooltipToFirstCard,
    isAddTagLabelsTooltipToFirstCard,
  } = getStageProps();

  const nonHomepageLabels = vodLabels?.filter(
    ({ name }) => name !== ONBOARDING_TEMPLATES_PRE_MADE_LABELS.homepage
  );

  const shouldShowLabelsTooltip = isAddTagLabelsTooltipToFirstCard && !nonHomepageLabels?.length;
  return (
    <LayoutRoot ref={containerRef}>
      <HeaderAndSubheader header={header} subheader={subheader} />
      <StyledComponentWithLoader isLoading={isLoadingVideos}>
        <StyledVideoGrid
          items={currentVideos}
          initialized={initialized}
          onSelectedVideosAction={onSelectedVideosAction}
          isDisableCardHoverState
          analyticsData={analyticsData}
          isAddTagProductsTooltipToFirstCard={isAddTagProductsTooltipToFirstCard}
          isAddTagLabelsTooltipToFirstCard={shouldShowLabelsTooltip}
        />
        <NumberOfEntities text={numberOfVideosText} shouldShow={isDone && videos.length} />
        <Loading isLoading={isLoading} />
      </StyledComponentWithLoader>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: overlay;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
`;

const StyledVideoGrid = styled(VideoGrid)`
  padding: 0 72px;
`;

const StyledComponentWithLoader = styled(ComponentWithLoader)`
  position: absolute;
  align-self: center;
  top: calc(50% - 12px);
`;

export default OnboardingTemplatesTagProducts;
