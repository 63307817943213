import React from 'react';
import EditorItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorItem';
import { Slider } from '@material-ui/core';
import styled from 'styled-components';
import HorizontalFlex, {
  Gap16HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { Input } from 'shared/react/components/basic/input/Input';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import NumericInput from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/NumericInput';

function EditorSliderItem({
  text,
  renderTextLayout = null,
  onChange,
  min,
  max,
  dataTestId = '',
  marks = null,
  step,
  value,
  disabled,
  ...props
}) {
  const onInputChange = e => {
    const value = e.target.value;
    if (value < min) {
      onChange(e, min);
      return;
    }

    if (value > max) {
      onChange(e, max);
      return;
    }

    onChange(e, +value);
  };

  return (
    <LayoutRoot>
      <Container>
        <EditorItem
          {...props}
          disabled={disabled}
          text={text}
          renderTextLayout={renderTextLayout}
        />
        <SliderContainer>
          <CustomSlider
            value={value}
            step={step}
            valueLabelDisplay="auto"
            min={min}
            max={max}
            onChange={onChange}
            disabled={disabled}
          />
          <SliderInput type="number" value={value || 0} onChange={onInputChange} />
        </SliderContainer>
      </Container>
    </LayoutRoot>
  );
}

const LayoutRoot = styled(HorizontalFlex)`
  width: 100%;
  & * {
    border: none !important;
  }

  .MuiOutlinedInput-notchedOutline {
  }

  .MuiInputBase-root {
    height: 40px;
  }

  .MuiSlider-track {
    height: 4px;
  }

  .MuiSlider-rail {
    color: ${({ theme }) => theme.colors.neutralLight};
    height: 4px;
  }
  .MuiSlider-thumb {
    width: 18px;
    height: 18px;
    color: ${({ theme }) => theme.colors.primary};
    margin-top: -7px;
    border: 1px solid ${({ theme }) => theme.colors.neutralLighter};
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.05))
      drop-shadow(0px 2px 10px rgba(50, 50, 93, 0.1));
  }

  .MuiSlider-thumb > .MuiSlider-valueLabel {
    color: ${({ theme }) => theme.colors.white};
    left: -50%;
  }

  .MuiSlider-valueLabel span > span {
    color: ${({ theme }) => theme.colors.black};
  }
`;

const Container = styled(Gap8VerticalFlex)`
  width: 100%;
`;

const CustomSlider = styled(Slider)``;

const SliderContainer = styled(Gap16HorizontalFlex)`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 22px;
  align-items: center;
`;

const SliderInput = styled(NumericInput)``;

export default EditorSliderItem;
