import React from 'react';
import styled from 'styled-components';
import FolderMenuItem from 'app/src/pages/dashboard/components/folder/FolderMenuItem';
import { UGC_SEARCH, UGC_PENDING, UGC_APPROVED } from 'app/src/constants/navigation.constants';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

type Props = {
  source: string;
  selectSource?: (source: string) => void;
};

const NAV_ITEMS = [
  {
    key: UGC_SEARCH,
    text: 'Search',
    showLeftIcon: false,
  },
  {
    key: UGC_PENDING,
    text: 'Pending',
    showLeftIcon: false,
  },
  {
    key: UGC_APPROVED,
    text: 'Approved',
    showLeftIcon: false,
  },
];

export const UgcSideBar = ({ source, selectSource }: Props) => {
  return (
    <LayoutRoot>
      {NAV_ITEMS.map(({ text, key, ...props }) => (
        <FolderMenuItem
          onClick={() => selectSource(key)}
          key={text}
          text={text}
          showDefaultEndIcon={false}
          isSelected={source === key}
          {...props}
        />
      ))}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  gap: 4px;
  overflow: auto;
`;

export default UgcSideBar;
