import React from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { AccordionTitle, AnalyticsTextField } from 'app/src/basic_components/commonStyles';
import BasicAccordion from 'shared/react/components/BasicAccordion';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextSubtitle } from 'shared/react/components/complex/Text';
import ResetToDefaultInputWrapper from 'src/complex_components/ResetToDefaultInputWrapper';
import Utils from 'src/utils';

import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import { FIND_GOOGLE_ANALYTICS_HELP_ARTICLE } from 'src/constants/url.constants';
import useGoogleAnalytics from 'src/hooks/useGoogleAnalytics';

const TrackingSettings = () => {
  const { setValue, watch } = useFormContext();

  const [googleAnalyticsID, facebookAnalyticsID] = watch([
    'googleAnalyticsID',
    'facebookAnalyticsID',
  ]);

  const { googleAnalyticsId: googleAnalyticsTag } = useGoogleAnalytics(googleAnalyticsID);

  function handleGoogleAnalyticsIdChange(e) {
    setValue('googleAnalyticsID', e.target.value);
  }

  function handleFacebookAnalyticsIdChange(e) {
    setValue('facebookAnalyticsID', e.target.value);
  }
  const handleResetGoogleAnalyticsID = () => {
    setValue('googleAnalyticsID', null);
  };

  const onFindGoogleTagClick = () => {
    Utils.openInNewTab(FIND_GOOGLE_ANALYTICS_HELP_ARTICLE);
  };

  return (
    <LayoutRoot>
      <BasicAccordion header={<AccordionTitle>Tracking</AccordionTitle>} startExpanded={false}>
        <TrackingContainer>
          <TrackingItemContainer>
            <GoogleAnalyticsTitle>Google Analytics</GoogleAnalyticsTitle>
            <ResetToDefaultInputWrapper onClick={handleResetGoogleAnalyticsID}>
              <AnalyticsTextField
                label="Google Analytics tracking code"
                variant="outlined"
                name="googleAnalyticsID"
                placeholder="G-1AAA11AAAA"
                value={googleAnalyticsTag}
                onChange={handleGoogleAnalyticsIdChange}
              />
            </ResetToDefaultInputWrapper>
            <LinkContainer onClick={onFindGoogleTagClick}>
              <LinkText>Find your Google tag ID</LinkText>
            </LinkContainer>
          </TrackingItemContainer>
          <FacebookPixelTitle>Facebook Pixel</FacebookPixelTitle>
          <AnalyticsTextField
            label="Facebook Pixel tracking code"
            variant="outlined"
            name="facebookAnalyticsID"
            placeholder="123456789123456"
            value={facebookAnalyticsID || ''}
            onChange={handleFacebookAnalyticsIdChange}
          />
        </TrackingContainer>
      </BasicAccordion>
    </LayoutRoot>
  );
};

export default TrackingSettings;

const TrackingContainer = styled(VerticalFlex)`
  max-width: 336px;
`;

const TrackingItemContainer = styled(VerticalFlex)`
  gap: 4px;
`;

const LayoutRoot = styled.div`
  display: grid;
  align-items: start;
  align-content: start;
  width: 100%;
  padding: 24px;
  overflow-y: auto;
  max-width: 630px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    padding: 16px;
  }
`;

const GoogleAnalyticsTitle = styled(TextSubtitle)`
  margin-top: 24px;
`;

const LinkContainer = styled(Gap8HorizontalFlexWrap)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.blue1};
`;

const LinkText = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.blue1};
`;

const FacebookPixelTitle = styled(TextSubtitle)`
  margin-top: 16px;
`;
