import React from 'react';
import { PageContainer, PageMain, PageHeader } from 'app/src/pages/dashboard/components/page/Page';
import ShopAppGrid from 'app/src/pages/dashboard/pages/shop-app/components/shop-app-grid/ShopAppGrid';
import styled from 'styled-components';

const ShopApp = () => (
  <PageContainer>
    <PageMain>
      <EmptyPageHeader />
      <ShopAppGrid />
    </PageMain>
  </PageContainer>
);

const EmptyPageHeader = styled(PageHeader)`
  height: 64px;
`;

export default ShopApp;
