import React from 'react';
import styled from 'styled-components';
import { Container, Modal } from '../../styled_components';
import { CLOSE_MODAL_DATA_TEST_ID } from 'app/src/constants/dataTestIds.constants';

export default function StandardModal({
  children,
  open,
  onClose,
  onRendered,
  width,
  maxWidth,
  height,
  autoWidth,
  removeDefaultPadding,
  excludeXButton,
  fullscreen,
  closeModalLocation,
  isFixedHeight,
  zIndex,
  ...props
}) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      onRendered={onRendered}
      $isFixedHeight={isFixedHeight}
      z-index={zIndex}
    >
      {fullscreen ? (
        <FullScreenModalContainer>
          {!excludeXButton && <FullScreenCloseModal onClick={onClose}>✕</FullScreenCloseModal>}
          <FullScreenModalContent excludeXButton={excludeXButton}>
            {children}
          </FullScreenModalContent>
        </FullScreenModalContainer>
      ) : (
        <ModalContainer
          width={width}
          maxWidth={maxWidth}
          height={height}
          autoWidth={autoWidth}
          removeDefaultPadding={removeDefaultPadding}
          $isFixedHeight={isFixedHeight}
          {...props}
        >
          {!excludeXButton && (
            <CloseModal
              closeModalLocation={closeModalLocation}
              onClick={onClose}
              data-test-id={CLOSE_MODAL_DATA_TEST_ID}
            >
              ✕
            </CloseModal>
          )}
          {children}
        </ModalContainer>
      )}
    </Modal>
  );
}

const ModalContainer = styled(Container)`
  min-width: 300px;
  padding: ${props => (props.removeDefaultPadding ? '0px' : '20px')};
  max-width: ${props => props.maxWidth || props.width || 'fit-content'};
  min-height: ${props => props.height || 'fit-content'};
  flex-grow: ${props => props.autoWidth || '1'};
  height: ${props => (props.$isFixedHeight ? 'max-content' : props.height || 'unset')};
  margin: 0 16px;
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    margin: 20px;
  }
`;

const CloseModal = styled.div`
  cursor: pointer;
  position: absolute;
  right: ${({ closeModalLocation }) => closeModalLocation || '20px'};
  top: ${({ closeModalLocation }) => closeModalLocation || '15px'};
  font-weight: 600;
  color: black;
  z-index: 2;
`;

const FullScreenModalContent = styled.div`
  grid-row: 1;
  grid-column: 1;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    grid-row: 2;
    grid-column: 1;
  }
`;

const FullScreenModalContainer = styled(Container)`
  display: grid;
  min-width: 300px;
  border-radius: 0;
  padding: 0;
  max-width: ${props => props.width};
  min-height: ${props => props.height};
  width: 100%;
  height: 100%;
  margin: 0;
  overflow-y: auto;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    grid-template-rows: auto 1fr;
  }
`;

const FullScreenCloseModal = styled.div`
  cursor: pointer;
  justify-self: end;
  z-index: 2;
  margin: 20px 30px;
  grid-row: 1;
  grid-column: 1;
  height: 31px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    height: 20px;
    margin: 16px;
    grid-row: 1;
    grid-column: 1;
  }
`;
