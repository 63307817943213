import { createActionsHook, createContainer, createHook, createStore } from 'react-sweet-state';

const initialState = {
  currentProjectId: '',
  currentModal: '',
  currentId: '',
  modalType: '',
  currentFolderId: '',
  currentIds: [],
  modalProps: {},
};

const actions = {
  setCurrentProjectId:
    projectId =>
    ({ setState }) => {
      setState({ currentProjectId: projectId });
    },
  setCurrentFolderId:
    folderId =>
    ({ setState }) => {
      setState({ currentFolderId: folderId });
    },
  setCurrentModal:
    (modalName, modalProps) =>
    ({ setState, getState }) => {
      const { modalProps: stateModalProps } = getState();

      setState({
        currentModal: modalName,
        modalProps: modalProps ?? stateModalProps,
      });
    },
  setCurrentId:
    currentId =>
    ({ setState }) => {
      setState({ currentId });
    },
  setCurrentIds:
    currentIds =>
    ({ setState }) => {
      setState({ currentIds });
    },
  setCurrentModalType:
    modalType =>
    ({ setState }) => {
      setState({ modalType });
    },
  setModalProps:
    modalProps =>
    ({ setState }) => {
      setState({ modalProps });
    },
  updateModalProps:
    updateProps =>
    ({ setState, getState }) => {
      const { modalProps: stateModalProps } = getState();
      const modalProps = { ...stateModalProps, ...updateProps };

      setState({ modalProps });
    },
  clearModalState:
    () =>
    ({ setState }) => {
      setState({ ...initialState });
    },
};

const ModalStore = createStore({ initialState, actions });

export const useModalActions = createActionsHook(ModalStore);

export const useModal = createHook(ModalStore);

export const ModalContainer = createContainer(ModalStore);
