import { TIKTOK, INSTAGRAM } from 'shared/react/constants/intergrations.constants';

export const GORGIAS = 'gorgias';
export const INTERCOM = 'intercom';
export const SHOPIFY = 'shopify';
export const MAGENTO = 'magento';
export const HUBSPOT = 'hubspot';
export const WIX = 'wix';
export const OUTREACH = 'outreach';
export const SALESLOFT = 'salesloft';
export { TIKTOK };
export const DROPBOX = 'dropbox';
export const SYNTHESIA = 'synthesia';
export const HAS_SHOPIFY_INSTALLED = 'hasAlreadyInstalledShopify';
export const STORE_CONNECTED_TO_ANOTHER_ACCOUNT = 'storeConnectedToAnotherAccount';
export const SHOPIFY_INSTALLATION_FAILED = 'storeInstallationFailed';
export const MONDAY = 'monday';
export const GREEN_HOUSE = 'greenhouse';
export const KLAVIYO = 'klaviyo';
export const BIG_COMMERCE = 'bigcommerce';
export { INSTAGRAM };
export const INSTAGRAM_GRAPH = 'instagramGraph';
export const GOOGLE_DRIVE = 'googleDrive';
export const PRODUCTS_IMPORT = 'productsImport';
export const YOTPO = 'yotpo';
export const SHOPIFY_PLUS = 'Shopify Plus';
export const YOUTUBE = 'youtube';
export const TAVUS = 'tavus';
export const UGC_INSTAGRAM_GRAPH = `ugc_instagramGraph`;
export const UGC_INSTAGRAM_GRAPH_DENIED = 'ugc_instagramGraph_denied';
export const UGC_INSTAGRAM_GRAPH_PENDING = `ugc_instagramGraph_pending`;
export const ALL_UGC_UPLOAD_TYPES = [
  UGC_INSTAGRAM_GRAPH,
  UGC_INSTAGRAM_GRAPH_DENIED,
  UGC_INSTAGRAM_GRAPH_PENDING,
];

export const INSTAGRAM_GRAPH_ERROR_CODE = 'CouldntGetInstagram';
export const INSTAGRAM_GRAPH_ERROR_CODE_Message =
  "We're having trouble connecting to Instagram. To ensure a smooth setup, please select the Instagram account associated with the Facebook page you're attempting to connect.";

export const PRODUCTS_CATALOG_INTEGRATIONS = [SHOPIFY, MAGENTO, PRODUCTS_IMPORT];

export const MULTI_ACCOUNTS_INTEGRATIONS_SOURCES = [INSTAGRAM_GRAPH, TIKTOK];
