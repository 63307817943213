import React from 'react';

interface PlansUsageViewsProps {
  maxViews: number;
  monthViews: number;
  progressPercent: number;

  className?: string;
  isShopify?: boolean;
}

const getUsageMesasge = (monthViews: number, maxViews: number) => {
  return monthViews > maxViews
    ? `Your Tolstoy widgets have had ${monthViews.toLocaleString()} unique impressions this calendar month, exceeding the ${maxViews.toLocaleString()} included in your plan.`
    : `Your Tolstoy widgets have had ${monthViews.toLocaleString()} out of ${maxViews.toLocaleString()} unique impressions included in your plan per calendar month.`;
};

export const PlansUsageViews = ({
  maxViews,
  monthViews,
  progressPercent,
  isShopify = false,
  className,
}: PlansUsageViewsProps) => {
  // const hasExceeded = monthViews > maxViews;
  // const percentage = hasExceeded ? Math.round((maxViews / monthViews) * 100) : progressPercent;

  return (
    <div className={className}>
      <div className="flex flex-col gap-5">
        <div className="flex items-center gap-2">
          <p className="text-lg font-semibold text-black">Usage</p>
        </div>
        <div className="flex flex-col gap-4 text-sm">
          <p>
            Usage is based on unique impressions, tracking the number of times Tolstoy was loaded on
            your site to unique visitors.
          </p>
          {isShopify && <p>{getUsageMesasge(monthViews, maxViews)}</p>}
        </div>

        {/* Temporarely hiding untill finishde design is confirmed */}
        {/* <Progress
          className={cn(
            'h-4 rounded bg-slate-200',
            hasExceeded && 'bg-purple-800',
          )}
          indicatorClassName="bg-gradient-to-r from-blue-500 to-blue-700"
          value={percentage}
        /> */}
      </div>
    </div>
  );
};
