import React from 'react';
import ColorfulSeparator from './ColorfulSeparator';

const ColorfulWrapper = ({ children }) => {
  return (
    <>
      <ColorfulSeparator />
      {children}
    </>
  );
};

export default ColorfulWrapper;
