import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html,
  body,
  input,
  textarea,
  #app,
  #app > div,
  #app > button,
  #app > span,
  #app > li,
  button,
  span,
  div,
  li {
    font-family: 'Inter', 'Nunito Sans', sans-serif;
  }
`;

export default GlobalStyle;
