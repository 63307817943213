import React from 'react';
import { project as projectType } from 'app/src/types/entities';
import DynamicTypeContent from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/DynamicTypeContent';
import Spinner from 'app/src/basic_components/Spinner';
import styled from 'styled-components';
import { useBuilderFormContext } from 'app/src/context/BuilderFormStore';
import Builder from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/Builder';
import SourceProjectNotice from 'app/src/pages/project/pages/project-edit/components/project-content/SourceProjectNotice';
import { getIsDerivedProject } from 'app/src/utils/project.utils';
import { useProjects } from 'app/src/context/ProjectsStore';

type Props = {
  project: projectType;
  containerRef: React.RefObject<HTMLDivElement>;
};

const ProjectContent = ({ project: currentProject, containerRef }: Props) => {
  const {
    getProjectIsFeedTypeDynamic,
    builderForm: {
      dirtyFormToUpdate: { isLoading },
    },
  } = useBuilderFormContext();

  const isDerivedProject = getIsDerivedProject(currentProject);
  const [{ project: sourceProject }] = useProjects({ projectId: currentProject?.sourceProjectId });
  const project = isDerivedProject ? sourceProject : currentProject;

  if (isLoading) {
    return (
      <>
        <Spinner />
      </>
    );
  }

  if (getProjectIsFeedTypeDynamic(project)) {
    return (
      <DynamicTypeContent
        containerRef={containerRef}
        isDerivedProject={isDerivedProject}
        project={project}
        projects={{ currentProject, sourceProject }}
      />
    );
  }

  return (
    <BuilderContainer>
      <SourceProjectNotice project={currentProject} sourceProject={sourceProject} />
      <Builder />
    </BuilderContainer>
  );
};

const BuilderContainer = styled.div`
  & > * {
    margin: 0;
  }
`;

export default ProjectContent;
