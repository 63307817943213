import { SHOP_PROD } from 'src/constants/analytics.constants';
import { CUBE_PROPERTY_KEYS } from 'src/constants/cube.constants';
import Utils from 'src/utils';
import {
  FormattedLocationMetric,
  GetShopMetricsProps,
  LocationMetric,
  ShopMetrics,
} from './types/getShopMetrics.types';

const getFormattedLocationMetrics = (locationMetrics: LocationMetric): FormattedLocationMetric => {
  const location = locationMetrics[`${SHOP_PROD}.${CUBE_PROPERTY_KEYS.location}`];
  const numOfImpressions = +locationMetrics[`${SHOP_PROD}.${CUBE_PROPERTY_KEYS.numOfImpressions}`];
  const numOfSessionStarts =
    +locationMetrics[`${SHOP_PROD}.${CUBE_PROPERTY_KEYS.numOfSessionStarts}`];
  const numOfVideosWatched =
    +locationMetrics[`${SHOP_PROD}.${CUBE_PROPERTY_KEYS.numOfVideosWatched}`];
  const numOfProductsClicked =
    +locationMetrics[`${SHOP_PROD}.${CUBE_PROPERTY_KEYS.numOfProductsClicked}`];
  const numOfProductFavorites =
    +locationMetrics[`${SHOP_PROD}.${CUBE_PROPERTY_KEYS.numOfProductFavorites}`];
  const numOfOpenStoreScreen =
    +locationMetrics[`${SHOP_PROD}.${CUBE_PROPERTY_KEYS.numOfOpenStoreScreen}`];
  const numOfNavigateToStore =
    +locationMetrics[`${SHOP_PROD}.${CUBE_PROPERTY_KEYS.numOfNavigateToStore}`];
  const numOfFollowedStore =
    +locationMetrics[`${SHOP_PROD}.${CUBE_PROPERTY_KEYS.numOfFollowedStore}`];
  const clickThroughRate = Utils.getPercentage(numOfSessionStarts, numOfImpressions, false, 2);

  return {
    location,
    numOfImpressions,
    numOfSessionStarts,
    numOfVideosWatched,
    numOfProductsClicked,
    numOfProductFavorites,
    numOfOpenStoreScreen,
    numOfNavigateToStore,
    numOfFollowedStore,
    clickThroughRate,
  };
};

export const getShopMetrics = ({ data }: GetShopMetricsProps): ShopMetrics => {
  const shopMetrics = {};

  data.reduce((acc: FormattedLocationMetric, locationMetrics) => {
    const { location, ...formattedMetrics } = getFormattedLocationMetrics(locationMetrics);

    acc[location] = formattedMetrics;

    return acc;
  }, shopMetrics);

  return shopMetrics;
};
