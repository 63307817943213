import React from 'react';
import styled from 'styled-components';
import { FEED_CART_SUBTOTAL_DATA_TYPE } from '../../../../constants/feedPreviewDataTypes.constants';
import HorizontalFlex from '../../flex_layouts/HorizontalFlex';
import VerticalFlex from '../../flex_layouts/VerticalFlex';
import FeedCheckoutButton from 'shared/react/components/complex/feed/feed_buttons/FeedCheckoutButton';
import { FEED_ANALYTICS_LOCATIONS } from 'shared/react/constants/analyticsLocations.constants';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';

const FeedCartFooter = ({ shouldHide, cartTotalPrice, getDisplayedPriceByCents }) => {
  const [, { t }] = useTranslation('feed.cart');

  if (shouldHide) {
    return null;
  }

  return (
    <LayoutRoot>
      <SubtotalContainer>
        <SubtotalText>{t('subtotal')}</SubtotalText>
        <SubtotalValue data-type={FEED_CART_SUBTOTAL_DATA_TYPE}>
          {getDisplayedPriceByCents(cartTotalPrice)}
        </SubtotalValue>
      </SubtotalContainer>
      <FeedCheckoutButton
        buttonText={t('checkout')}
        location={FEED_ANALYTICS_LOCATIONS.feedCartFooter}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  width: 100%;
  box-shadow: 0 3px 34px ${({ theme }) => theme.colors.black15opacity};
  padding: 24px;
  box-sizing: border-box;
  gap: 24px;
  border-radius: 24px 24px 0 0;
`;

const SubtotalContainer = styled(HorizontalFlex)`
  justify-content: space-between;
`;

const SubtotalText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

const SubtotalValue = styled.div`
  font-weight: 800;
  font-size: 16px;
  line-height: 16px;
`;

export default FeedCartFooter;
