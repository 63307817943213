import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { useVodConnection } from 'src/context/VodConnectionStore';
import { useVodConnectionSuggestion } from 'src/context/VodConnectionSuggestionStore';
import { useSelectedApp } from 'src/context/AppsStore';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import { FEATURE_SUGGESTED_PRODUCTS } from 'app/src/constants/appFeatures.constants';
import { vodConnectionSuggestion as VodConnectionSuggestion } from 'src/types/entities';

type ReturnType = {
  isInitialized: boolean;
  shouldShowSuggestions: boolean;
  pendingVodConnectionSuggestions: VodConnectionSuggestion[];
  getSuggestionsByVodAssetId: (vodAssetId: string) => VodConnectionSuggestion[];
};

const PendingVodConnectionSuggestionsContext = createContext(null);

export const usePendingVodConnectionSuggestions = (): ReturnType => {
  return useContext(PendingVodConnectionSuggestionsContext);
};

export const PendingVodConnectionSuggestionsProvider = ({ children }) => {
  const { getFeatureEnabled } = useFeatureActions();
  const [selectedApp] = useSelectedApp();
  const [{ vodConnections }] = useVodConnection();
  const [{ vodConnectionSuggestions, isInitialized }] = useVodConnectionSuggestion();
  const isSuggestedProductsEnabled = getFeatureEnabled(FEATURE_SUGGESTED_PRODUCTS);

  /**
   * Filter out the suggestions that are already tagged
   * and sort them by score
   */
  const pendingVodConnectionSuggestions = useMemo(() => {
    return vodConnectionSuggestions
      .filter(
        suggestion =>
          !vodConnections?.some(
            ({ vodAssetId, externalProductId }) =>
              vodAssetId === suggestion.vodAssetId &&
              externalProductId === suggestion.externalProductId
          )
      )
      .sort((a, b) => b.score - a.score);
  }, [vodConnectionSuggestions, vodConnections]);

  const getSuggestionsByVodAssetId = useCallback(
    (vodAssetId: string) =>
      pendingVodConnectionSuggestions.filter(suggestion => suggestion.vodAssetId === vodAssetId),
    [pendingVodConnectionSuggestions]
  );

  const shouldShowSuggestions =
    isSuggestedProductsEnabled && !!pendingVodConnectionSuggestions.length;

  const value = {
    isInitialized,
    shouldShowSuggestions,
    pendingVodConnectionSuggestions,
    getSuggestionsByVodAssetId,
  };

  return (
    <PendingVodConnectionSuggestionsContext.Provider value={value}>
      {children}
    </PendingVodConnectionSuggestionsContext.Provider>
  );
};
