import React, { useRef } from 'react';
import styled from 'styled-components';

function InputWrapper({
  id,
  onChange,
  capture,
  type,
  disabled,
  value,
  accept,
  visible,
  children,
  ...props
}) {
  const inputRef = useRef(null);

  function handleClick() {
    if (disabled) {
      return;
    }
    inputRef.current.click();
  }

  return (
    <LayoutRoot {...props}>
      <ElementContainer visible={visible} disabled={disabled} onClick={handleClick}>
        {children}
      </ElementContainer>
      <Input ref={inputRef} id={id} value={value} onChange={onChange} type={type} />
    </LayoutRoot>
  );
}

export default InputWrapper;

const LayoutRoot = styled.div`
  display: grid;
  position: relative;
  width: fit-content;
  height: 100%;
`;

const Input = styled.input`
  visibility: collapse;
  overflow: hidden;
  padding: 0;
  border: none;
  width: 100%;
  height: 100%;
  position: absolute;
  background: transparent;
`;

const ElementContainer = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  width: 100%;
  height: 100%;
`;
