import styled, { css } from 'styled-components';

const BasicInput = styled.input`
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  color: ${({ error, theme }) => (error ? theme.colors.red2 : theme.colors.gray2)};
  &::-webkit-input-placeholder {
    color: ${({ error, theme }) => (error ? theme.colors.red1 : theme.colors.gray4)};
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      ::selection {
        background: ${({ theme }) => theme.colors.white};
      }
      ::-moz-selection {
        background: ${({ theme }) => theme.colors.white};
      }
    `}
`;

export default BasicInput;
