import React from 'react';
import AnalyticsEngagementEmptyState from '../empty/AnalyticsEngagementEmptyState';
import AnalyticsEngagementMetrics from './AnalyticsEngagementMetrics';
import AnalyticsEngagementOvertime from './AnalyticsEngagementOvertime';
import AnalyticsEngagementTables from './AnalyticsEngagementTables';

const AnalyticsEngagement = ({ shouldBlur }) => {
  if (shouldBlur) {
    return <AnalyticsEngagementEmptyState />;
  }

  return (
    <>
      <AnalyticsEngagementMetrics />
      <AnalyticsEngagementOvertime />
      <AnalyticsEngagementTables />
    </>
  );
};

export default AnalyticsEngagement;
