import React from 'react';
import AnalyticsMetricsBoxBenchmark from './AnalyticsMetricBoxBenchmark';
import InfoIconWithTooltip from 'app/src/complex_components/InfoIconWithTooltip';
import styled from 'styled-components';
import Tooltip from 'app/src/basic_components/Tooltip';
import Utils from 'app/src/utils';
import { CircularProgress } from '@material-ui/core';
import { DesignSizes } from 'shared/react/theme/DesignSystem';
import { TextBody, TextTiny } from 'shared/react/components/basic/text/TextV2';
import HorizontalFlex, {
  Gap16HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

const AnalyticsMetricBoxFooter = ({ footerText, footerValue, footerPercentage }) => {
  if (!footerText || (isNaN(footerPercentage) && !footerValue)) {
    return null;
  }

  if (!isNaN(footerPercentage)) {
    return (
      <StyledAnalyticsMetricBoxFooter>
        <b>{`${Utils.safeRoundNumber(footerPercentage, 1)}%`}</b> {footerText}
      </StyledAnalyticsMetricBoxFooter>
    );
  }
  return (
    <StyledAnalyticsMetricBoxFooter>
      <b>{footerValue}</b> {footerText}
    </StyledAnalyticsMetricBoxFooter>
  );
};

const AnalyticsMetricBox = ({
  color,
  loading,
  title = 0,
  mainTitle = '',
  subtitle = '',
  footerPercentage,
  footerText,
  footerValue,
  tooltipText,
  titleTooltipText,
  headerTooltipText,
  size,
  benchmarkTitle,
  benchmarkValue,
  category,
}) => {
  return (
    <StyledAnalyticsMetricBox size={size}>
      <AnalyticsMetricBoxMainTitleContainer>
        {mainTitle && (
          <Tooltip text={headerTooltipText || ''} placement="top">
            <AnalyticsMetricBoxMainTitle>{mainTitle}</AnalyticsMetricBoxMainTitle>
          </Tooltip>
        )}
        <AnalyticsMetricsBoxBenchmark
          benchmarkTitle={benchmarkTitle}
          benchmarkValue={benchmarkValue}
          category={category}
          loading={loading}
        />
      </AnalyticsMetricBoxMainTitleContainer>

      <Tooltip text={titleTooltipText || ''} placement="bottom">
        <AnalyticsMetricBoxTitle size={size} color={color}>
          {loading ? <CircularProgress size={24} /> : title}
        </AnalyticsMetricBoxTitle>
      </Tooltip>
      <AnalyticsMetricBoxSubtitleContainer>
        <AnalyticsMetricBoxSubtitle>{subtitle}</AnalyticsMetricBoxSubtitle>
        {tooltipText && <InfoIconWithTooltip title={tooltipText} tooltipPlacement="right" />}
      </AnalyticsMetricBoxSubtitleContainer>
      <AnalyticsMetricBoxFooter
        footerPercentage={footerPercentage}
        footerText={footerText}
        footerValue={footerValue}
      />
    </StyledAnalyticsMetricBox>
  );
};

export default AnalyticsMetricBox;

const StyledAnalyticsMetricBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: ${({ size }) => (size === DesignSizes.SMALL ? '220px' : '260')};
  max-width: ${({ size }) => (size === DesignSizes.SMALL ? '220px' : '260')};
  height: ${({ size }) => (size === DesignSizes.SMALL ? '88px' : '152px')};
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.05), 0 2px 10px 0 rgba(50, 50, 93, 0.1);
  padding: 8px 16px 12px;
  background: ${({ theme }) => theme.colors.white};
`;

const AnalyticsMetricBoxTitle = styled(TextBody)`
  flex-shrink: 0;
  font-weight: bold;
  font-size: ${({ size }) => (size === DesignSizes.SMALL ? '20px' : '30px')};
  line-height: 100%;
  color: ${({ color }) => color};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 110%;

  &::after {
    content: '*';
    visibility: hidden;
  }
`;

const AnalyticsMetricBoxSubtitleContainer = styled(HorizontalFlex)`
  gap: 4px;
`;

const AnalyticsMetricBoxSubtitle = styled(TextBody)`
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.ghostDark};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AnalyticsMetricBoxMainTitleContainer = styled(Gap16HorizontalFlex)`
  align-items: center;
  justify-content: space-between;
`;

const AnalyticsMetricBoxMainTitle = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralDark};
  justify-content: space-between;
  line-height: 18px;
  text-transform: uppercase;
  width: fit-content;
`;

const StyledAnalyticsMetricBoxFooter = styled(TextBody)`
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.ghostDark};
  margin-top: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  & > b {
    color: ${({ theme }) => theme.colors.black};
    font-weight: bold;
  }
`;
