import { publishMethodOptions } from 'app/src/types/entities';
import { ARCHIVE_FOLDER } from 'src/constants/folders';

export const ONSITE_DEFAULT_PROJECT_TYPES = [
  publishMethodOptions.stories,
  publishMethodOptions.carousel,
  publishMethodOptions.bubble,
  publishMethodOptions.embed,
  publishMethodOptions.unassigned,
  publishMethodOptions.hero,
  publishMethodOptions.tile,
];

export const DEFAULT_ONSITE_FOLDER_FILTER = {
  neq: ARCHIVE_FOLDER.id,
};

export const DEFAULT_ONSITE_FILTERS = {
  publishMethod: {
    in: ONSITE_DEFAULT_PROJECT_TYPES,
  },
  folder: DEFAULT_ONSITE_FOLDER_FILTER,
};
