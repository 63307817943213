import React from 'react';
import styled, { useTheme } from 'styled-components';
import XIcon from 'app/src/images/XIcon';
import { creationFlowTitles } from 'app/src/pages/creation_flow/creationFlowConstants';
import { TextBody } from 'shared/react/components/complex/Text';
import BackIcon from 'app/src/images/BackIcon';
import { EXIT_MODAL_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import { Gap16HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { RECORD_SCREEN, RECORD_VIDEO } from 'app/src/constants/creation_method.constants';

const ModalHeader = ({ onClose, clearSelectedMethod, selectedMethod }) => {
  const theme = useTheme();
  const isRecord = [RECORD_VIDEO, RECORD_SCREEN].includes(selectedMethod);

  return (
    <LayoutRoot isRecord={isRecord}>
      {!isRecord && (
        <>
          <IconContainer onClick={clearSelectedMethod}>
            <BackIcon />
          </IconContainer>
          <TextContainer>{creationFlowTitles[selectedMethod]}</TextContainer>
        </>
      )}
      <CloseModal data-test-id={EXIT_MODAL_TEST_ID} onClick={onClose}>
        <XIcon fill={theme.colors.ghostDark} />
      </CloseModal>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16HorizontalFlex)`
  position: ${({ isRecord }) => (isRecord ? 'absolute' : 'relative')};
  right: ${({ isRecord }) => (isRecord ? 0 : 'auto')};
  padding: 16px 24px;
  border-bottom: ${({ isRecord, theme }) => (isRecord ? '0' : `1px solid ${theme.colors.gray3}`)};
  z-index: 3;
`;

const CloseModal = styled(HorizontalFlexWrap)`
  backdrop-filter: blur(5px);
  cursor: pointer;
  border-radius: 50%;
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: none;
  cursor: pointer;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    display: flex;
    align-items: center;
  }
`;

const TextContainer = styled(TextBody)`
  width: auto;
  flex-grow: 1;
  font-weight: 500;
`;

export default ModalHeader;
