import React from 'react';
import { TextBody, TextH3Bold } from 'shared/react/components/basic/text/TextV2';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Cases from 'src/pages/dashboard/components/cases/Cases';
import { CASE_TYPES_COLLECT_UGC } from 'src/pages/dashboard/components/cases/cases.constants';
import { OverflowContainer } from 'src/pages/dashboard/components/page/Page';
import styled from 'styled-components';

const CollectUGC = () => {
  return (
    <OverflowContainer>
      <LayoutRoot>
        <HeaderContainer>
          <TextH3Bold>Collect videos from customers</TextH3Bold>
          <TextBody>
            Effortlessly grow your content library by encouraging real customer videos. Simply add
            to a campaign or flow, offer rewards to boost participation, and collect high-quality
            videos vetted by Tolstoy's content experts and AI.
          </TextBody>
        </HeaderContainer>
        <Cases types={CASE_TYPES_COLLECT_UGC} />
      </LayoutRoot>
    </OverflowContainer>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  padding: 48px;
  gap: 24px;
  align-items: center;
`;

const HeaderContainer = styled(Gap8VerticalFlex)`
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 912px;
`;

export default CollectUGC;
