import { PRODUCT_CTA_TYPES } from 'shared/react/constants/feed.constants';

export const NAME_KEY = 'name';
export const EMAIL_KEY = 'email';
export const PHONE_KEY = 'phone';
export const CONSENT_KEY = 'consent';
export const DEFAULT_COLLECT_TEXT = 'Leave us your details so we can get back to you 👋';
export const SUBTITLES_ENABLED_PROPERTY_KEY = 'subtitlesEnabled';
export const START_SCREEN_TEXT_PROPERTY_KEY = 'startScreenText';
export const FAST_FORWARD_ENABLED_PROPERTY_KEY = 'fastForwardEnabled';
export const AUTOPLAY_ENABLED_PROPERTY_KEY = 'autoplayEnabled';
export const MINIMUM_NUMBER_OF_TILES_PROPERTY_KEY = 'minimumNumberOfTiles';
export const VIDEOS_FROM_LAST_X_DAYS_KEY = 'videosFromLastXDays';

// Carousel
export const CAROUSEL_SHAPE_PROPERTY_KEY = 'carouselShape';
export const CAROUSEL_BORDER_RADIUS_PROPERTY_KEY = 'carouselBorderRadius';
export const CAROUSEL_MOTION_PROPERTY_KEY = 'carouselMotion';
export const CAROUSEL_BORDER_COLOR_PROPERTY_KEY = 'carouselBorderColor';
export const CAROUSEL_BORDER_WIDTH_PROPERTY_KEY = 'carouselBorderWidth';
export const CAROUSEL_BORDER_PROPERTY_KEY = 'carouselBorder';
export const CAROUSEL_FONT_SIZE_PROPERTY_KEY = 'carouselFontSize';
export const CAROUSEL_FONT_COLOR_PROPERTY_KEY = 'carouselFontColor';
export const CAROUSEL_TITLE_PROPERTY_KEY = 'carouselTitle';
export const CAROUSEL_TITLE_TEXT_PROPERTY_KEY = 'carouselTitleText';
export const CAROUSEL_TITLE_FONT_WEIGHT_PROPERTY_KEY = 'carouselTitleFontWeight';
export const CAROUSEL_TITE_NAME_ENABLED_PROPERTY_KEY = 'carouselTileNameEnabled';
export const CAROUSEL_TITE_NAME_LOCATION_PROPERTY_KEY = 'carouselTileNameLocation';
export const CAROUSEL_TITE_NAME_TEXT_SIZE_PROPERTY_KEY = 'carouselTileNameTextSize';
export const CAROUSEL_TITE_NAME_TEXT_COLOR_PROPERTY_KEY = 'carouselTileNameTextColor';
export const CAROUSEL_TITE_NAME_BACKGROUND_ENABLED_PROPERTY_KEY =
  'carouselTileNameBackgroundEnabled';
export const CAROUSEL_TITE_NAME_BACKGROUND_COLOR_PROPERTY_KEY = 'carouselTileNameBackgroundColor';
export const CAROUSEL_TITE_NAME_BACKGROUND_TRANSPARANCY_PROPERTY_KEY =
  'carouselTileNameBackgroundTransparancy';
export const CAROUSEL_PLAY_BUTTON_BACKGROUND_PROPERTY_KEY = 'carouselPlayButtonBackground';
export const CAROUSEL_PLAY_BUTTON_BACKGROUND_COLOR_PROPERTY_KEY =
  'carouselPlayButtonBackgroundColor';
export const CAROUSEL_PLAY_BUTTON_BORDER_PROPERTY_KEY = 'carouselPlayButtonBorder';
export const CAROUSEL_PLAY_BUTTON_BORDER_COLOR_PROPERTY_KEY = 'carouselPlayButtonBorderColor';
export const CAROUSEL_PLAY_BUTTON_TRANSPARENCY_PROPERTY_KEY = 'carouselPlayButtonOpacity';
export const CAROUSEL_PLAY_IN_TILE_FIRST_PROPERTY_KEY = 'carouselPlayInTileFirst';
export const CAROUSEL_ITEM_SIZE_TYPE_PROPERTY_KEY = 'carouselItemSizeType';
export const CAROUSEL_ITEMS_PER_ROW_PROPERTY_KEY = 'carouselItemsPerRow';
export const CAROUSEL_ITEM_HEIGHT_PROPERTY_KEY = 'carouselItemHeight';
export const CAROUSEL_SPACING_HORIZONTAL_PROPERTY_KEY = 'carouselSpacingHorizontal';
export const CAROUSEL_PADDING_HORIZONTAL_PROPERTY_KEY = 'carouselPaddingHorizontal';
export const CAROUSEL_PADDING_VERTICAL_PROPERTY_KEY = 'carouselPaddingVertical';
export const CAROUSEL_TYPE_PROPERTY_KEY = 'carouselType';
export const CAROUSEL_CREATOR_PROFILE_LINK_POSITION_PROPERTY_KEY =
  'carouselCreatorProfileLinkPosition';

// Spotlight Carousel
export const SPOTLIGHT_CAROUSEL_CONTENT_TILE_TYPE_PROPERTY_KEY = 'spotlightCarouselContentTileType';
export const SPOTLIGHT_CAROUSEL_CONTENT_TILE_FALLBACK_PROPERTY_KEY =
  'spotlightCarouselContentTileFallback';
export const SPOTLIGHT_SETTINGS_PROPERTY_KEY = 'spotlightSettings';

// Stories
export const STORIES_BORDER_RADIUS_PROPERTY_KEY = 'storiesBorderRadius';
export const STORIES_BORDER_ENABLED_PROPERTY_KEY = 'storiesBorderEnabled';
export const STORIES_BORDER_WIDTH_PROPERTY_KEY = 'storiesBorderWidth';
export const STORIES_BORDER_COLOR_PROPERTY_KEY = 'storiesBorderColor';
export const STORIES_BORDER_OPACITY_PROPERTY_KEY = 'storiesBorderOpacity';
export const STORIES_BORDER_TYPE_PROPERTY_KEY = 'storiesBorderType';
export const STORIES_LAYOUT_ITEMS_SIZE_TYPE_PROPERTY_KEY = 'storiesItemsSizeType';
export const STORIES_LAYOUT_ITEMS_SHAPE_PROPERTY_KEY = 'storiesItemsShape';
export const STORIES_LAYOUT_ITEMS_PER_ROW_PROPERTY_KEY = 'storiesItemsPerRow';
export const STORIES_LAYOUT_ITEMS_SIZE_PROPERTY_KEY = 'storiesCircleSize';
export const STORIES_LAYOUT_ITEMS_RECTANGLE_SIZE_WIDTH_PROPERTY_KEY = 'storiesRectangleWidth';
export const STORIES_LAYOUT_ITEMS_RECTANGLE_SIZE_HEIGHT_PROPERTY_KEY = 'storiesRectangleHeight';
export const STORIES_LAYOUT_ITEMS_SPACING_PROPERTY_KEY = 'storiesItemsSpacing';
export const STORIES_TITLE_ENABLED_PROPERTY_KEY = 'storiesTitleEnabled';
export const STORIES_TITLE_FONT_SIZE_PROPERTY_KEY = 'storiesTitleFontSize';
export const STORIES_MOBILE_TITLE_FONT_SIZE_PROPERTY_KEY = 'storiesMobileTitleFontSize';
export const STORIES_TITLE_COLOR_PROPERTY_KEY = 'storiesTitleColor';
export const STORIES_TITLE_TEXT_PROPERTY_KEY = 'storiesTitleText';
export const STORIES_TITLE_FONT_WEIGHT_PROPERTY_KEY = 'storiesTitleFontWeight';
export const STORIES_STORY_NAME_ENABLED_PROPERTY_KEY = 'storiesStoryNameEnabled';
export const STORIES_STORY_NAME_TYPE_PROPERTY_KEY = 'storiesStoryNameType';
export const STORIES_STORY_NAME_FONT_WEIGHT_PROPERTY_KEY = 'storiesStoryNameFontWeight';
export const STORIES_STORY_NAME_TEXT_ALIGN_PROPERTY_KEY = 'storiesStoryNameTextAlign';
export const STORIES_STORY_NAME_ALIGNMENT_PROPERTY_KEY = 'storiesStoryNameAlignment';
export const STORIES_STORY_NAME_PADDING_PROPERTY_KEY = 'storiesStoryNamePadding';
export const STORIES_STORY_NAME_FONT_SIZE_PROPERTY_KEY = 'storiesStoryNameFontSize';
export const STORIES_STORY_NAME_COLOR_PROPERTY_KEY = 'storiesStoryNameColor';
export const STORIES_ALIGNMENT_PROPERTY_KEY = 'storiesAlignment';
export const STORIES_ALIGNMENTS = { left: 'left', center: 'center', right: 'right' };
export const STORIES_NAME_ALIGNMENTS = { baseline: 'baseline', center: 'center', end: 'end' };
export const STORIES_MOTION_PROPERTY_KEY = 'storiesMotion';

// Hero settings
export const HERO_CAPTION_PROPERTY_KEY = 'captions';
export const HERO_YOUTUBE_LOOP_PROPERTY_KEY = 'youtubeLoop';
export const HERO_YOUTUBE_AUTOPLAY_PROPERTY_KEY = 'youtubeAutoplay';
export const HERO_DESKTOP_ALIGNMENT = 'desktopAlignment';
export const HERO_MOBILE_ALIGNMENT = 'mobileAlignment';

// Tile
export const TILE_HORIZONTAL_MARGIN_PROPERTY_KEY = 'tileHorizontalMargin';
export const TILE_VERTICAL_MARGIN_PROPERTY_KEY = 'tileVerticalMargin';
export const TILE_BORDER_RADIUS_PROPERTY_KEY = 'tileBorderRadius';
export const TILE_SHOULD_SHOW_CONTROLS_PROPERTY_KEY = 'tileShouldShowControls';
export const TILE_SHOULD_SHOW_CAPTIONS_PROPERTY_KEY = 'tileShouldShowCaptions';
export const TILE_PLAY_BUTTON_HAS_BACKGROUND_PROPERTY_KEY = 'tilePlayButtonHasBackground';
export const TILE_PLAY_BUTTON_BACKGROUND_COLOR_PROPERTY_KEY = 'tilePlayButtonBackgroundColor';
export const TILE_PLAY_BUTTON_BACKGROUND_OPACITY_PROPERTY_KEY = 'tilePlayButtonBackgroundOpacity';
export const TILE_PLAY_BUTTON_HAS_BORDER_PROPERTY_KEY = 'tilePlayButtonHasBorder';
export const TILE_PLAY_BUTTON_BORDER_COLOR_PROPERTY_KEY = 'tilePlayButtonBorderColor';

// Product details
export const PRODUCT_DETAILS_PRICE_ENABLED_PROPERTY_KEY = 'productDetailsPriceEnabled';
export const PRODUCT_DETAILS_CHECKOUT_SHOPPING_BAG_PROPERTY_KEY =
  'productDetailsCheckoutShoppingBagEnabled';
export const PRODUCT_DETAILS_SHIPPING_ENABLED_PROPERTY_KEY = 'productDetailsShippingEnabled';
export const PRODUCT_DETAILS_SHIPPING_TEXT_PROPERTY_KEY = 'productDetailsShippingText';
export const PRODUCT_DETAILS_SHIPPING_FONT_COLOR_PROPERTY_KEY = 'productDetailsShippingFontColor';
export const PRODUCT_DETAILS_SHIPPING_FONT_WEIGHT_PROPERTY_KEY = 'productDetailsShippingFontWeight';
export const PRODUCT_DETAILS_SALES_BADGE_ENABLED_PROPERTY_KEY = 'productDetailsSalesBadgeEnabled';
export const PRODUCT_DETAILS_SALES_BADGE_TEXT_PROPERTY_KEY = 'productDetailsSalesBadgeText';
export const PRODUCT_DETAILS_SALES_BADGE_FONT_COLOR_PROPERTY_KEY =
  'productDetailsSalesBadgeFontColor';
export const PRODUCT_DETAILS_SALES_BADGE_BACKGROUND_COLOR_PROPERTY_KEY =
  'productDetailsSalesBadgeBackgroundColor';
export const PRODUCT_DETAILS_SALES_BADGE_BORDER_ENABLED_PROPERTY_KEY =
  'productDetailsSalesBadgeBorderEnabled';
export const PRODUCT_DETAILS_SALES_BADGE_BORDER_WIDTH_PROPERTY_KEY =
  'productDetailsSalesBadgeBorderWidth';
export const PRODUCT_DETAILS_SALES_BADGE_BORDER_COLOR_PROPERTY_KEY =
  'productDetailsSalesBadgeBorderColor';
export const PRODUCT_DETAILS_STOCK_ENABLED_PROPERTY_KEY = 'productDetailsStockEnabled';
export const PRODUCT_DETAILS_STOCK_IN_COLOR_PROPERTY_KEY = 'productDetailsStockInColor';
export const PRODUCT_DETAILS_STOCK_OUT_COLOR_PROPERTY_KEY = 'productDetailsStockOutColor';
export const PRODUCT_DETAILS_PRODUCT_RECOMMENDATIONS_ENABLED_PROPERTY_KEY =
  'productDetailsRecommendedProductsEnabled';
export const PRODUCT_DETAILS_PRODUCT_RECOMMENDATIONS_TITLE_PROPERTY_KEY =
  'productDetailsRecommendedProductsTitle';
export const PRODUCT_DETAILS_PRODUCT_RECOMMENDATIONS_NAME_ENABLED_PROPERTY_KEY =
  'productDetailsRecommendedProductsNameEnabled';
export const PRODUCT_DETAILS_PRODUCT_RECOMMENDATIONS_PRICE_ENABLED_PROPERTY_KEY =
  'productDetailsRecommendedProductsPriceEnabled';
export const PRODUCT_DETAILS_SUBSCRIPTIONS_ENABLED_PROPERTY_KEY =
  'productDetailsSubscriptionsEnabled';
export const PRODUCT_DETAILS_SUBSCRIPTIONS_HIGHLIGHT_RECURRING_PROPERTY_KEY =
  'productDetailsSubscriptionsHighlightRecurringEnabled';
export const PRODUCT_DETAILS_SUBSCRIPTIONS_DESCRIPTION_LEFT_PROPERTY_KEY =
  'productDetailsSubscriptionsDescriptionLeft';
export const PRODUCT_DETAILS_SUBSCRIPTIONS_DESCRIPTION_RIGHT_PROPERTY_KEY =
  'productDetailsSubscriptionsDescriptionRight';
export const PRODUCT_DESCRIPTION_COLLAPSED_BY_DEFAULT = 'productDescriptionCollapsedByDefault';
export const PRODUCT_DESCRIPTION_ALWAYS_SHOW = 'productDescriptionAlwaysShow';

export const CHAT_LANDING_PAGE_PROPERTY_KEY = 'chatLandingPage';
export const COLLECT_TEXT_PROPERTY_KEY = 'collectText';
export const COLLECT_FIELDS_PROPERTY_KEY = 'collectFields';
export const REQUIRED_PROPERTY_KEY = 'required';
export const COLLECT_AFTER_ANY_RESPONSE_PROPERTY_KEY = 'collectAfterAnyResponse';
export const COLLECT_AFTER_STEP_PROPERTY_KEY = 'collectAfterStep';
export const HIDE_AFTER_SUBMISSION_PROPERTY_KEY = 'cacheLeadFormAnswers';
export const START_KEY = 'start';
export const COLLECT_PROPERTY_KEY = 'collect';
export const LINK_PROPERTY_KEY = 'link';
export const CONSENT_PROPERTY_KEY = 'consent';
export const PLACEHOLDER_PROPERTY_KEY = 'placeholder';
export const NAME_PROPERTY_KEY = 'name';
export const CHAPTERS_ENABLED_PROPERTY_KEY = 'chaptersEnabled';
export const SHOW_BUTTONS_INDEX_PROPERTY_KEY = 'showButtonsIndex';
export const NOTIFICATION_BADGE_PROPERTY_KEY = 'widgetNotificationBadge';
export const BUBBLE_TEXT_ENABLED_PROPERTY_KEY = 'bubbleTextEnabled';
export const BUBBLE_TEXT_PROPERTY_KEY = 'bubbleText';
export const BORDER_PROPERTY_KEY = 'border';
export const WIDGET_BORDER_COLOR_PROPERTY_KEY = 'widgetBorderColor';
export const WIDGET_HEADER_LOCATION_KEY = 'widgetHeaderLocation';
export const SLIDE_ENABLED_PROPERTY_KEY = 'slideEnabled';
export const SLIDE_FROM_PROPERTY_KEY = 'slideFrom';
export const WIDGET_HEADER_PROPERTY_KEY = 'widgetHeader';
export const BUBBLE_MARGIN_BOTTOM_PROPERTY_KEY = 'bubbleMarginBottom';
export const BUBBLE_MARGIN_SIDE_PROPERTY_KEY = 'bubbleMarginSide';
export const THEME_COLOR_PROPERTY_KEY = 'themeColor';
export const THEME_TEXT_COLOR_PROPERTY_KEY = 'themeTextColor';
export const SHAPE_PROPERTY_KEY = 'widgetShape';
export const WIDGET_SIZE_PROPERTY_KEY = 'widgetSize';
export const VERTICAL_ORIENTATION_PROPERTY_KEY = 'verticalOrientation';
export const WIDGET_POSITION_PROPERTY_KEY = 'widgetPosition';
export const POPUP_MODE_PROPERTY_KEY = 'popupMode';
export const KEEP_SETTINGS_ON_MOBILE_PROPERTY_KEY = 'keepSettingsOnMobile';
export const SAVE_SETTINGS_ERROR_MESSAGE = 'Failed to save settings.';
export const SAVE_SETTINGS_SUCCESS_MESSAGE = 'Settings saved successfully.';
export const WIDGET_SETTINGS_CATEGORY_KEY = 'widget-settings';
export const EMBED_CAROUSEL_CATEGORY_KEY = 'embed-carousel-category';
export const STORIES_CATEGORY_KEY = 'stories-category';
export const TILE_CATEGORY_KEY = 'tile-category';
export const VIDEO_PAGE_CATEGORY_KEY = 'video-page-category';
export const EMAIL_SETTINGS_CATEGORY_KEY = 'email-settings';
export const LANDING_PAGE_CATEGORY_KEY = 'landing-page';
export const PLAYER_CATEGORY_KEY = 'player';
export const LEAD_FORM_CATEGORY_KEY = 'lead-form';
export const FEED_CATEGORY_KEY = 'feed';
export const VIDEO_PLAYER_CATEGORY_KEY = 'video-player';
export const GIF_CATEGORY_KEY = 'gif';
export const PRODUCT_DETAILS_CATEGORY_KEY = 'product-details';
export const BRANDING_CATEGORY_KEY = 'branding';
export const HERO_CATEGORY_KEY = 'hero';
export const HERO_OVERLAY_CATEGORY_KEY = 'heroOverlay';
export const FEED_AUTO_SCROLL_PROPERTY_KEY = 'feedAutoScroll';
export const FEED_FULLSCREEN_PROPERTY_KEY = 'isFullscreenFeed';
export const FEED_SHOW_DESCRIPTION_IF_NO_PRODUCTS_PROPERTY_KEY = 'showDescriptionIfNoProducts';
export const FEED_START_ON_MUTE = 'feedStartOnMute';
export const DISABLE_MULTI_PRODUCT_VIEW = 'disableMultiProductView';
export const SHOW_CREATOR_PROFILE_LINK_PROPERTY_KEY = 'showCreatorProfileLink';
export const IS_SHOPPABLE_PROPERTY_KEY = 'isShoppable';
export const HIDE_WISHLIST_PROPERTY_KEY = 'hideWishlist';
export const TARGET_PAGE_PROPERTY_KEY = 'targetPage';
export const EMAIL_UTM_PARAM_KEY = 'utmParam';
export const EMAIL_PAGE_URL = 'customUrl';
export const TILE_ACTION_PROPERTY_KEY = 'tileAction';
export const EMAIL_TV_PAGE_HEADER_ID = 'headerId';
export const EMAIL_FALLBACK_LABEL_ID = 'fallbackLabelId';
export const PDP_CONFIG_KEY = 'pdpConfig';
export const SHOW_POPUP_SETTING = false;
export const THUMBNAIL_EDITOR_MINIMUM_TIME = 3.09;
export const DEFAULT_COLLECT_FIELDS = [
  {
    name: EMAIL_KEY,
    type: 'email',
    required: true,
    collect: true,
    key: EMAIL_KEY,
    backgroundColor: '#FF005C',
  },
  { name: NAME_KEY, type: 'text', required: false, collect: false, key: NAME_KEY },
  { name: PHONE_KEY, type: 'text', required: false, collect: false, key: PHONE_KEY },
  {
    name: CONSENT_KEY,
    type: 'checkbox',
    key: CONSENT_KEY,
    required: true,
    collect: false,
    placeholder: 'In order to continue, please confirm that you accept our {{Terms link}}',
    link: 'http://terms.com',
  },
];
export const EMBED_CAROUSEL_MOTION = {
  static: 'static',
  dynamic: 'dynamic',
  hoverOver: 'hoverOver',
};

export const EMBED_CAROUSEL_ITEM_SIZE_TYPE = {
  responsive: 'responsive',
  fixed: 'fixed',
};

export const WIDGET_HEADER_LOCATIONS = { bottom: 'bottom', center: 'center', top: 'top' };

export const EDITOR_CATEGORIES = [
  GIF_CATEGORY_KEY,
  STORIES_CATEGORY_KEY,
  TILE_CATEGORY_KEY,
  EMBED_CAROUSEL_CATEGORY_KEY,
  VIDEO_PLAYER_CATEGORY_KEY,
  FEED_CATEGORY_KEY,
  PRODUCT_DETAILS_CATEGORY_KEY,
  PLAYER_CATEGORY_KEY,
  WIDGET_SETTINGS_CATEGORY_KEY,
  LANDING_PAGE_CATEGORY_KEY,
  LEAD_FORM_CATEGORY_KEY,
  BRANDING_CATEGORY_KEY,
  HERO_CATEGORY_KEY,
  HERO_OVERLAY_CATEGORY_KEY,
];

export const STORIES_BORDER_TYPES = {
  solid: 'solid',
  dashed: 'dashed',
};

export const EMBED_STORY_MOTION = {
  static: EMBED_CAROUSEL_MOTION.static,
  dynamic: EMBED_CAROUSEL_MOTION.dynamic,
};

export const STORIES_ITEMS_SIZE_TYPES = {
  responsive: 'responsive',
  fixed: 'fixed',
};

export const STORIES_ITEMS_SHAPE_TYPES = {
  circle: 'circle',
  rectangle: 'rectangle',
};

export const CAROUSEL_TILE_NAME_LOCATION_TYPES = {
  under: 'under',
  over: 'over',
};

export const LOOK_AND_FEEL_PREVIEW_WIDTH = 502;
export const LOOK_AND_FEEL_PREVIEW_HORIZONTAL_PADDING = 32;
export const LOOK_AND_FEEL_PREVIEW_CONTENT_WIDTH =
  LOOK_AND_FEEL_PREVIEW_WIDTH - 2 * LOOK_AND_FEEL_PREVIEW_HORIZONTAL_PADDING;
export const DEFAULT_STORIES_ITEMS_SIZE_TYPE = STORIES_ITEMS_SIZE_TYPES.fixed;
export const DEFAULT_STORIES_SIZE = 104;
export const DEFAULT_STORIES_ITEMS_PER_ROW = 4;
export const DEFAULT_STORIES_ITEMS_SPACING = 8;
export const STORIES_RESPONSIVE_TOOLTIP =
  'Automatically show as many items as possible according to carousel width.';
export const STORIES_MIN_BORDER_RADIUS = 0;
export const STORIES_MAX_BORDER_RADIUS = 128;
export const STORIES_MIN_ITEM_FIXED_SIZE = 8;
export const STORIES_ITEM_MAX_FIXED_SIZE = 256;
export const STORIES_MIN_ITEMS_PER_ROW = 1;
export const STORIES_MAX_ITEMS_PER_ROW = 30;
export const STORIES_MIN_SPACING = 0;
export const STORIES_MAX_SPACING = 256;
export const STORIES_DEFAULT_CIRCLE_TYPE_BORDER_RADIUS = 128;
export const STORIES_DEFAULT_RECTANGULAR_TYPE_BORDER_RADIUS = 4;
export const DEFAULT_CAROUSEL_TILE_NAME_LOCATION = CAROUSEL_TILE_NAME_LOCATION_TYPES.under;
export const DEFAULT_CAROUSEL_TILE_NAME_BACKGROUND_TRANSPARENCY = 50;
export const DEFAULT_PRODUCT_DETAILS_SUBSCRIPTIONS_DESCRIPTION_LEFT = 'Best value';
export const DEFAULT_PRODUCT_DETAILS_SUBSCRIPTIONS_DESCRIPTION_RIGHT =
  'Pause or Cancel at Anytime!';
export const DEFAULT_CAROUSEL_ITEMS_PER_ROW = 5;
export const DEFAULT_CAROUSEL_ITEM_HEIGHT = 416;
export const DEFAULT_CAROUSEL_SPACING_HORIZONTAL = 6;
export const DEFAULT_CAROUSEL_PADDING_HORIZONTAL = 0;
export const DEFAULT_CAROUSEL_PADDING_VERTICAL = 0;
export const DEFAULT_HERO_CAPTION_LOCATION = 'center';
export const FONT_WEIGHTS = {
  light: 300,
  normal: 400,
  bold: 700,
};

export const DEFAULT_CTA_BUTTON_TEXT = {
  [PRODUCT_CTA_TYPES.addToCart]: 'Add to cart',
  [PRODUCT_CTA_TYPES.buyNow]: 'Buy now',
  [PRODUCT_CTA_TYPES.openProductPage]: 'Learn more',
  [PRODUCT_CTA_TYPES.customUrl]: 'Custom link',
  [PRODUCT_CTA_TYPES.closePlayer]: 'Close player',
  [PRODUCT_CTA_TYPES.openProductPage]: 'Learn more',
};

export const CAROUSEL_TYPE = {
  DEFAULT: 'default',
  SPOTLIGHT: 'spotlight',
};

export const SPOTLIGHT_CAROUSEL_CONTENT_TILE_TYPE = {
  PRODUCT: 'product',
  VIDEO_NAME: 'videoName',
  NONE: 'none',
};

export const DEAULT_MULTIPLE_PRODUCTS_SETTINGS = {
  divider: {
    enabled: true,
  },
  dividerTitle: {
    enabled: true,
    text: 'Related products',
  },
  ctaSettings: {
    ctaWithVariants: {
      buttonText: '',
    },
    ctaWithoutVariants: {
      buttonText: '',
    },
  },
};

export const CAROUSEL_CREATOR_PROFILE_LINK_POSITION = {
  TOP: 'top',
  BOTTOM: 'bottom',
};

export const STORIES_NAME_LOCATION = {
  below: 'below',
  overlay: 'overlay',
};
