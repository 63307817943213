import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';
import React, { useRef } from 'react';
import styled from 'styled-components';
import ComponentWithLoader from 'app/src/complex_components/ComponentWithLoader';
import { uploadFontFile } from 'app/src/helpers/Font';
import UploadIcon from './UploadIcon';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { LinkButton } from 'shared/react/components/basic/button-v2/BasicButton';
import Tooltip from 'app/src/basic_components/Tooltip';

const UploadFont = ({
  fontSrc,
  uploading,
  onClear,
  onUpdate,
  setFont,
  setUploading,
  loading,
  disabled,
  weight,
}) => {
  const fontInputRef = useRef();
  const [, { setErrorSnackbar }] = useSnackBar();

  const setErrorMessage = text => {
    setErrorSnackbar(text);
  };

  const onUploadFont = async e => {
    const target = e.currentTarget;
    const files = target.files;
    const fonts = [];

    setUploading(true);
    for (const file of files) {
      const res = await uploadFontFile(file, setErrorMessage, weight);
      if (res) {
        fonts.push(res);
      }
    }

    const fontData = fonts.reduce((acc, font) => {
      return { ...acc, ...font, files: { ...acc.files, ...font.files } };
    }, {});

    setUploading(false);
    if (fontData?.family) {
      setFont(fontData);
      onUpdate?.();
    } else {
      target.value = '';
    }
  };

  const onButtonClick = () => {
    if (disabled) {
      return;
    }

    fontInputRef.current.click();
  };

  return (
    <Tooltip text="Upload a font file or select multiple files from the same font family">
      <LinkButton onClick={onButtonClick} disabled={disabled || loading || uploading}>
        <ComponentWithLoader isLoading={uploading}>
          <UploadIcon />
        </ComponentWithLoader>
        <LightTextSmall>Upload</LightTextSmall>
        <Input ref={fontInputRef} type="file" accept=".ttf,.otf" multiple onChange={onUploadFont} />
      </LinkButton>
    </Tooltip>
  );
};

const Input = styled.input`
  display: none;
`;

const LightTextSmall = styled(TextTiny)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary};
`;

export default UploadFont;
