import React from 'react';

const HideIcon = () => {
  return (
    <svg width="26" height="21" viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.2266 19.4062C25.5 19.6016 25.5781 19.9922 25.3438 20.2656C25.1484 20.5391 24.7578 20.5781 24.4844 20.3828L0.734375 1.63281C0.460938 1.4375 0.382812 1.04688 0.617188 0.773438C0.8125 0.5 1.20312 0.421875 1.47656 0.65625L25.2266 19.4062ZM24.1328 11.0078C23.7422 11.9453 23.0391 13.2344 22.0234 14.4844L21.0469 13.7031C21.9844 12.5312 22.6094 11.3984 23 10.5C22.4531 9.25 21.3203 7.375 19.6406 5.8125C17.9609 4.25 15.7344 3 13 3C11.4375 3 10.0312 3.42969 8.82031 4.05469L7.76562 3.19531C9.25 2.33594 10.9688 1.75 13 1.75C16.125 1.75 18.6641 3.19531 20.5 4.91406C22.3359 6.59375 23.5469 8.625 24.1328 10.0312C24.25 10.3438 24.25 10.6953 24.1328 11.0078ZM3.9375 6.55469L4.91406 7.33594C3.97656 8.46875 3.35156 9.64062 2.96094 10.5C3.50781 11.75 4.64062 13.625 6.32031 15.1875C8 16.75 10.2266 18 12.9609 18C14.5234 18 15.9297 17.6094 17.1406 16.9844L18.1953 17.8438C16.7109 18.7031 14.9922 19.25 12.9609 19.25C9.83594 19.25 7.29688 17.8438 5.46094 16.125C3.625 14.4062 2.41406 12.375 1.82812 11.0078C1.71094 10.6953 1.71094 10.3438 1.82812 10.0312C2.21875 9.09375 2.92188 7.80469 3.9375 6.55469ZM7.96094 10.5C7.96094 10.2656 8 10.0312 8.03906 9.79688L9.25 10.7344C9.36719 12.7266 10.9688 14.25 12.9609 14.25C13.1953 14.25 13.4297 14.25 13.625 14.2109L14.8359 15.1484C14.25 15.3828 13.625 15.5 12.9609 15.5C10.2266 15.5 7.96094 13.2734 7.96094 10.5ZM18 10.5C18 10.7734 17.9609 11.0078 17.9219 11.2422L16.7109 10.3047C16.5938 8.3125 14.9922 6.75 12.9609 6.75C12.7656 6.75 12.5312 6.78906 12.3359 6.82812L11.125 5.89062C11.7109 5.65625 12.3359 5.5 12.9609 5.5C15.7344 5.5 18 7.76562 18 10.5Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default HideIcon;
