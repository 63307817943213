import React from 'react';
import styled, { useTheme } from 'styled-components';
import { TextH6, TextSmall } from 'shared/react/components/complex/Text';

function ItemTextValue({ children, placeholder, isTitle, ...props }) {
  const content = children || placeholder;
  const theme = useTheme();

  if (isTitle) {
    return (
      <StyledTextH6 color={children ? theme.colors.black : theme.colors.gray8} {...props}>
        {content}
      </StyledTextH6>
    );
  }
  return (
    <StyledTextSmall color={children ? theme.colors.black : theme.colors.gray8} {...props}>
      {content}
    </StyledTextSmall>
  );
}

export default ItemTextValue;

const StyledTextSmall = styled(TextSmall)`
  color: ${({ color }) => color};
`;
const StyledTextH6 = styled(TextH6)`
  color: ${({ color }) => color};
`;
