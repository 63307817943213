import React, { forwardRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

const BorderedImage = forwardRef(
  (
    { src, borderRadius, borderColor, borderWidth, width, height, placeholderHeight, ...props },
    ref
  ) => {
    const [isLoaded, setLoaded] = useState(false);

    useEffect(() => {
      if (ref.current?.src === src) {
        return;
      }
      setLoaded(false);
    }, [src]);

    function handleLoaded() {
      setLoaded(true);
    }

    return (
      <LayoutRoot
        borderRadius={borderRadius}
        borderColor={borderColor}
        width={width}
        height="fit-content"
        borderWidth={borderWidth}
        {...props}
      >
        <Image
          as="img"
          height={height}
          visible={isLoaded}
          borderRadius={borderRadius}
          src={src}
          onLoad={handleLoaded}
          ref={ref}
        />
        {!isLoaded && (
          <Placeholder borderRadius={borderRadius} placeholderHeight={placeholderHeight || height}>
            <CircularProgress size={20} />
          </Placeholder>
        )}
      </LayoutRoot>
    );
  }
);

BorderedImage.displayName = 'BorderedImage';

export default BorderedImage;

const LayoutRoot = styled.div`
  display: grid;
  border-radius: ${props => props.borderRadius || '0'};
  background-color: ${props => props.borderColor || 'transparent'};
  padding: ${props => props.borderWidth || '0'};
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '100%'};
`;

const BaseView = styled.div`
  width: 100%;
  grid-row: 1;
  grid-column: 1;
  border-radius: ${props => props.borderRadius || '0'};
`;

const Placeholder = styled(BaseView)`
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: ${({ theme }) => theme.colors.gray1};
  height: ${props => props.placeholderHeight || '100%'};
`;

const Image = styled(BaseView)`
  object-fit: cover;
  border-radius: ${props => props.borderRadius || '0'};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`;
