import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import FolderIcon from 'app/src/images/dashboard_v2/FolderIcon';
import { TextH6, TextSmall } from 'shared/react/components/complex/Text';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';
import FolderCardImages from 'app/src/pages/dashboard/pages/libraries/tolstoys_tab/folder_card/FolderCardImages';
import BackIcon from 'app/src/images/BackIcon';
import VerticalMenuIcon from 'app/src/images/dashboard_v2/VerticalMenuIcon';
import FolderMenuItems from 'app/src/pages/dashboard/pages/libraries/folders_v2/FolderMenuItems';
import IconButton from 'app/src/complex_components/IconButton';
import useHasResourcePermissions from 'app/src/hooks/useHasResourcePermissions';
import { track } from 'app/src/helpers/Tracker';

const FolderCard = ({ folder, projects = [], onClick, searchValue }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [ref, setRef] = useState(null);
  const hasMenuPermissions = useHasResourcePermissions(folder);
  const [isMenuOpen, setMenuOpen] = useState(null);

  const handleMenuOpened = e => {
    e.stopPropagation();
    track('Folder Menu Click');
    setMenuOpen(e.currentTarget);
  };

  if (!projects.length && searchValue) {
    return null;
  }

  const onSubtitleClick = e => {
    e.stopPropagation();
    setIsOpen(true);
  };

  const onBackButtonClick = e => {
    e.stopPropagation();
    setIsOpen(false);
  };

  const getContent = () => {
    if (isOpen) {
      return (
        <>
          <BackButtonContainer>
            <BackIconContainer onClick={onBackButtonClick}>
              <BackIcon />
            </BackIconContainer>
          </BackButtonContainer>
          <FolderCardImages currentRef={ref} projects={projects} />
        </>
      );
    }

    return (
      <>
        {hasMenuPermissions && (
          <>
            <StyledIconButton onClick={handleMenuOpened}>
              <VerticalMenuIcon />
            </StyledIconButton>
            <FolderMenuItems isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} folder={folder} />
          </>
        )}
        <FolderTextContainer>
          <EllipsisTextOverflowWrapper>
            <FolderTitle>{folder.name}</FolderTitle>
          </EllipsisTextOverflowWrapper>
          <FolderSubTitle onClick={onSubtitleClick}>{projects.length} Tolstoys</FolderSubTitle>
        </FolderTextContainer>
      </>
    );
  };

  return (
    <LayoutRoot onClick={onClick}>
      <IconContainer ref={setRef}>
        <CustomFolderIcon />
        {getContent()}
      </IconContainer>
    </LayoutRoot>
  );
};

const gridRow = css`
  grid-row: 1;
  grid-column: 1;
`;

const StyledIconButton = styled(IconButton)`
  opacity: 0;
  margin: 40px 8px;
  transition: 0.2s;
  ${gridRow};
  justify-self: flex-end;
`;

const LayoutRoot = styled(Gap16VerticalFlex)`
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    transform: translateY(-3px);
  }

  &:hover ${StyledIconButton} {
    opacity: 1;
  }
`;

const FolderTextContainer = styled(VerticalFlex)`
  gap: 4px;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  ${gridRow};
`;

const BackButtonContainer = styled.div`
  ${gridRow};
  padding: 14px 40px;
  z-index: 2;

  & svg {
    width: 14px;
    height: 12px;
  }
`;

const FolderTitle = styled(TextH6)``;

const FolderSubTitle = styled(TextSmall)`
  transition: 0.3s;
  color: ${({ theme }) => theme.colors.primary};

  &:hover {
    color: ${({ theme }) => theme.colors.primaryHover};
  }
`;

const IconContainer = styled.div`
  display: grid;
`;

const CustomFolderIcon = styled(FolderIcon)`
  width: 100%;
  height: 100%;
  ${gridRow};
`;

const BackIconContainer = styled.div``;

export default FolderCard;
