import React from 'react';
import styled from 'styled-components';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { CircularProgress } from '@material-ui/core';
import BasicInput from 'app/src/basic_components/BasicInput';

const InputWithIcon = ({ className, isLoading, icon, ...props }) => {
  return (
    <InputContainer className={className}>
      <IconContainer>{icon}</IconContainer>
      <Input {...props} />
      {isLoading && <InputLoading size={50} />}
    </InputContainer>
  );
};

const InputContainer = styled.div`
  display: grid;
  width: 100%;

  & > * {
    grid-row: 1;
    grid-column: 1;
  }
`;

const IconContainer = styled(HorizontalFlex)`
  padding: 16px;
  align-items: center;
  height: inherit;
`;

const InputLoading = styled(CircularProgress)`
  justify-self: flex-end;
  display: flex;
  align-self: center;
  padding: 16px;
  height: inherit;
`;

const Input = styled(BasicInput)`
  border: 1px solid ${({ theme }) => theme.colors.gray4};
  width: 100%;
  height: inherit;
  padding: 16px 44px;
  border-radius: 8px;
  font-size: 16px;
`;

export default InputWithIcon;
