import { SOCIAL_PLATFORMS_KEYS } from 'shared/react/constants/share.constants';

export const getSocialPlatformLink = (key, playerUrl) => {
  switch (key) {
    case SOCIAL_PLATFORMS_KEYS.facebook:
      return `https://www.facebook.com/sharer/sharer.php?u=${playerUrl}`;
    case SOCIAL_PLATFORMS_KEYS.twitter:
      return `https://twitter.com/share?url=${playerUrl}`;
    case SOCIAL_PLATFORMS_KEYS.linkedin:
      return `https://www.linkedin.com/shareArticle?mini=true&url=${playerUrl}`;
    case SOCIAL_PLATFORMS_KEYS.whatsapp:
      return `https://wa.me/?text=${playerUrl}`;
  }
};
