import React from 'react';

const OutlinedPlayIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.875 4.625a.659.659 0 0 1 .75 0l4.5 2.75A.75.75 0 0 1 11.5 8a.762.762 0 0 1-.375.656l-4.5 2.75c-.219.125-.531.156-.75 0-.25-.125-.375-.375-.375-.656v-5.5c0-.25.125-.5.375-.625ZM16 8c0 4.438-3.594 8-8 8-4.438 0-8-3.563-8-8 0-4.406 3.563-8 8-8 4.406 0 8 3.594 8 8ZM8 1.5C4.406 1.5 1.5 4.438 1.5 8c0 3.594 2.906 6.5 6.5 6.5 3.563 0 6.5-2.906 6.5-6.5 0-3.563-2.938-6.5-6.5-6.5Z"
        fill="#01BE81"
      />
    </svg>
  );
};

export default OutlinedPlayIcon;
