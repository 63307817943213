import React from 'react';

const CopyToClipboardIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.7656 3.16406C12.9062 3.30469 13 3.49219 13 3.70312V9C13 9.84375 12.3203 10.5 11.5 10.5H7C6.15625 10.5 5.5 9.84375 5.47656 9V3C5.47656 2.17969 6.13281 1.5 6.97656 1.5H10.7969C11.0078 1.5 11.1953 1.59375 11.3359 1.73438L12.7656 3.16406ZM11.875 9H11.8516V4.5H10.75C10.3281 4.5 10 4.17188 10 3.75L9.97656 2.64844H6.97656C6.76562 2.64844 6.60156 2.8125 6.60156 3.02344V9C6.60156 9.21094 6.76562 9.375 6.97656 9.375H11.5C11.6875 9.375 11.875 9.21094 11.875 9ZM7.375 12V11.25H8.5V12C8.5 12.8438 7.82031 13.5 7 13.5H2.5C1.65625 13.5 1 12.8438 1 12L0.976562 6C0.976562 5.17969 1.65625 4.5 2.47656 4.5H4.75V5.64844H2.47656C2.28906 5.64844 2.10156 5.8125 2.10156 6.02344V12C2.10156 12.2109 2.26562 12.375 2.47656 12.375H7C7.1875 12.375 7.375 12.2109 7.375 12Z"
        fill="white"
      />
    </svg>
  );
};

export default CopyToClipboardIcon;
