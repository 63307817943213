import React, { useEffect, useState, FC } from 'react';
import styled from 'styled-components';
import { Checkbox } from '@material-ui/core';
import Utils from 'app/src/utils';
import { TextSmall } from 'shared/react/components/complex/Text';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { PRODUCT_CTA_TYPES } from 'shared/react/constants/feed.constants';
import { DEFAULT_CTA_BUTTON_TEXT } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants.js';
import EditorHeader from '../shared/EditorHeader';
import EditorMainContainer from '../shared/EditorMainContainer';
import EditorTextInputItem from '../shared/EditorTextInputItem';
import EditorSelect from '../shared/EditorSelect';
import OnOffToggleItem from '../shared/OnOffToggleItem';

const INVALID_URL_ERROR_MESSAGE = 'Please enter a valid URL';

const PRODUCT_CTA_NAMES = {
  [PRODUCT_CTA_TYPES.addToCart]: 'Add to cart',
  [PRODUCT_CTA_TYPES.openProductPage]: 'Product page',
  [PRODUCT_CTA_TYPES.buyNow]: 'Buy now',
  [PRODUCT_CTA_TYPES.customUrl]: 'Custom link',
  [PRODUCT_CTA_TYPES.closePlayer]: 'Close player',
};

const PRODUCT_CTA_ITEMS = Object.entries(PRODUCT_CTA_NAMES).map(([id, name]) => ({ id, name }));

type ButtonConfig = {
  enabled: boolean;
  ctaType: string;
  buttonText: string;
  value: string;
  isOpenInANewTab: boolean;
};

type Props = {
  title: string;
  buttonConfig: ButtonConfig;
  onChange?: (buttonConfig: ButtonConfig) => void;
};

const ProductDetailsButtonEditor: FC<Props> = ({ title, buttonConfig, onChange = () => {} }) => {
  const { enabled, ctaType, buttonText, value, isOpenInANewTab } = buttonConfig;
  const isCustomUrl = ctaType === PRODUCT_CTA_TYPES.customUrl;

  const [customUrl, setCustomUrl] = useState(value);
  const [customUrlError, setCustomUrlError] = useState('');

  useEffect(() => {
    if (value === customUrl) {
      return;
    }

    setCustomUrl(value);
  }, [value]);

  const handleOnChange = (updateConfig = {}) => onChange({ ...buttonConfig, ...updateConfig });
  const handleOnToggleChange = () => handleOnChange({ enabled: !enabled });
  const handleOpenInNewTabChange = () => handleOnChange({ isOpenInANewTab: !isOpenInANewTab });
  const handleButtonTextChange = value => handleOnChange({ buttonText: value });
  const handleCtaActionChange = value => {
    const buttonConfigUpdate: Partial<ButtonConfig> = {
      ctaType: value.id,
      buttonText: DEFAULT_CTA_BUTTON_TEXT[value.id] || '',
    };

    handleOnChange(buttonConfigUpdate);
  };
  const handleSetCustomUrl = value => {
    const errorMessage = Utils.isValidUrl(value) ? '' : INVALID_URL_ERROR_MESSAGE;

    setCustomUrlError(errorMessage);
    setCustomUrl(value);

    if (!errorMessage) {
      handleOnChange({ value });
    }
  };

  return (
    <>
      <EditorHeader title={title} isWhite={enabled}>
        <OnOffToggleItem checked={enabled} onChange={handleOnToggleChange} />
      </EditorHeader>
      <EditorMainContainer shouldHide={!enabled}>
        <EditorSelect
          onChange={handleCtaActionChange}
          text="Action"
          value={PRODUCT_CTA_NAMES[ctaType]}
          items={PRODUCT_CTA_ITEMS}
          customHeight={40}
          tooltipText=""
        />
        {isCustomUrl && (
          <>
            <EditorTextInputItem
              placeholder="Custom Url"
              text="Link URL"
              maxWidth="200px"
              value={customUrl}
              onChange={handleSetCustomUrl}
              error={!!customUrlError}
              helperText={customUrlError}
              maxLength={500}
            />
            <CheckBoxLayout>
              <Checkbox
                checked={isOpenInANewTab}
                onChange={handleOpenInNewTabChange}
                style={{ padding: 0 }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <TextSmall>Open in new tab</TextSmall>
            </CheckBoxLayout>
          </>
        )}
        <EditorTextInputItem
          placeholder="Button text"
          text="Text"
          value={buttonText}
          maxWidth="200px"
          onChange={handleButtonTextChange}
        />
      </EditorMainContainer>
    </>
  );
};

const CheckBoxLayout = styled(HorizontalFlex)`
  width: 100%;
  align-items: center;
  gap: 4px;
  max-width: 200px;
  margin-left: auto;
`;

export default ProductDetailsButtonEditor;
