import React from 'react';
import styled, { useTheme } from 'styled-components';
import Button from 'shared/react/components/basic/Button';
import ViewAllIcon from 'app/src/images/ViewAll';
import EcommerceIcon from 'app/src/images/Ecommerce';
import RecruitingIcon from 'app/src/images/Recruiting';
import AppsAndWebsites from 'app/src/images/AppsAndWebsites';
import {
  ALL_ID,
  SAAS_ID,
  ECOMMERCE_ID,
  RECRUITING_ID,
} from 'app/src/constants/readyMade.constants';

const FILTERS = [
  { id: ECOMMERCE_ID, text: 'eCommerce', leftIcon: <EcommerceIcon /> },
  { id: SAAS_ID, text: 'SaaS', leftIcon: <AppsAndWebsites /> },
  { id: RECRUITING_ID, text: 'Recruiting', leftIcon: <RecruitingIcon /> },
  { id: ALL_ID, text: 'View all', leftIcon: <ViewAllIcon /> },
];

const ReadyMadeFilters = ({ isSelected, onFilterClick }) => {
  const theme = useTheme();

  const getBackgroundColor = filter => {
    if (isSelected(filter)) {
      return theme.colors.primaryLight;
    }
    return 'transparent';
  };

  const onButtonClick = filter => {
    if (!isSelected(filter)) {
      onFilterClick(filter);
    }
  };

  return (
    <LayoutRoot>
      {FILTERS.map(({ id, text, leftIcon }) => (
        <FilterItem key={id}>
          <Button
            backgroundColor={getBackgroundColor(id)}
            textColor={theme.colors.black}
            onClick={() => onButtonClick(id)}
            fontSize="12px"
            fontWeight="600"
            borderRadius="20px"
            padding="12px 20px 14px"
            leftIcon={leftIcon}
          >
            {text}
          </Button>
        </FilterItem>
      ))}
    </LayoutRoot>
  );
};

export default ReadyMadeFilters;

const LayoutRoot = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: 4px;
  justify-self: center;
  align-items: center;
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    justify-self: start;
    grid-template-columns: repeat(2, auto);
    overflow-x: scroll;
    width: fit-content;
    max-width: fit-content;
  }
`;

const FilterItem = styled.div`
  width: fit-content;
`;
