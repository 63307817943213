import { useState } from 'react';
import { DesignSettings } from 'src/types/design';
import { useAppActions } from 'src/context/AppsStore';
import { SAVE_IN_DB_APP_TYPES } from 'src/constants/design';
import { useFeatureActions } from 'src/context/FeaturesStore';
import { FEATURE_DESIGN_ACCOUNT_SETTINGS } from 'shared/react/constants/features.constants';

export const useDesignEditor = (defaultDesignSettings: DesignSettings) => {
  const { updateApp } = useAppActions();
  const { updateAccountFeatureSettings } = useFeatureActions();
  const [designSettings, setDesignSettings] = useState(defaultDesignSettings);
  const updateDesignSettings = (update: Partial<DesignSettings>) =>
    setDesignSettings(prev => ({ ...prev, ...update }));

  const saveDesignSettings = ({ apps = [], shouldSaveToAccount = false }) => {
    const { features, ...settings } = designSettings;
    let shouldSaveSettingsToFeature = shouldSaveToAccount;
    const featureToUpdate = {
      [FEATURE_DESIGN_ACCOUNT_SETTINGS]: { features },
    };

    const promises = apps.map(async app => {
      if (!SAVE_IN_DB_APP_TYPES.includes(app.app)) {
        shouldSaveSettingsToFeature = true;
        return;
      }

      const appSettings = JSON.parse(app.settings || '{}');
      const update = {
        settings: JSON.stringify({
          ...appSettings,
          design: settings,
        }),
      };

      return updateApp(app.id, update, { isBatchUpdate: true });
    });

    if (shouldSaveSettingsToFeature) {
      featureToUpdate[FEATURE_DESIGN_ACCOUNT_SETTINGS] = {
        ...featureToUpdate[FEATURE_DESIGN_ACCOUNT_SETTINGS],
        ...settings,
      };
    }

    promises.push(updateAccountFeatureSettings(featureToUpdate));

    return Promise.all(promises);
  };

  return {
    designSettings,
    setDesignSettings,
    saveDesignSettings,
    updateDesignSettings,
  };
};
