import ReadOnlyInput from 'app/src/basic_components/ReadOnlyInput';
import { BILLING_PACKAGE_TEXTS, BILLING_PACKAGE_TYPES } from 'app/src/constants/billings.constants';
import { track } from 'app/src/helpers/Tracker';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { navigateToPlans } from 'app/src/utils/navigation.utils';
import { openChat } from 'app/src/utils/support.utils';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';

const Plan = ({ packageType }) => {
  const history = useHistory();

  const contactSupportLinkData = {
    prelinkText: '',
    linkText: 'Contact support',
    postLinkText: `to change workspace's plan.`,
    onClickLink: () => {
      openChat();
      track(`Support Click`, {
        location: 'Account information',
      });
    },
  };

  const plansPageLinkData = {
    prelinkText: 'Go',
    linkText: 'Plans',
    postLinkText: `to change workspace's plan.`,
    onClickLink: () => {
      navigateToPlans(history, true);
    },
  };

  const packageTypes = {
    [BILLING_PACKAGE_TYPES.enterprise]: {
      text: BILLING_PACKAGE_TEXTS.enterprise,
      linkData: contactSupportLinkData,
    },
    [BILLING_PACKAGE_TYPES.pro]: {
      text: BILLING_PACKAGE_TEXTS.pro,
      linkData: plansPageLinkData,
    },
    [BILLING_PACKAGE_TYPES.business]: {
      text: BILLING_PACKAGE_TEXTS.business,
      linkData: plansPageLinkData,
    },
    [BILLING_PACKAGE_TYPES.free]: {
      text: BILLING_PACKAGE_TEXTS.free,
      linkData: plansPageLinkData,
    },
  };

  const planData = packageTypes[packageType];

  if (!planData) {
    return null;
  }

  const {
    text,
    linkData: { prelinkText, linkText, postLinkText, onClickLink },
  } = planData;

  return (
    <LayoutRoot>
      <StyledReadOnlyInput value={text} />
      <PlanExtraLinkContainer>
        {prelinkText}
        <PlanExtraLink onClick={onClickLink}>{linkText}</PlanExtraLink>
        {postLinkText}
      </PlanExtraLinkContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)``;

const StyledReadOnlyInput = styled(ReadOnlyInput)`
  width: 420px;
`;

const PlanExtraLinkContainer = styled(HorizontalFlexWrap)`
  gap: 4px;
  color: ${({ theme }) => theme.colors.gray36};
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
`;

const PlanExtraLink = styled.span`
  color: ${({ theme }) => theme.colors.blue7};
  cursor: pointer;
`;

export default Plan;
