import React from 'react';
import ChartTooltip from 'app/src/complex_components/analytics/chart-tooltip/ChartTooltip';
import Utils from 'app/src/utils';
import { EmailEventsOvertimeRecord } from 'app/src/services/cube/types/getEmailEventsOvertimeQuery.types';
import { getShortMonthAndDate } from 'app/src/utils/date.utils';
import { useTheme } from 'styled-components';

type TooltipPayload = {
  payload: EmailEventsOvertimeRecord;
};

type Props = {
  active: boolean;
  payload: [TooltipPayload];
  label: string;
};

const AnalyticsEmailOpensTooltip = ({ active, payload, label }: Props) => {
  const theme = useTheme();
  if (!active || !payload?.length) {
    return null;
  }

  const [emailOvertimeData] = payload;
  const { payload: emailOvertime } = emailOvertimeData;

  const metricsByCampaign = Object.entries(emailOvertime?.attributionByCampaignType || {}).sort(
    ([_, a], [__, b]) => b.numOfEmailOpens - a.numOfEmailOpens
  );

  const lineGroups = [
    {
      key: 'total',
      header: 'Total',
      bulletColor: theme.colors.successDark,
      value: Utils.formatNumber({
        value: emailOvertime.numOfEmailOpens,
      }),
      lines: metricsByCampaign.map(([campaignType, metrics]) => ({
        key: campaignType,
        header: campaignType,
        value: Utils.formatNumber({
          value: metrics.numOfEmailOpens,
        }),
      })),
    },
  ];

  return <ChartTooltip label={getShortMonthAndDate(label)} lineGroups={lineGroups} />;
};

export default AnalyticsEmailOpensTooltip;
