import React from 'react';
import styled from 'styled-components';
import { BREAKPOINT_OPTIONS } from 'shared/react/constants/menu.constants';
import Menu from 'shared/react/components/basic/Menu';
import MenuProduct from 'app/src/complex_components/search-products/MenuProduct';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import ProductTags from 'app/src/complex_components/search-products/product-tags/ProductTags';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';

const ProductsSearchMenu = ({
  onClose,
  isOpen,
  getProductComponent,
  handleProductSelected,
  searchProducts,
  searchInputRef,
  selectedProducts,
  onRemoveProduct,
  onClearAll,
  onSelectAll,
  isSingleSelect,
  disabledProductIds,
  disabledTooltipText,
  searchValue,
  selectedTags,
  appUrl,
  onTagClick,
  vodConnections,
}) => {
  const onClick = (product, isSelected) => {
    if (!isSelected || isSingleSelect) {
      handleProductSelected(product);
      return;
    }

    onRemoveProduct(product.productId);
  };

  const hasProducts = !!searchProducts.length;
  return (
    <Menu
      onClose={onClose}
      open={isOpen}
      anchorEl={searchInputRef.current}
      breakpoint={BREAKPOINT_OPTIONS.moveToTop}
    >
      <LayoutRoot width={searchInputRef?.current?.clientWidth} open={isOpen}>
        {!isSingleSelect && hasProducts && (
          <Buttons>
            <TextButton onClick={onSelectAll}>Select all ({searchProducts.length})</TextButton>
            <span>-</span>
            <TextButton onClick={onClearAll}>Clear</TextButton>
          </Buttons>
        )}
        <SearchProductsList hasProducts={hasProducts}>
          {hasProducts && <Header>Products</Header>}
          {searchProducts.map(product => {
            const isSelected = selectedProducts
              .filter(Boolean)
              .find(
                ({ productId }) =>
                  productId === product.productId || productId === product.externalProductId
              );

            return (
              getProductComponent?.(product, onClose) || (
                <MenuProduct
                  key={product.productId}
                  title={product.title}
                  currencySymbol={product.currencySymbol}
                  price={product.price}
                  imageUrl={product.imageUrl}
                  handle={product.handle}
                  onClick={() => onClick(product, isSelected)}
                  isSelected={isSelected}
                  isSingleSelect={isSingleSelect}
                  disabled={disabledProductIds.includes(product.productId)}
                  disabledTooltipText={disabledTooltipText}
                  vodConnectionsCount={product.vodConnectionsCount}
                />
              )
            );
          })}
        </SearchProductsList>
        <ProductTags
          onTagClick={onTagClick}
          searchValue={searchValue}
          selectedTags={selectedTags}
          appUrl={appUrl}
          isSingleSelect={isSingleSelect}
          vodConnections={vodConnections}
        />
      </LayoutRoot>
    </Menu>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)`
  height: fit-content;
  width: ${({ width }) => width}px;
  min-width: fit-content;
  border-radius: 2px 2px 8px 8px;
  z-index: 10;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ open }) =>
    open ? '0px 10px 25px rgba(50, 50, 93, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.05);' : ''};
  padding: 0 0 8px;
`;

const SearchProductsList = styled(Gap8VerticalFlex)`
  max-height: 276px;
  overflow: auto;
  padding: ${({ hasProducts }) => (hasProducts ? '0 0 8px' : '0')};
`;

const Header = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralDarker};
  padding: 0 8px;
`;

const Buttons = styled(HorizontalFlex)`
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  margin: 8px 16px 0;
`;

const TextButton = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`;

export default ProductsSearchMenu;
