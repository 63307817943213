import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FeedPlayer from 'player/src/components/feed/feed_player/FeedPlayer';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import { FontFamilyCss } from 'player/src/components/player/FontFamilyCss';
import SharedUtils from 'shared/react/utils/utils';
import useFeedProducts from 'shared/react/components/complex/context/hooks/useFeedProducts';
import PlayerGlobalModal from 'shared/react/components/complex/PlayerGlobalModal';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';
import FeedSidePanel from 'shared/react/components/complex/feed/FeedSidePanel';
import FeedGlobalStyle from 'player/src/components/feed/FeedGlobalStyle';
import FeedGlobalSnackbar from 'player/src/components/feed/FeedGlobalSnackbar';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';
import { TOLSTOY_CUSTOM_HEADING_FONT_FAMILY } from 'shared/react/constants/playerSettings.constants';
import useFeedState from 'shared/react/components/complex/context/hooks/useFeedState';
import useIsPrincessPolly from 'shared/react/hooks/useIsPrincessPolly';

const Feed = ({ config, className }) => {
  const [, { getProductsData }] = useFeedProducts();
  const [{ stepsMap }] = useProjectConfig();
  const [{ searchControls }] = useFeedState();
  const { steps, subtitlesEnabled } = config;
  const [customFontFamily, setCustomFontFamily] = useState('');
  const [customHeadingFontFamily, setCustomHeadingFontFamily] = useState('');
  const [currentStepKey, setCurrentStepKey] = useState(steps[0].key);
  const [{ direction }] = useTranslation();
  const { isSearchFeedOpen } = searchControls;
  const isPrincessPolly = useIsPrincessPolly();

  useEffect(() => {
    if (!config) {
      return;
    }

    if (isSearchFeedOpen || !stepsMap[currentStepKey]) {
      setCurrentStepKey(steps[0].key);
    }

    const { font: fontCustomization, fontHeading: fontHeadingCustomization } =
      config.design.branding.typography;

    SharedUtils.reloadFont(fontCustomization, setCustomFontFamily);
    SharedUtils.reloadFont(
      fontHeadingCustomization,
      setCustomHeadingFontFamily,
      TOLSTOY_CUSTOM_HEADING_FONT_FAMILY
    );
  }, [config]);

  if (!stepsMap[currentStepKey]) {
    return null;
  }

  const { current: currentStep } = stepsMap[currentStepKey];
  const { product, isMultipleProducts } = getProductsData(currentStep);
  return (
    <LayoutRoot
      direction={direction}
      customFontFamily={customFontFamily}
      customHeadingFontFamily={customHeadingFontFamily}
      className={className}
      $isPrincessPolly={isPrincessPolly}
    >
      <FeedPlayer
        currentStepKey={currentStepKey}
        setCurrentStepKey={setCurrentStepKey}
        stepsMap={stepsMap}
        steps={steps}
        isSubtitlesEnabled={subtitlesEnabled}
      />
      <FeedSidePanel
        product={product}
        currentStepKey={currentStepKey}
        isMultipleProducts={isMultipleProducts}
      />
      <PlayerGlobalModal currentStep={currentStep} />
      <FeedGlobalSnackbar />
      <FeedGlobalStyle
        customFontFamily={customFontFamily}
        customHeadingFontFamily={customHeadingFontFamily}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlexWrap)`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  flex-wrap: nowrap;
  direction: ${({ direction = 'ltr' }) => direction};
  font-family: ${({ customFontFamily }) =>
    customFontFamily ? customFontFamily : 'Nunito Sans, sans-serif'};

  ${FontFamilyCss};

  && * {
    letter-spacing: ${({ $isPrincessPolly }) => ($isPrincessPolly ? '0.05rem' : '')};
  }
`;

export default Feed;
