import React from 'react'

const TolyLogoClose = () => {
  return (
    <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="51" height="51" rx="25.5" fill="#ED2455"/>
            <path d="M35.25 23.3438L26.2969 31.9219C26.0156 32.1562 25.7344 32.25 25.5 32.25C25.2188 32.25 24.9375 32.1562 24.7031 31.9688L15.7031 23.3438C15.2344 22.9219 15.2344 22.1719 15.6562 21.75C16.0781 21.2812 16.8281 21.2812 17.25 21.7031L25.5 29.5781L33.7031 21.7031C34.125 21.2812 34.875 21.2812 35.2969 21.75C35.7188 22.1719 35.7188 22.9219 35.25 23.3438Z" fill="white"/>
    </svg>
  )
}

export default TolyLogoClose