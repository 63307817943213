import React, { useState } from 'react';
import RenameModal from 'app/src/pages/modals/RenameModal';
import { useProjects } from 'app/src/context/ProjectsStore';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { TOLSTOY_RENAME_MODAL_KEY } from 'app/src/constants/modals.constants';
import useIsMounted from 'shared/react/hooks/useIsMounted';

const TolstoyRenameModal = () => {
  const [{ currentProjectId, currentModal }, { setCurrentModal }] = useModal();
  const [{ project }, { updateProject, publishProject }] = useProjects({
    projectId: currentProjectId,
  });
  const [isRenaming, setRenaming] = useState(false);
  const isMounted = useIsMounted();

  const handleCloseRenameModal = () => {
    setCurrentModal('');
  };

  const handleRenameProject = async newName => {
    setRenaming(true);

    const projectCopy = { ...project };
    projectCopy.name = newName;

    const updatedProject = await updateProject(projectCopy);
    if (!isMounted()) {
      return;
    }
    setRenaming(false);
    if (updatedProject) {
      publishProject(updatedProject.id);
      handleCloseRenameModal();
    }
  };

  return (
    <RenameModal
      open={currentModal === TOLSTOY_RENAME_MODAL_KEY}
      placeholder="How would you call this Tolstoy?"
      isRenaming={isRenaming}
      name={project?.name}
      onClose={handleCloseRenameModal}
      onRename={handleRenameProject}
    />
  );
};

export default TolstoyRenameModal;
