import {
  AUDIO_RESPONSE_KEY,
  CALENDLY_RESPONSE_KEY,
  CHAT_RESPONSE_KEY,
  FREE_TEXT_RESPONSE_KEY,
  IMAGE_RESPONSE_KEY,
  LEAD_FORM_RESPONSE_KEY,
  LINK_RESPONSE_KEY,
  PROMO_CODE_RESPONSE_KEY,
  VIDEO_RESPONSE_KEY,
} from 'app/src/constants/tolstoy.constants';
import LeadFormAnswerIcon from 'app/src/images/GoToLeadFormIcon';
import FreeTextAnswerIcon from 'app/src/images/GoToFreeTextIcon';
import OutlinedVideoIcon from 'app/src/images/video_icon/OutlinedVideoIcon';
import AudioResponseAnswerIcon from 'app/src/images/AudioResponseAnswerIcon';
import ImageResponseAnswerIcon from 'app/src/images/ImageResponseAnswerIcon';
import LinkIcon from 'shared/react/images/share/LinkIcon';
import CalendlyIcon from 'app/src/images/calendars/CalendlyIcon';
import PromoCodeIcon from 'app/src/images/PromoCodeIcon';
import ChatResponseIcon from 'app/src/images/ChatResponseIcon';
import React from 'react';

export const getAnswerImage = ({ type, defaultIcon }) => {
  const types = {
    [LEAD_FORM_RESPONSE_KEY]: <LeadFormAnswerIcon />,
    [FREE_TEXT_RESPONSE_KEY]: <FreeTextAnswerIcon />,
    [VIDEO_RESPONSE_KEY]: <OutlinedVideoIcon />,
    [AUDIO_RESPONSE_KEY]: <AudioResponseAnswerIcon />,
    [IMAGE_RESPONSE_KEY]: <ImageResponseAnswerIcon />,
    [LINK_RESPONSE_KEY]: <LinkIcon />,
    [CALENDLY_RESPONSE_KEY]: <CalendlyIcon />,
    [PROMO_CODE_RESPONSE_KEY]: <PromoCodeIcon />,
    [CHAT_RESPONSE_KEY]: <ChatResponseIcon />,
  };
  return types[type] || defaultIcon;
};
