import React from 'react';

const DisconnectIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 10.5C0 4.992 4.453.5 10 .5c5.508 0 10 4.492 10 10 0 5.547-4.492 10-10 10a9.97 9.97 0 0 1-10-10Zm14.492-1.719a1.06 1.06 0 0 0 0-1.523 1.061 1.061 0 0 0-1.523 0L8.75 11.477l-1.758-1.72a1.061 1.061 0 0 0-1.523 0c-.43.43-.43 1.095 0 1.524l2.5 2.5c.43.43 1.093.43 1.523 0l5-5Z"
        fill="#FFDEEB"
      />
      <path
        d="M5.625 10.5c0-.508.39-.938.938-.938h6.875a.95.95 0 0 1 .937.938c0 .547-.43.938-.938.938H6.564c-.547 0-.938-.391-.938-.938Z"
        fill="#E2506D"
      />
    </svg>
  );
};

export default DisconnectIcon;
