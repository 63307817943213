import React from 'react';
import styled, { css } from 'styled-components';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextSmall } from 'shared/react/components/complex/Text';

export enum AddNewVideoButtonModes {
  Regular = 'Regular',
  BigCard = 'BigCard',
}

export type AddNewVideoButtonProps = {
  mode?: AddNewVideoButtonModes;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const AddNewVideoButton = ({
  mode = AddNewVideoButtonModes.Regular,
  onClick,
}: AddNewVideoButtonProps) => {
  return (
    <StyledButton mode={mode} onClick={onClick}>
      <Text mode={mode}>+ New asset</Text>
    </StyledButton>
  );
};

const Text = styled(TextSmall)`
  ${({ mode }) => {
    switch (mode) {
      case AddNewVideoButtonModes.BigCard:
        return css`
          font-size: 12px;
          color: ${({ theme }) => theme.colors.primary};
        `;
      case AddNewVideoButtonModes.Regular:
      default:
        return css`
          color: ${({ theme }) => theme.colors.neutralDarker};
        `;
    }
  }}
`;

const StyledButton = styled(HorizontalFlexCentered)`
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  ${({ mode }) => {
    switch (mode) {
      case AddNewVideoButtonModes.BigCard:
        return css`
          color: ${({ theme }) => theme.colors.primary};
          border: none;
          border-radius: 4px;
          min-width: 168px;
          height: 122px;
          margin: 2px;
          box-shadow: 0px 2px 4px 0px #32325d1a;
        `;
      case AddNewVideoButtonModes.Regular:
      default:
        return css`
          font-size: 12px;
          padding: 9px 12px;
          color: ${({ theme }) => theme.colors.neutralDarker};
          border: 1px solid ${({ theme }) => theme.colors.neutralLight};
          border-radius: 4px;
        `;
    }
  }}
`;

export default AddNewVideoButton;
