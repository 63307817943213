import { useApps } from 'src/context/AppsStore';
import { useProjectActions } from 'src/context/ProjectsStore';

const useUpdateAppGoogleAnalyticsId = () => {
  const [{ selectedApp, selectedAppUrl }, { updateApp }] = useApps();
  const { invalidateProjectsConfigs } = useProjectActions();

  const updateAppGoogleAnalyticsId = async googleAnalyticsID => {
    try {
      const res = await updateApp(selectedApp.id, { googleAnalyticsID });

      if (res) {
        await invalidateProjectsConfigs(selectedAppUrl);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return { updateAppGoogleAnalyticsId };
};

export default useUpdateAppGoogleAnalyticsId;
