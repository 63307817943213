import React from 'react';

const LockIcon = () => {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 5V3.5c0-1.64 1.336-3 3-3 1.64 0 3 1.36 3 3V5h.75c.82 0 1.5.68 1.5 1.5V11c0 .844-.68 1.5-1.5 1.5h-7.5A1.48 1.48 0 0 1 .75 11V6.5c0-.82.656-1.5 1.5-1.5H3Zm1.125 0h3.75V3.5A1.88 1.88 0 0 0 6 1.625 1.866 1.866 0 0 0 4.125 3.5V5Zm-2.25 6c0 .21.164.375.375.375h7.5a.385.385 0 0 0 .375-.375V6.5a.403.403 0 0 0-.375-.375h-7.5a.385.385 0 0 0-.375.375V11Z"
        fill="#3D4FE0"
      />
    </svg>
  );
};

export default LockIcon;
