import React from 'react';
import { useRulesContext } from 'app/src/pages/project/pages/rules_and_triggers/RulesContext';
import styled from 'styled-components';
import { TextSmall } from 'shared/react/components/complex/Text';
import { track } from 'app/src/helpers/Tracker';
import { IDENTIFY_HELP_ARTICLE } from 'app/src/constants/url.constants';

function EmptyState() {
  const { visitorModeEnabled } = useRulesContext();

  function getText() {
    return visitorModeEnabled
      ? "Personalize your viewer's experience based on your website visitors (identified by cookies)."
      : "Personalize your viewer's experience by connecting your user-base with identify (JavaScript code).";
  }

  const handleHelpLinkClick = () => {
    track('Visitor How To Connect');
  };

  return (
    <LayoutRoot>
      <Text>{getText()}</Text>
      <HelpLink
        onClick={handleHelpLinkClick}
        visitorModeEnabled={visitorModeEnabled}
        href={IDENTIFY_HELP_ARTICLE}
        target="_blank"
        rel="noreferrer"
      >
        How to connect.
      </HelpLink>
    </LayoutRoot>
  );
}

export default EmptyState;

const LayoutRoot = styled.div`
  padding: 8px 0 0px;
`;

const Text = styled(TextSmall)`
  font-weight: 600;
  display: inline;
`;

const HelpLink = styled.a`
  padding-left: 2px;
  display: ${({ visitorModeEnabled }) => (visitorModeEnabled ? 'none' : '')};
  text-decoration: none;
  color: ${({ theme }) => theme.colors.blue1};
  font-weight: bold;
  cursor: pointer;
`;
