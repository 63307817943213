import { EVENTS_NAMES } from 'shared/react/constants/events.constants';

const events = {};

export const subscribeEvent = (eventName, callback) => {
  if (!events[eventName]) {
    events[eventName] = [];
  }

  events[eventName].push(callback);
};

export const unsubscribeEvent = (eventName, callback) => {
  if (!events[eventName]) {
    return;
  }

  events[eventName] = events[eventName].filter(event => event !== callback);
};

export const handleEvent = (eventName, event) => {
  if (!events[eventName]) {
    return;
  }

  events[eventName].forEach(callback => callback(event));
};

const onVisibilityChange = event => {
  handleEvent(EVENTS_NAMES.VISIBILITY_CHANGE, event);
};

const handleMessage = event => {
  const eventName = event.data?.eventName || event.data;
  handleEvent(eventName, event);
};

const EVENTS = {
  [EVENTS_NAMES.VISIBILITY_CHANGE]: onVisibilityChange,
};

export const initializeEvents = () => {
  window.addEventListener('message', event => {
    handleMessage(event);
  });

  Object.entries(EVENTS).forEach(([eventName, callback]) => {
    document.addEventListener(eventName, callback);
  });
};

export const removeAllEvents = () => {
  Object.entries(EVENTS).forEach(([eventName, callback]) => {
    document.removeEventListener(eventName, callback);
  });
};
