import React from 'react';
import styled, { useTheme } from 'styled-components';
import Button from 'shared/react/components/basic/Button';
import { useUser } from 'app/src/context/userStore/UserStore';
import { navigateToBilling } from '../../utils/navigation.utils';
import { useHistory } from 'react-router-dom';
import { track } from '../../helpers/Tracker';
import { useBilling } from 'app/src/hooks/useBillings';

const UpgradeButton = ({ analyticsData, onButtonClick }) => {
  const theme = useTheme();
  const history = useHistory();
  const { isFreePackage } = useBilling();
  const [{ account }] = useUser();
  const canStartTrial = isFreePackage && !account?.trialEndAt;

  const onClick = () => {
    track('Upgrade Button Clicked', analyticsData);
    if (onButtonClick) {
      onButtonClick();
      return;
    }

    navigateToBilling(history);
  };

  return (
    <StyledButton
      onClick={onClick}
      borderRadius="20px"
      fontWeight={600}
      padding="5px 10px"
      backgroundColor={theme.colors.success}
      hoverBackgroundColor={`${theme.colors.success}F2`}
      activeBackgroundColor={`${theme.colors.success}E6`}
      textColor={theme.colors.white}
      width="68px"
      fontSize="12px"
    >
      {canStartTrial ? 'Start Free Trial' : 'Upgrade'}
    </StyledButton>
  );
};

export default UpgradeButton;

const StyledButton = styled(Button)`
  line-height: 14px;
`;
