import React from 'react';

const ExitIntent = () => (
  <svg width={44} height={44} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.692 32.77v6.153A3.077 3.077 0 0 1 26.615 42H5.077A3.077 3.077 0 0 1 2 38.923V5.077A3.077 3.077 0 0 1 5.077 2h21.538a3.077 3.077 0 0 1 3.077 3.077v6.154"
      stroke="url(#a)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.46 22H42"
      stroke="url(#b)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.846 15.846 42 22l-6.154 6.154"
      stroke="url(#c)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient id="a" x1={2} y1={42} x2={29.692} y2={42} gradientUnits="userSpaceOnUse">
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
      <linearGradient id="b" x1={20.461} y1={23} x2={41.999} y2={23} gradientUnits="userSpaceOnUse">
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={35.846}
        y1={28.154}
        x2={41.999}
        y2={28.154}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
    </defs>
  </svg>
);

export default ExitIntent;
