import React, { useId } from 'react';
import styled from 'styled-components';
import { Select } from '@material-ui/core';
import { FILTER_DATA_TYPE } from 'app/src/constants/analytics.constants';
import { TextSmall } from 'shared/react/components/basic/text/TextV2';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import ExpanderTopArrowIcon from 'src/images/creation_method_icons/ExpanderTopArrowIcon';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { AnalyticsFilterLabel } from './AnalyticsFilterLabel';

const AnalyticsFilterValue = value => {
  return (
    <Gap8HorizontalFlex data-type={FILTER_DATA_TYPE}>
      <AnalyticsFilterValueText value={value}>{value}</AnalyticsFilterValueText>
    </Gap8HorizontalFlex>
  );
};

const compareItems = ({ props: aProps }, { props: bProps }) => {
  if (aProps.disabled && !bProps.disabled) {
    return 1;
  }
  if (!aProps.disabled && bProps.disabled) {
    return -1;
  }
  return aProps.children.localeCompare(bProps.children);
};

const getSortedItems = children => {
  if (!children || !Array.isArray(children[1])) {
    return children;
  }
  const [first, items] = children;
  const sortedItems = items.sort(compareItems);

  return [first, sortedItems];
};

const AnalyticsFilter = ({
  children,
  className = null,
  getSelectedValue,
  label = null,
  onChange,
  value,
}) => {
  const selectId = useId();
  const handleChange = event => {
    onChange(event.target.value);
  };

  const sortedItems = getSortedItems(children);
  return (
    <Gap8VerticalFlex className={className}>
      <AnalyticsFilterLabel id={selectId}>{label}</AnalyticsFilterLabel>
      <Gap8HorizontalFlex>
        <AnalyticsFilterSelect
          IconComponent={ExpanderTopArrowIcon}
          MenuProps={{
            anchorOrigin: {
              vertical: 20,
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          inputProps={{ id: selectId }}
          disableUnderline
          value={value}
          onChange={handleChange}
          renderValue={value => AnalyticsFilterValue(getSelectedValue(value))}
        >
          {sortedItems}
        </AnalyticsFilterSelect>
      </Gap8HorizontalFlex>
    </Gap8VerticalFlex>
  );
};

export default AnalyticsFilter;

const AnalyticsFilterSelect = styled(Select)`
  .MuiSelect-icon {
    top: calc(50% - 3px);
    transform: rotate(180deg);
    width: 12px;
  }

  .MuiSelect-icon.MuiSelect-iconOpen {
    transform: rotate(0);
  }

  .MuiSelect-select:focus {
    background-color: transparent;
  }

  .MuiSelect-select.MuiSelect-select {
    min-height: 14px;
    padding: 0 20px 0 0;
  }
`;

const AnalyticsFilterValueText = styled(TextSmall)`
  font-weight: 600;
  line-height: 14px;
`;
