import React from 'react';
import TolstoyIcon from 'app/src/images/TolstoyIcon';
import styled from 'styled-components';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

const TolstoyLoader = ({ ...props }) => {
  return (
    <IconContainer {...props}>
      <CustomIcon />
    </IconContainer>
  );
};

const IconContainer = styled(HorizontalFlex)`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  animation: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  z-index: 1;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const CustomIcon = styled(TolstoyIcon)`
  width: inherit;
  height: inherit;
`;

export default TolstoyLoader;
