import React, { ReactElement } from 'react';
import styled, { useTheme } from 'styled-components';
import { TextSmall } from 'shared/react/components/complex/Text';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import ProIcon from 'app/src/images/ProIcon';

type Props = {
  text: string;
  onClick: () => void;
  icon?: ReactElement;
  iconPosition?: 'left' | 'right';
  active?: boolean;
  isProSetting?: boolean;
  underlined?: boolean;
};

function LinkButton({
  active,
  onClick,
  icon,
  iconPosition = 'left',
  text,
  isProSetting,
  underlined,
}: Props) {
  const theme = useTheme();
  return (
    <LayoutRoot $active={active} onClick={onClick} data-test-id={text}>
      {iconPosition === 'left' && icon}
      <TextContainer>
        <StatusText $active={active} $underlined={underlined}>
          {text}
        </StatusText>
        {isProSetting && <ProIcon fill={theme.colors.black} />}
      </TextContainer>
      {iconPosition === 'right' && icon}
    </LayoutRoot>
  );
}

export default LinkButton;

const LayoutRoot = styled.div<{ $active: boolean }>`
  display: flex;
  align-items: center;
  padding: 0;
  gap: 12px;
  border: none;
  background: none;
  cursor: pointer;
  transition: 0.15s;
  flex-shrink: 0;

  &:hover {
    opacity: ${({ $active }) => ($active ? 1 : 0.6)};
  }

  &:focus,
  &:active {
    opacity: ${({ $active }) => ($active ? 1 : 0.4)};
  }
`;

const StatusText = styled(TextSmall)<{ $active: boolean; $underlined: boolean }>`
  color: ${({ $active, theme }) => ($active ? theme.colors.blue7 : '')};
  font-weight: ${({ $active }) => ($active ? 700 : 600)};
  text-decoration: ${({ $underlined }) => ($underlined ? 'underline' : 'none')};
`;

const TextContainer = styled(HorizontalFlexWrap)`
  gap: 4px;
`;
