import { FormControl, InputLabel, Select as MuiSelect } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const Select = ({ children, ...props }) => {
  return (
    <CustomFormControl variant="outlined">
      <InputLabel>{props.label}</InputLabel>
      <CustomSelect {...props}>{children}</CustomSelect>
    </CustomFormControl>
  );
};

const CustomSelect = styled(MuiSelect)``;

const CustomFormControl = styled(FormControl)`
  width: 100%;
`;

export default Select;
