import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getIsFeed } from 'app/src/context/ProjectsStore';
import { PUBLISH_METHODS } from 'shared/react/constants/tolstoy.constants';
import Embed from './embed/Embed';
import Widget from './widget/Widget';
import { PUBLISH_KEY } from 'app/src/constants/tolstoy.constants';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import FloatingPricingBadge from 'app/src/complex_components/billings/badges/FloatingPricingBadge';
import { DASHBOARD_TABLET_MAX_WIDTH } from 'shared/react/utils/mediaQuery';
import Utils from 'app/src/utils';
import ChoosePublishMethod from 'app/src/pages/modals/publish/choose_publish_method/ChoosePublishMethod';
import Gap16HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap16HorizontalFlexWrap';
import BackButton from 'app/src/basic_components/BackButton';
import { TextH5 } from 'shared/react/components/complex/Text';
import { track } from 'app/src/helpers/Tracker';
import { useUser } from 'app/src/context/userStore/UserStore';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';
import PublishVideoPage from './video_page/PublishVideoPage';
import PublishHeroPage from './hero/PublishHeroPage';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextSubtitle } from 'shared/react/components/complex/Text';
import { publishMethodOptions } from 'app/src/types/entities';

let debounceTimeout;

const Publish = ({ type, project, onTabChanged }) => {
  const [live, setLive] = useState(false);
  const [{ account }, { updateAccountSettings }] = useUser();
  const [, { setSnackbar, setErrorSnackbar }] = useSnackBar();
  const [loading, setLoading] = useState(false);
  const tabs = getIsFeed(project)
    ? [publishMethodOptions.bubble, publishMethodOptions.embed, publishMethodOptions.stories]
    : [publishMethodOptions.bubble, publishMethodOptions.embed];

  const onSetLive = async () => {
    await handleSave(null, { live: !live });
    setLive(!live);
  };

  const handleAccountSettingsChanged = async (urls, publishSettings) => {
    const { live } = publishSettings;
    let liveIds = account?.liveIds;
    const isIdIncluded = liveIds?.includes(project.publishId);

    if (!live && isIdIncluded) {
      liveIds = liveIds.filter(id => id !== project.publishId);
      track('Click Set Not Live', { projectId: project.id, publishId: project.publishId });
    } else if (live && !isIdIncluded) {
      liveIds = account.liveIds || [];
      liveIds.push(project.publishId);
      track('Click Set Live', { projectId: project.id, publishId: project.publishId });
    }

    await updateAccountSettings(
      {
        liveIds,
      },
      true
    );
  };

  const handleSave = (e, publishSettings = { live }, settings) => {
    e?.stopPropagation();

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    setLoading(true);

    debounceTimeout = setTimeout(async () => {
      try {
        await handleAccountSettingsChanged(
          settings?.widgetUrls || project.widgetUrls,
          publishSettings
        );
        setSnackbar('Changes have been saved!');
        setLoading(false);
      } catch (err) {
        setErrorSnackbar('There have been an error saving');
      }
    }, 800);
  };

  const getHeader = isV2Ready => {
    const shouldHideHeader = type === PUBLISH_KEY;

    if (shouldHideHeader) {
      return null;
    }

    if (isV2Ready) {
      return (
        <ModalHeader>
          <ModalTitle>Publish</ModalTitle>
        </ModalHeader>
      );
    }

    const shouldHideBack = !tabs.includes(type) || project?.publishMethod;
    const onBackClick = () => onTabChanged(PUBLISH_KEY);
    return (
      <BackButtonContainer>
        <StyledBackButton onClick={onBackClick} hide={shouldHideBack} />
        <PageHeader>{PUBLISH_METHODS[type].text}</PageHeader>
      </BackButtonContainer>
    );
  };

  const getSelectedType = () => {
    switch (type) {
      case PUBLISH_METHODS[publishMethodOptions.carousel].widgetType:
        return (
          <Embed project={project} widgetType={PUBLISH_METHODS[publishMethodOptions.carousel].widgetType} />
        );
      case PUBLISH_METHODS[publishMethodOptions.bubble].widgetType:
        return (
          <Widget
            loading={loading}
            setLoading={setLoading}
            onSetLive={onSetLive}
            project={project}
            live={live}
            setLive={setLive}
          />
        );
      case PUBLISH_METHODS[publishMethodOptions.stories].widgetType:
        return <Embed project={project} widgetType={PUBLISH_METHODS[publishMethodOptions.stories].widgetType} />;
      case PUBLISH_METHODS[publishMethodOptions.embed].widgetType:
        return (
          <Embed project={project} widgetType={PUBLISH_METHODS[publishMethodOptions.embed].widgetType} />
        );
      case PUBLISH_METHODS[publishMethodOptions.videoPage].widgetType:
        return <PublishVideoPage project={project} />;
      case PUBLISH_METHODS[publishMethodOptions.hero].widgetType:
        return <PublishHeroPage project={project} />;
      default:
        return (
          <ChoosePublishMethod
            live={live}
            onSetLive={onSetLive}
            loading={loading}
            project={project}
            onPublishMethodChanged={onTabChanged}
          />
        );
    }
  };

  const setFromAccountSettings = () => {
    const liveIds = account?.liveIds;
    const isLive = liveIds?.includes(project?.publishId);
    setLive(isLive);
  };

  useEffect(() => {
    setFromAccountSettings();
  }, [project, account]);

  const isV2Ready = [PUBLISH_METHODS[publishMethodOptions.hero].widgetType].includes(type);
  const Wrapper = isV2Ready ? V2Wrapper : V1Wrapper;

  return (
    <Wrapper>
      {getHeader(isV2Ready)}
      {getSelectedType()}
      <FloatingPricingBadge
        leftText="Brand your Tolstoy"
        centerText="Customize Tolstoy to align with your brand"
        analyticsData={Utils.getAnalyticsLocationObject('Publish Modal Floating Badge')}
        variant="light"
      />
    </Wrapper>
  );
};

const V1Wrapper = styled(VerticalFlex)`
  padding: 40px;
  gap: 56px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 24px;
  height: 100%;
  overflow: auto;

  @media (${DASHBOARD_TABLET_MAX_WIDTH}) {
    padding: 30px;
  }
`;

const V2Wrapper = styled(VerticalFlex)`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  height: 100%;
  overflow: auto;
`;

const ModalHeader = styled(HorizontalFlex)`
  height: 56px;
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutralLighter};
`;

const ModalTitle = styled(TextSubtitle)`
  font-weight: 500;
`;

const BackButtonContainer = styled(Gap16HorizontalFlexWrap)``;

const StyledBackButton = styled(BackButton)`
  display: ${({ hide }) => (hide ? 'none' : '')};
`;

const PageHeader = styled(TextH5)`
  font-weight: 800;
  text-transform: capitalize;
`;

export default Publish;
