import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Switch } from '@material-ui/core';
import { TextH6 } from 'shared/react/components/complex/Text';
import Button from 'shared/react/components/complex/Button';
import { CircularProgress } from '@material-ui/core';
import SubtitlePart from './SubtitlePart';
import AddNewPartIcon from 'app/src/images/add_new_step.svg';
import { v4 as uuidv4 } from 'uuid';
import { parseInt } from 'lodash';
import {
  SAVE_DATA_TEST_ID,
  SUBTITLES_SECTION_LOADER_DATA_TEST_ID,
} from 'app/src/constants/dataTestIds.constants';

const TIME_REGEX =
  /[0-9]{1,2}:[0-9]{1,2}:[0-9]{1,2}.[0-9]* --> [0-9]{1,2}:[0-9]{1,2}:[0-9]{1,2}.[0-9]*/g;
const CONTENT_SPLIT_REGEX =
  /\s[0-9]*\s[0-9]{1,2}:[0-9]{1,2}:[0-9]{1,2}.[0-9]* --> [0-9]{1,2}:[0-9]{1,2}:[0-9]{1,2}.[0-9]*/g;

const SubtitlesSection = ({
  subtitles,
  setSubtitlesBlob,
  subtitlesEnabled,
  saveSubtitleEnabled,
  onSave,
  loading,
}) => {
  const [subtitleArray, setSubtitleArray] = useState([]);
  const [isSubtitlesEnabled, setSubtitlesEnabled] = useState(subtitlesEnabled);

  const handlePartChanged = (i, text, times) => {
    const arr = [...subtitleArray];
    arr[i] = {
      text,
      times,
      key: uuidv4(),
    };
    setBlobAndParts(arr);
  };

  const handlePartDeleted = i => {
    const arr = [...subtitleArray];
    arr.splice(i, 1);
    setBlobAndParts(arr);
  };

  const addNewPart = () => {
    const arr = [...subtitleArray];
    const element = arr.length ? arr[arr.length - 1] : { times: '00:00:00.000 --> 00:00:00.000' };
    const [, to] = element.times.split(' --> ');

    arr.push({
      text: '',
      times: prepareNewTimes(to),
      key: uuidv4(),
    });
    setSubtitleArray(arr);
  };

  const prepareNewTimes = to => {
    const newFrom = addSecondToTime(to);
    return `${newFrom} --> ${addSecondToTime(newFrom)}`;
  };

  const addSecondToTime = time => {
    let [hours, minutes, seconds] = time.split('.')[0].split(':');

    if (parseInt(seconds) < 59) {
      seconds = `${parseInt(seconds) + 1}`;
    } else {
      seconds = '00';
      minutes = `${parseInt(minutes) + 1}`;
    }

    seconds = seconds.length > 1 ? seconds : `0${seconds}`;
    minutes = minutes.length > 1 ? minutes : `0${minutes}`;

    const milliseconds = time.split('.')[1];

    return `${hours}:${minutes}:${seconds}.${milliseconds}`;
  };

  const setBlobAndParts = arr => {
    const parts = arr.map(({ text, times }, i) => `${i + 1}\n${times}${text}`);
    const subtitles = `WEBVTT\n\n${parts.join('\n')}`;

    const blob = new Blob([subtitles], { type: 'text/plain' });
    setSubtitleArray(arr);
    setSubtitlesBlob(blob);
  };

  const onSetSubtitleEnabledChanged = e => {
    setSubtitlesEnabled(e.target.checked);
    saveSubtitleEnabled(e.target.checked);
  };

  useEffect(() => {
    if (!subtitles) {
      return;
    }
    const textArr = subtitles.split(CONTENT_SPLIT_REGEX);
    textArr.shift();

    const timesArr = [...subtitles.matchAll(TIME_REGEX)];

    const arr = textArr.map((text, i) => {
      return {
        text,
        times: timesArr[i][0],
        key: uuidv4(),
      };
    });

    setSubtitleArray(arr);
  }, [subtitles]);

  return (
    <Container>
      <SubtitlesContainer>
        <TopContainer>
          <TextH6>Edit captions</TextH6>
          <EnableSection>
            <EnableText>Disable / Enable</EnableText>
            <Switch checked={isSubtitlesEnabled} onChange={onSetSubtitleEnabledChanged} />
          </EnableSection>
        </TopContainer>
        {subtitleArray.map(({ text, times, key }, i) => (
          <SubtitlePart
            key={key}
            index={i}
            onPartChanged={handlePartChanged}
            onPartDeleted={handlePartDeleted}
            text={text}
            times={times}
          />
        ))}
        {!subtitles && (
          <StyledCircularProgress data-test-id={SUBTITLES_SECTION_LOADER_DATA_TEST_ID} size={20} />
        )}
        <AddNewPart onClick={addNewPart}>
          Add
          <img width="24px" height="24px" src={AddNewPartIcon} alt="Add Part" />
        </AddNewPart>
      </SubtitlesContainer>
      <Button onClick={onSave} loading={loading} dataTestId={SAVE_DATA_TEST_ID}>
        Save
      </Button>
    </Container>
  );
};

const Container = styled.div``;

const SubtitlesContainer = styled.div`
  background: ${({ theme }) => theme.colors.gray24};
  display: grid;
  gap: 8px;
  padding: 20px;
  border-radius: 16px;
  align-items: center;
  align-self: start;
  align-content: center;
  overflow-y: auto;
  width: 100%;
  margin-bottom: 20px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    grid-row: 2;
    grid-column: 1;
    min-width: 0;
    gap: 12px;
    padding: 0 16px;
    width: 100%;
  }
`;

const TopContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  background: ${({ theme }) => theme.colors.gray24};
  border-radius: 16px;
  margin-bottom: 8px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    padding: 16px;
  }
`;

const EnableSection = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 10px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    padding: 16px;
  }
`;

const EnableText = styled.div`
  font-size: 16px;
`;

const AddNewPart = styled.div`
  width: 30%;
  cursor: pointer;
  align-items: center;
  display: flex;
  gap: 10px;
  font-weight: 600;
  padding: 8px 16px;
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    padding: 16px;
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  margin: 0 auto;
`;

export default SubtitlesSection;
