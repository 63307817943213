import React from 'react';
import styled from 'styled-components';
import EditorTextInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorTextInputItem';
import EditorHeader from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorHeader';
import EditorMainContainer from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorMainContainer';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const AppearanceEditor = () => {
  return (
    <LayoutRoot>
      <EditorHeader title="Appearance" />
      <EditorMainContainer>
        <EditorTextInputItem text="Button text" placeholder="Input text" />
        <EditorTextInputItem text="Calendar link" placeholder="My calendar URL" />
      </EditorMainContainer>
    </LayoutRoot>
  );
};

export default AppearanceEditor;

const LayoutRoot = styled(VerticalFlex)``;
