import React from 'react';
import styled from 'styled-components';
import { milkConditionCss } from 'shared/react/components/clientsCss.constants';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import useIsMilk from 'shared/react/hooks/useIsMilk';

const ProductSizeVariant = ({
  optionPosition,
  handleProductOptionSelected,
  selectedValue,
  values,
  tilesFeature: {
    fontWeight,
    fontSize,
    selectedTextColor,
    selectedBackgroundColor,
    borderSize,
    borderRadius,
  } = {},
}) => {
  const isMilk = useIsMilk();

  const onSizeSelected = size => {
    handleProductOptionSelected(optionPosition, size);
  };

  return (
    <LayoutRoot>
      {values.map(title => {
        return (
          <Container
            key={title}
            fontWeight={fontWeight}
            fontSize={fontSize}
            selectedTextColor={selectedTextColor}
            selectedBackgroundColor={selectedBackgroundColor}
            borderSize={borderSize}
            borderRadius={borderRadius}
            isSelected={title === selectedValue}
            onClick={() => onSizeSelected(title)}
          >
            <Text isMilk={isMilk}>{title}</Text>
          </Container>
        );
      })}
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(78px, 1fr));
  grid-auto-rows: 40px;
  gap: 8px;
`;

const Container = styled(HorizontalFlexCentered)`
  font-weight: ${({ fontWeight }) => fontWeight || 600};
  font-size: ${({ fontSize }) => fontSize || '16px'};
  color: ${({ isSelected, selectedTextColor, theme }) =>
    isSelected ? selectedTextColor || theme.colors.white : theme.colors.black};
  background: ${({ isSelected, selectedBackgroundColor, theme }) =>
    isSelected ? selectedBackgroundColor || theme.colors.black : theme.colors.white};
  border: ${({ borderSize }) => borderSize || 1}px solid ${({ theme }) => theme.colors.black};
  border-radius: ${({ borderRadius }) => borderRadius || 4}px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: ${({ selectedTextColor, theme }) => selectedTextColor || theme.colors.white};
    background: ${({ selectedBackgroundColor, theme }) =>
      theme.getHexOpacity(selectedBackgroundColor || theme.colors.black, 90)};
  }

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    font-size: ${({ fontSize }) => fontSize || '14px'};
  }
`;

const Text = styled.div`
  ${milkConditionCss};
`;

export default ProductSizeVariant;
