import React from 'react';

const PlusIcon = ({ ...props }) => {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.375 1.375a.771.771 0 0 0-.75-.75.755.755 0 0 0-.75.75V4.75H1.5a.755.755 0 0 0-.75.75c0 .422.328.75.75.75h3.375v3.375c0 .422.328.75.75.75.398 0 .75-.328.75-.75V6.25H9.75c.398 0 .75-.328.75-.75a.771.771 0 0 0-.75-.75H6.375V1.375Z"
        fill="#fff"
      />
    </svg>
  );
};

export default PlusIcon;
