import React, { FC } from 'react';
import styled from 'styled-components';
import ShopAppCard from 'app/src/pages/dashboard/pages/shop-app/components/shop-app-card/ShopAppCard';
import GridLoading from 'app/src/pages/dashboard/components/loading/GridLoading';
import useShopItems from '../../hooks/useShopItems';
import { Gap16HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

const ShopAppGrid: FC = () => {
  const items = useShopItems();

  if (!items?.length) {
    return <GridLoading />;
  }

  return (
    <Container>
      {items.map(item => (
        <ShopAppCard key={item.key} item={item} />
      ))}
    </Container>
  );
};

const Container = styled(Gap16HorizontalFlex)`
  flex-wrap: wrap;
  padding: 24px;
`;

export default ShopAppGrid;
