import Routes, { projectTabs } from '../helpers/Routes';
import { WIDGET_SETTINGS_CATEGORY_KEY } from '../pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import {
  prepareBrandingParams,
  preparePublishParam,
  prepareShareParam,
} from '../pages/share/share.utils';

const getOrigin = history => {
  const { origin } = history?.location?.state || {};
  return origin;
};

export const navigateAndSaveOrigin = (history, path, state = {}) => {
  const origin = getOrigin(history);
  history?.push(path, { ...state, origin });
};

export const getEditStepRoute = (projectId, stepId) => {
  return `${Routes.getBuilderV2Route()
    .replace(Routes.getProjectIdParameter(), projectId)
    .replace(Routes.getStepParameter(), stepId)}`;
};

export const navigateWithSaveOrigin = (history, path, state = {}) => {
  const origin = history?.location?.pathname;
  history?.push(path, { ...state, origin });
};

export const tryToNavigateBackToOrigin = history => {
  const origin = getOrigin(history);

  if (!origin) {
    return false;
  }

  history.push(origin);
  return true;
};

export const tryToNavigateToReplyLocation = history => {
  const replyLocation = history?.location?.state?.reply?.location;

  if (!replyLocation) {
    return false;
  }

  history.push(replyLocation);
  return true;
};

export const navigateToEmptyInbox = history => {
  history.push(Routes.getDashboardV2ThreadsRoute(''));
};

export const navigateToAccount = history => {
  history.push(Routes.getMyProfileRoute());
};

export const navigateToIntegrations = history => {
  history.push(Routes.getIntegrationsRoute());
};

export const navigateToVideoInbox = history => {
  history.push(Routes.getVideoInboxBaseRoute());
};

export const navigateToEmptyTolstoy = history => {
  history.push(Routes.getDashboardBaseRoute());
};

export const navigateToRoot = (history, state = {}) => {
  history.push({ pathname: Routes.getRootRoute(), state });
};

export const navigateToLogin = history => {
  history.push(Routes.getLoginRoute());
};

export const navigateToOnboarding = history => {
  history.push(Routes.getOnboardingBaseRoute());
};

export const navigateToPlans = (history, replace) => {
  navigate(history, Routes.getPlansRoute(), replace);
};

export const navigateToBilling = (history, replace) => {
  navigate(history, Routes.getBillingRoute(), replace);
};

const navigate = (history, route, replace) => {
  replace ? history.replace(route) : history.push(route);
};

export const navigateToLibrary = (history, projectId, stepId, reply) => {
  navigateAndSaveOrigin(history, `/library/tolstoy-library/${projectId}/${stepId}`, {
    order: 0,
    reply,
  });
};

export const navigateToTeamMembers = (history, openModal) => {
  history.push({
    pathname: Routes.getTeamRoute(),
    state: {
      openModal,
    },
  });
};

export const getIsAuthRoute = history => {
  return Routes.getAuthRoutes().includes(history?.location?.pathname);
};

export const navigateToShareType = (history, type) => {
  return history.replace({
    pathname: window.location.pathname,
    search: prepareShareParam(type),
  });
};

export const navigateToBranding = (history, projectId, section) => {
  return history.replace({
    pathname: Routes.getBrandingRoute(),
    search: prepareBrandingParams(projectId, section),
    state: {
      previousUrl: history.location.pathname,
    },
  });
};

export const navigateToShareTypeByProjectId = (history, type, projectId) => {
  return history.replace({
    pathname: Routes.getBuilderRoute().replace(Routes.getProjectIdParameter(), projectId),
    search: prepareShareParam(type),
  });
};

export const navigateToPublishType = (history, type) => {
  return history.replace({
    pathname: window.location.pathname,
    search: preparePublishParam(type),
  });
};

export const navigateToDashboard = (history, state) => {
  const dashboardData = {
    pathname: Routes.getDashboardBaseRoute(),
  };

  if (state) {
    dashboardData.state = state;
  }

  return history.push(dashboardData);
};

export const navigateToVideoFiles = history => {
  return history.push({
    pathname: Routes.getVideoFilesBaseRoute(),
  });
};

export const navigateToBuilder = (history, projectId, stepId) => {
  return history.push({
    pathname: `${Routes.getDashboardBaseRoute()}/${projectId}/builder${stepId ? `/${stepId}` : ''}`,
  });
};

export const navigateToInviteInvalidMessage = history => {
  return history.push(Routes.getTolstoyMessageInviteInvalidRoute());
};

export const navigateToProjectBuilder = (history, projectId) => {
  if (projectId) {
    return history.replace({
      pathname: Routes.getBuilderRoute().replace(Routes.getProjectIdParameter(), projectId),
    });
  }

  if (
    Routes.getDashboardWithSlashBaseRoute() === history.location.pathname ||
    Routes.getDashboardBaseRoute() === history.location.pathname
  ) {
    return;
  }

  return history.replace(Routes.getDashboardWithSlashBaseRoute());
};

export const navigateToCreateWorkspaceMessage = history => {
  return history.push(Routes.getTolstoyMessageCreateWorkspaceRoute());
};

export const navigateToStep = (history, projectId, stepId) => {
  return history.push(
    Routes.getBuilderV2Route()
      .replace(Routes.getProjectIdParameter(), projectId)
      .replace(Routes.getStepParameter(), stepId)
  );
};

export const navigateToDesign = (history, projectId, selectedCategory = '') => {
  return history.push({
    pathname: Routes.getProjectTabRoute(projectTabs.design),
    search: '?' + new URLSearchParams({ selectedCategory }),
  });
};

export const navigateToWidgetSettings = (history, projectId) => {
  navigateToDesign(history, projectId, WIDGET_SETTINGS_CATEGORY_KEY);
};

export const navigateToFeedLandingPage = () => {
  window.location.replace('https://tolstoy.webflow.io/marketing/shoppable-feed');
};
