import React, { useState } from 'react';
import styled from 'styled-components';

import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { project as projectType } from 'app/src/types/entities';
import FileInput from 'src/pages/project/pages/hero/components/file-input/FileInput';
import { useVideoById } from 'src/context/VideosStore';
import { STEP_TYPE } from 'src/pages/project/pages/hero/constants';
import { LoadingIndicatorContainer } from 'src/pages/dashboard/components/card/CardImage';
import HeroStepContent, { STEP_CONFIG } from './HeroStepContent';
import { useApps } from 'src/context/AppsStore';
import { useProjectActions } from 'app/src/context/ProjectsStore';

type Props = {
  project: projectType;
  stepType: STEP_TYPE;
  durationLimit: number;
};

const HeroStep = ({ project, stepType, durationLimit }: Props) => {
  const [progress, setProgress] = useState(0);
  const [{ shopify }, { processHeroProject }] = useApps();
  const { publishProject } = useProjectActions();

  const step = project.steps?.items?.find(({ description }) => description === stepType);
  const [video] = useVideoById(step?.videoId);

  const onProgress = progress => {
    setProgress(parseInt(progress || 0));
  };

  const onFileUploaded = async () => {
    setProgress(0);
    if (project.steps?.items?.length === 2 && shopify) {
      processHeroProject(project.id);
    }
    await publishProject(project.id);
  };

  return (
    <FileInputContainer stepType={stepType}>
      {!!progress && <UploadingProgress progress={progress} />}
      <HeroStepContent video={video} stepConfig={STEP_CONFIG[stepType]} />
      <ButtonWrapper>
        <FileInput
          project={project}
          stepType={stepType}
          step={step}
          onProgress={onProgress}
          onFileUploaded={onFileUploaded}
          limit={durationLimit}
        />
      </ButtonWrapper>
    </FileInputContainer>
  );
};

const FileInputContainer = styled(Gap8HorizontalFlex)`
  position: relative;
  height: 80px;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.neutralLight};
  border-radius: 4px;
  padding-right: 24px;
  overflow: hidden;

  & ${LoadingIndicatorContainer} {
    position: static;
    flex-shrink: 0;
    width: ${({ stepType }) => (stepType === STEP_TYPE.desktop ? 140 : 44)}px;
  }
`;

const ButtonWrapper = styled.div`
  margin-left: auto;
`;

const UploadingProgress = styled.div<{ progress: number }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: ${({ progress }) => progress}%;
  background-color: ${({ theme }) => theme.colors.success};
  transition: all 0.3s linear;
  opacity: 0.4;
`;

export default HeroStep;
