import styled from 'styled-components';

export const SHAPE_TYPES = {
  circle: 'circle',
  rectangle: 'rectangle',
};

export const SetLiveContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  gap: 8px;
  padding-bottom: 8px;
`;
