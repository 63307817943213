import { PLAYER_EVENTS } from 'player-events';
import { REACT_APP_ASSETS_OUTPUT, REACT_APP_BASE_URL } from 'player/src/config/player.config';
import {
  CURRENT_PAGE_DB_PRODUCT_ID_URL_PARAM,
  IS_SHOPIFY_STORE,
  IS_TAPCART_URL_PARAM,
  PRODUCT_ID_URL_PARAM,
  SHOPIFY_STORE_URL_PARAM,
  TOLSTOY_VIDEO_ID_URL_PARAM,
} from 'player/src/constants/urls.constants';
import { getShouldContrastColor } from 'shared/react/utils/color';

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const WINDOWS_NAVIGATOR_PLATFORMS = ['Win32', 'Win64', 'Windows', 'WinCE'];

class Utils {
  static getIsEmbed(playerType) {
    return playerType === 'embed';
  }

  static getIsTv(playerType) {
    return playerType === 'tv';
  }

  static getIsAiVideo(uploadType) {
    return uploadType === 'aiVideo';
  }

  static getIsLandingPage() {
    return !window.location.search.includes('host');
  }

  static getParam(param) {
    const params = new URLSearchParams(window.location.search);
    return params.get(param);
  }
  static getPlayerVersion() {
    return this.getParam('pv');
  }

  static getWidgetSessionId() {
    return this.getParam('si');
  }

  static getWidgetAnonymousId() {
    return this.getParam('ai');
  }

  static getResolution() {
    return this.getParam('resolution');
  }

  static getPlayerType() {
    return this.getParam('playerType');
  }

  static getIsAppPreview() {
    return window.location.origin === REACT_APP_BASE_URL;
  }

  static getParentUrl() {
    return this.getParam('url');
  }

  static getName() {
    return this.getParam('name');
  }

  static getEmail() {
    return this.getParam('email');
  }

  static getPhone() {
    return this.getParam('phone');
  }

  static getVodAssetId() {
    return this.getParam('vodAssetId');
  }

  static getReplyProjectId() {
    return this.getParam('replyProjectId');
  }

  static getIsShoppable() {
    return this.getParam('isShoppable') !== 'false';
  }

  static getCurrentPageDbProductId() {
    return this.getParam(CURRENT_PAGE_DB_PRODUCT_ID_URL_PARAM);
  }

  static getProductId() {
    return this.getParam(PRODUCT_ID_URL_PARAM);
  }

  static getIsDebug() {
    return this.getParam('td');
  }

  static getIsDynamic() {
    return this.getParam('isDynamic') === 'true';
  }

  static getShopifyStoreUrl() {
    return this.getParam(SHOPIFY_STORE_URL_PARAM);
  }

  static getPlayerId() {
    return this.getParam('pi');
  }

  static getIsShopifyStore() {
    return this.getParam(IS_SHOPIFY_STORE) === 'true';
  }

  static getIsTapcart() {
    return this.getParam(IS_TAPCART_URL_PARAM) === 'true';
  }

  static getStartVideoId() {
    return this.getParam(TOLSTOY_VIDEO_ID_URL_PARAM);
  }

  static isNullOrUndefined(value) {
    return value === null || value === undefined;
  }

  static isSafari() {
    const params = new URLSearchParams(window.location.search);
    return window.safari !== undefined || params.get('safari') === 'true';
  }

  static isIphone() {
    return navigator.platform === 'iPhone' || navigator.platform === 'iPhone Simulator';
  }

  static isValidEmail(email) {
    return email && !email.endsWith('.dbz') && EMAIL_REGEX.test(String(email).toLowerCase());
  }

  static isFirefox() {
    return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  }

  static isWindows() {
    return WINDOWS_NAVIGATOR_PLATFORMS.some(winPlatform => winPlatform === navigator.platform);
  }

  // Documentation: https://medium.com/@botfather/react-loading-chunk-failed-error-88d0bb75b406
  static componentLoader(lazyComponent, attemptsLeft) {
    attemptsLeft = attemptsLeft || 20;
    return new Promise((resolve, reject) => {
      lazyComponent()
        .then(resolve)
        .catch(() => {
          setTimeout(() => {
            if (attemptsLeft === 1) {
              this.log('Failed to lazy load component.');
              return;
            }
            this.componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
          }, 500);
        });
    });
  }

  static log(message) {
    if (window.Sentry) {
      window.Sentry.captureMessage(message);
      return;
    }

    console.error(message);
  }

  static logError(exception, message) {
    if (!window.Sentry) {
      console.log('error ', exception, message);
      return;
    }

    if (message) {
      window.Sentry.captureException(exception, {
        tags: {
          message: message,
        },
      });
    } else {
      window.Sentry.captureException(exception);
    }
  }

  static async hasPermission(permissionName) {
    try {
      const result = await navigator.permissions.query({ name: permissionName });
      return result?.state === 'granted';
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  static isMobile() {
    return this.isAndroid() || this.isIos();
  }

  static openInNewTab(url) {
    const win = window.open(url, '_blank');
    win.focus();
  }

  static isAndroid() {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('android') > -1; //&& ua.indexOf("mobile");
  }

  static isIos() {
    return (
      /iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    );
  }

  static isChrome() {
    return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  }

  static enforceProtocol(url, protocol = 'https') {
    if (
      url.startsWith('http') ||
      url.startsWith('//') ||
      url.startsWith('tel:') ||
      url.startsWith('mailto:')
    ) {
      return url;
    }
    return `${protocol}://${url}`;
  }

  static addUrlParam(url, paramKey, paramValue) {
    const newUrl = new URL(this.enforceProtocol(url));
    newUrl.searchParams.set(paramKey, paramValue);
    return newUrl.toString();
  }

  static getVisibilityEventNames() {
    let hidden, visibilityChange;
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }
    return {
      hidden,
      visibilityChange,
    };
  }

  static loadScript(src, onload) {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = src;

    if (onload) {
      s.onload = onload;
    }

    const x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
  }

  static getCookieValue = name => {
    return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';
  };

  static sizeInBytesToMb(sizeInBytes) {
    return (sizeInBytes / (1024 * 1024)).toFixed(2);
  }

  static isInnerTheme(theme) {
    return theme === 'inner';
  }

  static uniq(array) {
    return [...new Set(array)];
  }

  static getFromArray(object, path, defaultValue) {
    return path.reduce((obj, key) => obj && obj[key], object) || defaultValue;
  }

  static formatDateMinutes(date) {
    return ('0' + date.getMinutes()).slice(-2);
  }

  static isResponse(value) {
    return [
      PLAYER_EVENTS.audioResponse,
      PLAYER_EVENTS.videoResponse,
      PLAYER_EVENTS.imageResponse,
      PLAYER_EVENTS.collectInfo,
      PLAYER_EVENTS.submitInput,
    ].includes(value);
  }

  static isLightHouse(value) {
    return value.toLocaleLowerCase().includes('chrome-lighthouse');
  }

  static getButtonTextColor({ color, theme, defaultColor }) {
    if (!color) {
      return defaultColor || theme.colors.white;
    }

    return getShouldContrastColor(color) ? theme.colors.white : theme.colors.black;
  }

  static scheduleCallbackToNextRender(callback) {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        callback();
      });
    });
  }

  static getAccountLogoUrl(appKey) {
    return `${REACT_APP_ASSETS_OUTPUT}/public/assets/${appKey}/player-logo.png`;
  }

  static checkIfUrlIsAllowed(url, allowedOrigins) {
    if (!allowedOrigins) {
      return true;
    }

    let urlOrigin;
    try {
      urlOrigin = new URL(url).origin;
    } catch (e) {
      Utils.logError('Failed to parse url', url);
      return false;
    }

    return allowedOrigins.some(allowedOrigin => {
      allowedOrigin = new URL(allowedOrigin).origin;
      return urlOrigin === allowedOrigin;
    });
  }

  static checkIfParentUrlIsAllowed(allowedOrigins) {
    if (!allowedOrigins) {
      return true;
    }

    const parentUrl = Utils.getIsLandingPage() ? window.location.href : document.referrer;

    return Utils.checkIfUrlIsAllowed(parentUrl, allowedOrigins);
  }
}

export default Utils;
