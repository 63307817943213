import BroadcastHub from './BroadcastHub.js';

class EventSubscriber {
  constructor({ shouldIncludeLogs = false, shouldArchivePastEvents = false } = {}) {
    this.broadcastHub = new BroadcastHub();
    this.eventCallbacks = {};
    this.pastEvents = {};
    this.shouldIncludeLogs = shouldIncludeLogs;
    this.shouldArchivePastEvents = shouldArchivePastEvents;
  }

  logMessage(message) {
    if (!this.shouldIncludeLogs) {
      return;
    }

    console.log(message);
  }

  throwError(message) {
    if (!this.shouldIncludeLogs) {
      return;
    }

    throw new Error(message);
  }

  archivePastEvent(message) {
    if (!this.shouldArchivePastEvents) {
      return;
    }

    const { eventName } = message;

    if (!this.pastEvents[eventName]) {
      this.pastEvents[eventName] = [];
    }

    this.pastEvents[eventName].push(message);
  }

  postMessage(message) {
    this.archivePastEvent(message);

    this.broadcastHub.postMessage(message);
  }

  postPastEvents({ eventName, callback }) {
    if (!this.pastEvents[eventName]) {
      return;
    }

    for (const pastEvent of this.pastEvents[eventName]) {
      callback({ data: { ...pastEvent, isPastEvent: true } });
    }
  }

  subscribe({ eventName, callback, shouldIncludePastEvents }) {
    if (!this.eventCallbacks[eventName]) {
      this.eventCallbacks[eventName] = new Set();
    }

    if (this.eventCallbacks[eventName].has(callback)) {
      this.throwError(`Already subscribed to event ${eventName} with this callback`);
      return;
    }

    this.eventCallbacks[eventName].add(callback);

    if (shouldIncludePastEvents) {
      this.postPastEvents({ eventName, callback });
    }
  }

  subscribeMultipleEvents = ({ eventNames, callback, shouldIncludePastEvents }) => {
    for (const eventName of eventNames) {
      this.subscribe({ eventName, callback, shouldIncludePastEvents });
    }
  };

  unsubscribe({ eventName, callback }) {
    if (!this.eventCallbacks[eventName]) {
      this.throwError(`Not subscribed to event ${eventName}`);
      return;
    }

    if (!this.eventCallbacks[eventName].has(callback)) {
      this.throwError('Callback is not subscribed');
      return;
    }

    const isCallbackDeleted = this.eventCallbacks[eventName].delete(callback);
    if (!isCallbackDeleted) {
      this.logMessage(`Not subscribed to event ${eventName} with this callback`);
      return;
    }

    this.logMessage(`Unsubscribed from event ${eventName}`);
  }

  unsubscribeMultipleEvents = ({ eventNames, callback }) => {
    for (const eventName of eventNames) {
      this.unsubscribe({ eventName, callback });
    }
  };

  initListener(eventHandler) {
    this.broadcastHub.addEventListener('message', eventHandler);
  }
}

export default EventSubscriber;
