import { useAccountContext } from 'shared/react/components/complex/context/AccountContext';

const useFeedAccount = () => {
  const account = useAccountContext();

  const actions = {};

  return [account, actions];
};

export default useFeedAccount;
