import React from 'react';

import { useBilling } from 'src/hooks/useBillings';
import { usePricingUsage } from 'src/hooks/pricing';
import { useTeamMembers } from 'src/context/AccountStore';
import { getPriceForExtraViews, getExtraFee } from 'src/utils/pricing';
import { getPaidSeatsCount } from 'src/utils/account';

import { PlansUsageViews } from './PlansUsageViews';
import { PlansUsageBilling } from './PlansUsageBilling';

export const PlansUsage = () => {
  const [teamMembers] = useTeamMembers();
  const paidSeatsCount = getPaidSeatsCount(teamMembers);
  const { maxViews, price, progressPercent, currentMonthPageView } = usePricingUsage();
  const { currentPackage, isShopifySubscription, openBillingPage } = useBilling();

  const extraFee = getExtraFee({
    paidSeatsCount,
    views: currentMonthPageView,
    pricingPackage: currentPackage,
  });

  const priceForExtraViews = getPriceForExtraViews(currentPackage);

  if (!isShopifySubscription) {
    return null;
  }

  return (
    <div className="mb-6 flex items-center gap-5 px-6">
      <PlansUsageViews
        className="flex-1"
        maxViews={maxViews}
        monthViews={currentMonthPageView}
        progressPercent={progressPercent}
        isShopify={isShopifySubscription}
      />
      <PlansUsageBilling
        extraFee={extraFee}
        packagePrice={price}
        priceForExtraViews={priceForExtraViews}
        onBillingClick={openBillingPage}
      />
    </div>
  );
};
