import React from 'react';
import { useBilling } from 'app/src/hooks/useBillings';
import EditorMainContainer from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorMainContainer';
import { useBrandingContext } from '../../../BrandingContext';
import ContactFormEditor from './ContactFormEditor';
import StartScreenEditor from './StartScreenEditor';
import BorderRadiusEditor from './BorderRadiusEditor';
import DisplayIconsEditor from './DisplayIconsEditor';
import BrandingExpander from 'app/src/pages/account/branding/editors/BrandingExpander';

type Props = {
  settings: any;
  features?: any;
  defaultSettings: any;
  disabled?: boolean;
  onChange?: any;
};

const BranchingEditor = ({
  settings = {},
  features = {},
  defaultSettings = {},
  onChange = () => {},
}: Props) => {
  const { isFreePackage } = useBilling();
  const { loading } = useBrandingContext();
  const isStartScreenCustomizationDisabled = isFreePackage || loading;
  const isButtonsCustomizationDisabled = loading;

  const updateSettings = update => onChange({ ...settings, ...update });
  const onStartScreenTextChange = startScreenText => updateSettings({ startScreenText });
  const onBorderRadiusChange = borderRadius => updateSettings({ borderRadius });
  const onDisplayIconsChange = icons => updateSettings({ icons });
  const onContactFormChange = contactForm => updateSettings({ contactForm });

  return (
    <BrandingExpander title="Branching cta's">
      <EditorMainContainer>
        <StartScreenEditor
          text={settings.startScreenText}
          defaultText={defaultSettings.startScreenText}
          disabled={isStartScreenCustomizationDisabled}
          onChange={onStartScreenTextChange}
        />
        <BorderRadiusEditor
          radius={settings.borderRadius}
          disabled={isButtonsCustomizationDisabled}
          onChange={onBorderRadiusChange}
        />
        <DisplayIconsEditor
          settings={settings.icons}
          defaultSettings={defaultSettings.icons}
          disabled={isButtonsCustomizationDisabled}
          onChange={onDisplayIconsChange}
        />
        <ContactFormEditor
          settings={settings.contactForm}
          defaultSettings={defaultSettings.contactForm}
          disabled={loading}
          onChange={onContactFormChange}
        />
      </EditorMainContainer>
    </BrandingExpander>
  );
};

export default BranchingEditor;
