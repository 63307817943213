import React from 'react';

const AllTeammatesIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="8" fill="#F4F5F7" />
      <path
        d="M15.973 17.5a2.824 2.824 0 0 0 2.843-2.844 2.842 2.842 0 0 0-2.843-2.844 2.86 2.86 0 0 0-2.844 2.844 2.842 2.842 0 0 0 2.844 2.844Zm1.367.875h-2.707c-2.16 0-3.883 1.64-3.883 3.664 0 .41.328.711.766.711h8.941c.438 0 .793-.3.793-.71 0-2.024-1.75-3.665-3.91-3.665Zm3.91-5.25a2.176 2.176 0 0 0 2.188-2.188A2.194 2.194 0 0 0 21.25 8.75c-1.23 0-2.188.984-2.188 2.188a2.16 2.16 0 0 0 2.188 2.187Zm-8.996 1.531c0-.136.027-.273.055-.41a2.022 2.022 0 0 0-.985-.246H9.656c-1.34 0-2.406 1.148-2.406 2.57 0 .274.191.492.465.492h5.469a3.564 3.564 0 0 1-.93-2.406Zm-1.504-1.531a2.176 2.176 0 0 0 2.188-2.188A2.194 2.194 0 0 0 10.75 8.75c-1.23 0-2.188.984-2.188 2.188a2.16 2.16 0 0 0 2.188 2.187ZM22.316 14h-1.668c-.355 0-.683.11-.984.246 0 .137.027.274.027.41 0 .93-.328 1.778-.902 2.406h5.469a.49.49 0 0 0 .492-.492c0-1.422-1.094-2.57-2.434-2.57Z"
        fill="#2F3137"
      />
    </svg>
  );
};

export default AllTeammatesIcon;
