import GoToNextAnswerIcon from 'app/src/images/GoToNextAnswerIcon';
import React from 'react';

export const FIELD_KEYS = [
  { label: 'Tolstoy field', key: 'tolstoyField', width: 239 },
  { label: 'Direction', key: 'direction', width: 87, defaultText: <GoToNextAnswerIcon key={1} /> },
  { label: 'Hubspot field', key: 'hubspotField', width: 239 },
  { label: 'Mapping type', key: 'mappingType', width: 156, defaultText: 'Custom' },
  { label: '', key: 'enabled', width: 138 },
];
