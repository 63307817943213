import React from 'react';

const LinkIcon = ({ fill, darkBackground }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.002 19.722c5.369 0 9.722-4.353 9.722-9.722C19.724 4.63 15.37.278 10 .278 4.633.278.28 4.63.28 10c0 5.37 4.353 9.722 9.723 9.722Z"
        fill={fill || '#FFFFFF'}
      />
      <path
        d="M13.082 11.417c-.41 0-.73.341-.73.729v2.187H5.79v-6.54h2.188a.75.75 0 0 0 .729-.729.734.734 0 0 0-.73-.729H5.79a1.44 1.44 0 0 0-1.458 1.459v6.54c0 .82.638 1.458 1.458 1.458h6.563c.797 0 1.458-.638 1.458-1.459v-2.187a.75.75 0 0 0-.729-.73Zm2.689-7.064a.732.732 0 0 0-.502-.228h-3.645c-.41 0-.73.342-.73.73 0 .41.32.728.707.728h1.891l-5.104 5.173c-.273.296-.273.752 0 1.025a.73.73 0 0 0 1.048 0l5.104-5.15V8.5c0 .41.32.73.73.73.387 0 .729-.32.729-.73V4.854a.732.732 0 0 0-.228-.501Z"
        fill={darkBackground ? '#FFFFFF' : '#2F3137'}
      />
    </svg>
  );
};

export default LinkIcon;
