import React from 'react';

const ItalicIcon = ({ fill = '#7D8087' }) => {
  return (
    <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.5 1.188A.94.94 0 0 0 8.562.25H4.439a.925.925 0 0 0-.938.938c0 .539.398.937.938.937h.914l-2.766 6.75H1.438a.925.925 0 0 0-.938.938c0 .539.398.937.938.937h4.125a.925.925 0 0 0 .937-.938.94.94 0 0 0-.938-.937h-.937l2.766-6.75h1.171a.925.925 0 0 0 .938-.938Z"
        fill={fill}
      />
    </svg>
  );
};

export default ItalicIcon;
