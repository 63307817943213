import React from 'react';
import VideoSpotlightWithHeader from 'src/complex_components/VideoSpotlightWithHeader';
import { OFFICE_PETS_VIDEO_URLS } from 'src/constants/videoUrls.constants';
import useAccountOnboardingTemplates from '../hooks/useAccountOnboardingTemplates';

const OnboardingTemplatesEnd = () => {
  const { getStageProps } = useAccountOnboardingTemplates();

  const { subheader } = getStageProps();
  return (
    <VideoSpotlightWithHeader
      header="You did it!"
      subheader={subheader}
      videoSubheader="You made Cello so happy!"
      videoSrc={OFFICE_PETS_VIDEO_URLS.celloHappyOnTheCouch}
    />
  );
};

export default OnboardingTemplatesEnd;
