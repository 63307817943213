import GridLoading from 'app/src/pages/dashboard/components/loading/GridLoading';
import { GridContainer } from 'app/src/pages/dashboard/components/page/Page';
import VideosEmptyState from 'app/src/pages/dashboard/pages/videos/components/empty-states/VideosEmptyState';
import { VideosPageVodAsset } from 'app/src/pages/dashboard/pages/videos/types/videos.types';
import { WhereCondition } from 'app/src/pages/dashboard/utils/filterEntities';
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { AutoSizer, CellMeasurer, Grid, CellMeasurerCache } from 'react-virtualized';
import { AnalyticsDataProps } from 'src/types/common/common';
import styled from 'styled-components';
import VideoCard from '../video_card/VideoCard';
import { PendingVodConnectionSuggestionsProvider } from 'src/hooks/vod-connection-suggestions/PendingVodConnectionsContext';
import Spinner from 'app/src/basic_components/Spinner';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

type Props = {
  items?: VideosPageVodAsset[];
  selectedItems?: string[];
  toggleSelected?: (id: string) => void;
  isLoading?: boolean;
  initialized: boolean;
  onSelectedVideosAction?: (video?: VideosPageVodAsset[]) => void;
  method?: string;
  videosFilters?: WhereCondition;
  onVideoChoose?: (video: VideosPageVodAsset) => void;
  isDisableCardHoverState?: boolean;
  analyticsData?: AnalyticsDataProps;
  isAddTagProductsTooltipToFirstCard?: boolean;
  isAddTagLabelsTooltipToFirstCard?: boolean;
  onScrollEndCallback?: () => void;
  isSelectionMode?: boolean;
};

const VideoGrid = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const [scrollTop, setScrollTop] = useState(0);
  const {
    items,
    selectedItems,
    toggleSelected,
    initialized,
    onSelectedVideosAction,
    method,
    videosFilters,
    onVideoChoose,
    isDisableCardHoverState,
    analyticsData,
    isAddTagProductsTooltipToFirstCard,
    isAddTagLabelsTooltipToFirstCard,
    onScrollEndCallback,
    isSelectionMode,
    isLoading,
    ...rest
  } = props;
  const gapSize = 12;
  const cache = new CellMeasurerCache({
    defaultHeight: 296,
    minHeight: 272,
    fixedHeight: true,
    fixedWidth: false,
  });

  const columnBreakpoints = [
    { width: 1600, columns: 6 },
    { width: 1320, columns: 5 },
    { width: 992, columns: 4 },
    { width: 768, columns: 3 },
    { width: 576, columns: 2 },
    { width: 0, columns: 1 },
  ];

  const getColumnData = containerWidth => {
    const currentColumnBreakpoint = columnBreakpoints.find(
      columnBreakpoint => containerWidth > columnBreakpoint.width
    );
    const numberOfColumns = currentColumnBreakpoint?.columns || 1;

    const totalGapSize = (numberOfColumns - 1) * gapSize;
    const cellWidth = (containerWidth - totalGapSize) / numberOfColumns;
    return { columnCount: numberOfColumns, cellWidth };
  };

  const handleScroll = ({ clientHeight, scrollHeight, scrollTop }) => {
    setScrollTop(scrollTop);

    if (scrollTop + clientHeight >= scrollHeight - 20) {
      if (onScrollEndCallback) {
        onScrollEndCallback();
      }
    }
  };

  const gridRef = useRef();

  const cellRenderer = ({ columnIndex, key, rowIndex, style, parent }) => {
    const { width } = parent.props;
    const { columnCount } = getColumnData(width);
    const videoIndex = rowIndex * columnCount + columnIndex;
    const video = items[videoIndex];

    if (!video) {
      return null;
    }

    let innerStyle = {
      margin: gapSize,
    };

    if (columnIndex === columnCount - 1) {
      innerStyle = {
        ...innerStyle,
        marginRight: 0,
      };
    }

    return (
      <CellMeasurer
        cache={cache}
        columnIndex={columnIndex}
        key={key}
        parent={parent}
        rowIndex={rowIndex}
      >
        <div style={style}>
          <div style={innerStyle}>
            <VideoCard
              key={video.id}
              video={video}
              method={method}
              toggleSelected={toggleSelected}
              isSelected={selectedItems?.includes(video.id)}
              isSelectionMode={isSelectionMode || !!selectedItems?.length}
              onSelectedVideosAction={onSelectedVideosAction}
              onVideoChoose={onVideoChoose}
              isDisableCardHoverState={isDisableCardHoverState}
              analyticsData={analyticsData}
              isTagProductsTooltipShown={isAddTagProductsTooltipToFirstCard && videoIndex === 0}
              isTagLabelsTooltipShown={isAddTagLabelsTooltipToFirstCard && videoIndex === 0}
            />
          </div>
        </div>
      </CellMeasurer>
    );
  };

  useEffect(() => {
    setTimeout(() => {
      setScrollTop(0);
    }, 0);
  }, [method, videosFilters]);

  if (!initialized) {
    return <GridLoading />;
  }

  const isModal = !!onVideoChoose;

  if (!items.length) {
    return (
      <VideosEmptyState
        isModal={isModal}
        videosFilters={videosFilters}
        onSelectedVideosAction={onSelectedVideosAction}
      />
    );
  }

  return (
    <PendingVodConnectionSuggestionsProvider>
      <StyledGridContainer isModal={isModal} ref={ref} {...rest}>
        <AutoSizer>
          {({ height, width }) => {
            const { columnCount, cellWidth } = getColumnData(width);

            return (
              <>
                <Grid
                  onScroll={handleScroll}
                  cellRenderer={cellRenderer}
                  columnCount={columnCount}
                  columnWidth={cellWidth}
                  height={height}
                  rowCount={Math.ceil(items.length / columnCount)}
                  rowHeight={cache.rowHeight}
                  width={width}
                  scrollToAlignment="start"
                  containerStyle={{
                    width: width + gapSize * 2,
                    maxWidth: width + gapSize * 2,
                    marginLeft: -gapSize,
                    marginRight: -gapSize,
                  }}
                  scrollTop={scrollTop}
                  ref={gridRef}
                />
                {isLoading && (
                  <SpinnerContainer width={width}>
                    <Spinner />
                  </SpinnerContainer>
                )}
              </>
            );
          }}
        </AutoSizer>
      </StyledGridContainer>
    </PendingVodConnectionSuggestionsProvider>
  );
});
VideoGrid.displayName = 'VideoGrid';

const StyledGridContainer = styled(GridContainer)<{ isModal: boolean }>`
  padding: ${({ isModal }) => (isModal ? '0 24px 24px' : '24px')};
  grid-auto-rows: unset;
  height: 100%;
  padding: 24px 0 0 24px;
`;

const SpinnerContainer = styled(HorizontalFlex)<{ width: number }>`
  width: ${({ width }) => width}px;
  padding: 12px 0;
  justify-content: center;
  position: absolute;
  bottom: 0;
  background: ${({ theme }) => theme.colors.white};
`;

export default VideoGrid;
