import React from 'react';

const LibraryIcon = ({ fill = '#50545E' }) => {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 0H2C.875 0 0 .906 0 2v10c0 1.125.875 2 2 2h12c1.094 0 2-.875 2-2V2c0-1.094-.906-2-2-2ZM4.25 12.5H2a.494.494 0 0 1-.5-.5V5.75h2.75v6.75ZM14.5 12c0 .281-.25.5-.5.5H5.75V5.75h8.75V12Zm0-7.75h-13V2c0-.25.219-.5.5-.5h12c.25 0 .5.25.5.5v2.25Z"
        fill={fill}
      />
    </svg>
  );
};

export default LibraryIcon;
