import React from 'react';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import Utils from 'app/src/utils';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { SuccessButton, WhiteButton } from 'shared/react/components/basic/button-v2/BasicButton';
import EyeIcon from 'app/src/images/dashboard_v2/EyeIcon';
import TopRightArrowIcon from 'app/src/images/dashboard_v2/TopRightArrowIcon';
import { track } from 'app/src/helpers/Tracker';
import { navigateToPublishType, navigateToShareType } from 'app/src/utils/navigation.utils';
import { useHistory, useParams } from 'react-router-dom';
import { useProjects } from 'app/src/context/ProjectsStore';
import { LINK_KEY, PUBLISH_KEY } from 'app/src/constants/tolstoy.constants';
import useWidgetPreviewUrl from 'app/src/hooks/useWidgetPreviewUrl';
import Routes, { projectTabs } from 'app/src/helpers/Routes';
import { PUBLISH_BUTTON_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import ShopAppButtons from 'app/src/pages/project/components/project-top-bar/components/shopAppButtons/ShopAppButtons';
import { publishMethodOptions } from 'src/types/entities';
import SetLiveButton from '../set-live-button/SetLiveButton';
import { useApps } from 'app/src/context/AppsStore';
import Tooltip from 'app/src/basic_components/tooltip-v2/Tooltip';
import useAccountInstallation from 'src/hooks/useAccountInstallation';
import { useFeatureActions } from 'src/context/FeaturesStore';
import { FEATURE_STORIES_CAROUSEL_INSTALLATION_V2 } from 'shared/react/constants/features.constants';
import useNavigation from 'app/src/hooks/useNavigation';
import { useBilling } from 'app/src/hooks/useBillings';
import { useUser } from 'src/context/userStore/UserStore';
import useSetProjectLive from 'app/src/hooks/useSetProjectLive';
import PublishButton from 'app/src/pages/project/components/project-top-bar/components/set-live-button/PublishButton';
import PublishInstructionsButton from '../video-collection-campaign/PublishInstructionsButton';

const ProjectTabButtons = () => {
  const { projectId } = useParams();
  const history = useHistory();
  const [{ account }] = useUser();
  const { getFeatureEnabled } = useFeatureActions();
  const { hidePublishContent } = useBilling();
  const [{ project = {}, loading, projects }] = useProjects({ projectId });
  const { openSampleUrl } = useWidgetPreviewUrl(project);
  const isOffsite = history.location.pathname.includes(Routes.getDashboardV2OffsiteRoute(''));
  const isShopApp = project?.publishMethod === publishMethodOptions.shopApp;
  const isEmail = project?.publishMethod === publishMethodOptions.email;
  const isVideoCollectionCampaign =
    project?.publishMethod === publishMethodOptions.videoCollectionCampaign;
  const { dynamic: isDynamic, publishMethod } = project;
  const [{ shopify: isShopify }, { getAppUsingUrl }] = useApps();
  const currentApp = getAppUsingUrl(project?.appUrl);
  const [{ isInstallationCompleted: isAccountInstallationCompleted }] = useAccountInstallation(
    currentApp?.id
  );
  const { navigateToProjectTab } = useNavigation();
  const { isLoading, toggleWidget } = useSetProjectLive(project);

  const isStoriesCarouselInstallationV2Enabled = getFeatureEnabled(
    FEATURE_STORIES_CAROUSEL_INSTALLATION_V2
  );

  const isHero = publishMethod === publishMethodOptions.hero;

  const isProjectAndAccountInstallationCompleted =
    isAccountInstallationCompleted && project?.installationCompleted;

  const getPublishMethod = () => {
    if (publishMethod === publishMethodOptions.unassigned) {
      return PUBLISH_KEY;
    }

    return publishMethod ?? PUBLISH_KEY;
  };

  const handlePreviewClick = () => {
    track('Project Preview Click');
    openSampleUrl();
  };

  const openVideoCollectionPreview = (): void => {
    track('Preview Video Collection', { location: 'Dashboard Toolbar' });
    const videoCollectionLandingPageLink = `https://d2t4nmbkd63vhg.cloudfront.net/${project.publishId}`;
    Utils.openInNewTab(videoCollectionLandingPageLink);
  };

  const handleShare = () => {
    track('Share Tolstoy Click', { location: 'Dashboard Toolbar' });
    navigateToShareType(history, LINK_KEY);
  };

  const handlePublish = () => {
    track('Publish Tolstoy Click', { location: 'Dashboard Toolbar', isDynamic });
    navigateToPublishType(history, getPublishMethod());
  };

  const onPublishClick = () => {
    if (isOffsite) {
      handleShare();
      return;
    }

    handlePublish();
  };

  const openInstallationPage = () => {
    track('Set Live Tooltip Click');
    navigateToProjectTab(project, projectTabs.installation);
  };

  const getIsSetLiveDisabled = () => {
    const isPublishMethodBubble = publishMethod === publishMethodOptions.bubble;
    const isProjectInLiveIds = account?.liveIds?.includes(project.publishId);

    if (hidePublishContent) {
      return true;
    }

    if (project.live || isProjectInLiveIds) {
      return false;
    }

    if (publishMethod === publishMethodOptions.unassigned) {
      return true;
    }

    if (isStoriesCarouselInstallationV2Enabled) {
      return isPublishMethodBubble
        ? !isAccountInstallationCompleted
        : !isProjectAndAccountInstallationCompleted;
    }

    return false;
  };

  const isSetLiveDisabled = getIsSetLiveDisabled();

  const isInstallationRequired = isSetLiveDisabled;

  const getTooltipText = () => {
    if (!isSetLiveDisabled) {
      return '';
    }

    if (isInstallationRequired) {
      return (
        <div>
          To set live, complete the{' '}
          <ClickableText onClick={openInstallationPage}>installation</ClickableText> first
        </div>
      );
    } else {
      // Unhandled edge case
      return '';
    }
  };

  const tooltipText = getTooltipText();

  const getButtons = () => {
    if (loading) {
      return null;
    }

    if (isEmail) {
      return (
        <ButtonsContainer>
          <PublishButton project={project} />
        </ButtonsContainer>
      );
    }

    if (isVideoCollectionCampaign) {
      return (
        <>
          <WhiteButton onClick={openVideoCollectionPreview}>
            <EyeIcon /> Preview
          </WhiteButton>
          <PublishInstructionsButton project={project} />
        </>
      );
    }

    if (!isShopApp) {
      return (
        <>
          <WhiteButton onClick={handlePreviewClick}>
            <EyeIcon /> Preview
          </WhiteButton>
          <SuccessButton data-test-id={PUBLISH_BUTTON_TEST_ID} onClick={onPublishClick}>
            <TopRightArrowIcon />
            Publish
          </SuccessButton>
        </>
      );
    }

    return <ShopAppButtons />;
  };

  if (isStoriesCarouselInstallationV2Enabled) {
    if (isHero && !isShopify) {
      return null;
    }

    if (project.discover) {
      return (
        <>
          <ButtonsContainer>
            <WhiteButton onClick={handlePreviewClick}>
              <EyeIcon /> Preview
            </WhiteButton>
            <PublishButton project={project} />
          </ButtonsContainer>
        </>
      );
    }

    switch (publishMethod) {
      case publishMethodOptions.hero:
        return (
          <Tooltip text={tooltipText} hidden={!tooltipText} interactive={true}>
            <SetLiveButton
              isLive={project.live}
              onSetLive={toggleWidget}
              onSetUnLive={toggleWidget}
              isLoading={isLoading}
              disabled={isSetLiveDisabled}
            />
          </Tooltip>
        );
      case publishMethodOptions.embed:
        return (
          <WhiteButton onClick={handlePreviewClick}>
            <EyeIcon /> Preview
          </WhiteButton>
        );
      case publishMethodOptions.videoPage:
      case publishMethodOptions.tvChannel:
      case publishMethodOptions.carousel:
      case publishMethodOptions.stories:
      case publishMethodOptions.bubble:
      case publishMethodOptions.tile:
        return (
          <ButtonsContainer>
            <WhiteButton onClick={handlePreviewClick}>
              <EyeIcon /> Preview
            </WhiteButton>
            <PublishButton project={project} />
          </ButtonsContainer>
        );
      case publishMethodOptions.unassigned:
        return (
          <ButtonsContainer>
            <WhiteButton onClick={handlePreviewClick}>
              <EyeIcon /> Preview
            </WhiteButton>
          </ButtonsContainer>
        );
    }
  }

  return <LayoutRoot>{getButtons()}</LayoutRoot>;
};

const LayoutRoot = styled(Gap8HorizontalFlex)`
  background: ${({ theme }) => theme.colors.white};
  align-items: center;
`;

const ClickableText = styled.span`
  transition: 0.3s;
  cursor: pointer;
  text-decoration: underline;
`;

const pulseAnimationCSS = css`
  @keyframes pulseInstall {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 ${({ theme }) => rgba(theme.colors.warning, 0.5)};
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 15px ${({ theme }) => rgba(theme.colors.warning, 0)};
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 ${({ theme }) => rgba(theme.colors.warning, 0)};
    }
  }

  animation: pulseInstall 3s infinite;
`;

const ButtonsContainer = styled(Gap8HorizontalFlex)`
  // Fix for install button. Tooltip container shrinks its height by 2 pixels otherwise
  & > div {
    display: flex;
  }
`;

export default ProjectTabButtons;
