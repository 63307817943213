import { uniqWith } from 'lodash';
import { vodConnectionSuggestion } from 'app/src/types/entities';

export const getUniqueVodConnectionSuggestions = (
  vodConnectionSuggestions: vodConnectionSuggestion[]
) =>
  uniqWith(
    vodConnectionSuggestions,
    (a, b) => a.externalProductId === b.externalProductId && a.vodAssetId === b.vodAssetId
  );
