import React from 'react';
import ShareIcon from 'app/src/images/ShareIcon';
import Pencil from 'app/src/images/grey_pencil.svg';
import {
  DELETE_TOLSTOY_MODAL_KEY,
  MOVE_TOLSTOY_MODAL_KEY,
  SHARE_MODAL_KEY,
  TOLSTOY_COPY_TO_WORKSPACE_MODAL_KEY,
  TOLSTOY_RENAME_MODAL_KEY,
} from 'app/src/constants/modals.constants';
import DuplicateIcon from 'app/src/images/project_card_icons/duplicate_icon.svg';
import PreviewIcon from 'app/src/images/project_card_icons/external-link.svg';
import DeleteIcon from 'app/src/images/project_card_icons/delete_icon.svg';
import RemoveMacroIcon from 'app/src/images/project_card_icons/macro-full.svg';
import {
  DELETE_PROJECT_MENU_ITEM_TEST_ID,
  REMOVE_MACRO_DATA_TEST_ID,
  RENAME_PROJECT_MENU_ITEM_TEST_ID,
  SAVE_AS_MACRO_DATA_TEST_ID,
} from 'app/src/constants/dataTestIds.constants';
import SaveMacroIcon from 'app/src/images/project_card_icons/macro.svg';
import { track } from 'app/src/helpers/Tracker';
import { useProjects } from 'app/src/context/ProjectsStore';
import { navigateToShareType } from 'app/src/utils/navigation.utils';
import { LINK_KEY } from 'app/src/constants/tolstoy.constants';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { useBilling } from 'app/src/hooks/useBillings';
import { useDashboard } from 'app/src/context/ui_store/DashboardStore';
import { useVideos } from 'app/src/context/VideosStore';
import Utils from 'app/src/utils';
import MenuItem from 'app/src/complex_components/MenuItem';
import { useHistory } from 'react-router-dom';
import ArrowRight from 'app/src/images/folders/ArrowRight';
import { useTheme } from 'styled-components';
import TeamIcon from 'app/src/images/TeamIcon';
import { useAccount } from 'app/src/context/AccountStore';
import { SHOPIFY } from 'app/src/constants/intergrations.constants';
import { useApps } from 'app/src/context/AppsStore';
import ExportToStoreIcon from 'app/src/images/project_card_icons/export_to_store_icon.svg';
import { FolderType } from 'app/src/types/entities';

const ProjectCardMenuItems = ({ project, setMenuOpen, isFeed, inProject = false }) => {
  const [, { updateProject, getProjectUrl, duplicateProject, startExportProjectToStore }] =
    useProjects();
  const [, { setCurrentModal, setCurrentProjectId, setModalProps }] = useModal();
  const [{ workspaces }] = useAccount();
  const [, { setCreatingDuplication, setCreatingNewTolstoy }] = useDashboard();
  const [, { fetch: fetchVideos }] = useVideos();
  const { lockTolstoyCreation } = useBilling();
  const history = useHistory();
  const theme = useTheme();
  const projectTypeName = isFeed ? 'Feed' : 'Tolstoy';
  const [{ apps: storeApps }] = useApps();
  const shopifyApps = storeApps.filter(({ app }) => app === SHOPIFY);

  const handleCloseMenu = e => {
    e.stopPropagation();
    setMenuOpen(null);
  };

  const onToggleMacroClicked = async (project, enabled) => {
    if (enabled) {
      track('Tolstoy Save As A Macro');
    } else {
      track('Tolstoy Removed Macro');
    }

    await updateProject({ ...project, macro: enabled });
  };

  const handleShareClick = () => {
    track('Project Card Menu Item Click', { type: SHARE_MODAL_KEY });
    setCurrentProjectId(project.id);
    setCurrentModal(SHARE_MODAL_KEY);
    navigateToShareType(history, LINK_KEY);
  };

  const handlePreviewClick = () => {
    track('Project Card Menu Item Click', { type: 'Preview' });
    Utils.openInNewTab(`${getProjectUrl(project.publishId, isFeed)}?sneakpeek`);
  };

  const setLoaders = isLoading => {
    setCreatingDuplication(isLoading);
    setCreatingNewTolstoy(isLoading);
  };

  const onDuplicateProject = async () => {
    track('Tolstoy Duplicate Click');
    await duplicateProject({ project, setLoaders, setCurrentProjectId, setCurrentModal });
    await fetchVideos();
  };

  const onExportProject = async () => {
    track('Tolstoy Export to store Click', { projectId: project.id });
    await startExportProjectToStore(project, setLoaders, setCurrentProjectId, setCurrentModal);
    await fetchVideos();
  };

  const disableExportToStore = () => {
    return Utils.isQuiz(project?.tolstoyType) || shopifyApps.length < 2 || lockTolstoyCreation;
  };

  const getMacroMenuItem = () => {
    if (isFeed) {
      return null;
    }

    if (project?.macro) {
      return {
        title: 'Remove macro',
        icon: RemoveMacroIcon,
        onClick: () => onToggleMacroClicked(project, false),
        dataTestId: REMOVE_MACRO_DATA_TEST_ID,
      };
    }

    return {
      title: 'Save as macro',
      icon: SaveMacroIcon,
      onClick: () => onToggleMacroClicked(project, true),
      dataTestId: SAVE_AS_MACRO_DATA_TEST_ID,
    };
  };

  const handleOpenModalByKey = modalKey => {
    track('Project Card Menu Item Click', { type: modalKey });
    setCurrentProjectId(project.id);
    setCurrentModal(modalKey);
    setModalProps({ ids: [project.id], type: FolderType.projects });
  };

  const onCopyToWorkspaceClicked = () => {
    setCurrentModal(TOLSTOY_COPY_TO_WORKSPACE_MODAL_KEY);
    setModalProps({ project });
  };

  const getCopyToWorkspaceItem = () => {
    return {
      title: 'Copy to workspace',
      componentIcon: <TeamIcon fill={theme.colors.ghostDark} />,
      onClick: () => onCopyToWorkspaceClicked(project),
      hidden: workspaces?.length <= 1 || inProject,
    };
  };

  const MENU_ITEMS = [
    {
      title: 'Share',
      componentIcon: <ShareIcon />,
      onClick: handleShareClick,
    },
    {
      title: `Rename ${projectTypeName}`,
      icon: Pencil,
      onClick: () => handleOpenModalByKey(TOLSTOY_RENAME_MODAL_KEY),
      dataTestId: RENAME_PROJECT_MENU_ITEM_TEST_ID,
    },
    {
      title: `Duplicate ${projectTypeName}`,
      icon: DuplicateIcon,
      disabled: lockTolstoyCreation,
      hidden: inProject,
      onClick: onDuplicateProject,
    },
    {
      title: `Export to Store`,
      icon: ExportToStoreIcon,
      disabled: disableExportToStore(),
      onClick: () => onExportProject(project),
    },
    {
      title: `Preview ${projectTypeName}`,
      icon: PreviewIcon,
      onClick: () => handlePreviewClick(),
    },
    {
      title: 'Move Tolstoy',
      hidden: isFeed || inProject,
      componentIcon: <ArrowRight fill={theme.colors.ghostDark} />,
      onClick: () => handleOpenModalByKey(MOVE_TOLSTOY_MODAL_KEY),
    },
    getMacroMenuItem(),
    getCopyToWorkspaceItem(),
    {
      title: 'Delete',
      icon: DeleteIcon,
      onClick: () => handleOpenModalByKey(DELETE_TOLSTOY_MODAL_KEY),
      dataTestId: DELETE_PROJECT_MENU_ITEM_TEST_ID,
    },
  ];

  const getContent = menuItem => {
    if (!menuItem) {
      return;
    }

    return (
      <MenuItem
        disabled={menuItem.disabled}
        key={menuItem.title}
        menuItem={menuItem}
        onClose={handleCloseMenu}
        data-test-id={menuItem.dataTestId || ''}
      />
    );
  };

  return <>{MENU_ITEMS.map(getContent)}</>;
};

export default ProjectCardMenuItems;
