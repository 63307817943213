import React, { lazy, Suspense, useEffect } from 'react';
import Utils from '../../../utils';
import { getSessionId, track } from '../../../tracker/Tracker';
import Constants from '../../../constants';
import {
  AUDIO_RESPONSE_KEY,
  CHAT_RESPONSE_KEY,
  FREE_TEXT_RESPONSE_KEY,
  IMAGE_RESPONSE_KEY,
  LEAD_FORM_RESPONSE_KEY,
  SIDE_BAR,
  VIDEO_RESPONSE_KEY,
} from '../../../constants/tolstoy.constants';
import { getLeadFormStateKey, getSentInCurrentSessionKey } from '../player.utils';
import { getLocalStorageItem, setLocalStorageItem } from '../../../actions/localStorage.actions.js';
import PlayerLoader from 'player/src/components/PlayerLoader';
import LeadFormContainer from '../../responses/lead_form/LeadFormContainer';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';

const DateTimePickerModal = lazy(() =>
  Utils.componentLoader(() =>
    import(/* webpackChunkName: "date-time-picker-modal" */ '../../DateTimePickerModal')
  )
);

const FreeTextModal = lazy(() =>
  Utils.componentLoader(() =>
    import(/* webpackChunkName: "free-text-modal" */ '../../responses/free_text/FreeTextModal')
  )
);

const CityPickerModal = lazy(() =>
  Utils.componentLoader(() =>
    import(/* webpackChunkName: "city-picker-modal" */ '../../CityPickerModal')
  )
);

const ChapterPicker = lazy(() =>
  Utils.componentLoader(() =>
    import(/* webpackChunkName: "chapter-picker" */ '../../ChapterPicker')
  )
);

const AudioResponse = lazy(() =>
  Utils.componentLoader(() =>
    import(/* webpackChunkName: "audio-response" */ '../../responses/audio/AudioResponse')
  )
);

const CalendlyWidget = lazy(() =>
  Utils.componentLoader(() =>
    import(/* webpackChunkName: "calendly-widget" */ '../../CalendlyWidget')
  )
);

const ImageResponse = lazy(() =>
  Utils.componentLoader(() =>
    import(/* webpackChunkName: "image-response" */ '../../responses/image/ImageResponse')
  )
);

const VideoResponseRecorder = lazy(() =>
  Utils.componentLoader(() =>
    import(/* webpackChunkName: "video-response" */ '../../responses/video/VideoResponseRecorder')
  )
);

function AnswerTypes({
  playlist,
  selectedVideo,
  landingPage,
  allowCancel,
  wide,
  collectInfo,
  accountId,
  projectId,
  canAccessStorage,
  verticalOrientation,
  calendlyWidgetSettings,
  setCalendlyWidgetSettings,
  resultVideoBlob,
  setResultVideoBlob,
  customButtonsText,
  customChapterPicker,
  customLeadFormText,
  customFreeTextText,
  nextStepType,
  nextStep,
  closeModal,
  imageFile,
  onImageUploadCancel,
  goToAfterLeadFormStep,
  setGoToAfterLeadFormStep,
  chapterPickerOpen,
  setChapterPickerOpen,
  collectInfoForm,
  controlsShown,
  userPause,
  previewMode,
  play,
  moveTo,
  videoDuration,
  getActiveVideoRefCurrent,
  answerKey,
  onVideoUpload,
  appKey,
  brandColor,
  transitioningFromVideoId,
}) {
  const [, { t }] = useTranslation();

  const isSideBar = !nextStep;

  function onLeadFormCompleted(isCustom) {
    if (SIDE_BAR === nextStep) {
      closeModal();
      play();
      return;
    }

    if (isCustom && goToAfterLeadFormStep) {
      const goToAfterLeadForm = goToAfterLeadFormStep;
      setGoToAfterLeadFormStep(null);
      collectInfoForm(goToAfterLeadForm, null, true);
      return;
    }

    setGoToAfterLeadFormStep(null);
    moveTo(nextStep);

    if (nextStep !== CHAT_RESPONSE_KEY) {
      animateSubmitButton();
    }
  }

  function onResponseCompleted() {
    if (goToAfterLeadFormStep) {
      const goToAfterLeadForm = goToAfterLeadFormStep;
      setGoToAfterLeadFormStep(null);
      collectInfoForm(goToAfterLeadForm, null);
    } else if (isSideBar) {
      closeModal();
      play();
    } else {
      moveTo(nextStep);
      animateSubmitButton();
    }
  }

  function onChapterSelected(index) {
    track('chapterSelected');

    if (getSelectedVideoIndex() !== index) {
      moveTo(playlist[index].key);
      animateSubmitButton();
    } else {
      closeChapterPicker();
    }
  }

  function getSelectedVideoIndex() {
    return playlist.findIndex(video => video.key === selectedVideo.key);
  }

  function collectSubmit(fields, stepIndex) {
    let eventData = { collectInfoStep: stepIndex };
    const customFields = {};
    const formData = {};

    fields
      .filter(f => f.value && !f.custom)
      .forEach(f => {
        formData[f.name] = f.value;
        eventData[f.name] = f.value;
        eventData.collectInfoType = f.name;
        eventData.text = `${f.name}: ${f.value}`;
      });
    fields
      .filter(f => f.value && f.custom)
      .forEach(f => {
        formData[f.name] = f.value;
        customFields[f.name] = f.value;
        eventData.collectInfoType = f.name;
        eventData.text = `${f.name}: ${f.value}`;
      });

    if (Object.keys(customFields).length > 0) {
      eventData.customFields = JSON.stringify(customFields);
    }
    if (Object.keys(formData).length > 0) {
      eventData.formData = JSON.stringify(formData);
    }

    track('collectInfo', eventData);
    saveLeadFormInfoToLocalStorage(eventData, customFields, formData);
  }

  function saveLeadFormInfoToLocalStorage(eventData, customFields, formData) {
    if (!canAccessStorage) {
      return;
    }

    const leadFormStateKey = getLeadFormStateKey(projectId);
    const leadFormState = getLocalStorageItem(leadFormStateKey);
    if (leadFormState) {
      let leadFormStateJson = JSON.parse(leadFormState);

      if (leadFormStateJson.customFields) {
        let storedCustomFields = JSON.parse(leadFormStateJson.customFields);
        if (storedCustomFields && Object.keys(customFields).length > 0) {
          leadFormStateJson.customFields = JSON.stringify({
            ...storedCustomFields,
            ...customFields,
          });
          eventData['customFields'] = leadFormStateJson.customFields;
        }
      }

      if (leadFormStateJson.formData) {
        let storedFormData = JSON.parse(leadFormStateJson.formData);
        if (storedFormData && Object.keys(formData).length > 0) {
          leadFormStateJson.formData = JSON.stringify({
            ...storedFormData,
            ...formData,
          });
          eventData['formData'] = leadFormStateJson.formData;
        }
      }

      eventData = {
        ...leadFormStateJson,
        ...eventData,
        collectInfoStep: 0,
        collectInfoType: 'tolstoy_cache',
        text: `${eventData.text}, ${leadFormStateJson.text}`,
      };
    }
    setLocalStorageItem(leadFormStateKey, JSON.stringify(eventData));

    const sessionId = getSessionId();
    const leadFormSentInSessionKey = getSentInCurrentSessionKey(sessionId);
    sessionStorage.setItem(leadFormSentInSessionKey, 'true');
  }

  function animateSubmitButton(callback) {
    setTimeout(() => {
      callback ? callback() : onCloseModal(true);
    }, Constants.SubmitAnimationMilliseconds);
  }

  const onCustomLeadFormSave = (values, stepIndex) => {
    let eventData = { collectInfoStep: stepIndex };
    const formData = {};
    const keys = Object.keys(values);

    keys.forEach(key => {
      formData[key] = values[key];
      eventData.collectInfoType = 'custom';
    });

    if (Object.keys(formData).length > 0) {
      eventData.formData = JSON.stringify(formData);
    }
    console.log(eventData);
    track('collectInfo', eventData);
  };

  function hasActiveVideoCompleted() {
    let activeVideoRefCurrent = getActiveVideoRefCurrent();
    return activeVideoRefCurrent && activeVideoRefCurrent.currentTime >= videoDuration;
  }

  function closeChapterPicker() {
    if (!chapterPickerOpen) {
      return;
    }
    setChapterPickerOpen(false);
    if (userPause) {
      return;
    }
    play();
  }

  function onCloseModal(moveNext) {
    setCalendlyWidgetSettings(null);
    closeModal();

    if (!moveNext && !hasActiveVideoCompleted()) {
      play();
    }
  }

  function inputSubmit(input, next) {
    const data = { text: input, answerKey };
    if (isSideBar) {
      data.next = 'sidebar';
    }

    track('submitInput', data);
    if (goToAfterLeadFormStep) {
      setGoToAfterLeadFormStep(null);
      animateSubmitButton(() => {
        collectInfoForm(goToAfterLeadFormStep, null);
      });
    } else if (!next) {
      closeModal();
    } else {
      moveTo(next);
      animateSubmitButton();
    }
  }

  function handleCalendlyScheduled() {
    if (goToAfterLeadFormStep) {
      setGoToAfterLeadFormStep(null);
      animateSubmitButton(() => {
        collectInfoForm(goToAfterLeadFormStep, null);
        setCalendlyWidgetSettings(null);
      });
      return;
    }

    moveTo(calendlyWidgetSettings.next);
    animateSubmitButton(() => {
      setCalendlyWidgetSettings(null);
    });
  }

  useEffect(() => {
    if (!transitioningFromVideoId && chapterPickerOpen) {
      closeChapterPicker();
    }
  }, [transitioningFromVideoId]);

  const enableClose = !!answerKey || (nextStep && nextStep !== 'start');
  return (
    <>
      {LEAD_FORM_RESPONSE_KEY === nextStepType && (
        <LeadFormContainer
          formText={collectInfo.text || t('interactive.leadForm.defaultHeader')}
          inputFields={collectInfo.fields}
          landingPage={landingPage}
          wide={wide}
          controlsShown={controlsShown}
          customLeadFormText={customLeadFormText}
          goToAfterLeadFormStep={goToAfterLeadFormStep}
          onLeadFormCompleted={onLeadFormCompleted}
          onCustomLeadFormSave={onCustomLeadFormSave}
          onSubmit={collectSubmit}
          onClose={onCloseModal}
          enableClose={enableClose}
          nextStep={nextStep}
          projectId={projectId}
          accountId={accountId}
          verticalOrientation={verticalOrientation}
          stepKey={answerKey}
        />
      )}
      {FREE_TEXT_RESPONSE_KEY === nextStepType && (
        <Suspense fallback={<PlayerLoader />}>
          <FreeTextModal
            videoKey={selectedVideo?.key}
            projectId={projectId}
            customFreeTextText={customFreeTextText}
            onSubmit={input => inputSubmit(input, nextStep)}
            onClose={closeModal}
            brandColor={brandColor}
            answerKey={answerKey}
          />
        </Suspense>
      )}
      {VIDEO_RESPONSE_KEY === nextStepType && (
        <Suspense fallback={<PlayerLoader />}>
          <VideoResponseRecorder
            accountId={accountId}
            projectId={projectId}
            play={play}
            onVideoResponseCompleted={onResponseCompleted}
            verticalOrientation={verticalOrientation}
            onCancelVideoResponse={closeModal}
            resultVideoBlob={resultVideoBlob}
            answerKey={answerKey}
            setResultVideoBlob={setResultVideoBlob}
            allowCancel={allowCancel}
            previewMode={previewMode}
            customButtonsText={customButtonsText}
            onVideoUpload={onVideoUpload}
            isSideBar={isSideBar}
            appKey={appKey}
          />
        </Suspense>
      )}
      {IMAGE_RESPONSE_KEY === nextStepType && (
        <Suspense fallback={<PlayerLoader />}>
          <ImageResponse
            accountId={accountId}
            file={imageFile}
            onCancel={onImageUploadCancel}
            appKey={appKey}
            onUploadCompleted={onResponseCompleted}
            brandColor={brandColor}
            answerKey={answerKey}
          />
        </Suspense>
      )}
      {AUDIO_RESPONSE_KEY === nextStepType && (
        <Suspense fallback={<PlayerLoader />}>
          <AudioResponse
            onResponseCompleted={onResponseCompleted}
            previewMode={previewMode}
            customButtonsText={customButtonsText}
            onClose={onCloseModal}
            appKey={appKey}
            isSideBar={isSideBar}
            accountId={accountId}
            answerKey={answerKey}
          />
        </Suspense>
      )}
      {'DateTime' === nextStepType && (
        <Suspense fallback={<PlayerLoader />}>
          <DateTimePickerModal
            formText="Please select your most convenient date & time"
            onSubmit={input => inputSubmit(input, nextStep)}
            onClose={onCloseModal}
          />
        </Suspense>
      )}
      {'City' === nextStepType && (
        <Suspense fallback={<PlayerLoader />}>
          <CityPickerModal
            formText="Please share with us the city that you're living in"
            onSubmit={input => inputSubmit(input, nextStep)}
            onClose={onCloseModal}
          />
        </Suspense>
      )}
      {chapterPickerOpen && (
        <Suspense fallback={<PlayerLoader />}>
          <ChapterPicker
            closeChapterPicker={closeChapterPicker}
            onChapterSelected={onChapterSelected}
            playlist={playlist}
            selectedChapter={getSelectedVideoIndex()}
            customChapterPicker={customChapterPicker}
          />
        </Suspense>
      )}
      {calendlyWidgetSettings && (
        <Suspense fallback={<PlayerLoader />}>
          <CalendlyWidget
            calendlyWidgetSettings={calendlyWidgetSettings}
            onCalendlyScheduled={handleCalendlyScheduled}
            verticalOrientation={verticalOrientation}
            onClose={onCloseModal}
          />
        </Suspense>
      )}
    </>
  );
}

export default AnswerTypes;
