import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { CircularProgress } from '@material-ui/core';

export default function InteractiveVideo({
  isStandardVideo,
  src,
  title,
  width,
  height,
  borderRadius,
  backgroundColor,
  ...props
}) {
  const [isLoaded, setLoaded] = useState(false);
  const [hasVideo, setHasVideo] = useState(true);

  useEffect(() => {
    setLoaded(false);
    setHasVideo(!!src);
  }, [src]);

  function handleLoaded() {
    setLoaded(true);
  }

  return (
    <LayoutRoot borderRadius={borderRadius} height={height}>
      {!isLoaded && <Background borderRadius={borderRadius} backgroundColor={backgroundColor} />}
      <VideoContent
        title={title}
        width={width}
        height={height}
        isLoaded={isLoaded}
        onLoaded={handleLoaded}
        src={src}
        hasVideo={hasVideo}
        borderRadius={borderRadius}
        isStandardVideo={isStandardVideo}
        {...props}
      />

      {!isLoaded && hasVideo && <StyledCircularProgress size={20} />}
    </LayoutRoot>
  );
}

const VideoContent = ({
  hasVideo,
  isStandardVideo,
  src,
  height,
  width,
  title,
  isLoaded,
  onLoaded,
  borderRadius,
  ...props
}) => {
  if (!hasVideo) {
    return null;
  }

  if (isStandardVideo) {
    return (
      <Video
        src={src}
        autoplay
        isLoaded={true}
        controls
        onLoadedMetadata={onLoaded}
        height={height}
        {...props}
      />
    );
  }

  return (
    <Player
      key={src}
      src={src}
      title={title}
      width={width}
      height={height}
      isLoaded={isLoaded}
      onLoad={onLoaded}
      scrolling="no"
      frameBorder="0"
      allow="autoplay *; clipboard-write *;camera *; microphone *; encrypted-media *; fullscreen *; display-capture *;"
      borderRadius={borderRadius}
      {...props}
    />
  );
};

const LayoutRoot = styled.div`
  display: grid;
  height: ${({ height }) => height || '100%'};
  width: 100%;
  overflow-y: hidden;
  border-radius: ${({ borderRadius }) => borderRadius};
`;

const Background = styled.div`
  background: ${({ backgroundColor, theme }) => backgroundColor || theme.colors.gray6};
  border-radius: ${({ borderRadius }) => borderRadius};
  grid-row: 1;
  grid-column: 1;
`;

const baseVideoCss = css`
  width: ${props => props.width || '100%'};
  height: ${({ height }) => height || '100%'};
  opacity: ${props => (props.isLoaded ? 1 : 0)};
  background: ${({ theme }) => theme.colors.black};
  justify-self: center;
  align-self: center;
  grid-row: 1;
  grid-column: 1;
  border-radius: ${({ borderRadius }) => borderRadius};
`;

const Player = styled.iframe`
  ${baseVideoCss}
`;

const Video = styled.video`
  ${baseVideoCss}
`;

const StyledCircularProgress = styled(CircularProgress)`
  grid-row: 1;
  grid-column: 1;
  z-index: 1000;
  justify-self: center;
  align-self: center;
`;
