import { useEffect, useState } from 'react';
import { useVideos } from 'src/context/VideosStore';
import { track } from 'src/helpers/Tracker';
import { useSnackBarActions } from 'src/context/ui_store/SnackBarStore';

type UseVideoCaptionsProps = {
  video: any;
  subtitlesBlob: Blob | null;
  setSubtitlesBlob: (blob: Blob) => void;
  uploadedVTTContent: string | null;
};

const useVideoCaptions = ({
  video,
  subtitlesBlob,
  setSubtitlesBlob,
  uploadedVTTContent,
}: UseVideoCaptionsProps) => {
  const { setSnackbar, setErrorSnackbar } = useSnackBarActions();
  const [, { getSubtitlesUrlByVideo, uploadSubtitles }] = useVideos();
  const [subtitles, setSubtitles] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const onSave = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    const subtitles = await subtitlesBlob?.text();
    if (!subtitles) {
      setErrorSnackbar('No subtitles to save');
      setIsLoading(false);
      return;
    }

    try {
      await uploadSubtitles({ projectId: null, videoId: video.id, subtitles });
      setSubtitles(subtitles);
      setIsLoading(false);
      track('updated subtitles');
      setSnackbar('Successfully saved subtitles');
    } catch (err) {
      setIsLoading(false);
      setErrorSnackbar('Failed to save subtitles');
    }
  };

  const setVideoSubtitles = async () => {
    if (uploadedVTTContent) {
      setSubtitles(uploadedVTTContent);
      setIsLoading(false);
    } else {
      try {
        const response = await fetch(
          `${getSubtitlesUrlByVideo(video)}?timestamp=${new Date().getTime()}`
        );

        if (!response.ok) {
          throw new Error('getSubtitlesUrl failed');
        }

        const blob = await response.blob();
        const text = await blob.text();
        setSubtitles(text);
        setSubtitlesBlob(blob);
      } catch (e) {
        setSubtitles('WEBVTT\n');
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setVideoSubtitles();
  }, [video, uploadedVTTContent]);

  return {
    onSave,
    subtitles,
    setSubtitles,
    isLoading,
  };
};

export default useVideoCaptions;
