import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import StraightArrowRightIcon from 'app/src/images/creation_method_icons/StraightArrowRightIcon';
import { rgba } from 'polished';

type Props = {
  images: string[];
  title: string;
};

const CasesGallery = ({ images, title }: Props) => {
  const [index, setIndex] = useState(0);

  const handlePrevClick = () => {
    setIndex(index === 0 ? images.length - 1 : index - 1);
  };

  const handleNextClick = () => {
    setIndex(index === images.length - 1 ? 0 : index + 1);
  };

  return (
    <LayoutRoot>
      <Container>
        {images.length > 1 && (
          <PreviousButton onClick={handlePrevClick}>
            <StraightArrowRightIcon />
          </PreviousButton>
        )}

        <Image src={images[index]} alt={title} />

        {images.length > 1 && (
          <NextButton onClick={handleNextClick}>
            <StraightArrowRightIcon />
          </NextButton>
        )}
      </Container>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  max-width: 522px;
  aspect-ratio: 399 / 410;
  width: 100%;
`;

const Container = styled.div`
  position: relative;
`;

const BaseButton = css`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  top: 50%;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.neutralLight};
  background: ${({ theme }) => rgba(theme.colors.neutralBlack, 0.45)};
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: ${({ theme }) => theme.colors.white};

    svg path {
      fill: ${({ theme }) => theme.colors.neutralBlack};
    }
  }
`;

const PreviousButton = styled.div`
  ${BaseButton};
  left: 8px;
  transform: rotate(180deg) translateY(50%);
`;

const NextButton = styled.div`
  ${BaseButton};
  right: 8px;
  transform: translateY(-50%);
`;

const Image = styled.img`
  display: block;
  width: 100%;
  user-select: none;
  aspect-ratio: 399 / 410;
`;

export default CasesGallery;
