import React from 'react';
import styled from 'styled-components';
import MacroFull from 'app/src/images/MacroFull';
import { TextBody } from 'shared/react/components/complex/Text';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';

function PreviewStepContainer({ headerText, descriptionText }) {
  return (
    <StepHeaderContainer>
      <HeaderContainer>
        <MacroFull /> <StyledStepHeader> {headerText}</StyledStepHeader>
      </HeaderContainer>
      <TextBody>{descriptionText}</TextBody>
    </StepHeaderContainer>
  );
}

export default PreviewStepContainer;

const HeaderContainer = styled(Gap8HorizontalFlexWrap)`
  align-items: center;
`;

const StepHeaderContainer = styled.div`
  max-width: 300px;
`;

const StyledStepHeader = styled(TextBody)`
  font-size: 20px;
  font-weight: 800;
  line-height: 28px;
`;
