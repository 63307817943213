import { REPLY } from '../constants/tolstoy.constants';
import { PRODUCT_ID_URL_PARAM, SHOPIFY_STORE_URL_PARAM } from '../constants/urls.constants';
import Utils from '../utils';

export const addUrlParamsToEventsData = (eventsData, currentSession) => {
  const params = new URLSearchParams(window.location.search);
  const campaign = params.get('utm_campaign');
  const email = params.get('email');
  const name = params.get('name');
  const phone = params.get('phone');

  eventsData.playerType = 'landing-page';

  eventsData.isMobile = Utils.isMobile();

  if (params.has('sneakpeek') || params.has('sneakpeek_hidden')) {
    eventsData.preview = true;
  }

  if (params.has('sessionId') || currentSession.sessionId) {
    eventsData.sessionId = params.get('sessionId') || currentSession.sessionId;
    eventsData.playerType = REPLY;
  }

  if (params.has('replyProjectId') || currentSession.replyProjectId) {
    eventsData.replyProjectId =
      params.get('replyProjectId')?.slice(0, 36) || currentSession.replyProjectId;
  }

  if (params.has('replyPublishId')) {
    eventsData.replyPublishId = params.get('replyPublishId');
  }

  if (params.has('abTestId')) {
    eventsData.abTestId = params.get('abTestId');
  }

  const isIframe = window.self !== window.top;

  if (params.has('url') || isIframe) {
    eventsData.playerType = 'embed';
  }

  if (params.has('url')) {
    eventsData.parentUrl = encodeURI(params.get('url'));
  } else {
    eventsData.parentUrl = isIframe ? document.referrer : window.location.href;
  }

  if (params.has('playerType')) {
    eventsData.playerType = params.get('playerType');
  }

  if (params.has('playerVariant')) {
    eventsData.playerVariant = params.get('playerVariant');
  }

  if (params.has('group')) {
    eventsData.testGroup = params.get('group');
  }

  if (params.has('anonymousId')) {
    eventsData.anonymousId = params.get('anonymousId');
  }

  if (params.has('resolution')) {
    eventsData.videoResolution = params.get('resolution');
  }

  if (params.has('appKey')) {
    eventsData.appKey = params.get('appKey');
  }

  if (params.has('intercomConversationId')) {
    eventsData.intercomConversationId = params.get('intercomConversationId');
    Utils.loadScript(
      'https://s3.amazonaws.com/intercom-sheets.com/messenger-sheet-library.latest.js',
      () => {
        window.INTERCOM_MESSENGER_SHEET_LIBRARY.setTitle(' ');
      }
    );
  }

  if (params.has('customerId')) {
    eventsData.customerId = params.get('customerId');
  }

  if (campaign) {
    eventsData.campaign = campaign;
  }
  if (email && Utils.isValidEmail(email)) {
    eventsData.email = email;
  }
  if (name) {
    eventsData.name = name;
  }
  if (phone) {
    eventsData.phone = phone;
  }

  if (params.has(SHOPIFY_STORE_URL_PARAM)) {
    eventsData.storeUrl = params.get(SHOPIFY_STORE_URL_PARAM);
  }

  if (params.has(PRODUCT_ID_URL_PARAM)) {
    eventsData.currentPageProductId = Utils.getProductId();
  }
};
