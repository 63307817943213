import React, { useState } from 'react';
import styled from 'styled-components';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import Utils from 'shared/react/utils/utils';
import { TextH6 } from 'shared/react/components/complex/Text';
import FeedButton from '../feed/feed_buttons/FeedButton';
import useCustomerLogin from '../context/hooks/useCustomerLogin';

const CustomerLoginForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [emailInput, setEmailInput] = useState('');
  const [, { handleSubmitEmail }] = useCustomerLogin();

  const onEmailSubmit = async () => {
    setIsLoading(true);
    await handleSubmitEmail(emailInput);
  };

  return (
    <LayoutRoot>
      <StyledText>Personalize what you see, every time you swipe.</StyledText>
      <StyledInput
        id="email-input"
        type="text"
        placeholder="Email"
        value={emailInput}
        onChange={e => {
          setEmailInput(e.target.value);
        }}
      />
      <StyledFeedButton
        onClick={onEmailSubmit}
        isLoading={isLoading}
        buttonText={'Get login code'}
        buttonTextColor={'white'}
        isDisabled={!Utils.validateEmail(emailInput)}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)`
  justify-content: space-between;
  min-height: 198px;
`;

const StyledInput = styled.input`
  height: 40px;
  color: ${({ theme }) => theme.colors.black};
  border-radius: 8px;
  padding: 0 16px;
  border: ${({ theme }) => `1px solid ${theme.colors.gray38}`};
  ::placeholder {
    color: ${({ theme }) => theme.colors.gray36};
  }
`;

const StyledFeedButton = styled(FeedButton)`
  div {
    font-size: 16px;
  }
`;

const StyledText = styled(TextH6)`
  margin-top: 16px;
`;

export default CustomerLoginForm;
