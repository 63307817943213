import React, { forwardRef } from 'react';
import styled from 'styled-components';

const ScrollHereDiv = forwardRef((props, ref) => {
  return <LayoutRoot ref={ref} {...props} />;
});

ScrollHereDiv.displayName = 'scrollHere';

export default ScrollHereDiv;

const LayoutRoot = styled.div``;
