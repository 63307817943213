import React, { FC } from 'react';
import styled from 'styled-components';

import { TextH1, TextH4 } from 'shared/react/components/basic/text/TextV2';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

type Props = { isEmpty: boolean };

export const PageTitle: FC<Props> = ({ isEmpty }) => {
  const getTitle = () => {
    if (isEmpty) {
      return <TextH1>All products are tagged or suggestions not found</TextH1>;
    }

    return (
      <>
        <TextH1>AI-Powered Video Matching</TextH1>
        <TextH4>Products with videos have 3X conversions</TextH4>
      </>
    );
  };

  return <Title>{getTitle()}</Title>;
};

const Title = styled(VerticalFlex)`
  align-items: center;
  margin-top: 24px;
`;

export default PageTitle;
