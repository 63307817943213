import { createActionsHook, createContainer, createHook, createStore } from 'react-sweet-state';
import { SYNTHESIA } from 'app/src/constants/intergrations.constants';
import { v4 as uuidv4 } from 'uuid';
import SynthesiaIcon from 'app/src/images/integrations/synthesiaCard.png';

type State = {
  videos: any;
  loading: boolean | null;
  initialized: boolean;
};

const initialState: State = {
  videos: [],
  loading: null,
  initialized: false,
};

const onSynthesiaResponse = response => {
  if (!response || !response.videos) {
    return [];
  }

  return response.videos;
};

const getDuration = data => {
  const duration = data.duration.split('.')[0].split(':');
  const hoursInSeconds = +duration[0] * 3600;
  const minutesInSeconds = +duration[1] * 60;
  return hoursInSeconds + minutesInSeconds + +duration[2];
};

const actions = {
  fetchSynthesia:
    apiKey =>
    async ({ setState, dispatch }) => {
      const limit = 100;
      const offset = 0;
      const url = `https://api.synthesia.io/v2/videos?limit=${limit}&offset=${offset}`;
      try {
        const res = await fetch(url, { headers: { authorization: apiKey } });
        const resJson = await res.json();
        const videos = onSynthesiaResponse(resJson);

        setState({
          videos: videos.map((video, i) => dispatch(actions.normalizeSynthesiaData(video, i))),
          initialized: true,
        });
        return true;
      } catch (err) {
        console.log(err);
        return false;
      }
    },
  normalizeSynthesiaData:
    (data, i) =>
    (_, { owner }) => {
      const id = uuidv4();
      const createdAt = data.createdAt * 1000;

      return {
        ...data,
        externalId: data.id,
        id,
        newId: id,
        videoUrl: data.download,
        app: SYNTHESIA,
        createdAt: new Date(createdAt).toISOString(),
        owner,
        stockAsset: { videoUrl: data.download, posterUrl: SynthesiaIcon },
        name: data.title || `Synthesia video #${i + 1}`,
        uploadType: SYNTHESIA,
        duration: getDuration(data),
      };
    },
  clearSynthesia:
    () =>
    ({ setState }) => {
      setState(initialState);
    },
};

type Actions = typeof actions;

const SynthesiaStore = createStore<State, Actions>({ initialState, actions, name: 'synthesia' });

export const useSynthesia = createHook(SynthesiaStore);

export const useSynthesiaActions = createActionsHook(SynthesiaStore);

export const synthesiaContainer = createContainer(SynthesiaStore, {
  onCleanup:
    () =>
    ({ dispatch }) => {
      dispatch(actions.clearSynthesia());
    },
});
