import { VIDEO_OUTPUT } from 'widget/src/config/widget.config.js';
import {
  ASSET_TYPE,
  IMAGE_SIZE,
  IMAGE_SIZE_SUFFIX,
  DEFAULT_IMAGE_EXTENSION
} from 'widget/src/constants/assets.constants.js';

export const isImageAsset = asset => asset?.type === ASSET_TYPE.IMAGE;
export const isGalleryAsset = asset => asset?.type === ASSET_TYPE.GALLERY;
export const isVideoAsset = asset => !asset?.type || asset?.type === ASSET_TYPE.VIDEO;

export const getStepImageUrl = ({ videoOwner, videoId }, { size = IMAGE_SIZE.M } = {}) => {
  const route = `${VIDEO_OUTPUT}/public/${videoOwner}/${videoId}/`;
  const fileName = `${videoId}${IMAGE_SIZE_SUFFIX[size]}${DEFAULT_IMAGE_EXTENSION}`;

  return `${route}${fileName}`;
};

export const getNextNonImageIndex = (steps, playingIndex = -1) => {
  for (let index = playingIndex + 1; index < steps.length; index++) {
    if (isImageAsset(steps[index])) {
      continue;
    }

    return index;
  }
};
