import { createActionsHook, createContainer, createHook, createStore } from 'react-sweet-state';

const initialState = {
  isShareButtonHighlighted: false,
  activeTemplateKey: '',
  activeTemplateStageIndex: null,
  isTemplatesNextStepBlocked: false,
  activeTemplateProjectId: null,
};

const actions = {
  setHighlightShareButton:
    isShareButtonHighlighted =>
    ({ setState }) => {
      setState({ isShareButtonHighlighted });
    },
  setActiveTemplateKey:
    activeTemplateKey =>
    ({ setState }) => {
      setState({ activeTemplateKey });
    },
  setActiveTemplateStageIndex:
    activeTemplateStageIndex =>
    ({ setState }) => {
      setState({ activeTemplateStageIndex });
    },
  setIsTemplatesNextStepBlocked:
    isTemplatesNextStepBlocked =>
    ({ setState }) => {
      setState({ isTemplatesNextStepBlocked });
    },
  setActiveTemplateProjectId:
    activeTemplateProjectId =>
    ({ setState }) => {
      setState({ activeTemplateProjectId });
    },
};

const OnboardingStore = createStore({ initialState, actions });

export const useOnboarding = createHook(OnboardingStore);

export const useOnboardingActions = createActionsHook(OnboardingStore);

export const OnboardingContainer = createContainer(OnboardingStore);
