import VerticalFlex from 'player/src/components/common/VerticalFlex';
import React from 'react';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import styled from 'styled-components';

type Props = {
  header: string;
  Icon: React.FC;
  createdAt: string;
  children: React.ReactNode;
};

const JourneyDrawerStep = ({ header, Icon, createdAt, children }: Props) => {
  return (
    <LayoutRoot>
      <IconContainer>
        <Icon />
      </IconContainer>
      <Content>
        <HeaderContainer>
          <Header>{header}</Header>
          <DateText>{new Date(createdAt).toLocaleString('en-us')}</DateText>
        </HeaderContainer>
        <Subheader>{children}</Subheader>
      </Content>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)`
  gap: 4px;
`;

const Content = styled(Gap8VerticalFlex)`
  width: 100%;
  margin-top: 2px;
`;

const HeaderContainer = styled(HorizontalFlex)`
  justify-content: space-between;
`;

const Header = styled(TextTiny)`
  font-weight: 600;
`;

const Subheader = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralDark};
  font-weight: 600;
`;

const DateText = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralDark};
  font-weight: 400;
`;

const IconContainer = styled(VerticalFlex)`
  padding-top: 1px;
`;

export default JourneyDrawerStep;
