import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Snackbar as CustomSnackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import styled from 'styled-components';
import { SNACK_BAR_TEST_ID } from 'shared/react/constants/dataTestIds.constants';
const SnackBar = ({
  setOpen,
  severity,
  text,
  alertProps,
  verticalPosition,
  horizontalPosition,
  autoHideDuration = 3000,
  ...props
}) => {
  return (
    <CustomSnackbar
      data-test-id={SNACK_BAR_TEST_ID}
      anchorOrigin={{
        vertical: verticalPosition || 'top',
        horizontal: horizontalPosition || 'center',
      }}
      autoHideDuration={autoHideDuration}
      onClose={() => setOpen(false)}
      {...props}
    >
      <Alert
        iconMapping={{ success: <SuccessIcon fontSize="inherit" /> }}
        onClose={() => setOpen(false)}
        severity={severity}
      >
        {text}
      </Alert>
    </CustomSnackbar>
  );
};

const SuccessIcon = styled(CheckCircleIcon)`
  fill: ${({ theme }) => theme.colors.success};
`;

export default SnackBar;
