import { getIsFeed, useProjects } from 'app/src/context/ProjectsStore';
import { useParams } from 'react-router-dom';
import { FEATURE_CUSTOM_DOMAIN } from '../constants/appFeatures.constants';
import { useFeatures } from 'app/src/context/FeaturesStore';
import APP_CONFIG from 'app/src/config/app.config';

const usePlayerUrl = id => {
  const { projectId = id } = useParams();
  const [{ project }] = useProjects({ projectId });
  const [, { getFeatureEnabled, getSettingsByKey }] = useFeatures();

  const getDomain = () => {
    const customDomainSettings = getSettingsByKey(FEATURE_CUSTOM_DOMAIN);
    const isCustomDomainEnabled = getFeatureEnabled(FEATURE_CUSTOM_DOMAIN);

    if (isCustomDomainEnabled) {
      return `https://${customDomainSettings?.domain}`;
    }

    return APP_CONFIG.PUBLISH_URL;
  };

  const getPathParameters = () => {
    const isFeed = getIsFeed(project);
    if (isFeed) {
      return 'feed/';
    }

    return '';
  };

  const path = `${getPathParameters()}${project?.publishId}`;
  const domain = getDomain();
  const playerUrl = `${domain}/${path}`;

  return {
    playerUrl,
    domain,
  };
};

export default usePlayerUrl;
