import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useApps } from 'app/src/context/AppsStore';
import { useProjectActions } from 'app/src/context/ProjectsStore';
import { useRulesActions } from 'app/src/context/RulesStore';
import { useDashboard } from 'app/src/context/ui_store/DashboardStore';
import { track } from 'app/src/helpers/Tracker';
import useNavigation from 'app/src/hooks/useNavigation';
import StraightArrowRightIcon from 'app/src/images/creation_method_icons/StraightArrowRightIcon';
import { ONSITE_TARGET_PAGES } from 'app/src/pages/dashboard/constants/onsiteTargetPages.constants';
import useAccountOnboarding from 'app/src/pages/dashboard/pages/homepage/onboarding/hooks/useAccountOnboarding';
import useAccountOnboardingTemplates from 'app/src/pages/dashboard/pages/homepage/onboarding/hooks/useAccountOnboardingTemplates';
import { OnboardingTemplateKey } from 'app/src/pages/dashboard/pages/homepage/onboarding/templates/constants/onboardingTemplates.constants';
import SelectCaseModal from 'app/src/pages/dashboard/components/cases/SelectCaseModal';
import {
  CASE_CONTENT,
  CaseGuide,
  cases,
  CASES_WAIT_LISTS_JOINED_LOCAL_STORAGE_KEY,
  CaseType,
  caseTypeToOnboardingTemplateKey,
} from 'app/src/pages/dashboard/components/cases/cases.constants';
import { CAMPAIGN_TILE_ACTIONS } from 'app/src/constants/campaignTileActions.constants';
import {
  CAROUSEL_TYPE,
  SPOTLIGHT_CAROUSEL_CONTENT_TILE_TYPE,
} from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import { publishMethodOptions, RuleGroupType, VodConnectionType } from 'app/src/types/entities';
import { TextH3Bold, TextSmall, TextTiny } from 'shared/react/components/basic/text/TextV2';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import ShopIcon from 'app/src/pages/dashboard/components/cases/images/icons/ShopIcon';
import usePreloadImages from 'app/src/hooks/usePreloadImages';
import CheckMarkIcon from 'src/images/CheckMarkIcon';
import { RulesService } from 'app/src/services/rules';
import useSelectAppProjects from 'app/src/hooks/useSelectedAppProjects';
import CasesGuide from './CasesGuide';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { useVodConnection } from 'src/context/VodConnectionStore';
import useGetSearchProducts from 'src/hooks/useGetSearchProducts';
import { SearchProductsPayload } from 'src/types/common';
import { useSnackBarActions } from 'src/context/ui_store/SnackBarStore';

type Props = {
  types: CaseType[];
  isOnboarding?: boolean;
  isEmptyState?: boolean;
  isOffsite?: boolean;
  guide?: CaseGuide;
};

const Cases = ({ types, isOnboarding, isEmptyState, isOffsite = false, guide }: Props) => {
  const [selectedType, setSelectedType] = useState<CaseType | null>(null);
  const [{ shopify, productsImport, selectedApp }] = useApps();
  const { onboardingStatuses } = useAccountOnboarding();
  const [{ onSiteFilters, offsiteFilters }] = useDashboard();
  const filters = isOffsite ? offsiteFilters : onSiteFilters;
  const { appUrl: selectedAppUrl, folder, layout } = filters;
  const { handleTemplateCreateClick } = useAccountOnboardingTemplates();
  const { navigateToProjectTab } = useNavigation();
  const { createProject, updateProject } = useProjectActions();
  const { saveRules, createDefaultDynamicRule } = useRulesActions();
  const [{ vodConnections }] = useVodConnection();
  const { selectedAppProjects } = useSelectAppProjects();
  const [joinedWaitLists, setJoinedWaitLists] = useLocalStorage(
    CASES_WAIT_LISTS_JOINED_LOCAL_STORAGE_KEY,
    {}
  );
  const preload = usePreloadImages();
  const getSearchProducts = useGetSearchProducts(selectedApp);
  const { setErrorSnackbar } = useSnackBarActions();

  useEffect(() => {
    const images = Object.values(CASE_CONTENT).flatMap(({ images }) => images);
    preload(images);
  }, []);

  const handleCaseClick = (type: CaseType) => {
    track('Click use-case', { type });
    setSelectedType(type);
  };

  const handleCloseModal = () => {
    setSelectedType(null);
  };

  const folderId = folder?.eq;
  const selectedLayout = layout?.satisfies?.args?.[0];
  const currentAppUrl = selectedAppUrl?.in?.[0] || shopify?.appUrl || productsImport?.appUrl;

  const createStory = async () => {
    const project = await createProject({
      isEcomFeed: true,
      publishMethod: publishMethodOptions.stories,
      dynamic: true,
      appUrl: currentAppUrl,
      folder: folderId,
      targetPage: ONSITE_TARGET_PAGES.ProductPages,
      widgetSettings: { isFullscreenFeed: false },
      namePrefix: CASE_CONTENT[CaseType.PRODUCT_PAGE_STORIES].title,
      useCaseName: cases[CaseType.PRODUCT_PAGE_STORIES].name,
    });
    return project;
  };

  const createCarousel = async () => {
    const project = await createProject({
      isEcomFeed: true,
      publishMethod: publishMethodOptions.carousel,
      dynamic: true,
      appUrl: currentAppUrl,
      folder: folderId,
      targetPage: ONSITE_TARGET_PAGES.HomePages,
      widgetSettings: { carouselType: CAROUSEL_TYPE.SPOTLIGHT, isFullscreenFeed: false },
      namePrefix: CASE_CONTENT[CaseType.HOMEPAGE_SPOTLIGHT].title,
      useCaseName: cases[CaseType.HOMEPAGE_SPOTLIGHT].name,
    });
    return project;
  };

  const createVideo = async () => {
    const project = await createProject({
      isEcomFeed: true,
      publishMethod: publishMethodOptions.videoPage,
      dynamic: true,
      appUrl: currentAppUrl,
      folder: folderId,
      targetPage: ONSITE_TARGET_PAGES.HomePages,
      widgetSettings: { isFullscreenFeed: false },
      namePrefix: CASE_CONTENT[CaseType.FOR_YOU].title,
      useCaseName: cases[CaseType.FOR_YOU].name,
    });
    return project;
  };

  const createFounderVideo = async () => {
    const project = await createProject({
      isEcomFeed: false,
      publishMethod: publishMethodOptions.bubble,
      dynamic: false,
      appUrl: currentAppUrl,
      folder: folderId,
      targetPage: ONSITE_TARGET_PAGES.HomePages,
      vertical: false,
      widgetSettings: {},
      namePrefix: CASE_CONTENT[CaseType.FOUNDER_VIDEO].title,
      useCaseName: cases[CaseType.FOUNDER_VIDEO].name,
    });
    return project;
  };

  const createHeroVideo = async () => {
    const project = await createProject({
      isEcomFeed: true,
      publishMethod: publishMethodOptions.hero,
      dynamic: false,
      appUrl: currentAppUrl,
      folder: folderId,
      targetPage: ONSITE_TARGET_PAGES.HomePages,
      vertical: true,
      widgetSettings: { carouselType: 'default', isFullscreenFeed: false },
      namePrefix: CASE_CONTENT[CaseType.HERO_VIDEO].title,
      useCaseName: cases[CaseType.HERO_VIDEO].name,
    });
    return project;
  };

  const createTapcart = async () => {
    const project = await createProject({
      isEcomFeed: true,
      publishMethod: publishMethodOptions.videoPage,
      dynamic: true,
      appUrl: currentAppUrl,
      folder: folderId,
      targetPage: ONSITE_TARGET_PAGES.HomePages,
      widgetSettings: { isFullscreenFeed: false },
      namePrefix: CASE_CONTENT[CaseType.TAPCART_APP].title,
      discover: false,
      isCreateShopifyTvPage: false,
      useCaseName: cases[CaseType.TAPCART_APP].name,
    });
    return project;
  };

  const createTapcartPdpCarousel = async () => {
    const project = await createProject({
      isEcomFeed: true,
      publishMethod: publishMethodOptions.carousel,
      dynamic: true,
      appUrl: currentAppUrl,
      folder: folderId,
      targetPage: ONSITE_TARGET_PAGES.ProductPages,
      widgetSettings: {
        isFullscreenFeed: false,
        carouselType: CAROUSEL_TYPE.SPOTLIGHT,
        spotlightCarouselContentTileType: SPOTLIGHT_CAROUSEL_CONTENT_TILE_TYPE.NONE,
      },
      namePrefix: CASE_CONTENT[CaseType.TAPCART_APP_PDP_CAROUSEL].title,
      discover: false,
      isCreateShopifyTvPage: false,
      useCaseName: cases[CaseType.TAPCART_APP_PDP_CAROUSEL].name,
    });
    return project;
  };

  const createFuego = async () => {
    const project = await createProject({
      isEcomFeed: true,
      publishMethod: publishMethodOptions.stories,
      dynamic: true,
      appUrl: currentAppUrl,
      folder: folderId,
      targetPage: ONSITE_TARGET_PAGES.ProductPages,
      widgetSettings: { isFullscreenFeed: false },
      namePrefix: cases[CaseType.FUEGO_PDP].namePrefix,
      useCaseName: cases[CaseType.FUEGO_PDP].name,
    });
    return project;
  };

  const createEmailTrending = async () => {
    const project = await createProject({
      isEcomFeed: true,
      publishMethod: publishMethodOptions.email,
      dynamic: true,
      appUrl: currentAppUrl,
      folder: folderId,
      targetPage: ONSITE_TARGET_PAGES.TvPages,
      widgetSettings: { isFullscreenFeed: true },
      namePrefix: CASE_CONTENT[CaseType.EMAIL_TRENDING].title,
      isCreateShopifyTvPage: true,
      useCaseName: cases[CaseType.EMAIL_TRENDING].name,
      emailSettings: {
        tileAction: CAMPAIGN_TILE_ACTIONS.openTolstoyTvPage,
      },
    });
    return project;
  };

  const createEmailAbandonmentRevival = async () => {
    const project = await createProject({
      isEcomFeed: true,
      publishMethod: publishMethodOptions.email,
      dynamic: true,
      appUrl: currentAppUrl,
      folder: folderId,
      targetPage: ONSITE_TARGET_PAGES.ProductPages,
      widgetSettings: {},
      namePrefix: CASE_CONTENT[CaseType.EMAIL_ABANDONMENT_REVIVAL].title,
      isCreateShopifyTvPage: true,
      useCaseName: cases[CaseType.EMAIL_ABANDONMENT_REVIVAL].name,
      emailSettings: {
        tileAction: CAMPAIGN_TILE_ACTIONS.openProductPage,
      },
    });
    return project;
  };

  const getDefaultExternalProductId = async () => {
    const productConnection = vodConnections.find(
      ({ type, appUrl }) => appUrl === selectedAppUrl && type === VodConnectionType.productId
    );

    if (productConnection) {
      return productConnection.externalProductId;
    }

    const body = {
      term: '',
      appUrl: selectedApp.appUrl,
      appKey: selectedApp.appKey,
    } as SearchProductsPayload;

    const { searchProducts } = await getSearchProducts(body);

    return searchProducts[0]?.productId;
  };

  const createEmailProductLaunch = async () => {
    const externalProductId = await getDefaultExternalProductId();
    if (!externalProductId) {
      setErrorSnackbar('Failed to create project. Please try again.');
      return;
    }

    const project = await createProject({
      isEcomFeed: true,
      publishMethod: publishMethodOptions.email,
      dynamic: true,
      appUrl: currentAppUrl,
      folder: folderId,
      targetPage: ONSITE_TARGET_PAGES.SpecificProductPage,
      widgetSettings: { isFullscreenFeed: false },
      namePrefix: CASE_CONTENT[CaseType.EMAIL_PRODUCT_LAUNCH].title,
      useCaseName: cases[CaseType.EMAIL_PRODUCT_LAUNCH].name,
      emailSettings: {
        tileAction: CAMPAIGN_TILE_ACTIONS.openProductPage,
        externalProductId,
      },
    });
    return project;
  };

  const createTilePdp = async () => {
    const project = await createProject({
      isEcomFeed: true,
      publishMethod: publishMethodOptions.tile,
      dynamic: true,
      appUrl: currentAppUrl,
      folder: folderId,
      targetPage: ONSITE_TARGET_PAGES.ProductPages,
      widgetSettings: { isFullscreenFeed: false },
      namePrefix: CASE_CONTENT[CaseType.TILE_PDP].title,
      useCaseName: cases[CaseType.TILE_PDP].name,
    });

    return project;
  };

  const createTolstoy = async (type: CaseType) => {
    track(`Click Create new use-caseCase from onsite`, { type, isEmptyState });

    let project;

    switch (type) {
      case CaseType.PRODUCT_PAGE_STORIES:
        project = await createStory();
        break;
      case CaseType.HOMEPAGE_SPOTLIGHT:
        project = await createCarousel();
        break;
      case CaseType.FOR_YOU:
        project = await createVideo();
        break;
      case CaseType.FOUNDER_VIDEO:
        project = await createFounderVideo();
        break;
      case CaseType.HERO_VIDEO:
        project = await createHeroVideo();
        break;
      case CaseType.TAPCART_APP:
        project = await createTapcart();
        break;
      case CaseType.TAPCART_APP_PDP_CAROUSEL:
        project = await createTapcartPdpCarousel();
        break;
      case CaseType.FUEGO_PDP:
        project = await createFuego();
        break;
      case CaseType.EMAIL_TRENDING:
        project = await createEmailTrending();
        break;
      case CaseType.EMAIL_ABANDONMENT_REVIVAL:
        project = await createEmailAbandonmentRevival();
        break;
      case CaseType.EMAIL_PRODUCT_LAUNCH:
        project = await createEmailProductLaunch();
        break;
      case CaseType.TILE_PDP:
        project = await createTilePdp();
        break;
      default:
        return;
    }

    if (!project) {
      return;
    }

    const rules = shopify ? [[createDefaultDynamicRule()]] : [[]];
    if (
      [
        CaseType.HOMEPAGE_SPOTLIGHT,
        CaseType.FOR_YOU,
        CaseType.TAPCART_APP,
        CaseType.EMAIL_TRENDING,
      ].includes(type)
    ) {
      rules.push(
        [RulesService.getTrendingVideosRule()],
        [RulesService.getAllowNonTaggedVideosRule()]
      );
    }

    const ruleGroup = { rules, enabled: true };
    await saveRules(ruleGroup, project, RuleGroupType.dynamic);

    if (
      [
        CaseType.EMAIL_ABANDONMENT_REVIVAL,
        CaseType.EMAIL_PRODUCT_LAUNCH,
        CaseType.EMAIL_TRENDING,
      ].includes(type)
    ) {
      const emailBubbleRules = [];
      emailBubbleRules.push(
        [RulesService.getEmailRule(project.publishId)],
        [RulesService.getNotTvPageRule()]
      );

      const emailBubbleRuleGroup = { rules: emailBubbleRules, enabled: true };
      await saveRules(emailBubbleRuleGroup, project, RuleGroupType.bubble);
    }

    navigateToProjectTab(project, '');
  };

  const startOnboarding = (type: CaseType) => {
    track(`Click Create new use-caseCase from onboarding`, { type, isEmptyState });
    const onboardingTemplateKey = caseTypeToOnboardingTemplateKey[type];
    handleTemplateCreateClick(onboardingTemplateKey);
    return;
  };

  const handleWaitListedCaseClick = (type: CaseType) => {
    track('Join Wait List Click', { type });
    setJoinedWaitLists({ ...joinedWaitLists, [type]: true });
    setSelectedType(null);
  };

  const handleCaseStartClick = async (type: CaseType) => {
    const { isWaitListedCase } = cases[type];

    if (isWaitListedCase) {
      return handleWaitListedCaseClick(type);
    }

    if (isOnboarding) {
      return startOnboarding(type);
    }

    createTolstoy(type);
  };

  return (
    <LayoutRoot>
      <Content isOnboarding={isOnboarding}>
        {types.map(type => {
          const { title, subtitle, titleIcon, image, rightContent, layout, isShopifyOnly } =
            cases[type];
          const isShop = type === CaseType.SHOP_VIDEOS;

          const isCompleted =
            isShop &&
            selectedApp?.isShopAppEligible &&
            onboardingStatuses?.templates?.[OnboardingTemplateKey.shopAppProject]?.isDone;

          if (
            (isEmptyState && selectedLayout && selectedLayout !== layout) ||
            (isShopifyOnly && !shopify)
          ) {
            return null;
          }

          const fixedIconSize = ![
            CaseType.TAPCART_APP,
            CaseType.TAPCART_APP_PDP_CAROUSEL,
            CaseType.EMAIL_TRENDING,
            CaseType.FUEGO_PDP,
          ].includes(type);

          return (
            <CaseContainer key={title}>
              <Case onClick={() => handleCaseClick(type)}>
                {isCompleted && (
                  <CompletedBadge>
                    <CheckMarkIcon />
                    <CompletedBadgeLabel>Completed!</CompletedBadgeLabel>
                  </CompletedBadge>
                )}
                <Header>
                  <div>
                    <TitleContainer isShop={isShop}>
                      {titleIcon && (
                        <TitleIconContainer $fixedSize={fixedIconSize}>
                          {titleIcon}
                        </TitleIconContainer>
                      )}
                      {isShop && (
                        <ShopIconContainer>
                          <ShopIcon />
                        </ShopIconContainer>
                      )}
                      <Title>{title}</Title>
                    </TitleContainer>
                    {subtitle && <Subtitle>{subtitle}</Subtitle>}
                  </div>
                  <RightArrowContainer>
                    <RightArrowIcon />
                  </RightArrowContainer>
                  {rightContent}
                </Header>
                <ImageContainer>
                  <img src={image} alt={title} />
                </ImageContainer>
              </Case>
            </CaseContainer>
          );
        })}
        <SelectCaseModal
          open={!!selectedType}
          onClick={handleCaseStartClick}
          onClose={handleCloseModal}
          type={selectedType}
        />
      </Content>
      <CasesGuide guide={guide} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  gap: 56px;
  width: 100%;
  height: 100%;
  padding: 40px;
  overflow-y: auto;
`;

const Content = styled.div<{ isOnboarding: boolean }>`
  gap: 24px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, max-content));
  grid-auto-rows: 1fr;
  justify-content: center;
  margin-top: ${({ isOnboarding }) => (isOnboarding ? '16px' : '0')};
`;

const RightArrowIcon = styled(StraightArrowRightIcon)`
  width: 24px;
  height: 24px;
`;

const CaseContainer = styled(Gap8VerticalFlex)`
  align-items: flex-start;
  justify-content: center;
  position: relative;
  height: 100%;
  max-width: 370px;
`;

const Case = styled(VerticalFlex)`
  overflow: hidden;
  cursor: pointer;
  border-radius: 8px;
  outline: 2px solid ${({ theme }) => theme.colors.neutralDark};
  background: ${({ theme }) => theme.colors.white};
  height: 100%;
  max-height: 500px;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primaryHover};
    box-shadow: 0 0 0 4px ${({ theme }) => theme.colors.primaryLight};
  }
`;

const RightArrowContainer = styled(VerticalFlex)`
  display: none;
  position: absolute;
  right: 24px;
  bottom: 16px;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) =>
    `linear-gradient(90deg, ${theme.getHexOpacity(theme.colors.white, 0)} 0%, ${
      theme.colors.white
    } 80%)`};

  ${Case}:hover & {
    display: flex;

    & svg path {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const Header = styled(HorizontalFlex)`
  position: relative;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px 24px 24px;
  flex-grow: 1;
`;

const TitleContainer = styled(HorizontalFlex)<{ isShop: boolean }>`
  gap: ${({ isShop }) => (isShop ? 6 : 8)}px;
  align-items: center;
`;

const TitleIconContainer = styled.div<{ $fixedSize: boolean }>`
  align-self: center;
  display: flex;

  & svg {
    width: ${({ $fixedSize }) => ($fixedSize ? '16px' : undefined)};
    height: ${({ $fixedSize }) => ($fixedSize ? '16px' : undefined)};
  }
  & svg path:not(.icon-path-mask) {
    fill: ${({ theme }) => theme.colors.neutralDarkest};
  }

  ${Case}:hover & svg path:not(.icon-path-mask) {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const ShopIconContainer = styled.div`
  display: flex;
  align-items: flex-end;
  padding-bottom: 2px;

  ${Case}:hover & svg path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const Title = styled(TextH3Bold)`
  ${Case}:hover & {
    -webkit-text-fill-color: unset;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const Subtitle = styled(TextSmall)`
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.neutralDark};

  ${Case}:hover & {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const ImageContainer = styled.div`
  background: ${({ theme }) => theme.colors.neutralDarkest};

  & > img {
    max-height: 374px;
    width: 100%;
    display: block;
  }
`;

const CompletedBadge = styled(HorizontalFlex)`
  position: absolute;
  left: 0;
  top: -32px;
  align-items: center;
  gap: 4px;
  padding: 4px 12px;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.success};

  svg path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const CompletedBadgeLabel = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.white};
`;

export default Cases;
