import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import arrayMove from 'array-move';
import { useProjects } from 'app/src/context/ProjectsStore';
import { useHistory } from 'react-router-dom';
import { getSteps } from 'app/src/utils/project.utils';
import { navigateToStep } from 'app/src/utils/navigation.utils';
import { defaultQuizProductStepData } from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/editStepDefaultData';
import Step from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/tolstoy_list/Step';
import Routes from 'app/src/helpers/Routes';
import AddSteps from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/tolstoy_list/AddSteps';
import Gap16HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap16HorizontalFlexWrap';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import Utils from 'shared/react/utils/utils';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { DASHBOARD_TABLET_MAX_WIDTH } from 'shared/react/utils/mediaQuery';
import MacrosModal from 'app/src/pages/project/pages/inbox/macros/MacrosModal';
import useNavigation from 'app/src/hooks/useNavigation';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { FEATURE_TOLSTOY_V2 } from 'shared/react/constants/features.constants';
import AddNewStep from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/tolstoy_list/add-new-step/AddNewStep';
import { FormProvider, useForm } from 'react-hook-form';
import { track } from 'app/src/helpers/Tracker';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { UNSAVED_CHANGES_MODAL_KEY } from 'app/src/constants/modals.constants';
import useAddStep from '../useAddStep';
import { useBuilderFormContext } from 'app/src/context/BuilderFormStore';
import { DEBOUNCE_TIME } from 'app/src/constants/builder.constants';
import useDrag from 'app/src/hooks/useDrag';

const TolstoyList = ({ project, selectedStepId, step }) => {
  const [isMacroModalOpen, setIsMacroModalOpen] = useState(false);
  const [isCreatingNewResultPart, setIsCreatingNewResultPart] = useState(false);
  const [blockedLocation, setBlockedLocation] = useState(null);
  const [isReadyToNavigate, setIsReadyToNavigate] = useState(false);
  const isMobile = useMediaQuery(`(${DASHBOARD_TABLET_MAX_WIDTH})`) || Utils.isMobile();
  const { navigateToProjectTab } = useNavigation();
  const [, { getFeatureEnabled }] = useFeatures();
  const isTolstoyV2Enabled = getFeatureEnabled(FEATURE_TOLSTOY_V2);
  const [, { setCurrentModal, setModalProps }] = useModal();
  const {
    onDragOver,
    handleDragStart,
    handleDrop,
    draggingToIndex,
    draggingFromIndex,
    loadingIndex,
  } = useDrag({ onDrop });
  const { openCreationFlowModal } = useAddStep(project);
  const {
    steps: builderFormSteps,
    stepsOrder: builderFormStepsOrder,
    setStepsOrder,
    builderForm: {
      isDirty: isBuilderDirty,
      dirtyFormToUpdate: { isLoading: isBuilderLoading },
    },
  } = useBuilderFormContext();
  const methods = useForm({ defaultValues: { stepsOrder: project?.stepsOrder || [] } });
  const {
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    formState: { isDirty, isSubmitting: isLoading },
  } = methods;
  const stepsOrderFormValues = watch();

  const history = useHistory();
  const [, { publishProject, copyMacroSteps, createProjectStep, getStepByName }] = useProjects();

  const init = stepsOrder => {
    const initialState = {
      stepsOrder: stepsOrder || project.stepsOrder,
    };

    reset(initialState);
    setStepsOrder({});
    setChangesIfNeeded();
  };

  const setValueDirty = useCallback(
    (target, value, shouldDirty = true) => setValue(target, value, { shouldDirty }),
    []
  );

  const setChangesIfNeeded = () => {
    if (!builderFormStepsOrder?.stepsOrder) {
      return;
    }

    setValueDirty('stepsOrder', builderFormStepsOrder.stepsOrder);
  };

  const steps = useMemo(() => {
    const hasUpdated = stepsOrderFormValues.stepsOrder.length !== project.stepsOrder.length;
    if (hasUpdated) {
      init();
    }

    const projectSteps = getSteps(project, stepsOrderFormValues.stepsOrder);

    return projectSteps.map(step => builderFormSteps[step.id]?.step || step);
  }, [project?.steps, project?.stepsOrder, builderFormSteps, isDirty, stepsOrderFormValues]);

  async function onDrop(index) {
    const stepsOrder = getValues('stepsOrder');
    arrayMove(stepsOrder, draggingFromIndex, index);
    const newStepsOrder = arrayMove(stepsOrder, draggingFromIndex, index);

    setValueDirty('stepsOrder', newStepsOrder);
  }

  const openUnsavedChangesModal = callback => {
    setModalProps({ onLeavePage: callback });
    setCurrentModal(UNSAVED_CHANGES_MODAL_KEY);
  };

  const checkUnsavedChanges = callback => {
    if (isReadyToNavigate || !isBuilderDirty) {
      callback();
      return;
    }

    openUnsavedChangesModal(callback);
  };

  const handleEditStepClick = step => {
    const route = Routes.getBuilderV2Route()
      .replace(Routes.getProjectIdParameter(), project.id)
      .replace(Routes.getStepIdOptionalParameter(), step.id);

    const locationState = { isReadyToNavigate: true };

    if (isTolstoyV2Enabled) {
      navigateToProjectTab(project, step.id, locationState);
      return;
    }

    history.push({
      pathname: route,
      state: locationState,
    });
  };

  const onAddProductResultClick = () => {
    checkUnsavedChanges(onAddProductResult);
  };

  const onAddProductResult = async () => {
    setIsCreatingNewResultPart(true);
    const newStep = {
      ...defaultQuizProductStepData(false, true),
      projectId: project.id,
    };
    const newProject = await createProjectStep(newStep, project);
    const lastStepName = newProject.stepsOrder[newProject.stepsOrder.length - 1];
    const step = getStepByName(lastStepName, newProject);
    await publishProject(newProject.id);
    navigateToStep(history, project.id, step.id);
    setIsCreatingNewResultPart(false);
  };

  const onAddMacrosClick = () => {
    checkUnsavedChanges(onAddMacros);
  };

  const onAddMacros = () => {
    setIsMacroModalOpen(true);
  };

  const addMacroSteps = async fromProject => {
    await copyMacroSteps(fromProject.id, project.id);
    setIsMacroModalOpen(false);
  };

  const onAddNewPartClick = () => {
    checkUnsavedChanges(onAddNewPart);
  };

  const onAddNewPart = () => {
    track('Add New Part Click');
    openCreationFlowModal({ project, isFeed: project.feed });
  };

  const getAddStepComponent = () => {
    if (isTolstoyV2Enabled) {
      return (
        <AddNewStep
          project={project}
          onAddProductResult={onAddProductResultClick}
          checkUnsavedChanges={checkUnsavedChanges}
          setIsReadyToNavigate={setIsReadyToNavigate}
        />
      );
    }

    return (
      <AddSteps
        onAddMacros={onAddMacrosClick}
        isCreatingNewResultPart={isCreatingNewResultPart}
        project={project}
        onAddProductResult={onAddProductResultClick}
        onAddNewPart={onAddNewPartClick}
      />
    );
  };
  const addStepsComponent = getAddStepComponent();

  const onSave = useCallback(async () => {
    const stepsOrder = getValues('stepsOrder');
    init(stepsOrder);
  }, [isDirty]);

  const onCancel = () => {
    init();
  };

  const onSaveClickCallback = useCallback(() => {
    handleSubmit(onSave)();
  }, [onSave]);

  useEffect(() => {
    return history.block(nextLocation => {
      const locationIsReadyToNavigate = nextLocation.state?.isReadyToNavigate;

      if (isBuilderLoading) {
        return false;
      }

      if (isReadyToNavigate || !isBuilderDirty) {
        // Navigate normally
        setIsReadyToNavigate(false);
        return true;
      }

      if (locationIsReadyToNavigate) {
        // Wate for debounce changes before navigation
        setBlockedLocation(nextLocation);
        setTimeout(() => {
          setIsReadyToNavigate(true);
        }, DEBOUNCE_TIME);
        return false;
      }

      setBlockedLocation(nextLocation);
      openUnsavedChangesModal(() => setIsReadyToNavigate(true));

      // Block navigation
      return false;
    });
  }, [history, isReadyToNavigate, isBuilderDirty, isBuilderLoading]);

  useEffect(() => {
    if (isReadyToNavigate && blockedLocation) {
      // Retry navigation
      history.push(blockedLocation.pathname);
    }
  }, [isReadyToNavigate, blockedLocation, history]);

  useEffect(() => {
    const formStepsOrder = getValues('stepsOrder') || [];
    if (!formStepsOrder) {
      return;
    }

    if (!isDirty) {
      setStepsOrder({});
      return;
    }

    setStepsOrder({
      stepsOrder: formStepsOrder,
      isDirty,
      isLoading,
      onSave: onSaveClickCallback,
      onDiscard: onCancel,
    });
  }, [stepsOrderFormValues, isDirty, isLoading, onSaveClickCallback]);

  useEffect(() => {
    init();
  }, []);

  return (
    <FormProvider {...methods}>
      <LayoutRoot isLoading={!Utils.isNullOrUndefined(loadingIndex)} shouldUseMarginRight={!!step}>
        <NormalHorizontalFlex>
          <StyledSortedListBoxContainer flexWrap={!step}>
            {steps.map((step, index) => {
              return (
                <StyledSortableElement
                  key={step.id}
                  onDragStart={e => handleDragStart(e, index)}
                  onDrop={e => handleDrop(e, index)}
                  onDragOver={e => onDragOver(e, index)}
                >
                  <Step
                    loadingIndex={loadingIndex}
                    isSelected={step.id === selectedStepId}
                    step={step}
                    order={index}
                    project={project}
                    onEditStepClick={handleEditStepClick}
                    draggingOver={draggingToIndex === index}
                    onAddNewPart={onAddNewPartClick}
                  />
                </StyledSortableElement>
              );
            })}
            {(!step || isMobile) && addStepsComponent}
          </StyledSortedListBoxContainer>
          {!!step && !isMobile && addStepsComponent}
        </NormalHorizontalFlex>
        <MacrosModal
          addMacroSteps={addMacroSteps}
          isAddStep={true}
          open={isMacroModalOpen}
          stepsLength={project?.stepsCount}
          closeModal={() => setIsMacroModalOpen(false)}
        />
      </LayoutRoot>
    </FormProvider>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)`
  height: 100%;
  overflow-y: auto;
  flex-grow: 1;
  margin-right: ${({ shouldUseMarginRight }) => (shouldUseMarginRight ? '24px' : '')};
  pointer-events: ${({ isLoading }) => (isLoading ? 'none' : 'auto')};
  margin-top: 16px;

  @media (${({ theme }) => theme.breakpoints.laptopMax}) {
    gap: 8px;
  }
`;

const StyledSortedListBoxContainer = styled(Gap16HorizontalFlexWrap)`
  padding-bottom: 20px;
  flex-wrap: ${({ flexWrap }) => (flexWrap ? 'wrap' : 'nowrap')};
  overflow: auto;
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    justify-content: center;
    flex-wrap: wrap;
  }
`;

const NormalHorizontalFlex = styled(Gap16HorizontalFlexWrap)`
  flex-wrap: nowrap;
  overflow: hidden;
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    flex-wrap: wrap;
  }
`;

const StyledSortableElement = styled.div``;

export default TolstoyList;
