import React from 'react';

const StandIcon = () => {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.469 6.469a1.529 1.529 0 0 1-.406-.375c-.126.156-.25.281-.407.375A2.733 2.733 0 0 1 12.031 7c-.625 0-1.187-.188-1.625-.531a1.646 1.646 0 0 1-.437-.375 1.53 1.53 0 0 1-.406.375c-.438.343-1 .531-1.626.531a2.734 2.734 0 0 1-1.625-.531 1.53 1.53 0 0 1-.406-.375 1.53 1.53 0 0 1-.406.375A2.734 2.734 0 0 1 3.875 7H3.5C1.75 6.75.937 4.719 1.875 3.25L3.656.437A.842.842 0 0 1 4.406 0h11.157c.312 0 .593.156.75.438l1.78 2.812c.938 1.469.126 3.5-1.624 3.75h-.375c-.625 0-1.188-.188-1.625-.531ZM15.5 1H4.469l-1.75 2.781c-.594.938 0 2.094.906 2.219h.25c.5 0 .969-.219 1.281-.563a.998.998 0 0 1 .75-.343c.282 0 .563.125.75.343.313.344.782.563 1.282.563.53 0 .968-.219 1.312-.563a.934.934 0 0 1 .719-.343c.281 0 .562.125.75.343.312.344.781.563 1.312.563.5 0 .969-.219 1.281-.563a.998.998 0 0 1 .75-.343c.282 0 .532.125.72.343.343.344.78.563 1.312.563h.25c.906-.125 1.5-1.281.906-2.219L15.5 1ZM4 8.5V11h12V8.5c0-.25.219-.5.5-.5.25 0 .5.25.5.5V14c0 1.125-.906 2-2 2H5c-1.125 0-2-.875-2-2V8.5c0-.25.219-.5.5-.5.25 0 .5.25.5.5ZM4 14c0 .563.438 1 1 1h10c.531 0 1-.438 1-1v-2H4v2Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default StandIcon;
