import React, { FC } from 'react';

import Menu from 'shared/react/components/basic/Menu';
import { MenuLayout } from 'src/pages/dashboard/components/filters/components/filter-block/FilterBlock';

type Props = {
  anchorRef?: JSX.Element;
  onClose: () => void;
  minHeight?: number;
};

const MenuWrapper: FC<Props> = ({ anchorRef, onClose, children, minHeight }) => {
  return (
    <Menu
      open={!!anchorRef}
      anchorEl={anchorRef}
      onClose={onClose}
      direction={{ vertical: 'bottom', horizontal: 'left' }}
      canCloseMenu={undefined}
      className={undefined}
    >
      <MenuLayout minHeight={minHeight}>{children}</MenuLayout>
    </Menu>
  );
};

export default MenuWrapper;
