import React from 'react';

const ScreenIcon = ({ fill = '#50545E' }) => {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 0H2C.875 0 0 .906 0 2v9c0 1.125.875 2 2 2h4.625l-.313 1.5H4.75a.74.74 0 0 0-.75.75c0 .438.313.75.75.75h8.5a.74.74 0 0 0 .75-.75.76.76 0 0 0-.75-.75h-1.594l-.312-1.5H16c1.094 0 2-.875 2-2V2c0-1.094-.906-2-2-2Zm-5.875 14.5H7.844l.312-1.5h1.656l.313 1.5ZM16.5 11c0 .281-.25.5-.5.5H2a.494.494 0 0 1-.5-.5V2c0-.25.219-.5.5-.5h14c.25 0 .5.25.5.5v9Z"
        fill={fill}
      />
    </svg>
  );
};

export default ScreenIcon;
