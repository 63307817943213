import { SITE_ACTIVITY_LOG } from 'app/src/constants/analytics.constants';
import { CUBE_PROPERTY_KEYS } from 'app/src/constants/cube.constants';
import {
  GetTimeOnPageMetricsProps,
  GetTimeOnPageUpliftMetricsProps,
} from './types/getTimeOnPageMetrics.types';
import { getUplift } from 'app/src/services/cube/cube.utils';

export const getTimeOnPageMetrics = ({ timeOnPage }: GetTimeOnPageMetricsProps) => {
  return timeOnPage.map(({ x, xValues, ...timeOnPage }) => {
    const {
      [`${SITE_ACTIVITY_LOG}.${CUBE_PROPERTY_KEYS.averageTimeOnPage}`]: averageTimeOnPage,
      [`${SITE_ACTIVITY_LOG}.${CUBE_PROPERTY_KEYS.averageTimeOnTolstoy}`]: averageTimeOnTolstoy,
      [`${SITE_ACTIVITY_LOG}.${CUBE_PROPERTY_KEYS.sumTimeOnTolstoy}`]: sumTimeOnTolstoy,
      [`${SITE_ACTIVITY_LOG}.${CUBE_PROPERTY_KEYS.averageTimeOnPageWhenInteractingTolstoy}`]:
        averageTimeOnPageWhenInteractingTolstoy,
      [`${SITE_ACTIVITY_LOG}.${CUBE_PROPERTY_KEYS.numOfInteractingVisits}`]: numOfInteractingVisits,
      [`${SITE_ACTIVITY_LOG}.${CUBE_PROPERTY_KEYS.numOfNonInteractingVisits}`]:
        numOfNonInteractingVisits,
      [`${SITE_ACTIVITY_LOG}.${CUBE_PROPERTY_KEYS.numOfNonInteractingVisitsUnique}`]:
        numOfNonInteractingVisitsUnique,
    } = timeOnPage;
    return {
      x,
      xValues,
      averageTimeOnPage,
      averageTimeOnTolstoy,
      sumTimeOnTolstoy,
      averageTimeOnPageWhenInteractingTolstoy,
      numOfInteractingVisits,
      numOfNonInteractingVisits,
      numOfNonInteractingVisitsUnique,
    };
  });
};

export const getTimeOnPageUpliftMetrics = ({ timeOnPageData }: GetTimeOnPageUpliftMetricsProps) => {
  if (!timeOnPageData) {
    return {};
  }

  let sumOfAverageTimeOnPage = 0;
  let sumOfAverageTimeOnPageWhenInteractingTolstoy = 0;
  let sumOfNonInteractingVisits = 0;
  let sumOfInteractingVisits = 0;

  for (const {
    averageTimeOnPage,
    averageTimeOnPageWhenInteractingTolstoy,
    numOfNonInteractingVisits,
    numOfInteractingVisits,
  } of timeOnPageData) {
    sumOfAverageTimeOnPage += averageTimeOnPage * numOfNonInteractingVisits;
    sumOfAverageTimeOnPageWhenInteractingTolstoy +=
      averageTimeOnPageWhenInteractingTolstoy * numOfInteractingVisits;
    sumOfNonInteractingVisits += numOfNonInteractingVisits;
    sumOfInteractingVisits += numOfInteractingVisits;
  }

  const totalAverageTimeOnPage = sumOfAverageTimeOnPage / sumOfNonInteractingVisits;
  const totalAverageTimeOnPageWhenInteractingTolstoy =
    sumOfAverageTimeOnPageWhenInteractingTolstoy / sumOfInteractingVisits;

  const timeOnPageUplift = getUplift({
    value: totalAverageTimeOnPageWhenInteractingTolstoy,
    baseline: totalAverageTimeOnPage,
  });

  return {
    timeOnPageUplift,
    totalAverageTimeOnPage,
    totalAverageTimeOnPageWhenInteractingTolstoy,
  };
};
