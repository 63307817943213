import React from 'react';
import BottomArrowIcon from 'shared/react/images/BottomArrowIcon';
import styled from 'styled-components';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import { TextTiny } from 'shared/react/components/complex/Text';
import { useApps } from 'app/src/context/AppsStore';
import { getNextStepDescription } from 'app/src/utils/step.utils';

const AnswerNext = ({
  stepsOrder,
  next,
  type,
  isProductType,
  onChooseAnswerNext,
  index,
  productCtaType,
  isFeed,
  ecomPlatform,
}) => {
  const [{ magento }] = useApps();

  const shouldShowArrowIcon = !isProductType || (isFeed && !magento);
  return (
    <NextContainer
      isProductType={isProductType}
      shouldShowArrowIcon={shouldShowArrowIcon}
      onClick={e => onChooseAnswerNext?.(e, index)}
    >
      <Text>
        {getNextStepDescription({
          stepsOrder,
          nextStep: next,
          type,
          isProductType,
          productCtaType,
          ecomPlatform,
        })}
      </Text>
      {shouldShowArrowIcon && <BottomArrowIcon width={12} height={12} />}
    </NextContainer>
  );
};

const Text = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.white};
  white-space: nowrap;
`;

const NextContainer = styled(HorizontalFlexWrap)`
  background: ${({ theme, isProductType }) =>
    isProductType ? theme.colors.success : theme.colors.black};
  width: 90px;
  height: 24px;
  padding: 0 8px;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: ${({ shouldShowArrowIcon }) =>
    shouldShowArrowIcon ? 'space-between' : 'center'};
  border-radius: 65px;
  gap: 4px;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  & path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export default AnswerNext;
