import style from 'widget/src/widgets/EmbedWidgets/SpotlightCarousel/SpotlightCarousel.module.css';
import { logDevelopmentMessage } from 'widget/src/utils';
import { CAROUSEL_CREATOR_PROFILE_LINK_POSITION } from 'widget/src/constants/widgetConstants';

// TODO: replace magical strings with SHARED ACROSS ALL REPOS!!! constants
const getProfileLink = (handle, provider = 'tiktok') => {
  if (provider === 'tiktok') return `https://www.tiktok.com/${handle}/`;
  if (provider === 'instagram') return `https://www.instagram.com/${handle}/`;
};

function ensureAtSign(string) {
  if (!string) return '';
  if (string[0] === '@') return string;
  return '@' + string;
}

export const handleLinkString = ({
  step,
  showCreatorProfileLink,
  carouselCreatorProfileLinkPosition
}) => {
  try {
    const { handle, provider } = step?.externalProviderData || {};

    const shouldHide = !(showCreatorProfileLink && handle);

    if (shouldHide) {
      return '';
    }

    const handleLink = getProfileLink(handle, provider);

    return `
      <div
        class="${style.handleContainer}"
        style="${
          carouselCreatorProfileLinkPosition === CAROUSEL_CREATOR_PROFILE_LINK_POSITION.TOP
            ? 'top: 4px'
            : 'bottom: 16px'
        }"
      >
        <a
          target="_blank"
          rel="noopener noreferrer"
          href=${handleLink}
          class="${style.handleLink}"
        >
          ${ensureAtSign(handle)}
        </a>
      </div>
    `;
  } catch (error) {
    logDevelopmentMessage(error);
  }
};
