import React from 'react';
import GaugeMeterIcon from 'app/src/images/GaugeMeterIcon';
import styled from 'styled-components';
import Tooltip from 'app/src/basic_components/Tooltip';

const getGaugeMeterPointerRotation = (benchmarkValue: number) => {
  if (!+benchmarkValue) {
    return 0;
  }

  return (benchmarkValue / Math.abs(benchmarkValue)) * 60;
};

const AnalyticsMetricsBoxBenchmark = ({
  benchmarkTitle,
  benchmarkValue,
  category,
  loading = false,
}) => {
  if (loading || !benchmarkTitle || isNaN(benchmarkValue)) {
    return null;
  }

  const isError = benchmarkValue < 0;
  const titleMessage = isError ? 'lower than your category' : 'higher than your category';
  const categoryTitle = category ? `(${category})` : '';

  return (
    <Tooltip text={[benchmarkTitle, titleMessage, categoryTitle].join(' ')}>
      <StyledGaugeMeterIcon pointerRotate={getGaugeMeterPointerRotation(benchmarkValue)} />
    </Tooltip>
  );
};

const StyledGaugeMeterIcon = styled(GaugeMeterIcon)`
  transform: scale(1.6) translateY(-2px);

  path {
    fill: ${({ theme }) => theme.colors.neutralDark};
  }
`;

export default AnalyticsMetricsBoxBenchmark;
