import React from 'react';
import styled from 'styled-components';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import WarningMessageIcon from 'app/src/images/WarningMessageIcon';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';

type Props = {
  message: string;
  linkText?: string;
  onLinkClick?: () => void;
  className?: string;
};
const WarningMessage = ({ message, linkText, onLinkClick, className }: Props) => {
  return (
    <LayoutRoot className={className}>
      <WarningMessageIcon />
      <TextContainer>
        <Text>{message}</Text>
        {linkText && <LinkText onClick={onLinkClick}>{linkText}</LinkText>}
      </TextContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlex)`
  padding: 8px 12px;
  border: 1px solid ${({ theme }) => theme.colors.neutralGray};
  border-radius: 4px;
  width: max-content;
  box-shadow: 0px 2px 4px rgba(50, 50, 93, 0.1);
`;

const Text = styled(TextTiny)`
  max-width: 244px;
`;

const LinkText = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.primary};
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.primaryHover};
  }
`;

const TextContainer = styled(Gap8VerticalFlex)``;

export default WarningMessage;
