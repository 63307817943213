import React, { useState } from 'react';
import useHeroPreview from 'app/src/pages/project/pages/look_and_feel/preview/hero-preview/useHeroPreview';
import Hero from '@tolstoy/hero/src/Hero';
import { Config } from '@tolstoy/hero/src/types/config';
import MobileDesktopPreviewToggle from 'app/src/pages/creation_flow/right_section/video_container/video_overlay/MobileDesktopPreviewToggle';
import { PREVIEW_DESKTOP_KEY, PREVIEW_MOBILE_KEY } from 'app/src/constants/tolstoy.constants';
import styled from 'styled-components';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';

const HeroPreview = () => {
  const { config } = useHeroPreview();
  const [isMobile, setIsMobile] = useState(false);

  if (!config?.steps?.length) {
    return;
  }

  const setSelectedPreviewMode = selectedPreviewMode => {
    setIsMobile(selectedPreviewMode === PREVIEW_MOBILE_KEY);
  };

  const previewMode = isMobile ? PREVIEW_MOBILE_KEY : PREVIEW_DESKTOP_KEY;

  return (
    <LayoutRoot>
      <MobileDesktopPreviewToggle
        selectedPreviewMode={previewMode}
        setSelectedPreviewMode={setSelectedPreviewMode}
        leftTooltipText="Desktop"
        rightTooltipText="Mobile"
      />
      <div className="self-center">
        <HeroContainer>
          <Hero isMobile={isMobile} config={config as unknown as Config} onSendEvent={() => {}} />
        </HeroContainer>
      </div>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)`
  width: 100%;
  align-items: center;

  .tolstoy-hero-video-container {
    width: fit-content;
    height: fit-content;
  }
`;

const HeroContainer = styled.div``;

export default HeroPreview;
