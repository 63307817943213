import React from 'react';
import { ChevronsUpDown } from 'lucide-react';
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'ui-library/core';
import { SortOrder, ORDER_LABEL } from '../../../constants';

type OrderDropdownProps = {
  order: SortOrder;
  onOrderChange: (order: SortOrder) => void;
};

export const OrderDropdown = ({ order, onOrderChange }: OrderDropdownProps) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="sm">
          {ORDER_LABEL[order]}
          <ChevronsUpDown className="ml-2 h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-[100px]">
        <DropdownMenuItem onSelect={() => onOrderChange(SortOrder.DESC)}>
          {ORDER_LABEL[SortOrder.DESC]}
        </DropdownMenuItem>
        <DropdownMenuItem onSelect={() => onOrderChange(SortOrder.ASC)}>
          {ORDER_LABEL[SortOrder.ASC]}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default OrderDropdown;
