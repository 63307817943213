import React from 'react';

const SortArrow = props => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.781 8.281c.281-.312.281-.781-.031-1.062a.746.746 0 0 0-1.063.031L7.72 11.406V.781a.722.722 0 0 0-.75-.75.74.74 0 0 0-.75.75v10.625L2.28 7.25a.746.746 0 0 0-1.062-.031c-.313.281-.313.75-.032 1.062l5.25 5.5A.732.732 0 0 0 6.97 14a.753.753 0 0 0 .562-.219l5.25-5.5Z"
      fill="#3D4FE0"
    />
  </svg>
);

export default SortArrow;
