import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { TextBody } from 'shared/react/components/complex/Text';
import Button from 'shared/react/components/complex/Button';
import Tooltip from 'app/src/basic_components/Tooltip';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';
import { track } from 'app/src/helpers/Tracker';
import Utils from 'app/src/utils';
import { REMOVE_ADDON_MODAL_KEY } from 'app/src/constants/modals.constants';
import { useModal } from 'app/src/context/ui_store/ModalStore';

const PlanAddon = ({ id, title, description, price, active, disabled, name, removeModalText }) => {
  const [{ isExpectingDelayedFetch }, { addAddon }] = useFeatures();
  const [, { setErrorSnackbar }] = useSnackBar();
  const [, { setCurrentModal, setModalProps }] = useModal();
  const [enabled, setEnabled] = useState(active);
  const [loading, setLoading] = useState(false);
  const formattedPrice = `+$${price}`;
  const buttonText = enabled ? 'Remove' : 'Add';
  const tooltipText = disabled ? 'To enable the add-on upgrade to Pro' : '';

  const handleAddAddonClicked = async () => {
    setLoading(true);
    const { error, returnUrl } = (await addAddon(id)) || {};

    if (returnUrl) {
      track(`Shopify ${name} Add Addon Click`);
      return Utils.openInTheSameTab(returnUrl);
    }

    setLoading(false);

    if (error) {
      setErrorSnackbar('Something went wrong. please try again');
      return;
    }

    track(`${name} Addon Upgraded`);

    setEnabled(!enabled);
  };

  const handleRemoveAddonClicked = () => {
    setModalProps({ removeModalText, addonId: id, name, setEnabled });
    setCurrentModal(REMOVE_ADDON_MODAL_KEY);
  };

  useEffect(() => {
    setEnabled(active);
  }, [active]);

  return (
    <StyledPlanAddon>
      <AddonText>
        <AddonTitle>{title}</AddonTitle>
        <AddonDescription>{description}</AddonDescription>
      </AddonText>
      <AddonPrice>
        <span>{formattedPrice}</span>/mo
      </AddonPrice>
      <Tooltip placement="bottom" header={tooltipText}>
        <AddonActionButton
          disabled={disabled}
          onClick={enabled ? handleRemoveAddonClicked : handleAddAddonClicked}
          loading={loading || isExpectingDelayedFetch}
        >
          {buttonText}
        </AddonActionButton>
      </Tooltip>
    </StyledPlanAddon>
  );
};

export default PlanAddon;

const StyledPlanAddon = styled(HorizontalFlexWrap)`
  max-width: 810px;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  align-items: center;
  border-radius: 12px;
  justify-content: space-between;
`;

const AddonText = styled(Gap8VerticalFlex)`
  max-width: 305px;
`;

const AddonTitle = styled(TextBody)`
  font-weight: 800;
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.black};
`;

const AddonDescription = styled(TextBody)`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.gray2};
`;

const AddonPrice = styled.div`
  & > span {
    font-weight: 800;
    font-size: 20px;
    line-height: 28px;
    color: ${({ theme }) => theme.colors.black};
  }
`;

const AddonActionButton = styled(Button)``;
