import React from 'react';

const ShoppingBagIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.989 3.297H3.318c-.5 0-.911.392-.936.89l-.38 7.616a.937.937 0 0 0 .82.977l6.167.77V3.298Zm.469 10.288a.936.936 0 0 0 .202-.043l1.9-.633a.938.938 0 0 0 .636-.983l-.778-7.785a.938.938 0 0 0-.933-.844H9.458v10.288Z"
        fill="#EAECF0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.071 4c.032-.908.25-1.719.556-2.31a2.47 2.47 0 0 1 .545-.734c.192-.165.374-.237.536-.237.161 0 .344.072.535.237.033.03.066.06.1.094a3.33 3.33 0 0 0-.124.187c-.355.582-.616 1.38-.709 2.278l.466.048c.087-.841.33-1.57.643-2.081l.026-.042c.05.078.097.161.144.25.306.591.523 1.402.555 2.31.005.13.11.234.239.234.13 0 .234-.105.23-.234-.032-.976-.265-1.862-.608-2.525a3.634 3.634 0 0 0-.257-.425c.22-.226.442-.331.635-.331.29 0 .643.238.963.763.223.365.411.84.532 1.39l.478-.005c-.129-.633-.343-1.192-.61-1.63C8.6.67 8.123.25 7.583.25c-.344 0-.662.17-.938.44A2.064 2.064 0 0 0 6.55.602C6.301.387 6.015.25 5.708.25c-.307 0-.594.137-.842.352-.248.214-.47.515-.656.873-.343.663-.576 1.549-.608 2.525a.228.228 0 0 0 .23.234c.13 0 .234-.105.24-.234Z"
        fill="#EAECF0"
      />
    </svg>
  );
};

export default ShoppingBagIcon;
