import CreateNewTolstoy from 'app/src/pages/create_new_tolstoy/CreateNewTolstoy';
import React from 'react';
import Menu from 'shared/react/components/basic/Menu';
import { BREAKPOINT_OPTIONS } from 'shared/react/constants/menu.constants';
import { useIsMobile } from 'shared/react/hooks/useIsMobile';
import styled from 'styled-components';

const CreateNewTolstoyButtonMenu = ({
  anchorEl,
  setAnchorEl,
  setLoading,
  location,
  isOffsite,
  isEmptyState = false,
  appUrl,
  currentFolder,
  publishMethod = null,
}) => {
  const isMobile = useIsMobile();

  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <StyledMenu
      onClose={onClose}
      open={!!anchorEl}
      anchorEl={anchorEl}
      breakpoint={BREAKPOINT_OPTIONS.moveToTop}
      direction={{ horizontal: 'right', vertical: 'bottom' }}
      endScreenDistance={isMobile ? 8 : 24}
    >
      <CreateNewTolstoy
        setLoading={setLoading}
        location={location}
        onCloseMenu={onClose}
        isOffsite={isOffsite}
        isEmptyState={isEmptyState}
        appUrl={appUrl}
        currentFolder={currentFolder}
        publishMethod={publishMethod}
      />
    </StyledMenu>
  );
};

const StyledMenu = styled(Menu)`
  box-shadow: 0 18px 15px rgb(0 0 0 / 8%);
  border-radius: 12px;
  width: 448px;
`;

export default CreateNewTolstoyButtonMenu;
