import React from 'react';

const TrashIcon = ({ ...props }) => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.5 12.5c0 .281-.25.5-.5.5a.494.494 0 0 1-.5-.5v-7c0-.25.219-.5.5-.5.25 0 .5.25.5.5v7Zm3 0c0 .281-.25.5-.5.5a.494.494 0 0 1-.5-.5v-7c0-.25.219-.5.5-.5.25 0 .5.25.5.5v7Zm3 0c0 .281-.25.5-.5.5a.494.494 0 0 1-.5-.5v-7c0-.25.219-.5.5-.5.25 0 .5.25.5.5v7ZM9.687.719 10.5 2h3c.25 0 .5.25.5.5 0 .281-.25.5-.5.5H13v10.5c0 1.406-1.125 2.5-2.5 2.5h-7A2.468 2.468 0 0 1 1 13.5V3H.5a.494.494 0 0 1-.5-.5c0-.25.219-.5.5-.5h2.969L4.28.719A1.47 1.47 0 0 1 5.531 0h2.907c.5 0 1 .281 1.25.719ZM4.625 2h4.719l-.5-.75A.488.488 0 0 0 8.438 1H5.53a.488.488 0 0 0-.406.25l-.5.75ZM2 13.5c0 .844.656 1.5 1.5 1.5h7a1.5 1.5 0 0 0 1.5-1.5V3H2v10.5Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default TrashIcon;
