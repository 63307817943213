import React, { useState } from 'react';
import styled from 'styled-components';

import { ComparisonOperator, OperatorValue } from 'src/pages/dashboard/utils/filterEntities';
import menuConfig from 'src/pages/dashboard/components/filters/filtersConfig';
import MenuWrapper from 'src/pages/dashboard/components/filters/components/menu-layout/MenuLayout';
import useActions from 'src/pages/dashboard/components/filters/hooks/useActions';
import { FilterOption } from 'src/pages/dashboard/components/filters/filtersConfig';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { LightTextTiny } from 'shared/react/components/complex/Text';
import { CheckboxV2 } from 'src/complex_components/Checkbox';
import { FilterTypeRow } from 'app/src/pages/dashboard/components/filters/components/filter-block/FilterBlock';
import { SearchInput } from 'app/src/basic_components/input/InputWithIcon';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

type Props = {
  filterKey: string;
  anchorRef?: JSX.Element;
  onClose: () => void;
  currentFilter: ComparisonOperator;
  setFilters: (filters: { [key: string]: ComparisonOperator }) => void;
  actions: ReturnType<typeof useActions>;
};

type ParseCurrentFilterReturnType = {
  selectedComparator: string;
  selectedItems?: OperatorValue[];
};

export const parseCurrentFilter = (
  currentFilter: ComparisonOperator = {}
): ParseCurrentFilterReturnType => {
  const comparatorKey = Object.keys(currentFilter)[0];

  const selectedItems =
    currentFilter?.[comparatorKey]?.args || currentFilter?.[comparatorKey] || [];

  return { selectedComparator: comparatorKey, selectedItems };
};

const getIsChecked = (value, id) => {
  if (value === id) {
    return true;
  }

  if (Array.isArray(value)) {
    return value.includes(id);
  }

  return false;
};

const FilterByKeyMenu = ({
  filterKey,
  anchorRef,
  onClose,
  currentFilter,
  setFilters,
  actions,
}: Props) => {
  const [inputValue, setInputValue] = useState('');

  if (!filterKey || !anchorRef) {
    return null;
  }
  const { comparators, onOptionClick, getData, hasSearch, customSearch, minHeight } =
    menuConfig[filterKey];

  const { selectedComparator, selectedItems } = parseCurrentFilter(currentFilter);

  const onFilterOptionClick = (option: FilterOption) => {
    let nextSelectedItems;
    if (Array.isArray(selectedItems)) {
      const index = selectedItems.indexOf(option.id);
      nextSelectedItems = [...selectedItems];
      if (index > -1) {
        nextSelectedItems.splice(index, 1);
      } else {
        nextSelectedItems.push(option.id);
      }
    } else {
      nextSelectedItems = [option.id];
    }

    if (!nextSelectedItems.length) {
      setFilters({ [filterKey]: undefined });
      return;
    }

    const comparator = selectedComparator || comparators[0];

    if (onOptionClick) {
      onOptionClick({ ...actions, value: nextSelectedItems, comparator });
      return;
    }

    setFilters({
      [filterKey]: { [comparator]: nextSelectedItems },
    });
  };

  const data = getData({ ...actions, inputValue });

  const getSearch = () => {
    if (customSearch) {
      const comparator = selectedComparator || comparators[0];

      return customSearch({ ...actions, onClose, setFilters, comparator, value: selectedItems });
    }

    if (!hasSearch) {
      return '';
    }

    return <SearchInput value={inputValue} onChange={e => setInputValue(e.target.value)} />;
  };

  return (
    <MenuWrapper minHeight={minHeight || 0} anchorRef={anchorRef} onClose={onClose}>
      {getSearch()}
      <List>
        {data.map((option: FilterOption) => {
          const isChecked = getIsChecked(selectedItems, option.id);
          return (
            <FilterTypeRow
              icon={<CheckboxV2 checked={isChecked} />}
              key={option.id}
              onClick={() => onFilterOptionClick(option)}
            >
              <OptionRow title={option.name}>
                {option.icon}
                <EllipsisTextOverflowWrapper>
                  <LightTextTiny>{option.name}</LightTextTiny>
                </EllipsisTextOverflowWrapper>
              </OptionRow>
            </FilterTypeRow>
          );
        })}
      </List>
    </MenuWrapper>
  );
};

const OptionRow = styled(Gap8HorizontalFlex)`
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

const List = styled(VerticalFlex)`
  max-height: 230px;
  overflow: auto;
`;

export default FilterByKeyMenu;
