import { useEffect, useState } from 'react';
import { useProducts } from 'app/src/context/ProductsStore';
import { useDynamicConditionsContext } from '../../components/dynamic-conditions/context/DynamicConditionsContext';
import { VodAssetPayload } from '../../types';
import useGetVideoPayload from '../useGetVideoPayload';
import useDynamicTypeVideosActions, { UseVideosActions } from './useDynamicTypeVideosActions';
import { useVideos } from 'app/src/context/VideosStore';
import { vodAsset as VodAsset } from 'app/src/types/entities';
import useGetMissingProducts from '../useGetMissingProducts';

export type UseDynamicVideos = UseVideosActions & {
  videos: VodAssetPayload[];
  filteredVodAssets: VodAsset[];
  isProjectProductPage?: boolean;
};

export const useDynamicTypeVideos = ({
  project,
  searchTerm,
  isProjectProductPage = false,
}): UseDynamicVideos => {
  const [{ data: vodAssets }] = useVideos();
  const [{ products: productsMap }] = useProducts();
  const { videoRules, vodAssetIds: defaultVodAssetIds } = useDynamicConditionsContext();
  const [vodAssetIds, setVodAssetIds] = useState(defaultVodAssetIds);
  const [videos, setVideos] = useState<VodAssetPayload[]>([]);
  const [filteredVodAssets, setFilteredVodAssets] = useState<VodAsset[]>([]);
  const getVideoPayload = useGetVideoPayload({ vodAssetIds, videoRules, project, productsMap });
  const videoActions = useDynamicTypeVideosActions({
    project,
    vodAssetIds,
    setVodAssetIds,
    videoRules,
  });

  useGetMissingProducts({ project, productsMap, vodAssetIds });

  useEffect(() => {
    setVodAssetIds(defaultVodAssetIds);
  }, [defaultVodAssetIds]);

  useEffect(() => {
    let videos = vodAssetIds.flatMap(
      (vodAssetId, index) => getVideoPayload(vodAssetId, index) || []
    );
    if (searchTerm) {
      if (isProjectProductPage) {
        return;
      }

      videos = videos.filter(({ video }) => {
        return video.name.toLowerCase().includes(searchTerm.toLowerCase());
      });
    }

    setVideos(videos);
  }, [vodAssetIds, videoRules, searchTerm, productsMap]);

  useEffect(() => {
    if (vodAssets?.length > 0) {
      const filteredVodAssets = vodAssets.filter(vodAsset => {
        return vodAsset.name.toLowerCase().includes(searchTerm.toLowerCase());
      });
      setFilteredVodAssets(filteredVodAssets);
    }
  }, [searchTerm]);

  return {
    videos,
    filteredVodAssets,
    ...videoActions,
  };
};

export default useDynamicTypeVideos;
