import React from 'react';

const GreenVIconV2 = () => (
  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17 6.031A.684.684 0 0 0 17 5a.684.684 0 0 0-1.031 0L8 12.969 4 9a.684.684 0 0 0-1.031 0 .684.684 0 0 0 0 1.031l4.5 4.5c.125.156.312.219.531.219a.622.622 0 0 0 .5-.219l8.5-8.5Z"
      fill="#01BE81"
    />
  </svg>
);

export default GreenVIconV2;
