import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Routes from '../helpers/Routes';
import { useUser } from 'app/src/context/userStore/UserStore';

function PrivateRoute({ children, ...rest }) {
  const [{ isAuthenticated }] = useUser();

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: Routes.getLoginRoute(),
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
