import React from 'react';
import styled from 'styled-components';
import FeedVideoHandle from 'shared/react/components/complex/feed/feed_overlay_mobile/FeedVideoHandle';
import { AssetsUtilsService } from 'shared/react/services/assets';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import { TextBody } from 'shared/react/components/basic/text/TextV2';

const FeedCaptions = ({ currentCaptions }) => {
  return (
    <FeedCaptionsContainer isHidden={!currentCaptions}>
      <StyledCaptions title={currentCaptions}>{currentCaptions}</StyledCaptions>
    </FeedCaptionsContainer>
  );
};

const FeedCaptionsAndHandle = ({ currentCaptions, step, audioRef }) => {
  const isGallery = AssetsUtilsService.isGalleryAsset(step);

  if (isGallery && audioRef?.current?.src) {
    return null;
  }

  return (
    <StyledFeedCaptionsAndHandle $isGallery={isGallery}>
      <FeedCaptions currentCaptions={currentCaptions} />
      <FeedVideoHandle isMobile step={step} />
    </StyledFeedCaptionsAndHandle>
  );
};

const StyledFeedCaptionsAndHandle = styled(Gap16VerticalFlex)`
  width: 100%;
  flex-basis: ${({ $isGallery }) => ($isGallery ? '15%' : 'auto')};
`;

const FeedCaptionsContainer = styled(HorizontalFlex)`
  display: ${({ isHidden }) => (isHidden ? 'none' : '')};
  padding: 5px;
  margin: 0 20px;
  background-color: rgba(0, 0, 0, 0.6);
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 80%;
`;

const StyledCaptions = styled(TextBody)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-word;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
`;

export default FeedCaptionsAndHandle;
