import { ATTRIBUTION_WINDOW_OPTIONS } from 'app/src/constants/analytics.constants';

export const CUBE_PROPERTY_KEYS = {
  attributionDiff: 'attributiondiff',
  avgPrice: 'avgPrice',
  count: 'count',
  eventName: 'eventName',
  text: 'text',
  productIds: 'productIds',
  averageTimeOnTolstoy: 'averageTimeOnTolstoy',
  sumTimeOnTolstoy: 'sumTimeOnTolstoy',
  timeOnTolstoyRatio: 'timeOnTolstoyRatio',
  averageTimeOnPage: 'averageTimeOnPage',
  averageTimeOnPageWhenInteractingTolstoy: 'averageTimeOnPageWhenInteractingTolstoy',
  numOfInteractingVisits: 'numOfInteractingVisits',
  numOfNonInteractingVisits: 'numOfNonInteractingVisits',
  numOfNonInteractingVisitsUnique: 'numOfNonInteractingVisitsUnique',
  tolstoySessions: 'tolstoySessions',
  timestamp: 'timestamp',
  publishId: 'publishid',
  orderId: 'orderid',
  videoId: 'videoid',
  email: 'email',
  currency: 'currency',
  timeOnTolstoy: 'timeOnTolstoy',
  appUrl: 'appurl',
  domain: 'domain',
  createdAt: 'createdat',
  postVideoRevenue: 'postVideoRevenue',
  inVideoRevenue: 'inVideoRevenue',
  indirectRevenue: 'indirectRevenue',
  totalRevenue: 'totalRevenue',
  plays: 'plays',
  parentUrl: 'parentUrl',
  pageUrl: 'pageurl',
  dirtyAverageTimeOnTolstoy: 'dirtyAverageTimeOnTolstoy',
  numOfVideosWatched: 'numOfVideosWatched',
  bounceRate: 'bounceRate',
  averageWatchPercentage: 'averageWatchPercentage',
  watchTime: 'watchTime',
  id: 'id',
  orderName: 'ordername',
  duration: 'duration',
  conversionRate: 'conversionRate',
  averageOrderValue: 'averageOrderValue',
  watchedAllPercentage: 'watchedAllPercentage',
  isInteracted: 'isinteracted',
  INTERACTED: 'INTERACTED',
  numOfEmbedViews: 'numOfEmbedViews',
  numOfOrders: 'numOfOrders',
  numOfImpressions: 'numOfImpressions',
  numOfSessionStarts: 'numOfSessionStarts',
  numOfSwipes: 'numOfSwipes',
  numOfGenericInteractions: 'numOfGenericInteractions',
  numOfProductInteractions: 'numOfProductInteractions',
  location: 'location',
  uniqueUserCount: 'uniqueUserCount',
  numOfProductsClicked: 'numOfProductsClicked',
  numOfProductFavorites: 'numOfProductFavorites',
  numOfOpenStoreScreen: 'numOfOpenStoreScreen',
  numOfNavigateToStore: 'numOfNavigateToStore',
  numOfFollowedStore: 'numOfFollowedStore',
  campaign: 'campaign',
  firstSeen: 'firstSeen',
  numOfEmailOpens: 'numOfEmailOpens',
  numOfEmailClicks: 'numOfEmailClicks',
  totalTimeAverage: 'totalTimeAverage',
  totalTimeSum: 'totalTimeSum',
  eventsCombined: 'events_combined', // old metric used to calculate multiple events
  playerType: 'playertype',
};

export const TABLE_COLUMN_KEYS = {
  publishId: 'publishId',
  avgVideosWatched: 'avgVideosWatched',
  percentageOnTolstoy: 'percentageOnTolstoy',
  parentUrl: CUBE_PROPERTY_KEYS.parentUrl,
  inVideoRevenue: CUBE_PROPERTY_KEYS.inVideoRevenue,
  postVideoRevenue: CUBE_PROPERTY_KEYS.postVideoRevenue,
  indirectRevenue: CUBE_PROPERTY_KEYS.indirectRevenue,
  totalRevenue: 'totalRevenue',
  plays: CUBE_PROPERTY_KEYS.plays,
  averageTimeOnPage: CUBE_PROPERTY_KEYS.averageTimeOnPage,
  averageTimeOnPageWhenInteractingTolstoy:
    CUBE_PROPERTY_KEYS.averageTimeOnPageWhenInteractingTolstoy,
  averageTimeOnTolstoy: CUBE_PROPERTY_KEYS.averageTimeOnTolstoy,
  id: CUBE_PROPERTY_KEYS.id,
  duration: CUBE_PROPERTY_KEYS.duration,
  bounceRate: CUBE_PROPERTY_KEYS.bounceRate,
  averageWatchPercentage: CUBE_PROPERTY_KEYS.averageWatchPercentage,
  watchedAllPercentage: CUBE_PROPERTY_KEYS.watchedAllPercentage,
  watchTime: CUBE_PROPERTY_KEYS.watchTime,
  conversionRate: CUBE_PROPERTY_KEYS.conversionRate,
  averageOrderValue: CUBE_PROPERTY_KEYS.averageOrderValue,
  source: 'source',
  playRate: 'playRate',
  createdAt: 'createdAt',
  email: CUBE_PROPERTY_KEYS.email,
  orderId: 'orderId',
  videoId: 'videoId',
  currency: CUBE_PROPERTY_KEYS.currency,
  tolstoySessions: CUBE_PROPERTY_KEYS.tolstoySessions,
  numOfVideosWatched: CUBE_PROPERTY_KEYS.numOfVideosWatched,
  numOfOrders: CUBE_PROPERTY_KEYS.numOfOrders,
  sumTimeOnTolstoy: CUBE_PROPERTY_KEYS.sumTimeOnTolstoy,
  timeOnTolstoyRatio: CUBE_PROPERTY_KEYS.timeOnTolstoyRatio,
  eventName: CUBE_PROPERTY_KEYS.eventName,
  text: CUBE_PROPERTY_KEYS.text,
  productIds: CUBE_PROPERTY_KEYS.productIds,
  firstSeen: CUBE_PROPERTY_KEYS.firstSeen,
  campaign: CUBE_PROPERTY_KEYS.campaign,
  numOfEmailOpens: CUBE_PROPERTY_KEYS.numOfEmailOpens,
  numOfEmailClicks: CUBE_PROPERTY_KEYS.numOfEmailClicks,
};

export const REVENUE_TYPES = {
  direct: 'direct',
  influenced: 'influenced',
  touched: 'touched',
};

export const CUBE_FILTER_TYPES = {
  equals: 'equals',
  lessThan: 'lt',
  notContains: 'notContains',
};

export const CUBE_FILTERS = {
  appKey: {
    operator: CUBE_FILTER_TYPES.equals,
  },
  dateRange: {
    operator: CUBE_FILTER_TYPES.equals,
  },
  [CUBE_PROPERTY_KEYS.attributionDiff]: {
    operator: CUBE_FILTER_TYPES.lessThan,
  },
  email: {
    operator: CUBE_FILTER_TYPES.equals,
  },
  eventName: {
    operator: CUBE_FILTER_TYPES.equals,
  },
  publishId: {
    operator: CUBE_FILTER_TYPES.equals,
  },
  flipPublishId: {
    customFilterKey: CUBE_PROPERTY_KEYS.publishId,
    // Using notContains is a hack to pass the parameter to the cube query,
    // it doesn't filter out the publishId, the cube query will filter only events from this publishId
    operator: CUBE_FILTER_TYPES.notContains,
  },
  appUrl: {
    operator: CUBE_FILTER_TYPES.equals,
  },
  flipAppUrl: {
    customFilterKey: CUBE_PROPERTY_KEYS.appUrl,
    operator: CUBE_FILTER_TYPES.notContains,
  },
  domain: {
    operator: CUBE_FILTER_TYPES.equals,
  },
  flipDomain: {
    customFilterKey: CUBE_PROPERTY_KEYS.domain,
    operator: CUBE_FILTER_TYPES.notContains,
  },
};

export const ATTRIBUTION_DAYS_BY_FILTER = {
  [ATTRIBUTION_WINDOW_OPTIONS.twentyFourHours]: '1',
  [ATTRIBUTION_WINDOW_OPTIONS.threeDays]: '3',
  [ATTRIBUTION_WINDOW_OPTIONS.sevenDays]: '7',
  [ATTRIBUTION_WINDOW_OPTIONS.thirtyDays]: '30',
};
