import React, { useState } from 'react';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import CompanyInformation from './CompanyInformation';
import SnackBar from 'app/src/basic_components/SnackBar';
import AccountInformation from './AccountInformation/AccountInformation';
import AccountSettings from './AccountSettings';

function WorkspaceInformation({
  packageType,
  accountName,
  accountUrl,
  companySize,
  companyVertical,
  companyUseCase,
  multipassSecret,
  isShopifyPlus,
  updateMultipassSecret,
  updateNumberOfEmployees,
  loading,
  updateCompanyVertical,
  updateAccountName,
  updateAccountUrl,
  updateCompanyUseCase,
  onUpdate,
  shouldUseCache,
  updateShouldUseCache,
}) {
  const [errorMessage, setErrorMessage] = useState(null);

  return (
    <LayoutRoot>
      <AccountInformation
        packageType={packageType}
        multipassSecret={multipassSecret}
        isShopifyPlus={isShopifyPlus}
        updateMultipassSecret={updateMultipassSecret}
        onUpdate={onUpdate}
        loading={loading}
      />
      <CompanyInformation
        accountName={accountName}
        accountUrl={accountUrl}
        numberOfEmployees={companySize}
        companyVertical={companyVertical}
        loading={loading}
        companyUseCase={companyUseCase}
        updateNumberOfEmployees={updateNumberOfEmployees}
        updateCompanyVertical={updateCompanyVertical}
        updateAccountName={updateAccountName}
        updateAccountUrl={updateAccountUrl}
        updateCompanyUseCase={updateCompanyUseCase}
        onUpdate={onUpdate}
      />
      <AccountSettings
        shouldUseCache={shouldUseCache}
        updateShouldUseCache={updateShouldUseCache}
        onUpdate={onUpdate}
      />
      <SnackBar
        text={errorMessage}
        open={errorMessage}
        setOpen={() => setErrorMessage(null)}
        severity="error"
      />
    </LayoutRoot>
  );
}

export default WorkspaceInformation;

const LayoutRoot = styled(VerticalFlex)`
  gap: 48px;
`;
