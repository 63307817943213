import { useModal } from 'app/src/context/ui_store/ModalStore';
import { useVideoSelectorForModal } from 'app/src/pages/dashboard/pages/videos/components/video_card/video-switcher/useVideoSelectorForModal';
import { useCallback } from 'react';
import { useDashboard } from 'app/src/context/ui_store/DashboardStore';
import useVideosFilters from 'app/src/pages/dashboard/pages/videos/hooks/useVideosFilters';
import { VideoMovementDirection } from 'app/src/pages/dashboard/pages/videos/components/video_card/video-switcher/VideoMovementDirection';
import { useKeydownEvent } from 'app/src/complex_components/global_modal/video/useKeydownEvent';

const keyToDirection: Record<string, VideoMovementDirection> = {
  ArrowLeft: 'prev',
  ArrowRight: 'next',
};

interface UseVideoSwitcherInModalProps {
  shouldMove: () => boolean;
}

export const useVideoSwitcherInModal = (args: UseVideoSwitcherInModalProps) => {
  const { shouldMove } = args;
  const [{ videosFilters }, { setVideosFilters }] = useDashboard();
  const { videos: videosOnDashboard } = useVideosFilters({ videosFilters, setVideosFilters });
  const [
    {
      currentId,
      currentIds,
      modalProps: { analyticsData },
    },
  ] = useModal();
  const videoId = currentId || currentIds[0];
  const { openVideoInModal } = useVideoSelectorForModal({ analyticsData });

  const moveToVideo = useCallback(
    (direction: VideoMovementDirection) => {
      if (!shouldMove()) {
        return;
      }

      const indexOfCurrentVideo = videosOnDashboard.findIndex(v => v.id === videoId);

      const shift = direction === 'prev' ? -1 : 1;
      const indexOfNextVideo = indexOfCurrentVideo + shift;
      if (indexOfNextVideo < 0 || indexOfNextVideo >= videosOnDashboard.length) {
        // nothing to do
        return;
      }

      const nextVideo = videosOnDashboard[indexOfNextVideo];
      openVideoInModal(nextVideo);
    },
    [shouldMove, videosOnDashboard, openVideoInModal, videoId]
  );

  const moveOnKeydown = useCallback(
    e => {
      if (!shouldMove()) {
        return;
      }

      e.preventDefault();
      moveToVideo(keyToDirection[e.key]);
    },
    [moveToVideo, shouldMove]
  );

  useKeydownEvent('ArrowLeft', moveOnKeydown);
  useKeydownEvent('ArrowRight', moveOnKeydown);

  return { moveToVideo };
};
