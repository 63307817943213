import React from 'react';
import IntegrationsAccount from 'app/src/pages/creation_flow/right_section/integrations_common/IntegrationsAccount';
import { IMPORT_FROM_INSTAGRAM } from 'app/src/constants/creation_method.constants';
import { useInstagram } from 'app/src/context/InstagramStore';
import useIntegrationsVideos from 'app/src/hooks/useIntegrationsVideos';

const InstagramAccount = () => {
  const { instagramProfile, instagram: instagramApp } = useIntegrationsVideos();
  const [{ initialized }, { clearStore }] = useInstagram();
  const { userName } = instagramProfile;

  const userInfo = { userName };

  return (
    <IntegrationsAccount
      initialized={initialized}
      userInfo={userInfo}
      clearStore={clearStore}
      app={instagramApp}
      integration={IMPORT_FROM_INSTAGRAM}
    />
  );
};

export default InstagramAccount;
