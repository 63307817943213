import React from 'react';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextSubtitle } from 'shared/react/components/complex/Text';
import { useFormContext } from 'react-hook-form';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import IosSwitch from 'app/src/basic_components/IosSwitch';
import FeedActionButtonEditorFields from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/feed/FeedActionButtonEditorFields';
import { URL_KEY } from 'shared/react/constants/generalKeys.constants';
import { LINK_BUTTON_SWITCH_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import Tooltip from 'app/src/basic_components/Tooltip';

const FeedActionButtonEditor = ({ buttonType }) => {
  const { setValue, watch } = useFormContext();
  const [button, products] = watch([buttonType, 'products']);

  const setFeedActionButton = newFeedActionButton => {
    setValue(buttonType, newFeedActionButton);
  };

  const getNewFeedActionButton = () => {
    if (!button) {
      return {
        type: URL_KEY,
        isOpenInANewTab: true,
        enabled: true,
      };
    }

    return { ...button };
  };

  const handleSwitchClick = () => {
    const buttonData = getNewFeedActionButton();
    setFeedActionButton({ ...buttonData, enabled: !button?.enabled });
  };

  return (
    <LayoutRoot>
      <HeaderContainer>
        <StyledTextSubtitle>Link button</StyledTextSubtitle>
        <Tooltip
          text={!products?.length ? 'This will only be visible If you have one product.' : null}
          placement="right"
        >
          <IosSwitch
            checked={button?.enabled || false}
            onChange={handleSwitchClick}
            data-test-id={LINK_BUTTON_SWITCH_TEST_ID}
          />
        </Tooltip>
      </HeaderContainer>
      <FeedActionButtonEditorFields
        buttonType={buttonType}
        getNewFeedActionButton={getNewFeedActionButton}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  gap: 24px;
  justify-content: center;
  width: 100%;
  padding: 16px 24px;
`;

const HeaderContainer = styled(HorizontalFlexWrap)`
  justify-content: flex-start;
  gap: 12px;
  align-items: center;
`;

const StyledTextSubtitle = styled(TextSubtitle)``;

export default FeedActionButtonEditor;
