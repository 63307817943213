import { UGC_INSTAGRAM_GRAPH_PENDING } from 'app/src/constants/intergrations.constants';
import filterEntities, { WhereCondition } from 'app/src/pages/dashboard/utils/filterEntities';
import { VideosPageVodAsset } from 'app/src/pages/dashboard/pages/videos/types/videos.types';
import { omit } from 'lodash';
import { useMemo } from 'react';

export const useInMemoryVideoFilters = (
  videos: VideosPageVodAsset[],
  videosFilters: WhereCondition<VideosPageVodAsset>
) => {
  const filteredVideos = useMemo(() => {
    const defaultFilteredVideos = videos.filter(
      ({ uploadType }) => uploadType !== UGC_INSTAGRAM_GRAPH_PENDING
    );

    return filterEntities<VideosPageVodAsset>(defaultFilteredVideos, omit(videosFilters, ['app']));
  }, [videos, videosFilters]);

  return { filteredVideos };
};
