import { PUBLISH_METHODS } from 'shared/react/constants/tolstoy.constants';
import { publishMethodOptions } from 'src/types/entities';

export const MODAL_Z_INDEX = 1000;
export const REDIRECT_PATH_SESSION_STORAGE_KEY = 'redirectPath';
export const CUSTOM_LOGIN_LOADING_SESSION_STORAGE_KEY = 'tolstoy-custom-login-loading';
export const DEFAULT_NUMBER_OF_CARDS_TO_SHOW = window.innerWidth > 1920 ? 40 : 20;
export const TRASH_BUTTON_DATA_TYPE = 'trash-button';
export const TEXTAREA_INPUT_TYPE = 'textarea';
export const ONBOARDING = 'onboarding';
export const OFFSITE = 'offsite';
export const ONSITE = 'onsite';
export const VIDEO_INBOX = 'video_inbox';

export const EMBED_WIDGETS_NAMES = {
  [PUBLISH_METHODS[publishMethodOptions.carousel].widgetType]: 'carousel',
  [PUBLISH_METHODS[publishMethodOptions.stories].widgetType]: 'story',
};

export const PUBLISH_PREVIEW_SIZE = 3.33;
export const CHECKOUT_SHOPPING_BAG_SETTING_TOOLTIP_TEXT =
  'Show your shopping bag and allow users to proceed to checkout directly from Tolstoy';

export const PROJECT_TYPES_WITH_INSTALL_TAB = [
  publishMethodOptions.carousel,
  publishMethodOptions.hero,
  publishMethodOptions.stories,
  publishMethodOptions.videoPage,
  publishMethodOptions.tvChannel,
  publishMethodOptions.embed,
  publishMethodOptions.bubble,
  publishMethodOptions.unassigned,
];

export const APPS_SELECT_ALL_STORES_KEY = 'all';
