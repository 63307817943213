import TolstoyLoader from 'app/src/basic_components/TolstoyLoader';
import IntegrationVideoDetails from 'app/src/pages/creation_flow/right_section/integrations_common/IntegrationVideoDetails';
import React, { useEffect, useState } from 'react';
import ComponentWithOverlayLoader from 'shared/react/components/basic/ComponentWithOverlayLoader';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { TextTiny } from 'shared/react/components/complex/Text';
import ImageWithFallback from 'shared/react/images/ImageWithFallback';
import Utils from 'shared/react/utils/utils';
import styled from 'styled-components';
import Checkbox from 'app/src/complex_components/Checkbox';
import { useImportVideos } from 'app/src/context/ui_store/ImportVideosStore';
import AddStepsTooltip from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/tolstoy_list/AddStepsTooltip';

const IntegrationVideo = video => {
  const { posterUrl, videoUrl, description, viewCount, likeCount, integration, isLoading } = video;
  const [
    { selectedVideosToImport, disableAddVideosToImport },
    { addSelectedVideoToImport, removeSelectedVideoToImport },
  ] = useImportVideos();
  const [isChecked, setIsChecked] = useState(false);
  const showTooltip = disableAddVideosToImport && !isChecked;

  useEffect(() => {
    if (!selectedVideosToImport.length) {
      setIsChecked(false);
    }

    if (selectedVideosToImport.includes(videoUrl)) {
      setIsChecked(true);
    }
  }, [selectedVideosToImport.length]);

  const onCheck = () => {
    if (isChecked) {
      removeSelectedVideoToImport(video);
      setIsChecked(false);
      return;
    }

    if (disableAddVideosToImport) {
      return;
    }

    setIsChecked(true);
    addSelectedVideoToImport(video);
  };

  return (
    <LayoutRoot>
      <ComponentWithOverlayLoader customLoader={<Loader />} isLoading={isChecked && isLoading}>
        <ImageContainer onClick={onCheck}>
          <Image loading={isLoading} src={posterUrl} />
          <IntegrationVideoDetails
            likeCount={likeCount}
            viewCount={viewCount}
            integration={integration}
          />
          <ButtonsContainer isSelected={isChecked} disabled={disableAddVideosToImport}>
            <AddStepsTooltip placement="right-start" hidden={!showTooltip} isLocked={showTooltip}>
              <ButtonsContent>
                <Checkbox
                  checked={isChecked}
                  onChange={onCheck}
                  disableFocusRipple
                  onClick={Utils.stopPropagation}
                  disabled={disableAddVideosToImport}
                />
              </ButtonsContent>
            </AddStepsTooltip>
          </ButtonsContainer>
        </ImageContainer>
      </ComponentWithOverlayLoader>
      <OverflowContainer>
        <Description>{description}</Description>
      </OverflowContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)``;

const ImageContainer = styled.div`
  display: grid;
`;

const Image = styled(ImageWithFallback)`
  object-fit: cover;
  cursor: pointer;
  max-width: unset;
  aspect-ratio: 3/4;
  z-index: unset;
  pointer-events: ${({ loading }) => loading && 'none'};
  filter: ${({ loading }) => loading && 'grayscale(0.4);'};
  grid-row: 1;
  grid-column: 1;
  height: 100%;
  width: 100%;
`;

const Description = styled(TextTiny)`
  height: 14px;
  align-self: self-start;
`;

const OverflowContainer = styled(EllipsisTextOverflowWrapper)`
  align-self: self-start;
`;

const Loader = styled(TolstoyLoader)`
  svg {
    width: 32px;
  }
`;

const ButtonsContainer = styled.div`
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};
  grid-row: 1;
  grid-column: 1;
  transition: 0.25s;
  background: ${({ theme, isSelected }) =>
    theme.getHexOpacity(theme.colors.white, isSelected ? 0 : 40)};
  cursor: ${({ disabled }) => (disabled ? '' : 'pointer')};

  &:hover {
    opacity: 1;
  }
`;

const ButtonsContent = styled(Gap8HorizontalFlexWrap)`
  margin: 8px;
  justify-content: space-between;
  align-items: flex-start;
`;

export default IntegrationVideo;
