import {
  AUDIO_RESPONSE_KEY,
  CALENDLY_RESPONSE_KEY,
  FREE_TEXT_RESPONSE_KEY,
  VIDEO_RESPONSE_KEY,
} from './tolstoy.constants';
import TextIcon from 'app/src/images/TextIcon';
import VideoResponseAnswerIcon from 'app/src/images/GoToVideoResponseIcon';
import AudioResponseAnswerIcon from 'app/src/images/AudioResponseAnswerIcon';
import CalendlyIcon from 'app/src/images/calendars/CalendlyIcon';
import React from 'react';
import Colors from 'shared/react/constants/colors';

export const defaultResponsesIcons = {
  [FREE_TEXT_RESPONSE_KEY]: TextIcon,
  [VIDEO_RESPONSE_KEY]: VideoResponseAnswerIcon,
  [AUDIO_RESPONSE_KEY]: AudioResponseAnswerIcon,
  [CALENDLY_RESPONSE_KEY]: CalendlyIcon,
};

export const defaultResponsesBackgroundColors = {
  [FREE_TEXT_RESPONSE_KEY]: Colors.primaryLight,
  [VIDEO_RESPONSE_KEY]: Colors.pink6,
  [AUDIO_RESPONSE_KEY]: Colors.successLight,
  [CALENDLY_RESPONSE_KEY]: Colors.yellow5,
};

export const defaultResponsesIconColors = {
  [FREE_TEXT_RESPONSE_KEY]: Colors.blue12,
  [VIDEO_RESPONSE_KEY]: Colors.danger,
  [AUDIO_RESPONSE_KEY]: Colors.success,
  [CALENDLY_RESPONSE_KEY]: Colors.yellow6,
};

export const defaultResponsesIcon = (type, props) => {
  const Icon = defaultResponsesIcons[type];
  const fill = defaultResponsesIconColors[type];
  return <Icon fill={fill} {...props} />;
};
