import React from 'react';
import styled from 'styled-components';
import ArrowIcon from 'shared/react/images/feed/ArrowIcon';
import BackdropButton from 'shared/react/components/complex/BackdropButton';
import { BolderTextSmall } from 'shared/react/components/complex/Text';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';
import useIsMilk from 'shared/react/hooks/useIsMilk';
import { milkConditionCss } from 'shared/react/components/clientsCss.constants';
import useIsFenty from 'shared/react/hooks/useIsFenty';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

const FeedViewProductsButton = ({ shouldHide, isProductsExpanded, setIsProductsExpanded }) => {
  const [, { t }] = useTranslation('feed.mobile.productView');
  const isMilk = useIsMilk();
  const isFenty = useIsFenty();

  const onClick = e => {
    e.stopPropagation();
    setIsProductsExpanded(!isProductsExpanded);
  };

  if (shouldHide) {
    return null;
  }

  return (
    <ButtonWrapper>
      <StyeldBackdropButton onClick={onClick} isExpanded={isProductsExpanded}>
        <StyledTextSmall isFenty={isFenty} isMilk={isMilk} isExpanded={isProductsExpanded}>
          {t('viewProducts')}
        </StyledTextSmall>
        <StyledArrowIcon flip={isProductsExpanded} />
      </StyeldBackdropButton>
    </ButtonWrapper>
  );
};

const StyeldBackdropButton = styled(BackdropButton)`
  width: auto;
  height: auto;
  gap: ${({ isExpanded }) => (isExpanded ? 0 : 8)}px;
  padding: 10px;
`;

const StyledArrowIcon = styled(ArrowIcon)`
  width: 20px;
  height: 20px;
`;

const StyledTextSmall = styled(BolderTextSmall)`
  color: ${({ theme, isFenty }) => (isFenty ? theme.colors.black : theme.colors.white)};
  max-width: ${({ isExpanded }) => (isExpanded ? 0 : 100)}px;
  opacity: ${({ isExpanded }) => (isExpanded ? 0 : 1)};
  transition: 0.3s ease-out;
  max-height: ${({ isExpanded }) => (isExpanded ? 0 : 500)}px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 22px;
  ${milkConditionCss};
`;

const ButtonWrapper = styled(HorizontalFlex)`
  width: 100%;
  flex-basis: 15%;
`;

export default FeedViewProductsButton;
