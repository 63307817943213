import React from 'react';

const HashtagIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.25 4.25c.406 0 .75.344.75.781 0 .407-.344.719-.75.719l-2.594.031-.812 4.5h2.406a.76.76 0 0 1 .75.75c0 .406-.344.719-.75.719h-2.687l-.47 2.656a.736.736 0 0 1-.718.594h-.156a.73.73 0 0 1-.594-.875l.406-2.375H5.562l-.468 2.656a.736.736 0 0 1-.719.594h-.156c-.407-.062-.657-.469-.594-.875l.438-2.375H1.75A.72.72 0 0 1 1 11a.74.74 0 0 1 .75-.75h2.563l.812-4.5H2.75c-.437 0-.75-.312-.75-.719 0-.437.313-.781.75-.781h2.656l.469-2.625c.063-.406.469-.656.875-.594s.656.469.594.875L6.937 4.25h3.47l.468-2.625c.063-.406.469-.656.875-.594s.656.469.594.875l-.438 2.344zm-4.937 6 .812-4.5H6.656l-.812 4.5z"
        fill="#2F3137"
      />
    </svg>
  );
};

export default HashtagIcon;
