import React from 'react';
import styled from 'styled-components';
import StandardModal from 'app/src/pages/modals/StandardModal';
import ShopifyLogo from 'app/src/images/shopify.png';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextBody, TextH5 } from 'shared/react/components/complex/Text';
import ChatWithSupport from 'app/src/complex_components/ChatWithSupport';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { HAS_SHOPIFY_INSTALLED } from 'app/src/constants/intergrations.constants';
import Button from 'shared/react/components/complex/Button';

const ShopifyAlreadyInstalledModal = () => {
  const [, { setCurrentModal }] = useModal();

  const onClose = () => {
    localStorage.removeItem(HAS_SHOPIFY_INSTALLED);
    setCurrentModal('');
  };

  return (
    <StandardModal open width="440px" onClose={onClose}>
      <LayoutRoot>
        <TopContainer>
          <StyledImage src={ShopifyLogo} />
          <ContentContainer>
            <TextH5>Shopify store already connected</TextH5>
            <TextBody>
              Seems like you already have a store connected. You can only connect one store to an
              account.
            </TextBody>
            <ChatWithSupport />
          </ContentContainer>
        </TopContainer>
        <ButtonContainer>
          <Button onClick={onClose}>Close</Button>
        </ButtonContainer>
      </LayoutRoot>
    </StandardModal>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  gap: 40px;
`;

const StyledImage = styled.img`
  height: 48px;
  width: 42px;
`;

const TopContainer = styled(VerticalFlex)`
  gap: 24px;
`;

const ContentContainer = styled(Gap8VerticalFlex)``;

const ButtonContainer = styled(VerticalFlex)`
  align-items: flex-end;
`;

export default ShopifyAlreadyInstalledModal;
