import React from 'react';

const AccountBillingsIcon = ({ fill = '#3D4FE0' }) => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.25 2H13c-.406 0-.781.25-.938.656a1.01 1.01 0 0 0 .22 1.094l1.28 1.281-6.03 6.032a.964.964 0 0 0 0 1.406.99.99 0 0 0 .718.281c.25 0 .5-.094.688-.281l6.03-6.031 1.282 1.28a.99.99 0 0 0 .719.282c.125 0 .25 0 .375-.063.406-.156.656-.53.656-.937V2.75a.76.76 0 0 0-.75-.75Zm-3 10a.74.74 0 0 0-.75.75v3.5c0 .156-.125.25-.25.25h-9.5c-.156 0-.25-.094-.25-.25v-9.5c0-.125.094-.25.25-.25h3.5A.74.74 0 0 0 8 5.75.76.76 0 0 0 7.25 5h-3.5C2.781 5 2 5.813 2 6.75v9.5c0 .969.781 1.75 1.75 1.75h9.5c.938 0 1.75-.781 1.75-1.75v-3.5a.76.76 0 0 0-.75-.75Z"
        fill={fill}
      />
    </svg>
  );
};

export default AccountBillingsIcon;
