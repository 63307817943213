import React from 'react';
import { useAnalytics } from 'src/context/AnalyticsStore';
import styled from 'styled-components';
import AnalyticsMetrics from '../AnalyticsMetrics';
import { SHOP_METRICS_SETTINGS } from './analyticsShop.constants';
import { DesignSizes } from 'shared/react/theme/DesignSystem';
import { ENTRY_POINT_LOCATIONS } from 'src/constants/shop.constants';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';

const AnalyticsShop = () => {
  const [{ shopMetrics, isLoadingShopMetrics }] = useAnalytics();

  return (
    <LayoutRoot>
      {ENTRY_POINT_LOCATIONS.map(location => {
        const data = shopMetrics?.[location] || {};
        const { railMetrics, feedMetrics, header } = SHOP_METRICS_SETTINGS[location];

        return (
          <Gap16VerticalFlex>
            <AnalyticsMetrics
              size={DesignSizes.SMALL}
              data={data}
              metrics={railMetrics.metrics}
              loading={isLoadingShopMetrics}
              isUnique={false}
              header={header}
              shouldFormatWithLetter={false}
            />
            <AnalyticsMetrics
              size={DesignSizes.SMALL}
              data={data}
              metrics={feedMetrics.metrics}
              loading={isLoadingShopMetrics}
              isUnique={false}
              header=""
              shouldFormatWithLetter={false}
            />
          </Gap16VerticalFlex>
        );
      })}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  gap: 40px;
`;

export default AnalyticsShop;
