import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import ImageIcon from '../../icons/ImageIcon';
import { uploadImageFile } from 'app/src/helpers/Image';
import Utils from '../../../../utils';
import { IMAGE_TYPE } from 'shared/react/constants/attachments_types.constants';

const ImageButton = ({ track, setErrorText, setAttachments, attachments, containerRef }) => {
  const loadingRef = useRef(false);
  const inputRef = useRef();
  const attachmentRef = useRef();

  const onImageIconClick = () => {
    inputRef.current?.click();
  };
  const onUploadImage = async (e, blob) => {
    if (loadingRef.current) {
      return;
    }

    track('Image Upload Started');

    loadingRef.current = true;
    const target = e?.currentTarget;
    const file = target?.files[0] || blob;
    const fileName = file.name;
    const type = IMAGE_TYPE;

    setAttachments([...attachmentRef.current, { fileName, url: '', type }]);
    const imageUrl = await uploadImageFile(file, setErrorText);
    const newAttachments = [...attachmentRef.current];
    newAttachments.pop();

    if (imageUrl) {
      const url = Utils.getImageUrl(imageUrl);
      const newAttachment = {
        fileName,
        url,
        type,
      };
      newAttachments.push(newAttachment);
    }
    setAttachments(newAttachments);
    loadingRef.current = false;
  };

  const pasteAndUploadImage = event => {
    const item = (event.clipboardData || event.originalEvent.clipboardData).items[0];
    if (item.kind !== 'file' && !item.type.includes('image/')) {
      return;
    }

    const blob = item.getAsFile();
    const reader = new FileReader();
    reader.onload = () => onUploadImage(null, blob);
    reader.readAsDataURL(blob);
  };

  useEffect(() => {
    containerRef.current?.addEventListener?.('paste', pasteAndUploadImage, false);
    return () => {
      containerRef.current?.removeEventListener?.('paste', pasteAndUploadImage, false);
    };
  }, [containerRef, attachments, loadingRef.current]);

  useEffect(() => {
    attachmentRef.current = attachments;
  }, [attachments]);

  return (
    <LayoutRoot onClick={onImageIconClick}>
      <ImageIcon />
      <InvisibleImageInput
        onChange={onUploadImage}
        ref={inputRef}
        type="file"
        accept="image/png, image/jpeg image/jpg"
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background: ${({ isSelected, theme }) => (isSelected ? theme.colors.gray2 : '')};
  justify-content: center;
  width: 20px;
  height: 20px;
  transition: 0.3s;
  &:hover {
    background: ${({ isSelected, theme }) =>
      isSelected ? theme.colors.gray2 : theme.colors.ghostLight};
  }
`;

const InvisibleImageInput = styled.input`
  display: none;
`;

export default ImageButton;
