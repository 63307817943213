import Header from 'app/src/complex_components/notifications/Header';
import NotificationsListContainer from 'app/src/complex_components/notifications/NotificationsListContainer';
import OptionMenu from 'app/src/complex_components/notifications/OptionMenu';
import { useAccount } from 'app/src/context/AccountStore';
import { useInAppNotifications } from 'app/src/context/InAppNotificationsStore';
import { useUser } from 'app/src/context/userStore/UserStore';
import { track } from 'app/src/helpers/Tracker';
import { useBilling } from 'app/src/hooks/useBillings';
import { useInfiniteScroll } from 'app/src/hooks/useInfiniteScroll';
import React, { useEffect, useRef, useState } from 'react';
import useOnClickOutside from 'shared/react/hooks/useOutsideClick';
import styled from 'styled-components';

const TOP_OFFSET_PIXELS = 18;

function InAppNotificationsPanel() {
  const notificationsListRef = useRef();
  const containerRef = useRef({});
  const [{ inAppNotifications }, { fetchInAppNotificationsByTeamMemberId }] =
    useInAppNotifications();
  const [
    { teamMembers, isNotificationListOpen },
    { setInAppNotificationsPanelOpened, toggleNotificationListOpen },
  ] = useAccount();
  const [, { getUser }] = useUser();
  const user = getUser();
  const currentTeamMember = getCurrentTeamMember();
  const { hasReachedInAppNotificationsLimit } = useBilling();
  const [isOptionMenuOpen, setOptionMenuOpen] = useState(false);

  useEffect(() => {
    if (!currentTeamMember?.id) {
      return;
    }

    setInAppNotificationsPanelOpened(currentTeamMember.id);
  }, [currentTeamMember?.id]);

  function getCurrentTeamMember() {
    return teamMembers?.find(({ userId }) => userId === user?.owner);
  }

  const fetchMore = async () => {
    await fetchInAppNotificationsByTeamMemberId(currentTeamMember?.teamMemberId);
  };

  const handleClose = () => {
    toggleNotificationListOpen(false);
  };

  const handleToggleMenu = () => {
    setOptionMenuOpen(!isOptionMenuOpen);
  };

  useInfiniteScroll(notificationsListRef, fetchMore);
  useOnClickOutside([containerRef], handleClose);

  if (!isNotificationListOpen) {
    return null;
  }

  return (
    <LayoutRoot>
      <MainContainer ref={containerRef}>
        <Header onToggleMenu={handleToggleMenu} onClose={handleClose} />
        <ContentContainer ref={notificationsListRef} disabled={hasReachedInAppNotificationsLimit}>
          <NotificationsListContainer
            isFeatureDisabled={hasReachedInAppNotificationsLimit}
            inAppNotifications={inAppNotifications}
            currentTeamMember={currentTeamMember}
            onClosePanel={handleClose}
          />
        </ContentContainer>
        {isOptionMenuOpen && (
          <OptionMenu
            teamMemberId={currentTeamMember?.teamMemberId}
            onToggleMenu={handleToggleMenu}
            onClosePanel={handleClose}
          />
        )}
      </MainContainer>
    </LayoutRoot>
  );
}

const LayoutRoot = styled.div`
  z-index: 100000;
  position: absolute;
  left: 200px;
  top: ${TOP_OFFSET_PIXELS}px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    left: 8px;
    top: 70px;
  }
`;

const MainContainer = styled.div`
  background: white;
  display: grid;
  grid-template-rows: auto 1fr;
  border-radius: 16px 16px 16px 0;
  width: 395px;
  height: calc(100vh - ${TOP_OFFSET_PIXELS}px);
  overflow-y: hidden;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: calc(100vw - 16px);
    height: calc(100vh - 80px);
  }
`;

const ContentContainer = styled.div`
  grid-row: 2;
  grid-column: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: ${({ disabled }) => (disabled ? 'hidden' : 'auto')};
  overflow-x: hidden;
  max-height: 100%;
`;

export default InAppNotificationsPanel;
