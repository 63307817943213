import React, { useState } from 'react';
import styled from 'styled-components';
import UserBox from 'app/src/basic_components/UserBox';
import Gap16HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap16HorizontalFlexWrap';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import ItemTextValue from './ItemTextValue';
import PenIcon from 'app/src/images/PenIcon';
import ChangeIconModal from './ChangeIconModal';
import Utils from '../../utils';
import { track } from '../../helpers/Tracker';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';

function BaseDetails({
  avatarName,
  updateFunction,
  user,
  header,
  headerPlaceholder,
  subHeaderPlaceholder,
  subHeader,
  logoSettings,
  routeKey,
  canEdit = true,
  location,
}) {
  const [changeIconModalOpen, setChangeIconModalOpen] = useState(false);

  const toggleModal = () => {
    if (!changeIconModalOpen) {
      track(`Change ${location} Icon`);
    }
    setChangeIconModalOpen(!changeIconModalOpen);
  };

  const logo = Utils.getLogoUrl(logoSettings?.logo);
  return (
    <>
      <LayoutRoot>
        <AvatarContainer canEdit={canEdit} onClick={toggleModal}>
          <Avatar
            fontSize="24px"
            fontWeight="900"
            color="white"
            backgroundColor={logoSettings?.color}
            userImageSrc={logo}
            name={avatarName}
            scale={'64px'}
          />
          {canEdit && (
            <EditButton>
              <PenIcon />
            </EditButton>
          )}
        </AvatarContainer>
        <NameEmailContainer>
          <ItemTextValue isTitle placeholder={headerPlaceholder}>
            {header}
          </ItemTextValue>
          <ItemTextValue placeholder={subHeaderPlaceholder}>{subHeader}</ItemTextValue>
        </NameEmailContainer>
      </LayoutRoot>
      <ChangeIconModal
        user={user}
        updateFunction={updateFunction}
        name={name || avatarName}
        routeKey={routeKey}
        open={changeIconModalOpen}
        logoSettings={logoSettings}
        closeModal={toggleModal}
      />
    </>
  );
}

export default BaseDetails;

const LayoutRoot = styled(Gap16HorizontalFlexWrap)`
  align-items: center;
  width: 420px;
`;

const EditButton = styled(HorizontalFlexWrap)`
  grid-area: 1;
  grid-column: 1;
  border-radius: 100%;
  height: 24px;
  width: 24px;
  background: ${({ theme }) => theme.colors.white};
  place-self: flex-end;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  z-index: 1;
  opacity: 0;
  transition: 0.3s;
`;

const Avatar = styled(UserBox)`
  border-radius: 100%;
  grid-area: 1;
  grid-column: 1;
`;

const NameEmailContainer = styled(VerticalFlex)`
  gap: 2px;
`;

const AvatarContainer = styled.div`
  display: grid;
  cursor: pointer;
  pointer-events: ${({ canEdit }) => (canEdit ? 'all' : 'none')};

  &:hover ${EditButton} {
    opacity: ${({ canEdit }) => (canEdit ? '1' : '0')};
  }
`;
