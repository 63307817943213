import React from 'react';

const AddPartIcon = ({ width = 38, height = 38, ...props }) => (
  <svg
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 38 38"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19 .25C8.61.25.25 8.688.25 19 .25 29.39 8.61 37.75 19 37.75c10.313 0 18.75-8.36 18.75-18.75C37.75 8.687 29.312.25 19 .25Zm7.5 20.625h-5.625V26.5c0 1.094-.86 1.875-1.875 1.875-1.094 0-1.875-.781-1.875-1.875v-5.625H11.5c-1.094 0-1.875-.781-1.875-1.875 0-1.016.781-1.875 1.875-1.875h5.625V11.5c0-1.016.781-1.875 1.875-1.875 1.016 0 1.875.86 1.875 1.875v5.625H26.5c1.016 0 1.875.86 1.875 1.875 0 1.094-.86 1.875-1.875 1.875Z"
      fill="#01BE81"
    />
  </svg>
);

export default AddPartIcon;
