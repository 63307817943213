import React from 'react';
import styled from 'styled-components';
import { FEED_CART_TITLE_DATA_TYPE } from '../../../../constants/feedPreviewDataTypes.constants';
import { Gap8HorizontalFlex } from '../../flex_layouts/HorizontalFlex';
import { BolderTextBody } from '../../Text';
import FeedCartItemsQuantity from './FeedCartItemsQuantity';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';

const FeedCartTitle = ({ itemsQuantity }) => {
  const [, { t }] = useTranslation('feed.cart');

  return (
    <LayoutRoot>
      <BolderTextBody data-type={FEED_CART_TITLE_DATA_TYPE}>{t('myCart')}</BolderTextBody>
      <FeedCartItemsQuantity quantity={itemsQuantity} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlex)`
  align-items: center;
`;

export default FeedCartTitle;
