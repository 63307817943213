import React from 'react';
import styled from 'styled-components';
import { getIsDynamic, useProjects } from 'app/src/context/ProjectsStore';
import APP_CONFIG from 'app/src/config/app.config';
import { track } from '../../helpers/Tracker';

const AnalyticsTolstoyLink = ({ value: publishId }) => {
  const [, { getProjectByPublishId }] = useProjects();

  const project = getProjectByPublishId(publishId);
  const name = project?.name;
  const link = `${APP_CONFIG.PUBLISH_URL}/${publishId}?sneakpeek`;

  const onLinkClick = () => {
    track('Analytics Tolstoy Link Click');
  };

  if (!name) {
    return null;
  }

  if (getIsDynamic(project)) {
    return name;
  }

  return (
    <StyledAnalyticsTolstoyLink
      target="_blank"
      href={link}
      rel="noreferrer"
      title={name}
      onClick={onLinkClick}
    >
      {name}
    </StyledAnalyticsTolstoyLink>
  );
};

export default AnalyticsTolstoyLink;

const StyledAnalyticsTolstoyLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
