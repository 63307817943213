import React from 'react';

const CheckMarkIcon = ({ className }) => {
  return (
    <svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.016.984a.723.723 0 0 1 0 1.055l-6 6a.723.723 0 0 1-1.055 0l-3-3a.723.723 0 0 1 0-1.055.723.723 0 0 1 1.055 0L4.5 6.445 9.96.985a.723.723 0 0 1 1.056 0Z"
        fill="#fff"
      />
    </svg>
  );
};

export default CheckMarkIcon;
