import React from 'react';
import styled from 'styled-components';
import { TextSmall } from 'shared/react/components/complex/Text';
import { Tooltip as MuiTooltip } from '@material-ui/core';

const Tooltip = ({
  text,
  header,
  placement,
  tooltipImage,
  hidden,
  children,
  containerWidth,
  maxWidth,
  ...props
}) => {
  if (hidden) {
    return children;
  }

  if (!header && !text) {
    return children;
  }

  const getTooltipBody = () => {
    return (
      <React.Fragment>
        <TooltipContainer maxWidth={maxWidth} {...props}>
          {header && (
            <TitleContainer>
              <TooltipTitle>{header}</TooltipTitle>
            </TitleContainer>
          )}
          {text && <TextContainer>{text}</TextContainer>}
          {tooltipImage}
        </TooltipContainer>
      </React.Fragment>
    );
  };

  return (
    <StyledTooltip
      title={getTooltipBody()}
      disableFocusListener
      disableTouchListener
      disableInteractive
      arrow
      placement={placement}
      enterDelay={0}
      {...props}
    >
      <Container containerWidth={containerWidth}>{children}</Container>
    </StyledTooltip>
  );
};

export default Tooltip;

const ToBeStyledTooltip = ({ className, ...props }) => (
  <MuiTooltip {...props} classes={{ tooltip: className }} />
);

const StyledTooltip = styled(ToBeStyledTooltip)`
  background: ${({ theme }) => theme.colors.gray2};
  padding: 0;

  & .MuiTooltip-arrow::before {
    background: ${({ theme }) => theme.colors.gray2};
  }
`;

const TooltipContainer = styled.div`
  display: flex;
  z-index: 100;
  flex-flow: row wrap;
  padding: 8px;
  border-radius: 5px;
  max-width:${({ maxWidth }) => (maxWidth ? maxWidth : '225px')} 
  gap: 8px;
`;

const TitleContainer = styled.div`
  display: flex;
`;

const TooltipTitle = styled(TextSmall)`
  font-weight: 800;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.white};
`;

const TextContainer = styled(TextSmall)`
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
`;

const Container = styled.div`
  width: ${({ containerWidth }) => containerWidth || ''};
`;
