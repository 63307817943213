import * as React from 'react';

const MicIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="#fff"
        d="M12.75 6a.74.74 0 00-.75.75V8c0 2.344-2.031 4.219-4.406 4C5.5 11.781 4 9.937 4 7.844V6.75A.76.76 0 003.25 6a.74.74 0 00-.75.75v1.031c0 2.782 2 5.282 4.75 5.657V14.5h-1.5a.74.74 0 00-.75.75c0 .438.313.75.75.75h4.5a.74.74 0 00.75-.75.76.76 0 00-.75-.75h-1.5v-1.031C11.406 13.094 13.5 10.78 13.5 8V6.75a.76.76 0 00-.75-.75zM8 11a3 3 0 003-3V3a3 3 0 00-6 0v5a3 3 0 003 3zM6.5 3A1.5 1.5 0 018 1.5c.813 0 1.5.688 1.5 1.5v5A1.5 1.5 0 018 9.5 1.48 1.48 0 016.5 8V3z"
      ></path>
    </svg>
  );
};

export default MicIcon;
