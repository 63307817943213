import { useCartContext } from 'shared/react/components/complex/context/CartContext';

const useFeedCart = () => {
  const { cart = {}, setCart } = useCartContext();

  const actions = {
    setCart,
  };

  const numberOfCartItems = cart.items?.reduce((acc, item) => acc + item?.quantity, 0);
  return [{ cart, numberOfCartItems }, actions];
};

export default useFeedCart;
