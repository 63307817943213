import React from 'react';

const DefaultAnswersIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#FEE1E6" />
      <path
        d="M14.25 9.125A1.14 1.14 0 0 0 13.125 8h-6.75c-.633 0-1.125.516-1.125 1.125v6.75A1.11 1.11 0 0 0 6.375 17h6.75c.61 0 1.125-.492 1.125-1.125v-6.75Zm4.5.375c0-.586-.703-.938-1.195-.61L15 10.673v3.68l2.555 1.78c.492.329 1.195-.023 1.195-.609V9.5Z"
        fill="#FA536E"
      />
    </svg>
  );
};

export default DefaultAnswersIcon;
