import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Checkbox from 'app/src/complex_components/Checkbox';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import DefaultProjectPlaceholderV2 from 'app/src/images/dashboard_v2/default_project_placeholder_v2.svg';
import { NO_VIDEOS_PROJECT_IMAGE_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import Utils from 'shared/react/utils/utils';
import ComponentWithLoader from 'app/src/complex_components/ComponentWithLoader';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { VIDEO_STATUS } from 'shared/react/constants/video.constants';

const CardImageContainer = ({
  isChecked,
  buttonsContainer,
  selectCard,
  imageSrc,
  children,
  selectedCardsLength,
  videoStatus,
  disableExtraTolstoyClick,
}) => {
  const [videoImage, setVideoImage] = useState(imageSrc);
  const [isError, setIsError] = useState(false);

  const onImageError = () => {
    setVideoImage(DefaultProjectPlaceholderV2);
    setIsError(true);
  };

  useEffect(() => {
    setVideoImage(imageSrc);
  }, [imageSrc, videoStatus]);

  return (
    <LayoutRoot>
      <ComponentWithLoader
        isLoading={videoStatus === VIDEO_STATUS.uploading}
        renderLoader={indicator => (
          <LoadingIndicatorContainer>{indicator}</LoadingIndicatorContainer>
        )}
      >
        <StyledImage
          onError={onImageError}
          src={videoImage}
          data-test-id={isError ? NO_VIDEOS_PROJECT_IMAGE_TEST_ID : null}
        />
      </ComponentWithLoader>
      {!disableExtraTolstoyClick && (
        <ButtonsContainer isSelected={isChecked} data-child={buttonsContainer}>
          <ButtonsContent>
            <Checkbox
              checked={isChecked}
              onChange={selectCard}
              disableFocusRipple
              onClick={Utils.stopPropagation}
            />
            {selectedCardsLength ? '' : children}
          </ButtonsContent>
        </ButtonsContainer>
      )}
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  flex-grow: 1;
  display: grid;
  position: relative;
  min-height: 180px;
`;

const LoadingIndicatorContainer = styled(HorizontalFlexCentered)`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.gray32};
`;

const StyledImage = styled.img`
  border-radius: 12px 12px 0 0;
  grid-row: 1;
  grid-column: 1;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 450px;
  object-fit: contain;
  background: ${({ theme }) => theme.colors.black};
  aspect-ratio: 16/9;
`;

const ButtonsContainer = styled.div`
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};
  grid-row: 1;
  grid-column: 1;
  transition: 0.25s;
  background: ${({ theme }) => theme.getHexOpacity(theme.colors.black, 40)};
`;

const ButtonsContent = styled(Gap8HorizontalFlexWrap)`
  margin: 8px;
  justify-content: space-between;
  align-items: flex-start;
`;

export default CardImageContainer;
