// FeedProductContent
export const PRODUCT_HEADER_DATA_TYPE = 'product-header';
export const SMALL_PRODUCT_HEADER_DATA_TYPE = 'small-product-header';
export const PRODUCT_DETAILS_DATA_TYPE = 'product-details';

// FeedProductPrice
export const PRODUCT_PRICE_DATA_TYPE = 'product-price';
export const PRODUCT_PRICE_CURRENCY_SYMBOL_DATA_TYPE = 'product-price-currency-symbol';
export const PRODUCT_PRICE_NUMBER_DATA_TYPE = 'product-price-number';
export const SMALL_PRODUCT_PRICE_DATA_TYPE = 'small-product-price';
export const PRODUCT_DISCOUNTED_PRICE_DATA_TYPE = 'discounted-price-container';

// FeedProduct
export const FEED_PRODUCT_DATA_TYPE = 'feed-product';
export const PRODUCT_IMAGE_DATA_TYPE = 'product-image';
export const MULTI_PRODUCT_SECTION_DATA_TYPE = 'multi-product';
export const MORE_BUTTON_DATA_TYPE = 'more-products';
export const SMALL_PRODUCT_VIEW_DATA_TYPE = 'small-product-view';
export const PRODUCTS_SECTION_WRAPPER_DATA_TYPE = 'products-section-wrapper';
export const FEED_PRODUCT_DESCRIPTION_DATA_TYPE = 'feed-product-description';
export const FEED_PRODUCT_DESCRIPTION_CONTENT_DATA_TYPE = 'feed-product-description-content';
export const FEED_PRODUCT_DESCRIPTION_TITLE_DATA_TYPE = 'feed-product-description-title';
export const FEED_PRODUCT_OPTIONS_BUY_NOW_DATA_TYPE = 'feed-product-options-buy-now';

// FeedProductOptionSelect
export const PRODUCT_OPTION_DATA_TYPE = 'product-option';
export const PRODUCT_OPTION_HEADER_DATA_TYPE = 'product-option-header';
export const SELECT_INPUT_CONTAINER_DATA_TYPE = 'select-input-container';
export const MULTI_PRODUCT_AVAILABLE_OPTIONS_DATA_TYPE = 'multi-product-available-options';

// FeedButton
export const FEED_BUTTON_DATA_TYPE = 'feed-button';
export const FEED_BUTTON_TEXT_DATA_TYPE = 'feed-button-text';
export const FEED_BUTTON_LOADER_DATA_TYPE = 'feed-button-loader';

// Carousel
export const CAROUSEL_DATA_TYPE = 'carousel';

// CarouselArrow
export const CAROUSEL_ARROW_DATA_TYPE = 'carousel-arrow';

// FeedDescription
export const FEED_DESCRIPTION_DATA_TYPE = 'feed-description';
export const FEED_DESCRIPTION_TITLE_DATA_TYPE = 'feed-description-title';
export const FEED_DESCRIPTION_TEXT_DATA_TYPE = 'feed-description-text';
export const FEED_DESCRIPTION_CONTENT_DATA_TYPE = 'feed-description-content';

// FeedUpNext
export const FEED_UP_NEXT_DATA_TYPE = 'feed-up-next';

// FeedCurrentStepDetails
export const FEED_CURRENT_STEP_DETAILS_DATA_TYPE = 'feed-current-step-details';

// FeedNavigationArrows
export const FEED_NAVIGATION_ARROWS_DATA_TYPE = 'feed-navigation-arrows';

// FeedQuickAddButton
export const FEED_QUICK_ADD_BUTTON_DATA_TYPE = 'feed-quick-add-button';

// FeedQuickAddButtonContent
export const FEED_QUICK_ADD_BUTTON_CONTENT_DATA_TYPE = 'feed-quick-add-button-content';
export const FEED_QUICK_ADD_BUTTON_PLUS_ICON_DATA_TYPE = 'feed-quick-add-button-plus-icon';

// FeedShare
export const FEED_SHARE_COPY_LINK_DATA_TYPE = 'feed-share-copy-link';
export const FEED_SHARE_LINK_DATA_TYPE = 'feed-share-link';
export const FEED_SHARE_SOCIAL_BUTTONS_DATA_TYPE = 'feed-share-social-buttons';

// FeedCartButton
export const FEED_CART_BUTTON_DATA_TYPE = 'feed-cart-button';
export const FEED_MENU_BUTTON_DATA_TYPE = 'feed-menu-button';
export const FEED_CART_TITLE_DATA_TYPE = 'feed-cart-title';
export const FEED_CART_SUBTOTAL_DATA_TYPE = 'feed-cart-subtotal';
export const FEED_CART_QUANTITY_DATA_TYPE = 'feed-cart-quantity';

// MODAL
export const PRODUCT_MODAL_DATA_TYPE = 'product-modal';

// recommandations
export const FEED_RECOMMENDED_PRODUCTS_NAME_DATA_TYPE = 'feed-recommended-products-name';
export const RECOMMENDED_PRODUCT_PRICE_DATA_TYPE = 'feed-recommended-products-price';
export const RECOMMENDED_PRODUCTS_TITLE_DATA_TYPE = 'feed-recommended-products-title';
