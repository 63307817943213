import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import Answer from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/custom/Answer';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Button from 'shared/react/components/complex/Button';
import Types from 'shared/react/theme/Types';
import { track } from 'app/src/helpers/Tracker';
import { getDefaultAnswer } from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/editStepDefaultData';
import { FEATURE_APP_LIMITS, FEATURE_QUIZ_LIMITS } from 'shared/react/constants/features.constants';
import { useFeatures } from 'app/src/context/FeaturesStore';
import Tooltip from 'app/src/basic_components/Tooltip';
import Utils from 'app/src/utils';
import Accordion from 'app/src/basic_components/accordion/Accordion';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import { TextBody, TextSmall } from 'shared/react/components/complex/Text';
import InfoIconWithTooltip from 'app/src/complex_components/InfoIconWithTooltip';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import Sizes from 'shared/react/theme/Sizes';
import AnswerTypeMenu from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/custom/AnswerTypeMenu';
import { useFormContext } from 'react-hook-form';
import { ADD_CHOICE_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import { safeScrollIntoViewIfNeeded } from 'app/src/utils/browser.utils';
import AntSwitch from 'app/src/basic_components/AntSwitch';
import InvisibleDiv from 'app/src/basic_components/InvisibleDiv';

const EditCustomButtons = ({
  answers,
  setAnswers,
  project,
  isLoading,
  onChooseAnswerNext,
  setSelectedIndex,
  handleAnswerChanged,
  onModalPropertyChanged,
  selectedAnswer,
  multipleChoiceSelection,
}) => {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const [sideBarButtonsEnabled] = watch(['sideBarButtonsEnabled']);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [, { getSettingsByKey }] = useFeatures();
  const ref = useRef();
  const { maxNumberOfAnswersInTolstoy = 9 } = getSettingsByKey(FEATURE_APP_LIMITS);
  const { maxNumberOfAnswersInTolstoy: maxNumberOfAnswersInQuizTolstoy = 5, isEnabled = true } =
    getSettingsByKey(FEATURE_QUIZ_LIMITS);
  const isMultipleDisabled = !!answers.find(answer => {
    return !!answer.type;
  });

  const onMultipleChoiceSelection = () => {
    if (answers.length) {
      const newAnswers = [...answers];
      const next = answers[0].next;
      newAnswers.forEach(answer => {
        answer.next = next;
      });
      setAnswers(newAnswers);
    }
    setValue('multipleChoiceSelection', !multipleChoiceSelection);
  };

  const advancedOptions = [
    {
      header: 'Show default buttons',
      desc: 'Allow your viewers to leave a video, audio or text response at any point during your Tolstoy.',
      checked: sideBarButtonsEnabled,
      onChange: () => setValue('sideBarButtonsEnabled', !sideBarButtonsEnabled),
    },
    {
      header: 'Allow multiple selection',
      desc: 'Allow your viewers to select multiple answers per question. All viewers will follow the same path regardless of their choice.',
      checked: multipleChoiceSelection,
      onChange: onMultipleChoiceSelection,
      disabled: isMultipleDisabled,
      tooltipText: isMultipleDisabled
        ? 'To use multi choice selection, all buttons must lead to the same next step and not have an action attached.'
        : '',
    },
  ];

  const isQuizOptionDisabled = useMemo(() => {
    if (!Utils.isQuiz(project?.tolstoyType) || Utils.isQuiz(project?.tolstoyType, 2)) {
      return false;
    }

    if (!isEnabled) {
      return true;
    }

    if (answers.length >= maxNumberOfAnswersInQuizTolstoy) {
      return true;
    }

    return false;
  }, [project, answers]);

  const handleAddAnswerClicked = () => {
    if (answers.length > maxNumberOfAnswersInTolstoy) {
      return;
    }

    track('Add Choice Click');
    setAnswers([
      ...answers,
      // isReply ? getDefaultReplyAnswer() :
      getDefaultAnswer(),
    ]);

    setTimeout(() => {
      safeScrollIntoViewIfNeeded(ref.current);
    }, 0);
  };

  const handleRemoveAnswerClicked = index => {
    const list = [...answers];
    list.splice(index, 1);
    setAnswers(list);
  };

  const onCollectViewerInfo = (e, index) => {
    if (multipleChoiceSelection) {
      return;
    }

    setAnchorEl(e.currentTarget);
    setSelectedIndex(index);
  };

  const shouldShowAddButton = answers.length < maxNumberOfAnswersInTolstoy;

  return (
    <LayoutRoot>
      <AnswersContainer>
        {answers.map((answer, i) => {
          return (
            <Answer
              answer={answer}
              index={i}
              key={answer.key}
              stepsOrder={project.stepsOrder}
              handleAnswerChanged={handleAnswerChanged}
              onCollectViewerInfo={onCollectViewerInfo}
              handleRemoveAnswerClicked={handleRemoveAnswerClicked}
              onChooseAnswerNext={onChooseAnswerNext}
              multipleChoiceSelection={multipleChoiceSelection}
              error={errors?.answers?.[i]}
              isLoading={isLoading}
            />
          );
        })}
        <InvisibleDiv ref={ref} />
      </AnswersContainer>
      <AddContainer>
        {shouldShowAddButton && (
          <AddTooltip
            placement="right"
            text={isQuizOptionDisabled ? 'Less is more, we allow 4 options per quiz part' : ''}
          >
            <Button
              disabled={!!isLoading || isQuizOptionDisabled}
              type={Types.Success}
              size={Sizes.Small}
              data-test-id={ADD_CHOICE_TEST_ID}
              padding="5px 10px"
              onClick={handleAddAnswerClicked}
            >
              Add +
            </Button>
          </AddTooltip>
        )}
      </AddContainer>
      <StyledAccordion
        headerPadding={'16px 24px 16px'}
        mobileHeaderPadding={'16px 12px 16px'}
        isExpanded={isExpanded || false}
        setExpanded={setIsExpanded}
        headerText={<HeaderText>Advanced settings</HeaderText>}
      >
        <AdvancedContainer>
          {advancedOptions.map(({ header, desc, onChange, disabled, checked, tooltipText }) => (
            <MultipleChoiceContainer key={header}>
              <MultipleChoiceTextContainer>
                <TextBody>{header}</TextBody>
                <InfoIconWithTooltip title={desc} />
              </MultipleChoiceTextContainer>
              <Tooltip text={tooltipText}>
                <SwitchContainer>
                  <AntSwitch checked={checked} disabled={disabled} onChange={onChange} />
                  <OnText>On</OnText>
                </SwitchContainer>
              </Tooltip>
            </MultipleChoiceContainer>
          ))}
        </AdvancedContainer>
      </StyledAccordion>
      <AnswerTypeMenu
        anchorEl={anchorEl}
        selectedAnswer={selectedAnswer}
        setAnchorEl={setAnchorEl}
        answer={selectedAnswer}
        onModalPropertyChanged={onModalPropertyChanged}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)``;

const AnswersContainer = styled(VerticalFlex)``;

const AddTooltip = styled(Tooltip)`
  width: 300px;
`;

const AddContainer = styled.div`
  width: fit-content;
  padding-left: 24px;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    padding-left: 16px;
  }
`;

const HeaderText = styled(TextBody)`
  font-weight: 400;
`;

const StyledAccordion = styled(Accordion)`
  && {
    background: ${({ theme, isExpanded }) =>
      isExpanded ? theme.colors.gray1 : theme.colors.white};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.gray1};
  }

  & .MuiAccordionDetails-root {
    background: ${({ theme }) => theme.colors.white};
  }
`;

const MultipleChoiceContainer = styled.div`
  display: grid;
  justify-self: start;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 10px;
  padding: 0 0px;
  width: 300px;
`;

const MultipleChoiceTextContainer = styled(Gap8HorizontalFlexWrap)`
  gap: 8px;
  align-items: center;
`;

const AdvancedContainer = styled(VerticalFlex)`
  gap: 24px;
  padding: 16px 24px ${({ isExpanded }) => (isExpanded ? 0 : '')};

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    padding: 16px 16px ${({ isExpanded }) => (isExpanded ? 0 : '')};
  }
`;

const SwitchContainer = styled(Gap8HorizontalFlexWrap)``;

const OnText = styled(TextSmall)``;

export default EditCustomButtons;
