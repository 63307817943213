import { YoutubeConfig } from "./types/config";
import { getYoutubeEmbedUrl } from "./utils";

type Props = {
  step: { videoUrl: string; type: string };
  youtubeConfig: YoutubeConfig;
};

const YoutubeHero = ({ step, youtubeConfig = {} as YoutubeConfig }: Props) => {
  const { youtubeLoop, youtubeAutoplay } = youtubeConfig || {};

  return (
    <iframe
      frameBorder="0"
      src={getYoutubeEmbedUrl(step.videoUrl, youtubeLoop, youtubeAutoplay)}
      className="youtube-iframe"
      style="overflow:hidden;height:100%;width:100%"
      width={window.innerWidth}
      height={window.innerHeight}
    />
  );
};

export default YoutubeHero;
