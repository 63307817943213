export const VIDEO_INITIAL_DELAY_DURATION = 500;
export const DESKTOP_VIDEO_WIDTH = 580;
export const MOBILE_VIDEO_WIDTH = 303.75;
export const MINIMUM_TRIMMING_SECONDS = 1;
export const NEXT = 'next';
export const TYPE = 'type';
export const EDIT_STEP_TABS = {
  video: 'video',
  overlayText: 'overlayText',
  buttons: 'editButtons',
};
export const PRODUCTS_RESULT = 'products-result';

export const TABS = {
  default: 'default',
  custom: 'custom',
  products: 'shoppable',
};

export const PRODUCTS = 'products';

export const SHOPPABLE_STEP_TYPES = [TABS.products, PRODUCTS];
