import { useEffect, useState } from 'react';

const useDrag = ({ onDrop }) => {
  const [draggingFromIndex, setDraggingFromIndex] = useState(null);
  const [draggingToIndex, setDraggingToIndex] = useState(null);
  const [loadingIndex, setLoadingIndex] = useState(null);

  const isDraggingAboveItem = itemIndex => {
    return draggingFromIndex === null || itemIndex === draggingFromIndex;
  };

  const handleDragStart = (e, index) => {
    e.stopPropagation();
    setDraggingFromIndex(index);
  };

  const handleDragEnd = e => {
    e.stopPropagation();
    setDraggingFromIndex(null);
    setDraggingToIndex(null);
  };

  const handleDrop = async (e, index, isDisabled) => {
    e.preventDefault();
    e.stopPropagation();

    if (isDraggingAboveItem(index) || isDisabled) {
      return;
    }

    setLoadingIndex(index);

    await onDrop(index);

    setLoadingIndex(null);
  };

  const onDragOver = (e, index) => {
    e.stopPropagation();
    e.preventDefault();

    if (isDraggingAboveItem(index)) {
      setDraggingToIndex(null);
      return;
    }

    if (draggingToIndex === index) {
      return;
    }

    setDraggingToIndex(index);
  };

  useEffect(() => {
    window.addEventListener('dragend', handleDragEnd);

    return () => {
      window.removeEventListener('dragend', handleDragEnd);
    };
  }, []);

  return {
    onDragOver,
    isDraggingAboveItem,
    handleDrop,
    handleDragStart,
    loadingIndex,
    draggingToIndex,
    draggingFromIndex,
  };
};

export default useDrag;
