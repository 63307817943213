import React from 'react';
import styled from 'styled-components';

import Utils from 'shared/react/utils/utils';
import Checkbox from 'app/src/complex_components/Checkbox';
import { CardControlsContainer } from './Card';

const CardControls = ({ children, isSelected, toggleSelected, isSelectionMode }) => {
  return (
    <CardControlsContainer isSelected={isSelected}>
      <CheckboxStyled
        checked={isSelected}
        onChange={toggleSelected}
        disableFocusRipple
        onClick={Utils.stopPropagation}
      />
      {!isSelectionMode && children}
    </CardControlsContainer>
  );
};

const CheckboxStyled = styled(Checkbox)`
  padding: 4px;
`;

export default CardControls;
