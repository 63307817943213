import React from 'react';
import Utils from 'src/utils';
import FeedCtaEditor from './FeedCtaEditor';
import FeedMultipleProductDividerEditor from './FeedMultipleProductDividerEditor';
import { DEAULT_MULTIPLE_PRODUCTS_SETTINGS } from '../../../LookAndFeelPage.constants';
import EditorOnOffToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorOnOffToggleItem';
import EditorTextInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorTextInputItem';

const FeedMultipleProductEditor = ({
  multipleProductsSettings = DEAULT_MULTIPLE_PRODUCTS_SETTINGS,
  setPlayerSettingsProperty,
}) => {
  const {
    divider,
    dividerTitle,
    title: title,
    titleEnabled: titleEnabled,
    ctaSettings: {
      ctaWithVariants: { buttonText: ctaWithVariantsButtonText },
      ctaWithoutVariants: { buttonText: ctaWithoutVariantsButtonText },
    },
  } = multipleProductsSettings || {};

  const updateMultipleProductsSettings = settings =>
    setPlayerSettingsProperty(
      'multipleProductsSettings',
      Utils.mergeObjects(multipleProductsSettings, settings)
    );

  const updateTitleEnabled = enabled => updateMultipleProductsSettings({ titleEnabled: enabled });
  const updateTitle = title => updateMultipleProductsSettings({ title });
  const updateCtaSettings = ctaSettings => updateMultipleProductsSettings({ ctaSettings });
  const updateDividerSettings = divider => updateMultipleProductsSettings({ divider });
  const updateDividerTitleSettings = dividerTitle =>
    updateMultipleProductsSettings({ dividerTitle });

  const updateCtaWithVariantsSettings = (update = {}) => {
    const ctaWithVariants = Utils.mergeObjects(
      multipleProductsSettings.ctaSettings.ctaWithVariants,
      update
    );
    const ctaSettings = Utils.mergeObjects(multipleProductsSettings.ctaSettings, {
      ctaWithVariants,
    });

    updateCtaSettings(ctaSettings);
  };

  const updateCtaWithoutVariantsSettings = (update = {}) => {
    const ctaWithoutVariants = Utils.mergeObjects(
      multipleProductsSettings.ctaSettings.ctaWithoutVariants,
      update
    );
    const ctaSettings = Utils.mergeObjects(multipleProductsSettings.ctaSettings, {
      ctaWithoutVariants,
    });

    updateCtaSettings(ctaSettings);
  };

  const onCtaWithVariantsTextChange = value => updateCtaWithVariantsSettings({ buttonText: value });
  const onCtaWithoutVariantsTextChange = value =>
    updateCtaWithoutVariantsSettings({ buttonText: value });

  return (
    <>
      <EditorOnOffToggleItem text="Title" checked={titleEnabled} onChange={updateTitleEnabled} />
      {titleEnabled && (
        <EditorTextInputItem
          placeholder="Title text"
          text="Text"
          value={title}
          maxWidth="200px"
          onChange={updateTitle}
        />
      )}
      <FeedMultipleProductDividerEditor
        dividerSettings={divider}
        dividerTitleSettings={dividerTitle}
        updateDividerSettings={updateDividerSettings}
        updateDividerTitleSettings={updateDividerTitleSettings}
      />
      <FeedCtaEditor
        title="Cta"
        buttonTextPlaceholder="View product"
        buttonText={ctaWithVariantsButtonText}
        onButtonTextChange={onCtaWithVariantsTextChange}
      />
      {/*<FeedCtaEditor*/}
      {/*  title="Cta without variants"*/}
      {/*  buttonTextPlaceholder="Add to cart"*/}
      {/*  buttonText={ctaWithoutVariantsButtonText}*/}
      {/*  onButtonTextChange={onCtaWithoutVariantsTextChange}*/}
      {/*/>*/}
    </>
  );
};

export default FeedMultipleProductEditor;
