import React from 'react';

const FacebookIcon = ({ fill = '#3C5193', width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m16.648 13.75.547-3.594H13.72V7.812c0-1.015.469-1.953 2.031-1.953h1.602V2.773S15.906 2.5 14.539 2.5c-2.851 0-4.726 1.758-4.726 4.883v2.773H6.608v3.594h3.204v8.75h3.906v-8.75h2.93Z"
        fill={fill}
      />
    </svg>
  );
};
export default FacebookIcon;
