import React from 'react';

const HomeIcon = () => {
  return (
    <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.7188 8.1875C19.0312 8.46875 19.0625 8.9375 18.8125 9.25C18.5312 9.5625 18.0625 9.59375 17.75 9.34375L17 8.6875V14.5C17 15.9062 15.875 17 14.5 17H5.5C4.09375 17 3 15.9062 3 14.5V8.6875L2.21875 9.34375C1.90625 9.59375 1.4375 9.5625 1.15625 9.25C0.90625 8.9375 0.9375 8.46875 1.25 8.1875L9.5 1.1875C9.78125 0.96875 10.1875 0.96875 10.4688 1.1875L18.7188 8.1875ZM5.5 15.5H7V10.75C7 10.0625 7.53125 9.5 8.25 9.5H11.75C12.4375 9.5 13 10.0625 13 10.75V15.5H14.5C15.0312 15.5 15.5 15.0625 15.5 14.5V7.40625L10 2.75L4.5 7.40625V14.5C4.5 15.0625 4.9375 15.5 5.5 15.5ZM8.5 15.5H11.5V11H8.5V15.5Z"
        fill="#2F3137"
      />
    </svg>
  );
};

export default HomeIcon;
