import React from 'react';
import MenuWithContainer from 'shared/react/components/basic/MenuWithContainer';
import styled from 'styled-components';
import { AnchorElementProps } from 'app/src/types/common/common';
import AccountMenuContent from 'app/src/pages/sidebar/account-menu/AccountMenuContent';

type Props = AnchorElementProps;

const AccountMenu = ({ anchorElement, setAnchorElement }: Props) => {
  const onClose = () => {
    setAnchorElement(null);
  };

  return (
    <Menu
      open={!!anchorElement}
      onClose={onClose}
      anchorEl={anchorElement}
      direction={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <AccountMenuContent onClose={onClose} />
    </Menu>
  );
};

const Menu = styled(MenuWithContainer)`
  max-width: 200px;
  padding: 8px;
  border-radius: 4px;
`;

export default AccountMenu;
