import React from 'react';
import styled, { useTheme } from 'styled-components';
import { ButtonGroupV2 } from 'src/complex_components/button-group/ButtonGroupV2';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import useNavigation from 'app/src/hooks/useNavigation';
import { useHistory, useParams } from 'react-router-dom';
import { projectTabs } from 'app/src/helpers/Routes';
import Utils from 'app/src/utils';
import { useProjects } from 'app/src/context/ProjectsStore';
import { publishMethodOptions } from 'app/src/types/entities';
import { useProductPageSettings } from 'app/src/context/ProductPageSettingsStore';
import { useApps } from 'app/src/context/AppsStore';
import {
  DESIGN_TAB_DATA_TEST_ID,
  RESPONSES_TAB_DATA_TEST_ID,
  VIDEOS_TAB_DATA_TEST_ID,
} from 'app/src/constants/dataTestIds.constants';
import RightButtonsGroup from './RightButtonsGroup';
import AnalyticsIcon from 'src/images/sidebar-v2/AnalyticsIcon';
import SettingsIcon from 'src/images/SettingsIcon';
import { PROJECT_TYPES_WITH_INSTALL_TAB } from 'src/constants/ui.constants';
import { useFeatureActions } from 'src/context/FeaturesStore';
import { FEATURE_STORIES_CAROUSEL_INSTALLATION_V2 } from 'shared/react/constants/features.constants';
import { getIsDerivedProject } from 'app/src/utils/project.utils';
import { EMAIL_FLOWS_KEY } from 'app/src/constants/analytics.constants';

const ProjectTabs = ({ project }) => {
  const { dynamic, feed, publishMethod, tolstoyType } = project;
  const { projectId, stepId = '' } = useParams();
  const isDerivedProject = getIsDerivedProject(project);
  const [{ step }] = useProjects({ projectId, stepId });
  const { navigateToProjectTab, getProjectTabPath, navigateToAccountAnalytics } = useNavigation();
  const [{ shopify }] = useApps();
  const [, { isProductPage }] = useProductPageSettings({});
  const history = useHistory();
  const theme = useTheme();
  const { getFeatureEnabled } = useFeatureActions();
  const pathname = history.location.pathname;
  const isShopApp = publishMethod === publishMethodOptions.shopApp;
  const isEmail = publishMethod === publishMethodOptions.email;
  const isVideoCollectionCampaign = publishMethod === publishMethodOptions.videoCollectionCampaign;

  const getIsInstallTabHidden = () => {
    if (
      [
        publishMethodOptions.carousel,
        publishMethodOptions.stories,
        publishMethodOptions.bubble,
        publishMethodOptions.tvChannel,
        publishMethodOptions.videoPage,
      ].includes(publishMethod)
    ) {
      return true;
    }

    const isStoriesCarouselInstallationV2Enabled = getFeatureEnabled(
      FEATURE_STORIES_CAROUSEL_INSTALLATION_V2
    );

    if (isStoriesCarouselInstallationV2Enabled) {
      return !PROJECT_TYPES_WITH_INSTALL_TAB.includes(publishMethod);
    }

    return false;
  };

  const isRulesHidden = () => {
    const shouldHideRulesByPublishMethod = [
      publishMethodOptions.videoPage,
      publishMethodOptions.landingPage,
      publishMethodOptions.embed,
      publishMethodOptions.hero,
    ].includes(publishMethod);

    if (isEmail || isVideoCollectionCampaign) {
      return true;
    }

    const isBubble = publishMethod === publishMethodOptions.bubble;
    if (isBubble) {
      return false;
    }

    if (dynamic) {
      return true;
    }

    if (shouldHideRulesByPublishMethod) {
      return true;
    }

    if (shopify && !isProductPage(project) && feed && !isBubble) {
      return true;
    }

    return false;
  };

  const analyticsButton = {
    text: 'Analytics',
    isEnabled: false,
    onClick: () => {
      navigateToAccountAnalytics({
        projectIds: [projectId],
        tab: isEmail ? EMAIL_FLOWS_KEY : null,
      });
    },
  };

  const leftButtons = [
    {
      text: 'Videos',
      isEnabled:
        (getProjectTabPath(projectId, stepId) === pathname && step) ||
        getProjectTabPath(projectId, '') === pathname,
      onClick: () => navigateToProjectTab(project, ''),
      dataTestId: VIDEOS_TAB_DATA_TEST_ID,
      hidden: isVideoCollectionCampaign,
    },
    {
      ...analyticsButton,
      hidden: !isShopApp || isVideoCollectionCampaign,
    },
    {
      text: 'Quiz',
      isEnabled: getProjectTabPath(projectId, projectTabs.quiz) === pathname,
      onClick: () => navigateToProjectTab(project, projectTabs.quiz),
      hidden: !Utils.isQuiz(tolstoyType),
    },
    {
      text: 'Design',
      isEnabled: getProjectTabPath(projectId, projectTabs.design) === pathname,
      onClick: () => navigateToProjectTab(project, projectTabs.design),
      dataTestId: DESIGN_TAB_DATA_TEST_ID,
      hidden: isShopApp || isDerivedProject,
    },
    {
      text: 'Rules',
      isEnabled: getProjectTabPath(projectId, projectTabs.rules) === pathname,
      onClick: () => navigateToProjectTab(project, projectTabs.rules),
      hidden: isRulesHidden(),
    },
    {
      text: 'Install',
      isEnabled: getProjectTabPath(projectId, projectTabs.installation) === pathname,
      onClick: () => navigateToProjectTab(project, projectTabs.installation),
      hidden: getIsInstallTabHidden(),
    },
    {
      text: 'Responses',
      isEnabled: getProjectTabPath(projectId, projectTabs.responses) === pathname,
      onClick: () => navigateToProjectTab(project, projectTabs.responses),
      hidden: feed || publishMethod === publishMethodOptions.tvChannel || isVideoCollectionCampaign,
      dataTestId: RESPONSES_TAB_DATA_TEST_ID,
    },
  ];

  const rightButtons = [
    {
      ...analyticsButton,
      hidden: isShopApp || isVideoCollectionCampaign,
      icon: <AnalyticsIcon />,
    },
    {
      text: '',
      isEnabled: getProjectTabPath(projectId, projectTabs.settings) === pathname,
      onClick: () => navigateToProjectTab(project, projectTabs.settings),
      icon: <SettingsIcon fill={theme.colors.black} width="12px" height="12px" />,
      hidden: isShopApp,
    },
  ];

  if (isShopApp) {
    return null;
  }

  return (
    <LayoutRoot isShopApp={isShopApp}>
      <ButtonGroupV2
        buttons={leftButtons}
        defaultBackground={undefined}
        disabled={undefined}
        height={undefined}
        width={undefined}
      />
      <RightButtonsGroup buttons={rightButtons} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)`
  padding: 24px;
  justify-content: space-between;
`;

export default ProjectTabs;
