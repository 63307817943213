import { useState, useEffect } from 'react';
import { useSelectedApp } from 'src/context/AppsStore';

const useAppColorPalette = () => {
  const [selectedApp] = useSelectedApp();
  const [palette, setPalette] = useState([]);

  useEffect(() => {
    const { theme } = JSON.parse(selectedApp?.settings || '{}');
    const newPalette = theme?.palette?.colors || [];

    setPalette(newPalette);
  }, [selectedApp]);

  return palette;
};

export default useAppColorPalette;
