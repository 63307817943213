import React, { useEffect, useMemo, useRef, useState } from 'react';
import { TextH6 } from 'shared/react/components/basic/text/TextV2';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import ProductTile from 'src/basic_components/ProductTile';
import { SearchInput } from 'src/basic_components/input/InputWithIcon';
import ComponentWithLoader from 'src/complex_components/ComponentWithLoader';
import { useSelectedApp } from 'src/context/AppsStore';
import { useVodConnection } from 'src/context/VodConnectionStore';
import { useModal } from 'src/context/ui_store/ModalStore';
import { useDebounce } from 'src/hooks/useDebounce';
import useGetSearchProducts from 'src/hooks/useGetSearchProducts';
import StandardModalV2 from 'src/pages/modals/StandardModalV2';
import { SearchProduct, SearchProductsPayload } from 'src/types/common';
import styled from 'styled-components';

type FetchProductsResponse = Promise<{
  products?: SearchProduct[];
  nextPageFrom?: number;
  isAborted?: boolean;
}>;

type Props = {
  closeModal: () => void;
};

// @TODO: Share search products logic with Products.tsx
const SelectProductModal = ({ closeModal }: Props) => {
  const [{ productConnections }] = useVodConnection();
  const [selectedApp] = useSelectedApp();
  const getSearchProducts = useGetSearchProducts(selectedApp);
  const [{ modalProps }] = useModal();

  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 600);

  const [isLoading, setIsLoading] = useState(true);
  const [searchProducts, setSearchProducts] = useState([]);
  const latestRequestIndexRef = useRef(0);
  const { onProductSelected } = modalProps;

  const latestTaggedProductIds = useMemo(
    () => productConnections.slice(0, 10).map(({ externalProductId }) => externalProductId),
    [productConnections]
  );

  const onSearchValueChange = e => {
    setSearchValue(e.target.value);
  };

  const fetchProducts = async (body: SearchProductsPayload): FetchProductsResponse => {
    const requestIndex = latestRequestIndexRef.current + 1;
    latestRequestIndexRef.current = requestIndex;
    const { searchProducts: products, nextPageFrom } = await getSearchProducts(body);

    if (requestIndex !== latestRequestIndexRef.current) {
      return { isAborted: true };
    }

    return { products, nextPageFrom };
  };

  const fetchProductsByTerm = async () => {
    const body = {
      term: debouncedSearchValue,
      appUrl: selectedApp.appUrl,
      appKey: selectedApp.appKey,
    } as SearchProductsPayload;

    const { products, isAborted } = await fetchProducts(body);

    if (isAborted) {
      return;
    }

    setSearchProducts(products);
    setIsLoading(false);
  };

  const fetchProductsByIds = async (ids: string[]) => {
    const body = {
      ids,
      appUrl: selectedApp.appUrl,
      appKey: selectedApp.appKey,
      isSearchByIds: true,
    } as SearchProductsPayload;

    const { products, isAborted } = await fetchProducts(body);

    if (isAborted) {
      return;
    }

    products.sort((a, b) => ids.indexOf(a.productId) - ids.indexOf(b.productId));

    setSearchProducts(products);
    setIsLoading(false);
  };

  const fetchProductsHandler = async () => {
    if (debouncedSearchValue || !latestTaggedProductIds.length) {
      await fetchProductsByTerm();
      return;
    }

    await fetchProductsByIds(latestTaggedProductIds);
  };

  const handleProductSelected = (externalProductId: string) => {
    setIsLoading(true);
    onProductSelected(externalProductId);
  };

  useEffect(() => {
    if (!selectedApp?.appUrl) {
      return;
    }

    setIsLoading(true);
    fetchProductsHandler();
  }, [debouncedSearchValue, selectedApp?.appUrl, latestTaggedProductIds]);

  return (
    <StyledStandardModal open onClose={closeModal}>
      <LayoutRoot>
        <HeaderContainer>
          <TextH6>Campaign product</TextH6>
          <StyledSearchInput
            onChange={onSearchValueChange}
            value={searchValue}
            placeholder="Search for a product..."
            disabled={!selectedApp?.appUrl}
          />
        </HeaderContainer>
        <ProductsContainer>
          <ComponentWithLoader isLoading={isLoading}>
            {searchProducts.map(({ productId, imageUrl, title }) => (
              <StyledProductTile
                key={productId}
                imageUrl={imageUrl}
                title={title}
                disableTooltip
                onClick={() => handleProductSelected(productId)}
                titleNumberOfLines={3}
              />
            ))}
          </ComponentWithLoader>
        </ProductsContainer>
      </LayoutRoot>
    </StyledStandardModal>
  );
};

const StyledStandardModal = styled(StandardModalV2)`
  height: 100%;
`;

const LayoutRoot = styled(Gap16VerticalFlex)`
  width: 386px;
  height: 494px;
`;

const HeaderContainer = styled(Gap16VerticalFlex)`
  padding: 24px 24px 0;
`;

const StyledSearchInput = styled(SearchInput)`
  height: 32px;
`;

const ProductsContainer = styled(Gap8VerticalFlex)`
  overflow-y: auto;
  padding: 0 24px 16px;
  align-items: center;
`;

const StyledProductTile = styled(ProductTile)`
  border: none;
  width: 100%;

  & img {
    width: 50px;
    height: 50px;
    box-shadow: ${({ theme }) => theme.boxShadows.elevation1};
    border: 2px solid ${({ theme }) => theme.colors.white};
  }
`;

export default SelectProductModal;
