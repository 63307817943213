import React from 'react';

type GaugeMeterIconProps = {
  pointerRotate?: number;
} & React.SVGProps<SVGSVGElement>;

const GaugeMeterIcon = ({ pointerRotate, ...props }: GaugeMeterIconProps) => {
  return (
    <svg width={12} height={7} xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
      <g stroke="null" fill="#090A0B">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.125 2.04a5.83 5.83 0 0 1 1.708 4.125h-1.166v-.583h.55a5.25 5.25 0 0 0-1.304-2.917l-.416.416-.413-.412.416-.416A5.25 5.25 0 0 0 6.292.923v.867h-.584V.923A5.25 5.25 0 0 0 2.5 2.253l.416.416-.412.412-.417-.416A5.25 5.25 0 0 0 .783 5.582h.55v.583H.167a5.833 5.833 0 0 1 9.958-4.124z"
        />
        <g stroke="null" transform={`rotate(${-46.77 + pointerRotate} 6.02127 4.51633)`}>
          <path d="M6.126 5.981A1.167 1.167 0 1 1 4.53 4.279 1.167 1.167 0 0 1 6.126 5.98z" />
          <path d="M4.53 4.279q.182-.171.412-.27l2.94-1.273-1.46 2.851q-.114.223-.296.394z" />
        </g>
      </g>
    </svg>
  );
};

export default GaugeMeterIcon;
