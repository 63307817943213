import React from 'react';
import Analytics from 'app/src/complex_components/analytics/Analytics';
import { FEATURE_ACCOUNT_ANALYTICS } from 'app/src/constants/appFeatures.constants';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { useHistory } from 'react-router-dom';
import { navigateToRoot } from '../../utils/navigation.utils';

const AccountAnalyticsPage = () => {
  const history = useHistory();
  const [, { getFeatureEnabled }] = useFeatures();

  const accountAnalyticsEnabled = getFeatureEnabled(FEATURE_ACCOUNT_ANALYTICS);

  if (!accountAnalyticsEnabled) {
    navigateToRoot(history);
    return null;
  }

  return <Analytics />;
};

export default AccountAnalyticsPage;
