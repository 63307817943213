import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import { toTimeString } from 'app/src/utils/video.utils';

const ONLY_NUMBERS_AND_DOT_AND_DOUBLE_DOTS_REGEX = '^[0-9.:]*$';
const debounceTime = 700;

const VideoDurationInputs = ({
  startTime,
  setStartTime,
  duration,
  endTime,
  setEndTime,
  minDifference,
  hideEndInput,
}) => {
  const [videoInputStartTime, setVideoInputStartTime] = useState('');
  const [videoInputEndTime, setVideoInputEndTime] = useState('');
  const startTimeout = useRef();
  const endTimeout = useRef();

  const getSecondsFromString = string => {
    const [minutes, seconds] = string.split(':');
    if (!seconds) {
      return +minutes;
    }
    const value = +minutes * 60 + +seconds;
    return value;
  };

  const onStartInputChange = e => {
    const value = e.target.value;
    clearTimeout(startTimeout.current);

    if (!value.match(ONLY_NUMBERS_AND_DOT_AND_DOUBLE_DOTS_REGEX)) {
      return;
    }

    setVideoInputStartTime(value);
    startTimeout.current = setTimeout(() => {
      onStartTimeInputBlur(e);
    }, debounceTime);
  };

  const onEndInputChange = e => {
    const value = e.target.value;
    clearTimeout(endTimeout.current);
    if (!value.match(ONLY_NUMBERS_AND_DOT_AND_DOUBLE_DOTS_REGEX)) {
      return;
    }

    setVideoInputEndTime(value);
    endTimeout.current = setTimeout(() => {
      onEndTimeInputBlur(e);
    }, debounceTime);
  };

  const onStartTimeInputBlur = e => {
    let value = getSecondsFromString(e.target.value);
    clearTimeout(startTimeout.current);

    if (value > duration - minDifference) {
      value = duration - minDifference;
    }

    if (value > endTime - minDifference) {
      setVideoInputEndTime(toTimeString(value + minDifference));
      setEndTime(value + minDifference);
    }

    if (value < 0) {
      value = 0;
    }

    setVideoInputStartTime(toTimeString(value));
    setStartTime(value);
  };

  const onEndTimeInputBlur = e => {
    let value = getSecondsFromString(e.target.value);
    clearTimeout(endTimeout.current);

    if (value >= duration) {
      value = duration;
    }

    if (value <= minDifference) {
      value = 0;
    }

    if (value <= minDifference) {
      value = minDifference;
    }

    if (startTime + minDifference >= value) {
      setVideoInputStartTime(toTimeString(value - minDifference));
      setStartTime(value - minDifference);
    }

    setVideoInputEndTime(toTimeString(value));
    setEndTime(value);
  };

  useEffect(() => {
    setVideoInputStartTime(toTimeString(startTime));
  }, [startTime]);

  useEffect(() => {
    setVideoInputEndTime(toTimeString(endTime));
  }, [endTime]);

  if (hideEndInput) {
    return (
      <InputsContainer>
        <Input
          onChange={onStartInputChange}
          onBlur={onStartTimeInputBlur}
          value={videoInputStartTime}
        />
      </InputsContainer>
    );
  }

  return (
    <InputsContainer>
      <Input
        onChange={onStartInputChange}
        onBlur={onStartTimeInputBlur}
        value={videoInputStartTime}
      />
      -
      <Input onBlur={onEndTimeInputBlur} onChange={onEndInputChange} value={videoInputEndTime} />
    </InputsContainer>
  );
};

const InputsContainer = styled(Gap8HorizontalFlexWrap)`
  flex-wrap: nowrap;
  align-items: center;
  gap: 4px;
`;

const Input = styled.input`
  outline: none;
  border: 1px solid ${({ theme }) => theme.colors.ghostLight};
  color: ${({ theme }) => theme.colors.gray2};
  border-radius: 8px;
  font-size: 12px;
  max-width: 60px;
  padding: 8px;
`;

export default VideoDurationInputs;
