export const EVENTS = {
  pageView: "pageView",
  sessionStart: "sessionStart",
  clickCta: "clickCta",
  buttonClicked: "heroButtonClicked",
};

export enum VIEWING_DEVICES {
  mobile = "mobile",
  desktop = "desktop",
  youtube = "youtube",
}

export const VIDEO_SIZE_TYPES = {
  fixed: "fixed",
  responsive: "responsive",
};

export const TOLSTOY_CUSTOM_FONT_FAMILY = "tolstoy-custom-font-family";
export const TOLSTOY_CUSTOM_HEADING_FONT_FAMILY =
  "tolstoy-custom-heading-font-family";