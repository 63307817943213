import React, { useState } from 'react';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import ExpanderHeader from './ExpanderHeader';

function Expander({
  icon,
  title,
  subtitle,
  children,
  isDefaultExpanded,
  showHeaderBottomBorder,
  headerStyle,
  contentContainerStyle,
  isExpanded: parentIsExpanded,
  setExpanded: parentSetExpanded,
  renderHeaderLeftContainer,
  renderHeaderRightContainer,
  showShadow,
  ...props
}) {
  const [isExpanded, setExpanded] = useState(isDefaultExpanded);
  const [isTransitioning, setIsTransitioning] = useState(isDefaultExpanded);

  const handleClick = e => {
    setIsTransitioning(true);
    if (parentSetExpanded) {
      parentSetExpanded(!parentIsExpanded);
    } else {
      setExpanded(!isExpanded);
    }

    setTimeout(() => {
      setIsTransitioning(false);
    }, 300);
  };

  const expanded = parentSetExpanded ? parentIsExpanded : isExpanded;

  return (
    <LayoutRoot {...props}>
      <ExpanderHeader
        onClick={handleClick}
        isExpanded={expanded}
        icon={icon}
        showShadow={showShadow}
        title={title}
        showHeaderBottomBorder={showHeaderBottomBorder}
        subtitle={subtitle}
        headerStyle={headerStyle}
        renderHeaderLeftContainer={renderHeaderLeftContainer}
        renderHeaderRightContainer={renderHeaderRightContainer}
      />
      <ContentContainer expanded={expanded} containerStyle={contentContainerStyle}>
        {(!!expanded || !!isTransitioning) && children}
      </ContentContainer>
    </LayoutRoot>
  );
}

export default Expander;

const LayoutRoot = styled(VerticalFlex)`
  background: ${({ theme }) => theme.colors.white};
  pointer-events: ${({ isLoading }) => (isLoading ? 'none' : '')};
  opacity: ${({ isLoading }) => (isLoading ? 0.4 : 1.0)};
  border-radius: 16px;
  width: 100%;
`;

const ContentContainer = styled.div`
  ${({ containerStyle }) => containerStyle}
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
  max-height: ${({ expanded }) => (expanded ? '3000px' : '0px')};
`;
