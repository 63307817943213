import React from 'react';
import HomePageIcon from 'app/src/images/sidebar-v2/onsite/HomePageIcon';
import { ONSITE_TARGET_PAGES } from 'app/src/pages/dashboard/constants/onsiteTargetPages.constants';
import OtherPagesIcon from 'app/src/images/sidebar-v2/onsite/OtherPagesIcon';
import TagIcon from 'app/src/images/sidebar-v2/onsite/TagIcon';
import CollectionsIcon from 'app/src/images/sidebar-v2/onsite/CollectionsIcon';

type Props = {
  route: ONSITE_TARGET_PAGES;
};

const OnSiteImages = ({ route }: Props) => {
  const ICONS = {
    [ONSITE_TARGET_PAGES.HomePages]: <HomePageIcon />,
    [ONSITE_TARGET_PAGES.ProductPages]: <TagIcon />,
    [ONSITE_TARGET_PAGES.Collections]: <CollectionsIcon />,
    [ONSITE_TARGET_PAGES.OtherPages]: <OtherPagesIcon />,
  };

  return ICONS[route] || null;
};

export default OnSiteImages;
