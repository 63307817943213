import { EQUALS_KEY, NOT_EQUALS_KEY } from 'app/src/constants/ruleGroups.constants';

export enum MatchConditions {
  matchAll = 'matchAll',
  matchAny = 'matchAny',
}

export enum DynamicRuleTypes {
  vodLabel = 'vodLabel',
  taggedProduct = 'taggedProduct',
  allowNonTaggedVideos = 'allowNonTaggedVideos',
  taggedLabel = 'taggedLabel',
  hideVodAsset = 'hideVodAsset',
  orderFeed = 'orderFeed',
  vodSource = 'vodSource',
  vodType = 'vodType',
  vodProjectId = 'vodProjectId',
  trending = 'trending',
}

export const VIDEO_RULES = [DynamicRuleTypes.hideVodAsset, DynamicRuleTypes.orderFeed];

export const POSITIVE_RULES = [EQUALS_KEY];
export const CONDITION_LABELS = {
  [EQUALS_KEY]: 'Is',
  [NOT_EQUALS_KEY]: 'Is not',
};
