import VideosTopBar from 'app/src/pages/dashboard/pages/videos/components/top-bar/VideosTopBar';
import VideosContent from 'app/src/pages/dashboard/pages/videos/components/videos-content/VideosContent';
import { PageMain } from 'app/src/pages/dashboard/components/page/Page';
import React, { useEffect, useState } from 'react';
import { LibraryPageProps } from 'app/src/pages/dashboard/pages/videos/components/library-pages/library-pages-types';
import { SortDirection } from 'app/src/pages/dashboard/pages/videos/hooks/search/searchRequests';
import { usePaginatedVodAssets } from 'app/src/pages/dashboard/pages/videos/hooks/search/usePaginatedVodAssets';
import { useVideos } from 'app/src/context/VideosStore';
import { useTrendingVideos } from 'app/src/pages/dashboard/pages/videos/hooks/useTrendingVideos';
import useUpdateVideosMultiSelectState from 'app/src/hooks/useUpdateVideosMultiSelectBarState';
import { VideosPageVodAsset } from 'app/src/pages/dashboard/pages/videos/types/videos.types';

export type VodAssetsLibraryPageProps = LibraryPageProps;

export const VodAssetsLibraryPage = React.forwardRef(
  (
    {
      videosFilters,
      setVideosFilters,
      onVideoChoose,
      selectedVideos,
      setSelectedVideos,
      toggleSelected,
    }: VodAssetsLibraryPageProps,
    containerRef
  ) => {
    const method = null;
    const [dateSortDirection, setDateSortDirection] = useState(SortDirection.desc);
    const isTrendingPlaylist = !!videosFilters.trending;

    const { trendingVideos } = useTrendingVideos();
    const { videos, isLoading, loadMore, isFetchingNextPage, refetch } = usePaginatedVodAssets({
      videosFilters,
      dateSortDirection,
      enabled: !isTrendingPlaylist,
    });
    const initialized = !isLoading;

    const [data] = useVideos();
    useEffect(() => {
      // We need refetch here to get new video assets after video was updated
      refetch();
    }, [data]);

    // Vod asset does not support any actions.
    // However, we need to pass empty function to avoid null access errors.
    const onSelectedVideosAction = async (_: VideosPageVodAsset[] | null) => {
      // do nothing.
    };

    useUpdateVideosMultiSelectState({
      videos: videos,
      onSelectedVideosAction: onSelectedVideosAction,
      selectedVideos: selectedVideos,
      setSelectedVideos: setSelectedVideos,
      shouldShowDelete: true,
      disableDownload: false,
    });

    return (
      <PageMain>
        <VideosTopBar
          isModal={!!onVideoChoose}
          videosFilters={videosFilters}
          setVideosFilters={setVideosFilters}
          canSortDate={true}
          dateSortDirection={dateSortDirection}
          setDateSortDirection={setDateSortDirection}
          onSelectedVideosAction={onSelectedVideosAction}
        />
        <VideosContent
          ref={containerRef}
          isTrendingPlaylist={isTrendingPlaylist}
          videos={videosFilters.trending ? trendingVideos : videos}
          selectedVideos={selectedVideos}
          toggleSelected={toggleSelected}
          initialized={initialized}
          method={method}
          videosFilters={videosFilters}
          onVideoChoose={onVideoChoose}
          isLoading={isFetchingNextPage}
          onScrollEndCallback={loadMore}
          onSelectedVideosAction={onSelectedVideosAction}
        />
      </PageMain>
    );
  }
);
VodAssetsLibraryPage.displayName = 'VodAssetsLibraryPage';
