import React from 'react';

const WebsiteIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 3.25C0 1.883 1.094.75 2.5.75h15c1.367 0 2.5 1.133 2.5 2.5v12.5c0 1.406-1.133 2.5-2.5 2.5h-15a2.468 2.468 0 0 1-2.5-2.5V3.25ZM6.25 4.5h12.5V3.25c0-.664-.586-1.25-1.25-1.25H6.25v2.5ZM5 2H2.5c-.703 0-1.25.586-1.25 1.25V4.5H5V2ZM1.25 5.75v10c0 .703.547 1.25 1.25 1.25h15c.664 0 1.25-.547 1.25-1.25v-10H1.25Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default WebsiteIcon;
