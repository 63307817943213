import styled, { useTheme } from 'styled-components';
import React from 'react';
import Utils from '../../utils';
import AnalyticsMetricBox from './AnalyticsMetricBox';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import Gap16HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap16HorizontalFlexWrap';
import { TextH3 } from 'shared/react/components/basic/text/TextV2';

const AnalyticsMetrics = ({
  data,
  metrics,
  isUnique,
  size,
  loading,
  header,
  shouldFormatWithLetter,
  ...props
}) => {
  const theme = useTheme();
  loading = loading || Utils.isNullOrUndefined(data);
  return (
    <LayoutRoot {...props}>
      <Header header={header} />
      <MetricsContainer>
        {metrics.map(
          ({
            id,
            color,
            subtitle,
            mainTitle,
            footerPercentageFromId,
            footerPercentageId,
            footerText,
            footerValueId,
            nonUnique,
            formatted,
            isPercentage,
            tooltipText,
            titleTooltipText,
            headerTooltipText,
            noDataTitleTooltipText,
            defaultValue,
            benchmark,
          }) => {
            const value = data?.[id] || 0;
            const footerPercentageFrom = data?.[footerPercentageFromId];
            const footerValue = data?.[footerValueId];
            const currency = data?.currency;
            const unique = isUnique && !nonUnique;
            const title = Utils.formatNumber({
              value,
              currency,
              formatted,
              digits: 1,
              isPercentage,
              defaultValue,
              letter: shouldFormatWithLetter,
            });
            let benchmarkValue, benchmarkTitle;
            const titleTooltip = (!value && noDataTitleTooltipText) || titleTooltipText;

            if (data?.benchmarkData?.[benchmark]) {
              benchmarkValue = (value - data?.benchmarkData?.[benchmark] * 100).toFixed(0);
              benchmarkTitle = Utils.formatNumber({
                value: Math.abs(benchmarkValue),
                currency,
                formatted,
                digits: 1,
                isPercentage,
              });
            }

            return (
              <AnalyticsMetricBox
                size={size}
                key={id}
                color={theme.colors[color]}
                loading={loading}
                title={title}
                mainTitle={mainTitle}
                subtitle={unique ? `Unique ${subtitle}` : subtitle}
                footerPercentage={
                  data?.[footerPercentageId] || (100 * value) / footerPercentageFrom
                }
                footerText={footerText}
                footerValue={formatted ? footerValue : +footerValue}
                tooltipText={tooltipText}
                titleTooltipText={titleTooltip}
                headerTooltipText={headerTooltipText}
                benchmarkTitle={benchmarkTitle}
                benchmarkValue={benchmarkValue}
                category={data?.benchmarkData?.category}
              />
            );
          }
        )}
      </MetricsContainer>
    </LayoutRoot>
  );
};

const Header = ({ header }) => {
  if (!header) {
    return null;
  }

  return <TextH3>{header}</TextH3>;
};

const LayoutRoot = styled(Gap8VerticalFlex)``;

const MetricsContainer = styled(Gap16HorizontalFlexWrap)``;

export default AnalyticsMetrics;
