import React, { useRef } from 'react';
import styled from 'styled-components';
import { INSTAGRAM_GRAPH } from 'src/constants/intergrations.constants';
import { PagePanel } from 'app/src/pages/dashboard/components/page/Page';
import VideoInboxSideBar from 'app/src/pages/dashboard/pages/videos/components/side-bar/VideoInboxSideBar';
import useNavigation from 'app/src/hooks/useNavigation';
import { ImageSupportBanner } from 'src/pages/dashboard/components/banners';
import { ComparatorEnum, WhereCondition } from 'app/src/pages/dashboard/utils/filterEntities';
import { CONFIRM_MODAL_KEY, ADD_EDIT_PLAYLIST_MODAL_KEY } from 'app/src/constants/modals.constants';
import { MODAL_STATE } from 'app/src/pages/modals/folder_modal/folders.constants';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { useVodLabelsActions } from 'src/context/VodLabelsStore';
import { CREATE_CUSTOM_PLAYLIST_BUTTON_DATA_TEST_ID } from 'src/constants/dataTestIds.constants';
import { PlaylistItem } from 'src/pages/dashboard/types/playlist.types';
import { useVodConnectionActions } from 'src/context/VodConnectionStore';
import { useSidebarLocalSettings } from 'app/src/pages/dashboard/hooks/sidebar';
import {
  SidebarItem,
  SidebarExpander,
  SidebarPlaylistItems,
} from 'src/pages/dashboard/components/sidebar';
import {
  VIDEOS_SIDEBAR_PLAYLIST_GROUPS,
  VIDEOS_SIDEBAR_DEFAULT_SETTINGS,
  VIDEOS_SIDEBAR_LOCAL_STORAGE_SETTINGS_KEY as LOCAL_SETTINGS_KEY,
} from 'src/pages/dashboard/constants/sidebar.constants';
import useIntegrationsVideos from 'app/src/hooks/useIntegrationsVideos';
import { ASSET_TYPE } from 'shared/react/services/assets';
import { getTypeFilterValue, isOnlyTypeFilterSelected } from 'src/utils/filters.utils';
import useCustomPlaylists from '../../../../hooks/useCustomPlaylists';
import {
  getAllImagesPlaylist,
  getAllVideosPlaylist,
  getGalleriesPlaylist,
  getTrendingVideosPlaylist,
} from '../../utils/filters';

export type AssetCounts = {
  videosCount: number;
  imagesCount: number;
  galleriesCount: number;
  trendingVideosCount: number;
};

export type VideosSidebarProps = {
  unselectAll: () => void;
  videosFilters: any;
  setVideosFilters: (filters: WhereCondition) => void;
  shouldSidebarRedirect: boolean;
  hideSidebar?: boolean;
  assetCounts: AssetCounts;
};

const VideosSidebar = ({
  unselectAll,
  videosFilters,
  setVideosFilters,
  shouldSidebarRedirect,
  hideSidebar,
  assetCounts,
}: VideosSidebarProps) => {
  const { folder, ...filters } = videosFilters;
  const { videosCount, imagesCount, galleriesCount, trendingVideosCount } = assetCounts;

  const sidebarItemRef = useRef(null);

  const customPlaylists = useCustomPlaylists();
  const { getHasVodLabelByVodAssetId } = useVodConnectionActions();
  const { deleteVodLabel } = useVodLabelsActions();
  const [, { setCurrentModal, setCurrentModalType, clearModalState }] = useModal();
  const { navigateToDashboardLibrary, locationState } = useNavigation();
  const { settings, setSettings } = useSidebarLocalSettings(
    LOCAL_SETTINGS_KEY,
    VIDEOS_SIDEBAR_DEFAULT_SETTINGS
  );
  const { tiktokApps, instagramApps } = useIntegrationsVideos();

  const getIsPlaylistExpanded = playlistGroup => settings[playlistGroup] || false;
  const setExpanded = (groupKey: string) =>
    setSettings({ ...settings, [groupKey]: !settings[groupKey] });

  const onSetVideoFilters = filters => {
    if (shouldSidebarRedirect) {
      navigateToDashboardLibrary('', locationState);
    }

    unselectAll();
    setVideosFilters({ app: null, uploadType: null, ...filters });
  };

  const selectAllVideosPlaylist = () =>
    onSetVideoFilters({
      folder: null,
      labels: null,
      trending: null,
      type: getTypeFilterValue(ASSET_TYPE.VIDEO),
    });
  const selectAllImagesPlaylist = e => {
    sidebarItemRef.current = e.target;
    onSetVideoFilters({
      folder: null,
      labels: null,
      trending: null,
      type: getTypeFilterValue(ASSET_TYPE.IMAGE),
    });
  };

  const selectGalleriesPlaylist = e => {
    sidebarItemRef.current = e.target;
    onSetVideoFilters({
      folder: null,
      labels: null,
      trending: null,
      type: getTypeFilterValue(ASSET_TYPE.GALLERY),
    });
  };

  const getIsAllVideosPlaylistSelected = () =>
    isOnlyTypeFilterSelected(videosFilters, ASSET_TYPE.VIDEO);
  const getIsAllImagesPlaylistSelected = () =>
    isOnlyTypeFilterSelected(videosFilters, ASSET_TYPE.IMAGE);
  const getIsGalleriesPlaylistSelected = () =>
    isOnlyTypeFilterSelected(videosFilters, ASSET_TYPE.GALLERY);

  const selectTrendingVideosPlaylist = () => {
    if (!getIsTrendingVideosPlaylistActive()) {
      return;
    }
    return onSetVideoFilters({
      folder: null,
      labels: null,
      type: null,
      trending: { [ComparatorEnum.in]: true },
    });
  };
  const getIsTrendingVideosPlaylistSelected = () =>
    !videosFilters.folder && !videosFilters.labels && videosFilters.trending;
  const getIsTrendingVideosPlaylistActive = () => tiktokApps.length > 0 || instagramApps.length > 0;

  const currentFilters = { ...filters };
  delete currentFilters.uploadType;

  const onCustomPlaylistClick = (playlist: PlaylistItem) => {
    const newFilters = {
      ...videosFilters,
      type: null,
      trending: null,
      labels: {
        satisfies: {
          fn: getHasVodLabelByVodAssetId,
          args: [playlist.id],
        },
      },
      uploadType: null,
    };

    unselectAll();
    setVideosFilters(newFilters);
  };

  const getSelectedLabel = () => {
    const selectedLabelIds = videosFilters.labels?.satisfies?.args || [];

    return selectedLabelIds.length === 1 ? selectedLabelIds[0] : null;
  };

  const onAddPlaylistClick = () => {
    setCurrentModal(ADD_EDIT_PLAYLIST_MODAL_KEY);
    setCurrentModalType(MODAL_STATE.new);
  };

  const onEditPlaylist = (playlist: PlaylistItem) => {
    if (!playlist) {
      return;
    }

    setCurrentModal(ADD_EDIT_PLAYLIST_MODAL_KEY, { playlist });
    setCurrentModalType(MODAL_STATE.rename);
  };

  const deletePlaylist = async ({ id }: PlaylistItem) => {
    clearModalState();
    await deleteVodLabel(id);
  };

  const onDeletePlaylist = (playlist: PlaylistItem) => {
    if (!playlist) {
      return;
    }

    setCurrentModal(CONFIRM_MODAL_KEY, { actionButtonClicked: () => deletePlaylist(playlist) });
  };

  const onSelectSource = (e, source) => {
    const isInstagram = source === INSTAGRAM_GRAPH;

    if (!isInstagram || !e?.target) {
      return;
    }

    sidebarItemRef.current = e.target;
  };

  const allVideosPlaylist = getAllVideosPlaylist(videosCount);
  const allImagesPlaylist = getAllImagesPlaylist(imagesCount);
  const galleriesPlaylist = getGalleriesPlaylist(galleriesCount);
  const trendingPlaylist = getTrendingVideosPlaylist(trendingVideosCount);

  const data = [
    {
      title: VIDEOS_SIDEBAR_PLAYLIST_GROUPS.SOURCES,
      overflowHidden: false,
      content: (
        <VideoInboxSideBar
          shouldSidebarRedirect={shouldSidebarRedirect}
          unselectAll={unselectAll}
          videosFilters={videosFilters}
          setVideosFilters={setVideosFilters}
        />
      ),
    },
    {
      title: VIDEOS_SIDEBAR_PLAYLIST_GROUPS.CUSTOM_PLAYLISTS,
      onAddButtonClick: onAddPlaylistClick,
      overflowHidden: true,
      dataTestId: CREATE_CUSTOM_PLAYLIST_BUTTON_DATA_TEST_ID,
      content: (
        <SidebarPlaylistItems
          playlists={customPlaylists}
          selectedPlaylistId={getSelectedLabel()}
          onClick={onCustomPlaylistClick}
          onEdit={onEditPlaylist}
          onDelete={onDeletePlaylist}
          withEditMenu
        />
      ),
    },
  ];

  if (hideSidebar) {
    return null;
  }

  return (
    <LayoutRoot>
      <SidebarItem
        name={allVideosPlaylist.name}
        count={allVideosPlaylist.count}
        isSelected={getIsAllVideosPlaylistSelected()}
        onClick={selectAllVideosPlaylist}
        isTopLevel
      />
      <SidebarItem
        name={allImagesPlaylist.name}
        count={allImagesPlaylist.count}
        isSelected={getIsAllImagesPlaylistSelected()}
        onClick={selectAllImagesPlaylist}
        isTopLevel
      />
      <SidebarItem
        name={galleriesPlaylist.name}
        count={galleriesPlaylist.count}
        isSelected={getIsGalleriesPlaylistSelected()}
        onClick={selectGalleriesPlaylist}
        isTopLevel
      />
      <SidebarExpander
        title={VIDEOS_SIDEBAR_PLAYLIST_GROUPS.SOURCES}
        isExpanded={getIsPlaylistExpanded(VIDEOS_SIDEBAR_PLAYLIST_GROUPS.SOURCES)}
        setExpanded={() => setExpanded(VIDEOS_SIDEBAR_PLAYLIST_GROUPS.SOURCES)}
      >
        <VideoInboxSideBar
          shouldSidebarRedirect={shouldSidebarRedirect}
          unselectAll={unselectAll}
          videosFilters={videosFilters}
          setVideosFilters={setVideosFilters}
          onSelectSource={onSelectSource}
        />
      </SidebarExpander>
      <SidebarItem
        name={trendingPlaylist.name}
        count={trendingPlaylist.count}
        tooltipText={trendingPlaylist.tooltip}
        isSelected={getIsTrendingVideosPlaylistSelected()}
        onClick={selectTrendingVideosPlaylist}
        isDisabled={!getIsTrendingVideosPlaylistActive()}
        isTopLevel
      />
      <SidebarExpander
        title={VIDEOS_SIDEBAR_PLAYLIST_GROUPS.CUSTOM_PLAYLISTS}
        titleDataTestId={CREATE_CUSTOM_PLAYLIST_BUTTON_DATA_TEST_ID}
        isExpanded={getIsPlaylistExpanded(VIDEOS_SIDEBAR_PLAYLIST_GROUPS.CUSTOM_PLAYLISTS)}
        withPlustButton={getIsPlaylistExpanded(VIDEOS_SIDEBAR_PLAYLIST_GROUPS.CUSTOM_PLAYLISTS)}
        onPlusClick={onAddPlaylistClick}
        setExpanded={() => setExpanded(VIDEOS_SIDEBAR_PLAYLIST_GROUPS.CUSTOM_PLAYLISTS)}
        overflowHidden
      >
        <SidebarPlaylistItems
          playlists={customPlaylists}
          selectedPlaylistId={getSelectedLabel()}
          onClick={onCustomPlaylistClick}
          onEdit={onEditPlaylist}
          onDelete={onDeletePlaylist}
          withEditMenu
        />
      </SidebarExpander>
      <ImageSupportBanner anchorRef={sidebarItemRef} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(PagePanel)`
  gap: 4px;
`;

export default VideosSidebar;
