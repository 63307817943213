import { REACT_APP_API_BASE_URL } from 'player/src/config/player.config';
import HttpService from 'shared/react/services/httpService';

const PROJECTS_BASE_URL = `${REACT_APP_API_BASE_URL}/projects`;

export const createProject = async ({ project, steps, token }) => {
  const projectData = await HttpService.post(
    `${PROJECTS_BASE_URL}/project-with-steps`,
    {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
    JSON.stringify({
      project,
      steps,
    })
  );

  const newProject = projectData.data;
  return newProject;
};

export const publishProject = async ({ projectId, token }) => {
  return HttpService.post(`${PROJECTS_BASE_URL}/${projectId}/publish`, {
    authorization: `Bearer ${token}`,
  });
};
