import React from 'react'


const TolyLogoOpen = () => {
  return (
    <svg width="51" height="52" viewBox="0 0 51 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.5" width="51" height="51" rx="25.5" fill="#ED2455"/>
        <path d="M24.0919 8.47581C17.3425 8.94334 12.9157 15.5817 13.8955 22.2759C15.1527 30.8653 13.1173 37.075 12.8595 41.7244C12.8431 42.0184 13.0926 42.2133 13.3112 42.016C14.111 41.2942 15.1556 39.292 17.0075 39.1637C19.1131 39.0179 20.575 39.8195 21.9816 42.2613C22.1608 42.5724 22.4628 42.5511 22.6135 42.2252C23.1747 41.0112 24.5437 38.6417 26.1737 38.5288C28.6246 38.359 29.8877 42.4064 30.5734 42.3589C31.2591 42.3114 32.8022 38.0696 35.0891 37.9112C38.3931 37.6823 39.6764 41.7283 40.296 41.6228C40.9156 41.5172 36.5963 27.1092 36.1288 20.3599C35.6612 13.6106 30.8412 8.00827 24.0919 8.47581Z" fill="url(#paint0_linear_6_13)"/>
        <path d="M21.9566 18.8308L21.9664 18.8301C23.2965 18.738 24.4507 19.753 24.5439 21.0974C24.637 22.4418 23.6341 23.6062 22.3036 23.6984C21.023 23.7871 19.9061 22.85 19.741 21.5811L19.7365 21.5814L19.6921 20.9403C19.6778 20.7336 19.6894 20.5311 19.7242 20.336C19.8925 19.1137 20.6997 18.0515 21.8705 17.5887L21.9566 18.8308Z" fill="#ED2455"/>
        <path d="M27.8845 22.0637L27.8747 22.0644C26.5442 22.1565 25.3903 21.1413 25.2972 19.7971C25.2041 18.4527 26.207 17.2883 27.5375 17.1961C28.8178 17.1075 29.9349 18.0445 30.1001 19.3134L30.1045 19.3131L30.149 19.9542C30.1633 20.161 30.1513 20.3635 30.1165 20.5582C29.9482 21.7809 29.1414 22.8431 27.9705 23.3058L27.8845 22.0637Z" fill="#ED2455"/>
        <defs>
        <linearGradient id="paint0_linear_6_13" x1="25.4343" y1="27.8087" x2="32.8093" y2="64.3087" gradientUnits="userSpaceOnUse">
        <stop stopColor="white"/>
        <stop offset="1" stopColor="#FEBACE" stopOpacity="0"/>
        </linearGradient>
        </defs>
    </svg>
  )
}

export default TolyLogoOpen;