import IosSwitch from 'app/src/basic_components/IosSwitch';
import React from 'react';
import PublishPricingTooltip from './PublishPricingTooltip';
import { useTheme } from 'styled-components';
import { useBilling } from 'app/src/hooks/useBillings';
import { PUBLISH_METHODS } from 'shared/react/constants/tolstoy.constants';
import { publishMethodOptions } from 'app/src/types/entities';

const bubbleWidgetType = PUBLISH_METHODS[publishMethodOptions.bubble].widgetType;

const SetLiveTogglePricing = ({
  disabled: disabledInput,
  checked,
  onChange,
  loading,
  widgetType = bubbleWidgetType,
}) => {
  const theme = useTheme();
  const { hasReachedLiveWidgetsLimit } = useBilling();

  let disabled = disabledInput;

  if (widgetType === bubbleWidgetType) {
    disabled = disabled || (!checked && hasReachedLiveWidgetsLimit);
  }

  return (
    <PublishPricingTooltip
      header={widgetType}
      location={`${widgetType} Set Live`}
      hide={loading || checked || !hasReachedLiveWidgetsLimit}
    >
      <IosSwitch
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        activeColor={theme.colors.success}
      />
    </PublishPricingTooltip>
  );
};

export default SetLiveTogglePricing;
