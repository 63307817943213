import { useApps } from 'app/src/context/AppsStore';
import { useUser } from 'app/src/context/userStore/UserStore';
import useGetSearchProducts from 'app/src/hooks/useGetSearchProducts';
import { useRef, useState } from 'react';

const useProductFilterActions = () => {
  const [{ selectedApp, selectedAppUrl }] = useApps();
  const [{ account }] = useUser();
  const timeoutRef = useRef(null);
  const [productsValue, setProductsValue] = useState('');
  const [searchProducts, setSearchProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const getSearchProducts = useGetSearchProducts(selectedApp);

  const search = async value => {
    setIsProductsLoading(true);
    const body = {
      term: value,
      appKey: account.appKey,
      appUrl: selectedAppUrl,
      hasVodConnection: false,
    };
    const { searchProducts: products } = await getSearchProducts(body);
    setSearchProducts(products);
    setIsProductsLoading(false);
  };

  const onSearchProduct = e => {
    clearTimeout(timeoutRef.current);
    const value = e.target.value;
    setProductsValue(value);
    if (!value) {
      return;
    }

    timeoutRef.current = setTimeout(() => {
      search(value);
    }, 300);
  };

  const resetSearch = () => {
    setProductsValue('');
    setSearchProducts([]);
    setSelectedProducts([]);
  };

  return {
    productsValue,
    onSearchProduct,
    searchProducts,
    setSelectedProducts,
    selectedProducts,
    isProductsLoading,
    resetSearch,
  };
};

export default useProductFilterActions;
