export enum UgcRequestStep {
  Request = 'request',
  Confirm = 'confirm',
  Pending = 'pending',
  Done = 'done',
}

export enum LoadState {
  LoadingUsername = 'loadingUsername',
  Importing = 'importing',
}
