import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import TimeBar from 'app/src/pages/project/pages/look_and_feel/editor/editors/gif_settings_editor/TimeBar';
import { THUMBNAIL_EDITOR_MINIMUM_TIME } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import PlayIcon from 'app/src/images/dashboard_v2/PlayIcon';
import PauseVideoIcon from 'app/src/images/video_icon/PauseVideoIcon';

type Props = {
  videoSrc: string;
  duration: number;
  startTime: number;
  onStartTimeChange: (startTime: number) => void;
};

const END_TIME_DIFFERENCE = 3;

const SelectPart = ({ videoSrc, duration, startTime, onStartTimeChange }: Props) => {
  const [currTime, setCurrTime] = useState(0);
  const [isPaused, setIsPaused] = useState(true);
  const endTime = startTime + END_TIME_DIFFERENCE;
  const [selectedPosition, setSelectedPosition] = useState(null);
  const timeBarRef = useRef<HTMLDivElement>();
  const videoRef = useRef<HTMLVideoElement>();
  const intervalRef = useRef(null);

  const onPlay = time => {
    const isStartOrFinish = startTime === currTime || endTime === currTime;
    clearInterval(intervalRef.current);
    setIsPaused(false);

    videoRef.current.currentTime = startTime;
    videoRef.current.play();

    time = time || isStartOrFinish ? startTime : currTime;

    intervalRef.current = setInterval(() => {
      if (!videoRef?.current) {
        clearInterval(intervalRef.current);
        return;
      }

      if (time >= endTime) {
        videoRef.current.pause();
        setCurrTime(endTime);
        clearInterval(intervalRef.current);
        onPlay(startTime);
        return;
      }

      time += 0.1;
      setCurrTime(time);
    }, 100);
  };

  const onMouseMove = ev => {
    const offsetOfSelectedPosition =
      (ev.pageX || ev?.changedTouches?.[0]?.clientX) - selectedPosition;

    const shouldExit =
      selectedPosition === null ||
      offsetOfSelectedPosition === 0 ||
      duration <= THUMBNAIL_EDITOR_MINIMUM_TIME;

    if (shouldExit) {
      return;
    }

    const percentageUsed = offsetOfSelectedPosition / timeBarRef.current?.clientWidth;
    let time = percentageUsed * duration;
    time = time + startTime;

    if (time <= 0) {
      time = 0;
    }

    if (time > duration - END_TIME_DIFFERENCE) {
      time = duration - END_TIME_DIFFERENCE;
    }

    setSelectedPosition(ev.pageX);
    videoRef.current.currentTime = time;
    if (!isPaused) {
      onPlay(time);
    }

    onStartTimeChange(time);
  };

  const pauseVideo = () => {
    clearInterval(intervalRef.current);
    setIsPaused(true);
    setCurrTime(0);
    videoRef.current.pause();
  };

  useEffect(() => {
    videoRef.current.currentTime = startTime || 0;
  }, [startTime]);

  return (
    <LayoutRoot onMouseMove={onMouseMove}>
      <Video ref={videoRef} src={videoSrc} muted={true} />
      <TimeBarContainer>
        <TimeBar
          endTime={endTime}
          startTime={startTime}
          duration={duration}
          timeBarRef={timeBarRef}
          setSelectedPosition={setSelectedPosition}
          currTime={currTime}
          showTimes={true}
        />

        {isPaused ? <PlayIcon onClick={onPlay} /> : <PauseVideoIcon onClick={pauseVideo} />}
      </TimeBarContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)`
  padding: 16px;
  text-align: center;
  flex-grow: 1;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.neutralLightest};
  align-items: center;
  gap: 37px;
  justify-content: space-between;
`;

const Video = styled.video`
  height: 264px;
  border-radius: 8px;
  width: 100%;
`;

const TimeBarContainer = styled(HorizontalFlex)`
  gap: 24px;
  width: 100%;
  align-items: center;
  position: relative;
  & path {
    fill: ${({ theme }) => theme.colors.neutralDarker};
  }

  & svg {
    width: 12px;
    height: 12px;
    cursor: pointer;
  }
`;

export default SelectPart;
