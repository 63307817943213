import React from 'react';

const Page = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7C14 8.85652 13.2625 10.637 11.9497 11.9497C10.637 13.2625 8.85652 14 7 14C5.14348 14 3.36301 13.2625 2.05025 11.9497C0.737498 10.637 0 8.85652 0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0ZM12.2705 4.30769C11.5798 2.96478 10.4045 1.9346 8.98262 1.42585C9.46077 2.33046 9.80646 3.29969 10.0089 4.30769H12.2705ZM12.8972 7.53308C12.9145 7.35591 12.9231 7.17801 12.9231 7C12.922 6.4535 12.8448 5.90981 12.6937 5.38462H10.1715C10.206 5.74108 10.2308 6.09969 10.2308 6.46154C10.2285 7.18507 10.1542 7.90659 10.0089 8.61539H12.6937C12.796 8.26108 12.8638 7.89923 12.8983 7.53308H12.8972ZM9.09031 7.53308L9.09677 7.47815C9.13352 7.14051 9.15257 6.80117 9.15385 6.46154C9.14662 6.10132 9.11929 5.74179 9.072 5.38462H4.928C4.88032 5.74175 4.85299 6.1013 4.84615 6.46154C4.84879 7.18662 4.93296 7.90911 5.09708 8.61539H8.90292C8.98585 8.25785 9.04831 7.89708 9.09031 7.53308ZM8.88354 4.30769C8.62982 3.16869 8.17192 2.08505 7.532 1.10923C7.35539 1.09415 7.17877 1.07692 7 1.07692C6.88782 1.07747 6.77572 1.08286 6.664 1.09308L6.468 1.10923L6.46154 1.12C5.825 2.09296 5.36933 3.17284 5.11646 4.30769H8.88354ZM3.99108 4.30769C4.19765 3.30039 4.548 2.32803 5.03138 1.42046C3.60337 1.92748 2.4225 2.96006 1.72954 4.30769H3.99108ZM1.30631 5.38462C1.15542 5.90986 1.07822 6.45352 1.07692 7C1.07822 7.54649 1.15542 8.09014 1.30631 8.61539H3.99108C3.84507 7.90669 3.77075 7.18511 3.76923 6.46154C3.76923 6.09969 3.794 5.74108 3.82846 5.38462H1.30631ZM4.27215 9.69231H1.73062C2.13771 10.4875 2.71893 11.1806 3.43101 11.72C4.14309 12.2594 4.96768 12.6313 5.84339 12.8078C5.15414 11.8614 4.62346 10.8092 4.27215 9.69231ZM7 12.5397C7.68863 11.688 8.22692 10.725 8.59169 9.69231H5.40831C5.7731 10.725 6.31139 11.688 7 12.5397ZM9.72785 9.69231C9.37652 10.8092 8.84585 11.8614 8.15662 12.8078C9.03226 12.6311 9.85679 12.2592 10.5688 11.7198C11.2809 11.1804 11.8622 10.4874 12.2694 9.69231H9.72785Z"
        fill="#2F3137"
      />
    </svg>
  );
};

export default Page;
