import React from 'react';
import useNavigation from 'app/src/hooks/useNavigation';
import { PagePanel } from 'app/src/pages/dashboard/components/page/Page';
import useActions from 'app/src/pages/dashboard/components/filters/hooks/useActions';
import { WhereCondition, ComparatorEnum } from 'app/src/pages/dashboard/utils/filterEntities';
import {
  SidebarItem,
  SidebarExpander,
  SidebarFolderItems,
  SidebarPlaylistItems,
} from 'src/pages/dashboard/components/sidebar';
import { PlaylistItem } from 'src/pages/dashboard/types/playlist.types';
import { useSidebarFolderItems } from 'src/pages/dashboard/hooks/sidebar';
import { useSidebarLocalSettings } from 'app/src/pages/dashboard/hooks/sidebar';
import {
  ONSITE_SIDEBAR_PLAYLIST_GROUPS,
  ONSITE_SIDEBAR_DEFAULT_SETTINGS,
  ONSITE_SIDEBAR_LOCAL_STORAGE_SETTINGS_KEY as LOCAL_SETTINGS_KEY,
} from 'app/src/pages/dashboard/constants/sidebar.constants';
import { ARCHIVE_FOLDER } from 'src/constants/folders';
import Layouts from './Layouts';

const DEFAULT_SIDEBAR_FILTERS = {
  layout: null,
  playlists: null,
  folder: {
    neq: ARCHIVE_FOLDER.id,
  },
};

interface Props {
  filters: WhereCondition;
  setFilters: (filters: WhereCondition, forceReplace?: boolean) => void;
}

// FIXME: optimize renderring
const Sidebar: React.FC<Props> = ({ filters, setFilters }) => {
  const { navigateToDashboardV2Onsite } = useNavigation();
  const { playlists, isPlaylistUsedInProject, checkProjectPublishMethod } = useActions(setFilters);
  const { settings, setSettings } = useSidebarLocalSettings(
    LOCAL_SETTINGS_KEY,
    ONSITE_SIDEBAR_DEFAULT_SETTINGS
  );
  const {
    folders,
    totalProjects,
    foldersMapping,
    selectedFolderId,
    createNewFolder,
    onRename,
    onDelete,
  } = useSidebarFolderItems();

  const setExpanded = React.useCallback(
    (groupKey: string) => {
      setSettings({
        ...settings,
        [groupKey]: !settings[groupKey],
      });
    },
    [settings, setSettings]
  );

  const resetFilters = React.useCallback(() => {
    navigateToDashboardV2Onsite();
    setFilters(DEFAULT_SIDEBAR_FILTERS);
  }, [setFilters]);

  const onLayoutClick = React.useCallback(
    (layout: string) => {
      navigateToDashboardV2Onsite();
      setFilters({
        ...DEFAULT_SIDEBAR_FILTERS,
        layout: {
          [ComparatorEnum.satisfies]: {
            fn: checkProjectPublishMethod,
            args: [layout],
          },
        },
      });
    },
    [setFilters, checkProjectPublishMethod]
  );

  const onPlaylistClick = React.useCallback(
    (playlist: PlaylistItem) => {
      navigateToDashboardV2Onsite();
      setFilters({
        ...DEFAULT_SIDEBAR_FILTERS,
        playlists: {
          [ComparatorEnum.satisfies]: {
            fn: isPlaylistUsedInProject,
            args: [playlist.id],
          },
        },
      });
    },
    [setFilters, isPlaylistUsedInProject]
  );

  const onFolderClick = React.useCallback(
    folder => {
      navigateToDashboardV2Onsite(folder.id);
      setFilters({
        ...DEFAULT_SIDEBAR_FILTERS,
        folder: { [ComparatorEnum.eq]: folder.id },
      });
    },
    [setFilters]
  );

  const onArchiveClick = React.useCallback(() => {
    navigateToDashboardV2Onsite(ARCHIVE_FOLDER.id);
    setFilters({
      ...DEFAULT_SIDEBAR_FILTERS,
      folder: { [ComparatorEnum.eq]: ARCHIVE_FOLDER.id },
    });
  }, [setFilters]);

  const selectedLayout = React.useMemo(
    () => filters.layout?.satisfies?.args?.[0],
    [filters?.layout]
  );
  const selectedPlaylist = React.useMemo(
    () => filters.playlists?.satisfies?.args?.[0],
    [filters?.playlists]
  );
  const isAllSelected = React.useMemo(
    () => !selectedFolderId && !selectedLayout && !selectedPlaylist,
    [selectedFolderId, selectedLayout, selectedPlaylist]
  );

  return (
    <PagePanel>
      <SidebarItem
        name="All"
        count={totalProjects}
        isSelected={isAllSelected}
        onClick={resetFilters}
        isTopLevel
      />
      <SidebarExpander
        title={ONSITE_SIDEBAR_PLAYLIST_GROUPS.LAYOUTS}
        isExpanded={settings[ONSITE_SIDEBAR_PLAYLIST_GROUPS.LAYOUTS]}
        setExpanded={() => setExpanded(ONSITE_SIDEBAR_PLAYLIST_GROUPS.LAYOUTS)}
      >
        <Layouts selectedLayout={selectedLayout} onLayoutClick={onLayoutClick} />
      </SidebarExpander>
      <SidebarExpander
        title={ONSITE_SIDEBAR_PLAYLIST_GROUPS.FOLDERS}
        isExpanded={settings[ONSITE_SIDEBAR_PLAYLIST_GROUPS.FOLDERS]}
        setExpanded={() => setExpanded(ONSITE_SIDEBAR_PLAYLIST_GROUPS.FOLDERS)}
        onPlusClick={createNewFolder}
        withPlustButton
      >
        <SidebarFolderItems
          folders={folders}
          foldersMapping={foldersMapping}
          selectedFolderId={selectedFolderId}
          onClick={onFolderClick}
          onEdit={onRename}
          onDelete={onDelete}
        />
        <SidebarItem name={ARCHIVE_FOLDER.name} onClick={onArchiveClick} />
      </SidebarExpander>
      <SidebarExpander
        title={ONSITE_SIDEBAR_PLAYLIST_GROUPS.PLAYLISTS}
        isExpanded={settings[ONSITE_SIDEBAR_PLAYLIST_GROUPS.PLAYLISTS]}
        setExpanded={() => setExpanded(ONSITE_SIDEBAR_PLAYLIST_GROUPS.PLAYLISTS)}
      >
        <SidebarPlaylistItems
          playlists={playlists}
          selectedPlaylistId={selectedPlaylist}
          onClick={onPlaylistClick}
        />
      </SidebarExpander>
    </PagePanel>
  );
};

export default Sidebar;
