import React from 'react';
import styled from 'styled-components';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import GhostImage from 'app/src/images/ghosts.png';
import { TextSmall, TextSmallBold } from 'shared/react/components/basic/text/TextV2';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

type Props = {
  title: string;
  subTitle: string;
};

export const UgcGhostsEmptyState = ({ title, subTitle }: Props) => {
  return (
    <LayoutRoot>
      <TextSmallBold>{title}</TextSmallBold>
      <TextSmall>{subTitle}</TextSmall>
      <GhostsContainer>
        <Image src={GhostImage} />
      </GhostsContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  gap: 2px;
  height: 100%;
  position: relative;
  padding: 24px;
  align-items: center;
`;

const GhostsContainer = styled(HorizontalFlex)`
  position: absolute;
  top: 100px;
  width: 100%;
  justify-content: center;
`;

const Image = styled.img`
  max-width: 402px;
`;

export default UgcGhostsEmptyState;
