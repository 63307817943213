import React from 'react';

const VideoIcon = () => {
  return (
    <svg width="90" height="60" viewBox="0 0 90 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 2.5c-4.219 0-7.5 3.438-7.5 7.5v40c0 4.219 3.281 7.5 7.5 7.5h40c4.063 0 7.5-3.281 7.5-7.5V10c0-4.063-3.438-7.5-7.5-7.5H10Zm50 40.156V50c0 5.625-4.531 10-10 10H10C4.375 60 0 55.625 0 50V10C0 4.531 4.375 0 10 0h40c5.469 0 10 4.531 10 10v32.657Zm21.094 11.719L65 45.469v-2.813l17.344 9.532c.469.156 1.094.312 1.562.312 2.031 0 3.594-1.563 3.594-3.438V11.094c0-2.031-1.563-3.594-3.594-3.594-.469 0-1.094.156-1.562.469L65 17.5v-2.813l16.094-8.906C82.03 5.313 82.969 5 83.906 5 87.187 5 90 7.813 90 11.094v37.968C90 52.344 87.187 55 83.906 55c-.937 0-1.875-.156-2.812-.625Z"
        fill="#B3B6BC"
      />
    </svg>
  );
};

export default VideoIcon;
