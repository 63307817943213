import TolstoyLoader from 'app/src/basic_components/TolstoyLoader';
import React from 'react';
import { TextH5 } from 'shared/react/components/complex/Text';
import styled from 'styled-components';

type Props = {
  loadingText?: string;
  errorIcon?: React.ReactNode;
};

const LoadingVideo = ({
  loadingText = 'We are preparing your asset.',
  errorIcon,
  ...props
}: Props) => {
  return (
    <LoadingContainer {...props}>
      {errorIcon || <TolstoyLoader />}
      <LoadingText>{loadingText}</LoadingText>
    </LoadingContainer>
  );
};

const LoadingContainer = styled.div`
  display: grid;
  gap: 40px;
  justify-items: center;
`;

const LoadingText = styled(TextH5)``;

export default LoadingVideo;
