import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import useFeedFeatures from 'shared/react/components/complex/context/hooks/useFeedFeatures';
import { FEATURE_WHITE_LABEL } from 'player/src/constants/playerFeatures.constants';
import useFeedAccount from 'shared/react/components/complex/context/hooks/useFeedAccount';
import Utils from 'player/src/utils';
import WaterMark from 'player/src/components/player/video-overlay/WaterMark';

const FeedOverlayLogo = () => {
  const [, { getFeatureSettingsByKey }] = useFeedFeatures();
  const [{ appKey }] = useFeedAccount();
  const { showAccountLogo: whiteLabelShowAccountLogo, hidePoweredBy, semiPoweredBy } =
    getFeatureSettingsByKey(FEATURE_WHITE_LABEL) || {};
  const [accountLogo, setAccountLogo] = useState('');
  const [isImageError, setIsImageError] = useState(false);
  const playerType = Utils.getPlayerType();
  const isTv = Utils.getIsTv(playerType);
  const shouldShowAccountLogo = whiteLabelShowAccountLogo && !isImageError && !isTv && accountLogo;

  useEffect(() => {
    if (!whiteLabelShowAccountLogo || isTv) {
      return;
    }

    const logoUrl = Utils.getAccountLogoUrl(appKey);
    setAccountLogo(logoUrl);
  }, [whiteLabelShowAccountLogo, isTv]);

  return (
    <>
      <WaterMarkContainer>
        <WaterMark hidePoweredBy={hidePoweredBy} semiPoweredBy={semiPoweredBy} />
      </WaterMarkContainer>
      {shouldShowAccountLogo && (
        <AccountLogo
          alt="Company logo"
          moveLeft={!!playerType}
          src={accountLogo}
          onError={() => setIsImageError(true)}
        />
      )}
    </>
  );
};

const AlignToTopLeftCss = css`
  position: absolute;
  top: 0;
  left: ${({ moveLeft }) => (moveLeft ? '50px' : '0')};
  padding: 16px;
  z-index: 3;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    padding: 16px;
    left: 40px;
  }
`;

const WaterMarkContainer = styled.div`
  & button {
    background: transparent;
  }
`;

const AccountLogo = styled.img`
  border: none;
  ${AlignToTopLeftCss};
  max-width: 200px;
  max-height: 70px;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    max-width: 100px;
    max-height: 40px;
  }
`;

export default FeedOverlayLogo;
