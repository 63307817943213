import React from 'react';
import styled from 'styled-components';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import { BolderTextSmall, TextSmall } from 'shared/react/components/complex/Text';

function FlowCondition() {
  return (
    <LayoutRoot>
      <TextSmall>When</TextSmall>
      <Condition>All</Condition>
      <TextSmall>of the rules are true</TextSmall>
    </LayoutRoot>
  );
}

export default FlowCondition;

const LayoutRoot = styled(Gap8HorizontalFlexWrap)`
  align-items: center;
`;

const Condition = styled(BolderTextSmall)`
  padding: 4px 10px;
  border-radius: 8px;
  color: black;
  background: ${({ theme }) => theme.colors.primaryLight};
`;
