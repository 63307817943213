import React from 'react';

const ShopifyLogoIcon = () => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.406 4.602c0 .078 2.11 14.335 2.11 14.335l-4.883 1.25V3.273l1.133 1.133s1.367.04 1.445.04c.117 0 .195.038.195.156ZM11.5 3.273c.352-.117.547-.156.586-.156.039-.039.117-.039.156-.039v17.11L.445 18.155S1.891 7.141 1.93 6.75c.039-.508.078-.508.625-.703 0 0 .742-.234 1.836-.586.117-.82.507-1.875 1.054-2.734C6.187 1.516 7.125.852 8.062.813c.508 0 .899.156 1.211.507 0 .04.04.04.079.078.039 0 .078-.039.117-.039h.039c.703 0 1.328.43 1.719 1.211.117.235.195.47.273.703Zm-2.148.664c0-.507-.04-1.289-.313-1.835a1.77 1.77 0 0 0-.664.507c-.469.47-.82 1.211-1.016 1.954.664-.196 1.368-.43 1.993-.625Zm-1.29-2.46c-1.445.039-2.695 2.304-3.046 3.789.507-.157 1.093-.313 1.64-.508.196-.938.625-1.953 1.25-2.578a3.5 3.5 0 0 1 .742-.586 1.248 1.248 0 0 0-.546-.117h-.04ZM6.266 10.07c-.04-.195 0-.937 1.132-1.015.82-.04 1.485.273 1.485.273l.586-2.265s-.508-.235-1.524-.196c-2.617.196-3.828 2.031-3.71 3.828.156 2.149 2.304 2.07 2.382 3.399a.719.719 0 0 1-.742.781c-.82.078-1.875-.703-1.875-.703l-.39 1.719s1.015 1.093 2.93.976c1.6-.078 2.694-1.367 2.577-3.203-.156-2.383-2.812-2.578-2.851-3.594Zm3.71-6.328c.352-.078.665-.195.938-.273-.156-.469-.508-1.29-1.21-1.406.194.546.273 1.21.273 1.68Z"
        fill="#7D8087"
      />
    </svg>
  );
};

export default ShopifyLogoIcon;
