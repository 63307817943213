import { CUBE_PROPERTY_KEYS } from 'app/src/constants/cube.constants';
import { EMAIL_RECIPIENTS_PROD } from 'app/src/constants/analytics.constants';
import { EmailRecipient } from './types/getEmailRecipients.types';
import { ResultSet } from '@cubejs-client/core';

const getEmailRecipients = (rawEmailRecipients: ResultSet): EmailRecipient[] => {
  return rawEmailRecipients.tablePivot()?.map((data, idx) => {
    return {
      id: idx,
      campaign: data[`${EMAIL_RECIPIENTS_PROD}.${CUBE_PROPERTY_KEYS.campaign}`] || '',
      email: data[`${EMAIL_RECIPIENTS_PROD}.${CUBE_PROPERTY_KEYS.email}`] || '',
      firstSeen: data[`${EMAIL_RECIPIENTS_PROD}.${CUBE_PROPERTY_KEYS.firstSeen}`],
      numOfEmailClicks:
        +data[`${EMAIL_RECIPIENTS_PROD}.${CUBE_PROPERTY_KEYS.numOfEmailClicks}`] || 0,
      numOfEmailOpens: +data[`${EMAIL_RECIPIENTS_PROD}.${CUBE_PROPERTY_KEYS.numOfEmailOpens}`] || 0,
      numOfOrders: +data[`${EMAIL_RECIPIENTS_PROD}.${CUBE_PROPERTY_KEYS.numOfOrders}`] || 0,
    } as EmailRecipient;
  });
};

export default getEmailRecipients;
