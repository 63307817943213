import React from 'react';
import styled, { useTheme } from 'styled-components';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import { CAROUSEL_TILE_NAME_LOCATION_TYPES } from '../../LookAndFeelPage.constants';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';

const EmbedCarouselTileName = ({
  size = 1,
  text = '',
  carouselTileNameEnabled = false,
  carouselTileNameLocation = CAROUSEL_TILE_NAME_LOCATION_TYPES.under,
  carouselTileNameTextSize = 16,
  carouselTileNameTextColor = 'black',
  carouselTileNameBackgroundEnabled = false,
  carouselTileNameBackgroundColor = 'black',
  carouselTileNameBackgroundTransparancy = 50,
  carouselBorderRadius = 0,
}) => {
  const theme = useTheme();
  const { branding } = useDesignSettingsContext();
  const isOver = carouselTileNameLocation === CAROUSEL_TILE_NAME_LOCATION_TYPES.over;

  const getBackgroundStyle = () => {
    if (!isOver || !carouselTileNameBackgroundEnabled) {
      return '';
    }

    return theme.getHexOpacity(
      carouselTileNameBackgroundColor || theme.colors.black,
      carouselTileNameBackgroundTransparancy
    );
  };

  if (!carouselTileNameEnabled || !text) {
    return null;
  }

  return (
    <LayoutRoot
      isOver={isOver}
      backgroundStyle={getBackgroundStyle()}
      borderRadius={carouselBorderRadius / size}
      size={size}
    >
      <TileNameText
        color={carouselTileNameTextColor}
        fontSize={carouselTileNameTextSize / size}
        fontFamily={branding.typography.font.family}
        isOver={isOver}
      >
        {text}
      </TileNameText>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  position: ${({ isOver }) => (isOver ? 'absolute' : '')};
  bottom: ${({ isOver }) => (isOver ? '0' : '')};
  z-index: ${({ isOver }) => (isOver ? '2' : '')};
  background: ${({ backgroundStyle }) => backgroundStyle};
  border-radius: ${({ borderRadius }) => `0 0 ${borderRadius}px ${borderRadius}px`};
  height: ${({ size }) => 80 / size}px;
  padding: ${({ size }) => 8 / size}px;
  width: 100%;
`;

const TileNameText = styled(HorizontalFlexWrap)`
  height: 100%;
  align-items: ${({ isOver }) => (isOver ? 'center' : '')};
  justify-content: center;
  text-align: center;
  color: ${({ color, theme }) => color || theme.colors.black};
  font-size: ${({ fontSize }) => fontSize}px;
  line-height: ${({ fontSize }) => fontSize * 1.2}px;
  font-family: ${({ fontFamily }) => fontFamily || ''};
  overflow: hidden;
`;

export default EmbedCarouselTileName;
