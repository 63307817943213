import React from 'react';
import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Line,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import styled, { useTheme } from 'styled-components';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { useAnalytics } from 'app/src/context/AnalyticsStore';
import { getShortMonthAndDate } from 'app/src/utils/date.utils';
import { PLAYER_USER_LOG } from 'app/src/constants/analytics.constants';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';

const dateFormatter = fullDate => {
  return getShortMonthAndDate(fullDate);
};

const tooltipFormatter = (value, name) => {
  const valueId = name.split(',')[0];
  const tooltip = LINES.find(({ id }) => id === valueId).name;

  return [value, tooltip];
};

const tooltipLabelFormatter = fullDate => {
  return getShortMonthAndDate(fullDate);
};

const LINE_PROPS = {
  dot: false,
  strokeWidth: 3,
  type: 'monotone',
};

const LINES = [
  {
    id: 'sessionStart',
    name: 'Click Play',
    color: 'blue7',
  },
  {
    id: 'clickCta',
    name: 'Button Click',
    color: 'success',
  },
  {
    id: 'submitInput',
    name: 'Text Response',
    color: '#f66d00',
  },
  {
    id: 'audioResponse',
    name: 'Audio Response',
    color: '#ffa800',
  },
  {
    id: 'videoResponse',
    name: 'Video Response',
    color: '#7cb342',
  },
  {
    id: 'collectInfo',
    name: 'Collect Contact Details',
    color: '#f10096',
  },
  {
    id: 'feedScroll',
    name: 'Feed Swipe',
    color: 'warning',
  },
  {
    id: 'addToCart',
    name: 'Add to Cart',
    color: 'purple1',
  },
];

const AnalyticsEngagementOvertime = () => {
  const theme = useTheme();
  const [{ engagementEventsOvertime = {}, unique }] = useAnalytics();

  const countKey = unique ? `${PLAYER_USER_LOG}.uniqueUserCount` : `${PLAYER_USER_LOG}.count`;
  const label = unique ? 'unique viewers' : 'viewers';
  return (
    <StyledAnalyticsEngagementOvertime>
      <TextTiny>Events over time</TextTiny>
      <ResponsiveContainer width="99%" aspect={3}>
        <LineChart data={engagementEventsOvertime}>
          <XAxis dataKey="x" tickFormatter={dateFormatter} />
          <YAxis axisLine={false} tickLine={false} label={{ value: label, name: 'yaxis' }} />
          <CartesianGrid stroke="#eee" strokeDasharray="4 4" vertical={false} horizontal={false} />
          <Tooltip formatter={tooltipFormatter} labelFormatter={tooltipLabelFormatter} />
          {LINES.map(({ id, color }) => {
            return (
              <Line
                {...LINE_PROPS}
                key={id}
                dataKey={`${id},${countKey}`}
                stroke={theme.colors[color] || color}
              />
            );
          })}
        </LineChart>
      </ResponsiveContainer>
    </StyledAnalyticsEngagementOvertime>
  );
};

export default AnalyticsEngagementOvertime;

const StyledAnalyticsEngagementOvertime = styled(Gap8VerticalFlex)`
  .recharts-responsive-container {
    background: ${({ theme }) => theme.colors.white};
    border-radius: 12px;
  }

  & g > text[name='yaxis'] {
    transform: rotate(270deg);
    transform-origin: 26px;
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.black};
  }
`;
