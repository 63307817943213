import React from 'react';

function NotificationImpressionIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" viewBox="0 0 40 40">
      <rect width="40" height="40" fill="#FFE5D7" rx="10" />
      <path
        fill="#FD7830"
        d="M28.875 19.469C27.187 15.625 23.812 13 20 13c-3.844 0-7.219 2.625-8.906 6.469A1.73 1.73 0 0011 20c0 .156.031.406.094.563C12.78 24.405 16.156 27 20 27c3.813 0 7.188-2.594 8.875-6.438.063-.156.125-.406.125-.562 0-.125-.063-.375-.125-.531zm-4.375.562c0 2.469-2.031 4.469-4.5 4.469-2.5 0-4.5-2-4.5-4.5 0-2.469 2-4.5 4.5-4.5 2.469 0 4.5 2.031 4.5 4.5v.031zM20 17h-.031c-.063.031-.157.031-.25.031.156.313.281.625.281.969 0 1.125-.906 2-2 2-.375 0-.719-.094-1-.25V20a3 3 0 003 3c1.625 0 3-1.344 3-3 0-1.625-1.375-3-3-3z"
      />
    </svg>
  );
}

export default NotificationImpressionIcon;
