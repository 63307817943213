import VideoIcon from 'app/src/images/creation_method_icons/VideoIcon';
import {
  IMPORT_FROM_INSTAGRAM,
  IMPORT_FROM_TIKTOK,
  LIBRARY,
  TOLSTOY_LIBRARY,
  RECORD_SCREEN,
  RECORD_VIDEO,
  START_FROM_TEMPLATE,
  STOCK_VIDEOS,
  UPLOAD_FILE,
} from 'app/src/constants/creation_method.constants';
import UploadFileIcon from 'app/src/images/creation_method_icons/UploadFileIcon';
import ScreenIcon from 'app/src/images/creation_method_icons/ScreenIcon';
import LibraryIcon from 'app/src/images/creation_method_icons/LibraryIcon';
import {
  LIBRARY_TAB_TEST_ID,
  READY_MADE_TOLSTOY_BUTTON_DATA_TEST_ID,
  STOCK_VIDEOS_TAB_TEST_ID,
} from 'app/src/constants/dataTestIds.constants';
import TemplateIcon from 'app/src/images/creation_method_icons/TemplateIcon';
import StockVideosIcon from 'app/src/images/creation_method_icons/StockVideosIcon';
import TikTokIcon from 'app/src/images/TikTokIcon';
import Utils from 'shared/react/utils/utils';
import InstagramIcon from 'app/src/images/InstagramIcon';

const RECORD_VIDEO_CATEGORY = { Icon: VideoIcon, name: RECORD_VIDEO };
const UPLOAD_FILE_CATEGORY = { Icon: UploadFileIcon, name: UPLOAD_FILE };
const RECORD_SCREEN_CATEGORY = { Icon: ScreenIcon, name: RECORD_SCREEN };
const LIBRARY_CATEGORY = {
  Icon: LibraryIcon,
  name: LIBRARY,
  dataTestId: LIBRARY_TAB_TEST_ID,
};
const TOLSTOY_CATEGORY = {
  Icon: TemplateIcon,
  name: TOLSTOY_LIBRARY,
};
const TEMPLATE_CATEGORY = {
  Icon: TemplateIcon,
  name: START_FROM_TEMPLATE,
  dataTestId: READY_MADE_TOLSTOY_BUTTON_DATA_TEST_ID,
};
const STOCK_VIDEOS_CATEGORY = {
  Icon: StockVideosIcon,
  name: STOCK_VIDEOS,
  dataTestId: STOCK_VIDEOS_TAB_TEST_ID,
};
const TIKTOK_CATEGORY = {
  Icon: TikTokIcon,
  name: IMPORT_FROM_TIKTOK,
};
const INSTAGRAM_CATEGORY = {
  Icon: InstagramIcon,
  name: IMPORT_FROM_INSTAGRAM,
};

const creationCategories = {
  [LIBRARY]: LIBRARY_CATEGORY,
  [TOLSTOY_LIBRARY]: TOLSTOY_CATEGORY,
  [START_FROM_TEMPLATE]: TEMPLATE_CATEGORY,
  [STOCK_VIDEOS]: STOCK_VIDEOS_CATEGORY,
  [RECORD_VIDEO]: RECORD_VIDEO_CATEGORY,
  [UPLOAD_FILE]: UPLOAD_FILE_CATEGORY,
  [RECORD_SCREEN]: RECORD_SCREEN_CATEGORY,
  [IMPORT_FROM_TIKTOK]: TIKTOK_CATEGORY,
  [IMPORT_FROM_INSTAGRAM]: INSTAGRAM_CATEGORY,
};

export const getEcomCategories = (shouldShowTemplates, isInstagramEnabled) => {
  const items = [UPLOAD_FILE_CATEGORY, TIKTOK_CATEGORY];

  if (isInstagramEnabled) {
    items.push(INSTAGRAM_CATEGORY);
  }

  items.push(RECORD_VIDEO_CATEGORY);

  if (!Utils.isMobile()) {
    items.push(RECORD_SCREEN_CATEGORY);
  }

  items.push(LIBRARY_CATEGORY);

  items.push(STOCK_VIDEOS_CATEGORY);

  if (shouldShowTemplates) {
    items.push(TEMPLATE_CATEGORY);
  }

  return items;
};

export const getCategories = (shouldShowTemplates, isInstagramEnabled) => {
  const items = [RECORD_VIDEO_CATEGORY, UPLOAD_FILE_CATEGORY];

  if (!Utils.isMobile()) {
    items.push(RECORD_SCREEN_CATEGORY);
  }

  items.push(LIBRARY_CATEGORY);

  if (shouldShowTemplates) {
    items.push(TEMPLATE_CATEGORY);
  }

  items.push(STOCK_VIDEOS_CATEGORY, TIKTOK_CATEGORY);

  if (isInstagramEnabled) {
    items.push(INSTAGRAM_CATEGORY);
  }

  return items;
};

export const getCustomCategories = customCategoriesMethods => {
  const items = [];
  customCategoriesMethods.forEach(categoryName => {
    if (creationCategories[categoryName]) {
      items.push(creationCategories[categoryName]);
    }
  });
  return items;
};
