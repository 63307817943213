export const getSearchParamsPath = (params: Record<string, unknown> = {}) => {
  const searchParams = {};
  Object.entries(params).forEach(([key, value]) => {
    if (value !== null && value !== undefined && value !== '') {
      searchParams[key] = value;
    }
  });

  return new URLSearchParams(searchParams).toString();
};

export const getUrlWithParams = (url: string, params: Record<string, unknown>) => {
  return `${url}?${getSearchParamsPath(params)}`;
};

export const getUrlParams = () => {
  return new URLSearchParams(window.location.search);
};

export const appendParamsToUrl = (url: string | URL, params: Record<string, unknown>): string => {
  const newUrl = new URL(url);
  const newParamsPath = getSearchParamsPath(params);

  newUrl.search = [newUrl.search, newParamsPath].filter(Boolean).join('&');

  return newUrl.toString();
};
