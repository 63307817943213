import { vodLabel as VodLabel } from 'app/src/types/entities';
import { FEATURE_INSTAGRAM_IMPORT_PRODUCTS } from 'shared/react/constants/features.constants';
import { useAccountActions } from 'src/context/AccountStore';
import { useApps } from 'src/context/AppsStore';
import { useFeatureActions } from 'src/context/FeaturesStore';
import { useInstagram } from 'src/context/InstagramStore';
import useCreateIntegrationLabels from '../useCreateIntegrationLabels';

const useOnInstagramVideoImported = () => {
  const [, { getProductsForVideoId }] = useInstagram();
  const { getAccountAppKey } = useAccountActions();
  const { getFeatureEnabled } = useFeatureActions();
  const [{ shopify }] = useApps();
  const { createIntegrationLabels } = useCreateIntegrationLabels();
  const isProductImportEnabled = getFeatureEnabled(FEATURE_INSTAGRAM_IMPORT_PRODUCTS);

  const extractHashtagsFromDescription = (description: string): string[] => {
    // return all occurrences of words that start with #
    return description?.match(/#\w+/g);
  };

  const createLabelsByInstagramHashtags = async ({ integrationVodAsset, vodAssetId }) => {
    const { description } = integrationVodAsset;

    const hashtags = extractHashtagsFromDescription(description);

    const appKey = getAccountAppKey();

    return createIntegrationLabels(hashtags, { appKey, id: vodAssetId });
  };

  const handleInstagramVideoImported = async ({ integrationVodAsset, vodAssetId }) => {
    const promises = [
      createLabelsByInstagramHashtags({ integrationVodAsset, vodAssetId }),
    ] as Promise<VodLabel[] | void>[];

    if (!!shopify && isProductImportEnabled) {
      promises.push(
        getProductsForVideoId(integrationVodAsset.externalId || integrationVodAsset.id, vodAssetId)
      );
    }

    await Promise.all(promises);
  };

  return {
    handleInstagramVideoImported,
  };
};

export default useOnInstagramVideoImported;
