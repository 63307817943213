import React from 'react';
import {
  BannerDiv,
  PackagesRow,
} from 'app/src/complex_components/billings/BillingPackagesV2/BillingPackagesV2';

const BillingPackageRow = ({ type, Component, packages }) => {
  const values = packages.map(data => data[type]);

  return (
    <PackagesRow>
      {values.map((value, index) => (
        <React.Fragment key={index}>
          {packages[index]?.banner && <BannerDiv />}
          <Component
            key={index}
            value={value}
            packageInfo={packages[index]}
            bannerText={packages[index]?.bannerText}
          >
            {value}
          </Component>
        </React.Fragment>
      ))}
    </PackagesRow>
  );
};

export default BillingPackageRow;
