import React from 'react';

const ShareIcon = () => (
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.25 5a.74.74 0 0 0 .75-.75V2l5 4.5-5 4.5V8.75A.76.76 0 0 0 9.25 8H6c-2.219 0-4 1.813-4 4 0 .281 0 .531.063.75C1.469 12 1 10.969 1 9.5 1 7.031 3 5 5.5 5h3.75ZM9 9.5V11c0 .406.219.781.594.938a.961.961 0 0 0 1.062-.188l5-4.5A.95.95 0 0 0 16 6.5a.934.934 0 0 0-.344-.719l-5-4.5a.961.961 0 0 0-1.062-.187A.984.984 0 0 0 9 2v2H5.5A5.488 5.488 0 0 0 0 9.5c0 3.563 2.531 5.125 3.125 5.469a.614.614 0 0 0 .25.031c.344 0 .625-.25.625-.594 0-.25-.156-.469-.313-.625C3.376 13.5 3 12.97 3 12a3 3 0 0 1 3-3h3v.5Z"
      fill="#090A0B"
    />
  </svg>
);

export default ShareIcon;
