import React from 'react';
import styled from 'styled-components';
import Menu from 'shared/react/components/basic/Menu';
import ProductIcon from 'shared/react/images/ProductIcon';
import MenuItemV2 from 'app/src/complex_components/MenuItemV2';
import Separator from 'shared/react/components/basic/Separator';
import CartIcon from 'app/src/images/CartIcon';
import { useApps } from 'app/src/context/AppsStore';
import { PRODUCT_CTA_TYPES } from 'shared/react/constants/feed.constants';
import PromoCodeIcon from 'app/src/images/PromoCodeIcon';

const ProductActionMenu = ({ anchorEl, setAnchorEl, isFeed, setProductCtaType }) => {
  const [{ shopify }] = useApps();

  if (!isFeed || !shopify) {
    return null;
  }

  const ITEMS = [
    {
      icon: <CartIcon />,
      title: 'Add to cart',
      value: PRODUCT_CTA_TYPES.addToCart,
      onClick: () => setProductCtaType(PRODUCT_CTA_TYPES.addToCart),
      tooltipText:
        'Please note the "Add to cart" button is only active on your Shopify website. When playing your feed on the Tolstoy landing page (preview included) the button will lead viewers to the checkout page',
    },
    {
      icon: <ProductIcon width={16} height={16} />,
      title: 'Buy now',
      value: PRODUCT_CTA_TYPES.buyNow,
      onClick: () => setProductCtaType(PRODUCT_CTA_TYPES.buyNow),
    },
    {
      icon: <StyledPromoCodeIcon />,
      title: 'Product page',
      value: PRODUCT_CTA_TYPES.openProductPage,
      onClick: () => setProductCtaType(PRODUCT_CTA_TYPES.openProductPage),
    },
  ];

  return (
    <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
      <LayoutRoot>
        <Header>Where to?</Header>
        <Separator />
        {ITEMS.map(item => {
          return <MenuItemV2 key={item.value} {...item} />;
        })}
      </LayoutRoot>
    </Menu>
  );
};

const LayoutRoot = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  position: relative;
`;

const Header = styled.div`
  color: ${({ theme }) => theme.colors.gray25};
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding: 20px 0 8px 20px;
`;

const StyledPromoCodeIcon = styled(PromoCodeIcon)`
  width: 16px;
  height: 16px;
`;

export default ProductActionMenu;
