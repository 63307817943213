import React from 'react';

const AccountPlansIcon = ({ fill = '#3D4FE0' }) => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 3H3c-1.125 0-2 .906-2 2v10c0 1.125.875 2 2 2h14c1.094 0 2-.875 2-2V5c0-1.094-.906-2-2-2Zm.469 12c0 .281-.219.5-.5.5H3a.494.494 0 0 1-.5-.5V5c0-.25.219-.5.5-.5h13.969c.281 0 .5.25.5.5v10ZM6.25 12.5h-1.5a.74.74 0 0 0-.75.75c0 .438.313.75.75.75h1.5a.74.74 0 0 0 .75-.75.76.76 0 0 0-.75-.75Zm6 0h-3.5a.74.74 0 0 0-.75.75c0 .438.313.75.75.75h3.5a.74.74 0 0 0 .75-.75.76.76 0 0 0-.75-.75Z"
        fill={fill}
      />
    </svg>
  );
};

export default AccountPlansIcon;
