import React from 'react';
import styled from 'styled-components';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import { Checkbox } from '@material-ui/core';
import { TextBody } from 'shared/react/components/complex/Text';

const TikTokImportFromLink = ({ consentChecked, setConsentChecked }) => {
  return (
    <>
      <VideoContainer>
        <InteractiveVideoContainer></InteractiveVideoContainer>
      </VideoContainer>
      <ConsentContainer>
        <ConsentCheckbox
          checked={consentChecked}
          onChange={() => setConsentChecked(!consentChecked)}
          style={{ padding: '0px', 'border-radius': '2px' }}
        />
        <ConsentText>
          {`By using this feature and importing content to Tosltoy ("Content"), you acknowledge and
          agree that you may only import Content which you own or otherwise have the right to import
          to Tolstoy. You are solely responsible for ensuring that your use of this functionality
          complies with applicable laws, including, without limitation, privacy laws, and that you
          have any and all rights and consents necessary to make use of this functionality.`}
        </ConsentText>
      </ConsentContainer>
    </>
  );
};

const ConsentContainer = styled(Gap8HorizontalFlexWrap)`
  align-items: start;

  & + & {
    margin-top: 8px;
  }
`;

const ConsentCheckbox = styled(Checkbox)``;

const ConsentText = styled(TextBody)`
  padding-top: 2px;
  flex: 1;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.black};
`;

const VideoContainer = styled.div`
  display: grid;
  height: 100%;
`;

const InteractiveVideoContainer = styled.div`
  grid-column: 1;
  grid-row: 1;
`;

export default TikTokImportFromLink;
