import React, { useRef } from 'react';
import styled from 'styled-components';
import WindowActionBarButtons from 'app/src/complex_components/WindowActionBarButtons';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import { useVideos } from 'app/src/context/VideosStore';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import Button from 'shared/react/components/complex/Button';
import Types from 'shared/react/theme/Types';
import BottomToRightArrowIcon from 'app/src/images/BottomToRightArrowIcon';
import { useBilling } from 'app/src/hooks/useBillings';
import CLPPreview from 'app/src/pages/project/pages/look_and_feel/preview/landing_page_preview/CLPPreview';

function LandingPagePreview() {
  const {
    project,
    customizationSettings: { chatLandingPage },
  } = useLookAndFeelContext();
  const [, { getVideoUrl }] = useVideos();
  const { isFreePackage } = useBilling();
  const videoRef = useRef();
  const firstStep = project.steps.items.find(step => step.name === project.stepsOrder[0]) || {};
  const [{ video }] = useVideos(firstStep.videoId);

  return (
    <LayoutRoot>
      <HeaderContainer>
        <DotsContainer />
        <Gap8HorizontalFlexWrap>
          <Button
            leftIcon={<BottomToRightArrowIcon />}
            showLeftIcon={true}
            disableColorStateChanges={true}
            type={Types.Ghost}
          >
            Share
          </Button>
          {isFreePackage && (
            <Button disableColorStateChanges={true} type={Types.Success}>
              Get Tolstoy for free
            </Button>
          )}
        </Gap8HorizontalFlexWrap>
      </HeaderContainer>
      <Video
        src={getVideoUrl(video)}
        ref={videoRef}
        autoPlay
        loop
        muted
        playsInline
        allowFullScreen={false}
        disablePictureInPicture={true}
        controls={false}
      />
      {!!chatLandingPage && <CLPPreview />}
    </LayoutRoot>
  );
}

export default LandingPagePreview;

const LayoutRoot = styled(Gap16VerticalFlex)`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.gray4};
  border-radius: 24px;
  padding: 32px;
  max-width: 791px;
`;

const DotsContainer = styled(WindowActionBarButtons)`
  margin-bottom: 24px;
`;

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

const Video = styled.video`
  margin-top: 34px;
  border-radius: 16px;
  object-fit: cover;
  width: 100%;
  height: 396px;
`;
