import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import styled from 'styled-components';

export default styled(Gap8HorizontalFlex)`
  padding: 10px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  justify-content: center;
  cursor: pointer;
  align-items: center;
`;
