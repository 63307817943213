import { createActionsHook, createContainer, createHook, createStore } from 'react-sweet-state';
import { getRequest } from 'app/src/helpers/API';
import { INSTAGRAM } from 'app/src/constants/intergrations.constants';
import { DEFAULT_NUMBER_OF_CARDS_TO_SHOW } from 'app/src/constants/ui.constants';
import { InstagramApiService } from 'app/src/services/instagram';
import {
  getProductsForOldVideos,
  normalizeInstagramVideoData,
} from 'app/src/services/instagram/InstagramApiService';

export const IMPORTANT_UGC_PERMISSION = 'instagram_manage_comments';
export const UGC_WEBHOOK_PERMISSION = 'pages_manage_metadata';
const NEXT_PAGE_SIZE = 20;

const initialState = {
  accountVideos: [],
  cursor: null,
  appsOptionsMap: {},
  initialized: false,
  isWaitingForResponse: false,
  gotAllVideos: false,
  isLoading: false,
  mediaByTag: {},
  selectedUGCCategory: '',
};

const actions = {
  fetchByAccount:
    app =>
    async ({ getState, setState, dispatch }, { appKey, owner }) => {
      try {
        const { cursor, accountVideos, gotAllVideos, isLoading } = getState();
        if (gotAllVideos || isLoading) {
          return;
        }

        setState({ isLoading: true });

        const searchParams = new URLSearchParams();
        searchParams.set('appKey', appKey);
        searchParams.set('maxNumberOfVideos', DEFAULT_NUMBER_OF_CARDS_TO_SHOW);

        if (cursor) {
          searchParams.set('nextCursor', cursor);
        }

        const instagramAPIKey = app?.app === INSTAGRAM ? 'instagram' : 'instagram-graph';

        setState({ isWaitingForResponse: true });
        const response = await getRequest(
          instagramAPIKey,
          `/${instagramAPIKey}/get-videos-list?${searchParams.toString()}`
        );

        const { videos = [], nextCursor } = response;

        const normalizedVideos = videos.map((video, i) =>
          normalizeInstagramVideoData(video, owner, i + accountVideos.length)
        );

        setState({
          accountVideos: [...accountVideos, ...normalizedVideos],
          cursor: nextCursor,
          gotAllVideos: !nextCursor,
          initialized: true,
          isWaitingForResponse: false,
          isLoading: false,
        });
      } catch (e) {
        console.error(e);
        setState({ isWaitingForResponse: false });
        return e;
      }
    },
  fetchByApps:
    apps =>
    async ({ getState, setState, dispatch }, { owner }) => {
      try {
        const { accountVideos, isLoading, appsOptionsMap } = getState();

        if (isLoading) {
          return;
        }

        setState({ isLoading: true, isWaitingForResponse: true });

        const appsToFetch = apps.filter(app => !appsOptionsMap[app.id]?.gotAllVideos);

        const responses = await InstagramApiService.getVideosByApps(appsToFetch, appsOptionsMap);
        const newOptions = {};
        const newVideos = [];

        responses.forEach(({ videos = [], nextCursor }, i) => {
          const app = appsToFetch[i];

          newOptions[app.id] = {
            ...(appsOptionsMap[app.id] || {}),
            maxNumberOfVideos: NEXT_PAGE_SIZE,
            cursor: nextCursor,
            gotAllVideos: !nextCursor,
          };

          newVideos.push(...videos);
        });

        const normalizedVideos = newVideos.map((video, i) =>
          normalizeInstagramVideoData(video, owner, i + accountVideos.length)
        );

        setState({
          accountVideos: [...accountVideos, ...normalizedVideos],
          appsOptionsMap: { ...appsOptionsMap, ...newOptions },
          initialized: true,
          isWaitingForResponse: false,
          isLoading: false,
        });
      } catch (e) {
        console.error(e);
        setState({
          isWaitingForResponse: false,
          isLoading: false,
        });
        return e;
      }
    },
  getProductsForVideoId:
    (externalVideoId, vodAssetId) =>
    async (_, { appKey }) => {
      const searchParams = new URLSearchParams();
      searchParams.set('appKey', appKey);
      searchParams.set('videoId', externalVideoId);
      searchParams.set('vodAssetId', vodAssetId);

      try {
        await getRequest(
          'instagram-graph',
          `/instagram-graph/get-products?${searchParams.toString()}`
        );
      } catch (err) {
        console.log(err);
      }
    },
  fetchByAccountTagging:
    (appId, reload) =>
    async ({ setState, getState, owner, dispatch }) => {
      try {
        const { accountVideos, isLoading, appsOptionsMap } = getState();
        const gotAllVideos = !reload && appsOptionsMap[appId]?.gotAllVideos;

        if (isLoading || !appId || gotAllVideos) {
          return;
        }

        setState({ isLoading: true, isWaitingForResponse: true });

        const { media, nextCursor } = await InstagramApiService.getMediaByTagging({
          appId,
          options: reload ? {} : appsOptionsMap[appId],
          accountVideosLength: accountVideos.length,
          owner,
        });

        const newOptions = {};

        if (reload) {
          newOptions[appId] = {
            cursor: nextCursor,
            gotAllVideos: !nextCursor,
            media,
          };
        } else {
          newOptions[appId] = {
            ...(appsOptionsMap[appId] || {}),
            cursor: nextCursor,
            gotAllVideos: !nextCursor,
            media: [...(appsOptionsMap[appId]?.media || []), ...media].filter(Boolean),
          };
        }

        setState({
          appsOptionsMap: { ...appsOptionsMap, ...newOptions },
          initialized: true,
          isWaitingForResponse: false,
          isLoading: false,
        });
      } catch (e) {
        console.error(e);
        setState({
          isWaitingForResponse: false,
          isLoading: false,
        });
        return e;
      }
    },
  fetchByHashtag:
    (appId, hashtag, reload) =>
    async ({ setState, getState, owner, dispatch }) => {
      try {
        const { accountVideos, isLoading, appsOptionsMap } = getState();

        const gotAllVideos = !reload && appsOptionsMap[hashtag]?.gotAllVideos;
        if (isLoading || !hashtag || gotAllVideos) {
          return;
        }

        setState({ isLoading: true, isWaitingForResponse: true });

        const { media, nextCursor, hashtagId } = await InstagramApiService.getMediaByHashtag({
          appId,
          hashtag,
          options: reload ? {} : appsOptionsMap[hashtag],
          accountVideosLength: accountVideos.length,
          owner,
        });

        const newOptions = {};

        if (reload) {
          newOptions[hashtag] = {
            cursor: nextCursor,
            gotAllVideos: !nextCursor,
            media: media.filter(Boolean),
          };
        } else {
          newOptions[hashtag] = {
            ...(appsOptionsMap[hashtag] || {}),
            hashtagId,
            cursor: nextCursor,
            gotAllVideos: !nextCursor,
            media: [...(appsOptionsMap[hashtag]?.media || []), ...(media || [])].filter(Boolean),
          };
        }

        setState({
          appsOptionsMap: { ...appsOptionsMap, ...newOptions },
          initialized: true,
          isWaitingForResponse: false,
          isLoading: false,
        });
      } catch (e) {
        console.error(e);
        setState({
          isWaitingForResponse: false,
          isLoading: false,
        });
        return e;
      }
    },
  addInstagramHashtag: (app, hashtag) => () => {
    const settings = { ...app?.settings };
    settings.hashtags = [...new Set([...(app?.settings?.hashtags || []), hashtag])];
    return settings;
  },
  removeInstagramHashtag: (app, hashtag) => () => {
    const settings = { ...app.settings };
    settings.hashtags = (app.settings.hashtags || []).filter(h => h !== hashtag);
    return settings;
  },
  getProductsForOldVideos:
    () =>
    async ({ getState }, { appKey }) => {
      await getProductsForOldVideos(appKey);
    },
  setSelectedUGCCategory:
    category =>
    ({ setState }) => {
      setState({ selectedUGCCategory: category });
    },
  clearStore:
    () =>
    ({ setState }) => {
      setState({ ...initialState });
    },
};

const InstagramStore = createStore({ initialState, actions, name: 'Instagram' });

export const useInstagram = createHook(InstagramStore);
export const useInstagramActions = createActionsHook(InstagramStore);
export const useInstagramUgcSelectedCategory = createHook(InstagramStore, {
  selector: state => state.selectedUGCCategory,
});

export const InstagramContainer = createContainer(InstagramStore);
