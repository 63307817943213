import styled from 'styled-components';
import Spinner from 'app/src/basic_components/Spinner';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import { getHideVodRuleTypeKey, getOrderVodRuleTypeKey } from 'app/src/context/RulesStore';
import { useVideoActions } from 'app/src/context/VideosStore';
import { sortEntities } from 'app/src/hooks/use-dynamic-video-ids-by-project/checkRules';
import useDrag from 'app/src/hooks/useDrag';
import ExpanderTopArrowIcon from 'app/src/images/creation_method_icons/ExpanderTopArrowIcon';
import { RowData } from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-products-table/types/dynamicProductsTable.type';
import TableVideoCard from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/video-card/TableVideoCard';
import { project as Project, rule as RuleType } from 'app/src/types/entities';
import arrayMove from 'array-move';
import React, { useEffect, useState } from 'react';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { FEATURE_ORDER_FEED_VIDEOS } from 'shared/react/constants/features.constants';
import VirtualizedProductDetails from 'src/basic_components/virtualized-table/VirtualizedProductDetails';
import {
  VirtualizedRowColumn,
  VirtualizedRowContainer,
  VirtualizedVideosRowContainer,
} from 'src/basic_components/virtualized-table/VirtualizedRows';
import { track } from 'src/helpers/Tracker';
import AddNewVideoButton, {
  AddNewVideoButtonModes,
} from '../../add-new-video-button/AddNewVideoButton';

type Props = {
  index: number;
  style: any;
  rowData: RowData;
  firstColumnWidth: number;
  tileWidth: number;
  expandedIndex: number;
  setExpandedIndex: (index: number) => void;
  analyticsData: {
    [key: string]: string;
  };
  setVideoRules: (rules: any) => void;
  videoRules?: RuleType[];
  project: Project;
  onAddManualVideo?: (rowData: RowData) => void;
  onRemoveManualVideo?: ({ video, rowData }) => void;
};

const TableRow = ({
  index,
  style,
  rowData,
  firstColumnWidth,
  tileWidth,
  expandedIndex,
  setExpandedIndex,
  analyticsData,
  videoRules,
  setVideoRules,
  project,
  onAddManualVideo,
  onRemoveManualVideo,
}: Props) => {
  const { getFeatureEnabled } = useFeatureActions();
  const [vodAssetIds, setVodAssetIds] = useState(rowData.videoAssetIds || []);
  const { onDragOver, handleDragStart, handleDrop, draggingToIndex, draggingFromIndex } = useDrag({
    onDrop,
  });

  const { getVideoById } = useVideoActions();
  const isOpen = expandedIndex === index;
  const currentTop = style.top;
  const productId = rowData.productPage?.productId;
  const canOrder = getFeatureEnabled(FEATURE_ORDER_FEED_VIDEOS);

  function onDrop(index) {
    if (!canOrder) {
      return;
    }

    const newAssetsOrder = arrayMove(vodAssetIds, draggingFromIndex, index);
    const sortedEntities = sortEntities(newAssetsOrder, videoRules, productId);
    setVodAssetIds(sortedEntities);
  }

  const onRowClick = e => {
    track('Dynamic Products Table Expand Row Click', analyticsData);
    setExpandedIndex(isOpen ? null : index);
    e.target.scrollIntoView();
  };

  useEffect(() => {
    if (rowData.isLoading) {
      return;
    }

    setVodAssetIds(rowData.videoAssetIds);
  }, [rowData.videoAssetIds]);

  const onAddManualVideoClick = e => {
    e.stopPropagation();
    onAddManualVideo(rowData);
  };

  const hasVideos = !!rowData.videosNumber;
  const handleRemoveManualVideo = async video => onRemoveManualVideo({ video, rowData });

  if (rowData.isLoading) {
    return (
      <VirtualizedRowContainer>
        <Spinner />
      </VirtualizedRowContainer>
    );
  }

  const row = (
    <VirtualizedRowContainer onClick={onRowClick}>
      <VirtualizedRowColumn width={firstColumnWidth}>
        <VirtualizedProductDetails
          imageUrl={rowData.productPage.imageUrl}
          title={rowData.productPage.name}
        />
      </VirtualizedRowColumn>
      <VirtualizedRowColumn width={tileWidth}>
        {hasVideos ? (
          <CenteredTextTiny>{rowData.videosNumber}</CenteredTextTiny>
        ) : (
          <AddNewVideoButton onClick={onAddManualVideoClick} />
        )}
      </VirtualizedRowColumn>
      <VirtualizedRowColumn width={50}>
        <StyledIconButton isOpen={isOpen}>
          <ExpanderTopArrowIcon />
        </StyledIconButton>
      </VirtualizedRowColumn>
    </VirtualizedRowContainer>
  );

  if (isOpen) {
    return (
      <RowContainer style={{ ...style, top: currentTop }}>
        {row}
        <VideosRow>
          {vodAssetIds.map((videoAssetId, index) => {
            const video = getVideoById(videoAssetId);
            const isDisabled = !!videoRules?.find(({ value }) => {
              return (
                value === getOrderVodRuleTypeKey(video.id, index, productId) ||
                value === getHideVodRuleTypeKey(video.id, productId)
              );
            });

            return (
              <React.Fragment key={videoAssetId}>
                {draggingToIndex === index && canOrder && !isDisabled && (
                  <div
                    onDragStart={e => handleDragStart(e, index)}
                    onDrop={e => handleDrop(e, index, isDisabled)}
                    onDragOver={e => onDragOver(e, index)}
                  />
                )}
                <StyledSortableElement
                  onDragStart={e => handleDragStart(e, index)}
                  onDrop={e => handleDrop(e, index, isDisabled)}
                  onDragOver={e => onDragOver(e, index)}
                >
                  <TableVideoCard
                    videoRules={videoRules}
                    setVideoRules={setVideoRules}
                    video={video}
                    analyticsData={analyticsData}
                    productId={productId}
                    isDragged={index === draggingFromIndex}
                    index={index}
                    isDragDisabled={isDisabled}
                    project={project}
                    onRemove={() => handleRemoveManualVideo(video)}
                  />
                </StyledSortableElement>
              </React.Fragment>
            );
          })}
          <AddNewVideoButton
            mode={AddNewVideoButtonModes.BigCard}
            onClick={onAddManualVideoClick}
          />
        </VideosRow>
      </RowContainer>
    );
  }

  return <RowContainer style={{ ...style, top: currentTop }}>{row}</RowContainer>;
};

const StyledIconButton = styled(HorizontalFlex)<{ isOpen: boolean }>`
  margin-right: 8px;
  justify-content: flex-end;

  svg {
    transition: 0.3s;
    transform: rotate(${({ isOpen }) => (isOpen ? 0 : 180)}deg);
  }
`;

const VideosRow = styled(VirtualizedVideosRowContainer)`
  height: 100%;
`;

const RowContainer = styled(VerticalFlex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutralLight};

  :last-of-type {
    border-bottom: none;
  }
`;

const CenteredTextTiny = styled(TextTiny)`
  text-align: center;
`;

const StyledSortableElement = styled.div``;

export default TableRow;
