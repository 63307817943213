import React from 'react';
import styled from 'styled-components';
import TolstoyLogoWithWord from 'app/src/images/TolstoyLogoWithWord';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const TolstoyEmptyScreen = ({ children, onXButton, ...props }) => {
  return (
    <LayoutRoot>
      <Content {...props}>
        <LogoContainer>
          <TolstoyLogoWithWord />
        </LogoContainer>
        {children}
      </Content>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  background: ${({ theme }) => theme.colors.white};
  height: 100%;
  padding: 16px;
  overflow: hidden;
  width: 100%;
}

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    padding: 0px;
    overflow: auto;
    background: ${({ theme }) => theme.colors.gray5};
  }
`;

const Content = styled(VerticalFlex)`
  background: ${({ theme }) => theme.colors.gray5};
  width: 100%;
  height: 100%;
  border-radius: 16px;
  align-items: center;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    height: inherit;
    margin: 0;
    padding: 16px;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 48px;
  left: 48px;

  & svg {
    height: 28px;
    width: 100%;
  }

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    position: initial;
  }
`;

export default TolstoyEmptyScreen;
