import React from 'react';

const VideoReplyIcon = () => {
  return (
    <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x=".5" width="28" height="28" rx="14" fill="#FFE0EC" />
      <path
        d="M16.75 11.125A1.14 1.14 0 0 0 15.625 10h-6.75c-.633 0-1.125.516-1.125 1.125v6.75A1.11 1.11 0 0 0 8.875 19h6.75c.61 0 1.125-.492 1.125-1.125v-6.75Zm4.5.375c0-.586-.703-.938-1.195-.61L17.5 12.673v3.68l2.555 1.78c.492.329 1.195-.023 1.195-.609V11.5Z"
        fill="#FA536E"
      />
    </svg>
  );
};

export default VideoReplyIcon;
