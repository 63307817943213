import React from 'react';
import Utils from 'app/src/utils';
import CountdownRecording from 'app/src/pages/creation_flow/right_section/video_container/video_overlay/CountdownRecording';
import ChooseDevices from 'app/src/pages/creation_flow/right_section/video_container/video_overlay/ChooseDevices';
import { PREVIEW_MOBILE_KEY } from 'app/src/constants/tolstoy.constants';
import OrientationPreviewOverlay from 'app/src/pages/creation_flow/right_section/video_container/video_overlay/OrientationPreviewOverlay';
import VideoTimer from 'app/src/pages/creation_flow/right_section/video_container/video_overlay/VideoTimer';

const VideoOverlay = props => {
  const { countdownValue, isRecordVideo, recording, selectedPreviewMode, uploadState } = props;
  const shouldShowCountdown = !Utils.isNullOrUndefined(countdownValue);
  const shouldShowPreviewOverlay = selectedPreviewMode === PREVIEW_MOBILE_KEY;
  const shouldShowChooseDevices = !recording && Utils.isNullOrUndefined(countdownValue);

  if (uploadState) {
    return null;
  }

  return (
    <>
      {shouldShowCountdown && (
        <CountdownRecording countdownValue={countdownValue} isVideoRecording={isRecordVideo} />
      )}
      {shouldShowChooseDevices && <ChooseDevices {...props} />}
      {shouldShowPreviewOverlay && <OrientationPreviewOverlay />}
      <VideoTimer {...props} />
    </>
  );
};

export default VideoOverlay;
