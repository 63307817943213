import React from 'react';

const ShopifySmallIcon = () => {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.844 2.96c0-.07-.047-.093-.117-.093-.047 0-.868-.023-.868-.023l-.68-.68v10.149l2.93-.75S9.844 3.008 9.844 2.96ZM7.5 2.165c-.047-.14-.094-.281-.164-.422-.234-.469-.61-.726-1.031-.726-.047 0-.07.023-.094.023-.024-.023-.047-.023-.047-.047-.187-.21-.422-.304-.726-.304-.563.023-1.125.421-1.57 1.148-.329.516-.563 1.148-.634 1.64-.656.212-1.101.352-1.101.352-.328.117-.352.117-.375.422-.024.234-.89 6.844-.89 6.844l7.077 1.219V2.046c-.023 0-.07 0-.093.023 0 0-.141.024-.352.094Zm-1.29.398c-.374.118-.796.258-1.194.376.117-.446.328-.891.609-1.172.094-.118.234-.235.398-.305.165.328.188.797.188 1.101Zm-.772-1.476a.94.94 0 0 1 .351.07 2.1 2.1 0 0 0-.445.352c-.375.375-.633.984-.75 1.547-.328.117-.68.21-.985.304.211-.89.961-2.25 1.829-2.273ZM4.359 6.242c.024.61 1.618.727 1.711 2.156.07 1.102-.586 1.875-1.547 1.922-1.148.07-1.757-.586-1.757-.586L3 8.704s.633.468 1.125.421a.431.431 0 0 0 .445-.469c-.047-.797-1.336-.75-1.43-2.039-.07-1.078.657-2.18 2.227-2.297.61-.023.914.117.914.117l-.351 1.36s-.399-.188-.89-.164c-.68.047-.704.492-.68.61Zm2.227-3.797c0-.28-.047-.68-.164-1.007.422.07.633.562.726.843-.164.047-.351.117-.562.164Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default ShopifySmallIcon;
