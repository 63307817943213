import { createActionsHook, createContainer, createHook, createStore } from 'react-sweet-state';
import { getDefaultProjectFilters } from 'app/src/utils/filters.utils';
import { WhereCondition } from 'app/src/pages/dashboard/utils/filterEntities';
import { DEFAULT_ONSITE_FILTERS, DEFAULT_OFFSITE_FILTERS } from 'src/constants/filters';
import { ASSET_TYPE } from 'shared/react/services/assets';
import { getTypeFilterValue } from 'src/utils/filters.utils';

type State = {
  sortBy: string;
  ascending: boolean;
  isCreatingDuplication: boolean;
  isCreatingNewTolstoy: boolean;
  projectFilters: any;
  selectedTeamMember: any;
  selectedFolder: string;
  selectedPublishMethod: string | null;
  offsiteFilters: WhereCondition;
  onSiteFilters: WhereCondition;
  videosFilters: WhereCondition;
  shopAppFilters: WhereCondition;
  duplicationMethod: string | null;
};

const initialState: State = {
  sortBy: 'updatedAt',
  ascending: false,
  isCreatingDuplication: false,
  isCreatingNewTolstoy: false,
  projectFilters: {},
  selectedTeamMember: {},
  selectedFolder: '',
  selectedPublishMethod: null,
  duplicationMethod: null,
  offsiteFilters: DEFAULT_OFFSITE_FILTERS,
  onSiteFilters: DEFAULT_ONSITE_FILTERS,
  shopAppFilters: {},
  videosFilters: {
    type: getTypeFilterValue(ASSET_TYPE.VIDEO),
  },
};

export const removeFalsy = value => {
  const keys = Object.keys(value);
  keys.forEach(key => {
    if (!value[key]) {
      delete value[key];
    }
  });
};

const actions = {
  setAppUrlInFilters:
    (selectedAppUrl: string) =>
    ({ dispatch }) => {
      const appUrlFilter = { appUrl: { in: [selectedAppUrl, null] } };

      dispatch(actions.setOnSiteFilters(appUrlFilter));
      dispatch(actions.setOffsiteFilters(appUrlFilter));
      dispatch(actions.setShopAppFilters(appUrlFilter));
    },
  setSortBy:
    sortBy =>
    ({ setState }) => {
      setState({ sortBy });
    },
  setAscending:
    ascending =>
    ({ setState }) => {
      setState({ ascending });
    },
  clearDashboard:
    () =>
    ({ setState }) => {
      setState({ ...initialState });
    },
  setCreatingDuplication:
    isCreatingDuplication =>
    ({ setState }) => {
      setState({ isCreatingDuplication });
    },
  setCreatingNewTolstoy:
    isCreatingNewTolstoy =>
    ({ setState }) => {
      setState({ isCreatingNewTolstoy });
    },
  setProjectFilters:
    projectFilters =>
    ({ setState }) => {
      setState({ projectFilters });
    },
  setSelectedTeamMember:
    selectedTeamMember =>
    ({ setState }) => {
      setState({ selectedTeamMember });
    },
  setDuplicationMethod:
    duplicationMethod =>
    ({ setState }) => {
      setState({ duplicationMethod });
    },
  setSelectedFolder:
    selectedFolder =>
    ({ setState }) => {
      setState({ selectedFolder });
    },
  setSelectedPublishMethod:
    selectedPublishMethod =>
    ({ setState }) => {
      setState({ selectedPublishMethod });
    },
  setFilters:
    (key: string, filters, forceReplace?: boolean) =>
    ({ setState, getState }) => {
      const newFilters = forceReplace ? { ...filters } : { ...getState()[key], ...filters };

      removeFalsy(newFilters);
      setState({ [key]: newFilters });
    },
  setOffsiteFilters:
    (filters, forceReplace?: boolean) =>
    ({ dispatch }) => {
      dispatch(actions.setFilters('offsiteFilters', filters, forceReplace));
    },
  setOnSiteFilters:
    (filters, forceReplace?: boolean) =>
    ({ dispatch }) => {
      dispatch(actions.setFilters('onSiteFilters', filters, forceReplace));
    },
  setVideosFilters:
    (filters, forceReplace?: boolean) =>
    ({ dispatch }) => {
      dispatch(actions.setFilters('videosFilters', filters, forceReplace));
    },
  setShopAppFilters:
    (filters, forceReplace?: boolean) =>
    ({ dispatch }) => {
      dispatch(actions.setFilters('shopAppFilters', filters, forceReplace));
    },
  resetProjectFilters:
    isAccountOwner =>
    ({ dispatch }) => {
      const defaultFilters = getDefaultProjectFilters({ isAccountOwner });
      dispatch(actions.setProjectFilters({ ...defaultFilters }));
      dispatch(actions.setSelectedTeamMember({}));
      dispatch(actions.setSelectedFolder(''));
      dispatch(actions.setSelectedPublishMethod(null));
    },
};

type Actions = typeof actions;

const DashboardStore = createStore({ initialState, actions });
export const useDashboardActions = createActionsHook(DashboardStore);

export const useDashboard = createHook<State, Actions, State, void>(DashboardStore);

export const DashboardContainer = createContainer(DashboardStore);
