import InitialsBadge from 'app/src/complex_components/InitialsBadge';
import { getInitials } from './contacts.utils';
import React from 'react';
import styled from 'styled-components';
import Unassigned from 'app/src/images/Unassigned';
import Utils from '../../utils';

const UNASSIGNED = 'unassigned';

const getAssignee = (teamMembers, user, assigneeId) => {
  if (!assigneeId) {
    return UNASSIGNED;
  }

  if (user?.id === assigneeId) {
    return user;
  }

  const assignee = teamMembers.find(member => member.id === assigneeId);

  return assignee;
};

const SessionAssignee = ({ teamMembers, assigneeId, user, active }) => {
  const assignee = getAssignee(teamMembers, user, assigneeId);
  const assigneeName = assignee?.name || assignee?.email || UNASSIGNED;
  const showAssignee = teamMembers.length > 1;

  if (!showAssignee) {
    return null;
  }

  const logoSettings = Utils.safeParse(assignee?.user?.logoSettings);

  return (
    <ContactAssignee title="assign" active={active}>
      {assignee !== UNASSIGNED && (
        <InitialsBadge
          imageSrc={Utils.getLogoUrl(logoSettings?.logo)}
          backgroundColor={logoSettings?.color}
          initials={getInitials(assigneeName)}
        />
      )}
      {assignee === UNASSIGNED && <Unassigned />}
    </ContactAssignee>
  );
};

export default SessionAssignee;

const ContactAssignee = styled.div`
  grid-area: assignee;
  display: flex;
  align-items: center;
  height: 22px;
  color: ${({ active, theme }) => (active ? theme.colors.white : theme.colors.ghostDark)};
`;
