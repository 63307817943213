import React from 'react';

const CodeBracketsIcon = () => {
  return (
    <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.719.805c.465.136.738.601.601 1.066l-3.5 12.25c-.136.465-.601.738-1.066.602-.465-.137-.738-.602-.602-1.067l3.5-12.25c.137-.465.602-.738 1.067-.601Zm2.215 3.28a.843.843 0 0 1 1.23 0l3.063 3.063a.843.843 0 0 1 0 1.23l-3.063 3.063a.843.843 0 0 1-1.23 0 .843.843 0 0 1 0-1.23l2.433-2.461-2.433-2.434a.843.843 0 0 1 0-1.23Zm-8.395 0a.843.843 0 0 1 0 1.231L3.105 7.75l2.434 2.46a.843.843 0 0 1 0 1.231.843.843 0 0 1-1.23 0L1.246 8.38a.843.843 0 0 1 0-1.23L4.31 4.085a.843.843 0 0 1 1.23 0Z"
        fill="#7D8087"
      />
    </svg>
  );
};

export default CodeBracketsIcon;
