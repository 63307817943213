import React from 'react';

const OpenLockIcon = () => {
  return (
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5 5h.75c.82 0 1.5.68 1.5 1.5V11c0 .844-.68 1.5-1.5 1.5h-7.5A1.48 1.48 0 0 1 .25 11V6.5c0-.82.656-1.5 1.5-1.5h5.625V3.5c0-1.64 1.336-3 3-3 1.64 0 3 1.36 3 3v1.688a.555.555 0 0 1-.563.562.542.542 0 0 1-.562-.563V3.5a1.88 1.88 0 0 0-1.875-1.875A1.866 1.866 0 0 0 8.5 3.5V5ZM1.375 6.5V11c0 .21.164.375.375.375h7.5A.385.385 0 0 0 9.625 11V6.5a.403.403 0 0 0-.375-.375h-7.5a.385.385 0 0 0-.375.375Z"
        fill="#3D4FE0"
      />
    </svg>
  );
};

export default OpenLockIcon;
