import React from 'react';

const StatusIcon = () => {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5 9c0-2.313-1.25-4.438-3.25-5.625a6.524 6.524 0 0 0-6.5 0C2.719 4.563 1.5 6.688 1.5 9a6.507 6.507 0 0 0 3.25 5.656 6.523 6.523 0 0 0 6.5 0c2-1.187 3.25-3.312 3.25-5.656ZM0 9a7.94 7.94 0 0 1 4-6.906c2.469-1.438 5.5-1.438 8 0C14.469 3.53 16 6.156 16 9a8.02 8.02 0 0 1-4 6.938c-2.5 1.437-5.531 1.437-8 0A7.953 7.953 0 0 1 0 9Z"
        fill="#B3B6BC"
      />
    </svg>
  );
};

export default StatusIcon;
