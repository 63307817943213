import React from 'react';
import styled from 'styled-components';
import Spinner from 'app/src/basic_components/Spinner';
import useDrag from 'app/src/hooks/useDrag';
import ExpanderTopArrowIcon from 'app/src/images/creation_method_icons/ExpanderTopArrowIcon';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import VirtualizedProductDetails from 'src/basic_components/virtualized-table/VirtualizedProductDetails';
import {
  VirtualizedRowColumn,
  VirtualizedRowContainer,
  VirtualizedVideosRowContainer,
} from 'src/basic_components/virtualized-table/VirtualizedRows';
import { track } from 'src/helpers/Tracker';
import AddNewVideoButton, {
  AddNewVideoButtonModes,
} from '../add-new-video-button/AddNewVideoButton';
import { RowData } from '../dynamic-products-table/types/dynamicProductsTable.type';
import TableVideoCard from './TableVideoCard';
import PlusButton from 'app/src/basic_components/PlusButton';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import { OPTIMAL_PRODUCT_VIDEOS } from 'app/src/constants/project.constants';
import { FEATURE_AI_VIDEOS } from 'shared/react/constants/features.constants';
import Utils from 'app/src/utils';
import VideoStudioButton from 'app/src/basic_components/VideoStudionButton';
import Tooltip from 'app/src/basic_components/Tooltip';
import { useApps } from 'src/context/AppsStore';

type Props = {
  index: number;
  style: any;
  rowData: RowData;
  firstColumnWidth: number;
  tileWidth: number;
  analyticsData: {
    [key: string]: string;
  };
  expandedProductId: string;
  canHide?: boolean;
  canOrder?: boolean;
  canLock?: boolean;
  canAddManualVideo?: boolean;
  canRemoveManualVideo?: boolean;
  onAddManualVideo?: (rowData: RowData) => void;
  onRemoveManualVideo?: ({ video, rowData }) => void;
  hideVideo: (videoId: string, productId?: string) => void;
  showVideo: (videoId: string, order: number, productId?: string) => void;
  lockVideo: (videoId: string, order: number, productId?: string) => void;
  unlockVideo: (videoId: string, order: number, productId?: string) => void;
  moveVideo: (params: { index: number; draggingFromIndex: number; productId?: string }) => void;
  openProductsModal: ({ video, analyticsData }) => void;
  setExpandedProduct: (productId: string) => void;
};

const DynamicProductsTableRow = ({
  style,
  rowData,
  firstColumnWidth,
  tileWidth,
  analyticsData,
  expandedProductId,
  canHide = false,
  canOrder = false,
  canLock = false,
  canAddManualVideo = true,
  canRemoveManualVideo = true,
  onAddManualVideo,
  onRemoveManualVideo,
  hideVideo,
  showVideo,
  lockVideo,
  unlockVideo,
  moveVideo,
  openProductsModal,
  setExpandedProduct,
}: Props) => {
  const { onDragOver, handleDragStart, handleDrop, draggingToIndex, draggingFromIndex } = useDrag({
    onDrop,
  });
  const { videos = [] } = rowData;
  const currentTop = style.top;
  const productId = rowData.productPage?.productId;
  const isOpen = productId === expandedProductId;
  const { getFeatureEnabled } = useFeatureActions();
  const [{ shopify: isShopify }] = useApps();
  const isAiVideoEnabled = getFeatureEnabled(FEATURE_AI_VIDEOS) && isShopify;

  function onDrop(index) {
    moveVideo({ index, draggingFromIndex, productId });
  }

  const onRowClick = e => {
    track('Dynamic Products Table Expand Row Click', analyticsData);
    setExpandedProduct(isOpen ? null : productId);
  };

  const onAddManualVideoClick = e => {
    e.stopPropagation();
    onAddManualVideo(rowData);
  };

  const onVideoClick = video => openProductsModal({ video, analyticsData });

  const onCreateAiVideoClick = e => {
    e.stopPropagation();
    track('Create AI Product Video from Project', { productId });
    Utils.navigateToStudio(productId);
  };

  const hasVideos = !!rowData.videos?.length;
  const lessThenOptimalVideos = rowData.videosNumber < OPTIMAL_PRODUCT_VIDEOS;
  const handleRemoveManualVideo = async video => onRemoveManualVideo({ video, rowData });
  const hasVideosOrNoManualAdd = hasVideos || !canAddManualVideo;
  const shouldShowCreateButton = isAiVideoEnabled && lessThenOptimalVideos;

  const renderVideoSection = () => {
    if (hasVideosOrNoManualAdd) {
      return shouldShowCreateButton ? (
        <ButtonsContainer>
          <Tooltip text="Create AI Product Video from Project">
            <VideoStudioButton onClick={onCreateAiVideoClick} label="Create" />
          </Tooltip>
          <CenteredTextTinyWithPadding>{rowData.videosNumber}</CenteredTextTinyWithPadding>
        </ButtonsContainer>
      ) : (
        <CenteredTextTiny>{rowData.videosNumber}</CenteredTextTiny>
      );
    } else {
      return (
        <ButtonsContainer>
          {isAiVideoEnabled && (
            <Tooltip text="Create AI Product Video from Project">
              <VideoStudioButton onClick={onCreateAiVideoClick} label="Create" />
            </Tooltip>
          )}
          <PlusButton onClick={onAddManualVideoClick} />
        </ButtonsContainer>
      );
    }
  };

  if (rowData.isLoading) {
    return (
      <VirtualizedRowContainer>
        <Spinner />
      </VirtualizedRowContainer>
    );
  }

  const row = (
    <VirtualizedRowContainer onClick={onRowClick}>
      <VirtualizedRowColumn width={firstColumnWidth}>
        <VirtualizedProductDetails
          imageUrl={rowData.productPage.imageUrl}
          title={rowData.productPage.name}
        />
      </VirtualizedRowColumn>
      <VirtualizedRowColumn width={tileWidth}>{renderVideoSection()}</VirtualizedRowColumn>
      <VirtualizedRowColumn width={50}>
        <StyledIconButton isOpen={isOpen}>
          <ExpanderTopArrowIcon />
        </StyledIconButton>
      </VirtualizedRowColumn>
    </VirtualizedRowContainer>
  );

  if (isOpen) {
    return (
      <RowContainer style={{ ...style, top: currentTop }}>
        {row}
        <VideosRow>
          {videos.map((videoPayload, index) => {
            const {
              video,
              isLocked,
              isHidden,
              isDisabled,
              isOutOfStock,
              hasManualVodConnection,
              shouldHideOOSProducts,
            } = videoPayload;

            return (
              <React.Fragment key={video.id}>
                {draggingToIndex === index && canOrder && !isDisabled && (
                  <div
                    onDragStart={e => handleDragStart(e, index)}
                    onDrop={e => handleDrop(e, index, isDisabled)}
                    onDragOver={e => onDragOver(e, index)}
                  />
                )}
                <StyledSortableElement
                  onDragStart={e => handleDragStart(e, index)}
                  onDrop={e => handleDrop(e, index, isDisabled)}
                  onDragOver={e => onDragOver(e, index)}
                >
                  <TableVideoCard
                    video={video}
                    isDragged={index === draggingFromIndex}
                    isDragDisabled={isDisabled}
                    isLocked={isLocked}
                    isHidden={isHidden}
                    isOutOfStock={isOutOfStock}
                    shouldHideOOSProducts={shouldHideOOSProducts}
                    hasManualVodConnection={hasManualVodConnection}
                    canHide={canHide}
                    canLock={canLock}
                    onClick={onVideoClick}
                    onRemove={canRemoveManualVideo ? () => handleRemoveManualVideo(video) : null}
                    hideVideo={() => hideVideo(video.id, productId)}
                    showVideo={() => showVideo(video.id, index, productId)}
                    lockVideo={() => lockVideo(video.id, index, productId)}
                    unlockVideo={() => unlockVideo(video.id, index, productId)}
                  />
                </StyledSortableElement>
              </React.Fragment>
            );
          })}
          {canAddManualVideo && (
            <AddNewVideoButton
              mode={AddNewVideoButtonModes.BigCard}
              onClick={onAddManualVideoClick}
            />
          )}
        </VideosRow>
      </RowContainer>
    );
  }

  return <RowContainer style={{ ...style, top: currentTop }}>{row}</RowContainer>;
};

const StyledIconButton = styled(HorizontalFlex)<{ isOpen: boolean }>`
  margin-right: 8px;
  justify-content: flex-end;

  svg {
    transition: 0.3s;
    transform: rotate(${({ isOpen }) => (isOpen ? 0 : 180)}deg);
  }
`;

const VideosRow = styled(VirtualizedVideosRowContainer)`
  height: 100%;
`;

const RowContainer = styled(VerticalFlex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutralLight};

  :not(button):last-of-type {
    border-bottom: none;
  }
`;

const CenteredTextTiny = styled(TextTiny)`
  text-align: center;
`;

const CenteredTextTinyWithPadding = styled(CenteredTextTiny)`
  padding: 0 16px;
`;

const StyledSortableElement = styled.div``;

const ButtonsContainer = styled(HorizontalFlex)`
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-right: 90px;
`;

export default DynamicProductsTableRow;
