import styled from 'styled-components';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextTiny } from 'shared/react/components/complex/Text';
import MenuItemV3, {
  CreateMenuItem,
  smallMenuItemCss,
} from 'app/src/complex_components/menu_items/MenuItemV3';

export const PageContainer = styled(HorizontalFlex)`
  flex-grow: 1;
  align-items: stretch;
  overflow: hidden;
`;

export const PagePanel = styled(VerticalFlex)`
  flex-shrink: 0;
  border-right: 1px solid ${({ theme }) => theme.colors.neutralLighter};
  padding: 8px;
  width: 200px;
  background: ${({ theme }) => theme.colors.white};
  overflow-y: auto;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    max-width: 150px;
    width: auto;
    padding: 4px;
  }
`;

export const PageSideBarMenuItemWithSelected = styled(MenuItemV3)`
  & path {
    fill: ${({ theme, isSelected }) =>
      isSelected ? theme.colors.primary : theme.colors.neutralGray};
  }
`;

export const PageSideBarMenuItem = styled(PageSideBarMenuItemWithSelected)`
  ${smallMenuItemCss}
`;

export const PageSideBarCreateMenuItem = styled(CreateMenuItem)`
  ${smallMenuItemCss}
`;

export const PanelTextItem = styled(TextTiny)`
  font-weight: 500;
  display: flex;
`;

export const PageHeader = styled(HorizontalFlex)`
  padding: 16px 24px;
  background: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutralLighter};
`;

export const PageMain = styled(VerticalFlex)`
  flex-grow: 1;
  overflow: hidden;
`;

export const OverflowContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  grid-auto-rows: 206px;
  gap: 24px;
  padding: 24px;
`;
