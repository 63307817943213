import React, { useRef } from 'react';
import styled from 'styled-components';

import { VerticalFlexCentered } from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import SearchIcon from 'shared/react/images/feed/SearchIcon';

const FeedSearchInput = ({ onSubmit, searchTerm, setSearchTerm, isMobile }) => {
  const inputRef = useRef(null);

  const handleSubmitInput = e => {
    e.preventDefault();

    if (!searchTerm) {
      return;
    }

    onSubmit(searchTerm);
    inputRef.current?.blur();
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleSubmitInput(event);
    }
  };

  return (
    <LayoutRoot onSubmit={handleSubmitInput}>
      <SearchContainer>
        <IconWrapper>
          <SearchIcon />
        </IconWrapper>
        <StyledInput
          enterKeyHint="search"
          ref={inputRef}
          type="text"
          autoFocus={!isMobile}
          placeholder="Search"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          onKeyDown={handleKeyPress}
        />
      </SearchContainer>
      <SearchButton type="submit">Search</SearchButton>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.form`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const SearchContainer = styled(VerticalFlexCentered)`
  flex: 1;
  width: 100%;
  border: none;
  background-color: ${props => props.theme.colors.neutralLightest};
  border-radius: 8px;
  padding: 5px 10px;
`;

const StyledInput = styled.input`
  border: none;
  width: 100%;
  box-sizing: border-box;
  padding-left: 35px;
  background-color: ${props => props.theme.colors.neutralLightest};
  height: 20px;
  font-size: 16px;

  &:focus {
    outline: none;
    font-size: 16px;
  }

  &::placeholder {
    color: ${props => props.theme.colors.neutralDark};
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 10px;
  display: flex;
  align-items: center;

  & > svg {
    width: 16px;
    height: 16px;
  }
`;

const SearchButton = styled.button`
  color: ${props => props.theme.colors.red2};
  background: transparent;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;

  :disabled {
    pointer-events: none;
    cursor: default;
  }
`;

export default FeedSearchInput;
