import React, { useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import DesignStoriesPreviewStoryName from 'app/src/pages/project/pages/look_and_feel/preview/feed_stories_preview/DesignStoriesPreviewStoryName';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import DefaultTilePreview from 'app/src/images/default_tile_preview.png';
import { useVideos } from 'app/src/context/VideosStore';
import {
  DEFAULT_STORIES_SIZE,
  STORIES_BORDER_TYPES,
  STORIES_ITEM_MAX_FIXED_SIZE,
  STORIES_ITEMS_SHAPE_TYPES,
  STORIES_ITEMS_SIZE_TYPES,
} from '../../LookAndFeelPage.constants';

type Props = {
  storiesBorderRadius?: number;
  storiesBorderEnabled?: boolean;
  storiesBorderWidth?: number;
  storiesBorderColor?: string;
  storiesBorderType?: string;
  storiesBorderOpacity?: number;
  storiesStoryNameEnabled?: boolean;
  storiesStoryNameFontSize?: number;
  storiesStoryNameColor?: string;
  storiesItemsSizeType: string;
  storiesCircleSize?: number;
  partName: string;
  avatarUrl: string;
  size: number;
  videosRefs?: any;
  index?: number;
  isEditingThumbnail: boolean;
  video: any;
  storiesRectangleWidth?: number;
  storiesRectangleHeight?: number;
  storiesItemsShape?: string;
};

const DesignStoriesPreviewTile = ({
  storiesBorderRadius = 50,
  storiesBorderEnabled = true,
  storiesBorderWidth = 2,
  storiesBorderColor,
  storiesBorderType = STORIES_BORDER_TYPES.dashed,
  storiesBorderOpacity = 1,
  storiesStoryNameEnabled,
  storiesStoryNameFontSize,
  storiesStoryNameColor,
  storiesItemsSizeType,
  storiesRectangleWidth,
  storiesRectangleHeight,
  storiesItemsShape,
  storiesCircleSize = 80,
  partName,
  avatarUrl,
  size,
  videosRefs = { current: [] },
  index = 0,
  isEditingThumbnail,
  video,
}: Props) => {
  const theme = useTheme();
  const [hasErrorOccurred, setErrorOccurred] = useState(false);
  const [, { getVideoUrl, handleVideoLoadError }] = useVideos();
  const [videoUrl, setVideoUrl] = useState(getVideoUrl(video, '_640'));

  const onVideoError = () => {
    handleVideoLoadError(video, videoUrl, setVideoUrl, true);
  };

  const getBorder = () => {
    const color = theme.getHexOpacity(
      storiesBorderColor || theme.colors.pink9,
      storiesBorderOpacity * 100
    );

    if (isEditingThumbnail) {
      return `${(storiesBorderWidth * 2) / size}px ${STORIES_BORDER_TYPES.solid} ${color}`;
    }

    if (!storiesBorderEnabled) {
      return '';
    }

    return `${storiesBorderWidth / size}px ${storiesBorderType} ${color}`;
  };

  const getProportionalBorderRadius = () => {
    if (storiesItemsShape === STORIES_ITEMS_SHAPE_TYPES.rectangle) {
      return storiesBorderRadius;
    }

    if (storiesItemsSizeType === STORIES_ITEMS_SIZE_TYPES.responsive) {
      return storiesBorderRadius / size;
    }

    return (storiesBorderRadius / size) * (storiesCircleSize / DEFAULT_STORIES_SIZE);
  };

  const onError = () => {
    if (hasErrorOccurred) {
      return;
    }

    onVideoError();
    setErrorOccurred(true);
  };

  const border = getBorder();
  const proportionalBorderRadius = getProportionalBorderRadius();

  return (
    <LayoutRoot size={size} storiesCircleSize={storiesCircleSize}>
      <ImageAndBackground
        $storiesRectangleHeight={storiesRectangleHeight}
        $storiesRectangleWidth={storiesRectangleWidth}
        $storiesItemsShape={storiesItemsShape}
        $size={size}
        $storiesCircleSize={storiesCircleSize}
        $storiesItemsSizeType={storiesItemsSizeType}
      >
        <ImageContainer
          $storiesRectangleHeight={storiesRectangleHeight}
          $storiesRectangleWidth={storiesRectangleWidth}
          $storiesItemsShape={storiesItemsShape}
          $size={size}
          $storiesCircleSize={storiesCircleSize}
          $storiesItemsSizeType={storiesItemsSizeType}
        >
          <BackgroundImage
            src={DefaultTilePreview}
            onError={onError}
            $borderRadius={proportionalBorderRadius}
            $storiesBorderEnabled={storiesBorderEnabled}
            $border={border}
          />
        </ImageContainer>
        <ImageContainer
          $storiesRectangleHeight={storiesRectangleHeight}
          $storiesRectangleWidth={storiesRectangleWidth}
          $storiesItemsShape={storiesItemsShape}
          $size={size}
          $storiesCircleSize={storiesCircleSize}
          $storiesItemsSizeType={storiesItemsSizeType}
        >
          <Video
            $borderRadius={proportionalBorderRadius}
            $border={border}
            src={videoUrl}
            muted={true}
            ref={videoRef => (videosRefs.current[index] = videoRef)}
            onError={onError}
            poster={avatarUrl}
          />
        </ImageContainer>
      </ImageAndBackground>
      <DesignStoriesPreviewStoryName
        storiesStoryNameEnabled={storiesStoryNameEnabled}
        storiesStoryNameFontSize={storiesStoryNameFontSize}
        storiesStoryNameColor={storiesStoryNameColor}
        partName={partName}
        size={size}
      />
    </LayoutRoot>
  );
};

const sizeCss = css<{
  $size: number;
  $storiesItemsShape: string;
  $storiesRectangleWidth: number;
  $storiesRectangleHeight: number;
  $storiesCircleSize: number;
  $storiesItemsSizeType: string;
}>`
  ${({
    $size,
    $storiesItemsShape,
    $storiesRectangleWidth,
    $storiesRectangleHeight,
    $storiesCircleSize,
    $storiesItemsSizeType,
  }) => {
    if ($storiesItemsShape === STORIES_ITEMS_SHAPE_TYPES.circle) {
      return css`
        height: ${$storiesCircleSize / $size}px;
        width: ${$storiesCircleSize / $size}px;
      `;
    }

    if ($storiesItemsSizeType === STORIES_ITEMS_SIZE_TYPES.responsive) {
      return css`
        aspect-ratio: 9/16;
      `;
    }

    return css`
      width: ${$storiesRectangleWidth}px;
      height: ${$storiesRectangleHeight}px;
    `;
  }}}
`;

const LayoutRoot = styled(VerticalFlex)`
  gap: ${({ size }) => 8 / size}px;
  width: 100%;
  height: 100%;
`;

const Video = styled.video<{ $borderRadius: number; $border: string }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 0;
  border-radius: ${({ $borderRadius }) => $borderRadius}px;
  border: ${({ $border }) => $border};
  transition: 0.3s;
  z-index: 3;
  aspect-ratio: 1;
`;

const BackgroundImage = styled.img<{
  $storiesBorderEnabled: boolean;
  $borderRadius: number;
  $border: string;
}>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: ${({ $storiesBorderEnabled }) => ($storiesBorderEnabled ? '2px' : '')};
  border-radius: ${({ $borderRadius }) => $borderRadius}px;
  border: ${({ $border }) => $border};
  transition: 0.3s;
  aspect-ratio: 1;
  z-index: 2;
`;

const ImageAndBackground = styled.div`
  display: grid;
  max-height: ${({ $size }) => STORIES_ITEM_MAX_FIXED_SIZE / $size}px;
  max-width: ${({ $size }) => STORIES_ITEM_MAX_FIXED_SIZE / $size}px;
  box-sizing: border-box;
  ${sizeCss}
`;

const ImageContainer = styled(VerticalFlex)`
  max-height: ${({ $size }) => STORIES_ITEM_MAX_FIXED_SIZE / $size}px;
  max-width: ${({ $size }) => STORIES_ITEM_MAX_FIXED_SIZE / $size}px;
  grid-column: 1;
  grid-row: 1;
  box-sizing: border-box;
  ${sizeCss};
`;

export default DesignStoriesPreviewTile;
