import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DashedInput from 'app/src/basic_components/DashedInput';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { useDebounce } from 'app/src/hooks/useDebounce';
import { DEBOUNCE_TIME } from 'app/src/constants/builder.constants';

const AnswerEdit = ({ value, updateUrl, index }) => {
  const [isEditLinkDisabled, setEditLinkDisabled] = useState(true);
  const [inputValue, setInputValue] = useState(value || '');
  const debouncedInputValue = useDebounce(inputValue, DEBOUNCE_TIME);

  const onChange = e => {
    setInputValue(e.target.value);
  };

  const onClick = () => {
    if (isEditLinkDisabled) {
      setEditLinkDisabled(false);
    }
  };

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  useEffect(() => {
    if (isEditLinkDisabled) {
      setEditLinkDisabled(false);
    }

    updateUrl(debouncedInputValue, index);
  }, [debouncedInputValue]);

  return (
    <LayoutRoot>
      <TitleTextField
        value={inputValue}
        placeholder={value}
        onChange={onChange}
        onClick={onClick}
        maxLength="100"
        isDisabled={isEditLinkDisabled}
      />
    </LayoutRoot>
  );
};

export default AnswerEdit;

const LayoutRoot = styled(HorizontalFlexCentered)`
  margin-left: 48px;
`;

const TitleTextField = styled(DashedInput)`
  font-style: normal;
  font-size: 16px;
  color: ${({ theme, isDisabled }) => (isDisabled ? theme.colors.gray4 : theme.colors.black)};
  border: 1px dashed ${({ theme }) => theme.colors.primaryDark};
  direction: rtl;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 400;

  &:focus {
    direction: ltr;
  }

  &&::placeholder {
    font-size: 14px;
    font-weight: 400;
  }
`;
