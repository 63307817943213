import React from 'react';

const CalendarIcon = ({ fill, darkBackground }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0015 19.7221C15.371 19.7221 19.7238 15.3693 19.7238 9.99984C19.7238 4.63039 15.371 0.277588 10.0015 0.277588C4.6321 0.277588 0.279297 4.63039 0.279297 9.99984C0.279297 15.3693 4.6321 19.7221 10.0015 19.7221Z"
        fill={fill || '#FFFFFF'}
      />
      <path
        d="M13.6465 5.41659H12.735V4.50513C12.735 4.2089 12.4844 3.95825 12.1882 3.95825C11.8692 3.95825 11.6413 4.2089 11.6413 4.50513V5.41659H8.35998V4.50513C8.35998 4.2089 8.10938 3.95825 7.81315 3.95825C7.49414 3.95825 7.26628 4.2089 7.26628 4.50513V5.41659H6.35482C5.5345 5.41659 4.89648 6.07739 4.89648 6.87492V14.1666C4.89648 14.9869 5.5345 15.6249 6.35482 15.6249H13.6465C14.444 15.6249 15.1048 14.9869 15.1048 14.1666V6.87492C15.1048 6.07739 14.444 5.41659 13.6465 5.41659ZM13.6465 14.5312H6.35482C6.14974 14.5312 5.99023 14.3717 5.99023 14.1666V8.33325H14.0111V14.1666C14.0111 14.3717 13.8288 14.5312 13.6465 14.5312Z"
        fill={darkBackground ? '#FFFFFF' : '#2F3137'}
      />
    </svg>
  );
};

export default CalendarIcon;
