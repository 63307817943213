export const getProductCouldHaveVariants = product =>
  !['magento', 'productsImport'].includes(product?.ecomPlatform);

export const getNewVariant = (product, productOptions) => {
  if (!getProductCouldHaveVariants(product)) {
    return product.variants[0];
  }

  return product.variants.find(({ selectedOptions }) => {
    return Object.entries(productOptions).every(([position, { selectedValue }]) => {
      return selectedOptions[position] === selectedValue;
    });
  });
};

export const getProductOptionsFromVariant = (product, variant) => {
  if (!getProductCouldHaveVariants(product)) {
    return product.options;
  }

  if (!variant) {
    return product.options;
  }

  const updatedProductOptions = Object.entries(product.options).reduce(
    (acc, [position, option]) => {
      return {
        ...acc,
        [position]: { ...option, selectedValue: variant.selectedOptions[position] },
      };
    },
    {}
  );

  return updatedProductOptions;
};

export const getProductsWithVariantsFromStep = (step, options = {}) => {
  const { priorityProductId } = options;

  return (step?.products || []).reduce((acc, product) => {
    if (!product) {
      return acc;
    }

    const isPriorityProduct = product.id === priorityProductId;

    if (!product.variantIds?.length) {
      return isPriorityProduct ? [product, ...acc] : [...acc, product];
    }

    const variants = product.variantIds?.map(variantId => ({ id: product.id, variantId }));

    return isPriorityProduct ? [...variants, ...acc] : [...acc, ...variants];
  }, []);
};

export const isUrlProvider = provider => {
  return provider === 'url';
};

export const isProductsImportProvider = provider => {
  return provider === 'productsImport';
};
