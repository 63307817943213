import React from 'react';
import styled, { useTheme } from 'styled-components';
import classNames from 'classnames';
import { ANSWERS_CONTAINER_DATA_TEST_ID } from '../../../constants/dataTestIds.constants';
import { closePlayerInsideIntercomChat } from '../player.utils';
import ResponseAnswers from '../response-answers/ResponseAnswers';
import AnswerButtonInputWrapper from '../answer-buttons/AnswerButtonInputWrapper';
import ActionButton from '../../ActionButton';
import HorizontalFlexWrap from '../../../../../shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import {
  AUDIO_RESPONSE_KEY,
  BUBBLE,
  CALENDLY_RESPONSE_KEY,
  CHAT_RESPONSE_KEY,
  FREE_TEXT_RESPONSE_KEY,
  LEAD_FORM_RESPONSE_KEY,
  OPEN_INTERCOM_RESPONSE_KEY,
  VIDEO_RESPONSE_KEY,
  CHAT_PLATFORMS,
} from '../../../constants/tolstoy.constants';
import Utils from '../../../utils';
import { isAnyResponse, postMessage } from '../controls/controls.utils';
import { getCustomFormData } from 'player/src/utils/custom-form.utils';
import { getLogoIcon } from 'shared/react/utils/icons';

const MAX_NUMBER_OF_ANSWERS_FOR_CIRCLE_BUTTONS = 4;

const circleButtonResponseKeys = [
  AUDIO_RESPONSE_KEY,
  VIDEO_RESPONSE_KEY,
  FREE_TEXT_RESPONSE_KEY,
  CALENDLY_RESPONSE_KEY,
];

function AnswersSection({
  layout,
  controlsShown,
  elementsCount,
  handlePlayPause,
  hasSubtitles,
  selectedVideo,
  wide,
  isChatLandingPage,
  answerKey,
  selectedAnswerKeys,
  customLayout,
  isMovingToNextStep,
  isDefaultResponseEnabled,
  setImageFile,
  setMovingToNextStep,
  transitioningFromVideoId,
  promoCode,
  setPromoCode,
  pause,
  setCalendlyWidgetSettings,
  setControlsShown,
  setNextStep,
  setNextStepType,
  answerRef,
  setSelectedAnswerKeys,
  trackClick,
  setAnswerKey,
  collectInfo,
  shouldOpenLeadFormAfterStep,
  setGoToAfterLeadFormStep,
  setIsIntegrationLoading,
  projectId,
  collectInfoForm,
  version,
  moveTo,
  playerType,
  brandColor,
  borderRadius,
  buttonsSettings,
  hidePoweredBy,
  showButtonsIndex,
  onKeyDown,
  setNextRef,
}) {
  const isMobile = Utils.isMobile() || Utils.isIphone();
  const isWidget = playerType === BUBBLE;
  const theme = useTheme();

  const useCircleButtons =
    isDefaultResponseEnabled ||
    (isDefaultResponseEnabled === undefined &&
      selectedVideo.answers.length > 0 &&
      selectedVideo.answers.length <= MAX_NUMBER_OF_ANSWERS_FOR_CIRCLE_BUTTONS &&
      selectedVideo.answers.every(answer => circleButtonResponseKeys.includes(answer.type)));

  const isMultipleChoiceSelection = selectedVideo?.multipleChoiceSelection || false;
  const filteredDefaultResponses = selectedVideo?.defaultResponses?.filter(res => {
    return res.disabled === false;
  });

  function onImageSelected(file) {
    setImageFile(file);
  }

  function checkPromoCode(answer) {
    if (promoCode) {
      return;
    }
    if (answer.type === 'PromoCode') {
      setPromoCode(answer.value);
    }
  }

  function showCalendlyWidget(next, value) {
    pause();
    setCalendlyWidgetSettings({ next, value });
    setControlsShown(true);
  }

  const openModal = (next, type) => {
    pause();
    setNextStep(next);
    setNextStepType(type);
    setControlsShown(true);
  };

  function handleCompleteClick(buttonRef) {
    if (!selectedAnswerKeys?.length) {
      return;
    }

    setMovingToNextStep(true);

    const selectedAnswers = selectedAnswerKeys.map(selectedAnswerKey =>
      selectedVideo.answers.find(answer => answer.key === selectedAnswerKey)
    );

    const answersArray = [];
    let mergedText = '';
    let mergedAnswerKeys = '';
    let next = null;

    selectedAnswers.forEach((selectedAnswer, i) => {
      let isLastElement = i === selectedAnswers.length - 1;
      next = selectedAnswer.next;
      let text = selectedAnswer.text;
      let key = selectedAnswer.key;
      answersArray.push({
        text,
        key,
      });
      mergedText = mergedText.concat(text).concat(isLastElement ? '' : ', ');
      mergedAnswerKeys = mergedAnswerKeys.concat(key).concat(isLastElement ? '' : ', ');
    });

    trackClick(mergedText, next, 'multiChoiceSelection', mergedAnswerKeys, {
      answers: answersArray,
    });

    const includesAfterStep = collectInfo?.afterStep?.includes(selectedVideo.key);
    if (shouldOpenLeadFormAfterStep(includesAfterStep, collectInfo?.afterStep)) {
      setGoToAfterLeadFormStep(next);
      collectInfoForm(next, buttonRef.current);
      return;
    }

    moveTo(next);
  }

  async function handleCtaClick(video, answer, buttonRef) {
    if (answerKey) {
      return;
    }
    answerRef.current = answer;
    const { type, text, next, value, key: clickedAnswerKey } = answer;
    if (video.multipleChoiceSelection) {
      if (selectedAnswerKeys.includes(clickedAnswerKey)) {
        setSelectedAnswerKeys(
          selectedAnswerKeys.filter(selectedAnswer => selectedAnswer !== clickedAnswerKey)
        );
      } else {
        setSelectedAnswerKeys(oldSelectedAnswers => [...oldSelectedAnswers, clickedAnswerKey]);
      }

      return;
    }

    if (transitioningFromVideoId) {
      return;
    }
    setAnswerKey(clickedAnswerKey);
    checkPromoCode(answer);
    trackClick(text, next, type, clickedAnswerKey);
    const includesAfterStep = collectInfo?.afterStep?.includes(video.key);

    if (shouldOpenLeadFormAfterStep(includesAfterStep, collectInfo?.afterStep)) {
      setGoToAfterLeadFormStep(next);
    }

    const customFormData = await getCustomFormData({ projectId, clickedAnswerKey });

    if (isAnyResponse(type)) {
      openModal(next, type);
    } else if (type === CALENDLY_RESPONSE_KEY) {
      showCalendlyWidget(next, value);
    } else if (type === LEAD_FORM_RESPONSE_KEY || includesAfterStep || customFormData) {
      const isCustom = includesAfterStep ? false : !!customFormData;
      collectInfoForm(next, buttonRef.current, isCustom);
    } else if (type === CHAT_RESPONSE_KEY || next === CHAT_RESPONSE_KEY) {
      postMessage(CHAT_PLATFORMS[value], answer);
      setIsIntegrationLoading(true);
      if (value === OPEN_INTERCOM_RESPONSE_KEY) {
        closePlayerInsideIntercomChat();
      }
    } else if (next) {
      moveTo(next, buttonRef.current);
    }
  }

  const textColor = Utils.getButtonTextColor({
    color: brandColor,
    theme,
    defaultColor: theme.colors.blue12,
  });
  const shouldAddChatLandingPageMargin =
    !isMultipleChoiceSelection && isChatLandingPage && isMobile && !isWidget;
  const hasAnswersWithImages = selectedVideo.answers
    .filter(answer => !answer.disabled)
    .find(answer => {
      return getLogoIcon(answer.type || '', brandColor, answer.next);
    });

  return (
    <div
      className={classNames(`controls-${layout}`, {
        'controls-back': controlsShown,
        'controls-back-with-subtitles': hasSubtitles,
        'controls-none': !controlsShown,
        'controls-bottom': wide,
        'controls-multiline': elementsCount > 3,
      })}
      onClick={handlePlayPause}
    >
      <AnswersContainer
        noSubtitles={!hasSubtitles && !isMultipleChoiceSelection}
        useCircleButtons={useCircleButtons}
        hidePoweredBy={hidePoweredBy}
        shouldAddChatLandingPageMargin={shouldAddChatLandingPageMargin}
        data-test-id={ANSWERS_CONTAINER_DATA_TEST_ID}
        aria-label="Choices"
        role="group"
      >
        {useCircleButtons ? (
          <ResponseAnswers
            disabled={!!answerKey}
            answers={isDefaultResponseEnabled ? filteredDefaultResponses : selectedVideo.answers}
            setNextRef={setNextRef}
            onKeyDown={onKeyDown}
            onClick={(buttonRef, answer) => {
              handleCtaClick(selectedVideo, answer, buttonRef);
            }}
          />
        ) : (
          selectedVideo.answers
            .filter(answer => !answer.disabled)
            .map((answer, index) => (
              <AnswerButtonInputWrapper
                id={index}
                disabled={!!answerKey}
                index={index}
                key={index}
                setNextRef={setNextRef}
                onKeyDown={onKeyDown}
                selected={selectedAnswerKeys.includes(answer.key)}
                isMultipleChoiceSelection={isMultipleChoiceSelection}
                wide={wide}
                onImageSelected={onImageSelected}
                stepAnswersCount={elementsCount}
                hasAnswersWithImages={hasAnswersWithImages}
                onClick={buttonRef => {
                  handleCtaClick(selectedVideo, answer, buttonRef);
                }}
                selectedVideo={selectedVideo}
                answer={answer}
                hasSubtitles={hasSubtitles}
                customLayout={customLayout}
                version={version}
                brandColor={brandColor}
                showButtonsIndex={showButtonsIndex}
                buttonsSettings={buttonsSettings}
                borderRadius={borderRadius}
              />
            ))
        )}
      </AnswersContainer>
      {isMultipleChoiceSelection && (
        <CompleteButtonContainer>
          <ActionButton
            newVersion={version === 'groupB'}
            disabled={!selectedAnswerKeys.length}
            isLoading={isMovingToNextStep}
            onClick={buttonRef => handleCompleteClick(buttonRef)}
            brandColor={brandColor}
            textColor={textColor}
            borderRadius={borderRadius}
          >
            Complete
          </ActionButton>
        </CompleteButtonContainer>
      )}
    </div>
  );
}

export default AnswersSection;

const AnswersContainer = styled(HorizontalFlexWrap)`
  justify-content: center;
  align-items: stretch;
  width: 100%;
  max-width: 916px;
  margin: ${({ shouldAddChatLandingPageMargin }) =>
    shouldAddChatLandingPageMargin ? '0 auto 48px 0' : '0 auto 0px'};
  max-height: 300px;
  overflow-y: ${({ useCircleButtons }) => (useCircleButtons ? 'hidden' : 'auto')};
  ${({ useCircleButtons }) => (useCircleButtons ? 'padding: 4px 0' : '')};
  padding-bottom: ${({ useCircleButtons }) => (useCircleButtons ? '32px' : '28px')};
  grid-area: answers-section;

  @media (${({ theme }) => theme.breakpoints.laptopMax}) {
    max-width: 800px;
  }

  @media only screen and (max-width: 450px), screen and (hover: none) and (pointer: coarse) {
    max-height: 240px;
    white-space: nowrap;
    overflow: ${({ useCircleButtons }) => (useCircleButtons ? 'initial' : 'auto')};
  }
`;

const CompleteButtonContainer = styled.div`
  align-self: center;
  margin-top: 16px;
  margin-bottom: 30px;
  z-index: 100000000;
`;
