import { IS_DEV } from 'src/config/app.config';
import { APPROVAL_URL } from 'src/constants/ugc';
import {
  UGC_INSTAGRAM_GRAPH,
  UGC_INSTAGRAM_GRAPH_PENDING,
} from 'src/constants/intergrations.constants';
import { UgcRequestStep } from './UgcRequest.constants';

export const getPrimaryButtonText = ({
  step,
  username,
}: {
  step: UgcRequestStep;
  username?: string;
}) => {
  if ([UgcRequestStep.Pending, UgcRequestStep.Request].includes(step)) {
    return username ? 'Copy & go to profile' : 'Copy & go to post';
  }

  return '';
};

export const getStep = ({ video, isRequestSent }) => {
  if (video?.uploadType === UGC_INSTAGRAM_GRAPH) {
    return UgcRequestStep.Done;
  }

  if (video?.uploadType === UGC_INSTAGRAM_GRAPH_PENDING || isRequestSent) {
    return UgcRequestStep.Pending;
  }

  return UgcRequestStep.Request;
};

export const getDmUrl = (username: string) => {
  return `https://ig.me/m/${username}`;
};

export const getCurrentDate = (): string => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const getDefaultMessage = (username: string) => {
  return `Hey${
    username ? ' @' + username : ''
  }, we love this story and we’d love to use it on our website and selling channels. If you’re open to it, give us approval through this link. Thank you️!`;
};

export const parseMessage = (message: string, username: string) => {
  const usenameHandle = username ? `@${username}` : '';
  return message.replace('{{username}}', usenameHandle);
};

export const getApprovalLink = ({ externalId, appKey }: { externalId: string; appKey: string }) => {
  return `${APPROVAL_URL}?external_id=${externalId}&app_key=${appKey}&isDev=${IS_DEV}`;
};
