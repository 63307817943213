import React from 'react';

function NotificationsIcon({ color: fill = '#50545E' }) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.719 13.344c-.594-.656-1.75-1.625-1.75-4.844 0-2.406-1.688-4.344-3.969-4.844V3c0-.531-.469-1-1-1-.563 0-1 .469-1 1v.656c-2.313.5-4 2.438-4 4.844 0 3.219-1.156 4.188-1.75 4.844A.915.915 0 0 0 3 14c0 .531.406 1 1 1h11.969c.625 0 1-.469 1.031-1a.953.953 0 0 0-.281-.656ZM5.094 13.5C5.75 12.625 6.5 11.187 6.5 8.531V8.5C6.5 6.594 8.063 5 10 5c1.906 0 3.5 1.594 3.5 3.5v.031c0 2.656.719 4.094 1.375 4.969H5.094ZM10 18c1.094 0 2-.875 2-2H8c0 1.125.875 2 2 2Z"
        fill={fill}
      />
    </svg>
  );
}

export default NotificationsIcon;
