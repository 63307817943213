import React from 'react';
import styled from 'styled-components';
import Utils from 'shared/react/utils/utils';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

type Props = {
  duration: number;
};

const VideoCardTime = ({ duration }: Props) => {
  if (!duration) {
    return null;
  }

  return <LayoutRoot>{Utils.secondsToTimeString(duration, false)}</LayoutRoot>;
};

const LayoutRoot = styled(HorizontalFlexCentered)`
  position: absolute;
  height: 16px;
  width: 30px;
  bottom: 8px;
  right: 8px;
  border-radius: 4px;
  align-items: center;
  z-index: 2;
  background-color: ${({ theme }) => theme.getHexOpacity(theme.colors.black, 50)};
  color: ${({ theme }) => theme.colors.white};
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
`;

export default VideoCardTime;
