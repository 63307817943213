import React from 'react';
import TopBar from 'src/pages/dashboard/components/top-bar/TopBar';

/*
  Placeholder for future
*/
export const UgcPendingTopBar = () => {
  return <TopBar />;
};

export default UgcPendingTopBar;
