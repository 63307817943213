import React from 'react';

const FilledVideoIcon = ({ fill = '#FA536E' }) => {
  return (
    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 1.5c0-.813-.688-1.5-1.5-1.5h-9A1.5 1.5 0 0 0 0 1.5v9c0 .844.656 1.5 1.5 1.5h9a1.5 1.5 0 0 0 1.5-1.5v-9Zm6 .5c0-.781-.938-1.25-1.594-.813L13 3.563V8.47l3.406 2.375c.657.437 1.594-.031 1.594-.813V2Z"
        fill={fill}
      />
    </svg>
  );
};

export default FilledVideoIcon;
