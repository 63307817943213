import React from 'react';
import styled from 'styled-components';
import { HorizontalFlexCentered } from '../complex/flex_layouts/HorizontalFlex';

const BadgeNumber = ({ value }) => {
  if (!value || value === 0) {
    return null;
  }

  return <LayoutRoot>{value}</LayoutRoot>;
};

const LayoutRoot = styled(HorizontalFlexCentered)`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.success};
`;

export default BadgeNumber;
