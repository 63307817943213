export class CustomEventManager {
  constructor() {
    this.listeners = new Map();
  }

  _getListeners(eventName) {
    if (!this.listeners.has(eventName)) {
      this.listeners.set(eventName, new Set());
    }

    return this.listeners.get(eventName);
  }

  addEventListener(eventName, listener) {
    if (!eventName || typeof listener !== 'function') {
      return;
    }

    this._getListeners(eventName).add(listener);
  }

  removeEventListener(eventName, listener) {
    this._getListeners(eventName).delete(listener);
  }

  emitEvent(eventName, eventData) {
    const listeners = this._getListeners(eventName);

    listeners.forEach(listener => {
      listener(eventData);
    });
  }
}

export default CustomEventManager;
