import React from 'react';

const PenIcon = () => (
  <svg width={14} height={13} viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.664.945A1.533 1.533 0 0 0 10.61.5c-.375 0-.773.164-1.054.445l-7.5 7.5c-.211.211-.352.47-.422.75L1 12.055a.374.374 0 0 0 .352.445h.093s1.97-.422 2.86-.633c.28-.047.515-.187.726-.398l7.524-7.524a1.485 1.485 0 0 0 0-2.11l-.89-.89Zm-7.43 9.727c-.046.07-.117.094-.187.117-.422.094-1.102.234-1.688.375l.375-1.734c.024-.07.047-.14.118-.188L8.5 3.57 9.93 5l-5.696 5.672Z"
      fill="#090A0B"
    />
  </svg>
);

export default PenIcon;
