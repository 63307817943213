import React from 'react';
import { track } from 'app/src/helpers/Tracker';
import ResetToDefaultInputWrapper from 'app/src/complex_components/ResetToDefaultInputWrapper';
import EditorTextInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorTextInputItem';

type Props = {
  text: string;
  defaultText?: string;
  disabled?: boolean;
  onChange?: any;
};

const StartScreenEditor = ({
  text,
  defaultText = '',
  disabled = false,
  onChange = () => {},
}: Props) => {
  const handleResetToDefault = () => {
    track('Branding Reset To Default', { property: 'startScreenText' });
    onChange(defaultText);
  };

  return (
    <>
      <ResetToDefaultInputWrapper disabled={disabled} onClick={handleResetToDefault}>
        <EditorTextInputItem
          text="Start screen text"
          disabled={disabled}
          value={text}
          placeholder="Begin an interactive conversation"
          onChange={onChange}
          maxLength={200}
        />
      </ResetToDefaultInputWrapper>
    </>
  );
};

export default StartScreenEditor;
