import { useRules } from 'app/src/context/RulesStore';
import { project as Project, RuleGroupType } from 'app/src/types/entities';
import { DynamicTypeFormValues } from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/DynamicTypeContent';
import { getRules } from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/helpers/getRulesArray';

export const useSaveRules = (project: Project) => {
  const [{ rules: currentRuleGroup }, { saveRules: createOrUpdateRules }] = useRules();

  return async (values: DynamicTypeFormValues) => {
    const rules = getRules(values);
    let ruleGroup = { rules, enabled: true };
    if (currentRuleGroup?.projectId === project.id) {
      ruleGroup = { ...currentRuleGroup, rules, enabled: true };
    }

    return createOrUpdateRules(ruleGroup, project, RuleGroupType.dynamic);
  };
};

export default useSaveRules;
