import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { vodAsset as VodAsset } from 'src/types/entities';
import { VideoRenditionsService } from 'shared/react/services/assets/videos';
import { VIDEO_STATUS } from 'shared/react/constants/video.constants';
import { VerticalFlexCentered } from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import LoadingVideo from 'src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/video_preview/LoadingVideo';
import { AssetDimensionsCss } from './asset.styles';
import Utils from 'app/src/utils';
import { VideoAnimatedCaptions } from 'shared/video-captions/animated';

type Props = {
  video: VodAsset;
  shouldHide?: boolean;
};

const VALID_VIDEO_STATUS = [VIDEO_STATUS.done, VIDEO_STATUS.migrating];

const ModalVideoPreview = ({ video, shouldHide, thumbnail }: Props) => {
  const videoRef = useRef(null);
  const [animatedCaptions, setAnimatedCaptions] = React.useState(false);

  useEffect(() => {
    const includesCaptions = Utils.safeParse(video?.captionsSettings);
    if (!videoRef.current || animatedCaptions || !includesCaptions.enabled) {
      return;
    }

    const url = VideoRenditionsService.getVideoUrl(video);
    const captions = new VideoAnimatedCaptions({
      videoElement: videoRef.current,
      captionsUrl: url.replace('mp4', 'vtt'),
    });
    setAnimatedCaptions(captions);
  }, [videoRef.current]);

  if (shouldHide) {
    return null;
  }

  if (!video || (!VALID_VIDEO_STATUS.includes(video?.status) && !video?.stockAsset?.videoUrl)) {
    return (
      <LoadingContainer>
        <StyledLoadingVideo poster={thumbnail} />
        <Image src={thumbnail} />
      </LoadingContainer>
    );
  }

  return (
    <VideoContainer>
      <Video
        loop
        controls
        muted
        autoPlay
        src={VideoRenditionsService.getVideoUrl(video)}
        ref={videoRef}
        poster={VideoRenditionsService.getVideoPosterLarge(video)}
      />
    </VideoContainer>
  );
};

const Video = styled.video`
  ${AssetDimensionsCss};
  object-fit: contain;
  background: ${({ theme }) => theme.colors.black};

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    display: none;
  }
`;

const LoadingContainer = styled(VerticalFlexCentered)`
  ${AssetDimensionsCss};
  background: ${({ theme }) => theme.colors.neutralLighter};
  position: relative;
`;

const VideoContainer = styled(LoadingContainer)``;

const StyledLoadingVideo = styled(LoadingVideo)`
  text-align: center;
  align-items: center;
  backdrop-filter: blur(5px);
  z-index: 1;
  padding: 40px 32px 16px;
  border-radius: 4px;
  background: ${({ theme }) => theme.getHexOpacity(theme.colors.white, 20)};
  gap: 32px;

  path {
    fill: ${({ theme }) => theme.colors.white};
  }

  div {
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.white};
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: fill;
  position: absolute;
`;

export default ModalVideoPreview;
