import React from 'react';
import styled from 'styled-components';

import { PrimaryButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { TextSmall } from 'shared/react/components/basic/text/TextV2';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import AppEmbedInstallationSrc from 'app/src/images/AppEmbedInstallation.png';
import ShopifyLogoIcon from 'src/images/creation_method_icons/ShopifyLogoIcon';
import RedirectToLink from 'app/src/images/RedirectToLink';
import Utils from 'src/utils';
import { LIQUID_FILENAMES } from 'shared/react/constants/tolstoy.constants';
import CopyCode from 'src/complex_components/CopyCode';
import VerticalFlex, {
  VerticalFlexCentered,
} from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import ExpanderV2 from 'src/basic_components/ExpanderV2';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { useLocation } from 'react-router-dom';

type Props = {
  appUrl: string;
  widgetCode: string;
};

const ShopifyTolstoyCodeInstallation: React.FC<Props> = ({ widgetCode, appUrl }) => {
  const { hash } = useLocation();

  const handleOpenDeepLink = () => {
    const liquidFilename = LIQUID_FILENAMES.widgetBlock;
    const shopifyDomain = appUrl;
    const deepLink = Utils.getAppEmbedDeepLink(shopifyDomain, liquidFilename);

    Utils.openInNewTab(deepLink);
  };

  return (
    <LayoutRoot>
      <Gap8VerticalFlex>
        <EmbedInstallationWrapper>
          <TextSmallDark>
            {`Click the button below and then click “Save” in your Shopify dashboard. Don't worry,
            this won't publish anything on your store`}
          </TextSmallDark>
          <DeepLinkButton onClick={handleOpenDeepLink}>
            <ShopifyLogoIcon />
            Go To Shopify
            <RedirectToLink />
          </DeepLinkButton>
          <ImageContainer>
            <AppEmbedInstallationImg src={AppEmbedInstallationSrc} />
          </ImageContainer>
        </EmbedInstallationWrapper>

        <ExpanderV2
          collapsedTitle="Use code instead"
          expandedTitle="Use code instead"
          startExpanded={hash === '#code'}
        >
          <ManualInstallationWrapper>
            <TextSmallDark>
              {'Copy and paste the following code in your <head> section'}
            </TextSmallDark>
            <CopyCode codeToCopy={widgetCode} />
          </ManualInstallationWrapper>
        </ExpanderV2>
      </Gap8VerticalFlex>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  position: relative;
`;

const AppEmbedInstallationImg = styled.img`
  width: 400px;
  max-width: 100%;
`;

const ImageContainer = styled(VerticalFlexCentered)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.neutralLightest};
  padding: 16px;
  border-radius: 4px;
`;

const EmbedInstallationWrapper = styled(Gap16VerticalFlex)``;

const ManualInstallationWrapper = styled(Gap16VerticalFlex)`
  width: 50%;
`;

const DeepLinkButton = styled(PrimaryButton)`
  margin-right: auto;

  & svg {
    width: 14px;
    height: 14px;
  }

  & path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const TextSmallDark = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.neutralDark};
`;

export default ShopifyTolstoyCodeInstallation;
