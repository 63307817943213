import { App } from 'app/src/types/app';
import { getRequest } from 'app/src/helpers/API';
import { getSearchParamsPath } from 'app/src/utils/url.utils';
import { TIKTOK } from 'app/src/constants/intergrations.constants';
import { DEFAULT_NUMBER_OF_CARDS_TO_SHOW } from 'app/src/constants/ui.constants';
import {
  AppOptionsMap,
  VideoByAppResult,
  VideosByAppOptions,
  TiktokVideo,
} from 'app/src/types/integrations';
import { TiktokUtilsService } from 'app/src/services/tiktok';

const getVideosByAppId = async (
  appId: string,
  options: VideosByAppOptions = {}
): Promise<VideoByAppResult<TiktokVideo>> => {
  const { cursor, owner, maxNumberOfVideos = DEFAULT_NUMBER_OF_CARDS_TO_SHOW } = options;
  const searchParams = getSearchParamsPath({
    appId,
    cursor,
    maxNumberOfVideos: `${maxNumberOfVideos}`,
  });

  const url = `/tiktok/get-videos?${searchParams}`;
  const response = await getRequest(TIKTOK, url);
  const data = response.data;

  const videos = data.videos.map((video, i) => {
    const defaultName = `TikTok video #${i + 1}`;

    return TiktokUtilsService.normalizeTikTokVideo(video, { owner, defaultName });
  });

  return {
    videos,
    nextCursor: data.has_more ? data.cursor : null,
  };
};

const getVideosByApp = async (app: App, options: VideosByAppOptions = {}) => {
  return getVideosByAppId(app.id, options);
};

export const getVideosByApps = async (
  apps: App[] = [],
  optionsMap: AppOptionsMap<VideosByAppOptions> = {}
) => {
  return Promise.all(apps.map(app => getVideosByApp(app, optionsMap[app.id])));
};

export const getUserInfoByAppId = async (appId: string) => {
  const queryParams = getSearchParamsPath({ appId });
  const url = `/tiktok/get-user-info?${queryParams}`;
  const response = await getRequest('tiktok', url);
  const user = response.data.user;

  return {
    avatarUrl: user.avatar_url_100,
    userName: user.display_name,
    description: user.bio_description,
  };
};

export const getUserInfosByAppIds = async (appIds: string[] = []) => {
  return Promise.all(appIds.map(appId => getUserInfoByAppId(appId)));
};
