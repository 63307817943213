import React from 'react';

function StopIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill="#fff"
        d="M21 6c0-1.64-1.36-3-3-3H6C4.312 3 3 4.36 3 6v12c0 1.688 1.313 3 3 3h12c1.64 0 3-1.313 3-3V6z"
      ></path>
    </svg>
  );
}

export default StopIcon;
