import React, { useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import Gap16HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap16HorizontalFlexWrap';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import VOutlinedIcon from 'app/src/images/VOutlinedIcon';
import DashedInput from 'app/src/basic_components/DashedInput';
import { useFormContext } from 'react-hook-form';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import InvisibleDiv from 'app/src/basic_components/InvisibleDiv';
import { useDebounce } from 'app/src/hooks/useDebounce';
import { DEBOUNCE_TIME } from 'app/src/constants/builder.constants';

const EditOverlayText = ({ isFeed, isProductResultStep }) => {
  const { setValue, watch } = useFormContext();
  const [overlayText, overlayTextColor] = watch(['overlayText', 'overlayTextColor']);
  const theme = useTheme();
  const scrollRef = useRef();
  const [inputValue, setInputValue] = useState(overlayText || '');
  const debouncedInputValue = useDebounce(inputValue, DEBOUNCE_TIME);

  const isWhiteSelected = theme.colors.white === overlayTextColor;

  const getPlaceholderText = () => {
    if (isProductResultStep) {
      return 'Header title';
    }

    return 'Type here overlay text for your video';
  };

  const setOverlayTextIfNeeded = newValue => {
    if (overlayText === newValue) {
      return;
    }

    setValue('overlayText', newValue);
  };

  const setOverlayTextColorIfNeeded = newValue => {
    if (overlayTextColor === newValue) {
      return;
    }

    setValue('overlayTextColor', newValue);
  };

  const onTextChange = e => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    setInputValue(overlayText);
  }, [overlayText]);

  useEffect(() => {
    setOverlayTextIfNeeded(debouncedInputValue);
  }, [debouncedInputValue]);

  if (isFeed) {
    return null;
  }

  return (
    <LayoutRoot>
      <Content>
        <OverlayTextContent>
          <TitleTextField
            value={inputValue}
            onChange={onTextChange}
            placeholder={getPlaceholderText()}
          />
          {!isProductResultStep && (
            <ButtonsContainer>
              <WhiteButton onClick={() => setOverlayTextColorIfNeeded(theme.colors.white)}>
                {isWhiteSelected ? <VOutlinedIcon /> : ''}
              </WhiteButton>
              <Button onClick={() => setOverlayTextColorIfNeeded(theme.colors.black)}>
                {isWhiteSelected ? '' : <VOutlinedIcon fill={theme.colors.white} />}
              </Button>
            </ButtonsContainer>
          )}
        </OverlayTextContent>
      </Content>
      <InvisibleDiv ref={scrollRef} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

const Content = styled.div`
  width: 100%;
  overflow: auto;
`;

const OverlayTextContent = styled(Gap16HorizontalFlexWrap)`
  height: 56px;
  width: 100%;
  padding: 0 16px 0 24px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    padding: 16px;
  }
`;

const TitleTextField = styled(DashedInput)`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding-left: 0;

  &::placeholder {
    font-size: 14px;
    font-weight: 600;
  }
`;

const ButtonsContainer = styled(Gap8HorizontalFlexWrap)`
  flex-wrap: nowrap;
  padding-right: 16px;
`;

const Button = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 100%;
  background: ${({ theme }) => theme.colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WhiteButton = styled(Button)`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.black};
`;

export default EditOverlayText;
