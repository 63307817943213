import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { toTimeString } from 'app/src/utils/video.utils';
import HorizontalFlex, {
  Gap8HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';

const TimeBar = ({
  endTime,
  startTime,
  duration,
  timeBarRef,
  setSelectedPosition,
  currTime,
  showTimes = false,
}) => {
  const timeRef = useRef();
  const barWidthPercentage = ((endTime - startTime) / duration) * 100 || 100;
  const barLeftPercentage = (startTime / duration) * 100 || 0;

  useEffect(() => {
    const onMouseUp = () => setSelectedPosition(null);
    window.addEventListener('mouseup', onMouseUp);
    return () => {
      window.removeEventListener('mouseup', onMouseUp);
    };
  }, []);

  const times = [startTime, endTime].map(time => toTimeString(time, false));

  return (
    <LayoutRoot ref={timeBarRef}>
      <Time
        onMouseDown={ev => setSelectedPosition(ev.pageX)}
        left={barLeftPercentage}
        ref={timeRef}
        width={barWidthPercentage}
      />
      <VideoTimeContainer width={timeRef?.current?.clientWidth} left={barLeftPercentage}>
        {showTimes && times.map(time => <VideoTimeText key={time}>{time}</VideoTimeText>)}
      </VideoTimeContainer>
      {currTime !== 0 && <VideoTime time={(currTime / duration) * 100} />}
    </LayoutRoot>
  );
};

const DefaultLocation = styled.div`
  grid-area: 1;
  grid-column: 1;
`;

const LayoutRoot = styled.div`
  display: grid;
  background: ${({ theme }) => theme.colors.gray3};
  border-radius: 2px;
  width: 100%;
  height: 24px;
  cursor: pointer;
  position: relative;
  overflow: inherit;
`;

const Time = styled(DefaultLocation).attrs(({ left, width }) => ({
  style: {
    left: ` ${left}%`,
    width: ` ${width}%`,
    minWidth: '4%',
  },
}))`
  background: ${({ theme }) => theme.colors.primary};
  position: relative;
  user-select: none;
  border-radius: 2px;
`;

const VideoTime = styled(DefaultLocation).attrs(({ time }) => ({
  style: {
    left: `${time}%`,
  },
}))`
  z-index: 1;
  background: ${({ theme }) => theme.colors.white};
  height: 20px;
  position: relative;
  width: 3px;
  border-radius: 2px;
  transition: 0.1s;
  pointer-events: none;
  align-self: center;
`;

const VideoTimeContainer = styled(Gap8HorizontalFlex)`
  position: absolute;
  top: -26px;
  justify-content: space-between;
  width: ${({ width }) => width + 50}px;
  left: calc(${({ left, width = 24 }) => `${left}% - 25px`});
`;

const VideoTimeText = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.neutralBlack};
  padding: 4px;
  border-radius: 4px;
`;

export default TimeBar;
