import { createContainer, createHook, createStore } from 'react-sweet-state';

type State = {
  currentProductId: string;
  initialVodAssetIds: string[];
  newVodAssetIds: string[];
  deletedVodAssetIds: string[];
  isDirty: boolean;
  isLoading: boolean;
  currentProductData: any;
};

const initialState: State = {
  currentProductId: '',
  initialVodAssetIds: [],
  newVodAssetIds: [],
  deletedVodAssetIds: [],
  isDirty: false,
  isLoading: false,
  currentProductData: {},
};

const addItemToUniqueArray = (array: string[], item: string) => [...new Set([...array, item])];
const removeItemFromArray = (array: string[], item: string) => array.filter(id => id !== item);

const actions = {
  init:
    ({ currentProductId, initialVodAssetIds, productData }) =>
    ({ setState }) => {
      setState({
        currentProductId,
        initialVodAssetIds,
        currentProductData: productData,
        isLoading: false,
        isDirty: false,
        newVodAssetIds: [],
        deletedVodAssetIds: [],
      });
    },
  addVideo:
    ({ vodAssetId }) =>
    ({ getState, setState }) => {
      const { initialVodAssetIds, newVodAssetIds, deletedVodAssetIds } = getState();

      const isVideoAlreadyTagged = initialVodAssetIds.includes(vodAssetId);
      const isVideoDeleted = deletedVodAssetIds.includes(vodAssetId);

      if (isVideoAlreadyTagged && !isVideoDeleted) {
        return { isVideoAlreadyTagged: true };
      }

      setState({
        newVodAssetIds: addItemToUniqueArray(newVodAssetIds, vodAssetId),
        deletedVodAssetIds: removeItemFromArray(deletedVodAssetIds, vodAssetId),
        isDirty: true,
      });
    },
  removeVideo:
    ({ vodAssetId }) =>
    ({ getState, setState }) => {
      const { initialVodAssetIds, newVodAssetIds, deletedVodAssetIds } = getState();

      const isVideoNew = newVodAssetIds.includes(vodAssetId);
      if (isVideoNew) {
        setState({
          newVodAssetIds: removeItemFromArray(newVodAssetIds, vodAssetId),
          isDirty: true,
        });
        return;
      }

      setState({
        initialVodAssetIds: removeItemFromArray(initialVodAssetIds, vodAssetId),
        newVodAssetIds: removeItemFromArray(newVodAssetIds, vodAssetId),
        deletedVodAssetIds: addItemToUniqueArray(deletedVodAssetIds, vodAssetId),
        isDirty: true,
      });
    },
  clearState:
    () =>
    ({ setState }) => {
      setState({
        currentProductId: '',
        isDirty: false,
        isLoading: false,
        newVodAssetIds: [],
        deletedVodAssetIds: [],
        currentProductData: {},
        initialVodAssetIds: [],
      });
    },
};

type Actions = typeof actions;

const AddProductVideosStore = createStore({
  initialState,
  actions,
});

export const useAddProductVideos = createHook<State, Actions, State, void>(AddProductVideosStore);

export const AddProductVideosContainer = createContainer(AddProductVideosStore);
