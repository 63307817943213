import React from 'react';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import BillingsPackageMetrics from 'app/src/complex_components/billings/BillingPackageMetrics';
import BillingPackagePriceBreakdown from 'app/src/complex_components/billings/BillingPackagePriceBreakdown';
import { useBilling } from '../../hooks/useBillings';

export const PlansPageHeader = ({ isShopify }) => {
  const { isFreePackage, isProPackage } = useBilling();

  return (
    <StyledPlansPageHeader>
      {isFreePackage && <BillingsPackageMetrics />}
      {isProPackage && !isShopify && <BillingPackagePriceBreakdown />}
    </StyledPlansPageHeader>
  );
};

export default PlansPageHeader;

const StyledPlansPageHeader = styled(VerticalFlex)`
  justify-content: space-between;

  @media (${({ theme }) => theme.breakpoints.dashboardTabletMax}) {
    padding: 0 8px;
  }
`;
