import React from 'react';
import styled from 'styled-components';
import { IconButton as MUIIconButton } from '@material-ui/core';

type Props = {
  src: string;
  alt?: string;
  width?: string;
  disabled?: boolean;
  height?: string;
  hoverBackground?: string;
  padding?: string;
};

const IconButton = React.forwardRef<Props, any>(
  ({ src, alt, width, disabled, height, hoverBackground, children, ...props }, ref) => {
    function handleClick(e) {
      e.stopPropagation();
    }

    return (
      <StyledIconButton
        ref={ref}
        onClick={handleClick}
        width={width}
        disabled={disabled}
        height={height}
        $hoverBackground={hoverBackground}
        {...props}
      >
        {children || <Image src={src} alt={alt || ''} />}
      </StyledIconButton>
    );
  }
);
IconButton.displayName = 'IconButton';

export default IconButton;

const StyledIconButton = styled(MUIIconButton)<
  Pick<Props, 'width' | 'height' | 'padding'> & { $hoverBackground: string }
>`
  padding: ${({ padding }) => padding || '8px'};
  opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
  width: ${({ width }) => width || '40px'};
  height: ${({ height }) => height || '40px'};
  ${({ $hoverBackground }) =>
    $hoverBackground &&
    `
  &:hover {
    background: ${$hoverBackground};
  }
  `}
`;

const Image = styled.img``;
