import React, { lazy, Suspense } from 'react';
import useSwipeUpAnimation from 'shared/react/components/complex/context/hooks/useSwipeUpAnimation';
import SharedUtils from 'shared/react/utils/utils';
import Utils from 'player/src/utils';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';

const FeedSwipeUpForMore = lazy(() =>
  Utils.componentLoader(() =>
    import(/* webpackChunkName: "feed-swipe-up-for-more" */ './FeedSwipeUpForMore')
  )
);

const FeedSwipeUpForMoreWrapper = ({ isLoading }) => {
  const [{ hasSeenSwipeUpAnimation }] = useSwipeUpAnimation();
  const [, { getIsSingleStepFeed }] = useProjectConfig();

  if (hasSeenSwipeUpAnimation || !SharedUtils.isMobile() || getIsSingleStepFeed()) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <FeedSwipeUpForMore isLoading={isLoading} />
    </Suspense>
  );
};

export default FeedSwipeUpForMoreWrapper;
