import * as React from 'react';

const LandingPageIcon = ({ ...props }) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.25 17v2.5h12.5V17zM19 17h-3.75v2.5H19zm-3.75 3.75V32h17.5V20.75zm-1.25-5h20v17.5H14z"
      fill="#090A0B"
    />
  </svg>
);

export default LandingPageIcon;
