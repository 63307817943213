import React from 'react';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import CopyCode from 'app/src/complex_components/CopyCode';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { FlexDirection } from 'app/src/types/common';
import CopyToClipboardIcon from 'app/src/images/dashboard_v2/CopyToClipboardIcon';
import RadioButtonV2 from 'app/src/basic_components/radio-button/RadioButtonV2';
import styled from 'styled-components';

const CSS = `* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}`;

const NAVIGATION_ITEMS = 'navigationItems';
const TOP_BAR = 'topBar';
const BOTTOM_NAVIGATION = 'bottomNavigation';
const SIDE_NAVIGATION = 'sideNavigation';

const navTypes = {
  [TOP_BAR]: {
    [SIDE_NAVIGATION]: 'header',
    [BOTTOM_NAVIGATION]: 'header-and-bottom-navbar',
  },
  [NAVIGATION_ITEMS]: {
    [SIDE_NAVIGATION]: 'custom-screen',
    [BOTTOM_NAVIGATION]: 'custom-screen-and-bottom-navbar',
  },
};

const HOMEPAGE_TOP_BAR =
  'https://share.synthesia.io/embeds/videos/0733361c-74a5-461e-a0f4-7aeb413416c6';
const NAVIGATION_ITEM =
  'https://share.synthesia.io/embeds/videos/36865632-2350-438f-9765-b6c004b53ef6';

const INSTRUCTIONS_LINK = {
  [TOP_BAR]: HOMEPAGE_TOP_BAR,
  [NAVIGATION_ITEMS]: NAVIGATION_ITEM,
};

const getPDPData = (project, location) => {
  return [
    {
      title: 'HTML',
      code: `<tolstoy-block
    data-publish-id="${project.publishId}"
    data-block-type="video-page"
    data-nav-type="${location}"
></tolstoy-block>`,
    },
    {
      title: 'CSS',
      code: CSS,
    },
    {
      title: 'Javascript',
      code: `function createTolstoyBlock() {
  const script = document.createElement('script');
  script.setAttribute('type', 'module');
  script.setAttribute('defer', '');
  script.setAttribute('src', 'https://widget.gotolstoy.com/we/widget.js');
  script.setAttribute('data-app-key', '${project.appKey}');

  document.head.appendChild(script);
}

createTolstoyBlock();`,
    },
  ];
};

export const getStepsForTapcartTV = (
  project,
  selectedLocation,
  setSelectedLocation,
  selectedType,
  setSelectedType
) => {
  const location = navTypes?.[selectedLocation]?.[selectedType];
  const link = INSTRUCTIONS_LINK[selectedLocation];
  const tapcartPDPData = getPDPData(project, location);

  const step1 = {
    title: 'Select where in your app you’ll be adding your video feed',
    body: (
      <Gap8HorizontalFlex>
        <div>Home page top bar</div>
        <RadioButtonV2
          checked={selectedLocation === TOP_BAR}
          onClick={() => setSelectedLocation(TOP_BAR)}
        />
        <div>Navigation item</div>
        <RadioButtonV2
          checked={selectedLocation === NAVIGATION_ITEMS}
          onClick={() => setSelectedLocation(NAVIGATION_ITEMS)}
        />
      </Gap8HorizontalFlex>
    ),
  };

  const step2 = {
    title: 'What type of navigation does your app use?',
    body: (
      <Gap8HorizontalFlex>
        <div>Side navigation</div>
        <RadioButtonV2
          checked={selectedType === SIDE_NAVIGATION}
          onClick={() => setSelectedType(SIDE_NAVIGATION)}
        />
        <div>Bottom navigation</div>
        <RadioButtonV2
          checked={selectedType === BOTTOM_NAVIGATION}
          onClick={() => setSelectedType(BOTTOM_NAVIGATION)}
        />
      </Gap8HorizontalFlex>
    ),
  };

  const step3 = {
    title: (
      <>
        Follow the{' '}
        <Link target="_blank" href={link}>
          installation instructions
        </Link>{' '}
        to add your feed to Tapcart using custom blocks with the below code
      </>
    ),
    body: (
      <Gap8VerticalFlex>
        {tapcartPDPData.map(data => (
          <VerticalFlex key={data.title}>
            <div>{data.title}</div>
            <CopyCode
              direction={FlexDirection.row}
              codeToCopy={data.code}
              padding="8px"
              buttonText={<CopyToClipboardIcon />}
            />
          </VerticalFlex>
        ))}
      </Gap8VerticalFlex>
    ),
  };

  if (!selectedLocation) {
    return [step1];
  }

  if (!selectedType) {
    return [step1, step2];
  }

  return [step1, step2, step3];
};

const Link = styled.a`
  color: ${({ theme }) => theme.colors.primary};

  &:hover {
    color: ${({ theme }) => theme.colors.primaryHover};
  }
`;
