import React from 'react';
import styled from 'styled-components';

import { UPLOAD_TYPES } from 'shared/react/constants/video.constants';
import { Gap8HorizontalFlex } from '../../flex_layouts/HorizontalFlex';
import { TextSmall } from 'shared/react/components/basic/text/TextV2';
import AiIcon from 'shared/react/images/feed/AiIcon';

const FeedAiTag = ({ className, step }) => {
  const { uploadType } = step || {};

  // if (!uploadType) {
  return null;
  // }

  const isAiVideo = uploadType.includes(UPLOAD_TYPES.aiVideo);

  if (!isAiVideo) {
    return null;
  }

  return (
    <LayoutRoot className={className}>
      <AiIcon />
      <TextSmallWhite>AI Video</TextSmallWhite>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlex)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
`;

const TextSmallWhite = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.white};
`;

export default FeedAiTag;
