import React, { useState } from 'react';
import styled from 'styled-components';
import { DASHBOARD_TABLET_MAX_WIDTH } from 'shared/react/utils/mediaQuery';
import EmailPreview from 'app/src/complex_components/EmailPreview';
import Gap16HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap16HorizontalFlexWrap';
import { TextTiny } from 'shared/react/components/complex/Text';
import { track } from 'app/src/helpers/Tracker';
import DownloadIcon from 'app/src/images/DownloadIcon';
import EmailAutomationTools from './EmailAutomationTools';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';
import Utils from '../../../utils';
import usePlayerUrl from 'app/src/hooks/usePlayerUrl';
import { appendParamsToUrl } from 'app/src/utils/url.utils';
import { getEmailSnippet } from './email-snippets';
import { useEmailAssets } from './email-assets';
import {
  EMAIL_LAYOUT_MAX_ASSETS_COUNT,
  EMAIL_LAYOUT_TYPE_OPTIONS,
} from './email-layout-type.constants';

function ShareViaEmail({ project }) {
  const [, { setSnackbar }] = useSnackBar();
  const { playerUrl } = usePlayerUrl(project?.id);
  const [emailLayoutType, setEmailLayoutType] = useState(EMAIL_LAYOUT_TYPE_OPTIONS[0]);

  const emailAssets = useEmailAssets({
    project,
    maxAssetsCount: EMAIL_LAYOUT_MAX_ASSETS_COUNT[emailLayoutType.id],
  });

  const isCopyDisabled = !emailAssets.length;

  const handleCopyCode = async ({ email, location, pixelTracking, selectedTool }) => {
    track('Copy Code Click', { location, selectedTool });

    const getAssetActionUrl = asset => {
      return appendParamsToUrl(playerUrl, asset.actionUrlParams);
    };

    const code = getEmailSnippet({
      assets: emailAssets,
      automationTool: selectedTool,
      email,
      emailLayoutType,
      getAssetActionUrl,
      pixelTracking,
      project,
    });

    Utils.copyHtmlToClipboard(code);
    setSnackbar('The code copied to your clipboard!');
  };

  return (
    <LayoutRoot>
      <Content>
        <LeftContainer>
          <EmailAutomationTools
            onCopyClick={handleCopyCode}
            isCopyDisabled={isCopyDisabled}
            project={project}
            emailLayoutType={emailLayoutType}
            onEmailLayoutTypeChange={setEmailLayoutType}
          />
        </LeftContainer>
        <EmailPreviewContainer>
          <EmailPreview emailAssets={emailAssets} />
          <DownloadGif href={emailAssets[0]?.gifUrl}>
            <DownloadGifText>Download GIF</DownloadGifText>
            <DownloadIcon />
          </DownloadGif>
        </EmailPreviewContainer>
      </Content>
    </LayoutRoot>
  );
}

export default ShareViaEmail;

const LayoutRoot = styled.div`
  display: grid;
  height: fit-content;
  gap: 8px;

  @media (${DASHBOARD_TABLET_MAX_WIDTH}) {
    flex-wrap: unset;
    overflow: auto;
  }
`;

const Content = styled(Gap16HorizontalFlexWrap)`
  justify-content: space-between;
  gap: 16px;
  width: 100%;
`;

const EmailPreviewContainer = styled.div`
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    display: none;
  }
`;

const DownloadGif = styled.a`
  display: flex;
  margin-top: 16px;
  justify-content: flex-end;
  gap: 6px;
  text-decoration: none;
`;

const DownloadGifText = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.gray2};
`;

const LeftContainer = styled.div`
  width: 50%;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 100%;
  }
`;
