import React from 'react';

function SupportIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19" fill="none" viewBox="0 0 21 19">
      <path
        fill="#50545E"
        d="M11.86 4.5H9.944c-1.133 0-2.07.977-2.07 2.11 0 .35.273.624.625.624a.642.642 0 00.625-.625c0-.468.352-.859.82-.859h1.914c.547 0 1.016.469 1.016 1.016 0 .39-.234.742-.586.937l-1.601.82a.623.623 0 00-.313.547v1.055c0 .352.273.625.625.625a.642.642 0 00.625-.625v-.664l1.25-.664c.742-.39 1.25-1.172 1.25-2.031A2.267 2.267 0 0011.859 4.5zM11 11.688a.76.76 0 00-.781.78c0 .47.312.782.781.782a.76.76 0 00.781-.781.784.784 0 00-.781-.781zM11 .75C5.453.75 1 4.422 1 8.875c0 1.875.742 3.594 2.031 4.96-.547 1.524-1.758 2.852-1.797 2.852-.273.274-.312.665-.195 1.016a.937.937 0 00.86.547c2.421 0 4.296-.977 5.468-1.797C8.5 16.805 9.711 17 11 17c5.508 0 10-3.633 10-8.125C21 4.422 16.508.75 11 .75zm0 15c-1.133 0-2.227-.156-3.281-.469-.352-.117-.782-.078-1.094.156-.898.626-2.266 1.368-3.984 1.563a10.761 10.761 0 001.562-2.734c.195-.43.078-.938-.234-1.29-1.133-1.21-1.719-2.617-1.719-4.101C2.25 5.085 6.156 2 11 2c4.805 0 8.75 3.086 8.75 6.875 0 3.79-3.945 6.875-8.75 6.875z"
      />
    </svg>
  );
}

export default SupportIcon;
