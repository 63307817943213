import React from 'react';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextBodyLeadingBold, TextSmall } from 'shared/react/components/complex/Text';
import IntegrationsButton from 'app/src/pages/creation_flow/right_section/integrations_common/IntegrationsButton';
import { useTranslation } from 'react-i18next';
import { InfoLightBadge } from 'shared/react/components/basic/Badge';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import RedirectToLink from 'app/src/images/RedirectToLink';
import Utils from 'shared/react/utils/utils';

const IntegrationsConnectAccountState = ({
  handleConnectClick,
  badgeText,
  showMeHowLink,
  integration,
}) => {
  const { t } = useTranslation();

  const onShowMeHow = () => {
    Utils.openInNewTab(showMeHowLink);
  };

  return (
    <LayoutRoot>
      {!!badgeText && <Badge>{badgeText}</Badge>}
      <Text>{t(`connectAccount.${[integration]}.header`)}</Text>
      <ButtonContainer>
        <IntegrationsButton onClick={handleConnectClick} integration={integration}>
          {t(`connectAccount.${[integration]}.buttonText`)}
        </IntegrationsButton>
        {!!showMeHowLink && (
          <ShowMeHowContainer onClick={onShowMeHow}>
            <ShowMeHowText>Show me how</ShowMeHowText>
            <RedirectToLink />
          </ShowMeHowContainer>
        )}
      </ButtonContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 24px;
`;

const Text = styled(TextBodyLeadingBold)``;

const ButtonContainer = styled(VerticalFlex)`
  gap: 12px;
`;

const Badge = styled(InfoLightBadge)`
  font-weight: 700;
`;

const ShowMeHowText = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.primary};
  transition: 0.3s;
`;

const ShowMeHowContainer = styled(HorizontalFlex)`
  gap: 6px;
  align-self: center;
  cursor: pointer;

  & path {
    fill: ${({ theme }) => theme.colors.primary};
    transition: 0.3s;
  }

  &:hover ${ShowMeHowText} {
    color: ${({ theme }) => theme.colors.primaryHover};
  }

  &:hover path {
    fill: ${({ theme }) => theme.colors.primaryHover};
  }
`;

export default IntegrationsConnectAccountState;
