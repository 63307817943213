export const PLAYER_TYPES = {
  feed: 'feed',
};

export const PUBLISH_METHOD_OPTIONS = {
  videoPage: 'videoPage',
  stories: 'stories',
  carousel: 'carousel',
  bubble: 'bubble',
  embed: 'embed',
  landingPage: 'landingPage',
  unassigned: 'unassigned',
  hero: 'hero',
  shopApp: 'shopApp',
  tvChannel: 'tvChannel',
  email: 'email',
  tile: 'tile',
  videoCollectionCampaign: 'videoCollectionCampaign',
};

// TODO: get rid on these constants as they duplicate publishMethodOptions
export const FEED_EMBEDS = [PUBLISH_METHOD_OPTIONS.stories, PUBLISH_METHOD_OPTIONS.carousel];

export const PLAYER_TYPE = 'playerType';
export const TOLSTOY_KEY = 'tolstoy';
export const PUBLISH_METHODS_WITH_LIVE_FIELD = [
  PUBLISH_METHOD_OPTIONS.carousel,
  PUBLISH_METHOD_OPTIONS.stories,
  PUBLISH_METHOD_OPTIONS.shopApp,
];
export const LIVE_SETTABLE_PUBLISH_METHODS = [
  PUBLISH_METHOD_OPTIONS.bubble,
  PUBLISH_METHOD_OPTIONS.carousel,
  PUBLISH_METHOD_OPTIONS.stories,
  PUBLISH_METHOD_OPTIONS.shopApp,
  PUBLISH_METHOD_OPTIONS.hero,
];

export const PUBLISH_METHODS = {
  [PUBLISH_METHOD_OPTIONS.videoPage]: {
    widgetType: PUBLISH_METHOD_OPTIONS.videoPage,
    text: 'TV Page',
    color: 'black',
  },
  [PUBLISH_METHOD_OPTIONS.tvChannel]: {
    widgetType: PUBLISH_METHOD_OPTIONS.tvChannel,
    text: 'TV Channel',
    color: 'black',
  },
  [PUBLISH_METHOD_OPTIONS.stories]: {
    widgetType: PUBLISH_METHOD_OPTIONS.stories,
    text: 'Stories',
    color: 'purple4',
    id: 'story',
  },
  [PUBLISH_METHOD_OPTIONS.carousel]: {
    widgetType: PUBLISH_METHOD_OPTIONS.carousel,
    text: 'Carousel',
    color: 'pink7',
    id: 'carousel',
  },
  [PUBLISH_METHOD_OPTIONS.bubble]: {
    widgetType: PUBLISH_METHOD_OPTIONS.bubble,
    text: 'Widget',
    color: 'success',
  },
  [PUBLISH_METHOD_OPTIONS.embed]: {
    widgetType: PUBLISH_METHOD_OPTIONS.embed,
    text: 'Embed',
    color: 'warning',
    id: 'embed',
  },
  [PUBLISH_METHOD_OPTIONS.landingPage]: {
    widgetType: PUBLISH_METHOD_OPTIONS.landingPage,
    text: 'Off-site message',
    color: 'info',
  },
  [PUBLISH_METHOD_OPTIONS.hero]: {
    widgetType: PUBLISH_METHOD_OPTIONS.hero,
    text: 'Hero',
    color: 'info',
  },
  [PUBLISH_METHOD_OPTIONS.shopApp]: {
    widgetType: PUBLISH_METHOD_OPTIONS.shopApp,
    text: 'Shop Carousel',
    onboardingHeaderText: 'carousel',
  },
  [PUBLISH_METHOD_OPTIONS.email]: {
    widgetType: PUBLISH_METHOD_OPTIONS.email,
    text: 'Email',
  },
  [PUBLISH_METHOD_OPTIONS.tile]: {
    widgetType: PUBLISH_METHOD_OPTIONS.tile,
    text: 'Tile',
  },
  [PUBLISH_METHOD_OPTIONS.videoCollectionCampaign]: {
    widgetType: PUBLISH_METHOD_OPTIONS.videoCollectionCampaign,
    text: 'Video Collection Campaign',
  },
};

export const LIQUID_FILENAMES = {
  widgetBlock: 'widget-block',
  tvChannelButton: 'tv-channel-button',
};
