import { useEffect } from 'react';
import { useApps } from 'src/context/AppsStore';
import { useInstagram } from 'src/context/InstagramStore';
import { useTikTok } from 'src/context/TikTokStore';
import { useVideos } from 'src/context/VideosStore';
import { useUser } from 'src/context/userStore/UserStore';
import useCreateIntegrationVideo from 'src/hooks/use-create-integration-video/useCreateIntegrationVideo';
import usePrepareIntegrationVideos from '../../../videos/hooks/usePrepareIntegrationVideos';
import { VideosPageVodAsset } from '../../../videos/types/videos.types';
import { useGoogleDrive } from 'app/src/context/GoogleDriveStore';

const useAccountOnboardingVideos = () => {
  const [{ account = {} }] = useUser();
  const [{ data: videos = [], initialized }] = useVideos();

  const [
    { accountVideos: tiktokVideos, initialized: tiktokInitialized, gotAllVideos: gotAllTiktok },
    { fetchByApps: fetchTikTokVideos },
  ] = useTikTok();

  const [
    {
      accountVideos: instagramVideos,
      initialized: instagramInitialized,
      gotAllVideos: gotAllInstagram,
    },
    { fetchByAccount: fetchInstagramVideos },
  ] = useInstagram();

  const [
    {
      videos: googleDriveVideos,
      initialized: googleDriveInitialized,
      gotAllVideos: gotAllGoogleDrive,
    },
    { fetchByAccount: fetchGoogleDriveVideos },
  ] = useGoogleDrive();

  const [
    { tiktokApps, tiktok: tiktokApp, instagramGraph: instagramApp, googleDrive: googleDriveApp },
  ] = useApps();

  const { prepareIntegrationVideos } = usePrepareIntegrationVideos();
  const { importMultipleFiles } = useCreateIntegrationVideo();

  const getIsVideoImported = (integrationVideo: VideosPageVodAsset | null) => {
    return videos.find(({ id }) => id === integrationVideo?.id);
  };

  const getAllVideos = () => {
    const formattedTiktokVideos = prepareIntegrationVideos(tiktokVideos);
    const formattedInstagramVideos = prepareIntegrationVideos(instagramVideos);
    const formattedGoogleDriveVideos = prepareIntegrationVideos(googleDriveVideos);

    return [
      ...videos,
      ...formattedTiktokVideos.filter(video => !getIsVideoImported(video)),
      ...formattedInstagramVideos.filter(video => !getIsVideoImported(video)),
      ...formattedGoogleDriveVideos.filter(video => !getIsVideoImported(video)),
    ];
  };

  const onSelectedVideosAction = async (videos: VideosPageVodAsset[] | null) => {
    const video = videos[0];

    const { owner } = account;

    const isVideoImported = getIsVideoImported(video);
    if (isVideoImported) {
      return;
    }

    return importMultipleFiles({
      videos: [video],
      owner,
      method: video.uploadType,
    });
  };

  useEffect(() => {
    if (tiktokApps.length && !tiktokInitialized) {
      fetchTikTokVideos(tiktokApps);
    }
  }, [tiktokApp, tiktokInitialized]);

  useEffect(() => {
    if (instagramApp && !instagramInitialized) {
      fetchInstagramVideos();
    }
  }, [instagramApp, instagramInitialized]);

  useEffect(() => {
    if (googleDriveApp && !googleDriveInitialized) {
      fetchGoogleDriveVideos();
    }
  }, [googleDriveApp, googleDriveInitialized]);

  const onScrollEndCallback = () => {
    if (tiktokApps.length && !gotAllTiktok) {
      fetchTikTokVideos(tiktokApps);
    }

    if (instagramApp && !gotAllInstagram) {
      fetchInstagramVideos();
    }

    if (googleDriveApp && !gotAllGoogleDrive) {
      fetchGoogleDriveVideos();
    }
  };

  const isTiktokInitialized = tiktokApp ? tiktokInitialized : true;
  const isInstagramInitialized = instagramApp ? instagramInitialized : true;
  const isGoogleDriveInitialized = googleDriveApp ? googleDriveInitialized : true;
  const isVideosInitialized =
    initialized && isInstagramInitialized && isTiktokInitialized && isGoogleDriveInitialized;

  const allVideos = getAllVideos();

  return {
    allVideos,
    isLoading: !isVideosInitialized,
    onSelectedVideosAction,
    onScrollEndCallback,
  };
};

export default useAccountOnboardingVideos;
