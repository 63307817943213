import {
  FEATURE_PRICING_ADDONS,
  FEATURE_WHITE_LABEL,
} from 'app/src/constants/appFeatures.constants';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { useBilling } from 'app/src/hooks/useBillings';
import React from 'react';
import { TextBody } from 'shared/react/components/complex/Text';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import styled from 'styled-components';
import PlanAddon from './PlanAddon';

export const PlanAddons = () => {
  const { currentPackage = {} } = useBilling();
  const [, { getFeatureEnabled, getSettingsByKey }] = useFeatures();
  const { addons } = currentPackage;
  const active = getSettingsByKey(FEATURE_WHITE_LABEL)?.hidePoweredBy;

  if (!addons?.length || !getFeatureEnabled(FEATURE_PRICING_ADDONS) || !active) {
    return null;
  }

  return (
    <StyledPlanAddons>
      <PlanAddonsTitle>Add-ons</PlanAddonsTitle>
      {addons?.map((addon, index) => (
        <PlanAddon key={index} {...addon} active={active} />
      ))}
    </StyledPlanAddons>
  );
};

export default PlanAddons;

const StyledPlanAddons = styled(VerticalFlex)`
  gap: 18px;
`;

const PlanAddonsTitle = styled(TextBody)`
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.black};
`;
