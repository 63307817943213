import React from 'react';
import styled from 'styled-components';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import { TextBody } from 'shared/react/components/complex/Text';

const GenericBadgeContent = ({
  subtitleBeforeTitle,
  showCloseButton,
  icon,
  subtitlesColor,
  subtitle,
  titleColor,
  title,
  titleOnClick,
}) => {
  if (subtitleBeforeTitle) {
    return (
      <BadgeContent showCloseButton={showCloseButton}>
        {icon}
        <BadgeSubtitle color={subtitlesColor}>{subtitle}</BadgeSubtitle>
        <BadgeTitle onClick={titleOnClick} color={titleColor}>
          {title}
        </BadgeTitle>
      </BadgeContent>
    );
  }

  return (
    <BadgeContent showCloseButton={showCloseButton}>
      {icon}
      <BadgeTitle color={titleColor} onClick={titleOnClick}>
        {title}
      </BadgeTitle>
      <BadgeSubtitle color={subtitlesColor}>{subtitle}</BadgeSubtitle>
    </BadgeContent>
  );
};

export default GenericBadgeContent;

const BadgeContent = styled(Gap8HorizontalFlexWrap)`
  margin-left: ${({ showCloseButton }) => (showCloseButton ? 'auto' : '')};
  justify-content: center;
`;

const BadgeText = styled(TextBody)`
  font-size: 12px;
  line-height: 14px;
`;

const BadgeTitle = styled(BadgeText)`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : '')};
  pointer-events: ${({ onClick }) => (onClick ? 'auto' : 'none')};
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  color: ${({ color }) => color};
`;

const BadgeSubtitle = styled(BadgeText)`
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: ${({ color }) => color};

  @media screen and (max-width: 800px) {
    display: none;
  }
`;
