import React from 'react';

const ImageIcon = () => {
  return (
    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.75 2.75c.813 0 1.5.688 1.5 1.5a1.5 1.5 0 0 1-1.5 1.5 1.48 1.48 0 0 1-1.5-1.5 1.5 1.5 0 0 1 1.5-1.5M14.969 0c1.125 0 2 .906 2 2v10c0 1.125-.907 2-2 2h-12c-1.094 0-2-.875-2-2V2c0-1.094.906-2 2-2zm.5 11.813V2c0-.25-.219-.5-.5-.5h-12c-.25 0-.5.25-.5.5L2.5 12l2.344-2.937c.125-.126.281-.22.468-.22.188 0 .344.095.47.22L6.937 10.5 10.25 6a.6.6 0 0 1 .5-.25.5.5 0 0 1 .438.25z"
        fill="#fff"
      />
    </svg>
  );
};

export default ImageIcon;
