import React from 'react';

const CrownIcon = props => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.49219 3.98438L8.82812 6.67969C9.03906 7.10156 9.60156 7.21875 9.95312 6.9375L12.0625 5.25C11.9453 5.10938 11.875 4.89844 11.875 4.6875C11.875 4.17188 12.2734 3.75 12.8125 3.75C13.3281 3.75 13.75 4.17188 13.75 4.6875C13.75 5.22656 13.3281 5.625 12.8125 5.625C12.7891 5.625 12.7891 5.625 12.7891 5.625L11.7109 11.5312C11.5938 12.2344 10.9609 12.75 10.2344 12.75H3.74219C3.01562 12.75 2.38281 12.2344 2.26562 11.5312L1.1875 5.625C0.648438 5.625 0.25 5.22656 0.25 4.6875C0.25 4.17188 0.648438 3.75 1.1875 3.75C1.70312 3.75 2.125 4.17188 2.125 4.6875C2.125 4.89844 2.05469 5.10938 1.9375 5.25L4.02344 6.9375C4.39844 7.21875 4.9375 7.10156 5.14844 6.67969L6.50781 3.98438C6.22656 3.82031 6.0625 3.53906 6.0625 3.1875C6.0625 2.67188 6.46094 2.25 7 2.25C7.51562 2.25 7.9375 2.67188 7.9375 3.1875C7.9375 3.53906 7.75 3.82031 7.49219 3.98438Z"
      fill="#FFC728"
    />
  </svg>
);

export default CrownIcon;
