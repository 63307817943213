import React, { useState } from 'react';
import styled from 'styled-components';
import StandardModal from 'app/src/pages/modals/StandardModal';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { TextBody, TextH5 } from 'shared/react/components/complex/Text';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import Button from 'shared/react/components/complex/Button';
import Types from 'shared/react/theme/Types';
import { useAccount } from 'app/src/context/AccountStore';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';

const TransferOwnershipModal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [
    {
      modalProps: { teamMember },
    },
    { clearModalState },
  ] = useModal();
  const [, { transferAccountOwnership }] = useAccount();
  const [, { setErrorSnackbar }] = useSnackBar();

  const onClose = () => {
    if (isLoading) {
      return;
    }

    clearModalState();
  };

  const onTransfer = async () => {
    setIsLoading(true);
    const err = await transferAccountOwnership(teamMember);
    setIsLoading(false);

    if (err) {
      setErrorSnackbar('Something went wrong. please try again');
      return;
    }
    onClose();
  };

  const teamMemberName = teamMember.email.split('@')[0];
  return (
    <StandardModal open={true} onClose={onClose} width="440px" removeDefaultPadding>
      <LayoutRoot>
        <Header>Transfer ownership</Header>
        <TextContainer>
          <SubHeader>Are you sure you want to make {teamMemberName} the owner?</SubHeader>
          <Text>
            You cannot undo this action and you won’t be able to change anything on your account.
          </Text>
        </TextContainer>
        <ButtonsContainer>
          <Button onClick={onClose} type={Types.Secondary}>
            Cancel
          </Button>
          <Button loading={isLoading} onClick={onTransfer} type={Types.Danger}>
            Transfer ownership
          </Button>
        </ButtonsContainer>
      </LayoutRoot>
    </StandardModal>
  );
};

const LayoutRoot = styled.div`
  padding: 24px;
`;

const Header = styled(TextH5)``;

const TextContainer = styled(Gap8VerticalFlex)`
  margin-top: 24px;
`;

const SubHeader = styled(TextBody)`
  font-weight: 800;
`;

const Text = styled(TextBody)`
  color: ${({ theme }) => theme.colors.ghostDark};
`;

const ButtonsContainer = styled(Gap8HorizontalFlexWrap)`
  margin-top: 40px;
  justify-content: flex-end;
`;

export default TransferOwnershipModal;
