import React, { useState } from 'react';
import styled from 'styled-components';
import Button from 'shared/react/components/complex/Button';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import usePlayerUrl from '../../../hooks/usePlayerUrl';
import QRCodeFromUrl from './QRCode';
import { track } from 'app/src/helpers/Tracker';

const QR_SIZE = 232;
const DEFAULT_FILE_NAME = 'QR';
const FILE_TYPE = 'png';
const CANVAS_ID = 'qr-canvas';

const ShareViaQR = ({ project }) => {
  const { playerUrl } = usePlayerUrl(project?.id);
  const [isLoading, setIsLoading] = useState(false);

  const getFileName = () => {
    let fileName = DEFAULT_FILE_NAME;
    if (project?.name) {
      fileName = project.name;
    }

    return `${fileName}.${FILE_TYPE}`;
  };

  const onClickDownloadQRCode = async () => {
    if (isLoading) {
      return;
    }
    track('Download Qr Code');

    setIsLoading(true);

    const canvas = document.getElementById(CANVAS_ID);
    const link = document.createElement('a');
    link.href = canvas.toDataURL();
    link.download = getFileName();
    link.click();
    link.remove();

    setIsLoading(false);
  };

  return (
    <LayoutRoot>
      <QRContainer>
        <QRCodeFromUrl url={playerUrl} size={QR_SIZE} canvasId={CANVAS_ID} />
      </QRContainer>
      <Button onClick={onClickDownloadQRCode} disabled={isLoading} loading={isLoading}>
        Download QR Code
      </Button>
    </LayoutRoot>
  );
};

export default ShareViaQR;

const LayoutRoot = styled(VerticalFlex)`
  gap: 32px;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const QRContainer = styled.div`
  height: ${QR_SIZE}px;
  width: ${QR_SIZE}px;
`;
