import React, { useState } from 'react';
import TimeBar from 'app/src/complex_components/TimeBar';
import styled, { css } from 'styled-components';
import AnswersTimeEditor from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/video_preview/AnswersTimeEditor';

const TimeBarContainer = ({
  videoDuration,
  isEditingAnswersTime,
  playTime,
  handleCurrentTimeChanged,
  answerTime,
  handleAnswerTimeChanged,
}) => {
  const [changeToPercentage, setChangeToPercentage] = useState(null);

  if (!isEditingAnswersTime) {
    return (
      <TimeBar
        videoDuration={videoDuration}
        time={playTime}
        onTimeChanged={handleCurrentTimeChanged}
        changeToPercentage={changeToPercentage}
        setChangeToPercentage={setChangeToPercentage}
      />
    );
  }

  return (
    <StyledAnswersTimeEditor
      videoDuration={videoDuration}
      answerTime={answerTime}
      onAnswerTimeChanged={handleAnswerTimeChanged}
      changeToPercentage={changeToPercentage}
      setChangeToPercentage={setChangeToPercentage}
    />
  );
};

const StyledAnswersTimeEditorMobile = css`
  width: 100%;
`;

const StyledAnswersTimeEditor = styled(AnswersTimeEditor)`
  ${({ isVertical }) => isVertical && StyledAnswersTimeEditorMobile};

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    ${StyledAnswersTimeEditorMobile};
  }
`;

export default TimeBarContainer;
