import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { project as Project, rule as RuleType, vodAsset } from 'app/src/types/entities';
import TableVideoCard from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/video-card/TableVideoCard';
import arrayMove from 'array-move';
import useDrag from 'app/src/hooks/useDrag';
import { getHideVodRuleTypeKey, getOrderVodRuleTypeKey } from 'app/src/context/RulesStore';
import { sortEntities } from 'app/src/hooks/use-dynamic-video-ids-by-project/checkRules';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import { FEATURE_ORDER_FEED_VIDEOS } from 'shared/react/constants/features.constants';
import { useVodConnectionActions } from 'app/src/context/VodConnectionStore';
import { useProductActions } from 'app/src/context/ProductsStore';
import { useApps } from 'src/context/AppsStore';
import { useOpenVideoPickerModal } from 'src/hooks/modals';
import { useVodProjectIdConnectionActions } from 'src/hooks/vod-connections';
import AddNewVideoButton, {
  AddNewVideoButtonModes,
} from '../add-new-video-button/AddNewVideoButton';

type Props = {
  loading: boolean;
  vodAssets: vodAsset[];
  videoRules?: RuleType[];
  setVideoRules?: (rules: RuleType[]) => void;
  setVodAssets: (vodAssets: vodAsset[]) => void;
  project: Project;
};

const DynamicNonPDP = ({
  loading,
  vodAssets,
  setVodAssets,
  videoRules,
  setVideoRules,
  project,
}: Props) => {
  const { onDragOver, handleDragStart, handleDrop, draggingToIndex, draggingFromIndex } = useDrag({
    onDrop,
  });
  const { getFeatureEnabled } = useFeatureActions();
  const { getProductsByVodAssetIds } = useVodConnectionActions();
  const { getProducts } = useProductActions();
  const [{ selectedAppUrl }] = useApps();
  const openVideoPickerModal = useOpenVideoPickerModal();
  const { createVideoProjectIdConnection, deleteVodProjectIdConnection } =
    useVodProjectIdConnectionActions();
  const [vodConnectionDeletingMap, setVodConnectionDeletingMap] = useState({});

  useEffect(() => {
    const vodAssetIds = vodAssets.map(vodAsset => vodAsset.id);
    const productIds = getProductsByVodAssetIds(vodAssetIds, project.appUrl);
    if (!productIds.length) {
      return;
    }

    getProducts(productIds);
  }, [vodAssets]);

  function onDrop(index) {
    console.log({ index });
    if (!getFeatureEnabled(FEATURE_ORDER_FEED_VIDEOS)) {
      return;
    }

    const newAssetsOrder = arrayMove(vodAssets, draggingFromIndex, index);
    const sortedEntities = sortEntities(newAssetsOrder, videoRules, null);
    setVodAssets(sortedEntities);
  }

  const onAddManualVideo = async video => {
    if (!video?.id) {
      return;
    }

    await createVideoProjectIdConnection({
      appUrl: selectedAppUrl,
      projectId: project.id,
      vodAssetId: video.id,
    });
  };

  const onRemoveManualVideo = async video => {
    if (vodConnectionDeletingMap[video.id]) {
      return;
    }

    setVodConnectionDeletingMap(prevState => ({ ...prevState, [video.id]: true }));

    await deleteVodProjectIdConnection({ vodAssetId: video.id, projectId: project.id });

    setVodConnectionDeletingMap(prevState => ({ ...prevState, [video.id]: false }));
  };

  const onOpenVideoPickerModal = () => openVideoPickerModal({ onVideoPicked: onAddManualVideo });

  return (
    <LayoutRoot>
      {!loading &&
        vodAssets.map((video, index) => {
          const isDisabled = !!videoRules?.find(({ value }) => {
            return (
              value === getOrderVodRuleTypeKey(video.id, index, null) ||
              value === getHideVodRuleTypeKey(video.id, null)
            );
          });

          if (vodConnectionDeletingMap[video.id]) {
            return null;
          }

          return (
            <React.Fragment key={video.id}>
              {draggingToIndex === index && !isDisabled && (
                <div
                  onDragStart={e => handleDragStart(e, index)}
                  onDrop={e => handleDrop(e, index, isDisabled)}
                  onDragOver={e => onDragOver(e, index)}
                />
              )}
              <StyledSortableElement
                onDragStart={e => handleDragStart(e, index)}
                onDrop={e => handleDrop(e, index, isDisabled)}
                onDragOver={e => onDragOver(e, index)}
              >
                <TableVideoCard
                  videoRules={videoRules}
                  setVideoRules={setVideoRules}
                  key={video.id}
                  video={video}
                  isDragged={index === draggingFromIndex}
                  index={index}
                  isDragDisabled={isDisabled}
                  project={project}
                  onRemove={() => onRemoveManualVideo(video)}
                />
              </StyledSortableElement>
            </React.Fragment>
          );
        })}
      <AddNewVideoButton mode={AddNewVideoButtonModes.BigCard} onClick={onOpenVideoPickerModal} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  grid-template-columns: repeat(auto-fill, minmax(171px, 1fr));
  display: grid;
  gap: 16px;
`;

const StyledSortableElement = styled.div``;

export default DynamicNonPDP;
