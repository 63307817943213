import { track } from './Tracker';

export const hideWidget = () => {
  if (!window.tolstoyWidget) {
    return;
  }

  window.tolstoyWidget.hide();
};

export const identify = async (user, customAttributes = {}) => {
  await window.tolstoyWidget?.identify({
    email: user.email,
    userId: user.id,
    customAttributes,
  });
};

export const recreateWidget = (widgetId, settings) => {
  window.tolstoyWidget?.recreate(widgetId, settings);
};

export const isWidgetShown = () => {
  return window.tolstoyWidget;
};

export const showWidget = (attempts = 0) => {
  if (attempts > 4) {
    return;
  }

  if (!window.tolstoyWidget?.show) {
    setTimeout(() => {
      showWidget(attempts + 1);
    }, 1000);
    return;
  }

  window.tolstoyWidget.on('onWidgetOpen', () => track('Help Widget Click'));

  window.tolstoyWidget.show();
};

export const startWidget = () => {
  if (!window.tolstoyWidget) {
    return;
  }

  window.tolstoyWidget.start();
};

export const changeWidgetEmail = (user, attempts = 0) => {
  if (!user || attempts > 4) {
    return;
  }

  if (window.tolstoyWidget?.changeEmail) {
    window.tolstoyWidget.changeEmail(user);
    return;
  }

  setTimeout(() => {
    changeWidgetEmail(user, attempts + 1);
  }, 1000);
};
