import React from 'react';
import styled from 'styled-components';
import { vodAsset as VodAsset } from 'src/types/entities';
import { ImageRenditionsService } from 'shared/react/services/assets/images';

type Props = {
  image: VodAsset;
};

export const ImagePreview = ({ image }: Props) => {
  const imageSrc = ImageRenditionsService.getRenditionUrl(image);

  return <Image src={imageSrc} alt={image.name} />;
};

const Image = styled.img`
  max-height: 540px;
  max-width: 960px;

  @media (${({ theme }) => theme.breakpoints.laptopMax}) {
    max-height: 410px;
  }
`;

export default ImagePreview;
