import { defaultRef } from './constants';
import { ImageAssetRef } from './ImageAssetRef';
import { VideoAssetRef } from './VideoAssetRef';
import GalleryAssetRef from 'player/src/helpers/assets/GalleryAssetRef';

export class FeedAssetRef {
  constructor(ref = defaultRef, { isImage = false, isGallery = false } = {}) {
    if (isImage) return new ImageAssetRef(ref);

    if (isGallery) return new GalleryAssetRef(ref);

    return new VideoAssetRef(ref);
  }
}

export default FeedAssetRef;
