import React, { FC } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';

import { useVideoActions } from 'src/context/VideosStore';
import { useModalActions } from 'src/context/ui_store/ModalStore';
import { VIDEO_PREVIEW_MODAL_KEY } from 'src/constants/modals.constants';
import VideoActions from '../video_actions/VideoActions';
import Utils from 'shared/react/utils/utils';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

type Props = {
  videoId: string;
  isSubmitting: boolean;
  rejectSuggestion: (videoId: string) => void;
  acceptSuggestion: (videoId: string) => void;
};

const VideoSuggestion: FC<Props> = ({
  videoId,
  isSubmitting,
  rejectSuggestion,
  acceptSuggestion,
}) => {
  const { getVideoById, getVideoPoster } = useVideoActions();
  const { setCurrentModal, setCurrentId } = useModalActions();

  const video = getVideoById(videoId);

  const handleOpenProductsModal = (vodAssetId: string) => () => {
    setCurrentId(vodAssetId);
    setCurrentModal(VIDEO_PREVIEW_MODAL_KEY);
  };

  if (!video) {
    return null;
  }

  return (
    <Container onClick={handleOpenProductsModal(videoId)}>
      <VideoInfo>
        <VideoDetails>
          <VideoDuration>{Utils.secondsToTimeString(video.duration, false)}</VideoDuration>
        </VideoDetails>
        <VideoActions
          videoId={videoId}
          isSubmitting={isSubmitting}
          rejectSuggestion={rejectSuggestion}
          acceptSuggestion={acceptSuggestion}
        />
      </VideoInfo>
      <VideosImage src={getVideoPoster(video)} alt={video.name} />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  font-size: 0px;
  cursor: pointer;
`;

const VideosImage = styled.img`
  object-fit: cover;
  aspect-ratio: 2 / 3;
  height: 240px;
  border-radius: 6px;
`;

const VideoInfo = styled(VerticalFlex)`
  position: absolute;
  bottom: 4px;
  left: 4px;
  right: 4px;
  font-size: 12px;
  gap: 6px;
`;

const VideoDetails = styled(HorizontalFlex)`
  align-items: center;
  justify-content: flex-end;
`;

const VideoDuration = styled.div`
  background-color: ${({ theme }) => rgba(theme.colors.black, 0.5)};
  color: ${({ theme }) => theme.colors.white};
  padding: 2px 4px;
  border-radius: 4px;
`;

export default VideoSuggestion;
