import React from 'react';

const IntercomIcon = props => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M21 0H3C1.344 0 0 1.344 0 3v18c0 1.656 1.344 3 3 3h18c1.656 0 3-1.344 3-3V3c0-1.656-1.344-3-3-3Zm-5.8 4.399c0-.438.359-.801.8-.801.442 0 .802.363.802.8v10.688a.802.802 0 0 1-1.602 0V4.4Zm-4.001-.407a.801.801 0 0 1 1.602 0v11.606a.801.801 0 0 1-1.602 0V3.992Zm-4 .407c0-.438.36-.801.801-.801.442 0 .801.363.801.8v10.688a.802.802 0 0 1-1.602 0V4.4ZM3.2 6a.803.803 0 0 1 1.603 0v7.195a.802.802 0 0 1-1.602 0V6Zm17.32 12.203c-.12.106-3.086 2.594-8.519 2.594s-8.399-2.489-8.52-2.594a.8.8 0 0 1 1.04-1.218c.047.038 2.691 2.21 7.48 2.21 4.848 0 7.453-2.187 7.477-2.207a.8.8 0 1 1 1.042 1.215Zm.282-5.008a.802.802 0 0 1-1.602 0V6a.802.802 0 0 1 1.6 0v7.195Z"
        fill="#0057FF"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default IntercomIcon;
