import React from 'react';

const PageIcon = () => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 2C0 .906.875 0 2 0h12c1.094 0 2 .906 2 2v10c0 1.125-.906 2-2 2H2c-1.125 0-2-.875-2-2V2Zm5 1h10V2c0-.531-.469-1-1-1H5v2ZM4 1H2c-.563 0-1 .469-1 1v1h3V1ZM1 4v8c0 .563.438 1 1 1h12c.531 0 1-.438 1-1V4H1Z"
      fill="#090A0B"
    />
  </svg>
);

export default PageIcon;
