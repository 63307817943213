import React from 'react';
import useFeedAccount from 'shared/react/components/complex/context/hooks/useFeedAccount';
import useFeedFeatures from 'shared/react/components/complex/context/hooks/useFeedFeatures';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';
import useTracker from 'shared/react/components/complex/context/hooks/useTracker';
import FeedBrandedButton from 'shared/react/components/complex/feed/feed_buttons/FeedBrandedButton';
import { REACT_APP_API_BASE_URL } from 'shared/react/config/shared.config';
import { FEATURE_FEED_HIDE_CHECKOUT_BUTTON } from 'shared/react/constants/features.constants';
import UrlHelper from 'shared/react/utils/urlHelper';
import Utils from 'shared/react/utils/utils';

const FeedCheckoutButton = ({ location, buttonText }) => {
  const [, { track }] = useTracker();
  const [{ appUrl }] = useProjectConfig();
  const [{ appKey }] = useFeedAccount();
  const [, { getFeatureSettingsByKey }] = useFeedFeatures();
  const { shouldHide } = getFeatureSettingsByKey(FEATURE_FEED_HIDE_CHECKOUT_BUTTON);

  const handleCheckoutClick = () => {
    track('clickCart', Utils.getAnalyticsLocationObject(location));

    const siteUrl = Utils.getSiteUrl() || appUrl;
    const utmParams = UrlHelper.getUtmParams();
    const redirectUrl = UrlHelper.appendSearchParamsToUrl(`${siteUrl}/checkout`, utmParams);

    const urlParams = new URLSearchParams({
      redirectUrl: redirectUrl,
      checkout: new Date().toISOString(),
      appKey,
      anonymousId: window.anonymousId,
    });

    const url = new URL(`${REACT_APP_API_BASE_URL}/users/actions/user/redirect`);

    url.search = urlParams.toString();

    Utils.openInNewTab(url.toString());
  };

  if (shouldHide) {
    return null;
  }

  return <FeedBrandedButton buttonText={buttonText} onClick={handleCheckoutClick} />;
};

export default FeedCheckoutButton;
