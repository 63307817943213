import React from 'react';
import styled from 'styled-components';
import { VerticalFlexCentered } from './flex_layouts/VerticalFlex';
import ImageWithLoader from './ImageWithLoader';

const ImageWithBackdrop = ({ src, className }) => {
  return (
    <LayoutRoot className={className}>
      <Image src={src} />
      <BackdropImage src={src} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlexCentered)`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.background};
`;

const Image = styled(ImageWithLoader)`
  z-index: 1;

  & > img {
    border-radius: 0;
    backdrop-filter: blur(1px);
  }
`;

const BackdropImage = styled(ImageWithLoader)`
  position: absolute;
  inset: 0;
  z-index: 0;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0;
    filter: blur(6px);
  }
`;

export default ImageWithBackdrop;
