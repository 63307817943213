import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Volume2, VolumeX } from 'lucide-react';
import HorizontalFlex, {
  Gap8HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextSmall } from 'shared/react/components/basic/text/TextV2';
import { useTopBarContext } from 'app/src/context/TopBarStore';
import { GhostButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { track } from 'app/src/helpers/Tracker';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import {
  ADD_PRODUCTS_MODAL_KEY,
  GALLERY_MODAL_KEY,
  MOVE_TOLSTOY_MODAL_KEY,
  VIDEO_DELETE_MODAL_KEY,
  VIDEO_ALLOW_SOUND_MODAL_KEY,
  VIDEO_DISALLOWED_SOUND_MODAL_KEY,
} from 'app/src/constants/modals.constants';
import { FolderType } from 'app/src/types/entities';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { useVideos } from 'app/src/context/VideosStore';
import { FEATURE_DYNAMIC_FEED } from 'app/src/constants/appFeatures.constants';
import { useApps } from 'app/src/context/AppsStore';
import { TIKTOK } from 'app/src/constants/intergrations.constants';
import ArrowDownToLineIcon from 'app/src/images/ArrowDownToLineIcon';
import FilledTrashIcon from 'app/src/images/sidebar-v2/FilledTrashIcon';
import FilledFolderIcon from 'app/src/images/dashboard_v2/FilledFolderIcon';
import TopBarActionButton from './TopBarActionButton';
import { TopBarButtonProps } from './types/TopBar.types';
import ShoppingBagIcon from 'app/src/images/sidebar-v2/ShoppingBagIcon';
import LabelsMenu from 'app/src/pages/dashboard/components/top-bar/labels-menu/LabelsMenu';
import MultiTagIcon from 'app/src/images/MultiTagIcon';
import { ASSET_TYPE } from 'shared/react/services/assets';
import { PrimaryButton } from 'shared/react/components/basic/button-v2/BasicButton';

const VideosMultiSelectTopBar = () => {
  const [menuRef, setMenuRef] = useState(null);
  const { videosMultiSelectState } = useTopBarContext();
  const [, { setCurrentIds, setCurrentModal, setModalProps }] = useModal();
  const [, { getFeatureEnabled }] = useFeatures();
  const [, { downloadVideo, getVideoPosterLarge }] = useVideos();
  const isDynamicEnabled = getFeatureEnabled(FEATURE_DYNAMIC_FEED);
  const [, { isEcomPlatformConnected }] = useApps();
  const [isImagesState, setIsImagesState] = useState(false);

  const {
    videos,
    onSelectedVideosAction,
    selectedVideos,
    setSelectedVideos,
    shouldShowDelete,
    disableDownload,
  } = videosMultiSelectState || {};

  const selectAll = () => {
    setSelectedVideos(videos.map(v => v.id));
  };

  const onClose = () => {
    setSelectedVideos([]);
  };

  const getVideos = () => {
    return videos.filter(vod => selectedVideos.includes(vod.id));
  };

  const onDelete = () => {
    track('Multiple Videos Delete Click', { numberOfVideos: selectedVideos.length });
    setCurrentModal(VIDEO_DELETE_MODAL_KEY);
    setCurrentIds(selectedVideos);
    onClose();
  };

  const onTag = () => {
    track('Multiple Videos Tag Click', { numberOfVideos: selectedVideos.length });
    setCurrentModal(ADD_PRODUCTS_MODAL_KEY);
    setCurrentIds(selectedVideos);
    const video = videos.find(({ id }) => id === selectedVideos[0]);
    const videoUrl = video?.app !== TIKTOK && video?.videoUrl;
    onClose();
    setModalProps({
      thumbnail: getVideoPosterLarge(video),
      videoUrl,
    });
    onSelectedVideosAction?.();
  };

  const moveToFolder = async () => {
    track('Multiple Videos Move Click', { numberOfVideos: selectedVideos.length });
    setCurrentModal(MOVE_TOLSTOY_MODAL_KEY);
    setModalProps({ ids: selectedVideos, type: FolderType.videos });
    onSelectedVideosAction?.();
    onClose();
  };

  const handleDownload = async () => {
    track('Multiple Videos Download Click', { numberOfVideos: selectedVideos.length });
    const videos = getVideos();

    const promises = videos.map(downloadVideo);
    await Promise.all(promises);

    onClose();
  };

  const onLabel = e => {
    setMenuRef(e.currentTarget);
  };

  const onCreateGallery = () => {
    setCurrentModal(GALLERY_MODAL_KEY);
    setCurrentIds(selectedVideos);
    const selectedImages = videos.filter(video => selectedVideos.includes(video.id));
    setModalProps({ selectedImages });
    onClose();
  };

  const selectButtons: TopBarButtonProps[] = [
    {
      key: 'select-all',
      children: <span>Select all</span>,
      onClick: selectAll,
      iconOnly: false,
      buttonComponent: StyledGhostButton,
      hidden: false,
    },
    {
      key: 'clear-all',
      children: <span>Clear all</span>,
      onClick: onClose,
      iconOnly: false,
      buttonComponent: StyledGhostButton,
      hidden: false,
    },
  ];

  const restoreSound = () => {
    track('Multiple Videos Sound Allowed Click', { numberOfVideos: selectedVideos.length });
    setCurrentModal(VIDEO_ALLOW_SOUND_MODAL_KEY);
    setCurrentIds(selectedVideos);
    onClose();
  };

  const removeSound = () => {
    track('Multiple Videos Sound Disallowed Click', { numberOfVideos: selectedVideos.length });
    setCurrentModal(VIDEO_DISALLOWED_SOUND_MODAL_KEY);
    setCurrentIds(selectedVideos);
    onClose();
  };

  const actionButtons: TopBarButtonProps[] = [
    {
      key: 'remove-sound',
      children: <VolumeX />,
      tooltipText: 'Remove sound',
      onClick: removeSound,
      iconOnly: true,
      buttonComponent: StyledGhostButton,
      hidden: false,
    },
    {
      key: 'restore-sound',
      children: <Volume2 />,
      tooltipText: 'Restore sound',
      onClick: restoreSound,
      iconOnly: true,
      buttonComponent: StyledGhostButton,
      hidden: false,
    },
    {
      key: 'delete',
      children: <FilledTrashIcon />,
      tooltipText: `Delete (${selectedVideos.length})`,
      onClick: onDelete,
      iconOnly: true,
      buttonComponent: StyledGhostButton,
      hidden: !shouldShowDelete,
    },
    {
      key: 'tag-products',
      children: <ShoppingBagIcon />,
      tooltipText: `Tag products (${selectedVideos.length})`,
      onClick: onTag,
      iconOnly: true,
      buttonComponent: StyledGhostButton,
      hidden: !isEcomPlatformConnected() || !isDynamicEnabled,
    },
    {
      key: 'custom-playlist',
      children: <MultiTagIcon />,
      tooltipText: `Custom Playlists (${selectedVideos.length})`,
      onClick: onLabel,
      iconOnly: true,
      buttonComponent: StyledGhostButton,
    },
    {
      key: 'move-to-folder',
      children: <FilledFolderIcon />,
      tooltipText: `Move to folder (${selectedVideos.length})`,
      onClick: moveToFolder,
      iconOnly: true,
      buttonComponent: StyledGhostButton,
    },
    {
      key: 'download',
      children: <ArrowDownToLineIcon />,
      tooltipText: `Download (${selectedVideos.length})`,
      onClick: handleDownload,
      iconOnly: true,
      buttonComponent: StyledGhostButton,
      hidden: disableDownload,
    },
    {
      key: 'cancel',
      children: 'Cancel',
      tooltipText: null,
      onClick: onClose,
      buttonComponent: StyledGhostButton,
      hidden: false,
    },
  ];

  if (isImagesState) {
    actionButtons.push({
      key: 'create-gallery',
      children: '+ New carousel',
      tooltipText: null,
      onClick: onCreateGallery,
      buttonComponent: PrimaryButton,
    });
  }

  useEffect(() => {
    setIsImagesState(videos.every(video => video.type === ASSET_TYPE.IMAGE));
  }, [videos]);

  return (
    <LayoutRoot>
      <Title>{selectedVideos.length} selected</Title>
      <ButtonsContainer>
        {selectButtons.map(button => (
          <TopBarActionButton {...button} key={button.key} />
        ))}
      </ButtonsContainer>
      <Spacer />
      <ButtonsContainer>
        {actionButtons.map(button => (
          <TopBarActionButton {...button} key={button.key} />
        ))}
      </ButtonsContainer>
      <LabelsMenu menuRef={menuRef} onClose={onClose} selectedVideos={selectedVideos} />
    </LayoutRoot>
  );
};

const Spacer = styled.div`
  flex-grow: 1;
`;

const LayoutRoot = styled(HorizontalFlex)`
  background: ${({ theme }) => theme.colors.neutralDeep};
  padding: 16px 24px;
  height: 64px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutralLight};
  column-gap: 10px;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    gap: 8px;
  }
`;

const Title = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.neutralGray};
`;

const ButtonsContainer = styled(Gap8HorizontalFlex)`
  height: 100%;
  color: ${({ theme }) => theme.colors.neutralLight};

  & svg path {
    fill: ${({ theme }) => theme.colors.neutralLight};
  }
`;

const StyledGhostButton = styled(GhostButton)`
  color: ${({ theme }) => theme.colors.neutralLight};

  & svg path {
    fill: ${({ theme }) => theme.colors.neutralLight};
  }

  & svg {
    width: 16px;
    height: 16px;
  }
`;

export default VideosMultiSelectTopBar;
