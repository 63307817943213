export const TOLSTOY_MODAL_OPEN_MESSAGE = 'tolstoyModalOpen';
export const TOLSTOY_MODAL_CLOSE_MESSAGE = 'tolstoyModalClose';
export const TOLSTOY_PLAYER_READY_MESSAGE = 'tolstoyPlayerReady';
export const TOLSTOY_PLAYER_VIDEO_METADATA_CHANGE_MESSAGE = 'tolstoyPlayerMetadataChange';
export const TOLSTOY_PLAYER_MUTED_MESSAGE = 'tolstoyPlayerMutedChange';
export const TOLSTOY_PLAYER_PLAY_PAUSE_MESSAGE = 'tolstoyPlayerPlayPause';
export const TOLSTOY_PLAYER_PLAY_BACK_RATE_MESSAGE = 'tolstoyPlayerPlayBackRate';
export const TOLSTOY_PLAYER_SEEK_MESSAGE = 'tolstoyPlayerSeek';
export const TOLSTOY_PLAYER_VOLUME_CHANGE_MESSAGE = 'tolstoyVolumeChange';
export const TOLSTOY_PLAYER_PLAY_MESSAGE = 'tolstoyPlay';
export const TOLSTOY_ESCAPE_KEY_PRESSED_MESSAGE = 'tolstoyEscapeKeyPressed';
export const TOLSTOY_WATCHED_PRODUCT_IDS_MESSAGE = 'tolstoyWatchedProductIds';
export const TOLSTOY_REPORT_MODAL_OPEN_MESSAGE = 'tolstoyReportModalOpen';
export const TOLSTOY_REPORT_MODAL_CLOSE_MESSAGE = 'tolstoyReportModalClose';
export const SHOW_FEED_PRODUCT_MODAL_MESSAGE = 'showFeedProductModal';
export const TOGGLE_FEED_CLOSE_BUTTON_MESSAGE = 'toggleFeedCloseButton';
export const SHOW_FEED_CART_MOBILE_MESSAGE = 'showFeedCartMobile';
export const FEED_PLAY_MESSAGE = 'feedPlay';
export const FEED_PAUSE_MESSAGE = 'feedPause';
export const VIDEO_UN_MUTED_MESSAGE = 'videoUnmuted';
export const PRODUCT_SELECTED_MESSAGE = 'productSelected';
export const TOLSTOY_MOVE_TO_URL = 'tolstoyMoveToUrl';
export const TOLSTOY_CLOSE_MODAL_MESSAGE = 'tolstoyCloseModalMessage';
export const OPEN_REBUY_CART_MESSAGE = 'tolstoyOpenRebuyCart';
export const OPEN_KENDO_MODAL_MESSAGE = 'tolstoyOpenKendoModal';
export const IS_REBUY_APP_INSTALLED_MESSAGE = 'tolstoyIsRebuyAppInstalled';
export const OPEN_TAPCART_PRODUCT_MESSAGE = 'tolstoyOpenTapcartProduct';
export const IS_AFTERPAY_APP_INSTALLED_MESSAGE = 'tolstoyIsAfterpayAppInstalled';
export const PRODUCT_CARD_CLICK_MESSAGE = 'tolstoyProductCardClick';
export const PRODUCT_CARD_CLICK_SUBSCRIBED_MESSAGE = 'tolstoyProductCardClickSubscribed';
export const URL_LOCALE_UPDATE_MESSAGE = 'tolstoyUrlLocaleUpdate';
export const REDIRECT_TO_URL_MESSAGE = 'tolstoyRedirectToUrl';
export const REQUEST_PRODUCTS_UPDATE_MESSAGE = 'tolstoyRequestProductsUpdate';
export const PRODUCT_UPDATE_RESPONSE_MESSAGE = 'tolstoyProductUpdateResponse';
export const IS_NON_BASE_CURRENCY_MESSAGE = 'tolstoyIsNonBaseCurrency';
export const MODAL_MESSAGING_READY_MESSAGE = 'tolstoyModalMessagingReady';

export const PRE_CONFIG_MESSAGES = {
  ready: 'tolstoyPreConfigMessengerReady',
  vodAssetIds: 'tolstoyVodAssetIds',
};
