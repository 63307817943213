import { useOpenVideoPickerModal } from 'src/hooks/modals';
import { useRulesActions } from 'app/src/context/RulesStore';
import { getOrderVodRuleTypeKey, getHideVodRuleTypeKey } from 'app/src/context/RulesStore';
import { rule as RuleType } from 'app/src/types/entities';
import { HIDE_VOD_ASSET_TYPE, ORDER_FEED_TYPE } from 'app/src/constants/ruleGroups.constants';
import { useDynamicConditionsContext } from '../components/dynamic-conditions/context/DynamicConditionsContext';
import { useAppActions } from 'app/src/context/AppsStore';
import { useModalActions } from 'app/src/context/ui_store/ModalStore';
import {
  ADD_PRODUCTS_MODAL_KEY,
  VIDEO_PREVIEW_MODAL_KEY,
} from 'app/src/constants/modals.constants';

export type UseDynamicActions = {
  hideVideo: (videoId: string, productId?: string) => void;
  showVideo: (videoId: string, order: number, productId?: string) => void;
  lockVideo: (videoId: string, order: number, productId?: string) => void;
  unlockVideo: (videoId: string, order: number, productId?: string) => void;
  loadMore: (params: { startIndex: number; stopIndex: number }) => Promise<void>;
  openProductsModal: ({ video, analyticsData }) => void;
  openVideoPickerModal: ({ onVideoPicked, onModalClosed }: any) => void;
};

export const useDynamicTypeActions = (): UseDynamicActions => {
  const { createHideVideoRule, createFeedOrderRule } = useRulesActions();
  const { videoRules, setVideoRules } = useDynamicConditionsContext();
  const { getProductsCatalogApp } = useAppActions();
  const productsCatalogApp = getProductsCatalogApp();
  const { setCurrentModal, setCurrentIds, setModalProps, setCurrentId } = useModalActions();
  const openVideoPickerModal = useOpenVideoPickerModal();

  const openProductsModal = ({ video, analyticsData }) => {
    if (!productsCatalogApp) {
      setCurrentId(video.id);
      setCurrentModal(VIDEO_PREVIEW_MODAL_KEY);
      return;
    }

    setModalProps({ analyticsData });
    setCurrentIds([video.id]);
    setCurrentModal(ADD_PRODUCTS_MODAL_KEY);
  };

  const loadMore = async ({ startIndex, stopIndex }) => {};

  const hideVideo = (videoId, externalProductid = null) => {
    const newRule = createHideVideoRule(videoId, externalProductid);

    setVideoRules([...videoRules, newRule] as RuleType[]);
  };

  const showVideo = (videoId, order, externalProductid = null) => {
    const newRules = videoRules.filter(({ value, type }) => {
      if (type === HIDE_VOD_ASSET_TYPE) {
        return value !== getHideVodRuleTypeKey(videoId, externalProductid);
      }

      if (type === ORDER_FEED_TYPE) {
        return value !== getOrderVodRuleTypeKey(videoId, order, externalProductid);
      }

      return true;
    });

    setVideoRules(newRules);
  };

  const lockVideo = (videoId, order, externalProductid = null) => {
    const newRule = createFeedOrderRule(videoId, order, externalProductid);

    setVideoRules([...videoRules, newRule] as RuleType[]);
  };

  const unlockVideo = (videoId, order, externalProductid = null) => {
    const newRules = videoRules.filter(
      ({ value }) => value !== getOrderVodRuleTypeKey(videoId, order, externalProductid)
    );

    setVideoRules(newRules);
  };

  return {
    loadMore,
    hideVideo,
    showVideo,
    lockVideo,
    unlockVideo,
    openProductsModal,
    openVideoPickerModal,
  };
};

export default useDynamicTypeActions;
