import { createContext, useContext } from 'react';
import { rule as RuleType } from 'app/src/types/entities';
import { MatchConditions } from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/constants/dynamicConditions.constants';
import { UseFormReturn } from 'react-hook-form';
import { DynamicTypeFormValues } from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/DynamicTypeContent';

type dynamicConditionsContextType = {
  methods: UseFormReturn<DynamicTypeFormValues>;
  isShop: boolean;
  vodAssetIds: string[];
  rules: RuleType[];
  matchConditions: MatchConditions;
  videoRules: RuleType[];
  setRules: (rules: RuleType[]) => void;
  setMatchConditions: (matchConditions: MatchConditions) => void;
  setVideoRules: (videoRules: RuleType[]) => void;
};
export const dynamicConditionsContext = createContext<dynamicConditionsContextType>(
  {} as dynamicConditionsContextType
);

export const DynamicConditionsProvider = dynamicConditionsContext.Provider;

export const useDynamicConditionsContext = () => useContext(dynamicConditionsContext);
