import React, { useRef } from 'react';
import { RichUtils, Modifier } from 'draft-js';
import EditorState from 'draft-js/lib/EditorState';
import styled, { useTheme } from 'styled-components';
import LinkIcon from '../../icons/LinkIcon';
import LinkPopup from './LinkPopup';
import useOutsideClick from 'shared/react/hooks/useOutsideClick';
import { HYPER_LINK } from '../../constants/plugins.constants';

const getCurrentTextSelection = (editorState, selectionState) => {
  if (!selectionState) {
    return '';
  }
  const anchorKey = selectionState.getAnchorKey();
  const currentContent = editorState.getCurrentContent();
  const currentContentBlock = currentContent.getBlockForKey(anchorKey);
  const start = selectionState.getStartOffset();
  const end = selectionState.getEndOffset();
  const selectedText = currentContentBlock.getText().slice(start, end);

  return selectedText;
};

const LinkButton = ({
  editorState,
  setEditorState,
  track,
  setSelectionState,
  selectionState,
  setSelectedPlugin,
  selectedPlugin,
}) => {
  const theme = useTheme();
  const popupRef = useRef();
  const isOpen = selectedPlugin === HYPER_LINK;

  const onIconClick = () => {
    if (isOpen) {
      onClose();
      return;
    }

    track('Rich Text Link Opened Click');
    setSelectedPlugin(HYPER_LINK);
    setSelectionState(editorState.getSelection());
  };

  const onClose = () => {
    track('Rich Text Link Closed Click');
    setSelectedPlugin(null);
  };

  const onAddLink = (text, link) => {
    track(`Rich Text Link Save Click`);
    const content = editorState
      .getCurrentContent()
      .createEntity('LINK', 'MUTABLE', { url: link, text });

    const entityKey = content.getLastCreatedEntityKey();

    const contentStateWithEntity = Modifier.replaceText(
      content,
      selectionState,
      text,
      editorState.getCurrentInlineStyle(),
      entityKey
    );

    const newEditorState = EditorState.push(editorState, contentStateWithEntity, 'create-entity');
    const newEntityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newSelectionState = newEditorState.getSelection();
    setEditorState(RichUtils.toggleLink(newEditorState, newSelectionState, newEntityKey));
    onClose();
    return 'handled';
  };

  useOutsideClick([popupRef], onClose);

  return (
    <LayoutRoot ref={popupRef}>
      <IconContainer isSelected={isOpen} onMouseDown={onIconClick}>
        <LinkIcon fill={isOpen ? theme.colors.white : undefined} />
      </IconContainer>
      {isOpen && (
        <LinkPopup
          defaultText={getCurrentTextSelection(editorState, selectionState)}
          onClose={onClose}
          onSave={onAddLink}
        />
      )}
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  position: relative;
  display: flex;
`;

const IconContainer = styled.div`
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background: ${({ isSelected, theme }) => (isSelected ? theme.colors.gray2 : '')};
  justify-content: center;
  width: 20px;
  height: 20px;
  transition: 0.3s;
  &:hover {
    background: ${({ isSelected, theme }) =>
      isSelected ? theme.colors.gray2 : theme.colors.ghostLight};
  }
`;

export default LinkButton;
