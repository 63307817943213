import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import EmptyStateCard from 'app/src/pages/dashboard/pages/libraries/tolstoys_tab/empty_state/EmptyStateCard';
import EmptyStateBody from 'app/src/pages/dashboard/pages/libraries/tolstoys_tab/empty_state/EmptyStateBody';
import { EMPTY_STATE_TEST_ID } from 'app/src/constants/dataTestIds.constants';

const TolstoysTabEmptyState = () => {
  const [numberOfCards, setNumberOfCards] = useState(0);
  const skeletonsContainerRef = useRef();

  const getNumberOfCards = () => {
    const containerWidth = skeletonsContainerRef?.current?.clientWidth;
    return Math.floor(containerWidth / 300);
  };

  const updateNumberOfCards = () => {
    setNumberOfCards(getNumberOfCards());
  };

  const getCards = () => {
    return new Array(numberOfCards).fill(<EmptyStateCard />);
  };

  useEffect(() => {
    if (!skeletonsContainerRef.current) {
      return;
    }

    updateNumberOfCards();
  }, [skeletonsContainerRef?.current?.clientWidth]);

  useEffect(() => {
    window.addEventListener('resize', updateNumberOfCards);

    return () => window.removeEventListener('resize', updateNumberOfCards);
  }, []);

  return (
    <LayoutRoot data-test-id={EMPTY_STATE_TEST_ID}>
      <SkeletonsContainer ref={skeletonsContainerRef}>{getCards()}</SkeletonsContainer>
      <EmptyStateBody />
    </LayoutRoot>
  );
};

const SkeletonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  gap: 24px;
  width: 100%;
  overflow: hidden;
`;

const LayoutRoot = styled(VerticalFlex)`
  gap: 108px;
  padding-right: 24px;
`;

export default TolstoysTabEmptyState;
