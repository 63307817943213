import { useFeatures } from 'app/src/context/FeaturesStore';
import { useUser } from 'app/src/context/userStore/UserStore';
import { useEffect, useState } from 'react';
import { useSessions } from 'app/src/context/SessionsStore';
import Utils from '../utils';
import { navigateToInviteInvalidMessage } from '../utils/navigation.utils';
import { useHistory } from 'react-router-dom';
import {
  fetchCurrentAccountDetails,
  getCubejsToken,
  useAccount,
  validateInvite,
} from 'app/src/context/AccountStore';
import { cancelRequests } from '../helpers/API';
import { useShareTypes } from 'app/src/context/ui_store/ShareTypesStore';
import { useDashboard } from 'app/src/context/ui_store/DashboardStore';
import { usePackages } from 'app/src/context/PackagesStore';
import { identify as widgetIdentify } from '../helpers/Widget';
import { group as analyticsGroup, identify as analyticsIdentify } from '../helpers/Tracker';
import { useInAppNotifications } from 'app/src/context/InAppNotificationsStore';
import { BILLING_PACKAGE_TYPES } from 'app/src/constants/billings.constants';
import { logRocketLogLocalStorage } from 'app/src/utils/logRocket.utils';
import { useTikTok } from 'app/src/context/TikTokStore';
import { useInstagram } from 'app/src/context/InstagramStore';
import { useHubspot } from 'app/src/context/integrations/HubspotStore';
import { LOGIN_APP_KEY_LOCAL_STORAGE_KEY } from 'app/src/constants/tolstoy.constants';
import { useSynthesiaActions } from 'app/src/context/SynthesiaStore';
import { useGoogleDriveActions } from 'app/src/context/GoogleDriveStore';
import { intercomBoot } from 'app/src/utils/intercom.utils';
import APP_CONFIG from 'app/src/config/app.config';

let initialized = false;
let isFetchingAccount = false;
const useOnInit = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [accountDetails, setAccountDetails] = useState();
  const [, { fetchFeatures }] = useFeatures();
  const [{ user }, { clearUser, fetchAccount, subscribeAccount, unsubscribeAccount }] = useUser();
  const [, { subscribeNotificationCreated, unsubscribeNotificationCreated, setError }] =
    useAccount();
  const [
    ,
    {
      fetchInAppNotificationsByTeamMemberId,
      subscribeInAppNotifications,
      unsubscribeInAppNotifications,
    },
  ] = useInAppNotifications();

  const [, { clearResponsesStore, clearSessionsStore, fetchProjectResponseCounters }] =
    useSessions();
  const [, { clearShareTypes }] = useShareTypes();
  const [, { clearDashboard }] = useDashboard();
  const [, { clearStore: clearTikTok }] = useTikTok();
  const [, { clearStore: clearInstagram }] = useInstagram();
  const { clearSynthesia } = useSynthesiaActions();
  const [, { clearStore: clearHubspot }] = useHubspot();
  const { clearGoogleDrive } = useGoogleDriveActions();
  const [, { getPlan, getPackage }] = usePackages();
  const [, { clearState: clearInAppNotifications }] = useInAppNotifications();

  const userId = user?.owner;
  const deleteInviteIfNotValid = async history => {
    const invite = Utils.getInvite();

    if (!invite) {
      return;
    }

    const isValid = await validateInvite(invite.inviteToken);

    if (isValid) {
      return;
    }

    if (user) {
      setError({
        title: 'You are already connected to Tolstoy',
        subtitle: 'Try logging out of your account and then reuse the invite link',
      });
    }

    navigateToInviteInvalidMessage(history);
  };

  useEffect(() => {
    logRocketLogLocalStorage();
    if (userId) {
      setCurrentAccountDetails(userId);
    }

    return () => {
      if (userId) {
        cancelRequests();
      }

      initialized = false;
      window.removeEventListener('message', hasSeenPricingTolstoy);
    };
  }, [userId]);

  useEffect(() => {
    if (accountDetails?.appKey) {
      initStores(accountDetails?.appKey, userId);
    }

    return () => {
      unsubscribeStores();
      if (accountDetails?.appKey) {
        clearStores();
      }
    };
  }, [accountDetails?.appKey]);

  const setCurrentAccountDetails = async userId => {
    if (isFetchingAccount) {
      return;
    }

    isFetchingAccount = true;
    const currentAppKey = localStorage.getItem(LOGIN_APP_KEY_LOCAL_STORAGE_KEY);
    localStorage.removeItem(LOGIN_APP_KEY_LOCAL_STORAGE_KEY);
    const user = await fetchCurrentAccountDetails(userId, currentAppKey);
    await getCubejsToken();

    const { appKey, isAccountOwner, accounts } = user;

    setAccountDetails({ appKey, owner: userId, isAccountOwner, accounts });
    isFetchingAccount = false;
  };

  const identifyUserPackage = account => {
    if (Utils.isGhostAccount()) {
      return;
    }

    const currentPackage = getPackage(account?.packageId);

    if (currentPackage?.id && currentPackage.name) {
      const identifyData = {
        packageId: currentPackage.id,
        packageName: currentPackage.name,
        isFreePackage: currentPackage.type === BILLING_PACKAGE_TYPES.free ? 1 : 0,
        isPricing: 1,
      };
      analyticsIdentify(userId, identifyData);
      analyticsGroup(account.appKey, {
        plan: identifyData.packageName,
      });
      widgetIdentify(user, identifyData);

      intercomBoot({
        user_id: userId,
        Package: currentPackage.name,
      });

      window.removeEventListener('message', hasSeenPricingTolstoy);
      window.addEventListener('message', hasSeenPricingTolstoy);
    } else {
      widgetIdentify(user, {
        isPricing: 0,
      });
    }
  };

  const hasSeenPricingTolstoy = event => {
    if (
      event?.data?.name === 'tolstoyStarted' &&
      event?.data?.publishId === APP_CONFIG.TOLSTOY_PRICING_ONBOARDING_ID
    ) {
      widgetIdentify(user, {
        seenPricingTolstoy: 1,
      });
    }
  };

  const initStores = async (appKey, owner) => {
    if (initialized || loading) {
      return;
    }

    setLoading(true);

    subscribeStoresByAppKey(appKey);
    const teamMemberId = `${appKey}_${owner}`;
    subscribeStoresByTeamMemberId(teamMemberId);

    const [account] = await Promise.all([
      fetchAccount(appKey),
      fetchProjectResponseCounters(),
      fetchInAppNotificationsByTeamMemberId(teamMemberId),
      getPlan(),
      fetchFeatures(appKey),
      deleteInviteIfNotValid(history),
    ]);

    identifyUserPackage(account);

    initialized = true;
    setLoading(false);
  };

  const clearStores = () => {
    clearUser();
    clearResponsesStore();
    clearSessionsStore();
    clearShareTypes();
    clearDashboard();
    clearInAppNotifications();
    clearTikTok();
    clearInstagram();
    clearHubspot();
    clearSynthesia();
    clearGoogleDrive();
  };

  const subscribeStoresByAppKey = appKey => {
    subscribeAccount({ appKey, fetchFeatures, getPlan });
  };

  const subscribeStoresByTeamMemberId = teamMemberId => {
    subscribeInAppNotifications(teamMemberId);
    subscribeNotificationCreated(teamMemberId);
  };

  const unsubscribeStores = () => {
    unsubscribeAccount();
    unsubscribeInAppNotifications();
    unsubscribeNotificationCreated();
  };

  return {
    loading,
    initialized,
    accountDetails,
  };
};

export default useOnInit;
