import React from 'react';
import styled from 'styled-components';
import { TextSubtitle } from 'shared/react/components/complex/Text';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import RulesSummaryContent from 'app/src/pages/project/pages/rules_and_triggers/summary/RulesSummaryContent';
import { useRulesContext } from 'app/src/pages/project/pages/rules_and_triggers/RulesContext';

function RulesSummary({ project }) {
  const { ruleGroup, showOnDevice, showOnDomains } = useRulesContext();
  const isEmptyState = !ruleGroup?.exitIntent && !ruleGroup?.rules?.length;

  if (project?.feed) {
    return null;
  }

  return (
    <LayoutRoot>
      {!isEmptyState && <TextSubtitle>Summary</TextSubtitle>}
      <Gap8VerticalFlex>
        <RulesSummaryContent
          ruleGroup={ruleGroup}
          showOnDevice={showOnDevice}
          isEmptyState={isEmptyState}
          showOnDomains={showOnDomains}
        />
      </Gap8VerticalFlex>
    </LayoutRoot>
  );
}

export default RulesSummary;

const LayoutRoot = styled(Gap8VerticalFlex)`
  background: ${({ theme }) => theme.colors.white};
  gap: 32px;
  width: 371px;
  height: fit-content;
  max-width: 100%;
  padding: 24px;
  border-radius: 12px;
  position: sticky;
  top: 24px;
`;
