import { useModalContext } from 'shared/react/components/complex/context/ModalContext';
import Utils from 'shared/react/utils/utils';

const useFeedModal = () => {
  const { modalsStack, setModalsStack } = useModalContext();

  const getCurrentModal = () => {
    if (!modalsStack?.length) {
      return null;
    }
    return modalsStack[modalsStack.length - 1];
  };

  const clearModalState = () => {
    setModalsStack([]);
  };

  const pushModal = modal => {
    const currentModal = getCurrentModal();
    if (currentModal?.key === modal.key) {
      return Utils.logError({ message: `Could not open an opened modal (${modal.key})` });
    }

    setModalsStack([...modalsStack, modal]);
  };

  const popModal = () => {
    if (!modalsStack.length) {
      return Utils.logError({ message: 'Could not close modal because there is no opened modal' });
    }

    const newModalsStack = modalsStack.slice(0, modalsStack.length - 1);
    setModalsStack(newModalsStack);
  };

  const currentModal = getCurrentModal();
  const actions = { clearModalState, pushModal, popModal };

  return [{ currentModal }, actions];
};

export default useFeedModal;
