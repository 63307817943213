let sentryOnDownloadCallbacks = [];

const getIsSentryDownloaded = () => {
  return !!window.Sentry;
};

export const sentryAddCustomTag = ({ key, value }) => {
  sentryOnLoad(() => {
    window.Sentry.setTag(key, value);
  });
};

export const sentryAddBreadcrumb = ({ data, level = 'info', message, category }) => {
  const timestamp = new Date().toISOString();
  sentryOnLoad(() => {
    window.Sentry.addBreadcrumb({ data, level, message, category, timestamp });
  });
};

export const sentryConfigureScope = ({ key, value }) => {
  sentryOnLoad(() => {
    window.Sentry.configureScope(scope => {
      scope.setExtra(key, value);
    });
  });
};

export const sentryOnLoad = callback => {
  if (getIsSentryDownloaded()) {
    window.Sentry.onLoad(callback);
    return;
  }
  sentryOnDownloadCallbacks.push(callback);
};

export const sentryOnDownloaded = () => {
  sentryOnDownloadCallbacks.forEach(callback => {
    try {
      window.Sentry.onLoad(callback);
    } catch (error) {
      console.error(error);
    }
  });
  sentryOnDownloadCallbacks = [];
};
