import React, { FC } from 'react';
import InstallStepSimple from 'app/src/pages/project/pages/installation/components/shared/InstallStepSimple';
import { project as Project } from 'src/types/entities';
import InstallSetLiveStepDescription from 'app/src/pages/project/pages/installation/components/shared/InstallSetLiveStepDescription';

type InstallSetLiveStepProps = {
  isDisabled: boolean;
  stepNumber?: number;
  project: Project;
  startExpanded?: boolean;
};

const InstallSetLiveStep: FC<InstallSetLiveStepProps> = props => {
  const { isDisabled, stepNumber = 3, project, startExpanded } = props;

  const text = project?.installationCompleted
    ? 'You’re ready to go! Click the set live button. Don’t worry, you can pause it any time!'
    : 'Did you complete the setup and add the section to your Shopify theme?';

  return (
    <InstallStepSimple
      isDisabled={isDisabled}
      text={text}
      stepNumber={stepNumber}
      stepHeader="Complete and set live"
      startExpanded={startExpanded}
      description={<InstallSetLiveStepDescription project={project} isDisabled={isDisabled} />}
    />
  );
};

export default InstallSetLiveStep;
