import React from 'react';
import { useTheme } from 'styled-components';

const SettingsIcon = ({ fill, width, height, ...props }) => {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '24'}
      height={height || '24'}
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill={fill || theme.colors.gray2}
        d="M23.39 15.61c0-.282-.14-.563-.374-.704l-2.157-1.219c.094-.562.141-1.124.141-1.687 0-.516-.047-1.078-.14-1.64l2.156-1.22c.234-.14.375-.421.375-.703 0-.656-1.97-4.64-2.953-4.64a.756.756 0 00-.422.14l-2.157 1.266c-.843-.75-1.828-1.266-2.859-1.64V1.03c0-.375-.234-.656-.61-.75A9.827 9.827 0 0012 0c-.844 0-1.64.094-2.39.281-.376.094-.61.375-.61.75v2.532c-1.031.374-2.016.89-2.86 1.64L3.985 3.937a.756.756 0 00-.421-.14c-.891 0-2.954 3.844-2.954 4.64 0 .282.141.563.375.704l2.157 1.218A10.12 10.12 0 003 12c0 .563.047 1.125.14 1.688L.985 14.905c-.234.14-.375.422-.375.703 0 .657 1.922 4.641 2.954 4.641.14 0 .28-.047.421-.14l2.157-1.266c.843.75 1.828 1.265 2.859 1.64v2.532c0 .375.234.656.61.75.75.187 1.546.234 2.39.234.844 0 1.64-.047 2.39-.234.376-.094.61-.375.61-.75v-2.532c1.031-.375 2.016-.89 2.86-1.64l2.156 1.265c.14.094.28.141.422.141.89 0 2.953-3.844 2.953-4.64zM12 15.75A3.731 3.731 0 018.25 12 3.761 3.761 0 0112 8.25 3.761 3.761 0 0115.75 12c0 2.11-1.688 3.75-3.75 3.75z"
      />
    </svg>
  );
};

export default SettingsIcon;
