import React from 'react';

const TagIcon = ({ ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.688 5.125c0-.508.39-.938.937-.938a.95.95 0 0 1 .938.938c0 .547-.43.938-.938.938-.547 0-.938-.391-.938-.938ZM.25 2.625C.25 1.609 1.07.75 2.125.75h5.82c.664 0 1.29.273 1.758.742l6.875 6.875a2.523 2.523 0 0 1 0 3.555l-5.195 5.195a2.523 2.523 0 0 1-3.555 0L.953 10.242C.484 9.773.25 9.148.25 8.484V2.625Zm1.602 6.758 6.875 6.875c.468.469 1.289.469 1.757 0l5.235-5.235c.469-.468.469-1.289 0-1.757L8.844 2.39A1.346 1.346 0 0 0 7.945 2h-5.82a.642.642 0 0 0-.625.625v5.86c0 .312.117.663.352.898Zm0 0-.899.86.899-.86Z"
        fill="#090A0B"
      />
    </svg>
  );
};
export default TagIcon;
