import OverlayDescriptionText from "./OverlayDescriptionText";
import { FC } from "preact/compat";
import { TextCustomization } from "types/config";

type Props = {
  headerSettings: TextCustomization;
  subHeaderSettings: TextCustomization;
  verticalSpacing: number;
  isMobile: boolean;
};

const OverlayDescription: FC<Props> = ({
  headerSettings,
  subHeaderSettings,
  verticalSpacing,
  isMobile,
}) => {
  if (!headerSettings.text && !subHeaderSettings.text) {
    return null;
  }

  const style = {
    gap: `${verticalSpacing}px`,
  };

  return (
    <div style={style} className="hero-overlay-description">
      <OverlayDescriptionText isMobile={isMobile} {...headerSettings} />
      <OverlayDescriptionText isMobile={isMobile} {...subHeaderSettings} />
    </div>
  );
};

export default OverlayDescription;
