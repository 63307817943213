export const getDefaultValues = () => {
  return {
    resolveConflicts: 'hubspot',
    fields: [
      {
        tolstoyField: { step: 'contactForm', key: 'email' },
        hubspotField: 'email',
        mappingType: 'Default',
        enabled: true,
      },
      {
        tolstoyField: { step: 'contactForm', key: 'name' },
        hubspotField: 'firstname',
        mappingType: 'Default',
        enabled: true,
      },
      {
        tolstoyField: { step: 'contactForm', key: 'name' },
        hubspotField: 'lastname',
        mappingType: 'Default',
        enabled: true,
      },
      {
        tolstoyField: { step: 'contactForm', key: 'phone' },
        hubspotField: 'phone',
        mappingType: 'Default',
        enabled: true,
      },
    ],
  };
};
