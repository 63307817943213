import React from 'react';
import styled from 'styled-components';
import SmsIcon from 'app/src/images/SmsIcon';
import { TextBodyLeading } from 'shared/react/components/complex/Text';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const SHOPIFY_GIF_URL =
  'https://d2oqxeenpyo9js.cloudfront.net/tolstoy-assets/tolstoy_shopify_sms_campaign.gif';

const ShopifySmsCampaign = () => {
  return (
    <LayoutRoot>
      <ShopifyGif src={SHOPIFY_GIF_URL} alt="Shopify GIF" />
      <GreenSmsContainer>
        <SmsIcon />
        <TextContainer>
          Drive sales by implementing a shoppable Tolstoy in your SMS campaigns.
        </TextContainer>
      </GreenSmsContainer>
    </LayoutRoot>
  );
};

export default ShopifySmsCampaign;

const LayoutRoot = styled(HorizontalFlexWrap)`
  gap: 32px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    display: none;
  }
`;

const GreenSmsContainer = styled(VerticalFlex)`
  gap: 16px;
  background: ${({ theme }) => theme.colors.successLight};
  padding: 16px;
  border-radius: 12px;
  max-width: 151px;
  max-height: 236px;
`;

const TextContainer = styled(TextBodyLeading)`
  color: ${({ theme }) => theme.colors.success};
  font-weight: 700;
`;

const ShopifyGif = styled.img`
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.31);
  border-radius: 24px;
  width: 180px;
  height: 380px;
`;
