import { createActionsHook, createContainer, createHook, createStore } from 'react-sweet-state';

const initialState = {
  queryParams: {},
};

const actions = {
  getQueryParamByKey:
    key =>
    ({ getState }) => {
      const queryParams = getState().queryParams;
      return queryParams[key];
    },
  setSearchQueryParams:
    searchString =>
    ({ setState }) => {
      console.log('got search', searchString);
      const searchParams = new URLSearchParams(searchString);
      const queryParams = {};
      for (const [key, value] of searchParams) {
        queryParams[key] = value;
      }

      setState({ queryParams });
    },
};

const UnauthenticatedStateStore = createStore({
  initialState,
  actions,
  name: 'unauthenticatedState',
});

export const useUnauthenticatedState = createHook(UnauthenticatedStateStore);

export const useUnauthenticatedStateActions = createActionsHook(UnauthenticatedStateStore);

export const UnauthenticatedStateContainer = createContainer(UnauthenticatedStateStore, {
  onInit:
    () =>
    ({ dispatch }) => {
      const { search } = window.location;
      dispatch(actions.setSearchQueryParams(search));
    },
});
