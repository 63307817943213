import React from 'react';

const MediumTolstoyBracesIcon = () => (
  <svg width={64} height={64} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.658 20.923h.06c8.127 0 14.718 6.658 14.718 14.872 0 8.214-6.59 14.872-14.718 14.872-7.824 0-14.22-6.169-14.69-13.954H0v-3.917c0-1.263.156-2.49.45-3.661 1.538-7.362 6.894-13.48 14.208-15.802v7.59Zm34.684 22.155h-.06c-8.129 0-14.718-6.66-14.718-14.872 0-8.215 6.59-14.872 14.718-14.872 7.823 0 14.22 6.168 14.69 13.953H64v3.918a15 15 0 0 1-.452 3.659c-1.538 7.363-6.892 13.482-14.206 15.803v-7.59Z"
      fill="#B3B6BC"
    />
  </svg>
);

export default MediumTolstoyBracesIcon;
