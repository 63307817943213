import React from 'react';

const GoogleDriveIcon = () => {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.594 8.844 5.469 0H10.5l5.125 8.844h-5.031Zm-4.313.75H16L13.469 14H3.75l2.531-4.406ZM4.813 1.125l2.593 4.406L2.5 14 0 9.594l4.813-8.469Z"
        fill="#B3B6BC"
      />
    </svg>
  );
};

export default GoogleDriveIcon;
