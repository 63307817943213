import { createContainer, createHook, createStore } from 'react-sweet-state';
import { getRequest } from 'app/src/helpers/API';
import { v4 as uuidv4 } from 'uuid';
import { TIKTOK } from 'app/src/constants/intergrations.constants';
import { TiktokApiService } from 'app/src/services/tiktok';

const initialState = {
  accountVideos: [],
  userInfoMap: {},
  firstUserInfo: null,
  cursor: 0,
  appsOptionsMap: {},
  loading: false,
  initialized: false,
  error: null,
  gotAllVideos: false,
};

const TIKTOK_URL_REGEX = '^https:\\/\\/www.tiktok.com\\/.*\\/video\\/.*';
const TIKTOK_MOBILE_URL_REGEX = '^https:\\/\\/vt.tiktok.com\\/.*';

const actions = {
  fetchByApps:
    apps =>
    async ({ getState, setState }) => {
      try {
        const { accountVideos, loading, appsOptionsMap } = getState();

        if (loading) {
          return;
        }

        setState({ loading: true });

        const newOptions = {};
        const newVideos = [];
        let gotAllVideos = true;
        const appsToFetch = apps.filter(app => !appsOptionsMap[app.id]?.gotAllVideos);
        const responses = await TiktokApiService.getVideosByApps(appsToFetch, appsOptionsMap);

        responses.forEach(({ videos = [], nextCursor }, i) => {
          const app = appsToFetch[i];

          newOptions[app.id] = {
            ...(appsOptionsMap[app.id] || {}),
            cursor: nextCursor,
            gotAllVideos: !nextCursor,
          };

          if (nextCursor) {
            gotAllVideos = false;
          }

          newVideos.push(...videos);
        });

        setState({
          accountVideos: [...accountVideos, ...newVideos],
          appsOptionsMap: { ...appsOptionsMap, ...newOptions },
          gotAllVideos,
          initialized: true,
          loading: false,
        });
      } catch (e) {
        if (e) {
          console.error(e);
        }

        setState({ loading: false, initialized: true });
        return e;
      }
    },
  fetchUserInfoByApps:
    apps =>
    async ({ setState, getState }) => {
      try {
        const { userInfoMap, firstUserInfo } = getState();

        const newUserInfoMap = {};
        const appIds = apps.flatMap(app => (userInfoMap[app.id] ? [] : app.id));
        const userInfos = await TiktokApiService.getUserInfosByAppIds(appIds);

        userInfos.forEach((userInfo, index) => {
          const appId = appIds[index];

          newUserInfoMap[appId] = userInfo;
        });

        setState({
          userInfoMap: { ...userInfoMap, ...newUserInfoMap },
          firstUserInfo: firstUserInfo || userInfos[0] || null,
        });
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
  downloadTikTokVideo: (videoUrl, owner, videoId, originalUrl) => async () => {
    try {
      await getRequest(
        'tiktok',
        `/tiktok/download?url=${videoUrl}&owner=${owner}&videoId=${videoId}&originalUrl=${
          originalUrl.split('?')[0]
        }`
      );
      return true;
    } catch (e) {
      console.error('downloadTikTokVideo error', e);
      return null;
    }
  },
  fetchTikTokVideoData: url => async () => {
    try {
      const response = await getRequest('tiktok', `/tiktok/get-video?url=${url.split('?')[0]}`);
      return response;
    } catch (e) {
      return null;
    }
  },
  getTikTokOriginalVideo:
    url =>
    async ({ dispatch }) => {
      if (dispatch(actions.checkTiktokUrl(url))) {
        return null;
      }

      const videoUrl = await dispatch(actions.fetchTikTokVideoData(url));

      return videoUrl;
    },
  checkTiktokUrl: url => () => {
    return (
      (!new RegExp(TIKTOK_URL_REGEX).test(url) && !new RegExp(TIKTOK_MOBILE_URL_REGEX).test(url)) ||
      url.split(' ').length > 1
    );
  },

  normalizeTiktokData: (data, owner, i) => () => {
    const { posterUrl, description, externalCreatedAt, videoUrl } = data;
    const id = uuidv4();
    return {
      ...data,
      videoUrl,
      id,
      externalId: data.id,
      newId: id,
      app: TIKTOK,
      createdAt: new Date(externalCreatedAt * 1000).toISOString(),
      owner,
      stockAsset: { posterUrl },
      name: description || `TikTok video #${i + 1}`,
      uploadType: TIKTOK,
    };
  },
  clearStore:
    () =>
    ({ setState }) => {
      setState({ ...initialState });
    },
};

const TikTokStore = createStore({ initialState, actions, name: 'Videos' });

export const useTikTok = createHook(TikTokStore);

export const TikTokContainer = createContainer(TikTokStore);
