import React, { useState } from 'react';
import styled from 'styled-components';
import { useVideos } from 'app/src/context/VideosStore';
import { useResponses } from 'app/src/context/ResponsesStore';
import { TextSmall } from 'shared/react/components/complex/Text';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { FEATURE_SHOW_ORIGINAL_VIDEO } from 'app/src/constants/appFeatures.constants';
import { getDiffInSeconds } from 'app/src/utils/date.utils';
import { NEW_RESPONSE_SECONDS } from 'app/src/constants/responsesInbox.constants';
import { EVENTS } from 'app/src/constants/tolstoy.constants';
import { CircularProgress } from '@material-ui/core';
import Utils from 'app/src/utils';

const VideoProcessingRetryAttempts = 10;
let counter = 0;

const getIsNewResponse = ({ createdAt }) => {
  if (getDiffInSeconds(new Date(), new Date(createdAt)) <= NEW_RESPONSE_SECONDS) {
    return true;
  }
  return false;
};

const NewResponsePlaceholder = () => (
  <LoadingContainer>
    <CircularProgress size={24} />
  </LoadingContainer>
);

const VideoResponse = ({ response, setVideoModalSrc, onVideoLoaded }) => {
  const [, { getVideoPosterLarge, getVideoUrl, getVideoById }] = useVideos();
  const [, { getVideoResponseImage, getVideoResponseUrl }] = useResponses();
  const [, { getFeatureEnabled }] = useFeatures();
  const [videoError, setVideoError] = useState(false);
  const [loading, setLoading] = useState(false);
  const responseType = response.type;
  const newResponse = getIsNewResponse(response);

  const returnVideoError = () => {
    return <Title>An error occurred while the video was being submitted.</Title>;
  };

  const handleVideoLoadError = ({ target }) => {
    const originalSrc = target.src;
    if (counter >= VideoProcessingRetryAttempts) {
      setVideoError(true);
      setLoading(false);
      return;
    }

    const timeout = newResponse ? 8000 : 1000;
    setLoading(true);

    setTimeout(function () {
      counter++;
      target.src = originalSrc;
    }, timeout);
  };

  const handleVideoLoad = () => {
    setLoading(false);
    onVideoLoaded();
  };

  if (!response.owner) {
    response.owner = Utils.getOwner();
  }

  let videoResponseUrl;
  let imageResponseUrl;
  let video = getVideoById(response.value);
  if (responseType === 'tolstoyReply' || (video && responseType === EVENTS.videoResponse)) {
    video = video || { id: response.value, stockAsset: response.stockAsset, owner: response.owner };
    videoResponseUrl = getVideoUrl(video);
    imageResponseUrl = getVideoPosterLarge(video);
  } else {
    videoResponseUrl = getVideoResponseUrl(
      response,
      getFeatureEnabled(FEATURE_SHOW_ORIGINAL_VIDEO)
    );
    imageResponseUrl = getVideoResponseImage(response);
  }

  if (videoError && newResponse) {
    return <Title>Video is being processed</Title>;
  }
  if (videoError) {
    return returnVideoError();
  }

  return (
    <VideoContainer>
      <Image
        src={imageResponseUrl}
        onClick={() => setVideoModalSrc(videoResponseUrl)}
        onError={handleVideoLoadError}
        onLoad={handleVideoLoad}
      />
      {loading && <NewResponsePlaceholder />}
    </VideoContainer>
  );
};

const Image = styled.img`
  width: 100%;
  height: 269px;
  object-fit: cover;
  border-radius: 16px;
  border: none;
  cursor: pointer;

  :hover {
    border: 2px solid ${({ theme }) => theme.colors.blue5};
  }
`;

const VideoContainer = styled.div`
  position: relative;
  display: flex;
`;

const Title = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.blue2};
`;

const LoadingContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 269px;
  display: grid;
  place-content: center;
  background-color: ${({ theme }) => theme.colors.primaryLight};
`;

export default VideoResponse;
