import React from 'react';

const DiscoverIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
      <mask id="path-1-inside-1_1368_32179" fill="white">
        <path d="M10.2184 5.03813C9.84542 4.23591 9.19815 3.59331 8.39324 3.22616L8.05713 3.07284C7.9738 3.03117 7.93213 2.94784 7.93213 2.86451C7.93213 2.78117 7.9738 2.69784 8.05713 2.65617L8.39324 2.50286C9.19815 2.13571 9.84542 1.49311 10.2184 0.690885L10.3766 0.35062C10.4044 0.267287 10.4877 0.211731 10.571 0.211731C10.6544 0.211731 10.7377 0.267287 10.7794 0.35062L10.9263 0.672831C11.2973 1.48614 11.9494 2.13822 12.7627 2.5092L13.0849 2.65617C13.1682 2.69784 13.2238 2.78117 13.2238 2.86451C13.2238 2.94784 13.1682 3.03117 13.0849 3.07284L12.7627 3.21982C11.9494 3.5908 11.2973 4.24288 10.9263 5.05619L10.7794 5.3784C10.7377 5.46173 10.6544 5.51729 10.571 5.51729C10.4877 5.51729 10.4044 5.46173 10.3766 5.3784L10.2184 5.03813Z" />
      </mask>
      <path
        d="M10.2184 5.03813C9.84542 4.23591 9.19815 3.59331 8.39324 3.22616L8.05713 3.07284C7.9738 3.03117 7.93213 2.94784 7.93213 2.86451C7.93213 2.78117 7.9738 2.69784 8.05713 2.65617L8.39324 2.50286C9.19815 2.13571 9.84542 1.49311 10.2184 0.690885L10.3766 0.35062C10.4044 0.267287 10.4877 0.211731 10.571 0.211731C10.6544 0.211731 10.7377 0.267287 10.7794 0.35062L10.9263 0.672831C11.2973 1.48614 11.9494 2.13822 12.7627 2.5092L13.0849 2.65617C13.1682 2.69784 13.2238 2.78117 13.2238 2.86451C13.2238 2.94784 13.1682 3.03117 13.0849 3.07284L12.7627 3.21982C11.9494 3.5908 11.2973 4.24288 10.9263 5.05619L10.7794 5.3784C10.7377 5.46173 10.6544 5.51729 10.571 5.51729C10.4877 5.51729 10.4044 5.46173 10.3766 5.3784L10.2184 5.03813Z"
        stroke="#090A0B"
        strokeWidth="3.71124"
        mask="url(#path-1-inside-1_1368_32179)"
      />
      <mask id="path-2-inside-2_1368_32179" fill="white">
        <path d="M15.273 6.14169C15.1837 5.94955 15.0287 5.79563 14.8359 5.7077L14.0894 5.36718C14.0343 5.33967 14.0068 5.28466 14.0068 5.22964C14.0068 5.17463 14.0343 5.11962 14.0894 5.09211L14.8359 4.75159C15.0287 4.66366 15.1837 4.50974 15.273 4.3176L15.6206 3.57008C15.6389 3.51507 15.6939 3.47839 15.7489 3.47839C15.8039 3.47839 15.8589 3.51507 15.8865 3.57008L16.2255 4.31328C16.3143 4.50808 16.4705 4.66426 16.6653 4.75311L17.4085 5.09211C17.4635 5.11962 17.5002 5.17463 17.5002 5.22964C17.5002 5.28466 17.4635 5.33967 17.4085 5.36718L16.6653 5.70618C16.4705 5.79503 16.3143 5.95121 16.2255 6.14601L15.8865 6.88921C15.8589 6.94422 15.8039 6.9809 15.7489 6.9809C15.6939 6.9809 15.6389 6.94422 15.6206 6.88921L15.273 6.14169Z" />
      </mask>
      <path
        d="M15.273 6.14169C15.1837 5.94955 15.0287 5.79563 14.8359 5.7077L14.0894 5.36718C14.0343 5.33967 14.0068 5.28466 14.0068 5.22964C14.0068 5.17463 14.0343 5.11962 14.0894 5.09211L14.8359 4.75159C15.0287 4.66366 15.1837 4.50974 15.273 4.3176L15.6206 3.57008C15.6389 3.51507 15.6939 3.47839 15.7489 3.47839C15.8039 3.47839 15.8589 3.51507 15.8865 3.57008L16.2255 4.31328C16.3143 4.50808 16.4705 4.66426 16.6653 4.75311L17.4085 5.09211C17.4635 5.11962 17.5002 5.17463 17.5002 5.22964C17.5002 5.28466 17.4635 5.33967 17.4085 5.36718L16.6653 5.70618C16.4705 5.79503 16.3143 5.95121 16.2255 6.14601L15.8865 6.88921C15.8589 6.94422 15.8039 6.9809 15.7489 6.9809C15.6939 6.9809 15.6389 6.94422 15.6206 6.88921L15.273 6.14169Z"
        fill="#090A0B"
      />
      <path
        d="M15.273 6.14169C15.1837 5.94955 15.0287 5.79563 14.8359 5.7077L14.0894 5.36718C14.0343 5.33967 14.0068 5.28466 14.0068 5.22964C14.0068 5.17463 14.0343 5.11962 14.0894 5.09211L14.8359 4.75159C15.0287 4.66366 15.1837 4.50974 15.273 4.3176L15.6206 3.57008C15.6389 3.51507 15.6939 3.47839 15.7489 3.47839C15.8039 3.47839 15.8589 3.51507 15.8865 3.57008L16.2255 4.31328C16.3143 4.50808 16.4705 4.66426 16.6653 4.75311L17.4085 5.09211C17.4635 5.11962 17.5002 5.17463 17.5002 5.22964C17.5002 5.28466 17.4635 5.33967 17.4085 5.36718L16.6653 5.70618C16.4705 5.79503 16.3143 5.95121 16.2255 6.14601L15.8865 6.88921C15.8589 6.94422 15.8039 6.9809 15.7489 6.9809C15.6939 6.9809 15.6389 6.94422 15.6206 6.88921L15.273 6.14169Z"
        stroke="#090A0B"
        strokeWidth="5.56685"
        mask="url(#path-2-inside-2_1368_32179)"
      />
      <mask id="path-3-inside-3_1368_32179" fill="white">
        <path d="M4.61964 4.60046L13.9606 10.6222C14.4147 10.9301 14.7066 11.4776 14.7066 12.025C14.7066 12.6067 14.4147 13.1541 13.9606 13.4278L4.61964 19.4496C4.13313 19.7575 3.51688 19.7917 3.03037 19.4838C2.54386 19.2101 2.25195 18.6626 2.25195 18.0468V6.00325C2.25195 5.4216 2.54386 4.87417 3.03037 4.60046C3.51688 4.29252 4.13313 4.29252 4.61964 4.60046Z" />
      </mask>
      <path
        d="M4.61964 4.60046L13.9606 10.6222C14.4147 10.9301 14.7066 11.4776 14.7066 12.025C14.7066 12.6067 14.4147 13.1541 13.9606 13.4278L4.61964 19.4496C4.13313 19.7575 3.51688 19.7917 3.03037 19.4838C2.54386 19.2101 2.25195 18.6626 2.25195 18.0468V6.00325C2.25195 5.4216 2.54386 4.87417 3.03037 4.60046C3.51688 4.29252 4.13313 4.29252 4.61964 4.60046Z"
        stroke="#090A0B"
        strokeWidth="2.6"
        mask="url(#path-3-inside-3_1368_32179)"
      />
    </svg>
  );
};

export default DiscoverIcon;
