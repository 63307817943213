import React from 'react';

const ThickPlusIcon = ({ ...props }) => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.125 8.5C17.125 7.64062 16.4219 6.9375 15.5625 6.9375H10.5625V1.9375C10.5625 1.07812 9.85938 0.375 9 0.375C8.10156 0.375 7.4375 1.07812 7.4375 1.9375V6.9375H2.4375C1.53906 6.9375 0.875 7.64062 0.875 8.5C0.875 9.39844 1.53906 10.0625 2.4375 10.0625H7.4375V15.0625C7.4375 15.9609 8.10156 16.625 9 16.625C9.85938 16.625 10.5625 15.9609 10.5625 15.0625V10.0625H15.5625C16.4219 10.0625 17.125 9.39844 17.125 8.5Z"
        fill="white"
      />
    </svg>
  );
};

export default ThickPlusIcon;
