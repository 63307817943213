import React, { useEffect, useState } from 'react';
import { useAccount } from 'app/src/context/AccountStore';
import BrokenLinkIcon from 'app/src/images/broken_link.svg';
import styled from 'styled-components';
import { TextBody } from 'shared/react/components/complex/Text';
import Button from 'shared/react/components/basic/Button';
import { navigateToRoot } from '../../utils/navigation.utils';
import { useHistory } from 'react-router-dom';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const DEFAULT_TITLE = 'Invite invalid';
const DEFAULT_SUBTITLE = 'This invite may be expired, or you might not have permission to join.';

const InviteInvalid = () => {
  const history = useHistory();
  const [{ error }, { setError }] = useAccount();
  const title = useState(error?.title || DEFAULT_TITLE);
  const subtitle = useState(error?.subtitle || DEFAULT_SUBTITLE);

  useEffect(() => {
    if (error?.title) {
      setError(null);
    }
  }, [error]);

  const onContinueClick = () => {
    navigateToRoot(history);
  };

  return (
    <StyledInviteInvalid>
      <InviteInvalidIcon src={BrokenLinkIcon} alt="Broken link icon" />
      <InviteInvalidText>
        <InviteInvalidTitle>{title}</InviteInvalidTitle>
        <InviteInvalidSubtitle>{subtitle}</InviteInvalidSubtitle>
      </InviteInvalidText>
      <ContinueButton onClick={onContinueClick}>Continue to Tolstoy</ContinueButton>
    </StyledInviteInvalid>
  );
};

export default InviteInvalid;

const StyledInviteInvalid = styled(VerticalFlex)`
  align-items: center;
  gap: 64px;
`;

const InviteInvalidIcon = styled.img`
  width: 80px;
  height: 64px;
`;

const InviteInvalidText = styled(VerticalFlex)`
  align-items: center;
  gap: 16px;
`;

const InviteInvalidTitle = styled(TextBody)`
  font-weight: 800;
  font-size: 40px;
  line-height: 44px;
`;

const InviteInvalidSubtitle = styled(TextBody)`
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
`;

const ContinueButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.blue7};
  border: none;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  font-size: 22px;
  line-height: 20px;
  padding: 19px 40px 21px;
  border-radius: 100px;
  cursor: pointer;
`;
