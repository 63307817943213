import SuccessIcon from 'app/src/images/Success';
import PendingIcon from 'app/src/images/Pending';
import React, { useState } from 'react';
import styled from 'styled-components';
import { TextTiny } from 'shared/react/components/complex/Text';
import { STATUS_ACCEPTED, STATUS_PENDING } from 'app/src/constants/teamMembers.constants';
import ResendIcon from 'app/src/images/ResendIcon';
import { useAccount } from 'app/src/context/AccountStore';
import { track } from 'app/src/helpers/Tracker';
import { CircularProgress } from '@material-ui/core';
import SnackBar from 'app/src/basic_components/SnackBar';
import Tooltip from 'app/src/basic_components/Tooltip';

const TeamMemberStatusTooltip = ({
  showResendInviteButton,
  disabled,
  onClick,
  loading,
  buttonDisabled,
}) => {
  if (!showResendInviteButton) {
    return null;
  }

  return (
    <Tooltip placement="bottom" text={!disabled ? 'Resend invite' : ''}>
      <ResendButton onClick={onClick} $loading={loading} disabled={buttonDisabled}>
        <ResendIcon />
        <ResendButtonLoading size={14} />
      </ResendButton>
    </Tooltip>
  );
};

const TeamMemberStatus = ({ id, status, key, viewOnly }) => {
  const [, { resendInvite }] = useAccount();
  const [loading, setLoading] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [resendResponse, setResendResponse] = useState(undefined);
  const text = status === STATUS_ACCEPTED ? 'Active' : 'Pending';

  const disabled = loading || resendResponse;

  const handleResendInvite = async () => {
    if (disabled) {
      return;
    }

    setLoading(true);

    const resendInviteResponse = await resendInvite(id);

    track('Resend Invite');
    setLoading(false);
    setSnackBarOpen(true);
    setResendResponse(resendInviteResponse);
  };

  const showResendInviteButton = !viewOnly && status === STATUS_PENDING;
  const resendError = resendResponse === null;

  return (
    <StyledTeamMemberStatus>
      <TeamMemberStatusBadge key={key} data-type={status}>
        <SuccessIcon data-type={STATUS_ACCEPTED} />
        <PendingIcon data-type={STATUS_PENDING} />
        <StatusContent data-type={status}>{text}</StatusContent>
      </TeamMemberStatusBadge>
      <TeamMemberStatusTooltip
        showResendInviteButton={showResendInviteButton}
        onClick={handleResendInvite}
        loading={loading}
        disabled={disabled}
        buttonDisabled={resendResponse}
      />
      <SnackBar
        text={
          resendError ? 'Error occurred, please try again later' : 'New invite will be sent shortly'
        }
        open={snackBarOpen}
        setOpen={setSnackBarOpen}
        severity={resendError ? 'error' : 'success'}
      />
    </StyledTeamMemberStatus>
  );
};

export default TeamMemberStatus;

const StyledTeamMemberStatus = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const TeamMemberStatusBadge = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 5px 10px;
  border-radius: 12px;
  &[data-type=${STATUS_ACCEPTED}] {
    background-color: ${({ theme }) => theme.colors.green7};
    & > * {
      color: ${({ theme }) => theme.colors.green8};
    }
    & > :not([data-type=${STATUS_ACCEPTED}]) {
      display: none;
    }
  }
  &[data-type=${STATUS_PENDING}] {
    background-color: ${({ theme }) => theme.colors.gray3};
    & > * {
      color: ${({ theme }) => theme.colors.gray2};
    }
    & > :not([data-type=${STATUS_PENDING}]) {
      display: none;
    }
  }
`;

const StatusContent = styled(TextTiny)``;

const ResendButton = styled.button`
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  background: transparent;
  border: none;
  display: grid;
  place-content: center;
  padding: 0;

  & > :first-child {
    visibility: ${({ $loading }) => ($loading ? 'hidden' : '')};
  }

  & > :last-child {
    display: ${({ $loading }) => ($loading ? '' : 'none')};
  }
`;

const ResendButtonLoading = styled(CircularProgress)`
  position: absolute;
`;
