const useSortEntities = <T>(entities: T[], key: string, asc: boolean): T[] => {
  return entities.sort((a, b) => {
    const dateA = new Date(b[key]).getTime();
    const dateB = new Date(a[key]).getTime();
    if (asc) {
      return dateA - dateB;
    }

    return dateB - dateA;
  });
};

export default useSortEntities;
