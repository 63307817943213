import { useSelectedProductsContext } from 'app/src/complex_components/global_modal/video/add_products_modal/SelectedProductsContext';
import { ADD_PRODUCTS_MODAL_SAVE_DATA_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import React from 'react';
import ModalFooterButtons from 'src/complex_components/global_modal/common/ModalFooterButtons';

type Props = {
  onSave: () => void;
  onDiscard: () => void;
};

const AddProductsModalButtons = ({ onSave, onDiscard }: Props) => {
  const [{ isDirty, isSaving, isLoading }] = useSelectedProductsContext();

  return (
    <ModalFooterButtons
      isSaveLoading={isSaving}
      isDiscardDisabled={isSaving || isLoading}
      isSaveDisabled={!isDirty || isSaving || isLoading}
      onDiscard={onDiscard}
      onSave={onSave}
      saveButtonDataTestId={ADD_PRODUCTS_MODAL_SAVE_DATA_TEST_ID}
    />
  );
};

export default AddProductsModalButtons;
