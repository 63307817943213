import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import XButton from './XButton';
import Types from 'shared/react/theme/Types';
import Button from 'shared/react/components/complex/Button';
import { useMediaQuery } from '@material-ui/core';
import { TABLET_MAX_WIDTH } from 'shared/react/utils/mediaQuery';
import Utils from '../utils';
import Routes from '../helpers/Routes';
import { useHistory, useLocation } from 'react-router-dom';
import { useProjects } from 'app/src/context/ProjectsStore';
import Sizes from 'shared/react/theme/Sizes';
import BorderColorTypes from 'shared/react/theme/BorderColorTypes';
import BorderRadiusTypes from 'shared/react/theme/BorderRadiusTypes';
import {
  navigateToProjectBuilder,
  navigateToRoot,
  tryToNavigateBackToOrigin,
  tryToNavigateToReplyLocation,
} from '../utils/navigation.utils';
import { TextH6 } from 'shared/react/components/complex/Text';

function ModalCard({
  children,
  disableContentPadding,
  projectId,
  shouldShowXButton = true,
  isReply,
  header,
  ...props
}) {
  const isMobile = useMediaQuery(`(${TABLET_MAX_WIDTH})`) || Utils.isMobile();
  const history = useHistory();
  const location = useLocation();
  const [, { getDisplayedProjects }] = useProjects();
  const [isBackButtonVisible, setBackButtonVisible] = useState(false);
  useEffect(() => {
    let backButtonVisible = false;
    if (isMobile) {
      const routesWithoutBackButton = [
        Routes.getDashboardBaseRoute(),
        Routes.getDashboardWithSlashBaseRoute(),
      ];
      backButtonVisible = !routesWithoutBackButton.includes(location.pathname);
    } else {
      Routes.getRoutesWithBackButton().forEach(routeWithBackButton => {
        if (location.pathname.includes(routeWithBackButton)) {
          backButtonVisible = true;
        }
      });
    }
    setBackButtonVisible(backButtonVisible);
  }, [location, isMobile]);
  const projects = getDisplayedProjects();

  function navigateToHome() {
    const dashboardRoutes = [
      Routes.getDashboardBaseRoute(),
      Routes.getDashboardWithSlashBaseRoute(),
    ];
    if (isMobile && dashboardRoutes.includes(location.pathname)) {
      return;
    }
    if (tryToNavigateBackToOrigin(history)) {
      return;
    }
    if (tryToNavigateToReplyLocation(history)) {
      return;
    }
    if (isReply) {
      history.goBack();
    } else if (!isMobile) {
      navigateToProjectBuilder(history, projectId || projects[0]?.id);
    } else {
      navigateToRoot(history);
    }
  }

  function handleBackClick() {
    history.goBack();
  }

  function handleXButtonClick() {
    navigateToHome();
  }

  return (
    <LayoutRoot {...props}>
      <ModalToolbarContainer includesHeader={!!header} backButtonVisible={isBackButtonVisible}>
        {isBackButtonVisible && (
          <BackButton
            size={Sizes.FitContent}
            type={Types.Ghost}
            transparentBackground
            borderRadiusType={BorderRadiusTypes.FullFlat}
            borderColorType={BorderColorTypes.FullyTransparent}
            showLeftIcon
            onClick={handleBackClick}
            padding="10px 0"
          >
            Back
          </BackButton>
        )}
        {header && <Title>{header}</Title>}
        {shouldShowXButton && <StyledXButton onClick={handleXButtonClick} />}{' '}
      </ModalToolbarContainer>

      <ModalContent disableContentPadding={disableContentPadding}>{children}</ModalContent>
    </LayoutRoot>
  );
}

export default ModalCard;

const LayoutRoot = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  background: ${({ theme }) => theme.colors.gray1};
  border-radius: 20px;
  margin: 20px;
  height: fit-content;
  overflow: hidden;
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    overflow: auto;
    height: 100%;
    width: 100%;
    padding: 8px;
    border-radius: 0;
    margin: 0;
  }
`;

const ModalToolbarContainer = styled.div`
  width: 100%;
  display: grid;
  padding: 16px 40px 8px 40px;
  grid-template-columns: ${({ includesHeader }) => (includesHeader ? '1fr 1fr 1fr' : '1fr auto')};
  align-items: center;
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    margin-bottom: 0;
    padding: 0 8px;
  }
`;

const BackButton = styled(Button)`
  min-width: 0;
  justify-items: start;
  &:hover {
    opacity: 0.75;
  }

  &:focus,
  &:active {
    opacity: 0.5;
  }
`;

const StyledXButton = styled(XButton)`
  padding: 8px 0 8px 8px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    padding: 0 0 8px 8px;
    border-radius: 0;
  }
`;

const ModalContent = styled.div`
  background: ${({ theme }) => theme.colors.gray1};
  padding: ${({ disableContentPadding }) => (disableContentPadding ? '0' : '20px 40px')};
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    padding: 0;
    border-radius: 20px 20px 0 0;
  }
`;

const Title = styled(TextH6)``;
