import React from 'react';

function AccountEmailIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
        fill="#50545E"
        d="M8.219.531A7.49 7.49 0 002.78 2.625 7.56 7.56 0 00.5 8.063c0 3.687 2.719 6.906 6.375 7.437H7c.344 0 .656-.25.719-.625a.741.741 0 00-.625-.844C4.187 13.594 2 11.031 2 8.062c0-1.656.625-3.218 1.813-4.343C5 2.563 6.5 1.969 8.155 2.03 11.375 2.125 14 4.937 14 8.344v.594c0 .75-.625 1.374-1.375 1.374a1.367 1.367 0 01-1.375-1.374V5.28a.76.76 0 00-.75-.75.771.771 0 00-.75.625 3.38 3.38 0 00-2-.625 3.494 3.494 0 00-3.5 3.5c0 1.906 1.563 3.5 3.5 3.5.938 0 1.813-.406 2.438-1.031.53.781 1.406 1.313 2.437 1.313A2.888 2.888 0 0015.5 8.937v-.593C15.5 4.156 12.219.625 8.219.53zm-.469 9.5c-1.125 0-2-.906-2-2 0-1.125.875-2 2-2 1.094 0 2 .875 2 2 0 1.094-.906 2-2 2z"
      ></path>
    </svg>
  );
}

export default AccountEmailIcon;
