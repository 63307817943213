import { createContainer, createHook, createStore } from 'react-sweet-state';
import { graphqlRequest } from 'app/src/helpers/API';
import { projectAppsByAppKey } from 'app/src/graphql/queries';
import { graphqlOperation } from 'aws-amplify';
import { createProjectApp, updateProjectApp } from 'app/src/graphql/mutations';

const initialState = {
  projectApps: [],
};
const actions = {
  fetchProjectApps:
    () =>
    async ({ setState }, { appKey }) => {
      const response = await graphqlRequest(graphqlOperation(projectAppsByAppKey, { appKey }));
      setState({ projectApps: response.data.projectAppsByAppKey.items });
    },
  createProjectApp:
    input =>
    async ({ setState, getState }) => {
      const response = await graphqlRequest(graphqlOperation(createProjectApp, { input }));
      const { projectApps } = getState();

      setState({ projectApps: [...projectApps, response.data.createProjectApp] });
    },
  updateProjectApp:
    input =>
    async ({ setState, getState }) => {
      delete input.createdAt;
      delete input.updatedAt;
      const response = await graphqlRequest(graphqlOperation(updateProjectApp, { input }));
      const { projectApps } = getState();
      const newApp = response.data.updateProjectApp;
      const newProjectApps = projectApps.map(app => {
        if (app.id === newApp.id) {
          return newApp;
        }

        return app;
      });

      setState({ projectApps: newProjectApps });
    },
  clearStore:
    () =>
    ({ setState }) => {
      setState(initialState);
    },
};

const projectAppsSelector = (state, args) => {
  const projectApp = state.projectApps?.find(
    projectApp => projectApp.projectId === args?.projectId && projectApp.appId === args?.appId
  );

  return {
    ...state,
    projectApp,
  };
};

const ProjectAppsStore = createStore({ initialState, actions });

export const useProjectApps = createHook(ProjectAppsStore, { selector: projectAppsSelector });

export const ProjectAppsContainer = createContainer(ProjectAppsStore, {
  onInit:
    () =>
    ({ dispatch }) => {
      dispatch(actions.fetchProjectApps());
    },
  onCleanup:
    () =>
    ({ dispatch }) => {
      dispatch(actions.clearStore());
    },
});
