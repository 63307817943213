import React from 'react';
import Button from 'shared/react/components/complex/Button';
import styled from 'styled-components';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';

type CopyCodeButtonProps = React.ComponentProps<typeof Button>;

const CopyCodeButton = (props: CopyCodeButtonProps) => {
  return (
    <LayoutRoot>
      <Button {...props}>Copy code</Button>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)`
  padding-right: 4px;
  gap: 12px;
`;

export default CopyCodeButton;
