import React from 'react';

const OutlinedXButton = ({ size = 14 }) => (
  <svg
    width={size}
    height={size - 1}
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.055 12.594a.662.662 0 0 0 0-.899L7.859 6.5l5.196-5.156a.662.662 0 0 0 0-.899.662.662 0 0 0-.899 0L7 5.641 1.805.445a.662.662 0 0 0-.899 0 .662.662 0 0 0 0 .899L6.102 6.5.906 11.695a.662.662 0 0 0 0 .899.662.662 0 0 0 .899 0L7 7.398l5.156 5.196a.662.662 0 0 0 .899 0Z"
      fill="#090A0B"
    />
  </svg>
);

export default OutlinedXButton;
