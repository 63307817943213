import React from 'react';
import Tooltip from 'app/src/basic_components/Tooltip';
import screenRecordingAndCamera from './tooltip_images/screen_recording_and_camera.png';
import inVideoCallToAction from './tooltip_images/in_video_call_to_actions.png';
import linkAndSocial from './tooltip_images/link_and_social_sharing.png';
import embedInEmails from './tooltip_images/embed_in_emails.png';
import onSiteEmbed from './tooltip_images/on_site_embeds.png';
import widget from './tooltip_images/widget.png';
import landingPageLogo from './tooltip_images/landing_page_logo.png';
import customizeStartScreen from './tooltip_images/customize_start_screen.png';
import removeWatermark from './tooltip_images/remove_watermark.png';
import styled from 'styled-components';
import InfoIcon from 'shared/react/images/InfoIcon';

const Image = styled.img`
  width: 100%;
`;

const featuresTooltipsData = {
  'Screen recording & cam bubble': {
    header: 'Screen recording & cam bubble',
    tooltipImage: <Image src={screenRecordingAndCamera} />,
  },
  'In-video Call to actions': {
    header: 'In-video Call to actions',
    text: 'Make your videos interactive, guide your users with links, buttons and response options.',
    tooltipImage: <Image src={inVideoCallToAction} />,
  },
  'Link and social sharing': {
    header: 'Link and social sharing',
    text: 'Share your tolstoy with a link or over social.',
    tooltipImage: <Image src={linkAndSocial} />,
  },
  'Embed in Emails': {
    header: 'Embed in Emails',
    text: 'Embed animated GIFs in your emails.',
    tooltipImage: <Image src={embedInEmails} />,
  },
  Widget: {
    header: 'Widget',
    tooltipImage: <Image src={widget} />,
  },
  'On site Embeds': {
    header: 'On site Embeds',
    tooltipImage: <Image src={onSiteEmbed} />,
  },
  'Landing page logo': {
    header: 'Landing page logo',
    text: 'Add your logo to the Tolstoy video page.',
    tooltipImage: <Image src={landingPageLogo} />,
  },
  'Customize start screen ': {
    header: 'Customize start screen',
    text: 'Customize your Tolstoy start screen tagline and colors.',
    tooltipImage: <Image src={customizeStartScreen} />,
  },
  'Remove Tolstoy WaterMark': {
    header: 'Remove Tolstoy WaterMark',
    text: 'Remove the Tolstoy watermark from all Tolstoys plus all other brand customizations.',
    tooltipImage: <Image src={removeWatermark} />,
  },
  'Analytics and viewer notifications': {
    header: 'Analytics and viewer notifications',
    text: 'Get data driven insights on your Tolstoys and receive event based notifications',
  },
  Integrations: {
    header: 'Integrations',
    text: 'Connect Tolstoy with all the platforms and tools you already use like Shopify, Wix, BigCommerce, WordPress, Intercom, Zendesk, Gorgias, Zapier, HubSpot, Outreach, SalesLoft, Monday, Intercom, Zendesk and more ',
  },
  'Team workspace': {
    header: 'Team workspace',
    text: 'Workspace for the entire team to access and share videos and viewer insights',
  },
  'unlimited tolstoys': {
    header: 'Unlimited Tolstoys',
    text: 'Paid Creators can create unlimited Tolstoys',
  },
  'Widget Rules & Triggers': {
    header: 'Widget Rules & Triggers',
    text: 'Set when and who sees your widget with Rules and Triggers',
  },
  'Whitelabel end screen': {
    header: 'Whitelabel end screen',
    text: 'Remove Tosltoy bradning from the end screen',
  },
  'Edit thumbnail': {
    header: 'Edit thumbnail',
    text: 'Make your Tolstoy truly pop by editing your thumbnail to look and feel exactly how you want it.',
  },
  'Up to 50 Creators Lite': {
    header: 'Members',
    text: 'Creator lite members are free and have limited sharing and recording capabilities.',
  },
  'Unlimited Creators': {
    header: 'Members',
    text: "Creators are members with unlimited recording and sharing capabilities, you can add as many paid creators as you'd like. ",
  },
  Tolstoys: {
    10: {
      header: 'Tolstoys',
      text: 'Create up to 10 Tolstoys',
    },
    Unlimited: {
      header: 'Tolstoys',
      text: 'Paid Creators can create unlimited Tolstoys',
    },
  },
  'Additional impressions': {
    '$2 per 1K views': {
      header: 'Additional impressions',
      text: 'If your account views are over 5k views, you will be charged $2 per 1k view. ',
    },
  },
  'Included impressions': {
    '5K per month': {
      header: 'Included impressions',
      text: 'Views is the number of unique visitors  exposed to a Tolstoy. The Pro plan includes 5K views/Month.',
    },
    '1K per month': {
      header: 'Included impressions',
      text: 'Impressions is the number of times your Tolstoy was displayed to unique viewers..',
    },
  },
  'Share on site!': {
    header: 'Share on site',
    text: 'Widget, Carousel, Embed, Stories and more!',
  },
  '10 Business seats': {
    header: 'Business seats',
    text: "For teams using video messages. You can add as many Business seats as you'd like. Pricing is $29/Seat.",
  },
};

const FeaturesTooltips = ({ feature, limitKey, header, text }) => {
  const currFeatureTooltip = featuresTooltipsData[limitKey]?.[feature] ||
    featuresTooltipsData[feature] || { header, text };
  if (!currFeatureTooltip.header) {
    return null;
  }

  return (
    <CustomTooltip
      placement="right"
      {...currFeatureTooltip}
      header={currFeatureTooltip.header.toUpperCase()}
    >
      <InfoContainer>
        <InfoIcon />
      </InfoContainer>
    </CustomTooltip>
  );
};

const CustomTooltip = styled(Tooltip)`
  min-width: 225px;
  padding: 8px;
`;

const InfoContainer = styled.div`
  height: 20px;
`;

export default FeaturesTooltips;
