import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import CarouselSliderNavigation from './CarouselSliderNavigation';
import { CAROUSEL_DATA_TYPE } from 'shared/react/constants/feedPreviewDataTypes.constants';
import HorizontalFlex from '../flex_layouts/HorizontalFlex';
import { ITEMS_GAP, LEFT, RIGHT, IMAGE_WIDTH, IMAGE_HEIGHT } from './carouselSlider.constants';
import { CarouselSliderArrowLayoutRoot } from 'shared/react/components/complex/carousel_slider/CarouselSliderArrow';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import CarouselNavigation from 'shared/react/components/complex/carousel/CarouselNavigation';

const CarouselSlider = ({ items, carouselScrollToIndex, onArrowClick, renderItem, ...props }) => {
  const itemsContainerRef = useRef();
  const arrowRefs = useRef({
    [LEFT]: null,
    [RIGHT]: null,
  });
  const [isControlsVisible, setIsControlsVisible] = React.useState(false);
  const [selectedDot, setSelectedDot] = React.useState(0);

  const scrollIntoIndex = index => {
    handleScrollTo(index * (IMAGE_WIDTH + ITEMS_GAP));
  };

  const scrollToNext = () => {
    if (!itemsContainerRef.current) {
      return;
    }

    const { scrollLeft, clientWidth } = itemsContainerRef.current;
    handleScrollTo(scrollLeft + clientWidth, true);
  };

  const scrollToPrevious = () => {
    if (!itemsContainerRef.current) {
      return;
    }

    const { scrollLeft, clientWidth } = itemsContainerRef.current;
    handleScrollTo(scrollLeft - clientWidth, true);
  };

  const handleScrollTo = (left, shouldLoop) => {
    if (!itemsContainerRef.current) {
      return;
    }

    const container = itemsContainerRef.current;
    const minLeft = 0;
    const maxLeft = container.scrollWidth - container.clientWidth;
    const currentScroll = container.scrollLeft;
    let newLeft = Math.max(left, minLeft);
    newLeft = Math.min(newLeft, maxLeft);

    if (shouldLoop && newLeft === currentScroll) {
      if (currentScroll === minLeft) {
        newLeft = maxLeft;
      } else if (currentScroll === maxLeft) {
        newLeft = minLeft;
      }
    }

    container.scroll({ left: newLeft, behavior: 'smooth' });
  };

  const onDotsClick = index => {
    setSelectedDot(index);
    scrollIntoIndex(index);
  };

  useEffect(() => {
    const setIndexByScrollPosition = () => {
      const itemWidth = itemsContainerRef.current.children[0].clientWidth;
      if (itemsContainerRef.current.scrollLeft === 0) {
        setSelectedDot(0);
        return;
      }

      if (
        itemsContainerRef.current.scrollLeft ===
        itemsContainerRef.current.scrollWidth - itemsContainerRef.current.clientWidth
      ) {
        setSelectedDot(items.length - 1);
        return;
      }

      const newSelectedIndex = Math.round(itemsContainerRef.current.scrollLeft / itemWidth);

      setSelectedDot(newSelectedIndex);
    };

    if (!itemsContainerRef.current) {
      return;
    }

    itemsContainerRef.current.addEventListener('scroll', setIndexByScrollPosition);

    return () => {
      itemsContainerRef.current?.removeEventListener('scroll', setIndexByScrollPosition);
    };
  }, [items, itemsContainerRef]);

  useEffect(() => {
    if (!itemsContainerRef.current) {
      return;
    }

    const container = itemsContainerRef.current;
    const scrollMaxWidth = container.scrollWidth - container.clientWidth;

    setIsControlsVisible(scrollMaxWidth > 0);
  }, [items]);

  useEffect(() => {
    if (!itemsContainerRef.current) {
      return;
    }

    scrollIntoIndex(carouselScrollToIndex);
  }, [carouselScrollToIndex]);

  if (!items?.length) {
    return null;
  }

  return (
    <LayoutRoot data-type={CAROUSEL_DATA_TYPE} {...props}>
      <CarouselSliderItemsContainer ref={itemsContainerRef} itemsAmount={items.length}>
        {items.map((item, index) => {
          return <ItemContainer key={index}>{renderItem(item)}</ItemContainer>;
        })}
      </CarouselSliderItemsContainer>
      <CarouselNavigation
        itemsLength={items.length}
        onDotClick={onDotsClick}
        selectedIndex={selectedDot}
        scrollIndexIntoView={scrollIntoIndex}
        minimumItemsLength={2}
        withoutArrows
      />
      <CarouselSliderNavigation
        isVisible={isControlsVisible}
        onArrowClick={onArrowClick}
        scrollToNext={scrollToNext}
        scrollToPrevious={scrollToPrevious}
        ref={arrowRefs}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)`
  position: relative;
  direction: ltr;

  & ${CarouselSliderArrowLayoutRoot} {
    display: none;
  }

  &:hover ${CarouselSliderArrowLayoutRoot} {
    display: flex;
  }
`;

export const CarouselSliderItemsContainer = styled(HorizontalFlex)`
  z-index: 1;
  gap: ${ITEMS_GAP}px;
  overflow: auto;
  height: 100%;
  width: 100%;
  scrollbar-width: none;
  position: relative;
  transition: 0.3s;
  justify-content: ${({ itemsAmount }) => (itemsAmount === 1 ? 'center' : '')};
  padding: 0 16px;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ItemContainer = styled(HorizontalFlex)`
  justify-content: center;
  align-items: flex-start;
  width: ${IMAGE_WIDTH}px;
  min-width: ${IMAGE_WIDTH}px;
  min-height: ${IMAGE_HEIGHT}px;
`;

export default CarouselSlider;
