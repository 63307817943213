import { CURRENCY_CODES } from 'shared/react/constants/currency.constants';
import { convertRevenueToUsd } from 'app/src/services/cube/cube.utils';
import { ORDER_ITEMS_V2_PROD } from 'app/src/constants/analytics.constants';
import { CUBE_PROPERTY_KEYS } from 'app/src/constants/cube.constants';

const convertRevenueByTypeToUsd = ({ revenueByType, rates, currency }) => {
  const revenueByTypeInCurrency = {};
  const rate = rates[currency];

  for (const [revenueType, revenue] of Object.entries(revenueByType)) {
    revenueByTypeInCurrency[revenueType] = convertRevenueToUsd({
      revenue,
      rate,
    });
  }

  return revenueByTypeInCurrency;
};

const accumulateCurrenciesToUsd = ({ revenueByCurrency, rates }) => {
  let highestCurrency = CURRENCY_CODES.usd;
  let highestTotalInUsd = 0;
  const revenueByRevenueTypeInUsd = {
    inVideoRevenue: 0,
    postVideoRevenue: 0,
    indirectRevenue: 0,
  };

  for (const [currency, revenueByType] of Object.entries(revenueByCurrency)) {
    const {
      inVideoRevenue: inVideoInUsd = 0,
      postVideoRevenue: postVideoInUsd = 0,
      indirectRevenue: indirectInUsd = 0,
    } = convertRevenueByTypeToUsd({
      revenueByType,
      rates,
      currency,
    });

    const totalRevenueByCurrency = inVideoInUsd + postVideoInUsd + indirectInUsd;

    if (highestTotalInUsd < totalRevenueByCurrency) {
      highestCurrency = currency;
      highestTotalInUsd = inVideoInUsd + postVideoInUsd + indirectInUsd;
    }

    revenueByRevenueTypeInUsd.inVideoRevenue += inVideoInUsd;
    revenueByRevenueTypeInUsd.postVideoRevenue += postVideoInUsd;
    revenueByRevenueTypeInUsd.indirectRevenue += indirectInUsd;
  }

  return {
    revenueByRevenueTypeInUsd,
    highestCurrency,
  };
};

const formatByCurrency = ({ conversionMetrics }) => {
  const currencyData = {};

  for (const conversionMetric of conversionMetrics) {
    let {
      [`${ORDER_ITEMS_V2_PROD}.${CUBE_PROPERTY_KEYS.currency}`]: currency,
      [`${ORDER_ITEMS_V2_PROD}.${CUBE_PROPERTY_KEYS.inVideoRevenue}`]: inVideoRevenue,
      [`${ORDER_ITEMS_V2_PROD}.${CUBE_PROPERTY_KEYS.postVideoRevenue}`]: postVideoRevenue,
      [`${ORDER_ITEMS_V2_PROD}.${CUBE_PROPERTY_KEYS.indirectRevenue}`]: indirectRevenue,
    } = conversionMetric;

    currency = currency?.toUpperCase();

    currencyData[currency] = {
      inVideoRevenue: +inVideoRevenue,
      postVideoRevenue: +postVideoRevenue,
      indirectRevenue: +indirectRevenue,
    };
  }

  return currencyData;
};

const convertRevenueToHighestCurrency = ({ revenueByRevenueTypeInUsd, rates, highestCurrency }) => {
  if (highestCurrency === CURRENCY_CODES.usd) {
    return revenueByRevenueTypeInUsd;
  }

  const rate = rates[highestCurrency];

  return {
    inVideoRevenue: revenueByRevenueTypeInUsd.inVideoRevenue * rate,
    postVideoRevenue: revenueByRevenueTypeInUsd.postVideoRevenue * rate,
    indirectRevenue: revenueByRevenueTypeInUsd.indirectRevenue * rate,
  };
};

export const getMetricsData = ({ conversionMetrics, rates }) => {
  const revenueByCurrency = formatByCurrency({ conversionMetrics });

  const { revenueByRevenueTypeInUsd, highestCurrency } = accumulateCurrenciesToUsd({
    revenueByCurrency,
    rates,
  });

  const { inVideoRevenue, postVideoRevenue, indirectRevenue } = convertRevenueToHighestCurrency({
    revenueByRevenueTypeInUsd,
    rates,
    highestCurrency,
  });

  const directRevenue = inVideoRevenue + postVideoRevenue;
  return {
    currency: highestCurrency,
    directRevenue,
    totalInVideoRevenue: inVideoRevenue,
    totalPostVideoRevenue: postVideoRevenue,
    totalIndirectRevenue: indirectRevenue,
    totalRevenue: inVideoRevenue + postVideoRevenue + indirectRevenue,
  };
};
