import React from 'react';

const UploadIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.844 5.375a.53.53 0 0 1-.719 0 .53.53 0 0 1 0-.719l4.5-4.5A.518.518 0 0 1 7 0c.125 0 .25.063.344.156l4.5 4.5a.53.53 0 0 1 0 .719.53.53 0 0 1-.719 0L7.5 1.719V11c0 .281-.25.5-.5.5a.494.494 0 0 1-.5-.5V1.719L2.844 5.375ZM12.5 13c.25 0 .5.25.5.5 0 .281-.25.5-.5.5h-11a.494.494 0 0 1-.5-.5c0-.25.219-.5.5-.5h11Z"
        fill="#4B60E8"
      />
    </svg>
  );
};

export default UploadIcon;
