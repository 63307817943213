import { useProductRecommendationsContext } from 'shared/react/components/complex/context/ProductRecommendationsContext';

const useProductRecommendations = () => {
  const { productRecommendationsMap = {}, setProductRecommendationsMap } =
    useProductRecommendationsContext();

  const convertProduct = product => {
    return {
      id: product.id,
      compareAtPrice: product.compare_at_price,
      description: product.description,
      imageUrl: `https:${product.featured_image}`,
      price: product.price,
      title: product.title,
      available: product.available,
      media: product.media.map(({ src }) => ({ src })),
      url: product.url,
    };
  };

  const actions = {
    setProductRecommendations: (productId, productRecommendations) => {
      const newRecommendationsMap = { ...productRecommendationsMap };
      newRecommendationsMap[productId] = productRecommendations.map(convertProduct);
      setProductRecommendationsMap(newRecommendationsMap);
      return newRecommendationsMap[productId];
    },
  };

  return [{ productRecommendationsMap }, actions];
};

export default useProductRecommendations;
