import React, { useEffect, useState } from 'react';
import { WhiteButton } from 'shared/react/components/basic/button-v2/BasicButton';
import ReplaceIcon from 'src/images/ReplaceIcon';
import { SearchProduct, SearchProductsPayload } from 'src/types/common';
import styled from 'styled-components';
import ProductTile from 'src/basic_components/ProductTile';
import { useModalActions } from 'src/context/ui_store/ModalStore';
import { SELECT_PRODUCT_MODAL_KEY } from 'src/constants/modals.constants';
import { project as ProjectType } from 'src/types/entities';
import { useApps } from 'src/context/AppsStore';
import useGetSearchProducts from 'src/hooks/useGetSearchProducts';

type Props = {
  externalProductId: string;
  onProductSelected: (externalProductId: string) => Promise<ProjectType>;
};

const ProductSelector = ({ externalProductId, onProductSelected }: Props) => {
  const { setCurrentModal } = useModalActions();
  const [{ selectedApp }] = useApps();
  const getSearchProducts = useGetSearchProducts(selectedApp);
  const [product, setProduct] = useState<Partial<SearchProduct>>({});
  const [isLoadingProduct, setIsLoadingProduct] = useState(false);

  const fetchProduct = async () => {
    setIsLoadingProduct(true);

    const body = {
      appUrl: selectedApp.appUrl,
      appKey: selectedApp.appKey,
      isSearchByIds: true,
      ids: [externalProductId],
    } as SearchProductsPayload;

    const { searchProducts } = await getSearchProducts(body);
    setProduct(searchProducts[0]);
    setIsLoadingProduct(false);
  };

  const handleProductSelected = async (externalProductId: string) => {
    await onProductSelected?.(externalProductId);
    setCurrentModal(null);
  };

  const handleReplaceProductClick = () => {
    setCurrentModal(SELECT_PRODUCT_MODAL_KEY, { onProductSelected: handleProductSelected });
  };

  useEffect(() => {
    if (!externalProductId) {
      return;
    }

    fetchProduct();
  }, [externalProductId]);

  if (!externalProductId) {
    return null;
  }

  return (
    <StyledProductTile
      title={product.title}
      imageUrl={product.imageUrl}
      isLoading={isLoadingProduct}
      titleNumberOfLines={3}
      button={
        <StyledWhiteButton onClick={handleReplaceProductClick}>
          <ReplaceIcon />
        </StyledWhiteButton>
      }
    />
  );
};

const StyledProductTile = styled(ProductTile)`
  width: 275px;
  height: 68px;
  gap: 8px;
  box-shadow: ${({ theme }) => theme.boxShadows.elevation2};
  padding: 8px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};
  align-items: center;

  & > div {
    gap: 8px;
    align-items: center;
    height: 100%;
  }

  & img {
    width: 50px;
    height: 50px;
    box-shadow: ${({ theme }) => theme.boxShadows.elevation1};
    border: 2px solid ${({ theme }) => theme.colors.white};
  }
`;

const StyledWhiteButton = styled(WhiteButton)`
  width: 32px;
  height: 32px;

  & > svg {
    flex-shrink: 0;
  }
`;

export default ProductSelector;
