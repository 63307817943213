import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useOnboarding } from 'src/context/ui_store/OnboardingStore';
import useNavigation from 'src/hooks/useNavigation';
import OnboardingTemplatesEnd from './OnboardingTemplatesEnd';
import OnboardingTemplatesEndShop from 'src/pages/dashboard/pages/homepage/onboarding/templates/components/end_shop/OnboardingTemplatesEndShop';
import OnboardingTemplatesIntegrations from './components/integrations/OnboardingTemplatesIntegrations';
import OnboardingTemplatesReviewVideos from './components/review_videos/OnboardingTemplatesReviewVideos';
import OnboardingTemplatesTagVideos from './components/tag_videos/OnboardingTemplatesTagVideos';
import { OnboardingTemplateStage } from './constants/onboardingTemplates.constants';

type Props = {
  containerRef: React.RefObject<HTMLDivElement>;
};

const OnboardingTemplateContent = ({ containerRef }: Props) => {
  const [{ activeTemplateKey }, { setActiveTemplateKey, setActiveTemplateStageIndex }] =
    useOnboarding();
  const { navigateToOnboardingTemplates } = useNavigation();

  const { stage: activeStage } = useParams();

  useEffect(() => {
    const isOnUnknownTemplate = !activeTemplateKey && activeStage;

    if (isOnUnknownTemplate) {
      navigateToOnboardingTemplates();
    }
  }, [activeStage, activeTemplateKey]);

  useEffect(() => {
    return () => {
      setActiveTemplateKey(null);
      setActiveTemplateStageIndex(null);
    };
  }, []);

  if (!activeTemplateKey) {
    return null;
  }

  switch (activeStage) {
    case OnboardingTemplateStage.integrations:
      return <OnboardingTemplatesIntegrations />;
    case OnboardingTemplateStage.tagVideos:
      return <OnboardingTemplatesTagVideos />;
    case OnboardingTemplateStage.reviewVideos:
      return <OnboardingTemplatesReviewVideos containerRef={containerRef} />;
    case OnboardingTemplateStage.end:
      return <OnboardingTemplatesEnd />;
    case OnboardingTemplateStage.endShop:
      return <OnboardingTemplatesEndShop />;
    default:
      return null;
  }
};

export default OnboardingTemplateContent;
