export const customUpdateApp = /* GraphQL */ `
  mutation CustomUpdateApp($id: ID!, $googleAnalyticsID: String, $settings: AWSJSON, $data: AWSJSON, $importRange: Int) {
    updateApp(input: { id: $id, googleAnalyticsID: $googleAnalyticsID, settings: $settings, data: $data, importRange: $importRange } ) {
      id
      owner
      appKey
      app
      active
      data
      token
      appExternalId
      appUrl
      importSettings
      importRange
      syncTagsMode
      createdAt
      updatedAt
      googleAnalyticsID
      settings
    }
  }
`;
