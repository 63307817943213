import React from 'react';
import {
  Alert,
  AlertDescription,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@tolstoy/ui-library/core';
import { Info } from 'lucide-react';
import Utils from 'shared/react/utils/utils';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { INSTAGRAM_CONNECT_HELP_ARTICLE } from 'app/src/constants/url.constants';
import { PrimaryButton } from 'shared/react/components/basic/button-v2/BasicButton';

type ModalProps = {
  onConnectInstagramGraph: () => void;
};

const InstagramConnectModal = ({ closeModal }) => {
  const [{ modalProps }] = useModal();
  const { onConnectInstagramGraph } = modalProps as ModalProps;

  const handleConnect = () => {
    onConnectInstagramGraph();
  };

  const openHelpUrl = () => {
    Utils.openInNewTab(INSTAGRAM_CONNECT_HELP_ARTICLE);
  };

  return (
    <Dialog open={true} onOpenChange={closeModal}>
      <DialogContent className="sm:max-w-[448px]">
        <DialogHeader className="flex flex-row items-center">
          <DialogTitle className="mr-2">Connect Your Instagram Account(s)</DialogTitle>
          <Info className="!mt-0 h-5 w-5 cursor-pointer text-neutral-500" onClick={openHelpUrl} />
        </DialogHeader>
        <div className="space-y-4">
          <p className="text-sm text-neutral-600">
            You will follow the below steps through Meta&apos;s integration to connect your
            Instagram account(s) to our app:
          </p>
          <ol className="list-inside list-decimal space-y-2 text-sm text-neutral-600">
            <li>
              Click &quot;Edit previous settings&quot; if you&apos;ve already connected to Meta
            </li>
            <li>
              Select all the Facebook Business accounts connected to the Instagram pages you will be
              connecting
            </li>
            <li>
              Select all the Facebook pages connected to the Instagram pages you will be connecting
            </li>
            <li>Select the Instagram pages you want to connect</li>
          </ol>
          <Alert variant="warning" className="border-[#FFC728] bg-[#FFC728] text-black">
            <AlertDescription>
              <strong>Important:</strong> Each time you adjust your settings, previous connections
              will be overwritten. To add more Instagram accounts, make sure to also select all
              previously connected Facebook accounts, pages, and Instagram accounts.
            </AlertDescription>
          </Alert>
          <PrimaryButton onClick={handleConnect} className="w-full">
            Connect to Instagram
          </PrimaryButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default InstagramConnectModal;
