import React from 'react';
import LoadingIndicator from 'shared/react/components/basic/LoadingIndicator';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import styled from 'styled-components';

const ComponentWithOverlayLoader = ({
  isLoading,
  children,
  loaderColor = undefined,
  customLoader = undefined,
  shouldApplyLoadingOpacity = false,
  ...props
}) => {
  const getLoader = () => {
    if (customLoader) {
      return customLoader;
    }

    return <StyledLoadingIndicator loaderColor={loaderColor} />;
  };

  return (
    <LayoutRoot isLoading={isLoading} {...props}>
      <CenteredAbsoluteContainer
        shouldApplyLoadingOpacity={shouldApplyLoadingOpacity}
        isLoading={isLoading}
      >
        {isLoading && getLoader()}
      </CenteredAbsoluteContainer>
      {children}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  pointer-events: ${({ isLoading }) => (isLoading ? 'none' : 'auto')};
`;

const CenteredAbsoluteContainer = styled(VerticalFlex)`
  display: ${({ isLoading }) => (isLoading ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: 0;
  z-index: 10;
  background: ${({ theme, shouldApplyLoadingOpacity, isLoading }) =>
    isLoading && shouldApplyLoadingOpacity ? theme.getHexOpacity(theme.colors.white, 40) : ''};
  pointer-events: none !important;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  border-top: 2px solid ${({ theme, loaderColor }) => loaderColor || theme.colors.white};
  width: 20px;
  height: 20px;
  background: ${({ theme }) => theme.getHexOpacity(theme.colors.black, 10)};
`;

export default ComponentWithOverlayLoader;
