import React from 'react';
import styled from 'styled-components';

type Props = {
  progressPercentage: number;
};

const ProgressBar = ({ progressPercentage }: Props) => {
  return (
    <LayoutRoot>
      <ProgressFill $progressPercentage={progressPercentage} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  background-color: ${({ theme }) => theme.colors.neutralLight};
  border-radius: 4px;
  height: 4px;
  width: 64px;
`;

const ProgressFill = styled.div<{ $progressPercentage: number }>`
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 4px;
  height: 100%;
  width: ${({ $progressPercentage }) => $progressPercentage}%;
`;

export default ProgressBar;
