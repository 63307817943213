import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Fade, TextField, Typography } from '@material-ui/core';
import MoleculeButton from 'shared/react/components/complex/Button';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import google from 'app/src/images/google.svg';
import useStyles from './styles';
import Sizes from 'shared/react/theme/Sizes';
import { track } from '../../helpers/Tracker';
import ResetPasswordWidget from './ResetPasswordWidget';
import StandardModal from '../modals/StandardModal';
import Routes from '../../helpers/Routes';
import useQuery from '../../hooks/useQuery';
import SnackBar from 'app/src/basic_components/SnackBar';
import { TextBody, TextH5, TextSmall, TextSubtitle } from 'shared/react/components/complex/Text';

import FormInput from 'app/src/complex_components/FormInput';
import Utils from '../../utils';
import Toolbar from '../toolbar/Toolbar';
import {
  EMAIL_DATA_TEST_ID,
  I_AGREE_CHECKBOX_DATA_TEST_ID,
  LOGIN_ACTION_BUTTON_DATA_TEST_ID,
  PASSWORD_DATA_TEST_ID,
  SIGN_UP_BUTTON_DATA_TEST_ID,
} from 'app/src/constants/dataTestIds.constants';
import { SHOW_TRIAL_MODAL } from 'app/src/constants/billings.constants';
import { useUser } from 'app/src/context/userStore/UserStore';

const EMAIL_NOT_VALID_ERROR = 'Email is not valid';
const AGREE_TERMS_ERROR =
  'You need to agree to our Terms of service and Privacy Policy to create your account';

const Login = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [, { loginUser, signUpUser, logout, googleLogin, resetPassword }] = useUser();
  const query = useQuery();
  const integration = query.get('integration');
  const inviteToken = query.get('inviteToken');
  const shopifyShop = query.get('shopifyShop');
  const utmSource = query.get('utm_source');
  const urlParams = location.search;
  const integrationToken = query.get('token');
  const [isSignUp, setIsSignUp] = useState(true);
  const [isLoading, setIsLoading] = useState(Utils.getCustomLoginLoading() || false);
  const [error, setError] = useState('');
  const [loginValue, setLoginValue] = useState(query.get('Email') || query.get('Email-2') || '');
  const [passwordValue, setPasswordValue] = useState('');
  const [forgotModalOpen, setForgotModalOpen] = useState(false);
  const [resetEmailValue, setResetEmailValue] = useState('');
  const [successBar, setSuccessBar] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [terms, setTerms] = useState(false);

  useEffect(() => {
    Utils.setInviteDetails();
    if (!Utils.isGhostAccount()) {
      window.Intercom?.('boot');
    }
  }, []);

  useEffect(() => {
    if (isSignUp) {
      Utils.setTolstoyGroup();
    }

    return () => {
      Utils.clearTolstoyGroupIfNeeded();
    };
  }, [isSignUp]);

  useEffect(() => {
    if (location.pathname === Routes.getLogoutRoute()) {
      logout();
    } else {
      setIsSignUp(location.pathname === Routes.getSignupRoute());
    }
  }, [location, history]);

  const setIntegrationDataToLocalStorage = () => {
    if (integration) {
      localStorage.setItem('integration', integration);
    }

    if (integrationToken) {
      localStorage.setItem('integrationToken', integrationToken);
    }

    if (shopifyShop) {
      localStorage.setItem('shopifyShop', shopifyShop);
    }

    if (urlParams) {
      localStorage.setItem('urlParams', urlParams);
    }
  };

  const loginClick = () => {
    track(`${isSignUp ? 'Signup' : 'Login'} Click`);

    if (!isEmail(loginValue)) {
      setError(EMAIL_NOT_VALID_ERROR);
      return;
    }

    if (isSignUp && !terms) {
      setError(AGREE_TERMS_ERROR);
      return;
    }

    setIntegrationDataToLocalStorage();

    const email = loginValue.toLowerCase();
    if (isSignUp) {
      if (window.location?.href?.includes?.(SHOW_TRIAL_MODAL)) {
        localStorage.setItem(SHOW_TRIAL_MODAL, 'true');
      }
      signUpUser({
        email,
        password: passwordValue,
        setIsLoading,
        setError,
        inviteToken,
        utmSource,
      });
    } else {
      loginUser(email, passwordValue, setIsLoading, setError);
    }
  };

  const resetClick = () => {
    if (isEmail(resetEmailValue)) {
      resetPassword(resetEmailValue, setIsLoading, setError, setForgotModalOpen, setSuccessBar);
    } else {
      setErrorEmail(true);
    }
  };

  const isEmail = email => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const onGoogleLogin = () => {
    setIntegrationDataToLocalStorage();
    googleLogin({ isSignUp, inviteToken, utmSource, integration });
  };

  const forgotPasswordModal = () => {
    return (
      <>
        <StandardModal open={forgotModalOpen} onClose={() => setForgotModalOpen(false)}>
          <ResetPasswordWidget disableWidgetMenu title="Reset your password">
            <TextField
              InputProps={{
                classes: { underline: classes.textFieldUnderline, input: classes.textField },
              }}
              value={resetEmailValue}
              onChange={e => setResetEmailValue(e.target.value)}
              margin="normal"
              placeholder="Email Address"
              type="email"
              fullWidth
            />
            <Fade in={errorEmail}>
              <Typography color="secondary" className={classes.errorMessage}>
                Invalid Email
              </Typography>
            </Fade>
            <MoleculeButton
              onClick={resetClick}
              disabled={resetEmailValue.length === 0}
              loading={isLoading}
            >
              Reset Password
            </MoleculeButton>
          </ResetPasswordWidget>
        </StandardModal>
        <SnackBar
          text="Reset password email was sent!"
          open={successBar}
          setOpen={setSuccessBar}
          severity="success"
          autoHideDuration={6000}
        />
      </>
    );
  };

  const changeAuthMethod = path => {
    setErrorEmail(null);
    setError('');
    const integrationRoute = Utils.getIntegrationRoute({
      path,
      integration,
      integrationToken,
      shopifyShop,
      urlParams,
    });
    history.push(integrationRoute);
  };

  const onKeyPressed = e => {
    if (e.keyCode === 13) {
      loginClick();
      // put the login here
    }
  };

  return (
    <MainLoginContainer>
      <Toolbar />
      <LoginFormContainer>
        <LoginTitle>{isSignUp ? 'Sign up' : 'Sign in'}</LoginTitle>
        <GoogleButtonContainer
          size="large"
          className={classes.googleButton}
          onClick={onGoogleLogin}
          disabled={isLoading}
        >
          <GoogleButton>
            <GoogleIcon src={google} alt="google" />
            <GoogleText>Continue with Google</GoogleText>
          </GoogleButton>
        </GoogleButtonContainer>
        <FormDividerContainer>
          <OrSeparator />
          <OrText>OR</OrText>
          <OrSeparator />
        </FormDividerContainer>
        <FieldsContainer>
          <FieldTitle>Business Email</FieldTitle>
          <FormTextField
            InputProps={{
              classes: { underline: classes.textFieldUnderline, input: classes.textField },
            }}
            value={loginValue}
            onKeyDown={onKeyPressed}
            onChange={e => setLoginValue(e.target.value)}
            placeholder="Business Email Address"
            type="email"
            fullWidth
            maxLength={100}
            data-test-id={EMAIL_DATA_TEST_ID}
            disabled={isLoading}
          />
          <PasswordTitleContainer>
            <FieldTitle>Password</FieldTitle>
            {!isSignUp && (
              <ForgotPasswordLink onClick={() => setForgotModalOpen(true)} disabled={isLoading}>
                Forgot password?
              </ForgotPasswordLink>
            )}
          </PasswordTitleContainer>
          <FormTextField
            InputProps={{
              classes: { underline: classes.textFieldUnderline, input: classes.textField },
            }}
            onKeyDown={onKeyPressed}
            value={passwordValue}
            onChange={e => setPasswordValue(e.target.value)}
            placeholder="Password"
            type="password"
            fullWidth
            maxLength={50}
            data-test-id={PASSWORD_DATA_TEST_ID}
            disabled={isLoading}
          />
          {isSignUp && (
            <BelowPasswordContainer>
              <TermsContainer>
                <Checkbox
                  checked={terms}
                  onChange={() => setTerms(!terms)}
                  name="checkedA"
                  style={{ padding: '0px' }}
                  data-test-id={I_AGREE_CHECKBOX_DATA_TEST_ID}
                  disabled={isLoading}
                />
                <IAgreeToText>I agree to the&nbsp;</IAgreeToText>
                <TermsAndConditionsLink
                  onClick={() => Utils.openInNewTab('https://www.gotolstoy.com/terms-of-use')}
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Service
                </TermsAndConditionsLink>
                <TextSmall>&nbsp;and&nbsp;</TextSmall>
                <TermsAndConditionsLink
                  onClick={() => Utils.openInNewTab('https://www.gotolstoy.com/privacy-policy')}
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </TermsAndConditionsLink>
              </TermsContainer>
            </BelowPasswordContainer>
          )}
          <LoginButton
            data-test-id={LOGIN_ACTION_BUTTON_DATA_TEST_ID}
            onClick={loginClick}
            loading={isLoading}
            size={Sizes.Regular}
            fullWidth
          >
            {isSignUp ? 'Create your account' : 'Sign in'}
          </LoginButton>
        </FieldsContainer>
        <Fade in={!!error.length} timeout={30} unmountOnExit={true}>
          <ErrorsContainer>
            {error && (
              <Typography color="secondary" className={classes.errorMessage}>
                {error}
              </Typography>
            )}
          </ErrorsContainer>
        </Fade>

        <ChangeAuthMethodContainer disabled={isLoading}>
          {isSignUp ? (
            <TextBody>Already have an account? </TextBody>
          ) : (
            <TextBody>Don&apos;t have an account? </TextBody>
          )}
          <LargeLink
            onClick={() => {
              changeAuthMethod(isSignUp ? Routes.getLoginRoute() : Routes.getSignupRoute());
            }}
            data-test-id={SIGN_UP_BUTTON_DATA_TEST_ID}
          >
            {isSignUp ? 'Sign in' : 'Sign up'}
          </LargeLink>
        </ChangeAuthMethodContainer>
      </LoginFormContainer>
      {forgotPasswordModal()}
    </MainLoginContainer>
  );
};

const DisabledCss = css`
  opacity: 0.4;
  pointer-events: none;
`;

const IAgreeToText = styled(TextSmall)`
  margin-left: 8px;
`;

const TermsAndConditionsLink = styled(TextSmall)`
  cursor: pointer;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.blue7};
  text-decoration: underline;
`;

const LargeLink = styled(TextBody)`
  cursor: pointer;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.blue7};
`;

const ChangeAuthMethodContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  justify-items: center;
  margin-top: 20px;
  ${({ disabled }) => disabled && DisabledCss}
`;

const BelowPasswordContainer = styled.div`
  display: grid;
  width: 100%;
  align-items: center;
  min-height: 24px;
`;

const LoginButton = styled(MoleculeButton)`
  margin-top: 8px;
`;

const ForgotPasswordLink = styled(TextSmall)`
  justify-self: center;
  cursor: pointer;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.blue7};
  ${({ disabled }) => disabled && DisabledCss}
`;

const TermsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 0;
  justify-items: start;
  align-items: center;
`;

const ErrorsContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 4px;
`;

const FieldTitle = styled(TextSubtitle)``;

const FormTextField = styled(FormInput)`
  margin-bottom: 4px;
  width: 100%;
`;

const PasswordTitleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: end;
`;

const OrSeparator = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.colors.ghostLight};
`;

const OrText = styled(TextBody)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.gray31};
`;

const FormDividerContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  width: 100%;
  gap: 8px;
  padding: 24px;
  border-radius: 16px;
`;

const MainLoginContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  align-items: start;
  justify-items: center;
  align-self: center;
  overflow-y: auto;
`;

const LoginFormContainer = styled.div`
  width: 414px;
  align-self: center;
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-height: 580px) {
    gap: 0;
  }

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 100%;
    padding: 16px;
  }
`;

const LoginTitle = styled(TextH5)`
  margin-top: 28px;
  margin-bottom: 32px;
`;

const GoogleButtonContainer = styled(Button)`
  border-radius: 10px;
  ${({ disabled }) => disabled && DisabledCss}
`;

const GoogleButton = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  justify-items: center;
  column-gap: 16px;
  height: 100%;
  width: 100%;
  text-transform: none;
`;

const GoogleIcon = styled.img`
  width: 24px;
  margin-left: 11px;
  grid-row: 1;
  grid-column: 1;
`;
const GoogleText = styled(TextBody)`
  font-weight: 600;
  grid-row: 1;
  grid-column: 1 / span 2;
`;

export default withRouter(Login);
