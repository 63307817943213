import React from 'react';

const CalendlyBlueIcon = ({ height = 32, width = 33 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.39 20.75c-1.017.902-2.285 2.024-4.586 2.024h-1.376c-1.664 0-3.179-.605-4.262-1.702-1.06-1.072-1.642-2.539-1.642-4.131v-1.882c0-1.592.583-3.06 1.642-4.131 1.083-1.097 2.598-1.701 4.262-1.701h1.376c2.304 0 3.57 1.12 4.585 2.022 1.056.93 1.966 1.742 4.393 1.742.37 0 .74-.03 1.105-.088l-.009-.021a8.324 8.324 0 0 0-.51-1.049l-1.622-2.81a8.334 8.334 0 0 0-7.217-4.166h-3.245a8.333 8.333 0 0 0-7.217 4.166l-1.622 2.81a8.335 8.335 0 0 0 0 8.333l1.622 2.81a8.333 8.333 0 0 0 7.217 4.167h3.245a8.333 8.333 0 0 0 7.217-4.166l1.622-2.806a8.31 8.31 0 0 0 .51-1.048l.009-.02a6.878 6.878 0 0 0-1.105-.094c-2.427 0-3.337.808-4.393 1.742"
      fill="#006BFF"
    />
    <path
      d="M17.804 10.933h-1.376c-2.534 0-4.199 1.81-4.199 4.126v1.882c0 2.317 1.665 4.126 4.2 4.126h1.375c3.692 0 3.402-3.764 8.977-3.764a8.57 8.57 0 0 1 1.575.145 8.334 8.334 0 0 0 0-2.896 8.601 8.601 0 0 1-1.575.145c-5.577 0-5.285-3.764-8.977-3.764Z"
      fill="#006BFF"
    />
    <path
      d="M31.56 18.823a7.773 7.773 0 0 0-3.203-1.376v.028a8.336 8.336 0 0 1-.465 1.622 6.095 6.095 0 0 1 2.648 1.09c0 .008-.005.017-.008.025a14.275 14.275 0 1 1 0-8.424c0 .008.005.018.008.025a6.083 6.083 0 0 1-2.648 1.09c.21.524.365 1.068.465 1.623v.026a7.771 7.771 0 0 0 3.202-1.375c.913-.676.736-1.44.598-1.891a16 16 0 1 0 0 9.428c.138-.452.315-1.215-.598-1.89Z"
      fill="#006BFF"
    />
    <path
      d="M27.887 12.902a6.82 6.82 0 0 1-1.105.093c-2.427 0-3.337-.807-4.391-1.742-1.017-.901-2.283-2.023-4.586-2.023h-1.376c-1.666 0-3.18.605-4.263 1.702-1.059 1.071-1.642 2.538-1.642 4.13v1.883c0 1.592.583 3.059 1.642 4.13 1.084 1.098 2.597 1.702 4.263 1.702h1.376c2.303 0 3.569-1.121 4.586-2.023 1.054-.93 1.964-1.742 4.39-1.742.371 0 .74.03 1.106.088a8.27 8.27 0 0 0 .465-1.622v-.027a8.6 8.6 0 0 0-1.575-.145c-5.577 0-5.285 3.766-8.977 3.766h-1.376c-2.534 0-4.198-1.81-4.198-4.127v-1.886c0-2.317 1.664-4.126 4.198-4.126H17.8c3.692 0 3.402 3.763 8.977 3.763a8.49 8.49 0 0 0 1.575-.144v-.026c-.1-.555-.256-1.1-.465-1.624Z"
      fill="#0AE8F0"
    />
    <path
      d="M27.887 12.902a6.82 6.82 0 0 1-1.105.093c-2.427 0-3.337-.807-4.391-1.742-1.017-.901-2.283-2.023-4.586-2.023h-1.376c-1.666 0-3.18.605-4.263 1.702-1.059 1.071-1.642 2.538-1.642 4.13v1.883c0 1.592.583 3.059 1.642 4.13 1.084 1.098 2.597 1.702 4.263 1.702h1.376c2.303 0 3.569-1.121 4.586-2.023 1.054-.93 1.964-1.742 4.39-1.742.371 0 .74.03 1.106.088a8.27 8.27 0 0 0 .465-1.622v-.027a8.6 8.6 0 0 0-1.575-.145c-5.577 0-5.285 3.766-8.977 3.766h-1.376c-2.534 0-4.198-1.81-4.198-4.127v-1.886c0-2.317 1.664-4.126 4.198-4.126H17.8c3.692 0 3.402 3.763 8.977 3.763a8.49 8.49 0 0 0 1.575-.144v-.026c-.1-.555-.256-1.1-.465-1.624Z"
      fill="#0AE8F0"
    />
  </svg>
);

export default CalendlyBlueIcon;
