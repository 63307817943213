import React, { useState } from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

function Video({ borderRadius, src, width, height, objectFit, mute, hasControls, loop, ...props }) {
  const [videoLoaded, setVideoLoaded] = useState(false);

  function handleCanPlay(e) {
    setVideoLoaded(true);
    return e.target.play();
  }

  function handleError() {}

  return (
    <LayoutRoot {...props}>
      {!videoLoaded && (
        <VideoBack borderRadius={borderRadius} width={width} height={height}>
          <CircularProgress size={20} />
        </VideoBack>
      )}

      <StyledVideo
        src={src}
        borderRadius={borderRadius}
        width={width}
        height={height}
        $objectFit={objectFit}
        visible={src}
        autoplay
        playsInline
        muted={mute}
        loop={loop}
        onLoadedMetadata={handleCanPlay}
        onError={handleError}
        disablePictureInPicture={true}
        controls={hasControls}
      />
    </LayoutRoot>
  );
}

export default Video;

const LayoutRoot = styled.div`
  display: grid;
  width: fit-content;
  height: fit-content;
`;

const VideoBack = styled.div`
  background: black;
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '100%'};
  border-radius: ${props => props.borderRadius || '0'};
  grid-row: 1;
  grid-column: 1;
`;

const StyledVideo = styled.video`
  grid-row: 1;
  grid-column: 1;
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '100%'};
  border-radius: ${props => props.borderRadius || '0'};
  object-fit: ${props => props.$objectFit || 'contain'};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  overflow: hidden;
  opacity: ${props => (props.visible ? '1' : '0')};
  transition: opacity 250ms;
  outline: unset;
  background: ${props => (props.objectFit === 'cover' ? 'transparent' : 'black')};
  z-index: 1;
`;
