import React from 'react';
import styled, { useTheme } from 'styled-components';
import { ComparatorEnum, WhereCondition } from 'app/src/pages/dashboard/utils/filterEntities';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { CheckboxV2 } from 'src/complex_components/Checkbox';
import { TextSmall } from 'shared/react/components/basic/text/TextV2';
import { ASSET_TYPE } from 'shared/react/services/assets';
import {
  isTypeFilterSelected,
  getTypeFilterValue,
  hasSuggestionsSelected,
} from 'src/utils/filters.utils';
import { usePendingVodConnectionSuggestions } from 'src/hooks/vod-connection-suggestions/PendingVodConnectionsContext';

type Props = {
  shouldShowAssetTypeButtons?: boolean;
  filters: WhereCondition;
  setFilters: (filters: WhereCondition, forceReplace?: boolean) => void;
};

const VideosExtraFilters = ({
  shouldShowAssetTypeButtons = false,
  filters = {},
  setFilters,
}: Props) => {
  const theme = useTheme();
  const { shouldShowSuggestions, getSuggestionsByVodAssetId } =
    usePendingVodConnectionSuggestions();

  const isVideoFilterSelected = isTypeFilterSelected(filters, ASSET_TYPE.VIDEO);
  const isImageFilterSelected = isTypeFilterSelected(filters, ASSET_TYPE.IMAGE);
  const hasSuggestionSelected = hasSuggestionsSelected(filters);
  const areBothSelected = !filters.type || (!isVideoFilterSelected && !isImageFilterSelected);

  const resetTypeFilter = () => setFilters({ type: null });
  const setTypeFilter = (type: string) => {
    setFilters({ type: getTypeFilterValue(type) });
  };

  const getHasSuggestions = ({ id: vodAssetId }) => {
    return getSuggestionsByVodAssetId(vodAssetId).length > 0;
  };

  const handleSuggestionsClick = () => {
    if (hasSuggestionSelected) {
      return setFilters({ hasSuggestions: null });
    }

    setFilters({
      hasSuggestions: {
        [ComparatorEnum.satisfies]: {
          fn: getHasSuggestions,
          args: [true],
        },
      },
    });
  };

  const handleVideoFilterClick = () => {
    if (areBothSelected) {
      return setTypeFilter(ASSET_TYPE.IMAGE);
    }

    if (isImageFilterSelected) {
      return resetTypeFilter();
    }

    setTypeFilter(ASSET_TYPE.VIDEO);
  };

  const handleImageFilterClick = () => {
    if (areBothSelected) {
      return setTypeFilter(ASSET_TYPE.VIDEO);
    }

    if (isVideoFilterSelected) {
      return resetTypeFilter();
    }

    setTypeFilter(ASSET_TYPE.IMAGE);
  };

  const filterButtons = [];

  if (shouldShowAssetTypeButtons) {
    filterButtons.push(
      {
        text: 'Videos',
        onClick: handleVideoFilterClick,
        checked: isVideoFilterSelected || areBothSelected,
      },
      {
        text: 'Images',
        onClick: handleImageFilterClick,
        checked: isImageFilterSelected || areBothSelected,
      }
    );
  }

  if (shouldShowSuggestions) {
    filterButtons.push({
      text: 'Suggested products',
      onClick: handleSuggestionsClick,
      checked: hasSuggestionSelected,
    });
  }

  if (!filterButtons.length) {
    return null;
  }

  return filterButtons.map(({ text, onClick, checked }) => (
    <ExtraFilterButton key={text} onClick={onClick}>
      <Checkbox checked={checked} checkBackgrounColor={theme.colors.neutralBlack} />
      <ButtonText>{text}</ButtonText>
    </ExtraFilterButton>
  ));
};

const Checkbox = styled(CheckboxV2)`
  padding: 0;

  background-color: ${({ theme }) => theme.colors.neutralLightest};
`;

const ExtraFilterButton = styled(HorizontalFlexCentered)`
  gap: 8px;
  padding: 4px;
  cursor: pointer;
`;

const ButtonText = styled(TextSmall)`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutralBlack};
`;

export default VideosExtraFilters;
