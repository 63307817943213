import useFeedState from 'shared/react/components/complex/context/hooks/useFeedState';
import {
  TOLSTOY_AUTO_OPEN_URL_PARAM,
  TOLSTOY_VIDEO_ID_URL_PARAM,
  VARIANT_ID_URL_PARAM,
  SHOPIFY_PREVIEW_DOMAIN_NAME,
} from 'player/src/constants/urls.constants';
import Utils from 'player/src/utils';
import { isProductsImportProvider } from 'shared/react/utils/feedProducts.utils';
import UrlHelper from 'shared/react/utils/urlHelper';

const getPDPBaseUrl = ({ appUrl, urlLocale, handle, ecomPlatform }) => {
  const isProductsImport = isProductsImportProvider(ecomPlatform);
  if (isProductsImport && UrlHelper.isValidUrl(handle)) {
    // in product import, handle is the full url
    return new URL(handle);
  }

  const parentUrl = Utils.getParentUrl();
  const productPath = `products/${handle}`;

  if (parentUrl?.includes(SHOPIFY_PREVIEW_DOMAIN_NAME)) {
    const url = new URL(parentUrl);

    return new URL(`${url.origin}${urlLocale}${productPath}`);
  }

  return new URL(`https://${appUrl}${urlLocale}${productPath}`);
};

const useEcomNavigation = () => {
  const [{ urlLocale }] = useFeedState();

  const getPDPUrl = ({ product, variantId, publishId, videoId }) => {
    const { appUrl, handle, ecomPlatform } = product;

    const redirectUrl = getPDPBaseUrl({ appUrl, urlLocale, handle, ecomPlatform });

    if (variantId) {
      redirectUrl.searchParams.append(VARIANT_ID_URL_PARAM, variantId);
    }

    if (publishId) {
      redirectUrl.searchParams.append(TOLSTOY_AUTO_OPEN_URL_PARAM, publishId);
    }

    if (videoId) {
      redirectUrl.searchParams.append(TOLSTOY_VIDEO_ID_URL_PARAM, videoId);
    }

    return redirectUrl.href;
  };

  return {
    getPDPUrl,
  };
};

export default useEcomNavigation;
