import * as React from 'react';

const QuizResultLinkIcon = ({ ...props }) => (
  <svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={48} height={48} rx={12} fill="#EAECF0" />
    <path
      d="M18.797 19.89c1.969-1.968 5.203-1.968 7.172 0a5.12 5.12 0 0 1 .562 6.575l-.035.035c-.351.527-1.055.633-1.582.281-.492-.386-.633-1.09-.246-1.582l.035-.035a2.83 2.83 0 0 0-.316-3.656c-1.09-1.125-2.883-1.125-4.008 0l-3.938 3.937c-1.125 1.09-1.125 2.883 0 4.008.985.985 2.532 1.09 3.657.317l.035-.07c.527-.352 1.23-.247 1.582.28.351.493.246 1.196-.246 1.582l-.07.036a5.073 5.073 0 0 1-6.54-.563 5.03 5.03 0 0 1 0-7.172l3.938-3.972Zm10.371 8.72a5.03 5.03 0 0 1-7.172 0 5.073 5.073 0 0 1-.562-6.54l.035-.035c.351-.527 1.054-.633 1.582-.281.492.351.633 1.055.246 1.582l-.035.035c-.809 1.125-.668 2.672.316 3.656 1.09 1.125 2.883 1.125 4.008 0l3.937-3.937c1.125-1.125 1.125-2.918 0-4.008-.984-.984-2.53-1.125-3.656-.316l-.035.035a1.122 1.122 0 0 1-1.582-.246c-.352-.492-.246-1.196.246-1.582l.07-.035a5.073 5.073 0 0 1 6.54.562 5.03 5.03 0 0 1 0 7.172l-3.938 3.937Z"
      fill="#090A0B"
    />
  </svg>
);

export default QuizResultLinkIcon;
