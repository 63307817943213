import React from 'react';
import {
  FEED_BUTTON_TEXT_DATA_TYPE,
  MORE_BUTTON_DATA_TYPE,
  SMALL_PRODUCT_PRICE_DATA_TYPE,
} from 'shared/react/constants/feedPreviewDataTypes.constants';
import useIsFenty, { FENTY_MAX_QUANTITY } from 'shared/react/hooks/useIsFenty';
import useIsPrincessPolly from 'shared/react/hooks/useIsPrincessPolly';
import styled, { css } from 'styled-components';
import FeedProductPrice from 'shared/react/components/complex/feed/feed_products/FeedProductPrice';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import FeedBrandedButton from 'shared/react/components/complex/feed/feed_buttons/FeedBrandedButton';
import Utils from 'shared/react/utils/utils';
import useIsMilk from 'shared/react/hooks/useIsMilk';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';
import useFeedFeatures from 'shared/react/components/complex/context/hooks/useFeedFeatures';
import { FEATURE_PLAYER_FEED_MOBILE_PRICE_TOP_LOCATION } from 'shared/react/constants/features.constants';
import useFeedCart from 'shared/react/components/complex/context/hooks/useFeedCart';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import {
  PRODUCT_PRICE_CURRENCY_SYMBOL_DATA_TYPE,
  PRODUCT_PRICE_NUMBER_DATA_TYPE,
} from 'shared/react/constants/feedPreviewDataTypes.constants';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';
import { getDesignCss } from 'shared/react/utils/addSharedCss';

const FeedSmallProductViewBottom = ({ selectedVariant, product, cardTextColor, getButtonText }) => {
  const isFenty = useIsFenty();
  const isMilk = useIsMilk();
  const isPrincessPolly = useIsPrincessPolly();
  const [{ direction }] = useTranslation('feed.mobile.productView');
  const [, { getFeatureEnabled }] = useFeedFeatures();
  const [{ cart = {} }] = useFeedCart();
  const { feedDesign } = useDesignSettingsContext();

  const cartProduct = cart?.items?.find(({ variantId }) => {
    return product?.variants?.find(({ id }) => id === variantId);
  });

  const quantity = cartProduct?.quantity || 0;

  const fentyText = !!isFenty && quantity >= FENTY_MAX_QUANTITY ? 'Limited to 5 per order!' : '';

  if (getFeatureEnabled(FEATURE_PLAYER_FEED_MOBILE_PRICE_TOP_LOCATION)) {
    const styledFeedProductPrice = (
      <StyledFeedProductPrice
        data-type={SMALL_PRODUCT_PRICE_DATA_TYPE}
        product={product}
        selectedVariant={selectedVariant}
        isMobileView
        $isPrincessPolly={isPrincessPolly}
        cardTextColor={cardTextColor}
        design={feedDesign?.feedProductCards}
        $design={feedDesign?.feedProductCards.productPrice}
      />
    );
    return (
      <>
        {!isFenty && styledFeedProductPrice}

        <PriceAndButtonConatiner isFenty={isFenty}>
          {isFenty && styledFeedProductPrice}
          <StyledFeedBrandedButton
            data-type={MORE_BUTTON_DATA_TYPE}
            direction={direction}
            isMilk={isMilk}
            isFenty={isFenty}
            buttonText={getButtonText()}
            $isPrincessPolly={isPrincessPolly}
            $design={feedDesign?.feedProductCards?.ctaButton}
          />
          {fentyText && <FentyText>{fentyText}</FentyText>}
        </PriceAndButtonConatiner>
      </>
    );
  }

  return (
    <PriceAndButtonConatiner isFenty={isFenty}>
      <StyledFeedProductPrice
        data-type={SMALL_PRODUCT_PRICE_DATA_TYPE}
        product={product}
        selectedVariant={selectedVariant}
        isMobileView
        cardTextColor={cardTextColor}
        $isPrincessPolly={isPrincessPolly}
        design={feedDesign?.feedProductCards}
        $design={feedDesign?.feedProductCards.productPrice}
      />
      <StyledFeedBrandedButton
        data-type={MORE_BUTTON_DATA_TYPE}
        direction={direction}
        isMilk={isMilk}
        isFenty={isFenty}
        buttonText={getButtonText()}
        $isPrincessPolly={isPrincessPolly}
        $design={feedDesign?.feedProductCards?.ctaButton}
      />
      {fentyText && <FentyText>{fentyText}</FentyText>}
    </PriceAndButtonConatiner>
  );
};

const StyledFeedProductPrice = styled(FeedProductPrice)`
  font-size: 14px;
  line-height: 16px;
  font-weight: ${({ $isPrincessPolly }) => ($isPrincessPolly ? '500' : '600')};
  color: ${({ theme, cardTextColor }) => cardTextColor || theme.colors.white};
  ${({ $design }) => getDesignCss($design)};

  [data-type='${PRODUCT_PRICE_CURRENCY_SYMBOL_DATA_TYPE}'],
  [data-type='${PRODUCT_PRICE_NUMBER_DATA_TYPE}'] {
    display: ${({ $isPrincessPolly }) => ($isPrincessPolly ? 'inline-block' : undefined)};
    padding-top: ${({ $isPrincessPolly }) => ($isPrincessPolly ? '22px' : undefined)};
  }
`;

const PriceAndButtonConatiner = styled(HorizontalFlex)`
  ${({ isFenty }) => {
    if (!isFenty) {
      return;
    }

    return css`
      gap: 8px;
      align-items: center;

      > div {
        margin-left: 0;
        margin-bottom: 0;
      }
    `;
  }}
`;

const StyledFeedBrandedButton = styled(FeedBrandedButton)`
  ${({ $design }) => getDesignCss($design)};
  right: 12px;
  bottom: 8px;
  min-width: 34px;
  max-width: 90px;
  min-height: 16px;
  margin-left: auto;
  white-space: nowrap;

  ${({
    isFenty,
    isMilk,
    $isPrincessPolly,
    $design: { font, fontSize, fontWeight, fontColor } = {},
  }) => css`
    position: ${isFenty ? 'static' : 'absolute'};
    padding: ${isFenty ? '7px 15px' : '5px 15px'};
    text-transform: ${isFenty ? 'uppercase' : 'none'};

    & ${Utils.getDataType(FEED_BUTTON_TEXT_DATA_TYPE)} {
      font-size: 14px;
      line-height: 16px;
      font-family: ${font};
      font-size: ${fontSize}px;
      font-weight: ${fontWeight};
      color: ${fontColor};
      font-size: ${isMilk ? '16px' : isFenty ? '12px' : ''};
      font-weight: ${$isPrincessPolly ? '500' : ''};
      line-height: ${isMilk ? '20px' : isFenty ? '14px' : ''};
      margin-top: ${isFenty ? '2px' : ''};
    }
  `}
`;

const FentyText = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
`;

export default FeedSmallProductViewBottom;
