import React, { useState, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { useTopBarContext } from 'src/context/TopBarStore';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextH6 } from 'shared/react/components/basic/text/TextV2';
import { TopBarProps } from './types/TopBar.types';
import SaveTopBar from './SaveTopBar';
import ProjectsMultiSelectTopBar from './ProjectsMultiSelectTopBar';
import VideosMultiSelectTopBar from './VideosMultiSelectTopBar';

const TopBar = ({
  leftContent,
  centerContent,
  rightContent,
  dirtyTitle,
  renderDirtyTitle,
}: TopBarProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const { dirtyForm, projectsMultiSelectState, videosMultiSelectState } = useTopBarContext();

  const contents = [
    {
      key: 'left-content',
      content: leftContent,
    },
    {
      key: 'center-content',
      content: centerContent,
    },
    {
      key: 'right-content',
      content: rightContent,
    },
  ];

  const onSave = async (e: SyntheticEvent) => {
    setIsSaving(true);
    await dirtyForm.onSaveClick(e);
    setIsSaving(false);
  };

  const getContent = ({ content, key }: { content?: React.ReactNode; key: string }) => {
    if (!content) {
      return null;
    }

    if (typeof content !== 'string') {
      return <div key={key}>{content}</div>;
    }

    return <TextH6 key={key}>{content}</TextH6>;
  };

  if (dirtyForm || isSaving) {
    return (
      <SaveTopBar
        isSaving={isSaving}
        onSave={onSave}
        title={dirtyTitle}
        renderSaveTitle={renderDirtyTitle}
      />
    );
  }

  if (projectsMultiSelectState?.selectedIds?.length) {
    return <ProjectsMultiSelectTopBar />;
  }

  if (videosMultiSelectState?.selectedVideos?.length) {
    return <VideosMultiSelectTopBar />;
  }

  return <DefaultTopBar>{contents.map(getContent)}</DefaultTopBar>;
};

const DefaultTopBar = styled(HorizontalFlex)`
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  min-height: 64px;
  background: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutralLighter};
`;

export default TopBar;
