import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ModalWrapper from 'shared/react/components/basic/ModalWrapper';
import useFeedModal from 'shared/react/components/complex/context/hooks/useFeedModal';
import ReportWrapper from './ReportWrapper';
import Utils from 'shared/react/utils/utils';
import {
  TOLSTOY_REPORT_MODAL_OPEN_MESSAGE,
  TOLSTOY_REPORT_MODAL_CLOSE_MESSAGE,
} from 'shared/react/constants/messages.constants';

const FeedReportModal = () => {
  const [{ currentModal }, { popModal }] = useFeedModal();
  const { onClose, isMobile, videoId } = currentModal.props;

  const [isOpen, setIsOpen] = useState(false);

  const handleModalClosed = () => {
    setIsOpen(false);
    popModal();
    onClose?.();

    Utils.postMessageToWindow({ eventName: TOLSTOY_REPORT_MODAL_CLOSE_MESSAGE });
  };

  useEffect(() => {
    Utils.postMessageToWindow({ eventName: TOLSTOY_REPORT_MODAL_OPEN_MESSAGE });
  }, []);

  return (
    <Wrapper isMobile={isMobile} isOpen={isOpen} setIsOpen={setIsOpen}>
      <ReportWrapper onClose={handleModalClosed} videoId={videoId} />
    </Wrapper>
  );
};

const Wrapper = ({ isMobile, ...props }) => {
  switch (isMobile) {
    case true:
      return <StyledModalWrapperMobile {...props} />;
    case false:
      return <StyledModalWrapper {...props} />;
    default:
      return null;
  }
};

const StyledModalWrapper = styled(ModalWrapper)`
  position: absolute;
  top: 50%;
  right: 62.5%;
  transform: translate(50%, -50%);
  border-radius: 10px;
  width: 400px;
  height: 75%;
  max-height: 1000px;
  z-index: 50;
  box-sizing: border-box;
  padding-top: 60px;
  padding: 16px;
`;

const StyledModalWrapperMobile = styled(ModalWrapper)`
  position: absolute;
  bottom: 0;
  border-radius: 10px 10px 0 0;
  width: 100vw;
  height: 100%;
  z-index: 50;
  box-sizing: border-box;
  padding: 60px 16px 16px 16px;
`;

export default FeedReportModal;
