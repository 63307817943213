import React from 'react';

const SendInvitation = () => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.7422 0.617188C12.5781 0.5 12.3438 0.476562 12.1562 0.59375L1.28125 6.78125C1.09375 6.875 0.976562 7.08594 1 7.29688C1 7.50781 1.14062 7.69531 1.32812 7.78906L4.9375 9.28906V11.9375C4.9375 12.1484 5.03125 12.3359 5.21875 12.4531C5.3125 12.4766 5.40625 12.5 5.47656 12.5C5.57031 12.5 5.6875 12.4766 5.78125 12.4297L8.38281 10.7422L10.6797 11.7266C10.75 11.75 10.8203 11.7734 10.9141 11.7734C11.0078 11.7734 11.1016 11.7266 11.1719 11.6797C11.3359 11.6094 11.4297 11.4453 11.4531 11.2812L12.9531 1.15625C13.0234 0.945312 12.9297 0.734375 12.7422 0.617188ZM9.64844 3.3125L5.26562 8.21094L2.82812 7.17969L9.64844 3.3125ZM6.03906 10.9062V9.75781L7.11719 10.2266L6.03906 10.9062ZM10.4688 10.3906L6.34375 8.65625L11.5938 2.82031L10.4688 10.3906Z"
        fill="white"
      />
    </svg>
  );
};

export default SendInvitation;
