import React from 'react';
import styled from 'styled-components';
import { Gap8HorizontalFlex, HorizontalFlexCentered } from '../complex/flex_layouts/HorizontalFlex';

const BasicRadio = ({ checked, onChange, disabled, children, size, checkedColor }) => {
  const onClick = () => {
    if (disabled || !onChange) {
      return;
    }

    onChange(!checked);
  };

  return (
    <LayoutRoot onClick={onClick} size={size}>
      <RadioButton checked={checked} checkedColor={checkedColor}>
        <CheckIndicator $checked={checked} />
      </RadioButton>
      {children}
    </LayoutRoot>
  );
};

const RadioButton = styled(HorizontalFlexCentered)`
  width: ${({ size }) => size || 16}px;
  height: ${({ size }) => size || 16}px;
  min-width: ${({ size }) => size || 16}px;
  min-height: ${({ size }) => size || 16}px;
  border-radius: 50%;
  border: 1px solid
    ${({ theme, checked, checkedColor }) =>
      checked && checkedColor ? checkedColor : theme.colors.black};
  background-color: ${({ checked, checkedColor, theme }) =>
    checked ? checkedColor || theme.colors.black : ''};
`;

const LayoutRoot = styled(Gap8HorizontalFlex)`
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? '' : 'pointer')};

  :hover ${RadioButton} {
    box-shadow: ${({ theme }) => `0 0 1px 1px ${theme.colors.gray2}`};
  }
`;

const CheckIndicator = styled.div`
  display: ${({ $checked }) => ($checked ? '' : 'none')};
  width: 50%;
  height: 50%;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
`;

export default BasicRadio;
