import React, { useState } from 'react';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';
import { DESCRIPTION_SIDE_PANEL_TYPE } from 'shared/react/constants/feed.constants';
import FeedDescription from 'shared/react/components/complex/feed/feed_description/FeedDescription';
import { useFeedIsMobile } from 'shared/react/hooks/useIsMobile';
import FeedProducts from 'shared/react/components/complex/feed/feed_products/FeedProducts';
import styled from 'styled-components';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import MovieIcon from 'shared/react/images/MovieIcon';
import { TextBodyLeading } from '../Text';
import useFeedFeatures from 'shared/react/components/complex/context/hooks/useFeedFeatures';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { FEATURE_SHOW_VIDEO_NAME } from 'shared/react/constants/features.constants';
import {
  closeSidePanelAnimation,
  openSidePanelAnimation,
} from 'shared/react/components/basic/openSidePanelAnimation';
import useFeedState from 'shared/react/components/complex/context/hooks/useFeedState';
import { TOP_TABS, FEED_TABS } from 'shared/react/constants/feed.constants';
import FeedWishlistSidePanel from 'shared/react/components/complex/feed/feed_wishlist/FeedWishlistSidePanel';
import { topTabsCss } from 'shared/react/components/clientsCss.constants';
import useIsFenty from 'shared/react/hooks/useIsFenty';
import useIsPrincessPolly from 'shared/react/hooks/useIsPrincessPolly';

const getIsSidePanelType = (currentStep, type) => {
  return currentStep.feedSidePanelType === type;
};

const FeedSidePanel = ({ product, currentStepKey, isFullWidth, isMultipleProducts }) => {
  const isMobile = useFeedIsMobile();
  const isFenty = useIsFenty();
  const isPrincessPolly = useIsPrincessPolly();

  const [
    { isShoppable, publishId, showDescriptionIfNoProducts, playerSettings, isAppPreview },
    { getCurrentStep },
  ] = useProjectConfig();

  const [{ isTapcart, isProductsInitialized }] = useFeedState();
  const [, { getFeatureSettingsByKey }] = useFeedFeatures();
  const currentStep = getCurrentStep(currentStepKey);
  const hideWishlist = playerSettings?.hideWishlist || isTapcart || false;
  const featureSettings = getFeatureSettingsByKey(FEATURE_SHOW_VIDEO_NAME);
  const shouldShowVideoName = featureSettings?.projects?.includes(publishId);
  const isDescription =
    getIsSidePanelType(currentStep, DESCRIPTION_SIDE_PANEL_TYPE) &&
    currentStep.feedSidePanelDescription;
  const isProducts = isShoppable && (isMultipleProducts || product);
  const isDynamicDescription =
    !isProducts && currentStep.videoDescription && showDescriptionIfNoProducts;
  const shouldOpen = isDynamicDescription || isDescription || isProducts;
  const [activeTab, setActiveTab] = useState(TOP_TABS[0].key);

  const handleClickTab = (e, tabKey) => {
    e.stopPropagation();
    setActiveTab(tabKey);
  };

  const getSidePanelContent = () => {
    if (activeTab === FEED_TABS.VIDEOS) {
      return (
        <FeedProducts
          isFullWidth={isFullWidth}
          currentStepKey={currentStepKey}
          product={product}
          isMultipleProducts={isMultipleProducts}
        />
      );
    }

    return <FeedWishlistSidePanel currentStepKey={currentStepKey} />;
  };

  if ((isMobile || !isProductsInitialized) && !isAppPreview) {
    return null;
  }
  const showWishlist = !hideWishlist && !isFenty && !isPrincessPolly;

  const getContent = () => {
    if (isDescription || isDynamicDescription) {
      return <FeedDescription currentStepKey={currentStepKey} />;
    }

    if (isProducts) {
      return (
        <>
          {showWishlist && (
            <FeedTabsWrapper>
              {TOP_TABS.map(tab => (
                <FeedTab
                  onClick={e => {
                    handleClickTab(e, tab.key);
                  }}
                  inModal={true}
                  isActive={activeTab === tab.key}
                  key={tab.key}
                >
                  {tab.text}
                </FeedTab>
              ))}
            </FeedTabsWrapper>
          )}
          {!!shouldShowVideoName && (
            <AbsoluteNameOfVideo>
              <StyledTextBodyLeading>Video name is:</StyledTextBodyLeading>
              <VideoName>{currentStep.videoName}</VideoName>
            </AbsoluteNameOfVideo>
          )}
          {getSidePanelContent()}
        </>
      );
    }
  };

  if (featureSettings?.projects?.includes(publishId)) {
    return (
      <LayoutRoot>
        <MovieIcon />
        <StyledTextBodyLeading>Video name is:</StyledTextBodyLeading>
        <VideoName>{currentStep.videoName}</VideoName>
        <Line />
      </LayoutRoot>
    );
  }

  return (
    <LayoutRoot
      shouldOpen={shouldOpen}
      role="group"
      aria-label="Video feed side panel"
      id={`feedSidePanel-${currentStep.videoId}`}
    >
      {getContent()}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)`
  position: relative;
  max-width: 25vw;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};

  & > svg {
    width: 40px;
    height: 40px;
    & path {
      fill: ${({ theme }) => theme.getHexOpacity(theme.colors.black, 20)};
    }
  }

  ${({ shouldOpen }) => (shouldOpen ? openSidePanelAnimation : closeSidePanelAnimation)};
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  margin: 4px;

  background: ${({ theme }) => theme.getHexOpacity(theme.colors.black, 10)};
`;

const StyledTextBodyLeading = styled(TextBodyLeading)`
  font-weight: 500;
`;

const AbsoluteNameOfVideo = styled(Gap16VerticalFlex)`
  position: absolute;
  right: 20px;
  z-index: 100;
  max-width: 150px;
`;

const VideoName = styled(StyledTextBodyLeading)`
  user-select: all;
`;

const FeedTab = styled.div`
  cursor: pointer;
  padding: 8px;
  font-size: 14px;
  position: relative;

  ${topTabsCss}
`;

const FeedTabsWrapper = styled(HorizontalFlex)`
  justify-content: left;
  padding: 16px;
  color: ${({ theme }) => theme.colors.black};
  z-index: 13;
`;

export default FeedSidePanel;
