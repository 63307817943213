import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import EditorOnOffToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorOnOffToggleItem';
import EditorTextInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorTextInputItem';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';
import {
  FAST_FORWARD_ENABLED_PROPERTY_KEY,
  SUBTITLES_ENABLED_PROPERTY_KEY,
  START_SCREEN_TEXT_PROPERTY_KEY,
} from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import ResetToDefaultInputWrapper from 'app/src/complex_components/ResetToDefaultInputWrapper';
import {
  DEFAULT_PLAY_BUTTON_START_SCREEN_TEXT
} from 'shared/react/constants/feature-constants/playButtonCustomization.constants';
import PremiumFeatureTooltip from 'app/src/pages/account/branding/PremiumFeatureTooltip';
import Utils from 'app/src/utils';
import { useProjects } from 'app/src/context/ProjectsStore';
import EditorHeader from '../shared/EditorHeader';
import EditorMainContainer from '../shared/EditorMainContainer';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { publishMethodOptions } from 'app/src/types/entities';

function ControlsEditor() {
  const {
    customizationSettings: { fastForwardEnabled, subtitlesEnabled, startScreenText, feed },
    setCustomizationSettingsProperty,
  } = useLookAndFeelContext();
  const {
    player: { branching }
  } = useDesignSettingsContext();
  const { projectId } = useParams();
  const [{ project }] = useProjects({ projectId });
  const publishMethod = project?.publishMethod;
  const isBubble = publishMethod === publishMethodOptions.bubble;
  const shouldHide = isBubble;

  const handleResetStartText = () => {
    setCustomizationSettingsProperty(
      START_SCREEN_TEXT_PROPERTY_KEY,
      branching.startScreenText || DEFAULT_PLAY_BUTTON_START_SCREEN_TEXT || ''
    );
  };

  return (
    <LayoutRoot>
      <EditorHeader title="Controls" />
      <EditorMainContainer>
        <PremiumFeatureTooltip
          header="Start Text"
          analyticsData={Utils.getAnalyticsLocationObject('Start Text')}
          shouldHide={shouldHide}
          fullWidthContainer={false}
        >
          <ResetToDefaultInputWrapper
            onClick={handleResetStartText}
          >
            <EditorTextInputItem
              text="Start text"
              placeholder="Begin an interactive video"
              value={startScreenText}
              onChange={value =>
                setCustomizationSettingsProperty(START_SCREEN_TEXT_PROPERTY_KEY, value)
              }
            />
          </ResetToDefaultInputWrapper>
        </PremiumFeatureTooltip>

        <EditorOnOffToggleItem
          text="Show captions"
          checked={!!subtitlesEnabled}
          onChange={value =>
            setCustomizationSettingsProperty(SUBTITLES_ENABLED_PROPERTY_KEY, value)
          }
        />
        {!feed && (
          <EditorOnOffToggleItem
            text="Allow to change video speed"
            checked={!!fastForwardEnabled}
            onChange={value =>
              setCustomizationSettingsProperty(FAST_FORWARD_ENABLED_PROPERTY_KEY, value)
            }
          />
        )}
      </EditorMainContainer>
    </LayoutRoot>
  );
}

export default ControlsEditor;

const LayoutRoot = styled(VerticalFlex)``;
