import React from 'react';
import Cases from 'app/src/pages/dashboard/components/cases/Cases';
import {
  CASE_TYPES_OFFSITE,
  CASE_TYPE_GUIDES,
} from 'app/src/pages/dashboard/components/cases/cases.constants';
import { useApps } from 'app/src/context/AppsStore';

const OffsiteEmptyState = () => {
  const [{ shopify: isShopify }] = useApps();

  return (
    <Cases
      types={CASE_TYPES_OFFSITE}
      isEmptyState
      isOffsite
      guide={isShopify ? CASE_TYPE_GUIDES.offsite : undefined}
    />
  );
};

export default OffsiteEmptyState;
