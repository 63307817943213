import React from 'react';

const InfoIcon = ({ fill = '#7D8087', ...props }) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 2.5c-4.156 0-7.5 3.375-7.5 7.5 0 4.156 3.344 7.5 7.5 7.5 4.125 0 7.5-3.344 7.5-7.5 0-4.125-3.375-7.5-7.5-7.5ZM10 16c-3.313 0-6-2.688-6-6 0-3.281 2.688-6 6-6 3.281 0 6 2.719 6 6 0 3.313-2.719 6-6 6Zm1.25-3.5h-.5V9.75A.76.76 0 0 0 10 9H9a.74.74 0 0 0-.75.75c0 .438.313.75.75.75h.25v2h-.5a.74.74 0 0 0-.75.75c0 .438.313.75.75.75h2.5a.74.74 0 0 0 .75-.75.76.76 0 0 0-.75-.75ZM10 8c.531 0 1-.438 1-1 0-.531-.469-1-1-1-.563 0-1 .469-1 1 0 .563.438 1 1 1Z"
      fill={fill}
    />
  </svg>
);

export default InfoIcon;
