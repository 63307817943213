import React from 'react';

const StopIcon = () => (
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.031 14.125 2.875 4.969A6.548 6.548 0 0 0 1.5 9c0 3.594 2.906 6.5 6.5 6.5 1.5 0 2.906-.5 4.031-1.375Zm1.063-1.063A6.597 6.597 0 0 0 14.5 9c0-3.563-2.938-6.5-6.5-6.5a6.597 6.597 0 0 0-4.063 1.406l9.157 9.156ZM0 9a7.94 7.94 0 0 1 4-6.906c2.469-1.438 5.5-1.438 8 0C14.469 3.53 16 6.156 16 9a8.02 8.02 0 0 1-4 6.938c-2.5 1.437-5.531 1.437-8 0A7.953 7.953 0 0 1 0 9Z"
      fill="#090A0B"
    />
  </svg>
);

export default StopIcon;
