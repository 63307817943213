import styled from 'styled-components';

const Text = styled.div`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.black};
`;

export const TextH1 = styled(Text)`
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.015em;
`;

export const TextH2 = styled(Text)`
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.005em;
`;

export const TextH2Bold = styled(TextH2)`
  font-weight: 700;
`;

export const TextH3 = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
`;

export const TextH3Bold = styled(TextH3)`
  font-weight: 700;
`;

export const TextH4 = styled(Text)`
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.0025em;
`;

export const TextH5 = styled(Text)`
  font-size: 17px;
  line-height: 23px;
`;

export const TextH6 = styled(Text)`
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0015em;
`;

export const TextOverline = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.05em;
`;

export const TextBody = styled(Text)`
  font-size: 16px;
  line-height: 22px;
`;

export const TextBodyBold = styled(TextBody)`
  font-weight: 700;
`;

export const TextSmall = styled(Text)`
  font-size: 14px;
  line-height: 18px;
`;

export const TextSmallBold = styled(TextSmall)`
  font-weight: 700;
`;

export const TextTiny = styled(Text)`
  font-size: 12px;
  line-height: 14px;
`;

export const TextXTiny = styled(Text)`
  font-size: 10px;
  line-height: 12px;
`;
