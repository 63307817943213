import { useEffect, useState } from 'react';
import { useVodLabels } from 'app/src/context/VodLabelsStore';
import { useVodAssetIdsByLabelId } from 'src/context/VodConnectionStore';
import { PlaylistItem } from '../types/playlist.types';

const getCustomPlaylists = (vodLabels, vodAssetIdsByLabelId): PlaylistItem[] => {
  return vodLabels
    .map(label => ({
      ...label,
      count: vodAssetIdsByLabelId[label.id]?.length || 0,
    }))
    .sort((playlistA, playlistB) => playlistB.count - playlistA.count);
};

const useCustomPlaylists = (): PlaylistItem[] => {
  const [{ vodLabels }] = useVodLabels();
  const [vodAssetIdsByLabelId] = useVodAssetIdsByLabelId();
  const [customPlaylists, setCustomPlaylists] = useState<PlaylistItem[]>(
    getCustomPlaylists(vodLabels, vodAssetIdsByLabelId)
  );

  useEffect(() => {
    setCustomPlaylists(getCustomPlaylists(vodLabels, vodAssetIdsByLabelId));
  }, [vodLabels, vodAssetIdsByLabelId]);

  return customPlaylists;
};

export default useCustomPlaylists;
