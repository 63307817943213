import React from 'react';

const EmailOpenedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.406 5.094c.375.281.594.718.594 1.187V14c0 1.125-.906 2-2 2H2c-1.125 0-2-.875-2-2V6.281c0-.468.188-.906.563-1.187C1.343 4.5 1.969 4 5.688 1.312 6.218.938 7.25 0 7.967 0H8c.719 0 1.75.938 2.281 1.313C14 4 14.625 4.5 15.406 5.092M14.5 14V8.656l-4.281 3.531A3.4 3.4 0 0 1 8 13a3.58 3.58 0 0 1-2.25-.781L1.5 8.656V14c0 .281.219.5.5.5h12c.25 0 .5-.219.5-.5m0-7.281V6.28c-.75-.562-1.344-1.031-5.094-3.75-.125-.094-.219-.156-.344-.25-.25-.219-.78-.625-1.062-.75-.312.125-.844.532-1.094.75-.125.094-.218.156-.312.25C2.812 5.25 2.219 5.72 1.5 6.281v.438l5.188 4.312c.718.594 1.875.594 2.593 0z"
        fill="#7D8087"
      />
    </svg>
  );
};

export default EmailOpenedIcon;
