import React, { FC, useState } from 'react';

import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import ImportFileHeader from './ImportFileHeader';
import ImportFileInput from './ImportFileInput';
import ImportFileRecurring from './ImportFileRecurring';
import { useApps } from 'src/context/AppsStore';
import { useProductActions } from 'src/context/ProductsStore';
import { useUser } from 'src/context/userStore/UserStore';
import ImportFileCurrency from './ImportFileCurrency';
import BasicAccordionV2, { StepName, StepNumber } from 'src/basic_components/BasicAccordionV2';

const Step2ImportFile: FC = () => {
  const [{ productsImport }] = useApps();
  const [{ account }] = useUser();
  const { updateProductsIntegration } = useProductActions();
  const [isLoading, setIsLoading] = useState(false);

  const updateIntegration = async appData => {
    setIsLoading(true);

    await updateProductsIntegration({
      appKey: account.appKey,
      appData,
    });

    setIsLoading(false);
  };

  return (
    <BasicAccordionV2
      header={
        <>
          <StepNumber>Step 2</StepNumber>
          <StepName>Import CSV file</StepName>
        </>
      }
    >
      <Container>
        <FileArea>
          <ImportFileHeader />

          <ImportFileInput
            app={productsImport}
            account={account}
            isLoading={isLoading}
            updateIntegration={updateIntegration}
          />

          <ImportFileRecurring
            updateIntegration={updateIntegration}
            app={productsImport}
            isLoading={isLoading}
          />
        </FileArea>
        <ImportFileCurrency
          updateIntegration={updateIntegration}
          app={productsImport}
          isLoading={isLoading}
        />
      </Container>
    </BasicAccordionV2>
  );
};

const Container = styled.div`
  margin: 16px;
`;

const FileArea = styled(VerticalFlex)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutralLightest};
  border: 2px dashed ${({ theme }) => theme.colors.neutralLight};
  padding: 32px;
  gap: 24px;
  margin-bottom: 24px;
`;

export default Step2ImportFile;
