import React from 'react';
import styled from 'styled-components';
import { useLookAndFeelContext } from '../../../LookAndFeelContext';
import { CHECKOUT_SHOPPING_BAG_SETTING_TOOLTIP_TEXT } from 'app/src/constants/ui.constants';
import { PRODUCT_DETAILS_CHECKOUT_SHOPPING_BAG_PROPERTY_KEY } from '../../../LookAndFeelPage.constants';
import EditorHeader from '../shared/EditorHeader';
import OnOffToggleItem from '../shared/OnOffToggleItem';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const ProductDetailsCheckoutEditor = () => {
  const {
    customizationSettings: {
      playerSettings: { productDetailsCheckoutShoppingBagEnabled },
    },
    setPlayerSettingsProperty,
  } = useLookAndFeelContext();

  const onEnableCheckoutShoppingBag = () => {
    setPlayerSettingsProperty(
      PRODUCT_DETAILS_CHECKOUT_SHOPPING_BAG_PROPERTY_KEY,
      !productDetailsCheckoutShoppingBagEnabled
    );
  };

  return (
    <LayoutRoot>
      <EditorHeader
        title="Checkout & shopping bag"
        isWhite={true}
        tooltipText={CHECKOUT_SHOPPING_BAG_SETTING_TOOLTIP_TEXT}
      >
        <OnOffToggleItem
          checked={productDetailsCheckoutShoppingBagEnabled}
          onChange={onEnableCheckoutShoppingBag}
        />
      </EditorHeader>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

export default ProductDetailsCheckoutEditor;
