import React from 'react';
import { project as Project, publishMethodOptions } from 'app/src/types/entities';
import { useApps } from 'app/src/context/AppsStore';
import {
  EMBED_WIDGETS_SHOPIFY_INSTALL_ORDER,
  EmbedWidgetsInstall,
  EmbedWidgetsShopifyInstall,
} from 'app/src/types/install';
import InstallStepSimple from 'app/src/pages/project/pages/installation/components/shared/InstallStepSimple';
import styled from 'styled-components';
import { TextSmall, TextTiny } from 'shared/react/components/basic/text/TextV2';
import { getLiquidCode } from 'app/src/pages/project/pages/installation/components/installation.helpers';

type Props = {
  project: Project;
  isShopify: boolean;
  isDisabled: boolean;
};

const NonShopifyLiquidCodeDisplay = ({ publishMethod, publishId }) => {
  return (
    <TextTinyDark>
      {`<tolstoy-${publishMethod}`}
      <br />
      <IndentContainer>
        {`id="${publishId}"`}
        <br />
        {`class="tolstoy-${publishMethod}"`}
        <br />
        {`data-product-id="`}
        <TextTinyRed>PRODUCT_ID</TextTinyRed>
        {`">`}
        <br />
      </IndentContainer>
      {`</tolstoy-${publishMethod}>`}
    </TextTinyDark>
  );
};

const getCodeToDisplay = (liquidCode: string, isShopify: boolean, project: Project) => {
  const publishMethod = project?.publishMethod;
  const publishId = project?.publishId;

  if (isShopify) {
    return liquidCode;
  }

  return <NonShopifyLiquidCodeDisplay publishMethod={publishMethod} publishId={publishId} />;
};

const NonShopifyCodeSectionDescription = () => (
  <TextSmallDark>
    {`Copy the following code, and place it in your site's code.`}
    <br />
    {`Make sure to change the `}
    <TextSmallRed>PRODUCT_ID</TextSmallRed>
  </TextSmallDark>
);

const getStepTitleAndDescription = (
  isShopify: boolean,
  project: Project,
  publishMethod: publishMethodOptions
) => {
  if (isShopify) {
    return {
      stepTitle: `Add “${project.name}“ ${publishMethod} to store`,
      stepDescription: 'Copy the following code, and place it your page’s liquid.',
    };
  }

  return {
    stepTitle: `Add ${project.name} ${publishMethod} to site`,
    stepDescription: <NonShopifyCodeSectionDescription />,
  };
};

const ManualInstallationStep = ({ project, isDisabled }: Props) => {
  const [{ shopify: isShopify }] = useApps();
  const publishMethod = project?.publishMethod;

  const liquidCode = getLiquidCode(isShopify, project);
  const codeToDisplay = getCodeToDisplay(liquidCode, isShopify, project);
  const currentStepName = EmbedWidgetsInstall.addCode;
  const startExpanded =
    !project.installStep ||
    EMBED_WIDGETS_SHOPIFY_INSTALL_ORDER.includes(project.installStep as EmbedWidgetsShopifyInstall);

  const { stepTitle, stepDescription } = getStepTitleAndDescription(
    isShopify,
    project,
    publishMethod
  );

  return (
    <InstallStepSimple
      codeToCopy={liquidCode}
      codeToDisplay={codeToDisplay}
      copyText="Copy Code"
      isDisabled={isDisabled}
      text={stepDescription}
      stepNumber={1}
      stepHeader={stepTitle}
      currentStepName={currentStepName}
      project={project}
      startExpanded={startExpanded}
    />
  );
};

const TextSmallDark = styled(TextSmall)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.neutralDark};
`;

const TextSmallRed = styled(TextSmall)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.red1};
`;

const TextTinyRed = styled(TextTiny)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.red1};
`;

const TextTinyDark = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralDark};
`;

const IndentContainer = styled.div`
  margin-left: 20px;
`;

export default ManualInstallationStep;
