import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import VideoOverlay from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/video_preview/VideoOverlay';
import LoadingVideo from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/video_preview/LoadingVideo';
import { BaseVideoSize } from 'app/src/basic_components/BaseVideoSize';
import VideoNotFound from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/video_preview/VideoNotFound';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { CREATION_FLOW_MODAL_KEY } from 'app/src/constants/modals.constants';
import FeedSidePanelContainer from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/feed/FeedSidePanelContainer';
import RootProvidersWrapper from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/video_preview/RootProvidersWrapper';
import { MOBILE_VIDEO_WIDTH } from 'app/src/constants/editStep.constants';
import {
  DESCRIPTION_SIDE_PANEL_TYPE,
  SHOPABLE_SIDE_PANEL_TYPE,
} from 'shared/react/constants/feed.constants';
import PreviewCss from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/feed/feed_preview_css/PreviewCss';
import { useApps } from 'app/src/context/AppsStore';
import { useVideos } from 'app/src/context/VideosStore';
import SharedUtils from 'shared/react/utils/utils';
import { VIDEO_STATUS } from 'shared/react/constants/video.constants';
import AddNewStepMenu from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/tolstoy_list/add-new-step/AddNewStepMenu';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import { FEATURE_TOLSTOY_V2 } from 'shared/react/constants/features.constants';

const VIDEO_PROCESSING_RETRY_ATTEMPTS = 10;
const THREE_MINUTES_IN_MILLISECONDS = 1000 * 60 * 3;

const VideoPreview = ({
  onVideoLoaded,
  videoLoaded,
  videoRef,
  video,
  videoUrl,
  isProductType,
  isLoading,
  isEditingAnswersTime,
  setEditingAnswersTime,
  isVideoReady,
  videoDuration,
  setVideoLoaded,
  step,
  project,
  isMobilePreview,
}) => {
  const [anchorElement, setAnchorElement] = useState(null);
  const [, { setCurrentModal, setModalProps }] = useModal();
  const { setValue, watch, reply } = useFormContext();
  const location = useLocation();
  const [, { isEcomPlatformConnected }] = useApps();
  const [, { getBasicVideoUrl }] = useVideos(step.videoId);
  const { getFeatureEnabled } = useFeatureActions();
  const isTolstoyV2Enabled = getFeatureEnabled(FEATURE_TOLSTOY_V2);
  const isExtension = location?.state?.chromeExtension;

  const [
    answers,
    isDefaultResponseButtonsEnabled,
    defaultResponses,
    sideBarButtonsEnabled,
    answerTime,
    products,
    overlayText,
    overlayTextColor,
    videoContain,
    feedSidePanelType,
  ] = watch([
    'answers',
    'defaultResponsesEnabled',
    'defaultResponses',
    'sideBarButtonsEnabled',
    'answerTime',
    'products',
    'overlayText',
    'overlayTextColor',
    'videoContain',
    'feedSidePanelType',
  ]);

  const setAnswerTime = time => {
    setValue('answerTime', time, false);
  };

  const [isMuted, setIsMuted] = useState(true);
  const [playTime, setPlayTime] = useState(0);
  const [videoError, setVideoError] = useState(false);
  const counterRef = useRef();
  const timeoutRef = useRef();

  const handleTimeUpdate = () => {
    setPlayTime(videoRef?.current?.currentTime);
  };

  const onLoad = () => {
    videoRef.current.play();
    onVideoLoaded();
  };

  const onChangeVideoClick = e => {
    if (isTolstoyV2Enabled) {
      setAnchorElement(e.currentTarget);
      return;
    }

    setModalProps({ step, project, reply, changedVideo: video });
    setCurrentModal(CREATION_FLOW_MODAL_KEY);
  };

  const handleVideoLoadError = e => {
    const target = e.target;

    if (counterRef.current >= VIDEO_PROCESSING_RETRY_ATTEMPTS && location.state?.shouldRetry) {
      clearTimeout(timeoutRef.current);
      setVideoError(true);
      return;
    }

    let currentUtcDate = new Date().toISOString();
    const millisecondsSinceCreation = new Date(currentUtcDate) - new Date(video.createdAt);

    if (THREE_MINUTES_IN_MILLISECONDS >= millisecondsSinceCreation || isExtension) {
      timeoutRef.current = setTimeout(() => {
        counterRef.current++;
        target.src = videoUrl;
      }, 10000);
      return;
    }

    if (SharedUtils.getIsShopifyCDN(videoUrl)) {
      target.src = getBasicVideoUrl(video);
      return;
    }

    setVideoError(true);
  };

  const handleVideoClick = () => {
    videoRef.current.paused ? videoRef.current.play() : videoRef.current.pause();
  };

  useEffect(() => {
    clearTimeout(timeoutRef.current);

    if (!video || video?.status === VIDEO_STATUS.invalid) {
      setVideoError(true);
      return;
    }

    setVideoError(false);
  }, [video]);

  useEffect(() => {
    setVideoLoaded(false);
  }, [videoUrl]);

  const getVideoContain = () => {
    if (!isFeed || isMobilePreview) {
      return !videoContain;
    }

    return false;
  };

  const getIsHorizontal = () => {
    return !isFeed && !isMobilePreview && !verticalOrientation;
  };

  const getOverlayContent = () => {
    if (videoError) {
      return (
        <LoadingContainer>
          <VideoNotFound handleChangeVideoClick={onChangeVideoClick} />
        </LoadingContainer>
      );
    }

    return (
      <RootProvidersWrapper project={project}>
        <VideoOverlay
          answers={isDefaultResponseButtonsEnabled ? defaultResponses : answers}
          videoLoaded={videoLoaded}
          isProductType={isProductType}
          isDefaultResponseButtonsEnabled={isDefaultResponseButtonsEnabled}
          overlayText={overlayText}
          overlayTextColor={overlayTextColor}
          products={products}
          isSidebarButtonsEnabled={sideBarButtonsEnabled}
          setMute={setIsMuted}
          mute={isMuted}
          isLoading={isLoading}
          isVideoReady={isVideoReady}
          isEditingAnswersTime={isEditingAnswersTime}
          setEditingAnswersTime={setEditingAnswersTime}
          verticalOrientation={verticalOrientation}
          videoDuration={videoDuration}
          playTime={playTime}
          answerTime={answerTime}
          setAnswerTime={setAnswerTime}
          videoRef={videoRef}
          project={project}
          step={step}
          isMobilePreview={isMobilePreview}
          video={video}
          onChangeVideoClick={onChangeVideoClick}
        />
      </RootProvidersWrapper>
    );
  };

  const isDescriptionSidePanel = feedSidePanelType === DESCRIPTION_SIDE_PANEL_TYPE;
  const isShoppableSidePanel = feedSidePanelType === SHOPABLE_SIDE_PANEL_TYPE;
  const isFeed = project.feed;
  const verticalOrientation = isFeed ? false : project.verticalOrientation;
  const isMultipleProducts = products?.length > 1;
  return (
    <LayoutRoot
      onClick={handleVideoClick}
      isFeed={isFeed}
      isMobilePreview={isMobilePreview}
      isShoppableSidePanel={isShoppableSidePanel}
      isMultipleProducts={isMultipleProducts}
      isEcomPlatformConnected={isEcomPlatformConnected}
      isHorizontal={getIsHorizontal()}
      isReply={reply}
    >
      {!videoLoaded && !videoError && (
        <LoadingContainer>
          <LoadingVideo />
        </LoadingContainer>
      )}
      <Video
        as="video"
        src={isVideoReady ? videoUrl : ''}
        visible={videoLoaded && !videoError}
        autoplay
        ref={videoRef}
        playsInline
        muted={isMuted}
        loop
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={onLoad}
        onError={handleVideoLoadError}
        disablePictureInPicture={true}
        videoContain={getVideoContain()}
        reply={!!reply}
        isFeed={isFeed}
        isMobilePreview={isMobilePreview}
        verticalOrientation={verticalOrientation}
      />
      {isFeed && (
        <RootProvidersWrapper project={project}>
          <FeedSidePanelContainer
            step={step}
            isMobilePreview={isMobilePreview}
            isDescriptionSidePanel={isDescriptionSidePanel}
          />
        </RootProvidersWrapper>
      )}
      {getOverlayContent()}
      <AddNewStepMenu
        project={project}
        step={step}
        anchorElement={anchorElement}
        setAnchorElement={setAnchorElement}
        changedVideo={video}
        direction={{ vertical: 'center', horizontal: 'right' }}
      />
    </LayoutRoot>
  );
};

const overlay = css`
  grid-column: 1;
  grid-row: 1;
`;

const LayoutRoot = styled.div`
  display: grid;
  max-height: 540px;
  grid-template-columns: ${({ isMobilePreview, isDescriptionSidePanel, isHorizontal }) => {
    if ((isDescriptionSidePanel && isMobilePreview) || isHorizontal) {
      return '';
    }

    if (isMobilePreview) {
      return `${MOBILE_VIDEO_WIDTH}px ${MOBILE_VIDEO_WIDTH}px`;
    }

    return '70% 30%';
  }};
  border-left: ${({ isReply, theme }) => (isReply ? `1px solid ${theme.colors.neutralWhite}` : '')};
  gap: ${({ isMobilePreview }) => (isMobilePreview ? '24px' : '')};
  width: 100%;
  justify-content: center;
  max-width: 960px;
  ${PreviewCss};

  @media (${({ theme }) => theme.breakpoints.laptopMax}) {
    max-height: 410px;
  }
`;

const Video = styled(BaseVideoSize)`
  aspect-ratio: ${({ verticalOrientation, isMobilePreview }) =>
    verticalOrientation || isMobilePreview ? '9/16' : '16/9'};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  background: ${({ theme }) => theme.colors.black};
  width: 100%;
  height: 100%;
  object-fit: ${props => (props.videoContain ? 'cover' : 'contain')};
  ${overlay};
  max-width: ${({ isMobilePreview }) => (isMobilePreview ? `${MOBILE_VIDEO_WIDTH}px` : '')};
  border-radius: ${({ reply, isFeed, isMobilePreview }) => {
    if (isMobilePreview) {
      return '16px';
    }

    if (isFeed) {
      return '16px 0 0 16px';
    }

    if (reply) {
      return '0 0 16px 16px';
    }

    return '16px';
  }};
`;

const LoadingContainer = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  justify-items: center;
  align-items: center;
  visibility: visible;
  ${overlay}
`;

export default VideoPreview;
