import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TvStep1 from 'app/src/pages/project/pages/installation/components/tv/images/tv-step1.png';
import TvStep2 from 'app/src/pages/project/pages/installation/components/tv/images/tv-step2.png';
import TvStep3 from 'app/src/pages/project/pages/installation/components/tv/images/tv-step3.png';
import TvStep4 from 'app/src/pages/project/pages/installation/components/tv/images/tv-step4.png';
import TvStepOptional from 'app/src/pages/project/pages/installation/components/tv/images/tv-optional.png';
import { project as Project } from 'app/src/types/entities';
import { useApps } from 'app/src/context/AppsStore';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import CopyCode from 'app/src/complex_components/CopyCode';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { Input } from 'shared/react/components/basic/input/Input';
import { ShopifyTvInstall, TvInstall } from 'app/src/types/install';
import { useBuilderFormContext } from 'app/src/context/BuilderFormStore';

const useTvInstallSteps = (
  project: Project,
  isDisabled: boolean,
  showManualInstallCode: boolean
) => {
  const [inputValue, setInputValue] = useState('');
  const [shouldOpenSecondNonShopifyStep, setShouldOpenSecondNonShopifyStep] = useState(false);
  const [{ shopify: isShopify }] = useApps();
  const { getProjectTypeDiscover } = useBuilderFormContext();
  const getCode = () => {
    const dataHeaderId = inputValue ? ` data-header-id='${inputValue}'` : ``;
    return `<div id='tolstoy-tv-container' data-publish-id='${project.publishId}'${dataHeaderId} class='tolstoy-video-page' />`;
  };

  const onSetInputValue = e => {
    const value = e.target.value;

    setInputValue(value);
  };

  useEffect(() => {
    if (
      inputValue &&
      !shouldOpenSecondNonShopifyStep &&
      project.installStep === TvInstall.setYourSiteHeader
    ) {
      setShouldOpenSecondNonShopifyStep(true);
    }
  }, [project]);

  const nonShopifySteps = [
    {
      isDisabled: isDisabled,
      text: (
        <Gap16VerticalFlex>
          <div>Inspect header → Copy header&apos;s element ID → Paste ID in input</div>
          <CustomInput
            placeholder="Enter your site's header Id"
            value={inputValue}
            onChange={onSetInputValue}
          />
        </Gap16VerticalFlex>
      ),
      stepHeader: "Set your site's header",
      startExpanded: !shouldOpenSecondNonShopifyStep,
      currentStepName: TvInstall.setYourSiteHeader,
    },
    {
      isDisabled: isDisabled,
      stepHeader: 'Copy code and paste on an empty page',
      startExpanded: shouldOpenSecondNonShopifyStep,
      text: (
        <HorizontalFlex>
          <CustomCopyCode codeToCopy={getCode()} />
        </HorizontalFlex>
      ),
    },
  ];

  const shopifySteps = [
    {
      image: <DefaultImage src={TvStep1} width="662px" />,
      isDisabled: isDisabled,
      text: 'Go to Online store → Themes → Customize → Pages → Create template → “Video page”',
      stepHeader: 'Create a new template',
      startExpanded: !project.installStep || project.installStep === TvInstall.setYourSiteHeader,
      currentStepName: ShopifyTvInstall.createTemplate,
    },
    {
      image: <DefaultImage src={TvStep2} width="508px" />,
      isDisabled: isDisabled,
      text: 'Go to Online Store → Pages → Add page → Theme template → Video page* → Page name',
      stepHeader: 'Create new video page',
      description: '* Make sure to assign page to new “Video page” template',
      currentStepName: ShopifyTvInstall.createNewVideoPage,
      startExpanded: project.installStep === ShopifyTvInstall.createTemplate,
    },
    {
      image: <DefaultImage src={TvStep3} width="564px" />,
      isDisabled: isDisabled,
      text: 'Go to Online store → Themes → Customize → Pages → “Video page” → Add sections → “Tolstoy TV page”',
      stepHeader: 'Add section to video page',
      currentStepName: ShopifyTvInstall.addSection,
      startExpanded: project.installStep === ShopifyTvInstall.createNewVideoPage,
    },
    {
      image: <DefaultImage src={TvStep4} width="564px" />,
      isDisabled: isDisabled,
      stepHeader: 'Paste ID in section',
      text: 'Copy the FeedID and paste in the Publish ID in your “Tolstoy TV page” section on Shopify themes',
      codeToCopy: project?.publishId,
      copyText: 'Copy ID',
      currentStepName: ShopifyTvInstall.pasteId,
      startExpanded: project.installStep === ShopifyTvInstall.addSection,
    },
    {
      image: <DefaultImage src={TvStepOptional} width="397px" />,
      isDisabled: isDisabled,
      stepHeader: 'Set menu item',
      text: 'Go to → Online Store → Navigation → Main menu → Add menu item→ Select “Video page” for link',
      headerOptionalText: '(Optional)',
      startExpanded: project.installStep === ShopifyTvInstall.pasteId,
    },
  ];

  const discoverSteps = [];

  if (!isShopify || showManualInstallCode) {
    return nonShopifySteps;
  }

  if (getProjectTypeDiscover(project)) {
    return discoverSteps;
  }

  return shopifySteps;
};

const DefaultImage = styled.img<{ width: string }>`
  width: ${({ width }) => width};
  max-width: 100%;
  object-fit: contain;
`;

const CustomInput = styled(Input)`
  max-width: 480px;
  padding: 10px 12px;
  font-size: 14px;
`;

const CustomCopyCode = styled(CopyCode)`
  max-width: 480px;
  background: ${({ theme }) => theme.colors.neutralLightest};
`;

export default useTvInstallSteps;
