import { TextBody, TextH6 } from 'shared/react/components/complex/Text';
import React from 'react';
import { Link } from '@material-ui/core';
import Utils from 'app/src/utils';
import styled from 'styled-components';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import SetLiveTogglePricing from 'app/src/complex_components/pricing/SetLiveTogglePricing';

const IntegrationSetLiveContent = ({
  isIntegrationApiSetWidget,
  loading,
  handlePublishAppChange,
  appActive,
  appUrl,
}) => {
  return (
    <>
      {isIntegrationApiSetWidget && (
        <StyledHorizontalFlex>
          <AddToSite>
            <TextH6>Add to site</TextH6>
            <SetLiveTogglePricing
              disabled={loading}
              checked={appActive}
              onChange={handlePublishAppChange}
            />
          </AddToSite>
        </StyledHorizontalFlex>
      )}
      {appUrl && (
        <StyledHorizontalFlex>
          <TextBody>
            Once {!isIntegrationApiSetWidget ? 'set live' : 'added'}, your widget will be published
            on: <br />
            <Link href={Utils.getLink(appUrl)} target="_blank" rel="noopener">
              {appUrl}
            </Link>
            .
          </TextBody>
        </StyledHorizontalFlex>
      )}
    </>
  );
};

const AddToSite = styled(Gap8HorizontalFlexWrap)`
  align-items: center;
`;

export const StyledHorizontalFlex = styled(HorizontalFlexWrap)``;

export default IntegrationSetLiveContent;
