import { useApps } from 'app/src/context/AppsStore';
import { useEffect } from 'react';
import { useTikTok } from 'app/src/context/TikTokStore';
import useIntegrationsVideos from 'app/src/hooks/useIntegrationsVideos';

export const useTikTokLibraryVideos = () => {
  const [{ tiktokApps, initialized: appsInitialized }] = useApps();

  const [
    {
      accountVideos: tiktokVideos,
      initialized: tiktokInitialized,
      loading,
      gotAllVideos: gotAllTiktok,
    },
    { fetchByApps: fetchTikTokVideos, fetchUserInfoByApps },
  ] = useTikTok();

  const initialized = (!tiktokApps.length && appsInitialized) || tiktokInitialized;
  useEffect(() => {
    if (!initialized && tiktokApps.length) {
      fetchTikTokVideos(tiktokApps);
    }
  }, [initialized, tiktokApps]);

  const { allTiktokAppsHaveUserInfo } = useIntegrationsVideos();
  useEffect(() => {
    if (tiktokApps.length && !allTiktokAppsHaveUserInfo) {
      fetchUserInfoByApps(tiktokApps);
    }
  }, [allTiktokAppsHaveUserInfo, tiktokApps]);

  const loadMore = async () => {
    if (initialized && !loading && !gotAllTiktok) {
      await fetchTikTokVideos(tiktokApps);
    }
  };

  return { videos: tiktokVideos, initialized, loadMore, isFetchingNextPage: loading };
};
