import React from 'react';

const StraightArrowRightIcon = ({ ...props }) => {
  return (
    <svg
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m11.016 6.04-3.75 3.75a.723.723 0 0 1-1.055 0 .723.723 0 0 1 0-1.056L8.67 6.25H1.5a.74.74 0 0 1-.75-.75c0-.398.328-.75.75-.75h7.172L6.21 2.29a.723.723 0 0 1 0-1.056.723.723 0 0 1 1.055 0l3.75 3.75a.723.723 0 0 1 0 1.055Z"
        fill="#fff"
      />
    </svg>
  );
};

export default StraightArrowRightIcon;
