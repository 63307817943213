import React, { forwardRef, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import FeedOverlay from 'player/src/components/feed/feed_overlay/FeedOverlay';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { ImageRenditionsService } from 'shared/react/services/assets';
import ImageWithFallback from 'shared/react/images/ImageWithFallback';
import { CarouselProvider } from 'shared/react/components/complex/context/CarouselContext';
import useFeedState from 'shared/react/components/complex/context/hooks/useFeedState';

const PUBLIC_PATH = '/public/';

const FeedCarousel = forwardRef(
  (
    {
      step,
      index,
      videoRef,
      numOfSteps,
      nextStepName,

      isPlaying,
      isCurrentVideo,
      isPlayerPreview,
      isPlayerVisible,
      isStepOutOfView,

      handlePlayPause,
      handleNavigateToStep,
    },
    ref
  ) => {
    const { videoOwner: owner, referenceIds, stockAsset } = step;
    const audioRef = useRef(new Audio());
    const [{ isUserMute }] = useFeedState();

    let posterOwnerId = owner;
    if (stockAsset?.posterUrl) {
      const ownerStartIndex = stockAsset.posterUrl.indexOf(PUBLIC_PATH) + PUBLIC_PATH.length;
      const ownerEndIndex = stockAsset.posterUrl.indexOf('/', ownerStartIndex);
      posterOwnerId = stockAsset.posterUrl.substring(ownerStartIndex, ownerEndIndex);
    }
    const effectiveOwner = posterOwnerId !== owner ? posterOwnerId : owner;

    const carouselSrcs = referenceIds.map(referenceId => {
      return ImageRenditionsService.getRenditionUrl({ id: referenceId, owner: effectiveOwner });
    });

    const imagesContainerRef = useRef(null);
    const [currentAssetIndex, setCurrentAssetIndex] = useState(0);

    const scrollLeft = e => {
      e.stopPropagation();
      if (imagesContainerRef.current) {
        imagesContainerRef.current.scrollBy({
          left: -imagesContainerRef.current.offsetWidth,
          behavior: 'smooth',
        });
      }
      setCurrentAssetIndex(currentAssetIndex - 1);
    };

    const scrollRight = e => {
      e.stopPropagation();
      if (imagesContainerRef.current) {
        imagesContainerRef.current.scrollBy({
          left: imagesContainerRef.current.offsetWidth,
          behavior: 'smooth',
        });
      }
      setCurrentAssetIndex(currentAssetIndex + 1);
    };
    const showCarouselNavigation = carouselSrcs.length > 1;
    const showLeftArrow = currentAssetIndex > 0;
    const showRightArrow = currentAssetIndex < carouselSrcs.length - 1;

    const scrollToIndex = (index, e) => {
      e.stopPropagation();
      imagesContainerRef.current.scrollTo({
        left: index * imagesContainerRef.current.offsetWidth,
      });
      setCurrentAssetIndex(index);
    };

    const updateCurrentIndex = () => {
      const container = imagesContainerRef.current;
      if (!container) return;

      const scrollPosition = container.scrollLeft;
      const childWidth = container.firstChild.offsetWidth;
      const newIndex = Math.round(scrollPosition / childWidth);

      if (newIndex !== currentAssetIndex) {
        setCurrentAssetIndex(newIndex);
      }
    };

    useEffect(() => {
      const currentAudio = audioRef.current;
      if (isCurrentVideo && stockAsset?.audioUrl) {
        audioRef.current.src = stockAsset?.audioUrl;
        currentAudio.loop = true;

        currentAudio.muted = !!isUserMute;

        currentAudio.play();
      } else {
        currentAudio.pause();
        currentAudio.loop = false;
      }

      if (isCurrentVideo && stockAsset?.audioUrl && !isPlayerVisible) {
        currentAudio.pause();
        currentAudio.removeAttribute('src');
        currentAudio.load();
      }

      return () => {
        currentAudio.pause();
        currentAudio.removeAttribute('src');
        currentAudio.load();
      };
    }, [isCurrentVideo, isPlayerVisible, isUserMute]);

    useEffect(() => {
      const container = imagesContainerRef.current;
      if (container) {
        container.addEventListener('scroll', updateCurrentIndex);

        return () => {
          container.removeEventListener('scroll', updateCurrentIndex);
        };
      }
    }, [currentAssetIndex]);

    return (
      <CarouselProvider
        value={{
          currentAssetIndex,
          referenceIds,
          showCarouselNavigation,
          showLeftArrow,
          showRightArrow,
          scrollToIndex,
        }}
      >
        <LayoutRoot ref={ref} $isStepOutOfView={isStepOutOfView} data-video-index={index}>
          <ImagesContainer ref={imagesContainerRef}>
            {carouselSrcs.map(src => (
              <Image src={src} key={src} data-video-index={index} onClick={handlePlayPause} />
            ))}
          </ImagesContainer>
          <FeedOverlay
            isCurrentVideo={isCurrentVideo}
            videoRef={videoRef}
            audioRef={audioRef}
            handleNavigateToStep={handleNavigateToStep}
            nextStepName={nextStepName}
            handlePlayPause={handlePlayPause}
            isPlaying={isPlaying}
            step={step}
            numOfSteps={numOfSteps}
            isFeed={true}
            isPlayerPreview={isPlayerPreview}
            scrollLeft={scrollLeft}
            scrollRight={scrollRight}
          />
        </LayoutRoot>
      </CarouselProvider>
    );
  }
);

const ImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 100%;
  min-height: 100%;
  height: 100%;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  position: relative;
  z-index: 1;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

const Image = styled(ImageWithFallback)`
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  object-fit: contain;
  scroll-snap-align: start;
  position: relative;
`;

const LayoutRoot = styled(HorizontalFlexCentered)`
  display: ${({ $isStepOutOfView }) => ($isStepOutOfView ? 'none' : 'flex')};
  height: 100%;
  width: 100%;
  scroll-snap-align: start;
  min-width: 100%;
  min-height: 100%;
  scroll-snap-stop: always;
  position: relative;
  overflow: hidden;
`;

FeedCarousel.displayName = 'FeedCarousel';

export default FeedCarousel;
