import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import BaseDetails from '../BaseDetails';
import PersonalInformation from './PersonalInformation';
import { useUser } from 'app/src/context/userStore/UserStore';
import { CircularProgress } from '@material-ui/core';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Calendars from './Calendars';
import StickyTopBar from 'app/src/pages/dashboard/components/top-bar/StickyTopBar';

const getName = (firstName, lastName) => {
  if (!firstName && !lastName) {
    return '';
  }

  if (!lastName) {
    return firstName.trim();
  }

  if (!firstName) {
    return lastName.trim();
  }

  return `${firstName} ${lastName}`.trim();
};

const USER_LOGO_NAME = 'user_logo.png';

const MyProfilePage = () => {
  const [{ userLogoSettings }, { updateUser, getUser }] = useUser();
  const user = getUser();
  const [firstName, setFirstName] = useState(user?.firstName);
  const [lastName, setLastName] = useState(user?.lastName);
  const [email, setEmail] = useState(user?.email);
  const name = getName(firstName, lastName);

  useEffect(() => {
    if (!user) {
      return;
    }
    setFirstName(user?.firstName);
    setLastName(user?.lastName);
    setEmail(user?.email);
  }, [user]);

  if (!user?.owner) {
    return <StyledCircularProgress size={20} />;
  }

  return (
    <LayoutRoot>
      <StickyTopBar leftContent="My profile" />
      <Content>
        <MainContainer>
          <BaseDetails
            user={user}
            updateFunction={updateUser}
            header={name}
            headerPlaceholder={'Full name'}
            subHeader={email}
            subHeaderPlaceholder={'Email'}
            avatarName={email}
            routeKey={`assets/${user.owner}/${USER_LOGO_NAME}`}
            logoSettings={userLogoSettings}
            location={'Account'}
          />
          <PersonalInformation firstName={firstName} lastName={lastName} email={email} />
          <Calendars />
        </MainContainer>
      </Content>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

const Content = styled(VerticalFlex)`
  width: 100%;
  height: 100%;
  padding: 32px;
  flex-grow: 1;
`;

const MainContainer = styled(VerticalFlex)`
  gap: 40px;
`;

const StyledCircularProgress = styled(CircularProgress)`
  align-self: start;
  margin: 32px;
`;

export default MyProfilePage;
