import { VodConnectionType } from 'src/types/entities';
import { useCreateVodConnection } from 'src/hooks/vod-connections';
import {
  useVodConnectionsList,
  useProjectIdsByVodAssetIdMap,
} from 'src/context/VodConnectionStore';

export const useVodProjectIdConnectionActions = () => {
  const { createVodConnection } = useCreateVodConnection();
  const [projectIdsByVodAssetIdMap, { deleteVodConnection }] = useProjectIdsByVodAssetIdMap();

  const createVideoProjectIdConnection = async ({ projectId, vodAssetId, appUrl }) => {
    if (!vodAssetId || !projectId || !appUrl) {
      return;
    }

    await createVodConnection({
      appUrl,
      projectId,
      vodAssetId,
      type: VodConnectionType.vodProjectId,
    });
  };

  const deleteVodProjectIdConnection = async ({ vodAssetId, projectId }) => {
    const connectionToRemove = projectIdsByVodAssetIdMap[vodAssetId]?.find(
      conection => conection.projectId === projectId
    );

    if (!connectionToRemove) {
      return;
    }

    await deleteVodConnection(connectionToRemove.id);
  };

  return { createVideoProjectIdConnection, deleteVodProjectIdConnection };
};
