import { useEffect } from 'react';

const useEventListener = ({ eventName, ref, callback }) => {
  useEffect(() => {
    if (!ref.current) {
      return;
    }

    ref.current.addEventListener(eventName, callback);

    return () => {
      ref.current?.removeEventListener(eventName, callback);
    };
  }, [ref.current, callback]);
};

export default useEventListener;
