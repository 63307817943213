import React from 'react';
import styled from 'styled-components';
import sailthruIcon from './sailthru-icon.png';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextH2 } from 'shared/react/components/basic/text/TextV2';

const SailthruIcon = (props: React.HTMLProps<HTMLDivElement>) => {
  return (
    <LayoutRoot {...props}>
      <img src={sailthruIcon} alt="Sailthru" width="32" height="32" />
      <Text>SAILTHRU</Text>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlexCentered)`
  gap: 8px;
  height: 40px;
  overflow: hidden;
`;

const Text = styled(TextH2)`
  color: #7d8086;
  font-size: 20px;
`;

export default SailthruIcon;
