import ANALYTICS_OVERVIEW_EMPTY_STATE_DATA from 'app/src/complex_components/analytics/overview/analyticsOverviewEmptyStateData.json';
import { ANALYTICS_OVERVIEW_TOTAL_REVENUE } from 'app/src/constants/analytics.constants';
import { useAnalytics } from 'app/src/context/AnalyticsStore';
import Utils from 'app/src/utils';
import { useProjectActions } from 'src/context/ProjectsStore';
import { useUser } from 'app/src/context/userStore/UserStore';

const useAnalyticsOverview = () => {
  const [
    {
      isLoadingOrdersAovOvertime,
      isLoadingOverviewEngagementOvertime,
      isLoadingTopConvertingVideoMetrics,
      isLoadingUniqueUsersMetrics,
      topConvertingVideoMetrics,
      topConvertingVideoInsights,
      uniqueUsersMetrics,
      engagementMetrics,
      videoMetricsSummary,
      productClicksOvertime,
      isLoadingProductClicksOvertime,
    },
    {
      getConversionMetricsData,
      getConversionOvertimeData,
      getConversionOrdersAovData,
      getOverviewEngagementOvertimeData,
      getBenchmarkData,
      getConversionRatesOvertimeData,
      getUniqueUsersSummary,
      getTimeOnPageOvertimeSummary,
    },
  ] = useAnalytics();
  const { getIsAnyProjectLive } = useProjectActions();
  const [{ account }] = useUser();

  const { currency, totalIndirectRevenue, directRevenue, totalRevenue } =
    getConversionMetricsData() || {};

  // Total revenue
  const formatMoney = value => Utils.formatNumber({ value, currency, letter: false });
  const formatAndRoundNumber = value => Utils.formatNumberToString(value, 0, 0, false);
  const totalRevenueData = {
    breakdown: [
      {
        name: ANALYTICS_OVERVIEW_TOTAL_REVENUE.DIRECT,
        formattedValue: formatMoney(directRevenue),
        tooltipText: ANALYTICS_OVERVIEW_TOTAL_REVENUE.DIRECT_TOOLTIP,
        value: directRevenue,
      },
      {
        name: ANALYTICS_OVERVIEW_TOTAL_REVENUE.INDIRECT,
        formattedValue: formatMoney(totalIndirectRevenue),
        tooltipText: ANALYTICS_OVERVIEW_TOTAL_REVENUE.INDIRECT_TOOLTIP,
        value: totalIndirectRevenue,
      },
    ],
  };
  const totalRevenueNoData =
    Utils.isNullOrUndefined(directRevenue) && Utils.isNullOrUndefined(totalIndirectRevenue);
  const totalRevenueInvalidData = isNaN(directRevenue) || isNaN(totalIndirectRevenue);
  const totalRevenueShouldShowEmptyState = totalRevenueNoData || totalRevenueInvalidData;
  const totalRevenueShouldShowLoader =
    directRevenue === undefined || totalIndirectRevenue === undefined;
  const totalRevenueSubtitle = formatMoney(directRevenue + totalIndirectRevenue);

  // AOV Uplift Overtime
  const {
    ordersAovOvertimeData,
    tolstoyOrdersAov,
    tolstoyOrdersTotalCount,
    baselineOrdersAov,
    aovOvertimeUplift,
    baselineOrdersTotalCount,
  } = getConversionOrdersAovData() || {};
  const formattedTolstoyOrdersAov = formatMoney(tolstoyOrdersAov);
  const formattedBaselineOrdersAov = formatMoney(baselineOrdersAov);
  const aovOvertimeNoData = Utils.isNullOrUndefined(aovOvertimeUplift);
  const aovOvertimeShouldShowEmptyState =
    Utils.isNullOrUndefined(ordersAovOvertimeData) || aovOvertimeNoData;
  const aovOvertimeShouldShowLoader = isLoadingOrdersAovOvertime;

  // Revenue
  const revenueOvertimeData = getConversionOvertimeData();
  const revenueSubtitle = formatMoney(totalRevenue);
  const revenueInvalidData = isNaN(directRevenue) && directRevenue !== undefined;
  const revenueNoData =
    Utils.isNullOrUndefined(revenueSubtitle) || Utils.isNullOrUndefined(revenueOvertimeData);
  const revenueShouldShowEmptyState = revenueNoData || revenueInvalidData;
  const revenueShouldShowLoader = revenueNoData && !revenueInvalidData;

  // Viewers
  const { totalUniqueUsersSessionStarts, totalUniqueUsersPageViews } = getUniqueUsersSummary();
  const fmtTotalUniqueUsersSessionStarts = formatAndRoundNumber(totalUniqueUsersSessionStarts);
  const fmtTotalUniqueUsersPageViews = formatAndRoundNumber(totalUniqueUsersPageViews);
  const viewersShouldShowLoader = isLoadingUniqueUsersMetrics;
  const viewersShowEmptyState = isLoadingUniqueUsersMetrics || !uniqueUsersMetrics;
  const engagementRate = Utils.getPercentage(
    totalUniqueUsersSessionStarts,
    totalUniqueUsersPageViews,
    false,
    1
  );

  const totalVideosWatched = videoMetricsSummary?.totalVideosWatched;
  const fmtVideoWatched = formatAndRoundNumber(totalVideosWatched);
  const fmtAverageVideosWatchedPerSession = Utils.formatNumberToString(
    totalVideosWatched / engagementMetrics?.sessionStart,
    0,
    1
  );

  // Engagement overtime/CTA
  const { overviewEngagementOvertimeMetrics } = getOverviewEngagementOvertimeData() || {};
  const { engagementOvertimeNoData, overallNumOfProductInteractions } =
    overviewEngagementOvertimeMetrics || {};

  // Product Interactions
  const formattedOverallNumOfProductInteractions = formatAndRoundNumber(
    overallNumOfProductInteractions
  );
  const numOfProductClicks = Utils.sumBy(productClicksOvertime, r => r.numOfProductClicks);
  const formattedNumOfProductClicks = formatAndRoundNumber(numOfProductClicks);
  const productCTR = Utils.getPercentage(
    numOfProductClicks,
    Utils.sumBy(productClicksOvertime, r => r.numOfSessionStarts),
    false,
    1
  );
  const formattedProductCTR = Utils.formatNumber({
    value: productCTR,
    isPercentage: true,
  });

  const clickThoughRate = Utils.getPercentage(
    overallNumOfProductInteractions,
    engagementMetrics?.sessionStart,
    false,
    1
  );

  const formattedClickThoughRate = Utils.formatNumber({
    value: clickThoughRate,
    isPercentage: true,
  });

  // Conversion Rates
  const timeOnPageOvertimeSummary = getTimeOnPageOvertimeSummary();
  const conversionRatesOvertimeData = getConversionRatesOvertimeData();
  const shouldShowConversionRatesLoader =
    isLoadingOverviewEngagementOvertime || isLoadingOrdersAovOvertime;
  const shouldShowConversionRatesEmptyState = engagementOvertimeNoData || aovOvertimeNoData;
  const tolstoyConversionRate = Utils.getPercentage(
    tolstoyOrdersTotalCount,
    totalUniqueUsersSessionStarts,
    false,
    1
  );
  const fmtTolstoyOrdersTotalCount = formatAndRoundNumber(tolstoyOrdersTotalCount);

  const tolstoyOrdersOfTotalOrders = Utils.getPercentage(
    tolstoyOrdersTotalCount,
    baselineOrdersTotalCount,
    false,
    1
  );

  const baselineConversionRate = Utils.getPercentage(
    baselineOrdersTotalCount,
    timeOnPageOvertimeSummary.numOfNonInteractingVisitsUnique,
    false,
    1
  );

  const formattedTolstoyConversionRate = Utils.formatNumber({
    value: tolstoyConversionRate,
    isPercentage: true,
  });
  const formattedBaselineConversionRate = Utils.formatNumber({
    value: baselineConversionRate,
    isPercentage: true,
  });

  // Top converting videos
  const isTopConvertingVideosEmpty =
    !topConvertingVideoMetrics?.length && !isLoadingTopConvertingVideoMetrics;

  const isAnalyticsOverviewEmpty =
    totalRevenueShouldShowEmptyState &&
    !totalRevenueShouldShowLoader &&
    revenueShouldShowEmptyState &&
    !revenueShouldShowLoader &&
    aovOvertimeShouldShowEmptyState &&
    !aovOvertimeShouldShowLoader &&
    isTopConvertingVideosEmpty;
  const benchmarkData = getBenchmarkData();

  const shouldShowEmptyState =
    isAnalyticsOverviewEmpty && !getIsAnyProjectLive() && !account?.liveIds?.length;

  if (shouldShowEmptyState) {
    return { ...ANALYTICS_OVERVIEW_EMPTY_STATE_DATA, benchmarkData };
  }

  return {
    currency,

    totalRevenueData,
    totalRevenueShouldShowEmptyState,
    totalRevenueShouldShowLoader,
    totalRevenueSubtitle,

    revenueSubtitle,
    revenueOvertimeData,
    revenueShouldShowEmptyState,
    revenueShouldShowLoader,

    formattedOverallNumOfProductInteractions,
    productCTR,
    formattedProductCTR,
    formattedClickThoughRate,

    ordersAovOvertimeData,
    formattedTolstoyOrdersAov,
    formattedBaselineOrdersAov,
    aovOvertimeShouldShowEmptyState,
    aovOvertimeShouldShowLoader,
    fmtTolstoyOrdersTotalCount,
    tolstoyOrdersOfTotalOrders,

    engagementRate,
    fmtTotalUniqueUsersPageViews,
    fmtTotalUniqueUsersSessionStarts,
    fmtAverageVideosWatchedPerSession,
    fmtVideoWatched,
    totalUniqueUsersPageViews,
    totalUniqueUsersSessionStarts,
    uniqueUsersMetrics,
    viewersShouldShowLoader,
    viewersShowEmptyState,

    baselineConversionRate,
    conversionRatesOvertimeData,
    formattedBaselineConversionRate,
    formattedTolstoyConversionRate,
    shouldShowConversionRatesEmptyState,
    shouldShowConversionRatesLoader,
    tolstoyConversionRate,

    isLoadingTopConvertingVideoMetrics,
    topConvertingVideoMetrics,
    isTopConvertingVideosEmpty,
    topConvertingVideoInsights,

    productClicksOvertime,
    formattedNumOfProductClicks,
    isLoadingProductClicksOvertime,

    benchmarkData,
  };
};

export default useAnalyticsOverview;
