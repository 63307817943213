import React from 'react';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Card from 'app/src/basic_components/Card';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import ProjectCardDetails from 'app/src/pages/dashboard/pages/libraries/tolstoys_tab/project_card_v2/ProjectCardDetails';
import ProjectCardImageContainer from 'app/src/pages/dashboard/pages/libraries/tolstoys_tab/project_card_v2/ProjectCardImageContainer';
import { track } from 'app/src/helpers/Tracker';

const BUTTONS_CONTAINER = 'buttons-container';

const ProjectCardV2 = ({
  project,
  setSelectedTolstoys,
  selectedTolstoys,
  handleNavigateToProject,
  onProjectClick,
  ...props
}) => {
  const isChecked = selectedTolstoys.includes(project?.id);
  const isFeed = project.feed;

  const handleProjectClick = () => {
    if (onProjectClick) {
      onProjectClick(project);
      return;
    }

    if (selectedTolstoys.length) {
      selectProject();
      return;
    }

    handleNavigateToProject(project);
  };

  const selectProject = () => {
    if (!isChecked) {
      track('Project Card Selected');
      setSelectedTolstoys([...selectedTolstoys, project.id]);
      return;
    }

    track('Project Card Select Canceled');
    const filteredVideos = selectedTolstoys.filter(id => {
      return id !== project.id;
    });
    setSelectedTolstoys(filteredVideos);
  };

  return (
    <StyledCard shadowAnimationsEnabled onClick={handleProjectClick} {...props}>
      <LayoutRoot isChecked={isChecked}>
        <ProjectCardImageContainer
          {...props}
          project={project}
          buttonsContainer={BUTTONS_CONTAINER}
          selectProject={selectProject}
          isChecked={isChecked}
          selectedCardsLength={selectedTolstoys.length}
          isFeed={isFeed}
        />
        <DetailsContainer>
          <ProjectCardDetails
            isFeed={isFeed}
            selectedCardsLength={selectedTolstoys.length}
            project={project}
          />
        </DetailsContainer>
      </LayoutRoot>
    </StyledCard>
  );
};

export default ProjectCardV2;

const LayoutRoot = styled(VerticalFlex)`
  gap: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  border: 2px solid ${({ isChecked, theme }) => (isChecked ? theme.colors.primary : 'transparent')};
  border-radius: ${({ isChecked }) => (isChecked ? '14px' : '12px')};

  &:hover [data-child=${BUTTONS_CONTAINER}] {
    opacity: 1;
  }
`;

const StyledCard = styled(Card)`
  border-radius: 12px;
`;

const DetailsContainer = styled(Gap16VerticalFlex)`
  padding: 0 16px 16px;
`;
