import React from 'react';
import styled, { css } from 'styled-components';
import { TextH1 } from 'shared/react/components/complex/Text';

function CountdownRecording({ countdownValue, isVideoRecording }) {
  return (
    <LayoutRoot isVideoRecording={isVideoRecording}>
      <Title>{countdownValue}</Title>
    </LayoutRoot>
  );
}

export default CountdownRecording;

const LayoutRoot = styled.div`
  z-index: 1000;
  ${({ isVideoRecording }) =>
    isVideoRecording
      ? css`
          grid-row: 1;
          grid-column: 1;
          display: grid;
          align-items: center;
          justify-items: center;
          background: #0000009e;
          font-family: sans-serif;
          pointer-events: none;
        `
      : css`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `}
`;

const Title = styled(TextH1)`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  font-size: 330px;
`;
