import { useEffect, useRef } from 'react';

import useTracker from 'shared/react/components/complex/context/hooks/useTracker';
import Utils from 'shared/react/utils/utils';

const getIsShopifyPdp = () => {
  const parentUrl = Utils.getParentUrl();

  return ['products', 'pages'].some(path => parentUrl?.includes(path));
};

const useFeedSearchTracking = ({ videos, trendingVideos, searchTerm }) => {
  const [, { track }] = useTracker();
  const videosRef = useRef(videos);
  const searchTermRef = useRef(searchTerm);
  const isPdp = getIsShopifyPdp();

  useEffect(() => {
    videosRef.current = videos;
    searchTermRef.current = searchTerm;
  }, [videos, searchTerm]);

  const trackSearchModalClosed = () => {
    const trackData = {
      searchText: searchTermRef.current,
      videosShownCount: videosRef.current?.length,
      highestConfidenceScore: videosRef.current?.[0]?.score,
      isPdp,
    };

    track('feedSearchModalClosed', {
      formData: JSON.stringify(trackData),
    });
  };

  const trackVideoClick = ({ id, videoUrl, score, index, feedType }) => {
    const trackData = {
      feedType,
      searchText: searchTerm,
      videosShownCount: videos?.length,
      trendingVideosShownCount: trendingVideos?.length,
      placementOfVideoClicked: index + 1,
      videoId: id,
      videoUrl,
      score,
      isPdp,
    };

    track('feedSearchModalVideoClick', {
      formData: JSON.stringify(trackData),
    });
  };

  return {
    trackVideoClick,
    trackSearchModalClosed,
  };
};

export default useFeedSearchTracking;
