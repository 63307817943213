import { useMemo } from 'react';
import { useSessions } from 'app/src/context/SessionsStore';
import { useUser } from 'app/src/context/userStore/UserStore';
import {
  CREATED_BY_ME_PROJECT_FILTER,
  CREATED_BY_TEAMMATE_PROJECT_FILTER,
  FOLDER_FILTER,
  LIVE_PROJECT_FILTER,
  MACRO_PROJECT_FILTER,
  PRIVATE_PROJECT_FILTER,
  PUBLISH_METHOD_FILTER,
} from 'app/src/constants/filters.constants';
import { useDashboard } from 'app/src/context/ui_store/DashboardStore';

const filterBySearchValue = (projects, searchValue) => {
  return projects.filter(project => {
    return project.name.toLowerCase().includes(searchValue.toLowerCase());
  });
};

const filterMinusOne = index => {
  return index !== -1 ? index : 10000;
};

const compareFoldersOrder = (firstIndex, secondIndex) => {
  return filterMinusOne(filterMinusOne(firstIndex)) - filterMinusOne(secondIndex);
};

const isFolderExists = (folders, folderId) => {
  return folders.find(({ id }) => id === folderId);
};

const createFoldersMapping = (folders, projects, noFolders) => {
  const projectsWithoutFolders = [];
  const folderMapping = {};
  const foldersOrder = [];

  for (const project of projects) {
    if (!project.folder || !isFolderExists(folders, project.folder)) {
      projectsWithoutFolders.push(project);
      continue;
    }

    if (!folderMapping[project.folder]) {
      foldersOrder.push(project.folder);
      folderMapping[project.folder] = [project];
      continue;
    }

    folderMapping[project.folder].push(project);
  }

  folders.sort((a, b) =>
    compareFoldersOrder(foldersOrder.indexOf(a.id), foldersOrder.indexOf(b.id))
  );

  return {
    folderMapping,
    projectsWithoutFolders: noFolders ? projects : projectsWithoutFolders,
    folders,
  };
};

const filterFunctions = {
  [CREATED_BY_ME_PROJECT_FILTER]: ({ project, currentUser }) => project.owner === currentUser,
  [PRIVATE_PROJECT_FILTER]: ({ project }) => project.private,
  [MACRO_PROJECT_FILTER]: ({ project }) => project.macro,
  [LIVE_PROJECT_FILTER]: ({ project, isProjectLive }) => isProjectLive(project),
  [CREATED_BY_TEAMMATE_PROJECT_FILTER]: ({ project, selectedTeamMemberId }) =>
    project.owner === selectedTeamMemberId,
  [FOLDER_FILTER]: ({ selectedFolderId, project }) => project.folder === selectedFolderId,
  [PUBLISH_METHOD_FILTER]: ({ selectedPublishMethod, project }) =>
    project.publishMethod === selectedPublishMethod,
};

const getActiveFilterKeys = filters => {
  const activeFilters = Object.entries(filters).flatMap(([key, active]) => {
    if (!active) {
      return [];
    }

    return key;
  });

  return activeFilters;
};

const isQualifiedProject = ({
  project,
  filters,
  currentUser,
  isProjectLive,
  selectedTeamMemberId,
  selectedFolderId,
  selectedPublishMethod,
}) => {
  return filters.every(key =>
    filterFunctions[key]({
      project,
      filters,
      currentUser,
      isProjectLive,
      selectedTeamMemberId,
      selectedFolderId,
      selectedPublishMethod,
    })
  );
};

const applyFilters = (
  projects,
  filters,
  currentUser,
  isProjectLive,
  selectedTeamMemberId,
  selectedFolderId,
  selectedPublishMethod
) => {
  const activeFilterKeys = getActiveFilterKeys(filters);
  if (!activeFilterKeys.length) {
    return projects;
  }

  return projects.flatMap(project => {
    if (
      isQualifiedProject({
        project,
        filters: activeFilterKeys,
        currentUser,
        isProjectLive,
        selectedTeamMemberId,
        selectedFolderId,
        selectedPublishMethod,
      })
    ) {
      return project;
    }

    return [];
  });
};

const getProjectsByFolder = (projects, selectedFolderId) => {
  return projects.filter(project => project.folder === selectedFolderId);
};

const useProjectFilter = (
  folders,
  projects,
  searchValue,
  sortBy,
  ascending,
  filters = false,
  selectedFolderId,
  noFolders,
  selectedPublishMethod
) => {
  const [, { getProjectTotalResponses, getProjectUnreadResponses }] = useSessions();
  const [{ user = {} }, { isProjectLive }] = useUser();
  const [{ selectedTeamMember }] = useDashboard();

  return useMemo(() => {
    if (!user) {
      return {};
    }

    const { owner: currentUser } = user;
    const { owner: selectedTeamMemberId } = selectedTeamMember || {};

    const responsesSortHelpers = {
      totalResponses: getProjectTotalResponses,
      totalUnreadResponses: getProjectUnreadResponses,
    };

    if (selectedFolderId) {
      projects = getProjectsByFolder(projects, selectedFolderId);
    }

    projects = applyFilters(
      projects,
      filters,
      currentUser,
      isProjectLive,
      selectedTeamMemberId,
      selectedFolderId,
      selectedPublishMethod
    );

    if (searchValue) {
      projects = filterBySearchValue(projects, searchValue);
    }

    if (sortBy) {
      sortProjects(projects, sortBy, ascending, responsesSortHelpers);
    }

    if (!folders) {
      return { folders, folderMapping: {}, projectsWithoutFolders: projects };
    }

    return createFoldersMapping(folders, projects, noFolders);
  }, [projects, folders, searchValue, sortBy, ascending, filters]);
};

const sortProjects = (projects, sortBy, ascending, responsesSortHelpers) => {
  if (sortBy === 'totalResponses' || sortBy === 'totalUnreadResponses') {
    const responsesSortHelper = responsesSortHelpers[sortBy];
    projects.sort((a, b) => {
      if (ascending) {
        return responsesSortHelper(a.id) - responsesSortHelper(b.id);
      }

      return responsesSortHelper(b.id) - responsesSortHelper(a.id);
    });
    return;
  }

  projects.sort((a, b) => {
    if (ascending) {
      return new Date(a[sortBy]) - new Date(b[sortBy]);
    }

    return new Date(b[sortBy]) - new Date(a[sortBy]);
  });
};

export default useProjectFilter;
