import React from 'react';

const ConfettiIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g
        clipPath="url(#confetti)"
        stroke="#C4B5FD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="m4.833 9.417-3.166 8.916 8.916-3.158M3.333 2.5h.009m14.991 4.167h.009m-5.842-5h.008m5.825 15h.009m-.009-15-1.866.625a2.42 2.42 0 0 0-1.634 2.6 1.218 1.218 0 0 1-1.208 1.358h-.317c-.716 0-1.333.5-1.466 1.2l-.175.883m6.666 2.5-.683-.275a1.223 1.223 0 0 0-1.65.925 1.21 1.21 0 0 1-1.192 1.017h-.641m-5-10.833.275.683A1.223 1.223 0 0 1 8.517 4c-.584.083-1.017.6-1.017 1.192v.641" />
        <path d="M9.167 10.833c1.608 1.609 2.358 3.475 1.666 4.167-.691.692-2.558-.058-4.166-1.667S4.308 9.858 5 9.167s2.558.058 4.167 1.666" />
      </g>
      <defs>
        <clipPath id="confetti">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ConfettiIcon;
