import Utils from 'app/src/utils';
import { getDailyAveragesData } from 'app/src/services/cube/getDailyAveragesData.cube';
import {
  GetConversionOrdersAovMetricsProps,
  ResolveDailyDataProps,
} from 'app/src/services/cube/types/getConversionOrdersAovMetrics.types';
import { ConversionMetric } from './types/cube-common.types';
import { CUBE_PROPERTY_KEYS } from 'app/src/constants/cube.constants';

const getIsInteractedKey = (key: string) => key.includes(CUBE_PROPERTY_KEYS.INTERACTED);

const seperateObjectByInteracted = (ordersAovOvertime: ConversionMetric) => {
  const interactedKeys = {};
  const nonInteractedKeys = {};

  for (const key in ordersAovOvertime) {
    if (getIsInteractedKey(key)) {
      interactedKeys[key] = ordersAovOvertime[key];
      continue;
    }

    nonInteractedKeys[key] = ordersAovOvertime[key];
  }

  return {
    interactedKeys,
    nonInteractedKeys,
  };
};

const resolveDailyData = ({ ordersAov, currency, rates }: ResolveDailyDataProps) => {
  const { interactedKeys, nonInteractedKeys } = seperateObjectByInteracted(ordersAov);

  const { dailyAveragePrice: tolstoyDailyAveragePrice, dailyTotalCount: tolstoyDailyTotalCount } =
    getDailyAveragesData({
      conversions: interactedKeys,
      currency,
      rates,
    });

  const { dailyAveragePrice: baselineDailyAveragePrice, dailyTotalCount: baselineDailyTotalCount } =
    getDailyAveragesData({
      conversions: nonInteractedKeys,
      currency,
      rates,
    });

  return {
    tolstoyDailyAveragePrice,
    tolstoyDailyTotalCount,
    baselineDailyAveragePrice,
    baselineDailyTotalCount,
  };
};

export const getConversionOrdersAovMetrics = ({
  ordersAovOvertime,
  rates,
  currency,
}: GetConversionOrdersAovMetricsProps) => {
  return ordersAovOvertime.map(({ x, xValues, ...ordersAov }) => {
    const {
      tolstoyDailyAveragePrice,
      tolstoyDailyTotalCount,
      baselineDailyAveragePrice,
      baselineDailyTotalCount,
    } = resolveDailyData({
      ordersAov,
      currency,
      rates,
    });

    const tolstoyDailyAveragePriceWithCurrency = Utils.addCurrencySymbolToNumber(
      tolstoyDailyAveragePrice,
      currency,
      2
    );

    const baselineDailyAveragePriceWithCurrency = Utils.addCurrencySymbolToNumber(
      baselineDailyAveragePrice,
      currency,
      2
    );

    return {
      x,
      xValues,
      tolstoyDailyAveragePrice,
      tolstoyDailyAveragePriceWithCurrency,
      tolstoyDailyTotalCount,
      baselineDailyAveragePrice,
      baselineDailyAveragePriceWithCurrency,
      baselineDailyTotalCount,
    };
  });
};
