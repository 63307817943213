import React, { FC } from 'react';
import styled from 'styled-components';
import InstallationCompletedButton from 'app/src/pages/project/pages/installation/components/shared/InstallationCompletedButton';
import SetLiveButton from 'app/src/pages/project/components/project-top-bar/components/set-live-button/SetLiveButton';
import useSetProjectLive from 'app/src/hooks/useSetProjectLive';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { project as Project } from 'app/src/types/entities';

type InstallSetLiveStepDescriptionProps = {
  isDisabled: boolean;
  project: Project;
};

const InstallSetLiveStepDescription: FC<InstallSetLiveStepDescriptionProps> = props => {
  const { project, isDisabled } = props;
  const { isLoading, toggleWidget } = useSetProjectLive(project);

  return (
    <LayoutRoot>
      <InstallationCompletedButton project={project} isDisabled={isDisabled} />
      <ButtonContainer>
        <SetLiveButton
          isLoading={isLoading}
          isLive={project.live}
          onSetLive={toggleWidget}
          onSetUnLive={toggleWidget}
          disabled={!project?.installationCompleted}
        />
      </ButtonContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)``;

const ButtonContainer = styled(HorizontalFlex)`
  justify-content: flex-end;
`;

export default InstallSetLiveStepDescription;
