import { useAppActions, useSelectedApp } from 'app/src/context/AppsStore';
import Utils from 'app/src/utils';

export const useCurrentStoreApp = () => {
  const [selectedApp] = useSelectedApp();
  const { getProductsCatalogApp } = useAppActions();
  const productsCatalogApp = getProductsCatalogApp();

  const isShopify = !!selectedApp;
  const app = isShopify ? selectedApp : productsCatalogApp;
  const appUrl = app?.appUrl || null;
  const appKey = app?.appKey || Utils.getAppKey() || null;

  return { app, appUrl, appKey };
};
