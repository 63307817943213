import React, { useState } from 'react';
import Button from 'shared/react/components/complex/Button';
import styled from 'styled-components';
import { useBilling } from 'app/src/hooks/useBillings';
import BillingPackageCurrentPackage from 'app/src/complex_components/billings/BillingPackagesV2/BillingPackageCurrentPackage';
import { TextBody } from 'shared/react/components/complex/Text';
import { BILLING_PACKAGE_TYPES } from 'app/src/constants/billings.constants';
import PaymentRoleModal from 'app/src/pages/modals/PaymentRoleModal';
import { useHistory } from 'react-router-dom';
import { track } from 'app/src/helpers/Tracker';
import { REQUEST_OWNER_TO_UPGRADE_PLAN_KEY } from 'app/src/constants/modals.constants';
import { useAccount } from 'app/src/context/AccountStore';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import Utils from 'app/src/utils';

const BillingPackageButton = ({ packageInfo }) => {
  const history = useHistory();
  const [, { setCurrentModal }] = useModal();
  const [{ isAccountOwner }] = useAccount();
  const { getPackageActionText, downgradeToFreePackage, currentPackage } = useBilling();
  const [packageId, setPackageId] = useState(null);
  const [loading, setLoading] = useState(false);
  const { id, name, type, color, ownerWarning } = packageInfo;

  const active = id === currentPackage?.id;

  const onClick = async () => {
    if (active) {
      return;
    }

    if (!isAccountOwner && ownerWarning) {
      setCurrentModal(REQUEST_OWNER_TO_UPGRADE_PLAN_KEY);
      track('Plan Click Non Owner Warning', {
        packageId: id,
        packageName: name,
      });
      return;
    }

    setLoading(true);

    trackPackageClicked(packageId);

    if ([BILLING_PACKAGE_TYPES.pro, BILLING_PACKAGE_TYPES.business].includes(type)) {
      setPackageId(id);
      setLoading(false);
      return;
    }

    if (type === BILLING_PACKAGE_TYPES.free) {
      await downgradeToFreePackage(history);
      setLoading(false);
      return;
    }

    Utils.openInNewTab('https://www.gotolstoy.com/contact-sales');

    setLoading(false);
  };

  const trackPackageClicked = () => {
    track('Plan Click', {
      packageId: id,
      packageName: name,
    });
  };

  return (
    <BillingPackageButtonContainer>
      <PaymentRoleModal onClose={() => setPackageId(null)} packageId={packageId} type={type} />
      <BillingPackageCurrentPackage active={active} />
      <StyledBillingPackageButton
        color={color}
        visible={!active}
        onClick={onClick}
        loading={loading}
      >
        <ButtonText> {getPackageActionText(id)} </ButtonText>
      </StyledBillingPackageButton>
    </BillingPackageButtonContainer>
  );
};

export default BillingPackageButton;

const BillingPackageButtonContainer = styled.div`
  width: 100%;
  padding-bottom: 16px;
`;

const StyledBillingPackageButton = styled(Button)`
  display: ${({ visible }) => (visible ? '' : 'none')};
  width: 100%;

  & > div {
    width: 100%;
    background-color: ${({ color }) => color};
  }
`;

const ButtonText = styled(TextBody)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.white};
`;
