export const EVENT_NAMES = {
  embedView: 'embedView',
  embedPlay: 'embedPlay',
  embedPause: 'embedPause',
  embedMute: 'embedMute',
  embedUnmute: 'embedUnmute',
  embedExpand: 'embedExpand',
  tolstoyModalClose: 'tolstoyModalClose',
  feedProductModalOpen: 'feedProductModalOpen',
  feedProductModalClose: 'feedProductModalClose',
  videoLoaded: 'videoLoaded',
  pageView: 'pageView',
  clickViewProduct: 'clickViewProduct',
  openProductPageClick: 'openProductPageClick',
  feedPlay: 'feedPlay',
  feedPause: 'feedPause',
  videoMuted: 'videoMuted',
  videoUnmuted: 'videoUnmuted',
  videoWatched: 'videoWatched',
  openShareLink: 'Open Share Link',
  feedNavigationArrowClick: 'feedNavigationArrowClick',
  feedScroll: 'feedScroll',
  sessionStart: 'sessionStart',
  abTestInitialized: 'abTestInitialized'
};
