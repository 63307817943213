export const EMBED_CAROUSEL_MOTION = {
  static: 'static',
  dynamic: 'dynamic',
  hoverOver: 'hoverOver'
};

export const CAROUSEL_TILE_NAME_LOCATION_TYPES = {
  under: 'under',
  over: 'over'
};

export const PUBLIC_CLASSES = {
  carousel: 'tolstoy-carousel',
  carouselContainer: 'tolstoy-carousel-container',
  title: 'tolstoy-carousel-title',
  videoCarouselContainer: 'tolstoy-video-carousel-container',
  previousButton: 'tolstoy-previous-button',
  nextButton: 'tolstoy-next-button',
  arrowsContainer: 'tolstoy-carousel-arrows-container',
  videosContainer: 'tolstoy-carousel-videos-container',
  videoContainer: 'tolstoy-carousel-video-container',
  video: 'tolstoy-carousel-video',
  image: 'tolstoy-carousel-image',
  dotsContainer: 'tolstoy-dots-container',
  dot: 'tolstoy-dot',
  playButtonContainer: 'tolstoy-play-button-container',
  controlsContainer: 'tolstoy-carousel-controls-container',
  expandButton: 'tolstoy-carousel-expand-button',
  muteButton: 'tolstoy-carousel-mute-button',
  playButton: 'tolstoy-carousel-play-button',
  tileContainer: 'tolstoy-carousel-tile-container',
  tile: 'tolstoy-carousel-tile',
  centerTile: 'tolstoy-carousel-center-tile',
  tileNameContainer: 'tolstoy-tile-name-container',
  tileNameText: 'tolstoy-carousel-tile-name-text',
  arrowButtonContainer: 'tolstoy-carousel-arrow-button-container',
  arrowButton: 'tolstoy-carousel-arrow-button',
  tileNameHeightPlaceholder: 'tolstoy-tile-name-height-placeholder',
  productTile: 'tolstoy-product-tile',
  productContent: 'tolstoy-product-content',
  productTileImage: 'tolstoy-product-tile-image',
  productTileTitle: 'tolstoy-product-tile-title',
  variantTitle: 'tolstoy-product-variant-tile-title',
  productTilePrice: 'tolstoy-product-tile-price',
  productTileButton: 'tolstoy-product-tile-button',
  productTileArrow: 'tolstoy-product-tile-arrow',
  productTileFallback: 'tolstoy-product-tile-fallback',
  descriptionTile: 'tolstoy-description-tile',
  descriptionTileContent: 'tolstoy-description-tile-content',
  descriptionTileButton: 'tolstoy-description-tile-button'
};

export const MAX_TILE_NUMBER = 6;
export const MIN_TILE_NUMBER = 2;
export const MIN_WIDTH_FOR_TILE = 80;
export const DEFAULT_DOT_OPACITY = 0.3;
export const TILE_NAME_HEIGHT = 80;
export const SPACING_HORIZONTAL = 12;
export const DEFAULT_TILE_HEIGHT = 416;

export const CAROUSEL_TYPE = {
  DEFAULT: 'default',
  SPOTLIGHT: 'spotlight'
};

export const SPOTLIGHT_CAROUSEL_CONTENT_TILE_TYPE = {
  product: 'product',
  videoName: 'videoName',
  none: 'none'
};

export const CAROUSEL_TITLE = {
  shoppableVideoCarousel: 'Shoppable Video Carousel',
  viewProduct: 'View Product'
};
