import React from 'react';
import { useTikTok } from 'app/src/context/TikTokStore';
import { useApps } from 'app/src/context/AppsStore';
import IntegrationsAccount from 'app/src/pages/creation_flow/right_section/integrations_common/IntegrationsAccount';
import { IMPORT_FROM_TIKTOK } from 'app/src/constants/creation_method.constants';

const TikTokAccount = () => {
  const [{ initialized, firstUserInfo }, { clearStore }] = useTikTok();
  const [{ tiktok: tiktokApp }] = useApps();

  return (
    <IntegrationsAccount
      initialized={initialized}
      userInfo={firstUserInfo}
      clearStore={clearStore}
      app={tiktokApp}
      integration={IMPORT_FROM_TIKTOK}
    />
  );
};

export default TikTokAccount;
