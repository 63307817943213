import Utils from 'shared/react/utils/utils';
import { URL_KEY } from 'shared/react/constants/generalKeys.constants';
import { RECHARGE_MESSAGING } from 'shared/react/constants/rechargeMessaging.constants';
import { TOLSTOY_MOVE_TO_URL } from 'shared/react/constants/messages.constants';

const handleUrlType = (value, isOpenInANewTab) => {
  if (!value) {
    return;
  }

  if (isOpenInANewTab) {
    return Utils.openInNewTab(value);
  }

  if (Utils.getIsLandingPage()) {
    return Utils.openInTheSameTab(value, true);
  }

  return Utils.postMessageToWindow({ name: TOLSTOY_MOVE_TO_URL, url: value });
};

const handleRechargeMessaging = async (value, eventType) => {
  return Utils.postMessageToWindow({ name: RECHARGE_MESSAGING, type: eventType, ...value });
};

export const getFeedActionButtonOnClick = ({ type, value, isOpenInANewTab, eventType }) => {
  switch (type) {
    case URL_KEY:
      return handleUrlType(value, isOpenInANewTab);
    case RECHARGE_MESSAGING:
      return handleRechargeMessaging(value, eventType);
    default:
      return null;
  }
};
