import React from 'react';
import { useTheme } from 'styled-components';

function Unassigned({ fill, ...props }) {
  const theme = useTheme();

  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill={fill || theme.colors.ghostLight} />
      <path
        d="M8 7.75C9.36719 7.75 10.5 6.63672 10.5 5.25C10.5 3.88281 9.36719 2.75 8 2.75C6.61328 2.75 5.5 3.88281 5.5 5.25C5.5 6.63672 6.61328 7.75 8 7.75ZM8.97656 8.6875H7.00391C5.12891 8.6875 3.625 10.2109 3.625 12.0859C3.625 12.457 3.91797 12.75 4.28906 12.75H11.6914C12.0625 12.75 12.375 12.457 12.375 12.0859C12.375 10.2109 10.8516 8.6875 8.97656 8.6875Z"
        fill="white"
      />
    </svg>
  );
}

export default Unassigned;
