import React from 'react';
import ComponentWithOverlayLoader from 'shared/react/components/basic/ComponentWithOverlayLoader';
import Spinner from 'src/basic_components/Spinner';
import styled from 'styled-components';
import {
  JourneyDrawerStepEventName,
  JourneyDrawerStepType,
} from './journey-drawer-step/types/JourneyDrawerStep.types';
import JourneyDrawerImpressionStep from './journey-drawer-step/JourneyDrawerImpressionStep';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import JourneyDrawerVideoStep from './journey-drawer-step/JourneyDrawerVideoStep';
import JourneyDrawerPurchaseStep from './journey-drawer-step/JourneyDrawerPurchaseStep';
import JourneyEmailOpenStep from './journey-drawer-step/JourneyEmailOpenStep';
import JourneyEmailClickStep from './journey-drawer-step/JourneyEmailClickStep';

type Props = {
  isLoading: boolean;
  journeySteps: JourneyDrawerStepType[];
};

const JourneyDrawerContent = ({ isLoading, journeySteps }: Props) => {
  return (
    <StyledComponentWithOverlayLoader
      isLoading={isLoading}
      shouldApplyLoadingOpacity
      customLoader={<Spinner />}
    >
      <LayoutRoot>
        {journeySteps.map(journeyStep => {
          const { id, eventName } = journeyStep;

          switch (eventName) {
            case JourneyDrawerStepEventName.impression:
              return <JourneyDrawerImpressionStep key={id} data={journeyStep} />;
            case JourneyDrawerStepEventName.video:
              return <JourneyDrawerVideoStep key={id} data={journeyStep} />;
            case JourneyDrawerStepEventName.purchase:
              return <JourneyDrawerPurchaseStep key={id} data={journeyStep} />;
            case JourneyDrawerStepEventName.openEmail:
              return <JourneyEmailOpenStep key={id} data={journeyStep} />;
            case JourneyDrawerStepEventName.emailClick:
              return <JourneyEmailClickStep key={id} data={journeyStep} />;
            default:
              null;
          }
        })}
      </LayoutRoot>
    </StyledComponentWithOverlayLoader>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)`
  padding: 8px 16px 16px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  word-break: break-word;
`;

const StyledComponentWithOverlayLoader = styled(ComponentWithOverlayLoader)`
  overflow: hidden;
`;

export default JourneyDrawerContent;
