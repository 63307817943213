import React from 'react';
import EditorItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorItem';
import ButtonGroupV2 from 'app/src/complex_components/button-group/ButtonGroupV2';

function EditorButtonGroupItem({ text, buttons, disabled, ...props }) {
  return (
    <EditorItem text={text} disabled={disabled} {...props}>
      <ButtonGroupV2 buttons={buttons} disabled={disabled} />
    </EditorItem>
  );
}

export default EditorButtonGroupItem;
