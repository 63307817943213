import React from 'react';
import styled from 'styled-components';
import TopBar from './TopBar';
import { TopBarProps } from './types/TopBar.types';

const StickyTopBar = (topBarProps: TopBarProps) => {
  return (
    <TopBarContainer>
      <TopBar {...topBarProps} />
    </TopBarContainer>
  );
};

const TopBarContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  border-left: 1px solid ${({ theme }) => theme.colors.neutralLighter};
`;

export default StickyTopBar;
