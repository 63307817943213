import React from 'react';
import styled from 'styled-components';
import ShoppingBag from 'app/src/images/ShoppingBag';
import { Gap16HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextSubtitle } from 'shared/react/components/complex/Text';
import useNavigation from 'src/hooks/useNavigation';
import { useAnalytics } from 'src/context/AnalyticsStore';
import { useApps } from 'src/context/AppsStore';

const AnalyticsOrdersEmptyStateBanner = () => {
  const [{ shopify: isShopify }] = useApps();
  const { navigateToAccountInstall } = useNavigation();
  const [{ conversionOrders }] = useAnalytics();

  if (isShopify || conversionOrders?.length) {
    return null;
  }

  return (
    <LayoutRoot>
      <ShoppingBag width={32} height={32} />
      <Content>
        <StyledTextSubtitle>{`We haven't received any orders yet.`}</StyledTextSubtitle>
        <StyledTextSubtitle>
          Start tracking your orders from Tolstoy by{' '}
          <UnderlinedText onClick={() => navigateToAccountInstall()}>
            placing the Conversion Code in your thank you page.
          </UnderlinedText>
        </StyledTextSubtitle>
      </Content>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16HorizontalFlex)`
  background: ${({ theme }) => theme.colors.primaryLight};
  padding: 24px;
  border-radius: 16px;
  align-items: center;
`;

const Content = styled(VerticalFlex)``;

const StyledTextSubtitle = styled(TextSubtitle)`
  color: ${({ theme }) => theme.colors.blue7};
`;

const UnderlinedText = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

export default AnalyticsOrdersEmptyStateBanner;
