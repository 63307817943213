import React from 'react';
import styled from 'styled-components';
import { TextTiny } from 'shared/react/components/complex/Text';
import ExportToCSVIcon from 'shared/react/images/ExportToCSVIcon';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

const ExportButton = ({ isLoading, onClick, isEnabled }) => {
  if (!isEnabled) {
    return null;
  }

  return (
    <LayoutRoot onClick={onClick} isLoading={isLoading}>
      <ExportToCSVIcon />
      <TextTiny>Export to csv</TextTiny>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)`
  position: absolute;
  gap: 4px;
  padding: 3px 8px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 40px;
  right: -20px;
  z-index: 10;
  cursor: pointer;
  pointer-events: ${({ isLoading }) => `${isLoading ? 'none' : ''}`};
  filter: drop-shadow(0px 10px 25px rgba(50, 50, 93, 0.1))
    drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.05));
`;

export default ExportButton;
