import React from 'react';

function TikTokIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="15" fill="none" viewBox="0 0 13 15">
      <path
        fill="#000"
        d="M11.382 3.208c-.102-.052-.2-.11-.296-.172a4.148 4.148 0 01-.758-.644A3.577 3.577 0 019.473.628h.003C9.411.247 9.438 0 9.442 0H6.866v9.961c0 .134 0 .266-.005.397l-.003.049-.001.022v.005a2.187 2.187 0 01-1.101 1.737 2.15 2.15 0 01-1.066.28 2.18 2.18 0 01-2.173-2.187A2.18 2.18 0 014.69 8.078c.227 0 .453.036.67.106l.002-2.623a4.785 4.785 0 00-3.687 1.079 5.056 5.056 0 00-1.103 1.36c-.109.187-.52.94-.569 2.164-.031.694.177 1.413.277 1.71v.006c.062.175.304.772.699 1.276.318.404.694.758 1.116 1.052v-.006l.006.006c1.247.848 2.63.792 2.63.792.24-.01 1.041 0 1.952-.431 1.01-.479 1.585-1.191 1.585-1.191.367-.426.66-.912.863-1.436.234-.613.311-1.348.311-1.641V5.015c.032.019.448.294.448.294s.6.384 1.535.635c.671.178 1.576.215 1.576.215V3.602c-.317.034-.96-.066-1.62-.394z"
      />
    </svg>
  );
}

export default TikTokIcon;
