import React, { useState } from 'react';
import styled from 'styled-components';

import { TextH6 } from 'shared/react/components/complex/Text';
import { VerticalFlexCentered } from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const CaptionsVideoPreview = ({ poster, src, subtitlesBlob }) => {
  const [errorLoading, setErrorLoading] = useState(false);

  const handleVideoLoadError = () => {
    setErrorLoading(true);
  };

  const handleCanPlay = e => {
    return e.target.play();
  };

  if (errorLoading) {
    return (
      <ErrorContainer>
        <TextH6White>Error Loading Video</TextH6White>
      </ErrorContainer>
    );
  }

  return (
    <VideoContainer
      loop
      controls
      muted
      autoPlay
      disablePictureInPicture
      src={src}
      poster={poster}
      playsInline
      onLoadedMetadata={handleCanPlay}
      onError={handleVideoLoadError}
    >
      {subtitlesBlob && <track kind="subtitles" default src={URL.createObjectURL(subtitlesBlob)} />}
    </VideoContainer>
  );
};

const VideoContainer = styled.video`
  width: 300px;
  height: 533px;
  object-fit: contain;
  background: ${({ theme }) => theme.colors.black};
`;

const ErrorContainer = styled(VerticalFlexCentered)`
  width: 300px;
  height: 533px;
  background: ${({ theme }) => theme.colors.black};
`;

const TextH6White = styled(TextH6)`
  color: ${({ theme }) => theme.colors.white};
`;

export default CaptionsVideoPreview;
