import React from 'react';

import { app as AppEntity } from 'app/src/types/entities';
import ShopifyAppInstallationSteps from './ShopifyInstallationSteps';
import DefaultAppInstallationSteps from './DefaultInstallationSteps';

type Props = {
  app?: AppEntity;
  startExpanded?: boolean;
  isShopify?: boolean;
};

const AppInstallationSteps = ({ app, startExpanded, isShopify }: Props) => {
  if (isShopify) {
    return <ShopifyAppInstallationSteps app={app} startExpanded={startExpanded} />;
  }

  return <DefaultAppInstallationSteps app={app} startExpanded={startExpanded} />;
};

export default AppInstallationSteps;
