import SearchProducts from 'app/src/complex_components/search-products/SearchProducts';
import { app, vodAsset } from 'app/src/types/entities';
import React from 'react';
import { TextSubtitle } from 'shared/react/components/complex/Text';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { AnalyticsDataProps } from 'src/types/common/common';
import styled from 'styled-components';
import { useSelectedProductsContext } from 'app/src/complex_components/global_modal/video/add_products_modal/SelectedProductsContext';

type Props = {
  selectedApp: app;
  analyticsData?: AnalyticsDataProps;
  video: vodAsset;
  setIsSelectProductPopupOpened: (isMenuOpen: boolean) => void;
};

const AddProductsModalTopContainer = ({
  selectedApp,
  analyticsData,
  video,
  setIsSelectProductPopupOpened,
}: Props) => {
  const [
    { selectedProducts, selectedTags, isSaving },
    { toggleTag, addProduct, setSelectedProducts },
  ] = useSelectedProductsContext();
  return (
    <SearchContainer>
      <SearchHeaderContainer>
        <Header>Tag products</Header>
      </SearchHeaderContainer>
      <SearchProducts
        selectedProducts={selectedProducts}
        onProductSelected={addProduct}
        selectedApp={selectedApp}
        location="Add Products Modal"
        onHasNoPermissions={undefined}
        getProductComponent={undefined}
        isLoading={undefined}
        isSaving={isSaving}
        analyticsData={analyticsData}
        setSelectedProducts={setSelectedProducts}
        onTagClick={toggleTag}
        selectedTags={selectedTags}
        autoFocus={true}
        connections={video?.vodConnections?.items}
        setIsSelectProductPopupOpened={setIsSelectProductPopupOpened}
      />
    </SearchContainer>
  );
};

const Header = styled(TextSubtitle)`
  font-weight: 500;
`;

const SearchContainer = styled(Gap8VerticalFlex)``;

const SearchHeaderContainer = styled(HorizontalFlex)`
  justify-content: space-between;
  align-items: flex-end;
`;

export default AddProductsModalTopContainer;
