const GRID_AREA_CONTROLS = 'controls';
const GRID_AREA_WATERMARK = 'watermark';
const GRID_AREA_OVERLAY_TEXT = 'overlay-text';
const GRID_AREA_SUBTITLES = 'subtitles';
const GRID_AREA_PRODUCTS = 'products';
const GRID_AREA_ANSWERS_SECTION = 'answers-section';

function getGridAreaDefinitions(isProducts, isVertical, hasSubtitles, isPanelExpanded) {
  if (isVertical) {
    return isProducts
      ? [
          GRID_AREA_CONTROLS,
          GRID_AREA_OVERLAY_TEXT,
          GRID_AREA_SUBTITLES,
          GRID_AREA_WATERMARK,
          GRID_AREA_PRODUCTS,
        ]
      : [
          GRID_AREA_CONTROLS,
          GRID_AREA_SUBTITLES,
          GRID_AREA_OVERLAY_TEXT,
          GRID_AREA_ANSWERS_SECTION,
          GRID_AREA_WATERMARK,
        ];
  }

  if (!isProducts) {
    return [
      GRID_AREA_CONTROLS,
      GRID_AREA_SUBTITLES,
      GRID_AREA_OVERLAY_TEXT,
      GRID_AREA_ANSWERS_SECTION,
      GRID_AREA_WATERMARK,
    ];
  }

  const subtitlesWithProducts = hasSubtitles
    ? `${GRID_AREA_SUBTITLES} ${GRID_AREA_PRODUCTS}`
    : `. ${GRID_AREA_PRODUCTS}`;

  return isPanelExpanded
    ? [
        `${GRID_AREA_CONTROLS} ${GRID_AREA_PRODUCTS}`,
        `${GRID_AREA_OVERLAY_TEXT} ${GRID_AREA_PRODUCTS}`,
        `${GRID_AREA_WATERMARK} ${GRID_AREA_PRODUCTS}`,
        subtitlesWithProducts,
      ]
    : [
        `${GRID_AREA_CONTROLS} ${GRID_AREA_CONTROLS}`,
        `${GRID_AREA_OVERLAY_TEXT} ${GRID_AREA_PRODUCTS}`,
        subtitlesWithProducts,
        `${GRID_AREA_WATERMARK} ${GRID_AREA_WATERMARK}`,
      ];
}

export const getGridTemplateAreas = (isProducts, isVertical, hasSubtitles, isPanelExpanded) => {
  return getGridAreaDefinitions(isProducts, isVertical, hasSubtitles, isPanelExpanded)
    .filter(area => hasSubtitles || area !== GRID_AREA_SUBTITLES)
    .map(area => `'${area}'`)
    .join(' ');
};
