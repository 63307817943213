import React from 'react';
import styled from 'styled-components';
import { SmallSuccessButton } from 'shared/react/components/basic/button/button/SuccessButton';
import PlusIcon from 'app/src/images/PlusIcon';

const AddButton = ({ children, ...props }) => {
  return (
    <AddNewButton {...props}>
      <PlusIcon />
      {children}
    </AddNewButton>
  );
};

const AddNewButton = styled(SmallSuccessButton)`
  gap: 8px;
`;

export default AddButton;
