import React from 'react';
import styled from 'styled-components';
import Types from 'shared/react/theme/Types';
import { PREVIEW_READY_MADE_NEXT_BUTTON_DATA_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import { TextSmall } from 'shared/react/components/complex/Text';
import Link from '@material-ui/core/Link';
import Button from 'shared/react/components/complex/Button';

const TemplateFooter = ({
  hasExplanation,
  selectedTemplate,
  isMobile,
  loadingState,
  handlePreviewOnSampleSite,
  LOADING_TYPES,
  handleGoNext,
  actionButtonText,
  reportPreviewOnLiveSite,
}) => {
  return (
    <Footer hasExplanation={hasExplanation}>
      {!selectedTemplate.hidePreviewButton && (
        <CustomizeButton
          type={Types.Ghost}
          fullWidth={!hasExplanation || isMobile}
          disabled={loadingState}
          onClick={handlePreviewOnSampleSite}
        >
          {selectedTemplate.guideButtonText || 'Preview on sample site'}
        </CustomizeButton>
      )}
      <ActionButton
        onClick={handleGoNext}
        disabled={loadingState}
        loading={loadingState === LOADING_TYPES.standard}
        fullWidth={true}
        data-test-id={PREVIEW_READY_MADE_NEXT_BUTTON_DATA_TEST_ID}
      >
        {actionButtonText}
      </ActionButton>
      {selectedTemplate.sampleSiteUrl && (
        <StyledSeeItLiveLinkContainer>
          <TextSmall fontWeight="700">
            See it live on{' '}
            <Link
              href={selectedTemplate.sampleSiteUrl}
              target="_blank"
              onClick={reportPreviewOnLiveSite}
            >
              {selectedTemplate.sampleSiteName}
            </Link>
          </TextSmall>
        </StyledSeeItLiveLinkContainer>
      )}
    </Footer>
  );
};

const Footer = styled.div`
  display: grid;
  grid-column: 1;
  grid-template-columns: 1fr auto;
  align-self: start;
  align-items: center;
  justify-items: stretch;
  grid-template-rows: auto;
  gap: 8px;
  width: ${({ hasExplanation }) => (hasExplanation ? 'fit-content' : '100%')};
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    align-self: center;
    grid-template-columns: auto;
    width: 100%;
    padding: 0 16px 16px 16px;
  }
`;

const ActionButton = styled(Button)`
  justify-self: center;
  min-width: 126px;
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    justify-self: stretch;
  }
`;
const CustomizeButton = styled(Button)`
  justify-self: center;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    justify-self: stretch;
  }
`;

const StyledSeeItLiveLinkContainer = styled.div`
  grid-column: 1;
  margin-top: 8px;
  margin-left: 5px;
`;

export default TemplateFooter;
