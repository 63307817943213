import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import ReplayIcon from '../../../assets/ReplayIcon';
import BackArrowIcon from '../../../assets/BackArrowIcon';
import InputRange from 'react-input-range';
import Duration from 'shared/react/components/basic/Duration';
import {
  AUDIO_RESPONSE_KEY,
  BUBBLE,
  FREE_TEXT_RESPONSE_KEY,
  SIDE_BAR,
  VIDEO_RESPONSE_KEY,
} from '../../../constants/tolstoy.constants';
import { track } from '../../../tracker/Tracker';
import ExpandIcon from '../../../assets/ExpandButton';
import MuteButton from 'shared/react/images/MuteButton';
import UnmuteButton from 'shared/react/images/UnmuteButton';
import Utils from '../../../utils';
import {
  BACK_BUTTON_DATA_TEST_ID,
  CHAPTERS_BUTTON_DATA_TEST_ID,
  CONTROLS_CONTAINER_DATA_TEST_ID,
  CONTROLS_HEADER_DATA_TEST_ID,
  DURATION_BAR_CONTAINER_DATA_TEST_ID,
} from '../../../constants/dataTestIds.constants';
import { IconButton } from '../../common/commonStyles';
import VideoSideBar from '../../../assets/images/sideBar/VideoSideBar';
import AudioSideBar from '../../../assets/images/sideBar/AudioSideBar';
import TextSideBar from '../../../assets/images/sideBar/TextSideBar';
import MenuIcon from '../../../assets/MenuIcon';
import {
  appKeysWithControlsHidden,
  projectsWithControlsHidden,
} from '../../../customData/customerSettings';

const getIsFullscreenEnabled = () => {
  try {
    return (
      document.fullscreenEnabled ||
      document.webkitFullscreenEnabled ||
      document.mozFullScreenEnabled ||
      document.msFullscreenEnabled
    );
  } catch {
    return false;
  }
};

const Controls = ({
  landingPage,
  showStartScreen,
  userPause,
  shouldShowThread,
  handlePlayPause,
  hasChapters,
  fastForwardEnabled,
  replay,
  played,
  setPlayed,
  getActiveVideoRefCurrent,
  videoTime,
  setVideoSpeed,
  videoSpeed,
  videoDuration,
  selectedVideo,
  previousVideoKeys,
  transitioningFromVideoId,
  hideBackButton,
  setChapterPickerOpen,
  pause,
  setNextStepType,
  collectInfo,
  setGoToAfterLeadFormStep,
  moveTo,
  isVertical,
  toggleFullscreen,
  toggleSafariFullscreen,
  playerType,
  userMuted,
  muteVideo,
  isPanelExpanded,
  disableFullscreen,
  isDefaultResponseEnabled,
  trackClick,
  shouldOpenLeadFormAfterStep,
  isFeedModeActive,
  isHovered,
  customLayout,
  projectId,
  setQuizAnswersKeys,
  quizAnswersKeys,
  setIsLoading,
  appKey,
  showEndScreen,
  setNextRef,
  onKeyDown,
  isInitialMute,
  showAutoplayUnmute,
  setShowAutoplayUnmute,
}) => {
  const shouldAlwaysShowControlsOnMobile =
    customLayout?.durationBar?.shouldAlwaysShowControlsOnMobile;
  const [showControlsHeader, setShowControlsHeader] = useState(false);
  const [showBackgroundOverlay, setShowBackgroundOverlay] = useState(false);
  const timeoutRef = useRef();
  const isMobile = Utils.isMobile() || Utils.isIphone();
  const isFullscreenEnabled =
    playerType !== BUBBLE && !disableFullscreen && getIsFullscreenEnabled() && !isMobile;
  const isAppPreview = Utils.getIsAppPreview();

  const openResponse = type => {
    track('sidebarClick', { type });
    pause();

    if (shouldOpenLeadFormAfterStep(false, collectInfo?.afterStep)) {
      setGoToAfterLeadFormStep(SIDE_BAR);
    }

    switch (type) {
      case AUDIO_RESPONSE_KEY:
        return setNextStepType(AUDIO_RESPONSE_KEY);
      case VIDEO_RESPONSE_KEY:
        return setNextStepType(VIDEO_RESPONSE_KEY);
      case FREE_TEXT_RESPONSE_KEY:
        return setNextStepType(FREE_TEXT_RESPONSE_KEY);
    }
  };

  function showChapterPicker() {
    if (transitioningFromVideoId) {
      return;
    }

    track('chapterPickerOpened');
    setChapterPickerOpen(true);
    pause();
  }

  function progressClick(value) {
    setPlayed(value);
    let videoRef = getActiveVideoRefCurrent();
    if (videoRef) {
      videoRef.currentTime = parseFloat(value) * videoDuration;
      setIsLoading(true);
    }
  }

  const progressClickComplete = () => {
    track('videoSeeked', { videoTime: videoTime() });
  };

  function goBack(e) {
    if (previousVideoKeys && hasBackButton && !transitioningFromVideoId) {
      const key = previousVideoKeys[previousVideoKeys.length - 1];
      trackClick('Tolstoy Back', key);
      const newQuizAnswers = [...quizAnswersKeys];
      newQuizAnswers.pop();
      setQuizAnswersKeys(newQuizAnswers);

      moveTo(key, e.target, true);
    }
  }

  const getSideBarButtons = () => {
    if (!selectedVideo.sideBarButtonsEnabled || isDefaultResponseEnabled || isFeedModeActive) {
      return [];
    }
    return [
      { type: VIDEO_RESPONSE_KEY, icon: <VideoSideBar /> },
      { type: AUDIO_RESPONSE_KEY, icon: <AudioSideBar /> },
      { type: FREE_TEXT_RESPONSE_KEY, icon: <TextSideBar /> },
    ];
  };

  const changeVideoSpeed = () => {
    switch (videoSpeed) {
      case '1':
        setVideoSpeed('1.2');
        break;
      case '1.2':
        setVideoSpeed('1.5');
        break;
      case '1.5':
        setVideoSpeed('2');
        break;
      case '2':
        setVideoSpeed('1');
        break;
    }
  };

  const hasBackButton = previousVideoKeys.length > 0 && !hideBackButton;

  const getGridColumnsSize = () => {
    const configRight = [fastForwardEnabled, hasChapters, isFullscreenEnabled];
    let gridColumns = 'auto 1fr auto';

    if (hasBackButton) {
      gridColumns = 'auto ' + gridColumns;
    }

    configRight.forEach(config => {
      if (config) {
        gridColumns += ' auto';
      }
    });

    return gridColumns;
  };

  const handleVideoMute = () => {
    muteVideo(!userMuted);
  };

  const handlePlayerClicked = e => {
    if (isInitialMute || showAutoplayUnmute) {
      handlePlayPause(e);
      setShowAutoplayUnmute(false);
      return;
    }

    setShowControlsHeader(true);
    clearTimeout(timeoutRef.current);
    if (!Utils.isMobile()) {
      handlePlayPause(e);
      return;
    }

    if (showControlsHeader) {
      handlePlayPause(e);
    }

    timeoutRef.current = setTimeout(() => {
      setShowControlsHeader(false);
    }, 4000);
  };

  const toggleBackgroundOverlay = () => {
    setShowBackgroundOverlay(!showBackgroundOverlay);
  };

  const fullscreenFunction =
    Utils.isSafari() || Utils.isIphone() ? toggleSafariFullscreen : toggleFullscreen;
  const muteButton = userMuted ? <MuteButton /> : <UnmuteButton />;

  useEffect(() => {
    if (isHovered !== showControlsHeader || Utils.isMobile()) {
      return;
    }

    setShowControlsHeader(isHovered);
  }, [isHovered, showControlsHeader]);

  const shouldShowControls = isAppPreview ? true : !showStartScreen && isHovered;
  const shouldShowBackgroundOverlay = isAppPreview ? true : showBackgroundOverlay || isHovered;

  return (
    <LayoutRoot
      landingPage={landingPage}
      showStartScreen={showStartScreen}
      userPause={userPause}
      shouldShowThread={shouldShowThread}
      isVertical={isVertical}
      isMobile={isMobile}
      data-test-id={CONTROLS_CONTAINER_DATA_TEST_ID}
      aria-label="Video controls"
      role="group"
      aria-hidden={showStartScreen || showEndScreen}
    >
      <ControlsHeader
        onClick={handlePlayerClicked}
        showControlsHeader={showControlsHeader}
        shouldAlwaysShowControlsOnMobile={!showStartScreen && shouldAlwaysShowControlsOnMobile}
        isMobile={isMobile}
        data-test-id={CONTROLS_HEADER_DATA_TEST_ID}
        isProductsExpanded={isPanelExpanded}
        isVertical={isVertical}
        isHovered={shouldShowControls}
      >
        <ControlsContainer
          hasBackButton={hasBackButton}
          hasChapters={hasChapters}
          showControlsHeader={
            isMobile ? shouldAlwaysShowControlsOnMobile || showControlsHeader : true
          }
          hasVideoSpeed={fastForwardEnabled}
          onClick={e => e.stopPropagation()}
          getGridColumnsSize={getGridColumnsSize}
          isProductsExpanded={isPanelExpanded}
          isVertical={isVertical}
          onMouseEnter={toggleBackgroundOverlay}
          onMouseLeave={toggleBackgroundOverlay}
          shouldHide={
            projectsWithControlsHidden.includes(projectId) ||
            appKeysWithControlsHidden.includes(appKey)
          }
        >
          {hasBackButton && (
            <PlayerTopButtonContainer
              aria-label="Go back"
              role="button"
              onClick={goBack}
              data-test-id={BACK_BUTTON_DATA_TEST_ID}
              tabIndex="0"
              onKeyDown={onKeyDown}
              ref={setNextRef}
            >
              <BackArrowIcon />
            </PlayerTopButtonContainer>
          )}
          <ReplayButtonContainer
            aria-label="Replay video"
            role="button"
            onClick={replay}
            tabIndex="0"
            onKeyDown={onKeyDown}
            ref={setNextRef}
          >
            <ReplayIcon />
          </ReplayButtonContainer>
          <DurationBarContainer
            data-test-id={DURATION_BAR_CONTAINER_DATA_TEST_ID}
            isMobile={isMobile}
            durationBarSettings={customLayout.durationBar}
          >
            <InputRange
              maxValue={1}
              minValue={0}
              step={0.001}
              value={played || 0}
              aria-valuenow={played || 0}
              aria-valuemin={0}
              aria-valuemax={1}
              aria-label="Video Time"
              onChange={progressClick}
              onChangeComplete={progressClickComplete}
            />
            <DurationTextContainer>
              <Duration seconds={played * videoDuration} />
              {' / '}
              <Duration seconds={videoDuration} />
            </DurationTextContainer>
          </DurationBarContainer>
          <PlayerTopButtonContainer
            aria-label={userMuted ? 'Unmute' : 'Mute'}
            role="button"
            onClick={handleVideoMute}
            onKeyDown={onKeyDown}
            tabIndex="0"
            ref={setNextRef}
          >
            {muteButton}
          </PlayerTopButtonContainer>
          {isFullscreenEnabled && (
            <PlayerTopButtonContainer
              aria-label="Full screen"
              role="button"
              onClick={fullscreenFunction}
              tabIndex="0"
              onKeyDown={onKeyDown}
              ref={setNextRef}
            >
              <ExpandIcon />
            </PlayerTopButtonContainer>
          )}
          {fastForwardEnabled && (
            <VideoSpeedContainer
              role="button"
              aria-label="Video speed"
              onClick={changeVideoSpeed}
              tabIndex="0"
              onKeyDown={onKeyDown}
              ref={setNextRef}
            >
              {videoSpeed}x
            </VideoSpeedContainer>
          )}
          {hasChapters && (
            <PlayerTopButtonContainer
              onClick={showChapterPicker}
              role="button"
              aria-label="Choose chapter"
              data-test-id={CHAPTERS_BUTTON_DATA_TEST_ID}
              tabIndex="0"
              onKeyDown={onKeyDown}
              ref={setNextRef}
            >
              <MenuIcon />
            </PlayerTopButtonContainer>
          )}
          <SideBarButtons>
            {getSideBarButtons().map(({ icon, type }) => (
              <SideBarButton
                tabIndex="0"
                key={type}
                role="button"
                onClick={() => openResponse(type)}
                aria-label={`${type} Response`}
                onKeyDown={onKeyDown}
                ref={setNextRef}
              >
                {icon}
              </SideBarButton>
            ))}
          </SideBarButtons>
        </ControlsContainer>
        <BackgroundOverlay
          showBackgroundOverlay={shouldShowBackgroundOverlay}
          showControlsHeader={shouldAlwaysShowControlsOnMobile || showControlsHeader}
          isMobile={isMobile}
        />
      </ControlsHeader>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  max-height: ${({ maxHeight, isVertical, isMobile }) => (isVertical || isMobile) && maxHeight};
  transition: 0.3s;
  grid-area: controls;
  direction: ltr;

  ${({ landingPage }) => (landingPage ? 'position: static;' : '')};
  ${({ showStartScreen, userPause }) => (!showStartScreen || userPause ? 'z-index: 10;' : '')};
  ${({ shouldShowThread }) => (shouldShowThread ? 'position: absolute;' : '')};

  @media screen and (orientation: landscape) and (max-height: 450px) {
    max-width: ${({ maxWidth, isVertical }) => (isVertical ? '100%' : maxWidth)};
    max-height: 100%;
  }
`;

const ControlsContainer = styled.div`
  height: 40px;
  padding: 16px 24px;
  width: 100%;
  display: ${({ shouldHide }) => (shouldHide ? 'none' : 'grid')};
  grid-template-columns: ${({ getGridColumnsSize }) => getGridColumnsSize()};
  column-gap: 16px;
  align-items: center;
  z-index: 30;
  max-width: ${({ maxWidth, isVertical, isProductsExpanded }) =>
    !isVertical && isProductsExpanded && maxWidth};
  pointer-events: ${({ showControlsHeader }) => (showControlsHeader ? '' : 'none')};

  @media only screen and (max-width: 450px), screen and (max-height: 645px) {
    border-radius: 0;
  }

  @media (max-width: 450px) {
    padding-right: 24px;
  }
`;

const PlayerTopButtonContainer = styled(IconButton)`
  cursor: pointer;
`;

const VideoSpeedContainer = styled(PlayerTopButtonContainer)`
  padding: 10px;
  background: rgba(255, 255, 255, 0.5);
  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  font-size: 14px;
  min-width: 27px;
  border-radius: 8px;
`;

const ReplayButtonContainer = styled(PlayerTopButtonContainer)`
  width: 20px;
  height: 17px;
`;

const DurationBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 12px;

  & .input-range__slider {
    display: ${({ isMobile }) => (isMobile ? 'initial' : 'none')};
  }

  &:hover .input-range__slider {
    display: initial;
  }

  & .input-range__track--active {
    background: ${({ durationBarSettings, theme }) =>
      durationBarSettings?.color ? durationBarSettings.color : theme.colors.white};
  }

  &:hover .input-range__slider {
    display: initial;
    background: ${({ durationBarSettings, theme }) =>
      durationBarSettings?.color ? durationBarSettings.color : theme.colors.white};
    height: 0.7rem;
    width: 0.8rem;
    margin-top: -0.5rem;
    margin-left: -0.5rem;
  }
`;

const DurationTextContainer = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
  line-height: 14px;
  align-self: flex-end;
`;

const ControlsHeader = styled.div`
  height: 100%;
  min-height: 30px;
  display: flex;
  justify-content: ${({ isProductsExpanded, isVertical }) =>
    isProductsExpanded && !isVertical ? 'flex-start' : 'center'};
  z-index: 41;
  transition: 0.3s;
  opacity: 0;

  ${({ showControlsHeader, isMobile, isHovered, shouldAlwaysShowControlsOnMobile }) => {
    if (!isMobile) {
      if (isHovered) {
        return 'opacity: 1';
      }

      return css`
        &:hover {
          opacity: 1;
        }

        &:focus-within {
          opacity: 1;
        }
      `;
    }

    if (shouldAlwaysShowControlsOnMobile) {
      return 'opacity:1';
    }

    if (showControlsHeader) {
      return 'opacity: 1';
    }
  }}
`;

const SideBarButtons = styled.div`
  display: grid;
  margin-top: 12px;
  gap: 6px;
  grid-row: 2;
  grid-column-end: -1;
`;

const SideBarButton = styled.div`
  cursor: pointer;
  transition: 0.3s;

  &:focus-visible ${ControlsHeader} {
    opacity: 1;
  }

  &:hover {
    transform: scale(1.1);
  }
`;

const BackgroundOverlay = styled.div`
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.4)),
    to(transparent),
    color-stop(0.18, transparent)
  );
  transition: 0.3s;
  z-index: 10;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: ${({ showBackgroundOverlay, showControlsHeader, isMobile }) =>
    showBackgroundOverlay || (showControlsHeader && isMobile) ? 1 : 0};

  &:hover {
    opacity: 1;
  }
`;

export default Controls;
