import React from 'react';
import Cases from 'app/src/pages/dashboard/components/cases/Cases';
import { CASE_TYPES_ONSITE } from 'app/src/pages/dashboard/components/cases/cases.constants';
import OnsiteArchiveEmptyState from './OnsiteArchiveEmptyState';

type Props = {
  isArchiveFolder?: boolean;
};

const OnSiteEmptyState = ({ isArchiveFolder }: Props) => {
  if (isArchiveFolder) {
    return <OnsiteArchiveEmptyState />;
  }

  return <Cases types={CASE_TYPES_ONSITE} isEmptyState />;
};

export default OnSiteEmptyState;
