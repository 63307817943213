import React from 'react';

const Hazard = () => {
  return (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.7656 16.7891L12.4062 2.57031C11.7812 1.47656 10.1797 1.47656 9.51562 2.57031L1.19531 16.7891C0.570312 17.8828 1.35156 19.25 2.64062 19.25H19.3203C20.6094 19.25 21.3906 17.8828 20.7656 16.7891ZM10.0625 7.0625C10.0625 6.55469 10.4531 6.125 11 6.125C11.5078 6.125 11.9375 6.55469 11.9375 7.0625V12.0625C11.9375 12.6094 11.5078 13 11 13C10.4531 13 10.0625 12.6094 10.0625 12.0625V7.0625ZM11 16.75C10.2969 16.75 9.75 16.2031 9.75 15.5391C9.75 14.875 10.2969 14.3281 11 14.3281C11.6641 14.3281 12.2109 14.875 12.2109 15.5391C12.2109 16.2031 11.6641 16.75 11 16.75Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default Hazard;
