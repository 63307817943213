import React from 'react';

const FilledVideoIcon = ({ fill = '#01BE81', size = 17 }) => {
  return (
    <svg
      width={size}
      height={size - 4}
      viewBox="0 0 17 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.313 3.348a1.4 1.4 0 0 0 0-2.004c-.563-.528-1.442-.528-1.97 0L6.329 9.359 2.812 5.844c-.562-.528-1.44-.528-1.968 0a1.4 1.4 0 0 0 0 2.004l4.5 4.5c.246.28.633.421.984.421.352 0 .703-.14.984-.421l9-9Z"
        fill={fill}
      />
    </svg>
  );
};

export default FilledVideoIcon;
