import { REACT_APP_ZEROBOUNCE_KEY } from 'player/src/config/player.config';
import useKeyboardNavigation from 'player/src/hooks/useKeyboardNavigation';
import React, { useEffect, useRef, useState } from 'react';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';
import { useIsMobile } from 'shared/react/hooks/useIsMobile';
import HttpService from 'shared/react/services/httpService';
import styled, { css } from 'styled-components';
import ErrorIcon from '../../../assets/error.svg';
import '../../../assets/input_multi_modal.scss';
import xButton from '../../../assets/x_icon.svg';
import xButtonWhite from '../../../assets/x_icon_white.svg';
import {
  ERROR_MESSAGE_DATA_TEST_ID,
  INPUT_FIELD_DATA_TEST_ID,
  NEXT_BUTTON_DATA_TEST_ID,
} from '../../../constants/dataTestIds.constants';
import Utils from '../../../utils';
import LoadingIndicator from '../../LoadingIndicator';
import Input from '../../common/Input';
import CustomForm from './CustomForm';
import LeadFormDefaultColors from './LeadFormDefaultColors.json';

const TransitionAttributes =
  'background-color, border-bottom-color, border-top-color, border-left-color, border-right-color';

function LeadForm({
  inputFields,
  formText,
  onSubmit,
  onCustomLeadFormSave,
  onLeadFormCompleted,
  verticalOrientation,
  customLeadFormText,
  enableClose,
  onClose,
  projectId,
  stepKey,
  goToAfterLeadFormStep,
  nextStep,
  customData,
}) {
  const [{ direction }, { t }] = useTranslation('interactive.leadForm');
  const [checkboxLabel, setCheckboxLabel] = useState('');
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [currentValue, setCurrentValue] = useState('');
  const [values, setValues] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const textRef = useRef(null);
  const isMobile = useIsMobile();
  const [currentFieldIndex, setCurrentFieldIndex] = useState(0);
  const fields = inputFields.filter(f => f.collect && f.type !== 'checkbox');
  const checkboxField = inputFields.find(f => f.collect && f.type === 'checkbox');
  let currFieldData;
  if (customData[projectId]?.isNotWithLeadForm) {
    currFieldData = customData[projectId]?.[stepKey];
  } else if (goToAfterLeadFormStep && nextStep) {
    currFieldData = customData[projectId]?.[stepKey];
  }
  const { onKeyDown, setNextRef } = useKeyboardNavigation(onClose);

  useEffect(() => {
    setTimeout(() => {
      if (textRef.current) {
        textRef.current.focus();
      }
    }, 250);
  }, []);

  useEffect(() => {
    function formatConsentText(field) {
      const re = /\{{(.*)}}/i;
      const match = field.placeholder.match(re);
      if (match) {
        field.placeholderResult = field.placeholder.split(match[0]);
        field.link = (
          <a rel="noopener noreferrer" target="_blank" href={field.link}>
            {match[1]}
          </a>
        );
        setCheckboxLabel(
          <LeadFormCheckboxText>
            <span>{checkboxField.placeholderResult[0]}</span>
            {checkboxField.link}
            <span>{checkboxField.placeholderResult[1]}</span>
          </LeadFormCheckboxText>
        );
      } else {
        setCheckboxLabel(checkboxField.placeholder);
      }
    }

    if (checkboxField) {
      formatConsentText(checkboxField);
    }
  }, [checkboxField]);

  function handleClose() {
    onClose();
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (e.name === 'next-button') {
      onNextClicked(e);
    } else if (e.name === 'back-button') {
      onBackClicked(e);
    }
  }

  function handleEnterPress(e) {
    onKeyDown(e);

    if (e.keyCode === 13) {
      e.preventDefault();
      onNextClicked(e);
    }
  }

  const fieldPlaceholder = {
    email: customLeadFormText.yourEmail || t(`yourEmail`),
    name: customLeadFormText.yourName || t(`yourName`),
    phone: customLeadFormText.yourPhone || t(`yourPhone`),
  };

  function onBackClicked(e) {
    e.stopPropagation();

    if (!canGoBack()) {
      return;
    }

    let textRefCurrent = textRef.current;
    if (textRefCurrent) {
      setCurrentValue('');
      textRefCurrent.focus();
      setCurrentFieldIndex(currentFieldIndex - 1);
    }
  }

  function isCheckBoxField() {
    let checkBoxFieldIndex = 0;
    if (projectId === '8a229714-d4aa-4066-8089-bb9d25a4c42a') {
      checkBoxFieldIndex = 1;
    }
    return checkboxField && currentFieldIndex === checkBoxFieldIndex;
  }

  function canMoveNext() {
    if (currFieldData) {
      const keys = Object.keys(values);
      if (currFieldData.length === keys.length) {
        return true;
      }
      if (currFieldData.every(({ name, required }) => !required || !!values[name])) {
        return true;
      }
    }
    if (isCheckBoxField() && !checkboxValue) {
      return false;
    }
    if (currentField.required && !currentValue) {
      return false;
    }
    return currentFieldIndex < fields.length && !submitting;
  }

  async function validateEmailWithZerobounce(currentFieldInputElement) {
    const projectIds = ['8a229714-d4aa-4066-8089-bb9d25a4c42a'];

    if (!projectIds.includes(projectId)) {
      return true;
    }
    setSubmitting(true);

    // According to the ZeroBounce API documentation - adding the ip_address parameter is mandatory even if it's empty.
    const response = await HttpService.get(
      `https://api.zerobounce.net/v2/validate?api_key=${REACT_APP_ZEROBOUNCE_KEY}&email=${currentFieldInputElement.value}&ip_address`
    );

    setSubmitting(false);

    if (response.data.status === 'invalid') {
      setErrorMessage(invalidEmailText());
      return false;
    }

    return true;
  }

  async function onNextClicked(e) {
    e.stopPropagation();
    setErrorMessage(null);
    if (!canMoveNext()) {
      return;
    }

    let value = currentValue;
    let currentFieldInputElement = inputFields.filter(f => f.name === currentField.name)[0];
    currentFieldInputElement.value = value;

    let textRefCurrent = textRef.current;
    if (!textRefCurrent) {
      return;
    }

    if (currentFieldInputElement.type === 'email') {
      if (
        currentFieldInputElement.required &&
        !Utils.isValidEmail(currentFieldInputElement.value)
      ) {
        setErrorMessage(invalidEmailText());
        return;
      }

      let splitEmail = currentFieldInputElement.value.split('@');
      const address = splitEmail[0];
      const domain = splitEmail[1];

      if (domain?.includes('gmail.')) {
        currentFieldInputElement.value = `${address}@gmail.com`;
      }

      if (!(await validateEmailWithZerobounce(currentFieldInputElement))) {
        return;
      }
    }

    if (currentFieldIndex < fields.length - 1) {
      setCurrentValue('');
      textRefCurrent.focus();
      setCurrentFieldIndex(currentFieldIndex + 1);
    }

    onSubmit([currentFieldInputElement], currentFieldIndex);

    if (currentFieldIndex >= fields.length - 1) {
      setSubmitting(onLeadFormCompleted());
    }
  }

  let currentField = fields[currentFieldIndex];
  if (currentField.name === 'Password') {
    currentField.type = 'password';
  }
  function canGoBack() {
    return currentFieldIndex > 0 && !submitting;
  }

  function getCurrentColorGroup(currentField) {
    const defaultColorGroups = LeadFormDefaultColors;
    let defaultColorGroup = defaultColorGroups[currentFieldIndex % 4];
    defaultColorGroup.background =
      customData[projectId]?.backgroundColor ||
      currentField?.backgroundColor ||
      defaultColorGroup.background;

    defaultColorGroup.text =
      customData[projectId]?.textColor || currentField?.textColor || defaultColorGroup.text;

    defaultColorGroup.border =
      customData[projectId]?.border || currentField?.borderColor || defaultColorGroup.border;

    return defaultColorGroup;
  }

  const handleMultipleValuesSubmit = e => {
    e.preventDefault();
    const keys = Object.keys(values);

    const any = keys.every(key => {
      return values[key];
    });

    if (!any) {
      return;
    }

    onCustomLeadFormSave(values, currentFieldIndex);
    onLeadFormCompleted(!!currFieldData);
  };

  function handleInputChange(e) {
    if (errorMessage) {
      setErrorMessage(null);
    }
    setCurrentValue(e);
  }

  function invalidEmailText() {
    return customLeadFormText.emailError || t(`invalidEmail`);
  }

  function getSubmitText() {
    if (currentFieldIndex === fields.length - 1) {
      return customLeadFormText.submit || t(`submit`);
    } else {
      return customLeadFormText.next || t(`next`);
    }
  }

  const currentColorGroup = getCurrentColorGroup(currentField);
  const isCustomLeadForm = !!currFieldData;
  const customFormText =
    (isCustomLeadForm && customData[projectId]?.formText?.[stepKey]) ||
    currFieldData?.[0]?.leadFormText;

  return (
    <LeadFormModal
      color={currentColorGroup?.text || currentColorGroup?.background}
      backgroundColor={currentColorGroup?.background}
      borderColor={currentColorGroup?.border || currentColorGroup?.background}
      onSubmit={handleSubmit}
      aria-label="Contact form modal"
      role="dialog"
      aria-modal="true"
    >
      <LeadFormModalContent verticalOrientation={verticalOrientation}>
        <LeadFormLeftPanel>
          {enableClose ? (
            <LeadFormXButtonMobile
              tabIndex="0"
              src={xButtonWhite}
              alt="Close modal"
              onClick={handleClose}
              aria-label="Close contact form"
              role="button"
              ref={ref => (isMobile ? setNextRef(ref) : '')}
              onKeyDown={onKeyDown}
            />
          ) : (
            <div />
          )}
          <LeadFormModalEllipseLeft />
          <LeadFormLeftPanelText direction={direction} verticalOrientation={verticalOrientation}>
            {customFormText || formText}
          </LeadFormLeftPanelText>
        </LeadFormLeftPanel>
        <LeadFormRightPanel direction={direction} verticalOrientation={verticalOrientation}>
          {enableClose ? (
            <LeadFormXButtonDesktop
              ref={ref => (!isMobile ? setNextRef(ref) : '')}
              src={xButton}
              alt="Close modal"
              onClick={handleClose}
              onKeyDown={onKeyDown}
              tabIndex="0"
              aria-label="Close contact form"
              role="button"
            />
          ) : (
            <div />
          )}

          <LeadFormInputFieldContainer isCustomLeadForm={isCustomLeadForm}>
            {isCustomLeadForm ? (
              <CustomForm
                color={currentColorGroup?.text || currentColorGroup?.background}
                borderColor={currentColorGroup?.border || currentColorGroup?.background}
                values={values}
                setValues={setValues}
                inputs={currFieldData || []}
              />
            ) : (
              <>
                <label htmlFor="input" hidden>
                  {currentField.name}
                </label>
                <Input
                  data-test-id={INPUT_FIELD_DATA_TEST_ID}
                  type={currentField.type}
                  ref={ref => {
                    textRef.current = ref;
                    setNextRef(ref);
                  }}
                  color={currentColorGroup?.text || currentColorGroup?.background}
                  borderColor={currentColorGroup?.border || currentColorGroup?.background}
                  required={currentField.required}
                  value={currentValue}
                  name={currentField.name}
                  maxLength={currentField.maxLength || 200}
                  onKeyDown={handleEnterPress}
                  onChange={handleInputChange}
                  aria-label={currentField.name}
                  aria-required={currentField.required}
                  aria-invalid={!!errorMessage}
                  tabIndex="0"
                  id="input"
                  placeholder={
                    (currentField.required ? '*' : '') +
                    (currentField.custom ? currentField.name : fieldPlaceholder[currentField.name])
                  }
                />
              </>
            )}
            {errorMessage && (
              <ErrorMessageContainer>
                <img alt="Error image" src={ErrorIcon} />
                <ErrorMessageText
                  aria-errormessage={errorMessage}
                  data-test-id={ERROR_MESSAGE_DATA_TEST_ID}
                >
                  {errorMessage}
                </ErrorMessageText>
              </ErrorMessageContainer>
            )}
            {isCheckBoxField() && (
              <LeadFormCheckboxContainer>
                <LeadFormInputCheckbox
                  type="checkbox"
                  name="consent"
                  checked={checkboxValue}
                  required={checkboxField.required}
                  onChange={e => setCheckboxValue(e.target.checked)}
                  aria-checked={checkboxValue}
                  aria-required={checkboxField.required}
                  role="checkbox"
                />
                {checkboxLabel}
              </LeadFormCheckboxContainer>
            )}
          </LeadFormInputFieldContainer>

          <LeadFormRightButtonsContainer>
            {!isCustomLeadForm && canGoBack() ? (
              <LeadFormButton
                name="back-button"
                value={customLeadFormText.back || 'Back'}
                disabled={!canGoBack()}
                type="submit"
                onClick={onBackClicked}
                onKeyDown={onKeyDown}
                tabIndex="0"
                aria-disabled={!canGoBack()}
                aria-label="Back button"
                role="button"
                ref={setNextRef}
              />
            ) : (
              <div />
            )}
            <LeadFormNextButtonContainer>
              <LeadFormButton
                data-test-id={NEXT_BUTTON_DATA_TEST_ID}
                name="next-button"
                type="submit"
                role="button"
                color={currentColorGroup?.text || currentColorGroup?.background}
                backgroundColor={currentColorGroup?.background}
                borderColor={currentColorGroup?.border || currentColorGroup?.background}
                disabled={submitting || !canMoveNext()}
                aria-disabled={submitting || !canMoveNext()}
                aria-label="Continue button"
                onClick={isCustomLeadForm ? handleMultipleValuesSubmit : onNextClicked}
                tabIndex="0"
                onKeyDown={onKeyDown}
                value={getSubmitText()}
                ref={setNextRef}
              />
              {submitting && <LoadingIndicator />}
            </LeadFormNextButtonContainer>
          </LeadFormRightButtonsContainer>
        </LeadFormRightPanel>
      </LeadFormModalContent>
    </LeadFormModal>
  );
}

export default LeadForm;

const LeadFormModal = styled.form`
  color: ${({ color }) => color};
  background: ${({ backgroundColor }) => backgroundColor};
  border-color: ${({ borderColor }) => borderColor};
  -webkit-transition: background-color 350ms linear;
  -ms-transition: background-color 350ms linear;
  transition: background-color 350ms linear;

  &::placeholder {
    color: ${props => props.color};
    opacity: 0.5 !important;
    -webkit-transition: color 350ms linear;
    -ms-transition: color 350ms linear;
    transition: color 350ms linear;
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: ${props => props.color} !important;
  }

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  border-radius: 10px;

  @media (max-width: 450px), screen and (hover: none) and (pointer: coarse) {
    position: fixed;
    border-radius: 0;
  }
`;

const LeadFormModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: ${({ verticalOrientation }) => (verticalOrientation ? 'auto' : '1fr 1fr')};
  grid-template-rows: ${({ verticalOrientation }) => (verticalOrientation ? '1fr 1fr' : 'auto')};
  overflow-x: hidden;
  overflow-y: auto;
  background: transparent;

  @media (max-width: 450px) {
    grid-template-columns: auto;
    grid-template-rows: 1fr 1fr;
  }
`;

const LeadFormLeftPanel = styled.div`
  display: grid;
`;

const LeadFormXButtonMobile = styled.img`
  cursor: pointer;
  justify-self: end;
  align-self: start;
  width: 20px;
  visibility: collapse;
  margin: 20px;

  @media (max-width: 450px) {
    display: none;
  }
`;

const LeadFormModalEllipseLeft = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  background: #ee8184;
  opacity: 0.5;
  border-radius: 50%;
  justify-self: center;
  align-self: end;
  filter: blur(104px);
  grid-row: 1;
  grid-column: 1;
`;

const LeadFormLeftPanelText = styled.div`
  justify-self: ${props => (props.verticalOrientation ? 'center' : 'start')};
  padding: 20px 10px 20px ${props => (props.verticalOrientation ? 0 : 44)}px;
  align-self: end;
  max-width: 283px;
  grid-row: 1;
  grid-column: 1;
  text-align: start;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 150.9%;
  color: #ffffff;

  ${({ direction, verticalOrientation }) =>
    direction === 'rtl' &&
    css`
      padding: 20px ${verticalOrientation ? 0 : 44}px 20px 10px;
    `};

  @media (max-width: 450px) {
    align-self: center;
    justify-self: center;

    ${({ direction }) => (direction === 'rtl' ? 'padding-right: 0' : 'padding-left: 0')};
  }
`;

const LeadFormRightPanel = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  margin-top: ${props => (props.verticalOrientation ? 0 : 16)}px;
  margin-bottom: 16px;
  margin-right: 16px;
  margin-left: ${props => (props.verticalOrientation ? 16 : 0)}px;
  background: white;
  border-radius: 10px;

  ${({ direction }) =>
    direction === 'rtl' &&
    css`
      margin-right: 0;
      margin-left: 16px;
    `};

  @media (max-width: 450px) {
    margin-top: 0;
    margin-left: 16px;
    margin-right: 16px;
    grid-template-rows: 1fr min-content;
  }
`;

const LeadFormXButtonDesktop = styled.img`
  justify-self: end;
  align-self: start;
  grid-row: 1;
  grid-column: 1;
  margin: 20px;
  cursor: pointer;

  @media (max-width: 450px) {
    display: none;
  }
`;

const LeadFormInputFieldContainer = styled.div`
  display: grid;
  justify-self: center;
  align-self: center;
  gap: 16px;
  width: 80%;

  @media (max-width: 450px) {
    grid-area: initial;
    height: initial;
    overflow: hidden;
    width: 95%;
  }
`;

const ErrorMessageContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;
  align-items: center;
  justify-items: start;
  justify-self: start;
`;

const ErrorMessageText = styled.div`
  color: #990038;
  text-align: start;
`;

const LeadFormCheckboxContainer = styled.div`
  width: 100%;
  text-align: start;
  align-items: center;
  display: flex;
`;

const LeadFormInputCheckbox = styled.input`
  width: 20px;
  height: 20px;
  min-height: 18px;
  min-width: 18px;
  cursor: pointer;
`;

const LeadFormRightButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-self: end;
  justify-self: end;
  margin: 20px;
  gap: 8px;

  @media (max-width: 450px) {
    grid-row: initial;
    grid-column: initial;
  }
`;

const LeadFormButton = styled.input`
  -webkit-appearance: none;
  text-align: center;
  height: 42px;
  width: min-content;
  min-width: 90px;
  padding: 10px;
  outline: none;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  opacity: ${props => (props.disabled ? 0.4 : 1)};
  grid-row: 1;
  grid-column: 1;
  color: ${({ disabled, color }) => (!disabled && color ? 'white' : '#888888')};
  background: ${({ disabled, backgroundColor }) =>
    !disabled && backgroundColor ? backgroundColor : 'transparent'};
  border-color: ${({ disabled, borderColor }) =>
    !disabled && borderColor ? borderColor : 'transparent'};
  -webkit-transition: ${TransitionAttributes}, 350ms linear;
  -ms-transition: ${TransitionAttributes}, 350ms linear;
  transition: ${TransitionAttributes}, 350ms linear;
  border-radius: 6px;
  box-shadow: none;
  border-style: solid;

  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }

  &:hover {
    opacity: ${props => (props.disabled ? 0.4 : 0.9)};
  }
`;

const LeadFormNextButtonContainer = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
`;

const LeadFormCheckboxText = styled.div`
  white-space: initial;
  margin-left: 10px;
  font-size: 16px;
`;
