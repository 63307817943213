import React from 'react';
import AnalyticsFilter from './AnalyticsFilter';
import styled from 'styled-components';
import useIsEcom from 'app/src/hooks/UseIsEcom';
import { MenuItem } from '@material-ui/core';
import { useApps } from 'app/src/context/AppsStore';
import {
  CONVERSION_KEY,
  EMAIL_FLOWS_KEY,
  ENGAGEMENT_KEY,
  FILTER_TYPE_CHOICES,
  ONSITE_KEY,
  OVERVIEW_KEY,
  SHOP_KEY,
  VIDEOS_KEY,
} from 'app/src/constants/analytics.constants';
import { useAnalyticsProjects } from 'app/src/complex_components/analytics/useAnalyticsProjects';

type AnalyticsTypeFilterProps = {
  selectedTab: string;
  setSelectedTab: (v: string) => void;
  isAnalyticsOverviewEnabled: boolean;
};

export function AnalyticsTypeFilter({
  selectedTab,
  setSelectedTab,
  isAnalyticsOverviewEnabled,
}: AnalyticsTypeFilterProps) {
  const [{ shopify: isShopify }] = useApps();
  const { hasSelectedProjects } = useAnalyticsProjects();
  const isEcom = useIsEcom();

  const items = [
    { key: OVERVIEW_KEY, hidden: !isAnalyticsOverviewEnabled || !isEcom },
    { key: ONSITE_KEY },
    { key: SHOP_KEY, hidden: !isShopify || hasSelectedProjects },
    { key: VIDEOS_KEY },
    { key: CONVERSION_KEY, hidden: !isEcom },
    { key: ENGAGEMENT_KEY },
    { key: EMAIL_FLOWS_KEY },
  ].filter(item => !item.hidden);

  return (
    <StyledAnalyticsTypeFilter
      value={selectedTab}
      onChange={setSelectedTab}
      getSelectedValue={v => FILTER_TYPE_CHOICES[v] || v}
    >
      {items.map(({ key }) => (
        <MenuItem key={key} value={key}>
          {FILTER_TYPE_CHOICES[key] ?? key}
        </MenuItem>
      ))}
    </StyledAnalyticsTypeFilter>
  );
}

const StyledAnalyticsTypeFilter = styled(AnalyticsFilter)`
  .MuiSelect-select * {
    font-size: 18px;
    line-height: 20px;
  }
`;
