import React, { useEffect, useState } from 'react';
import {
  getCustomForm,
  getShouldLoadCustomLeadForm,
  loadCustomLeadFormIfNeeded,
} from 'player/src/utils/custom-form.utils';
import LeadForm from './LeadForm';
import ComponentWithLoader from 'shared/react/components/basic/ComponentWithLoader';

const LeadFormContainer = ({ projectId, ...props }) => {
  const [loading, setLoading] = useState(getShouldLoadCustomLeadForm({ projectId }));

  useEffect(() => {
    loadLeadForm();
  }, [projectId]);

  const loadLeadForm = async () => {
    if (!getShouldLoadCustomLeadForm({ projectId })) {
      setLoading(false);
      return;
    }

    setLoading(true);
    await loadCustomLeadFormIfNeeded({ projectId });
    setLoading(false);
  };

  return (
    <ComponentWithLoader isLoading={loading}>
      <LeadForm projectId={projectId} customData={getCustomForm()} {...props} />;
    </ComponentWithLoader>
  );
};

export default LeadFormContainer;
