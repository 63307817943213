import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { TextSmall } from 'shared/react/components/complex/Text';
import { FIELD_KEYS } from 'app/src/pages/modals/hubspot_mapping/hubspot_table/hubspotTable.constants';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import BasicSelect from 'shared/react/components/basic/basic_select/BasicSelect';
import { useHubspot } from 'app/src/context/integrations/HubspotStore';
import AddButton from 'app/src/basic_components/AddButton';

const HubspotAddField = ({ onAddField, projectFields, fields }) => {
  const [{ properties }] = useHubspot();
  const [tolstoyField, setTolstoyField] = useState('');
  const [hubspotField, setHubspotField] = useState('');

  const getPropertiesItems = () => {
    return Object.values(properties).map(({ name, label }) => {
      return { name: label, id: name };
    });
  };

  const setHubspot = value => {
    setHubspotField(value);
  };

  const setTolstoy = value => {
    setTolstoyField(value);
  };

  const onAddClick = () => {
    if (!tolstoyField || !hubspotField) {
      return;
    }

    const newTolstoyField = {
      step: tolstoyField.step,
      key: tolstoyField.id,
      answer: tolstoyField.answer,
    };

    onAddField({
      tolstoyField: newTolstoyField,
      hubspotField: hubspotField.id,
      mappingType: 'Custom',
    });
  };

  const filteredHubspotFields = useMemo(() => {
    const hubspotFields = getPropertiesItems();
    hubspotFields.sort((a, b) => a.name.localeCompare(b.name));
    const usedFieldMapping = {};
    fields?.forEach(field => {
      usedFieldMapping[field.hubspotField] = true;
    });

    return hubspotFields.filter(field => {
      return !usedFieldMapping[field.id];
    });
  }, [fields, properties]);

  const filteredProjectFields = useMemo(() => {
    const usedFieldMapping = {};
    fields?.forEach(({ tolstoyField }) => {
      usedFieldMapping[`${tolstoyField.step}-${tolstoyField.key}`] = true;
    });

    return projectFields.filter(field => {
      return !usedFieldMapping[`${field.step}-${field.id}`];
    });
  }, [fields]);

  const values = {
    tolstoyField: (
      <BasicSelect
        customHeight={14}
        onChange={setTolstoy}
        value={tolstoyField}
        items={filteredProjectFields}
      />
    ),
    hubspotField: (
      <BasicSelect
        customHeight={14}
        onChange={setHubspot}
        value={hubspotField}
        items={filteredHubspotFields}
      />
    ),
    ['enabled']: <AddButton onClick={onAddClick}>Add</AddButton>,
  };

  return (
    <LayoutRoot>
      {FIELD_KEYS.map(({ key, width, defaultText }) => {
        const shouldRemovePadding = ['tolstoyField', 'hubspotField'].includes(key);
        return (
          <Field shouldRemovePadding={shouldRemovePadding} width={width} key={key}>
            {values[key] || defaultText}
          </Field>
        );
      })}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)``;

const Field = styled(TextSmall)`
  border-right: 1px solid ${({ theme }) => theme.colors.gray3};
  display: flex;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.gray3};
  padding: ${({ shouldRemovePadding }) => (shouldRemovePadding ? '4px' : '16px')};
  min-width: ${({ width }) => width}px;
  max-width: ${({ width }) => width}px;

  &:last-of-type {
    border-right: none;
  }
`;

export default HubspotAddField;
