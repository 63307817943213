import { CardDetails } from 'app/src/pages/dashboard/components/card/Card';
import VideoCardLabels from 'app/src/pages/dashboard/pages/videos/components/video_card/components/video-card-details/components/video-card-labels/VideoCardLabels';
import VideoDetails from 'app/src/pages/dashboard/pages/videos/components/video_card/components/video-card-details/components/video-details/VideoDetails';
import { VideosPageVodAsset } from 'app/src/pages/dashboard/pages/videos/types/videos.types';
import React from 'react';
import { AnalyticsDataProps } from 'src/types/common/common';
import InteractiveVideoCardProducts from 'app/src/pages/dashboard/pages/videos/components/video_card/components/video-card-details/components/interactive-video-card-products/InteractiveVideoCardProducts';
import OnboardingTemplatesTagVideosTooltip from 'app/src/pages/dashboard/pages/homepage/onboarding/templates/components/tag_videos/OnboardingTemplatesTagVideosTooltip';

type Props = {
  video: VideosPageVodAsset;
  className?: string;
  onVideoChoose: (video: VideosPageVodAsset) => void;
  isProductsTaggingEnabled: boolean;
  onSelectedVideosAction?: (video: VideosPageVodAsset[] | null) => void;
  analyticsData?: AnalyticsDataProps;
  isTagProductsTooltipShown?: boolean;
  isTagLabelsTooltipShown?: boolean;
};

const VideoCardDetails = ({
  video,
  className,
  onVideoChoose,
  isProductsTaggingEnabled,
  onSelectedVideosAction,
  analyticsData,
  isTagProductsTooltipShown,
  isTagLabelsTooltipShown,
}: Props) => {
  return (
    <CardDetails className={className}>
      <VideoDetails video={video} />
      <OnboardingTemplatesTagVideosTooltip text={isTagProductsTooltipShown ? 'Tag products' : ''}>
        <InteractiveVideoCardProducts
          onVideoChoose={onVideoChoose}
          isProductsTaggingEnabled={isProductsTaggingEnabled}
          video={video}
        />
      </OnboardingTemplatesTagVideosTooltip>
      <VideoCardLabels
        video={video}
        onSelectedVideosAction={onSelectedVideosAction}
        analyticsData={analyticsData}
        isTagLabelsTooltipShown={isTagLabelsTooltipShown}
      />
    </CardDetails>
  );
};

export default VideoCardDetails;
