import React from 'react';

const ResendIcon = () => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.82031 2.11719C1.57422 2.22656 1.4375 2.47266 1.4375 2.71875V6.21875C1.4375 6.60156 1.71094 6.875 2.09375 6.875H5.59375C5.83984 6.875 6.08594 6.73828 6.19531 6.49219C6.27734 6.24609 6.22266 5.94531 6.03125 5.78125L4.60938 4.33203C5.51172 3.48438 6.6875 2.9375 8 2.9375C10.6523 2.9375 12.8125 5.09766 12.8125 7.75C12.8125 10.4297 10.6523 12.5625 8 12.5625C6.85156 12.5625 5.75781 12.1797 4.91016 11.4688C4.63672 11.2227 4.22656 11.2773 3.98047 11.5508C3.76172 11.8242 3.78906 12.2344 4.0625 12.4805C5.15625 13.3828 6.55078 13.875 8 13.875C11.3633 13.875 14.125 11.1406 14.125 7.75C14.125 4.38672 11.3633 1.625 8 1.625C6.33203 1.625 4.82812 2.30859 3.67969 3.42969L2.53125 2.28125C2.36719 2.08984 2.06641 2.03516 1.82031 2.11719Z"
        fill="#7D8087"
      />
    </svg>
  );
};

export default ResendIcon;
