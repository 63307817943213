import { useEffect, useMemo, useState } from 'react';
import { ADD_PRODUCT_VIDEOS_MODAL_KEY } from 'src/constants/modals.constants';
import { useApps } from 'src/context/AppsStore';
import { useProductActions } from 'src/context/ProductsStore';
import { useVodConnectionActions } from 'src/context/VodConnectionStore';
import { useAddProductVideos as useAddProductVideosStore } from 'src/context/ui_store/AddProductVideosStore';
import { useModal } from 'src/context/ui_store/ModalStore';
import { useSnackBarActions } from 'src/context/ui_store/SnackBarStore';
import { track } from 'src/helpers/Tracker';
import { useOpenVideoPickerModal } from 'src/hooks/modals/useOpenVideoPickerModal';
import { useCreateVodConnection } from 'src/hooks/vod-connections';
import { VodConnectionProvider, VodConnectionType } from 'src/types/entities';
import { VOD_CONNECTION_TYPES } from 'shared/react/constants/vodConnection.constants';

const useAddProductVideos = () => {
  const { createProducts } = useProductActions();
  const { getVodConnectionByProductId, deleteConnectionByProductId } = useVodConnectionActions();
  const openVideoPickerModal = useOpenVideoPickerModal();
  const [{ currentId: productId, modalProps }, { setCurrentModal, setCurrentId, setModalProps }] =
    useModal();
  const [
    {
      currentProductId,
      initialVodAssetIds,
      newVodAssetIds,
      deletedVodAssetIds,
      currentProductData,
      isDirty,
      isLoading,
    },
    { init, addVideo, removeVideo, clearState },
  ] = useAddProductVideosStore();
  const [{ selectedAppUrl }] = useApps();
  const { createVodConnection } = useCreateVodConnection();
  const { setInfoSnackbar } = useSnackBarActions();
  const { analyticsData, ...productData } = modalProps;

  const [isSaving, setIsSaving] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const handleVideoPicked = async video => {
    if (!video?.id) {
      return;
    }

    const { isVideoAlreadyTagged } = addVideo({ vodAssetId: video.id }) || {};
    setCurrentModal(ADD_PRODUCT_VIDEOS_MODAL_KEY);
    setCurrentId(currentProductId);
    setModalProps({ ...modalProps, ...currentProductData });

    if (isVideoAlreadyTagged) {
      setInfoSnackbar('Video already tagged to Product');
    }
  };

  const handleAddVideoClick = async () => {
    openVideoPickerModal({
      onVideoPicked: handleVideoPicked,
      shouldBreakAfterVideoPicked: true,
    });
  };

  const createNewVodConnections = async (productId: string) => {
    track('Vod Connections Created', {
      type: VOD_CONNECTION_TYPES.productId,
      location: `${analyticsData.location}/Add Product Videos Modal`,
    });

    return Promise.all(
      newVodAssetIds.map(vodAssetId =>
        createVodConnection({
          productId,
          externalProductId: currentProductId,
          vodAssetId: vodAssetId,
          type: VodConnectionType.productId,
          appUrl: selectedAppUrl,
          provider: VodConnectionProvider.shopify,
        })
      )
    );
  };

  const deleteVodConnections = async () => {
    return Promise.all(
      deletedVodAssetIds.map(vodAssetId =>
        deleteConnectionByProductId({
          externalProductId: currentProductId,
          vodAssetId,
        })
      )
    );
  };

  const save = async () => {
    setIsSaving(true);

    const product = (await createProducts([currentProductId], selectedAppUrl))?.[0];

    const promises = [];

    promises.push(createNewVodConnections(product.id));

    promises.push(deleteVodConnections());

    await Promise.all(promises);

    clearState();
    setIsSaving(false);
  };

  useEffect(() => {
    if (productId === currentProductId) {
      return;
    }

    const initialVodConnections = getVodConnectionByProductId(productId);
    const initialVodAssetIds = initialVodConnections.map(({ vodAssetId }) => vodAssetId);

    setIsInitialized(false);

    init({
      currentProductId: productId,
      initialVodAssetIds,
      productData,
    });

    setIsInitialized(true);
  }, [productId, currentProductId]);

  const vodAssetIds = useMemo(
    () => [...initialVodAssetIds, ...newVodAssetIds],
    [initialVodAssetIds, newVodAssetIds]
  );

  return {
    handleAddVideoClick,
    removeVideo,
    save,
    discard: clearState,
    vodAssetIds,
    currentProductData,
    isDirty,
    isLoading,
    isSaving,
    isInitialized,
  };
};

export default useAddProductVideos;
