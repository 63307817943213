import React from 'react';
import { useExtension } from 'app/src/context/ui_store/ExtensionStore';
import { Modal } from '../../styled_components';
import styled from 'styled-components';
import { TextBody, TextH6 } from 'shared/react/components/complex/Text';
import Button from 'shared/react/components/complex/Button';
import RedirectButton from 'app/src/images/RedirectButton';
import Utils from '../../utils';
import { track } from '../../helpers/Tracker';

const AddExtensionModal = () => {
  const [{ addExtensionModalOpen }, { toggleAddExtensionModal }] = useExtension();

  const onClose = () => {
    toggleAddExtensionModal(false);
  };

  const onOpenExtension = () => {
    track('Visit Chrome Extension');
    Utils.openInNewTab(
      'https://chrome.google.com/webstore/detail/tolstoy/lddobacmfjhpmcdapikgehklfonmnoeg'
    );
  };

  return (
    <Modal open={addExtensionModalOpen} onClose={onClose}>
      <ModalContent>
        <HeaderContainer>
          <Header>Hey, please install the Chrome Extension to use this feature.</Header>
          <CloseModal onClick={onClose}>✕</CloseModal>
        </HeaderContainer>
        <Text>
          The extension will allow you to record your screen quickly. Need help setting up the
          extension?
        </Text>
        <ButtonContainer>
          <Button onClick={onOpenExtension} rightIcon={<RedirectButton />} showRightIcon={true}>
            Visit Chrome Store
          </Button>
        </ButtonContainer>
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div`
  max-width: 390px;
  padding: 32px;
  box-shadow: 0px 4px 18px -4px rgba(0, 0, 0, 0.18), 0px 39px 34px -19px rgba(0, 0, 0, 0.08);
  background: ${({ theme }) => theme.colors.white};
  border-radius: 16px;
  outline: none;
  position: relative;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CloseModal = styled.div`
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 16px;
  font-weight: 600;
  color: black;
  z-index: 2;
`;

const Header = styled(TextH6)`
  font-weight: 800;
  margin-bottom: 30px;
`;

const Text = styled(TextBody)`
  margin-bottom: 64px;
  font-weight: normal;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default AddExtensionModal;
