import React from 'react';
import styled from 'styled-components';
import LoadingIndicator from './LoadingIndicator';

function BlueButton({ children, onClick, disabled, isLoading, ...props }) {
  function handleClick(e) {
    e.stopPropagation();
    if (disabled) {
      return;
    }
    onClick(e);
  }

  return (
    <LayoutRoot onClick={handleClick} disabled={disabled || isLoading} {...props}>
      {children}
      {isLoading && <StyledLoadingIndicator />}
    </LayoutRoot>
  );
}

export default BlueButton;

const LayoutRoot = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: grid;
  justify-content: center;
  justify-items: center;
  align-items: center;
  padding: 12px 24px;
  font-size: 22px;
  font-weight: 600;
  min-height: 28px;
  color: ${({ disabled }) => (disabled ? 'rgba(61,79,224,0.8)' : 'white')};
  background: ${({ disabled }) => (disabled ? 'rgba(226,237,254,0.8)' : '#3D4FE0')};
  border-radius: 8px;
  z-index: 100000000;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  grid-column: 1 / span 2;
`;
