import React, { useState, useEffect } from 'react';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import styled from 'styled-components';
import StandardModalV2 from 'app/src/pages/modals/StandardModalV2';
import { TextBodyBold, TextSmall } from 'shared/react/components/basic/text/TextV2';
import IosSwitch from 'app/src/basic_components/IosSwitch';
import HorizontalFlex, {
  Gap8HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { GhostButton, PrimaryButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { useApps } from 'app/src/context/AppsStore';
import { useInstagram } from 'app/src/context/InstagramStore';

const InstagramTagProductsModal = ({ closeModal }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [searchForOldVideos, setShouldSearchForOldVideos] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [{ instagramApps }, { updateApp }] = useApps();
  const [, { getProductsForOldVideos }] = useInstagram();
  const instagramApp = instagramApps[0];
  const setChecked = value => {
    setIsChecked(value.target.checked);
  };

  const saveState = async () => {
    setIsLoading(true);
    const settings = JSON.stringify({
      ...instagramApp.settings,
      shouldTagProducts: isChecked,
      searchForOldVideos,
    });
    await updateApp(instagramApp.id, {
      settings,
    });

    if (isChecked && searchForOldVideos) {
      await getProductsForOldVideos();
    }

    closeModal();
  };

  useEffect(() => {
    setIsChecked(instagramApp?.settings?.shouldTagProducts || false);
    setShouldSearchForOldVideos(instagramApp?.settings?.searchForOldVideos || false);
  }, [instagramApp]);

  return (
    <StyledStandardModal disableEnforceFocus onClose={closeModal} open>
      <LayoutRoot>
        <Header>Autotag</Header>
        <SwitchContainer>
          <Text>
            Apply Instagram Shopping product tags to future videos added to your Tolstoy library
          </Text>
          <IosSwitch checked={isChecked} onChange={setChecked} />
        </SwitchContainer>
        <SwitchContainer>
          <Text>
            Apply Instagram Shopping product tags to existing videos in your Tolstoy library
          </Text>
          <IosSwitch
            disabled={!isChecked}
            checked={searchForOldVideos && isChecked}
            onChange={value => {
              setShouldSearchForOldVideos(value.target.checked);
            }}
          />
        </SwitchContainer>

        <ButtonsContainer>
          <GhostButton onClick={closeModal}>Cancel</GhostButton>
          <PrimaryButton isLoading={isLoading} onClick={saveState}>
            Save
          </PrimaryButton>
        </ButtonsContainer>
      </LayoutRoot>
    </StyledStandardModal>
  );
};

const StyledStandardModal = styled(StandardModalV2)``;

const Header = styled(TextBodyBold)``;

const LayoutRoot = styled(VerticalFlex)`
  padding: 24px;
  width: 500px;
  border-radius: 4px;
  gap: 16px;
`;

const Text = styled(TextSmall)`
  margin: 0 4px;
`;

const SwitchContainer = styled(HorizontalFlex)`
  gap: 8px;
  justify-content: space-between;
`;

const ButtonsContainer = styled(Gap8HorizontalFlex)`
  justify-content: flex-end;
`;

export default InstagramTagProductsModal;
