import React from 'react';
import styled from 'styled-components';
import BoldIcon from '../icons/BoldIcon';
import ItalicIcon from '../icons/ItalicIcon';
import UnderLineIcon from '../icons/UnderLineIcon';
import { RichUtils } from 'draft-js';
import ListIcon from '../icons/ListIcon';
import CountedListIcon from '../icons/CountedListIcon';
import LinkButton from '../plugins/hyperLink/LinkButton';
import ReplyOptions from './ReplyOptions';
import ToolBarButton from './ToolBarButton';
import ImageButton from './image_button/ImageButton';
import Utils from 'shared/react/utils/utils';

const Toolbar = props => {
  const { editorState, setEditorState, focusEditor, track } = props;
  const inlineStyle = editorState?.getCurrentInlineStyle();

  const BLOCK_TYPES = [
    { style: 'unordered-list-item', icon: ListIcon },
    {
      style: 'ordered-list-item',
      icon: CountedListIcon,
    },
  ];

  const INLINE_STYLES = [
    { style: 'BOLD', icon: BoldIcon, isSelected: inlineStyle.has('BOLD') },
    { style: 'ITALIC', icon: ItalicIcon, isSelected: inlineStyle.has('ITALIC') },
    {
      style: 'UNDERLINE',
      icon: UnderLineIcon,
      isSelected: inlineStyle.has('UNDERLINE'),
    },
  ];

  const onInlineClick = (e, style) => {
    e.stopPropagation();
    track(`Rich Text ${style} Click`);
    let nextState = RichUtils.toggleInlineStyle(editorState, style);
    setEditorState(nextState);
    focusEditor();
  };

  const onBlockClick = (e, style) => {
    e.stopPropagation();
    const styleName = {
      'unordered-list-item': 'Bullet List',
      'ordered-list-item': 'Number List',
    };
    track(`Rich Text ${styleName[style]} Click`);

    let nextState = RichUtils.toggleBlockType(editorState, style);
    setEditorState(nextState);
    focusEditor();
  };

  const BlockStyleControls = () => {
    return BLOCK_TYPES.map(type => (
      <ToolBarButton key={type.style} onClick={onBlockClick} {...props} {...type} />
    ));
  };

  const InlineStyleControls = () => {
    return INLINE_STYLES.map(type => (
      <ToolBarButton key={type.style} onClick={onInlineClick} {...props} {...type} />
    ));
  };

  return (
    <LayoutRoot onClick={Utils.stopPropagation}>
      <ReplyOptions {...props} />
      <InlineStyleControls />
      <BlockStyleControls />
      <ToolBarButton component={LinkButton} {...props} />
      <ToolBarButton component={ImageButton} {...props} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export default Toolbar;
