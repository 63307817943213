import React from 'react';
import BlockFreeUserPublishMessage from 'app/src/pages/modals/publish/BlockFreeUserPublishMessage';
import styled from 'styled-components';
import { publishMethodOptions, project as Project } from 'app/src/types/entities';

const text = {
  [publishMethodOptions.bubble]: 'Bubble',
  [publishMethodOptions.embed]: 'Embed',
  [publishMethodOptions.hero]: 'Hero',
  [publishMethodOptions.stories]: 'Stories',
  [publishMethodOptions.carousel]: 'Carousel',
  [publishMethodOptions.videoPage]: 'TV Page',
  [publishMethodOptions.tvChannel]: 'TV Channel',
};
const accountText = 'Tolstoy';

type Props = {
  project?: Project;
};

const PublishFree = ({ project }: Props) => {
  return <CustomBlockFreeUserPublishMessage type={text[project?.publishMethod] || accountText} />;
};

const CustomBlockFreeUserPublishMessage = styled(BlockFreeUserPublishMessage)`
  background: inherit;
  border: 1px solid ${({ theme }) => theme.colors.neutralLight};
  padding: 16px;
`;

export default PublishFree;
