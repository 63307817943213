import React from 'react';

const BackArrowIcon = props => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.7109 8.5C17.7109 9.04688 17.2812 9.47656 16.7734 9.47656H3.49219L8.6875 14.3984C9.07812 14.75 9.07812 15.375 8.72656 15.7266C8.375 16.1172 7.78906 16.1172 7.39844 15.7656L0.523438 9.20312C0.328125 9.00781 0.25 8.77344 0.25 8.5C0.25 8.26562 0.328125 8.03125 0.523438 7.83594L7.39844 1.27344C7.78906 0.921875 8.375 0.921875 8.72656 1.3125C9.07812 1.66406 9.07812 2.28906 8.6875 2.64062L3.49219 7.5625H16.7734C17.3203 7.5625 17.7109 7.99219 17.7109 8.5Z"
      fill="#090A0B"
    />
  </svg>
);

export default BackArrowIcon;
