import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useProjects } from 'app/src/context/ProjectsStore';
import { useFolders } from 'app/src/context/FoldersStore';
import Breadcrumbs from 'app/src/pages/project/components/project-top-bar/components/breadcrumbs/Breadcrumbs';
import ProjectTabButtons from 'app/src/pages/project/components/project-top-bar/components/projectTabButtons/ProjectTabButtons';
import TopBar from 'app/src/pages/dashboard/components/top-bar/TopBar';
import { TextH6 } from 'shared/react/components/basic/text/TextV2';

const ProjectTopBar = () => {
  const { folderId, projectId } = useParams();
  const [, { getFolderById }] = useFolders({});
  const [{ project }] = useProjects({ projectId });

  const getTitle = () => {
    let title = '';

    const folder = getFolderById(folderId)?.name;
    if (folder) {
      title += `${folder} / `;
    }

    title += project?.name;

    return title;
  };

  const renderDirtyTitle = (title: string) => {
    return <DirtyTitle className="notranslate">{title}</DirtyTitle>;
  };

  return (
    <TopBar
      leftContent={<Breadcrumbs />}
      rightContent={<ProjectTabButtons />}
      dirtyTitle={getTitle()}
      renderDirtyTitle={renderDirtyTitle}
    />
  );
};

const DirtyTitle = styled(TextH6)`
  color: ${({ theme }) => theme.colors.neutralLight};
  padding-left: 20px;
`;

export default ProjectTopBar;
