import React from 'react';

const FentyStarHalfFilled = ({ color = 'black', opacity = 1 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
      <path
        d="m9.844 1.625 1.844 3.5 3.937.688 1.781.312-1.281 1.313-2.75 2.843.563 3.938.25 1.781-1.626-.781L9 13.469l-3.594 1.75L3.781 16l.281-1.781.563-3.938-2.781-2.844L.563 6.126l1.812-.312 3.906-.688 1.844-3.5L9 0zM9 3.219v8.562l.656.344 2.656 1.281-.437-2.906-.094-.75.5-.5 2.063-2.125-2.906-.5-.72-.125-.343-.656z"
        fill={color}
        opacity={opacity}
      />
    </svg>
  );
};

export default FentyStarHalfFilled;
