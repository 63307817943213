import { useApps } from 'app/src/context/AppsStore';
import { useProjects } from 'app/src/context/ProjectsStore';
import { APPS_SELECT_ALL_STORES_KEY } from 'app/src/constants/ui.constants';

const getSelectedAppProjects = (projects, selectedAppUrl) => {
  if (!selectedAppUrl || selectedAppUrl === APPS_SELECT_ALL_STORES_KEY) {
    return projects;
  }

  return projects?.filter(project => project.appUrl === selectedAppUrl);
};

const useSelectAppProjects = () => {
  const [{ projects }] = useProjects();
  const [{ selectedAppUrl }] = useApps();

  const selectedAppProjects = getSelectedAppProjects(projects, selectedAppUrl);

  return { selectedAppProjects };
};

export default useSelectAppProjects;
