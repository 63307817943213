import React from 'react';

const InstagramIcon = () => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 4.406C6 4.406 4.406 6.031 4.406 8 4.406 10 6 11.594 8 11.594A3.594 3.594 0 0 0 11.594 8c0-1.969-1.625-3.594-3.594-3.594Zm0 5.938A2.34 2.34 0 0 1 5.656 8c0-1.281 1.032-2.313 2.344-2.313A2.307 2.307 0 0 1 10.313 8c0 1.313-1.032 2.344-2.313 2.344Zm4.563-6.063a.84.84 0 0 1-.844.844.84.84 0 0 1-.844-.844.84.84 0 0 1 .844-.843.84.84 0 0 1 .844.843Zm2.374.844C14.876 4 14.626 3 13.813 2.187 13 1.375 12 1.125 10.874 1.063 9.719 1 6.25 1 5.094 1.063 3.969 1.125 3 1.375 2.156 2.188 1.344 3 1.094 4 1.031 5.124c-.062 1.156-.062 4.625 0 5.781.063 1.125.313 2.094 1.125 2.938.844.812 1.813 1.062 2.938 1.125 1.156.062 4.625.062 5.781 0 1.125-.063 2.125-.313 2.938-1.125.812-.844 1.062-1.813 1.124-2.938.063-1.156.063-4.625 0-5.781Zm-1.5 7c-.218.625-.718 1.094-1.312 1.344-.938.375-3.125.281-4.125.281-1.031 0-3.219.094-4.125-.281a2.37 2.37 0 0 1-1.344-1.344C2.156 11.219 2.25 9.031 2.25 8c0-1-.094-3.188.281-4.125a2.41 2.41 0 0 1 1.344-1.313c.906-.374 3.094-.28 4.125-.28 1 0 3.188-.095 4.125.28.594.22 1.063.72 1.313 1.313.374.938.28 3.125.28 4.125 0 1.031.095 3.219-.28 4.125Z"
      fill="#50545E"
    />
  </svg>
);
export default InstagramIcon;
