export const intercomBoot = params => {
  intercomAction('boot', params);
};

export const intercomUpdate = params => {
  intercomAction('update', params);
};

export const intercomShow = () => {
  intercomAction('show');
};

export const trackIntercomEvent = (eventName, params = {}) => {
  intercomAction('trackEvent', eventName, params);
};

const intercomAction = (action, params = {}, metadata) => {
  window.Intercom?.(action, params, metadata);
};
