import UrlHelper from 'shared/react/utils/urlHelper';

const LINK_REGEX = /\[.+?\]\(.+?\)/gi;
const BOLD_REGEX = /\*.+?\*/gi;
const ITALIC_REGEX = /_.+?_/gi;
const STRIKETHROUGH_REGEX = /~.+?~/gi;
const CODE_REGEX = /```[\s\S]*?```/gi;

function escapeHtml(str) {
    return str.replace(/&/g, "&amp;")
         .replace(/</g, "&lt;")
         .replace(/>/g, "&gt;")
         .replace(/"/g, "&quot;")
         .replace(/\*/g, '&#42;')
         .replace(/'/g, "&#039;");
}

function removeFirstLastChars(str) {
  return str.substring(1, str.length - 1);
}

const REPLACEMENTS = [
  {
    regex: CODE_REGEX,
    replaceFunc: str => escapeHtml(str.replace(/```/g, ''))
  },
  {
    regex: BOLD_REGEX,
    replaceFunc: str => `<b>${removeFirstLastChars(str)}</b>`,
  },
  {
    regex: ITALIC_REGEX,
    replaceFunc: str => `<i>${removeFirstLastChars(str)}</i>`,
  },
  {
    regex: STRIKETHROUGH_REGEX,
    replaceFunc: str => `<s>${removeFirstLastChars(str)}</s>`,
  },
  {
    regex: LINK_REGEX,
    replaceFunc: str => {
      const [text] = str.match(/\[.+?\]/);
      const [link] = str.match(/\(.+?\)/);
      const href = UrlHelper.enforceProtocol(removeFirstLastChars(link));

      return `<a href=${href} target="_blank" rel="noreferrer">${removeFirstLastChars(text)}</a>`;
    },
  },
];

function replaceAll(text, regex, replaceFunc) {
  let match;
  while ((match = regex.exec(text)) !== null) {
    const str = match[0];
    text = text.replace(str, replaceFunc(str));
  }

  return text;
}

function format(text) {
  REPLACEMENTS.forEach(({ regex, replaceFunc }) => {
    text = replaceAll(text, regex, replaceFunc);
  });

  return text;
}

export function formatText(text) {
  if (!text) {
    return '';
  }

  return format(text);
}
