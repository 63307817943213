import { EVENTS, VIDEO_SIZE_TYPES, VIEWING_DEVICES } from "./constants";
import "./style.css";
import YoutubeHero from "./YoutubeHero";
import { Config, YoutubeConfig } from "./types/config";
import Overlay from "./overlay/Overlay";
import { useEffect, useState } from "preact/hooks";
import { FC } from "preact/compat";

type Props = {
  config: Config;
  onSendEvent: (eventName: string) => Promise<void> | void;
  isMobile: boolean;
};

const Hero: FC<Props> = ({ config, onSendEvent, isMobile }) => {
  const [videoUrl, setVideoUrl] = useState("");
  const [videoPoster, setVideoPoster] = useState("");
  const [isYoutube, setIsYoutube] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const {
    captions,
    captionLocation = "center",
    videoSizeType = VIDEO_SIZE_TYPES.responsive,
    width,
    height,
    ...youtubeConfig
  } = config?.heroSettings || {};

  const onClick = async () => {
    let videoLink = config.link;
    if (!videoLink.url) {
      return;
    }

    await Promise.all([
      onSendEvent(EVENTS.sessionStart),
      onSendEvent(EVENTS.clickCta),
    ]);

    const shouldOpenInNewTab = videoLink?.isOpenInNewTab;

    if (!shouldOpenInNewTab) {
      window.top.location.href = videoLink.url;
      return;
    }

    window.open(videoLink.url, "_blank");
  };

  const getVideoByType = (steps, type: VIEWING_DEVICES) => {
    return (
      steps.find((step) => {
        return step.type === type;
      }) || steps[0]
    );
  };

  const startVideo = (config) => {
    const type = isMobile ? VIEWING_DEVICES.mobile : VIEWING_DEVICES.desktop;
    const video = getVideoByType(config.steps, type);
    setVideoUrl(video.videoUrl);
    setVideoPoster(video.posterUrl);
  };

  const linkClassName = config?.link?.url ? "link" : "";
  const videoClassName = `tolstoy-hero-video ${linkClassName}`;

  const style =
    videoSizeType === VIDEO_SIZE_TYPES.responsive ? {} : { width, height };

  useEffect(() => {
    if (!config) {
      return;
    }

    onSendEvent(EVENTS.pageView);

    setIsYoutube(
      !!config?.steps.find(({ type }) => type === VIEWING_DEVICES.youtube)
    );
    startVideo(config);
  }, [config, isMobile]);

  if (!config) {
    return;
  }

  if (isYoutube) {
    return (
      <YoutubeHero
        youtubeConfig={youtubeConfig as YoutubeConfig}
        step={config.steps.find(({ type }) => type === VIEWING_DEVICES.youtube)}
      />
    );
  }

  return (
    <div
      tabIndex="0"
      aria-label="Tolstoy hero video button"
      className="tolstoy-hero-video-container"
      role="button"
      onClick={onClick}
      style={style}
    >
      <Overlay
        isMobile={isMobile}
        onSendEvent={onSendEvent}
        heroSettings={config?.heroSettings}
        shouldBePointer={!!config?.link?.url}
        isMuted={isMuted}
        setIsMuted={setIsMuted}
      />
      <video
        className={videoClassName}
        loop
        playsInline
        aria-label="Hero Video"
        disablePictureInPicture
        disableRemotePlayback
        muted={isMuted}
        autoPlay
        preload="auto"
        poster={videoPoster}
        src={videoUrl}
      />
      {!!captions && (
        <div
          className={`absolute tolstoy-hero-video-caption ${linkClassName} ${captionLocation}`}
        >
          {captions}
        </div>
      )}
    </div>
  );
};

export default Hero;
