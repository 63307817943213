import React, { useState } from 'react';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import TolstoysTab from 'app/src/pages/dashboard/pages/libraries/tolstoys_tab/TolstoysTab';
import StandardModal from 'app/src/pages/modals/StandardModal';
import styled from 'styled-components';
import { defaultStepData } from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/editStepDefaultData';
import { useProjects } from 'app/src/context/ProjectsStore';
import { useApps } from 'app/src/context/AppsStore';
import { navigateToBuilder } from 'app/src/utils/navigation.utils';
import { useHistory } from 'react-router-dom';
import Message from 'app/src/pages/creation_flow/right_section/Message';
import { LOADING_STATE } from 'app/src/pages/creation_flow/creationFlowConstants';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';
import useAddStep from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/useAddStep';
import { useDebounce } from 'app/src/hooks/useDebounce';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

const ImportVideosToExistingTolstoyModal = () => {
  const [
    {
      modalProps: { importToLibrary },
    },
    { clearModalState },
  ] = useModal();
  const [, { createProjectStep }] = useProjects();
  const [, { isEcomPlatformConnected }] = useApps();
  const [, { setErrorSnackbar }] = useSnackBar();
  const history = useHistory();
  const { getMaxNumberOfParts } = useAddStep();
  const [uploadState, setUploadState] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 300);

  const createStep = (videoId, project) => {
    const newStep = {
      ...defaultStepData(project.feed, isEcomPlatformConnected()),
      videoId,
      projectId: project.id,
    };

    return createProjectStep(newStep, project);
  };

  const onProjectClick = async project => {
    const numberOfParts = project?.steps.items.length || 0;
    const numberOfAllowedParts = getMaxNumberOfParts(project);

    const remainingParts = numberOfAllowedParts - numberOfParts;

    if (remainingParts < 1) {
      setErrorSnackbar('Cant add more parts to this project');
      return;
    }

    setUploadState(LOADING_STATE.importVideos);
    const videos = await importToLibrary(remainingParts);
    setUploadState(LOADING_STATE.creatingSteps);

    const promises = videos.map(({ videoId }) => {
      return createStep(videoId, project);
    });

    const newProject = (await Promise.all(promises))[0];

    navigateToBuilder(history, newProject.id, newProject.steps.items[0].id);
    clearModalState();
  };

  return (
    <>
      <Modal onClose={clearModalState} disableEnforceFocus open={true}>
        <TolstoysTab
          disableExtraTolstoyClick
          debouncedSearchValue={debouncedSearchValue}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          onProjectClick={onProjectClick}
        />
      </Modal>
      {!!uploadState && (
        <MessageContainer>
          <Message uploadState={uploadState} />
        </MessageContainer>
      )}
    </>
  );
};

const Modal = styled(StandardModal)`
  height: 80%;
  overflow: auto;
  width: 100%;
  max-width: 80%;
  position: relative;
  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    max-width: 100%;
    height: 95%;
    padding: 36px 16px 16px;
    margin: 0 8px;
  }
`;

const MessageContainer = styled(HorizontalFlexCentered)`
  position: absolute;
  z-index: 14500;
  height: 100%;
  width: 100%;
`;

export default ImportVideosToExistingTolstoyModal;
