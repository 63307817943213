import React from 'react';
import PricingTooltip from 'app/src/complex_components/PricingTooltip';
import { useBilling } from 'app/src/hooks/useBillings';

const PremiumFeatureTooltip = ({
  header,
  analyticsData,
  marginTop,
  children,
  shouldHide,
  fullWidthContainer,
  containerWidth,
  placement = 'right',
}) => {
  const { isFreePackage } = useBilling();

  return (
    <PricingTooltip
      header={header}
      text={`Hey! I'm a premium feature, click below to unlock.`}
      placement={placement}
      analyticsData={analyticsData}
      marginTop={marginTop}
      shouldHide={shouldHide || !isFreePackage}
      fullWidthContainer={fullWidthContainer}
      requestUserUpgrade={false}
      containerWidth={containerWidth}
    >
      {children}
    </PricingTooltip>
  );
};

export default PremiumFeatureTooltip;
