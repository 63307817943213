import { createStore, createHook, createContainer } from 'react-sweet-state';
import { getRequest, postRequest } from '../helpers/API';
import Utils from '../utils';

const initialState = {
  packages: [],
};

const actions = {
  getPlan:
    () =>
    async ({ setState }) => {
      const plan = await getRequest('payment', `/${Utils.getAppKey()}/plan`);

      setState(plan);
    },
  getPackage:
    packageId =>
    ({ getState }) => {
      const { packages } = getState();

      return packages?.find(({ id }) => id === packageId);
    },
  updatePackage: packageId => async () => {
    return postRequest('account-actions', '/actions/accounts/update-package', {
      body: {
        appKey: Utils.getAppKey(),
        packageId,
      },
    });
  },
  upgradePackage:
    packageId =>
    async ({ dispatch }) => {
      try {
        const { link } = await dispatch(actions.updatePackage(packageId));
        return link;
      } catch (error) {
        Utils.logError('Could not upgrade package', error);
        console.error('Could not upgrade package', error);
      }
    },
  getStripeSessionLink: () => async () => {
    try {
      const { url } = await postRequest('stripe', '/billing/sessions', {
        body: {
          appKey: Utils.getAppKey(),
        },
      });
      return url;
    } catch (e) {
      Utils.logError('Get billing link error', e);
      console.log(e);
      return null;
    }
  },
  unsubscribe: () => async () => {
    try {
      await postRequest('payment', '/unsubscribe', {
        body: {
          appKey: Utils.getAppKey(),
        },
      });
    } catch (e) {
      Utils.logError('Stripe unsubscribe error', e);
      console.log(e);
      return e;
    }
  },
  getBillingDetails: () => async () => {
    try {
      const data = await postRequest('stripe', '/billing/details', {
        body: {
          appKey: Utils.getAppKey(),
        },
      });

      return data;
    } catch (e) {
      Utils.logError('Get billing link error', e);
      console.log(e);
      return null;
    }
  },
};

const PackagesStore = createStore({ initialState, actions, name: 'Packages' });

export const usePackages = createHook(PackagesStore);

export const PackagesContainer = createContainer(PackagesStore);
