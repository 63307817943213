import React from 'react';

const BlackInstagramIcon = props => (
  <svg
    width={42}
    height={43}
    viewBox="0 0 42 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y={0.5} width={42} height={41.999} rx={10} fill="#2F3137" />
    <g clipPath="url(#black_instagram_icon)">
      <path
        d="M21.036 13.25c-4.572 0-8.215 3.714-8.215 8.214a8.17 8.17 0 0 0 8.215 8.215c4.5 0 8.214-3.643 8.214-8.215 0-4.5-3.714-8.214-8.214-8.214Zm0 13.571a5.35 5.35 0 0 1-5.357-5.357c0-2.928 2.357-5.285 5.357-5.285a5.274 5.274 0 0 1 5.285 5.285c0 3-2.357 5.357-5.285 5.357Zm10.428-13.857a1.92 1.92 0 0 1-1.928 1.929 1.92 1.92 0 0 1-1.929-1.929 1.92 1.92 0 0 1 1.929-1.928 1.92 1.92 0 0 1 1.928 1.928Zm5.429 1.929c-.143-2.572-.714-4.857-2.572-6.714-1.857-1.858-4.142-2.429-6.714-2.572-2.643-.143-10.571-.143-13.214 0-2.572.143-4.786.714-6.714 2.572-1.858 1.857-2.429 4.142-2.572 6.714-.143 2.643-.143 10.571 0 13.214.143 2.572.714 4.786 2.572 6.714 1.928 1.858 4.142 2.429 6.714 2.572 2.643.143 10.571.143 13.214 0 2.572-.143 4.857-.714 6.714-2.572 1.858-1.928 2.429-4.142 2.572-6.714.143-2.643.143-10.571 0-13.214Zm-3.429 16c-.5 1.428-1.643 2.5-3 3.071-2.143.857-7.143.643-9.428.643-2.357 0-7.357.214-9.429-.643a5.416 5.416 0 0 1-3.071-3.071c-.857-2.072-.643-7.072-.643-9.429 0-2.285-.214-7.285.643-9.428a5.51 5.51 0 0 1 3.071-3c2.072-.857 7.072-.643 9.429-.643 2.285 0 7.285-.214 9.428.643 1.357.5 2.429 1.643 3 3 .857 2.143.643 7.143.643 9.428 0 2.357.214 7.357-.643 9.429Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="black_instagram_icon">
        <path fill="#fff" d="M5 5.5h32v31.999H5z" />
      </clipPath>
    </defs>
  </svg>
);

export default BlackInstagramIcon;
