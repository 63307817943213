import React from 'react';

const SendgridIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={40}
    id="Layer_1"
    x={0}
    y={0}
    viewBox="0 0 792 230.7"
    style={{
      enableBackground: 'new 0 0 792 230.7',
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.st1,.st3{fill:#7d8087}'}</style>
    <path
      className="st1"
      d="m179.3 176.3 18.5-14.5c5.2 9 13.5 14.2 23 14.2 10.3 0 15.8-6.7 15.8-14 0-8.7-10.6-11.5-21.9-15-14.2-4.4-30.1-9.9-30.1-30.2 0-17 14.8-30.5 35.3-30.5 17.3 0 27.2 6.5 35.8 15.4L239 114.3c-4.4-6.5-10.6-10-18.9-10-9.4 0-14.5 5.1-14.5 11.8 0 8.1 10.2 10.9 21.5 14.7 14.4 4.7 30.7 11 30.7 31.4 0 16.9-13.4 33.1-36.8 33.1-19.3.1-32.1-8.1-41.7-19zm162.6-55.1h19.8v8.1c4.7-6 11.8-9.6 20.1-9.6 17.1 0 27.5 11 27.5 29.8v44.3H389v-41.7c0-9.7-4.5-15.4-13.4-15.4-7.6 0-13.8 5.2-13.8 17.4v39.7h-19.9v-72.6zm72 36.4c0-25.7 18.9-37.8 35.3-37.8 9.4 0 16.9 3.5 21.7 8.6V87.8h19.9v106.1h-19.9v-8.1c-4.8 5.7-12.5 9.6-21.9 9.6-15.5 0-35.1-12.3-35.1-37.8zm57.5-.2c0-10.8-7.8-19.5-18.6-19.5-11 0-19.5 8.3-19.5 19.5s8.4 19.6 19.5 19.6c10.8 0 18.6-8.8 18.6-19.6zm24-16.5c0-30.2 22.8-54.5 54.2-54.5 15.7 0 28.9 5.7 38.5 14.7 3.9 3.7 7.2 7.9 9.8 12.5l-17.7 10.8c-6.5-12.1-16.6-18.3-30.4-18.3-19.3 0-34.3 15.8-34.3 34.9 0 19.5 14.7 34.9 35 34.9 15.4 0 26.3-8.7 30.1-22.2h-33.3v-19.3h55.1v8.1c0 28.5-20.3 53-51.9 53-33.2-.1-55.1-25.3-55.1-54.6zm112-19.7h19.8v11.9c3.6-7.6 10.2-11.9 20.1-11.9h8l-7.2 19.3h-5.4c-10.6 0-15.3 5.5-15.3 19v34.3h-19.9v-72.6zm53 0h19.9v72.7h-19.9v-53.3h-7.2l7.2-19.4zm10-11c6.6 0 11.9-5.3 11.9-11.9 0-6.6-5.3-11.9-11.9-11.9-6.6 0-11.9 5.3-11.9 11.9 0 6.5 5.3 11.9 11.9 11.9zm14.6 47.4c0-25.7 18.9-37.8 35.3-37.8 9.4 0 16.9 3.5 21.7 8.6V87.8h19.9v106.1H742v-8.1c-4.8 5.7-12.5 9.6-21.9 9.6-15.5 0-35.1-12.3-35.1-37.8zm57.6-.2c0-10.8-7.8-19.5-18.6-19.5-11 0-19.5 8.3-19.5 19.5S712.9 177 724 177c10.7 0 18.6-8.8 18.6-19.6zm-405.4 0c0-20.9-15.3-37.6-37.5-37.6-20.9 0-37.8 16.9-37.8 37.8s15.7 37.8 38.4 37.8c15.7 0 27-7.6 33.3-18.4l-15.8-9.4c-3.3 6.4-9.8 10.4-17.4 10.4-10.5 0-17-5.2-19.3-13.2h56.1v-7.4zm-55.2-8.7c3.1-6.8 9.4-11.5 17.9-11.5 8.4 0 14.7 3.9 17.3 11.5H282z"
    />
    <path
      d="M70.6 151.5H113v42.4H70.6zm0-42.5H28.2v42.5h42.4z"
      style={{
        fill: '#7d8087',
      }}
    />
    <path className="st3" d="M28.2 151.5h42.4v42.4H28.2z" />
    <path
      d="M155.5 109h-42.4v42.5h42.4V109zM70.6 66.6H113V109H70.6zm0 42.4v42.5h42.5V109z"
      style={{
        fill: '#7d8087',
      }}
    />
    <path className="st3" d="M113.1 66.6h42.4V109h-42.4z" />
    <path
      className="st1"
      d="M199.4 41v24.7h-5.7V41h-8.8v-5h23.4v4.9h-8.9zm50.5 24.7h-5.4l-6.6-21.6-6.6 21.6h-5.4l-8.3-29.8 6 .1 5.5 21 6.2-21h5.7l6.2 21 5.4-21h5.7l-8.4 29.7zm19.8 0V36h5.7v29.7h-5.7zm19 0V36h5.7v24.7h9.9l.1 5h-15.7zm27.5 0V36h5.7v29.7h-5.7zm31.8.6c-8.4 0-13.9-6.2-13.9-15.4s5.5-15.4 14-15.4S362 41.7 362 50.9c-.1 9.1-5.5 15.4-14 15.4zm0-26.1c-5.1 0-8.1 4.2-8.1 10.7s3.1 10.7 8.1 10.7c5.1 0 8.2-4.2 8.2-10.7s-3.1-10.7-8.2-10.7z"
    />
  </svg>
);

export default SendgridIcon;
