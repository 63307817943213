export const CURRENCY_CODES = {
  usd: 'USD',
};

export const CURRENCY_SYMBOL_MAP = {
  USD: '$',
  CAD: 'CA$',
  EUR: '€',
  AED: 'د.إ.‏',
  AFN: '؋',
  ALL: 'Lek',
  AMD: 'դր.',
  ARS: '$',
  AUD: 'AU$',
  AZN: 'ман.',
  BAM: 'KM',
  BDT: '৳',
  BGN: 'лв.',
  BHD: 'د.ب.‏',
  BIF: 'FBu',
  BND: '$',
  BOB: 'Bs',
  BRL: 'R$',
  BWP: 'P',
  BYN: 'руб.',
  BZD: '$',
  CDF: 'FrCD',
  CHF: 'CHF',
  CLP: '$',
  CNY: 'CN¥',
  COP: '$',
  CRC: '₡',
  CVE: 'CV$',
  CZK: 'Kč',
  DJF: 'Fdj',
  DKK: 'kr',
  DOP: 'RD$',
  DZD: 'د.ج.‏',
  EEK: 'kr',
  EGP: 'ج.م.‏',
  ERN: 'Nfk',
  ETB: 'Br',
  GBP: '£',
  GEL: 'GEL',
  GHS: 'GH₵',
  GNF: 'FG',
  GTQ: 'Q',
  HKD: '$',
  HNL: 'L',
  HRK: 'kn',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  INR: '₹',
  IQD: 'د.ع.‏',
  IRR: '﷼',
  ISK: 'kr',
  JMD: '$',
  JOD: 'د.أ.‏',
  JPY: '￥',
  KES: 'Ksh',
  KHR: '៛',
  KMF: 'FC',
  KRW: '₩',
  KWD: 'د.ك.‏',
  KZT: 'тңг.',
  LBP: 'ل.ل.‏',
  LKR: 'SL Re',
  LTL: 'Lt',
  LVL: 'Ls',
  LYD: 'د.ل.‏',
  MAD: 'د.م.‏',
  MDL: 'MDL',
  MGA: 'MGA',
  MKD: 'MKD',
  MMK: 'K',
  MOP: 'MOP$',
  MUR: 'MURs',
  MXN: 'MX$',
  MYR: 'RM',
  MZN: 'MTn',
  NAD: 'N$',
  NGN: '₦',
  NIO: 'C$',
  NOK: 'kr',
  NPR: 'नेरू',
  NZD: '$',
  OMR: 'ر.ع.‏',
  PAB: 'B/.',
  PEN: 'S/.',
  PHP: '₱',
  PKR: '₨',
  PLN: 'zł',
  PYG: '₲',
  QAR: 'ر.ق.‏',
  RON: 'RON',
  RSD: 'дин.',
  RUB: '₽.',
  RWF: 'FR',
  SAR: 'ر.س.‏',
  SDG: 'SDG',
  SEK: 'kr',
  SGD: '$',
  SOS: 'Ssh',
  SYP: 'ل.س.‏',
  THB: '฿',
  TND: 'د.ت.‏',
  TOP: 'T$',
  TRY: 'TL',
  TTD: '$',
  TWD: 'NT$',
  TZS: 'TSh',
  UAH: '₴',
  UGX: 'USh',
  UYU: '$',
  UZS: 'UZS',
  VEF: 'Bs.F.',
  VND: '₫',
  XAF: 'FCFA',
  XOF: 'CFA',
  YER: 'ر.ي.‏',
  ZAR: 'R',
  ZMK: 'ZK',
  ZWL: 'ZWL$',
};
