import { createContainer, createHook, createStore } from 'react-sweet-state';
import { Amplify, graphqlOperation, Storage } from 'aws-amplify';
import { graphqlRequest, postRequest } from '../helpers/API';
import {
  responsesBySessionId,
  sessionsByProjectId,
  sessionsByEmail,
  getSession,
} from '../graphql/queries';
import dateFormat from 'dateformat';
import Utils from '../utils';
import APP_CONFIG from 'app/src/config/app.config';

const initialState = {
  sessionsByDate: {},
  error: null,
  nextToken: null,
  products: [],
  selectedSession: null,
};

const SESSIONS_FETCH_SIZE = 50;
const RESPONSES_FETCH_SIZE = 500;
const MAX_RESPONSES_SIZE = 5000;

async function getResponsesBySessionId(sessionId, nextToken) {
  try {
    const response = await graphqlRequest(
      graphqlOperation(responsesBySessionId, {
        sessionId,
        sortDirection: 'ASC',
        limit: RESPONSES_FETCH_SIZE,
        nextToken,
      })
    );
    return response.data.responsesBySessionId;
  } catch (error) {
    return {};
  }
}

async function getResponses(sessionId) {
  let { items, nextToken } = await getResponsesBySessionId(sessionId, null);
  const responses = items;
  while (nextToken && responses.length < MAX_RESPONSES_SIZE) {
    ({ items, nextToken } = await getResponsesBySessionId(sessionId, nextToken));
    responses.push(...items);
  }

  return responses;
}

async function getSessionsByProjectId(projectId, nextToken) {
  try {
    const response = await graphqlRequest(
      graphqlOperation(sessionsByProjectId, {
        projectId,
        sortDirection: 'DESC',
        limit: SESSIONS_FETCH_SIZE,
        nextToken,
      })
    );
    return response.data.sessionsByProjectId;
  } catch (error) {
    return {};
  }
}

function filterSessions(sessions, filters = []) {
  if (!Object.keys(filters).length) {
    return sessions;
  }

  return sessions.filter(session => {
    for (const key of filters) {
      if (session[key]) {
        return true;
      }
    }

    return false;
  });
}

async function getSessions(projectId, currNextToken, filters = {}) {
  let { items, nextToken } = await getSessionsByProjectId(projectId, currNextToken);

  const sessions = filterSessions(items, filters);
  while (nextToken && sessions.length < SESSIONS_FETCH_SIZE) {
    ({ items, nextToken } = await getSessionsByProjectId(projectId, nextToken));
    sessions.push(...filterSessions(items, filters));
  }

  return {
    sessions,
    nextToken,
  };
}

function addSessionsByDate(sessionsByDate = {}, sessions) {
  for (const session of sessions) {
    const dateKey = getSessionByDateKEy(session.lastEventAt);

    if (!sessionsByDate[dateKey]) {
      sessionsByDate[dateKey] = {};
    }

    sessionsByDate[dateKey][session.id] = session;
  }
}

function getSessionByDateKEy(lastEventAt) {
  const date = new Date(lastEventAt);
  date.setHours(0, 0, 0, 0);
  return dateFormat(date, 'DDDD, mmmm dS, yyyy');
}

const actions = {
  fetchResponsesBySessionId: sessionId => async () => {
    try {
      return getResponses(sessionId);
    } catch (e) {
      Utils.logError('can`t fetch responses', e);
      console.log(e);
      return null;
    }
  },
  sendReplyEmail:
    (publishId, sessionId, to, replyProjectId, videoId, text, compose, attachments) => async () => {
      try {
        const response = await postRequest('reply-email', '/actions/mailer/reply', {
          body: {
            publishId,
            sessionId,
            to,
            replyProjectId,
            videoId,
            text,
            compose,
            attachments,
          },
        });
        if (response.err) {
          Utils.logError('Sending a reply email failed.', response.err);
        }

        return response;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
  sendReplyEmailV2:
    ({
      publishId,
      sessionId,
      to,
      replyProjectId,
      videoId,
      text,
      compose,
      attachments,
      fromEmail,
    }) =>
    async () => {
      try {
        const response = await postRequest('reply-email', '/actions/mailer/reply', {
          body: {
            publishId,
            sessionId,
            to,
            replyProjectId,
            videoId,
            text,
            compose,
            attachments,
            fromEmail,
          },
        });
        if (response.err) {
          Utils.logError('Sending a reply email failed.', response.err);
        }

        return response;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
  sendProductsEmail: body => async () => {
    try {
      const response = await postRequest('reply-email', '/actions/mailer/products', {
        body,
      });
      if (response.err) {
        Utils.logError('Sending a reply email failed.', response.err);
      }

      return response;
    } catch (e) {
      console.log(e);
      return null;
    }
  },
  getProducts:
    email =>
    async ({ getState, setState }) => {
      if (getState().products.length) {
        return;
      }

      const route = `products-csv/${email}.csv`;

      // noinspection JSAccessibilityCheck
      Storage.configure({
        AWSS3: {
          bucket: Amplify._config.aws_user_files_s3_bucket,
          region: Amplify._config.aws_project_region,
        },
      });
      let csvUrl;
      try {
        csvUrl = await Storage.get(route);
      } catch (err) {
        console.error('Failure in getting products url', err);
      }

      const products = await Utils.downloadAndParseCsv(csvUrl);
      if (products.message) {
        throw new Error('Not Found');
      }

      if (products[0][0].toLocaleLowerCase() === 'id') {
        products.shift();
      }

      setState({ products });
    },
  fetchSessionsByProjectId:
    (projectId, filters) =>
    async ({ getState, setState }) => {
      try {
        const { sessionsByDate, nextToken } = getState();

        const { sessions, nextToken: newNextToken } = await getSessions(
          projectId,
          nextToken,
          filters
        );

        addSessionsByDate(sessionsByDate, sessions);

        setState({
          sessionsByDate,
          nextToken: newNextToken,
        });

        return sessionsByDate;
      } catch (error) {
        console.error(error);
        return { sessionsByDate: {}, nextToken: null };
      }
    },
  fetchSessionsByEmail:
    (projectId, email) =>
    async ({ setState }) => {
      try {
        const response = await graphqlRequest(
          graphqlOperation(sessionsByEmail, {
            filter: {
              projectId: {
                eq: projectId,
              },
            },
            email,
            sortDirection: 'DESC',
          })
        );

        const sessions = response.data.sessionsByEmail.items;
        const sessionsByDate = {};
        addSessionsByDate(sessionsByDate, sessions);
        setState({ sessionsByDate, nextToken: null });
      } catch (error) {
        console.error(error);
        return { sessionsByDate: {}, nextToken: null };
      }
    },
  fetchSessionsById:
    id =>
    async ({ getState, setState }) => {
      try {
        const { sessionsByDate } = getState();

        const response = await graphqlRequest(graphqlOperation(getSession, { id }));
        const sessions = [response.data.getSession];
        addSessionsByDate(sessionsByDate, sessions);
        setState({ sessionsByDate, nextToken: null });
      } catch (error) {
        console.error(error);
        return { sessionsByDate: {}, nextToken: null };
      }
    },
  createResponse:
    (sessionId, newTolstoyId, email, value, projectId, type, attachments) => async () => {
      const body = {
        stepName: '',
        projectId,
        sessionId,
        type,
        value,
        email,
        read: true,
        attachments,
      };
      if (newTolstoyId) {
        body.collectInfo = JSON.stringify({ newTolstoyId });
      }

      await postRequest('response-actions', '/actions/replies', { body });
      const responseDate = new Date().toISOString();
      return { ...body, createdAt: responseDate, updatedAt: responseDate };
    },
  getVideoResponseUrl: (response, isOriginalVideo) => () => {
    if (response.stockAsset?.videoUrl) {
      return response.stockAsset?.videoUrl;
    }

    if (isOriginalVideo) {
      return getVideoUrlFromInput(response);
    }

    return getVideoUrlFromOutput(response);
  },
  getVideoResponseImage:
    ({ value: videoId, owner, stockAsset }) =>
    () => {
      if (stockAsset?.posterUrl) {
        return stockAsset?.posterUrl;
      }

      return `${APP_CONFIG.VIDEO_OUTPUT}/responses/${owner}/${videoId}/${videoId}.0000000.jpg`;
    },
  getImageResponseUrl:
    ({ value: name, owner }) =>
    () => {
      return `https://${APP_CONFIG.IMAGE_OUTPUT}/responses/${owner}/${name}`;
    },
  getAudioResponseUrl:
    ({ value: name, owner }) =>
    () => {
      return `${APP_CONFIG.VIDEO_OUTPUT}/audio-responses/${owner}/${name}/${name}.mp3`;
    },
  clearResponsesStore:
    () =>
    ({ getState, setState }) => {
      setState({ sessionsByDate: {}, error: null, nextToken: null, products: getState().products });
    },
};

const getVideoUrlFromInput = ({ value: videoId, owner }) => {
  return `https://${APP_CONFIG.VIDEO_INPUT}.s3.amazonaws.com/responses/${owner}/${videoId}.webm}`;
};

const getVideoUrlFromOutput = ({ value: videoId, owner }) => {
  const videoUrl = APP_CONFIG.VIDEO_OUTPUT;

  return `${videoUrl}/responses/${owner}/${videoId}/${videoId}.mp4`;
};

const ResponsesStore = createStore({ initialState, actions, name: 'Responses' });

export const useResponses = createHook(ResponsesStore);

export const ResponsesContainer = createContainer(ResponsesStore);
