import React from 'react';
import styled from 'styled-components';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import { TextBody } from 'shared/react/components/complex/Text';
import BillingPackageUsageCurrent from './BillingPackageUsageCurrent';

const getUsage = (currentMonthPageView, maxPageViews) => {
  if (!currentMonthPageView) {
    return 0;
  }
  return Math.min(100, Math.ceil((100 * currentMonthPageView) / maxPageViews));
};

const BillingPackageMetric = ({ text, showTotal, total, currentValue }) => {
  return (
    <StyledBillingPackageMetric>
      <BillingPackageMetricTitle>
        <BillingPackageUsageCurrent
          showTotal={showTotal}
          currentValue={currentValue}
          total={total}
        />
        <BillingPackageMetricText>{text}</BillingPackageMetricText>
      </BillingPackageMetricTitle>

      {showTotal && (
        <BillingPackageMetricUsageBar>
          <BillingPackageMetricUsageBarCurrent usage={getUsage(currentValue, total)} />
        </BillingPackageMetricUsageBar>
      )}
    </StyledBillingPackageMetric>
  );
};

export default BillingPackageMetric;

const StyledBillingPackageMetric = styled(Gap16VerticalFlex)`
  min-width: 341px;
  padding-left: 20px;
`;

const BillingPackageMetricTitle = styled(Gap8VerticalFlex)``;

const BillingPackageMetricText = styled(TextBody)`
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray2};
`;

const BillingPackageMetricUsageBar = styled.div`
  position: relative;
  border-radius: 39px;
  background: ${({ theme }) => theme.colors.successLight};
  height: 12px;
  width: 100%;
`;

const BillingPackageMetricUsageBarCurrent = styled.div`
  position: absolute;
  border-radius: 39px;
  background: ${({ theme }) => theme.colors.success};
  left: 0;
  width: ${({ usage }) => `${usage}%`};
  height: 12px;
`;
