import { useEffect, useRef, useState } from 'react';

import { useProductActions } from 'app/src/context/ProductsStore';
import { SHOPIFY } from 'app/src/constants/intergrations.constants';

const LOAD_NEW_PRODUCTS_THRESHOLD = 5;

const useProductsWithSuggestedVideos = selectedApp => {
  const { getProductsWithSuggestedVideos } = useProductActions();

  const { appUrl, app } = selectedApp || {};
  const isShopify = app === SHOPIFY;

  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const isAllProductsFetched = useRef(false);

  const fetchProducts = async (shouldSetLoading?: boolean) => {
    if (isAllProductsFetched.current) {
      return;
    }

    if (shouldSetLoading) {
      setIsLoading(true);
    }

    const productsResponse = await getProductsWithSuggestedVideos(appUrl);

    setProducts(products => {
      const newProducts = productsResponse.items.filter(
        product => !products.find(({ id }) => id === product.id)
      );

      if (!newProducts.length) {
        isAllProductsFetched.current = true;
        return products;
      }

      return [...products, ...newProducts];
    });

    if (shouldSetLoading) {
      setIsLoading(false);
    }
  };

  const removeProduct = productId => {
    setProducts(products => products.filter(({ id }) => id !== productId));

    if (products.length < LOAD_NEW_PRODUCTS_THRESHOLD) {
      fetchProducts();
    }
  };

  useEffect(() => {
    isAllProductsFetched.current = false;
    setProducts([]);

    if (!isShopify || !appUrl) {
      return;
    }

    fetchProducts(true);
  }, [appUrl, isShopify]);

  return { products, isLoading, fetchProducts, removeProduct };
};

export default useProductsWithSuggestedVideos;
