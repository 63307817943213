export const secondsToMinutesSecondsDisplay = secondsValue => {
  if (!secondsValue) {
    return '00:00';
  }

  let seconds = secondsValue;

  let minutes = Math.floor(seconds / 60);
  minutes >= 1 ? (seconds = seconds - minutes * 60) : (minutes = '00');
  seconds < 1 ? (seconds = '00') : void 0;

  minutes.toString().length === 1 ? (minutes = '0' + minutes) : void 0;
  seconds.toString().length === 1 ? (seconds = '0' + seconds) : void 0;

  return minutes + ':' + ('0' + Math.round(seconds)).slice(-2);
};
