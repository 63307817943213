import React from 'react';
import styled, { useTheme } from 'styled-components';
import { TextSmall } from 'shared/react/components/complex/Text';
import Utils from 'app/src/utils';
import collectInfoResponseTitles from 'app/src/pages/project/pages/inbox/ConversationComponents/collectInfoResponse.constants.json';
import CollectInfoEmail from 'app/src/images/response_collect_info_email.svg';
import CollectInfoPhone from 'app/src/images/response_collect_info_phone.svg';
import CollectInfoName from 'app/src/images/response_collect_info_name.svg';

const getCollectInfoValue = ({ collectInfo }) => {
  if (Utils.isJsonString(collectInfo)) {
    return Object.entries(JSON.parse(JSON.parse(collectInfo)));
  }
  const [key, value] = collectInfo.split(':');
  return [[key, value?.trim() || '']];
};

function getFilteredCollectInfo(response) {
  let collectInfo = getCollectInfoValue(response);
  if (collectInfo.some(item => item[0] === 'upsrepmail')) {
    collectInfo = collectInfo.filter(item => item[0] !== 'email');
  }
  return collectInfo;
}

function getTitle(key) {
  return collectInfoResponseTitles[key] || `${Utils.upperCaseFirstChar(key)} `;
}

const getIcon = key => {
  switch (key) {
    case 'email':
      return CollectInfoEmail;
    case 'phone':
      return CollectInfoPhone;
    default:
      return CollectInfoName;
  }
};

const CollectInfoResponse = ({ response }) => {
  const collectInfo = getFilteredCollectInfo(response);
  const theme = useTheme();

  return (
    <StyledCollectInfoResponse>
      {collectInfo.map(([key, value]) => (
        <CollectInfoRow key={key}>
          <CollectInfoIcon src={getIcon(key)} />
          <TextSmall textColor={theme.colors.gray27}>{getTitle(key)}</TextSmall>
          <TextSmall textColor={theme.colors.blue7}>{value}</TextSmall>
        </CollectInfoRow>
      ))}
    </StyledCollectInfoResponse>
  );
};

export default CollectInfoResponse;

const StyledCollectInfoResponse = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 14px auto 1fr;
  align-items: center;
`;

const CollectInfoRow = styled.div`
  display: contents;
`;

const CollectInfoIcon = styled.img`
  width: 14px;
  height: 14px;
`;
