import React, { FC, useState } from 'react';
import styled from 'styled-components';

import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { LeftChevronIcon } from 'shared/react/images/ChevronIcon';
import { useApps } from 'src/context/AppsStore';
import { useProductActions } from 'src/context/ProductsStore';
import useCreateVodConnection from 'src/hooks/vod-connections/useCreateVodConnection';
import { VodConnectionProvider, VodConnectionType } from 'src/types/entities';
import { RowData } from '../../types/ProductsTable.types';
import VideoSuggestion from '../video_suggestion/VideoSuggestion';

type Props = {
  product: RowData;
  isExpanded: boolean;
  toggleExpanded: (id: string) => () => void;
  expandNext: (id: string) => void;
  fetchProducts: () => void;
  removeProduct: (productId: string) => void;
};

const ProductRow: FC<Props> = ({
  product,
  isExpanded,
  toggleExpanded,
  expandNext,
  fetchProducts,
  removeProduct,
}) => {
  const [rejectedVideoIds, setRejectedVideoIds] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { createVodConnection } = useCreateVodConnection();
  const { createProducts } = useProductActions();
  const [{ selectedAppUrl }] = useApps();

  const { title, imageUrl, id: externalProductId, videoIds } = product;

  const videoIdsToRender = videoIds.filter(videoId => !rejectedVideoIds.includes(videoId));

  const rejectSuggestion = videoId => {
    const nextRejectedVideoIds = [...new Set([...rejectedVideoIds, videoId])];
    const uniqueVideoIds = [...new Set(videoIds)];
    if (nextRejectedVideoIds.length === uniqueVideoIds.length) {
      expandNext(externalProductId);
      fetchProducts();
    }
    setRejectedVideoIds(nextRejectedVideoIds);
  };

  const acceptSuggestion = async videoId => {
    setIsSubmitting(true);

    const product = (await createProducts([externalProductId], selectedAppUrl))?.[0];

    if (!product) {
      console.log('Error creating product');
      return;
    }

    await createVodConnection({
      productId: product.id,
      externalProductId,
      type: VodConnectionType.productId,
      provider: VodConnectionProvider.shopify,
      vodAssetId: videoId,
      appUrl: selectedAppUrl,
      tag: undefined,
      vodLabelId: undefined,
      variantIds: undefined,
    });

    expandNext(externalProductId);
    removeProduct(externalProductId);

    setIsSubmitting(false);
  };

  const getVideoSuggestions = () => {
    if (!isExpanded) {
      return null;
    }

    return (
      <VideosRow>
        {videoIdsToRender.map((videoId: string) => {
          return (
            <VideoSuggestion
              key={`${externalProductId}${videoId}`}
              videoId={videoId}
              isSubmitting={isSubmitting}
              rejectSuggestion={rejectSuggestion}
              acceptSuggestion={acceptSuggestion}
            />
          );
        })}
      </VideosRow>
    );
  };

  if (!videoIdsToRender.length) {
    return null;
  }

  return (
    <Row>
      <ProductRowDetails onClick={toggleExpanded(externalProductId)}>
        <Image src={imageUrl} />
        <EllipsisTextOverflowWrapper>
          <TextTiny>{title}</TextTiny>
        </EllipsisTextOverflowWrapper>
        <ExpandIcon isExpanded={isExpanded} />
      </ProductRowDetails>

      {getVideoSuggestions()}
    </Row>
  );
};

const Row = styled(VerticalFlex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutralLightest};

  &:last-child {
    border-bottom: none;
  }
`;

const ProductRowDetails = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
`;

const VideosRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.neutralLightest};
  gap: 16px;
`;

const Image = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.boxShadows.elevation1};
  margin-right: 8px;
`;

const ExpandIcon = styled(LeftChevronIcon)`
  margin-left: auto;
  margin-right: 8px;
  transform: rotate(${({ isExpanded }) => (isExpanded ? 90 : 270)}deg);
`;

export default ProductRow;
