import React from 'react';

const XIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0547 16.5938C15.8203 16.8281 15.3906 16.8281 15.1562 16.5938L10 11.3984L4.80469 16.5938C4.57031 16.8281 4.14062 16.8281 3.90625 16.5938C3.67188 16.3594 3.67188 15.9297 3.90625 15.6953L9.10156 10.5L3.90625 5.34375C3.67188 5.10938 3.67188 4.67969 3.90625 4.44531C4.14062 4.21094 4.57031 4.21094 4.80469 4.44531L10 9.64062L15.1562 4.44531C15.3906 4.21094 15.8203 4.21094 16.0547 4.44531C16.2891 4.67969 16.2891 5.10938 16.0547 5.34375L10.8594 10.5L16.0547 15.6953C16.2891 15.9297 16.2891 16.3594 16.0547 16.5938Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default XIcon;
