import React from 'react';
import styled from 'styled-components';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import {
  GrayLine,
  SmallSphere,
} from 'app/src/pages/modals/publish/previews/static_preview_items/commonPreviewItems';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const PreviewContentGroup = () => {
  return (
    <LayoutRoot>
      <SmallSphere />
      <LinesContainer>
        <GrayLine />
        <GrayLine />
        <ShortLine />
      </LinesContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)``;

const LinesContainer = styled(VerticalFlex)`
  gap: 4px;
`;

const ShortLine = styled(GrayLine)`
  width: 30px;
`;

export default PreviewContentGroup;
