import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import Gap16HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap16HorizontalFlexWrap';
import AddPartIcon from 'app/src/images/addStepIcons/AddPartIcon';
import AddStepsTooltip from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/tolstoy_list/AddStepsTooltip';
import { TextBody } from 'shared/react/components/complex/Text';
import Menu from 'shared/react/components/basic/Menu';
import { BREAKPOINT_OPTIONS } from 'shared/react/constants/menu.constants';
import MacroFull from 'app/src/images/MacroFull';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import useAddStep from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/useAddStep';
import {
  ADD_MACRO_TILE_DATA_TEST_ID,
  ADD_STEP_RIGHT_MENU_TEST_ID,
  ADD_STEP_TEST_ID,
} from 'app/src/constants/dataTestIds.constants';
import ButtonWithRightSelect from 'app/src/complex_components/ButtonWithRightSelect';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { FEATURE_QUIZ_PRODUCTS_RESULT } from 'app/src/constants/appFeatures.constants';
import { useFeatures } from 'app/src/context/FeaturesStore';
import ShoppingBag from 'app/src/images/ShoppingBag';
import Utils from 'app/src/utils';
import AddFeedVideo from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/tolstoy_list/AddFeedVideo';
import { useApps } from 'app/src/context/AppsStore';

const AddSteps = ({
  project,
  onAddMacros,
  onAddProductResult,
  onAddNewPart,
  isCreatingNewResultPart,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [{ shopify }] = useApps();
  const theme = useTheme();
  const [, { getFeatureEnabled }] = useFeatures();

  const {
    isLocked,
    shouldHide,
    isQuizOptionDisabled,
    maxNumberOfPartsInTolstoy,
    quizProductsStepLimitPassed,
  } = useAddStep(project);

  const shouldNotShowQuizAddProductsStep =
    !shopify ||
    !Utils.isQuiz(project?.tolstoyType, 2) ||
    !getFeatureEnabled(FEATURE_QUIZ_PRODUCTS_RESULT) ||
    quizProductsStepLimitPassed;

  const addNewPartClicked = () => {
    if (isLocked) {
      return;
    }

    onAddNewPart();
  };

  const onExtraOptionsClicked = e => {
    if (isLocked && shouldNotShowQuizAddProductsStep) {
      return;
    }
    setAnchorEl(e.target);
  };

  const addMacroClicked = () => {
    if (isLocked) {
      return;
    }
    onAddMacros();
  };

  const addProductResultStepClicked = () => {
    onAddProductResult();
    setAnchorEl(null);
  };

  const getQuizResultsAddSteps = () => {
    if (shouldNotShowQuizAddProductsStep || shouldHide) {
      return null;
    }

    return (
      <LayoutRoot
        width={anchorEl?.clientWidth}
        onClick={addProductResultStepClicked}
        shouldHide={false}
      >
        <LeftContainer>
          <IconContainer>
            <ShoppingBag fill={theme.colors.success} />
          </IconContainer>
          <AddNewPartText>Add new result</AddNewPartText>
        </LeftContainer>
      </LayoutRoot>
    );
  };

  if (project.feed) {
    return <AddFeedVideo addNewPartClicked={addNewPartClicked} isLocked={isLocked} />;
  }

  return (
    <AddStepsTooltip
      isQuizOptionDisabled={isQuizOptionDisabled}
      isLocked={isLocked}
      maxNumberOfPartsInTolstoy={maxNumberOfPartsInTolstoy}
    >
      <div>
        <ButtonWithRightSelect
          onExtraOptionsClicked={onExtraOptionsClicked}
          isLeftButtonLocked={isLocked}
          isRightButtonLocked={isLocked && shouldNotShowQuizAddProductsStep}
          shouldHide={shouldHide}
          leftContainerTestId={ADD_STEP_TEST_ID}
          rightContainerTestId={ADD_STEP_RIGHT_MENU_TEST_ID}
          onLeftContainerClick={addNewPartClicked}
          leftText="Add new part"
          isLoading={isCreatingNewResultPart}
          icon={<AddPartIcon width={24} height={24} />}
          isOpen={!!anchorEl}
        />
      </div>
      <Menu
        onClose={() => setAnchorEl(null)}
        open={!!anchorEl}
        anchorEl={anchorEl}
        breakpoint={BREAKPOINT_OPTIONS.moveToTop}
      >
        <MenuContainer>
          {getQuizResultsAddSteps()}
          {!isLocked && (
            <LayoutRoot
              width={anchorEl?.clientWidth}
              onClick={addMacroClicked}
              shouldHide={shouldHide}
              data-test-id={ADD_MACRO_TILE_DATA_TEST_ID}
            >
              <LeftContainer isLocked={isLocked}>
                <IconContainer>
                  <MacroFull />
                </IconContainer>
                <AddNewPartText>Add macro</AddNewPartText>
              </LeftContainer>
            </LayoutRoot>
          )}
        </MenuContainer>
      </Menu>
    </AddStepsTooltip>
  );
};

const LayoutRoot = styled.div`
  display: ${({ shouldHide }) => (shouldHide ? 'none' : 'grid')};
  grid-template-columns: 1fr auto auto;
  background: ${({ theme }) => theme.colors.white};
  width: ${({ width }) => width}px;
  &:last-of-type {
    border-radius: 0 0 16px 16px;
  }
`;

const MenuContainer = styled(VerticalFlex)`
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
`;

const LeftContainer = styled(Gap16HorizontalFlexWrap)`
  padding: 8px 16px 8px 8px;
  align-items: center;
  cursor: ${({ isLocked }) => (isLocked ? '' : 'pointer')};

  & path {
    fill: ${({ theme, isLocked }) => (isLocked ? theme.colors.ghostLight : '')};
  }
`;

const AddNewPartText = styled(TextBody)`
  font-weight: 800;
`;

const IconContainer = styled(HorizontalFlexWrap)`
  width: 24px;
  height: 24px;
  align-content: center;
`;

export default AddSteps;
