import React from 'react';
import styled from 'styled-components';
import ExitIntentToggle from 'app/src/pages/project/pages/rules_and_triggers/editor/event_triggers/ExitIntentToggle';
import ShowAfter from 'app/src/pages/project/pages/rules_and_triggers/editor/event_triggers/ShowAfter';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import { BolderTextSmall } from 'shared/react/components/complex/Text';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import Restrictions from 'app/src/pages/project/pages/rules_and_triggers/editor/event_triggers/Restrictions';
import IosSwitch from 'app/src/basic_components/IosSwitch';
import { useRulesContext } from 'app/src/pages/project/pages/rules_and_triggers/RulesContext';
import { STOP_SHOWING_WIDGET_TO } from 'app/src/pages/project/pages/rules_and_triggers/Rule.constants';

const RulesEventTriggersEditor = () => {
  const {
    ruleGroup,
    createRestrictionRule,
    removeRestrictionRule,
    delayTriggerEnabled,
    exitIntent,
  } = useRulesContext();

  const tooltipText = 'You can only have one event trigger enabled at a time.';

  const restrictionRuleGroup = ruleGroup?.rules?.find(group => {
    for (const [, value] of Object.entries(STOP_SHOWING_WIDGET_TO)) {
      if (value === group[0].type) {
        return group;
      }
    }
  });
  const restrictionRule = restrictionRuleGroup?.[0];

  const onRestrictionClick = e => {
    const value = e.target.checked;
    if (value) {
      createRestrictionRule();
      return;
    }
    removeRestrictionRule();
  };

  return (
    <LayoutRoot>
      <Header>
        <HeaderText>Triggers</HeaderText>
      </Header>
      <Content>
        <ExitIntentToggle disabled={delayTriggerEnabled} tooltipText={tooltipText} />
        <ShowAfter disabled={exitIntent} tooltipText={tooltipText} />
      </Content>
      <Header>
        <HeaderText>Restrictions</HeaderText>
        <IosSwitch checked={!!restrictionRule} onChange={onRestrictionClick} />
      </Header>
      {!!restrictionRule && (
        <Content>
          <Restrictions restrictionRule={restrictionRule} />
        </Content>
      )}
    </LayoutRoot>
  );
};

export default RulesEventTriggersEditor;

const LayoutRoot = styled(Gap16VerticalFlex)`
  top: -8px;
  position: relative;
  padding: 8px 0px 16px 0;

  &:first-child {
    padding-top: 8px;
  }
`;

const Header = styled(HorizontalFlexWrap)`
  background: ${({ theme }) => theme.colors.gray1};
  padding: 16px;
  justify-content: space-between;
`;

const HeaderText = styled(BolderTextSmall)`
  font-weight: 800;
`;

const Content = styled(Gap16VerticalFlex)`
  padding: 0 16px;
`;
