import { ASSET_TYPE } from 'shared/react/services/assets';
import { defaultRef } from './constants';

export class VideoAssetRef {
  constructor(ref = defaultRef) {
    this.ref = ref;

    this.isImage = false;
    this.isGallery = false;
    this.isVideo = true;
    this.type = ASSET_TYPE.VIDEO;
  }

  get muted() {
    return this.ref.muted;
  }

  set muted(value) {
    this.ref.muted = value;
  }

  get currentTime() {
    return this.ref.currentTime;
  }

  set currentTime(value) {
    this.ref.currentTime = value;
  }

  get duration() {
    return this.ref.duration;
  }

  get paused() {
    return this.ref.paused;
  }

  async play() {
    return this.ref.play();
  }

  pause() {
    return this.ref.pause();
  }

  addEventListener(eventName, callback) {
    return this.ref.addEventListener(eventName, callback);
  }

  removeEventListener(eventName, callback) {
    return this.ref.removeEventListener(eventName, callback);
  }

  scrollIntoView() {
    return this.ref.scrollIntoView();
  }
}

export default VideoAssetRef;
