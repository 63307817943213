import React from 'react';
import styled, { useTheme } from 'styled-components';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import { TextSmall } from 'shared/react/components/complex/Text';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import Utils from 'app/src/utils';
import { OverlayIconButton } from 'app/src/basic_components/commonStyles';
import MirrorIcon from 'app/src/images/creation_method_icons/MirrorIcon';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import StopIcon from 'app/src/images/StopIcon';
import Gap16HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap16HorizontalFlexWrap';
import DeleteIcon from 'app/src/images/DeleteIcon';

const Controls = ({
  setMirror,
  mirror,
  startRecording,
  recording,
  countdownValue,
  stopRecording,
  canStopRecording,
  isRecordVideo,
  uploadState,
  cancelRecording,
}) => {
  const theme = useTheme();
  const onMirrorChange = () => {
    setMirror(!mirror);
  };

  const onRecordingClick = () => {
    if (recording) {
      stopRecording();
      return;
    }
    startRecording();
  };

  const isDisabled = !Utils.isNullOrUndefined(countdownValue) || !canStopRecording;

  if (uploadState) {
    return null;
  }

  const getMirrorButton = () => {
    if (recording || !Utils.isNullOrUndefined(countdownValue)) {
      return null;
    }

    return (
      <MirrorButton onClick={onMirrorChange}>
        <MirrorIcon />
        <MirrorSmallText>Mirror image</MirrorSmallText>
      </MirrorButton>
    );
  };

  const getRecordingText = () => {
    if (recording) {
      return 'Stop';
    }

    return 'Start recording';
  };

  const getRedCircleText = () => {
    if (recording) {
      return <StopIcon />;
    }

    return '';
  };

  return (
    <LayoutRoot>
      <MirrorContainer>{isRecordVideo && getMirrorButton()}</MirrorContainer>
      <CenterContainer>
        <RedCircleButtonContainer>
          <RedCircleContainer disabled={isDisabled} onClick={onRecordingClick}>
            <RedCircle>{getRedCircleText()}</RedCircle>
          </RedCircleContainer>
          <CircleText>{getRecordingText()}</CircleText>
        </RedCircleButtonContainer>
        <DiscardButton isHidden={!recording} onClick={cancelRecording}>
          <DeleteIcon fill={theme.colors.white} />
        </DiscardButton>
      </CenterContainer>
      <MirrorContainer />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlexWrap)`
  padding: 16px;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 1;
  position: relative;
  flex-wrap: nowrap;
`;

const CenterContainer = styled(Gap16HorizontalFlexWrap)`
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
`;

const MirrorContainer = styled(Gap8HorizontalFlexWrap)`
  width: 130px;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    width: 40px;
  }
`;

const MirrorSmallText = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.white};

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    display: none;
  }
`;

const MirrorButton = styled(OverlayIconButton)`
  width: 100%;
  border-radius: 100px;
  padding: 12px;
  display: flex;
  gap: 8px;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    width: unset;
  }
`;

const DiscardButton = styled(OverlayIconButton)`
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : '')};
  pointer-events: ${({ isHidden }) => (isHidden ? 'none' : '')};
  position: absolute;
  left: 100%;
  transition: 0.3s;

  &:hover {
    transform: scale(1.1);
  }
`;

const RedCircleButtonContainer = styled(Gap8VerticalFlex)`
  align-items: center;
  min-width: 96px;
`;

const CircleText = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.white};
  pointer-events: none;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    display: none;
  }
`;

const RedCircleContainer = styled(HorizontalFlexWrap)`
  width: 72px;
  height: 72px;
  background: ${({ theme }) => theme.getHexOpacity(theme.colors.danger, 50)};
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : '')};

  &:hover {
    transform: scale(1.1);
  }
`;

const RedCircle = styled(HorizontalFlexWrap)`
  width: 56px;
  height: 56px;
  border-radius: 100%;
  background: ${({ theme }) => theme.colors.danger};
  justify-content: center;
  align-items: center;
`;

export default Controls;
