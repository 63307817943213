import { useTranslationContext } from 'shared/react/components/complex/context/TranslationContext';
import en from 'shared/react/i18n/local/english.json';

const rtlLang = ['arabic', 'hebrew'];

export const getCssDirection = language => {
  return rtlLang.includes(language) ? 'rtl' : 'ltr';
};

const useTranslation = (prefixKey = '') => {
  const { translationObj, language } = useTranslationContext() || {};
  const fallbackTranslationObj = en;

  const translate = key => {
    const keys = key.split('.');
    let prefixKeys = prefixKey.split('.');
    if (prefixKeys.length === 1 && !prefixKeys[0]) {
      prefixKeys = [];
    }
    let text = translationObj;
    let fallbackText = fallbackTranslationObj;
    [...prefixKeys, ...keys].forEach(key => {
      text = text?.[key];
      fallbackText = fallbackText?.[key];
    });

    return text || fallbackText;
  };

  const actions = { t: translate };
  const direction = getCssDirection(language);

  return [{ direction }, actions];
};

export default useTranslation;
