import { useEffect, useState } from 'react';
import Utils from 'player/src/utils';

const useMediaQuery = (query, whenTrue = true, whenFalse = false) => {
  const [match, setMatch] = useState(false);

  useEffect(() => {
    if (typeof window === 'undefined' || typeof window.matchMedia === 'undefined') {
      return;
    }

    const mediaQuery = window.matchMedia(query);
    setMatch(!!mediaQuery.matches);

    const handler = () => setMatch(!!mediaQuery.matches);
    if (Utils.isSafari()) {
      mediaQuery.addListener(handler);
      return () => mediaQuery.removeListener(handler);
    }

    mediaQuery.addEventListener('change', handler);
    return () => mediaQuery.removeEventListener('change', handler);
  }, [query]);

  return match ? whenTrue : whenFalse;
};

export default useMediaQuery;
