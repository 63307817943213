import React from 'react';
import styled, { useTheme } from 'styled-components';
import VIcon from 'shared/react/images/VIcon';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

const BillingPackageCurrentPackage = ({ active }) => {
  const theme = useTheme();

  if (!active) {
    return null;
  }

  return (
    <StyledBillingPackageCurrentPackage>
      <IconContainer>
        <VIcon fill={theme.colors.blue7} width="12" height="12" />
      </IconContainer>
      Current plan
    </StyledBillingPackageCurrentPackage>
  );
};

export default BillingPackageCurrentPackage;

const StyledBillingPackageCurrentPackage = styled(Gap8HorizontalFlex)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black};
  border: 1px solid ${({ theme }) => theme.colors.gray35};
  border-radius: 20px;
  padding: 10px 28px;
  justify-content: center;
`;

const IconContainer = styled.div`
  width: 20px;
  height: 20px;
  display: grid;
  place-content: center;
  background: ${({ theme }) => theme.colors.primaryLight};
  border-radius: 20px;
  flex-shrink: 0;
`;
