import {
  ComparisonOperator,
  ComparatorEnum,
  SatisfiesWith,
} from 'src/pages/dashboard/utils/filterEntities';

export const getSelectedByKey = (filter: ComparisonOperator) => {
  const [comparator, value] = Object.entries(filter)[0];

  if (
    [ComparatorEnum.satisfies, ComparatorEnum.notSatisfies].includes(comparator as ComparatorEnum)
  ) {
    return (value as SatisfiesWith).args;
  }

  return value;
};

export const getComparatorText = (comparator: ComparatorEnum, filter: ComparisonOperator) => {
  const selectedItems = getSelectedByKey(filter);
  switch (comparator) {
    case ComparatorEnum.in:
    case ComparatorEnum.satisfies:
      return selectedItems?.length > 1 ? 'is any of' : 'is';
    case ComparatorEnum.notIn:
    case ComparatorEnum.notSatisfies:
      return 'is not';
    default:
      return comparator;
  }
};
