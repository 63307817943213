import React from 'react';
import styled from 'styled-components';
import Gap16HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap16HorizontalFlexWrap';
import { TextSubtitle } from 'shared/react/components/complex/Text';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import listItemStatesCss from 'shared/react/components/complex/css/listItemStatesCss';
import { creationFlowTitles } from 'app/src/pages/creation_flow/creationFlowConstants';

const CreationMethod = ({ Icon, name, selectedMethod, onMethodClick, dataTestId }) => {
  const isSelected = selectedMethod === name;

  return (
    <LayoutRoot
      data-test-id={dataTestId}
      isSelected={isSelected}
      onClick={() => onMethodClick(name)}
    >
      <IconContainer>
        <Icon />
      </IconContainer>
      <Text isSelected={isSelected}>{creationFlowTitles[name]}</Text>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16HorizontalFlexWrap)`
  align-items: center;
  cursor: ${({ isSelected }) => (isSelected ? 'default' : 'pointer')};
  padding: 8px 12px;
  border-radius: 8px;

  & path {
    fill: ${({ theme, isSelected }) => (isSelected ? theme.colors.primary : theme.colors.black)};

    &:hover {
      & path {
        fill: ${({ theme, isSelected }) =>
          isSelected ? theme.colors.primaryHover : theme.colors.black};
      }
    }
  }
  ${listItemStatesCss}
`;

const IconContainer = styled(HorizontalFlexWrap)`
  width: 16px;
  align-items: center;
  justify-content: center;
`;

const Text = styled(TextSubtitle)`
  color: ${({ theme, isSelected }) => (isSelected ? theme.colors.primary : theme.colors.black)};
  font-weight: ${({ isSelected }) => (isSelected ? 700 : 400)};
  transition: 0.3s;

  &:hover {
    color: ${({ theme, isSelected }) => !isSelected && theme.colors.black};
  }

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    font-size: 18px;
  }
`;

export default CreationMethod;
