import { WhereCondition } from 'app/src/pages/dashboard/utils/filterEntities';
import { TIKTOK } from 'shared/react/constants/intergrations.constants';
import { SOURCES_UPLOAD_TYPE } from 'app/src/constants/videoSources.constants.js';
import {
  DROPBOX,
  GOOGLE_DRIVE,
  INSTAGRAM_GRAPH,
  SYNTHESIA,
  YOUTUBE,
} from 'app/src/constants/intergrations.constants';
import {
  IMPORT_FROM_DROPBOX,
  IMPORT_FROM_GOOGLE_DRIVE,
  IMPORT_FROM_INSTAGRAM,
  IMPORT_FROM_SYNTHESIA,
  IMPORT_FROM_TIKTOK,
  IMPORT_FROM_YOUTUBE,
} from 'app/src/constants/creation_method.constants';

export enum MediaLibrarySource {
  INSTAGRAM = 'instagram',
  YOUTUBE = 'youtube',
  TIKTOK = 'tiktok',
  DROPBOX = 'dropbox',
  GOOGLE_DRIVE = 'google_drive',
  SYNTHESIA = 'synthesia',
}

export const MEDIA_SOURCE_IMPORT_METHODS = {
  [MediaLibrarySource.TIKTOK]: IMPORT_FROM_TIKTOK,
  [MediaLibrarySource.INSTAGRAM]: IMPORT_FROM_INSTAGRAM,
  [MediaLibrarySource.SYNTHESIA]: IMPORT_FROM_SYNTHESIA,
  [MediaLibrarySource.GOOGLE_DRIVE]: IMPORT_FROM_GOOGLE_DRIVE,
  [MediaLibrarySource.DROPBOX]: IMPORT_FROM_DROPBOX,
  [MediaLibrarySource.YOUTUBE]: IMPORT_FROM_YOUTUBE,
};

export const getMediaSource = (videosFilters: WhereCondition): MediaLibrarySource | null => {
  const currentVideoSource = videosFilters.uploadType?.in?.[0] as string;

  if (SOURCES_UPLOAD_TYPE[TIKTOK].includes(currentVideoSource)) {
    return MediaLibrarySource.TIKTOK;
  }

  if (SOURCES_UPLOAD_TYPE[INSTAGRAM_GRAPH].includes(currentVideoSource)) {
    return MediaLibrarySource.INSTAGRAM;
  }

  if (currentVideoSource === SYNTHESIA) {
    return MediaLibrarySource.SYNTHESIA;
  }

  if (currentVideoSource === YOUTUBE) {
    return MediaLibrarySource.YOUTUBE;
  }

  if (currentVideoSource === GOOGLE_DRIVE) {
    return MediaLibrarySource.GOOGLE_DRIVE;
  }

  if (currentVideoSource === DROPBOX) {
    return MediaLibrarySource.DROPBOX;
  }

  return null;
};
