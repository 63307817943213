import { get, set } from 'lodash';
import { VOD_CONNECTION_TYPES } from 'shared/react/constants/vodConnection.constants';

export const getTagsByVodAssetId = connections => {
  const connectionsMap = {};

  for (const { type, tag, vodAssetId, appUrl } of connections) {
    if (type !== VOD_CONNECTION_TYPES.tag) {
      continue;
    }

    const currentValue = get(connectionsMap, [vodAssetId, appUrl], []);
    set(connectionsMap, [vodAssetId, appUrl], [...currentValue, tag]);
  }

  return connectionsMap;
};

export const getLabelsByVodAssetId = connections => {
  const connectionsMap = {};

  for (const { type, vodLabelId, vodAssetId } of connections) {
    if (type !== VOD_CONNECTION_TYPES.vodLabelId) {
      continue;
    }

    const currentValue = get(connectionsMap, [vodAssetId], []);
    set(connectionsMap, [vodAssetId], [...currentValue, vodLabelId]);
  }

  return connectionsMap;
};

export const getVodAssetIdsByLabelId = connections => {
  const connectionsMap = {};

  for (const { type, vodLabelId, vodAssetId } of connections) {
    if (type !== VOD_CONNECTION_TYPES.vodLabelId) {
      continue;
    }

    const currentValue = get(connectionsMap, [vodLabelId], []);
    set(connectionsMap, [vodLabelId], [...currentValue, vodAssetId]);
  }

  return connectionsMap;
};

export const getProjectIdByVodAssetIdMap = connections => {
  const connectionsMap = {};

  for (const connection of connections) {
    const { type, vodAssetId } = connection;

    if (type !== VOD_CONNECTION_TYPES.vodProjectId) {
      continue;
    }

    const currentValue = get(connectionsMap, [vodAssetId], []);
    set(connectionsMap, [vodAssetId], [...currentValue, connection]);
  }

  return connectionsMap;
};

export const getProductsAndVariantsByVodAssetMap = (connections, selectedAppUrl) => {
  const productsByVodAssetMap = {};
  const vodAssetIdsByExternalProductId = {};
  const variantsByVodAssetMap = {};

  for (const connection of connections) {
    const { type, productId, vodAssetId, appUrl, variantIds, externalProductId } = connection;
    if (type !== VOD_CONNECTION_TYPES.productId) {
      continue;
    }

    if (appUrl !== selectedAppUrl) {
      continue;
    }

    const productsCurrentValue = get(productsByVodAssetMap, [vodAssetId, appUrl], []);
    set(productsByVodAssetMap, [vodAssetId, appUrl], [...productsCurrentValue, productId]);
    set(variantsByVodAssetMap, [vodAssetId, appUrl, productId], variantIds || []);

    const currentVodAssets = get(vodAssetIdsByExternalProductId, [externalProductId, appUrl], []);
    set(
      vodAssetIdsByExternalProductId,
      [externalProductId, appUrl],
      [...currentVodAssets, vodAssetId]
    );
  }

  return { productsByVodAssetMap, variantsByVodAssetMap, vodAssetIdsByExternalProductId };
};

export const getProductConnections = (connections, selectedAppUrl) => {
  return connections.flatMap(connection => {
    const { type, appUrl } = connection;
    if (type !== VOD_CONNECTION_TYPES.productId) {
      return [];
    }

    if (appUrl !== selectedAppUrl) {
      return [];
    }

    return connection;
  });
};

export const getDistinctNumberOfProducts = connections => {
  const productIds = connections.map(({ productId }) => productId);

  const distinctProductIds = [...new Set(productIds)];

  return distinctProductIds.length;
};

export const getNumberOfProductVideos = productsByVodAssetMap => {
  return Object.values(productsByVodAssetMap).length;
};

export const getVodConnectionTypeKey = ({ appKey, id }) => {
  return `${appKey}_${id}`;
};

export const getVodConnectionByProductId = ({ vodConnections, externalProductId, vodAssetId }) => {
  return vodConnections.find(
    connection =>
      connection.externalProductId === externalProductId && connection.vodAssetId === vodAssetId
  );
};

export const getVodConnectionByTag = ({ vodConnections, vodAssetId, appUrl, tag }) => {
  return vodConnections.find(
    connection =>
      connection.tag === tag && connection.vodAssetId === vodAssetId && connection.appUrl === appUrl
  );
};

export const getVodConnectionByVodLabelId = ({ vodConnections, vodAssetId, vodLabelId }) => {
  return vodConnections.find(
    connection => connection.vodAssetId === vodAssetId && connection.vodLabelId === vodLabelId
  );
};

export const getVodConnectionByVodAssetId = vodConnections => {
  return vodConnections.reduce((acc, connection) => {
    const currentValue = acc[connection.vodAssetId] || [];
    return {
      ...acc,
      [connection.vodAssetId]: [...currentValue, connection],
    };
  }, {});
};

export const getVodConnectionTags = vodConnections => {
  return vodConnections.reduce((acc, connection) => {
    if (!connection.tag) {
      return acc;
    }

    return acc.add(connection.tag);
  }, new Set());
};
