import React from 'react';
import styled, { css } from 'styled-components';
import ToggleSetting from 'app/src/complex_components/ToggleSetting';
import NotificationImpressionIcon from 'shared/react/images/notifications/NotificationImpressionIcon';
import NotificationPlayedIcon from 'shared/react/images/notifications/NotificationPlayedIcon';
import NotificationTextResponseIcon from 'shared/react/images/notifications/NotificationTextResponseIcon';
import NotificationCalendarInviteIcon from 'shared/react/images/notifications/NotificationCalendarInviteIcon';
import NotificationConversionIcon from 'shared/react/images/notifications/NotificationConversionIcon';
import NotificationAudioResponseIcon from 'shared/react/images/notifications/NotificationAudioResponseIcon';
import NotificationVideoResponseIcon from 'shared/react/images/notifications/NotificationVideoResponseIcon';
import { TextTiny } from 'shared/react/components/complex/Text';
import NotificationReachedEndIcon from 'shared/react/images/notifications/NotificationReachedEndIcon';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import NotificationInteractedIcon from 'shared/react/images/notifications/NotificationInteractedIcon';
import NotificationAnonymousIcon from 'shared/react/images/notifications/NotificationAnonymousIcon';
import Expander from 'shared/react/components/complex/expanders/Expander';

const IMPRESSION = 'view';
const PLAYED_TOLSTOY = 'start';
const INTERACTED = 'click';
const TEXT = 'text';
const AUDIO = 'audio';
const VIDEO = 'video';
const CALENDAR_INVITE = 'calendar';
const CONVERSION = 'conversion';
const END = 'end';
const SHOW_ANONYMOUS = 'showAnonymous';

function NotificationItem({
  supportedNotification,
  isEmailEnabled,
  isInAppEnabled,
  setEmailSettingProperty,
  setInAppSettingProperty,
}) {
  const { key, title, tooltipText } = supportedNotification;

  const getIcon = key => {
    const icons = {
      [IMPRESSION]: <NotificationImpressionIcon />,
      [INTERACTED]: <NotificationInteractedIcon />,
      [PLAYED_TOLSTOY]: <NotificationPlayedIcon />,
      [TEXT]: <NotificationTextResponseIcon />,
      [CALENDAR_INVITE]: <NotificationCalendarInviteIcon />,
      [CONVERSION]: <NotificationConversionIcon />,
      [AUDIO]: <NotificationAudioResponseIcon />,
      [VIDEO]: <NotificationVideoResponseIcon />,
      [END]: <NotificationReachedEndIcon />,
      [SHOW_ANONYMOUS]: <NotificationAnonymousIcon />,
    };

    return icons[key];
  };

  const getSubtitle = () => {
    if (isEmailEnabled && isInAppEnabled) {
      return 'Email, In-app';
    } else if (isEmailEnabled) {
      return 'Email';
    } else if (isInAppEnabled) {
      return 'In-app';
    }
    return 'None';
  };

  return (
    <Expander
      icon={getIcon(key)}
      title={title}
      subtitle={getSubtitle()}
      contentContainerStyle={css`
        padding: 8px 0 0 8px;
      `}
    >
      <NotificationItemDetails>
        <NotificationsShortExplanation>{tooltipText}</NotificationsShortExplanation>
        <ToggleSetting
          key={`EMAIL-${key}`}
          enabled={isEmailEnabled}
          onEnabledChange={enabled => setEmailSettingProperty(key, enabled)}
          title={'Email'}
        />
        <ToggleSetting
          key={`IN_APP-${key}`}
          enabled={isInAppEnabled}
          onEnabledChange={enabled => setInAppSettingProperty(key, enabled)}
          title={'In-app'}
        />
      </NotificationItemDetails>
    </Expander>
  );
}

export default NotificationItem;

const NotificationItemDetails = styled(Gap8VerticalFlex)`
  padding-left: 16px;
`;

const NotificationsShortExplanation = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.gray2};
`;
