import React, { FunctionComponent } from 'react';
import { Textarea } from '@tolstoy/ui-library/core';
import InputField from './InputField';

interface TextAreaProps {
  label: string;
  placeholder?: string;
  value: string | string[];
  onChange: (value: string | string[]) => void;
  splitLines?: boolean;
  maxLength?: number;
  disabled?: boolean;
}

const TextArea: FunctionComponent<TextAreaProps> = ({
  label,
  placeholder,
  value,
  onChange,
  splitLines,
  maxLength,
  disabled,
}) => (
  <InputField label={label}>
    <Textarea
      placeholder={placeholder}
      value={Array.isArray(value) ? value.join('\n') : value}
      onChange={e => {
        const value = e.target.value ?? '';
        onChange(splitLines ? value.split('\n') : value);
      }}
      maxLength={maxLength}
      disabled={disabled}
    />
  </InputField>
);

export default TextArea;
