import { getIsTapcart, getTapcartDeviceId } from '../utils/tapcart.utils.js';
import { TOLSTOY_CACHE_KEYS } from '../constants/cache.constants.js';
import {
  getFromCache,
  getFromLocalStorage,
  getFromSessionStorage,
  removeFromSessionStorage,
  setLocalStorage,
  setToCache,
  setToSessionStorage,
  uuidv4
} from '../utils/cache.utils.js';
import { Keys } from '../widgets/keys.js';

const {
  TOLSTOY_HIDE_WIDGET_STORAGE_KEY,
  TOLSTOY_SESSION_ID_KEY,
  TOLSTOY_SESSION_UNIQUE_ID_KEY,
  TOLSTOY_PRODUCT_RECOMMENDATIONS,
  TOLSTOY_ANONYMOUS_ID,
  TOLSTOY_REPLY,
  TOLSTOY_GROUP,
  TOLSTOY_PUBLISH_ID,
  TOLSTOY_ACTIVITY_KEY,
  TOLSTOY_NOT_VISIBLE_OPENED_PUBLISH_ID_KEY,
  TOLSTOY_INTERACTION_DATE,
  IS_SHOPIFY_CART_ANONYMOUS_ID_SET_KEY,
  TOLSTOY_RECREATE_RESOLUTIONS_PREFIX,
  TOLSTOY_AB_TEST_GROUP
} = TOLSTOY_CACHE_KEYS;

function getInteractionDateKey(appKey) {
  return appKey ? `${TOLSTOY_INTERACTION_DATE}-${appKey}` : TOLSTOY_INTERACTION_DATE;
}

export function getAnonymousId() {
  return getFromCache(TOLSTOY_ANONYMOUS_ID);
}

export function setAnonymousId(anonymousId) {
  if (!anonymousId || ['undefined', 'false'].includes(anonymousId)) {
    return;
  }

  setToCache(TOLSTOY_ANONYMOUS_ID, anonymousId);
}

/**
 * Get anonymousId from cache or generate a new one
 * For Tapcart, get the device id and use it as anonymousId.
 * The deviceId is also use in tapcartSubscriber.utils.js to link the cart to the user
 * @returns {string} anonymousId
 */
export function getAndSetAnonymousId() {
  const tapcartDeviceId = getIsTapcart() ? getTapcartDeviceId() : null;

  let anonymousId = tapcartDeviceId || getAnonymousId() || uuidv4();
  if (anonymousId === 'false') {
    anonymousId = uuidv4();
  }

  setAnonymousId(anonymousId);

  return anonymousId;
}

export function getTestGroup() {
  return getFromCache(TOLSTOY_GROUP);
}

export function setTestGroup(group) {
  setToCache(TOLSTOY_GROUP, group, true);
}

export function getPublishId() {
  return getFromCache(TOLSTOY_PUBLISH_ID);
}

export function setPublishId(publishId) {
  setToCache(TOLSTOY_PUBLISH_ID, publishId, true);
}

export function getTolstoysActivity() {
  return JSON.parse(getFromSessionStorage(TOLSTOY_ACTIVITY_KEY) || '{}');
}

export function pushTolstoyActivity({ publishId, timeStamp, type, sessionId }) {
  const tolstoysActivity = getTolstoysActivity() || {};
  const tolstoyActivity = tolstoysActivity[publishId] || [];
  tolstoysActivity[publishId] = [...tolstoyActivity, { timeStamp, type, sessionId }];
  setToSessionStorage(TOLSTOY_ACTIVITY_KEY, JSON.stringify(tolstoysActivity), true);
}

export const pushTolstoyViewedProducts = ({ publishId, productId }) => {
  const viewedProducts = getTolstoyViewedProducts();
  const product = viewedProducts[productId] || [];
  const data = { interactionDate: new Date().toISOString(), publishId, productId };
  product.push(data);
  viewedProducts[productId] = product;
  setTolstoyViewedProducts(viewedProducts);
};

export const clearTolstoyActivity = () => {
  setToSessionStorage(TOLSTOY_ACTIVITY_KEY, '{}', true);
};

export function getIsReply() {
  return getFromCache(TOLSTOY_REPLY);
}

export function setIsReply(publishId) {
  setToCache(TOLSTOY_REPLY, publishId);
}

export function setTolstoyProductRecommendation() {
  setToCache(TOLSTOY_PRODUCT_RECOMMENDATIONS, true);
}

export function getTolstoyProductRecommendation() {
  return getFromCache(TOLSTOY_PRODUCT_RECOMMENDATIONS);
}

export const getTolstoyViewers = () => {
  return JSON.parse(getFromLocalStorage(Keys.TOLSTOY_VIEWERS_KEY) || '{}');
};

export const getTolstoyAbTest = () => {
  const tolstoyViewers = getTolstoyViewers();
  return tolstoyViewers.abTest || {};
};

export const setTolstoyAbTest = abTest => {
  const tolstoyViewers = getTolstoyViewers();
  tolstoyViewers.abTest = abTest;
  setTolstoyViewers(tolstoyViewers);
};

export const getTolstoyViewedProducts = () => {
  return JSON.parse(getFromCache(Keys.TOLSTOY_VIEWED_PRODUCTS) || '{}');
};

export const setTolstoyViewedProducts = viewedProducts => {
  setToCache(Keys.TOLSTOY_VIEWED_PRODUCTS, JSON.stringify(viewedProducts));
};

export const setTolstoyViewers = tolstoyViewers => {
  setLocalStorage(Keys.TOLSTOY_VIEWERS_KEY, JSON.stringify(tolstoyViewers));
};

export function getTolstoyInteractionDate(appKey) {
  return getFromCache(getInteractionDateKey(appKey)) || getFromCache(getInteractionDateKey());
}

export function setIdentifyInfoId(id) {
  setLocalStorage(Keys.IDENTIFY_INFO_ID_KEY, id);
}

export function getIdentifyInfoId() {
  return getFromLocalStorage(Keys.IDENTIFY_INFO_ID_KEY);
}

export function removeIdentifyInfoId() {
  localStorage.removeItem(Keys.IDENTIFY_INFO_ID_KEY);
}

export const setSessionCounter = count => {
  setLocalStorage(Keys.SESSION_COUNTER_KEY, count);
};

export const getSessionCounter = () => {
  return Number(getFromLocalStorage(Keys.SESSION_COUNTER_KEY));
};

export const setFirstSeenAt = date => {
  setLocalStorage(Keys.FIRST_SEEN_AT_KEY, date);
};

export const getFirstSeenAt = () => {
  return getFromLocalStorage(Keys.FIRST_SEEN_AT_KEY);
};

export function setTolstoyInteractionDate(appKey) {
  const date = new Date().toISOString();
  setToCache(getInteractionDateKey(appKey), date);
}

export const getTolstoyHideWidget = widgetId => {
  return getFromSessionStorage(`${TOLSTOY_HIDE_WIDGET_STORAGE_KEY}-${widgetId}`);
};

export const setTolstoyHideWidget = widgetId => {
  return setToSessionStorage(`${TOLSTOY_HIDE_WIDGET_STORAGE_KEY}-${widgetId}`, 'true', true);
};

export const removeTolstoyHideWidget = widgetId => {
  return window.sessionStorage.removeItem(`${TOLSTOY_HIDE_WIDGET_STORAGE_KEY}-${widgetId}`);
};

export const getSessionId = () => {
  return getFromSessionStorage(TOLSTOY_SESSION_ID_KEY);
};

export const setSessionId = sessionId => {
  return setToSessionStorage(TOLSTOY_SESSION_ID_KEY, sessionId, true);
};

export const removeSessionId = () => {
  return removeFromSessionStorage(TOLSTOY_SESSION_ID_KEY);
};

export const getSessionUniqueId = () => {
  const uuid = getFromSessionStorage(TOLSTOY_SESSION_UNIQUE_ID_KEY);
  if (uuid) {
    return uuid;
  }

  return setSessionUniqueId();
};

export const setSessionUniqueId = () => {
  const uuid = uuidv4();
  setToSessionStorage(TOLSTOY_SESSION_UNIQUE_ID_KEY, uuid, true);
  return uuid;
};

export const getNotVisibleOpenedTolstoyPublishId = () => {
  const value = getFromSessionStorage(TOLSTOY_NOT_VISIBLE_OPENED_PUBLISH_ID_KEY);
  if (!value || value === 'null') {
    return null;
  }

  return value;
};

export const setNotVisibileOpenedTolstoyPublishId = publishId => {
  return setToSessionStorage(TOLSTOY_NOT_VISIBLE_OPENED_PUBLISH_ID_KEY, publishId, true);
};

export const updateTolstoyActivitySessionIdByPublishId = (publishId, sessionId) => {
  const tolstoysActivity = getTolstoysActivity() || {};
  const activities = tolstoysActivity[publishId] || [];

  if (!activities?.length) {
    return;
  }

  const lastActivity = activities[activities.length - 1];
  lastActivity.sessionId = sessionId;

  setToSessionStorage(TOLSTOY_ACTIVITY_KEY, JSON.stringify(tolstoysActivity), true);
};

export const getIsShopifyCartAnonymousIdSet = () => {
  return getFromSessionStorage(IS_SHOPIFY_CART_ANONYMOUS_ID_SET_KEY);
};

export const setIsShopifyCartAnonymousIdSet = value => {
  return setToSessionStorage(IS_SHOPIFY_CART_ANONYMOUS_ID_SET_KEY, value);
};

export const setTolstoyRecreateResolution = publishId => {
  return setLocalStorage(`${TOLSTOY_RECREATE_RESOLUTIONS_PREFIX}-${publishId}`, 'true', true);
};

export const getTolstoyRecreateResolution = publishId => {
  return getFromLocalStorage(`${TOLSTOY_RECREATE_RESOLUTIONS_PREFIX}-${publishId}`);
};

export const setShowNotificationBadge = (widgetId, value) => {
  return setLocalStorage(`${Keys.NOTIFICATION_BADGE_PREFIX}-${widgetId}`, value, true);
};

export const getShowNotificationBadge = widgetId => {
  return getFromLocalStorage(`${Keys.NOTIFICATION_BADGE_PREFIX}-${widgetId}`);
};

export const setShowBubbleText = (widgetId, bubbleText, value) => {
  return setLocalStorage(`${Keys.BUBBLE_TEXT_PREFIX}-${widgetId}-${bubbleText}`, value, true);
};

export const getShowBubbleText = (widgetId, bubbleText) => {
  return getFromLocalStorage(`${Keys.BUBBLE_TEXT_PREFIX}-${widgetId}-${bubbleText}`);
};

export const setRecreateNewResolution = (publishId, value) => {
  return setLocalStorage(`${Keys.RECREATE_NEW_RESOLUTION_PREFIX}-${publishId}`, value, true);
};

export const getRecreateNewResolution = publishId => {
  return getFromLocalStorage(`${Keys.RECREATE_NEW_RESOLUTION_PREFIX}-${publishId}`);
};

export const setAbTestGroup = (abTestId, group) => {
  return setLocalStorage(`${TOLSTOY_AB_TEST_GROUP}-${abTestId}`, group, true);
};

export const getAbTestGroup = abTestId => {
  return getFromLocalStorage(`${TOLSTOY_AB_TEST_GROUP}-${abTestId}`);
};
