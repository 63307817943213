import React from 'react';

const StockVideoIcon = () => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.61.75c.976 0 1.874.586 2.265 1.523l1.64 3.633c.157.313.235.664.235 1.016v8.828c0 1.406-1.133 2.5-2.5 2.5H2.75a2.468 2.468 0 0 1-2.5-2.5V6.922c0-.352.04-.703.195-1.016l1.64-3.633A2.456 2.456 0 0 1 4.353.75h9.257Zm0 1.25H9.624v3.75h6.445l-1.328-3.008A1.251 1.251 0 0 0 13.61 2Zm2.89 5h-15v8.75c0 .703.547 1.25 1.25 1.25h12.5c.664 0 1.25-.547 1.25-1.25V7ZM1.89 5.75h6.485V2H4.352c-.508 0-.938.313-1.133.742L1.89 5.75Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default StockVideoIcon;
