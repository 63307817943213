import React from 'react';

const DownloadIcon = () => (
  <svg width={12} height={13} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5 7.625H9.234L8.11 8.75h2.39c.188 0 .375.188.375.375V11c0 .21-.188.375-.375.375h-9A.37.37 0 0 1 1.125 11V9.125c0-.188.164-.375.375-.375h2.367L2.742 7.625H1.5c-.844 0-1.5.68-1.5 1.5V11c0 .844.656 1.5 1.5 1.5h9c.82 0 1.5-.656 1.5-1.5V9.125c0-.82-.68-1.5-1.5-1.5Zm-.375 2.438a.57.57 0 0 0-.563-.563.555.555 0 0 0-.562.563c0 .328.234.562.563.562a.555.555 0 0 0 .562-.563Zm-4.523-.915c.093.118.234.165.398.165.14 0 .281-.047.375-.165l3.188-3.187a.513.513 0 0 0 0-.774.513.513 0 0 0-.774 0L6.563 7.415V1.063A.57.57 0 0 0 6 .5a.555.555 0 0 0-.563.563v6.351l-2.25-2.226a.513.513 0 0 0-.773 0 .513.513 0 0 0 0 .773l3.188 3.187Z"
      fill="#50545E"
    />
  </svg>
);

export default DownloadIcon;
