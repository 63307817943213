import React from 'react';

const Unarchive = ({ color }) => {
  return (
    <svg width="18" height="16" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.65833 5V5.05H8.70833H10.7126L7.5 8.26262L4.28738 5.05H6.29167H6.34167V5V2.55H8.65833V5ZM13.3333 13.3833H13.3833V13.3333V10.8333V10.7833H13.3333H10.3833H10.3547L10.3402 10.808C9.7652 11.7855 8.72263 12.45 7.50833 12.45C6.29385 12.45 5.24317 11.7854 4.67659 10.8083L4.66214 10.7833H4.63333H1.66667H1.61667V10.8333V13.3333V13.3833H1.66667H13.3333ZM13.3333 9.21667H13.3833V9.16667V1.66667V1.61667H13.3333H1.65833H1.60828L1.60833 1.66672L1.61667 9.16672L1.61672 9.21667H1.66667H5.79239C5.81908 10.1381 6.58077 10.8833 7.50833 10.8833C8.43589 10.8833 9.19759 10.1381 9.22428 9.21667H13.3333ZM1.65833 0.05H13.3333C14.2224 0.05 14.95 0.777614 14.95 1.66667V13.3333C14.95 14.2224 14.2224 14.95 13.3333 14.95H1.65833C0.761436 14.95 0.05 14.2229 0.05 13.3333V1.66667C0.05 0.777123 0.761436 0.05 1.65833 0.05Z"
        fill={color}
        stroke="#E2EDFE"
        strokeWidth="0.1"
      />
    </svg>
  );
};

export default Unarchive;
