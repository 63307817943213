import { PLAYER_EVENTS, PLAYER_EVENT_NAMES } from 'player-events';
import { REACT_APP_GOOGLE_ANALYTICS_KEY } from 'player/src/config/player.config';

const loadGAScript = () => {
  if (window.gtag || window.dataLayer) {
    return;
  }

  const googleAnalyticsLoader = document.createElement('script');
  googleAnalyticsLoader.src = `https://www.googletagmanager.com/gtag/js?id=${REACT_APP_GOOGLE_ANALYTICS_KEY}`;
  document.head.appendChild(googleAnalyticsLoader);

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  window.gtag = gtag;
  window.gtag('js', new Date());
  window.gtag('config', REACT_APP_GOOGLE_ANALYTICS_KEY);
};

const sendGoogleAnalyticsEvent = ({ eventName, text, playlist, collectInfoType }) => {
  if (!window.gtag) {
    return;
  }

  if (eventName === PLAYER_EVENTS.sessionStart) {
    sendCustomGoogleEvent(playlist, 'click', 'Start Tolstoy');
    sendCustomGoogleEvent(playlist, PLAYER_EVENT_NAMES.sessionStart);
  } else if (eventName === PLAYER_EVENTS.clickCta) {
    sendCustomGoogleEvent(playlist, 'click', text);
    sendCustomGoogleEvent(playlist, PLAYER_EVENT_NAMES.clickCta, text);
  } else if (eventName === PLAYER_EVENTS.submitInput) {
    sendCustomGoogleEvent(playlist, PLAYER_EVENT_NAMES.submitInput);
  } else if (eventName === PLAYER_EVENTS.collectInfo) {
    sendCustomGoogleEvent(playlist, PLAYER_EVENT_NAMES.collectInfo, collectInfoType);
  } else if (eventName === PLAYER_EVENTS.videoResponse) {
    sendCustomGoogleEvent(playlist, PLAYER_EVENT_NAMES.videoResponse);
  } else if (eventName === PLAYER_EVENTS.imageResponse) {
    sendCustomGoogleEvent(playlist, PLAYER_EVENT_NAMES.imageResponse);
  } else if (eventName === PLAYER_EVENTS.audioResponse) {
    sendCustomGoogleEvent(playlist, PLAYER_EVENT_NAMES.audioResponse);
  } else if (eventName === PLAYER_EVENTS.sessionEnd) {
    sendCustomGoogleEvent(playlist, PLAYER_EVENT_NAMES.sessionEnd);
  }
};

const sendCustomGoogleEvent = (playlist, eventAction, eventLabel) => {
  const eventCategory = `tolstoy-${playlist}`;
  window.gtag('event', eventAction, {
    event_category: eventCategory,
    event_label: eventLabel,
  });
};

const initGoogleAnalytics = googleAnalyticsID => {
  const gaID = googleAnalyticsID;
  if (!gaID || !window.gtag) {
    return;
  }
  window.gtag('config', gaID, {
    campaign_name: 'clientTracker',
    cookie_domain: 'auto',
    cookie_flags: 'max-age=7200;secure;samesite=none',
  });
  window.gtag('event', 'tolstoyPageView');
};

export { initGoogleAnalytics, loadGAScript, sendGoogleAnalyticsEvent };
