import React from 'react';
import styled, { useTheme } from 'styled-components';

function CollapsingToolbarArrowRight({ fill, width = 7, height = 11, rotationDegrees }) {
  const theme = useTheme();

  return (
    <Container rotationDegrees={rotationDegrees}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 7 11"
      >
        <path
          fill={fill || theme.colors.black}
          d="M1.898.625a.56.56 0 00-.796-.023.56.56 0 00-.024.796l3.938 4.125-3.938 4.102a.56.56 0 00.024.797c.21.21.585.21.796-.024l4.313-4.5c.094-.117.164-.257.164-.375 0-.14-.07-.28-.164-.398L1.898.625z"
        />
      </svg>
    </Container>
  );
}

export default CollapsingToolbarArrowRight;

const Container = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  width: 14px;
  height: 14px;
  transition: 0.3s;
  ${({ rotationDegrees }) =>
    rotationDegrees
      ? `
          transform: rotate(${rotationDegrees}deg);
        `
      : null}
`;
