import React from 'react';
import styled from 'styled-components';
import BackArrowIcon from 'shared/react/images/BackArrowIcon';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import useKeyboardNavigation from 'player/src/hooks/useKeyboardNavigation';

const FeedBackButton = props => {
  const { onKeyDown, setNextRef } = useKeyboardNavigation();

  return (
    <BackContainer
      {...props}
      role="button"
      aria-label="Back button"
      tabIndex="0"
      onKeyDown={onKeyDown}
      ref={setNextRef}
    >
      <BackArrowIcon />
    </BackContainer>
  );
};

const BackContainer = styled(HorizontalFlex)`
  justify-content: flex-start;
  padding: 16px 16px 8px;

  & svg {
    cursor: pointer;
  }
`;

export default FeedBackButton;
