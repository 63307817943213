import React, { useState } from 'react';
import styled from 'styled-components';
import MacroFull from 'app/src/images/MacroFull';
import CircularLinkIcon from 'app/src/images/dashboard_v2/CircularLinkIcon';
import { track } from 'app/src/helpers/Tracker';
import Utils from 'app/src/utils';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';
import VerticalMenuIcon from 'app/src/images/dashboard_v2/VerticalMenuIcon';
import IconButton from 'app/src/complex_components/IconButton';
import ProjectCardMenu from 'app/src/pages/dashboard/pages/libraries/tolstoys_tab/project_card_v2/ProjectCardMenu';
import { useVideoAssets } from 'app/src/hooks/useVideoAssets';
import Tooltip from 'app/src/basic_components/Tooltip';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import useHasResourcePermissions from 'app/src/hooks/useHasResourcePermissions';
import CardImageContainer from 'app/src/pages/dashboard/pages/libraries/CardImageContainer';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { useAccount } from 'app/src/context/AccountStore';
import { TextTiny } from 'shared/react/components/complex/Text';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';
import usePlayerUrl from 'app/src/hooks/usePlayerUrl';

const ProjectCardImageContainer = ({
  project,
  selectProject,
  isFeed,
  folder,
  isModalMode,
  ...props
}) => {
  const [, { setSnackbar }] = useSnackBar();
  const { largeProjectImage } = useVideoAssets(project);
  const [{ isAccountOwner }] = useAccount();
  const { playerUrl } = usePlayerUrl(project?.id);

  const [isMenuOpen, setMenuOpen] = useState(null);

  const handleCopyLink = e => {
    e.stopPropagation();
    track('Copy Sharable Link Click', { location: 'Project Card' });
    Utils.copyToClipboard(playerUrl);
    setSnackbar('Link Copied to Clipboard');
  };

  const handleMenuOpened = e => {
    track('Project Card Menu Opened');
    e.stopPropagation();
    setMenuOpen(e.currentTarget);
  };

  const showMenu = !isModalMode;
  const isMacro = project.macro;
  const hasMenuPermissions = useHasResourcePermissions(project, isAccountOwner);
  return (
    <LayoutRoot>
      {isMacro && (
        <IconContainer>
          <Tooltip text="Saved as macro" placement="right">
            <MacroIconContainer>
              <MacroFull />
            </MacroIconContainer>
          </Tooltip>
        </IconContainer>
      )}
      {folder && (
        <FolderContainer>
          <EllipsisTextOverflowWrapper>
            <Tooltip text={folder.name} placement="top">
              <FolderName>{folder.name}</FolderName>
            </Tooltip>
          </EllipsisTextOverflowWrapper>
        </FolderContainer>
      )}
      <CardImageContainer {...props} selectCard={selectProject} imageSrc={largeProjectImage}>
        {showMenu && (
          <ButtonsContainer isMenuOpen={isMenuOpen}>
            {hasMenuPermissions && (
              <StyledIconButton onClick={handleMenuOpened}>
                <VerticalMenuIcon />
              </StyledIconButton>
            )}
            <StyledIconButton onClick={handleCopyLink}>
              <CircularLinkIcon />
            </StyledIconButton>
          </ButtonsContainer>
        )}
      </CardImageContainer>
      {hasMenuPermissions && (
        <ProjectCardMenu
          isFeed={isFeed}
          project={project}
          isMenuOpen={isMenuOpen}
          setMenuOpen={setMenuOpen}
        />
      )}
    </LayoutRoot>
  );
};

export default ProjectCardImageContainer;

const LayoutRoot = styled.div`
  display: grid;
  position: relative;
  width: 100%;
  height: 100%;
`;

const MacroIconContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 8px;
`;

const IconContainer = styled(HorizontalFlexWrap)`
  align-items: center;
  border-radius: 50%;
  position: absolute;
  box-shadow: 0 2px 4px rgba(50, 50, 93, 0.15);
  right: 12px;
  bottom: -16px;
  z-index: 1;
`;

const FolderContainer = styled(IconContainer)`
  border-radius: 6px;
  left: 8px;
  bottom: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  max-width: 120px;
  width: max-content;
`;

const ButtonsContainer = styled(Gap8VerticalFlex)`
  margin: 8px;
  grid-row: 1;
  grid-column: 1;
  align-items: flex-end;
  transition: 0.25s;
`;

const StyledIconButton = styled(IconButton)`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    background-color: ${({ theme }) => theme.colors.ghostLight};
  }

  &:active {
    background-color: ${({ theme }) => theme.getHexOpacity(theme.colors.ghostLight, 90)};
  }
`;

const FolderName = styled(TextTiny)`
  padding: 5px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;
