import React from 'react';
import styled from 'styled-components';

import Utils from 'src/utils';
import { track } from 'src/helpers/Tracker';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextSmallBold, TextSmall, TextTiny } from 'shared/react/components/basic/text/TextV2';
import HorizontalFlex, {
  Gap16HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import FilledHeartIcon from 'app/src/images/social/FilledHeartIcon';
import FilledCommentsIcon from 'app/src/images/social/FilledCommentsIcon';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';
import { PrimaryButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { VideosPageVodAsset } from 'app/src/pages/dashboard/pages/videos/types/videos.types';
import Tooltip from 'app/src/basic_components/tooltip-v2/Tooltip';
import {
  UGC_INSTAGRAM_GRAPH,
  UGC_INSTAGRAM_GRAPH_PENDING,
} from 'app/src/constants/intergrations.constants';
import { FEATURE_DYNAMIC_FEED } from 'shared/react/constants/features.constants';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import { useAppActions } from 'app/src/context/AppsStore';
import { ADD_PRODUCTS_MODAL_KEY, VIDEO_PREVIEW_MODAL_KEY } from 'src/constants/modals.constants';
import { useModalActions } from 'app/src/context/ui_store/ModalStore';
import { formatUgcItemDate, isAssetImported } from '../../utils';
import UgcMediaPreview from './UgcMediaPreview';
import UgcMediaStatusLabel from './UgcMediaStatusLabel';

type Props = {
  asset?: VideosPageVodAsset;
  defaultHashtag: string;
  onViewPostClick: (asset: VideosPageVodAsset) => void;
};
export const UgcMediaItem = ({ asset, defaultHashtag, onViewPostClick }: Props) => {
  const { getFeatureEnabled } = useFeatureActions();
  const { getProductsCatalogApp } = useAppActions();
  const { setCurrentId, setCurrentModal, setCurrentIds } = useModalActions();
  const isApproved = asset?.uploadType === UGC_INSTAGRAM_GRAPH;
  const isPending = asset?.uploadType === UGC_INSTAGRAM_GRAPH_PENDING;
  const {
    commentsCount: savedCommentCount,
    likeCount: saveLikeCount,
    ugcHashtag = '',
  } = Utils.safeParse(asset.externalProviderData);
  const isDynamicEnabled = getFeatureEnabled(FEATURE_DYNAMIC_FEED);
  const productsCatalogApp = getProductsCatalogApp();
  const isProductsTaggingEnabled = isDynamicEnabled && productsCatalogApp;

  const onApprovedClick = () => {
    if (!isApproved) {
      return handleViewPostClick();
    }

    if (isProductsTaggingEnabled) {
      track('Video Card Add Products Click');
      setCurrentIds([asset.id]);
      setCurrentModal(ADD_PRODUCTS_MODAL_KEY);
      return;
    }

    track('Video Card Click');
    setCurrentId(asset.id);
    setCurrentModal(VIDEO_PREVIEW_MODAL_KEY);
  };

  const handleViewPostClick = () => {
    track(`UGC ${isPending ? 'View Post' : 'Get Content'} Click`);
    onViewPostClick(asset);
  };

  const getButton = () => {
    if (isAssetImported(asset.uploadType)) {
      return <UgcMediaStatusLabel uploadType={asset.uploadType} />;
    }

    return <Button onClick={handleViewPostClick}>Request usage rights</Button>;
  };

  const currentLikeCount = saveLikeCount || asset.likeCount;
  const currentCommentCount = savedCommentCount || asset.commentsCount;

  return (
    <LayoutRoot onClick={onApprovedClick} isApproved={isApproved}>
      <UgcMediaPreview
        hashtag={ugcHashtag || defaultHashtag}
        asset={asset}
        isPending={isPending}
        isApproved={isApproved}
      />
      <Content>
        {!!asset.name && (
          <Tooltip text={asset.name}>
            <EllipsisTextOverflowWrapper>
              <Title>{asset.name}</Title>
            </EllipsisTextOverflowWrapper>
          </Tooltip>
        )}
        {!!asset.createdAt && (
          <EllipsisTextOverflowWrapper>
            <CreatedTime>{formatUgcItemDate(asset.createdAt)}</CreatedTime>
          </EllipsisTextOverflowWrapper>
        )}
        <SocialContainer>
          {currentLikeCount && (
            <LikesContainer>
              <FilledHeartIcon />
              <TextTiny>{currentLikeCount}</TextTiny>
            </LikesContainer>
          )}
          {!!currentCommentCount && (
            <LikesContainer>
              <FilledCommentsIcon />
              <TextTiny>{currentCommentCount}</TextTiny>
            </LikesContainer>
          )}
        </SocialContainer>
        {getButton()}
      </Content>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  border-radius: 4px;
  cursor: ${({ isApproved }) => (isApproved ? 'pointer' : '')};
  overflow: hidden;
  height: 270px;
`;

const Content = styled(VerticalFlex)`
  padding: 16px 16px 10px;
  gap: 12px;
  justify-content: space-between;
  height: 100%;
`;

const Title = styled(TextSmallBold)`
  height: 18px;
`;

const CreatedTime = styled(TextSmall)`
  color: #909193;
`;

const SocialContainer = styled(Gap16HorizontalFlex)``;

const Button = styled(PrimaryButton)`
  visibility: hidden;
  margin-top: auto;
  padding: 7px 12px;

  ${LayoutRoot}:hover & {
    visibility: visible;
  }
`;

const LikesContainer = styled(HorizontalFlex)`
  gap: 4px;
`;

export default UgcMediaItem;
