import React from 'react';

const VideoContainIcon = () => {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.648.547a.663.663 0 0 0-.21-.047H8.062a.555.555 0 0 0-.562.563c0 .328.234.562.563.562h2.015L6.914 4.813a.513.513 0 0 0 0 .773.514.514 0 0 0 .774 0l3.187-3.164v2.015c0 .329.234.563.563.563A.555.555 0 0 0 12 4.437V1.063c0-.07-.023-.14-.047-.21a.64.64 0 0 0-.305-.305ZM.328 12.477c.07.023.14.023.234.023h3.375a.555.555 0 0 0 .563-.563.57.57 0 0 0-.563-.562H1.898l3.165-3.164a.513.513 0 0 0 0-.774.513.513 0 0 0-.774 0l-3.164 3.165v-2.04A.57.57 0 0 0 .562 8 .555.555 0 0 0 0 8.563v3.374c0 .094 0 .165.023.235a.65.65 0 0 0 .305.305Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default VideoContainIcon;
