import React, { useEffect } from 'react';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';
import FeedProductActionButton from 'shared/react/components/complex/feed/feed_products/FeedProductActionButton';
import FeedBuyNowButton from 'shared/react/components/complex/feed/feed_products/feed_buy_now_button/FeedBuyNowButton';
import FeedCtaButton from 'shared/react/components/complex/feed/feed_products/feed_cta_button/FeedCtaButton';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { FEED_SECONDARY_BUTTON_TEST_ID } from 'shared/react/constants/dataTestIds.constants';
import {
  DEFAULT_QUANTITY,
  PRIMARY_DARK_BUTTON_TYPE,
  SECONDARY_BUTTON_TYPE,
} from 'shared/react/constants/feed.constants';
import styled, { css } from 'styled-components';
import { useIsQuickShop } from 'shared/react/hooks/useIsFenty';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';
import Utils from 'shared/react/utils/utils';
import { FEED_BUTTON_TEXT_DATA_TYPE } from 'shared/react/constants/feedPreviewDataTypes.constants';
import { getDesignCss } from 'shared/react/utils/addSharedCss';

const FeedProductButtons = ({ product, currentStepKey, selectedVariant }) => {
  const [{ pdpConfig, dynamic: isDynamic }] = useProjectConfig();
  const {
    appUrl,
    handle: productHandle,
    ecomPlatform,
    currencySymbol,
    id: externalProductId,
    title,
  } = product;
  const { id: selectedVariantId, isVariantAvailableForSale, price } = selectedVariant || {};
  const [quantity, setQuantity] = React.useState(DEFAULT_QUANTITY);
  const hasPdpConfig = !!pdpConfig;
  const isQuickShopEnabled = useIsQuickShop(product);
  const { feedDesign } = useDesignSettingsContext();

  useEffect(() => {
    if (quantity !== DEFAULT_QUANTITY) {
      setQuantity(DEFAULT_QUANTITY);
    }
  }, [product.id]);

  const renderCtaButton = (buttonConfig, buttonType, design) => (
    <StyledFeedCtaButton
      selectedVariantId={selectedVariantId}
      externalProductId={externalProductId}
      isVariantAvailableForSale={isVariantAvailableForSale}
      appUrl={appUrl}
      currentStepKey={currentStepKey}
      productHandle={productHandle}
      ecomPlatform={ecomPlatform}
      productId={product.id}
      buttonConfig={buttonConfig}
      buttonType={buttonType}
      quantityPickerState={{ quantity, setQuantity }}
      currencySymbol={currencySymbol}
      price={price}
      isQuickShopEnabled={isQuickShopEnabled}
      productTitle={title}
      $design={design}
    />
  );

  if (isDynamic && hasPdpConfig) {
    return (
      <ButtonsContainer>
        {renderCtaButton(
          pdpConfig.primaryButton,
          PRIMARY_DARK_BUTTON_TYPE,
          feedDesign?.inFeedPDP?.primaryCtaButton
        )}
        {renderCtaButton(
          pdpConfig.secondaryButton,
          SECONDARY_BUTTON_TYPE,
          feedDesign?.inFeedPDP?.secondaryCtaButton
        )}
      </ButtonsContainer>
    );
  }

  return (
    <ButtonsContainer>
      <StyledFeedByNowButton
        selectedVariantId={selectedVariantId}
        externalProductId={externalProductId}
        isVariantAvailableForSale={isVariantAvailableForSale}
        isDisabled={!isVariantAvailableForSale}
        appUrl={appUrl}
        currentStepKey={currentStepKey}
        productHandle={productHandle}
        ecomPlatform={ecomPlatform}
        productId={product.id}
        quantityPickerState={{ quantity, setQuantity }}
        currencySymbol={currencySymbol}
        price={price}
        productTitle={title}
        $design={feedDesign?.inFeedPDP?.primaryCtaButton}
      />
      <StyledFeedProductActionButton
        currentStepKey={currentStepKey}
        dataTestId={FEED_SECONDARY_BUTTON_TEST_ID}
        productTitle={title}
        $design={feedDesign?.inFeedPDP?.secondaryCtaButton}
      />
    </ButtonsContainer>
  );
};

const ButtonsContainer = styled(Gap8VerticalFlex)`
  gap: 12px;
`;

const commonCss = css`
  ${({ $design }) => getDesignCss($design)};
  & ${Utils.getDataType(FEED_BUTTON_TEXT_DATA_TYPE)} {
    ${({ $design: { font, fontSize, fontWeight, fontColor } = {} }) => {
      return css`
        font-family: ${font};
        font-size: ${fontSize}px;
        font-weight: ${fontWeight};
        color: ${fontColor};
      `;
    }}`;

const StyledFeedByNowButton = styled(FeedBuyNowButton)`
  ${commonCss}
`;

const StyledFeedProductActionButton = styled(FeedProductActionButton)`
  ${commonCss}
`;

const StyledFeedCtaButton = styled(FeedCtaButton)`
  ${commonCss}
`;

export default FeedProductButtons;
