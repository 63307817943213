import React from 'react';
import { PrimaryButton, WhiteButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import styled from 'styled-components';

type Props = {
  isSaveLoading: boolean;
  isDiscardDisabled: boolean;
  isSaveDisabled: boolean;
  onDiscard: () => void;
  onSave: () => void;
  saveButtonDataTestId?: string;
};

const ModalFooterButtons = ({
  isSaveLoading,
  isDiscardDisabled,
  isSaveDisabled,
  onDiscard,
  onSave,
  saveButtonDataTestId,
}: Props) => {
  return (
    <LayoutRoot>
      <WhiteButton disabled={isDiscardDisabled} onClick={onDiscard}>
        Discard
      </WhiteButton>
      <PrimaryButton
        data-test-id={saveButtonDataTestId}
        disabled={isSaveDisabled}
        isLoading={isSaveLoading}
        onClick={onSave}
      >
        Save
      </PrimaryButton>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlex)`
  justify-content: flex-end;
`;

export default ModalFooterButtons;
