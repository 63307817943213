import React from 'react';

const AudioIcon = ({ width = 22, height = 22, iconFill = '#F40000', circleFill = '#FFFBFB' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11" cy="11" r="11" fill={circleFill} />
      <path
        d="M11.2951 15.0947C8.92688 15.0947 7 13.0581 7 10.5548C7 10.2871 7.20527 10.0703 7.45832 10.0703C7.71151 10.0703 7.91663 10.2873 7.91663 10.5548C7.91678 12.5238 9.43223 14.1258 11.2951 14.1258C13.1578 14.1258 14.6734 12.5238 14.6734 10.5549C14.6734 10.2873 14.8785 10.0705 15.1317 10.0705C15.3849 10.0705 15.59 10.2874 15.59 10.5549C15.5901 13.0581 13.6633 15.0947 11.2951 15.0947Z"
        fill={iconFill}
      />
      <path
        d="M11.2954 13.4947C9.7743 13.4947 8.52979 12.1792 8.52979 10.5715V6.92323C8.52979 5.31545 9.7743 4 11.2954 4C12.8164 4 14.0609 5.31545 14.0609 6.92323V10.5713C14.0609 12.1791 12.8164 13.4947 11.2954 13.4947Z"
        fill={iconFill}
      />
      <path
        d="M11.2947 17.9452C11.0416 17.9452 10.8364 17.7284 10.8364 17.4607V14.7315C10.8364 14.4639 11.0417 14.2471 11.2947 14.2471C11.5479 14.2471 11.7531 14.4639 11.7531 14.7315V17.4607C11.7531 17.7284 11.5479 17.9452 11.2947 17.9452Z"
        fill={iconFill}
      />
      <path
        d="M12.8406 18.0705H9.74933C9.49614 18.0705 9.29102 17.8536 9.29102 17.586C9.29102 17.3184 9.49629 17.1016 9.74933 17.1016H12.8404C13.0936 17.1016 13.2987 17.3184 13.2987 17.586C13.2987 17.8536 13.0938 18.0705 12.8406 18.0705Z"
        fill={iconFill}
      />
    </svg>
  );
};

export default AudioIcon;
