import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import IconButton from 'app/src/complex_components/IconButton';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import { sensitiveDataFilter } from 'shared/react/components/basic/SensitiveData';

export const CardContainer = styled(VerticalFlex)`
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  cursor: pointer;
`;

export const CardImageWrapper = styled.div`
  flex-shrink: 0;
  position: relative;
  display: grid;
  overflow: hidden;
  height: 130px;
`;

export const CardControlsContainer = styled(Gap8HorizontalFlexWrap)`
  justify-content: space-between;
  align-items: flex-start;
  padding: 8px;
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};
  transition: 0.25s;
  background: ${({ theme }) => theme.getHexOpacity(theme.colors.black, 40)};
  z-index: 1;

  ${CardContainer}:hover & {
    opacity: 1;
  }
`;

export const ContextMenuIcon = styled(IconButton)`
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.getHexOpacity(theme.colors.black, 10)};
  border-radius: 6px;
  padding: 0;

  & path {
    fill: ${({ theme }) => theme.colors.white};
  }

  &:hover {
    background-color: ${({ theme }) => theme.getHexOpacity(theme.colors.black, 20)};
  }

  &:active {
    background-color: ${({ theme }) => theme.getHexOpacity(theme.colors.ghostLight, 90)};
  }
`;

export const CardDetails = styled(VerticalFlex)`
  gap: 12px;
  position: relative;
  padding: 16px;
  flex-grow: 1;
  overflow: hidden;
`;

export const CardDetailsText = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  ${sensitiveDataFilter()};
`;

export const CardCreatedAt = styled(CardDetailsText)`
  color: ${({ theme }) => theme.colors.ghostDark};
`;

export const CardName = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.black};
`;
