import React from 'react';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import LineClampWrapper from 'shared/react/components/basic/LineClampWrapper';
import { useDrawerActions } from 'src/context/ui_store/DrawerStore';
import {
  GlobalDrawerType,
  SetCurrentDrawerArgs,
} from 'src/complex_components/global_drawer/types/globalDrawer.types';
import { sensitiveDataFilter } from 'shared/react/components/basic/SensitiveData';

type Props = {
  email: string;
};

const AnalyticsTableEmailCell = ({ email }: Props) => {
  const { setCurrentDrawer } = useDrawerActions();

  const onClick = () => {
    const drawerArgs = {
      currentDrawer: GlobalDrawerType.Journey,
      drawerProps: { email },
    } as SetCurrentDrawerArgs;

    setCurrentDrawer(drawerArgs);
  };

  return (
    <LayoutRoot onClick={onClick}>
      <LineClampWrapper>{email}</LineClampWrapper>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  line-height: 16px;
  transition: color 0.2s ease-in-out;
  ${sensitiveDataFilter()}

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export default AnalyticsTableEmailCell;
