import { VideosPageVodAsset } from 'app/src/pages/dashboard/pages/videos/types/videos.types';
import { useVodConnection } from 'app/src/context/VodConnectionStore';
import { useEffect, useMemo, useRef } from 'react';
import { useProducts } from 'app/src/context/ProductsStore';

export const useVideoCardProducts = (video: VideosPageVodAsset) => {
  const [
    { initialized: connectionsInitialized, vodConnections, selectedAppUrl },
    { getProductsByVodAssetId },
  ] = useVodConnection();
  const [{ loadingProducts, products }, { getProductsDebounced }] = useProducts();
  const productsLoadingRef = useRef(null);
  // This reference is used to track if vodConnection storage state is changed.
  // If it's changed we need to update connected products.
  const vodConnectionReference = vodConnections;

  // Cache loading state per card. Cause loadingProducts sets to true if any product is loading.
  if (connectionsInitialized && productsLoadingRef.current === true && !loadingProducts) {
    productsLoadingRef.current = false;
  }

  const productIds = useMemo(() => {
    return getProductsByVodAssetId(video.id, selectedAppUrl);
  }, [getProductsByVodAssetId, selectedAppUrl, video.id, vodConnectionReference]);

  useEffect(() => {
    if (connectionsInitialized && productIds.length) {
      getProductsDebounced(productIds);
      productsLoadingRef.current = true;
    }
  }, [
    connectionsInitialized,
    getProductsByVodAssetId,
    getProductsDebounced,
    productIds,
    selectedAppUrl,
    video.id,
  ]);

  const { product, extraProductsCount } = useMemo(() => {
    if (!connectionsInitialized) {
      return { product: null, extraProductsCount: 0 };
    }

    const foundProducts = productIds.map((id: string) => products[id]).filter(product => !!product);
    return {
      product: foundProducts[0] || null,
      extraProductsCount: Math.max(foundProducts.length - 1, 0),
    };
  }, [connectionsInitialized, productIds, products]);

  return {
    loading: !connectionsInitialized || productsLoadingRef.current,
    product,
    extraProductsCount,
  };
};
