import React from 'react';

const VideoIcon = ({ fill, darkBackground }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0015 19.7221C15.371 19.7221 19.7238 15.3693 19.7238 9.99984C19.7238 4.63039 15.371 0.277588 10.0015 0.277588C4.6321 0.277588 0.279297 4.63039 0.279297 9.99984C0.279297 15.3693 4.6321 19.7221 10.0015 19.7221Z"
        fill={fill || '#FFFFFF'}
      />
      <path
        d="M12.3535 6.677C12.3535 6.08455 11.8522 5.58325 11.2597 5.58325H4.69727C4.08203 5.58325 3.60352 6.08455 3.60352 6.677V13.2395C3.60352 13.8547 4.08203 14.3333 4.69727 14.3333H11.2597C11.8522 14.3333 12.3535 13.8547 12.3535 13.2395V6.677ZM16.7285 7.04159C16.7285 6.47193 16.0449 6.13013 15.5664 6.44913L13.0827 8.18091V11.7584L15.5664 13.4901C16.0449 13.8091 16.7285 13.4674 16.7285 12.8977V7.04159Z"
        fill={darkBackground ? '#FFFFFF' : '#2F3137'}
      />
    </svg>
  );
};

export default VideoIcon;
