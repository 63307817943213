import { ONSITE_TARGET_PAGES } from 'app/src/pages/dashboard/constants/onsiteTargetPages.constants';
import { CAMPAIGN_TILE_ACTIONS } from 'app/src/constants/campaignTileActions.constants';
import { cases } from 'app/src/pages/dashboard/components/cases/cases.constants';

export const GIF_ACTION_OPTIONS = [
  {
    id: ONSITE_TARGET_PAGES.ProductPages,
    name: 'Product Pages',
    tileAction: CAMPAIGN_TILE_ACTIONS.openProductPage,
    useCaseNames: [cases.EMAIL_ABANDONMENT_REVIVAL.name, cases.EMAIL_PRODUCT_LAUNCH.name],
  },
  {
    id: ONSITE_TARGET_PAGES.SpecificPage,
    name: 'Specific Page',
    tileAction: CAMPAIGN_TILE_ACTIONS.openSpecificPage,
    useCaseNames: [cases.EMAIL_TRENDING.name, cases.EMAIL_PRODUCT_LAUNCH.name],
  },
  {
    id: ONSITE_TARGET_PAGES.TvPages,
    name: 'New TV page',
    tileAction: CAMPAIGN_TILE_ACTIONS.openTolstoyTvPage,
    useCaseNames: [cases.EMAIL_PRODUCT_LAUNCH.name, cases.EMAIL_TRENDING.name],
  },
];
