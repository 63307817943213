import { getAndSetAnonymousId } from '../common/cache.js';
import { PLAYER_URL } from '../config/widget.config.js';
import { IN_TILE_CAROUSEL_EMBED_WIDGET, URL_PARAMETERS } from '../constants/widgetConstants.js';
import { FEATURE_PLAYER_VERSION } from '../widgets/Modal/constants/features.constants.js';
import { getPlayerOrigin } from './feature.utils.js';
import { getUrlCustomParams } from './url.utils.js';
import { getIsTapcart } from './tapcart.utils.js';

export const getIframeBaseUrl = (data, isFeed) => {
  const playerOrigin = getPlayerOrigin(data);

  if (playerOrigin) {
    const debug = getSafeParameter('td');
    const playerLazy = getPlayerLazy(data);

    if (debug && playerLazy) {
      // lazy & debug
      return playerOrigin.replace('2.0.0', '2.0.0-ls');
    }

    if (playerLazy) {
      // lazy
      return playerOrigin.replace('2.0.0', '2.0.0-l');
    }

    if (debug) {
      // debug
      return playerOrigin.replace('2.0.0', '2.0.0-s');
    }

    return playerOrigin;
  }

  let playerUrl = `${PLAYER_URL}`;

  if (isFeed) {
    playerUrl += '/feed';
  }

  if (data.publishId) {
    playerUrl += `/${data.publishId}`;
  }

  return playerUrl;
};

const isShopifyStore = () => {
  return Boolean(window.Shopify);
};

export const getSafeParameter = parameter => {
  const urlSearchParamsSupported = 'URLSearchParams' in window;
  if (!urlSearchParamsSupported) {
    return null;
  }

  try {
    const searchParams = new URLSearchParams(window.location?.search);
    return searchParams.get(parameter);
  } catch (error) {
    if (error) {
      return null;
    }
  }
};

const getPlayerLazy = config => {
  return config.playerLazy || getSafeParameter('pl');
};

const getPlayerVersion = config => {
  return config?.featureSettings?.[FEATURE_PLAYER_VERSION]?.version;
};

const getPlayerVariant = config => {
  if (config?.carouselEmbed?.carouselPlayInTileFirst) {
    return IN_TILE_CAROUSEL_EMBED_WIDGET;
  }

  return '';
};

export const getCustomerId = () => {
  return window._st?.cid || window.ShopifyAnalytics?.meta?.page?.customerId || '';
};

export const getCustomerAuthToken = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get('authToken');
};

export const getEmailFromParams = () => {
  return window.tolstoyUserInfo?.email || window.tolstoyUser;
};

export const getTolstoyAutoOpen = () => {
  return getSafeParameter(URL_PARAMETERS.tolstoyAutoOpen) || window.tolstoyAutoOpen;
};

export const getTolstoyStartVideo = () => {
  return getSafeParameter(URL_PARAMETERS.tolstoyStartVideo) || window.tolstoyStartVideo;
};

/* eslint-disable complexity */
export const getIframeSource = ({ data, isFeed, email, isDynamic, modalId, tolstoyStartVideo }) => {
  const parentUrl = `${window.location.origin}${window.location.pathname}`.slice(0, 1999);
  const shopifyStoreUrl = window.Shopify?.shop || '';
  const shopifyStore = Boolean(isShopifyStore() && shopifyStoreUrl);
  let source = `${getIframeBaseUrl(data, isFeed)}?host&url=${parentUrl}&safari=${Boolean(
    window?.safari
  )}&dontDownload=${Boolean(data.dontDownload)}&playerType=${
    data.playerType
  }&isShopifyStore=${shopifyStore}&shopifyStoreUrl=${shopifyStoreUrl}&modalId=${modalId}&ai=${getAndSetAnonymousId()}`;

  if (getCustomerAuthToken()) {
    source += `&authToken=${getCustomerAuthToken()}`;
  }

  if (isShopifyStore()) {
    const shopifyRootRoute = window.Shopify.routes?.root;
    if (shopifyRootRoute) {
      source += `&shopifyRootRoute=${shopifyRootRoute}`;
    }
  }

  if (window.tolstoyUserInfo?.name) {
    source += `&name=${window.tolstoyUserInfo?.name}`;
  }

  if (window.tolstoyUserInfo?.phone) {
    source += `&phone=${window.tolstoyUserInfo?.phone}`;
  }

  if (tolstoyStartVideo) {
    source += `&tolstoyStartVideo=${tolstoyStartVideo}`;
  }

  const emailValue = email || getEmailFromParams();
  if (emailValue) {
    source += `&email=${emailValue}`;
  }

  if (data.productId) {
    source += `&productId=${data.productId}`;
  }

  if (data.currentProductDbId) {
    source += `&currentPageDbProductId=${data.currentProductDbId}`;
  }

  if (data.testGroup) {
    source += `&group=${data.testGroup}`;
  }

  if (data.sneakpeek) {
    source += `&sneakpeek`;
  }

  if (data.sneakpeek_hidden) {
    source += `&sneakpeek_hidden`;
  }

  if (data.token) {
    source += `&token=${data.token}`;
  }

  if (data.gclid) {
    source += `&gclid=${data.gclid}`;
  }

  if (data.widgetDelay) {
    source += `&delay`;
  }

  if (data.abTestId) {
    source += `&abTestId=${data.abTestId}`;
  }

  if (data.isShoppable === false) {
    source += `&isShoppable=${data?.isShoppable}`;
  }

  if (data.si) {
    source += `&si=${data.si}`;
  }

  const debug = getSafeParameter('td');
  if (debug) {
    source += `&td=${debug}`;
  }

  const lazy = getPlayerLazy(data);
  if (lazy) {
    source += `&pl=${lazy}`;
  }

  const playerVersion = getPlayerVersion(data);
  if (playerVersion) {
    source += `&pv=${playerVersion}`;
  }

  const playerVariant = getPlayerVariant(data);
  if (playerVariant) {
    source += `&playerVariant=${playerVariant}`;
  }

  if (isDynamic) {
    source += '&isDynamic=true';
  }

  const customerId = getCustomerId();
  if (customerId) {
    source += `&customerId=${customerId}`;
  }

  const urlCustomParams = getUrlCustomParams();
  if (urlCustomParams.size > 0) {
    source += `&${urlCustomParams.toString()}`;
  }

  const isTapcart = getIsTapcart();
  if (isTapcart) {
    source += `&isTapcart=true`;
  }

  if (getPlayerOrigin(data)) {
    if (isFeed) {
      source += `&isFeed=true`;
    }

    if (data.publishId) {
      source += `&publishId=${data.publishId}`;
    }
  }

  return source;
};
/* eslint-enable complexity */
