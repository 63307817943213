import AnalyticsEngagementEmptyState from 'app/src/complex_components/analytics/empty/AnalyticsEngagementEmptyState';
import React from 'react';
import AnalyticsVideosTable from 'app/src/complex_components/analytics/videos/AnalyticsVideosTable';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import AnalyticsVideosMetrics from './AnalyticsVideosMetrics';

type Props = {
  shouldBlur: boolean;
};

const AnalyticsVideos = ({ shouldBlur }: Props) => {
  if (shouldBlur) {
    return <AnalyticsEngagementEmptyState />;
  }

  return (
    <Gap8VerticalFlex>
      <AnalyticsVideosMetrics />
      <AnalyticsVideosTable />
    </Gap8VerticalFlex>
  );
};

export default AnalyticsVideos;
