import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ColorPalettePopoverHeader from './components/ColorPalettePopoverHeader';
import ColorPaletteList from './components/ColorPaletteList';
import HexColorPicker from './components/HexColorPicker';
import { MODE, DEFAULT_COLOR } from './ColorPalettePopover.constants';
import MenuWithContainer from 'shared/react/components/basic/MenuWithContainer';
import { BREAKPOINT_OPTIONS } from 'shared/react/constants/menu.constants';

export interface ColorPalettePopoverProps {
  open: boolean;
  palette: {
    label: string;
    value: string;
  }[];
  anchorEl: Element;
  onChange?: (color: string) => void;
  onClose?: () => void;
  value?: string;
}

const ColorPalettePopover = ({
  open,
  palette = [],
  anchorEl,
  onChange = () => {},
  onClose = () => {},
  value,
}: ColorPalettePopoverProps) => {
  const [mode, setMode] = useState(palette.length ? MODE.PALETTE : MODE.CUSTOM);
  const [color, setColor] = useState(DEFAULT_COLOR);
  const isPaletteMode = mode === MODE.PALETTE;
  const hasPalette = palette.length > 0;

  const onAddCustomClick = () => {
    setMode(MODE.CUSTOM);
  };

  const onBackToBrandColorsClick = () => {
    setMode(MODE.PALETTE);
  };

  const handleClose = () => {
    if (!isPaletteMode) {
      onChange(color);
    }

    onClose();
  };

  useEffect(() => {
    setColor(value);
  }, [value]);

  useEffect(() => {
    const newMode = palette.length ? MODE.PALETTE : MODE.CUSTOM;

    if (mode !== newMode) {
      setMode(newMode);
    }
  }, [palette]);

  return (
    <Menu
      hasPalette={hasPalette}
      isPaletteMode={isPaletteMode}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      breakpoint={BREAKPOINT_OPTIONS.moveToTop}
    >
      {hasPalette && (
        <ColorPalettePopoverHeader
          mode={mode}
          onAddCustom={onAddCustomClick}
          onBackToBrandColors={onBackToBrandColorsClick}
        />
      )}
      {isPaletteMode ? (
        <ColorPaletteList value={value} palette={palette} onChange={onChange} onClose={onClose} />
      ) : (
        <HexColorPicker value={value} color={color} onChange={setColor} />
      )}
    </Menu>
  );
};

const Menu = styled(MenuWithContainer)`
  height: ${({ isPaletteMode, hasPalette }) =>
    isPaletteMode ? 'auto' : hasPalette ? '436px' : '396px'};
  width: 336px;
  padding: 8px;
`;

export default ColorPalettePopover;
