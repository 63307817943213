import React from 'react';
import Utils from '../../../utils';
import TeamMemberText from './TeamMemberText';

const getFormattedDate = date => {
  if (!date) {
    return '';
  }

  const dateObject = new Date(date);
  return `${dateObject.getDate()} ${Utils.getShortMonthName(
    dateObject
  )} ${dateObject.getFullYear()}`;
};

const TeamMemberDate = ({ date }) => {
  const formattedDate = getFormattedDate(date);
  return <TeamMemberText text={formattedDate} />;
};

export default TeamMemberDate;
