import React from 'react';
import UploadIcon from 'app/src/images/dashboard_v2/UploadIcon';
import {
  IMPORT_FROM_INSTAGRAM,
  IMPORT_FROM_TIKTOK,
  LIBRARY,
  RECORD_SCREEN,
  RECORD_VIDEO,
  STOCK_VIDEOS,
  TOLSTOY_LIBRARY,
  UPLOAD_FILE,
} from 'app/src/constants/creation_method.constants';
import TiktokIcon from 'app/src/images/dashboard_v2/TiktokIcon';
import CameraIcon from 'app/src/images/dashboard_v2/CameraIcon';
import DesktopIcon from 'app/src/images/dashboard_v2/DesktopIcon';
import { CREATION_FLOW_MODAL_KEY } from 'app/src/constants/modals.constants';
import { useModalActions } from 'app/src/context/ui_store/ModalStore';
import { useExtensionActions } from 'app/src/context/ui_store/ExtensionStore';
import InstagramIcon from 'app/src/images/InstagramIcon';
import VideosIcon from 'app/src/images/sidebar-v2/VideosIcon';
import TemplateIcon from 'app/src/images/creation_method_icons/TemplateIcon';
import StockVideoIcon from 'app/src/images/sidebar-v2/StockVideoIcon';
import {
  getDuplicateSource,
  SOURCES_UPLOAD_TYPE,
} from 'app/src/constants/videoSources.constants.js';
import { INSTAGRAM_GRAPH, TIKTOK } from 'app/src/constants/intergrations.constants';
import { STOCK_VIDEOS_TAB_TEST_ID } from 'app/src/constants/dataTestIds.constants';

type Props = {
  videoFolder?: string;
  toggleMenu?: (event: React.MouseEvent<HTMLElement>) => void;
  extraProps?: any;
  tiktokText?: string;
  redirectToVideos?: boolean;
};

const useGetCreateVideoMenuItems = ({
  videoFolder,
  toggleMenu,
  extraProps,
  tiktokText = 'Import from URL',
  redirectToVideos = false,
}: Props) => {
  const { setCurrentModal, setModalProps } = useModalActions();
  const { openModalOrExtension } = useExtensionActions();

  const getRedirectionProps = (selectedMethod: string, source: string | null) => {
    let defaultVideoFilterProps;
    let defaultSelectedMethod = selectedMethod;
    const currentUploadType = SOURCES_UPLOAD_TYPE[source];
    if (currentUploadType && redirectToVideos) {
      defaultSelectedMethod = LIBRARY;
      const duplicatedUploadTypes = currentUploadType?.map(getDuplicateSource);
      const uploadType = [...currentUploadType, ...duplicatedUploadTypes];
      defaultVideoFilterProps = { uploadType: { in: uploadType }, name: { contains: '' } };
    }

    return { defaultSelectedMethod, defaultVideoFilterProps };
  };

  const onMenuItemClick = (selectedMethod: string, source: string | null) => () => {
    const { defaultSelectedMethod, defaultVideoFilterProps } = getRedirectionProps(
      selectedMethod,
      source
    );

    setModalProps({
      isLeftPanelVisible: false,
      shouldCreateProject: false,
      videoFolder,
      ...extraProps,
      defaultVideoFilterProps,
      defaultSelectedMethod,
    });

    setCurrentModal(CREATION_FLOW_MODAL_KEY);
    toggleMenu?.(null);
  };

  const onScreenRecording = () => {
    const onNotChrome = onMenuItemClick(RECORD_SCREEN, null);
    openModalOrExtension('App Record Screen', {}, onNotChrome);
  };

  const uploadFileItem = {
    text: 'Upload file',
    icon: <UploadIcon />,
    onClick: onMenuItemClick(UPLOAD_FILE, null),
  };

  const tiktokItem = {
    text: tiktokText,
    icon: <TiktokIcon />,
    onClick: onMenuItemClick(IMPORT_FROM_TIKTOK, TIKTOK),
  };

  const instagramItem = {
    text: 'Instagram',
    icon: <InstagramIcon />,
    onClick: onMenuItemClick(LIBRARY, INSTAGRAM_GRAPH),
  };

  const videoLibraryItem = {
    text: 'Video Library',
    icon: <VideosIcon />,
    onClick: onMenuItemClick(LIBRARY, null),
  };

  const stockVideoItem = {
    text: 'Stock videos',
    icon: <StockVideoIcon />,
    onClick: onMenuItemClick(STOCK_VIDEOS, null),
    dataTestId: STOCK_VIDEOS_TAB_TEST_ID,
  };

  const tolstoyItem = {
    text: 'Tolstoy Library',
    icon: <TemplateIcon />,
    onClick: onMenuItemClick(TOLSTOY_LIBRARY, null),
  };

  const recordVideoItem = {
    text: 'Record video',
    icon: <CameraIcon />,
    onClick: onMenuItemClick(RECORD_VIDEO, null),
  };

  const recordScreenItem = {
    text: 'Record screen',
    icon: <DesktopIcon />,
    onClick: onScreenRecording,
  };

  const recordingsItems = [recordVideoItem, recordScreenItem];

  const creationCategories = {
    [LIBRARY]: videoLibraryItem,
    [TOLSTOY_LIBRARY]: tolstoyItem,
    [STOCK_VIDEOS]: stockVideoItem,
    [RECORD_VIDEO]: recordVideoItem,
    [UPLOAD_FILE]: uploadFileItem,
    [RECORD_SCREEN]: recordScreenItem,
    [IMPORT_FROM_TIKTOK]: tiktokItem,
    [IMPORT_FROM_INSTAGRAM]: instagramItem,
    ['separator']: { separator: true },
  };

  const getCustomItems = customMethods => {
    const items = [];
    customMethods.forEach(categoryName => {
      if (creationCategories[categoryName]) {
        items.push(creationCategories[categoryName]);
      }
    });
    return items;
  };

  return {
    uploadFileItem,
    tiktokItem,
    recordingsItems,
    instagramItem,
    videoLibraryItem,
    stockVideoItem,
    tolstoyItem,
    getCustomItems,
  };
};

export default useGetCreateVideoMenuItems;
