import React from 'react';

const MacroIcon = ({ fill = '#50545E', background = '#F4F5F7' }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="16" fill={background} />
      <path
        d="M22 9.5C22 8.6875 21.3125 8 20.5 8H11.5C10.6562 8 10 8.6875 10 9.5V24L16 20.5L22 24V9.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default MacroIcon;
