import React from 'react';
import { useTheme } from 'styled-components';

function GoToVideoResponseIcon({ fill, ...props }) {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="12"
      fill="none"
      viewBox="0 0 18 12"
      {...props}
    >
      <path
        fill={fill || theme.colors.ghostDark}
        d="M12 1.5v9a1.5 1.5 0 01-1.5 1.5h-9A1.48 1.48 0 010 10.5v-9A1.5 1.5 0 011.5 0h9c.813 0 1.5.688 1.5 1.5zm6 .5v8.031c0 .781-.938 1.25-1.594.813L13 8.469V3.563l3.406-2.376C17.063.75 18 1.22 18 2z"
      ></path>
    </svg>
  );
}

export default GoToVideoResponseIcon;
