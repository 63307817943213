import { BASE_URL } from 'app/src/config/app.config';

let broadcastChannel;

const CHANNEL_NAME = 'tolstoy-app-channel';
const RELOAD_EVENT = 'tolstoy-app-reload';

export const initializeBroadcastChannel = () => {
  try {
    if ('BroadcastChannel' in self) {
      broadcastChannel = new BroadcastChannel(CHANNEL_NAME);

      broadcastChannel.addEventListener('message', handleMessage);
    }
  } catch (error) {
    console.error('self is not defined', error);
  }
};

export const broadcastReloadMessage = () => {
  return broadcastChannel?.postMessage(RELOAD_EVENT);
};

const handleMessage = event => {
  console.log('Got broadcast message', event);

  if (!verifyMessage(event)) {
    return;
  }

  switch (event.data) {
    case RELOAD_EVENT:
      console.log('Got reload request');
      window.location.reload();
      break;
  }
};

const verifyMessage = ({ origin }) => {
  return origin.startsWith(BASE_URL);
};
