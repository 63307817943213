import {
  IMPORT_FROM_INSTAGRAM,
  IMPORT_FROM_TIKTOK,
  LIBRARY,
  RECORD_SCREEN,
  RECORD_VIDEO,
  START_FROM_TEMPLATE,
  STOCK_VIDEOS,
  TOLSTOY_LIBRARY,
  UPLOAD_FILE,
} from 'app/src/constants/creation_method.constants';

export const creationFlowTitles = {
  [RECORD_VIDEO]: 'Record video',
  [UPLOAD_FILE]: 'Upload files',
  [RECORD_SCREEN]: 'Record screen',
  [LIBRARY]: 'Video Files',
  [TOLSTOY_LIBRARY]: 'Tolstoy Library',
  [START_FROM_TEMPLATE]: 'Start from Template',
  [STOCK_VIDEOS]: 'Stock videos',
  [IMPORT_FROM_TIKTOK]: 'Import from TikTok',
  [IMPORT_FROM_INSTAGRAM]: 'Import from Instagram',
};

export const LOADING_STATE = {
  uploadingVideo: 'UPLOADING_VIDEO',
  creatingProject: 'CREATING_PROJECT',
  updatingProject: 'UPDATING_PROJECT',
  creatingStep: 'CREATING_STEP',
  creatingSteps: 'CREATING_STEPS',
  updatingStep: 'UPDATING_STEP',
  importVideos: 'IMPORT_VIDEOS',
};

export const loadingTitles = {
  [LOADING_STATE.uploadingVideo]: 'Uploading video',
  [LOADING_STATE.creatingProject]: 'Creating your Tolstoy',
  [LOADING_STATE.updatingProject]: 'Updating your Tolstoy',
  [LOADING_STATE.creatingStep]: 'Creating Tolstoy part',
  [LOADING_STATE.creatingSteps]: 'Creating Tolstoy parts',
  [LOADING_STATE.updatingStep]: 'Updating Tolstoy Part',
  [LOADING_STATE.importVideos]: 'Importing Videos',
};
