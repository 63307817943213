import React from 'react';

const LinkedInIcon = ({ fill = '#0090D8', width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.156 20H3.523V8.32h3.633V20ZM5.32 6.758c-1.133 0-2.07-.977-2.07-2.149 0-1.601 1.719-2.617 3.125-1.796.664.351 1.055 1.054 1.055 1.796 0 1.172-.938 2.149-2.11 2.149ZM20.71 20h-3.593v-5.664c0-1.367-.039-3.086-1.914-3.086s-2.148 1.445-2.148 2.969V20H9.422V8.32h3.476v1.602h.04c.507-.899 1.68-1.875 3.437-1.875 3.672 0 4.375 2.422 4.375 5.547V20h-.04Z"
        fill={fill}
      />
    </svg>
  );
};
export default LinkedInIcon;
