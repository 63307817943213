import React, { useState } from 'react';
import styled from 'styled-components';
import { Badge } from 'shared/react/components/basic/badge/Badge';
import { useVodLabelById } from 'app/src/context/VodLabelsStore';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';
import Tooltip from 'app/src/basic_components/tooltip-v2/Tooltip';
import XIconWithCircle from 'app/src/images/XIconWithCricle';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

type Props = {
  vodLabelId?: string;
  isHidden: boolean;
  onDelete?: (vodLabelId: string) => void;
  text?: string;
};

const VideoCardLabel = ({ vodLabelId, isHidden, onDelete, text }: Props) => {
  const [label] = useVodLabelById(vodLabelId);
  const [isLoading, setIsLoading] = useState(false);

  const onClick = async () => {
    setIsLoading(true);
    await onDelete(vodLabelId);
    setIsLoading(false);
  };

  if ((!label && !text) || isLoading) {
    return null;
  }

  return (
    <StyledBadge hidden={isHidden}>
      <Tooltip text={label?.name || text}>
        <BadgeContent>
          <div>
            <EllipsisTextOverflowWrapper>
              <TextContainer>{label?.name || text}</TextContainer>
            </EllipsisTextOverflowWrapper>
          </div>
          {!!onDelete && (
            <EndIcon onClick={onClick}>
              <XIconWithCircle />
            </EndIcon>
          )}
        </BadgeContent>
      </Tooltip>
    </StyledBadge>
  );
};

const EndIcon = styled.div`
  display: none;
`;

const TextContainer = styled.div`
  width: max-content;
`;

const BadgeContent = styled(HorizontalFlex)`
  gap: 4px;
`;

const StyledBadge = styled(Badge).attrs(({ theme }) => ({
  weight: theme.designWeights.SQUARE,
  state: theme.designStates.LIGHT,
}))`
  overflow: hidden;
  display: flex;
  gap: 4px;
  padding: 4px;
  min-width: fit-content;
  pointer-events: ${({ isLoading }) => (isLoading ? 'none' : '')};

  &:hover {
    min-width: auto;
    ${EndIcon} {
      display: flex;
    }

    ${TextContainer} {
      width: 100%;
    }
  }
`;

export default VideoCardLabel;
