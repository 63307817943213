import React from 'react';
import styled from 'styled-components';
import { TextSmall } from 'shared/react/components/complex/Text';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';

const BadgeContent = ({ children, selectedAmount, onCancel }) => {
  return (
    <>
      <LeftText>{selectedAmount} selected</LeftText>
      <ButtonsContainer>
        {children}
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
      </ButtonsContainer>
    </>
  );
};

const LeftText = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
  line-height: 16px;
`;

const ButtonsContainer = styled(Gap8HorizontalFlexWrap)``;

const CancelButton = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  height: 24px;
  width: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 12px;
`;

export default BadgeContent;
