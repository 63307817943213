import React from 'react';

import { app as AppEntity } from 'app/src/types/entities';
import { SHOPIFY } from 'src/constants/intergrations.constants';
import ShopifyTolstoyCodeInstallation from './ShopifyTolstoyCodeInstallation';
import DefaultTolstoyCodeInstallation from './DefaultTolstoyCodeInstallation';
import Utils from 'src/utils';

const getWidgetCode = appKey => {
  return `
    <script>tolstoyAppKey="${appKey}"</script>
    <script type="module"
      async
      src="https://widget.gotolstoy.com/we/widget.js"
    >
    </script>
    <script
      type="text/javascript"
      nomodule
      async
      src="https://widget.gotolstoy.com/widget/widget.js"
    ></script>
    `;
};

type Props = {
  app: AppEntity;
};

const TolstoyCodeInstallation: React.FC<Props> = ({ app }) => {
  const integration = app?.app;
  const isShopify = integration === SHOPIFY;
  const appKey = app?.appKey || Utils.getAppKey();
  const appUrl = app?.appUrl;
  const widgetCode = getWidgetCode(appKey);

  if (isShopify) {
    return <ShopifyTolstoyCodeInstallation widgetCode={widgetCode} appUrl={appUrl} />;
  }

  return <DefaultTolstoyCodeInstallation widgetCode={widgetCode} integration={integration} />;
};

export default TolstoyCodeInstallation;
