import React from 'react';
import styled, { useTheme } from 'styled-components';
import StandardModal from '../pages/modals/StandardModal';
import InteractiveVideo from './InteractiveVideo';

const VideoModal = ({ src, open, closeModal, isVertical, ...props }) => {
  const theme = useTheme();
  return (
    <StandardModal onClose={closeModal} open={!!open} {...props}>
      <Container isVertical={isVertical}>
        <InteractiveVideo
          src={src}
          backgroundColor={theme.colors.gray24}
          borderRadius={'12px'}
          width="100%"
          title="Preview Tolstoy"
        />
      </Container>
    </StandardModal>
  );
};

const Container = styled.div`
  padding: 20px;
  width: ${({ isVertical }) => (isVertical ? '468px' : '727px')};
  height: ${({ isVertical }) => (isVertical ? '600px' : '468px')};
  max-width: 960px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 400px;
    padding: 10px 10px;
  }
  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    width: 100%;
    padding: 10px 0;
  }
`;

export default VideoModal;
