export const logRocketLogLocalStorage = () => {
  try {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (!key.toLowerCase().includes('tolstoy') || key.toLowerCase() !== 'appKey') {
        continue;
      }

      logLogRocketMessage(`key: ${key}, value: ${localStorage.getItem(localStorage.getItem(key))}`);
    }
  } catch (error) {
    console.log('can`t log local storage', error);
  }
};

export const logLogRocketMessage = message => {
  window.LogRocket?.log(message);
};
