import React from 'react';
import styled from 'styled-components';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import FeaturesTooltips from 'app/src/complex_components/billings/tooltips/FeaturesTooltips';

const BillingPackageViewsPrice = ({ value, packageInfo }) => {
  const { viewsPriceTooltipHeader, viewsPriceTooltipText } = packageInfo;

  return (
    <StyledBillingPackageViewsPrice>
      {value}
      <FeaturesTooltips header={viewsPriceTooltipHeader} text={viewsPriceTooltipText} />
    </StyledBillingPackageViewsPrice>
  );
};

export default BillingPackageViewsPrice;

const StyledBillingPackageViewsPrice = styled(HorizontalFlex)`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black1};
  padding-bottom: 16px;
`;
