import React from 'react';
import ShopifySmallIcon from 'app/src/images/integrations/ShopifySmallIcon';
import styled from 'styled-components';
import MenuItemV3, {
  MenuItemEndIconContainer,
  MenuItemIconContainer,
  smallMenuItemCss,
} from 'app/src/complex_components/menu_items/MenuItemV3';

type Props = {
  tag: string;
  endIcon?: React.ReactNode;
};

const ProductTag = ({ tag, endIcon }: Props) => {
  return (
    <Tag icon={<ShopifySmallIcon />} endIcon={endIcon}>
      {tag}
    </Tag>
  );
};

const Tag = styled(MenuItemV3)`
  padding: 5px 12px;
  background: ${({ theme }) => theme.colors.neutralLighter};
  border-radius: 4px;
  ${smallMenuItemCss};

  & ${MenuItemIconContainer} {
    width: 12px;
    height: 12px;
    & svg {
      width: 12px;
      height: 12px;
    }
  }

  ${MenuItemEndIconContainer} {
    display: none;
  }

  &:hover ${MenuItemEndIconContainer} {
    display: flex;
  }

  && {
    width: auto;
  }
`;
export default ProductTag;
