import * as React from 'react';

const LeadFormIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M34 17H14v15h20zm-20-1.25h21.25v17.5h-22.5v-17.5zM19 22c0 .703.547 1.25 1.25 1.25.664 0 1.25-.547 1.25-1.25 0-.664-.586-1.25-1.25-1.25-.703 0-1.25.586-1.25 1.25m3.75 0c0 .898-.508 1.719-1.25 2.188-.781.43-1.758.43-2.5 0A2.54 2.54 0 0 1 17.75 22c0-.86.469-1.68 1.25-2.148.742-.43 1.719-.43 2.5 0 .742.468 1.25 1.289 1.25 2.148m-4.727 5-.859 2.5h-1.289l1.25-3.75h6.25l1.25 3.75h-1.328l-.86-2.5zm8.477-6.25h5.625V22h-6.25v-1.25zm0 2.5h5.625v1.25h-6.25v-1.25zm0 2.5h5.625V27h-6.25v-1.25z"
      fill="#090A0B"
    />
  </svg>
);

export default LeadFormIcon;
