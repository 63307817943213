import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import MenuItems from 'app/src/complex_components/menu_items/MenuItems';
import useAccountMenuButtons from 'app/src/pages/sidebar/account-menu/useAccountMenuButtons';
import Workspaces from 'app/src/pages/sidebar/account-menu/workspaces/Workspaces';
import Separator from 'shared/react/components/basic/Separator';
import useNavigation from 'app/src/hooks/useNavigation';
import { useUser } from 'app/src/context/userStore/UserStore';
import LogoutIcon from 'app/src/images/LogoutIcon';
import UserBox from 'app/src/basic_components/UserBox';
import Utils from 'app/src/utils';
import MenuItemV3 from 'src/complex_components/menu_items/MenuItemV3';

type Props = {
  onClose: () => void;
};
const AccountMenu = ({ onClose }: Props) => {
  const [currentKey, setCurrentKey] = useState('');

  const { buttons } = useAccountMenuButtons(onClose);
  const [{ storageUser, userLogoSettings }, { logout }] = useUser();
  const { navigateToRoot, navigateToMyProfile } = useNavigation();
  const theme = useTheme();

  const handleLogout = async () => {
    await logout();
    navigateToRoot();
  };

  if (currentKey) {
    return <MenuItems items={buttons} currentKey={currentKey} setCurrentKey={setCurrentKey} />;
  }
  const MyProfileIcon = (
    <UserBox
      fontSize="10px"
      fontWeight="900"
      scale={'20px'}
      color={theme.colors.black}
      userImageSrc={Utils.getLogoUrl(userLogoSettings.logo)}
      backgroundColor={userLogoSettings.background || theme.colors.primaryLight}
      name={storageUser?.email}
      icon={undefined}
      badgeText={undefined}
      badgeTooltipText={undefined}
    />
  );

  return (
    <>
      <Workspaces />
      <CustomSeparator />
      <MenuItems items={buttons} currentKey={currentKey} setCurrentKey={setCurrentKey} />
      <CustomSeparator />
      <MenuItemV3 onClick={navigateToMyProfile} icon={MyProfileIcon}>
        {storageUser?.email}
      </MenuItemV3>
      <MenuItemV3 onClick={handleLogout} icon={<LogoutIcon />}>
        Logout
      </MenuItemV3>
    </>
  );
};

const CustomSeparator = styled(Separator)`
  margin: 8px 0;
`;

export default AccountMenu;
