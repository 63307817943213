import InternalMessaging from '../../messaging/internal/InternalMessaging.js';
import { INTERNAL_EVENTS } from '../../constants/internalEvents.constants.js';
import { FEATURE_BLOCK_SHOPIFY_ADD_TO_CART_ATTRIBUTE } from '../../constants/features.constants.js';
import { getAppKey } from '../../common/appKey.js';
import { reloadFont } from '../../common/fontLoader.js';
import { getConfigByProductId, getConfigByUrl } from '../../common/requests.js';
import { PLACEHOLDER_PRODUCT_ID } from '../../constants/widgetConstants.js';
import AbTest from '../AbTest/AbTest.js';
import { getFeatureIsEnabled } from '../../utils/feature.utils.js';
import { getBaseUrl } from './Carousel/carousel.utils.js';
import { EMBED_CAROUSEL_MOTION } from './Carousel/carouselConstants.js';

export const getPublicId = (className, publishId) => {
  return `${className}-${publishId}`;
};

export const getEmbedPublishId = element => {
  if (element.dataset.publishId) {
    return element.dataset.publishId;
  }

  if (element.id) {
    return element.id.split('_')[0];
  }

  return '';
};

export const reloadFontIfNeeded = configData => {
  const fontCustomizationSettings = configData?.design?.branding?.typography?.font;

  if (!fontCustomizationSettings?.family) {
    return;
  }

  reloadFont(fontCustomizationSettings);
};

export const getVideoBaseUrl = ({
  step,
  embedMotion,
  isStory,
  loadAll = false,
  isCarouselPlayInTileFirst
}) => {
  const isStatic = embedMotion === EMBED_CAROUSEL_MOTION.static;
  if (isStatic || isCarouselPlayInTileFirst) {
    return null;
  }

  const isMobile = window.innerWidth <= 450;
  const isHoverOver = embedMotion === EMBED_CAROUSEL_MOTION.hoverOver;
  const isDynamic = embedMotion === EMBED_CAROUSEL_MOTION.dynamic;

  if (isDynamic && loadAll) {
    return getBaseUrl({ step, isStory });
  }

  if ((isHoverOver && isMobile) || isDynamic) {
    return null;
  }

  return getBaseUrl({ step, isStory });
};

export const isInViewport = element => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= -rect.height &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const getAllElements = (type, publishId) => {
  return window.document.querySelectorAll(
    `[data-tolstoy-element="${getPublicId(type, publishId)}"]`
  );
};

export const stopAllVideos = ({ videoClass, publishId, removeEventListenerFunction }) => {
  const videoContainers = getAllElements(videoClass, publishId);

  for (const video of videoContainers) {
    video.pause();
    removeEventListenerFunction?.(video);
  }
};

export const stopDynamicVideos = ({
  videoClass,
  publishId,
  setIsDynamicVideoRunning,
  onVideoEnd
}) => {
  setIsDynamicVideoRunning(false);

  stopAllVideos({
    videoClass,
    publishId,
    removeEventListenerFunction: video => {
      video.removeEventListener('ended', onVideoEnd);
    }
  });
};

export const onViewPortChangeHandler = ({
  videoClass,
  isDynamicVideoRunning,
  setIsDynamicVideoRunning,
  dynamicVideoHandler,
  publishId,
  onVideoEnd
}) => {
  const videoContainers = getAllElements(videoClass, publishId);
  if (videoContainers.length === 0) {
    return;
  }

  const isEmbedInViewport = isInViewport(videoContainers[0]);

  if (!isEmbedInViewport && isDynamicVideoRunning) {
    stopDynamicVideos({ videoClass, publishId, setIsDynamicVideoRunning, onVideoEnd });
    setIsDynamicVideoRunning(false);
  } else if (isEmbedInViewport && !isDynamicVideoRunning) {
    dynamicVideoHandler();
    setIsDynamicVideoRunning(true);
  }
};

export const getConfig = async ({ publishId, productId, widgetType, tags, appUrl, variantId }) => {
  const getConfigFunction =
    productId && productId !== PLACEHOLDER_PRODUCT_ID ? getConfigByProductId : getConfigByUrl;
  const appKey = window.tolstoyAppKey || getAppKey() || '';
  const urlParams = new URLSearchParams(window.location.search);
  const currentVariantId = variantId || urlParams.get('variant');

  const res = await getConfigFunction({
    productId,
    defaultPublishId: publishId,
    widgetType,
    appKey,
    tags,
    appUrl,
    variantId: currentVariantId
  });

  const abTest = new AbTest(widgetType);
  const abTestSettings = abTest.init(res) || {};
  if (abTestSettings.hideElement) {
    return { disabled: true };
  }

  if (res?.disabled && !res.abTestId) {
    const message = getMessageFromError(res.message);
    throw new Error(message || res?.message || "couldn't find product page");
  }

  if (
    getFeatureIsEnabled({ config: res, featureKey: FEATURE_BLOCK_SHOPIFY_ADD_TO_CART_ATTRIBUTE })
  ) {
    InternalMessaging.postMessage({
      eventName: INTERNAL_EVENTS.blockAnonymousIdToCart,
      appKey
    });
  }

  if (res.openCommerceSettings) {
    InternalMessaging.postMessage({
      eventName: INTERNAL_EVENTS.openCommerceSettingsUpdate,
      openCommerceSettings: res.openCommerceSettings
    });
  }

  return res;
};

/**
 * @param {boolean | undefined | null} isFeedMuted - Global setting. Indicates if any video must be muted.
 * @param {boolean | undefined | null} isSoundAllowed - Video-level setting. Indicates if a customer wants this video to play sound.
 * @return {boolean}
 */
export function shouldForceMute(isFeedMuted, isSoundAllowed) {
  return Boolean(
    isSoundAllowed === null || isSoundAllowed === undefined ? isFeedMuted : !isSoundAllowed
  );
}

export const getMessageFromError = message => {
  const messages = {
    'Invalid publishId': 'Publish ID in the Publish tab of your Tolstoy admin',
    'Project is deleted': 'Project has been deleted',
    'Project is not live': 'Your project is in draft, please set to publish in your Tolstoy admin',
    'No filtered videos found': 'Videos will only display on pages with tagged videos',
    'No videos found': 'Videos will only display on pages with tagged videos',
    'No connections found': 'Videos will only display on pages with tagged videos'
  };

  return messages[message] || '';
};
