import React from 'react';
import { STORE_CONNECTED_TO_ANOTHER_ACCOUNT } from 'app/src/constants/intergrations.constants';
import ShopifyStoreModal from './ShopifyStoreModal';

const HijackShopifyStoreModal = () => {
  return (
    <ShopifyStoreModal
      title="Shopify store is already connected to an account"
      subtitle="There is already a Tolstoy account connected to this shopify store. To connect the
              store to this account you should uninstall and reinstall Tolstoy app through shopify
              while you are connected to this Tolstoy account."
      localStorageKey={STORE_CONNECTED_TO_ANOTHER_ACCOUNT}
    />
  );
};

export default HijackShopifyStoreModal;
