export enum PackageType {
  Free = 'free',
  Pro = 'pro',
  Business = 'business',
  Enterprise = 'enterprise',
}

export enum ShopifyuProductsType {
  Fixed = 'fixed',
  PerSeat = 'per-seat',
  PerUniqueUsage = 'per-unique-usage',
}
