import React from 'react';

const AtIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.219.531c4 .094 7.281 3.625 7.281 7.813v.531a2.887 2.887 0 0 1-2.875 2.875c-1.031 0-1.906-.531-2.437-1.312-.626.624-1.5 1.03-2.438 1.03-1.937 0-3.5-1.593-3.5-3.468 0-1.906 1.563-3.5 3.5-3.5.75 0 1.438.25 2 .688a.77.77 0 0 1 .75-.625c.406 0 .75.312.75.718v3.657c0 .75.594 1.374 1.375 1.374.75 0 1.375-.624 1.375-1.374v-.594c0-3.406-2.625-6.219-5.844-6.313C6.5 1.97 5 2.562 3.812 3.72 2.625 4.844 2 6.406 2 8.063c0 2.968 2.188 5.53 5.094 5.968a.74.74 0 0 1 .625.844A.734.734 0 0 1 7 15.5h-.125C3.219 14.969.5 11.75.5 8.063c0-2.063.813-4 2.281-5.438A7.5 7.5 0 0 1 8.22.531M7.75 10c1.094 0 2-.875 2-1.969 0-1.062-.906-1.969-2-1.969-1.125 0-2 .875-2 1.97 0 1.093.875 1.968 2 1.968"
        fill="#2F3137"
      />
    </svg>
  );
};

export default AtIcon;
