import React, { FC } from 'react';
import PricingTooltip from 'app/src/complex_components/PricingTooltip';
import EditorItemContent from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorItemContent';
import { EditorItemProps } from 'app/src/pages/project/pages/look_and_feel/types/lookAndFeel.types';

const EditorItem: FC<EditorItemProps> = ({ isPro, tooltipProps, ...props }) => {
  if (!isPro) {
    return <EditorItemContent {...props} />;
  }

  return (
    <PricingTooltip
      header={'Index buttons'}
      text={`Hey! I'm a premium feature, click below to unlock.`}
      placement={'right'}
      shouldHide={false}
      requestUserUpgrade={false}
      {...tooltipProps}
    >
      <EditorItemContent {...props} />
    </PricingTooltip>
  );
};

export default EditorItem;
