import React from 'react';
import { GridContainer } from 'app/src/pages/dashboard/components/page/Page';
import Spinner from 'app/src/basic_components/Spinner';

const GridLoading = () => {
  return (
    <GridContainer>
      <Spinner size={undefined} />
    </GridContainer>
  );
};
export default GridLoading;
