import React from 'react';

const Archive = ({ color }) => {
  return (
    <svg width="18" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5 1H1.5C0.65625 1 0 1.6875 0 2.5V4.5C0 5.0625 0.4375 5.5 0.96875 5.5L1 13C1 14.125 1.875 15 3 15H13C14.0938 15 15 14.125 15 13V5.5C15.5312 5.5 16 5.0625 16 4.53125V2.5C16 1.6875 15.3125 1 14.5 1ZM13 13.5H3C2.71875 13.5 2.5 13.2812 2.5 13V5.5H13.5V13C13.5 13.2812 13.25 13.5 13 13.5ZM14.5 4H1.5V2.5H14.5V4ZM5.71875 8.5H10.2188C10.6562 8.5 11 8.1875 11 7.75C11 7.34375 10.6562 7 10.25 7H5.71875C5.3125 7 5 7.34375 5 7.75C5 8.1875 5.3125 8.5 5.71875 8.5Z"
        fill={color}
      />
    </svg>
  );
};

export default Archive;
