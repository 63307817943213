import React, { FC } from 'react';
import styled from 'styled-components';
import { Tooltip as MuiTooltip } from '@material-ui/core';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';

type Props = {
  text: string | React.ReactElement;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  hidden?: boolean;
  className?: string;
  arrow?: boolean;
  interactive?: boolean;
  children: React.ReactNode;
};

const Tooltip: FC<Props> = ({
  text,
  placement,
  hidden,
  children,
  className,
  arrow,
  interactive = false,
}): React.ReactElement => {
  if (hidden || !text) {
    return (children as React.ReactElement) || null;
  }

  const getTooltipBody = () => {
    return (
      <TooltipContainer className={className}>
        <TextContainer>{text}</TextContainer>
      </TooltipContainer>
    );
  };

  return (
    <StyledTooltip
      interactive={interactive}
      arrow={arrow}
      placement={placement}
      title={getTooltipBody()}
    >
      <Container>{children}</Container>
    </StyledTooltip>
  );
};

type ToBeStyledTooltipProps = {
  className?: string;
  title: React.ReactNode;
  placement: 'top' | 'bottom' | 'left' | 'right';
  arrow?: boolean;
  interactive?: boolean;
};

const ToBeStyledTooltip: FC<ToBeStyledTooltipProps> = ({
  className,
  title,
  children,
  placement,
  arrow,
  interactive,
}) => (
  <MuiTooltip
    title={title}
    disableFocusListener
    disableTouchListener
    enterDelay={0}
    placement={placement}
    classes={{ tooltip: className }}
    arrow={arrow}
    interactive={interactive}
  >
    {children as React.ReactElement}
  </MuiTooltip>
);

const StyledTooltip = styled(ToBeStyledTooltip)`
  background: ${({ theme }) => theme.colors.gray2};
  padding: 0;

  & .MuiTooltip-arrow::before {
    background: ${({ theme }) => theme.colors.gray2};
  }
`;

const TooltipContainer = styled.div`
  z-index: 100;
  padding: 4px 8px;
  border-radius: 6px;
  max-width: 430px;
  background: ${({ theme }) => theme.colors.black};
`;

const TextContainer = styled(TextTiny)`
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
`;

const Container = styled.div``;

export default Tooltip;
