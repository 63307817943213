import styled from 'styled-components';

const Popup = styled.div`
  display: ${({ open }) => (open ? 'grid' : 'none')};
  position: absolute;
  z-index: 2;
  top: 20px;
  right: 40px;
  max-width: 390px;
  padding: 32px;
  box-shadow:
    0px 4px 18px -4px rgba(0, 0, 0, 0.18),
    0px 39px 34px -19px rgba(0, 0, 0, 0.08);
  background: ${({ theme }) => theme.colors.white};
  border-radius: 16px;
  outline: none;
  gap: 32px;
`;
export default Popup;
