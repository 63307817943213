import { getShouldOpenRecorder } from '../../helpers/tolstoy.helpers';
import Player from '../player/Player';
import Utils from '../../utils';
import React, { lazy, Suspense } from 'react';
import PlayerLoader from 'player/src/components/PlayerLoader';

const PlayerV2 = lazy(() =>
  Utils.componentLoader(() => import(/* webpackChunkName: "player-v2" */ './PlayerV2'))
);

const PlayerContainer = ({ tolstoyType, ...props }) => {
  if (getShouldOpenRecorder(tolstoyType)) {
    return (
      <Suspense fallback={<PlayerLoader />}>
        <PlayerV2 tolstoyType={tolstoyType} {...props} />;
      </Suspense>
    );
  }

  return <Player tolstoyType={tolstoyType} {...props} />;
};

export default PlayerContainer;
