import React from 'react';

const VerticalMenuIcon = props => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 3.5A1.5 1.5 0 0 0 8.5 2C8.5 1.187 7.812.5 7 .5A1.5 1.5 0 0 0 5.5 2c0 .844.656 1.5 1.5 1.5Zm0 9A1.5 1.5 0 0 0 5.5 14c0 .844.656 1.5 1.5 1.5A1.5 1.5 0 0 0 8.5 14c0-.813-.688-1.5-1.5-1.5Zm0-6A1.5 1.5 0 0 0 5.5 8c0 .844.656 1.5 1.5 1.5A1.5 1.5 0 0 0 8.5 8c0-.813-.688-1.5-1.5-1.5Z"
      fill="#50545E"
    />
  </svg>
);

export default VerticalMenuIcon;
