import React from 'react';

function Video({ fill }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" fill="none" viewBox="0 0 18 16">
      <path
        fill={fill || '#7D8087'}
        d="M17.406 3.219a1.16 1.16 0 00-1.187-.094L13 4.625V4c0-1.094-.906-2-2-2H2C.875 2 0 2.906 0 4v8c0 1.125.875 2 2 2h9c1.094 0 2-.875 2-2v-.594l3.219 1.5c.156.063.343.125.531.125.219 0 .469-.094.656-.219.375-.218.594-.624.594-1.062V4.281a1.22 1.22 0 00-.594-1.062zM11.5 12c0 .281-.25.5-.5.5H2a.494.494 0 01-.5-.5V4c0-.25.219-.5.5-.5h9c.25 0 .5.25.5.5v8zm5-.625L13 9.781V6.25l3.5-1.594v6.719z"
      ></path>
    </svg>
  );
}

export default Video;
