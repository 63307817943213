import {
  RECORDINGS,
  SCREEN_AND_CAMERA,
  SCREEN_RECORDING,
  UPLOAD,
  VIDEO_RECORDING,
} from 'app/src/constants/recordingTypes.constants';
import {
  GOOGLE_DRIVE,
  INSTAGRAM_GRAPH,
  SYNTHESIA,
  TIKTOK,
  YOUTUBE,
} from 'app/src/constants/intergrations.constants';
import { UGC } from 'app/src/constants/navigation.constants';
import { SOURCES_UPLOAD_TYPE } from 'app/src/constants/videoSources.constants.js';
import InstagramSmallIcon from 'app/src/images/integrations/InstagramSmallIcon';
import TiktokSmallIcon from 'app/src/images/integrations/TiktokSmallIcon';
import GoogleDriveIcon from 'app/src/images/integrations/GoogleDriveIcon';
import SynthesiaSmallIcon from 'app/src/images/integrations/SynthesiaSmallIcon';
import OutlinedVideoIcon from 'app/src/images/video_icon/OutlinedVideoIcon';
import UploadFileIcon from 'app/src/images/creation_method_icons/UploadFileIcon';
import { AI_VIDEO } from 'app/src/constants/creation_method.constants';
import AiIcon from 'app/src/images/dashboard_v2/AiIcon';
import YoutubeSmallIcon from 'app/src/images/integrations/YoutubeSmallIcon';

export const RECORDINGS_SOURCES = [SCREEN_RECORDING, VIDEO_RECORDING, SCREEN_AND_CAMERA];

export const SOURCE_NAMES = {
  [UPLOAD]: 'Upload',
  [INSTAGRAM_GRAPH]: 'Instagram',
  [GOOGLE_DRIVE]: 'Google Drive',
  [TIKTOK]: 'TikTok',
  [RECORDINGS]: 'Recordings',
  [SYNTHESIA]: 'Synthesia',
  [AI_VIDEO]: 'AI Video',
  [YOUTUBE]: 'YouTube',
};

export const SOURCES_ICONS = {
  [UPLOAD]: UploadFileIcon,
  [INSTAGRAM_GRAPH]: InstagramSmallIcon,
  [GOOGLE_DRIVE]: GoogleDriveIcon,
  [TIKTOK]: TiktokSmallIcon,
  [RECORDINGS]: OutlinedVideoIcon,
  [SYNTHESIA]: SynthesiaSmallIcon,
  [AI_VIDEO]: AiIcon,
  [YOUTUBE]: YoutubeSmallIcon,
};

export const VIDEO_SOURCES = [
  UPLOAD,
  INSTAGRAM_GRAPH,
  GOOGLE_DRIVE,
  TIKTOK,
  RECORDINGS,
  SYNTHESIA,
  AI_VIDEO,
  YOUTUBE,
  UGC,
];

export const USER_CONTENT_INSTAGRAM_GRAPH = `${INSTAGRAM_GRAPH}UserContent`;

export const isSourceSelected = (key, source) => SOURCES_UPLOAD_TYPE[key].includes(source);

export const UGC_COLLECT = 'collect_ugc';
