import React from 'react';
import styled from 'styled-components';

function LoadingIndicator({ size, ...props }) {
  return <LayoutRoot size={size} {...props} />;
}

export default LoadingIndicator;

const LayoutRoot = styled.div`
  z-index: 1;
  width: ${props => props.size || 16}px;
  height: ${props => props.size || 16}px;
  border: 2px solid transparent;
  border-radius: 50%;
  border-top: 2px solid ${({ theme }) => theme.colors.primary};
  -webkit-animation: spin 1.2s linear infinite;
  animation: spin 1.2s linear infinite;
  animation-timing-function: ease-in-out;
  grid-row: 1;
  grid-column: 1;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* Add animation to "page content" */
  .animate-bottom {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
  }
  @keyframes animatebottom {
    from {
      bottom: -100px;
      opacity: 0;
    }
    to {
      bottom: 0;
      opacity: 1;
    }
  }
`;
