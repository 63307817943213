import React from 'react';
import styled from 'styled-components';
import Utils from 'shared/react/utils/utils';
import { AssetsUtilsService } from 'shared/react/services/assets';
import FilledMuteIcon from 'shared/react/images/feed/FilledMuteIcon';
import FilledUnmuteIcon from 'shared/react/images/feed/FilledUnmuteIcon';
import useFeedState from 'shared/react/components/complex/context/hooks/useFeedState';
import { PLAYER_TYPES } from 'shared/react/constants/tolstoy.constants';
import useTracker from 'shared/react/components/complex/context/hooks/useTracker';
import BackdropButton from '../../BackdropButton';
import { FEED_MUTE_BUTTON_TEST_ID } from 'shared/react/constants/dataTestIds.constants';
import { shouldForceMute } from '../../../../utils/sound';

const FeedMuteButton = ({ videoRef, audioRef, step }) => {
  const [, { track }] = useTracker();
  const [{ isFeedMuted, isUserMute, setIsUserMute, isInitialMute, setIsInitialMute }] =
    useFeedState();
  const shouldShowMutedIcon = isInitialMute || isUserMute;
  const isImage = AssetsUtilsService.isImageAsset(step);
  const isGallery = AssetsUtilsService.isGalleryAsset(step);

  const shouldHideMuteButton =
    isImage ||
    shouldForceMute(isFeedMuted, step.isSoundAllowed) ||
    (isGallery && !audioRef?.current?.src);

  if (shouldHideMuteButton) {
    return null;
  }

  const muteGalleryAudio = () => {
    audioRef.current.muted = !audioRef.current.muted;
    setIsUserMute(audioRef.current.muted);
  };

  const onClick = e => {
    e.stopPropagation();
    if (isGallery) {
      muteGalleryAudio();
      return;
    }

    track(isUserMute ? 'videoUnmuted' : 'videoMuted', {
      location: Utils.getAnalyticsLocationObject(PLAYER_TYPES.feed),
      videoName: step.videoName,
    });

    if (isInitialMute) {
      setIsInitialMute(false);
    }

    setIsUserMute(!isUserMute);

    if (!shouldForceMute(isFeedMuted, step.isSoundAllowed)) {
      videoRef.current.muted = !isUserMute;
    }
  };

  return (
    <StyledBackdropButton
      data-test-id={FEED_MUTE_BUTTON_TEST_ID}
      onClick={onClick}
      ariaLabel="Toggle mute"
    >
      {shouldShowMutedIcon ? <FilledMuteIcon /> : <FilledUnmuteIcon />}
    </StyledBackdropButton>
  );
};

const StyledBackdropButton = styled(BackdropButton)`
  flex-basis: 15%;
`;

export default FeedMuteButton;
