import React from 'react';
import styled from 'styled-components';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextBody } from 'shared/react/components/complex/Text';

type Props = {
  imageSrc?: string;
  app?: string;
  button: React.ReactNode;
  text?: string;
};

const VideosEmptySpaceBox = ({ imageSrc, app, button, text }: Props) => {
  return (
    <LayoutRoot>
      {!!imageSrc && <Icon src={imageSrc} alt={app} />}
      {!!text && <Text>{text}</Text>}
      {button}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)`
  justify-content: space-between;
  padding: 16px 24px;
  background: ${({ theme }) => theme.colors.white};
  width: 478px;
  border-radius: 8px;
  align-items: center;
  height: 88px;
`;

const Text = styled(TextBody)`
  font-weight: 500;
  line-height: 22px;
`;

const Icon = styled.img`
  height: 40px;
`;

export default VideosEmptySpaceBox;
