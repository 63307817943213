import React, { FC } from 'react';
import styled from 'styled-components';

type Props = {
  className?: string;
};

export const LeftChevronIcon: FC<Props> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      width="8"
      height="16"
      viewBox="0 0 8 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M7.313 1.156c.125.094.156.219.156.344a.47.47 0 0 1-.125.344L1.656 8l5.657 6.188c.187.187.187.5-.032.687a.465.465 0 0 1-.687-.031l-6-6.5a.453.453 0 0 1 0-.656l6-6.5c.218-.22.531-.22.718-.032Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export const ChevronRightIcon = styled(LeftChevronIcon)`
  transform: rotate(180deg);
`;

export const ChevronDownIcon = styled(ChevronRightIcon)`
  transform: rotate(-90deg);
  width: 12px;
  height: 12px;
`;

export const ChevronUpIcon = styled(ChevronRightIcon)`
  transform: rotate(90deg);
  width: 12px;
  height: 12px;
  cursor: pointer;
`;
