import React from 'react';

const Name = () => {
  return (
    <svg width="85" height="24" viewBox="0 0 85 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.25 3.973v6.767H3.032V3.973H.083v16.154h2.95v-6.548h6.215v6.548H12.2V3.973H9.25Zm12.393 10.933a2.441 2.441 0 1 1-4.883 0v-6.92h-2.798v6.92a5.235 5.235 0 0 0 10.47 0v-6.92h-2.79v6.92ZM42.38 8.699c0-1.419.938-1.868 1.965-1.868.83 0 1.923.63 2.64 1.395l1.832-2.16c-.915-1.238-2.77-2.093-4.29-2.093-3.037 0-5.233 1.778-5.233 4.726 0 5.47 6.685 3.734 6.685 6.795 0 .944-.917 1.777-1.966 1.777-1.655 0-2.191-.808-2.952-1.664l-2.034 2.115c1.301 1.597 2.906 2.408 4.828 2.408 2.885 0 5.205-1.8 5.205-4.614 0-6.071-6.685-4.184-6.685-6.817h.005Zm41.355 8.847c-1.654 0-2.124-.715-2.124-1.81v-4.851h2.571V8.427h-2.571V5.184l-2.838 1.274v9.88c0 2.527 1.744 3.8 4.135 3.8.375.007.75-.023 1.12-.089l.691-2.548c-.312.021-.67.043-.984.043v.002Zm-51.44-9.48c-1.385 0-2.353.403-3.289 1.32V4.07h-2.802v9.855c0 3.689 2.666 6.217 5.664 6.217 3.325 0 6.25-2.573 6.25-6.037 0-3.42-2.692-6.036-5.822-6.036v-.002Zm-.017 9.255a3.181 3.181 0 1 1 0-6.363 3.181 3.181 0 0 1 0 6.363Zm30.455-3.362c0-3.47-2.918-6.037-6.25-6.037-2.996 0-5.664 2.527-5.664 6.217V24h2.803v-5.324c.934.916 1.903 1.32 3.288 1.32 3.13 0 5.822-2.616 5.822-6.037h.001Zm-2.654-.035a3.18 3.18 0 1 1-6.36 0 3.18 3.18 0 0 1 6.36 0"
        fill="#33475B"
      />
      <path
        d="M71.79 7.787V4.97a2.17 2.17 0 0 0 1.25-1.954V2.95A2.169 2.169 0 0 0 70.87.782h-.064a2.169 2.169 0 0 0-2.17 2.17v.064a2.17 2.17 0 0 0 1.251 1.954v2.817a6.141 6.141 0 0 0-2.92 1.285l-7.723-6.016c.055-.198.084-.403.087-.608a2.443 2.443 0 1 0-2.447 2.439 2.417 2.417 0 0 0 1.204-.328l7.605 5.918a6.16 6.16 0 0 0 .094 6.939l-2.313 2.314a1.986 1.986 0 0 0-.578-.094 2.006 2.006 0 1 0 2.008 2.008 1.98 1.98 0 0 0-.094-.578l2.288-2.29a6.171 6.171 0 1 0 4.69-10.99Zm-.95 9.262a3.165 3.165 0 1 1 0-6.33 3.166 3.166 0 0 1 .004 6.328"
        fill="#FF7A59"
      />
    </svg>
  );
};

export default Name;
