import Analytics from 'widget/src/widgets/Analytics/Analytics.js';
import { EVENT_NAMES } from 'widget/src/constants/events.constants.js';
import { getAbTestGroup, getSessionUniqueId, setAbTestGroup } from '../../common/cache.js';

const GROUP = {
  A: 'A',
  B: 'B'
};

class AbTest {
  constructor(widgetType) {
    this.widgetType = widgetType;
  }

  sendAbTestInitializedEvent({ abTestId, group, excludeExternalAnalytics, probability }) {
    const params = {
      abTestId,
      eventName: EVENT_NAMES.abTestInitialized,
      sessionUniqueId: getSessionUniqueId(),
      testGroup: group,
      probability
    };

    this.analytics.sendEventImmediately(params, excludeExternalAnalytics);
  }

  initializeGroup({ abTestId, probability, excludeExternalAnalytics }) {
    const existingGroup = getAbTestGroup(abTestId);
    if (existingGroup) {
      this.group = existingGroup;
      return;
    }

    const random = Math.random();
    // Getting a random GROUP based on the probability value.
    const group = random > probability ? GROUP.B : GROUP.A;
    this.group = group;

    this.sendAbTestInitializedEvent({ abTestId, group, excludeExternalAnalytics, probability });
    setAbTestGroup(abTestId, group);
  }

  init(config) {
    if (!config) {
      return;
    }

    const { abTestSettings = {} } = config;
    const {
      isEnabled,
      abTestId,
      probability,
      groupASettings = {},
      groupBSettings = {},
      excludeExternalAnalytics
    } = abTestSettings;
    if (!isEnabled) {
      return;
    }

    this.analytics = new Analytics({ config, playerType: this.widgetType });
    this.initializeGroup({ abTestId, probability, excludeExternalAnalytics });

    return this.group === GROUP.A ? groupASettings : groupBSettings;
  }
}

export default AbTest;
