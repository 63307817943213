import React from 'react';

const ExpandIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.5 0H0.5C0.21875 0 0 0.25 0 0.5V4.5C0 4.78125 0.21875 5 0.5 5C0.75 5 1 4.78125 1 4.5V1H4.5C4.75 1 5 0.78125 5 0.5C5 0.25 4.75 0 4.5 0ZM4.5 13H1V9.5C1 9.25 0.75 9 0.5 9C0.21875 9 0 9.25 0 9.5V13.5C0 13.7812 0.21875 14 0.5 14H4.5C4.75 14 5 13.7812 5 13.5C5 13.25 4.75 13 4.5 13ZM13.5 9C13.2188 9 13 9.25 13 9.5V13H9.5C9.21875 13 9 13.25 9 13.5C9 13.7812 9.21875 14 9.5 14H13.5C13.75 14 14 13.7812 14 13.5V9.5C14 9.25 13.75 9 13.5 9ZM13.5 0H9.5C9.21875 0 9 0.25 9 0.5C9 0.78125 9.21875 1 9.5 1H13V4.5C13 4.78125 13.2188 5 13.5 5C13.75 5 14 4.78125 14 4.5V0.5C14 0.25 13.75 0 13.5 0Z"
        fill="white"
      />
    </svg>
  );
};

export default ExpandIcon;
