import React from 'react';
import styled from 'styled-components';
import { TextBodyLeadingBold, TextSmall } from 'shared/react/components/complex/Text';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import Utils from 'app/src/utils';

const FeedEmbedWidgetsShopifyStepperProductPage = ({ widgetType }) => {
  return [
    <TextContainer key={4}>
      <ShopifyV2Container>
        <TextBodyLeadingBold>Place {widgetType} in theme</TextBodyLeadingBold>
        <TextSmallContainer>
          <Span>
            {`Go to Shopify themes → ‘Add section’ → 
            ‘Tolstoy ${Utils.upperCaseFirstChar(widgetType)}’. `}
          </Span>
        </TextSmallContainer>
      </ShopifyV2Container>
    </TextContainer>,
  ];
};

const ShopifyV2Container = styled(VerticalFlex)``;

const TextContainer = styled(Gap8HorizontalFlex)`
  align-items: center;
`;

const Span = styled.span``;

const TextSmallContainer = styled(TextSmall)`
  max-width: 420px;
`;

export default FeedEmbedWidgetsShopifyStepperProductPage;
