import React from 'react';
import styled from 'styled-components';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import BillingPackagePageViewMetric from './BillingPackagePageViewMetric';

const BillingPackageMetrics = () => {
  return (
    <StyledBillingPackagePageMetrics>
      <BillingPackagePageViewMetric />
    </StyledBillingPackagePageMetrics>
  );
};

export default BillingPackageMetrics;

const StyledBillingPackagePageMetrics = styled(HorizontalFlexWrap)`
  gap: 56px;
`;
