import React from 'react';
import { useDrawer } from 'src/context/ui_store/DrawerStore';
import { GlobalDrawerType } from './types/globalDrawer.types';
import JourneyDrawer from './drawers/journey-drawer/JourneyDrawer';
import UGCSettingsDrawer from './drawers/ugc/UGCSettingsDrawer';
import InstagramSettingsDrawer from './drawers/instagram-settings/InstagramSettingsDrawer';

const GlobalDrawerContent = () => {
  const [{ currentDrawer }] = useDrawer();

  switch (currentDrawer) {
    case GlobalDrawerType.Journey:
      return <JourneyDrawer />;
    case GlobalDrawerType.UGCSettings:
      return <UGCSettingsDrawer />;
    case GlobalDrawerType.InstagramSettings:
      return <InstagramSettingsDrawer />;
    default:
      return null;
  }
};

export default GlobalDrawerContent;
