import React from 'react';
import styled from 'styled-components';
import { TextSmall, TextSubtitle } from 'shared/react/components/complex/Text';
import { useUser } from 'app/src/context/userStore/UserStore';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import { getTimeSince } from 'app/src/utils/date.utils';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';
import TeamMemberDetails from 'app/src/complex_components/TeamMemberDetails';
import ShareToLibraryMenu from 'app/src/pages/dashboard/pages/libraries/tolstoys_tab/project_card_v2/share_to_library_menu/ShareToLibraryMenu';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import { useSessions } from 'app/src/context/SessionsStore';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import ProjectCardPublishMethodBadge from './ProjectCardPublishMethodBadge';

const getFormattedUnreadResponses = unreadResponses => {
  if (unreadResponses > 9) {
    return '9+';
  }

  return unreadResponses;
};

const getUnreadResponsesText = number => {
  if (number >= 99) {
    return '99+';
  }
  return number;
};

const ProjectCardDetails = ({ project, selectedCardsLength, isFeed }) => {
  const [, { getProjectTotalResponses, getProjectUnreadResponses }] = useSessions();
  const [, { isProjectLive }] = useUser();
  const stepsLength = project?.steps?.items?.length;
  const unreadResponses = getProjectUnreadResponses(project.id);
  const totalResponses = getProjectTotalResponses(project.id);
  const isUnreadResponses = !!unreadResponses;
  const isLive = isProjectLive(project);

  return (
    <LayoutRoot>
      <TopContainer>
        <ProjectCardPublishMethodBadge publishMethod={project.publishMethod} />
        <ShareToLibraryMenu selectedCardsLength={selectedCardsLength} project={project} />
      </TopContainer>
      <ProjectDetailsContainer>
        <ProjectDetails>
          <ProjectNameContainer>
            <EllipsisTextOverflowWrapper>
              <ProjectName className="notranslate" data-test-id={project.name} title={project.name}>
                {project.name}
              </ProjectName>
            </EllipsisTextOverflowWrapper>
            {isLive && (
              <LiveBadgeContainer>
                <LiveText>Live</LiveText>
              </LiveBadgeContainer>
            )}
            {isUnreadResponses && !isFeed && (
              <UnreadSessionsContainer>
                {getFormattedUnreadResponses(unreadResponses)}
              </UnreadSessionsContainer>
            )}
          </ProjectNameContainer>
        </ProjectDetails>
        <TeamMemberDetails imageSize="16px" fontSize="12px" asset={project} />
        <ProjectExtraDetails>
          {getTimeSince(project.createdAt)}
          <Dot />
          {stepsLength} {isFeed ? 'videos' : 'parts'}
          {!isFeed && (
            <>
              <Dot />
              {getUnreadResponsesText(totalResponses)} responses
            </>
          )}
        </ProjectExtraDetails>
      </ProjectDetailsContainer>
    </LayoutRoot>
  );
};

export default ProjectCardDetails;

const LayoutRoot = styled(Gap16VerticalFlex)``;

const TopContainer = styled(HorizontalFlexWrap)`
  justify-content: space-between;
  align-items: center;
`;

const ProjectDetailsContainer = styled(Gap8VerticalFlex)`
  overflow: hidden;
`;

const ProjectDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

const ProjectNameContainer = styled(Gap8HorizontalFlexWrap)`
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
`;

const ProjectName = styled(TextSubtitle)`
  font-weight: 700;
`;

const LiveBadgeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  background-color: ${({ theme }) => theme.colors.success};
  border-radius: 12px;
  width: 38px;
  height: 20px;
`;

const LiveText = styled(TextSmall)`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
`;

const Dot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: ${({ theme }) => theme.colors.ghostDark};
`;

const ProjectExtraDetails = styled(HorizontalFlexWrap)`
  align-items: center;
  gap: 6px;
  font-size: 12px;
  flex-wrap: nowrap;
  margin-top: 8px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.ghostDark};
`;

const UnreadSessionsContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.danger};
  color: ${({ theme }) => theme.colors.white};
  padding: 2px 6px;
  border-radius: 12px;
  font-weight: 700;
  font-size: 10px;
`;
