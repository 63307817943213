import React from 'react';
import Utils from 'shared/react/utils/utils';
import useFeedState from 'shared/react/components/complex/context/hooks/useFeedState';
import FeedAsset from './FeedAsset';

const FeedAssets = ({
  adjacentSteps,
  videoRef,
  handleNavigateToStep,
  stepsMap,
  outOfViewSteps,
  videosObserver,
  isLoadingVideo,
  setIsLoadingVideo,
  isSubtitlesEnabled,
}) => {
  const [{ handlePlayPause, isPlayerVisible, isPlaying, isPlayerPreview }] = useFeedState();

  const getSteps = () => {
    if (Utils.isIos() || Utils.isIphone() || Utils.isSafari() || Utils.isFirefox()) {
      return [...adjacentSteps, ...outOfViewSteps];
    }

    return [...adjacentSteps];
  };

  const steps = getSteps();

  const getValueIfIsCurrentVideo = (value, isCurrentVideo) => {
    if (!isCurrentVideo) {
      return null;
    }

    return value;
  };

  return steps.map((step, index) => {
    const { key, isCurrentVideo, isStepOutOfView, secondaryKey } = step;
    const { question: nextStepName } = stepsMap[key]?.next || {};

    return (
      <FeedAsset
        step={step}
        index={index}
        videoRef={videoRef}
        key={secondaryKey || key}
        numOfSteps={steps.length}
        videosObserver={videosObserver}
        nextStepName={getValueIfIsCurrentVideo(nextStepName, isCurrentVideo)}
        isPlaying={isPlaying}
        isCurrentVideo={isCurrentVideo}
        isLoadingVideo={isLoadingVideo}
        isPlayerPreview={isPlayerPreview}
        isPlayerVisible={isPlayerVisible}
        isStepOutOfView={isStepOutOfView}
        setIsLoadingVideo={setIsLoadingVideo}
        isSubtitlesEnabled={isSubtitlesEnabled}
        handlePlayPause={handlePlayPause}
        handleNavigateToStep={handleNavigateToStep}
      />
    );
  });
};

export default FeedAssets;
