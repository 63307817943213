import React, { useState } from 'react';
import styled from 'styled-components';
import CrossIcon from 'src/images/dashboard_v2/CrossIcon';
import MenuWithContainer from 'shared/react/components/basic/MenuWithContainer';

const LOCAL_STORAGE_KEY = 'tolstoy-image-carousel-support-banner-seen';

const POSITION = {
  vertical: 'bottom',
  horizontal: 'right',
};

type Props = {
  shouldHide?: boolean;
  anchorRef: any;
};

export const ImageSupportBanner = ({ shouldHide = false, anchorRef }: Props) => {
  const [open, setOpen] = useState(localStorage.getItem(LOCAL_STORAGE_KEY) !== 'true');

  const canCloseMenu = () => true;
  const onClose = () => {
    localStorage.setItem(LOCAL_STORAGE_KEY, 'true');
    setOpen(false);
  };

  if (shouldHide || !open) {
    return null;
  }

  return (
    <MenuWithContainer
      open={open}
      direction={POSITION}
      anchorEl={anchorRef.current}
      canCloseMenu={canCloseMenu}
    >
      <Content>
        <CrossButton onClick={onClose}>
          <CrossIcon />
        </CrossButton>
        We now support image carousels in the widgets and feeds. Select images, add music, and tag
        products to build dynamic image carousels for your site
      </Content>
    </MenuWithContainer>
  );
};

const Content = styled.div`
  padding: 18px;
  max-width: 270px;
  border-radius: 4px;
`;

const CrossButton = styled.button`
  position: absolute;
  max-width: 18px;
  max-height: 18px;
  top: 6px;
  right: 6px;
  background: none;
  border: none;
  cursor: pointer;
`;

export default ImageSupportBanner;
