import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import { getIsFeed, useProjects } from 'app/src/context/ProjectsStore';
import { SHAPE_TYPES } from 'app/src/pages/project/pages/look_and_feel/common';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import EditorButtonGroupItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorButtonGroupItem';
import EditorOnOffToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorOnOffToggleItem';
import DisabledContainer from 'shared/react/components/basic/DisabledContainer';
import Tooltip from 'app/src/basic_components/Tooltip';
import {
  BUBBLE_MARGIN_BOTTOM_PROPERTY_KEY,
  BUBBLE_MARGIN_SIDE_PROPERTY_KEY,
  KEEP_SETTINGS_ON_MOBILE_PROPERTY_KEY,
  POPUP_MODE_PROPERTY_KEY,
  SHAPE_PROPERTY_KEY,
  SHOW_POPUP_SETTING,
  VERTICAL_ORIENTATION_PROPERTY_KEY,
  WIDGET_POSITION_PROPERTY_KEY,
  WIDGET_SIZE_PROPERTY_KEY,
} from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import {
  CIRCLE_TEST_ID,
  LARGE_SIZE_DATA_TEST_ID,
  MEDIUM_SIZE_DATA_TEST_ID,
  RECTANGLE_TEST_ID,
  SMALL_SIZE_DATA_TEST_ID,
} from 'shared/react/constants/dataTestIds.constants';
import EditorTextInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorTextInputItem';
import EditorHeader from '../shared/EditorHeader';
import EditorMainContainer from '../shared/EditorMainContainer';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

function LayoutEditor() {
  const {
    customizationSettings: {
      widgetShape,
      widgetSize,
      verticalOrientation,
      widgetPosition,
      popupMode,
      widgetSettings: { keepSettingsOnMobile, bubbleMarginBottom, bubbleMarginSide },
    },
    setCustomizationSettingsProperty,
    setWidgetSettingsProperty,
  } = useLookAndFeelContext();
  const { projectId } = useParams();
  const [{ project }] = useProjects({ projectId });
  const isFeed = getIsFeed(project);

  const shapeButtons = [
    {
      text: 'Rectangle',
      isEnabled: widgetShape === SHAPE_TYPES.rectangle,
      dataTestId: RECTANGLE_TEST_ID,
      onClick: () => setCustomizationSettingsProperty(SHAPE_PROPERTY_KEY, SHAPE_TYPES.rectangle),
    },
    {
      text: 'Circle',
      isEnabled: widgetShape === SHAPE_TYPES.circle,
      dataTestId: CIRCLE_TEST_ID,
      onClick: () => setCustomizationSettingsProperty(SHAPE_PROPERTY_KEY, SHAPE_TYPES.circle),
    },
  ];

  const circleButtonSize = [
    {
      text: 'S',
      isEnabled: widgetSize === 's',
      dataTestId: SMALL_SIZE_DATA_TEST_ID,
      onClick: () => setCustomizationSettingsProperty(WIDGET_SIZE_PROPERTY_KEY, 's'),
    },
    {
      text: 'M',
      isEnabled: widgetSize === 'm',
      dataTestId: MEDIUM_SIZE_DATA_TEST_ID,
      onClick: () => setCustomizationSettingsProperty(WIDGET_SIZE_PROPERTY_KEY, 'm'),
    },
    {
      text: 'L',
      isEnabled: widgetSize === 'l',
      dataTestId: LARGE_SIZE_DATA_TEST_ID,
      onClick: () => setCustomizationSettingsProperty(WIDGET_SIZE_PROPERTY_KEY, 'l'),
    },
  ];

  const orientationButtons = [
    {
      text: 'Vertical',
      isEnabled: verticalOrientation,
      onClick: () => setCustomizationSettingsProperty(VERTICAL_ORIENTATION_PROPERTY_KEY, true),
    },
    {
      text: 'Horizontal',
      isEnabled: !verticalOrientation,
      onClick: () => setCustomizationSettingsProperty(VERTICAL_ORIENTATION_PROPERTY_KEY, false),
    },
  ];

  const positionButtons = [
    {
      text: 'Left',
      isEnabled: widgetPosition === 'bottomLeft',
      onClick: () => setCustomizationSettingsProperty(WIDGET_POSITION_PROPERTY_KEY, 'bottomLeft'),
    },
    {
      text: 'Right',
      isEnabled: widgetPosition === 'bottomRight',
      onClick: () => setCustomizationSettingsProperty(WIDGET_POSITION_PROPERTY_KEY, 'bottomRight'),
    },
  ];

  const currentSide = widgetPosition === 'bottomLeft' ? 'left' : 'right';

  return (
    <LayoutRoot>
      <EditorHeader title="Layout" />
      <EditorMainContainer>
        {SHOW_POPUP_SETTING && (
          <EditorOnOffToggleItem
            text="Use widget as a pop-up"
            checked={popupMode}
            onChange={value => setCustomizationSettingsProperty(POPUP_MODE_PROPERTY_KEY, value)}
          />
        )}
        <Tooltip
          text={popupMode ? 'The bubble settings are not applied in popup mode.' : ''}
          placement="right"
        >
          <BubbleSettingsContainer disabled={popupMode}>
            <EditorButtonGroupItem text="Shape" buttons={shapeButtons} />
            {shapeButtons.find(shape => shape.text === 'Circle')?.isEnabled && (
              <EditorButtonGroupItem text="Size" buttons={circleButtonSize} />
            )}
            <EditorOnOffToggleItem
              tooltipText="When disabled, the widget on mobile will always be a small circle."
              text="Apply shape and size to mobile"
              checked={keepSettingsOnMobile}
              onChange={value =>
                setWidgetSettingsProperty(KEEP_SETTINGS_ON_MOBILE_PROPERTY_KEY, value)
              }
            />

            {isFeed && <EditorButtonGroupItem text="Orientation" buttons={orientationButtons} />}
            <EditorButtonGroupItem text="Position" buttons={positionButtons} />
            <NumberInputItem
              text="Margin bottom"
              value={bubbleMarginBottom}
              type="number"
              onChange={value =>
                setWidgetSettingsProperty(BUBBLE_MARGIN_BOTTOM_PROPERTY_KEY, +value)
              }
            />
            <NumberInputItem
              text={`Margin ${currentSide}`}
              value={bubbleMarginSide}
              type="number"
              onChange={value => setWidgetSettingsProperty(BUBBLE_MARGIN_SIDE_PROPERTY_KEY, +value)}
            />
          </BubbleSettingsContainer>
        </Tooltip>
      </EditorMainContainer>
    </LayoutRoot>
  );
}

export default LayoutEditor;

const LayoutRoot = styled(VerticalFlex)``;

const BubbleSettingsContainer = styled(Gap16VerticalFlex)`
  ${({ disabled }) => disabled && DisabledContainer}
`;

const NumberInputItem = styled(EditorTextInputItem)`
  width: 80px;
`;
