import React from 'react';
import ImageWithBackdrop from './ImageWithBackdrop';
import ImageWithLoader from './ImageWithLoader';
import { useDesignSettingsContext } from './context/DesignSettingsContext';
import { IMAGE_BACKGROUND_TYPE, IMAGE_FIT_TYPE } from '../../constants/feed.constants';

const FittedImage = ({ ...props }) => {
  const { player } = useDesignSettingsContext();
  const { feedImageFitType, feedImageBackgroundType } =
    player.feedCards.productDetailsCard.productImages;
  const shouldAddBlurryBackground =
    feedImageFitType === IMAGE_FIT_TYPE.CONTAIN &&
    feedImageBackgroundType === IMAGE_BACKGROUND_TYPE.BLURRY;

  if (shouldAddBlurryBackground) {
    return <ImageWithBackdrop {...props} />;
  }

  return <ImageWithLoader imageFit={feedImageFitType} {...props} />;
};

export default FittedImage;
