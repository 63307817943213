import React from 'react';
import Button from 'shared/react/components/basic/Button';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { track } from '../../helpers/Tracker';

const NavigateToButton = ({
  analyticsData = {},
  onButtonClick,
  navigateFunction,
  text,
  backgroundColor,
  textColor,
  projectId,
  disabled,
}) => {
  const history = useHistory();

  const onClick = () => {
    track('Navigation Button Clicked', { ...analyticsData, text });
    if (onButtonClick) {
      onButtonClick();
      return;
    }

    navigateFunction(history, projectId);
  };

  return (
    <StyledButton
      onClick={onClick}
      borderRadius="20px"
      fontWeight={600}
      padding="5px 10px"
      backgroundColor={backgroundColor}
      hoverBackgroundColor={`${backgroundColor}F2`}
      activeBackgroundColor={`${backgroundColor}E6`}
      textColor={textColor}
      width="68px"
      fontSize="12px"
      disabled={disabled}
    >
      {text}
    </StyledButton>
  );
};

export default NavigateToButton;

const StyledButton = styled(Button)`
  line-height: 14px;
`;
