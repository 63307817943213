import React from 'react';
import styled from 'styled-components';

function Snackbar({ onClose, text, isError, ...props }) {
  return (
    <SnackbarContainer onClick={onClose} {...props}>
      <SnackbarSubContainer isError={isError}>
        <SnackbarTitle>{text}</SnackbarTitle>
        <SnackbarActionButton alt="Close">×</SnackbarActionButton>
      </SnackbarSubContainer>
    </SnackbarContainer>
  );
}

const SnackbarContainer = styled.div`
  display: grid;
  z-index: 10;
`;

const SnackbarSubContainer = styled.div`
  color: ${({ isError }) => (isError ? '#fff' : '#1e4620')};
  display: grid;
  margin: 8px 16px 16px;
  font-size: 14px;
  background: ${({ isError }) => (isError ? '#FA536E' : '#edf7ed')};
  justify-self: center;
  grid-template-columns: 1fr auto;
  justify-items: start;
  align-items: center;
  gap: 24px;
  padding: 2px 12px;
  border-radius: 10px;
  cursor: pointer;
`;
const SnackbarTitle = styled.div`
  text-align: start;
  font-weight: 600;
  text-transform: uppercase;
`;

const SnackbarActionButton = styled.span`
  font-size: 16px;
  line-height: 32px;
`;

export default Snackbar;
