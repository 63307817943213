import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useDashboard } from 'app/src/context/ui_store/DashboardStore';
import { useProjects } from 'app/src/context/ProjectsStore';
import { useHistory } from 'react-router-dom';
import { navigateToBuilder } from 'app/src/utils/navigation.utils';
import ComponentWithLoader from 'app/src/complex_components/ComponentWithLoader';
import ProjectCardLoadingState from 'app/src/pages/dashboard/pages/libraries/tolstoys_tab/project_card_v2/ProjectCardLoadingState';
import ProjectCardV2 from 'app/src/pages/dashboard/pages/libraries/tolstoys_tab/project_card_v2/ProjectCardV2';
import EmptyStateBody from 'app/src/pages/dashboard/pages/libraries/tolstoys_tab/empty_state/EmptyStateBody';
import { track } from 'app/src/helpers/Tracker';
import { useFeatures } from 'app/src/context/FeaturesStore';
import {
  FEATURE_DASHBOARD_V2_NEW_FOLDERS,
  FEATURE_REMOVE_FOLDERS_DESIGN,
} from 'shared/react/constants/features.constants';
import FolderCard from 'app/src/pages/dashboard/pages/libraries/tolstoys_tab/folder_card/FolderCard';

const ProjectCardsContainer = ({
  projects,
  shouldShowEmptyStateBody,
  shownCardsNumber,
  folders,
  folderMapping,
  setSelectedTolstoys,
  selectedTolstoys,
  searchValue,
  disableExtraTolstoyClick,
  onProjectClick,
}) => {
  const [{ isCreatingDuplication, selectedFolder, sortBy, ascending }, { setSelectedFolder }] =
    useDashboard();
  const [{ loading }] = useProjects();
  const [, { getFeatureEnabled }] = useFeatures();
  const isNewFolderView = getFeatureEnabled(FEATURE_DASHBOARD_V2_NEW_FOLDERS);
  const noFolders = getFeatureEnabled(FEATURE_REMOVE_FOLDERS_DESIGN);
  const history = useHistory();

  const folderMap = useMemo(() => {
    const map = {};
    folders?.forEach(folder => {
      map[folder.id] = folder;
    });
    return map;
  }, [folders]);

  const shownCards = useMemo(() => {
    if (!projects) {
      return [];
    }

    return projects.slice(0, shownCardsNumber);
  }, [projects, shownCardsNumber, sortBy, ascending]);

  const handleNavigateToProject = project => {
    track('Project Card Click');
    const stepId = project.feed ? '' : project.steps.items?.[0]?.id;

    return navigateToBuilder(history, project.id, stepId);
  };

  if (shouldShowEmptyStateBody) {
    return (
      <ComponentWithLoader isLoading={loading}>
        <EmptyStateBody />
      </ComponentWithLoader>
    );
  }

  const shouldShowFolders = !noFolders && !selectedFolder && isNewFolderView;
  return (
    <LayoutRoot>
      <ComponentWithLoader isLoading={loading}>
        {isCreatingDuplication && (
          <ProjectCardLoadingState
            setSelectedTolstoys={setSelectedTolstoys}
            selectedTolstoys={selectedTolstoys}
          />
        )}
        {shouldShowFolders &&
          folders?.map(folder => {
            return (
              <FolderCard
                key={folder.id}
                folder={folder}
                projects={folderMapping[folder.id]}
                onClick={() => setSelectedFolder(folder)}
                searchValue={searchValue}
              />
            );
          })}
        {shownCards.map(project => {
          return (
            <ProjectCardV2
              project={project}
              key={project.id}
              handleNavigateToProject={handleNavigateToProject}
              setSelectedTolstoys={setSelectedTolstoys}
              selectedTolstoys={selectedTolstoys}
              folder={noFolders && folderMap[project.folder]}
              onProjectClick={onProjectClick}
              disableExtraTolstoyClick={disableExtraTolstoyClick}
            />
          );
        })}
      </ComponentWithLoader>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  gap: 24px;
  padding: 0 24px 8px 0;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    padding: 0 24px 8px;
  }
`;

export default ProjectCardsContainer;
