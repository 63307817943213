import React, { useState } from 'react';
import AreYouSureModal from 'app/src/pages/modals/AreYouSureModal';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { useVideos } from 'app/src/context/VideosStore';
import useIsMounted from 'shared/react/hooks/useIsMounted';

const DeleteVideoModal = () => {
  const [{ currentIds }, { setCurrentModal, setCurrentId }] = useModal();
  const [, { deleteVideo }] = useVideos();
  const [loading, setLoading] = useState(false);
  const isMounted = useIsMounted();

  const onClose = () => {
    setCurrentId('');
    setCurrentModal('');
  };

  const onDelete = async () => {
    setLoading(true);
    const promises = currentIds.map(id => {
      return deleteVideo({ id });
    });

    await Promise.all(promises);
    if (!isMounted()) {
      return;
    }

    onClose();
  };

  return (
    <AreYouSureModal
      closeButtonClicked={onClose}
      open={true}
      loading={loading}
      actionButtonClicked={onDelete}
      secondaryButtonClicked={onClose}
    />
  );
};

export default DeleteVideoModal;
