import Utils from 'player/src/utils';
import useCustomerLogin from 'shared/react/components/complex/context/hooks/useCustomerLogin';
import useFeedCart from 'shared/react/components/complex/context/hooks/useFeedCart';
import useFeedState from 'shared/react/components/complex/context/hooks/useFeedState';
import useProductRecommendations from 'shared/react/components/complex/context/hooks/useProductRecommendations';
import {
  LOGIN_WITH_MULTIPASS_URL_REQUEST_MESSAGE,
  CART_DATA_REQUEST_MESSAGE,
  PRODUCT_RECOMMENDATIONS_REQUEST_MESSAGE,
} from 'shared/react/constants/shopifyMessaging.constants';
import SharedUtils from 'shared/react/utils/utils';

const useShopify = () => {
  const [{ setMessagingRequestDetails, setShopifyResponse, isShopifyStore }] = useFeedState();
  const [, { setCart }] = useFeedCart();
  const [{ productRecommendationsMap }, { setProductRecommendations }] =
    useProductRecommendations();
  const [, { setCustomerLoginStatusCode }] = useCustomerLogin();

  const requestShopifyCart = () => {
    if (!isShopifyStore) {
      return;
    }

    SharedUtils.postMessageToWindow({
      eventName: CART_DATA_REQUEST_MESSAGE,
    });
  };

  const handleCartResponse = data => {
    setShopifyResponse(data);
    setMessagingRequestDetails({});
  };

  const handleGetCartResponse = ({ cart }) => {
    setCart({
      currencyCode: cart.currency,
      totalPrice: cart.total_price,
      itemsCount: cart.item_count,
      items: cart.items.map(item => ({
        title: item.product_title,
        description: item.product_description,
        optionsWithValues: item.options_with_values,
        imageUrl: item.image,
        price: item.price,
        quantity: item.quantity,
        variantId: item.variant_id,
        variantTitle: item.variant_title,
        sellingPlanAllocation: item.selling_plan_allocation,
      })),
    });
  };

  const handleAddToCartError = data => {
    Utils.logError(`Error adding item ${data.variantId} to cart`, data.error);
    handleCartResponse(data);
  };

  const handleUpdateItemQuantityError = data => {
    Utils.logError(`Error updating item ${data.variantId} quantity`, data.error);
    handleCartResponse(data);
  };

  const requestProductRecommendations = productId => {
    if (!isShopifyStore || productRecommendationsMap[productId]) {
      return;
    }

    SharedUtils.postMessageToWindow({
      eventName: PRODUCT_RECOMMENDATIONS_REQUEST_MESSAGE,
      productId,
    });
  };

  const handleGetProductRecommendationsResponse = ({ productId, products }) => {
    setProductRecommendations(productId, products);
  };

  const handleLoginWithMultipassUrl = multipassUrl => {
    if (!multipassUrl) {
      return;
    }

    SharedUtils.postMessageToWindow({
      eventName: LOGIN_WITH_MULTIPASS_URL_REQUEST_MESSAGE,
      multipassUrl,
    });
  };

  const handleLoginWithMultipassUrlResponse = ({ statusCode }, publishId, currentStep) => {
    setCustomerLoginStatusCode(statusCode);
    if (publishId && currentStep?.videoId && statusCode === 200) {
      SharedUtils.reloadPageWithPlayer(publishId, currentStep.videoId);
    }
  };

  const actions = {
    requestShopifyCart,
    handleCartResponse,
    handleGetCartResponse,
    handleAddToCartError,
    handleUpdateItemQuantityError,
    requestProductRecommendations,
    handleGetProductRecommendationsResponse,
    handleLoginWithMultipassUrl,
    handleLoginWithMultipassUrlResponse,
  };

  return [{}, actions];
};

export default useShopify;
