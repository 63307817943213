import React from 'react';
import styled from 'styled-components';

import Menu from 'shared/react/components/basic/Menu';
import { ComparisonOperator } from 'src/pages/dashboard/utils/filterEntities';
import { MenuLayout } from 'src/pages/dashboard/components/filters/components/filter-block/FilterBlock';
import ComparatorMenuContent from '../comparator-menu-content/ComparatorMenuContent';

type Props = {
  filterKey: string;
  anchorRef: JSX.Element;
  onClose: () => void;
  currentFilter: ComparisonOperator;
  setFilters: (filters: { [key: string]: ComparisonOperator }) => void;
};

const ComparatorMenu = ({ filterKey, anchorRef, onClose, currentFilter, setFilters }: Props) => (
  <Menu
    open={!!anchorRef}
    anchorEl={anchorRef}
    onClose={onClose}
    direction={{ vertical: 'bottom', horizontal: 'left' }}
    canCloseMenu={undefined}
    className={undefined}
  >
    <ComparatorMenuLayout>
      <ComparatorMenuContent
        filterKey={filterKey}
        onClose={onClose}
        filter={currentFilter}
        setFilters={setFilters}
      />
    </ComparatorMenuLayout>
  </Menu>
);

const ComparatorMenuLayout = styled(MenuLayout)`
  width: 108px;
`;

export default ComparatorMenu;
