import React from 'react';
import styled from 'styled-components';
import EditorTextInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorTextInputItem';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import EditorColorInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorColorInputItem';
import {
  COLLECT_FIELDS_PROPERTY_KEY,
  COLLECT_TEXT_PROPERTY_KEY,
  DEFAULT_COLLECT_FIELDS,
  DEFAULT_COLLECT_TEXT,
} from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import Utils from 'app/src/utils';
import PremiumFeatureTooltip from 'app/src/pages/account/branding/PremiumFeatureTooltip';
import ResetToDefaultInputWrapper from 'app/src/complex_components/ResetToDefaultInputWrapper';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';
import {
  CONTACT_FORM_CTA_TEXT_TYPE,
} from 'shared/react/constants/feature-constants/contactFormCustomization.constants';
import EditorHeader from '../shared/EditorHeader';
import EditorMainContainer from '../shared/EditorMainContainer';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const AppearanceEditor = () => {
  const {
    customizationSettings: { collectText, collectFields },
    setCustomizationSettingsProperty,
  } = useLookAndFeelContext();
  const {
    player,
  } = useDesignSettingsContext();
  const contactFormSettings = player.branching.contactForm;

  const handleCtaTextChange = value => {
    setCustomizationSettingsProperty(COLLECT_TEXT_PROPERTY_KEY, value);
  };

  const handleBackgroundColorChange = value => {
    const newCollectFields = collectFields.map(collectField => ({
      ...collectField,
      backgroundColor: value,
    }));
    setCustomizationSettingsProperty(COLLECT_FIELDS_PROPERTY_KEY, newCollectFields);
  };

  const handleResetCtaText = () => {
    handleCtaTextChange(
      contactFormSettings.ctaText || DEFAULT_COLLECT_TEXT || ''
    );
  };

  const handleResetBackgroundColor = () => {
    handleBackgroundColorChange(
      contactFormSettings.color ||
        DEFAULT_COLLECT_FIELDS?.backgroundColor ||
        ''
    );
  };

  const firstCollectField = collectFields.find(field => field.backgroundColor);

  return (
    <LayoutRoot>
      <EditorHeader title="Appearance" />
      <EditorMainContainer>
        <PremiumFeatureTooltip
          header="CTA text"
          analyticsData={Utils.getAnalyticsLocationObject('CTA text')}
          fullWidthContainer={false}
        >
          <ResetToDefaultInputWrapper
            onClick={() =>
              handleResetCtaText(
                COLLECT_TEXT_PROPERTY_KEY,
                CONTACT_FORM_CTA_TEXT_TYPE,
                DEFAULT_COLLECT_TEXT
              )
            }
          >
            <EditorTextInputItem
              text="CTA text"
              value={collectText}
              onChange={handleCtaTextChange}
            />
          </ResetToDefaultInputWrapper>
        </PremiumFeatureTooltip>

        <PremiumFeatureTooltip
          header="Background"
          analyticsData={Utils.getAnalyticsLocationObject('Background')}
          fullWidthContainer={false}
        >
          <ResetToDefaultInputWrapper
            onClick={handleResetBackgroundColor}
          >
            <EditorColorInputItem
              text="Background"
              color={firstCollectField?.backgroundColor}
              onChange={handleBackgroundColorChange}
            />
          </ResetToDefaultInputWrapper>
        </PremiumFeatureTooltip>
      </EditorMainContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

export default AppearanceEditor;
