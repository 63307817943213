import React from 'react';
import styled, { css } from 'styled-components';
import { track } from 'app/src/helpers/Tracker';
import { useDashboard } from 'app/src/context/ui_store/DashboardStore';
import SortArrow from 'app/src/images/SortArrow';
import { Menu } from '@material-ui/core';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const SortV2Menu = ({ anchorEl, setAnchorEl, sortOptions, currentOption, setCurrentOption }) => {
  const [{ sortBy, ascending }, { setSortBy, setAscending }] = useDashboard();

  const handleSortMenuClosed = () => {
    setAnchorEl(null);
  };

  const handleSortOptionClicked = (e, option) => {
    e.stopPropagation();

    if (currentOption === option) {
      setAscending(!ascending);
      track('Sort by', { sortBy, type: ascending ? 'asc' : 'desc' });
      return;
    }

    setAscending(false);
    setCurrentOption(option);
    setSortBy(option);
    track('Sort by', { sortBy, type: ascending ? 'asc' : 'desc' });
  };

  return (
    <StyledMenu
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      getContentAnchorEl={null}
      anchorEl={anchorEl}
      disableAutoFocusItem
      open={!!anchorEl}
      onClick={handleSortMenuClosed}
      onClose={handleSortMenuClosed}
    >
      <SortByContainer>
        <SortByText>Sort by</SortByText>
        {Object.entries(sortOptions).map(([key, name]) => {
          return (
            <SortOptionContainer
              key={key || 'createdAt'}
              onClick={e => handleSortOptionClicked(e, key)}
            >
              {name}
              {currentOption === key && <StyledSortArrow ascending={ascending} />}
            </SortOptionContainer>
          );
        })}
      </SortByContainer>
    </StyledMenu>
  );
};

const StyledMenu = styled(Menu)`
  .MuiPaper-rounded {
    border-radius: 12px;
  }
`;

const SortByContainer = styled(VerticalFlex)`
  width: 216px;
  border-radius: 32px;
`;

const SortMenuCss = css`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
`;

const SortByText = styled.div`
  ${SortMenuCss};
  padding: 16px 16px 8px;
  color: ${({ theme }) => theme.colors.ghostDark};
`;

const SortOptionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  ${SortMenuCss};
  padding: 8px;
  margin: 0 8px;
  border-radius: 8px;
  height: 32px;
  user-select: none;

  &:hover {
    background: ${({ theme }) => theme.colors.gray5};
    cursor: pointer;
  }
`;

const StyledSortArrow = styled(SortArrow)`
  transform: rotate(${({ ascending }) => (ascending ? '180' : '0')}deg);
  transition: 0.3s;
`;

export default SortV2Menu;
