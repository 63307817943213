import React from 'react';
import ComponentWithLoader from 'app/src/complex_components/ComponentWithLoader';
import styled from 'styled-components';
import Logo from 'app/src/pages/account/branding/editors/general/Logo';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

const EditorLogoPreview = ({ errorMessage, userImageSrc, loading }) => {
  return (
    <LayoutRoot $isError={!!errorMessage}>
      <ComponentWithLoader isLoading={loading}>
        <Logo userImageSrc={userImageSrc} />
      </ComponentWithLoader>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)<{ $isError: boolean }>`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  min-width: 125px;
  min-height: 125px;
  max-width: 400px;
  max-height: 400px;
  border-radius: 8px;
  border: ${({ $isError, theme }) => ($isError ? `1px solid ${theme.colors.error}` : '')};
`;

export default EditorLogoPreview;
