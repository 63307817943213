import React, { FC } from 'react';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';
import useTopBar from 'app/src/hooks/useTopBar';
import useBuilderForm from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/useBuilderForm';
import { DesignSettingsProvider } from 'shared/react/components/complex/context/DesignSettingsContext';
import { ProjectsContainer } from 'app/src/context/ProjectsStore';
import { AccountContainer } from 'app/src/context/AccountStore';
import { ProductPageSettingsContainer } from 'app/src/context/ProductPageSettingsStore';
import { FoldersContainer } from 'app/src/context/FoldersStore';
import { SessionsContainer } from 'app/src/context/SessionsStore';
import { ResponsesContainer } from 'app/src/context/ResponsesStore';
import { PackagesContainer } from 'app/src/context/PackagesStore';
import { InAppNotificationsContainer } from 'app/src/context/InAppNotificationsStore';
import { AppsContainer } from 'app/src/context/AppsStore';
import { ProductsContainer } from 'app/src/context/ProductsStore';
import { RulesContainer, useRules } from 'app/src/context/RulesStore';
import { TikTokContainer } from 'app/src/context/TikTokStore';
import { InstagramContainer } from 'app/src/context/InstagramStore';
import { GoogleDriveContainer } from 'app/src/context/GoogleDriveStore';
import { HubspotContainer } from 'app/src/context/integrations/HubspotStore';
import { ProjectAppsContainer } from 'app/src/context/ProjectAppStore';
import { ImportVideosContainer } from 'app/src/context/ui_store/ImportVideosStore';
import { IntegrationVodAssetContainer } from 'app/src/context/IntegrationVodAssetStore';
import { VodConnectionContainer } from 'app/src/context/VodConnectionStore';
import { VodLabelsContainer } from 'app/src/context/VodLabelsStore';
import { BuilderFormProvider } from 'app/src/context/BuilderFormStore';
import { TopBarProvider } from 'app/src/context/TopBarStore';
import { useDesignSettings } from 'src/hooks/design';
import { AddProductVideosContainer } from 'src/context/ui_store/AddProductVideosStore';
import { useVideoActions, VideosContainer } from 'app/src/context/VideosStore';
import { YoutubeContainer } from 'app/src/context/YoutubeStore';
import { VodConnectionSuggestionContainer } from 'src/context/VodConnectionSuggestionStore';
import { useStudioAuth } from 'src/hooks/studio';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { useDrawerActions } from 'app/src/context/ui_store/DrawerStore';

type Props = {
  accountDetails: any;
  children: React.ReactNode;
};

const UserContainers: FC<Props> = ({ accountDetails, children }) => {
  const [{ data: features }, { getFeatureEnabled, getSettingsByKey }] = useFeatures();
  const history = useHistory();
  const [, snackbarActions] = useSnackBar();
  const [, { saveRules }] = useRules();
  const [, { clearModalState }] = useModal();
  const { setCurrentDrawer } = useDrawerActions();
  const topBarProviderValue = useTopBar();
  const builderFormProviderValue = useBuilderForm();
  const designSettings = useDesignSettings();
  const {
    addVodConnectionToVodAsset,
    removeVodConnectionToVodAsset,
    updateVodConnectionToVodAsset,
  } = useVideoActions();

  useStudioAuth();

  if (!accountDetails || !Object.keys(features || {})?.length) {
    return (
      <LoadingContainer>
        <CircularProgress size={24} />
      </LoadingContainer>
    );
  }

  const containerParams = {
    features,
    getFeatureEnabled,
    getSettingsByKey,
    history,
    ...snackbarActions,
    ...accountDetails,
    clearModalState,
    setCurrentDrawer,
  };

  const connectionParams = {
    ...containerParams,
    addVodConnectionToVodAsset,
    removeVodConnectionToVodAsset,
    updateVodConnectionToVodAsset,
  };

  return (
    <VideosContainer {...containerParams} isGlobal>
      <ProjectsContainer saveRules={saveRules} {...containerParams} isGlobal>
        <AccountContainer {...containerParams} isGlobal>
          <ProductPageSettingsContainer {...containerParams} isGlobal>
            <FoldersContainer {...containerParams} isGlobal>
              <SessionsContainer {...containerParams} isGlobal>
                <ResponsesContainer {...containerParams} isGlobal>
                  <PackagesContainer {...containerParams} isGlobal>
                    <InAppNotificationsContainer {...containerParams} isGlobal>
                      <AppsContainer {...containerParams} isGlobal>
                        <ProductsContainer {...containerParams} isGlobal>
                          <RulesContainer {...containerParams} isGlobal>
                            <TikTokContainer {...containerParams}>
                              <InstagramContainer {...containerParams} isGlobal>
                                <GoogleDriveContainer {...containerParams} isGlobal>
                                  <YoutubeContainer {...containerParams} isGlobal>
                                    <HubspotContainer {...containerParams} isGlobal>
                                      <ProjectAppsContainer {...containerParams} isGlobal>
                                        <ImportVideosContainer {...containerParams} isGlobal>
                                          <IntegrationVodAssetContainer {...containerParams}>
                                            <VodConnectionContainer {...connectionParams}>
                                              <VodLabelsContainer {...containerParams}>
                                                <BuilderFormProvider
                                                  value={builderFormProviderValue}
                                                >
                                                  <TopBarProvider value={topBarProviderValue}>
                                                    <DesignSettingsProvider value={designSettings}>
                                                      <AddProductVideosContainer>
                                                        <VodConnectionSuggestionContainer
                                                          {...containerParams}
                                                        >
                                                          {children}
                                                        </VodConnectionSuggestionContainer>
                                                      </AddProductVideosContainer>
                                                    </DesignSettingsProvider>
                                                  </TopBarProvider>
                                                </BuilderFormProvider>
                                              </VodLabelsContainer>
                                            </VodConnectionContainer>
                                          </IntegrationVodAssetContainer>
                                        </ImportVideosContainer>
                                      </ProjectAppsContainer>
                                    </HubspotContainer>
                                  </YoutubeContainer>
                                </GoogleDriveContainer>
                              </InstagramContainer>
                            </TikTokContainer>
                          </RulesContainer>
                        </ProductsContainer>
                      </AppsContainer>
                    </InAppNotificationsContainer>
                  </PackagesContainer>
                </ResponsesContainer>
              </SessionsContainer>
            </FoldersContainer>
          </ProductPageSettingsContainer>
        </AccountContainer>
      </ProjectsContainer>
    </VideosContainer>
  );
};

const LoadingContainer = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  place-content: center;
`;

export default UserContainers;
