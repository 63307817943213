import React from 'react';
import styled, { css } from 'styled-components';
import Expander from 'shared/react/components/complex/expanders/Expander';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import RulesEventTriggersEditor from 'app/src/pages/project/pages/rules_and_triggers/editor/event_triggers/RulesEventTriggersEditor';
import RulesLocationEditor from 'app/src/pages/project/pages/rules_and_triggers/editor/location/RulesLocationEditor';
import RulesDevicesEditor from 'app/src/pages/project/pages/rules_and_triggers/editor/devices/RulesDevicesEditor';
import RulesPagesIcon from 'shared/react/images/rules/RulesPagesIcon';
import RulesEventTriggersIcon from 'shared/react/images/rules/RulesEventTriggersIcon';
import RulesLocationIcon from 'shared/react/images/rules/RulesLocationIcon';
import RulesAudienceIcon from 'shared/react/images/rules/RulesAudienceIcon';
import RulesDevicesIcon from 'shared/react/images/rules/RulesDevicesIcon';
import RulesAudienceEditor from 'app/src/pages/project/pages/rules_and_triggers/editor/audience/RulesAudienceEditor';
import RulesPagesEditor from 'app/src/pages/project/pages/rules_and_triggers/editor/pages/RulesPagesEditor';
import { useRulesContext } from 'app/src/pages/project/pages/rules_and_triggers/RulesContext';
import { RulesEditorProvider } from 'app/src/pages/project/pages/rules_and_triggers/editor/RulesEditorContext';
import WidgetRules from 'app/src/pages/project/pages/rules_and_triggers/editor/shared/WidgetRules';
import RulesStoresIcon from 'shared/react/images/rules/RulesStoresIcon';
import RulesStoresEditor from 'app/src/pages/project/pages/rules_and_triggers/editor/stores/RulesStoresEditor';
import RulesProductPagesIcon from 'shared/react/images/rules/RulesProductPagesIcon';
import RulesProductPagesEditor from 'app/src/pages/project/pages/rules_and_triggers/editor/product-pages/RulesProductPagesEditor';
import InfoIconWithTooltip from 'app/src/complex_components/InfoIconWithTooltip';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { publishMethodOptions } from 'app/src/types/entities';

function RulesEditor({ shopifyApps }) {
  const { ruleGroup, setRuleGroup, setUnsaved, project } = useRulesContext();

  const shouldShowStores = () => {
    if (shopifyApps?.length > 1) {
      return false;
    }

    return true;
  };

  const shouldShowBubbleRules =
    !project.publishMethod ||
    [publishMethodOptions.bubble, publishMethodOptions.unassigned].includes(project.publishMethod);

  const categories = [
    {
      title: 'Product pages',
      disabled: !project.feed || shouldShowBubbleRules,
      icon: <RulesProductPagesIcon />,
      content: <RulesProductPagesEditor />,
      titleTooltip: 'Select specific product pages to publish the Tolstoy on.',
    },
    {
      title: 'Stores',
      disabled: shouldShowStores(),
      icon: <RulesStoresIcon />,
      content: <RulesStoresEditor />,
    },
    {
      title: 'Pages',
      icon: <RulesPagesIcon />,
      isDefaultExpanded: true,
      content: <RulesPagesEditor />,
      disabled: project.feed && !shouldShowBubbleRules,
    },
    {
      title: 'Event triggers',
      icon: <RulesEventTriggersIcon />,
      content: <RulesEventTriggersEditor />,
      disabled: project.feed && !shouldShowBubbleRules,
    },
    {
      title: 'Location',
      disabled: true,
      icon: <RulesLocationIcon />,
      content: <RulesLocationEditor />,
    },
    {
      title: 'Audience',
      icon: <RulesAudienceIcon />,
      content: <RulesAudienceEditor />,
      disabled: project.feed && !shouldShowBubbleRules,
    },
    {
      title: 'Devices',
      icon: <RulesDevicesIcon />,
      content: <RulesDevicesEditor />,
      disabled: project.feed && !shouldShowBubbleRules,
    },
  ];

  function deleteRule(andConditionIndex, ruleKey) {
    const newRuleGroup = {
      ...ruleGroup,
    };
    const andCondition = newRuleGroup.rules[andConditionIndex];
    const moreThanOneRule = andCondition.length > 1;
    if (moreThanOneRule) {
      newRuleGroup.rules[andConditionIndex] = andCondition.filter(
        condition => condition.key !== ruleKey
      );
    } else {
      newRuleGroup.rules = newRuleGroup.rules.filter(
        rule => !rule.some(condition => condition.key === ruleKey)
      );
    }

    setRuleGroup(newRuleGroup);
    setUnsaved(true);
  }

  function getProviderValue() {
    return { deleteRule };
  }

  return (
    <RulesEditorProvider value={getProviderValue()}>
      <LayoutRoot>
        {!project.feed && <WidgetRules />}
        {categories
          .filter(({ disabled }) => !disabled)
          .map(({ title, icon, content, isDefaultExpanded, titleTooltip }) => (
            <Expander
              key={title}
              title={
                <ExpanderHeaderText>
                  {title}
                  <InfoIconWithTooltip title={titleTooltip} />
                </ExpanderHeaderText>
              }
              icon={icon}
              isDefaultExpanded={isDefaultExpanded}
              contentContainerStyle={
                title !== 'Event triggers' &&
                css`
                  padding: 8px 8px 16px 16px;
                `
              }
            >
              {content}
            </Expander>
          ))}
      </LayoutRoot>
    </RulesEditorProvider>
  );
}

export default RulesEditor;

const LayoutRoot = styled(Gap16VerticalFlex)`
  height: fit-content;
  width: 516px;
`;

const ExpanderHeaderText = styled(HorizontalFlex)`
  gap: 12px;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
  }

  path {
    fill: ${props => props.theme.colors.black};
  }
`;
