import React from 'react';

const EarthIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 0c4.406 0 8 3.594 8 8 0 4.438-3.594 8-8 8-4.438 0-8-3.563-8-8 0-4.406 3.563-8 8-8Zm0 14.5c.219 0 .813-.219 1.469-1.5.281-.563.5-1.219.687-2H5.812c.188.781.407 1.438.688 2 .656 1.281 1.25 1.5 1.5 1.5Zm-2.438-5h4.844a12.063 12.063 0 0 0 0-3H5.562C5.5 7 5.5 7.5 5.5 8c0 .531 0 1.031.063 1.5ZM10.157 5c-.187-.75-.406-1.406-.687-1.969C8.813 1.75 8.219 1.5 8 1.5c-.25 0-.844.25-1.5 1.531C6.219 3.594 6 4.25 5.812 5h4.344Zm1.75 1.5a12.063 12.063 0 0 1 0 3h2.406c.126-.469.188-.969.188-1.5 0-.5-.063-1-.188-1.5h-2.406Zm-1.219-4.406c.438.812.782 1.812 1 2.906h2.063a6.43 6.43 0 0 0-3.063-2.906Zm-5.406 0A6.43 6.43 0 0 0 2.22 5h2.06c.219-1.094.563-2.094 1-2.906ZM1.5 8c0 .531.031 1.031.156 1.5h2.407C4 9.031 4 8.531 4 8c0-.5 0-1 .063-1.5H1.655C1.531 7 1.5 7.5 1.5 8Zm12.25 3h-2.063c-.218 1.125-.562 2.125-1 2.938A6.351 6.351 0 0 0 13.75 11Zm-9.469 0H2.22a6.351 6.351 0 0 0 3.062 2.938c-.437-.813-.781-1.813-1-2.938Z"
        fill="#52555B"
      />
    </svg>
  );
};

export default EarthIcon;
