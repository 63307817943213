import React from 'react';
import styled from 'styled-components';
import QuizFlow from 'app/src/pages/project/pages/quiz/quiz_result/flow/QuizFlow';
import Gap16HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap16HorizontalFlexWrap';
import AddFlowButton from 'app/src/pages/project/pages/quiz/quiz_result/flow/AddFlowButton';

function QuizFlowList({ quizResultId, flows }) {
  return (
    <LayoutRoot>
      {flows?.map(flow => (
        <QuizFlow key={flow.id} flow={flow} quizResultId={quizResultId} />
      ))}
      <AddFlowButton quizResultId={quizResultId} />
    </LayoutRoot>
  );
}

export default QuizFlowList;

const LayoutRoot = styled(Gap16HorizontalFlexWrap)`
  flex-wrap: wrap;
`;
