import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { getLetterIcon } from '../../utils/icons';

function ChoiceButton({
  onClick,
  text,
  index,
  circle,
  icon,
  hasSubtitles,
  disabled,
  customLayout,
  wide,
  selected,
  isMultipleChoiceSelection,
  stepAnswersCount,
  dataTestId,
  brandColor,
  buttonsSettings,
  showButtonsIndex = true,
  hasAnswersWithImages,
  setNextRef = () => {},
  ...props
}) {
  const buttonRef = useRef(null);
  const iconElement = icon || (showButtonsIndex && circle && getLetterIcon(index, brandColor));
  const shouldShowImages = !!hasAnswersWithImages || (showButtonsIndex && circle);

  return (
    <LayoutRoot
      ref={ref => {
        buttonRef.current = ref;
        setNextRef(ref);
      }}
      disabled={disabled}
      hasSubtitles={hasSubtitles}
      stepAnswersCount={stepAnswersCount}
      circle={circle}
      wide={wide}
      selected={selected}
      isMultipleChoiceSelection={isMultipleChoiceSelection}
      onClick={handleClick}
      style={customLayout?.answerButton}
      data-test-id={dataTestId}
      brandColor={brandColor}
      height={buttonsSettings?.height}
      shouldShowImages={shouldShowImages}
      {...props}
    >
      <IconContainer shouldShowImages={shouldShowImages} style={getIconContainerCustomStyle()}>
        {iconElement}
      </IconContainer>
      <Text
        shouldShowImages={shouldShowImages}
        data-cy={'btn-text'}
        fontSize={buttonsSettings?.fontSize}
      >
        {text}
      </Text>
    </LayoutRoot>
  );

  function handleClick(e) {
    e.stopPropagation();
    onClick && onClick(buttonRef);
  }

  function getIconContainerCustomStyle() {
    if (icon) {
      return {};
    }
    return customLayout?.letterIcon;
  }
}

export default ChoiceButton;

const LayoutRoot = styled.div`
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.6);
  border: 1px solid rgb(255, 255, 255, 0.3);
  padding: ${({ circle, shouldShowImages }) =>
    circle && shouldShowImages ? '0 12px 0 16px' : '0 12px'};
  border-radius: ${({ borderRadius }) => borderRadius ?? '44'}px;
  box-sizing: border-box;
  color: white;
  transition: opacity 0.3s;
  margin: 5px;
  justify-content: ${({ circle, shouldShowImages }) =>
    circle && shouldShowImages ? 'normal' : 'center'};
  ${props =>
    props.disabled
      ? css`
          opacity: 0.6;
          cursor: default;
        `
      : ''}
  min-width: ${props => (props.stepAnswersCount >= 4 ? 'calc(100% * (1 / 5) + 7em)' : '200px')};
  max-width: 268px;
  flex: 1;
  display: flex;
  align-items: center;
  min-height: ${({ height = '50px' }) => height};
  height: fit-content;
  gap: 16px;

  &:last-of-type {
    margin-bottom: ${({ hasSubtitles }) => (hasSubtitles ? '12px' : '')};
  }

  :hover {
    opacity: 0.8;
  }

  :focus {
    outline: none;
  }

  :focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }

  :active {
    box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.2);
    outline: none;
  }

  @media only screen and (max-width: 768px) {
    max-width: 300px;
  }

  @media only screen and (max-width: 450px), screen and (hover: none) and (pointer: coarse) {
    white-space: normal;
    width: 100%;
    max-width: 100%;
    justify-items: stretch;
    margin-left: 15px;
    margin-right: 15px;

    ${props =>
      props.wide
        ? css`
            color: black;
            border-color: black;
          `
        : ''}
  }

  @media only screen and (max-height: 645px) {
    max-width: 300px;
  }

  ${({ selected, brandColor, theme }) =>
    selected
      ? css`
          border: 2px solid ${brandColor ? brandColor : theme.colors.primary};

          :hover {
            border: 2px solid
              ${brandColor ? theme.getHexOpacity(brandColor, 80) : theme.colors.primaryHover};
            box-shadow: none;
          }

          :active {
            border: 2px solid
              ${brandColor ? theme.getHexOpacity(brandColor, 90) : theme.colors.primaryActive};
            box-shadow: none;
          }
        `
      : ''}
`;

const IconContainer = styled.div`
  min-width: 20px;
  min-height: 20px;
  flex-direction: column;
  justify-content: center;
  display: ${({ shouldShowImages }) => (shouldShowImages ? 'flex' : 'none')};
`;

const Text = styled.div`
  line-height: 18px;
  font-size: ${({ fontSize = '16px' }) => fontSize};
  font-weight: 500;
  letter-spacing: 0.02em;
  text-align: ${({ shouldShowImages }) => (shouldShowImages ? 'left' : 'center')};

  @media only screen and (max-width: 768px) {
    font-size: ${({ fontSize = '17px' }) => fontSize};
  }
`;
