import { DynamicTypeFormValues } from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/DynamicTypeContent';
import { MatchConditions } from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/constants/dynamicConditions.constants';

export const getRules = ({ rules, matchConditions, videoRules }: DynamicTypeFormValues) => {
  let value;
  if (matchConditions === MatchConditions.matchAll) {
    value = [rules];
  } else {
    value = rules.map(rule => [rule]);
  }

  if (videoRules.length) {
    value.push(videoRules);
  }

  return value;
};
