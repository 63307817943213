import React from 'react';
import styled from 'styled-components';
import TextInput from 'app/src/basic_components/TextInput';
import EditorItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorItem';

type Props = {
  text: string;
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  maxLength?: number;
  placeholderRtl?: boolean;
  type?: string;
  maxWidth?: string;
  error?: boolean;
  helperText?: string;
  tooltipText?: string;
};

const EditorTextInputItem = ({
  text,
  placeholder,
  value,
  onChange,
  disabled,
  maxLength,
  placeholderRtl,
  type,
  maxWidth,
  tooltipText,
  ...props
}: Props) => {
  return (
    <EditorItem text={text} tooltipText={tooltipText}>
      <StyledTextInput
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onChange={e => onChange(e.target.value)}
        maxLength={maxLength}
        maxWidth={maxWidth || '196px'}
        $placeholderRtl={placeholderRtl}
        type={type}
        {...props}
      />
    </EditorItem>
  );
};

const StyledTextInput = styled(TextInput)`
  direction: ${({ $placeholderRtl }) => ($placeholderRtl ? 'rtl' : '')};
  overflow: hidden;

  .MuiInputBase-root {
    height: 40px;

    input:focus {
      direction: ltr;
    }
  }
`;

export default EditorTextInputItem;
