export const FILTER_DATA_TYPE = 'analytics-filter';

export const DATE_FILTER_TODAY = 'Today';
export const DATE_FILTER_YESTERDAY = 'Yesterday';
export const DATE_FILTER_LAST_24_HOURS = 'Last 24 hours';
export const DATE_FILTER_LAST_3_DAYS = 'Last 3 days';
export const DATE_FILTER_LAST_7_DAYS = 'Last 7 days';
export const DATE_FILTER_LAST_30_DAYS = 'Last 30 days';
export const DATE_FILTER_LAST_60_DAYS = 'Last 60 days';
export const DATE_FILTER_LAST_90_DAYS = 'Last 90 days';
export const DATE_FILTER_THIS_MONTH = 'This month';
export const DATE_FILTER_LAST_MONTH = 'Last month';

export const OVERVIEW_KEY = 'overview';
export const ENGAGEMENT_KEY = 'engagement';
export const CONVERSION_KEY = 'conversion';
export const ORDERS_KEY = 'orders';
export const ONSITE_KEY = 'onsite';
export const VIDEOS_KEY = 'videos';
export const SHOP_KEY = 'shop';
export const EMAIL_FLOWS_KEY = 'email-flows';

export const FILTER_TYPE_CHOICES = {
  [OVERVIEW_KEY]: 'Overview',
  [ONSITE_KEY]: 'Online Store',
  [SHOP_KEY]: 'Shop',
  [VIDEOS_KEY]: 'Videos',
  [CONVERSION_KEY]: 'Orders',
  [ENGAGEMENT_KEY]: 'Engagement',
  [EMAIL_FLOWS_KEY]: 'Email flows',
};

export const ANALYTICS_HEADER_CLASS_NAME = 'tolstoy-analytics-header';
export const ANALYTICS_CONTENT_CLASS_NAME = 'tolstoy-analytics-content';

export const PLAYER_USER_LOG = 'cub_player_events';
export const PLAYER_FEED_SESSIONS = 'cub_feed_sessions';
export const ORDER_SUMMARY_PROD = 'cub_orders_summary';
export const ONSITE_TOLSTOYS_PROD = 'cub_on_site_tolstoy';
export const VIDEOS_PROD = 'cub_videos';
export const VIEWERS_PROD = 'cub_viewers';
export const JOURNEYS_PROD = 'cub_journeys';
export const SITE_ACTIVITY_LOG = 'cub_site_activity';
export const ORDERS_V2_PROD = 'cub_orders_v2';
export const ORDER_ITEMS_V2_PROD = 'cub_order_items_v2';
export const SHOP_PROD = 'cub_shop';
export const EMAIL_RECIPIENTS_PROD = 'cub_email_recipients';

export const TOP_CONVERSION_VIDEOS_LIMIT = 5;

export const ANALYTICS_OVERVIEW_BAR_CHARTS = {
  TOLSTOY_VIEWERS: {
    id: 'tolstoyViewers',
    title: 'Tolstoy Viewers',
    bars: [
      { dataKey: 'sessionStarts', fill: 'successDark', stackId: 'a' },
      { dataKey: 'pageViews', fill: 'neutralLight', stackId: 'a' },
    ],
  },
  VIEWER_REVENUE_BREAKDOWN: {
    id: 'viewerRevenueBreakdown',
    title: 'Viewer Revenue Breakdown',
    bars: [
      { dataKey: 'directRevenue', fill: 'successDark', stackId: 'a' },
      { dataKey: 'indirectRevenue', fill: 'success', stackId: 'a' },
    ],
  },
  CONVERSION_RATE: {
    id: 'conversionRate',
    title: 'Conversion Rate',
    lines: [
      // todo: uncomment when we have enough site activity data to show more accurate conversion rate
      // { dataKey: 'baselineConversionRate', stroke: 'neutralLight' },
      { dataKey: 'tolstoyConversionRate', stroke: 'successDark' },
    ],
  },
  AVG_AOV: {
    id: 'avgAov',
    title: 'avg. aov',
    lines: [
      { dataKey: 'baselineDailyAveragePrice', stroke: 'neutralLight' },
      { dataKey: 'tolstoyDailyAveragePrice', stroke: 'successDark' },
    ],
  },
  PRODUCT_CTR: {
    id: 'productCTR',
    title: 'Product Click Through Rate',
    lines: [{ dataKey: 'productCTR', stroke: 'successDark' }],
  },
};

export const ANALYTICS_OVERVIEW_STATS = {
  TOLSTOY: 'Tolstoy',
  UPLIFT: 'Uplift',
  OVERALL: 'Overall',
  BASELINE: 'Baseline',
  REVENUE_UPLIFT_TOOLTIP: `Conversion rate of visitors who played a Tolstoy VS. visitors who didn't play a Tolstoy`,
  ORDERS_TOLSTOY_TOOLTIP: '',
  ORDERS_UPLIFT_TOOLTIP: `Average order value of visitors who played a Tolstoy VS. visitors who didn’t play a Tolstoy`,
  VIDEO_PLAYS_TOLSTOY_TOOLTIP: `% of people who played a video, out of all those who landed on a page with a video on it`,
  VIDEO_PLAYS_OVERALL_TOOLTIP: `% of people who played a video, out of your total site traffic`,
  AOV: 'AOV',
  ORDERS: 'Orders',
  INTERACTIVE: 'Interactive',
  SHOPPABLE: 'Shoppable',
  INTERACTIVE_TOOLTIP: 'Interactive CTAs are all Tolstoy CTAs, excluding Shoppable CTAs',
  SHOPPABLE_TOOLTIP: 'Shoppable CTAs are product related Tolstoy CTAs',
  TOLSTOY_VIEWERS_TOOLTIP: 'Users who watched video within selected period',
  CONVERSION_RATE_TOOLTIP: 'Percentage of users who made a purchase after watching a video',
  AVG_AOV_TOOLTIP: 'Average order value of users who watched video',
  TOLSTOY_CONVERSION_RATE_USERS: 'Tolstoy Users',
  BASELINE_TOLSTOY_CONVERSION_RATE_USERS: 'Non-Tolstoy Users',
  ENGAGEMENT_RATE: 'Engagement Rate',
  ENGAGEMENT_RATE_TOOLTIP: 'Percentage of video viewers out of total site visitors',
  PRODUCT_CLICKS: 'Product Clicks',
  PRODUCT_CLICKS_TOOLTIP: 'Total number of product clicks',
  PRODUCT_CTR: 'Product Click Through Rate',
  PRODUCT_CTR_TOOLTIP: 'Percentage of viewers who clicked on a product',
};

export const ANALYTICS_OVERVIEW_TOTAL_REVENUE = {
  DIRECT: 'Direct Revenue',
  DIRECT_TOOLTIP: 'Revenue from products that were tagged in a watched video',
  IN_VIDEO: 'In-Video Purchase',
  INDIRECT: 'Indirect Revenue',
  INDIRECT_TOOLTIP:
    'Revenue from Tolstoy viewers from products that were not tagged in watched videos',
  POST_VIDEO: 'Post-Video Purchase',
};

export const ANALYTICS_EMAIL_CHARTS = {
  EMAIL_OPENS: {
    id: 'emailOpens',
    title: 'Viewed Gif (opened email)',
    lines: [
      {
        dataKey: 'numOfEmailOpens',
        dot: null,
        stroke: 'successDark',
      },
    ],
  },
  EMAIL_CLICKS: {
    id: 'emailClicks',
    title: 'Clicked Gif',
    lines: [
      {
        dataKey: 'numOfEmailClicks',
        dot: null,
        stroke: 'successDark',
      },
    ],
  },
  EMAIL_CONVERSIONS: {
    id: 'emailConversions',
    title: 'Conversion',
    lines: [
      {
        dataKey: 'conversionRate',
        dot: null,
        stroke: 'successDark',
      },
    ],
  },
  EMAIL_REVENUE: {
    id: 'emailRevenue',
    title: 'Viewer revenue breakdown',
    bars: [
      { dataKey: 'directRevenue', fill: 'successDark', stackId: 'a' },
      { dataKey: 'indirectRevenue', fill: 'success', stackId: 'a' },
    ],
  },
};

export const ATTRIBUTION_WINDOW_OPTIONS = {
  twentyFourHours: '24 Hours',
  threeDays: '3 Days',
  sevenDays: '7 Days',
  thirtyDays: '30 Days',
};

export const CHANNEL_FILTER_OPTIONS = {
  all: 'All',
  tapcart: 'Tapcart',
};

export const TAPCART_CUSTOM_BLOCK_BASE_URL = 'custom-blocks.tapcart.com';

export const ANALYTICS_TABLE_KEYS = {
  orders: 'conversion-orders',
  onsite: 'tolstoy-onsite',
  videos: 'tolstoy-videos',
  audience: 'tolstoy-audience',
  recipients: 'tolstoy-email-recipients',
};

export const GENERAL_DATA_KEYS = {
  baselineDailyAveragePrice: 'baselineDailyAveragePrice',
  baselineDailyTotalCount: 'baselineDailyTotalCount',
  tolstoyDailyAveragePrice: 'tolstoyDailyAveragePrice',
  tolstoyDailyTotalCount: 'tolstoyDailyTotalCount',
  avgPrice: 'avgPrice',
  dailyTotalCount: 'dailyTotalCount',
};

export const ANALYTICS_TABLE_COLUMN_GROUPS = {
  general: 'general',
  spacer: 'spacer',
  conversion: 'conversion',
  engagement: 'engagement',
};

export const ANALYTICS_TABLE_COLUMN_CLASSNAMES = {
  lastColumn: 'last-column',
};

export const SPACER_COLUMN_ATTRIBUTES = {
  hideSortIcons: true,
  aggregable: false,
  disableColumnMenu: true,
  disableExport: true,
  disableReorder: true,
  filterable: false,
  hideable: false,
  sortable: false,
  width: 8,
  minWidth: 8,
  maxWidth: 8,
  headerClassName: ANALYTICS_TABLE_COLUMN_GROUPS.spacer,
  cellClassName: ANALYTICS_TABLE_COLUMN_GROUPS.spacer,
  headerName: ' ',
  groupName: '',
  valueGetter: () => ' ',
};
