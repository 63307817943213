import React from 'react';
import CircleCheck from 'shared/react/images/share/CircleCheck';
import OutlinedXButton from '../../assets/images/OutlinedXButton';
import styled from 'styled-components';

const ShareSnackbar = ({ open, text, closeSnackbar }) => {
  return (
    open && (
      <SnackbarContainer>
        <LeftContainer>
          <IconContainer>
            <CircleCheck />
          </IconContainer>
          {text}
        </LeftContainer>
        <CloseContainer onClick={closeSnackbar}>
          <OutlinedXButton />
        </CloseContainer>
      </SnackbarContainer>
    )
  );
};

const SnackbarContainer = styled.div`
  padding: 23px 16px;
  background: ${({ theme }) => theme.colors.successLight};
  display: flex;
  position: absolute;
  bottom: 26px;
  right: 32px;
  z-index: 101;
  min-width: 300px;
  justify-content: space-between;
  border-radius: 12px;
`;

const IconContainer = styled.div`
  margin-right: 16px;
`;

const CloseContainer = styled.div`
  align-self: center;
  cursor: pointer;
`;

const LeftContainer = styled.div`
  align-self: center;
  display: flex;
  color: ${({ theme }) => theme.colors.successDark};
`;
export default ShareSnackbar;
