import React, { useEffect, useRef, useState } from 'react';
import { AutoSizer, Column, InfiniteLoader, Table, WindowScroller } from 'react-virtualized';
import styled from 'styled-components';
import {
  EXPANDED_ROW_HEIGHT,
  HEADER_HEIGHT,
  ROW_HEIGHT,
  VIDEO_ROW_GAP,
  VIDEO_ROW_HEIGHT,
  VIDEO_TILE_WIDTH,
  getFirstColumnWidth,
} from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-products-table/dynamicProductsTable.constants';
import { RowData } from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-products-table/types/dynamicProductsTable.type';
import { project as Project } from 'app/src/types/entities';
import {
  TableFirstHeaderText,
  TableHeaderText,
} from 'src/basic_components/virtualized-table/VirtualizedHeaders';
import { AnalyticsDataProps } from 'src/types/common/common';
import Spinner from 'app/src/basic_components/Spinner';
import DynamicProductsTableRow from './DynamicProductsTableRow';

import 'react-virtualized/styles.css'; // import default styles
import { track } from 'src/helpers/Tracker';

type Props = {
  project: Project;
  containerRef: React.RefObject<HTMLDivElement>;
  analyticsData?: AnalyticsDataProps;
  loading?: boolean;
  products: any;
  canHide?: boolean;
  canOrder?: boolean;
  canLock?: boolean;
  canAddManualVideo?: boolean;
  canRemoveManualVideo?: boolean;
  isLoading?: boolean;
  loadMoreProducts?: ({ startIndex, stopIndex }: { startIndex: number; stopIndex: number }) => void;
  hideVideo: (videoId: string, productId?: string) => void;
  showVideo: (videoId: string, order: number, productId?: string) => void;
  lockVideo: (videoId: string, order: number, productId?: string) => void;
  unlockVideo: (videoId: string, order: number, productId?: string) => void;
  moveVideo: (params: { index: number; draggingFromIndex: number; productId?: string }) => void;
  removeManualVideo: (params: { videoId: string; externalProductId: string }) => Promise<any>;
  addManualVideo: (params: {
    videoId: string;
    provider?: string;
    productId?: string;
    externalProductId?: string;
  }) => Promise<any>;
  openProductsModal: ({ video, analyticsData }) => void;
  openVideoPickerModal: ({ onVideoPicked, onModalClosed }: any) => void;
};

const DEFAULT_OPEN_ROW_INDEX = 0;

const DynamicProductsTable = ({
  products,
  project,
  containerRef,
  analyticsData,
  canHide = false,
  canOrder = false,
  canLock = true,
  canAddManualVideo = true,
  canRemoveManualVideo = true,
  isLoading = false,
  loadMoreProducts,
  hideVideo,
  showVideo,
  lockVideo,
  unlockVideo,
  moveVideo,
  removeManualVideo,
  addManualVideo,
  openVideoPickerModal,
  openProductsModal,
}: Props) => {
  const [expandedProductId, setExpandedProductId] = useState(
    products[DEFAULT_OPEN_ROW_INDEX]?.product?.productId
  );
  const [rows, setRows] = useState<RowData[]>([{ isLoading: true }]);
  const tableRef = useRef<any>();
  const newProductIdConnectionRef = useRef();

  useEffect(() => {
    const newRows = products.map(({ product, videos }) => {
      const { id, title: name, imageUrl, productId, ecomPlatform } = product;

      return {
        product,
        videos,
        productPage: { imageUrl, name, productId, id, ecomPlatform },
        videosNumber: videos.length,
      };
    });

    setRows(newRows);
  }, [products]);

  useEffect(() => {
    if (!tableRef.current) {
      return;
    }

    tableRef.current.recomputeRowHeights();
    tableRef.current.forceUpdate();
  }, [expandedProductId, products]);

  const isRowLoaded = ({ index }) => {
    if (isLoading) {
      return false;
    }

    return !!rows[index];
  };

  const getRowHeight = (index, width) => {
    const isExpanded = rows[index]?.productPage?.productId === expandedProductId;
    const length = rows[index]?.videos?.length;

    if (isExpanded && !length) {
      return EXPANDED_ROW_HEIGHT + VIDEO_ROW_HEIGHT;
    }

    if (!isExpanded || !length) {
      return ROW_HEIGHT;
    }

    const gapLength = VIDEO_ROW_GAP * (length - 1);
    const rowLength = length * VIDEO_TILE_WIDTH + gapLength;
    const numberOfTiles = Math.floor(width / VIDEO_TILE_WIDTH);

    const numberOfRows = Math.ceil(rowLength / width);
    const gapWidth = numberOfTiles - VIDEO_ROW_GAP;

    if (numberOfRows >= 4) {
      return EXPANDED_ROW_HEIGHT + VIDEO_ROW_HEIGHT * 4 + gapWidth;
    }

    return EXPANDED_ROW_HEIGHT + VIDEO_ROW_HEIGHT * numberOfRows + gapWidth;
  };

  const handleAddManualVideo = async video => {
    if (!video.id || !project.id || !newProductIdConnectionRef.current) {
      return;
    }

    const {
      id: productId,
      productId: externalProductId,
      ecomPlatform: provider,
    } = newProductIdConnectionRef.current;

    track('Vod Connections Created', {
      location: 'Dynamic Products Table',
    });

    addManualVideo({ videoId: video.id, provider, productId, externalProductId });
  };

  const handleRemoveManualVideo = async ({ video, rowData }) => {
    const externalProductId = rowData.productPage?.productId;

    removeManualVideo({ videoId: video.id, externalProductId });
  };

  const onVideoPickerModalClosed = () => {
    newProductIdConnectionRef.current = null;
  };

  const onOpenVideoPickerModal = () =>
    openVideoPickerModal({
      onVideoPicked: handleAddManualVideo,
      onModalClosed: onVideoPickerModalClosed,
    });

  const onAddManualVideoClick = rowData => {
    newProductIdConnectionRef.current = rowData.productPage;

    onOpenVideoPickerModal();
  };

  const labelRenderer = ({ label }) => <TableHeaderText>{label}</TableHeaderText>;

  if (isLoading && !products?.length) {
    return <Spinner size={32} />;
  }

  if (!products?.length) {
    return <div>No products found</div>;
  }

  return (
    <LayoutRoot>
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreProducts}
        rowCount={products.length}
      >
        {({ onRowsRendered, registerChild }) => (
          <WindowScroller scrollElement={containerRef.current}>
            {({ height = 0, scrollTop }) => {
              return (
                <AutoSizer disableHeight>
                  {({ width }) => {
                    const firstColumnWidth = getFirstColumnWidth(width);
                    const tileWidth = width - firstColumnWidth;

                    return (
                      <Table
                        autoHeight
                        height={height}
                        width={width}
                        scrollTop={scrollTop}
                        onRowsRendered={onRowsRendered}
                        ref={ref => {
                          registerChild(ref);
                          tableRef.current = ref;
                        }}
                        rowHeight={({ index }) => getRowHeight(index, width)}
                        rowCount={rows.length}
                        headerHeight={HEADER_HEIGHT}
                        rowGetter={({ index }) => rows[index]}
                        flexGrow={1}
                        overscanRowCount={5}
                        rowRenderer={props => (
                          <DynamicProductsTableRow
                            firstColumnWidth={firstColumnWidth}
                            tileWidth={tileWidth}
                            expandedProductId={expandedProductId}
                            analyticsData={analyticsData}
                            canHide={canHide}
                            canOrder={canOrder}
                            canLock={canLock}
                            canAddManualVideo={canAddManualVideo}
                            canRemoveManualVideo={canRemoveManualVideo}
                            onAddManualVideo={onAddManualVideoClick}
                            onRemoveManualVideo={handleRemoveManualVideo}
                            openProductsModal={openProductsModal}
                            hideVideo={hideVideo}
                            showVideo={showVideo}
                            lockVideo={lockVideo}
                            unlockVideo={unlockVideo}
                            moveVideo={moveVideo}
                            setExpandedProduct={setExpandedProductId}
                            {...props}
                          />
                        )}
                      >
                        <Column
                          label="Product Page"
                          dataKey="productPage"
                          positon
                          width={firstColumnWidth}
                          headerRenderer={({ label }) => (
                            <TableFirstHeaderText>{label}</TableFirstHeaderText>
                          )}
                        />
                        <Column
                          label="Videos"
                          dataKey="videosNumber"
                          width={tileWidth}
                          headerRenderer={labelRenderer}
                        />
                        <Column width={50} dataKey="" label="" />
                      </Table>
                    );
                  }}
                </AutoSizer>
              );
            }}
          </WindowScroller>
        )}
      </InfiniteLoader>
      {isLoading && <Spinner size={32} />}
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  padding: 0 0 24px 10px;

  .ReactVirtualized__Table__headerRow {
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutralLight};
  }
`;

export default DynamicProductsTable;
