import React from 'react';
import styled from 'styled-components';
import EmbedCarouselStaticPreview from 'app/src/pages/modals/publish/previews/static_preview_items/EmbedCarouselStaticPreview';
import EmbedCarouselPreviewTitle from 'app/src/pages/project/pages/look_and_feel/preview/embed_carousel_preview/EmbedCarouselPreviewTitle';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import DefaultCarouselPreviewTile from './DefaultCarouselPreviewTile';
import PublishEmbedCarouselPreviewTile from './PublishEmbedCarouselPreviewTile';
import { useVideos } from 'app/src/context/VideosStore';
import { useDynamicConditionsContext } from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/context/DynamicConditionsContext';

const NUMBER_OF_TILES = 3;

type Props = {
  project: any;
  widgetSettings: any;
  size: number;
};

const PublishEmbedCarouselPreview = ({ project, size, widgetSettings }: Props) => {
  const [, { getVideoById }] = useVideos();
  const { vodAssetIds } = useDynamicConditionsContext();

  let videosWithDescriptions = [];
  if (project?.dynamic) {
    videosWithDescriptions = vodAssetIds.map(videoId => {
      const video = getVideoById(videoId);
      return { videoId, description: video?.description };
    });
  } else {
    videosWithDescriptions = project.steps.items.map(({ description, videoId }) => {
      return { videoId, description };
    });
  }

  const defaultTilesNumber = Math.max(NUMBER_OF_TILES - videosWithDescriptions.length, 0);

  return (
    <EmbedCarouselStaticPreview>
      <LayoutRoot size={size}>
        <EmbedCarouselPreviewTitle size={size} widgetSettings={widgetSettings} />
        <VideosContainer>
          {videosWithDescriptions.slice(0, NUMBER_OF_TILES).map(({ videoId, description }) => (
            <PublishEmbedCarouselPreviewTile
              key={videoId}
              size={size}
              videoId={videoId}
              description={description}
              widgetSettings={widgetSettings}
            />
          ))}
          {[...Array(defaultTilesNumber)].map((_, i) => (
            <DefaultCarouselPreviewTile
              key={`default-carousel-preview-tile-${i}`}
              size={size}
              widgetSettings={widgetSettings}
            />
          ))}
        </VideosContainer>
      </LayoutRoot>
    </EmbedCarouselStaticPreview>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  gap: ${({ size }) => 24 / size}px;
`;

const VideosContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${NUMBER_OF_TILES}, 1fr);
  gap: 8px;
  overflow: hidden;
`;

export default PublishEmbedCarouselPreview;
