import React from 'react';
import styled, { useTheme } from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import ImageIcon from '../icons/ImageIcon';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import { IMAGE_TYPE } from 'shared/react/constants/attachments_types.constants';
import Utils from '../../../utils';

const Attachments = ({ attachments, setAttachments }) => {
  const theme = useTheme();
  const IMAGE_TYPES = { [IMAGE_TYPE]: <ImageIcon fill={theme.colors.primary} /> };

  const onRemove = (e, url, isLoading) => {
    e.stopPropagation();
    if (isLoading) {
      return;
    }
    const newAttachments = [...attachments];
    const filteredAttachments = newAttachments.filter(attachment => {
      return attachment.url !== url;
    });
    setAttachments(filteredAttachments);
  };

  return (
    <LayoutRoot>
      {attachments.map(attachment => {
        const { url, fileName, type } = attachment;
        const isLoading = !url;
        const image = IMAGE_TYPES[type];
        const onAttachmentClick = () => Utils.openInNewTab(attachment.url);

        return (
          <AttachmentContainer onClick={onAttachmentClick} key={url}>
            <TextContainer isLoading={isLoading}>
              <ImageContainer>{image}</ImageContainer>
              {fileName}
              <XContainer onClick={e => onRemove(e, url, isLoading)}>X</XContainer>
            </TextContainer>
            {isLoading && (
              <LoadingContainer>
                <CircularProgress size={8} />
              </LoadingContainer>
            )}
          </AttachmentContainer>
        );
      })}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlexWrap)`
  margin: 12px 0 0 4px;
  overflow: auto;
`;

const AttachmentContainer = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  color: ${({ theme }) => theme.colors.primary};
  display: grid;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    transform: scale(1.03);
  }
`;

const TextContainer = styled.div`
  grid-row: 1;
  grid-column: 1;
  display: flex;
  gap: 8px;
  opacity: ${({ isLoading }) => (isLoading ? 0.2 : 1)};
`;

const ImageContainer = styled.div`
  display: flex;
`;

const LoadingContainer = styled.div`
  grid-row: 1;
  grid-column: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const XContainer = styled.div`
  opacity: 0.4;
  transition: 0.3s;
  cursor: pointer;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.ghostDark};
  height: 10px;
  &:hover {
    opacity: 1;
  }
`;

export default Attachments;
