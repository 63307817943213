import React from 'react';

const FilledEyeIcon = () => (
  <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.313 15.203C26.78 9.438 21.718 5.5 16 5.5c-5.766 0-10.828 3.938-13.36 9.703A2.6 2.6 0 0 0 2.5 16c0 .234.047.61.14.844C5.173 22.609 10.235 26.5 16 26.5c5.719 0 10.781-3.89 13.313-9.656.093-.235.187-.61.187-.844 0-.188-.094-.563-.188-.797Zm-6.563.844c0 3.703-3.047 6.703-6.75 6.703-3.75 0-6.75-3-6.75-6.75 0-3.703 3-6.75 6.75-6.75 3.703 0 6.75 3.047 6.75 6.75v.047ZM16 11.5h-.047c-.094.047-.234.047-.375.047.235.469.422.937.422 1.453 0 1.688-1.36 3-3 3-.563 0-1.078-.14-1.5-.375V16c0 2.484 2.016 4.5 4.5 4.5 2.438 0 4.5-2.016 4.5-4.5 0-2.438-2.063-4.5-4.5-4.5Z"
      fill="#fff"
    />
  </svg>
);

export default FilledEyeIcon;
