import { useEffect, useState } from 'react';
import { useProductActions } from 'app/src/context/ProductsStore';
import { useVodConnection } from 'src/context/VodConnectionStore';

const MAX_PRODUCTS_TO_REQUEST = 500;

const useGetMissingProducts = ({ project, productsMap, vodAssetIds }) => {
  const [hasRequested, setHasRequested] = useState(false);
  const { getProducts } = useProductActions();
  const [{ productsByVodAssetMap }] = useVodConnection();

  useEffect(() => {
    const missingProductsList = [];

    if (!vodAssetIds.length || hasRequested) {
      return;
    }

    vodAssetIds.forEach(videoId => {
      const vodProductIds = productsByVodAssetMap[videoId]?.[project?.appUrl] || [];

      vodProductIds.slice(0, MAX_PRODUCTS_TO_REQUEST).forEach((productId: string) => {
        if (!productsMap[productId]) {
          missingProductsList.push(productId);
        }
      });
    });

    if (!missingProductsList.length) {
      return;
    }

    getProducts(missingProductsList);
    setHasRequested(true);
  }, [vodAssetIds]);
};

export default useGetMissingProducts;
