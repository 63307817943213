import React from 'react';
import { usePackages } from 'app/src/context/PackagesStore';
import BillingPackagesV2 from 'app/src/complex_components/billings/BillingPackagesV2/BillingPackagesV2';
import BillingPackages from 'app/src/complex_components/billings/BillingPackages';

const BillingPackagesRouter = () => {
  const [{ packages }] = usePackages();

  if (!packages?.length) {
    return null;
  }

  if (packages.length === 4) {
    return <BillingPackagesV2 />;
  }

  return <BillingPackages />;
};

export default BillingPackagesRouter;
