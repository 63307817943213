import {
  EMAIL_RECIPIENTS_PROD,
  JOURNEYS_PROD,
  ONSITE_TOLSTOYS_PROD,
  ORDER_ITEMS_V2_PROD,
  ORDER_SUMMARY_PROD,
  ORDERS_V2_PROD,
  PLAYER_USER_LOG,
  SHOP_PROD,
  SITE_ACTIVITY_LOG,
  VIDEOS_PROD,
  VIEWERS_PROD,
} from 'app/src/constants/analytics.constants';
import { getCubeFiltersV2 } from 'app/src/services/cube/cube.utils';
import { CUBE_PROPERTY_KEYS } from 'app/src/constants/cube.constants';
import Utils from 'shared/react/utils/utils';
import { EVENTS } from 'app/src/constants/tolstoy.constants';

export const getConversionMetricsQuery = ({ dateRange, ...filterParams }) => {
  return {
    measures: [
      `${ORDER_ITEMS_V2_PROD}.${CUBE_PROPERTY_KEYS.inVideoRevenue}`,
      `${ORDER_ITEMS_V2_PROD}.${CUBE_PROPERTY_KEYS.postVideoRevenue}`,
      `${ORDER_ITEMS_V2_PROD}.${CUBE_PROPERTY_KEYS.indirectRevenue}`,
    ],
    timeDimensions: [
      {
        dimension: `${ORDER_ITEMS_V2_PROD}.${CUBE_PROPERTY_KEYS.createdAt}`,
        dateRange,
      },
    ],
    filters: getCubeFiltersV2({ filters: filterParams, tableKey: ORDER_ITEMS_V2_PROD }),
    dimensions: [`${ORDER_ITEMS_V2_PROD}.${CUBE_PROPERTY_KEYS.currency}`],
  };
};

export const getConversionEventsOvertimeQuery = params => {
  const conversionMetricsQuery = Utils.deepCopyObject(getConversionMetricsQuery({ ...params }));

  conversionMetricsQuery.timeDimensions[0].granularity = 'day';

  return conversionMetricsQuery;
};

export const getConversionOrdersQuery = ({ dateRange, ...filterParams }) => {
  return {
    measures: [
      `${ORDER_SUMMARY_PROD}.${CUBE_PROPERTY_KEYS.postVideoRevenue}`,
      `${ORDER_SUMMARY_PROD}.${CUBE_PROPERTY_KEYS.inVideoRevenue}`,
      `${ORDER_SUMMARY_PROD}.${CUBE_PROPERTY_KEYS.indirectRevenue}`,
    ],
    timeDimensions: [
      {
        dimension: `${ORDER_SUMMARY_PROD}.${CUBE_PROPERTY_KEYS.createdAt}`,
        dateRange,
      },
    ],
    order: {
      [`${ORDER_SUMMARY_PROD}.${CUBE_PROPERTY_KEYS.createdAt}`]: 'desc',
    },
    filters: getCubeFiltersV2({ filters: filterParams, tableKey: ORDER_SUMMARY_PROD }),
    dimensions: [
      `${ORDER_SUMMARY_PROD}.${CUBE_PROPERTY_KEYS.orderId}`,
      `${ORDER_SUMMARY_PROD}.${CUBE_PROPERTY_KEYS.orderName}`,
      `${ORDER_SUMMARY_PROD}.${CUBE_PROPERTY_KEYS.email}`,
      `${ORDER_SUMMARY_PROD}.${CUBE_PROPERTY_KEYS.createdAt}`,
      `${ORDER_SUMMARY_PROD}.${CUBE_PROPERTY_KEYS.currency}`,
      `${ORDER_SUMMARY_PROD}.${CUBE_PROPERTY_KEYS.appUrl}`,
      `${ORDER_SUMMARY_PROD}.${CUBE_PROPERTY_KEYS.timeOnTolstoy}`,
    ],
  };
};

export const getTimeOnPageOvertimeQuery = ({ dateRange, ...filterParams }) => {
  return {
    measures: [
      `${SITE_ACTIVITY_LOG}.${CUBE_PROPERTY_KEYS.averageTimeOnPage}`,
      `${SITE_ACTIVITY_LOG}.${CUBE_PROPERTY_KEYS.averageTimeOnPageWhenInteractingTolstoy}`,
      `${SITE_ACTIVITY_LOG}.${CUBE_PROPERTY_KEYS.numOfInteractingVisits}`,
      `${SITE_ACTIVITY_LOG}.${CUBE_PROPERTY_KEYS.numOfNonInteractingVisits}`,
      `${SITE_ACTIVITY_LOG}.${CUBE_PROPERTY_KEYS.numOfNonInteractingVisitsUnique}`,
      `${SITE_ACTIVITY_LOG}.${CUBE_PROPERTY_KEYS.averageTimeOnTolstoy}`,
      `${SITE_ACTIVITY_LOG}.${CUBE_PROPERTY_KEYS.sumTimeOnTolstoy}`,
    ],
    timeDimensions: [
      {
        dimension: `${SITE_ACTIVITY_LOG}.${CUBE_PROPERTY_KEYS.timestamp}`,
        granularity: 'day',
        dateRange,
      },
    ],
    filters: getCubeFiltersV2({ filters: filterParams, tableKey: SITE_ACTIVITY_LOG }),
  };
};

export const getTimeOnPageQuery = ({ dateRange, ...filterParams }) => {
  return {
    measures: [
      `${SITE_ACTIVITY_LOG}.${CUBE_PROPERTY_KEYS.averageTimeOnTolstoy}`,
      `${SITE_ACTIVITY_LOG}.${CUBE_PROPERTY_KEYS.sumTimeOnTolstoy}`,
    ],
    timeDimensions: [
      {
        dimension: `${SITE_ACTIVITY_LOG}.${CUBE_PROPERTY_KEYS.timestamp}`,
        dateRange,
      },
    ],
    filters: getCubeFiltersV2({ filters: filterParams, tableKey: SITE_ACTIVITY_LOG }),
  };
};

export const getOnsiteQuery = ({ dateRange, ...filterParams }) => {
  return {
    measures: [`${ONSITE_TOLSTOYS_PROD}.${CUBE_PROPERTY_KEYS.plays}`],
    timeDimensions: [
      {
        dimension: `${ONSITE_TOLSTOYS_PROD}.${CUBE_PROPERTY_KEYS.createdAt}`,
        dateRange,
      },
    ],
    order: {
      [`${ONSITE_TOLSTOYS_PROD}.${CUBE_PROPERTY_KEYS.plays}`]: 'desc',
    },
    filters: getCubeFiltersV2({ filters: filterParams, tableKey: ONSITE_TOLSTOYS_PROD }),
    dimensions: [
      `${ONSITE_TOLSTOYS_PROD}.${CUBE_PROPERTY_KEYS.publishId}`,
      `${ONSITE_TOLSTOYS_PROD}.${CUBE_PROPERTY_KEYS.parentUrl}`,
      `${ONSITE_TOLSTOYS_PROD}.${CUBE_PROPERTY_KEYS.inVideoRevenue}`,
      `${ONSITE_TOLSTOYS_PROD}.${CUBE_PROPERTY_KEYS.postVideoRevenue}`,
      `${ONSITE_TOLSTOYS_PROD}.${CUBE_PROPERTY_KEYS.indirectRevenue}`,
      `${ONSITE_TOLSTOYS_PROD}.${CUBE_PROPERTY_KEYS.currency}`,
      `${ONSITE_TOLSTOYS_PROD}.${CUBE_PROPERTY_KEYS.averageTimeOnTolstoy}`,
      `${ONSITE_TOLSTOYS_PROD}.${CUBE_PROPERTY_KEYS.averageTimeOnPage}`,
      `${ONSITE_TOLSTOYS_PROD}.${CUBE_PROPERTY_KEYS.averageTimeOnPageWhenInteractingTolstoy}`,
      `${ONSITE_TOLSTOYS_PROD}.${CUBE_PROPERTY_KEYS.numOfVideosWatched}`,
      `${ONSITE_TOLSTOYS_PROD}.${CUBE_PROPERTY_KEYS.numOfEmbedViews}`,
      `${ONSITE_TOLSTOYS_PROD}.${CUBE_PROPERTY_KEYS.numOfOrders}`,
    ],
  };
};

export const getVideoMetricsQuery = ({ dateRange, ...filterParams }) => {
  return {
    measures: [
      `${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.bounceRate}`,
      `${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.averageWatchPercentage}`,
    ],
    timeDimensions: [
      {
        dimension: `${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.createdAt}`,
        dateRange,
      },
    ],
    order: {
      [`${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.plays}`]: 'desc',
    },
    filters: getCubeFiltersV2({ filters: filterParams, tableKey: VIDEOS_PROD }),
    dimensions: [
      `${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.id}`,
      `${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.duration}`,
      `${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.plays}`,
      `${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.conversionRate}`,
      `${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.watchedAllPercentage}`,
      `${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.inVideoRevenue}`,
      `${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.postVideoRevenue}`,
      `${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.indirectRevenue}`,
      `${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.totalRevenue}`,
      `${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.currency}`,
      `${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.numOfOrders}`,
    ],
  };
};

export const getViewersMetricsQuery = ({ dateRange, ...filterParams }) => {
  return {
    measures: [],
    timeDimensions: [
      {
        dimension: `${VIEWERS_PROD}.${CUBE_PROPERTY_KEYS.createdAt}`,
        dateRange,
      },
    ],
    order: {
      [`${VIEWERS_PROD}.${CUBE_PROPERTY_KEYS.createdAt}`]: 'desc',
    },
    filters: getCubeFiltersV2({ filters: filterParams, tableKey: VIEWERS_PROD }),
    dimensions: [
      `${VIEWERS_PROD}.${CUBE_PROPERTY_KEYS.email}`,
      `${VIEWERS_PROD}.${CUBE_PROPERTY_KEYS.createdAt}`,
      `${VIEWERS_PROD}.${CUBE_PROPERTY_KEYS.numOfVideosWatched}`,
      `${VIEWERS_PROD}.${CUBE_PROPERTY_KEYS.currency}`,
      `${VIEWERS_PROD}.${CUBE_PROPERTY_KEYS.numOfOrders}`,
    ],
  };
};

export const getJourneysMetricsQuery = ({ dateRange, ...filterParams }) => {
  return {
    measures: [],
    timeDimensions: [
      {
        dimension: `${JOURNEYS_PROD}.${CUBE_PROPERTY_KEYS.createdAt}`,
        dateRange,
      },
    ],
    order: {
      [`${JOURNEYS_PROD}.${CUBE_PROPERTY_KEYS.createdAt}`]: 'asc',
    },
    filters: getCubeFiltersV2({ filters: filterParams, tableKey: JOURNEYS_PROD }),
    dimensions: [
      `${JOURNEYS_PROD}.${CUBE_PROPERTY_KEYS.createdAt}`,
      `${JOURNEYS_PROD}.${CUBE_PROPERTY_KEYS.eventName}`,
      `${JOURNEYS_PROD}.${CUBE_PROPERTY_KEYS.videoId}`,
      `${JOURNEYS_PROD}.${CUBE_PROPERTY_KEYS.orderId}`,
      `${JOURNEYS_PROD}.${CUBE_PROPERTY_KEYS.publishId}`,
      `${JOURNEYS_PROD}.${CUBE_PROPERTY_KEYS.parentUrl}`,
      `${JOURNEYS_PROD}.${CUBE_PROPERTY_KEYS.watchTime}`,
    ],
  };
};

export const getOrdersAovOvertimeQuery = ({ dateRange, ...filterParams }) => {
  return {
    measures: [
      `${ORDERS_V2_PROD}.${CUBE_PROPERTY_KEYS.count}`,
      `${ORDERS_V2_PROD}.${CUBE_PROPERTY_KEYS.avgPrice}`,
    ],
    timeDimensions: [
      {
        dimension: `${ORDERS_V2_PROD}.${CUBE_PROPERTY_KEYS.createdAt}`,
        granularity: 'day',
        dateRange,
      },
    ],
    filters: getCubeFiltersV2({ filters: filterParams, tableKey: ORDERS_V2_PROD }),
    dimensions: [
      `${ORDERS_V2_PROD}.${CUBE_PROPERTY_KEYS.currency}`,
      `${ORDERS_V2_PROD}.${CUBE_PROPERTY_KEYS.isInteracted}`,
    ],
  };
};

export const getOverviewEngagementsOvertimeQuery = ({ dateRange, ...filterParams }) => {
  return {
    measures: [
      `${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.numOfSessionStarts}`,
      `${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.numOfSwipes}`,
      `${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.numOfGenericInteractions}`,
      `${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.numOfProductInteractions}`,
    ],
    timeDimensions: [
      {
        dimension: `${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.createdAt}`,
        granularity: 'day',
        dateRange,
      },
    ],
    order: {
      [`${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.createdAt}`]: 'desc',
    },
    filters: getCubeFiltersV2({ filters: filterParams, tableKey: PLAYER_USER_LOG }),
  };
};

export const getShopMetricsQuery = ({ dateRange, ...filterParams }) => {
  return {
    measures: [
      `${SHOP_PROD}.${CUBE_PROPERTY_KEYS.numOfVideosWatched}`,
      `${SHOP_PROD}.${CUBE_PROPERTY_KEYS.numOfSessionStarts}`,
      `${SHOP_PROD}.${CUBE_PROPERTY_KEYS.numOfImpressions}`,
      `${SHOP_PROD}.${CUBE_PROPERTY_KEYS.numOfProductsClicked}`,
      `${SHOP_PROD}.${CUBE_PROPERTY_KEYS.numOfProductFavorites}`,
      `${SHOP_PROD}.${CUBE_PROPERTY_KEYS.numOfOpenStoreScreen}`,
      `${SHOP_PROD}.${CUBE_PROPERTY_KEYS.numOfNavigateToStore}`,
      `${SHOP_PROD}.${CUBE_PROPERTY_KEYS.numOfFollowedStore}`,
    ],
    timeDimensions: [
      {
        dimension: `${SHOP_PROD}.${CUBE_PROPERTY_KEYS.createdAt}`,
        dateRange,
      },
    ],
    filters: getCubeFiltersV2({ filters: filterParams, tableKey: SHOP_PROD }),
    dimensions: [`${SHOP_PROD}.${CUBE_PROPERTY_KEYS.location}`],
  };
};

export const getTopConvertingVideoMetricsQuery = ({ dateRange, limit, ...filterParams } = {}) => {
  return {
    limit,
    timeDimensions: [
      {
        dimension: `${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.createdAt}`,
        dateRange,
      },
    ],
    order: {
      [`${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.totalRevenue}`]: 'desc',
    },
    filters: getCubeFiltersV2({
      filters: filterParams,
      tableKey: VIDEOS_PROD,
    }),
    dimensions: [
      `${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.id}`,
      `${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.conversionRate}`,
      `${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.currency}`,
      `${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.plays}`,
      `${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.totalRevenue}`,
    ],
  };
};

export const getUniqueUsersMetricsQuery = ({ dateRange, ...filterParams }) => {
  return {
    measures: [`${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.uniqueUserCount}`],
    timeDimensions: [
      {
        dimension: `${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.createdAt}`,
        granularity: 'day',
        dateRange,
      },
    ],
    order: {
      [`${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.createdAt}`]: 'desc',
    },
    dimensions: [`${PLAYER_USER_LOG}.eventname`],
    filters: getCubeFiltersV2({
      filters: {
        eventName: [EVENTS.sessionStart, EVENTS.pageView],
        ...filterParams,
      },
      tableKey: PLAYER_USER_LOG,
    }),
  };
};

export const getProjectActivePagesQuery = ({ dateRange, ...filterParams }) => {
  return {
    measures: [`${SITE_ACTIVITY_LOG}.${CUBE_PROPERTY_KEYS.numOfNonInteractingVisits}`],
    timeDimensions: [
      {
        dimension: `${SITE_ACTIVITY_LOG}.${CUBE_PROPERTY_KEYS.timestamp}`,
        dateRange,
      },
    ],
    order: {
      [`${SITE_ACTIVITY_LOG}.${CUBE_PROPERTY_KEYS.numOfNonInteractingVisits}`]: 'desc',
    },
    dimensions: [`${SITE_ACTIVITY_LOG}.${CUBE_PROPERTY_KEYS.pageUrl}`],
    filters: getCubeFiltersV2({ filters: filterParams, tableKey: SITE_ACTIVITY_LOG }),
  };
};

export const getEmailEventsOvertimeQuery = ({ dateRange, ...filterParams }) => {
  return {
    measures: [`${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.count}`],
    timeDimensions: [
      {
        dimension: `${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.createdAt}`,
        granularity: 'day',
        dateRange,
      },
    ],
    order: {
      [`${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.createdAt}`]: 'desc',
    },
    dimensions: [
      `${PLAYER_USER_LOG}.eventname`,
      `${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.campaign}`,
    ],
    filters: getCubeFiltersV2({
      filters: {
        eventName: [EVENTS.openEmail, EVENTS.emailClick],
        ...filterParams,
      },
      tableKey: PLAYER_USER_LOG,
    }),
  };
};

export const getEmailRecipientsQuery = ({ dateRange, ...filterParams }) => {
  return {
    measures: [
      `${EMAIL_RECIPIENTS_PROD}.${CUBE_PROPERTY_KEYS.numOfEmailOpens}`,
      `${EMAIL_RECIPIENTS_PROD}.${CUBE_PROPERTY_KEYS.numOfEmailClicks}`,
      `${EMAIL_RECIPIENTS_PROD}.${CUBE_PROPERTY_KEYS.numOfOrders}`,
    ],
    timeDimensions: [
      {
        dimension: `${EMAIL_RECIPIENTS_PROD}.${CUBE_PROPERTY_KEYS.createdAt}`,
        dateRange,
      },
    ],
    order: {
      [`${EMAIL_RECIPIENTS_PROD}.${CUBE_PROPERTY_KEYS.firstSeen}`]: 'asc',
      [`${EMAIL_RECIPIENTS_PROD}.${CUBE_PROPERTY_KEYS.campaign}`]: 'desc',
    },
    dimensions: [
      `${EMAIL_RECIPIENTS_PROD}.${CUBE_PROPERTY_KEYS.email}`,
      `${EMAIL_RECIPIENTS_PROD}.${CUBE_PROPERTY_KEYS.campaign}`,
      `${EMAIL_RECIPIENTS_PROD}.${CUBE_PROPERTY_KEYS.firstSeen}`,
    ],
    filters: getCubeFiltersV2({
      filters: filterParams,
      tableKey: EMAIL_RECIPIENTS_PROD,
    }),
  };
};

export const getEmailMetricsOvertimeQuery = ({ dateRange, ...filterParams }) => {
  return {
    measures: [
      `${EMAIL_RECIPIENTS_PROD}.${CUBE_PROPERTY_KEYS.numOfEmailClicks}`,
      `${EMAIL_RECIPIENTS_PROD}.${CUBE_PROPERTY_KEYS.numOfOrders}`,
      `${EMAIL_RECIPIENTS_PROD}.${CUBE_PROPERTY_KEYS.inVideoRevenue}`,
      `${EMAIL_RECIPIENTS_PROD}.${CUBE_PROPERTY_KEYS.postVideoRevenue}`,
      `${EMAIL_RECIPIENTS_PROD}.${CUBE_PROPERTY_KEYS.indirectRevenue}`,
    ],
    timeDimensions: [
      {
        dimension: `${EMAIL_RECIPIENTS_PROD}.${CUBE_PROPERTY_KEYS.createdAt}`,
        granularity: 'day',
        dateRange,
      },
    ],
    dimensions: [
      `${EMAIL_RECIPIENTS_PROD}.${CUBE_PROPERTY_KEYS.campaign}`,
      `${EMAIL_RECIPIENTS_PROD}.${CUBE_PROPERTY_KEYS.currency}`,
    ],
    filters: getCubeFiltersV2({
      filters: filterParams,
      tableKey: EMAIL_RECIPIENTS_PROD,
    }),
  };
};

export const getEngagementMetricsQuery = ({ dateRange, ...filterParams }) => {
  return {
    measures: [
      `${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.uniqueUserCount}`,
      `${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.count}`,
      `${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.totalTimeAverage}`,
      `${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.totalTimeSum}`,
    ],
    timeDimensions: [
      {
        dimension: `${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.createdAt}`,
        dateRange,
      },
    ],
    filters: getCubeFiltersV2({ filters: filterParams, tableKey: PLAYER_USER_LOG }),
    dimensions: [`${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.eventsCombined}`],
  };
};

export const getProductClicksOvertimeQuery = ({ dateRange, ...filterParams }) => {
  return {
    measures: [`${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.count}`],
    timeDimensions: [
      {
        dimension: `${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.createdAt}`,
        granularity: 'day',
        dateRange,
      },
    ],
    dimensions: [
      `${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.playerType}`,
      `${PLAYER_USER_LOG}.eventname`,
    ],
    filters: getCubeFiltersV2({
      filters: {
        eventName: [
          EVENTS.clickViewProduct,
          EVENTS.openProductPageClick,
          EVENTS.sessionStart,
          EVENTS.feedProductModalOpen,
        ],
        ...filterParams,
      },
      tableKey: PLAYER_USER_LOG,
    }),
  };
};
