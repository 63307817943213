import React from 'react';

const FeaturedCollectionIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
      <path
        d="M9.5 0C10.5938 0 11.5 0.90625 11.5 2V14C11.5 15.125 10.5938 16 9.5 16H2.5C1.375 16 0.5 15.125 0.5 14V2C0.5 0.90625 1.375 0 2.5 0H9.5ZM10 14V2C10 1.75 9.75 1.5 9.5 1.5H2.5C2.21875 1.5 2 1.75 2 2V14C2 14.2812 2.21875 14.5 2.5 14.5H9.5C9.75 14.5 10 14.2812 10 14ZM7 12.5C7.25 12.5 7.5 12.75 7.5 13C7.5 13.2812 7.25 13.5 7 13.5H5C4.71875 13.5 4.5 13.2812 4.5 13C4.5 12.75 4.71875 12.5 5 12.5H7Z"
        fill="#2F3137"
      />
    </svg>
  );
};

export default FeaturedCollectionIcon;
