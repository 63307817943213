import React, { useEffect, useState } from 'react';
import { useUser } from 'app/src/context/userStore/UserStore';
import { useApps } from 'app/src/context/AppsStore';
import ShopifyReviewModal from './modals/ShopifyReviewModal';
import { track } from '../helpers/Tracker';
import Utils from 'app/src/utils';

const IS_MODAL_ACTIVE = false;
const SHOPIFY_REVIEW_MODAL_KEY = 'shopifyReview';

const AppModals = () => {
  const [{ account, storageUser: user }] = useUser();
  const [{ shopify }] = useApps();
  const [openedModal, setOpenedModal] = useState(null);
  const userEmail = user?.email;

  useEffect(() => {
    if (!shopify || !account) {
      setOpenedModal(null);
      return;
    }

    const todayTimeStamp = Date.now();

    const shouldOpenShopifyReviewModal = getShouldOpenShopifyReviewModal();
    if (shouldOpenShopifyReviewModal) {
      setOpenedModal(SHOPIFY_REVIEW_MODAL_KEY);
      localStorage.setItem(SHOPIFY_REVIEW_MODAL_KEY, `${todayTimeStamp}`);
      track('Shopify Review Modal Opened');
    }
  }, [account, shopify]);

  const getShouldOpenShopifyReviewModal = () => {
    if (!IS_MODAL_ACTIVE || !shopify || !account || Utils.getIsDev()) {
      return false;
    }

    if (localStorage.getItem(SHOPIFY_REVIEW_MODAL_KEY)) {
      return false;
    }

    const todayTimeStamp = Date.now();
    const monthAfterSignupDate = new Date(account.createdAt);
    monthAfterSignupDate.setMonth(monthAfterSignupDate.getMonth() + 1);
    if (monthAfterSignupDate < todayTimeStamp) {
      return true;
    }
    return false;
  };

  return (
    <>
      <ShopifyReviewModal
        open={openedModal === SHOPIFY_REVIEW_MODAL_KEY}
        userEmail={userEmail}
        closeModal={() => setOpenedModal(null)}
      />
    </>
  );
};

export default AppModals;
