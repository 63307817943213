import React from 'react';
import XIcon from 'app/src/images/XIcon';
import TolstoyLogoWithWord from 'app/src/images/TolstoyLogoWithWord';
import styled from 'styled-components';

const MessageContainer = ({ children, onXButton, ...props }) => {
  return (
    <Message>
      <Content {...props}>
        {onXButton && (
          <XIconContainer onClick={onXButton}>
            <XIcon />
          </XIconContainer>
        )}
        <LogoContainer>
          <TolstoyLogoWithWord />
        </LogoContainer>
        {children}
      </Content>
    </Message>
  );
};

const Message = styled.div`
  background: white;
  height: 100%;
  position: relative;
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    background: ${({ theme }) => theme.colors.gray5};
  }
`;

const Content = styled.div`
  background: ${({ theme }) => theme.colors.gray5};
  width: 98%;
  height: 96.7%;
  margin: 16px auto;
  border-radius: 16px;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding-bottom: 10px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    height: inherit;
  }
`;

const XIconContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 36px;
  right: 36px;
`;

const LogoContainer = styled.div`
  margin-top: 88px;
`;

export default MessageContainer;
