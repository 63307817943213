import { ASSET_TYPE } from './constants';
import { IMAGE_CONTENT_TYPES } from './images';

export const isVideoAsset = asset => !asset.type || asset.type === ASSET_TYPE.VIDEO;

export const isImageAsset = asset => asset.type === ASSET_TYPE.IMAGE;

export const isImageFileSupported = file => IMAGE_CONTENT_TYPES.includes(file?.type);

export const isGalleryAsset = asset => asset.type === ASSET_TYPE.GALLERY;

export const isSavedAsset = asset => !asset.newId;

export default {
  isSavedAsset,
  isImageAsset,
  isVideoAsset,
  isImageFileSupported,
  isGalleryAsset,
};
