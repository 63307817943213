import React from 'react';
import styled, { useTheme } from 'styled-components';
import { SHOPIFY } from 'app/src/constants/intergrations.constants';
import { BASIC_SELECT_HEADER_DATA_TYPE } from 'shared/react/constants/ui.constants';
import { useApps } from 'app/src/context/AppsStore';
import { track } from 'app/src/helpers/Tracker';
import { Gap16HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import BasicSelect from 'shared/react/components/basic/basic_select/BasicSelect';
import { cleanStoreName } from 'app/src/utils/shopify.utils';

const StorePhase = ({ selectedStore, setSelectedStore }) => {
  const [{ apps: storeApps }] = useApps();
  const shopifyApps = storeApps.filter(({ app }) => app === SHOPIFY);
  const theme = useTheme();

  const onShopifyAppChange = value => {
    track('Chose Store on export to store modal', {
      appUrl: value,
    });
    setSelectedStore(value);
  };

  const getShopifyAppsItems = () => {
    return shopifyApps.map(app => {
      return {
        id: app.appUrl,
        name: cleanStoreName(app.appUrl),
      };
    });
  };

  return (
    <StoreSelectContainer>
      <BasicSelect
        inputBackground={theme.colors.white}
        items={getShopifyAppsItems()}
        value={selectedStore.name}
        onChange={onShopifyAppChange}
        itemFontColor={theme.colors.black}
        itemFontSize="16px"
      />
    </StoreSelectContainer>
  );
};

export default StorePhase;

const StoreSelectContainer = styled(Gap16HorizontalFlex)`
  min-height: 48px;
  width: 420px;
  padding: 12px 16px 0;

  & ${`[data-type="${BASIC_SELECT_HEADER_DATA_TYPE}"]`} {
    color: ${({ theme }) => theme.colors.black};
    font-weight: 400;
    font-size: 16px;
  }

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    width: 280px;
  } ;
`;
