import cubejs from '@cubejs-client/core';
import { CUBE_PROPERTY_KEYS } from 'app/src/constants/cube.constants';
import {
  getAttributionDiff,
  getCubeFiltersV2,
  getOrdersSumData,
  getUplift,
} from 'app/src/services/cube/cube.utils';
import { getMetricsData } from 'app/src/services/cube/getConversionMetrics.cube';
import { getConversionOrdersAovMetrics } from 'app/src/services/cube/getConversionOrdersAovMetrics.cube';
import { getConversionOvertimeMetrics } from 'app/src/services/cube/getCovnersionOvertimeMetrics.cube';
import { getOverviewEngagementOvertimeMetrics } from 'app/src/services/cube/getOverviewEngagementOvertimeMetrics.cube';
import { getTimeOnPageMetrics } from 'app/src/services/cube/getTimeOnPageMetrics.cube';
import { getVideoMetrics } from 'app/src/services/cube/getVideoMetrics.cube';
import {
  getConversionEventsOvertimeQuery,
  getConversionMetricsQuery,
  getConversionOrdersQuery,
  getJourneysMetricsQuery,
  getOnsiteQuery,
  getOrdersAovOvertimeQuery,
  getOverviewEngagementsOvertimeQuery,
  getShopMetricsQuery,
  getTimeOnPageOvertimeQuery,
  getTimeOnPageQuery,
  getTopConvertingVideoMetricsQuery,
  getUniqueUsersMetricsQuery,
  getVideoMetricsQuery,
  getViewersMetricsQuery,
  getProjectActivePagesQuery,
  getEmailEventsOvertimeQuery,
  getEmailRecipientsQuery,
  getEmailMetricsOvertimeQuery,
  getEngagementMetricsQuery,
  getProductClicksOvertimeQuery,
} from 'app/src/services/cube/queries.cube';
import { addDays, addMonths, endOfMonth, startOfMonth, subDays } from 'date-fns';
import { createActionsHook, createContainer, createHook, createStore } from 'react-sweet-state';
import { default as SharedUtils } from 'shared/react/utils/utils';
import {
  ATTRIBUTION_WINDOW_OPTIONS,
  CHANNEL_FILTER_OPTIONS,
  CONVERSION_KEY,
  DATE_FILTER_LAST_24_HOURS,
  DATE_FILTER_LAST_30_DAYS,
  DATE_FILTER_LAST_3_DAYS,
  DATE_FILTER_LAST_60_DAYS,
  DATE_FILTER_LAST_7_DAYS,
  DATE_FILTER_LAST_90_DAYS,
  DATE_FILTER_LAST_MONTH,
  DATE_FILTER_THIS_MONTH,
  DATE_FILTER_TODAY,
  DATE_FILTER_YESTERDAY,
  EMAIL_FLOWS_KEY,
  ENGAGEMENT_KEY,
  GENERAL_DATA_KEYS,
  JOURNEYS_PROD,
  ONSITE_KEY,
  ONSITE_TOLSTOYS_PROD,
  ORDER_SUMMARY_PROD,
  OVERVIEW_KEY,
  PLAYER_FEED_SESSIONS,
  PLAYER_USER_LOG,
  SHOP_KEY,
  SITE_ACTIVITY_LOG,
  TAPCART_CUSTOM_BLOCK_BASE_URL,
  TOP_CONVERSION_VIDEOS_LIMIT,
  VIDEOS_KEY,
  VIEWERS_PROD,
} from '../constants/analytics.constants';
import { getRequest, postRequest } from '../helpers/API';
import Utils from '../utils';
import { APPS_SELECT_ALL_STORES_KEY } from 'src/constants/ui.constants';
import { getShopMetrics } from 'src/services/cube/getShopMetrics.cube';
import { CUBE_URL } from 'src/config/app.config';
import { getConversionRateMetrics } from 'app/src/services/cube/getConversionRatesOvertimeMetrics.cube';
import { EVENTS } from 'app/src/constants/tolstoy.constants';
import getEmailEventsOvertime from 'app/src/services/cube/getEmailEventsOvertime.cube';
import getEmailRecipients from 'app/src/services/cube/getEmailRecipients.cube';
import getEmailConversionsOvertime from 'app/src/services/cube/getEmailConversionsOvertime.cube';
import { getVideosConversionMetrics } from 'app/src/services/cube/getVideosConversionMetrics';
import { getProductClicksOvertime } from 'app/src/services/cube/getProductClicksOvertime';
import { CURRENCY_CODES } from 'shared/react/constants/currency.constants';

const DEFAULT_DATE_FILTER = DATE_FILTER_LAST_30_DAYS;

const DATE_FILTER_MAP = {
  [DATE_FILTER_LAST_24_HOURS]: 'from 1 days ago to now',
  [DATE_FILTER_LAST_3_DAYS]: 'last 3 days to yesterday',
  [DATE_FILTER_LAST_7_DAYS]: 'last 7 days to yesterday',
  [DATE_FILTER_LAST_30_DAYS]: 'last 29 days to yesterday',
  [DATE_FILTER_LAST_60_DAYS]: 'last 59 days to yesterday',
  [DATE_FILTER_LAST_90_DAYS]: 'last 89 days to yesterday',
  [DATE_FILTER_THIS_MONTH]: 'This month',
};

const FETCH_REQUESTS_TABS = {
  fetchEngagementFeedClicks: [ENGAGEMENT_KEY],
  fetchEngagementOverTime: [OVERVIEW_KEY, ENGAGEMENT_KEY],
  fetchEngagementMetrics: [OVERVIEW_KEY, ENGAGEMENT_KEY, CONVERSION_KEY, VIDEOS_KEY],
  fetchEngagementEmailCollected: [ENGAGEMENT_KEY],
  fetchEngagementTolstoysEvent: [ENGAGEMENT_KEY],
  fetchEngagementTolstoyClicks: [ENGAGEMENT_KEY],
  fetchConversions: [OVERVIEW_KEY, CONVERSION_KEY, EMAIL_FLOWS_KEY],
  fetchConversionMetrics: [OVERVIEW_KEY, CONVERSION_KEY],
  fetchConversionOvertime: [OVERVIEW_KEY, CONVERSION_KEY],
  fetchConversionOrders: [OVERVIEW_KEY, CONVERSION_KEY],
  fetchOrdersAovOvertime: [OVERVIEW_KEY, CONVERSION_KEY],
  fetchTimeOnPageOvertime: [OVERVIEW_KEY],
  fetchTimeOnPageMetrics: [ENGAGEMENT_KEY],
  fetchOnsite: [ONSITE_KEY],
  fetchOnsiteMetrics: [ONSITE_KEY],
  fetchVideos: [VIDEOS_KEY],
  fetchOverviewEngagementOvertime: [OVERVIEW_KEY],
  fetchShop: [SHOP_KEY],
  fetchShopMetrics: [SHOP_KEY],
  fetchTopConvertingVideoMetrics: [OVERVIEW_KEY],
  fetchUniqueUsersMetrics: [OVERVIEW_KEY],
  fetchProductClicksOvertime: [OVERVIEW_KEY],
};

const getAppKey = accountAppKey => {
  const appKey = accountAppKey || Utils.getAppKey();
  return [appKey];
};

const getAppUrlFilter = appUrl => {
  if (!appUrl || appUrl === APPS_SELECT_ALL_STORES_KEY) {
    return;
  }

  return [appUrl];
};

const getDomainFilter = (domain, channel) => {
  if (channel === CHANNEL_FILTER_OPTIONS.tapcart) {
    return [TAPCART_CUSTOM_BLOCK_BASE_URL];
  }

  if (!domain || domain === APPS_SELECT_ALL_STORES_KEY) {
    return;
  }

  return [domain];
};

const getPublishIds = (publishId, publishIds) => {
  if (!publishId && !publishIds) {
    return null;
  }

  return publishId ? [publishId] : publishIds;
};

const getCubejsApi = () => {
  const cubeJsApi = cubejs(Utils.getCubeJsKey(), {
    apiUrl: CUBE_URL,
  });
  return cubeJsApi;
};

const getDate = date => {
  const month = Utils.addZeroToNumberIfNeeded(date.getMonth() + 1);
  const day = Utils.addZeroToNumberIfNeeded(date.getDate());

  return `${date.getFullYear()}-${month}-${day}`;
};

const getDateRange = dateFilter => {
  return (
    DATE_FILTER_MAP[dateFilter] || [getDate(dateFilter.startDate), getDate(dateFilter.endDate)]
  );
};

const getEngagementEventsOvertimeQuery = (
  { dateRange, unique = true, ...filterParams },
  selectedTab
) => {
  const key = PLAYER_USER_LOG;
  const measure =
    unique && selectedTab !== OVERVIEW_KEY ? `${key}.uniqueUserCount` : `${key}.count`;
  return {
    measures: [measure],
    timeDimensions: [
      {
        dimension: `${key}.createdat`,
        granularity: 'day',
        dateRange,
      },
    ],
    filters: [
      ...getCubeFiltersV2({ filters: filterParams, tableKey: key }),
      {
        member: `${key}.eventname`,
        operator: 'equals',
        values: [
          'pageView',
          'clickCta',
          'videoResponse',
          'sessionStart',
          'videoLoaded',
          'submitInput',
          'audioResponse',
          'collectInfo',
          'feedScroll',
          'addToCart',
        ],
      },
    ],
    dimensions: [`${key}.eventname`],
  };
};

const getEngagementFeedClicksQuery = ({ dateRange, unique, ...filterParams }) => {
  const key = PLAYER_FEED_SESSIONS;
  return {
    measures: [`${key}.totalTimeAverage`, `${key}.count`],
    timeDimensions: [
      {
        dimension: `${key}.createdat`,
        dateRange,
      },
    ],
    filters: getCubeFiltersV2({ filters: filterParams, tableKey: key }),
    dimensions: [`${key}.videoname`],
  };
};

const getEngagementEmailCollectedQuery = ({ dateRange, ...filterParams }) => {
  const key = 'PlayerUserLogEmails';
  return {
    dimensions: [
      `${key}.email`,
      `${key}.publishid`,
      `${key}.sessionStart`,
      `${key}.clickCta`,
      `${key}.createdat`,
    ],
    timeDimensions: [
      {
        dimension: `${key}.createdat`,
        granularity: 'day',
        dateRange,
      },
    ],
    order: {
      [`${key}.createdat`]: 'desc',
    },
    filters: getCubeFiltersV2({ filters: filterParams, tableKey: key }),
    limit: 100,
    measures: [`${key}.count`],
  };
};

const getEngagementTolstoysEventQuery = ({ dateRange, unique = true, ...filterParams }) => {
  const key = PLAYER_USER_LOG;
  const measure = unique ? `${key}.uniqueUserCount` : `${key}.count`;
  return {
    measures: [measure],
    timeDimensions: [
      {
        dimension: `${key}.createdat`,
        dateRange,
      },
    ],
    order: {
      [`${key}.count`]: 'desc',
    },
    dimensions: [`${key}.publishid`, `${key}.eventname`],
    filters: [
      ...getCubeFiltersV2({ filters: filterParams, tableKey: key }),
      {
        member: `${key}.eventname`,
        operator: 'equals',
        values: [
          'sessionStart',
          'clickCta',
          'pageView',
          'audioResponse',
          'collectInfo',
          'videoResponse',
          'submitInput',
          'sidebarClick',
        ],
      },
    ],
  };
};

const getEngagementTolstoyClicksQuery = ({ dateRange, unique = true, ...filterParams }) => {
  const key = PLAYER_USER_LOG;
  const measure = unique ? `${key}.uniqueUserCount` : `${key}.count`;
  return {
    measures: [measure],
    timeDimensions: [
      {
        dimension: `${key}.createdat`,
        dateRange,
      },
    ],
    dimensions: [`${key}.stepname`, `${key}.answerkey`],
    filters: [
      ...getCubeFiltersV2({ filters: filterParams, tableKey: key }),
      {
        member: `${key}.eventname`,
        operator: 'equals',
        values: ['clickCta'],
      },
    ],
  };
};

const getCurrentMonthPageView = ({ appKey } = {}) => {
  const key = PLAYER_USER_LOG;
  return {
    measures: [`${key}.uniqueUserCount`],
    timeDimensions: [
      {
        dimension: `${key}.createdat`,
        dateRange: 'This month',
      },
    ],
    filters: [
      {
        member: `${key}.appkey`,
        operator: 'equals',
        values: appKey,
      },
      {
        member: `${key}.eventname`,
        operator: 'equals',
        values: ['pageView'],
      },
    ],
    dimensions: [`${key}.eventname`],
  };
};

const getRates = async () => {
  return getRequest('rates', '/currency/rates');
};

const initialState = {
  dateFilter: DEFAULT_DATE_FILTER,
  publishIds: null,
  rates: null,
  unique: true,
  videoMetricsSortingModel: [],
  engagementEventsOvertime: null,
  engagementTolstoyClicks: null,
  engagementFeedClicks: null,
  currentMonthPageView: null,
  averageTimeSpent: null,
  totalTimeSpent: null,
  conversionRateCount: null,
  conversionCount: null,
  feedTotalTimeAverage: null,
  didntPlayTolstoyConversionMetrics: null,
  selectedTab: null,
  interactionFilter: ATTRIBUTION_WINDOW_OPTIONS.twentyFourHours,
  isLoadingOnsiteMetrics: true,
  isLoadingVideoMetrics: true,
  isLoadingConversionOrders: true,
  isLoadingOrdersAovOvertime: true,
  isLoadingOverviewEngagementOvertime: true,
  isLoadingTopConvertingVideoMetrics: true,
  isLoadingUniqueUsersMetrics: true,
  appUrl: null,
  domain: null,
  benchmarkData: null,
  shopMetrics: null,
  isLoadingShopMetrics: true,
  projectActivePages: null,
  isProjectActivePagesLoading: true,
  emailEventsOvertime: null,
  isLoadingEmailEventsOvertime: true,
  emailMetricsOvertime: null,
  isLoadingEmailMetricsOvertime: true,
  emailRecipients: null,
  isLoadingEmailRecipients: true,
  channel: null,
  topConvertingVideoInsights: {},
  isLoadingTopConvertingVideoInsights: false,
  productClicksOvertime: null,
  isLoadingProductClicksOvertime: true,
  currency: CURRENCY_CODES.usd,
};

const actions = {
  fetchAll:
    () =>
    ({ dispatch }) => {
      dispatch(actions.clearEngagements());
      dispatch(actions.clearConversions());
      dispatch(actions.clearOnsite());
      dispatch(actions.clearVideos());
      dispatch(actions.clearBenchmarkData());
      dispatch(actions.clearEmail());
      dispatch(actions.fetchEngagements());
      dispatch(actions.fetchConversions());
      dispatch(actions.fetchOnsite());
      dispatch(actions.fetchVideos());
      dispatch(actions.fetchViewers());
      dispatch(actions.fetchBenchmarkData());
      dispatch(actions.fetchShop());
      dispatch(actions.fetchTopConvertingVideoMetrics());
      dispatch(actions.fetchUniqueUsersMetrics());
      dispatch(actions.fetchProductClicksOvertime());
    },
  setSelectedTab:
    selectedTab =>
    ({ setState, dispatch }) => {
      setState({ selectedTab });
      dispatch(actions.fetchAll());
    },
  setDateFilter:
    dateFilter =>
    ({ setState, dispatch }) => {
      setState({ dateFilter });
      dispatch(actions.fetchAll());
    },
  setAppFilter:
    (appUrl, domain) =>
    ({ setState, dispatch }) => {
      setState({ appUrl, domain });
      dispatch(actions.fetchAll());
    },
  setInteractionFilter:
    interactionFilter =>
    ({ setState, dispatch }) => {
      setState({ interactionFilter });

      dispatch(actions.fetchAll());
    },
  setChannelFilter:
    channel =>
    ({ setState, dispatch }) => {
      setState({ channel });

      dispatch(actions.fetchAll());
    },
  setPublishIds:
    publishIds =>
    ({ setState, dispatch }) => {
      setState({ publishIds });

      dispatch(actions.fetchAll());
    },
  setUnique:
    unique =>
    ({ setState, dispatch }) => {
      setState({ unique });

      dispatch(actions.clearEngagements());
      dispatch(actions.fetchEngagements());
    },
  setVideoMetricsSortingModel:
    videoMetricsSortingModel =>
    ({ setState }) => {
      setState({ videoMetricsSortingModel });
    },
  shouldShowFeedTable:
    () =>
    ({ getState }) => {
      const { dateFilter } = getState();
      const date = new Date();
      const lastDateOfTable = new Date('2022-12-19T20:20:30.914Z');
      let days = null;
      switch (dateFilter) {
        case DATE_FILTER_LAST_7_DAYS:
          days = 7;
          break;
        case DATE_FILTER_LAST_30_DAYS:
          days = 30;
          break;
        case DATE_FILTER_LAST_60_DAYS:
          days = 60;
          break;
        case DATE_FILTER_LAST_90_DAYS:
          days = 90;
          break;
      }

      const startDate = days ? subDays(date, days) : dateFilter?.startDate;
      return startDate > lastDateOfTable;
    },
  fetchEngagements:
    () =>
    ({ getState, dispatch }, { publishId: accountPublishId, isFeed }) => {
      const { unique, selectedTab } = getState();

      const filters = dispatch(
        actions.getAllFilters({ domainInResult: true, publishIdInResult: true })
      );
      const params = { ...filters };
      params.unique = selectedTab === OVERVIEW_KEY ? false : unique;

      dispatch(actions.fetchEngagementOverTime(params));
      dispatch(actions.fetchOverviewEngagementOvertime(params));
      dispatch(actions.fetchEngagementMetrics(params));
      dispatch(actions.fetchTimeOnPageMetrics(filters));

      if (!accountPublishId) {
        dispatch(actions.fetchEngagementTolstoysEvent(params));
        return;
      }

      dispatch(actions.fetchEngagementTolstoyClicks(params));

      if (isFeed) {
        if (!actions.shouldShowFeedTable()) {
          return;
        }

        dispatch(actions.fetchEngagementFeedClicks(params));
        return;
      }

      dispatch(
        actions.fetchEngagementEmailCollected({
          appKey: filters.appKey,
          publishId: filters.publishId,
          dateRange: filters.dateRange,
        })
      );
    },
  fetchOverviewEngagementOvertime:
    params =>
    async ({ getState, setState }) => {
      const { selectedTab } = getState();
      if (!FETCH_REQUESTS_TABS.fetchOverviewEngagementOvertime.includes(selectedTab)) {
        return;
      }

      const { unique, ...filteredParams } = params;
      const resultSet = await getCubejsApi().load(
        getOverviewEngagementsOvertimeQuery(filteredParams)
      );
      if (!resultSet) {
        setState({ isLoadingOverviewEngagementOvertime: false });
        return;
      }

      const tableData = resultSet?.chartPivot();
      const overviewEngagementOvertime = tableData?.map(({ x, xValues, ...data }) => {
        return {
          x,
          xValues,
          numOfGenericInteractions:
            data[`${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.numOfGenericInteractions}`],
          numOfProductInteractions:
            data[`${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.numOfProductInteractions}`],
          numOfSessionStarts: data[`${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.numOfSessionStarts}`],
          numOfSwipes: data[`${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.numOfSwipes}`],
        };
      });

      setState({ overviewEngagementOvertime, isLoadingOverviewEngagementOvertime: false });
    },
  fetchEngagementFeedClicks:
    params =>
    async ({ getState, setState }) => {
      const { selectedTab } = getState();
      if (!FETCH_REQUESTS_TABS.fetchEngagementFeedClicks.includes(selectedTab)) {
        return;
      }

      const key = PLAYER_FEED_SESSIONS;
      const resultSet = await getCubejsApi().load(getEngagementFeedClicksQuery(params));
      if (!resultSet) {
        return;
      }

      const tableData = resultSet?.tablePivot();
      const engagementFeedClicks = tableData.map((data, index) => {
        return {
          id: index,
          totalTimeAverage: (+data[`${key}.totalTimeAverage`] || 0).toFixed(2),
          videoName: data[`${key}.videoname`],
          count: data[`${key}.count`],
        };
      });

      setState({ engagementFeedClicks });
    },
  fetchEngagementOverTime:
    params =>
    async ({ getState, setState }) => {
      const { selectedTab } = getState();
      if (!FETCH_REQUESTS_TABS.fetchEngagementOverTime.includes(selectedTab)) {
        return;
      }

      const resultSet = await getCubejsApi().load(
        getEngagementEventsOvertimeQuery(params, selectedTab)
      );

      const engagementEventsOvertime = resultSet?.chartPivot();

      setState({ engagementEventsOvertime });
    },
  fetchEngagementMetrics:
    params =>
    async ({ getState, setState }) => {
      const { selectedTab } = getState();
      if (!FETCH_REQUESTS_TABS.fetchEngagementMetrics.includes(selectedTab)) {
        return;
      }

      const resultSet = await getCubejsApi().load(getEngagementMetricsQuery(params));
      if (!resultSet) {
        return;
      }

      const tableData = resultSet?.tablePivot({
        x: [`${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.eventsCombined}`],
        y: ['measures'],
      });

      const eventKey = `${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.eventsCombined}`;
      const countKey = `${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.count}`;
      const uniqueCountKey = `${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.uniqueUserCount}`;
      const totalTimeAverageKey = `${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.totalTimeAverage}`;
      const totalTimeKey = `${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.totalTimeSum}`;

      const engagementMetrics = tableData.reduce((acc, data) => {
        const key = data[eventKey];
        const numOfEvents = data[countKey];
        const uniqueNumOfEvents = data[uniqueCountKey];
        const totalTimeSpent = data[totalTimeKey];
        const averageTimeSpent = data[totalTimeAverageKey];

        acc[key] = numOfEvents;
        acc[`${key}Unique`] = uniqueNumOfEvents;

        if (totalTimeSpent || averageTimeSpent) {
          acc.totalTimeSpent = SharedUtils.secondsToTime(totalTimeSpent);
          acc.averageTimeSpent = averageTimeSpent;
        }

        return acc;
      }, {});

      setState({ engagementMetrics });
    },
  fetchEngagementEmailCollected:
    params =>
    async ({ getState, setState }) => {
      const { selectedTab } = getState();
      if (!FETCH_REQUESTS_TABS.fetchEngagementEmailCollected.includes(selectedTab)) {
        return;
      }

      const engagementEmailCollected = await getCubejsApi().load(
        getEngagementEmailCollectedQuery(params)
      );

      setState({ engagementEmailCollected });
    },
  fetchEngagementTolstoysEvent:
    params =>
    async ({ getState, setState }) => {
      const { selectedTab } = getState();
      if (!FETCH_REQUESTS_TABS.fetchEngagementTolstoysEvent.includes(selectedTab)) {
        return;
      }

      const engagementTolstoysEvent = await getCubejsApi().load(
        getEngagementTolstoysEventQuery(params)
      );

      setState({ engagementTolstoysEvent });
    },
  fetchEngagementTolstoyClicks:
    params =>
    async ({ getState, setState }) => {
      const { selectedTab } = getState();
      if (!FETCH_REQUESTS_TABS.fetchEngagementTolstoyClicks.includes(selectedTab)) {
        return;
      }

      const { unique = true } = params;
      const resultSet = await getCubejsApi().load(getEngagementTolstoyClicksQuery(params));
      const key = PLAYER_USER_LOG;
      const tableData = resultSet?.tablePivot();
      const countKey = unique ? `${key}.uniqueUserCount` : `${key}.count`;
      const engagementTolstoyClicks = tableData.map(data => {
        return {
          id: `${data[`${key}.stepname`]}_${data[`${key}.answerkey`]}`,
          answerKey: data[`${key}.answerkey`],
          stepName: data[`${key}.stepname`],
          clicks: data[countKey],
        };
      });

      setState({ engagementTolstoyClicks });
    },
  fetchConversions:
    () =>
    async ({ getState, setState, dispatch }) => {
      const { rates, selectedTab } = getState();
      if (!FETCH_REQUESTS_TABS.fetchConversions.includes(selectedTab)) {
        return;
      }

      if (!rates) {
        const response = await getRates();
        setState({ rates: response });
      }

      const filters = dispatch(actions.getAllFilters());
      const filterInQuery = dispatch(
        actions.getAllFilters({ domainInResult: true, publishIdInResult: true })
      );
      dispatch(actions.fetchConversionMetrics(filters));
      dispatch(actions.fetchConversionOvertime(filters));
      dispatch(actions.fetchConversionOrders(filters));
      dispatch(actions.fetchOrdersAovOvertime(filters));
      dispatch(actions.fetchTimeOnPageOvertime(filterInQuery));
    },
  fetchOnsite:
    () =>
    async ({ getState, dispatch }) => {
      const { selectedTab } = getState();
      if (!FETCH_REQUESTS_TABS.fetchOnsite.includes(selectedTab)) {
        return;
      }

      const params = dispatch(actions.getAllFilters({ publishIdInResult: true }));
      dispatch(actions.fetchOnsiteMetrics(params));
    },
  fetchVideos:
    () =>
    async ({ getState, setState, dispatch }) => {
      const { selectedTab, rates } = getState();
      if (!FETCH_REQUESTS_TABS.fetchVideos.includes(selectedTab)) {
        return;
      }

      if (!rates) {
        const response = await getRates();
        setState({ rates: response });
      }

      const params = dispatch(actions.getAllFilters());
      dispatch(actions.fetchVideoMetrics(params));
    },
  fetchViewers:
    () =>
    async ({ dispatch }) => {
      const params = dispatch(actions.getAllFilters());

      dispatch(actions.fetchViewersMetrics(params));
    },
  fetchShop:
    () =>
    async ({ getState, dispatch }) => {
      const { selectedTab } = getState();
      if (!FETCH_REQUESTS_TABS.fetchShop.includes(selectedTab)) {
        return;
      }

      const { attributiondiff, ...params } = dispatch(
        actions.getAllFilters({
          appUrlInResult: true,
        })
      );
      dispatch(actions.fetchShopMetrics(params));
    },
  fetchConversionMetrics: (params = {}) => {
    return async ({ getState, setState }) => {
      const { selectedTab } = getState();
      if (!FETCH_REQUESTS_TABS.fetchConversionMetrics.includes(selectedTab)) {
        return;
      }

      const conversionMetricsData = await getCubejsApi().load(getConversionMetricsQuery(params));

      const conversionMetrics = conversionMetricsData?.tablePivot();

      setState({ conversionMetrics });
    };
  },
  fetchConversionOvertime:
    params =>
    async ({ getState, setState }) => {
      const { selectedTab } = getState();
      if (!FETCH_REQUESTS_TABS.fetchConversionOvertime.includes(selectedTab)) {
        return;
      }

      const resultSet = await getCubejsApi().load(getConversionEventsOvertimeQuery(params));

      const conversionOvertime = resultSet?.chartPivot();

      setState({ conversionOvertime });
    },
  fetchConversionOrders:
    params =>
    async ({ getState, setState }) => {
      const { selectedTab } = getState();
      if (!FETCH_REQUESTS_TABS.fetchConversionOrders.includes(selectedTab)) {
        setState({ isLoadingConversionOrders: false });
        return;
      }

      setState({ isLoadingConversionOrders: true });

      const resultSet = await getCubejsApi().load(getConversionOrdersQuery(params));

      if (!resultSet) {
        setState({ isLoadingConversionOrders: false });
        return null;
      }

      const conversionOrders = resultSet?.tablePivot().map(data => {
        return {
          id: data[`${ORDER_SUMMARY_PROD}.${CUBE_PROPERTY_KEYS.orderId}`],
          orderId: data[`${ORDER_SUMMARY_PROD}.${CUBE_PROPERTY_KEYS.orderId}`],
          orderName: data[`${ORDER_SUMMARY_PROD}.${CUBE_PROPERTY_KEYS.orderName}`],
          currency: data[`${ORDER_SUMMARY_PROD}.${CUBE_PROPERTY_KEYS.currency}`],
          email: data[`${ORDER_SUMMARY_PROD}.${CUBE_PROPERTY_KEYS.email}`],
          inVideoRevenue: data[`${ORDER_SUMMARY_PROD}.${CUBE_PROPERTY_KEYS.inVideoRevenue}`],
          postVideoRevenue: data[`${ORDER_SUMMARY_PROD}.${CUBE_PROPERTY_KEYS.postVideoRevenue}`],
          indirectRevenue: data[`${ORDER_SUMMARY_PROD}.${CUBE_PROPERTY_KEYS.indirectRevenue}`],
          timeOnTolstoy: data[`${ORDER_SUMMARY_PROD}.${CUBE_PROPERTY_KEYS.timeOnTolstoy}`],
          appUrl: data[`${ORDER_SUMMARY_PROD}.${CUBE_PROPERTY_KEYS.appUrl}`],
          createdAt: data[`${ORDER_SUMMARY_PROD}.${CUBE_PROPERTY_KEYS.createdAt}`],
        };
      });

      setState({ conversionOrders, isLoadingConversionOrders: false });
    },
  fetchOrdersAovOvertime:
    params =>
    async ({ getState, setState }) => {
      const { selectedTab } = getState();
      if (!FETCH_REQUESTS_TABS.fetchOrdersAovOvertime.includes(selectedTab)) {
        setState({ isLoadingOrdersAovOvertime: false });
        return;
      }

      const resultSet = await getCubejsApi().load(getOrdersAovOvertimeQuery(params));

      const ordersAovOvertime = resultSet?.chartPivot();

      setState({ ordersAovOvertime, isLoadingOrdersAovOvertime: false });
    },
  fetchTimeOnPageMetrics:
    params =>
    async ({ getState, setState }) => {
      const { selectedTab } = getState();
      if (!FETCH_REQUESTS_TABS.fetchTimeOnPageMetrics.includes(selectedTab)) {
        return;
      }

      const resultSet = await getCubejsApi().load(getTimeOnPageQuery(params));

      const timeOnPageMetrics = resultSet?.chartPivot();

      setState({ timeOnPageMetrics });
    },
  fetchTimeOnPageOvertime:
    params =>
    async ({ getState, setState }) => {
      const { selectedTab } = getState();
      if (!FETCH_REQUESTS_TABS.fetchTimeOnPageOvertime.includes(selectedTab)) {
        return;
      }

      const resultSet = await getCubejsApi().load(getTimeOnPageOvertimeQuery(params));

      const timeOnPageOvertime = resultSet?.chartPivot();

      setState({ timeOnPageOvertime });
    },
  fetchOnsiteMetrics:
    params =>
    async ({ getState, setState }) => {
      const { selectedTab } = getState();
      if (!FETCH_REQUESTS_TABS.fetchOnsiteMetrics.includes(selectedTab)) {
        setState({ isLoadingOnsiteMetrics: false });
        return;
      }

      setState({ isLoadingOnsiteMetrics: true });

      const resultSet = await getCubejsApi().load(getOnsiteQuery(params));

      if (!resultSet) {
        setState({ isLoadingOnsiteMetrics: false });
        return null;
      }

      const onsiteMetrics = resultSet?.tablePivot().map((data, index) => {
        const parentUrl = data[`${ONSITE_TOLSTOYS_PROD}.${CUBE_PROPERTY_KEYS.parentUrl}`];
        const publishId = data[`${ONSITE_TOLSTOYS_PROD}.${CUBE_PROPERTY_KEYS.publishId}`];
        const currency = data[`${ONSITE_TOLSTOYS_PROD}.${CUBE_PROPERTY_KEYS.currency}`];
        const id = index;

        return {
          id,
          publishId,
          parentUrl,
          inVideoRevenue: data[`${ONSITE_TOLSTOYS_PROD}.${CUBE_PROPERTY_KEYS.inVideoRevenue}`],
          postVideoRevenue: data[`${ONSITE_TOLSTOYS_PROD}.${CUBE_PROPERTY_KEYS.postVideoRevenue}`],
          indirectRevenue: data[`${ONSITE_TOLSTOYS_PROD}.${CUBE_PROPERTY_KEYS.indirectRevenue}`],
          currency,
          averageTimeOnTolstoy:
            data[`${ONSITE_TOLSTOYS_PROD}.${CUBE_PROPERTY_KEYS.averageTimeOnTolstoy}`],
          averageTimeOnPage:
            data[`${ONSITE_TOLSTOYS_PROD}.${CUBE_PROPERTY_KEYS.averageTimeOnPage}`],
          averageTimeOnPageWhenInteractingTolstoy:
            data[
              `${ONSITE_TOLSTOYS_PROD}.${CUBE_PROPERTY_KEYS.averageTimeOnPageWhenInteractingTolstoy}`
            ],
          numOfVideosWatched:
            data[`${ONSITE_TOLSTOYS_PROD}.${CUBE_PROPERTY_KEYS.numOfVideosWatched}`],
          plays: data[`${ONSITE_TOLSTOYS_PROD}.${CUBE_PROPERTY_KEYS.plays}`],
          numOfEmbedViews: data[`${ONSITE_TOLSTOYS_PROD}.${CUBE_PROPERTY_KEYS.numOfEmbedViews}`],
          numOfOrders: data[`${ONSITE_TOLSTOYS_PROD}.${CUBE_PROPERTY_KEYS.numOfOrders}`],
        };
      });

      setState({ onsiteMetrics, isLoadingOnsiteMetrics: false });
    },
  fetchVideoMetrics:
    params =>
    async ({ getState, setState }) => {
      const { selectedTab, currency, rates } = getState();
      if (!FETCH_REQUESTS_TABS.fetchVideos.includes(selectedTab) || !currency) {
        return;
      }

      setState({ isLoadingVideoMetrics: true });

      const resultSet = await getCubejsApi().load(getVideoMetricsQuery(params));

      if (!resultSet) {
        setState({ isLoadingVideoMetrics: false });
        return null;
      }

      const videoMetrics = getVideoMetrics({
        resultSet,
        currency,
        rates,
      });

      const totalVideosWatched = videoMetrics.reduce((acc, video) => {
        return acc + +video.plays;
      }, 0);

      setState({
        videoMetrics,
        videoMetricsSummary: { totalVideosWatched },
        isLoadingVideoMetrics: false,
      });
    },
  fetchTopConvertingVideoMetrics:
    () =>
    async ({ getState, setState, dispatch }) => {
      const { selectedTab } = getState();
      if (!FETCH_REQUESTS_TABS.fetchTopConvertingVideoMetrics.includes(selectedTab)) {
        return;
      }

      setState({ isLoadingTopConvertingVideoMetrics: true });

      const params = dispatch(actions.getAllFilters());
      const resultSet = await getCubejsApi().load(
        getTopConvertingVideoMetricsQuery({
          ...params,
          limit: TOP_CONVERSION_VIDEOS_LIMIT,
        })
      );
      if (!resultSet) {
        setState({ isLoadingTopConvertingVideoMetrics: false });
        return null;
      }

      setState({
        topConvertingVideoMetrics: getVideosConversionMetrics(resultSet),
        isLoadingTopConvertingVideoMetrics: false,
      });

      dispatch(actions.fetchTopConvertingVideoInsights());
    },
  fetchTopConvertingVideoInsights:
    () =>
    async ({ getState, setState }) => {
      const {
        topConvertingVideoInsights,
        topConvertingVideoMetrics,
        isLoadingTopConvertingVideoMetrics,
        isLoadingTopConvertingVideoInsights,
      } = getState();

      const hasAlreadyFetchedInsights = Object.keys(topConvertingVideoInsights).length;
      if (
        hasAlreadyFetchedInsights ||
        isLoadingTopConvertingVideoInsights ||
        isLoadingTopConvertingVideoMetrics ||
        !topConvertingVideoMetrics?.length
      ) {
        return null;
      }

      setState({ isLoadingTopConvertingVideoInsights: true });

      const insights = await postRequest('video-analysis', '/top-converting-video-insights', {
        body: {
          topConvertingVideoMetrics,
        },
      });

      setState({
        topConvertingVideoInsights: insights,
        isLoadingTopConvertingVideoInsights: false,
      });
    },
  fetchViewersMetrics:
    params =>
    async ({ setState }) => {
      setState({ isLoadingViewersMetrics: true });

      const resultSet = await getCubejsApi().load(getViewersMetricsQuery(params));

      if (!resultSet) {
        setState({ isLoadingViewersMetrics: false });
        return null;
      }

      const viewersMetrics = resultSet?.tablePivot().map((data, index) => {
        return {
          id: index,
          email: data[`${VIEWERS_PROD}.${CUBE_PROPERTY_KEYS.email}`],
          createdAt: data[`${VIEWERS_PROD}.${CUBE_PROPERTY_KEYS.createdAt}`],
          numOfOrders: data[`${VIEWERS_PROD}.${CUBE_PROPERTY_KEYS.numOfOrders}`] || 0,
          numOfVideosWatched: data[`${VIEWERS_PROD}.${CUBE_PROPERTY_KEYS.numOfVideosWatched}`],
          currency: data[`${VIEWERS_PROD}.${CUBE_PROPERTY_KEYS.currency}`],
        };
      });

      setState({ viewersMetrics, isLoadingViewersMetrics: false });
    },
  fetchCurrentMonthPageView:
    () =>
    async ({ getState, setState }, { appKey: accountAppKey }) => {
      const key = PLAYER_USER_LOG;
      const { currentMonthPageView } = getState();

      if (currentMonthPageView) {
        return;
      }

      const appKey = getAppKey(accountAppKey);

      const resultSet = await getCubejsApi().load(getCurrentMonthPageView({ appKey }));
      const newCurrentMonthPageView = resultSet.totalRow()?.[`${key}.uniqueUserCount`] || 0;
      setState({ currentMonthPageView: newCurrentMonthPageView });
    },
  fetchShopMetrics:
    params =>
    async ({ getState, setState }) => {
      const { selectedTab } = getState();
      if (!FETCH_REQUESTS_TABS.fetchShopMetrics.includes(selectedTab)) {
        return;
      }

      setState({ isLoadingShopMetrics: true });

      const resultSet = await getCubejsApi().load(getShopMetricsQuery(params));

      if (!resultSet) {
        setState({ isLoadingShopMetrics: false });
        return null;
      }

      const data = resultSet.tablePivot();

      const shopMetrics = getShopMetrics({ data });

      setState({ shopMetrics, isLoadingShopMetrics: false });
    },
  getViewerJourney:
    email =>
    async ({ dispatch }) => {
      const filters = dispatch(actions.getAllFilters({ publishIdInResult: true }));

      const resultSet = await getCubejsApi().load(
        getJourneysMetricsQuery({ ...filters, email: [email] })
      );

      if (!resultSet) {
        return null;
      }

      const journeysMetrics = resultSet?.tablePivot().map((data, index) => {
        return {
          id: index,
          createdAt: data[`${JOURNEYS_PROD}.${CUBE_PROPERTY_KEYS.createdAt}`],
          eventName: data[`${JOURNEYS_PROD}.${CUBE_PROPERTY_KEYS.eventName}`],
          videoId: data[`${JOURNEYS_PROD}.${CUBE_PROPERTY_KEYS.videoId}`],
          orderId: data[`${JOURNEYS_PROD}.${CUBE_PROPERTY_KEYS.orderId}`],
          publishId: data[`${JOURNEYS_PROD}.${CUBE_PROPERTY_KEYS.publishId}`],
          parentUrl: data[`${JOURNEYS_PROD}.${CUBE_PROPERTY_KEYS.parentUrl}`],
          watchTime: data[`${JOURNEYS_PROD}.${CUBE_PROPERTY_KEYS.watchTime}`],
        };
      });

      return journeysMetrics;
    },
  getConversionOvertimeData:
    () =>
    ({ getState }) => {
      const { currency, conversionOvertime, rates } = getState();

      if (!currency || !conversionOvertime) {
        return null;
      }

      return getConversionOvertimeMetrics({
        conversionOvertime,
        currency,
        rates,
      });
    },
  fetchUniqueUsersMetrics:
    () =>
    async ({ dispatch, setState, getState }) => {
      const { selectedTab } = getState();
      if (!FETCH_REQUESTS_TABS.fetchUniqueUsersMetrics.includes(selectedTab)) {
        return;
      }

      setState({ isLoadingUniqueUsersMetrics: true });
      const params = dispatch(
        actions.getAllFilters({ publishIdInResult: true, domainInResult: true })
      );
      const resultSet = await getCubejsApi().load(getUniqueUsersMetricsQuery(params));

      if (!resultSet) {
        setState({ isLoadingUniqueUsersMetrics: false });
        return null;
      }

      const sessionStartsKey = `${EVENTS.sessionStart},${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.uniqueUserCount}`;
      const pageViewsKey = `${EVENTS.pageView},${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.uniqueUserCount}`;
      const uniqueUsersMetrics = resultSet?.chartPivot().map(({ x, xValues, ...data }) => {
        return {
          x,
          xValues,
          sessionStarts: data[sessionStartsKey] || 0,
          pageViews: data[pageViewsKey] || 0,
        };
      });

      setState({ uniqueUsersMetrics, isLoadingUniqueUsersMetrics: false });
    },
  getUniqueUsersSummary:
    () =>
    ({ getState }) => {
      const { uniqueUsersMetrics } = getState();
      const initialData = {
        totalUniqueUsersSessionStarts: 0,
        totalUniqueUsersPageViews: 0,
      };

      if (!uniqueUsersMetrics) {
        return initialData;
      }

      return uniqueUsersMetrics.reduce(
        (acc, { sessionStarts, pageViews }) => ({
          totalUniqueUsersPageViews: acc.totalUniqueUsersPageViews + pageViews,
          totalUniqueUsersSessionStarts: acc.totalUniqueUsersSessionStarts + sessionStarts,
        }),
        initialData
      );
    },
  getConversionMetricsData:
    () =>
    ({ getState, setState }) => {
      const { conversionMetrics, rates } = getState();

      if (!conversionMetrics) {
        return null;
      }

      const { currency, totalRevenue, totalIndirectRevenue, directRevenue } = getMetricsData({
        conversionMetrics,
        rates,
      });

      setState({ currency });

      return {
        currency,
        totalIndirectRevenue,
        directRevenue,
        totalRevenue,
      };
    },
  getConversionOrdersAovData:
    () =>
    ({ getState }) => {
      const { currency, ordersAovOvertime, rates } = getState();

      if (!ordersAovOvertime || !currency) {
        return null;
      }

      const ordersAovOvertimeData = getConversionOrdersAovMetrics({
        ordersAovOvertime,
        currency,
        rates,
      });

      const { ordersAov: tolstoyOrdersAov, ordersTotalCount: tolstoyOrdersTotalCount } =
        getOrdersSumData({
          data: ordersAovOvertimeData,
          avgPriceKey: GENERAL_DATA_KEYS.tolstoyDailyAveragePrice,
          dailyTotalCountKey: GENERAL_DATA_KEYS.tolstoyDailyTotalCount,
        });

      const { ordersAov: baselineOrdersAov, ordersTotalCount: baselineOrdersTotalCount } =
        getOrdersSumData({
          data: ordersAovOvertimeData,
          avgPriceKey: GENERAL_DATA_KEYS.baselineDailyAveragePrice,
          dailyTotalCountKey: GENERAL_DATA_KEYS.baselineDailyTotalCount,
        });

      const aovOvertimeUplift = getUplift({
        value: tolstoyOrdersAov,
        baseline: baselineOrdersAov,
      });

      return {
        ordersAovOvertimeData,
        tolstoyOrdersAov,
        tolstoyOrdersTotalCount,
        baselineOrdersAov,
        baselineOrdersTotalCount,
        aovOvertimeUplift,
      };
    },
  getTimeOnPageOverTimeData:
    () =>
    ({ getState }) => {
      const { timeOnPageOvertime } = getState();

      if (!timeOnPageOvertime) {
        return null;
      }

      return getTimeOnPageMetrics({ timeOnPage: timeOnPageOvertime });
    },
  getTimeOnPageData:
    () =>
    ({ getState }) => {
      const { timeOnPageMetrics } = getState();

      if (!timeOnPageMetrics) {
        return null;
      }

      const timeOnPageMetricsData = getTimeOnPageMetrics({ timeOnPage: timeOnPageMetrics });
      return timeOnPageMetricsData?.[0];
    },
  getTimeOnPageOvertimeSummary:
    () =>
    ({ dispatch }) => {
      const timeOnPageOvertimeData = dispatch(actions.getTimeOnPageOverTimeData());
      const initialData = {
        numOfNonInteractingVisitsUnique: 0,
      };

      if (!timeOnPageOvertimeData) {
        return initialData;
      }

      return timeOnPageOvertimeData.reduce(
        (acc, { numOfNonInteractingVisitsUnique }) => ({
          numOfNonInteractingVisitsUnique:
            acc.numOfNonInteractingVisitsUnique + numOfNonInteractingVisitsUnique,
        }),
        initialData
      );
    },
  getOverviewEngagementOvertimeData:
    () =>
    ({ getState }) => {
      const { overviewEngagementOvertime } = getState();

      if (!overviewEngagementOvertime) {
        return null;
      }

      const overviewEngagementOvertimeMetrics = getOverviewEngagementOvertimeMetrics({
        overviewEngagementOvertime,
      });

      return {
        overviewEngagementOvertimeMetrics,
        overviewEngagementOvertime,
      };
    },
  getConversionRatesOvertimeData:
    () =>
    ({ getState, dispatch }) => {
      const { currency, ordersAovOvertime, rates, uniqueUsersMetrics } = getState();
      const timeOnPageOvertimeData = dispatch(actions.getTimeOnPageOverTimeData());

      if (!ordersAovOvertime || !uniqueUsersMetrics || !timeOnPageOvertimeData) {
        return null;
      }

      const ordersAovOvertimeData = getConversionOrdersAovMetrics({
        currency,
        ordersAovOvertime,
        rates,
      });
      return getConversionRateMetrics({
        ordersAovOvertimeData,
        timeOnPageOvertimeData,
        uniqueUsersMetrics,
      });
    },
  fetchProductClicksOvertime:
    () =>
    async ({ dispatch, setState, getState }) => {
      const { selectedTab } = getState();
      if (!FETCH_REQUESTS_TABS.fetchProductClicksOvertime.includes(selectedTab)) {
        return;
      }

      setState({ isLoadingProductClicksOvertime: true });

      const filters = dispatch(
        actions.getAllFilters({ publishIdInResult: true, domainInResult: true })
      );
      const resultSet = await getCubejsApi().load(getProductClicksOvertimeQuery(filters));

      if (!resultSet) {
        setState({ productClicksOvertime: null, isLoadingProductClicksOvertime: false });
        return;
      }

      setState({
        productClicksOvertime: getProductClicksOvertime(resultSet),
        isLoadingProductClicksOvertime: false,
      });
    },
  fetchEmailEventsOvertime:
    ({ publishIds } = {}) =>
    async ({ dispatch, setState }) => {
      setState({ isLoadingEmailEventsOvertime: true });

      const filters = dispatch(actions.getAllFilters({ publishIdInResult: true }));
      const resultSet = await getCubejsApi().load(
        getEmailEventsOvertimeQuery({
          ...filters,
          publishId: publishIds,
        })
      );

      if (!resultSet) {
        setState({ emailEventsOvertime: null, isLoadingEmailEventsOvertime: false });
        return;
      }

      setState({
        emailEventsOvertime: getEmailEventsOvertime(resultSet),
        isLoadingEmailEventsOvertime: false,
      });
    },
  fetchEmailMetricsOvertime:
    ({ publishIds } = {}) =>
    async ({ dispatch, setState }) => {
      setState({ isLoadingEmailMetricsOvertime: true });

      const filters = dispatch(actions.getAllFilters());
      const resultSet = await getCubejsApi().load(
        getEmailMetricsOvertimeQuery({
          ...filters,
          flipPublishId: publishIds,
        })
      );

      if (!resultSet) {
        setState({ emailMetricsOvertime: null, isLoadingEmailMetricsOvertime: false });
        return;
      }

      setState({
        emailMetricsOvertime: resultSet,
        isLoadingEmailMetricsOvertime: false,
      });
    },
  fetchEmailRecipients:
    ({ publishIds } = {}) =>
    async ({ dispatch, setState }) => {
      setState({ isLoadingEmailRecipients: true });

      const filters = dispatch(actions.getAllFilters());
      const resultSet = await getCubejsApi().load(
        getEmailRecipientsQuery({
          ...filters,
          flipPublishId: publishIds,
        })
      );

      if (!resultSet) {
        setState({ emailRecipients: null, isLoadingEmailRecipients: false });
        return;
      }

      setState({
        emailRecipients: getEmailRecipients(resultSet),
        isLoadingEmailRecipients: false,
      });
    },
  getAllFilters:
    ({ publishIdInResult, domainInResult, appUrlInResult } = {}) =>
    ({ getState }, { appKey: accountAppKey, publishId: accountPublishId }) => {
      const {
        dateFilter,
        publishIds,
        interactionFilter,
        channel,
        appUrl: selectedAppUrl,
        domain: selectedDomain,
      } = getState();

      const filter = {
        appKey: getAppKey(accountAppKey),
        dateRange: getDateRange(dateFilter),
        attributiondiff: getAttributionDiff(interactionFilter),
      };

      const publishId = getPublishIds(accountPublishId, publishIds);
      const domain = getDomainFilter(selectedDomain, channel);
      const appUrl = getAppUrlFilter(selectedAppUrl);

      publishIdInResult ? (filter.publishId = publishId) : (filter.flipPublishId = publishId);
      domainInResult ? (filter.domain = domain) : (filter.flipDomain = domain);
      appUrlInResult ? (filter.appUrl = appUrl) : (filter.flipAppUrl = appUrl);

      return filter;
    },
  fetchBenchmarkData:
    () =>
    async ({ setState }, { appKey: accountAppKey }) => {
      const benchmarkData = await postRequest('user-actions', '/actions/users/benchmark-data', {
        body: {
          appKey: getAppKey(accountAppKey)[0],
        },
      });
      setState({ benchmarkData });
    },
  fetchProjectActivePages:
    ({ publishId, dateRange = DATE_FILTER_LAST_7_DAYS }) =>
    async ({ setState }) => {
      setState({
        projectActivePages: null,
        isProjectActivePagesLoading: true,
      });

      const resultSet = await getCubejsApi().load(
        getProjectActivePagesQuery({ dateRange, publishId: [publishId] })
      );
      const projectActivePages = resultSet.tablePivot().map(data => ({
        pageUrl: data[`${SITE_ACTIVITY_LOG}.${CUBE_PROPERTY_KEYS.pageUrl}`],
        numOfNonInteractingVisits:
          data[`${SITE_ACTIVITY_LOG}.${CUBE_PROPERTY_KEYS.numOfNonInteractingVisits}`],
      }));

      setState({
        projectActivePages,
        isProjectActivePagesLoading: false,
      });
    },
  getBenchmarkData:
    () =>
    ({ getState }) => {
      const { benchmarkData } = getState();
      return benchmarkData;
    },
  clearFilters:
    () =>
    ({ setState }) => {
      setState({
        dateFilter: DEFAULT_DATE_FILTER,
        publishIds: null,
      });
    },
  clearEngagements:
    () =>
    ({ setState }) => {
      setState({
        engagementEventsOvertime: null,
        engagementMetrics: null,
        engagementEmailCollected: null,
        engagementTolstoysEvent: null,
        engagementTolstoyClicks: null,
        averageTimeSpent: null,
        totalTimeSpent: null,
        feedTotalTimeAverage: null,
        engagementFeedClicks: null,
        timeOnPageOvertime: null,
        timeOnPageMetrics: null,
        overviewEngagementsOvertime: null,
        isLoadingOverviewEngagementOvertime: true,
        overviewEngagementOvertime: null,
        uniqueUsersMetrics: null,
        productClicksOvertime: null,
        isLoadingProductClicksOvertime: true,
      });
    },
  clearConversions:
    () =>
    ({ setState }) => {
      setState({
        conversionOvertime: null,
        conversionMetrics: null,
        viewersMetrics: null,
        conversionOrders: null,
        timeOnPageOvertime: null,
        conversionOrdersOvertime: null,
        ordersAovOvertime: null,
        isLoadingOrdersAovOvertime: true,
      });
    },
  clearOnsite:
    () =>
    ({ setState }) => {
      setState({
        onsiteMetrics: null,
      });
    },
  clearVideos:
    () =>
    ({ setState }) => {
      setState({
        videoMetrics: null,
        videoMetricsSummary: null,
      });
    },
  clearBenchmarkData:
    () =>
    ({ setState }) => {
      setState({
        benchmarkData: null,
      });
    },
  clearEmail:
    () =>
    ({ setState }) => {
      setState({
        emailEventsOvertime: null,
        emailMetricsOvertime: null,
        emailRecipients: null,
      });
    },
};

const monthBack = addMonths(new Date(), -1);
const yesterday = addDays(new Date(), -1);

export const FILTER_DATE_VALUES = {
  [DATE_FILTER_TODAY]: { startDate: new Date(), endDate: new Date() },
  [DATE_FILTER_YESTERDAY]: { startDate: yesterday, endDate: yesterday },
  [DATE_FILTER_LAST_7_DAYS]: { startDate: addDays(new Date(), -7), endDate: yesterday },
  [DATE_FILTER_LAST_30_DAYS]: { startDate: addDays(new Date(), -30), endDate: yesterday },
  [DATE_FILTER_LAST_60_DAYS]: { startDate: addDays(new Date(), -60), endDate: yesterday },
  [DATE_FILTER_LAST_90_DAYS]: { startDate: addDays(new Date(), -90), endDate: yesterday },
  [DATE_FILTER_THIS_MONTH]: { startDate: startOfMonth(new Date()), endDate: yesterday },
  [DATE_FILTER_LAST_MONTH]: {
    startDate: startOfMonth(monthBack),
    endDate: endOfMonth(monthBack),
  },
};

const analyticsEmailConversionsSelector = ({ emailMetricsOvertime, rates }) => {
  if (!emailMetricsOvertime || !rates) {
    return {};
  }

  return getEmailConversionsOvertime({ emailMetricsOvertime, rates });
};

const analyticsEmailTopMetrics = ({ emailRecipients }) => {
  if (!emailRecipients || emailRecipients.length < 0) {
    return {
      emailCTR: 0,
      conversionRate: 0,
    };
  }

  const totalEmailClicks = emailRecipients.reduce(
    (sum, recipient) => sum + recipient.numOfEmailClicks,
    0
  );
  const totalEmailOpens = emailRecipients.reduce(
    (sum, recipient) => sum + recipient.numOfEmailOpens,
    0
  );
  const totalOrders = emailRecipients.reduce((sum, recipient) => sum + recipient.numOfOrders, 0);

  const emailCTR =
    totalEmailOpens === 0 ? 0 : ((totalEmailClicks / totalEmailOpens) * 100).toFixed(2);
  const conversionRate =
    totalEmailClicks === 0 ? 0 : ((totalOrders / totalEmailClicks) * 100).toFixed(2);

  return {
    emailCTR,
    conversionRate,
  };
};

const analyticsChannel = ({ channel }) => channel;

const AnalyticsStore = createStore({ initialState, actions, name: 'Analytics' });

export const useAnalytics = createHook(AnalyticsStore);

export const useAnalyticsEmailConversions = createHook(AnalyticsStore, {
  selector: analyticsEmailConversionsSelector,
});

export const useEmailTopMetrics = createHook(AnalyticsStore, {
  selector: analyticsEmailTopMetrics,
});

export const useAnalyticsChannel = createHook(AnalyticsStore, { selector: analyticsChannel });

export const useAnalyticsActions = createActionsHook(AnalyticsStore);

export const AnalyticsContainer = createContainer(AnalyticsStore, {
  onInit:
    () =>
    ({ setState }, { initialPublishIds, initialSelectedTab }) => {
      if (initialPublishIds || initialSelectedTab) {
        setState({
          publishIds: initialPublishIds || initialState.publishIds,
          initialSelectedTab: initialSelectedTab || initialState.selectedTab,
        });
      }
    },
  onUpdate:
    () =>
    ({ dispatch }) => {
      dispatch(actions.fetchConversions());
    },
  onCleanup:
    () =>
    ({ dispatch }) => {
      dispatch(actions.clearFilters());
    },
});
