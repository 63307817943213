import Utils from 'app/src/utils';
import { convertRevenueToUsd } from 'app/src/services/cube/cube.utils';
import { CURRENCY_CODES } from 'shared/react/constants/currency.constants';
import { CUBE_PROPERTY_KEYS } from 'app/src/constants/cube.constants';

const accumulateConversionByCurrency = ({ conversions, currency, rates }) => {
  let indirectRevenueInUsd = 0;
  let inVideoRevenueInUsd = 0;
  let postVideoRevenueInUsd = 0;

  for (const [conversionKey, revenue] of Object.entries(conversions)) {
    const [conversionCurrency] = conversionKey.split(',');
    const [, revenueType] = conversionKey.split('.');
    const revenueInUsd = convertRevenueToUsd({ revenue, rate: rates[conversionCurrency] });

    switch (revenueType) {
      case CUBE_PROPERTY_KEYS.inVideoRevenue:
        inVideoRevenueInUsd += revenueInUsd;
        break;
      case CUBE_PROPERTY_KEYS.postVideoRevenue:
        postVideoRevenueInUsd += revenueInUsd;
        break;
      case CUBE_PROPERTY_KEYS.indirectRevenue:
        indirectRevenueInUsd += revenueInUsd;
        break;
      default:
        console.error(`Unknown revenue type: ${revenueType}`);
    }
  }

  const directRevenueInUsd = inVideoRevenueInUsd + postVideoRevenueInUsd;
  if (currency === CURRENCY_CODES.usd) {
    return {
      directRevenue: directRevenueInUsd,
      indirectRevenue: indirectRevenueInUsd,
      inVideoRevenue: inVideoRevenueInUsd,
      postVideoRevenue: postVideoRevenueInUsd,
    };
  }

  return {
    directRevenue: directRevenueInUsd * rates[currency],
    indirectRevenue: indirectRevenueInUsd * rates[currency],
    inVideoRevenue: inVideoRevenueInUsd * rates[currency],
    postVideoRevenue: postVideoRevenueInUsd * rates[currency],
  };
};

export const getConversionOvertimeMetrics = ({ conversionOvertime, rates, currency }) => {
  return conversionOvertime.map(({ x, xValues, ...conversions }) => {
    const { directRevenue, indirectRevenue, inVideoRevenue, postVideoRevenue } =
      accumulateConversionByCurrency({
        conversions,
        currency,
        rates,
        x,
      });

    return {
      x,
      xValues,
      directRevenue,
      directRevenueWithCurrency: Utils.addCurrencySymbolToNumber(directRevenue, currency, 1),
      indirectRevenue,
      indirectRevenueWithCurrency: Utils.addCurrencySymbolToNumber(indirectRevenue, currency, 1),
      inVideoRevenue,
      inVideoRevenueWithCurrency: Utils.addCurrencySymbolToNumber(inVideoRevenue, currency, 1),
      postVideoRevenue,
      postVideoRevenueWithCurrency: Utils.addCurrencySymbolToNumber(postVideoRevenue, currency, 1),
    };
  });
};
