import React, { useState } from 'react';
import styled from 'styled-components';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import ShareToLibraryMenuItems from 'app/src/pages/dashboard/pages/libraries/tolstoys_tab/project_card_v2/share_to_library_menu/ShareToLibraryMenuItems';
import { useUser } from 'app/src/context/userStore/UserStore';
import ShouldRenderWrapper from 'app/src/basic_components/ShouldRenderWrapper';
import { track } from 'app/src/helpers/Tracker';
import MenuWithContainer from 'shared/react/components/basic/MenuWithContainer';
import { useAccount } from 'app/src/context/AccountStore';
import ShareToLibraryMenuTrigger from 'app/src/pages/dashboard/pages/libraries/tolstoys_tab/project_card_v2/share_to_library_menu/ShareToLibraryMenuTrigger';

const ShareToLibraryMenu = ({ project, selectedCardsLength }) => {
  const [{ user }] = useUser();
  const [{ isAccountOwner }] = useAccount();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const { private: isPrivate } = project;

  const handleCloseShareToMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenShareToMenu = e => {
    e.stopPropagation();
    track('Project Card Share To Library Menu Click');
    setAnchorEl(e.currentTarget);
  };

  const hasPrivatePermissions =
    (project.owner === user.owner || (isAccountOwner && !isPrivate)) && !selectedCardsLength;

  const getMenuOnClick = () => {
    if (!hasPrivatePermissions) {
      return null;
    }

    return handleOpenShareToMenu;
  };

  const isLoading = loading ? 'true' : '';
  return (
    <ShareToMenu loading={isLoading} hasPrivatePermissions={hasPrivatePermissions}>
      <MenuTextContainer
        onClick={getMenuOnClick()}
        hasPrivatePermissions={hasPrivatePermissions}
        loading={isLoading}
      >
        <ShareToLibraryMenuTrigger
          project={project}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          isLoading={isLoading}
          hasPrivatePermissions={hasPrivatePermissions}
        />
      </MenuTextContainer>
      <ShouldRenderWrapper shouldRender={hasPrivatePermissions}>
        <StyledMenu open={!!anchorEl} anchorEl={anchorEl} onClose={handleCloseShareToMenu}>
          <ShareToLibraryMenuItems
            project={project}
            onClose={handleCloseShareToMenu}
            loading={loading}
            setLoading={setLoading}
          />
        </StyledMenu>
      </ShouldRenderWrapper>
    </ShareToMenu>
  );
};

const StyledMenu = styled(MenuWithContainer)`
  border-radius: 12px;
`;

const ShareToMenu = styled.div`
  display: flex;
  align-items: flex-start;
  transition: 0.2s;
  cursor: ${({ loading }) => (loading ? 'default' : 'pointer')};

  &:hover {
    opacity: ${({ hasPrivatePermissions }) => (hasPrivatePermissions ? 0.7 : '')};
  }
`;

const MenuTextContainer = styled(HorizontalFlexWrap)`
  gap: 4px;
  align-items: center;
  opacity: ${({ loading }) => (loading ? 0.7 : 1)};
  pointer-events: ${({ loading }) => (loading ? 'none' : '')};

  & path {
    fill: ${({ theme }) => theme.colors.ghostDark};
  }
`;

export default ShareToLibraryMenu;
