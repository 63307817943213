import { useState, useEffect } from 'react';
import { useAccountActions } from 'src/context/AccountStore';
import { useVodConnection } from 'app/src/context/VodConnectionStore';
import { useVodLabels } from 'app/src/context/VodLabelsStore';
import { project as Project, vodAsset as VodAsset } from 'app/src/types/entities';
import {
  getDuplicateSource,
  SOURCES_UPLOAD_TYPE,
} from 'app/src/constants/videoSources.constants.js';
import useProductFilterActions from 'app/src/pages/dashboard/components/filters/hooks/useProductFilterActions';
import { useUserActions } from 'app/src/context/userStore/UserStore';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import { useAppActions, useApps } from 'app/src/context/AppsStore';
import useDynamicVideoIdsByProject from 'app/src/hooks/use-dynamic-video-ids-by-project/useDynamicVideoIdsByProject';
import { useProjects } from 'app/src/context/ProjectsStore';
import { useRules } from 'app/src/context/RulesStore';
import { getPlaylists } from 'src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/components/playlists-picker/utils';

const doesRuleUsePlaylist = (playlistId, rule) => {
  const [type, value] = playlistId.split('_');

  return rule.type === type && rule.value === value;
};

// define all actions here. Actions do nothing by default.
const useActions = setFilters => {
  const { getTeamMembers } = useAccountActions();
  const [{ apps, selectedAppUrl }] = useApps();
  const { isEcomPlatformConnected } = useAppActions();
  const { isProjectLive } = useUserActions();
  const productActions = useProductFilterActions();
  const { getFeatureEnabled } = useFeatureActions();
  const [{ projects }] = useProjects();
  const [{ ruleGroups }] = useRules();
  const { getVideoIdsByProject } = useDynamicVideoIdsByProject(null, null, false);
  const [
    { tags },
    {
      getHasVodConnectionsTypesByVodAssetId,
      getHasVodLabelByVodAssetId,
      getHasTagsByVodAssetId,
      getHasProductByVodAssetId,
    },
  ] = useVodConnection();
  const [{ vodLabels }, { getVodLabelById }] = useVodLabels();
  const [playlists, setPlaylists] = useState([]);

  const liveProjects = projects.filter(({ live }) => live);
  const liveVideoIds = liveProjects.reduce((acc, project) => {
    const projectRuleGroup = ruleGroups.map(({ projectId }) => projectId === project.id);
    const vodAssetIds = getVideoIdsByProject(project, projectRuleGroup);
    return [...acc, ...vodAssetIds];
  }, []);

  const filterVideoBySource = (video: VodAsset, args) => {
    const values = args.flatMap(value => {
      const sources = SOURCES_UPLOAD_TYPE[value];
      const duplicateSources = sources.map(getDuplicateSource);

      return [...sources, ...duplicateSources];
    });

    return values.includes(video.uploadType);
  };

  const isVideoLive = video => {
    return liveVideoIds.includes(video.id);
  };

  const isVideoOnTime = (vodAsset, args) => {
    const createdAt = new Date(vodAsset.createdAt);
    const [startDate, endDate] = args;
    return createdAt >= startDate && createdAt <= endDate;
  };

  const checkProjectPublishMethod = (project: Project, args) => {
    return args.includes(project.publishMethod);
  };

  const isPlaylistUsedInProject = (project: Project, playlistIds: string[] = []) => {
    const ruleGroup = ruleGroups.find(ruleGroup => ruleGroup.projectId === project.id);

    if (!ruleGroup?.rules?.length) {
      return false;
    }

    const flatRules = ruleGroup.rules.flat();

    return flatRules.some(rule =>
      playlistIds.some(playlistId => doesRuleUsePlaylist(playlistId, rule))
    );
  };

  useEffect(() => {
    setFilters({ products: undefined });
    productActions.resetSearch();
  }, [selectedAppUrl]);

  useEffect(() => {
    const { playlists } = getPlaylists({ vodLabels });

    setPlaylists(playlists);
  }, [vodLabels]);

  const actions = {
    apps,
    vodLabels,
    playlists,
    getTeamMembers,
    setFilters,
    getHasVodConnectionsTypesByVodAssetId,
    getHasVodLabelByVodAssetId,
    getVodLabelById,
    getHasTagsByVodAssetId,
    tags,
    filterVideoBySource,
    getHasProductByVodAssetId,
    checkProjectPublishMethod,
    isProjectLive,
    isVideoLive,
    getFeatureEnabled,
    hasProducts: isEcomPlatformConnected(),
    isVideoOnTime,
    isPlaylistUsedInProject,
    ...productActions,
  };

  return actions;
};

export default useActions;
