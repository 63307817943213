import React, { useEffect, useState } from 'react';
import AutoComplete, { createFilterOptions } from 'app/src/basic_components/AutoComplete';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import PlusIcon from 'app/src/images/PlusIcon';
import styled, { useTheme } from 'styled-components';
import Utils from 'app/src/utils';
import { SHOP_APP_TARGET } from 'app/src/constants/project.constants';
import { Label } from 'app/src/styled_components';
import { project as Project } from 'app/src/types/entities';
import { Tooltip } from '@material-ui/core';
import { useAnalytics } from 'app/src/context/AnalyticsStore';
import { CAMPAIGN_TILE_ACTIONS } from 'app/src/constants/campaignTileActions.constants';

type Props = {
  onChange: (value: string) => void;
  value: string;
  project: Project;
  disabled: boolean;
};

type TileActionOption = {
  label: string;
  value: string;
  isPredefined: boolean;
  isVirtual?: boolean;
  isVisible: boolean;
};

const TOOLTIP_CHARS_THRESHOLD = 31;

const searchOptions = createFilterOptions<TileActionOption>();

const usePredefinedTileActions = (project: Project): TileActionOption[] => {
  return [
    {
      value: CAMPAIGN_TILE_ACTIONS.openTolstoyTvPage,
      label: 'New TV page',
      isPredefined: true,
      isVisible: true,
    },
    {
      value: CAMPAIGN_TILE_ACTIONS.openProductPage,
      label: 'Product page',
      isPredefined: true,
      isVisible: project?.targetPage === SHOP_APP_TARGET.productPage,
    },
    {
      value: CAMPAIGN_TILE_ACTIONS.openHomePage,
      label: 'Home page',
      isPredefined: true,
      isVisible: project?.targetPage !== SHOP_APP_TARGET.productPage,
    },
  ];
};

const useRecommendedTileActions = (project: Project): TileActionOption[] => {
  const [{ projectActivePages, isProjectActivePagesLoading }, { fetchProjectActivePages }] =
    useAnalytics();

  useEffect(() => {
    if (!project?.publishId) {
      return;
    }

    fetchProjectActivePages({
      publishId: project?.publishId,
    });
  }, [project?.publishId]);

  if (project?.targetPage === SHOP_APP_TARGET.productPage) {
    return [];
  }

  if (isProjectActivePagesLoading || !projectActivePages) {
    return [];
  }

  return projectActivePages.map(({ pageUrl }) => ({
    value: pageUrl,
    label: pageUrl,
    isPredefined: false,
    isVisible: true,
  }));
};

const CampaignTileActionAutocomplete = ({ disabled, onChange, value, project }: Props) => {
  const predefinedTileActions = usePredefinedTileActions(project);
  const recommendedTileActions = useRecommendedTileActions(project);
  const options = [...predefinedTileActions, ...recommendedTileActions];
  const theme = useTheme();
  const [isActive, setIsActive] = useState(false);
  const [error, setError] = useState('');
  const isPDP = project?.targetPage === SHOP_APP_TARGET.productPage;

  useEffect(() => {
    setError('');
  }, [value]);

  const getOptionSelected = (option: TileActionOption, value: string) => {
    return option.value === value;
  };

  const getOptionLabel = (input: string | TileActionOption) => {
    if (typeof input === 'string') {
      const option = options.find(({ value }) => value === input);
      return option?.label || input;
    }

    return input?.label;
  };

  const filterOptions = (options: TileActionOption[], params) => {
    const visibleOptions = options.filter(option => option.isVisible);
    const filtered = searchOptions(visibleOptions, params);

    if (!isPDP && params.inputValue !== '') {
      filtered.unshift({
        value: params.inputValue,
        label: params.inputValue,
        isPredefined: true,
        isVisible: true,
        isVirtual: true,
      });
    }

    return filtered;
  };

  const groupByOptions = (option: TileActionOption) => {
    return option.isPredefined ? '' : 'Videos recently viewed on';
  };

  const handleOnChange = (_, input: string | TileActionOption) => {
    const newValue = typeof input === 'string' ? input : input?.value;
    if (!newValue) {
      setError('This field cannot be empty');
      return;
    }

    const isPredefinedAction = predefinedTileActions.some(({ value }) => value === newValue);
    if (!isPredefinedAction && !Utils.isValidUrl(newValue)) {
      setError('Please enter a valid URL');
      return;
    }

    setError('');
    onChange(newValue);
  };

  const renderOption = (option: TileActionOption) => {
    const label = getOptionLabel(option);
    const color =
      option.isVirtual || option.value === CAMPAIGN_TILE_ACTIONS.openTolstoyTvPage
        ? theme.colors.primary
        : undefined;

    return (
      <Tooltip arrow title={label.length > TOOLTIP_CHARS_THRESHOLD ? label : ''} placement="right">
        <OptionContent $color={color} data-value={option.value}>
          {renderOptionIcon(option)}
          <div>{option?.isVirtual ? `Use "${label}"` : label}</div>
        </OptionContent>
      </Tooltip>
    );
  };

  const renderOptionIcon = (option: TileActionOption) => {
    if (option.value === CAMPAIGN_TILE_ACTIONS.openTolstoyTvPage) {
      return <PlusIcon fill={theme.colors.primary} size={16} />;
    }

    return null;
  };

  const valueLabel = getOptionLabel(value);
  const shouldShowRootTooltip = !isActive && valueLabel?.length > TOOLTIP_CHARS_THRESHOLD;

  return (
    <Tooltip
      arrow
      enterDelay={300}
      placement="right"
      title={shouldShowRootTooltip ? valueLabel : ''}
    >
      <LayoutRoot $disabled={disabled}>
        <ErrorLabel>{error}</ErrorLabel>
        <StyledAutoComplete
          clearOnBlur
          disableClearable
          disabled={disabled}
          filterOptions={filterOptions}
          freeSolo={!isPDP}
          fullWidth
          getOptionLabel={getOptionLabel}
          getOptionSelected={getOptionSelected}
          groupBy={groupByOptions}
          ListboxComponent={AutocompleteListBox}
          onBlur={() => setIsActive(false)}
          onChange={handleOnChange}
          onFocus={() => setIsActive(true)}
          options={options}
          PaperComponent={AutocompletePaper}
          renderOption={renderOption}
          selectOnFocus
          value={value}
        />
      </LayoutRoot>
    </Tooltip>
  );
};

const LayoutRoot = styled.div<{ $disabled: boolean }>`
  width: 100%;

  &,
  input {
    cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : undefined)};
  }
`;

const StyledAutoComplete = styled(AutoComplete)`
  .MuiFormControl-root {
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.neutralLighter};
  }

  .MuiInputBase-root {
    border-radius: 0;
    border: none;
    padding-bottom: 0;
    padding-left: 8px;
    padding-top: 0;

    input {
      border-radius: 0;
      border: none;
      font-size: 14px;
      padding: 7px 0;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    display: none;
  }
`;

const AutocompletePaper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutralLighter};
  margin-top: 4px;
  overflow-x: hidden;
  padding: 4px;
`;

const AutocompleteListBox = styled.div`
  padding: 2px 0;
  max-height: 300px;
  overflow-x: hidden;

  .MuiAutocomplete-groupLabel {
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutralLightest};
    color: ${({ theme }) => theme.colors.neutralDark};
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    padding: 8px 8px 8px 6px;

    &:empty {
      display: none;
    }
  }

  .MuiAutocomplete-groupUl {
    margin-top: 0;
    margin-bottom: 0;
  }

  [role='option'] {
    border-radius: 4px;
    border: 1px solid transparent;
    box-sizing: border-box;
    cursor: pointer;
    height: 34px;
    line-height: 14px;
    padding: 0 16px 0 12px;
    transition: 0.3s;

    &:hover {
      background-color: ${({ theme }) => theme.colors.neutralLightest};
    }
  }
`;

const OptionContent = styled(HorizontalFlex)<{ $color?: string }>`
  align-items: center;
  color: ${({ $color, theme }) => $color || theme.colors.neutralBlack};
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 500;
  gap: 4px;
  width: 100%;

  & > *:last-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const ErrorLabel = styled(Label)`
  color: ${({ theme }) => theme.colors.error};
  font-weight: 500;
  text-align: right;
  width: 100%;

  &:empty {
    visibility: hidden;
  }
`;

export default CampaignTileActionAutocomplete;
