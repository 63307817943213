import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import FeedProductContent from 'shared/react/components/complex/feed/feed_products/FeedProductContent';
import FeedProductOptions from 'shared/react/components/complex/feed/feed_products/FeedProductOptions';
import { getVariantSelectedValues } from 'shared/react/components/complex/feed/feed_utils/feed.utils';
import Utils from 'shared/react/utils/utils';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import useTracker from 'shared/react/components/complex/context/hooks/useTracker';
import { PLAYER_TYPES } from 'shared/react/constants/tolstoy.constants';
import {
  FEED_PRODUCT_DATA_TYPE,
  FEED_PRODUCT_OPTIONS_BUY_NOW_DATA_TYPE,
  PRODUCT_OPTION_HEADER_DATA_TYPE,
  SELECT_INPUT_CONTAINER_DATA_TYPE,
} from 'shared/react/constants/feedPreviewDataTypes.constants';
import { PRODUCT_SELECTED_MESSAGE } from 'shared/react/constants/messages.constants';
import { getNewVariant, getProductOptionsFromVariant } from 'shared/react/utils/feedProducts.utils';
import useFeedCart from 'shared/react/components/complex/context/hooks/useFeedCart';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';
import FeedProductDescription from 'shared/react/components/complex/feed/feed_products/FeedProductDescription';
import FeedProductImagesCarousel from './FeedProductImagesCarousel';
import FeedProductShipping from './FeedProductShipping';
import FeedProductSalesBadge from './FeedProductSalesBadge';
import FeedProductRecommendations from './FeedProductRecommendations';
import FeedProductRecharge from 'shared/react/components/complex/feed/feed_products/feed_product_recharge/FeedProductRecharge';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';
import FeedProductButtons from './FeedProductButtons';
import FeedAfterpayIntegration from './feed_afterpay_integration/FeedAfterpayIntegration';
import { getProductsWithVariantsFromStep } from 'shared/react/utils/feedProducts.utils';
import FeedBackButton from 'shared/react/components/basic/FeedBackButton';
import { useFeedStateContext } from 'shared/react/components/complex/context/FeedStateContext';
import { getDesignCss } from 'shared/react/utils/addSharedCss';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';

const FeedProduct = ({
  product,
  variantId,
  currentStepKey,
  disableZoomIn,
  isFullWidth,
  isHorizontal,
  hideVariantSubtitle,
  handleBackButton,
  ...props
}) => {
  const [, { track }] = useTracker();
  const [{ numberOfCartItems }] = useFeedCart();
  const [{ playerSettings }, { getCurrentStep }] = useProjectConfig();
  const { currentPageDbProductId } = useFeedStateContext();
  const [productOptions, setProductOptions] = useState({});
  const [selectedVariant, setSelectedVariant] = useState({});
  const { dbProductId, descriptionHtml, variants } = product;
  const { productDetailsRecommendedProductsEnabled } = playerSettings || {};
  const [{ direction }] = useTranslation();
  const { id: selectedVariantId } = selectedVariant || {};
  const { feedDesign } = useDesignSettingsContext();

  const currentStep = getCurrentStep(currentStepKey);
  const productsWithVariants = getProductsWithVariantsFromStep(currentStep, {
    priorityProductId: currentPageDbProductId,
  });
  variantId = variantId || productsWithVariants.find(p => p?.id === dbProductId)?.variantId;

  const handleProductOptionSelected = (optionPosition, value) => {
    const newOption = productOptions[optionPosition];
    newOption.selectedValue = value;
    track('productOptionSelected', Utils.getAnalyticsLocationObject(PLAYER_TYPES.feed));
    setProductOptions({ ...productOptions, [optionPosition]: newOption });
  };

  useEffect(() => {
    const variant = variants.find(v => `${v.id}` === `${variantId}`);
    setSelectedVariant(variant || variants[0]);
    setProductOptions(getProductOptionsFromVariant(product, variant));

    if (productDetailsRecommendedProductsEnabled) {
      Utils.postMessage({ eventName: PRODUCT_SELECTED_MESSAGE, productId: product.id });
    }
  }, [product, variantId]);

  const variantsSelectedValues = getVariantSelectedValues(productOptions);
  useEffect(() => {
    const newVariant = getNewVariant(product, productOptions);
    setSelectedVariant(newVariant);
  }, [variantsSelectedValues]);

  return (
    <LayoutRoot
      data-type={FEED_PRODUCT_DATA_TYPE}
      isCartOpen={!!numberOfCartItems}
      isFullWidth={isFullWidth || isHorizontal}
      handleBackButton={handleBackButton}
      {...props}
    >
      {!!handleBackButton && <FeedBackButton onClick={handleBackButton} />}
      <FeedProductImagesCarousel
        product={product}
        selectedVariant={selectedVariant}
        disableZoomIn={disableZoomIn}
      />
      <StyledVerticalFlex direction={direction} isFullWidth={isFullWidth || isHorizontal}>
        <FeedProductSalesBadge product={product} selectedVariant={selectedVariant} />
        <FeedProductContent
          vodAssetId={currentStep?.videoId}
          product={product}
          variantId={variantId}
          selectedVariant={selectedVariant}
          hideVariantSubtitle={hideVariantSubtitle}
        />
        <OptionsBuyNowContainer
          $design={feedDesign}
          data-type={FEED_PRODUCT_OPTIONS_BUY_NOW_DATA_TYPE}
        >
          <FeedProductOptions
            productOptions={productOptions}
            handleProductOptionSelected={handleProductOptionSelected}
            product={product}
            selectedVariant={selectedVariant}
            variants={variants}
          />
          <FeedProductRecharge
            productId={product.id}
            selectedVariantId={selectedVariantId}
            currencySymbol={product.currencySymbol}
          />
          <FeedProductButtons
            product={product}
            currentStepKey={currentStepKey}
            selectedVariant={selectedVariant}
          />
        </OptionsBuyNowContainer>
        <FeedAfterpayIntegration product={product} selectedVariant={selectedVariant} />
        <FeedProductShipping />
        <FeedProductDescription descriptionHtml={descriptionHtml} />
      </StyledVerticalFlex>
      <FeedProductRecommendations product={product} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 0;
  overflow-wrap: break-word;
  gap: ${({ isFullWidth }) => (isFullWidth ? '40px' : '24px')};
  width: 100%;
  height: calc(100% - 50px);
  flex-shrink: 0;
  box-sizing: border-box;
  user-select: none;
  padding-bottom: ${({ isCartOpen }) => (isCartOpen ? '170px' : '50px')};
  padding-top: ${({ handleBackButton }) => (handleBackButton ? '0' : '20px')};
  direction: ltr;
`;

const OptionsBuyNowContainer = styled(Gap16VerticalFlex)`
  gap: 32px;

  & ${Utils.getDataType(PRODUCT_OPTION_HEADER_DATA_TYPE)} {
    ${({ $design }) => getDesignCss($design?.inFeedPDP?.variantTitle)};
  }

  & ${Utils.getDataType(SELECT_INPUT_CONTAINER_DATA_TYPE)} {
    ${({ $design }) => getDesignCss($design?.inFeedPDP?.variantDropdown)};
  }
`;

const StyledVerticalFlex = styled(VerticalFlex)`
  gap: 14px;
  padding: 0 16px;
  direction: ${({ direction }) => direction};
`;

export default FeedProduct;
