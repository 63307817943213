import React from 'react';
import OnsiteCard from 'app/src/pages/dashboard/pages/onsites/components/onsite_card/OnsiteCard';
import styled from 'styled-components';
import OverlaySpinner from 'app/src/basic_components/OverlaySpinner';
import { useDashboard } from 'app/src/context/ui_store/DashboardStore';

const onSiteDuplicateCard = () => {
  const [{ duplicationMethod }] = useDashboard();

  if (!duplicationMethod) {
    return null;
  }

  const project = {
    id: 'loading-state',
    updatedAt: new Date(),
    name: 'Creating new Tolstoy...',
    publishMethod: duplicationMethod,
  };

  return (
    <ComponentWithOverlayLoader size={30} isLoading={true}>
      <OnsiteCard
        project={project}
        toggleSelected={() => {}}
        isSelected={false}
        isSelectionMode={false}
      />
    </ComponentWithOverlayLoader>
  );
};

const ComponentWithOverlayLoader = styled(OverlaySpinner)`
  pointer-events: none;
`;

export default onSiteDuplicateCard;
