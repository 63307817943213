import React from 'react';

const preloadedImages: string[] = [];

const usePreloadImages = () => {
  const preload = (urls: string[]) => {
    for (const url of urls) {
      if (preloadedImages.includes(url)) {
        continue;
      }

      const image = new Image();
      image.src = url;
      preloadedImages.push(url);
    }
  };

  return preload;
};

export default usePreloadImages;
