import React from 'react';
import styled from 'styled-components';
import EditorMainContainer from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorMainContainer';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import MuteEditor from './MuteEditor';
import ShoppingCardEditor from './ShoppingCardEditor';
import BrandingExpander from 'app/src/pages/account/branding/editors/BrandingExpander';
import FeedButtonsEditor from 'app/src/pages/account/branding/editors/player/controls/FeedButtonsEditor';

type Props = {
  settings?: any;
  features?: any;
  onChange?: any;
};

const ControlsEditor = ({ settings = {}, features = {}, onChange = () => {} }: Props) => {
  const updateSettings = (update, options?) => onChange({ ...settings, ...update }, options);

  return (
    <LayoutRoot>
      <BrandingExpander title="Controls">
        <EditorMainContainer>
          <MuteEditor settings={settings} features={features} onChange={updateSettings} />
          <ShoppingCardEditor settings={settings} onChange={updateSettings} />
          <FeedButtonsEditor settings={settings} onChange={updateSettings} />
        </EditorMainContainer>
      </BrandingExpander>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

export default ControlsEditor;
