import React from 'react';

const DropboxIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m4 1.333-4 2.4 4 2.4-4 2.4 4 2.4 4-2.4 4 2.4 4-2.4-4-2.4 4-2.4-4-2.4-4 2.4-4-2.4Zm4 2.4 4 2.4-4 2.4-4-2.4 4-2.4Z"
        fill="#B3B6BC"
      />
      <path d="m4 12.533 4-2.4 4 2.4-4 2.4-4-2.4Z" fill="#B3B6BC" />
    </svg>
  );
};

export default DropboxIcon;
