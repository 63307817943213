import React from 'react';

const ProductIcon = ({ fill = '#50545E', width = 18, height = 21 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 6.75H13.6875V5.1875C13.6875 2.60938 11.5781 0.5 9 0.5C6.38281 0.5 4.3125 2.60938 4.3125 5.1875V6.75H1.5C0.796875 6.75 0.25 7.33594 0.25 8V17.375C0.25 19.1328 1.61719 20.5 3.375 20.5H14.625C16.3438 20.5 17.75 19.1328 17.75 17.375V8C17.75 7.33594 17.1641 6.75 16.5 6.75ZM6.1875 5.1875C6.1875 3.66406 7.4375 2.375 9 2.375C10.5234 2.375 11.8125 3.66406 11.8125 5.1875V6.75H6.1875V5.1875ZM15.875 17.375C15.875 18.0781 15.2891 18.625 14.625 18.625H3.375C2.67188 18.625 2.125 18.0781 2.125 17.375V8.625H4.3125V10.8125C4.3125 11.3594 4.70312 11.75 5.25 11.75C5.75781 11.75 6.1875 11.3594 6.1875 10.8125V8.625H11.8125V10.8125C11.8125 11.3594 12.2031 11.75 12.75 11.75C13.2578 11.75 13.6875 11.3594 13.6875 10.8125V8.625H15.875V17.375Z"
        fill={fill}
      />
    </svg>
  );
};

export default ProductIcon;
