import React, { useState } from 'react';
import styled from 'styled-components';
import {
  CUSTOM_PLAYLIST_RENAME_MODAL_SAVE_DATA_TEST_ID,
  TYPE_CUSTOM_PLAYLIST_NAME_DATA_TEST_ID,
} from 'app/src/constants/dataTestIds.constants';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import FormInput from 'app/src/complex_components/FormInput';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { MODAL_STATE } from 'app/src/pages/modals/folder_modal/folders.constants';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import { WhiteButton, PrimaryButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { TextH3Bold } from 'shared/react/components/basic/text/TextV2';
import StandardModalV2 from 'app/src/pages/modals/StandardModalV2';
import { useVodLabelsActions } from 'src/context/VodLabelsStore';

const HEADERS = {
  [MODAL_STATE.new]: 'Create new playlist',
  [MODAL_STATE.rename]: 'Rename playlist',
};

type ModalProps = {
  onSaveExternal: () => void;
};

const AddEditPlaylistModal = () => {
  const [{ modalProps }] = useModal();
  const { onSaveExternal } = modalProps as ModalProps;
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [
    {
      modalType,
      modalProps: { playlist },
    },
    { clearModalState },
  ] = useModal();
  const { createVodLabel, updateVodLabel } = useVodLabelsActions();

  const header = HEADERS[modalType];

  const onChange = e => setValue(e.target.value);

  const onSave = async () => {
    if (!value?.trim()) {
      return;
    }

    setLoading(true);

    if (modalType === MODAL_STATE.new) {
      await createVodLabel({ name: value });
    } else if (modalType === MODAL_STATE.rename && playlist) {
      const { id, appKey } = playlist;

      await updateVodLabel({ id, appKey, name: value });
    }

    setLoading(false);
    clearModalState();
    if (onSaveExternal) {
      onSaveExternal();
    }
  };

  return (
    <StandardModalV2 onClose={clearModalState} open>
      <ModalContainer>
        <HorizontalFlex>
          <TextH3Bold>{header}</TextH3Bold>
        </HorizontalFlex>
        <CustomInput
          data-test-id={TYPE_CUSTOM_PLAYLIST_NAME_DATA_TEST_ID}
          value={value}
          placeholder="Playlist name"
          onChange={onChange}
          autoFocus
        />
        <ButtonsContainer>
          <WhiteButton disabled={loading} onClick={clearModalState}>
            Cancel
          </WhiteButton>
          <PrimaryButton
            data-test-id={CUSTOM_PLAYLIST_RENAME_MODAL_SAVE_DATA_TEST_ID}
            isLoading={loading}
            onClick={onSave}
          >
            Save
          </PrimaryButton>
        </ButtonsContainer>
      </ModalContainer>
    </StandardModalV2>
  );
};

const ModalContainer = styled(Gap16VerticalFlex)`
  padding: 24px 24px 16px;
  min-width: 500px;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    min-width: 200px;
  }
`;

const ButtonsContainer = styled(Gap8HorizontalFlexWrap)`
  justify-content: flex-end;
`;

const CustomInput = styled(FormInput)`
  width: 100%;
  padding: 10px 8px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  margin-top: 4px;
`;

export default AddEditPlaylistModal;
