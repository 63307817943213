import React from 'react';
import styled from 'styled-components';
import Card from 'app/src/basic_components/Card';
import PreviewMobileNotch from 'app/src/pages/modals/publish/previews/static_preview_items/PreviewMobileNotch';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import PreviewContentGroup from 'app/src/pages/modals/publish/previews/static_preview_items/PreviewContentGroup';
import { PreviewBox } from 'app/src/pages/modals/publish/previews/static_preview_items/commonPreviewItems';

const WidgetStaticPreview = () => {
  return (
    <LayoutRoot disabled>
      <TopContainer>
        <PreviewMobileNotch />
        <PreviewBox />
      </TopContainer>
      <PreviewContentGroup />
      <PreviewContentGroup />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Card)`
  display: flex;
  flex-direction: column;
  grid-row: 1;
  grid-column: 1;
  padding: 10px 12px;
  gap: 16px;
`;

const TopContainer = styled(VerticalFlex)`
  gap: 10px;
  width: 100%;
  align-items: center;
`;

export default WidgetStaticPreview;
