import React, { useState } from 'react';
import styled from 'styled-components';
import { TextH5 } from 'shared/react/components/complex/Text';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useProjects } from 'app/src/context/ProjectsStore';
import { useVideos } from 'app/src/context/VideosStore';
import { track } from 'app/src/helpers/Tracker';
import Utils from 'app/src/utils';
import TemplatePreview from 'app/src/pages/creation_flow/right_section/templates/templateConfirmation/TemplatePreview';
import TemplateRightSection from 'app/src/pages/creation_flow/right_section/templates/templateConfirmation/TemplateRightSection';
import Breadcrumbs from 'app/src/basic_components/Breadcrumbs';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import TemplateFooter from 'app/src/pages/creation_flow/right_section/templates/templateConfirmation/TemplateFooter';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { useApps } from 'app/src/context/AppsStore';
import { IS_PROD } from 'app/src/config/app.config';

const USE_TOLSTOY_TEXT = 'Next';
const LOADING_TYPES = {
  standard: 'standard',
  goToDashboard: 'goToDashboard',
  addToSite: 'addToSite',
};
const env = IS_PROD ? 'prod' : 'dev';
const isGuide = ({ actionButtonText }) => !!actionButtonText;

const TemplateConfirmation = ({ selectedTemplate, setSelectedTemplate, onCopyTemplate }) => {
  const isMobile = useMediaQuery('(max-width:769px)');
  const [, { fetchProjects, createProject, cloneProject }] = useProjects();
  const [, { fetch }] = useVideos();
  const [
    {
      modalProps: { publishMethod, appUrl },
    },
  ] = useModal();
  const [{ shopify }] = useApps();
  const readyMades = selectedTemplate?.env[env].projects;
  const [loadingState, setLoadingState] = useState('');
  const [selectedReadyMade, setSelectedReadyMade] = useState(readyMades[0]);
  const hasMultipleReadyMades = readyMades.length > 1;
  const actionButtonText = selectedTemplate.actionButtonText || USE_TOLSTOY_TEXT;
  const hasExplanation = selectedTemplate.explanation;

  const onCancel = () => {
    setSelectedTemplate(null);
  };

  function handlePreviewOnSampleSite() {
    track('Preview Tolstoy On Sample Site Click', { location: 'create' });

    Utils.openInNewTab(selectedTemplate.guideLink || selectedReadyMade.sampleSiteUrl);
  }

  const reportPreviewOnLiveSite = () => {
    track('Preview Tolstoy On Sample Site Click', {
      location: 'create',
      key: selectedTemplate.key,
      title: selectedTemplate.title,
    });
  };

  const cloneReadyMadeTolstoy = async () => {
    const { projectId, err } = await cloneProject({
      projectId: selectedReadyMade.id,
      tolstoyType: `readyMade_${selectedTemplate.key}`,
    });

    if (!projectId || err) {
      return null;
    }

    const [projects] = await Promise.all([
      fetchProjects({ withoutChangingLibrary: true }),
      fetch(),
    ]);
    return projects.find(project => project.id === projectId);
  };

  const onGuideButtonClick = async () => {
    track('Customize Guide Tolstoy Click', {
      location: 'create',
      key: selectedTemplate.key,
      name: selectedTemplate.title,
    });

    setLoadingState(true);
    try {
      const type = selectedTemplate.key === 'video_quiz' ? 'quiz_v2' : '';
      const project = await createProject({
        input: {
          name: selectedTemplate.title,
          stepsOrder: [],
          appUrl: appUrl || shopify?.appUrl,
        },
        type,
        publishMethod,
      });
      if (project) {
        onCopyTemplate(project);
      }
    } finally {
      setLoadingState(false);
    }
  };

  const handleGoNext = async () => {
    if (isGuide(selectedTemplate)) {
      await onGuideButtonClick();
      return;
    }

    setLoadingState(LOADING_TYPES.standard);
    const project = await cloneReadyMadeTolstoy();
    onCopyTemplate(project);
    setLoadingState('');
  };

  const getFooter = () => {
    return (
      <TemplateFooter
        LOADING_TYPES={LOADING_TYPES}
        hasExplanation={hasExplanation}
        selectedTemplate={selectedTemplate}
        isMobile={isMobile}
        loadingState={loadingState}
        handlePreviewOnSampleSite={handlePreviewOnSampleSite}
        handleGoNext={handleGoNext}
        actionButtonText={actionButtonText}
        reportPreviewOnLiveSite={reportPreviewOnLiveSite}
      />
    );
  };

  const breadcrumbs = [
    { text: 'Ready-mades', onClick: onCancel },
    { text: selectedTemplate.title },
  ];

  return (
    <LayoutRoot>
      <BreadCrumbsContainer breadcrumbs={breadcrumbs} />
      <MainContainer
        hasMultipleReadyMades={hasMultipleReadyMades}
        hasExplanation={hasExplanation}
        moreTolstoysContainerWidth={isMobile || !hasMultipleReadyMades ? 0 : 96}
      >
        {!hasExplanation && <Title>{selectedTemplate.title}</Title>}
        <TemplatePreview
          selectedTemplate={selectedTemplate}
          selectedReadyMade={selectedReadyMade}
          hasExplanation={hasExplanation}
          isMobile={isMobile}
          hasMultipleReadyMades={hasMultipleReadyMades}
          readyMades={readyMades}
          setSelectedReadyMade={setSelectedReadyMade}
        />
        {(!hasExplanation || isMobile) && getFooter()}
        <TemplateRightSection
          selectedTemplate={selectedTemplate}
          setSelectedReadyMade={setSelectedReadyMade}
          hasExplanation={hasExplanation}
          hasMultipleReadyMades={hasMultipleReadyMades}
          readyMades={readyMades}
          isMobile={isMobile}
          getFooter={getFooter}
          selectedReadyMade={selectedReadyMade}
        />
      </MainContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  gap: 24px;
`;

const BreadCrumbsContainer = styled(Breadcrumbs)`
  padding: 40px 0 0 24px;
`;

const MainContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr ${({ hasExplanation, moreTolstoysContainerWidth }) =>
      hasExplanation ? 'auto' : `${moreTolstoysContainerWidth}px`};

  margin-left: ${({ hasExplanation, moreTolstoysContainerWidth }) =>
    hasExplanation ? '0' : `${moreTolstoysContainerWidth}px`};
  width: ${({ hasExplanation, moreTolstoysContainerWidth }) =>
    hasExplanation ? '' : `${360 + moreTolstoysContainerWidth}px`};
  row-gap: 16px;
  column-gap: 20px;
  align-self: center;
  justify-self: center;
  justify-content: center;
  height: 100%;

  @media (max-width: 840px) {
    width: 100%;
  }

  @media (max-width: 769px) {
    height: 100%;
    width: 100%;
    grid-template-rows: ${props =>
      props.hasMultipleReadyMades ? 'auto 1fr auto' : 'auto 1fr auto'};
    grid-template-columns: auto;
    align-items: center;
  }
`;

const Title = styled(TextH5)`
  justify-self: center;
  text-align: center;
  align-self: end;
  grid-column: 1;
  @media (max-width: 769px) {
    align-self: center;
  }
`;

export default TemplateConfirmation;
