import React from 'react';
import styled from 'styled-components';
import FilledEyeIcon from 'shared/react/images/feed/FilledEyeIcon';
import { PRODUCT_IMAGE_DATA_TYPE } from '../../../../constants/feedPreviewDataTypes.constants';
import VerticalFlex from '../../flex_layouts/VerticalFlex';
import useIsFenty from 'shared/react/hooks/useIsFenty';
import useIsPrincessPolly from 'shared/react/hooks/useIsPrincessPolly';
import FittedImage from '../../FittedImage';
import { IMAGE_FIT_TYPE } from '../../../../constants/feed.constants';
import { useDesignSettingsContext } from '../../context/DesignSettingsContext';

const FeedProductImage = ({ src, onClick, imageFit }) => {
  const isFenty = useIsFenty();
  const isPP = useIsPrincessPolly();
  const { player } = useDesignSettingsContext();
  const { feedImageFitType } = player.feedCards.productDetailsCard.productImages;

  return (
    <LayoutRoot
      onClick={onClick}
      data-type={PRODUCT_IMAGE_DATA_TYPE}
      role="button"
      aria-label="view product"
      $isFenty={isFenty}
      $isPP={isPP}
    >
      <EyeIconContainer $isFenty={isFenty}>
        <FilledEyeIcon />
      </EyeIconContainer>
      <StyledFittedImage
        src={src}
        imageFit={imageFit}
        $isCoverFit={feedImageFitType === IMAGE_FIT_TYPE.COVER}
        alt="Product image"
      />
    </LayoutRoot>
  );
};

const EyeIconContainer = styled(VerticalFlex)`
  position: absolute;
  z-index: 11;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: ${({ $isFenty }) => ($isFenty ? '0' : 'pointer')};
  opacity: 0;
  transition: 0.3s;
  background: ${({ theme }) => theme.colors.black20opacity};
`;

const LayoutRoot = styled.div`
  height: 100%;
  max-height: ${({ $isFenty }) => ($isFenty ? '94px' : '160px')};
  max-width: ${({ $isFenty }) => ($isFenty ? '73px' : '100px')};
  min-width: ${({ $isFenty }) => ($isFenty ? '73px' : '100px')};
  width: 100%;
  position: relative;

  &:hover {
    & ${EyeIconContainer} {
      cursor: ${({ $isPP }) => ($isPP ? 'default' : '')};
      opacity: ${({ $isFenty, $isPP }) => ($isPP ? 0 : $isFenty ? '0' : '1')};
    }
  }
`;

const StyledFittedImage = styled(FittedImage)`
  width: 100%;
  height: 100%;
  justify-content: ${({ $isCoverFit }) => (!$isCoverFit ? 'flex-start' : '')};

  & img {
    border-radius: 0;
    height: ${({ $isCoverFit }) => ($isCoverFit ? '100%' : '')};
  }
`;

export default FeedProductImage;
