import { useProductsContext } from 'shared/react/components/complex/context/ProductsContext';
import {
  SHOPABLE_SIDE_PANEL_TYPE,
  YOTPO_REFETCH_TIMEOUT,
} from 'shared/react/constants/feed.constants';
import { fetchProductReviews } from 'shared/react/services/feed-products.service';

const useFeedProducts = productId => {
  const { products, productsExtraData } = useProductsContext();

  const actions = {
    getCurrentProduct: step => {
      if (!step) {
        return null;
      }

      const productId = step.products?.[0]?.id;
      return products?.[productId];
    },
    getIsShoppableSidePanel: step => {
      return !step?.feedSidePanelType || step?.feedSidePanelType === SHOPABLE_SIDE_PANEL_TYPE;
    },
    getProductsData: step => {
      if (!step) {
        return null;
      }
      const isMultipleProducts =
        actions.getIsShoppableSidePanel(step) &&
        (step.products?.length > 1 || step?.products?.[0]?.variantIds?.length > 1);

      if (isMultipleProducts) {
        return { isMultipleProducts: true };
      }

      const product = actions.getCurrentProduct(step);
      return { product };
    },
    getProductByVariantId: variantId => {
      return Object.values(products).find(({ variants }) =>
        variants.find(({ id }) => id === variantId)
      );
    },
    getProductNames: step => {
      const { isMultipleProducts, product } = actions.getProductsData(step);
      if (isMultipleProducts) {
        return step.products.flatMap(({ id }) => products[id]?.title || []).join(',');
      }

      return product?.title;
    },
    getProductReviews: async productId => {
      const { id: externalId, yotpo = {} } = products[productId] || {};
      const { reviews, score, updatedAt } = yotpo;

      const shouldUpdate = !updatedAt || new Date() - new Date(updatedAt) > YOTPO_REFETCH_TIMEOUT;

      if (!shouldUpdate) {
        return { reviews, score, updatedAt };
      }

      if (!externalId) {
        return;
      }

      const { data, status } = (await fetchProductReviews(externalId)) || {};

      if (status !== 200) {
        return;
      }

      return data;
    },
    getProductExtraData: productId => {
      return productsExtraData?.[productId];
    },
    getExternalProductIds: step => {
      return step.products?.map(({ id }) => products[id]?.id);
    },
  };

  const getSelectedProduct = () => {
    if (!productId) {
      return;
    }

    return products[productId] || {};
  };

  const selector = getSelectedProduct();
  return [selector || products, actions];
};

export default useFeedProducts;
