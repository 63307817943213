import React, { useState } from 'react';
import RenameModal from 'app/src/pages/modals/RenameModal';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import useIsMounted from 'shared/react/hooks/useIsMounted';
import { useVideos } from 'app/src/context/VideosStore';

const RenameVideoModal = () => {
  const [{ currentId }, { setCurrentModal, setCurrentId }] = useModal();
  const [{ video }, { updateVideo }] = useVideos(currentId);
  const [isRenaming, setRenaming] = useState(false);
  const isMounted = useIsMounted();
  const handleCloseRenameModal = () => {
    setCurrentModal('');
  };

  const handleRenameVideo = async newName => {
    const videoCopy = { ...video, name: newName };
    delete videoCopy.createdAt;
    delete videoCopy.updatedAt;
    setRenaming(true);
    await updateVideo(videoCopy);
    if (!isMounted()) {
      return;
    }
    setRenaming(false);
    handleCloseRenameModal();
    setCurrentId(null);
  };

  return (
    <RenameModal
      open={true}
      placeholder="How would you call this Video?"
      isRenaming={isRenaming}
      name={video?.name}
      onClose={handleCloseRenameModal}
      onRename={handleRenameVideo}
    />
  );
};

export default RenameVideoModal;
