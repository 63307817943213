import React from 'react';
import QRCode from 'qrcode.react';

const DEFAULT_SIZE = 256;

const QRCodeFromUrl = ({ url, size, canvasId }) => {
  return <QRCode value={url} size={size || DEFAULT_SIZE} renderAs="canvas" id={canvasId} />;
};

export default QRCodeFromUrl;
