import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import useFeedModal from 'shared/react/components/complex/context/hooks/useFeedModal';
import useFeedProducts from 'shared/react/components/complex/context/hooks/useFeedProducts';
import useTracker from 'shared/react/components/complex/context/hooks/useTracker';
import FeedCartSummary from 'shared/react/components/complex/feed/feed_cart/FeedCartSummary';
import FeedProducts from 'shared/react/components/complex/feed/feed_products/FeedProducts';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';
import { SHOW_FEED_PRODUCT_MODAL_MESSAGE } from 'shared/react/constants/messages.constants';
import ModalWrapper from 'shared/react/components/basic/ModalWrapper';
import HorizontalCss from 'shared/react/components/complex/feed/feed_products/HorizontalCss';
import { CART_MODAL_KEY } from 'shared/react/constants/modals.constants';
import { PRODUCT_MODAL_DATA_TYPE } from 'shared/react/constants/feedPreviewDataTypes.constants';
import LandingPageHorizontalCss from 'shared/react/components/complex/feed/feed_products/LandingPageHorizontalCss';
import useKeyboardNavigation from 'player/src/hooks/useKeyboardNavigation';
import useIsPrincessPolly from 'shared/react/hooks/useIsPrincessPolly';
import Utils from 'shared/react/utils/utils';

const ProductModal = () => {
  const [{ currentModal }, { pushModal, popModal }] = useFeedModal();
  const {
    onClose,
    currentStepKey,
    verticalOrientation,
    landingPage,
    customProduct,
    customContentWidth,
    product: clickedProduct,
  } = currentModal?.props || {};
  const [, { getProductsData, getProductNames }] = useFeedProducts();
  const [{ isAppPreview, playerSettings }, { getCurrentStep }] = useProjectConfig();
  const { disableMultiProductView } = playerSettings;
  const { resetRefs } = useKeyboardNavigation();
  const [, { track }] = useTracker();
  const [isOpen, setIsOpen] = useState(false);
  const isHorizontal = verticalOrientation === false;
  const currentStep = getCurrentStep(currentStepKey);
  const isPrincessPolly = useIsPrincessPolly();

  const handleModalClosed = () => {
    const productNames = getProductNames(currentStep);
    track('feedProductModalClose', { productNames });
    resetRefs();
    Utils.postMessageToWindow(
      { name: SHOW_FEED_PRODUCT_MODAL_MESSAGE, eventName: SHOW_FEED_PRODUCT_MODAL_MESSAGE },
      '*'
    ); // bug week temp fix, should be migrated to use only eventName
    onClose?.();
    setIsOpen(false);
  };

  const handleClickViewCart = () => {
    pushModal({
      key: CART_MODAL_KEY,
      props: {
        currentStepKey: currentStepKey,
      },
    });
  };

  const { product, isMultipleProducts } = getProductsData(currentStep);

  return (
    <StyledModalWrapper
      onClose={popModal}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isHorizontal={isHorizontal}
      hideBackgroundOverlay={isHorizontal || isAppPreview || landingPage}
      data-type={PRODUCT_MODAL_DATA_TYPE}
      onClick={e => e.stopPropagation()}
      landingPage={landingPage}
      customContentWidth={customContentWidth}
      $isPrincessPolly={isPrincessPolly}
    >
      <FeedProducts
        product={customProduct || product || clickedProduct}
        currentStepKey={currentStepKey}
        isMultipleProducts={!customProduct && isMultipleProducts}
        isHorizontal={isHorizontal}
        landingPage={landingPage}
        handleBackButton={handleModalClosed}
        priorityProductId={clickedProduct?.dbProductId}
        isMultiProductDisabled={disableMultiProductView}
        isFullWidth
      />
      <FeedCartSummary onClickViewCart={handleClickViewCart} />
    </StyledModalWrapper>
  );
};

const StyledModalWrapper = styled(ModalWrapper)`
  top: 0;
  width: ${({ isHorizontal }) => (isHorizontal ? '40%' : '100%')};
  height: 100%;
  overflow-y: auto;
  right: 0;

  && * {
    letter-spacing: ${({ $isPrincessPolly }) => ($isPrincessPolly ? '0.05rem' : '')};
  }

  ${({ customContentWidth }) => {
    if (customContentWidth) {
      return css`
        > * {
          display: flex;
          margin: auto;
          max-width: ${customContentWidth};
        }
      `;
    }
  }}

  ${({ isHorizontal, landingPage }) => {
    if (!isHorizontal) {
      return '';
    }

    if (landingPage) {
      return LandingPageHorizontalCss;
    }

    return HorizontalCss;
  }}
`;

export default ProductModal;
