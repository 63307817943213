import React, { useState } from 'react';
import { ComparisonOperator, WhereCondition } from 'src/pages/dashboard/utils/filterEntities';
import { FilterConfig } from 'src/pages/dashboard/components/filters/filtersConfig';
import MenuWrapper from 'src/pages/dashboard/components/filters/components/menu-layout/MenuLayout';
import FilterByKeyMenu from 'src/pages/dashboard/components/filters/components/filter-by-key-menu/FilterByKeyMenu';
import useActions from 'app/src/pages/dashboard/components/filters/hooks/useActions';
import { FilterTypeRow } from 'app/src/pages/dashboard/components/filters/components/filter-block/FilterBlock';
import styled from 'styled-components';

type Props = {
  anchorRef?: JSX.Element;
  onClose: () => void;
  menuConfig: { [key: string]: FilterConfig };
  currentFilters: WhereCondition;
  setFilters: (filters: { [key: string]: ComparisonOperator }) => void;
  actions: ReturnType<typeof useActions>;
};

const FilterTypesMenu = ({
  anchorRef,
  onClose,
  menuConfig,
  currentFilters,
  setFilters,
  actions,
}: Props) => {
  const [filterKey, setFilterKey] = useState(null);

  const onMenuClose = () => {
    setFilterKey(null);
    onClose();
  };

  const onFilterTypeClick = (key: string) => {
    setFilterKey(key);
  };

  if (filterKey) {
    return (
      <FilterByKeyMenu
        filterKey={filterKey}
        anchorRef={anchorRef}
        onClose={onMenuClose}
        currentFilter={currentFilters[filterKey]}
        setFilters={setFilters}
        actions={actions}
      />
    );
  }

  return (
    <MenuWrapper anchorRef={anchorRef} onClose={onMenuClose}>
      {Object.entries(menuConfig).map(([key, item]: [string, FilterConfig]) => {
        const Icon = item.icon;
        const onClick = () => {
          if (!item.onClick) {
            onFilterTypeClick(key);
            return;
          }

          item.onClick(actions);
          onMenuClose();
        };

        return (
          <StyledFilterTypeRow icon={<Icon />} key={key} onClick={onClick}>
            {item.name}
          </StyledFilterTypeRow>
        );
      })}
    </MenuWrapper>
  );
};

const StyledFilterTypeRow = styled(FilterTypeRow)`
  & path {
    fill: ${({ theme }) => theme.colors.neutralGray};
  }
`;

export default FilterTypesMenu;
