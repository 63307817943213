import React from 'react';

const MuteButton = () => {
  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.6875 7L17.3438 5.375C17.5312 5.1875 17.5312 4.84375 17.3438 4.65625C17.1562 4.46875 16.8125 4.46875 16.625 4.65625L15 6.3125L13.3438 4.65625C13.1562 4.46875 12.8125 4.46875 12.625 4.65625C12.4375 4.84375 12.4375 5.1875 12.625 5.375L14.2812 7L12.625 8.65625C12.4375 8.84375 12.4375 9.1875 12.625 9.375C12.8125 9.5625 13.1562 9.5625 13.3438 9.375L15 7.71875L16.625 9.375C16.8125 9.5625 17.1562 9.5625 17.3438 9.375C17.5312 9.1875 17.5312 8.84375 17.3438 8.65625L15.6875 7ZM9 0C8.75 0 8.5 0.0625 8.3125 0.25L4.09375 4H1.5C0.65625 4 0 4.6875 0 5.5V8.46875C0 9.28125 0.65625 9.9375 1.5 9.9375H4.09375L8.3125 13.6875C8.5 13.8438 8.75 13.9375 9 13.9375C9.65625 13.9375 9.96875 13.375 9.96875 12.9688V1C9.96875 0.59375 9.65625 0 9 0ZM8.96875 13L4.46875 9H1.5C1.21875 9 1 8.75 1 8.5V5.5C1 5.21875 1.21875 5 1.5 5H4.46875L8.9375 1.03125L8.96875 13Z"
        fill="white"
      />
    </svg>
  );
};

export default MuteButton;
