import React from 'react';
import { useResponses } from 'app/src/context/ResponsesStore';

const AudioResponse = ({ response, children }) => {
  const [, { getAudioResponseUrl }] = useResponses();
  return (
    <>
      {children}
      <audio controls src={getAudioResponseUrl(response)} />
    </>
  );
};

export default AudioResponse;
