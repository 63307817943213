import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { TextBody } from 'shared/react/components/complex/Text';
import { usePackages } from 'app/src/context/PackagesStore';
import { useBilling } from 'app/src/hooks/useBillings';
import Utils from '../../utils';
import { CircularProgress } from '@material-ui/core';
import { useAccount } from 'app/src/context/AccountStore';
import { NON_BREAKING_SPACE_UNICODE } from 'shared/react/constants/unicode.constants';

const PRICES = [
  { key: 'views', text: 'per views' },
  { key: 'seats', text: 'per members' },
  { key: 'addons', text: 'add-ons' },
  { key: 'total', text: 'Total' },
];

const BillingPackagePriceBoxAmount = ({ className, amount }) => {
  const loading = amount === undefined;
  const formattedAmount = loading ? '' : Utils.addCurrencySymbolToNumber(amount, 'USD');

  return (
    <StyledBillingPackagePriceBoxAmount className={className}>
      <BillingPackagePriceBoxAmountText>{formattedAmount}</BillingPackagePriceBoxAmountText>
      {loading && <StyledCircularProgress size={24} />}
    </StyledBillingPackagePriceBoxAmount>
  );
};

const BillingPackagePriceBreakdown = () => {
  const [{ isAccountOwner }] = useAccount();
  const { isProPackage } = useBilling();
  const [, { getBillingDetails }] = usePackages();
  const [details, setDetails] = useState({});

  useEffect(() => {
    if (isProPackage && isAccountOwner) {
      getDetails();
    }
  }, [isProPackage, isAccountOwner]);

  const getDetails = async () => {
    const billingDetails = await getBillingDetails();
    setDetails(billingDetails);
  };

  if (!isProPackage || !isAccountOwner) {
    return null;
  }

  return (
    <StyledBillingPackagePriceBreakdown>
      <StyledBillingPackagePriceBreakdownTitle>
        Amount spent so far
      </StyledBillingPackagePriceBreakdownTitle>
      <BillingPackagePriceBoxes>
        {PRICES.map(({ key, text }) => {
          const amount = details[key];
          return (
            <BillingPackagePriceBox key={key} type={key} amount={amount}>
              <BillingPackagePriceBoxAmount amount={amount} />
              <BillingPackagePriceBoxText>{text}</BillingPackagePriceBoxText>
            </BillingPackagePriceBox>
          );
        })}
      </BillingPackagePriceBoxes>
    </StyledBillingPackagePriceBreakdown>
  );
};

export default BillingPackagePriceBreakdown;

const StyledBillingPackagePriceBreakdown = styled(Gap8VerticalFlex)`
  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    padding: 16px 0 0 16px;
  }
`;

const StyledBillingPackagePriceBreakdownTitle = styled(TextBody)`
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.black};
`;

const BillingPackagePriceBoxes = styled(HorizontalFlexWrap)`
  gap: 40px;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    gap: 16px;
  }
`;

const StyledBillingPackagePriceBoxAmount = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: grid;
  place-items: center;
`;

const BillingPackagePriceBoxAmountText = styled(TextBody)`
  font-weight: 800;
  font-size: 56px;
  line-height: 56px;
  color: ${({ theme }) => theme.colors.gray4};

  &::after {
    content: '${NON_BREAKING_SPACE_UNICODE}';
  }
`;

const BillingPackagePriceBoxText = styled(TextBody)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray4};
`;

const BillingPackagePriceBox = styled(Gap8VerticalFlex)`
  display: ${({ amount, key }) => (amount < 0 && key === 'total' ? 'none' : '')};
  & ${BillingPackagePriceBoxAmountText} {
    color: ${({ theme, type }) => (type === 'total' ? theme.colors.black : '')};
  }

  & > ${BillingPackagePriceBoxText} {
    color: ${({ theme, type }) => (type === 'total' ? theme.colors.gray31 : '')};
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
`;
