export const Keys = {
  IDENTIFY_INFO_ID_KEY: 'identifyInfoId',
  SESSION_COUNTER_KEY: 'tolstoySessionCounter',
  LAST_SEEN_AT_KEY: 'tolstoyLastSeenAtKey',
  FIRST_SEEN_AT_KEY: 'tolstoyFirstSeenAtKey',
  TOLSTOY_VIEWERS_KEY: 'tolstoyViewers',
  TOLSTOY_VIEWED_PRODUCTS: 'tolstoyViewedProducts',
  CURRENT_CUSTOMER_EMAIL_KEY: 'tolstoyCurrentCustomer',
  BUBBLE_TEXT_PREFIX: 'bubbleText',
  NOTIFICATION_BADGE_PREFIX: 'notificationBadge',
  RECREATE_NEW_RESOLUTION_PREFIX: 'reCreateNewResolution'
};
