import React, { useState } from 'react';
import { PUBLISH_METHODS, PUBLISH_METHOD_OPTIONS } from 'shared/react/constants/tolstoy.constants';
import { FEED_ID, QUIZ_ID, TOLSTOY_ID } from 'app/src/constants/CreateNewTolstoy.constants';
import HorizontalFlex, {
  Gap16HorizontalFlex,
  Gap8HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { projectTabs } from 'src/helpers/Routes';
import { track } from 'app/src/helpers/Tracker';
import { usePublishMethods } from 'app/src/hooks/usePublishMethods/usePublishMethods';
import { useIsMobile } from 'shared/react/hooks/useIsMobile';
import { useModalActions } from 'app/src/context/ui_store/ModalStore';
import { useProjectActions } from 'app/src/context/ProjectsStore';
import { CREATION_FLOW_MODAL_KEY } from 'app/src/constants/modals.constants';
import InteractivePreview from 'app/src/images/create_new_content/tolstoy_types_options_previews/interactive.png';
import StoriesPreview from 'app/src/images/create_new_content/publish_options_previews/stories.png';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextBody } from 'shared/react/components/complex/Text';
import ImagePreviewContainer from 'app/src/basic_components/ImagePreviewContainer';
import ArrowIcon from 'shared/react/images/feed/ArrowIcon';
import { useApps } from 'app/src/context/AppsStore';
import useNavigation from 'app/src/hooks/useNavigation';
import { FEATURE_DEFAULT_CAROUSEL_TYPE } from 'shared/react/constants/features.constants';
import NewTolstoyMenuItems from 'app/src/pages/create_new_tolstoy/NewTolstoyMenuItems';
import { publishMethodOptions, RuleGroupType } from 'app/src/types/entities';
import { LIBRARY } from 'app/src/constants/creation_method.constants';
import { useRulesActions } from 'app/src/context/RulesStore';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import { ONSITE_TARGET_PAGES } from 'app/src/pages/dashboard/constants/onsiteTargetPages.constants';
import { RulesService } from 'app/src/services/rules';

const getIsDynamicProject = ({ publishMethod, isOffsite, isProductPages }) => {
  if (isOffsite) {
    return false;
  }

  if (isProductPages) {
    return ![
      publishMethodOptions.landingPage,
      publishMethodOptions.videoPage,
      publishMethodOptions.hero,
    ].includes(publishMethod);
  }

  return ![publishMethodOptions.hero].includes(publishMethod);
};

const isFeedEmbeds = publishMethod => {
  return [
    publishMethodOptions.videoPage,
    publishMethodOptions.stories,
    publishMethodOptions.carousel,
    publishMethodOptions.hero,
    publishMethodOptions.tile,
  ].includes(publishMethod);
};

const CreateNewTolstoy = ({
  location,
  onCloseMenu,
  onCreateProject,
  onCreateInteractive,
  isOffsite = false,
  isEmptyState = false,
  appUrl,
  currentFolder,
  publishMethod: defaultPublishMethod = null,
  ...props
}) => {
  const { publishMethodItems: mainMenuItems, tolstoyTypeItems: subMenuItems } = usePublishMethods();
  const { navigateToProjectTab } = useNavigation();
  const [{ shopify, productsImport }] = useApps();
  const { saveRules, createRule, createDefaultDynamicRule } = useRulesActions();
  const { setCurrentModal, setModalProps } = useModalActions();
  const { getSettingsByKey } = useFeatureActions();
  const { createProject } = useProjectActions();
  const isMobile = useIsMobile();
  const firstMainMenuItem = mainMenuItems[0];
  const isDefaultBubble = defaultPublishMethod === publishMethodOptions.bubble;
  const defaultTolstoyType = isFeedEmbeds(defaultPublishMethod) ? FEED_ID : TOLSTOY_ID;
  const shouldAskWhichPage = !!(shopify || productsImport);
  const shouldStartInChoosePage = defaultPublishMethod && !isDefaultBubble && shouldAskWhichPage;
  const [viewedPreview, setViewedPreview] = useState(
    shouldStartInChoosePage || <ImagePreviewContainer src={firstMainMenuItem.previewSrc} />
  );
  const [chosenPublishMethod, setChosenPublishMethod] = useState(defaultPublishMethod);
  const [selectedPublishMethod, setSelectedPublishMethod] = useState(defaultTolstoyType || null);
  const [menuItems, setMenuItems] = useState(
    isDefaultBubble || isOffsite ? subMenuItems : mainMenuItems
  );
  const [openBeforeHover, setOpenBeforeHover] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [isChoosePage, setIsChoosePage] = useState(shouldStartInChoosePage || false);
  const currentAppUrl = appUrl || shopify?.appUrl || productsImport?.appUrl;
  const trackParams = { location, isEmptyState };

  const { carouselType } = getSettingsByKey(FEATURE_DEFAULT_CAROUSEL_TYPE) || {};

  const createFeed = async ({ publishMethod, page }) => {
    track('Create New Feed Click', trackParams);

    setLoading(true);
    const isProductPages = page === ONSITE_TARGET_PAGES.ProductPages;
    const project = await createProject({
      isEcomFeed: true,
      publishMethod,
      dynamic: getIsDynamicProject({ publishMethod, isOffsite, isProductPages }),
      appUrl: currentAppUrl,
      folder: currentFolder,
      targetPage: page,
      widgetSettings: { carouselType, isFullscreenFeed: false },
    });

    const rules = shopify ? [[createDefaultDynamicRule()]] : [[]];

    if (!isProductPages) {
      rules.push([RulesService.getAllowNonTaggedVideosRule()]);
    }

    const ruleGroup = { rules, enabled: true };
    await saveRules(ruleGroup, project, RuleGroupType.dynamic);

    await onCreateProject?.(project);
    navigateToProjectTab(project, '');
  };

  const createQuiz = async ({ publishMethod, page }) => {
    track('Create New Quiz Click', trackParams);
    setLoading(true);
    const quizV2 = 'quiz_v2';
    const project = await createProject({
      type: quizV2,
      publishMethod,
      appUrl: currentAppUrl,
      folder: currentFolder,
      targetPage: page,
    });
    await onCreateProject?.(project);
    setModalProps({
      project,
      isLeftPanelVisible: false,
      defaultSelectedMethod: LIBRARY,
      appUrl: currentAppUrl,
      folder: currentFolder,
      targetPage: page,
    });
    setCurrentModal(CREATION_FLOW_MODAL_KEY);
    setLoading(false);
  };

  const createInteractive = ({ publishMethod, page }) => {
    track('Create New Tolstoy Click', trackParams);

    if (onCreateInteractive) {
      setLoading(true);
      onCreateInteractive(publishMethod);
      return;
    }

    const modalProps = {
      publishMethod,
      location,
      appUrl: currentAppUrl,
      folder: currentFolder,
      targetPage: page,
    };

    if (!window.Cypress) {
      modalProps.isLeftPanelVisible = false;
      modalProps.defaultSelectedMethod = LIBRARY;
    }

    setModalProps(modalProps);
    setCurrentModal(CREATION_FLOW_MODAL_KEY);
  };

  const createVideoCollectionCampaign = async () => {
    track('Create New Video Collection Campaign Click', trackParams);

    setLoading(true);
    const project = await createProject({
      publishMethod: PUBLISH_METHOD_OPTIONS.videoCollectionCampaign,
      appUrl: currentAppUrl,
      folder: currentFolder,
    });
    await onCreateProject?.(project);
    navigateToProjectTab(project, projectTabs.design);
  };

  const getPublishMethod = publishMethod => {
    if (defaultPublishMethod) {
      return defaultPublishMethod;
    }

    if (isOffsite) {
      return publishMethodOptions.landingPage;
    }

    return publishMethod;
  };

  const handleCreateNew = (id, publishMethod, page) => {
    // TODO: move to create handlers
    const currentPublishMethod = getPublishMethod(publishMethod);

    if (
      shouldAskWhichPage &&
      !isOffsite &&
      publishMethod !== publishMethodOptions.videoPage &&
      !isChoosePage
    ) {
      setSelectedPublishMethod(id);
      setIsChoosePage(true);
      setViewedPreview(null);
      return;
    }

    switch (id) {
      case FEED_ID:
        createFeed({ publishMethod: currentPublishMethod, page });
        break;
      case QUIZ_ID:
        createQuiz({ publishMethod: currentPublishMethod, page });
        break;
      case TOLSTOY_ID:
        createInteractive({ publishMethod: currentPublishMethod, page });
        break;
      case PUBLISH_METHOD_OPTIONS.videoCollectionCampaign:
        createVideoCollectionCampaign();
        break;
      default:
        throw new Error('Unknown entity', id);
    }

    onClose();
  };

  const onPageClick = page => {
    handleCreateNew(selectedPublishMethod, chosenPublishMethod, page);
  };

  const handlePublishMethodClick = publishMethod => {
    track(`Create New Tolstoy ${publishMethod} Menu Click`, trackParams);
    setChosenPublishMethod(publishMethod);
    setSelectedPublishMethod(publishMethod);

    if (isFeedEmbeds(publishMethod)) {
      handleCreateNew(FEED_ID, publishMethod);
      return;
    }

    if (publishMethodOptions.embed === publishMethod) {
      handleCreateNew(TOLSTOY_ID, publishMethod);
      return;
    }

    setOpenBeforeHover(true);
    setMenuItems(subMenuItems);
    setViewedPreview(<ImagePreviewContainer src={InteractivePreview} />);
  };

  const resetMenuContent = () => {
    setChosenPublishMethod(null);
    setMenuItems(mainMenuItems);
    setViewedPreview(<ImagePreviewContainer src={StoriesPreview} />);
    setOpenBeforeHover(true);
  };

  const onClose = () => {
    resetMenuContent();
    onCloseMenu?.();
  };

  const onBackButtonClick = () => {
    track('Create New Tolstoy Menu Back Button Click', trackParams);

    if (isChoosePage) {
      if (chosenPublishMethod === publishMethodOptions.videoPage) {
        setViewedPreview(<ImagePreviewContainer src={InteractivePreview} />);
      }

      setIsChoosePage(false);
    }

    resetMenuContent();
  };

  const handleHover = ({ previewSrc }) => {
    setOpenBeforeHover(false);
    setViewedPreview(<ImagePreviewContainer src={previewSrc} />);
  };

  return (
    <LayoutRoot {...props}>
      {!isMobile && viewedPreview}
      <InnerMenuLayoutRoot>
        {chosenPublishMethod && (
          <SubMenuHeader onClick={onBackButtonClick}>
            <IconContainer>
              <ArrowIcon />
            </IconContainer>
            <SubMenuTitle>{PUBLISH_METHODS[chosenPublishMethod].text}</SubMenuTitle>
          </SubMenuHeader>
        )}
        <NewTolstoyMenuItems
          handleCreateNew={handleCreateNew}
          handlePublishMethodClick={handlePublishMethodClick}
          menuItems={menuItems}
          chosenPublishMethod={chosenPublishMethod}
          selectedPublishMethod={selectedPublishMethod}
          isLoading={isLoading}
          openBeforeHover={openBeforeHover}
          firstMainMenuItemId={firstMainMenuItem.id}
          isOffsite={isOffsite}
          handleHover={handleHover}
          onPageClick={onPageClick}
          isChoosePage={isChoosePage}
          shouldAskWhichPage={shouldAskWhichPage}
        />
      </InnerMenuLayoutRoot>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16HorizontalFlex)`
  padding: 16px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  border-radius: 16px 0 16px 16px;
`;
const InnerMenuLayoutRoot = styled(VerticalFlex)`
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    max-width: 260px;
  }
`;

const IconContainer = styled(HorizontalFlex)`
  transform: rotate(-90deg);
`;

const SubMenuHeader = styled(Gap8HorizontalFlex)`
  width: 100%;
  height: 40px;
  align-items: center;
  padding: 0 8px;
  margin-bottom: 8px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
  }

  path {
    fill: ${({ theme }) => theme.colors.black};
  }
`;

const SubMenuTitle = styled(TextBody)`
  color: ${({ theme }) => theme.colors.black};
  font-weight: 500;

  &:first-letter {
    text-transform: uppercase;
  }
`;

export default CreateNewTolstoy;
