import React from 'react';
import styled from 'styled-components';
import { TextH6 } from 'shared/react/components/complex/Text';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import Method from 'app/src/pages/creation_flow/creation_method/Method';
import XIcon from 'app/src/images/XIcon';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import {
  getCategories,
  getEcomCategories,
  getCustomCategories,
} from 'app/src/pages/creation_flow/creation_method/CreationMethod.constants';
import { useFeatures } from 'app/src/context/FeaturesStore';
import {
  FEATURE_INSTAGRAM_INTEGRATION,
  FEATURE_CUSTOM_CREATION_METHODS,
} from 'shared/react/constants/features.constants';

const CreationMethod = ({
  selectedMethod,
  onMethodClick,
  shouldShowTemplates,
  onClose,
  headerText = 'Create Tolstoy',
  isEcom,
}) => {
  const [, { getFeatureEnabled, getSettingsByKey }] = useFeatures();
  const isInstagramEnabled = getFeatureEnabled(FEATURE_INSTAGRAM_INTEGRATION);
  const customCreationMethods = getSettingsByKey(FEATURE_CUSTOM_CREATION_METHODS);

  const getItems = () => {
    if (customCreationMethods.methods) {
      return getCustomCategories(customCreationMethods.methods);
    }

    if (isEcom) {
      return getEcomCategories(shouldShowTemplates, isInstagramEnabled);
    }

    return getCategories(shouldShowTemplates, isInstagramEnabled);
  };

  const items = getItems();
  return (
    <LayoutRoot>
      <HeaderContainer>
        <Header>{headerText}</Header>
      </HeaderContainer>
      {items.map(item => {
        return (
          <Method
            onMethodClick={onMethodClick}
            key={item.name}
            {...item}
            selectedMethod={selectedMethod}
          />
        );
      })}
      <CloseModal onClick={onClose}>
        <XIcon />
      </CloseModal>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  gap: 8px;
  padding: 32px 8px;
  width: 251px;
  border-right: 1px solid ${({ theme }) => theme.colors.gray3};

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    padding: 24px 0px;
    width: 100%;

    & > * {
      padding-left: 40px;
    }
  }
`;

const HeaderContainer = styled(Gap8VerticalFlex)`
  padding: 0 16px 16px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    padding: 0 40px 24px;
  }
`;

const Header = styled(TextH6)`
  font-weight: 800;
`;

const CloseModal = styled(HorizontalFlexWrap)`
  z-index: 1;
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
  display: none;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    display: block;
  }
`;

export default CreationMethod;
