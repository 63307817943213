import { INTERNAL_EVENTS } from '../constants/internalEvents.constants.js';
import {
  RECHARGE_MESSAGING,
  RECHARGE_WIDGET_DATA_MESSAGE,
  REQUEST_RECHARGE_ADD_TO_CART_MESSAGE,
  REQUEST_RECHARGE_WIDGET_DATA_MESSAGE,
  TOLSTOY_PLAYER_READY_MESSAGE
} from '../constants/messages.constants.js';

class RechargeMessaging {
  constructor(tolstoyIframe) {
    this.tolstoyIframe = tolstoyIframe;
    this.rechargeMessagingHandler = this.rechargeMessagingHandler.bind(this);
    this.playerReady = false;
    this.playerReadyMessages = [];
    this.subscribeForPlayerStart();
    this.init();
  }

  subscribeForPlayerStart() {
    window.addEventListener('message', event => {
      if (!event.data) {
        return;
      }

      if (event.data.eventName === TOLSTOY_PLAYER_READY_MESSAGE) {
        this.playerReady = true;
        for (const message of this.playerReadyMessages || []) {
          this.tolstoyIframe?.contentWindow?.postMessage(message, '*');
        }

        this.playerReadyMessages = [];
      }
    });
  }

  safePostMessageToWindow(message) {
    if (!this.playerReady) {
      return this.playerReadyMessages.push(message);
    }

    this.tolstoyIframe?.contentWindow.postMessage(message, '*');
  }

  async getRechargeWidgetData() {
    if (!window.ReChargeWidget) {
      return;
    }

    const rechargeWidgetData = await window.ReChargeWidget.api.fetchWidgetData();

    this.safePostMessageToWindow({
      eventName: RECHARGE_WIDGET_DATA_MESSAGE,
      rechargeWidgetData
    });
  }

  async rechargeAddToCart({ rechargeCartItem, variantId }) {
    if (!window.ReChargeWidget) {
      return;
    }

    try {
      await window.ReChargeWidget.api.postToCart(rechargeCartItem);
      this.safePostMessageToWindow({ eventName: INTERNAL_EVENTS.addToCartSuccess, variantId });
    } catch {
      this.safePostMessageToWindow({
        eventName: INTERNAL_EVENTS.addToCartError,
        error: 'Error adding to cart',
        variantId
      });
    }
  }

  rechargeMessagingHandler(event) {
    if (event.data.name !== RECHARGE_MESSAGING) {
      return;
    }

    switch (event.data.type) {
      case REQUEST_RECHARGE_WIDGET_DATA_MESSAGE:
        return this.getRechargeWidgetData();
      case REQUEST_RECHARGE_ADD_TO_CART_MESSAGE:
        return this.rechargeAddToCart(event.data);
      default:
        return null;
    }
  }

  init() {
    window.addEventListener('message', this.rechargeMessagingHandler);
  }
}

export default RechargeMessaging;
