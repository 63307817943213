import React, { SyntheticEvent } from 'react';
import {
  FilterBlock,
  FilterComparator,
  FilterGroup,
  FilterName,
} from 'app/src/pages/dashboard/components/filters/components/filter-block/FilterBlock';
import CrossIcon from 'app/src/images/dashboard_v2/CrossIcon';
import filtersConfig from 'app/src/pages/dashboard/components/filters/filtersConfig';
import { ComparatorEnum, ComparisonOperator } from 'app/src/pages/dashboard/utils/filterEntities';
import { MenuVariants } from 'app/src/pages/dashboard/components/filters/constants/filter.constants';
import useActions from 'app/src/pages/dashboard/components/filters/hooks/useActions';
import { parseCurrentFilter } from 'app/src/pages/dashboard/components/filters/components/filter-by-key-menu/FilterByKeyMenu';

type Props = {
  currentKey: string;
  filter: ComparisonOperator;
  actions: ReturnType<typeof useActions>;
  onRemoveFilter: (key: string) => void;
  onMenuToggle: (type: MenuVariants, key?: string) => (event?: SyntheticEvent) => void;
};

const CurrentFiltersGroup = ({
  currentKey,
  filter,
  actions,
  onRemoveFilter,
  onMenuToggle,
}: Props) => {
  const {
    name,
    icon: Icon,
    getData,
    filterSuffix,
    getFilterValueString,
    filterComparatorString,
  } = filtersConfig[currentKey];

  const getFilterComparator = (filter: ComparisonOperator) => {
    const [comparator] = Object.entries(filter)[0];
    const { selectedItems: value } = parseCurrentFilter(filter);
    if (filterComparatorString) {
      return filterComparatorString(comparator);
    }

    const isMultipleSelected = Array.isArray(value) && value.length > 1;
    if ([ComparatorEnum.satisfies, ComparatorEnum.in].includes(comparator as ComparatorEnum)) {
      return isMultipleSelected ? 'is any of' : 'is';
    }

    return 'is not';
  };

  const getFilterValue = (key: string, filter: ComparisonOperator) => {
    const { selectedItems: value } = parseCurrentFilter(filter);
    if (getFilterValueString) {
      return getFilterValueString(value);
    }

    if (!Array.isArray(value) || !value.length) {
      return '';
    }

    if (value.length === 1) {
      const valueId = value[0];
      const menuOptions = getData(actions);
      return menuOptions.find(option => option.id === valueId)?.name;
    }

    return `${value.length} ${filterSuffix}`;
  };

  const filterValue = getFilterValue(currentKey, filter);
  const comparator = getFilterComparator(filter);

  if (!filterValue) {
    return (
      <FilterGroup>
        <FilterComparator onClick={onMenuToggle(MenuVariants.comparator, currentKey)}>
          {comparator}
        </FilterComparator>
        <FilterName>
          <Icon />
          {name}
        </FilterName>
        <FilterBlock onClick={() => onRemoveFilter(currentKey)}>
          <CrossIcon />
        </FilterBlock>
      </FilterGroup>
    );
  }

  return (
    <FilterGroup>
      <FilterName>
        <Icon />
        {name}
      </FilterName>
      <FilterComparator onClick={onMenuToggle(MenuVariants.comparator, currentKey)}>
        {comparator}
      </FilterComparator>
      <FilterBlock onClick={onMenuToggle(MenuVariants.filter, currentKey)}>
        {filterValue}
      </FilterBlock>
      <FilterBlock onClick={() => onRemoveFilter(currentKey)}>
        <CrossIcon />
      </FilterBlock>
    </FilterGroup>
  );
};

export default CurrentFiltersGroup;
