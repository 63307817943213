import React, { useEffect, useState } from 'react';
import Routes from '../helpers/Routes';
import { Route, Switch } from 'react-router-dom';
import ProductRecommendationReply from '../pages/product_recommendation_reply/ProductRecommendationReply';
import SubtitlesEditor from '../pages/subtitles_editor/SubtitlesEditor';
import Onboarding from '../pages/onboarding/Onboarding';
import GhostAccountNotification from '../pages/banners/GhostAccountNotification';
import useOnInit from '../hooks/useOnInit';
import JoinWorkspaceModal from '../pages/modals/JoinWorkspaceModal';
import TolstoyMessage from '../pages/message/TolstoyMessage';
import Utils from '../utils';
import AddExtensionModal from '../pages/modals/AddExtensionModal';
import VideoTrimPage from '../pages/video_trim/VideoTrimPage';
import GlobalModal from 'app/src/complex_components/global_modal/GlobalModal';
import DashboardV2 from 'app/src/pages/dashboard/Dashboard.tsx';
import DowngradePage from 'app/src/pages/downgrade/DowngradePage';
import TolstoyWidgetLauncher from '../pages/banners/TolstoyWidgetLauncher';
import GlobalStyle from 'app/src/themes/GlobalStyle';
import ProjectPage from 'app/src/pages/project/ProjectPage';
import Root from 'app/src/pages/root/Root';
import UserContainers from 'app/src/routes/User.containers';
import TolyChatBot from 'app/src/pages/dashboard/components/toly-chatbot/TolyChatBot';
import usePreloadImages from 'app/src/hooks/usePreloadImages';
import { cases } from 'app/src/pages/dashboard/components/cases/cases.constants';
import GlobalDrawer from 'src/complex_components/global_drawer/GlobalDrawer';

const User = () => {
  const { loading, initialized, accountDetails } = useOnInit();
  const [showInvite, setShowInvite] = useState(false);
  const preload = usePreloadImages();

  useEffect(() => {
    const images = Object.values(cases).map(({ image }) => image);
    preload(images);
  }, []);

  useEffect(() => {
    if (!loading && initialized) {
      setShowInvite(!!Utils.getInvite());
    }
  }, [loading, initialized]);

  const routes = (
    <>
      <Route path={Routes.getTolstoyMessageBaseRoute()}>
        <TolstoyMessage />
      </Route>
      <Route path={Routes.getVideoTrimmingRoute()}>
        <VideoTrimPage />
      </Route>

      <Switch>
        <Route path={Routes.getDowngradeBaseRoute()}>
          <DowngradePage />
        </Route>
        <Route path={Routes.getOnboardingBaseRoute()}>
          <Onboarding />
        </Route>
        <Route path={Routes.getProductRecommendationReplyRoute()}>
          <ProductRecommendationReply />
        </Route>
        <Route path={Routes.getSubtitlesEditorRoutes()}>
          <SubtitlesEditor />
        </Route>
        <Switch>
          <Route path={[Routes.getSignupRoute(), Routes.getLoginRoute()]}>
            <Root />
          </Route>
          <Route path={Routes.getV2DashboardRoutes()} exact>
            <DashboardV2 accountDetails={accountDetails} />
          </Route>
          <Route path={Routes.getV2ProjectRoutes()}>
            <ProjectPage />
          </Route>
        </Switch>
      </Switch>
    </>
  );

  return (
    <UserContainers accountDetails={accountDetails}>
      <GlobalStyle />
      <TolstoyWidgetLauncher />
      <GhostAccountNotification />
      <AddExtensionModal />
      {showInvite && <JoinWorkspaceModal open={showInvite} onClose={() => setShowInvite(false)} />}
      {routes}
      <GlobalModal />
      <GlobalDrawer />
      <TolyChatBot />
    </UserContainers>
  );
};

export default User;
