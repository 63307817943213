import React from 'react';
import styled from 'styled-components';
import FeedProduct from 'shared/react/components/complex/feed/feed_products/FeedProduct';
import { SHOW_FEED_PRODUCT_MODAL_MESSAGE } from 'shared/react/constants/messages.constants';
import { useIsMobile } from 'shared/react/hooks/useIsMobile';
import Utils from 'shared/react/utils/utils';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';
import useKeyboardNavigation from 'player/src/hooks/useKeyboardNavigation';

const FeedFocusedProduct = ({
  focusedProduct,
  focusedVariantId,
  currentStepKey,
  setFocusedProduct,
  isFullWidth,
  disableZoomIn,
  isHorizontal,
  priorityProductId,
  closeModal,
}) => {
  const isMobile = useIsMobile();
  const [{ isAppPreview }] = useProjectConfig();
  const { resetRefs } = useKeyboardNavigation();

  const onBackButtonClick = () => {
    resetRefs();
    if (!isMobile) {
      Utils.postMessageToWindow({ name: SHOW_FEED_PRODUCT_MODAL_MESSAGE });
    }

    if (priorityProductId && isMobile) {
      closeModal();
    } else {
      setFocusedProduct(null);
    }
  };

  return (
    <LayoutRoot isFullWidth={isFullWidth || isHorizontal}>
      <FeedProduct
        product={focusedProduct}
        variantId={focusedVariantId}
        currentStepKey={currentStepKey}
        disableZoomIn={disableZoomIn || isAppPreview || isHorizontal}
        isFullWidth={isFullWidth}
        isHorizontal={isHorizontal}
        handleBackButton={onBackButtonClick}
        hideVariantSubtitle
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  width: 100%;
  height: 100%;
  max-width: ${({ isFullWidth }) => (isFullWidth ? '100%' : '25vw')};
  position: relative;
`;

export default FeedFocusedProduct;
