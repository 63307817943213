import React from 'react';
import styled from 'styled-components';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextSmall, TextTiny } from 'shared/react/components/basic/text/TextV2';
import { ChevronDownIcon, ChevronRightIcon } from 'shared/react/images/ChevronIcon';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';

type Props = {
  roundColorShape: JSX.Element;
  color: string;
  onClick: () => void;
  palette: {
    label: string;
    value: string;
  }[];
  setInputEl: (ref: HTMLDivElement) => void;
};

const PalletColorInput = ({ roundColorShape, color, onClick, palette, setInputEl }: Props) => {
  const currentColor = palette.find(item => item.value === color);

  const currentColorLabel = currentColor ? currentColor.label : color;

  return (
    <LayoutRoot ref={setInputEl} onClick={onClick}>
      {roundColorShape}
      <TextContainer>
        <EllipsisTextOverflowWrapper>
          <Header>{currentColorLabel}</Header>
        </EllipsisTextOverflowWrapper>
        {!!currentColor && <SubHeader>{color}</SubHeader>}
      </TextContainer>
      <StyledChevronDownIcon />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)`
  padding: 6px 16px;
  height: 44px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.neutralLight};
  align-items: center;
  cursor: pointer;
  max-width: 200px;
  min-width: 200px;
  gap: 12px;
`;

const TextContainer = styled(VerticalFlex)`
  width: 100%;
`;

const Header = styled(TextSmall)``;

const SubHeader = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralDarker};
`;

const StyledChevronDownIcon = styled(ChevronDownIcon)`
  cursor: pointer;
`;

export default PalletColorInput;
