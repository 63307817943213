import React from 'react';
import { TextSmall, TextTiny } from 'shared/react/components/basic/text/TextV2';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Tooltip from 'src/basic_components/tooltip-v2/Tooltip';
import MagicIcon from 'src/complex_components/global_modal/video/add_products_modal/components/icons/MagicIcon';
import styled from 'styled-components';
import { AnalyticsTopConvertingVideoInsight } from './analytics-player/AnalyticsPlayer.types';

type Props = {
  insights: AnalyticsTopConvertingVideoInsight[];
};

const AnalyticsTopConvertingVideoInsights = ({ insights }: Props) => {
  if (!insights?.length) {
    return null;
  }

  return (
    <LayoutRoot>
      <Header>
        <MagicIcon />
        <StyledTextSmall>Insights</StyledTextSmall>
      </Header>
      {insights.map(({ header, description, recommendation }, index) => {
        const bullet = recommendation ? '◆' : '•';
        return (
          <BulletsContainer key={index}>
            <Tooltip text={description}>
              <TextTiny>
                <Bullet $recommendation={recommendation}>{bullet}</Bullet> {header}
              </TextTiny>
            </Tooltip>
          </BulletsContainer>
        );
      })}
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  padding: 8px 0 8px 8px;
  max-width: 195px;
`;

const Header = styled(HorizontalFlex)`
  gap: 4px;
  align-items: center;
`;

const BulletsContainer = styled(VerticalFlex)`
  padding-top: 4px;
  padding-left: 8px;
`;

const StyledTextSmall = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.grapePurple};
`;

const Bullet = styled.span<{ $recommendation?: boolean }>`
  color: ${({ theme, $recommendation }) =>
    $recommendation ? theme.colors.fruityOrange : undefined};
`;

export default AnalyticsTopConvertingVideoInsights;
