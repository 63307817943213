import { vodLabel as VodLabel } from 'src/types/entities';
import { graphqlRequest } from 'src/helpers/API';
import { graphqlOperation } from 'aws-amplify';
import {
  createVodLabel as createVodLabelGraphQL,
  updateVodLabel as updateVodLabelGraphQL,
  deleteVodLabel as deleteVodLabelGraphQL,
} from 'src/graphql/mutations';

export const createVodLabel = async (vodLabel: Partial<VodLabel>) =>
  graphqlRequest(graphqlOperation(createVodLabelGraphQL, { input: vodLabel }));

export const updateVodLabel = async (vodLabel: Partial<VodLabel>) => {
  if (!vodLabel.id) {
    return;
  }

  const response = await graphqlRequest(
    graphqlOperation(updateVodLabelGraphQL, { input: vodLabel })
  );

  return response?.data?.updateVodLabel;
};

export const deleteVodLabel = async (id: string) => {
  if (!id) {
    return;
  }

  const response = await graphqlRequest(graphqlOperation(deleteVodLabelGraphQL, { input: { id } }));

  return response?.data?.deleteVodLabel;
};

export default {
  createVodLabel,
  updateVodLabel,
  deleteVodLabel,
};
