import React from 'react';

const ClockIcon = () => {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 .216C4.805.216.625 4.435.625 9.59A9.353 9.353 0 0 0 10 18.966c5.156 0 9.375-4.18 9.375-9.375 0-5.156-4.219-9.375-9.375-9.375Zm3.242 12.46a.984.984 0 0 1-.781.352.9.9 0 0 1-.547-.156l-2.5-1.875a.984.984 0 0 1-.351-.781V5.528c0-.507.39-.937.937-.937a.95.95 0 0 1 .938.937v4.22l2.109 1.6a.94.94 0 0 1 .195 1.329Z"
        fill="#7D8087"
      />
    </svg>
  );
};

export default ClockIcon;
