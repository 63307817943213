import React from 'react';

function DIcon({ fill, darkBackground, size = 20 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={12} cy={12} r={12} fill={fill || '#FFFFFF'} />
      <path
        d="M8.895 15.5h2.255c2.453 0 4.18-1.727 4.18-4.015 0-2.277-1.529-4.015-4.246-4.015H8.895v8.03Zm2.013-6.611c1.97 0 2.893 1.078 2.893 2.596 0 1.474-1.089 2.596-2.827 2.596h-.55V8.889h.484Z"
        fill={darkBackground ? '#FFFFFF' : '#2F3137'}
      />
    </svg>
  );
}

export default DIcon;
