import React from 'react';
import styled from 'styled-components';
import { Clock, Check, X } from 'lucide-react';

import { TextSmall } from 'shared/react/components/basic/text/TextV2';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import {
  UGC_INSTAGRAM_GRAPH,
  UGC_INSTAGRAM_GRAPH_DENIED,
  UGC_INSTAGRAM_GRAPH_PENDING,
} from 'app/src/constants/intergrations.constants';

type Props = {
  uploadType: string;
};

const STATUS_TEXT = {
  [UGC_INSTAGRAM_GRAPH]: {
    text: 'Approved for use',
    icon: <Check size={18} stroke="#090A0B" />,
  },
  [UGC_INSTAGRAM_GRAPH_DENIED]: {
    text: 'Usage rights declined',
    icon: <X size={18} stroke="#090A0B" />,
  },
  [UGC_INSTAGRAM_GRAPH_PENDING]: {
    text: 'Pending creator approval',
    icon: <Clock size={18} fill="#090A0B" stroke="white" />,
  },
};

const UgcMediaStatusLabel = ({ uploadType }: Props) => {
  const { text, icon } = STATUS_TEXT[uploadType] || {};

  if (!text) {
    return null;
  }

  return (
    <Container>
      {icon}
      <UnderlinedText>{text}</UnderlinedText>
    </Container>
  );
};

const UnderlinedText = styled(TextSmall)`
  text-decoration: underline;
`;

const Container = styled(Gap8HorizontalFlex)`
  align-items: center;
  gap: 6px;
`;

export default UgcMediaStatusLabel;
