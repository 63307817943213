import React from 'react';
import { UnauthenticatedStateContainer } from './UnauthenticatedStateStore';
import { QuizContainer } from './QuizStore';
import { ReadyMadeContainer } from './ReadyMadeStore';
import { FeaturesContainer } from './FeaturesStore';

import UIStore from './ui_store/UIStore';

const RootStore = ({ children }) => {
  return (
    <QuizContainer>
      <ReadyMadeContainer>
        <FeaturesContainer>
          <UnauthenticatedStateContainer>
            <UIStore>{children}</UIStore>
          </UnauthenticatedStateContainer>
        </FeaturesContainer>
      </ReadyMadeContainer>
    </QuizContainer>
  );
};

export default RootStore;
