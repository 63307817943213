import Routes from 'src/helpers/Routes';
import useNavigation from './useNavigation';

const useIsCurrentPathName = () => {
  const { currentPathName } = useNavigation();

  const isAnalyticsPath = Routes.getDashboardV2AnalyticsRoute().includes(currentPathName);

  return { isAnalyticsPath };
};

export default useIsCurrentPathName;
