import React from 'react';

const ABCIcon = () => {
  return (
    <svg width="40" height="13" viewBox="0 0 40 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 12.5c3.305 0 6-2.672 6-6 0-3.305-2.695-6-6-6-3.328 0-6 2.695-6 6 0 3.328 2.672 6 6 6Zm0-9c.21 0 .398.14.492.328L8.93 8.703c.14.281.023.61-.258.75-.258.14-.61.024-.75-.258l-.399-.82h-3.07l-.398.82a.555.555 0 0 1-.75.258.555.555 0 0 1-.258-.75l2.437-4.875A.576.576 0 0 1 6 3.5Zm0 1.828L5.016 7.25H6.96L6 5.328ZM20 12.5c3.305 0 6-2.672 6-6 0-3.305-2.695-6-6-6-3.328 0-6 2.695-6 6 0 3.328 2.672 6 6 6Zm-2.25-8.438c0-.304.234-.562.563-.562h2.156a1.78 1.78 0 0 1 1.781 1.781c0 .399-.14.75-.328 1.032a1.8 1.8 0 0 1 .703 1.406A1.78 1.78 0 0 1 20.844 9.5h-2.532a.542.542 0 0 1-.562-.563V4.063Zm3.375 1.22a.67.67 0 0 0-.656-.657h-1.594v1.313h1.594a.655.655 0 0 0 .656-.657Zm-2.25 3.093h1.969a.655.655 0 0 0 .656-.656.67.67 0 0 0-.656-.657h-1.969v1.313ZM34 12.5c3.305 0 6-2.672 6-6 0-3.305-2.695-6-6-6-3.328 0-6 2.695-6 6 0 3.328 2.672 6 6 6Zm-1.336-4.664a1.881 1.881 0 0 0 2.648 0 .58.58 0 0 1 .797 0 .58.58 0 0 1 0 .797 2.999 2.999 0 1 1-4.242-4.242 2.999 2.999 0 0 1 4.242 0 .58.58 0 0 1 0 .796.58.58 0 0 1-.797 0 1.881 1.881 0 0 0-2.648 0 1.881 1.881 0 0 0 0 2.649Z"
        fill="#000"
      />
    </svg>
  );
};

export default ABCIcon;
