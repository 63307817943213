import React from 'react';
import styled, { useTheme } from 'styled-components';

import Utils from 'shared/react/utils/utils';
import { TextTiny } from 'shared/react/components/complex/Text';

const CustomerLoginFooter = () => {
  const theme = useTheme();

  return (
    <LayoutRoot fontWeight="400" lineHeight="16px">
      {/* TODO: use translations */}
      <TextTiny fontWeight="400" textColor={theme.colors.grey34} lineHeight="16px">
        By continuing, you agree to Tolstoy’s
        <TermsAndConditionsLink
          onClick={() => Utils.openInNewTab('https://www.gotolstoy.com/terms-of-use')}
          target="_blank"
          rel="noreferrer"
        >
          Terms of Use
        </TermsAndConditionsLink>
        and confirm that you have read Tolstoy’s
        <TermsAndConditionsLink
          onClick={() => Utils.openInNewTab('https://www.gotolstoy.com/privacy-policy')}
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy.
        </TermsAndConditionsLink>
      </TextTiny>
    </LayoutRoot>
  );
};

const TermsAndConditionsLink = styled(TextTiny)`
  display: inline;
  padding: 0 5px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black};
`;

const LayoutRoot = styled(TextTiny)`
  gap: 40px;
  color: ${({ theme }) => theme.colors.grey34};
`;

export default CustomerLoginFooter;
