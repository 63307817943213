import React from 'react';
import Button from 'shared/react/components/complex/Button';
import Sizes from 'shared/react/theme/Sizes';
import Types from 'shared/react/theme/Types';
import PlusIcon from 'app/src/images/PlusIcon';

function AddRuleButton({ disabled, onClick, ...props }) {
  return (
    <Button
      size={Sizes.Small}
      type={Types.Success}
      showRightIcon
      padding="0 10px"
      rightIcon={<PlusIcon />}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      Add rule
    </Button>
  );
}

export default AddRuleButton;
