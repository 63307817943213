import React from 'react';

const ProductPitch = props => (
  <svg
    width={39}
    height={44}
    viewBox="0 0 39 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M31.332 39.65a1.74 1.74 0 0 1-.436 1.627A2.362 2.362 0 0 1 29.16 42H6.898a2.361 2.361 0 0 1-1.735-.723 1.74 1.74 0 0 1-.436-1.626l3.847-15.015h18.91l3.848 15.015Z"
      stroke="url(#a)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 24.636h32.057"
      stroke="url(#b)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.518 32.65h23.02"
      stroke="url(#c)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.784 24.636 17.262 7.41c-.436-2.973-1.863-5.803-4.842-5.366-2.98.436-3.53 3.562-3.095 6.527l2.36 16.064"
      stroke="url(#d)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m25.781 24.636 4.332-11.138"
      stroke="url(#e)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.388 11.672a5.784 5.784 0 0 0 7.384 2.252c1.899-1.18.534-3.464-.356-4.83a9.75 9.75 0 0 0-6.185-4.357l-.082-.014a3.74 3.74 0 0 0-4.143 2.326c-5.129 13.106-5.633 14.415-5.67 14.52"
      stroke="url(#f)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient id="a" x1={4.676} y1={42} x2={31.383} y2={42} gradientUnits="userSpaceOnUse">
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={2}
        y1={25.636}
        x2={34.057}
        y2={25.636}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={6.518}
        y1={33.65}
        x2={29.538}
        y2={33.65}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={9.193}
        y1={24.636}
        x2={19.784}
        y2={24.636}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={25.781}
        y1={24.636}
        x2={30.113}
        y2={24.636}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
      <linearGradient
        id="f"
        x1={19.336}
        y1={21.569}
        x2={36.729}
        y2={21.569}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
    </defs>
  </svg>
);

export default ProductPitch;
