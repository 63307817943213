import { useProjects } from 'app/src/context/ProjectsStore';
import { useAnalyticsProjects } from './useAnalyticsProjects';

const AnalyticsTolstoyStepAnswer = ({ value: answerKey, row }) => {
  const [, { getStepByName }] = useProjects();
  const { selectedProjects } = useAnalyticsProjects();

  const project = selectedProjects?.find(({ project }) => getStepByName(row.stepName, project));
  const step = getStepByName(row.stepName, project);

  if (step?.defaultResponsesEnabled) {
    return step.defaultResponses?.find(({ key }) => key === answerKey)?.text || answerKey;
  }

  return step?.answers?.find(({ key }) => key === answerKey)?.text || answerKey;
};

export default AnalyticsTolstoyStepAnswer;
