import React, { FC } from 'react';
import styled, { useTheme } from 'styled-components';
import { EditorItemContentProps } from 'src/pages/project/pages/look_and_feel/types/lookAndFeel.types';
import { TextSmall } from 'shared/react/components/complex/Text';
import InfoIconWithTooltip from 'app/src/complex_components/InfoIconWithTooltip';
import InfoIconV2 from 'app/src/images/InfoIconV2';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import ProIcon from 'app/src/images/ProIcon';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

const EditorItemContent: FC<EditorItemContentProps> = ({
  text,
  tooltipText,
  renderTextLayout,
  children,
  isPro,
  ...props
}) => {
  const theme = useTheme();
  const undfinedTooltipProps = {
    alignToCenter: undefined,
    iconFill: undefined,
    margin: undefined,
  };

  return (
    <LayoutRoot {...props}>
      {renderTextLayout?.() || (
        <TitleContainer>
          <EditorItemText>{text}</EditorItemText>
          {tooltipText && (
            <InfoIconWithTooltip
              title={tooltipText}
              tooltipPlacement="right"
              customIcon={<InfoIconV2 />}
              {...undfinedTooltipProps}
            />
          )}
          {isPro && <ProIcon fill={theme.colors.black} />}
        </TitleContainer>
      )}

      {children}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)`
  justify-content: space-between;
  min-height: 42px;
  gap: 16px;
  justify-items: end;
  align-items: center;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : '')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  flex-wrap: wrap;
`;

const TitleContainer = styled(Gap8HorizontalFlexWrap)`
  flex-shrink: 0;
`;

const EditorItemText = styled(TextSmall)`
  font-weight: 500;
`;

export default EditorItemContent;
