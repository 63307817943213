import { getNextNonImageIndex } from 'widget/src/utils/assets.utils.js';
import { INTERNAL_EVENTS } from 'widget/src/constants/internalEvents.constants.js';
import { getEmbedProductId } from 'widget/src/utils/script.utils.js';
import { setImpressionByPublishId } from '../../../common/analytics.js';
import { getAppKey } from '../../../common/appKey.js';
import { reCreateResolutions } from '../../../common/reCreateResolutions.js';
import { getShop } from '../../../common/shop.js';
import {
  JPG_AVATAR_EXTENSION,
  JPG_EXTENSION,
  WEBP_AVATAR_EXTENSION
} from '../../../constants/extensions.js';
import { WIDGETS_MESSAGES, WIDGET_MESSAGES_EVENTS } from '../../../constants/messages.constants.js';
import { STORY_EMBED_WIDGET, DOM_EXCEPTION_ERRORS } from '../../../constants/widgetConstants.js';
import { getIsPlayerLazy, getFeatureSettings } from '../../../utils/feature.utils.js';
import { logDevelopmentError, getIsIOS } from '../../../utils/utils.js';
import Modal from '../../Modal/Modal.js';
import { getBaseUrl } from '../Carousel/carousel.utils.js';
import {
  getAllElements,
  getConfig,
  getEmbedPublishId,
  getPublicId,
  onViewPortChangeHandler,
  reloadFontIfNeeded,
  stopDynamicVideos
} from '../embedWidgets.utils.js';
import { safePlayVideo } from '../../../utils/dom.utils.js';
import { FEATURE_WIDGET_LOADING } from '../../../constants/features.constants.js';
import InternalMessaging from '../../../messaging/internal/InternalMessaging.js';
import {
  INTERNAL_MESSAGES_TO_SUBSCRIBE,
  STORIES_ARROWS_VISIBILITY_ATTRIBUTES
} from './story.constants.js';
import {
  DEFAULT_STORIES_ITEMS_PER_ROW,
  DEFAULT_STORIES_ITEMS_SIZE_TYPE,
  DEFAULT_STORIES_SIZE,
  DEFAULT_STORIES_SPACING,
  EMBED_STORY_MOTION,
  PUBLIC_CLASSES,
  STORIES_ITEMS_SIZE_TYPES,
  STORIES_ITEM_MAX_FIXED_SIZE,
  STORIES_NAME_LOCATION
} from './storyConstants.js';
import getHTML from './storyHtml.js';

const ID_SELECTOR_BY_PREFIX = 'data-tolstoy-element^';

const exitEarlyIfNeeded = ({ element }) => {
  if (getAppKey() !== '56bfe6cb-0926-420a-ac48-4d36a3a2ea07') {
    // thrivecausemetics.com
    return false;
  }

  try {
    // eslint-disable-next-line unicorn/prefer-query-selector
    const quickShop = document.getElementsByTagName('quick-shop')[0];

    if (!quickShop) {
      return false;
    }

    return quickShop.contains(element);
  } catch {
    return false;
  }
};

class Story {
  constructor() {
    this.initialized = false;
    this.handlePageView = this.handlePageView.bind(this);
    this.handleView = this.handleView.bind(this);
    this.onViewPortChange = this.onViewPortChange.bind(this);
    this.onVideoEnd = this.onVideoEnd.bind(this);
    this.stopDynamicVideos = this.stopDynamicVideos.bind(this);
    this.setIsDynamicVideoRunning = this.setIsDynamicVideoRunning.bind(this);
    this.handleDynamicVideos = this.handleDynamicVideos.bind(this);
    this.modalMessageHandler = this.modalMessageHandler.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.scrollToPrevious = this.scrollToPrevious.bind(this);
    this.scrollToNext = this.scrollToNext.bind(this);
    this.currentVideoPlaying = 0;
    this.isDynamicVideoRunning = false;
    this.dynamicVideoIndex = 0;
    this.isCreateResolutionCalled = false;
    this.initInternalMessagingSubscriptions();
  }

  onVideoEnd() {
    this.dynamicVideoIndex = getNextNonImageIndex(
      this.data.storiesEmbed.storiesSteps,
      this.dynamicVideoIndex
    );
    this.handleDynamicVideos();
  }

  onViewPortChange() {
    onViewPortChangeHandler({
      videoClass: PUBLIC_CLASSES.storyVideo,
      isDynamicVideoRunning: this.isDynamicVideoRunning,
      setIsDynamicVideoRunning: this.setIsDynamicVideoRunning,
      dynamicVideoHandler: this.handleDynamicVideos,
      publishId: this.data.publishId,
      onVideoEnd: this.onVideoEnd
    });
  }

  setIsDynamicVideoRunning(value) {
    this.isDynamicVideoRunning = value;
  }

  stopDynamicVideos() {
    stopDynamicVideos({
      videoClass: PUBLIC_CLASSES.storyVideo,
      onVideoEnd: this.onVideoEnd,
      publishId: this.data.publishId,
      setIsDynamicVideoRunning: this.setIsDynamicVideoRunning
    });
  }

  start(index) {
    this.modal?.open(index);
  }

  getElementByPublicId(type) {
    return window.document.querySelector(
      `[data-tolstoy-element="${getPublicId(type, this.data.publishId)}"]`
    );
  }

  getAllElements(type) {
    return window.document.querySelectorAll(type);
  }

  getIdWithPrefixSelector(id) {
    return `[${ID_SELECTOR_BY_PREFIX}=${id}]`;
  }

  addOnClickEventsToStoryTiles() {
    const tileId = getPublicId(PUBLIC_CLASSES.storyTile, this.data.publishId);
    const tileIdByPrefix = this.getIdWithPrefixSelector(tileId);
    const tiles = this.getAllElements(tileIdByPrefix);

    for (const [index, tile] of tiles.entries()) {
      if (tile.getAttribute('hasClickEvent')) {
        continue;
      }

      tile?.addEventListener('keypress', event => {
        if (event.code === 'Space' || event.code === 'Enter') {
          this.start(index);
        }
      });

      tile.addEventListener('click', () => {
        this.start(index);
      });
      tile.setAttribute('hasClickEvent', true);
    }
  }

  addOnClickEventToPlusTile() {
    const plusTile = this.getElementByPublicId(PUBLIC_CLASSES.plusTile);
    if (!plusTile) {
      return;
    }

    plusTile.addEventListener('click', () => {
      this.start(this.numberOfTiles);
    });
  }

  getMaxTilesNumber() {
    const {
      storiesItemsSizeType = DEFAULT_STORIES_ITEMS_SIZE_TYPE,
      storiesItemsPerRow = DEFAULT_STORIES_ITEMS_PER_ROW,
      storiesCircleSize = DEFAULT_STORIES_SIZE,
      storiesItemsSpacing = DEFAULT_STORIES_SPACING
    } = this.data.storiesEmbed;

    if (storiesItemsSizeType === STORIES_ITEMS_SIZE_TYPES.responsive) {
      return storiesItemsPerRow;
    }

    const tilesWithSpacingsWidth = this.parentWidth - storiesCircleSize;
    const tileWithSpacing = storiesCircleSize + storiesItemsSpacing;

    const numberOfTilesWithSpacings = Math.floor(tilesWithSpacingsWidth / tileWithSpacing);
    const numberOfTilesWithoutSpacing = 1;

    return numberOfTilesWithSpacings + numberOfTilesWithoutSpacing;
  }

  getCircleSize(numberOfTiles) {
    const {
      storiesItemsSizeType = DEFAULT_STORIES_ITEMS_SIZE_TYPE,
      storiesCircleSize = DEFAULT_STORIES_SIZE,
      storiesItemsSpacing = DEFAULT_STORIES_SPACING
    } = this.data.storiesEmbed;

    if (storiesItemsSizeType === STORIES_ITEMS_SIZE_TYPES.responsive) {
      const itemSize = this.parentWidth / numberOfTiles - storiesItemsSpacing;
      return Math.min(itemSize, STORIES_ITEM_MAX_FIXED_SIZE);
    }

    return storiesCircleSize;
  }

  initTileNumbers() {
    const { storiesEmbed } = this.data;
    const {
      storiesSteps,
      storiesRectangleWidth = DEFAULT_STORIES_SIZE,
      storiesRectangleHeight = DEFAULT_STORIES_SIZE,
      storiesItemsShape,
      storiesItemsSizeType,
      storiesStoryNameType
    } = storiesEmbed;

    const numberOfSteps = storiesSteps.length;
    const maxTilesNumber = this.getMaxTilesNumber();
    const tileSize = this.getCircleSize(maxTilesNumber);

    this.numberOfTiles = Math.min(numberOfSteps, maxTilesNumber);
    const isCircle = storiesItemsShape === 'circle';
    const isResponsive = storiesItemsSizeType === STORIES_ITEMS_SIZE_TYPES.responsive;
    const isTextOverlay = storiesStoryNameType === STORIES_NAME_LOCATION.overlay;
    this.tilesData = {
      numberOfSteps,
      numberOfTiles: this.numberOfTiles,
      tileSize,
      storiesRectangleWidth,
      storiesRectangleHeight,
      isCircle,
      isResponsive,
      isTextOverlay
    };
  }

  addOnErrorEventToImages() {
    const { publishId } = this.data;
    const imageId = getPublicId(PUBLIC_CLASSES.storyImage, publishId);
    const imageIdByPrefix = this.getIdWithPrefixSelector(imageId);
    const images = this.getAllElements(imageIdByPrefix);

    for (const image of images) {
      image.addEventListener('error', async () => {
        if (image.src.includes(WEBP_AVATAR_EXTENSION)) {
          image.src = image.src.replace(WEBP_AVATAR_EXTENSION, JPG_AVATAR_EXTENSION);
        } else if (image.src.includes(JPG_AVATAR_EXTENSION)) {
          image.src = image.src.replace(JPG_AVATAR_EXTENSION, JPG_EXTENSION);
        }

        if (publishId && !this.isCreateResolutionCalled) {
          await reCreateResolutions(publishId);

          this.isCreateResolutionCalled = true;
        }
      });
    }
  }

  addOnErrorEventToVideos() {
    const { publishId } = this.data;
    const videos = document.querySelectorAll(`.${PUBLIC_CLASSES.storyVideo}`);

    for (const video of videos) {
      video.addEventListener('error', async event => {
        const isNotMediaError = event.type === 'error' && event.target.error === null;
        if (isNotMediaError) {
          if (video.poster.includes(WEBP_AVATAR_EXTENSION)) {
            video.poster = video.poster.replace(WEBP_AVATAR_EXTENSION, JPG_AVATAR_EXTENSION);
          } else if (video.poster.includes(JPG_AVATAR_EXTENSION)) {
            video.poster = video.poster.replace(JPG_AVATAR_EXTENSION, JPG_EXTENSION);
          }

          if (publishId && !this.isCreateResolutionCalled) {
            await reCreateResolutions(publishId);

            this.isCreateResolutionCalled = true;
          }
        }
      });
    }
  }

  initSkeleton(element, initialData) {
    const storiesEmbed = {
      storiesSteps: [{}, {}, {}, {}, {}, {}],
      storiesBorderColor: null,
      storiesBorderWidth: null,
      storiesBorder: null,
      storiesPlayButtonOpacity: 0.1,
      storiesBorderRadius: 50,
      storiesTitle: null,
      skeleton: true,
      storiesAlignment: 'left',
      ...initialData,
      storiesMotion: 'static'
    };

    const data = { storiesEmbed, publishId: 'skeleton' };
    this.data = data;
    const {
      parentElement: { clientWidth: parentWidth }
    } = element;

    this.parentWidth = parentWidth;
    this.initTileNumbers();

    element.innerHTML = getHTML(data, parentWidth, this.tilesData);
    this.handleScrollEvents();
  }

  async init(element, currentProductId, variantId) {
    const publishId = getEmbedPublishId(element);
    const productId = currentProductId || getEmbedProductId(element);
    const appUrl = window.Shopify?.shop || getShop();
    const tags = element.dataset?.tags?.split(',')?.filter(Boolean) || '';
    const shouldNotInitialize = !publishId && !productId && !tags?.length;

    window.tolstoyWidget = {
      ...window.tolstoyWidget,
      [publishId]: {
        init: (productId, variantId) => {
          this?.modal?.remove?.();
          this.init(element, productId, variantId);
        }
      }
    };

    if (window.Shopify?.designMode && shouldNotInitialize) {
      this.initSkeleton(element, {
        storiesTitleText: 'Publish ID in the Publish tab of your Tolstoy admin',
        storiesTitleEnabled: true,
        storiesAlignment: 'center'
      });
    }

    if (shouldNotInitialize) {
      this.initialized = false;
      return;
    }

    if (exitEarlyIfNeeded({ element })) {
      return;
    }

    if (window.Shopify?.designMode) {
      this.initSkeleton(element, {
        storiesTitleText: 'Loading...',
        storiesTitleEnabled: false,
        storiesAlignment: 'center'
      });
    }

    try {
      const data = await getConfig({
        publishId,
        productId,
        widgetType: STORY_EMBED_WIDGET,
        tags,
        appUrl,
        variantId
      });

      if (!data || data.disabled) {
        this.initialized = false;
        element.innerHTML = '';
        return;
      }

      this.initElements(element, data, productId);
    } catch (error) {
      if (window.Shopify?.designMode && error.message) {
        this.initSkeleton(element, {
          storiesTitleText: error.message,
          storiesTitleEnabled: true,
          storiesAlignment: 'center'
        });
      }

      logDevelopmentError(error);
      this.initialized = false;
    }
  }

  initElements(element, data, productId) {
    try {
      data.playerType = STORY_EMBED_WIDGET;
      data.productId = productId;

      if (getIsPlayerLazy(data)) {
        data.playerLazy = true;
      }

      this.data = data;
      this.dynamicVideoIndex = getNextNonImageIndex(this.data.storiesEmbed.storiesSteps);

      const {
        parentElement: { clientWidth: parentWidth }
      } = element;

      this.parentWidth = parentWidth;

      this.initTileNumbers();

      element.innerHTML = getHTML(data, parentWidth, this.tilesData);

      if (window.location.origin !== process.env.VITE_BASE_URL) {
        this.modal = new Modal(this.data);
      }

      reloadFontIfNeeded(this.data);
      this.addOnClickEventsToStoryTiles();
      this.addOnClickEventToPlusTile();
      this.addOnErrorEventToImages();
      this.addOnErrorEventToVideos();
      this.handleScrollEvents();
      this.handleMotion(this.data);
      setImpressionByPublishId(data.publishId, false);

      this.initialized = true;
    } catch (error) {
      logDevelopmentError(error);
      this.initialized = false;
    }
  }

  getIsInitialized() {
    return this.initialized;
  }

  handleMotion({ storiesEmbed }) {
    const { storiesMotion } = storiesEmbed;
    if (!storiesMotion || storiesMotion === EMBED_STORY_MOTION.dynamic) {
      this.handleDynamicEvents();
    }
  }

  getEventParams() {
    const params = { currentPageProductId: this.data.productId };

    return params;
  }

  handlePageView() {
    if (this.data.playerLazy) {
      this.modal.sendPageView(this.getEventParams());
    }
  }

  handleView() {
    setImpressionByPublishId(this.data.publishId, true);
    this.modal.sendEmbedView(this.getEventParams());
  }

  handleOpenEvent(data) {
    if (data.eventData?.stopDynamicEmbed) {
      this.stopDynamicVideos();
    }
  }

  modalMessageHandler({ data }) {
    if (data.name !== WIDGETS_MESSAGES) {
      return;
    }

    const handler = {
      [WIDGET_MESSAGES_EVENTS.OPEN]: data => this.handleOpenEvent(data),
      [WIDGET_MESSAGES_EVENTS.CLOSE]: data => this.onViewPortChange(data)
    };

    const eventFunction = handler[data.type];
    eventFunction?.(data);
  }

  handleDynamicEvents() {
    this.onViewPortChange();
    window.addEventListener('load', this.onViewPortChange);
    window.addEventListener('scroll', this.onViewPortChange);
    window.addEventListener('message', this.modalMessageHandler);
  }

  onScroll(event) {
    const element = event.target;
    const currentScroll = element.scrollLeft;
    const OFFSET = 1;
    const scrollMaxWidth = element.scrollWidth - element.clientWidth - OFFSET;

    if (currentScroll) {
      this.getElementByPublicId(PUBLIC_CLASSES.previousButton).dataset.visibility =
        STORIES_ARROWS_VISIBILITY_ATTRIBUTES.visible;
    } else {
      this.getElementByPublicId(PUBLIC_CLASSES.previousButton).dataset.visibility =
        STORIES_ARROWS_VISIBILITY_ATTRIBUTES.hidden;
    }

    if (scrollMaxWidth <= currentScroll) {
      this.getElementByPublicId(PUBLIC_CLASSES.nextButton).dataset.visibility =
        STORIES_ARROWS_VISIBILITY_ATTRIBUTES.hidden;
    } else {
      this.getElementByPublicId(PUBLIC_CLASSES.nextButton).dataset.visibility =
        STORIES_ARROWS_VISIBILITY_ATTRIBUTES.visible;
    }
  }

  scrollToNext() {
    const storiesContainer = this.getElementByPublicId(PUBLIC_CLASSES.tilesContainer);
    const currentScroll = storiesContainer.scrollLeft;
    const scrollMaxWidth = storiesContainer.scrollWidth - storiesContainer.clientWidth;
    let scrollTo = currentScroll + storiesContainer.clientWidth;

    if (currentScroll === scrollMaxWidth) {
      scrollTo = 0;
    }

    if (scrollTo > scrollMaxWidth) {
      scrollTo = scrollMaxWidth;
    }

    storiesContainer.scroll({
      left: scrollTo,
      behavior: 'smooth',
      block: 'nearest'
    });
  }

  scrollToPrevious() {
    const storiesContainer = this.getElementByPublicId(PUBLIC_CLASSES.tilesContainer);
    const currentScroll = storiesContainer.scrollLeft;
    const scrollMaxWidth = storiesContainer.scrollWidth - storiesContainer.clientWidth;
    let scrollTo = currentScroll - storiesContainer.clientWidth;

    if (!currentScroll) {
      scrollTo = scrollMaxWidth;
    }

    if (scrollTo < 0) {
      scrollTo = 0;
    }

    storiesContainer.scroll({
      left: scrollTo,
      block: 'nearest',
      behavior: 'smooth'
    });
  }

  handleScrollEvents() {
    const storiesContainer = this.getElementByPublicId(PUBLIC_CLASSES.tilesContainer);

    if (!storiesContainer) {
      return;
    }

    const nextContainer = this.getElementByPublicId(PUBLIC_CLASSES.nextButton);
    const previousContainer = this.getElementByPublicId(PUBLIC_CLASSES.previousButton);
    const scrollMaxWidth = storiesContainer.scrollWidth - storiesContainer.clientWidth;

    if (scrollMaxWidth <= 1) {
      nextContainer.style.display = 'none';
      previousContainer.style.display = 'none';
      return;
    }

    previousContainer.addEventListener('click', this.scrollToPrevious);
    nextContainer.addEventListener('click', this.scrollToNext);
    storiesContainer.addEventListener('scroll', this.onScroll);
  }

  handleDynamicVideos() {
    const videoPlayingIndex = this.dynamicVideoIndex;
    const videos = getAllElements(PUBLIC_CLASSES.storyVideo, this.data.publishId);
    const video = videos[videoPlayingIndex];

    if (!videos?.length) {
      return;
    }

    const { scrollActiveStoryIntoView } =
      getFeatureSettings({
        config: this.data,
        key: FEATURE_WIDGET_LOADING
      }) || {};
    if (scrollActiveStoryIntoView) {
      video?.scrollIntoViewIfNeeded?.();
    }

    const onVideoError = error => {
      if (error.name !== DOM_EXCEPTION_ERRORS.notAllowedError) {
        throw error;
      }

      if (videoPlayingIndex !== 0) {
        return;
      }

      if (getIsIOS()) {
        this.modal.loadFullPlayer();
      }
    };

    if (!video) {
      this.dynamicVideoIndex = 0;
      this.handleDynamicVideos();
      return;
    }

    if (!video.src) {
      const step = this.data.storiesEmbed.storiesSteps[videoPlayingIndex];
      video.src = getBaseUrl({ step, isStory: true });

      if (getIsIOS()) {
        try {
          video.autoplay = 'true';
        } catch (error) {
          onVideoError(error);
        }

        video.addEventListener('ended', this.onVideoEnd, { once: true });
        return;
      }

      // video.scrollIntoView({ behavior: 'smooth' });
      video.addEventListener(
        'canplay',
        () => {
          safePlayVideo({ video, onError: onVideoError });
        },
        { once: true }
      );
    }

    safePlayVideo({ video, onError: onVideoError });

    video.addEventListener('ended', this.onVideoEnd, { once: true });
  }

  removeWindowEventListeners = () => {
    window.removeEventListener('load', this.onViewPortChange);
    window.removeEventListener('scroll', this.onViewPortChange);
    window.removeEventListener('message', this.modalMessageHandler);
  };

  internalMessagingHandler = ({ data }) => {
    switch (data.eventName) {
      case INTERNAL_EVENTS.urlChange:
        this.removeWindowEventListeners();
        break;
      default:
        return null;
    }
  };

  initInternalMessagingSubscriptions() {
    InternalMessaging.subscribeMultipleEvents({
      eventNames: INTERNAL_MESSAGES_TO_SUBSCRIBE,
      callback: this.internalMessagingHandler
    });
  }
}

export default Story;
