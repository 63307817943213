import React from 'react';

const LightningIcon = () => (
  <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m.5 6.5.656-5.344A.758.758 0 0 1 1.906.5h3.938a.67.67 0 0 1 .656.656c0 .07-.023.14-.047.211L5.375 4.25h3.258a.88.88 0 0 1 .867.867c0 .188-.07.352-.164.492l-4.5 6.586c-.14.188-.375.328-.61.328h-.07c-.375 0-.656-.304-.656-.68 0-.046 0-.116.023-.163l1.102-4.43H1.25A.74.74 0 0 1 .5 6.5Z"
      fill="#B3B6BC"
    />
  </svg>
);

export default LightningIcon;
