import React from 'react';
import { vodAsset as VodAsset, assetType as AssetType } from 'src/types/entities';
import ModalImagePreview from './ModalImagePreview';
import ModalVideoPreview from './ModalVideoPreview';

type Props = {
  asset: VodAsset;
  shouldHide?: boolean;
  thumbnail?: string;
};

export const ModalAssetPreview = ({ asset, shouldHide, thumbnail }: Props) => {
  if (shouldHide || !asset) {
    return null;
  }

  if ([AssetType.image, AssetType.gallery].includes(asset.type)) {
    return <ModalImagePreview image={asset} shouldHide={shouldHide} />;
  }

  return <ModalVideoPreview video={asset} thumbnail={thumbnail} shouldHide={shouldHide} />;
};

export default ModalAssetPreview;
