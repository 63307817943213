import React from 'react';
import { useBilling } from 'app/src/hooks/useBillings';

const ProIcon = ({ fill = '#fff', width = 18, height = 13 }) => {
  const { isPricingEnabled, isEnterprisePackage } = useBilling();

  if (!isPricingEnabled || isEnterprisePackage) {
    return null;
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 4.95h-.9v.9H9c.253 0 .45-.197.45-.45A.463.463 0 0 0 9 4.95Zm-4.5 0h-.928v.9H4.5c.225 0 .45-.197.45-.45a.484.484 0 0 0-.45-.45ZM16.2 0H1.8C.787 0 0 .816 0 1.8v9c0 1.012.787 1.8 1.8 1.8h14.4c.984 0 1.8-.787 1.8-1.8v-9c0-.984-.816-1.8-1.8-1.8ZM4.5 7.2h-.928v1.125c0 .394-.31.675-.675.675a.666.666 0 0 1-.675-.675v-4.05c0-.366.31-.675.675-.675H4.5c.984 0 1.8.816 1.8 1.8 0 1.013-.816 1.8-1.8 1.8Zm6.188.76a.716.716 0 0 1-.197.956.866.866 0 0 1-.366.084.707.707 0 0 1-.59-.281L8.577 7.2H8.1v1.125c0 .394-.31.675-.675.675a.65.65 0 0 1-.675-.675v-4.05c0-.366.281-.675.675-.675H9c.984 0 1.8.816 1.8 1.8 0 .647-.31 1.21-.816 1.519l.704 1.04ZM13.724 9c-1.378 0-2.475-1.21-2.475-2.7 0-1.463 1.097-2.7 2.475-2.7 1.35 0 2.475 1.237 2.475 2.7 0 1.49-1.125 2.7-2.475 2.7Zm0-4.05c-.647 0-1.125.619-1.125 1.35 0 .76.478 1.35 1.125 1.35.619 0 1.125-.59 1.125-1.35 0-.731-.506-1.35-1.125-1.35Z"
        fill={fill}
      />
    </svg>
  );
};

export default ProIcon;
