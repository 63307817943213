import React from 'react';
import styled from 'styled-components';
import BackdropButton from '../../BackdropButton';
import { Gap8HorizontalFlex } from '../../flex_layouts/HorizontalFlex';
import ArrowHorizontalIcon from '../../../../images/feed/ArrowHorizontalIcon';
import { AssetsUtilsService } from '../../../../services/assets';
import useFeedCarousel from '../../context/hooks/useFeedCarousel';

const FeedCarouselNavigation = ({ scrollLeft, scrollRight, step, isFullWidth }) => {
  const isGallery = AssetsUtilsService.isGalleryAsset(step);
  const [carouselState] = useFeedCarousel();
  if (!isGallery || !carouselState?.showCarouselNavigation) return null;

  const { referenceIds, currentAssetIndex, showLeftArrow, showRightArrow, scrollToIndex } =
    carouselState;

  return (
    <LayoutRoot isFullWidth={isFullWidth}>
      <CarouselNavigationContainer>
        <StyledBackdropButton
          onClick={e => scrollLeft(e)}
          isSmall={true}
          $isHidden={!showLeftArrow}
        >
          <ArrowHorizontalIcon flip={true} />
        </StyledBackdropButton>
        <DotsContainer>
          {referenceIds.map((id, index) => (
            <CarouselDot
              key={id}
              $isActive={index === currentAssetIndex}
              onClick={e => scrollToIndex(index, e)}
            ></CarouselDot>
          ))}
        </DotsContainer>
        <StyledBackdropButton
          onClick={e => scrollRight(e)}
          isSmall={true}
          $isHidden={!showRightArrow}
        >
          <ArrowHorizontalIcon />
        </StyledBackdropButton>
      </CarouselNavigationContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlex)`
  justify-content: center;
  flex-basis: 100%;
  flex-grow: 2;
  z-index: 12;
  ${({ isFullWidth }) => (isFullWidth ? 'width: 100%;' : '')}
  div > svg {
    width: 16px;
    height: 16px;
  }
`;

const CarouselNavigationContainer = styled(Gap8HorizontalFlex)`
  margin: 4px;
  box-sizing: border-box;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    gap: 8px;
  }
`;

const DotsContainer = styled(Gap8HorizontalFlex)`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.getHexOpacity(theme.colors.black, 20)};
  border-radius: 16px;
  padding: 0 12px;
  backdrop-filter: blur(10px);
  transition: 0.3s;
  width: 100%;
  height: 32px;
  &:hover {
    background: ${({ theme }) => theme.getHexOpacity(theme.colors.black, 30)};
  }
`;

const CarouselDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.white};
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0.5)};
  cursor: pointer;
  pointer-events: auto;

  &:hover {
    opacity: 1;
  }
`;
const StyledBackdropButton = styled(BackdropButton)`
  opacity: ${({ $isHidden }) => ($isHidden ? 0 : '')};
`;
export default FeedCarouselNavigation;
