import React from 'react';
import styled from 'styled-components';

import ModalWrapper from 'shared/react/components/basic/ModalWrapper';
import useFeedModal from 'shared/react/components/complex/context/hooks/useFeedModal';
import FeedSearchInput from 'shared/react/components/complex/feed/feed_search/FeedSearchInput';
import ArrowIcon from 'shared/react/images/feed/ArrowIcon';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import { Gap16HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import FeedSearchContent from 'shared/react/components/complex/feed/feed_search/FeedSearchContent';
import useFeedSearchActions from 'shared/react/components/complex/feed/feed_search/hooks/useFeedSearchActions';
import useFeedProductSearch from 'shared/react/components/complex/feed/feed_search/hooks/useFeedProductSearch';
import { useFeedIsMobile } from 'shared/react/hooks/useIsMobile';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';

const FeedSearchModal = () => {
  const isMobile = useFeedIsMobile();
  const [{ isShoppable }] = useProjectConfig();
  const [{ currentModal }] = useFeedModal();
  const { currentStepKey } = currentModal.props;
  const [
    { videos, trendingVideos, isOpen, isLoading, searchTerm },
    { setSearchTerm, handleCloseModal, getVideosBySearchTerm, handleVideoClick, setIsOpen },
  ] = useFeedSearchActions();
  const vodAssetIds = videos?.map(video => video.id);
  const { products, getProductsBySearchTerm } = useFeedProductSearch({ vodAssetIds });

  const handleSearchInputSubmit = searchTerm => {
    getVideosBySearchTerm(searchTerm);

    if (isShoppable) {
      getProductsBySearchTerm(searchTerm);
    }
  };

  return (
    <StyledModalWrapper
      disableAnimation
      isMobile={isMobile}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={handleCloseModal}
    >
      <ContentWrapper>
        <TopContainer>
          <BackButton onClick={handleCloseModal}>
            <StyledArrowIcon />
          </BackButton>

          <InputWrapper>
            <FeedSearchInput
              isMobile={isMobile}
              onSubmit={handleSearchInputSubmit}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          </InputWrapper>
        </TopContainer>

        <FeedSearchContent
          searchTerm={searchTerm}
          currentStepKey={currentStepKey}
          isMobile={isMobile}
          videos={videos}
          products={products}
          trendingVideos={trendingVideos}
          onVideoClick={handleVideoClick}
          isLoading={isLoading}
        />
      </ContentWrapper>
    </StyledModalWrapper>
  );
};

const StyledModalWrapper = styled(ModalWrapper)`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  box-sizing: border-box;
`;

const ContentWrapper = styled(Gap16VerticalFlex)`
  height: 100%;
  max-width: 600px;
  margin: auto;
`;

const TopContainer = styled(Gap16HorizontalFlex)`
  padding: 20px 8px 8px 8px;
  align-items: center;
`;

const BackButton = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const StyledArrowIcon = styled(ArrowIcon)`
  transform: rotate(-90deg);

  & path {
    fill: ${({ theme }) => theme.colors.black};
  }
`;

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export default FeedSearchModal;
