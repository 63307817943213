import { ASSET_TYPE } from './constants';
import { ImageRenditionsService } from './images';
import { VideoRenditionsService } from './videos';

export const AssetRenditionsService = {
  getAssetPoster: asset => {
    if (!asset) {
      return;
    }

    switch (asset.type) {
      case ASSET_TYPE.IMAGE:
        return ImageRenditionsService.getRenditionUrl(asset);
      case ASSET_TYPE.GALLERY:
        return ImageRenditionsService.getRenditionUrl(asset);
      default:
      case ASSET_TYPE.VIDEO:
        return VideoRenditionsService.getVideoPoster(asset);
    }
  },
  getAssetPosterLarge: asset => {
    if (!asset) {
      return;
    }
    switch (asset.type) {
      case ASSET_TYPE.IMAGE:
        return ImageRenditionsService.getRenditionUrl(asset);
      case ASSET_TYPE.GALLERY:
        return ImageRenditionsService.getRenditionUrl(asset);
      default:
      case ASSET_TYPE.VIDEO:
        return VideoRenditionsService.getVideoPosterLarge(asset);
    }
  },
};

export default AssetRenditionsService;
