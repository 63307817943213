import React, { useState } from 'react';
import {
  Label,
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from '@tolstoy/ui-library/core';
import styled from 'styled-components';
import { DesignSizes } from 'src/types/design-system';
import Utils from 'src/utils';
import { intercomShow } from 'src/utils/intercom.utils';
import {
  PrimaryButton,
  SecondaryButton,
} from 'shared/react/components/basic/button-v2/BasicButton';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { PublishModalBaseCss } from 'app/src/pages/project/components/project-top-bar/components/set-live-button/css';

enum EmailTool {
  KLAVIYO = 'klaviyo',
  ATTENTIVE = 'attentive',
}

const emailTools = [
  {
    label: 'Klaviyo',
    value: EmailTool.KLAVIYO,
  },
  {
    label: 'Attentive',
    value: EmailTool.ATTENTIVE,
  },
];

const Share = ({ project }) => {
  const [selectedEmailToo, selectEmailTool] = useState<EmailTool>(emailTools[0].value);
  const copyLink = (): void => {
    const getQueryParams = (emailTool: EmailTool) => {
      switch (emailTool) {
        case EmailTool.KLAVIYO:
          return 'email={{email}}';
        case EmailTool.ATTENTIVE:
          return 'email={{subscriber.email}}';
        default:
          throw new Error('Unknown email tool');
      }
    };

    const videoCollectionLandingPageLink = `https://d2t4nmbkd63vhg.cloudfront.net/${project.publishId}?${getQueryParams(selectedEmailToo)}`;
    Utils.copyToClipboard(videoCollectionLandingPageLink);
  };

  return (
    <Layout>
      <div className="grid gap-4 pb-6">
        <h2 className="text-lg font-semibold">Link landing page to email</h2>
        <div className="grid max-w-60 gap-2">
          <Label htmlFor="tool">Select your email tool</Label>
          <Select
            value={selectedEmailToo}
            onValueChange={value => {
              selectEmailTool(value as EmailTool);
            }}
          >
            <SelectTrigger>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {emailTools.map(({ label, value }) => (
                <SelectItem key={value} value={value}>
                  {label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="grid gap-2">
          <Label>How to</Label>
          <p>Copy the link and hyperlink one or more elements in your email.</p>
        </div>
      </div>
      <ActionsContainer>
        <SecondaryButton size={DesignSizes.SMALL} onClick={intercomShow}>
          ?
        </SecondaryButton>
        <PrimaryButton size={DesignSizes.SMALL} onClick={copyLink}>
          Copy link
        </PrimaryButton>
      </ActionsContainer>
    </Layout>
  );
};

const Layout = styled.div`
  ${PublishModalBaseCss};
  top: 58px;
  width: 550px;
  max-height: calc(100vh - 128px);
  overflow: hidden;
  background: ${({ theme }) => theme.colors.neutralPale};
  z-index: 50;
`;

const ActionsContainer = styled(Gap8HorizontalFlex)`
  justify-content: flex-end;
  background: ${({ theme }) => theme.colors.white};
  margin: 0 -16px -16px -16px;
  padding: 8px 16px;
  box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.05);
`;

export default Share;
