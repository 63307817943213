import React from 'react';
import styled from 'styled-components';
import { PLAYER_TYPES } from 'shared/react/constants/tolstoy.constants';
import useProductRecommendations from 'shared/react/components/complex/context/hooks/useProductRecommendations';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';
import useTracker from 'shared/react/components/complex/context/hooks/useTracker';
import ImageWithLoader from 'shared/react/components/complex/ImageWithLoader';
import CarouselSlider from 'shared/react/components/complex/carousel_slider/CarouselSlider';
import { BolderTextSmall, LightTextSmall, TextH6 } from 'shared/react/components/complex/Text';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import Utils from 'shared/react/utils/utils';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';
import {
  FEED_RECOMMENDED_PRODUCTS_NAME_DATA_TYPE,
  RECOMMENDED_PRODUCT_PRICE_DATA_TYPE,
  RECOMMENDED_PRODUCTS_TITLE_DATA_TYPE
} from '../../../../constants/feedPreviewDataTypes.constants';
import useTranslation from '../../context/hooks/useTranslation';
import useKeyboardNavigation from 'player/src/hooks/useKeyboardNavigation';
import FeedProductPriceDisplay from './FeedProductPriceDisplay';

const FeedProductRecommendations = ({ product, isHorizontal }) => {
  const [, { t }] = useTranslation('feed.products');
  const [, { track }] = useTracker();
  const [{ playerSettings, appUrl }] = useProjectConfig();
  const { onKeyDown, setNextRef } = useKeyboardNavigation();
  const [{ productRecommendationsMap }] = useProductRecommendations();
  const productRecommendations = productRecommendationsMap[product.id];
  const { player } = useDesignSettingsContext();
  const { headerColorText, bodyColorText } = player.feedCards.text;

  const {
    productDetailsRecommendedProductsEnabled: enabled,
    productDetailsRecommendedProductsTitle: title,
    productDetailsRecommendedProductsNameEnabled: showProductName,
    productDetailsRecommendedProductsPriceEnabled: showProductPrice,
  } = playerSettings || {};

  const onClickRecommnededProduct = ({ url }) => {
    const siteUrl = Utils.getSiteUrl() || appUrl;
    const productUrl = `${siteUrl}${url}`;
    Utils.openInNewTab(productUrl);
  };

  const handleRenderItem = ({ imageUrl, title, price, url }) => {
    const displayedPrice = Utils.getDisplayedPriceSegments({
      price: price / 100,
      currencySymbol: product.currencySymbol,
    });

    return (
      <RecommendedProductContainer
        onClick={() => onClickRecommnededProduct({ url })}
        role="button"
        aria-label={`View recommended product: ${title}`}
        ref={setNextRef}
        onKeyDown={onKeyDown}
        tabIndex="0"
      >
        <StyledImageWithLoader src={imageUrl} />
        {(showProductName || showProductPrice) && (
          <RecommendedProductDetails>
            {showProductName && (
              <ProductName data-type={FEED_RECOMMENDED_PRODUCTS_NAME_DATA_TYPE}>
                {title}
              </ProductName>
            )}
            {showProductPrice && (
              <ProductPrice data-type={RECOMMENDED_PRODUCT_PRICE_DATA_TYPE}>
                <FeedProductPriceDisplay price={displayedPrice}/>
              </ProductPrice>
            )}
          </RecommendedProductDetails>
        )}
      </RecommendedProductContainer>
    );
  };

  const handleArrowClick = direction => {
    track('imageCarouselArrowClick', {
      ...Utils.getAnalyticsLocationObject(PLAYER_TYPES.feed),
      direction,
    });
  };

  if (!enabled || !productRecommendations?.length) {
    return null;
  }

  return (
    <LayoutRoot bodyColorText={bodyColorText} headerColorText={headerColorText}>
      <StyledTitle data-type={RECOMMENDED_PRODUCTS_TITLE_DATA_TYPE}>
        {title || t('recommendedProducts')}
      </StyledTitle>
      <StyledCarousel
        items={productRecommendations}
        onArrowClick={handleArrowClick}
        renderItem={handleRenderItem}
        isHorizontal={isHorizontal}
        itemsPerPage={2.5}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)`
  text-align: left;
  direction: ltr;

  & ${Utils.getDataType(FEED_RECOMMENDED_PRODUCTS_NAME_DATA_TYPE)} {
    color: ${({ headerColorText, theme }) => headerColorText || theme.colors.black};
  }

  & ${Utils.getDataType(RECOMMENDED_PRODUCTS_TITLE_DATA_TYPE)} {
    color: ${({ headerColorText, theme }) => headerColorText || theme.colors.black};
  }

  & ${Utils.getDataType(RECOMMENDED_PRODUCT_PRICE_DATA_TYPE)} {
    color: ${({ bodyColorText, theme }) => bodyColorText || theme.colors.black};
  }
`;

const StyledTitle = styled(TextH6)`
  padding: 0 24px;
`;

const StyledCarousel = styled(CarouselSlider)`
  width: ${({ isHorizontal }) => (isHorizontal ? '100%' : '')};
`;

const ProductName = styled(BolderTextSmall)``;

const ProductPrice = styled(LightTextSmall)``;

const RecommendedProductContainer = styled(Gap8VerticalFlex)`
  overflow: hidden;
  cursor: pointer;

  &:hover ${ProductName} {
    text-decoration: underline;
  }
`;

const StyledImageWithLoader = styled(ImageWithLoader)`
  & img {
    height: 100%;
    width: 100%;
    aspect-ratio: 1;
    object-fit: contain;
    border-radius: 0;
  }
`;

const RecommendedProductDetails = styled(VerticalFlex)`
  gap: 4px;
  padding: 0 16px;
`;

export default FeedProductRecommendations;
