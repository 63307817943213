import {
  PlayMode,
  ScrollMode,
  MainTilePosition,
  SpotlightSizingMode,
  SpotlightDeviceSettings
} from '../types';

export const DEFAULT_SPOTLIGHT_SETTINGS_DESKTOP: SpotlightDeviceSettings = {
  marginX: 0,
  sizingMode: SpotlightSizingMode.Fixed,
  mainTilePosition: MainTilePosition.Center,
  playMode: PlayMode.Fixed,
  shouldShowArrows: true,
  scrollMode: ScrollMode.Infinite,

  // default values for responsive
  tilesAmount: 4,
  peekWidth: 0,
  aspectRatioW: 9, // for responsive, width
  aspectRatioH: 16, // for responsive, height
 
  video: {
    width: 300,
    height: 388
  },
  centerVideo: {
    width: 300,
    height: 500
  },
  product: {
    height: 80
  },
  item: {
    paddingY: 0,
    paddingX: 8
  },
  centerItem: {
    paddingY: 0,
    paddingX: 8
  },

  controls: {
    play: {
      enabled: false,
      backgroundEnabled: true,
      backgroundColor: '#0000004d',
      borderEnabled: false,
      opacity: 1,
    },
    mute: {
      enabled: true,
      backgroundEnabled: true,
      backgroundColor: '#0000004d',
      borderEnabled: false,
      opacity: 1,
    },
  },
};

export const DEFAULT_SPOTLIGHT_SETTINGS_MOBILE: SpotlightDeviceSettings = {
  marginX: 0,
  sizingMode: SpotlightSizingMode.Fixed,
  mainTilePosition: MainTilePosition.Center,
  playMode: PlayMode.Fixed,
  shouldShowArrows: false,
  scrollMode: ScrollMode.Infinite,

  // default values for responsive
  tilesAmount: 1,
  peekWidth: 20,
  aspectRatioW: 9, // for responsive, width
  aspectRatioH: 16, // for responsive, height

  video: {
    width: 250,
    height: 388
  },
  centerVideo: {
    width: 250,
    height: 388
  },
  product: {
    height: 80
  },
  item: {
    paddingY: 0,
    paddingX: 6
  },
  centerItem: {
    paddingY: 0,
    paddingX: 6
  },

  controls: {
    play: {
      enabled: false,
      backgroundEnabled: true,
      backgroundColor: '#0000004d',
      borderEnabled: false,
      opacity: 1,
    },
    mute: {
      enabled: true,
      backgroundEnabled: true,
      backgroundColor: '#0000004d',
      borderEnabled: false,
      opacity: 1,
    },
  },
};
