import React from 'react';
import { PAGE_TYPES } from 'app/src/constants/publish.constants';
import ButtonGroup from 'app/src/complex_components/button-group/ButtonGroup';

const EmbedCategories = ({ currentPage, setCurrentPage }) => {
  const buttons = [
    {
      text: 'Product page',
      isEnabled: currentPage === PAGE_TYPES.product,
      onClick: () => setCurrentPage(PAGE_TYPES.product),
    },
    {
      text: 'Other page',
      isEnabled: currentPage === PAGE_TYPES.default,
      onClick: () => setCurrentPage(PAGE_TYPES.default),
    },
  ];

  return <ButtonGroup buttons={buttons} />;
};

export default EmbedCategories;
