import React from 'react';
import styled from 'styled-components';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';

export const AnalyticsFilterLabel = ({ children, ...other }: React.HTMLProps<HTMLLabelElement>) => {
  if (!children) {
    return null;
  }

  return (
    <LayoutRoot as="label" {...other}>
      {children}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralDark};
`;
