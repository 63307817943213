import React from 'react';

const FentyStarEmpty = ({ color = 'black', opacity = 1 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
    <path
      d="m11.688 5.125 3.906.688 1.812.312-1.281 1.313-2.75 2.843.563 3.938.25 1.781-1.626-.781L9 13.469l-3.594 1.75L3.781 16l.281-1.781.563-3.938-2.781-2.844L.563 6.126l1.812-.312 3.906-.688 1.844-3.5L9 0l.844 1.625zm2.656 2-2.906-.5-.72-.125-.343-.656L9 3.219 7.625 5.844l-.344.656-.75.125-2.906.5L5.688 9.25l.53.5-.124.75-.407 2.906 2.625-1.281.688-.344.656.344 2.656 1.281-.437-2.906-.094-.75.5-.5z"
      fill={color}
      opacity={opacity}
    />
  </svg>
);

export default FentyStarEmpty;
