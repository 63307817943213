import React, { useState } from 'react';
import { Menu } from '@material-ui/core';
import styled from 'styled-components';
import MenuItem from './MenuItem';

/* DEPRECATED
 * Please use BasicMenuV2
 */

export default function BasicMenu({
  menuItems,
  selectedMenuItemKey,
  children,
  onMenuItemClick,
  customFooter,
  menuItemCustomPadding,
  allowSingleItem,
  isMenuOpen: parentIsMenuOpen,
  setIsMenuOpen: parentSetMenuOpen,
}) {
  const [anchorRef, setAnchorRef] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const hasMenuItems = menuItems?.length > (allowSingleItem ? 0 : 1);

  const changeOpenState = value => {
    if (parentSetMenuOpen) {
      parentSetMenuOpen(value);
    } else {
      setIsMenuOpen(value);
    }
  };

  const openMenu = () => {
    changeOpenState(true);
  };

  const closeMenu = () => {
    changeOpenState(false);
  };

  function handleMenuClick(e) {
    e.stopPropagation();
    if (!hasMenuItems) {
      return;
    }
    setAnchorRef(e.currentTarget);
    openMenu();
  }

  function handleCloseMenu(e) {
    e.stopPropagation();
    closeMenu();
  }

  function handleMenuItemClick(e, menuItem) {
    if (menuItem.disabled) {
      openMenu();
      return;
    }
    e.stopPropagation();
    closeMenu();
    onMenuItemClick(menuItem);
  }

  const open = parentSetMenuOpen ? parentIsMenuOpen : isMenuOpen;

  return (
    <FullHeightContainer>
      <FullHeightContainer onClick={handleMenuClick}>{children}</FullHeightContainer>
      <StyledMenu open={open} anchorEl={anchorRef} onClose={handleCloseMenu} disableAutoFocusItem>
        {hasMenuItems &&
          menuItems.map(menuItem => (
            <MenuItem
              menuItem={menuItem}
              onClose={handleCloseMenu}
              key={menuItem.key}
              selected={menuItem.key === selectedMenuItemKey}
              onClick={e => handleMenuItemClick(e, menuItem)}
              customPadding={menuItemCustomPadding}
            >
              {menuItem.title}
            </MenuItem>
          ))}
        {customFooter && <div onClick={() => setIsMenuOpen(false)}>{customFooter}</div>}
      </StyledMenu>
    </FullHeightContainer>
  );
}

const StyledMenu = styled(Menu)`
  cursor: default;
`;

const FullHeightContainer = styled.div`
  height: 100%;
`;
