import React from 'react';

const BoldIcon = ({ fill = '#7D8087' }) => {
  return (
    <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.375 5.313a3.09 3.09 0 0 0 .727-1.97A3.106 3.106 0 0 0 6.008.25h-4.57a.925.925 0 0 0-.938.938c0 .539.398.937.938.937h.187v6.75h-.188a.925.925 0 0 0-.937.938c0 .539.398.937.938.937h4.968A3.106 3.106 0 0 0 9.5 7.656a3.05 3.05 0 0 0-1.125-2.343ZM3.5 2.124h2.508c.656 0 1.219.563 1.219 1.219 0 .68-.563 1.219-1.22 1.219H3.5V2.124Zm2.906 6.75H3.5V6.437h2.906c.657 0 1.219.563 1.219 1.22 0 .679-.563 1.218-1.219 1.218Z"
        fill={fill}
      />
    </svg>
  );
};

export default BoldIcon;
