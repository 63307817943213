import React from 'react';
import styled from 'styled-components';
import Utils from 'shared/react/utils/utils';
import LineClampWrapper from 'shared/react/components/basic/LineClampWrapper';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

type Props = {
  url: string;
  text: string;
  isDisabled?: boolean;
};

const FormattedLink = ({ url, text, isDisabled, ...props }: Props) => {
  const onClick = isDisabled ? undefined : () => Utils.openInNewTab(url);

  return (
    <LayoutRoot onClick={onClick} isDisabled={isDisabled} {...props}>
      <LineClampWrapper>{text}</LineClampWrapper>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  line-height: 16px;
  transition: color 0.2s ease-in-out;
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};

  &:hover {
    color: ${({ theme, isDisabled }) => (isDisabled ? '' : theme.colors.primary)};
  }
`;

export default FormattedLink;
