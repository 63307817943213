import React from 'react';
import styled from 'styled-components';
import HorizontalFlexWrap from './HorizontalFlexWrap';

function Gap16HorizontalFlexWrap({ children, ...props }) {
  return <LayoutRoot {...props}>{children}</LayoutRoot>;
}

export default Gap16HorizontalFlexWrap;

const LayoutRoot = styled(HorizontalFlexWrap)`
  gap: 16px;
`;
