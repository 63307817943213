import React, { useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { useApps } from 'app/src/context/AppsStore';
import { useProducts } from 'app/src/context/ProductsStore';
import { getIsFeedStepShoppable } from 'app/src/context/ProjectsStore';
import { track } from 'app/src/helpers/Tracker';
import LinkIcon from 'shared/react/images/share/LinkIcon';
import { safeScrollIntoViewIfNeeded } from 'app/src/utils/browser.utils';
import { BolderTextBody } from 'shared/react/components/complex/Text';
import HorizontalFlex, {
  HorizontalFlexCentered,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import SearchProductsDisplay from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/products/SearchProductsDisplay';
import EmptyState from '../EmptyState';
import AnswerEdit from './AnswerEdit';

const ReviewFeedPart = ({ project, step, selectedStore, alreadyReviewed }) => {
  const [products, setProducts] = useState(step.products);
  const [productCtaType, setProductCtaType] = useState(step.productCtaType);
  const [productActionButtonLink, setProductActionButtonLink] = useState(
    step.feedProductActionButton?.value
  );
  const [, { createProducts }] = useProducts();
  const ref = useRef();
  const [{ apps: storeApps }] = useApps();
  const theme = useTheme();
  const isShoppable = getIsFeedStepShoppable(step);
  const hasLink = step.feedProductActionButton?.enabled;

  const updateCtaType = type => {
    step.productCtaType = type;
    setProductCtaType(type);
  };

  const updateProducts = products => {
    step.products = products;
    setProducts(products);
  };

  const updateLinkUrl = value => {
    const updatedAction = { ...step.feedProductActionButton, value };
    step.feedProductActionButton = updatedAction;
    setProductActionButtonLink(value);
  };

  const getSelectedApp = () => {
    return storeApps?.find(app => app.appUrl === selectedStore.id);
  };

  const handleRemoveProduct = product => {
    const { id, productId } = product;
    track('Products Section Product Removed', {
      id,
      productId,
    });

    setProducts(products.filter(({ productId }) => productId !== product.productId));
  };

  const handleSearchProductSelect = async ({ productId }) => {
    track('Products Section Product Added', {
      productId,
    });

    const productIsAlreadyChosen = !!products.find(product => product.productId === productId);
    if (productIsAlreadyChosen || !selectedStore) {
      return;
    }

    const [createdProduct] = await createProducts([productId], selectedStore.id);

    updateProducts([...products, createdProduct]);
    setTimeout(() => {
      safeScrollIntoViewIfNeeded(ref.current);
    }, 0);
  };

  useEffect(() => {
    if (alreadyReviewed) {
      setProducts(step.products);
    } else {
      step.productsAppUrl = selectedStore.id;
      setProducts([]);
      step.products = [];
    }

    setProductCtaType(step.productCtaType);
    setProductActionButtonLink(step.feedProductActionButton?.value || '');
  }, [step]);

  if (!isShoppable && !hasLink) {
    return <EmptyState />;
  }

  return (
    <LayoutRoot>
      {isShoppable && (
        <>
          <EditSectionHeaders>Products</EditSectionHeaders>
          <SearchProductsDisplay
            isLoadingProducts={false}
            showSearchContainer
            products={products}
            selectedApp={getSelectedApp()}
            handleSearchProductSelect={handleSearchProductSelect}
            handleHasNoPermissions={() => {}}
            handleRemoveProduct={handleRemoveProduct}
            project={project}
            scrollAnchorRef={ref}
            productCtaType={productCtaType}
            setProductCtaType={updateCtaType}
          />
        </>
      )}
      {hasLink && (
        <>
          <EditSectionHeaders>Link button</EditSectionHeaders>
          <AnswerLayout>
            <AnswerHeader>
              <LinkIconCircle>
                <LinkIcon fill={theme.colors.white} width={12} />
              </LinkIconCircle>
              <LinkHeader>{step.feedProductActionButton.text}</LinkHeader>
            </AnswerHeader>
            <AnswerEdit value={productActionButtonLink || ''} updateUrl={updateLinkUrl} />
          </AnswerLayout>
        </>
      )}
    </LayoutRoot>
  );
};

export default ReviewFeedPart;

const LayoutRoot = styled(Gap16VerticalFlex)``;

const AnswerHeader = styled(HorizontalFlex)`
  align-items: center;
  gap: 12px;
`;

const AnswerLayout = styled(VerticalFlex)`
  gap: 20px;
`;

const LinkIconCircle = styled(HorizontalFlexCentered)`
  background: ${({ theme }) => theme.colors.black};
  width: 24px;
  height: 24px;
  border-radius: 100px;
`;

const LinkHeader = styled(BolderTextBody)`
  color: ${({ theme }) => theme.colors.gray2};
`;

const EditSectionHeaders = styled(BolderTextBody)`
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  line-height: 16px;
`;
