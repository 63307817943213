import React from 'react';
import styled, { useTheme } from 'styled-components';
import Trash from 'app/src/images/Trash';
import IconButton from 'app/src/complex_components/IconButton';
import { PRODUCT_SEARCH_RESULT_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import ImageWithFallback from 'shared/react/images/ImageWithFallback';
import LineClampWrapper from 'shared/react/components/basic/LineClampWrapper';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { CheckboxV2 } from 'app/src/complex_components/Checkbox';
import HorizontalFlex, {
  Gap8HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import Tooltip from 'app/src/basic_components/Tooltip';
import { TextXTiny, TextTiny } from 'shared/react/components/basic/text/TextV2';
import { formatHandle } from 'app/src/utils/shopify.utils';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import { FEATURE_SHOW_PRODUCT_HANDLE } from 'app/src/constants/appFeatures.constants';

const MenuProduct = ({
  imageUrl,
  title,
  onClick,
  onRemove,
  isSelected = false,
  isSingleSelect,
  disabled,
  disabledTooltipText,
  vodConnectionsCount = undefined,
  handle,
}) => {
  const { getFeatureEnabled } = useFeatureActions();
  const theme = useTheme();
  const isShowProductHandleEnabled = getFeatureEnabled(FEATURE_SHOW_PRODUCT_HANDLE);

  const onProductClick = () => {
    if (disabled) {
      return;
    }

    onClick();
  };

  return (
    <Tooltip text={disabled && disabledTooltipText} placement="right">
      <Container
        disabled={disabled}
        onClick={onProductClick}
        data-test-id={PRODUCT_SEARCH_RESULT_TEST_ID}
      >
        {!isSingleSelect && <CheckboxV2 checked={!!isSelected} />}
        <Image src={imageUrl} width={40} height={40} borderRadius={2} />
        <VerticalFlex>
          <LineClampWrapper numberOfLines={2}>
            <Title title={title}>{title}</Title>
          </LineClampWrapper>
          {isShowProductHandleEnabled && <Subtitle>{formatHandle(handle)}</Subtitle>}
        </VerticalFlex>
        {vodConnectionsCount && (
          <Counter>
            <CounterText>{vodConnectionsCount}</CounterText>
          </Counter>
        )}
        <EndContainer>
          {onRemove && (
            <DeleteContainer onClick={onRemove}>
              <Trash fill={theme.colors.ghostDark} width={14} height={16} />
            </DeleteContainer>
          )}
        </EndContainer>
      </Container>
    </Tooltip>
  );
};

const Container = styled(Gap8HorizontalFlex)`
  color: ${({ theme }) => theme.colors.white};
  align-items: center;
  transition: 0.3s;
  cursor: pointer;
  height: 100%;
  padding: 0px 12px;
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  .MuiButtonBase-root {
    padding: 0;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.neutralLightest};
  }
`;

const Counter = styled(HorizontalFlex)`
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.neutralLightest};
  margin-left: auto;
`;

const CounterText = styled(TextTiny)`
  font-weight: 500;
`;

const Image = styled(ImageWithFallback)`
  overflow: hidden;
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 2px;
`;

const Title = styled(TextTiny)`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.black};
`;

const Subtitle = styled(TextXTiny)`
  color: ${({ theme }) => theme.colors.neutralDark};
`;

const EndContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DeleteContainer = styled(IconButton)`
  &:hover,
  &:focus,
  &:active {
    background: #e6e6e6;
  }

  margin-left: 8px;
  padding: 14px;
`;

export default MenuProduct;
