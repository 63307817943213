import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ShareModal from './ShareModal';
import ShareSnackbar from './ShareSnackbar';
import { track } from '../../tracker/Tracker';
import GhostButton from 'shared/react/components/basic/button/button/GhostButton';
import ArrowBottomToRightIcon from 'player/src/assets/images/ArrowBottomToRightIcon';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';

const Share = ({ publishId }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const buttonRef = useRef();
  const [, { t }] = useTranslation('interactive.landingPage');

  const closeModal = e => {
    if (buttonRef.current.contains(e.target)) {
      return;
    }

    toggleModal();
  };

  const toggleModal = () => {
    if (!modalOpen) {
      track('Share Modal Opened');
    }
    setModalOpen(!modalOpen);
  };

  useEffect(() => {
    if (snackBarOpen) {
      setTimeout(() => {
        setSnackBarOpen(false);
      }, 2000);
    }
  }, [snackBarOpen]);

  return (
    <>
      <ShareSnackbar
        text="Link was copied successfully"
        open={snackBarOpen}
        closeSnackbar={() => setSnackBarOpen(false)}
      />
      <ShareContainer>
        <Button ref={buttonRef} onClick={toggleModal}>
          <StyledShareButton>
            <ArrowBottomToRightIcon /> {t('share')}
          </StyledShareButton>
        </Button>
        {modalOpen && (
          <ShareModal
            setSnackBarOpen={setSnackBarOpen}
            buttonRef={buttonRef}
            closeModal={closeModal}
            publishId={publishId}
          />
        )}
      </ShareContainer>
    </>
  );
};

const ShareContainer = styled.div``;

const Button = styled.div`
  cursor: pointer;
  max-height: 40px;
`;

const StyledShareButton = styled(GhostButton)`
  font-weight: 400;
  height: 40px;
  width: 104px;
  gap: 4px;
`;

export default Share;
