import type { ResultSet } from '@cubejs-client/core';
import Utils from 'src/utils';
import { CUBE_PROPERTY_KEYS } from 'app/src/constants/cube.constants';
import { EVENTS } from 'src/constants/tolstoy.constants';
import { PLAYER_USER_LOG } from 'app/src/constants/analytics.constants';
import {
  ProductClicksOvertimeRecord,
  BaseProductClickMetrics,
} from './types/getProductClicksOvertime.types';

const DEFAULT_METRICS: BaseProductClickMetrics = {
  numOfProductClicks: 0,
  numOfSessionStarts: 0,
  productCTR: 0,
};

const getPlayerTypes = (rawProductClicksOvertime: ResultSet): string[] => {
  const playerTypeKey = `${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.playerType}`;
  const playerTypes = rawProductClicksOvertime.tablePivot()?.map(data => data[playerTypeKey] || '');

  return Array.from(new Set(playerTypes)) as string[];
};

const getEventsCount = (record: Record<string, unknown>, playerType: string, event: string) => {
  const value = playerType
    ? +record[`${playerType},${event},${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.count}`]
    : +record[`${event},${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.count}`];

  return Number.isFinite(value) ? value : 0;
};

const groupEventsByPlayerType = (row: Record<string, unknown>, playerTypes: string[]) => {
  return playerTypes.reduce(
    (acc, playerType) => {
      const numOfSessionStarts = getEventsCount(row, playerType, EVENTS.sessionStart);
      const numOfProductPageClicks = getEventsCount(row, playerType, EVENTS.openProductPageClick);
      const numOfProductViewClicks = getEventsCount(row, playerType, EVENTS.clickViewProduct);
      const numOfFeedProductModalOpens = getEventsCount(
        row,
        playerType,
        EVENTS.feedProductModalOpen
      );
      const numOfProductClicks =
        numOfProductPageClicks + numOfProductViewClicks + numOfFeedProductModalOpens;

      const totalNumOfSessionStarts = acc.numOfSessionStarts + numOfSessionStarts;
      const totalNumOfProductClicks = acc.numOfProductClicks + numOfProductClicks;

      const productCTR = Utils.getPercentage(numOfProductClicks, numOfSessionStarts, false, 2);
      const totalProductCTR = Utils.getPercentage(
        totalNumOfProductClicks,
        totalNumOfSessionStarts,
        false,
        2
      );

      acc.numOfProductClicks = totalNumOfProductClicks;
      acc.numOfSessionStarts = totalNumOfSessionStarts;
      acc.productCTR = totalProductCTR;
      acc.metricsByPlayerType[playerType] = {
        numOfProductClicks,
        numOfSessionStarts,
        playerType,
        productCTR,
      };

      return acc;
    },
    {
      ...DEFAULT_METRICS,
      metricsByPlayerType: {},
    } as ProductClicksOvertimeRecord
  );
};

export const getProductClicksOvertime = (
  rawProductClicksOvertime: ResultSet
): ProductClicksOvertimeRecord[] => {
  const playerTypes = getPlayerTypes(rawProductClicksOvertime);

  return rawProductClicksOvertime?.chartPivot()?.map(({ x, xValues, ...data }) => {
    return {
      x,
      xValues,
      ...groupEventsByPlayerType(data, playerTypes),
    } as ProductClicksOvertimeRecord;
  });
};
