import * as React from 'react';

const OpenLinkIcon = ({ ...props }) => (
  <svg
    width={12}
    height={13}
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.188 8a.555.555 0 0 0-.563.563v2.624a.185.185 0 0 1-.188.188H1.314c-.118 0-.188-.07-.188-.188V4.064c0-.094.07-.188.188-.188h2.625a.555.555 0 0 0 .562-.563.57.57 0 0 0-.563-.562H1.313A1.325 1.325 0 0 0 0 4.063v7.125A1.31 1.31 0 0 0 1.313 12.5h7.125c.703 0 1.312-.586 1.312-1.313V8.563A.57.57 0 0 0 9.187 8Zm2.25-7.5H7.5a.555.555 0 0 0-.563.563c0 .328.235.562.563.562h2.578L4.29 7.438a.513.513 0 0 0 0 .773c.094.117.234.164.399.164a.47.47 0 0 0 .375-.164l5.812-5.79V5c0 .328.234.563.563.563A.555.555 0 0 0 12 5V1.062A.57.57 0 0 0 11.437.5Z"
      fill="#3D4FE0"
    />
  </svg>
);

export default OpenLinkIcon;
