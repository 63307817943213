import React from 'react';
import styled, { useTheme } from 'styled-components';
import { PUBLISH_METHODS } from 'shared/react/constants/tolstoy.constants';
import { TextSmall } from 'shared/react/components/complex/Text';
import Dot from 'app/src/complex_components/Dot';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

const PublishMethod = ({ publishMethod, dotSize, fontSize }) => {
  const theme = useTheme();
  if (!publishMethod || !PUBLISH_METHODS[publishMethod]) {
    return null;
  }

  const { text, color } = PUBLISH_METHODS[publishMethod];

  return (
    <>
      <DotContainer size={dotSize}>
        <Dot color={theme.colors[color]} />
      </DotContainer>
      <PublishMethodText fontSize={fontSize}>{text}</PublishMethodText>
    </>
  );
};

const PublishMethodText = styled(TextSmall)`
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '')};
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
  text-transform: capitalize;
`;

const DotContainer = styled(HorizontalFlexCentered)`
  height: ${({ size }) => size || 24}px;
  width: ${({ size }) => size || 24}px;
`;

export default PublishMethod;
