import React, { useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { SOUND_LIBRARY } from 'app/src/constants/soundLibrary.constants';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import SoundPlayIcon from 'app/src/images/SoundPlayIcon';
import SoundPauseIcon from 'app/src/images/SoundPauseIcon';
import CloseIcon from 'app/src/images/CloseIcon';
import Check from 'app/src/images/Check';

const SoundLibrary = ({ isOpen, onTrackSelect, onClose, selectedTrack }) => {
  const [playingTrack, setPlayingTrack] = useState(null);
  const [openCategory, setOpenCategory] = useState(null);
  const audioRef = useRef(new Audio());
  const theme = useTheme();

  const toggleCategory = category => {
    setOpenCategory(openCategory === category ? null : category);
  };

  const handleTrackSelection = track => {
    onTrackSelect(track);
  };

  const togglePlay = (e, track) => {
    e.stopPropagation();

    if (playingTrack === track.name) {
      audioRef.current.pause();
      setPlayingTrack(null);
    } else {
      audioRef.current.src = track.link;
      audioRef.current.play();
      setPlayingTrack(track.name);
    }
  };

  useEffect(() => {
    audioRef.current.addEventListener('ended', () => setPlayingTrack(null));
    return () => {
      audioRef.current.pause();
      audioRef.current.removeEventListener('ended', () => setPlayingTrack(null));
    };
  }, []);

  useEffect(() => {
    if (selectedTrack) {
      const foundCategory = Object.keys(SOUND_LIBRARY).find(category =>
        SOUND_LIBRARY[category].some(track => track.name === selectedTrack.name)
      );
      if (foundCategory) {
        setOpenCategory(foundCategory);
      }
    }
  }, [selectedTrack]);

  return (
    <LibraryWrapper $isOpen={isOpen}>
      <LibraryHeader>
        <span>Sound Library</span>
        <CloseIconWrapper onClick={() => onClose()}>
          <CloseIcon />
        </CloseIconWrapper>
      </LibraryHeader>
      {Object.entries(SOUND_LIBRARY).map(([category, subTracks]) => (
        <div key={category}>
          <CategoryTitle onClick={() => toggleCategory(category)}>{category}</CategoryTitle>
          <Sublist $isOpen={openCategory === category}>
            {subTracks.map(track => (
              <SublistItem key={track.name} onClick={() => handleTrackSelection(track)}>
                <SublistItemText>
                  {selectedTrack?.name === track.name && (
                    <Check color={theme.colors.white} width={12} height={12} />
                  )}
                  {track.name}
                </SublistItemText>
                <RoundButtonWrapper onClick={e => togglePlay(e, track)}>
                  {playingTrack === track.name ? <SoundPauseIcon /> : <SoundPlayIcon />}
                </RoundButtonWrapper>
              </SublistItem>
            ))}
          </Sublist>
        </div>
      ))}
    </LibraryWrapper>
  );
};

const LibraryWrapper = styled(VerticalFlex)`
  position: absolute;
  top: 60px;
  z-index: 9;
  width: 100%;
  height: 540px;
  background: ${({ theme }) => theme.getHexOpacity(theme.colors.black, 60)};
  backdrop-filter: blur(3px);
  transition: transform 0.3s ease-in-out;
  transform: ${({ $isOpen }) => ($isOpen ? 'translateY(0)' : 'translateY(100%)')};
`;

const LibraryHeader = styled(HorizontalFlex)`
  justify-content: space-between;
  padding: 12px 0;
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  width: 292px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutralDarker};
  margin: 0 auto;
`;

const CloseIconWrapper = styled.div`
  cursor: pointer;
`;

const CategoryTitle = styled.div`
  cursor: pointer;
  padding: 12px;
  color: ${({ theme }) => theme.colors.neutralLight};
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  &:hover {
    background: ${({ theme }) => theme.getHexOpacity(theme.colors.black, 40)};
  }
`;

const Sublist = styled.div`
  overflow-x: auto;
  transition:
    max-height 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
  padding: 0 8px;
  max-height: ${({ $isOpen }) => ($isOpen ? '300px' : '0')};
`;

const RoundButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.neutralDark};
`;

const SublistItem = styled(HorizontalFlex)`
  padding: 4px 8px;
  margin-bottom: 4px;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.neutralLight};
  border-radius: 2px;
  &:hover {
    background: ${({ theme }) => theme.getHexOpacity(theme.colors.black, 40)};
  }
`;

const SublistItemText = styled(HorizontalFlex)`
  align-items: center;
  gap: 8px;
`;

export default SoundLibrary;
