import Utils from 'app/src/utils';

export const ACCOUNT_LOGO_NAME = 'logo.png';
export const PLAYER_LOGO_NAME = 'player-logo.png';
export const LOGO_URL = 'logoUrl';
export const PLAYER_LOGO_URL = 'playerLogoUrl';
export const CUSTOM_FONT = 'custom-font';

export const getLogoSrc = (type, account, accountSettingsToUpdate) => {
  const isAccountSettingsToUpdate = Object.keys(accountSettingsToUpdate || {}).length;
  if (isAccountSettingsToUpdate) {
    return Utils.getLogoUrl(accountSettingsToUpdate[type]);
  }

  return Utils.getLogoUrl(account?.[type]);
};

export const getFontSrc = (account, accountFeaturesToUpdate) => {
  const isAccountSettingsToUpdate = Object.keys(accountFeaturesToUpdate || {}).length;
  if (isAccountSettingsToUpdate) {
    return Utils.getFontUrl(accountFeaturesToUpdate['font-customization']?.family);
  }

  return Utils.getFontUrl(account['font-customization']?.family);
};

export const getDefaultFontSrc = ({ family, account }) => {
  // is it relevant to check for 'font-customization' from account?
  return family || Utils.getFontUrl(account?.['font-customization']?.family);
};

export const getCurrentValue = ({
  type,
  accountFeaturesToUpdate,
  feature,
  featureSettings,
  defaultSettings,
}) => {
  const isSetToUpdate = !Utils.isNullOrUndefined(accountFeaturesToUpdate?.[feature]?.[type]);
  if (isSetToUpdate) {
    return accountFeaturesToUpdate[feature][type];
  }

  if (!Utils.isNullOrUndefined(featureSettings?.[type])) {
    return featureSettings[type];
  }

  return defaultSettings[type];
};
