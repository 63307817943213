import { GOOGLE_DRIVE } from 'app/src/constants/intergrations.constants';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import { useGoogleDrive } from 'app/src/context/GoogleDriveStore';
import { useVodConnectionLabelsByVod } from 'app/src/context/VodConnectionStore';
import PlusIcon from 'app/src/images/PlusIcon';
import VideoCardLabel from 'app/src/pages/dashboard/pages/videos/components/video_card/components/video-card-details/components/video-card-labels/VideoCardLabel';
import VideoLabelsMenu from 'app/src/pages/dashboard/pages/videos/components/video_card/components/video-card-details/components/video-card-labels/VideoLabelsMenu';
import VideoTaggedLabelsMenu from 'app/src/pages/dashboard/pages/videos/components/video_card/components/video-card-details/components/video-card-labels/VideoTaggedLabelsMenu';
import { VideosPageVodAsset } from 'app/src/pages/dashboard/pages/videos/types/videos.types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Badge } from 'shared/react/components/basic/badge/Badge';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { FEATURE_VIDEO_LABELS } from 'shared/react/constants/features.constants';
import { track } from 'src/helpers/Tracker';
import OnboardingTemplatesTagVideosTooltip from 'src/pages/dashboard/pages/homepage/onboarding/templates/components/tag_videos/OnboardingTemplatesTagVideosTooltip';
import { PseudoDashedBorderCss } from 'src/styled_components';
import { AnalyticsDataProps } from 'src/types/common/common';
import styled from 'styled-components';
import { vodAsset } from 'app/src/types/entities';
import { debounce } from 'lodash';

type Props = {
  video: VideosPageVodAsset | vodAsset;
  onSelectedVideosAction?: (video: VideosPageVodAsset[] | null) => void;
  analyticsData?: AnalyticsDataProps;
  isTagLabelsTooltipShown?: boolean;
};

const ADDITIONAL_LABELS_WIDTH = 36;
const LABELS_GAP = 4;
const PADDING = 32;

const VideoCardLabels = ({
  video,
  onSelectedVideosAction,
  analyticsData,
  isTagLabelsTooltipShown,
}: Props) => {
  const [connectionLabels = [], { deleteConnectionByVodLabel }] =
    useVodConnectionLabelsByVod(video);
  const { getFeatureEnabled } = useFeatureActions();
  const [{ videoFoldersById }] = useGoogleDrive();
  const [searchValue, setSearchValue] = useState('');
  const [anchorElement, setAnchorElement] = useState(null);
  const [anchorExtraTagsElement, setAnchorExtraTagsElement] = useState(null);
  const [startingNumberOfTiles, setStartingNumberOfTiles] = useState(0);
  const [numberOfLabels, setNumberOfLabels] = useState(100);
  const [containerWidth, setContainerWidth] = useState(500);
  const [addingLabelText, setAddingLabelText] = useState('');
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const shouldShowTagsByGoogleDrive = video?.app === GOOGLE_DRIVE;
  const currentLabels = shouldShowTagsByGoogleDrive
    ? videoFoldersById[video.parents?.[0]] || []
    : connectionLabels;

  const onDelete = (vodLabelId: string) => {
    track('Video Label Deleted', analyticsData);
    setLabelsNumber(true);
    return deleteConnectionByVodLabel({ vodAssetId: video.id, vodLabelId });
  };

  const setInputAnchor = () => {
    inputRef.current.focus();
    setAnchorElement(inputRef.current);
  };

  const onInputChange = e => {
    setSearchValue(e.target.value);
    setInputAnchor();

    if (!e.target.value) {
      setNumberOfLabels(startingNumberOfTiles);
    }
  };

  const setCurrentContainerWidth = useCallback(() => {
    if (!containerRef.current) {
      return;
    }

    const children = containerRef.current.children;
    let width = 0;
    for (const child of children) {
      width += child.getBoundingClientRect().width + LABELS_GAP;
    }

    setContainerWidth(width - LABELS_GAP);
  }, []);

  const debouncedSetCurrentContainerWidth = useCallback(debounce(setCurrentContainerWidth, 400), [
    setCurrentContainerWidth,
  ]);

  const closeMenu = () => {
    setAnchorElement(null);
    setSearchValue('');
    setAnchorExtraTagsElement(null);
    setCurrentContainerWidth();
  };

  const getNumberOfAdditionalTags = () => {
    const numberOfAdditionalTags = connectionLabels.length - numberOfLabels;
    if (numberOfAdditionalTags <= 0) {
      return 0;
    }

    if (numberOfAdditionalTags >= 9) {
      return 9;
    }

    return numberOfAdditionalTags;
  };

  const onExtraTagsClick = e => {
    setAnchorExtraTagsElement(e.currentTarget);
  };

  const setLabelsNumber = (updateTiles = false) => {
    if (!currentLabels.length || !containerRef.current) {
      return;
    }

    const children = containerRef.current.children;
    const containerWidth = containerRef.current.parentElement.offsetWidth - PADDING;

    let width = 0;
    let index = 0;

    for (const child of children) {
      width += child.offsetWidth + LABELS_GAP;

      if (width >= containerWidth) {
        if (width - child.offsetWidth + ADDITIONAL_LABELS_WIDTH >= containerWidth) {
          index = index - 1;
        }

        index = index - 1;
        setNumberOfLabels(index);
        break;
      }

      index++;
    }

    if (!startingNumberOfTiles || updateTiles) {
      setStartingNumberOfTiles(index);
    }
  };

  useEffect(() => {
    if (inputRef.current && !!anchorElement) {
      inputRef.current.focus();
    }
  }, [inputRef, anchorElement]);

  useEffect(() => {
    debouncedSetCurrentContainerWidth();

    return () => {
      debouncedSetCurrentContainerWidth.cancel();
    };
  }, [
    debouncedSetCurrentContainerWidth,
    currentLabels.length,
    numberOfLabels,
    searchValue,
    !!anchorElement,
  ]);

  useEffect(() => {
    if (!currentLabels.length) {
      return;
    }

    setLabelsNumber(true);
  }, [currentLabels]);

  useEffect(setLabelsNumber, [searchValue, anchorElement]);

  if (!getFeatureEnabled(FEATURE_VIDEO_LABELS)) {
    return null;
  }

  const connectionLabelsToShow = currentLabels.slice(0, numberOfLabels);
  const connectionLabelsToHide = currentLabels.slice(numberOfLabels);
  const numberOfAdditionalTags = getNumberOfAdditionalTags();
  const shouldHaveGap = !currentLabels.length;
  const shouldHideLabelText = currentLabels.length && !anchorElement;

  return (
    <>
      <LayoutRoot
        disableEvents={!!anchorElement}
        maxWidth={containerWidth}
        ref={containerRef}
        onClick={e => e.stopPropagation()}
      >
        <OnboardingTemplatesTagVideosTooltip text={isTagLabelsTooltipShown ? 'Add playlists' : ''}>
          <InputBadge
            shouldHaveGap={shouldHaveGap}
            onClick={setInputAnchor}
            isTagLabelsTooltipShown={isTagLabelsTooltipShown}
          >
            <StyledPlusIcon />
            <Input
              onChange={onInputChange}
              ref={inputRef}
              value={searchValue}
              placeholder={shouldHideLabelText ? '' : 'Playlist'}
              hasConnections={!!currentLabels.length}
              length={searchValue.length}
            />
          </InputBadge>
        </OnboardingTemplatesTagVideosTooltip>
        {connectionLabelsToShow.map((vodLabelId, index) => (
          <VideoCardLabel
            isHidden={numberOfLabels >= index}
            key={vodLabelId}
            vodLabelId={vodLabelId}
            onDelete={shouldShowTagsByGoogleDrive ? null : onDelete}
            text={vodLabelId}
          />
        ))}
        {addingLabelText && !numberOfAdditionalTags && (
          <VideoCardLabel isHidden={false} text={addingLabelText} onDelete={onDelete} />
        )}
        {!!numberOfAdditionalTags && (
          <StyledPlusBadge isBlack={!!anchorExtraTagsElement} onClick={onExtraTagsClick}>
            <StyledPlusIcon />
            {numberOfAdditionalTags}
          </StyledPlusBadge>
        )}
      </LayoutRoot>
      <VideoLabelsMenu
        anchorElement={anchorElement}
        setAddingLabelText={setAddingLabelText}
        setAnchorElement={setAnchorElement}
        searchValue={searchValue?.trim()}
        video={video}
        connectionLabels={connectionLabels}
        onSelectedVideosAction={onSelectedVideosAction}
        onClose={closeMenu}
        setSearchValue={setSearchValue}
        analyticsData={analyticsData}
      />
      <VideoTaggedLabelsMenu
        anchorElement={anchorExtraTagsElement}
        labels={connectionLabelsToHide}
        onClose={closeMenu}
        vodAssetId={video.id}
        analyticsData={analyticsData}
      />
    </>
  );
};

const LayoutRoot = styled(HorizontalFlex)`
  gap: 4px;
  max-width: ${({ maxWidth = 500 }) => maxWidth}px;
  width: fit-content;
  pointer-events: ${({ disableEvents }) => (disableEvents ? 'none' : '')};
`;

const StyledBadge = styled(Badge).attrs(({ theme }) => ({
  weight: theme.designWeights.SQUARE,
  state: theme.designStates.LIGHT,
}))<{ shouldHaveGap: boolean }>`
  min-width: 24px;
  padding: 4px;
  gap: ${({ shouldHaveGap }) => (shouldHaveGap ? '4px' : '0')};

  &:focus-within {
    gap: 4px;
  }
`;

const InputBadge = styled(StyledBadge)<{ isTagLabelsTooltipShown: boolean }>`
  &:focus-within {
    min-width: max-content;
    width: max-content;
  }

  ${({ isTagLabelsTooltipShown }) => (isTagLabelsTooltipShown ? PseudoDashedBorderCss : '')}
`;

const StyledPlusBadge = styled(StyledBadge)<{ isBlack: boolean }>`
  background: ${({ theme, isBlack }) => isBlack && theme.colors.black};
  color: ${({ theme, isBlack }) => isBlack && theme.colors.white};
  gap: 2px;
  min-width: auto;

  & svg {
    width: 10px;
    height: 9px;
  }

  & path {
    fill: ${({ theme, isBlack }) => isBlack && theme.colors.white};
  }
`;

const Input = styled.input<{ hasConnections: boolean; length: number }>`
  border: none;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  max-width: ${({ hasConnections }) => (hasConnections ? '8px' : '42px')};
  overflow: hidden;
  outline: none;
  width: ${({ hasConnections }) => (hasConnections ? '0' : '42px')};
  cursor: pointer;

  &:focus {
    max-width: 162px;
    min-width: 31px;
    width: ${({ length }) => (length ? (length + 2) * 6 : '')}px;
    cursor: text;

    &::placeholder {
      color: ${({ theme }) => theme.colors.neutralGray};
    }
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.black};
  }
`;

const StyledPlusIcon = styled(PlusIcon).attrs(({ theme }) => ({
  fill: theme.colors.black,
  size: 10,
}))`
  min-width: 10px;
  min-height: 11px;
`;

export default VideoCardLabels;
