import React from 'react';

const AiBadgeIcon = () => {
  return (
    <svg width="44" height="20" viewBox="0 0 44 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.5"
        y="0.5"
        width="43"
        height="19"
        rx="7.5"
        stroke="url(#paint0_angular_819_7641)"
      />
      <path
        d="M15.6641 6.51562L17 6L17.4922 4.6875C17.5156 4.57031 17.6328 4.5 17.75 4.5C17.8438 4.5 17.9609 4.57031 17.9844 4.6875L18.5 6L19.8125 6.51562C19.9297 6.53906 20 6.65625 20 6.75C20 6.86719 19.9297 6.98438 19.8125 7.00781L18.5 7.5L17.9844 8.83594C17.9609 8.92969 17.8438 9 17.75 9C17.6328 9 17.5156 8.92969 17.4922 8.83594L17 7.5L15.6641 7.00781C15.5469 6.98438 15.5 6.86719 15.5 6.75C15.5 6.65625 15.5469 6.53906 15.6641 6.51562ZM12.125 6.23438C12.1719 6.09375 12.3125 6 12.4531 6C12.5938 6 12.7344 6.09375 12.8047 6.23438L14.0234 8.90625L16.6953 10.125C16.8359 10.1953 16.9297 10.3359 16.9297 10.4766C16.9297 10.6172 16.8359 10.7578 16.6953 10.8281L14.0234 12.0469L12.8047 14.7188C12.7344 14.8594 12.5938 14.9531 12.4531 14.9531C12.3125 14.9531 12.1719 14.8594 12.125 14.7188L10.8828 12.0469L8.21094 10.8281C8.07031 10.7578 8 10.6172 8 10.4766C8 10.3359 8.07031 10.1953 8.21094 10.125L10.8828 8.90625L12.125 6.23438ZM11.8906 9.375C11.7969 9.60938 11.5859 9.82031 11.3516 9.91406L10.1562 10.4766L11.3516 11.0391C11.5859 11.1328 11.7969 11.3438 11.8906 11.5781L12.4531 12.7734L13.0156 11.5781C13.1094 11.3438 13.3203 11.1328 13.5547 11.0391L14.75 10.4766L13.5547 9.91406C13.3203 9.82031 13.1094 9.60938 13.0156 9.375L12.4531 8.17969L11.8906 9.375ZM17.4922 12.1875C17.5156 12.0703 17.6328 12 17.75 12C17.8438 12 17.9609 12.0703 17.9844 12.1875L18.5 13.5L19.8125 14.0156C19.9297 14.0391 20 14.1562 20 14.25C20 14.3672 19.9297 14.4844 19.8125 14.5078L18.5 15L17.9844 16.3359C17.9609 16.4297 17.8438 16.5 17.75 16.5C17.6328 16.5 17.5156 16.4297 17.4922 16.3359L17 15L15.6641 14.5078C15.5469 14.4844 15.5 14.3672 15.5 14.25C15.5 14.1562 15.5469 14.0391 15.6641 14.0156L17 13.5L17.4922 12.1875Z"
        fill="url(#paint1_linear_819_7641)"
      />
      <path
        d="M25.8777 14H24.4799L27.6206 5.27273H29.1419L32.2825 14H30.8848L28.4174 6.85795H28.3493L25.8777 14ZM26.112 10.5824H30.6461V11.6903H26.112V10.5824ZM34.8596 5.27273V14H33.5428V5.27273H34.8596Z"
        fill="url(#paint2_linear_819_7641)"
      />
      <defs>
        <radialGradient
          id="paint0_angular_819_7641"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(22 10) rotate(90) scale(10 22)"
        >
          <stop stopColor="#CA45F8" />
          <stop offset="0.520833" stopColor="#52C1FF" />
          <stop offset="1" stopColor="#CA45F8" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_819_7641"
          x1="8"
          y1="15"
          x2="28"
          y2="15"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D83AFF" />
          <stop offset="1" stopColor="#59B9FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_819_7641"
          x1="17.5"
          y1="10"
          x2="36"
          y2="10"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BD39FC" />
          <stop offset="1" stopColor="#54CAFD" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AiBadgeIcon;
