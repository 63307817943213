import * as React from 'react';
import { useTheme } from 'styled-components';

function Copy(props) {
  const theme = useTheme();
  return (
    <svg
      width={12}
      height={13}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={props.fill || theme.colors.white}
        d="M11.766 2.164l-1.43-1.43A.763.763 0 009.796.5H6c-.844 0-1.523.68-1.5 1.523v6c0 .82.656 1.477 1.5 1.477h4.5c.82 0 1.5-.656 1.5-1.5V2.703a.763.763 0 00-.234-.539zM10.875 8c0 .21-.188.375-.375.375H6A.37.37 0 015.625 8V2.023A.37.37 0 016 1.648h3V2.75c0 .422.328.75.75.75h1.125V8zm-4.5 3c0 .21-.188.375-.375.375H1.5A.37.37 0 011.125 11V5.023a.37.37 0 01.375-.375h2.25V3.5H1.5c-.844 0-1.523.68-1.5 1.523v6c0 .82.656 1.477 1.5 1.477H6c.82 0 1.5-.656 1.5-1.5v-.75H6.375V11z"
      />
    </svg>
  );
}

export default Copy;
