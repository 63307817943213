import React from 'react';

const PersonalizedOutreachBlast = props => (
  <svg
    width={44}
    height={44}
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22 4.667v20"
      stroke="url(#a)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 24.613s3.591-10.473 12.77-13.102"
      stroke="url(#b)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 24.613A23.218 23.218 0 0 0 8.724 10.925"
      stroke="url(#c)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 23.337a27.082 27.082 0 0 1 20 1.276"
      stroke="url(#d)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.398 34.51S14 24.103 22 24.613"
      stroke="url(#e)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42 23.337a27.082 27.082 0 0 0-20 1.276"
      stroke="url(#f)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.6 34.51S30 24.103 22 24.613"
      stroke="url(#g)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 2v5.333"
      stroke="url(#h)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.667 4.667H3.334"
      stroke="url(#i)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 35.333v5.334"
      stroke="url(#j)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.667 38h-5.333"
      stroke="url(#k)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M4 42a.667.667 0 0 1 0-1.333" stroke="url(#l)" strokeWidth={3} />
    <path d="M4 42a.667.667 0 1 0 0-1.333" stroke="url(#m)" strokeWidth={3} />
    <path d="M40 32.667a.667.667 0 1 1 0-1.334" stroke="url(#n)" strokeWidth={3} />
    <path d="M40 32.667a.667.667 0 1 0 0-1.334" stroke="url(#o)" strokeWidth={3} />
    <path d="M33.335 6a.667.667 0 0 1 0-1.333" stroke="url(#p)" strokeWidth={3} />
    <path d="M33.334 6a.667.667 0 1 0 0-1.333" stroke="url(#q)" strokeWidth={3} />
    <defs>
      <linearGradient id="a" x1={22} y1={24.667} x2={23} y2={24.667} gradientUnits="userSpaceOnUse">
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={22}
        y1={24.613}
        x2={34.77}
        y2={24.613}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={8.723}
        y1={24.613}
        x2={22.001}
        y2={24.613}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
      <linearGradient id="d" x1={2} y1={24.613} x2={22} y2={24.613} gradientUnits="userSpaceOnUse">
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={9.398}
        y1={34.51}
        x2={21.999}
        y2={34.51}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
      <linearGradient id="f" x1={22} y1={24.613} x2={42} y2={24.613} gradientUnits="userSpaceOnUse">
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
      <linearGradient
        id="g"
        x1={22}
        y1={34.51}
        x2={34.601}
        y2={34.51}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
      <linearGradient id="h" x1={6} y1={7.333} x2={7} y2={7.333} gradientUnits="userSpaceOnUse">
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
      <linearGradient
        id="i"
        x1={3.334}
        y1={5.667}
        x2={8.667}
        y2={5.667}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
      <linearGradient id="j" x1={22} y1={40.667} x2={23} y2={40.667} gradientUnits="userSpaceOnUse">
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
      <linearGradient id="k" x1={19.334} y1={39} x2={24.667} y2={39} gradientUnits="userSpaceOnUse">
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
      <linearGradient id="l" x1={3.334} y1={42} x2={4.001} y2={42} gradientUnits="userSpaceOnUse">
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
      <linearGradient id="m" x1={4} y1={42} x2={4.667} y2={42} gradientUnits="userSpaceOnUse">
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
      <linearGradient
        id="n"
        x1={39.334}
        y1={32.667}
        x2={40.001}
        y2={32.667}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
      <linearGradient
        id="o"
        x1={40}
        y1={32.667}
        x2={40.667}
        y2={32.667}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
      <linearGradient id="p" x1={32.668} y1={6} x2={33.335} y2={6} gradientUnits="userSpaceOnUse">
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
      <linearGradient id="q" x1={33.334} y1={6} x2={34.001} y2={6} gradientUnits="userSpaceOnUse">
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
    </defs>
  </svg>
);

export default PersonalizedOutreachBlast;
