import React from 'react';
import styled from 'styled-components';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import { IS_SHOPPABLE_PROPERTY_KEY } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import EditorOnOffToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorOnOffToggleItem';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import EditorMainContainer from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorMainContainer';

const FeedBehaviourEditor = () => {
  const {
    customizationSettings: {
      playerSettings: { isShoppable },
    },
    setPlayerSettingsProperty,
    setWidgetSettingsProperty,
  } = useLookAndFeelContext();

  const onShopabaleVideosChange = (value: boolean) => {
    setPlayerSettingsProperty(IS_SHOPPABLE_PROPERTY_KEY, value);
    setWidgetSettingsProperty(IS_SHOPPABLE_PROPERTY_KEY, value);
  };

  return (
    <LayoutRoot>
      <EditorMainContainer shouldHide={false}>
        <EditorOnOffToggleItem
          text="Make videos shoppable"
          checked={isShoppable}
          onChange={onShopabaleVideosChange}
        />
      </EditorMainContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

export default FeedBehaviourEditor;
