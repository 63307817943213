import {
  MOBILE_MAX_WIDTH,
  TABLET_MAX_WIDTH,
  DASHBOARD_TABLET_MAX_WIDTH,
  HORIZONTAL_MODAL_MAX_HEIGHT,
  SMALL_PHONE_MAX_HEIGHT,
  LAPTOP_MAX_HEIGHT,
  PREVIEW_MAX_WIDTH,
} from '../utils/mediaQuery';

export default {
  mobileMax: MOBILE_MAX_WIDTH,
  tabletMax: TABLET_MAX_WIDTH,
  dashboardTabletMax: DASHBOARD_TABLET_MAX_WIDTH,
  horizontalModalMaxHeight: HORIZONTAL_MODAL_MAX_HEIGHT,
  smallPhoneMaxHeight: SMALL_PHONE_MAX_HEIGHT,
  laptopMax: LAPTOP_MAX_HEIGHT,
  previewMax: PREVIEW_MAX_WIDTH,
};
