export const START_SCREEN_KEY = 'startScreen';
export const BUTTONS_KEY = 'buttons';
export const LOGO_KEY = 'logo';
export const CONTACT_FORM_KEY = 'contactForm';
export const TEXT_KEY = 'text';
export const PLAYER_SETTINGS_KEY = 'playerSettingsKey';
export const PLAYER_CONTROLS_KEY = 'playerControlsKey';
export const PLAYER_FEED_CARDS_KEY = 'playerFeedCardsKey';
export const PLAYER_BRANCHING_KEY = 'playerBranchingKey';
export const FONT_KEY = 'font';
export const FEED_PRODUCT_CARDS_KEY = 'feedProductCards';
export const IN_FEED_PDP_KEY = 'inFeedPDP';
export const PLAY_BUTTON_KEY = 'playButton';
export const CHECKOUT_KEY = 'checkout';
export const BEHAVIOUR_KEY = 'behaviour';
export const PRODUCT_IMAGE_EDITOR_KEY = 'productImageEditorKey';

export const GENERAL_CATEGORY_KEY = 'General';
export const PLAYER_CATEGORY_KEY = 'Player';
export const VARIANTS_KEY = 'variants';
export const BRANDING_CATEGORIES = [GENERAL_CATEGORY_KEY, PLAYER_CATEGORY_KEY];

export const PLAYER_SCROLL_TO_KEY = 'playerScrollTo';
