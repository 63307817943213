import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useMousePosition from 'app/src/hooks/useMousePosition';
import Utils from 'app/src/utils';
import { TextTiny } from 'shared/react/components/complex/Text';
import { secondsToMinutesSecondsDisplay } from 'app/src/utils/time.utils';

function TimeBar({
  videoDuration,
  time,
  onTimeChanged,
  changeToPercentage,
  setChangeToPercentage,
  ...props
}) {
  const [ref, callbackRef, mousePosition] = useMousePosition();
  const [answersTimePercentage, setAnswersTimePercentage] = useState(0);

  useEffect(() => {
    setAnswersTimePercentage(Utils.getPercentage(time, videoDuration));
  }, [time, videoDuration]);

  useEffect(() => {
    const boundingClientRect = ref.current?.getBoundingClientRect();
    if (!boundingClientRect) {
      return;
    }

    const { left: mouseLeft } = mousePosition;
    const { left, width } = boundingClientRect;

    if (!mouseLeft) {
      setChangeToPercentage(null);
      return;
    }

    const distanceFromStart = mouseLeft - left;
    setChangeToPercentage(Utils.getPercentage(distanceFromStart, width));
  }, [mousePosition]);

  function handleMouseLeave() {
    setChangeToPercentage(null);
  }

  function handleLayoutRootClicked(e) {
    e.stopPropagation();
    onTimeChanged(Utils.getValueOfPercentage(videoDuration, changeToPercentage));
  }

  return (
    <LayoutRoot {...props}>
      <MainContainer>
        <BarBackgroundContainer percentage={100}>
          <BarBackground />
        </BarBackgroundContainer>

        {!Utils.isNullOrUndefined(changeToPercentage) && (
          <GoToBar percentage={changeToPercentage} />
        )}

        <ProgressBar percentage={answersTimePercentage} />
      </MainContainer>
      <TransparentOverlay
        ref={callbackRef}
        onMouseLeave={handleMouseLeave}
        onClick={handleLayoutRootClicked}
      />
      <VideoDurationText>{secondsToMinutesSecondsDisplay(time)}</VideoDurationText>
    </LayoutRoot>
  );
}

export default TimeBar;

const LayoutRoot = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 8px;
  align-items: center;
  align-self: center;
  height: fit-content;
  cursor: pointer;
  padding: 8px 16px 8px 8px;
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 100%;
  }
`;

const BaseBar = styled.div`
  width: ${({ percentage }) => percentage}%;
  height: 4px;
  grid-row: 1;
  grid-column: 1;
  border-radius: 2px;
`;

const MainContainer = styled.div`
  grid-row: 1;
  grid-column: 1;
  display: grid;
  align-items: center;
  width: 100%;
`;

const TransparentOverlay = styled.div`
  grid-row: 1;
  grid-column: 1;
  z-index: 1000;
  width: 100%;
  height: 100%;
`;

const VideoDurationText = styled(TextTiny)`
  grid-row: 1;
  grid-column: 2;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
  text-shadow: #000 1px 0 10px;
`;

const BarBackgroundContainer = styled(BaseBar)`
  display: grid;
`;

const BarBackground = styled.div`
  background-color: ${({ theme }) => theme.colors.gray9};
  border-radius: 2px;
  width: 100%;
`;

const ProgressBar = styled(BaseBar)`
  display: grid;
  background: white;
  transition: 0.5s;
  height: 100%;
  border-radius: 2px;
`;

const GoToBar = styled(BaseBar)`
  display: grid;
  transition: 0.2s;
  height: 100%;
  border-radius: 0;
  opacity: 0.6;
  background: ${({ theme }) => theme.colors.black30opacity};
`;
