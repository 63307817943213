import React, { forwardRef, HTMLAttributes, ReactNode } from 'react';
import { Label } from '@tolstoy/ui-library/core';
import { cn } from 'src/utils/styles';

interface EditorItemProps extends HTMLAttributes<HTMLDivElement> {
  label: string;
  labelClassName?: string;
  children: ReactNode;
}

export const EditorItem = forwardRef<HTMLDivElement, EditorItemProps>(
  ({ label, children, className, labelClassName, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        'flex flex-wrap justify-between items-center gap-4',
        className,
      )}
      {...props}
    >
      <Label
        className={cn(
          'text-sm font-semibold text-gray-900',
          labelClassName,
        )}
      >
        {label}
      </Label>
      {children}
    </div>
  )
);

EditorItem.displayName = 'InputField';
