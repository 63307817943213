import React from 'react';
import FileUploadAnswerButton from './FileUploadAnswerButton';
import ChoiceButton from '../../../../../shared/react/components/complex/ChoiceButton';
import { ANSWER_BUTTON_DATA_TEST_ID as answerButtonDataTestId } from '../../../constants/dataTestIds.constants';
import { getLogoIcon } from '../../../../../shared/react/utils/icons';

function AnswerButtonInputWrapper({
  id,
  onImageSelected,
  onClick,
  selected,
  isMultipleChoiceSelection,
  selectedVideo,
  answer,
  wide,
  hasSubtitles,
  index,
  customLayout,
  stepAnswersCount,
  disabled,
  brandColor,
  buttonsSettings,
  showButtonsIndex,
  hasAnswersWithImages,
  setNextRef,
  onKeyDown,
  borderRadius,
}) {
  const icon = getLogoIcon(answer.type || '', brandColor, answer.next);

  const props = {
    customLayout,
    dataTestId: answerButtonDataTestId,
    stepAnswersCount,
    brandColor,
    borderRadius,
    onClick,
    circle: selectedVideo.answers.length > 1,
    buttonsSettings,
    index,
    wide,
    selected,
    text: answer.text,
    hasAnswersWithImages,
    tabIndex: 0,
    onKeyDown,
    setNextRef,
    ['aria-label']: `${answer.text} choice`,
    ['aria-disabled']: disabled,
    disabled,
    role: 'button',
  };

  switch (answer.type) {
    case 'Image':
      return (
        <FileUploadAnswerButton
          id={id}
          key={index}
          hasSubtitles={hasSubtitles}
          onImageSelected={onImageSelected}
          selectedVideo={selectedVideo}
          {...props}
        />
      );
    default:
      return (
        <ChoiceButton
          key={index}
          hasSubtitles={hasSubtitles}
          icon={icon}
          isMultipleChoiceSelection={isMultipleChoiceSelection}
          showButtonsIndex={showButtonsIndex}
          buttonsSettings={buttonsSettings}
          {...props}
        />
      );
  }
}

export default AnswerButtonInputWrapper;
