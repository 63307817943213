import React from 'react';

const FilledFolderIcon = () => {
  return (
    <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 10.75h9c.82 0 1.5-.656 1.5-1.5v-6c0-.82-.68-1.5-1.5-1.5H6.75c-.258 0-.469-.094-.61-.281l-.445-.61A1.51 1.51 0 0 0 4.5.25h-3C.656.25 0 .93 0 1.75v7.5c0 .844.656 1.5 1.5 1.5Z"
        fill="#fff"
      />
    </svg>
  );
};

export default FilledFolderIcon;
