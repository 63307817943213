import React from 'react';

const MobileIcon = () => (
  <svg width="20" height="32" viewBox="0 0 20 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 0H4C1.75 0 0 1.813 0 4v24c0 2.25 1.75 4 4 4h12c2.188 0 4-1.75 4-4V4c0-2.188-1.813-4-4-4Zm2 28c0 1.125-.938 2-2 2H4c-1.125 0-2-.875-2-2V4c0-1.063.875-2 2-2h12c1.063 0 2 .938 2 2v24Zm-6-3H8c-.563 0-1 .5-1 1 0 .563.438 1 1 1h4c.5 0 1-.438 1-1 0-.5-.5-1-1-1Z"
      fill="#090A0B"
    />
  </svg>
);

export default MobileIcon;
