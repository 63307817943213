import { PricingPackage } from 'src/types/pricing';
import { ShopifyuProductsType } from 'src/constants/pricing';
import { getMaxPageViews } from './views';

const normalizePrice = (price: number) => Math.round(price * 100) / 100;

/*
  parses price string in format "$169" to number 169.00
*/
export const parsePrice = (price: string) => {
  if (!price) {
    return 0;
  }

  const parsedPrice = parseFloat(price.replace(/[^\d.-]/g, '').trim());
  return normalizePrice(parsedPrice);
};

type ViewsPriceProps = {
  date?: Date;
  views: number;
  pricingPackage: PricingPackage;
  paidSeatsCount?: number;
};

export const getPriceByViews = ({
  views,
  pricingPackage,
  paidSeatsCount = 0,
}: ViewsPriceProps): number => {
  if (!pricingPackage) {
    return 0;
  }

  const pricePerExtraView = getPricePerExtraView(pricingPackage);
  const maxPageViews = getMaxPageViews(pricingPackage);
  const seatPrice = pricingPackage.details.seatPrice || 0;
  // Default 1 because if no freeSeats specified, we include 1 (owner)
  const freeSeats = pricingPackage.details.freeSeats || 1;
  const extraViews = Math.max(views - maxPageViews, 0);

  const extraViewsPrice = extraViews * pricePerExtraView;
  const priceForSeats = Math.max(paidSeatsCount - freeSeats, 0) * seatPrice;
  const total = parsePrice(pricingPackage.details.price) + extraViewsPrice + priceForSeats;

  return normalizePrice(total);
};

export const getExtraFee = ({ views, pricingPackage, paidSeatsCount = 0 }: ViewsPriceProps) => {
  if (!pricingPackage?.details?.price) {
    return 0;
  }

  const viewsPrice = getPriceByViews({
    views,
    pricingPackage,
    paidSeatsCount,
  });
  const packagePrice = parsePrice(pricingPackage.details.price);

  return Math.round(viewsPrice - packagePrice);
};

export const getPriceForExtraViews = (pricingPackage: PricingPackage): number => {
  const perUsagePlan = pricingPackage?.shopifyProducts?.find(
    product => product.type === ShopifyuProductsType.PerUniqueUsage
  );

  return perUsagePlan?.price || 0;
};

export const getPricePerExtraView = (pricingPackage: PricingPackage): number => {
  if (!pricingPackage?.shopifyProducts?.length) {
    return 0;
  }

  const price = getPriceForExtraViews(pricingPackage);

  return price / 1000 || 0;
};
