import React from 'react';
import styled from 'styled-components';
import { CHAT_LANDING_PAGE_PROPERTY_KEY } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import EditorOnOffToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorOnOffToggleItem';
import EditorHeader from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorHeader';
import EditorMainContainer from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorMainContainer';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const FeaturesEditor = () => {
  const {
    customizationSettings: { chatLandingPage },
    setCustomizationSettingsProperty,
  } = useLookAndFeelContext();

  return (
    <LayoutRoot>
      <EditorHeader title="Appearance" />
      <EditorMainContainer>
        <EditorOnOffToggleItem
          text="Chat"
          checked={!!chatLandingPage}
          tooltipText="New! Increase the number of responses that your Tolstoy gets by enabling a chatbot feature on the page."
          onChange={value =>
            setCustomizationSettingsProperty(CHAT_LANDING_PAGE_PROPERTY_KEY, value)
          }
        />
      </EditorMainContainer>
    </LayoutRoot>
  );
};

export default FeaturesEditor;

const LayoutRoot = styled(VerticalFlex)``;
