import React from 'react';

const HeartIcon = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
      <path d="M2.086 4.108c-.344.35-.617.766-.804 1.224a3.832 3.832 0 0 0 0 2.888c.187.458.46.874.804 1.224L8.496 16l6.418-6.548c.344-.35.617-.766.804-1.224a3.83 3.83 0 0 0 0-2.888 3.772 3.772 0 0 0-2.003-2.044 3.626 3.626 0 0 0-2.831 0c-.449.19-.856.469-1.2.82L8.505 5.32l-1.19-1.212a3.684 3.684 0 0 0-1.198-.82 3.626 3.626 0 0 0-2.831 0c-.45.19-.857.47-1.2.82Z"></path>
    </svg>
  );
};

export default HeartIcon;
