import React from 'react';
import styled from 'styled-components';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';
import useFeedState from 'shared/react/components/complex/context/hooks/useFeedState';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';
import Utils from 'shared/react/utils/utils';
import AfterpayLogo from 'shared/react/images/AfterpayLogo';
import { BolderTextSmall, TextSmall } from '../../../Text';

const NUMBER_OF_INSTALLMENTS = 4;

const FeedAfterpayIntegration = ({ product, selectedVariant, isMultipleProductDetails }) => {
  const [{ isAfterpayAppInstalled }] = useFeedState();
  const [, { t }] = useTranslation('feed.afterpay');
  const { player } = useDesignSettingsContext();
  const { showAfterpay } = player.feedCards.productDetailsCard.advanced;

  const { currencySymbol } = product;

  if (isMultipleProductDetails || !isAfterpayAppInstalled || !selectedVariant?.price || !showAfterpay) {
    return null;
  }

  const afterpayAmount = Utils.getDisplayedPrice({
    price: (selectedVariant?.price / NUMBER_OF_INSTALLMENTS).toFixed(2),
    currencySymbol,
  });

  return (
    <LayoutRoot>
      {t('or')} {NUMBER_OF_INSTALLMENTS} {t('payments')}
      <BolderTextSmall>{afterpayAmount}</BolderTextSmall>
      {t('with')}
      <AfterpayLogo />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(TextSmall)`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
  flex-shrink: 0;
  font-weight: 500;
`;

export default FeedAfterpayIntegration;
