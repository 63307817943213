import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { TextSmall } from 'shared/react/components/basic/text/TextV2';
import Utils from 'app/src/utils';
import { FEATURE_QUIZ_PRODUCTS_RESULT } from 'shared/react/constants/features.constants';
import { useApps } from 'app/src/context/AppsStore';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import ShoppingBag from 'app/src/images/ShoppingBag';
import PlusIcon from 'app/src/images/dashboard_v2/PlusIcon';
import { project as Project } from 'app/src/types/entities';
import { ADD_STEP_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import AddStepsTooltip from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/tolstoy_list/AddStepsTooltip';
import AddNewStepMenu from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/tolstoy_list/add-new-step/AddNewStepMenu';
import useAddStep from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/useAddStep';

type Props = {
  project: Project;
  onAddProductResult: () => void;
  checkUnsavedChanges: (callback: () => void) => void;
  setIsReadyToNavigate: (isReadyToNavigate: boolean) => void;
};

const AddNewStep = ({
  project,
  onAddProductResult,
  checkUnsavedChanges,
  setIsReadyToNavigate,
}: Props) => {
  const [anchorElement, setAnchorElement] = useState(null);
  const anchorTarget = useRef(null);
  const { getFeatureEnabled } = useFeatureActions();
  const [{ shopify }] = useApps();
  const {
    isLocked,
    shouldHide,
    isQuizOptionDisabled,
    maxNumberOfPartsInTolstoy,
    quizProductsStepLimitPassed,
  } = useAddStep(project);

  const shouldNotShowQuizAddProductsStep =
    !shopify ||
    !Utils.isQuiz(project?.tolstoyType, 2) ||
    !getFeatureEnabled(FEATURE_QUIZ_PRODUCTS_RESULT) ||
    quizProductsStepLimitPassed;

  const onButtonClick = e => {
    if (isLocked) {
      return;
    }

    setIsReadyToNavigate(false);
    anchorTarget.current = e?.currentTarget;
    const showAnchor = () => {
      setIsReadyToNavigate(false);
      setAnchorElement(anchorTarget.current);
    };
    checkUnsavedChanges(showAnchor);
  };

  const onAddResult = () => {
    if (isLocked) {
      return;
    }

    onAddProductResult();
  };

  const showQuiz = isLocked && shouldNotShowQuizAddProductsStep;
  const newStepButtons = [
    {
      text: `New video`,
      disabled: isLocked,
      icon: <PlusIcon />,
      onClick: onButtonClick,
      dataTestId: ADD_STEP_TEST_ID,
    },
  ];

  if (showQuiz) {
    newStepButtons.push({
      text: `New result`,
      disabled: isLocked,
      icon: <ShoppingBag />,
      onClick: onAddResult,
      dataTestId: null,
    });
  }

  if (shouldHide) {
    return null;
  }

  return (
    <AddStepsTooltip
      isQuizOptionDisabled={isQuizOptionDisabled}
      isLocked={isLocked}
      maxNumberOfPartsInTolstoy={maxNumberOfPartsInTolstoy}
      hidden={undefined}
    >
      <LayoutRoot>
        {newStepButtons.map(({ text, icon, onClick, dataTestId }) => (
          <Button showQuiz={showQuiz} key={text} onClick={onClick} data-test-id={dataTestId}>
            {icon}
            <Text>{text}</Text>
          </Button>
        ))}
      </LayoutRoot>
      <AddNewStepMenu
        anchorElement={anchorElement}
        setAnchorElement={setAnchorElement}
        project={project}
      />
    </AddStepsTooltip>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)`
  height: 100%;
  min-height: 114px;
`;

const Text = styled(TextSmall)`
  transition: 0.3s;
  color: ${({ theme }) => theme.colors.success};
`;

const Button = styled(Gap8VerticalFlex)<{ showQuiz: boolean }>`
  border-radius: 4px;
  transition: 0.3s;
  width: 144px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(50, 50, 93, 0.1);
  cursor: pointer;
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
  max-height: 114px;
  flex-direction: ${({ showQuiz }) => showQuiz && 'row'};

  & svg {
    width: 20px;
    height: 20px;
  }

  & path {
    fill: ${({ theme }) => theme.colors.success};
  }

  &:hover {
    ${Text} {
      color: ${({ theme }) => theme.colors.successHover};
    }

    path {
      fill: ${({ theme }) => theme.colors.success};
    }

    box-shadow:
      0px 5px 15px rgba(50, 50, 93, 0.1),
      0px 2px 5px rgba(0, 0, 0, 0.04);
  }
`;

export default AddNewStep;
