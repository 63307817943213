import React, { useState } from 'react';
import styled from 'styled-components';
import google from 'shared/react/images/google.svg';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import CustomerLoginCodeValidation from './CustomerLoginCodeValidation';
import CustomerLoginForm from './CustomerLoginForm';
import { CUSTOMER_LOGIN_STATE } from 'shared/react/constants/feature-constants/customerLogin.constants';
import { TextBody, TextH6 } from '../Text';
import LightButton from '../../basic/button/button/LightButton';
import useTracker from 'shared/react/components/complex/context/hooks/useTracker';
import useProjectConfig from '../context/hooks/useProjectConfig';
import useFeedAccount from '../context/hooks/useFeedAccount';
import Utils from 'shared/react/utils/utils';

const CustomerLoginBody = ({ customerLoginState, currentStep }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [{ appUrl, publishId, isMultipass }] = useProjectConfig();
  const [{ appKey }] = useFeedAccount();
  const [, { track }] = useTracker();
  const onGoogleLogin = () => {
    setIsLoading(true);
    const redirectUrl = Utils.getPageWithPlayerUrl(publishId, currentStep);
    const url = Utils.getCustomerGoogleAuthUrl(appUrl, appKey, redirectUrl);
    track('customerLoginGoogleClick');
    return window.open(`${url}`, '_top');
    setIsLoading(false);
  };

  const getCustomerLoginComponent = isMultipass => {
    if (isMultipass) {
      return <CustomerLoginForm />;
    }
    return (
      <>
        <StyledText>Personalize what you see, every time you swipe.</StyledText>
        <GoogleButtonContainer size="large" onClick={onGoogleLogin} disabled={isLoading}>
          <GoogleButton>
            <GoogleIcon src={google} alt="google" />
            <GoogleText>Continue with Google</GoogleText>
          </GoogleButton>
        </GoogleButtonContainer>
      </>
    );
  };

  return (
    <LayoutRoot>
      {customerLoginState === CUSTOMER_LOGIN_STATE.UNAUTHENTICATED ? (
        getCustomerLoginComponent(isMultipass)
      ) : (
        <CustomerLoginCodeValidation />
      )}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  margin-bottom: 16px;
`;

const GoogleButtonContainer = styled(LightButton)`
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.neutralGray};
  width: 100%;
  margin: 10px 0;
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.neutralGray};
    background: ${({ theme }) => theme.colors.neutralLighter};
  }
`;

const GoogleButton = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  justify-items: center;
  column-gap: 16px;
  height: 100%;
  width: 100%;
  text-transform: none;
`;

const GoogleIcon = styled.img`
  width: 24px;
  margin-left: 11px;
  grid-row: 1;
  grid-column: 1;
`;

const GoogleText = styled(TextBody)`
  font-weight: 600;
  grid-row: 1;
  grid-column: 1 / span 2;
`;

const StyledText = styled(TextH6)`
  margin-top: 16px;
`;
export default CustomerLoginBody;
