import { useEffect, useState, useRef } from 'react';
import { useRechargeContext } from '../RechargeContext';
import useFeedState from 'shared/react/components/complex/context/hooks/useFeedState';
import SharedUtils from 'shared/react/utils/utils';
import {
  RECHARGE_MESSAGING,
  REQUEST_RECHARGE_ADD_TO_CART_MESSAGE,
  REQUEST_RECHARGE_WIDGET_DATA_MESSAGE,
} from 'shared/react/constants/rechargeMessaging.constants';
import { CART_DATA_REQUEST_MESSAGE } from 'shared/react/constants/shopifyMessaging.constants';
import Utils from 'shared/react/utils/utils';

const useRecharge = () => {
  const [rechargeProducts, setRechargeProducts] = useState({});
  const [rechargeWidgetSettings, setRechargeWidgetSettings] = useState({});
  const rechargeState = useRechargeContext();
  const {
    setRechargeWidgetData,
    isRechargeEnabled,
    rechargeWidgetData,
    rechargeCartItem,
    setRechargeCartItem,
  } = rechargeState;
  const [{ setMessagingRequestDetails }] = useFeedState();
  const intervalRef = useRef(null);
  const isRechargeInitialized = !!rechargeWidgetData;

  const handleRequestRechargeWidget = () => {
    clearInterval(intervalRef?.current);
    if (isRechargeInitialized) {
      return;
    }

    let intervalTimes = 0;
    intervalRef.current = setInterval(() => {
      if (intervalTimes > 10) {
        clearInterval(intervalRef.current);
      }

      intervalTimes += 1;
      SharedUtils.postMessageToWindow({
        name: RECHARGE_MESSAGING,
        type: REQUEST_RECHARGE_WIDGET_DATA_MESSAGE,
      });
    }, 3000);
  };

  const rechargePostToCart = async () => {
    if (!isRechargeEnabled || !isRechargeInitialized || !rechargeCartItem?.id) {
      return;
    }

    Utils.postMessageToWindow({
      name: RECHARGE_MESSAGING,
      type: REQUEST_RECHARGE_ADD_TO_CART_MESSAGE,
      rechargeCartItem,
    });
  };

  const handleGetRechargeWidgetData = ({ rechargeWidgetData: data }) => {
    if (!data) {
      return;
    }

    clearInterval(intervalRef.current);
    intervalRef.current = null;

    setRechargeWidgetData(data);
  };

  const handleRechargePostToCartResponse = () => {
    setMessagingRequestDetails({});
    SharedUtils.postMessageToWindow({
      eventName: CART_DATA_REQUEST_MESSAGE,
    });
  };

  const getSellingPlanGroup = (sellingPlanAllocation, sellingPlanGroups, maxPercentageDiscount) => {
    const {
      selling_plan_group_id: allocationSellingPlanGroupId,
      selling_plan_id: allocationSellingPlanId,
      discounted_price,
    } = sellingPlanAllocation;

    const sellingPlanGroup = sellingPlanGroups.find(
      ({ selling_plan_group_id }) => selling_plan_group_id === allocationSellingPlanGroupId
    );

    const sellingPlan = sellingPlanGroup?.selling_plans.find(
      ({ selling_plan_id, price_adjustments_value, price_adjustments_value_type }) => {
        if (
          price_adjustments_value_type === 'percentage' &&
          price_adjustments_value > maxPercentageDiscount
        ) {
          maxPercentageDiscount = price_adjustments_value;
        }

        return selling_plan_id === allocationSellingPlanId;
      }
    );

    return {
      ...sellingPlanAllocation,
      ...sellingPlan,
      discounted_price,
    };
  };

  const getSellingPlans = (sellingPlanAllocations, rechargeProduct) => {
    let maxPercentageDiscount = 0;

    const sellingPlanGroups = rechargeProduct?.selling_plan_groups;

    const sellingPlans = sellingPlanAllocations.map(plan =>
      getSellingPlanGroup(plan, sellingPlanGroups, maxPercentageDiscount)
    );

    return { sellingPlans, maxPercentageDiscount };
  };

  const getRechargeProduct = (productId, variantId) => {
    const rechargeProduct = rechargeProducts[productId];
    const rechargeVariant = rechargeProduct?.variants?.find(({ id }) => id === variantId);
    if (!rechargeVariant) {
      return null;
    }

    const { prices, selling_plan_allocations } = rechargeVariant;

    const { sellingPlans, maxPercentageDiscount } = getSellingPlans(
      selling_plan_allocations,
      rechargeProduct
    );

    return {
      prices,
      sellingPlans,
      maxPercentageDiscount,
      storefrontPurchaseOptions: rechargeProduct.subscription_options.storefront_purchase_options,
    };
  };

  const getShouldShowRecharge = ({ productId, variantId, isMultipleProductDetails }) => {
    if (!isRechargeEnabled || !isRechargeInitialized) {
      return;
    }

    const rechargeProduct = getRechargeProduct(productId, variantId);

    return (
      rechargeProduct?.storefrontPurchaseOptions === 'subscription_and_onetime' &&
      !isMultipleProductDetails
    );
  };

  useEffect(() => {
    if (!isRechargeEnabled) {
      return;
    }

    if (!isRechargeInitialized) {
      handleRequestRechargeWidget();
      return;
    }

    const { products, storeSettings, widgetSettings } = rechargeWidgetData;

    setRechargeProducts(Object.fromEntries(products.flatMap(Object.entries)));
    setRechargeWidgetSettings({
      currencySymbol: storeSettings.store_currency.currency_symbol,
      onetimeMessage: widgetSettings.onetime_message,
      subscribeMessage: widgetSettings.subscribe_message,
      subscribeWithoutDiscountMessage: widgetSettings.subscribe_without_discount_message,
      deliveryDropdownLabel: widgetSettings.delivery_dropdown_label,
      howItWorks: widgetSettings.how_it_works,
    });
  }, [rechargeWidgetData, isRechargeEnabled, isRechargeInitialized]);

  const actions = {
    handleRequestRechargeWidget,
    handleGetRechargeWidgetData,
    handleRechargePostToCartResponse,
    getRechargeProduct,
    rechargePostToCart,
    getShouldShowRecharge,
  };

  return [
    {
      ...rechargeState,
      rechargeProducts,
      rechargeWidgetSettings,
      rechargeCartItem,
      setRechargeCartItem,
    },
    actions,
  ];
};

export default useRecharge;
