import React from 'react';

const ThinPlusIcon = () => {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.125 8.5a.642.642 0 0 1-.625.625H9.625V16c0 .352-.313.664-.625.664-.352 0-.625-.312-.625-.664V9.125H1.5c-.352 0-.625-.273-.625-.586 0-.351.273-.664.625-.664h6.875V1c0-.313.273-.586.625-.586.313 0 .625.274.625.586v6.875H16.5c.313 0 .625.313.625.625Z"
        fill="#3D4FE0"
      />
    </svg>
  );
};

export default ThinPlusIcon;
