export const SHOPIFY_ACCESS_SCOPES = [
  'read_themes',
  'write_themes',
  'read_script_tags',
  'write_script_tags',
  'read_products',
  'read_orders',
  'read_content',
  'write_content',
  'read_files',
  'write_files',
];

export const HAS_SEEN_SHOPIFY_REINSTALL_MODAL_KEY = 'hasSeenShopifyReinstallModal';

export const SHOPIFY_SUFFIX = '.myshopify.com';
