import React from 'react';
import DesignStoriesPreview from 'app/src/pages/project/pages/look_and_feel/preview/feed_stories_preview/DesignStoriesPreview';
import StoriesStaticPreview from 'app/src/pages/modals/publish/previews/static_preview_items/StoriesStaticPreview';

const PublishStoriesPreview = ({ ...props }) => {
  return (
    <StoriesStaticPreview>
      <DesignStoriesPreview {...props} />
    </StoriesStaticPreview>
  );
};

export default PublishStoriesPreview;
