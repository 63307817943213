import React, { useEffect, useState } from 'react';
import { useAppActions, useApps } from 'app/src/context/AppsStore';
import useAccountInstallation from 'app/src/hooks/useAccountInstallation';
import InstallationTitle from 'app/src/pages/project/pages/installation/components/shared/InstallationTitle';
import InstallStepSimple from 'app/src/pages/project/pages/installation/components/shared/InstallStepSimple';
import { project as Project } from 'src/types/entities';
import styled from 'styled-components';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import heroImage1 from './images/hero-image1.png';
import heroImage2 from './images/hero-image2.png';
import InstallSetLiveStep from 'app/src/pages/project/pages/installation/components/shared/InstallSetLiveStep';
import { EmbedWidgetsShopifyInstall } from 'app/src/types/install';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import CopyCode from 'app/src/complex_components/CopyCode';
import { useVideoActions } from 'app/src/context/VideosStore';
import { STEP_TYPE } from 'app/src/pages/project/pages/hero/constants';
import { HERO_PUBLISH_URL } from 'app/src/config/app.config';
import Spinner from 'app/src/basic_components/Spinner';
import { STORIES_ITEMS_SIZE_TYPES } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';

type Props = {
  project: Project;
};

const HeroInstallation = ({ project }: Props) => {
  const { getVideoById } = useVideoActions();
  const [showManualInstallCode, setShowManualInstallCode] = useState(true);
  const { getAppUsingUrl } = useAppActions();
  const currentApp = getAppUsingUrl(project.appUrl);
  const projectName = project?.name;
  const [{ isInstallationCompleted: isAccountInstallationCompleted }] = useAccountInstallation(
    currentApp?.id
  );
  const [{ shopify: isShopify }] = useApps();
  const isYoutube = project?.steps?.items?.find(step => step.description === STEP_TYPE.youtube);

  const isDisabled = !isAccountInstallationCompleted;

  const getVideos = () => {
    const projectSteps = project.steps.items;
    const mobileStep = projectSteps.find(step => step.description === STEP_TYPE.mobile);
    const desktopStep = projectSteps.find(step => step.description === STEP_TYPE.desktop);
    const mobileVideo = getVideoById(mobileStep?.videoId);
    const desktopVideo = getVideoById(desktopStep?.videoId);
    return { mobileVideo, desktopVideo };
  };

  const { mobileVideo, desktopVideo } = getVideos();
  const url = `${HERO_PUBLISH_URL}?publishId=${project?.publishId}`;
  const hasVideos = !!mobileVideo && !!desktopVideo;

  const getYoutubeCode = () => {
    return `<iframe class="tolstoy-hero-iframe " id="hero-tolstoy" src="${url}"
                style="width:100%;"
                scrolling="no" frameborder="0" allow="autoplay *;">
        </iframe>
<style>
   .tolstoy-hero-iframe  {
        aspect-ratio: 16/9
      }
</style>`;
  };

  const getCode = () => {
    if (isYoutube) {
      return getYoutubeCode();
    }

    if (!hasVideos) {
      return 'Please upload a video for mobile and desktop';
    }

    if (!mobileVideo) {
      return 'Please upload a video for mobile';
    }

    if (!desktopVideo) {
      return 'Please upload a video for desktop';
    }

    if (!mobileVideo.width || !desktopVideo.width) {
      return <Spinner />;
    }

    const heroSettings = JSON.parse(project?.heroSettings || '{}');
    const { videoSizeType, width, height } = heroSettings;
    const isResponsive = videoSizeType === STORIES_ITEMS_SIZE_TYPES.responsive;

    if (!isResponsive && width && height) {
      return `<iframe class="tolstoy-hero-iframe " id="hero-tolstoy" src="${url}"
                style="width:${width}px;height: ${height}px"
                scrolling="no" frameborder="0" allow="autoplay *;">
        </iframe>
`;
    }

    return `<iframe class="tolstoy-hero-iframe " id="hero-tolstoy" src="${url}"
                style="width:100%;"
                scrolling="no" frameborder="0" allow="autoplay *;">
        </iframe>
      <style>
          @media (min-width: 575px) {
      .tolstoy-hero-iframe  {
        aspect-ratio: ${desktopVideo?.width / desktopVideo?.height};
      }
    }

    @media (max-width: 575px) {
      .tolstoy-hero-iframe {
        aspect-ratio: ${mobileVideo?.width / mobileVideo?.height};
      }
</style>
`;
  };

  useEffect(() => {
    if (isShopify && !isYoutube) {
      setShowManualInstallCode(false);
    }
  }, [isShopify]);

  const steps = [
    {
      image: <DefaultImage src={heroImage1} />,
      isDisabled: !isAccountInstallationCompleted,
      text: 'Go to Shopify themes -> Page template -> Add section → Tolstoy hero',
      stepHeader: `Add “${projectName}” Hero to theme`,
      startExpanded: !project.installStep,
      currentStepName: EmbedWidgetsShopifyInstall.addSection,
    },
    {
      image: <CopyCodeImage src={heroImage2} />,
      isDisabled: !isAccountInstallationCompleted,
      stepHeader: `Paste “${projectName}”s ID in section`,
      text: 'Copy the ID below and place in the section added to theme in step 1',
      codeToCopy: project?.publishId,
      copyText: 'Copy ID',
      startExpanded: project.installStep === EmbedWidgetsShopifyInstall.addSection,
      currentStepName: EmbedWidgetsShopifyInstall.enterPublishId,
    },
  ];

  const manualKey = {
    stepHeader: 'Copy code and paste on your home page',
    text: (
      <HorizontalFlex>
        <CustomCopyCode $hasVideos={hasVideos} codeToCopy={getCode()} />
      </HorizontalFlex>
    ),
  };

  const currentSteps = showManualInstallCode ? [manualKey] : steps;

  return (
    <SectionWrapper>
      {!isYoutube && (
        <InstallationTitle
          showManualInstallCode={showManualInstallCode}
          setShowManualInstallCode={setShowManualInstallCode}
          isAccountInstallationCompleted={isAccountInstallationCompleted}
        />
      )}
      <StepsContainer>
        {currentSteps.map((step, index) => (
          <InstallStepSimple project={project} {...step} stepNumber={index + 1} key={step.text} />
        ))}
      </StepsContainer>
      {!showManualInstallCode && (
        <InstallSetLiveStep
          startExpanded={project.installStep === EmbedWidgetsShopifyInstall.enterPublishId}
          project={project}
          isDisabled={isDisabled}
        />
      )}
    </SectionWrapper>
  );
};

const SectionWrapper = styled(Gap16VerticalFlex)``;

const StepsContainer = styled(Gap8VerticalFlex)``;

const DefaultImage = styled.img`
  width: 480px;
  max-width: 100%;
  object-fit: contain;
`;

const CopyCodeImage = styled.img`
  width: 240px;
  max-width: 100%;
`;

const CustomCopyCode = styled(CopyCode)<{ $hasVideos: boolean }>`
  max-width: 480px;
  background: ${({ theme }) => theme.colors.neutralLightest};
  text-align: ${({ $hasVideos }) => ($hasVideos ? '' : 'center')};
`;

export default HeroInstallation;
