import React from 'react';
import styled from 'styled-components';
import { TextSmall } from 'shared/react/components/complex/Text';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import Tooltip from 'app/src/basic_components/Tooltip';
import FoldersMenuItems from 'app/src/pages/dashboard/pages/libraries/project_filters/FoldersMenuItems';
import Button from 'shared/react/components/complex/Button';
import CircledPlusIcon from 'app/src/images/CircledPlusIcon';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { RENAME_FOLDER_MODAL_KEY } from 'app/src/constants/modals.constants';
import { MODAL_STATE } from 'app/src/pages/modals/folder_modal/folders.constants';
import Separator from 'shared/react/components/basic/Separator';
import Menu from 'shared/react/components/basic/Menu';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { CREATE_NEW_FOLDER_BUTTON_DATA_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import { FolderType } from 'app/src/types/entities';

const FoldersMenu = ({ anchorEl, setAnchorEl, folders }) => {
  const [, { setCurrentModal, setCurrentModalType, setModalProps }] = useModal();
  const onClose = () => {
    setAnchorEl(false);
  };

  const onCreateFolder = () => {
    setCurrentModal(RENAME_FOLDER_MODAL_KEY);
    setCurrentModalType(MODAL_STATE.new);
    setModalProps({ type: FolderType.projects });
    onClose();
  };

  return (
    <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={onClose}>
      <MenuContent>
        <TopContainer>
          <StyledTextSmall>Folder</StyledTextSmall>
          <Tooltip text="Create a new folder" placement="right">
            <ResetFilterContainer>
              <Button
                padding="0 8px"
                height="24px"
                leftIcon={<CircledPlusIcon />}
                showLeftIcon
                fullWidth
                data-test-id={CREATE_NEW_FOLDER_BUTTON_DATA_TEST_ID}
                onClick={onCreateFolder}
              >
                Create
              </Button>
            </ResetFilterContainer>
          </Tooltip>
        </TopContainer>
        <CustomSeparator />
        <FoldersMenuItems
          distanceFromTopScreen={anchorEl?.getBoundingClientRect?.()?.top}
          folders={folders}
          onClose={onClose}
        />
      </MenuContent>
    </Menu>
  );
};

const MenuContent = styled(VerticalFlex)`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  max-height: 300px;
  overflow: auto;
`;

const StyledTextSmall = styled(TextSmall)`
  ${({ theme }) => theme.colors.gray2}
`;

const CustomSeparator = styled(Separator)`
  background-color: ${({ theme }) => theme.colors.ghostLight};
`;

const TopContainer = styled(HorizontalFlexWrap)`
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  padding: 18px 16px 8px;
  align-items: center;
`;

const ResetFilterContainer = styled(HorizontalFlexWrap)`
  gap: 6px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;
  font-weight: 600;
  height: 100%;

  &:hover {
    transform: scale(1.1);
  }
`;

export default FoldersMenu;
