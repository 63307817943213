import React from 'react';
import { TextBody } from 'shared/react/components/complex/Text';
import { formatText } from 'shared/react/utils/format';
import sanitizeHtml from 'sanitize-html';
import styled, { useTheme } from 'styled-components';
import Attachments from 'shared/react/components/complex/attachments/Attachments';

const TextContent = ({ response, children }) => {
  const theme = useTheme();

  const getText = () => {
    return (
      <StyledTextContent
        textColor={theme.colors.gray27}
        dangerouslySetInnerHTML={{ __html: sanitizeHtml(formatText(response.value)) }}
      />
    );
  };

  return (
    <>
      {children}
      {getText()}
      <Attachments attachments={response.attachments} />
    </>
  );
};

export default TextContent;

const StyledTextContent = styled(TextBody)`
  white-space: pre-line;
  overflow-wrap: break-word;
  width: initial;
`;
