import React from 'react';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import styled from 'styled-components';

type Props = {
  numOfVodAssets: number;
};

const VerticalVideoCardAssetsBadge = ({ numOfVodAssets }: Props) => {
  if (numOfVodAssets <= 1) {
    return null;
  }

  return (
    <LayoutRoot>
      <StyledTextTiny>+{numOfVodAssets}</StyledTextTiny>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  padding: 8px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.white};
  width: fit-content;
  height: 32px;
  background-color: ${({ theme }) => theme.getHexOpacity(theme.colors.white, 25)};
  backdrop-filter: blur(2px);
`;

const StyledTextTiny = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.white};
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
`;

export default VerticalVideoCardAssetsBadge;
