import { css } from 'styled-components';

const flowCardCss = css`
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.08);
  background: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  height: fit-content;
`;

export default flowCardCss;
