import React from 'react';
import ChartBox from '../chart/ChartBox';
import ChartHeader from '../chart/ChartHeader';
import ComposedChart from 'app/src/complex_components/analytics/chart/ComposedChart';
import AnalyticsOverviewRevenueTooltip from './AnalyticsOverviewRevenueTooltip';
import useAnalyticsOverview from 'app/src/hooks/useAnalyticsOverview';
import Utils from 'app/src/utils';
import { ANALYTICS_OVERVIEW_BAR_CHARTS } from 'app/src/constants/analytics.constants';
import { ChartStats } from '../chart/ChartStats';

const AnalyticsOverviewRevenue = () => {
  const {
    currency,
    revenueOvertimeData,
    revenueShouldShowEmptyState,
    revenueShouldShowLoader,
    revenueSubtitle,
    totalRevenueData,
  } = useAnalyticsOverview();
  const { title, bars } = ANALYTICS_OVERVIEW_BAR_CHARTS.VIEWER_REVENUE_BREAKDOWN;

  const yTickFormatter = (value: string) => {
    return Utils.formatNumber({ value, currency });
  };

  return (
    <ChartBox shouldShowLoader={revenueShouldShowLoader}>
      <ChartHeader subtitle={revenueSubtitle} title={title} />
      <ChartStats
        rows={totalRevenueData.breakdown}
        shouldShowEmptyState={revenueShouldShowEmptyState}
      />
      <ComposedChart
        data={revenueOvertimeData}
        bars={bars}
        renderCustomTooltip={AnalyticsOverviewRevenueTooltip}
        shouldShowEmptyState={revenueShouldShowEmptyState}
        yTickFormatter={yTickFormatter}
      />
    </ChartBox>
  );
};

export default AnalyticsOverviewRevenue;
