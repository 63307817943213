import Utils from 'app/src/utils';
import {
  project as ProjectEntity,
  publishMethodOptions,
  vodConnection,
} from 'app/src/types/entities';
import usePlayerUrl from 'app/src/hooks/usePlayerUrl';
import { useProductPageSettingsActions } from 'app/src/context/ProductPageSettingsStore';
import { useVodConnection } from 'app/src/context/VodConnectionStore';
import { ONSITE_TARGET_PAGES } from 'app/src/pages/dashboard/constants/onsiteTargetPages.constants';
import APP_CONFIG from 'app/src/config/app.config';

const WIDGET_PREVIEW_URL_PARAM = {
  bubble: 'widget',
  carousel: 'carousel',
  stories: 'stories',
  embed: 'embed',
};

const CATEGORIES_TO_QUERY_PARAMS = {
  [publishMethodOptions.bubble]: WIDGET_PREVIEW_URL_PARAM.bubble,
  [publishMethodOptions.carousel]: WIDGET_PREVIEW_URL_PARAM.carousel,
  [publishMethodOptions.stories]: WIDGET_PREVIEW_URL_PARAM.stories,
  [publishMethodOptions.embed]: WIDGET_PREVIEW_URL_PARAM.embed,
};

const PREVIEW_SITE_URLS = {
  [ONSITE_TARGET_PAGES.AllPages]: 'https://rubkik.myshopify.com/',
  [ONSITE_TARGET_PAGES.Collections]: 'https://rubkik.gotolstoy.com/collections/all',
  [ONSITE_TARGET_PAGES.OtherPages]: 'https://rubkik.gotolstoy.com/pages/sizing',
  [ONSITE_TARGET_PAGES.ProductPages]:
    'https://rubkik.myshopify.com/products/the-ellie-short-synthetic-lace-front-wig',
};

const useWidgetPreviewUrl = (project = {} as ProjectEntity) => {
  const { playerUrl } = usePlayerUrl(project.id);
  const { isProductPage } = useProductPageSettingsActions();
  const [{ vodConnections }] = useVodConnection();
  const isPDP = isProductPage(project);

  const getType = () => {
    const currentType = CATEGORIES_TO_QUERY_PARAMS[project.publishMethod];

    if (currentType) {
      return currentType;
    }

    if (project.feed) {
      return CATEGORIES_TO_QUERY_PARAMS[publishMethodOptions.stories];
    }

    return CATEGORIES_TO_QUERY_PARAMS[publishMethodOptions.bubble];
  };

  const getBiggestConnectionProductId = () => {
    const biggestProductConnectionMapping = {};

    vodConnections.forEach((connection: vodConnection) => {
      if (!connection.externalProductId) {
        return;
      }

      const currentValue = biggestProductConnectionMapping[connection.externalProductId] || 0;
      biggestProductConnectionMapping[connection.externalProductId] = currentValue + 1;
    });

    let productWithMostConnections = { productId: null, number: 0 };

    Object.entries(biggestProductConnectionMapping).forEach(([key, value]) => {
      if (productWithMostConnections.number < value) {
        productWithMostConnections = { productId: key, number: value as number };
      }
    });

    return productWithMostConnections.productId;
  };

  const getHeight = () => {
    if (project?.verticalOrientation) {
      return '711px';
    }

    return '540px';
  };

  const getMaxWidth = () => {
    if (project?.verticalOrientation) {
      return '400px';
    }

    return '960px';
  };

  const getEmbedString = () => {
    return `?embed=${project.publishId}&max-width=${getMaxWidth()}&height=${getHeight()}`;
  };

  const getQueryString = () => {
    const type = getType();
    let defaultString = `?${type}=${project.publishId}`;

    if (project.appUrl) {
      defaultString = `${defaultString}&appUrl=${project.appUrl}`;
    }

    if (project.publishMethod === publishMethodOptions.embed) {
      return getEmbedString();
    }

    if (project.dynamic) {
      return `${defaultString}&${type}-productId=${getBiggestConnectionProductId()}`;
    }

    return `${defaultString}`;
  };

  const getUrl = () => {
    const queryString = getQueryString();

    if (isPDP) {
      return `${PREVIEW_SITE_URLS[ONSITE_TARGET_PAGES.ProductPages]}${queryString}`;
    }

    const defaultURL =
      PREVIEW_SITE_URLS[project.targetPage] || PREVIEW_SITE_URLS[ONSITE_TARGET_PAGES.AllPages];

    return `${defaultURL}${queryString}`;
  };

  const openSampleUrl = () => {
    const url = getUrl();
    if (
      project.discover ||
      !url ||
      project.publishMethod === publishMethodOptions.landingPage ||
      !project.publishMethod ||
      APP_CONFIG.IS_DEV
    ) {
      Utils.openInNewTab(playerUrl);
      return;
    }

    Utils.openInNewTab(url);
  };

  return { openSampleUrl };
};

export default useWidgetPreviewUrl;
