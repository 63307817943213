import { createActionsHook, createContainer, createHook, createStore } from 'react-sweet-state';
import { getRequest, postRequest } from 'app/src/helpers/API';
import Utils from 'app/src/utils';
import { VideosPageVodAsset } from 'app/src/pages/dashboard/pages/videos/types/videos.types';
import { uuidv4 } from 'widget/src/utils/cache.utils';
import { YOUTUBE } from 'app/src/constants/intergrations.constants';

type State = {
  videos: VideosPageVodAsset[];
  loading: boolean | null;
  pickingFolder: boolean;
  initialized: boolean;
  gotAllVideos: boolean;
  cursor: string;
  videoFoldersById: { [key: string]: string[] };
  rootFolderId?: string | null;
};

const initialState: State = {
  videos: [],
  loading: null,
  pickingFolder: false,
  initialized: false,
  gotAllVideos: false,
  cursor: '',
  videoFoldersById: {},
  rootFolderId: undefined,
};

const actions = {
  fetchByAccount:
    () =>
    async ({ getState, setState, dispatch }, { appKey, owner }) => {
      try {
        const { cursor, videos, gotAllVideos, loading } = getState();
        if (gotAllVideos || loading) {
          return;
        }
        setState({ loading: true });

        const searchParams = new URLSearchParams();
        searchParams.set('appKey', appKey);

        if (cursor) {
          searchParams.set('pageToken', cursor);
        }

        const data = await getRequest('youtube', `/youtube/get-videos?${searchParams.toString()}`);

        if (
          data ===
          'To ensure a smooth connection between YouTube and our system, could you connect with the account belonging to the YouTube channel owner?'
        ) {
          setState({ loading: false, error: data, initialized: true });
          return;
        }

        const newVideos = data.items.map(
          ({
            snippet: { title, thumbnails },
            contentDetails: { videoId: externalId, videoPublishedAt: createdAt },
            ...data
          }) => {
            const posterUrl =
              thumbnails?.standard?.url || thumbnails?.medium?.url || thumbnails?.high?.url;

            const id = uuidv4();
            return {
              ...data,
              name: title,
              title,
              id,
              newId: id,
              stockAsset: { posterUrl },
              externalId: externalId,
              externalCreatedAt: createdAt,
              createdAt,
              owner,
              app: YOUTUBE,
              uploadType: YOUTUBE,
            };
          }
        );

        const currentVideos = [...videos, ...newVideos];

        setState({
          videos: currentVideos,
          cursor: data.nextPageToken,
          gotAllVideos: !data.nextPageToken,
          initialized: true,
          loading: false,
        });
      } catch (e) {
        console.log(e);
        Utils.logError('youtubeStore.fetchByAccount', e);
        setState({ loading: false });
        return e;
      }
    },
  clearYoutube:
    () =>
    ({ setState }) => {
      setState(initialState);
    },
};

type Actions = typeof actions;

const youtubeStore = createStore<State, Actions>({
  initialState,
  actions,
  name: 'youtube',
});

export const useYoutube = createHook(youtubeStore);

export const useYoutubeActions = createActionsHook(youtubeStore);

export const YoutubeContainer = createContainer(youtubeStore, {
  onCleanup:
    () =>
    ({ dispatch }) => {
      dispatch(actions.clearYoutube());
    },
});
