import React from 'react';
import styled, { css } from 'styled-components';
import { vodAsset as VodAsset } from 'src/types/entities';
import { ImageRenditionsService } from 'shared/react/services/assets/images';
import { VIDEO_STATUS } from 'shared/react/constants/video.constants';
import { VerticalFlexCentered } from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import LoadingVideo from 'src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/video_preview/LoadingVideo';

type Props = {
  image: VodAsset;
  shouldHide?: boolean;
};

const VALID_IMAGE_STATUSES = [VIDEO_STATUS.done];

export const ModalImagePreview = ({ image, shouldHide }: Props) => {
  if (shouldHide) {
    return null;
  }

  if (!image || (!VALID_IMAGE_STATUSES.includes(image?.status) && !image?.stockAsset?.posterUrl)) {
    return (
      <LoadingContainer>
        <StyledLoadingVideo />
      </LoadingContainer>
    );
  }

  const imageSrc = ImageRenditionsService.getRenditionUrl(image);

  return <Image src={imageSrc} alt={image.name} />;
};

const ImageDimensionsCss = css`
  width: 300px;
  min-width: 300px;
  height: 533px;
`;

const Image = styled.img`
  ${ImageDimensionsCss};
  object-fit: contain;
  background: ${({ theme }) => theme.colors.black};
`;

const LoadingContainer = styled(VerticalFlexCentered)`
  ${ImageDimensionsCss}
  background: ${({ theme }) => theme.colors.neutralLighter};
`;

const StyledLoadingVideo = styled(LoadingVideo)`
  text-align: center;
  align-items: center;
`;

export default ModalImagePreview;
