import {
  defaults,
  createStore,
  createHook,
  createContainer,
  createActionsHook,
} from 'react-sweet-state';
import { Amplify, Storage } from 'aws-amplify';
import { uploadToS3 } from '../helpers/S3Upload';
import { postRequest } from '../helpers/API';
import Papa from 'papaparse';
import Utils from '../utils';

defaults.devtools = true;

const initialState = {
  quiz: [],
  isLoading: false,
};

const actions = {
  setIsLoading:
    isLoading =>
    ({ setState }) => {
      setState({ isLoading });
    },

  getOrCreateQuiz:
    (project, setLoading) =>
    async ({ setState }) => {
      setState({ isLoading: true });

      // noinspection JSAccessibilityCheck
      Storage.configure({
        AWSS3: {
          bucket: Amplify._config.aws_user_files_s3_bucket,
          region: Amplify._config.aws_project_region,
        },
      });
      let csvUrl;
      try {
        csvUrl = await Storage.get(getQuizUrl(project.publishId));
      } catch (err) {
        console.error('Failure in getting quiz url', err);
      }

      try {
        const quiz = await Utils.downloadAndParseCsv(csvUrl);
        if (quiz.message) {
          throw new Error('Not Found');
        }

        setState({ quiz });
        setLoading('');
        setState({ isLoading: false });

        return quiz;
      } catch (err) {
        if (err.message === 'Not Found') {
          const newQuiz = createNewQuiz(project);
          setState({ quiz: newQuiz });
          setLoading('');
          setState({ isLoading: false });
          return newQuiz;
        }
      }
    },
  publishQuiz:
    (publishId, quiz, project) =>
    async ({ setState }) => {
      try {
        const parsedCsv = Papa.unparse(quiz);
        await uploadToS3('quiz', `public/${getQuizUrl(publishId)}`, parsedCsv);

        if (project) {
          const readableQuiz = createNewQuiz(project, true);
          const readableParsedCsv = Papa.unparse(readableQuiz);
          await uploadToS3('quiz', `public/${getQuizUrl(publishId, true)}`, readableParsedCsv);
        }

        setState({ quiz });
        return true;
      } catch (err) {
        console.error('Failure in publishing new quiz', err);
        return false;
      }
    },
  makeNewQuiz: publishId => async () => {
    try {
      await postRequest('publish-actions', '/actions/projects/quiz', {
        body: {
          publishId,
        },
      });
    } catch (err) {
      console.error('Publish quiz failed', err);
    }
  },
  updateQuiz:
    project =>
    async ({ dispatch }) => {
      const quiz = await dispatch(actions.getOrCreateQuiz(project, () => {}));
      const keyValues = {};
      quiz.forEach(row => {
        const key = row.slice(0, -1).join('');
        keyValues[key] = row[row.length - 1];
      });

      const newQuizOptions = createNewQuiz(project);

      newQuizOptions.forEach((row, i) => {
        const key = row.slice(0, -1).join('');
        newQuizOptions[i][row.length - 1] = keyValues[key];
      });

      await dispatch(actions.publishQuiz(project.publishId, newQuizOptions));
    },
  clearQuizState:
    () =>
    ({ setState }) => {
      setState({ quiz: [] });
    },
};

export const createNewQuiz = (project, readable) => {
  if (!project) {
    return [];
  }

  const orderMapping = getProjectOrderMapping(project);

  const stepsOrder = getProjectOrder(project, orderMapping);

  const quiz = [];
  stepsOrder?.[0]?.answers.forEach(({ key, next, text }) => {
    const quizRow = new Array(stepsOrder.length + 1).fill('');
    quizRow[0] = readable ? text : key;
    if (next === 'end') {
      return quiz.push(quizRow);
    }

    buildQuiz(quiz, stepsOrder, quizRow, orderMapping, next, stepsOrder[0].name, readable);
  });

  return quiz;
};

const buildQuiz = (quiz, steps, quizRow, mapping, nextStepName, previousStepName, readable) => {
  if (mapping[previousStepName] > mapping[nextStepName]) {
    return;
  }

  const step = steps[mapping[nextStepName]];

  if (!step) {
    return;
  }

  step.answers.forEach(({ next, key, text }) => {
    const copy = [...quizRow];
    copy[mapping[nextStepName]] = readable ? text : key;
    if (next === 'end') {
      quiz.push(copy);
    } else {
      buildQuiz(quiz, steps, copy, mapping, next, nextStepName, readable);
    }
  });
};

export const getProjectOrderMapping = project => {
  const orderMapping = {};
  project?.stepsOrder?.forEach((step, i) => {
    orderMapping[step] = i;
  });
  return orderMapping;
};

export const getProjectOrder = (project, orderMapping) => {
  const stepsOrder = [];
  project.steps.items.forEach(step => {
    const index = orderMapping[step.name];
    stepsOrder[index] = step;
  });
  return stepsOrder;
};

const getQuizUrl = (publishId, readable) =>
  `quiz-csv/${publishId}${readable ? '.readable' : ''}.csv`;

const QuizStore = createStore({ initialState, actions, name: 'Quiz' });

export const useQuiz = createHook(QuizStore);

export const useQuizActions = createActionsHook(QuizStore);

export const QuizContainer = createContainer(QuizStore);
