import { postRequest } from '../helpers/API';
import { createContainer, createHook, createStore } from 'react-sweet-state';
import Utils from 'app/src/utils';

const initialState = {
  link: '',
};

const actions = {
  createReferralLink:
    () =>
    async ({ setState }) => {
      const response = await postRequest('firstpromoter', '/firstpromoter/create-referral-link');
      if (response.err) {
        Utils.logError('failed to create referral link', response.err);
      }

      const link = response?.link;
      setState({ link });
      return link;
    },
};

const ReferralStore = createStore({ initialState, actions, name: 'Referral' });

export const useReferral = createHook(ReferralStore);

export const ReferralContainer = createContainer(ReferralStore);
