import React from 'react';
import styled from 'styled-components';
import LeftSidebar from './LeftSidebar';
import RightPanel from './RightPanel';

const AccountPage = () => {
  return (
    <ContentContainer>
      <Content>
        <LeftSidebar />
        <RightPanel />
      </Content>
    </ContentContainer>
  );
};

const ContentContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background-color: white;
  overflow-y: hidden;

  @media (${({ theme }) => theme.breakpoints.dashboardTabletMax}) {
    overflow-y: auto;
    flex-direction: column;
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;
  overflow: hidden;

  @media (${({ theme }) => theme.breakpoints.dashboardTabletMax}) {
    grid-template-columns: auto;
    grid-template-rows: auto 1fr;
  }
`;

export default AccountPage;
