import React from 'react';
import styled from 'styled-components';
import PlayIcon from 'shared/react/images/PlayIcon';
import { PLAY_BUTTON_DATA_TEST_ID } from '../../constants/dataTestIds.constants';

const StartScreenPlayButton = ({
  showStartScreen,
  hidePlay,
  transitioningFromVideoId,
  ...props
}) => {
  return (
    <LayoutRoot
      shouldShow={!showStartScreen || !hidePlay}
      transitioningFromVideoId={transitioningFromVideoId}
      data-test-id={PLAY_BUTTON_DATA_TEST_ID}
      {...props}
      role="button"
    >
      <PlayIcon />
    </LayoutRoot>
  );
};

export default StartScreenPlayButton;

const LayoutRoot = styled.div`
  transition: all 0.3s ease-in-out;
  visibility: ${({ shouldShow }) => (shouldShow ? 'visible' : 'hidden')};
  opacity: ${({ shouldShow }) => (shouldShow ? 1 : 0)};
  transform: scale(${({ shouldShow }) => (shouldShow ? 1 : 0.9)});
  animation-name: ${({ transitioningFromVideoId }) => (transitioningFromVideoId ? 'jumping' : '')};
  animation-duration: ${({ transitioningFromVideoId }) => (transitioningFromVideoId ? '0.5s' : '')};
  animation-iteration-count: ${({ transitioningFromVideoId }) =>
    transitioningFromVideoId ? 'infinite' : ''};
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`;
