import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';
import { TextH6 } from 'shared/react/components/complex/Text';
import StandardModal from './StandardModal';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import useUploadFile from 'app/src/hooks/useUploadFile';
import Message from 'app/src/pages/creation_flow/right_section/Message';
import { v4 as uuidv4 } from 'uuid';
import { LOADING_STATE } from 'app/src/pages/creation_flow/creationFlowConstants';
import { useVideos } from 'app/src/context/VideosStore';
import { useUser } from 'app/src/context/userStore/UserStore';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';
import Utils from 'app/src/utils';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import UploadFilesEmptyState from 'app/src/complex_components/upload_files/UploadFilesEmptyState';
import UploadFilesVideoPreview from 'app/src/complex_components/upload_files/UploadFilesVideoPreview';
import Separator from 'shared/react/components/basic/Separator';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import { VIDEO_STATUS } from 'shared/react/constants/video.constants';

const UploadFilesModal = () => {
  const inputRef = useRef();
  const uploadProgressRef = useRef();
  const [uploadedBlob, setUploadedBlob] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [uploadState, setUploadState] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [videoReady, setVideoReady] = useState(false);
  const [numErrors, setNumErrors] = useState(0);

  const [
    {
      modalProps: { onDone },
    },
    { clearModalState },
  ] = useModal();
  const { checkFile } = useUploadFile();
  const [{ user = {} }] = useUser();
  const [{ data: videos }, { createVideo, uploadVideoAsset, deleteVideo }] = useVideos();
  const [, { setErrorSnackbar }] = useSnackBar();

  const onSelectFilesClick = () => {
    setUploadedBlob(null);

    inputRef.current?.click();
  };

  const onDropFiles = e => {
    e.preventDefault();
    e.stopPropagation();
    const files = [...e.dataTransfer.files];
    onUploadFiles(files);
  };

  const onUploadFiles = async files => {
    if (files.length > 1) {
      await uploadMultipleFiles(files);
      return;
    }

    const { file } = await checkFile(files[0]);
    if (file) {
      setUploadedBlob(file);
    }
  };

  const onFilesSelected = e => {
    const files = [...e.target.files];
    onUploadFiles(files);
  };

  const resetState = () => {
    setUploadedBlob(null);
  };

  const handleUploadProgress = (uuid, progress) => {
    uploadProgressRef.current = progress;
    setUploadProgress(progress);
  };

  const onCreateVideoFailure = async (err, video) => {
    const error = Utils.enrichErrorMessage(err, 'Video upload failed');
    Sentry.captureException(error);
    console.log('onVideoUploadCompleted failure', error);

    if (video) {
      await deleteVideo({ id: video.id });
    }
    setNumErrors(num => num + 1);
  };

  const onCreateNewVideo = async ({ blob, mirror, videoInvalid, multiVideoLength }) => {
    const uuid = uuidv4();

    setUploadState(LOADING_STATE.uploadingVideo);
    setUploadProgress('0%');
    setIsLoading(true);

    const name = blob.name || `Recorded Video #${videos.length + 1}`;
    const { owner } = user;

    const video = await createVideo({
      id: uuid,
      owner,
      name,
      status: VIDEO_STATUS.uploading,
      uploadType: 'upload',
      mirror,
      originalSize: `${Utils.sizeInBytesToMb(blob.size)}MB`,
    });

    const response = await uploadVideoAsset(
      history,
      {
        uuid,
        blob,
        videoInvalid,
        owner,
        video: video || videos[0],
      },
      handleUploadProgress
    );

    if (response?.error || !response?.data) {
      await onCreateVideoFailure(response.error, response.video);
      setIsLoading(false);
      return;
    }

    if (multiVideoLength) {
      return;
    }

    setUploadProgress(null);
    uploadProgressRef.current = null;
    setUploadProgress(null);
    onDone?.();
    clearModalState();
  };

  const uploadMultipleFiles = async files => {
    const promises = files.map(checkFile);

    const approvedVideos = await Promise.all(promises);

    const videosUploadPromises = approvedVideos.flatMap(({ file }) => {
      if (!file) {
        console.log('upload failed for file', file);
        return [];
      }

      return onCreateNewVideo({
        blob: file,
        mirror: false,
        videoInvalid: false,
        multiVideoLength: files.length,
      });
    });

    onClose();
    await Promise.all(videosUploadPromises);

    if (numErrors > 0) {
      setErrorSnackbar(
        `There has been an error when uploading  ${numErrors} out of ${files.length} videos. Please try uploading them again.`
      );
    }

    setIsLoading(false);

    setUploadProgress(null);
    uploadProgressRef.current = null;
  };

  const onClose = () => {
    if (isLoading) {
      return;
    }
    clearModalState();
  };

  return (
    <StyledStandardModal open={true} onClose={onClose}>
      <LayoutRoot>
        <TextContainer>
          <TextH6>Upload files</TextH6>
        </TextContainer>
        <Separator />
        <UploadFilesContent>
          <UploadFilesEmptyState
            onDropFiles={onDropFiles}
            onSelectFilesClick={onSelectFilesClick}
          />
          <UploadFilesVideoPreview
            videoReady={videoReady}
            setVideoReady={setVideoReady}
            uploadedBlob={uploadedBlob}
            onCreateNewVideo={onCreateNewVideo}
          />
          <Message
            uploadProgress={uploadProgress}
            isUploadFile={true}
            resetVideoState={resetState}
            onCreateNewVideo={onCreateNewVideo}
            uploadState={uploadState}
            uploadedBlob={uploadedBlob}
            messageText={'Upload this video?'}
          />
        </UploadFilesContent>
        <HiddenInput
          onChange={onFilesSelected}
          type="file"
          accept="video/*"
          ref={inputRef}
          multiple
        />
      </LayoutRoot>
    </StyledStandardModal>
  );
};

const StyledStandardModal = styled(StandardModal)`
  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    height: 100%;
    width: 100%;
    margin: 0;
    border-radius: 0;
  }

  @media (${({ theme }) => theme.breakpoints.smallPhoneMaxHeight}) {
    height: 100%;
    width: 100%;
  }
`;

const LayoutRoot = styled(Gap16VerticalFlex)`
  width: 980px;
  height: 580px;
  max-height: 100%;
  max-width: 100%;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    height: 100%;
  }

  @media (${({ theme }) => theme.breakpoints.smallPhoneMaxHeight}) {
    height: 100%;
  }
`;

const TextContainer = styled(HorizontalFlexWrap)``;

const UploadFilesContent = styled.div`
  display: grid;
  overflow: hidden;
  border-radius: 16px;
  flex-grow: 1;
  margin: 20px;

  & > * {
    grid-row: 1;
    grid-column: 1;
  }

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    margin: 0px;
  }
`;

const HiddenInput = styled.input`
  display: none;
`;

export default UploadFilesModal;
