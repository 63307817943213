import { EmailAsset } from './email-assets';
import { escape } from 'lodash';
import { OPEN_EMAIL_IMAGE } from 'app/src/config/app.config';
import { project as Project } from 'app/src/types/entities';
import { getAutomationToolEmailVariable } from './automation-tool.utils';
import { EMAIL_LAYOUT_CAROUSEL, EMAIL_LAYOUT_SINGLE_TILE } from './email-layout-type.constants';

type SnippetBaseProps = {
  automationTool?: string;
  getAssetActionUrl: (asset: EmailAsset) => string;
  pixelTracking: boolean;
  project: Project;
  trackingEmail?: string;
};

type SingleAssetSnippetProps = SnippetBaseProps & {
  asset: EmailAsset;
};

type MultiAssetsSnippetProps = SnippetBaseProps & {
  assets: EmailAsset[];
};

type EmailSnippetFactoryProps = SnippetBaseProps & {
  assets: EmailAsset[];
  email: string;
  emailLayoutType: { id: string };
};

const CAROUSEL_STYLES = `<style type="text/css">
    @media only screen and (min-width: 520px) {
        .tolstoy-row {
            width: 500px !important;
        }
        .tolstoy-row .tolstoy-col {
            vertical-align: top;
            width: 166px !important;
        }
    }
    @media (max-width: 520px) {
        .tolstoy-row-container {
            max-width: 100% !important;
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
        .tolstoy-row .tolstoy-col {
            min-width: 220px !important;
            max-width: 100% !important;
            display: block !important;
        }
        .tolstoy-row {
            width: 100% !important;
        }
        .tolstoy-col {
            width: 100% !important;
        }
        .tolstoy-col > div {
            margin: 0 auto;
        }
        .tolstoy-row .tolstoy-col {
            min-width: 0 !important;
            display: table-cell !important;
            width: 33.33% !important;
        }
    }
    .tolstoy-carousel table, .tolstoy-carousel tr, .tolstoy-carousel td {
        vertical-align: top;
        border-collapse: collapse;
    }
    .ie-container table, .mso-container table {
        table-layout: fixed;
    }
</style>`;

const appendUrlTrackingEmail = (url: string, trackingEmail: string) => {
  const urlParams = new URL(url).search;
  if (urlParams) {
    /* trackingEmail could contain automation tool variable, like {{ email }}.
     * In this case, encodeURIComponent may produce wrong result, because it will encode { and }
     * and automation tool might not be able to replace it.
     */
    return `${url}&email=${escape(trackingEmail)}`;
  }

  return `${url}?email=${escape(trackingEmail)}`;
};

const getPixelTrackingSnippet = ({
  pixelTracking,
  project,
  trackingEmail = '',
}: SnippetBaseProps) => {
  if (!pixelTracking) {
    return '';
  }

  const emailImage = appendUrlTrackingEmail(`${OPEN_EMAIL_IMAGE}/${project?.id}`, trackingEmail);
  return `<img src='${escape(
    emailImage
  )}' alt='Tolstoy' style="display: none; width: 1px; height: 1px;"/>`;
};

export const getSingleTileSnippet = (props: SingleAssetSnippetProps): string => {
  const { project, trackingEmail, getAssetActionUrl, asset } = props;

  const href = appendUrlTrackingEmail(getAssetActionUrl(asset), trackingEmail);

  const styleString = `border-radius:10px;max-width:360px;max-height:360px;`;
  // Keep this as oneliner because Hubspot doesn't like newlines :(
  const code = `<div style='max-width: 360px; max-height: 360px;'>${getPixelTrackingSnippet(
    props
  )}<a href='${href}' target="_blank">
    <!--[if !mso]><!-->
    <img src='${encodeURI(asset.gifUrl)}' alt='Tolstoy' style='${styleString}'/>
    <!--<![endif]-->

    <!--[if gte mso 15]>
    <img src='${encodeURI(asset.gifUrl)}' alt='Tolstoy' style='${styleString}'/>
    <![endif]-->

    <!--[if lte mso 14]>
    <img src='${encodeURI(asset.imageUrl)}' alt='Tolstoy' style='${styleString}'/>
    <![endif]-->
  </a>
    <br/>▶️ <a href='${href}' target="_blank">${escape(project?.name || '')}</a>
</div><br/>`;

  return code;
};

const getCarouselTileSnippet = ({
  asset,
  getAssetActionUrl,
  trackingEmail,
}: SingleAssetSnippetProps): string => {
  const href = appendUrlTrackingEmail(getAssetActionUrl(asset), trackingEmail);
  const imgStyle =
    'outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: inline-block !important;border: none;float: none;height: auto; width: 100%;max-width: 158.67px; object-fit: cover;';

  return `<!--[if (mso)|(IE)]>
<td align="center" width="166"
  style="width: 166px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;"
  valign="top"><![endif]-->
<div class="tolstoy-col" style="max-width: 320px;min-width: 166.67px; display: table-cell;vertical-align: top;">
  <div style="height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px; align-content: center;">
    <!--[if (!mso)&(!IE)]><!-->
    <div style="box-sizing: border-box; height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px; align-content: center;">
      <!--<![endif]-->
      <table role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
        <tbody>
          <tr>
            <td style="overflow-wrap:break-word;word-break:break-word;padding:0px 4px;font-family:arial,helvetica,sans-serif;" align="left">
              <table width="100%" cellpadding="0" cellspacing="0" border="0">
                <tr>
                  <td style="padding-right: 0px;padding-left: 0px;" align="center">
                    <a href="${href}" target="_blank">
                      <!--[if !mso]><!-->
                      <img align="center" border="0"
                        src="${asset.gifUrl}"
                        alt="Tolstoy" title=""
                        style="${imgStyle}"
                        width="158.67" />
                      <!--<![endif]-->

                      <!--[if gte mso 15]>
                      <img align="center" border="0"
                        src="${asset.gifUrl}"
                        alt="Tolstoy" title=""
                        style="${imgStyle}"
                        width="158.67" />
                      <![endif]-->

                      <!--[if lte mso 14]>
                      <img align="center" border="0"
                        src="${asset.imageUrl}"
                        alt="Tolstoy" title=""
                        style="${imgStyle}"
                        width="158.67" />
                      <![endif]-->
                    </a>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    <!--[if (!mso)&(!IE)]><!-->
    </div>
    <!--<![endif]-->
  </div>
</div>
<!--[if (mso)|(IE)]>
</td>
<![endif]-->`;
};

export const getCarouselSnippet = (props: MultiAssetsSnippetProps): string => {
  const { assets } = props;
  const carouselTiles = assets.map(asset => getCarouselTileSnippet({ ...props, asset })).join('');

  return `<!--[if IE]><div class="ie-container"><![endif]-->
<!--[if mso]><div class="mso-container"><![endif]-->${getPixelTrackingSnippet(
    props
  )}${CAROUSEL_STYLES}
<table class="tolstoy-carousel"
  style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;min-width: 220px;Margin: 0 auto; width:100%"
  cellpadding="0" cellspacing="0">
  <tbody>
    <tr style="vertical-align: top">
      <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top">
        <!--[if (mso)|(IE)]>
        <table width="100%" cellpadding="0" cellspacing="0" border="0">
          <tr>
            <td align="center"><![endif]-->
              <div class="tolstoy-row-container" style="padding: 8px 0px;background-color: transparent">
                <div class="tolstoy-row"
                  style="margin: 0 auto;min-width: 220px;max-width: 500px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">
                  <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                    <!--[if (mso)|(IE)]>
                    <table width="100%" cellpadding="0" cellspacing="0" border="0">
                      <tr>
                        <td style="padding: 8px 0px;background-color: transparent;" align="center">
                          <table cellpadding="0" cellspacing="0" border="0" style="width:500px;">
                            <tr style="background-color: transparent;"><![endif]-->
                              ${carouselTiles}
                        <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
                  </div>
                </div>
            </div>
            <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
        </td>
    </tr>
    </tbody>
</table>
<!--[if mso]></div><![endif]-->
<!--[if IE]></div><![endif]-->`;
};

export const getEmailSnippet = ({
  assets,
  automationTool,
  email,
  emailLayoutType,
  getAssetActionUrl,
  pixelTracking,
  project,
}: EmailSnippetFactoryProps) => {
  if (!assets.length) {
    console.warn('No assets to render');
    return '';
  }

  const trackingEmail = email || getAutomationToolEmailVariable(automationTool);
  const baseProps = {
    automationTool,
    getAssetActionUrl,
    pixelTracking,
    project,
    trackingEmail,
  };

  switch (emailLayoutType.id) {
    case EMAIL_LAYOUT_SINGLE_TILE:
      return getSingleTileSnippet({ ...baseProps, asset: assets[0] });
    case EMAIL_LAYOUT_CAROUSEL:
      return getCarouselSnippet({ ...baseProps, assets: assets });
    default:
      console.error('Unknown rendering type', emailLayoutType.id);
      return '';
  }
};
