import React from 'react';
import styled from 'styled-components';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

const OnsiteArchiveEmptyState = () => <LayoutRoot>No archived projects found</LayoutRoot>;

const LayoutRoot = styled(HorizontalFlexCentered)`
  width: 100%;
  height: 100%;
`;

export default OnsiteArchiveEmptyState;
