import React, { useRef, useState } from 'react';
import {
  PageContainer,
  PageMain,
  OverflowContainer,
} from 'app/src/pages/dashboard/components/page/Page';
import Filters from 'app/src/pages/dashboard/components/filters/Filters';
import { useProjects } from 'src/context/ProjectsStore';
import { useVirtualizedList } from 'src/hooks/useInfiniteScroll';
import { track } from 'src/helpers/Tracker';
import { useDashboard } from 'app/src/context/ui_store/DashboardStore';
import useOffsiteFilters from 'app/src/pages/dashboard/pages/offsite/hooks/useOffsiteFilters';
import { defaultFilterKeys } from 'src/pages/dashboard/components/filters/filtersConfig';
import OffsiteGrid from './components/offsite_grid/OffsiteGrid';
import NumberOfEntities from 'app/src/pages/dashboard/pages/videos/components/number-of-entities/NumberOfEntities';
import Loading from 'app/src/pages/dashboard/components/loading/Loading';
import useUpdateProjectsMultiSelectState from 'src/hooks/useUpdateProjectsMultiSelectBarState';
import TopBar from '../../components/top-bar/TopBar';
import Sidebar from './components/sidebar/Sidebar';
import NewFromScratchOffsiteButton from './components/NewFromScratchOffsiteButton';
import NewFromCaseOffsiteButton from './components/NewFromCaseOffsiteButton';
import useAllOffsiteProjects from 'app/src/pages/dashboard/pages/offsite/hooks/useAllOffsiteProjects';
import { useApps } from 'app/src/context/AppsStore';

const Offsite = () => {
  const [{ fetchedProjects }] = useProjects();
  const [{ offsiteFilters }, { setOffsiteFilters }] = useDashboard();
  const [{ shopify: isShopify }, { getAppUsingUrl }] = useApps();
  const [selectedProjects, setSelectedProjects] = useState([]);
  const containerRef = useRef();

  const projects = useOffsiteFilters();
  const allOffsiteProjects = useAllOffsiteProjects();

  const { renderCount, isDone, isLoading } = useVirtualizedList(
    containerRef,
    projects.length,
    null,
    offsiteFilters
  );

  const unselectAll = () => {
    setSelectedProjects([]);
  };

  const selectAll = () => {
    setSelectedProjects(projects.map(p => p.id));
  };

  const toggleSelected = videoId => {
    if (!selectedProjects.includes(videoId)) {
      track('Offsite Card Selected');
      setSelectedProjects([...selectedProjects, videoId]);
      return;
    }

    track('Offsite Card Selection Canceled');
    setSelectedProjects(selectedProjects.filter(id => id !== videoId));
  };

  const projectsToRender = projects.slice(0, renderCount);
  const noFilteredProjects = projects.length === 0;
  const noOffsiteProjects = allOffsiteProjects.length === 0;

  useUpdateProjectsMultiSelectState({
    selectedIds: selectedProjects,
    isOnsite: false,
    onCancel: unselectAll,
    selectAll,
    filters: offsiteFilters,
  });

  const getTopBarLeftContent = () => {
    if (noOffsiteProjects) {
      return 'Start with a popular use case';
    }

    return (
      <Filters
        availableFilters={defaultFilterKeys}
        existingFilters={offsiteFilters}
        setFilters={setOffsiteFilters}
      />
    );
  };

  const getTopBarRightContent = () => {
    if (noFilteredProjects || !isShopify) {
      return <NewFromScratchOffsiteButton />;
    }

    return <NewFromCaseOffsiteButton />;
  };

  return (
    <PageContainer>
      {noOffsiteProjects ? null : <Sidebar />}
      <PageMain>
        <TopBar leftContent={getTopBarLeftContent()} rightContent={getTopBarRightContent()} />
        <OverflowContainer ref={containerRef}>
          <OffsiteGrid
            items={projectsToRender}
            selectedItems={selectedProjects}
            toggleSelected={toggleSelected}
            fetchedProjects={fetchedProjects}
          />
          <NumberOfEntities
            text={`${projects.length} Tolstoys shown`}
            shouldShow={isDone && projectsToRender.length}
          />
          <Loading isLoading={isLoading} />
        </OverflowContainer>
      </PageMain>
    </PageContainer>
  );
};

export default Offsite;
