import React from 'react';
import styled from 'styled-components';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import CarouselDot from './CarouselDot';

const CarouselDots = ({ numberOfDots, onClick, selectedDot }) => {
  return (
    <LayoutRoot>
      {[...Array(numberOfDots)].map((_, index) => {
        return (
          <CarouselDot
            key={index}
            index={index}
            onClick={onClick}
            selected={index === selectedDot}
          />
        );
      })}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlexWrap)`
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
`;

export default CarouselDots;
