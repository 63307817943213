import React from 'react';

const TiktokIcon = () => (
  <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.75 8.703c-1.758.04-3.398-.508-4.805-1.523v6.992c0 2.656-1.64 5-4.14 5.937a6.3 6.3 0 0 1-6.992-1.757 6.398 6.398 0 0 1-.82-7.227C3.242 8.82 5.82 7.531 8.476 7.883v3.515a2.95 2.95 0 0 0-3.282 1.055c-.703 1.055-.703 2.422.04 3.438.742 1.015 2.07 1.445 3.242 1.054a2.931 2.931 0 0 0 2.03-2.773V.5h3.438c0 .313 0 .586.078.898a4.62 4.62 0 0 0 2.11 3.125c.742.508 1.68.782 2.617.782v3.398Z"
      fill="#090A0B"
    />
  </svg>
);

export default TiktokIcon;
