import React from 'react';
import TextButton from 'app/src/complex_components/TextButton';
import styled from 'styled-components';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import { TextH4 } from 'shared/react/components/complex/Text';
import Gap16HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap16HorizontalFlexWrap';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import {
  CategoryHeader,
  Textarea,
  SubHeader,
} from 'app/src/layouts/tolstoy_empty_screen/emptyScreenCss';

const DowngradeMainReasons = ({
  selectedOptions,
  otherValue,
  setOtherValue,
  onAnswerClick,
  additionalInfoValue,
  setAdditionalInfoValue,
}) => {
  const options = [
    { title: 'Too expensive', value: 'Too expensive' },
    { title: "Didn't see value", value: "Didn't see value" },
    { title: 'Too complicated', value: 'Too complicated' },
    { title: "Couldn't create content", value: "Couldn't create content" },
    { title: 'Bugs', value: 'Bugs' },
    { title: 'Switched to different product', value: 'Switched to different product' },
    { title: 'Moving companies', value: 'Moving companies' },
    { title: 'Missing feature', value: 'Missing feature' },
    { title: 'Poor customer service', value: 'Poor customer service' },
    { title: 'Other', type: 'input', value: otherValue, setValue: setOtherValue },
  ];

  return (
    <>
      <HeaderContainer>
        <Header>What is the main reason you want to cancel your subscription? </Header>
        <SubHeader>
          This information is super valuable to us as we try to improve the product. Please feel
          free to be brutal. We will be eternally grateful for your honesty.
        </SubHeader>
      </HeaderContainer>
      <ChoicesContainer>
        <CategoryHeader>Choose what best describes your issues*</CategoryHeader>
        <Choices>
          {options.map(({ title, value, type, setValue }) => {
            const isSelected = selectedOptions.includes(value) || (type && !!value);
            return (
              <TextButton
                value={value}
                onClick={value => onAnswerClick(value)}
                isSelected={isSelected}
                key={title}
                type={type}
                setValue={setValue}
                title={title}
              />
            );
          })}
        </Choices>
      </ChoicesContainer>
      <AdditionalInfoContainer>
        <CategoryHeader>Please write any additional info here</CategoryHeader>
        <Textarea
          value={additionalInfoValue}
          onChange={e => setAdditionalInfoValue(e.target.value)}
          placeholder="Your sincere opinion here"
        />
      </AdditionalInfoContainer>
    </>
  );
};

const HeaderContainer = styled(Gap16VerticalFlex)`
  margin: 56px 0;
  max-width: 76ch;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    margin: 40px 0;
  }
`;

const Header = styled(TextH4)`
  text-align: center;
  font-weight: 700;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    font-size: 24px;
  }
`;

const Choices = styled(Gap16HorizontalFlexWrap)`
  max-width: 831px;
  justify-content: center;
`;

const ChoicesContainer = styled(VerticalFlex)`
  align-items: center;
  gap: 24px;
`;

const AdditionalInfoContainer = styled(ChoicesContainer)`
  margin-top: 62px;
  height: 100%;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    margin-top: 40px;
  }
`;

export default DowngradeMainReasons;
