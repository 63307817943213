import React from 'react';
import styled from 'styled-components';
import SadTolstoyLogo from 'player/src/assets/SadTolstoyLogo';
import { BolderTextSmall } from 'shared/react/components/complex/Text';
import VerticalFlex from '../common/VerticalFlex';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';
import { VIDEO_WASNT_FOUND_DATA_TEST_ID } from 'player/src/constants/dataTestIds.constants';

const VideoWasntFound = () => {
  const [, { t }] = useTranslation('playerWrapper');

  return (
    <LayoutRoot data-test-id={VIDEO_WASNT_FOUND_DATA_TEST_ID}>
      <SadTolstoyLogo />
      <BolderTextSmall fontSize={20}>{t('videoWasntFound')}</BolderTextSmall>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  gap: 24px;
  align-items: center;
  position: absolute;
  top: 212px;
  width: 100%;
`;

export default VideoWasntFound;
