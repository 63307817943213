import {
  EXTENSION_EVENT_KEY,
  OPEN_EXTENSION,
  LOGOUT,
  LOGIN,
  PROJECT_CREATED,
  SEND_URL,
  RECORDING_DATA_PROCESSED,
  IS_UPLOAD_FINISHED,
  SHOULD_GET_SIGN_URL,
} from '../constants/extension.constants';
import Utils from 'app/src/utils';

const sendExtensionEvent = detail => {
  if (Utils.getIsDev()) {
    return;
  }

  const newEvent = new CustomEvent(EXTENSION_EVENT_KEY, { detail });
  window.dispatchEvent(newEvent);
};

export const sendExtensionLogoutEvent = () => {
  sendExtensionEvent({ name: LOGOUT });
};

export const sendExtensionSignInEvent = () => {
  if (Utils.getIsDev()) {
    return;
  }

  const keys = Object.keys(localStorage);
  const sessionKey = Object.keys(sessionStorage);
  const storage = [];
  keys.forEach(key => {
    const value = localStorage.getItem(key);
    storage.push({ key, value });
  });

  sessionKey.forEach(key => {
    const value = sessionStorage.getItem(key);
    storage.push({ key, value });
  });

  const newEvent = new CustomEvent(EXTENSION_EVENT_KEY, {
    detail: { name: LOGIN, state: storage },
  });

  setTimeout(() => {
    window.dispatchEvent(newEvent);
  }, 1000);
};

export const sendIsUploadFinished = () => {
  sendExtensionEvent({ name: IS_UPLOAD_FINISHED });
};

export const sendShouldGetSignUrlEvent = () => {
  sendExtensionEvent({ name: SHOULD_GET_SIGN_URL });
};

export const sendUploadSignUrlEvent = uploadURL => {
  sendExtensionEvent({ name: SEND_URL, uploadURL });
};

export const sendRecordingDataProcessedEvent = () => {
  sendExtensionEvent({ name: RECORDING_DATA_PROCESSED });
};

export const sendOpenExtension = (from, extraData) => {
  sendExtensionEvent({ name: OPEN_EXTENSION, state: { from, extraData } });
};

export const sendTolstoyCreated = (publishId, gifUrl) => {
  sendExtensionEvent({ name: PROJECT_CREATED, state: { publishId, gifUrl } });
};
