import React, { useState } from 'react';

type AnalyticsPlayersManagerCtx = {
  activePlayer: string | null;
  setActivePlayer: (id: string | null) => void;
  togglePlayer: (id: string) => void;
};

const Context = React.createContext<AnalyticsPlayersManagerCtx | null>(null);

export const useAnalyticsPlayersManager = () => {
  const ctx = React.useContext(Context);
  if (!ctx) {
    throw new Error('useAnalyticsPlayersManager must be used within a AnalyticsPlayersManager');
  }

  return ctx;
};

const AnalyticsPlayersManager = ({ children }) => {
  const [activePlayer, setActivePlayer] = useState<string | null>(null);
  const togglePlayer = (id: string) => {
    if (activePlayer === id) {
      setActivePlayer(null);
      return;
    }
    setActivePlayer(id);
  };

  return (
    <Context.Provider
      value={{
        activePlayer,
        setActivePlayer,
        togglePlayer,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AnalyticsPlayersManager;
