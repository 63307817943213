import React from 'react';

const UniqueIcon = ({ fill = '#7D8087' }) => {
  return (
    <svg width="26" height="21" viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.188 1.75h-3.946c.235-.234.43-.508.469-.86.039-.195-.078-.39-.273-.39H16.75c-2.773 0-5 2.266-5 5h-5a3.6 3.6 0 0 0-2.813 1.328V6.75A3.443 3.443 0 0 0 .5 10.188v2.187c0 .352.273.625.625.625h.625a.642.642 0 0 0 .625-.625v-2.188c0-.507.234-.937.625-1.21v.273c0 1.094.43 2.07 1.172 2.734L3.156 14.68c-.156.468-.195 1.015-.078 1.484l.86 3.398c.156.586.624.938 1.21.938h1.29c.82 0 1.406-.742 1.21-1.523l-.82-3.36.938-2.46 3.984.859v5.234c0 .703.547 1.25 1.25 1.25h1.25c.664 0 1.25-.547 1.25-1.25v-6.29c.742-.82 1.25-1.874 1.25-3.085V5.852l.625.273.703 1.484c.313.586 1.016.86 1.602.625l1.25-.546c.508-.157.82-.625.82-1.133V3.78l3.594-1.445c.273-.156.117-.586-.157-.586Zm-5.938 2.5a.617.617 0 0 1-.625-.625c0-.313.273-.625.625-.625.313 0 .625.313.625.625a.642.642 0 0 1-.625.625Z"
        fill={fill}
      />
    </svg>
  );
};

export default UniqueIcon;
