export const TOLSTOY_CACHE_KEYS = {
  TOLSTOY_HIDE_WIDGET_STORAGE_KEY: 'tolstoy-hide-widget',
  TOLSTOY_SESSION_ID_KEY: 'tolstoy-session-id',
  TOLSTOY_SESSION_UNIQUE_ID_KEY: 'tolstoy-session-unique-id',
  TOLSTOY_PRODUCT_RECOMMENDATIONS: 'tolstoy-product-recommendation',
  TOLSTOY_ANONYMOUS_ID: 'tolstoy-anonymousId',
  TOLSTOY_REPLY: 'tolstoy-reply',
  TOLSTOY_GROUP: 'tolstoy-group',
  TOLSTOY_PUBLISH_ID: 'tolstoy-publishId',
  TOLSTOY_ACTIVITY_KEY: 'tolstoy-activity',
  TOLSTOY_NOT_VISIBLE_OPENED_PUBLISH_ID_KEY: 'tolstoy-not-visible-opened-publish-id',
  TOLSTOY_INTERACTION_DATE: 'tolstoy-interaction-date',
  IS_SHOPIFY_CART_ANONYMOUS_ID_SET_KEY: 'tolstoy-is-shopify-cart-anonymous-id-set',
  TOLSTOY_COOKIE_POLICY: 'tolstoy-cookie-policy',
  TOLSTOY_RECREATE_RESOLUTIONS_PREFIX: 'tolstoy-recreate-resolutions',
  TOLSTOY_AB_TEST_GROUP: 'tolstoy-ab-test-group'
};

export const COOKIE_POLICIES = {
  accepted: 'accepted',
  rejected: 'rejected'
};
