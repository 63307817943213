import { useState, useEffect, useRef } from 'react';

import Utils from 'shared/react/utils/utils';
import useFeedModal from 'shared/react/components/complex/context/hooks/useFeedModal';
import useFeedSearch from 'shared/react/components/complex/context/hooks/useFeedSearch';
import useFeedSearchTracking from 'shared/react/components/complex/feed/feed_search/hooks/useFeedSearchTracking';
import { TOGGLE_FEED_CLOSE_BUTTON_MESSAGE } from 'shared/react/constants/messages.constants';

const useFeedSearchActions = () => {
  const [{ currentModal }, { popModal }] = useFeedModal();
  const { currentVideoId: videoId, currentVideoUrl, currentVideoRef } = currentModal.props;
  const { videos, trendingVideos, isLoading, getVideosBySearchTerm, loadNewVideoFeed } =
    useFeedSearch({ videoId, trackingData: { currentVideoUrl } });
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const isVideoClicked = useRef(false);
  const { trackVideoClick, trackSearchModalClosed } = useFeedSearchTracking({
    videos,
    trendingVideos,
    searchTerm,
  });

  const actions = {
    setIsOpen,
    setSearchTerm,
    getVideosBySearchTerm,
    toggleFeedCloseButton: ({ hideCloseButton }) => {
      Utils.postMessageToWindow({
        eventName: TOGGLE_FEED_CLOSE_BUTTON_MESSAGE,
        hideCloseButton,
        hideOnFullScreen: true,
      });
    },

    handleCloseModal: ({ playInitialVideo = true }) => {
      if (playInitialVideo) {
        currentVideoRef?.current?.play();
      }

      setIsOpen(false);
      popModal();
    },

    handleVideoClick: async ({ feedType, video, index }) => {
      const { id, videoUrl, score } = video;

      trackVideoClick({ id, videoUrl, score, index, feedType });
      isVideoClicked.current = true;
      loadNewVideoFeed({ feedType, videoId: id });

      actions.handleCloseModal({ playInitialVideo: false });
    },
  };

  useEffect(() => {
    actions.toggleFeedCloseButton({ hideCloseButton: true });

    return () => {
      actions.toggleFeedCloseButton({ hideCloseButton: false });

      if (isVideoClicked.current) {
        isVideoClicked.current = false;
        return;
      }

      trackSearchModalClosed();
    };
  }, []);

  return [
    {
      videos,
      trendingVideos,
      isOpen,
      isLoading,
      searchTerm,
    },
    actions,
  ];
};

export default useFeedSearchActions;
