import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { VerticalFlexCentered } from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextTiny } from 'shared/react/components/complex/Text';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import ExpanderTopArrowIcon from 'src/images/creation_method_icons/ExpanderTopArrowIcon';

export type WarningType = {
  message: string;
};

type Props = {
  warnings?: WarningType[];
};

const ImportFileWarnings: FC<Props> = ({ warnings }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  if (!warnings?.length) {
    return null;
  }

  const getWarnings = () => {
    if (!isExpanded) {
      return null;
    }

    return (
      <WarningsWrapper>
        {warnings.map((warning, index) => (
          <WarningRow key={index}>
            <WarningIndex>{index}</WarningIndex>
            <Warning>{warning.message}</Warning>
          </WarningRow>
        ))}
      </WarningsWrapper>
    );
  };

  const onExpandWarnings = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Container>
      <TitleRow onClick={onExpandWarnings}>
        <Title>
          <b>Note:</b> {warnings?.length} products didn’t import (missing requirements)
        </Title>
        <ArrowWrapper isExpanded={isExpanded}>
          <ExpanderTopArrowIcon />
        </ArrowWrapper>
      </TitleRow>
      {getWarnings()}
    </Container>
  );
};

const Container = styled(VerticalFlexCentered)`
  max-width: 502px;
  width: 100%;
  gap: 12px;
  margin: auto;
`;

const TitleRow = styled(Gap8HorizontalFlex)`
  align-items: center;
`;

const Title = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.warning};
`;

const WarningsWrapper = styled(Gap8VerticalFlex)`
  width: 100%;
  padding: 8px 16px;
  max-height: 142px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.neutralDeep};
  border: 1px solid ${({ theme }) => theme.colors.neutralGray};
  overflow: auto;
`;

const WarningRow = styled(Gap8HorizontalFlex)``;

const WarningIndex = styled(TextTiny)`
  flex-basis: 24px;
  flex-shrink: 0;
  color: ${({ theme }) => theme.colors.white};
`;

const Warning = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.white};
`;

const ArrowWrapper = styled.div<{ isExpanded: boolean }>`
  transform: rotate(${({ isExpanded }) => (isExpanded ? 0 : 180)}deg);
  transition: 0.2s ease-out;

  & svg {
    width: 12px;
    height: 12px;
  }

  & path {
    fill: ${({ theme }) => theme.colors.warning};
  }
`;

export default ImportFileWarnings;
