import React from 'react';
import styled from 'styled-components';
import useRecharge from 'shared/react/components/complex/context/hooks/useRecharge';
import BasicSelect from 'shared/react/components/basic/basic_select/BasicSelect';
import VerticalFlex from '../../../flex_layouts/VerticalFlex';
import { Gap16HorizontalFlex } from '../../../flex_layouts/HorizontalFlex';
import { LightTextSmall } from 'shared/react/components/complex/Text';

const FeedProductRechargeSellingPlansDropdown = ({
  shouldHide,
  items,
  value,
  setSellingPlanName,
  borderRadius,
}) => {
  const [{ rechargeWidgetSettings }] = useRecharge();
  const onChange = plan => {
    setSellingPlanName(plan);
  };

  if (shouldHide) {
    return null;
  }

  return (
    <DeliveryOptionContaienr>
      <DeliveryOptionLabel>{rechargeWidgetSettings.deliveryDropdownLabel}</DeliveryOptionLabel>
      <BasicSelect
        items={items}
        value={value}
        onChange={onChange}
        inputPadding="0px 12px"
        customHeight={40}
        renderHeader={value => <SelectHeader>{value}</SelectHeader>}
        borderRadius={borderRadius}
      />
    </DeliveryOptionContaienr>
  );
};

const DeliveryOptionContaienr = styled(VerticalFlex)`
  gap: 4px;
`;

const DeliveryOptionLabel = styled(LightTextSmall)``;

const SelectHeader = styled(Gap16HorizontalFlex)`
  color: ${({ theme }) => theme.colors.black};
`;

export default FeedProductRechargeSellingPlansDropdown;
