import React from 'react';
import styled from 'styled-components';

const EllipsisTextOverflowWrapper = ({ children, ...props }) => {
  return <LayoutRoot {...props}>{children}</LayoutRoot>;
};

export default EllipsisTextOverflowWrapper;

const LayoutRoot = styled.div`
  display: grid;
  width: fit-content;
  overflow: hidden;

  & > * {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
