import React from 'react';
import styled, { css } from 'styled-components';
import TextInput from 'app/src/basic_components/TextInput';

const NumericInput = ({ onInputChange, min = 0, max, value, placeholder, width, suffix }) => {
  const onChangeHandler = e => {
    onInputChange(+e.target.value);
  };

  return (
    <StyledTextInput
      value={value || 0}
      placeholder={placeholder}
      onChange={onChangeHandler}
      onFocus={e => e.target.select()}
      maxWidth="72px"
      width={width}
      type="number"
      inputProps={{ min, max }}
      $suffix={!!suffix}
    />
  );
};

const StyledTextInput = styled(TextInput)<{ width: string; $suffix: boolean }>`
  overflow: hidden;
  width: ${({ width }) => width};

  & div > input {
    text-align: center;
    font-weight: 500;
    font-size: 16px;
  }

  ${({ $suffix }) => {
    if (!$suffix) {
      return;
    }

    return css`
      && div > input {
        text-align: right;
        padding-right: 0;
        padding-top: 11px;
      }

      & * {
        border: none !important;
      }

      .MuiOutlinedInput-notchedOutline {
        border: none !important;
      }
    `;
  }}

  .MuiInputBase-root {
    height: 40px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    display: none;
  }
`;

export default NumericInput;
