import React from 'react';

const RulesDevicesIcon = ({ ...props }) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={40} height={40} rx={10} fill="#ECE9FF" />
    <path
      d="M24 12h-8a1.5 1.5 0 0 0-1.5 1.5v13c0 .844.656 1.5 1.5 1.5h8a1.5 1.5 0 0 0 1.5-1.5v-13c0-.813-.688-1.5-1.5-1.5Zm-2.5 14c0 .281-.25.5-.5.5h-2a.494.494 0 0 1-.5-.5c0-.25.219-.5.5-.5h2c.25 0 .5.25.5.5Z"
      fill="#7B61FF"
    />
  </svg>
);

export default RulesDevicesIcon;
