import React from 'react';

const PlayIcon = props => (
  <svg
    width="16"
    height="19"
    viewBox="0 0 16 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.352 1.023a1.869 1.869 0 0 0-1.914 0A1.808 1.808 0 0 0 .5 2.625v13.75c0 .703.352 1.328.938 1.64.585.352 1.328.313 1.914-.038l11.25-6.875c.546-.313.898-.938.898-1.602 0-.625-.352-1.25-.898-1.602L3.352 1.023Z"
      fill="#EAECF0"
    />
  </svg>
);

export default PlayIcon;
