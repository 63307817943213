import React from 'react';

const MagicIcon = ({ ...props }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.664 3.516 10 3l.492-1.313c.024-.117.14-.187.258-.187.094 0 .21.07.234.188L11.5 3l1.313.516c.117.023.187.14.187.234 0 .117-.07.234-.188.258L11.5 4.5l-.516 1.336c-.023.094-.14.164-.234.164-.117 0-.234-.07-.258-.164L10 4.5l-1.336-.492c-.117-.024-.164-.14-.164-.258 0-.094.047-.21.164-.234Zm-3.539-.282A.352.352 0 0 1 5.453 3a.41.41 0 0 1 .352.234l1.218 2.672 2.672 1.219c.14.07.235.21.235.352a.41.41 0 0 1-.235.351L7.023 9.047l-1.218 2.672a.41.41 0 0 1-.352.234.352.352 0 0 1-.328-.234L3.883 9.047 1.21 7.828A.387.387 0 0 1 1 7.477c0-.141.07-.282.21-.352l2.673-1.219 1.242-2.672Zm-.234 3.141a1.011 1.011 0 0 1-.54.54l-1.195.562 1.196.562c.234.094.445.305.539.54l.562 1.194.563-1.195c.093-.234.304-.445.539-.539l1.195-.562-1.195-.563a1.011 1.011 0 0 1-.54-.539L5.454 5.18l-.562 1.195Zm5.601 2.813c.024-.118.14-.188.258-.188.094 0 .21.07.234.188L11.5 10.5l1.313.516c.117.023.187.14.187.234 0 .117-.07.234-.188.258L11.5 12l-.516 1.336c-.023.094-.14.164-.234.164-.117 0-.234-.07-.258-.164L10 12l-1.336-.492c-.117-.024-.164-.14-.164-.258 0-.094.047-.21.164-.234L10 10.5l.492-1.313Z"
        fill="#684BD8"
      />
    </svg>
  );
};

export default MagicIcon;
