import { useUser } from 'src/context/userStore/UserStore';
import Utils from 'src/utils';
import { useApps } from 'app/src/context/AppsStore';
import { DEFAULT_INSTALLATION_STATUS_KEY } from 'app/src/constants/install.constants';

const useAccountInstallation = (currentAppId = DEFAULT_INSTALLATION_STATUS_KEY) => {
  const [{ account }, { updateAccountSettings }] = useUser();
  const [{ shopify: isShopify }] = useApps();
  const installationStatusKey = isShopify ? currentAppId : DEFAULT_INSTALLATION_STATUS_KEY;
  const appsInstallationStatuses = Utils.safeParse(account?.appsInstallationStatuses) || {};

  const isInstallationCompleted =
    appsInstallationStatuses[installationStatusKey]?.installationCompleted || false;

  const toggleAccountInstallation = async () => {
    try {
      const updatedAppsInstallationStatuses = {
        ...appsInstallationStatuses,
        [installationStatusKey]: {
          installationCompleted: !isInstallationCompleted,
        },
      };

      return updateAccountSettings({
        appsInstallationStatuses: JSON.stringify(updatedAppsInstallationStatuses),
      });
    } catch (error) {
      return false;
    }
  };

  return [
    {
      appsInstallationStatuses,
      isInstallationCompleted,
      toggleAccountInstallation,
    },
  ];
};

export default useAccountInstallation;
