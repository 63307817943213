import React from 'react';
import styled from 'styled-components';
import EmbedCarouselPreviewPlayButton from '../../../project/pages/look_and_feel/preview/embed_carousel_preview/EmbedCarouselPreviewPlayButton';
import DefaultTilePreview from 'app/src/images/default_tile_preview.png';
import { getVideoBorderRadius } from 'shared/react/components/complex/feed/feed_utils/feed.utils';
import Tooltip from 'app/src/basic_components/Tooltip';

type Props = {
  size: number;
  widgetSettings: any;
};

const DefaultCarouselPreviewTile = ({ size, widgetSettings }: Props) => {
  const {
    carouselBorder,
    carouselBorderRadius,
    carouselBorderColor,
    carouselBorderWidth,
    carouselShape,
  } = widgetSettings || {};

  const videoBorderRadius = getVideoBorderRadius(widgetSettings);

  return (
    <VideoContainer
      $borderWidth={carouselBorder && carouselBorderWidth / size}
      $borderColor={carouselBorder && carouselBorderColor}
      $borderRadius={carouselBorderRadius / size}
      $ratio={carouselShape}
    >
      <StyledTooltip
        text="To see best results, tag a few more videos in the `Videos` section."
        placement="top"
      >
        <Image
          src={DefaultTilePreview}
          $borderRadius={videoBorderRadius / size}
          $ratio={carouselShape}
        />
        <EmbedCarouselPreviewPlayButton widgetSettingsPreview={widgetSettings} size={size} />
      </StyledTooltip>
    </VideoContainer>
  );
};

const Image = styled.img<{ $ratio: number; $borderRadius: number }>`
  aspect-ratio: ${({ $ratio }) => $ratio};
  object-fit: cover;
  border-radius: ${({ $borderRadius }) => $borderRadius}px;
  grid-column: 1;
  grid-row: 1;
  width: 100%;
  height: 100%;
`;

const VideoContainer = styled.div<{
  $borderWidth: number;
  $borderColor: string;
  $borderRadius: number;
  $ratio: number;
}>`
  border: ${({ $borderWidth, $borderColor }) => `${$borderWidth}px solid ${$borderColor}`};
  border-radius: ${({ $borderRadius }) => $borderRadius}px;
  background: ${({ $borderColor }) => $borderColor};
  transition: 0.3s;
  display: grid;
  pointer-events: auto;
  overflow: hidden;
  aspect-ratio: ${({ $ratio }) => $ratio};
  height: fit-content;
`;

const StyledTooltip = styled(Tooltip)`
  background-color: ${({ theme }) => theme.colors.primary};

  & .MuiTooltip-arrow::before {
    background: ${({ theme }) => theme.colors.primary};
  }
`;

export default DefaultCarouselPreviewTile;
