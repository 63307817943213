import React, { useState } from 'react';
import styled from 'styled-components';
import {
  FOLDER_RENAME_MODAL_SAVE_DATA_TEST_ID,
  TYPE_FOLDER_NAME_DATA_TEST_ID,
} from 'app/src/constants/dataTestIds.constants';
import { Container, Modal } from 'app/src/styled_components';
import { LightTextTiny, TextSubtitle } from 'shared/react/components/complex/Text';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import FormInput from 'app/src/complex_components/FormInput';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { RENAME_FOLDER_MODAL_KEY } from 'app/src/constants/modals.constants';
import { MODAL_STATE } from 'app/src/pages/modals/folder_modal/folders.constants';
import { useFolders } from 'app/src/context/FoldersStore';
import { track } from 'app/src/helpers/Tracker';
import { useProjects } from 'app/src/context/ProjectsStore';
import { FolderType } from 'app/src/types/entities';
import { useVideos } from 'app/src/context/VideosStore';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { WhiteButton, PrimaryButton } from 'shared/react/components/basic/button-v2/BasicButton';

const RenameFolderModal = () => {
  const [, { updateFolder, createFolder }] = useFolders();
  const [
    {
      currentModal,
      modalType,
      currentFolderId,
      currentProjectId,
      modalProps: { ids, type, appUrl },
    },
    { setCurrentModal, setCurrentModalType, setCurrentFolderId, setCurrentProjectId },
  ] = useModal();
  const [{ project, showPersonalLibrary: isPrivateProject }, { updateProject, getProjectById }] =
    useProjects({
      projectId: currentProjectId,
    });

  const [, { updateVideo, getVideoById }] = useVideos();
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);

  const getHeaderByType = modalType => {
    const headers = {
      [MODAL_STATE.new]: 'Create new folder',
      [MODAL_STATE.rename]: 'Rename folder',
    };

    return headers[modalType];
  };

  const onClose = () => {
    track('Folder Modal Closed', { type: getHeaderByType(modalType) });
    setCurrentFolderId('');
    setCurrentModalType('');
    setCurrentModal('');
  };

  const onChange = e => {
    setValue(e.target.value);
  };

  const moveEntity = folderId => {
    if (type === FolderType.videos) {
      return moveVideos(folderId);
    }

    return moveProject(folderId);
  };

  const moveVideos = (folder = null) => {
    if (!project && !ids?.length) {
      return;
    }

    const promises = ids.map(id => {
      const currentVideo = getVideoById(id);
      return updateVideo({ ...currentVideo, folder });
    });

    return Promise.all(promises);
  };

  const moveProject = async (folder = null) => {
    if (!project && !ids?.length) {
      return;
    }
    let promises;

    if (ids?.length) {
      promises = ids.map(id => {
        const currProject = getProjectById(id);
        return updateProject({ ...currProject, folder, private: false });
      });
    } else {
      promises = [updateProject({ ...project, folder, private: false })];
    }

    await Promise.all(promises);
    setCurrentProjectId('');
  };

  const onCreateFolder = async name => {
    setLoading(true);
    const newFolder = {
      name,
      private: false,
      type: type || FolderType.projects,
      appUrl,
    };

    if ([FolderType.onsites, FolderType.projects].includes(type)) {
      newFolder.private = isPrivateProject;
    }

    const folder = await createFolder(newFolder);
    track('Create Folder Click');
    await moveEntity(folder.id);
    onClose();
  };

  const handleUpdateFunction = async newData => {
    if (modalType === MODAL_STATE.rename) {
      return updateFolder(newData);
    }

    return onCreateFolder(newData.name);
  };

  const onRename = async name => {
    setLoading(true);
    track('Rename Folder Click');
    const newData = { id: currentFolderId, name };
    await handleUpdateFunction(newData);
    onClose();
  };

  return (
    <Modal open={currentModal === RENAME_FOLDER_MODAL_KEY} onClose={onClose} width="340px">
      <ModalContainer>
        <HeaderContainer>
          <HeaderText>{getHeaderByType(modalType)}</HeaderText>
          <CloseModal onClick={onClose}>✕</CloseModal>
        </HeaderContainer>
        <ContentContainer>
          <InputLabel>Name</InputLabel>
          <CustomInput
            autoFocus
            placeholder="Folder name"
            onChange={onChange}
            value={value}
            data-test-id={TYPE_FOLDER_NAME_DATA_TEST_ID}
          />
        </ContentContainer>
        <ButtonsContainer>
          <WhiteButton disabled={loading} onClick={onClose}>
            Cancel
          </WhiteButton>
          <PrimaryButton
            isLoading={loading}
            onClick={() => onRename(value)}
            data-test-id={FOLDER_RENAME_MODAL_SAVE_DATA_TEST_ID}
          >
            Save
          </PrimaryButton>
        </ButtonsContainer>
      </ModalContainer>
    </Modal>
  );
};

const ModalContainer = styled(Container)`
  padding: 0;
  border-radius: 8px;
  min-width: 500px;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    min-width: 200px;
  } ;
`;

const HeaderContainer = styled(HorizontalFlex)`
  height: 56px;
  padding: 0 24px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutralLighter};
`;

const HeaderText = styled(TextSubtitle)`
  font-weight: 500;
`;

const CloseModal = styled.div`
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 15px;
  font-weight: 600;
  color: black;
  z-index: 2;
`;

const ContentContainer = styled.div`
  margin: 16px 24px;
`;

const ButtonsContainer = styled(Gap8HorizontalFlexWrap)`
  margin: 16px 24px;
  justify-content: flex-end;
`;

const CustomInput = styled(FormInput)`
  width: 100%;
  padding: 10px 8px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  margin-top: 4px;
`;

const InputLabel = styled(LightTextTiny)`
  font-size: 10px;
`;

export default RenameFolderModal;
