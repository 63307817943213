import { useEffect, useRef } from 'react';
import { Auth } from 'aws-amplify';

import { useSelectedApp } from 'src/context/AppsStore';
import { StudioAuthService } from 'src/services/studio';

const loginStudio = async ({ appKey, appUrl, owner }) => {
  const session = await Auth.currentSession();

  const idToken = session.getIdToken().getJwtToken();
  const accessToken = session.getAccessToken().getJwtToken();
  const refreshToken = session.getRefreshToken().getToken();

  StudioAuthService.updateStudioAppUrl(appUrl);
  StudioAuthService.loginStudio({
    idToken,
    accessToken,
    refreshToken,
    appKey,
    appUrl,
    owner,
  });
};

export const useStudioAuth = () => {
  const prevSelectedApp = useRef(null);
  const [selectedApp] = useSelectedApp();

  useEffect(() => {
    if (!selectedApp) {
      return;
    }

    const { appUrl, appKey, owner } = selectedApp;

    if (prevSelectedApp.current?.appKey === appKey && prevSelectedApp.current?.appUrl === appUrl) {
      return;
    }

    loginStudio({ appKey, appUrl, owner });

    return () => {
      StudioAuthService.logoutStudio();
    };
  }, [selectedApp]);
};

export default useStudioAuth;
