import React, { useState } from 'react';
import CreateNewTolstoyButtonMenu from 'app/src/pages/dashboard/pages/libraries/CreateNewTolstoyButtonMenu';
import { ONSITE } from 'app/src/constants/ui.constants';
import useCanCreateProject from 'app/src/pages/dashboard/hooks/useCanCreateProject';
import styled from 'styled-components';
import CreateTolstoyTooltipV2 from 'app/src/pages/dashboard/pages/libraries/dashboard_toolbar/CreateTolstoyTooltipV2';
import { useDashboard } from 'app/src/context/ui_store/DashboardStore';
import { GhostButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { useApps } from 'app/src/context/AppsStore';
import { CREATE_TOLSTOY_BUTTON_DATA_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import { useProjects } from 'app/src/context/ProjectsStore';
import { useRules } from 'app/src/context/RulesStore';
import Spinner from 'app/src/basic_components/Spinner';
import { track } from 'app/src/helpers/Tracker';

type Props = {
  isEmptyState?: boolean;
};

const NewFromScratchOnsiteButton = ({ isEmptyState = false }: Props) => {
  const [anchorElement, setAnchorElement] = useState(null);
  const [{ shopify }] = useApps();
  const [
    {
      onSiteFilters: { appUrl: selectedAppUrl, folder },
    },
  ] = useDashboard();
  const [{ creating: creatingProject }] = useProjects();
  const [{ loading: creatingRules }] = useRules();

  const isLoading = creatingProject || creatingRules;
  const disabled = useCanCreateProject();
  const appUrl = selectedAppUrl?.in?.[0] || shopify?.appUrl;

  const onButtonClick = async event => {
    if (!event || !event.currentTarget || disabled) {
      setAnchorElement(null);
    }

    track('Click create from scratch');
    setAnchorElement(anchorElement ? null : event.currentTarget);
  };

  const currentFolder = folder?.eq;

  return (
    <>
      <StyledCreateTolstoyTooltipV2 isLocked={disabled}>
        <GhostButton
          data-test-id={CREATE_TOLSTOY_BUTTON_DATA_TEST_ID}
          disabled={disabled || isLoading}
          onClick={onButtonClick}
        >
          {isLoading && <Spinner size={16} />}
          Create from scratch
        </GhostButton>
      </StyledCreateTolstoyTooltipV2>

      <CreateNewTolstoyButtonMenu
        anchorEl={anchorElement}
        setAnchorEl={setAnchorElement}
        setLoading={undefined}
        currentFolder={currentFolder}
        location={ONSITE}
        isOffsite={false}
        isEmptyState={isEmptyState}
        appUrl={appUrl}
      />
    </>
  );
};

const StyledCreateTolstoyTooltipV2 = styled(CreateTolstoyTooltipV2)`
  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    align-self: baseline;
  }
`;

export default NewFromScratchOnsiteButton;
