import React, { useState } from 'react';
import styled from 'styled-components';
import Utils from 'app/src/utils';
import { useQuizV2 } from 'app/src/context/QuizV2Store';
import { useQuizContext } from 'app/src/pages/project/pages/quiz/QuizContext';
import Button from 'shared/react/components/complex/Button';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';
import Types from 'shared/react/theme/Types';
import { TextH6 } from 'shared/react/components/complex/Text';
import { LINK_KEY } from 'app/src/constants/tolstoy.constants';
function AddQuizResult({ header }) {
  const [, { createQuizResult, publishQuiz, isUnsaved, setUnsaved, saveQuizResults }] = useQuizV2();
  const [, { setSnackbar, setErrorSnackbar }] = useSnackBar();
  const { project, quizResults, setQuizResults } = useQuizContext();
  const [isCreatingQuizResult, setCreatingQuizResult] = useState(false);

  const handleCreateEmptyQuizResult = async () => {
    setCreatingQuizResult(true);
    const appKey = Utils.getAppKey();
    const owner = Utils.getOwner();

    const createdQuizResult = await createQuizResult({
      projectId: project.id,
      appKey,
      owner,
      type: LINK_KEY,
      flows: [],
      isFallback: !quizResults.length,
    });

    setCreatingQuizResult(false);

    if (!createdQuizResult) {
      setErrorSnackbar('Failed to create result.');
      return;
    }

    setQuizResults([...quizResults, { ...createdQuizResult, isDefaultExpanded: true }]);

    const successMessage = 'Result created successfully';

    if (isUnsaved) {
      setSnackbar(successMessage);
      setUnsaved(false);
      await saveQuizResults(quizResults);
    } else {
      await publishQuiz(project.publishId);
      setSnackbar(successMessage);
    }
  };

  return (
    <LayoutRoot spreadToSides={!!header}>
      {header && <TextH6> {header} </TextH6>}
      <StyledButton
        onClick={handleCreateEmptyQuizResult}
        loading={isCreatingQuizResult}
        disabled={isCreatingQuizResult || quizResults.length >= 100}
        type={Types.Success}
      >
        Add result logic
      </StyledButton>
    </LayoutRoot>
  );
}

export default AddQuizResult;

const LayoutRoot = styled.div`
  display: grid;
  grid-template-columns: ${({ spreadToSides }) => (spreadToSides ? '1fr auto' : '')};
  align-items: center;
  gap: 8px;
  width: ${({ spreadToSides }) => (spreadToSides ? '100%' : '')};
`;

const StyledButton = styled(Button)`
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.success};
  font-weight: 600;
`;
