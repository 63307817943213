import { INTERNAL_EVENTS } from '../../../constants/internalEvents.constants.js';

export const INTERNAL_MESSAGES_TO_SUBSCRIBE = [
  INTERNAL_EVENTS.addToCartError,
  INTERNAL_EVENTS.addToCartSuccess,
  INTERNAL_EVENTS.addToCartSoldOut,
  INTERNAL_EVENTS.isRebuyAppInstalled,
  INTERNAL_EVENTS.isAfterpayAppInstalled,
  INTERNAL_EVENTS.openRebuyCart,
  INTERNAL_EVENTS.returnProductsMetafields,
  INTERNAL_EVENTS.rejectCookiePolicy,
  INTERNAL_EVENTS.productCardClickSubscribed,
  INTERNAL_EVENTS.isTapcart,
  INTERNAL_EVENTS.urlLocaleUpdate,
  INTERNAL_EVENTS.loginWithMultipassUrlResponse,
  INTERNAL_EVENTS.cartDataResponse,
  INTERNAL_EVENTS.cartItemQuantityChangeSuccess,
  INTERNAL_EVENTS.cartItemQuantityChangeError,
  INTERNAL_EVENTS.productRecommendationsResponse,
  INTERNAL_EVENTS.productUpdateResponse,
  INTERNAL_EVENTS.isNonBaseCurrency,
  INTERNAL_EVENTS.eventListenersInitialized
];
