import React from 'react';
import ShopifyTags from 'app/src/complex_components/global_modal/video/add_products_modal/components/shopify-tags/ShopifyTags';
import { TagTabs } from 'app/src/complex_components/global_modal/video/add_products_modal/constants/addProductsModal.constants';
import SelectedProductsWithSuggestions from 'app/src/complex_components/global_modal/video/add_products_modal/SelectedProductsWithSuggestions';
import { useModal } from 'app/src/context/ui_store/ModalStore';

type Props = {
  selectedTab: TagTabs;
};

const AddProductsModalContent = ({ selectedTab }: Props) => {
  const [{ currentIds: vodAssetIds }] = useModal();

  switch (selectedTab) {
    case TagTabs.Products:
      return <SelectedProductsWithSuggestions vodAssetId={vodAssetIds[0]} />;
    case TagTabs.Tags:
      return <ShopifyTags />;
  }
};

export default AddProductsModalContent;
