import React from 'react';
import styled from 'styled-components';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { SearchInput } from 'src/basic_components/input/InputWithIcon';
import { track } from 'app/src/helpers/Tracker';
import useNavigation from 'app/src/hooks/useNavigation';
import {
  project as projectType,
  publishMethodOptions,
  rule as RuleType,
} from 'app/src/types/entities';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextSmall } from 'shared/react/components/complex/Text';
import DynamicConditions from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/DynamicConditions';
import { useProductPageSettingsActions } from 'app/src/context/ProductPageSettingsStore';
import { MatchConditions } from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/constants/dynamicConditions.constants';
import { useDynamicConditionsContext } from './components/dynamic-conditions/context/DynamicConditionsContext';
import {
  FEATURE_DYNAMIC_PDP_TABLE,
  FEATURE_FEED_DYNAMIC_NEW_DESIGN,
} from 'shared/react/constants/features.constants';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import DynamicContent from 'src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-content/DynamicContent';
import { useRulesActions } from 'app/src/context/RulesStore';
import useDynamicTypeContent from './hooks/useDynamicTypeContent';
import { SHOP_APP_TARGET } from 'src/constants/project.constants';
import useHostProjectParams from './components/dynamic-conditions/hooks/useHostProjectParams';
import ProductSelector from 'src/complex_components/ProductSelector';
import SourceProjectNotice from 'app/src/pages/project/pages/project-edit/components/project-content/SourceProjectNotice';

export type DynamicTypeFormValues = {
  rules: RuleType[];
  matchConditions: MatchConditions;
  videoRules: RuleType[];
};

type Props = {
  project: projectType;
  containerRef: React.RefObject<HTMLDivElement>;
  isDerivedProject: boolean;
  projects?: {
    currentProject: projectType;
    sourceProject: projectType;
  };
};

const DynamicTypeContent = ({
  project,
  projects,
  containerRef,
  isDerivedProject = false,
}: Props) => {
  const { navigateToDashboardLibrary, currentPathName, getBackToEditorState } = useNavigation();
  const { createDefaultDynamicRule } = useRulesActions();
  const { isProductPage } = useProductPageSettingsActions();
  const { getFeatureEnabled } = useFeatureActions();
  const isPDPTableEnabled = getFeatureEnabled(FEATURE_DYNAMIC_PDP_TABLE);
  const isDynamicNewDesign = getFeatureEnabled(FEATURE_FEED_DYNAMIC_NEW_DESIGN);
  const { vodAssetIds, rules, videoRules, setVideoRules } = useDynamicConditionsContext();
  const { isTargetSpecificProductPage, targetExternalProductId, updateTargetExternalProductId } =
    useHostProjectParams();
  const isShopAppPdp =
    project?.publishMethod === publishMethodOptions.shopApp &&
    project?.targetPage === SHOP_APP_TARGET.productPage;
  const isProjectProductPage = isProductPage(project) && !isTargetSpecificProductPage;
  const {
    isLoading,
    isSearchDisabled,
    products,
    videos,
    vodAssets,
    searchTerm,
    canHide,
    canLock,
    canOrder,
    canAddManualVideo,
    canRemoveManualVideo,
    hideVideo,
    showVideo,
    lockVideo,
    unlockVideo,
    loadMore,
    moveVideo,
    addManualVideo,
    removeManualVideo,
    openVideoPickerModal,
    openProductsModal,
    setSearchTerm,
  } = useDynamicTypeContent({ project, isDerivedProject, isProjectProductPage });
  const pdpCount = products.filter(product => product?.videos.length > 0).length;

  const onTagMoreProducts = () => {
    track('Dynamic Builder Tag Products Click');
    navigateToDashboardLibrary('', getBackToEditorState(currentPathName));
  };

  const onSearchValueChange = e => setSearchTerm(e.target.value);

  const searchPlaceholder = isProjectProductPage ? 'Search products...' : 'Search videos...';

  const SubHeaderText = () => {
    if (projects && projects.sourceProject) {
      return (
        <SourceProjectNotice
          project={projects.currentProject}
          sourceProject={projects.sourceProject}
        />
      );
    }

    if (isDerivedProject) {
      return '';
    }

    if (project.discover) {
      return `You can manually hide any videos that shouldn't be eligible to be featured.`;
    }

    if (isProjectProductPage && isPDPTableEnabled) {
      return `We found ${pdpCount} product pages with videos from any of the selected playlists`;
    }

    if (!isDynamicNewDesign) {
      return 'Preview tagged videos per product page.';
    }

    if (vodAssetIds.length) {
      return `We found ${vodAssetIds.length} videos from any of the selected playlists`;
    }

    return 'There are no videos found matching these conditions';
  };

  const showDynamicConditions = !isShopAppPdp && !isDerivedProject;

  return (
    <LayoutRoot>
      {showDynamicConditions && (
        <DynamicConditions
          project={project}
          isProjectProductPage={isProjectProductPage}
          isDiscover={project?.discover}
          onTagMoreProducts={onTagMoreProducts}
        />
      )}
      {(!!rules.length || project?.discover) && (
        <HeaderContainer>
          <SubHeaderContainer>
            <Text>
              <SubHeaderText />
            </Text>
            <StyledSearchInput
              onChange={onSearchValueChange}
              value={searchTerm}
              placeholder={searchPlaceholder}
              disabled={isSearchDisabled}
            />
          </SubHeaderContainer>
        </HeaderContainer>
      )}
      <ProductSelector
        externalProductId={targetExternalProductId}
        onProductSelected={updateTargetExternalProductId}
      />
      <DynamicContent
        project={project}
        videoRules={videoRules}
        rules={isShopAppPdp ? ([createDefaultDynamicRule()] as RuleType[]) : rules}
        products={products}
        videos={videos}
        vodAssets={vodAssets}
        isProjectProductPage={isProjectProductPage}
        isLoading={isLoading}
        containerRef={containerRef}
        canHide={canHide}
        canLock={canLock}
        canOrder={canOrder}
        canAddManualVideo={canAddManualVideo}
        canRemoveManualVideo={canRemoveManualVideo}
        setVideoRules={setVideoRules}
        loadMoreProducts={loadMore}
        hideVideo={hideVideo}
        showVideo={showVideo}
        lockVideo={lockVideo}
        unlockVideo={unlockVideo}
        moveVideo={moveVideo}
        addManualVideo={addManualVideo}
        removeManualVideo={removeManualVideo}
        openVideoPickerModal={openVideoPickerModal}
        openProductsModal={openProductsModal}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)``;

const Text = styled(TextSmall)`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;

const HeaderContainer = styled(VerticalFlex)`
  gap: 4px;
`;

const SubHeaderContainer = styled(HorizontalFlex)`
  justify-content: space-between;
  align-items: center;
`;

const StyledSearchInput = styled(SearchInput)`
  width: 224px;
  height: 32px;
`;

export default DynamicTypeContent;
