import React from 'react';
import styled from 'styled-components';
import { CircularProgress, InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { TextH6 } from 'shared/react/components/complex/Text';

function Header({ isLoading, isLoadedOnce, onSearchVideos, searchTimeout }) {
  function handleChange(e) {
    if (searchTimeout && searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    let message = e.target.value;
    searchTimeout.current = setTimeout(() => {
      onSearchVideos(message);
    }, 1000);
  }

  return (
    <LayoutRoot>
      <TextH6>Videos by Pexels</TextH6>
      <StyledTextField
        label="Search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <CircularProgressContainer visible={+(isLoading && isLoadedOnce)}>
                <CircularProgress size={24} />
              </CircularProgressContainer>
            </InputAdornment>
          ),
        }}
        onChange={handleChange}
      />
    </LayoutRoot>
  );
}

export default Header;

const LayoutRoot = styled.div`
  display: grid;
  justify-items: center;
  gap: 20px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 100%;
  }
`;

const CircularProgressContainer = styled.div`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

const StyledTextField = styled(TextField)`
  width: 100%;

  && {
    width: 100%;
  }
`;
