import { useEffect } from 'react';
import { useVideoActions } from 'app/src/context/VideosStore';
import useGetFeatures from 'app/src/hooks/preview/useGetFeatures';

const MAX_PREVIEW_TILES = 15;

const useWidgetPreviewInit = (
  element,
  currentClass,
  project,
  vodAssetIds,
  customizationSettings,
  stepsName
) => {
  const { getVideoById } = useVideoActions();
  const { widgetFeatures: featureSettings } = useGetFeatures();
  const embedName = `${stepsName}Embed`;
  const embedStep = `${stepsName}Steps`;

  const prepareStep = (video, step) => {
    return {
      key: step?.name || video?.name,
      videoId: video?.id,
      videoOwner: video?.owner,
      stockAsset: video?.stockAsset,
      partName: step?.question || step?.description || video?.name,
    };
  };

  const prepareSteps = (steps, videosObject) => {
    return steps.map(step => prepareStep(videosObject[step.videoId], step));
  };

  useEffect(() => {
    if (!element) {
      return;
    }

    let steps;
    let videosObject;

    if (project.dynamic) {
      videosObject = vodAssetIds.reduce((acc, id) => {
        const video = getVideoById(id);
        if (video) {
          acc[id] = video;
        }

        return acc;
      }, {});

      const slicedVideos = vodAssetIds.slice(0, MAX_PREVIEW_TILES);

      steps = slicedVideos.map(id => {
        return { videoId: id };
      });
    } else {
      steps = project.steps.items;

      videosObject = steps.reduce((acc, step) => {
        const video = getVideoById(step.videoId);
        if (video) {
          acc[step.videoId] = video;
        }

        return acc;
      }, {});
    }
    const currentSteps = prepareSteps(steps, videosObject);

    if (!currentSteps?.length) {
      const emptySteps = [{}, {}, {}, {}, {}, {}];
      const emptyStateData = {
        ...customizationSettings.widgetSettings,
        skeleton: false,
        [embedStep]: emptySteps,
      };

      return currentClass.initSkeleton(element, emptyStateData);
    }

    const newData = {
      ...project,
      featureSettings,
      [embedName]: {
        [embedStep]: currentSteps,
        ...customizationSettings.widgetSettings,
      },
    };

    currentClass.initElements(element, newData);
  }, [element, customizationSettings, project]);
};

export default useWidgetPreviewInit;
