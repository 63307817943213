import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import { track } from 'app/src/helpers/Tracker';
import RoundedPlayIcon from 'app/src/images/RoundedPlayIcon';
import NavigateToBlueButton from '../../navigation_items/navigation_buttons/NavigateToBlueButton';
import NavigateToWhiteButton from '../../navigation_items/navigation_buttons/NavigateToWhiteButton';
import GenericBadge from './GenericBadge';
import { BADGE_CLOSED } from './PromotionBadges';

const PromotionBadge = ({
  title,
  titleOnClick,
  subtitle,
  backgroundColor,
  buttons,
  subtitleBeforeTitle = false,
  navigateWhite,
  textWhite,
  navigateBlue,
  textBlue,
  showIcon = true,
  featureKey,
  disabled,
}) => {
  const [open, setOpen] = useState(true);
  const theme = useTheme();

  const handleCloseClick = () => {
    track(`Promotion Banner Closed ${title}`);
    localStorage.setItem(`${featureKey}${BADGE_CLOSED}`, new Date().toISOString().slice(0, 10));
    setOpen(false);
  };

  const getButtons = () => {
    if (buttons) {
      return buttons;
    }
    return [
      <NavigateToWhiteButton
        key={'WhiteButton'}
        navigateFunction={navigateWhite}
        text={textWhite}
        disabled={disabled}
      />,
      <NavigateToBlueButton
        key={'BlueButton'}
        navigateFunction={navigateBlue}
        text={textBlue}
        disabled={disabled}
      />,
    ];
  };

  if (!open) {
    return null;
  }

  return (
    <GenericBadge
      title={title}
      titleOnClick={titleOnClick}
      subtitle={subtitle}
      handleCloseClick={handleCloseClick}
      backgroundColor={backgroundColor || theme.colors.successLight}
      icon={showIcon ? <RoundedPlayIcon /> : null}
      buttons={getButtons()}
      titleColor={theme.colors.blue12}
      subtitlesColor={theme.colors.blue2}
      subtitleBeforeTitle={subtitleBeforeTitle}
      closeButtonBackgroundColor={backgroundColor || theme.colors.successLight}
      closeButtonColor={theme.colors.blue2}
    />
  );
};

export default PromotionBadge;
