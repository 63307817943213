import { useState, useEffect } from 'react';

import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';
import useFeedState from 'shared/react/components/complex/context/hooks/useFeedState';
import {
  fetchDynamicProducts,
  fetchSearchProductsBySearchTerm,
} from 'shared/react/services/feed-products.service';
import SharedUtils from 'shared/react/utils/utils';
import {
  REQUEST_PRODUCTS_UPDATE_MESSAGE,
  PRODUCT_UPDATE_RESPONSE_MESSAGE,
} from 'shared/react/constants/messages.constants';

const SEARCH_PRODUCTS_LIMIT = 12;

const useFeedProductSearch = ({ vodAssetIds }) => {
  const [{ appUrl, isShoppable }] = useProjectConfig();
  const [{ isShopifyStore, shopifyRootRoute }] = useFeedState();
  const [products, setProducts] = useState([]);
  const shouldUpdateProductsFromShopify = isShopifyStore && shopifyRootRoute !== '/';

  const updateProductsFromShopifyIfNeeded = products => {
    if (!shouldUpdateProductsFromShopify) {
      return;
    }

    setProducts(products => products.map(product => ({ ...product, isFetchingPrice: true })));

    const handles = products.map(p => p.handle);
    SharedUtils.postMessageToWindow({
      eventName: REQUEST_PRODUCTS_UPDATE_MESSAGE,
      handles,
    });
  };

  const getProductsUpdatedWithResponseFromShopify = ({ products, updatedProduct, handle }) => {
    const updatedProducts = products.map(product => {
      if (product.handle !== handle) {
        return product;
      }

      if (!updatedProduct) {
        return { ...product, isFetchingPrice: false };
      }

      return {
        ...product,
        isFetchingPrice: false,
        currencyCode: updatedProduct.currencyCode,
        currencySymbol: updatedProduct.currencySymbol,
        price: updatedProduct.price,
        compareAtPrice: updatedProduct.compare_at_price,
        variants: product.variants.map((variant, index) => ({
          ...variant,
          price: updatedProduct.variants[index].price,
          compareAtPrice: updatedProduct.variants[index].compare_at_price,
        })),
      };
    });

    return updatedProducts;
  };

  const handleProductUpdateResponseFromShopify = event => {
    if (event.data.eventName === PRODUCT_UPDATE_RESPONSE_MESSAGE) {
      setProducts(products => {
        const { product: updatedProduct, handle } = event.data;
        return getProductsUpdatedWithResponseFromShopify({ products, updatedProduct, handle });
      });
    }
  };

  const getProductsBySearchTerm = async term => {
    try {
      const products = await fetchSearchProductsBySearchTerm({
        appUrl,
        term,
        customLimit: SEARCH_PRODUCTS_LIMIT,
      });

      const productsToSet = Object.values(products || {});

      setProducts(productsToSet);
      updateProductsFromShopifyIfNeeded(productsToSet);
      return products;
    } catch (error) {
      return;
    }
  };

  const getProductsByVodAssetIds = async vodAssetIds => {
    try {
      const { data, status } = await fetchDynamicProducts({
        vodAssetIds,
        shopifyStoreUrl: appUrl,
        isShopifyStore,
      });

      const products = Object.values(data || {});

      if (status !== 200 || !products?.length) {
        return;
      }

      const topProducts = products.slice(0, SEARCH_PRODUCTS_LIMIT);

      setProducts(topProducts);
      updateProductsFromShopifyIfNeeded(topProducts);
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    if (!isShoppable || products?.length || !vodAssetIds?.length) {
      return;
    }

    getProductsByVodAssetIds(vodAssetIds);
  }, [products, vodAssetIds]);

  useEffect(() => {
    window.addEventListener('message', handleProductUpdateResponseFromShopify);

    return () => {
      window.removeEventListener('message', handleProductUpdateResponseFromShopify);
    };
  }, []);

  return { products, getProductsBySearchTerm };
};

export default useFeedProductSearch;
