const getIsPropertySupported = ({ property, value }) => {
  if (!CSS?.supports) {
    return false;
  }

  const isPropertySupported = CSS.supports(property, value);

  return isPropertySupported;
};

export const getIsAspectRatioSupported = () => {
  return getIsPropertySupported({ property: 'aspect-ratio', value: '9 / 16' });
};
