import * as React from 'react';

const WhatsappIcon = ({ borderColor, ...props }) => (
  <svg
    width={49}
    height={48}
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M30.594 18.328a8.533 8.533 0 0 0-6.133-2.578c-4.766 0-8.672 3.906-8.672 8.672 0 1.562.43 3.047 1.172 4.336L15.75 33.25l4.57-1.172c1.29.664 2.696 1.055 4.14 1.055 4.806 0 8.79-3.906 8.79-8.672 0-2.344-1.016-4.492-2.656-6.133Zm-6.133 13.32a7.224 7.224 0 0 1-3.672-1.015l-.234-.156-2.735.742.742-2.657-.195-.273a7.515 7.515 0 0 1-1.094-3.867c0-3.945 3.243-7.188 7.227-7.188 1.914 0 3.71.743 5.078 2.11 1.367 1.367 2.188 3.164 2.188 5.117 0 3.945-3.32 7.187-7.305 7.187Zm3.984-5.39a48.326 48.326 0 0 0-1.484-.703c-.195-.078-.352-.117-.508.117-.117.195-.547.703-.664.86-.156.116-.273.155-.469.038-1.289-.625-2.11-1.133-2.968-2.578-.235-.39.234-.351.625-1.172.078-.156.039-.273 0-.39-.04-.117-.508-1.172-.665-1.602-.195-.43-.351-.39-.507-.39h-.39a.734.734 0 0 0-.587.273c-.195.234-.742.742-.742 1.797 0 1.094.742 2.11.86 2.265.116.118 1.523 2.305 3.71 3.243 1.367.625 1.914.664 2.617.547.391-.04 1.25-.508 1.446-1.016.195-.508.195-.938.117-1.016-.04-.117-.195-.156-.39-.273Z"
      fill="#3D4FE0"
    />
    <path
      d="M24.5 47c-12.703 0-23-10.297-23-23h-2c0 13.807 11.193 25 25 25v-2Zm23-23c0 12.703-10.297 23-23 23v2c13.807 0 25-11.193 25-25h-2Zm-23-23c12.703 0 23 10.297 23 23h2c0-13.807-11.193-25-25-25v2Zm0-2C10.693-1-.5 10.193-.5 24h2c0-12.703 10.297-23 23-23v-2Z"
      fill={borderColor || '#fff'}
    />
  </svg>
);

export default WhatsappIcon;
