import React from 'react';

const SparklesIcon = props => (
  <svg
    width={16}
    height={14}
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.66406 2.51562L11 2L11.4922 0.6875C11.5156 0.570312 11.6328 0.5 11.75 0.5C11.8438 0.5 11.9609 0.570312 11.9844 0.6875L12.5 2L13.8125 2.51562C13.9297 2.53906 14 2.65625 14 2.75C14 2.86719 13.9297 2.98438 13.8125 3.00781L12.5 3.5L11.9844 4.83594C11.9609 4.92969 11.8438 5 11.75 5C11.6328 5 11.5156 4.92969 11.4922 4.83594L11 3.5L9.66406 3.00781C9.54688 2.98438 9.5 2.86719 9.5 2.75C9.5 2.65625 9.54688 2.53906 9.66406 2.51562ZM6.125 2.23438C6.17188 2.09375 6.3125 2 6.45312 2C6.59375 2 6.73438 2.09375 6.80469 2.23438L8.02344 4.90625L10.6953 6.125C10.8359 6.19531 10.9297 6.33594 10.9297 6.47656C10.9297 6.61719 10.8359 6.75781 10.6953 6.82812L8.02344 8.04688L6.80469 10.7188C6.73438 10.8594 6.59375 10.9531 6.45312 10.9531C6.3125 10.9531 6.17188 10.8594 6.125 10.7188L4.88281 8.04688L2.21094 6.82812C2.07031 6.75781 2 6.61719 2 6.47656C2 6.33594 2.07031 6.19531 2.21094 6.125L4.88281 4.90625L6.125 2.23438ZM5.89062 5.375C5.79688 5.60938 5.58594 5.82031 5.35156 5.91406L4.15625 6.47656L5.35156 7.03906C5.58594 7.13281 5.79688 7.34375 5.89062 7.57812L6.45312 8.77344L7.01562 7.57812C7.10938 7.34375 7.32031 7.13281 7.55469 7.03906L8.75 6.47656L7.55469 5.91406C7.32031 5.82031 7.10938 5.60938 7.01562 5.375L6.45312 4.17969L5.89062 5.375ZM11.4922 8.1875C11.5156 8.07031 11.6328 8 11.75 8C11.8438 8 11.9609 8.07031 11.9844 8.1875L12.5 9.5L13.8125 10.0156C13.9297 10.0391 14 10.1562 14 10.25C14 10.3672 13.9297 10.4844 13.8125 10.5078L12.5 11L11.9844 12.3359C11.9609 12.4297 11.8438 12.5 11.75 12.5C11.6328 12.5 11.5156 12.4297 11.4922 12.3359L11 11L9.66406 10.5078C9.54688 10.4844 9.5 10.3672 9.5 10.25C9.5 10.1562 9.54688 10.0391 9.66406 10.0156L11 9.5L11.4922 8.1875Z"
      fill="white"
    />
  </svg>
);

export default SparklesIcon;
