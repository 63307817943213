import React from 'react';
import { JourneyDrawerStepType } from './types/JourneyDrawerStep.types';
import { formatShopifyPageName } from 'src/utils/shopify.utils';
import JourneyDrawerStep from './JourneyDrawerStep';
import WebpageGlobeIcon from 'src/images/audience/journey/WebpageGlobeIcon';
import FormattedLink from 'src/basic_components/FormattedLink';
import styled from 'styled-components';

type Props = {
  data: JourneyDrawerStepType;
};

const JourneyDrawerImpressionStep = ({ data }: Props) => {
  const text = formatShopifyPageName(data.parentUrl);

  return (
    <JourneyDrawerStep header="Visited page" Icon={WebpageGlobeIcon} createdAt={data.createdAt}>
      <StyledFormattedLink url={data.parentUrl} text={text} />
    </JourneyDrawerStep>
  );
};

const StyledFormattedLink = styled(FormattedLink)`
  align-items: flex-start;
  width: fit-content;
`;

export default JourneyDrawerImpressionStep;
