import React from 'react';
import { TextH2Bold, TextBodyBold } from 'shared/react/components/basic/text/TextV2';
import IosSwitch from 'src/basic_components/IosSwitch';
import InfoIconWithTooltip from 'src/complex_components/InfoIconWithTooltip';

type Props = {
  shouldUseCache: boolean;
  updateShouldUseCache: (value: boolean) => void;
  onUpdate: () => void;
};

const AccountSettings = ({ shouldUseCache, updateShouldUseCache, onUpdate }: Props) => {
  const onSpeedUpWidgetChange = () => {
    onUpdate();
    updateShouldUseCache(!shouldUseCache);
  };

  return (
    <div className="flex h-full w-full flex-col gap-4">
      <TextH2Bold>Account settings</TextH2Bold>
      <div className="flex flex-row justify-between pr-3">
        <div className="flex flex-row items-center gap-2">
          <TextBodyBold>Optimize Tolstoy for speed</TextBodyBold>
          <InfoIconWithTooltip
            title="There will be a delay before you see the results of any changes you implement (approx. 15 minutes)"
            iconFill={undefined}
            alignToCenter={undefined}
            margin={undefined}
            tooltipPlacement={undefined}
            customIcon={undefined}
            containerWidth={undefined}
          />
        </div>
        <IosSwitch checked={shouldUseCache} onChange={onSpeedUpWidgetChange} />
      </div>
    </div>
  );
};

export default AccountSettings;
