import React from 'react';

const StarFilled = ({ color = 'black', opacity = 1 }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_24496_5673)">
        <path
          d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z"
          fill={color}
          opacity={opacity}
        />
      </g>
    </svg>
  );
};

export default StarFilled;
