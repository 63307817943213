import { useApps } from 'app/src/context/AppsStore';
import { useEffect } from 'react';
import { useYoutube } from 'app/src/context/YoutubeStore';

export const useYouTubeLibraryVideos = () => {
  const [{ youtube, initialized: appsInitialized }] = useApps();

  const [
    { videos: youtubeVideos, gotAllVideos, initialized: youtubeInitialized, loading },
    { fetchByAccount: fetchYoutube },
  ] = useYoutube();
  const initialized = (!youtube && appsInitialized) || youtubeInitialized;

  useEffect(() => {
    if (!initialized) {
      fetchYoutube();
    }
  }, [initialized]);

  const loadMore = async () => {
    if (initialized && !loading && !gotAllVideos) {
      await fetchYoutube();
    }
  };

  return { videos: youtubeVideos, initialized, loadMore, isFetchingNextPage: loading };
};
