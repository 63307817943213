import { useEffect } from 'react';

export const useKeydownEvent = (keyKey: string, fn: (e: KeyboardEvent) => unknown) => {
  useEffect(() => {
    const capture = true;
    const handleKey = (e: KeyboardEvent) => {
      if (e.key === keyKey) {
        fn(e);
      }
    };

    document.addEventListener('keydown', handleKey, { capture });
    return () => {
      document.removeEventListener('keydown', handleKey, { capture });
    };
  }, [fn, keyKey]);
};
