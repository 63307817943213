import { ATTRIBUTION_DAYS_BY_FILTER, CUBE_FILTERS } from 'app/src/constants/cube.constants';
import { GENERAL_DATA_KEYS } from 'app/src/constants/analytics.constants';

const getFilterRoute = ({ filterKey, tableKey }) => {
  return `${tableKey}.${filterKey.toLowerCase()}`;
};

const getCubeFilter = ({ filterKey, filterValues, tableKey }) => {
  if (!filterValues || !CUBE_FILTERS[filterKey]) {
    return [];
  }

  const { operator, customFilterKey } = CUBE_FILTERS[filterKey];
  const member = getFilterRoute({ filterKey: customFilterKey || filterKey, tableKey });
  return {
    member,
    operator,
    values: filterValues,
  };
};

export const getCubeFiltersV2 = ({ filters, tableKey }) => {
  return Object.entries(filters).flatMap(([filterKey, filterValues]) => {
    return getCubeFilter({ filterKey, filterValues, tableKey });
  });
};

export const getAttributionDiff = interactionFilter => {
  if (!interactionFilter) {
    return null;
  }

  return [ATTRIBUTION_DAYS_BY_FILTER[interactionFilter]];
};

export const convertRevenueToUsd = ({ revenue, rate }) => {
  if (!revenue) {
    return 0;
  }

  return revenue / rate;
};

const getAccumulatedTotalCount = (data, dailyTotalCountKey) => {
  return data.reduce((acc, dayData) => acc + dayData[dailyTotalCountKey], 0);
};

const getDailyAveragePrice = (data, avgPriceKey, dailyTotalCountKey) => {
  return data.reduce((acc, dayData) => acc + dayData[avgPriceKey] * dayData[dailyTotalCountKey], 0);
};

export const getOrdersSumData = ({
  data,
  avgPriceKey = GENERAL_DATA_KEYS.avgPrice,
  dailyTotalCountKey = GENERAL_DATA_KEYS.dailyTotalCount,
}) => {
  const ordersTotalCount = getAccumulatedTotalCount(data, dailyTotalCountKey);

  const dailyAvgPrice = getDailyAveragePrice(data, avgPriceKey, dailyTotalCountKey);

  const ordersAov = dailyAvgPrice / ordersTotalCount || 0;

  return { ordersTotalCount, ordersAov };
};

export const getUplift = ({ value, baseline }) => {
  if (!baseline || !value) {
    return 0;
  }

  return ((value - baseline) / baseline) * 100;
};
