import React from 'react';
import styled from 'styled-components';
import { useAccount } from 'src/context/AccountStore';
import useHasResourcePermissions from 'src/hooks/useHasResourcePermissions';
import { AnchorElementProps } from 'src/types/common/common';
import { useProjectActions } from 'app/src/context/ProjectsStore';
import { useModalActions } from 'app/src/context/ui_store/ModalStore';
import { useDashboardActions } from 'app/src/context/ui_store/DashboardStore';
import { useVideoActions } from 'app/src/context/VideosStore';
import { useBilling } from 'app/src/hooks/useBillings';
import { useHistory } from 'react-router-dom';
import { useApps } from 'app/src/context/AppsStore';
import { SHOPIFY } from 'app/src/constants/intergrations.constants';
import { track } from 'app/src/helpers/Tracker';
import {
  DELETE_TOLSTOY_MODAL_KEY,
  MOVE_TOLSTOY_MODAL_KEY,
  SHARE_MODAL_KEY,
  TOLSTOY_COPY_TO_WORKSPACE_MODAL_KEY,
  TOLSTOY_RENAME_MODAL_KEY,
} from 'app/src/constants/modals.constants';
import { navigateToShareType } from 'app/src/utils/navigation.utils';
import { LINK_KEY } from 'app/src/constants/tolstoy.constants';
import Utils from 'app/src/utils';
import { FolderType, project as ProjectEntity, publishMethodOptions } from 'app/src/types/entities';
import PencilIcon from 'app/src/images/PencilIcon';
import {
  DELETE_PROJECT_MENU_ITEM_TEST_ID,
  RENAME_PROJECT_MENU_ITEM_TEST_ID,
} from 'app/src/constants/dataTestIds.constants';
import ShareIcon from 'app/src/images/project-menu/ShareIcon';
import PreviewIcon from 'app/src/images/project-menu/PreviewIcon';
import PageIcon from 'app/src/images/project-menu/PageIcon';
import DuplicateIcon from 'app/src/images/project-menu/DuplicateIcon';
import StandIcon from 'app/src/images/project-menu/StandIcon';
import BuildingIcon from 'app/src/images/project-menu/BuildingIcon';
import TrashIcon from 'app/src/images/sidebar-v2/TrashIcon';
import MenuWithItems from 'app/src/complex_components/menu_items/MenuWithItems';
import { useProductPageSettingsActions } from 'app/src/context/ProductPageSettingsStore';
import CampaignsIcon from 'app/src/images/sidebar-v2/CampaingsIcon';
import FolderOutlinedIcon from 'app/src/images/FolderOutlinedIcon';
import useWidgetPreviewUrl from 'app/src/hooks/useWidgetPreviewUrl';
import ArchiveIcon from 'app/src/images/Archive';
import { useArchiveProject } from 'src/hooks/projects';
import { ARCHIVE_FOLDER } from 'src/constants/folders';

type Props = AnchorElementProps & {
  project: ProjectEntity;
  inProject?: boolean;
  isOnsite?: boolean;
};

const ProjectMenu = ({ project, anchorElement, setAnchorElement, inProject, isOnsite }: Props) => {
  const [{ isAccountOwner, workspaces }] = useAccount();
  const [{ shopify }] = useApps();
  const hasMenuPermissions = useHasResourcePermissions(project, isAccountOwner);
  const { duplicateProject, startExportProjectToStore, updateProject } = useProjectActions();
  const { isProductPage } = useProductPageSettingsActions();
  const { setCurrentModal, setCurrentProjectId, setModalProps } = useModalActions();
  const { setCreatingDuplication, setCreatingNewTolstoy, setDuplicationMethod } =
    useDashboardActions();
  const { fetch: fetchVideos } = useVideoActions();
  const { lockTolstoyCreation } = useBilling();
  const history = useHistory();
  const isFeed = project.feed;
  const projectTypeName = isFeed ? 'Feed' : 'Tolstoy';
  const [{ apps: storeApps }] = useApps();
  const shopifyApps = storeApps.filter(({ app }) => app === SHOPIFY);
  const { openSampleUrl } = useWidgetPreviewUrl(project);
  const { confirmArchiveProject } = useArchiveProject();

  const handleCloseMenu = () => {
    setAnchorElement(null);
  };

  const handleShareClick = () => {
    track('Project Card Menu Item Click', { type: SHARE_MODAL_KEY });
    setCurrentProjectId(project.id);
    setCurrentModal(SHARE_MODAL_KEY);
    navigateToShareType(history, LINK_KEY);
  };

  const handlePreviewClick = () => {
    track('Project Card Menu Item Click', { type: 'Preview' });
    openSampleUrl();
  };

  const setLoaders = (isLoading, publishMethod) => {
    setDuplicationMethod(publishMethod);
    setCreatingDuplication(isLoading);
    setCreatingNewTolstoy(isLoading);
  };

  const onDuplicateProject = async method => {
    track('Tolstoy Duplicate Click');
    handleCloseMenu();
    const publishMethod = method || project.publishMethod || publishMethodOptions.landingPage;
    await duplicateProject({
      project,
      setLoaders,
      setCurrentProjectId,
      setCurrentModal,
      publishMethod,
    });
    await fetchVideos();
  };

  const onExportProject = async () => {
    track('Tolstoy Export to store Click', { projectId: project.id });
    await startExportProjectToStore(project, setLoaders, setCurrentProjectId, setCurrentModal);
    handleCloseMenu();
    await fetchVideos();
  };

  const disableExportToStore = () => {
    return Utils.isQuiz(project?.tolstoyType) || lockTolstoyCreation;
  };

  const handleOpenModalByKey = modalKey => {
    track('Project Card Menu Item Click', { type: modalKey });
    setCurrentProjectId(project.id);
    setCurrentModal(modalKey);
    const type = isOnsite ? FolderType.onsites : FolderType.projects;
    setModalProps({ ids: [project.id], type, appUrl: project.appUrl });
  };

  const onCopyToWorkspaceClicked = () => {
    setCurrentModal(TOLSTOY_COPY_TO_WORKSPACE_MODAL_KEY);
    setModalProps({ project });
  };

  const changePublishMethod = async (publishMethod: publishMethodOptions, folder = null) => {
    const params = {
      ...project,
      publishMethod,
      folder,
      ...(publishMethod === publishMethodOptions.landingPage ? { targetPage: null } : {}),
    };

    await updateProject(params);
  };

  const getDuplicateMenuItems = (
    callback: (value: string) => void = onDuplicateProject,
    items = null
  ) => {
    if (isFeed) {
      return [
        { text: 'Stories', onClick: () => callback(publishMethodOptions.stories), items },
        { text: 'Carousel', onClick: () => callback(publishMethodOptions.carousel), items },
        { text: 'Bubble', onClick: () => callback(publishMethodOptions.bubble), items },
        { text: 'TV Page', onClick: () => callback(publishMethodOptions.videoPage), items },
      ];
    }

    return [
      { text: 'Bubble', onClick: () => callback(publishMethodOptions.bubble), items },
      { text: 'Embed', onClick: () => callback(publishMethodOptions.embed), items },
    ];
  };

  const isShopifyOnsite = isOnsite && shopify;

  const MENU_ITEMS = [
    {
      text: `Rename ${projectTypeName}`,
      icon: <PencilIcon />,
      onClick: () => handleOpenModalByKey(TOLSTOY_RENAME_MODAL_KEY),
      dataTestId: RENAME_PROJECT_MENU_ITEM_TEST_ID,
    },
    {
      text: 'Share',
      icon: <ShareIcon />,
      onClick: handleShareClick,
      hidden: project.dynamic,
    },
    {
      text: `Preview`,
      icon: <PreviewIcon />,
      onClick: () => handlePreviewClick(),
    },
    { separator: true },
    {
      text: 'Move to',
      hidden: inProject,
      icon: <PageIcon />,
      items: [
        {
          text: 'Folder',
          onClick: () => handleOpenModalByKey(MOVE_TOLSTOY_MODAL_KEY),
          icon: <FolderOutlinedIcon />,
        },
        { separator: true },
        {
          text: 'Onsite',
          icon: <PageIcon />,
          hidden: isOnsite,
          items: getDuplicateMenuItems(changePublishMethod, null),
        },
        {
          text: 'Offsite',
          icon: <CampaignsIcon />,
          onClick: () => changePublishMethod(publishMethodOptions.landingPage),
          hidden: isProductPage(project) || !isOnsite,
        },
      ],
    },
    {
      text: `Duplicate`,
      icon: <DuplicateIcon />,
      disabled: lockTolstoyCreation,
      hidden: inProject || isShopifyOnsite,
      onClick: () => onDuplicateProject(''),
    },
    {
      text: `Duplicate as`,
      icon: <DuplicateIcon />,
      disabled: lockTolstoyCreation,
      hidden: inProject || !isShopifyOnsite,
      items: getDuplicateMenuItems(),
    },
    {
      text: `Export to Store`,
      icon: <StandIcon />,
      disabled: disableExportToStore(),
      hidden: inProject || shopifyApps?.length < 2,
      onClick: () => onExportProject(),
    },
    { separator: true, hidden: inProject },
    {
      text: 'Copy to workspace',
      icon: <BuildingIcon />,
      onClick: () => onCopyToWorkspaceClicked(),
      hidden: workspaces?.length <= 1 || inProject,
    },
    { separator: true, hidden: workspaces?.length <= 1 || inProject },

    {
      text: ARCHIVE_FOLDER.name,
      icon: <ArchiveIcon color="#090A0B" />,
      hidden: !isOnsite || project.folder === ARCHIVE_FOLDER.id,
      onClick: () => confirmArchiveProject(project),
    },
    {
      text: 'Delete',
      icon: <TrashIcon />,
      onClick: () => handleOpenModalByKey(DELETE_TOLSTOY_MODAL_KEY),
      dataTestId: DELETE_PROJECT_MENU_ITEM_TEST_ID,
    },
  ];

  if (!hasMenuPermissions) {
    return null;
  }

  return (
    <CustomMenuItems
      anchorElement={anchorElement}
      onClose={handleCloseMenu}
      menuItems={MENU_ITEMS}
    />
  );
};

const CustomMenuItems = styled(MenuWithItems)`
  min-width: 168px;
`;

export default ProjectMenu;
