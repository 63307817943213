import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  AUDIO_INPUT,
  RECORD_VIDEO,
  RECORDING_MINIMUM_FILE_SIZE,
  VIDEO_INPUT,
} from 'app/src/constants/creation_method.constants';
import { getMirroredVideo } from 'shared/react/components/basic/MirrorVideo';
import { BaseVideoSize } from 'app/src/basic_components/BaseVideoSize';
import { getScreenRecording, getVideoRecording, stopStream } from 'app/src/utils/video.utils';
import VideoRecorderError from 'app/src/pages/creation_flow/right_section/video_container/VideoRecorderError';
import {
  FEATURE_APP_LIMITS,
  FEATURE_LIMIT_MAX_RECORDING_TIME,
} from 'shared/react/constants/features.constants';
import Utils from 'app/src/utils';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import Controls from 'app/src/pages/creation_flow/right_section/video_container/Controls';
import VideoOverlay from 'app/src/pages/creation_flow/right_section/video_container/video_overlay/VideoOverlay';
import useIsMounted from 'shared/react/hooks/useIsMounted';

const getDefaultDeviceValue = (option, savedDevice) => {
  if (option !== 'default' && option) {
    return option;
  }

  return savedDevice || 'default';
};

const MIME_TYPES = [
  'video/webm;codecs=vp9',
  'video/webm;codecs="vp8,opus"',
  'video/webm;codecs=h264',
  'video/webm',
  'video/mp4',
];

const VideoContainer = ({
  selectedMethod,
  onCreateNewVideo,
  uploadState,
  selectedPreviewMode,
  setSelectedPreviewMode,
}) => {
  const savedAudioDevice = JSON.parse(localStorage.getItem(`${AUDIO_INPUT}-device`) || '{}');
  const savedVideoDevice = JSON.parse(localStorage.getItem(`${VIDEO_INPUT}-device`) || '{}');
  const [mirror, setMirror] = useState(true);
  const [mediaStream, setMediaStream] = useState(null);
  const [error, setError] = useState(null);
  const [constrains, setConstraints] = useState({
    audio: getDefaultDeviceValue(savedAudioDevice?.value),
    video: getDefaultDeviceValue(savedVideoDevice?.value),
  });
  const [constrainsNames, setConstraintsNames] = useState({
    [AUDIO_INPUT]: savedAudioDevice?.label,
    [VIDEO_INPUT]: savedVideoDevice?.label,
  });
  const [canStopRecording, setCanStopRecording] = useState(true);
  const [recordedVideoTimer, setRecordedVideoTimer] = useState(0);
  const [recording, setRecording] = useState(false);
  const [countdownValue, setCountdownValue] = useState(null);
  const [resultVideoBlob, setResultVideoBlob] = useState(null);
  const videoRef = useRef();
  const mediaRecorderRef = useRef();
  const recordedVideoTimerInterval = useRef();
  const countdownTimerRef = useRef();
  const dataChunksRef = useRef([]);
  const [, { getSettingsByKey }] = useFeatures();
  const [, { setSnackbar }] = useSnackBar();
  const limitMaxRecordingTimeSettings = getSettingsByKey(FEATURE_LIMIT_MAX_RECORDING_TIME);
  const { maxRecordingTime, isUnlimited, globalMaxRecordingTime } =
    limitMaxRecordingTimeSettings || {};
  const recordingMaxTime = isUnlimited ? globalMaxRecordingTime : maxRecordingTime;
  const isRecordVideo = selectedMethod === RECORD_VIDEO;
  const shouldMirror = mirror && isRecordVideo;
  const isMounted = useIsMounted();

  const getMimeType = () => {
    return MIME_TYPES.find(window.MediaRecorder.isTypeSupported) || 'video/webm';
  };

  const restartState = () => {
    setRecordedVideoTimer(0);
    setRecording(false);
    setCanStopRecording(true);
    dataChunksRef.current = [];
    if (videoRef?.current) {
      videoRef.current.srcObject = null;
      videoRef.current.src = null;
    }
  };

  const onStopMediaRecorder = () => {
    if (!dataChunksRef.current) {
      return;
    }

    const recordedBlob = new Blob(dataChunksRef.current, {
      type: `video/${Utils.isSafari() ? 'mp4' : 'webm'}`,
    });

    setResultVideoBlob(recordedBlob);
    setMediaStream(null);
    setRecording(false);
    videoRef.current.src = URL.createObjectURL(recordedBlob);
    videoRef.current.srcObject = null;
    onCreateNewVideo({ blob: recordedBlob, mirror: !!shouldMirror });
  };

  useEffect(() => {
    if (countdownValue === -1) {
      recordedVideoTimerInterval.current = setInterval(() => {
        setRecordedVideoTimer(recordedVideoTimer => recordedVideoTimer + 1);
      }, 1000);

      setCountdownValue(null);
      setRecording(true);

      const currentStream = mediaStream;

      if (!currentStream) {
        return;
      }

      mediaRecorderRef.current = new MediaRecorder(currentStream, {
        videoBitsPerSecond: 5000000,
        mimeType: getMimeType(),
      });

      mediaRecorderRef.current.ondataavailable = event => {
        if (!dataChunksRef.current) {
          return;
        }

        dataChunksRef.current.push(event.data);
        checkVideoSize();
      };

      mediaRecorderRef.current.onstop = onStopMediaRecorder;

      mediaRecorderRef.current.start(10);

      setTimeout(() => {
        setCanStopRecording(true);
      }, 1000);
    }
  }, [countdownValue]);

  const startRecording = () => {
    let secondsLeft = 2;
    setRecordedVideoTimer(0);
    setCountdownValue(secondsLeft);
    dataChunksRef.current = [];
    setCanStopRecording(false);

    countdownTimerRef.current = setInterval(() => {
      if (secondsLeft > -1) {
        secondsLeft -= 1;
        setCountdownValue(secondsLeft);
        return;
      }

      clearInterval(countdownTimerRef.current);
    }, 1000);
  };

  const enableStream = async () => {
    try {
      let stream;
      if (!isRecordVideo) {
        stream = await getScreenRecording(constrains);
      } else {
        stream = await getVideoRecording(constrains);
      }
      if (!isMounted() || !videoRef.current) {
        return;
      }

      videoRef.current.srcObject = stream;
      setMediaStream(stream);
    } catch (err) {
      console.error('Enable stream failed', err);
      setError(err?.message);
    }
  };

  const stopRecording = () => {
    setRecording(false);
    clearInterval(recordedVideoTimerInterval.current);
    recordedVideoTimerInterval.current = null;

    let size = 0;

    dataChunksRef?.current.forEach(blob => {
      size += +Utils.sizeInBytesToMb(blob.size);
    });

    if (size < RECORDING_MINIMUM_FILE_SIZE) {
      return;
    }
    mediaRecorderRef.current.stop();
  };

  const checkVideoSize = () => {
    const MAX_FILE_SIZE = getSettingsByKey(FEATURE_APP_LIMITS).maxFileSizeMB;

    const size = dataChunksRef?.current.reduce(
      (sum, blob) => sum + +Utils.sizeInBytesToMb(blob.size),
      0
    );

    if (MAX_FILE_SIZE <= size) {
      if (mediaRecorderRef.current?.state === 'recording') {
        stopRecording();
        setSnackbar(
          `You've reached your upload size limit of ${MAX_FILE_SIZE}. If you'd like to increase your size limit, please contact support.`,
          'error'
        );
      }
    }
  };

  const handleRetry = () => {
    setError(null);
  };

  const handleCanPlay = ({ target }) => {
    if (target.duration && target.duration !== Infinity) {
      setRecordedVideoTimer(target.duration);
    }
    videoRef.current.play();
  };

  const cancelRecording = () => {
    setRecording(false);
    clearInterval(recordedVideoTimerInterval.current);
    recordedVideoTimerInterval.current = null;
    dataChunksRef.current = null;
    mediaRecorderRef.current.stop();
  };

  useEffect(() => {
    const video = mediaStream?.getVideoTracks()[0];
    if (!video) {
      return;
    }

    if (recording) {
      video.onended = stopRecording;
    } else if (!resultVideoBlob) {
      video.onended = () => {
        clearInterval(countdownTimerRef.current);
        setCountdownValue(null);
        setError('stop');
      };
    }
  }, [mediaStream, recording]);

  useEffect(() => {
    enableStream();
  }, [selectedMethod]);

  useEffect(() => {
    if (mediaStream) {
      stopStream(mediaStream);
      enableStream();
    }
  }, [constrains]);

  useEffect(() => {
    return () => {
      restartState();
      clearInterval(recordedVideoTimerInterval.current);
    };
  }, [selectedMethod]);

  useEffect(() => {
    if (recordedVideoTimer >= recordingMaxTime) {
      stopRecording();
    }
  }, [recordedVideoTimer]);

  useEffect(() => {
    if (uploadState || mediaStream?.active) {
      return;
    }

    videoRef.current.src = null;
    videoRef.current.srcObject = null;
    enableStream();
  }, [uploadState]);

  useEffect(() => {
    return () => {
      stopStream(mediaStream);
    };
  }, [mediaStream]);

  if (error) {
    return <VideoRecorderError errorKey={error} retry={handleRetry} />;
  }

  return (
    <LayoutRoot>
      <Video
        ref={videoRef}
        mirror={shouldMirror}
        autoplay
        as="video"
        loop
        playsInline
        disablePictureInPicture={true}
        muted
        controls={false}
        onLoadedMetadata={handleCanPlay}
        isVideo={mirror}
      />

      <VideoOverlay
        countdownValue={countdownValue}
        isRecordVideo={isRecordVideo}
        constrainsNames={constrainsNames}
        setConstraintsNames={setConstraintsNames}
        constrains={constrains}
        setConstraints={setConstraints}
        isScreenRecording={!isRecordVideo}
        VIDEOINPUT={VIDEO_INPUT}
        AUDIOINPUT={AUDIO_INPUT}
        selectedPreviewMode={selectedPreviewMode}
        setSelectedPreviewMode={setSelectedPreviewMode}
        isResultPreview={!!resultVideoBlob}
        stream={mediaStream}
        recording={recording}
        uploadState={uploadState}
        recordedVideoTimer={recordedVideoTimer}
        recordingMaxTime={recordingMaxTime}
      />
      <Controls
        startRecording={startRecording}
        stopRecording={stopRecording}
        countdownValue={countdownValue}
        recording={recording}
        canStopRecording={canStopRecording}
        mirror={mirror}
        isRecordVideo={isRecordVideo}
        setMirror={setMirror}
        uploadState={uploadState}
        cancelRecording={cancelRecording}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlexWrap)`
  position: relative;
  display: grid;

  & > * {
    grid-row: 1;
    grid-column: 1;
  }

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    overflow: hidden;
  }
`;

const Video = styled(BaseVideoSize)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  transition: opacity 250ms;
  border-radius: 0;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    object-fit: cover;
  }

  ${({ mirror }) => (mirror ? getMirroredVideo() : '')}
`;

export default VideoContainer;
