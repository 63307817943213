import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { assetType as AssetType } from 'src/types/entities';
import VideoPreview from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/video_preview/VideoPreview';
import { useVideos } from 'app/src/context/VideosStore';
import {
  defaultStepData,
  getDefaultAnswer,
  getDefaultResponsesAnswers,
} from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/editStepDefaultData';
import { useProductActions } from 'app/src/context/ProductsStore';
import Utils from 'app/src/utils';
import { useApps } from 'app/src/context/AppsStore';
import EditButtons from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/EditButtons';
import EditOverlayText from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_overlay_text/EditOverlayText';
import { identify } from 'app/src/helpers/Widget';
import { useProjects } from 'app/src/context/ProjectsStore';
import { useQuiz } from 'app/src/context/QuizStore';
import { useUser } from 'app/src/context/userStore/UserStore';
import { FormProvider, useForm } from 'react-hook-form';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { DELETE_STEP_MODAL_KEY } from 'app/src/constants/modals.constants';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import DeleteIcon from 'app/src/images/DeleteIcon';
import {
  EVENTS,
  PREVIEW_DESKTOP_KEY,
  PREVIEW_MOBILE_KEY,
} from 'app/src/constants/tolstoy.constants';
import { useResponses } from 'app/src/context/ResponsesStore';
import { useHistory } from 'react-router-dom';
import {
  DASHBOARD_TABLET_MAX_WIDTH,
  HORIZONTAL_EDIT_STEP_MAX_WIDTH,
  VERTICAL_EDIT_STEP_MAX_WIDTH,
} from 'shared/react/utils/mediaQuery';
import { DELETE_STEP_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import { PRODUCTS, PRODUCTS_RESULT, TABS } from 'app/src/constants/editStep.constants';
import useWindowSize from 'shared/react/hooks/useWindowSize';
import StepTitleV2 from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/StepTitleV2';
import ProductsResultPreview from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/products_result_preview/ProductsResultPreview';
import { Gap16HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import PreviewToggle from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/PreviewToggle';
import { VIDEO_STATUS } from 'shared/react/constants/video.constants';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { FEATURE_FETCH_BY_URL, FEATURE_TOLSTOY_V2 } from 'app/src/constants/appFeatures.constants';
import Separator from 'shared/react/components/basic/Separator';
import { useBuilderFormContext } from 'app/src/context/BuilderFormStore';
import {
  DESCRIPTION_SIDE_PANEL_TYPE,
  SHOPABLE_SIDE_PANEL_TYPE,
} from 'shared/react/constants/feed.constants';
import ImagePreview from './video_preview/ImagePreview';

const FETCH_NEW_VIDEO_INTERVAL_TIMEOUT = 600;

const VIDEO_DONE_STATUS = [VIDEO_STATUS.done, VIDEO_STATUS.migrating];

const getEditStepBreakpoint = ({ isMobilePreview }) =>
  isMobilePreview ? VERTICAL_EDIT_STEP_MAX_WIDTH : HORIZONTAL_EDIT_STEP_MAX_WIDTH;

const getIsVideoReady = ({ status } = {}) => {
  return VIDEO_DONE_STATUS.includes(status);
};

const BuilderEditStep = ({ project, step, reply, ...props }) => {
  const theme = useTheme();
  const [{ video }, { getVideoUrl, getVideo, getVideoById }] = useVideos(step.videoId);
  const { getProducts } = useProductActions();
  const [
    { apps: storeApps, initialized: isAppsInitialized },
    { isAppFromEcomPlatform, isEcomPlatformConnected },
  ] = useApps();
  const [{ projects }, { updateProjectStep, publishProject, updateProject }] = useProjects();
  const [, { setModalProps, setCurrentModal }] = useModal();
  const [, { createResponse, sendReplyEmail }] = useResponses();
  const [, { getFeatureEnabled }] = useFeatures();
  const [{ user }] = useUser();
  const history = useHistory();
  const [, { updateQuiz }] = useQuiz();
  const videoRef = useRef();
  const contentRef = useRef();
  const isFeed = project.feed;
  const { getStep, setStep } = useBuilderFormContext();
  const methods = useForm({ defaultValues: defaultStepData(isFeed, isEcomPlatformConnected()) });
  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    setError,
    formState: { isDirty, isSubmitting: isLoading },
  } = methods;
  const stepFormValues = watch();
  const [stepType] = watch(['type']);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [isVideoReady, setIsVideoReady] = useState(!location.state?.resultVideoBlob);
  const [isEditingAnswersTime, setEditingAnswersTime] = useState(false);
  const [isLoadingProducts, setLoadingProducts] = useState(false);
  const [selectedPreviewMode, setSelectedPreviewMode] = useState(PREVIEW_DESKTOP_KEY);
  const [videoHeight, setVideoHeight] = useState(null);
  const videoUrl = getVideoUrl(video);
  const isProductsResultStep = stepType === PRODUCTS_RESULT;
  useWindowSize();
  const videoDuration = videoRef.current?.duration;
  const order = project.stepsOrder.findIndex(name => {
    return step.name === name;
  });
  const videoId = location.state?.videoId || location?.state?.video?.id || step?.videoId;
  const newVideoFetchTimeout = useRef();
  const isMobilePreview = selectedPreviewMode === PREVIEW_MOBILE_KEY || project.verticalOrientation;
  const isTolstoyV2Enabled = getFeatureEnabled(FEATURE_TOLSTOY_V2);
  const isFetchByUrlFeatureEnabled = getFeatureEnabled(FEATURE_FETCH_BY_URL);

  const setValues = newData => {
    Object.entries(newData).forEach(([key, value]) => {
      setValue(key, value, { shouldDirty: true });
    });
  };

  useEffect(() => {
    if (!video) {
      return;
    }

    if (!VIDEO_DONE_STATUS.includes(video?.status) && !video.stockAsset) {
      setIsVideoReady(false);
      scheduleNewVideoFetchTimeout();
    }
    if (getIsVideoReady(video)) {
      setIsVideoReady(true);
      clearNewVideoFetchTimeout();
    }

    return () => {
      clearNewVideoFetchTimeout();
    };
  }, [video?.status, video?.duration]);

  const scheduleNewVideoFetchTimeout = () => {
    clearNewVideoFetchTimeout();
    newVideoFetchTimeout.current = setTimeout(fetchVideoReady, FETCH_NEW_VIDEO_INTERVAL_TIMEOUT);
  };

  const clearNewVideoFetchTimeout = () => {
    clearTimeout(newVideoFetchTimeout.current);
  };
  const fetchVideoReady = async () => {
    if (!video?.id) {
      scheduleNewVideoFetchTimeout();
      return;
    }

    if ([VIDEO_STATUS.invalid, VIDEO_STATUS.done].includes(video?.status)) {
      return;
    }

    const newVideo = await getVideo(video.id, video);

    if ([VIDEO_STATUS.invalid, VIDEO_STATUS.done].includes(newVideo?.status)) {
      return;
    }

    scheduleNewVideoFetchTimeout();
  };

  const onVideoLoaded = () => {
    setVideoLoaded(true);
  };

  const getStepType = ({ type, defaultResponsesEnabled, products }) => {
    if (isFeed && (isFetchByUrlFeatureEnabled || isEcomPlatformConnected())) {
      return TABS.products;
    }

    if (type === PRODUCTS) {
      return TABS.products;
    }

    if (type) {
      return type;
    }

    if (products) {
      return TABS.products;
    }

    if (defaultResponsesEnabled) {
      return TABS.default;
    }

    return TABS.custom;
  };

  const fetchProducts = () => {
    const stepProducts = step?.products;
    if (!stepProducts || isLoading) {
      return [];
    }

    const productIds = stepProducts.map(product => product.id);
    return getProducts(productIds);
  };

  const getProductsAppUrl = () => {
    const defaultAppUrl = storeApps?.find(storeApp => isAppFromEcomPlatform(storeApp))?.appUrl;

    const existingAppUrl = storeApps?.find(
      storeApp => isAppFromEcomPlatform(storeApp) && storeApp.appUrl === step?.productsAppUrl
    )?.appUrl;

    if (isTolstoyV2Enabled) {
      return project.appUrl || existingAppUrl || defaultAppUrl;
    }

    return existingAppUrl || defaultAppUrl;
  };

  const getFeedActionButtonsData = () => {
    const defaultActionButton = {
      enabled: false,
      isOpenInANewTab: true,
      text: '',
      type: 'url',
      value: '',
    };

    if (
      step.feedSidePanelType === DESCRIPTION_SIDE_PANEL_TYPE &&
      step.feedDescriptionActionButton === null
    ) {
      return {
        feedDescriptionActionButton: defaultActionButton,
        feedProductActionButton: step.feedProductActionButton,
        feedSidePanelType: step.feedSidePanelType,
      };
    }

    if (
      step.feedSidePanelType === SHOPABLE_SIDE_PANEL_TYPE &&
      step.feedDescriptionActionButton === null
    ) {
      return {
        feedDescriptionActionButton: step.feedDescriptionActionButton,
        feedProductActionButton: defaultActionButton,
        feedSidePanelType: step.feedSidePanelType,
      };
    }

    return {
      feedDescriptionActionButton: step.feedDescriptionActionButton,
      feedProductActionButton: step.feedProductActionButton,
      feedSidePanelType: step.feedSidePanelType,
    };
  };

  const getFeedSidePanelDescription = () => {
    if (step.feedSidePanelType !== DESCRIPTION_SIDE_PANEL_TYPE) {
      return null;
    }

    return step.feedSidePanelDescription || '';
  };

  const init = async preventChanges => {
    if (!step) {
      return;
    }

    const defaultResponses = getDefaultResponsesAnswers().map(response => {
      const res = step.defaultResponses?.find(stepResponses => stepResponses?.key === response.key);

      if (res) {
        return { ...response, next: res.next, value: res?.value };
      }
      return response;
    });

    const productsAppUrl = getProductsAppUrl();

    const { feedDescriptionActionButton, feedProductActionButton, feedSidePanelType } =
      getFeedActionButtonsData();

    const feedSidePanelDescription = getFeedSidePanelDescription();

    const products = await fetchProducts();
    const initialState = {
      ...step,
      answers: step.answers || [getDefaultAnswer()],
      type: getStepType(step),
      overlayTextColor: step?.overlayTextColor || theme.colors.white,
      defaultResponsesEnabled: step.defaultResponsesEnabled || false,
      sideBarButtonsEnabled: step?.sideBarButtonsEnabled || false,
      defaultResponses,
      productsAppUrl,
      products,
      feedDescriptionActionButton,
      feedProductActionButton,
      feedSidePanelType,
      feedSidePanelDescription,
    };

    reset(initialState);
    if (!preventChanges) {
      setChangesIfNeeded();
    }
  };

  const setChangesIfNeeded = () => {
    const stepForm = getStep(step.id);
    if (stepForm) {
      setValues(stepForm.step);
    }
  };

  const getProductsIds = products => {
    return products.map(({ id }) => ({ id }));
  };

  const onCancel = () => {
    if (reply) {
      setCurrentModal('');
      setModalProps({});
      return;
    }

    init(true);
  };

  const sendReply = (project, newProject) => {
    let sessionId = reply.sessionId;

    return [
      createResponse(
        sessionId,
        project.id,
        reply.email,
        newProject.steps.items[0].videoId,
        reply.projectId,
        EVENTS.tolstoyReply
      ),
      sendReplyEmail(
        project.publishId,
        sessionId,
        [reply.email],
        reply.projectId,
        video?.id || step.videoId,
        null,
        false
      ),
    ];
  };

  const checkStepsForErrors = newStep => {
    let error = false;

    if (stepType === TABS.custom) {
      newStep.answers.forEach((answer, i) => {
        if (!answer.text) {
          error = true;
          if (newStep.id === step.id) {
            setError(`answers[${i}]`, { message: 'Type button text here' });
          }
        }
      });
    }

    return error;
  };

  const onSave = async (newStep, isNotSelected, updatedProject) => {
    let stepProducts = null;
    const currProductsOption = newStep.products;
    if (checkStepsForErrors(newStep)) {
      return;
    }

    const newName = location.state?.newStepName;
    const video = location.state?.video;
    const shouldReset = newStep.id === step.id;

    if (video && shouldReset) {
      newStep.videoId = video.id;
    }

    if (videoId && shouldReset) {
      newStep.videoId = videoId;
    }

    newStep.products = stepProducts;
    newStep.answerTime = Math.round(newStep.answerTime);

    if (newName && shouldReset) {
      newStep.description = newName;
    }

    const stepToReset = shouldReset ? { ...newStep, products: currProductsOption } : null;

    if (stepType === TABS.products || isProductsResultStep) {
      newStep.products = getProductsIds(currProductsOption);
    }

    const newProject = updatedProject || project;

    const updatedStep = await updateProjectStep(newStep, newProject);

    if (!newProject.appUrl && updatedStep.productsAppUrl) {
      await updateProject({ ...newProject, appUrl: updatedStep.productsAppUrl });
    }

    if (isLoadingProducts && !isNotSelected) {
      setLoadingProducts(false);
    }

    const promises = [];

    if (Utils.isQuiz(updatedStep?.tolstoyType, 1)) {
      promises.push(updateQuiz(updatedStep));
    }

    if (newProject && reply) {
      promises.push(...sendReply(newProject, newProject));
    }

    promises.push(
      publishProject(newProject.id),
      identify(user, { projectsLength: (projects?.length || 0) + 1 })
    );

    await Promise.all(promises);

    if (stepToReset && !isNotSelected) {
      reset(stepToReset);
    }

    if (!reply) {
      return;
    }

    setTimeout(() => {
      history.push({
        pathname: reply.location,
        state: { reply: !!reply, ...reply.extraStateParams },
      });

      onCancel();
    }, 1500);
  };

  const onDelete = () => {
    setModalProps({ stepId: step.id, project });
    setCurrentModal(DELETE_STEP_MODAL_KEY);
  };

  useEffect(() => {
    let isMounted = true;

    const asyncInit = async () => {
      if (isMounted) {
        await init();
      }
    };

    asyncInit();

    return () => {
      isMounted = false; // Cleanup to avoid setting state after unmount
    };
  }, [step?.id]);

  useEffect(() => {
    if (!step || !isAppsInitialized) {
      return;
    }

    const productsAppUrl = getProductsAppUrl();

    setValue('productsAppUrl', productsAppUrl, { shouldDirty: false });
  }, [isAppsInitialized]);

  const isProductType = stepType === TABS.products;

  const getPreview = () => {
    if (isProductsResultStep) {
      return <ProductsResultPreview project={project} />;
    }

    const asset = getVideoById(step.videoId);
    if (asset?.type === AssetType.image) {
      return <ImagePreview image={asset} />;
    }

    return (
      <VideoPreview
        step={step}
        project={project}
        videoRef={videoRef}
        videoLoaded={videoLoaded}
        video={video}
        onVideoLoaded={onVideoLoaded}
        videoUrl={videoUrl}
        videoDuration={videoDuration}
        isProductType={isProductType}
        isLoading={isLoading}
        isVideoReady={isVideoReady}
        isEditingAnswersTime={isEditingAnswersTime}
        setEditingAnswersTime={setEditingAnswersTime}
        setVideoLoaded={setVideoLoaded}
        isMobilePreview={isMobilePreview}
      />
    );
  };

  const updateStepValueOverride = useCallback((target, value, shouldDirty = true) => {
    setValue(target, value, { shouldDirty });
  }, []);

  const onSaveClickCallback = useCallback(
    async (newStep, isNotSelected) => {
      if (isNotSelected) {
        return onSave(newStep, isNotSelected);
      }

      handleSubmit(onSave)();
    },
    [isDirty]
  );

  useEffect(() => {
    if (!videoRef?.current || !videoLoaded) {
      return;
    }

    const observer = new ResizeObserver(() => {
      setVideoHeight(videoRef.current?.getBoundingClientRect().height);
    });
    observer.observe(videoRef.current);

    return () => observer?.disconnect();
  }, [videoRef, videoLoaded]);

  useEffect(() => {
    const formStepGetValues = getValues();
    const formStep = formStepGetValues;

    if (!step || !formStep || step.name !== formStep.name) {
      return;
    }

    if (!isDirty && !reply) {
      setStep(step.id, null);
      return;
    }

    setStep(step.id, {
      step: reply ? step : formStep,
      isDirty: isDirty || reply,
      isLoading,
      onSave: onSaveClickCallback,
      onDiscard: onCancel,
    });
  }, [stepFormValues, isDirty, isLoading, step?.name]);

  return (
    <FormProvider
      {...methods}
      isFetchByUrlFeatureEnabled={isFetchByUrlFeatureEnabled}
      reply={reply}
      setValue={updateStepValueOverride}
    >
      <LayoutRoot {...props}>
        <Card reply={!!reply} isMobilePreview={isMobilePreview}>
          {getPreview()}
          <CardContent
            isTolstoyV2Enabled={isTolstoyV2Enabled}
            reply={!!reply}
            ref={contentRef}
            isMobilePreview={isMobilePreview}
            height={videoHeight}
          >
            {!reply && (
              <>
                <LayoutHeader>
                  <StepTitleV2
                    order={order}
                    project={project}
                    isProductResultStep={isProductsResultStep}
                  />
                  <PreviewToggle
                    isFeed={isFeed}
                    selectedPreviewMode={selectedPreviewMode}
                    setSelectedPreviewMode={setSelectedPreviewMode}
                  />
                  <DeleteContainer data-test-id={DELETE_STEP_TEST_ID} onClick={onDelete}>
                    <DeleteIcon />
                  </DeleteContainer>
                </LayoutHeader>
                <Separator />
              </>
            )}
            <EditOverlayText isProductResultStep={isProductsResultStep} isFeed={isFeed} />
            <EditButtons
              project={project}
              isLoading={isLoading}
              step={step}
              isLoadingProducts={isLoadingProducts}
              setLoadingProducts={setLoadingProducts}
              isProductResultStep={isProductsResultStep}
            />
          </CardContent>
        </Card>
      </LayoutRoot>
    </FormProvider>
  );
};

const LayoutRoot = styled.form`
  padding-right: 20px;
  position: relative;
  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    padding-right: 0;
  }

  @media (${DASHBOARD_TABLET_MAX_WIDTH}) {
    height: auto;
  }
`;

const LayoutHeader = styled(Gap16HorizontalFlex)`
  padding: 16px 24px;
  align-items: center;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    padding: 16px;
  }

  @media (${({ theme }) => theme.breakpoints.laptopMax}) {
    gap: 8px;
  }
`;

const DeleteContainer = styled(Gap8HorizontalFlexWrap)`
  align-items: center;
  cursor: pointer;
  & path {
    fill: ${({ theme }) => theme.colors.danger};
  }
`;

const Card = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 16px;
  border-radius: ${({ reply }) => (reply ? '0px 0px 16px 16px' : '16px')};
  overflow: ${({ reply }) => (reply ? 'auto' : 'hidden')};
  margin: ${({ reply }) => (reply ? '' : '10px 0 0px')};
  height: 100%;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    padding: 4px;
    margin: ${({ reply }) => (reply ? '' : '10px 0 20px')};
  }

  @media (${getEditStepBreakpoint}) {
    grid-template-columns: 1fr;
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ height }) => `${height || 540}px`};
  min-width: 429px;
  position: relative;
  overflow: hidden;
  background: ${({ theme, isTolstoyV2Enabled, reply }) =>
    isTolstoyV2Enabled && !reply ? '' : theme.colors.white};
  border-radius: 16px;
  max-width: 960px;
  grid-column: 1;
  grid-row: 1;

  @media (${getEditStepBreakpoint}) {
    width: 100%;
    min-width: unset;
    grid-row: 2;
  }
`;

export default BuilderEditStep;
