import React, { useMemo, useState } from 'react';
import { useAnalytics } from 'app/src/context/AnalyticsStore';
import AnalyticsTable from 'app/src/complex_components/analytics/AnalyticsTable';
import { ANALYTICS_TABLE_KEYS } from 'app/src/constants/analytics.constants';
import { getVideosTableColumns } from 'app/src/complex_components/analytics/videos/getVideosTableColumns';
import { useVideoActions } from 'app/src/context/VideosStore';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import { FEATURE_EXPORT_ANALYTICS } from 'shared/react/constants/features.constants';
import { track } from 'app/src/helpers/Tracker';
import { VIDEO_SOURCE_NAME_BY_KEY } from 'app/src/constants/videoSources.constants.js';

const AnalyticsVideosTable = () => {
  const [
    { videoMetrics, isLoadingVideoMetrics, videoMetricsSortingModel },
    { setVideoMetricsSortingModel },
  ] = useAnalytics();
  const { getVideoById } = useVideoActions();
  const { getFeatureEnabled } = useFeatureActions();
  const [isLoading, setLoading] = useState(false);
  const isExportEnabled = getFeatureEnabled(FEATURE_EXPORT_ANALYTICS);

  const getVodAssetName = (vodAssetId: string) => {
    const video = getVideoById(vodAssetId);

    return video?.name || vodAssetId;
  };

  const getVodAssetSource = (vodAssetId: string) => {
    const video = getVideoById(vodAssetId);

    return VIDEO_SOURCE_NAME_BY_KEY[video?.uploadType] || video?.uploadType || '';
  };

  const trackClick = () => {
    track('Videos Account Analytics Export to CSV Clicked');
  };

  const columns = useMemo(() => getVideosTableColumns({ getVodAssetName, getVodAssetSource }), []);
  return (
    <AnalyticsTable
      rows={videoMetrics}
      columns={columns}
      isExportEnabled={isExportEnabled}
      isLoading={isLoadingVideoMetrics || isLoading}
      setLoading={setLoading}
      tableName={ANALYTICS_TABLE_KEYS.videos}
      onDownloadCsv={trackClick}
      title={undefined}
      sortModel={videoMetricsSortingModel}
      onSortModelChange={setVideoMetricsSortingModel}
      onRowClick={undefined}
    />
  );
};

export default AnalyticsVideosTable;
