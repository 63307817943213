import React from 'react';
import styled from 'styled-components';
import { DashedInputCss } from 'app/src/basic_components/DashedInputCss';

const DashedInput = React.forwardRef((props, ref) => {
  return <CustomInput ref={ref} {...props} />;
});

const CustomInput = styled.input`
  ${DashedInputCss}
`;

DashedInput.displayName = 'DashedInput';

export default DashedInput;
