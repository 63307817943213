import { useApps } from 'app/src/context/AppsStore';
import { useEffect, useMemo } from 'react';
import { useGoogleDrive } from 'app/src/context/GoogleDriveStore';
import Utils from 'app/src/utils';

export const useGoogleDriveLibraryVideos = () => {
  const [{ initialized: appsInitialized, googleDrive }] = useApps();

  const [
    {
      videos: googleDriveVideos,
      initialized: googleDriveInitialized,
      gotAllVideos: gotAllGoogleDrive,
      rootFolderId: rootFolderIdFromStore,
      loading,
    },
    { fetchByAccount: fetchGoogleDrive, pickFolder },
  ] = useGoogleDrive();

  const getRootFolderId = () => {
    if (rootFolderIdFromStore !== undefined) {
      return rootFolderIdFromStore;
    }

    const data = Utils.safeParse(googleDrive?.data);
    return data?.rootFolderId;
  };

  const initialized = (!googleDrive && appsInitialized) || googleDriveInitialized;
  const rootFolderId = getRootFolderId();

  useEffect(() => {
    if (!initialized && googleDrive) {
      fetchGoogleDrive();
    }
  }, [initialized, googleDrive]);

  useEffect(() => {
    if (googleDrive && rootFolderId === undefined) {
      pickFolder();
    }
  }, [rootFolderId, googleDrive]);

  const loadMore = async () => {
    if (initialized && !loading && !gotAllGoogleDrive) {
      await fetchGoogleDrive();
    }
  };

  const driveVideos = useMemo(() => {
    if (rootFolderId === undefined) {
      return [];
    }

    return googleDriveVideos;
  }, [googleDriveVideos, rootFolderId]);

  return { videos: driveVideos, initialized, loadMore, isFetchingNextPage: loading };
};
