import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { TextField } from '../../styled_components';
import Button from 'shared/react/components/complex/Button';
import StandardModal from './StandardModal';
import { TextSubtitle } from 'shared/react/components/complex/Text';
import Types from 'shared/react/theme/Types';
import { TextSmall } from 'shared/react/components/basic/text/TextV2';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import useIsMounted from 'shared/react/hooks/useIsMounted';
import { useVideos } from 'app/src/context/VideosStore';
import {
  getHandleFromUrl,
  getProfileLink,
  getProviderByUrl,
  ensureAtSign,
} from 'shared/react/utils/social';

export default function AddCreatorProfileModal() {
  const [{ currentId }, { setCurrentModal, setCurrentId }] = useModal();
  const [{ video }, { updateVideo }] = useVideos(currentId);

  /** @type {React.MutableRefObject<HTMLInputElement>} */
  const inputRef = useRef();
  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.select();
    }, 50);
  }, []);

  const { provider, handle: initialHandle } = JSON.parse(video?.externalProviderData || '{}');
  const initialUrl = initialHandle ? getProfileLink(initialHandle, provider) : '';

  const [handle, setHandle] = useState(initialHandle);
  const [loading, setLoading] = useState(false);
  const isMounted = useIsMounted();

  const [error, setError] = useState('');

  function handleChange(e) {
    const value = e.target.value;
    if (value === '') {
      setError(''); // empty input to delete creator profile link
      setHandle('');
    } else if (getProviderByUrl(value) === undefined) {
      setError('Currently, only tiktok.com and instagram.com are supported');
    } else {
      setError('');
      setHandle(getHandleFromUrl({ url: value }));
    }
  }

  function handleClose() {
    setCurrentModal('');
  }

  async function handleSave() {
    const videoCopy = { ...video };
    const externalProviderDataAsObject = JSON.parse(video?.externalProviderData || '{}');

    if (handle == null || handle === '') {
      // delete specific props
      delete externalProviderDataAsObject?.provider;
      delete externalProviderDataAsObject?.handle;
      videoCopy.externalProviderData = JSON.stringify(externalProviderDataAsObject);
    } else {
      const externalProviderData = JSON.stringify({
        ...externalProviderDataAsObject,
        provider: getProviderByUrl(inputRef.current.value),
        handle,
      });
      videoCopy.externalProviderData = externalProviderData;
    }

    setLoading(true);
    await updateVideo(videoCopy);
    if (!isMounted()) {
      return;
    }
    setLoading(false);
    handleClose();
    setCurrentId(null);
  }

  return (
    <StandardModal open onClose={handleClose} width="425px">
      <StyledTitle>Add creator profile</StyledTitle>
      <InputContainer>
        <Label>Profile URL</Label>
        <TextField
          ref={inputRef}
          autoFocus
          name="creatorProfileUrl"
          variant="outlined"
          placeholder={'e.g. www.instagram.com/gotolstoy/'}
          defaultValue={initialUrl}
          onChange={handleChange}
        />
      </InputContainer>
      <InputContainer>
        <Label>
          Handle:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {ensureAtSign(handle)}
        </Label>
      </InputContainer>
      {error ? <ErrorMessage>{error}</ErrorMessage> : null}
      <ButtonContainer>
        <CancelButton type={Types.Ghost} onClick={handleClose}>
          Cancel
        </CancelButton>
        <Button loading={loading} disabled={Boolean(error)} onClick={() => handleSave()}>
          Save
        </Button>
      </ButtonContainer>
    </StandardModal>
  );
}

const InputContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

const ErrorMessage = styled.span`
  display: block;
  color: red;
  opacity: 0.7;
  font-size: 12px;
`;

const Label = styled(TextSmall)`
  flex-shrink: 0;
  margin-right: 15px;
`;

const StyledTitle = styled(TextSubtitle)`
  margin-bottom: 12px;
`;

const CancelButton = styled(Button)`
  margin-right: 10px;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`;
