import React from 'react';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import styled from 'styled-components';
import { CheckboxV2 } from 'app/src/complex_components/Checkbox';
import { TextSmall } from 'shared/react/components/basic/text/TextV2';

type Props = {
  children?: React.ReactNode;
  onClick?: () => void;
  selected?: boolean;
};

const AnalyticsMultiSelectItem = ({ children, selected = false, onClick }: Props) => {
  return (
    <LayoutRoot onClick={onClick}>
      <Checkbox checked={selected} />
      <ItemText>{children}</ItemText>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)`
  align-items: center;
  padding: 8px;
  cursor: pointer;
  gap: 4px;
`;

const Checkbox = styled(CheckboxV2)`
  padding: 4px;
`;

const ItemText = styled(TextSmall)`
  font-weight: 500;
`;

export default AnalyticsMultiSelectItem;
