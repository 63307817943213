import { useEffect } from 'react';
import { useTopBarContext } from 'app/src/context/TopBarStore';
import { VideosMultiSelectBarState } from 'app/src/pages/dashboard/components/top-bar/types/TopBar.types';

const useUpdateVideosMultiSelectState = (videosMultiSelectState: VideosMultiSelectBarState) => {
  const { setVideosMultiSelectState } = useTopBarContext();

  useEffect(() => {
    if (!videosMultiSelectState.selectedVideos?.length) {
      setVideosMultiSelectState(null);
      return;
    }

    setVideosMultiSelectState(videosMultiSelectState);
  }, [videosMultiSelectState.selectedVideos?.length]);

  useEffect(() => {
    return () => {
      setVideosMultiSelectState(null);
    };
  }, []);
};

export default useUpdateVideosMultiSelectState;
