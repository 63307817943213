import React from 'react';

const MirrorIcon = () => {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.5 5H9.96875V6.75C9.96875 7.0625 10.1562 7.34375 10.4375 7.46875C10.7188 7.5625 11.0625 7.5 11.25 7.28125L13.75 4.78125C14.0625 4.5 14.0625 4.03125 13.75 3.75L11.25 1.25C11.0625 1.03125 10.7188 0.96875 10.4375 1.0625C10.1562 1.1875 9.96875 1.46875 9.96875 1.75V3.5H5.5C2.4375 3.5 0 5.96875 0 9C0 9.4375 0.3125 9.75 0.75 9.75C1.15625 9.75 1.5 9.4375 1.5 9C1.5 6.8125 3.28125 5 5.5 5ZM15.25 8.25C14.8125 8.25 14.5 8.59375 14.5 9C14.5 11.2188 12.6875 13 10.5 13H6V11.25C6 10.9688 5.8125 10.6875 5.53125 10.5625C5.25 10.4688 4.90625 10.5312 4.71875 10.75L2.21875 13.25C1.90625 13.5312 1.90625 14 2.21875 14.2812L4.71875 16.7812C4.90625 17 5.25 17.0625 5.53125 16.9688C5.8125 16.8438 6 16.5625 6 16.25V14.5H10.5C13.5312 14.5 16 12.0625 16 9C16 8.59375 15.6562 8.25 15.25 8.25Z"
        fill="white"
      />
    </svg>
  );
};

export default MirrorIcon;
