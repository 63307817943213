import React from 'react';
import styled from 'styled-components';
import { BaseVideoSize } from 'app/src/basic_components/BaseVideoSize';
import FeedNavigation from 'shared/react/components/complex/feed/feed_overlay/FeedNavigation';
import { MOBILE_VIDEO_WIDTH } from 'app/src/constants/editStep.constants';

const FeedOverlay = ({ videoLoaded, step, project, isMobilePreview, videoRef }) => {
  const getNextStepName = () => {
    const stepIndex = project.stepsOrder.findIndex(name => name === step.name);
    const nextStepName = project.stepsOrder[stepIndex + 1] || project.stepsOrder[0];

    if (!nextStepName) {
      return '';
    }

    const nextStep = project.steps.items.find(({ name }) => {
      return name === nextStepName;
    });

    return nextStep?.description;
  };

  const nextStepName = getNextStepName();

  return (
    <LayoutRoot videoLoaded={videoLoaded} isMobilePreview={isMobilePreview}>
      <FeedNavigation
        nextStepName={nextStepName}
        videoRef={videoRef}
        step={step}
        handlePlayPause={() => {}}
        handleNavigateToStep={() => {}}
        isMobilePreview={isMobilePreview}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(BaseVideoSize)`
  position: relative;
  display: ${({ videoLoaded }) => (videoLoaded ? 'flex' : 'none')};
  height: 100%;
  grid-row: 1;
  grid-column: 1;
  z-index: 2;
  overflow: hidden;
  width: 100%;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
  max-width: ${({ isMobilePreview }) => (isMobilePreview ? `${MOBILE_VIDEO_WIDTH}px` : '')};
  gap: 20px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 70%,
    ${({ theme }) => theme.getHexOpacity(theme.colors.black, 45)} 100%
  );

  @media (max-height: 811px) {
    height: 410px;
  }

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 100%;
    border-radius: 8px;
  }
`;

export default FeedOverlay;
