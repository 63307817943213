import React from 'react';
import styled from 'styled-components';
import ComponentWithLoader from 'app/src/complex_components/ComponentWithLoader';
import Product from './Product';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import InvisibleDiv from 'app/src/basic_components/InvisibleDiv';
import SearchProductsWrapper from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/products/SearchProductsWrapper';

const SearchProductsDisplay = ({
  isLoadingProducts,
  showSearchContainer,
  products,
  selectedApp,
  handleSearchProductSelect,
  handleHasNoPermissions,
  handleRemoveProduct,
  project,
  scrollAnchorRef,
  productCtaType,
  setProductCtaType,
}) => {
  const isFeed = project.feed;

  return (
    <StyledComponentWithLoader isLoading={isLoadingProducts}>
      <LayoutRoot>
        {showSearchContainer && (
          <SearchContainer>
            <SearchProductsWrapper
              selectedProducts={products}
              selectedApp={selectedApp}
              onProductSelected={handleSearchProductSelect}
              onHasNoPermissions={handleHasNoPermissions}
            />
          </SearchContainer>
        )}
        <AnswersContainer>
          {products?.filter(Boolean).map((product, i) => {
            return (
              <Product
                key={product.productId}
                product={product}
                index={i}
                stepsOrder={project.stepsOrder}
                handleRemoveProduct={handleRemoveProduct}
                isFeed={isFeed}
                productCtaType={productCtaType}
                setProductCtaType={setProductCtaType}
              />
            );
          })}
          <InvisibleDiv ref={scrollAnchorRef} />
        </AnswersContainer>
      </LayoutRoot>
    </StyledComponentWithLoader>
  );
};

export default SearchProductsDisplay;

const LayoutRoot = styled(Gap16VerticalFlex)``;

const SearchContainer = styled.div`
  padding: 12px 16px 0;
`;

const AnswersContainer = styled(VerticalFlex)`
  max-height: 192px;
  overflow: auto;
`;

const StyledComponentWithLoader = styled(ComponentWithLoader)`
  margin: 24px;
`;
