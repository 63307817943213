import React from 'react';
import PublishPaymentRequiredSrc from 'app/src/images/installation/publish-payment-required.png';
import {
  TextH2,
  TextSmall,
  TextSmallBold,
  TextTiny,
} from 'shared/react/components/basic/text/TextV2';
import {
  SecondaryButton,
  SuccessButton,
} from 'shared/react/components/basic/button-v2/BasicButton';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { PublishModalBaseCss } from 'app/src/pages/project/components/project-top-bar/components/set-live-button/css';
import { navigateToPlans } from 'app/src/utils/navigation.utils';
import { useHistory } from 'react-router-dom';

type Props = {
  onCancel: () => void;
  headerText?: string;
  subHeaderText?: string;
};

const PaymentRequiredModal = ({ onCancel, headerText, subHeaderText }: Props) => {
  const history = useHistory();

  const handleStartTrialClick = () => {
    navigateToPlans(history, true);
  };

  return (
    <LayoutRoot>
      <Image src={PublishPaymentRequiredSrc} width={300} alt="2 week free trial" />
      <Card>
        <Content>
          <ProBadge>Pro</ProBadge>
          <Title>2 week free trial</Title>
          <SubTitle>
            {headerText ||
              'Upgrade to a paid plan to experience the power of video directly on your site'}
          </SubTitle>
          <Text>
            {subHeaderText ||
              'You can cancel at any point during your free trial period, without any charges.'}
          </Text>
        </Content>
        <Buttons>
          <SecondaryButton size="normal" onClick={onCancel}>
            Cancel
          </SecondaryButton>
          <SuccessButton size="normal" onClick={handleStartTrialClick}>
            Start free trial
          </SuccessButton>
        </Buttons>
      </Card>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  ${PublishModalBaseCss};
  overflow: hidden;
  top: 58px;
  width: 650px;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
`;

const Image = styled.img`
  margin: -16px 0 -16px -16px;
`;

const Card = styled(VerticalFlex)`
  padding: 8px 8px 8px 24px;
`;

const Content = styled(VerticalFlex)`
  flex-grow: 1;
`;

const Buttons = styled(Gap8HorizontalFlex)`
  justify-content: flex-end;
`;

const ProBadge = styled(TextTiny)`
  align-self: flex-start;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.grapePurple};
  border-radius: 2px;
  text-transform: uppercase;
  padding: 2px;
`;

const Title = styled(TextH2)`
  color: ${({ theme }) => theme.colors.grapePurple};
  font-weight: 700;
  line-height: 40px;
`;

const SubTitle = styled(TextSmallBold)`
  margin-top: 40px;
`;

const Text = styled(TextSmall)`
  margin-top: 16px;
`;

export default PaymentRequiredModal;
