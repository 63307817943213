import React from 'react';
import PricingTooltip from 'app/src/complex_components/PricingTooltip';
import Utils from 'app/src/utils';
import { useBilling } from 'app/src/hooks/useBillings';

const CreateTolstoyTooltipV2 = ({ isLocked, children, placement, ...props }) => {
  const {
    lockTolstoyCreation,
    maxNumberOfAllowedTolstoys,
    maxNumberOfAllowedVideos,
    hasReachedVideosLimit,
    hasReachedTolstoysLimit,
  } = useBilling();

  if (hasReachedTolstoysLimit) {
    return (
      <PricingTooltip
        header="TOLSTOYS COUNT"
        text={`You have reached your limit of ${maxNumberOfAllowedTolstoys} Tolstoys.`}
        analyticsData={Utils.getAnalyticsLocationObject('Create Tolstoy Tooltip')}
        shouldHide={!lockTolstoyCreation}
        placement={placement}
        {...props}
      >
        {children}
      </PricingTooltip>
    );
  }

  return (
    <PricingTooltip
      header="VIDEOS COUNT"
      text={`You have reached your limit of ${maxNumberOfAllowedVideos} videos.`}
      analyticsData={Utils.getAnalyticsLocationObject('Create Tolstoy Tooltip')}
      shouldHide={!lockTolstoyCreation || !hasReachedVideosLimit}
      placement={placement}
      {...props}
    >
      {children}
    </PricingTooltip>
  );
};

export default CreateTolstoyTooltipV2;
