import { useVodAssets } from 'app/src/context/VideosStore';
import { getVodConnectionTypeKey } from 'app/src/utils/vodConnections.utils';
import { VodConnectionType } from 'app/src/types/entities';
import { DynamicRuleTypes } from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/constants/dynamicConditions.constants';

import { FULL_SOURCES_UPLOAD_TYPE } from 'app/src/constants/videoSources.constants.js';

const getVodSource = source => {
  return Object.keys(FULL_SOURCES_UPLOAD_TYPE).find(key =>
    FULL_SOURCES_UPLOAD_TYPE[key].includes(source)
  );
};

const useVodSourceVodConnections = () => {
  const [videos] = useVodAssets();

  const getVodSourceVodConnections = ruleGroup => {
    const sources =
      ruleGroup?.rules?.flat().flatMap(rule => {
        if (rule.type !== DynamicRuleTypes.vodSource) {
          return [];
        }

        return rule.value;
      }) || [];

    return videos.flatMap(video => {
      const vodSource = getVodSource(video.uploadType);

      if (!vodSource || !sources?.includes(vodSource)) {
        return [];
      }

      return {
        vodAssetId: video.id,
        typeKey: getVodConnectionTypeKey({ appKey: video.appKey, id: vodSource }),
        type: VodConnectionType.vodSource,
      };
    });
  };

  return getVodSourceVodConnections;
};

export default useVodSourceVodConnections;
