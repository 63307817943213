import React from 'react';

const Play = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.5 15C4.625 15 4 14.3125 4 13.5V2.5C4 1.71875 4.625 1.03125 5.5 1.03125C5.75 1.03125 6.03125 1.09375 6.28125 1.25L15.2812 6.75C15.7188 7 16 7.5 16 8C16 8.53125 15.7188 9.03125 15.2812 9.28125L6.28125 14.7812C6.03125 14.9375 5.75 15 5.5 15Z"
        fill="#3D4FE0"
      />
    </svg>
  );
};

export default Play;
