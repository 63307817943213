import { useProductActions } from 'app/src/context/ProductsStore';
import useLocalStorage from 'app/src/hooks/useLocalStorage';
import Utils from 'app/src/utils';
import { MAGENTO, SHOPIFY } from 'app/src/constants/intergrations.constants';

const getMigrationLocalStorageKey = appUrl => {
  return `tolstoy-${appUrl}-search-v2-migrating`;
};

const useGetSearchProducts = (selectedApp, skipMigration = false) => {
  const { getSearchProducts, getSearchProductsV2, triggerSearchV2Migration } = useProductActions();
  const appUrl = selectedApp?.appUrl;
  const isShopify = selectedApp?.app === SHOPIFY;

  const [hasTriggeredSearchV2Migration, setHasTriggeredSearchV2Migration] = useLocalStorage(
    getMigrationLocalStorageKey(appUrl)
  );

  const triggerSearchV2MigrationIfNeeded = () => {
    if (hasTriggeredSearchV2Migration) {
      return;
    }

    try {
      triggerSearchV2Migration(appUrl);
    } catch (error) {
      Utils.logError('Failed to trigger searchV2 migration', error);
    }

    setHasTriggeredSearchV2Migration(true);
  };

  if (selectedApp?.app === MAGENTO) {
    return getSearchProducts;
  }

  const data = Utils.safeParse(selectedApp?.data);

  const { hasCreatedElasticSearchProducts } = data;
  if (hasCreatedElasticSearchProducts) {
    return getSearchProductsV2;
  }

  if (isShopify && !skipMigration) {
    triggerSearchV2MigrationIfNeeded();
  }

  return getSearchProducts;
};

export default useGetSearchProducts;
