import Tooltip from 'app/src/basic_components/tooltip-v2/Tooltip';
import {
  ADD_PRODUCTS_MODAL_KEY,
  VIDEO_PREVIEW_MODAL_KEY,
} from 'app/src/constants/modals.constants';
import { useAppActions } from 'app/src/context/AppsStore';
import { useProducts } from 'app/src/context/ProductsStore';
import { getOrderVodRuleTypeKey } from 'app/src/context/RulesStore';
import { useVideoActions } from 'app/src/context/VideosStore';
import { useVodConnection } from 'app/src/context/VodConnectionStore';
import { useModalActions } from 'app/src/context/ui_store/ModalStore';
import TableVideoOverlay from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/video-card/TableVideoOverlay';
import { project as Project, rule as RuleType, vodAsset } from 'app/src/types/entities';
import React, { useRef, useState } from 'react';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';
import { TextSmall } from 'shared/react/components/basic/text/TextV2';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import {
  VirtualizedVideosImage,
  VirtualizedVideosImageContainer,
} from 'src/basic_components/virtualized-table/VirtualizedRows';
import { AnalyticsDataProps } from 'src/types/common/common';
import styled from 'styled-components';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';

type Props = {
  className?: string;
  video: vodAsset;
  analyticsData?: AnalyticsDataProps;
  videoRules?: RuleType[];
  setVideoRules?: (rules: RuleType[]) => void;
  productId?: string;
  isDragged?: boolean;
  index?: number;
  isDragDisabled?: boolean;
  project: Project;
  canLock?: boolean;
  canOpenProductsModal?: boolean;
  isDiscover?: boolean;
  onRemove?: () => void;
};

const TableVideoCard = ({
  className,
  video,
  analyticsData,
  productId,
  videoRules,
  setVideoRules,
  isDragged,
  index,
  isDragDisabled,
  project,
  canLock = true,
  canOpenProductsModal = true,
  isDiscover,
  onRemove,
}: Props) => {
  const { getVideoPoster } = useVideoActions();
  const { setCurrentModal, setCurrentIds, setModalProps, setCurrentId } = useModalActions();
  const [{ products }] = useProducts();
  const [{ productsByVodAssetMap = {}, projectIdsByVodAssetIdMap = {} }] = useVodConnection();
  const { getProductsCatalogApp } = useAppActions();
  const [isImageBroken, setIsImageBroken] = useState(false);
  const counter = useRef(0);
  const productsCatalogApp = getProductsCatalogApp();
  const { player } = useDesignSettingsContext();

  if (!video) {
    return null;
  }

  const hasManualVodConnection = projectIdsByVodAssetIdMap?.[video.id]?.some(
    connection => connection.projectId === project.id
  );
  const shouldShowRemoveButton = onRemove && hasManualVodConnection;
  const vodProductIds = productsByVodAssetMap?.[video.id]?.[project?.appUrl] || [];
  const isOutOfStock =
    !!vodProductIds.length &&
    !vodProductIds.some(productId => !products?.[productId]?.isOutOfStock);

  const { hideOOSProducts } = player.feedCards.productDetailsCard.stock;
  const isVideoNameLight = isOutOfStock && hideOOSProducts;

  const isLocked = !!videoRules?.find(({ value }) => {
    return value === getOrderVodRuleTypeKey(video.id, index, productId);
  });

  const handleOpenProductsModal = () => {
    if (!canOpenProductsModal) {
      return;
    }
    if (!productsCatalogApp) {
      setCurrentId(video.id);
      setCurrentModal(VIDEO_PREVIEW_MODAL_KEY);
      return;
    }

    setModalProps({ analyticsData });
    setCurrentIds([video.id]);
    setCurrentModal(ADD_PRODUCTS_MODAL_KEY);
  };

  const handleImageLoadError = e => {
    const target = e.target;
    if (counter.current >= 3) {
      setIsImageBroken(true);
      return;
    }

    setTimeout(() => {
      counter.current++;
      target.src = getVideoPoster(video);
    }, 4500);
  };

  return (
    <LayoutRoot
      className={className}
      isDiscover={isDiscover}
      isDragged={isDragged}
      onClick={handleOpenProductsModal}
    >
      <VirtualizedVideosImageContainer>
        <Tooltip placement="top" hidden={!isLocked} text="Unlock video to reorder">
          <VirtualizedVideosImage
            draggable={!isDragDisabled}
            src={isImageBroken ? null : getVideoPoster(video)}
            onError={handleImageLoadError}
          />
          <StyledVideoOverlay
            productId={productId}
            videoRules={videoRules}
            setVideoRules={setVideoRules}
            video={video}
            index={index}
            isLocked={isLocked}
            isOutOfStock={isOutOfStock}
            canLock={canLock}
            isDiscover={isDiscover}
            canRemove={shouldShowRemoveButton}
            onRemove={onRemove}
          />
        </Tooltip>
      </VirtualizedVideosImageContainer>
      <EllipsisTextOverflowWrapper>
        <VideoName className="notranslate" isLight={isVideoNameLight}>
          {video.name}
        </VideoName>
      </EllipsisTextOverflowWrapper>
    </LayoutRoot>
  );
};

const VideoName = styled(TextSmall)<{ isLight: boolean }>`
  padding: 4px 8px;
  color: ${({ theme, isLight }) =>
    isLight ? theme.colors.neutralDark : theme.colors.neutralBlack};
`;

const StyledVideoOverlay = styled(TableVideoOverlay)``;

const LayoutRoot = styled(VerticalFlex)<{ isDragged: boolean; isDiscover: boolean }>`
  position: relative;
  background: ${({ theme }) => theme.colors.white};
  padding: 2px;
  max-height: ${({ isDiscover }) => (isDiscover ? '162px' : '126px')};

  box-shadow: 0 2px 4px rgba(50, 50, 93, 0.1);
  border-radius: 4px;
  cursor: pointer;
  filter: ${({ isDragged }) => (isDragged ? 'opacity(0.5)' : '')};

  &:hover ${StyledVideoOverlay} {
    display: flex;
  }
`;

const LabelsContainer = styled.div`
  margin: 0 8px;
`;

export default TableVideoCard;
