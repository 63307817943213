import React from 'react';
import { milkConditionCss } from 'shared/react/components/clientsCss.constants';
import { TextH4 } from 'shared/react/components/complex/Text';
import {
  PRODUCT_DISCOUNTED_PRICE_DATA_TYPE,
  PRODUCT_PRICE_DATA_TYPE,
} from 'shared/react/constants/feedPreviewDataTypes.constants';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';
import useIsMilk from 'shared/react/hooks/useIsMilk';
import useIsPrincessPolly from 'shared/react/hooks/useIsPrincessPolly';
import Utils from 'shared/react/utils/utils';
import styled, { css } from 'styled-components';
import useProjectConfig from '../../context/hooks/useProjectConfig';
import HorizontalFlex from '../../flex_layouts/HorizontalFlex';
import FeedProductPriceDisplay from './FeedProductPriceDisplay';
import { getDesignCss } from 'shared/react/utils/addSharedCss';

const FeedProductPrice = ({
  product,
  selectedVariant,
  isMultipleProductDetails,
  className,
  isMobileView,
  design,
}) => {
  const [{ playerSettings }] = useProjectConfig();
  const { productDetailsPriceEnabled: enabled } = playerSettings || {};
  const currencySymbol = product?.currencySymbol;
  const {
    player: { feedCards },
  } = useDesignSettingsContext();
  const { bodyColorText, discountedColorText } = feedCards.text;
  const { includeProductPrice } = feedCards.productDetailsCard.primaryButton.settings;
  const isMilk = useIsMilk();
  const isPrincessPolly = useIsPrincessPolly();

  const getDisplayedData = () => {
    const { compareAtPrice, price: finalPrice } = selectedVariant;
    const displayCompareAtPrice = Utils.getDisplayedPriceSegments({
      price: compareAtPrice,
      currencySymbol,
    });
    const displayedFinalPrice = Utils.getDisplayedPriceSegments({
      price: finalPrice,
      currencySymbol,
    });

    return { displayCompareAtPrice, displayedFinalPrice };
  };

  const primaryButtonHasPrice = includeProductPrice && !isMobileView && !isMultipleProductDetails;

  if (enabled === false || primaryButtonHasPrice) {
    return null;
  }

  if (product.isFetchingPrice) {
    return <PriceSkeleton isPrincessPolly={isPrincessPolly}>---</PriceSkeleton>;
  }

  if (
    isMultipleProductDetails ||
    !selectedVariant?.price ||
    !selectedVariant?.compareAtPrice ||
    +selectedVariant?.compareAtPrice <= +selectedVariant?.price
  ) {
    const price = Utils.getDisplayedPriceSegments({
      price: selectedVariant?.price,
      currencySymbol,
    });

    return (
      <ProductPrice
        data-type={PRODUCT_PRICE_DATA_TYPE}
        className={className}
        bodyColorText={bodyColorText}
        isMilk={isMilk}
        isMobileView={isMobileView}
        $isPrincessPolly={isPrincessPolly}
        $design={design?.productPrice}
      >
        <FeedProductPriceDisplay price={price} />
      </ProductPrice>
    );
  }

  const { displayCompareAtPrice, displayedFinalPrice } = getDisplayedData();

  return (
    <DiscountedPriceContainer
      isMilk={isMilk}
      data-type={PRODUCT_DISCOUNTED_PRICE_DATA_TYPE}
      className={className}
    >
      <CompareAtPrice
        $design={design?.productPrice}
        className={className}
        isMilk={isMilk}
        isMobileView={isMobileView}
        $saleDesign={design?.salePrice}
      >
        <FeedProductPriceDisplay price={displayCompareAtPrice} />
      </CompareAtPrice>
      <FinalPrice
        className={className}
        discountedColorText={discountedColorText}
        isMilk={isMilk}
        isMobileView={isMobileView}
        $design={design?.productPrice}
      >
        <FeedProductPriceDisplay price={displayedFinalPrice} />
      </FinalPrice>
    </DiscountedPriceContainer>
  );
};

const milkFontSizeCss = css`
  ${({ isMilk, isMobileView }) => {
    if (!isMilk) {
      return '';
    }

    if (isMobileView) {
      return `
        font-size: 18px !important;
        line-height: 22px !important;
        font-weight:600;
      `;
    }

    return `
      font-size: 20px !important;
      line-height: 24px !important;
      font-weight:600;
    `;
  }}
`;

const ProductPrice = styled(TextH4)`
  font-size: 18px;
  line-height: 20px;
  font-weight: normal;
  color: ${({ bodyColorText, theme }) => bodyColorText || theme.colors.black};
  ${milkFontSizeCss};
  ${({ $design }) => getDesignCss($design)};
  font-size: ${({ $isPrincessPolly }) => ($isPrincessPolly ? '16px' : '')};
`;

const DiscountedPriceContainer = styled(HorizontalFlex)`
  font-size: 18px;
  line-height: 20px;
  flex-wrap: wrap;
  gap: 4px;
  ${milkConditionCss};
`;

const CompareAtPrice = styled.div`
  text-decoration: line-through;
  font-size: 18px;
  align-self: center;

  ${milkFontSizeCss};

  && {
    color: ${({ theme }) => theme.colors.gray4};
    ${({ $saleDesign }) => getDesignCss($saleDesign)};
  }
  ${({ $design }) => getDesignCss($design)};
`;

const FinalPrice = styled.div`
  font-weight: 700;
  color: ${({ discountedColorText, theme }) => discountedColorText || theme.colors.red7};
  ${milkFontSizeCss};
  ${({ $design }) => getDesignCss($design)};
`;

const PriceSkeleton = styled.div`
  align-self: flex-start;
  padding: 0 8px;
  margin-bottom: ${({ isPrincessPolly }) => (isPrincessPolly ? '-22px' : 0)};
  color: #aaa;
  background-color: #ddd;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );
  background-position: left -30px top 0;
  background-repeat: no-repeat;
  border-radius: 4px;
  animation: shine 1s ease infinite;

  @keyframes shine {
    to {
      background-position: right -30px top 0;
    }
  }
`;

export default FeedProductPrice;
