import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import StandardModal from './StandardModal';
import Video from 'app/src/complex_components/Video';
import { useVideoSwitcherInModal } from 'app/src/pages/dashboard/pages/videos/components/video_card/useVideoSwitcherInModal';
import {
  LeftButton,
  RightButton,
} from 'app/src/pages/dashboard/pages/videos/components/video_card/video-switcher/buttons';
import Routes from 'app/src/helpers/Routes';
import { useKeydownEvent } from 'app/src/complex_components/global_modal/video/useKeydownEvent';

export default function VideoModal({ src, open, onClose, onError }) {
  const { pathname } = useLocation();
  const isVideosDashboard = Routes.isVideosDashboard(pathname);
  const { moveToVideo } = useVideoSwitcherInModal({ shouldMove: useCallback(() => true, []) });

  useKeydownEvent(
    'Escape',
    useCallback(
      e => {
        e.preventDefault();
        onClose();
      },
      [onClose]
    )
  );

  return (
    <StandardModal open={open} onClose={onClose}>
      {isVideosDashboard && <LeftButton moveToVideo={moveToVideo} />}
      <StyledVideo mute={false} autoPlay hasControls={true} src={src} onError={onError} />
      {isVideosDashboard && <RightButton moveToVideo={moveToVideo} />}
    </StandardModal>
  );
}

const StyledVideo = styled(Video)`
  margin: 20px;
  width: 600px;
  height: 400px;
  object-fit: contain;
  border-radius: 6px;
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 90%;
  }
`;
