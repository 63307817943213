import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import HorizontalFlex, {
  Gap8HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextSmall } from 'shared/react/components/complex/Text';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';
import ThinPlusIcon from 'app/src/images/ThinPlusIcon';
import { MenuItemProps } from 'app/src/complex_components/menu_items/types/MenuItem.types';
import { DesignSizes } from 'app/src/types/design-system';
import Tooltip from 'app/src/basic_components/tooltip-v2/Tooltip';

const MenuItemV3: FC<MenuItemProps> = ({
  icon,
  showTextOnMobile = true,
  endIcon,
  isSelected = false,
  children,
  hidden = false,
  dataTestId,
  className,
  tooltipText,
  disabled,
  isLoading,
  color,
  tooltipPlacement = 'right',
  showEndIconOnHover = false,
  ...props
}) => {
  if (hidden) {
    return null;
  }

  return (
    <MenuItemV3LayoutRoot>
      <StyledTooltip
        hidden={!tooltipText}
        text={tooltipText as string}
        placement={tooltipPlacement}
      >
        <LayoutRoot
          isSelected={isSelected}
          data-test-id={dataTestId}
          className={className}
          disabled={disabled}
          isLoading={isLoading}
          {...props}
        >
          <LeftContainer>
            {!!icon && (
              <MenuItemIconContainer disabled={disabled} isLoading={isLoading}>
                {icon}
              </MenuItemIconContainer>
            )}
            <EllipsisTextOverflowWrapper>
              <Text
                color={color}
                disabled={disabled}
                isLoading={isLoading}
                showTextOnMobile={showTextOnMobile}
              >
                {children}
              </Text>
            </EllipsisTextOverflowWrapper>
          </LeftContainer>
          {!!endIcon && (
            <MenuItemEndIconContainer
              showEndIconOnHover={showEndIconOnHover}
              disabled={disabled}
              isLoading={isLoading}
            >
              {endIcon}
            </MenuItemEndIconContainer>
          )}
        </LayoutRoot>
      </StyledTooltip>
    </MenuItemV3LayoutRoot>
  );
};

const StyledTooltip = styled(Tooltip)``;

const LayoutRoot = styled(Gap8HorizontalFlex)<{ color?: string }>`
  width: 100%;
  padding: 10px 16px;
  cursor: ${({ isLoading, disabled }) => (isLoading || disabled ? 'default' : 'pointer')};
  transition: 0.3s;
  background: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.neutralLightest : theme.colors.white};
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  opacity: ${({ isLoading }) => (isLoading ? 0.7 : 1)};
  pointer-events: ${({ isLoading, disabled }) => (isLoading || disabled ? 'none' : 'auto')};

  &:hover {
    background: ${({ theme, isLoading, disabled }) =>
      isLoading || disabled ? '' : theme.colors.neutralLightest};
  }

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    padding: 8px 4px;
  }
`;

const LeftContainer = styled(Gap8HorizontalFlex)`
  align-items: center;
`;

export const MenuItemIconContainer = styled(HorizontalFlex)`
  position: relative;
  width: 20px;
  height: 20px;
  align-items: center;

  svg {
    height: 20px;
    width: 20px;
  }

  && > svg > path {
    fill: ${({ theme, disabled, isLoading }) =>
      disabled || isLoading ? theme.colors.neutralDark : ''};
  }
`;

export const MenuItemEndIconContainer = styled(MenuItemIconContainer)<{
  showEndIconOnHover: boolean;
}>`
  opacity: ${({ showEndIconOnHover }) => (showEndIconOnHover ? 0 : 1)};
`;

export const MenuItemV3LayoutRoot = styled(HorizontalFlex)`
  width: 100%;

  &:hover {
    ${MenuItemEndIconContainer} {
      opacity: 1;
    }
  }

  & > div {
    width: 100%;
  }
`;

const Text = styled(TextSmall)`
  font-weight: 500;
  color: ${({ theme, disabled, isLoading, color }) =>
    isLoading || disabled ? theme.colors.neutralDark : color};

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    display: ${({ showTextOnMobile }) => (showTextOnMobile ? 'block' : 'none')};
  }
`;

export const CreateMenuItem = styled(MenuItemV3).attrs(() => ({
  icon: <ThinPlusIcon />,
}))`
  div {
    color: ${({ theme }) => theme.colors.primary};
  }

  & path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

export const xsmallMenuItemCss = css``;

export const smallMenuItemCss = css`
  padding: 8px 12px;
  border-radius: 6px;

  & ${Text} {
    font-size: 12px;
    line-height: 14px;
  }

  & ${MenuItemIconContainer} {
    width: 16px;
    height: 16px;
  }

  & svg {
    width: 16px;
    height: 16px;
  }
`;

export const normalMenuItemCss = css``;

export const largeMenuItemCss = css``;

const menuItemSizesCssMap = {
  [DesignSizes.XSMALL]: xsmallMenuItemCss,
  [DesignSizes.SMALL]: smallMenuItemCss,
  [DesignSizes.NORMAL]: normalMenuItemCss,
  [DesignSizes.LARGE]: largeMenuItemCss,
};

export const menuItemSizesCss = css<{ size: DesignSizes }>`
  ${({ size }) => menuItemSizesCssMap[size]}
`;

export default MenuItemV3;
