import React from 'react';
import { SelectedProductsProvider } from 'app/src/complex_components/global_modal/video/add_products_modal/SelectedProductsContext';
import GalleryModal from 'app/src/complex_components/global_modal/gallery-modal/GalleryModal';

type Props = {
  closeModal: () => void;
};
const GalleryModalWrapper = ({ closeModal }: Props) => {
  return (
    <SelectedProductsProvider>
      <GalleryModal closeModal={closeModal} />
    </SelectedProductsProvider>
  );
};

export default GalleryModalWrapper;
