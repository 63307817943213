import React from 'react';
import styled, { useTheme } from 'styled-components';
import { TextSmall } from 'shared/react/components/complex/Text';
import FilterIcon from 'app/src/images/Filter';
import { EMAIL_FILTER } from 'app/src/constants/responsesInbox.constants';
import Utils from 'shared/react/utils/utils';

const FILTERS = [
  { id: 'reachedEnd', name: 'Completed' },
  { id: [EMAIL_FILTER], name: 'Email collected' },
  { id: 'videoResponse', name: 'Video response' },
  { id: 'audioResponse', name: 'Audio response' },
  { id: 'textResponse', name: 'Text response' },
];

const InboxFilters = ({ filters, onFiltersChange }) => {
  const theme = useTheme();
  const onChange = id => {
    const newFilters = new Set(filters);
    if (newFilters.has(id)) {
      newFilters.delete(id);
    } else {
      newFilters.add(id);
    }
    onFiltersChange(newFilters);
  };

  return (
    <StyledFilters>
      <FilterTitle>
        <FilterIcon />
        <TextSmall fontWeight="700" textColor={theme.colors.ghostDark}>
          Filter
        </TextSmall>
      </FilterTitle>
      <FilterContainer>
        {FILTERS.map(({ id, name }) => (
          <FilterRow key={id} onClick={() => onChange(id)}>
            <FilterCheckbox
              checked={filters.has(id)}
              onChange={() => onChange(id)}
              onClick={Utils.stopPropagation}
            />
            <TextSmall textColor={theme.colors.gray2}>{name}</TextSmall>
          </FilterRow>
        ))}
      </FilterContainer>
    </StyledFilters>
  );
};

export default InboxFilters;

const StyledFilters = styled.div`
  grid-area: filters;
  padding-top: 10px;
  padding-left: 17px;
  box-shadow: inset -1px 0px 0px ${({ theme }) => theme.colors.gray3};
`;

const FilterTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 20px 0;
`;

const FilterRow = styled.div`
  > * {
    padding: 0;
  }
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

const FilterCheckbox = styled.input.attrs(() => ({
  type: 'checkbox',
}))`
  width: 16px;
  height: 16px;
  outline: 1px solid ${({ theme }) => theme.colors.gray4};
  outline-offset: -1px;
  cursor: pointer;
`;
