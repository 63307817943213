export const DEFAULT_IMAGE_EXTENSION = '.webp';

export const ASSET_TYPE = {
  VIDEO: 'video',
  IMAGE: 'image',
  GALLERY: 'gallery'
};

export const IMAGE_SIZE = {
  XS: 250,
  S: 480,
  M: 960,
  L: 1280,
  XL: 1920
};

export const IMAGE_SIZE_SUFFIX = {
  [IMAGE_SIZE.XS]: '_250',
  [IMAGE_SIZE.S]: '_480',
  [IMAGE_SIZE.M]: '_960',
  [IMAGE_SIZE.L]: '_1280',
  [IMAGE_SIZE.XL]: '_1920'
};
