import {
  FEATURE_APP_LIMITS,
  FEATURE_LIMIT_MAX_RECORDING_TIME,
  FEATURE_LIMIT_MIN_RECORDING_TIME,
} from 'shared/react/constants/features.constants';
import Utils from 'app/src/utils';
import { getVideoInfo } from 'app/src/utils/video.utils';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';
import { MAX_IMAGE_SIZE_MB } from 'src/constants/assets.constants';
import { AssetsUtilsService } from 'shared/react/services/assets';

const useUploadFile = () => {
  const [, { getSettingsByKey }] = useFeatures();
  const [, { setErrorSnackbar }] = useSnackBar();

  const checkVideoFile = async file => {
    const maxFileSizeMB = getSettingsByKey(FEATURE_APP_LIMITS).maxFileSizeMB;
    const limitMaxRecordingTimeSettings = getSettingsByKey(FEATURE_LIMIT_MAX_RECORDING_TIME);
    const limitMinRecordingTimeSettings = getSettingsByKey(FEATURE_LIMIT_MIN_RECORDING_TIME);
    const { maxRecordingTime, isUnlimited, globalMaxRecordingTime } =
      limitMaxRecordingTimeSettings || {};
    const { minRecordingTime } = limitMinRecordingTimeSettings || {};

    if (!file.type.includes('video')) {
      setErrorSnackbar(
        'File type should be video. Please contact support if you need further assistance'
      );
      return {};
    }

    if (Utils.sizeInBytesToMb(file.size) > maxFileSizeMB) {
      console.log('File size is high', Utils.sizeInBytesToMb(file.size));
      setErrorSnackbar(
        `File size should be lower than ${maxFileSizeMB} Megabytes. Please contact support if you need further assistance`
      );
      return {};
    }

    const urlBlob = URL.createObjectURL(file);
    const maxTime = isUnlimited ? globalMaxRecordingTime : maxRecordingTime;
    const { duration, isVertical } = await getVideoInfo(urlBlob);

    if (duration === Infinity) {
      return { file, isValid: true };
    }

    if (duration > maxTime) {
      console.log('Video duration is high', duration);
      setErrorSnackbar(
        `Video is too long. Please upload video shorter than ${maxRecordingTime} seconds or contact support for assistance`
      );
      return {};
    }

    if (duration < minRecordingTime) {
      console.log('Video duration is low', duration);
      setErrorSnackbar(
        `Video is too short. Please upload video longer than ${minRecordingTime} seconds or contact support for assistance`
      );
      return {};
    }

    return { file, isVertical, duration, isValid: true };
  };

  const checkImageFile = async file => {
    if (Utils.sizeInBytesToMb(file.size) > MAX_IMAGE_SIZE_MB) {
      console.log('File size is high', Utils.sizeInBytesToMb(file.size));
      setErrorSnackbar(
        `File size should be lower than ${MAX_IMAGE_SIZE_MB} Megabytes. Please contact support if you need further assistance`
      );
      return {};
    }

    if (!AssetsUtilsService.isImageFileSupported(file)) {
      setErrorSnackbar(
        `File type ${file.type} not supported. Please contact support if you need further assistance`
      );
      return {};
    }

    return { file, isValid: true };
  };

  const checkFile = async file => {
    if (file.type.includes('video')) {
      return checkVideoFile(file);
    }

    if (file.type.includes('image')) {
      return checkImageFile(file);
    }

    setErrorSnackbar(`Invalid type of file. Please contact support if you need further assistance`);

    return {};
  };

  return {
    checkFile,
    checkVideoFile,
    checkImageFile,
  };
};

export default useUploadFile;
