import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import VolumeOffIcon from 'app/src/images/volume_off_icon.svg';
import VolumeUpIcon from 'app/src/images/volume_up_icon.svg';
import IconButton from 'app/src/complex_components/IconButton';
import SideBarButtons from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/video_preview/SideBarButtons';
import StepPreviewButton from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/video_preview/StepPreviewButton';
import TimeBarContainer from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/video_preview/TimeBarContainer';
import { BaseVideoSize } from 'app/src/basic_components/BaseVideoSize';
import FeedOverlay from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/video_preview/FeedOverlay';
import RootProvidersWrapper from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/video_preview/RootProvidersWrapper';
import VideoSettingsToolbar from 'app/src/complex_components/VideoSettingsToolbar';
import { useIsMobile } from 'shared/react/hooks/useIsMobile';
import SmallProductWrapper from 'shared/react/components/complex/feed/feed_overlay_mobile/SmallProductWrapper';
import PlayerGlobalModal from 'shared/react/components/complex/PlayerGlobalModal';
import { PRODUCTS_SECTION_WRAPPER_DATA_TYPE } from 'shared/react/constants/feedPreviewDataTypes.constants';
import { getIsFeed } from 'app/src/context/ProjectsStore';

const SCREEN_LAPTOP_INCH_RATIO = 16 / 24;

const VideoOverlay = ({
  answers,
  videoLoaded,
  isProductType,
  isDefaultResponseButtonsEnabled,
  overlayText,
  overlayTextColor,
  isSidebarButtonsEnabled,
  setMute,
  mute,
  isLoading,
  isVideoReady,
  isEditingAnswersTime,
  setEditingAnswersTime,
  videoDuration,
  playTime,
  answerTime,
  setAnswerTime,
  videoRef,
  project,
  step,
  isMobilePreview,
  verticalOrientation,
  video,
  onChangeVideoClick,
}) => {
  const isMobile = useIsMobile();

  const handleAnswerTimeChanged = value => {
    setAnswerTime(value);
  };

  const handleCurrentTimeChanged = value => {
    videoRef.current.currentTime = value;
  };

  const handleClickToggleMute = event => {
    event.stopPropagation();
    setMute(!mute);
  };

  const getAdvancedOptions = () => {
    if (isProductType) {
      return (
        <>
          <ProductsContainer>
            <ProductsSectionContainerWrapper data-type={PRODUCTS_SECTION_WRAPPER_DATA_TYPE}>
              <SmallProductWrapper
                step={step}
                videoRef={videoRef}
                hidePoweredBy
                isInteractive={!getIsFeed(project)}
                pause={() => {}}
                play={() => {}}
                verticalOrientation={verticalOrientation || isMobilePreview}
              />
            </ProductsSectionContainerWrapper>
          </ProductsContainer>
          <ProductsMainContainer isVertical={verticalOrientation || isMobilePreview} hidePoweredBy>
            <PlayerGlobalModal />
          </ProductsMainContainer>
        </>
      );
    }

    if (isSidebarButtonsEnabled && videoLoaded) {
      return <StyledSideBarButtons />;
    }

    return null;
  };

  const videoSettingsToolbar = useMemo(
    () => (
      <VideoSettingsToolbar
        step={step}
        project={project}
        projectId={project?.id}
        video={video}
        onChangeVideoClick={onChangeVideoClick}
        isLoading={isLoading}
        isVideoReady={isVideoReady}
        videoDuration={videoDuration}
        isMobilePreview={isMobilePreview}
        videoRef={videoRef}
        isEditingAnswersTime={isEditingAnswersTime}
        setEditingAnswersTime={setEditingAnswersTime}
        videoLoaded={videoLoaded}
      />
    ),
    [step, video, onChangeVideoClick, isMobilePreview]
  );

  if (project?.feed) {
    return (
      <>
        <RootProvidersWrapper project={project} isMuted={mute} setIsMuted={setMute}>
          <FeedOverlay
            videoLoaded={videoLoaded}
            step={step}
            project={project}
            isMobilePreview={isMobilePreview}
            videoRef={videoRef}
          />
        </RootProvidersWrapper>
        {videoSettingsToolbar}
      </>
    );
  }

  const getButtonsWidth = () => {
    const buttonsCount = answers?.length || 0;

    if (verticalOrientation || isMobile) {
      return 280;
    }

    if (buttonsCount === 4) {
      return 800;
    }

    return 916;
  };

  return (
    <>
      <VideoControlsContainer verticalOrientation={verticalOrientation} videoLoaded={videoLoaded}>
        <LowerContainer isDefaultResponseButtonsEnabled={isDefaultResponseButtonsEnabled}>
          <OverlayText isProductType={isProductType} overlayTextColor={overlayTextColor}>
            {overlayText}
          </OverlayText>
          {!isProductType && (
            <BottomButtonsContainer
              isDefaultResponseButtonsEnabled={isDefaultResponseButtonsEnabled}
              width={getButtonsWidth()}
              buttonsCount={answers?.length || 0}
              verticalOrientation={verticalOrientation}
            >
              {answers?.map(({ key, type, text }, i) => (
                <StepPreviewButton
                  key={key}
                  type={type}
                  i={i}
                  text={text}
                  answers={answers}
                  isDefaultResponseButtonsEnabled={isDefaultResponseButtonsEnabled}
                  project={project}
                />
              ))}
            </BottomButtonsContainer>
          )}
        </LowerContainer>
      </VideoControlsContainer>
      {getAdvancedOptions()}
      <TopContainer>
        <MuteButtonContainer>
          <MuteButton
            onClick={handleClickToggleMute}
            src={mute ? VolumeOffIcon : VolumeUpIcon}
            disabled={isLoading}
          />
        </MuteButtonContainer>
        {isVideoReady && (
          <TimeBarContainer
            videoDuration={videoDuration}
            answerTime={answerTime}
            handleAnswerTimeChanged={handleAnswerTimeChanged}
            playTime={playTime}
            handleCurrentTimeChanged={handleCurrentTimeChanged}
            isEditingAnswersTime={isEditingAnswersTime}
          />
        )}
      </TopContainer>
      {videoSettingsToolbar}
    </>
  );
};

const VideoControlsContainer = styled(BaseVideoSize)`
  position: relative;
  display: ${({ videoLoaded }) => (videoLoaded ? 'grid' : 'none')};
  height: 100%;
  aspect-ratio: ${({ verticalOrientation }) => (verticalOrientation ? '9/16' : '16/9')};
  width: inherit;
  grid-template-rows: auto 1fr auto;
  grid-row: 1;
  grid-column: 1;
  z-index: 2;
  overflow-y: auto;

  @media (max-height: 811px) {
    height: 410px;
  }

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 100%;
    border-radius: 8px;
  }
`;

const MuteButtonContainer = styled.div`
  padding: 4px;
  display: grid;
  width: fit-content;
  z-index: 1;
`;

const MuteButton = styled(IconButton)`
  grid-row: 1;
  grid-column: 4;
  color: white;
  filter: drop-shadow(0 3px 2px rgba(0, 0, 0, 1));
`;

const TopContainer = styled.div`
  display: grid;
  align-self: start;
  grid-template-columns: auto 1fr;
  grid-row: 1;
  grid-column: 1;
  z-index: 4;
`;

const LowerContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-column: 1;
  grid-row: 2;
  z-index: 1;
  gap: 16px;
  max-height: 240px;
  overflow-y: auto;
  overflow-x: hidden;
  align-self: end;
  align-items: center;
  justify-content: center;
  padding: ${({ isDefaultResponseButtonsEnabled }) =>
    isDefaultResponseButtonsEnabled ? '16px' : '0px 8px 16px 8px'};
`;

const OverlayText = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${({ overlayTextColor, theme }) => overlayTextColor || theme.colors.white};
  z-index: 1;
  text-align: center;
  justify-self: center;
  align-self: center;
  margin-bottom: ${({ isProductType }) => (isProductType ? '85px' : '0')};
  max-width: 350px;
  overflow-wrap: anywhere;
  text-shadow: ${({ theme }) => theme.colors.black} 1px 0 10px;
  cursor: pointer;
`;

const BottomButtonsContainerMobile = css`
  border-radius: 0;
`;

const BottomButtonsContainer = styled.div`
  flex-wrap: ${({ isDefaultResponseButtonsEnabled }) =>
    isDefaultResponseButtonsEnabled ? 'unset' : 'wrap'};
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: ${({ width }) => `${width}px`};
  height: unset;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    ${BottomButtonsContainerMobile};
  }

  @media (${({ theme }) => theme.breakpoints.laptopMax}) {
    max-width: ${({ width, isMobilePreview, verticalOrientation }) =>
      isMobilePreview || verticalOrientation ? '' : `${width * SCREEN_LAPTOP_INCH_RATIO}px`};
  }
`;

const ProductsContainerMobile = css`
  display: flex;
  flex-direction: column-reverse;
  max-width: 318px;
`;

const ProductsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-row: 1;
  grid-column: 1;
  justify-content: end;
  align-items: end;
  overflow-y: hidden;
  width: 100%;
  max-height: 100%;
  z-index: ${({ isPanelExpanded }) => (isPanelExpanded ? 5 : 2)};
  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.getHexOpacity(theme.colors.black, 0)} 55%,
    ${({ theme }) => theme.getHexOpacity(theme.colors.black, 45)} 100%
  );

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    ${ProductsContainerMobile};
  }
`;

const ProductsSectionContainerWrapperMobile = css`
  padding-left: 0;
  padding-top: 16px;
`;

const ProductsSectionContainerWrapper = styled.div`
  display: flex;
  overflow: hidden;
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    ${ProductsSectionContainerWrapperMobile};
  }
`;

const StyledSideBarButtons = styled(SideBarButtons)`
  grid-row: 1;
  grid-column: 1;
  justify-self: end;
  align-self: start;
  padding: 8px;
  z-index: 2;
  margin-top: 40px;
`;

const ProductsMainContainerMobile = css`
  grid-template-columns: auto;
  grid-template-rows: 1fr auto;
  position: relative;
  max-height: 100%;
`;

const ProductsMainContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  left: 0;
  height: 100%;
  max-height: 100%;
  grid-row: 1;
  grid-column: 1;
  align-items: end;
  ${({ isVertical }) => isVertical && ProductsMainContainerMobile};

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    ${ProductsMainContainerMobile};
  }
`;

export default VideoOverlay;
