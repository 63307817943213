import React, { FunctionComponent, useEffect, useState } from 'react';
import { useLookAndFeelContext } from '../../../../LookAndFeelContext';
import EditorMainContainer from '../../shared/EditorMainContainer';
import TextInput from '../../shared/ui-library/TextInput';
import ColorInput from '../../shared/ui-library/ColorInput';

const initialState = {
  logoUrl: '',
  backgroundColor: '',
};

const Branding: FunctionComponent = () => {
  const [state, setState] = useState(initialState);

  const {
    customizationSettings: {
      videoCollectionCampaignSettings: {
        header: headerSettings, // `video-recorder` header settings
      },
    },
    updateCustomizationSettings,
  } = useLookAndFeelContext();

  useEffect(() => {
    if (headerSettings) {
      setState(prev => ({ ...prev, ...headerSettings }));
    }
  }, [headerSettings]);

  const updateProperty = (key: string, value: string): void => {
    setState(prev => ({ ...prev, [key]: value ?? '' }));
    updateCustomizationSettings({
      videoCollectionCampaignSettings: {
        header: {
          [key]: value,
        },
      },
    });
  };

  return (
    <>
      <EditorMainContainer shouldHide={false}>
        <TextInput
          label="Logo url"
          value={state.logoUrl}
          placeholder="Logo url"
          onChange={value => {
            updateProperty('logoUrl', value);
          }}
        />
        <ColorInput
          label="Background"
          color={state.backgroundColor}
          onChange={value => {
            updateProperty('backgroundColor', value);
          }}
        />
      </EditorMainContainer>
    </>
  );
};

export default Branding;
