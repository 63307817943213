import React from 'react';
import styled from 'styled-components';
import AnalyticsOwnerFilter from './AnalyticsOwnerFilter';
import AnalyticsDateFilter from './AnalyticsDateFilter';
import AnalyticsUniqueFilter from 'app/src/complex_components/analytics/filters/AnalyticsUniqueFilter';
import AnalyticsInteractionFilter from 'app/src/complex_components/analytics/filters/AnalyticsInteractionFilter';
import { AnalyticsTypeFilter } from './AnalyticsTypeFilter';
import {
  Gap16HorizontalFlex,
  HorizontalFlexCentered,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { useApps } from 'app/src/context/AppsStore';
import AnalyticsChannelFilter from './AnalyticsChannelFilter';
import AnalyticsProjectFilter from './AnalyticsProjectFilter';

const AnalyticsFilters = ({
  showFilter,
  isEngagement,
  isAnalyticsOverviewEnabled,
  selectedTab,
  setSelectedTab,
}) => {
  const [{ shopify: isShopify }] = useApps();

  if (!showFilter) {
    return null;
  }

  return (
    <StyledAnalyticsFilters>
      <AnalyticsFiltersColumn>
        <AnalyticsTypeFilter
          isAnalyticsOverviewEnabled={isAnalyticsOverviewEnabled}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </AnalyticsFiltersColumn>
      <AnalyticsFiltersColumn>
        <AnalyticsUniqueFilter showFilter={isEngagement && showFilter} />
        <AnalyticsChannelFilter isAnalyticsOverviewEnabled={isAnalyticsOverviewEnabled} />
        {!isShopify && <AnalyticsOwnerFilter />}
        <AnalyticsProjectFilter />
        {!isEngagement && <AnalyticsInteractionFilter />}
        <AnalyticsDateFilter />
      </AnalyticsFiltersColumn>
    </StyledAnalyticsFilters>
  );
};

export default AnalyticsFilters;

const AnalyticsFiltersColumn = styled(HorizontalFlexCentered)`
  gap: 40px;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
  }
`;

const StyledAnalyticsFilters = styled(Gap16HorizontalFlex)`
  justify-content: space-between;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    flex-direction: column;
  }
`;
