import { PLAYER_EVENT_NAMES, PLAYER_EVENTS } from 'player-events';
import { REACT_APP_API_BASE_URL } from 'player/src/config/player.config';
import HttpService from 'shared/react/services/httpService';
import { v4 as uuidv4 } from 'uuid';
import { FEATURE_WIDGET_EVENTS } from '../constants/playerFeatures.constants';
import Utils from '../utils';
import { initFacebookAnalytics, sendFacebookAnalyticsEvent } from './FbEvents';
import { initGoogleAnalytics, sendGoogleAnalyticsEvent } from './GAEvents';
import { sendWithInitialDelay } from './EventDelay';

const idleEvents = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart', 'keydown'];
const IGNORED_EMBED_EVENTS = [PLAYER_EVENTS.pageView, 'embedView'];
let analyticsData = { sessionId: Utils.getWidgetSessionId() || uuidv4() };
const intervalLimit = 7200;
const maximumIdleTimeInSeconds = 60;
const secondInMilliseconds = 1000;
let totalTime = 0;
let lastSessionTotalTime = 0;
let videoTime = 0;
let interval;
let idleInterval;
let responseTime = 0;
let isIdle = false;
let noEventTime = 0;
let featureSettings = {};
let pageViewSent = false;
let sessionStartSent = false;

const getShouldSendPageView = ({ playerType } = {}) => {
  if (Utils.getIsEmbed(playerType) || Utils.getIsLandingPage()) {
    return true;
  }

  if (!featureSettings[FEATURE_WIDGET_EVENTS]) {
    return true;
  }

  if (pageViewSent) {
    return false;
  }

  return !Utils.getWidgetSessionId();
};

window.addEventListener('message', event => {
  if (!event.data) {
    return;
  }

  if (event.data === 'tolstoyModalClose' && analyticsData.parentUrl) {
    resetSession();
  }

  if (event.data.name === 'setTolstoyData') {
    addAnalyticsData({ parentUrl: event.data.parentUrl });
  }

  if (event.data.name === 'tolstoyWidgetShown' && getShouldSendPageView()) {
    handleEvent(PLAYER_EVENTS.pageView, analyticsData);
  }

  if (event.data.name === 'embedView') {
    handleEmbedView(formatWidgetEventData(event));
  }

  if (event.data.playerVariant === 'inTileCarousel') {
    handleInTileCarouselEvent(formatWidgetEventData(event));
  }
});

const getShouldIgnoreEvent = eventName => {
  return IGNORED_EMBED_EVENTS.includes(eventName);
};

const formatWidgetEventData = event => {
  const { name: eventName, ...data } = event.data;

  return { eventName, data };
};

const handleInTileCarouselEvent = ({ eventName, data = {} } = {}) => {
  if (getShouldIgnoreEvent(eventName)) {
    return;
  }

  trackSessionStart(data);
  track(eventName, data);
};

const handleEmbedView = ({ data = {} } = {}) => {
  trackEmbedView(data);
};

const trackEmbedView = (params = {}) => {
  track('embedView', params);
};

const trackSessionStart = (params = {}) => {
  if (sessionStartSent) {
    return;
  }

  sessionStartSent = true;
  track(PLAYER_EVENTS.sessionStart, params);
};

const resetSession = (stopIntervals = true) => {
  analyticsData.sessionId = uuidv4();
  lastSessionTotalTime = totalTime;
  totalTime = 0;
  videoTime = 0;
  responseTime = 0;
  isIdle = false;
  noEventTime = 0;
  sessionStartSent = false;

  if (stopIntervals) {
    clearInterval(interval);
    clearListeners();
    interval = null;
  }
};

const addAnalyticsData = params => {
  analyticsData = { ...analyticsData, ...params };
};

export const getAnonymousId = () => {
  const widgetAnonymousId = Utils.getWidgetAnonymousId();
  if (widgetAnonymousId) {
    return widgetAnonymousId;
  }

  try {
    const cookieValue = document.cookie.split('; ').find(c => c.startsWith('tolstoy-anonymousId'));
    if (cookieValue) {
      const [, anonymousId] = cookieValue.split('=');
      return anonymousId;
    }
    return window.localStorage.getItem('tolstoy-anonymousId');
  } catch (e) {
    console.log('Failed to read from the local storage.');
    return null;
  }
};

const setAnonymousId = anonymousId => {
  if (!anonymousId || anonymousId === 'undefined') {
    return;
  }
  try {
    // 1 year
    const maxAge = 60 * 60 * 24 * 365;

    window.localStorage.setItem('tolstoy-anonymousId', anonymousId);
    document.cookie = `tolstoy-anonymousId=${anonymousId}; samesite=none; secure=true; domain=gotolstoy.com; path=/; max-age=${maxAge};`;
  } catch (e) {
    console.log('Failed to read from the local storage.');
  }
};

const setPublishId = publishId => {
  if (!publishId) {
    return;
  }
  const prevValue = Utils.getCookieValue('tolstoy-viewed');
  if (prevValue.includes(publishId)) {
    return;
  }
  const values = prevValue.split(',');
  let value;
  if (values.length === 10) {
    values.pop();
    values.unshift(publishId);
    value = values.join(',');
  } else {
    value = `${publishId}${prevValue && `,${prevValue}`}`;
  }
  document.cookie = `tolstoy-viewed=${value}; samesite=none; secure=true; Domain=gotolstoy.com`;
};

const initAnalytics = (params, delayPageView, customParams = {}, features = {}) => {
  window.anonymousId = params.anonymousId || getAnonymousId();
  const { facebookAnalyticsID, preview } = params;
  console.log('init analytics');
  const data = { ...params };
  if (Object.keys(customParams).length) {
    data.customParams = JSON.stringify(customParams);
  }

  featureSettings = features;

  addAnalyticsData(data);

  if (!window.anonymousId || ['undefined', 'false'].includes(window.anonymousId)) {
    window.anonymousId = uuidv4();
  }

  setAnonymousId(window.anonymousId);

  handlePageView({ preview, facebookAnalyticsID, analyticsData, delayPageView });
};

const handlePageView = ({ preview, facebookAnalyticsID, analyticsData, delayPageView }) => {
  if (pageViewSent || delayPageView) {
    return;
  }

  if (!preview) {
    initGoogleAnalytics(analyticsData.googleAnalyticsID);
    initFacebookAnalytics(facebookAnalyticsID);
  }

  if (getShouldSendPageView(analyticsData)) {
    handleEvent(PLAYER_EVENTS.pageView, analyticsData);
    postMessageEvents(PLAYER_EVENTS.pageView, analyticsData);
  }

  pageViewSent = true;
};

const track = async (name, params) => {
  if (PLAYER_EVENTS.sessionStart === name && !interval && !analyticsData.preview) {
    interval = window.setInterval(countTime, 1000);
  }

  if (name === PLAYER_EVENTS.videoPause) {
    clearListeners();
  } else if (
    [PLAYER_EVENTS.sessionStart, PLAYER_EVENTS.videoStart, PLAYER_EVENTS.videoResume].includes(name)
  ) {
    initListeners();
  }
  const eventData = { ...analyticsData, ...params, responseTime: videoTime };

  if (eventData.answerType) {
    clearListeners();
    responseTime = 0;
  }

  if (!eventData.answerType && responseTime && Utils.isResponse(name)) {
    initListeners();
    eventData.responseTime = responseTime;
  }

  postMessageEvents(name, eventData);
  console.log('track', name, eventData);
  await handleEvent(name, eventData);
};

const postMessageEvents = (name, eventData) => {
  const eventName = PLAYER_EVENT_NAMES[name] || PLAYER_EVENTS[name] || name;
  const extraParams = {};
  if (!eventName) {
    return;
  }
  if (name === PLAYER_EVENTS.sessionStart) {
    setPublishId(eventData.publishId);
  }
  window.parent.postMessage(
    {
      ...eventData,
      anonymousId: window.anonymousId,
      name: eventName,
      totalTime: totalTime || lastSessionTotalTime,
      ...extraParams,
    },
    '*'
  );

  if (!PLAYER_EVENT_NAMES[name]) {
    return;
  }

  const event = new CustomEvent('tolstoyPlayerEvent', {
    detail: { ...eventData, anonymousId: window.anonymousId, name: eventName },
  });
  window.dispatchEvent(event);
};

const handleEvent = async (name, eventData) => {
  await sendHttpEvent(name, eventData);
};

const sendHttpEvent = async (eventName, params) => {
  const eventParams = {
    timestamp: new Date().toISOString(),
    anonymousId: window.anonymousId,
    eventName,
    ...params,
  };

  await handleSend(`${REACT_APP_API_BASE_URL}/events/event`, eventParams);
  console.log('SENT', { anonymousId: window.anonymousId, eventName, ...params });
};

const getIsAppContainerVisible = () => {
  const root = document.querySelector('#root');
  return !!root?.firstChild?.offsetWidth;
};

const countTime = () => {
  const isPageVisible = getIsAppContainerVisible();

  if ((document.hasFocus() || isPageVisible) && !document.hidden && !isIdle) {
    totalTime += 1;
    videoTime += 1;
    responseTime += 1;
  }

  if (totalTime >= intervalLimit) {
    clearInterval(interval);
    interval = null;
  }
};

const sendViaHttp = (url, data) => {
  return HttpService.post(url, {}, data);
};

const safeSendBeacon = (url, data) => {
  try {
    const hasQueuedRequest = window.navigator.sendBeacon(url, data);

    if (!hasQueuedRequest) {
      return sendViaHttp(url, data);
    }
  } catch (error) {
    Utils.logError(error);
    return sendViaHttp(url, data);
  }
};

const sendEvent = sendWithInitialDelay((url, data) => {
  // Sometimes sendBeacon isn't defined, ? after navigator is just a safe check
  if (window.navigator?.sendBeacon) {
    return safeSendBeacon(url, data);
  }

  return sendViaHttp(url, data);
});

const handleSend = async (url, params) => {
  if (analyticsData.preview || Utils.getIsAppPreview()) {
    return;
  }
  await sendEvent(url, JSON.stringify(params));
  sendGoogleAnalyticsEvent(params);
  sendFacebookAnalyticsEvent(params);
};

const startIdleInterval = () => {
  if (idleInterval) return;

  idleInterval = setInterval(() => {
    noEventTime += 1;

    if (maximumIdleTimeInSeconds === noEventTime) {
      onIdleUser();
    }
  }, secondInMilliseconds);
};

const onIdleUser = () => {
  isIdle = true;
  clearInterval(idleInterval);
  noEventTime = 0;
  idleInterval = null;
};

const resetNoEventTime = () => {
  noEventTime = 0;
  isIdle = false;
  startIdleInterval();
};

const clearListeners = () => {
  clearInterval(idleInterval);
  idleInterval = null;
  isIdle = false;
  idleEvents.forEach(name => {
    document.removeEventListener(name, resetNoEventTime, true);
  });
};

const initListeners = () => {
  if (idleInterval) {
    return;
  }
  startIdleInterval();
  idleEvents.forEach(name => {
    document.addEventListener(name, resetNoEventTime, true);
  });
};

const getSessionId = () => {
  return analyticsData.sessionId;
};

export {
  addAnalyticsData,
  formatWidgetEventData,
  getSessionId,
  initAnalytics,
  resetSession,
  track,
  trackEmbedView,
  trackSessionStart,
};
