import React, { useRef } from 'react';
import { Route, Switch } from 'react-router-dom';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { useOnboarding } from 'src/context/ui_store/OnboardingStore';
import Routes from 'src/helpers/Routes';
import styled from 'styled-components';
import OnboardingFooter from './OnboardingFooter';
import OnboardingTemplateContent from './templates/OnboardingTemplateContent';
import OnboardingTemplatePicker from './templates/OnboardingTemplatePicker';

const Onboarding = () => {
  const [{ activeTemplateKey }] = useOnboarding();
  const containerRef = useRef(null);

  return (
    <LayoutRoot isActiveTemplate={!!activeTemplateKey}>
      <Content ref={containerRef}>
        <Switch>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <Route path={Routes.getOnboardingTemplatesRoute()} exact>
            <OnboardingTemplatePicker />
          </Route>
          <Route path={Routes.getOnboardingTemplatesStageRoute()}>
            <OnboardingTemplateContent containerRef={containerRef} />
          </Route>
        </Switch>
      </Content>
      <OnboardingFooter />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)<{ isActiveTemplate: boolean }>`
  height: 100%;
  overflow: hidden;
  width: 100%;
  justify-content: ${({ isActiveTemplate }) => (isActiveTemplate ? 'space-between' : 'center')}};
`;

const Content = styled.div`
  overflow: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  background: ${({ theme }) => theme.colors.neutralLightest};

  & > * {
    padding: 40px 40px 0 40px;
  }
`;

export default Onboarding;
