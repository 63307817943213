import React from 'react';
import ChartTooltip from 'app/src/complex_components/analytics/chart-tooltip/ChartTooltip';
import { ANALYTICS_OVERVIEW_TOTAL_REVENUE } from 'app/src/constants/analytics.constants';
import { getShortMonthAndDate } from 'app/src/utils/date.utils';
import { TooltipPayload } from 'app/src/complex_components/analytics/chart-tooltip/ChartTooltip.types';
import { useTheme } from 'styled-components';

type AnalyticsOverviewRevenueTooltipProps = {
  active: boolean;
  payload: [TooltipPayload<string>, TooltipPayload<string>];
  label: string;
};

const AnalyticsOverviewRevenueTooltip = ({
  active,
  payload,
  label,
}: AnalyticsOverviewRevenueTooltipProps) => {
  const theme = useTheme();
  if (!active || !payload?.length) {
    return null;
  }

  const [directRevenueData, indirectRevenueData] = payload;
  const { payload: directRevenue } = directRevenueData;
  const { payload: indirectRevenue } = indirectRevenueData;

  const lineGroups = [
    {
      key: ANALYTICS_OVERVIEW_TOTAL_REVENUE.DIRECT,
      header: ANALYTICS_OVERVIEW_TOTAL_REVENUE.DIRECT,
      bulletColor: theme.colors.successDark,
      value: directRevenue.directRevenueWithCurrency,
      lines: [
        {
          header: ANALYTICS_OVERVIEW_TOTAL_REVENUE.IN_VIDEO,
          value: directRevenue.inVideoRevenueWithCurrency,
        },
        {
          header: ANALYTICS_OVERVIEW_TOTAL_REVENUE.POST_VIDEO,
          value: directRevenue.postVideoRevenueWithCurrency,
        },
      ],
    },
    {
      key: ANALYTICS_OVERVIEW_TOTAL_REVENUE.INDIRECT,
      header: ANALYTICS_OVERVIEW_TOTAL_REVENUE.INDIRECT,
      bulletColor: theme.colors.success,
      value: indirectRevenue.indirectRevenueWithCurrency,
    },
  ];

  return <ChartTooltip label={getShortMonthAndDate(label)} lineGroups={lineGroups} />;
};

export default AnalyticsOverviewRevenueTooltip;
