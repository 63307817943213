import GhostImage from 'app/src/images/ghosts.png';
import React from 'react';
import ButtonTypes from 'shared/react/theme/Types';
import { PrimaryButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { DangerButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { TextH3Bold } from 'shared/react/components/basic/text/TextV2';
import { TextSmall } from 'shared/react/components/complex/Text';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import styled from 'styled-components';

type Props = {
  onClick: () => void;
  buttonContent: React.ReactNode;
  header: string;
  subheader: string;
  buttonType?: (typeof ButtonTypes)[keyof typeof ButtonTypes];
  textContainerWidth?: string;
};

const GhostsEmptyState = ({
  onClick,
  buttonContent,
  header,
  subheader,
  textContainerWidth,
  buttonType,
}: Props) => {
  const renderButton = () => {
    if (buttonType === ButtonTypes.Danger) {
      return <StyledDangerButton onClick={onClick}>{buttonContent}</StyledDangerButton>;
    }
    return <Button onClick={onClick}>{buttonContent}</Button>;
  };

  return (
    <LayoutRoot>
      <Content>
        <ImageContainer>
          <Image src={GhostImage} />
        </ImageContainer>
        <TextContainer textContainerWidth={textContainerWidth}>
          <Header>{header}</Header>
          <SubHeader>{subheader}</SubHeader>
        </TextContainer>
        <ButtonContainer>{renderButton()}</ButtonContainer>
      </Content>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  padding: 16px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
`;

const ImageContainer = styled(HorizontalFlexCentered)`
  position: relative;
`;

const Image = styled.img`
  height: 190px;
  position: absolute;
  width: fit-content;
  top: 0;
  background: radial-gradient(50.37% 50.37% at 50% 50%, #c0c9db 0%, rgba(217, 217, 217, 0) 100%);
`;

const Content = styled(VerticalFlex)`
  height: 312px;
  padding: 24px 40px 16px 40px;
  border-radius: 4px;
  align-items: center;
  gap: 24px;
`;

const TextContainer = styled(Gap8VerticalFlex)<{ textContainerWidth: string }>`
  width: ${({ textContainerWidth }) => (textContainerWidth ? textContainerWidth : '')};
  align-items: center;
  margin-top: 150px;
`;

const Header = styled(TextH3Bold)`
  color: ${({ theme }) => theme.colors.neutralDarker};
`;

const SubHeader = styled(TextSmall)`
  text-align: center;
  color: ${({ theme }) => theme.colors.neutralDarker};
`;

const ButtonContainer = styled.div``;

const Button = styled(PrimaryButton)`
  & img {
    height: 18px;
    width: 18px;
  }
`;
const StyledDangerButton = styled(DangerButton)`
  & img {
    height: 18px;
    width: 18px;
  }
`;
export default GhostsEmptyState;
