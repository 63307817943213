const staticCss = {
  addStyleToTextElement: (textElement, theme) => {
    textElement.style.display = "inline-block";
    textElement.style.padding = "0 5px";
    textElement.style.color = theme.text.color;
    textElement.style.fontSize = theme.text.size;
    textElement.style.textShadow = theme.text.shadow;
  },
  addCaptionsCss: (element) => {
    element.style.position = "absolute";
    element.style.width = "100%";
    element.style.height = "100%";
    element.style.display = "flex";
    element.style.justifyContent = "center";
    element.style.alignItems = "center";
    element.style.top = "0";
    element.style.pointerEvents = "none";
  },
  addToCaptionsContainerCss: (element) => {
    element.style.textAlign = "center";
  },
};

const animatedCss = {
  addAnimation: (textElement, videoElement, theme) => {
    const animation = theme.animation;
    const timing = {
      duration: 4000,
      iterations: Infinity,
      fill: "forwards",
    };

    const currentAnimation = textElement.animate(animation, timing);

    const startAnimation = () => {
      currentAnimation.play();
    };

    const stopAnimation = () => {
      currentAnimation.pause();
    };

    videoElement.addEventListener("pause", stopAnimation);
    videoElement.addEventListener("play", startAnimation);
  },
  addStyleToTextElement: (textElement, index, color, theme) => {
    textElement.style.display = "inline-block";
    textElement.style.padding = "0 5px";
    textElement.style.color = theme.text.color;
    textElement.style.fontSize = theme.text.size;

    const isFirstWord = index === 0;
    if (isFirstWord) {
      textElement.style.color = color;
    }

    textElement.style.textShadow = theme.text.shadow;
  },
  addCaptionsCss: (element) => {
    element.style.position = "absolute";
    element.style.width = "100%";
    element.style.height = "100%";
    element.style.display = "flex";
    element.style.justifyContent = "center";
    element.style.alignItems = "center";
    element.style.top = "0";
    element.style.pointerEvents = "none";
  },
  addToCaptionsContainerCss: (element) => {
    element.style.maxWidth = "300px";
    element.style.textAlign = "center";
  },
};

export { staticCss, animatedCss };
