import React from 'react';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';
import {
  CardCreatedAt,
  CardDetailsText,
  CardName,
} from 'app/src/pages/dashboard/components/card/Card';
import { getTimeSince } from 'app/src/utils/date.utils';
import styled from 'styled-components';
import HorizontalFlex, {
  Gap8HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import {
  GOOGLE_DRIVE,
  INSTAGRAM,
  INSTAGRAM_GRAPH,
  TIKTOK,
} from 'app/src/constants/intergrations.constants';
import Utils from 'app/src/utils';
import useIntegrationsVideos from 'app/src/hooks/useIntegrationsVideos';
import { useTikTok } from 'app/src/context/TikTokStore';
import { useParams } from 'react-router-dom';
import TiktokSmallIcon from 'app/src/images/integrations/TiktokSmallIcon';
import InstagramSmallIcon from 'app/src/images/integrations/InstagramSmallIcon';
import { useAccount } from 'app/src/context/AccountStore';
import { VideosPageVodAsset } from 'app/src/pages/dashboard/pages/videos/types/videos.types';
import GoogleDriveIcon from 'app/src/images/integrations/GoogleDriveIcon';
import { AI_VIDEO } from 'app/src/constants/creation_method.constants';
import Tooltip from 'app/src/basic_components/tooltip-v2/Tooltip';
import { getProfileLink, ensureAtSign } from 'shared/react/utils/social';
import AiVideoTag from './AiVideoTag';

const ICONS = {
  [TIKTOK]: <TiktokSmallIcon />,
  [INSTAGRAM]: <InstagramSmallIcon />,
  [INSTAGRAM_GRAPH]: <InstagramSmallIcon />,
  [GOOGLE_DRIVE]: <GoogleDriveIcon />,
};

type Props = {
  video: VideosPageVodAsset;
};

const VideoDetails = ({ video }: Props) => {
  const { instagramApps, tiktokApps, googleDriveProfile, getInstagramUsername, getTiktokUsername } =
    useIntegrationsVideos();
  const [{ firstUserInfo }] = useTikTok();
  const [{ teamMembers }] = useAccount();
  const { source } = useParams();
  const { appExternalId } = video;

  const getOwnerTeamMember = () => {
    return teamMembers.find(({ userId }) => userId === video.owner) || teamMembers[0];
  };

  const getTeamMemberDetails = () => {
    switch (source) {
      case INSTAGRAM_GRAPH:
        if (!instagramApps.length) {
          break;
        }

        return {
          userName: `@${getInstagramUsername({ appExternalId })}`,
        };
      case TIKTOK:
        if (!tiktokApps.length && !firstUserInfo?.userName) {
          break;
        }

        return {
          userName: `@${getTiktokUsername({ appExternalId }) || firstUserInfo?.userName}`,
        };
      case GOOGLE_DRIVE:
        if (!googleDriveProfile) {
          break;
        }

        return {
          userName: `@${googleDriveProfile?.email.split('@')[0]}`,
        };
    }

    const ownerTeamMember = getOwnerTeamMember();
    if (!ownerTeamMember) {
      return {};
    }

    const {
      user: { firstName, lastName, email },
    } = ownerTeamMember;

    return {
      userName: Utils.getUserName(firstName, lastName, email),
    };
  };

  const { userName } = getTeamMemberDetails();

  const currentIcon = ICONS[source];
  const isAiVideo = video?.uploadType?.includes(AI_VIDEO);
  const { handle } = JSON.parse(video?.externalProviderData || '{}');

  const handleSection = handle ? (
    <EllipsisTextOverflowWrapper>
      <CardDetailsText className="notranslate">
        by{' '}
        <a
          target="_blank"
          className="handleLink"
          rel="noopener noreferrer"
          href={getProfileLink(handle)}
        >
          {ensureAtSign(handle)}
        </a>
      </CardDetailsText>
    </EllipsisTextOverflowWrapper>
  ) : null;

  return (
    <>
      <TopSectionContainer>
        <Tooltip className="notranslate" text={video.name}>
          {handleSection}
          <EllipsisTextOverflowWrapper>
            <CardName className="notranslate">{video.name}</CardName>
          </EllipsisTextOverflowWrapper>
        </Tooltip>
        <AiVideoTag isHidden={!isAiVideo} />
      </TopSectionContainer>
      <DetailsContainer>
        {currentIcon && <IconContainer>{currentIcon}</IconContainer>}
        <EllipsisTextOverflowWrapper>
          <CardDetailsText className="notranslate">{userName}</CardDetailsText>
        </EllipsisTextOverflowWrapper>
        <EllipsisTextOverflowWrapper>
          <CardCreatedAt>{getTimeSince(video.createdAt)}</CardCreatedAt>
        </EllipsisTextOverflowWrapper>
      </DetailsContainer>
    </>
  );
};

const DetailsContainer = styled(Gap8HorizontalFlex)`
  align-items: center;
`;

const TopSectionContainer = styled(Gap8HorizontalFlex)`
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  max-width: 100%;

  & svg {
    height: 20px;
    min-width: 44px;
  }
`;

const IconContainer = styled(HorizontalFlex)`
  svg {
    height: 12px;
    width: 12px;
  }

  & path {
    fill: ${({ theme }) => theme.colors.black};
  }
`;

export default VideoDetails;
