import React, { useState } from 'react';
import styled from 'styled-components';
import AnswerContainer from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/AnswerContainer';
import { TextBody, TextSmall } from 'shared/react/components/complex/Text';
import GoTo from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/GoTo';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';
import TrashButton from 'app/src/basic_components/TrashButton';
import { TRASH_BUTTON_DATA_TYPE } from 'app/src/constants/ui.constants';
import ProductActionMenu from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/products/ProductActionMenu';
import ImageWithFallback from 'shared/react/images/ImageWithFallback';

const Product = ({
  product,
  index,
  stepsOrder,
  handleRemoveProduct,
  onCollectViewerInfo,
  isFeed,
  productCtaType = null,
  setProductCtaType,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleGoToClick = e => {
    if (!isFeed) {
      return;
    }

    setAnchorEl(e.currentTarget);
  };

  return (
    <LayoutRoot {...props}>
      <IfContainer>
        <StyledImageWithFallback src={product.imageUrl} />
        <ProductDetails>
          <EllipsisTextOverflowWrapper>
            <TextContainer>{product.title}</TextContainer>
          </EllipsisTextOverflowWrapper>
          <Price>
            {!!product.price && (product.currencySymbol || '$')}
            {product.price}
          </Price>
        </ProductDetails>
      </IfContainer>
      <EndContainer>
        <CustomGoTo
          onCollectViewerInfo={onCollectViewerInfo}
          isProductType={true}
          stepsOrder={stepsOrder}
          index={index}
          background="transparent"
          productCtaType={productCtaType}
          onChooseAnswerNext={handleGoToClick}
          isFeed={isFeed}
          {...product}
        />
        <TrashButton onClick={() => handleRemoveProduct(product)} />
      </EndContainer>
      <ProductActionMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        isFeed={isFeed}
        setProductCtaType={setProductCtaType}
      />
    </LayoutRoot>
  );
};

const IfContainer = styled(Gap8HorizontalFlexWrap)`
  align-items: center;
  flex-wrap: nowrap;
`;

const ProductDetails = styled(Gap8VerticalFlex)``;

const TextContainer = styled(TextBody)``;

const Price = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.gray2};
`;

const EndContainer = styled(HorizontalFlexWrap)`
  flex-wrap: nowrap;
  align-items: center;
`;

const LayoutRoot = styled(AnswerContainer)`
  position: relative;
  grid-template-columns: 1fr auto auto;

  &:hover {
    background: ${({ theme }) => theme.colors.gray1};

    & ${`[data-type="${TRASH_BUTTON_DATA_TYPE}"]`} {
      opacity: 1;
    }
  }

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    grid-template-columns: 1fr auto;
  }

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    position: relative;
  }
`;

const CustomGoTo = styled(GoTo)`
  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    max-width: 100%;
    padding: 6px 6px 6px 8px;
  }
`;

const StyledImageWithFallback = styled(ImageWithFallback)`
  height: 56px;
  width: 56px;
`;

export default Product;
