import React from 'react';
import { useApps } from 'app/src/context/AppsStore';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import {
  PUBLISH_METHODS_ITEMS,
  TOLSTOY_TYPE_ITEMS,
} from 'app/src/hooks/usePublishMethods/usePublishMethods.constants';
import { ONSITE_TARGET_PAGES } from 'app/src/pages/dashboard/constants/onsiteTargetPages.constants';
import OnSiteImages from 'app/src/basic_components/OnSiteImages';

export const usePublishMethods = () => {
  const [apps] = useApps();
  const { getFeatureEnabled } = useFeatureActions();
  const publishMethodItems = PUBLISH_METHODS_ITEMS.filter(
    item => !item.isHidden?.({ apps, getFeatureEnabled })
  );

  const availablePages = [
    ONSITE_TARGET_PAGES.HomePages,
    ONSITE_TARGET_PAGES.ProductPages,
    ONSITE_TARGET_PAGES.Collections,
    ONSITE_TARGET_PAGES.OtherPages,
  ];

  const pages = availablePages.map(route => {
    return { route, icon: <OnSiteImages route={route} /> };
  });

  return {
    publishMethodItems,
    tolstoyTypeItems: TOLSTOY_TYPE_ITEMS,
    pages,
  };
};
