import Types from '../../theme/Types';
import Theme from '../../theme/Theme';
import ColorModes from '../../theme/ColorModes';
import Sizes from '../../theme/Sizes';
import { getBaseText } from './Text';
import { useTheme } from 'styled-components';

function ButtonText({ children, type, colorMode, disabled, size, ...props }) {
  const globalTheme = useTheme();

  function getTheme() {
    const themeType = type || Types.Primary;

    return Theme[themeType];
  }

  function getColorModeGroup(theme) {
    if (disabled) {
      return theme.main;
    }
    switch (colorMode) {
      default:
      case ColorModes.Main:
        return theme.main;
      case ColorModes.Dark:
        return theme.dark;
      case ColorModes.Light:
        return theme.light;
    }
  }

  function getTextColor() {
    const theme = getTheme();
    if (!theme) {
      return globalTheme.colors.white;
    }

    return getColorModeGroup(theme).text;
  }

  function getTextSettings() {
    switch (size) {
      default:
      case Sizes.Regular:
        return {
          fontSize: '14px',
          lineHeight: '18px',
          fontWeight: '600',
        };
      case Sizes.Large:
        return {
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: 'normal',
        };
      case Sizes.ExtraLarge:
        return {
          fontSize: '18px',
          lineHeight: '26px',
          fontWeight: '600',
          letterSpacing: '0.0015em',
        };
    }
  }

  let textSettings = getTextSettings();
  return getBaseText(
    children,
    textSettings.fontSize,
    textSettings.lineHeight,
    textSettings.fontWeight,
    textSettings.letterSpacing,
    getTextColor()?.normal,
    getTextColor()?.hover,
    getTextColor()?.active,
    null,
    { ...props }
  );
}

export default ButtonText;
