import React from 'react';
import styled from 'styled-components';
import {
  PRODUCT_DETAILS_PRODUCT_RECOMMENDATIONS_ENABLED_PROPERTY_KEY,
  PRODUCT_DETAILS_PRODUCT_RECOMMENDATIONS_NAME_ENABLED_PROPERTY_KEY,
  PRODUCT_DETAILS_PRODUCT_RECOMMENDATIONS_PRICE_ENABLED_PROPERTY_KEY,
  PRODUCT_DETAILS_PRODUCT_RECOMMENDATIONS_TITLE_PROPERTY_KEY,
} from '../../../LookAndFeelPage.constants';
import { useLookAndFeelContext } from '../../../LookAndFeelContext';
import EditorHeader from '../shared/EditorHeader';
import OnOffToggleItem from '../shared/OnOffToggleItem';
import EditorMainContainer from '../shared/EditorMainContainer';
import EditorTextInputItem from '../shared/EditorTextInputItem';
import EditorOnOffToggleItem from '../shared/EditorOnOffToggleItem';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const ProductDetailsRecommendedProductsEditor = () => {
  const {
    customizationSettings: {
      playerSettings: {
        productDetailsRecommendedProductsEnabled,
        productDetailsRecommendedProductsTitle,
        productDetailsRecommendedProductsNameEnabled,
        productDetailsRecommendedProductsPriceEnabled,
      },
    },
    setPlayerSettingsProperty,
  } = useLookAndFeelContext();

  const onEnableRecommendedProducts = () => {
    setPlayerSettingsProperty(
      PRODUCT_DETAILS_PRODUCT_RECOMMENDATIONS_ENABLED_PROPERTY_KEY,
      !productDetailsRecommendedProductsEnabled
    );
  };

  const onTextChange = value => {
    setPlayerSettingsProperty(PRODUCT_DETAILS_PRODUCT_RECOMMENDATIONS_TITLE_PROPERTY_KEY, value);
  };

  const onEnableName = () => {
    setPlayerSettingsProperty(
      PRODUCT_DETAILS_PRODUCT_RECOMMENDATIONS_NAME_ENABLED_PROPERTY_KEY,
      !productDetailsRecommendedProductsNameEnabled
    );
  };

  const onEnablePrice = () => {
    setPlayerSettingsProperty(
      PRODUCT_DETAILS_PRODUCT_RECOMMENDATIONS_PRICE_ENABLED_PROPERTY_KEY,
      !productDetailsRecommendedProductsPriceEnabled
    );
  };

  return (
    <LayoutRoot>
      <EditorHeader
        title="Recommended products"
        isWhite={!productDetailsRecommendedProductsEnabled}
      >
        <OnOffToggleItem
          checked={productDetailsRecommendedProductsEnabled}
          onChange={onEnableRecommendedProducts}
        />
      </EditorHeader>
      <EditorMainContainer shouldHide={!productDetailsRecommendedProductsEnabled}>
        <EditorTextInputItem
          placeholder="Handpicked for you"
          text="Section title"
          value={productDetailsRecommendedProductsTitle}
          onChange={onTextChange}
        />
        <EditorOnOffToggleItem
          text="Product name"
          checked={productDetailsRecommendedProductsNameEnabled}
          onChange={onEnableName}
        />
        <EditorOnOffToggleItem
          text="Price"
          checked={productDetailsRecommendedProductsPriceEnabled}
          onChange={onEnablePrice}
        />
      </EditorMainContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

export default ProductDetailsRecommendedProductsEditor;
