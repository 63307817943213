import React from 'react';
import { ChevronsUpDown, Check } from 'lucide-react';
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'ui-library/core';

interface SortField {
  value: string;
  label: string;
}

type SortProps = {
  selectedFieldValue: string;
  fields: SortField[];
  onSelect: (fieldValue: string) => void;
};

export const UgcSort = ({ selectedFieldValue, fields, onSelect }: SortProps) => {
  const field = fields.find(field => field.value === selectedFieldValue);
  return (
    <div className="flex items-center space-x-2">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" size="sm">
            {field.label || 'Sort by'}
            <ChevronsUpDown className="ml-2 h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-[120px]">
          {fields.map(field => (
            <DropdownMenuItem
              key={field.value}
              className="flex items-center justify-between"
              onSelect={() => onSelect(field.value)}
            >
              {field.label}
              {field.value === selectedFieldValue && <Check className="h-4 w-4" />}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default UgcSort;
