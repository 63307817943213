import React from 'react';

const EndNextStepIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 15.5V1c0-.531-.469-1-1-1-.563 0-1 .469-1 1v14.5c0 .281.219.5.5.5h1c.25 0 .5-.219.5-.5ZM14.875 0c-.219 0-.406.063-.625.156-1.406.656-2.469.875-3.344.875-1.875 0-2.969-1-5.125-1C5 .031 4.094.156 3 .5v10.969c1-.313 1.844-.438 2.625-.438 2.313 0 3.906.969 6.219.969 1 0 2.125-.188 3.5-.719.406-.156.656-.5.656-.875V.97c0-.595-.531-.97-1.125-.97Z"
        fill="#7D8087"
      />
    </svg>
  );
};

export default EndNextStepIcon;
