import React, { useCallback, useEffect, useState } from 'react';
import { PRODUCTS_KEY, STEP_KEY } from 'app/src/constants/tolstoy.constants';
import { useQuizV2 } from 'app/src/context/QuizV2Store';
import { useParams } from 'react-router-dom';
import { useProjects } from 'app/src/context/ProjectsStore';
import { v4 as uuidv4 } from 'uuid';
import useIsMounted from 'app/src/hooks/useIsMounted';
import { QuizProvider } from 'app/src/pages/project/pages/quiz/QuizContext';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';
import QuizPageV1 from 'app/src/pages/project/pages/quiz/quiz_v1/QuizPage';
import QuizResultsLayout from 'app/src/pages/project/pages/quiz/QuizResultsLayout';
import Utils from 'app/src/utils';
import useUpdateDirtyForm from 'app/src/hooks/useUpdateDirtyForm';

function QuizPage() {
  const [, { fetchQuizResults, deleteQuizResult, publishQuiz, updateQuizResults }] = useQuizV2();
  const { projectId } = useParams();
  const [, { setSnackbar, setErrorSnackbar }] = useSnackBar();
  const [{ project }, { isContactFormActivated }] = useProjects({
    projectId,
  });
  const [deletingQuizResultIds, setDeletingQuizResultIds] = useState([]);
  const [quizResults, setQuizResults] = useState([]);
  const [isUnsaved, setUnsaved] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [isCancelling, setCancelling] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [errors, setErrors] = useState([]);
  const isErrorIncluded = errors.some(error => error);
  const isMounted = useIsMounted();

  const fetchQuizResultItems = async () => {
    const quizResults = await fetchQuizResults(projectId);
    setIsFetching(false);

    if (!isMounted()) {
      return;
    }

    setQuizResults(quizResults);
  };

  useEffect(() => {
    if (!projectId) {
      return;
    }

    fetchQuizResultItems();
  }, [projectId]);

  const isQuizResultFallback = quizResultId => {
    const result = quizResults.find(quizResult => quizResult.id === quizResultId);
    return !!result?.isFallback;
  };

  const handleDeleteQuizResult = async quizResultId => {
    setDeletingQuizResultIds([...deletingQuizResultIds, quizResultId]);
    if (!(await deleteQuizResult(quizResultId))) {
      setErrorSnackbar('Failed to delete result.');
      return;
    }

    setSnackbar('Result deleted successfully');

    const newQuizResults = [...quizResults.filter(quizResult => quizResult.id !== quizResultId)];
    if (newQuizResults.length && isQuizResultFallback(quizResultId)) {
      newQuizResults[0].isFallback = true;
    }
    setQuizResults(newQuizResults);
    setDeletingQuizResultIds(previousdeletingQuizResultIds => [
      ...previousdeletingQuizResultIds.filter(id => id !== quizResultId),
    ]);

    if (isUnsaved) {
      setUnsaved(false);
      await saveQuizResults(newQuizResults);
    } else {
      await publishQuiz(project.publishId);
    }
  };

  const handleDeleteFlow = (quizResultId, flowId) => {
    const newQuizResults = [...quizResults];
    const quizResult = newQuizResults.find(quizResult => quizResult.id === quizResultId);
    quizResult.flows = quizResult.flows.filter(({ id }) => id !== flowId);

    setQuizResults(newQuizResults);
    setUnsaved(true);
  };

  const handleSetFallback = quizResultId => {
    const newQuizResults = [...quizResults];

    for (const newQuizResult of newQuizResults) {
      newQuizResult.isFallback = newQuizResult.id === quizResultId;
    }

    setQuizResults(newQuizResults);
    setUnsaved(true);
  };

  const handleSetKlaviyoListId = (quizResultId, klaviyoListId) => {
    const newQuizResults = [...quizResults];

    for (const newQuizResult of newQuizResults) {
      if (newQuizResult.id === quizResultId) {
        newQuizResult.klaviyoListId = klaviyoListId;
        break;
      }
    }

    if (!isContactFormActivated(project)) {
      setSnackbar(
        "You can't add subscribers to Klaviyo without enabling the contact form, which you can find in the Design tab",
        'warning'
      );
    }

    setQuizResults(newQuizResults);
    setUnsaved(true);
  };

  const handleSetProductResult = (quizResultId, stepName) => {
    const newQuizResults = [...quizResults];

    let index = 0;
    for (const newQuizResult of newQuizResults) {
      if (newQuizResult.id === quizResultId) {
        let newErrors = [...errors];
        newErrors[index] = null;
        setErrors(newErrors);
        newQuizResult.type = STEP_KEY;
        newQuizResult.stepName = stepName;
        break;
      }
      index++;
    }

    setQuizResults(newQuizResults);
    setUnsaved(true);
  };

  const handleSetResultType = (quizResultId, type) => {
    const newQuizResults = [...quizResults];

    if (type === PRODUCTS_KEY) {
      type = STEP_KEY;
    }

    let index = 0;
    for (const newQuizResult of newQuizResults) {
      if (newQuizResult.id === quizResultId) {
        let newErrors = [...errors];
        newErrors[index] = null;
        setErrors(newErrors);
        newQuizResult.type = type;
        break;
      }
      index++;
    }

    setQuizResults(newQuizResults);
    setUnsaved(true);
  };

  const handleSetResultUrl = (index, url) => {
    const newQuizResults = [...quizResults];

    newQuizResults[index].type = 'link';
    newQuizResults[index].url = url;
    if (errors?.[index]) {
      const newErrors = [...errors];
      newErrors[index] = null;
      setErrors(newErrors);
    }

    setQuizResults(newQuizResults);
    setUnsaved(true);
  };

  function getDefaultAndFlow() {
    return {
      id: uuidv4(),
      type: 'and',
      rules: [],
    };
  }

  const handleCreateFlowClick = id => {
    const newQuizResults = [...quizResults];
    const quizResult = newQuizResults.find(quizResult => quizResult.id === id);
    quizResult.flows.push(getDefaultAndFlow());

    setQuizResults(newQuizResults);
    setUnsaved(true);
  };

  const handleReset = async () => {
    setCancelling(true);
    const quizResults = await fetchQuizResults(project?.id);
    setQuizResults([...quizResults]);
    setCancelling(false);
    setUnsaved(false);
  };

  const saveQuizResults = async quizResults => {
    try {
      const promises = updateQuizResults(quizResults);
      await Promise.all(promises);
    } catch (e) {
      console.error(e);
      setErrorSnackbar('Error saving the quiz');
      return;
    }

    await publishQuiz(project.publishId);
    return true;
  };

  const checkForErrors = quizResults => {
    const errors = new Array(quizResults.length);
    let error = false;
    quizResults.forEach(({ type, url, stepName }, i) => {
      if (type === STEP_KEY) {
        if (!stepName) {
          errors[i] = "Step can't be empty";
          error = true;
        }

        return;
      }

      if (Utils.isValidUrl(url)) {
        return;
      }

      errors[i] = 'Invalid Url';
      error = true;
    });

    setErrors(errors);
    return error;
  };

  const handleSaveClick = async e => {
    e?.stopPropagation();
    setSaving(true);

    if (checkForErrors(quizResults)) {
      setSaving(false);
      return;
    }

    const res = await saveQuizResults(quizResults);

    if (res) {
      setSnackbar('Changes have been saved');
      setUnsaved(false);
    }

    setSaving(false);
  };

  const getProviderValue = () => {
    return {
      onDeleteQuizResult: handleDeleteQuizResult,
      onCreateFlowClick: handleCreateFlowClick,
      onDeleteFlow: handleDeleteFlow,
      onSetFallback: handleSetFallback,
      onSetKlaviyoListId: handleSetKlaviyoListId,
      onSetProductResult: handleSetProductResult,
      onSetResultType: handleSetResultType,
      onSetResultUrl: handleSetResultUrl,
      saveQuizResults,
      project,
      quizResults,
      setQuizResults,
      isUnsaved,
      setUnsaved,
      errors,
      deletingQuizResultIds,
      handleSaveClick,
    };
  };

  const onSaveClickCallback = useCallback(handleSaveClick, [quizResults, project]);

  useUpdateDirtyForm(isUnsaved, {
    onSaveClick: onSaveClickCallback,
    onDiscardClick: handleReset,
    isCancelling,
    isLoading: isSaving,
    isSaveDisabled: isErrorIncluded,
  });

  if (!project?.tolstoyType.includes('_v2')) {
    return <QuizPageV1 />;
  }

  return (
    <QuizProvider value={getProviderValue()}>
      <QuizResultsLayout isLoading={isFetching} quizResults={quizResults} />
    </QuizProvider>
  );
}

export default QuizPage;
