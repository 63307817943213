import styled from 'styled-components';

const HorizontalFlex = styled.div`
  display: flex;
`;

export const Gap8HorizontalFlex = styled(HorizontalFlex)`
  gap: 8px;
`;

export const Gap16HorizontalFlex = styled(HorizontalFlex)`
  gap: 16px;
`;

export const HorizontalFlexCentered = styled(HorizontalFlex)`
  justify-content: center;
  align-items: center;
`;

export default HorizontalFlex;
