import React, { useState } from 'react';
import FeedCtaButton from 'shared/react/components/complex/feed/feed_products/feed_cta_button/FeedCtaButton';
import FeedBuyNowButton from 'shared/react/components/complex/feed/feed_products/feed_buy_now_button/FeedBuyNowButton';
import styled from 'styled-components';
import FeedMultipleProductsButtonCss from 'shared/react/components/complex/feed/feed_products/feed_multiple_products/FeedMultipleProductsButtonCss';
import { FEED_QUICK_ADD_BUTTON_DATA_TYPE } from 'shared/react/constants/feedPreviewDataTypes.constants';
import useFeedFeatures from 'shared/react/components/complex/context/hooks/useFeedFeatures';
import { FEATURE_FEED_HIDE_BUY_NOW_BUTTON } from 'shared/react/constants/features.constants';
import { BUY_NOW_DATA_TEST_ID } from '../../../../../../constants/dataTestIds.constants';
import { DEFAULT_QUANTITY } from 'shared/react/constants/feed.constants';
import useIsFenty, { useIsQuickShop } from 'shared/react/hooks/useIsFenty';
import useIsPrincessPolly from 'shared/react/hooks/useIsPrincessPolly';

const FeedQuickAddButton = ({
  appUrl,
  currentStepKey,
  selectedVariant,
  productHandle,
  ecomPlatform,
  onBuyNowClick,
  productId,
  buttonConfig,
  isDynamic,
  handleProductFocused,
  isMultiVariant,
  externalProductId,
  ctaSettings = {},
  primaryHasQuantityPicker,
  hasVariantOptions,
  product,
  isMultipleProductDetails,
  design,
}) => {
  const [, { getFeatureSettingsByKey }] = useFeedFeatures();
  const [quantity, setQuantity] = useState(DEFAULT_QUANTITY);
  const isQuickShopEnabled = useIsQuickShop(product);
  const isFenty = useIsFenty();
  const isPP = useIsPrincessPolly();
  const { shouldHide: isFeedBuyNowButtonHidden } = getFeatureSettingsByKey(
    FEATURE_FEED_HIDE_BUY_NOW_BUTTON
  );

  if (isFeedBuyNowButtonHidden) {
    return null;
  }

  const { id: selectedVariantId, isVariantAvailableForSale } = selectedVariant || {};
  const quantityPickerState = { quantity, setQuantity };

  if (isDynamic && buttonConfig) {
    return (
      <StyledFeedCtaButton
        selectedVariantId={selectedVariantId}
        selectedVariant={selectedVariant}
        appUrl={appUrl}
        currentStepKey={currentStepKey}
        isVariantAvailableForSale={isVariantAvailableForSale}
        data-type={FEED_QUICK_ADD_BUTTON_DATA_TYPE}
        data-test-id={BUY_NOW_DATA_TEST_ID}
        productHandle={productHandle}
        ecomPlatform={ecomPlatform}
        onBuyNowClick={onBuyNowClick}
        productId={productId}
        buttonConfig={buttonConfig}
        handleProductFocused={handleProductFocused}
        isMultiVariant={isMultiVariant}
        viewProductCtaSettings={ctaSettings}
        externalProductId={externalProductId}
        $primaryHasQuantityPicker={primaryHasQuantityPicker}
        isViewProduct={!!hasVariantOptions}
        quantityPickerState={quantityPickerState}
        isQuickShopEnabled={isQuickShopEnabled}
        isMultipleProductDetails={isMultipleProductDetails}
        $isFenty={isFenty}
        $isPP={isPP}
        $design={design}
      />
    );
  }

  return (
    <StyledFeedBuyNowButton
      selectedVariantId={selectedVariantId}
      isVariantAvailableForSale={isVariantAvailableForSale}
      selectedVariant={selectedVariant}
      appUrl={appUrl}
      currentStepKey={currentStepKey}
      isDisabled={!isVariantAvailableForSale}
      isQuickAdd
      $primaryHasQuantityPicker={primaryHasQuantityPicker}
      data-type={FEED_QUICK_ADD_BUTTON_DATA_TYPE}
      data-test-id={BUY_NOW_DATA_TEST_ID}
      productHandle={productHandle}
      ecomPlatform={ecomPlatform}
      onBuyNowClick={onBuyNowClick}
      productId={productId}
      isViewProduct={!!hasVariantOptions}
      handleProductFocused={handleProductFocused}
      quantityPickerState={quantityPickerState}
      isQuickShopEnabled={isQuickShopEnabled}
      $isFenty={isFenty}
      $isPP={isPP}
      $design={design}
      isMultipleProductDetails={isMultipleProductDetails}
    />
  );
};

const StyledFeedBuyNowButton = styled(FeedBuyNowButton)`
  ${FeedMultipleProductsButtonCss};
`;

const StyledFeedCtaButton = styled(FeedCtaButton)`
  ${FeedMultipleProductsButtonCss};
`;

export default FeedQuickAddButton;
