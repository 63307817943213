import { css } from 'styled-components';

export const DASHED_INPUT_HEIGHT = 28;

export const DashedInputCss = css`
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.black};
  border: 1px solid transparent;
  background: transparent;
  padding-left: 5px;
  padding-right: 5px;
  height: ${DASHED_INPUT_HEIGHT}px;
  width: 100%;

  &:hover {
    background: ${({ theme }) => theme.colors.gray5};
  }

  &:focus,
  &:active {
    background: ${({ theme }) => theme.colors.white};
  }

  &:hover,
  &:focus,
  &:active,
  &:disabled {
    opacity: 1;
    outline: 0;
    border: 1px dashed ${({ theme }) => theme.colors.primaryDark};
  }

  &:disabled {
    padding-right: 32px;
    background: ${({ theme }) => theme.colors.gray5};
    opacity: 0.4;
  }

  ::placeholder {
    font-size: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.ghostDark};
  }
`;
