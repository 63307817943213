import useFeedFeatures from 'shared/react/components/complex/context/hooks/useFeedFeatures';
import useFeedModal from 'shared/react/components/complex/context/hooks/useFeedModal';
import useFeedProducts from 'shared/react/components/complex/context/hooks/useFeedProducts';
import useFeedState from 'shared/react/components/complex/context/hooks/useFeedState';
import useTracker from 'shared/react/components/complex/context/hooks/useTracker';
import { FEATURE_DESCRIPTION_CLOSE_FEED_PDP } from 'shared/react/constants/features.constants';
import {
  PRODUCT_CARD_CLICK_MESSAGE,
  SHOW_FEED_PRODUCT_MODAL_MESSAGE,
  TOLSTOY_MOVE_TO_URL,
} from 'shared/react/constants/messages.constants';
import { PRODUCT_MODAL_KEY } from 'shared/react/constants/modals.constants';
import Utils from 'shared/react/utils/utils';
import useEcomNavigation from '../../feed/hooks/useEcomNavigation';
import { OPEN_KENDO_MODAL_MESSAGE } from '../../../../constants/messages.constants';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';

const useOpenProductModal = ({
  step,
  currentStepKey,
  isMobilePreview,
  verticalOrientation,
  landingPage,
}) => {
  const [, { track }] = useTracker();
  const [, { pushModal }] = useFeedModal();
  const [, { getProductNames }] = useFeedProducts();
  const [{ publishId }] = useProjectConfig();
  const [{ isProductModalDisabled, isTapcart }] = useFeedState();
  const [, { getFeatureEnabled, getFeatureSettingsByKey }] = useFeedFeatures();
  const { getPDPUrl } = useEcomNavigation();
  // FEATURE_DESCRIPTION_CLOSE_FEED_PDP should be threated as 'should open pdp on feed product click'
  const shouldOpenPdpOnFeedProductClick = getFeatureEnabled(FEATURE_DESCRIPTION_CLOSE_FEED_PDP);

  const openPdpPage = pdpUrl => {
    Utils.postMessageToWindow({ name: TOLSTOY_MOVE_TO_URL, url: pdpUrl });
  };

  const pushProductModal = ({ customProps, onModalClose, product }) => {
    requestAnimationFrame(() => {
      pushModal({
        key: PRODUCT_MODAL_KEY,
        props: {
          onClose: () => onModalClose?.(),
          currentStepKey: currentStepKey || (step.key ?? step.name),
          verticalOrientation,
          landingPage,
          product,
          ...customProps,
        },
      });
    });
  };

  const openPdpOnFeedProductClick = ({ product, variantId }) => {
    let getPDPUrlParams = {
      product,
      variantId,
    };

    const { autoOpenTolstoy } = getFeatureSettingsByKey(FEATURE_DESCRIPTION_CLOSE_FEED_PDP) || {};

    if (autoOpenTolstoy) {
      getPDPUrlParams = { ...getPDPUrlParams, publishId, videoId: step?.videoId };
    }

    const pdpUrl = getPDPUrl(getPDPUrlParams);

    return openPdpPage(pdpUrl);
  };

  const handleOpenProductModal = (
    event,
    {
      product,
      externalProductIds,
      variantId,
      customProps,
      isQuickShopEnabled,
      onModalOpen,
      onModalClose,
      isMultipleProducts,
    }
  ) => {
    event.preventDefault();
    event.stopPropagation();

    if (isMobilePreview) {
      return;
    }

    if (isQuickShopEnabled && !isMultipleProducts) {
      Utils.postMessageToWindow({
        eventName: OPEN_KENDO_MODAL_MESSAGE,
        productHandle: product?.handle,
      });
      return;
    }

    const productNames = getProductNames(step);
    // this event should be treated as feedProductCardClick
    track('feedProductModalOpen', { productNames });

    if (!isTapcart && shouldOpenPdpOnFeedProductClick) {
      return openPdpOnFeedProductClick({ product, variantId });
    }

    onModalOpen?.();

    Utils.postMessageToWindow({
      eventName: PRODUCT_CARD_CLICK_MESSAGE,
      variantId,
      productId: product.id,
      productHandle: product.handle,
      externalProductIds,
    });

    if (isProductModalDisabled) {
      return;
    }

    window.parent.postMessage({ name: SHOW_FEED_PRODUCT_MODAL_MESSAGE, isOpen: true }, '*');

    pushProductModal({ customProps, onModalClose, product });
  };

  return { handleOpenProductModal };
};

export default useOpenProductModal;
