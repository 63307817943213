import React from 'react';

const CreatedAtIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 1c0-.531.438-1 1-1 .531 0 1 .469 1 1v1h4V1c0-.531.438-1 1-1 .531 0 1 .469 1 1v1h1.5c.813 0 1.5.688 1.5 1.5V5H1V3.5A1.5 1.5 0 0 1 2.5 2H4V1Zm11 5v8.5a1.5 1.5 0 0 1-1.5 1.5h-11A1.48 1.48 0 0 1 1 14.5V6h14Z"
        fill="#B3B6BC"
      />
    </svg>
  );
};

export default CreatedAtIcon;
