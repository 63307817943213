import React from 'react';
import styled from 'styled-components';
import EditorGroup from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorGroup';
import { FONT_KEY, LOGO_KEY } from '../../branding.constants';
import FontEditor from './FontEditor';
import LogoEditor from './LogoEditor';

const GeneralEditorGroup = ({
  settings = {},
  features = {},
  defaultSettings = {},
  onChange = () => {},
}) => {
  const updateSettings = (update, options) => onChange({ ...settings, ...update }, options);
  const onTypographyChange = (settings, options) => updateSettings({ typography: settings }, options);

  return (
    <StyledEditorGroup
      editors={[
        {
          key: LOGO_KEY,
          component: <LogoEditor />,
        },
        {
          key: FONT_KEY,
          component: (
            <FontEditor
              settings={settings.typography}
              features={features}
              defaultSettings={defaultSettings.typography}
              onChange={onTypographyChange}
            />
          ),
        },
      ]}
    />
  );
};

const StyledEditorGroup = styled(EditorGroup)`
  padding: 0;

  & > * {
    padding: 0;
  }
`;

export default GeneralEditorGroup;
