import React, { useState } from 'react';
import styled from 'styled-components';
import BasicInput from 'app/src/basic_components/BasicInput';
import InviteReferralPoster from 'app/src/images/invite_referral.png';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { TextH6, LightTextSmall, LightTextTiny } from 'shared/react/components/complex/Text';
import Button from 'shared/react/components/complex/Button';
import { useReferral } from 'app/src/context/ReferralStore';
import Utils from '../../../utils';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';
import SocialShareButtons from 'shared/react/components/complex/SocialShareButtons';
import TwitterIcon from 'app/src/images/share/TwitterIcon';
import FacebookIcon from 'app/src/images/share/FacebookIcon';
import LinkedInIcon from 'app/src/images/share/LinkedinIcon';
import WhatsappIcon from 'app/src/images/share/WhatsappIcon';
import { track } from 'app/src/helpers/Tracker';

function InviteReferralModalContent() {
  const [{ link }, { createReferralLink }] = useReferral();
  const [, { setSnackbar, setErrorSnackbar }] = useSnackBar();
  const [isLoading, setLoading] = useState(false);

  const getUrl = async getSocialShareUrl => {
    let referralLink = link;
    if (!referralLink) {
      referralLink = await createReferralLink();
    }

    return getSocialShareUrl(referralLink);
  };

  const shareButtons = [
    {
      icon: <FacebookIcon />,
      getUrl: () => getUrl(Utils.getFacebookShareUrl),
    },
    {
      icon: <TwitterIcon />,
      getUrl: () => getUrl(Utils.getTwitterShareUrl),
    },
    {
      icon: <LinkedInIcon />,
      getUrl: () => getUrl(Utils.getLinkedInShareUrl),
    },
    {
      icon: <WhatsappIcon />,
      getUrl: () => getUrl(Utils.getWhatsappShareUrl),
    },
  ];

  function copyLink(link) {
    if (!link) {
      return;
    }

    track('Referral Url Copied');
    Utils.copyToClipboard(link);
    setSnackbar('Link copied successfully');
  }

  async function fetchReferralLink() {
    setLoading(true);
    const link = await createReferralLink();
    if (!link) {
      setErrorSnackbar('Failed to create referral link');
    }

    setLoading(false);
    return link;
  }

  const handleCopyLinkClick = async () => {
    let referralLink = link;
    if (!referralLink) {
      referralLink = await fetchReferralLink();
    }

    copyLink(referralLink);
  };

  return (
    <LayoutRoot>
      <Image src={InviteReferralPoster} />
      <ContentContainer>
        <TitleContainer>
          <TextH6>Just share your referral link.</TextH6>
          <LightTextSmall>
            Get 2 months of Tolstoy for free for every friend who signs up to a Pro account and
            completes the trial.
          </LightTextSmall>
        </TitleContainer>
        <LinkContainer>
          <CopyLinkContainer>
            <InputWrapper>
              <Input value={link || 'https://www.gotolstoy.com...'} disabled={isLoading || !link} />
            </InputWrapper>
            <Button loading={isLoading} onClick={handleCopyLinkClick}>
              Copy link
            </Button>
          </CopyLinkContainer>
          <SocialShareButtons shareButtons={shareButtons} withLoading />
        </LinkContainer>
        <LightTextTiny>Not including usage cost, up to one year for free.</LightTextTiny>
      </ContentContainer>
    </LayoutRoot>
  );
}

export default InviteReferralModalContent;

const LayoutRoot = styled(HorizontalFlexWrap)`
  flex-wrap: nowrap;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.primaryLight};
  border-radius: 8px;
  padding: 40px 16px 40px 24px;
  width: 700px;
  height: 400px;
  gap: 40px;
`;

const Image = styled.img`
  width: 290px;
  height: 100%;
`;

const ContentContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  max-width: 311px;
  justify-items: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 16px;
`;

const TitleContainer = styled(Gap8VerticalFlex)`
  justify-items: center;
  align-items: center;
  > * {
    text-align: center;
  }
`;

const LinkContainer = styled.div`
  display: grid;
  justify-items: center;
  gap: 24px;
  width: 100%;
`;

const InputWrapper = styled.div`
  background: white;
  padding: 8px;
  border-radius: 8px;
  height: 100%;
`;

const Input = styled(BasicInput)`
  width: 100%;
`;

const CopyLinkContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 8px;
  align-items: center;
  width: 100%;
`;
