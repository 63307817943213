export const TEXT_FIELDS_CHANGES = ['font', 'fontSize', 'fontColor', 'fontWeight'];
export const CTA_BUTTON_CHANGES = [
  'font',
  'fontSize',
  'fontColor',
  'fontWeight',
  'borderToggle',
  'borderThickness',
  'borderColor',
  'borderRadius',
  'backgroundColor',
];

export const SALE_TEXT_CHANGES = ['fontColor'];
