import React from 'react';
import styled from 'styled-components';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import {
  CAROUSEL_FONT_COLOR_PROPERTY_KEY,
  CAROUSEL_FONT_SIZE_PROPERTY_KEY,
  CAROUSEL_TITLE_FONT_WEIGHT_PROPERTY_KEY,
  CAROUSEL_TITLE_PROPERTY_KEY,
  CAROUSEL_TITLE_TEXT_PROPERTY_KEY,
} from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import EditorColorInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorColorInputItem';
import OnOffToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/OnOffToggleItem';
import EditorTextInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorTextInputItem';
import EditorSelect from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorSelect';
import Utils from 'shared/react/utils/utils';
import EditorSliderItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorSliderItem';
import EditorHeader from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorHeader';
import EditorMainContainer from '../shared/EditorMainContainer';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const fontSizes = Utils.range(12, 50);

const FeedCarouselTitleEditor = ({ disabled }) => {
  const {
    customizationSettings: {
      widgetSettings: {
        carouselFontSize,
        carouselFontColor,
        carouselTitle,
        carouselTitleText,
        carouselTitleFontWeight,
      },
    },
    setWidgetSettingsProperty,
  } = useLookAndFeelContext();

  const onTextSizeChange = value => {
    setWidgetSettingsProperty(CAROUSEL_FONT_SIZE_PROPERTY_KEY, value.id);
  };

  const onColorChange = value => {
    setWidgetSettingsProperty(CAROUSEL_FONT_COLOR_PROPERTY_KEY, value);
  };

  const onEnableTitle = () => {
    setWidgetSettingsProperty(CAROUSEL_TITLE_PROPERTY_KEY, !carouselTitle);
  };

  const onFeedTitleChange = value => {
    setWidgetSettingsProperty(CAROUSEL_TITLE_TEXT_PROPERTY_KEY, value);
  };

  const onFeedFontWeightChange = (e, value) => {
    setWidgetSettingsProperty(CAROUSEL_TITLE_FONT_WEIGHT_PROPERTY_KEY, value);
  };

  return (
    <LayoutRoot>
      <EditorHeader title="Title" disabled={disabled} proFeature isWhite={!carouselTitle}>
        <OnOffToggleItem checked={carouselTitle} onChange={onEnableTitle} disabled={disabled} />
      </EditorHeader>
      <EditorMainContainer shouldHide={!carouselTitle}>
        <EditorTextInputItem
          placeholder="Title goes here"
          text="Text"
          value={carouselTitleText}
          onChange={onFeedTitleChange}
          disabled={disabled}
        />
        <EditorSelect
          onChange={onTextSizeChange}
          text="Text size"
          value={carouselFontSize}
          items={fontSizes}
          disabled={disabled}
        />
        <EditorSliderItem
          onChange={onFeedFontWeightChange}
          text="Text weight"
          value={carouselTitleFontWeight}
          min={100}
          step={100}
          max={900}
          disabled={disabled}
        />
        <EditorColorInputItem
          text="Text color"
          color={carouselFontColor}
          onChange={onColorChange}
          disabled={disabled}
        />
      </EditorMainContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

export default FeedCarouselTitleEditor;
