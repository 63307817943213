import React from 'react';
import IntegrationVideo from 'app/src/pages/creation_flow/right_section/integrations_common/IntegrationVideo';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { CircularProgress } from '@material-ui/core';

const IntegrationsVideosList = ({
  accountVideos,
  integration,
  isLoading,
  isWaitingForResponse,
}) => {
  return (
    <LayoutRoot>
      <ProjectsContainer>
        {accountVideos.map(video => {
          return (
            <IntegrationVideo
              key={video.posterUrl}
              integration={integration}
              isLoading={isLoading}
              {...video}
            />
          );
        })}
      </ProjectsContainer>
      {isWaitingForResponse && <CircularProgress />}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  align-items: center;
  height: 100%;
  gap: 24px;
  padding-bottom: 16px;
  width: 100%;
`;

const ProjectsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 24px;
  width: 100%;
`;

export default IntegrationsVideosList;
