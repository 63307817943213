import React from 'react';
import styled from 'styled-components';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import TolstoysLeftBadge from 'app/src/complex_components/billings/badges/TolstoysLeftBadge';
import ProjectCardsContainer from 'app/src/pages/dashboard/pages/libraries/tolstoys_tab/ProjectCardsContainer';

const TolstoysLibrary = ({
  projects,
  shownCardsNumber,
  shouldShowEmptyStateBody,
  folders,
  folderMapping,
  setSelectedTolstoys,
  selectedTolstoys,
  searchValue,
  disableExtraTolstoyClick,
  onProjectClick,
  isModalMode,
}) => {
  return (
    <LayoutRoot>
      <StyledTolstoysLeftBadge isSidebarOpen />
      <ProjectCardsContainer
        projects={projects}
        searchValue={searchValue}
        shouldShowEmptyStateBody={shouldShowEmptyStateBody}
        shownCardsNumber={shownCardsNumber}
        folders={folders}
        folderMapping={folderMapping}
        setSelectedTolstoys={setSelectedTolstoys}
        selectedTolstoys={selectedTolstoys}
        disableExtraTolstoyClick={disableExtraTolstoyClick}
        onProjectClick={onProjectClick}
        isModalMode={isModalMode}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)`
  padding-bottom: 120px;
`;

const StyledTolstoysLeftBadge = styled(TolstoysLeftBadge)`
  max-width: 280px;
  margin: 0;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    max-width: unset;
    margin: 0 24px;
  }
`;

export default TolstoysLibrary;
