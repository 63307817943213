import React from 'react';
import styled from 'styled-components';
import StandardModalV2 from 'app/src/pages/modals/StandardModalV2';
import Videos from 'app/src/pages/dashboard/pages/videos/Videos';
import useModalVideoFilters from 'app/src/pages/creation_flow/right_section/hooks/useModalVideoFilters';
import { getVideosModalDefaultFilterProps } from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/components/playlists-picker/utils';
import { DynamicRuleTypes } from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/constants/dynamicConditions.constants';
import { YOUTUBE } from 'app/src/constants/intergrations.constants';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { vodAsset } from 'app/src/types/entities';

type Props = {
  closeModal: () => void;
};

type ModalProps = {
  source: string;
  onChoose: (video: vodAsset) => void;
};

const VideoLibraryModal = ({ closeModal }: Props) => {
  const [{ modalProps }] = useModal();
  const { onChoose, source } = modalProps as ModalProps;
  const defaultProps = getVideosModalDefaultFilterProps(DynamicRuleTypes.vodSource, source);
  const { videosFilters, setVideosFilters } = useModalVideoFilters(source ? defaultProps : {});

  return (
    <StyledStandardModal disableEnforceFocus onClose={closeModal} open>
      <Videos
        onVideoChoose={onChoose}
        videosFilters={videosFilters}
        setVideosFilters={setVideosFilters}
        shouldSidebarRedirect={false}
        hideSidebar={true}
      />
    </StyledStandardModal>
  );
};

const StyledStandardModal = styled(StandardModalV2)`
  width: 800px;
  height: 600px;
`;

export default VideoLibraryModal;
