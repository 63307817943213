import { css } from 'styled-components';
import {
  CAROUSEL_ARROW_DATA_TYPE,
  CAROUSEL_DATA_TYPE,
  FEED_BUTTON_DATA_TYPE,
  FEED_CART_BUTTON_DATA_TYPE,
  FEED_CURRENT_STEP_DETAILS_DATA_TYPE,
  FEED_DESCRIPTION_CONTENT_DATA_TYPE,
  FEED_DESCRIPTION_DATA_TYPE,
  FEED_DESCRIPTION_TEXT_DATA_TYPE,
  FEED_DESCRIPTION_TITLE_DATA_TYPE,
  FEED_NAVIGATION_ARROWS_DATA_TYPE,
  FEED_PRODUCT_DATA_TYPE,
  FEED_PRODUCT_OPTIONS_BUY_NOW_DATA_TYPE,
  FEED_SHARE_COPY_LINK_DATA_TYPE,
  FEED_SHARE_LINK_DATA_TYPE,
  FEED_SHARE_SOCIAL_BUTTONS_DATA_TYPE,
  FEED_UP_NEXT_DATA_TYPE,
  MORE_BUTTON_DATA_TYPE,
  PRODUCT_DETAILS_DATA_TYPE,
  PRODUCT_DISCOUNTED_PRICE_DATA_TYPE,
  PRODUCT_HEADER_DATA_TYPE,
  PRODUCT_MODAL_DATA_TYPE,
  PRODUCT_OPTION_HEADER_DATA_TYPE,
  PRODUCT_PRICE_DATA_TYPE,
  PRODUCTS_SECTION_WRAPPER_DATA_TYPE,
  SELECT_INPUT_CONTAINER_DATA_TYPE,
  SMALL_PRODUCT_VIEW_DATA_TYPE,
} from 'shared/react/constants/feedPreviewDataTypes.constants';
import Utils from 'shared/react/utils/utils';
import FeedMultipleProductsPreviewCss from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/feed/feed_preview_css/FeedMultipleProductsPreviewCss';

const FeedProductContentCss = css`
  & ${Utils.getDataType(PRODUCT_HEADER_DATA_TYPE)} {
    font-size: 16px;
    line-height: 24px;
  }

  & ${Utils.getDataType(PRODUCT_PRICE_DATA_TYPE)} {
    font-size: 20px;
    line-height: 26px;
  }

  & ${Utils.getDataType(PRODUCT_DISCOUNTED_PRICE_DATA_TYPE)} {
    font-size: 22px;
    line-height: 26px;
  }

  & ${Utils.getDataType(PRODUCT_DETAILS_DATA_TYPE)} {
    gap: 4px;
  }
`;

const FeedProductCss = css`
  & ${Utils.getDataType(FEED_PRODUCT_DATA_TYPE)} {
    gap: 20px;
  }

  & ${Utils.getDataType(FEED_PRODUCT_OPTIONS_BUY_NOW_DATA_TYPE)} {
    gap: 30px;
  }
`;

const FeedProductOptionSelectCss = css`
  & ${Utils.getDataType(PRODUCT_OPTION_HEADER_DATA_TYPE)} {
    font-size: 12px;
    line-height: 12px;
  }

  & ${Utils.getDataType(SELECT_INPUT_CONTAINER_DATA_TYPE)} {
    padding: 9px 16px;
  }
`;

const FeedButtonCss = css`
  & ${Utils.getDataType(FEED_BUTTON_DATA_TYPE)} {
    padding: 11px 24px;
  }
`;

const CarouselCss = css`
  & ${Utils.getDataType(CAROUSEL_DATA_TYPE)} {
    max-height: 150px;
  }
`;

const CarouselArrowCss = css`
  & ${Utils.getDataType(CAROUSEL_ARROW_DATA_TYPE)} {
    display: none;
  }
`;

const FeedDescriptionCss = css`
  & ${Utils.getDataType(FEED_DESCRIPTION_DATA_TYPE)} {
    gap: 16px;
  }

  & ${Utils.getDataType(FEED_DESCRIPTION_CONTENT_DATA_TYPE)} {
    gap: 8px;
  }

  & ${Utils.getDataType(FEED_DESCRIPTION_TITLE_DATA_TYPE)} {
    font-size: 18px;
    line-height: 28px;
  }

  & ${Utils.getDataType(FEED_DESCRIPTION_TEXT_DATA_TYPE)} {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }
`;

const FeedUpNextCss = css`
  & ${Utils.getDataType(FEED_UP_NEXT_DATA_TYPE)} {
    display: none;
  }
`;

const FeedCurrentStepDetailsCss = css`
  & ${Utils.getDataType(FEED_CURRENT_STEP_DETAILS_DATA_TYPE)} {
    display: none;
  }
`;

const FeedNavigationArrowsCss = css`
  & ${Utils.getDataType(FEED_NAVIGATION_ARROWS_DATA_TYPE)} {
    display: none;
  }
`;

const FeedShareCss = css`
  & ${Utils.getDataType(FEED_SHARE_COPY_LINK_DATA_TYPE)} {
    font-size: 12px;
    line-height: 14px;
  }

  & ${Utils.getDataType(FEED_SHARE_LINK_DATA_TYPE)} {
    font-size: 12px;
    line-height: 14px;
  }

  & ${Utils.getDataType(FEED_SHARE_SOCIAL_BUTTONS_DATA_TYPE)} {
    & svg {
      width: 16px;
      height: 16px;
    }
  }
`;

const FeedCartButtonCss = css`
  & ${Utils.getDataType(FEED_CART_BUTTON_DATA_TYPE)} {
    display: ${({ isShoppableSidePanel, isEcomPlatformConnected }) =>
      !isShoppableSidePanel || !isEcomPlatformConnected ? 'none' : ''};
  }
`;

const ProductModalCss = css`
  & ${Utils.getDataType(PRODUCT_MODAL_DATA_TYPE)} {
    width: ${({ isHorizontal }) => (isHorizontal ? '40%' : '')};
    border-radius: ${({ isHorizontal }) => (isHorizontal ? '8px' : '16px')};
  }
`;

const FeedSmallProductViewCss = css`
  & ${Utils.getDataType(SMALL_PRODUCT_VIEW_DATA_TYPE)} {
    width: ${({ isHorizontal }) => (isHorizontal ? '37.5%' : '')};
  }

  & ${Utils.getDataType(MORE_BUTTON_DATA_TYPE)} {
    min-width: ${({ isHorizontal }) => (isHorizontal ? '85px' : '50px')};
    height: ${({ isHorizontal }) => (isHorizontal ? '32px' : '24px')};
  }
`;

const ProductSectionContainerWrapperCss = css`
  & ${Utils.getDataType(PRODUCTS_SECTION_WRAPPER_DATA_TYPE)} {
    padding: 12px;
    justify-content: flex-end;
  }
`;

export default css`
  ${({ isFeed }) => isFeed && FeedProductContentCss};
  ${FeedProductCss};
  ${FeedProductOptionSelectCss};
  ${FeedButtonCss};
  ${CarouselCss};
  ${CarouselArrowCss};
  ${FeedDescriptionCss};
  ${FeedUpNextCss};
  ${FeedCurrentStepDetailsCss};
  ${FeedNavigationArrowsCss};
  ${FeedShareCss};
  ${FeedCartButtonCss};
  ${({ isFeed }) => !isFeed && FeedSmallProductViewCss};
  ${({ isFeed }) => !isFeed && ProductModalCss};
  ${({ isHorizontal }) => isHorizontal && ProductSectionContainerWrapperCss};
  ${({ isMultipleProducts, isFeed }) =>
    isMultipleProducts && isFeed && FeedMultipleProductsPreviewCss};
`;
