import React from 'react';

const BrandingIcon = ({ fill = '#3D4FE0' }) => (
  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m2.094 6.188 1.562.625.656 1.562c.032.063.094.125.157.125.031 0 0 0 0 0 .093 0 .156-.031.187-.094l.657-1.562 1.562-.657a.172.172 0 0 0 .094-.156c0-.093-.032-.187-.094-.218l-1.563-.625v-.032l-.656-1.531c-.031-.063-.093-.094-.156-.125-.031 0 0 0 0 0a.211.211 0 0 0-.188.125l-.656 1.563-1.562.625C2.03 5.875 2 5.938 2 6c0 .094.031.156.094.188ZM8.469 4l.375.906C8.906 5 8.938 5 8.969 5c0 0 .094 0 .094-.063L9.469 4l.906-.375c.063-.031.094-.063.094-.094 0-.062-.031-.094-.094-.125L9.469 3l-.406-.906C9.063 2.03 9.03 2 8.969 2c-.094 0-.063.031-.094.094L8.469 3l-.906.406c-.063.031-.094.063-.094.094 0 .063.031.094.093.125L8.47 4Zm9.406 9.813-1.563-.626-.656-1.562c-.031-.063-.094-.125-.156-.125a.211.211 0 0 0-.188.125l-.656 1.563-1.562.656A.172.172 0 0 0 13 14c0 .094.031.156.094.188l1.562.656.656 1.562c.032.063.094.094.188.094a.172.172 0 0 0 .156-.094l.656-1.562 1.563-.656a.172.172 0 0 0 .094-.157.284.284 0 0 0-.094-.219ZM17.531 3.5 16.47 2.469A1.383 1.383 0 0 0 15.437 2a1.4 1.4 0 0 0-1.062.469L2.437 14.406a1.453 1.453 0 0 0 0 2.094l1.032 1.063c.312.312.687.437 1.062.437.407 0 .782-.125 1.063-.438L17.53 5.626c.594-.563.594-1.531 0-2.125Zm-13 13L3.5 15.469 10.938 8 12 9.063 4.531 16.5Zm8.5-8.5L11.97 6.969l3.469-3.438L16.5 4.562 13.031 8Z"
      fill={fill}
    />
  </svg>
);

export default BrandingIcon;
