export const PUBLIC_CLASSES = {
  tile: 'tolstoy-tile',
  tileContainer: 'tolstoy-tile-container',
  video: 'tolstoy-tile-video',
  nativeCaptionsTrack: 'tolstoy-tile-native-captions-track',
  customCaptionsTrack: 'tolstoy-tile-custom-captions-track',
  imageOverlay: 'tolstoy-tile-image-overlay',
  image: 'tolstoy-tile-image',
  controlsOverlay: 'tolstoy-tile-controls-overlay',
  controlsContainer: 'tolstoy-tile-controls',
  playButtonOverlay: 'tolstoy-tile-play-button-overlay',
  playButtonContainer: 'tolstoy-tile-play-button-container',
  playButtonLabelContainer: 'tolstoy-tile-play-button-label-container',
  playButtonLabel: 'tolstoy-tile-play-button-label',
  timebarWrapper: 'tolstoy-tile-timebar-wrapper',
  timebarContainer: 'tolstoy-tile-timebar-container',
  timebar: 'tolstoy-tile-timebar',
  timeIndicator: 'tolstoy-tile-time-indicator',
  muteButton: 'tolstoy-tile-mute-button',
  fullScreenButton: 'tolstoy-tile-full-screen-button',
  togglesContainer: 'tolstoy-tile-toggles',
  captionsButton: 'tolstoy-tile-captions-button'
};
