import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { TextBody } from 'shared/react/components/basic/text/TextV2';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

type Props = {
  thumbnail: File | null;
  onThumbnailUpload: (file: File) => void;
};

const UploadImage = ({ thumbnail, onThumbnailUpload }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const imageSrc = thumbnail ? URL.createObjectURL(thumbnail) : null;

  const onAreaClick = () => inputRef.current?.click();

  const onImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (!file) {
      return;
    }

    onThumbnailUpload(file);
  };

  return (
    <LayoutRoot onClick={onAreaClick}>
      {imageSrc ? <ThumbnailImage src={imageSrc} /> : <Placeholder>Choose file</Placeholder>}
      <HiddenInput onChange={onImageChange} type="file" accept="image/*" ref={inputRef} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)`
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px dashed ${({ theme }) => theme.colors.neutralGrey};
`;

const ThumbnailImage = styled.img`
  height: 360px;
  width: 100%;
  border-radius: 8px;
  object-fit: contain;
`;

const Placeholder = styled(TextBody)`
  color: ${({ theme }) => theme.colors.neutralGrey};
`;

const HiddenInput = styled.input`
  display: none;
`;

export default UploadImage;
