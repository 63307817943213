import React from 'react';

const RulesProductPagesIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="10" fill="#FFE0E6" />
      <path
        d="M13 14.5v4.688c0 .53.188 1.03.563 1.406l5.5 5.5a2.018 2.018 0 0 0 2.843 0l4.157-4.157a2.018 2.018 0 0 0 0-2.843l-5.5-5.5a1.959 1.959 0 0 0-1.407-.563H14.5C13.656 13 13 13.687 13 14.5Zm3.5 1c.531 0 1 .469 1 1 0 .563-.469 1-1 1-.563 0-1-.438-1-1 0-.531.438-1 1-1Z"
        fill="#E2506D"
      />
    </svg>
  );
};

export default RulesProductPagesIcon;
