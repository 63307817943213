import React from 'react';
import styled from 'styled-components';
import { BolderTextSmall } from 'shared/react/components/complex/Text';
import QuizRuleXIcon from 'shared/react/images/quiz/QuizRuleXIcon';
import Tooltip from 'app/src/basic_components/Tooltip';

function QuizRuleChip({ children, tooltipText, onClick }) {
  return (
    <Tooltip text={onClick ? tooltipText : ''} placement="top">
      <LayoutRoot isClickable={!!onClick} onClick={onClick}>
        <Text>{children}</Text>
        {onClick && (
          <XIconContainer>
            <QuizRuleXIcon />
          </XIconContainer>
        )}
      </LayoutRoot>
    </Tooltip>
  );
}

export default QuizRuleChip;

const XIconContainer = styled.div`
  align-items: center;
  grid-row: 1;
  grid-column: 1;
  display: none;
  background: ${({ theme }) => theme.colors.gray3};
  height: fit-content;
  z-index: 1;
`;

const Text = styled(BolderTextSmall)`
  grid-row: 1;
  grid-column: 1;
  color: ${({ theme }) => theme.colors.black};
`;

const LayoutRoot = styled.div`
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'normal')};
  display: grid;
  align-items: center;
  justify-items: center;
  background: ${({ theme }) => theme.colors.gray3};
  height: fit-content;
  padding: 4px 10px;
  border-radius: 8px;

  &:hover > ${Text} {
    opacity: ${({ isClickable }) => (isClickable ? 0.2 : 1.0)};
  }

  &:hover > ${XIconContainer} {
    display: grid;
  }
`;
