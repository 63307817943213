import React from 'react';

export const PaddingVerticalIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 14.5c0 .281-.25.5-.5.5H.5a.494.494 0 0 1-.5-.5c0-.25.219-.5.5-.5h15c.25 0 .5.25.5.5ZM4.5 11A1.48 1.48 0 0 1 3 9.5v-3A1.5 1.5 0 0 1 4.5 5h7c.813 0 1.5.688 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-7Zm0-1h7c.25 0 .5-.219.5-.5v-3c0-.25-.25-.5-.5-.5h-7c-.281 0-.5.25-.5.5v3c0 .281.219.5.5.5Zm-4-8a.494.494 0 0 1-.5-.5c0-.25.219-.5.5-.5h15c.25 0 .5.25.5.5 0 .281-.25.5-.5.5H.5Z"
      fill="#fff"
    />
  </svg>
);
