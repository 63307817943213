import { createContainer, createHook, createStore } from 'react-sweet-state';

const initialState = {
  selectedVideosToImport: [],
  disableAddVideosToImport: false,
};

const actions = {
  addSelectedVideoToImport:
    videoUrl =>
    ({ getState, setState }) => {
      const { selectedVideosToImport, disableAddVideosToImport } = getState();
      if (disableAddVideosToImport) {
        return;
      }

      setState({
        selectedVideosToImport: [...selectedVideosToImport, videoUrl],
      });
    },
  removeSelectedVideoToImport:
    ({ id }) =>
    ({ getState, setState }) => {
      const { selectedVideosToImport } = getState();
      setState({
        selectedVideosToImport: selectedVideosToImport.filter(video => video.id !== id),
        disableAddVideosToImport: false,
      });
    },
  onReachStepLimit:
    () =>
    ({ setState }) => {
      setState({ disableAddVideosToImport: true });
    },
  resetSelectedVideosToImport:
    () =>
    ({ setState }) => {
      setState({ selectedVideosToImport: [], disableAddVideosToImport: false });
    },
};

const ImportVideosStore = createStore({ initialState, actions });

export const useImportVideos = createHook(ImportVideosStore);

export const ImportVideosContainer = createContainer(ImportVideosStore);
