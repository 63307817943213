import React, { FunctionComponent, useEffect, useState } from 'react';
import { useLookAndFeelContext } from '../../../../LookAndFeelContext';
import EditorMainContainer from '../../shared/EditorMainContainer';
import TextArea from '../../shared/ui-library/TextArea';

const initialState = {
  offerText: '',
  introText: '',
};

const Offer: FunctionComponent = () => {
  const [state, setState] = useState(initialState);

  const {
    customizationSettings: {
      videoCollectionCampaignSettings: {
        landingPage: landingPageSettings, // `video-recorder` landingPage settings
      },
    },
    updateCustomizationSettings,
  } = useLookAndFeelContext();

  useEffect(() => {
    if (landingPageSettings) {
      setState(prev => ({ ...prev, ...landingPageSettings }));
    }
  }, [landingPageSettings]);

  const updateProperty = (key: string, value: string | string[]): void => {
    setState(prev => ({ ...prev, [key]: value }));
    updateCustomizationSettings({
      videoCollectionCampaignSettings: {
        landingPage: {
          [key]: value,
        },
      },
    });
  };

  return (
    <EditorMainContainer shouldHide={false}>
      <TextArea
        label="Offer text"
        placeholder={initialState.offerText}
        value={state.offerText}
        onChange={value => {
          updateProperty('offerText', value);
        }}
        splitLines={true}
      />
      <TextArea
        label="Intro text"
        placeholder={initialState.introText}
        value={state.introText}
        onChange={value => {
          updateProperty('introText', value);
        }}
        splitLines={true}
      />
    </EditorMainContainer>
  );
};

export default Offer;
