import React, { useState } from 'react';
import styled from 'styled-components';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { project as Project } from 'app/src/types/entities';
import ProjectInstallationSteps from './ProjectInstallationSteps';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { useAppActions } from 'src/context/AppsStore';
import useAccountInstallation from 'src/hooks/useAccountInstallation';
import InstallationTitle from 'app/src/pages/project/pages/installation/components/shared/InstallationTitle';
import { EmbedWidgetsInstall } from 'app/src/types/install';

type Props = {
  project: Project;
};

const ProjectInstallationSection = ({ project }: Props) => {
  const { getAppUsingUrl } = useAppActions();
  const currentApp = getAppUsingUrl(project.appUrl);
  const [{ isInstallationCompleted: isAccountInstallationCompleted }] = useAccountInstallation(
    currentApp?.id
  );
  const [showManualInstallCode, setShowManualInstallCode] = useState(
    project.installStep === EmbedWidgetsInstall.addCode
  );

  const isDisabled = !isAccountInstallationCompleted;

  return (
    <SectionWrapper>
      <InstallationTitle
        setShowManualInstallCode={setShowManualInstallCode}
        showManualInstallCode={showManualInstallCode}
        isAccountInstallationCompleted={isAccountInstallationCompleted}
      />
      <InstallationStepsWrapper>
        <ProjectInstallationSteps
          isDisabled={isDisabled}
          project={project}
          showManualInstallCode={showManualInstallCode}
        />
      </InstallationStepsWrapper>
    </SectionWrapper>
  );
};

const SectionWrapper = styled(Gap8VerticalFlex)``;

const InstallationStepsWrapper = styled(VerticalFlex)`
  gap: 24px;
`;

export default ProjectInstallationSection;
