import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import FeedShare from 'shared/react/components/complex/feed/feed_share/FeedShare';
import { TextBodyLeading } from 'shared/react/components/complex/Text';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import XIcon from 'shared/react/images/XIcon';
import useTracker from 'shared/react/components/complex/context/hooks/useTracker';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import ModalWrapper from 'shared/react/components/basic/ModalWrapper';
import useFeedModal from 'shared/react/components/complex/context/hooks/useFeedModal';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';
import useKeyboardNavigation from 'player/src/hooks/useKeyboardNavigation';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';

const FeedShareModal = ({ currentStep }) => {
  const [, { popModal }] = useFeedModal();
  const [, { track }] = useTracker();
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const { player } = useDesignSettingsContext();
  const { onKeyDown, setNextRef } = useKeyboardNavigation();
  const { headerColorText } = player.feedCards.text;
  const [, { t }] = useTranslation('interactive.landingPage');

  const handleModalClosed = () => {
    track('feedShareModalClose');
    setIsOpen(false);
  };

  return (
    <StyledModalWrapper onClose={popModal} isOpen={isOpen} setIsOpen={setIsOpen}>
      <LayoutRoot>
        <TopContainer>
          <ModalHeader headerColorText={headerColorText}>{t('share')}</ModalHeader>
          <XButton
            onClick={handleModalClosed}
            role="button"
            tabIndex="0"
            aria-label="Share close button"
            ref={setNextRef}
            onKeyDown={onKeyDown}
          >
            <XIcon />
          </XButton>
        </TopContainer>
        <FeedShare
          currentStep={currentStep}
          iconsFillColor={theme.colors.black}
          shouldHideCopyLink={false}
        />
      </LayoutRoot>
    </StyledModalWrapper>
  );
};

const StyledModalWrapper = styled(ModalWrapper)`
  bottom: 0;
  padding: 16px;
  border-radius: 10px 10px 0 0;
  width: 100vw;
  box-sizing: border-box;
`;

const LayoutRoot = styled(VerticalFlex)``;

const ModalHeader = styled(TextBodyLeading)`
  text-align: center;
  font-weight: 700;
  color: ${({ headerColorText, theme }) => headerColorText || theme.colors.black};
`;

const XButton = styled(VerticalFlex)`
  & svg {
    width: 18px;
    height: 18px;
  }
`;

const TopContainer = styled(HorizontalFlex)`
  justify-content: space-between;
`;

export default FeedShareModal;
