import React from 'react';
import { TextBody } from 'shared/react/components/complex/Text';
import styled from 'styled-components';

const DEFAULT_TEXT = 'Need some extra help?';
const DEFAULT_FONT_SIZE = 14;

const ChatWithSupport = ({ children, ...props }) => {
  const onSupportClick = () => {
    window?.Intercom?.('showNewMessage');
  };

  return (
    <StyledChatWithSupport {...props}>
      <TextBody fontSize={DEFAULT_FONT_SIZE}>
        {children || DEFAULT_TEXT}
        <StyledButton onClick={onSupportClick}>
          <ChatWithSupportLink>Chat with support</ChatWithSupportLink>
        </StyledButton>
      </TextBody>
    </StyledChatWithSupport>
  );
};

export default ChatWithSupport;

const StyledChatWithSupport = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled.button`
  border: none;
  background: unset;
  cursor: pointer;
`;

const ChatWithSupportLink = styled.span`
  color: ${({ theme }) => theme.colors.blue1};
  font-weight: bold;
`;
