import React from 'react';
import EmptyStateLogo from 'app/src/pages/account/branding/editors/general/images/emptyLogoImage.png';
import { LOGO_MAX_HEIGHT, LOGO_MAX_WIDTH } from 'app/src/helpers/Image';
import Utils from 'shared/react/utils/utils';
import { MAX_IMAGE_SIZE } from 'app/src/constants/account.constants';
import styled from 'styled-components';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';

type Props = {
  userImageSrc: string;
};

const Logo = ({ userImageSrc }: Props) => {
  if (userImageSrc) {
    return <StyledLogo src={userImageSrc} alt="icon" />;
  }

  return (
    <Container>
      <img src={EmptyStateLogo} alt="img" />
      <SizeText>
        Max {LOGO_MAX_HEIGHT}x{LOGO_MAX_WIDTH}. Max {Utils.formatBytes(MAX_IMAGE_SIZE)}
      </SizeText>
    </Container>
  );
};

const StyledLogo = styled.img`
  max-width: 300px;
  max-height: 300px;
  padding: 16px;
`;

const Container = styled(Gap8VerticalFlex)`
  height: 177px;
  width: 100%;
  padding: 16px 0 8px 0;
  background: ${({ theme }) => theme.colors.neutralLightest};
`;

const SizeText = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralGray};
  text-align: center;
`;

export default Logo;
