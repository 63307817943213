import { useEffect } from 'react';
import { useTopBarContext } from 'app/src/context/TopBarStore';
import { ProjectsMultiSelectBarState } from 'app/src/pages/dashboard/components/top-bar/types/TopBar.types';

const useUpdateProjectsMultiSelectState = (
  projectsMultiSelectState: ProjectsMultiSelectBarState
) => {
  const { setProjectsMultiSelectState } = useTopBarContext();

  useEffect(() => {
    if (!projectsMultiSelectState.selectedIds?.length) {
      setProjectsMultiSelectState(null);
      return;
    }

    setProjectsMultiSelectState(projectsMultiSelectState);
  }, [projectsMultiSelectState.selectedIds?.length]);

  useEffect(() => {
    projectsMultiSelectState.onCancel();
  }, [projectsMultiSelectState.filters]);

  useEffect(() => {
    return () => {
      setProjectsMultiSelectState(null);
    };
  }, []);
};

export default useUpdateProjectsMultiSelectState;
