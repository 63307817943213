import React from 'react';

const DownloadIcon = () => {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.742 9.406c.07.07.164.094.258.094.07 0 .164-.023.234-.094l3.375-3c.164-.117.164-.375.024-.515-.117-.164-.375-.164-.516-.024L6.375 8.305V.875A.403.403 0 0 0 6 .5a.385.385 0 0 0-.375.375v7.43L2.859 5.867c-.14-.14-.398-.14-.515.024-.14.14-.14.398.023.515l3.375 3ZM10.5 8H9.375A.385.385 0 0 0 9 8.375c0 .21.164.375.375.375H10.5c.398 0 .75.352.75.75V11c0 .422-.352.75-.75.75h-9A.74.74 0 0 1 .75 11V9.5c0-.398.328-.75.75-.75h1.125A.385.385 0 0 0 3 8.375.403.403 0 0 0 2.625 8H1.5C.656 8 0 8.68 0 9.5V11c0 .844.656 1.5 1.5 1.5h9c.82 0 1.5-.656 1.5-1.5V9.5c0-.82-.68-1.5-1.5-1.5Zm-.188 2.25a.57.57 0 0 0-.562-.563.555.555 0 0 0-.563.563c0 .328.235.563.563.563a.555.555 0 0 0 .563-.563Z"
        fill="#50545E"
      />
    </svg>
  );
};

export default DownloadIcon;
