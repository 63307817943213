import React from 'react';
import styled from 'styled-components';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

type Props = {
  dataTestId?: string;
  isSelected?: boolean;
  withEditMenu?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
};

export const SidebarBaseItem = ({
  dataTestId,
  isSelected = false,
  onClick = () => {},
  children,
}: Props) => {
  return (
    <StyledSidebarBaseItem data-test-id={dataTestId} onClick={onClick} isSelected={isSelected}>
      {children}
    </StyledSidebarBaseItem>
  );
};

export const StyledSidebarBaseItem = styled(HorizontalFlexCentered)`
  min-height: 36px;
  padding: 8px 0 8px 16px;
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  border-radius: 6px;
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.neutralLightest : 'none'};

  &:hover {
    background-color: ${({ theme, isDisabled }) =>
      !isDisabled ? theme.colors.neutralLightest : 'none'};
  }
`;

export default SidebarBaseItem;
