import React from 'react';
import { CategoryHeader } from 'app/src/layouts/tolstoy_empty_screen/emptyScreenCss';
import TextButton from 'app/src/complex_components/TextButton';
import styled from 'styled-components';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import { TextH4 } from 'shared/react/components/complex/Text';
import Gap16HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap16HorizontalFlexWrap';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import {
  COMPANY_MONTHLY_TRAFFIC_OPTIONS,
  COMPANY_ROLE_OPTIONS,
  COMPANY_SIZE_OPTIONS,
  COMPANY_VERTICAL_OPTIONS,
  ECOMMERCE,
} from 'app/src/constants/accountSettings.constants';
import {
  COMPANY_MONTHLY_TRAFFIC_DATA_TEST_ID,
  COMPANY_ROLE_DATA_TEST_ID,
  COMPANY_SIZE_DATA_TEST_ID,
  COMPANY_VERTICAL_DATA_TEST_ID,
} from 'app/src/constants/dataTestIds.constants';

const OnboardingCompanyQuestions = ({
  otherVerticalValue,
  setOtherVerticalValue,
  otherRoleValue,
  setOtherRoleValue,
  data,
  isShopify,
  onAnswerClick,
}) => {
  const onSetOtherVerticalValue = value => {
    setOtherVerticalValue(value);
    onAnswerClick(value, COMPANY_VERTICAL_DATA_TEST_ID);
  };

  const onSetOtherRoleValue = value => {
    setOtherRoleValue(value);
    onAnswerClick(value, COMPANY_ROLE_DATA_TEST_ID);
  };

  const verticalOptions = COMPANY_VERTICAL_OPTIONS.map(title => {
    if (title === 'Other') {
      return {
        title: 'Other',
        type: 'input',
        value: otherVerticalValue,
        setValue: onSetOtherVerticalValue,
      };
    }

    return { title, value: title };
  });

  const getThirdOption = () => {
    if (data[COMPANY_VERTICAL_DATA_TEST_ID] === ECOMMERCE) {
      const thirdQuestionOptions = COMPANY_MONTHLY_TRAFFIC_OPTIONS.map(title => {
        return { title, value: title };
      });

      return {
        thirdQuestionOptions,
        thirdQuestionValue: data[COMPANY_MONTHLY_TRAFFIC_DATA_TEST_ID],
        thirdQuestionType: COMPANY_MONTHLY_TRAFFIC_DATA_TEST_ID,
        thirdQuestionTitle: 'Monthly traffic',
      };
    }

    const thirdQuestionOptions = COMPANY_ROLE_OPTIONS.map(title => {
      if (title === 'Other') {
        return {
          title: 'Other',
          type: 'input',
          value: otherRoleValue,
          setValue: onSetOtherRoleValue,
        };
      }
      return { title, value: title };
    });

    return {
      thirdQuestionOptions,
      thirdQuestionValue: data[COMPANY_ROLE_DATA_TEST_ID],
      thirdQuestionType: COMPANY_ROLE_DATA_TEST_ID,
      thirdQuestionTitle: 'Role',
    };
  };

  const { thirdQuestionOptions, thirdQuestionValue, thirdQuestionType, thirdQuestionTitle } =
    getThirdOption();

  return (
    <>
      <HeaderContainer>
        <Header>Tell us more about your company to customize your experience.</Header>
      </HeaderContainer>
      {!isShopify && (
        <ChoicesContainer>
          <CategoryHeader>Vertical</CategoryHeader>
          <Choices>
            {verticalOptions.map(({ title, value, type, setValue }) => {
              const isSelected = value === data[COMPANY_VERTICAL_DATA_TEST_ID];
              return (
                <TextButton
                  value={value}
                  onClick={value => onAnswerClick(value, COMPANY_VERTICAL_DATA_TEST_ID)}
                  isSelected={isSelected}
                  key={title}
                  type={type}
                  setValue={setValue}
                  title={title}
                  data-test-id={title}
                />
              );
            })}
          </Choices>
        </ChoicesContainer>
      )}

      <ChoicesContainer>
        <CategoryHeader>Company size</CategoryHeader>
        <Choices>
          {COMPANY_SIZE_OPTIONS.map(title => {
            const isSelected = title === data[COMPANY_SIZE_DATA_TEST_ID];
            return (
              <TextButton
                value={title}
                onClick={value => onAnswerClick(value, COMPANY_SIZE_DATA_TEST_ID)}
                isSelected={isSelected}
                key={title}
                title={title}
                data-test-id={title}
              />
            );
          })}
        </Choices>
      </ChoicesContainer>

      <ChoicesContainer>
        <CategoryHeader>{thirdQuestionTitle}</CategoryHeader>
        <Choices>
          {thirdQuestionOptions.map(({ title, value, type, setValue }) => {
            const isSelected = value && value === thirdQuestionValue;
            return (
              <TextButton
                value={value}
                onClick={value => onAnswerClick(value, thirdQuestionType)}
                isSelected={isSelected}
                key={title}
                type={type}
                setValue={setValue}
                title={title}
                data-test-id={title}
              />
            );
          })}
        </Choices>
      </ChoicesContainer>
    </>
  );
};

const HeaderContainer = styled(Gap16VerticalFlex)`
  max-width: 76ch;
`;

const Header = styled(TextH4)`
  text-align: center;
  font-weight: 700;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    font-size: 24px;
  }
`;

const Choices = styled(Gap16HorizontalFlexWrap)`
  max-width: 530px;
  justify-content: center;
`;

const ChoicesContainer = styled(VerticalFlex)`
  align-items: center;
  gap: 24px;
`;

export default OnboardingCompanyQuestions;
