import React from 'react';

const ArrowRight = ({ fill }) => {
  return (
    <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.25 1.219c-.313-.282-.781-.282-1.063.031a.746.746 0 0 0 .032 1.063l4.156 3.968H.75a.722.722 0 0 0-.75.75c0 .407.313.75.75.75h10.625L7.219 11.72a.746.746 0 0 0-.032 1.062c.282.313.75.313 1.063.031l5.5-5.25a.732.732 0 0 0 .219-.53.753.753 0 0 0-.219-.563l-5.5-5.25Z"
        fill={fill || '#7D8087'}
      />
    </svg>
  );
};

export default ArrowRight;
