import { project as Project, publishMethodOptions } from 'app/src/types/entities';
import { useSnackBarActions } from 'app/src/context/ui_store/SnackBarStore';
import { useUserActions } from 'app/src/context/userStore/UserStore';
import { useProjectActions } from 'app/src/context/ProjectsStore';
import { useApps } from 'app/src/context/AppsStore';
import { track } from 'app/src/helpers/Tracker';

export const useToggleProjectLive = () => {
  const { setSnackbar } = useSnackBarActions();
  const { toggleWidgetIsLive } = useUserActions();
  const { updateProject, publishProject } = useProjectActions();
  const [apps, { toggleHeroProject, getAppUsingUrl, setProjectApp }] = useApps();

  const storeApp = apps.wix || apps.shopify || apps.bigcommerce;
  const source = storeApp?.app;
  const isIntegrationApiSetWidget = source === 'wix' || source === 'bigcommerce';

  const handleIntegrationsResponse = (res, checked, excludeSuccessAlert) => {
    if (res.ok) {
      if (!excludeSuccessAlert) {
        setSnackbar(`Tolstoy Widget ${checked ? 'added to' : 'removed from'} Site!`);
      }
      return;
    }

    const errorMessage = res.error?.response?.data;
    if (errorMessage === 'site does not have a connected domain') {
      setSnackbar('Site does not have a connected domain', 'warning');
      return;
    }

    setSnackbar('Error adding to Site!', 'error');
  };

  const publishAppChanged = async ({ project, app, checked, excludeSuccessAlert }) => {
    if (!isIntegrationApiSetWidget) {
      return;
    }

    const res = await setProjectApp(app, project.id, checked, project.publishId);
    handleIntegrationsResponse(res, checked, excludeSuccessAlert);
  };

  const handleToggleHeroProject = async (project: Project, isLive?: boolean) => {
    if (project.publishMethod !== publishMethodOptions.hero) {
      return;
    }

    try {
      await toggleHeroProject(project.id);
    } catch (err) {
      console.log("Couldn't update project", err);
      await updateProject({ ...project, live: isLive });
    }
  };

  const toggleProjectLive = async (project: Project, isLive?: boolean) => {
    const app = getAppUsingUrl(project?.appUrl);
    const live = isLive === undefined ? !project.live : isLive;

    track('Set Live Click', { status: live });

    await updateProject({ ...project, live });
    await handleToggleHeroProject(project, !live);
    await publishAppChanged({ project, app, checked: live, excludeSuccessAlert: true });
  };

  const toggleLive = async (project: Project, isLive?: boolean) => {
    const isBubble = project.publishMethod === publishMethodOptions.bubble;

    if (isBubble) {
      await toggleWidgetIsLive(project, isLive);
    } else {
      await toggleProjectLive(project, isLive);
    }

    await publishProject(project.id);
  };

  return toggleLive;
};

export default useToggleProjectLive;
