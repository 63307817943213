import React from 'react';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

function AccountNotificationsPage() {
  return <LayoutRoot>Account Notifications</LayoutRoot>;
}

export default AccountNotificationsPage;

const LayoutRoot = styled(VerticalFlex)``;
