import React from 'react';
import styled from 'styled-components';
import { TextSmall } from 'shared/react/components/complex/Text';

const ProductReply = ({ response }) => {
  const values = JSON.parse(response.value);
  return values.map(value => {
    return (
      <ProductResponseContainer key={value.id}>
        <CollectInfoItemKeyContainer>
          <CollectInfoItemKeyText>{value.title}</CollectInfoItemKeyText>
        </CollectInfoItemKeyContainer>
      </ProductResponseContainer>
    );
  });
};

export default ProductReply;

const CollectInfoContainer = styled.div`
  display: grid;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 16px;
`;

const ProductResponseContainer = styled(CollectInfoContainer)`
  padding: 8px;
`;

const CollectInfoItemKeyContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  align-items: center;
`;

const CollectInfoItemKeyText = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.blue2};
`;
