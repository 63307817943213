import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import ComponentWithLoader from 'app/src/complex_components/ComponentWithLoader';
import { URL_KEY } from 'app/src/constants/ruleGroups.constants';
import { useRules } from 'app/src/context/RulesStore';
import EmptyState from '../EmptyState';
import RuleEditUrlItem from './RuleEditUrlItem';

const ReviewRules = ({ project, ruleGroup, setRuleGroup }) => {
  const [{ rules }, { fetchRules }] = useRules();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getRules = async () => {
      if (!ruleGroup) {
        setIsLoading(true);
        await fetchRules(project?.id);
        setIsLoading(false);
      }
    };
    getRules();
  }, []);

  useEffect(() => {
    if (!ruleGroup) {
      setRuleGroup(rules);
    }
  }, [rules]);

  if (!isLoading && !ruleGroup?.rules?.flat().filter(({ type }) => type === URL_KEY).length) {
    return <EmptyState isRules={true} />;
  }

  return (
    <LayoutRoot>
      <ComponentWithLoader isLoading={isLoading}>
        {ruleGroup?.rules?.flat().map(rule => (
          <RuleEditUrlItem key={rule.key} rule={rule} />
        ))}
      </ComponentWithLoader>
    </LayoutRoot>
  );
};

export default ReviewRules;

const LayoutRoot = styled(VerticalFlex)`
  gap: 36px;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    max-width: 280px;
  } ;
`;
