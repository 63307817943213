import { NODE_ENV } from '../config/widget.config.js';
import { getSafeParameter } from './iframe.utils.js';

export const uppercaseFirstChar = text => {
  if (!text?.length) {
    return '';
  }

  return `${text[0]?.toUpperCase()}${text?.slice(1)}`;
};

export const getRandomNumberBetweenTwoIntegers = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const generateUUID = () => {
  const generateRandomHexDigit = c => {
    const r = Math.floor(Math.random() * 16);
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  };

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, generateRandomHexDigit);
};

const getIsDevelopment = () => {
  return NODE_ENV === 'development';
};

const getIsLogEnabled = () => {
  return getIsDevelopment() || getSafeParameter('td') === 'true';
};

export const logDevelopmentMessage = message => {
  if (getIsLogEnabled()) {
    console.log(message);
  }
};

export const logDevelopmentError = message => {
  if (getIsLogEnabled()) {
    console.error(message);
  }
};

export const getIsIOS = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const debounce = (func, delay = 300) => {
  let timer;

  return (...args) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const sleep = async (ms = 0) =>
  new Promise(resolve => {
    setTimeout(resolve, ms);
  });

export const safeRoundNumber = (value, digits = 1) => {
  const number = Number(value);

  if (Number.isNaN(number)) {
    return 0;
  }

  if (Number.isInteger(number)) {
    return number;
  }

  return Number.parseFloat(number.toFixed(digits));
};

export const removeHtmlTags = text => {
  return text.replace(/<[^>]*>?/gm, '');
};

export const isNullOrUndefined = value => {
  return value === null || value === undefined;
};

export const formatTime = time => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};
