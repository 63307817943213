import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import InboxStatus from 'app/src/pages/project/pages/inbox/InboxStatus';
import InboxFilters from 'app/src/pages/project/pages/inbox/InboxFilters';
import InboxConversations from 'app/src/pages/project/pages/inbox/InboxConversations';
import { STATUS_ALL } from 'app/src/constants/responsesInbox.constants';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import InboxConversation from 'app/src/pages/project/pages/inbox/InboxConversation';
import { useInfiniteScroll } from 'app/src/hooks/useInfiniteScroll';
import { useSessions } from 'app/src/context/SessionsStore';
import useQuery from 'app/src/hooks/useQuery';
import { track } from 'app/src/helpers/Tracker';
import { useProjects } from 'app/src/context/ProjectsStore';
import ShareButton from 'app/src/pages/project/pages/inbox/ShareButton';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { FEATURE_CONTACTS_FILTERS } from 'app/src/constants/appFeatures.constants';
import { useResponses } from 'app/src/context/ResponsesStore';
import { useBilling } from 'app/src/hooks/useBillings';
import PricingBanner from 'app/src/complex_components/billings/badges/PricingBanner';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Utils from 'app/src/utils';

const getFilters = (status, filters) =>
  status.id === STATUS_ALL ? [...filters] : [...filters, status.id];

const getIsSessionStillExists = (sessions = [], session) =>
  sessions?.some(({ id }) => id === session.id);

const removeSessionId = (location, history) => {
  const queryParams = new URLSearchParams(location.search);

  if (queryParams.has('sessionId')) {
    queryParams.delete('sessionId');
  }
  const { state = {} } = location;
  delete state.sessionId;
  history.replace({
    search: queryParams.toString(),
    state,
  });
};

const getSessionId = (query, location) => {
  return query?.get('sessionId') || location?.state?.conversationId;
};

const Inbox = ({ closeSidebar }) => {
  const sessionsRef = useRef();
  const [status, setStatus] = useState({ id: STATUS_ALL, name: 'All' });
  const [project, setProject] = useState(null);
  const [email, setEmail] = useState('');
  const [selectedResponses, setSelectedResponses] = useState(null);
  const [, { getOrFetchProjectById }] = useProjects();
  const [
    { sessions, selectedSession, doneFetching, loading, total, responsesFilters },
    {
      clearSessionsStore,
      fetchSessions,
      updateSessionProperties,
      setSelectedSession,
      setResponsesFilters,
    },
  ] = useSessions();
  const [, { fetchResponsesBySessionId }] = useResponses();
  const history = useHistory();
  const { projectId = '' } = useParams();
  const query = useQuery();
  const location = useLocation();
  const [sessionId, setSessionId] = useState(getSessionId(query, location));
  const [, { getFeatureEnabled }] = useFeatures();
  const withFilters = getFeatureEnabled(FEATURE_CONTACTS_FILTERS);
  const { hasReachedResponsesLimit } = useBilling();

  useEffect(() => {
    fetchProject(projectId);
  }, [projectId]);

  useEffect(() => {
    closeSidebar();

    return () => {
      clearSessionsStore();
    };
  }, []);

  const fetchProject = async projectId => {
    if (hasReachedResponsesLimit) {
      return;
    }

    const response = await getOrFetchProjectById(projectId);
    setProject(response);
  };

  const fetchMore = async () => {
    if (hasReachedResponsesLimit) {
      return;
    }

    const filtersAndStatus = getFilters(status, responsesFilters);
    await fetchSessions({
      projectId,
      filters: filtersAndStatus,
      email,
      sessionId,
      withFilters,
    });
  };

  const { setDoneScroll } = useInfiniteScroll(sessionsRef, fetchMore);

  useEffect(() => {
    clearState();
    fetchMore();
  }, [projectId, status, responsesFilters, email, withFilters]);

  useEffect(() => {
    if (!selectedSession || !getIsSessionStillExists(sessions, selectedSession)) {
      const newSelectedSession = sessions?.[0] ?? null;
      setSelectedSession(newSelectedSession);
      if (!newSelectedSession) {
        setSelectedResponses(null);
      }
    }
    setDoneScroll(doneFetching);
  }, [sessions, selectedSession]);

  useEffect(() => {
    const getSelectedResponses = async () => {
      if (selectedSession) {
        const responses = await fetchResponsesBySessionId(selectedSession.id);
        setSelectedResponses(responses);
      }
    }
    getSelectedResponses();
  }, [selectedSession, location.state]);

  const clearState = () => {
    if (hasReachedResponsesLimit) {
      return;
    }

    if (sessionId) {
      removeSessionId(location, history);
      setSessionId('');
    }
    clearSessionsStore();
    setSelectedResponses(null);
    setSelectedSession(null);
  };

  const updateSession = (sessionId, properties) => {
    if (hasReachedResponsesLimit) {
      return;
    }

    return updateSessionProperties(sessionId, properties, getFilters(status, responsesFilters));
  };

  const onSessionSelect = async session => {
    if (hasReachedResponsesLimit) {
      return;
    }

    setSelectedResponses(null);
    setSelectedSession(session);
  };

  const onStatusChange = newStatus => {
    if (hasReachedResponsesLimit) {
      return;
    }

    setStatus(newStatus);
    track('Inbox status change', {
      newStatus,
      responsesFilters,
    });
  };

  const onFiltersChange = newFilters => {
    if (hasReachedResponsesLimit) {
      return;
    }

    setResponsesFilters(newFilters);
    track('Inbox filters change', {
      newFilters,
      status,
    });
  };

  return (
    <LayoutRoot>
      {hasReachedResponsesLimit && (
        <StyledPricingBanner
          text="RESPONSES"
          analyticsData={Utils.getAnalyticsLocationObject('Responses Banner')}
        />
      )}
      <MainContainer disabled={hasReachedResponsesLimit}>
        <InboxStatus status={status} onStatusChange={onStatusChange} />
        <InboxFilters filters={responsesFilters} onFiltersChange={onFiltersChange} />
        <InboxConversations
          onEmailChange={setEmail}
          selectedConversation={selectedSession}
          conversations={sessions}
          status={status}
          loadingConversations={loading}
          onConversationSelect={onSessionSelect}
          updateConversation={updateSession}
          conversationsRef={sessionsRef}
          counter={total}
        />
        <InboxConversation
          project={project}
          conversation={selectedSession}
          responses={selectedResponses}
          status={status}
          loading={loading}
          empty={!total}
          emptyStateActionButton={<ShareButton project={project} />}
        />
      </MainContainer>
    </LayoutRoot>
  );
};

export default Inbox;

const LayoutRoot = styled(VerticalFlex)`
  height: 100%;
  overflow: hidden;
`;

const MainContainer = styled.div`
  display: grid;
  grid-template-areas:
    'status conversations conversation'
    'filters conversations conversation';
  grid-template-columns: 168px 300px auto;
  grid-template-rows: auto 1fr;
  overflow-x: auto;
  overflow-y: hidden;
  height: 100%;
  position: relative;
  filter: ${({ disabled }) => (disabled ? 'blur(5px)' : '')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : '')};

  @media (${({ theme }) => theme.breakpoints.dashboardTabletMax}) {
    left: initial;
    width: initial;
  }
`;

const StyledPricingBanner = styled(PricingBanner)`
  z-index: 1;
  margin: 8px;
`;
