import { FEATURE_PUBLISH_V2 } from 'shared/react/constants/features.constants';
import { PUBLISH_METHODS_WITH_LIVE_FIELD } from 'shared/react/constants/tolstoy.constants';
import { useFeatureActions } from 'src/context/FeaturesStore';
import useQuery from 'src/hooks/useQuery';
import { project as ProjectType, publishMethodOptions } from 'src/types/entities';

const usePublishFeature = (project: ProjectType) => {
  const { getFeatureEnabled } = useFeatureActions();
  const publishType = useQuery().get('publish');

  const isPublishV2Enabled = getFeatureEnabled(FEATURE_PUBLISH_V2);

  const publishMethod =
    project?.publishMethod === publishMethodOptions.unassigned
      ? publishType
      : project?.publishMethod;

  const shouldShowPublishToggle =
    isPublishV2Enabled && PUBLISH_METHODS_WITH_LIVE_FIELD.includes(publishMethod);

  return { shouldShowPublishToggle };
};

export default usePublishFeature;
