import React from 'react';

const OutlinedVIcon = () => (
  <svg width={12} height={9} viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.25 1.523a.513.513 0 0 0 0-.773.513.513 0 0 0-.773 0L4.5 6.727l-3-2.977a.513.513 0 0 0-.773 0 .513.513 0 0 0 0 .773l3.375 3.375c.093.118.234.165.398.165.14 0 .281-.047.375-.165l6.375-6.375Z"
      fill="#fff"
    />
  </svg>
);

export default OutlinedVIcon;
