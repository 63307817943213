import React from 'react';
import Separator from 'shared/react/components/basic/Separator';
import QuizFlowList from 'app/src/pages/project/pages/quiz/quiz_result/flow/QuizFlowList';
import styled from 'styled-components';
import { TextSmall } from 'shared/react/components/complex/Text';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

function QuizResult({ quizResult }) {
  const { id: quizResultId, flows } = quizResult;

  return (
    <LayoutRoot>
      <Stack>
        <LeftStyledSeparator />
        <Explanation>Results above will show with the following conditions</Explanation>
        <RightStyledSeparator />
      </Stack>

      <QuizFlowList quizResultId={quizResultId} flows={flows} />
    </LayoutRoot>
  );
}

export default QuizResult;

const LayoutRoot = styled(VerticalFlex)`
  gap: 16px;
  width: 100%;
`;

const StyledSeparator = styled(Separator)`
  grid-row: 1;
  z-index: 1;
  width: 100%;
`;

const LeftStyledSeparator = styled(StyledSeparator)`
  grid-column: 1;
`;

const RightStyledSeparator = styled(StyledSeparator)`
  grid-column: 3;
`;

const Stack = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
`;

const Explanation = styled(TextSmall)`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.ghostLight};
  border-radius: 24px;
  padding: 6px 16px;
  color: ${({ theme }) => theme.colors.ghostDark};
  width: inherit;
  align-items: center;
  z-index: 2;
`;
