import React from 'react';

function IIcon({ fill, darkBackground, size = 20 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={12} cy={12} r={12} fill={fill || '#FFFFFF'} />
      <path d="M11.238 15.5h1.53V7.47h-1.53v8.03Z" fill={darkBackground ? '#FFFFFF' : '#2F3137'} />
    </svg>
  );
}

export default IIcon;
