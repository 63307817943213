import React from 'react';
import ChartBox from '../chart/ChartBox';
import ChartHeader from '../chart/ChartHeader';
import { useAnalyticsChannel } from 'app/src/context/AnalyticsStore';
import ComposedChart from 'app/src/complex_components/analytics/chart/ComposedChart';
import AnalyticsOverviewViewersTooltip from './AnalyticsOverviewViewersTooltip';
import useAnalyticsOverview from 'app/src/hooks/useAnalyticsOverview';
import Utils from 'app/src/utils';
import { ChartStats } from '../chart/ChartStats';
import {
  CHANNEL_FILTER_OPTIONS,
  ANALYTICS_OVERVIEW_STATS,
  ANALYTICS_OVERVIEW_BAR_CHARTS,
} from 'app/src/constants/analytics.constants';

const AnalyticsOverviewViewers = () => {
  const [channel] = useAnalyticsChannel();
  const {
    engagementRate,
    fmtTotalUniqueUsersSessionStarts,
    uniqueUsersMetrics,
    viewersShouldShowLoader,
    viewersShowEmptyState,
  } = useAnalyticsOverview();
  const { title, bars } = ANALYTICS_OVERVIEW_BAR_CHARTS.TOLSTOY_VIEWERS;
  const isTapcartChannel = channel === CHANNEL_FILTER_OPTIONS.tapcart;
  const visibleBars = isTapcartChannel ? bars.filter(bar => bar.dataKey !== 'pageViews') : bars;

  const statsRows = [
    {
      name: ANALYTICS_OVERVIEW_STATS.ENGAGEMENT_RATE,
      formattedValue: Utils.formatNumber({ value: engagementRate, isPercentage: true }),
      tooltipText: ANALYTICS_OVERVIEW_STATS.ENGAGEMENT_RATE_TOOLTIP,
      hidden: isTapcartChannel,
    },
  ];

  const yTickFormatter = (value: string) => {
    return Utils.formatNumber({ value });
  };

  return (
    <ChartBox shouldShowLoader={viewersShouldShowLoader}>
      <ChartHeader
        subtitle={viewersShowEmptyState ? '' : fmtTotalUniqueUsersSessionStarts}
        title={title}
        titleTooltipText={ANALYTICS_OVERVIEW_STATS.TOLSTOY_VIEWERS_TOOLTIP}
      />
      <ChartStats rows={statsRows} shouldShowEmptyState={viewersShowEmptyState} />
      <ComposedChart
        bars={visibleBars}
        data={uniqueUsersMetrics}
        renderCustomTooltip={AnalyticsOverviewViewersTooltip}
        shouldShowEmptyState={viewersShowEmptyState}
        yTickFormatter={yTickFormatter}
      />
    </ChartBox>
  );
};

export default AnalyticsOverviewViewers;
