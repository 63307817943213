import React from 'react';

const DeleteIcon = ({ fill = '#787F8C' }) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.25 2.5H11.9062L10.8438 0.75C10.5625 0.28125 10.0938 0 9.5625 0H6.40625C5.875 0 5.40625 0.28125 5.125 0.75L4.0625 2.5H1.75C1.3125 2.5 1 2.84375 1 3.25C1 3.6875 1.3125 4 1.75 4H2L2.65625 14.5938C2.6875 15.4062 3.34375 16 4.15625 16H11.8125C12.625 16 13.2812 15.4062 13.3125 14.5938L14 4H14.25C14.6562 4 15 3.6875 15 3.25C15 2.84375 14.6562 2.5 14.25 2.5ZM6.40625 1.5H9.5625L10.1562 2.5H5.8125L6.40625 1.5ZM11.8125 14.5H4.15625L3.5 4H12.4688L11.8125 14.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default DeleteIcon;
