import styled from 'styled-components';
import useIsMilk from 'shared/react/hooks/useIsMilk';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';
import { getProfileLink } from "shared/react/utils/social";

const FeedVideoHandle = ({ step }) => {
  const [{ playerSettings }] = useProjectConfig();
  const isMilk = useIsMilk();
  const { handle } = step?.externalProviderData || {};

  const shouldHide = !(playerSettings?.showCreatorProfileLink && handle);

  if (shouldHide) {
    return null;
  }

  return (
    <LayoutRoot>
      <StyledHandleLink
        target="_blank"
        rel="noopener noreferrer"
        href={getProfileLink(handle)}
        isMilk={isMilk}
      >
        {handle}
      </StyledHandleLink>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  margin-right: auto;
  margin-bottom: 10px;
  margin-left: 4px;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
`;

const StyledHandleLink = styled.a`
  margin-right: auto;
  align-self: center;
  color: ${({ theme, cardTextColor }) => cardTextColor || theme.colors.white};
  font-size: ${({ isMilk }) => (isMilk ? '20px' : '13px')};
  font-weight: ${({ isMilk }) => (isMilk ? '600' : '500')};
  line-height: ${({ isMilk }) => (isMilk ? '22px' : '15px')};
  text-decoration: none;
`;

export default FeedVideoHandle;
