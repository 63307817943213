import React, { useEffect, useState, useMemo } from 'react';
import Utils from 'src/utils';
import { App } from 'src/types/app';
import { useAppActions } from 'src/context/AppsStore';
import { useVideos } from 'src/context/VideosStore';
import { useSaveInstagramUGCSettings } from 'src/hooks/ugc';
import { getUgcMessage, makeHashtag, getSocialUsername } from 'src/utils/ugc';
import { useDrawer } from 'src/context/ui_store/DrawerStore';
import { useInstagram } from 'app/src/context/InstagramStore';
import { ALL_UGC_UPLOAD_TYPES } from 'src/constants/intergrations.constants';
import { GlobalDrawerType } from 'src/complex_components/global_drawer/types/globalDrawer.types';

import { UgcPageContent, UgcSort, UgcFilter } from '../layout';
import { SEARCH_FILTERS, SORT_FIELDS, DEFAULT_SORT } from '../../constants';
import { useFilteredAssets } from '../../hooks';
import UgcSearchTopBar from './UgcSearchTopBar';

type Props = {
  ugcApps: App[];
  selectedApp: App;
  selectedUGCCategory: string;
  onSelectApp?: (app: App) => void;
};

const EMPTY_STATE = {
  title: 'No results found',
  subTitle: 'Please search another hashtag',
};

/*
  TODO: refactor component
    1. separate logic into hooks or move up
    2. remove 'selectedUGCCategory' from instagram store - no reason to have it there, it should be local state
*/
export const UgcSearchPage = ({
  ugcApps,
  selectedApp,
  selectedUGCCategory,
  onSelectApp,
}: Props) => {
  const { updateApp } = useAppActions();
  const [isSearching, setIsSearching] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [
    { appsOptionsMap, isLoading },
    {
      fetchByHashtag,
      addInstagramHashtag,
      fetchByAccountTagging,
      setSelectedUGCCategory,
      removeInstagramHashtag,
    },
  ] = useInstagram();
  const [{ data }, { getVideoByExternalId }] = useVideos();
  const [{ currentDrawer }, { setCurrentDrawer }] = useDrawer();
  const saveUGCSettings = useSaveInstagramUGCSettings(selectedApp);

  const defaultAssets = useMemo(() => {
    const id = selectedUGCCategory || selectedApp.id;
    const { media = [] } = appsOptionsMap[id] || {};
    const videos = media?.filter(video => !getVideoByExternalId(video.externalId)) || [];
    const selectedCategory = makeHashtag(selectedUGCCategory) || getSocialUsername(selectedApp);
    const importedAssets = data.filter(asset => {
      const isUgc = ALL_UGC_UPLOAD_TYPES.includes(asset.uploadType);
      const { ugcHashtag } = Utils.safeParse(asset.externalProviderData);

      return isUgc && selectedCategory === ugcHashtag;
    });

    return [...videos, ...importedAssets];
  }, [data, selectedApp, selectedUGCCategory, appsOptionsMap, getVideoByExternalId]);
  const {
    assets: videos,
    setFilters,
    filters,
    setSort,
    sort,
  } = useFilteredAssets({
    assets: defaultAssets,
    defaultSort: DEFAULT_SORT,
    defaultFilters: SEARCH_FILTERS,
  });

  const hashtags = ugcApps.flatMap(app => app?.settings?.hashtags || []);

  const fetchMedia = async ({ hashtag = null, app = null, reload = false }) => {
    if (hashtag) {
      return fetchByHashtag(selectedApp.id, hashtag, reload);
    }

    return fetchByAccountTagging(app.id, reload);
  };

  useEffect(() => {
    setIsSearching(true);

    fetchMedia({ app: selectedApp }).finally(() => setIsSearching(false));
  }, []);

  // closes drawer when navigating away from search tab
  useEffect(() => {
    return () => {
      if (currentDrawer === GlobalDrawerType.UGCSettings) {
        setCurrentDrawer({ currentDrawer: null });
      }
    };
  }, [currentDrawer]);

  const onOpenUGCSettings = () => {
    setCurrentDrawer({
      currentDrawer: GlobalDrawerType.UGCSettings,
      drawerProps: {
        message: getUgcMessage(selectedApp),
        onSaveSettings: saveUGCSettings,
      },
    });
  };

  const onRefresh = async () => {
    if (isLoading || isRefreshing) {
      return;
    }

    setIsRefreshing(true);

    await fetchMedia({
      hashtag: selectedUGCCategory,
      app: selectedApp,
      reload: true,
    });

    setIsRefreshing(false);
  };

  const onSearch = async (hashtag: string) => {
    setIsSearching(true);
    setSelectedUGCCategory(hashtag);
    const promises = [];

    if (!hashtags.includes(hashtag)) {
      const settings = addInstagramHashtag(selectedApp, hashtag);

      promises.push(updateApp(selectedApp.id, { settings: JSON.stringify(settings) }));
    }

    promises.push(fetchMedia({ hashtag }));

    await Promise.all(promises);

    setIsSearching(false);
  };

  const handleSelectApp = (id: string) => {
    const app = ugcApps.find(app => app.id === id);

    if (!app) {
      return;
    }

    setSelectedUGCCategory(null);
    onSelectApp(app);
  };

  /*
    Potential bug: hashtag not necessarily is in selectedApp.settings.hashtags
                   maybe in one of other ugcApps

    Suggested solution: make hard app selection on top level
              and show only hashtags from selectedApp
  */
  const onRemoveHashtag = async hashtag => {
    const settings = removeInstagramHashtag(selectedApp, hashtag);
    await updateApp(selectedApp.id, { settings: JSON.stringify(settings) });
  };

  const loadMore = async (start, stop) => {
    const id = selectedUGCCategory || selectedApp.id;
    const { gotAllVideos = false } = appsOptionsMap[id] || {};

    if (stop < videos.length || isLoading || gotAllVideos) {
      return;
    }

    await fetchMedia({
      app: selectedApp,
      hashtag: selectedUGCCategory,
    });
  };

  return (
    <>
      <UgcSearchTopBar
        ugcApps={ugcApps}
        hashtags={hashtags}
        selectedApp={selectedApp}
        selectedHashtag={selectedUGCCategory}
        isLoading={isLoading}
        isRefreshing={isRefreshing || isSearching}
        onSearch={onSearch}
        onRefresh={onRefresh}
        onSelectApp={handleSelectApp}
        onSettingsClick={onOpenUGCSettings}
        onRemoveHashtag={onRemoveHashtag}
      />
      <div className="flex justify-end gap-2 px-6 py-4">
        <UgcSort
          selectedFieldValue={sort.field}
          order={sort.order}
          fields={SORT_FIELDS}
          onSort={setSort}
        />
        <UgcFilter filters={filters} onChange={setFilters} />
      </div>
      <UgcPageContent
        videos={videos}
        selectedApp={selectedApp}
        selectedUGCCategory={selectedUGCCategory}
        mediaLabel={makeHashtag(selectedUGCCategory)}
        overscan={20}
        emptyState={EMPTY_STATE}
        isLoading={isSearching || isLoading}
        loadMore={loadMore}
      />
    </>
  );
};

export default UgcSearchPage;
