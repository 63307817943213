import React, { useState } from 'react';
import { project, vodAsset } from 'src/types/entities';
import styled from 'styled-components';
import HeroYoutubeStep from 'app/src/pages/project/pages/hero/components/hero-step/HeroYoutubeStep';
import { TextBodyLeading } from 'shared/react/components/complex/Text';
import { GhostButton, PrimaryButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { useModalActions } from 'app/src/context/ui_store/ModalStore';
import { useProjectActions } from 'app/src/context/ProjectsStore';
import { track } from 'app/src/helpers/Tracker';
import { LIBRARY_MODAL_KEY } from 'app/src/constants/modals.constants';
import { YOUTUBE } from 'app/src/constants/intergrations.constants';
import { STEP_TYPE } from 'app/src/pages/project/pages/hero/constants';
import { CheckboxV2 } from 'app/src/complex_components/Checkbox';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';

type Props = {
  project: project;
  setIsYoutube: (isYoutube: boolean) => void;
};

const HeroYoutube = ({ project, setIsYoutube }: Props) => {
  const { setCurrentModal, clearModalState } = useModalActions();
  const { updateProjectStep, deleteProjectStep, publishProject } = useProjectActions();
  const [isLoading, setIsLoading] = useState(false);

  const onChoose = async (video: vodAsset) => {
    track('Hero Choose From Youtube');
    const step = project?.steps?.items.find(({ description }) => description === STEP_TYPE.youtube);

    const newStep = {
      id: step.id,
      videoId: video.externalId,
      question: video?.stockAsset?.posterUrl,
    };

    await updateProjectStep(newStep, project);
    clearModalState();
  };

  const onYoutubeClick = () => {
    track('Hero Choose From Youtube Button Click');
    setCurrentModal(LIBRARY_MODAL_KEY, { source: YOUTUBE, onChoose });
  };

  const onUploadClick = async () => {
    track('Hero Upload Files Button Click');
    const step = project?.steps?.items.find(({ description }) => description === STEP_TYPE.youtube);
    setIsLoading(true);
    await deleteProjectStep({ ...step }, project);
    await publishProject(project.id);
    setIsLoading(false);
    setIsYoutube(false);
  };

  return (
    <LayoutRoot>
      <TextBodyLeading>Hero section video</TextBodyLeading>
      <HeroYoutubeStep project={project} />
      <ButtonsContainer>
        <GhostButton onClick={onYoutubeClick}>Change YouTube video</GhostButton>
        <PrimaryButton isLoading={isLoading} onClick={onUploadClick}>
          Upload files
        </PrimaryButton>
      </ButtonsContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)``;

const ButtonsContainer = styled(Gap8HorizontalFlex)``;

export default HeroYoutube;
