import { useCallback } from 'react';
import { InstagramUGCSettings } from 'src/types/ugc';
import { useAppActions } from 'src/context/AppsStore';

export const useSaveInstagramUGCSettings = instagramApp => {
  const { updateApp } = useAppActions();

  const saveInstagramUGCSettings = useCallback(
    async (settings: InstagramUGCSettings) => {
      await updateApp(instagramApp.id, {
        data: JSON.stringify({
          ...(instagramApp.data || {}),
          ugcSettings: settings,
        }),
      });
    },
    [updateApp, instagramApp]
  );

  return saveInstagramUGCSettings;
};

export default useSaveInstagramUGCSettings;
