import React, { FC, SyntheticEvent } from 'react';
import styled from 'styled-components';
import FolderIcon from 'app/src/images/dashboard_v2/FolderIcon';
import { PageSideBarMenuItem, PanelTextItem } from 'app/src/pages/dashboard/components/page/Page';
import { TextSmall } from 'shared/react/components/basic/text/TextV2';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import ThreeVerticalDotsIcon from 'app/src/images/dashboard_v2/ThreeVerticalDotsIcon';
import {
  FOLDER_EXTRA_BUTTONS_DATA_TEST_ID,
  FOLDER_ITEM_DATA_TEST_ID,
} from 'app/src/constants/dataTestIds.constants';
import Expander from 'shared/react/components/complex/expanders/Expander';
import { EXPANDER_HEADER_OUTER_DATA_TYPE } from 'shared/react/components/complex/expanders/expander.constants';
import { SidebarIntegrationSideButtonLayoutRoot } from 'app/src/pages/dashboard/pages/videos/components/side-bar/SidebarIntegrationSideButton';
import { SidebarIntegrationMenuLayoutRoot } from 'app/src/pages/dashboard/pages/videos/components/side-bar/SidebarIntegrationMenu';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';

type Props = {
  onClick: () => void;
  isSelected: boolean;
  number?: number | null;
  text: string;
  showDefaultEndIcon?: boolean;
  id?: string;
  openMenu?: (e: SyntheticEvent, id: string) => void;
  icon?: React.ReactNode;
  endIcon?: React.ReactNode;
  className?: string;
  index?: number;
  hidden?: boolean;
  items?: React.ReactNode;
  isBeta?: boolean;
  setSelectedApp?: (id: string) => void;
  isNew?: boolean;
};

const FolderMenuItem: FC<Props> = ({
  onClick,
  isSelected,
  number,
  text,
  showDefaultEndIcon = true,
  id,
  openMenu,
  icon = <FolderIcon />,
  endIcon,
  className,
  index,
  items,
  setSelectedApp,
  showLeftIcon = true,
  isBeta = false,
  isNew,
  hidden,
  ...props
}) => {
  const handleOpenMenu = (e: SyntheticEvent) => {
    e.stopPropagation();
    openMenu(e, id);
  };

  const getEndIcon = () => {
    if (endIcon) {
      return endIcon;
    }

    if (!showDefaultEndIcon) {
      return null;
    }

    return (
      <EndIconContainer
        data-test-id={`${FOLDER_EXTRA_BUTTONS_DATA_TEST_ID}-${index}`}
        onClick={handleOpenMenu}
      >
        <ThreeVerticalDotsIcon />
      </EndIconContainer>
    );
  };

  if (hidden) {
    return null;
  }

  if (items) {
    return (
      <CustomExpander
        {...props}
        isSelected={isSelected}
        hidden={hidden}
        isDefaultExpanded={isSelected}
        renderHeaderLeftContainer={
          <StyledMenuItem
            className={className}
            icon={icon}
            onClick={onClick}
            isSelected={isSelected}
            tooltipPlacement="bottom"
            tooltipText={text}
            data-test-id={`${FOLDER_ITEM_DATA_TEST_ID}-${index}`}
            $isInExpander={true}
            hidden={hidden}
            {...props}
          >
            <PanelTextItem>
              <EllipsisTextOverflowWrapper>
                <StyledMenuItemText>{text}</StyledMenuItemText>
              </EllipsisTextOverflowWrapper>
              {isBeta && <BetaTag>Beta</BetaTag>}
              {!!number && <FolderNumberSpan>{number}</FolderNumberSpan>}
            </PanelTextItem>
          </StyledMenuItem>
        }
      >
        <ItemsContainer>
          {items.map(({ text, key, ...props }) => {
            return (
              <AccordionFolderMenuItem
                onClick={() => setSelectedApp(key)}
                key={text}
                text={text}
                showDefaultEndIcon={false}
                {...props}
              />
            );
          })}
        </ItemsContainer>
      </CustomExpander>
    );
  }

  return (
    <StyledMenuItem
      className={className}
      icon={showLeftIcon ? icon : null}
      onClick={onClick}
      isSelected={isSelected}
      endIcon={getEndIcon()}
      tooltipPlacement="bottom"
      tooltipText={text}
      data-test-id={`${FOLDER_ITEM_DATA_TEST_ID}-${index}`}
      hidden={hidden}
      {...props}
    >
      <PanelTextItem>
        <EllipsisTextOverflowWrapper>
          <StyledMenuItemText>{text}</StyledMenuItemText>
        </EllipsisTextOverflowWrapper>
        {isNew && <NewTag>New</NewTag>}
        {isBeta && <BetaTag>Beta</BetaTag>}
        {!!number && <FolderNumberSpan>{number}</FolderNumberSpan>}
      </PanelTextItem>
    </StyledMenuItem>
  );
};

const FolderNumberSpan = styled.span`
  margin-left: 12px;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    margin-left: 5px;
  }
`;

const EndIconContainer = styled(HorizontalFlexCentered)`
  width: 20px;
  height: 20px;
  display: none;
  padding: 3px 4px;
  border-radius: 16px;
  transition: 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
  }

  & > svg {
    width: 12px;
    height: 12px;
  }

  & svg > path {
    fill: ${({ theme }) => theme.colors.black};
  }

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    display: flex;
  }
`;

const StyledMenuItem = styled(PageSideBarMenuItem)`
  padding: ${({ $isInExpander }) => ($isInExpander ? '0' : '8px 12px')};
  background: ${({ $isInExpander }) => ($isInExpander ? 'unset' : '')};

  opacity: 1;

  &:hover {
    background: ${({ $isInExpander }) => ($isInExpander ? 'unset' : '')};
  }

  &:hover ${EndIconContainer} {
    display: flex;
  }
`;

const StyledMenuItemText = styled(TextSmall)`
  font-weight: 400;
`;

const CustomExpander = styled(Expander)`
  & ${SidebarIntegrationSideButtonLayoutRoot}, & ${SidebarIntegrationMenuLayoutRoot} {
    display: flex;
    opacity: 0;
  }

  &:hover ${SidebarIntegrationSideButtonLayoutRoot}, &:hover ${SidebarIntegrationMenuLayoutRoot} {
    opacity: 1;
  }

  & [data-type=${EXPANDER_HEADER_OUTER_DATA_TYPE}] {
    padding: 8px 12px 8px 12px;
  }
`;

const ItemsContainer = styled(Gap8VerticalFlex)`
  gap: 4px;
`;

const AccordionFolderMenuItem = styled(FolderMenuItem)`
  padding: 8px 36px;
`;

const Tag = styled.div`
  border-radius: 8px;
  margin-left: 8px;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
  padding: 2px 4px;
`;

const BetaTag = styled(Tag)`
  background-color: ${({ theme }) => theme.colors.primary};
`;

const NewTag = styled(Tag)`
  background-color: ${({ theme }) => theme.colors.info};
`;

export default FolderMenuItem;
