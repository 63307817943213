import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { ROW_HEIGHT } from 'src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-products-table/dynamicProductsTable.constants';
import styled from 'styled-components';

export const VirtualizedRowColumn = styled.div.attrs({
  className: 'ReactVirtualized__Table__headerColumn',
})<{ width: number }>`
  flex: 0 1 ${({ width }) => width}px;
`;

export const VirtualizedRowContainer = styled(HorizontalFlex)`
  align-items: center;
  min-height: ${ROW_HEIGHT}px;
  cursor: pointer;
`;

export const VirtualizedVideosRowContainer = styled.div`
  margin-left: 10px;
  overflow: auto;
  background: ${({ theme }) => theme.colors.neutralLightest};
  padding: 16px 24px;
  grid-template-columns: repeat(auto-fill, minmax(171px, 1fr));
  grid-template-rows: repeat(auto-fill, 126px);
  display: grid;
  gap: 16px;
  margin-right: 8px;
`;

export const VirtualizedVideosImageContainer = styled.div`
  width: 100%;
  height: 96px;
  border-radius: 2px 2px 0 0;
  position: relative;
`;

export const VirtualizedVideosImage = styled.img`
  object-fit: contain;
  width: 100%;
  height: 96px;
  border-radius: 2px 2px 0 0;
  background: ${({ theme }) => theme.colors.black};
`;
