export const CREATED_BY_ME_PROJECT_FILTER = 'createdByOwner';
export const CREATED_BY_TEAMMATE_PROJECT_FILTER = 'createdByTeammate';
export const PRIVATE_PROJECT_FILTER = 'private';
export const LIVE_PROJECT_FILTER = 'live';
export const MACRO_PROJECT_FILTER = 'macro';
export const FOLDER_FILTER = 'folder';
export const PUBLISH_METHOD_FILTER = 'publishMethod';

export const PROJECT_FILTERS_TEXT_MAP = {
  [PRIVATE_PROJECT_FILTER]: 'Personal',
  [FOLDER_FILTER]: 'Folder',
  [LIVE_PROJECT_FILTER]: 'Live',
  [MACRO_PROJECT_FILTER]: 'Macros',
  [CREATED_BY_ME_PROJECT_FILTER]: 'Created by me',
  [CREATED_BY_TEAMMATE_PROJECT_FILTER]: 'Created by',
  [PUBLISH_METHOD_FILTER]: 'Type',
};

export const CREATED_BY_ME_VIDEO_FILES_FILTER = 'createdByOwner';
export const CREATED_BY_TEAMMATE_VIDEO_FILES_FILTER = 'createdByTeammate';

export const VIDEO_FILES_FILTERS_TEXT_MAP = {
  [CREATED_BY_ME_VIDEO_FILES_FILTER]: 'Created by me',
  [CREATED_BY_TEAMMATE_VIDEO_FILES_FILTER]: 'Created by',
};

const VIDEO_INBOX_BY_ACCOUNT_KEY = 'byAccount';

export const VIDEO_INBOX_FILTERS = {
  byAccount: VIDEO_INBOX_BY_ACCOUNT_KEY,
};

export const DEFAULT_VIDEO_INBOX_FILTERS = {
  [VIDEO_INBOX_BY_ACCOUNT_KEY]: '',
};
