import React from 'react';
import styled from 'styled-components';
import useProjectConfig from '../../context/hooks/useProjectConfig';
import { TextSmall } from '../../Text';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';
import Utils from 'shared/react/utils/utils';

const FeedProductStock = ({ selectedVariant, isMultipleProductDetails }) => {
  const [, { t }] = useTranslation('feed.products');
  const [{ playerSettings }] = useProjectConfig();
  const {
    productDetailsStockEnabled: enabled,
    productDetailsStockInColor: inStockColor,
    productDetailsStockOutColor: outStockColor,
  } = playerSettings || {};
  const isInStock = selectedVariant?.isVariantAvailableForSale;

  if (!enabled || isMultipleProductDetails || Utils.isNullOrUndefined(isInStock)) {
    return null;
  }

  const color = isInStock ? inStockColor : outStockColor;
  const text = isInStock ? t('inStock') : t('outOfStock');

  return <LayoutRoot color={color}>{text}</LayoutRoot>;
};

const LayoutRoot = styled(TextSmall)`
  color: ${({ color, theme }) => color || theme.colors.black};
  font-weight: 400;
`;

export default FeedProductStock;
