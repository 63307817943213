import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Routes from '../helpers/Routes';
import Login from '../pages/login/Login';
import ResetPassword from '../pages/login/ResetPassword';
import TolstoyMessage from '../pages/message/TolstoyMessage';

const Auth = () => {
  return (
    <Switch>
      <Route path={Routes.getLoginRoute()}>
        <Login login={true} />
      </Route>
      <Route path={Routes.getSignupRoute()}>
        <Login login={false} />
      </Route>
      <Route path={Routes.getResetPasswordRoute()}>
        <ResetPassword />
      </Route>
      <Route path={Routes.getTolstoyMessageBaseRoute()}>
        <TolstoyMessage />
      </Route>
    </Switch>
  );
};

export default Auth;
