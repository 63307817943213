import React from 'react';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import {
  AUTOPLAY_ENABLED_PROPERTY_KEY,
  SLIDE_ENABLED_PROPERTY_KEY,
} from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import EditorOnOffToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorOnOffToggleItem';
import { FEATURE_WIDGET_AUTOPLAY } from 'app/src/constants/appFeatures.constants';

const AutoplayEditorToggleItem = () => {
  const [, { getFeatureEnabled }] = useFeatures();
  const {
    customizationSettings: { widgetSettings },
    setWidgetSettingsProperty,
  } = useLookAndFeelContext();

  if (!getFeatureEnabled(FEATURE_WIDGET_AUTOPLAY)) {
    return null;
  }

  const onChangeAutoplay = value => {
    if (value) {
      setWidgetSettingsProperty(SLIDE_ENABLED_PROPERTY_KEY, false);
    }
    setWidgetSettingsProperty(AUTOPLAY_ENABLED_PROPERTY_KEY, value);
  };

  return (
    <EditorOnOffToggleItem
      text="Auto-open widget"
      tooltipText={'Widget will expand automatically and play on mute.'}
      checked={!!widgetSettings.autoplayEnabled}
      onChange={onChangeAutoplay}
    />
  );
};

export default AutoplayEditorToggleItem;
