import React from 'react';

const ArrowTopRightWithLineIcon = () => {
  return (
    <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 .75v7.5a.74.74 0 0 1-.75.75.722.722 0 0 1-.75-.75V2.562l-7.219 7.22A.732.732 0 0 1 1.75 10c-.219 0-.406-.063-.531-.219a.684.684 0 0 1 0-1.031L8.437 1.5H2.75A.722.722 0 0 1 2 .75.74.74 0 0 1 2.75 0h7.5a.76.76 0 0 1 .75.75Z"
        fill="#fff"
      />
    </svg>
  );
};

export default ArrowTopRightWithLineIcon;
