/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//  This file was automatically generated and should not be edited.

export type app = {
  __typename: 'app';
  id: string;
  owner?: string | null;
  appKey?: string | null;
  app?: string | null;
  active?: boolean | null;
  data?: string | null;
  token?: string | null;
  appExternalId?: string | null;
  appUrl?: string | null;
  importSettings?: ImportSettings | null;
  importRange?: number | null;
  syncTagsMode?: string | null;
  syndicateBy?: SyndicateBy | null;
  googleAnalyticsID?: string | null;
  settings?: string | null;
  isShopAppEligible?: boolean | null;
  taxonomyId?: number | null;
  createdAt: string;
  updatedAt: string;
};

export enum ImportSettings {
  off = 'off',
  semi = 'semi',
  auto = 'auto',
}

export enum SyndicateBy {
  sku = 'sku',
  barcode = 'barcode',
}

export type CreateVodAssetInput = {
  id?: string | null;
  type?: assetType | null;
  owner?: string | null;
  appKey?: string | null;
  appExternalId?: string | null;
  createdAt?: string | null;
  name: string;
  description?: string | null;
  creatorProfile?: CreatorProfileInput | null;
  isSoundAllowed?: boolean | null;
  status: string;
  uploadType?: string | null;
  stockAsset?: StockAssetInput | null;
  duration?: number | null;
  folder?: string | null;
  width?: number | null;
  height?: number | null;
  isVertical?: boolean | null;
  isHDR?: boolean | null;
  trimSettings?: string | null;
  originalVOD?: string | null;
  mirror?: boolean | null;
  recorderVersion?: string | null;
  originalSize?: string | null;
  externalId?: string | null;
  externalProviderData?: string | null;
  isMigratingToShopify?: boolean | null;
  shopifyUploadingStatus?: string | null;
  isEverUsed?: boolean | null;
  captionsSettings?: string | null;
  referenceIds?: Array<string | null> | null;
  analysis?: string | null;
  autoGenerated?: boolean | null;
};

export enum assetType {
  video = 'video',
  image = 'image',
  gallery = 'gallery',
}

export type CreatorProfileInput = {
  url?: string | null;
};

export type StockAssetInput = {
  videoUrl?: string | null;
  posterUrl?: string | null;
  gifUrl?: string | null;
  hasOriginal?: boolean | null;
  shopifyFileId?: string | null;
  previewUrl?: string | null;
  previewShopifyFileId?: string | null;
  shopifyPosterUrl?: string | null;
  posterShopifyFileId?: string | null;
  avatarUrl?: string | null;
  audioUrl?: string | null;
  gifShopifyFileId?: string | null;
};

export type ModelvodAssetConditionInput = {
  type?: ModelassetTypeInput | null;
  owner?: ModelStringInput | null;
  appKey?: ModelStringInput | null;
  appExternalId?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  isSoundAllowed?: ModelBooleanInput | null;
  status?: ModelStringInput | null;
  uploadType?: ModelStringInput | null;
  duration?: ModelIntInput | null;
  folder?: ModelStringInput | null;
  width?: ModelIntInput | null;
  height?: ModelIntInput | null;
  isVertical?: ModelBooleanInput | null;
  isHDR?: ModelBooleanInput | null;
  trimSettings?: ModelStringInput | null;
  originalVOD?: ModelStringInput | null;
  mirror?: ModelBooleanInput | null;
  recorderVersion?: ModelStringInput | null;
  originalSize?: ModelStringInput | null;
  externalId?: ModelStringInput | null;
  externalProviderData?: ModelStringInput | null;
  isMigratingToShopify?: ModelBooleanInput | null;
  shopifyUploadingStatus?: ModelStringInput | null;
  isEverUsed?: ModelBooleanInput | null;
  captionsSettings?: ModelStringInput | null;
  referenceIds?: ModelStringInput | null;
  analysis?: ModelStringInput | null;
  autoGenerated?: ModelBooleanInput | null;
  and?: Array<ModelvodAssetConditionInput | null> | null;
  or?: Array<ModelvodAssetConditionInput | null> | null;
  not?: ModelvodAssetConditionInput | null;
};

export type ModelassetTypeInput = {
  eq?: assetType | null;
  ne?: assetType | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = 'binary',
  binarySet = 'binarySet',
  bool = 'bool',
  list = 'list',
  map = 'map',
  number = 'number',
  numberSet = 'numberSet',
  string = 'string',
  stringSet = 'stringSet',
  _null = '_null',
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type vodAsset = {
  __typename: 'vodAsset';
  id: string;
  type?: assetType | null;
  owner?: string | null;
  appKey?: string | null;
  appExternalId?: string | null;
  createdAt?: string | null;
  name: string;
  description?: string | null;
  creatorProfile?: creatorProfile | null;
  isSoundAllowed?: boolean | null;
  status: string;
  uploadType?: string | null;
  stockAsset?: stockAsset | null;
  duration?: number | null;
  folder?: string | null;
  width?: number | null;
  height?: number | null;
  isVertical?: boolean | null;
  isHDR?: boolean | null;
  trimSettings?: string | null;
  originalVOD?: string | null;
  mirror?: boolean | null;
  recorderVersion?: string | null;
  originalSize?: string | null;
  externalId?: string | null;
  externalProviderData?: string | null;
  isMigratingToShopify?: boolean | null;
  shopifyUploadingStatus?: string | null;
  isEverUsed?: boolean | null;
  captionsSettings?: string | null;
  referenceIds?: Array<string | null> | null;
  vodConnections?: ModelvodConnectionConnection | null;
  analysis?: string | null;
  autoGenerated?: boolean | null;
  updatedAt: string;
};

export type creatorProfile = {
  __typename: 'creatorProfile';
  url?: string | null;
};

export type stockAsset = {
  __typename: 'stockAsset';
  videoUrl?: string | null;
  posterUrl?: string | null;
  gifUrl?: string | null;
  hasOriginal?: boolean | null;
  shopifyFileId?: string | null;
  previewUrl?: string | null;
  previewShopifyFileId?: string | null;
  shopifyPosterUrl?: string | null;
  posterShopifyFileId?: string | null;
  avatarUrl?: string | null;
  audioUrl?: string | null;
  gifShopifyFileId?: string | null;
};

export type ModelvodConnectionConnection = {
  __typename: 'ModelvodConnectionConnection';
  items: Array<vodConnection | null>;
  nextToken?: string | null;
};

export type vodConnection = {
  __typename: 'vodConnection';
  id: string;
  appKey?: string | null;
  vodAssetId?: string | null;
  productId?: string | null;
  externalProductId?: string | null;
  collectionId?: string | null;
  provider?: VodConnectionProvider | null;
  tag?: string | null;
  createdAt?: string | null;
  typeKey?: string | null;
  type?: VodConnectionType | null;
  appUrl?: string | null;
  label?: string | null;
  vodLabelId?: string | null;
  vodSource?: string | null;
  vodType?: string | null;
  variantIds?: Array<string | null> | null;
  projectId?: string | null;
  orderIndex?: number | null;
  source?: string | null;
  updatedAt: string;
};

export enum VodConnectionProvider {
  shopify = 'shopify',
  magento = 'magento',
  productsImport = 'productsImport',
  shopifyStudio = 'shopifyStudio',
}

export enum VodConnectionType {
  tag = 'tag',
  productId = 'productId',
  collectionId = 'collectionId',
  vodLabelId = 'vodLabelId',
  vodSource = 'vodSource',
  vodProjectId = 'vodProjectId',
  vodType = 'vodType',
}

export type UpdateVodAssetInput = {
  id: string;
  type?: assetType | null;
  owner?: string | null;
  appKey?: string | null;
  appExternalId?: string | null;
  createdAt?: string | null;
  name?: string | null;
  description?: string | null;
  creatorProfile?: CreatorProfileInput | null;
  isSoundAllowed?: boolean | null;
  status?: string | null;
  uploadType?: string | null;
  stockAsset?: StockAssetInput | null;
  duration?: number | null;
  folder?: string | null;
  width?: number | null;
  height?: number | null;
  isVertical?: boolean | null;
  isHDR?: boolean | null;
  trimSettings?: string | null;
  originalVOD?: string | null;
  mirror?: boolean | null;
  recorderVersion?: string | null;
  originalSize?: string | null;
  externalId?: string | null;
  externalProviderData?: string | null;
  isMigratingToShopify?: boolean | null;
  shopifyUploadingStatus?: string | null;
  isEverUsed?: boolean | null;
  captionsSettings?: string | null;
  referenceIds?: Array<string | null> | null;
  analysis?: string | null;
  autoGenerated?: boolean | null;
};

export type DeleteVodAssetInput = {
  id: string;
};

export type CreateVodResponseAssetInput = {
  id?: string | null;
  owner: string;
  appKey?: string | null;
  createdAt?: string | null;
  status?: string | null;
  uploadType?: string | null;
  duration?: number | null;
  width?: number | null;
  height?: number | null;
  isVertical?: boolean | null;
  isHDR?: boolean | null;
  mirror?: boolean | null;
  originalSize?: string | null;
  email?: string | null;
  projectId?: string | null;
};

export type ModelvodResponseAssetConditionInput = {
  owner?: ModelStringInput | null;
  appKey?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  status?: ModelStringInput | null;
  uploadType?: ModelStringInput | null;
  duration?: ModelIntInput | null;
  width?: ModelIntInput | null;
  height?: ModelIntInput | null;
  isVertical?: ModelBooleanInput | null;
  isHDR?: ModelBooleanInput | null;
  mirror?: ModelBooleanInput | null;
  originalSize?: ModelStringInput | null;
  email?: ModelStringInput | null;
  projectId?: ModelStringInput | null;
  and?: Array<ModelvodResponseAssetConditionInput | null> | null;
  or?: Array<ModelvodResponseAssetConditionInput | null> | null;
  not?: ModelvodResponseAssetConditionInput | null;
};

export type vodResponseAsset = {
  __typename: 'vodResponseAsset';
  id: string;
  owner: string;
  appKey?: string | null;
  createdAt: string;
  status?: string | null;
  uploadType?: string | null;
  duration?: number | null;
  width?: number | null;
  height?: number | null;
  isVertical?: boolean | null;
  isHDR?: boolean | null;
  mirror?: boolean | null;
  originalSize?: string | null;
  email?: string | null;
  projectId?: string | null;
  updatedAt: string;
};

export type UpdateVodResponseAssetInput = {
  id: string;
  owner?: string | null;
  appKey?: string | null;
  createdAt?: string | null;
  status?: string | null;
  uploadType?: string | null;
  duration?: number | null;
  width?: number | null;
  height?: number | null;
  isVertical?: boolean | null;
  isHDR?: boolean | null;
  mirror?: boolean | null;
  originalSize?: string | null;
  email?: string | null;
  projectId?: string | null;
};

export type DeleteVodResponseAssetInput = {
  id: string;
};

export type CreateProjectInput = {
  id?: string | null;
  owner?: string | null;
  appKey?: string | null;
  typeKey?: string | null;
  createdAt?: string | null;
  publishId: string;
  name: string;
  folder?: string | null;
  targetPage?: string | null;
  verticalOrientation?: boolean | null;
  widgetPosition?: string | null;
  widgetHeader?: string | null;
  widgetUrls?: Array<string | null> | null;
  description?: string | null;
  emailNotifications?: Array<string | null> | null;
  notificationLevel?: number | null;
  viewerNotificationLevel?: number | null;
  chaptersEnabled?: boolean | null;
  subtitlesEnabled?: boolean | null;
  stepsOrder?: Array<string | null> | null;
  collectText?: string | null;
  collectFields?: Array<InputFieldInput | null> | null;
  collectAfterStep?: Array<string | null> | null;
  cacheLeadFormAnswers?: boolean | null;
  googleAnalyticsID?: string | null;
  facebookAnalyticsID?: string | null;
  totalResponses?: number | null;
  unreadResponses?: number | null;
  tolstoyType?: string | null;
  stepsCount?: number | null;
  widgetBorderColor?: string | null;
  widgetShape?: string | null;
  widgetSize?: string | null;
  widgetNotificationBadge?: boolean | null;
  videoResolutions?: Array<string | null> | null;
  fastForwardEnabled?: boolean | null;
  rulesEnabled?: boolean | null;
  macro?: boolean | null;
  hideChatPlatform?: boolean | null;
  private?: boolean | null;
  chatLandingPage?: boolean | null;
  widgetSettings?: string | null;
  notificationSettingsId?: string | null;
  collectAfterAnyResponse?: boolean | null;
  startScreenText?: string | null;
  feed?: boolean | null;
  feedAutoScroll?: boolean | null;
  feedShowUnviewedStepsFirst?: boolean | null;
  language?: string | null;
  showButtonsIndex?: boolean | null;
  publishMethod?: publishMethodOptions | null;
  dynamic?: boolean | null;
  feedProductCtaType?: ProductCtaType | null;
  playerSettings?: string | null;
  appUrl?: string | null;
  live?: boolean | null;
  primaryButton?: CtaButtonConfigInput | null;
  secondaryButton?: CtaButtonConfigInput | null;
  pdpConfig?: PdpConfigInput | null;
  installationCompleted?: boolean | null;
  installStep?: string | null;
  discover?: boolean | null;
  useCaseName?: string | null;
  heroSettings?: string | null;
  sourceProjectId?: string | null;
  emailSettings?: string | null;
  videoCollectionCampaignSettings?: string | null;
  secretVideoSettings?: string | null;
  integrationSettings?: string | null;
};

export type InputFieldInput = {
  key?: string | null;
  name?: string | null;
  type?: string | null;
  placeholder?: string | null;
  link?: string | null;
  required?: boolean | null;
  collect?: boolean | null;
  custom?: boolean | null;
  textColor?: string | null;
  borderColor?: string | null;
  backgroundColor?: string | null;
};

export enum publishMethodOptions {
  videoPage = 'videoPage',
  stories = 'stories',
  carousel = 'carousel',
  bubble = 'bubble',
  embed = 'embed',
  landingPage = 'landingPage',
  unassigned = 'unassigned',
  hero = 'hero',
  shopApp = 'shopApp',
  tvChannel = 'tvChannel',
  email = 'email',
  tile = 'tile',
  videoCollectionCampaign = 'videoCollectionCampaign',
  studio = 'studio',
}

export enum ProductCtaType {
  buyNow = 'buyNow',
  addToCart = 'addToCart',
  viewProduct = 'viewProduct',
  openProductPage = 'openProductPage',
  customUrl = 'customUrl',
  closePlayer = 'closePlayer',
}

export type CtaButtonConfigInput = {
  enabled?: boolean | null;
  ctaType?: ProductCtaType | null;
  buttonText?: string | null;
  value?: string | null;
  isOpenInANewTab?: boolean | null;
};

export type PdpConfigInput = {
  primaryButton?: CtaButtonConfigInput | null;
  secondaryButton?: CtaButtonConfigInput | null;
  miniCtaButton?: MiniCtaButtonConfigInput | null;
};

export type MiniCtaButtonConfigInput = {
  enabled?: boolean | null;
  text?: string | null;
};

export type ModelprojectConditionInput = {
  owner?: ModelStringInput | null;
  appKey?: ModelStringInput | null;
  typeKey?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  publishId?: ModelStringInput | null;
  name?: ModelStringInput | null;
  folder?: ModelStringInput | null;
  targetPage?: ModelStringInput | null;
  verticalOrientation?: ModelBooleanInput | null;
  widgetPosition?: ModelStringInput | null;
  widgetHeader?: ModelStringInput | null;
  widgetUrls?: ModelStringInput | null;
  description?: ModelStringInput | null;
  emailNotifications?: ModelStringInput | null;
  notificationLevel?: ModelIntInput | null;
  viewerNotificationLevel?: ModelIntInput | null;
  chaptersEnabled?: ModelBooleanInput | null;
  subtitlesEnabled?: ModelBooleanInput | null;
  stepsOrder?: ModelStringInput | null;
  collectText?: ModelStringInput | null;
  collectAfterStep?: ModelStringInput | null;
  cacheLeadFormAnswers?: ModelBooleanInput | null;
  googleAnalyticsID?: ModelStringInput | null;
  facebookAnalyticsID?: ModelStringInput | null;
  totalResponses?: ModelIntInput | null;
  unreadResponses?: ModelIntInput | null;
  tolstoyType?: ModelStringInput | null;
  stepsCount?: ModelIntInput | null;
  widgetBorderColor?: ModelStringInput | null;
  widgetShape?: ModelStringInput | null;
  widgetSize?: ModelStringInput | null;
  widgetNotificationBadge?: ModelBooleanInput | null;
  videoResolutions?: ModelStringInput | null;
  fastForwardEnabled?: ModelBooleanInput | null;
  rulesEnabled?: ModelBooleanInput | null;
  macro?: ModelBooleanInput | null;
  hideChatPlatform?: ModelBooleanInput | null;
  private?: ModelBooleanInput | null;
  chatLandingPage?: ModelBooleanInput | null;
  widgetSettings?: ModelStringInput | null;
  notificationSettingsId?: ModelStringInput | null;
  collectAfterAnyResponse?: ModelBooleanInput | null;
  startScreenText?: ModelStringInput | null;
  feed?: ModelBooleanInput | null;
  feedAutoScroll?: ModelBooleanInput | null;
  feedShowUnviewedStepsFirst?: ModelBooleanInput | null;
  language?: ModelStringInput | null;
  showButtonsIndex?: ModelBooleanInput | null;
  publishMethod?: ModelpublishMethodOptionsInput | null;
  dynamic?: ModelBooleanInput | null;
  feedProductCtaType?: ModelProductCtaTypeInput | null;
  playerSettings?: ModelStringInput | null;
  appUrl?: ModelStringInput | null;
  live?: ModelBooleanInput | null;
  installationCompleted?: ModelBooleanInput | null;
  installStep?: ModelStringInput | null;
  discover?: ModelBooleanInput | null;
  useCaseName?: ModelStringInput | null;
  heroSettings?: ModelStringInput | null;
  sourceProjectId?: ModelIDInput | null;
  emailSettings?: ModelStringInput | null;
  videoCollectionCampaignSettings?: ModelStringInput | null;
  secretVideoSettings?: ModelStringInput | null;
  integrationSettings?: ModelStringInput | null;
  and?: Array<ModelprojectConditionInput | null> | null;
  or?: Array<ModelprojectConditionInput | null> | null;
  not?: ModelprojectConditionInput | null;
};

export type ModelpublishMethodOptionsInput = {
  eq?: publishMethodOptions | null;
  ne?: publishMethodOptions | null;
};

export type ModelProductCtaTypeInput = {
  eq?: ProductCtaType | null;
  ne?: ProductCtaType | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type project = {
  __typename: 'project';
  id: string;
  owner?: string | null;
  appKey?: string | null;
  typeKey?: string | null;
  createdAt?: string | null;
  publishId: string;
  name: string;
  folder?: string | null;
  targetPage?: string | null;
  verticalOrientation?: boolean | null;
  widgetPosition?: string | null;
  widgetHeader?: string | null;
  widgetUrls?: Array<string | null> | null;
  description?: string | null;
  emailNotifications?: Array<string | null> | null;
  notificationLevel?: number | null;
  viewerNotificationLevel?: number | null;
  chaptersEnabled?: boolean | null;
  subtitlesEnabled?: boolean | null;
  stepsOrder?: Array<string | null> | null;
  collectText?: string | null;
  collectFields?: Array<inputField | null> | null;
  collectAfterStep?: Array<string | null> | null;
  cacheLeadFormAnswers?: boolean | null;
  googleAnalyticsID?: string | null;
  facebookAnalyticsID?: string | null;
  totalResponses?: number | null;
  unreadResponses?: number | null;
  tolstoyType?: string | null;
  steps?: ModelprojectStepConnection | null;
  stepsCount?: number | null;
  widgetBorderColor?: string | null;
  widgetShape?: string | null;
  widgetSize?: string | null;
  widgetNotificationBadge?: boolean | null;
  videoResolutions?: Array<string | null> | null;
  fastForwardEnabled?: boolean | null;
  rulesEnabled?: boolean | null;
  macro?: boolean | null;
  hideChatPlatform?: boolean | null;
  private?: boolean | null;
  chatLandingPage?: boolean | null;
  widgetSettings?: string | null;
  notificationSettingsId?: string | null;
  collectAfterAnyResponse?: boolean | null;
  startScreenText?: string | null;
  feed?: boolean | null;
  feedAutoScroll?: boolean | null;
  feedShowUnviewedStepsFirst?: boolean | null;
  language?: string | null;
  showButtonsIndex?: boolean | null;
  publishMethod?: publishMethodOptions | null;
  dynamic?: boolean | null;
  feedProductCtaType?: ProductCtaType | null;
  playerSettings?: string | null;
  appUrl?: string | null;
  live?: boolean | null;
  primaryButton?: ctaButtonConfig | null;
  secondaryButton?: ctaButtonConfig | null;
  pdpConfig?: pdpConfig | null;
  installationCompleted?: boolean | null;
  installStep?: string | null;
  discover?: boolean | null;
  useCaseName?: string | null;
  heroSettings?: string | null;
  sourceProjectId?: string | null;
  emailSettings?: string | null;
  videoCollectionCampaignSettings?: string | null;
  secretVideoSettings?: string | null;
  integrationSettings?: string | null;
  updatedAt: string;
};

export type inputField = {
  __typename: 'inputField';
  key?: string | null;
  name?: string | null;
  type?: string | null;
  placeholder?: string | null;
  link?: string | null;
  required?: boolean | null;
  collect?: boolean | null;
  custom?: boolean | null;
  textColor?: string | null;
  borderColor?: string | null;
  backgroundColor?: string | null;
};

export type ModelprojectStepConnection = {
  __typename: 'ModelprojectStepConnection';
  items: Array<projectStep | null>;
  nextToken?: string | null;
};

export type projectStep = {
  __typename: 'projectStep';
  id: string;
  owner?: string | null;
  appKey?: string | null;
  projectId: string;
  videoId?: string | null;
  name: string;
  type?: string | null;
  description?: string | null;
  script?: string | null;
  question?: string | null;
  questionVisible?: boolean | null;
  answerTime?: number | null;
  answers?: Array<answer | null> | null;
  products?: Array<product | null> | null;
  productsAppUrl?: string | null;
  videoContain?: boolean | null;
  overlayText?: string | null;
  overlayTextColor?: string | null;
  multipleChoiceSelection?: boolean | null;
  defaultResponses?: Array<answer | null> | null;
  defaultResponsesEnabled?: boolean | null;
  sideBarButtonsEnabled?: boolean | null;
  feedProductActionButton?: feedProductActionButton | null;
  feedSidePanelType?: FeedSidePanelType | null;
  feedDescriptionActionButton?: feedProductActionButton | null;
  feedSidePanelDescription?: string | null;
  productCtaType?: ProductCtaType | null;
  createdAt: string;
  updatedAt: string;
};

export type answer = {
  __typename: 'answer';
  key?: string | null;
  text?: string | null;
  next?: string | null;
  value?: string | null;
  type?: string | null;
  disabled?: boolean | null;
};

export type product = {
  __typename: 'product';
  id?: string | null;
};

export type feedProductActionButton = {
  __typename: 'feedProductActionButton';
  type?: string | null;
  url?: string | null;
  text?: string | null;
  isOpenInANewTab?: boolean | null;
  value?: string | null;
  enabled?: boolean | null;
};

export enum FeedSidePanelType {
  shoppable = 'shoppable',
  description = 'description',
}

export type ctaButtonConfig = {
  __typename: 'ctaButtonConfig';
  enabled?: boolean | null;
  ctaType?: ProductCtaType | null;
  buttonText?: string | null;
  value?: string | null;
  isOpenInANewTab?: boolean | null;
};

export type pdpConfig = {
  __typename: 'pdpConfig';
  primaryButton?: ctaButtonConfig | null;
  secondaryButton?: ctaButtonConfig | null;
  miniCtaButton?: miniCtaButtonConfig | null;
};

export type miniCtaButtonConfig = {
  __typename: 'miniCtaButtonConfig';
  enabled?: boolean | null;
  text?: string | null;
};

export type UpdateProjectInput = {
  id: string;
  owner?: string | null;
  appKey?: string | null;
  typeKey?: string | null;
  createdAt?: string | null;
  publishId?: string | null;
  name?: string | null;
  folder?: string | null;
  targetPage?: string | null;
  verticalOrientation?: boolean | null;
  widgetPosition?: string | null;
  widgetHeader?: string | null;
  widgetUrls?: Array<string | null> | null;
  description?: string | null;
  emailNotifications?: Array<string | null> | null;
  notificationLevel?: number | null;
  viewerNotificationLevel?: number | null;
  chaptersEnabled?: boolean | null;
  subtitlesEnabled?: boolean | null;
  stepsOrder?: Array<string | null> | null;
  collectText?: string | null;
  collectFields?: Array<InputFieldInput | null> | null;
  collectAfterStep?: Array<string | null> | null;
  cacheLeadFormAnswers?: boolean | null;
  googleAnalyticsID?: string | null;
  facebookAnalyticsID?: string | null;
  totalResponses?: number | null;
  unreadResponses?: number | null;
  tolstoyType?: string | null;
  stepsCount?: number | null;
  widgetBorderColor?: string | null;
  widgetShape?: string | null;
  widgetSize?: string | null;
  widgetNotificationBadge?: boolean | null;
  videoResolutions?: Array<string | null> | null;
  fastForwardEnabled?: boolean | null;
  rulesEnabled?: boolean | null;
  macro?: boolean | null;
  hideChatPlatform?: boolean | null;
  private?: boolean | null;
  chatLandingPage?: boolean | null;
  widgetSettings?: string | null;
  notificationSettingsId?: string | null;
  collectAfterAnyResponse?: boolean | null;
  startScreenText?: string | null;
  feed?: boolean | null;
  feedAutoScroll?: boolean | null;
  feedShowUnviewedStepsFirst?: boolean | null;
  language?: string | null;
  showButtonsIndex?: boolean | null;
  publishMethod?: publishMethodOptions | null;
  dynamic?: boolean | null;
  feedProductCtaType?: ProductCtaType | null;
  playerSettings?: string | null;
  appUrl?: string | null;
  live?: boolean | null;
  primaryButton?: CtaButtonConfigInput | null;
  secondaryButton?: CtaButtonConfigInput | null;
  pdpConfig?: PdpConfigInput | null;
  installationCompleted?: boolean | null;
  installStep?: string | null;
  discover?: boolean | null;
  useCaseName?: string | null;
  heroSettings?: string | null;
  sourceProjectId?: string | null;
  emailSettings?: string | null;
  videoCollectionCampaignSettings?: string | null;
  secretVideoSettings?: string | null;
  integrationSettings?: string | null;
};

export type DeleteProjectInput = {
  id: string;
};

export type CreateProjectStepInput = {
  id?: string | null;
  owner?: string | null;
  appKey?: string | null;
  projectId: string;
  videoId?: string | null;
  name: string;
  type?: string | null;
  description?: string | null;
  script?: string | null;
  question?: string | null;
  questionVisible?: boolean | null;
  answerTime?: number | null;
  answers?: Array<AnswerInput | null> | null;
  products?: Array<ProductInput | null> | null;
  productsAppUrl?: string | null;
  videoContain?: boolean | null;
  overlayText?: string | null;
  overlayTextColor?: string | null;
  multipleChoiceSelection?: boolean | null;
  defaultResponses?: Array<AnswerInput | null> | null;
  defaultResponsesEnabled?: boolean | null;
  sideBarButtonsEnabled?: boolean | null;
  feedProductActionButton?: FeedProductActionButtonInput | null;
  feedSidePanelType?: FeedSidePanelType | null;
  feedDescriptionActionButton?: FeedProductActionButtonInput | null;
  feedSidePanelDescription?: string | null;
  productCtaType?: ProductCtaType | null;
};

export type AnswerInput = {
  key?: string | null;
  text?: string | null;
  next?: string | null;
  value?: string | null;
  type?: string | null;
  disabled?: boolean | null;
};

export type ProductInput = {
  id?: string | null;
};

export type FeedProductActionButtonInput = {
  type?: string | null;
  url?: string | null;
  text?: string | null;
  isOpenInANewTab?: boolean | null;
  value?: string | null;
  enabled?: boolean | null;
};

export type ModelprojectStepConditionInput = {
  owner?: ModelStringInput | null;
  appKey?: ModelStringInput | null;
  projectId?: ModelIDInput | null;
  videoId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  type?: ModelStringInput | null;
  description?: ModelStringInput | null;
  script?: ModelStringInput | null;
  question?: ModelStringInput | null;
  questionVisible?: ModelBooleanInput | null;
  answerTime?: ModelIntInput | null;
  productsAppUrl?: ModelStringInput | null;
  videoContain?: ModelBooleanInput | null;
  overlayText?: ModelStringInput | null;
  overlayTextColor?: ModelStringInput | null;
  multipleChoiceSelection?: ModelBooleanInput | null;
  defaultResponsesEnabled?: ModelBooleanInput | null;
  sideBarButtonsEnabled?: ModelBooleanInput | null;
  feedSidePanelType?: ModelFeedSidePanelTypeInput | null;
  feedSidePanelDescription?: ModelStringInput | null;
  productCtaType?: ModelProductCtaTypeInput | null;
  and?: Array<ModelprojectStepConditionInput | null> | null;
  or?: Array<ModelprojectStepConditionInput | null> | null;
  not?: ModelprojectStepConditionInput | null;
};

export type ModelFeedSidePanelTypeInput = {
  eq?: FeedSidePanelType | null;
  ne?: FeedSidePanelType | null;
};

export type UpdateProjectStepInput = {
  id: string;
  owner?: string | null;
  appKey?: string | null;
  projectId?: string | null;
  videoId?: string | null;
  name?: string | null;
  type?: string | null;
  description?: string | null;
  script?: string | null;
  question?: string | null;
  questionVisible?: boolean | null;
  answerTime?: number | null;
  answers?: Array<AnswerInput | null> | null;
  products?: Array<ProductInput | null> | null;
  productsAppUrl?: string | null;
  videoContain?: boolean | null;
  overlayText?: string | null;
  overlayTextColor?: string | null;
  multipleChoiceSelection?: boolean | null;
  defaultResponses?: Array<AnswerInput | null> | null;
  defaultResponsesEnabled?: boolean | null;
  sideBarButtonsEnabled?: boolean | null;
  feedProductActionButton?: FeedProductActionButtonInput | null;
  feedSidePanelType?: FeedSidePanelType | null;
  feedDescriptionActionButton?: FeedProductActionButtonInput | null;
  feedSidePanelDescription?: string | null;
  productCtaType?: ProductCtaType | null;
};

export type DeleteProjectStepInput = {
  id: string;
};

export type CreateUserSettingsInput = {
  id?: string | null;
  owner?: string | null;
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  lastLogin?: string | null;
  source?: string | null;
  token?: string | null;
  appKey?: string | null;
  hasLoggedIn?: boolean | null;
  hasShared?: boolean | null;
  viewedTolstoys?: Array<string | null> | null;
  calendars?: Array<CalendarInput | null> | null;
  emailAutomationTool?: string | null;
  logoSettings?: string | null;
  googleInfo?: string | null;
  crmData?: string | null;
};

export type CalendarInput = {
  id?: string | null;
  name?: string | null;
  url?: string | null;
};

export type ModeluserSettingsConditionInput = {
  owner?: ModelStringInput | null;
  email?: ModelStringInput | null;
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  lastLogin?: ModelStringInput | null;
  source?: ModelStringInput | null;
  token?: ModelStringInput | null;
  appKey?: ModelStringInput | null;
  hasLoggedIn?: ModelBooleanInput | null;
  hasShared?: ModelBooleanInput | null;
  viewedTolstoys?: ModelStringInput | null;
  emailAutomationTool?: ModelStringInput | null;
  logoSettings?: ModelStringInput | null;
  googleInfo?: ModelStringInput | null;
  crmData?: ModelStringInput | null;
  and?: Array<ModeluserSettingsConditionInput | null> | null;
  or?: Array<ModeluserSettingsConditionInput | null> | null;
  not?: ModeluserSettingsConditionInput | null;
};

export type userSettings = {
  __typename: 'userSettings';
  id: string;
  owner?: string | null;
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  lastLogin?: string | null;
  source?: string | null;
  token?: string | null;
  appKey?: string | null;
  hasLoggedIn?: boolean | null;
  hasShared?: boolean | null;
  viewedTolstoys?: Array<string | null> | null;
  calendars?: Array<calendar | null> | null;
  emailAutomationTool?: string | null;
  logoSettings?: string | null;
  googleInfo?: string | null;
  crmData?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type calendar = {
  __typename: 'calendar';
  id?: string | null;
  name?: string | null;
  url?: string | null;
};

export type UpdateUserSettingsInput = {
  id: string;
  owner?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  lastLogin?: string | null;
  source?: string | null;
  token?: string | null;
  appKey?: string | null;
  hasLoggedIn?: boolean | null;
  hasShared?: boolean | null;
  viewedTolstoys?: Array<string | null> | null;
  calendars?: Array<CalendarInput | null> | null;
  emailAutomationTool?: string | null;
  logoSettings?: string | null;
  googleInfo?: string | null;
  crmData?: string | null;
};

export type DeleteUserSettingsInput = {
  id: string;
};

export type CreateAccountSettingsInput = {
  id?: string | null;
  appKey: string;
  owner?: string | null;
  name?: string | null;
  logoSettings?: string | null;
  widgetSettings?: string | null;
  downgradeInfo?: string | null;
  liveIds?: Array<string | null> | null;
  url?: string | null;
  logoUrl?: string | null;
  playerLogoUrl?: string | null;
  companyVertical?: string | null;
  companySize?: string | null;
  companyRole?: string | null;
  monthlyTraffic?: string | null;
  useCase?: string | null;
  packageId?: string | null;
  token?: string | null;
  paymentProvider?: string | null;
  paymentSubscriptions?: Array<string | null> | null;
  paymentIdentifier?: string | null;
  packageGroup?: string | null;
  joinedPackageAt?: string | null;
  trialEndAt?: string | null;
  referral?: string | null;
  crmData?: string | null;
  trialDays?: number | null;
  googleAnalyticsID?: string | null;
  appsInstallationStatuses?: string | null;
  onboardingStatuses?: string | null;
  assetAnalysis?: string | null;
};

export type ModelaccountSettingsConditionInput = {
  appKey?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  name?: ModelStringInput | null;
  logoSettings?: ModelStringInput | null;
  widgetSettings?: ModelStringInput | null;
  downgradeInfo?: ModelStringInput | null;
  liveIds?: ModelStringInput | null;
  url?: ModelStringInput | null;
  logoUrl?: ModelStringInput | null;
  playerLogoUrl?: ModelStringInput | null;
  companyVertical?: ModelStringInput | null;
  companySize?: ModelStringInput | null;
  companyRole?: ModelStringInput | null;
  monthlyTraffic?: ModelStringInput | null;
  useCase?: ModelStringInput | null;
  packageId?: ModelStringInput | null;
  token?: ModelStringInput | null;
  paymentProvider?: ModelStringInput | null;
  paymentSubscriptions?: ModelStringInput | null;
  paymentIdentifier?: ModelStringInput | null;
  packageGroup?: ModelStringInput | null;
  joinedPackageAt?: ModelStringInput | null;
  trialEndAt?: ModelStringInput | null;
  referral?: ModelStringInput | null;
  crmData?: ModelStringInput | null;
  trialDays?: ModelIntInput | null;
  googleAnalyticsID?: ModelStringInput | null;
  appsInstallationStatuses?: ModelStringInput | null;
  onboardingStatuses?: ModelStringInput | null;
  assetAnalysis?: ModelStringInput | null;
  and?: Array<ModelaccountSettingsConditionInput | null> | null;
  or?: Array<ModelaccountSettingsConditionInput | null> | null;
  not?: ModelaccountSettingsConditionInput | null;
};

export type accountSettings = {
  __typename: 'accountSettings';
  id: string;
  appKey: string;
  owner?: string | null;
  name?: string | null;
  logoSettings?: string | null;
  widgetSettings?: string | null;
  downgradeInfo?: string | null;
  liveIds?: Array<string | null> | null;
  url?: string | null;
  logoUrl?: string | null;
  playerLogoUrl?: string | null;
  companyVertical?: string | null;
  companySize?: string | null;
  companyRole?: string | null;
  monthlyTraffic?: string | null;
  useCase?: string | null;
  packageId?: string | null;
  token?: string | null;
  paymentProvider?: string | null;
  paymentSubscriptions?: Array<string | null> | null;
  paymentIdentifier?: string | null;
  packageGroup?: string | null;
  joinedPackageAt?: string | null;
  trialEndAt?: string | null;
  referral?: string | null;
  crmData?: string | null;
  trialDays?: number | null;
  googleAnalyticsID?: string | null;
  appsInstallationStatuses?: string | null;
  onboardingStatuses?: string | null;
  assetAnalysis?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAccountSettingsInput = {
  id: string;
  appKey?: string | null;
  owner?: string | null;
  name?: string | null;
  logoSettings?: string | null;
  widgetSettings?: string | null;
  downgradeInfo?: string | null;
  liveIds?: Array<string | null> | null;
  url?: string | null;
  logoUrl?: string | null;
  playerLogoUrl?: string | null;
  companyVertical?: string | null;
  companySize?: string | null;
  companyRole?: string | null;
  monthlyTraffic?: string | null;
  useCase?: string | null;
  packageId?: string | null;
  token?: string | null;
  paymentProvider?: string | null;
  paymentSubscriptions?: Array<string | null> | null;
  paymentIdentifier?: string | null;
  packageGroup?: string | null;
  joinedPackageAt?: string | null;
  trialEndAt?: string | null;
  referral?: string | null;
  crmData?: string | null;
  trialDays?: number | null;
  googleAnalyticsID?: string | null;
  appsInstallationStatuses?: string | null;
  onboardingStatuses?: string | null;
  assetAnalysis?: string | null;
};

export type DeleteAccountSettingsInput = {
  id: string;
};

export type CreateAppInput = {
  id?: string | null;
  owner?: string | null;
  appKey?: string | null;
  app?: string | null;
  active?: boolean | null;
  data?: string | null;
  token?: string | null;
  appExternalId?: string | null;
  appUrl?: string | null;
  importSettings?: ImportSettings | null;
  importRange?: number | null;
  syncTagsMode?: string | null;
  syndicateBy?: SyndicateBy | null;
  googleAnalyticsID?: string | null;
  settings?: string | null;
  isShopAppEligible?: boolean | null;
  taxonomyId?: number | null;
};

export type ModelappConditionInput = {
  owner?: ModelStringInput | null;
  appKey?: ModelStringInput | null;
  app?: ModelStringInput | null;
  active?: ModelBooleanInput | null;
  data?: ModelStringInput | null;
  token?: ModelStringInput | null;
  appExternalId?: ModelStringInput | null;
  appUrl?: ModelStringInput | null;
  importSettings?: ModelImportSettingsInput | null;
  importRange?: ModelIntInput | null;
  syncTagsMode?: ModelStringInput | null;
  syndicateBy?: ModelSyndicateByInput | null;
  googleAnalyticsID?: ModelStringInput | null;
  settings?: ModelStringInput | null;
  isShopAppEligible?: ModelBooleanInput | null;
  taxonomyId?: ModelIntInput | null;
  and?: Array<ModelappConditionInput | null> | null;
  or?: Array<ModelappConditionInput | null> | null;
  not?: ModelappConditionInput | null;
};

export type ModelImportSettingsInput = {
  eq?: ImportSettings | null;
  ne?: ImportSettings | null;
};

export type ModelSyndicateByInput = {
  eq?: SyndicateBy | null;
  ne?: SyndicateBy | null;
};

export type UpdateAppInput = {
  id: string;
  owner?: string | null;
  appKey?: string | null;
  app?: string | null;
  active?: boolean | null;
  data?: string | null;
  token?: string | null;
  appExternalId?: string | null;
  appUrl?: string | null;
  importSettings?: ImportSettings | null;
  importRange?: number | null;
  syncTagsMode?: string | null;
  syndicateBy?: SyndicateBy | null;
  googleAnalyticsID?: string | null;
  settings?: string | null;
  isShopAppEligible?: boolean | null;
  taxonomyId?: number | null;
};

export type DeleteAppInput = {
  id: string;
};

export type CreateProjectAppInput = {
  id?: string | null;
  appId?: string | null;
  appKey?: string | null;
  projectId?: string | null;
  owner?: string | null;
  active?: boolean | null;
  data?: string | null;
};

export type ModelprojectAppConditionInput = {
  appId?: ModelIDInput | null;
  appKey?: ModelStringInput | null;
  projectId?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  active?: ModelBooleanInput | null;
  data?: ModelStringInput | null;
  and?: Array<ModelprojectAppConditionInput | null> | null;
  or?: Array<ModelprojectAppConditionInput | null> | null;
  not?: ModelprojectAppConditionInput | null;
};

export type projectApp = {
  __typename: 'projectApp';
  id: string;
  appId?: string | null;
  appKey?: string | null;
  projectId?: string | null;
  owner?: string | null;
  active?: boolean | null;
  data?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateProjectAppInput = {
  id: string;
  appId?: string | null;
  appKey?: string | null;
  projectId?: string | null;
  owner?: string | null;
  active?: boolean | null;
  data?: string | null;
};

export type DeleteProjectAppInput = {
  id: string;
};

export type CreateResponseInput = {
  id?: string | null;
  owner?: string | null;
  appKey?: string | null;
  stepName?: string | null;
  projectId?: string | null;
  anonymousId?: string | null;
  sessionId?: string | null;
  type?: string | null;
  value?: string | null;
  email?: string | null;
  name?: string | null;
  phone?: string | null;
  createdAt?: string | null;
  read?: boolean | null;
  collectInfo?: string | null;
  answerType?: string | null;
  parentUrl?: string | null;
  isProfanity?: boolean | null;
  attachments?: Array<AttachmentInput | null> | null;
};

export type AttachmentInput = {
  fileName?: string | null;
  url?: string | null;
  type?: string | null;
};

export type ModelresponseConditionInput = {
  owner?: ModelStringInput | null;
  appKey?: ModelStringInput | null;
  stepName?: ModelStringInput | null;
  projectId?: ModelStringInput | null;
  anonymousId?: ModelStringInput | null;
  sessionId?: ModelStringInput | null;
  type?: ModelStringInput | null;
  value?: ModelStringInput | null;
  email?: ModelStringInput | null;
  name?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  read?: ModelBooleanInput | null;
  collectInfo?: ModelStringInput | null;
  answerType?: ModelStringInput | null;
  parentUrl?: ModelStringInput | null;
  isProfanity?: ModelBooleanInput | null;
  and?: Array<ModelresponseConditionInput | null> | null;
  or?: Array<ModelresponseConditionInput | null> | null;
  not?: ModelresponseConditionInput | null;
};

export type response = {
  __typename: 'response';
  id: string;
  owner?: string | null;
  appKey?: string | null;
  stepName?: string | null;
  projectId?: string | null;
  anonymousId?: string | null;
  sessionId?: string | null;
  type?: string | null;
  value?: string | null;
  email?: string | null;
  name?: string | null;
  phone?: string | null;
  createdAt: string;
  read?: boolean | null;
  collectInfo?: string | null;
  answerType?: string | null;
  parentUrl?: string | null;
  isProfanity?: boolean | null;
  attachments?: Array<attachment | null> | null;
  updatedAt: string;
};

export type attachment = {
  __typename: 'attachment';
  fileName?: string | null;
  url?: string | null;
  type?: string | null;
};

export type UpdateResponseInput = {
  id: string;
  owner?: string | null;
  appKey?: string | null;
  stepName?: string | null;
  projectId?: string | null;
  anonymousId?: string | null;
  sessionId?: string | null;
  type?: string | null;
  value?: string | null;
  email?: string | null;
  name?: string | null;
  phone?: string | null;
  createdAt?: string | null;
  read?: boolean | null;
  collectInfo?: string | null;
  answerType?: string | null;
  parentUrl?: string | null;
  isProfanity?: boolean | null;
  attachments?: Array<AttachmentInput | null> | null;
};

export type DeleteResponseInput = {
  id: string;
};

export type CreateInAppNotificationInput = {
  id?: string | null;
  owner?: string | null;
  teamMemberId?: string | null;
  projectId?: string | null;
  sessionId?: string | null;
  type?: string | null;
  value?: string | null;
  email?: string | null;
  createdAt?: string | null;
  read?: boolean | null;
  expirationUnixTime: number;
  token?: string | null;
  stepName?: string | null;
};

export type ModelinAppNotificationConditionInput = {
  owner?: ModelStringInput | null;
  teamMemberId?: ModelStringInput | null;
  projectId?: ModelStringInput | null;
  sessionId?: ModelStringInput | null;
  type?: ModelStringInput | null;
  value?: ModelStringInput | null;
  email?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  read?: ModelBooleanInput | null;
  expirationUnixTime?: ModelIntInput | null;
  token?: ModelStringInput | null;
  stepName?: ModelStringInput | null;
  and?: Array<ModelinAppNotificationConditionInput | null> | null;
  or?: Array<ModelinAppNotificationConditionInput | null> | null;
  not?: ModelinAppNotificationConditionInput | null;
};

export type inAppNotification = {
  __typename: 'inAppNotification';
  id: string;
  owner?: string | null;
  teamMemberId?: string | null;
  projectId?: string | null;
  sessionId?: string | null;
  type?: string | null;
  value?: string | null;
  email?: string | null;
  createdAt: string;
  read?: boolean | null;
  expirationUnixTime: number;
  token?: string | null;
  stepName?: string | null;
  updatedAt: string;
};

export type UpdateInAppNotificationInput = {
  id: string;
  owner?: string | null;
  teamMemberId?: string | null;
  projectId?: string | null;
  sessionId?: string | null;
  type?: string | null;
  value?: string | null;
  email?: string | null;
  createdAt?: string | null;
  read?: boolean | null;
  expirationUnixTime?: number | null;
  token?: string | null;
  stepName?: string | null;
};

export type DeleteInAppNotificationInput = {
  id: string;
};

export type CreateConferenceRoomConnectionInput = {
  id?: string | null;
};

export type ModelconferenceRoomConnectionConditionInput = {
  and?: Array<ModelconferenceRoomConnectionConditionInput | null> | null;
  or?: Array<ModelconferenceRoomConnectionConditionInput | null> | null;
  not?: ModelconferenceRoomConnectionConditionInput | null;
};

export type conferenceRoomConnection = {
  __typename: 'conferenceRoomConnection';
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateConferenceRoomConnectionInput = {
  id: string;
};

export type DeleteConferenceRoomConnectionInput = {
  id: string;
};

export type CreateConferenceRoomInput = {
  id?: string | null;
  owner: string;
  roomId: string;
  userId: string;
  active?: boolean | null;
  email?: string | null;
  appToken?: string | null;
  lastSeenAt?: string | null;
};

export type ModelconferenceRoomConditionInput = {
  owner?: ModelStringInput | null;
  roomId?: ModelStringInput | null;
  userId?: ModelStringInput | null;
  active?: ModelBooleanInput | null;
  email?: ModelStringInput | null;
  appToken?: ModelStringInput | null;
  lastSeenAt?: ModelStringInput | null;
  and?: Array<ModelconferenceRoomConditionInput | null> | null;
  or?: Array<ModelconferenceRoomConditionInput | null> | null;
  not?: ModelconferenceRoomConditionInput | null;
};

export type conferenceRoom = {
  __typename: 'conferenceRoom';
  id: string;
  owner: string;
  roomId: string;
  userId: string;
  active?: boolean | null;
  email?: string | null;
  appToken?: string | null;
  lastSeenAt?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateConferenceRoomInput = {
  id: string;
  owner?: string | null;
  roomId?: string | null;
  userId?: string | null;
  active?: boolean | null;
  email?: string | null;
  appToken?: string | null;
  lastSeenAt?: string | null;
};

export type DeleteConferenceRoomInput = {
  id: string;
};

export type CreateConferenceRoomActivityInput = {
  id?: string | null;
  roomName?: string | null;
  createdAt?: string | null;
  expirationUnixTime: number;
  updatedAt?: string | null;
  appToken?: string | null;
  type?: string | null;
};

export type ModelconferenceRoomActivityConditionInput = {
  roomName?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  expirationUnixTime?: ModelIntInput | null;
  updatedAt?: ModelStringInput | null;
  appToken?: ModelStringInput | null;
  type?: ModelStringInput | null;
  and?: Array<ModelconferenceRoomActivityConditionInput | null> | null;
  or?: Array<ModelconferenceRoomActivityConditionInput | null> | null;
  not?: ModelconferenceRoomActivityConditionInput | null;
};

export type conferenceRoomActivity = {
  __typename: 'conferenceRoomActivity';
  id: string;
  roomName?: string | null;
  createdAt: string;
  expirationUnixTime: number;
  updatedAt?: string | null;
  appToken?: string | null;
  type?: string | null;
};

export type UpdateConferenceRoomActivityInput = {
  id: string;
  roomName?: string | null;
  createdAt?: string | null;
  expirationUnixTime?: number | null;
  updatedAt?: string | null;
  appToken?: string | null;
  type?: string | null;
};

export type DeleteConferenceRoomActivityInput = {
  id: string;
};

export type CreateSessionInput = {
  id?: string | null;
  owner?: string | null;
  assignee?: string | null;
  appKey?: string | null;
  projectId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  lastEventAt: string;
  sessionTime?: string | null;
  name?: string | null;
  email?: string | null;
  phone?: string | null;
  read?: boolean | null;
  archived?: boolean | null;
  actionNeeded?: boolean | null;
  totalEvents?: number | null;
  totalResponses?: number | null;
  totalReplies?: number | null;
  parentUrl?: string | null;
  videoResponse?: boolean | null;
  imageResponse?: boolean | null;
  audioResponse?: boolean | null;
  textResponse?: boolean | null;
  formResponse?: boolean | null;
  reachedEnd?: boolean | null;
  isSpam?: boolean | null;
};

export type ModelsessionConditionInput = {
  owner?: ModelStringInput | null;
  assignee?: ModelStringInput | null;
  appKey?: ModelStringInput | null;
  projectId?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  lastEventAt?: ModelStringInput | null;
  sessionTime?: ModelStringInput | null;
  name?: ModelStringInput | null;
  email?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  read?: ModelBooleanInput | null;
  archived?: ModelBooleanInput | null;
  actionNeeded?: ModelBooleanInput | null;
  totalEvents?: ModelIntInput | null;
  totalResponses?: ModelIntInput | null;
  totalReplies?: ModelIntInput | null;
  parentUrl?: ModelStringInput | null;
  videoResponse?: ModelBooleanInput | null;
  imageResponse?: ModelBooleanInput | null;
  audioResponse?: ModelBooleanInput | null;
  textResponse?: ModelBooleanInput | null;
  formResponse?: ModelBooleanInput | null;
  reachedEnd?: ModelBooleanInput | null;
  isSpam?: ModelBooleanInput | null;
  and?: Array<ModelsessionConditionInput | null> | null;
  or?: Array<ModelsessionConditionInput | null> | null;
  not?: ModelsessionConditionInput | null;
};

export type session = {
  __typename: 'session';
  id: string;
  owner?: string | null;
  assignee?: string | null;
  appKey?: string | null;
  projectId?: string | null;
  createdAt: string;
  updatedAt: string;
  lastEventAt: string;
  sessionTime?: string | null;
  name?: string | null;
  email?: string | null;
  phone?: string | null;
  read?: boolean | null;
  archived?: boolean | null;
  actionNeeded?: boolean | null;
  totalEvents?: number | null;
  totalResponses?: number | null;
  totalReplies?: number | null;
  parentUrl?: string | null;
  videoResponse?: boolean | null;
  imageResponse?: boolean | null;
  audioResponse?: boolean | null;
  textResponse?: boolean | null;
  formResponse?: boolean | null;
  reachedEnd?: boolean | null;
  isSpam?: boolean | null;
};

export type UpdateSessionInput = {
  id: string;
  owner?: string | null;
  assignee?: string | null;
  appKey?: string | null;
  projectId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  lastEventAt?: string | null;
  sessionTime?: string | null;
  name?: string | null;
  email?: string | null;
  phone?: string | null;
  read?: boolean | null;
  archived?: boolean | null;
  actionNeeded?: boolean | null;
  totalEvents?: number | null;
  totalResponses?: number | null;
  totalReplies?: number | null;
  parentUrl?: string | null;
  videoResponse?: boolean | null;
  imageResponse?: boolean | null;
  audioResponse?: boolean | null;
  textResponse?: boolean | null;
  formResponse?: boolean | null;
  reachedEnd?: boolean | null;
  isSpam?: boolean | null;
};

export type DeleteSessionInput = {
  id: string;
};

export type CreateAccountIdentifiedUsersInput = {
  id?: string | null;
  owner?: string | null;
  appKey: string;
  userId?: string | null;
  email?: string | null;
  phone?: string | null;
  name?: string | null;
  lastSeenAt?: string | null;
  firstSeenAt?: string | null;
  signedUpAt?: string | null;
  sessionCount?: number | null;
  customAttributes?: string | null;
};

export type ModelaccountIdentifiedUsersConditionInput = {
  owner?: ModelStringInput | null;
  appKey?: ModelStringInput | null;
  userId?: ModelStringInput | null;
  email?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  name?: ModelStringInput | null;
  lastSeenAt?: ModelStringInput | null;
  firstSeenAt?: ModelStringInput | null;
  signedUpAt?: ModelStringInput | null;
  sessionCount?: ModelIntInput | null;
  customAttributes?: ModelStringInput | null;
  and?: Array<ModelaccountIdentifiedUsersConditionInput | null> | null;
  or?: Array<ModelaccountIdentifiedUsersConditionInput | null> | null;
  not?: ModelaccountIdentifiedUsersConditionInput | null;
};

export type accountIdentifiedUsers = {
  __typename: 'accountIdentifiedUsers';
  id: string;
  owner?: string | null;
  appKey: string;
  userId?: string | null;
  email?: string | null;
  phone?: string | null;
  name?: string | null;
  lastSeenAt?: string | null;
  firstSeenAt?: string | null;
  signedUpAt?: string | null;
  sessionCount?: number | null;
  customAttributes?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAccountIdentifiedUsersInput = {
  id: string;
  owner?: string | null;
  appKey?: string | null;
  userId?: string | null;
  email?: string | null;
  phone?: string | null;
  name?: string | null;
  lastSeenAt?: string | null;
  firstSeenAt?: string | null;
  signedUpAt?: string | null;
  sessionCount?: number | null;
  customAttributes?: string | null;
};

export type DeleteAccountIdentifiedUsersInput = {
  id: string;
};

export type CreateRuleGroupInput = {
  id?: string | null;
  appKey: string;
  projectId: string;
  publishId?: string | null;
  owner: string;
  rules: Array<Array<RuleInput | null> | null>;
  enabled?: boolean | null;
  visitorModeEnabled?: boolean | null;
  exitIntent?: boolean | null;
  delayTriggerEnabled?: boolean | null;
  delayTriggerSeconds?: number | null;
  showOnDevice?: string | null;
  showOnDomains?: Array<string | null> | null;
  abTestId?: string | null;
  type?: RuleGroupType | null;
};

export type RuleInput = {
  key: string;
  type?: string | null;
  condition?: string | null;
  value?: string | null;
  limit?: string | null;
  behaviors?: Array<string | null> | null;
};

export enum RuleGroupType {
  dynamic = 'dynamic',
  bubble = 'bubble',
}

export type ModelruleGroupConditionInput = {
  appKey?: ModelStringInput | null;
  projectId?: ModelIDInput | null;
  publishId?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  enabled?: ModelBooleanInput | null;
  visitorModeEnabled?: ModelBooleanInput | null;
  exitIntent?: ModelBooleanInput | null;
  delayTriggerEnabled?: ModelBooleanInput | null;
  delayTriggerSeconds?: ModelIntInput | null;
  showOnDevice?: ModelStringInput | null;
  showOnDomains?: ModelStringInput | null;
  abTestId?: ModelIDInput | null;
  type?: ModelRuleGroupTypeInput | null;
  and?: Array<ModelruleGroupConditionInput | null> | null;
  or?: Array<ModelruleGroupConditionInput | null> | null;
  not?: ModelruleGroupConditionInput | null;
};

export type ModelRuleGroupTypeInput = {
  eq?: RuleGroupType | null;
  ne?: RuleGroupType | null;
};

export type ruleGroup = {
  __typename: 'ruleGroup';
  id: string;
  appKey: string;
  projectId: string;
  publishId?: string | null;
  owner: string;
  rules: Array<rule | null>;
  enabled?: boolean | null;
  visitorModeEnabled?: boolean | null;
  exitIntent?: boolean | null;
  delayTriggerEnabled?: boolean | null;
  delayTriggerSeconds?: number | null;
  showOnDevice?: string | null;
  showOnDomains?: Array<string | null> | null;
  abTestId?: string | null;
  type?: RuleGroupType | null;
  createdAt: string;
  updatedAt: string;
};

export type rule = {
  __typename: 'rule';
  key: string;
  type?: string | null;
  condition?: string | null;
  value?: string | null;
  limit?: string | null;
  behaviors?: Array<string | null> | null;
};

export type UpdateRuleGroupInput = {
  id: string;
  appKey?: string | null;
  projectId?: string | null;
  publishId?: string | null;
  owner?: string | null;
  rules?: Array<Array<RuleInput | null> | null> | null;
  enabled?: boolean | null;
  visitorModeEnabled?: boolean | null;
  exitIntent?: boolean | null;
  delayTriggerEnabled?: boolean | null;
  delayTriggerSeconds?: number | null;
  showOnDevice?: string | null;
  showOnDomains?: Array<string | null> | null;
  abTestId?: string | null;
  type?: RuleGroupType | null;
};

export type DeleteRuleGroupInput = {
  id: string;
};

export type CreateIdentifyAttributeInput = {
  id?: string | null;
  appKey: string;
  owner: string;
  key?: string | null;
  type?: string | null;
};

export type ModelidentifyAttributeConditionInput = {
  appKey?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  key?: ModelStringInput | null;
  type?: ModelStringInput | null;
  and?: Array<ModelidentifyAttributeConditionInput | null> | null;
  or?: Array<ModelidentifyAttributeConditionInput | null> | null;
  not?: ModelidentifyAttributeConditionInput | null;
};

export type identifyAttribute = {
  __typename: 'identifyAttribute';
  id: string;
  appKey: string;
  owner: string;
  key?: string | null;
  type?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateIdentifyAttributeInput = {
  id: string;
  appKey?: string | null;
  owner?: string | null;
  key?: string | null;
  type?: string | null;
};

export type DeleteIdentifyAttributeInput = {
  id: string;
};

export type CreateInviteInput = {
  id?: string | null;
  owner: string;
  appKey: string;
  email?: string | null;
  role: Role;
  status: InviteStatus;
  token?: string | null;
  createdAt?: string | null;
};

export enum Role {
  admin = 'admin',
  owner = 'owner',
}

export enum InviteStatus {
  accepted = 'accepted',
  pending = 'pending',
  expired = 'expired',
}

export type ModelinviteConditionInput = {
  owner?: ModelStringInput | null;
  appKey?: ModelStringInput | null;
  email?: ModelStringInput | null;
  role?: ModelRoleInput | null;
  status?: ModelInviteStatusInput | null;
  token?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelinviteConditionInput | null> | null;
  or?: Array<ModelinviteConditionInput | null> | null;
  not?: ModelinviteConditionInput | null;
};

export type ModelRoleInput = {
  eq?: Role | null;
  ne?: Role | null;
};

export type ModelInviteStatusInput = {
  eq?: InviteStatus | null;
  ne?: InviteStatus | null;
};

export type invite = {
  __typename: 'invite';
  id: string;
  owner: string;
  appKey: string;
  email?: string | null;
  role: Role;
  status: InviteStatus;
  token?: string | null;
  createdAt?: string | null;
  updatedAt: string;
};

export type UpdateInviteInput = {
  id: string;
  owner?: string | null;
  appKey?: string | null;
  email?: string | null;
  role?: Role | null;
  status?: InviteStatus | null;
  token?: string | null;
  createdAt?: string | null;
};

export type DeleteInviteInput = {
  id: string;
};

export type CreateAccountTeamMemberInput = {
  id?: string | null;
  owner: string;
  appKey: string;
  email?: string | null;
  role: Role;
  status: InviteStatus;
  inviteToken?: string | null;
  createdAt?: string | null;
  userId?: string | null;
  teamMemberId?: string | null;
  lastLoginAt?: string | null;
  notificationSettingsId?: string | null;
  paymentRole?: PaymentRole | null;
  unreadInAppNotificationsCounter?: number | null;
};

export enum PaymentRole {
  pro = 'pro',
  lite = 'lite',
}

export type ModelaccountTeamMemberConditionInput = {
  owner?: ModelStringInput | null;
  appKey?: ModelStringInput | null;
  email?: ModelStringInput | null;
  role?: ModelRoleInput | null;
  status?: ModelInviteStatusInput | null;
  inviteToken?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  userId?: ModelStringInput | null;
  teamMemberId?: ModelStringInput | null;
  lastLoginAt?: ModelStringInput | null;
  notificationSettingsId?: ModelStringInput | null;
  paymentRole?: ModelPaymentRoleInput | null;
  unreadInAppNotificationsCounter?: ModelIntInput | null;
  and?: Array<ModelaccountTeamMemberConditionInput | null> | null;
  or?: Array<ModelaccountTeamMemberConditionInput | null> | null;
  not?: ModelaccountTeamMemberConditionInput | null;
};

export type ModelPaymentRoleInput = {
  eq?: PaymentRole | null;
  ne?: PaymentRole | null;
};

export type accountTeamMember = {
  __typename: 'accountTeamMember';
  id: string;
  owner: string;
  appKey: string;
  email?: string | null;
  role: Role;
  status: InviteStatus;
  inviteToken?: string | null;
  createdAt?: string | null;
  userId?: string | null;
  teamMemberId?: string | null;
  lastLoginAt?: string | null;
  user?: ModeluserSettingsConnection | null;
  account?: ModelaccountSettingsConnection | null;
  notificationSettingsId?: string | null;
  paymentRole?: PaymentRole | null;
  unreadInAppNotificationsCounter?: number | null;
  updatedAt: string;
};

export type ModeluserSettingsConnection = {
  __typename: 'ModeluserSettingsConnection';
  items: Array<userSettings | null>;
  nextToken?: string | null;
};

export type ModelaccountSettingsConnection = {
  __typename: 'ModelaccountSettingsConnection';
  items: Array<accountSettings | null>;
  nextToken?: string | null;
};

export type UpdateAccountTeamMemberInput = {
  id: string;
  owner?: string | null;
  appKey?: string | null;
  email?: string | null;
  role?: Role | null;
  status?: InviteStatus | null;
  inviteToken?: string | null;
  createdAt?: string | null;
  userId?: string | null;
  teamMemberId?: string | null;
  lastLoginAt?: string | null;
  notificationSettingsId?: string | null;
  paymentRole?: PaymentRole | null;
  unreadInAppNotificationsCounter?: number | null;
};

export type DeleteAccountTeamMemberInput = {
  id: string;
};

export type CreateTeamMemberSettingsInput = {
  id?: string | null;
  teamMemberId?: string | null;
  notificationSettingsId?: string | null;
};

export type ModelteamMemberSettingsConditionInput = {
  teamMemberId?: ModelStringInput | null;
  notificationSettingsId?: ModelStringInput | null;
  and?: Array<ModelteamMemberSettingsConditionInput | null> | null;
  or?: Array<ModelteamMemberSettingsConditionInput | null> | null;
  not?: ModelteamMemberSettingsConditionInput | null;
};

export type teamMemberSettings = {
  __typename: 'teamMemberSettings';
  id: string;
  teamMemberId?: string | null;
  notificationSettingsId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateTeamMemberSettingsInput = {
  id: string;
  teamMemberId?: string | null;
  notificationSettingsId?: string | null;
};

export type DeleteTeamMemberSettingsInput = {
  id: string;
};

export type CreateNotificationSettingsInput = {
  id?: string | null;
  owner: string;
  projectId?: string | null;
  email?: SharedNotificationSettingsInput | null;
  inApp?: SharedNotificationSettingsInput | null;
};

export type SharedNotificationSettingsInput = {
  showAnonymous?: boolean | null;
  enabled?: Array<string | null> | null;
  digest?: Array<string | null> | null;
};

export type ModelnotificationSettingsConditionInput = {
  owner?: ModelStringInput | null;
  projectId?: ModelStringInput | null;
  and?: Array<ModelnotificationSettingsConditionInput | null> | null;
  or?: Array<ModelnotificationSettingsConditionInput | null> | null;
  not?: ModelnotificationSettingsConditionInput | null;
};

export type notificationSettings = {
  __typename: 'notificationSettings';
  id: string;
  owner: string;
  projectId?: string | null;
  email?: sharedNotificationSettings | null;
  inApp?: sharedNotificationSettings | null;
  createdAt: string;
  updatedAt: string;
};

export type sharedNotificationSettings = {
  __typename: 'sharedNotificationSettings';
  showAnonymous?: boolean | null;
  enabled?: Array<string | null> | null;
  digest?: Array<string | null> | null;
};

export type UpdateNotificationSettingsInput = {
  id: string;
  owner?: string | null;
  projectId?: string | null;
  email?: SharedNotificationSettingsInput | null;
  inApp?: SharedNotificationSettingsInput | null;
};

export type DeleteNotificationSettingsInput = {
  id: string;
};

export type CreateFolderInput = {
  id?: string | null;
  owner?: string | null;
  name: string;
  appKey: string;
  private?: boolean | null;
  type?: FolderType | null;
  appUrl?: string | null;
};

export enum FolderType {
  videos = 'videos',
  projects = 'projects',
  onsites = 'onsites',
}

export type ModelfolderConditionInput = {
  owner?: ModelStringInput | null;
  name?: ModelStringInput | null;
  appKey?: ModelStringInput | null;
  private?: ModelBooleanInput | null;
  type?: ModelFolderTypeInput | null;
  appUrl?: ModelStringInput | null;
  and?: Array<ModelfolderConditionInput | null> | null;
  or?: Array<ModelfolderConditionInput | null> | null;
  not?: ModelfolderConditionInput | null;
};

export type ModelFolderTypeInput = {
  eq?: FolderType | null;
  ne?: FolderType | null;
};

export type folder = {
  __typename: 'folder';
  id: string;
  owner?: string | null;
  name: string;
  appKey: string;
  private?: boolean | null;
  type?: FolderType | null;
  appUrl?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateFolderInput = {
  id: string;
  owner?: string | null;
  name?: string | null;
  appKey?: string | null;
  private?: boolean | null;
  type?: FolderType | null;
  appUrl?: string | null;
};

export type DeleteFolderInput = {
  id: string;
};

export type CreateAccountUserInput = {
  id?: string | null;
  appKey: string;
  email?: string | null;
  name?: string | null;
  phone?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  lastSeenAt?: string | null;
  emailVerified?: boolean | null;
  sessionCount?: number | null;
  customAttributes?: string | null;
};

export type ModelaccountUserConditionInput = {
  appKey?: ModelStringInput | null;
  email?: ModelStringInput | null;
  name?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  lastSeenAt?: ModelStringInput | null;
  emailVerified?: ModelBooleanInput | null;
  sessionCount?: ModelIntInput | null;
  customAttributes?: ModelStringInput | null;
  and?: Array<ModelaccountUserConditionInput | null> | null;
  or?: Array<ModelaccountUserConditionInput | null> | null;
  not?: ModelaccountUserConditionInput | null;
};

export type accountUser = {
  __typename: 'accountUser';
  id: string;
  appKey: string;
  email?: string | null;
  name?: string | null;
  phone?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  lastSeenAt?: string | null;
  emailVerified?: boolean | null;
  sessionCount?: number | null;
  customAttributes?: string | null;
};

export type UpdateAccountUserInput = {
  id: string;
  appKey?: string | null;
  email?: string | null;
  name?: string | null;
  phone?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  lastSeenAt?: string | null;
  emailVerified?: boolean | null;
  sessionCount?: number | null;
  customAttributes?: string | null;
};

export type DeleteAccountUserInput = {
  id: string;
};

export type CreateAccountUsageInput = {
  id?: string | null;
  appKey: string;
  featureId: string;
  usage?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelaccountUsageConditionInput = {
  appKey?: ModelStringInput | null;
  featureId?: ModelStringInput | null;
  usage?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelaccountUsageConditionInput | null> | null;
  or?: Array<ModelaccountUsageConditionInput | null> | null;
  not?: ModelaccountUsageConditionInput | null;
};

export type accountUsage = {
  __typename: 'accountUsage';
  id: string;
  appKey: string;
  featureId: string;
  usage?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateAccountUsageInput = {
  id: string;
  appKey?: string | null;
  featureId?: string | null;
  usage?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteAccountUsageInput = {
  id: string;
};

export type CreatePlayerConfigInput = {
  id?: string | null;
  appKey: string;
  publishId: string;
  projectId: string;
  config?: string | null;
};

export type ModelplayerConfigConditionInput = {
  appKey?: ModelStringInput | null;
  publishId?: ModelStringInput | null;
  projectId?: ModelStringInput | null;
  config?: ModelStringInput | null;
  and?: Array<ModelplayerConfigConditionInput | null> | null;
  or?: Array<ModelplayerConfigConditionInput | null> | null;
  not?: ModelplayerConfigConditionInput | null;
};

export type playerConfig = {
  __typename: 'playerConfig';
  id: string;
  appKey: string;
  publishId: string;
  projectId: string;
  config?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdatePlayerConfigInput = {
  id: string;
  appKey?: string | null;
  publishId?: string | null;
  projectId?: string | null;
  config?: string | null;
};

export type DeletePlayerConfigInput = {
  id: string;
};

export type CreateWidgetConfigInput = {
  id?: string | null;
  appKey: string;
  publishId: string;
  projectId: string;
  config?: string | null;
};

export type ModelwidgetConfigConditionInput = {
  appKey?: ModelStringInput | null;
  publishId?: ModelStringInput | null;
  projectId?: ModelStringInput | null;
  config?: ModelStringInput | null;
  and?: Array<ModelwidgetConfigConditionInput | null> | null;
  or?: Array<ModelwidgetConfigConditionInput | null> | null;
  not?: ModelwidgetConfigConditionInput | null;
};

export type widgetConfig = {
  __typename: 'widgetConfig';
  id: string;
  appKey: string;
  publishId: string;
  projectId: string;
  config?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateWidgetConfigInput = {
  id: string;
  appKey?: string | null;
  publishId?: string | null;
  projectId?: string | null;
  config?: string | null;
};

export type DeleteWidgetConfigInput = {
  id: string;
};

export type CreateAccountPackageInput = {
  id?: string | null;
  appKey: string;
  packageId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  app?: TolstoyApp | null;
  featuresPackageId?: string | null;
  group?: string | null;
};

export enum TolstoyApp {
  app = 'app',
  studio = 'studio',
}

export type ModelaccountPackageConditionInput = {
  appKey?: ModelStringInput | null;
  packageId?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  app?: ModelTolstoyAppInput | null;
  featuresPackageId?: ModelStringInput | null;
  group?: ModelStringInput | null;
  and?: Array<ModelaccountPackageConditionInput | null> | null;
  or?: Array<ModelaccountPackageConditionInput | null> | null;
  not?: ModelaccountPackageConditionInput | null;
};

export type ModelTolstoyAppInput = {
  eq?: TolstoyApp | null;
  ne?: TolstoyApp | null;
};

export type accountPackage = {
  __typename: 'accountPackage';
  id: string;
  appKey: string;
  packageId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  app?: TolstoyApp | null;
  featuresPackageId?: string | null;
  group?: string | null;
};

export type UpdateAccountPackageInput = {
  id: string;
  appKey?: string | null;
  packageId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  app?: TolstoyApp | null;
  featuresPackageId?: string | null;
  group?: string | null;
};

export type DeleteAccountPackageInput = {
  id: string;
};

export type CreatePackageInput = {
  id?: string | null;
  name?: string | null;
  description?: string | null;
  stripeProducts?: Array<StripeProductInput | null> | null;
  trialPeriodDays?: number | null;
  maxTrialPeriodDays?: number | null;
  type?: string | null;
  details?: string | null;
  group?: string | null;
  order?: number | null;
  featuresPackageId?: string | null;
  addons?: Array<string | null> | null;
  app?: TolstoyApp | null;
};

export type StripeProductInput = {
  type?: string | null;
  price?: string | null;
};

export type ModelpackageConditionInput = {
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  trialPeriodDays?: ModelIntInput | null;
  maxTrialPeriodDays?: ModelIntInput | null;
  type?: ModelStringInput | null;
  details?: ModelStringInput | null;
  group?: ModelStringInput | null;
  order?: ModelIntInput | null;
  featuresPackageId?: ModelStringInput | null;
  addons?: ModelStringInput | null;
  app?: ModelTolstoyAppInput | null;
  and?: Array<ModelpackageConditionInput | null> | null;
  or?: Array<ModelpackageConditionInput | null> | null;
  not?: ModelpackageConditionInput | null;
};

export type package = {
  __typename: 'package';
  id: string;
  name?: string | null;
  description?: string | null;
  stripeProducts?: Array<stripeProduct | null> | null;
  trialPeriodDays?: number | null;
  maxTrialPeriodDays?: number | null;
  type?: string | null;
  details?: string | null;
  group?: string | null;
  order?: number | null;
  featuresPackageId?: string | null;
  addons?: Array<string | null> | null;
  app?: TolstoyApp | null;
  createdAt: string;
  updatedAt: string;
};

export type stripeProduct = {
  __typename: 'stripeProduct';
  type?: string | null;
  price?: string | null;
};

export type UpdatePackageInput = {
  id: string;
  name?: string | null;
  description?: string | null;
  stripeProducts?: Array<StripeProductInput | null> | null;
  trialPeriodDays?: number | null;
  maxTrialPeriodDays?: number | null;
  type?: string | null;
  details?: string | null;
  group?: string | null;
  order?: number | null;
  featuresPackageId?: string | null;
  addons?: Array<string | null> | null;
  app?: TolstoyApp | null;
};

export type DeletePackageInput = {
  id: string;
};

export type CreateExperimentInput = {
  id?: string | null;
  featureId: string;
  name?: string | null;
  description?: string | null;
  expireAt?: string | null;
  type?: string | null;
  groups?: string | null;
};

export type ModelexperimentConditionInput = {
  featureId?: ModelStringInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  expireAt?: ModelStringInput | null;
  type?: ModelStringInput | null;
  groups?: ModelStringInput | null;
  and?: Array<ModelexperimentConditionInput | null> | null;
  or?: Array<ModelexperimentConditionInput | null> | null;
  not?: ModelexperimentConditionInput | null;
};

export type experiment = {
  __typename: 'experiment';
  id: string;
  featureId: string;
  name?: string | null;
  description?: string | null;
  expireAt?: string | null;
  type?: string | null;
  groups?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateExperimentInput = {
  id: string;
  featureId?: string | null;
  name?: string | null;
  description?: string | null;
  expireAt?: string | null;
  type?: string | null;
  groups?: string | null;
};

export type DeleteExperimentInput = {
  id: string;
};

export type CreatePaymentInput = {
  id?: string | null;
  subscriptionId?: string | null;
  appKey?: string | null;
  reportAt?: string | null;
  usagePrice?: number | null;
  usageQuantity?: number | null;
  type?: string | null;
  provider?: string | null;
  identifier?: string | null;
  packageId?: string | null;
  packageType?: string | null;
  dailyUsagePrice?: number | null;
  aggregatedPrice?: number | null;
  aggregatedUsageQuantity?: number | null;
  dailyUsageQuantity?: number | null;
  seatsPrice?: number | null;
  price?: number | null;
  interval?: string | null;
  numberOfProTeamMembers?: number | null;
  invoiceId?: string | null;
  invoiceItemId?: string | null;
  paymentSubscriptionId?: string | null;
};

export type ModelpaymentConditionInput = {
  subscriptionId?: ModelStringInput | null;
  appKey?: ModelStringInput | null;
  reportAt?: ModelStringInput | null;
  usagePrice?: ModelIntInput | null;
  usageQuantity?: ModelIntInput | null;
  type?: ModelStringInput | null;
  provider?: ModelStringInput | null;
  identifier?: ModelStringInput | null;
  packageId?: ModelStringInput | null;
  packageType?: ModelStringInput | null;
  dailyUsagePrice?: ModelIntInput | null;
  aggregatedPrice?: ModelIntInput | null;
  aggregatedUsageQuantity?: ModelIntInput | null;
  dailyUsageQuantity?: ModelIntInput | null;
  seatsPrice?: ModelIntInput | null;
  price?: ModelIntInput | null;
  interval?: ModelStringInput | null;
  numberOfProTeamMembers?: ModelIntInput | null;
  invoiceId?: ModelStringInput | null;
  invoiceItemId?: ModelStringInput | null;
  paymentSubscriptionId?: ModelIDInput | null;
  and?: Array<ModelpaymentConditionInput | null> | null;
  or?: Array<ModelpaymentConditionInput | null> | null;
  not?: ModelpaymentConditionInput | null;
};

export type payment = {
  __typename: 'payment';
  id: string;
  subscriptionId?: string | null;
  appKey?: string | null;
  reportAt?: string | null;
  usagePrice?: number | null;
  usageQuantity?: number | null;
  type?: string | null;
  provider?: string | null;
  identifier?: string | null;
  packageId?: string | null;
  packageType?: string | null;
  dailyUsagePrice?: number | null;
  aggregatedPrice?: number | null;
  aggregatedUsageQuantity?: number | null;
  dailyUsageQuantity?: number | null;
  seatsPrice?: number | null;
  price?: number | null;
  interval?: string | null;
  numberOfProTeamMembers?: number | null;
  invoiceId?: string | null;
  invoiceItemId?: string | null;
  paymentSubscriptionId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdatePaymentInput = {
  id: string;
  subscriptionId?: string | null;
  appKey?: string | null;
  reportAt?: string | null;
  usagePrice?: number | null;
  usageQuantity?: number | null;
  type?: string | null;
  provider?: string | null;
  identifier?: string | null;
  packageId?: string | null;
  packageType?: string | null;
  dailyUsagePrice?: number | null;
  aggregatedPrice?: number | null;
  aggregatedUsageQuantity?: number | null;
  dailyUsageQuantity?: number | null;
  seatsPrice?: number | null;
  price?: number | null;
  interval?: string | null;
  numberOfProTeamMembers?: number | null;
  invoiceId?: string | null;
  invoiceItemId?: string | null;
  paymentSubscriptionId?: string | null;
};

export type DeletePaymentInput = {
  id: string;
};

export type CreateQuizResultInput = {
  id?: string | null;
  projectId: string;
  appKey?: string | null;
  owner?: string | null;
  createdAt?: string | null;
  url?: string | null;
  title?: string | null;
  imageUrl?: string | null;
  flows?: Array<QuizFlowInput | null> | null;
  isFallback?: boolean | null;
  klaviyoListId?: string | null;
  stepName?: string | null;
  type?: ResultType | null;
};

export type QuizFlowInput = {
  id: string;
  type?: string | null;
  rules?: Array<QuizRuleInput | null> | null;
};

export type QuizRuleInput = {
  stepId: string;
  choices?: Array<string | null> | null;
};

export enum ResultType {
  link = 'link',
  step = 'step',
}

export type ModelquizResultConditionInput = {
  projectId?: ModelIDInput | null;
  appKey?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  url?: ModelStringInput | null;
  title?: ModelStringInput | null;
  imageUrl?: ModelStringInput | null;
  isFallback?: ModelBooleanInput | null;
  klaviyoListId?: ModelStringInput | null;
  stepName?: ModelStringInput | null;
  type?: ModelResultTypeInput | null;
  and?: Array<ModelquizResultConditionInput | null> | null;
  or?: Array<ModelquizResultConditionInput | null> | null;
  not?: ModelquizResultConditionInput | null;
};

export type ModelResultTypeInput = {
  eq?: ResultType | null;
  ne?: ResultType | null;
};

export type quizResult = {
  __typename: 'quizResult';
  id: string;
  projectId: string;
  appKey?: string | null;
  owner?: string | null;
  createdAt?: string | null;
  url?: string | null;
  title?: string | null;
  imageUrl?: string | null;
  flows?: Array<quizFlow | null> | null;
  isFallback?: boolean | null;
  klaviyoListId?: string | null;
  stepName?: string | null;
  type?: ResultType | null;
  updatedAt: string;
};

export type quizFlow = {
  __typename: 'quizFlow';
  id: string;
  type?: string | null;
  rules?: Array<quizRule | null> | null;
};

export type quizRule = {
  __typename: 'quizRule';
  stepId: string;
  choices?: Array<string | null> | null;
};

export type UpdateQuizResultInput = {
  id: string;
  projectId?: string | null;
  appKey?: string | null;
  owner?: string | null;
  createdAt?: string | null;
  url?: string | null;
  title?: string | null;
  imageUrl?: string | null;
  flows?: Array<QuizFlowInput | null> | null;
  isFallback?: boolean | null;
  klaviyoListId?: string | null;
  stepName?: string | null;
  type?: ResultType | null;
};

export type DeleteQuizResultInput = {
  id: string;
};

export type CreateEcomProductInput = {
  id?: string | null;
  productId: string;
  appKey?: string | null;
};

export type ModelecomProductConditionInput = {
  productId?: ModelIDInput | null;
  appKey?: ModelStringInput | null;
  and?: Array<ModelecomProductConditionInput | null> | null;
  or?: Array<ModelecomProductConditionInput | null> | null;
  not?: ModelecomProductConditionInput | null;
};

export type ecomProduct = {
  __typename: 'ecomProduct';
  id: string;
  productId: string;
  appKey?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateEcomProductInput = {
  id: string;
  productId?: string | null;
  appKey?: string | null;
};

export type DeleteEcomProductInput = {
  id: string;
};

export type CreateProductPageSettingsInput = {
  id?: string | null;
  productId?: string | null;
  productUrl?: string | null;
  abTestId?: string | null;
  publishId?: string | null;
  appKey?: string | null;
  widgetType?: WidgetType | null;
  productSource?: ProductSource | null;
};

export enum WidgetType {
  carousel = 'carousel',
  stories = 'stories',
  bubble = 'bubble',
  centeredModal = 'centeredModal',
}

export enum ProductSource {
  shopify = 'shopify',
  magento = 'magento',
  url = 'url',
}

export type ModelproductPageSettingsConditionInput = {
  productId?: ModelStringInput | null;
  productUrl?: ModelStringInput | null;
  abTestId?: ModelStringInput | null;
  publishId?: ModelStringInput | null;
  appKey?: ModelStringInput | null;
  widgetType?: ModelWidgetTypeInput | null;
  productSource?: ModelProductSourceInput | null;
  and?: Array<ModelproductPageSettingsConditionInput | null> | null;
  or?: Array<ModelproductPageSettingsConditionInput | null> | null;
  not?: ModelproductPageSettingsConditionInput | null;
};

export type ModelWidgetTypeInput = {
  eq?: WidgetType | null;
  ne?: WidgetType | null;
};

export type ModelProductSourceInput = {
  eq?: ProductSource | null;
  ne?: ProductSource | null;
};

export type productPageSettings = {
  __typename: 'productPageSettings';
  id: string;
  productId?: string | null;
  productUrl?: string | null;
  abTestId?: string | null;
  publishId?: string | null;
  appKey?: string | null;
  widgetType?: WidgetType | null;
  productSource?: ProductSource | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateProductPageSettingsInput = {
  id: string;
  productId?: string | null;
  productUrl?: string | null;
  abTestId?: string | null;
  publishId?: string | null;
  appKey?: string | null;
  widgetType?: WidgetType | null;
  productSource?: ProductSource | null;
};

export type DeleteProductPageSettingsInput = {
  id: string;
};

export type CreateAbTestInput = {
  id?: string | null;
  appKey?: string | null;
  owner?: string | null;
  options?: Array<AbTestGroupInput | null> | null;
  endAt?: string | null;
  startAt?: string | null;
};

export type AbTestGroupInput = {
  publishId?: string | null;
  probability?: number | null;
};

export type ModelabTestConditionInput = {
  appKey?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  endAt?: ModelStringInput | null;
  startAt?: ModelStringInput | null;
  and?: Array<ModelabTestConditionInput | null> | null;
  or?: Array<ModelabTestConditionInput | null> | null;
  not?: ModelabTestConditionInput | null;
};

export type abTest = {
  __typename: 'abTest';
  id: string;
  appKey?: string | null;
  owner?: string | null;
  options?: Array<abTestGroup | null> | null;
  endAt?: string | null;
  startAt?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type abTestGroup = {
  __typename: 'abTestGroup';
  publishId?: string | null;
  probability?: number | null;
};

export type UpdateAbTestInput = {
  id: string;
  appKey?: string | null;
  owner?: string | null;
  options?: Array<AbTestGroupInput | null> | null;
  endAt?: string | null;
  startAt?: string | null;
};

export type DeleteAbTestInput = {
  id: string;
};

export type CreateIntegrationVodAssetInput = {
  id?: string | null;
  appKey?: string | null;
  owner?: string | null;
  app?: string | null;
  appId?: string | null;
  externalId?: string | null;
  externalCreatedAt?: string | null;
  vodId?: string | null;
  seen?: boolean | null;
  title?: string | null;
  thumbnailUrl?: string | null;
  downloadUrl?: string | null;
  status?: StatusTypes | null;
  createdAt?: string | null;
};

export enum StatusTypes {
  invalid = 'invalid',
  pending = 'pending',
  removed = 'removed',
  imported = 'imported',
}

export type ModelintegrationVodAssetConditionInput = {
  appKey?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  app?: ModelStringInput | null;
  appId?: ModelStringInput | null;
  externalId?: ModelStringInput | null;
  externalCreatedAt?: ModelStringInput | null;
  vodId?: ModelStringInput | null;
  seen?: ModelBooleanInput | null;
  title?: ModelStringInput | null;
  thumbnailUrl?: ModelStringInput | null;
  downloadUrl?: ModelStringInput | null;
  status?: ModelStatusTypesInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelintegrationVodAssetConditionInput | null> | null;
  or?: Array<ModelintegrationVodAssetConditionInput | null> | null;
  not?: ModelintegrationVodAssetConditionInput | null;
};

export type ModelStatusTypesInput = {
  eq?: StatusTypes | null;
  ne?: StatusTypes | null;
};

export type integrationVodAsset = {
  __typename: 'integrationVodAsset';
  id: string;
  appKey?: string | null;
  owner?: string | null;
  app?: string | null;
  appId?: string | null;
  externalId?: string | null;
  externalCreatedAt?: string | null;
  vodId?: string | null;
  seen?: boolean | null;
  title?: string | null;
  thumbnailUrl?: string | null;
  downloadUrl?: string | null;
  status?: StatusTypes | null;
  createdAt?: string | null;
  updatedAt: string;
};

export type UpdateIntegrationVodAssetInput = {
  id: string;
  appKey?: string | null;
  owner?: string | null;
  app?: string | null;
  appId?: string | null;
  externalId?: string | null;
  externalCreatedAt?: string | null;
  vodId?: string | null;
  seen?: boolean | null;
  title?: string | null;
  thumbnailUrl?: string | null;
  downloadUrl?: string | null;
  status?: StatusTypes | null;
  createdAt?: string | null;
};

export type DeleteIntegrationVodAssetInput = {
  id: string;
};

export type CreateVodConnectionInput = {
  id?: string | null;
  appKey?: string | null;
  vodAssetId?: string | null;
  productId?: string | null;
  externalProductId?: string | null;
  collectionId?: string | null;
  provider?: VodConnectionProvider | null;
  tag?: string | null;
  createdAt?: string | null;
  typeKey?: string | null;
  type?: VodConnectionType | null;
  appUrl?: string | null;
  label?: string | null;
  vodLabelId?: string | null;
  vodSource?: string | null;
  vodType?: string | null;
  variantIds?: Array<string | null> | null;
  projectId?: string | null;
  orderIndex?: number | null;
  source?: string | null;
};

export type ModelvodConnectionConditionInput = {
  appKey?: ModelStringInput | null;
  vodAssetId?: ModelIDInput | null;
  productId?: ModelStringInput | null;
  externalProductId?: ModelStringInput | null;
  collectionId?: ModelStringInput | null;
  provider?: ModelVodConnectionProviderInput | null;
  tag?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  typeKey?: ModelStringInput | null;
  type?: ModelVodConnectionTypeInput | null;
  appUrl?: ModelStringInput | null;
  label?: ModelStringInput | null;
  vodLabelId?: ModelStringInput | null;
  vodSource?: ModelStringInput | null;
  vodType?: ModelStringInput | null;
  variantIds?: ModelStringInput | null;
  projectId?: ModelStringInput | null;
  orderIndex?: ModelIntInput | null;
  source?: ModelStringInput | null;
  and?: Array<ModelvodConnectionConditionInput | null> | null;
  or?: Array<ModelvodConnectionConditionInput | null> | null;
  not?: ModelvodConnectionConditionInput | null;
};

export type ModelVodConnectionProviderInput = {
  eq?: VodConnectionProvider | null;
  ne?: VodConnectionProvider | null;
};

export type ModelVodConnectionTypeInput = {
  eq?: VodConnectionType | null;
  ne?: VodConnectionType | null;
};

export type UpdateVodConnectionInput = {
  id: string;
  appKey?: string | null;
  vodAssetId?: string | null;
  productId?: string | null;
  externalProductId?: string | null;
  collectionId?: string | null;
  provider?: VodConnectionProvider | null;
  tag?: string | null;
  createdAt?: string | null;
  typeKey?: string | null;
  type?: VodConnectionType | null;
  appUrl?: string | null;
  label?: string | null;
  vodLabelId?: string | null;
  vodSource?: string | null;
  vodType?: string | null;
  variantIds?: Array<string | null> | null;
  projectId?: string | null;
  orderIndex?: number | null;
  source?: string | null;
};

export type DeleteVodConnectionInput = {
  id: string;
};

export type CreateCustomerInput = {
  id?: string | null;
  appKey: string;
  appUrl: string;
  externalId?: string | null;
  googleId?: string | null;
  anonymousIds?: Array<string | null> | null;
  email: string;
  phone?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  acceptsMarketing?: boolean | null;
};

export type ModelcustomerConditionInput = {
  appKey?: ModelStringInput | null;
  appUrl?: ModelStringInput | null;
  externalId?: ModelStringInput | null;
  googleId?: ModelStringInput | null;
  anonymousIds?: ModelStringInput | null;
  email?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  acceptsMarketing?: ModelBooleanInput | null;
  and?: Array<ModelcustomerConditionInput | null> | null;
  or?: Array<ModelcustomerConditionInput | null> | null;
  not?: ModelcustomerConditionInput | null;
};

export type customer = {
  __typename: 'customer';
  id: string;
  appKey: string;
  appUrl: string;
  externalId?: string | null;
  googleId?: string | null;
  anonymousIds?: Array<string | null> | null;
  email: string;
  phone?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  acceptsMarketing?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCustomerInput = {
  id: string;
  appKey?: string | null;
  appUrl?: string | null;
  externalId?: string | null;
  googleId?: string | null;
  anonymousIds?: Array<string | null> | null;
  email?: string | null;
  phone?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  acceptsMarketing?: boolean | null;
};

export type DeleteCustomerInput = {
  id: string;
};

export type CreateVodLabelInput = {
  id?: string | null;
  appKey: string;
  name: string;
};

export type ModelvodLabelConditionInput = {
  appKey?: ModelStringInput | null;
  name?: ModelStringInput | null;
  and?: Array<ModelvodLabelConditionInput | null> | null;
  or?: Array<ModelvodLabelConditionInput | null> | null;
  not?: ModelvodLabelConditionInput | null;
};

export type vodLabel = {
  __typename: 'vodLabel';
  id: string;
  appKey: string;
  name: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateVodLabelInput = {
  id: string;
  appKey?: string | null;
  name?: string | null;
};

export type DeleteVodLabelInput = {
  id: string;
};

export type CreateSuggestedProductAcceptanceInput = {
  id?: string | null;
  appKey: string;
  vodAssetId: string;
  externalProductId: string;
  accepted: boolean;
};

export type ModelsuggestedProductAcceptanceConditionInput = {
  appKey?: ModelStringInput | null;
  vodAssetId?: ModelIDInput | null;
  externalProductId?: ModelStringInput | null;
  accepted?: ModelBooleanInput | null;
  and?: Array<ModelsuggestedProductAcceptanceConditionInput | null> | null;
  or?: Array<ModelsuggestedProductAcceptanceConditionInput | null> | null;
  not?: ModelsuggestedProductAcceptanceConditionInput | null;
};

export type suggestedProductAcceptance = {
  __typename: 'suggestedProductAcceptance';
  id: string;
  appKey: string;
  vodAssetId: string;
  externalProductId: string;
  accepted: boolean;
  createdAt: string;
  updatedAt: string;
};

export type UpdateSuggestedProductAcceptanceInput = {
  id: string;
  appKey?: string | null;
  vodAssetId?: string | null;
  externalProductId?: string | null;
  accepted?: boolean | null;
};

export type DeleteSuggestedProductAcceptanceInput = {
  id: string;
};

export type CreateCustomerWishListInput = {
  id?: string | null;
  appKey: string;
  appUrl: string;
  vodAssetId: string;
  externalCustomerId?: string | null;
  email?: string | null;
  externalProductId: string;
};

export type ModelcustomerWishListConditionInput = {
  appKey?: ModelStringInput | null;
  appUrl?: ModelStringInput | null;
  vodAssetId?: ModelIDInput | null;
  externalCustomerId?: ModelStringInput | null;
  email?: ModelStringInput | null;
  externalProductId?: ModelStringInput | null;
  and?: Array<ModelcustomerWishListConditionInput | null> | null;
  or?: Array<ModelcustomerWishListConditionInput | null> | null;
  not?: ModelcustomerWishListConditionInput | null;
};

export type customerWishList = {
  __typename: 'customerWishList';
  id: string;
  appKey: string;
  appUrl: string;
  vodAssetId: string;
  externalCustomerId?: string | null;
  email?: string | null;
  externalProductId: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCustomerWishListInput = {
  id: string;
  appKey?: string | null;
  appUrl?: string | null;
  vodAssetId?: string | null;
  externalCustomerId?: string | null;
  email?: string | null;
  externalProductId?: string | null;
};

export type DeleteCustomerWishListInput = {
  id: string;
};

export type CreateVodConnectionSuggestionInput = {
  id?: string | null;
  appKey?: string | null;
  appUrl?: string | null;
  createdAt?: string | null;
  vodAssetId?: string | null;
  externalProductId?: string | null;
  status?: VodConnectionSuggestionStatus | null;
  source?: VodConnectionSuggestionSource | null;
  method?: VodConnectionSuggestionMethod | null;
  methodVersion?: string | null;
  score?: number | null;
};

export enum VodConnectionSuggestionStatus {
  accepted = 'accepted',
  rejected = 'rejected',
  pending = 'pending',
}

export enum VodConnectionSuggestionSource {
  elasticSearchProducts = 'elasticSearchProducts',
}

export enum VodConnectionSuggestionMethod {
  vodAssetToProduct = 'vodAssetToProduct',
  vodAnalysisToProduct = 'vodAnalysisToProduct',
}

export type ModelvodConnectionSuggestionConditionInput = {
  appKey?: ModelStringInput | null;
  appUrl?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  vodAssetId?: ModelIDInput | null;
  externalProductId?: ModelStringInput | null;
  status?: ModelVodConnectionSuggestionStatusInput | null;
  source?: ModelVodConnectionSuggestionSourceInput | null;
  method?: ModelVodConnectionSuggestionMethodInput | null;
  methodVersion?: ModelStringInput | null;
  score?: ModelFloatInput | null;
  and?: Array<ModelvodConnectionSuggestionConditionInput | null> | null;
  or?: Array<ModelvodConnectionSuggestionConditionInput | null> | null;
  not?: ModelvodConnectionSuggestionConditionInput | null;
};

export type ModelVodConnectionSuggestionStatusInput = {
  eq?: VodConnectionSuggestionStatus | null;
  ne?: VodConnectionSuggestionStatus | null;
};

export type ModelVodConnectionSuggestionSourceInput = {
  eq?: VodConnectionSuggestionSource | null;
  ne?: VodConnectionSuggestionSource | null;
};

export type ModelVodConnectionSuggestionMethodInput = {
  eq?: VodConnectionSuggestionMethod | null;
  ne?: VodConnectionSuggestionMethod | null;
};

export type ModelFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type vodConnectionSuggestion = {
  __typename: 'vodConnectionSuggestion';
  id: string;
  appKey?: string | null;
  appUrl?: string | null;
  createdAt?: string | null;
  vodAssetId?: string | null;
  externalProductId?: string | null;
  status?: VodConnectionSuggestionStatus | null;
  source?: VodConnectionSuggestionSource | null;
  method?: VodConnectionSuggestionMethod | null;
  methodVersion?: string | null;
  score?: number | null;
  updatedAt: string;
};

export type UpdateVodConnectionSuggestionInput = {
  id: string;
  appKey?: string | null;
  appUrl?: string | null;
  createdAt?: string | null;
  vodAssetId?: string | null;
  externalProductId?: string | null;
  status?: VodConnectionSuggestionStatus | null;
  source?: VodConnectionSuggestionSource | null;
  method?: VodConnectionSuggestionMethod | null;
  methodVersion?: string | null;
  score?: number | null;
};

export type DeleteVodConnectionSuggestionInput = {
  id: string;
};

export type ModelvodAssetFilterInput = {
  id?: ModelIDInput | null;
  type?: ModelassetTypeInput | null;
  owner?: ModelStringInput | null;
  appKey?: ModelStringInput | null;
  appExternalId?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  isSoundAllowed?: ModelBooleanInput | null;
  status?: ModelStringInput | null;
  uploadType?: ModelStringInput | null;
  duration?: ModelIntInput | null;
  folder?: ModelStringInput | null;
  width?: ModelIntInput | null;
  height?: ModelIntInput | null;
  isVertical?: ModelBooleanInput | null;
  isHDR?: ModelBooleanInput | null;
  trimSettings?: ModelStringInput | null;
  originalVOD?: ModelStringInput | null;
  mirror?: ModelBooleanInput | null;
  recorderVersion?: ModelStringInput | null;
  originalSize?: ModelStringInput | null;
  externalId?: ModelStringInput | null;
  externalProviderData?: ModelStringInput | null;
  isMigratingToShopify?: ModelBooleanInput | null;
  shopifyUploadingStatus?: ModelStringInput | null;
  isEverUsed?: ModelBooleanInput | null;
  captionsSettings?: ModelStringInput | null;
  referenceIds?: ModelStringInput | null;
  analysis?: ModelStringInput | null;
  autoGenerated?: ModelBooleanInput | null;
  and?: Array<ModelvodAssetFilterInput | null> | null;
  or?: Array<ModelvodAssetFilterInput | null> | null;
  not?: ModelvodAssetFilterInput | null;
};

export type ModelvodAssetConnection = {
  __typename: 'ModelvodAssetConnection';
  items: Array<vodAsset | null>;
  nextToken?: string | null;
};

export type ModelvodResponseAssetFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  appKey?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  status?: ModelStringInput | null;
  uploadType?: ModelStringInput | null;
  duration?: ModelIntInput | null;
  width?: ModelIntInput | null;
  height?: ModelIntInput | null;
  isVertical?: ModelBooleanInput | null;
  isHDR?: ModelBooleanInput | null;
  mirror?: ModelBooleanInput | null;
  originalSize?: ModelStringInput | null;
  email?: ModelStringInput | null;
  projectId?: ModelStringInput | null;
  and?: Array<ModelvodResponseAssetFilterInput | null> | null;
  or?: Array<ModelvodResponseAssetFilterInput | null> | null;
  not?: ModelvodResponseAssetFilterInput | null;
};

export type ModelvodResponseAssetConnection = {
  __typename: 'ModelvodResponseAssetConnection';
  items: Array<vodResponseAsset | null>;
  nextToken?: string | null;
};

export type ModelprojectFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  appKey?: ModelStringInput | null;
  typeKey?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  publishId?: ModelStringInput | null;
  name?: ModelStringInput | null;
  folder?: ModelStringInput | null;
  targetPage?: ModelStringInput | null;
  verticalOrientation?: ModelBooleanInput | null;
  widgetPosition?: ModelStringInput | null;
  widgetHeader?: ModelStringInput | null;
  widgetUrls?: ModelStringInput | null;
  description?: ModelStringInput | null;
  emailNotifications?: ModelStringInput | null;
  notificationLevel?: ModelIntInput | null;
  viewerNotificationLevel?: ModelIntInput | null;
  chaptersEnabled?: ModelBooleanInput | null;
  subtitlesEnabled?: ModelBooleanInput | null;
  stepsOrder?: ModelStringInput | null;
  collectText?: ModelStringInput | null;
  collectAfterStep?: ModelStringInput | null;
  cacheLeadFormAnswers?: ModelBooleanInput | null;
  googleAnalyticsID?: ModelStringInput | null;
  facebookAnalyticsID?: ModelStringInput | null;
  totalResponses?: ModelIntInput | null;
  unreadResponses?: ModelIntInput | null;
  tolstoyType?: ModelStringInput | null;
  stepsCount?: ModelIntInput | null;
  widgetBorderColor?: ModelStringInput | null;
  widgetShape?: ModelStringInput | null;
  widgetSize?: ModelStringInput | null;
  widgetNotificationBadge?: ModelBooleanInput | null;
  videoResolutions?: ModelStringInput | null;
  fastForwardEnabled?: ModelBooleanInput | null;
  rulesEnabled?: ModelBooleanInput | null;
  macro?: ModelBooleanInput | null;
  hideChatPlatform?: ModelBooleanInput | null;
  private?: ModelBooleanInput | null;
  chatLandingPage?: ModelBooleanInput | null;
  widgetSettings?: ModelStringInput | null;
  notificationSettingsId?: ModelStringInput | null;
  collectAfterAnyResponse?: ModelBooleanInput | null;
  startScreenText?: ModelStringInput | null;
  feed?: ModelBooleanInput | null;
  feedAutoScroll?: ModelBooleanInput | null;
  feedShowUnviewedStepsFirst?: ModelBooleanInput | null;
  language?: ModelStringInput | null;
  showButtonsIndex?: ModelBooleanInput | null;
  publishMethod?: ModelpublishMethodOptionsInput | null;
  dynamic?: ModelBooleanInput | null;
  feedProductCtaType?: ModelProductCtaTypeInput | null;
  playerSettings?: ModelStringInput | null;
  appUrl?: ModelStringInput | null;
  live?: ModelBooleanInput | null;
  installationCompleted?: ModelBooleanInput | null;
  installStep?: ModelStringInput | null;
  discover?: ModelBooleanInput | null;
  useCaseName?: ModelStringInput | null;
  heroSettings?: ModelStringInput | null;
  sourceProjectId?: ModelIDInput | null;
  emailSettings?: ModelStringInput | null;
  videoCollectionCampaignSettings?: ModelStringInput | null;
  secretVideoSettings?: ModelStringInput | null;
  integrationSettings?: ModelStringInput | null;
  and?: Array<ModelprojectFilterInput | null> | null;
  or?: Array<ModelprojectFilterInput | null> | null;
  not?: ModelprojectFilterInput | null;
};

export type ModelprojectConnection = {
  __typename: 'ModelprojectConnection';
  items: Array<project | null>;
  nextToken?: string | null;
};

export type ModelprojectStepFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  appKey?: ModelStringInput | null;
  projectId?: ModelIDInput | null;
  videoId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  type?: ModelStringInput | null;
  description?: ModelStringInput | null;
  script?: ModelStringInput | null;
  question?: ModelStringInput | null;
  questionVisible?: ModelBooleanInput | null;
  answerTime?: ModelIntInput | null;
  productsAppUrl?: ModelStringInput | null;
  videoContain?: ModelBooleanInput | null;
  overlayText?: ModelStringInput | null;
  overlayTextColor?: ModelStringInput | null;
  multipleChoiceSelection?: ModelBooleanInput | null;
  defaultResponsesEnabled?: ModelBooleanInput | null;
  sideBarButtonsEnabled?: ModelBooleanInput | null;
  feedSidePanelType?: ModelFeedSidePanelTypeInput | null;
  feedSidePanelDescription?: ModelStringInput | null;
  productCtaType?: ModelProductCtaTypeInput | null;
  and?: Array<ModelprojectStepFilterInput | null> | null;
  or?: Array<ModelprojectStepFilterInput | null> | null;
  not?: ModelprojectStepFilterInput | null;
};

export type ModeluserSettingsFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  email?: ModelStringInput | null;
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  lastLogin?: ModelStringInput | null;
  source?: ModelStringInput | null;
  token?: ModelStringInput | null;
  appKey?: ModelStringInput | null;
  hasLoggedIn?: ModelBooleanInput | null;
  hasShared?: ModelBooleanInput | null;
  viewedTolstoys?: ModelStringInput | null;
  emailAutomationTool?: ModelStringInput | null;
  logoSettings?: ModelStringInput | null;
  googleInfo?: ModelStringInput | null;
  crmData?: ModelStringInput | null;
  and?: Array<ModeluserSettingsFilterInput | null> | null;
  or?: Array<ModeluserSettingsFilterInput | null> | null;
  not?: ModeluserSettingsFilterInput | null;
};

export type ModelaccountSettingsFilterInput = {
  id?: ModelIDInput | null;
  appKey?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  name?: ModelStringInput | null;
  logoSettings?: ModelStringInput | null;
  widgetSettings?: ModelStringInput | null;
  downgradeInfo?: ModelStringInput | null;
  liveIds?: ModelStringInput | null;
  url?: ModelStringInput | null;
  logoUrl?: ModelStringInput | null;
  playerLogoUrl?: ModelStringInput | null;
  companyVertical?: ModelStringInput | null;
  companySize?: ModelStringInput | null;
  companyRole?: ModelStringInput | null;
  monthlyTraffic?: ModelStringInput | null;
  useCase?: ModelStringInput | null;
  packageId?: ModelStringInput | null;
  token?: ModelStringInput | null;
  paymentProvider?: ModelStringInput | null;
  paymentSubscriptions?: ModelStringInput | null;
  paymentIdentifier?: ModelStringInput | null;
  packageGroup?: ModelStringInput | null;
  joinedPackageAt?: ModelStringInput | null;
  trialEndAt?: ModelStringInput | null;
  referral?: ModelStringInput | null;
  crmData?: ModelStringInput | null;
  trialDays?: ModelIntInput | null;
  googleAnalyticsID?: ModelStringInput | null;
  appsInstallationStatuses?: ModelStringInput | null;
  onboardingStatuses?: ModelStringInput | null;
  assetAnalysis?: ModelStringInput | null;
  and?: Array<ModelaccountSettingsFilterInput | null> | null;
  or?: Array<ModelaccountSettingsFilterInput | null> | null;
  not?: ModelaccountSettingsFilterInput | null;
};

export type ModelappFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  appKey?: ModelStringInput | null;
  app?: ModelStringInput | null;
  active?: ModelBooleanInput | null;
  data?: ModelStringInput | null;
  token?: ModelStringInput | null;
  appExternalId?: ModelStringInput | null;
  appUrl?: ModelStringInput | null;
  importSettings?: ModelImportSettingsInput | null;
  importRange?: ModelIntInput | null;
  syncTagsMode?: ModelStringInput | null;
  syndicateBy?: ModelSyndicateByInput | null;
  googleAnalyticsID?: ModelStringInput | null;
  settings?: ModelStringInput | null;
  isShopAppEligible?: ModelBooleanInput | null;
  taxonomyId?: ModelIntInput | null;
  and?: Array<ModelappFilterInput | null> | null;
  or?: Array<ModelappFilterInput | null> | null;
  not?: ModelappFilterInput | null;
};

export type ModelappConnection = {
  __typename: 'ModelappConnection';
  items: Array<app | null>;
  nextToken?: string | null;
};

export type ModelprojectAppFilterInput = {
  id?: ModelIDInput | null;
  appId?: ModelIDInput | null;
  appKey?: ModelStringInput | null;
  projectId?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  active?: ModelBooleanInput | null;
  data?: ModelStringInput | null;
  and?: Array<ModelprojectAppFilterInput | null> | null;
  or?: Array<ModelprojectAppFilterInput | null> | null;
  not?: ModelprojectAppFilterInput | null;
};

export type ModelprojectAppConnection = {
  __typename: 'ModelprojectAppConnection';
  items: Array<projectApp | null>;
  nextToken?: string | null;
};

export type ModelresponseFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  appKey?: ModelStringInput | null;
  stepName?: ModelStringInput | null;
  projectId?: ModelStringInput | null;
  anonymousId?: ModelStringInput | null;
  sessionId?: ModelStringInput | null;
  type?: ModelStringInput | null;
  value?: ModelStringInput | null;
  email?: ModelStringInput | null;
  name?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  read?: ModelBooleanInput | null;
  collectInfo?: ModelStringInput | null;
  answerType?: ModelStringInput | null;
  parentUrl?: ModelStringInput | null;
  isProfanity?: ModelBooleanInput | null;
  and?: Array<ModelresponseFilterInput | null> | null;
  or?: Array<ModelresponseFilterInput | null> | null;
  not?: ModelresponseFilterInput | null;
};

export type ModelresponseConnection = {
  __typename: 'ModelresponseConnection';
  items: Array<response | null>;
  nextToken?: string | null;
};

export type ModelinAppNotificationFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  teamMemberId?: ModelStringInput | null;
  projectId?: ModelStringInput | null;
  sessionId?: ModelStringInput | null;
  type?: ModelStringInput | null;
  value?: ModelStringInput | null;
  email?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  read?: ModelBooleanInput | null;
  expirationUnixTime?: ModelIntInput | null;
  token?: ModelStringInput | null;
  stepName?: ModelStringInput | null;
  and?: Array<ModelinAppNotificationFilterInput | null> | null;
  or?: Array<ModelinAppNotificationFilterInput | null> | null;
  not?: ModelinAppNotificationFilterInput | null;
};

export type ModelinAppNotificationConnection = {
  __typename: 'ModelinAppNotificationConnection';
  items: Array<inAppNotification | null>;
  nextToken?: string | null;
};

export type ModelconferenceRoomConnectionFilterInput = {
  id?: ModelIDInput | null;
  and?: Array<ModelconferenceRoomConnectionFilterInput | null> | null;
  or?: Array<ModelconferenceRoomConnectionFilterInput | null> | null;
  not?: ModelconferenceRoomConnectionFilterInput | null;
};

export type ModelconferenceRoomConnectionConnection = {
  __typename: 'ModelconferenceRoomConnectionConnection';
  items: Array<conferenceRoomConnection | null>;
  nextToken?: string | null;
};

export type ModelconferenceRoomFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  roomId?: ModelStringInput | null;
  userId?: ModelStringInput | null;
  active?: ModelBooleanInput | null;
  email?: ModelStringInput | null;
  appToken?: ModelStringInput | null;
  lastSeenAt?: ModelStringInput | null;
  and?: Array<ModelconferenceRoomFilterInput | null> | null;
  or?: Array<ModelconferenceRoomFilterInput | null> | null;
  not?: ModelconferenceRoomFilterInput | null;
};

export type ModelconferenceRoomConnection = {
  __typename: 'ModelconferenceRoomConnection';
  items: Array<conferenceRoom | null>;
  nextToken?: string | null;
};

export type ModelconferenceRoomActivityFilterInput = {
  id?: ModelIDInput | null;
  roomName?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  expirationUnixTime?: ModelIntInput | null;
  updatedAt?: ModelStringInput | null;
  appToken?: ModelStringInput | null;
  type?: ModelStringInput | null;
  and?: Array<ModelconferenceRoomActivityFilterInput | null> | null;
  or?: Array<ModelconferenceRoomActivityFilterInput | null> | null;
  not?: ModelconferenceRoomActivityFilterInput | null;
};

export type ModelconferenceRoomActivityConnection = {
  __typename: 'ModelconferenceRoomActivityConnection';
  items: Array<conferenceRoomActivity | null>;
  nextToken?: string | null;
};

export type ModelsessionFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  assignee?: ModelStringInput | null;
  appKey?: ModelStringInput | null;
  projectId?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  lastEventAt?: ModelStringInput | null;
  sessionTime?: ModelStringInput | null;
  name?: ModelStringInput | null;
  email?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  read?: ModelBooleanInput | null;
  archived?: ModelBooleanInput | null;
  actionNeeded?: ModelBooleanInput | null;
  totalEvents?: ModelIntInput | null;
  totalResponses?: ModelIntInput | null;
  totalReplies?: ModelIntInput | null;
  parentUrl?: ModelStringInput | null;
  videoResponse?: ModelBooleanInput | null;
  imageResponse?: ModelBooleanInput | null;
  audioResponse?: ModelBooleanInput | null;
  textResponse?: ModelBooleanInput | null;
  formResponse?: ModelBooleanInput | null;
  reachedEnd?: ModelBooleanInput | null;
  isSpam?: ModelBooleanInput | null;
  and?: Array<ModelsessionFilterInput | null> | null;
  or?: Array<ModelsessionFilterInput | null> | null;
  not?: ModelsessionFilterInput | null;
};

export type ModelsessionConnection = {
  __typename: 'ModelsessionConnection';
  items: Array<session | null>;
  nextToken?: string | null;
};

export type ModelaccountIdentifiedUsersFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  appKey?: ModelStringInput | null;
  userId?: ModelStringInput | null;
  email?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  name?: ModelStringInput | null;
  lastSeenAt?: ModelStringInput | null;
  firstSeenAt?: ModelStringInput | null;
  signedUpAt?: ModelStringInput | null;
  sessionCount?: ModelIntInput | null;
  customAttributes?: ModelStringInput | null;
  and?: Array<ModelaccountIdentifiedUsersFilterInput | null> | null;
  or?: Array<ModelaccountIdentifiedUsersFilterInput | null> | null;
  not?: ModelaccountIdentifiedUsersFilterInput | null;
};

export type ModelaccountIdentifiedUsersConnection = {
  __typename: 'ModelaccountIdentifiedUsersConnection';
  items: Array<accountIdentifiedUsers | null>;
  nextToken?: string | null;
};

export type ModelruleGroupFilterInput = {
  id?: ModelIDInput | null;
  appKey?: ModelStringInput | null;
  projectId?: ModelIDInput | null;
  publishId?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  enabled?: ModelBooleanInput | null;
  visitorModeEnabled?: ModelBooleanInput | null;
  exitIntent?: ModelBooleanInput | null;
  delayTriggerEnabled?: ModelBooleanInput | null;
  delayTriggerSeconds?: ModelIntInput | null;
  showOnDevice?: ModelStringInput | null;
  showOnDomains?: ModelStringInput | null;
  abTestId?: ModelIDInput | null;
  type?: ModelRuleGroupTypeInput | null;
  and?: Array<ModelruleGroupFilterInput | null> | null;
  or?: Array<ModelruleGroupFilterInput | null> | null;
  not?: ModelruleGroupFilterInput | null;
};

export type ModelruleGroupConnection = {
  __typename: 'ModelruleGroupConnection';
  items: Array<ruleGroup | null>;
  nextToken?: string | null;
};

export type ModelidentifyAttributeFilterInput = {
  id?: ModelIDInput | null;
  appKey?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  key?: ModelStringInput | null;
  type?: ModelStringInput | null;
  and?: Array<ModelidentifyAttributeFilterInput | null> | null;
  or?: Array<ModelidentifyAttributeFilterInput | null> | null;
  not?: ModelidentifyAttributeFilterInput | null;
};

export type ModelidentifyAttributeConnection = {
  __typename: 'ModelidentifyAttributeConnection';
  items: Array<identifyAttribute | null>;
  nextToken?: string | null;
};

export type ModelinviteFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  appKey?: ModelStringInput | null;
  email?: ModelStringInput | null;
  role?: ModelRoleInput | null;
  status?: ModelInviteStatusInput | null;
  token?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelinviteFilterInput | null> | null;
  or?: Array<ModelinviteFilterInput | null> | null;
  not?: ModelinviteFilterInput | null;
};

export type ModelinviteConnection = {
  __typename: 'ModelinviteConnection';
  items: Array<invite | null>;
  nextToken?: string | null;
};

export type ModelaccountTeamMemberFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  appKey?: ModelStringInput | null;
  email?: ModelStringInput | null;
  role?: ModelRoleInput | null;
  status?: ModelInviteStatusInput | null;
  inviteToken?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  userId?: ModelStringInput | null;
  teamMemberId?: ModelStringInput | null;
  lastLoginAt?: ModelStringInput | null;
  notificationSettingsId?: ModelStringInput | null;
  paymentRole?: ModelPaymentRoleInput | null;
  unreadInAppNotificationsCounter?: ModelIntInput | null;
  and?: Array<ModelaccountTeamMemberFilterInput | null> | null;
  or?: Array<ModelaccountTeamMemberFilterInput | null> | null;
  not?: ModelaccountTeamMemberFilterInput | null;
};

export type ModelaccountTeamMemberConnection = {
  __typename: 'ModelaccountTeamMemberConnection';
  items: Array<accountTeamMember | null>;
  nextToken?: string | null;
};

export type ModelteamMemberSettingsFilterInput = {
  id?: ModelIDInput | null;
  teamMemberId?: ModelStringInput | null;
  notificationSettingsId?: ModelStringInput | null;
  and?: Array<ModelteamMemberSettingsFilterInput | null> | null;
  or?: Array<ModelteamMemberSettingsFilterInput | null> | null;
  not?: ModelteamMemberSettingsFilterInput | null;
};

export type ModelteamMemberSettingsConnection = {
  __typename: 'ModelteamMemberSettingsConnection';
  items: Array<teamMemberSettings | null>;
  nextToken?: string | null;
};

export type ModelnotificationSettingsFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  projectId?: ModelStringInput | null;
  and?: Array<ModelnotificationSettingsFilterInput | null> | null;
  or?: Array<ModelnotificationSettingsFilterInput | null> | null;
  not?: ModelnotificationSettingsFilterInput | null;
};

export type ModelnotificationSettingsConnection = {
  __typename: 'ModelnotificationSettingsConnection';
  items: Array<notificationSettings | null>;
  nextToken?: string | null;
};

export type ModelfolderFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  name?: ModelStringInput | null;
  appKey?: ModelStringInput | null;
  private?: ModelBooleanInput | null;
  type?: ModelFolderTypeInput | null;
  appUrl?: ModelStringInput | null;
  and?: Array<ModelfolderFilterInput | null> | null;
  or?: Array<ModelfolderFilterInput | null> | null;
  not?: ModelfolderFilterInput | null;
};

export type ModelfolderConnection = {
  __typename: 'ModelfolderConnection';
  items: Array<folder | null>;
  nextToken?: string | null;
};

export type ModelaccountUserFilterInput = {
  id?: ModelIDInput | null;
  appKey?: ModelStringInput | null;
  email?: ModelStringInput | null;
  name?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  lastSeenAt?: ModelStringInput | null;
  emailVerified?: ModelBooleanInput | null;
  sessionCount?: ModelIntInput | null;
  customAttributes?: ModelStringInput | null;
  and?: Array<ModelaccountUserFilterInput | null> | null;
  or?: Array<ModelaccountUserFilterInput | null> | null;
  not?: ModelaccountUserFilterInput | null;
};

export type ModelaccountUserConnection = {
  __typename: 'ModelaccountUserConnection';
  items: Array<accountUser | null>;
  nextToken?: string | null;
};

export type ModelaccountUsageFilterInput = {
  id?: ModelIDInput | null;
  appKey?: ModelStringInput | null;
  featureId?: ModelStringInput | null;
  usage?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelaccountUsageFilterInput | null> | null;
  or?: Array<ModelaccountUsageFilterInput | null> | null;
  not?: ModelaccountUsageFilterInput | null;
};

export type ModelaccountUsageConnection = {
  __typename: 'ModelaccountUsageConnection';
  items: Array<accountUsage | null>;
  nextToken?: string | null;
};

export type ModelplayerConfigFilterInput = {
  id?: ModelIDInput | null;
  appKey?: ModelStringInput | null;
  publishId?: ModelStringInput | null;
  projectId?: ModelStringInput | null;
  config?: ModelStringInput | null;
  and?: Array<ModelplayerConfigFilterInput | null> | null;
  or?: Array<ModelplayerConfigFilterInput | null> | null;
  not?: ModelplayerConfigFilterInput | null;
};

export type ModelplayerConfigConnection = {
  __typename: 'ModelplayerConfigConnection';
  items: Array<playerConfig | null>;
  nextToken?: string | null;
};

export type ModelwidgetConfigFilterInput = {
  id?: ModelIDInput | null;
  appKey?: ModelStringInput | null;
  publishId?: ModelStringInput | null;
  projectId?: ModelStringInput | null;
  config?: ModelStringInput | null;
  and?: Array<ModelwidgetConfigFilterInput | null> | null;
  or?: Array<ModelwidgetConfigFilterInput | null> | null;
  not?: ModelwidgetConfigFilterInput | null;
};

export type ModelwidgetConfigConnection = {
  __typename: 'ModelwidgetConfigConnection';
  items: Array<widgetConfig | null>;
  nextToken?: string | null;
};

export type ModelaccountPackageFilterInput = {
  id?: ModelIDInput | null;
  appKey?: ModelStringInput | null;
  packageId?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  app?: ModelTolstoyAppInput | null;
  featuresPackageId?: ModelStringInput | null;
  group?: ModelStringInput | null;
  and?: Array<ModelaccountPackageFilterInput | null> | null;
  or?: Array<ModelaccountPackageFilterInput | null> | null;
  not?: ModelaccountPackageFilterInput | null;
};

export type ModelaccountPackageConnection = {
  __typename: 'ModelaccountPackageConnection';
  items: Array<accountPackage | null>;
  nextToken?: string | null;
};

export type ModelpackageFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  trialPeriodDays?: ModelIntInput | null;
  maxTrialPeriodDays?: ModelIntInput | null;
  type?: ModelStringInput | null;
  details?: ModelStringInput | null;
  group?: ModelStringInput | null;
  order?: ModelIntInput | null;
  featuresPackageId?: ModelStringInput | null;
  addons?: ModelStringInput | null;
  app?: ModelTolstoyAppInput | null;
  and?: Array<ModelpackageFilterInput | null> | null;
  or?: Array<ModelpackageFilterInput | null> | null;
  not?: ModelpackageFilterInput | null;
};

export type ModelpackageConnection = {
  __typename: 'ModelpackageConnection';
  items: Array<package | null>;
  nextToken?: string | null;
};

export type ModelexperimentFilterInput = {
  id?: ModelIDInput | null;
  featureId?: ModelStringInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  expireAt?: ModelStringInput | null;
  type?: ModelStringInput | null;
  groups?: ModelStringInput | null;
  and?: Array<ModelexperimentFilterInput | null> | null;
  or?: Array<ModelexperimentFilterInput | null> | null;
  not?: ModelexperimentFilterInput | null;
};

export type ModelexperimentConnection = {
  __typename: 'ModelexperimentConnection';
  items: Array<experiment | null>;
  nextToken?: string | null;
};

export type ModelpaymentFilterInput = {
  id?: ModelIDInput | null;
  subscriptionId?: ModelStringInput | null;
  appKey?: ModelStringInput | null;
  reportAt?: ModelStringInput | null;
  usagePrice?: ModelIntInput | null;
  usageQuantity?: ModelIntInput | null;
  type?: ModelStringInput | null;
  provider?: ModelStringInput | null;
  identifier?: ModelStringInput | null;
  packageId?: ModelStringInput | null;
  packageType?: ModelStringInput | null;
  dailyUsagePrice?: ModelIntInput | null;
  aggregatedPrice?: ModelIntInput | null;
  aggregatedUsageQuantity?: ModelIntInput | null;
  dailyUsageQuantity?: ModelIntInput | null;
  seatsPrice?: ModelIntInput | null;
  price?: ModelIntInput | null;
  interval?: ModelStringInput | null;
  numberOfProTeamMembers?: ModelIntInput | null;
  invoiceId?: ModelStringInput | null;
  invoiceItemId?: ModelStringInput | null;
  paymentSubscriptionId?: ModelIDInput | null;
  and?: Array<ModelpaymentFilterInput | null> | null;
  or?: Array<ModelpaymentFilterInput | null> | null;
  not?: ModelpaymentFilterInput | null;
};

export type ModelpaymentConnection = {
  __typename: 'ModelpaymentConnection';
  items: Array<payment | null>;
  nextToken?: string | null;
};

export type ModelquizResultFilterInput = {
  id?: ModelIDInput | null;
  projectId?: ModelIDInput | null;
  appKey?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  url?: ModelStringInput | null;
  title?: ModelStringInput | null;
  imageUrl?: ModelStringInput | null;
  isFallback?: ModelBooleanInput | null;
  klaviyoListId?: ModelStringInput | null;
  stepName?: ModelStringInput | null;
  type?: ModelResultTypeInput | null;
  and?: Array<ModelquizResultFilterInput | null> | null;
  or?: Array<ModelquizResultFilterInput | null> | null;
  not?: ModelquizResultFilterInput | null;
};

export type ModelquizResultConnection = {
  __typename: 'ModelquizResultConnection';
  items: Array<quizResult | null>;
  nextToken?: string | null;
};

export type ModelecomProductFilterInput = {
  id?: ModelIDInput | null;
  productId?: ModelIDInput | null;
  appKey?: ModelStringInput | null;
  and?: Array<ModelecomProductFilterInput | null> | null;
  or?: Array<ModelecomProductFilterInput | null> | null;
  not?: ModelecomProductFilterInput | null;
};

export type ModelecomProductConnection = {
  __typename: 'ModelecomProductConnection';
  items: Array<ecomProduct | null>;
  nextToken?: string | null;
};

export type ModelproductPageSettingsFilterInput = {
  id?: ModelIDInput | null;
  productId?: ModelStringInput | null;
  productUrl?: ModelStringInput | null;
  abTestId?: ModelStringInput | null;
  publishId?: ModelStringInput | null;
  appKey?: ModelStringInput | null;
  widgetType?: ModelWidgetTypeInput | null;
  productSource?: ModelProductSourceInput | null;
  and?: Array<ModelproductPageSettingsFilterInput | null> | null;
  or?: Array<ModelproductPageSettingsFilterInput | null> | null;
  not?: ModelproductPageSettingsFilterInput | null;
};

export type ModelproductPageSettingsConnection = {
  __typename: 'ModelproductPageSettingsConnection';
  items: Array<productPageSettings | null>;
  nextToken?: string | null;
};

export type ModelabTestFilterInput = {
  id?: ModelIDInput | null;
  appKey?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  endAt?: ModelStringInput | null;
  startAt?: ModelStringInput | null;
  and?: Array<ModelabTestFilterInput | null> | null;
  or?: Array<ModelabTestFilterInput | null> | null;
  not?: ModelabTestFilterInput | null;
};

export type ModelabTestConnection = {
  __typename: 'ModelabTestConnection';
  items: Array<abTest | null>;
  nextToken?: string | null;
};

export type ModelintegrationVodAssetFilterInput = {
  id?: ModelIDInput | null;
  appKey?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  app?: ModelStringInput | null;
  appId?: ModelStringInput | null;
  externalId?: ModelStringInput | null;
  externalCreatedAt?: ModelStringInput | null;
  vodId?: ModelStringInput | null;
  seen?: ModelBooleanInput | null;
  title?: ModelStringInput | null;
  thumbnailUrl?: ModelStringInput | null;
  downloadUrl?: ModelStringInput | null;
  status?: ModelStatusTypesInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelintegrationVodAssetFilterInput | null> | null;
  or?: Array<ModelintegrationVodAssetFilterInput | null> | null;
  not?: ModelintegrationVodAssetFilterInput | null;
};

export type ModelintegrationVodAssetConnection = {
  __typename: 'ModelintegrationVodAssetConnection';
  items: Array<integrationVodAsset | null>;
  nextToken?: string | null;
};

export type ModelvodConnectionFilterInput = {
  id?: ModelIDInput | null;
  appKey?: ModelStringInput | null;
  vodAssetId?: ModelIDInput | null;
  productId?: ModelStringInput | null;
  externalProductId?: ModelStringInput | null;
  collectionId?: ModelStringInput | null;
  provider?: ModelVodConnectionProviderInput | null;
  tag?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  typeKey?: ModelStringInput | null;
  type?: ModelVodConnectionTypeInput | null;
  appUrl?: ModelStringInput | null;
  label?: ModelStringInput | null;
  vodLabelId?: ModelStringInput | null;
  vodSource?: ModelStringInput | null;
  vodType?: ModelStringInput | null;
  variantIds?: ModelStringInput | null;
  projectId?: ModelStringInput | null;
  orderIndex?: ModelIntInput | null;
  source?: ModelStringInput | null;
  and?: Array<ModelvodConnectionFilterInput | null> | null;
  or?: Array<ModelvodConnectionFilterInput | null> | null;
  not?: ModelvodConnectionFilterInput | null;
};

export type ModelcustomerFilterInput = {
  id?: ModelIDInput | null;
  appKey?: ModelStringInput | null;
  appUrl?: ModelStringInput | null;
  externalId?: ModelStringInput | null;
  googleId?: ModelStringInput | null;
  anonymousIds?: ModelStringInput | null;
  email?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  acceptsMarketing?: ModelBooleanInput | null;
  and?: Array<ModelcustomerFilterInput | null> | null;
  or?: Array<ModelcustomerFilterInput | null> | null;
  not?: ModelcustomerFilterInput | null;
};

export type ModelcustomerConnection = {
  __typename: 'ModelcustomerConnection';
  items: Array<customer | null>;
  nextToken?: string | null;
};

export type ModelvodLabelFilterInput = {
  id?: ModelIDInput | null;
  appKey?: ModelStringInput | null;
  name?: ModelStringInput | null;
  and?: Array<ModelvodLabelFilterInput | null> | null;
  or?: Array<ModelvodLabelFilterInput | null> | null;
  not?: ModelvodLabelFilterInput | null;
};

export type ModelvodLabelConnection = {
  __typename: 'ModelvodLabelConnection';
  items: Array<vodLabel | null>;
  nextToken?: string | null;
};

export type ModelsuggestedProductAcceptanceFilterInput = {
  id?: ModelIDInput | null;
  appKey?: ModelStringInput | null;
  vodAssetId?: ModelIDInput | null;
  externalProductId?: ModelStringInput | null;
  accepted?: ModelBooleanInput | null;
  and?: Array<ModelsuggestedProductAcceptanceFilterInput | null> | null;
  or?: Array<ModelsuggestedProductAcceptanceFilterInput | null> | null;
  not?: ModelsuggestedProductAcceptanceFilterInput | null;
};

export type ModelsuggestedProductAcceptanceConnection = {
  __typename: 'ModelsuggestedProductAcceptanceConnection';
  items: Array<suggestedProductAcceptance | null>;
  nextToken?: string | null;
};

export type ModelcustomerWishListFilterInput = {
  id?: ModelIDInput | null;
  appKey?: ModelStringInput | null;
  appUrl?: ModelStringInput | null;
  vodAssetId?: ModelIDInput | null;
  externalCustomerId?: ModelStringInput | null;
  email?: ModelStringInput | null;
  externalProductId?: ModelStringInput | null;
  and?: Array<ModelcustomerWishListFilterInput | null> | null;
  or?: Array<ModelcustomerWishListFilterInput | null> | null;
  not?: ModelcustomerWishListFilterInput | null;
};

export type ModelcustomerWishListConnection = {
  __typename: 'ModelcustomerWishListConnection';
  items: Array<customerWishList | null>;
  nextToken?: string | null;
};

export type ModelvodConnectionSuggestionFilterInput = {
  id?: ModelIDInput | null;
  appKey?: ModelStringInput | null;
  appUrl?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  vodAssetId?: ModelIDInput | null;
  externalProductId?: ModelStringInput | null;
  status?: ModelVodConnectionSuggestionStatusInput | null;
  source?: ModelVodConnectionSuggestionSourceInput | null;
  method?: ModelVodConnectionSuggestionMethodInput | null;
  methodVersion?: ModelStringInput | null;
  score?: ModelFloatInput | null;
  and?: Array<ModelvodConnectionSuggestionFilterInput | null> | null;
  or?: Array<ModelvodConnectionSuggestionFilterInput | null> | null;
  not?: ModelvodConnectionSuggestionFilterInput | null;
};

export type ModelvodConnectionSuggestionConnection = {
  __typename: 'ModelvodConnectionSuggestionConnection';
  items: Array<vodConnectionSuggestion | null>;
  nextToken?: string | null;
};

export enum ModelSortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type ModelStringKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelIntKeyConditionInput = {
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type CustomUpdateAppMutationVariables = {
  id: string;
  googleAnalyticsID?: string | null;
  settings?: string | null;
  data?: string | null;
  importRange?: number | null;
};

export type CustomUpdateAppMutation = {
  updateApp?: {
    __typename: 'app';
    id: string;
    owner?: string | null;
    appKey?: string | null;
    app?: string | null;
    active?: boolean | null;
    data?: string | null;
    token?: string | null;
    appExternalId?: string | null;
    appUrl?: string | null;
    importSettings?: ImportSettings | null;
    importRange?: number | null;
    syncTagsMode?: string | null;
    createdAt: string;
    updatedAt: string;
    googleAnalyticsID?: string | null;
    settings?: string | null;
  } | null;
};

export type CreateVodAssetMutationVariables = {
  input: CreateVodAssetInput;
  condition?: ModelvodAssetConditionInput | null;
};

export type CreateVodAssetMutation = {
  createVodAsset?: {
    __typename: 'vodAsset';
    id: string;
    type?: assetType | null;
    owner?: string | null;
    appKey?: string | null;
    appExternalId?: string | null;
    createdAt?: string | null;
    name: string;
    description?: string | null;
    creatorProfile?: {
      __typename: 'creatorProfile';
      url?: string | null;
    } | null;
    isSoundAllowed?: boolean | null;
    status: string;
    uploadType?: string | null;
    stockAsset?: {
      __typename: 'stockAsset';
      videoUrl?: string | null;
      posterUrl?: string | null;
      gifUrl?: string | null;
      hasOriginal?: boolean | null;
      shopifyFileId?: string | null;
      previewUrl?: string | null;
      previewShopifyFileId?: string | null;
      shopifyPosterUrl?: string | null;
      posterShopifyFileId?: string | null;
      avatarUrl?: string | null;
      audioUrl?: string | null;
      gifShopifyFileId?: string | null;
    } | null;
    duration?: number | null;
    folder?: string | null;
    width?: number | null;
    height?: number | null;
    isVertical?: boolean | null;
    isHDR?: boolean | null;
    trimSettings?: string | null;
    originalVOD?: string | null;
    mirror?: boolean | null;
    recorderVersion?: string | null;
    originalSize?: string | null;
    externalId?: string | null;
    externalProviderData?: string | null;
    isMigratingToShopify?: boolean | null;
    shopifyUploadingStatus?: string | null;
    isEverUsed?: boolean | null;
    captionsSettings?: string | null;
    referenceIds?: Array<string | null> | null;
    vodConnections?: {
      __typename: 'ModelvodConnectionConnection';
      items: Array<{
        __typename: 'vodConnection';
        id: string;
        appKey?: string | null;
        vodAssetId?: string | null;
        productId?: string | null;
        externalProductId?: string | null;
        collectionId?: string | null;
        provider?: VodConnectionProvider | null;
        tag?: string | null;
        createdAt?: string | null;
        typeKey?: string | null;
        type?: VodConnectionType | null;
        appUrl?: string | null;
        label?: string | null;
        vodLabelId?: string | null;
        vodSource?: string | null;
        vodType?: string | null;
        variantIds?: Array<string | null> | null;
        projectId?: string | null;
        orderIndex?: number | null;
        source?: string | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    analysis?: string | null;
    autoGenerated?: boolean | null;
    updatedAt: string;
  } | null;
};

export type UpdateVodAssetMutationVariables = {
  input: UpdateVodAssetInput;
  condition?: ModelvodAssetConditionInput | null;
};

export type UpdateVodAssetMutation = {
  updateVodAsset?: {
    __typename: 'vodAsset';
    id: string;
    type?: assetType | null;
    owner?: string | null;
    appKey?: string | null;
    appExternalId?: string | null;
    createdAt?: string | null;
    name: string;
    description?: string | null;
    creatorProfile?: {
      __typename: 'creatorProfile';
      url?: string | null;
    } | null;
    isSoundAllowed?: boolean | null;
    status: string;
    uploadType?: string | null;
    stockAsset?: {
      __typename: 'stockAsset';
      videoUrl?: string | null;
      posterUrl?: string | null;
      gifUrl?: string | null;
      hasOriginal?: boolean | null;
      shopifyFileId?: string | null;
      previewUrl?: string | null;
      previewShopifyFileId?: string | null;
      shopifyPosterUrl?: string | null;
      posterShopifyFileId?: string | null;
      avatarUrl?: string | null;
      audioUrl?: string | null;
      gifShopifyFileId?: string | null;
    } | null;
    duration?: number | null;
    folder?: string | null;
    width?: number | null;
    height?: number | null;
    isVertical?: boolean | null;
    isHDR?: boolean | null;
    trimSettings?: string | null;
    originalVOD?: string | null;
    mirror?: boolean | null;
    recorderVersion?: string | null;
    originalSize?: string | null;
    externalId?: string | null;
    externalProviderData?: string | null;
    isMigratingToShopify?: boolean | null;
    shopifyUploadingStatus?: string | null;
    isEverUsed?: boolean | null;
    captionsSettings?: string | null;
    referenceIds?: Array<string | null> | null;
    vodConnections?: {
      __typename: 'ModelvodConnectionConnection';
      items: Array<{
        __typename: 'vodConnection';
        id: string;
        appKey?: string | null;
        vodAssetId?: string | null;
        productId?: string | null;
        externalProductId?: string | null;
        collectionId?: string | null;
        provider?: VodConnectionProvider | null;
        tag?: string | null;
        createdAt?: string | null;
        typeKey?: string | null;
        type?: VodConnectionType | null;
        appUrl?: string | null;
        label?: string | null;
        vodLabelId?: string | null;
        vodSource?: string | null;
        vodType?: string | null;
        variantIds?: Array<string | null> | null;
        projectId?: string | null;
        orderIndex?: number | null;
        source?: string | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    analysis?: string | null;
    autoGenerated?: boolean | null;
    updatedAt: string;
  } | null;
};

export type DeleteVodAssetMutationVariables = {
  input: DeleteVodAssetInput;
  condition?: ModelvodAssetConditionInput | null;
};

export type DeleteVodAssetMutation = {
  deleteVodAsset?: {
    __typename: 'vodAsset';
    id: string;
    type?: assetType | null;
    owner?: string | null;
    appKey?: string | null;
    appExternalId?: string | null;
    createdAt?: string | null;
    name: string;
    description?: string | null;
    creatorProfile?: {
      __typename: 'creatorProfile';
      url?: string | null;
    } | null;
    isSoundAllowed?: boolean | null;
    status: string;
    uploadType?: string | null;
    stockAsset?: {
      __typename: 'stockAsset';
      videoUrl?: string | null;
      posterUrl?: string | null;
      gifUrl?: string | null;
      hasOriginal?: boolean | null;
      shopifyFileId?: string | null;
      previewUrl?: string | null;
      previewShopifyFileId?: string | null;
      shopifyPosterUrl?: string | null;
      posterShopifyFileId?: string | null;
      avatarUrl?: string | null;
      audioUrl?: string | null;
      gifShopifyFileId?: string | null;
    } | null;
    duration?: number | null;
    folder?: string | null;
    width?: number | null;
    height?: number | null;
    isVertical?: boolean | null;
    isHDR?: boolean | null;
    trimSettings?: string | null;
    originalVOD?: string | null;
    mirror?: boolean | null;
    recorderVersion?: string | null;
    originalSize?: string | null;
    externalId?: string | null;
    externalProviderData?: string | null;
    isMigratingToShopify?: boolean | null;
    shopifyUploadingStatus?: string | null;
    isEverUsed?: boolean | null;
    captionsSettings?: string | null;
    referenceIds?: Array<string | null> | null;
    vodConnections?: {
      __typename: 'ModelvodConnectionConnection';
      items: Array<{
        __typename: 'vodConnection';
        id: string;
        appKey?: string | null;
        vodAssetId?: string | null;
        productId?: string | null;
        externalProductId?: string | null;
        collectionId?: string | null;
        provider?: VodConnectionProvider | null;
        tag?: string | null;
        createdAt?: string | null;
        typeKey?: string | null;
        type?: VodConnectionType | null;
        appUrl?: string | null;
        label?: string | null;
        vodLabelId?: string | null;
        vodSource?: string | null;
        vodType?: string | null;
        variantIds?: Array<string | null> | null;
        projectId?: string | null;
        orderIndex?: number | null;
        source?: string | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    analysis?: string | null;
    autoGenerated?: boolean | null;
    updatedAt: string;
  } | null;
};

export type CreateVodResponseAssetMutationVariables = {
  input: CreateVodResponseAssetInput;
  condition?: ModelvodResponseAssetConditionInput | null;
};

export type CreateVodResponseAssetMutation = {
  createVodResponseAsset?: {
    __typename: 'vodResponseAsset';
    id: string;
    owner: string;
    appKey?: string | null;
    createdAt: string;
    status?: string | null;
    uploadType?: string | null;
    duration?: number | null;
    width?: number | null;
    height?: number | null;
    isVertical?: boolean | null;
    isHDR?: boolean | null;
    mirror?: boolean | null;
    originalSize?: string | null;
    email?: string | null;
    projectId?: string | null;
    updatedAt: string;
  } | null;
};

export type UpdateVodResponseAssetMutationVariables = {
  input: UpdateVodResponseAssetInput;
  condition?: ModelvodResponseAssetConditionInput | null;
};

export type UpdateVodResponseAssetMutation = {
  updateVodResponseAsset?: {
    __typename: 'vodResponseAsset';
    id: string;
    owner: string;
    appKey?: string | null;
    createdAt: string;
    status?: string | null;
    uploadType?: string | null;
    duration?: number | null;
    width?: number | null;
    height?: number | null;
    isVertical?: boolean | null;
    isHDR?: boolean | null;
    mirror?: boolean | null;
    originalSize?: string | null;
    email?: string | null;
    projectId?: string | null;
    updatedAt: string;
  } | null;
};

export type DeleteVodResponseAssetMutationVariables = {
  input: DeleteVodResponseAssetInput;
  condition?: ModelvodResponseAssetConditionInput | null;
};

export type DeleteVodResponseAssetMutation = {
  deleteVodResponseAsset?: {
    __typename: 'vodResponseAsset';
    id: string;
    owner: string;
    appKey?: string | null;
    createdAt: string;
    status?: string | null;
    uploadType?: string | null;
    duration?: number | null;
    width?: number | null;
    height?: number | null;
    isVertical?: boolean | null;
    isHDR?: boolean | null;
    mirror?: boolean | null;
    originalSize?: string | null;
    email?: string | null;
    projectId?: string | null;
    updatedAt: string;
  } | null;
};

export type CreateProjectMutationVariables = {
  input: CreateProjectInput;
  condition?: ModelprojectConditionInput | null;
};

export type CreateProjectMutation = {
  createProject?: {
    __typename: 'project';
    id: string;
    owner?: string | null;
    appKey?: string | null;
    typeKey?: string | null;
    createdAt?: string | null;
    publishId: string;
    name: string;
    folder?: string | null;
    targetPage?: string | null;
    verticalOrientation?: boolean | null;
    widgetPosition?: string | null;
    widgetHeader?: string | null;
    widgetUrls?: Array<string | null> | null;
    description?: string | null;
    emailNotifications?: Array<string | null> | null;
    notificationLevel?: number | null;
    viewerNotificationLevel?: number | null;
    chaptersEnabled?: boolean | null;
    subtitlesEnabled?: boolean | null;
    stepsOrder?: Array<string | null> | null;
    collectText?: string | null;
    collectFields?: Array<{
      __typename: 'inputField';
      key?: string | null;
      name?: string | null;
      type?: string | null;
      placeholder?: string | null;
      link?: string | null;
      required?: boolean | null;
      collect?: boolean | null;
      custom?: boolean | null;
      textColor?: string | null;
      borderColor?: string | null;
      backgroundColor?: string | null;
    } | null> | null;
    collectAfterStep?: Array<string | null> | null;
    cacheLeadFormAnswers?: boolean | null;
    googleAnalyticsID?: string | null;
    facebookAnalyticsID?: string | null;
    totalResponses?: number | null;
    unreadResponses?: number | null;
    tolstoyType?: string | null;
    steps?: {
      __typename: 'ModelprojectStepConnection';
      items: Array<{
        __typename: 'projectStep';
        id: string;
        owner?: string | null;
        appKey?: string | null;
        projectId: string;
        videoId?: string | null;
        name: string;
        type?: string | null;
        description?: string | null;
        script?: string | null;
        question?: string | null;
        questionVisible?: boolean | null;
        answerTime?: number | null;
        answers?: Array<{
          __typename: 'answer';
          key?: string | null;
          text?: string | null;
          next?: string | null;
          value?: string | null;
          type?: string | null;
          disabled?: boolean | null;
        } | null> | null;
        products?: Array<{
          __typename: 'product';
          id?: string | null;
        } | null> | null;
        productsAppUrl?: string | null;
        videoContain?: boolean | null;
        overlayText?: string | null;
        overlayTextColor?: string | null;
        multipleChoiceSelection?: boolean | null;
        defaultResponses?: Array<{
          __typename: 'answer';
          key?: string | null;
          text?: string | null;
          next?: string | null;
          value?: string | null;
          type?: string | null;
          disabled?: boolean | null;
        } | null> | null;
        defaultResponsesEnabled?: boolean | null;
        sideBarButtonsEnabled?: boolean | null;
        feedProductActionButton?: {
          __typename: 'feedProductActionButton';
          type?: string | null;
          url?: string | null;
          text?: string | null;
          isOpenInANewTab?: boolean | null;
          value?: string | null;
          enabled?: boolean | null;
        } | null;
        feedSidePanelType?: FeedSidePanelType | null;
        feedDescriptionActionButton?: {
          __typename: 'feedProductActionButton';
          type?: string | null;
          url?: string | null;
          text?: string | null;
          isOpenInANewTab?: boolean | null;
          value?: string | null;
          enabled?: boolean | null;
        } | null;
        feedSidePanelDescription?: string | null;
        productCtaType?: ProductCtaType | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    stepsCount?: number | null;
    widgetBorderColor?: string | null;
    widgetShape?: string | null;
    widgetSize?: string | null;
    widgetNotificationBadge?: boolean | null;
    videoResolutions?: Array<string | null> | null;
    fastForwardEnabled?: boolean | null;
    rulesEnabled?: boolean | null;
    macro?: boolean | null;
    hideChatPlatform?: boolean | null;
    private?: boolean | null;
    chatLandingPage?: boolean | null;
    widgetSettings?: string | null;
    notificationSettingsId?: string | null;
    collectAfterAnyResponse?: boolean | null;
    startScreenText?: string | null;
    feed?: boolean | null;
    feedAutoScroll?: boolean | null;
    feedShowUnviewedStepsFirst?: boolean | null;
    language?: string | null;
    showButtonsIndex?: boolean | null;
    publishMethod?: publishMethodOptions | null;
    dynamic?: boolean | null;
    feedProductCtaType?: ProductCtaType | null;
    playerSettings?: string | null;
    appUrl?: string | null;
    live?: boolean | null;
    primaryButton?: {
      __typename: 'ctaButtonConfig';
      enabled?: boolean | null;
      ctaType?: ProductCtaType | null;
      buttonText?: string | null;
      value?: string | null;
      isOpenInANewTab?: boolean | null;
    } | null;
    secondaryButton?: {
      __typename: 'ctaButtonConfig';
      enabled?: boolean | null;
      ctaType?: ProductCtaType | null;
      buttonText?: string | null;
      value?: string | null;
      isOpenInANewTab?: boolean | null;
    } | null;
    pdpConfig?: {
      __typename: 'pdpConfig';
      primaryButton?: {
        __typename: 'ctaButtonConfig';
        enabled?: boolean | null;
        ctaType?: ProductCtaType | null;
        buttonText?: string | null;
        value?: string | null;
        isOpenInANewTab?: boolean | null;
      } | null;
      secondaryButton?: {
        __typename: 'ctaButtonConfig';
        enabled?: boolean | null;
        ctaType?: ProductCtaType | null;
        buttonText?: string | null;
        value?: string | null;
        isOpenInANewTab?: boolean | null;
      } | null;
      miniCtaButton?: {
        __typename: 'miniCtaButtonConfig';
        enabled?: boolean | null;
        text?: string | null;
      } | null;
    } | null;
    installationCompleted?: boolean | null;
    installStep?: string | null;
    discover?: boolean | null;
    useCaseName?: string | null;
    heroSettings?: string | null;
    sourceProjectId?: string | null;
    emailSettings?: string | null;
    videoCollectionCampaignSettings?: string | null;
    secretVideoSettings?: string | null;
    integrationSettings?: string | null;
    updatedAt: string;
  } | null;
};

export type UpdateProjectMutationVariables = {
  input: UpdateProjectInput;
  condition?: ModelprojectConditionInput | null;
};

export type UpdateProjectMutation = {
  updateProject?: {
    __typename: 'project';
    id: string;
    owner?: string | null;
    appKey?: string | null;
    typeKey?: string | null;
    createdAt?: string | null;
    publishId: string;
    name: string;
    folder?: string | null;
    targetPage?: string | null;
    verticalOrientation?: boolean | null;
    widgetPosition?: string | null;
    widgetHeader?: string | null;
    widgetUrls?: Array<string | null> | null;
    description?: string | null;
    emailNotifications?: Array<string | null> | null;
    notificationLevel?: number | null;
    viewerNotificationLevel?: number | null;
    chaptersEnabled?: boolean | null;
    subtitlesEnabled?: boolean | null;
    stepsOrder?: Array<string | null> | null;
    collectText?: string | null;
    collectFields?: Array<{
      __typename: 'inputField';
      key?: string | null;
      name?: string | null;
      type?: string | null;
      placeholder?: string | null;
      link?: string | null;
      required?: boolean | null;
      collect?: boolean | null;
      custom?: boolean | null;
      textColor?: string | null;
      borderColor?: string | null;
      backgroundColor?: string | null;
    } | null> | null;
    collectAfterStep?: Array<string | null> | null;
    cacheLeadFormAnswers?: boolean | null;
    googleAnalyticsID?: string | null;
    facebookAnalyticsID?: string | null;
    totalResponses?: number | null;
    unreadResponses?: number | null;
    tolstoyType?: string | null;
    steps?: {
      __typename: 'ModelprojectStepConnection';
      items: Array<{
        __typename: 'projectStep';
        id: string;
        owner?: string | null;
        appKey?: string | null;
        projectId: string;
        videoId?: string | null;
        name: string;
        type?: string | null;
        description?: string | null;
        script?: string | null;
        question?: string | null;
        questionVisible?: boolean | null;
        answerTime?: number | null;
        answers?: Array<{
          __typename: 'answer';
          key?: string | null;
          text?: string | null;
          next?: string | null;
          value?: string | null;
          type?: string | null;
          disabled?: boolean | null;
        } | null> | null;
        products?: Array<{
          __typename: 'product';
          id?: string | null;
        } | null> | null;
        productsAppUrl?: string | null;
        videoContain?: boolean | null;
        overlayText?: string | null;
        overlayTextColor?: string | null;
        multipleChoiceSelection?: boolean | null;
        defaultResponses?: Array<{
          __typename: 'answer';
          key?: string | null;
          text?: string | null;
          next?: string | null;
          value?: string | null;
          type?: string | null;
          disabled?: boolean | null;
        } | null> | null;
        defaultResponsesEnabled?: boolean | null;
        sideBarButtonsEnabled?: boolean | null;
        feedProductActionButton?: {
          __typename: 'feedProductActionButton';
          type?: string | null;
          url?: string | null;
          text?: string | null;
          isOpenInANewTab?: boolean | null;
          value?: string | null;
          enabled?: boolean | null;
        } | null;
        feedSidePanelType?: FeedSidePanelType | null;
        feedDescriptionActionButton?: {
          __typename: 'feedProductActionButton';
          type?: string | null;
          url?: string | null;
          text?: string | null;
          isOpenInANewTab?: boolean | null;
          value?: string | null;
          enabled?: boolean | null;
        } | null;
        feedSidePanelDescription?: string | null;
        productCtaType?: ProductCtaType | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    stepsCount?: number | null;
    widgetBorderColor?: string | null;
    widgetShape?: string | null;
    widgetSize?: string | null;
    widgetNotificationBadge?: boolean | null;
    videoResolutions?: Array<string | null> | null;
    fastForwardEnabled?: boolean | null;
    rulesEnabled?: boolean | null;
    macro?: boolean | null;
    hideChatPlatform?: boolean | null;
    private?: boolean | null;
    chatLandingPage?: boolean | null;
    widgetSettings?: string | null;
    notificationSettingsId?: string | null;
    collectAfterAnyResponse?: boolean | null;
    startScreenText?: string | null;
    feed?: boolean | null;
    feedAutoScroll?: boolean | null;
    feedShowUnviewedStepsFirst?: boolean | null;
    language?: string | null;
    showButtonsIndex?: boolean | null;
    publishMethod?: publishMethodOptions | null;
    dynamic?: boolean | null;
    feedProductCtaType?: ProductCtaType | null;
    playerSettings?: string | null;
    appUrl?: string | null;
    live?: boolean | null;
    primaryButton?: {
      __typename: 'ctaButtonConfig';
      enabled?: boolean | null;
      ctaType?: ProductCtaType | null;
      buttonText?: string | null;
      value?: string | null;
      isOpenInANewTab?: boolean | null;
    } | null;
    secondaryButton?: {
      __typename: 'ctaButtonConfig';
      enabled?: boolean | null;
      ctaType?: ProductCtaType | null;
      buttonText?: string | null;
      value?: string | null;
      isOpenInANewTab?: boolean | null;
    } | null;
    pdpConfig?: {
      __typename: 'pdpConfig';
      primaryButton?: {
        __typename: 'ctaButtonConfig';
        enabled?: boolean | null;
        ctaType?: ProductCtaType | null;
        buttonText?: string | null;
        value?: string | null;
        isOpenInANewTab?: boolean | null;
      } | null;
      secondaryButton?: {
        __typename: 'ctaButtonConfig';
        enabled?: boolean | null;
        ctaType?: ProductCtaType | null;
        buttonText?: string | null;
        value?: string | null;
        isOpenInANewTab?: boolean | null;
      } | null;
      miniCtaButton?: {
        __typename: 'miniCtaButtonConfig';
        enabled?: boolean | null;
        text?: string | null;
      } | null;
    } | null;
    installationCompleted?: boolean | null;
    installStep?: string | null;
    discover?: boolean | null;
    useCaseName?: string | null;
    heroSettings?: string | null;
    sourceProjectId?: string | null;
    emailSettings?: string | null;
    videoCollectionCampaignSettings?: string | null;
    secretVideoSettings?: string | null;
    integrationSettings?: string | null;
    updatedAt: string;
  } | null;
};

export type DeleteProjectMutationVariables = {
  input: DeleteProjectInput;
  condition?: ModelprojectConditionInput | null;
};

export type DeleteProjectMutation = {
  deleteProject?: {
    __typename: 'project';
    id: string;
    owner?: string | null;
    appKey?: string | null;
    typeKey?: string | null;
    createdAt?: string | null;
    publishId: string;
    name: string;
    folder?: string | null;
    targetPage?: string | null;
    verticalOrientation?: boolean | null;
    widgetPosition?: string | null;
    widgetHeader?: string | null;
    widgetUrls?: Array<string | null> | null;
    description?: string | null;
    emailNotifications?: Array<string | null> | null;
    notificationLevel?: number | null;
    viewerNotificationLevel?: number | null;
    chaptersEnabled?: boolean | null;
    subtitlesEnabled?: boolean | null;
    stepsOrder?: Array<string | null> | null;
    collectText?: string | null;
    collectFields?: Array<{
      __typename: 'inputField';
      key?: string | null;
      name?: string | null;
      type?: string | null;
      placeholder?: string | null;
      link?: string | null;
      required?: boolean | null;
      collect?: boolean | null;
      custom?: boolean | null;
      textColor?: string | null;
      borderColor?: string | null;
      backgroundColor?: string | null;
    } | null> | null;
    collectAfterStep?: Array<string | null> | null;
    cacheLeadFormAnswers?: boolean | null;
    googleAnalyticsID?: string | null;
    facebookAnalyticsID?: string | null;
    totalResponses?: number | null;
    unreadResponses?: number | null;
    tolstoyType?: string | null;
    steps?: {
      __typename: 'ModelprojectStepConnection';
      items: Array<{
        __typename: 'projectStep';
        id: string;
        owner?: string | null;
        appKey?: string | null;
        projectId: string;
        videoId?: string | null;
        name: string;
        type?: string | null;
        description?: string | null;
        script?: string | null;
        question?: string | null;
        questionVisible?: boolean | null;
        answerTime?: number | null;
        answers?: Array<{
          __typename: 'answer';
          key?: string | null;
          text?: string | null;
          next?: string | null;
          value?: string | null;
          type?: string | null;
          disabled?: boolean | null;
        } | null> | null;
        products?: Array<{
          __typename: 'product';
          id?: string | null;
        } | null> | null;
        productsAppUrl?: string | null;
        videoContain?: boolean | null;
        overlayText?: string | null;
        overlayTextColor?: string | null;
        multipleChoiceSelection?: boolean | null;
        defaultResponses?: Array<{
          __typename: 'answer';
          key?: string | null;
          text?: string | null;
          next?: string | null;
          value?: string | null;
          type?: string | null;
          disabled?: boolean | null;
        } | null> | null;
        defaultResponsesEnabled?: boolean | null;
        sideBarButtonsEnabled?: boolean | null;
        feedProductActionButton?: {
          __typename: 'feedProductActionButton';
          type?: string | null;
          url?: string | null;
          text?: string | null;
          isOpenInANewTab?: boolean | null;
          value?: string | null;
          enabled?: boolean | null;
        } | null;
        feedSidePanelType?: FeedSidePanelType | null;
        feedDescriptionActionButton?: {
          __typename: 'feedProductActionButton';
          type?: string | null;
          url?: string | null;
          text?: string | null;
          isOpenInANewTab?: boolean | null;
          value?: string | null;
          enabled?: boolean | null;
        } | null;
        feedSidePanelDescription?: string | null;
        productCtaType?: ProductCtaType | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    stepsCount?: number | null;
    widgetBorderColor?: string | null;
    widgetShape?: string | null;
    widgetSize?: string | null;
    widgetNotificationBadge?: boolean | null;
    videoResolutions?: Array<string | null> | null;
    fastForwardEnabled?: boolean | null;
    rulesEnabled?: boolean | null;
    macro?: boolean | null;
    hideChatPlatform?: boolean | null;
    private?: boolean | null;
    chatLandingPage?: boolean | null;
    widgetSettings?: string | null;
    notificationSettingsId?: string | null;
    collectAfterAnyResponse?: boolean | null;
    startScreenText?: string | null;
    feed?: boolean | null;
    feedAutoScroll?: boolean | null;
    feedShowUnviewedStepsFirst?: boolean | null;
    language?: string | null;
    showButtonsIndex?: boolean | null;
    publishMethod?: publishMethodOptions | null;
    dynamic?: boolean | null;
    feedProductCtaType?: ProductCtaType | null;
    playerSettings?: string | null;
    appUrl?: string | null;
    live?: boolean | null;
    primaryButton?: {
      __typename: 'ctaButtonConfig';
      enabled?: boolean | null;
      ctaType?: ProductCtaType | null;
      buttonText?: string | null;
      value?: string | null;
      isOpenInANewTab?: boolean | null;
    } | null;
    secondaryButton?: {
      __typename: 'ctaButtonConfig';
      enabled?: boolean | null;
      ctaType?: ProductCtaType | null;
      buttonText?: string | null;
      value?: string | null;
      isOpenInANewTab?: boolean | null;
    } | null;
    pdpConfig?: {
      __typename: 'pdpConfig';
      primaryButton?: {
        __typename: 'ctaButtonConfig';
        enabled?: boolean | null;
        ctaType?: ProductCtaType | null;
        buttonText?: string | null;
        value?: string | null;
        isOpenInANewTab?: boolean | null;
      } | null;
      secondaryButton?: {
        __typename: 'ctaButtonConfig';
        enabled?: boolean | null;
        ctaType?: ProductCtaType | null;
        buttonText?: string | null;
        value?: string | null;
        isOpenInANewTab?: boolean | null;
      } | null;
      miniCtaButton?: {
        __typename: 'miniCtaButtonConfig';
        enabled?: boolean | null;
        text?: string | null;
      } | null;
    } | null;
    installationCompleted?: boolean | null;
    installStep?: string | null;
    discover?: boolean | null;
    useCaseName?: string | null;
    heroSettings?: string | null;
    sourceProjectId?: string | null;
    emailSettings?: string | null;
    videoCollectionCampaignSettings?: string | null;
    secretVideoSettings?: string | null;
    integrationSettings?: string | null;
    updatedAt: string;
  } | null;
};

export type CreateProjectStepMutationVariables = {
  input: CreateProjectStepInput;
  condition?: ModelprojectStepConditionInput | null;
};

export type CreateProjectStepMutation = {
  createProjectStep?: {
    __typename: 'projectStep';
    id: string;
    owner?: string | null;
    appKey?: string | null;
    projectId: string;
    videoId?: string | null;
    name: string;
    type?: string | null;
    description?: string | null;
    script?: string | null;
    question?: string | null;
    questionVisible?: boolean | null;
    answerTime?: number | null;
    answers?: Array<{
      __typename: 'answer';
      key?: string | null;
      text?: string | null;
      next?: string | null;
      value?: string | null;
      type?: string | null;
      disabled?: boolean | null;
    } | null> | null;
    products?: Array<{
      __typename: 'product';
      id?: string | null;
    } | null> | null;
    productsAppUrl?: string | null;
    videoContain?: boolean | null;
    overlayText?: string | null;
    overlayTextColor?: string | null;
    multipleChoiceSelection?: boolean | null;
    defaultResponses?: Array<{
      __typename: 'answer';
      key?: string | null;
      text?: string | null;
      next?: string | null;
      value?: string | null;
      type?: string | null;
      disabled?: boolean | null;
    } | null> | null;
    defaultResponsesEnabled?: boolean | null;
    sideBarButtonsEnabled?: boolean | null;
    feedProductActionButton?: {
      __typename: 'feedProductActionButton';
      type?: string | null;
      url?: string | null;
      text?: string | null;
      isOpenInANewTab?: boolean | null;
      value?: string | null;
      enabled?: boolean | null;
    } | null;
    feedSidePanelType?: FeedSidePanelType | null;
    feedDescriptionActionButton?: {
      __typename: 'feedProductActionButton';
      type?: string | null;
      url?: string | null;
      text?: string | null;
      isOpenInANewTab?: boolean | null;
      value?: string | null;
      enabled?: boolean | null;
    } | null;
    feedSidePanelDescription?: string | null;
    productCtaType?: ProductCtaType | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateProjectStepMutationVariables = {
  input: UpdateProjectStepInput;
  condition?: ModelprojectStepConditionInput | null;
};

export type UpdateProjectStepMutation = {
  updateProjectStep?: {
    __typename: 'projectStep';
    id: string;
    owner?: string | null;
    appKey?: string | null;
    projectId: string;
    videoId?: string | null;
    name: string;
    type?: string | null;
    description?: string | null;
    script?: string | null;
    question?: string | null;
    questionVisible?: boolean | null;
    answerTime?: number | null;
    answers?: Array<{
      __typename: 'answer';
      key?: string | null;
      text?: string | null;
      next?: string | null;
      value?: string | null;
      type?: string | null;
      disabled?: boolean | null;
    } | null> | null;
    products?: Array<{
      __typename: 'product';
      id?: string | null;
    } | null> | null;
    productsAppUrl?: string | null;
    videoContain?: boolean | null;
    overlayText?: string | null;
    overlayTextColor?: string | null;
    multipleChoiceSelection?: boolean | null;
    defaultResponses?: Array<{
      __typename: 'answer';
      key?: string | null;
      text?: string | null;
      next?: string | null;
      value?: string | null;
      type?: string | null;
      disabled?: boolean | null;
    } | null> | null;
    defaultResponsesEnabled?: boolean | null;
    sideBarButtonsEnabled?: boolean | null;
    feedProductActionButton?: {
      __typename: 'feedProductActionButton';
      type?: string | null;
      url?: string | null;
      text?: string | null;
      isOpenInANewTab?: boolean | null;
      value?: string | null;
      enabled?: boolean | null;
    } | null;
    feedSidePanelType?: FeedSidePanelType | null;
    feedDescriptionActionButton?: {
      __typename: 'feedProductActionButton';
      type?: string | null;
      url?: string | null;
      text?: string | null;
      isOpenInANewTab?: boolean | null;
      value?: string | null;
      enabled?: boolean | null;
    } | null;
    feedSidePanelDescription?: string | null;
    productCtaType?: ProductCtaType | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteProjectStepMutationVariables = {
  input: DeleteProjectStepInput;
  condition?: ModelprojectStepConditionInput | null;
};

export type DeleteProjectStepMutation = {
  deleteProjectStep?: {
    __typename: 'projectStep';
    id: string;
    owner?: string | null;
    appKey?: string | null;
    projectId: string;
    videoId?: string | null;
    name: string;
    type?: string | null;
    description?: string | null;
    script?: string | null;
    question?: string | null;
    questionVisible?: boolean | null;
    answerTime?: number | null;
    answers?: Array<{
      __typename: 'answer';
      key?: string | null;
      text?: string | null;
      next?: string | null;
      value?: string | null;
      type?: string | null;
      disabled?: boolean | null;
    } | null> | null;
    products?: Array<{
      __typename: 'product';
      id?: string | null;
    } | null> | null;
    productsAppUrl?: string | null;
    videoContain?: boolean | null;
    overlayText?: string | null;
    overlayTextColor?: string | null;
    multipleChoiceSelection?: boolean | null;
    defaultResponses?: Array<{
      __typename: 'answer';
      key?: string | null;
      text?: string | null;
      next?: string | null;
      value?: string | null;
      type?: string | null;
      disabled?: boolean | null;
    } | null> | null;
    defaultResponsesEnabled?: boolean | null;
    sideBarButtonsEnabled?: boolean | null;
    feedProductActionButton?: {
      __typename: 'feedProductActionButton';
      type?: string | null;
      url?: string | null;
      text?: string | null;
      isOpenInANewTab?: boolean | null;
      value?: string | null;
      enabled?: boolean | null;
    } | null;
    feedSidePanelType?: FeedSidePanelType | null;
    feedDescriptionActionButton?: {
      __typename: 'feedProductActionButton';
      type?: string | null;
      url?: string | null;
      text?: string | null;
      isOpenInANewTab?: boolean | null;
      value?: string | null;
      enabled?: boolean | null;
    } | null;
    feedSidePanelDescription?: string | null;
    productCtaType?: ProductCtaType | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateUserSettingsMutationVariables = {
  input: CreateUserSettingsInput;
  condition?: ModeluserSettingsConditionInput | null;
};

export type CreateUserSettingsMutation = {
  createUserSettings?: {
    __typename: 'userSettings';
    id: string;
    owner?: string | null;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    lastLogin?: string | null;
    source?: string | null;
    token?: string | null;
    appKey?: string | null;
    hasLoggedIn?: boolean | null;
    hasShared?: boolean | null;
    viewedTolstoys?: Array<string | null> | null;
    calendars?: Array<{
      __typename: 'calendar';
      id?: string | null;
      name?: string | null;
      url?: string | null;
    } | null> | null;
    emailAutomationTool?: string | null;
    logoSettings?: string | null;
    googleInfo?: string | null;
    crmData?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateUserSettingsMutationVariables = {
  input: UpdateUserSettingsInput;
  condition?: ModeluserSettingsConditionInput | null;
};

export type UpdateUserSettingsMutation = {
  updateUserSettings?: {
    __typename: 'userSettings';
    id: string;
    owner?: string | null;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    lastLogin?: string | null;
    source?: string | null;
    token?: string | null;
    appKey?: string | null;
    hasLoggedIn?: boolean | null;
    hasShared?: boolean | null;
    viewedTolstoys?: Array<string | null> | null;
    calendars?: Array<{
      __typename: 'calendar';
      id?: string | null;
      name?: string | null;
      url?: string | null;
    } | null> | null;
    emailAutomationTool?: string | null;
    logoSettings?: string | null;
    googleInfo?: string | null;
    crmData?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteUserSettingsMutationVariables = {
  input: DeleteUserSettingsInput;
  condition?: ModeluserSettingsConditionInput | null;
};

export type DeleteUserSettingsMutation = {
  deleteUserSettings?: {
    __typename: 'userSettings';
    id: string;
    owner?: string | null;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    lastLogin?: string | null;
    source?: string | null;
    token?: string | null;
    appKey?: string | null;
    hasLoggedIn?: boolean | null;
    hasShared?: boolean | null;
    viewedTolstoys?: Array<string | null> | null;
    calendars?: Array<{
      __typename: 'calendar';
      id?: string | null;
      name?: string | null;
      url?: string | null;
    } | null> | null;
    emailAutomationTool?: string | null;
    logoSettings?: string | null;
    googleInfo?: string | null;
    crmData?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateAccountSettingsMutationVariables = {
  input: CreateAccountSettingsInput;
  condition?: ModelaccountSettingsConditionInput | null;
};

export type CreateAccountSettingsMutation = {
  createAccountSettings?: {
    __typename: 'accountSettings';
    id: string;
    appKey: string;
    owner?: string | null;
    name?: string | null;
    logoSettings?: string | null;
    widgetSettings?: string | null;
    downgradeInfo?: string | null;
    liveIds?: Array<string | null> | null;
    url?: string | null;
    logoUrl?: string | null;
    playerLogoUrl?: string | null;
    companyVertical?: string | null;
    companySize?: string | null;
    companyRole?: string | null;
    monthlyTraffic?: string | null;
    useCase?: string | null;
    packageId?: string | null;
    token?: string | null;
    paymentProvider?: string | null;
    paymentSubscriptions?: Array<string | null> | null;
    paymentIdentifier?: string | null;
    packageGroup?: string | null;
    joinedPackageAt?: string | null;
    trialEndAt?: string | null;
    referral?: string | null;
    crmData?: string | null;
    trialDays?: number | null;
    googleAnalyticsID?: string | null;
    appsInstallationStatuses?: string | null;
    onboardingStatuses?: string | null;
    assetAnalysis?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateAccountSettingsMutationVariables = {
  input: UpdateAccountSettingsInput;
  condition?: ModelaccountSettingsConditionInput | null;
};

export type UpdateAccountSettingsMutation = {
  updateAccountSettings?: {
    __typename: 'accountSettings';
    id: string;
    appKey: string;
    owner?: string | null;
    name?: string | null;
    logoSettings?: string | null;
    widgetSettings?: string | null;
    downgradeInfo?: string | null;
    liveIds?: Array<string | null> | null;
    url?: string | null;
    logoUrl?: string | null;
    playerLogoUrl?: string | null;
    companyVertical?: string | null;
    companySize?: string | null;
    companyRole?: string | null;
    monthlyTraffic?: string | null;
    useCase?: string | null;
    packageId?: string | null;
    token?: string | null;
    paymentProvider?: string | null;
    paymentSubscriptions?: Array<string | null> | null;
    paymentIdentifier?: string | null;
    packageGroup?: string | null;
    joinedPackageAt?: string | null;
    trialEndAt?: string | null;
    referral?: string | null;
    crmData?: string | null;
    trialDays?: number | null;
    googleAnalyticsID?: string | null;
    appsInstallationStatuses?: string | null;
    onboardingStatuses?: string | null;
    assetAnalysis?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteAccountSettingsMutationVariables = {
  input: DeleteAccountSettingsInput;
  condition?: ModelaccountSettingsConditionInput | null;
};

export type DeleteAccountSettingsMutation = {
  deleteAccountSettings?: {
    __typename: 'accountSettings';
    id: string;
    appKey: string;
    owner?: string | null;
    name?: string | null;
    logoSettings?: string | null;
    widgetSettings?: string | null;
    downgradeInfo?: string | null;
    liveIds?: Array<string | null> | null;
    url?: string | null;
    logoUrl?: string | null;
    playerLogoUrl?: string | null;
    companyVertical?: string | null;
    companySize?: string | null;
    companyRole?: string | null;
    monthlyTraffic?: string | null;
    useCase?: string | null;
    packageId?: string | null;
    token?: string | null;
    paymentProvider?: string | null;
    paymentSubscriptions?: Array<string | null> | null;
    paymentIdentifier?: string | null;
    packageGroup?: string | null;
    joinedPackageAt?: string | null;
    trialEndAt?: string | null;
    referral?: string | null;
    crmData?: string | null;
    trialDays?: number | null;
    googleAnalyticsID?: string | null;
    appsInstallationStatuses?: string | null;
    onboardingStatuses?: string | null;
    assetAnalysis?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateAppMutationVariables = {
  input: CreateAppInput;
  condition?: ModelappConditionInput | null;
};

export type CreateAppMutation = {
  createApp?: {
    __typename: 'app';
    id: string;
    owner?: string | null;
    appKey?: string | null;
    app?: string | null;
    active?: boolean | null;
    data?: string | null;
    token?: string | null;
    appExternalId?: string | null;
    appUrl?: string | null;
    importSettings?: ImportSettings | null;
    importRange?: number | null;
    syncTagsMode?: string | null;
    syndicateBy?: SyndicateBy | null;
    googleAnalyticsID?: string | null;
    settings?: string | null;
    isShopAppEligible?: boolean | null;
    taxonomyId?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateAppMutationVariables = {
  input: UpdateAppInput;
  condition?: ModelappConditionInput | null;
};

export type UpdateAppMutation = {
  updateApp?: {
    __typename: 'app';
    id: string;
    owner?: string | null;
    appKey?: string | null;
    app?: string | null;
    active?: boolean | null;
    data?: string | null;
    token?: string | null;
    appExternalId?: string | null;
    appUrl?: string | null;
    importSettings?: ImportSettings | null;
    importRange?: number | null;
    syncTagsMode?: string | null;
    syndicateBy?: SyndicateBy | null;
    googleAnalyticsID?: string | null;
    settings?: string | null;
    isShopAppEligible?: boolean | null;
    taxonomyId?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteAppMutationVariables = {
  input: DeleteAppInput;
  condition?: ModelappConditionInput | null;
};

export type DeleteAppMutation = {
  deleteApp?: {
    __typename: 'app';
    id: string;
    owner?: string | null;
    appKey?: string | null;
    app?: string | null;
    active?: boolean | null;
    data?: string | null;
    token?: string | null;
    appExternalId?: string | null;
    appUrl?: string | null;
    importSettings?: ImportSettings | null;
    importRange?: number | null;
    syncTagsMode?: string | null;
    syndicateBy?: SyndicateBy | null;
    googleAnalyticsID?: string | null;
    settings?: string | null;
    isShopAppEligible?: boolean | null;
    taxonomyId?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateProjectAppMutationVariables = {
  input: CreateProjectAppInput;
  condition?: ModelprojectAppConditionInput | null;
};

export type CreateProjectAppMutation = {
  createProjectApp?: {
    __typename: 'projectApp';
    id: string;
    appId?: string | null;
    appKey?: string | null;
    projectId?: string | null;
    owner?: string | null;
    active?: boolean | null;
    data?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateProjectAppMutationVariables = {
  input: UpdateProjectAppInput;
  condition?: ModelprojectAppConditionInput | null;
};

export type UpdateProjectAppMutation = {
  updateProjectApp?: {
    __typename: 'projectApp';
    id: string;
    appId?: string | null;
    appKey?: string | null;
    projectId?: string | null;
    owner?: string | null;
    active?: boolean | null;
    data?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteProjectAppMutationVariables = {
  input: DeleteProjectAppInput;
  condition?: ModelprojectAppConditionInput | null;
};

export type DeleteProjectAppMutation = {
  deleteProjectApp?: {
    __typename: 'projectApp';
    id: string;
    appId?: string | null;
    appKey?: string | null;
    projectId?: string | null;
    owner?: string | null;
    active?: boolean | null;
    data?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateResponseMutationVariables = {
  input: CreateResponseInput;
  condition?: ModelresponseConditionInput | null;
};

export type CreateResponseMutation = {
  createResponse?: {
    __typename: 'response';
    id: string;
    owner?: string | null;
    appKey?: string | null;
    stepName?: string | null;
    projectId?: string | null;
    anonymousId?: string | null;
    sessionId?: string | null;
    type?: string | null;
    value?: string | null;
    email?: string | null;
    name?: string | null;
    phone?: string | null;
    createdAt: string;
    read?: boolean | null;
    collectInfo?: string | null;
    answerType?: string | null;
    parentUrl?: string | null;
    isProfanity?: boolean | null;
    attachments?: Array<{
      __typename: 'attachment';
      fileName?: string | null;
      url?: string | null;
      type?: string | null;
    } | null> | null;
    updatedAt: string;
  } | null;
};

export type UpdateResponseMutationVariables = {
  input: UpdateResponseInput;
  condition?: ModelresponseConditionInput | null;
};

export type UpdateResponseMutation = {
  updateResponse?: {
    __typename: 'response';
    id: string;
    owner?: string | null;
    appKey?: string | null;
    stepName?: string | null;
    projectId?: string | null;
    anonymousId?: string | null;
    sessionId?: string | null;
    type?: string | null;
    value?: string | null;
    email?: string | null;
    name?: string | null;
    phone?: string | null;
    createdAt: string;
    read?: boolean | null;
    collectInfo?: string | null;
    answerType?: string | null;
    parentUrl?: string | null;
    isProfanity?: boolean | null;
    attachments?: Array<{
      __typename: 'attachment';
      fileName?: string | null;
      url?: string | null;
      type?: string | null;
    } | null> | null;
    updatedAt: string;
  } | null;
};

export type DeleteResponseMutationVariables = {
  input: DeleteResponseInput;
  condition?: ModelresponseConditionInput | null;
};

export type DeleteResponseMutation = {
  deleteResponse?: {
    __typename: 'response';
    id: string;
    owner?: string | null;
    appKey?: string | null;
    stepName?: string | null;
    projectId?: string | null;
    anonymousId?: string | null;
    sessionId?: string | null;
    type?: string | null;
    value?: string | null;
    email?: string | null;
    name?: string | null;
    phone?: string | null;
    createdAt: string;
    read?: boolean | null;
    collectInfo?: string | null;
    answerType?: string | null;
    parentUrl?: string | null;
    isProfanity?: boolean | null;
    attachments?: Array<{
      __typename: 'attachment';
      fileName?: string | null;
      url?: string | null;
      type?: string | null;
    } | null> | null;
    updatedAt: string;
  } | null;
};

export type CreateInAppNotificationMutationVariables = {
  input: CreateInAppNotificationInput;
  condition?: ModelinAppNotificationConditionInput | null;
};

export type CreateInAppNotificationMutation = {
  createInAppNotification?: {
    __typename: 'inAppNotification';
    id: string;
    owner?: string | null;
    teamMemberId?: string | null;
    projectId?: string | null;
    sessionId?: string | null;
    type?: string | null;
    value?: string | null;
    email?: string | null;
    createdAt: string;
    read?: boolean | null;
    expirationUnixTime: number;
    token?: string | null;
    stepName?: string | null;
    updatedAt: string;
  } | null;
};

export type UpdateInAppNotificationMutationVariables = {
  input: UpdateInAppNotificationInput;
  condition?: ModelinAppNotificationConditionInput | null;
};

export type UpdateInAppNotificationMutation = {
  updateInAppNotification?: {
    __typename: 'inAppNotification';
    id: string;
    owner?: string | null;
    teamMemberId?: string | null;
    projectId?: string | null;
    sessionId?: string | null;
    type?: string | null;
    value?: string | null;
    email?: string | null;
    createdAt: string;
    read?: boolean | null;
    expirationUnixTime: number;
    token?: string | null;
    stepName?: string | null;
    updatedAt: string;
  } | null;
};

export type DeleteInAppNotificationMutationVariables = {
  input: DeleteInAppNotificationInput;
  condition?: ModelinAppNotificationConditionInput | null;
};

export type DeleteInAppNotificationMutation = {
  deleteInAppNotification?: {
    __typename: 'inAppNotification';
    id: string;
    owner?: string | null;
    teamMemberId?: string | null;
    projectId?: string | null;
    sessionId?: string | null;
    type?: string | null;
    value?: string | null;
    email?: string | null;
    createdAt: string;
    read?: boolean | null;
    expirationUnixTime: number;
    token?: string | null;
    stepName?: string | null;
    updatedAt: string;
  } | null;
};

export type CreateConferenceRoomConnectionMutationVariables = {
  input: CreateConferenceRoomConnectionInput;
  condition?: ModelconferenceRoomConnectionConditionInput | null;
};

export type CreateConferenceRoomConnectionMutation = {
  createConferenceRoomConnection?: {
    __typename: 'conferenceRoomConnection';
    id: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateConferenceRoomConnectionMutationVariables = {
  input: UpdateConferenceRoomConnectionInput;
  condition?: ModelconferenceRoomConnectionConditionInput | null;
};

export type UpdateConferenceRoomConnectionMutation = {
  updateConferenceRoomConnection?: {
    __typename: 'conferenceRoomConnection';
    id: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteConferenceRoomConnectionMutationVariables = {
  input: DeleteConferenceRoomConnectionInput;
  condition?: ModelconferenceRoomConnectionConditionInput | null;
};

export type DeleteConferenceRoomConnectionMutation = {
  deleteConferenceRoomConnection?: {
    __typename: 'conferenceRoomConnection';
    id: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateConferenceRoomMutationVariables = {
  input: CreateConferenceRoomInput;
  condition?: ModelconferenceRoomConditionInput | null;
};

export type CreateConferenceRoomMutation = {
  createConferenceRoom?: {
    __typename: 'conferenceRoom';
    id: string;
    owner: string;
    roomId: string;
    userId: string;
    active?: boolean | null;
    email?: string | null;
    appToken?: string | null;
    lastSeenAt?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateConferenceRoomMutationVariables = {
  input: UpdateConferenceRoomInput;
  condition?: ModelconferenceRoomConditionInput | null;
};

export type UpdateConferenceRoomMutation = {
  updateConferenceRoom?: {
    __typename: 'conferenceRoom';
    id: string;
    owner: string;
    roomId: string;
    userId: string;
    active?: boolean | null;
    email?: string | null;
    appToken?: string | null;
    lastSeenAt?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteConferenceRoomMutationVariables = {
  input: DeleteConferenceRoomInput;
  condition?: ModelconferenceRoomConditionInput | null;
};

export type DeleteConferenceRoomMutation = {
  deleteConferenceRoom?: {
    __typename: 'conferenceRoom';
    id: string;
    owner: string;
    roomId: string;
    userId: string;
    active?: boolean | null;
    email?: string | null;
    appToken?: string | null;
    lastSeenAt?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateConferenceRoomActivityMutationVariables = {
  input: CreateConferenceRoomActivityInput;
  condition?: ModelconferenceRoomActivityConditionInput | null;
};

export type CreateConferenceRoomActivityMutation = {
  createConferenceRoomActivity?: {
    __typename: 'conferenceRoomActivity';
    id: string;
    roomName?: string | null;
    createdAt: string;
    expirationUnixTime: number;
    updatedAt?: string | null;
    appToken?: string | null;
    type?: string | null;
  } | null;
};

export type UpdateConferenceRoomActivityMutationVariables = {
  input: UpdateConferenceRoomActivityInput;
  condition?: ModelconferenceRoomActivityConditionInput | null;
};

export type UpdateConferenceRoomActivityMutation = {
  updateConferenceRoomActivity?: {
    __typename: 'conferenceRoomActivity';
    id: string;
    roomName?: string | null;
    createdAt: string;
    expirationUnixTime: number;
    updatedAt?: string | null;
    appToken?: string | null;
    type?: string | null;
  } | null;
};

export type DeleteConferenceRoomActivityMutationVariables = {
  input: DeleteConferenceRoomActivityInput;
  condition?: ModelconferenceRoomActivityConditionInput | null;
};

export type DeleteConferenceRoomActivityMutation = {
  deleteConferenceRoomActivity?: {
    __typename: 'conferenceRoomActivity';
    id: string;
    roomName?: string | null;
    createdAt: string;
    expirationUnixTime: number;
    updatedAt?: string | null;
    appToken?: string | null;
    type?: string | null;
  } | null;
};

export type CreateSessionMutationVariables = {
  input: CreateSessionInput;
  condition?: ModelsessionConditionInput | null;
};

export type CreateSessionMutation = {
  createSession?: {
    __typename: 'session';
    id: string;
    owner?: string | null;
    assignee?: string | null;
    appKey?: string | null;
    projectId?: string | null;
    createdAt: string;
    updatedAt: string;
    lastEventAt: string;
    sessionTime?: string | null;
    name?: string | null;
    email?: string | null;
    phone?: string | null;
    read?: boolean | null;
    archived?: boolean | null;
    actionNeeded?: boolean | null;
    totalEvents?: number | null;
    totalResponses?: number | null;
    totalReplies?: number | null;
    parentUrl?: string | null;
    videoResponse?: boolean | null;
    imageResponse?: boolean | null;
    audioResponse?: boolean | null;
    textResponse?: boolean | null;
    formResponse?: boolean | null;
    reachedEnd?: boolean | null;
    isSpam?: boolean | null;
  } | null;
};

export type UpdateSessionMutationVariables = {
  input: UpdateSessionInput;
  condition?: ModelsessionConditionInput | null;
};

export type UpdateSessionMutation = {
  updateSession?: {
    __typename: 'session';
    id: string;
    owner?: string | null;
    assignee?: string | null;
    appKey?: string | null;
    projectId?: string | null;
    createdAt: string;
    updatedAt: string;
    lastEventAt: string;
    sessionTime?: string | null;
    name?: string | null;
    email?: string | null;
    phone?: string | null;
    read?: boolean | null;
    archived?: boolean | null;
    actionNeeded?: boolean | null;
    totalEvents?: number | null;
    totalResponses?: number | null;
    totalReplies?: number | null;
    parentUrl?: string | null;
    videoResponse?: boolean | null;
    imageResponse?: boolean | null;
    audioResponse?: boolean | null;
    textResponse?: boolean | null;
    formResponse?: boolean | null;
    reachedEnd?: boolean | null;
    isSpam?: boolean | null;
  } | null;
};

export type DeleteSessionMutationVariables = {
  input: DeleteSessionInput;
  condition?: ModelsessionConditionInput | null;
};

export type DeleteSessionMutation = {
  deleteSession?: {
    __typename: 'session';
    id: string;
    owner?: string | null;
    assignee?: string | null;
    appKey?: string | null;
    projectId?: string | null;
    createdAt: string;
    updatedAt: string;
    lastEventAt: string;
    sessionTime?: string | null;
    name?: string | null;
    email?: string | null;
    phone?: string | null;
    read?: boolean | null;
    archived?: boolean | null;
    actionNeeded?: boolean | null;
    totalEvents?: number | null;
    totalResponses?: number | null;
    totalReplies?: number | null;
    parentUrl?: string | null;
    videoResponse?: boolean | null;
    imageResponse?: boolean | null;
    audioResponse?: boolean | null;
    textResponse?: boolean | null;
    formResponse?: boolean | null;
    reachedEnd?: boolean | null;
    isSpam?: boolean | null;
  } | null;
};

export type CreateAccountIdentifiedUsersMutationVariables = {
  input: CreateAccountIdentifiedUsersInput;
  condition?: ModelaccountIdentifiedUsersConditionInput | null;
};

export type CreateAccountIdentifiedUsersMutation = {
  createAccountIdentifiedUsers?: {
    __typename: 'accountIdentifiedUsers';
    id: string;
    owner?: string | null;
    appKey: string;
    userId?: string | null;
    email?: string | null;
    phone?: string | null;
    name?: string | null;
    lastSeenAt?: string | null;
    firstSeenAt?: string | null;
    signedUpAt?: string | null;
    sessionCount?: number | null;
    customAttributes?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateAccountIdentifiedUsersMutationVariables = {
  input: UpdateAccountIdentifiedUsersInput;
  condition?: ModelaccountIdentifiedUsersConditionInput | null;
};

export type UpdateAccountIdentifiedUsersMutation = {
  updateAccountIdentifiedUsers?: {
    __typename: 'accountIdentifiedUsers';
    id: string;
    owner?: string | null;
    appKey: string;
    userId?: string | null;
    email?: string | null;
    phone?: string | null;
    name?: string | null;
    lastSeenAt?: string | null;
    firstSeenAt?: string | null;
    signedUpAt?: string | null;
    sessionCount?: number | null;
    customAttributes?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteAccountIdentifiedUsersMutationVariables = {
  input: DeleteAccountIdentifiedUsersInput;
  condition?: ModelaccountIdentifiedUsersConditionInput | null;
};

export type DeleteAccountIdentifiedUsersMutation = {
  deleteAccountIdentifiedUsers?: {
    __typename: 'accountIdentifiedUsers';
    id: string;
    owner?: string | null;
    appKey: string;
    userId?: string | null;
    email?: string | null;
    phone?: string | null;
    name?: string | null;
    lastSeenAt?: string | null;
    firstSeenAt?: string | null;
    signedUpAt?: string | null;
    sessionCount?: number | null;
    customAttributes?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateRuleGroupMutationVariables = {
  input: CreateRuleGroupInput;
  condition?: ModelruleGroupConditionInput | null;
};

export type CreateRuleGroupMutation = {
  createRuleGroup?: {
    __typename: 'ruleGroup';
    id: string;
    appKey: string;
    projectId: string;
    publishId?: string | null;
    owner: string;
    rules: Array<{
      __typename: 'rule';
      key: string;
      type?: string | null;
      condition?: string | null;
      value?: string | null;
      limit?: string | null;
      behaviors?: Array<string | null> | null;
    } | null>;
    enabled?: boolean | null;
    visitorModeEnabled?: boolean | null;
    exitIntent?: boolean | null;
    delayTriggerEnabled?: boolean | null;
    delayTriggerSeconds?: number | null;
    showOnDevice?: string | null;
    showOnDomains?: Array<string | null> | null;
    abTestId?: string | null;
    type?: RuleGroupType | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateRuleGroupMutationVariables = {
  input: UpdateRuleGroupInput;
  condition?: ModelruleGroupConditionInput | null;
};

export type UpdateRuleGroupMutation = {
  updateRuleGroup?: {
    __typename: 'ruleGroup';
    id: string;
    appKey: string;
    projectId: string;
    publishId?: string | null;
    owner: string;
    rules: Array<{
      __typename: 'rule';
      key: string;
      type?: string | null;
      condition?: string | null;
      value?: string | null;
      limit?: string | null;
      behaviors?: Array<string | null> | null;
    } | null>;
    enabled?: boolean | null;
    visitorModeEnabled?: boolean | null;
    exitIntent?: boolean | null;
    delayTriggerEnabled?: boolean | null;
    delayTriggerSeconds?: number | null;
    showOnDevice?: string | null;
    showOnDomains?: Array<string | null> | null;
    abTestId?: string | null;
    type?: RuleGroupType | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteRuleGroupMutationVariables = {
  input: DeleteRuleGroupInput;
  condition?: ModelruleGroupConditionInput | null;
};

export type DeleteRuleGroupMutation = {
  deleteRuleGroup?: {
    __typename: 'ruleGroup';
    id: string;
    appKey: string;
    projectId: string;
    publishId?: string | null;
    owner: string;
    rules: Array<{
      __typename: 'rule';
      key: string;
      type?: string | null;
      condition?: string | null;
      value?: string | null;
      limit?: string | null;
      behaviors?: Array<string | null> | null;
    } | null>;
    enabled?: boolean | null;
    visitorModeEnabled?: boolean | null;
    exitIntent?: boolean | null;
    delayTriggerEnabled?: boolean | null;
    delayTriggerSeconds?: number | null;
    showOnDevice?: string | null;
    showOnDomains?: Array<string | null> | null;
    abTestId?: string | null;
    type?: RuleGroupType | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateIdentifyAttributeMutationVariables = {
  input: CreateIdentifyAttributeInput;
  condition?: ModelidentifyAttributeConditionInput | null;
};

export type CreateIdentifyAttributeMutation = {
  createIdentifyAttribute?: {
    __typename: 'identifyAttribute';
    id: string;
    appKey: string;
    owner: string;
    key?: string | null;
    type?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateIdentifyAttributeMutationVariables = {
  input: UpdateIdentifyAttributeInput;
  condition?: ModelidentifyAttributeConditionInput | null;
};

export type UpdateIdentifyAttributeMutation = {
  updateIdentifyAttribute?: {
    __typename: 'identifyAttribute';
    id: string;
    appKey: string;
    owner: string;
    key?: string | null;
    type?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteIdentifyAttributeMutationVariables = {
  input: DeleteIdentifyAttributeInput;
  condition?: ModelidentifyAttributeConditionInput | null;
};

export type DeleteIdentifyAttributeMutation = {
  deleteIdentifyAttribute?: {
    __typename: 'identifyAttribute';
    id: string;
    appKey: string;
    owner: string;
    key?: string | null;
    type?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateInviteMutationVariables = {
  input: CreateInviteInput;
  condition?: ModelinviteConditionInput | null;
};

export type CreateInviteMutation = {
  createInvite?: {
    __typename: 'invite';
    id: string;
    owner: string;
    appKey: string;
    email?: string | null;
    role: Role;
    status: InviteStatus;
    token?: string | null;
    createdAt?: string | null;
    updatedAt: string;
  } | null;
};

export type UpdateInviteMutationVariables = {
  input: UpdateInviteInput;
  condition?: ModelinviteConditionInput | null;
};

export type UpdateInviteMutation = {
  updateInvite?: {
    __typename: 'invite';
    id: string;
    owner: string;
    appKey: string;
    email?: string | null;
    role: Role;
    status: InviteStatus;
    token?: string | null;
    createdAt?: string | null;
    updatedAt: string;
  } | null;
};

export type DeleteInviteMutationVariables = {
  input: DeleteInviteInput;
  condition?: ModelinviteConditionInput | null;
};

export type DeleteInviteMutation = {
  deleteInvite?: {
    __typename: 'invite';
    id: string;
    owner: string;
    appKey: string;
    email?: string | null;
    role: Role;
    status: InviteStatus;
    token?: string | null;
    createdAt?: string | null;
    updatedAt: string;
  } | null;
};

export type CreateAccountTeamMemberMutationVariables = {
  input: CreateAccountTeamMemberInput;
  condition?: ModelaccountTeamMemberConditionInput | null;
};

export type CreateAccountTeamMemberMutation = {
  createAccountTeamMember?: {
    __typename: 'accountTeamMember';
    id: string;
    owner: string;
    appKey: string;
    email?: string | null;
    role: Role;
    status: InviteStatus;
    inviteToken?: string | null;
    createdAt?: string | null;
    userId?: string | null;
    teamMemberId?: string | null;
    lastLoginAt?: string | null;
    user?: {
      __typename: 'ModeluserSettingsConnection';
      items: Array<{
        __typename: 'userSettings';
        id: string;
        owner?: string | null;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        lastLogin?: string | null;
        source?: string | null;
        token?: string | null;
        appKey?: string | null;
        hasLoggedIn?: boolean | null;
        hasShared?: boolean | null;
        viewedTolstoys?: Array<string | null> | null;
        calendars?: Array<{
          __typename: 'calendar';
          id?: string | null;
          name?: string | null;
          url?: string | null;
        } | null> | null;
        emailAutomationTool?: string | null;
        logoSettings?: string | null;
        googleInfo?: string | null;
        crmData?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    account?: {
      __typename: 'ModelaccountSettingsConnection';
      items: Array<{
        __typename: 'accountSettings';
        id: string;
        appKey: string;
        owner?: string | null;
        name?: string | null;
        logoSettings?: string | null;
        widgetSettings?: string | null;
        downgradeInfo?: string | null;
        liveIds?: Array<string | null> | null;
        url?: string | null;
        logoUrl?: string | null;
        playerLogoUrl?: string | null;
        companyVertical?: string | null;
        companySize?: string | null;
        companyRole?: string | null;
        monthlyTraffic?: string | null;
        useCase?: string | null;
        packageId?: string | null;
        token?: string | null;
        paymentProvider?: string | null;
        paymentSubscriptions?: Array<string | null> | null;
        paymentIdentifier?: string | null;
        packageGroup?: string | null;
        joinedPackageAt?: string | null;
        trialEndAt?: string | null;
        referral?: string | null;
        crmData?: string | null;
        trialDays?: number | null;
        googleAnalyticsID?: string | null;
        appsInstallationStatuses?: string | null;
        onboardingStatuses?: string | null;
        assetAnalysis?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    notificationSettingsId?: string | null;
    paymentRole?: PaymentRole | null;
    unreadInAppNotificationsCounter?: number | null;
    updatedAt: string;
  } | null;
};

export type UpdateAccountTeamMemberMutationVariables = {
  input: UpdateAccountTeamMemberInput;
  condition?: ModelaccountTeamMemberConditionInput | null;
};

export type UpdateAccountTeamMemberMutation = {
  updateAccountTeamMember?: {
    __typename: 'accountTeamMember';
    id: string;
    owner: string;
    appKey: string;
    email?: string | null;
    role: Role;
    status: InviteStatus;
    inviteToken?: string | null;
    createdAt?: string | null;
    userId?: string | null;
    teamMemberId?: string | null;
    lastLoginAt?: string | null;
    user?: {
      __typename: 'ModeluserSettingsConnection';
      items: Array<{
        __typename: 'userSettings';
        id: string;
        owner?: string | null;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        lastLogin?: string | null;
        source?: string | null;
        token?: string | null;
        appKey?: string | null;
        hasLoggedIn?: boolean | null;
        hasShared?: boolean | null;
        viewedTolstoys?: Array<string | null> | null;
        calendars?: Array<{
          __typename: 'calendar';
          id?: string | null;
          name?: string | null;
          url?: string | null;
        } | null> | null;
        emailAutomationTool?: string | null;
        logoSettings?: string | null;
        googleInfo?: string | null;
        crmData?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    account?: {
      __typename: 'ModelaccountSettingsConnection';
      items: Array<{
        __typename: 'accountSettings';
        id: string;
        appKey: string;
        owner?: string | null;
        name?: string | null;
        logoSettings?: string | null;
        widgetSettings?: string | null;
        downgradeInfo?: string | null;
        liveIds?: Array<string | null> | null;
        url?: string | null;
        logoUrl?: string | null;
        playerLogoUrl?: string | null;
        companyVertical?: string | null;
        companySize?: string | null;
        companyRole?: string | null;
        monthlyTraffic?: string | null;
        useCase?: string | null;
        packageId?: string | null;
        token?: string | null;
        paymentProvider?: string | null;
        paymentSubscriptions?: Array<string | null> | null;
        paymentIdentifier?: string | null;
        packageGroup?: string | null;
        joinedPackageAt?: string | null;
        trialEndAt?: string | null;
        referral?: string | null;
        crmData?: string | null;
        trialDays?: number | null;
        googleAnalyticsID?: string | null;
        appsInstallationStatuses?: string | null;
        onboardingStatuses?: string | null;
        assetAnalysis?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    notificationSettingsId?: string | null;
    paymentRole?: PaymentRole | null;
    unreadInAppNotificationsCounter?: number | null;
    updatedAt: string;
  } | null;
};

export type DeleteAccountTeamMemberMutationVariables = {
  input: DeleteAccountTeamMemberInput;
  condition?: ModelaccountTeamMemberConditionInput | null;
};

export type DeleteAccountTeamMemberMutation = {
  deleteAccountTeamMember?: {
    __typename: 'accountTeamMember';
    id: string;
    owner: string;
    appKey: string;
    email?: string | null;
    role: Role;
    status: InviteStatus;
    inviteToken?: string | null;
    createdAt?: string | null;
    userId?: string | null;
    teamMemberId?: string | null;
    lastLoginAt?: string | null;
    user?: {
      __typename: 'ModeluserSettingsConnection';
      items: Array<{
        __typename: 'userSettings';
        id: string;
        owner?: string | null;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        lastLogin?: string | null;
        source?: string | null;
        token?: string | null;
        appKey?: string | null;
        hasLoggedIn?: boolean | null;
        hasShared?: boolean | null;
        viewedTolstoys?: Array<string | null> | null;
        calendars?: Array<{
          __typename: 'calendar';
          id?: string | null;
          name?: string | null;
          url?: string | null;
        } | null> | null;
        emailAutomationTool?: string | null;
        logoSettings?: string | null;
        googleInfo?: string | null;
        crmData?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    account?: {
      __typename: 'ModelaccountSettingsConnection';
      items: Array<{
        __typename: 'accountSettings';
        id: string;
        appKey: string;
        owner?: string | null;
        name?: string | null;
        logoSettings?: string | null;
        widgetSettings?: string | null;
        downgradeInfo?: string | null;
        liveIds?: Array<string | null> | null;
        url?: string | null;
        logoUrl?: string | null;
        playerLogoUrl?: string | null;
        companyVertical?: string | null;
        companySize?: string | null;
        companyRole?: string | null;
        monthlyTraffic?: string | null;
        useCase?: string | null;
        packageId?: string | null;
        token?: string | null;
        paymentProvider?: string | null;
        paymentSubscriptions?: Array<string | null> | null;
        paymentIdentifier?: string | null;
        packageGroup?: string | null;
        joinedPackageAt?: string | null;
        trialEndAt?: string | null;
        referral?: string | null;
        crmData?: string | null;
        trialDays?: number | null;
        googleAnalyticsID?: string | null;
        appsInstallationStatuses?: string | null;
        onboardingStatuses?: string | null;
        assetAnalysis?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    notificationSettingsId?: string | null;
    paymentRole?: PaymentRole | null;
    unreadInAppNotificationsCounter?: number | null;
    updatedAt: string;
  } | null;
};

export type CreateTeamMemberSettingsMutationVariables = {
  input: CreateTeamMemberSettingsInput;
  condition?: ModelteamMemberSettingsConditionInput | null;
};

export type CreateTeamMemberSettingsMutation = {
  createTeamMemberSettings?: {
    __typename: 'teamMemberSettings';
    id: string;
    teamMemberId?: string | null;
    notificationSettingsId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateTeamMemberSettingsMutationVariables = {
  input: UpdateTeamMemberSettingsInput;
  condition?: ModelteamMemberSettingsConditionInput | null;
};

export type UpdateTeamMemberSettingsMutation = {
  updateTeamMemberSettings?: {
    __typename: 'teamMemberSettings';
    id: string;
    teamMemberId?: string | null;
    notificationSettingsId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteTeamMemberSettingsMutationVariables = {
  input: DeleteTeamMemberSettingsInput;
  condition?: ModelteamMemberSettingsConditionInput | null;
};

export type DeleteTeamMemberSettingsMutation = {
  deleteTeamMemberSettings?: {
    __typename: 'teamMemberSettings';
    id: string;
    teamMemberId?: string | null;
    notificationSettingsId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateNotificationSettingsMutationVariables = {
  input: CreateNotificationSettingsInput;
  condition?: ModelnotificationSettingsConditionInput | null;
};

export type CreateNotificationSettingsMutation = {
  createNotificationSettings?: {
    __typename: 'notificationSettings';
    id: string;
    owner: string;
    projectId?: string | null;
    email?: {
      __typename: 'sharedNotificationSettings';
      showAnonymous?: boolean | null;
      enabled?: Array<string | null> | null;
      digest?: Array<string | null> | null;
    } | null;
    inApp?: {
      __typename: 'sharedNotificationSettings';
      showAnonymous?: boolean | null;
      enabled?: Array<string | null> | null;
      digest?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateNotificationSettingsMutationVariables = {
  input: UpdateNotificationSettingsInput;
  condition?: ModelnotificationSettingsConditionInput | null;
};

export type UpdateNotificationSettingsMutation = {
  updateNotificationSettings?: {
    __typename: 'notificationSettings';
    id: string;
    owner: string;
    projectId?: string | null;
    email?: {
      __typename: 'sharedNotificationSettings';
      showAnonymous?: boolean | null;
      enabled?: Array<string | null> | null;
      digest?: Array<string | null> | null;
    } | null;
    inApp?: {
      __typename: 'sharedNotificationSettings';
      showAnonymous?: boolean | null;
      enabled?: Array<string | null> | null;
      digest?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteNotificationSettingsMutationVariables = {
  input: DeleteNotificationSettingsInput;
  condition?: ModelnotificationSettingsConditionInput | null;
};

export type DeleteNotificationSettingsMutation = {
  deleteNotificationSettings?: {
    __typename: 'notificationSettings';
    id: string;
    owner: string;
    projectId?: string | null;
    email?: {
      __typename: 'sharedNotificationSettings';
      showAnonymous?: boolean | null;
      enabled?: Array<string | null> | null;
      digest?: Array<string | null> | null;
    } | null;
    inApp?: {
      __typename: 'sharedNotificationSettings';
      showAnonymous?: boolean | null;
      enabled?: Array<string | null> | null;
      digest?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateFolderMutationVariables = {
  input: CreateFolderInput;
  condition?: ModelfolderConditionInput | null;
};

export type CreateFolderMutation = {
  createFolder?: {
    __typename: 'folder';
    id: string;
    owner?: string | null;
    name: string;
    appKey: string;
    private?: boolean | null;
    type?: FolderType | null;
    appUrl?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateFolderMutationVariables = {
  input: UpdateFolderInput;
  condition?: ModelfolderConditionInput | null;
};

export type UpdateFolderMutation = {
  updateFolder?: {
    __typename: 'folder';
    id: string;
    owner?: string | null;
    name: string;
    appKey: string;
    private?: boolean | null;
    type?: FolderType | null;
    appUrl?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteFolderMutationVariables = {
  input: DeleteFolderInput;
  condition?: ModelfolderConditionInput | null;
};

export type DeleteFolderMutation = {
  deleteFolder?: {
    __typename: 'folder';
    id: string;
    owner?: string | null;
    name: string;
    appKey: string;
    private?: boolean | null;
    type?: FolderType | null;
    appUrl?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateAccountUserMutationVariables = {
  input: CreateAccountUserInput;
  condition?: ModelaccountUserConditionInput | null;
};

export type CreateAccountUserMutation = {
  createAccountUser?: {
    __typename: 'accountUser';
    id: string;
    appKey: string;
    email?: string | null;
    name?: string | null;
    phone?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    lastSeenAt?: string | null;
    emailVerified?: boolean | null;
    sessionCount?: number | null;
    customAttributes?: string | null;
  } | null;
};

export type UpdateAccountUserMutationVariables = {
  input: UpdateAccountUserInput;
  condition?: ModelaccountUserConditionInput | null;
};

export type UpdateAccountUserMutation = {
  updateAccountUser?: {
    __typename: 'accountUser';
    id: string;
    appKey: string;
    email?: string | null;
    name?: string | null;
    phone?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    lastSeenAt?: string | null;
    emailVerified?: boolean | null;
    sessionCount?: number | null;
    customAttributes?: string | null;
  } | null;
};

export type DeleteAccountUserMutationVariables = {
  input: DeleteAccountUserInput;
  condition?: ModelaccountUserConditionInput | null;
};

export type DeleteAccountUserMutation = {
  deleteAccountUser?: {
    __typename: 'accountUser';
    id: string;
    appKey: string;
    email?: string | null;
    name?: string | null;
    phone?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    lastSeenAt?: string | null;
    emailVerified?: boolean | null;
    sessionCount?: number | null;
    customAttributes?: string | null;
  } | null;
};

export type CreateAccountUsageMutationVariables = {
  input: CreateAccountUsageInput;
  condition?: ModelaccountUsageConditionInput | null;
};

export type CreateAccountUsageMutation = {
  createAccountUsage?: {
    __typename: 'accountUsage';
    id: string;
    appKey: string;
    featureId: string;
    usage?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type UpdateAccountUsageMutationVariables = {
  input: UpdateAccountUsageInput;
  condition?: ModelaccountUsageConditionInput | null;
};

export type UpdateAccountUsageMutation = {
  updateAccountUsage?: {
    __typename: 'accountUsage';
    id: string;
    appKey: string;
    featureId: string;
    usage?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type DeleteAccountUsageMutationVariables = {
  input: DeleteAccountUsageInput;
  condition?: ModelaccountUsageConditionInput | null;
};

export type DeleteAccountUsageMutation = {
  deleteAccountUsage?: {
    __typename: 'accountUsage';
    id: string;
    appKey: string;
    featureId: string;
    usage?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type CreatePlayerConfigMutationVariables = {
  input: CreatePlayerConfigInput;
  condition?: ModelplayerConfigConditionInput | null;
};

export type CreatePlayerConfigMutation = {
  createPlayerConfig?: {
    __typename: 'playerConfig';
    id: string;
    appKey: string;
    publishId: string;
    projectId: string;
    config?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdatePlayerConfigMutationVariables = {
  input: UpdatePlayerConfigInput;
  condition?: ModelplayerConfigConditionInput | null;
};

export type UpdatePlayerConfigMutation = {
  updatePlayerConfig?: {
    __typename: 'playerConfig';
    id: string;
    appKey: string;
    publishId: string;
    projectId: string;
    config?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeletePlayerConfigMutationVariables = {
  input: DeletePlayerConfigInput;
  condition?: ModelplayerConfigConditionInput | null;
};

export type DeletePlayerConfigMutation = {
  deletePlayerConfig?: {
    __typename: 'playerConfig';
    id: string;
    appKey: string;
    publishId: string;
    projectId: string;
    config?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateWidgetConfigMutationVariables = {
  input: CreateWidgetConfigInput;
  condition?: ModelwidgetConfigConditionInput | null;
};

export type CreateWidgetConfigMutation = {
  createWidgetConfig?: {
    __typename: 'widgetConfig';
    id: string;
    appKey: string;
    publishId: string;
    projectId: string;
    config?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateWidgetConfigMutationVariables = {
  input: UpdateWidgetConfigInput;
  condition?: ModelwidgetConfigConditionInput | null;
};

export type UpdateWidgetConfigMutation = {
  updateWidgetConfig?: {
    __typename: 'widgetConfig';
    id: string;
    appKey: string;
    publishId: string;
    projectId: string;
    config?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteWidgetConfigMutationVariables = {
  input: DeleteWidgetConfigInput;
  condition?: ModelwidgetConfigConditionInput | null;
};

export type DeleteWidgetConfigMutation = {
  deleteWidgetConfig?: {
    __typename: 'widgetConfig';
    id: string;
    appKey: string;
    publishId: string;
    projectId: string;
    config?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateAccountPackageMutationVariables = {
  input: CreateAccountPackageInput;
  condition?: ModelaccountPackageConditionInput | null;
};

export type CreateAccountPackageMutation = {
  createAccountPackage?: {
    __typename: 'accountPackage';
    id: string;
    appKey: string;
    packageId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    app?: TolstoyApp | null;
    featuresPackageId?: string | null;
    group?: string | null;
  } | null;
};

export type UpdateAccountPackageMutationVariables = {
  input: UpdateAccountPackageInput;
  condition?: ModelaccountPackageConditionInput | null;
};

export type UpdateAccountPackageMutation = {
  updateAccountPackage?: {
    __typename: 'accountPackage';
    id: string;
    appKey: string;
    packageId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    app?: TolstoyApp | null;
    featuresPackageId?: string | null;
    group?: string | null;
  } | null;
};

export type DeleteAccountPackageMutationVariables = {
  input: DeleteAccountPackageInput;
  condition?: ModelaccountPackageConditionInput | null;
};

export type DeleteAccountPackageMutation = {
  deleteAccountPackage?: {
    __typename: 'accountPackage';
    id: string;
    appKey: string;
    packageId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    app?: TolstoyApp | null;
    featuresPackageId?: string | null;
    group?: string | null;
  } | null;
};

export type CreatePackageMutationVariables = {
  input: CreatePackageInput;
  condition?: ModelpackageConditionInput | null;
};

export type CreatePackageMutation = {
  createPackage?: {
    __typename: 'package';
    id: string;
    name?: string | null;
    description?: string | null;
    stripeProducts?: Array<{
      __typename: 'stripeProduct';
      type?: string | null;
      price?: string | null;
    } | null> | null;
    trialPeriodDays?: number | null;
    maxTrialPeriodDays?: number | null;
    type?: string | null;
    details?: string | null;
    group?: string | null;
    order?: number | null;
    featuresPackageId?: string | null;
    addons?: Array<string | null> | null;
    app?: TolstoyApp | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdatePackageMutationVariables = {
  input: UpdatePackageInput;
  condition?: ModelpackageConditionInput | null;
};

export type UpdatePackageMutation = {
  updatePackage?: {
    __typename: 'package';
    id: string;
    name?: string | null;
    description?: string | null;
    stripeProducts?: Array<{
      __typename: 'stripeProduct';
      type?: string | null;
      price?: string | null;
    } | null> | null;
    trialPeriodDays?: number | null;
    maxTrialPeriodDays?: number | null;
    type?: string | null;
    details?: string | null;
    group?: string | null;
    order?: number | null;
    featuresPackageId?: string | null;
    addons?: Array<string | null> | null;
    app?: TolstoyApp | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeletePackageMutationVariables = {
  input: DeletePackageInput;
  condition?: ModelpackageConditionInput | null;
};

export type DeletePackageMutation = {
  deletePackage?: {
    __typename: 'package';
    id: string;
    name?: string | null;
    description?: string | null;
    stripeProducts?: Array<{
      __typename: 'stripeProduct';
      type?: string | null;
      price?: string | null;
    } | null> | null;
    trialPeriodDays?: number | null;
    maxTrialPeriodDays?: number | null;
    type?: string | null;
    details?: string | null;
    group?: string | null;
    order?: number | null;
    featuresPackageId?: string | null;
    addons?: Array<string | null> | null;
    app?: TolstoyApp | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateExperimentMutationVariables = {
  input: CreateExperimentInput;
  condition?: ModelexperimentConditionInput | null;
};

export type CreateExperimentMutation = {
  createExperiment?: {
    __typename: 'experiment';
    id: string;
    featureId: string;
    name?: string | null;
    description?: string | null;
    expireAt?: string | null;
    type?: string | null;
    groups?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateExperimentMutationVariables = {
  input: UpdateExperimentInput;
  condition?: ModelexperimentConditionInput | null;
};

export type UpdateExperimentMutation = {
  updateExperiment?: {
    __typename: 'experiment';
    id: string;
    featureId: string;
    name?: string | null;
    description?: string | null;
    expireAt?: string | null;
    type?: string | null;
    groups?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteExperimentMutationVariables = {
  input: DeleteExperimentInput;
  condition?: ModelexperimentConditionInput | null;
};

export type DeleteExperimentMutation = {
  deleteExperiment?: {
    __typename: 'experiment';
    id: string;
    featureId: string;
    name?: string | null;
    description?: string | null;
    expireAt?: string | null;
    type?: string | null;
    groups?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreatePaymentMutationVariables = {
  input: CreatePaymentInput;
  condition?: ModelpaymentConditionInput | null;
};

export type CreatePaymentMutation = {
  createPayment?: {
    __typename: 'payment';
    id: string;
    subscriptionId?: string | null;
    appKey?: string | null;
    reportAt?: string | null;
    usagePrice?: number | null;
    usageQuantity?: number | null;
    type?: string | null;
    provider?: string | null;
    identifier?: string | null;
    packageId?: string | null;
    packageType?: string | null;
    dailyUsagePrice?: number | null;
    aggregatedPrice?: number | null;
    aggregatedUsageQuantity?: number | null;
    dailyUsageQuantity?: number | null;
    seatsPrice?: number | null;
    price?: number | null;
    interval?: string | null;
    numberOfProTeamMembers?: number | null;
    invoiceId?: string | null;
    invoiceItemId?: string | null;
    paymentSubscriptionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdatePaymentMutationVariables = {
  input: UpdatePaymentInput;
  condition?: ModelpaymentConditionInput | null;
};

export type UpdatePaymentMutation = {
  updatePayment?: {
    __typename: 'payment';
    id: string;
    subscriptionId?: string | null;
    appKey?: string | null;
    reportAt?: string | null;
    usagePrice?: number | null;
    usageQuantity?: number | null;
    type?: string | null;
    provider?: string | null;
    identifier?: string | null;
    packageId?: string | null;
    packageType?: string | null;
    dailyUsagePrice?: number | null;
    aggregatedPrice?: number | null;
    aggregatedUsageQuantity?: number | null;
    dailyUsageQuantity?: number | null;
    seatsPrice?: number | null;
    price?: number | null;
    interval?: string | null;
    numberOfProTeamMembers?: number | null;
    invoiceId?: string | null;
    invoiceItemId?: string | null;
    paymentSubscriptionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeletePaymentMutationVariables = {
  input: DeletePaymentInput;
  condition?: ModelpaymentConditionInput | null;
};

export type DeletePaymentMutation = {
  deletePayment?: {
    __typename: 'payment';
    id: string;
    subscriptionId?: string | null;
    appKey?: string | null;
    reportAt?: string | null;
    usagePrice?: number | null;
    usageQuantity?: number | null;
    type?: string | null;
    provider?: string | null;
    identifier?: string | null;
    packageId?: string | null;
    packageType?: string | null;
    dailyUsagePrice?: number | null;
    aggregatedPrice?: number | null;
    aggregatedUsageQuantity?: number | null;
    dailyUsageQuantity?: number | null;
    seatsPrice?: number | null;
    price?: number | null;
    interval?: string | null;
    numberOfProTeamMembers?: number | null;
    invoiceId?: string | null;
    invoiceItemId?: string | null;
    paymentSubscriptionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateQuizResultMutationVariables = {
  input: CreateQuizResultInput;
  condition?: ModelquizResultConditionInput | null;
};

export type CreateQuizResultMutation = {
  createQuizResult?: {
    __typename: 'quizResult';
    id: string;
    projectId: string;
    appKey?: string | null;
    owner?: string | null;
    createdAt?: string | null;
    url?: string | null;
    title?: string | null;
    imageUrl?: string | null;
    flows?: Array<{
      __typename: 'quizFlow';
      id: string;
      type?: string | null;
      rules?: Array<{
        __typename: 'quizRule';
        stepId: string;
        choices?: Array<string | null> | null;
      } | null> | null;
    } | null> | null;
    isFallback?: boolean | null;
    klaviyoListId?: string | null;
    stepName?: string | null;
    type?: ResultType | null;
    updatedAt: string;
  } | null;
};

export type UpdateQuizResultMutationVariables = {
  input: UpdateQuizResultInput;
  condition?: ModelquizResultConditionInput | null;
};

export type UpdateQuizResultMutation = {
  updateQuizResult?: {
    __typename: 'quizResult';
    id: string;
    projectId: string;
    appKey?: string | null;
    owner?: string | null;
    createdAt?: string | null;
    url?: string | null;
    title?: string | null;
    imageUrl?: string | null;
    flows?: Array<{
      __typename: 'quizFlow';
      id: string;
      type?: string | null;
      rules?: Array<{
        __typename: 'quizRule';
        stepId: string;
        choices?: Array<string | null> | null;
      } | null> | null;
    } | null> | null;
    isFallback?: boolean | null;
    klaviyoListId?: string | null;
    stepName?: string | null;
    type?: ResultType | null;
    updatedAt: string;
  } | null;
};

export type DeleteQuizResultMutationVariables = {
  input: DeleteQuizResultInput;
  condition?: ModelquizResultConditionInput | null;
};

export type DeleteQuizResultMutation = {
  deleteQuizResult?: {
    __typename: 'quizResult';
    id: string;
    projectId: string;
    appKey?: string | null;
    owner?: string | null;
    createdAt?: string | null;
    url?: string | null;
    title?: string | null;
    imageUrl?: string | null;
    flows?: Array<{
      __typename: 'quizFlow';
      id: string;
      type?: string | null;
      rules?: Array<{
        __typename: 'quizRule';
        stepId: string;
        choices?: Array<string | null> | null;
      } | null> | null;
    } | null> | null;
    isFallback?: boolean | null;
    klaviyoListId?: string | null;
    stepName?: string | null;
    type?: ResultType | null;
    updatedAt: string;
  } | null;
};

export type CreateEcomProductMutationVariables = {
  input: CreateEcomProductInput;
  condition?: ModelecomProductConditionInput | null;
};

export type CreateEcomProductMutation = {
  createEcomProduct?: {
    __typename: 'ecomProduct';
    id: string;
    productId: string;
    appKey?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateEcomProductMutationVariables = {
  input: UpdateEcomProductInput;
  condition?: ModelecomProductConditionInput | null;
};

export type UpdateEcomProductMutation = {
  updateEcomProduct?: {
    __typename: 'ecomProduct';
    id: string;
    productId: string;
    appKey?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteEcomProductMutationVariables = {
  input: DeleteEcomProductInput;
  condition?: ModelecomProductConditionInput | null;
};

export type DeleteEcomProductMutation = {
  deleteEcomProduct?: {
    __typename: 'ecomProduct';
    id: string;
    productId: string;
    appKey?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateProductPageSettingsMutationVariables = {
  input: CreateProductPageSettingsInput;
  condition?: ModelproductPageSettingsConditionInput | null;
};

export type CreateProductPageSettingsMutation = {
  createProductPageSettings?: {
    __typename: 'productPageSettings';
    id: string;
    productId?: string | null;
    productUrl?: string | null;
    abTestId?: string | null;
    publishId?: string | null;
    appKey?: string | null;
    widgetType?: WidgetType | null;
    productSource?: ProductSource | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateProductPageSettingsMutationVariables = {
  input: UpdateProductPageSettingsInput;
  condition?: ModelproductPageSettingsConditionInput | null;
};

export type UpdateProductPageSettingsMutation = {
  updateProductPageSettings?: {
    __typename: 'productPageSettings';
    id: string;
    productId?: string | null;
    productUrl?: string | null;
    abTestId?: string | null;
    publishId?: string | null;
    appKey?: string | null;
    widgetType?: WidgetType | null;
    productSource?: ProductSource | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteProductPageSettingsMutationVariables = {
  input: DeleteProductPageSettingsInput;
  condition?: ModelproductPageSettingsConditionInput | null;
};

export type DeleteProductPageSettingsMutation = {
  deleteProductPageSettings?: {
    __typename: 'productPageSettings';
    id: string;
    productId?: string | null;
    productUrl?: string | null;
    abTestId?: string | null;
    publishId?: string | null;
    appKey?: string | null;
    widgetType?: WidgetType | null;
    productSource?: ProductSource | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateAbTestMutationVariables = {
  input: CreateAbTestInput;
  condition?: ModelabTestConditionInput | null;
};

export type CreateAbTestMutation = {
  createAbTest?: {
    __typename: 'abTest';
    id: string;
    appKey?: string | null;
    owner?: string | null;
    options?: Array<{
      __typename: 'abTestGroup';
      publishId?: string | null;
      probability?: number | null;
    } | null> | null;
    endAt?: string | null;
    startAt?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateAbTestMutationVariables = {
  input: UpdateAbTestInput;
  condition?: ModelabTestConditionInput | null;
};

export type UpdateAbTestMutation = {
  updateAbTest?: {
    __typename: 'abTest';
    id: string;
    appKey?: string | null;
    owner?: string | null;
    options?: Array<{
      __typename: 'abTestGroup';
      publishId?: string | null;
      probability?: number | null;
    } | null> | null;
    endAt?: string | null;
    startAt?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteAbTestMutationVariables = {
  input: DeleteAbTestInput;
  condition?: ModelabTestConditionInput | null;
};

export type DeleteAbTestMutation = {
  deleteAbTest?: {
    __typename: 'abTest';
    id: string;
    appKey?: string | null;
    owner?: string | null;
    options?: Array<{
      __typename: 'abTestGroup';
      publishId?: string | null;
      probability?: number | null;
    } | null> | null;
    endAt?: string | null;
    startAt?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateIntegrationVodAssetMutationVariables = {
  input: CreateIntegrationVodAssetInput;
  condition?: ModelintegrationVodAssetConditionInput | null;
};

export type CreateIntegrationVodAssetMutation = {
  createIntegrationVodAsset?: {
    __typename: 'integrationVodAsset';
    id: string;
    appKey?: string | null;
    owner?: string | null;
    app?: string | null;
    appId?: string | null;
    externalId?: string | null;
    externalCreatedAt?: string | null;
    vodId?: string | null;
    seen?: boolean | null;
    title?: string | null;
    thumbnailUrl?: string | null;
    downloadUrl?: string | null;
    status?: StatusTypes | null;
    createdAt?: string | null;
    updatedAt: string;
  } | null;
};

export type UpdateIntegrationVodAssetMutationVariables = {
  input: UpdateIntegrationVodAssetInput;
  condition?: ModelintegrationVodAssetConditionInput | null;
};

export type UpdateIntegrationVodAssetMutation = {
  updateIntegrationVodAsset?: {
    __typename: 'integrationVodAsset';
    id: string;
    appKey?: string | null;
    owner?: string | null;
    app?: string | null;
    appId?: string | null;
    externalId?: string | null;
    externalCreatedAt?: string | null;
    vodId?: string | null;
    seen?: boolean | null;
    title?: string | null;
    thumbnailUrl?: string | null;
    downloadUrl?: string | null;
    status?: StatusTypes | null;
    createdAt?: string | null;
    updatedAt: string;
  } | null;
};

export type DeleteIntegrationVodAssetMutationVariables = {
  input: DeleteIntegrationVodAssetInput;
  condition?: ModelintegrationVodAssetConditionInput | null;
};

export type DeleteIntegrationVodAssetMutation = {
  deleteIntegrationVodAsset?: {
    __typename: 'integrationVodAsset';
    id: string;
    appKey?: string | null;
    owner?: string | null;
    app?: string | null;
    appId?: string | null;
    externalId?: string | null;
    externalCreatedAt?: string | null;
    vodId?: string | null;
    seen?: boolean | null;
    title?: string | null;
    thumbnailUrl?: string | null;
    downloadUrl?: string | null;
    status?: StatusTypes | null;
    createdAt?: string | null;
    updatedAt: string;
  } | null;
};

export type CreateVodConnectionMutationVariables = {
  input: CreateVodConnectionInput;
  condition?: ModelvodConnectionConditionInput | null;
};

export type CreateVodConnectionMutation = {
  createVodConnection?: {
    __typename: 'vodConnection';
    id: string;
    appKey?: string | null;
    vodAssetId?: string | null;
    productId?: string | null;
    externalProductId?: string | null;
    collectionId?: string | null;
    provider?: VodConnectionProvider | null;
    tag?: string | null;
    createdAt?: string | null;
    typeKey?: string | null;
    type?: VodConnectionType | null;
    appUrl?: string | null;
    label?: string | null;
    vodLabelId?: string | null;
    vodSource?: string | null;
    vodType?: string | null;
    variantIds?: Array<string | null> | null;
    projectId?: string | null;
    orderIndex?: number | null;
    source?: string | null;
    updatedAt: string;
  } | null;
};

export type UpdateVodConnectionMutationVariables = {
  input: UpdateVodConnectionInput;
  condition?: ModelvodConnectionConditionInput | null;
};

export type UpdateVodConnectionMutation = {
  updateVodConnection?: {
    __typename: 'vodConnection';
    id: string;
    appKey?: string | null;
    vodAssetId?: string | null;
    productId?: string | null;
    externalProductId?: string | null;
    collectionId?: string | null;
    provider?: VodConnectionProvider | null;
    tag?: string | null;
    createdAt?: string | null;
    typeKey?: string | null;
    type?: VodConnectionType | null;
    appUrl?: string | null;
    label?: string | null;
    vodLabelId?: string | null;
    vodSource?: string | null;
    vodType?: string | null;
    variantIds?: Array<string | null> | null;
    projectId?: string | null;
    orderIndex?: number | null;
    source?: string | null;
    updatedAt: string;
  } | null;
};

export type DeleteVodConnectionMutationVariables = {
  input: DeleteVodConnectionInput;
  condition?: ModelvodConnectionConditionInput | null;
};

export type DeleteVodConnectionMutation = {
  deleteVodConnection?: {
    __typename: 'vodConnection';
    id: string;
    appKey?: string | null;
    vodAssetId?: string | null;
    productId?: string | null;
    externalProductId?: string | null;
    collectionId?: string | null;
    provider?: VodConnectionProvider | null;
    tag?: string | null;
    createdAt?: string | null;
    typeKey?: string | null;
    type?: VodConnectionType | null;
    appUrl?: string | null;
    label?: string | null;
    vodLabelId?: string | null;
    vodSource?: string | null;
    vodType?: string | null;
    variantIds?: Array<string | null> | null;
    projectId?: string | null;
    orderIndex?: number | null;
    source?: string | null;
    updatedAt: string;
  } | null;
};

export type CreateCustomerMutationVariables = {
  input: CreateCustomerInput;
  condition?: ModelcustomerConditionInput | null;
};

export type CreateCustomerMutation = {
  createCustomer?: {
    __typename: 'customer';
    id: string;
    appKey: string;
    appUrl: string;
    externalId?: string | null;
    googleId?: string | null;
    anonymousIds?: Array<string | null> | null;
    email: string;
    phone?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    acceptsMarketing?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateCustomerMutationVariables = {
  input: UpdateCustomerInput;
  condition?: ModelcustomerConditionInput | null;
};

export type UpdateCustomerMutation = {
  updateCustomer?: {
    __typename: 'customer';
    id: string;
    appKey: string;
    appUrl: string;
    externalId?: string | null;
    googleId?: string | null;
    anonymousIds?: Array<string | null> | null;
    email: string;
    phone?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    acceptsMarketing?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteCustomerMutationVariables = {
  input: DeleteCustomerInput;
  condition?: ModelcustomerConditionInput | null;
};

export type DeleteCustomerMutation = {
  deleteCustomer?: {
    __typename: 'customer';
    id: string;
    appKey: string;
    appUrl: string;
    externalId?: string | null;
    googleId?: string | null;
    anonymousIds?: Array<string | null> | null;
    email: string;
    phone?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    acceptsMarketing?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateVodLabelMutationVariables = {
  input: CreateVodLabelInput;
  condition?: ModelvodLabelConditionInput | null;
};

export type CreateVodLabelMutation = {
  createVodLabel?: {
    __typename: 'vodLabel';
    id: string;
    appKey: string;
    name: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateVodLabelMutationVariables = {
  input: UpdateVodLabelInput;
  condition?: ModelvodLabelConditionInput | null;
};

export type UpdateVodLabelMutation = {
  updateVodLabel?: {
    __typename: 'vodLabel';
    id: string;
    appKey: string;
    name: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteVodLabelMutationVariables = {
  input: DeleteVodLabelInput;
  condition?: ModelvodLabelConditionInput | null;
};

export type DeleteVodLabelMutation = {
  deleteVodLabel?: {
    __typename: 'vodLabel';
    id: string;
    appKey: string;
    name: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateSuggestedProductAcceptanceMutationVariables = {
  input: CreateSuggestedProductAcceptanceInput;
  condition?: ModelsuggestedProductAcceptanceConditionInput | null;
};

export type CreateSuggestedProductAcceptanceMutation = {
  createSuggestedProductAcceptance?: {
    __typename: 'suggestedProductAcceptance';
    id: string;
    appKey: string;
    vodAssetId: string;
    externalProductId: string;
    accepted: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateSuggestedProductAcceptanceMutationVariables = {
  input: UpdateSuggestedProductAcceptanceInput;
  condition?: ModelsuggestedProductAcceptanceConditionInput | null;
};

export type UpdateSuggestedProductAcceptanceMutation = {
  updateSuggestedProductAcceptance?: {
    __typename: 'suggestedProductAcceptance';
    id: string;
    appKey: string;
    vodAssetId: string;
    externalProductId: string;
    accepted: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteSuggestedProductAcceptanceMutationVariables = {
  input: DeleteSuggestedProductAcceptanceInput;
  condition?: ModelsuggestedProductAcceptanceConditionInput | null;
};

export type DeleteSuggestedProductAcceptanceMutation = {
  deleteSuggestedProductAcceptance?: {
    __typename: 'suggestedProductAcceptance';
    id: string;
    appKey: string;
    vodAssetId: string;
    externalProductId: string;
    accepted: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateCustomerWishListMutationVariables = {
  input: CreateCustomerWishListInput;
  condition?: ModelcustomerWishListConditionInput | null;
};

export type CreateCustomerWishListMutation = {
  createCustomerWishList?: {
    __typename: 'customerWishList';
    id: string;
    appKey: string;
    appUrl: string;
    vodAssetId: string;
    externalCustomerId?: string | null;
    email?: string | null;
    externalProductId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateCustomerWishListMutationVariables = {
  input: UpdateCustomerWishListInput;
  condition?: ModelcustomerWishListConditionInput | null;
};

export type UpdateCustomerWishListMutation = {
  updateCustomerWishList?: {
    __typename: 'customerWishList';
    id: string;
    appKey: string;
    appUrl: string;
    vodAssetId: string;
    externalCustomerId?: string | null;
    email?: string | null;
    externalProductId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteCustomerWishListMutationVariables = {
  input: DeleteCustomerWishListInput;
  condition?: ModelcustomerWishListConditionInput | null;
};

export type DeleteCustomerWishListMutation = {
  deleteCustomerWishList?: {
    __typename: 'customerWishList';
    id: string;
    appKey: string;
    appUrl: string;
    vodAssetId: string;
    externalCustomerId?: string | null;
    email?: string | null;
    externalProductId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateVodConnectionSuggestionMutationVariables = {
  input: CreateVodConnectionSuggestionInput;
  condition?: ModelvodConnectionSuggestionConditionInput | null;
};

export type CreateVodConnectionSuggestionMutation = {
  createVodConnectionSuggestion?: {
    __typename: 'vodConnectionSuggestion';
    id: string;
    appKey?: string | null;
    appUrl?: string | null;
    createdAt?: string | null;
    vodAssetId?: string | null;
    externalProductId?: string | null;
    status?: VodConnectionSuggestionStatus | null;
    source?: VodConnectionSuggestionSource | null;
    method?: VodConnectionSuggestionMethod | null;
    methodVersion?: string | null;
    score?: number | null;
    updatedAt: string;
  } | null;
};

export type UpdateVodConnectionSuggestionMutationVariables = {
  input: UpdateVodConnectionSuggestionInput;
  condition?: ModelvodConnectionSuggestionConditionInput | null;
};

export type UpdateVodConnectionSuggestionMutation = {
  updateVodConnectionSuggestion?: {
    __typename: 'vodConnectionSuggestion';
    id: string;
    appKey?: string | null;
    appUrl?: string | null;
    createdAt?: string | null;
    vodAssetId?: string | null;
    externalProductId?: string | null;
    status?: VodConnectionSuggestionStatus | null;
    source?: VodConnectionSuggestionSource | null;
    method?: VodConnectionSuggestionMethod | null;
    methodVersion?: string | null;
    score?: number | null;
    updatedAt: string;
  } | null;
};

export type DeleteVodConnectionSuggestionMutationVariables = {
  input: DeleteVodConnectionSuggestionInput;
  condition?: ModelvodConnectionSuggestionConditionInput | null;
};

export type DeleteVodConnectionSuggestionMutation = {
  deleteVodConnectionSuggestion?: {
    __typename: 'vodConnectionSuggestion';
    id: string;
    appKey?: string | null;
    appUrl?: string | null;
    createdAt?: string | null;
    vodAssetId?: string | null;
    externalProductId?: string | null;
    status?: VodConnectionSuggestionStatus | null;
    source?: VodConnectionSuggestionSource | null;
    method?: VodConnectionSuggestionMethod | null;
    methodVersion?: string | null;
    score?: number | null;
    updatedAt: string;
  } | null;
};

export type GetVodAssetQueryVariables = {
  id: string;
};

export type GetVodAssetQuery = {
  getVodAsset?: {
    __typename: 'vodAsset';
    id: string;
    type?: assetType | null;
    owner?: string | null;
    appKey?: string | null;
    appExternalId?: string | null;
    createdAt?: string | null;
    name: string;
    description?: string | null;
    creatorProfile?: {
      __typename: 'creatorProfile';
      url?: string | null;
    } | null;
    isSoundAllowed?: boolean | null;
    status: string;
    uploadType?: string | null;
    stockAsset?: {
      __typename: 'stockAsset';
      videoUrl?: string | null;
      posterUrl?: string | null;
      gifUrl?: string | null;
      hasOriginal?: boolean | null;
      shopifyFileId?: string | null;
      previewUrl?: string | null;
      previewShopifyFileId?: string | null;
      shopifyPosterUrl?: string | null;
      posterShopifyFileId?: string | null;
      avatarUrl?: string | null;
      audioUrl?: string | null;
      gifShopifyFileId?: string | null;
    } | null;
    duration?: number | null;
    folder?: string | null;
    width?: number | null;
    height?: number | null;
    isVertical?: boolean | null;
    isHDR?: boolean | null;
    trimSettings?: string | null;
    originalVOD?: string | null;
    mirror?: boolean | null;
    recorderVersion?: string | null;
    originalSize?: string | null;
    externalId?: string | null;
    externalProviderData?: string | null;
    isMigratingToShopify?: boolean | null;
    shopifyUploadingStatus?: string | null;
    isEverUsed?: boolean | null;
    captionsSettings?: string | null;
    referenceIds?: Array<string | null> | null;
    vodConnections?: {
      __typename: 'ModelvodConnectionConnection';
      items: Array<{
        __typename: 'vodConnection';
        id: string;
        appKey?: string | null;
        vodAssetId?: string | null;
        productId?: string | null;
        externalProductId?: string | null;
        collectionId?: string | null;
        provider?: VodConnectionProvider | null;
        tag?: string | null;
        createdAt?: string | null;
        typeKey?: string | null;
        type?: VodConnectionType | null;
        appUrl?: string | null;
        label?: string | null;
        vodLabelId?: string | null;
        vodSource?: string | null;
        vodType?: string | null;
        variantIds?: Array<string | null> | null;
        projectId?: string | null;
        orderIndex?: number | null;
        source?: string | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    analysis?: string | null;
    autoGenerated?: boolean | null;
    updatedAt: string;
  } | null;
};

export type ListVodAssetsQueryVariables = {
  filter?: ModelvodAssetFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListVodAssetsQuery = {
  listVodAssets?: {
    __typename: 'ModelvodAssetConnection';
    items: Array<{
      __typename: 'vodAsset';
      id: string;
      type?: assetType | null;
      owner?: string | null;
      appKey?: string | null;
      appExternalId?: string | null;
      createdAt?: string | null;
      name: string;
      description?: string | null;
      creatorProfile?: {
        __typename: 'creatorProfile';
        url?: string | null;
      } | null;
      isSoundAllowed?: boolean | null;
      status: string;
      uploadType?: string | null;
      stockAsset?: {
        __typename: 'stockAsset';
        videoUrl?: string | null;
        posterUrl?: string | null;
        gifUrl?: string | null;
        hasOriginal?: boolean | null;
        shopifyFileId?: string | null;
        previewUrl?: string | null;
        previewShopifyFileId?: string | null;
        shopifyPosterUrl?: string | null;
        posterShopifyFileId?: string | null;
        avatarUrl?: string | null;
        audioUrl?: string | null;
        gifShopifyFileId?: string | null;
      } | null;
      duration?: number | null;
      folder?: string | null;
      width?: number | null;
      height?: number | null;
      isVertical?: boolean | null;
      isHDR?: boolean | null;
      trimSettings?: string | null;
      originalVOD?: string | null;
      mirror?: boolean | null;
      recorderVersion?: string | null;
      originalSize?: string | null;
      externalId?: string | null;
      externalProviderData?: string | null;
      isMigratingToShopify?: boolean | null;
      shopifyUploadingStatus?: string | null;
      isEverUsed?: boolean | null;
      captionsSettings?: string | null;
      referenceIds?: Array<string | null> | null;
      vodConnections?: {
        __typename: 'ModelvodConnectionConnection';
        items: Array<{
          __typename: 'vodConnection';
          id: string;
          appKey?: string | null;
          vodAssetId?: string | null;
          productId?: string | null;
          externalProductId?: string | null;
          collectionId?: string | null;
          provider?: VodConnectionProvider | null;
          tag?: string | null;
          createdAt?: string | null;
          typeKey?: string | null;
          type?: VodConnectionType | null;
          appUrl?: string | null;
          label?: string | null;
          vodLabelId?: string | null;
          vodSource?: string | null;
          vodType?: string | null;
          variantIds?: Array<string | null> | null;
          projectId?: string | null;
          orderIndex?: number | null;
          source?: string | null;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      analysis?: string | null;
      autoGenerated?: boolean | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetVodResponseAssetQueryVariables = {
  id: string;
};

export type GetVodResponseAssetQuery = {
  getVodResponseAsset?: {
    __typename: 'vodResponseAsset';
    id: string;
    owner: string;
    appKey?: string | null;
    createdAt: string;
    status?: string | null;
    uploadType?: string | null;
    duration?: number | null;
    width?: number | null;
    height?: number | null;
    isVertical?: boolean | null;
    isHDR?: boolean | null;
    mirror?: boolean | null;
    originalSize?: string | null;
    email?: string | null;
    projectId?: string | null;
    updatedAt: string;
  } | null;
};

export type ListVodResponseAssetsQueryVariables = {
  filter?: ModelvodResponseAssetFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListVodResponseAssetsQuery = {
  listVodResponseAssets?: {
    __typename: 'ModelvodResponseAssetConnection';
    items: Array<{
      __typename: 'vodResponseAsset';
      id: string;
      owner: string;
      appKey?: string | null;
      createdAt: string;
      status?: string | null;
      uploadType?: string | null;
      duration?: number | null;
      width?: number | null;
      height?: number | null;
      isVertical?: boolean | null;
      isHDR?: boolean | null;
      mirror?: boolean | null;
      originalSize?: string | null;
      email?: string | null;
      projectId?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetProjectQueryVariables = {
  id: string;
};

export type GetProjectQuery = {
  getProject?: {
    __typename: 'project';
    id: string;
    owner?: string | null;
    appKey?: string | null;
    typeKey?: string | null;
    createdAt?: string | null;
    publishId: string;
    name: string;
    folder?: string | null;
    targetPage?: string | null;
    verticalOrientation?: boolean | null;
    widgetPosition?: string | null;
    widgetHeader?: string | null;
    widgetUrls?: Array<string | null> | null;
    description?: string | null;
    emailNotifications?: Array<string | null> | null;
    notificationLevel?: number | null;
    viewerNotificationLevel?: number | null;
    chaptersEnabled?: boolean | null;
    subtitlesEnabled?: boolean | null;
    stepsOrder?: Array<string | null> | null;
    collectText?: string | null;
    collectFields?: Array<{
      __typename: 'inputField';
      key?: string | null;
      name?: string | null;
      type?: string | null;
      placeholder?: string | null;
      link?: string | null;
      required?: boolean | null;
      collect?: boolean | null;
      custom?: boolean | null;
      textColor?: string | null;
      borderColor?: string | null;
      backgroundColor?: string | null;
    } | null> | null;
    collectAfterStep?: Array<string | null> | null;
    cacheLeadFormAnswers?: boolean | null;
    googleAnalyticsID?: string | null;
    facebookAnalyticsID?: string | null;
    totalResponses?: number | null;
    unreadResponses?: number | null;
    tolstoyType?: string | null;
    steps?: {
      __typename: 'ModelprojectStepConnection';
      items: Array<{
        __typename: 'projectStep';
        id: string;
        owner?: string | null;
        appKey?: string | null;
        projectId: string;
        videoId?: string | null;
        name: string;
        type?: string | null;
        description?: string | null;
        script?: string | null;
        question?: string | null;
        questionVisible?: boolean | null;
        answerTime?: number | null;
        answers?: Array<{
          __typename: 'answer';
          key?: string | null;
          text?: string | null;
          next?: string | null;
          value?: string | null;
          type?: string | null;
          disabled?: boolean | null;
        } | null> | null;
        products?: Array<{
          __typename: 'product';
          id?: string | null;
        } | null> | null;
        productsAppUrl?: string | null;
        videoContain?: boolean | null;
        overlayText?: string | null;
        overlayTextColor?: string | null;
        multipleChoiceSelection?: boolean | null;
        defaultResponses?: Array<{
          __typename: 'answer';
          key?: string | null;
          text?: string | null;
          next?: string | null;
          value?: string | null;
          type?: string | null;
          disabled?: boolean | null;
        } | null> | null;
        defaultResponsesEnabled?: boolean | null;
        sideBarButtonsEnabled?: boolean | null;
        feedProductActionButton?: {
          __typename: 'feedProductActionButton';
          type?: string | null;
          url?: string | null;
          text?: string | null;
          isOpenInANewTab?: boolean | null;
          value?: string | null;
          enabled?: boolean | null;
        } | null;
        feedSidePanelType?: FeedSidePanelType | null;
        feedDescriptionActionButton?: {
          __typename: 'feedProductActionButton';
          type?: string | null;
          url?: string | null;
          text?: string | null;
          isOpenInANewTab?: boolean | null;
          value?: string | null;
          enabled?: boolean | null;
        } | null;
        feedSidePanelDescription?: string | null;
        productCtaType?: ProductCtaType | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    stepsCount?: number | null;
    widgetBorderColor?: string | null;
    widgetShape?: string | null;
    widgetSize?: string | null;
    widgetNotificationBadge?: boolean | null;
    videoResolutions?: Array<string | null> | null;
    fastForwardEnabled?: boolean | null;
    rulesEnabled?: boolean | null;
    macro?: boolean | null;
    hideChatPlatform?: boolean | null;
    private?: boolean | null;
    chatLandingPage?: boolean | null;
    widgetSettings?: string | null;
    notificationSettingsId?: string | null;
    collectAfterAnyResponse?: boolean | null;
    startScreenText?: string | null;
    feed?: boolean | null;
    feedAutoScroll?: boolean | null;
    feedShowUnviewedStepsFirst?: boolean | null;
    language?: string | null;
    showButtonsIndex?: boolean | null;
    publishMethod?: publishMethodOptions | null;
    dynamic?: boolean | null;
    feedProductCtaType?: ProductCtaType | null;
    playerSettings?: string | null;
    appUrl?: string | null;
    live?: boolean | null;
    primaryButton?: {
      __typename: 'ctaButtonConfig';
      enabled?: boolean | null;
      ctaType?: ProductCtaType | null;
      buttonText?: string | null;
      value?: string | null;
      isOpenInANewTab?: boolean | null;
    } | null;
    secondaryButton?: {
      __typename: 'ctaButtonConfig';
      enabled?: boolean | null;
      ctaType?: ProductCtaType | null;
      buttonText?: string | null;
      value?: string | null;
      isOpenInANewTab?: boolean | null;
    } | null;
    pdpConfig?: {
      __typename: 'pdpConfig';
      primaryButton?: {
        __typename: 'ctaButtonConfig';
        enabled?: boolean | null;
        ctaType?: ProductCtaType | null;
        buttonText?: string | null;
        value?: string | null;
        isOpenInANewTab?: boolean | null;
      } | null;
      secondaryButton?: {
        __typename: 'ctaButtonConfig';
        enabled?: boolean | null;
        ctaType?: ProductCtaType | null;
        buttonText?: string | null;
        value?: string | null;
        isOpenInANewTab?: boolean | null;
      } | null;
      miniCtaButton?: {
        __typename: 'miniCtaButtonConfig';
        enabled?: boolean | null;
        text?: string | null;
      } | null;
    } | null;
    installationCompleted?: boolean | null;
    installStep?: string | null;
    discover?: boolean | null;
    useCaseName?: string | null;
    heroSettings?: string | null;
    sourceProjectId?: string | null;
    emailSettings?: string | null;
    videoCollectionCampaignSettings?: string | null;
    secretVideoSettings?: string | null;
    integrationSettings?: string | null;
    updatedAt: string;
  } | null;
};

export type ListProjectsQueryVariables = {
  filter?: ModelprojectFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListProjectsQuery = {
  listProjects?: {
    __typename: 'ModelprojectConnection';
    items: Array<{
      __typename: 'project';
      id: string;
      owner?: string | null;
      appKey?: string | null;
      typeKey?: string | null;
      createdAt?: string | null;
      publishId: string;
      name: string;
      folder?: string | null;
      targetPage?: string | null;
      verticalOrientation?: boolean | null;
      widgetPosition?: string | null;
      widgetHeader?: string | null;
      widgetUrls?: Array<string | null> | null;
      description?: string | null;
      emailNotifications?: Array<string | null> | null;
      notificationLevel?: number | null;
      viewerNotificationLevel?: number | null;
      chaptersEnabled?: boolean | null;
      subtitlesEnabled?: boolean | null;
      stepsOrder?: Array<string | null> | null;
      collectText?: string | null;
      collectFields?: Array<{
        __typename: 'inputField';
        key?: string | null;
        name?: string | null;
        type?: string | null;
        placeholder?: string | null;
        link?: string | null;
        required?: boolean | null;
        collect?: boolean | null;
        custom?: boolean | null;
        textColor?: string | null;
        borderColor?: string | null;
        backgroundColor?: string | null;
      } | null> | null;
      collectAfterStep?: Array<string | null> | null;
      cacheLeadFormAnswers?: boolean | null;
      googleAnalyticsID?: string | null;
      facebookAnalyticsID?: string | null;
      totalResponses?: number | null;
      unreadResponses?: number | null;
      tolstoyType?: string | null;
      steps?: {
        __typename: 'ModelprojectStepConnection';
        items: Array<{
          __typename: 'projectStep';
          id: string;
          owner?: string | null;
          appKey?: string | null;
          projectId: string;
          videoId?: string | null;
          name: string;
          type?: string | null;
          description?: string | null;
          script?: string | null;
          question?: string | null;
          questionVisible?: boolean | null;
          answerTime?: number | null;
          answers?: Array<{
            __typename: 'answer';
            key?: string | null;
            text?: string | null;
            next?: string | null;
            value?: string | null;
            type?: string | null;
            disabled?: boolean | null;
          } | null> | null;
          products?: Array<{
            __typename: 'product';
            id?: string | null;
          } | null> | null;
          productsAppUrl?: string | null;
          videoContain?: boolean | null;
          overlayText?: string | null;
          overlayTextColor?: string | null;
          multipleChoiceSelection?: boolean | null;
          defaultResponses?: Array<{
            __typename: 'answer';
            key?: string | null;
            text?: string | null;
            next?: string | null;
            value?: string | null;
            type?: string | null;
            disabled?: boolean | null;
          } | null> | null;
          defaultResponsesEnabled?: boolean | null;
          sideBarButtonsEnabled?: boolean | null;
          feedProductActionButton?: {
            __typename: 'feedProductActionButton';
            type?: string | null;
            url?: string | null;
            text?: string | null;
            isOpenInANewTab?: boolean | null;
            value?: string | null;
            enabled?: boolean | null;
          } | null;
          feedSidePanelType?: FeedSidePanelType | null;
          feedDescriptionActionButton?: {
            __typename: 'feedProductActionButton';
            type?: string | null;
            url?: string | null;
            text?: string | null;
            isOpenInANewTab?: boolean | null;
            value?: string | null;
            enabled?: boolean | null;
          } | null;
          feedSidePanelDescription?: string | null;
          productCtaType?: ProductCtaType | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      stepsCount?: number | null;
      widgetBorderColor?: string | null;
      widgetShape?: string | null;
      widgetSize?: string | null;
      widgetNotificationBadge?: boolean | null;
      videoResolutions?: Array<string | null> | null;
      fastForwardEnabled?: boolean | null;
      rulesEnabled?: boolean | null;
      macro?: boolean | null;
      hideChatPlatform?: boolean | null;
      private?: boolean | null;
      chatLandingPage?: boolean | null;
      widgetSettings?: string | null;
      notificationSettingsId?: string | null;
      collectAfterAnyResponse?: boolean | null;
      startScreenText?: string | null;
      feed?: boolean | null;
      feedAutoScroll?: boolean | null;
      feedShowUnviewedStepsFirst?: boolean | null;
      language?: string | null;
      showButtonsIndex?: boolean | null;
      publishMethod?: publishMethodOptions | null;
      dynamic?: boolean | null;
      feedProductCtaType?: ProductCtaType | null;
      playerSettings?: string | null;
      appUrl?: string | null;
      live?: boolean | null;
      primaryButton?: {
        __typename: 'ctaButtonConfig';
        enabled?: boolean | null;
        ctaType?: ProductCtaType | null;
        buttonText?: string | null;
        value?: string | null;
        isOpenInANewTab?: boolean | null;
      } | null;
      secondaryButton?: {
        __typename: 'ctaButtonConfig';
        enabled?: boolean | null;
        ctaType?: ProductCtaType | null;
        buttonText?: string | null;
        value?: string | null;
        isOpenInANewTab?: boolean | null;
      } | null;
      pdpConfig?: {
        __typename: 'pdpConfig';
        primaryButton?: {
          __typename: 'ctaButtonConfig';
          enabled?: boolean | null;
          ctaType?: ProductCtaType | null;
          buttonText?: string | null;
          value?: string | null;
          isOpenInANewTab?: boolean | null;
        } | null;
        secondaryButton?: {
          __typename: 'ctaButtonConfig';
          enabled?: boolean | null;
          ctaType?: ProductCtaType | null;
          buttonText?: string | null;
          value?: string | null;
          isOpenInANewTab?: boolean | null;
        } | null;
        miniCtaButton?: {
          __typename: 'miniCtaButtonConfig';
          enabled?: boolean | null;
          text?: string | null;
        } | null;
      } | null;
      installationCompleted?: boolean | null;
      installStep?: string | null;
      discover?: boolean | null;
      useCaseName?: string | null;
      heroSettings?: string | null;
      sourceProjectId?: string | null;
      emailSettings?: string | null;
      videoCollectionCampaignSettings?: string | null;
      secretVideoSettings?: string | null;
      integrationSettings?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetProjectStepQueryVariables = {
  id: string;
};

export type GetProjectStepQuery = {
  getProjectStep?: {
    __typename: 'projectStep';
    id: string;
    owner?: string | null;
    appKey?: string | null;
    projectId: string;
    videoId?: string | null;
    name: string;
    type?: string | null;
    description?: string | null;
    script?: string | null;
    question?: string | null;
    questionVisible?: boolean | null;
    answerTime?: number | null;
    answers?: Array<{
      __typename: 'answer';
      key?: string | null;
      text?: string | null;
      next?: string | null;
      value?: string | null;
      type?: string | null;
      disabled?: boolean | null;
    } | null> | null;
    products?: Array<{
      __typename: 'product';
      id?: string | null;
    } | null> | null;
    productsAppUrl?: string | null;
    videoContain?: boolean | null;
    overlayText?: string | null;
    overlayTextColor?: string | null;
    multipleChoiceSelection?: boolean | null;
    defaultResponses?: Array<{
      __typename: 'answer';
      key?: string | null;
      text?: string | null;
      next?: string | null;
      value?: string | null;
      type?: string | null;
      disabled?: boolean | null;
    } | null> | null;
    defaultResponsesEnabled?: boolean | null;
    sideBarButtonsEnabled?: boolean | null;
    feedProductActionButton?: {
      __typename: 'feedProductActionButton';
      type?: string | null;
      url?: string | null;
      text?: string | null;
      isOpenInANewTab?: boolean | null;
      value?: string | null;
      enabled?: boolean | null;
    } | null;
    feedSidePanelType?: FeedSidePanelType | null;
    feedDescriptionActionButton?: {
      __typename: 'feedProductActionButton';
      type?: string | null;
      url?: string | null;
      text?: string | null;
      isOpenInANewTab?: boolean | null;
      value?: string | null;
      enabled?: boolean | null;
    } | null;
    feedSidePanelDescription?: string | null;
    productCtaType?: ProductCtaType | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListProjectStepsQueryVariables = {
  filter?: ModelprojectStepFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListProjectStepsQuery = {
  listProjectSteps?: {
    __typename: 'ModelprojectStepConnection';
    items: Array<{
      __typename: 'projectStep';
      id: string;
      owner?: string | null;
      appKey?: string | null;
      projectId: string;
      videoId?: string | null;
      name: string;
      type?: string | null;
      description?: string | null;
      script?: string | null;
      question?: string | null;
      questionVisible?: boolean | null;
      answerTime?: number | null;
      answers?: Array<{
        __typename: 'answer';
        key?: string | null;
        text?: string | null;
        next?: string | null;
        value?: string | null;
        type?: string | null;
        disabled?: boolean | null;
      } | null> | null;
      products?: Array<{
        __typename: 'product';
        id?: string | null;
      } | null> | null;
      productsAppUrl?: string | null;
      videoContain?: boolean | null;
      overlayText?: string | null;
      overlayTextColor?: string | null;
      multipleChoiceSelection?: boolean | null;
      defaultResponses?: Array<{
        __typename: 'answer';
        key?: string | null;
        text?: string | null;
        next?: string | null;
        value?: string | null;
        type?: string | null;
        disabled?: boolean | null;
      } | null> | null;
      defaultResponsesEnabled?: boolean | null;
      sideBarButtonsEnabled?: boolean | null;
      feedProductActionButton?: {
        __typename: 'feedProductActionButton';
        type?: string | null;
        url?: string | null;
        text?: string | null;
        isOpenInANewTab?: boolean | null;
        value?: string | null;
        enabled?: boolean | null;
      } | null;
      feedSidePanelType?: FeedSidePanelType | null;
      feedDescriptionActionButton?: {
        __typename: 'feedProductActionButton';
        type?: string | null;
        url?: string | null;
        text?: string | null;
        isOpenInANewTab?: boolean | null;
        value?: string | null;
        enabled?: boolean | null;
      } | null;
      feedSidePanelDescription?: string | null;
      productCtaType?: ProductCtaType | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetUserSettingsQueryVariables = {
  id: string;
};

export type GetUserSettingsQuery = {
  getUserSettings?: {
    __typename: 'userSettings';
    id: string;
    owner?: string | null;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    lastLogin?: string | null;
    source?: string | null;
    token?: string | null;
    appKey?: string | null;
    hasLoggedIn?: boolean | null;
    hasShared?: boolean | null;
    viewedTolstoys?: Array<string | null> | null;
    calendars?: Array<{
      __typename: 'calendar';
      id?: string | null;
      name?: string | null;
      url?: string | null;
    } | null> | null;
    emailAutomationTool?: string | null;
    logoSettings?: string | null;
    googleInfo?: string | null;
    crmData?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListUserSettingssQueryVariables = {
  filter?: ModeluserSettingsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListUserSettingssQuery = {
  listUserSettingss?: {
    __typename: 'ModeluserSettingsConnection';
    items: Array<{
      __typename: 'userSettings';
      id: string;
      owner?: string | null;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      lastLogin?: string | null;
      source?: string | null;
      token?: string | null;
      appKey?: string | null;
      hasLoggedIn?: boolean | null;
      hasShared?: boolean | null;
      viewedTolstoys?: Array<string | null> | null;
      calendars?: Array<{
        __typename: 'calendar';
        id?: string | null;
        name?: string | null;
        url?: string | null;
      } | null> | null;
      emailAutomationTool?: string | null;
      logoSettings?: string | null;
      googleInfo?: string | null;
      crmData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetAccountSettingsQueryVariables = {
  id: string;
};

export type GetAccountSettingsQuery = {
  getAccountSettings?: {
    __typename: 'accountSettings';
    id: string;
    appKey: string;
    owner?: string | null;
    name?: string | null;
    logoSettings?: string | null;
    widgetSettings?: string | null;
    downgradeInfo?: string | null;
    liveIds?: Array<string | null> | null;
    url?: string | null;
    logoUrl?: string | null;
    playerLogoUrl?: string | null;
    companyVertical?: string | null;
    companySize?: string | null;
    companyRole?: string | null;
    monthlyTraffic?: string | null;
    useCase?: string | null;
    packageId?: string | null;
    token?: string | null;
    paymentProvider?: string | null;
    paymentSubscriptions?: Array<string | null> | null;
    paymentIdentifier?: string | null;
    packageGroup?: string | null;
    joinedPackageAt?: string | null;
    trialEndAt?: string | null;
    referral?: string | null;
    crmData?: string | null;
    trialDays?: number | null;
    googleAnalyticsID?: string | null;
    appsInstallationStatuses?: string | null;
    onboardingStatuses?: string | null;
    assetAnalysis?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListAccountSettingssQueryVariables = {
  filter?: ModelaccountSettingsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListAccountSettingssQuery = {
  listAccountSettingss?: {
    __typename: 'ModelaccountSettingsConnection';
    items: Array<{
      __typename: 'accountSettings';
      id: string;
      appKey: string;
      owner?: string | null;
      name?: string | null;
      logoSettings?: string | null;
      widgetSettings?: string | null;
      downgradeInfo?: string | null;
      liveIds?: Array<string | null> | null;
      url?: string | null;
      logoUrl?: string | null;
      playerLogoUrl?: string | null;
      companyVertical?: string | null;
      companySize?: string | null;
      companyRole?: string | null;
      monthlyTraffic?: string | null;
      useCase?: string | null;
      packageId?: string | null;
      token?: string | null;
      paymentProvider?: string | null;
      paymentSubscriptions?: Array<string | null> | null;
      paymentIdentifier?: string | null;
      packageGroup?: string | null;
      joinedPackageAt?: string | null;
      trialEndAt?: string | null;
      referral?: string | null;
      crmData?: string | null;
      trialDays?: number | null;
      googleAnalyticsID?: string | null;
      appsInstallationStatuses?: string | null;
      onboardingStatuses?: string | null;
      assetAnalysis?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetAppQueryVariables = {
  id: string;
};

export type GetAppQuery = {
  getApp?: {
    __typename: 'app';
    id: string;
    owner?: string | null;
    appKey?: string | null;
    app?: string | null;
    active?: boolean | null;
    data?: string | null;
    token?: string | null;
    appExternalId?: string | null;
    appUrl?: string | null;
    importSettings?: ImportSettings | null;
    importRange?: number | null;
    syncTagsMode?: string | null;
    syndicateBy?: SyndicateBy | null;
    googleAnalyticsID?: string | null;
    settings?: string | null;
    isShopAppEligible?: boolean | null;
    taxonomyId?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListAppsQueryVariables = {
  filter?: ModelappFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListAppsQuery = {
  listApps?: {
    __typename: 'ModelappConnection';
    items: Array<{
      __typename: 'app';
      id: string;
      owner?: string | null;
      appKey?: string | null;
      app?: string | null;
      active?: boolean | null;
      data?: string | null;
      token?: string | null;
      appExternalId?: string | null;
      appUrl?: string | null;
      importSettings?: ImportSettings | null;
      importRange?: number | null;
      syncTagsMode?: string | null;
      syndicateBy?: SyndicateBy | null;
      googleAnalyticsID?: string | null;
      settings?: string | null;
      isShopAppEligible?: boolean | null;
      taxonomyId?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetProjectAppQueryVariables = {
  id: string;
};

export type GetProjectAppQuery = {
  getProjectApp?: {
    __typename: 'projectApp';
    id: string;
    appId?: string | null;
    appKey?: string | null;
    projectId?: string | null;
    owner?: string | null;
    active?: boolean | null;
    data?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListProjectAppsQueryVariables = {
  filter?: ModelprojectAppFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListProjectAppsQuery = {
  listProjectApps?: {
    __typename: 'ModelprojectAppConnection';
    items: Array<{
      __typename: 'projectApp';
      id: string;
      appId?: string | null;
      appKey?: string | null;
      projectId?: string | null;
      owner?: string | null;
      active?: boolean | null;
      data?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetResponseQueryVariables = {
  id: string;
};

export type GetResponseQuery = {
  getResponse?: {
    __typename: 'response';
    id: string;
    owner?: string | null;
    appKey?: string | null;
    stepName?: string | null;
    projectId?: string | null;
    anonymousId?: string | null;
    sessionId?: string | null;
    type?: string | null;
    value?: string | null;
    email?: string | null;
    name?: string | null;
    phone?: string | null;
    createdAt: string;
    read?: boolean | null;
    collectInfo?: string | null;
    answerType?: string | null;
    parentUrl?: string | null;
    isProfanity?: boolean | null;
    attachments?: Array<{
      __typename: 'attachment';
      fileName?: string | null;
      url?: string | null;
      type?: string | null;
    } | null> | null;
    updatedAt: string;
  } | null;
};

export type ListResponsesQueryVariables = {
  filter?: ModelresponseFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListResponsesQuery = {
  listResponses?: {
    __typename: 'ModelresponseConnection';
    items: Array<{
      __typename: 'response';
      id: string;
      owner?: string | null;
      appKey?: string | null;
      stepName?: string | null;
      projectId?: string | null;
      anonymousId?: string | null;
      sessionId?: string | null;
      type?: string | null;
      value?: string | null;
      email?: string | null;
      name?: string | null;
      phone?: string | null;
      createdAt: string;
      read?: boolean | null;
      collectInfo?: string | null;
      answerType?: string | null;
      parentUrl?: string | null;
      isProfanity?: boolean | null;
      attachments?: Array<{
        __typename: 'attachment';
        fileName?: string | null;
        url?: string | null;
        type?: string | null;
      } | null> | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetInAppNotificationQueryVariables = {
  id: string;
};

export type GetInAppNotificationQuery = {
  getInAppNotification?: {
    __typename: 'inAppNotification';
    id: string;
    owner?: string | null;
    teamMemberId?: string | null;
    projectId?: string | null;
    sessionId?: string | null;
    type?: string | null;
    value?: string | null;
    email?: string | null;
    createdAt: string;
    read?: boolean | null;
    expirationUnixTime: number;
    token?: string | null;
    stepName?: string | null;
    updatedAt: string;
  } | null;
};

export type ListInAppNotificationsQueryVariables = {
  filter?: ModelinAppNotificationFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListInAppNotificationsQuery = {
  listInAppNotifications?: {
    __typename: 'ModelinAppNotificationConnection';
    items: Array<{
      __typename: 'inAppNotification';
      id: string;
      owner?: string | null;
      teamMemberId?: string | null;
      projectId?: string | null;
      sessionId?: string | null;
      type?: string | null;
      value?: string | null;
      email?: string | null;
      createdAt: string;
      read?: boolean | null;
      expirationUnixTime: number;
      token?: string | null;
      stepName?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetConferenceRoomConnectionQueryVariables = {
  id: string;
};

export type GetConferenceRoomConnectionQuery = {
  getConferenceRoomConnection?: {
    __typename: 'conferenceRoomConnection';
    id: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListConferenceRoomConnectionsQueryVariables = {
  filter?: ModelconferenceRoomConnectionFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListConferenceRoomConnectionsQuery = {
  listConferenceRoomConnections?: {
    __typename: 'ModelconferenceRoomConnectionConnection';
    items: Array<{
      __typename: 'conferenceRoomConnection';
      id: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetConferenceRoomQueryVariables = {
  id: string;
};

export type GetConferenceRoomQuery = {
  getConferenceRoom?: {
    __typename: 'conferenceRoom';
    id: string;
    owner: string;
    roomId: string;
    userId: string;
    active?: boolean | null;
    email?: string | null;
    appToken?: string | null;
    lastSeenAt?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListConferenceRoomsQueryVariables = {
  filter?: ModelconferenceRoomFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListConferenceRoomsQuery = {
  listConferenceRooms?: {
    __typename: 'ModelconferenceRoomConnection';
    items: Array<{
      __typename: 'conferenceRoom';
      id: string;
      owner: string;
      roomId: string;
      userId: string;
      active?: boolean | null;
      email?: string | null;
      appToken?: string | null;
      lastSeenAt?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetConferenceRoomActivityQueryVariables = {
  id: string;
};

export type GetConferenceRoomActivityQuery = {
  getConferenceRoomActivity?: {
    __typename: 'conferenceRoomActivity';
    id: string;
    roomName?: string | null;
    createdAt: string;
    expirationUnixTime: number;
    updatedAt?: string | null;
    appToken?: string | null;
    type?: string | null;
  } | null;
};

export type ListConferenceRoomActivitysQueryVariables = {
  filter?: ModelconferenceRoomActivityFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListConferenceRoomActivitysQuery = {
  listConferenceRoomActivitys?: {
    __typename: 'ModelconferenceRoomActivityConnection';
    items: Array<{
      __typename: 'conferenceRoomActivity';
      id: string;
      roomName?: string | null;
      createdAt: string;
      expirationUnixTime: number;
      updatedAt?: string | null;
      appToken?: string | null;
      type?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSessionQueryVariables = {
  id: string;
};

export type GetSessionQuery = {
  getSession?: {
    __typename: 'session';
    id: string;
    owner?: string | null;
    assignee?: string | null;
    appKey?: string | null;
    projectId?: string | null;
    createdAt: string;
    updatedAt: string;
    lastEventAt: string;
    sessionTime?: string | null;
    name?: string | null;
    email?: string | null;
    phone?: string | null;
    read?: boolean | null;
    archived?: boolean | null;
    actionNeeded?: boolean | null;
    totalEvents?: number | null;
    totalResponses?: number | null;
    totalReplies?: number | null;
    parentUrl?: string | null;
    videoResponse?: boolean | null;
    imageResponse?: boolean | null;
    audioResponse?: boolean | null;
    textResponse?: boolean | null;
    formResponse?: boolean | null;
    reachedEnd?: boolean | null;
    isSpam?: boolean | null;
  } | null;
};

export type ListSessionsQueryVariables = {
  filter?: ModelsessionFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSessionsQuery = {
  listSessions?: {
    __typename: 'ModelsessionConnection';
    items: Array<{
      __typename: 'session';
      id: string;
      owner?: string | null;
      assignee?: string | null;
      appKey?: string | null;
      projectId?: string | null;
      createdAt: string;
      updatedAt: string;
      lastEventAt: string;
      sessionTime?: string | null;
      name?: string | null;
      email?: string | null;
      phone?: string | null;
      read?: boolean | null;
      archived?: boolean | null;
      actionNeeded?: boolean | null;
      totalEvents?: number | null;
      totalResponses?: number | null;
      totalReplies?: number | null;
      parentUrl?: string | null;
      videoResponse?: boolean | null;
      imageResponse?: boolean | null;
      audioResponse?: boolean | null;
      textResponse?: boolean | null;
      formResponse?: boolean | null;
      reachedEnd?: boolean | null;
      isSpam?: boolean | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetAccountIdentifiedUsersQueryVariables = {
  id: string;
};

export type GetAccountIdentifiedUsersQuery = {
  getAccountIdentifiedUsers?: {
    __typename: 'accountIdentifiedUsers';
    id: string;
    owner?: string | null;
    appKey: string;
    userId?: string | null;
    email?: string | null;
    phone?: string | null;
    name?: string | null;
    lastSeenAt?: string | null;
    firstSeenAt?: string | null;
    signedUpAt?: string | null;
    sessionCount?: number | null;
    customAttributes?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListAccountIdentifiedUserssQueryVariables = {
  filter?: ModelaccountIdentifiedUsersFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListAccountIdentifiedUserssQuery = {
  listAccountIdentifiedUserss?: {
    __typename: 'ModelaccountIdentifiedUsersConnection';
    items: Array<{
      __typename: 'accountIdentifiedUsers';
      id: string;
      owner?: string | null;
      appKey: string;
      userId?: string | null;
      email?: string | null;
      phone?: string | null;
      name?: string | null;
      lastSeenAt?: string | null;
      firstSeenAt?: string | null;
      signedUpAt?: string | null;
      sessionCount?: number | null;
      customAttributes?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetRuleGroupQueryVariables = {
  id: string;
};

export type GetRuleGroupQuery = {
  getRuleGroup?: {
    __typename: 'ruleGroup';
    id: string;
    appKey: string;
    projectId: string;
    publishId?: string | null;
    owner: string;
    rules: Array<{
      __typename: 'rule';
      key: string;
      type?: string | null;
      condition?: string | null;
      value?: string | null;
      limit?: string | null;
      behaviors?: Array<string | null> | null;
    } | null>;
    enabled?: boolean | null;
    visitorModeEnabled?: boolean | null;
    exitIntent?: boolean | null;
    delayTriggerEnabled?: boolean | null;
    delayTriggerSeconds?: number | null;
    showOnDevice?: string | null;
    showOnDomains?: Array<string | null> | null;
    abTestId?: string | null;
    type?: RuleGroupType | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListRuleGroupsQueryVariables = {
  filter?: ModelruleGroupFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListRuleGroupsQuery = {
  listRuleGroups?: {
    __typename: 'ModelruleGroupConnection';
    items: Array<{
      __typename: 'ruleGroup';
      id: string;
      appKey: string;
      projectId: string;
      publishId?: string | null;
      owner: string;
      rules: Array<{
        __typename: 'rule';
        key: string;
        type?: string | null;
        condition?: string | null;
        value?: string | null;
        limit?: string | null;
        behaviors?: Array<string | null> | null;
      } | null>;
      enabled?: boolean | null;
      visitorModeEnabled?: boolean | null;
      exitIntent?: boolean | null;
      delayTriggerEnabled?: boolean | null;
      delayTriggerSeconds?: number | null;
      showOnDevice?: string | null;
      showOnDomains?: Array<string | null> | null;
      abTestId?: string | null;
      type?: RuleGroupType | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetIdentifyAttributeQueryVariables = {
  id: string;
};

export type GetIdentifyAttributeQuery = {
  getIdentifyAttribute?: {
    __typename: 'identifyAttribute';
    id: string;
    appKey: string;
    owner: string;
    key?: string | null;
    type?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListIdentifyAttributesQueryVariables = {
  filter?: ModelidentifyAttributeFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListIdentifyAttributesQuery = {
  listIdentifyAttributes?: {
    __typename: 'ModelidentifyAttributeConnection';
    items: Array<{
      __typename: 'identifyAttribute';
      id: string;
      appKey: string;
      owner: string;
      key?: string | null;
      type?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetInviteQueryVariables = {
  id: string;
};

export type GetInviteQuery = {
  getInvite?: {
    __typename: 'invite';
    id: string;
    owner: string;
    appKey: string;
    email?: string | null;
    role: Role;
    status: InviteStatus;
    token?: string | null;
    createdAt?: string | null;
    updatedAt: string;
  } | null;
};

export type ListInvitesQueryVariables = {
  filter?: ModelinviteFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListInvitesQuery = {
  listInvites?: {
    __typename: 'ModelinviteConnection';
    items: Array<{
      __typename: 'invite';
      id: string;
      owner: string;
      appKey: string;
      email?: string | null;
      role: Role;
      status: InviteStatus;
      token?: string | null;
      createdAt?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetAccountTeamMemberQueryVariables = {
  id: string;
};

export type GetAccountTeamMemberQuery = {
  getAccountTeamMember?: {
    __typename: 'accountTeamMember';
    id: string;
    owner: string;
    appKey: string;
    email?: string | null;
    role: Role;
    status: InviteStatus;
    inviteToken?: string | null;
    createdAt?: string | null;
    userId?: string | null;
    teamMemberId?: string | null;
    lastLoginAt?: string | null;
    user?: {
      __typename: 'ModeluserSettingsConnection';
      items: Array<{
        __typename: 'userSettings';
        id: string;
        owner?: string | null;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        lastLogin?: string | null;
        source?: string | null;
        token?: string | null;
        appKey?: string | null;
        hasLoggedIn?: boolean | null;
        hasShared?: boolean | null;
        viewedTolstoys?: Array<string | null> | null;
        calendars?: Array<{
          __typename: 'calendar';
          id?: string | null;
          name?: string | null;
          url?: string | null;
        } | null> | null;
        emailAutomationTool?: string | null;
        logoSettings?: string | null;
        googleInfo?: string | null;
        crmData?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    account?: {
      __typename: 'ModelaccountSettingsConnection';
      items: Array<{
        __typename: 'accountSettings';
        id: string;
        appKey: string;
        owner?: string | null;
        name?: string | null;
        logoSettings?: string | null;
        widgetSettings?: string | null;
        downgradeInfo?: string | null;
        liveIds?: Array<string | null> | null;
        url?: string | null;
        logoUrl?: string | null;
        playerLogoUrl?: string | null;
        companyVertical?: string | null;
        companySize?: string | null;
        companyRole?: string | null;
        monthlyTraffic?: string | null;
        useCase?: string | null;
        packageId?: string | null;
        token?: string | null;
        paymentProvider?: string | null;
        paymentSubscriptions?: Array<string | null> | null;
        paymentIdentifier?: string | null;
        packageGroup?: string | null;
        joinedPackageAt?: string | null;
        trialEndAt?: string | null;
        referral?: string | null;
        crmData?: string | null;
        trialDays?: number | null;
        googleAnalyticsID?: string | null;
        appsInstallationStatuses?: string | null;
        onboardingStatuses?: string | null;
        assetAnalysis?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    notificationSettingsId?: string | null;
    paymentRole?: PaymentRole | null;
    unreadInAppNotificationsCounter?: number | null;
    updatedAt: string;
  } | null;
};

export type ListAccountTeamMembersQueryVariables = {
  filter?: ModelaccountTeamMemberFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListAccountTeamMembersQuery = {
  listAccountTeamMembers?: {
    __typename: 'ModelaccountTeamMemberConnection';
    items: Array<{
      __typename: 'accountTeamMember';
      id: string;
      owner: string;
      appKey: string;
      email?: string | null;
      role: Role;
      status: InviteStatus;
      inviteToken?: string | null;
      createdAt?: string | null;
      userId?: string | null;
      teamMemberId?: string | null;
      lastLoginAt?: string | null;
      user?: {
        __typename: 'ModeluserSettingsConnection';
        items: Array<{
          __typename: 'userSettings';
          id: string;
          owner?: string | null;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          lastLogin?: string | null;
          source?: string | null;
          token?: string | null;
          appKey?: string | null;
          hasLoggedIn?: boolean | null;
          hasShared?: boolean | null;
          viewedTolstoys?: Array<string | null> | null;
          calendars?: Array<{
            __typename: 'calendar';
            id?: string | null;
            name?: string | null;
            url?: string | null;
          } | null> | null;
          emailAutomationTool?: string | null;
          logoSettings?: string | null;
          googleInfo?: string | null;
          crmData?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      account?: {
        __typename: 'ModelaccountSettingsConnection';
        items: Array<{
          __typename: 'accountSettings';
          id: string;
          appKey: string;
          owner?: string | null;
          name?: string | null;
          logoSettings?: string | null;
          widgetSettings?: string | null;
          downgradeInfo?: string | null;
          liveIds?: Array<string | null> | null;
          url?: string | null;
          logoUrl?: string | null;
          playerLogoUrl?: string | null;
          companyVertical?: string | null;
          companySize?: string | null;
          companyRole?: string | null;
          monthlyTraffic?: string | null;
          useCase?: string | null;
          packageId?: string | null;
          token?: string | null;
          paymentProvider?: string | null;
          paymentSubscriptions?: Array<string | null> | null;
          paymentIdentifier?: string | null;
          packageGroup?: string | null;
          joinedPackageAt?: string | null;
          trialEndAt?: string | null;
          referral?: string | null;
          crmData?: string | null;
          trialDays?: number | null;
          googleAnalyticsID?: string | null;
          appsInstallationStatuses?: string | null;
          onboardingStatuses?: string | null;
          assetAnalysis?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      notificationSettingsId?: string | null;
      paymentRole?: PaymentRole | null;
      unreadInAppNotificationsCounter?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetTeamMemberSettingsQueryVariables = {
  id: string;
};

export type GetTeamMemberSettingsQuery = {
  getTeamMemberSettings?: {
    __typename: 'teamMemberSettings';
    id: string;
    teamMemberId?: string | null;
    notificationSettingsId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListTeamMemberSettingssQueryVariables = {
  filter?: ModelteamMemberSettingsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListTeamMemberSettingssQuery = {
  listTeamMemberSettingss?: {
    __typename: 'ModelteamMemberSettingsConnection';
    items: Array<{
      __typename: 'teamMemberSettings';
      id: string;
      teamMemberId?: string | null;
      notificationSettingsId?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetNotificationSettingsQueryVariables = {
  id: string;
};

export type GetNotificationSettingsQuery = {
  getNotificationSettings?: {
    __typename: 'notificationSettings';
    id: string;
    owner: string;
    projectId?: string | null;
    email?: {
      __typename: 'sharedNotificationSettings';
      showAnonymous?: boolean | null;
      enabled?: Array<string | null> | null;
      digest?: Array<string | null> | null;
    } | null;
    inApp?: {
      __typename: 'sharedNotificationSettings';
      showAnonymous?: boolean | null;
      enabled?: Array<string | null> | null;
      digest?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListNotificationSettingssQueryVariables = {
  filter?: ModelnotificationSettingsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListNotificationSettingssQuery = {
  listNotificationSettingss?: {
    __typename: 'ModelnotificationSettingsConnection';
    items: Array<{
      __typename: 'notificationSettings';
      id: string;
      owner: string;
      projectId?: string | null;
      email?: {
        __typename: 'sharedNotificationSettings';
        showAnonymous?: boolean | null;
        enabled?: Array<string | null> | null;
        digest?: Array<string | null> | null;
      } | null;
      inApp?: {
        __typename: 'sharedNotificationSettings';
        showAnonymous?: boolean | null;
        enabled?: Array<string | null> | null;
        digest?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetFolderQueryVariables = {
  id: string;
};

export type GetFolderQuery = {
  getFolder?: {
    __typename: 'folder';
    id: string;
    owner?: string | null;
    name: string;
    appKey: string;
    private?: boolean | null;
    type?: FolderType | null;
    appUrl?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListFoldersQueryVariables = {
  filter?: ModelfolderFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListFoldersQuery = {
  listFolders?: {
    __typename: 'ModelfolderConnection';
    items: Array<{
      __typename: 'folder';
      id: string;
      owner?: string | null;
      name: string;
      appKey: string;
      private?: boolean | null;
      type?: FolderType | null;
      appUrl?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetAccountUserQueryVariables = {
  id: string;
};

export type GetAccountUserQuery = {
  getAccountUser?: {
    __typename: 'accountUser';
    id: string;
    appKey: string;
    email?: string | null;
    name?: string | null;
    phone?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    lastSeenAt?: string | null;
    emailVerified?: boolean | null;
    sessionCount?: number | null;
    customAttributes?: string | null;
  } | null;
};

export type ListAccountUsersQueryVariables = {
  filter?: ModelaccountUserFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListAccountUsersQuery = {
  listAccountUsers?: {
    __typename: 'ModelaccountUserConnection';
    items: Array<{
      __typename: 'accountUser';
      id: string;
      appKey: string;
      email?: string | null;
      name?: string | null;
      phone?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      lastSeenAt?: string | null;
      emailVerified?: boolean | null;
      sessionCount?: number | null;
      customAttributes?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetAccountUsageQueryVariables = {
  id: string;
};

export type GetAccountUsageQuery = {
  getAccountUsage?: {
    __typename: 'accountUsage';
    id: string;
    appKey: string;
    featureId: string;
    usage?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListAccountUsagesQueryVariables = {
  filter?: ModelaccountUsageFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListAccountUsagesQuery = {
  listAccountUsages?: {
    __typename: 'ModelaccountUsageConnection';
    items: Array<{
      __typename: 'accountUsage';
      id: string;
      appKey: string;
      featureId: string;
      usage?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetPlayerConfigQueryVariables = {
  id: string;
};

export type GetPlayerConfigQuery = {
  getPlayerConfig?: {
    __typename: 'playerConfig';
    id: string;
    appKey: string;
    publishId: string;
    projectId: string;
    config?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListPlayerConfigsQueryVariables = {
  filter?: ModelplayerConfigFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListPlayerConfigsQuery = {
  listPlayerConfigs?: {
    __typename: 'ModelplayerConfigConnection';
    items: Array<{
      __typename: 'playerConfig';
      id: string;
      appKey: string;
      publishId: string;
      projectId: string;
      config?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetWidgetConfigQueryVariables = {
  id: string;
};

export type GetWidgetConfigQuery = {
  getWidgetConfig?: {
    __typename: 'widgetConfig';
    id: string;
    appKey: string;
    publishId: string;
    projectId: string;
    config?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListWidgetConfigsQueryVariables = {
  filter?: ModelwidgetConfigFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListWidgetConfigsQuery = {
  listWidgetConfigs?: {
    __typename: 'ModelwidgetConfigConnection';
    items: Array<{
      __typename: 'widgetConfig';
      id: string;
      appKey: string;
      publishId: string;
      projectId: string;
      config?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetAccountPackageQueryVariables = {
  id: string;
};

export type GetAccountPackageQuery = {
  getAccountPackage?: {
    __typename: 'accountPackage';
    id: string;
    appKey: string;
    packageId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    app?: TolstoyApp | null;
    featuresPackageId?: string | null;
    group?: string | null;
  } | null;
};

export type ListAccountPackagesQueryVariables = {
  filter?: ModelaccountPackageFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListAccountPackagesQuery = {
  listAccountPackages?: {
    __typename: 'ModelaccountPackageConnection';
    items: Array<{
      __typename: 'accountPackage';
      id: string;
      appKey: string;
      packageId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      app?: TolstoyApp | null;
      featuresPackageId?: string | null;
      group?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetPackageQueryVariables = {
  id: string;
};

export type GetPackageQuery = {
  getPackage?: {
    __typename: 'package';
    id: string;
    name?: string | null;
    description?: string | null;
    stripeProducts?: Array<{
      __typename: 'stripeProduct';
      type?: string | null;
      price?: string | null;
    } | null> | null;
    trialPeriodDays?: number | null;
    maxTrialPeriodDays?: number | null;
    type?: string | null;
    details?: string | null;
    group?: string | null;
    order?: number | null;
    featuresPackageId?: string | null;
    addons?: Array<string | null> | null;
    app?: TolstoyApp | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListPackagesQueryVariables = {
  filter?: ModelpackageFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListPackagesQuery = {
  listPackages?: {
    __typename: 'ModelpackageConnection';
    items: Array<{
      __typename: 'package';
      id: string;
      name?: string | null;
      description?: string | null;
      stripeProducts?: Array<{
        __typename: 'stripeProduct';
        type?: string | null;
        price?: string | null;
      } | null> | null;
      trialPeriodDays?: number | null;
      maxTrialPeriodDays?: number | null;
      type?: string | null;
      details?: string | null;
      group?: string | null;
      order?: number | null;
      featuresPackageId?: string | null;
      addons?: Array<string | null> | null;
      app?: TolstoyApp | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetExperimentQueryVariables = {
  id: string;
};

export type GetExperimentQuery = {
  getExperiment?: {
    __typename: 'experiment';
    id: string;
    featureId: string;
    name?: string | null;
    description?: string | null;
    expireAt?: string | null;
    type?: string | null;
    groups?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListExperimentsQueryVariables = {
  filter?: ModelexperimentFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListExperimentsQuery = {
  listExperiments?: {
    __typename: 'ModelexperimentConnection';
    items: Array<{
      __typename: 'experiment';
      id: string;
      featureId: string;
      name?: string | null;
      description?: string | null;
      expireAt?: string | null;
      type?: string | null;
      groups?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetPaymentQueryVariables = {
  id: string;
};

export type GetPaymentQuery = {
  getPayment?: {
    __typename: 'payment';
    id: string;
    subscriptionId?: string | null;
    appKey?: string | null;
    reportAt?: string | null;
    usagePrice?: number | null;
    usageQuantity?: number | null;
    type?: string | null;
    provider?: string | null;
    identifier?: string | null;
    packageId?: string | null;
    packageType?: string | null;
    dailyUsagePrice?: number | null;
    aggregatedPrice?: number | null;
    aggregatedUsageQuantity?: number | null;
    dailyUsageQuantity?: number | null;
    seatsPrice?: number | null;
    price?: number | null;
    interval?: string | null;
    numberOfProTeamMembers?: number | null;
    invoiceId?: string | null;
    invoiceItemId?: string | null;
    paymentSubscriptionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListPaymentsQueryVariables = {
  filter?: ModelpaymentFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListPaymentsQuery = {
  listPayments?: {
    __typename: 'ModelpaymentConnection';
    items: Array<{
      __typename: 'payment';
      id: string;
      subscriptionId?: string | null;
      appKey?: string | null;
      reportAt?: string | null;
      usagePrice?: number | null;
      usageQuantity?: number | null;
      type?: string | null;
      provider?: string | null;
      identifier?: string | null;
      packageId?: string | null;
      packageType?: string | null;
      dailyUsagePrice?: number | null;
      aggregatedPrice?: number | null;
      aggregatedUsageQuantity?: number | null;
      dailyUsageQuantity?: number | null;
      seatsPrice?: number | null;
      price?: number | null;
      interval?: string | null;
      numberOfProTeamMembers?: number | null;
      invoiceId?: string | null;
      invoiceItemId?: string | null;
      paymentSubscriptionId?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetQuizResultQueryVariables = {
  id: string;
};

export type GetQuizResultQuery = {
  getQuizResult?: {
    __typename: 'quizResult';
    id: string;
    projectId: string;
    appKey?: string | null;
    owner?: string | null;
    createdAt?: string | null;
    url?: string | null;
    title?: string | null;
    imageUrl?: string | null;
    flows?: Array<{
      __typename: 'quizFlow';
      id: string;
      type?: string | null;
      rules?: Array<{
        __typename: 'quizRule';
        stepId: string;
        choices?: Array<string | null> | null;
      } | null> | null;
    } | null> | null;
    isFallback?: boolean | null;
    klaviyoListId?: string | null;
    stepName?: string | null;
    type?: ResultType | null;
    updatedAt: string;
  } | null;
};

export type ListQuizResultsQueryVariables = {
  filter?: ModelquizResultFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListQuizResultsQuery = {
  listQuizResults?: {
    __typename: 'ModelquizResultConnection';
    items: Array<{
      __typename: 'quizResult';
      id: string;
      projectId: string;
      appKey?: string | null;
      owner?: string | null;
      createdAt?: string | null;
      url?: string | null;
      title?: string | null;
      imageUrl?: string | null;
      flows?: Array<{
        __typename: 'quizFlow';
        id: string;
        type?: string | null;
        rules?: Array<{
          __typename: 'quizRule';
          stepId: string;
          choices?: Array<string | null> | null;
        } | null> | null;
      } | null> | null;
      isFallback?: boolean | null;
      klaviyoListId?: string | null;
      stepName?: string | null;
      type?: ResultType | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetEcomProductQueryVariables = {
  id: string;
};

export type GetEcomProductQuery = {
  getEcomProduct?: {
    __typename: 'ecomProduct';
    id: string;
    productId: string;
    appKey?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListEcomProductsQueryVariables = {
  filter?: ModelecomProductFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListEcomProductsQuery = {
  listEcomProducts?: {
    __typename: 'ModelecomProductConnection';
    items: Array<{
      __typename: 'ecomProduct';
      id: string;
      productId: string;
      appKey?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetProductPageSettingsQueryVariables = {
  id: string;
};

export type GetProductPageSettingsQuery = {
  getProductPageSettings?: {
    __typename: 'productPageSettings';
    id: string;
    productId?: string | null;
    productUrl?: string | null;
    abTestId?: string | null;
    publishId?: string | null;
    appKey?: string | null;
    widgetType?: WidgetType | null;
    productSource?: ProductSource | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListProductPageSettingssQueryVariables = {
  filter?: ModelproductPageSettingsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListProductPageSettingssQuery = {
  listProductPageSettingss?: {
    __typename: 'ModelproductPageSettingsConnection';
    items: Array<{
      __typename: 'productPageSettings';
      id: string;
      productId?: string | null;
      productUrl?: string | null;
      abTestId?: string | null;
      publishId?: string | null;
      appKey?: string | null;
      widgetType?: WidgetType | null;
      productSource?: ProductSource | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetAbTestQueryVariables = {
  id: string;
};

export type GetAbTestQuery = {
  getAbTest?: {
    __typename: 'abTest';
    id: string;
    appKey?: string | null;
    owner?: string | null;
    options?: Array<{
      __typename: 'abTestGroup';
      publishId?: string | null;
      probability?: number | null;
    } | null> | null;
    endAt?: string | null;
    startAt?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListAbTestsQueryVariables = {
  filter?: ModelabTestFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListAbTestsQuery = {
  listAbTests?: {
    __typename: 'ModelabTestConnection';
    items: Array<{
      __typename: 'abTest';
      id: string;
      appKey?: string | null;
      owner?: string | null;
      options?: Array<{
        __typename: 'abTestGroup';
        publishId?: string | null;
        probability?: number | null;
      } | null> | null;
      endAt?: string | null;
      startAt?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetIntegrationVodAssetQueryVariables = {
  id: string;
};

export type GetIntegrationVodAssetQuery = {
  getIntegrationVodAsset?: {
    __typename: 'integrationVodAsset';
    id: string;
    appKey?: string | null;
    owner?: string | null;
    app?: string | null;
    appId?: string | null;
    externalId?: string | null;
    externalCreatedAt?: string | null;
    vodId?: string | null;
    seen?: boolean | null;
    title?: string | null;
    thumbnailUrl?: string | null;
    downloadUrl?: string | null;
    status?: StatusTypes | null;
    createdAt?: string | null;
    updatedAt: string;
  } | null;
};

export type ListIntegrationVodAssetsQueryVariables = {
  filter?: ModelintegrationVodAssetFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListIntegrationVodAssetsQuery = {
  listIntegrationVodAssets?: {
    __typename: 'ModelintegrationVodAssetConnection';
    items: Array<{
      __typename: 'integrationVodAsset';
      id: string;
      appKey?: string | null;
      owner?: string | null;
      app?: string | null;
      appId?: string | null;
      externalId?: string | null;
      externalCreatedAt?: string | null;
      vodId?: string | null;
      seen?: boolean | null;
      title?: string | null;
      thumbnailUrl?: string | null;
      downloadUrl?: string | null;
      status?: StatusTypes | null;
      createdAt?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetVodConnectionQueryVariables = {
  id: string;
};

export type GetVodConnectionQuery = {
  getVodConnection?: {
    __typename: 'vodConnection';
    id: string;
    appKey?: string | null;
    vodAssetId?: string | null;
    productId?: string | null;
    externalProductId?: string | null;
    collectionId?: string | null;
    provider?: VodConnectionProvider | null;
    tag?: string | null;
    createdAt?: string | null;
    typeKey?: string | null;
    type?: VodConnectionType | null;
    appUrl?: string | null;
    label?: string | null;
    vodLabelId?: string | null;
    vodSource?: string | null;
    vodType?: string | null;
    variantIds?: Array<string | null> | null;
    projectId?: string | null;
    orderIndex?: number | null;
    source?: string | null;
    updatedAt: string;
  } | null;
};

export type ListVodConnectionsQueryVariables = {
  filter?: ModelvodConnectionFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListVodConnectionsQuery = {
  listVodConnections?: {
    __typename: 'ModelvodConnectionConnection';
    items: Array<{
      __typename: 'vodConnection';
      id: string;
      appKey?: string | null;
      vodAssetId?: string | null;
      productId?: string | null;
      externalProductId?: string | null;
      collectionId?: string | null;
      provider?: VodConnectionProvider | null;
      tag?: string | null;
      createdAt?: string | null;
      typeKey?: string | null;
      type?: VodConnectionType | null;
      appUrl?: string | null;
      label?: string | null;
      vodLabelId?: string | null;
      vodSource?: string | null;
      vodType?: string | null;
      variantIds?: Array<string | null> | null;
      projectId?: string | null;
      orderIndex?: number | null;
      source?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetCustomerQueryVariables = {
  id: string;
};

export type GetCustomerQuery = {
  getCustomer?: {
    __typename: 'customer';
    id: string;
    appKey: string;
    appUrl: string;
    externalId?: string | null;
    googleId?: string | null;
    anonymousIds?: Array<string | null> | null;
    email: string;
    phone?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    acceptsMarketing?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListCustomersQueryVariables = {
  filter?: ModelcustomerFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListCustomersQuery = {
  listCustomers?: {
    __typename: 'ModelcustomerConnection';
    items: Array<{
      __typename: 'customer';
      id: string;
      appKey: string;
      appUrl: string;
      externalId?: string | null;
      googleId?: string | null;
      anonymousIds?: Array<string | null> | null;
      email: string;
      phone?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      acceptsMarketing?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetVodLabelQueryVariables = {
  id: string;
};

export type GetVodLabelQuery = {
  getVodLabel?: {
    __typename: 'vodLabel';
    id: string;
    appKey: string;
    name: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListVodLabelsQueryVariables = {
  filter?: ModelvodLabelFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListVodLabelsQuery = {
  listVodLabels?: {
    __typename: 'ModelvodLabelConnection';
    items: Array<{
      __typename: 'vodLabel';
      id: string;
      appKey: string;
      name: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSuggestedProductAcceptanceQueryVariables = {
  id: string;
};

export type GetSuggestedProductAcceptanceQuery = {
  getSuggestedProductAcceptance?: {
    __typename: 'suggestedProductAcceptance';
    id: string;
    appKey: string;
    vodAssetId: string;
    externalProductId: string;
    accepted: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListSuggestedProductAcceptancesQueryVariables = {
  filter?: ModelsuggestedProductAcceptanceFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSuggestedProductAcceptancesQuery = {
  listSuggestedProductAcceptances?: {
    __typename: 'ModelsuggestedProductAcceptanceConnection';
    items: Array<{
      __typename: 'suggestedProductAcceptance';
      id: string;
      appKey: string;
      vodAssetId: string;
      externalProductId: string;
      accepted: boolean;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetCustomerWishListQueryVariables = {
  id: string;
};

export type GetCustomerWishListQuery = {
  getCustomerWishList?: {
    __typename: 'customerWishList';
    id: string;
    appKey: string;
    appUrl: string;
    vodAssetId: string;
    externalCustomerId?: string | null;
    email?: string | null;
    externalProductId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListCustomerWishListsQueryVariables = {
  filter?: ModelcustomerWishListFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListCustomerWishListsQuery = {
  listCustomerWishLists?: {
    __typename: 'ModelcustomerWishListConnection';
    items: Array<{
      __typename: 'customerWishList';
      id: string;
      appKey: string;
      appUrl: string;
      vodAssetId: string;
      externalCustomerId?: string | null;
      email?: string | null;
      externalProductId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetVodConnectionSuggestionQueryVariables = {
  id: string;
};

export type GetVodConnectionSuggestionQuery = {
  getVodConnectionSuggestion?: {
    __typename: 'vodConnectionSuggestion';
    id: string;
    appKey?: string | null;
    appUrl?: string | null;
    createdAt?: string | null;
    vodAssetId?: string | null;
    externalProductId?: string | null;
    status?: VodConnectionSuggestionStatus | null;
    source?: VodConnectionSuggestionSource | null;
    method?: VodConnectionSuggestionMethod | null;
    methodVersion?: string | null;
    score?: number | null;
    updatedAt: string;
  } | null;
};

export type ListVodConnectionSuggestionsQueryVariables = {
  filter?: ModelvodConnectionSuggestionFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListVodConnectionSuggestionsQuery = {
  listVodConnectionSuggestions?: {
    __typename: 'ModelvodConnectionSuggestionConnection';
    items: Array<{
      __typename: 'vodConnectionSuggestion';
      id: string;
      appKey?: string | null;
      appUrl?: string | null;
      createdAt?: string | null;
      vodAssetId?: string | null;
      externalProductId?: string | null;
      status?: VodConnectionSuggestionStatus | null;
      source?: VodConnectionSuggestionSource | null;
      method?: VodConnectionSuggestionMethod | null;
      methodVersion?: string | null;
      score?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type VideosByOwnerQueryVariables = {
  owner?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelvodAssetFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type VideosByOwnerQuery = {
  videosByOwner?: {
    __typename: 'ModelvodAssetConnection';
    items: Array<{
      __typename: 'vodAsset';
      id: string;
      type?: assetType | null;
      owner?: string | null;
      appKey?: string | null;
      appExternalId?: string | null;
      createdAt?: string | null;
      name: string;
      description?: string | null;
      creatorProfile?: {
        __typename: 'creatorProfile';
        url?: string | null;
      } | null;
      isSoundAllowed?: boolean | null;
      status: string;
      uploadType?: string | null;
      stockAsset?: {
        __typename: 'stockAsset';
        videoUrl?: string | null;
        posterUrl?: string | null;
        gifUrl?: string | null;
        hasOriginal?: boolean | null;
        shopifyFileId?: string | null;
        previewUrl?: string | null;
        previewShopifyFileId?: string | null;
        shopifyPosterUrl?: string | null;
        posterShopifyFileId?: string | null;
        avatarUrl?: string | null;
        audioUrl?: string | null;
        gifShopifyFileId?: string | null;
      } | null;
      duration?: number | null;
      folder?: string | null;
      width?: number | null;
      height?: number | null;
      isVertical?: boolean | null;
      isHDR?: boolean | null;
      trimSettings?: string | null;
      originalVOD?: string | null;
      mirror?: boolean | null;
      recorderVersion?: string | null;
      originalSize?: string | null;
      externalId?: string | null;
      externalProviderData?: string | null;
      isMigratingToShopify?: boolean | null;
      shopifyUploadingStatus?: string | null;
      isEverUsed?: boolean | null;
      captionsSettings?: string | null;
      referenceIds?: Array<string | null> | null;
      vodConnections?: {
        __typename: 'ModelvodConnectionConnection';
        items: Array<{
          __typename: 'vodConnection';
          id: string;
          appKey?: string | null;
          vodAssetId?: string | null;
          productId?: string | null;
          externalProductId?: string | null;
          collectionId?: string | null;
          provider?: VodConnectionProvider | null;
          tag?: string | null;
          createdAt?: string | null;
          typeKey?: string | null;
          type?: VodConnectionType | null;
          appUrl?: string | null;
          label?: string | null;
          vodLabelId?: string | null;
          vodSource?: string | null;
          vodType?: string | null;
          variantIds?: Array<string | null> | null;
          projectId?: string | null;
          orderIndex?: number | null;
          source?: string | null;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      analysis?: string | null;
      autoGenerated?: boolean | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type VideosByAppKeyQueryVariables = {
  appKey?: string | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelvodAssetFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type VideosByAppKeyQuery = {
  videosByAppKey?: {
    __typename: 'ModelvodAssetConnection';
    items: Array<{
      __typename: 'vodAsset';
      id: string;
      type?: assetType | null;
      owner?: string | null;
      appKey?: string | null;
      appExternalId?: string | null;
      createdAt?: string | null;
      name: string;
      description?: string | null;
      creatorProfile?: {
        __typename: 'creatorProfile';
        url?: string | null;
      } | null;
      isSoundAllowed?: boolean | null;
      status: string;
      uploadType?: string | null;
      stockAsset?: {
        __typename: 'stockAsset';
        videoUrl?: string | null;
        posterUrl?: string | null;
        gifUrl?: string | null;
        hasOriginal?: boolean | null;
        shopifyFileId?: string | null;
        previewUrl?: string | null;
        previewShopifyFileId?: string | null;
        shopifyPosterUrl?: string | null;
        posterShopifyFileId?: string | null;
        avatarUrl?: string | null;
        audioUrl?: string | null;
        gifShopifyFileId?: string | null;
      } | null;
      duration?: number | null;
      folder?: string | null;
      width?: number | null;
      height?: number | null;
      isVertical?: boolean | null;
      isHDR?: boolean | null;
      trimSettings?: string | null;
      originalVOD?: string | null;
      mirror?: boolean | null;
      recorderVersion?: string | null;
      originalSize?: string | null;
      externalId?: string | null;
      externalProviderData?: string | null;
      isMigratingToShopify?: boolean | null;
      shopifyUploadingStatus?: string | null;
      isEverUsed?: boolean | null;
      captionsSettings?: string | null;
      referenceIds?: Array<string | null> | null;
      vodConnections?: {
        __typename: 'ModelvodConnectionConnection';
        items: Array<{
          __typename: 'vodConnection';
          id: string;
          appKey?: string | null;
          vodAssetId?: string | null;
          productId?: string | null;
          externalProductId?: string | null;
          collectionId?: string | null;
          provider?: VodConnectionProvider | null;
          tag?: string | null;
          createdAt?: string | null;
          typeKey?: string | null;
          type?: VodConnectionType | null;
          appUrl?: string | null;
          label?: string | null;
          vodLabelId?: string | null;
          vodSource?: string | null;
          vodType?: string | null;
          variantIds?: Array<string | null> | null;
          projectId?: string | null;
          orderIndex?: number | null;
          source?: string | null;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      analysis?: string | null;
      autoGenerated?: boolean | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type VideosByExternalIdQueryVariables = {
  externalId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelvodAssetFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type VideosByExternalIdQuery = {
  videosByExternalId?: {
    __typename: 'ModelvodAssetConnection';
    items: Array<{
      __typename: 'vodAsset';
      id: string;
      type?: assetType | null;
      owner?: string | null;
      appKey?: string | null;
      appExternalId?: string | null;
      createdAt?: string | null;
      name: string;
      description?: string | null;
      creatorProfile?: {
        __typename: 'creatorProfile';
        url?: string | null;
      } | null;
      isSoundAllowed?: boolean | null;
      status: string;
      uploadType?: string | null;
      stockAsset?: {
        __typename: 'stockAsset';
        videoUrl?: string | null;
        posterUrl?: string | null;
        gifUrl?: string | null;
        hasOriginal?: boolean | null;
        shopifyFileId?: string | null;
        previewUrl?: string | null;
        previewShopifyFileId?: string | null;
        shopifyPosterUrl?: string | null;
        posterShopifyFileId?: string | null;
        avatarUrl?: string | null;
        audioUrl?: string | null;
        gifShopifyFileId?: string | null;
      } | null;
      duration?: number | null;
      folder?: string | null;
      width?: number | null;
      height?: number | null;
      isVertical?: boolean | null;
      isHDR?: boolean | null;
      trimSettings?: string | null;
      originalVOD?: string | null;
      mirror?: boolean | null;
      recorderVersion?: string | null;
      originalSize?: string | null;
      externalId?: string | null;
      externalProviderData?: string | null;
      isMigratingToShopify?: boolean | null;
      shopifyUploadingStatus?: string | null;
      isEverUsed?: boolean | null;
      captionsSettings?: string | null;
      referenceIds?: Array<string | null> | null;
      vodConnections?: {
        __typename: 'ModelvodConnectionConnection';
        items: Array<{
          __typename: 'vodConnection';
          id: string;
          appKey?: string | null;
          vodAssetId?: string | null;
          productId?: string | null;
          externalProductId?: string | null;
          collectionId?: string | null;
          provider?: VodConnectionProvider | null;
          tag?: string | null;
          createdAt?: string | null;
          typeKey?: string | null;
          type?: VodConnectionType | null;
          appUrl?: string | null;
          label?: string | null;
          vodLabelId?: string | null;
          vodSource?: string | null;
          vodType?: string | null;
          variantIds?: Array<string | null> | null;
          projectId?: string | null;
          orderIndex?: number | null;
          source?: string | null;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      analysis?: string | null;
      autoGenerated?: boolean | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type VideoResponsesByOwnerQueryVariables = {
  owner?: string | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelvodResponseAssetFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type VideoResponsesByOwnerQuery = {
  videoResponsesByOwner?: {
    __typename: 'ModelvodResponseAssetConnection';
    items: Array<{
      __typename: 'vodResponseAsset';
      id: string;
      owner: string;
      appKey?: string | null;
      createdAt: string;
      status?: string | null;
      uploadType?: string | null;
      duration?: number | null;
      width?: number | null;
      height?: number | null;
      isVertical?: boolean | null;
      isHDR?: boolean | null;
      mirror?: boolean | null;
      originalSize?: string | null;
      email?: string | null;
      projectId?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type VideoResponsesByAppKeyQueryVariables = {
  appKey?: string | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelvodResponseAssetFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type VideoResponsesByAppKeyQuery = {
  videoResponsesByAppKey?: {
    __typename: 'ModelvodResponseAssetConnection';
    items: Array<{
      __typename: 'vodResponseAsset';
      id: string;
      owner: string;
      appKey?: string | null;
      createdAt: string;
      status?: string | null;
      uploadType?: string | null;
      duration?: number | null;
      width?: number | null;
      height?: number | null;
      isVertical?: boolean | null;
      isHDR?: boolean | null;
      mirror?: boolean | null;
      originalSize?: string | null;
      email?: string | null;
      projectId?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ProjectsByOwnerQueryVariables = {
  owner?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelprojectFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ProjectsByOwnerQuery = {
  projectsByOwner?: {
    __typename: 'ModelprojectConnection';
    items: Array<{
      __typename: 'project';
      id: string;
      owner?: string | null;
      appKey?: string | null;
      typeKey?: string | null;
      createdAt?: string | null;
      publishId: string;
      name: string;
      folder?: string | null;
      targetPage?: string | null;
      verticalOrientation?: boolean | null;
      widgetPosition?: string | null;
      widgetHeader?: string | null;
      widgetUrls?: Array<string | null> | null;
      description?: string | null;
      emailNotifications?: Array<string | null> | null;
      notificationLevel?: number | null;
      viewerNotificationLevel?: number | null;
      chaptersEnabled?: boolean | null;
      subtitlesEnabled?: boolean | null;
      stepsOrder?: Array<string | null> | null;
      collectText?: string | null;
      collectFields?: Array<{
        __typename: 'inputField';
        key?: string | null;
        name?: string | null;
        type?: string | null;
        placeholder?: string | null;
        link?: string | null;
        required?: boolean | null;
        collect?: boolean | null;
        custom?: boolean | null;
        textColor?: string | null;
        borderColor?: string | null;
        backgroundColor?: string | null;
      } | null> | null;
      collectAfterStep?: Array<string | null> | null;
      cacheLeadFormAnswers?: boolean | null;
      googleAnalyticsID?: string | null;
      facebookAnalyticsID?: string | null;
      totalResponses?: number | null;
      unreadResponses?: number | null;
      tolstoyType?: string | null;
      steps?: {
        __typename: 'ModelprojectStepConnection';
        items: Array<{
          __typename: 'projectStep';
          id: string;
          owner?: string | null;
          appKey?: string | null;
          projectId: string;
          videoId?: string | null;
          name: string;
          type?: string | null;
          description?: string | null;
          script?: string | null;
          question?: string | null;
          questionVisible?: boolean | null;
          answerTime?: number | null;
          answers?: Array<{
            __typename: 'answer';
            key?: string | null;
            text?: string | null;
            next?: string | null;
            value?: string | null;
            type?: string | null;
            disabled?: boolean | null;
          } | null> | null;
          products?: Array<{
            __typename: 'product';
            id?: string | null;
          } | null> | null;
          productsAppUrl?: string | null;
          videoContain?: boolean | null;
          overlayText?: string | null;
          overlayTextColor?: string | null;
          multipleChoiceSelection?: boolean | null;
          defaultResponses?: Array<{
            __typename: 'answer';
            key?: string | null;
            text?: string | null;
            next?: string | null;
            value?: string | null;
            type?: string | null;
            disabled?: boolean | null;
          } | null> | null;
          defaultResponsesEnabled?: boolean | null;
          sideBarButtonsEnabled?: boolean | null;
          feedProductActionButton?: {
            __typename: 'feedProductActionButton';
            type?: string | null;
            url?: string | null;
            text?: string | null;
            isOpenInANewTab?: boolean | null;
            value?: string | null;
            enabled?: boolean | null;
          } | null;
          feedSidePanelType?: FeedSidePanelType | null;
          feedDescriptionActionButton?: {
            __typename: 'feedProductActionButton';
            type?: string | null;
            url?: string | null;
            text?: string | null;
            isOpenInANewTab?: boolean | null;
            value?: string | null;
            enabled?: boolean | null;
          } | null;
          feedSidePanelDescription?: string | null;
          productCtaType?: ProductCtaType | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      stepsCount?: number | null;
      widgetBorderColor?: string | null;
      widgetShape?: string | null;
      widgetSize?: string | null;
      widgetNotificationBadge?: boolean | null;
      videoResolutions?: Array<string | null> | null;
      fastForwardEnabled?: boolean | null;
      rulesEnabled?: boolean | null;
      macro?: boolean | null;
      hideChatPlatform?: boolean | null;
      private?: boolean | null;
      chatLandingPage?: boolean | null;
      widgetSettings?: string | null;
      notificationSettingsId?: string | null;
      collectAfterAnyResponse?: boolean | null;
      startScreenText?: string | null;
      feed?: boolean | null;
      feedAutoScroll?: boolean | null;
      feedShowUnviewedStepsFirst?: boolean | null;
      language?: string | null;
      showButtonsIndex?: boolean | null;
      publishMethod?: publishMethodOptions | null;
      dynamic?: boolean | null;
      feedProductCtaType?: ProductCtaType | null;
      playerSettings?: string | null;
      appUrl?: string | null;
      live?: boolean | null;
      primaryButton?: {
        __typename: 'ctaButtonConfig';
        enabled?: boolean | null;
        ctaType?: ProductCtaType | null;
        buttonText?: string | null;
        value?: string | null;
        isOpenInANewTab?: boolean | null;
      } | null;
      secondaryButton?: {
        __typename: 'ctaButtonConfig';
        enabled?: boolean | null;
        ctaType?: ProductCtaType | null;
        buttonText?: string | null;
        value?: string | null;
        isOpenInANewTab?: boolean | null;
      } | null;
      pdpConfig?: {
        __typename: 'pdpConfig';
        primaryButton?: {
          __typename: 'ctaButtonConfig';
          enabled?: boolean | null;
          ctaType?: ProductCtaType | null;
          buttonText?: string | null;
          value?: string | null;
          isOpenInANewTab?: boolean | null;
        } | null;
        secondaryButton?: {
          __typename: 'ctaButtonConfig';
          enabled?: boolean | null;
          ctaType?: ProductCtaType | null;
          buttonText?: string | null;
          value?: string | null;
          isOpenInANewTab?: boolean | null;
        } | null;
        miniCtaButton?: {
          __typename: 'miniCtaButtonConfig';
          enabled?: boolean | null;
          text?: string | null;
        } | null;
      } | null;
      installationCompleted?: boolean | null;
      installStep?: string | null;
      discover?: boolean | null;
      useCaseName?: string | null;
      heroSettings?: string | null;
      sourceProjectId?: string | null;
      emailSettings?: string | null;
      videoCollectionCampaignSettings?: string | null;
      secretVideoSettings?: string | null;
      integrationSettings?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ProjectsByAppKeyQueryVariables = {
  appKey?: string | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelprojectFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ProjectsByAppKeyQuery = {
  projectsByAppKey?: {
    __typename: 'ModelprojectConnection';
    items: Array<{
      __typename: 'project';
      id: string;
      owner?: string | null;
      appKey?: string | null;
      typeKey?: string | null;
      createdAt?: string | null;
      publishId: string;
      name: string;
      folder?: string | null;
      targetPage?: string | null;
      verticalOrientation?: boolean | null;
      widgetPosition?: string | null;
      widgetHeader?: string | null;
      widgetUrls?: Array<string | null> | null;
      description?: string | null;
      emailNotifications?: Array<string | null> | null;
      notificationLevel?: number | null;
      viewerNotificationLevel?: number | null;
      chaptersEnabled?: boolean | null;
      subtitlesEnabled?: boolean | null;
      stepsOrder?: Array<string | null> | null;
      collectText?: string | null;
      collectFields?: Array<{
        __typename: 'inputField';
        key?: string | null;
        name?: string | null;
        type?: string | null;
        placeholder?: string | null;
        link?: string | null;
        required?: boolean | null;
        collect?: boolean | null;
        custom?: boolean | null;
        textColor?: string | null;
        borderColor?: string | null;
        backgroundColor?: string | null;
      } | null> | null;
      collectAfterStep?: Array<string | null> | null;
      cacheLeadFormAnswers?: boolean | null;
      googleAnalyticsID?: string | null;
      facebookAnalyticsID?: string | null;
      totalResponses?: number | null;
      unreadResponses?: number | null;
      tolstoyType?: string | null;
      steps?: {
        __typename: 'ModelprojectStepConnection';
        items: Array<{
          __typename: 'projectStep';
          id: string;
          owner?: string | null;
          appKey?: string | null;
          projectId: string;
          videoId?: string | null;
          name: string;
          type?: string | null;
          description?: string | null;
          script?: string | null;
          question?: string | null;
          questionVisible?: boolean | null;
          answerTime?: number | null;
          answers?: Array<{
            __typename: 'answer';
            key?: string | null;
            text?: string | null;
            next?: string | null;
            value?: string | null;
            type?: string | null;
            disabled?: boolean | null;
          } | null> | null;
          products?: Array<{
            __typename: 'product';
            id?: string | null;
          } | null> | null;
          productsAppUrl?: string | null;
          videoContain?: boolean | null;
          overlayText?: string | null;
          overlayTextColor?: string | null;
          multipleChoiceSelection?: boolean | null;
          defaultResponses?: Array<{
            __typename: 'answer';
            key?: string | null;
            text?: string | null;
            next?: string | null;
            value?: string | null;
            type?: string | null;
            disabled?: boolean | null;
          } | null> | null;
          defaultResponsesEnabled?: boolean | null;
          sideBarButtonsEnabled?: boolean | null;
          feedProductActionButton?: {
            __typename: 'feedProductActionButton';
            type?: string | null;
            url?: string | null;
            text?: string | null;
            isOpenInANewTab?: boolean | null;
            value?: string | null;
            enabled?: boolean | null;
          } | null;
          feedSidePanelType?: FeedSidePanelType | null;
          feedDescriptionActionButton?: {
            __typename: 'feedProductActionButton';
            type?: string | null;
            url?: string | null;
            text?: string | null;
            isOpenInANewTab?: boolean | null;
            value?: string | null;
            enabled?: boolean | null;
          } | null;
          feedSidePanelDescription?: string | null;
          productCtaType?: ProductCtaType | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      stepsCount?: number | null;
      widgetBorderColor?: string | null;
      widgetShape?: string | null;
      widgetSize?: string | null;
      widgetNotificationBadge?: boolean | null;
      videoResolutions?: Array<string | null> | null;
      fastForwardEnabled?: boolean | null;
      rulesEnabled?: boolean | null;
      macro?: boolean | null;
      hideChatPlatform?: boolean | null;
      private?: boolean | null;
      chatLandingPage?: boolean | null;
      widgetSettings?: string | null;
      notificationSettingsId?: string | null;
      collectAfterAnyResponse?: boolean | null;
      startScreenText?: string | null;
      feed?: boolean | null;
      feedAutoScroll?: boolean | null;
      feedShowUnviewedStepsFirst?: boolean | null;
      language?: string | null;
      showButtonsIndex?: boolean | null;
      publishMethod?: publishMethodOptions | null;
      dynamic?: boolean | null;
      feedProductCtaType?: ProductCtaType | null;
      playerSettings?: string | null;
      appUrl?: string | null;
      live?: boolean | null;
      primaryButton?: {
        __typename: 'ctaButtonConfig';
        enabled?: boolean | null;
        ctaType?: ProductCtaType | null;
        buttonText?: string | null;
        value?: string | null;
        isOpenInANewTab?: boolean | null;
      } | null;
      secondaryButton?: {
        __typename: 'ctaButtonConfig';
        enabled?: boolean | null;
        ctaType?: ProductCtaType | null;
        buttonText?: string | null;
        value?: string | null;
        isOpenInANewTab?: boolean | null;
      } | null;
      pdpConfig?: {
        __typename: 'pdpConfig';
        primaryButton?: {
          __typename: 'ctaButtonConfig';
          enabled?: boolean | null;
          ctaType?: ProductCtaType | null;
          buttonText?: string | null;
          value?: string | null;
          isOpenInANewTab?: boolean | null;
        } | null;
        secondaryButton?: {
          __typename: 'ctaButtonConfig';
          enabled?: boolean | null;
          ctaType?: ProductCtaType | null;
          buttonText?: string | null;
          value?: string | null;
          isOpenInANewTab?: boolean | null;
        } | null;
        miniCtaButton?: {
          __typename: 'miniCtaButtonConfig';
          enabled?: boolean | null;
          text?: string | null;
        } | null;
      } | null;
      installationCompleted?: boolean | null;
      installStep?: string | null;
      discover?: boolean | null;
      useCaseName?: string | null;
      heroSettings?: string | null;
      sourceProjectId?: string | null;
      emailSettings?: string | null;
      videoCollectionCampaignSettings?: string | null;
      secretVideoSettings?: string | null;
      integrationSettings?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ProjectsByAppKeySortByPublishMethodQueryVariables = {
  appKey?: string | null;
  publishMethod?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelprojectFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ProjectsByAppKeySortByPublishMethodQuery = {
  projectsByAppKeySortByPublishMethod?: {
    __typename: 'ModelprojectConnection';
    items: Array<{
      __typename: 'project';
      id: string;
      owner?: string | null;
      appKey?: string | null;
      typeKey?: string | null;
      createdAt?: string | null;
      publishId: string;
      name: string;
      folder?: string | null;
      targetPage?: string | null;
      verticalOrientation?: boolean | null;
      widgetPosition?: string | null;
      widgetHeader?: string | null;
      widgetUrls?: Array<string | null> | null;
      description?: string | null;
      emailNotifications?: Array<string | null> | null;
      notificationLevel?: number | null;
      viewerNotificationLevel?: number | null;
      chaptersEnabled?: boolean | null;
      subtitlesEnabled?: boolean | null;
      stepsOrder?: Array<string | null> | null;
      collectText?: string | null;
      collectFields?: Array<{
        __typename: 'inputField';
        key?: string | null;
        name?: string | null;
        type?: string | null;
        placeholder?: string | null;
        link?: string | null;
        required?: boolean | null;
        collect?: boolean | null;
        custom?: boolean | null;
        textColor?: string | null;
        borderColor?: string | null;
        backgroundColor?: string | null;
      } | null> | null;
      collectAfterStep?: Array<string | null> | null;
      cacheLeadFormAnswers?: boolean | null;
      googleAnalyticsID?: string | null;
      facebookAnalyticsID?: string | null;
      totalResponses?: number | null;
      unreadResponses?: number | null;
      tolstoyType?: string | null;
      steps?: {
        __typename: 'ModelprojectStepConnection';
        items: Array<{
          __typename: 'projectStep';
          id: string;
          owner?: string | null;
          appKey?: string | null;
          projectId: string;
          videoId?: string | null;
          name: string;
          type?: string | null;
          description?: string | null;
          script?: string | null;
          question?: string | null;
          questionVisible?: boolean | null;
          answerTime?: number | null;
          answers?: Array<{
            __typename: 'answer';
            key?: string | null;
            text?: string | null;
            next?: string | null;
            value?: string | null;
            type?: string | null;
            disabled?: boolean | null;
          } | null> | null;
          products?: Array<{
            __typename: 'product';
            id?: string | null;
          } | null> | null;
          productsAppUrl?: string | null;
          videoContain?: boolean | null;
          overlayText?: string | null;
          overlayTextColor?: string | null;
          multipleChoiceSelection?: boolean | null;
          defaultResponses?: Array<{
            __typename: 'answer';
            key?: string | null;
            text?: string | null;
            next?: string | null;
            value?: string | null;
            type?: string | null;
            disabled?: boolean | null;
          } | null> | null;
          defaultResponsesEnabled?: boolean | null;
          sideBarButtonsEnabled?: boolean | null;
          feedProductActionButton?: {
            __typename: 'feedProductActionButton';
            type?: string | null;
            url?: string | null;
            text?: string | null;
            isOpenInANewTab?: boolean | null;
            value?: string | null;
            enabled?: boolean | null;
          } | null;
          feedSidePanelType?: FeedSidePanelType | null;
          feedDescriptionActionButton?: {
            __typename: 'feedProductActionButton';
            type?: string | null;
            url?: string | null;
            text?: string | null;
            isOpenInANewTab?: boolean | null;
            value?: string | null;
            enabled?: boolean | null;
          } | null;
          feedSidePanelDescription?: string | null;
          productCtaType?: ProductCtaType | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      stepsCount?: number | null;
      widgetBorderColor?: string | null;
      widgetShape?: string | null;
      widgetSize?: string | null;
      widgetNotificationBadge?: boolean | null;
      videoResolutions?: Array<string | null> | null;
      fastForwardEnabled?: boolean | null;
      rulesEnabled?: boolean | null;
      macro?: boolean | null;
      hideChatPlatform?: boolean | null;
      private?: boolean | null;
      chatLandingPage?: boolean | null;
      widgetSettings?: string | null;
      notificationSettingsId?: string | null;
      collectAfterAnyResponse?: boolean | null;
      startScreenText?: string | null;
      feed?: boolean | null;
      feedAutoScroll?: boolean | null;
      feedShowUnviewedStepsFirst?: boolean | null;
      language?: string | null;
      showButtonsIndex?: boolean | null;
      publishMethod?: publishMethodOptions | null;
      dynamic?: boolean | null;
      feedProductCtaType?: ProductCtaType | null;
      playerSettings?: string | null;
      appUrl?: string | null;
      live?: boolean | null;
      primaryButton?: {
        __typename: 'ctaButtonConfig';
        enabled?: boolean | null;
        ctaType?: ProductCtaType | null;
        buttonText?: string | null;
        value?: string | null;
        isOpenInANewTab?: boolean | null;
      } | null;
      secondaryButton?: {
        __typename: 'ctaButtonConfig';
        enabled?: boolean | null;
        ctaType?: ProductCtaType | null;
        buttonText?: string | null;
        value?: string | null;
        isOpenInANewTab?: boolean | null;
      } | null;
      pdpConfig?: {
        __typename: 'pdpConfig';
        primaryButton?: {
          __typename: 'ctaButtonConfig';
          enabled?: boolean | null;
          ctaType?: ProductCtaType | null;
          buttonText?: string | null;
          value?: string | null;
          isOpenInANewTab?: boolean | null;
        } | null;
        secondaryButton?: {
          __typename: 'ctaButtonConfig';
          enabled?: boolean | null;
          ctaType?: ProductCtaType | null;
          buttonText?: string | null;
          value?: string | null;
          isOpenInANewTab?: boolean | null;
        } | null;
        miniCtaButton?: {
          __typename: 'miniCtaButtonConfig';
          enabled?: boolean | null;
          text?: string | null;
        } | null;
      } | null;
      installationCompleted?: boolean | null;
      installStep?: string | null;
      discover?: boolean | null;
      useCaseName?: string | null;
      heroSettings?: string | null;
      sourceProjectId?: string | null;
      emailSettings?: string | null;
      videoCollectionCampaignSettings?: string | null;
      secretVideoSettings?: string | null;
      integrationSettings?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ProjectsByPublishIdQueryVariables = {
  publishId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelprojectFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ProjectsByPublishIdQuery = {
  projectsByPublishId?: {
    __typename: 'ModelprojectConnection';
    items: Array<{
      __typename: 'project';
      id: string;
      owner?: string | null;
      appKey?: string | null;
      typeKey?: string | null;
      createdAt?: string | null;
      publishId: string;
      name: string;
      folder?: string | null;
      targetPage?: string | null;
      verticalOrientation?: boolean | null;
      widgetPosition?: string | null;
      widgetHeader?: string | null;
      widgetUrls?: Array<string | null> | null;
      description?: string | null;
      emailNotifications?: Array<string | null> | null;
      notificationLevel?: number | null;
      viewerNotificationLevel?: number | null;
      chaptersEnabled?: boolean | null;
      subtitlesEnabled?: boolean | null;
      stepsOrder?: Array<string | null> | null;
      collectText?: string | null;
      collectFields?: Array<{
        __typename: 'inputField';
        key?: string | null;
        name?: string | null;
        type?: string | null;
        placeholder?: string | null;
        link?: string | null;
        required?: boolean | null;
        collect?: boolean | null;
        custom?: boolean | null;
        textColor?: string | null;
        borderColor?: string | null;
        backgroundColor?: string | null;
      } | null> | null;
      collectAfterStep?: Array<string | null> | null;
      cacheLeadFormAnswers?: boolean | null;
      googleAnalyticsID?: string | null;
      facebookAnalyticsID?: string | null;
      totalResponses?: number | null;
      unreadResponses?: number | null;
      tolstoyType?: string | null;
      steps?: {
        __typename: 'ModelprojectStepConnection';
        items: Array<{
          __typename: 'projectStep';
          id: string;
          owner?: string | null;
          appKey?: string | null;
          projectId: string;
          videoId?: string | null;
          name: string;
          type?: string | null;
          description?: string | null;
          script?: string | null;
          question?: string | null;
          questionVisible?: boolean | null;
          answerTime?: number | null;
          answers?: Array<{
            __typename: 'answer';
            key?: string | null;
            text?: string | null;
            next?: string | null;
            value?: string | null;
            type?: string | null;
            disabled?: boolean | null;
          } | null> | null;
          products?: Array<{
            __typename: 'product';
            id?: string | null;
          } | null> | null;
          productsAppUrl?: string | null;
          videoContain?: boolean | null;
          overlayText?: string | null;
          overlayTextColor?: string | null;
          multipleChoiceSelection?: boolean | null;
          defaultResponses?: Array<{
            __typename: 'answer';
            key?: string | null;
            text?: string | null;
            next?: string | null;
            value?: string | null;
            type?: string | null;
            disabled?: boolean | null;
          } | null> | null;
          defaultResponsesEnabled?: boolean | null;
          sideBarButtonsEnabled?: boolean | null;
          feedProductActionButton?: {
            __typename: 'feedProductActionButton';
            type?: string | null;
            url?: string | null;
            text?: string | null;
            isOpenInANewTab?: boolean | null;
            value?: string | null;
            enabled?: boolean | null;
          } | null;
          feedSidePanelType?: FeedSidePanelType | null;
          feedDescriptionActionButton?: {
            __typename: 'feedProductActionButton';
            type?: string | null;
            url?: string | null;
            text?: string | null;
            isOpenInANewTab?: boolean | null;
            value?: string | null;
            enabled?: boolean | null;
          } | null;
          feedSidePanelDescription?: string | null;
          productCtaType?: ProductCtaType | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      stepsCount?: number | null;
      widgetBorderColor?: string | null;
      widgetShape?: string | null;
      widgetSize?: string | null;
      widgetNotificationBadge?: boolean | null;
      videoResolutions?: Array<string | null> | null;
      fastForwardEnabled?: boolean | null;
      rulesEnabled?: boolean | null;
      macro?: boolean | null;
      hideChatPlatform?: boolean | null;
      private?: boolean | null;
      chatLandingPage?: boolean | null;
      widgetSettings?: string | null;
      notificationSettingsId?: string | null;
      collectAfterAnyResponse?: boolean | null;
      startScreenText?: string | null;
      feed?: boolean | null;
      feedAutoScroll?: boolean | null;
      feedShowUnviewedStepsFirst?: boolean | null;
      language?: string | null;
      showButtonsIndex?: boolean | null;
      publishMethod?: publishMethodOptions | null;
      dynamic?: boolean | null;
      feedProductCtaType?: ProductCtaType | null;
      playerSettings?: string | null;
      appUrl?: string | null;
      live?: boolean | null;
      primaryButton?: {
        __typename: 'ctaButtonConfig';
        enabled?: boolean | null;
        ctaType?: ProductCtaType | null;
        buttonText?: string | null;
        value?: string | null;
        isOpenInANewTab?: boolean | null;
      } | null;
      secondaryButton?: {
        __typename: 'ctaButtonConfig';
        enabled?: boolean | null;
        ctaType?: ProductCtaType | null;
        buttonText?: string | null;
        value?: string | null;
        isOpenInANewTab?: boolean | null;
      } | null;
      pdpConfig?: {
        __typename: 'pdpConfig';
        primaryButton?: {
          __typename: 'ctaButtonConfig';
          enabled?: boolean | null;
          ctaType?: ProductCtaType | null;
          buttonText?: string | null;
          value?: string | null;
          isOpenInANewTab?: boolean | null;
        } | null;
        secondaryButton?: {
          __typename: 'ctaButtonConfig';
          enabled?: boolean | null;
          ctaType?: ProductCtaType | null;
          buttonText?: string | null;
          value?: string | null;
          isOpenInANewTab?: boolean | null;
        } | null;
        miniCtaButton?: {
          __typename: 'miniCtaButtonConfig';
          enabled?: boolean | null;
          text?: string | null;
        } | null;
      } | null;
      installationCompleted?: boolean | null;
      installStep?: string | null;
      discover?: boolean | null;
      useCaseName?: string | null;
      heroSettings?: string | null;
      sourceProjectId?: string | null;
      emailSettings?: string | null;
      videoCollectionCampaignSettings?: string | null;
      secretVideoSettings?: string | null;
      integrationSettings?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ProjectsByTypeKeyQueryVariables = {
  typeKey?: string | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelprojectFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ProjectsByTypeKeyQuery = {
  projectsByTypeKey?: {
    __typename: 'ModelprojectConnection';
    items: Array<{
      __typename: 'project';
      id: string;
      owner?: string | null;
      appKey?: string | null;
      typeKey?: string | null;
      createdAt?: string | null;
      publishId: string;
      name: string;
      folder?: string | null;
      targetPage?: string | null;
      verticalOrientation?: boolean | null;
      widgetPosition?: string | null;
      widgetHeader?: string | null;
      widgetUrls?: Array<string | null> | null;
      description?: string | null;
      emailNotifications?: Array<string | null> | null;
      notificationLevel?: number | null;
      viewerNotificationLevel?: number | null;
      chaptersEnabled?: boolean | null;
      subtitlesEnabled?: boolean | null;
      stepsOrder?: Array<string | null> | null;
      collectText?: string | null;
      collectFields?: Array<{
        __typename: 'inputField';
        key?: string | null;
        name?: string | null;
        type?: string | null;
        placeholder?: string | null;
        link?: string | null;
        required?: boolean | null;
        collect?: boolean | null;
        custom?: boolean | null;
        textColor?: string | null;
        borderColor?: string | null;
        backgroundColor?: string | null;
      } | null> | null;
      collectAfterStep?: Array<string | null> | null;
      cacheLeadFormAnswers?: boolean | null;
      googleAnalyticsID?: string | null;
      facebookAnalyticsID?: string | null;
      totalResponses?: number | null;
      unreadResponses?: number | null;
      tolstoyType?: string | null;
      steps?: {
        __typename: 'ModelprojectStepConnection';
        items: Array<{
          __typename: 'projectStep';
          id: string;
          owner?: string | null;
          appKey?: string | null;
          projectId: string;
          videoId?: string | null;
          name: string;
          type?: string | null;
          description?: string | null;
          script?: string | null;
          question?: string | null;
          questionVisible?: boolean | null;
          answerTime?: number | null;
          answers?: Array<{
            __typename: 'answer';
            key?: string | null;
            text?: string | null;
            next?: string | null;
            value?: string | null;
            type?: string | null;
            disabled?: boolean | null;
          } | null> | null;
          products?: Array<{
            __typename: 'product';
            id?: string | null;
          } | null> | null;
          productsAppUrl?: string | null;
          videoContain?: boolean | null;
          overlayText?: string | null;
          overlayTextColor?: string | null;
          multipleChoiceSelection?: boolean | null;
          defaultResponses?: Array<{
            __typename: 'answer';
            key?: string | null;
            text?: string | null;
            next?: string | null;
            value?: string | null;
            type?: string | null;
            disabled?: boolean | null;
          } | null> | null;
          defaultResponsesEnabled?: boolean | null;
          sideBarButtonsEnabled?: boolean | null;
          feedProductActionButton?: {
            __typename: 'feedProductActionButton';
            type?: string | null;
            url?: string | null;
            text?: string | null;
            isOpenInANewTab?: boolean | null;
            value?: string | null;
            enabled?: boolean | null;
          } | null;
          feedSidePanelType?: FeedSidePanelType | null;
          feedDescriptionActionButton?: {
            __typename: 'feedProductActionButton';
            type?: string | null;
            url?: string | null;
            text?: string | null;
            isOpenInANewTab?: boolean | null;
            value?: string | null;
            enabled?: boolean | null;
          } | null;
          feedSidePanelDescription?: string | null;
          productCtaType?: ProductCtaType | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      stepsCount?: number | null;
      widgetBorderColor?: string | null;
      widgetShape?: string | null;
      widgetSize?: string | null;
      widgetNotificationBadge?: boolean | null;
      videoResolutions?: Array<string | null> | null;
      fastForwardEnabled?: boolean | null;
      rulesEnabled?: boolean | null;
      macro?: boolean | null;
      hideChatPlatform?: boolean | null;
      private?: boolean | null;
      chatLandingPage?: boolean | null;
      widgetSettings?: string | null;
      notificationSettingsId?: string | null;
      collectAfterAnyResponse?: boolean | null;
      startScreenText?: string | null;
      feed?: boolean | null;
      feedAutoScroll?: boolean | null;
      feedShowUnviewedStepsFirst?: boolean | null;
      language?: string | null;
      showButtonsIndex?: boolean | null;
      publishMethod?: publishMethodOptions | null;
      dynamic?: boolean | null;
      feedProductCtaType?: ProductCtaType | null;
      playerSettings?: string | null;
      appUrl?: string | null;
      live?: boolean | null;
      primaryButton?: {
        __typename: 'ctaButtonConfig';
        enabled?: boolean | null;
        ctaType?: ProductCtaType | null;
        buttonText?: string | null;
        value?: string | null;
        isOpenInANewTab?: boolean | null;
      } | null;
      secondaryButton?: {
        __typename: 'ctaButtonConfig';
        enabled?: boolean | null;
        ctaType?: ProductCtaType | null;
        buttonText?: string | null;
        value?: string | null;
        isOpenInANewTab?: boolean | null;
      } | null;
      pdpConfig?: {
        __typename: 'pdpConfig';
        primaryButton?: {
          __typename: 'ctaButtonConfig';
          enabled?: boolean | null;
          ctaType?: ProductCtaType | null;
          buttonText?: string | null;
          value?: string | null;
          isOpenInANewTab?: boolean | null;
        } | null;
        secondaryButton?: {
          __typename: 'ctaButtonConfig';
          enabled?: boolean | null;
          ctaType?: ProductCtaType | null;
          buttonText?: string | null;
          value?: string | null;
          isOpenInANewTab?: boolean | null;
        } | null;
        miniCtaButton?: {
          __typename: 'miniCtaButtonConfig';
          enabled?: boolean | null;
          text?: string | null;
        } | null;
      } | null;
      installationCompleted?: boolean | null;
      installStep?: string | null;
      discover?: boolean | null;
      useCaseName?: string | null;
      heroSettings?: string | null;
      sourceProjectId?: string | null;
      emailSettings?: string | null;
      videoCollectionCampaignSettings?: string | null;
      secretVideoSettings?: string | null;
      integrationSettings?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ProjectsByFolderQueryVariables = {
  folder?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelprojectFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ProjectsByFolderQuery = {
  projectsByFolder?: {
    __typename: 'ModelprojectConnection';
    items: Array<{
      __typename: 'project';
      id: string;
      owner?: string | null;
      appKey?: string | null;
      typeKey?: string | null;
      createdAt?: string | null;
      publishId: string;
      name: string;
      folder?: string | null;
      targetPage?: string | null;
      verticalOrientation?: boolean | null;
      widgetPosition?: string | null;
      widgetHeader?: string | null;
      widgetUrls?: Array<string | null> | null;
      description?: string | null;
      emailNotifications?: Array<string | null> | null;
      notificationLevel?: number | null;
      viewerNotificationLevel?: number | null;
      chaptersEnabled?: boolean | null;
      subtitlesEnabled?: boolean | null;
      stepsOrder?: Array<string | null> | null;
      collectText?: string | null;
      collectFields?: Array<{
        __typename: 'inputField';
        key?: string | null;
        name?: string | null;
        type?: string | null;
        placeholder?: string | null;
        link?: string | null;
        required?: boolean | null;
        collect?: boolean | null;
        custom?: boolean | null;
        textColor?: string | null;
        borderColor?: string | null;
        backgroundColor?: string | null;
      } | null> | null;
      collectAfterStep?: Array<string | null> | null;
      cacheLeadFormAnswers?: boolean | null;
      googleAnalyticsID?: string | null;
      facebookAnalyticsID?: string | null;
      totalResponses?: number | null;
      unreadResponses?: number | null;
      tolstoyType?: string | null;
      steps?: {
        __typename: 'ModelprojectStepConnection';
        items: Array<{
          __typename: 'projectStep';
          id: string;
          owner?: string | null;
          appKey?: string | null;
          projectId: string;
          videoId?: string | null;
          name: string;
          type?: string | null;
          description?: string | null;
          script?: string | null;
          question?: string | null;
          questionVisible?: boolean | null;
          answerTime?: number | null;
          answers?: Array<{
            __typename: 'answer';
            key?: string | null;
            text?: string | null;
            next?: string | null;
            value?: string | null;
            type?: string | null;
            disabled?: boolean | null;
          } | null> | null;
          products?: Array<{
            __typename: 'product';
            id?: string | null;
          } | null> | null;
          productsAppUrl?: string | null;
          videoContain?: boolean | null;
          overlayText?: string | null;
          overlayTextColor?: string | null;
          multipleChoiceSelection?: boolean | null;
          defaultResponses?: Array<{
            __typename: 'answer';
            key?: string | null;
            text?: string | null;
            next?: string | null;
            value?: string | null;
            type?: string | null;
            disabled?: boolean | null;
          } | null> | null;
          defaultResponsesEnabled?: boolean | null;
          sideBarButtonsEnabled?: boolean | null;
          feedProductActionButton?: {
            __typename: 'feedProductActionButton';
            type?: string | null;
            url?: string | null;
            text?: string | null;
            isOpenInANewTab?: boolean | null;
            value?: string | null;
            enabled?: boolean | null;
          } | null;
          feedSidePanelType?: FeedSidePanelType | null;
          feedDescriptionActionButton?: {
            __typename: 'feedProductActionButton';
            type?: string | null;
            url?: string | null;
            text?: string | null;
            isOpenInANewTab?: boolean | null;
            value?: string | null;
            enabled?: boolean | null;
          } | null;
          feedSidePanelDescription?: string | null;
          productCtaType?: ProductCtaType | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      stepsCount?: number | null;
      widgetBorderColor?: string | null;
      widgetShape?: string | null;
      widgetSize?: string | null;
      widgetNotificationBadge?: boolean | null;
      videoResolutions?: Array<string | null> | null;
      fastForwardEnabled?: boolean | null;
      rulesEnabled?: boolean | null;
      macro?: boolean | null;
      hideChatPlatform?: boolean | null;
      private?: boolean | null;
      chatLandingPage?: boolean | null;
      widgetSettings?: string | null;
      notificationSettingsId?: string | null;
      collectAfterAnyResponse?: boolean | null;
      startScreenText?: string | null;
      feed?: boolean | null;
      feedAutoScroll?: boolean | null;
      feedShowUnviewedStepsFirst?: boolean | null;
      language?: string | null;
      showButtonsIndex?: boolean | null;
      publishMethod?: publishMethodOptions | null;
      dynamic?: boolean | null;
      feedProductCtaType?: ProductCtaType | null;
      playerSettings?: string | null;
      appUrl?: string | null;
      live?: boolean | null;
      primaryButton?: {
        __typename: 'ctaButtonConfig';
        enabled?: boolean | null;
        ctaType?: ProductCtaType | null;
        buttonText?: string | null;
        value?: string | null;
        isOpenInANewTab?: boolean | null;
      } | null;
      secondaryButton?: {
        __typename: 'ctaButtonConfig';
        enabled?: boolean | null;
        ctaType?: ProductCtaType | null;
        buttonText?: string | null;
        value?: string | null;
        isOpenInANewTab?: boolean | null;
      } | null;
      pdpConfig?: {
        __typename: 'pdpConfig';
        primaryButton?: {
          __typename: 'ctaButtonConfig';
          enabled?: boolean | null;
          ctaType?: ProductCtaType | null;
          buttonText?: string | null;
          value?: string | null;
          isOpenInANewTab?: boolean | null;
        } | null;
        secondaryButton?: {
          __typename: 'ctaButtonConfig';
          enabled?: boolean | null;
          ctaType?: ProductCtaType | null;
          buttonText?: string | null;
          value?: string | null;
          isOpenInANewTab?: boolean | null;
        } | null;
        miniCtaButton?: {
          __typename: 'miniCtaButtonConfig';
          enabled?: boolean | null;
          text?: string | null;
        } | null;
      } | null;
      installationCompleted?: boolean | null;
      installStep?: string | null;
      discover?: boolean | null;
      useCaseName?: string | null;
      heroSettings?: string | null;
      sourceProjectId?: string | null;
      emailSettings?: string | null;
      videoCollectionCampaignSettings?: string | null;
      secretVideoSettings?: string | null;
      integrationSettings?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type StepsByProjectIdQueryVariables = {
  projectId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelprojectStepFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type StepsByProjectIdQuery = {
  stepsByProjectId?: {
    __typename: 'ModelprojectStepConnection';
    items: Array<{
      __typename: 'projectStep';
      id: string;
      owner?: string | null;
      appKey?: string | null;
      projectId: string;
      videoId?: string | null;
      name: string;
      type?: string | null;
      description?: string | null;
      script?: string | null;
      question?: string | null;
      questionVisible?: boolean | null;
      answerTime?: number | null;
      answers?: Array<{
        __typename: 'answer';
        key?: string | null;
        text?: string | null;
        next?: string | null;
        value?: string | null;
        type?: string | null;
        disabled?: boolean | null;
      } | null> | null;
      products?: Array<{
        __typename: 'product';
        id?: string | null;
      } | null> | null;
      productsAppUrl?: string | null;
      videoContain?: boolean | null;
      overlayText?: string | null;
      overlayTextColor?: string | null;
      multipleChoiceSelection?: boolean | null;
      defaultResponses?: Array<{
        __typename: 'answer';
        key?: string | null;
        text?: string | null;
        next?: string | null;
        value?: string | null;
        type?: string | null;
        disabled?: boolean | null;
      } | null> | null;
      defaultResponsesEnabled?: boolean | null;
      sideBarButtonsEnabled?: boolean | null;
      feedProductActionButton?: {
        __typename: 'feedProductActionButton';
        type?: string | null;
        url?: string | null;
        text?: string | null;
        isOpenInANewTab?: boolean | null;
        value?: string | null;
        enabled?: boolean | null;
      } | null;
      feedSidePanelType?: FeedSidePanelType | null;
      feedDescriptionActionButton?: {
        __typename: 'feedProductActionButton';
        type?: string | null;
        url?: string | null;
        text?: string | null;
        isOpenInANewTab?: boolean | null;
        value?: string | null;
        enabled?: boolean | null;
      } | null;
      feedSidePanelDescription?: string | null;
      productCtaType?: ProductCtaType | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type StepsByOwnerQueryVariables = {
  owner?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelprojectStepFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type StepsByOwnerQuery = {
  stepsByOwner?: {
    __typename: 'ModelprojectStepConnection';
    items: Array<{
      __typename: 'projectStep';
      id: string;
      owner?: string | null;
      appKey?: string | null;
      projectId: string;
      videoId?: string | null;
      name: string;
      type?: string | null;
      description?: string | null;
      script?: string | null;
      question?: string | null;
      questionVisible?: boolean | null;
      answerTime?: number | null;
      answers?: Array<{
        __typename: 'answer';
        key?: string | null;
        text?: string | null;
        next?: string | null;
        value?: string | null;
        type?: string | null;
        disabled?: boolean | null;
      } | null> | null;
      products?: Array<{
        __typename: 'product';
        id?: string | null;
      } | null> | null;
      productsAppUrl?: string | null;
      videoContain?: boolean | null;
      overlayText?: string | null;
      overlayTextColor?: string | null;
      multipleChoiceSelection?: boolean | null;
      defaultResponses?: Array<{
        __typename: 'answer';
        key?: string | null;
        text?: string | null;
        next?: string | null;
        value?: string | null;
        type?: string | null;
        disabled?: boolean | null;
      } | null> | null;
      defaultResponsesEnabled?: boolean | null;
      sideBarButtonsEnabled?: boolean | null;
      feedProductActionButton?: {
        __typename: 'feedProductActionButton';
        type?: string | null;
        url?: string | null;
        text?: string | null;
        isOpenInANewTab?: boolean | null;
        value?: string | null;
        enabled?: boolean | null;
      } | null;
      feedSidePanelType?: FeedSidePanelType | null;
      feedDescriptionActionButton?: {
        __typename: 'feedProductActionButton';
        type?: string | null;
        url?: string | null;
        text?: string | null;
        isOpenInANewTab?: boolean | null;
        value?: string | null;
        enabled?: boolean | null;
      } | null;
      feedSidePanelDescription?: string | null;
      productCtaType?: ProductCtaType | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type StepsByAppKeyQueryVariables = {
  appKey?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelprojectStepFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type StepsByAppKeyQuery = {
  stepsByAppKey?: {
    __typename: 'ModelprojectStepConnection';
    items: Array<{
      __typename: 'projectStep';
      id: string;
      owner?: string | null;
      appKey?: string | null;
      projectId: string;
      videoId?: string | null;
      name: string;
      type?: string | null;
      description?: string | null;
      script?: string | null;
      question?: string | null;
      questionVisible?: boolean | null;
      answerTime?: number | null;
      answers?: Array<{
        __typename: 'answer';
        key?: string | null;
        text?: string | null;
        next?: string | null;
        value?: string | null;
        type?: string | null;
        disabled?: boolean | null;
      } | null> | null;
      products?: Array<{
        __typename: 'product';
        id?: string | null;
      } | null> | null;
      productsAppUrl?: string | null;
      videoContain?: boolean | null;
      overlayText?: string | null;
      overlayTextColor?: string | null;
      multipleChoiceSelection?: boolean | null;
      defaultResponses?: Array<{
        __typename: 'answer';
        key?: string | null;
        text?: string | null;
        next?: string | null;
        value?: string | null;
        type?: string | null;
        disabled?: boolean | null;
      } | null> | null;
      defaultResponsesEnabled?: boolean | null;
      sideBarButtonsEnabled?: boolean | null;
      feedProductActionButton?: {
        __typename: 'feedProductActionButton';
        type?: string | null;
        url?: string | null;
        text?: string | null;
        isOpenInANewTab?: boolean | null;
        value?: string | null;
        enabled?: boolean | null;
      } | null;
      feedSidePanelType?: FeedSidePanelType | null;
      feedDescriptionActionButton?: {
        __typename: 'feedProductActionButton';
        type?: string | null;
        url?: string | null;
        text?: string | null;
        isOpenInANewTab?: boolean | null;
        value?: string | null;
        enabled?: boolean | null;
      } | null;
      feedSidePanelDescription?: string | null;
      productCtaType?: ProductCtaType | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type StepsByVideoIdQueryVariables = {
  videoId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelprojectStepFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type StepsByVideoIdQuery = {
  stepsByVideoId?: {
    __typename: 'ModelprojectStepConnection';
    items: Array<{
      __typename: 'projectStep';
      id: string;
      owner?: string | null;
      appKey?: string | null;
      projectId: string;
      videoId?: string | null;
      name: string;
      type?: string | null;
      description?: string | null;
      script?: string | null;
      question?: string | null;
      questionVisible?: boolean | null;
      answerTime?: number | null;
      answers?: Array<{
        __typename: 'answer';
        key?: string | null;
        text?: string | null;
        next?: string | null;
        value?: string | null;
        type?: string | null;
        disabled?: boolean | null;
      } | null> | null;
      products?: Array<{
        __typename: 'product';
        id?: string | null;
      } | null> | null;
      productsAppUrl?: string | null;
      videoContain?: boolean | null;
      overlayText?: string | null;
      overlayTextColor?: string | null;
      multipleChoiceSelection?: boolean | null;
      defaultResponses?: Array<{
        __typename: 'answer';
        key?: string | null;
        text?: string | null;
        next?: string | null;
        value?: string | null;
        type?: string | null;
        disabled?: boolean | null;
      } | null> | null;
      defaultResponsesEnabled?: boolean | null;
      sideBarButtonsEnabled?: boolean | null;
      feedProductActionButton?: {
        __typename: 'feedProductActionButton';
        type?: string | null;
        url?: string | null;
        text?: string | null;
        isOpenInANewTab?: boolean | null;
        value?: string | null;
        enabled?: boolean | null;
      } | null;
      feedSidePanelType?: FeedSidePanelType | null;
      feedDescriptionActionButton?: {
        __typename: 'feedProductActionButton';
        type?: string | null;
        url?: string | null;
        text?: string | null;
        isOpenInANewTab?: boolean | null;
        value?: string | null;
        enabled?: boolean | null;
      } | null;
      feedSidePanelDescription?: string | null;
      productCtaType?: ProductCtaType | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UserSettingsByEmailQueryVariables = {
  email?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModeluserSettingsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type UserSettingsByEmailQuery = {
  userSettingsByEmail?: {
    __typename: 'ModeluserSettingsConnection';
    items: Array<{
      __typename: 'userSettings';
      id: string;
      owner?: string | null;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      lastLogin?: string | null;
      source?: string | null;
      token?: string | null;
      appKey?: string | null;
      hasLoggedIn?: boolean | null;
      hasShared?: boolean | null;
      viewedTolstoys?: Array<string | null> | null;
      calendars?: Array<{
        __typename: 'calendar';
        id?: string | null;
        name?: string | null;
        url?: string | null;
      } | null> | null;
      emailAutomationTool?: string | null;
      logoSettings?: string | null;
      googleInfo?: string | null;
      crmData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UserSettingsByOwnerQueryVariables = {
  owner?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModeluserSettingsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type UserSettingsByOwnerQuery = {
  userSettingsByOwner?: {
    __typename: 'ModeluserSettingsConnection';
    items: Array<{
      __typename: 'userSettings';
      id: string;
      owner?: string | null;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      lastLogin?: string | null;
      source?: string | null;
      token?: string | null;
      appKey?: string | null;
      hasLoggedIn?: boolean | null;
      hasShared?: boolean | null;
      viewedTolstoys?: Array<string | null> | null;
      calendars?: Array<{
        __typename: 'calendar';
        id?: string | null;
        name?: string | null;
        url?: string | null;
      } | null> | null;
      emailAutomationTool?: string | null;
      logoSettings?: string | null;
      googleInfo?: string | null;
      crmData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UserSettingsByTokenQueryVariables = {
  token?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModeluserSettingsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type UserSettingsByTokenQuery = {
  userSettingsByToken?: {
    __typename: 'ModeluserSettingsConnection';
    items: Array<{
      __typename: 'userSettings';
      id: string;
      owner?: string | null;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      lastLogin?: string | null;
      source?: string | null;
      token?: string | null;
      appKey?: string | null;
      hasLoggedIn?: boolean | null;
      hasShared?: boolean | null;
      viewedTolstoys?: Array<string | null> | null;
      calendars?: Array<{
        __typename: 'calendar';
        id?: string | null;
        name?: string | null;
        url?: string | null;
      } | null> | null;
      emailAutomationTool?: string | null;
      logoSettings?: string | null;
      googleInfo?: string | null;
      crmData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UserSettingsByAppKeyQueryVariables = {
  appKey?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModeluserSettingsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type UserSettingsByAppKeyQuery = {
  userSettingsByAppKey?: {
    __typename: 'ModeluserSettingsConnection';
    items: Array<{
      __typename: 'userSettings';
      id: string;
      owner?: string | null;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      lastLogin?: string | null;
      source?: string | null;
      token?: string | null;
      appKey?: string | null;
      hasLoggedIn?: boolean | null;
      hasShared?: boolean | null;
      viewedTolstoys?: Array<string | null> | null;
      calendars?: Array<{
        __typename: 'calendar';
        id?: string | null;
        name?: string | null;
        url?: string | null;
      } | null> | null;
      emailAutomationTool?: string | null;
      logoSettings?: string | null;
      googleInfo?: string | null;
      crmData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AccountSettingsByAppKeyQueryVariables = {
  appKey?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelaccountSettingsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AccountSettingsByAppKeyQuery = {
  accountSettingsByAppKey?: {
    __typename: 'ModelaccountSettingsConnection';
    items: Array<{
      __typename: 'accountSettings';
      id: string;
      appKey: string;
      owner?: string | null;
      name?: string | null;
      logoSettings?: string | null;
      widgetSettings?: string | null;
      downgradeInfo?: string | null;
      liveIds?: Array<string | null> | null;
      url?: string | null;
      logoUrl?: string | null;
      playerLogoUrl?: string | null;
      companyVertical?: string | null;
      companySize?: string | null;
      companyRole?: string | null;
      monthlyTraffic?: string | null;
      useCase?: string | null;
      packageId?: string | null;
      token?: string | null;
      paymentProvider?: string | null;
      paymentSubscriptions?: Array<string | null> | null;
      paymentIdentifier?: string | null;
      packageGroup?: string | null;
      joinedPackageAt?: string | null;
      trialEndAt?: string | null;
      referral?: string | null;
      crmData?: string | null;
      trialDays?: number | null;
      googleAnalyticsID?: string | null;
      appsInstallationStatuses?: string | null;
      onboardingStatuses?: string | null;
      assetAnalysis?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AccountSettingsByOwnerQueryVariables = {
  owner?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelaccountSettingsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AccountSettingsByOwnerQuery = {
  accountSettingsByOwner?: {
    __typename: 'ModelaccountSettingsConnection';
    items: Array<{
      __typename: 'accountSettings';
      id: string;
      appKey: string;
      owner?: string | null;
      name?: string | null;
      logoSettings?: string | null;
      widgetSettings?: string | null;
      downgradeInfo?: string | null;
      liveIds?: Array<string | null> | null;
      url?: string | null;
      logoUrl?: string | null;
      playerLogoUrl?: string | null;
      companyVertical?: string | null;
      companySize?: string | null;
      companyRole?: string | null;
      monthlyTraffic?: string | null;
      useCase?: string | null;
      packageId?: string | null;
      token?: string | null;
      paymentProvider?: string | null;
      paymentSubscriptions?: Array<string | null> | null;
      paymentIdentifier?: string | null;
      packageGroup?: string | null;
      joinedPackageAt?: string | null;
      trialEndAt?: string | null;
      referral?: string | null;
      crmData?: string | null;
      trialDays?: number | null;
      googleAnalyticsID?: string | null;
      appsInstallationStatuses?: string | null;
      onboardingStatuses?: string | null;
      assetAnalysis?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AccountSettingsByPackageIdQueryVariables = {
  packageId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelaccountSettingsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AccountSettingsByPackageIdQuery = {
  accountSettingsByPackageId?: {
    __typename: 'ModelaccountSettingsConnection';
    items: Array<{
      __typename: 'accountSettings';
      id: string;
      appKey: string;
      owner?: string | null;
      name?: string | null;
      logoSettings?: string | null;
      widgetSettings?: string | null;
      downgradeInfo?: string | null;
      liveIds?: Array<string | null> | null;
      url?: string | null;
      logoUrl?: string | null;
      playerLogoUrl?: string | null;
      companyVertical?: string | null;
      companySize?: string | null;
      companyRole?: string | null;
      monthlyTraffic?: string | null;
      useCase?: string | null;
      packageId?: string | null;
      token?: string | null;
      paymentProvider?: string | null;
      paymentSubscriptions?: Array<string | null> | null;
      paymentIdentifier?: string | null;
      packageGroup?: string | null;
      joinedPackageAt?: string | null;
      trialEndAt?: string | null;
      referral?: string | null;
      crmData?: string | null;
      trialDays?: number | null;
      googleAnalyticsID?: string | null;
      appsInstallationStatuses?: string | null;
      onboardingStatuses?: string | null;
      assetAnalysis?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AccountSettingsByTokenQueryVariables = {
  token?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelaccountSettingsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AccountSettingsByTokenQuery = {
  accountSettingsByToken?: {
    __typename: 'ModelaccountSettingsConnection';
    items: Array<{
      __typename: 'accountSettings';
      id: string;
      appKey: string;
      owner?: string | null;
      name?: string | null;
      logoSettings?: string | null;
      widgetSettings?: string | null;
      downgradeInfo?: string | null;
      liveIds?: Array<string | null> | null;
      url?: string | null;
      logoUrl?: string | null;
      playerLogoUrl?: string | null;
      companyVertical?: string | null;
      companySize?: string | null;
      companyRole?: string | null;
      monthlyTraffic?: string | null;
      useCase?: string | null;
      packageId?: string | null;
      token?: string | null;
      paymentProvider?: string | null;
      paymentSubscriptions?: Array<string | null> | null;
      paymentIdentifier?: string | null;
      packageGroup?: string | null;
      joinedPackageAt?: string | null;
      trialEndAt?: string | null;
      referral?: string | null;
      crmData?: string | null;
      trialDays?: number | null;
      googleAnalyticsID?: string | null;
      appsInstallationStatuses?: string | null;
      onboardingStatuses?: string | null;
      assetAnalysis?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AccountSettingsByPaymentIdentifierQueryVariables = {
  paymentIdentifier?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelaccountSettingsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AccountSettingsByPaymentIdentifierQuery = {
  accountSettingsByPaymentIdentifier?: {
    __typename: 'ModelaccountSettingsConnection';
    items: Array<{
      __typename: 'accountSettings';
      id: string;
      appKey: string;
      owner?: string | null;
      name?: string | null;
      logoSettings?: string | null;
      widgetSettings?: string | null;
      downgradeInfo?: string | null;
      liveIds?: Array<string | null> | null;
      url?: string | null;
      logoUrl?: string | null;
      playerLogoUrl?: string | null;
      companyVertical?: string | null;
      companySize?: string | null;
      companyRole?: string | null;
      monthlyTraffic?: string | null;
      useCase?: string | null;
      packageId?: string | null;
      token?: string | null;
      paymentProvider?: string | null;
      paymentSubscriptions?: Array<string | null> | null;
      paymentIdentifier?: string | null;
      packageGroup?: string | null;
      joinedPackageAt?: string | null;
      trialEndAt?: string | null;
      referral?: string | null;
      crmData?: string | null;
      trialDays?: number | null;
      googleAnalyticsID?: string | null;
      appsInstallationStatuses?: string | null;
      onboardingStatuses?: string | null;
      assetAnalysis?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AppsByOwnerQueryVariables = {
  owner?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelappFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AppsByOwnerQuery = {
  appsByOwner?: {
    __typename: 'ModelappConnection';
    items: Array<{
      __typename: 'app';
      id: string;
      owner?: string | null;
      appKey?: string | null;
      app?: string | null;
      active?: boolean | null;
      data?: string | null;
      token?: string | null;
      appExternalId?: string | null;
      appUrl?: string | null;
      importSettings?: ImportSettings | null;
      importRange?: number | null;
      syncTagsMode?: string | null;
      syndicateBy?: SyndicateBy | null;
      googleAnalyticsID?: string | null;
      settings?: string | null;
      isShopAppEligible?: boolean | null;
      taxonomyId?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AppsByAppKeyQueryVariables = {
  appKey?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelappFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AppsByAppKeyQuery = {
  appsByAppKey?: {
    __typename: 'ModelappConnection';
    items: Array<{
      __typename: 'app';
      id: string;
      owner?: string | null;
      appKey?: string | null;
      app?: string | null;
      active?: boolean | null;
      data?: string | null;
      token?: string | null;
      appExternalId?: string | null;
      appUrl?: string | null;
      importSettings?: ImportSettings | null;
      importRange?: number | null;
      syncTagsMode?: string | null;
      syndicateBy?: SyndicateBy | null;
      googleAnalyticsID?: string | null;
      settings?: string | null;
      isShopAppEligible?: boolean | null;
      taxonomyId?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AppsByExternalIdQueryVariables = {
  appExternalId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelappFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AppsByExternalIdQuery = {
  appsByExternalId?: {
    __typename: 'ModelappConnection';
    items: Array<{
      __typename: 'app';
      id: string;
      owner?: string | null;
      appKey?: string | null;
      app?: string | null;
      active?: boolean | null;
      data?: string | null;
      token?: string | null;
      appExternalId?: string | null;
      appUrl?: string | null;
      importSettings?: ImportSettings | null;
      importRange?: number | null;
      syncTagsMode?: string | null;
      syndicateBy?: SyndicateBy | null;
      googleAnalyticsID?: string | null;
      settings?: string | null;
      isShopAppEligible?: boolean | null;
      taxonomyId?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AppsByAppUrlQueryVariables = {
  appUrl?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelappFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AppsByAppUrlQuery = {
  appsByAppUrl?: {
    __typename: 'ModelappConnection';
    items: Array<{
      __typename: 'app';
      id: string;
      owner?: string | null;
      appKey?: string | null;
      app?: string | null;
      active?: boolean | null;
      data?: string | null;
      token?: string | null;
      appExternalId?: string | null;
      appUrl?: string | null;
      importSettings?: ImportSettings | null;
      importRange?: number | null;
      syncTagsMode?: string | null;
      syndicateBy?: SyndicateBy | null;
      googleAnalyticsID?: string | null;
      settings?: string | null;
      isShopAppEligible?: boolean | null;
      taxonomyId?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AppsByImportSettingsQueryVariables = {
  importSettings?: ImportSettings | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelappFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AppsByImportSettingsQuery = {
  appsByImportSettings?: {
    __typename: 'ModelappConnection';
    items: Array<{
      __typename: 'app';
      id: string;
      owner?: string | null;
      appKey?: string | null;
      app?: string | null;
      active?: boolean | null;
      data?: string | null;
      token?: string | null;
      appExternalId?: string | null;
      appUrl?: string | null;
      importSettings?: ImportSettings | null;
      importRange?: number | null;
      syncTagsMode?: string | null;
      syndicateBy?: SyndicateBy | null;
      googleAnalyticsID?: string | null;
      settings?: string | null;
      isShopAppEligible?: boolean | null;
      taxonomyId?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ProjectAppsByProjectIdQueryVariables = {
  projectId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelprojectAppFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ProjectAppsByProjectIdQuery = {
  projectAppsByProjectId?: {
    __typename: 'ModelprojectAppConnection';
    items: Array<{
      __typename: 'projectApp';
      id: string;
      appId?: string | null;
      appKey?: string | null;
      projectId?: string | null;
      owner?: string | null;
      active?: boolean | null;
      data?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ProjectAppsByOwnerQueryVariables = {
  owner?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelprojectAppFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ProjectAppsByOwnerQuery = {
  projectAppsByOwner?: {
    __typename: 'ModelprojectAppConnection';
    items: Array<{
      __typename: 'projectApp';
      id: string;
      appId?: string | null;
      appKey?: string | null;
      projectId?: string | null;
      owner?: string | null;
      active?: boolean | null;
      data?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ProjectAppsByAppKeyQueryVariables = {
  appKey?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelprojectAppFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ProjectAppsByAppKeyQuery = {
  projectAppsByAppKey?: {
    __typename: 'ModelprojectAppConnection';
    items: Array<{
      __typename: 'projectApp';
      id: string;
      appId?: string | null;
      appKey?: string | null;
      projectId?: string | null;
      owner?: string | null;
      active?: boolean | null;
      data?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ResponsesByProjectIdQueryVariables = {
  projectId?: string | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelresponseFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ResponsesByProjectIdQuery = {
  responsesByProjectId?: {
    __typename: 'ModelresponseConnection';
    items: Array<{
      __typename: 'response';
      id: string;
      owner?: string | null;
      appKey?: string | null;
      stepName?: string | null;
      projectId?: string | null;
      anonymousId?: string | null;
      sessionId?: string | null;
      type?: string | null;
      value?: string | null;
      email?: string | null;
      name?: string | null;
      phone?: string | null;
      createdAt: string;
      read?: boolean | null;
      collectInfo?: string | null;
      answerType?: string | null;
      parentUrl?: string | null;
      isProfanity?: boolean | null;
      attachments?: Array<{
        __typename: 'attachment';
        fileName?: string | null;
        url?: string | null;
        type?: string | null;
      } | null> | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ResponsesByOwnerQueryVariables = {
  owner?: string | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelresponseFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ResponsesByOwnerQuery = {
  responsesByOwner?: {
    __typename: 'ModelresponseConnection';
    items: Array<{
      __typename: 'response';
      id: string;
      owner?: string | null;
      appKey?: string | null;
      stepName?: string | null;
      projectId?: string | null;
      anonymousId?: string | null;
      sessionId?: string | null;
      type?: string | null;
      value?: string | null;
      email?: string | null;
      name?: string | null;
      phone?: string | null;
      createdAt: string;
      read?: boolean | null;
      collectInfo?: string | null;
      answerType?: string | null;
      parentUrl?: string | null;
      isProfanity?: boolean | null;
      attachments?: Array<{
        __typename: 'attachment';
        fileName?: string | null;
        url?: string | null;
        type?: string | null;
      } | null> | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ResponsesByAppKeyQueryVariables = {
  appKey?: string | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelresponseFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ResponsesByAppKeyQuery = {
  responsesByAppKey?: {
    __typename: 'ModelresponseConnection';
    items: Array<{
      __typename: 'response';
      id: string;
      owner?: string | null;
      appKey?: string | null;
      stepName?: string | null;
      projectId?: string | null;
      anonymousId?: string | null;
      sessionId?: string | null;
      type?: string | null;
      value?: string | null;
      email?: string | null;
      name?: string | null;
      phone?: string | null;
      createdAt: string;
      read?: boolean | null;
      collectInfo?: string | null;
      answerType?: string | null;
      parentUrl?: string | null;
      isProfanity?: boolean | null;
      attachments?: Array<{
        __typename: 'attachment';
        fileName?: string | null;
        url?: string | null;
        type?: string | null;
      } | null> | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ResponsesBySessionIdQueryVariables = {
  sessionId?: string | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelresponseFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ResponsesBySessionIdQuery = {
  responsesBySessionId?: {
    __typename: 'ModelresponseConnection';
    items: Array<{
      __typename: 'response';
      id: string;
      owner?: string | null;
      appKey?: string | null;
      stepName?: string | null;
      projectId?: string | null;
      anonymousId?: string | null;
      sessionId?: string | null;
      type?: string | null;
      value?: string | null;
      email?: string | null;
      name?: string | null;
      phone?: string | null;
      createdAt: string;
      read?: boolean | null;
      collectInfo?: string | null;
      answerType?: string | null;
      parentUrl?: string | null;
      isProfanity?: boolean | null;
      attachments?: Array<{
        __typename: 'attachment';
        fileName?: string | null;
        url?: string | null;
        type?: string | null;
      } | null> | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ResponsesByEmailQueryVariables = {
  email?: string | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelresponseFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ResponsesByEmailQuery = {
  responsesByEmail?: {
    __typename: 'ModelresponseConnection';
    items: Array<{
      __typename: 'response';
      id: string;
      owner?: string | null;
      appKey?: string | null;
      stepName?: string | null;
      projectId?: string | null;
      anonymousId?: string | null;
      sessionId?: string | null;
      type?: string | null;
      value?: string | null;
      email?: string | null;
      name?: string | null;
      phone?: string | null;
      createdAt: string;
      read?: boolean | null;
      collectInfo?: string | null;
      answerType?: string | null;
      parentUrl?: string | null;
      isProfanity?: boolean | null;
      attachments?: Array<{
        __typename: 'attachment';
        fileName?: string | null;
        url?: string | null;
        type?: string | null;
      } | null> | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type InAppNotificationsByOwnerQueryVariables = {
  owner?: string | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelinAppNotificationFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type InAppNotificationsByOwnerQuery = {
  inAppNotificationsByOwner?: {
    __typename: 'ModelinAppNotificationConnection';
    items: Array<{
      __typename: 'inAppNotification';
      id: string;
      owner?: string | null;
      teamMemberId?: string | null;
      projectId?: string | null;
      sessionId?: string | null;
      type?: string | null;
      value?: string | null;
      email?: string | null;
      createdAt: string;
      read?: boolean | null;
      expirationUnixTime: number;
      token?: string | null;
      stepName?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type InAppNotificationsByTeamMemberIdQueryVariables = {
  teamMemberId?: string | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelinAppNotificationFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type InAppNotificationsByTeamMemberIdQuery = {
  inAppNotificationsByTeamMemberId?: {
    __typename: 'ModelinAppNotificationConnection';
    items: Array<{
      __typename: 'inAppNotification';
      id: string;
      owner?: string | null;
      teamMemberId?: string | null;
      projectId?: string | null;
      sessionId?: string | null;
      type?: string | null;
      value?: string | null;
      email?: string | null;
      createdAt: string;
      read?: boolean | null;
      expirationUnixTime: number;
      token?: string | null;
      stepName?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ConferenceRoomsByOwnerQueryVariables = {
  owner?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelconferenceRoomFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ConferenceRoomsByOwnerQuery = {
  conferenceRoomsByOwner?: {
    __typename: 'ModelconferenceRoomConnection';
    items: Array<{
      __typename: 'conferenceRoom';
      id: string;
      owner: string;
      roomId: string;
      userId: string;
      active?: boolean | null;
      email?: string | null;
      appToken?: string | null;
      lastSeenAt?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ConferenceRoomActivityByRoomNameQueryVariables = {
  roomName?: string | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelconferenceRoomActivityFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ConferenceRoomActivityByRoomNameQuery = {
  conferenceRoomActivityByRoomName?: {
    __typename: 'ModelconferenceRoomActivityConnection';
    items: Array<{
      __typename: 'conferenceRoomActivity';
      id: string;
      roomName?: string | null;
      createdAt: string;
      expirationUnixTime: number;
      updatedAt?: string | null;
      appToken?: string | null;
      type?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SessionsByProjectIdQueryVariables = {
  projectId?: string | null;
  lastEventAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelsessionFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SessionsByProjectIdQuery = {
  sessionsByProjectId?: {
    __typename: 'ModelsessionConnection';
    items: Array<{
      __typename: 'session';
      id: string;
      owner?: string | null;
      assignee?: string | null;
      appKey?: string | null;
      projectId?: string | null;
      createdAt: string;
      updatedAt: string;
      lastEventAt: string;
      sessionTime?: string | null;
      name?: string | null;
      email?: string | null;
      phone?: string | null;
      read?: boolean | null;
      archived?: boolean | null;
      actionNeeded?: boolean | null;
      totalEvents?: number | null;
      totalResponses?: number | null;
      totalReplies?: number | null;
      parentUrl?: string | null;
      videoResponse?: boolean | null;
      imageResponse?: boolean | null;
      audioResponse?: boolean | null;
      textResponse?: boolean | null;
      formResponse?: boolean | null;
      reachedEnd?: boolean | null;
      isSpam?: boolean | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SessionsByOwnerQueryVariables = {
  owner?: string | null;
  lastEventAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelsessionFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SessionsByOwnerQuery = {
  sessionsByOwner?: {
    __typename: 'ModelsessionConnection';
    items: Array<{
      __typename: 'session';
      id: string;
      owner?: string | null;
      assignee?: string | null;
      appKey?: string | null;
      projectId?: string | null;
      createdAt: string;
      updatedAt: string;
      lastEventAt: string;
      sessionTime?: string | null;
      name?: string | null;
      email?: string | null;
      phone?: string | null;
      read?: boolean | null;
      archived?: boolean | null;
      actionNeeded?: boolean | null;
      totalEvents?: number | null;
      totalResponses?: number | null;
      totalReplies?: number | null;
      parentUrl?: string | null;
      videoResponse?: boolean | null;
      imageResponse?: boolean | null;
      audioResponse?: boolean | null;
      textResponse?: boolean | null;
      formResponse?: boolean | null;
      reachedEnd?: boolean | null;
      isSpam?: boolean | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SessionsByEmailQueryVariables = {
  email?: string | null;
  lastEventAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelsessionFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SessionsByEmailQuery = {
  sessionsByEmail?: {
    __typename: 'ModelsessionConnection';
    items: Array<{
      __typename: 'session';
      id: string;
      owner?: string | null;
      assignee?: string | null;
      appKey?: string | null;
      projectId?: string | null;
      createdAt: string;
      updatedAt: string;
      lastEventAt: string;
      sessionTime?: string | null;
      name?: string | null;
      email?: string | null;
      phone?: string | null;
      read?: boolean | null;
      archived?: boolean | null;
      actionNeeded?: boolean | null;
      totalEvents?: number | null;
      totalResponses?: number | null;
      totalReplies?: number | null;
      parentUrl?: string | null;
      videoResponse?: boolean | null;
      imageResponse?: boolean | null;
      audioResponse?: boolean | null;
      textResponse?: boolean | null;
      formResponse?: boolean | null;
      reachedEnd?: boolean | null;
      isSpam?: boolean | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SessionByAppKeyQueryVariables = {
  appKey?: string | null;
  lastEventAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelsessionFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SessionByAppKeyQuery = {
  sessionByAppKey?: {
    __typename: 'ModelsessionConnection';
    items: Array<{
      __typename: 'session';
      id: string;
      owner?: string | null;
      assignee?: string | null;
      appKey?: string | null;
      projectId?: string | null;
      createdAt: string;
      updatedAt: string;
      lastEventAt: string;
      sessionTime?: string | null;
      name?: string | null;
      email?: string | null;
      phone?: string | null;
      read?: boolean | null;
      archived?: boolean | null;
      actionNeeded?: boolean | null;
      totalEvents?: number | null;
      totalResponses?: number | null;
      totalReplies?: number | null;
      parentUrl?: string | null;
      videoResponse?: boolean | null;
      imageResponse?: boolean | null;
      audioResponse?: boolean | null;
      textResponse?: boolean | null;
      formResponse?: boolean | null;
      reachedEnd?: boolean | null;
      isSpam?: boolean | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AccountIdentifiedUsersByOwnerQueryVariables = {
  owner?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelaccountIdentifiedUsersFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AccountIdentifiedUsersByOwnerQuery = {
  accountIdentifiedUsersByOwner?: {
    __typename: 'ModelaccountIdentifiedUsersConnection';
    items: Array<{
      __typename: 'accountIdentifiedUsers';
      id: string;
      owner?: string | null;
      appKey: string;
      userId?: string | null;
      email?: string | null;
      phone?: string | null;
      name?: string | null;
      lastSeenAt?: string | null;
      firstSeenAt?: string | null;
      signedUpAt?: string | null;
      sessionCount?: number | null;
      customAttributes?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AccountIdentifiedUsersByAppKeyQueryVariables = {
  appKey?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelaccountIdentifiedUsersFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AccountIdentifiedUsersByAppKeyQuery = {
  accountIdentifiedUsersByAppKey?: {
    __typename: 'ModelaccountIdentifiedUsersConnection';
    items: Array<{
      __typename: 'accountIdentifiedUsers';
      id: string;
      owner?: string | null;
      appKey: string;
      userId?: string | null;
      email?: string | null;
      phone?: string | null;
      name?: string | null;
      lastSeenAt?: string | null;
      firstSeenAt?: string | null;
      signedUpAt?: string | null;
      sessionCount?: number | null;
      customAttributes?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AccountIdentifiedUsersByEmailQueryVariables = {
  email?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelaccountIdentifiedUsersFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AccountIdentifiedUsersByEmailQuery = {
  accountIdentifiedUsersByEmail?: {
    __typename: 'ModelaccountIdentifiedUsersConnection';
    items: Array<{
      __typename: 'accountIdentifiedUsers';
      id: string;
      owner?: string | null;
      appKey: string;
      userId?: string | null;
      email?: string | null;
      phone?: string | null;
      name?: string | null;
      lastSeenAt?: string | null;
      firstSeenAt?: string | null;
      signedUpAt?: string | null;
      sessionCount?: number | null;
      customAttributes?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AccountIdentifiedUsersByUserIdQueryVariables = {
  userId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelaccountIdentifiedUsersFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AccountIdentifiedUsersByUserIdQuery = {
  accountIdentifiedUsersByUserId?: {
    __typename: 'ModelaccountIdentifiedUsersConnection';
    items: Array<{
      __typename: 'accountIdentifiedUsers';
      id: string;
      owner?: string | null;
      appKey: string;
      userId?: string | null;
      email?: string | null;
      phone?: string | null;
      name?: string | null;
      lastSeenAt?: string | null;
      firstSeenAt?: string | null;
      signedUpAt?: string | null;
      sessionCount?: number | null;
      customAttributes?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type RuleGroupsByProjectIdQueryVariables = {
  projectId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelruleGroupFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type RuleGroupsByProjectIdQuery = {
  ruleGroupsByProjectId?: {
    __typename: 'ModelruleGroupConnection';
    items: Array<{
      __typename: 'ruleGroup';
      id: string;
      appKey: string;
      projectId: string;
      publishId?: string | null;
      owner: string;
      rules: Array<{
        __typename: 'rule';
        key: string;
        type?: string | null;
        condition?: string | null;
        value?: string | null;
        limit?: string | null;
        behaviors?: Array<string | null> | null;
      } | null>;
      enabled?: boolean | null;
      visitorModeEnabled?: boolean | null;
      exitIntent?: boolean | null;
      delayTriggerEnabled?: boolean | null;
      delayTriggerSeconds?: number | null;
      showOnDevice?: string | null;
      showOnDomains?: Array<string | null> | null;
      abTestId?: string | null;
      type?: RuleGroupType | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type RuleGroupsByAppKeyQueryVariables = {
  appKey?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelruleGroupFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type RuleGroupsByAppKeyQuery = {
  ruleGroupsByAppKey?: {
    __typename: 'ModelruleGroupConnection';
    items: Array<{
      __typename: 'ruleGroup';
      id: string;
      appKey: string;
      projectId: string;
      publishId?: string | null;
      owner: string;
      rules: Array<{
        __typename: 'rule';
        key: string;
        type?: string | null;
        condition?: string | null;
        value?: string | null;
        limit?: string | null;
        behaviors?: Array<string | null> | null;
      } | null>;
      enabled?: boolean | null;
      visitorModeEnabled?: boolean | null;
      exitIntent?: boolean | null;
      delayTriggerEnabled?: boolean | null;
      delayTriggerSeconds?: number | null;
      showOnDevice?: string | null;
      showOnDomains?: Array<string | null> | null;
      abTestId?: string | null;
      type?: RuleGroupType | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type RuleGroupsByOwnerQueryVariables = {
  owner?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelruleGroupFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type RuleGroupsByOwnerQuery = {
  ruleGroupsByOwner?: {
    __typename: 'ModelruleGroupConnection';
    items: Array<{
      __typename: 'ruleGroup';
      id: string;
      appKey: string;
      projectId: string;
      publishId?: string | null;
      owner: string;
      rules: Array<{
        __typename: 'rule';
        key: string;
        type?: string | null;
        condition?: string | null;
        value?: string | null;
        limit?: string | null;
        behaviors?: Array<string | null> | null;
      } | null>;
      enabled?: boolean | null;
      visitorModeEnabled?: boolean | null;
      exitIntent?: boolean | null;
      delayTriggerEnabled?: boolean | null;
      delayTriggerSeconds?: number | null;
      showOnDevice?: string | null;
      showOnDomains?: Array<string | null> | null;
      abTestId?: string | null;
      type?: RuleGroupType | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type RuleGroupsByAbTestIdQueryVariables = {
  abTestId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelruleGroupFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type RuleGroupsByAbTestIdQuery = {
  ruleGroupsByAbTestId?: {
    __typename: 'ModelruleGroupConnection';
    items: Array<{
      __typename: 'ruleGroup';
      id: string;
      appKey: string;
      projectId: string;
      publishId?: string | null;
      owner: string;
      rules: Array<{
        __typename: 'rule';
        key: string;
        type?: string | null;
        condition?: string | null;
        value?: string | null;
        limit?: string | null;
        behaviors?: Array<string | null> | null;
      } | null>;
      enabled?: boolean | null;
      visitorModeEnabled?: boolean | null;
      exitIntent?: boolean | null;
      delayTriggerEnabled?: boolean | null;
      delayTriggerSeconds?: number | null;
      showOnDevice?: string | null;
      showOnDomains?: Array<string | null> | null;
      abTestId?: string | null;
      type?: RuleGroupType | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type IdentifyAttributesByAppKeyQueryVariables = {
  appKey?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelidentifyAttributeFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type IdentifyAttributesByAppKeyQuery = {
  identifyAttributesByAppKey?: {
    __typename: 'ModelidentifyAttributeConnection';
    items: Array<{
      __typename: 'identifyAttribute';
      id: string;
      appKey: string;
      owner: string;
      key?: string | null;
      type?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type IdentifyAttributesByOwnerQueryVariables = {
  owner?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelidentifyAttributeFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type IdentifyAttributesByOwnerQuery = {
  identifyAttributesByOwner?: {
    __typename: 'ModelidentifyAttributeConnection';
    items: Array<{
      __typename: 'identifyAttribute';
      id: string;
      appKey: string;
      owner: string;
      key?: string | null;
      type?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type InvitesByAppKeyQueryVariables = {
  appKey?: string | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelinviteFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type InvitesByAppKeyQuery = {
  invitesByAppKey?: {
    __typename: 'ModelinviteConnection';
    items: Array<{
      __typename: 'invite';
      id: string;
      owner: string;
      appKey: string;
      email?: string | null;
      role: Role;
      status: InviteStatus;
      token?: string | null;
      createdAt?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type InvitesByOwnerQueryVariables = {
  owner?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelinviteFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type InvitesByOwnerQuery = {
  invitesByOwner?: {
    __typename: 'ModelinviteConnection';
    items: Array<{
      __typename: 'invite';
      id: string;
      owner: string;
      appKey: string;
      email?: string | null;
      role: Role;
      status: InviteStatus;
      token?: string | null;
      createdAt?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type InvitesByTokenQueryVariables = {
  token?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelinviteFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type InvitesByTokenQuery = {
  invitesByToken?: {
    __typename: 'ModelinviteConnection';
    items: Array<{
      __typename: 'invite';
      id: string;
      owner: string;
      appKey: string;
      email?: string | null;
      role: Role;
      status: InviteStatus;
      token?: string | null;
      createdAt?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AccountTeamMembersByAppKeyQueryVariables = {
  appKey?: string | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelaccountTeamMemberFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AccountTeamMembersByAppKeyQuery = {
  accountTeamMembersByAppKey?: {
    __typename: 'ModelaccountTeamMemberConnection';
    items: Array<{
      __typename: 'accountTeamMember';
      id: string;
      owner: string;
      appKey: string;
      email?: string | null;
      role: Role;
      status: InviteStatus;
      inviteToken?: string | null;
      createdAt?: string | null;
      userId?: string | null;
      teamMemberId?: string | null;
      lastLoginAt?: string | null;
      user?: {
        __typename: 'ModeluserSettingsConnection';
        items: Array<{
          __typename: 'userSettings';
          id: string;
          owner?: string | null;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          lastLogin?: string | null;
          source?: string | null;
          token?: string | null;
          appKey?: string | null;
          hasLoggedIn?: boolean | null;
          hasShared?: boolean | null;
          viewedTolstoys?: Array<string | null> | null;
          calendars?: Array<{
            __typename: 'calendar';
            id?: string | null;
            name?: string | null;
            url?: string | null;
          } | null> | null;
          emailAutomationTool?: string | null;
          logoSettings?: string | null;
          googleInfo?: string | null;
          crmData?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      account?: {
        __typename: 'ModelaccountSettingsConnection';
        items: Array<{
          __typename: 'accountSettings';
          id: string;
          appKey: string;
          owner?: string | null;
          name?: string | null;
          logoSettings?: string | null;
          widgetSettings?: string | null;
          downgradeInfo?: string | null;
          liveIds?: Array<string | null> | null;
          url?: string | null;
          logoUrl?: string | null;
          playerLogoUrl?: string | null;
          companyVertical?: string | null;
          companySize?: string | null;
          companyRole?: string | null;
          monthlyTraffic?: string | null;
          useCase?: string | null;
          packageId?: string | null;
          token?: string | null;
          paymentProvider?: string | null;
          paymentSubscriptions?: Array<string | null> | null;
          paymentIdentifier?: string | null;
          packageGroup?: string | null;
          joinedPackageAt?: string | null;
          trialEndAt?: string | null;
          referral?: string | null;
          crmData?: string | null;
          trialDays?: number | null;
          googleAnalyticsID?: string | null;
          appsInstallationStatuses?: string | null;
          onboardingStatuses?: string | null;
          assetAnalysis?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      notificationSettingsId?: string | null;
      paymentRole?: PaymentRole | null;
      unreadInAppNotificationsCounter?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AccountTeamMembersByOwnerQueryVariables = {
  owner?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelaccountTeamMemberFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AccountTeamMembersByOwnerQuery = {
  accountTeamMembersByOwner?: {
    __typename: 'ModelaccountTeamMemberConnection';
    items: Array<{
      __typename: 'accountTeamMember';
      id: string;
      owner: string;
      appKey: string;
      email?: string | null;
      role: Role;
      status: InviteStatus;
      inviteToken?: string | null;
      createdAt?: string | null;
      userId?: string | null;
      teamMemberId?: string | null;
      lastLoginAt?: string | null;
      user?: {
        __typename: 'ModeluserSettingsConnection';
        items: Array<{
          __typename: 'userSettings';
          id: string;
          owner?: string | null;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          lastLogin?: string | null;
          source?: string | null;
          token?: string | null;
          appKey?: string | null;
          hasLoggedIn?: boolean | null;
          hasShared?: boolean | null;
          viewedTolstoys?: Array<string | null> | null;
          calendars?: Array<{
            __typename: 'calendar';
            id?: string | null;
            name?: string | null;
            url?: string | null;
          } | null> | null;
          emailAutomationTool?: string | null;
          logoSettings?: string | null;
          googleInfo?: string | null;
          crmData?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      account?: {
        __typename: 'ModelaccountSettingsConnection';
        items: Array<{
          __typename: 'accountSettings';
          id: string;
          appKey: string;
          owner?: string | null;
          name?: string | null;
          logoSettings?: string | null;
          widgetSettings?: string | null;
          downgradeInfo?: string | null;
          liveIds?: Array<string | null> | null;
          url?: string | null;
          logoUrl?: string | null;
          playerLogoUrl?: string | null;
          companyVertical?: string | null;
          companySize?: string | null;
          companyRole?: string | null;
          monthlyTraffic?: string | null;
          useCase?: string | null;
          packageId?: string | null;
          token?: string | null;
          paymentProvider?: string | null;
          paymentSubscriptions?: Array<string | null> | null;
          paymentIdentifier?: string | null;
          packageGroup?: string | null;
          joinedPackageAt?: string | null;
          trialEndAt?: string | null;
          referral?: string | null;
          crmData?: string | null;
          trialDays?: number | null;
          googleAnalyticsID?: string | null;
          appsInstallationStatuses?: string | null;
          onboardingStatuses?: string | null;
          assetAnalysis?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      notificationSettingsId?: string | null;
      paymentRole?: PaymentRole | null;
      unreadInAppNotificationsCounter?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AccountTeamMembersByInviteTokenQueryVariables = {
  inviteToken?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelaccountTeamMemberFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AccountTeamMembersByInviteTokenQuery = {
  accountTeamMembersByInviteToken?: {
    __typename: 'ModelaccountTeamMemberConnection';
    items: Array<{
      __typename: 'accountTeamMember';
      id: string;
      owner: string;
      appKey: string;
      email?: string | null;
      role: Role;
      status: InviteStatus;
      inviteToken?: string | null;
      createdAt?: string | null;
      userId?: string | null;
      teamMemberId?: string | null;
      lastLoginAt?: string | null;
      user?: {
        __typename: 'ModeluserSettingsConnection';
        items: Array<{
          __typename: 'userSettings';
          id: string;
          owner?: string | null;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          lastLogin?: string | null;
          source?: string | null;
          token?: string | null;
          appKey?: string | null;
          hasLoggedIn?: boolean | null;
          hasShared?: boolean | null;
          viewedTolstoys?: Array<string | null> | null;
          calendars?: Array<{
            __typename: 'calendar';
            id?: string | null;
            name?: string | null;
            url?: string | null;
          } | null> | null;
          emailAutomationTool?: string | null;
          logoSettings?: string | null;
          googleInfo?: string | null;
          crmData?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      account?: {
        __typename: 'ModelaccountSettingsConnection';
        items: Array<{
          __typename: 'accountSettings';
          id: string;
          appKey: string;
          owner?: string | null;
          name?: string | null;
          logoSettings?: string | null;
          widgetSettings?: string | null;
          downgradeInfo?: string | null;
          liveIds?: Array<string | null> | null;
          url?: string | null;
          logoUrl?: string | null;
          playerLogoUrl?: string | null;
          companyVertical?: string | null;
          companySize?: string | null;
          companyRole?: string | null;
          monthlyTraffic?: string | null;
          useCase?: string | null;
          packageId?: string | null;
          token?: string | null;
          paymentProvider?: string | null;
          paymentSubscriptions?: Array<string | null> | null;
          paymentIdentifier?: string | null;
          packageGroup?: string | null;
          joinedPackageAt?: string | null;
          trialEndAt?: string | null;
          referral?: string | null;
          crmData?: string | null;
          trialDays?: number | null;
          googleAnalyticsID?: string | null;
          appsInstallationStatuses?: string | null;
          onboardingStatuses?: string | null;
          assetAnalysis?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      notificationSettingsId?: string | null;
      paymentRole?: PaymentRole | null;
      unreadInAppNotificationsCounter?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AccountTeamMembersByUserIdQueryVariables = {
  userId?: string | null;
  lastLoginAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelaccountTeamMemberFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AccountTeamMembersByUserIdQuery = {
  accountTeamMembersByUserId?: {
    __typename: 'ModelaccountTeamMemberConnection';
    items: Array<{
      __typename: 'accountTeamMember';
      id: string;
      owner: string;
      appKey: string;
      email?: string | null;
      role: Role;
      status: InviteStatus;
      inviteToken?: string | null;
      createdAt?: string | null;
      userId?: string | null;
      teamMemberId?: string | null;
      lastLoginAt?: string | null;
      user?: {
        __typename: 'ModeluserSettingsConnection';
        items: Array<{
          __typename: 'userSettings';
          id: string;
          owner?: string | null;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          lastLogin?: string | null;
          source?: string | null;
          token?: string | null;
          appKey?: string | null;
          hasLoggedIn?: boolean | null;
          hasShared?: boolean | null;
          viewedTolstoys?: Array<string | null> | null;
          calendars?: Array<{
            __typename: 'calendar';
            id?: string | null;
            name?: string | null;
            url?: string | null;
          } | null> | null;
          emailAutomationTool?: string | null;
          logoSettings?: string | null;
          googleInfo?: string | null;
          crmData?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      account?: {
        __typename: 'ModelaccountSettingsConnection';
        items: Array<{
          __typename: 'accountSettings';
          id: string;
          appKey: string;
          owner?: string | null;
          name?: string | null;
          logoSettings?: string | null;
          widgetSettings?: string | null;
          downgradeInfo?: string | null;
          liveIds?: Array<string | null> | null;
          url?: string | null;
          logoUrl?: string | null;
          playerLogoUrl?: string | null;
          companyVertical?: string | null;
          companySize?: string | null;
          companyRole?: string | null;
          monthlyTraffic?: string | null;
          useCase?: string | null;
          packageId?: string | null;
          token?: string | null;
          paymentProvider?: string | null;
          paymentSubscriptions?: Array<string | null> | null;
          paymentIdentifier?: string | null;
          packageGroup?: string | null;
          joinedPackageAt?: string | null;
          trialEndAt?: string | null;
          referral?: string | null;
          crmData?: string | null;
          trialDays?: number | null;
          googleAnalyticsID?: string | null;
          appsInstallationStatuses?: string | null;
          onboardingStatuses?: string | null;
          assetAnalysis?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      notificationSettingsId?: string | null;
      paymentRole?: PaymentRole | null;
      unreadInAppNotificationsCounter?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AccountTeamMembersByTeamMemberIdQueryVariables = {
  teamMemberId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelaccountTeamMemberFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AccountTeamMembersByTeamMemberIdQuery = {
  accountTeamMembersByTeamMemberId?: {
    __typename: 'ModelaccountTeamMemberConnection';
    items: Array<{
      __typename: 'accountTeamMember';
      id: string;
      owner: string;
      appKey: string;
      email?: string | null;
      role: Role;
      status: InviteStatus;
      inviteToken?: string | null;
      createdAt?: string | null;
      userId?: string | null;
      teamMemberId?: string | null;
      lastLoginAt?: string | null;
      user?: {
        __typename: 'ModeluserSettingsConnection';
        items: Array<{
          __typename: 'userSettings';
          id: string;
          owner?: string | null;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          lastLogin?: string | null;
          source?: string | null;
          token?: string | null;
          appKey?: string | null;
          hasLoggedIn?: boolean | null;
          hasShared?: boolean | null;
          viewedTolstoys?: Array<string | null> | null;
          calendars?: Array<{
            __typename: 'calendar';
            id?: string | null;
            name?: string | null;
            url?: string | null;
          } | null> | null;
          emailAutomationTool?: string | null;
          logoSettings?: string | null;
          googleInfo?: string | null;
          crmData?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      account?: {
        __typename: 'ModelaccountSettingsConnection';
        items: Array<{
          __typename: 'accountSettings';
          id: string;
          appKey: string;
          owner?: string | null;
          name?: string | null;
          logoSettings?: string | null;
          widgetSettings?: string | null;
          downgradeInfo?: string | null;
          liveIds?: Array<string | null> | null;
          url?: string | null;
          logoUrl?: string | null;
          playerLogoUrl?: string | null;
          companyVertical?: string | null;
          companySize?: string | null;
          companyRole?: string | null;
          monthlyTraffic?: string | null;
          useCase?: string | null;
          packageId?: string | null;
          token?: string | null;
          paymentProvider?: string | null;
          paymentSubscriptions?: Array<string | null> | null;
          paymentIdentifier?: string | null;
          packageGroup?: string | null;
          joinedPackageAt?: string | null;
          trialEndAt?: string | null;
          referral?: string | null;
          crmData?: string | null;
          trialDays?: number | null;
          googleAnalyticsID?: string | null;
          appsInstallationStatuses?: string | null;
          onboardingStatuses?: string | null;
          assetAnalysis?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      notificationSettingsId?: string | null;
      paymentRole?: PaymentRole | null;
      unreadInAppNotificationsCounter?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AccountTeamMembersByEmailQueryVariables = {
  email?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelaccountTeamMemberFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AccountTeamMembersByEmailQuery = {
  accountTeamMembersByEmail?: {
    __typename: 'ModelaccountTeamMemberConnection';
    items: Array<{
      __typename: 'accountTeamMember';
      id: string;
      owner: string;
      appKey: string;
      email?: string | null;
      role: Role;
      status: InviteStatus;
      inviteToken?: string | null;
      createdAt?: string | null;
      userId?: string | null;
      teamMemberId?: string | null;
      lastLoginAt?: string | null;
      user?: {
        __typename: 'ModeluserSettingsConnection';
        items: Array<{
          __typename: 'userSettings';
          id: string;
          owner?: string | null;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          lastLogin?: string | null;
          source?: string | null;
          token?: string | null;
          appKey?: string | null;
          hasLoggedIn?: boolean | null;
          hasShared?: boolean | null;
          viewedTolstoys?: Array<string | null> | null;
          calendars?: Array<{
            __typename: 'calendar';
            id?: string | null;
            name?: string | null;
            url?: string | null;
          } | null> | null;
          emailAutomationTool?: string | null;
          logoSettings?: string | null;
          googleInfo?: string | null;
          crmData?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      account?: {
        __typename: 'ModelaccountSettingsConnection';
        items: Array<{
          __typename: 'accountSettings';
          id: string;
          appKey: string;
          owner?: string | null;
          name?: string | null;
          logoSettings?: string | null;
          widgetSettings?: string | null;
          downgradeInfo?: string | null;
          liveIds?: Array<string | null> | null;
          url?: string | null;
          logoUrl?: string | null;
          playerLogoUrl?: string | null;
          companyVertical?: string | null;
          companySize?: string | null;
          companyRole?: string | null;
          monthlyTraffic?: string | null;
          useCase?: string | null;
          packageId?: string | null;
          token?: string | null;
          paymentProvider?: string | null;
          paymentSubscriptions?: Array<string | null> | null;
          paymentIdentifier?: string | null;
          packageGroup?: string | null;
          joinedPackageAt?: string | null;
          trialEndAt?: string | null;
          referral?: string | null;
          crmData?: string | null;
          trialDays?: number | null;
          googleAnalyticsID?: string | null;
          appsInstallationStatuses?: string | null;
          onboardingStatuses?: string | null;
          assetAnalysis?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      notificationSettingsId?: string | null;
      paymentRole?: PaymentRole | null;
      unreadInAppNotificationsCounter?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type TeamMemberSettingsByTeamMemberIdQueryVariables = {
  teamMemberId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelteamMemberSettingsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type TeamMemberSettingsByTeamMemberIdQuery = {
  teamMemberSettingsByTeamMemberId?: {
    __typename: 'ModelteamMemberSettingsConnection';
    items: Array<{
      __typename: 'teamMemberSettings';
      id: string;
      teamMemberId?: string | null;
      notificationSettingsId?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type NotificationSettingsByOwnerQueryVariables = {
  owner?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelnotificationSettingsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type NotificationSettingsByOwnerQuery = {
  notificationSettingsByOwner?: {
    __typename: 'ModelnotificationSettingsConnection';
    items: Array<{
      __typename: 'notificationSettings';
      id: string;
      owner: string;
      projectId?: string | null;
      email?: {
        __typename: 'sharedNotificationSettings';
        showAnonymous?: boolean | null;
        enabled?: Array<string | null> | null;
        digest?: Array<string | null> | null;
      } | null;
      inApp?: {
        __typename: 'sharedNotificationSettings';
        showAnonymous?: boolean | null;
        enabled?: Array<string | null> | null;
        digest?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type FoldersByOwnerQueryVariables = {
  owner?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelfolderFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type FoldersByOwnerQuery = {
  foldersByOwner?: {
    __typename: 'ModelfolderConnection';
    items: Array<{
      __typename: 'folder';
      id: string;
      owner?: string | null;
      name: string;
      appKey: string;
      private?: boolean | null;
      type?: FolderType | null;
      appUrl?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type FoldersByAppKeyQueryVariables = {
  appKey?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelfolderFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type FoldersByAppKeyQuery = {
  foldersByAppKey?: {
    __typename: 'ModelfolderConnection';
    items: Array<{
      __typename: 'folder';
      id: string;
      owner?: string | null;
      name: string;
      appKey: string;
      private?: boolean | null;
      type?: FolderType | null;
      appUrl?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AccountUsersByAppKeyQueryVariables = {
  appKey?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelaccountUserFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AccountUsersByAppKeyQuery = {
  accountUsersByAppKey?: {
    __typename: 'ModelaccountUserConnection';
    items: Array<{
      __typename: 'accountUser';
      id: string;
      appKey: string;
      email?: string | null;
      name?: string | null;
      phone?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      lastSeenAt?: string | null;
      emailVerified?: boolean | null;
      sessionCount?: number | null;
      customAttributes?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AccountUsersByEmailQueryVariables = {
  email?: string | null;
  lastSeenAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelaccountUserFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AccountUsersByEmailQuery = {
  accountUsersByEmail?: {
    __typename: 'ModelaccountUserConnection';
    items: Array<{
      __typename: 'accountUser';
      id: string;
      appKey: string;
      email?: string | null;
      name?: string | null;
      phone?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      lastSeenAt?: string | null;
      emailVerified?: boolean | null;
      sessionCount?: number | null;
      customAttributes?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AccountUsageByAppKeyQueryVariables = {
  appKey?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelaccountUsageFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AccountUsageByAppKeyQuery = {
  accountUsageByAppKey?: {
    __typename: 'ModelaccountUsageConnection';
    items: Array<{
      __typename: 'accountUsage';
      id: string;
      appKey: string;
      featureId: string;
      usage?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type PlayerConfigsByAppKeyQueryVariables = {
  appKey?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelplayerConfigFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type PlayerConfigsByAppKeyQuery = {
  playerConfigsByAppKey?: {
    __typename: 'ModelplayerConfigConnection';
    items: Array<{
      __typename: 'playerConfig';
      id: string;
      appKey: string;
      publishId: string;
      projectId: string;
      config?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type PlayerConfigsByPublishIdQueryVariables = {
  publishId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelplayerConfigFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type PlayerConfigsByPublishIdQuery = {
  playerConfigsByPublishId?: {
    __typename: 'ModelplayerConfigConnection';
    items: Array<{
      __typename: 'playerConfig';
      id: string;
      appKey: string;
      publishId: string;
      projectId: string;
      config?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type PlayerConfigsByProjectIdQueryVariables = {
  projectId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelplayerConfigFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type PlayerConfigsByProjectIdQuery = {
  playerConfigsByProjectId?: {
    __typename: 'ModelplayerConfigConnection';
    items: Array<{
      __typename: 'playerConfig';
      id: string;
      appKey: string;
      publishId: string;
      projectId: string;
      config?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type WidgetConfigsByAppKeyQueryVariables = {
  appKey?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelwidgetConfigFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type WidgetConfigsByAppKeyQuery = {
  widgetConfigsByAppKey?: {
    __typename: 'ModelwidgetConfigConnection';
    items: Array<{
      __typename: 'widgetConfig';
      id: string;
      appKey: string;
      publishId: string;
      projectId: string;
      config?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type WidgetConfigsByPublishIdQueryVariables = {
  publishId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelwidgetConfigFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type WidgetConfigsByPublishIdQuery = {
  widgetConfigsByPublishId?: {
    __typename: 'ModelwidgetConfigConnection';
    items: Array<{
      __typename: 'widgetConfig';
      id: string;
      appKey: string;
      publishId: string;
      projectId: string;
      config?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type WidgetConfigsByProjectIdQueryVariables = {
  projectId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelwidgetConfigFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type WidgetConfigsByProjectIdQuery = {
  widgetConfigsByProjectId?: {
    __typename: 'ModelwidgetConfigConnection';
    items: Array<{
      __typename: 'widgetConfig';
      id: string;
      appKey: string;
      publishId: string;
      projectId: string;
      config?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AccountPackagesByAppKeyQueryVariables = {
  appKey?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelaccountPackageFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AccountPackagesByAppKeyQuery = {
  accountPackagesByAppKey?: {
    __typename: 'ModelaccountPackageConnection';
    items: Array<{
      __typename: 'accountPackage';
      id: string;
      appKey: string;
      packageId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      app?: TolstoyApp | null;
      featuresPackageId?: string | null;
      group?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type PackagesByTypeQueryVariables = {
  type?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelpackageFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type PackagesByTypeQuery = {
  packagesByType?: {
    __typename: 'ModelpackageConnection';
    items: Array<{
      __typename: 'package';
      id: string;
      name?: string | null;
      description?: string | null;
      stripeProducts?: Array<{
        __typename: 'stripeProduct';
        type?: string | null;
        price?: string | null;
      } | null> | null;
      trialPeriodDays?: number | null;
      maxTrialPeriodDays?: number | null;
      type?: string | null;
      details?: string | null;
      group?: string | null;
      order?: number | null;
      featuresPackageId?: string | null;
      addons?: Array<string | null> | null;
      app?: TolstoyApp | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type PackagesByGroupQueryVariables = {
  group?: string | null;
  order?: ModelIntKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelpackageFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type PackagesByGroupQuery = {
  packagesByGroup?: {
    __typename: 'ModelpackageConnection';
    items: Array<{
      __typename: 'package';
      id: string;
      name?: string | null;
      description?: string | null;
      stripeProducts?: Array<{
        __typename: 'stripeProduct';
        type?: string | null;
        price?: string | null;
      } | null> | null;
      trialPeriodDays?: number | null;
      maxTrialPeriodDays?: number | null;
      type?: string | null;
      details?: string | null;
      group?: string | null;
      order?: number | null;
      featuresPackageId?: string | null;
      addons?: Array<string | null> | null;
      app?: TolstoyApp | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type PaymentsBySubscriptionIdQueryVariables = {
  subscriptionId?: string | null;
  reportAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelpaymentFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type PaymentsBySubscriptionIdQuery = {
  paymentsBySubscriptionId?: {
    __typename: 'ModelpaymentConnection';
    items: Array<{
      __typename: 'payment';
      id: string;
      subscriptionId?: string | null;
      appKey?: string | null;
      reportAt?: string | null;
      usagePrice?: number | null;
      usageQuantity?: number | null;
      type?: string | null;
      provider?: string | null;
      identifier?: string | null;
      packageId?: string | null;
      packageType?: string | null;
      dailyUsagePrice?: number | null;
      aggregatedPrice?: number | null;
      aggregatedUsageQuantity?: number | null;
      dailyUsageQuantity?: number | null;
      seatsPrice?: number | null;
      price?: number | null;
      interval?: string | null;
      numberOfProTeamMembers?: number | null;
      invoiceId?: string | null;
      invoiceItemId?: string | null;
      paymentSubscriptionId?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type PaymentsByProviderQueryVariables = {
  provider?: string | null;
  reportAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelpaymentFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type PaymentsByProviderQuery = {
  paymentsByProvider?: {
    __typename: 'ModelpaymentConnection';
    items: Array<{
      __typename: 'payment';
      id: string;
      subscriptionId?: string | null;
      appKey?: string | null;
      reportAt?: string | null;
      usagePrice?: number | null;
      usageQuantity?: number | null;
      type?: string | null;
      provider?: string | null;
      identifier?: string | null;
      packageId?: string | null;
      packageType?: string | null;
      dailyUsagePrice?: number | null;
      aggregatedPrice?: number | null;
      aggregatedUsageQuantity?: number | null;
      dailyUsageQuantity?: number | null;
      seatsPrice?: number | null;
      price?: number | null;
      interval?: string | null;
      numberOfProTeamMembers?: number | null;
      invoiceId?: string | null;
      invoiceItemId?: string | null;
      paymentSubscriptionId?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type PaymentsByInvoiceItemIdQueryVariables = {
  invoiceItemId?: string | null;
  reportAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelpaymentFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type PaymentsByInvoiceItemIdQuery = {
  paymentsByInvoiceItemId?: {
    __typename: 'ModelpaymentConnection';
    items: Array<{
      __typename: 'payment';
      id: string;
      subscriptionId?: string | null;
      appKey?: string | null;
      reportAt?: string | null;
      usagePrice?: number | null;
      usageQuantity?: number | null;
      type?: string | null;
      provider?: string | null;
      identifier?: string | null;
      packageId?: string | null;
      packageType?: string | null;
      dailyUsagePrice?: number | null;
      aggregatedPrice?: number | null;
      aggregatedUsageQuantity?: number | null;
      dailyUsageQuantity?: number | null;
      seatsPrice?: number | null;
      price?: number | null;
      interval?: string | null;
      numberOfProTeamMembers?: number | null;
      invoiceId?: string | null;
      invoiceItemId?: string | null;
      paymentSubscriptionId?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type PaymentsByInvoiceIdQueryVariables = {
  invoiceId?: string | null;
  reportAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelpaymentFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type PaymentsByInvoiceIdQuery = {
  paymentsByInvoiceId?: {
    __typename: 'ModelpaymentConnection';
    items: Array<{
      __typename: 'payment';
      id: string;
      subscriptionId?: string | null;
      appKey?: string | null;
      reportAt?: string | null;
      usagePrice?: number | null;
      usageQuantity?: number | null;
      type?: string | null;
      provider?: string | null;
      identifier?: string | null;
      packageId?: string | null;
      packageType?: string | null;
      dailyUsagePrice?: number | null;
      aggregatedPrice?: number | null;
      aggregatedUsageQuantity?: number | null;
      dailyUsageQuantity?: number | null;
      seatsPrice?: number | null;
      price?: number | null;
      interval?: string | null;
      numberOfProTeamMembers?: number | null;
      invoiceId?: string | null;
      invoiceItemId?: string | null;
      paymentSubscriptionId?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type PaymentsByAppKeyQueryVariables = {
  appKey?: string | null;
  reportAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelpaymentFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type PaymentsByAppKeyQuery = {
  paymentsByAppKey?: {
    __typename: 'ModelpaymentConnection';
    items: Array<{
      __typename: 'payment';
      id: string;
      subscriptionId?: string | null;
      appKey?: string | null;
      reportAt?: string | null;
      usagePrice?: number | null;
      usageQuantity?: number | null;
      type?: string | null;
      provider?: string | null;
      identifier?: string | null;
      packageId?: string | null;
      packageType?: string | null;
      dailyUsagePrice?: number | null;
      aggregatedPrice?: number | null;
      aggregatedUsageQuantity?: number | null;
      dailyUsageQuantity?: number | null;
      seatsPrice?: number | null;
      price?: number | null;
      interval?: string | null;
      numberOfProTeamMembers?: number | null;
      invoiceId?: string | null;
      invoiceItemId?: string | null;
      paymentSubscriptionId?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type QuizResultsByProjectIdQueryVariables = {
  projectId?: string | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelquizResultFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type QuizResultsByProjectIdQuery = {
  quizResultsByProjectId?: {
    __typename: 'ModelquizResultConnection';
    items: Array<{
      __typename: 'quizResult';
      id: string;
      projectId: string;
      appKey?: string | null;
      owner?: string | null;
      createdAt?: string | null;
      url?: string | null;
      title?: string | null;
      imageUrl?: string | null;
      flows?: Array<{
        __typename: 'quizFlow';
        id: string;
        type?: string | null;
        rules?: Array<{
          __typename: 'quizRule';
          stepId: string;
          choices?: Array<string | null> | null;
        } | null> | null;
      } | null> | null;
      isFallback?: boolean | null;
      klaviyoListId?: string | null;
      stepName?: string | null;
      type?: ResultType | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type QuizResultsByOwnerQueryVariables = {
  owner?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelquizResultFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type QuizResultsByOwnerQuery = {
  quizResultsByOwner?: {
    __typename: 'ModelquizResultConnection';
    items: Array<{
      __typename: 'quizResult';
      id: string;
      projectId: string;
      appKey?: string | null;
      owner?: string | null;
      createdAt?: string | null;
      url?: string | null;
      title?: string | null;
      imageUrl?: string | null;
      flows?: Array<{
        __typename: 'quizFlow';
        id: string;
        type?: string | null;
        rules?: Array<{
          __typename: 'quizRule';
          stepId: string;
          choices?: Array<string | null> | null;
        } | null> | null;
      } | null> | null;
      isFallback?: boolean | null;
      klaviyoListId?: string | null;
      stepName?: string | null;
      type?: ResultType | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type QuizResultsByAppKeyQueryVariables = {
  appKey?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelquizResultFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type QuizResultsByAppKeyQuery = {
  quizResultsByAppKey?: {
    __typename: 'ModelquizResultConnection';
    items: Array<{
      __typename: 'quizResult';
      id: string;
      projectId: string;
      appKey?: string | null;
      owner?: string | null;
      createdAt?: string | null;
      url?: string | null;
      title?: string | null;
      imageUrl?: string | null;
      flows?: Array<{
        __typename: 'quizFlow';
        id: string;
        type?: string | null;
        rules?: Array<{
          __typename: 'quizRule';
          stepId: string;
          choices?: Array<string | null> | null;
        } | null> | null;
      } | null> | null;
      isFallback?: boolean | null;
      klaviyoListId?: string | null;
      stepName?: string | null;
      type?: ResultType | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type EcomProductByProductIdQueryVariables = {
  productId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelecomProductFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type EcomProductByProductIdQuery = {
  ecomProductByProductId?: {
    __typename: 'ModelecomProductConnection';
    items: Array<{
      __typename: 'ecomProduct';
      id: string;
      productId: string;
      appKey?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type EcomProductByProductIdSortByAppKeyQueryVariables = {
  productId?: string | null;
  appKey?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelecomProductFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type EcomProductByProductIdSortByAppKeyQuery = {
  ecomProductByProductIdSortByAppKey?: {
    __typename: 'ModelecomProductConnection';
    items: Array<{
      __typename: 'ecomProduct';
      id: string;
      productId: string;
      appKey?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type EcomProductByAppKeyQueryVariables = {
  appKey?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelecomProductFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type EcomProductByAppKeyQuery = {
  ecomProductByAppKey?: {
    __typename: 'ModelecomProductConnection';
    items: Array<{
      __typename: 'ecomProduct';
      id: string;
      productId: string;
      appKey?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ProductPageSettingsByAppKeyQueryVariables = {
  appKey?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelproductPageSettingsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ProductPageSettingsByAppKeyQuery = {
  productPageSettingsByAppKey?: {
    __typename: 'ModelproductPageSettingsConnection';
    items: Array<{
      __typename: 'productPageSettings';
      id: string;
      productId?: string | null;
      productUrl?: string | null;
      abTestId?: string | null;
      publishId?: string | null;
      appKey?: string | null;
      widgetType?: WidgetType | null;
      productSource?: ProductSource | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ProductPageSettingsByProductIdQueryVariables = {
  productId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelproductPageSettingsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ProductPageSettingsByProductIdQuery = {
  productPageSettingsByProductId?: {
    __typename: 'ModelproductPageSettingsConnection';
    items: Array<{
      __typename: 'productPageSettings';
      id: string;
      productId?: string | null;
      productUrl?: string | null;
      abTestId?: string | null;
      publishId?: string | null;
      appKey?: string | null;
      widgetType?: WidgetType | null;
      productSource?: ProductSource | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ProductPageSettingsByProductUrlQueryVariables = {
  productUrl?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelproductPageSettingsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ProductPageSettingsByProductUrlQuery = {
  productPageSettingsByProductUrl?: {
    __typename: 'ModelproductPageSettingsConnection';
    items: Array<{
      __typename: 'productPageSettings';
      id: string;
      productId?: string | null;
      productUrl?: string | null;
      abTestId?: string | null;
      publishId?: string | null;
      appKey?: string | null;
      widgetType?: WidgetType | null;
      productSource?: ProductSource | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ProductPageSettingsByAbTestIdQueryVariables = {
  abTestId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelproductPageSettingsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ProductPageSettingsByAbTestIdQuery = {
  productPageSettingsByAbTestId?: {
    __typename: 'ModelproductPageSettingsConnection';
    items: Array<{
      __typename: 'productPageSettings';
      id: string;
      productId?: string | null;
      productUrl?: string | null;
      abTestId?: string | null;
      publishId?: string | null;
      appKey?: string | null;
      widgetType?: WidgetType | null;
      productSource?: ProductSource | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type AbTestByAppKeyQueryVariables = {
  appKey?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelabTestFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type AbTestByAppKeyQuery = {
  abTestByAppKey?: {
    __typename: 'ModelabTestConnection';
    items: Array<{
      __typename: 'abTest';
      id: string;
      appKey?: string | null;
      owner?: string | null;
      options?: Array<{
        __typename: 'abTestGroup';
        publishId?: string | null;
        probability?: number | null;
      } | null> | null;
      endAt?: string | null;
      startAt?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type IntegrationVodAssetByAppKeyQueryVariables = {
  appKey?: string | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelintegrationVodAssetFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type IntegrationVodAssetByAppKeyQuery = {
  integrationVodAssetByAppKey?: {
    __typename: 'ModelintegrationVodAssetConnection';
    items: Array<{
      __typename: 'integrationVodAsset';
      id: string;
      appKey?: string | null;
      owner?: string | null;
      app?: string | null;
      appId?: string | null;
      externalId?: string | null;
      externalCreatedAt?: string | null;
      vodId?: string | null;
      seen?: boolean | null;
      title?: string | null;
      thumbnailUrl?: string | null;
      downloadUrl?: string | null;
      status?: StatusTypes | null;
      createdAt?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type IntegrationVodAssetByExternalIdQueryVariables = {
  externalId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelintegrationVodAssetFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type IntegrationVodAssetByExternalIdQuery = {
  integrationVodAssetByExternalId?: {
    __typename: 'ModelintegrationVodAssetConnection';
    items: Array<{
      __typename: 'integrationVodAsset';
      id: string;
      appKey?: string | null;
      owner?: string | null;
      app?: string | null;
      appId?: string | null;
      externalId?: string | null;
      externalCreatedAt?: string | null;
      vodId?: string | null;
      seen?: boolean | null;
      title?: string | null;
      thumbnailUrl?: string | null;
      downloadUrl?: string | null;
      status?: StatusTypes | null;
      createdAt?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type IntegrationVodAssetByAppIdQueryVariables = {
  appId?: string | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelintegrationVodAssetFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type IntegrationVodAssetByAppIdQuery = {
  integrationVodAssetByAppId?: {
    __typename: 'ModelintegrationVodAssetConnection';
    items: Array<{
      __typename: 'integrationVodAsset';
      id: string;
      appKey?: string | null;
      owner?: string | null;
      app?: string | null;
      appId?: string | null;
      externalId?: string | null;
      externalCreatedAt?: string | null;
      vodId?: string | null;
      seen?: boolean | null;
      title?: string | null;
      thumbnailUrl?: string | null;
      downloadUrl?: string | null;
      status?: StatusTypes | null;
      createdAt?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type VodConnectionByAppKeyQueryVariables = {
  appKey?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelvodConnectionFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type VodConnectionByAppKeyQuery = {
  vodConnectionByAppKey?: {
    __typename: 'ModelvodConnectionConnection';
    items: Array<{
      __typename: 'vodConnection';
      id: string;
      appKey?: string | null;
      vodAssetId?: string | null;
      productId?: string | null;
      externalProductId?: string | null;
      collectionId?: string | null;
      provider?: VodConnectionProvider | null;
      tag?: string | null;
      createdAt?: string | null;
      typeKey?: string | null;
      type?: VodConnectionType | null;
      appUrl?: string | null;
      label?: string | null;
      vodLabelId?: string | null;
      vodSource?: string | null;
      vodType?: string | null;
      variantIds?: Array<string | null> | null;
      projectId?: string | null;
      orderIndex?: number | null;
      source?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type VodConnectionByAppKeySortByTypeQueryVariables = {
  appKey?: string | null;
  type?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelvodConnectionFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type VodConnectionByAppKeySortByTypeQuery = {
  vodConnectionByAppKeySortByType?: {
    __typename: 'ModelvodConnectionConnection';
    items: Array<{
      __typename: 'vodConnection';
      id: string;
      appKey?: string | null;
      vodAssetId?: string | null;
      productId?: string | null;
      externalProductId?: string | null;
      collectionId?: string | null;
      provider?: VodConnectionProvider | null;
      tag?: string | null;
      createdAt?: string | null;
      typeKey?: string | null;
      type?: VodConnectionType | null;
      appUrl?: string | null;
      label?: string | null;
      vodLabelId?: string | null;
      vodSource?: string | null;
      vodType?: string | null;
      variantIds?: Array<string | null> | null;
      projectId?: string | null;
      orderIndex?: number | null;
      source?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type VodConnectionByTypeKeyQueryVariables = {
  typeKey?: string | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelvodConnectionFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type VodConnectionByTypeKeyQuery = {
  vodConnectionByTypeKey?: {
    __typename: 'ModelvodConnectionConnection';
    items: Array<{
      __typename: 'vodConnection';
      id: string;
      appKey?: string | null;
      vodAssetId?: string | null;
      productId?: string | null;
      externalProductId?: string | null;
      collectionId?: string | null;
      provider?: VodConnectionProvider | null;
      tag?: string | null;
      createdAt?: string | null;
      typeKey?: string | null;
      type?: VodConnectionType | null;
      appUrl?: string | null;
      label?: string | null;
      vodLabelId?: string | null;
      vodSource?: string | null;
      vodType?: string | null;
      variantIds?: Array<string | null> | null;
      projectId?: string | null;
      orderIndex?: number | null;
      source?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type VodConnectionByVodAssetIdQueryVariables = {
  vodAssetId?: string | null;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelvodConnectionFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type VodConnectionByVodAssetIdQuery = {
  vodConnectionByVodAssetId?: {
    __typename: 'ModelvodConnectionConnection';
    items: Array<{
      __typename: 'vodConnection';
      id: string;
      appKey?: string | null;
      vodAssetId?: string | null;
      productId?: string | null;
      externalProductId?: string | null;
      collectionId?: string | null;
      provider?: VodConnectionProvider | null;
      tag?: string | null;
      createdAt?: string | null;
      typeKey?: string | null;
      type?: VodConnectionType | null;
      appUrl?: string | null;
      label?: string | null;
      vodLabelId?: string | null;
      vodSource?: string | null;
      vodType?: string | null;
      variantIds?: Array<string | null> | null;
      projectId?: string | null;
      orderIndex?: number | null;
      source?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CustomersByAppKeyQueryVariables = {
  appKey?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelcustomerFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type CustomersByAppKeyQuery = {
  customersByAppKey?: {
    __typename: 'ModelcustomerConnection';
    items: Array<{
      __typename: 'customer';
      id: string;
      appKey: string;
      appUrl: string;
      externalId?: string | null;
      googleId?: string | null;
      anonymousIds?: Array<string | null> | null;
      email: string;
      phone?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      acceptsMarketing?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CustomersByExternalIdQueryVariables = {
  externalId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelcustomerFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type CustomersByExternalIdQuery = {
  customersByExternalId?: {
    __typename: 'ModelcustomerConnection';
    items: Array<{
      __typename: 'customer';
      id: string;
      appKey: string;
      appUrl: string;
      externalId?: string | null;
      googleId?: string | null;
      anonymousIds?: Array<string | null> | null;
      email: string;
      phone?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      acceptsMarketing?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CustomersByEmailQueryVariables = {
  email?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelcustomerFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type CustomersByEmailQuery = {
  customersByEmail?: {
    __typename: 'ModelcustomerConnection';
    items: Array<{
      __typename: 'customer';
      id: string;
      appKey: string;
      appUrl: string;
      externalId?: string | null;
      googleId?: string | null;
      anonymousIds?: Array<string | null> | null;
      email: string;
      phone?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      acceptsMarketing?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type VodLabelByAppKeyQueryVariables = {
  appKey?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelvodLabelFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type VodLabelByAppKeyQuery = {
  vodLabelByAppKey?: {
    __typename: 'ModelvodLabelConnection';
    items: Array<{
      __typename: 'vodLabel';
      id: string;
      appKey: string;
      name: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SuggestedProductAcceptanceByAppKeyQueryVariables = {
  appKey?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelsuggestedProductAcceptanceFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SuggestedProductAcceptanceByAppKeyQuery = {
  suggestedProductAcceptanceByAppKey?: {
    __typename: 'ModelsuggestedProductAcceptanceConnection';
    items: Array<{
      __typename: 'suggestedProductAcceptance';
      id: string;
      appKey: string;
      vodAssetId: string;
      externalProductId: string;
      accepted: boolean;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SuggestedProductAcceptanceByVodAssetIdQueryVariables = {
  vodAssetId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelsuggestedProductAcceptanceFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SuggestedProductAcceptanceByVodAssetIdQuery = {
  suggestedProductAcceptanceByVodAssetId?: {
    __typename: 'ModelsuggestedProductAcceptanceConnection';
    items: Array<{
      __typename: 'suggestedProductAcceptance';
      id: string;
      appKey: string;
      vodAssetId: string;
      externalProductId: string;
      accepted: boolean;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SuggestedProductAcceptanceByExternalProductIdQueryVariables = {
  externalProductId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelsuggestedProductAcceptanceFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SuggestedProductAcceptanceByExternalProductIdQuery = {
  suggestedProductAcceptanceByExternalProductId?: {
    __typename: 'ModelsuggestedProductAcceptanceConnection';
    items: Array<{
      __typename: 'suggestedProductAcceptance';
      id: string;
      appKey: string;
      vodAssetId: string;
      externalProductId: string;
      accepted: boolean;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CustomerWishListByAppKeyQueryVariables = {
  appKey?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelcustomerWishListFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type CustomerWishListByAppKeyQuery = {
  customerWishListByAppKey?: {
    __typename: 'ModelcustomerWishListConnection';
    items: Array<{
      __typename: 'customerWishList';
      id: string;
      appKey: string;
      appUrl: string;
      vodAssetId: string;
      externalCustomerId?: string | null;
      email?: string | null;
      externalProductId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CustomerWishListByAppUrlQueryVariables = {
  appUrl?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelcustomerWishListFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type CustomerWishListByAppUrlQuery = {
  customerWishListByAppUrl?: {
    __typename: 'ModelcustomerWishListConnection';
    items: Array<{
      __typename: 'customerWishList';
      id: string;
      appKey: string;
      appUrl: string;
      vodAssetId: string;
      externalCustomerId?: string | null;
      email?: string | null;
      externalProductId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CustomerWishListByVodAssetIdQueryVariables = {
  vodAssetId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelcustomerWishListFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type CustomerWishListByVodAssetIdQuery = {
  customerWishListByVodAssetId?: {
    __typename: 'ModelcustomerWishListConnection';
    items: Array<{
      __typename: 'customerWishList';
      id: string;
      appKey: string;
      appUrl: string;
      vodAssetId: string;
      externalCustomerId?: string | null;
      email?: string | null;
      externalProductId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CustomerWishListByEmailQueryVariables = {
  email?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelcustomerWishListFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type CustomerWishListByEmailQuery = {
  customerWishListByEmail?: {
    __typename: 'ModelcustomerWishListConnection';
    items: Array<{
      __typename: 'customerWishList';
      id: string;
      appKey: string;
      appUrl: string;
      vodAssetId: string;
      externalCustomerId?: string | null;
      email?: string | null;
      externalProductId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CustomerWishListByExternalCustomerIdQueryVariables = {
  externalCustomerId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelcustomerWishListFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type CustomerWishListByExternalCustomerIdQuery = {
  customerWishListByExternalCustomerId?: {
    __typename: 'ModelcustomerWishListConnection';
    items: Array<{
      __typename: 'customerWishList';
      id: string;
      appKey: string;
      appUrl: string;
      vodAssetId: string;
      externalCustomerId?: string | null;
      email?: string | null;
      externalProductId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CustomerWishListByExternalCustomerIdSortByAppUrlQueryVariables = {
  externalCustomerId?: string | null;
  appUrl?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelcustomerWishListFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type CustomerWishListByExternalCustomerIdSortByAppUrlQuery = {
  customerWishListByExternalCustomerIdSortByAppUrl?: {
    __typename: 'ModelcustomerWishListConnection';
    items: Array<{
      __typename: 'customerWishList';
      id: string;
      appKey: string;
      appUrl: string;
      vodAssetId: string;
      externalCustomerId?: string | null;
      email?: string | null;
      externalProductId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type VodConnectionSuggestionByAppKeyQueryVariables = {
  appKey?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelvodConnectionSuggestionFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type VodConnectionSuggestionByAppKeyQuery = {
  vodConnectionSuggestionByAppKey?: {
    __typename: 'ModelvodConnectionSuggestionConnection';
    items: Array<{
      __typename: 'vodConnectionSuggestion';
      id: string;
      appKey?: string | null;
      appUrl?: string | null;
      createdAt?: string | null;
      vodAssetId?: string | null;
      externalProductId?: string | null;
      status?: VodConnectionSuggestionStatus | null;
      source?: VodConnectionSuggestionSource | null;
      method?: VodConnectionSuggestionMethod | null;
      methodVersion?: string | null;
      score?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type VodConnectionSuggestionByVodAssetIdQueryVariables = {
  vodAssetId?: string | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelvodConnectionSuggestionFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type VodConnectionSuggestionByVodAssetIdQuery = {
  vodConnectionSuggestionByVodAssetId?: {
    __typename: 'ModelvodConnectionSuggestionConnection';
    items: Array<{
      __typename: 'vodConnectionSuggestion';
      id: string;
      appKey?: string | null;
      appUrl?: string | null;
      createdAt?: string | null;
      vodAssetId?: string | null;
      externalProductId?: string | null;
      status?: VodConnectionSuggestionStatus | null;
      source?: VodConnectionSuggestionSource | null;
      method?: VodConnectionSuggestionMethod | null;
      methodVersion?: string | null;
      score?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnCreateOrUpdateSessionByAppKeySubscriptionVariables = {
  appKey: string;
};

export type OnCreateOrUpdateSessionByAppKeySubscription = {
  onCreateOrUpdateSessionByAppKey?: {
    __typename: 'session';
    id: string;
    owner?: string | null;
    assignee?: string | null;
    appKey?: string | null;
    projectId?: string | null;
    createdAt: string;
    updatedAt: string;
    lastEventAt: string;
    sessionTime?: string | null;
    name?: string | null;
    email?: string | null;
    phone?: string | null;
    read?: boolean | null;
    archived?: boolean | null;
    actionNeeded?: boolean | null;
    totalEvents?: number | null;
    totalResponses?: number | null;
    totalReplies?: number | null;
    parentUrl?: string | null;
    videoResponse?: boolean | null;
    imageResponse?: boolean | null;
    audioResponse?: boolean | null;
    textResponse?: boolean | null;
    formResponse?: boolean | null;
    reachedEnd?: boolean | null;
    isSpam?: boolean | null;
  } | null;
};

export type OnUpdateAccountSettingsByAppKeySubscriptionVariables = {
  appKey: string;
};

export type OnUpdateAccountSettingsByAppKeySubscription = {
  onUpdateAccountSettingsByAppKey?: {
    __typename: 'accountSettings';
    id: string;
    appKey: string;
    owner?: string | null;
    name?: string | null;
    logoSettings?: string | null;
    widgetSettings?: string | null;
    downgradeInfo?: string | null;
    liveIds?: Array<string | null> | null;
    url?: string | null;
    logoUrl?: string | null;
    playerLogoUrl?: string | null;
    companyVertical?: string | null;
    companySize?: string | null;
    companyRole?: string | null;
    monthlyTraffic?: string | null;
    useCase?: string | null;
    packageId?: string | null;
    token?: string | null;
    paymentProvider?: string | null;
    paymentSubscriptions?: Array<string | null> | null;
    paymentIdentifier?: string | null;
    packageGroup?: string | null;
    joinedPackageAt?: string | null;
    trialEndAt?: string | null;
    referral?: string | null;
    crmData?: string | null;
    trialDays?: number | null;
    googleAnalyticsID?: string | null;
    appsInstallationStatuses?: string | null;
    onboardingStatuses?: string | null;
    assetAnalysis?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateProjectByAppKeySubscriptionVariables = {
  appKey: string;
};

export type OnUpdateProjectByAppKeySubscription = {
  onUpdateProjectByAppKey?: {
    __typename: 'project';
    id: string;
    owner?: string | null;
    appKey?: string | null;
    typeKey?: string | null;
    createdAt?: string | null;
    publishId: string;
    name: string;
    folder?: string | null;
    targetPage?: string | null;
    verticalOrientation?: boolean | null;
    widgetPosition?: string | null;
    widgetHeader?: string | null;
    widgetUrls?: Array<string | null> | null;
    description?: string | null;
    emailNotifications?: Array<string | null> | null;
    notificationLevel?: number | null;
    viewerNotificationLevel?: number | null;
    chaptersEnabled?: boolean | null;
    subtitlesEnabled?: boolean | null;
    stepsOrder?: Array<string | null> | null;
    collectText?: string | null;
    collectFields?: Array<{
      __typename: 'inputField';
      key?: string | null;
      name?: string | null;
      type?: string | null;
      placeholder?: string | null;
      link?: string | null;
      required?: boolean | null;
      collect?: boolean | null;
      custom?: boolean | null;
      textColor?: string | null;
      borderColor?: string | null;
      backgroundColor?: string | null;
    } | null> | null;
    collectAfterStep?: Array<string | null> | null;
    cacheLeadFormAnswers?: boolean | null;
    googleAnalyticsID?: string | null;
    facebookAnalyticsID?: string | null;
    totalResponses?: number | null;
    unreadResponses?: number | null;
    tolstoyType?: string | null;
    steps?: {
      __typename: 'ModelprojectStepConnection';
      items: Array<{
        __typename: 'projectStep';
        id: string;
        owner?: string | null;
        appKey?: string | null;
        projectId: string;
        videoId?: string | null;
        name: string;
        type?: string | null;
        description?: string | null;
        script?: string | null;
        question?: string | null;
        questionVisible?: boolean | null;
        answerTime?: number | null;
        answers?: Array<{
          __typename: 'answer';
          key?: string | null;
          text?: string | null;
          next?: string | null;
          value?: string | null;
          type?: string | null;
          disabled?: boolean | null;
        } | null> | null;
        products?: Array<{
          __typename: 'product';
          id?: string | null;
        } | null> | null;
        productsAppUrl?: string | null;
        videoContain?: boolean | null;
        overlayText?: string | null;
        overlayTextColor?: string | null;
        multipleChoiceSelection?: boolean | null;
        defaultResponses?: Array<{
          __typename: 'answer';
          key?: string | null;
          text?: string | null;
          next?: string | null;
          value?: string | null;
          type?: string | null;
          disabled?: boolean | null;
        } | null> | null;
        defaultResponsesEnabled?: boolean | null;
        sideBarButtonsEnabled?: boolean | null;
        feedProductActionButton?: {
          __typename: 'feedProductActionButton';
          type?: string | null;
          url?: string | null;
          text?: string | null;
          isOpenInANewTab?: boolean | null;
          value?: string | null;
          enabled?: boolean | null;
        } | null;
        feedSidePanelType?: FeedSidePanelType | null;
        feedDescriptionActionButton?: {
          __typename: 'feedProductActionButton';
          type?: string | null;
          url?: string | null;
          text?: string | null;
          isOpenInANewTab?: boolean | null;
          value?: string | null;
          enabled?: boolean | null;
        } | null;
        feedSidePanelDescription?: string | null;
        productCtaType?: ProductCtaType | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    stepsCount?: number | null;
    widgetBorderColor?: string | null;
    widgetShape?: string | null;
    widgetSize?: string | null;
    widgetNotificationBadge?: boolean | null;
    videoResolutions?: Array<string | null> | null;
    fastForwardEnabled?: boolean | null;
    rulesEnabled?: boolean | null;
    macro?: boolean | null;
    hideChatPlatform?: boolean | null;
    private?: boolean | null;
    chatLandingPage?: boolean | null;
    widgetSettings?: string | null;
    notificationSettingsId?: string | null;
    collectAfterAnyResponse?: boolean | null;
    startScreenText?: string | null;
    feed?: boolean | null;
    feedAutoScroll?: boolean | null;
    feedShowUnviewedStepsFirst?: boolean | null;
    language?: string | null;
    showButtonsIndex?: boolean | null;
    publishMethod?: publishMethodOptions | null;
    dynamic?: boolean | null;
    feedProductCtaType?: ProductCtaType | null;
    playerSettings?: string | null;
    appUrl?: string | null;
    live?: boolean | null;
    primaryButton?: {
      __typename: 'ctaButtonConfig';
      enabled?: boolean | null;
      ctaType?: ProductCtaType | null;
      buttonText?: string | null;
      value?: string | null;
      isOpenInANewTab?: boolean | null;
    } | null;
    secondaryButton?: {
      __typename: 'ctaButtonConfig';
      enabled?: boolean | null;
      ctaType?: ProductCtaType | null;
      buttonText?: string | null;
      value?: string | null;
      isOpenInANewTab?: boolean | null;
    } | null;
    pdpConfig?: {
      __typename: 'pdpConfig';
      primaryButton?: {
        __typename: 'ctaButtonConfig';
        enabled?: boolean | null;
        ctaType?: ProductCtaType | null;
        buttonText?: string | null;
        value?: string | null;
        isOpenInANewTab?: boolean | null;
      } | null;
      secondaryButton?: {
        __typename: 'ctaButtonConfig';
        enabled?: boolean | null;
        ctaType?: ProductCtaType | null;
        buttonText?: string | null;
        value?: string | null;
        isOpenInANewTab?: boolean | null;
      } | null;
      miniCtaButton?: {
        __typename: 'miniCtaButtonConfig';
        enabled?: boolean | null;
        text?: string | null;
      } | null;
    } | null;
    installationCompleted?: boolean | null;
    installStep?: string | null;
    discover?: boolean | null;
    useCaseName?: string | null;
    heroSettings?: string | null;
    sourceProjectId?: string | null;
    emailSettings?: string | null;
    videoCollectionCampaignSettings?: string | null;
    secretVideoSettings?: string | null;
    integrationSettings?: string | null;
    updatedAt: string;
  } | null;
};

export type OnProjectChangeByAppKeySubscriptionVariables = {
  appKey: string;
};

export type OnProjectChangeByAppKeySubscription = {
  onProjectChangeByAppKey?: {
    __typename: 'project';
    id: string;
    owner?: string | null;
    appKey?: string | null;
    typeKey?: string | null;
    createdAt?: string | null;
    publishId: string;
    name: string;
    folder?: string | null;
    targetPage?: string | null;
    verticalOrientation?: boolean | null;
    widgetPosition?: string | null;
    widgetHeader?: string | null;
    widgetUrls?: Array<string | null> | null;
    description?: string | null;
    emailNotifications?: Array<string | null> | null;
    notificationLevel?: number | null;
    viewerNotificationLevel?: number | null;
    chaptersEnabled?: boolean | null;
    subtitlesEnabled?: boolean | null;
    stepsOrder?: Array<string | null> | null;
    collectText?: string | null;
    collectFields?: Array<{
      __typename: 'inputField';
      key?: string | null;
      name?: string | null;
      type?: string | null;
      placeholder?: string | null;
      link?: string | null;
      required?: boolean | null;
      collect?: boolean | null;
      custom?: boolean | null;
      textColor?: string | null;
      borderColor?: string | null;
      backgroundColor?: string | null;
    } | null> | null;
    collectAfterStep?: Array<string | null> | null;
    cacheLeadFormAnswers?: boolean | null;
    googleAnalyticsID?: string | null;
    facebookAnalyticsID?: string | null;
    totalResponses?: number | null;
    unreadResponses?: number | null;
    tolstoyType?: string | null;
    steps?: {
      __typename: 'ModelprojectStepConnection';
      items: Array<{
        __typename: 'projectStep';
        id: string;
        owner?: string | null;
        appKey?: string | null;
        projectId: string;
        videoId?: string | null;
        name: string;
        type?: string | null;
        description?: string | null;
        script?: string | null;
        question?: string | null;
        questionVisible?: boolean | null;
        answerTime?: number | null;
        answers?: Array<{
          __typename: 'answer';
          key?: string | null;
          text?: string | null;
          next?: string | null;
          value?: string | null;
          type?: string | null;
          disabled?: boolean | null;
        } | null> | null;
        products?: Array<{
          __typename: 'product';
          id?: string | null;
        } | null> | null;
        productsAppUrl?: string | null;
        videoContain?: boolean | null;
        overlayText?: string | null;
        overlayTextColor?: string | null;
        multipleChoiceSelection?: boolean | null;
        defaultResponses?: Array<{
          __typename: 'answer';
          key?: string | null;
          text?: string | null;
          next?: string | null;
          value?: string | null;
          type?: string | null;
          disabled?: boolean | null;
        } | null> | null;
        defaultResponsesEnabled?: boolean | null;
        sideBarButtonsEnabled?: boolean | null;
        feedProductActionButton?: {
          __typename: 'feedProductActionButton';
          type?: string | null;
          url?: string | null;
          text?: string | null;
          isOpenInANewTab?: boolean | null;
          value?: string | null;
          enabled?: boolean | null;
        } | null;
        feedSidePanelType?: FeedSidePanelType | null;
        feedDescriptionActionButton?: {
          __typename: 'feedProductActionButton';
          type?: string | null;
          url?: string | null;
          text?: string | null;
          isOpenInANewTab?: boolean | null;
          value?: string | null;
          enabled?: boolean | null;
        } | null;
        feedSidePanelDescription?: string | null;
        productCtaType?: ProductCtaType | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    stepsCount?: number | null;
    widgetBorderColor?: string | null;
    widgetShape?: string | null;
    widgetSize?: string | null;
    widgetNotificationBadge?: boolean | null;
    videoResolutions?: Array<string | null> | null;
    fastForwardEnabled?: boolean | null;
    rulesEnabled?: boolean | null;
    macro?: boolean | null;
    hideChatPlatform?: boolean | null;
    private?: boolean | null;
    chatLandingPage?: boolean | null;
    widgetSettings?: string | null;
    notificationSettingsId?: string | null;
    collectAfterAnyResponse?: boolean | null;
    startScreenText?: string | null;
    feed?: boolean | null;
    feedAutoScroll?: boolean | null;
    feedShowUnviewedStepsFirst?: boolean | null;
    language?: string | null;
    showButtonsIndex?: boolean | null;
    publishMethod?: publishMethodOptions | null;
    dynamic?: boolean | null;
    feedProductCtaType?: ProductCtaType | null;
    playerSettings?: string | null;
    appUrl?: string | null;
    live?: boolean | null;
    primaryButton?: {
      __typename: 'ctaButtonConfig';
      enabled?: boolean | null;
      ctaType?: ProductCtaType | null;
      buttonText?: string | null;
      value?: string | null;
      isOpenInANewTab?: boolean | null;
    } | null;
    secondaryButton?: {
      __typename: 'ctaButtonConfig';
      enabled?: boolean | null;
      ctaType?: ProductCtaType | null;
      buttonText?: string | null;
      value?: string | null;
      isOpenInANewTab?: boolean | null;
    } | null;
    pdpConfig?: {
      __typename: 'pdpConfig';
      primaryButton?: {
        __typename: 'ctaButtonConfig';
        enabled?: boolean | null;
        ctaType?: ProductCtaType | null;
        buttonText?: string | null;
        value?: string | null;
        isOpenInANewTab?: boolean | null;
      } | null;
      secondaryButton?: {
        __typename: 'ctaButtonConfig';
        enabled?: boolean | null;
        ctaType?: ProductCtaType | null;
        buttonText?: string | null;
        value?: string | null;
        isOpenInANewTab?: boolean | null;
      } | null;
      miniCtaButton?: {
        __typename: 'miniCtaButtonConfig';
        enabled?: boolean | null;
        text?: string | null;
      } | null;
    } | null;
    installationCompleted?: boolean | null;
    installStep?: string | null;
    discover?: boolean | null;
    useCaseName?: string | null;
    heroSettings?: string | null;
    sourceProjectId?: string | null;
    emailSettings?: string | null;
    videoCollectionCampaignSettings?: string | null;
    secretVideoSettings?: string | null;
    integrationSettings?: string | null;
    updatedAt: string;
  } | null;
};

export type OnProjectStepChangeByAppKeySubscriptionVariables = {
  appKey: string;
};

export type OnProjectStepChangeByAppKeySubscription = {
  onProjectStepChangeByAppKey?: {
    __typename: 'projectStep';
    id: string;
    owner?: string | null;
    appKey?: string | null;
    projectId: string;
    videoId?: string | null;
    name: string;
    type?: string | null;
    description?: string | null;
    script?: string | null;
    question?: string | null;
    questionVisible?: boolean | null;
    answerTime?: number | null;
    answers?: Array<{
      __typename: 'answer';
      key?: string | null;
      text?: string | null;
      next?: string | null;
      value?: string | null;
      type?: string | null;
      disabled?: boolean | null;
    } | null> | null;
    products?: Array<{
      __typename: 'product';
      id?: string | null;
    } | null> | null;
    productsAppUrl?: string | null;
    videoContain?: boolean | null;
    overlayText?: string | null;
    overlayTextColor?: string | null;
    multipleChoiceSelection?: boolean | null;
    defaultResponses?: Array<{
      __typename: 'answer';
      key?: string | null;
      text?: string | null;
      next?: string | null;
      value?: string | null;
      type?: string | null;
      disabled?: boolean | null;
    } | null> | null;
    defaultResponsesEnabled?: boolean | null;
    sideBarButtonsEnabled?: boolean | null;
    feedProductActionButton?: {
      __typename: 'feedProductActionButton';
      type?: string | null;
      url?: string | null;
      text?: string | null;
      isOpenInANewTab?: boolean | null;
      value?: string | null;
      enabled?: boolean | null;
    } | null;
    feedSidePanelType?: FeedSidePanelType | null;
    feedDescriptionActionButton?: {
      __typename: 'feedProductActionButton';
      type?: string | null;
      url?: string | null;
      text?: string | null;
      isOpenInANewTab?: boolean | null;
      value?: string | null;
      enabled?: boolean | null;
    } | null;
    feedSidePanelDescription?: string | null;
    productCtaType?: ProductCtaType | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateOrUpdateVodAssetByAppKeySubscriptionVariables = {
  appKey: string;
};

export type OnCreateOrUpdateVodAssetByAppKeySubscription = {
  onCreateOrUpdateVodAssetByAppKey?: {
    __typename: 'vodAsset';
    id: string;
    type?: assetType | null;
    owner?: string | null;
    appKey?: string | null;
    appExternalId?: string | null;
    createdAt?: string | null;
    name: string;
    description?: string | null;
    creatorProfile?: {
      __typename: 'creatorProfile';
      url?: string | null;
    } | null;
    isSoundAllowed?: boolean | null;
    status: string;
    uploadType?: string | null;
    stockAsset?: {
      __typename: 'stockAsset';
      videoUrl?: string | null;
      posterUrl?: string | null;
      gifUrl?: string | null;
      hasOriginal?: boolean | null;
      shopifyFileId?: string | null;
      previewUrl?: string | null;
      previewShopifyFileId?: string | null;
      shopifyPosterUrl?: string | null;
      posterShopifyFileId?: string | null;
      avatarUrl?: string | null;
      audioUrl?: string | null;
      gifShopifyFileId?: string | null;
    } | null;
    duration?: number | null;
    folder?: string | null;
    width?: number | null;
    height?: number | null;
    isVertical?: boolean | null;
    isHDR?: boolean | null;
    trimSettings?: string | null;
    originalVOD?: string | null;
    mirror?: boolean | null;
    recorderVersion?: string | null;
    originalSize?: string | null;
    externalId?: string | null;
    externalProviderData?: string | null;
    isMigratingToShopify?: boolean | null;
    shopifyUploadingStatus?: string | null;
    isEverUsed?: boolean | null;
    captionsSettings?: string | null;
    referenceIds?: Array<string | null> | null;
    vodConnections?: {
      __typename: 'ModelvodConnectionConnection';
      items: Array<{
        __typename: 'vodConnection';
        id: string;
        appKey?: string | null;
        vodAssetId?: string | null;
        productId?: string | null;
        externalProductId?: string | null;
        collectionId?: string | null;
        provider?: VodConnectionProvider | null;
        tag?: string | null;
        createdAt?: string | null;
        typeKey?: string | null;
        type?: VodConnectionType | null;
        appUrl?: string | null;
        label?: string | null;
        vodLabelId?: string | null;
        vodSource?: string | null;
        vodType?: string | null;
        variantIds?: Array<string | null> | null;
        projectId?: string | null;
        orderIndex?: number | null;
        source?: string | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    analysis?: string | null;
    autoGenerated?: boolean | null;
    updatedAt: string;
  } | null;
};

export type OnCreateInAppNotificationByTeamMemberIdSubscriptionVariables = {
  teamMemberId: string;
};

export type OnCreateInAppNotificationByTeamMemberIdSubscription = {
  onCreateInAppNotificationByTeamMemberId?: {
    __typename: 'inAppNotification';
    id: string;
    owner?: string | null;
    teamMemberId?: string | null;
    projectId?: string | null;
    sessionId?: string | null;
    type?: string | null;
    value?: string | null;
    email?: string | null;
    createdAt: string;
    read?: boolean | null;
    expirationUnixTime: number;
    token?: string | null;
    stepName?: string | null;
    updatedAt: string;
  } | null;
};

export type OnCreateOrUpdateAppByAppKeySubscriptionVariables = {
  appKey: string;
};

export type OnCreateOrUpdateAppByAppKeySubscription = {
  onCreateOrUpdateAppByAppKey?: {
    __typename: 'app';
    id: string;
    owner?: string | null;
    appKey?: string | null;
    app?: string | null;
    active?: boolean | null;
    data?: string | null;
    token?: string | null;
    appExternalId?: string | null;
    appUrl?: string | null;
    importSettings?: ImportSettings | null;
    importRange?: number | null;
    syncTagsMode?: string | null;
    syndicateBy?: SyndicateBy | null;
    googleAnalyticsID?: string | null;
    settings?: string | null;
    isShopAppEligible?: boolean | null;
    taxonomyId?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateOrUpdateIntegrationVodAssetByAppKeySubscriptionVariables = {
  appKey: string;
};

export type OnCreateOrUpdateIntegrationVodAssetByAppKeySubscription = {
  onCreateOrUpdateIntegrationVodAssetByAppKey?: {
    __typename: 'integrationVodAsset';
    id: string;
    appKey?: string | null;
    owner?: string | null;
    app?: string | null;
    appId?: string | null;
    externalId?: string | null;
    externalCreatedAt?: string | null;
    vodId?: string | null;
    seen?: boolean | null;
    title?: string | null;
    thumbnailUrl?: string | null;
    downloadUrl?: string | null;
    status?: StatusTypes | null;
    createdAt?: string | null;
    updatedAt: string;
  } | null;
};

export type OnCreateVodConnectionByAppKeySubscriptionVariables = {
  appKey: string;
};

export type OnCreateVodConnectionByAppKeySubscription = {
  onCreateVodConnectionByAppKey?: {
    __typename: 'vodConnection';
    id: string;
    appKey?: string | null;
    vodAssetId?: string | null;
    productId?: string | null;
    externalProductId?: string | null;
    collectionId?: string | null;
    provider?: VodConnectionProvider | null;
    tag?: string | null;
    createdAt?: string | null;
    typeKey?: string | null;
    type?: VodConnectionType | null;
    appUrl?: string | null;
    label?: string | null;
    vodLabelId?: string | null;
    vodSource?: string | null;
    vodType?: string | null;
    variantIds?: Array<string | null> | null;
    projectId?: string | null;
    orderIndex?: number | null;
    source?: string | null;
    updatedAt: string;
  } | null;
};

export type OnDeleteVodConnectionByAppKeySubscriptionVariables = {
  appKey: string;
};

export type OnDeleteVodConnectionByAppKeySubscription = {
  onDeleteVodConnectionByAppKey?: {
    __typename: 'vodConnection';
    id: string;
    appKey?: string | null;
    vodAssetId?: string | null;
    productId?: string | null;
    externalProductId?: string | null;
    collectionId?: string | null;
    provider?: VodConnectionProvider | null;
    tag?: string | null;
    createdAt?: string | null;
    typeKey?: string | null;
    type?: VodConnectionType | null;
    appUrl?: string | null;
    label?: string | null;
    vodLabelId?: string | null;
    vodSource?: string | null;
    vodType?: string | null;
    variantIds?: Array<string | null> | null;
    projectId?: string | null;
    orderIndex?: number | null;
    source?: string | null;
    updatedAt: string;
  } | null;
};

export type OnUpdateVodConnectionByAppKeySubscriptionVariables = {
  appKey: string;
};

export type OnUpdateVodConnectionByAppKeySubscription = {
  onUpdateVodConnectionByAppKey?: {
    __typename: 'vodConnection';
    id: string;
    appKey?: string | null;
    vodAssetId?: string | null;
    productId?: string | null;
    externalProductId?: string | null;
    collectionId?: string | null;
    provider?: VodConnectionProvider | null;
    tag?: string | null;
    createdAt?: string | null;
    typeKey?: string | null;
    type?: VodConnectionType | null;
    appUrl?: string | null;
    label?: string | null;
    vodLabelId?: string | null;
    vodSource?: string | null;
    vodType?: string | null;
    variantIds?: Array<string | null> | null;
    projectId?: string | null;
    orderIndex?: number | null;
    source?: string | null;
    updatedAt: string;
  } | null;
};

export type OnCreateVodConnectionSuggestionByAppKeySubscriptionVariables = {
  appKey: string;
};

export type OnCreateVodConnectionSuggestionByAppKeySubscription = {
  onCreateVodConnectionSuggestionByAppKey?: {
    __typename: 'vodConnectionSuggestion';
    id: string;
    appKey?: string | null;
    appUrl?: string | null;
    createdAt?: string | null;
    vodAssetId?: string | null;
    externalProductId?: string | null;
    status?: VodConnectionSuggestionStatus | null;
    source?: VodConnectionSuggestionSource | null;
    method?: VodConnectionSuggestionMethod | null;
    methodVersion?: string | null;
    score?: number | null;
    updatedAt: string;
  } | null;
};

export type OnCreateVodAssetSubscriptionVariables = {};

export type OnCreateVodAssetSubscription = {
  onCreateVodAsset?: {
    __typename: 'vodAsset';
    id: string;
    type?: assetType | null;
    owner?: string | null;
    appKey?: string | null;
    appExternalId?: string | null;
    createdAt?: string | null;
    name: string;
    description?: string | null;
    creatorProfile?: {
      __typename: 'creatorProfile';
      url?: string | null;
    } | null;
    isSoundAllowed?: boolean | null;
    status: string;
    uploadType?: string | null;
    stockAsset?: {
      __typename: 'stockAsset';
      videoUrl?: string | null;
      posterUrl?: string | null;
      gifUrl?: string | null;
      hasOriginal?: boolean | null;
      shopifyFileId?: string | null;
      previewUrl?: string | null;
      previewShopifyFileId?: string | null;
      shopifyPosterUrl?: string | null;
      posterShopifyFileId?: string | null;
      avatarUrl?: string | null;
      audioUrl?: string | null;
      gifShopifyFileId?: string | null;
    } | null;
    duration?: number | null;
    folder?: string | null;
    width?: number | null;
    height?: number | null;
    isVertical?: boolean | null;
    isHDR?: boolean | null;
    trimSettings?: string | null;
    originalVOD?: string | null;
    mirror?: boolean | null;
    recorderVersion?: string | null;
    originalSize?: string | null;
    externalId?: string | null;
    externalProviderData?: string | null;
    isMigratingToShopify?: boolean | null;
    shopifyUploadingStatus?: string | null;
    isEverUsed?: boolean | null;
    captionsSettings?: string | null;
    referenceIds?: Array<string | null> | null;
    vodConnections?: {
      __typename: 'ModelvodConnectionConnection';
      items: Array<{
        __typename: 'vodConnection';
        id: string;
        appKey?: string | null;
        vodAssetId?: string | null;
        productId?: string | null;
        externalProductId?: string | null;
        collectionId?: string | null;
        provider?: VodConnectionProvider | null;
        tag?: string | null;
        createdAt?: string | null;
        typeKey?: string | null;
        type?: VodConnectionType | null;
        appUrl?: string | null;
        label?: string | null;
        vodLabelId?: string | null;
        vodSource?: string | null;
        vodType?: string | null;
        variantIds?: Array<string | null> | null;
        projectId?: string | null;
        orderIndex?: number | null;
        source?: string | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    analysis?: string | null;
    autoGenerated?: boolean | null;
    updatedAt: string;
  } | null;
};

export type OnUpdateVodAssetSubscriptionVariables = {};

export type OnUpdateVodAssetSubscription = {
  onUpdateVodAsset?: {
    __typename: 'vodAsset';
    id: string;
    type?: assetType | null;
    owner?: string | null;
    appKey?: string | null;
    appExternalId?: string | null;
    createdAt?: string | null;
    name: string;
    description?: string | null;
    creatorProfile?: {
      __typename: 'creatorProfile';
      url?: string | null;
    } | null;
    isSoundAllowed?: boolean | null;
    status: string;
    uploadType?: string | null;
    stockAsset?: {
      __typename: 'stockAsset';
      videoUrl?: string | null;
      posterUrl?: string | null;
      gifUrl?: string | null;
      hasOriginal?: boolean | null;
      shopifyFileId?: string | null;
      previewUrl?: string | null;
      previewShopifyFileId?: string | null;
      shopifyPosterUrl?: string | null;
      posterShopifyFileId?: string | null;
      avatarUrl?: string | null;
      audioUrl?: string | null;
      gifShopifyFileId?: string | null;
    } | null;
    duration?: number | null;
    folder?: string | null;
    width?: number | null;
    height?: number | null;
    isVertical?: boolean | null;
    isHDR?: boolean | null;
    trimSettings?: string | null;
    originalVOD?: string | null;
    mirror?: boolean | null;
    recorderVersion?: string | null;
    originalSize?: string | null;
    externalId?: string | null;
    externalProviderData?: string | null;
    isMigratingToShopify?: boolean | null;
    shopifyUploadingStatus?: string | null;
    isEverUsed?: boolean | null;
    captionsSettings?: string | null;
    referenceIds?: Array<string | null> | null;
    vodConnections?: {
      __typename: 'ModelvodConnectionConnection';
      items: Array<{
        __typename: 'vodConnection';
        id: string;
        appKey?: string | null;
        vodAssetId?: string | null;
        productId?: string | null;
        externalProductId?: string | null;
        collectionId?: string | null;
        provider?: VodConnectionProvider | null;
        tag?: string | null;
        createdAt?: string | null;
        typeKey?: string | null;
        type?: VodConnectionType | null;
        appUrl?: string | null;
        label?: string | null;
        vodLabelId?: string | null;
        vodSource?: string | null;
        vodType?: string | null;
        variantIds?: Array<string | null> | null;
        projectId?: string | null;
        orderIndex?: number | null;
        source?: string | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    analysis?: string | null;
    autoGenerated?: boolean | null;
    updatedAt: string;
  } | null;
};

export type OnDeleteVodAssetSubscriptionVariables = {};

export type OnDeleteVodAssetSubscription = {
  onDeleteVodAsset?: {
    __typename: 'vodAsset';
    id: string;
    type?: assetType | null;
    owner?: string | null;
    appKey?: string | null;
    appExternalId?: string | null;
    createdAt?: string | null;
    name: string;
    description?: string | null;
    creatorProfile?: {
      __typename: 'creatorProfile';
      url?: string | null;
    } | null;
    isSoundAllowed?: boolean | null;
    status: string;
    uploadType?: string | null;
    stockAsset?: {
      __typename: 'stockAsset';
      videoUrl?: string | null;
      posterUrl?: string | null;
      gifUrl?: string | null;
      hasOriginal?: boolean | null;
      shopifyFileId?: string | null;
      previewUrl?: string | null;
      previewShopifyFileId?: string | null;
      shopifyPosterUrl?: string | null;
      posterShopifyFileId?: string | null;
      avatarUrl?: string | null;
      audioUrl?: string | null;
      gifShopifyFileId?: string | null;
    } | null;
    duration?: number | null;
    folder?: string | null;
    width?: number | null;
    height?: number | null;
    isVertical?: boolean | null;
    isHDR?: boolean | null;
    trimSettings?: string | null;
    originalVOD?: string | null;
    mirror?: boolean | null;
    recorderVersion?: string | null;
    originalSize?: string | null;
    externalId?: string | null;
    externalProviderData?: string | null;
    isMigratingToShopify?: boolean | null;
    shopifyUploadingStatus?: string | null;
    isEverUsed?: boolean | null;
    captionsSettings?: string | null;
    referenceIds?: Array<string | null> | null;
    vodConnections?: {
      __typename: 'ModelvodConnectionConnection';
      items: Array<{
        __typename: 'vodConnection';
        id: string;
        appKey?: string | null;
        vodAssetId?: string | null;
        productId?: string | null;
        externalProductId?: string | null;
        collectionId?: string | null;
        provider?: VodConnectionProvider | null;
        tag?: string | null;
        createdAt?: string | null;
        typeKey?: string | null;
        type?: VodConnectionType | null;
        appUrl?: string | null;
        label?: string | null;
        vodLabelId?: string | null;
        vodSource?: string | null;
        vodType?: string | null;
        variantIds?: Array<string | null> | null;
        projectId?: string | null;
        orderIndex?: number | null;
        source?: string | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    analysis?: string | null;
    autoGenerated?: boolean | null;
    updatedAt: string;
  } | null;
};

export type OnCreateVodResponseAssetSubscriptionVariables = {};

export type OnCreateVodResponseAssetSubscription = {
  onCreateVodResponseAsset?: {
    __typename: 'vodResponseAsset';
    id: string;
    owner: string;
    appKey?: string | null;
    createdAt: string;
    status?: string | null;
    uploadType?: string | null;
    duration?: number | null;
    width?: number | null;
    height?: number | null;
    isVertical?: boolean | null;
    isHDR?: boolean | null;
    mirror?: boolean | null;
    originalSize?: string | null;
    email?: string | null;
    projectId?: string | null;
    updatedAt: string;
  } | null;
};

export type OnUpdateVodResponseAssetSubscriptionVariables = {};

export type OnUpdateVodResponseAssetSubscription = {
  onUpdateVodResponseAsset?: {
    __typename: 'vodResponseAsset';
    id: string;
    owner: string;
    appKey?: string | null;
    createdAt: string;
    status?: string | null;
    uploadType?: string | null;
    duration?: number | null;
    width?: number | null;
    height?: number | null;
    isVertical?: boolean | null;
    isHDR?: boolean | null;
    mirror?: boolean | null;
    originalSize?: string | null;
    email?: string | null;
    projectId?: string | null;
    updatedAt: string;
  } | null;
};

export type OnDeleteVodResponseAssetSubscriptionVariables = {};

export type OnDeleteVodResponseAssetSubscription = {
  onDeleteVodResponseAsset?: {
    __typename: 'vodResponseAsset';
    id: string;
    owner: string;
    appKey?: string | null;
    createdAt: string;
    status?: string | null;
    uploadType?: string | null;
    duration?: number | null;
    width?: number | null;
    height?: number | null;
    isVertical?: boolean | null;
    isHDR?: boolean | null;
    mirror?: boolean | null;
    originalSize?: string | null;
    email?: string | null;
    projectId?: string | null;
    updatedAt: string;
  } | null;
};

export type OnCreateProjectSubscriptionVariables = {};

export type OnCreateProjectSubscription = {
  onCreateProject?: {
    __typename: 'project';
    id: string;
    owner?: string | null;
    appKey?: string | null;
    typeKey?: string | null;
    createdAt?: string | null;
    publishId: string;
    name: string;
    folder?: string | null;
    targetPage?: string | null;
    verticalOrientation?: boolean | null;
    widgetPosition?: string | null;
    widgetHeader?: string | null;
    widgetUrls?: Array<string | null> | null;
    description?: string | null;
    emailNotifications?: Array<string | null> | null;
    notificationLevel?: number | null;
    viewerNotificationLevel?: number | null;
    chaptersEnabled?: boolean | null;
    subtitlesEnabled?: boolean | null;
    stepsOrder?: Array<string | null> | null;
    collectText?: string | null;
    collectFields?: Array<{
      __typename: 'inputField';
      key?: string | null;
      name?: string | null;
      type?: string | null;
      placeholder?: string | null;
      link?: string | null;
      required?: boolean | null;
      collect?: boolean | null;
      custom?: boolean | null;
      textColor?: string | null;
      borderColor?: string | null;
      backgroundColor?: string | null;
    } | null> | null;
    collectAfterStep?: Array<string | null> | null;
    cacheLeadFormAnswers?: boolean | null;
    googleAnalyticsID?: string | null;
    facebookAnalyticsID?: string | null;
    totalResponses?: number | null;
    unreadResponses?: number | null;
    tolstoyType?: string | null;
    steps?: {
      __typename: 'ModelprojectStepConnection';
      items: Array<{
        __typename: 'projectStep';
        id: string;
        owner?: string | null;
        appKey?: string | null;
        projectId: string;
        videoId?: string | null;
        name: string;
        type?: string | null;
        description?: string | null;
        script?: string | null;
        question?: string | null;
        questionVisible?: boolean | null;
        answerTime?: number | null;
        answers?: Array<{
          __typename: 'answer';
          key?: string | null;
          text?: string | null;
          next?: string | null;
          value?: string | null;
          type?: string | null;
          disabled?: boolean | null;
        } | null> | null;
        products?: Array<{
          __typename: 'product';
          id?: string | null;
        } | null> | null;
        productsAppUrl?: string | null;
        videoContain?: boolean | null;
        overlayText?: string | null;
        overlayTextColor?: string | null;
        multipleChoiceSelection?: boolean | null;
        defaultResponses?: Array<{
          __typename: 'answer';
          key?: string | null;
          text?: string | null;
          next?: string | null;
          value?: string | null;
          type?: string | null;
          disabled?: boolean | null;
        } | null> | null;
        defaultResponsesEnabled?: boolean | null;
        sideBarButtonsEnabled?: boolean | null;
        feedProductActionButton?: {
          __typename: 'feedProductActionButton';
          type?: string | null;
          url?: string | null;
          text?: string | null;
          isOpenInANewTab?: boolean | null;
          value?: string | null;
          enabled?: boolean | null;
        } | null;
        feedSidePanelType?: FeedSidePanelType | null;
        feedDescriptionActionButton?: {
          __typename: 'feedProductActionButton';
          type?: string | null;
          url?: string | null;
          text?: string | null;
          isOpenInANewTab?: boolean | null;
          value?: string | null;
          enabled?: boolean | null;
        } | null;
        feedSidePanelDescription?: string | null;
        productCtaType?: ProductCtaType | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    stepsCount?: number | null;
    widgetBorderColor?: string | null;
    widgetShape?: string | null;
    widgetSize?: string | null;
    widgetNotificationBadge?: boolean | null;
    videoResolutions?: Array<string | null> | null;
    fastForwardEnabled?: boolean | null;
    rulesEnabled?: boolean | null;
    macro?: boolean | null;
    hideChatPlatform?: boolean | null;
    private?: boolean | null;
    chatLandingPage?: boolean | null;
    widgetSettings?: string | null;
    notificationSettingsId?: string | null;
    collectAfterAnyResponse?: boolean | null;
    startScreenText?: string | null;
    feed?: boolean | null;
    feedAutoScroll?: boolean | null;
    feedShowUnviewedStepsFirst?: boolean | null;
    language?: string | null;
    showButtonsIndex?: boolean | null;
    publishMethod?: publishMethodOptions | null;
    dynamic?: boolean | null;
    feedProductCtaType?: ProductCtaType | null;
    playerSettings?: string | null;
    appUrl?: string | null;
    live?: boolean | null;
    primaryButton?: {
      __typename: 'ctaButtonConfig';
      enabled?: boolean | null;
      ctaType?: ProductCtaType | null;
      buttonText?: string | null;
      value?: string | null;
      isOpenInANewTab?: boolean | null;
    } | null;
    secondaryButton?: {
      __typename: 'ctaButtonConfig';
      enabled?: boolean | null;
      ctaType?: ProductCtaType | null;
      buttonText?: string | null;
      value?: string | null;
      isOpenInANewTab?: boolean | null;
    } | null;
    pdpConfig?: {
      __typename: 'pdpConfig';
      primaryButton?: {
        __typename: 'ctaButtonConfig';
        enabled?: boolean | null;
        ctaType?: ProductCtaType | null;
        buttonText?: string | null;
        value?: string | null;
        isOpenInANewTab?: boolean | null;
      } | null;
      secondaryButton?: {
        __typename: 'ctaButtonConfig';
        enabled?: boolean | null;
        ctaType?: ProductCtaType | null;
        buttonText?: string | null;
        value?: string | null;
        isOpenInANewTab?: boolean | null;
      } | null;
      miniCtaButton?: {
        __typename: 'miniCtaButtonConfig';
        enabled?: boolean | null;
        text?: string | null;
      } | null;
    } | null;
    installationCompleted?: boolean | null;
    installStep?: string | null;
    discover?: boolean | null;
    useCaseName?: string | null;
    heroSettings?: string | null;
    sourceProjectId?: string | null;
    emailSettings?: string | null;
    videoCollectionCampaignSettings?: string | null;
    secretVideoSettings?: string | null;
    integrationSettings?: string | null;
    updatedAt: string;
  } | null;
};

export type OnUpdateProjectSubscriptionVariables = {};

export type OnUpdateProjectSubscription = {
  onUpdateProject?: {
    __typename: 'project';
    id: string;
    owner?: string | null;
    appKey?: string | null;
    typeKey?: string | null;
    createdAt?: string | null;
    publishId: string;
    name: string;
    folder?: string | null;
    targetPage?: string | null;
    verticalOrientation?: boolean | null;
    widgetPosition?: string | null;
    widgetHeader?: string | null;
    widgetUrls?: Array<string | null> | null;
    description?: string | null;
    emailNotifications?: Array<string | null> | null;
    notificationLevel?: number | null;
    viewerNotificationLevel?: number | null;
    chaptersEnabled?: boolean | null;
    subtitlesEnabled?: boolean | null;
    stepsOrder?: Array<string | null> | null;
    collectText?: string | null;
    collectFields?: Array<{
      __typename: 'inputField';
      key?: string | null;
      name?: string | null;
      type?: string | null;
      placeholder?: string | null;
      link?: string | null;
      required?: boolean | null;
      collect?: boolean | null;
      custom?: boolean | null;
      textColor?: string | null;
      borderColor?: string | null;
      backgroundColor?: string | null;
    } | null> | null;
    collectAfterStep?: Array<string | null> | null;
    cacheLeadFormAnswers?: boolean | null;
    googleAnalyticsID?: string | null;
    facebookAnalyticsID?: string | null;
    totalResponses?: number | null;
    unreadResponses?: number | null;
    tolstoyType?: string | null;
    steps?: {
      __typename: 'ModelprojectStepConnection';
      items: Array<{
        __typename: 'projectStep';
        id: string;
        owner?: string | null;
        appKey?: string | null;
        projectId: string;
        videoId?: string | null;
        name: string;
        type?: string | null;
        description?: string | null;
        script?: string | null;
        question?: string | null;
        questionVisible?: boolean | null;
        answerTime?: number | null;
        answers?: Array<{
          __typename: 'answer';
          key?: string | null;
          text?: string | null;
          next?: string | null;
          value?: string | null;
          type?: string | null;
          disabled?: boolean | null;
        } | null> | null;
        products?: Array<{
          __typename: 'product';
          id?: string | null;
        } | null> | null;
        productsAppUrl?: string | null;
        videoContain?: boolean | null;
        overlayText?: string | null;
        overlayTextColor?: string | null;
        multipleChoiceSelection?: boolean | null;
        defaultResponses?: Array<{
          __typename: 'answer';
          key?: string | null;
          text?: string | null;
          next?: string | null;
          value?: string | null;
          type?: string | null;
          disabled?: boolean | null;
        } | null> | null;
        defaultResponsesEnabled?: boolean | null;
        sideBarButtonsEnabled?: boolean | null;
        feedProductActionButton?: {
          __typename: 'feedProductActionButton';
          type?: string | null;
          url?: string | null;
          text?: string | null;
          isOpenInANewTab?: boolean | null;
          value?: string | null;
          enabled?: boolean | null;
        } | null;
        feedSidePanelType?: FeedSidePanelType | null;
        feedDescriptionActionButton?: {
          __typename: 'feedProductActionButton';
          type?: string | null;
          url?: string | null;
          text?: string | null;
          isOpenInANewTab?: boolean | null;
          value?: string | null;
          enabled?: boolean | null;
        } | null;
        feedSidePanelDescription?: string | null;
        productCtaType?: ProductCtaType | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    stepsCount?: number | null;
    widgetBorderColor?: string | null;
    widgetShape?: string | null;
    widgetSize?: string | null;
    widgetNotificationBadge?: boolean | null;
    videoResolutions?: Array<string | null> | null;
    fastForwardEnabled?: boolean | null;
    rulesEnabled?: boolean | null;
    macro?: boolean | null;
    hideChatPlatform?: boolean | null;
    private?: boolean | null;
    chatLandingPage?: boolean | null;
    widgetSettings?: string | null;
    notificationSettingsId?: string | null;
    collectAfterAnyResponse?: boolean | null;
    startScreenText?: string | null;
    feed?: boolean | null;
    feedAutoScroll?: boolean | null;
    feedShowUnviewedStepsFirst?: boolean | null;
    language?: string | null;
    showButtonsIndex?: boolean | null;
    publishMethod?: publishMethodOptions | null;
    dynamic?: boolean | null;
    feedProductCtaType?: ProductCtaType | null;
    playerSettings?: string | null;
    appUrl?: string | null;
    live?: boolean | null;
    primaryButton?: {
      __typename: 'ctaButtonConfig';
      enabled?: boolean | null;
      ctaType?: ProductCtaType | null;
      buttonText?: string | null;
      value?: string | null;
      isOpenInANewTab?: boolean | null;
    } | null;
    secondaryButton?: {
      __typename: 'ctaButtonConfig';
      enabled?: boolean | null;
      ctaType?: ProductCtaType | null;
      buttonText?: string | null;
      value?: string | null;
      isOpenInANewTab?: boolean | null;
    } | null;
    pdpConfig?: {
      __typename: 'pdpConfig';
      primaryButton?: {
        __typename: 'ctaButtonConfig';
        enabled?: boolean | null;
        ctaType?: ProductCtaType | null;
        buttonText?: string | null;
        value?: string | null;
        isOpenInANewTab?: boolean | null;
      } | null;
      secondaryButton?: {
        __typename: 'ctaButtonConfig';
        enabled?: boolean | null;
        ctaType?: ProductCtaType | null;
        buttonText?: string | null;
        value?: string | null;
        isOpenInANewTab?: boolean | null;
      } | null;
      miniCtaButton?: {
        __typename: 'miniCtaButtonConfig';
        enabled?: boolean | null;
        text?: string | null;
      } | null;
    } | null;
    installationCompleted?: boolean | null;
    installStep?: string | null;
    discover?: boolean | null;
    useCaseName?: string | null;
    heroSettings?: string | null;
    sourceProjectId?: string | null;
    emailSettings?: string | null;
    videoCollectionCampaignSettings?: string | null;
    secretVideoSettings?: string | null;
    integrationSettings?: string | null;
    updatedAt: string;
  } | null;
};

export type OnDeleteProjectSubscriptionVariables = {};

export type OnDeleteProjectSubscription = {
  onDeleteProject?: {
    __typename: 'project';
    id: string;
    owner?: string | null;
    appKey?: string | null;
    typeKey?: string | null;
    createdAt?: string | null;
    publishId: string;
    name: string;
    folder?: string | null;
    targetPage?: string | null;
    verticalOrientation?: boolean | null;
    widgetPosition?: string | null;
    widgetHeader?: string | null;
    widgetUrls?: Array<string | null> | null;
    description?: string | null;
    emailNotifications?: Array<string | null> | null;
    notificationLevel?: number | null;
    viewerNotificationLevel?: number | null;
    chaptersEnabled?: boolean | null;
    subtitlesEnabled?: boolean | null;
    stepsOrder?: Array<string | null> | null;
    collectText?: string | null;
    collectFields?: Array<{
      __typename: 'inputField';
      key?: string | null;
      name?: string | null;
      type?: string | null;
      placeholder?: string | null;
      link?: string | null;
      required?: boolean | null;
      collect?: boolean | null;
      custom?: boolean | null;
      textColor?: string | null;
      borderColor?: string | null;
      backgroundColor?: string | null;
    } | null> | null;
    collectAfterStep?: Array<string | null> | null;
    cacheLeadFormAnswers?: boolean | null;
    googleAnalyticsID?: string | null;
    facebookAnalyticsID?: string | null;
    totalResponses?: number | null;
    unreadResponses?: number | null;
    tolstoyType?: string | null;
    steps?: {
      __typename: 'ModelprojectStepConnection';
      items: Array<{
        __typename: 'projectStep';
        id: string;
        owner?: string | null;
        appKey?: string | null;
        projectId: string;
        videoId?: string | null;
        name: string;
        type?: string | null;
        description?: string | null;
        script?: string | null;
        question?: string | null;
        questionVisible?: boolean | null;
        answerTime?: number | null;
        answers?: Array<{
          __typename: 'answer';
          key?: string | null;
          text?: string | null;
          next?: string | null;
          value?: string | null;
          type?: string | null;
          disabled?: boolean | null;
        } | null> | null;
        products?: Array<{
          __typename: 'product';
          id?: string | null;
        } | null> | null;
        productsAppUrl?: string | null;
        videoContain?: boolean | null;
        overlayText?: string | null;
        overlayTextColor?: string | null;
        multipleChoiceSelection?: boolean | null;
        defaultResponses?: Array<{
          __typename: 'answer';
          key?: string | null;
          text?: string | null;
          next?: string | null;
          value?: string | null;
          type?: string | null;
          disabled?: boolean | null;
        } | null> | null;
        defaultResponsesEnabled?: boolean | null;
        sideBarButtonsEnabled?: boolean | null;
        feedProductActionButton?: {
          __typename: 'feedProductActionButton';
          type?: string | null;
          url?: string | null;
          text?: string | null;
          isOpenInANewTab?: boolean | null;
          value?: string | null;
          enabled?: boolean | null;
        } | null;
        feedSidePanelType?: FeedSidePanelType | null;
        feedDescriptionActionButton?: {
          __typename: 'feedProductActionButton';
          type?: string | null;
          url?: string | null;
          text?: string | null;
          isOpenInANewTab?: boolean | null;
          value?: string | null;
          enabled?: boolean | null;
        } | null;
        feedSidePanelDescription?: string | null;
        productCtaType?: ProductCtaType | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    stepsCount?: number | null;
    widgetBorderColor?: string | null;
    widgetShape?: string | null;
    widgetSize?: string | null;
    widgetNotificationBadge?: boolean | null;
    videoResolutions?: Array<string | null> | null;
    fastForwardEnabled?: boolean | null;
    rulesEnabled?: boolean | null;
    macro?: boolean | null;
    hideChatPlatform?: boolean | null;
    private?: boolean | null;
    chatLandingPage?: boolean | null;
    widgetSettings?: string | null;
    notificationSettingsId?: string | null;
    collectAfterAnyResponse?: boolean | null;
    startScreenText?: string | null;
    feed?: boolean | null;
    feedAutoScroll?: boolean | null;
    feedShowUnviewedStepsFirst?: boolean | null;
    language?: string | null;
    showButtonsIndex?: boolean | null;
    publishMethod?: publishMethodOptions | null;
    dynamic?: boolean | null;
    feedProductCtaType?: ProductCtaType | null;
    playerSettings?: string | null;
    appUrl?: string | null;
    live?: boolean | null;
    primaryButton?: {
      __typename: 'ctaButtonConfig';
      enabled?: boolean | null;
      ctaType?: ProductCtaType | null;
      buttonText?: string | null;
      value?: string | null;
      isOpenInANewTab?: boolean | null;
    } | null;
    secondaryButton?: {
      __typename: 'ctaButtonConfig';
      enabled?: boolean | null;
      ctaType?: ProductCtaType | null;
      buttonText?: string | null;
      value?: string | null;
      isOpenInANewTab?: boolean | null;
    } | null;
    pdpConfig?: {
      __typename: 'pdpConfig';
      primaryButton?: {
        __typename: 'ctaButtonConfig';
        enabled?: boolean | null;
        ctaType?: ProductCtaType | null;
        buttonText?: string | null;
        value?: string | null;
        isOpenInANewTab?: boolean | null;
      } | null;
      secondaryButton?: {
        __typename: 'ctaButtonConfig';
        enabled?: boolean | null;
        ctaType?: ProductCtaType | null;
        buttonText?: string | null;
        value?: string | null;
        isOpenInANewTab?: boolean | null;
      } | null;
      miniCtaButton?: {
        __typename: 'miniCtaButtonConfig';
        enabled?: boolean | null;
        text?: string | null;
      } | null;
    } | null;
    installationCompleted?: boolean | null;
    installStep?: string | null;
    discover?: boolean | null;
    useCaseName?: string | null;
    heroSettings?: string | null;
    sourceProjectId?: string | null;
    emailSettings?: string | null;
    videoCollectionCampaignSettings?: string | null;
    secretVideoSettings?: string | null;
    integrationSettings?: string | null;
    updatedAt: string;
  } | null;
};

export type OnCreateProjectStepSubscriptionVariables = {};

export type OnCreateProjectStepSubscription = {
  onCreateProjectStep?: {
    __typename: 'projectStep';
    id: string;
    owner?: string | null;
    appKey?: string | null;
    projectId: string;
    videoId?: string | null;
    name: string;
    type?: string | null;
    description?: string | null;
    script?: string | null;
    question?: string | null;
    questionVisible?: boolean | null;
    answerTime?: number | null;
    answers?: Array<{
      __typename: 'answer';
      key?: string | null;
      text?: string | null;
      next?: string | null;
      value?: string | null;
      type?: string | null;
      disabled?: boolean | null;
    } | null> | null;
    products?: Array<{
      __typename: 'product';
      id?: string | null;
    } | null> | null;
    productsAppUrl?: string | null;
    videoContain?: boolean | null;
    overlayText?: string | null;
    overlayTextColor?: string | null;
    multipleChoiceSelection?: boolean | null;
    defaultResponses?: Array<{
      __typename: 'answer';
      key?: string | null;
      text?: string | null;
      next?: string | null;
      value?: string | null;
      type?: string | null;
      disabled?: boolean | null;
    } | null> | null;
    defaultResponsesEnabled?: boolean | null;
    sideBarButtonsEnabled?: boolean | null;
    feedProductActionButton?: {
      __typename: 'feedProductActionButton';
      type?: string | null;
      url?: string | null;
      text?: string | null;
      isOpenInANewTab?: boolean | null;
      value?: string | null;
      enabled?: boolean | null;
    } | null;
    feedSidePanelType?: FeedSidePanelType | null;
    feedDescriptionActionButton?: {
      __typename: 'feedProductActionButton';
      type?: string | null;
      url?: string | null;
      text?: string | null;
      isOpenInANewTab?: boolean | null;
      value?: string | null;
      enabled?: boolean | null;
    } | null;
    feedSidePanelDescription?: string | null;
    productCtaType?: ProductCtaType | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateProjectStepSubscriptionVariables = {};

export type OnUpdateProjectStepSubscription = {
  onUpdateProjectStep?: {
    __typename: 'projectStep';
    id: string;
    owner?: string | null;
    appKey?: string | null;
    projectId: string;
    videoId?: string | null;
    name: string;
    type?: string | null;
    description?: string | null;
    script?: string | null;
    question?: string | null;
    questionVisible?: boolean | null;
    answerTime?: number | null;
    answers?: Array<{
      __typename: 'answer';
      key?: string | null;
      text?: string | null;
      next?: string | null;
      value?: string | null;
      type?: string | null;
      disabled?: boolean | null;
    } | null> | null;
    products?: Array<{
      __typename: 'product';
      id?: string | null;
    } | null> | null;
    productsAppUrl?: string | null;
    videoContain?: boolean | null;
    overlayText?: string | null;
    overlayTextColor?: string | null;
    multipleChoiceSelection?: boolean | null;
    defaultResponses?: Array<{
      __typename: 'answer';
      key?: string | null;
      text?: string | null;
      next?: string | null;
      value?: string | null;
      type?: string | null;
      disabled?: boolean | null;
    } | null> | null;
    defaultResponsesEnabled?: boolean | null;
    sideBarButtonsEnabled?: boolean | null;
    feedProductActionButton?: {
      __typename: 'feedProductActionButton';
      type?: string | null;
      url?: string | null;
      text?: string | null;
      isOpenInANewTab?: boolean | null;
      value?: string | null;
      enabled?: boolean | null;
    } | null;
    feedSidePanelType?: FeedSidePanelType | null;
    feedDescriptionActionButton?: {
      __typename: 'feedProductActionButton';
      type?: string | null;
      url?: string | null;
      text?: string | null;
      isOpenInANewTab?: boolean | null;
      value?: string | null;
      enabled?: boolean | null;
    } | null;
    feedSidePanelDescription?: string | null;
    productCtaType?: ProductCtaType | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteProjectStepSubscriptionVariables = {};

export type OnDeleteProjectStepSubscription = {
  onDeleteProjectStep?: {
    __typename: 'projectStep';
    id: string;
    owner?: string | null;
    appKey?: string | null;
    projectId: string;
    videoId?: string | null;
    name: string;
    type?: string | null;
    description?: string | null;
    script?: string | null;
    question?: string | null;
    questionVisible?: boolean | null;
    answerTime?: number | null;
    answers?: Array<{
      __typename: 'answer';
      key?: string | null;
      text?: string | null;
      next?: string | null;
      value?: string | null;
      type?: string | null;
      disabled?: boolean | null;
    } | null> | null;
    products?: Array<{
      __typename: 'product';
      id?: string | null;
    } | null> | null;
    productsAppUrl?: string | null;
    videoContain?: boolean | null;
    overlayText?: string | null;
    overlayTextColor?: string | null;
    multipleChoiceSelection?: boolean | null;
    defaultResponses?: Array<{
      __typename: 'answer';
      key?: string | null;
      text?: string | null;
      next?: string | null;
      value?: string | null;
      type?: string | null;
      disabled?: boolean | null;
    } | null> | null;
    defaultResponsesEnabled?: boolean | null;
    sideBarButtonsEnabled?: boolean | null;
    feedProductActionButton?: {
      __typename: 'feedProductActionButton';
      type?: string | null;
      url?: string | null;
      text?: string | null;
      isOpenInANewTab?: boolean | null;
      value?: string | null;
      enabled?: boolean | null;
    } | null;
    feedSidePanelType?: FeedSidePanelType | null;
    feedDescriptionActionButton?: {
      __typename: 'feedProductActionButton';
      type?: string | null;
      url?: string | null;
      text?: string | null;
      isOpenInANewTab?: boolean | null;
      value?: string | null;
      enabled?: boolean | null;
    } | null;
    feedSidePanelDescription?: string | null;
    productCtaType?: ProductCtaType | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateUserSettingsSubscriptionVariables = {};

export type OnCreateUserSettingsSubscription = {
  onCreateUserSettings?: {
    __typename: 'userSettings';
    id: string;
    owner?: string | null;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    lastLogin?: string | null;
    source?: string | null;
    token?: string | null;
    appKey?: string | null;
    hasLoggedIn?: boolean | null;
    hasShared?: boolean | null;
    viewedTolstoys?: Array<string | null> | null;
    calendars?: Array<{
      __typename: 'calendar';
      id?: string | null;
      name?: string | null;
      url?: string | null;
    } | null> | null;
    emailAutomationTool?: string | null;
    logoSettings?: string | null;
    googleInfo?: string | null;
    crmData?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateUserSettingsSubscriptionVariables = {};

export type OnUpdateUserSettingsSubscription = {
  onUpdateUserSettings?: {
    __typename: 'userSettings';
    id: string;
    owner?: string | null;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    lastLogin?: string | null;
    source?: string | null;
    token?: string | null;
    appKey?: string | null;
    hasLoggedIn?: boolean | null;
    hasShared?: boolean | null;
    viewedTolstoys?: Array<string | null> | null;
    calendars?: Array<{
      __typename: 'calendar';
      id?: string | null;
      name?: string | null;
      url?: string | null;
    } | null> | null;
    emailAutomationTool?: string | null;
    logoSettings?: string | null;
    googleInfo?: string | null;
    crmData?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteUserSettingsSubscriptionVariables = {};

export type OnDeleteUserSettingsSubscription = {
  onDeleteUserSettings?: {
    __typename: 'userSettings';
    id: string;
    owner?: string | null;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    lastLogin?: string | null;
    source?: string | null;
    token?: string | null;
    appKey?: string | null;
    hasLoggedIn?: boolean | null;
    hasShared?: boolean | null;
    viewedTolstoys?: Array<string | null> | null;
    calendars?: Array<{
      __typename: 'calendar';
      id?: string | null;
      name?: string | null;
      url?: string | null;
    } | null> | null;
    emailAutomationTool?: string | null;
    logoSettings?: string | null;
    googleInfo?: string | null;
    crmData?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateAccountSettingsSubscriptionVariables = {};

export type OnCreateAccountSettingsSubscription = {
  onCreateAccountSettings?: {
    __typename: 'accountSettings';
    id: string;
    appKey: string;
    owner?: string | null;
    name?: string | null;
    logoSettings?: string | null;
    widgetSettings?: string | null;
    downgradeInfo?: string | null;
    liveIds?: Array<string | null> | null;
    url?: string | null;
    logoUrl?: string | null;
    playerLogoUrl?: string | null;
    companyVertical?: string | null;
    companySize?: string | null;
    companyRole?: string | null;
    monthlyTraffic?: string | null;
    useCase?: string | null;
    packageId?: string | null;
    token?: string | null;
    paymentProvider?: string | null;
    paymentSubscriptions?: Array<string | null> | null;
    paymentIdentifier?: string | null;
    packageGroup?: string | null;
    joinedPackageAt?: string | null;
    trialEndAt?: string | null;
    referral?: string | null;
    crmData?: string | null;
    trialDays?: number | null;
    googleAnalyticsID?: string | null;
    appsInstallationStatuses?: string | null;
    onboardingStatuses?: string | null;
    assetAnalysis?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateAccountSettingsSubscriptionVariables = {};

export type OnUpdateAccountSettingsSubscription = {
  onUpdateAccountSettings?: {
    __typename: 'accountSettings';
    id: string;
    appKey: string;
    owner?: string | null;
    name?: string | null;
    logoSettings?: string | null;
    widgetSettings?: string | null;
    downgradeInfo?: string | null;
    liveIds?: Array<string | null> | null;
    url?: string | null;
    logoUrl?: string | null;
    playerLogoUrl?: string | null;
    companyVertical?: string | null;
    companySize?: string | null;
    companyRole?: string | null;
    monthlyTraffic?: string | null;
    useCase?: string | null;
    packageId?: string | null;
    token?: string | null;
    paymentProvider?: string | null;
    paymentSubscriptions?: Array<string | null> | null;
    paymentIdentifier?: string | null;
    packageGroup?: string | null;
    joinedPackageAt?: string | null;
    trialEndAt?: string | null;
    referral?: string | null;
    crmData?: string | null;
    trialDays?: number | null;
    googleAnalyticsID?: string | null;
    appsInstallationStatuses?: string | null;
    onboardingStatuses?: string | null;
    assetAnalysis?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteAccountSettingsSubscriptionVariables = {};

export type OnDeleteAccountSettingsSubscription = {
  onDeleteAccountSettings?: {
    __typename: 'accountSettings';
    id: string;
    appKey: string;
    owner?: string | null;
    name?: string | null;
    logoSettings?: string | null;
    widgetSettings?: string | null;
    downgradeInfo?: string | null;
    liveIds?: Array<string | null> | null;
    url?: string | null;
    logoUrl?: string | null;
    playerLogoUrl?: string | null;
    companyVertical?: string | null;
    companySize?: string | null;
    companyRole?: string | null;
    monthlyTraffic?: string | null;
    useCase?: string | null;
    packageId?: string | null;
    token?: string | null;
    paymentProvider?: string | null;
    paymentSubscriptions?: Array<string | null> | null;
    paymentIdentifier?: string | null;
    packageGroup?: string | null;
    joinedPackageAt?: string | null;
    trialEndAt?: string | null;
    referral?: string | null;
    crmData?: string | null;
    trialDays?: number | null;
    googleAnalyticsID?: string | null;
    appsInstallationStatuses?: string | null;
    onboardingStatuses?: string | null;
    assetAnalysis?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateAppSubscriptionVariables = {};

export type OnCreateAppSubscription = {
  onCreateApp?: {
    __typename: 'app';
    id: string;
    owner?: string | null;
    appKey?: string | null;
    app?: string | null;
    active?: boolean | null;
    data?: string | null;
    token?: string | null;
    appExternalId?: string | null;
    appUrl?: string | null;
    importSettings?: ImportSettings | null;
    importRange?: number | null;
    syncTagsMode?: string | null;
    syndicateBy?: SyndicateBy | null;
    googleAnalyticsID?: string | null;
    settings?: string | null;
    isShopAppEligible?: boolean | null;
    taxonomyId?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateAppSubscriptionVariables = {};

export type OnUpdateAppSubscription = {
  onUpdateApp?: {
    __typename: 'app';
    id: string;
    owner?: string | null;
    appKey?: string | null;
    app?: string | null;
    active?: boolean | null;
    data?: string | null;
    token?: string | null;
    appExternalId?: string | null;
    appUrl?: string | null;
    importSettings?: ImportSettings | null;
    importRange?: number | null;
    syncTagsMode?: string | null;
    syndicateBy?: SyndicateBy | null;
    googleAnalyticsID?: string | null;
    settings?: string | null;
    isShopAppEligible?: boolean | null;
    taxonomyId?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteAppSubscriptionVariables = {};

export type OnDeleteAppSubscription = {
  onDeleteApp?: {
    __typename: 'app';
    id: string;
    owner?: string | null;
    appKey?: string | null;
    app?: string | null;
    active?: boolean | null;
    data?: string | null;
    token?: string | null;
    appExternalId?: string | null;
    appUrl?: string | null;
    importSettings?: ImportSettings | null;
    importRange?: number | null;
    syncTagsMode?: string | null;
    syndicateBy?: SyndicateBy | null;
    googleAnalyticsID?: string | null;
    settings?: string | null;
    isShopAppEligible?: boolean | null;
    taxonomyId?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateProjectAppSubscriptionVariables = {};

export type OnCreateProjectAppSubscription = {
  onCreateProjectApp?: {
    __typename: 'projectApp';
    id: string;
    appId?: string | null;
    appKey?: string | null;
    projectId?: string | null;
    owner?: string | null;
    active?: boolean | null;
    data?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateProjectAppSubscriptionVariables = {};

export type OnUpdateProjectAppSubscription = {
  onUpdateProjectApp?: {
    __typename: 'projectApp';
    id: string;
    appId?: string | null;
    appKey?: string | null;
    projectId?: string | null;
    owner?: string | null;
    active?: boolean | null;
    data?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteProjectAppSubscriptionVariables = {};

export type OnDeleteProjectAppSubscription = {
  onDeleteProjectApp?: {
    __typename: 'projectApp';
    id: string;
    appId?: string | null;
    appKey?: string | null;
    projectId?: string | null;
    owner?: string | null;
    active?: boolean | null;
    data?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateResponseSubscriptionVariables = {};

export type OnCreateResponseSubscription = {
  onCreateResponse?: {
    __typename: 'response';
    id: string;
    owner?: string | null;
    appKey?: string | null;
    stepName?: string | null;
    projectId?: string | null;
    anonymousId?: string | null;
    sessionId?: string | null;
    type?: string | null;
    value?: string | null;
    email?: string | null;
    name?: string | null;
    phone?: string | null;
    createdAt: string;
    read?: boolean | null;
    collectInfo?: string | null;
    answerType?: string | null;
    parentUrl?: string | null;
    isProfanity?: boolean | null;
    attachments?: Array<{
      __typename: 'attachment';
      fileName?: string | null;
      url?: string | null;
      type?: string | null;
    } | null> | null;
    updatedAt: string;
  } | null;
};

export type OnUpdateResponseSubscriptionVariables = {};

export type OnUpdateResponseSubscription = {
  onUpdateResponse?: {
    __typename: 'response';
    id: string;
    owner?: string | null;
    appKey?: string | null;
    stepName?: string | null;
    projectId?: string | null;
    anonymousId?: string | null;
    sessionId?: string | null;
    type?: string | null;
    value?: string | null;
    email?: string | null;
    name?: string | null;
    phone?: string | null;
    createdAt: string;
    read?: boolean | null;
    collectInfo?: string | null;
    answerType?: string | null;
    parentUrl?: string | null;
    isProfanity?: boolean | null;
    attachments?: Array<{
      __typename: 'attachment';
      fileName?: string | null;
      url?: string | null;
      type?: string | null;
    } | null> | null;
    updatedAt: string;
  } | null;
};

export type OnDeleteResponseSubscriptionVariables = {};

export type OnDeleteResponseSubscription = {
  onDeleteResponse?: {
    __typename: 'response';
    id: string;
    owner?: string | null;
    appKey?: string | null;
    stepName?: string | null;
    projectId?: string | null;
    anonymousId?: string | null;
    sessionId?: string | null;
    type?: string | null;
    value?: string | null;
    email?: string | null;
    name?: string | null;
    phone?: string | null;
    createdAt: string;
    read?: boolean | null;
    collectInfo?: string | null;
    answerType?: string | null;
    parentUrl?: string | null;
    isProfanity?: boolean | null;
    attachments?: Array<{
      __typename: 'attachment';
      fileName?: string | null;
      url?: string | null;
      type?: string | null;
    } | null> | null;
    updatedAt: string;
  } | null;
};

export type OnCreateInAppNotificationSubscriptionVariables = {};

export type OnCreateInAppNotificationSubscription = {
  onCreateInAppNotification?: {
    __typename: 'inAppNotification';
    id: string;
    owner?: string | null;
    teamMemberId?: string | null;
    projectId?: string | null;
    sessionId?: string | null;
    type?: string | null;
    value?: string | null;
    email?: string | null;
    createdAt: string;
    read?: boolean | null;
    expirationUnixTime: number;
    token?: string | null;
    stepName?: string | null;
    updatedAt: string;
  } | null;
};

export type OnUpdateInAppNotificationSubscriptionVariables = {};

export type OnUpdateInAppNotificationSubscription = {
  onUpdateInAppNotification?: {
    __typename: 'inAppNotification';
    id: string;
    owner?: string | null;
    teamMemberId?: string | null;
    projectId?: string | null;
    sessionId?: string | null;
    type?: string | null;
    value?: string | null;
    email?: string | null;
    createdAt: string;
    read?: boolean | null;
    expirationUnixTime: number;
    token?: string | null;
    stepName?: string | null;
    updatedAt: string;
  } | null;
};

export type OnDeleteInAppNotificationSubscriptionVariables = {};

export type OnDeleteInAppNotificationSubscription = {
  onDeleteInAppNotification?: {
    __typename: 'inAppNotification';
    id: string;
    owner?: string | null;
    teamMemberId?: string | null;
    projectId?: string | null;
    sessionId?: string | null;
    type?: string | null;
    value?: string | null;
    email?: string | null;
    createdAt: string;
    read?: boolean | null;
    expirationUnixTime: number;
    token?: string | null;
    stepName?: string | null;
    updatedAt: string;
  } | null;
};

export type OnCreateConferenceRoomConnectionSubscriptionVariables = {};

export type OnCreateConferenceRoomConnectionSubscription = {
  onCreateConferenceRoomConnection?: {
    __typename: 'conferenceRoomConnection';
    id: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateConferenceRoomConnectionSubscriptionVariables = {};

export type OnUpdateConferenceRoomConnectionSubscription = {
  onUpdateConferenceRoomConnection?: {
    __typename: 'conferenceRoomConnection';
    id: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteConferenceRoomConnectionSubscriptionVariables = {};

export type OnDeleteConferenceRoomConnectionSubscription = {
  onDeleteConferenceRoomConnection?: {
    __typename: 'conferenceRoomConnection';
    id: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateConferenceRoomSubscriptionVariables = {};

export type OnCreateConferenceRoomSubscription = {
  onCreateConferenceRoom?: {
    __typename: 'conferenceRoom';
    id: string;
    owner: string;
    roomId: string;
    userId: string;
    active?: boolean | null;
    email?: string | null;
    appToken?: string | null;
    lastSeenAt?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateConferenceRoomSubscriptionVariables = {};

export type OnUpdateConferenceRoomSubscription = {
  onUpdateConferenceRoom?: {
    __typename: 'conferenceRoom';
    id: string;
    owner: string;
    roomId: string;
    userId: string;
    active?: boolean | null;
    email?: string | null;
    appToken?: string | null;
    lastSeenAt?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteConferenceRoomSubscriptionVariables = {};

export type OnDeleteConferenceRoomSubscription = {
  onDeleteConferenceRoom?: {
    __typename: 'conferenceRoom';
    id: string;
    owner: string;
    roomId: string;
    userId: string;
    active?: boolean | null;
    email?: string | null;
    appToken?: string | null;
    lastSeenAt?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateConferenceRoomActivitySubscriptionVariables = {};

export type OnCreateConferenceRoomActivitySubscription = {
  onCreateConferenceRoomActivity?: {
    __typename: 'conferenceRoomActivity';
    id: string;
    roomName?: string | null;
    createdAt: string;
    expirationUnixTime: number;
    updatedAt?: string | null;
    appToken?: string | null;
    type?: string | null;
  } | null;
};

export type OnUpdateConferenceRoomActivitySubscriptionVariables = {};

export type OnUpdateConferenceRoomActivitySubscription = {
  onUpdateConferenceRoomActivity?: {
    __typename: 'conferenceRoomActivity';
    id: string;
    roomName?: string | null;
    createdAt: string;
    expirationUnixTime: number;
    updatedAt?: string | null;
    appToken?: string | null;
    type?: string | null;
  } | null;
};

export type OnDeleteConferenceRoomActivitySubscriptionVariables = {};

export type OnDeleteConferenceRoomActivitySubscription = {
  onDeleteConferenceRoomActivity?: {
    __typename: 'conferenceRoomActivity';
    id: string;
    roomName?: string | null;
    createdAt: string;
    expirationUnixTime: number;
    updatedAt?: string | null;
    appToken?: string | null;
    type?: string | null;
  } | null;
};

export type OnCreateSessionSubscriptionVariables = {};

export type OnCreateSessionSubscription = {
  onCreateSession?: {
    __typename: 'session';
    id: string;
    owner?: string | null;
    assignee?: string | null;
    appKey?: string | null;
    projectId?: string | null;
    createdAt: string;
    updatedAt: string;
    lastEventAt: string;
    sessionTime?: string | null;
    name?: string | null;
    email?: string | null;
    phone?: string | null;
    read?: boolean | null;
    archived?: boolean | null;
    actionNeeded?: boolean | null;
    totalEvents?: number | null;
    totalResponses?: number | null;
    totalReplies?: number | null;
    parentUrl?: string | null;
    videoResponse?: boolean | null;
    imageResponse?: boolean | null;
    audioResponse?: boolean | null;
    textResponse?: boolean | null;
    formResponse?: boolean | null;
    reachedEnd?: boolean | null;
    isSpam?: boolean | null;
  } | null;
};

export type OnUpdateSessionSubscriptionVariables = {};

export type OnUpdateSessionSubscription = {
  onUpdateSession?: {
    __typename: 'session';
    id: string;
    owner?: string | null;
    assignee?: string | null;
    appKey?: string | null;
    projectId?: string | null;
    createdAt: string;
    updatedAt: string;
    lastEventAt: string;
    sessionTime?: string | null;
    name?: string | null;
    email?: string | null;
    phone?: string | null;
    read?: boolean | null;
    archived?: boolean | null;
    actionNeeded?: boolean | null;
    totalEvents?: number | null;
    totalResponses?: number | null;
    totalReplies?: number | null;
    parentUrl?: string | null;
    videoResponse?: boolean | null;
    imageResponse?: boolean | null;
    audioResponse?: boolean | null;
    textResponse?: boolean | null;
    formResponse?: boolean | null;
    reachedEnd?: boolean | null;
    isSpam?: boolean | null;
  } | null;
};

export type OnDeleteSessionSubscriptionVariables = {};

export type OnDeleteSessionSubscription = {
  onDeleteSession?: {
    __typename: 'session';
    id: string;
    owner?: string | null;
    assignee?: string | null;
    appKey?: string | null;
    projectId?: string | null;
    createdAt: string;
    updatedAt: string;
    lastEventAt: string;
    sessionTime?: string | null;
    name?: string | null;
    email?: string | null;
    phone?: string | null;
    read?: boolean | null;
    archived?: boolean | null;
    actionNeeded?: boolean | null;
    totalEvents?: number | null;
    totalResponses?: number | null;
    totalReplies?: number | null;
    parentUrl?: string | null;
    videoResponse?: boolean | null;
    imageResponse?: boolean | null;
    audioResponse?: boolean | null;
    textResponse?: boolean | null;
    formResponse?: boolean | null;
    reachedEnd?: boolean | null;
    isSpam?: boolean | null;
  } | null;
};

export type OnCreateAccountIdentifiedUsersSubscriptionVariables = {};

export type OnCreateAccountIdentifiedUsersSubscription = {
  onCreateAccountIdentifiedUsers?: {
    __typename: 'accountIdentifiedUsers';
    id: string;
    owner?: string | null;
    appKey: string;
    userId?: string | null;
    email?: string | null;
    phone?: string | null;
    name?: string | null;
    lastSeenAt?: string | null;
    firstSeenAt?: string | null;
    signedUpAt?: string | null;
    sessionCount?: number | null;
    customAttributes?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateAccountIdentifiedUsersSubscriptionVariables = {};

export type OnUpdateAccountIdentifiedUsersSubscription = {
  onUpdateAccountIdentifiedUsers?: {
    __typename: 'accountIdentifiedUsers';
    id: string;
    owner?: string | null;
    appKey: string;
    userId?: string | null;
    email?: string | null;
    phone?: string | null;
    name?: string | null;
    lastSeenAt?: string | null;
    firstSeenAt?: string | null;
    signedUpAt?: string | null;
    sessionCount?: number | null;
    customAttributes?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteAccountIdentifiedUsersSubscriptionVariables = {};

export type OnDeleteAccountIdentifiedUsersSubscription = {
  onDeleteAccountIdentifiedUsers?: {
    __typename: 'accountIdentifiedUsers';
    id: string;
    owner?: string | null;
    appKey: string;
    userId?: string | null;
    email?: string | null;
    phone?: string | null;
    name?: string | null;
    lastSeenAt?: string | null;
    firstSeenAt?: string | null;
    signedUpAt?: string | null;
    sessionCount?: number | null;
    customAttributes?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateRuleGroupSubscriptionVariables = {};

export type OnCreateRuleGroupSubscription = {
  onCreateRuleGroup?: {
    __typename: 'ruleGroup';
    id: string;
    appKey: string;
    projectId: string;
    publishId?: string | null;
    owner: string;
    rules: Array<{
      __typename: 'rule';
      key: string;
      type?: string | null;
      condition?: string | null;
      value?: string | null;
      limit?: string | null;
      behaviors?: Array<string | null> | null;
    } | null>;
    enabled?: boolean | null;
    visitorModeEnabled?: boolean | null;
    exitIntent?: boolean | null;
    delayTriggerEnabled?: boolean | null;
    delayTriggerSeconds?: number | null;
    showOnDevice?: string | null;
    showOnDomains?: Array<string | null> | null;
    abTestId?: string | null;
    type?: RuleGroupType | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateRuleGroupSubscriptionVariables = {};

export type OnUpdateRuleGroupSubscription = {
  onUpdateRuleGroup?: {
    __typename: 'ruleGroup';
    id: string;
    appKey: string;
    projectId: string;
    publishId?: string | null;
    owner: string;
    rules: Array<{
      __typename: 'rule';
      key: string;
      type?: string | null;
      condition?: string | null;
      value?: string | null;
      limit?: string | null;
      behaviors?: Array<string | null> | null;
    } | null>;
    enabled?: boolean | null;
    visitorModeEnabled?: boolean | null;
    exitIntent?: boolean | null;
    delayTriggerEnabled?: boolean | null;
    delayTriggerSeconds?: number | null;
    showOnDevice?: string | null;
    showOnDomains?: Array<string | null> | null;
    abTestId?: string | null;
    type?: RuleGroupType | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteRuleGroupSubscriptionVariables = {};

export type OnDeleteRuleGroupSubscription = {
  onDeleteRuleGroup?: {
    __typename: 'ruleGroup';
    id: string;
    appKey: string;
    projectId: string;
    publishId?: string | null;
    owner: string;
    rules: Array<{
      __typename: 'rule';
      key: string;
      type?: string | null;
      condition?: string | null;
      value?: string | null;
      limit?: string | null;
      behaviors?: Array<string | null> | null;
    } | null>;
    enabled?: boolean | null;
    visitorModeEnabled?: boolean | null;
    exitIntent?: boolean | null;
    delayTriggerEnabled?: boolean | null;
    delayTriggerSeconds?: number | null;
    showOnDevice?: string | null;
    showOnDomains?: Array<string | null> | null;
    abTestId?: string | null;
    type?: RuleGroupType | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateIdentifyAttributeSubscriptionVariables = {};

export type OnCreateIdentifyAttributeSubscription = {
  onCreateIdentifyAttribute?: {
    __typename: 'identifyAttribute';
    id: string;
    appKey: string;
    owner: string;
    key?: string | null;
    type?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateIdentifyAttributeSubscriptionVariables = {};

export type OnUpdateIdentifyAttributeSubscription = {
  onUpdateIdentifyAttribute?: {
    __typename: 'identifyAttribute';
    id: string;
    appKey: string;
    owner: string;
    key?: string | null;
    type?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteIdentifyAttributeSubscriptionVariables = {};

export type OnDeleteIdentifyAttributeSubscription = {
  onDeleteIdentifyAttribute?: {
    __typename: 'identifyAttribute';
    id: string;
    appKey: string;
    owner: string;
    key?: string | null;
    type?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateInviteSubscriptionVariables = {};

export type OnCreateInviteSubscription = {
  onCreateInvite?: {
    __typename: 'invite';
    id: string;
    owner: string;
    appKey: string;
    email?: string | null;
    role: Role;
    status: InviteStatus;
    token?: string | null;
    createdAt?: string | null;
    updatedAt: string;
  } | null;
};

export type OnUpdateInviteSubscriptionVariables = {};

export type OnUpdateInviteSubscription = {
  onUpdateInvite?: {
    __typename: 'invite';
    id: string;
    owner: string;
    appKey: string;
    email?: string | null;
    role: Role;
    status: InviteStatus;
    token?: string | null;
    createdAt?: string | null;
    updatedAt: string;
  } | null;
};

export type OnDeleteInviteSubscriptionVariables = {};

export type OnDeleteInviteSubscription = {
  onDeleteInvite?: {
    __typename: 'invite';
    id: string;
    owner: string;
    appKey: string;
    email?: string | null;
    role: Role;
    status: InviteStatus;
    token?: string | null;
    createdAt?: string | null;
    updatedAt: string;
  } | null;
};

export type OnCreateAccountTeamMemberSubscriptionVariables = {};

export type OnCreateAccountTeamMemberSubscription = {
  onCreateAccountTeamMember?: {
    __typename: 'accountTeamMember';
    id: string;
    owner: string;
    appKey: string;
    email?: string | null;
    role: Role;
    status: InviteStatus;
    inviteToken?: string | null;
    createdAt?: string | null;
    userId?: string | null;
    teamMemberId?: string | null;
    lastLoginAt?: string | null;
    user?: {
      __typename: 'ModeluserSettingsConnection';
      items: Array<{
        __typename: 'userSettings';
        id: string;
        owner?: string | null;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        lastLogin?: string | null;
        source?: string | null;
        token?: string | null;
        appKey?: string | null;
        hasLoggedIn?: boolean | null;
        hasShared?: boolean | null;
        viewedTolstoys?: Array<string | null> | null;
        calendars?: Array<{
          __typename: 'calendar';
          id?: string | null;
          name?: string | null;
          url?: string | null;
        } | null> | null;
        emailAutomationTool?: string | null;
        logoSettings?: string | null;
        googleInfo?: string | null;
        crmData?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    account?: {
      __typename: 'ModelaccountSettingsConnection';
      items: Array<{
        __typename: 'accountSettings';
        id: string;
        appKey: string;
        owner?: string | null;
        name?: string | null;
        logoSettings?: string | null;
        widgetSettings?: string | null;
        downgradeInfo?: string | null;
        liveIds?: Array<string | null> | null;
        url?: string | null;
        logoUrl?: string | null;
        playerLogoUrl?: string | null;
        companyVertical?: string | null;
        companySize?: string | null;
        companyRole?: string | null;
        monthlyTraffic?: string | null;
        useCase?: string | null;
        packageId?: string | null;
        token?: string | null;
        paymentProvider?: string | null;
        paymentSubscriptions?: Array<string | null> | null;
        paymentIdentifier?: string | null;
        packageGroup?: string | null;
        joinedPackageAt?: string | null;
        trialEndAt?: string | null;
        referral?: string | null;
        crmData?: string | null;
        trialDays?: number | null;
        googleAnalyticsID?: string | null;
        appsInstallationStatuses?: string | null;
        onboardingStatuses?: string | null;
        assetAnalysis?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    notificationSettingsId?: string | null;
    paymentRole?: PaymentRole | null;
    unreadInAppNotificationsCounter?: number | null;
    updatedAt: string;
  } | null;
};

export type OnUpdateAccountTeamMemberSubscriptionVariables = {};

export type OnUpdateAccountTeamMemberSubscription = {
  onUpdateAccountTeamMember?: {
    __typename: 'accountTeamMember';
    id: string;
    owner: string;
    appKey: string;
    email?: string | null;
    role: Role;
    status: InviteStatus;
    inviteToken?: string | null;
    createdAt?: string | null;
    userId?: string | null;
    teamMemberId?: string | null;
    lastLoginAt?: string | null;
    user?: {
      __typename: 'ModeluserSettingsConnection';
      items: Array<{
        __typename: 'userSettings';
        id: string;
        owner?: string | null;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        lastLogin?: string | null;
        source?: string | null;
        token?: string | null;
        appKey?: string | null;
        hasLoggedIn?: boolean | null;
        hasShared?: boolean | null;
        viewedTolstoys?: Array<string | null> | null;
        calendars?: Array<{
          __typename: 'calendar';
          id?: string | null;
          name?: string | null;
          url?: string | null;
        } | null> | null;
        emailAutomationTool?: string | null;
        logoSettings?: string | null;
        googleInfo?: string | null;
        crmData?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    account?: {
      __typename: 'ModelaccountSettingsConnection';
      items: Array<{
        __typename: 'accountSettings';
        id: string;
        appKey: string;
        owner?: string | null;
        name?: string | null;
        logoSettings?: string | null;
        widgetSettings?: string | null;
        downgradeInfo?: string | null;
        liveIds?: Array<string | null> | null;
        url?: string | null;
        logoUrl?: string | null;
        playerLogoUrl?: string | null;
        companyVertical?: string | null;
        companySize?: string | null;
        companyRole?: string | null;
        monthlyTraffic?: string | null;
        useCase?: string | null;
        packageId?: string | null;
        token?: string | null;
        paymentProvider?: string | null;
        paymentSubscriptions?: Array<string | null> | null;
        paymentIdentifier?: string | null;
        packageGroup?: string | null;
        joinedPackageAt?: string | null;
        trialEndAt?: string | null;
        referral?: string | null;
        crmData?: string | null;
        trialDays?: number | null;
        googleAnalyticsID?: string | null;
        appsInstallationStatuses?: string | null;
        onboardingStatuses?: string | null;
        assetAnalysis?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    notificationSettingsId?: string | null;
    paymentRole?: PaymentRole | null;
    unreadInAppNotificationsCounter?: number | null;
    updatedAt: string;
  } | null;
};

export type OnDeleteAccountTeamMemberSubscriptionVariables = {};

export type OnDeleteAccountTeamMemberSubscription = {
  onDeleteAccountTeamMember?: {
    __typename: 'accountTeamMember';
    id: string;
    owner: string;
    appKey: string;
    email?: string | null;
    role: Role;
    status: InviteStatus;
    inviteToken?: string | null;
    createdAt?: string | null;
    userId?: string | null;
    teamMemberId?: string | null;
    lastLoginAt?: string | null;
    user?: {
      __typename: 'ModeluserSettingsConnection';
      items: Array<{
        __typename: 'userSettings';
        id: string;
        owner?: string | null;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        lastLogin?: string | null;
        source?: string | null;
        token?: string | null;
        appKey?: string | null;
        hasLoggedIn?: boolean | null;
        hasShared?: boolean | null;
        viewedTolstoys?: Array<string | null> | null;
        calendars?: Array<{
          __typename: 'calendar';
          id?: string | null;
          name?: string | null;
          url?: string | null;
        } | null> | null;
        emailAutomationTool?: string | null;
        logoSettings?: string | null;
        googleInfo?: string | null;
        crmData?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    account?: {
      __typename: 'ModelaccountSettingsConnection';
      items: Array<{
        __typename: 'accountSettings';
        id: string;
        appKey: string;
        owner?: string | null;
        name?: string | null;
        logoSettings?: string | null;
        widgetSettings?: string | null;
        downgradeInfo?: string | null;
        liveIds?: Array<string | null> | null;
        url?: string | null;
        logoUrl?: string | null;
        playerLogoUrl?: string | null;
        companyVertical?: string | null;
        companySize?: string | null;
        companyRole?: string | null;
        monthlyTraffic?: string | null;
        useCase?: string | null;
        packageId?: string | null;
        token?: string | null;
        paymentProvider?: string | null;
        paymentSubscriptions?: Array<string | null> | null;
        paymentIdentifier?: string | null;
        packageGroup?: string | null;
        joinedPackageAt?: string | null;
        trialEndAt?: string | null;
        referral?: string | null;
        crmData?: string | null;
        trialDays?: number | null;
        googleAnalyticsID?: string | null;
        appsInstallationStatuses?: string | null;
        onboardingStatuses?: string | null;
        assetAnalysis?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    notificationSettingsId?: string | null;
    paymentRole?: PaymentRole | null;
    unreadInAppNotificationsCounter?: number | null;
    updatedAt: string;
  } | null;
};

export type OnCreateTeamMemberSettingsSubscriptionVariables = {};

export type OnCreateTeamMemberSettingsSubscription = {
  onCreateTeamMemberSettings?: {
    __typename: 'teamMemberSettings';
    id: string;
    teamMemberId?: string | null;
    notificationSettingsId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateTeamMemberSettingsSubscriptionVariables = {};

export type OnUpdateTeamMemberSettingsSubscription = {
  onUpdateTeamMemberSettings?: {
    __typename: 'teamMemberSettings';
    id: string;
    teamMemberId?: string | null;
    notificationSettingsId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteTeamMemberSettingsSubscriptionVariables = {};

export type OnDeleteTeamMemberSettingsSubscription = {
  onDeleteTeamMemberSettings?: {
    __typename: 'teamMemberSettings';
    id: string;
    teamMemberId?: string | null;
    notificationSettingsId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateNotificationSettingsSubscriptionVariables = {};

export type OnCreateNotificationSettingsSubscription = {
  onCreateNotificationSettings?: {
    __typename: 'notificationSettings';
    id: string;
    owner: string;
    projectId?: string | null;
    email?: {
      __typename: 'sharedNotificationSettings';
      showAnonymous?: boolean | null;
      enabled?: Array<string | null> | null;
      digest?: Array<string | null> | null;
    } | null;
    inApp?: {
      __typename: 'sharedNotificationSettings';
      showAnonymous?: boolean | null;
      enabled?: Array<string | null> | null;
      digest?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateNotificationSettingsSubscriptionVariables = {};

export type OnUpdateNotificationSettingsSubscription = {
  onUpdateNotificationSettings?: {
    __typename: 'notificationSettings';
    id: string;
    owner: string;
    projectId?: string | null;
    email?: {
      __typename: 'sharedNotificationSettings';
      showAnonymous?: boolean | null;
      enabled?: Array<string | null> | null;
      digest?: Array<string | null> | null;
    } | null;
    inApp?: {
      __typename: 'sharedNotificationSettings';
      showAnonymous?: boolean | null;
      enabled?: Array<string | null> | null;
      digest?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteNotificationSettingsSubscriptionVariables = {};

export type OnDeleteNotificationSettingsSubscription = {
  onDeleteNotificationSettings?: {
    __typename: 'notificationSettings';
    id: string;
    owner: string;
    projectId?: string | null;
    email?: {
      __typename: 'sharedNotificationSettings';
      showAnonymous?: boolean | null;
      enabled?: Array<string | null> | null;
      digest?: Array<string | null> | null;
    } | null;
    inApp?: {
      __typename: 'sharedNotificationSettings';
      showAnonymous?: boolean | null;
      enabled?: Array<string | null> | null;
      digest?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateFolderSubscriptionVariables = {};

export type OnCreateFolderSubscription = {
  onCreateFolder?: {
    __typename: 'folder';
    id: string;
    owner?: string | null;
    name: string;
    appKey: string;
    private?: boolean | null;
    type?: FolderType | null;
    appUrl?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateFolderSubscriptionVariables = {};

export type OnUpdateFolderSubscription = {
  onUpdateFolder?: {
    __typename: 'folder';
    id: string;
    owner?: string | null;
    name: string;
    appKey: string;
    private?: boolean | null;
    type?: FolderType | null;
    appUrl?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteFolderSubscriptionVariables = {};

export type OnDeleteFolderSubscription = {
  onDeleteFolder?: {
    __typename: 'folder';
    id: string;
    owner?: string | null;
    name: string;
    appKey: string;
    private?: boolean | null;
    type?: FolderType | null;
    appUrl?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateAccountUserSubscriptionVariables = {};

export type OnCreateAccountUserSubscription = {
  onCreateAccountUser?: {
    __typename: 'accountUser';
    id: string;
    appKey: string;
    email?: string | null;
    name?: string | null;
    phone?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    lastSeenAt?: string | null;
    emailVerified?: boolean | null;
    sessionCount?: number | null;
    customAttributes?: string | null;
  } | null;
};

export type OnUpdateAccountUserSubscriptionVariables = {};

export type OnUpdateAccountUserSubscription = {
  onUpdateAccountUser?: {
    __typename: 'accountUser';
    id: string;
    appKey: string;
    email?: string | null;
    name?: string | null;
    phone?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    lastSeenAt?: string | null;
    emailVerified?: boolean | null;
    sessionCount?: number | null;
    customAttributes?: string | null;
  } | null;
};

export type OnDeleteAccountUserSubscriptionVariables = {};

export type OnDeleteAccountUserSubscription = {
  onDeleteAccountUser?: {
    __typename: 'accountUser';
    id: string;
    appKey: string;
    email?: string | null;
    name?: string | null;
    phone?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    lastSeenAt?: string | null;
    emailVerified?: boolean | null;
    sessionCount?: number | null;
    customAttributes?: string | null;
  } | null;
};

export type OnCreateAccountUsageSubscriptionVariables = {};

export type OnCreateAccountUsageSubscription = {
  onCreateAccountUsage?: {
    __typename: 'accountUsage';
    id: string;
    appKey: string;
    featureId: string;
    usage?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnUpdateAccountUsageSubscriptionVariables = {};

export type OnUpdateAccountUsageSubscription = {
  onUpdateAccountUsage?: {
    __typename: 'accountUsage';
    id: string;
    appKey: string;
    featureId: string;
    usage?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnDeleteAccountUsageSubscriptionVariables = {};

export type OnDeleteAccountUsageSubscription = {
  onDeleteAccountUsage?: {
    __typename: 'accountUsage';
    id: string;
    appKey: string;
    featureId: string;
    usage?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnCreatePlayerConfigSubscriptionVariables = {};

export type OnCreatePlayerConfigSubscription = {
  onCreatePlayerConfig?: {
    __typename: 'playerConfig';
    id: string;
    appKey: string;
    publishId: string;
    projectId: string;
    config?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdatePlayerConfigSubscriptionVariables = {};

export type OnUpdatePlayerConfigSubscription = {
  onUpdatePlayerConfig?: {
    __typename: 'playerConfig';
    id: string;
    appKey: string;
    publishId: string;
    projectId: string;
    config?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeletePlayerConfigSubscriptionVariables = {};

export type OnDeletePlayerConfigSubscription = {
  onDeletePlayerConfig?: {
    __typename: 'playerConfig';
    id: string;
    appKey: string;
    publishId: string;
    projectId: string;
    config?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateWidgetConfigSubscriptionVariables = {};

export type OnCreateWidgetConfigSubscription = {
  onCreateWidgetConfig?: {
    __typename: 'widgetConfig';
    id: string;
    appKey: string;
    publishId: string;
    projectId: string;
    config?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateWidgetConfigSubscriptionVariables = {};

export type OnUpdateWidgetConfigSubscription = {
  onUpdateWidgetConfig?: {
    __typename: 'widgetConfig';
    id: string;
    appKey: string;
    publishId: string;
    projectId: string;
    config?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteWidgetConfigSubscriptionVariables = {};

export type OnDeleteWidgetConfigSubscription = {
  onDeleteWidgetConfig?: {
    __typename: 'widgetConfig';
    id: string;
    appKey: string;
    publishId: string;
    projectId: string;
    config?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateAccountPackageSubscriptionVariables = {};

export type OnCreateAccountPackageSubscription = {
  onCreateAccountPackage?: {
    __typename: 'accountPackage';
    id: string;
    appKey: string;
    packageId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    app?: TolstoyApp | null;
    featuresPackageId?: string | null;
    group?: string | null;
  } | null;
};

export type OnUpdateAccountPackageSubscriptionVariables = {};

export type OnUpdateAccountPackageSubscription = {
  onUpdateAccountPackage?: {
    __typename: 'accountPackage';
    id: string;
    appKey: string;
    packageId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    app?: TolstoyApp | null;
    featuresPackageId?: string | null;
    group?: string | null;
  } | null;
};

export type OnDeleteAccountPackageSubscriptionVariables = {};

export type OnDeleteAccountPackageSubscription = {
  onDeleteAccountPackage?: {
    __typename: 'accountPackage';
    id: string;
    appKey: string;
    packageId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    app?: TolstoyApp | null;
    featuresPackageId?: string | null;
    group?: string | null;
  } | null;
};

export type OnCreatePackageSubscriptionVariables = {};

export type OnCreatePackageSubscription = {
  onCreatePackage?: {
    __typename: 'package';
    id: string;
    name?: string | null;
    description?: string | null;
    stripeProducts?: Array<{
      __typename: 'stripeProduct';
      type?: string | null;
      price?: string | null;
    } | null> | null;
    trialPeriodDays?: number | null;
    maxTrialPeriodDays?: number | null;
    type?: string | null;
    details?: string | null;
    group?: string | null;
    order?: number | null;
    featuresPackageId?: string | null;
    addons?: Array<string | null> | null;
    app?: TolstoyApp | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdatePackageSubscriptionVariables = {};

export type OnUpdatePackageSubscription = {
  onUpdatePackage?: {
    __typename: 'package';
    id: string;
    name?: string | null;
    description?: string | null;
    stripeProducts?: Array<{
      __typename: 'stripeProduct';
      type?: string | null;
      price?: string | null;
    } | null> | null;
    trialPeriodDays?: number | null;
    maxTrialPeriodDays?: number | null;
    type?: string | null;
    details?: string | null;
    group?: string | null;
    order?: number | null;
    featuresPackageId?: string | null;
    addons?: Array<string | null> | null;
    app?: TolstoyApp | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeletePackageSubscriptionVariables = {};

export type OnDeletePackageSubscription = {
  onDeletePackage?: {
    __typename: 'package';
    id: string;
    name?: string | null;
    description?: string | null;
    stripeProducts?: Array<{
      __typename: 'stripeProduct';
      type?: string | null;
      price?: string | null;
    } | null> | null;
    trialPeriodDays?: number | null;
    maxTrialPeriodDays?: number | null;
    type?: string | null;
    details?: string | null;
    group?: string | null;
    order?: number | null;
    featuresPackageId?: string | null;
    addons?: Array<string | null> | null;
    app?: TolstoyApp | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateExperimentSubscriptionVariables = {};

export type OnCreateExperimentSubscription = {
  onCreateExperiment?: {
    __typename: 'experiment';
    id: string;
    featureId: string;
    name?: string | null;
    description?: string | null;
    expireAt?: string | null;
    type?: string | null;
    groups?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateExperimentSubscriptionVariables = {};

export type OnUpdateExperimentSubscription = {
  onUpdateExperiment?: {
    __typename: 'experiment';
    id: string;
    featureId: string;
    name?: string | null;
    description?: string | null;
    expireAt?: string | null;
    type?: string | null;
    groups?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteExperimentSubscriptionVariables = {};

export type OnDeleteExperimentSubscription = {
  onDeleteExperiment?: {
    __typename: 'experiment';
    id: string;
    featureId: string;
    name?: string | null;
    description?: string | null;
    expireAt?: string | null;
    type?: string | null;
    groups?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreatePaymentSubscriptionVariables = {};

export type OnCreatePaymentSubscription = {
  onCreatePayment?: {
    __typename: 'payment';
    id: string;
    subscriptionId?: string | null;
    appKey?: string | null;
    reportAt?: string | null;
    usagePrice?: number | null;
    usageQuantity?: number | null;
    type?: string | null;
    provider?: string | null;
    identifier?: string | null;
    packageId?: string | null;
    packageType?: string | null;
    dailyUsagePrice?: number | null;
    aggregatedPrice?: number | null;
    aggregatedUsageQuantity?: number | null;
    dailyUsageQuantity?: number | null;
    seatsPrice?: number | null;
    price?: number | null;
    interval?: string | null;
    numberOfProTeamMembers?: number | null;
    invoiceId?: string | null;
    invoiceItemId?: string | null;
    paymentSubscriptionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdatePaymentSubscriptionVariables = {};

export type OnUpdatePaymentSubscription = {
  onUpdatePayment?: {
    __typename: 'payment';
    id: string;
    subscriptionId?: string | null;
    appKey?: string | null;
    reportAt?: string | null;
    usagePrice?: number | null;
    usageQuantity?: number | null;
    type?: string | null;
    provider?: string | null;
    identifier?: string | null;
    packageId?: string | null;
    packageType?: string | null;
    dailyUsagePrice?: number | null;
    aggregatedPrice?: number | null;
    aggregatedUsageQuantity?: number | null;
    dailyUsageQuantity?: number | null;
    seatsPrice?: number | null;
    price?: number | null;
    interval?: string | null;
    numberOfProTeamMembers?: number | null;
    invoiceId?: string | null;
    invoiceItemId?: string | null;
    paymentSubscriptionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeletePaymentSubscriptionVariables = {};

export type OnDeletePaymentSubscription = {
  onDeletePayment?: {
    __typename: 'payment';
    id: string;
    subscriptionId?: string | null;
    appKey?: string | null;
    reportAt?: string | null;
    usagePrice?: number | null;
    usageQuantity?: number | null;
    type?: string | null;
    provider?: string | null;
    identifier?: string | null;
    packageId?: string | null;
    packageType?: string | null;
    dailyUsagePrice?: number | null;
    aggregatedPrice?: number | null;
    aggregatedUsageQuantity?: number | null;
    dailyUsageQuantity?: number | null;
    seatsPrice?: number | null;
    price?: number | null;
    interval?: string | null;
    numberOfProTeamMembers?: number | null;
    invoiceId?: string | null;
    invoiceItemId?: string | null;
    paymentSubscriptionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateQuizResultSubscriptionVariables = {};

export type OnCreateQuizResultSubscription = {
  onCreateQuizResult?: {
    __typename: 'quizResult';
    id: string;
    projectId: string;
    appKey?: string | null;
    owner?: string | null;
    createdAt?: string | null;
    url?: string | null;
    title?: string | null;
    imageUrl?: string | null;
    flows?: Array<{
      __typename: 'quizFlow';
      id: string;
      type?: string | null;
      rules?: Array<{
        __typename: 'quizRule';
        stepId: string;
        choices?: Array<string | null> | null;
      } | null> | null;
    } | null> | null;
    isFallback?: boolean | null;
    klaviyoListId?: string | null;
    stepName?: string | null;
    type?: ResultType | null;
    updatedAt: string;
  } | null;
};

export type OnUpdateQuizResultSubscriptionVariables = {};

export type OnUpdateQuizResultSubscription = {
  onUpdateQuizResult?: {
    __typename: 'quizResult';
    id: string;
    projectId: string;
    appKey?: string | null;
    owner?: string | null;
    createdAt?: string | null;
    url?: string | null;
    title?: string | null;
    imageUrl?: string | null;
    flows?: Array<{
      __typename: 'quizFlow';
      id: string;
      type?: string | null;
      rules?: Array<{
        __typename: 'quizRule';
        stepId: string;
        choices?: Array<string | null> | null;
      } | null> | null;
    } | null> | null;
    isFallback?: boolean | null;
    klaviyoListId?: string | null;
    stepName?: string | null;
    type?: ResultType | null;
    updatedAt: string;
  } | null;
};

export type OnDeleteQuizResultSubscriptionVariables = {};

export type OnDeleteQuizResultSubscription = {
  onDeleteQuizResult?: {
    __typename: 'quizResult';
    id: string;
    projectId: string;
    appKey?: string | null;
    owner?: string | null;
    createdAt?: string | null;
    url?: string | null;
    title?: string | null;
    imageUrl?: string | null;
    flows?: Array<{
      __typename: 'quizFlow';
      id: string;
      type?: string | null;
      rules?: Array<{
        __typename: 'quizRule';
        stepId: string;
        choices?: Array<string | null> | null;
      } | null> | null;
    } | null> | null;
    isFallback?: boolean | null;
    klaviyoListId?: string | null;
    stepName?: string | null;
    type?: ResultType | null;
    updatedAt: string;
  } | null;
};

export type OnCreateEcomProductSubscriptionVariables = {};

export type OnCreateEcomProductSubscription = {
  onCreateEcomProduct?: {
    __typename: 'ecomProduct';
    id: string;
    productId: string;
    appKey?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateEcomProductSubscriptionVariables = {};

export type OnUpdateEcomProductSubscription = {
  onUpdateEcomProduct?: {
    __typename: 'ecomProduct';
    id: string;
    productId: string;
    appKey?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteEcomProductSubscriptionVariables = {};

export type OnDeleteEcomProductSubscription = {
  onDeleteEcomProduct?: {
    __typename: 'ecomProduct';
    id: string;
    productId: string;
    appKey?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateProductPageSettingsSubscriptionVariables = {};

export type OnCreateProductPageSettingsSubscription = {
  onCreateProductPageSettings?: {
    __typename: 'productPageSettings';
    id: string;
    productId?: string | null;
    productUrl?: string | null;
    abTestId?: string | null;
    publishId?: string | null;
    appKey?: string | null;
    widgetType?: WidgetType | null;
    productSource?: ProductSource | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateProductPageSettingsSubscriptionVariables = {};

export type OnUpdateProductPageSettingsSubscription = {
  onUpdateProductPageSettings?: {
    __typename: 'productPageSettings';
    id: string;
    productId?: string | null;
    productUrl?: string | null;
    abTestId?: string | null;
    publishId?: string | null;
    appKey?: string | null;
    widgetType?: WidgetType | null;
    productSource?: ProductSource | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteProductPageSettingsSubscriptionVariables = {};

export type OnDeleteProductPageSettingsSubscription = {
  onDeleteProductPageSettings?: {
    __typename: 'productPageSettings';
    id: string;
    productId?: string | null;
    productUrl?: string | null;
    abTestId?: string | null;
    publishId?: string | null;
    appKey?: string | null;
    widgetType?: WidgetType | null;
    productSource?: ProductSource | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateAbTestSubscriptionVariables = {};

export type OnCreateAbTestSubscription = {
  onCreateAbTest?: {
    __typename: 'abTest';
    id: string;
    appKey?: string | null;
    owner?: string | null;
    options?: Array<{
      __typename: 'abTestGroup';
      publishId?: string | null;
      probability?: number | null;
    } | null> | null;
    endAt?: string | null;
    startAt?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateAbTestSubscriptionVariables = {};

export type OnUpdateAbTestSubscription = {
  onUpdateAbTest?: {
    __typename: 'abTest';
    id: string;
    appKey?: string | null;
    owner?: string | null;
    options?: Array<{
      __typename: 'abTestGroup';
      publishId?: string | null;
      probability?: number | null;
    } | null> | null;
    endAt?: string | null;
    startAt?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteAbTestSubscriptionVariables = {};

export type OnDeleteAbTestSubscription = {
  onDeleteAbTest?: {
    __typename: 'abTest';
    id: string;
    appKey?: string | null;
    owner?: string | null;
    options?: Array<{
      __typename: 'abTestGroup';
      publishId?: string | null;
      probability?: number | null;
    } | null> | null;
    endAt?: string | null;
    startAt?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateIntegrationVodAssetSubscriptionVariables = {};

export type OnCreateIntegrationVodAssetSubscription = {
  onCreateIntegrationVodAsset?: {
    __typename: 'integrationVodAsset';
    id: string;
    appKey?: string | null;
    owner?: string | null;
    app?: string | null;
    appId?: string | null;
    externalId?: string | null;
    externalCreatedAt?: string | null;
    vodId?: string | null;
    seen?: boolean | null;
    title?: string | null;
    thumbnailUrl?: string | null;
    downloadUrl?: string | null;
    status?: StatusTypes | null;
    createdAt?: string | null;
    updatedAt: string;
  } | null;
};

export type OnUpdateIntegrationVodAssetSubscriptionVariables = {};

export type OnUpdateIntegrationVodAssetSubscription = {
  onUpdateIntegrationVodAsset?: {
    __typename: 'integrationVodAsset';
    id: string;
    appKey?: string | null;
    owner?: string | null;
    app?: string | null;
    appId?: string | null;
    externalId?: string | null;
    externalCreatedAt?: string | null;
    vodId?: string | null;
    seen?: boolean | null;
    title?: string | null;
    thumbnailUrl?: string | null;
    downloadUrl?: string | null;
    status?: StatusTypes | null;
    createdAt?: string | null;
    updatedAt: string;
  } | null;
};

export type OnDeleteIntegrationVodAssetSubscriptionVariables = {};

export type OnDeleteIntegrationVodAssetSubscription = {
  onDeleteIntegrationVodAsset?: {
    __typename: 'integrationVodAsset';
    id: string;
    appKey?: string | null;
    owner?: string | null;
    app?: string | null;
    appId?: string | null;
    externalId?: string | null;
    externalCreatedAt?: string | null;
    vodId?: string | null;
    seen?: boolean | null;
    title?: string | null;
    thumbnailUrl?: string | null;
    downloadUrl?: string | null;
    status?: StatusTypes | null;
    createdAt?: string | null;
    updatedAt: string;
  } | null;
};

export type OnCreateVodConnectionSubscriptionVariables = {};

export type OnCreateVodConnectionSubscription = {
  onCreateVodConnection?: {
    __typename: 'vodConnection';
    id: string;
    appKey?: string | null;
    vodAssetId?: string | null;
    productId?: string | null;
    externalProductId?: string | null;
    collectionId?: string | null;
    provider?: VodConnectionProvider | null;
    tag?: string | null;
    createdAt?: string | null;
    typeKey?: string | null;
    type?: VodConnectionType | null;
    appUrl?: string | null;
    label?: string | null;
    vodLabelId?: string | null;
    vodSource?: string | null;
    vodType?: string | null;
    variantIds?: Array<string | null> | null;
    projectId?: string | null;
    orderIndex?: number | null;
    source?: string | null;
    updatedAt: string;
  } | null;
};

export type OnUpdateVodConnectionSubscriptionVariables = {};

export type OnUpdateVodConnectionSubscription = {
  onUpdateVodConnection?: {
    __typename: 'vodConnection';
    id: string;
    appKey?: string | null;
    vodAssetId?: string | null;
    productId?: string | null;
    externalProductId?: string | null;
    collectionId?: string | null;
    provider?: VodConnectionProvider | null;
    tag?: string | null;
    createdAt?: string | null;
    typeKey?: string | null;
    type?: VodConnectionType | null;
    appUrl?: string | null;
    label?: string | null;
    vodLabelId?: string | null;
    vodSource?: string | null;
    vodType?: string | null;
    variantIds?: Array<string | null> | null;
    projectId?: string | null;
    orderIndex?: number | null;
    source?: string | null;
    updatedAt: string;
  } | null;
};

export type OnDeleteVodConnectionSubscriptionVariables = {};

export type OnDeleteVodConnectionSubscription = {
  onDeleteVodConnection?: {
    __typename: 'vodConnection';
    id: string;
    appKey?: string | null;
    vodAssetId?: string | null;
    productId?: string | null;
    externalProductId?: string | null;
    collectionId?: string | null;
    provider?: VodConnectionProvider | null;
    tag?: string | null;
    createdAt?: string | null;
    typeKey?: string | null;
    type?: VodConnectionType | null;
    appUrl?: string | null;
    label?: string | null;
    vodLabelId?: string | null;
    vodSource?: string | null;
    vodType?: string | null;
    variantIds?: Array<string | null> | null;
    projectId?: string | null;
    orderIndex?: number | null;
    source?: string | null;
    updatedAt: string;
  } | null;
};

export type OnCreateCustomerSubscriptionVariables = {};

export type OnCreateCustomerSubscription = {
  onCreateCustomer?: {
    __typename: 'customer';
    id: string;
    appKey: string;
    appUrl: string;
    externalId?: string | null;
    googleId?: string | null;
    anonymousIds?: Array<string | null> | null;
    email: string;
    phone?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    acceptsMarketing?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateCustomerSubscriptionVariables = {};

export type OnUpdateCustomerSubscription = {
  onUpdateCustomer?: {
    __typename: 'customer';
    id: string;
    appKey: string;
    appUrl: string;
    externalId?: string | null;
    googleId?: string | null;
    anonymousIds?: Array<string | null> | null;
    email: string;
    phone?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    acceptsMarketing?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteCustomerSubscriptionVariables = {};

export type OnDeleteCustomerSubscription = {
  onDeleteCustomer?: {
    __typename: 'customer';
    id: string;
    appKey: string;
    appUrl: string;
    externalId?: string | null;
    googleId?: string | null;
    anonymousIds?: Array<string | null> | null;
    email: string;
    phone?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    acceptsMarketing?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateVodLabelSubscriptionVariables = {};

export type OnCreateVodLabelSubscription = {
  onCreateVodLabel?: {
    __typename: 'vodLabel';
    id: string;
    appKey: string;
    name: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateVodLabelSubscriptionVariables = {};

export type OnUpdateVodLabelSubscription = {
  onUpdateVodLabel?: {
    __typename: 'vodLabel';
    id: string;
    appKey: string;
    name: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteVodLabelSubscriptionVariables = {};

export type OnDeleteVodLabelSubscription = {
  onDeleteVodLabel?: {
    __typename: 'vodLabel';
    id: string;
    appKey: string;
    name: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateSuggestedProductAcceptanceSubscriptionVariables = {};

export type OnCreateSuggestedProductAcceptanceSubscription = {
  onCreateSuggestedProductAcceptance?: {
    __typename: 'suggestedProductAcceptance';
    id: string;
    appKey: string;
    vodAssetId: string;
    externalProductId: string;
    accepted: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateSuggestedProductAcceptanceSubscriptionVariables = {};

export type OnUpdateSuggestedProductAcceptanceSubscription = {
  onUpdateSuggestedProductAcceptance?: {
    __typename: 'suggestedProductAcceptance';
    id: string;
    appKey: string;
    vodAssetId: string;
    externalProductId: string;
    accepted: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteSuggestedProductAcceptanceSubscriptionVariables = {};

export type OnDeleteSuggestedProductAcceptanceSubscription = {
  onDeleteSuggestedProductAcceptance?: {
    __typename: 'suggestedProductAcceptance';
    id: string;
    appKey: string;
    vodAssetId: string;
    externalProductId: string;
    accepted: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateCustomerWishListSubscriptionVariables = {};

export type OnCreateCustomerWishListSubscription = {
  onCreateCustomerWishList?: {
    __typename: 'customerWishList';
    id: string;
    appKey: string;
    appUrl: string;
    vodAssetId: string;
    externalCustomerId?: string | null;
    email?: string | null;
    externalProductId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateCustomerWishListSubscriptionVariables = {};

export type OnUpdateCustomerWishListSubscription = {
  onUpdateCustomerWishList?: {
    __typename: 'customerWishList';
    id: string;
    appKey: string;
    appUrl: string;
    vodAssetId: string;
    externalCustomerId?: string | null;
    email?: string | null;
    externalProductId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteCustomerWishListSubscriptionVariables = {};

export type OnDeleteCustomerWishListSubscription = {
  onDeleteCustomerWishList?: {
    __typename: 'customerWishList';
    id: string;
    appKey: string;
    appUrl: string;
    vodAssetId: string;
    externalCustomerId?: string | null;
    email?: string | null;
    externalProductId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateVodConnectionSuggestionSubscriptionVariables = {};

export type OnCreateVodConnectionSuggestionSubscription = {
  onCreateVodConnectionSuggestion?: {
    __typename: 'vodConnectionSuggestion';
    id: string;
    appKey?: string | null;
    appUrl?: string | null;
    createdAt?: string | null;
    vodAssetId?: string | null;
    externalProductId?: string | null;
    status?: VodConnectionSuggestionStatus | null;
    source?: VodConnectionSuggestionSource | null;
    method?: VodConnectionSuggestionMethod | null;
    methodVersion?: string | null;
    score?: number | null;
    updatedAt: string;
  } | null;
};

export type OnUpdateVodConnectionSuggestionSubscriptionVariables = {};

export type OnUpdateVodConnectionSuggestionSubscription = {
  onUpdateVodConnectionSuggestion?: {
    __typename: 'vodConnectionSuggestion';
    id: string;
    appKey?: string | null;
    appUrl?: string | null;
    createdAt?: string | null;
    vodAssetId?: string | null;
    externalProductId?: string | null;
    status?: VodConnectionSuggestionStatus | null;
    source?: VodConnectionSuggestionSource | null;
    method?: VodConnectionSuggestionMethod | null;
    methodVersion?: string | null;
    score?: number | null;
    updatedAt: string;
  } | null;
};

export type OnDeleteVodConnectionSuggestionSubscriptionVariables = {};

export type OnDeleteVodConnectionSuggestionSubscription = {
  onDeleteVodConnectionSuggestion?: {
    __typename: 'vodConnectionSuggestion';
    id: string;
    appKey?: string | null;
    appUrl?: string | null;
    createdAt?: string | null;
    vodAssetId?: string | null;
    externalProductId?: string | null;
    status?: VodConnectionSuggestionStatus | null;
    source?: VodConnectionSuggestionSource | null;
    method?: VodConnectionSuggestionMethod | null;
    methodVersion?: string | null;
    score?: number | null;
    updatedAt: string;
  } | null;
};
