import React from 'react';

const TextIcon = ({ fill, ...props }) => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.75 2.375C19.75 1.34375 18.9062 0.5 17.875 0.5H2.125C1.04688 0.5 0.25 1.34375 0.25 2.375V4.625C0.25 5.70312 1.04688 6.5 2.125 6.5C3.15625 6.5 4 5.70312 4 4.625V4.25H8.125C8.125 4.34375 8.125 4.39062 8.125 4.4375V18.5H6.25C5.40625 18.5 4.75 19.2031 4.75 20C4.75 20.8438 5.40625 21.5 6.25 21.5H13.75C14.5469 21.5 15.25 20.8438 15.25 20C15.25 19.2031 14.5469 18.5 13.75 18.5H11.875V4.4375C11.875 4.39062 11.8281 4.34375 11.8281 4.25H16V4.625C16 5.70312 16.7969 6.5 17.875 6.5C18.9062 6.5 19.75 5.70312 19.75 4.625V2.375Z"
        fill={fill || 'black'}
      />
    </svg>
  );
};

export default TextIcon;
