import { EXTERNAL_EVENTS_TO_POST } from 'widget/src/constants/externalEvents.constants';
import {
  DATA_TEST_ID,
  TOLSTOY_MODAL_DATA_TEST_ID
} from '../../../cypress/dataTestIds.constants.js';
import {
  getRecreateNewResolution,
  getSessionId,
  removeSessionId,
  setRecreateNewResolution
} from '../../common/cache.js';
import RechargeMessaging from '../../common/recharge.js';
import { createResolutions } from '../../common/requests.js';
import { getHexOpacity } from '../../common/color.js';

import { getFeedXButtonSvg, xButtonsSvg } from '../../common/svgs.js';
import { INTERNAL_EVENTS } from '../../constants/internalEvents.constants.js';
import {
  PRE_CONFIG_MESSAGES,
  SHOW_FEED_CART_MOBILE_MESSAGE,
  SHOW_FEED_PRODUCT_MODAL_MESSAGE,
  TOLSTOY_CLOSE_MODAL_MESSAGE,
  TOLSTOY_ESCAPE_KEY_PRESSED_MESSAGE,
  TOLSTOY_MOVE_TO_URL,
  TOLSTOY_PLAYER_READY_MESSAGE,
  WIDGETS_MESSAGES,
  WIDGET_MESSAGES_EVENTS
} from '../../constants/messages.constants.js';
import { EMBED_WIDGET } from '../../constants/widgetConstants.js';
import {
  handleOpenOrCloseEvent,
  handleVideoPauseAndResumeEvents,
  isTolstoyOpen
} from '../../events/activityEventHelper.js';
import InternalMessaging from '../../messaging/internal/InternalMessaging.js';
import {
  getEmailFromParams,
  getIframeSource,
  getTolstoyAutoOpen,
  getTolstoyStartVideo
} from '../../utils/iframe.utils.js';
import { getIsTheSameURL } from '../../utils/url.utils.js';
import { generateUUID } from '../../utils/utils.js';
import style from './Modal.module.css';
import ModalManager from './ModalManager.js';
import { HIDE_FEED_CLOSE_BUTTON_CLASS } from './constants/classes.constants.js';
import {
  INTERNAL_MESSAGES_TO_SUBSCRIBE,
  EXTERNAL_MESSAGES_TO_SUBSCRIBE
} from './constants/modal.constants.js';

class Modal {
  constructor(data) {
    this.id = generateUUID();
    const autoOpenPublishId = getTolstoyAutoOpen();
    this.isAutoOpen = autoOpenPublishId === data.publishId;
    this.initInternalMessagingSubscriptions();
    this.initExternalMessagingSubscriptions();
    this.autoplay = data.autoplay;
    this.widgetDelay = data.widgetDelay;
    this.isModalCentered = data.centeredModal;
    this.onWidgetOpen = null;
    this.onWidgetClose = null;
    this.modal = document.createElement('div');
    this.modal.ariaLabel = `${data.name} modal`;
    this.modal.setAttribute('role', 'dialog');
    this.modal.ariaModal = 'true';
    this.modal.ariaHidden = 'true';
    this.mainContainer = document.createElement('div');
    this.mainContainer.addEventListener('click', () => {
      this.hide();
    });
    this.email = data.email || '';
    this.publishId = data.publishId;
    this.isOpen = false;
    this.modalWrapperElement = data.modalWrapperElement;
    this.isEmbed = this.getIsEmbedPlayerType(data.playerType);
    this.setDisplaySettings(data);
    this.subscribeForPlayerStart();
    this.preConfigMessages = [];
    this.preConfigMessagingReady = false;
    this.subscribeForPreConfigMessaging();
    this.subscribeToOnKeyPress();
    this.subscribeForWidgetCloseWhenAnotherOpens();
    this.load(data);
    this.modal.setAttribute(DATA_TEST_ID, TOLSTOY_MODAL_DATA_TEST_ID);
    this.subscribeToFeedMessages(data);
    this.subscribeForVideoEvents();
    this.playerReady = false;
    this.playerReadyMessages = [];
    this.lockScreen = this.lockScreen.bind(this);
    this.onTabKey = this.onTabKey.bind(this);
    this.sendAnalyticsData(data);
    this.playerLazy = data.playerLazy;

    this.rechargeMessaging = new RechargeMessaging(this.tolstoyIframe);

    if (this.isAutoOpen) {
      this.open();
    }
  }

  getIsEmbedPlayerType(playerType) {
    return playerType === EMBED_WIDGET;
  }

  postMessageToWindow(type, eventData) {
    window.postMessage({ name: WIDGETS_MESSAGES, type, eventData }, '*');
  }

  setDisplaySettings(data) {
    const isMobile = window.screen.width <= 450 || window.screen.height <= 450;
    this.isMobile = isMobile;

    this.modal.style.display = 'none';
    this.mainContainer.style.display = 'none';

    if (this.isEmbed) {
      this.modal.classList.add(style.tolstoyEmbedModal);
      return;
    }

    this.modal.classList.add(
      'tolstoy-modal',
      this.isModalCentered ? style.tolstoyCenteredModal : style.tolstoyModal
    );

    const isFullscreenFeed = data.feedSettings?.isFullscreenFeed !== false;
    if (data.feed && (isFullscreenFeed || isMobile)) {
      this.modal.classList.add(style.feedModal);
    } else if (data.feed) {
      this.modal.classList.add(style.tolstoyModalVertical);
      this.modal.classList.add(style.feedSmallModal);
    } else if (data.verticalOrientation) {
      this.modal.classList.add(style.tolstoyModalVertical);
      this.modal.classList.add(style.tolstoyModalVerticalSmall);
    } else {
      this.modal.classList.add(style.tolstoyModalHorizontal);
    }

    if (data.widgetPosition === 'bottomLeft') {
      this.modal.classList.add(style.tolstoyModalBottomLeft);
    } else {
      this.modal.classList.add(style.tolstoyModalBottomRight);
    }
  }

  changeEvent(eventType, callback) {
    this[eventType] = callback;
  }

  appendCloseButton(data) {
    if (this.isEmbed) {
      return;
    }

    const {
      feedButtonsBorderRadius,
      opacity = 0.2,
      backgroundColor = '#000000',
      color
    } = data?.design?.player?.controls || {};

    const closeButton = document.createElement('div');
    closeButton.ariaLabel = `${data.name} modal close`;
    closeButton.setAttribute('role', 'button');
    closeButton.setAttribute('tabindex', '0');
    const feedButtonClass = this.isFullscreenFeed
      ? style.feedCloseButton
      : style.mobileFeedCloseButton;
    closeButton.classList.add(
      'minimize-tolstoy-modal',
      this.isFeed ? feedButtonClass : style.closeButton
    );
    closeButton.innerHTML = this.isFeed ? getFeedXButtonSvg(color) : xButtonsSvg;

    closeButton.addEventListener('click', () => {
      this.hide();
      window.postMessage({ name: 'tolstoyModalClose' }, '*');
    });
    this.modal.append(closeButton);

    if (this.isFeed) {
      this.feedXButton = closeButton;
      closeButton.style.setProperty('border-radius', `${feedButtonsBorderRadius}px`, 'important');
      closeButton.style.setProperty('background-color', getHexOpacity(backgroundColor, opacity));
    }
  }

  subscribeToOnKeyPress() {
    window.addEventListener('keydown', event => {
      if (this.isOpen && event.key === 'Escape') {
        this.hide();
      }
    });
  }

  load(data) {
    const isFeed = data.feed;
    const isDynamic = data.dynamic;
    const isFullscreenFeed = data.feedSettings?.isFullscreenFeed !== false;
    this.isFeed = isFeed;
    this.isFullscreenFeed = isFullscreenFeed;
    this.appendCloseButton(data);

    const iframeContainer = document.createElement('div');
    iframeContainer.classList.add(style.iframeContainer);
    this.modal.append(iframeContainer);

    const tolstoyIframe = document.createElement('iframe');
    tolstoyIframe.name = 'tolstoy-modal';
    tolstoyIframe.classList.add(style.tolstoyIframe);
    if (data.verticalOrientation) {
      tolstoyIframe.classList.add(style.tolstoyIframeVertical);
      tolstoyIframe.classList.add(style.closeButtonVertical);
    }

    if (this.isEmbed) {
      tolstoyIframe.classList.add(style.embedTolstoyIframe);
    }

    if (isFeed && (isFullscreenFeed || this.isMobile)) {
      tolstoyIframe.classList.add(style.feedTolstoyIframe);
    }

    tolstoyIframe.allow =
      'autoplay *; clipboard-write *;camera *; microphone *; encrypted-media *; fullscreen *; display-capture *;';
    this.email = this.email || getEmailFromParams();
    const tolstoyStartVideo = getTolstoyStartVideo();

    tolstoyIframe.src = getIframeSource({
      data,
      isFeed,
      email: this.email,
      isDynamic,
      modalId: this.id,
      tolstoyStartVideo: this.isAutoOpen && tolstoyStartVideo
    });
    tolstoyIframe.title = `tolstoy-${data.playerType}`;
    tolstoyIframe.ariaLabel = `Tolstoy Player : ${data.name}`;

    iframeContainer.append(tolstoyIframe);

    tolstoyIframe.addEventListener('load', () => {
      this.removePlayerLoader();
    });

    this.tolstoyIframe = tolstoyIframe;
    ModalManager.registerIframe({ modalId: this.id, modalIframe: tolstoyIframe });
    this.appendPlayerLoader(iframeContainer);
    this.appendHtmlContent();
    this.safePostPreConfigMessage({
      eventName: PRE_CONFIG_MESSAGES.vodAssetIds,
      vodAssetIds: data.vodAssetIds,
      appKey: data.appKey
    });
  }

  appendPlayerLoader(iframeContainer) {
    this.playerLoader = document.createElement('div');
    this.playerLoader.classList.add(style.iframeLoaderContainer);

    const iframeLoaderBox = document.createElement('div');
    iframeLoaderBox.classList.add(style.iframeLoaderBox);

    this.playerLoader.append(iframeLoaderBox);

    const iframeLoader = document.createElement('div');
    iframeLoader.classList.add(style.iframeLoader);

    iframeLoaderBox.append(iframeLoader);

    iframeContainer.append(this.playerLoader);
  }

  removePlayerLoader() {
    if (!this.playerLoader) {
      return;
    }

    this.playerLoader.remove();
    this.playerLoader = null;
  }

  appendHtmlContent() {
    if (this.isModalCentered) {
      this.mainContainer.classList.add('main-container', style.mainContainer);
      this.mainContainer.append(this.modal);
      document.documentElement.append(this.mainContainer);
    } else if (this.isEmbed) {
      this.modalWrapperElement.append(this.modal);
    } else {
      document.documentElement.append(this.modal);
    }
  }

  changeEmail(email) {
    if (email === this.email || !email) {
      return;
    }

    let url = '';
    url = this.email
      ? this.tolstoyIframe.src.replace(this.email, email)
      : this.tolstoyIframe.src + `&email=${email}`;

    this.email = email;
    this.tolstoyIframe.src = url;
  }

  safePostMessageToIframe(message) {
    if (!this.playerReady) {
      return this.playerReadyMessages.push(message);
    }

    this.tolstoyIframe?.contentWindow?.postMessage(message, '*');
  }

  safePostPreConfigMessage(message) {
    if (!this.preConfigMessagingReady) {
      return this.preConfigMessages.push(message);
    }

    this.tolstoyIframe?.contentWindow?.postMessage(message, '*');
  }

  openPlayerModal() {
    this.modal.style.setProperty('display', 'inline', 'important');
    this.mainContainer.style.setProperty('display', 'flex', 'important');
    this.tolstoyIframe.focus();
    this.safePostMessageToIframe('tolstoyModalOpen');
    InternalMessaging.postMessage({ eventName: INTERNAL_EVENTS.modalOpen });
    this.postMessageToWindow(WIDGET_MESSAGES_EVENTS.OPEN, {
      stopDynamicEmbed: this.isFeed && this.isFullscreenFeed,
      publishId: this.publishId,
      hideBubblePreview: !this.isEmbed
    });
    handleOpenOrCloseEvent({
      type: WIDGET_MESSAGES_EVENTS.OPEN,
      publishId: this.publishId
    });
  }

  verifyPostMessage(event) {
    if (!event.data) {
      return false;
    }

    const isModalIframe = event.source === this.tolstoyIframe.contentWindow;
    return isModalIframe;
  }

  subscribeForPlayerStart() {
    window.addEventListener('message', event => {
      if (!this.verifyPostMessage(event)) {
        return;
      }

      if (event.data.eventName === TOLSTOY_PLAYER_READY_MESSAGE) {
        this.playerReady = true;
        for (const message of this.playerReadyMessages || []) {
          this.tolstoyIframe?.contentWindow?.postMessage(message, '*');
        }

        this.playerReadyMessages = [];
      }

      if (event.data.eventName === TOLSTOY_PLAYER_READY_MESSAGE && !this.isOpen && this.autoplay) {
        this.isOpen = true;

        if (!this.widgetDelay) {
          this.startAutoPlay();
        }

        return;
      }

      if (event.data.name === 'tolstoyShowWidget') {
        this.open();
      }
    });
  }

  subscribeForPreConfigMessaging() {
    window.addEventListener('message', event => {
      if (!this.verifyPostMessage(event)) {
        return;
      }

      if (event.data.name === PRE_CONFIG_MESSAGES.ready) {
        this.preConfigMessagingReady = true;
        for (const message of this.preConfigMessages || []) {
          this.tolstoyIframe?.contentWindow?.postMessage(message, '*');
        }

        this.preConfigMessages = [];
      }
    });
  }

  subscribeForWidgetCloseWhenAnotherOpens() {
    window.addEventListener('message', event => {
      const { name, type, eventData } = event.data;

      if (name !== WIDGETS_MESSAGES || type !== WIDGET_MESSAGES_EVENTS.OPEN) {
        return;
      }

      if (eventData.publishId === this.publishId) {
        return;
      }

      if (this.isEmbed) {
        return;
      }

      this.hide(false);
    });
  }

  subscribeForVideoEvents() {
    window.addEventListener('message', event => {
      if (!event.data) {
        return;
      }

      handleVideoPauseAndResumeEvents(event);
    });
  }

  startAutoPlay() {
    this.openPlayerModal();
    this.tolstoyIframe.contentWindow.postMessage('tolstoyAutoplay', '*');
  }

  handleMoveToUrl(url) {
    const currentUrls = [window.location.toString()];

    const myShopifyDomain = window.Shopify?.shop;
    if (myShopifyDomain) {
      currentUrls.push(`https://${myShopifyDomain}${window.location.pathname}`);
    }

    if (currentUrls.some(currentUrl => getIsTheSameURL(currentUrl, url))) {
      window.history.pushState({ publishId: this.publishId }, '');

      return this.hide();
    }

    window.open(url, '_self');
  }

  toggleFeedCloseButton({ hideOnFullScreen, hideCloseButton }) {
    const shouldHideCloseButton =
      hideCloseButton && (this.isMobile || !this.isFullscreenFeed || hideOnFullScreen);

    if (shouldHideCloseButton) {
      this.feedXButton.classList.add(HIDE_FEED_CLOSE_BUTTON_CLASS, style.hideFeedCloseButton);
      return;
    }

    this.feedXButton.classList.remove(HIDE_FEED_CLOSE_BUTTON_CLASS, style.hideFeedCloseButton);
  }

  subscribeToFeedMessages() {
    window.addEventListener('message', event => {
      if (
        this.feedXButton &&
        (event.data.name === SHOW_FEED_PRODUCT_MODAL_MESSAGE ||
          event.data.name === SHOW_FEED_CART_MOBILE_MESSAGE)
      ) {
        const { isOpen } = event.data;
        this.toggleFeedCloseButton({ hideCloseButton: isOpen });
      }

      const shouldCloseModal = [
        TOLSTOY_ESCAPE_KEY_PRESSED_MESSAGE,
        TOLSTOY_CLOSE_MODAL_MESSAGE
      ].includes(event.data.name);

      if (shouldCloseModal && this.publishId === event.data.publishId) {
        this.hide();
      }

      if (event.data.name === TOLSTOY_MOVE_TO_URL) {
        this.handleMoveToUrl(event.data.url);
      }
    });
  }

  delayedShow() {
    if (!this.playerLazy) {
      this.safePostMessageToIframe({ name: 'tolstoyWidgetShown' });
    }

    if (this.autoplay) {
      this.startAutoPlay();
    }
  }

  popHistoryOnFeedClose() {
    if (window.history.state?.publishId !== this.publishId) {
      return;
    }

    if (!this.isFullscreenFeed && !this.isMobile) {
      return;
    }

    window.history.back();
  }

  hide(sendCloseMessage = true) {
    if (!this.isOpen) {
      return;
    }

    this.modal.ariaHidden = 'true';

    this.modal.style.setProperty('display', 'none', 'important');
    this.mainContainer.style.setProperty('display', 'none', 'important');
    window.removeEventListener('keydown', this.onTabKey);
    this.isOpen = false;

    if (sendCloseMessage) {
      this.postMessageToWindow(WIDGET_MESSAGES_EVENTS.CLOSE, {
        publishId: this.publishId
      });
    }

    this.safePostMessageToIframe('tolstoyModalClose');

    const sessionId = getSessionId();
    if (isTolstoyOpen({ publishId: this.publishId, sessionId })) {
      handleOpenOrCloseEvent({
        type: WIDGET_MESSAGES_EVENTS.CLOSE,
        publishId: this.publishId
      });
      removeSessionId();
    }

    this.tolstoyIframe?.contentWindow?.postMessage('tolstoyReset', '*');

    document.body.classList.remove('tolstoy-widget-shown', style.tolstoyWidgetShown);

    if (window.screen.width <= 450 || window.screen.height <= 450) {
      window.removeEventListener('orientationchange', this.lockScreen);
      window.removeEventListener('touchmove', this.preventDefault);
    }

    window.postMessage({ name: 'tolstoyWidgetClose' }, '*');
    this.onWidgetClose?.();

    if (this.isFeed) {
      this.popHistoryOnFeedClose();
    }
  }

  show() {
    if (this.isOpen) {
      return;
    }

    this.tolstoyIframe?.contentWindow?.postMessage('tolstoyReset', '*');
  }

  isPortrait() {
    return window.screen?.orientation?.type === 'portrait-primary' || window.orientation === 0;
  }

  preventDefault(event) {
    event.preventDefault();
  }

  lockScreen() {
    if (this.modal.style.display === 'none') {
      return;
    }

    if (this.isPortrait()) {
      window.addEventListener('touchmove', this.preventDefault, { passive: false });
      return;
    }

    window.removeEventListener('touchmove', this.preventDefault);
  }

  addBrowserBackButtonClickEventListener() {
    if (!this.isFullscreenFeed && !this.isMobile) {
      return;
    }

    const onBackButtonClick = () => {
      this.hide();
      window.removeEventListener('popstate', onBackButtonClick);
    };

    const getNewState = () => {
      try {
        return { ...window.history.state, publishId: this.publishId };
      } catch {
        return { publishId: this.publishId };
      }
    };

    window.addEventListener('popstate', onBackButtonClick);
    window.history.pushState(getNewState(), '');
  }

  onTabKey(event) {
    if (event.key !== 'Tab') {
      return;
    }

    if (window.document.activeElement !== this.tolstoyIframe) {
      this.tolstoyIframe?.focus();
    }

    event.stopPropagation();
    event.preventDefault();
  }

  hidePreviewElement() {
    this.modalWrapperElement.firstChild.style.visibility = 'hidden';
  }

  getShouldHideBodyScrollBar() {
    if (this.isEmbed) {
      return false;
    }

    if (this.isMobile) {
      return true;
    }

    return this.isFeed && this.isFullscreenFeed;
  }

  open(partNumber) {
    this.modal.ariaHidden = 'true';

    if (this.isFeed) {
      this.initialPartNumber = partNumber;
      this.sendTolstoyPlayMessage();
      this.openPlayerModal();
      this.addBrowserBackButtonClickEventListener();
    } else {
      this.openPlayerModal();
      this.initialPartNumber = partNumber;
      this.sendTolstoyPlayMessage();
    }

    if (this.isEmbed) {
      this.hidePreviewElement();
    }

    window.addEventListener('keydown', this.onTabKey);

    this.isOpen = true;
    this.onWidgetOpen?.();
    window.postMessage({ name: 'tolstoyWidgetOpen' }, '*');
    if (!this.isEmbed && (window.screen.width <= 450 || window.screen.height <= 450)) {
      this.lockScreen();
      window.addEventListener('orientationchange', this.lockScreen);
    }

    const shouldHideBodyScrollBar = this.getShouldHideBodyScrollBar();

    if (shouldHideBodyScrollBar) {
      document.body.classList.add('tolstoy-widget-shown', style.tolstoyWidgetShown);
    }

    // await this.reCreateResolutions(this.publishId);
  }

  remove() {
    this.isOpen = false;
    this.modal.remove();
    this.mainContainer.remove();
  }

  sendTolstoyPlayMessage() {
    if (typeof this.initialPartNumber === 'number') {
      this.safePostMessageToIframe({
        eventName: 'tolstoyPlay',
        partNumber: this.initialPartNumber
      });
    } else {
      this.safePostMessageToIframe('tolstoyPlay');
    }
  }

  sendEmbedView(params = {}) {
    this.safePostMessageToIframe({ name: 'embedView', ...params });
  }

  sendPageView(params = {}) {
    this.safePostMessageToIframe({ name: 'pageView', ...params });
  }

  sendEvent(eventName, params = {}) {
    this.safePostMessageToIframe({ name: eventName, ...params });
  }

  sendAnalyticsData({
    appKey,
    abTestId,
    id: projectId,
    owner: accountId,
    name: playlist,
    publishId,
    facebookAnalyticsID,
    googleAnalyticsID,
    playerLazy
  }) {
    if (!playerLazy) {
      return;
    }

    const params = {
      abTestId,
      accountId,
      appKey,
      isMobile: this.isMobile,
      publishId,
      projectId,
      facebookAnalyticsID,
      googleAnalyticsID,
      playlist
    };

    this.safePostMessageToIframe({ name: 'analyticsData', params });
  }

  async reCreateResolutions(publishId) {
    if (!getRecreateNewResolution(publishId)) {
      await createResolutions(publishId);
      setRecreateNewResolution(publishId, 'true');
    }
  }

  loadFullPlayer() {
    this.safePostMessageToIframe({ name: 'tolstoyLoadFullPlayer' });
  }

  redirectToUrl({ url }) {
    if (url === window.location.href) {
      this.hide();
      return;
    }

    window.open(url, '_self');
  }

  changeZIndex(event) {
    this.modal.style.setProperty('z-index', event.data.zIndex, 'important');

    if (event.data.timeout) {
      setTimeout(() => {
        this.modal.style.removeProperty('z-index');
      }, event.data.timeout);
    }
  }

  internalMessagingHandler = event => {
    switch (event.data.eventName) {
      case INTERNAL_EVENTS.rebuyCartShown:
        this.modal.classList.add(style.rebuyCartShown);
        window.removeEventListener('touchmove', this.preventDefault);
        break;
      case INTERNAL_EVENTS.rebuyCartHidden:
        this.modal.classList.remove(style.rebuyCartShown);
        this.lockScreen();
        break;
      case INTERNAL_EVENTS.toggleFeedCloseButton:
        this.toggleFeedCloseButton(event.data);
        break;
      case INTERNAL_EVENTS.moveToUrl:
        this.redirectToUrl(event.data);
        break;
      case INTERNAL_EVENTS.changeZIndex:
        this.changeZIndex(event);
        break;
      case INTERNAL_EVENTS.closePlayer:
        this.hide();
        break;
      default:
        return null;
    }
  };

  externalMessagingHandler = event => {
    switch (event.data.eventName) {
      case EXTERNAL_EVENTS_TO_POST.tolstoyZIndexChange:
        this.changeZIndex(event);
        break;
      default:
        return null;
    }
  };

  initInternalMessagingSubscriptions() {
    InternalMessaging.subscribeMultipleEvents({
      eventNames: INTERNAL_MESSAGES_TO_SUBSCRIBE,
      callback: this.internalMessagingHandler
    });
  }

  initExternalMessagingSubscriptions = () => {
    InternalMessaging.subscribeMultipleEvents({
      eventNames: EXTERNAL_MESSAGES_TO_SUBSCRIBE,
      callback: this.externalMessagingHandler
    });
  };
}

export default Modal;
