import React, { useState } from 'react';
import { TextBody, TextSmall } from 'shared/react/components/basic/text/TextV2';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import BasicMenuV2 from 'src/complex_components/BasicMenuV2';
import ExpanderBottomArrow from 'src/images/ExpanderBottomArrow';
import styled from 'styled-components';
import BasicSelect from 'shared/react/components/basic/basic_select/BasicSelect';

type Props = {
  label: string;
  items: { [key: string]: any }[];
  selectedValue: string | { value: string };
  isDisabled?: boolean;
  onChange: (value: { value: string }) => void;
};

const SyncTagsDropdown = ({ label, items, selectedValue, isDisabled, onChange }: Props) => {
  return (
    <DropdownWrapper isDisabled={isDisabled}>
      <Label>{label}</Label>
      <Select
        items={items}
        value={selectedValue}
        onChange={onChange}
        inputBackground={undefined}
        inputPadding={undefined}
        inputBoxSizing={undefined}
        renderHeader={undefined}
        placeholder={label}
        customHeight={48}
        loading={undefined}
        itemsPadding={undefined}
        error={undefined}
        isDisabled={isDisabled}
      />
    </DropdownWrapper>
  );
};
const Select = styled(BasicSelect)`
  background: ${({ theme }) => theme.colors.white};
`;

const DropdownWrapper = styled(VerticalFlex)<{ isDisabled: boolean }>`
  opacity: ${({ isDisabled }) => (isDisabled ? 0.4 : 1)};
`;

const Label = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.neutralDarkest};
`;

export default SyncTagsDropdown;
