import React from 'react';
import ChartTooltip from '../chart-tooltip/ChartTooltip';
import Utils from 'src/utils';
import { ANALYTICS_OVERVIEW_TOTAL_REVENUE } from 'app/src/constants/analytics.constants';
import { EmailConversionOvertimeRecord } from 'app/src/services/cube/types/getEmailConvertions.types';
import { getShortMonthAndDate } from 'app/src/utils/date.utils';
import { useTheme } from 'styled-components';

type TooltipPayload = {
  payload: EmailConversionOvertimeRecord;
};

type Props = {
  active: boolean;
  payload: [TooltipPayload];
  label: string;
};

const AnalyticsEmailRevenueTooltip = ({ active, payload, label }: Props) => {
  const theme = useTheme();
  if (!active || !payload?.length) {
    return null;
  }

  const [emailOvertimeData] = payload;
  const { payload: emailOvertime } = emailOvertimeData;

  const lineGroups = [
    {
      key: ANALYTICS_OVERVIEW_TOTAL_REVENUE.DIRECT,
      header: ANALYTICS_OVERVIEW_TOTAL_REVENUE.DIRECT,
      bulletColor: theme.colors.successDark,
      value: Utils.formatNumber({
        value: emailOvertime.directRevenue,
        currency: emailOvertime.currency,
      }),
      lines: [
        {
          header: ANALYTICS_OVERVIEW_TOTAL_REVENUE.IN_VIDEO,
          value: Utils.formatNumber({
            value: emailOvertime.inVideoRevenue,
            currency: emailOvertime.currency,
          }),
        },
        {
          header: ANALYTICS_OVERVIEW_TOTAL_REVENUE.POST_VIDEO,
          value: Utils.formatNumber({
            value: emailOvertime.postVideoRevenue,
            currency: emailOvertime.currency,
          }),
        },
      ],
    },
    {
      key: ANALYTICS_OVERVIEW_TOTAL_REVENUE.INDIRECT,
      header: ANALYTICS_OVERVIEW_TOTAL_REVENUE.INDIRECT,
      bulletColor: theme.colors.success,
      value: Utils.formatNumber({
        value: emailOvertime.indirectRevenue,
        currency: emailOvertime.currency,
      }),
    },
  ];

  return <ChartTooltip label={getShortMonthAndDate(label)} lineGroups={lineGroups} />;
};

export default AnalyticsEmailRevenueTooltip;
