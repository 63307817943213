import React from 'react';
import styled from 'styled-components';
import { useGrantPermissions } from 'src/hooks/ugc';

import UgcPermissionsTopBar from './UgcPermissionsTopBar';
import UgcPermissionsContent from './UgcPermissionsContent';

export const UgcGrantPermissionsPage = () => {
  const { isUpgradeTooltipOpen, onGrantPermissions } = useGrantPermissions();

  return (
    <>
      <UgcPermissionsTopBar
        isUpgradeTooltipOpen={isUpgradeTooltipOpen}
        onGrantPermissions={onGrantPermissions}
      />
      <Content>
        <UgcPermissionsContent />
      </Content>
    </>
  );
};

const Content = styled.div`
  padding: 24px 24px 0 24px;
`;

export default UgcGrantPermissionsPage;
