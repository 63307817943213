import React from 'react';
import styled from 'styled-components';

import useFeedModal from 'shared/react/components/complex/context/hooks/useFeedModal';
import FeedBrandedButton from 'shared/react/components/complex/feed/feed_buttons/FeedBrandedButton';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { MORE_BUTTON_DATA_TYPE } from 'shared/react/constants/feedPreviewDataTypes.constants';
import { CUSTOMER_LOGIN_MODAL } from 'shared/react/constants/modals.constants';
import { TextTiny } from 'shared/react/components/complex/Text';
import useTracker from 'shared/react/components/complex/context/hooks/useTracker';
import { PRIMARY_DARK_BUTTON_TYPE } from 'shared/react/constants/feed.constants';
import { useFeedIsMobile } from 'shared/react/hooks/useIsMobile';
import useCustomerLogin from 'shared/react/components/complex/context/hooks/useCustomerLogin';

const CustomerLoginBanner = ({ videoRef }) => {
  const isMobile = useFeedIsMobile();
  const [, { track }] = useTracker();
  const [, { pushModal }] = useFeedModal();
  const [{ shouldShowLoginBanner }] = useCustomerLogin();

  const onModalClose = () => {
    if (videoRef) {
      videoRef.current.play();
    }
  };

  const onModalOpen = () => {
    if (videoRef) {
      videoRef.current.pause();
    }
  };

  const pushCustomerModal = () => {
    requestAnimationFrame(() => {
      pushModal({
        key: CUSTOMER_LOGIN_MODAL,
        props: {
          onClose: onModalClose,
        },
      });
    });
  };

  const handleOpenCustomerModal = e => {
    e.preventDefault();
    onModalOpen();
    track('customerLoginModalOpen');
    pushCustomerModal();
  };

  if (!shouldShowLoginBanner) {
    return null;
  }

  return (
    <LayoutRoot isMobile={isMobile}>
      <StyledTextTiny lineHeight="17px">
        Save your feed activity to get personalized recommendations.
      </StyledTextTiny>
      <StyledFeedBrandedButton
        type={PRIMARY_DARK_BUTTON_TYPE}
        onClick={handleOpenCustomerModal}
        data-type={MORE_BUTTON_DATA_TYPE}
        buttonText={'Sign in'}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlexCentered)`
  position: absolute;
  top: ${({ isMobile }) => (isMobile ? '12px' : '16px')};
  left: 50%;
  transform: translateX(-50%);
  width: 356px;
  max-width: 100%;
  padding: 8px 9px;
  background: ${({ theme }) => theme.getHexOpacity(theme.colors.black, 25)};
  backdrop-filter: blur(6px);
  border-radius: 8px;
  z-index: 12;
`;

const StyledTextTiny = styled(TextTiny)`
  text-align: left;
  color: ${({ theme }) => theme.colors.white};
`;

const StyledFeedBrandedButton = styled(FeedBrandedButton)`
  min-width: 50px;
  padding: 5px 15px;
  > div {
    font-size: 12px;
  }
`;

export default CustomerLoginBanner;
