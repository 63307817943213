import React from 'react';
import LoadingIndicator from 'shared/react/components/basic/LoadingIndicator';

const ComponentWithLoader = ({ isLoading, children, ...props }) => {
  if (isLoading) {
    return <LoadingIndicator {...props} />;
  }

  return children;
};

export default ComponentWithLoader;
