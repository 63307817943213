import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import styled from 'styled-components';

export const TableFirstHeaderText = styled(TextTiny)`
  text-transform: initial;
`;

export const TableHeaderText = styled(TableFirstHeaderText)`
  text-align: center;
`;
