import { css } from 'styled-components';
import Utils from 'shared/react/utils/utils';
import {
  FEED_BUTTON_LOADER_DATA_TYPE,
  FEED_BUTTON_TEXT_DATA_TYPE,
} from 'shared/react/constants/feedPreviewDataTypes.constants';
import { getDesignCss } from 'shared/react/utils/addSharedCss';

export default css`
  min-height: 40px;
  max-height: 40px;
  padding: 0;
  ${({ $design }) => getDesignCss($design)};

  & ${Utils.getDataType(FEED_BUTTON_TEXT_DATA_TYPE)} {
    font-size: 14px;
    line-height: 14px;

    ${({ $design: { font, fontSize, fontWeight, fontColor } = {} }) => {
      return css`
        font-family: ${font};
        font-size: ${fontSize}px;
        font-weight: ${fontWeight};
        color: ${fontColor};
      `;
    }}

    ${({ $isFenty }) =>
      $isFenty &&
      css`
        font-weight: 700;
      `}
  font-size: ${({ $isPP }) => ($isPP ? '16px' : '')};
    font-weight: ${({ $isPP }) => ($isPP ? '500' : '')};
  }

  & ${Utils.getDataType(FEED_BUTTON_LOADER_DATA_TYPE)} {
    width: 10px;
    height: 10px;
  }
`;
