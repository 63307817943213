import React from 'react';
import StandardModalV2 from 'app/src/pages/modals/StandardModalV2';
import styled, { css } from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextH3Bold, TextTiny } from 'shared/react/components/basic/text/TextV2';
import HorizontalFlex, {
  Gap16HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import {
  LinkButton,
  PrimaryButton,
  SuccessButton,
} from 'shared/react/components/basic/button-v2/BasicButton';
import StraightArrowRightIcon from 'app/src/images/creation_method_icons/StraightArrowRightIcon';
import {
  CaseType,
  CASE_CONTENT,
  CASES_WAIT_LISTS_JOINED_LOCAL_STORAGE_KEY,
  cases,
} from './cases.constants';
import { useProjects } from 'app/src/context/ProjectsStore';
import { useRules } from 'app/src/context/RulesStore';
import OverlaySpinner from 'app/src/basic_components/OverlaySpinner';
import { useApps } from 'src/context/AppsStore';
import ShopIcon from 'app/src/pages/dashboard/components/cases/images/icons/ShopIcon';
import CaseContent from './CaseContent';
import InfoIcon from 'shared/react/images/InfoIcon';
import useLocalStorage from 'src/hooks/useLocalStorage';
import CheckMarkIcon from 'src/images/CheckMarkIcon';

type Props = {
  open: boolean;
  onClick: (type: CaseType) => Promise<void> | void;
  onClose: () => void;
  type: CaseType | null;
};

const SelectCaseModal = ({ open, onClick, onClose, type }: Props) => {
  const [{ creating: creatingProject }] = useProjects();
  const [{ loading: creatingRules }] = useRules();
  const [{ selectedApp }] = useApps();
  const isShop = type === CaseType.SHOP_VIDEOS;

  if (!type) {
    return null;
  }

  const isLoading = creatingProject || creatingRules;

  const { title, titleIcon } = CASE_CONTENT[type];

  const isDisabled = type === CaseType.SHOP_VIDEOS && !selectedApp?.isShopAppEligible;

  return (
    <StandardModalV2 open={open} onClose={onClose} canCloseModal={!isLoading}>
      <OverlaySpinner isLoading={isLoading}>
        <LayoutRoot $isLoading={isLoading}>
          <Header isShop={isShop}>
            {titleIcon && <TitleIconContainer>{titleIcon}</TitleIconContainer>}
            {isShop && (
              <ShopIconContainer>
                <ShopIcon />
              </ShopIconContainer>
            )}
            <Title>{title}</Title>
          </Header>
          <CaseContent caseType={type} />
          <Buttons>
            {isDisabled && (
              <LinkButton as="a" href="https://apps.shopify.com/shop" target="__blank">
                Install Shop channel
              </LinkButton>
            )}
            <ActionButton type={type} onClick={onClick} />
          </Buttons>
        </LayoutRoot>
      </OverlaySpinner>
    </StandardModalV2>
  );
};

const ActionButton = ({ type, onClick }) => {
  const { isWaitListedCase } = cases[type];
  const [joinedWaitLists] = useLocalStorage(CASES_WAIT_LISTS_JOINED_LOCAL_STORAGE_KEY, {});
  const hasJoinedWaitList = joinedWaitLists[type];

  if (hasJoinedWaitList) {
    return (
      <SuccessButton>
        Joined Wait List
        <StyledCheckMarkIcon />
      </SuccessButton>
    );
  }

  return (
    <PrimaryButton onClick={() => onClick(type)}>
      {isWaitListedCase ? 'Join Wait List' : 'Start creating'}
      <StraightArrowRightIcon />
    </PrimaryButton>
  );
};

const LoadingOverlay = css<{ $isLoading: boolean }>`
  &::before {
    display: ${({ $isLoading }) => ($isLoading ? 'block' : ' none')};
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
    z-index: 1;
  }
`;

const LayoutRoot = styled(VerticalFlex)<{ $isLoading: boolean }>`
  position: relative;
  width: 75vw;
  max-width: 1100px;

  ${({ $isLoading }) => ($isLoading ? LoadingOverlay : '')};

  @media (${({ theme }) => theme.breakpoints.dashboardTabletMax}) {
    width: 90vw;
  }

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 100vw;
  }
`;

const Header = styled(HorizontalFlex)<{ isShop: boolean }>`
  gap: ${({ isShop }) => (isShop ? 6 : 8)}px;
  padding: 16px 24px;
  align-items: center;
`;

const TitleIconContainer = styled.div`
  display: flex;
  & svg path:not(.icon-path-mask) {
    fill: ${({ theme }) => theme.colors.neutralDarkest};
  }
`;

const ShopIconContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Title = styled(TextH3Bold)``;

const Buttons = styled(Gap16HorizontalFlex)`
  padding: 16px 24px 24px 24px;
  justify-content: flex-end;
  border-top: 1px solid ${({ theme }) => theme.colors.neutralLight};
  align-items: center;
`;

const TextTinyGray = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralGray};
  align-items: center;
  display: flex;
  svg {
    opacity: 0.5;
  }
`;

const StyledCheckMarkIcon = styled(CheckMarkIcon)`
  width: 14px;
  height: 14px;
`;

export default SelectCaseModal;
