import { css } from 'styled-components';
import Utils from 'app/src/utils';

export const sensitiveDataFilter = () => {
  if (!Utils.isGhostAccount()) {
    return css``;
  }

  return css`
    filter: blur(3px);
  `;
};
