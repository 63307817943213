import React from 'react';
import styled from 'styled-components';
import { useLookAndFeelContext } from '../../../LookAndFeelContext';
import {
  TILE_SHOULD_SHOW_CONTROLS_PROPERTY_KEY,
  TILE_SHOULD_SHOW_CAPTIONS_PROPERTY_KEY,
} from '../../../LookAndFeelPage.constants';
import EditorHeader from '../shared/EditorHeader';
import EditorMainContainer from '../shared/EditorMainContainer';
import EditorOnOffToggleItem from '../shared/EditorOnOffToggleItem';

const TileVideoEditor = () => {
  const {
    customizationSettings: {
      widgetSettings: { tileShouldShowControls, tileShouldShowCaptions },
    },
    setWidgetSettingsProperty,
  } = useLookAndFeelContext();

  const handleTileShouldShowControlsChange = () => {
    setWidgetSettingsProperty(TILE_SHOULD_SHOW_CONTROLS_PROPERTY_KEY, !tileShouldShowControls);
  };

  const handleTileShouldShowCaptionsChange = () => {
    setWidgetSettingsProperty(TILE_SHOULD_SHOW_CAPTIONS_PROPERTY_KEY, !tileShouldShowCaptions);
  };

  return (
    <LayoutRoot>
      <EditorHeader title="Video" />
      <EditorMainContainer>
        <EditorOnOffToggleItem
          text="Show video controls"
          checked={tileShouldShowControls}
          onChange={handleTileShouldShowControlsChange}
        />
        <EditorOnOffToggleItem
          text="Show video captions"
          checked={tileShouldShowCaptions}
          onChange={handleTileShouldShowCaptionsChange}
        />
      </EditorMainContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div``;

export default TileVideoEditor;
