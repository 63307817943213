import React from 'react';

const AiIconGradient = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: 'purple', stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: '#00FFFF', stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <path
        d="M10.2188 2.6875L12 2L12.6562 0.25C12.6875 0.09375 12.8438 0 13 0C13.125 0 13.2812 0.09375 13.3125 0.25L14 2L15.75 2.6875C15.9062 2.71875 16 2.875 16 3C16 3.15625 15.9062 3.3125 15.75 3.34375L14 4L13.3125 5.78125C13.2812 5.90625 13.125 6 13 6C12.8438 6 12.6875 5.90625 12.6562 5.78125L12 4L10.2188 3.34375C10.0625 3.3125 10 3.15625 10 3C10 2.875 10.0625 2.71875 10.2188 2.6875ZM5.5 2.3125C5.5625 2.125 5.75 2 5.9375 2C6.125 2 6.3125 2.125 6.40625 2.3125L8.03125 5.875L11.5938 7.5C11.7812 7.59375 11.9062 7.78125 11.9062 7.96875C11.9062 8.15625 11.7812 8.34375 11.5938 8.4375L8.03125 10.0625L6.40625 13.625C6.3125 13.8125 6.125 13.9375 5.9375 13.9375C5.75 13.9375 5.5625 13.8125 5.5 13.625L3.84375 10.0625L0.28125 8.4375C0.09375 8.34375 0 8.15625 0 7.96875C0 7.78125 0.09375 7.59375 0.28125 7.5L3.84375 5.875L5.5 2.3125ZM5.1875 6.5C5.0625 6.8125 4.78125 7.09375 4.46875 7.21875L2.875 7.96875L4.46875 8.71875C4.78125 8.84375 5.0625 9.125 5.1875 9.4375L5.9375 11.0312L6.6875 9.4375C6.8125 9.125 7.09375 8.84375 7.40625 8.71875L9 7.96875L7.40625 7.21875C7.09375 7.09375 6.8125 6.8125 6.6875 6.5L5.9375 4.90625L5.1875 6.5ZM12.6562 10.25C12.6875 10.0938 12.8438 10 13 10C13.125 10 13.2812 10.0938 13.3125 10.25L14 12L15.75 12.6875C15.9062 12.7188 16 12.875 16 13C16 13.1562 15.9062 13.3125 15.75 13.3438L14 14L13.3125 15.7812C13.2812 15.9062 13.125 16 13 16C12.8438 16 12.6875 15.9062 12.6562 15.7812L12 14L10.2188 13.3438C10.0625 13.3125 10 13.1562 10 13C10 12.875 10.0625 12.7188 10.2188 12.6875L12 12L12.6562 10.25Z"
        fill="url(#gradient1)"
      />
    </svg>
  );
};

export default AiIconGradient;
