import React from 'react';
import StandardModal from 'app/src/pages/modals/StandardModal';
import Publish from 'app/src/pages/modals/publish/Publish';
import { PUBLISH } from 'app/src/constants/searchParams.constants';
import { track } from 'app/src/helpers/Tracker';
import Utils from 'app/src/utils';
import { navigateToPublishType } from 'app/src/utils/navigation.utils';
import { useProjects } from 'app/src/context/ProjectsStore';
import { useHistory } from 'react-router-dom';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { publishMethodOptions } from 'app/src/types/entities';

const PublishModal = () => {
  const [{ currentProjectId }, { setCurrentModal }] = useModal();
  const [{ project }] = useProjects({ projectId: currentProjectId });
  const urlParams = new URLSearchParams(window.location.search);
  const publishType = urlParams.get(PUBLISH);
  const history = useHistory();

  const handleModalClose = () => {
    setCurrentModal('');
    history.replace({
      pathname: window.location.pathname,
    });
  };

  const handleModalTabChanged = lastUsedPublishType => {
    track(`Share ${Utils.upperCaseFirstChar(lastUsedPublishType)} Tab Click`);
    navigateToPublishType(history, lastUsedPublishType, currentProjectId);
  };

  const getSizes = () => {
    if (project?.publishMethod === publishMethodOptions.videoPage) {
      return { maxHeight: '735px', maxWidth: '980px' };
    }

    if (project?.publishMethod === publishMethodOptions.hero) {
      return { maxWidth: '552px' };
    }

    if (project?.publishMethod === publishMethodOptions.unassigned) {
      return { maxWidth: '840px' };
    }

    return { height: '530px', maxWidth: '840px' };
  };

  const { height, width, maxHeight, maxWidth } = getSizes();

  return (
    <StandardModal
      open={publishType && true}
      height={height}
      width={width}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      onClose={handleModalClose}
      removeDefaultPadding
    >
      <Publish type={publishType} project={project} onTabChanged={handleModalTabChanged} />
    </StandardModal>
  );
};

export default PublishModal;
