import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextSmall } from 'shared/react/components/complex/Text';
import { CheckboxV2 } from 'src/complex_components/Checkbox';
import Utils from 'src/utils';

type Props = {
  isLoading: boolean;
  app: any;
  updateIntegration: (appData) => void;
};

const ImportFileRecurring: FC<Props> = ({ updateIntegration, app, isLoading }) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const appData = Utils.safeParse(app?.data);
    setIsChecked(appData?.isRecurring);
  }, [app]);

  const onChange = async () => {
    if (isLoading) {
      return;
    }

    setIsChecked(!isChecked);

    updateIntegration({
      data: {
        isRecurring: !isChecked,
      },
    });
  };

  if (!app?.active) {
    return null;
  }

  return (
    <Container as="label">
      <Label>Automatically refresh every 24 hours</Label>
      <Checkbox onChange={onChange} checked={isChecked} disabled={isLoading} />
    </Container>
  );
};

const Container = styled(Gap8HorizontalFlex)``;

const Label = styled(TextSmall)``;

const Checkbox = styled(CheckboxV2)`
  padding: 0;
  margin: 0;
`;

export default ImportFileRecurring;
