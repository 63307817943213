import React from 'react';
import styled from 'styled-components';
import { useApps } from 'app/src/context/AppsStore';
import Tabs from './tabs/Tabs';
import ShareViaLink from './link/ShareViaLink';
import ShareViaEmail from './email/ShareViaEmail';
import ShareViaQR from './qr/ShareViaQR';
import {
  EMAIL_KEY,
  EMAIL_NAME,
  LINK_KEY,
  LINK_NAME,
  QR_KEY,
  QR_NAME,
} from 'app/src/constants/tolstoy.constants';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import FloatingPricingBadge from 'app/src/complex_components/billings/badges/FloatingPricingBadge';
import { DASHBOARD_TABLET_MAX_WIDTH } from 'shared/react/utils/mediaQuery';
import Utils from 'app/src/utils';
import { useVideos } from 'app/src/context/VideosStore';

const Share = ({ selectedTabKey, project, onTabChanged }) => {
  const firstStep = project?.steps?.items[0];
  const [{ video }, { getVideoGif }] = useVideos(firstStep?.videoId);
  const [apps] = useApps();
  const { shopify: isShopify } = apps;
  const gifUrl = getVideoGif(video || {});

  const tabs = [
    {
      key: LINK_KEY,
      name: LINK_NAME,
      content: <ShareViaLink project={project} isShopify={isShopify} gifUrl={gifUrl} />,
    },
    {
      key: EMAIL_KEY,
      name: EMAIL_NAME,
      content: <ShareViaEmail project={project} />,
    },
    {
      key: QR_KEY,
      name: QR_NAME,
      content: <ShareViaQR project={project} />,
    },
  ];

  const getSelectedTabContent = () => {
    return tabs.find(({ key }) => key === selectedTabKey)?.content;
  };

  return (
    <LayoutRoot>
      <Tabs tabs={tabs} selectedTabKey={selectedTabKey} onTabChanged={onTabChanged} />
      {getSelectedTabContent()}
      <FloatingPricingBadge
        leftText="Brand your Tolstoy"
        centerText="Customize Tolstoy to align with your brand"
        analyticsData={Utils.getAnalyticsLocationObject('Publish Modal Floating Badge')}
        variant="light"
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  padding: 40px;
  height: 100%;
  gap: 40px;

  @media (${DASHBOARD_TABLET_MAX_WIDTH}) {
    padding: 30px;
    gap: 32px;
  }
`;

export default Share;
