import React, { useState } from 'react';
import styled from 'styled-components';
import { useProjects } from 'app/src/context/ProjectsStore';
import PricingBadge from './PricingBadge';
import { useBilling } from 'app/src/hooks/useBillings';
import Utils from 'app/src/utils';
import UpgradeToProModal from '../modal/UpgradeToProModal';
import { useAccount } from 'app/src/context/AccountStore';

const TolstoysLeftBadge = ({ isSidebarOpen, ...props }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [{ numberOfTeamMemberTolstoys }] = useProjects();
  const { isFreePackage, maxNumberOfAllowedTolstoys, isPaymentRoleLight } = useBilling();
  const [{ isAccountOwner }] = useAccount();
  const closeToLimit = numberOfTeamMemberTolstoys >= 8;

  const getOnButtonClick = () => {
    if (!isFreePackage || isAccountOwner || !isPaymentRoleLight) {
      return null;
    }

    return () => setIsModalOpen(true);
  };

  const shouldShow = isSidebarOpen && isFreePackage && closeToLimit;

  return (
    <>
      <UpgradeToProModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />
      {shouldShow && (
        <LayoutRoot {...props}>
          <PricingBadge
            onButtonClick={getOnButtonClick()}
            text={`${numberOfTeamMemberTolstoys}/${maxNumberOfAllowedTolstoys} Tolstoys`}
            analyticsData={Utils.getAnalyticsLocationObject('Tolstoys Panel')}
            padding="8px 8px 8px 16px"
          />
        </LayoutRoot>
      )}
    </>
  );
};

export default TolstoysLeftBadge;

const LayoutRoot = styled.div`
  margin: 3px 16px 18px;
`;
