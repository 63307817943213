import React from 'react';

const ItalicIcon = ({ fill = '#7D8087' }) => {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.875 2h.375v3.75A3.731 3.731 0 0 0 6 9.5a3.746 3.746 0 0 0 3.75-3.75V2h.375c.398 0 .75-.328.75-.75a.771.771 0 0 0-.75-.75H7.5a.755.755 0 0 0-.75.75c0 .422.328.75.75.75h.375v3.75A1.866 1.866 0 0 1 6 7.625 1.851 1.851 0 0 1 4.125 5.75V2H4.5c.398 0 .75-.328.75-.75A.771.771 0 0 0 4.5.5H1.875a.755.755 0 0 0-.75.75c0 .422.328.75.75.75Zm8.625 9h-9a.755.755 0 0 0-.75.75c0 .422.328.75.75.75h9c.398 0 .75-.328.75-.75a.771.771 0 0 0-.75-.75Z"
        fill={fill}
      />
    </svg>
  );
};

export default ItalicIcon;
