import React from 'react';

const RulesStoresIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="10" fill="#F8E8F7" />
    <path
      d="M14 12C14.3438 12 14.6562 12.2812 14.7188 12.625L14.7812 13H27.9062C28.5625 13 29.0625 13.6562 28.875 14.2812L27.1875 20.2812C27.0625 20.7188 26.6875 21 26.2188 21H16.3125L16.5938 22.5H26.25C26.6562 22.5 27 22.8438 27 23.25C27 23.6875 26.6562 24 26.25 24H15.9688C15.625 24 15.3125 23.75 15.25 23.4062L13.375 13.5H11.75C11.3125 13.5 11 13.1875 11 12.75C11 12.3438 11.3125 12 11.75 12H14ZM15 26.5C15 25.6875 15.6562 25 16.5 25C17.3125 25 18 25.6875 18 26.5C18 27.3438 17.3125 28 16.5 28C15.6562 28 15 27.3438 15 26.5ZM27 26.5C27 27.3438 26.3125 28 25.5 28C24.6562 28 24 27.3438 24 26.5C24 25.6875 24.6562 25 25.5 25C26.3125 25 27 25.6875 27 26.5Z"
      fill="#F29BEF"
    />
  </svg>
);

export default RulesStoresIcon;
