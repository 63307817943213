import React from 'react';
import styled from 'styled-components';
import { TextSmall, TextTiny } from 'shared/react/components/complex/Text';
import IosSwitch from 'app/src/basic_components/IosSwitch';
import InfoIconWithTooltip from 'app/src/complex_components/InfoIconWithTooltip';
import Tooltip from 'app/src/basic_components/Tooltip';

function ToggleSetting({
  enabled,
  onEnabledChange,
  tooltipText,
  title,
  subtitle,
  disabled = false,
  buttonTooltipText,
  ...props
}) {
  const getSwitch = () => {
    const props = {
      disabled: disabled,
      checked: enabled || false,
      onChange: e => onEnabledChange(e.target.checked),
    };

    if (buttonTooltipText) {
      return (
        <Tooltip text={buttonTooltipText}>
          <SwitchContainer>
            <StyledIosSwitch
              checked={enabled || false}
              onChange={e => onEnabledChange(e.target.checked)}
            />
          </SwitchContainer>
        </Tooltip>
      );
    }
    return <IosSwitch {...props} />;
  };

  return (
    <LayoutRoot {...props}>
      {getSwitch()}
      <TitleContainer>
        <Title>{title}</Title>
        <InfoIconWithTooltip title={tooltipText} tooltipPlacement="right" />
      </TitleContainer>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </LayoutRoot>
  );
}

export default ToggleSetting;

const SwitchContainer = styled.div`
  height: 22px;
`;

const LayoutRoot = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  gap: 0 8px;
  justify-items: start;
`;

const Title = styled(TextSmall)`
  display: grid;
  align-items: center;
  align-self: center;
  grid-row: 2;
  grid-column: 1;
`;

const Subtitle = styled(TextTiny)`
  display: flex;
  flex-direction: column;
  grid-row: 2;
  grid-column: 2;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const StyledIosSwitch = styled(IosSwitch)`
  grid-row: 1;
  grid-column: 1;
  align-self: center;
`;
