import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { project as Project } from 'src/types/entities';
import { useProjectActions } from 'src/context/ProjectsStore';
import { useSnackBarActions } from 'src/context/ui_store/SnackBarStore';
import { track } from 'src/helpers/Tracker';
import Spinner from 'src/basic_components/Spinner';
import { PrimaryButton } from 'shared/react/components/basic/button-v2/BasicButton';

type InstallationCompletedButtonProps = {
  project: Project;
  isDisabled: boolean;
};

const InstallationCompletedButton: FC<InstallationCompletedButtonProps> = props => {
  const { project, isDisabled } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { setSnackbar, setErrorSnackbar } = useSnackBarActions();
  const { updateProject } = useProjectActions();
  const [isInstallationCompleted, setIsInstallationCompleted] = useState(
    project?.installationCompleted
  );

  const onButtonClick = async () => {
    track('Project Installation Completed Button Click', {
      status: !isInstallationCompleted,
    });

    setIsLoading(true);
    const success = await updateProject({
      ...project,
      installationCompleted: !isInstallationCompleted,
    });
    setIsLoading(false);

    if (success) {
      setIsInstallationCompleted(true);
      setSnackbar('Saved successfully');
    } else {
      setErrorSnackbar('Saving failed, please try again later');
    }
  };

  let buttonContent: string | JSX.Element = '';
  if (isLoading) {
    buttonContent = <Spinner size={16} />;
  } else if (isInstallationCompleted) {
    buttonContent = 'Completed';
  } else {
    buttonContent = <>Mark as complete</>;
  }

  return (
    <SuccessButtonStyled
      onClick={onButtonClick}
      disabled={isDisabled || isLoading || isInstallationCompleted}
    >
      {buttonContent}
    </SuccessButtonStyled>
  );
};

const SuccessButtonStyled = styled(PrimaryButton)`
  width: 120px;
`;

export default InstallationCompletedButton;
