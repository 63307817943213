import React from 'react';

export const MultiTagIcon = () => (
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m9.586 2.438 2.976 3.023a3.155 3.155 0 0 1 0 4.476l-2.624 2.649c-.211.234-.563.234-.797.023-.211-.234-.211-.586 0-.796l2.625-2.672a2.037 2.037 0 0 0 0-2.883l-3-3.047c-.211-.211-.211-.563.023-.797.211-.21.563-.21.797.023ZM1.5 6.89V3.375c0-.61.492-1.125 1.125-1.125h3.492c.399 0 .774.164 1.055.445l3.937 3.938a1.514 1.514 0 0 1 0 2.133l-3.117 3.117a1.514 1.514 0 0 1-2.133 0L1.922 7.945A1.469 1.469 0 0 1 1.5 6.891Zm3.375-2.016a.77
1.771 0 0 0-.75-.75.755.755 0 0 0-.75.75c0 .422.328.75.75.75.398 0 .75-.328.75-.75Z"
      fill="#DFE0E2"
    />
  </svg>
);

export default MultiTagIcon;
