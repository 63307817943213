import { generateUUID } from '../utils/utils.js';

class BroadcastHub {
  constructor(channelName) {
    this.name = channelName || generateUUID();
    try {
      if ('BroadcastChannel' in self) {
        this.broadcaster = new BroadcastChannel(this.name);
        this.receiver = new BroadcastChannel(this.name);
      }
    } catch (error) {
      console.error('self is not defined', error);
    }
  }

  postMessage = data => {
    this.broadcaster?.postMessage(data);
  };

  addEventListener = (...data) => {
    this.receiver?.addEventListener(...data);
  };
}

export default BroadcastHub;
