/* eslint-disable react/display-name */
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { DASHBOARD_TABLET_MAX_WIDTH } from 'shared/react/utils/mediaQuery';
import Routes from '../../helpers/Routes';
import Utils from '../../utils';
import { track } from '../../helpers/Tracker';
import LinkButton from 'app/src/complex_components/LinkButton';
import { TextSmall } from 'shared/react/components/complex/Text';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import {
  FEATURE_TEAM_ACCOUNTS,
  FEATURE_TEAM_MEMBER_NOTIFICATION_SETTINGS,
} from 'app/src/constants/appFeatures.constants';
import MyWorkspaceIcon from 'app/src/images/MyWorkspaceIcon';
import MyAccountIcon from 'app/src/images/MyAccountIcon';
import NotificationsIcon from 'app/src/images/NotificationsIcon';
import TeamIcon from 'app/src/images/TeamIcon';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { useBilling } from '../../hooks/useBillings';
import BrandingIcon from 'app/src/images/BrandingIcon';
import AccountPlansIcon from 'app/src/images/AccountPlansIcon';
import AccountBillingsIcon from 'app/src/images/AccountBillingsIcon';
import InstallationIcon from 'src/images/InstallationIcon';
import { FEATURE_ACCOUNT_INSTALLATION_TAB } from 'shared/react/constants/features.constants';
import MultipleTagsIcon from 'src/images/MultipleTagsIcon';
import { useApps } from 'src/context/AppsStore';
import { SHOPIFY } from 'src/constants/intergrations.constants';
import AnalyticsIcon from 'src/images/sidebar-v2/AnalyticsIcon';

function LeftSidebar() {
  const history = useHistory();
  const { getFeatureEnabled } = useFeatureActions();
  const theme = useTheme();
  const [{ apps }] = useApps();

  const teamMembersEnabled = getFeatureEnabled(FEATURE_TEAM_ACCOUNTS);
  const teamMemberNotificationSettingsEnabled = getFeatureEnabled(
    FEATURE_TEAM_MEMBER_NOTIFICATION_SETTINGS
  );
  const accountInstallationTabEnabled = getFeatureEnabled(FEATURE_ACCOUNT_INSTALLATION_TAB);
  const shopifyApps = apps?.filter(app => app?.app === SHOPIFY);

  const { isPricingEnabled, packageGroup, isBillingPageEnabled } = useBilling();

  const isMobile = useMediaQuery(`(${DASHBOARD_TABLET_MAX_WIDTH})`) || Utils.isMobile();

  const getStatusIconColor = active => (active ? theme.colors.blue7 : theme.colors.gray2);

  const leftSideTabs = [
    {
      key: Routes.getMyProfileSuffix(),
      text: 'My profile',
      route: Routes.getMyProfileRoute(),
      getIcon: active => <MyAccountIcon fill={getStatusIconColor(active)} />,
    },
    {
      key: Routes.getTeamMemberNotificationsSuffix(),
      text: 'Notifications',
      disabled: !teamMemberNotificationSettingsEnabled,
      route: Routes.getTeamMemberNotificationsRoute(),
      getIcon: active => <NotificationsIcon color={getStatusIconColor(active)} />,
    },
    {
      key: Routes.getMyWorkspaceSuffix(),
      text: 'My workspace',
      route: Routes.getMyWorkspaceRoute(),
      getIcon: active => <MyWorkspaceIcon fill={getStatusIconColor(active)} />,
    },
    {
      key: Routes.getInstallationSuffix(),
      text: 'Installation',
      disabled: !accountInstallationTabEnabled,
      route: Routes.getDashboardInstallationRoute(),
      getIcon: active => <InstallationIcon fill={getStatusIconColor(active)} />,
    },
    {
      key: Routes.getTrackingSuffix(),
      text: 'Tracking',
      route: Routes.getDashboardTrackingRoute(),
      getIcon: active => <AnalyticsIcon fill={getStatusIconColor(active)} />,
    },
    {
      key: Routes.getTeamSuffix(),
      text: 'Team',
      disabled: !teamMembersEnabled,
      route: Routes.getTeamRoute(),
      getIcon: active => <TeamIcon fill={getStatusIconColor(active)} />,
    },
    {
      key: Routes.getBrandingSuffix(),
      text: 'Branding',
      route: Routes.getBrandingRoute(),
      getIcon: active => <BrandingIcon fill={getStatusIconColor(active)} />,
      isProSetting: true,
    },
    {
      key: Routes.getSyncTagsSuffix(),
      text: 'Multi-store tagging',
      route: Routes.getSyncTagsRoute(),
      getIcon: active => <MultipleTagsIcon fill={getStatusIconColor(active)} />,
      disabled: shopifyApps?.length < 2,
    },
    {
      key: Routes.getPlansSuffix(),
      text: 'Plans',
      disabled: !isPricingEnabled || !packageGroup,
      route: Routes.getPlansRoute(),
      getIcon: active => <AccountPlansIcon fill={getStatusIconColor(active)} />,
    },
    {
      key: Routes.getBillingSuffix(),
      text: 'Billing',
      disabled: !isBillingPageEnabled,
      route: Routes.getBillingRoute(),
      getIcon: active => <AccountBillingsIcon fill={getStatusIconColor(active)} />,
    },
  ];

  function handleTabSelected(key) {
    const tab = leftSideTabs.find(x => x.key === key);

    const body = {
      pathname: `${Routes.getDashboardV2BaseRoute()}${tab.route}`,
    };

    track(tab.text + ' Tab Click');

    if (isMobile) {
      history.replace(body);
    } else {
      history.push(body);
    }
  }

  const selectedTab = leftSideTabs.find(x => location.pathname.endsWith(x.key)) || leftSideTabs[0];

  function shouldShowTab(leftSideTab) {
    return !leftSideTab.disabled;
  }

  return (
    <LayoutRoot>
      <SettingsTitle>Settings</SettingsTitle>
      <Content>
        {leftSideTabs
          .filter(leftSideTab => shouldShowTab(leftSideTab))
          .map(tab => {
            const active = selectedTab.key === tab.key;
            return (
              <LinkButton
                key={tab.key}
                active={active}
                text={tab.text}
                onClick={() => handleTabSelected(tab.key)}
                icon={tab.getIcon(active)}
                isProSetting={tab.isProSetting}
              />
            );
          })}
      </Content>
    </LayoutRoot>
  );
}

export default LeftSidebar;

const LayoutRoot = styled(VerticalFlex)`
  grid-column: 1;
  padding: 16px 24px 24px 24px;
  gap: 24px;
  height: 100%;
  width: 200px;

  @media (${({ theme }) => theme.breakpoints.dashboardTabletMax}) {
    grid-row: 1;
    width: 100%;
    overflow: hidden;
    padding: 16px 8px 0;
  }
`;

const SettingsTitle = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.neutralDarker};
`;

const Content = styled(VerticalFlex)`
  gap: 24px;
  width: 100%;
  min-width: 190px;

  @media (${({ theme }) => theme.breakpoints.dashboardTabletMax}) {
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0 0 8px;
  }
`;
