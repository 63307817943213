import React from 'react';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import StandardModal from 'app/src/pages/modals/StandardModal';
import HubspotMapping from 'app/src/pages/modals/hubspot_mapping/HubspotMapping';

const HubspotMappingModal = () => {
  const [, { clearModalState }] = useModal();

  return (
    <StandardModal open={true} onClose={clearModalState} width="927px" removeDefaultPadding>
      <HubspotMapping closeModal={clearModalState} />
    </StandardModal>
  );
};

export default HubspotMappingModal;
