import React from 'react';

const ChatIcon = ({ fill, darkBackground }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.002 19.722c5.369 0 9.722-4.353 9.722-9.722C19.724 4.63 15.37.278 10 .278 4.633.278.28 4.63.28 10c0 5.37 4.353 9.722 9.723 9.722Z"
        fill={fill || '#FFFFFF'}
      />
      <path
        d="M10.166 4.854c-3.235 0-5.833 2.142-5.833 4.74 0 1.139.479 2.164 1.299 2.985-.296 1.162-1.253 2.164-1.253 2.187a.219.219 0 0 0-.046.205c.046.069.091.091.182.091 1.504 0 2.644-.706 3.19-1.162a7.154 7.154 0 0 0 2.461.433c3.213 0 5.834-2.119 5.834-4.74 0-2.597-2.62-4.739-5.834-4.739Z"
        fill={darkBackground ? '#FFFFFF' : '#2F3137'}
      />
    </svg>
  );
};

export default ChatIcon;
