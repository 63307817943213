import React, { useRef, useState } from 'react';
import {
  OverflowContainer,
  PageContainer,
  PageMain,
} from 'app/src/pages/dashboard/components/page/Page';
import Filters from 'app/src/pages/dashboard/components/filters/Filters';
import { useProjects } from 'src/context/ProjectsStore';
import { useVirtualizedList } from 'src/hooks/useInfiniteScroll';
import { track } from 'src/helpers/Tracker';
import OnsiteGrid from './components/onsite_grid/OnsiteGrid';
import useOnsiteFilters from 'app/src/pages/dashboard/pages/onsites/hooks/useOnsiteFilters';
import { useDashboard } from 'src/context/ui_store/DashboardStore';
import {
  defaultFilterKeys,
  FilterKeys,
} from 'src/pages/dashboard/components/filters/filtersConfig';
import NumberOfEntities from 'app/src/pages/dashboard/pages/videos/components/number-of-entities/NumberOfEntities';
import Loading from 'app/src/pages/dashboard/components/loading/Loading';
import TopBar from '../../components/top-bar/TopBar';
import useUpdateProjectsMultiSelectState from 'src/hooks/useUpdateProjectsMultiSelectBarState';
import { useHistory } from 'react-router-dom';
import NewFromCaseOnsiteButton from 'app/src/pages/dashboard/pages/onsites/components/NewFromCaseOnsiteButton';
import NewFromScratchOnsiteButton from 'app/src/pages/dashboard/pages/onsites/components/NewFromScratchOnsiteButton';
import { isArchiveFolder } from 'src/utils/filters.utils';
import Routes from 'app/src/helpers/Routes';
import Sidebar from './components/sidebar/Sidebar';
import ExtraFilterButtons from './components/filter-buttons/ExtraFilterButtons';

const Onsite = () => {
  const [{ fetchedProjects }] = useProjects();
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [{ onSiteFilters }, { setOnSiteFilters }] = useDashboard();
  const history = useHistory();
  const isDiscoverTab = history.location.pathname.includes(Routes.getDashboardV2DiscoverRoute());
  const containerRef = useRef();
  const projects = useOnsiteFilters();
  const isArchiveFolderSelected = isArchiveFolder(onSiteFilters);

  const { renderCount, isDone, isLoading } = useVirtualizedList(
    containerRef,
    projects.length,
    null,
    onSiteFilters
  );

  const selectAll = () => {
    setSelectedProjects(projects.map(p => p.id));
  };

  const unselectAll = () => {
    setSelectedProjects([]);
  };

  const toggleSelected = videoId => {
    if (!selectedProjects.includes(videoId)) {
      track('Onsite Card Selected');
      setSelectedProjects([...selectedProjects, videoId]);
      return;
    }

    track('Onsite Card Selection Canceled');
    setSelectedProjects(selectedProjects.filter(id => id !== videoId));
  };

  const projectsToRender = projects.slice(0, renderCount);
  const isEmptyState = projects.length === 0;

  useUpdateProjectsMultiSelectState({
    selectedIds: selectedProjects,
    isOnsite: true,
    onCancel: unselectAll,
    selectAll,
    filters: onSiteFilters,
  });

  const availableFilters = [...defaultFilterKeys, FilterKeys.dynamic];

  const getTopBarRightContent = () => {
    if (isDiscoverTab) {
      return null;
    }

    return isEmptyState ? <NewFromScratchOnsiteButton /> : <NewFromCaseOnsiteButton />;
  };

  return (
    <PageContainer>
      {!isDiscoverTab && <Sidebar filters={onSiteFilters} setFilters={setOnSiteFilters} />}
      <PageMain>
        <TopBar
          leftContent={
            <Filters
              availableFilters={availableFilters}
              existingFilters={onSiteFilters}
              setFilters={setOnSiteFilters}
              extraFiltersSlot={
                <ExtraFilterButtons filters={onSiteFilters} setFilters={setOnSiteFilters} />
              }
            />
          }
          rightContent={getTopBarRightContent()}
        />
        <OverflowContainer ref={containerRef}>
          <OnsiteGrid
            items={projectsToRender}
            selectedItems={selectedProjects}
            toggleSelected={toggleSelected}
            fetchedProjects={fetchedProjects}
            isArchiveFolder={isArchiveFolderSelected}
          />
          <NumberOfEntities
            text={`${projects.length} Tolstoys shown`}
            shouldShow={isDone && projectsToRender.length}
          />
          <Loading isLoading={isLoading} />
        </OverflowContainer>
      </PageMain>
    </PageContainer>
  );
};

export default Onsite;
