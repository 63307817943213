import Utils from 'player/src/utils';
import { useEffect, useState } from 'react';
import useFeedModal from 'shared/react/components/complex/context/hooks/useFeedModal';
import useFeedState from 'shared/react/components/complex/context/hooks/useFeedState';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';
import {
  IS_AFTERPAY_APP_INSTALLED_MESSAGE,
  IS_REBUY_APP_INSTALLED_MESSAGE,
  PRODUCT_CARD_CLICK_SUBSCRIBED_MESSAGE,
  PRODUCT_SELECTED_MESSAGE,
  URL_LOCALE_UPDATE_MESSAGE,
  SHOW_FEED_PRODUCT_MODAL_MESSAGE,
  TOLSTOY_MODAL_CLOSE_MESSAGE,
  TOLSTOY_MODAL_OPEN_MESSAGE,
  TOLSTOY_PLAYER_PLAY_MESSAGE,
  TOLSTOY_PLAYER_READY_MESSAGE,
  MODAL_MESSAGING_READY_MESSAGE,
} from 'shared/react/constants/messages.constants';
import {
  ADD_TO_CART_ERROR_MESSAGE,
  ADD_TO_CART_SOLD_OUT_MESSAGE,
  ADD_TO_CART_SUCCESS_MESSAGE,
  CART_DATA_RESPONSE_MESSAGE,
  CART_ITEM_QUANTITY_CHANGE_ERROR_MESSAGE,
  CART_ITEM_QUANTITY_CHANGE_SUCCESS_MESSAGE,
  LOGIN_WITH_MULTIPASS_URL_RESPONSE_MESSAGE,
  PRODUCT_RECOMMENDATIONS_RESPONSE_MESSAGE,
} from 'shared/react/constants/shopifyMessaging.constants';
import { useFeedIsMobile } from 'shared/react/hooks/useIsMobile';
import SharedUtils from 'shared/react/utils/utils';
import useShopify from '../../../hooks/useShopify';
import { shouldForceMute } from 'shared/react/utils/sound';

let previouslyAddedMessageHandler = null;

const useFeedWidgetMessaging = ({ videoRef, setCurrentStepKey, currentStepKey, isClosedRef }) => {
  const [{ currentModal }, { popModal }] = useFeedModal();
  const [{ publishId }] = useProjectConfig();

  const [
    {
      isFeedMuted,
      isInitialMutedByDefault,
      setIsUserMute,
      setIsInitialMute,
      setPlayerVisible,
      setIsSessionStart,
      isPlaying,
      setIsPlaying,
      setIsRebuyAppInstalled,
      setIsAfterpayAppInstalled,
      setIsProductModalDisabled,
      setUrlLocale,
      isProductsInitialized,
    },
  ] = useFeedState();
  const [
    ,
    {
      requestShopifyCart,
      handleCartResponse,
      handleGetCartResponse,
      handleAddToCartError,
      handleUpdateItemQuantityError,
      requestProductRecommendations,
      handleGetProductRecommendationsResponse,
      handleLoginWithMultipassUrlResponse,
    },
  ] = useShopify();
  const isMobile = useFeedIsMobile();
  const [{ steps }, { getCurrentStep }] = useProjectConfig();

  const [isEventListenersInitialized, setIsEventListenersInitialized] = useState(false);

  const handleScrollToCurrentVideo = () => {
    if (!Utils.isFirefox()) {
      videoRef.current.scrollIntoView();
      return;
    }

    Utils.scheduleCallbackToNextRender(() => {
      videoRef.current.scrollIntoView();
    });
  };

  const closeCurrentModalIfOpen = () => {
    if (currentModal) {
      window.parent.postMessage({ name: SHOW_FEED_PRODUCT_MODAL_MESSAGE }, '*');
      popModal();
    }
  };

  const unmuteIfNeeded = () => {
    const step = getCurrentStep(currentStepKey);
    const shouldBeMuted =
      shouldForceMute(isFeedMuted, step.isSoundAllowed) || isInitialMutedByDefault;
    if (shouldBeMuted) {
      return;
    }

    if (videoRef.current?.ref?.dataset?.tolstoyVideoId !== currentStepKey) {
      return;
    }

    videoRef.current.muted = false;
    setIsUserMute(false);
    setIsInitialMute(false);
  };

  const handleTolstoyModalOpen = () => {
    requestShopifyCart();
    window.focus();
    handleScrollToCurrentVideo();
    setPlayerVisible(true);
    unmuteIfNeeded();
    closeCurrentModalIfOpen();

    requestAnimationFrame(() => {
      if (!isPlaying) {
        setIsPlaying(true);
      }
    });
  };

  const handleStepSelected = partNumber => {
    const stepKey = !Utils.isNullOrUndefined(partNumber) ? steps[partNumber]?.key : currentStepKey;

    if (!stepKey) {
      return;
    }

    if (stepKey !== currentStepKey) {
      videoRef.current.pause();
    }

    setCurrentStepKey(stepKey);

    Utils.scheduleCallbackToNextRender(() => {
      isClosedRef.current = false;
    });
  };

  const handleTolstoyModalClose = () => {
    setPlayerVisible(false);
    setIsSessionStart(true);
    videoRef.current.pause();
    isClosedRef.current = true;
  };

  const handleProductSelected = ({ productId }) => {
    if (!productId) {
      return;
    }

    requestProductRecommendations(productId);
  };

  const handleProductCardClickSubscribed = ({ isDisableProductModal }) => {
    if (!isMobile || !isDisableProductModal) {
      return;
    }

    setIsProductModalDisabled(true);
  };

  const handleUrlLocaleUpdate = ({ urlLocale }) => {
    setUrlLocale(urlLocale);
  };

  const handleMessages = ({ data }) => {
    const name = data.eventName || data;
    switch (name) {
      case TOLSTOY_MODAL_OPEN_MESSAGE:
        return handleTolstoyModalOpen();
      case TOLSTOY_MODAL_CLOSE_MESSAGE:
        return handleTolstoyModalClose();
      case TOLSTOY_PLAYER_PLAY_MESSAGE:
        return handleStepSelected(data.partNumber);
      case ADD_TO_CART_SOLD_OUT_MESSAGE:
      case ADD_TO_CART_SUCCESS_MESSAGE:
      case CART_ITEM_QUANTITY_CHANGE_SUCCESS_MESSAGE:
        return handleCartResponse(data);
      case ADD_TO_CART_ERROR_MESSAGE:
        return handleAddToCartError(data);
      case CART_ITEM_QUANTITY_CHANGE_ERROR_MESSAGE:
        return handleUpdateItemQuantityError(data);
      case CART_DATA_RESPONSE_MESSAGE:
        return handleGetCartResponse(data);
      case PRODUCT_SELECTED_MESSAGE:
        return handleProductSelected(data);
      case PRODUCT_RECOMMENDATIONS_RESPONSE_MESSAGE:
        return handleGetProductRecommendationsResponse(data);
      case LOGIN_WITH_MULTIPASS_URL_RESPONSE_MESSAGE:
        return handleLoginWithMultipassUrlResponse(
          data,
          publishId,
          steps.find(step => step.key === currentStepKey)
        );
      case IS_REBUY_APP_INSTALLED_MESSAGE:
        return setIsRebuyAppInstalled(data.value);
      case IS_AFTERPAY_APP_INSTALLED_MESSAGE:
        return setIsAfterpayAppInstalled(data.value);
      case PRODUCT_CARD_CLICK_SUBSCRIBED_MESSAGE:
        return handleProductCardClickSubscribed(data);
      case URL_LOCALE_UPDATE_MESSAGE:
        return handleUrlLocaleUpdate(data);
      default:
        return null;
    }
  };

  /**
   * This hack is needed here because:
   * - `useEffect` is called multiple times the video already plays.
   * - which leads to the fact that the `currentStepKey` scoped to an old key in the `unmuteIfNeeded` function
   * - which makes impossible to get relevant `step` in that function
   *
   * It is needed to get actual current `step` to force mute video.
   */
  {
    if (previouslyAddedMessageHandler) {
      window.removeEventListener('message', previouslyAddedMessageHandler);
    }
    window.addEventListener('message', handleMessages);
    previouslyAddedMessageHandler = handleMessages;
    if (!isEventListenersInitialized) {
      setIsEventListenersInitialized(true);
    }
  }

  useEffect(() => {
    SharedUtils.postMessageToWindow({
      eventName: TOLSTOY_PLAYER_READY_MESSAGE,
    });
    SharedUtils.postMessage({
      eventName: TOLSTOY_PLAYER_READY_MESSAGE,
    });
  }, []);

  useEffect(() => {
    if (!isEventListenersInitialized || !isProductsInitialized) {
      return;
    }

    SharedUtils.postMessageToWindow({ eventName: MODAL_MESSAGING_READY_MESSAGE });
  }, [isEventListenersInitialized, isProductsInitialized]);

  return null;
};

export default useFeedWidgetMessaging;
