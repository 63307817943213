import React from 'react';
import BrandingExpander from 'app/src/pages/account/branding/editors/BrandingExpander';
import EditorMainContainer from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorMainContainer';
import FeedDesignSettings from 'app/src/pages/account/branding/editors/feed/FeedDesignSettings';
import EditorSubheader from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorSubheader';
import { FeedProductCards } from 'app/src/types/design';
import {
  CTA_BUTTON_CHANGES,
  SALE_TEXT_CHANGES,
  TEXT_FIELDS_CHANGES,
} from 'app/src/pages/account/branding/editors/feed/FeedDesign.constants';

type Props = {
  settings: FeedProductCards;
  onChange: (settings: FeedProductCards, options: any) => void;
};

const FeedProductCardsEditor = ({ settings, onChange, fonts }: Props) => {
  const setFunction = (values, key) => {
    onChange({ ...settings, [key]: values }, {});
  };
  return (
    <BrandingExpander title="Feed product card">
      <EditorMainContainer>
        <EditorSubheader title="Product name" />
        <FeedDesignSettings
          setFunction={values => setFunction(values, 'productHeader')}
          values={settings.productHeader}
          fields={TEXT_FIELDS_CHANGES}
          fonts={fonts}
        />
        <EditorSubheader title="Product price" />
        <FeedDesignSettings
          setFunction={values => setFunction(values, 'productPrice')}
          values={settings.productPrice}
          fields={TEXT_FIELDS_CHANGES}
          fonts={fonts}
        />
        <EditorSubheader title="Sale price" />
        <FeedDesignSettings
          setFunction={values => setFunction(values, 'salePrice')}
          values={settings.salePrice}
          fields={SALE_TEXT_CHANGES}
          fonts={fonts}
        />
        <EditorSubheader title="Cta button" />
        <FeedDesignSettings
          setFunction={values => setFunction(values, 'ctaButton')}
          values={settings.ctaButton}
          fields={CTA_BUTTON_CHANGES}
          fonts={fonts}
        />
      </EditorMainContainer>
    </BrandingExpander>
  );
};

export default FeedProductCardsEditor;
