import React from 'react';
import styled from 'styled-components';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

const EmbedCarouselPreviewTitle = ({ size, widgetSettings }) => {
  const {
    carouselFontSize,
    carouselFontColor,
    carouselTitle,
    carouselTitleText,
    carouselTitleFontWeight,
  } = widgetSettings || {};
  const shouldShowTitle = carouselTitle && carouselTitleText;
  const { branding } = useDesignSettingsContext();

  if (!shouldShowTitle) {
    return null;
  }

  return (
    <LayoutRoot
      fontWeight={carouselTitleFontWeight}
      fontSize={carouselFontSize / size}
      fontFamily={branding.typography.font?.family}
      color={carouselFontColor}
    >
      {carouselTitleText}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)`
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: ${({ fontWeight }) => fontWeight};
  font-family: ${({ fontFamily }) => fontFamily};
  align-self: center;
`;

export default EmbedCarouselPreviewTitle;
