import React, { FC } from 'react';

type Props = {
  className?: string;
};

const CsvFileIcon: FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      width="46"
      height="47"
      viewBox="0 0 46 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.978.75h21.374v7.876a3.75 3.75 0 0 0 3.742 3.75l7.883.17v30.58a2.25 2.25 0 0 1-2.25 2.25H3.977a2.25 2.25 0 0 1-2.25-2.25V3A2.25 2.25 0 0 1 3.978.75Zm22.874 1.061.806.806 3.399 3.399 5.011 5.01-6.95-.15h-.016a2.25 2.25 0 0 1-2.25-2.25V1.81Z"
        fill="#F4F5F7"
        stroke="#7D8087"
      />
      <path
        d="M8.48 35.989a2.847 2.847 0 0 0 2.835 2.835l30.877.004a2.847 2.847 0 0 0 2.835-2.835V24.21a2.845 2.845 0 0 0-2.835-2.835H11.315a2.842 2.842 0 0 0-2.836 2.831V35.99Z"
        fill="#F4F5F7"
      />
      <path
        d="M11.316 38.824a2.847 2.847 0 0 1-2.836-2.835V24.206a2.842 2.842 0 0 1 2.835-2.83h30.878a2.845 2.845 0 0 1 2.835 2.834v11.783a2.847 2.847 0 0 1-2.835 2.835l-30.877-.004Zm10.045-7.316-2.359-.713c-.12.551-.307.968-.577 1.26-.263.293-.653.435-1.166.435-.533 0-.945-.18-1.238-.536-.292-.36-.439-1.02-.439-1.984 0-.776.124-1.346.368-1.71.326-.491.795-.735 1.406-.735a1.54 1.54 0 0 1 1.286.638c.094.12.18.315.263.577l2.37-.525c-.304-.919-.773-1.601-1.399-2.044-.626-.442-1.492-.663-2.587-.663-1.403 0-2.487.386-3.255 1.158-.765.773-1.148 1.88-1.148 3.32 0 1.08.221 1.968.656 2.658.435.69.953 1.174 1.553 1.451.6.274 1.372.413 2.321.413.78 0 1.421-.113 1.928-.338a3.247 3.247 0 0 0 1.267-1.005c.341-.442.593-.997.75-1.657Zm3.416-.192-2.553.162c.075.877.397 1.6.967 2.16.57.57 1.594.855 3.075.855.84 0 1.538-.12 2.093-.364a2.923 2.923 0 0 0 1.293-1.073c.308-.472.462-.986.462-1.545 0-.476-.113-.907-.345-1.29-.23-.382-.6-.705-1.11-.963-.507-.26-1.347-.514-2.524-.77-.473-.097-.773-.202-.896-.318-.128-.109-.192-.232-.192-.371a.61.61 0 0 1 .237-.484c.16-.131.397-.199.708-.199.38 0 .675.09.893.27.214.18.356.47.424.863l2.53-.15c-.112-.904-.457-1.564-1.042-1.976-.58-.417-1.428-.623-2.542-.623-.908 0-1.62.113-2.141.341-.522.225-.912.54-1.17.938-.26.397-.39.817-.39 1.264 0 .682.255 1.24.76 1.68.503.442 1.34.795 2.52 1.057.717.161 1.178.326 1.373.506.195.18.293.383.293.608 0 .236-.101.446-.311.626-.207.18-.503.27-.885.27-.518 0-.912-.176-1.19-.529-.168-.213-.28-.532-.337-.945Zm8.674-5.666H30.64l3.292 8.708h2.914l3.232-8.708h-2.73l-1.935 6.266-1.96-6.266Z"
        fill="#7D8087"
      />
    </svg>
  );
};

export default CsvFileIcon;
