import React from 'react';

import { useFeatureActions } from 'src/context/FeaturesStore';
import EditorGroup from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorGroup';
import FeedCarouselTitleEditor from 'app/src/pages/project/pages/look_and_feel/editor/editors/feed_carousel_editor/FeedCarouselTitleEditor';
import FeedCarouselBorderEditor from 'app/src/pages/project/pages/look_and_feel/editor/editors/feed_carousel_editor/FeedCarouselBorderEditor';
import FeedCarouselGeneralEditor from 'app/src/pages/project/pages/look_and_feel/editor/editors/feed_carousel_editor/FeedCarouselGeneralEditor';
import FeedCarouselTileNameEditor from 'app/src/pages/project/pages/look_and_feel/editor/editors/feed_carousel_editor/FeedCarouselTileNameEditor';
import FeedCarouselPlayButtonEditor from 'app/src/pages/project/pages/look_and_feel/editor/editors/feed_carousel_editor/PlayButtonFeedEmbedEditor';

import { useLookAndFeelContext } from '../../../LookAndFeelContext';
import { CAROUSEL_TYPE } from '../../../LookAndFeelPage.constants';
import { FEATURE_WIDGET_SPOTLIGHT_CAROUSEL } from 'src/constants/appFeatures.constants';

const FeedCarouselEditorGroup = () => {
  const { getFeatureEnabled } = useFeatureActions();
  const isSpotlightCarouselEnabled = getFeatureEnabled(FEATURE_WIDGET_SPOTLIGHT_CAROUSEL);
  const {
    customizationSettings: {
      widgetSettings: { carouselType },
    },
  } = useLookAndFeelContext();

  const isSpotlightCarousel =
    isSpotlightCarouselEnabled && carouselType === CAROUSEL_TYPE.SPOTLIGHT;

  return (
    <EditorGroup
      editors={[
        {
          key: 'general',
          component: <FeedCarouselGeneralEditor />,
        },
        {
          key: 'border',
          hidden: isSpotlightCarousel,
          component: <FeedCarouselBorderEditor disabled={isSpotlightCarousel} />,
        },
        {
          key: 'title',
          component: <FeedCarouselTitleEditor />,
        },
        {
          key: 'tileName',
          hidden: isSpotlightCarousel,
          component: <FeedCarouselTileNameEditor disabled={isSpotlightCarousel} />,
        },
        {
          key: 'playButton',
          hidden: isSpotlightCarousel,
          component: <FeedCarouselPlayButtonEditor disabled={isSpotlightCarousel} />,
        },
      ]}
    />
  );
};

export default FeedCarouselEditorGroup;
