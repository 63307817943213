import { createActionsHook, createContainer, createHook, createStore } from 'react-sweet-state';
import Utils from 'app/src/utils';
import { VodConnectionSuggestionStatus, vodConnectionSuggestion } from 'app/src/types/entities';
import { getUniqueVodConnectionSuggestions } from 'src/utils/vodConnectionSuggestion.utils';
import { Observable } from 'zen-observable-ts';
import {
  fetchPendingVodConnectionSuggestions,
  getCreateVodConnectionSuggestionSubscription,
  updateVodConnectionSuggestion,
} from 'src/services/vod-connection-suggestion/VodConnectionSuggestionGraphqlService';

type State = {
  isInitialized: boolean;
  vodConnectionSuggestions: vodConnectionSuggestion[];
  rawVodConnectionSuggestions: vodConnectionSuggestion[];
  selectedAppUrl: string;
  createSubscription: Observable<object> | null;
};

const initialState: State = {
  isInitialized: false,
  vodConnectionSuggestions: [],
  rawVodConnectionSuggestions: [],
  selectedAppUrl: '',
  createSubscription: null,
};

const actions = {
  fetch:
    () =>
    async ({ setState }, { appKey }) => {
      const pendingSuggestions = await fetchPendingVodConnectionSuggestions({ appKey });

      const uniqueSuggestions = getUniqueVodConnectionSuggestions(pendingSuggestions);

      setState({
        vodConnectionSuggestions: uniqueSuggestions,
        rawVodConnectionSuggestions: pendingSuggestions,
        isInitialized: true,
      });
    },
  subscribeCreateVodConnectionSuggestion:
    () =>
    async ({ setState, getState }, { appKey }) => {
      try {
        const subscription = getCreateVodConnectionSuggestionSubscription({ appKey });

        subscription.subscribe({
          next: async (response: any) => {
            const newSuggestion = response.value.data.onCreateVodConnectionSuggestionByAppKey;
            if (newSuggestion.status !== VodConnectionSuggestionStatus.pending) {
              return;
            }

            const { vodConnectionSuggestions, rawVodConnectionSuggestions } = getState();

            const uniqueSuggestions = getUniqueVodConnectionSuggestions([
              ...vodConnectionSuggestions,
              newSuggestion,
            ]);

            setState({
              vodConnectionSuggestions: uniqueSuggestions,
              rawVodConnectionSuggestions: [...rawVodConnectionSuggestions, newSuggestion],
            });
          },
          error: ({ error: { errors = [] } = {} }) => {
            Utils.logErrorMessage(
              errors[0]?.message || 'Error in onCreateVodConnectionSuggestionByAppKey subscribe'
            );
          },
        });

        setState({ createSubscription: subscription });
      } catch (error) {
        Utils.logError('Failed to subscribe to onCreateVodConnectionSuggestionByAppKey', error);
      }
    },
  updateSuggestionStatus:
    ({ externalProductId, vodAssetId, status }) =>
    async ({ getState, setState }) => {
      const { rawVodConnectionSuggestions, vodConnectionSuggestions } = getState();

      // update all suggestions with the same externalProductId and vodAssetId as the one being updated
      const correspondingSuggestions = rawVodConnectionSuggestions.filter(
        suggestion =>
          suggestion.externalProductId === externalProductId && suggestion.vodAssetId === vodAssetId
      );

      const promises = correspondingSuggestions.map(suggestion =>
        updateVodConnectionSuggestion({
          id: suggestion.id,
          status,
        })
      );

      await Promise.all(promises);

      const updatedSuggestions = vodConnectionSuggestions.filter(
        suggestion => !correspondingSuggestions.some(({ id }) => id === suggestion.id)
      );

      setState({
        vodConnectionSuggestions: updatedSuggestions,
      });
    },
  setSelectAppUrl:
    (selectedAppUrl: string) =>
    ({ setState }) => {
      setState({ selectedAppUrl });
    },
  clearStore:
    () =>
    ({ setState }) => {
      setState(initialState);
    },
};

const VodConnectionSuggestionStore = createStore({
  initialState,
  actions,
});

type VodConnectionSuggestionSelectorArgs = {
  vodAssetId: string;
};

const vodConnectionSuggestionSelector = (
  state: State,
  { vodAssetId }: VodConnectionSuggestionSelectorArgs
) => {
  const { vodConnectionSuggestions, selectedAppUrl } = state;

  const filteredSuggestions = vodConnectionSuggestions.filter(
    suggestion => suggestion.vodAssetId === vodAssetId && suggestion.appUrl === selectedAppUrl
  );

  return {
    ...state,
    vodConnectionSuggestions: filteredSuggestions,
  };
};

export const useVodConnectionSuggestion = createHook(VodConnectionSuggestionStore);

export const useVodConnectionSuggestionByVodAssetId = createHook(VodConnectionSuggestionStore, {
  selector: vodConnectionSuggestionSelector,
});

export const useVodConnectionSuggestionActions = createActionsHook(VodConnectionSuggestionStore);

export const VodConnectionSuggestionContainer = createContainer(VodConnectionSuggestionStore, {
  onInit:
    () =>
    ({ dispatch }) => {
      dispatch(actions.fetch()), dispatch(actions.subscribeCreateVodConnectionSuggestion());
    },
  onCleanup:
    () =>
    ({ dispatch }) =>
      dispatch(actions.clearStore()),
});
