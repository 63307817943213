import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useBrandingContext } from '../BrandingContext';
import PlayerEditorGroup from './player/PlayerEditorGroup';
import GeneralEditorGroup from './general/GeneralEditorGroup';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Separator from 'shared/react/components/basic/Separator';
import FeedEditorGroup from 'app/src/pages/account/branding/editors/feed/FeedEditorGroup';
import BranchingEditorGroup from 'app/src/pages/account/branding/editors/player/branching/BranchingEditorGroup';
import WidgetsEditor from 'app/src/pages/account/branding/editors/widgets/WidgetsEditor';

const BrandingEditor = ({
  settings = {},
  features = {},
  defaultSettings = {},
  onChange = (settings, options) => {},
}) => {
  const layoutRootRef = useRef();
  const { setEditorRightLocation } = useBrandingContext();

  const updateSettings = (update, options) => onChange({ ...settings, ...update }, options);
  const onBrandingChange = (settings, options) => updateSettings({ branding: settings }, options);
  const onPlayerChange = (settings, options) => updateSettings({ player: settings }, options);
  const onWidgetsChange = (settings, options) => updateSettings({ widgets: settings }, options);
  const onFeedDesignChange = (settings, options) =>
    updateSettings({ feedDesign: settings }, options);

  useEffect(() => {
    const { right } = layoutRootRef?.current?.getBoundingClientRect() || {};
    if (right) {
      setEditorRightLocation(right);
    }
  }, [layoutRootRef]);

  return (
    <LayoutRoot ref={layoutRootRef}>
      <GeneralEditorGroup
        settings={settings.branding}
        features={features}
        defaultSettings={defaultSettings.branding}
        onChange={onBrandingChange}
      />
      <Separator />
      <PlayerEditorGroup
        settings={settings.player}
        features={features}
        defaultSettings={defaultSettings.player}
        onChange={onPlayerChange}
      />
      <Separator />
      <FeedEditorGroup
        settings={settings.feedDesign}
        features={features}
        fonts={settings.branding.typography}
        defaultSettings={defaultSettings.feedDesign}
        onChange={onFeedDesignChange}
      />
      <Separator />
      <WidgetsEditor
        settings={settings.widgets}
        features={features}
        fonts={settings.branding.typography}
        defaultSettings={defaultSettings.widgets}
        onChange={onWidgetsChange}
      />
      <Separator />
      <BranchingEditorGroup
        settings={settings.player}
        features={features}
        defaultSettings={defaultSettings.player}
        onChange={onPlayerChange}
      />
    </LayoutRoot>
  );
};

export default BrandingEditor;

const LayoutRoot = styled(VerticalFlex)`
  height: fit-content;
  min-width: 424px;
  max-width: 424px;
`;
