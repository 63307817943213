import { useEffect, useState } from 'react';

import { getRankedVideosByAppUrl } from 'shared/react/services/feed-videos.service';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';

const useFeedTrendingVideos = () => {
  const [{ appUrl }] = useProjectConfig();
  const [isLoading, setIsLoading] = useState(false);
  const [trendingVideos, setTrendingVideos] = useState([]);

  const formatRankedVideos = videos => {
    return videos.map(video => ({
      id: video.vodAssetId,
      score: video.totalRating,
      posterUrl: video.posterUrl,
      videoUrl: video.videoUrl,
    }));
  };

  const getTrendingVideos = async () => {
    try {
      setIsLoading(true);
      const { data, status } = await getRankedVideosByAppUrl({ appUrl });

      if (status !== 200) {
        return;
      }

      const formattedVideos = formatRankedVideos(data);

      setTrendingVideos(formattedVideos);
      return formattedVideos;
    } catch (error) {
      return;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTrendingVideos();
  }, []);

  return { trendingVideos, isLoading };
};

export default useFeedTrendingVideos;
