import React from 'react';

function AIcon({ fill, darkBackground, size = 20 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={12} cy={12} r={12} fill={fill || '#FFFFFF'} />
      <path
        d="M8.383 15.5h1.595l.485-1.397h3.08l.484 1.397h1.595l-2.85-8.03h-1.54L8.383 15.5Zm3.62-5.852 1.034 2.992h-2.069l1.034-2.992Z"
        fill={darkBackground ? '#FFFFFF' : '#2F3137'}
      />
    </svg>
  );
}

export default AIcon;
