import VideosTopBar from 'app/src/pages/dashboard/pages/videos/components/top-bar/VideosTopBar';
import VideosContent from 'app/src/pages/dashboard/pages/videos/components/videos-content/VideosContent';
import { PageMain } from 'app/src/pages/dashboard/components/page/Page';
import React, { useEffect, useState } from 'react';
import { LibraryPageProps } from 'app/src/pages/dashboard/pages/videos/components/library-pages/library-pages-types';
import {
  MEDIA_SOURCE_IMPORT_METHODS,
  MediaLibrarySource,
} from 'app/src/pages/dashboard/pages/videos/hooks/media/mediaLibraryUtils';
import { useOnSelectedVideosAction } from 'app/src/pages/dashboard/pages/videos/hooks/useOnSelectedVideosAction';
import { SortDirection } from 'app/src/pages/dashboard/pages/videos/hooks/search/searchRequests';
import { usePaginatedVodAssets } from 'app/src/pages/dashboard/pages/videos/hooks/search/usePaginatedVodAssets';
import { useVideos } from 'app/src/context/VideosStore';

export const DropboxLibraryPage = React.forwardRef(
  (
    {
      videosFilters,
      setVideosFilters,
      onVideoChoose,

      selectedVideos,
      setSelectedVideos,
      toggleSelected,
    }: LibraryPageProps,
    containerRef
  ) => {
    const method = MEDIA_SOURCE_IMPORT_METHODS[MediaLibrarySource.DROPBOX];

    const [dateSortDirection, setDateSortDirection] = useState(SortDirection.desc);

    const { videos, isLoading, loadMore, isFetchingNextPage, refetch } = usePaginatedVodAssets({
      videosFilters,
      dateSortDirection,
    });
    const initialized = !isLoading;

    const [data] = useVideos();
    useEffect(() => {
      // We need refetch here to get new video assets after video was updated
      refetch();
    }, [data]);

    const { onSelectedVideosAction } = useOnSelectedVideosAction({
      videos,
      method,
      selectedVideos,
      setSelectedVideos,
    });

    return (
      <PageMain>
        <VideosTopBar
          isModal={!!onVideoChoose}
          videosFilters={videosFilters}
          setVideosFilters={setVideosFilters}
          onSelectedVideosAction={onSelectedVideosAction}
          canSortDate={true}
          dateSortDirection={dateSortDirection}
          setDateSortDirection={setDateSortDirection}
        />
        <VideosContent
          ref={containerRef}
          videos={videos}
          selectedVideos={selectedVideos}
          toggleSelected={toggleSelected}
          onSelectedVideosAction={onSelectedVideosAction}
          initialized={initialized}
          method={method}
          videosFilters={videosFilters}
          onVideoChoose={onVideoChoose}
          isLoading={isFetchingNextPage}
          onScrollEndCallback={loadMore}
        />
      </PageMain>
    );
  }
);
DropboxLibraryPage.displayName = 'DropboxLibraryPage';
