import React from 'react';
import LayoutEditor from 'app/src/pages/project/pages/look_and_feel/editor/editors/player_editor/LayoutEditor';
import ControlsEditor from 'app/src/pages/project/pages/look_and_feel/editor/editors/player_editor/ControlsEditor';
import FeaturesEditor from 'app/src/pages/project/pages/look_and_feel/editor/editors/player_editor/FeaturesEditor';
import ButtonsEditor from 'app/src/pages/project/pages/look_and_feel/editor/editors/player_editor/ButtonsEditor';
import EditorGroup from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorGroup';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import ProFeaturesEditor from 'app/src/pages/project/pages/look_and_feel/editor/editors/player_editor/ProFeaturesEditor';

function PlayerEditorGroup() {
  const {
    customizationSettings: { feed },
  } = useLookAndFeelContext();

  return (
    <EditorGroup
      editors={[
        { key: 'layout', component: <LayoutEditor />, hidden: feed },
        { key: 'controls', component: <ControlsEditor /> },
        { key: 'proFeaturesEditor', component: <ProFeaturesEditor /> },
        { key: 'features', component: <FeaturesEditor />, hidden: feed },
        { key: 'buttons', component: <ButtonsEditor />, hidden: true },
      ]}
    />
  );
}

export default PlayerEditorGroup;
