import { css } from 'styled-components';

const milkCss = css`
  position: relative;
  top: 3px;
`;

export const milkConditionCss = css`
  ${({ isMilk }) => isMilk && milkCss}
`;

export const topTabsCss = css`
  ${({ isActive }) => (isActive ? `&::after` : '')} {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 45%;
    height: 2.5px;
    background-color: ${({ theme, inModal }) =>
      inModal ? theme.colors.black : theme.colors.white};
    transform: translateX(-50%);
  }
`;
