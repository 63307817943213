import React from 'react';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import styled from 'styled-components';

type Props = {
  imageUrl: string;
  title: string;
};

const VirtualizedProductDetails = ({ imageUrl, title }: Props) => {
  return (
    <LayoutRoot>
      <Image src={imageUrl} />
      <EllipsisTextOverflowWrapper>
        <TextTiny>{title}</TextTiny>
      </EllipsisTextOverflowWrapper>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlex)`
  align-items: center;
`;

const Image = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.boxShadows.elevation1};
`;

export default VirtualizedProductDetails;
