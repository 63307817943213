import React from 'react';

const SizingIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5 6.25V3.5625L9.03125 8L13.5 12.4688V9.75C13.5 9.34375 13.8125 9 14.25 9C14.6562 9 15 9.34375 15 9.75V14.25C15 14.375 14.9688 14.4688 14.9375 14.5625C14.8438 14.75 14.7188 14.875 14.5312 14.9688C14.4375 15 14.3438 15 14.25 15H9.75C9.3125 15 9 14.6875 9 14.25C9 13.8438 9.3125 13.5 9.75 13.5H12.4375L8 9.0625L3.53125 13.5H6.25C6.65625 13.5 7 13.8438 7 14.25C7 14.6875 6.65625 15 6.25 15H1.75C1.625 15 1.53125 15 1.4375 14.9688C1.25 14.875 1.125 14.75 1.03125 14.5625C1 14.4688 1 14.375 1 14.25V9.75C1 9.34375 1.3125 9 1.75 9C2.15625 9 2.5 9.34375 2.5 9.75V12.4688L6.9375 8L2.5 3.5625V6.25C2.5 6.6875 2.15625 7 1.75 7C1.3125 7 1 6.6875 1 6.25V1.75C1 1.65625 1 1.5625 1.03125 1.46875C1.125 1.28125 1.25 1.15625 1.4375 1.0625C1.53125 1.03125 1.625 1 1.75 1H6.25C6.65625 1 7 1.34375 7 1.75C7 2.1875 6.65625 2.5 6.25 2.5H3.53125L8 6.96875L12.4375 2.5H9.75C9.3125 2.5 9 2.1875 9 1.75C9 1.34375 9.3125 1 9.75 1H14.25C14.3438 1 14.4375 1.03125 14.5312 1.0625C14.7188 1.15625 14.8438 1.28125 14.9375 1.46875C14.9688 1.5625 15 1.65625 15 1.75V6.25C15 6.6875 14.6562 7 14.25 7C13.8125 7 13.5 6.6875 13.5 6.25Z"
        fill="#2F3137"
      />
    </svg>
  );
};

export default SizingIcon;
