import React from 'react';
import styled from 'styled-components';
import { OVERLAY_TEXT_DATA_TEST_ID } from '../../../constants/dataTestIds.constants';

function OverlayText({ isVertical, isProducts, overlayText, overlayTextColor, customLayout }) {
  return (
    <LayoutRoot isVertical={isVertical} isProducts={isProducts}>
      <ControlsOverlayText
        style={customLayout?.overlayText}
        data-test-id={OVERLAY_TEXT_DATA_TEST_ID}
        overlayTextColor={overlayTextColor}
      >
        {overlayText}
      </ControlsOverlayText>
    </LayoutRoot>
  );
}

export default OverlayText;

const LayoutRoot = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  width: ${({ isProducts }) => (isProducts ? '' : '100%')};
  grid-area: overlay-text;
  ${({ isProducts, isVertical }) => (isProducts && !isVertical ? 'margin: 0 20px;' : '')}
`;

const ControlsOverlayText = styled.div`
  z-index: 1;
  align-self: center;
  justify-self: center;
  color: ${({ theme, overlayTextColor }) => overlayTextColor || theme.colors.white};
  font-size: 24px;
  font-weight: bold;
  text-shadow: ${({ theme }) => `0 0 6px ${theme.colors.black}`};
  margin: 8px 16px 16px;
`;
