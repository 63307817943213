import React, { useState } from 'react';
import { Link } from '@material-ui/core';
import { TextSmall, TextBody } from 'shared/react/components/complex/Text';
import Button from 'shared/react/components/complex/Button';
import SnackBar from 'app/src/basic_components/SnackBar';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import { useApps } from 'app/src/context/AppsStore';
import Utils from 'app/src/utils';
import styled from 'styled-components';
import { DASHBOARD_TABLET_MAX_WIDTH } from 'shared/react/utils/mediaQuery';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import CollapsingToolbarArrowRight from 'app/src/images/CollapsingToolbarArrowRight';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import EmbedStepper from 'app/src/pages/modals/publish/embed/EmbedStepper';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { FEATURE_WIDGET_SET_LIVE } from 'shared/react/constants/features.constants';

function WixEmbed({ publishId, getCode, appUrl, project }) {
  const [, { getWixEmbedRedirectLink }] = useApps();
  const [copySnackbar, setCopySnackbar] = useState(false);
  const [rotationDegrees, setRotationDegrees] = useState(0);
  const [, { getFeatureEnabled }] = useFeatures();
  const isWidgetSetLiveEnabled = getFeatureEnabled(FEATURE_WIDGET_SET_LIVE);

  async function handleGetEmbedLink() {
    const url = getWixEmbedRedirectLink(publishId);
    Utils.openInNewTab(url);
  }

  const handleCollapse = () => {
    setRotationDegrees(rotationDegrees === 90 ? 0 : 90);
  };

  return (
    <LayoutRoot>
      <Container>
        <FlexContainer>
          <TopLeftContainer>
            <HeaderText>Directly add your Tolstoy to Wix store</HeaderText>
            <WixAttention>This will redirect you to select where to place the Tolstoy</WixAttention>
            <StyledHorizontalFlex>
              <TextSmall>Your website:</TextSmall>
              <Link onClick={() => Utils.openInNewTab(appUrl)} href="">
                {appUrl}
              </Link>
            </StyledHorizontalFlex>
          </TopLeftContainer>
          <Button disabled={!isWidgetSetLiveEnabled} onClick={handleGetEmbedLink}>
            Add
          </Button>
        </FlexContainer>
      </Container>
      <BottomContainer>
        <CollapseHeaderContainer onClick={handleCollapse}>
          <CollapseHeaderText>Or embed your Tolstoy using code</CollapseHeaderText>
          <CollapsingToolbarArrowRight rotationDegrees={rotationDegrees} />
        </CollapseHeaderContainer>
        {!!rotationDegrees && <EmbedStepper project={project} getCode={getCode} />}
      </BottomContainer>
      <SnackBar
        text="Copied to Clipboard!"
        open={copySnackbar}
        setOpen={setCopySnackbar}
        severity="success"
      />
    </LayoutRoot>
  );
}

export default WixEmbed;

const LayoutRoot = styled(VerticalFlex)`
  @media (${DASHBOARD_TABLET_MAX_WIDTH}) {
    flex-wrap: unset;
    overflow: auto;
  }
`;

const Container = styled.div`
  padding-bottom: 40px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    padding: 0;
    border: 0;
  }
`;

const FlexContainer = styled(HorizontalFlexWrap)`
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  @media (${DASHBOARD_TABLET_MAX_WIDTH}) {
    flex-wrap: unset;
    overflow: auto;
    margin-bottom: 8px;
  }
`;

const HeaderText = styled(TextBody)`
  font-weight: 800;
`;

const CollapseHeaderText = styled(HeaderText)``;

const WixAttention = styled(TextSmall)`
  font-weight: 600;
  color: orange;
`;

const StyledHorizontalFlex = styled(HorizontalFlexWrap)`
  align-items: center;
  gap: 8px;
`;

const TopLeftContainer = styled(Gap8VerticalFlex)``;

const BottomContainer = styled.div``;

const CollapseHeaderContainer = styled(Gap8HorizontalFlexWrap)`
  align-items: center;
  cursor: pointer;
  width: max-content;
  padding-bottom: 16px;
`;
