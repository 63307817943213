import DefaultVideoPlaceholder from 'app/src/images/default_project_placeholder.svg';
import React from 'react';
import styled from 'styled-components';
import { TextH6 } from 'shared/react/components/complex/Text';
import Button from 'shared/react/components/complex/Button';
import Types from 'shared/react/theme/Types';

const VideoNotFound = ({ handleChangeVideoClick, loadingState }) => {
  return (
    <VideoNotFoundContainer>
      <img alt="No video" src={DefaultVideoPlaceholder} />
      <TextH6>Your video wasn&apos;t found.</TextH6>
      <Button
        type={Types.Ghost}
        onClick={handleChangeVideoClick}
        disabled={!!loadingState}
        fullWidth
      >
        Change video
      </Button>
    </VideoNotFoundContainer>
  );
};

const VideoNotFoundContainer = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  justify-self: center;
  align-self: center;
  gap: 16px;
`;

export default VideoNotFound;
