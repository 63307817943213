import React from 'react';
import styled from 'styled-components';
import useProjectConfig from '../../context/hooks/useProjectConfig';
import HorizontalFlexWrap from '../../flex_layouts/HorizontalFlexWrap';
import FeedProductStock from 'shared/react/components/complex/feed/feed_products/FeedProductStock';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import useRecharge from 'shared/react/components/complex/context/hooks/useRecharge';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';

const FeedProductSalesBadge = ({ selectedVariant, product }) => {
  const { id: selectedVariantId } = selectedVariant || {};

  const [, { getShouldShowRecharge }] = useRecharge();

  const shouldShowRecharge = getShouldShowRecharge({
    productId: product.id,
    variantId: selectedVariantId,
  });

  const { player } = useDesignSettingsContext();
  const { raidus: borderRadius } = player.feedCards.buttonsDesign.primary.border;
  const [{ playerSettings }] = useProjectConfig();
  const { price, compareAtPrice } = selectedVariant || {};

  const getIsOnSale = () => {
    if (!compareAtPrice || !price || !+compareAtPrice) {
      return false;
    }

    return +compareAtPrice > +price;
  };

  const {
    productDetailsSalesBadgeEnabled: enabled,
    productDetailsSalesBadgeText: text,
    productDetailsSalesBadgeFontColor: fontColor,
    productDetailsSalesBadgeBackgroundColor: backgroundColor,
    productDetailsSalesBadgeBorderEnabled: borderEnabled,
    productDetailsSalesBadgeBorderWidth: borderWidth,
    productDetailsSalesBadgeBorderColor: borderColor,
  } = playerSettings || {};

  if (!enabled || !getIsOnSale()) {
    if (shouldShowRecharge) {
      return (
        <SaleBadgeAndStockContainer>
          <FeedProductStock selectedVariant={selectedVariant} />
        </SaleBadgeAndStockContainer>
      );
    }

    return <Empty />;
  }

  if (!shouldShowRecharge) {
    return (
      <SaleBadgeAndStockContainer>
        <LayoutRoot
          fontColor={fontColor}
          backgroundColor={backgroundColor}
          borderEnabled={borderEnabled}
          borderWidth={borderWidth}
          borderColor={borderColor}
          borderRadius={borderRadius}
        >
          {text}
        </LayoutRoot>
      </SaleBadgeAndStockContainer>
    );
  }

  return (
    <SaleBadgeAndStockContainer>
      <LayoutRoot
        fontColor={fontColor}
        backgroundColor={backgroundColor}
        borderEnabled={borderEnabled}
        borderWidth={borderWidth}
        borderColor={borderColor}
        borderRadius={borderRadius}
      >
        {text}
      </LayoutRoot>
      <FeedProductStock selectedVariant={selectedVariant} />
    </SaleBadgeAndStockContainer>
  );
};

const LayoutRoot = styled(HorizontalFlexWrap)`
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding: 8px 12px;
  border-radius: ${({ borderRadius }) => borderRadius ?? 4}px;
  width: fit-content;
  color: ${({ fontColor, theme }) => fontColor || theme.colors.black};
  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.colors.white};
  border: ${({ borderEnabled, borderWidth = 1, borderColor, theme }) => {
    if (!borderEnabled) {
      return '';
    }

    return `${borderWidth}px solid ${borderColor || theme.colors.black}`;
  }};
`;

const Empty = styled.div`
  display: none;
`;

const SaleBadgeAndStockContainer = styled(HorizontalFlex)`
  justify-content: space-between;
  align-items: center;
`;

export default FeedProductSalesBadge;
