import React, { useState } from 'react';
import MenuWithContainer from 'shared/react/components/basic/MenuWithContainer';
import styled, { useTheme } from 'styled-components';
import { SearchInput } from 'app/src/basic_components/input/InputWithIcon';
import { useVodLabels } from 'app/src/context/VodLabelsStore';
import MenuItemV3 from 'app/src/complex_components/menu_items/MenuItemV3';
import OverlaySpinner from 'app/src/basic_components/OverlaySpinner';
import ThinPlusIcon from 'shared/react/images/ThinPlusIcon';
import useKeyPress from 'shared/react/hooks/useKeyPress';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextSmall } from 'shared/react/components/basic/text/TextV2';
import useCreateIntegrationLabels from 'app/src/hooks/useCreateIntegrationLabels';
import Spinner from 'app/src/basic_components/Spinner';
import { CheckboxV2 } from 'app/src/complex_components/Checkbox';
import { useVideoActions } from 'app/src/context/VideosStore';

enum LoadingState {
  creatingLabel = 'creating-label',
  creatingIntegrationLabel = 'creating-integration-label',
}

const LabelsMenu = ({ menuRef, onClose, selectedVideos }) => {
  const [{ vodLabels }, { createVodLabel }] = useVodLabels();
  const { createIntegrationLabels } = useCreateIntegrationLabels();
  const { getVideoById } = useVideoActions();
  const [searchValue, setSearchValue] = useState('');
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [loading, setLoading] = useState<LoadingState>(null);
  const theme = useTheme();

  const onSearchProduct = e => {
    setSearchValue(e.target.value);
  };

  const items = vodLabels.filter(label => {
    if (searchValue && !label.name.toLowerCase().includes(searchValue.toLowerCase())) {
      return null;
    }

    return label;
  });

  const onClick = (labelId: string) => {
    if (selectedLabels.includes(labelId)) {
      setSelectedLabels(selectedLabels.filter(id => id !== labelId));
      return;
    }

    setSelectedLabels([...selectedLabels, labelId]);
  };

  const findAndAddBySearchValue = () => {
    const existingLabel = vodLabels.find(({ name }) => {
      return name.toLowerCase() === searchValue.toLowerCase();
    });

    if (!existingLabel) {
      return false;
    }

    onClick(existingLabel.id);
    setSearchValue('');
    return true;
  };

  const onEnter = async () => {
    if (!searchValue || findAndAddBySearchValue() || items.length || loading) {
      return;
    }

    setLoading(LoadingState.creatingLabel);
    const newLabel = await createVodLabel({ name: searchValue });

    onClick(newLabel.id);
    setSearchValue('');
    setLoading(null);
  };

  const onDone = async () => {
    setLoading(LoadingState.creatingIntegrationLabel);
    const promises = selectedVideos.map(id => {
      const video = getVideoById(id);
      return createIntegrationLabels(selectedLabels, video);
    });

    await Promise.all(promises);

    setLoading(null);
    onClose();
  };

  useKeyPress('Enter', onEnter);
  const isCreatingLabel = loading === LoadingState.creatingLabel;
  const isDone = loading === LoadingState.creatingIntegrationLabel;
  return (
    <Menu open={!!menuRef} anchorEl={menuRef} onClose={onClose}>
      <SearchInput
        disabled={!!loading}
        placeholder="Search..."
        value={searchValue}
        onChange={onSearchProduct}
      />
      <ItemsList>
        {items.map(({ id, name }) => (
          <MenuItemV3
            onClick={() => onClick(id)}
            icon={<CheckboxV2 checked={selectedLabels.includes(id)} />}
            key={id}
          >
            {name}
          </MenuItemV3>
        ))}
        <OverlaySpinner isLoading={isCreatingLabel} size={16}>
          <AddLabelItem
            icon={<ThinPlusIcon />}
            onClick={onEnter}
            disabled={!searchValue}
            isLoading={isCreatingLabel}
            color={theme.colors.primary}
          >
            Create new label
          </AddLabelItem>
        </OverlaySpinner>
      </ItemsList>
      <ButtonContainer onClick={onDone}>
        <DoneButton>{isDone ? <Spinner size={16} /> : 'Done'}</DoneButton>
      </ButtonContainer>
    </Menu>
  );
};

const Menu = styled(MenuWithContainer)`
  width: 250px;
`;

const ItemsList = styled.div`
  max-height: 250px;
  overflow: auto;
  .MuiCheckbox-root {
    padding: 0;
  }
`;

const AddLabelItem = styled(MenuItemV3)`
  path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const DoneButton = styled(TextSmall)`
  padding: 8px 0;
  color: ${({ theme }) => theme.colors.primary};
`;

const ButtonContainer = styled(HorizontalFlex)`
  border-top: 1px solid ${({ theme }) => theme.colors.neutralLighter};
  justify-content: center;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.neutralLightest};
  }

  &:hover ${DoneButton} {
    color: ${({ theme }) => theme.colors.primaryHover};
  }
`;

export default LabelsMenu;
