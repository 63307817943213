import ColorfulWrapper from 'app/src/basic_components/ColorfulWrapper';
import { AnalyticsContainer } from 'app/src/context/AnalyticsStore';
import { useDashboard } from 'app/src/context/ui_store/DashboardStore';
import Routes from 'app/src/helpers/Routes';
import AccountAnalyticsPage from 'app/src/pages/account/AccountAnalyticsPage';
import AccountPage from 'app/src/pages/account/AccountPage';
import Contacts from 'app/src/pages/contacts/Contacts';
import Offsite from 'app/src/pages/dashboard/pages/offsite/Offsite';
import Onsite from 'app/src/pages/dashboard/pages/onsites/Onsite';
import ShopApp from 'app/src/pages/dashboard/pages/shop-app/ShopApp';
import Videos from 'app/src/pages/dashboard/pages/videos/Videos';
import UgcPage from 'app/src/pages/dashboard/pages/ugc/UgcPage';
import Integrations from 'app/src/pages/integrations/Integrations';
import Sidebar from 'app/src/pages/sidebar/Sidebar';
import { accountSettings as AccountSettings } from 'app/src/types/entities';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Homepage from 'src/pages/dashboard/pages/homepage/Homepage';
import styled from 'styled-components';
import Products from './pages/products/Products';
import AiTagging from './pages/ai-tagging/AiTagging';
import CreateOnsiteCase from 'app/src/pages/dashboard/pages/onsites/CreateCase';
import CreateOffsiteCase from 'app/src/pages/dashboard/pages/offsite/CreateCase';
import Audience from './pages/audience/Audience';
import Utils from 'src/utils';
import { useAnalyticsSearchParams } from 'app/src/complex_components/analytics/analyticsSearchParams';
import { getTypeFilterValue } from 'app/src/utils/filters.utils';
import { ASSET_TYPE } from 'shared/react/services/assets';
import VideosV2 from 'app/src/pages/dashboard/pages/videos/VideosV2';

type Props = {
  accountDetails: {
    appKey: string;
    isAccountOwner: boolean;
    accounts: AccountSettings[];
  };
};

const Dashboard = ({ accountDetails }: Props) => {
  const [{ videosFilters }, { setVideosFilters }] = useDashboard();
  const analyticParams = useAnalyticsSearchParams();

  const carouselFilters = {
    type: getTypeFilterValue(ASSET_TYPE.GALLERY),
  };

  return (
    <ColorfulWrapper>
      <LayoutRoot>
        <Sidebar />
        <Content>
          <Switch>
            <Route path={Routes.getHomepageRoute()}>
              <Homepage />
            </Route>
            <Route path={Routes.getProductsRoute()}>
              <Products analyticsData={Utils.getAnalyticsLocationObject('Products Tab')} />
            </Route>
            <Route path={Routes.getAiTaggingRoute()}>
              <AiTagging />
            </Route>
            <Route path={Routes.getDashbaordV2VideosRoute()}>
              <Videos videosFilters={videosFilters} setVideosFilters={setVideosFilters} />
            </Route>
            <Route path={Routes.getDashboardCarouselRoute()}>
              <Videos videosFilters={carouselFilters} setVideosFilters={setVideosFilters} />
            </Route>
            <Route path={Routes.getDashboardLibraryRoute()}>
              <VideosV2 videosFilters={videosFilters} setVideosFilters={setVideosFilters} />
            </Route>
            <Route path={Routes.getDashboardV2OnsiteCreateCaseRoute()}>
              <CreateOnsiteCase />
            </Route>
            <Route path={Routes.getDashboardV2OffsiteCreateCaseRoute()}>
              <CreateOffsiteCase />
            </Route>
            <Route path={Routes.getDashboardV2DiscoverRoute()}>
              <Onsite />
            </Route>
            <Route path={Routes.getDashboardV2OnsiteRoute()}>
              <Onsite />
            </Route>
            <Route path={Routes.getShopHomeFeedRoute()}>
              <Products analyticsData={Utils.getAnalyticsLocationObject('Shop Tab')} />
            </Route>
            <Route path={Routes.getShopRoute()}>
              <ShopApp />
            </Route>
            <Route path={Routes.getDashboardV2OffsiteRoute()}>
              <Offsite />
            </Route>
            <Route path={Routes.getDashboardV2ThreadsRoute()}>
              <Contacts />
            </Route>
            <Route path={Routes.getDashboardV2IntegrationsRoute()}>
              <Integrations />
            </Route>
            <Route path={Routes.getDashboardV2AccountBaseRoute()}>
              <AccountPage />
            </Route>
            <Route path={Routes.getDashboardV2AnalyticsRoute()}>
              <AnalyticsContainer
                {...accountDetails}
                initialPublishIds={analyticParams.publishIds}
                initialSelectedTab={analyticParams.tab}
                isGlobal
              >
                <AccountAnalyticsPage />
              </AnalyticsContainer>
            </Route>
            <Route path={Routes.getDashboardBaseRoute()}>
              <Redirect to={Routes.getDashboardLibraryRoute('')} />
            </Route>
            <Route path={Routes.getAudienceRoute()}>
              <Audience />
            </Route>
            <Route path={Routes.getDashbaordV2UgcRoute()}>
              <UgcPage />
            </Route>
          </Switch>
        </Content>
      </LayoutRoot>
    </ColorfulWrapper>
  );
};

const LayoutRoot = styled(HorizontalFlex)`
  height: 100vh;
  align-items: stretch;
  overflow: hidden;
`;

const Content = styled(VerticalFlex)`
  width: 100%;
  height: 100%;
  overflow: hidden;

  & > * {
    height: 100%;
  }
`;

export default Dashboard;
