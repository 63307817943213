import React from 'react';
import styled from 'styled-components';

const getShouldShowLowerLine = (stepComponents, index) => {
  const step = index + 1;
  const nextStep = index + 2;
  const nextStepComponent = stepComponents[index + 1];
  const isLastStep = step === stepComponents.length;
  return isLastStep || (nextStep === stepComponents.length && !!nextStepComponent?.props?.disabled);
};

const Stepper = ({ stepComponents, optional = [], stepsGap = 32 }) => {
  return (
    <StepsContainer $stepsGap={stepsGap}>
      {stepComponents.map((stepComponent, index) => {
        const step = index + 1;
        const stepTextSuffix = optional.includes(step) ? ' - optional' : '';
        const stepText = `Step ${step}${stepTextSuffix}`;
        const shouldRemoveStepHeader = stepComponent?.props?.removeStepHeader;
        const shouldShowLowerLine = getShouldShowLowerLine(stepComponents, index);
        const isStepDisabled = stepComponent?.props?.disabled;

        if (isStepDisabled) {
          return;
        }

        return (
          <StepContainer shouldRemoveStepHeader={shouldRemoveStepHeader} key={index}>
            <StepCounterLine />
            {!shouldRemoveStepHeader && !index && <StepCounterWhiteUpperLine />}
            {shouldShowLowerLine && (
              <StepCounterWhiteLowerLine shouldRemoveStepHeader={shouldRemoveStepHeader} />
            )}
            <StepCounter shouldRemoveStepHeader={shouldRemoveStepHeader}>{step}</StepCounter>
            {!shouldRemoveStepHeader && (
              <StepText customStyle={stepComponent?.props?.stepTextStyle}>{stepText}</StepText>
            )}
            {stepComponent}
          </StepContainer>
        );
      })}
    </StepsContainer>
  );
};

export default Stepper;

const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > * + * {
    padding-top: ${({ $stepsGap }) => `${$stepsGap || 0}px`};
  }
`;

const StepContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: min-content auto;
  column-gap: 24px;
  grid-template-rows: ${({ shouldRemoveStepHeader }) =>
    shouldRemoveStepHeader ? 'min-content' : 'min-content 4px min-content'};

  & > * {
    grid-row: ${({ shouldRemoveStepHeader }) => (shouldRemoveStepHeader ? '1' : '3')};
    grid-column: 2;
  }
`;

const StepCounter = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.primaryLight};
  padding: 5px 8px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.blue7};
  grid-column: 1;
  grid-row: ${({ shouldRemoveStepHeader }) => (shouldRemoveStepHeader ? '1' : '2')};
  align-self: ${({ shouldRemoveStepHeader }) => (shouldRemoveStepHeader ? 'baseline' : 'center')};
`;

const StepCounterLine = styled.div`
  position: absolute;
  height: 100%;
  width: 2px;
  grid-column: 1/1;
  grid-row: initial;
  background: ${({ theme }) => theme.colors.primaryLight};
  justify-self: center;
`;

const StepCounterWhiteUpperLine = styled(StepCounterLine)`
  grid-row: 1/1;
  background: ${({ theme }) => theme.colors.white};
`;

const StepCounterWhiteLowerLine = styled(StepCounterLine)`
  grid-row: ${({ shouldRemoveStepHeader }) => (shouldRemoveStepHeader ? '1/4' : '2/4')};
  background: ${({ theme }) => theme.colors.white};
`;

const StepText = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.black};
  grid-row: 1;
  ${({ customStyle }) => customStyle}
`;
