import React from 'react';
import styled, { css } from 'styled-components';

import Menu from 'shared/react/components/basic/Menu';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { BREAKPOINT_OPTIONS } from '../../../../constants/menu.constants';
import { TextSmall } from 'shared/react/components/basic/text/TextV2';
import FilledReportIcon from '../../../../images/feed/FilledReportIcon';
import useFeedModal from '../../context/hooks/useFeedModal';
import { REPORT_MODAL_KEY } from '../../../../constants/modals.constants';
import useKeyboardNavigation from 'player/src/hooks/useKeyboardNavigation';

const MoreOptionsMenu = ({
  anchorEl,
  setAnchorEl,
  isMobile,
  currentStepKey,
  videoRef,
  videoId,
}) => {
  const [, { pushModal }] = useFeedModal();
  const { onKeyDown, setNextRef } = useKeyboardNavigation();

  const handleMenuClosed = () => {
    videoRef.current.play();

    setAnchorEl(null);
  };

  const handleOpenReportMenu = e => {
    e.stopPropagation();

    videoRef.current.pause();

    setAnchorEl(null);

    pushModal({
      key: REPORT_MODAL_KEY,
      props: {
        isMobile,
        onClose: handleMenuClosed,
        currentStepKey,
        videoId,
      },
    });
  };

  return (
    <>
      <StyledMenu
        direction={{
          vertical: 'top',
          horizontal: 'left',
        }}
        breakpoint={BREAKPOINT_OPTIONS.moveToTop}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        disableAutoFocusItem
        open={!!anchorEl}
        onClick={handleMenuClosed}
        onClose={handleMenuClosed}
      >
        <Container>
          <ShareOptionContainer
            onClick={e => handleOpenReportMenu(e)}
            role="button"
            aria-label="Report"
            ref={setNextRef}
            onKeyDown={onKeyDown}
            tabIndex="0"
          >
            <FilledReportIcon />
            <RedTextSmall>Report</RedTextSmall>
          </ShareOptionContainer>
        </Container>
      </StyledMenu>
    </>
  );
};

const RedTextSmall = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.fireRed};
`;

const StyledMenu = styled(Menu)`
  background: ${({ theme }) => theme.getHexOpacity(theme.colors.black, 80)};
  border-radius: 12px;
  backdrop-filter: blur(10px);

  &:hover {
    background: ${({ theme }) => theme.getHexOpacity(theme.colors.black, 50)};
  }
`;

const Container = styled(VerticalFlex)`
  min-width: 150px;
  border-radius: 32px;
  gap: 16px;
`;

const MenuCss = css`
  text-align: start;
  color: ${({ theme }) => theme.colors.black};
`;

const ShareOptionContainer = styled.div`
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  ${MenuCss};
  font-weight: 510;
  font-size: 16px;
  line-height: 16px;
  margin: 0 8px;
  border-radius: 8px;
  height: 40px;
  user-select: none;
  gap: 8px;
`;

export default MoreOptionsMenu;
