import React from 'react';

const ThinPlusIcon = () => (
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.65 3.794H4.088v2.562h-.756V3.794H.77v-.756h2.562V.476h.756v2.562H6.65z"
      fill="#000"
    />
  </svg>
);

export default ThinPlusIcon;
