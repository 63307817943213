import React from 'react';
import Utils from 'app/src/utils';
import XIcon from 'app/src/images/XIcon';
import SuccessIcon from 'app/src/images/creation_method_icons/SuccessIcon';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import Gap16HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap16HorizontalFlexWrap';
import { TextBody } from 'shared/react/components/complex/Text';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import { loadingTitles } from 'app/src/pages/creation_flow/creationFlowConstants';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

const Message = ({
  uploadProgress,
  isUploadFile,
  resetVideoState,
  onCreateNewVideo,
  uploadedBlob,
  uploadState,
  videosUploadCompleted,
  messageText,
}) => {
  const getStatusText = () => {
    if (videosUploadCompleted?.outOf) {
      return `Uploading ${videosUploadCompleted.completed} / ${videosUploadCompleted.outOf}`;
    }

    return 'Uploading video';
  };

  if (isUploadFile && Utils.isNullOrUndefined(uploadProgress) && uploadedBlob && !uploadState) {
    return (
      <UseThisVideoContainer>
        <ControlsContainer isUploading={true}>
          <StatusText>{messageText || 'Use this video?'}</StatusText>
          <ButtonsContainer>
            <DangerIconContainer onClick={resetVideoState}>
              <XIcon size={24} />
            </DangerIconContainer>
            <SuccessIconContainer onClick={() => onCreateNewVideo({ blob: uploadedBlob })}>
              <SuccessIcon size={20} />
            </SuccessIconContainer>
          </ButtonsContainer>
        </ControlsContainer>
      </UseThisVideoContainer>
    );
  }

  if (!Utils.isNullOrUndefined(uploadProgress)) {
    return (
      <MessageContainer>
        <UploadProgressControlsContainer>
          <SuccessUploadContainer>
            <CircularProgress size={16} />
            {uploadProgress}
          </SuccessUploadContainer>
          <StatusText>{getStatusText()}</StatusText>
        </UploadProgressControlsContainer>
      </MessageContainer>
    );
  }

  if (uploadState) {
    return (
      <MessageContainer>
        <ControlsContainer>
          <LoadingSuccessContainer>
            <CircularProgress size={16} />
          </LoadingSuccessContainer>
          <StatusText>{loadingTitles[uploadState]}</StatusText>
        </ControlsContainer>
      </MessageContainer>
    );
  }

  return null;
};

const MessageContainer = styled(Gap16HorizontalFlexWrap)`
  justify-content: center;
  align-items: center;
  padding: 16px;
  position: absolute;
  width: 100%;
  height: 100%;
  flex-wrap: nowrap;
  z-index: 11;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    grid-row: 2;
  }
`;

const UseThisVideoContainer = styled(MessageContainer)`
  align-items: flex-end;
`;

const ControlsContainer = styled(HorizontalFlex)`
  padding: 4px 16px 4px 8px;
  align-items: center;
  border-radius: 12px;
  flex-wrap: nowrap;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 10px 25px rgba(50, 50, 93, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.05);
  height: 72px;
  gap: 12px;
`;

const UploadProgressControlsContainer = styled(ControlsContainer)`
  padding: 4px 12px 4px 8px;
`;

const StatusText = styled(TextBody)`
  font-weight: 700;
  line-height: 16px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  height: 56px;
  width: 56px;
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : '')};
  border-radius: 16px;

  & path {
    transition: 0.3s;
    fill: ${({ theme }) => theme.colors.ghostDark};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.ghostDark};
  }
`;

const DangerIconContainer = styled(IconContainer)`
  background: ${({ theme }) => theme.colors.pink6};

  & path {
    fill: ${({ theme }) => theme.colors.danger};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.pink6};

    & path {
      fill: ${({ theme }) => theme.colors.pink2};
    }
  }
`;

const SuccessIconContainer = styled(IconContainer)`
  background: ${({ theme }) => theme.colors.successLight};

  & path {
    fill: ${({ theme }) => theme.colors.success};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.successLightHover};

    & path {
      fill: ${({ theme }) => theme.colors.successHover};
    }
  }
`;

const ButtonsContainer = styled(Gap8HorizontalFlexWrap)``;

const SuccessUploadContainer = styled(SuccessIconContainer)`
  display: flex;
  gap: 16px;
  width: auto;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.successDark};
  padding: 10px 16px;
  & circle {
    stroke: ${({ theme }) => theme.colors.success};
    stroke-width: 5px;
  }
`;

const LoadingSuccessContainer = styled(SuccessUploadContainer)`
  width: 56px;
  height: 56px;
`;

export default Message;
