import React from 'react';
import styled, { useTheme } from 'styled-components';

import Dot from './Dot';

function WindowActionBarButtons({ ...props }) {
  const theme = useTheme();

  return (
    <LayoutRoot {...props}>
      <Dot color={theme.colors.red1} />
      <Dot color={theme.colors.yellow4} />
      <Dot color={theme.colors.green4} />
    </LayoutRoot>
  );
}

export default WindowActionBarButtons;

const LayoutRoot = styled.div`
  display: flex;
  flex-direction: row;
  justify-self: start;
  gap: 8px;
  margin-bottom: 16px;
`;
