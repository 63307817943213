import React from 'react';

const Pencil = ({ fill }) => {
  return (
    <svg width="12" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.6641 0.945312C11.3828 0.664062 10.9844 0.5 10.6094 0.5C10.2344 0.5 9.83594 0.664062 9.55469 0.945312L2.05469 8.44531C1.84375 8.65625 1.70312 8.91406 1.63281 9.19531L1 12.0547C0.953125 12.2891 1.14062 12.5 1.35156 12.5C1.375 12.5 1.42188 12.5 1.44531 12.5C1.44531 12.5 3.41406 12.0781 4.30469 11.8672C4.58594 11.8203 4.82031 11.6797 5.03125 11.4688C6.17969 10.3203 10.5156 5.98438 12.5547 3.94531C13.1406 3.35938 13.1406 2.42188 12.5547 1.83594L11.6641 0.945312ZM4.23438 10.6719C4.1875 10.7422 4.11719 10.7656 4.04688 10.7891C3.625 10.8828 2.94531 11.0234 2.35938 11.1641L2.73438 9.42969C2.75781 9.35938 2.78125 9.28906 2.85156 9.24219L8.5 3.57031L9.92969 5L4.23438 10.6719Z"
        fill={fill || '#090A0B'}
      />
    </svg>
  );
};

export default Pencil;
