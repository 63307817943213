import { LOGIN_CODE_ID_LOCAL_STORAGE_KEY } from 'shared/react/constants/feature-constants/customerLogin.constants';
import { REACT_APP_API_BASE_URL } from '../config/shared.config';

export const storeCodeId = codeId => {
  try {
    sessionStorage.setItem(LOGIN_CODE_ID_LOCAL_STORAGE_KEY, codeId);
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getStoredCodeId = () => {
  try {
    return sessionStorage.getItem(LOGIN_CODE_ID_LOCAL_STORAGE_KEY);
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getMultipassUrl = async (code, codeId, redirectPath) => {
  if (!code || !codeId) {
    return;
  }

  try {
    const response = await fetch(
      `${REACT_APP_API_BASE_URL}/customer/auth/login-with-verification-code`,
      {
        method: 'POST',
        body: JSON.stringify({ data: { code, codeId, redirectPath } }),
      }
    );
    const responseJson = await response.json();
    return responseJson.url;
  } catch (_error) {
    // Do nothing
  }
};

export const verifyCustomerEmail = async (email, appUrl) => {
  if (!email || !appUrl) {
    return;
  }
  try {
    const response = await fetch(`${REACT_APP_API_BASE_URL}/customer/auth/verify-email`, {
      method: 'POST',
      body: JSON.stringify({ data: { email, appUrl } }),
    });

    const responseJson = await response.json();
    const codeId = responseJson?.codeId;
    return codeId;
  } catch (_error) {
    // Do nothing
  }
};
