import React from 'react';
import EditorSubheader from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorSubheader';
import PrimaryButtonSettingsEditor from './PrimaryButtonSettingsEditor';

type Props = {
  settings?: {
    settings: {
      includeProductPrice: boolean;
      hasQuantityPicker: boolean;
    };
  };
  disabled?: boolean;
  onChange?: any;
};

const PrimaryButtonEditor = ({ settings, disabled = false, onChange = () => {} }: Props) => {
  const updateSettings = update => onChange({ ...settings, ...update });
  const handlePrimarySettingsUpdate = settings => updateSettings({ settings });

  return (
    <>
      <EditorSubheader title="Primary button" />
      <PrimaryButtonSettingsEditor
        settings={settings.settings}
        disabled={disabled}
        onChange={handlePrimarySettingsUpdate}
      />
    </>
  );
};

export default PrimaryButtonEditor;
