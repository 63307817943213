import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import Input from 'app/src/basic_components/Input';
import { useDebounce } from 'app/src/hooks/useDebounce';

const ToolBar = ({
  projectId,
  isSaveLoading,
  disabled,
  inputRef,
  selectedKeys,
  onMultipleChange,
  setSelectedKeys,
  isLoading,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const debouncedIsFocus = useDebounce(isFocused, 500);

  useEffect(() => {
    if (!debouncedIsFocus) {
      setSelectedKeys([]);
    }
  }, [debouncedIsFocus]);

  return (
    <Container isInputShown={selectedKeys.length} {...props}>
      <FlexContainer>
        <CustomInput
          onChange={onMultipleChange}
          isVisible={selectedKeys.length}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          inputProps={{ maxLength: 2000 }}
          inputRef={inputRef}
          disabled={isLoading}
          placeholder="You can link to products, calendars, checkout, etc."
          label="Apply to all selected links"
        />
      </FlexContainer>
    </Container>
  );
};

export default ToolBar;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 12px 24px 0 24px;
  @media (${({ theme }) => theme.breakpoints.dashboardTabletMax}) {
    min-height: ${({ isInputShown }) => (isInputShown ? '130px' : '72px')};
    max-width: 100%;
    overflow: auto;
    padding: 8px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 16px 16px 0;

  @media (${({ theme }) => theme.breakpoints.dashboardTabletMax}) {
    flex-direction: column;
    justify-content: center;
  }
`;

const CustomInput = styled(Input)`
  background-color: white;
  width: 400px;
  visibility: ${({ isVisible }) => (isVisible ? '' : 'hidden')};
  @media (${({ theme }) => theme.breakpoints.dashboardTabletMax}) {
    width: 90%;
    margin: 12px auto;
  }
`;
