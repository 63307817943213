import React from 'react';
import styled from 'styled-components';
import FeedProductPrice from 'shared/react/components/complex/feed/feed_products/FeedProductPrice';
import FeedProductStock from 'shared/react/components/complex/feed/feed_products/FeedProductStock';
import useRecharge from 'shared/react/components/complex/context/hooks/useRecharge';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import Utils from 'shared/react/utils/utils';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';

const FeedProductDetails = ({ product, selectedVariant, isMultipleProductDetails }) => {
  const [, { getShouldShowRecharge }] = useRecharge();
  const [{ playerSettings }] = useProjectConfig();
  const { productDetailsPriceEnabled } = playerSettings || {};
  const { player, feedDesign } = useDesignSettingsContext();
  const { includeProductPrice } = player.feedCards.productDetailsCard.primaryButton.settings;
  const shouldShowRecharge = getShouldShowRecharge({
    productId: product?.id,
    variantId: selectedVariant?.id,
    isMultipleProductDetails,
  });
  const isInStock = selectedVariant?.isVariantAvailableForSale;
  const { productDetailsStockEnabled } = playerSettings || {};

  const primaryButtonHasPrice = includeProductPrice && !isMultipleProductDetails;

  const shouldNotShowPrice = productDetailsPriceEnabled === false || primaryButtonHasPrice;
  const shouldNotShowStock =
    !productDetailsStockEnabled || isMultipleProductDetails || Utils.isNullOrUndefined(isInStock);

  if (shouldShowRecharge || (shouldNotShowStock && shouldNotShowPrice)) {
    return null;
  }

  return (
    <PriceAndStockContainer>
      <FeedProductPrice
        product={product}
        selectedVariant={selectedVariant}
        isMultipleProductDetails={isMultipleProductDetails}
        design={feedDesign?.inFeedPDP}
      />
      <FeedProductStock
        selectedVariant={selectedVariant}
        isMultipleProductDetails={isMultipleProductDetails}
      />
    </PriceAndStockContainer>
  );
};

const PriceAndStockContainer = styled(HorizontalFlex)`
  justify-content: space-between;
`;

export default FeedProductDetails;
