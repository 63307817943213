import React from 'react';

const NotificationIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.336 1.125c0-.313.312-.625.625-.625.351 0 .664.313.664.625v.664C12.75 2.102 15.25 4.758 15.25 8v1.172c0 1.68.664 3.32 1.875 4.531l.117.117c.313.313.508.742.508 1.211 0 .977-.781 1.719-1.758 1.719H1.97a1.685 1.685 0 0 1-1.72-1.719c0-.469.156-.898.469-1.21l.117-.118c1.21-1.21 1.914-2.851 1.914-4.531V8a6.237 6.237 0 0 1 5.625-6.21l-.04-.665ZM8.96 3c-2.734 0-5 2.266-5 5v1.172a7.654 7.654 0 0 1-2.227 5.43l-.117.078c-.078.117-.156.234-.156.351 0 .274.234.469.508.469h14.023c.274 0 .508-.195.508-.469 0-.117-.078-.234-.156-.351l-.117-.078A7.653 7.653 0 0 1 14 9.172V8c0-2.734-2.266-5-5.04-5Zm0 16.25a1.27 1.27 0 0 0 1.21-.82c.118-.313.47-.508.782-.39a.655.655 0 0 1 .39.82c-.35.976-1.288 1.64-2.382 1.64a2.493 2.493 0 0 1-2.344-1.64.654.654 0 0 1 .39-.82c.313-.118.665.077.782.39.195.508.664.82 1.172.82Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default NotificationIcon;
