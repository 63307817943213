import React from 'react';
import InstallStepSimple from 'app/src/pages/project/pages/installation/components/shared/InstallStepSimple';
import styled from 'styled-components';
import { VerticalFlexCentered } from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import AppEmbedInstallationSrc from 'app/src/images/AppEmbedInstallation.png';
import { app } from 'app/src/types/entities';
import ShopifyInstallDescription from 'app/src/pages/project/pages/installation/components/shared/account/ShopifyInstallDescription';
import ShopifyInstallText from 'app/src/pages/project/pages/installation/components/shared/account/ShopifyInstallText';
import GreenCheckCircleIcon from 'app/src/images/dashboard_v2/GreenCheckCircleIcon';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

type Props = {
  appUrl: string;
  isInstallationCompleted: boolean;
  currentApp: app;
};

const AccountInstallationShopify = ({ appUrl, isInstallationCompleted, currentApp }: Props) => {
  return (
    <InstallStepSimple
      image={
        <ImageContainer>
          <AppEmbedInstallationImg src={AppEmbedInstallationSrc} />
        </ImageContainer>
      }
      text={<ShopifyInstallText appUrl={appUrl} />}
      stepHeader={
        <Header>
          {isInstallationCompleted && <GreenCheckCircleIcon />}
          Add Tolstoy app to store
        </Header>
      }
      headerOptionalText="(Once per store)"
      isDisabled={false}
      startExpanded={!isInstallationCompleted}
      description={<ShopifyInstallDescription currentApp={currentApp} appUrl={appUrl} />}
    />
  );
};

const AppEmbedInstallationImg = styled.img`
  width: 400px;
  max-width: 100%;
`;
const ImageContainer = styled(VerticalFlexCentered)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.neutralLightest};
  padding: 16px;
  border-radius: 4px;
`;

const Header = styled(Gap8HorizontalFlex)`
  align-items: center;
`;

export default AccountInstallationShopify;
