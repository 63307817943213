import React, { useState, useEffect } from 'react';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import PlaylistTag from './PlaylistTag';
import { GroupSelectMenu, Group } from 'src/complex_components/group-select-menu';
import { PLAYLIST_TAG_MODES } from './constants';
import { compilePlaylistsMenuGroups } from './utils';
import { Playlist } from './types';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import {
  ADD_EDIT_PLAYLIST_MODAL_KEY,
  CREATION_FLOW_MODAL_KEY,
} from 'app/src/constants/modals.constants';
import { MODAL_STATE } from 'app/src/pages/modals/folder_modal/folders.constants';
import { LIBRARY } from 'app/src/constants/creation_method.constants';

export type PlaylistListProps = {
  playlists: Playlist[];
  maxVisiblePlaylists?: number;
  onPlaylistClick?: (playlist: Playlist) => void;
  onUpdateRuleCondition?: (playlist: Playlist, newCondition: string) => void;
};

const PlaylistList = ({
  playlists = [],
  maxVisiblePlaylists,
  onPlaylistClick = () => {},
  onUpdateRuleCondition,
}: PlaylistListProps) => {
  const [anchorElement, setAnchorElement] = useState(null);
  const [visiblePlaylists, setVisiblePlaylists] = useState<Playlist[]>([]);
  const [playlistsMenuGroups, setPlaylistsMenuGroups] = useState<Group[]>([]);
  const [, { setCurrentModal, setCurrentModalType, setModalProps }] = useModal();
  const shouldShowAddPlaylist = !!playlistsMenuGroups.length;

  const openPlaylistMenu = e => setAnchorElement(e.currentTarget);
  const closePlaylistMenu = () => setAnchorElement(null);

  const openNewPlaylistModal = () => {
    setModalProps({ onSaveExternal: openVideoLibraryModal });
    setCurrentModal(ADD_EDIT_PLAYLIST_MODAL_KEY);
    setCurrentModalType(MODAL_STATE.new);
  };

  const openVideoLibraryModal = () => {
    setModalProps({
      isLeftPanelVisible: false,
      shouldCreateProject: false,
      defaultSelectedMethod: LIBRARY,
    });

    setCurrentModal(CREATION_FLOW_MODAL_KEY);
  };

  useEffect(() => {
    const visiblePlaylists = playlists.slice(0, maxVisiblePlaylists);
    const groups = compilePlaylistsMenuGroups(playlists.slice(maxVisiblePlaylists));

    setVisiblePlaylists(visiblePlaylists);
    setPlaylistsMenuGroups(groups);
  }, [playlists]);

  const playlistsTags = visiblePlaylists.map(playlist => {
    const { value, name, type, mode, ctaText, shouldShowCtaText, isSelectConditionDisabled } =
      playlist;
    const onClick = () => onPlaylistClick(playlist);

    return (
      <PlaylistTag
        key={`${type}-${value}`}
        name={name}
        $mode={mode}
        ctaText={ctaText}
        shouldShowCtaText={shouldShowCtaText}
        onClick={onClick}
        playlist={playlist}
        onUpdateRuleCondition={onUpdateRuleCondition}
        isSelectConditionDisabled={isSelectConditionDisabled}
      />
    );
  });

  return (
    <Gap8HorizontalFlexWrap>
      {playlistsTags}
      <PlaylistTag
        name="+ "
        subName="New playlist"
        onClick={openNewPlaylistModal}
        isSelectConditionDisabled
      />
      {shouldShowAddPlaylist && (
        <>
          <PlaylistTag
            name="+ Playlist"
            $mode={PLAYLIST_TAG_MODES.OUTLINE}
            onClick={openPlaylistMenu}
            isSelectConditionDisabled
          />
          <GroupSelectMenu
            groups={playlistsMenuGroups}
            anchorElement={anchorElement}
            $width="208"
            $maxHeight="290"
            withSearch
            onDone={closePlaylistMenu}
            onClose={closePlaylistMenu}
            onItemSelect={item => onPlaylistClick(item.value)}
          />
        </>
      )}
    </Gap8HorizontalFlexWrap>
  );
};

export default PlaylistList;
