import { FC } from "preact/compat";
import { Button } from "../types/config";
import { createStyleObject, isMobileSafari } from "../utils";
import { EVENTS } from "../constants";

type Props = Button & {
  onSendEvent: (eventName: string) => void;
  isMobile: boolean;
};

const OverlayButton: FC<Props> = ({
  text,
  enabled,
  horizontalPadding,
  verticalPadding,
  onSendEvent,
  url,
  isOpenInNewTab,
  isMobile,
  ...props
}) => {
  if (!enabled) {
    return null;
  }

  const style = createStyleObject(isMobile, props);

  const onClick = (e) => {
    e.stopPropagation();
    onSendEvent(EVENTS.buttonClicked);

    if (!url) {
      return;
    }

    // Mobile Safari prevents pop-ups by default
    if (!isOpenInNewTab || isMobileSafari()) {
      window.top.location.href = url;
      return;
    }

    window.open(url, "_blank");
  };

  if (horizontalPadding) {
    const padding = `${horizontalPadding}px`;
    style.paddingLeft = padding;
    style.paddingRight = padding;
  }

  if (verticalPadding) {
    const padding = `${verticalPadding}px`;
    style.paddingTop = padding;
    style.paddingBottom = padding;
  }

  return (
    <button onClick={onClick} style={style} className="hero-button">
      {text}
    </button>
  );
};

export default OverlayButton;
