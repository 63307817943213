import React from 'react';
import FeedProductOptionSelect from 'shared/react/components/complex/feed/feed_products/FeedProductOptionSelect';
import ProductSizeVariant from 'shared/react/components/complex/feed/feed_products/feed_product_variants/product_variants/ProductSizeVariant';
import useFeedFeatures from 'shared/react/components/complex/context/hooks/useFeedFeatures';
import { FEATURE_FEED_PRODUCT_VARIANT_PICKER } from 'shared/react/constants/features.constants';
import ProductColorVariant from 'shared/react/components/complex/feed/feed_products/feed_product_variants/product_variants/ProductColorVariant';
import {
  COLOR_CATEGORY,
  MILK_COLOR_CATEGORY,
} from 'shared/react/components/complex/feed/feed_products/feed_product_variants/feedProductsVariants.constants';

const FeedProductsVariants = ({ name, color, values, ...props }) => {
  const [, { getFeatureSettingsByKey }] = useFeedFeatures();

  const variantsSettings = getFeatureSettingsByKey(FEATURE_FEED_PRODUCT_VARIANT_PICKER) || {};
  const lowerCaseName = name.toLocaleLowerCase();

  if (values.length === 1) {
    return null;
  }

  if (color && [MILK_COLOR_CATEGORY, COLOR_CATEGORY].includes(lowerCaseName)) {
    return (
      <ProductColorVariant
        values={values}
        tilesFeature={variantsSettings}
        color={color}
        {...props}
      />
    );
  }

  if (!variantsSettings?.variantTypes?.includes(lowerCaseName)) {
    return <FeedProductOptionSelect name={name} values={values} {...props} />;
  }

  return <ProductSizeVariant values={values} tilesFeature={variantsSettings} {...props} />;
};

export default FeedProductsVariants;
