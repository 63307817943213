export const DEFAULT_PLAYER_LANGUAGE = 'english';

export const PLAYER_LANGUAGE_TYPE = 'language';

// Keep this list in an alphabetic order
export const LANGUAGES = {
  // arabic: 'Arabic',
  bulgarian: 'Bulgarian',
  czech: 'Czech',
  danish: 'Danish',
  dutch: 'Dutch',
  english: 'English',
  finnish: 'Finnish',
  french: 'French',
  german: 'German',
  hebrew: 'Hebrew',
  italian: 'Italian',
  japanese: 'Japanese',
  polish: 'Polish',
  portuguese_brazil: 'Portuguese (Brazil)',
  romanian: 'Romanian',
  slovak: 'Slovak',
  spanish_latin_american: 'Spanish (Latin America)',
  lithuanian: 'Lithuanian',
  ukrainian: 'Ukrainian',
  // swedish: 'Swedish',
};

export const PLAYER_LANGUAGE_DEFAULT_SETTINGS = {
  [PLAYER_LANGUAGE_TYPE]: DEFAULT_PLAYER_LANGUAGE,
};
