import React, { useEffect, useState } from 'react';
import Button from 'shared/react/components/complex/Button';
import Sizes from 'shared/react/theme/Sizes';
import styled from 'styled-components';
import Utils from '../../utils';

const GhostAccountNotification = () => {
  const isGhostAccount = Utils.isGhostAccount();
  const [ghostAccountNotificationOpen, setGhostAccountNotificationOpen] = useState(isGhostAccount);

  useEffect(() => {
    setGhostAccountNotificationOpen(isGhostAccount);
  }, [isGhostAccount]);

  if (!ghostAccountNotificationOpen) {
    return null;
  }

  return (
    <StyledGhostAccountNotification>
      Preview account
      <Button
        padding="1px 6px"
        size={Sizes.FitContent}
        onClick={() => setGhostAccountNotificationOpen(false)}
      >
        X
      </Button>
    </StyledGhostAccountNotification>
  );
};

export default GhostAccountNotification;

const StyledGhostAccountNotification = styled.div`
  position: fixed;
  display: flex;
  gap: 4px;
  background: yellow;
  left: 50%;
  transform: translateX(-50%);
  font-size: 16px;
  font-weight: bold;
  top: 5px;
  padding: 6px 8px;
  border-radius: 8px;
  box-shadow: 0 0 0 1px #dadce0;
  z-index: 2;
`;
