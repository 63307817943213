import React, { FC } from 'react';
import styled from 'styled-components';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import PricingTooltip from 'app/src/complex_components/PricingTooltip';
import { AnalyticsData } from 'app/src/types/utils.types';

type Props = {
  shouldHide?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
  tooltipProps?: {
    shouldHide?: boolean;
    header?: string;
    analyticsData?: AnalyticsData;
  };
};

const EditorMainContainer: FC<Props> = ({
  shouldHide,
  children,
  tooltipProps = { shouldHide: true },
  ...props
}) => {
  const { shouldHide: shouldHideTooltip, header, analyticsData } = tooltipProps;

  const undefinedProps = {
    containerWidth: undefined,
    fullWidthContainer: undefined,
    marginBottom: undefined,
    marginTop: undefined,
    shouldHide: undefined,
  };

  if (shouldHide) {
    return null;
  }

  if (shouldHideTooltip) {
    return <LayoutRoot {...props}>{children}</LayoutRoot>;
  }

  return (
    <PricingTooltip
      header={header}
      text={`Hey! I'm a premium feature, click below to unlock.`}
      placement={'right'}
      requestUserUpgrade={true}
      analyticsData={analyticsData}
      {...undefinedProps}
    >
      <LayoutRoot {...props}>{children}</LayoutRoot>
    </PricingTooltip>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)`
  padding: 16px 24px;
  opacity: ${({ disabled }) => (disabled ? '0.4' : '')};
`;

export default EditorMainContainer;
