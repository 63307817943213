import React from 'react';
import VideoSpotlightWithHeader from 'src/complex_components/VideoSpotlightWithHeader';
import { OFFICE_PETS_VIDEO_URLS } from 'src/constants/videoUrls.constants';
import useAccountOnboardingTemplates from '../../../hooks/useAccountOnboardingTemplates';
import styled from 'styled-components';
import { PrimaryButton } from 'shared/react/components/basic/button-v2/BasicButton';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import { DesignSizes } from 'src/types/design-system';
import StraightArrowRightIcon from 'src/images/creation_method_icons/StraightArrowRightIcon';
import useNavigation from 'src/hooks/useNavigation';
import { TextH4 } from 'shared/react/components/basic/text/TextV2';
import { VerticalFlexCentered } from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import OnboardingTemplatesEndShopHeader from './OnboardingTemplatesEndShopHeader';

const OnboardingTemplatesEndShop = () => {
  const { getStageProps, updateTemplateProgress } = useAccountOnboardingTemplates();
  const { navigateToProducts } = useNavigation();

  const handleTagMoreProductsClick = async () => {
    updateTemplateProgress();
    navigateToProducts();
  };

  const { header, subheader } = getStageProps();
  return (
    <LayoutRoot>
      <StyledVideoSpotlightWithHeader
        header={<OnboardingTemplatesEndShopHeader />}
        videoSubheader="Menashe"
        videoSrc={OFFICE_PETS_VIDEO_URLS.menasheJumping}
      />
      <BottomContainer>
        <StyledTextH4>{subheader}</StyledTextH4>
        <PrimaryButton size={DesignSizes.NORMAL} onClick={handleTagMoreProductsClick}>
          Tag more products
          <StraightArrowRightIcon />
        </PrimaryButton>
      </BottomContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)`
  align-items: center;
  gap: 40px;
  padding: 80px 40px 0 !important;
`;

const StyledVideoSpotlightWithHeader = styled(VideoSpotlightWithHeader)`
  gap: 40px;
`;

const BottomContainer = styled(VerticalFlexCentered)`
  gap: 24px;
`;

const StyledTextH4 = styled(TextH4)`
  text-align: center;
  white-space: pre;
  color: ${({ theme }) => theme.colors.neutralDarker};
`;

export default OnboardingTemplatesEndShop;
