import React, { useState } from 'react';
import Button from 'shared/react/components/complex/Button';
import Types from 'shared/react/theme/Types';
import BorderRadiusTypes from 'shared/react/theme/BorderRadiusTypes';
import styled, { css } from 'styled-components';
import GhostButton from 'shared/react/components/basic/button/button/GhostButton';
import FormInput from 'app/src/complex_components/FormInput';
import { startWidget } from 'app/src/helpers/Widget';
import Utils from 'app/src/utils';
import { CircularProgress } from '@material-ui/core';
import DisconnectIcon from 'app/src/images/integrations/DisconnectIcon';
import ConnectedIcon from 'app/src/images/integrations/ConnectedIcon';
import useHover from 'shared/react/hooks/useHover';

const getButtonText = (comingSoon, connected, openSupport, isHovered) => {
  if (openSupport) {
    return 'Contact us';
  }

  if (comingSoon) {
    return 'Coming Soon';
  }

  if (!connected) {
    return 'Connect';
  }

  if (isHovered) {
    return 'Disconnect';
  }

  return 'Connected';
};

const ConnectButton = ({
  inputPlaceHolder,
  inputFixedText,
  comingSoon,
  openSupport,
  onDisconnect,
  onInputSave,
  url,
  connected,
  onClick,
  loading,
  connectedTooltipText,
}) => {
  const [isInputShown, setIsInputShown] = useState(false);
  const [inputValue, setInputValue] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [hoverRef, isHovered] = useHover();
  const buttonText = getButtonText(comingSoon, connected, openSupport, isHovered && onDisconnect);

  const handleApiKeyChanged = e => {
    setInputValue(e.target.value);
  };

  const handleReportApiKey = async () => {
    if (!inputValue) {
      return;
    }

    setIsSaving(true);
    await onInputSave(inputValue);
    setIsSaving(false);
  };

  const handleEnterClick = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleReportApiKey();
    }
  };

  function isApiKeyRequired() {
    return onInputSave && !connected;
  }

  const getConnectedIcon = () => {
    if (loading) {
      return <CircularProgress size={21} />;
    }

    if (isHovered && onDisconnect) {
      return <DisconnectIcon />;
    }

    return <ConnectedIcon />;
  };

  function handleConnectClick() {
    if (onClick) {
      onClick();
      return;
    }

    if (isApiKeyRequired()) {
      setIsInputShown(true);
      return;
    }

    if (connected) {
      return;
    }

    if (openSupport) {
      return startWidget();
    }

    if (!url) {
      return;
    }

    Utils.openInNewTab(url);
  }

  if (isApiKeyRequired() && isInputShown) {
    return (
      <InputContainer hasFixedText={!!inputFixedText}>
        <Input
          onKeyPress={handleEnterClick}
          placeholder={inputPlaceHolder}
          onChange={handleApiKeyChanged}
          disabled={isSaving}
        />
        {inputFixedText && <DisabledInput value={inputFixedText} />}
        <Button
          type={Types.Secondary}
          showRightIcon
          borderRadiusType={BorderRadiusTypes.RightCorneredLeftFlat}
          onClick={() => handleReportApiKey(inputValue)}
          loading={isSaving}
        />
      </InputContainer>
    );
  }

  if (connected) {
    return (
      <ConnectedButton
        disconnectOption={!!onDisconnect}
        onClick={() => onDisconnect?.()}
        ref={hoverRef}
        title={connectedTooltipText}
      >
        {getConnectedIcon()}
        {buttonText}
      </ConnectedButton>
    );
  }

  return (
    <Button
      type={connected || comingSoon ? Types.Primary : Types.Secondary}
      disableColorStateChanges={connected}
      onClick={handleConnectClick}
    >
      {buttonText}
    </Button>
  );
};

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ hasFixedText }) => (hasFixedText ? '4fr 3fr 1fr' : '1fr auto')};
  align-items: center;
  width: 100%;
  max-width: 330px;
`;

const ConnectedButton = styled(GhostButton)`
  padding: 9px 12px;
  height: fit-content;

  &:hover {
    border: 1px solid
      ${({ theme, disconnectOption }) =>
        disconnectOption ? theme.colors.ghostButtonBorderHover : theme.colors.gray4};
  }
`;

const Input = styled(FormInput)`
  outline: none;
  width: 100%;
  border-radius: 24px 0 0 24px;
  ${({ error }) =>
    error
      ? css`
          border-color: ${({ theme }) => theme.colors.error};
        `
      : ''}
  vertical-align: baseline;
  padding-left: 8px;
  padding-right: 8px;
  height: 40px;

  &:disabled {
    background: white;
  }

  font-weight: ${props => (props.draggingAbove ? '700' : '500')};
`;

const DisabledInput = styled(Input).attrs(() => ({ disabled: true }))`
  &:disabled {
    background: ${({ theme }) => theme.colors.gray5};
    color: ${({ theme }) => theme.colors.gray27};
    font-weight: 600;
    opacity: 1;
  }
  border-radius: 0;
  border: 0px;
  padding: 10px;
`;

export default ConnectButton;
