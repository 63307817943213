import React from 'react';
import styled from 'styled-components';
import { TextSmall } from 'shared/react/components/complex/Text';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

const EmptyState = ({ isRules }) => {
  const getText = () => {
    if (isRules) {
      return 'No rules to review.';
    }
    return 'No links or products to review.';
  };

  return (
    <LayoutRoot>
      <TextSmall>{getText()}</TextSmall>
    </LayoutRoot>
  );
};

export default EmptyState;

const LayoutRoot = styled(HorizontalFlex)``;
