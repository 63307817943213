import React from 'react';
import styled, { useTheme } from 'styled-components';
import XIcon from 'app/src/images/XIcon';
import MenuIcon from 'app/src/images/KebabMenuDark';
import IconButton from 'app/src/complex_components/IconButton';
import { TextH6 } from 'shared/react/components/complex/Text';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';

function Header({ onToggleMenu, onClose }) {
  const theme = useTheme();
  return (
    <LayoutRoot>
      <TextH6>Notifications</TextH6>

      <ButtonsContainer>
        <IconButton onClick={onToggleMenu} width="36px" height="36px">
          <MenuIcon color={theme.colors.black} size={36} />
        </IconButton>

        <IconButton onClick={onClose} width="36px" height="36px">
          <XIcon size={36} />
        </IconButton>
      </ButtonsContainer>
    </LayoutRoot>
  );
}

export default Header;

const LayoutRoot = styled.div`
  display: grid;
  gap: 4px;
  grid-template-columns: 1fr auto;
  padding: 8px 8px 0 16px;
  height: 56px;
  align-items: center;
`;

const ButtonsContainer = styled(Gap8HorizontalFlexWrap)``;
