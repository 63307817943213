import { useFeedStateContext } from 'shared/react/components/complex/context/FeedStateContext';

const useFeedState = () => {
  const feedState = useFeedStateContext();

  const actions = {};

  return [feedState, actions];
};

export default useFeedState;
