import React from 'react';

const PencilIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.469 2.875a1.794 1.794 0 0 1 0 2.469l-9.782 9.781c-.375.344-.812.625-1.28.75L1.624 17c-.188.031-.375 0-.5-.125a.486.486 0 0 1-.125-.5l1.125-3.781c.125-.469.406-.906.75-1.281l9.781-9.782a1.794 1.794 0 0 1 2.469 0l1.344 1.344Zm-4.813 1.094 2.375 2.375 1.719-1.688c.281-.312.281-.781 0-1.062L14.406 2.25c-.281-.281-.75-.281-1.062 0l-1.688 1.719Zm-.719.687-7.343 7.375c-.25.219-.438.531-.531.844l-.844 2.906 2.906-.844c.313-.093.625-.28.844-.53l7.343-7.345-2.374-2.406Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default PencilIcon;
