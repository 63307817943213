import React from 'react';
import styled from 'styled-components';
import EditorOnOffToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorOnOffToggleItem';
import EditorColorInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorColorInputItem';
import EditorHeader from '../shared/EditorHeader';
import EditorMainContainer from '../shared/EditorMainContainer';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

function ButtonsEditor() {
  return (
    <LayoutRoot>
      <EditorHeader title="Buttons" />
      <EditorMainContainer>
        <EditorOnOffToggleItem text="Align buttons to branding" />
        <EditorColorInputItem text="Background color" />
        <EditorColorInputItem text="Text color" />
      </EditorMainContainer>
    </LayoutRoot>
  );
}

export default ButtonsEditor;

const LayoutRoot = styled(VerticalFlex)``;
