import React from 'react';
import styled from 'styled-components';
import { Switch } from 'react-router-dom';
import Routes from '../../helpers/Routes';
import PrivateRoute from '../PrivateRoute';
import MyProfilePage from './account/MyProfilePage';
import MyWorkspacePage from './workspace/MyWorkspacePage';
import AccountNotificationsPage from './AccountNotificationsPage';
import BillingPage from './BillingPage';
import TeamMembersPage from '../team_members/TeamMembersPage';
import PlansPage from './PlansPage';
import AccountNotificationSettingsPage from 'app/src/pages/account/account/AccountNotificationSettingsPage';
import BrandingPage from './branding/BrandingPage';
import InstallationPage from './Installation/InstallationPage';
import SyncTagsPage from './sync_tags/SyncTagsPage';
import TrackingPage from './tracking/TrackingPage';

const RightPanel = ({ contentRef }) => {
  return (
    <RightPanelContainer ref={contentRef}>
      <Switch>
        <PrivateRoute
          path={[Routes.getMyProfileRoute(), Routes.getDashboardV2MyProfileBaseRoute()]}
          exact
        >
          <MyProfilePage />
        </PrivateRoute>
        <PrivateRoute
          path={[
            Routes.getTeamMemberNotificationsRoute(),
            Routes.getDashboardV2TeamMemberNotificationsRoute(),
          ]}
          exact
        >
          <AccountNotificationSettingsPage />
        </PrivateRoute>
        <PrivateRoute
          path={[Routes.getMyWorkspaceRoute(), Routes.getDashboardV2MyWorkspaceRoute()]}
          exact
        >
          <MyWorkspacePage />
        </PrivateRoute>
        <PrivateRoute path={[Routes.getDashboardInstallationRoute()]} exact>
          <InstallationPage />
        </PrivateRoute>
        <PrivateRoute path={[Routes.getDashboardTrackingRoute()]} exact>
          <TrackingPage />
        </PrivateRoute>
        <PrivateRoute path={[Routes.getTeamRoute(), Routes.getDashboardV2TeamRoute()]} exact>
          <TeamMembersPage />
        </PrivateRoute>
        <PrivateRoute
          path={[
            Routes.getAccountNotificationsRoute(),
            Routes.getDashboardV2AccountNotificationsRoute(),
          ]}
          exact
        >
          <AccountNotificationsPage />
        </PrivateRoute>
        <PrivateRoute path={[Routes.getPlansRoute(), Routes.getDashboardV2PlansRoute()]} exact>
          <PlansPage />
        </PrivateRoute>
        <PrivateRoute path={[Routes.getBillingRoute(), Routes.getDashboardV2BillingRoute()]} exact>
          <BillingPage />
        </PrivateRoute>
        <PrivateRoute path={Routes.getSyncTagsRoute()} exact>
          <SyncTagsPage />
        </PrivateRoute>
        <PrivateRoute
          path={[Routes.getBrandingRoute(), Routes.getDashboardV2BrandingRoute()]}
          exact
        >
          <BrandingPage />
        </PrivateRoute>
      </Switch>
    </RightPanelContainer>
  );
};

const RightPanelContainer = styled.div`
  display: grid;
  width: 100%;
  grid-row: 1;
  grid-column: 2;
  overflow-y: auto;
  transition: all 0.5s;
  background-color: ${({ theme }) => theme.colors.neutralLightest};
  @media (${({ theme }) => theme.breakpoints.dashboardTabletMax}) {
    grid-row: 2;
    grid-column: 1;
    width: 100%;
  }
`;

export default RightPanel;
