import { GOOGLE_DRIVE, INSTAGRAM_GRAPH, TIKTOK } from 'src/constants/intergrations.constants';
import BlackInstagramIcon from 'src/images/onboarding/templates/BlackInstagramIcon';
import BlackTikTokIcon from 'src/images/onboarding/templates/BlackTikTokIcon';
import BlackGoogleDriveIcon from 'app/src/images/onboarding/templates/BlackGoogleDriveIcon';
import { publishMethodOptions } from 'src/types/entities';
import { ONSITE_TARGET_PAGES } from 'app/src/pages/dashboard/constants/onsiteTargetPages.constants';
import Types from 'shared/react/theme/Types';

type ProjectProps = {
  publishMethod: publishMethodOptions;
  folder?: string;
  targetPage?: string;
  dynamic?: boolean;
  name?: string;
};

type StageProps = {
  header?: string;
  subheader: string;
  isAddTagProductsTooltipToFirstCard?: boolean;
  emptyStateHeader?: string;
  emptyStateSubheader?: string;
  nextButtonFunctionKey?: OnboardingTemplateEndStageFunctionKey;
  nextButtonText?: string;
  nextButtonType?: string;
  isAddTagLabelsTooltipToFirstCard?: boolean;
};

type OnboardingTemplate = {
  projectProps: ProjectProps;
  stages: OnboardingTemplateStage[];
  stageProps: Partial<Record<OnboardingTemplateStage, StageProps>>;
};

export enum OnboardingTemplateKey {
  pdpStories = 'pdpStories',
  homepageCarousel = 'homepageCarousel',
  shopAppProject = 'shopAppProject',
}

export enum OnboardingTemplateStage {
  integrations = 'integrations',
  tagVideos = 'tag-videos',
  reviewVideos = 'review-videos',
  end = 'end',
  endShop = 'end-shop',
}

export enum OnboardingTemplateEndStageFunctionKey {
  finalize = 'finalize',
  finalizeShopApp = 'finalizeShopApp',
  publishShopProjects = 'publishShopProjects',
}

export const ONBOARDING_TEMPLATES_PRE_MADE_LABELS = {
  homepage: 'Homepage',
};

export const ONBOARDING_TEMPLATES: Record<OnboardingTemplateKey, OnboardingTemplate> = {
  [OnboardingTemplateKey.pdpStories]: {
    projectProps: {
      publishMethod: publishMethodOptions.stories,
      folder: undefined,
      targetPage: ONSITE_TARGET_PAGES.ProductPages,
      dynamic: true,
      name: 'Video Stories',
    },
    stages: [
      OnboardingTemplateStage.integrations,
      OnboardingTemplateStage.tagVideos,
      OnboardingTemplateStage.reviewVideos,
      OnboardingTemplateStage.end,
    ],
    stageProps: {
      [OnboardingTemplateStage.tagVideos]: {
        header: 'Tag your products in videos',
        subheader: 'Videos automatically appear on the right product pages.',
        isAddTagProductsTooltipToFirstCard: true,
      },
      [OnboardingTemplateStage.reviewVideos]: {
        header: 'Review your tagged products',
        subheader: 'Expand a row to view the videos that will appear on that page.',
        emptyStateHeader: "We didn't find any videos related to your product pages.",
        emptyStateSubheader:
          "Please go back to the previous step to make sure you've tagged products to videos. Videos must be tagged in order to continue.",
      },
      [OnboardingTemplateStage.end]: {
        nextButtonFunctionKey: OnboardingTemplateEndStageFunctionKey.finalize,
        nextButtonText: 'Review & Publish',
        subheader: 'Congratulations, you’ve just created your first Tolstoy Stories.',
      },
    },
  },
  [OnboardingTemplateKey.homepageCarousel]: {
    projectProps: {
      publishMethod: publishMethodOptions.carousel,
      folder: undefined,
      targetPage: ONSITE_TARGET_PAGES.HomePages,
      dynamic: true,
      name: 'Video Carousel',
    },
    stages: [
      OnboardingTemplateStage.integrations,
      OnboardingTemplateStage.tagVideos,
      OnboardingTemplateStage.reviewVideos,
      OnboardingTemplateStage.end,
    ],
    stageProps: {
      [OnboardingTemplateStage.tagVideos]: {
        header: 'Add videos to playlists',
        subheader: 'Videos with Homepage playlist will be automatically placed on this template.',
        isAddTagLabelsTooltipToFirstCard: true,
      },
      [OnboardingTemplateStage.reviewVideos]: {
        header: 'Review your labeled videos',
        subheader:
          'For now, all videos labeled as Homepage are showing here. You can customize it later.',
        emptyStateHeader: "We didn't find any videos related to your playlists.",
        emptyStateSubheader:
          "Please go back to the previous step to make sure you've added videos to playlists. Videos must be labeled in order to continue.",
      },
      [OnboardingTemplateStage.end]: {
        nextButtonFunctionKey: OnboardingTemplateEndStageFunctionKey.finalize,
        nextButtonText: 'Review & Publish',
        subheader: 'Congratulations, you’ve just created your first Tolstoy Carousel.',
      },
    },
  },
  [OnboardingTemplateKey.shopAppProject]: {
    projectProps: {
      publishMethod: publishMethodOptions.shopApp,
    },
    stages: [
      OnboardingTemplateStage.integrations,
      OnboardingTemplateStage.tagVideos,
      OnboardingTemplateStage.endShop,
    ],
    stageProps: {
      [OnboardingTemplateStage.tagVideos]: {
        header: 'Tag your products in videos',
        subheader: 'Videos with products are eligible to be featured in Shop app.',
        isAddTagProductsTooltipToFirstCard: true,
        nextButtonText: 'Publish',
        nextButtonFunctionKey: OnboardingTemplateEndStageFunctionKey.publishShopProjects,
      },
      [OnboardingTemplateStage.endShop]: {
        nextButtonText: 'Done',
        nextButtonType: Types.Ghost,
        header: 'Your videos are now live on Shop app!',
        subheader: 'Every product you tag will be eligible \n' + 'to be featured in the Shop app.',
        nextButtonFunctionKey: OnboardingTemplateEndStageFunctionKey.finalizeShopApp,
      },
    },
  },
};

const DEFAULT_INTEGRATIONS = {
  integrations: [
    {
      key: INSTAGRAM_GRAPH,
      subText: 'Stories, reels & video posts',
      Icon: BlackInstagramIcon,
    },
    {
      key: TIKTOK,
      subText: 'TikTok videos',
      Icon: BlackTikTokIcon,
    },
    {
      key: GOOGLE_DRIVE,
      subText: 'Select folders',
      Icon: BlackGoogleDriveIcon,
    },
  ],
};

export const ONBOARDING_TEMPLATE_INTEGRATIONS = {
  [OnboardingTemplateKey.pdpStories]: DEFAULT_INTEGRATIONS,
  [OnboardingTemplateKey.homepageCarousel]: DEFAULT_INTEGRATIONS,
  [OnboardingTemplateKey.shopAppProject]: DEFAULT_INTEGRATIONS,
};
