import React, { useEffect, useState } from 'react';
import AnalyticsTable from 'app/src/complex_components/analytics/AnalyticsTable';
import { useAnalytics } from 'app/src/context/AnalyticsStore';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import { FEATURE_EXPORT_ANALYTICS } from 'shared/react/constants/features.constants';
import { getAudienceTableColumns } from 'src/pages/dashboard/pages/audience/getAudienceTableColumns';
import { ANALYTICS_TABLE_KEYS } from 'app/src/constants/analytics.constants';
import { track } from 'app/src/helpers/Tracker';
import Utils from 'src/utils';
import { useDrawerActions } from 'src/context/ui_store/DrawerStore';
import { GlobalDrawerType } from 'src/complex_components/global_drawer/types/globalDrawer.types';
import { GLOBAL_DRAWER_STYLES } from 'src/complex_components/global_drawer/styles/globalDrawer.styles';

const AudienceTable = () => {
  const [{ viewersMetrics, isLoadingViewersMetrics }, { fetchViewers }] = useAnalytics();
  const { getFeatureEnabled } = useFeatureActions();
  const [isLoading, setLoading] = useState(false);
  const { setCurrentDrawer } = useDrawerActions();

  const isExportEnabled = getFeatureEnabled(FEATURE_EXPORT_ANALYTICS);

  const trackClick = () => {
    track('Audience Export to CSV Clicked');
  };

  useEffect(() => {
    if (viewersMetrics) {
      return;
    }

    fetchViewers();
  }, []);

  const onRowClick = ({ row }) => {
    const { email } = row;

    setCurrentDrawer({
      currentDrawer: GlobalDrawerType.Journey,
      drawerProps: { email },
      drawerStyles: { top: GLOBAL_DRAWER_STYLES.TOP_BAR_TOP },
    });
  };

  const totalProfileCount = Utils.formatNumberToString(viewersMetrics?.length || 0, 0, 0, false);
  const columns = getAudienceTableColumns();
  return (
    <AnalyticsTable
      rows={viewersMetrics}
      columns={columns}
      isExportEnabled={isExportEnabled}
      isLoading={isLoadingViewersMetrics || isLoading}
      setLoading={setLoading}
      tableName={ANALYTICS_TABLE_KEYS.audience}
      onDownloadCsv={trackClick}
      title={`Total profile count: ${totalProfileCount}`}
      onRowClick={onRowClick}
    />
  );
};

export default AudienceTable;
