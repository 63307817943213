import React from 'react';
import styled, { useTheme } from 'styled-components';
import Utils from 'src/utils';
import { useUserActions } from 'app/src/context/userStore/UserStore';
import { WhereCondition, ComparatorEnum } from 'app/src/pages/dashboard/utils/filterEntities';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { CheckboxV2 } from 'src/complex_components/Checkbox';
import { TextSmall } from 'shared/react/components/complex/Text';

type Props = {
  filters: WhereCondition;
  setFilters: (filters: WhereCondition, forceReplace?: boolean) => void;
};

const getIsLive = (filters, comparator) => {
  const value = filters.live?.[comparator]?.args[0];

  return value ? Utils.safeParse(value) : null;
};

const ExtraFilterButtons = ({ filters = {}, setFilters }: Props) => {
  const theme = useTheme();
  const { isProjectLive } = useUserActions();

  const isActiveEnabled = getIsLive(filters, ComparatorEnum.satisfies) === true;
  const isDraftEnabled = getIsLive(filters, ComparatorEnum.notSatisfies) === false;
  const areBothEnabled = !isActiveEnabled && !isDraftEnabled;

  const resetLiveFilter = () => setFilters({ live: null });
  const setLiveFilter = () =>
    setFilters({ live: { [ComparatorEnum.satisfies]: { fn: isProjectLive, args: ['true'] } } });
  const setDraftFilter = () =>
    setFilters({ live: { [ComparatorEnum.notSatisfies]: { fn: isProjectLive, args: ['false'] } } });

  const handleActiveClick = () => {
    if (areBothEnabled) {
      return setDraftFilter();
    }

    if (isDraftEnabled) {
      return resetLiveFilter();
    }

    setLiveFilter();
  };

  const handleDraftClick = () => {
    if (areBothEnabled) {
      return setLiveFilter();
    }

    if (isActiveEnabled) {
      return resetLiveFilter();
    }

    setDraftFilter();
  };

  return (
    <>
      <ExtraFilterButton onClick={handleActiveClick}>
        <Checkbox
          checked={isActiveEnabled || areBothEnabled}
          checkBackgrounColor={theme.colors.neutralBlack}
        />
        <ButtonText>Active projects</ButtonText>
      </ExtraFilterButton>
      <ExtraFilterButton onClick={handleDraftClick}>
        <Checkbox
          checked={isDraftEnabled || areBothEnabled}
          checkBackgrounColor={theme.colors.neutralBlack}
        />
        <ButtonText>Draft projects</ButtonText>
      </ExtraFilterButton>
    </>
  );
};

const Checkbox = styled(CheckboxV2)`
  padding: 0;

  background-color: ${({ theme }) => theme.colors.neutralLightest};
`;

const ExtraFilterButton = styled(HorizontalFlexCentered)`
  gap: 8px;
  padding: 4px;
  cursor: pointer;
`;

const ButtonText = styled(TextSmall)`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutralBlack};
`;

export default ExtraFilterButtons;
