import React from 'react';
import Tooltip from '../Tooltip';
import { TextBody } from 'shared/react/components/complex/Text';
import ExpanderTopArrow from 'app/src/images/expander_top_arrow.svg';
import styled from 'styled-components';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';

const AccordionHeader = ({
  tooltipText,
  isExpanded,
  headerPadding,
  mobileHeaderPadding,
  headerText,
  hoverRef,
}) => {
  const getHeader = () => {
    return (
      <StyledAccordionSummary ref={hoverRef}>
        <HeaderContainer mobileHeaderPadding={mobileHeaderPadding} headerPadding={headerPadding}>
          <HeaderTextContainer>{headerText}</HeaderTextContainer>
          <ImageContainer>
            <Image isExpanded={isExpanded} src={ExpanderTopArrow} alt="Expander arrow" />
          </ImageContainer>
        </HeaderContainer>
      </StyledAccordionSummary>
    );
  };

  return tooltipText ? <Tooltip text={tooltipText}>{getHeader()}</Tooltip> : getHeader();
};

export default AccordionHeader;

const StyledAccordionSummary = styled(MuiAccordionSummary)`
  && {
    min-height: 0;

    .MuiAccordionSummary-content.Mui-expanded {
      margin: 0;
    }

    &.MuiAccordionSummary-root {
      padding: 0;
    }

    .MuiAccordionSummary-content {
      margin: 0;
    }

    &.MuiButtonBase-root {
      user-select: auto;
    }
  }
`;

const HeaderTextContainer = styled(TextBody)`
  width: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${({ headerPadding }) => headerPadding || 0};

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    padding: ${({ mobileHeaderPadding }) => mobileHeaderPadding || ''};
  }
`;

const ImageContainer = styled.span`
  width: 13px;
  line-height: unset;
`;

const Image = styled.img`
  transition: 0.3s;
  transform: ${({ isExpanded }) => (isExpanded ? '' : 'rotate(180deg)')};
`;
