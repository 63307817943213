import React from 'react';
import { Switch, Input, Slider } from '@tolstoy/ui-library/core';
import { ControlButtonSettings } from 'src/pages/project/pages/look_and_feel/types';
import { EditorItem, ColorInput } from 'src/pages/project/pages/look_and_feel/editor/editors/shared/ui-library';

interface SpotlightControlButtonEditorProps {
  label: string;
  settings: ControlButtonSettings;
  onUpdate: (settings: ControlButtonSettings) => void;
}

export const SpotlightControlButtonEditor = ({ label, settings = {}, onUpdate }: SpotlightControlButtonEditorProps) => {
  const isEnabled = settings.enabled || false;

  const update = (newSettings: Partial<ControlButtonSettings>) => {
    onUpdate({ ...settings, ...newSettings });
  }

  const header = (
    <EditorItem label={label}>
      <Switch
        checked={isEnabled}
        onCheckedChange={enabled => onUpdate({ ...settings, enabled })}
      />
    </EditorItem>
  );

  if (!isEnabled) {
    return header;
  }

  return (
    <>
      {header}

      <EditorItem label="Background" labelClassName="text-gray-700 font-medium">
        <Switch
          checked={settings.backgroundEnabled || false}
          onCheckedChange={backgroundEnabled => update({ backgroundEnabled })}
        />
      </EditorItem>
      <ColorInput
        label="Background color"
        color={settings.backgroundColor}
        disabled={!settings.backgroundEnabled}
        onChange={backgroundColor => update({ backgroundColor })}
        isRow
      />

      <EditorItem label="Border" labelClassName="text-gray-700 font-medium">
        <Switch
          checked={settings.borderEnabled || false}
          onCheckedChange={borderEnabled => update({ borderEnabled })}
        />
      </EditorItem>
      <ColorInput
        label="Border color"
        color={settings.borderColor}
        disabled={!settings.borderEnabled}
        onChange={borderColor => update({ borderColor })}
        isRow
      />

      <EditorItem label="Transparency" labelClassName="text-gray-700 font-medium">
        <div className="flex gap-2 w-full">
          <Slider
            className="flex-1 w-12"
            min={0}
            max={1}
            step={0.1}
            value={[settings.opacity]}
            onValueChange={([opacity]) => update({ opacity })}
          />
          <Input
            className="w-12"
            value={settings.opacity}
            onChange={e => update({ opacity: parseFloat(e.target.value) })}
          />
        </div>
      </EditorItem>
    </>
  );
};
