import React from 'react';
import useNavigation from 'app/src/hooks/useNavigation';
import { useProjectActions } from 'src/context/ProjectsStore';
import ShopAppIcon from 'src/images/dashboard_v2/ShopAppIcon';
import { SHOP_APP_TARGET } from 'src/constants/project.constants';
import { track } from 'src/helpers/Tracker';
import styled from 'styled-components';

import homePageImageSrc from 'src/images/shop-app/shop-home-feed.png';
import brandPageImageSrc from 'src/images/shop-app/shop-brand-page.png';
import pdpPageImageSrc from 'src/images/shop-app/shop-product-page.png';
import { useApps } from 'src/context/AppsStore';

const useShopItems = () => {
  const { navigateToProjectTab } = useNavigation();
  const { getShopProjects } = useProjectActions();
  const { navigateToShopHomeFeed } = useNavigation();
  const [{ selectedAppUrl, selectedApp }] = useApps();

  const shopProjects = getShopProjects()?.filter(project => project.appUrl === selectedAppUrl);
  const brandProject = shopProjects.find(
    project => project.targetPage === SHOP_APP_TARGET.homePage
  );
  const pdpProject = shopProjects.find(
    project => project.targetPage === SHOP_APP_TARGET.productPage
  );

  const onProjectClick = project => {
    track('Shop App Card Click');
    return navigateToProjectTab(project, '');
  };

  const items = [
    {
      key: 'shop-home',
      icon: <ShopAppIconStyled />,
      title: 'Popular videos',
      onClick: () => navigateToShopHomeFeed(),
      imageSrc: homePageImageSrc,
      isLive: selectedApp?.isShopAppEligible,
    },
    {
      key: 'shop-brand',
      title: 'Brand page',
      onClick: () => onProjectClick(brandProject),
      imageSrc: brandPageImageSrc,
      isLive: brandProject?.live,
    },
    {
      key: 'shop-pdp',
      title: 'Product page',
      onClick: () => onProjectClick(pdpProject),
      imageSrc: pdpPageImageSrc,
      isLive: pdpProject?.live,
    },
  ];

  return items;
};

const ShopAppIconStyled = styled(ShopAppIcon)`
  width: 14px;
  height: 14px;
`;

export default useShopItems;
