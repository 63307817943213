import React from 'react';
import styled from 'styled-components';
import BillingPackageFeature from 'app/src/complex_components/billings/BillingPackagesV2/BillingPackageFeature';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const BillingPackageFeatures = ({ value }) => {
  return (
    <StyledBillingPackageFeatures>
      {value.map(feature => (
        <BillingPackageFeature key={feature} feature={feature} />
      ))}
    </StyledBillingPackageFeatures>
  );
};

export default BillingPackageFeatures;

const StyledBillingPackageFeatures = styled(VerticalFlex)`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray36};
  gap: 12px;
`;
