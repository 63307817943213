import { createContainer, createHook, createStore } from 'react-sweet-state';
import { graphqlRequest, postRequest } from '../helpers/API';
import { graphqlOperation } from 'aws-amplify';
import { foldersByAppKey } from '../graphql/queries';
import { createFolder, updateFolder } from '../graphql/mutations';
import Utils from '../utils';
import { getUserPersonalAndTeamFilter } from './ProjectsStore';
import { getIsFeatureEnabled } from './FeaturesStore';
import { FEATURE_PRIVATE_RESOURCES } from 'app/src/constants/appFeatures.constants';
import { folder, FolderType } from 'app/src/types/entities';

type State = {
  folders: folder[] | null;
  loading: boolean | null;
  initialized: boolean;
};

const initialState: State = {
  folders: null,
  loading: null,
  initialized: false,
};

const actions = {
  fetchFolders:
    () =>
    async ({ setState, dispatch }, { appKey }) => {
      try {
        setState({ loading: true });
        const response = await graphqlRequest(
          graphqlOperation(foldersByAppKey, {
            appKey,
            ...getUserPersonalAndTeamFilter(),
          })
        );
        const folders = response.data.foldersByAppKey.items;
        setState({ folders, loading: false, initialized: true });
      } catch (err) {
        console.log(err);
        dispatch(actions.handleError(err));
      }
    },
  getDisplayedFolders:
    showPersonalLibrary =>
    ({ getState }, { features }) => {
      const { folders } = getState();
      if (!getIsFeatureEnabled(features, FEATURE_PRIVATE_RESOURCES)) {
        return folders;
      }

      return folders?.filter(folder => {
        if (showPersonalLibrary === null) {
          return false;
        }
        if (!showPersonalLibrary) {
          return !folder.private;
        }
        if (!folder.private) {
          return false;
        }
        if (showPersonalLibrary !== true) {
          return folder.owner === showPersonalLibrary;
        }

        return folder.owner === Utils.getOwner();
      });
    },
  getDisplayedFoldersV2:
    (type = FolderType.projects) =>
    ({ getState }) => {
      const { folders } = getState();

      const filteredFolders = folders?.filter(folder => {
        if (type === FolderType.projects && !folder.type) {
          return true;
        }

        return folder.type === type;
      });

      return filteredFolders;
    },
  createFolder:
    input =>
    async ({ setState, getState, dispatch }, { appKey }) => {
      try {
        input.appKey = appKey;
        input.owner = Utils.getOwner();
        const response = await graphqlRequest(graphqlOperation(createFolder, { input }));
        const folder = response.data.createFolder;
        const { folders } = getState();
        setState({ folders: [...folders, folder] });
        return folder;
      } catch (err) {
        console.log(err);
        dispatch(actions.handleError(err));
      }
    },
  deleteFolder:
    id =>
    async ({ setState, getState, dispatch }) => {
      try {
        const res = await postRequest('project-actions', '/actions/folders/delete', {
          body: {
            folder: id,
          },
        });
        const oldFolders = getState().folders;
        const folders = oldFolders.filter(folder => folder.id !== id);
        setState({ folders });
        return res;
      } catch (err) {
        console.log(err);
        dispatch(actions.handleError(err));
      }
    },
  updateFolder:
    input =>
    async ({ getState, setState, dispatch }) => {
      try {
        await graphqlRequest(graphqlOperation(updateFolder, { input }));
        let { folders } = getState();
        folders = folders.map(folder => {
          if (folder.id !== input.id) {
            return folder;
          }
          return { ...folder, ...input };
        });

        setState({ folders });
      } catch (err) {
        console.log(err);
        dispatch(actions.handleError(err));
      }
    },
  getFolderById:
    id =>
    ({ getState }) => {
      const { folders } = getState();
      return folders?.find(folder => folder.id === id);
    },
  handleError:
    error =>
    ({ setState }) => {
      Utils.logError('error on folders', error);
      setState({ loading: false });
    },
  clearFolders:
    () =>
    ({ setState }) => {
      setState({ folders: null });
    },
};

type Actions = typeof actions;

const FoldersStore = createStore<State, Actions>({ initialState, actions, name: 'Folders' });

type SelectorProps = {
  type?: FolderType | undefined;
  appUrl?: string | undefined;
};
const foldersSelector = (state: State, props: SelectorProps): State => {
  let { folders } = state;
  if (props?.type) {
    folders = folders?.filter(folder => {
      if (props.type === FolderType.projects && !folder.type) {
        return true;
      }

      return folder.type === props.type;
    });
  }

  if (props?.appUrl) {
    folders = folders?.filter(folder => {
      if (!folder.appUrl) {
        return true;
      }

      return folder.appUrl === props.appUrl;
    });
  }

  return {
    ...state,
    folders,
  };
};

export const useFolders = createHook<State, Actions, State, SelectorProps>(FoldersStore, {
  selector: foldersSelector,
});

export const FoldersContainer = createContainer(FoldersStore, {
  onInit:
    () =>
    ({ dispatch }) => {
      dispatch(actions.fetchFolders());
    },
  onCleanup:
    () =>
    ({ dispatch }) => {
      dispatch(actions.clearFolders());
    },
});
