import React from 'react';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const ArrowHorizontalIcon = ({ flip, ...props }) => (
  <LayoutRoot flip={flip}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 44.308 44.308"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.767 20.849 16.727 5.809a0.923 0.923 0 0 0 -1.305 0l-1.305 1.305a0.923 0.923 0 0 0 0 1.305L27.851 22.154l-13.735 13.734a0.923 0.923 0 0 0 0 1.305l1.306 1.305a0.923 0.923 0 0 0 1.305 0l15.04 -15.04a1.846 1.846 0 0 0 0 -2.61Z"
      />
    </svg>
  </LayoutRoot>
);

const LayoutRoot = styled(VerticalFlex)`
  align-items: center;
  transform: rotate(${({ flip }) => (flip ? '180deg' : '0')});
  transition: transform 0.2s ease-in-out;
`;

export default ArrowHorizontalIcon;
