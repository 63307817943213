import React from 'react';
import styled from 'styled-components';
import { track } from 'app/src/helpers/Tracker';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import ShareToLibraryMenuTriggerContent from 'app/src/pages/dashboard/pages/libraries/tolstoys_tab/project_card_v2/share_to_library_menu/ShareToLibraryMenuTriggerContent';

const ShareToLibraryMenuTrigger = ({
  project,
  anchorEl,
  setAnchorEl,
  isLoading,
  hasPrivatePermissions,
}) => {
  const handleOpenShareToMenu = e => {
    e.stopPropagation();
    track('Project Card Share To Library Menu Click');
    setAnchorEl(e.currentTarget);
  };

  const getMenuOnClick = () => {
    if (!hasPrivatePermissions) {
      return null;
    }

    return handleOpenShareToMenu;
  };

  return (
    <LayoutRoot
      onClick={getMenuOnClick()}
      hasPrivatePermissions={hasPrivatePermissions}
      loading={isLoading}
    >
      <ShareToLibraryMenuTriggerContent project={project} isOpen={!!anchorEl} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)`
  gap: 4px;
  align-items: center;
  opacity: ${({ loading }) => (loading ? 0.7 : 1)};
  pointer-events: ${({ loading }) => (loading ? 'none' : '')};

  & path {
    fill: ${({ theme }) => theme.colors.ghostDark};
  }
`;

export default ShareToLibraryMenuTrigger;
