import React, { useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { TextSmall, TextTiny } from 'shared/react/components/complex/Text';
import VideoModal from 'app/src/pages/modals/VideoModal';
import Utils from 'app/src/utils';
import ImageResponse from 'app/src/pages/project/pages/inbox/ConversationComponents/ImageResponse';
import ProductReply from 'app/src/pages/project/pages/inbox/ConversationComponents/ProductReply';
import { useUser } from 'app/src/context/userStore/UserStore';
import CollectInfoResponse from 'app/src/pages/project/pages/inbox/ConversationComponents/CollectInfoResponse';
import VideoContent from 'app/src/pages/project/pages/inbox/ConversationComponents/VideoContent';
import SelectedAnswer from 'app/src/pages/project/pages/inbox/ConversationComponents/SelectedAnswer';
import AudioResponse from 'app/src/pages/project/pages/inbox/ConversationComponents/AudioResponse';
import TextContent from 'app/src/pages/project/pages/inbox/ConversationComponents/TextContent';
import {
  AUDIO_RESPONSE_KEY,
  FREE_TEXT_RESPONSE_KEY,
  VIDEO_RESPONSE_KEY,
} from 'app/src/constants/tolstoy.constants';
import ConversationEmptyState from 'app/src/pages/project/pages/inbox/ConversationEmptyState';
import UrlContent from 'app/src/pages/project/pages/inbox/ConversationComponents/UrlContent';
import { PARENT_URL } from 'app/src/constants/responsesInbox.constants';
import CalendarInviteContent from 'app/src/pages/project/pages/inbox/CalendarInviteContent';
import UserBox from 'app/src/basic_components/UserBox';
import Button from 'shared/react/components/complex/Button';
import Types from 'shared/react/theme/Types';
import PdfResponse from 'app/src/pages/project/pages/inbox/ConversationComponents/PdfResponse';
import { PDF_EXTENSION } from 'shared/react/constants/fileExtensions.constants';

const getIsCalendarInvite = ({ type }) => type === 'calendarInvite';
const getIsParentUrl = ({ type }) => type === PARENT_URL;
const getIsCollectInfo = ({ type }) => type === 'collectInfo';
const getIsAudioResponse = ({ type }) => type === 'audioResponse';
const getIsVideoResponse = ({ type }) => type === 'videoResponse';
const getIsImageResponse = ({ type }) => type === 'imageResponse';
const getIsPdfResponse = ({ type, value }) =>
  getIsImageResponse({ type }) && value.endsWith(PDF_EXTENSION);
const getIsTextResponse = ({ type }) => type === 'submitInput';
const getIsTolstoyTextReply = ({ type }) => type === 'tolstoyTextReply';
const getIsTolstoyReply = ({ type }) => type === 'tolstoyReply';
const getIsTolstoyProductReply = ({ type }) => type === 'tolstoyProductsReply';
const getIsSideBarClick = ({ type }) => type === 'sidebarClick';
const getIsSessionEnd = ({ type }) => type === 'sessionEnd';
const getIsClickCta = ({ type }) => type === 'clickCta';
const getIsQuizResult = ({ type }) => type === 'quizResult';
const getIsDefaultAnswer = ({ value }) => {
  return value === 'Video Response' || value === 'Audio Response' || value === 'Text Response';
};
const getIsInteractiveResponse = response =>
  getIsVideoResponse(response) || getIsAudioResponse(response) || getIsTextResponse(response);

const getIsDisplayed = response => {
  if (getIsCollectInfo(response)) {
    return !!response.collectInfo;
  }
  return (
    !getIsSessionEnd(response) &&
    (!getIsClickCta(response) ||
      (!getIsDefaultAnswer(response) &&
        response.value !== 'free text' &&
        response.answerType !== VIDEO_RESPONSE_KEY &&
        response.answerType !== FREE_TEXT_RESPONSE_KEY &&
        response.answerType !== AUDIO_RESPONSE_KEY))
  );
};

const shouldMergeCollectInfo = (responses, index) => {
  const response = responses[index];
  const lastResponse = responses[index - 1];
  if (index === 0 || !getIsCollectInfo(response) || !getIsCollectInfo(lastResponse)) {
    return false;
  }
  return Utils.isJsonString(response.collectInfo) && Utils.isJsonString(lastResponse.collectInfo);
};
const parseCollectInfo = collectInfo => JSON.parse(JSON.parse(collectInfo));
const stringifyCollectInfo = collectInfo => JSON.stringify(JSON.stringify(collectInfo));

const addAndMerge = (responses, url) => {
  const mergedResponses = [];
  responses?.forEach((response, index) => {
    if (shouldMergeCollectInfo(responses, index)) {
      const lastResponseIndex = mergedResponses.length - 1;
      const collectInfo = mergedResponses[lastResponseIndex];
      collectInfo.collectInfo = stringifyCollectInfo({
        ...parseCollectInfo(collectInfo.collectInfo),
        ...parseCollectInfo(response.collectInfo),
      });
    } else {
      mergedResponses.push(response);
    }
  });

  if (url && mergedResponses.length > 0) {
    mergedResponses.unshift({
      type: PARENT_URL,
      value: url,
    });
  }

  return mergedResponses;
};

const getResponseAnswerNamesMap = responses => {
  const responseAnswerNamesMap = {};

  responses?.forEach((response, index) => {
    const prevResponse = responses[index - 1];

    if (
      getIsInteractiveResponse(response) &&
      prevResponse &&
      !getIsDefaultAnswer(prevResponse) &&
      !getIsTolstoyProductReply(prevResponse) &&
      !getIsInteractiveResponse(prevResponse) &&
      !getIsCollectInfo(prevResponse) &&
      prevResponse.answerType
    ) {
      responseAnswerNamesMap[response.id] = prevResponse?.value;
    }
  });

  return responseAnswerNamesMap;
};

const getIsReply = response =>
  getIsTolstoyTextReply(response) ||
  getIsTolstoyReply(response) ||
  getIsTolstoyProductReply(response);

const Conversation = ({ name, responses, project = {}, url = '' }) => {
  const [{ userLogoSettings }, { getUser }] = useUser();
  const theme = useTheme();
  const [videoModalSrc, setVideoModalSrc] = useState(null);
  const [profanityMessage, setProfanityMessage] = useState(false);
  const bottomRef = useRef();

  const { name: userName, email } = getUser();
  const userNameOrEmail = userName || email;
  let userAlreadyReplied = false;

  const filteredResponses = responses?.filter(getIsDisplayed);
  const mergedResponses = addAndMerge(filteredResponses, url);
  const responseAnswerNamesMap = getResponseAnswerNamesMap(responses);

  const getResponseStep = response => {
    return project?.steps?.items?.find(step => step?.name === response.stepName);
  };

  const getPartText = step => {
    return `Part ${getStepNumber(step)}`;
  };

  const getStepNumber = step => {
    let stepNumber = 0;
    project?.stepsOrder?.forEach((stepName, index) => {
      if (step?.name === stepName) {
        stepNumber = index + 1;
      }
    });
    return stepNumber;
  };

  const getTitle = response => {
    let title = '';
    if (getIsReply(response) || getIsSideBarClick(response)) {
      return null;
    }

    if (getIsCollectInfo(response)) {
      title = 'User submitted';
    } else if (getIsQuizResult(response)) {
      title = 'Quiz result';
    } else if (getIsParentUrl(response)) {
      title = 'Welcome Visitors Played';
    } else if (userAlreadyReplied) {
      title = '';
    } else {
      const step = getResponseStep(response);
      const partText = getPartText(step);
      title = [partText, step?.description, step?.overlayText].filter(v => v).join(' - ');
    }
    if (title) {
      return <TextSmall textColor={theme.colors.ghostDark}>{title}</TextSmall>;
    } else {
      return null;
    }
  };

  const getContent = response => {
    if (getIsSideBarClick(response)) {
      return;
    }
    if (getIsCollectInfo(response)) {
      return <CollectInfoResponse response={response} />;
    }
    if (getIsAudioResponse(response)) {
      return (
        <AudioResponse response={response}>
          <SelectedAnswer
            response={{ value: responseAnswerNamesMap[response.id] }}
            userAlreadyReplied={userAlreadyReplied}
          />
        </AudioResponse>
      );
    }
    if (getIsVideoResponse(response) || getIsTolstoyReply(response)) {
      return (
        <VideoContent response={response} setVideoModalSrc={setVideoModalSrc}>
          <SelectedAnswer
            response={{ value: responseAnswerNamesMap[response.id] }}
            userAlreadyReplied={userAlreadyReplied}
          />
        </VideoContent>
      );
    }
    if (getIsPdfResponse(response)) {
      return (
        <PdfResponse response={response}>
          <SelectedAnswer
            response={{ value: responseAnswerNamesMap[response.id] }}
            userAlreadyReplied={userAlreadyReplied}
          />
        </PdfResponse>
      );
    }
    if (getIsImageResponse(response)) {
      return (
        <ImageResponse response={response}>
          <SelectedAnswer
            response={{ value: responseAnswerNamesMap[response.id] }}
            userAlreadyReplied={userAlreadyReplied}
          />
        </ImageResponse>
      );
    }
    if (getIsTolstoyProductReply(response)) {
      return <ProductReply response={response} />;
    }
    if (getIsTextResponse(response) || getIsTolstoyTextReply(response)) {
      if (!response.value) {
        return null;
      }
      return (
        <TextContent response={response}>
          <SelectedAnswer
            response={{ value: responseAnswerNamesMap[response.id] }}
            userAlreadyReplied={userAlreadyReplied}
          />
        </TextContent>
      );
    }
    if (getIsParentUrl(response)) {
      return <UrlContent response={response} />;
    }
    if (getIsCalendarInvite(response)) {
      return <CalendarInviteContent />;
    }
    return <SelectedAnswer response={response} />;
  };

  const getIsNextResponseType = index => {
    return (
      (getIsReply(mergedResponses[index + 1]) && !getIsReply(mergedResponses[index])) ||
      (getIsReply(mergedResponses[index]) && !getIsReply(mergedResponses[index + 1]))
    );
  };

  const getDate = (response, index) => {
    if (!mergedResponses[index + 1] || getIsNextResponseType(index)) {
      const createdAt = new Date(response.createdAt);
      const date = `${
        createdAt.getMonth() + 1
      }/${createdAt.getDate()}/${createdAt.getFullYear()} | ${createdAt.getHours()}:${Utils.formatDateMinutes(
        createdAt
      )}`;
      return <ResponseDate type={response.type}>{date}</ResponseDate>;
    }
  };

  const emptyStateActionButton = () => {
    return (
      <Button onClick={() => setProfanityMessage(false)} type={Types.Warning}>
        Continue
      </Button>
    );
  };

  useEffect(() => {
    if (!responses) {
      return setProfanityMessage(false);
    }

    const isProfanity = responses.some(response => response.isProfanity);
    setProfanityMessage(isProfanity);
    bottomRef?.current?.scrollIntoView({ block: 'end' });
  }, [responses]);

  const showEmptyState =
    (responses?.length > 0 && mergedResponses?.length === 0) || profanityMessage;

  return (
    <StyledConversation>
      {!profanityMessage &&
        mergedResponses?.map((response, index) => {
          const userReply = getIsReply(response);
          userAlreadyReplied = userAlreadyReplied || userReply;
          const dataWriter = userReply ? userNameOrEmail : name?.[0]?.toUpperCase();
          const loggedInImage = userReply ? Utils.getLogoUrl(userLogoSettings?.logo) : null;
          const loggedInColor = userReply ? userLogoSettings?.color : null;
          const title = getTitle(response);
          const content = getContent(response);
          if (!title && !content) {
            return null;
          }
          return (
            <React.Fragment key={`${response.id}-${index}`}>
              <ResponseContainer
                type={response.type}
                data-user={!userReply || undefined}
                data-owner={userReply || undefined}
              >
                <UserBox
                  backgroundColor={loggedInColor}
                  userImageSrc={loggedInImage}
                  color={theme.colors.white}
                  name={dataWriter}
                  data-writer
                />
                <ResponseContent type={response.type}>
                  {title}
                  {content}
                </ResponseContent>
                <UserBox visibility="hidden" />
              </ResponseContainer>
              {getDate(response, index)}
            </React.Fragment>
          );
        })}
      {showEmptyState && (
        <ConversationEmptyState
          hasProfanity={profanityMessage}
          text="Viewer left tolstoy before submitting any answer"
          emptyStateActionButton={profanityMessage && emptyStateActionButton()}
        />
      )}
      <span ref={bottomRef} />
      <VideoModal
        open={videoModalSrc !== null}
        src={videoModalSrc}
        onClose={() => setVideoModalSrc(null)}
      />
    </StyledConversation>
  );
};

export default Conversation;

const StyledConversation = styled.div`
  position: relative;
  grid-area: conversation;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
  overflow: auto;
  padding-right: 5px;
  margin-bottom: 20px;
  > :is([data-user] + [data-user], [data-owner] + [data-owner]) > [data-writer] {
    visibility: hidden;
  }
`;

const ResponseContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  align-self: ${({ type }) => (getIsReply({ type }) ? 'flex-end' : 'initial')};
  flex-direction: ${({ type }) => (getIsReply({ type }) ? 'row-reverse' : 'initial')};
`;

const ResponseContent = styled.div`
  background-color: ${({ type, theme }) =>
    getIsReply({ type }) ? theme.colors.primaryLight : theme.colors.white};
  padding: 12px;
  border-radius: 10px;
  min-width: 10px;
  width: fit-content;
  height: fit-content;
  > * + * {
    margin-top: 8px;
  }
`;

const ResponseDate = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.ghostDark};
  align-self: ${({ type }) => (getIsReply({ type }) ? 'flex-end' : 'initial')};
  padding-left: 48px;
  padding-right: 48px;
`;
