import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import ModalWrapper from 'shared/react/components/basic/ModalWrapper';
import useFeedModal from 'shared/react/components/complex/context/hooks/useFeedModal';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import useCustomerLogin from 'shared/react/components/complex/context/hooks/useCustomerLogin';
import CustomerLoginHeader from './CustomerLoginHeader';
import CustomerLoginBody from './CustomerLoginBody';
import CustomerLoginFooter from './CustomerLoginFooter';
import { CUSTOMER_LOGIN_STATE } from 'shared/react/constants/feature-constants/customerLogin.constants';

const CustomerLoginModal = () => {
  const [{ currentModal }, { popModal }] = useFeedModal();
  const { onClose } = currentModal.props;
  const [isOpen, setIsOpen] = useState(false);
  const [{ customerLoginState, customerLoginStatusCode }, { setCustomerLoginState }] =
    useCustomerLogin();

  useEffect(() => {
    if (customerLoginStatusCode === 200) {
      popModal();
      setCustomerLoginState(CUSTOMER_LOGIN_STATE.LOGGED_IN);
    }
  }, [customerLoginStatusCode]);

  const handleCloseModal = () => {
    onClose?.();
    // TODO: why is there no close animation?
    popModal();
  };

  return (
    <StyledModalWrapper
      onClose={handleCloseModal}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      hideBackgroundOverlay={false}
    >
      <StyledModalContent>
        <CustomerLoginHeader handleCloseModal={handleCloseModal} />
        <CustomerLoginBody customerLoginState={customerLoginState} />
        <CustomerLoginFooter />
      </StyledModalContent>
    </StyledModalWrapper>
  );
};

const StyledModalWrapper = styled(ModalWrapper)`
  position: absolute;
  padding-top: 50px;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 13;
  background-color: ${({ theme }) => theme.colors.white};
  max-width: 25vw;
  transition: 0.3s;
  overflow-y: auto;
  display: flex;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    max-width: unset;
  }
`;

const StyledModalContent = styled(VerticalFlex)`
  gap: 16px;
  flex-grow: 1;
  padding: 8px 24px 24px 24px;
  overflow: auto;
`;

export default CustomerLoginModal;
