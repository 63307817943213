import React, { useEffect, useState } from 'react';
import Utils from 'app/src/utils';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import EditorMainContainer from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorMainContainer';
import EditorTextInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorTextInputItem';
import EditorSelect from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorSelect';
import {
  EMAIL_FALLBACK_LABEL_ID,
  EMAIL_PAGE_URL,
  EMAIL_UTM_PARAM_KEY,
  TARGET_PAGE_PROPERTY_KEY,
  TILE_ACTION_PROPERTY_KEY,
} from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import { GIF_ACTION_OPTIONS } from 'app/src/constants/gifActionOptions.constants';
import { ONSITE_TARGET_PAGES } from 'app/src/pages/dashboard/constants/onsiteTargetPages.constants';
import useCustomPlaylists from 'app/src/pages/dashboard/hooks/useCustomPlaylists';
import { cases } from 'app/src/pages/dashboard/components/cases/cases.constants';
import { CAMPAIGN_TILE_ACTIONS } from 'app/src/constants/campaignTileActions.constants';

const GifEditor = () => {
  const {
    project,
    setCustomizationSettingsProperty,
    setEmailSettingsProperty,
    customizationSettings,
  } = useLookAndFeelContext();
  const [gifAction, setGifAction] = useState();
  const [utmParam, setUtmParam] = useState(
    customizationSettings.emailSettings[EMAIL_UTM_PARAM_KEY]
  );
  const [pageUrlParam, setPageUrlParam] = useState(
    customizationSettings.emailSettings[EMAIL_PAGE_URL]
  );
  const [gifActionOptions, setGifActionOptions] = useState([]);
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);
  const [showSpecificPageUrl, setShowSpecificPageUrl] = useState(false);

  const showFallbackPlaylist = project.useCaseName === cases.EMAIL_ABANDONMENT_REVIVAL.name;
  const customPlaylists = useCustomPlaylists().map(playlist => ({
    ...playlist,
    disabled: playlist.count < 3,
    tooltip: playlist.count < 3 ? 'Fallback playlist must include 3+ videos' : undefined,
  }));

  const handleGifActionChange = gifAction => {
    if (gifAction.id !== ONSITE_TARGET_PAGES.TvPages) {
      setCustomizationSettingsProperty(TARGET_PAGE_PROPERTY_KEY, gifAction.id);
    }

    setShowSpecificPageUrl(gifAction.tileAction === CAMPAIGN_TILE_ACTIONS.openSpecificPage);
    setGifAction(gifAction);
  };

  const handleUtmParamChange = utmParam => {
    setEmailSettingsProperty(EMAIL_UTM_PARAM_KEY, utmParam);
    setUtmParam(utmParam);
  };

  const handlePageUrlParamChange = pageUrlParam => {
    setEmailSettingsProperty(EMAIL_PAGE_URL, pageUrlParam);
    setPageUrlParam(pageUrlParam);
  };

  useEffect(() => {
    if (
      !gifAction?.tileAction ||
      customizationSettings.emailSettings[TILE_ACTION_PROPERTY_KEY] === gifAction?.tileAction
    ) {
      return;
    }

    setEmailSettingsProperty(TILE_ACTION_PROPERTY_KEY, gifAction?.tileAction);
  }, [gifAction]);

  const onPlaylistClick = playlist => {
    setSelectedPlaylist(playlist);
    setEmailSettingsProperty(EMAIL_FALLBACK_LABEL_ID, playlist.id);
  };

  useEffect(() => {
    const selectedPlayList = customPlaylists.find(
      playlist => playlist.id === customizationSettings.emailSettings[EMAIL_FALLBACK_LABEL_ID]
    );
    if (selectedPlayList) {
      setSelectedPlaylist(selectedPlayList);
    }
  }, [customPlaylists]);

  useEffect(() => {
    setGifActionOptions(
      GIF_ACTION_OPTIONS.filter(option => option.useCaseNames.includes(project.useCaseName))
    );
    const emailTileAction = Utils.safeParse(project?.emailSettings)?.tileAction;

    const gifAction =
      GIF_ACTION_OPTIONS.find(gifAction => gifAction?.tileAction === emailTileAction) ||
      gifActionOptions[0];
    setGifAction(gifAction);
    setShowSpecificPageUrl(gifAction?.tileAction === CAMPAIGN_TILE_ACTIONS.openSpecificPage);
  }, [project]);

  return (
    <EditorMainContainer>
      <EditorSelect
        onChange={handleGifActionChange}
        text="Link to"
        value={gifAction}
        items={gifActionOptions}
        customHeight={40}
        customSelectWidth={190}
        tooltipText=""
      />
      {showSpecificPageUrl && (
        <EditorTextInputItem
          maxLength={500}
          text="Page URL"
          value={pageUrlParam}
          maxWidth="190px"
          placeholder={'https://www.your-shop.com/your-page'}
          onChange={handlePageUrlParamChange}
        />
      )}
      <EditorTextInputItem
        text="UTM param"
        value={utmParam}
        maxWidth="190px"
        placeholder={'source=tolstoy&utm_source=email'}
        onChange={handleUtmParamChange}
      ></EditorTextInputItem>
      {showFallbackPlaylist && (
        <EditorSelect
          onChange={onPlaylistClick}
          text="Fallback playlist"
          items={customPlaylists}
          value={selectedPlaylist}
          customHeight={40}
          customSelectWidth={190}
          showSearch={true}
          tooltipText="Select a playlist to populate the GIF in case there are less than 3 videos for the featured product."
        />
      )}
    </EditorMainContainer>
  );
};

export default GifEditor;
