import React from 'react';

const SoundPlayIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
    <path
      d="M3.21094 1.91406L9.96094 6.03906C10.2891 6.25 10.5 6.625 10.5 7C10.5 7.39844 10.2891 7.77344 9.96094 7.96094L3.21094 12.0859C2.85938 12.2969 2.41406 12.3203 2.0625 12.1094C1.71094 11.9219 1.5 11.5469 1.5 11.125V2.875C1.5 2.47656 1.71094 2.10156 2.0625 1.91406C2.41406 1.70312 2.85938 1.70312 3.21094 1.91406Z"
      fill="white"
    />
  </svg>
);

export default SoundPlayIcon;
