import { PLAYER_EVENT_NAMES } from 'player-events';

export const FREE_TEXT_RESPONSE_KEY = 'Input';
export const LEAD_FORM_RESPONSE_KEY = 'MultiInput';
export const VIDEO_RESPONSE_KEY = 'Video';
export const AUDIO_RESPONSE_KEY = 'Audio';
export const IMAGE_RESPONSE_KEY = 'Image';
export const CALENDLY_RESPONSE_KEY = 'Calendly';
export const PROMO_CODE_RESPONSE_KEY = 'PromoCode';
export const CHAT_RESPONSE_KEY = 'Chat';
export const LINK_RESPONSE_KEY = 'Link';
export const OPEN_GORGIAS_RESPONSE_KEY = 'OpenGorgias';
export const OPEN_INTERCOM_RESPONSE_KEY = 'OpenIntercom';
export const OPEN_TAWK_TO_RESPONSE_KEY = 'TawkTo';
export const OPEN_LIVE_CHAT_RESPONSE_KEY = 'LiveChat';
export const OPEN_HUBSPOT_RESPONSE_KEY = 'HubSpot';
export const OPEN_HELP_SCOUT_RESPONSE_KEY = 'HelpScout';
export const OPEN_DRIFT_RESPONSE_KEY = 'Drift';
export const OPEN_ZENDESK_RESPONSE_KEY = 'Zendesk';
export const OPEN_FRESHDESK_RESPONSE_KEY = 'Freshdesk';
export const OPEN_FRESH_CHAT = 'tolstoyOpenFreshChat';
export const OPEN_REAMAZE_RESPONSE_KEY = 'Re:amaze';
export const OPEN_REAMAZE = 'tolstoyOpenReamaze';
export const OPEN_ADA_RESPONSE_KEY = 'Ada';
export const OPEN_ADA = 'tolstoyOpenAda';
export const OPEN_TIDIO_RESPONSE_KEY = 'Tidio';
export const OPEN_TIDIO = 'tolstoyOpenTidio';
export const OPEN_CERTAINLY_RESPONSE_KEY = 'Certainly';
export const OPEN_CERTAINLY = 'tolstoyOpenCertainly';
export const APP_CONTAINER_CLASS_NAME = 'app-container';
export const PREVIEW_MOBILE_KEY = 'mobilePreview';
export const PREVIEW_DESKTOP_KEY = 'desktopPreview';
export const REPLY = 'reply';
export const BUBBLE = 'bubble';
export const LANDING_PAGE = 'landing-page';
export const SIDE_BAR = 'side_bar';
export const TYPE_RECORDER = 'tolstoy_recorder';
export const TYPE_STYLIST = 'readyMade_stylist_response';
export const REPLY_BAR_KEY = 'replyBar';
export const INTERCOM_KEY = 'Intercom';
export const GORGIAS_KEY = 'Gorgias';
export const GCLID_KEY = 'gclid';
export const DELAY_PAGE_VIEW = 'delay';
export const VIDEO_LOADED_TRACK_EVENT = 'videoLoaded';
export const LOW_VIDEO_RES = 640;

export const CHAT_PLATFORMS = {
  [OPEN_GORGIAS_RESPONSE_KEY]: PLAYER_EVENT_NAMES.openGorgias,
  [OPEN_INTERCOM_RESPONSE_KEY]: PLAYER_EVENT_NAMES.openIntercom,
  [OPEN_TAWK_TO_RESPONSE_KEY]: PLAYER_EVENT_NAMES.openTawkTo,
  [OPEN_HUBSPOT_RESPONSE_KEY]: PLAYER_EVENT_NAMES.openHubSpot,
  [OPEN_HELP_SCOUT_RESPONSE_KEY]: PLAYER_EVENT_NAMES.openHelpScout,
  [OPEN_DRIFT_RESPONSE_KEY]: PLAYER_EVENT_NAMES.openDrift,
  [OPEN_ZENDESK_RESPONSE_KEY]: PLAYER_EVENT_NAMES.openZendesk,
  [OPEN_LIVE_CHAT_RESPONSE_KEY]: PLAYER_EVENT_NAMES.openLiveChat,
  [INTERCOM_KEY]: PLAYER_EVENT_NAMES.openIntercom,
  [GORGIAS_KEY]: PLAYER_EVENT_NAMES.openGorgias,
  [OPEN_FRESHDESK_RESPONSE_KEY]: OPEN_FRESH_CHAT,
  [OPEN_REAMAZE_RESPONSE_KEY]: OPEN_REAMAZE,
  [OPEN_ADA_RESPONSE_KEY]: OPEN_ADA,
  [OPEN_TIDIO_RESPONSE_KEY]: OPEN_TIDIO,
  [OPEN_CERTAINLY_RESPONSE_KEY]: OPEN_CERTAINLY,
};
