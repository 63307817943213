import React from 'react';
import VerticalMenuIcon from 'app/src/images/dashboard_v2/VerticalMenuIcon';
import styled from 'styled-components';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import { TextSmall, TextTiny } from 'shared/react/components/complex/Text';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';

const FolderItem = ({
  name,
  number,
  selected,
  onClick,
  showMenu,
  onOpenMenu,
  folder,
  dataTestId,
}) => {
  return (
    <FolderContainer selected={selected}>
      <FolderDetails onClick={onClick}>
        <CustomEllipsis>
          <FolderTitle>{name}</FolderTitle>
        </CustomEllipsis>
        <FolderNumber>{number} Tolstoys</FolderNumber>
      </FolderDetails>
      <VerticalMenuContainer
        data-test-id={dataTestId}
        showMenu={showMenu}
        onClick={e => onOpenMenu(e, folder)}
      >
        <VerticalMenuIcon />
      </VerticalMenuContainer>
    </FolderContainer>
  );
};

const FolderContainer = styled(HorizontalFlexWrap)`
  flex-wrap: nowrap;
  gap: 12px;
  align-items: center;
  position: relative;
  justify-content: space-between;
  max-width: 270px;
  cursor: pointer;
  transition: 0.3s;
  & svg {
    min-width: 14px;
  }
  background: ${({ theme, selected }) =>
    selected ? theme.colors.primaryLight : theme.colors.white};

  &:hover {
    background: ${({ theme }) => theme.colors.primaryLightHover};
  }

  &:last-of-type {
    border-radius: 0 0 12px 12px;
  }
`;

const FolderDetails = styled.div`
  gap: 12px;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  width: 100%;
  padding: 11px 0px 11px 16px;
`;

const FolderTitle = styled(TextSmall)`
  width: 100%;
`;

const FolderNumber = styled(TextTiny)`
  width: 100%;
  color: ${({ theme }) => theme.colors.ghostDark};
  white-space: nowrap;
`;

const CustomEllipsis = styled(EllipsisTextOverflowWrapper)`
  width: 100%;
`;

const VerticalMenuContainer = styled(HorizontalFlexWrap)`
  visibility: ${({ showMenu }) => (showMenu ? '' : 'hidden')};
  pointer-events: ${({ showMenu }) => (showMenu ? '' : 'non')};
  padding: 11px 16px 11px 0;
`;

export default FolderItem;
