import React, { useEffect, useState } from 'react';
import Routes from 'app/src/helpers/Routes';
import { useHistory } from 'react-router-dom';
import { FEATURE_PRODUCT_RECOMMENDATIONS } from 'app/src/constants/appFeatures.constants';
import { track } from 'app/src/helpers/Tracker';
import ReplyBar from 'shared/react/components/complex/inbox/ReplyBar';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { useExtension } from 'app/src/context/ui_store/ExtensionStore';
import RichTextEditor from 'app/src/complex_components/rich-text-editor/RichTextEditor';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { CREATION_FLOW_MODAL_KEY } from 'app/src/constants/modals.constants';
import { useModal } from 'app/src/context/ui_store/ModalStore';

const REPLY_TYPES = {
  text: 'text',
  video: 'video',
  product: 'product',
  macro: 'macro',
  screenRecording: 'screen-recording',
};

const getAnalyticsLocation = history => history?.location?.pathname?.split?.('/')?.[1];

const updateHistoryWithSelectedConversation = (history, { conversation }) => {
  history.replace(history.location, {
    conversationId: conversation.id,
  });
};

const videoReply = (
  history,
  { email, conversation, project, location, screenRecording, setCurrentModal, setModalProps }
) => {
  const state = {
    screenRecording,
    reply: {
      email,
      sessionId: conversation.id,
      projectId: project.id,
      extraStateParams: {
        conversationId: conversation.id,
      },
      location,
    },
  };

  setCurrentModal(CREATION_FLOW_MODAL_KEY);
  setModalProps(state);
};

const productReply = (history, { email, conversation, project, location }) => {
  updateHistoryWithSelectedConversation(history, { conversation });
  history.push({
    pathname: Routes.getProductRecommendationReplyRoute(),
    state: {
      reply: {
        email,
        sessionId: conversation.id,
        projectId: project.id,
        publishId: project.publishId,
        name: conversation.name,
        extraStateParams: {
          conversationId: conversation.id,
        },
        location,
      },
    },
  });
};

const ConversationReply = ({ project, conversation, onTextReply }) => {
  const [, { getFeatureEnabled }] = useFeatures();
  const [, { openModalOrExtension }] = useExtension();
  const [, { setCurrentModal, setModalProps }] = useModal();
  const [isTextInputFocused, setIsTextInputFocused] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [attachments, setAttachments] = useState([]);

  const history = useHistory();
  const email = conversation?.email;

  const onReplyChange = type => {
    track('Inbox reply buttons', {
      type,
      sessionId: conversation.id,
      projectId: project.id,
      location: getAnalyticsLocation(history),
    });

    const location = history.location.pathname;

    if (type === REPLY_TYPES.video) {
      videoReply(history, {
        email,
        conversation,
        project,
        location,
        setCurrentModal,
        setModalProps,
      });
      return;
    }

    if (type === REPLY_TYPES.screenRecording) {
      openModalOrExtension(
        'App Screen Reply',
        {
          reply: {
            email,
            sessionId: conversation.id,
            projectId: project.id,
            publishId: project.publishId,
            name: conversation.name,
            extraStateParams: {
              conversationId: conversation.id,
            },
            location,
          },
        },
        () => {}
      );
    }

    if (type === REPLY_TYPES.product) {
      productReply(history, { email, conversation, project, location });
    }
  };

  const checkIfAttachmentsAreLoading = () => {
    return attachments.every(({ url }) => {
      return !!url;
    });
  };

  const submitTextReply = async textReply => {
    const content = editorState.getCurrentContent();

    const isEmptyAttachmentLoading = checkIfAttachmentsAreLoading();

    if ((!textReply && !content.hasText() && !attachments.length) || !isEmptyAttachmentLoading) {
      return;
    }

    let options = {
      defaultBlockTag: 'div',
      entityStyleFn: entity => {
        const entityType = entity.get('type').toLowerCase();
        if (entityType === 'link') {
          const data = entity.getData();
          return {
            element: 'a',
            attributes: {
              href: data.url,
              target: '_blank',
            },
          };
        }
      },
    };
    let html = stateToHTML(content, options);
    await onTextReply(textReply || html, attachments);
    setEditorState(EditorState.createEmpty());
    setAttachments([]);
    track('Inbox text reply sent');
  };

  const isChrome = window.navigator.vendor === 'Google Inc.';
  const hasText = editorState.getCurrentContent().hasText();
  const shouldShowToolbar = hasText || isTextInputFocused || !!attachments.length;

  const onScreenRecording = () => onReplyChange(REPLY_TYPES.screenRecording);
  const replyProps = {
    onTextReply: submitTextReply,
    onVideoReply: () => onReplyChange(REPLY_TYPES.video),
    onScreenRecording: isChrome && onScreenRecording,
    onProductReply: getFeatureEnabled(FEATURE_PRODUCT_RECOMMENDATIONS)
      ? () => onReplyChange(REPLY_TYPES.product)
      : undefined,
    shouldShowToolbar,
  };

  useEffect(() => {
    setEditorState(EditorState.createEmpty());
    setAttachments([]);
  }, [conversation]);

  return (
    <>
      <ReplyBar
        session={conversation}
        textReplyInput={
          <RichTextEditor
            {...replyProps}
            setEditorState={setEditorState}
            editorState={editorState}
            isTextInputFocused={isTextInputFocused}
            setIsTextInputFocused={setIsTextInputFocused}
            track={event => track(event)}
            attachments={attachments}
            setAttachments={setAttachments}
          />
        }
        {...replyProps}
      />
    </>
  );
};

export default ConversationReply;
