import React, { useState } from 'react';
import styled from 'styled-components';
import { TextH5 } from 'shared/react/components/complex/Text';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import AccountProfileIcon from 'app/src/images/AccountProfileIcon';
import AccountEmailIcon from 'app/src/images/AccountEmailIcon';
import StandardModal from '../../modals/StandardModal';
import EditProfileInformationModalContent from './EditProfileInformationModalContent';
import ItemTextValue from '../ItemTextValue';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import PencilButton from 'app/src/complex_components/PencilButton';
import { ConfigContainer } from 'app/src/basic_components/commonStyles';

function PersonalInformation({ firstName, lastName, email }) {
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  const handleEditClick = () => {
    setEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setEditModalOpen(false);
  };

  function getNameValue() {
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }

    if (firstName) {
      return firstName;
    }

    if (lastName) {
      return lastName;
    }
    return '';
  }

  return (
    <LayoutRoot>
      <TitleContainer>
        <TextH5>Personal information</TextH5>
        <PencilButton onClickFunction={handleEditClick} />
      </TitleContainer>
      <InfoItemsContainer>
        <InfoItem>
          <AccountProfileIcon />
          <ItemTextValue placeholder="Full name">{getNameValue()}</ItemTextValue>
        </InfoItem>
        <InfoItem>
          <AccountEmailIcon />
          <ItemTextValue placeholder="Email">{email}</ItemTextValue>
        </InfoItem>
      </InfoItemsContainer>
      <StandardModal onClose={handleCloseModal} open={isEditModalOpen}>
        <EditProfileInformationModalContent onClose={handleCloseModal} />
      </StandardModal>
    </LayoutRoot>
  );
}

export default PersonalInformation;

const LayoutRoot = styled(VerticalFlex)`
  width: 420px;
`;

const TitleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`;

const InfoItem = styled(HorizontalFlexWrap)`
  gap: 12px;
`;

const InfoItemsContainer = styled(ConfigContainer)`
  gap: 30px;
  padding: 24px;
`;
