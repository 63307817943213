import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';

import { project as projectType, vodAsset } from 'app/src/types/entities';
import {
  BolderTextSmall,
  LightTextTiny,
  TextBodyLeadingBold,
  TextSubtitle,
} from 'shared/react/components/complex/Text';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import InputWithIcon from 'src/basic_components/input/InputWithIcon';
import { CheckboxV2 } from 'src/complex_components/Checkbox';
import { useProjectActions } from 'src/context/ProjectsStore';
import { useApps } from 'src/context/AppsStore';
import { useUser } from 'src/context/userStore/UserStore';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import useUpdateDirtyForm from 'app/src/hooks/useUpdateDirtyForm';
import { GhostButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import { FEATURE_YOUTUBE_INTEGRATION } from 'shared/react/constants/features.constants';
import { track } from 'app/src/helpers/Tracker';
import { useModalActions } from 'app/src/context/ui_store/ModalStore';
import { LIBRARY_MODAL_KEY } from 'app/src/constants/modals.constants';
import { YOUTUBE } from 'app/src/constants/intergrations.constants';
import { STEP_TYPE } from 'app/src/pages/project/pages/hero/constants';
import HeroYoutube from 'app/src/pages/project/pages/hero/components/hero-youtube/HeroYoutube';
import HeroStep from 'app/src/pages/project/pages/hero/components/hero-step/HeroStep';

type Props = {
  project: projectType;
};

const DEFAULT_DURATION_LIMIT = 30;

const getDefaultValues = (project: projectType) => {
  const formValues = JSON.parse(project?.description || '{}');
  return {
    url: formValues?.url || '',
    isOpenInNewTab: formValues?.isOpenInNewTab || false,
  };
};

const getDurationLimit = ({ appKey }) => {
  if (appKey === '84b85d81-36c8-4635-b0ec-2f5901e60141') {
    // r.e.m beauty
    return 50;
  }

  return DEFAULT_DURATION_LIMIT;
};

const Hero = ({ project }: Props) => {
  const { updateProject, publishProject, createProjectStep } = useProjectActions();
  const [isYoutube, setIsYoutube] = useState(false);
  const { getFeatureEnabled } = useFeatureActions();
  const { setCurrentModal, clearModalState } = useModalActions();
  const [{ shopify }, { processHeroProject }] = useApps();
  const [{ account }] = useUser();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isDirty, isSubmitting },
  } = useForm({
    defaultValues: getDefaultValues(project),
  });
  const durationLimit = getDurationLimit(account);

  const onChoose = async (video: vodAsset) => {
    track('Hero Choose From Youtube');
    setIsYoutube(true);

    const newStep = {
      videoId: video.externalId,
      description: STEP_TYPE.youtube,
      projectId: project.id,
      question: video?.stockAsset?.posterUrl,
    };

    await createProjectStep(newStep, project);
    await publishProject(project.id);
    clearModalState();
  };

  const onYoutubeClick = () => {
    track('Hero Choose From Youtube Button Click');
    setCurrentModal(LIBRARY_MODAL_KEY, { source: YOUTUBE, onChoose });
  };

  const onSubmit = async data => {
    const updatedProject = await updateProject({
      ...project,
      description: JSON.stringify(data),
    });

    const promises = [publishProject(project.id)];

    if (shopify) {
      promises.push(processHeroProject(project.id));
    }

    await Promise.all(promises);

    reset(getDefaultValues(updatedProject));
  };

  const onSaveClickCallback = useCallback(() => {
    handleSubmit(onSubmit)();
  }, [isDirty]);

  useUpdateDirtyForm(isDirty, {
    isLoading: isSubmitting,
    disableHistoryBlocking: true,
    onSaveClick: onSaveClickCallback,
    onDiscardClick: () => reset(getDefaultValues(project)),
  });

  useEffect(() => {
    const isYoutube = project.steps.items.find(({ description }) => {
      return description === YOUTUBE;
    });

    setIsYoutube(!!isYoutube);
  }, [project]);

  if (!project) {
    return null;
  }

  const isYoutubeEnabled = getFeatureEnabled(FEATURE_YOUTUBE_INTEGRATION);

  if (isYoutube) {
    return <HeroYoutube setIsYoutube={setIsYoutube} project={project} />;
  }

  return (
    <LayoutRoot>
      <Title>Hero section video</Title>

      <>
        <Subtitle>{`Length should be up to ${durationLimit} seconds.`}</Subtitle>
        <Subtitle>
          Remember, if you manually install the code and then change the video file, you must
          reinstall the code. This ensures that the video proportions are correctly recognized and
          applied by the system.
        </Subtitle>
        <Subtitle></Subtitle>
      </>

      <SubHeader>Video</SubHeader>
      <HeroStep project={project} stepType={STEP_TYPE.desktop} durationLimit={durationLimit} />
      <HeroStep project={project} stepType={STEP_TYPE.mobile} durationLimit={durationLimit} />
      {isYoutubeEnabled && (
        <div>
          <GhostButton onClick={onYoutubeClick}>Choose from youtube</GhostButton>
        </div>
      )}

      <>
        <SubHeader>Link</SubHeader>
        <LightTextTiny>Video link to</LightTextTiny>
        <LinkInput {...register('url')} />
        <CheckboxWrapper as="label">
          <Controller
            name="isOpenInNewTab"
            control={control}
            render={({ field }) => <Checkbox {...field} checked={field.value} />}
          />
          Open in new tab
        </CheckboxWrapper>
      </>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)`
  width: 484px;
  margin-bottom: 24px;
`;

const Title = styled(TextBodyLeadingBold)`
  font-size: 20px;
`;
const Subtitle = styled(TextSubtitle)`
  font-weight: 500;
`;

const SubHeader = styled(BolderTextSmall)`
  margin-top: 8px;
`;

const LinkInput = styled(InputWithIcon)`
  border: 1px solid ${({ theme }) => theme.colors.neutralGray};
  border-radius: 4px;
`;

const CheckboxWrapper = styled(Gap8HorizontalFlex)``;

const Checkbox = styled(CheckboxV2)`
  padding: 0;
`;

export default Hero;
