import React from 'react';

const DiamondIcon = () => {
  return (
    <svg
      width="128"
      height="113"
      viewBox="0 0 128 113"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m102.804 15.427 18.3 28.1.3.3-55.8 68.7-55.5-68.7.5-.3 18-28.1h74.2Z"
        fill="#848BF9"
      />
      <path d="m84.803 43.526-19.2 69.001-18-69 18-28.1 19.2 28.1Z" fill="#7884EB" />
      <path d="m121.304 43.827-55.7 68.7 19.2-69 18-28.1 18.5 28.4Z" fill="#6573E7" />
      <path d="m10.604 43.526 18-28.1 19 28.1h-37Z" fill="#5060E2" />
      <path
        d="m42.705 36.426 4.9-1.5c.4-.1.4-.6 0-.7l-4.9-1.5c-.1 0-.2-.1-.3-.3l-1.4-4.8c-.1-.4-.6-.4-.7 0l-1.5 4.9c0 .1-.1.2-.3.3l-4.9 1.5c-.4.1-.4.6 0 .7l4.9 1.5c.1 0 .2.1.3.3l1.5 4.9c.1.4.6.4.7 0l1.5-4.9c0-.3.1-.4.2-.4Zm-34.601 18.6 3.4-2.8c.2-.2.1-.6-.2-.6l-4.4.4c-.1 0-.2 0-.3-.1l-2.8-3.4c-.2-.2-.6-.1-.6.2l.4 4.4c0 .1 0 .2-.1.3l-3.4 2.8c-.2.2-.1.6.2.6l4.4-.4c.1 0 .2 0 .3.1l2.8 3.4c.2.2.6.1.6-.2l-.4-4.4c-.1-.1 0-.2.1-.3Zm33.499-50.2 2.3-1.8c.2-.1.1-.4-.2-.4l-2.9.3c-.1 0-.1 0-.2-.1l-1.8-2.3c-.1-.2-.4-.1-.4.2l.3 2.9c0 .1 0 .1-.1.2l-2.3 1.8c-.2.1-.1.4.2.4l2.9-.3c.1 0 .1 0 .2.1l1.8 2.3c.1.2.4.1.4-.2l-.3-2.9c0-.1 0-.2.1-.2Zm74.702 88.8 4 2.1c.3.2.6-.2.4-.5l-2.2-4c-.1-.1-.1-.2 0-.3l2.1-4c.2-.3-.2-.6-.5-.4l-4 2.2c-.1.1-.2.1-.3 0l-4-2.1c-.3-.2-.6.2-.4.5l2.2 4c.1.1.1.2 0 .3l-2.1 4c-.2.3.2.6.5.4l4-2.2h.3Zm-83.801-5.699 3.1 1.6c.2.1.5-.1.3-.3l-1.7-3v-.2l1.6-3.1c.1-.2-.1-.5-.3-.3l-3 1.7h-.2l-3.1-1.6c-.2-.1-.5.1-.3.3l1.7 3v.2l-1.6 3.1c-.1.2.1.5.3.3l3-1.7h.2Zm-2.1 22.5 3 1.5c.2.1.5-.1.3-.3l-1.7-2.9v-.2l1.5-3c.1-.2-.1-.5-.3-.3l-2.9 1.6h-.2l-3-1.5c-.2-.1-.5.1-.3.3l1.7 2.9v.2l-1.5 3c-.1.2.1.5.3.3l2.9-1.7c.1.1.2.1.2.1Zm56.9-105.2 3 1.5c.2.1.4-.1.3-.3l-1.6-2.9v-.2l1.5-2.9c.1-.2-.1-.4-.3-.3l-2.9 1.6h-.2l-3.1-1.7c-.2-.1-.4.1-.3.3l1.6 2.9v.2l-1.5 3c-.1.2.1.4.3.3l2.9-1.5c.1-.1.2-.1.3 0Z"
        fill="#0E1658"
      />
      <path
        d="m121.305 43.827-36.5-.3 18-28.1 18.5 28.4Zm-36.502-.301h-37.2l18-28.1 19.2 28.1Z"
        fill="#5060E2"
      />
      <path
        d="m68.503 17.326 5.6-1.7c.4-.1.4-.7 0-.8l-5.6-1.7c-.1 0-.3-.1-.3-.3l-1.7-5.6c-.1-.4-.7-.4-.8 0l-1.7 5.6c0 .1-.1.3-.3.3l-5.7 1.7c-.4.1-.4.7 0 .8l5.6 1.7c.1 0 .3.1.3.3l1.7 5.6c.1.4.7.4.8 0l1.7-5.6c.1-.1.2-.2.4-.3Zm54.501 27.301 4.4-1.3c.3-.1.3-.6 0-.6l-4.4-1.3c-.1 0-.2-.1-.2-.2l-1.3-4.4c-.1-.3-.6-.3-.6 0l-1.3 4.4c0 .1-.1.2-.2.2l-4.4 1.3c-.3.1-.3.6 0 .6l4.4 1.3c.1 0 .2.1.2.2l1.3 4.4c.1.3.6.3.6 0l1.3-4.4c0-.1.1-.2.2-.2Z"
        fill="#0E1658"
      />
    </svg>
  );
};

export default DiamondIcon;
