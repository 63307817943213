import React from 'react';

function LogoutIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" fill="none" viewBox="0 0 20 19">
      <path
        fill="#50545E"
        d="M19.805 9.07L14.18 3.445a.662.662 0 00-.899 0 .662.662 0 000 .899l4.57 4.531H6.876a.642.642 0 00-.625.625c0 .352.273.625.625.625h10.977l-4.57 4.57a.662.662 0 000 .899.662.662 0 00.898 0l5.625-5.625A.647.647 0 0020 9.5a.627.627 0 00-.195-.43zM6.875 17h-3.75a1.851 1.851 0 01-1.875-1.875V3.875C1.25 2.859 2.07 2 3.125 2h3.75a.642.642 0 00.625-.625c0-.313-.313-.625-.625-.625h-3.75A3.11 3.11 0 000 3.875v11.25c0 1.758 1.367 3.125 3.125 3.125h3.75a.642.642 0 00.625-.625c0-.313-.313-.625-.625-.625z"
      />
    </svg>
  );
}

export default LogoutIcon;
