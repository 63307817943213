import React, { forwardRef } from 'react';
import { Checkbox, styled } from '@material-ui/core';

const BpIcon = styled('span')(() => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow: 'inset 0 0 0 1px #ffffff, inset 0 -1px 0 #ffffff',
  backgroundColor: 'transparent',
  borderColor: 'blue',
  'input:hover ~ &': {
    backgroundColor: 'transparent',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: 'white',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: `url("data:image/svg+xml,%3Csvg%20width%3D%2212%22%20height%3D%229%22%20viewBox%3D%220%200%2012%209%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M10.875%201.898a.934.934%200%200%200%200-1.335C10.5.21%209.915.21%209.562.563L4.22%205.905%201.875%203.563c-.375-.352-.96-.352-1.313%200a.934.934%200%200%200%200%201.335l3%203a.887.887%200%200%200%20.657.282.925.925%200%200%200%20.656-.282l6-6Z%22%20fill%3D%22%233D4FE0%22%2F%3E%3C%2Fsvg%3E")`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: '4px',
    backgroundPositionX: 'center',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#ffffffdb',
  },
});

const BpIconV2 = styled('span')(() => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  backgroundColor: 'transparent',
  boxShadow: 'unset',
  border: '1px solid #090A0B',
  transition: '0.3s',
  'input:hover ~ &': {
    boxShadow: '0px 0px 0px 3px rgba(64, 117, 255, 0.35);',
  },
}));

const BpCheckedIconV2 = styled(BpIcon)(({ backgroundColor }) => ({
  backgroundColor: backgroundColor || '#3D4FE0',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  border: 'unset',
  boxShadow: 'unset',
  transition: '0.3s',

  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.016.984a.723.723 0 0 1 0 1.055l-6 6a.723.723 0 0 1-1.055 0l-3-3a.723.723 0 0 1 0-1.055.723.723 0 0 1 1.055 0L4.5 6.445 9.96.985a.723.723 0 0 1 1.056 0Z' fill='%23fff'/%3E%3C/svg%3E")`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: '4px',
    backgroundPositionX: 'center',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: backgroundColor || '#3D4FE0',
    boxShadow: '0px 0px 0px 3px rgba(64, 117, 255, 0.35);',
  },
}));

const BpCheckbox = props => {
  return (
    <Checkbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
      }}
      disableRipple
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
};

type CheckboxV2Props = {
  checked: boolean;
};

export const CheckboxV2 = forwardRef<CheckboxV2Props, any>(
  ({ checkBackgrounColor, ...props }, ref) => {
    return (
      <Checkbox
        disableRipple
        checkedIcon={<BpCheckedIconV2 backgroundColor={checkBackgrounColor} />}
        icon={<BpIconV2 />}
        {...props}
        ref={ref}
      />
    );
  }
);
CheckboxV2.displayName = 'CheckboxV2';

export default BpCheckbox;
