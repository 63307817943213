import Colors from 'shared/react/constants/colors';
import { IMAGE_FIT_TYPE } from 'shared/react/constants/feed.constants';
import { IMAGE_BACKGROUND_TYPE } from '../feed.constants';
import {
  TOLSTOY_CUSTOM_FONT_FAMILY,
  TOLSTOY_CUSTOM_HEADING_FONT_FAMILY,
} from 'shared/react/constants/playerSettings.constants';

export const DEFAULT_DESIGN_SETTINGS = {
  branding: {
    typography: {
      font: { family: '', sources: {} },
      fontHeading: { family: '', sources: {} },
    },
  },
  widgets: {
    spotlightCarousel: {
      productHeader: {
        font: TOLSTOY_CUSTOM_HEADING_FONT_FAMILY,
        fontSize: 12,
        fontWeight: 700,
        fontColor: Colors.black,
      },
      productPrice: {
        font: TOLSTOY_CUSTOM_FONT_FAMILY,
        fontSize: 12,
        fontWeight: 700,
        fontColor: Colors.black,
      },
      salePrice: { fontColor: Colors.neutralGray },
      arrowColor: { fontColor: Colors.black },
    },
  },
  player: {
    settings: {
      language: 'english',
    },
    controls: {
      playButton: {
        backgroundOpacity: 100,
        borderColor: '',
        backgroundColor: '',
        size: 90,
      },
      isInitialMuted: false,
      isFeedMuted: false,
      checkoutShoppingBagEnabled: true,
      feedButtonsBorderRadius: 100,
      opacity: 0.2,
      backgroundColor: Colors.black,
      color: Colors.white,
    },
    feedCards: {
      text: {
        headerColorText: Colors.black1,
        bodyColorText: Colors.black1,
        discountedColorText: Colors.red7,
      },
      buttonsDesign: {
        primary: {
          backgroundColor: Colors.black,
          textColor: Colors.white,
          border: {
            enabled: false,
            color: '',
            thickness: 0.5,
            radius: 10,
          },
        },
        secondary: {
          backgroundColor: Colors.white,
          textColor: Colors.black,
          border: {
            enabled: true,
            color: Colors.black,
            thickness: 0.5,
          },
        },
      },
      productDetailsCard: {
        primaryButton: {
          settings: {
            includeProductPrice: false,
            hasQuantityPicker: false,
          },
        },
        productImages: {
          feedImageFitType: IMAGE_FIT_TYPE.COVER,
          feedImageBackgroundType: IMAGE_BACKGROUND_TYPE.SOLID,
        },
        stock: {
          hideOOSProducts: true,
        },
        advanced: {
          hiddenVariantIds: '',
          showAfterpay: false,
          moveToPDPOnTitleClick: false,
        },
        invideoProductCard: {
          opacity: 0.3,
          textColor: Colors.white,
          variantSubtitleNumOfLines: 1,
          productTitleNumOfLines: 1,
        },
      },
    },
    branching: {
      startScreenText: 'Begin an interactive conversation',
      borderRadius: 44,
      icons: {
        shouldDisplayIcon: true,
        iconColor: '',
      },
      contactForm: {
        ctaText: 'Leave us your details so we can get back to you 👋',
        color: '#FF005C',
      },
    },
  },
  feedDesign: {
    feedProductCards: {
      productHeader: {
        font: TOLSTOY_CUSTOM_HEADING_FONT_FAMILY,
        fontSize: 16,
        fontWeight: 400,
        fontColor: Colors.black,
      },
      productPrice: {
        font: TOLSTOY_CUSTOM_FONT_FAMILY,
        fontSize: 14,
        fontWeight: 400,
        fontColor: Colors.black,
      },
      salePrice: { fontColor: Colors.red7 },
      ctaButton: {
        font: TOLSTOY_CUSTOM_FONT_FAMILY,
        fontSize: 14,
        fontWeight: 700,
        fontColor: Colors.white,
        borderToggle: false,
        borderThickness: 1,
        borderColor: '',
        borderRadius: 4,
        backgroundColor: Colors.black,
      },
    },
    inFeedPDP: {
      productHeader: {
        font: TOLSTOY_CUSTOM_HEADING_FONT_FAMILY,
        fontSize: 24,
        fontWeight: 600,
        fontColor: Colors.black,
      },
      productPrice: {
        font: TOLSTOY_CUSTOM_FONT_FAMILY,
        fontSize: 16,
        fontWeight: 600,
        fontColor: '',
      },
      salePrice: { color: Colors.red7 },
      variantTitle: {
        font: TOLSTOY_CUSTOM_FONT_FAMILY,
        fontSize: 14,
        fontWeight: 400,
        fontColor: Colors.black,
      },
      variantDropdown: {
        font: TOLSTOY_CUSTOM_FONT_FAMILY,
        fontSize: 14,
        fontWeight: 400,
        fontColor: Colors.black,
      },
      secondaryCtaButton: {
        font: TOLSTOY_CUSTOM_FONT_FAMILY,
        fontSize: 14,
        fontWeight: 700,
        fontColor: Colors.black,
        borderToggle: false,
        borderThickness: 1,
        borderColor: '',
        borderRadius: 4,
        backgroundColor: Colors.white,
      },
      primaryCtaButton: {
        font: TOLSTOY_CUSTOM_FONT_FAMILY,
        fontSize: 14,
        fontWeight: 700,
        fontColor: Colors.white,
        borderToggle: false,
        borderThickness: 1,
        borderColor: '',
        borderRadius: 4,
        backgroundColor: Colors.black,
      },
      description: {
        font: TOLSTOY_CUSTOM_FONT_FAMILY,
        fontSize: 14,
        fontWeight: 400,
        fontColor: Colors.black,
      },
    },
  },
};
