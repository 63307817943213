import Utils from 'app/src/utils';
import SharedUtils from 'shared/react/utils/utils';
import { MAX_FONT_SIZE } from '../constants/account.constants';
import { CUSTOM_FONT } from '../utils/branding.utils';
import { uploadToS3 } from './S3Upload';

const fontNameAndWeights = [
  { name: 'Thin', value: 100 },
  { name: 'Hairline', value: 100 },
  { name: 'Extra Light', value: 200 },
  { name: 'Ultra light', value: 200 },
  { name: 'Light', value: 300 },
  { name: 'Regular', value: 400 },
  { name: 'Medium', value: 500 },
  { name: 'Semi Bold', value: 600 },
  { name: 'Demi Bold', value: 600 },
  { name: 'Extra Bold', value: 800 },
  { name: 'Ultra Bold', value: 800 },
  { name: 'Black', value: 900 },
  { name: 'Heavy', value: 900 },
  { name: 'Bold', value: 700 },
];

export const FONT_WEIGHTS = {
  100: 'Thin',
  200: 'Extra Light',
  300: 'Light',
  400: 'Regular',
  500: 'Medium',
  600: 'Semi Bold',
  700: 'Bold',
  800: 'Extra Bold',
  900: 'Heavy',
  normal: 'Regular',
};

const getFileType = (file, fileNameArray) => {
  if (!file.type) {
    return fileNameArray[fileNameArray.length - 1];
  }

  return file.type.split('/')[1];
};

const getFontWeight = file => {
  for (const weight of fontNameAndWeights) {
    const weightName = weight.name.toLowerCase();
    const dashedName = weightName.toLowerCase().split(' ').join('-');
    const upperCaseName = weightName.toLowerCase().split(' ').join('');
    if (
      file.name.toLowerCase().includes(weightName) ||
      file.name.toLowerCase().includes(dashedName) ||
      file.name.toLowerCase().includes(upperCaseName)
    ) {
      return weight.value;
    }
  }
};

export const verifyFontFile = file => {
  const fileNameArray = file.name.toLowerCase().split('.');
  const [fontName] = fileNameArray;
  const fontStyle = file.name.includes('italic') ? 'italic' : 'normal';
  const fontWeight = getFontWeight(file);

  const fileType = getFileType(file, fileNameArray);

  if (fileType === 'ttf' || fileType === 'otf') {
    return {
      fontFamily: CUSTOM_FONT + '-' + fontName,
      fontStyle,
      fileType,
      fontWeight,
    };
  }

  return {};
};

export const uploadFont = async (file, key, type = 'assets', keyPrefix = 'public/') => {
  try {
    await uploadToS3(type, `${keyPrefix}${key}`, file, null, true);
    return key;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const uploadFontFile = async (file, setErrorMessage, weight) => {
  if (!file) {
    return;
  }

  const { fontFamily, fontStyle, fileType, fontWeight } = verifyFontFile(file);

  if (file.size > MAX_FONT_SIZE) {
    const formattedSize = SharedUtils.formatBytes(MAX_FONT_SIZE);
    setErrorMessage(`Please upload an font smaller than ${formattedSize}.`);
    return;
  }

  if (!fontFamily) {
    setErrorMessage(`Please upload a valid font file of type ttf or otf.`);
    return;
  }

  const key = `assets/${Utils.getAppKey()}/${CUSTOM_FONT}${
    weight ? `-${weight}` : ''
  }-${fontWeight}.${fileType}`;
  const fontUrl = await uploadFont(file, key);

  if (fontUrl) {
    return {
      family: fontFamily.replace(/[\s()]/g, '-'),
      files: { [fontWeight]: `${Utils.getFontUrl(key)}?${Date.now()}` },
    };
  }

  setErrorMessage('Failed to upload the font. Please try again.');
};
