import React from 'react';

const MobileIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
      {...props}
    >
      <path
        fill="#fff"
        d="M11.5.5h-7c-1.125 0-2 .906-2 2v12c0 1.125.875 2 2 2h7c1.094 0 2-.875 2-2v-12c0-1.094-.906-2-2-2zm.5 14c0 .281-.25.5-.5.5h-7a.494.494 0 01-.5-.5V12h8v2.5zm0-4H4v-8c0-.25.219-.5.5-.5h7c.25 0 .5.25.5.5v8zm-4 3.75a.74.74 0 00.75-.75.76.76 0 00-.75-.75.74.74 0 00-.75.75c0 .438.313.75.75.75z"
      />
    </svg>
  );
};

export default MobileIcon;
