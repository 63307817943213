import { IMAGE_CONTENT_TYPES } from './images';
import { VIDEO_CONTENT_TYPES } from './videos';

export const SUPPORTED_CONTENT_TYPES = [...VIDEO_CONTENT_TYPES, ...IMAGE_CONTENT_TYPES];

export const ASSET_TYPE = {
  VIDEO: 'video',
  IMAGE: 'image',
  GALLERY: 'gallery',
};
