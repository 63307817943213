import React, { SyntheticEvent, forwardRef } from 'react';
import styled from 'styled-components';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import SearchIcon from '@material-ui/icons/Search';

type Props = {
  placeholder?: string;
  value?: string;
  onChange?: (e: SyntheticEvent) => void;
  icon?: React.ReactNode;
  className?: string;
  withoutBorder?: boolean;
};

const InputWithIcon = forwardRef<Props, any>(({ icon, className, ...props }, ref) => {
  return (
    <InputWithIconLayoutRoot className={className}>
      {icon && <IconContainer>{icon}</IconContainer>}
      <StyledInputWithIcon {...props} hasIcon={!!icon} ref={ref} />
    </InputWithIconLayoutRoot>
  );
});
InputWithIcon.displayName = 'InputWithIcon';

export const InputWithIconLayoutRoot = styled(HorizontalFlex)`
  position: relative;
  background: ${({ theme }) => theme.colors.neutralPale};
`;

const Input = styled.input<{ withoutBorder?: boolean }>`
  width: 100%;
  height: 100%;
  outline: none;
  padding: 10px 8px;
  font-size: 12px;
  border-radius: 4px;
  border: ${({ withoutBorder, theme }) =>
    withoutBorder ? 'none' : `1px solid ${theme.colors.neutralLight}`};
`;

const StyledInputWithIcon = styled(Input)<{ hasIcon: boolean }>`
  padding: 10px 10px 10px ${({ hasIcon }) => (hasIcon ? '30px' : '8px')};
`;

const IconContainer = styled(HorizontalFlex)`
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translate(0%, -50%);

  svg {
    width: 14px;
    height: 14px;
  }
`;

export const SearchInput = styled(InputWithIcon).attrs({ icon: <SearchIcon /> })``;

export default InputWithIcon;
