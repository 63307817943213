import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import RulesEditor from 'app/src/pages/project/pages/rules_and_triggers/editor/RulesEditor';
import RulesSummary from 'app/src/pages/project/pages/rules_and_triggers/summary/RulesSummary';
import { useParams } from 'react-router-dom';
import { useApps } from 'app/src/context/AppsStore';
import { useRules } from 'app/src/context/RulesStore';
import { useProjects } from 'app/src/context/ProjectsStore';
import { useUser } from 'app/src/context/userStore/UserStore';
import {
  ALL,
  DESKTOP,
  HEADLESS,
  MOBILE,
  negativeConditions,
  NONE,
  STOP_SHOWING_WIDGET_TO,
  whereBaseAttributes,
  whoUserAttributes,
  whoVisitorAttributes,
} from 'app/src/pages/project/pages/rules_and_triggers/Rule.constants';
import { RulesProvider } from 'app/src/pages/project/pages/rules_and_triggers/RulesContext';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';
import { track } from 'app/src/helpers/Tracker';
import ComponentWithLoader from 'app/src/complex_components/ComponentWithLoader';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import Utils from 'app/src/utils';
import { nanoid } from 'nanoid';
import { LESS_THAN_KEY } from 'app/src/constants/ruleGroups.constants';
import { SHOPIFY } from 'app/src/constants/intergrations.constants';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { FEATURE_TOLSTOY_V2 } from 'shared/react/constants/features.constants';
import { useProductPageSettings } from 'app/src/context/ProductPageSettingsStore';
import { useProducts } from 'app/src/context/ProductsStore';
import { PRODUCT_SOURCES } from 'app/src/constants/publish.constants';
import useUpdateDirtyForm from 'app/src/hooks/useUpdateDirtyForm';
import { publishMethodOptions } from 'app/src/types/entities';

function RulesPage() {
  const [
    { rules, customAttributes },
    { fetchCustomAttributes, fetchRules, clearRuleGroup, saveRules, getInvalidRules },
  ] = useRules();
  const [, { setSnackbar, setErrorSnackbar }] = useSnackBar();
  const { projectId } = useParams();
  const [{ project }, { publishProject, updateProject }] = useProjects({
    projectId,
  });
  const [{ apps: storeApps }, { getProjectApp, isAppFromEcomPlatform }] = useApps();
  const [{ account, user }] = useUser();
  const [{ products }, { createProductPageSettings, deleteProductPageSettings }] =
    useProductPageSettings({ publishId: project?.publishId, widgetType: project?.publishMethod });
  const [, { getProductsByProductsIds, createProducts }] = useProducts();
  const [, { getFeatureEnabled }] = useFeatures();

  const [isUnsaved, setUnsaved] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [isRulesLoaded, setRulesLoaded] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ruleGroup, setRuleGroup] = useState(null);
  const [whereAttributes, setWhereAttributes] = useState(whereBaseAttributes);
  const [whoAttributes, setWhoAttributes] = useState(whoUserAttributes);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const isTolstoyV2Enabled = getFeatureEnabled(FEATURE_TOLSTOY_V2);
  const visitorModeEnabled = Utils.isNullOrUndefined(ruleGroup?.visitorModeEnabled)
    ? true
    : ruleGroup.visitorModeEnabled;

  const exitIntent = !!ruleGroup?.exitIntent;
  const showOnDomains = ruleGroup?.showOnDomains;
  const delayTriggerEnabled = !!ruleGroup?.delayTriggerEnabled;
  const delayTriggerSeconds = ruleGroup?.delayTriggerSeconds;
  const shopifyApps = storeApps.filter(({ app }) => app === SHOPIFY);

  const setProducts = async () => {
    setIsLoadingProducts(true);
    const productIds = products
      .map(({ productId }) => {
        return productId;
      })
      .filter(Boolean);

    if (!productIds.length) {
      setIsLoadingProducts(false);
      return;
    }

    const currProducts = await getProductsByProductsIds(productIds);

    setSelectedProducts(currProducts);
    setIsLoadingProducts(false);
  };

  useEffect(() => {
    fetchRules(project?.id).then(() => {
      setRulesLoaded(true);
    });
  }, [project]);

  useEffect(() => {
    if (project?.feed) {
      setProducts();
    }
  }, [products]);

  useEffect(() => {
    setRuleGroup(rules);
  }, [rules]);

  useEffect(() => {
    fetchCustomAttributes(account?.appKey);
  }, [account]);

  useEffect(() => {
    return () => {
      clearRuleGroup();
    }
  }, []);

  useEffect(() => {
    if (!project || !account) {
      return;
    }

    setLoaded(true);
  }, [project, account, getProjectApp, ruleGroup]);

  useEffect(() => {
    if (!customAttributes) {
      return;
    }

    const attributes = whoUserAttributes.concat(
      customAttributes.map(({ key, type }) => ({
        key,
        title: key,
        type,
      }))
    );

    setWhoAttributes(attributes);
  }, [customAttributes]);

  const onDeviceShowOnChange = type => {
    const value = {
      [ALL]: { [DESKTOP]: MOBILE, [MOBILE]: DESKTOP },
      [NONE]: { [type]: type },
      [DESKTOP]: { [DESKTOP]: NONE, [MOBILE]: ALL },
      [MOBILE]: { [MOBILE]: NONE, [DESKTOP]: ALL },
    };

    const newShowOn = { ...ruleGroup, showOnDevice: value[ruleGroup?.showOnDevice || ALL][type] };

    setUnsaved(true);
    setRuleGroup(newShowOn);
  };

  const onShowOnDomainChange = storeUrl => {
    let newShowOnDomains = { ...ruleGroup };
    if (storeUrl === HEADLESS && newShowOnDomains.showOnDomains?.includes(storeUrl)) {
      newShowOnDomains.showOnDomains = [];
    } else if (storeUrl === HEADLESS) {
      newShowOnDomains.showOnDomains = [storeUrl];
    } else if (!showOnDomains) {
      const urls = shopifyApps.flatMap(({ appUrl }) => {
        if (storeUrl === appUrl) {
          return [];
        }

        return appUrl;
      });

      newShowOnDomains.showOnDomains = urls;
    } else if (showOnDomains.includes(storeUrl)) {
      newShowOnDomains.showOnDomains = showOnDomains.filter(domain => {
        return domain !== storeUrl;
      });
    } else if (newShowOnDomains.showOnDomains.length + 1 === shopifyApps.length) {
      newShowOnDomains.showOnDomains = null;
    } else {
      newShowOnDomains.showOnDomains = [...showOnDomains, storeUrl];
    }

    setUnsaved(true);
    setRuleGroup(newShowOnDomains);
  };

  function isOrConditionBelongsToGroup(groupedAttributes, orCondition) {
    return groupedAttributes.some(({ key }) => key === orCondition.type);
  }

  function getSpecificWhoAttributes() {
    if (!visitorModeEnabled) {
      return whoAttributes;
    }

    return whoAttributes.filter(whoAttribute =>
      whoVisitorAttributes.some(whoVisitorAttribute => whoVisitorAttribute.key === whoAttribute.key)
    );
  }

  function getWhoAndConditions() {
    return getGroupedAndConditions(getSpecificWhoAttributes());
  }

  function getWhereAndConditions() {
    return getGroupedAndConditions(whereAttributes);
  }

  function getAndCondition(andCondition, attributes, index) {
    return {
      orConditions: andCondition.filter(orCondition =>
        isOrConditionBelongsToGroup(attributes, orCondition)
      ),
      originalIndex: index,
    };
  }

  function getGroupedAndConditions(groupedAttributes) {
    if (!ruleGroup?.rules) {
      return null;
    }

    const andConditions = [];

    for (const [index, andCondition] of ruleGroup.rules.entries()) {
      const isBelongsToGroup = andCondition.some(orCondition =>
        isOrConditionBelongsToGroup(groupedAttributes, orCondition)
      );

      if (isBelongsToGroup) {
        andConditions.push(getAndCondition(andCondition, groupedAttributes, index));
      }
    }

    return andConditions;
  }

  async function publish(newProject) {
    await publishProject(newProject?.id || project?.id);
  }

  // Adjust the rules to the new "negative/positive condition" flow.
  function normalizeRules(ruleGroup) {
    const flatRules = ruleGroup.rules?.flat() || [];

    const rulesByGroup = {};
    flatRules.forEach(rule => {
      if (negativeConditions.includes(rule.condition)) {
        rulesByGroup[rule.key] = [rule];
        return;
      }
      rulesByGroup[rule.type] = rulesByGroup[rule.type] || [];
      rulesByGroup[rule.type].push(rule);
    });

    return [...Object.values(rulesByGroup)];
  }

  const getSelectedApp = () => {
    const step = project.steps?.items[0];
    const existingAppUrl = storeApps?.find(
      storeApp =>
        isAppFromEcomPlatform(storeApp) &&
        storeApp.appUrl === (project.appUrl || step?.productsAppUrl)
    );
    const defaultAppUrl = storeApps?.find(isAppFromEcomPlatform);
    return existingAppUrl || defaultAppUrl;
  };

  const deletePDPSSettings = pdpsSettings => {
    if (!pdpsSettings.length) {
      return null;
    }

    return Promise.all(
      pdpsSettings.map(({ id }) => {
        return deleteProductPageSettings({ id });
      })
    );
  };

  const createPDPSSettings = products => {
    if (!products.length) {
      return;
    }

    const productSource = getSelectedApp()?.app || PRODUCT_SOURCES.url;
    const createPDPSettingsPromise = products.map(({ productId, productUrl }) => {
      if (productUrl) {
        return createProductPageSettings({
          publishId: project.publishId,
          widgetType: project.publishMethod,
          productUrl,
          productSource: PRODUCT_SOURCES.url,
        });
      }

      return createProductPageSettings({
        productId,
        widgetType: project.publishMethod,
        publishId: project.publishId,
        productSource,
      });
    });

    const productIds = products.map(({ productId }) => productId).filter(Boolean);

    return Promise.all([...createPDPSettingsPromise, createProducts(productIds, project.appUrl)]);
  };

  const deleteAndCreatePDPSettings = () => {
    const productsToDeleteSettings = products.filter(({ productId }) => {
      return !selectedProducts.find(({ productId: currProductId }) => currProductId === productId);
    });

    const filteredSelectedProducts = selectedProducts.filter(({ productId }) => {
      return !products.find(product => productId === product.productId);
    });

    return Promise.all([
      deletePDPSSettings(productsToDeleteSettings),
      createPDPSSettings(filteredSelectedProducts),
    ]);
  };

  const getShowOnDomains = newRuleGroup => {
    if (shopifyApps.length <= 1) {
      return null;
    }

    if (newRuleGroup.showOnDomains) {
      return newRuleGroup.showOnDomains;
    }

    if (project.appUrl && isTolstoyV2Enabled) {
      return [project.appUrl];
    }

    return null;
  };

  async function handleSaveClick(e) {
    e?.stopPropagation();
    if (!project) {
      return;
    }
    try {
      const newRuleGroup = ruleGroup || { rules: [] };

      if (getInvalidRules(newRuleGroup)) {
        setErrorSnackbar('Please fix the invalid rules and try again.');
        return;
      }

      setSaving(true);
      setLoading(true);

      newRuleGroup.rules = normalizeRules(newRuleGroup);
      newRuleGroup.showOnDomains = getShowOnDomains(newRuleGroup);

      const showOnDeviceEnabled =
        !Utils.isNullOrUndefined(newRuleGroup.showOnDevice) && newRuleGroup.showOnDevice !== ALL;
      newRuleGroup.enabled =
        !!newRuleGroup?.rules?.length ||
        exitIntent ||
        delayTriggerEnabled ||
        showOnDeviceEnabled ||
        !!newRuleGroup.showOnDomains;

      await Promise.all([
        saveRules(newRuleGroup, project, publishMethodOptions.bubble),
        deleteAndCreatePDPSettings(),
      ]);

      const updatedProject = await updateProject({ ...project });

      await publish(updatedProject);
      setSnackbar('Changes have been saved');
    } catch (e) {
      console.error(e);
      setErrorSnackbar('Failed to save changes.');
    }

    setLoading(false);
    setSaving(false);
    setUnsaved(false);
  }

  const onCancel = async () => {
    setLoaded(false);
    setRuleGroup(rules);
    setLoaded(true);
    setUnsaved(false);
  };

  async function addRule(rule) {
    track('Rules Or Condition Clicked');

    const newRuleGroup = {
      ...ruleGroup,
    };

    newRuleGroup.rules = newRuleGroup.rules || [];
    newRuleGroup.rules[0] = newRuleGroup.rules[0] || [];
    newRuleGroup.rules[0].push(rule);

    setRuleGroup(newRuleGroup);
    setUnsaved(true);
  }

  const createRestrictionRule = () => {
    const newRuleGroup = {
      ...ruleGroup,
    };

    newRuleGroup.rules = newRuleGroup.rules || [];
    const newRule = {
      key: nanoid(5),
      type: STOP_SHOWING_WIDGET_TO.clickedX,
      condition: LESS_THAN_KEY,
      value: 2,
      limit: 24,
      behaviors: ['visibility'],
    };

    newRuleGroup.rules.push([newRule]);
    setUnsaved(true);
    setRuleGroup(newRuleGroup);
  };

  const removeRestrictionRule = () => {
    const newRuleGroup = {
      ...ruleGroup,
    };
    newRuleGroup.rules = newRuleGroup.rules.filter(group => {
      for (const [, value] of Object.entries(STOP_SHOWING_WIDGET_TO)) {
        if (value === group[0].type) {
          return false;
        }
      }
      return true;
    });
    setUnsaved(true);
    setRuleGroup(newRuleGroup);
  };

  function getProviderValue() {
    return {
      project,
      whoAttributes,
      setWhoAttributes,
      whereAttributes,
      setWhereAttributes,
      getWhereAndConditions,
      getWhoAndConditions,
      addRule,
      setUnsaved,
      ruleGroup,
      setRuleGroup,
      isLoaded,
      visitorModeEnabled,
      exitIntent,
      loading,
      delayTriggerEnabled,
      delayTriggerSeconds,
      onDeviceShowOnChange,
      showOnDevice: ruleGroup?.showOnDevice,
      createRestrictionRule,
      removeRestrictionRule,
      onShowOnDomainChange,
      showOnDomains,
      shopifyApps,
      setSelectedProducts,
      selectedProducts,
      isLoadingProducts,
    };
  }

  const onSaveClickCallback = useCallback(handleSaveClick, [
    ruleGroup,
    project,
    user,
    selectedProducts,
  ]);

  useUpdateDirtyForm(isUnsaved, {
    isLoading: isSaving,
    onSaveClick: onSaveClickCallback,
    onDiscardClick: onCancel,
  });

  return (
    <RulesProvider value={getProviderValue()}>
      <LayoutRoot isTolstoyV2Enabled={isTolstoyV2Enabled}>
        <ComponentWithLoader
          isLoading={!isLoaded || !isRulesLoaded}
          renderLoader={indicator => (
            <LoadingIndicatorContainer>{indicator}</LoadingIndicatorContainer>
          )}
        >
          <MainContainer>
            <RulesEditor shopifyApps={shopifyApps} />
            <RulesSummary project={project} />
          </MainContainer>
        </ComponentWithLoader>
      </LayoutRoot>
    </RulesProvider>
  );
}

export default RulesPage;

const LayoutRoot = styled.div`
  background: ${({ theme, isTolstoyV2Enabled }) => (isTolstoyV2Enabled ? '' : theme.colors.gray5)};
  height: 100%;
  margin-top: ${({ isTolstoyV2Enabled }) => (isTolstoyV2Enabled ? '' : '24px')};
`;

const MainContainer = styled(HorizontalFlexWrap)`
  gap: 32px;
  flex-wrap: nowrap;
  padding-bottom: 24px;
`;

const LoadingIndicatorContainer = styled.div`
  padding: 24px;
`;
