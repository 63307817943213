export const SOUND_LIBRARY = {
  Happy: [
    {
      name: 'Boots and Dust',
      link: 'https://media.gotolstoy.com/sounds-library/Happy/Boots and Dust.mp3',
    },
    {
      name: 'Boots On the Dance Floor',
      link: 'https://media.gotolstoy.com/sounds-library/Happy/Boots On the Dance Floor.mp3',
    },
    {
      name: 'Dance of the Ivory Keys',
      link: 'https://media.gotolstoy.com/sounds-library/Happy/Dance of the Ivory Keys.mp3',
    },
    {
      name: 'Dancing in the Sunshine',
      link: 'https://media.gotolstoy.com/sounds-library/Happy/Dancing in the Sunshine.mp3',
    },
    {
      name: 'Dancing Keys',
      link: 'https://media.gotolstoy.com/sounds-library/Happy/Dancing Keys.mp3',
    },
    {
      name: 'Groove All Night',
      link: 'https://media.gotolstoy.com/sounds-library/Happy/Groove All Night.mp3',
    },
    {
      name: 'Island Breeze',
      link: 'https://media.gotolstoy.com/sounds-library/Happy/Island Breeze.mp3',
    },
    {
      name: 'Neon Nights',
      link: 'https://media.gotolstoy.com/sounds-library/Happy/Neon Nights.mp3',
    },
    {
      name: 'Sunshine Boulevard',
      link: 'https://media.gotolstoy.com/sounds-library/Happy/Sunshine Boulevard.mp3',
    },
    {
      name: 'Sunshine in My Pocket',
      link: 'https://media.gotolstoy.com/sounds-library/Happy/Sunshine in My Pocket.mp3',
    },
    {
      name: 'Sunshine Island',
      link: 'https://media.gotolstoy.com/sounds-library/Happy/Sunshine Island.mp3',
    },
  ],
  Energetic: [
    {
      name: 'All Night Long',
      link: 'https://media.gotolstoy.com/sounds-library/Energetic/All Night Long.mp3',
    },
    {
      name: 'City Lights',
      link: 'https://media.gotolstoy.com/sounds-library/Energetic/City Lights.mp3',
    },
    {
      name: 'Electric Pulse',
      link: 'https://media.gotolstoy.com/sounds-library/Energetic/Electric Pulse.mp3',
    },
    {
      name: 'Electric Surge',
      link: 'https://media.gotolstoy.com/sounds-library/Energetic/Electric Surge.mp3',
    },
    {
      name: 'Electrify',
      link: 'https://media.gotolstoy.com/sounds-library/Energetic/Electrify.mp3',
    },
    {
      name: 'Energy Flow',
      link: 'https://media.gotolstoy.com/sounds-library/Energetic/Energy Flow.mp3',
    },
    {
      name: 'Pulse of the Dancefloor',
      link: 'https://media.gotolstoy.com/sounds-library/Energetic/Pulse of the Dancefloor.mp3',
    },
    {
      name: 'Pulse of the Night',
      link: 'https://media.gotolstoy.com/sounds-library/Energetic/Pulse of the Night.mp3',
    },
    {
      name: 'Sunshine and Smiles',
      link: 'https://media.gotolstoy.com/sounds-library/Energetic/Sunshine and Smiles.mp3',
    },
  ],
  Relaxing: [
    {
      name: 'Midnight Dreamers',
      link: 'https://media.gotolstoy.com/sounds-library/Relaxing/Midnight Dreamers.mp3',
    },
    {
      name: 'Midnight Mirage',
      link: 'https://media.gotolstoy.com/sounds-library/Relaxing/Midnight Mirage.mp3',
    },
    {
      name: 'Moonlit Serenade',
      link: 'https://media.gotolstoy.com/sounds-library/Relaxing/Moonlit Serenade.mp3',
    },
    {
      name: 'Serenity',
      link: 'https://media.gotolstoy.com/sounds-library/Relaxing/Serenity.mp3',
    },
    {
      name: "Serenity's Embrace",
      link: "https://media.gotolstoy.com/sounds-library/Relaxing/Serenity's Embrace.mp3",
    },
    {
      name: 'Sunshine Ska',
      link: 'https://media.gotolstoy.com/sounds-library/Relaxing/Sunshine Ska.mp3',
    },
    {
      name: 'Whispering Keys',
      link: 'https://media.gotolstoy.com/sounds-library/Relaxing/Whispering Keys.mp3',
    },
    {
      name: 'Whispers in the Breeze',
      link: 'https://media.gotolstoy.com/sounds-library/Relaxing/Whispers in the Breeze.mp3',
    },
  ],
  ElectricPop: [
    {
      name: 'Dancing in the Sunshine',
      link: 'https://media.gotolstoy.com/sounds-library/Electric Pop/Dancing in the Sunshine.mp3',
    },
    {
      name: 'Electric Sunshine',
      link: 'https://media.gotolstoy.com/sounds-library/Electric Pop/Electric Sunshine.mp3',
    },
    {
      name: 'Electric Heartbeat',
      link: 'https://media.gotolstoy.com/sounds-library/Electric Pop/Electric Heartbeat.mp3',
    },
    {
      name: 'Feel the Beat',
      link: 'https://media.gotolstoy.com/sounds-library/Electric Pop/Feel the Beat.mp3',
    },
    {
      name: 'Groove Fever',
      link: 'https://media.gotolstoy.com/sounds-library/Electric Pop/Groove Fever.mp3',
    },
    {
      name: "Let's Dance All Night",
      link: "https://media.gotolstoy.com/sounds-library/Electric Pop/Let's Dance All Night.mp3",
    },
    {
      name: 'Life in Technicolor',
      link: 'https://media.gotolstoy.com/sounds-library/Electric Pop/Life in Technicolor.mp3',
    },
    {
      name: 'Soaring High',
      link: 'https://media.gotolstoy.com/sounds-library/Electric Pop/Soaring High.mp3',
    },
    {
      name: 'Take It to the Top',
      link: 'https://media.gotolstoy.com/sounds-library/Electric Pop/Take It to the Top.mp3',
    },
    {
      name: 'Take Me Higher',
      link: 'https://media.gotolstoy.com/sounds-library/Electric Pop/Take Me Higher.mp3',
    },
    {
      name: 'Unbreakable',
      link: 'https://media.gotolstoy.com/sounds-library/Electric Pop/Unbreakable.mp3',
    },
  ],
};
