type OperatorFunction<T> = (entity: T, args?: any) => boolean;
export type OperatorValue = string | number | boolean;
export type SatisfiesWith<T = any> = {
  args?: any;
  fn: OperatorFunction<T>;
};

export enum ComparatorEnum {
  eq = 'eq',
  neq = 'neq',
  gt = 'gt',
  gte = 'gte',
  lt = 'lt',
  lte = 'lte',
  in = 'in',
  notIn = 'notIn',
  contains = 'contains',
  satisfies = 'satisfies',
  notSatisfies = 'notSatisfies',
}

export type ComparisonOperator<T = any> = {
  [ComparatorEnum.eq]?: OperatorValue;
  [ComparatorEnum.neq]?: OperatorValue;
  [ComparatorEnum.gt]?: OperatorValue;
  [ComparatorEnum.gte]?: OperatorValue;
  [ComparatorEnum.lt]?: OperatorValue;
  [ComparatorEnum.lte]?: OperatorValue;
  [ComparatorEnum.in]?: OperatorValue[];
  [ComparatorEnum.notIn]?: OperatorValue[];
  [ComparatorEnum.contains]?: string;
  [ComparatorEnum.satisfies]?: SatisfiesWith<T>;
  [ComparatorEnum.notSatisfies]?: SatisfiesWith<T>;
};

export type WhereCondition<T = any> = {
  [key: string]: ComparisonOperator<T>;
};

const isStringIncluded = (a: string, b: string): boolean => {
  return a.toLowerCase().includes(b.toLowerCase());
};

const compare = <T>(entity: T, column: string, operators: ComparisonOperator<T>): boolean => {
  const [operator, value] = Object.entries(operators)[0];
  switch (operator) {
    case ComparatorEnum.eq:
      return entity[column] === value;
    case ComparatorEnum.neq:
      return entity[column] !== value;
    case ComparatorEnum.gt:
      return entity[column] > value;
    case ComparatorEnum.gte:
      return entity[column] >= value;
    case ComparatorEnum.lt:
      return entity[column] < value;
    case ComparatorEnum.lte:
      return entity[column] <= value;
    case ComparatorEnum.contains:
      return typeof value === 'string' && isStringIncluded(entity[column], value);
    case ComparatorEnum.in:
      return Array.isArray(value) && value.includes(entity[column]);
    case ComparatorEnum.notIn:
      return Array.isArray(value) && !value.includes(entity[column]);
    case ComparatorEnum.satisfies: {
      const { fn, args } = value as SatisfiesWith;
      return fn(entity, args);
    }
    case ComparatorEnum.notSatisfies: {
      const { fn, args } = value as SatisfiesWith;
      return !fn(entity, args);
    }
    default:
      throw new Error(`Unsupported operator: ${operator}`);
  }
};

const applyConditions = <T>(entity: T, conditions: WhereCondition<T>): boolean => {
  return Object.keys(conditions).every(key => {
    return compare<T>(entity, key, conditions[key]);
  });
};

const filterEntities = <T>(entities: T[], where: WhereCondition<T>): T[] => {
  return entities.filter(entity => applyConditions<T>(entity, where));
};

export default filterEntities;
