import React from 'react';
import styled, { useTheme } from 'styled-components';

import { TextSmall, TextTiny } from 'shared/react/components/complex/Text';
import AccountArrowsIcon from 'app/src/images/sidebar-v2/AccountArrowsIcon';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import HorizontalFlex, {
  Gap8HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import UserBox from 'app/src/basic_components/UserBox';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';
import { sensitiveDataFilter } from 'shared/react/components/basic/SensitiveData';

type Props = {
  className?: string;
  header: string;
  subHeader: string;
  accountLogo: string;
  accountName: string;
  accountBackground?: string;
  onClick: (e: Event) => void;
  showIcon?: boolean;
  dataTestId?: string;
};

const AccountButton = ({
  header,
  subHeader,
  accountLogo,
  accountName,
  accountBackground,
  onClick,
  showIcon = true,
  className,
  dataTestId,
}: Props) => {
  const theme = useTheme();

  return (
    <LayoutRoot data-test-id={dataTestId} className={className} onClick={onClick}>
      <LeftContainer>
        <UserBox
          fontSize="20px"
          fontWeight="900"
          color={theme.colors.black}
          userImageSrc={accountLogo}
          backgroundColor={accountBackground || theme.colors.primaryLight}
          name={accountName}
          icon={undefined}
          badgeText={undefined}
          badgeTooltipText={undefined}
        />
        <TextContainer>
          <EllipsisTextOverflowWrapper>
            <Header className="notranslate">{header}</Header>
          </EllipsisTextOverflowWrapper>
          <EllipsisTextOverflowWrapper>
            <SubHeader className="notranslate">{subHeader}</SubHeader>
          </EllipsisTextOverflowWrapper>
        </TextContainer>
      </LeftContainer>
      {showIcon && (
        <IconContainer>
          <AccountArrowsIcon />
        </IconContainer>
      )}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlex)`
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  transition: 0.3s;
  cursor: pointer;
  border-radius: 12px;

  &:hover {
    background: ${({ theme }) => theme.colors.neutralLightest};
  }

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    padding: 0;
  }
`;

const LeftContainer = styled(Gap8HorizontalFlex)`
  align-items: center;
`;

const TextContainer = styled(VerticalFlex)`
  gap: 2px;
  ${sensitiveDataFilter()}

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    display: none;
  }
`;

const Header = styled(TextSmall)``;

const SubHeader = styled(TextTiny)`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.ghostDark};

  &:first-letter {
    text-transform: uppercase;
  }
`;

const IconContainer = styled(HorizontalFlex)`
  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    display: none;
  }
`;

export default AccountButton;
