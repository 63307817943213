import {
  FEATURE_WIDGET_LOADING,
  FEATURE_WIDGET_FALLBACK_VIDEO
} from '../constants/features.constants.js';

export const getFeatureSettings = ({ config, featureKey }) => {
  return config?.featureSettings?.[featureKey];
};

export const getFeatureIsEnabled = ({ config, featureKey }) => {
  return Boolean(config?.featureSettings?.[featureKey]);
};

export const getIsPlayerLazy = (config = {}) => {
  const widgetLoadingFeature = getFeatureSettings({ config, featureKey: FEATURE_WIDGET_LOADING });
  return widgetLoadingFeature?.playerLazy;
};

export const getPlayerOrigin = (config = {}) => {
  const widgetLoadingFeature = getFeatureSettings({ config, featureKey: FEATURE_WIDGET_LOADING });
  return widgetLoadingFeature?.playerOrigin;
};

export const getIsFallbackVideoDisabled = (config = {}) => {
  const widgetFallbackVideoFeature = getFeatureSettings({
    config,
    featureKey: FEATURE_WIDGET_FALLBACK_VIDEO
  });
  return widgetFallbackVideoFeature?.dontUseFallback;
};
