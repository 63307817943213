export const PAGE_TYPES = {
  product: 'product',
  default: 'other',
};

export const PRODUCT_SOURCES = {
  shopify: 'shopify',
  magento: 'magento',
  url: 'url',
};

export const SHOPIFY_PUBLISHING_TUTORIAL_URL =
  'https://share.synthesia.io/6c4e3381-4e16-4d12-b11d-54f508fc7601';
