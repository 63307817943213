import React, { useEffect, useState, useRef } from 'react';
import TrackerProviderWrapper from 'shared/react/components/complex/TrackerProviderWrapper';
import { ProjectConfigProvider } from 'shared/react/components/complex/context/ProjectConfigContext';
import { constructStepsMapping } from 'shared/react/components/complex/feed/feed_utils/feed.utils';
import { AccountProvider } from 'shared/react/components/complex/context/AccountContext';
import { useAccount } from 'app/src/context/AccountStore';
import { useProducts } from 'app/src/context/ProductsStore';
import { ProductsProvider } from 'shared/react/components/complex/context/ProductsContext';
import { ModalProvider } from 'shared/react/components/complex/context/ModalContext';
import { FeedStateProvider } from 'shared/react/components/complex/context/FeedStateContext';
import { FeaturesProvider } from 'shared/react/components/complex/context/FeaturesContext';
import { CartProvider } from 'shared/react/components/complex/context/CartContext';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { useFormContext } from 'react-hook-form';
import { FeedSnackbarProvider } from 'shared/react/components/complex/context/FeedSnackbarContext';
import { TranslationProvider } from 'shared/react/components/complex/context/TranslationContext';
import { DEFAULT_PLAYER_LANGUAGE } from 'shared/react/constants/feature-constants/playerLangaugeCustomization.constants';
import { getIsFeed } from 'app/src/context/ProjectsStore';
import { ProductRecommendationsProvider } from 'shared/react/components/complex/context/ProductRecommendationsContext';
import { RechargeProvider } from 'shared/react/components/complex/context/RechargeContext';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';
import { usePackageBrandingFeatures } from 'src/hooks/package';

const RootProvidersWrapper = ({ project, isMuted, setIsMuted, children }) => {
  const [{ account }] = useAccount();
  const [{ ecomProducts }, { getEcomProducts }] = useProducts();
  const [{ data: features }] = useFeatures();
  const { watch } = useFormContext();
  const currStep = watch();
  const [langText, setLangText] = useState({});
  const [modalsStack, setModalsStack] = useState([]);
  const [productRecommendationsMap, setProductRecommendationsMap] = useState({});
  const {
    player: { settings: playerDesignSettings },
  } = useDesignSettingsContext();
  const { isPlayerLanguageEnabled } = usePackageBrandingFeatures();
  const [buttonsRefLength, setButtonsRefLength] = useState(0);
  const buttonsRef = useRef({});

  const playerLanguage =
    project.language ||
    (isPlayerLanguageEnabled && playerDesignSettings?.language) ||
    DEFAULT_PLAYER_LANGUAGE;

  useEffect(() => {
    getEcomProducts(project);
  }, [project]);

  const {
    steps: { items: steps },
    id: projectId,
    publishId,
    feed,
    playerSettings,
  } = project;

  const updatedSteps = steps.map(step => {
    if (step.id === currStep.id) {
      return currStep;
    }
    return step;
  });

  const stepsMap = constructStepsMapping(updatedSteps);

  const currentPlayerSettings =
    typeof playerSettings === 'object' ? playerSettings : JSON.parse(playerSettings || '{}');

  const projectConfigContext = {
    projectId,
    steps: updatedSteps,
    stepsMap,
    isAppPreview: true,
    publishId,
    feed,
    playerSettings: currentPlayerSettings || {},
    isShoppable: currentPlayerSettings?.isShoppable ?? true,
  };

  const feedStateContext = {
    isUserMute: isMuted,
    setIsUserMute: setIsMuted,
    buttonsRef,
    buttonsRefLength,
    setButtonsRefLength,
  };

  const getModalContext = project => {
    if (getIsFeed(project)) {
      return {
        setCurrentModal: () => {},
        setModalProps: () => {},
      };
    }

    return {
      modalsStack,
      setModalsStack,
    };
  };

  const modalContext = getModalContext(project);

  const getFeatureSettings = () => {
    const featuresSettings = {};

    for (const [featureKey, { enabled, settings }] of Object.entries(features)) {
      if (!enabled) {
        continue;
      }

      featuresSettings[featureKey] = settings;
    }

    return featuresSettings;
  };

  const cartContext = { cart: {} };

  const snackbarContext = {
    setSnackbarText: () => {},
  };

  const productRecommendationsContext = {
    productRecommendationsMap,
    setProductRecommendationsMap,
  };

  const rechargeContext = {
    isRechargeEnabled: false,
  };

  const featureSettings = getFeatureSettings();

  const translationContext = { translationObj: langText, language: playerLanguage };

  const getPlayerLanguage = async () => {
    if (!playerLanguage || playerLanguage === DEFAULT_PLAYER_LANGUAGE) {
      return;
    }
    const data = await import(`@shared/react/i18n/local/${playerLanguage}.json`);

    if (data) {
      setLangText(data);
    }
  };

  useEffect(() => {
    getPlayerLanguage();
  }, [playerLanguage]);

  return (
    <TrackerProviderWrapper>
      <TranslationProvider value={translationContext}>
        <FeedStateProvider value={feedStateContext}>
          <ProjectConfigProvider value={projectConfigContext}>
            <AccountProvider value={{ ...account }}>
              <FeaturesProvider value={featureSettings}>
                <ProductsProvider value={{ products: ecomProducts, productsExtraData: null }}>
                  <CartProvider value={cartContext}>
                    <ModalProvider value={modalContext}>
                      <FeedSnackbarProvider value={snackbarContext}>
                        <ProductRecommendationsProvider value={productRecommendationsContext}>
                          <RechargeProvider value={rechargeContext}>{children}</RechargeProvider>
                        </ProductRecommendationsProvider>
                      </FeedSnackbarProvider>
                    </ModalProvider>
                  </CartProvider>
                </ProductsProvider>
              </FeaturesProvider>
            </AccountProvider>
          </ProjectConfigProvider>
        </FeedStateProvider>
      </TranslationProvider>
    </TrackerProviderWrapper>
  );
};

export default RootProvidersWrapper;
