import React, { forwardRef } from 'react';
import styled from 'styled-components';
import VerticalFlex from './VerticalFlex';

const Gap8VerticalFlex = forwardRef(({ children, ...props }, ref) => {
  return (
    <LayoutRoot {...props} ref={ref}>
      {children}
    </LayoutRoot>
  );
});

Gap8VerticalFlex.displayName = 'Gap8VerticalFlex';

export default Gap8VerticalFlex;

const LayoutRoot = styled(VerticalFlex)`
  gap: 8px;
`;
