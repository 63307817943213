import React from 'react';
import EditorGroup from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorGroup';
import { DesignBrandingTypography, WidgetSettings } from 'app/src/types/design';
import SpotlightWidgetEditor from 'app/src/pages/account/branding/editors/widgets/SpotlightWidgetEditor';

type Props = {
  settings: WidgetSettings;
  features: any;
  defaultSettings: WidgetSettings;
  onChange: (settings: WidgetSettings, options: any) => void;
  fonts: DesignBrandingTypography;
};

const WidgetsEditor = ({
  settings = {},
  features = {},
  defaultSettings = {},
  onChange = () => {},
  fonts = {},
}: Props) => {
  const updateSettings = (update, options) => onChange({ ...settings, ...update }, options);
  const onSpotlightChange = (settings, options) =>
    updateSettings({ spotlightCarousel: settings }, options);

  return (
    <EditorGroup
      editors={[
        {
          key: 'spotlight',
          component: (
            <SpotlightWidgetEditor
              settings={settings.spotlightCarousel}
              onChange={onSpotlightChange}
              fonts={fonts}
            />
          ),
        },
      ]}
    />
  );
};

export default WidgetsEditor;
