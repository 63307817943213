import React, { useState } from 'react';
import InputWrapper from 'app/src/basic_components/InputWrapper';
import tinycolor from 'tinycolor2';
import styled from 'styled-components';
import CustomInput from 'app/src/basic_components/Input';
import ColourfulRoundedButton from 'app/src/images/ColourfulRoundedButton.png';
import ColorPalettePicker from 'app/src/complex_components/color-palette/ColorPalettePicker';
import useAppColorPalette from 'app/src/hooks/apps-store/useAppColorPalette';
import PalletColorInput from 'app/src/complex_components/color-input/PalletColorInput';

const MinimumLightBrightness = 240;
let debounceTimeout;

const ColorInput = ({ disabled, color, onChange, height, maxWidth, ...props }) => {
  const [inputEl, setInputEl] = useState(null);
  const [isOpen, setIsOpen] = useState(null);
  const palette = useAppColorPalette();
  const hasPalette = !!palette.length;

  const handleBorderColorChange = async e => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const { value } = e.target;
    debounceTimeout = setTimeout(async () => {
      onChange(value);
    }, 400);
  };

  const onInputChange = e => {
    const value = e.target.value;
    onChange(value);
  };

  const onOpen = () => {
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
  };

  const handleColorChange = color => {
    onChange(color);
  };

  const roundColorShape = (
    <RoundContainer height={hasPalette ? '18px' : height}>
      <InputWrapper
        visible={true}
        value={color}
        onChange={handleBorderColorChange}
        disabled={disabled}
      >
        <ColorPicker
          disabled={disabled}
          hasBorder={tinycolor(color).getBrightness() > MinimumLightBrightness}
          color={color}
          onClick={onOpen}
        />
      </InputWrapper>
      <ColorPalettePicker
        open={!!isOpen}
        anchorEl={inputEl}
        onChange={handleColorChange}
        onClose={onClose}
        value={color}
      />
    </RoundContainer>
  );

  if (hasPalette) {
    return (
      <PalletColorInput
        color={color}
        onClick={onOpen}
        palette={palette}
        roundColorShape={roundColorShape}
        setInputEl={setInputEl}
      />
    );
  }

  return (
    <Input
      value={color}
      onChange={onInputChange}
      maxWidth={maxWidth}
      disabled={disabled}
      InputProps={{ endAdornment: roundColorShape }}
      placeholder="Pick a color"
      ref={setInputEl}
      {...props}
    />
  );
};

const ColorPicker = ({ color, disabled, onClick }) => {
  if (!color) {
    return (
      <ColourfulButtonContainer onClick={onClick}>
        <ColourfulButton src={ColourfulRoundedButton} />
      </ColourfulButtonContainer>
    );
  }

  return (
    <ColorCircleButton
      disabled={disabled}
      hasBorder={tinycolor(color).getBrightness() > MinimumLightBrightness}
      backgroundColor={color}
      onClick={onClick}
    />
  );
};

const ColourfulButtonContainer = styled.div`
  display: flex;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  align-items: center;
`;

const ColourfulButton = styled.img`
  min-width: 18px;
  width: 18px;
  height: 18px;
`;

const ColorCircleButton = styled.div`
  height: 18px;
  width: 18px;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  border: ${({ hasBorder, theme }) => (hasBorder ? `1px solid ${theme.colors.ghostDark}` : 'none')};
  border-radius: 50px;
  background: ${props => props.backgroundColor};
`;

const RoundContainer = styled.div`
  height: ${({ height }) => height};
`;

const Input = styled(CustomInput)`
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  .MuiOutlinedInput-input {
    padding: 8px 14px;
  }
`;

export default ColorInput;
