import React from 'react';

const PlayIcon = ({ fill = '#3D4FE0' }) => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.4375 8.75H2.25C2.01562 8.75 1.78125 8.65625 1.64062 8.46875C1.5 8.28125 1.45312 8.04688 1.5 7.83594L1.66406 7.25C1.89844 6.26562 2.48438 5.46875 3.25781 4.92969L3.51562 1.625H2.8125C2.69531 1.625 2.60156 1.60156 2.50781 1.55469C2.34375 1.46094 2.25 1.27344 2.25 1.0625C2.25 0.757812 2.48438 0.5 2.8125 0.5H9.1875C9.49219 0.5 9.75 0.757812 9.75 1.0625C9.75 1.27344 9.63281 1.46094 9.46875 1.55469C9.375 1.60156 9.28125 1.625 9.1875 1.625H8.46094L8.71875 4.92969C9.49219 5.46875 10.0781 6.26562 10.3125 7.25L10.4766 7.83594C10.5234 8.04688 10.4766 8.28125 10.3359 8.46875C10.1953 8.65625 9.96094 8.75 9.75 8.75H6.5625V11.9375C6.5625 12.2656 6.30469 12.5 5.97656 12.5C5.67188 12.5 5.41406 12.2656 5.41406 11.9375L5.4375 8.75ZM4.35938 5.5625L3.89062 5.86719C3.35156 6.24219 2.92969 6.82812 2.74219 7.50781L2.71875 7.625H5.4375V5.5625C5.4375 5.25781 5.67188 5 6 5C6.30469 5 6.5625 5.25781 6.5625 5.5625V7.625H9.25781L9.23438 7.50781C9.04688 6.82812 8.625 6.24219 8.08594 5.86719L7.61719 5.5625L7.33594 1.625H4.64062L4.35938 5.5625Z"
      fill={fill}
    />
  </svg>
);

export default PlayIcon;
