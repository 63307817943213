/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../types/entities";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateOrUpdateSessionByAppKey = /* GraphQL */ `subscription OnCreateOrUpdateSessionByAppKey($appKey: String!) {
  onCreateOrUpdateSessionByAppKey(appKey: $appKey) {
    id
    owner
    assignee
    appKey
    projectId
    createdAt
    updatedAt
    lastEventAt
    sessionTime
    name
    email
    phone
    read
    archived
    actionNeeded
    totalEvents
    totalResponses
    totalReplies
    parentUrl
    videoResponse
    imageResponse
    audioResponse
    textResponse
    formResponse
    reachedEnd
    isSpam
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOrUpdateSessionByAppKeySubscriptionVariables,
  APITypes.OnCreateOrUpdateSessionByAppKeySubscription
>;
export const onUpdateAccountSettingsByAppKey = /* GraphQL */ `subscription OnUpdateAccountSettingsByAppKey($appKey: String!) {
  onUpdateAccountSettingsByAppKey(appKey: $appKey) {
    id
    appKey
    owner
    name
    logoSettings
    widgetSettings
    downgradeInfo
    liveIds
    url
    logoUrl
    playerLogoUrl
    companyVertical
    companySize
    companyRole
    monthlyTraffic
    useCase
    packageId
    token
    paymentProvider
    paymentSubscriptions
    paymentIdentifier
    packageGroup
    joinedPackageAt
    trialEndAt
    referral
    crmData
    trialDays
    googleAnalyticsID
    appsInstallationStatuses
    onboardingStatuses
    assetAnalysis
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAccountSettingsByAppKeySubscriptionVariables,
  APITypes.OnUpdateAccountSettingsByAppKeySubscription
>;
export const onUpdateProjectByAppKey = /* GraphQL */ `subscription OnUpdateProjectByAppKey($appKey: String!) {
  onUpdateProjectByAppKey(appKey: $appKey) {
    id
    owner
    appKey
    typeKey
    createdAt
    publishId
    name
    folder
    targetPage
    verticalOrientation
    widgetPosition
    widgetHeader
    widgetUrls
    description
    emailNotifications
    notificationLevel
    viewerNotificationLevel
    chaptersEnabled
    subtitlesEnabled
    stepsOrder
    collectText
    collectFields {
      key
      name
      type
      placeholder
      link
      required
      collect
      custom
      textColor
      borderColor
      backgroundColor
    }
    collectAfterStep
    cacheLeadFormAnswers
    googleAnalyticsID
    facebookAnalyticsID
    totalResponses
    unreadResponses
    tolstoyType
    steps {
      items {
        id
        owner
        appKey
        projectId
        videoId
        name
        type
        description
        script
        question
        questionVisible
        answerTime
        answers {
          key
          text
          next
          value
          type
          disabled
        }
        products {
          id
        }
        productsAppUrl
        videoContain
        overlayText
        overlayTextColor
        multipleChoiceSelection
        defaultResponses {
          key
          text
          next
          value
          type
          disabled
        }
        defaultResponsesEnabled
        sideBarButtonsEnabled
        feedProductActionButton {
          type
          url
          text
          isOpenInANewTab
          value
          enabled
        }
        feedSidePanelType
        feedDescriptionActionButton {
          type
          url
          text
          isOpenInANewTab
          value
          enabled
        }
        feedSidePanelDescription
        productCtaType
        createdAt
        updatedAt
      }
      nextToken
    }
    stepsCount
    widgetBorderColor
    widgetShape
    widgetSize
    widgetNotificationBadge
    videoResolutions
    fastForwardEnabled
    rulesEnabled
    macro
    hideChatPlatform
    private
    chatLandingPage
    widgetSettings
    notificationSettingsId
    collectAfterAnyResponse
    startScreenText
    feed
    feedAutoScroll
    feedShowUnviewedStepsFirst
    language
    showButtonsIndex
    publishMethod
    dynamic
    feedProductCtaType
    playerSettings
    appUrl
    live
    primaryButton {
      enabled
      ctaType
      buttonText
      value
      isOpenInANewTab
    }
    secondaryButton {
      enabled
      ctaType
      buttonText
      value
      isOpenInANewTab
    }
    pdpConfig {
      primaryButton {
        enabled
        ctaType
        buttonText
        value
        isOpenInANewTab
      }
      secondaryButton {
        enabled
        ctaType
        buttonText
        value
        isOpenInANewTab
      }
      miniCtaButton {
        enabled
        text
      }
    }
    installationCompleted
    installStep
    discover
    useCaseName
    heroSettings
    sourceProjectId
    emailSettings
    videoCollectionCampaignSettings
    secretVideoSettings
    integrationSettings
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateProjectByAppKeySubscriptionVariables,
  APITypes.OnUpdateProjectByAppKeySubscription
>;
export const onProjectChangeByAppKey = /* GraphQL */ `subscription OnProjectChangeByAppKey($appKey: String!) {
  onProjectChangeByAppKey(appKey: $appKey) {
    id
    owner
    appKey
    typeKey
    createdAt
    publishId
    name
    folder
    targetPage
    verticalOrientation
    widgetPosition
    widgetHeader
    widgetUrls
    description
    emailNotifications
    notificationLevel
    viewerNotificationLevel
    chaptersEnabled
    subtitlesEnabled
    stepsOrder
    collectText
    collectFields {
      key
      name
      type
      placeholder
      link
      required
      collect
      custom
      textColor
      borderColor
      backgroundColor
    }
    collectAfterStep
    cacheLeadFormAnswers
    googleAnalyticsID
    facebookAnalyticsID
    totalResponses
    unreadResponses
    tolstoyType
    steps {
      items {
        id
        owner
        appKey
        projectId
        videoId
        name
        type
        description
        script
        question
        questionVisible
        answerTime
        answers {
          key
          text
          next
          value
          type
          disabled
        }
        products {
          id
        }
        productsAppUrl
        videoContain
        overlayText
        overlayTextColor
        multipleChoiceSelection
        defaultResponses {
          key
          text
          next
          value
          type
          disabled
        }
        defaultResponsesEnabled
        sideBarButtonsEnabled
        feedProductActionButton {
          type
          url
          text
          isOpenInANewTab
          value
          enabled
        }
        feedSidePanelType
        feedDescriptionActionButton {
          type
          url
          text
          isOpenInANewTab
          value
          enabled
        }
        feedSidePanelDescription
        productCtaType
        createdAt
        updatedAt
      }
      nextToken
    }
    stepsCount
    widgetBorderColor
    widgetShape
    widgetSize
    widgetNotificationBadge
    videoResolutions
    fastForwardEnabled
    rulesEnabled
    macro
    hideChatPlatform
    private
    chatLandingPage
    widgetSettings
    notificationSettingsId
    collectAfterAnyResponse
    startScreenText
    feed
    feedAutoScroll
    feedShowUnviewedStepsFirst
    language
    showButtonsIndex
    publishMethod
    dynamic
    feedProductCtaType
    playerSettings
    appUrl
    live
    primaryButton {
      enabled
      ctaType
      buttonText
      value
      isOpenInANewTab
    }
    secondaryButton {
      enabled
      ctaType
      buttonText
      value
      isOpenInANewTab
    }
    pdpConfig {
      primaryButton {
        enabled
        ctaType
        buttonText
        value
        isOpenInANewTab
      }
      secondaryButton {
        enabled
        ctaType
        buttonText
        value
        isOpenInANewTab
      }
      miniCtaButton {
        enabled
        text
      }
    }
    installationCompleted
    installStep
    discover
    useCaseName
    heroSettings
    sourceProjectId
    emailSettings
    videoCollectionCampaignSettings
    secretVideoSettings
    integrationSettings
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnProjectChangeByAppKeySubscriptionVariables,
  APITypes.OnProjectChangeByAppKeySubscription
>;
export const onProjectStepChangeByAppKey = /* GraphQL */ `subscription OnProjectStepChangeByAppKey($appKey: String!) {
  onProjectStepChangeByAppKey(appKey: $appKey) {
    id
    owner
    appKey
    projectId
    videoId
    name
    type
    description
    script
    question
    questionVisible
    answerTime
    answers {
      key
      text
      next
      value
      type
      disabled
    }
    products {
      id
    }
    productsAppUrl
    videoContain
    overlayText
    overlayTextColor
    multipleChoiceSelection
    defaultResponses {
      key
      text
      next
      value
      type
      disabled
    }
    defaultResponsesEnabled
    sideBarButtonsEnabled
    feedProductActionButton {
      type
      url
      text
      isOpenInANewTab
      value
      enabled
    }
    feedSidePanelType
    feedDescriptionActionButton {
      type
      url
      text
      isOpenInANewTab
      value
      enabled
    }
    feedSidePanelDescription
    productCtaType
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnProjectStepChangeByAppKeySubscriptionVariables,
  APITypes.OnProjectStepChangeByAppKeySubscription
>;
export const onCreateOrUpdateVodAssetByAppKey = /* GraphQL */ `subscription OnCreateOrUpdateVodAssetByAppKey($appKey: String!) {
  onCreateOrUpdateVodAssetByAppKey(appKey: $appKey) {
    id
    type
    owner
    appKey
    appExternalId
    createdAt
    name
    description
    creatorProfile {
      url
    }
    isSoundAllowed
    status
    uploadType
    stockAsset {
      videoUrl
      posterUrl
      gifUrl
      hasOriginal
      shopifyFileId
      previewUrl
      previewShopifyFileId
      shopifyPosterUrl
      posterShopifyFileId
      avatarUrl
      audioUrl
      gifShopifyFileId
    }
    duration
    folder
    width
    height
    isVertical
    isHDR
    trimSettings
    originalVOD
    mirror
    recorderVersion
    originalSize
    externalId
    externalProviderData
    isMigratingToShopify
    shopifyUploadingStatus
    isEverUsed
    captionsSettings
    referenceIds
    vodConnections {
      items {
        id
        appKey
        vodAssetId
        productId
        externalProductId
        collectionId
        provider
        tag
        createdAt
        typeKey
        type
        appUrl
        label
        vodLabelId
        vodSource
        vodType
        variantIds
        projectId
        orderIndex
        source
        updatedAt
      }
      nextToken
    }
    analysis
    autoGenerated
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOrUpdateVodAssetByAppKeySubscriptionVariables,
  APITypes.OnCreateOrUpdateVodAssetByAppKeySubscription
>;
export const onCreateInAppNotificationByTeamMemberId = /* GraphQL */ `subscription OnCreateInAppNotificationByTeamMemberId($teamMemberId: String!) {
  onCreateInAppNotificationByTeamMemberId(teamMemberId: $teamMemberId) {
    id
    owner
    teamMemberId
    projectId
    sessionId
    type
    value
    email
    createdAt
    read
    expirationUnixTime
    token
    stepName
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateInAppNotificationByTeamMemberIdSubscriptionVariables,
  APITypes.OnCreateInAppNotificationByTeamMemberIdSubscription
>;
export const onCreateOrUpdateAppByAppKey = /* GraphQL */ `subscription OnCreateOrUpdateAppByAppKey($appKey: String!) {
  onCreateOrUpdateAppByAppKey(appKey: $appKey) {
    id
    owner
    appKey
    app
    active
    data
    token
    appExternalId
    appUrl
    importSettings
    importRange
    syncTagsMode
    syndicateBy
    googleAnalyticsID
    settings
    isShopAppEligible
    taxonomyId
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOrUpdateAppByAppKeySubscriptionVariables,
  APITypes.OnCreateOrUpdateAppByAppKeySubscription
>;
export const onCreateOrUpdateIntegrationVodAssetByAppKey = /* GraphQL */ `subscription OnCreateOrUpdateIntegrationVodAssetByAppKey($appKey: String!) {
  onCreateOrUpdateIntegrationVodAssetByAppKey(appKey: $appKey) {
    id
    appKey
    owner
    app
    appId
    externalId
    externalCreatedAt
    vodId
    seen
    title
    thumbnailUrl
    downloadUrl
    status
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOrUpdateIntegrationVodAssetByAppKeySubscriptionVariables,
  APITypes.OnCreateOrUpdateIntegrationVodAssetByAppKeySubscription
>;
export const onCreateVodConnectionByAppKey = /* GraphQL */ `subscription OnCreateVodConnectionByAppKey($appKey: String!) {
  onCreateVodConnectionByAppKey(appKey: $appKey) {
    id
    appKey
    vodAssetId
    productId
    externalProductId
    collectionId
    provider
    tag
    createdAt
    typeKey
    type
    appUrl
    label
    vodLabelId
    vodSource
    vodType
    variantIds
    projectId
    orderIndex
    source
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateVodConnectionByAppKeySubscriptionVariables,
  APITypes.OnCreateVodConnectionByAppKeySubscription
>;
export const onDeleteVodConnectionByAppKey = /* GraphQL */ `subscription OnDeleteVodConnectionByAppKey($appKey: String!) {
  onDeleteVodConnectionByAppKey(appKey: $appKey) {
    id
    appKey
    vodAssetId
    productId
    externalProductId
    collectionId
    provider
    tag
    createdAt
    typeKey
    type
    appUrl
    label
    vodLabelId
    vodSource
    vodType
    variantIds
    projectId
    orderIndex
    source
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteVodConnectionByAppKeySubscriptionVariables,
  APITypes.OnDeleteVodConnectionByAppKeySubscription
>;
export const onUpdateVodConnectionByAppKey = /* GraphQL */ `subscription OnUpdateVodConnectionByAppKey($appKey: String!) {
  onUpdateVodConnectionByAppKey(appKey: $appKey) {
    id
    appKey
    vodAssetId
    productId
    externalProductId
    collectionId
    provider
    tag
    createdAt
    typeKey
    type
    appUrl
    label
    vodLabelId
    vodSource
    vodType
    variantIds
    projectId
    orderIndex
    source
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateVodConnectionByAppKeySubscriptionVariables,
  APITypes.OnUpdateVodConnectionByAppKeySubscription
>;
export const onCreateVodConnectionSuggestionByAppKey = /* GraphQL */ `subscription OnCreateVodConnectionSuggestionByAppKey($appKey: String!) {
  onCreateVodConnectionSuggestionByAppKey(appKey: $appKey) {
    id
    appKey
    appUrl
    createdAt
    vodAssetId
    externalProductId
    status
    source
    method
    methodVersion
    score
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateVodConnectionSuggestionByAppKeySubscriptionVariables,
  APITypes.OnCreateVodConnectionSuggestionByAppKeySubscription
>;
export const onCreateVodAsset = /* GraphQL */ `subscription OnCreateVodAsset {
  onCreateVodAsset {
    id
    type
    owner
    appKey
    appExternalId
    createdAt
    name
    description
    creatorProfile {
      url
    }
    isSoundAllowed
    status
    uploadType
    stockAsset {
      videoUrl
      posterUrl
      gifUrl
      hasOriginal
      shopifyFileId
      previewUrl
      previewShopifyFileId
      shopifyPosterUrl
      posterShopifyFileId
      avatarUrl
      audioUrl
      gifShopifyFileId
    }
    duration
    folder
    width
    height
    isVertical
    isHDR
    trimSettings
    originalVOD
    mirror
    recorderVersion
    originalSize
    externalId
    externalProviderData
    isMigratingToShopify
    shopifyUploadingStatus
    isEverUsed
    captionsSettings
    referenceIds
    vodConnections {
      items {
        id
        appKey
        vodAssetId
        productId
        externalProductId
        collectionId
        provider
        tag
        createdAt
        typeKey
        type
        appUrl
        label
        vodLabelId
        vodSource
        vodType
        variantIds
        projectId
        orderIndex
        source
        updatedAt
      }
      nextToken
    }
    analysis
    autoGenerated
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateVodAssetSubscriptionVariables,
  APITypes.OnCreateVodAssetSubscription
>;
export const onUpdateVodAsset = /* GraphQL */ `subscription OnUpdateVodAsset {
  onUpdateVodAsset {
    id
    type
    owner
    appKey
    appExternalId
    createdAt
    name
    description
    creatorProfile {
      url
    }
    isSoundAllowed
    status
    uploadType
    stockAsset {
      videoUrl
      posterUrl
      gifUrl
      hasOriginal
      shopifyFileId
      previewUrl
      previewShopifyFileId
      shopifyPosterUrl
      posterShopifyFileId
      avatarUrl
      audioUrl
      gifShopifyFileId
    }
    duration
    folder
    width
    height
    isVertical
    isHDR
    trimSettings
    originalVOD
    mirror
    recorderVersion
    originalSize
    externalId
    externalProviderData
    isMigratingToShopify
    shopifyUploadingStatus
    isEverUsed
    captionsSettings
    referenceIds
    vodConnections {
      items {
        id
        appKey
        vodAssetId
        productId
        externalProductId
        collectionId
        provider
        tag
        createdAt
        typeKey
        type
        appUrl
        label
        vodLabelId
        vodSource
        vodType
        variantIds
        projectId
        orderIndex
        source
        updatedAt
      }
      nextToken
    }
    analysis
    autoGenerated
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateVodAssetSubscriptionVariables,
  APITypes.OnUpdateVodAssetSubscription
>;
export const onDeleteVodAsset = /* GraphQL */ `subscription OnDeleteVodAsset {
  onDeleteVodAsset {
    id
    type
    owner
    appKey
    appExternalId
    createdAt
    name
    description
    creatorProfile {
      url
    }
    isSoundAllowed
    status
    uploadType
    stockAsset {
      videoUrl
      posterUrl
      gifUrl
      hasOriginal
      shopifyFileId
      previewUrl
      previewShopifyFileId
      shopifyPosterUrl
      posterShopifyFileId
      avatarUrl
      audioUrl
      gifShopifyFileId
    }
    duration
    folder
    width
    height
    isVertical
    isHDR
    trimSettings
    originalVOD
    mirror
    recorderVersion
    originalSize
    externalId
    externalProviderData
    isMigratingToShopify
    shopifyUploadingStatus
    isEverUsed
    captionsSettings
    referenceIds
    vodConnections {
      items {
        id
        appKey
        vodAssetId
        productId
        externalProductId
        collectionId
        provider
        tag
        createdAt
        typeKey
        type
        appUrl
        label
        vodLabelId
        vodSource
        vodType
        variantIds
        projectId
        orderIndex
        source
        updatedAt
      }
      nextToken
    }
    analysis
    autoGenerated
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteVodAssetSubscriptionVariables,
  APITypes.OnDeleteVodAssetSubscription
>;
export const onCreateVodResponseAsset = /* GraphQL */ `subscription OnCreateVodResponseAsset {
  onCreateVodResponseAsset {
    id
    owner
    appKey
    createdAt
    status
    uploadType
    duration
    width
    height
    isVertical
    isHDR
    mirror
    originalSize
    email
    projectId
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateVodResponseAssetSubscriptionVariables,
  APITypes.OnCreateVodResponseAssetSubscription
>;
export const onUpdateVodResponseAsset = /* GraphQL */ `subscription OnUpdateVodResponseAsset {
  onUpdateVodResponseAsset {
    id
    owner
    appKey
    createdAt
    status
    uploadType
    duration
    width
    height
    isVertical
    isHDR
    mirror
    originalSize
    email
    projectId
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateVodResponseAssetSubscriptionVariables,
  APITypes.OnUpdateVodResponseAssetSubscription
>;
export const onDeleteVodResponseAsset = /* GraphQL */ `subscription OnDeleteVodResponseAsset {
  onDeleteVodResponseAsset {
    id
    owner
    appKey
    createdAt
    status
    uploadType
    duration
    width
    height
    isVertical
    isHDR
    mirror
    originalSize
    email
    projectId
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteVodResponseAssetSubscriptionVariables,
  APITypes.OnDeleteVodResponseAssetSubscription
>;
export const onCreateProject = /* GraphQL */ `subscription OnCreateProject {
  onCreateProject {
    id
    owner
    appKey
    typeKey
    createdAt
    publishId
    name
    folder
    targetPage
    verticalOrientation
    widgetPosition
    widgetHeader
    widgetUrls
    description
    emailNotifications
    notificationLevel
    viewerNotificationLevel
    chaptersEnabled
    subtitlesEnabled
    stepsOrder
    collectText
    collectFields {
      key
      name
      type
      placeholder
      link
      required
      collect
      custom
      textColor
      borderColor
      backgroundColor
    }
    collectAfterStep
    cacheLeadFormAnswers
    googleAnalyticsID
    facebookAnalyticsID
    totalResponses
    unreadResponses
    tolstoyType
    steps {
      items {
        id
        owner
        appKey
        projectId
        videoId
        name
        type
        description
        script
        question
        questionVisible
        answerTime
        answers {
          key
          text
          next
          value
          type
          disabled
        }
        products {
          id
        }
        productsAppUrl
        videoContain
        overlayText
        overlayTextColor
        multipleChoiceSelection
        defaultResponses {
          key
          text
          next
          value
          type
          disabled
        }
        defaultResponsesEnabled
        sideBarButtonsEnabled
        feedProductActionButton {
          type
          url
          text
          isOpenInANewTab
          value
          enabled
        }
        feedSidePanelType
        feedDescriptionActionButton {
          type
          url
          text
          isOpenInANewTab
          value
          enabled
        }
        feedSidePanelDescription
        productCtaType
        createdAt
        updatedAt
      }
      nextToken
    }
    stepsCount
    widgetBorderColor
    widgetShape
    widgetSize
    widgetNotificationBadge
    videoResolutions
    fastForwardEnabled
    rulesEnabled
    macro
    hideChatPlatform
    private
    chatLandingPage
    widgetSettings
    notificationSettingsId
    collectAfterAnyResponse
    startScreenText
    feed
    feedAutoScroll
    feedShowUnviewedStepsFirst
    language
    showButtonsIndex
    publishMethod
    dynamic
    feedProductCtaType
    playerSettings
    appUrl
    live
    primaryButton {
      enabled
      ctaType
      buttonText
      value
      isOpenInANewTab
    }
    secondaryButton {
      enabled
      ctaType
      buttonText
      value
      isOpenInANewTab
    }
    pdpConfig {
      primaryButton {
        enabled
        ctaType
        buttonText
        value
        isOpenInANewTab
      }
      secondaryButton {
        enabled
        ctaType
        buttonText
        value
        isOpenInANewTab
      }
      miniCtaButton {
        enabled
        text
      }
    }
    installationCompleted
    installStep
    discover
    useCaseName
    heroSettings
    sourceProjectId
    emailSettings
    videoCollectionCampaignSettings
    secretVideoSettings
    integrationSettings
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateProjectSubscriptionVariables,
  APITypes.OnCreateProjectSubscription
>;
export const onUpdateProject = /* GraphQL */ `subscription OnUpdateProject {
  onUpdateProject {
    id
    owner
    appKey
    typeKey
    createdAt
    publishId
    name
    folder
    targetPage
    verticalOrientation
    widgetPosition
    widgetHeader
    widgetUrls
    description
    emailNotifications
    notificationLevel
    viewerNotificationLevel
    chaptersEnabled
    subtitlesEnabled
    stepsOrder
    collectText
    collectFields {
      key
      name
      type
      placeholder
      link
      required
      collect
      custom
      textColor
      borderColor
      backgroundColor
    }
    collectAfterStep
    cacheLeadFormAnswers
    googleAnalyticsID
    facebookAnalyticsID
    totalResponses
    unreadResponses
    tolstoyType
    steps {
      items {
        id
        owner
        appKey
        projectId
        videoId
        name
        type
        description
        script
        question
        questionVisible
        answerTime
        answers {
          key
          text
          next
          value
          type
          disabled
        }
        products {
          id
        }
        productsAppUrl
        videoContain
        overlayText
        overlayTextColor
        multipleChoiceSelection
        defaultResponses {
          key
          text
          next
          value
          type
          disabled
        }
        defaultResponsesEnabled
        sideBarButtonsEnabled
        feedProductActionButton {
          type
          url
          text
          isOpenInANewTab
          value
          enabled
        }
        feedSidePanelType
        feedDescriptionActionButton {
          type
          url
          text
          isOpenInANewTab
          value
          enabled
        }
        feedSidePanelDescription
        productCtaType
        createdAt
        updatedAt
      }
      nextToken
    }
    stepsCount
    widgetBorderColor
    widgetShape
    widgetSize
    widgetNotificationBadge
    videoResolutions
    fastForwardEnabled
    rulesEnabled
    macro
    hideChatPlatform
    private
    chatLandingPage
    widgetSettings
    notificationSettingsId
    collectAfterAnyResponse
    startScreenText
    feed
    feedAutoScroll
    feedShowUnviewedStepsFirst
    language
    showButtonsIndex
    publishMethod
    dynamic
    feedProductCtaType
    playerSettings
    appUrl
    live
    primaryButton {
      enabled
      ctaType
      buttonText
      value
      isOpenInANewTab
    }
    secondaryButton {
      enabled
      ctaType
      buttonText
      value
      isOpenInANewTab
    }
    pdpConfig {
      primaryButton {
        enabled
        ctaType
        buttonText
        value
        isOpenInANewTab
      }
      secondaryButton {
        enabled
        ctaType
        buttonText
        value
        isOpenInANewTab
      }
      miniCtaButton {
        enabled
        text
      }
    }
    installationCompleted
    installStep
    discover
    useCaseName
    heroSettings
    sourceProjectId
    emailSettings
    videoCollectionCampaignSettings
    secretVideoSettings
    integrationSettings
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateProjectSubscriptionVariables,
  APITypes.OnUpdateProjectSubscription
>;
export const onDeleteProject = /* GraphQL */ `subscription OnDeleteProject {
  onDeleteProject {
    id
    owner
    appKey
    typeKey
    createdAt
    publishId
    name
    folder
    targetPage
    verticalOrientation
    widgetPosition
    widgetHeader
    widgetUrls
    description
    emailNotifications
    notificationLevel
    viewerNotificationLevel
    chaptersEnabled
    subtitlesEnabled
    stepsOrder
    collectText
    collectFields {
      key
      name
      type
      placeholder
      link
      required
      collect
      custom
      textColor
      borderColor
      backgroundColor
    }
    collectAfterStep
    cacheLeadFormAnswers
    googleAnalyticsID
    facebookAnalyticsID
    totalResponses
    unreadResponses
    tolstoyType
    steps {
      items {
        id
        owner
        appKey
        projectId
        videoId
        name
        type
        description
        script
        question
        questionVisible
        answerTime
        answers {
          key
          text
          next
          value
          type
          disabled
        }
        products {
          id
        }
        productsAppUrl
        videoContain
        overlayText
        overlayTextColor
        multipleChoiceSelection
        defaultResponses {
          key
          text
          next
          value
          type
          disabled
        }
        defaultResponsesEnabled
        sideBarButtonsEnabled
        feedProductActionButton {
          type
          url
          text
          isOpenInANewTab
          value
          enabled
        }
        feedSidePanelType
        feedDescriptionActionButton {
          type
          url
          text
          isOpenInANewTab
          value
          enabled
        }
        feedSidePanelDescription
        productCtaType
        createdAt
        updatedAt
      }
      nextToken
    }
    stepsCount
    widgetBorderColor
    widgetShape
    widgetSize
    widgetNotificationBadge
    videoResolutions
    fastForwardEnabled
    rulesEnabled
    macro
    hideChatPlatform
    private
    chatLandingPage
    widgetSettings
    notificationSettingsId
    collectAfterAnyResponse
    startScreenText
    feed
    feedAutoScroll
    feedShowUnviewedStepsFirst
    language
    showButtonsIndex
    publishMethod
    dynamic
    feedProductCtaType
    playerSettings
    appUrl
    live
    primaryButton {
      enabled
      ctaType
      buttonText
      value
      isOpenInANewTab
    }
    secondaryButton {
      enabled
      ctaType
      buttonText
      value
      isOpenInANewTab
    }
    pdpConfig {
      primaryButton {
        enabled
        ctaType
        buttonText
        value
        isOpenInANewTab
      }
      secondaryButton {
        enabled
        ctaType
        buttonText
        value
        isOpenInANewTab
      }
      miniCtaButton {
        enabled
        text
      }
    }
    installationCompleted
    installStep
    discover
    useCaseName
    heroSettings
    sourceProjectId
    emailSettings
    videoCollectionCampaignSettings
    secretVideoSettings
    integrationSettings
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteProjectSubscriptionVariables,
  APITypes.OnDeleteProjectSubscription
>;
export const onCreateProjectStep = /* GraphQL */ `subscription OnCreateProjectStep {
  onCreateProjectStep {
    id
    owner
    appKey
    projectId
    videoId
    name
    type
    description
    script
    question
    questionVisible
    answerTime
    answers {
      key
      text
      next
      value
      type
      disabled
    }
    products {
      id
    }
    productsAppUrl
    videoContain
    overlayText
    overlayTextColor
    multipleChoiceSelection
    defaultResponses {
      key
      text
      next
      value
      type
      disabled
    }
    defaultResponsesEnabled
    sideBarButtonsEnabled
    feedProductActionButton {
      type
      url
      text
      isOpenInANewTab
      value
      enabled
    }
    feedSidePanelType
    feedDescriptionActionButton {
      type
      url
      text
      isOpenInANewTab
      value
      enabled
    }
    feedSidePanelDescription
    productCtaType
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateProjectStepSubscriptionVariables,
  APITypes.OnCreateProjectStepSubscription
>;
export const onUpdateProjectStep = /* GraphQL */ `subscription OnUpdateProjectStep {
  onUpdateProjectStep {
    id
    owner
    appKey
    projectId
    videoId
    name
    type
    description
    script
    question
    questionVisible
    answerTime
    answers {
      key
      text
      next
      value
      type
      disabled
    }
    products {
      id
    }
    productsAppUrl
    videoContain
    overlayText
    overlayTextColor
    multipleChoiceSelection
    defaultResponses {
      key
      text
      next
      value
      type
      disabled
    }
    defaultResponsesEnabled
    sideBarButtonsEnabled
    feedProductActionButton {
      type
      url
      text
      isOpenInANewTab
      value
      enabled
    }
    feedSidePanelType
    feedDescriptionActionButton {
      type
      url
      text
      isOpenInANewTab
      value
      enabled
    }
    feedSidePanelDescription
    productCtaType
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateProjectStepSubscriptionVariables,
  APITypes.OnUpdateProjectStepSubscription
>;
export const onDeleteProjectStep = /* GraphQL */ `subscription OnDeleteProjectStep {
  onDeleteProjectStep {
    id
    owner
    appKey
    projectId
    videoId
    name
    type
    description
    script
    question
    questionVisible
    answerTime
    answers {
      key
      text
      next
      value
      type
      disabled
    }
    products {
      id
    }
    productsAppUrl
    videoContain
    overlayText
    overlayTextColor
    multipleChoiceSelection
    defaultResponses {
      key
      text
      next
      value
      type
      disabled
    }
    defaultResponsesEnabled
    sideBarButtonsEnabled
    feedProductActionButton {
      type
      url
      text
      isOpenInANewTab
      value
      enabled
    }
    feedSidePanelType
    feedDescriptionActionButton {
      type
      url
      text
      isOpenInANewTab
      value
      enabled
    }
    feedSidePanelDescription
    productCtaType
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteProjectStepSubscriptionVariables,
  APITypes.OnDeleteProjectStepSubscription
>;
export const onCreateUserSettings = /* GraphQL */ `subscription OnCreateUserSettings {
  onCreateUserSettings {
    id
    owner
    email
    firstName
    lastName
    lastLogin
    source
    token
    appKey
    hasLoggedIn
    hasShared
    viewedTolstoys
    calendars {
      id
      name
      url
    }
    emailAutomationTool
    logoSettings
    googleInfo
    crmData
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserSettingsSubscriptionVariables,
  APITypes.OnCreateUserSettingsSubscription
>;
export const onUpdateUserSettings = /* GraphQL */ `subscription OnUpdateUserSettings {
  onUpdateUserSettings {
    id
    owner
    email
    firstName
    lastName
    lastLogin
    source
    token
    appKey
    hasLoggedIn
    hasShared
    viewedTolstoys
    calendars {
      id
      name
      url
    }
    emailAutomationTool
    logoSettings
    googleInfo
    crmData
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserSettingsSubscriptionVariables,
  APITypes.OnUpdateUserSettingsSubscription
>;
export const onDeleteUserSettings = /* GraphQL */ `subscription OnDeleteUserSettings {
  onDeleteUserSettings {
    id
    owner
    email
    firstName
    lastName
    lastLogin
    source
    token
    appKey
    hasLoggedIn
    hasShared
    viewedTolstoys
    calendars {
      id
      name
      url
    }
    emailAutomationTool
    logoSettings
    googleInfo
    crmData
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserSettingsSubscriptionVariables,
  APITypes.OnDeleteUserSettingsSubscription
>;
export const onCreateAccountSettings = /* GraphQL */ `subscription OnCreateAccountSettings {
  onCreateAccountSettings {
    id
    appKey
    owner
    name
    logoSettings
    widgetSettings
    downgradeInfo
    liveIds
    url
    logoUrl
    playerLogoUrl
    companyVertical
    companySize
    companyRole
    monthlyTraffic
    useCase
    packageId
    token
    paymentProvider
    paymentSubscriptions
    paymentIdentifier
    packageGroup
    joinedPackageAt
    trialEndAt
    referral
    crmData
    trialDays
    googleAnalyticsID
    appsInstallationStatuses
    onboardingStatuses
    assetAnalysis
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAccountSettingsSubscriptionVariables,
  APITypes.OnCreateAccountSettingsSubscription
>;
export const onUpdateAccountSettings = /* GraphQL */ `subscription OnUpdateAccountSettings {
  onUpdateAccountSettings {
    id
    appKey
    owner
    name
    logoSettings
    widgetSettings
    downgradeInfo
    liveIds
    url
    logoUrl
    playerLogoUrl
    companyVertical
    companySize
    companyRole
    monthlyTraffic
    useCase
    packageId
    token
    paymentProvider
    paymentSubscriptions
    paymentIdentifier
    packageGroup
    joinedPackageAt
    trialEndAt
    referral
    crmData
    trialDays
    googleAnalyticsID
    appsInstallationStatuses
    onboardingStatuses
    assetAnalysis
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAccountSettingsSubscriptionVariables,
  APITypes.OnUpdateAccountSettingsSubscription
>;
export const onDeleteAccountSettings = /* GraphQL */ `subscription OnDeleteAccountSettings {
  onDeleteAccountSettings {
    id
    appKey
    owner
    name
    logoSettings
    widgetSettings
    downgradeInfo
    liveIds
    url
    logoUrl
    playerLogoUrl
    companyVertical
    companySize
    companyRole
    monthlyTraffic
    useCase
    packageId
    token
    paymentProvider
    paymentSubscriptions
    paymentIdentifier
    packageGroup
    joinedPackageAt
    trialEndAt
    referral
    crmData
    trialDays
    googleAnalyticsID
    appsInstallationStatuses
    onboardingStatuses
    assetAnalysis
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAccountSettingsSubscriptionVariables,
  APITypes.OnDeleteAccountSettingsSubscription
>;
export const onCreateApp = /* GraphQL */ `subscription OnCreateApp {
  onCreateApp {
    id
    owner
    appKey
    app
    active
    data
    token
    appExternalId
    appUrl
    importSettings
    importRange
    syncTagsMode
    syndicateBy
    googleAnalyticsID
    settings
    isShopAppEligible
    taxonomyId
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAppSubscriptionVariables,
  APITypes.OnCreateAppSubscription
>;
export const onUpdateApp = /* GraphQL */ `subscription OnUpdateApp {
  onUpdateApp {
    id
    owner
    appKey
    app
    active
    data
    token
    appExternalId
    appUrl
    importSettings
    importRange
    syncTagsMode
    syndicateBy
    googleAnalyticsID
    settings
    isShopAppEligible
    taxonomyId
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAppSubscriptionVariables,
  APITypes.OnUpdateAppSubscription
>;
export const onDeleteApp = /* GraphQL */ `subscription OnDeleteApp {
  onDeleteApp {
    id
    owner
    appKey
    app
    active
    data
    token
    appExternalId
    appUrl
    importSettings
    importRange
    syncTagsMode
    syndicateBy
    googleAnalyticsID
    settings
    isShopAppEligible
    taxonomyId
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAppSubscriptionVariables,
  APITypes.OnDeleteAppSubscription
>;
export const onCreateProjectApp = /* GraphQL */ `subscription OnCreateProjectApp {
  onCreateProjectApp {
    id
    appId
    appKey
    projectId
    owner
    active
    data
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateProjectAppSubscriptionVariables,
  APITypes.OnCreateProjectAppSubscription
>;
export const onUpdateProjectApp = /* GraphQL */ `subscription OnUpdateProjectApp {
  onUpdateProjectApp {
    id
    appId
    appKey
    projectId
    owner
    active
    data
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateProjectAppSubscriptionVariables,
  APITypes.OnUpdateProjectAppSubscription
>;
export const onDeleteProjectApp = /* GraphQL */ `subscription OnDeleteProjectApp {
  onDeleteProjectApp {
    id
    appId
    appKey
    projectId
    owner
    active
    data
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteProjectAppSubscriptionVariables,
  APITypes.OnDeleteProjectAppSubscription
>;
export const onCreateResponse = /* GraphQL */ `subscription OnCreateResponse {
  onCreateResponse {
    id
    owner
    appKey
    stepName
    projectId
    anonymousId
    sessionId
    type
    value
    email
    name
    phone
    createdAt
    read
    collectInfo
    answerType
    parentUrl
    isProfanity
    attachments {
      fileName
      url
      type
    }
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateResponseSubscriptionVariables,
  APITypes.OnCreateResponseSubscription
>;
export const onUpdateResponse = /* GraphQL */ `subscription OnUpdateResponse {
  onUpdateResponse {
    id
    owner
    appKey
    stepName
    projectId
    anonymousId
    sessionId
    type
    value
    email
    name
    phone
    createdAt
    read
    collectInfo
    answerType
    parentUrl
    isProfanity
    attachments {
      fileName
      url
      type
    }
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateResponseSubscriptionVariables,
  APITypes.OnUpdateResponseSubscription
>;
export const onDeleteResponse = /* GraphQL */ `subscription OnDeleteResponse {
  onDeleteResponse {
    id
    owner
    appKey
    stepName
    projectId
    anonymousId
    sessionId
    type
    value
    email
    name
    phone
    createdAt
    read
    collectInfo
    answerType
    parentUrl
    isProfanity
    attachments {
      fileName
      url
      type
    }
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteResponseSubscriptionVariables,
  APITypes.OnDeleteResponseSubscription
>;
export const onCreateInAppNotification = /* GraphQL */ `subscription OnCreateInAppNotification {
  onCreateInAppNotification {
    id
    owner
    teamMemberId
    projectId
    sessionId
    type
    value
    email
    createdAt
    read
    expirationUnixTime
    token
    stepName
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateInAppNotificationSubscriptionVariables,
  APITypes.OnCreateInAppNotificationSubscription
>;
export const onUpdateInAppNotification = /* GraphQL */ `subscription OnUpdateInAppNotification {
  onUpdateInAppNotification {
    id
    owner
    teamMemberId
    projectId
    sessionId
    type
    value
    email
    createdAt
    read
    expirationUnixTime
    token
    stepName
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateInAppNotificationSubscriptionVariables,
  APITypes.OnUpdateInAppNotificationSubscription
>;
export const onDeleteInAppNotification = /* GraphQL */ `subscription OnDeleteInAppNotification {
  onDeleteInAppNotification {
    id
    owner
    teamMemberId
    projectId
    sessionId
    type
    value
    email
    createdAt
    read
    expirationUnixTime
    token
    stepName
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteInAppNotificationSubscriptionVariables,
  APITypes.OnDeleteInAppNotificationSubscription
>;
export const onCreateConferenceRoomConnection = /* GraphQL */ `subscription OnCreateConferenceRoomConnection {
  onCreateConferenceRoomConnection {
    id
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateConferenceRoomConnectionSubscriptionVariables,
  APITypes.OnCreateConferenceRoomConnectionSubscription
>;
export const onUpdateConferenceRoomConnection = /* GraphQL */ `subscription OnUpdateConferenceRoomConnection {
  onUpdateConferenceRoomConnection {
    id
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateConferenceRoomConnectionSubscriptionVariables,
  APITypes.OnUpdateConferenceRoomConnectionSubscription
>;
export const onDeleteConferenceRoomConnection = /* GraphQL */ `subscription OnDeleteConferenceRoomConnection {
  onDeleteConferenceRoomConnection {
    id
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteConferenceRoomConnectionSubscriptionVariables,
  APITypes.OnDeleteConferenceRoomConnectionSubscription
>;
export const onCreateConferenceRoom = /* GraphQL */ `subscription OnCreateConferenceRoom {
  onCreateConferenceRoom {
    id
    owner
    roomId
    userId
    active
    email
    appToken
    lastSeenAt
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateConferenceRoomSubscriptionVariables,
  APITypes.OnCreateConferenceRoomSubscription
>;
export const onUpdateConferenceRoom = /* GraphQL */ `subscription OnUpdateConferenceRoom {
  onUpdateConferenceRoom {
    id
    owner
    roomId
    userId
    active
    email
    appToken
    lastSeenAt
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateConferenceRoomSubscriptionVariables,
  APITypes.OnUpdateConferenceRoomSubscription
>;
export const onDeleteConferenceRoom = /* GraphQL */ `subscription OnDeleteConferenceRoom {
  onDeleteConferenceRoom {
    id
    owner
    roomId
    userId
    active
    email
    appToken
    lastSeenAt
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteConferenceRoomSubscriptionVariables,
  APITypes.OnDeleteConferenceRoomSubscription
>;
export const onCreateConferenceRoomActivity = /* GraphQL */ `subscription OnCreateConferenceRoomActivity {
  onCreateConferenceRoomActivity {
    id
    roomName
    createdAt
    expirationUnixTime
    updatedAt
    appToken
    type
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateConferenceRoomActivitySubscriptionVariables,
  APITypes.OnCreateConferenceRoomActivitySubscription
>;
export const onUpdateConferenceRoomActivity = /* GraphQL */ `subscription OnUpdateConferenceRoomActivity {
  onUpdateConferenceRoomActivity {
    id
    roomName
    createdAt
    expirationUnixTime
    updatedAt
    appToken
    type
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateConferenceRoomActivitySubscriptionVariables,
  APITypes.OnUpdateConferenceRoomActivitySubscription
>;
export const onDeleteConferenceRoomActivity = /* GraphQL */ `subscription OnDeleteConferenceRoomActivity {
  onDeleteConferenceRoomActivity {
    id
    roomName
    createdAt
    expirationUnixTime
    updatedAt
    appToken
    type
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteConferenceRoomActivitySubscriptionVariables,
  APITypes.OnDeleteConferenceRoomActivitySubscription
>;
export const onCreateSession = /* GraphQL */ `subscription OnCreateSession {
  onCreateSession {
    id
    owner
    assignee
    appKey
    projectId
    createdAt
    updatedAt
    lastEventAt
    sessionTime
    name
    email
    phone
    read
    archived
    actionNeeded
    totalEvents
    totalResponses
    totalReplies
    parentUrl
    videoResponse
    imageResponse
    audioResponse
    textResponse
    formResponse
    reachedEnd
    isSpam
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSessionSubscriptionVariables,
  APITypes.OnCreateSessionSubscription
>;
export const onUpdateSession = /* GraphQL */ `subscription OnUpdateSession {
  onUpdateSession {
    id
    owner
    assignee
    appKey
    projectId
    createdAt
    updatedAt
    lastEventAt
    sessionTime
    name
    email
    phone
    read
    archived
    actionNeeded
    totalEvents
    totalResponses
    totalReplies
    parentUrl
    videoResponse
    imageResponse
    audioResponse
    textResponse
    formResponse
    reachedEnd
    isSpam
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSessionSubscriptionVariables,
  APITypes.OnUpdateSessionSubscription
>;
export const onDeleteSession = /* GraphQL */ `subscription OnDeleteSession {
  onDeleteSession {
    id
    owner
    assignee
    appKey
    projectId
    createdAt
    updatedAt
    lastEventAt
    sessionTime
    name
    email
    phone
    read
    archived
    actionNeeded
    totalEvents
    totalResponses
    totalReplies
    parentUrl
    videoResponse
    imageResponse
    audioResponse
    textResponse
    formResponse
    reachedEnd
    isSpam
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSessionSubscriptionVariables,
  APITypes.OnDeleteSessionSubscription
>;
export const onCreateAccountIdentifiedUsers = /* GraphQL */ `subscription OnCreateAccountIdentifiedUsers {
  onCreateAccountIdentifiedUsers {
    id
    owner
    appKey
    userId
    email
    phone
    name
    lastSeenAt
    firstSeenAt
    signedUpAt
    sessionCount
    customAttributes
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAccountIdentifiedUsersSubscriptionVariables,
  APITypes.OnCreateAccountIdentifiedUsersSubscription
>;
export const onUpdateAccountIdentifiedUsers = /* GraphQL */ `subscription OnUpdateAccountIdentifiedUsers {
  onUpdateAccountIdentifiedUsers {
    id
    owner
    appKey
    userId
    email
    phone
    name
    lastSeenAt
    firstSeenAt
    signedUpAt
    sessionCount
    customAttributes
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAccountIdentifiedUsersSubscriptionVariables,
  APITypes.OnUpdateAccountIdentifiedUsersSubscription
>;
export const onDeleteAccountIdentifiedUsers = /* GraphQL */ `subscription OnDeleteAccountIdentifiedUsers {
  onDeleteAccountIdentifiedUsers {
    id
    owner
    appKey
    userId
    email
    phone
    name
    lastSeenAt
    firstSeenAt
    signedUpAt
    sessionCount
    customAttributes
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAccountIdentifiedUsersSubscriptionVariables,
  APITypes.OnDeleteAccountIdentifiedUsersSubscription
>;
export const onCreateRuleGroup = /* GraphQL */ `subscription OnCreateRuleGroup {
  onCreateRuleGroup {
    id
    appKey
    projectId
    publishId
    owner
    rules {
      key
      type
      condition
      value
      limit
      behaviors
    }
    enabled
    visitorModeEnabled
    exitIntent
    delayTriggerEnabled
    delayTriggerSeconds
    showOnDevice
    showOnDomains
    abTestId
    type
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateRuleGroupSubscriptionVariables,
  APITypes.OnCreateRuleGroupSubscription
>;
export const onUpdateRuleGroup = /* GraphQL */ `subscription OnUpdateRuleGroup {
  onUpdateRuleGroup {
    id
    appKey
    projectId
    publishId
    owner
    rules {
      key
      type
      condition
      value
      limit
      behaviors
    }
    enabled
    visitorModeEnabled
    exitIntent
    delayTriggerEnabled
    delayTriggerSeconds
    showOnDevice
    showOnDomains
    abTestId
    type
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateRuleGroupSubscriptionVariables,
  APITypes.OnUpdateRuleGroupSubscription
>;
export const onDeleteRuleGroup = /* GraphQL */ `subscription OnDeleteRuleGroup {
  onDeleteRuleGroup {
    id
    appKey
    projectId
    publishId
    owner
    rules {
      key
      type
      condition
      value
      limit
      behaviors
    }
    enabled
    visitorModeEnabled
    exitIntent
    delayTriggerEnabled
    delayTriggerSeconds
    showOnDevice
    showOnDomains
    abTestId
    type
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteRuleGroupSubscriptionVariables,
  APITypes.OnDeleteRuleGroupSubscription
>;
export const onCreateIdentifyAttribute = /* GraphQL */ `subscription OnCreateIdentifyAttribute {
  onCreateIdentifyAttribute {
    id
    appKey
    owner
    key
    type
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateIdentifyAttributeSubscriptionVariables,
  APITypes.OnCreateIdentifyAttributeSubscription
>;
export const onUpdateIdentifyAttribute = /* GraphQL */ `subscription OnUpdateIdentifyAttribute {
  onUpdateIdentifyAttribute {
    id
    appKey
    owner
    key
    type
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateIdentifyAttributeSubscriptionVariables,
  APITypes.OnUpdateIdentifyAttributeSubscription
>;
export const onDeleteIdentifyAttribute = /* GraphQL */ `subscription OnDeleteIdentifyAttribute {
  onDeleteIdentifyAttribute {
    id
    appKey
    owner
    key
    type
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteIdentifyAttributeSubscriptionVariables,
  APITypes.OnDeleteIdentifyAttributeSubscription
>;
export const onCreateInvite = /* GraphQL */ `subscription OnCreateInvite {
  onCreateInvite {
    id
    owner
    appKey
    email
    role
    status
    token
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateInviteSubscriptionVariables,
  APITypes.OnCreateInviteSubscription
>;
export const onUpdateInvite = /* GraphQL */ `subscription OnUpdateInvite {
  onUpdateInvite {
    id
    owner
    appKey
    email
    role
    status
    token
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateInviteSubscriptionVariables,
  APITypes.OnUpdateInviteSubscription
>;
export const onDeleteInvite = /* GraphQL */ `subscription OnDeleteInvite {
  onDeleteInvite {
    id
    owner
    appKey
    email
    role
    status
    token
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteInviteSubscriptionVariables,
  APITypes.OnDeleteInviteSubscription
>;
export const onCreateAccountTeamMember = /* GraphQL */ `subscription OnCreateAccountTeamMember {
  onCreateAccountTeamMember {
    id
    owner
    appKey
    email
    role
    status
    inviteToken
    createdAt
    userId
    teamMemberId
    lastLoginAt
    user {
      items {
        id
        owner
        email
        firstName
        lastName
        lastLogin
        source
        token
        appKey
        hasLoggedIn
        hasShared
        viewedTolstoys
        calendars {
          id
          name
          url
        }
        emailAutomationTool
        logoSettings
        googleInfo
        crmData
        createdAt
        updatedAt
      }
      nextToken
    }
    account {
      items {
        id
        appKey
        owner
        name
        logoSettings
        widgetSettings
        downgradeInfo
        liveIds
        url
        logoUrl
        playerLogoUrl
        companyVertical
        companySize
        companyRole
        monthlyTraffic
        useCase
        packageId
        token
        paymentProvider
        paymentSubscriptions
        paymentIdentifier
        packageGroup
        joinedPackageAt
        trialEndAt
        referral
        crmData
        trialDays
        googleAnalyticsID
        appsInstallationStatuses
        onboardingStatuses
        assetAnalysis
        createdAt
        updatedAt
      }
      nextToken
    }
    notificationSettingsId
    paymentRole
    unreadInAppNotificationsCounter
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAccountTeamMemberSubscriptionVariables,
  APITypes.OnCreateAccountTeamMemberSubscription
>;
export const onUpdateAccountTeamMember = /* GraphQL */ `subscription OnUpdateAccountTeamMember {
  onUpdateAccountTeamMember {
    id
    owner
    appKey
    email
    role
    status
    inviteToken
    createdAt
    userId
    teamMemberId
    lastLoginAt
    user {
      items {
        id
        owner
        email
        firstName
        lastName
        lastLogin
        source
        token
        appKey
        hasLoggedIn
        hasShared
        viewedTolstoys
        calendars {
          id
          name
          url
        }
        emailAutomationTool
        logoSettings
        googleInfo
        crmData
        createdAt
        updatedAt
      }
      nextToken
    }
    account {
      items {
        id
        appKey
        owner
        name
        logoSettings
        widgetSettings
        downgradeInfo
        liveIds
        url
        logoUrl
        playerLogoUrl
        companyVertical
        companySize
        companyRole
        monthlyTraffic
        useCase
        packageId
        token
        paymentProvider
        paymentSubscriptions
        paymentIdentifier
        packageGroup
        joinedPackageAt
        trialEndAt
        referral
        crmData
        trialDays
        googleAnalyticsID
        appsInstallationStatuses
        onboardingStatuses
        assetAnalysis
        createdAt
        updatedAt
      }
      nextToken
    }
    notificationSettingsId
    paymentRole
    unreadInAppNotificationsCounter
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAccountTeamMemberSubscriptionVariables,
  APITypes.OnUpdateAccountTeamMemberSubscription
>;
export const onDeleteAccountTeamMember = /* GraphQL */ `subscription OnDeleteAccountTeamMember {
  onDeleteAccountTeamMember {
    id
    owner
    appKey
    email
    role
    status
    inviteToken
    createdAt
    userId
    teamMemberId
    lastLoginAt
    user {
      items {
        id
        owner
        email
        firstName
        lastName
        lastLogin
        source
        token
        appKey
        hasLoggedIn
        hasShared
        viewedTolstoys
        calendars {
          id
          name
          url
        }
        emailAutomationTool
        logoSettings
        googleInfo
        crmData
        createdAt
        updatedAt
      }
      nextToken
    }
    account {
      items {
        id
        appKey
        owner
        name
        logoSettings
        widgetSettings
        downgradeInfo
        liveIds
        url
        logoUrl
        playerLogoUrl
        companyVertical
        companySize
        companyRole
        monthlyTraffic
        useCase
        packageId
        token
        paymentProvider
        paymentSubscriptions
        paymentIdentifier
        packageGroup
        joinedPackageAt
        trialEndAt
        referral
        crmData
        trialDays
        googleAnalyticsID
        appsInstallationStatuses
        onboardingStatuses
        assetAnalysis
        createdAt
        updatedAt
      }
      nextToken
    }
    notificationSettingsId
    paymentRole
    unreadInAppNotificationsCounter
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAccountTeamMemberSubscriptionVariables,
  APITypes.OnDeleteAccountTeamMemberSubscription
>;
export const onCreateTeamMemberSettings = /* GraphQL */ `subscription OnCreateTeamMemberSettings {
  onCreateTeamMemberSettings {
    id
    teamMemberId
    notificationSettingsId
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTeamMemberSettingsSubscriptionVariables,
  APITypes.OnCreateTeamMemberSettingsSubscription
>;
export const onUpdateTeamMemberSettings = /* GraphQL */ `subscription OnUpdateTeamMemberSettings {
  onUpdateTeamMemberSettings {
    id
    teamMemberId
    notificationSettingsId
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTeamMemberSettingsSubscriptionVariables,
  APITypes.OnUpdateTeamMemberSettingsSubscription
>;
export const onDeleteTeamMemberSettings = /* GraphQL */ `subscription OnDeleteTeamMemberSettings {
  onDeleteTeamMemberSettings {
    id
    teamMemberId
    notificationSettingsId
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTeamMemberSettingsSubscriptionVariables,
  APITypes.OnDeleteTeamMemberSettingsSubscription
>;
export const onCreateNotificationSettings = /* GraphQL */ `subscription OnCreateNotificationSettings {
  onCreateNotificationSettings {
    id
    owner
    projectId
    email {
      showAnonymous
      enabled
      digest
    }
    inApp {
      showAnonymous
      enabled
      digest
    }
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateNotificationSettingsSubscriptionVariables,
  APITypes.OnCreateNotificationSettingsSubscription
>;
export const onUpdateNotificationSettings = /* GraphQL */ `subscription OnUpdateNotificationSettings {
  onUpdateNotificationSettings {
    id
    owner
    projectId
    email {
      showAnonymous
      enabled
      digest
    }
    inApp {
      showAnonymous
      enabled
      digest
    }
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateNotificationSettingsSubscriptionVariables,
  APITypes.OnUpdateNotificationSettingsSubscription
>;
export const onDeleteNotificationSettings = /* GraphQL */ `subscription OnDeleteNotificationSettings {
  onDeleteNotificationSettings {
    id
    owner
    projectId
    email {
      showAnonymous
      enabled
      digest
    }
    inApp {
      showAnonymous
      enabled
      digest
    }
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteNotificationSettingsSubscriptionVariables,
  APITypes.OnDeleteNotificationSettingsSubscription
>;
export const onCreateFolder = /* GraphQL */ `subscription OnCreateFolder {
  onCreateFolder {
    id
    owner
    name
    appKey
    private
    type
    appUrl
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFolderSubscriptionVariables,
  APITypes.OnCreateFolderSubscription
>;
export const onUpdateFolder = /* GraphQL */ `subscription OnUpdateFolder {
  onUpdateFolder {
    id
    owner
    name
    appKey
    private
    type
    appUrl
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFolderSubscriptionVariables,
  APITypes.OnUpdateFolderSubscription
>;
export const onDeleteFolder = /* GraphQL */ `subscription OnDeleteFolder {
  onDeleteFolder {
    id
    owner
    name
    appKey
    private
    type
    appUrl
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteFolderSubscriptionVariables,
  APITypes.OnDeleteFolderSubscription
>;
export const onCreateAccountUser = /* GraphQL */ `subscription OnCreateAccountUser {
  onCreateAccountUser {
    id
    appKey
    email
    name
    phone
    createdAt
    updatedAt
    lastSeenAt
    emailVerified
    sessionCount
    customAttributes
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAccountUserSubscriptionVariables,
  APITypes.OnCreateAccountUserSubscription
>;
export const onUpdateAccountUser = /* GraphQL */ `subscription OnUpdateAccountUser {
  onUpdateAccountUser {
    id
    appKey
    email
    name
    phone
    createdAt
    updatedAt
    lastSeenAt
    emailVerified
    sessionCount
    customAttributes
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAccountUserSubscriptionVariables,
  APITypes.OnUpdateAccountUserSubscription
>;
export const onDeleteAccountUser = /* GraphQL */ `subscription OnDeleteAccountUser {
  onDeleteAccountUser {
    id
    appKey
    email
    name
    phone
    createdAt
    updatedAt
    lastSeenAt
    emailVerified
    sessionCount
    customAttributes
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAccountUserSubscriptionVariables,
  APITypes.OnDeleteAccountUserSubscription
>;
export const onCreateAccountUsage = /* GraphQL */ `subscription OnCreateAccountUsage {
  onCreateAccountUsage {
    id
    appKey
    featureId
    usage
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAccountUsageSubscriptionVariables,
  APITypes.OnCreateAccountUsageSubscription
>;
export const onUpdateAccountUsage = /* GraphQL */ `subscription OnUpdateAccountUsage {
  onUpdateAccountUsage {
    id
    appKey
    featureId
    usage
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAccountUsageSubscriptionVariables,
  APITypes.OnUpdateAccountUsageSubscription
>;
export const onDeleteAccountUsage = /* GraphQL */ `subscription OnDeleteAccountUsage {
  onDeleteAccountUsage {
    id
    appKey
    featureId
    usage
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAccountUsageSubscriptionVariables,
  APITypes.OnDeleteAccountUsageSubscription
>;
export const onCreatePlayerConfig = /* GraphQL */ `subscription OnCreatePlayerConfig {
  onCreatePlayerConfig {
    id
    appKey
    publishId
    projectId
    config
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePlayerConfigSubscriptionVariables,
  APITypes.OnCreatePlayerConfigSubscription
>;
export const onUpdatePlayerConfig = /* GraphQL */ `subscription OnUpdatePlayerConfig {
  onUpdatePlayerConfig {
    id
    appKey
    publishId
    projectId
    config
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePlayerConfigSubscriptionVariables,
  APITypes.OnUpdatePlayerConfigSubscription
>;
export const onDeletePlayerConfig = /* GraphQL */ `subscription OnDeletePlayerConfig {
  onDeletePlayerConfig {
    id
    appKey
    publishId
    projectId
    config
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePlayerConfigSubscriptionVariables,
  APITypes.OnDeletePlayerConfigSubscription
>;
export const onCreateWidgetConfig = /* GraphQL */ `subscription OnCreateWidgetConfig {
  onCreateWidgetConfig {
    id
    appKey
    publishId
    projectId
    config
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateWidgetConfigSubscriptionVariables,
  APITypes.OnCreateWidgetConfigSubscription
>;
export const onUpdateWidgetConfig = /* GraphQL */ `subscription OnUpdateWidgetConfig {
  onUpdateWidgetConfig {
    id
    appKey
    publishId
    projectId
    config
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateWidgetConfigSubscriptionVariables,
  APITypes.OnUpdateWidgetConfigSubscription
>;
export const onDeleteWidgetConfig = /* GraphQL */ `subscription OnDeleteWidgetConfig {
  onDeleteWidgetConfig {
    id
    appKey
    publishId
    projectId
    config
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteWidgetConfigSubscriptionVariables,
  APITypes.OnDeleteWidgetConfigSubscription
>;
export const onCreateAccountPackage = /* GraphQL */ `subscription OnCreateAccountPackage {
  onCreateAccountPackage {
    id
    appKey
    packageId
    createdAt
    updatedAt
    app
    featuresPackageId
    group
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAccountPackageSubscriptionVariables,
  APITypes.OnCreateAccountPackageSubscription
>;
export const onUpdateAccountPackage = /* GraphQL */ `subscription OnUpdateAccountPackage {
  onUpdateAccountPackage {
    id
    appKey
    packageId
    createdAt
    updatedAt
    app
    featuresPackageId
    group
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAccountPackageSubscriptionVariables,
  APITypes.OnUpdateAccountPackageSubscription
>;
export const onDeleteAccountPackage = /* GraphQL */ `subscription OnDeleteAccountPackage {
  onDeleteAccountPackage {
    id
    appKey
    packageId
    createdAt
    updatedAt
    app
    featuresPackageId
    group
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAccountPackageSubscriptionVariables,
  APITypes.OnDeleteAccountPackageSubscription
>;
export const onCreatePackage = /* GraphQL */ `subscription OnCreatePackage {
  onCreatePackage {
    id
    name
    description
    stripeProducts {
      type
      price
    }
    trialPeriodDays
    maxTrialPeriodDays
    type
    details
    group
    order
    featuresPackageId
    addons
    app
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePackageSubscriptionVariables,
  APITypes.OnCreatePackageSubscription
>;
export const onUpdatePackage = /* GraphQL */ `subscription OnUpdatePackage {
  onUpdatePackage {
    id
    name
    description
    stripeProducts {
      type
      price
    }
    trialPeriodDays
    maxTrialPeriodDays
    type
    details
    group
    order
    featuresPackageId
    addons
    app
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePackageSubscriptionVariables,
  APITypes.OnUpdatePackageSubscription
>;
export const onDeletePackage = /* GraphQL */ `subscription OnDeletePackage {
  onDeletePackage {
    id
    name
    description
    stripeProducts {
      type
      price
    }
    trialPeriodDays
    maxTrialPeriodDays
    type
    details
    group
    order
    featuresPackageId
    addons
    app
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePackageSubscriptionVariables,
  APITypes.OnDeletePackageSubscription
>;
export const onCreateExperiment = /* GraphQL */ `subscription OnCreateExperiment {
  onCreateExperiment {
    id
    featureId
    name
    description
    expireAt
    type
    groups
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateExperimentSubscriptionVariables,
  APITypes.OnCreateExperimentSubscription
>;
export const onUpdateExperiment = /* GraphQL */ `subscription OnUpdateExperiment {
  onUpdateExperiment {
    id
    featureId
    name
    description
    expireAt
    type
    groups
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateExperimentSubscriptionVariables,
  APITypes.OnUpdateExperimentSubscription
>;
export const onDeleteExperiment = /* GraphQL */ `subscription OnDeleteExperiment {
  onDeleteExperiment {
    id
    featureId
    name
    description
    expireAt
    type
    groups
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteExperimentSubscriptionVariables,
  APITypes.OnDeleteExperimentSubscription
>;
export const onCreatePayment = /* GraphQL */ `subscription OnCreatePayment {
  onCreatePayment {
    id
    subscriptionId
    appKey
    reportAt
    usagePrice
    usageQuantity
    type
    provider
    identifier
    packageId
    packageType
    dailyUsagePrice
    aggregatedPrice
    aggregatedUsageQuantity
    dailyUsageQuantity
    seatsPrice
    price
    interval
    numberOfProTeamMembers
    invoiceId
    invoiceItemId
    paymentSubscriptionId
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePaymentSubscriptionVariables,
  APITypes.OnCreatePaymentSubscription
>;
export const onUpdatePayment = /* GraphQL */ `subscription OnUpdatePayment {
  onUpdatePayment {
    id
    subscriptionId
    appKey
    reportAt
    usagePrice
    usageQuantity
    type
    provider
    identifier
    packageId
    packageType
    dailyUsagePrice
    aggregatedPrice
    aggregatedUsageQuantity
    dailyUsageQuantity
    seatsPrice
    price
    interval
    numberOfProTeamMembers
    invoiceId
    invoiceItemId
    paymentSubscriptionId
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePaymentSubscriptionVariables,
  APITypes.OnUpdatePaymentSubscription
>;
export const onDeletePayment = /* GraphQL */ `subscription OnDeletePayment {
  onDeletePayment {
    id
    subscriptionId
    appKey
    reportAt
    usagePrice
    usageQuantity
    type
    provider
    identifier
    packageId
    packageType
    dailyUsagePrice
    aggregatedPrice
    aggregatedUsageQuantity
    dailyUsageQuantity
    seatsPrice
    price
    interval
    numberOfProTeamMembers
    invoiceId
    invoiceItemId
    paymentSubscriptionId
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePaymentSubscriptionVariables,
  APITypes.OnDeletePaymentSubscription
>;
export const onCreateQuizResult = /* GraphQL */ `subscription OnCreateQuizResult {
  onCreateQuizResult {
    id
    projectId
    appKey
    owner
    createdAt
    url
    title
    imageUrl
    flows {
      id
      type
      rules {
        stepId
        choices
      }
    }
    isFallback
    klaviyoListId
    stepName
    type
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateQuizResultSubscriptionVariables,
  APITypes.OnCreateQuizResultSubscription
>;
export const onUpdateQuizResult = /* GraphQL */ `subscription OnUpdateQuizResult {
  onUpdateQuizResult {
    id
    projectId
    appKey
    owner
    createdAt
    url
    title
    imageUrl
    flows {
      id
      type
      rules {
        stepId
        choices
      }
    }
    isFallback
    klaviyoListId
    stepName
    type
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateQuizResultSubscriptionVariables,
  APITypes.OnUpdateQuizResultSubscription
>;
export const onDeleteQuizResult = /* GraphQL */ `subscription OnDeleteQuizResult {
  onDeleteQuizResult {
    id
    projectId
    appKey
    owner
    createdAt
    url
    title
    imageUrl
    flows {
      id
      type
      rules {
        stepId
        choices
      }
    }
    isFallback
    klaviyoListId
    stepName
    type
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteQuizResultSubscriptionVariables,
  APITypes.OnDeleteQuizResultSubscription
>;
export const onCreateEcomProduct = /* GraphQL */ `subscription OnCreateEcomProduct {
  onCreateEcomProduct {
    id
    productId
    appKey
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateEcomProductSubscriptionVariables,
  APITypes.OnCreateEcomProductSubscription
>;
export const onUpdateEcomProduct = /* GraphQL */ `subscription OnUpdateEcomProduct {
  onUpdateEcomProduct {
    id
    productId
    appKey
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateEcomProductSubscriptionVariables,
  APITypes.OnUpdateEcomProductSubscription
>;
export const onDeleteEcomProduct = /* GraphQL */ `subscription OnDeleteEcomProduct {
  onDeleteEcomProduct {
    id
    productId
    appKey
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteEcomProductSubscriptionVariables,
  APITypes.OnDeleteEcomProductSubscription
>;
export const onCreateProductPageSettings = /* GraphQL */ `subscription OnCreateProductPageSettings {
  onCreateProductPageSettings {
    id
    productId
    productUrl
    abTestId
    publishId
    appKey
    widgetType
    productSource
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateProductPageSettingsSubscriptionVariables,
  APITypes.OnCreateProductPageSettingsSubscription
>;
export const onUpdateProductPageSettings = /* GraphQL */ `subscription OnUpdateProductPageSettings {
  onUpdateProductPageSettings {
    id
    productId
    productUrl
    abTestId
    publishId
    appKey
    widgetType
    productSource
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateProductPageSettingsSubscriptionVariables,
  APITypes.OnUpdateProductPageSettingsSubscription
>;
export const onDeleteProductPageSettings = /* GraphQL */ `subscription OnDeleteProductPageSettings {
  onDeleteProductPageSettings {
    id
    productId
    productUrl
    abTestId
    publishId
    appKey
    widgetType
    productSource
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteProductPageSettingsSubscriptionVariables,
  APITypes.OnDeleteProductPageSettingsSubscription
>;
export const onCreateAbTest = /* GraphQL */ `subscription OnCreateAbTest {
  onCreateAbTest {
    id
    appKey
    owner
    options {
      publishId
      probability
    }
    endAt
    startAt
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAbTestSubscriptionVariables,
  APITypes.OnCreateAbTestSubscription
>;
export const onUpdateAbTest = /* GraphQL */ `subscription OnUpdateAbTest {
  onUpdateAbTest {
    id
    appKey
    owner
    options {
      publishId
      probability
    }
    endAt
    startAt
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAbTestSubscriptionVariables,
  APITypes.OnUpdateAbTestSubscription
>;
export const onDeleteAbTest = /* GraphQL */ `subscription OnDeleteAbTest {
  onDeleteAbTest {
    id
    appKey
    owner
    options {
      publishId
      probability
    }
    endAt
    startAt
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAbTestSubscriptionVariables,
  APITypes.OnDeleteAbTestSubscription
>;
export const onCreateIntegrationVodAsset = /* GraphQL */ `subscription OnCreateIntegrationVodAsset {
  onCreateIntegrationVodAsset {
    id
    appKey
    owner
    app
    appId
    externalId
    externalCreatedAt
    vodId
    seen
    title
    thumbnailUrl
    downloadUrl
    status
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateIntegrationVodAssetSubscriptionVariables,
  APITypes.OnCreateIntegrationVodAssetSubscription
>;
export const onUpdateIntegrationVodAsset = /* GraphQL */ `subscription OnUpdateIntegrationVodAsset {
  onUpdateIntegrationVodAsset {
    id
    appKey
    owner
    app
    appId
    externalId
    externalCreatedAt
    vodId
    seen
    title
    thumbnailUrl
    downloadUrl
    status
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateIntegrationVodAssetSubscriptionVariables,
  APITypes.OnUpdateIntegrationVodAssetSubscription
>;
export const onDeleteIntegrationVodAsset = /* GraphQL */ `subscription OnDeleteIntegrationVodAsset {
  onDeleteIntegrationVodAsset {
    id
    appKey
    owner
    app
    appId
    externalId
    externalCreatedAt
    vodId
    seen
    title
    thumbnailUrl
    downloadUrl
    status
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteIntegrationVodAssetSubscriptionVariables,
  APITypes.OnDeleteIntegrationVodAssetSubscription
>;
export const onCreateVodConnection = /* GraphQL */ `subscription OnCreateVodConnection {
  onCreateVodConnection {
    id
    appKey
    vodAssetId
    productId
    externalProductId
    collectionId
    provider
    tag
    createdAt
    typeKey
    type
    appUrl
    label
    vodLabelId
    vodSource
    vodType
    variantIds
    projectId
    orderIndex
    source
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateVodConnectionSubscriptionVariables,
  APITypes.OnCreateVodConnectionSubscription
>;
export const onUpdateVodConnection = /* GraphQL */ `subscription OnUpdateVodConnection {
  onUpdateVodConnection {
    id
    appKey
    vodAssetId
    productId
    externalProductId
    collectionId
    provider
    tag
    createdAt
    typeKey
    type
    appUrl
    label
    vodLabelId
    vodSource
    vodType
    variantIds
    projectId
    orderIndex
    source
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateVodConnectionSubscriptionVariables,
  APITypes.OnUpdateVodConnectionSubscription
>;
export const onDeleteVodConnection = /* GraphQL */ `subscription OnDeleteVodConnection {
  onDeleteVodConnection {
    id
    appKey
    vodAssetId
    productId
    externalProductId
    collectionId
    provider
    tag
    createdAt
    typeKey
    type
    appUrl
    label
    vodLabelId
    vodSource
    vodType
    variantIds
    projectId
    orderIndex
    source
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteVodConnectionSubscriptionVariables,
  APITypes.OnDeleteVodConnectionSubscription
>;
export const onCreateCustomer = /* GraphQL */ `subscription OnCreateCustomer {
  onCreateCustomer {
    id
    appKey
    appUrl
    externalId
    googleId
    anonymousIds
    email
    phone
    firstName
    lastName
    acceptsMarketing
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCustomerSubscriptionVariables,
  APITypes.OnCreateCustomerSubscription
>;
export const onUpdateCustomer = /* GraphQL */ `subscription OnUpdateCustomer {
  onUpdateCustomer {
    id
    appKey
    appUrl
    externalId
    googleId
    anonymousIds
    email
    phone
    firstName
    lastName
    acceptsMarketing
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCustomerSubscriptionVariables,
  APITypes.OnUpdateCustomerSubscription
>;
export const onDeleteCustomer = /* GraphQL */ `subscription OnDeleteCustomer {
  onDeleteCustomer {
    id
    appKey
    appUrl
    externalId
    googleId
    anonymousIds
    email
    phone
    firstName
    lastName
    acceptsMarketing
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCustomerSubscriptionVariables,
  APITypes.OnDeleteCustomerSubscription
>;
export const onCreateVodLabel = /* GraphQL */ `subscription OnCreateVodLabel {
  onCreateVodLabel {
    id
    appKey
    name
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateVodLabelSubscriptionVariables,
  APITypes.OnCreateVodLabelSubscription
>;
export const onUpdateVodLabel = /* GraphQL */ `subscription OnUpdateVodLabel {
  onUpdateVodLabel {
    id
    appKey
    name
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateVodLabelSubscriptionVariables,
  APITypes.OnUpdateVodLabelSubscription
>;
export const onDeleteVodLabel = /* GraphQL */ `subscription OnDeleteVodLabel {
  onDeleteVodLabel {
    id
    appKey
    name
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteVodLabelSubscriptionVariables,
  APITypes.OnDeleteVodLabelSubscription
>;
export const onCreateSuggestedProductAcceptance = /* GraphQL */ `subscription OnCreateSuggestedProductAcceptance {
  onCreateSuggestedProductAcceptance {
    id
    appKey
    vodAssetId
    externalProductId
    accepted
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSuggestedProductAcceptanceSubscriptionVariables,
  APITypes.OnCreateSuggestedProductAcceptanceSubscription
>;
export const onUpdateSuggestedProductAcceptance = /* GraphQL */ `subscription OnUpdateSuggestedProductAcceptance {
  onUpdateSuggestedProductAcceptance {
    id
    appKey
    vodAssetId
    externalProductId
    accepted
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSuggestedProductAcceptanceSubscriptionVariables,
  APITypes.OnUpdateSuggestedProductAcceptanceSubscription
>;
export const onDeleteSuggestedProductAcceptance = /* GraphQL */ `subscription OnDeleteSuggestedProductAcceptance {
  onDeleteSuggestedProductAcceptance {
    id
    appKey
    vodAssetId
    externalProductId
    accepted
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSuggestedProductAcceptanceSubscriptionVariables,
  APITypes.OnDeleteSuggestedProductAcceptanceSubscription
>;
export const onCreateCustomerWishList = /* GraphQL */ `subscription OnCreateCustomerWishList {
  onCreateCustomerWishList {
    id
    appKey
    appUrl
    vodAssetId
    externalCustomerId
    email
    externalProductId
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCustomerWishListSubscriptionVariables,
  APITypes.OnCreateCustomerWishListSubscription
>;
export const onUpdateCustomerWishList = /* GraphQL */ `subscription OnUpdateCustomerWishList {
  onUpdateCustomerWishList {
    id
    appKey
    appUrl
    vodAssetId
    externalCustomerId
    email
    externalProductId
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCustomerWishListSubscriptionVariables,
  APITypes.OnUpdateCustomerWishListSubscription
>;
export const onDeleteCustomerWishList = /* GraphQL */ `subscription OnDeleteCustomerWishList {
  onDeleteCustomerWishList {
    id
    appKey
    appUrl
    vodAssetId
    externalCustomerId
    email
    externalProductId
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCustomerWishListSubscriptionVariables,
  APITypes.OnDeleteCustomerWishListSubscription
>;
export const onCreateVodConnectionSuggestion = /* GraphQL */ `subscription OnCreateVodConnectionSuggestion {
  onCreateVodConnectionSuggestion {
    id
    appKey
    appUrl
    createdAt
    vodAssetId
    externalProductId
    status
    source
    method
    methodVersion
    score
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateVodConnectionSuggestionSubscriptionVariables,
  APITypes.OnCreateVodConnectionSuggestionSubscription
>;
export const onUpdateVodConnectionSuggestion = /* GraphQL */ `subscription OnUpdateVodConnectionSuggestion {
  onUpdateVodConnectionSuggestion {
    id
    appKey
    appUrl
    createdAt
    vodAssetId
    externalProductId
    status
    source
    method
    methodVersion
    score
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateVodConnectionSuggestionSubscriptionVariables,
  APITypes.OnUpdateVodConnectionSuggestionSubscription
>;
export const onDeleteVodConnectionSuggestion = /* GraphQL */ `subscription OnDeleteVodConnectionSuggestion {
  onDeleteVodConnectionSuggestion {
    id
    appKey
    appUrl
    createdAt
    vodAssetId
    externalProductId
    status
    source
    method
    methodVersion
    score
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteVodConnectionSuggestionSubscriptionVariables,
  APITypes.OnDeleteVodConnectionSuggestionSubscription
>;
