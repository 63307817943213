import React from 'react';
import styled, { useTheme } from 'styled-components';
import MenuWithItems from 'app/src/complex_components/menu_items/MenuWithItems';
import { MenuDirection } from 'app/src/complex_components/menu_items/types/MenuItem.types';
import { DesignSizes } from 'src/types/design-system';
import useGetCreateVideoMenuItems from 'app/src/hooks/useGetCreateVideoMenuItems';
import { FEATURE_CUSTOM_CREATION_METHODS } from 'shared/react/constants/features.constants';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import { AnchorElementProps } from 'app/src/types/common/common';
import { project as Project, projectStep, vodAsset } from 'app/src/types/entities';

type Props = AnchorElementProps & {
  step?: projectStep;
  project: Project;
  reply?: any;
  changedVideo?: vodAsset;
  direction?: MenuDirection;
};

const AddNewStepMenu = ({
  step,
  project,
  anchorElement,
  setAnchorElement,
  reply,
  changedVideo,
  direction = { vertical: 'bottom', horizontal: 'center' },
}: Props) => {
  const { getSettingsByKey } = useFeatureActions();
  const theme = useTheme();
  const customCreationMethods = getSettingsByKey(FEATURE_CUSTOM_CREATION_METHODS);
  const {
    uploadFileItem,
    tiktokItem,
    recordingsItems,
    instagramItem,
    videoLibraryItem,
    stockVideoItem,
    getCustomItems,
  } = useGetCreateVideoMenuItems({
    extraProps: { shouldCreateProject: true, project, step, reply, changedVideo },
    tiktokText: 'TikTok',
    redirectToVideos: true,
  });

  const getMenuItems = () => {
    const header = { text: 'Change video', color: theme.colors.neutralDarker };
    if (customCreationMethods.methods) {
      return [header, ...getCustomItems(customCreationMethods.methods)];
    }

    return [
      header,
      videoLibraryItem,
      instagramItem,
      tiktokItem,
      { separator: true },
      uploadFileItem,
      ...recordingsItems,
      stockVideoItem,
    ];
  };

  const menuItems = getMenuItems();

  return (
    <StyledMenuWithItems
      anchorElement={anchorElement}
      menuItems={menuItems}
      onClose={() => setAnchorElement(null)}
      size={DesignSizes.NORMAL}
      direction={direction}
    />
  );
};

const StyledMenuWithItems = styled(MenuWithItems)`
  border-radius: 8px;
  padding: 8px;
  width: 236px;
`;

export default AddNewStepMenu;
