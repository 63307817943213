import React from 'react';
import styled from 'styled-components';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { TextSmall, TextSubtitle } from 'shared/react/components/complex/Text';
import publishInstructionsSrc from 'src/images/hero_publish_instructions.png';
import publishInstructions2Src from 'src/images/hero_publish_instructions_2.png';
import Utils from 'src/utils';
import { useSnackBarActions } from 'src/context/ui_store/SnackBarStore';
import HorizontalFlex, {
  Gap8HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import CopyIcon from 'src/images/dashboard_v2/CopyIcon';
import WarningMessage from 'app/src/basic_components/warning-message/WarningMessage';
import useNavigation from 'src/hooks/useNavigation';

const PublishHeroPage = ({ project }) => {
  const { setSnackbar } = useSnackBarActions();
  const { navigateToAccountInstall } = useNavigation();

  const copyPublishId = () => {
    Utils.copyToClipboard(project.publishId);
    setSnackbar('The code copied to your clipboard!');
  };

  const accountQueryString = `?appUrl=${project.appUrl}`;

  return (
    <LayoutRoot>
      <CustomWarningMessage
        message="Please make sure you have completed the account level installation first"
        linkText="Account level installation"
        onLinkClick={() => navigateToAccountInstall(accountQueryString)}
      />
      <TextSubtitle>Place carousel in theme</TextSubtitle>
      <Description>Go to Shopify themes → Add section → Tolstoy Hero</Description>
      <Image src={publishInstructionsSrc} />

      <TextSubtitle>Paste publishID in section</TextSubtitle>
      <PublishIdContainer>
        <PublishIdInput value={project.publishId} disabled />
        <CopyButton onClick={copyPublishId}>
          <CopyIcon />
        </CopyButton>
      </PublishIdContainer>
      <PublishIdImage src={publishInstructions2Src} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)`
  padding: 8px 24px;
`;

const Description = styled(TextSmall)`
  font-weight: 500;
`;

const PublishIdImage = styled.img`
  width: 238px;
  filter: drop-shadow(0px 15px 35px rgba(50, 50, 93, 0.1))
    drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.07));
`;

const CustomWarningMessage = styled(WarningMessage)`
  margin-bottom: 32px;
`;

const Image = styled.img`
  max-height: 300px;
  width: max-content;
`;

const PublishIdContainer = styled(Gap8HorizontalFlex)``;

const PublishIdInput = styled.input`
  padding: 8px 10px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.neutralGray};
  border-radius: 4px;
`;

const CopyButton = styled(HorizontalFlex)`
  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export default PublishHeroPage;
