import React from 'react';

const ViewsIcon = () => {
  return (
    <svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.563.414c.351-.21.796-.21 1.148.024l6.75 4.101c.328.211.539.586.539.961 0 .398-.21.773-.54.96l-6.75 4.126c-.35.21-.796.234-1.147.023C.21 10.422 0 10.047 0 9.625v-8.25C0 .977.21.602.563.415ZM1.5.742a.72.72 0 0 0-.75 0 .711.711 0 0 0-.375.633v8.25c0 .281.14.54.375.656a.72.72 0 0 0 .75 0l6.75-4.125a.748.748 0 0 0 .375-.656.739.739 0 0 0-.375-.633L1.5.742Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default ViewsIcon;
