import React from 'react';
import { usePackageBrandingFeatures } from 'src/hooks/package';
import { getIsTapcartUseCase } from 'src/utils/cases.utils';
import EditorGroup from '../shared/EditorGroup';
import ProductDetailsPriceEditor from './ProductDetailsPriceEditor';
import ProductDetailsCheckoutEditor from './ProductDetailsCheckoutShoppingBagEditor';
import ProductDetailsSalesBadgeEditor from './ProductDetailsSalesBadgeEditor';
import ProductDetailsShippingEditor from './ProductDetailsShippingEditor';
import ProductDetailsStockEditor from './ProductDetailsStockEditor';
import ProductDetailsRecommendedProductsEditor from './ProductDetailsRecommendedProductsEditor';
import ProductDetailsButtonsEditor from './ProductDetailsButtonsEditor';
import ProductDescriptionEditor from './ProductDescriptionEditor';
import { useLookAndFeelContext } from '../../../LookAndFeelContext';

const ProductDetailsEditorGroup = () => {
  const {
    project: { dynamic, useCaseName },
  } = useLookAndFeelContext();
  const isTapcart = getIsTapcartUseCase(useCaseName);
  const features = usePackageBrandingFeatures();
  const { isFeedCheckoutCustomizationEnabled } = features;

  const shoulHideProductDetailsButtonEditor = !dynamic;

  return (
    <EditorGroup
      editors={[
        {
          key: 'price',
          component: <ProductDetailsPriceEditor />,
        },
        {
          key: 'product-buttons',
          component: <ProductDetailsButtonsEditor />,
          hidden: shoulHideProductDetailsButtonEditor || isTapcart,
        },
        {
          key: 'description',
          component: <ProductDescriptionEditor />,
          hidden: !isFeedCheckoutCustomizationEnabled || isTapcart,
        },
        {
          key: 'checkout',
          component: <ProductDetailsCheckoutEditor />,
          hidden: !isFeedCheckoutCustomizationEnabled || isTapcart,
        },
        {
          key: 'sales-badge',
          component: <ProductDetailsSalesBadgeEditor />,
          hidden: isTapcart,
        },
        {
          key: 'shipping',
          component: <ProductDetailsShippingEditor />,
          hidden: isTapcart,
        },
        {
          key: 'stock',
          component: <ProductDetailsStockEditor />,
          hidden: isTapcart,
        },
        {
          key: 'recommended-products',
          component: <ProductDetailsRecommendedProductsEditor />,
          hidden: isTapcart,
        },
      ]}
    />
  );
};

export default ProductDetailsEditorGroup;
