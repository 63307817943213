import React, { useEffect, useState } from 'react';
import { useApps } from 'app/src/context/AppsStore';
import { useQuizContext } from 'app/src/pages/project/pages/quiz/QuizContext';
import BasicSelect from 'shared/react/components/basic/basic_select/BasicSelect';
import styled from 'styled-components';

const CLEAR = 'clear';
const DEFAULT_TEXT = 'Add to Klaviyo list';

const KlaviyoMailingListsMenu = ({ quizResultId, chosenListId = '' }) => {
  const [mailingLists, setMailingLists] = useState([]);
  const [, { fetchKlaviyoMailingLists }] = useApps();
  const { onSetKlaviyoListId } = useQuizContext();
  const [currentListId, setCurrentListId] = useState(chosenListId);
  const [currentListName, setCurrentListName] = useState(DEFAULT_TEXT);

  const handleClear = () => {
    setCurrentListId('');
    setCurrentListName(DEFAULT_TEXT);
    onSetKlaviyoListId(quizResultId, '');
  };

  const handleClick = listItem => {
    const listId = listItem.id;

    if (listId === CLEAR) {
      handleClear();
      return;
    }
    setCurrentListId(listId);
    setCurrentListName(listItem.name);
    onSetKlaviyoListId(quizResultId, listId);
  };

  const fetchKlaviyoLists = async () => {
    try {
      const listsData = await fetchKlaviyoMailingLists();
      if (!listsData.length) {
        console.info('There are no Klaviyo mailing lists for current user');
        return;
      }

      setMailingLists(listsData);
      if (currentListId) {
        const chosenItem = listsData.filter(({ list_id }) => list_id === chosenListId)[0];
        setCurrentListName(chosenItem.list_name);
        return;
      }

      setCurrentListName(DEFAULT_TEXT);
    } catch (e) {
      console.error('Failed to fetch mailing lists on page startup', e);
    }
  };

  useEffect(() => {
    fetchKlaviyoLists();
  }, []);

  const items = mailingLists.map(({ list_id, list_name }) => {
    return { id: list_id, name: list_name };
  });

  items.unshift({ id: CLEAR, name: 'No mailing list' });

  return <StyledSelect items={items} value={currentListName} onChange={handleClick} />;
};

export default KlaviyoMailingListsMenu;

const StyledSelect = styled(BasicSelect)`
  width: 177px;
  justify-content: center;
  & > * {
    border: none;
    font-weight: 500;
  }
`;
