import React, { useState } from 'react';
import styled from 'styled-components';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import BillingPackage from './BillingPackage';
import { usePackages } from 'app/src/context/PackagesStore';
import { useUser } from 'app/src/context/userStore/UserStore';
import { CircularProgress } from '@material-ui/core';
import PaymentRoleModal from '../../pages/modals/PaymentRoleModal';
import { useBilling } from 'app/src/hooks/useBillings';
import { recreateWidget } from '../../helpers/Widget';
import Utils from '../../utils';
import { track } from '../../helpers/Tracker';
import { BILLING_PACKAGE_TYPES } from 'app/src/constants/billings.constants';
import { useHistory } from 'react-router-dom';
import { TOLSTOY_PRICING_ENTERPRISE_ID } from 'app/src/config/app.config';

const getIsActive = (account, packageProperties) => {
  if (!account?.packageId) {
    return packageProperties.type === BILLING_PACKAGE_TYPES.free;
  }
  return account.packageId === packageProperties.id;
};

export const getPackageDetails = ({ id, name, type, details = {} }) => {
  return { id, name, type, ...details };
};

const BillingPackages = () => {
  const [{ account }] = useUser();
  const [{ packages }] = usePackages();
  const { downgradeToFreePackage } = useBilling();
  const [packageId, setPackageId] = useState(null);
  const history = useHistory();

  const details = packages?.map(getPackageDetails);

  const onClick = async (packageId, type) => {
    trackPackageClicked(packageId);

    if (type === BILLING_PACKAGE_TYPES.pro) {
      setPackageId(packageId);
      return;
    }

    if (type === BILLING_PACKAGE_TYPES.free) {
      await downgradeToFreePackage(history);
      return;
    }

    recreateWidget(TOLSTOY_PRICING_ENTERPRISE_ID, {
      loadHidden: false,
      startPlayerImmediately: true,
      email: Utils.getUser().email,
    });
  };

  const trackPackageClicked = packageId => {
    const selectedPackage = packages.find(({ id }) => id === packageId);
    track('Plan Click', {
      packageId: selectedPackage.id,
      packageName: selectedPackage.name,
    });
  };

  return (
    <StyledBillingPackages>
      <PaymentRoleModal onClose={() => setPackageId(null)} packageId={packageId} />
      {details.map(packageProperties => (
        <BillingPackage
          key={packageProperties.type}
          active={getIsActive(account, packageProperties)}
          onClick={onClick}
          {...packageProperties}
        />
      ))}
      {!details.length && <CircularProgress size={24} />}
    </StyledBillingPackages>
  );
};

export default BillingPackages;

const StyledBillingPackages = styled(HorizontalFlexWrap)`
  gap: 24px;
  flex: 1;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    flex-direction: column;
    width: 100%;
  }
`;
