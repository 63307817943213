import { useProjects } from 'app/src/context/ProjectsStore';
import { useAnalyticsProjects } from './useAnalyticsProjects';

const AnalyticsTolstoyStep = ({ value: stepName }) => {
  const [, { getStepByName, getStepDescription }] = useProjects();
  const { selectedProjects } = useAnalyticsProjects();

  const project = selectedProjects?.find(({ project }) => getStepByName(stepName, project));
  const step = getStepByName(stepName, project);

  return getStepDescription(project, step);
};

export default AnalyticsTolstoyStep;
