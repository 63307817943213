import React from 'react';
import { getIsTapcartUseCase } from 'src/utils/cases.utils';
import EditorGroup from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorGroup';
import FeedBehaviourEditor from 'app/src/pages/project/pages/look_and_feel/editor/editors/feed_editor/FeedBehaviourEditor';
import FeedMultiProductLayoutEditor from 'app/src/pages/project/pages/look_and_feel/editor/editors/feed_editor/FeedMultiProductLayoutEditor';
import { useLookAndFeelContext } from '../../../LookAndFeelContext';

const FeedAppearanceEditorGroup = ({ hiddenItems }) => {
  const {
    project: { useCaseName },
  } = useLookAndFeelContext();
  const isTapcart = getIsTapcartUseCase(useCaseName);

  return (
    <EditorGroup
      editors={[
        { key: 'feedBehaviour', component: <FeedBehaviourEditor hiddenItems={hiddenItems} /> },
        {
          key: 'multiProductLayout',
          component: <FeedMultiProductLayoutEditor />,
          hidden: isTapcart,
        },
      ]}
    />
  );
};

export default FeedAppearanceEditorGroup;
