import React from 'react';

const ComposedChartEmptyState = () => {
  return (
    <svg
      width="269"
      height="238"
      viewBox="0 0 364 322"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="251" width="17.6364" height="71" rx="4" fill="#E2EDFE" />
      <rect x="34.6364" y="197" width="17.6364" height="125" rx="4" fill="#E2EDFE" />
      <rect x="69.2727" y="153" width="17.6364" height="169" rx="4" fill="#E2EDFE" />
      <rect x="103.909" y="197" width="17.6364" height="125" rx="4" fill="#E2EDFE" />
      <rect x="138.545" y="104" width="17.6364" height="218" rx="4" fill="#E2EDFE" />
      <rect x="173.182" y="197" width="17.6364" height="125" rx="4" fill="#E2EDFE" />
      <rect x="207.818" y="121" width="17.6364" height="201" rx="4" fill="#E2EDFE" />
      <rect x="242.455" y="197" width="17.6364" height="125" rx="4" fill="#E2EDFE" />
      <rect x="277.091" y="158" width="17.6364" height="164" rx="4" fill="#E2EDFE" />
      <rect x="311.727" y="58" width="17.6364" height="264" rx="4" fill="#E2EDFE" />
      <rect x="346.364" y="28" width="17.6364" height="294" rx="4" fill="#E2EDFE" />
    </svg>
  );
};

export default ComposedChartEmptyState;
