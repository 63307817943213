import React from 'react';
import styled from 'styled-components';
import Button from 'shared/react/components/complex/Button';

const SessionViewNotification = ({ sessionView, onClearFilters }) => {
  if (!sessionView) {
    return null;
  }

  return (
    <StyledSessionViewNotification>
      <Button onClick={onClearFilters}>See all open tickets</Button>
    </StyledSessionViewNotification>
  );
};

export default SessionViewNotification;

const StyledSessionViewNotification = styled.div`
  display: grid;
  place-items: center;
`;
