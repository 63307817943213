import { createStore, createHook, createContainer, createActionsHook } from 'react-sweet-state';
import { graphqlRequest } from '../helpers/API';
import { graphqlOperation } from 'aws-amplify';
import {
  identifyAttributesByAppKey,
  ruleGroupsByAppKey,
  ruleGroupsByProjectId,
} from '../graphql/queries';
import { createRuleGroup, deleteRuleGroup, updateRuleGroup } from '../graphql/mutations';
import {
  DELAY_TRIGGER_TYPE,
  EQUALS_KEY,
  HIDE_VOD_ASSET_TYPE,
  NOT_EQUALS_KEY,
  ORDER_FEED_TYPE,
} from '../constants/ruleGroups.constants';
import Utils from '../utils';
import { nanoid } from 'nanoid';
import { RuleGroupType } from 'app/src/types/entities';
import { RulesService } from 'src/services/rules';

const initialState = {
  rules: null,
  ruleGroups: [],
  customAttributes: null,
  loading: false,
  error: null,
  invalidRules: null,
};

const KEYS_WITH_URL_VALIDATION = [EQUALS_KEY, NOT_EQUALS_KEY];

const actions = {
  clearRuleGroup:
    () =>
    async ({ setState }) => {
      setState({ rules: null });
    },
  clearStore:
    () =>
    ({ setState }) => {
      setState(initialState);
    },
  fetchCustomAttributes:
    appKey =>
    async ({ setState }) => {
      if (!appKey) return;
      try {
        const response = await graphqlRequest(
          graphqlOperation(identifyAttributesByAppKey, { appKey, limit: 200 })
        );

        const customAttributes = response.data.identifyAttributesByAppKey.items;

        setState({ customAttributes, loading: false });
        return customAttributes;
      } catch (error) {
        console.error(error);
        setState({ rules: null, error, loading: false });
        return null;
      }
    },
  fetchRuleGroups:
    () =>
    async ({ setState }, { appKey }) => {
      try {
        const ruleGroups = [];
        let nextToken = null;
        do {
          const response = await graphqlRequest(
            graphqlOperation(ruleGroupsByAppKey, {
              appKey,
              limit: 500,
              nextToken,
            })
          );
          ruleGroups.push(...response.data.ruleGroupsByAppKey.items);
          nextToken = response.data.ruleGroupsByAppKey.nextToken;
        } while (nextToken);

        setState({ ruleGroups });
      } catch (error) {
        Utils.logError('Failed to fetch ruleGroups', error);
      }
    },
  fetchRules:
    (id, type = RuleGroupType.bubble) =>
    async ({ setState, dispatch }) => {
      if (!id) return;
      try {
        setState({ loading: true });
        const response = await graphqlRequest(
          graphqlOperation(ruleGroupsByProjectId, { projectId: id })
        );

        const rule = response.data.ruleGroupsByProjectId.items.find(({ type: ruleType }) => {
          if (!ruleType && type === RuleGroupType.bubble) {
            return true;
          }

          return ruleType === type;
        });

        const ruleGroups = dispatch(actions.getUpdatedRuleGroups(rule));

        setState({ rules: rule, loading: false, ruleGroups });
        return rule;
      } catch (error) {
        console.error(error);
        setState({ rules: null, error, loading: false });
        return { error };
      }
    },
  deleteRuleGroup: input => async () => {
    try {
      await graphqlRequest(graphqlOperation(deleteRuleGroup, { input }));
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  getInvalidRules:
    ruleGroup =>
    ({ setState }) => {
      setState({ invalidRules: null });
      const invalidRules = checkForInvalidRules(ruleGroup);
      if (invalidRules.length) {
        setState({ invalidRules });
        return { invalidRules };
      }

      return null;
    },
  createHideVideoRule: (vodAssetId, productId) => () => {
    const value = getHideVodRuleTypeKey(vodAssetId, productId);

    return {
      key: nanoid(5),
      type: HIDE_VOD_ASSET_TYPE,
      condition: EQUALS_KEY,
      value,
      behaviors: ['visibility'],
    };
  },
  createFeedOrderRule: (vodAssetId, order, productId) => () => {
    const value = getOrderVodRuleTypeKey(vodAssetId, order, productId);

    return {
      key: nanoid(5),
      type: ORDER_FEED_TYPE,
      condition: EQUALS_KEY,
      value,
      behaviors: ['visibility'],
    };
  },
  createRule: rule => () => RulesService.createRule(rule),
  createDefaultDynamicRule: () => () => RulesService.getDefaultDynamicRule(),
  saveRules:
    (ruleGroup, project, type = RuleGroupType.bubble) =>
    async ({ setState, getState, dispatch }, { appKey }) => {
      const { loading, ruleGroups } = getState();
      try {
        if (loading) {
          return;
        }

        setState({ invalidRules: null, loading: true });

        const { id, owner, publishId } = project;

        const invalidRules = checkForInvalidRules(ruleGroup);

        if (invalidRules.length) {
          setState({ invalidRules });
          return { invalidRules };
        }

        const input = {
          projectId: id,
          publishId,
          owner,
          appKey: appKey || project.appKey,
          rules: ruleGroup.rules,
          enabled: ruleGroup.enabled,
          visitorModeEnabled: ruleGroup.visitorModeEnabled,
          exitIntent: ruleGroup.exitIntent,
          delayTriggerEnabled: ruleGroup.delayTriggerEnabled,
          delayTriggerSeconds: ruleGroup.delayTriggerSeconds,
          showOnDevice: ruleGroup.showOnDevice,
          showOnDomains: ruleGroup.showOnDomains,
          type,
        };

        if (ruleGroup.id) {
          input.id = ruleGroup.id;
          const res = await graphqlRequest(graphqlOperation(updateRuleGroup, { input }));
          const updatedRuleGroups = dispatch(
            actions.getUpdatedRuleGroups(res.data.updateRuleGroup)
          );
          setState({
            rules: res.data.updateRuleGroup,
            loading: false,
            ruleGroups: updatedRuleGroups,
          });
          return true;
        } else {
          const res = await graphqlRequest(graphqlOperation(createRuleGroup, { input }));
          setState({
            rules: res.data.createRuleGroup,
            ruleGroups: [...ruleGroups, res.data.createRuleGroup],
          });
          return true;
        }
      } catch (error) {
        console.log(error);
      } finally {
        setState({ loading: false });
      }
    },
  clearInvalidRulesByType:
    ({ type }) =>
    ({ setState, getState }) => {
      const { invalidRules } = getState();

      if (!invalidRules) {
        return;
      }

      const index = invalidRules.findIndex(({ type: ruleType }) => ruleType === type);

      if (index < 0) {
        return;
      }

      const newInvalidRules = [...invalidRules];

      newInvalidRules.splice(index, 1);

      setState({ invalidRules: newInvalidRules });
    },
  clearInvalidRules:
    (andConditionIndex, orConditionIndex) =>
    ({ setState, getState }) => {
      const invalidRules = getState().invalidRules;

      if (!invalidRules) {
        return;
      }

      const index = invalidRules.findIndex(
        invalidRule =>
          invalidRule.andConditionIndex === andConditionIndex &&
          invalidRule.orConditionIndex === orConditionIndex
      );

      if (index < 0) {
        return;
      }

      const newInvalidRules = [...invalidRules];

      newInvalidRules.splice(index, 1);

      setState({ invalidRules: newInvalidRules });
    },
  getUpdatedRuleGroups:
    newRuleGroup =>
    ({ getState }) => {
      const { ruleGroups } = getState();
      return ruleGroups.map(ruleGroup => {
        if (ruleGroup.id === newRuleGroup?.id) {
          return newRuleGroup;
        }

        return ruleGroup;
      });
    },
};

export const getHideVodRuleTypeKey = (videoId, productId) => {
  if (productId) {
    return `${videoId}_${productId}`;
  }

  return videoId;
};

export const getOrderVodRuleTypeKey = (videoId, order = 0, productId) => {
  if (productId) {
    return `${videoId}_${order}_${productId}`;
  }

  return `${videoId}_${order}`;
};

const checkForInvalidRules = ruleGroup => {
  const invalidRules = [];
  ruleGroup.rules?.forEach((andCondition, andConditionIndex) =>
    andCondition.forEach((orCondition, orConditionIndex) => {
      if (orCondition.type === 'url' && KEYS_WITH_URL_VALIDATION.includes(orCondition.condition)) {
        if (!orCondition.value || !Utils.isValidUrl(orCondition.value)) {
          invalidRules.push({
            andConditionIndex,
            orConditionIndex,
          });
        }
      }
    })
  );

  if (checkForInvalidTriggers(ruleGroup)) {
    invalidRules.push({ type: DELAY_TRIGGER_TYPE });
  }

  return invalidRules;
};

const checkForInvalidTriggers = ruleGroup => {
  return ruleGroup.delayTriggerEnabled && !ruleGroup.delayTriggerSeconds;
};

const RulesStore = createStore({ initialState, actions, name: 'Rules' });

const getRuleGroupByProjectId = (state, projectId) => {
  const ruleGroupById = state.ruleGroups.find(ruleGroup => ruleGroup.projectId === projectId);
  return ruleGroupById;
};

const getNotBubbleRuleGroupByProjectId = (state, projectId) => {
  const ruleGroupById = state.ruleGroups.find(
    ruleGroup => ruleGroup.projectId === projectId && ruleGroup.type !== RuleGroupType.bubble
  );
  return ruleGroupById;
};

export const useRuleGroupByProjectId = createHook(RulesStore, {
  selector: getRuleGroupByProjectId,
});

export const useGetNotBubbleRuleGroupByProjectId = createHook(RulesStore, {
  selector: getNotBubbleRuleGroupByProjectId,
});

export const useRules = createHook(RulesStore);

export const useRulesActions = createActionsHook(RulesStore);

export const RulesContainer = createContainer(RulesStore, {
  onInit:
    () =>
    ({ dispatch }) => {
      dispatch(actions.fetchRuleGroups());
    },
  onCleanup:
    () =>
    ({ dispatch }) => {
      dispatch(actions.clearStore());
    },
});
