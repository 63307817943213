import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import StickyTopBar from '../../dashboard/components/top-bar/StickyTopBar';
import TrackingInformation from './TrackingInformation';
import Utils from 'src/utils';
import { useFeatureActions } from 'src/context/FeaturesStore';
import useUpdateAppGoogleAnalyticsId from 'src/hooks/useUpdateAppGoogleAnalyticsId';
import useUpdateDirtyForm from 'src/hooks/useUpdateDirtyForm';

const TrackingPage = () => {
  const { updateAccountFeatureSettings } = useFeatureActions();
  const { updateAppGoogleAnalyticsId } = useUpdateAppGoogleAnalyticsId();

  const [loading, setLoading] = useState(false);
  const [isUnsaved, setIsUnsaved] = useState(false);
  const [appDataToUpdate, setAppDataToUpdate] = useState(null);
  const [accountFeaturesToUpdate, setAccountFeaturesToUpdate] = useState(null);

  const onSave = useCallback(async () => {
    setLoading(true);

    if (accountFeaturesToUpdate) {
      await updateAccountFeatureSettings(accountFeaturesToUpdate);
    }

    if (!Utils.isNullOrUndefined(appDataToUpdate.googleAnalyticsID)) {
      await updateAppGoogleAnalyticsId(appDataToUpdate.googleAnalyticsID);
    }

    setAccountFeaturesToUpdate(null);
    setAppDataToUpdate(null);
    setLoading(false);
    setIsUnsaved(false);
  }, [accountFeaturesToUpdate, appDataToUpdate]);

  useUpdateDirtyForm(isUnsaved, {
    isLoading: loading,
    hideCancel: true,
    onSaveClick: onSave,
  });

  return (
    <LayoutRoot>
      <StickyTopBar leftContent="Tracking" dirtyTitle="Tracking" />
      <Content>
        <TrackingInformation
          loading={loading}
          onUpdate={() => setIsUnsaved(true)}
          accountFeaturesToUpdate={accountFeaturesToUpdate}
          setAccountFeaturesToUpdate={setAccountFeaturesToUpdate}
          appDataToUpdate={appDataToUpdate}
          setAppDataToUpdate={setAppDataToUpdate}
        />
      </Content>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  overflow-y: scroll;
`;

const Content = styled(VerticalFlex)`
  padding: 24px;
`;

export default TrackingPage;
