import React from 'react';
import EditorGroup from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorGroup';
import { PLAYER_BRANCHING_KEY } from '../../../branding.constants';
import { DesignPlayer } from 'app/src/types/design';
import BranchingEditor from './BranchingEditor';

type Props = {
  settings: DesignPlayer;
  features: any;
  defaultSettings: DesignPlayer;
  onChange: (settings: DesignPlayer, options: any) => void;
};

const BranchingEditorGroup = ({
  settings = {},
  features = {},
  defaultSettings = {},
  onChange = () => {},
}: Props) => {
  const updateSettings = (update, options) => onChange({ ...settings, ...update }, options);
  const onBranchingChange = (settings, options) => updateSettings({ branching: settings }, options);

  return (
    <EditorGroup
      editors={[
        {
          key: PLAYER_BRANCHING_KEY,
          component: (
            <BranchingEditor
              settings={settings.branching}
              features={features}
              defaultSettings={defaultSettings.branching}
              onChange={onBranchingChange}
            />
          ),
        },
      ]}
    />
  );
};

export default BranchingEditorGroup;
