import React from 'react';
import styled from 'styled-components';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { getIsTapcartUseCase } from 'src/utils/cases.utils';
import { FEATURE_RECHARGE_INTEGRATION } from 'app/src/constants/appFeatures.constants';
import { useLookAndFeelContext } from '../../../LookAndFeelContext';
import {
  PRODUCT_DETAILS_PRICE_ENABLED_PROPERTY_KEY,
  DEFAULT_PRODUCT_DETAILS_SUBSCRIPTIONS_DESCRIPTION_LEFT,
  DEFAULT_PRODUCT_DETAILS_SUBSCRIPTIONS_DESCRIPTION_RIGHT,
  PRODUCT_DETAILS_SUBSCRIPTIONS_DESCRIPTION_LEFT_PROPERTY_KEY,
  PRODUCT_DETAILS_SUBSCRIPTIONS_DESCRIPTION_RIGHT_PROPERTY_KEY,
  PRODUCT_DETAILS_SUBSCRIPTIONS_ENABLED_PROPERTY_KEY,
  PRODUCT_DETAILS_SUBSCRIPTIONS_HIGHLIGHT_RECURRING_PROPERTY_KEY,
} from '../../../LookAndFeelPage.constants';
import EditorHeader from '../shared/EditorHeader';
import EditorMainContainer from '../shared/EditorMainContainer';
import EditorOnOffToggleItem from '../shared/EditorOnOffToggleItem';
import EditorTextInputItem from '../shared/EditorTextInputItem';
import OnOffToggleItem from '../shared/OnOffToggleItem';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const TOOLTIP_TEXT = 'Hide product price from collapsed and expended products tagged on videos';
const TAPCART_TOOLTIP_TEXT = 'Hide product price if your store uses multi-currency in Tapcart';

const ProductDetailsPriceEditor = () => {
  const {
    project: { useCaseName },
    customizationSettings: {
      playerSettings: {
        productDetailsPriceEnabled,
        productDetailsSubscriptionsEnabled,
        productDetailsSubscriptionsHighlightRecurringEnabled,
        productDetailsSubscriptionsDescriptionLeft,
        productDetailsSubscriptionsDescriptionRight,
      },
    },
    setPlayerSettingsProperty,
  } = useLookAndFeelContext();
  const [, { getFeatureEnabled }] = useFeatures();
  const isTapcart = getIsTapcartUseCase(useCaseName);
  const isRechargeIntegrationFeatureEnabled = getFeatureEnabled(FEATURE_RECHARGE_INTEGRATION);

  const onEnableProductPrice = () => {
    setPlayerSettingsProperty(
      PRODUCT_DETAILS_PRICE_ENABLED_PROPERTY_KEY,
      !productDetailsPriceEnabled
    );
  };

  const onEnableSubscriptions = () => {
    setPlayerSettingsProperty(
      PRODUCT_DETAILS_SUBSCRIPTIONS_ENABLED_PROPERTY_KEY,
      !productDetailsSubscriptionsEnabled
    );
  };

  const onEnableFocusOnRecurringOptions = () => {
    setPlayerSettingsProperty(
      PRODUCT_DETAILS_SUBSCRIPTIONS_HIGHLIGHT_RECURRING_PROPERTY_KEY,
      !productDetailsSubscriptionsHighlightRecurringEnabled
    );
  };

  const onDescriptionLeftChange = (value: string) => {
    setPlayerSettingsProperty(PRODUCT_DETAILS_SUBSCRIPTIONS_DESCRIPTION_LEFT_PROPERTY_KEY, value);
  };

  const onDescriptionRightChange = (value: string) => {
    setPlayerSettingsProperty(PRODUCT_DETAILS_SUBSCRIPTIONS_DESCRIPTION_RIGHT_PROPERTY_KEY, value);
  };

  return (
    <LayoutRoot>
      <EditorHeader
        title="Product price"
        isWhite={!productDetailsPriceEnabled}
        tooltipText={isTapcart ? TAPCART_TOOLTIP_TEXT : TOOLTIP_TEXT}
      >
        <OnOffToggleItem checked={productDetailsPriceEnabled} onChange={onEnableProductPrice} />
      </EditorHeader>
      <EditorMainContainer
        shouldHide={!isRechargeIntegrationFeatureEnabled || !productDetailsPriceEnabled}
      >
        <EditorOnOffToggleItem
          text="Subscriptions"
          checked={productDetailsSubscriptionsEnabled}
          onChange={onEnableSubscriptions}
        />
        {productDetailsSubscriptionsEnabled && (
          <>
            <EditorOnOffToggleItem
              text="Highlight recurring options"
              checked={productDetailsSubscriptionsHighlightRecurringEnabled}
              onChange={onEnableFocusOnRecurringOptions}
            />
            <EditorTextInputItem
              placeholder={DEFAULT_PRODUCT_DETAILS_SUBSCRIPTIONS_DESCRIPTION_LEFT}
              text="Description left"
              value={productDetailsSubscriptionsDescriptionLeft}
              onChange={onDescriptionLeftChange}
            />
            <EditorTextInputItem
              placeholder={DEFAULT_PRODUCT_DETAILS_SUBSCRIPTIONS_DESCRIPTION_RIGHT}
              text="Description right"
              value={productDetailsSubscriptionsDescriptionRight}
              onChange={onDescriptionRightChange}
            />
          </>
        )}
      </EditorMainContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

export default ProductDetailsPriceEditor;
