import React from 'react';
import styled from 'styled-components';
import listItemStatesCss from 'shared/react/components/complex/css/listItemStatesCss';
import { TextSmall } from 'shared/react/components/complex/Text';
import Gap16HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap16HorizontalFlexWrap';

function OptionMenuItem({ children, icon, onClick }) {
  return (
    <LayoutRoot onClick={onClick}>
      {icon}
      <TextSmall>{children}</TextSmall>
    </LayoutRoot>
  );
}

export default OptionMenuItem;

const LayoutRoot = styled(Gap16HorizontalFlexWrap)`
  align-items: center;
  cursor: pointer;
  padding: 8px 16px;

  ${listItemStatesCss}
`;
