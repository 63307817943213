import React, { useMemo } from 'react';
import styled from 'styled-components';
import { getProjectOrderMapping } from 'app/src/context/QuizStore';
import Table from 'app/src/pages/project/pages/quiz/quiz_v1/Table';
import Button from 'shared/react/components/complex/Button';
import { QUIZ_LAYOUT_ROOT_DATA_TEST_ID } from 'app/src/constants/dataTestIds.constants';

const Quiz = ({
  isLoading,
  quiz,
  project,
  setCurrQuiz,
  saveChanges,
  selectedKeys,
  setSelectedKeys,
}) => {
  const onChange = (e, i) => {
    e.preventDefault();
    const copy = [...quiz];
    copy[i][stepsHeadersByOrder.length] = e.target.value;
    setCurrQuiz(copy);
  };

  const stepsHeadersByOrder = useMemo(() => {
    if (!project) {
      return;
    }
    const orderMapping = getProjectOrderMapping(project);
    const stepsOrder = [];
    project.steps.items.forEach(({ name, description }, i) => {
      const index = orderMapping[name];
      stepsOrder[index] = description || `Part ${i + 1}`;
    });
    return stepsOrder;
  }, [project]);

  const names = {};
  project.steps.items.forEach(step => {
    step.answers.forEach(({ key, text }) => {
      names[key] = text;
    });
  });

  return (
    <LayoutRoot data-test-id={QUIZ_LAYOUT_ROOT_DATA_TEST_ID}>
      <Container>
        <Table
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          names={names}
          headers={stepsHeadersByOrder}
          onChange={onChange}
          quiz={quiz}
          loading={isLoading}
        />
      </Container>
      <CustomButton loading={isLoading} onClick={saveChanges}>
        Save
      </CustomButton>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  padding: 16px 24px;
  overflow: auto;
  width: 100%;
  height: 100%;
`;

const Container = styled.div``;

const CustomButton = styled(Button)`
  margin: 10px 0;
`;

export default Quiz;
