export enum SyncTagsMode {
  primary = 'primary',
  replica = 'replica',
}

export enum EntryPointLocation {
  homeFeed = 'HOME_FEED',
  storePage = 'STORE_PAGE',
  productPage = 'PRODUCT_PAGE',
}

export const ENTRY_POINT_LOCATIONS = [
  EntryPointLocation.homeFeed,
  EntryPointLocation.storePage,
  EntryPointLocation.productPage,
];
