import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useFeedProducts from 'shared/react/components/complex/context/hooks/useFeedProducts';
import HorizontalFlex, { Gap8HorizontalFlex } from '../../../flex_layouts/HorizontalFlex';
import { getStarsArray } from './feedProductReviews.utils';
import StarFilled from 'shared/react/images/rating/StarFilled';
import StarHalfFilled from 'shared/react/images/rating/StarHalfFilled';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';
import useFeedState from 'shared/react/components/complex/context/hooks/useFeedState';
import useIsMilk from 'shared/react/hooks/useIsMilk';
import { milkConditionCss } from 'shared/react/components/clientsCss.constants';
import FentyStarFilled from 'shared/react/images/rating/FentyStarFilled';
import FentyStarHalfFilled from 'shared/react/images/rating/FentyStarHalfFilled';
import FentyStarEmpty from 'shared/react/images/rating/FentyStarEmpty';
import useIsFenty from 'shared/react/hooks/useIsFenty';
import useIsPrincessPolly from 'shared/react/hooks/useIsPrincessPolly';
import PPStarFull from 'shared/react/images/rating/PPStarFull';
import PPStarEmpty from 'shared/react/images/rating/PPStarEmpty';
import PPStarHalfFilled from 'shared/react/images/rating/PPStarHalfFilled';

export const starIcons = {
  full: <StarFilled />,
  empty: <StarFilled opacity={0.2} />,
  half: <StarHalfFilled opacity={0.2} />,
};

export const fentyStartIcons = {
  full: <FentyStarFilled />,
  empty: <FentyStarEmpty />,
  half: <FentyStarHalfFilled />,
};

export const princessPollyStarIcon = {
  full: <PPStarFull />,
  empty: <PPStarEmpty />,
  half: <PPStarHalfFilled />,
};

const FeedProductReviews = ({ product, isMultipleProductDetails }) => {
  const [{ yotpoAppKey }] = useFeedState();
  const [, { getProductReviews }] = useFeedProducts();
  const [productReviews, setProductReviews] = useState();
  const [, { t }] = useTranslation('feed.products');
  const isMilk = useIsMilk();
  const isFenty = useIsFenty();
  const isPrincessPolly = useIsPrincessPolly();

  useEffect(() => {
    const fetchReviews = async () => {
      if (!product) {
        return;
      }

      const productReviewsData = await getProductReviews(product.dbProductId);
      setProductReviews(productReviewsData);
    };

    if (yotpoAppKey) {
      fetchReviews();
    }
  }, [product]);

  if (!yotpoAppKey || !productReviews || !productReviews.reviews) {
    return null;
  }

  const { reviews, score } = productReviews;

  const getStarObjects = () => {
    if (isFenty) {
      return fentyStartIcons;
    }

    if (isPrincessPolly) {
      return princessPollyStarIcon;
    }

    return starIcons;
  };

  const starObject = getStarObjects();

  return (
    <LayoutRoot>
      <RatingContainer $isFenty={isFenty} $isPrincessPolly={isPrincessPolly}>
        {getStarsArray(score).map(starType => starObject[starType])}
      </RatingContainer>
      {!isMultipleProductDetails && (
        <ReviewsContainer isMilk={isMilk} $isPrincessPolly={isPrincessPolly}>
          {reviews} {reviews === 1 ? t('review') : t('reviews')}
        </ReviewsContainer>
      )}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlex)`
  flex-wrap: nowrap;
  flex-shrink: 0;
  align-items: center;
`;

const RatingContainer = styled(HorizontalFlex)`
  flex-shrink: 0;
  gap: ${({ $isFenty }) => ($isFenty ? '5px' : '')};

  svg {
    max-width: 18px;
    max-height: 18px;
    width: ${({ $isPrincessPolly }) => ($isPrincessPolly ? '16px' : '')};
    height: ${({ $isPrincessPolly }) => ($isPrincessPolly ? '16px' : '')};
  }
`;

const ReviewsContainer = styled.div`
  font-size: ${({ $isPrincessPolly }) => ($isPrincessPolly ? '13px' : '16px')};
  padding-top: ${({ $isPrincessPolly }) => ($isPrincessPolly ? '2px' : '')};
  height: ${({ $isPrincessPolly }) => ($isPrincessPolly ? '14px' : '')};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: underline;
  ${milkConditionCss};
`;

export default FeedProductReviews;
