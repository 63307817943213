import * as Sentry from '@sentry/react';
import {
  HUBSPOT,
  INTERCOM,
  OUTREACH,
  SALESLOFT,
  TIKTOK,
  WIX,
  YOTPO,
} from 'app/src/constants/intergrations.constants';
import { logLogRocketMessage } from 'app/src/utils/logRocket.utils';
import { merge as lodashMergeObject } from 'lodash';
import Papa from 'papaparse';
import { LIQUID_FILENAMES } from 'shared/react/constants/tolstoy.constants';
import { CURRENCY_SYMBOL_MAP } from 'shared/react/constants/currency.constants';
import {
  APP_KEY_LOCAL_STORAGE_KEY,
  APP_SETTINGS_LOCAL_STORAGE_KEY,
  CUBE_JS_KEY,
  GHOST_ACCOUNT_LOCAL_STORAGE_KEY,
  GHOST_ACCOUNT_QUERY_PARAM,
  INVITE_SESSION_STORAGE_KEY,
  RENEW_SUBSCRIPTION_DATE_LOCAL_STORAGE_KEY,
  REQUEST_TO_UPGRADE_PLAN_DATE_LOCAL_STORAGE_KEY,
  STUDIO_VIDEO_GENERATION_PATH,
  TOLSTOY_GROUP_LOCAL_STORAGE_KEY,
} from './constants/tolstoy.constants';
import { CUSTOM_LOGIN_LOADING_SESSION_STORAGE_KEY } from './constants/ui.constants';
import { ONSITE_DEFAULT_PROJECT_TYPES } from 'src/constants/filters';
import APP_CONFIG, { STUDIO_DOMAIN } from 'app/src/config/app.config';
import { cleanStoreName } from 'app/src/utils/shopify.utils';
import { ONSITE_TARGET_PAGES } from 'app/src/pages/dashboard/constants/onsiteTargetPages.constants';

type FormattedNumberOptions = {
  currency?: string;
  digits?: number;
  formatted?: boolean;
  isPercentage?: boolean;
  letter?: boolean;
  value: number | string;
  defaultValue?: string;
};

class Utils {
  static getFontUrl = Utils.getLogoUrl;

  static getFacebookShareUrl(url) {
    return `https://www.facebook.com/sharer/sharer.php?u=${url}`;
  }

  static getTwitterShareUrl(url) {
    return `https://twitter.com/share?url=${url}`;
  }

  static getLinkedInShareUrl(url) {
    return `https://www.linkedin.com/shareArticle?mini=true&url=${url}`;
  }

  static getWhatsappShareUrl(url) {
    return `https://wa.me/?text=${url}`;
  }

  static getRandomProperty(object) {
    const keys = Object.keys(object);
    return object[keys[(keys.length * Math.random()) << 0]];
  }

  static getAssetsUrl() {
    return `https://${APP_CONFIG.ASSETS_OUTPUT}/`;
  }

  static getLogoUrl(logo) {
    if (!logo) {
      return null;
    }

    return `https://${APP_CONFIG.ASSETS_OUTPUT}/public/${logo}`;
  }

  static getImageUrl(logo) {
    if (!logo) {
      return null;
    }

    return `https://${APP_CONFIG.IMAGE_OUTPUT}/${logo}`;
  }

  static safeParse(obj) {
    return JSON.parse(obj || '{}');
  }

  static getValueOfPercentage(total, percentage) {
    return (total / 100) * percentage;
  }

  static getPercentage(value, total, infinite = false, digits = 0) {
    let percentageValue = 0;
    if (value && total) {
      percentageValue = (value / total) * 100;
    }

    const percentage = this.safeRoundNumber(percentageValue, digits);
    if (infinite) {
      return percentage;
    }

    const maxValue = 100;
    return percentage > maxValue ? maxValue : percentage;
  }

  static isNullOrUndefined(value) {
    return value === null || value === undefined;
  }

  static getScrollbarWidth() {
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll';
    outer.style.msOverflowStyle = 'scrollbar';
    document.body.appendChild(outer);

    const inner = document.createElement('div');
    outer.appendChild(inner);

    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    outer.parentNode.removeChild(outer);

    return scrollbarWidth;
  }

  static isSafari() {
    return window.safari !== undefined;
  }

  static isValidUrl(url) {
    if (!url) {
      return false;
    }

    const pattern = new RegExp(
      '^((([A-Za-z]{3,9}:(?:\\/\\/)?)(?:[\\-;:&=\\+\\$,\\w]+@)?[A-Za-z0-9\\.\\-]+|(?:www\\.|[\\-;:&=\\+\\$,\\w]+@)[A-Za-z0-9\\.\\-]+)((?:\\/[\\+~%\\/\\.\\w\\-_]*)?\\??(?:[\\-\\+=&;%@\\.\\w_]*)#?(?:[\\.\\!\\/\\\\\\w]*))?)',
      'i'
    ); // fragment locator
    return (
      !!pattern.test(url) ||
      url.startsWith('tel:') ||
      url.startsWith('mailto:') ||
      url.startsWith('_self:')
    );
  }

  static removeHtmlTags(text) {
    return text.replace(/<\/?[^>]+(>|$)/g, '');
  }

  static sizeInBytesToMb(sizeInBytes) {
    return (sizeInBytes / (1024 * 1024)).toFixed(2);
  }

  static lowercaseAndReplaceSpacesWithUnderscore(text) {
    return text.replace(/\s+/g, '-').toLowerCase();
  }

  static copyToClipboardIos(text) {
    let textarea;
    let result;

    try {
      textarea = document.createElement('textarea');
      textarea.setAttribute('readonly', true);
      textarea.setAttribute('contenteditable', true);
      textarea.style.position = 'fixed';
      textarea.value = text;

      document.body.appendChild(textarea);

      textarea.focus();
      textarea.select();

      const range = document.createRange();
      range.selectNodeContents(textarea);

      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);

      textarea.setSelectionRange(0, textarea.value.length);
      result = document.execCommand('copy');
    } catch (err) {
      console.error(err);
      result = null;
    } finally {
      document.body.removeChild(textarea);
    }

    return result;
  }

  static copyHtmlToClipboard(text) {
    if (this.isIos()) {
      return this.copyToClipboardIos(text);
    } else if (this.isSafari()) {
      return this.copyToClipboard(text);
    }

    function listener(e) {
      e.clipboardData.setData('text/html', text);
      e.clipboardData.setData('text/plain', text);
      e.preventDefault();
    }

    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
  }

  static copyToClipboard(text) {
    navigator.clipboard.writeText(text);
  }

  static async hasPermission(permissionName) {
    try {
      const result = await navigator.permissions.query({ name: permissionName });
      return result?.state === 'granted';
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  static addZeroToNumberIfNeeded(number, addAlways) {
    if (number === 0 && !addAlways) {
      return '0';
    }

    return ('0' + number).slice(-2);
  }

  static upperCaseFirstChar(text = '') {
    if (!text?.length) {
      return '';
    }

    return `${text[0]?.toUpperCase()}${text?.slice(1)}`;
  }

  static isJsonString(string) {
    if (!string) {
      return false;
    }

    try {
      JSON.parse(string);
    } catch (e) {
      return false;
    }

    return true;
  }

  static isDateToday(timestamp) {
    const date = new Date(timestamp);
    const today = new Date();
    return (
      date.getDate() == today.getDate() &&
      date.getMonth() == today.getMonth() &&
      date.getFullYear() == today.getFullYear()
    );
  }

  static getFormattedDate(date) {
    if (!date) {
      return '';
    }

    const dateObject = new Date(date);
    return `${dateObject.getDate()} ${Utils.getShortMonthName(
      dateObject
    )} ${dateObject.getFullYear()}`;
  }

  static getDayDiff(date1, date2) {
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  static formatDateMinutes(date) {
    return ('0' + date.getMinutes()).slice(-2);
  }

  static getIsCurrentYear(date) {
    return date?.getFullYear() === new Date().getFullYear();
  }

  static getShortMonthName(date) {
    return date?.toLocaleString('en-US', { month: 'short' });
  }

  static getLink(url) {
    if (!url.match(/^https?:\/\//i)) {
      return 'https://' + url;
    }

    return url;
  }

  static openInNewTab(url) {
    if (!url) {
      return;
    }

    url = this.getLink(url);
    const win = window.open(url, '_blank');

    if (!win) {
      this.openInTheSameTab(url);
      return;
    }

    win.focus();
  }

  static openInTheSameTab(url) {
    window.location.replace(this.getLink(url));
  }

  static isAndroid() {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('android') > -1; //&& ua.indexOf("mobile");
  }

  static logError(message, exception) {
    if (message) {
      Sentry.captureException(exception, {
        tags: {
          message: message,
        },
      });
    } else {
      Sentry.captureException(exception);
    }
  }

  static logErrorMessage(message) {
    Sentry.captureMessage(message);
  }

  static enrichErrorMessage(error, message) {
    if (error.constructor) {
      return new error.constructor(`${message} - ${error.message}`);
    }

    return error;
  }

  static isIos() {
    return (
      /iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    );
  }

  static aOrAn(value) {
    if ('aeiou'.includes(value)) {
      return 'an';
    }

    return 'a';
  }

  static getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }

    if (
      navigator.userAgent.match(/Mac/) &&
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2
    ) {
      return 'ipad';
    }

    return null;
  }

  static isMobile() {
    return Utils.getMobileOperatingSystem() != null;
  }

  static moveToIntegration(integrationKey, email, token, userId, appKey) {
    const keys = [
      { key: HUBSPOT, url: `${APP_CONFIG.PUBLISH_HUBSPOT_URL}?email=${email}` },
      { key: INTERCOM, url: `${APP_CONFIG.INSTALL_INTERCOM_URL}?email=${email}` },
      { key: WIX, url: `${APP_CONFIG.INSTALL_WIX_URL}?email=${email}&token=${token}` },
      {
        key: OUTREACH,
        url: `${APP_CONFIG.INSTALL_OUTREACH_URL}?userId=${userId}&appKey=${appKey}`,
      },
      {
        key: SALESLOFT,
        url: `${APP_CONFIG.INSTALL_SALESLOFT_URL}?userId=${userId}&appKey=${appKey}`,
      },
      {
        key: TIKTOK,
        url: `${APP_CONFIG.INSTALL_TIKTOK_URL}?userId=${userId}&appKey=${appKey}`,
      },
      { key: YOTPO, url: `${APP_CONFIG.INSTALL_YOTPO_URL}?appKey=${appKey}` },
    ];

    const redirectTo = keys.find(integration => {
      return integrationKey === integration.key;
    });

    if (redirectTo) {
      window.open(redirectTo.url, '_self');
    }
  }

  static getVisibilityEventNames() {
    let hidden, visibilityChange;
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }

    return {
      hidden,
      visibilityChange,
    };
  }

  static loadScript(src) {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = src;
    const x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
  }

  static downloadAndParseCsv(csvUrl) {
    return new Promise(resolve => {
      Papa.parse(csvUrl, {
        download: true,
        complete: results => {
          resolve(results.data);
        },
        error: err => {
          console.error(err);
          resolve(err);
        },
      });
    });
  }

  static isQuiz(tolstoyType, version) {
    if (!tolstoyType?.includes('quiz')) {
      return false;
    }

    if (version === 2) {
      return tolstoyType?.includes('quiz_v2');
    }

    if (version === 1) {
      return tolstoyType?.includes('quiz') && !tolstoyType?.includes('quiz_v2');
    }

    return true;
  }

  static getCookieValue = name => {
    return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';
  };

  static hasIntegration = (apps, integrationName) => {
    return (apps?.data || []).find(({ app }) => app === integrationName);
  };

  static isGhostAccount = () => {
    return (
      location?.href?.includes(`${GHOST_ACCOUNT_QUERY_PARAM}=true`) ||
      localStorage?.getItem?.(GHOST_ACCOUNT_LOCAL_STORAGE_KEY) === 'true'
    );
  };

  static isEmail = email => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  static getUser = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    return user;
  };

  static getOwner = () => {
    const user = this.getUser();
    return user?.id;
  };

  static getAppKey = () => {
    return localStorage.getItem(APP_KEY_LOCAL_STORAGE_KEY);
  };

  static getCubeJsKey = () => {
    return localStorage.getItem(CUBE_JS_KEY);
  };

  static getIntegrationRoute = ({
    path,
    integration,
    integrationToken,
    integrationReturnTo,
    shopifyShop,
    urlParams,
  }) => {
    let integrationParam;
    if (urlParams) {
      integrationParam = integration ? `${urlParams}&integration=${integration}` : urlParams;
    } else {
      integrationParam = integration ? `?integration=${integration}` : '';
    }

    const tokenParam = integrationToken ? `&token=${integrationToken}` : '';
    const integrationReturnToParam = integrationReturnTo ? `&return_to=${integrationReturnTo}` : '';
    const shopifyShopParam = shopifyShop ? `&shopifyShop=${shopifyShop}` : '';
    return `${path}${integrationParam}${tokenParam}${integrationReturnToParam}${shopifyShopParam}`;
  };

  static capitalizeFirstLetter = string => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  };

  static setInviteDetails = () => {
    const query = new URLSearchParams(window.location.search);
    const inviteToken = query?.get('inviteToken');
    const account = query?.get('account');
    const accountName = query?.get('accountName');
    const appKey = query?.get('appKey');

    if (inviteToken && account && accountName) {
      logLogRocketMessage('setting invite details to session storage');
      sessionStorage.setItem(
        INVITE_SESSION_STORAGE_KEY,
        JSON.stringify({
          inviteToken,
          account,
          accountName,
          appKey,
        })
      );
    }
  };

  static setTolstoyGroup = () => {
    const query = new URLSearchParams(window.location.search);
    const tolstoyGroup = query?.get('tolstoyGroup');

    if (tolstoyGroup) {
      logLogRocketMessage('setting tolstoy group to local storage');
      localStorage.setItem(TOLSTOY_GROUP_LOCAL_STORAGE_KEY, tolstoyGroup);
    }
  };

  static clearTolstoyGroupIfNeeded = () => {
    if (location?.href?.includes?.('tolstoyGroup') || location?.href?.includes?.('signup')) {
      return;
    }

    logLogRocketMessage('removing tolstoy group from local storage');
    localStorage.removeItem(TOLSTOY_GROUP_LOCAL_STORAGE_KEY);
  };

  static getTolstoyGroup = () => {
    return localStorage.getItem(TOLSTOY_GROUP_LOCAL_STORAGE_KEY);
  };

  static getRequestToUpgradePlanDate = () => {
    return localStorage.getItem(REQUEST_TO_UPGRADE_PLAN_DATE_LOCAL_STORAGE_KEY);
  };

  static setRequestToUpgradePlanDate = () => {
    logLogRocketMessage('setting request to upgrade plan date to local storage');
    return localStorage.setItem(
      REQUEST_TO_UPGRADE_PLAN_DATE_LOCAL_STORAGE_KEY,
      new Date().toISOString()
    );
  };

  static getRenewSubscriptionDate = () => {
    return localStorage.getItem(RENEW_SUBSCRIPTION_DATE_LOCAL_STORAGE_KEY);
  };

  static setRenewSubscriptionDate = () => {
    logLogRocketMessage('setting renew subscription date to local storage');
    return localStorage.setItem(
      RENEW_SUBSCRIPTION_DATE_LOCAL_STORAGE_KEY,
      new Date().toISOString()
    );
  };

  static clearInviteIfNeeded = () => {
    if (location?.href?.includes?.('inviteToken')) {
      return;
    }

    this.clearInvite();
  };

  static clearInvite = () => {
    logLogRocketMessage('removing invite from session storage');
    sessionStorage.removeItem(INVITE_SESSION_STORAGE_KEY);
  };

  static getInvite = () => {
    return JSON.parse(sessionStorage.getItem(INVITE_SESSION_STORAGE_KEY));
  };

  static clearQueryStrings = () => {
    history.pushState(null, '', location.href.split('?')[0]);
  };

  static addCommasToNumber = (number, digits = 0) => {
    if ((!number && number !== 0) || Number.isNaN(+number)) {
      return undefined;
    }

    return this.safeRoundNumber(number, digits)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  static safeRoundNumber = (number, digits = 1) => {
    if (isNaN(+number)) {
      return 0;
    }

    if (Number.isInteger(+number)) {
      return +number;
    }

    return parseFloat((+number).toFixed(digits));
  };

  static formatNumberToString = (number, threshold, digits = 0, letter = true) => {
    if ((!number || Number.isNaN(+number)) && number !== 0) {
      return undefined;
    }

    if (!letter || (threshold && +number < threshold)) {
      return this.addCommasToNumber(number, digits);
    }

    if (number >= 1000000) {
      return this.safeRoundNumber(number / 1000000, digits) + 'm';
    }

    if (number >= 1000) {
      return this.safeRoundNumber(number / 1000, digits) + 'k';
    }

    return this.safeRoundNumber(number, digits);
  };

  static addCurrencySymbolToNumber = (number, currency, digits = 0, letter = true) => {
    if (!number || Number.isNaN(+number)) {
      return number;
    }

    const currencySymbol = CURRENCY_SYMBOL_MAP[currency] || '$';
    const formattedNumber = this.formatNumberToString(number, false, digits, letter);
    return `${currencySymbol}${formattedNumber}`;
  };

  static getAppSettings = () => {
    if (this.isGhostAccount()) {
      return;
    }

    return JSON.parse(localStorage.getItem(APP_SETTINGS_LOCAL_STORAGE_KEY));
  };

  static getAppSetting = key => {
    if (this.isGhostAccount()) {
      return;
    }

    const appSettings = this.getAppSettings();
    return appSettings?.[key];
  };

  static setAppSetting = (key, value) => {
    if (this.isGhostAccount()) {
      return {};
    }

    const currentAppSettings = this.getAppSettings();
    logLogRocketMessage('setting app setting to local storage');
    localStorage.setItem(
      APP_SETTINGS_LOCAL_STORAGE_KEY,
      JSON.stringify({
        ...currentAppSettings,
        [key]: value,
      })
    );
  };

  static getRootDomain = domain => {
    return domain?.split('.')?.slice(-2)?.join('.');
  };

  // static setCookie = ({ name, value, maxAge }) => {
  //   const rootDomain = this.getRootDomain(window.location.hostname);

  //   const domain = rootDomain === 'localhost' ? '' : `domain=.${rootDomain}; `;
  //   const expires = maxAge ? `max-age=${maxAge};` : '';
  //   const secureFlag = 'Secure; ';
  //   const sameSite = 'SameSite=None; ';

  //   const cookie = `${name}=${encodeURIComponent(
  //     value
  //   )}; path=/; ${expires}${domain}${secureFlag}${sameSite}`;

  //   document.cookie = cookie;
  // };

  // static clearCookie = name => {
  // document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
  // }

  static setCookie = ({ name, value, maxAge }) => {
    const rootDomain = this.getRootDomain(window.location.hostname);

    const domain = rootDomain === 'localhost' ? '' : `domain=.${rootDomain}; `;
    const expires = maxAge ? `max-age=${maxAge};` : '';
    const secureFlag = 'Secure; ';
    const sameSite = 'SameSite=None; ';

    const cookie = `${name}=${encodeURIComponent(
      value
    )}; path=/; ${expires}${domain}${secureFlag}${sameSite}`;

    document.cookie = cookie;
  };

  static saveAccessTokenInCookie = ({ accessToken, accessTokenExpireAt }) => {
    const maxAge = accessTokenExpireAt - Math.floor(Date.now() / 1000);

    const name = 'tolstoyAccessToken';
    const value = accessToken;

    this.setCookie({ name, value, maxAge });
  };

  static copySet = setToCopy => {
    return new Set([...setToCopy]);
  };

  static getAnalyticsLocationObject = location => {
    return { location };
  };

  static setCustomLoginLoading = () => {
    sessionStorage.setItem(CUSTOM_LOGIN_LOADING_SESSION_STORAGE_KEY, 'true');
  };

  static getCustomLoginLoading = () => {
    const loading = sessionStorage.getItem(CUSTOM_LOGIN_LOADING_SESSION_STORAGE_KEY);
    Utils.clearCustomLoginLoading();

    if (loading) {
      return true;
    }

    return false;
  };

  static clearCustomLoginLoading = () => {
    sessionStorage.removeItem(CUSTOM_LOGIN_LOADING_SESSION_STORAGE_KEY);
  };

  static getUserName(firstName, lastName, email) {
    if (!firstName && !lastName && !email) {
      return '';
    }

    if (!firstName && !lastName) {
      return email.split('@')[0];
    }

    if (!firstName) {
      return lastName;
    }

    return `${firstName || ''} ${lastName || ''}`;
  }

  static getIsDev() {
    return APP_CONFIG.NODE_ENV !== 'production';
  }

  static splitArrayIntoChunks = ({ array, chunkSize }) => {
    const splitArr = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      splitArr.push(chunk);
    }

    return splitArr;
  };

  static formatNumber = ({
    value,
    currency,
    formatted,
    digits = 0,
    isPercentage,
    defaultValue,
    letter = true,
  }: FormattedNumberOptions) => {
    if (!value && defaultValue) {
      return defaultValue;
    }

    if (formatted) {
      return value;
    }

    if (isPercentage) {
      return `${value}%`;
    }

    if (currency) {
      return this.addCurrencySymbolToNumber(value, currency, digits, letter);
    }

    return this.formatNumberToString(value, false, digits, letter);
  };

  static isDashboardV2 = () => {
    return window.location.pathname.includes('dashboard-v2');
  };

  static getFormattedStoreName = appUrl => {
    if (!appUrl) {
      return '';
    }

    const modifiedUrl = cleanStoreName(appUrl) || appUrl;
    const splitStoreName = modifiedUrl?.replace(/[-_]/g, ' ').split(' ') || [];
    return splitStoreName.map(word => this.capitalizeFirstLetter(word)).join(' ');
  };

  static getAppEmbedDeepLink = (shopifyDomain, liquidBlockFilename) => {
    if (!shopifyDomain || !liquidBlockFilename) {
      return '';
    }

    const themeAppExtensionId = APP_CONFIG.SHOPIFY_APP_BLOCK_EXTENSION_UUID;

    return `https://${shopifyDomain}/admin/themes/current/editor?context=apps&activateAppId=${themeAppExtensionId}/${liquidBlockFilename}`;
  };

  static getThemesDeepLink = shopifyDomain => {
    return `https://${shopifyDomain}/admin/themes/current/editor`;
  };

  static getAddTolstoyBlockDeepLink = project => {
    const { appUrl, publishMethod, targetPage } = project;

    const getTemplate = () => {
      switch (targetPage) {
        case ONSITE_TARGET_PAGES.ProductPages:
          return 'product';
        case ONSITE_TARGET_PAGES.Collections:
          return 'collection';
        default:
          return 'index';
      }
    };

    const themeAppExtensionId = APP_CONFIG.SHOPIFY_APP_BLOCK_EXTENSION_UUID;
    const publishMethod2FileName = {
      carousel: 'carousel-block',
      stories: 'stories-block',
      hero: 'hero-video-block',
      tvChannel: 'video-page',
      videoPage: 'video-page',
      tile: 'tile-block',
    };
    const blockFileName = publishMethod2FileName[publishMethod];

    const searchParams = new URLSearchParams({
      template: getTemplate(),
      addAppBlockId: `${themeAppExtensionId}/${blockFileName}`,

      /**
       * We can't know for sure for each theme the section id. Most themes have that section called 'main'. So, this is our best guess.
       * @link https://shopify.dev/docs/apps/build/online-store/theme-app-extensions/configuration#recommendations:~:text=link%20to%20it.-,Adding%20an%20app%20block%20to%20the%20main%20section,-The%20main%20section
       */
      target: 'mainSection',
    });
    const url = new URL(`/admin/themes/current/editor`, `https://${appUrl}`);
    url.search = searchParams.toString();
    return url.toString();
  };

  static isOnsite = project => {
    const isOnsite = ONSITE_DEFAULT_PROJECT_TYPES.includes(project?.publishMethod);

    return isOnsite || window.location.pathname.includes('onsite');
  };

  static handleOpenDeepLink = appUrl => {
    const liquidFilename = LIQUID_FILENAMES.widgetBlock;
    const shopifyDomain = appUrl;
    const deepLink = Utils.getAppEmbedDeepLink(shopifyDomain, liquidFilename);

    Utils.openInNewTab(deepLink);
  };

  static mergeObjects = (baseObject, overwritingObject) => {
    return lodashMergeObject(baseObject, overwritingObject);
  };

  static mergeListOfObjects = listOfObjects => {
    return listOfObjects.reduce((acc, obj) => {
      return Utils.mergeObjects(acc, obj);
    }, {});
  };

  static formatPluralText = (text, count) => {
    return +count === 1 ? text : `${text}s`;
  };

  static sumBy = <T>(
    collection: T[],
    valueGetter: (item: T) => number | undefined | null
  ): number => {
    if (!collection) {
      return 0;
    }

    return collection.reduce((acc, item) => {
      const value = valueGetter(item) || 0;
      return acc + value;
    }, 0);
  };

  static meanBy = <T>(
    collection: T[],
    valueGetter: (item: T) => number | undefined | null
  ): number => {
    if (!collection) {
      return 0;
    }

    return Utils.sumBy(collection, valueGetter) / collection.length;
  };

  static navigateToStudio(productId?: string) {
    const isGhostAccount = Utils.isGhostAccount();

    const studioUrl = new URL(STUDIO_DOMAIN + STUDIO_VIDEO_GENERATION_PATH);

    if (productId) {
      studioUrl.searchParams.append('productId', productId);
    }

    if (isGhostAccount) {
      studioUrl.searchParams.append(GHOST_ACCOUNT_QUERY_PARAM, 'true');
    }

    window.open(studioUrl.toString(), '_blank');
  }
}

export default Utils;
