import React, { useState } from 'react';
import styled from 'styled-components';
import useTracker from 'shared/react/components/complex/context/hooks/useTracker';
import Gap8VerticalFlex from '../../flex_layouts/Gap8VerticalFlex';
import ReportForm from './ReportForm';
import ReportConfirmation from './ReportConfirmation';

const FORM_STEPS = {
  report: 'report',
  confirmation: 'confirmation',
};

const ReportWrapper = ({ onClose, videoId }) => {
  const [, { track }] = useTracker();
  const [formStep, setFormStep] = useState(FORM_STEPS.report);

  const handleModalClosed = () => {
    track('ReportWrapperClosed');
    onClose?.();
    setFormStep(FORM_STEPS.report);
  };

  const handleFormSubmit = data => {
    const { key, description } = data;

    track('feedReportSubmit', {
      videoId,
      contentReport: { key, description },
      formData: JSON.stringify({ key, description }),
    });

    setFormStep(FORM_STEPS.confirmation);
  };

  return (
    <LayoutRoot>
      <ReportStep
        formStep={formStep}
        handleModalClosed={handleModalClosed}
        handleFormSubmit={handleFormSubmit}
      />
    </LayoutRoot>
  );
};

const ReportStep = ({ formStep, handleModalClosed, handleFormSubmit }) => {
  switch (formStep) {
    case FORM_STEPS.report:
      return <ReportForm onCancelForm={handleModalClosed} onFormSubmit={handleFormSubmit} />;
    case FORM_STEPS.confirmation:
      return <ReportConfirmation onCloseReport={handleModalClosed} />;
    default:
      return null;
  }
};

const LayoutRoot = styled(Gap8VerticalFlex)`
  height: 100%;
`;

export default ReportWrapper;
