import React from 'react';

function HIcon({ fill, darkBackground, size = 20 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={12} cy={12} r={12} fill={fill || '#FFFFFF'} />
      <path
        d="M13.514 15.5h1.529V7.47h-1.53v3.278H10.49V7.47H8.96v8.03h1.53v-3.289h3.024V15.5Z"
        fill={darkBackground ? '#FFFFFF' : '#2F3137'}
      />
    </svg>
  );
}

export default HIcon;
