import Utils from 'src/utils';
import { uploadToS3 } from 'src/helpers/S3Upload';
import APP_CONFIG from 'app/src/config/app.config';
import { S3UploadAssetType } from 'src/constants/assets.constants';

export const baseUrl = ({ owner }: { owner?: string }) =>
  `${APP_CONFIG.VIDEO_OUTPUT}/public/${owner}`;

export type UploadNewImageOptions = { owner?: string };

export const uploadNewImage = async (
  assetId: string,
  file: File,
  options: UploadNewImageOptions = {}
) => {
  const ext = file.name.split('.').pop();
  const { owner = Utils.getOwner() } = options;
  const path = `public/${owner}/${assetId}.${ext}`;

  return uploadToS3(S3UploadAssetType.Image, path, file);
};

export default {
  uploadNewImage,
};
