import { useLocation } from 'react-router-dom';
import {
  CONVERSION_KEY,
  EMAIL_FLOWS_KEY,
  ENGAGEMENT_KEY,
  FILTER_TYPE_CHOICES,
  ONSITE_KEY,
  OVERVIEW_KEY,
  SHOP_KEY,
  VIDEOS_KEY,
} from 'app/src/constants/analytics.constants';

export type AnalyticsSearchParams = {
  publishIds?: string[] | null;
  tab?: string | null;
};

const PROJECT_ID_PARAM = 'publishIds';
const TAB_PARAM = 'tab';

const AVAILABLE_TABS = [
  CONVERSION_KEY,
  EMAIL_FLOWS_KEY,
  ENGAGEMENT_KEY,
  FILTER_TYPE_CHOICES,
  ONSITE_KEY,
  OVERVIEW_KEY,
  SHOP_KEY,
  VIDEOS_KEY,
];

const SEARCH_PARAMS_SERIALIZER = {
  [PROJECT_ID_PARAM]: (value: string[]) =>
    value?.length ? encodeURIComponent(value.join(',')) : '',

  [TAB_PARAM]: (value: string) => (value ? encodeURIComponent(value) : ''),
};

const SEARCH_PARAMS_DESERIALIZER = {
  [PROJECT_ID_PARAM]: (value: string) => {
    if (!value) {
      return null;
    }

    return decodeURIComponent(value).split(',');
  },
  [TAB_PARAM]: (value: string) => {
    if (!value) {
      return null;
    }

    const decodedValue = decodeURIComponent(value);
    return AVAILABLE_TABS.includes(decodedValue) ? decodedValue : null;
  },
};

export const serializeAnalyticsSearchParams = (params: AnalyticsSearchParams) => {
  const searchParams = new URLSearchParams();

  for (const [key, value] of Object.entries(params)) {
    if (!SEARCH_PARAMS_SERIALIZER[key]) {
      continue;
    }

    const serializedValue = SEARCH_PARAMS_SERIALIZER[key](value);
    if (serializedValue) {
      searchParams.set(key, SEARCH_PARAMS_SERIALIZER[key](value));
    }
  }

  return searchParams.toString();
};

export const deserializeAnalyticsSearchParams = (search: string) => {
  const searchParams = new URLSearchParams(search);
  const params: AnalyticsSearchParams = {};

  for (const [key, value] of searchParams.entries()) {
    if (!SEARCH_PARAMS_DESERIALIZER[key]) {
      continue;
    }

    const deserializedValue = SEARCH_PARAMS_DESERIALIZER[key](value);
    if (deserializedValue) {
      params[key] = SEARCH_PARAMS_DESERIALIZER[key](value);
    }
  }

  return params;
};

export const useAnalyticsSearchParams = () => {
  const location = useLocation();

  return deserializeAnalyticsSearchParams(location.search);
};
