import React from 'react';
import OnboardingCompanyQuestions from 'app/src/pages/onboarding/OnboardingCompanyQuestions';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import OnboardingUseCase from 'app/src/pages/onboarding/OnboardingUseCase';

const OnboardingContent = ({ stage, ...props }) => {
  if (stage === 1) {
    return (
      <ContentContainer>
        <OnboardingCompanyQuestions {...props} />
      </ContentContainer>
    );
  }

  return (
    <ContentContainer>
      <OnboardingUseCase {...props} />
    </ContentContainer>
  );
};

const ContentContainer = styled(VerticalFlex)`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  align-items: center;
  gap: 32px;
  padding: 16px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    padding: 0px 16px;
  }
`;

export default OnboardingContent;
