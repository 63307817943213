import React from 'react';
import styled from 'styled-components';

import { TextSmall } from 'shared/react/components/basic/text/TextV2';
import CurvedLightningIcon from 'app/src/images/creation_method_icons/CurvedLightningIcon';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';

type Props = {
  showAutomaticInstallationSection: boolean;
  automaticSectionDescription: string | React.ReactElement;
};

const AutomaticInstallationSection: React.FC<Props> = ({
  automaticSectionDescription,
  showAutomaticInstallationSection,
}) => {
  if (!showAutomaticInstallationSection) {
    return null;
  }

  return (
    <>
      <TitleWrapper>
        <TextSmall>This happens automatically</TextSmall>
        <CurvedLightningIcon />
      </TitleWrapper>
      <TextSmallDark>{automaticSectionDescription}</TextSmallDark>
    </>
  );
};

const TitleWrapper = styled(Gap8HorizontalFlexWrap)`
  align-items: center;

  & svg {
    width: 13px;
    height: 18px;
  }
`;

const TextSmallDark = styled(TextSmall)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.neutralDark};
`;

export default AutomaticInstallationSection;
