import React from 'react';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import styled from 'styled-components';
import Separator from 'shared/react/components/basic/Separator';
import { OnSiteTextRoutesText } from 'app/src/constants/onSite.constants';
import { TextSmall } from 'shared/react/components/basic/text/TextV2';

const NewTolstoyChoosePage = ({ icon, route, onClick }) => {
  const onPageClick = () => {
    onClick(route);
  };

  return (
    <>
      <LayoutRoot data-test-id={route} onClick={onPageClick}>
        {icon}
        <Text>{OnSiteTextRoutesText[route]}</Text>
      </LayoutRoot>
      <StyledSeparator />
    </>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlex)`
  padding: 12px 8px;
  cursor: pointer;
  align-items: center;
  transition: 0.3s;

  svg {
    width: 18px;
    height: 18px;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.neutralLightest};
  }
`;

const Text = styled(TextSmall)`
  font-weight: 500;
  line-height: 22px;
`;

const StyledSeparator = styled(Separator)`
  background: ${({ theme }) => theme.colors.neutralLightest};

  &:last-of-type {
    display: none;
  }
`;

export default NewTolstoyChoosePage;
