/* Note -> this generates a multi-layered shadow with a nice dropoff based on
 * the falloff. Built in such a way it will never quite reach 0 or negative
 * opacity */
const generateShadow = ({ spread, maxOpacity, falloff, depth }) =>
  Array.from({ length: depth })
    .map(
      (_, index) =>
        `0 0 ${index * spread}px rgba(0, 0, 0, ${
          index === 0 ? maxOpacity : maxOpacity / (index + falloff)
        })`,
    )
    .join(",");

const base = {
  defaultColors: ["#FF2323", "#66FF00", "#00FFFF"],
  text: {
    color: "white",
    size: "32px",
    shadow: generateShadow({
      spread: 3,
      maxOpacity: 0.85,
      falloff: 0.15,
      depth: 8,
    }),
  },
};

const STATIC = {
  ...base,
};

const ANIMATED = {
  ...base,
  animation: [
    { transform: "scale(0.8)" },
    { transform: "scale(1)" },
    { transform: "scale(0.8)" },
  ],
  maxNumberOfWords: 3,
};

export { generateShadow, STATIC, ANIMATED };
