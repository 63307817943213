import React from 'react';

function NotificationVideoResponseIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" viewBox="0 0 40 40">
      <rect width="40" height="40" fill="#FEE1E6" rx="10" />
      <path
        fill="#FA536E"
        d="M23 15.5c0-.813-.688-1.5-1.5-1.5h-9a1.5 1.5 0 00-1.5 1.5v9c0 .844.656 1.5 1.5 1.5h9a1.5 1.5 0 001.5-1.5v-9zm6 .5c0-.781-.938-1.25-1.594-.813L24 17.564v4.906l3.406 2.375c.657.437 1.594-.032 1.594-.813V16z"
      />
    </svg>
  );
}

export default NotificationVideoResponseIcon;
