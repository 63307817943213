import React from 'react';

function NotificationAnonymousIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" viewBox="0 0 40 40">
      <rect width="40" height="40" fill="#DFE0E2" rx="10" />
      <path
        fill="#090A0B"
        d="M20 20c2.188 0 4-1.781 4-4 0-2.188-1.813-4-4-4-2.219 0-4 1.813-4 4 0 2.219 1.781 4 4 4zm1.563 1.5h-3.157c-3 0-5.406 2.438-5.406 5.438 0 .593.469 1.062 1.063 1.062h11.843C26.5 28 27 27.531 27 26.937c0-3-2.438-5.437-5.438-5.437z"
      />
    </svg>
  );
}

export default NotificationAnonymousIcon;
