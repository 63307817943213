import styled from 'styled-components';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import { TextBody, TextH4, TextSmall } from 'shared/react/components/complex/Text';

export const HeaderContainer = styled(Gap16VerticalFlex)`
  margin: 56px 0;
  max-width: 76ch;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    margin: 40px 0;
  }
`;

export const Header = styled(TextH4)`
  text-align: center;
  font-weight: 700;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    font-size: 24px;
  }
`;

export const SubHeader = styled(TextSmall)`
  text-align: center;
  color: ${({ theme }) => theme.colors.ghostDark};
`;

export const Textarea = styled.textarea`
  border: none;
  border-radius: 12px;
  padding: 12px 16px;
  resize: none;
  width: 750px;
  outline: none;
  font-size: 16px;
  height: 100%;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 500px;
  }

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    width: 300px;
  }
`;

export const CategoryHeader = styled(TextBody)`
  font-weight: 800;
`;
