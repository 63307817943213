import React from 'react';

const OtherPagesIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.375 19.25H4a2.468 2.468 0 0 1-2.5-2.5V4.875c0-.313-.313-.625-.625-.625a.642.642 0 0 0-.625.625V16.75c0 2.11 1.64 3.75 3.75 3.75h9.375a.642.642 0 0 0 .625-.625c0-.313-.313-.625-.625-.625Zm3.633-14.297-3.711-3.71A2.485 2.485 0 0 0 11.539.5H6.5A2.492 2.492 0 0 0 4 3v11.25c0 1.406 1.094 2.5 2.5 2.5h8.75c1.367 0 2.5-1.094 2.5-2.5V6.71c0-.663-.273-1.288-.742-1.757Zm-4.258-2.5L15.797 5.5h-2.422a.617.617 0 0 1-.625-.625V2.453ZM16.5 14.25c0 .703-.586 1.25-1.25 1.25H6.5c-.703 0-1.25-.547-1.25-1.25V3c0-.664.547-1.25 1.25-1.25h5v3.125a1.85 1.85 0 0 0 1.875 1.875H16.5v7.5Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default OtherPagesIcon;
