import React from 'react';

const UnmuteButton = () => {
  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.40625 0.09375C9.25 0.03125 9.125 0 9 0C8.75 0 8.5 0.09375 8.3125 0.28125L4.09375 4.03125H1.5C0.65625 4.03125 0 4.6875 0 5.53125V8.53125C0 9.34375 0.65625 10 1.5 10H4.09375L8.3125 13.75C8.5 13.9375 8.75 14 9 14C9.125 14 9.25 14 9.40625 13.9375C9.75 13.7812 10 13.4062 10 13V1.03125C10 0.625 9.75 0.25 9.40625 0.09375ZM8.96875 13L4.78125 9.28125L4.46875 9H1.5C1.21875 9 1 8.78125 1 8.53125V5.53125C1 5.25 1.21875 5.03125 1.5 5.03125H4.46875L4.78125 4.75L8.96875 1.03125C8.96875 1.03125 8.96875 1.03125 9 1.03125L8.96875 13ZM14.3438 2.09375C14.125 1.9375 13.8125 2 13.6562 2.25C13.5 2.46875 13.5625 2.78125 13.7812 2.9375C15.1562 3.84375 16 5.375 16 7C16 8.65625 15.1562 10.1875 13.7812 11.0938C13.5625 11.25 13.5 11.5625 13.6562 11.7812C13.75 11.9375 13.9062 12 14.0625 12C14.1562 12 14.25 12 14.3438 11.9375C16 10.8125 17 8.96875 17 7C17 5.03125 16 3.21875 14.3438 2.09375ZM12.6875 4.59375C12.4688 4.4375 12.1562 4.5 12 4.75C11.8438 4.96875 11.9062 5.28125 12.125 5.4375C12.6562 5.78125 13 6.375 13 7C13 7.65625 12.6562 8.25 12.125 8.59375C11.9062 8.75 11.8438 9.0625 12 9.28125C12.0938 9.4375 12.25 9.5 12.4062 9.5C12.5 9.5 12.5938 9.5 12.6875 9.4375C13.5 8.875 14 7.96875 14 7C14 6.0625 13.5 5.15625 12.6875 4.59375Z"
        fill="white"
      />
    </svg>
  );
};

export default UnmuteButton;
