// TODO add getVideoPoster with options ({ size } instead of 'getVideoPosterLarge' etc.)
import { AI_VIDEO_WATERMARK_EXTENSION } from './constants';
class VideoRenditionsServiceSingleton {
  init = ({ outputBucketUrl }) => {
    this.outputBucketUrl = outputBucketUrl;
  };

  getOwnerPath = ({ owner }) => {
    if (!this.outputBucketUrl) {
      throw new Error(
        'VideoRenditionsService is not initialized correctly. Missing outputBucketUrl.'
      );
    }

    return `${this.outputBucketUrl}/public/${owner}`;
  };

  getVideoBasicUrl = (video, resolution) => {
    return `${this.getOwnerPath(video)}/${video.id}/${video.id}${resolution || ''}.mp4`;
  };

  getAiVideoWithWatermark = (video, resolution) => {
    return `${VideoRenditionsService.getOwnerPath(video)}/${video.id}/${
      video.id
    }${AI_VIDEO_WATERMARK_EXTENSION}${resolution || ''}.mp4`;
  };

  getVideoUrl = (video, resolution) => {
    if (!video) {
      return null;
    }

    if (video.uploadType?.includes('aiVideo')) {
      return `${this.getOwnerPath(video)}/${video.id}/${video.id}_w_${resolution || ''}.mp4`;
    }

    if (video.stockAsset?.videoUrl) {
      return video.stockAsset.videoUrl;
    }

    return this.getVideoBasicUrl(video, resolution);
  };

  getVideoPoster = video => {
    if (!video) {
      return null;
    }

    if (video.stockAsset?.posterUrl) {
      return video.stockAsset.posterUrl;
    }

    return `${this.getOwnerPath(video)}/${video.id}/${video.id}.thumb.0000000.jpg`;
  };

  getVideoPosterLarge = video => {
    if (video.stockAsset?.posterUrl) {
      return video.stockAsset.posterUrl;
    }

    return `${this.getOwnerPath(video)}/${video.id}/${video.id}.0000000.jpg`;
  };
}

export const VideoRenditionsService = new VideoRenditionsServiceSingleton();

export default VideoRenditionsService;
