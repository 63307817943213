import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useUser } from 'app/src/context/userStore/UserStore';
import { CircularProgress } from '@material-ui/core';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import NotificationSettingsContainer from 'app/src/pages/notifications/NotificationSettingsContainer';
import {
  createOrUpdateNotificationSettings,
  fetchNotificationSettings,
  useAccount,
} from 'app/src/context/AccountStore';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { FEATURE_TEAM_MEMBER_NOTIFICATION_SETTINGS } from 'app/src/constants/appFeatures.constants';
import SnackBar from 'app/src/basic_components/SnackBar';
import StickyTopBar from 'app/src/pages/dashboard/components/top-bar/StickyTopBar';
import useUpdateDirtyForm from 'app/src/hooks/useUpdateDirtyForm';

function AccountNotificationSettingsPage() {
  const [, { getUser }] = useUser();
  const [{ teamMembers }, { updateTeamMember }] = useAccount();
  const [, { getFeatureEnabled, getSettingsByKey }] = useFeatures();

  const user = getUser();
  const [emailSettings, setEmailSettings] = useState([]);
  const [inAppSettings, setInAppSettings] = useState([]);
  const [isSaving, setSaving] = useState(false);
  const [isUnsaved, setUnsaved] = useState(false);
  const [isEmailShowAnonymous, setEmailShowAnonymous] = useState(false);
  const [isInAppShowAnonymous, setInAppShowAnonymous] = useState(false);
  const [successBar, setSuccessBar] = useState(false);

  function getCurrentTeamMember() {
    return teamMembers?.find(({ userId }) => userId === user.owner);
  }

  function getTeamMemberNotificationSettingsId() {
    const currentTeamMember = getCurrentTeamMember();
    return currentTeamMember?.notificationSettingsId;
  }

  async function loadNotificationSettings() {
    const notificationSettingsId = getTeamMemberNotificationSettingsId();
    let teamMemberNotificationSettings = null;
    if (notificationSettingsId) {
      teamMemberNotificationSettings = await fetchNotificationSettings(
        notificationSettingsId,
        user.owner
      );
    }

    const notificationSettings =
      teamMemberNotificationSettings || getSettingsByKey(FEATURE_TEAM_MEMBER_NOTIFICATION_SETTINGS);

    if (!notificationSettings) {
      return;
    }

    const { email: emailNotificationSettings, inApp: inAppNotificationSettings } =
      notificationSettings;
    setEmailShowAnonymous(emailNotificationSettings?.showAnonymous);
    setEmailSettings(emailNotificationSettings?.enabled || []);
    setInAppShowAnonymous(inAppNotificationSettings?.showAnonymous);
    setInAppSettings(inAppNotificationSettings?.enabled || []);
  }

  const handleSetInAppSettingProperty = (propertyName, enabled) => {
    setUnsaved(true);
    setInAppSettings(
      enabled
        ? [...inAppSettings, propertyName]
        : inAppSettings.filter(inAppSetting => inAppSetting !== propertyName)
    );
  };

  const handleSetEmailSettingProperty = (propertyName, enabled) => {
    setUnsaved(true);
    setEmailSettings(
      enabled
        ? [...emailSettings, propertyName]
        : emailSettings.filter(emailSetting => emailSetting !== propertyName)
    );
  };
  const handleSetInAppShowAnonymous = enabled => {
    setUnsaved(true);
    setInAppShowAnonymous(enabled);
  };

  const handleSetEmailShowAnonymous = enabled => {
    setUnsaved(true);
    setEmailShowAnonymous(enabled);
  };

  const handleSaveSettings = async () => {
    setSaving(true);
    const notificationSettingsId = getTeamMemberNotificationSettingsId();
    const response = await createOrUpdateNotificationSettings(notificationSettingsId, {
      owner: user.owner,
      email: {
        showAnonymous: isEmailShowAnonymous,
        enabled: emailSettings,
      },
      inApp: {
        showAnonymous: isInAppShowAnonymous,
        enabled: inAppSettings,
      },
    });
    const newNotificationSettingsId = response?.id;
    if (!notificationSettingsId && newNotificationSettingsId) {
      const myTeamMember = teamMembers?.find(({ userId }) => userId === user.owner);
      await updateTeamMember(myTeamMember.id, {
        notificationSettingsId: newNotificationSettingsId,
      });
    }
    setSaving(false);
    setUnsaved(false);
    setSuccessBar(true);
  };

  const onSaveClickCallback = useCallback(handleSaveSettings, [
    emailSettings,
    inAppSettings,
    isEmailShowAnonymous,
    isInAppShowAnonymous,
  ]);

  useEffect(() => {
    if (!user) {
      return;
    }
    loadNotificationSettings();
  }, [user, teamMembers?.length]);

  useUpdateDirtyForm(isUnsaved, {
    isLoading: isSaving,
    hideCancel: true,
    onSaveClick: onSaveClickCallback,
  });

  if (!getFeatureEnabled(FEATURE_TEAM_MEMBER_NOTIFICATION_SETTINGS)) {
    return null;
  }

  if (!user?.owner || !teamMembers?.length) {
    return <StyledCircularProgress size={20} />;
  }

  return (
    <LayoutRoot>
      <StickyTopBar leftContent="Notifications" dirtyTitle="Notifications" />
      <MainContainer>
        <NotificationSettingsContainer
          emailSettings={emailSettings}
          setEmailSettingProperty={handleSetEmailSettingProperty}
          isEmailShowAnonymous={isEmailShowAnonymous}
          setEmailShowAnonymous={handleSetEmailShowAnonymous}
          inAppSettings={inAppSettings}
          isInAppShowAnonymous={isInAppShowAnonymous}
          setInAppShowAnonymous={handleSetInAppShowAnonymous}
          setInAppSettingProperty={handleSetInAppSettingProperty}
        />
        <SnackBar
          text="Changes have been saved!"
          open={successBar}
          setOpen={setSuccessBar}
          severity="success"
        />
      </MainContainer>
    </LayoutRoot>
  );
}

const LayoutRoot = styled(VerticalFlex)``;

const MainContainer = styled(VerticalFlex)`
  width: 100%;
  height: 100%;
  padding: 32px;
  overflow-y: auto;
  flex-grow: 1;
`;

const StyledCircularProgress = styled(CircularProgress)`
  align-self: start;
  margin: 32px;
`;

export default AccountNotificationSettingsPage;
