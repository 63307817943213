import React from 'react';

function XThickIcon({ fill, width, height, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '16'}
      height={height || '16'}
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill={fill || '#FA536E'}
        d="M14.938 12.313L10.625 8l4.313-4.266a1.956 1.956 0 000-2.671 1.956 1.956 0 00-2.672 0L8 5.375 3.687 1.062a1.956 1.956 0 00-2.671 0 1.956 1.956 0 000 2.672L5.328 8l-4.312 4.313a1.956 1.956 0 000 2.671c.75.704 1.921.704 2.671 0L8 10.672l4.266 4.312c.75.704 1.921.704 2.671 0a1.956 1.956 0 000-2.671z"
      />
    </svg>
  );
}

export default XThickIcon;
