import React, { ReactNode } from 'react';
import { ToggleGroup, ToggleGroupItem } from '@tolstoy/ui-library/core';

export interface SingleToggleItem {
  value: any;
  label?: string;
  component?: ReactNode;
}

interface SingleToggleGroupProps {
  value: any;
  items: SingleToggleItem[];
  onChange: (value: any) => void;
}

export const SingleToggleGroup = ({
  value,
  items = [],
  onChange,
}: SingleToggleGroupProps) => {
  const renderItem = (item: SingleToggleItem) => (
    <ToggleGroupItem value={item.value}>
      {item.label && <p className="text-sm">{item.label}</p>}
      {item.component}
    </ToggleGroupItem>
  );

  return (
    <ToggleGroup
      type="single"
      value={value}
      onValueChange={onChange}
    >
      {items.map(renderItem)}
    </ToggleGroup>
  );
};

