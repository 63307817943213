import React from 'react';
import styled, { css } from 'styled-components';
import {
  EMAIL_PLATFORM_GMAIL,
  AUTOMATION_TOOL_HUBSPOT,
  AUTOMATION_TOOL_INTERCOM,
  AUTOMATION_TOOL_KLAVIYO,
  AUTOMATION_TOOL_MAILCHIMP,
  AUTOMATION_TOOL_OMNISEND,
  AUTOMATION_TOOL_OTHER,
  AUTOMATION_TOOL_OUTREACH,
  AUTOMATION_TOOL_SALESLOFT,
  AUTOMATION_TOOL_SENDGRID,
  AUTOMATION_TOOL_AMPLEMARKET,
  AUTOMATION_TOOL_OUTPLAY,
  AUTOMATION_TOOL_SALESFORCE,
  AUTOMATION_TOOL_SHOPIFY,
  AUTOMATION_TOOL_YOTPO,
  AUTOMATION_TOOL_ACTIVECAMPAIGN,
  AUTOMATION_TOOL_EMARSYS,
  AUTOMATION_TOOL_SAILTHRU,
} from 'app/src/constants/share.constants';
import OutreachIcon from 'app/src/images/automationToolsIcons/OutreachIcon';
import SalesforceIcon from 'app/src/images/automationToolsIcons/SalesforceIcon';
import SalesloftIcon from 'app/src/images/automationToolsIcons/SalesloftIcon';
import HubspotIcon from 'app/src/images/automationToolsIcons/HubspotIcon';
import KlaviyoIcon from 'app/src/images/automationToolsIcons/KlaviyoIcon';
import MailChimpIcon from 'app/src/images/automationToolsIcons/MailChimpIcon';
import SendgridIcon from 'app/src/images/automationToolsIcons/SendgridIcon';
import ShopifyIcon from 'app/src/images/automationToolsIcons/ShopifyIcon';
import OmnisendIcon from 'app/src/images/automationToolsIcons/OmnisendIcon';
import IntercomIcon from 'app/src/images/automationToolsIcons/IntercomIcon';
import YotpoIcon from 'app/src/images/automationToolsIcons/YotpoIcon';
import GmailIcon from 'app/src/images/automationToolsIcons/gmail.png';
import { TextH6 } from 'shared/react/components/basic/text/TextV2';
import AmpleMarketIcon from 'app/src/images/automationToolsIcons/AmpleMarketIcon';
import OutPlayIcon from 'app/src/images/automationToolsIcons/OutPlayIcon';
import ActiveCampaignIcon from 'app/src/images/automationToolsIcons/ActiveCampaignIcon';
import EmarsysIcon from 'app/src/images/automationToolsIcons/EmarsysIcon';
import SailthruIcon from 'app/src/images/automationToolsIcons/SailthruIcon';

const AutomationToolContainerContainerCss = css`
  padding-left: 8px;
  display: flex;
  align-items: center;
`;

const AutomationToolContainer = styled.div`
  ${AutomationToolContainerContainerCss}
`;

const StyledOtherMenuItem = styled(TextH6)`
  color: ${({ theme }) => theme.colors.gray31};
  ${AutomationToolContainerContainerCss};
  font-size: 16px;
  font-weight: 600;
  height: 40px;
`;

const Image = styled.img`
  max-width: 80px;
  max-height: 18px;
`;

const ImageContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
`;

const prepareIntegrationMenuItem = icon => {
  return <AutomationToolContainer>{icon}</AutomationToolContainer>;
};

export default [
  {
    id: EMAIL_PLATFORM_GMAIL,
    name: prepareIntegrationMenuItem(
      <ImageContainer>
        <Image src={GmailIcon} alt="Gmail" />
      </ImageContainer>
    ),
  },
  {
    id: AUTOMATION_TOOL_OUTREACH,
    name: prepareIntegrationMenuItem(<OutreachIcon alt="Outreach.io" />),
  },
  {
    id: AUTOMATION_TOOL_SALESLOFT,
    name: prepareIntegrationMenuItem(<SalesloftIcon alt="Salesloft" />),
  },
  {
    id: AUTOMATION_TOOL_HUBSPOT,
    name: prepareIntegrationMenuItem(<HubspotIcon alt="Hubspot" />),
  },
  {
    id: AUTOMATION_TOOL_KLAVIYO,
    hasProductVariableSupport: true,
    name: prepareIntegrationMenuItem(<KlaviyoIcon alt="Klaviyo" />),
  },
  {
    id: AUTOMATION_TOOL_SHOPIFY,
    name: prepareIntegrationMenuItem(<ShopifyIcon alt="Shopify" />),
  },
  {
    id: AUTOMATION_TOOL_MAILCHIMP,
    name: prepareIntegrationMenuItem(<MailChimpIcon alt="MailChimp" />),
  },
  {
    id: AUTOMATION_TOOL_SENDGRID,
    name: prepareIntegrationMenuItem(<SendgridIcon alt="Sendgrid" />),
  },
  {
    id: AUTOMATION_TOOL_OMNISEND,
    hasProductVariableSupport: true,
    name: prepareIntegrationMenuItem(<OmnisendIcon alt="Omnisend" />),
  },
  {
    id: AUTOMATION_TOOL_AMPLEMARKET,
    name: prepareIntegrationMenuItem(<AmpleMarketIcon alt="AmpleMarket" />),
  },
  {
    id: AUTOMATION_TOOL_OUTPLAY,
    name: prepareIntegrationMenuItem(<OutPlayIcon />),
  },
  {
    id: AUTOMATION_TOOL_INTERCOM,
    name: prepareIntegrationMenuItem(<IntercomIcon alt="Intercom" />),
  },
  {
    id: AUTOMATION_TOOL_YOTPO,
    name: prepareIntegrationMenuItem(<YotpoIcon alt="Yotpo" />),
  },
  {
    id: AUTOMATION_TOOL_SALESFORCE,
    name: prepareIntegrationMenuItem(<SalesforceIcon alt="Salesforce" />),
  },
  {
    id: AUTOMATION_TOOL_EMARSYS,
    name: prepareIntegrationMenuItem(<EmarsysIcon alt="Emarsys" />),
  },
  {
    id: AUTOMATION_TOOL_ACTIVECAMPAIGN,
    name: prepareIntegrationMenuItem(<ActiveCampaignIcon />),
  },
  {
    id: AUTOMATION_TOOL_SAILTHRU,
    name: prepareIntegrationMenuItem(<SailthruIcon />),
  },
  {
    id: AUTOMATION_TOOL_OTHER,
    name: <StyledOtherMenuItem>Other</StyledOtherMenuItem>,
  },
];
