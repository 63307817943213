import React from 'react';
import Tooltip from 'app/src/basic_components/Tooltip';
import styled from 'styled-components';

type Props = {
  text: string;
  children: React.ReactNode;
};

const OnboardingTemplatesTagVideosTooltip = ({ text, children }: Props) => {
  return (
    <StyledTooltip text={text} open placement="left" tooltipContainerPadding="8px">
      {children}
    </StyledTooltip>
  );
};

const StyledTooltip = styled(Tooltip)`
  background-color: ${({ theme }) => theme.colors.primary};

  & .MuiTooltip-arrow::before {
    background: ${({ theme }) => theme.colors.primary};
  }
`;

export default OnboardingTemplatesTagVideosTooltip;
