import styled from 'styled-components';
import BasicButton from './BasicButton';
import { SmallButton } from 'shared/react/components/basic/button/button/sharedButtonCss';

const SuccessButton = styled(BasicButton)`
  background: ${({ theme }) => theme.colors.success};
  color: ${({ theme }) => theme.colors.white};
  border: none;

  &:hover {
    border: none;
    background: ${({ theme }) => theme.colors.successHover};
  }
`;

export const SmallSuccessButton = styled(SuccessButton)`
  ${SmallButton}
`;

export default SuccessButton;
