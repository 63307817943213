import PublishMethod from 'app/src/complex_components/publish_methods/PublishMethod';
import React from 'react';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import { TextSmall } from 'shared/react/components/complex/Text';
import styled from 'styled-components';

const PublishMethodFilterMenuItem = ({ title, icon, publishMethod, ...props }) => {
  if (publishMethod) {
    return (
      <LayoutRoot {...props}>
        <PublishMethod publishMethod={publishMethod} />
      </LayoutRoot>
    );
  }

  return (
    <LayoutRoot {...props}>
      {icon}
      <Text>{title}</Text>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlexWrap)`
  gap: 12px;
  padding: 8px 20px;
  background: ${({ theme, isSelected }) => (isSelected ? theme.colors.gray5 : '')};
  cursor: pointer;
  align-items: center;
  &:hover {
    background: ${({ theme, isSelected }) =>
      isSelected ? theme.colors.gray5 : theme.getHexOpacity(theme.colors.gray5, 50)};
  }
`;

const Text = styled(TextSmall)``;

export default PublishMethodFilterMenuItem;
