import { PlaylistItem } from 'src/pages/dashboard/types/playlist.types';
import {
  ALL_IMAGES_PLAYLIST,
  ALL_VIDEOS_PLAYLIST,
  GALLERIES_PLAYLIST,
  TRENDING_VIDEOS_PLAYLIST,
} from '../constants/filters.constants';

export const getAllVideosPlaylist = (videosCount): PlaylistItem => ({
  ...ALL_VIDEOS_PLAYLIST,
  count: videosCount,
});

export const getAllImagesPlaylist = (imagesCount): PlaylistItem => ({
  ...ALL_IMAGES_PLAYLIST,
  count: imagesCount,
});

export const getTrendingVideosPlaylist = (videosCount): PlaylistItem => ({
  ...TRENDING_VIDEOS_PLAYLIST,
  count: videosCount,
});

export const getGalleriesPlaylist = (galleriesCount): PlaylistItem => ({
  ...GALLERIES_PLAYLIST,
  count: galleriesCount,
});
