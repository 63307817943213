import styled from 'styled-components';

const BasicButton = styled.button`
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  width: fit-content;
  padding: 12px;
  font-size: 14px;
  border-radius: 20px;
  line-height: 18px;
  font-weight: 600;
  letter-spacing: normal;
  transition: 0.3s;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? '0.25' : '1')};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};

  &:hover {
    background: ${({ theme }) => theme.colors.primaryActive};
  }
`;

export default BasicButton;
