import React from 'react';
import EditorToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorToggleItem';

type Props = {
  settings?: {
    includeProductPrice: boolean;
    hasQuantityPicker: boolean;
  };
  disabled?: boolean;
  onChange?: any;
};

const PrimaryButtonSettingsEditor = ({
  settings,
  disabled = false,
  onChange = () => {},
}: Props) => {
  const { includeProductPrice = false, hasQuantityPicker = false } = settings || {};
  const updateSettings = update => onChange({ ...settings, ...update });

  return (
    <>
      <EditorToggleItem
        disabled={disabled}
        text="Include product price"
        checked={includeProductPrice}
        onChange={includeProductPrice => updateSettings({ includeProductPrice })}
      />
      <EditorToggleItem
        disabled={disabled}
        text="Quantity picker"
        checked={hasQuantityPicker}
        onChange={hasQuantityPicker => updateSettings({ hasQuantityPicker })}
      />
    </>
  );
};

export default PrimaryButtonSettingsEditor;
