import React from 'react';
import styled from 'styled-components';

import { getTimeSince } from 'app/src/utils/date.utils';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';
import { Badge } from 'shared/react/components/basic/Badge';
import { CardCreatedAt, CardDetails, CardName } from 'app/src/pages/dashboard/components/card/Card';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { useUser } from 'src/context/userStore/UserStore';
import {
  PUBLISH_METHODS,
  LIVE_SETTABLE_PUBLISH_METHODS,
} from 'shared/react/constants/tolstoy.constants';
import { publishMethodOptions } from 'app/src/types/entities';
import { FEATURE_PUBLISH_V2 } from 'shared/react/constants/features.constants';
import { useFeatureActions } from 'src/context/FeaturesStore';
import CardProjectTypeIcon from 'app/src/pages/dashboard/components/card/CardProjectTypeIcon';

const OnsiteCardDetails = ({ project }) => {
  const [, { isProjectLive }] = useUser();
  const { getFeatureEnabled } = useFeatureActions();
  const isPublishV2Enabled = getFeatureEnabled(FEATURE_PUBLISH_V2);

  const liveSettablePublishMethods = isPublishV2Enabled
    ? LIVE_SETTABLE_PUBLISH_METHODS
    : [publishMethodOptions.bubble, publishMethodOptions.shopApp, publishMethodOptions.hero];
  const isLiveSettablePublishMethod = liveSettablePublishMethods.includes(project?.publishMethod);
  const isLive = isProjectLive(project);

  const getIsActiveBadge = () => {
    if (
      !isLiveSettablePublishMethod &&
      project?.publishMethod !== publishMethodOptions.unassigned
    ) {
      return null;
    }

    const badgeText = isLive ? 'Active' : 'Draft';
    return <IsActiveBadge isActive={isLive}>{badgeText}</IsActiveBadge>;
  };
  const shouldShowPublishMethod =
    project.publishMethod && project.publishMethod !== publishMethodOptions.unassigned;

  return (
    <DetailsWrapper>
      <NameWrapper>
        <EllipsisTextOverflowWrapper>
          <CardName className="notranslate" title={project.name}>
            {project.name}
          </CardName>
        </EllipsisTextOverflowWrapper>
        {getIsActiveBadge()}
      </NameWrapper>
      <MetaWrapper>
        <Gap8HorizontalFlex>
          {shouldShowPublishMethod && (
            <PublishMethodBadge>{PUBLISH_METHODS[project.publishMethod]?.text}</PublishMethodBadge>
          )}
        </Gap8HorizontalFlex>
        <BottomRow>
          <CardCreatedAt>Updated {getTimeSince(project.updatedAt)}</CardCreatedAt>
          <CardProjectTypeIcon project={project} />
        </BottomRow>
      </MetaWrapper>
    </DetailsWrapper>
  );
};

const DetailsWrapper = styled(CardDetails)`
  gap: 0;
  justify-content: space-between;
`;

const NameWrapper = styled(Gap8HorizontalFlex)`
  justify-content: space-between;
`;

const MetaWrapper = styled(VerticalFlex)`
  gap: 4px;
`;

const BottomRow = styled(Gap8HorizontalFlex)`
  align-items: center;
  justify-content: space-between;
`;

const PublishMethodBadge = styled(Badge)`
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  padding: 3px 8px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.neutralLightest};
`;

const IsActiveBadge = styled(PublishMethodBadge)`
  flex-shrink: 0;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ isActive, theme }) => theme.colors[isActive ? 'success' : 'ghostLight']};
`;

export default OnsiteCardDetails;
