import { useCallback, useRef, useState } from 'react';
import {
  BuilderForm,
  FeedTypeForm,
  StepForm,
  StepsForm,
  StepsOrderForm,
} from './types/BuilderForm.types';
import { useProjectActions } from 'app/src/context/ProjectsStore';
import { useQuizActions } from 'app/src/context/QuizStore';
import { useApps } from 'app/src/context/AppsStore';
import Utils from 'app/src/utils';
import { isEqual } from 'lodash';
import { project as ProjectType } from 'src/types/entities';
import { ONSITE_TARGET_PAGES } from 'app/src/pages/dashboard/constants/onsiteTargetPages.constants';

const noChangesBuilderForm: BuilderForm = {
  stepsOrder: {},
  steps: {},
  feedType: {},
  isDirty: false,
  isLoading: false,
  saveCallbacks: [],
  discardCallbacks: [],
};

const useBuilderForm = () => {
  const { updateProject, publishProject } = useProjectActions();
  const { updateQuiz } = useQuizActions();
  const [{ shopify }] = useApps();
  const [builderForm, setBuilderForm] = useState<BuilderForm>(noChangesBuilderForm);

  const isSavingRef = useRef(false);
  const isDiscardingRef = useRef(false);

  const setStepsOrder = (newStepsOrder: StepsOrderForm) => {
    if (
      isSavingRef.current ||
      isDiscardingRef.current ||
      isEqual(builderForm.stepsOrder?.stepsOrder, newStepsOrder?.stepsOrder)
    ) {
      return;
    }

    updateBuilderForm({ newStepsOrder });
  };

  const setStep = (stepId: string, stepForm: StepForm) => {
    if (
      isSavingRef.current ||
      isDiscardingRef.current ||
      isEqual(builderForm.steps[stepId]?.step, stepForm?.step)
    ) {
      return;
    }

    const newSteps = {
      ...builderForm.steps,
      [stepId]: stepForm,
    };

    if (!stepForm) {
      delete newSteps[stepId];
    }

    updateBuilderForm({ newSteps });
  };

  const getStep = (stepId: string) => builderForm.steps[stepId];

  const setFeedType = (newFeedType: FeedTypeForm) => {
    if (
      isSavingRef.current ||
      isDiscardingRef.current ||
      builderForm.feedType.isDynamic === newFeedType.isDynamic
    ) {
      return;
    }

    updateBuilderForm({ newFeedType });
  };

  const updateBuilderForm = ({
    newStepsOrder,
    newSteps,
    newFeedType,
  }: {
    newStepsOrder?: StepsOrderForm | null;
    newSteps?: StepsForm | null;
    newFeedType?: FeedTypeForm | null;
  } = {}) => {
    if (isSavingRef.current || isDiscardingRef.current) {
      return;
    }

    const updatedFeedType = newFeedType === undefined ? builderForm.feedType : newFeedType;
    const updatedStepsOrder = newStepsOrder === undefined ? builderForm.stepsOrder : newStepsOrder;
    const updatedSteps = newSteps === undefined ? builderForm.steps : newSteps;
    const stepsArr = updatedSteps ? Object.values(updatedSteps) : [];

    const newSaveCallbacks = [];
    const newDiscardCallbacks = [];
    let isAnyStepDirty = false;
    let isAnyStepLoading = false;

    if (updatedFeedType?.isDirty) {
      if (updatedFeedType.onSave) {
        newSaveCallbacks.push(updatedFeedType.onSave);
      }
      if (updatedFeedType.onDiscard) {
        newDiscardCallbacks.push(updatedFeedType.onDiscard);
      }
    }

    if (updatedStepsOrder?.isDirty) {
      if (updatedStepsOrder.onSave) {
        newSaveCallbacks.push(updatedStepsOrder.onSave);
      }
      if (updatedStepsOrder.onDiscard) {
        newDiscardCallbacks.push(updatedStepsOrder.onDiscard);
      }
    }

    stepsArr.forEach((stepForm: StepForm) => {
      isAnyStepLoading = isAnyStepLoading || !!stepForm.isLoading;

      if (!stepForm.isDirty) {
        return;
      }

      isAnyStepDirty = true;

      newSaveCallbacks.push(({ selectedStep, updatedProject }) => {
        const isNotSelected = !selectedStep || stepForm.step.id !== selectedStep.id;
        stepForm.onSave(stepForm.step, isNotSelected, updatedProject);
      });
      newDiscardCallbacks.push(({ selectedStep }) => {
        const isSelected = selectedStep && stepForm.step.id === selectedStep.id;
        if (isSelected) {
          stepForm.onDiscard();
        }
      });
    });

    const newIsDirty = isAnyStepDirty || !!updatedStepsOrder?.isDirty || !!updatedFeedType?.isDirty;
    const newIsLoading =
      isSavingRef.current ||
      isAnyStepLoading ||
      !!updatedStepsOrder?.isLoading ||
      !!updatedFeedType.isLoading;

    setBuilderForm({
      steps: updatedSteps,
      stepsOrder: updatedStepsOrder,
      feedType: updatedFeedType,
      isDirty: newIsDirty,
      isLoading: newIsLoading,
      saveCallbacks: newSaveCallbacks,
      discardCallbacks: newDiscardCallbacks,
    });
  };

  const resetBuilderForm = () => {
    setBuilderForm(noChangesBuilderForm);
  };

  const getProjectIsFeedTypeDynamic = project => {
    const isDynamic = !!project?.dynamic;
    const isChange = builderForm.feedType?.isDynamic !== undefined;
    const dynamicWithoutChange = isDynamic && !isChange;
    const notDynamicWithChange = !isDynamic && isChange;
    return dynamicWithoutChange || notDynamicWithChange;
  };

  const getProjectTypeDiscover = project => {
    return !!project.discover;
  };

  const onUpdateQuiz = (project: ProjectType) => {
    if (!Utils.isQuiz(project?.tolstoyType)) {
      return;
    }
    return updateQuiz(project);
  };

  const publish = (project: ProjectType) => {
    return Promise.all([onUpdateQuiz(project), publishProject(project.id)]);
  };

  const handleUpdateProject = async (project: ProjectType) => {
    const isStepsOrderDirty = builderForm.stepsOrder?.isDirty;
    const isFeedTypeDirty = builderForm.feedType?.isDirty;
    if (!isStepsOrderDirty && !isFeedTypeDirty) {
      return;
    }

    let newProject = { ...project };

    if (isStepsOrderDirty) {
      newProject = { ...newProject, stepsOrder: builderForm.stepsOrder.stepsOrder };
    }

    if (isFeedTypeDirty) {
      newProject = {
        ...newProject,
        folder: shopify ? 'all' : newProject.folder,
        targetPage: shopify ? ONSITE_TARGET_PAGES.ProductPages : undefined,
        dynamic: builderForm.feedType.isDynamic,
      };
    }
    const updatedProject = await updateProject(newProject);
    await publish(updatedProject);

    return updatedProject;
  };

  const onSaveClick = useCallback(
    async (project, selectedStep) => {
      isSavingRef.current = true;
      setBuilderForm(prevBuilderForm => ({ ...prevBuilderForm, isLoading: true }));
      const updatedProject = await handleUpdateProject(project);
      const promises = builderForm.saveCallbacks.map(callback =>
        callback({ selectedStep, updatedProject })
      );
      await Promise.all(promises);
      resetBuilderForm();
      isSavingRef.current = false;
    },
    [builderForm.saveCallbacks]
  );

  const onDiscardClick = useCallback(
    selectedStep => {
      isDiscardingRef.current = true;
      builderForm.discardCallbacks.forEach(callback => callback({ selectedStep }));
      resetBuilderForm();
      isDiscardingRef.current = false;
    },
    [builderForm.discardCallbacks]
  );

  return {
    steps: builderForm.steps,
    stepsOrder: builderForm.stepsOrder,
    feedType: builderForm.feedType,
    resetBuilderForm,
    getStep,
    setStep,
    setStepsOrder,
    setFeedType,
    getProjectIsFeedTypeDynamic,
    getProjectTypeDiscover,
    builderForm: {
      isDirty: builderForm.isDirty,
      dirtyFormToUpdate: {
        isLoading: builderForm.isLoading,
        isCancelling: isDiscardingRef.current,
        onSaveClick,
        onDiscardClick,
      },
    },
  };
};

export default useBuilderForm;
