import React from 'react';
import styled from 'styled-components';
import AtIcon from 'app/src/images/AtIcon';
import HashtagIcon from 'app/src/images/HashtagIcon';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextH4, TextSmall, TextTiny } from 'shared/react/components/basic/text/TextV2';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import HorizontalFlex, {
  Gap8HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import permissionImage from '../../images/permission.png';

const UgcPermissionsContent = () => {
  return (
    <LayoutRoot>
      <LeftContainer>
        <Gap8VerticalFlex>
          <TextTiny>Description</TextTiny>
          <TextH4>
            Source your best user content to use across your channels. Manage the entire flow of
            reviewing videos, requesting usage rights, and automatically importing once the user
            responds with permission.
          </TextH4>
        </Gap8VerticalFlex>
        <VerticalFlex>
          <TextTiny>Videos</TextTiny>
          <ItemContainer addBottomBorder>
            <AtIcon />
            <TextSmall>Videos your brand is tagged in</TextSmall>
          </ItemContainer>
          <ItemContainer>
            <HashtagIcon />
            <TextSmall>Videos tagged with relevant hashtags</TextSmall>
          </ItemContainer>
        </VerticalFlex>
      </LeftContainer>
      <Image src={permissionImage} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)`
  gap: 32px;
`;

const LeftContainer = styled(VerticalFlex)`
  gap: 40px;
`;

const ItemContainer = styled(Gap8HorizontalFlex)<{ addBottomBorder?: boolean }>`
  padding: 0 8px;
  height: 48px;
  align-items: center;
  border-bottom: 1px solid
    ${({ theme, addBottomBorder }) =>
      addBottomBorder ? theme.colors.neutralLighter : 'transparent'};
`;

const Image = styled.img`
  max-height: 502px;
`;

export default UgcPermissionsContent;
