import React from 'react';
import Share from 'player/src/components/share/Share';
import styled from 'styled-components';
import SuccessButton from 'shared/react/components/basic/button/button/SuccessButton';
import { track } from 'player/src/tracker/Tracker';
import Utils from 'player/src/utils';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';
import { POWERED_BY_URL } from 'player/src/constants/urls.constants';

const LandingPageContent = ({
  landingPageLogoSrc,
  showAccountLogo,
  onLandingPageLogoError,
  state,
  projectId,
  isWhiteLabelEnabled,
}) => {
  const [, { t }] = useTranslation('interactive.landingPage');
  const showTolstoyDefaultLogo = landingPageLogoSrc && !isWhiteLabelEnabled;
  const showCustomLogo = landingPageLogoSrc && showAccountLogo;
  const showGetTolstoyForFreeButton = !isWhiteLabelEnabled;

  function handleTolstoyClick() {
    if (showAccountLogo) {
      return;
    }

    track('tolstoyClick');
    Utils.openInNewTab(POWERED_BY_URL);
  }

  const onSignUpClick = () => {
    track('viralLinkLandingPageClick');
    Utils.openInNewTab(
      'https://app.gotolstoy.com/signup?utm_source=watermark&utm_medium=player&utm_campaign=button'
    );
  };

  return (
    <>
      {(showTolstoyDefaultLogo || showCustomLogo) && (
        <LogoContainer
          src={landingPageLogoSrc}
          alt="tolstoy logo"
          showAccountLogo={showAccountLogo}
          onClick={handleTolstoyClick}
          onError={onLandingPageLogoError}
        />
      )}
      {projectId === 'a16bc09a-da75-4761-b1c9-fb01235eb7b7' ? (
        <PartnersContainer>
          <Share publishId={state.publishId} />
          <img className="tolstoy-logo" src={`${state.baseUrl}/logo/logo2.png`} alt="UPS" />
          <img className="tolstoy-logo" src={`${state.baseUrl}/logo/logo1.png`} alt="Yotpo" />
        </PartnersContainer>
      ) : (
        <RightHeaderContainer>
          <Share publishId={state.publishId} />
          {showGetTolstoyForFreeButton && (
            <GetTolstoyButton onClick={onSignUpClick}>{t('getTolstoyForFree')}</GetTolstoyButton>
          )}
        </RightHeaderContainer>
      )}
    </>
  );
};

const GetTolstoyButton = styled(SuccessButton)`
  width: 175px;
  height: 40px;
`;

const PartnersContainer = styled.div`
  margin-right: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const RightHeaderContainer = styled.div`
  margin-left: auto;
  margin-right: 64px;
  display: flex;
  gap: 8px;
`;

const LogoContainer = styled.img`
  width: ${({ showAccountLogo }) => (showAccountLogo ? '' : '150px')};
  margin: ${({ showAccountLogo }) => (showAccountLogo ? '10px 10px 10px 50px' : '0 16px 0 50px')};
  cursor: ${({ showAccountLogo }) => (showAccountLogo ? '' : 'pointer')};
  height: 40px;

  @media screen and (max-width: 450px), screen and (hover: none) and (pointer: coarse) {
    display: ${({ showAccountLogo }) => (showAccountLogo ? '' : 'none')};
  }
`;

export default LandingPageContent;
