import React from 'react';
import Select from './Select';
import Input from './Input';
import Checkbox from './Checkbox';

const DynamicComponent = ({ type, ...props }) => {
  switch (type) {
    case 'select':
      return <Select {...props} />;
    case 'input':
      return <Input {...props} />;
    case 'checkbox':
      return <Checkbox {...props} />;
  }
};

export default DynamicComponent;
