import React from 'react';
import EditorGroup from '../shared/EditorGroup';
import TileLayoutEditor from './TileLayoutEditor';
import TileVideoEditor from './TileVideoEditor';
import TilePlayButtonEditor from './TilePlayButtonEditor';

const TileEditorGroup = () => {
  return (
    <EditorGroup
      editors={[
        {
          key: 'layout',
          component: <TileLayoutEditor />,
        },
        {
          key: 'video',
          component: <TileVideoEditor />,
        },
        {
          key: 'playButton',
          component: <TilePlayButtonEditor />,
        },
      ]}
    />
  );
};

export default TileEditorGroup;
