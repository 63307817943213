export const getTime = (event, videoLengthContainerRef, duration) => {
  const percentageUsed =
    (event?.changedTouches?.[0]?.clientX ||
      event.clientX - videoLengthContainerRef.current?.getBoundingClientRect().left) /
    videoLengthContainerRef.current?.clientWidth;

  let time = percentageUsed * duration;

  if (percentageUsed < 0) {
    time = 0;
  }

  if (percentageUsed >= 1) {
    time = duration;
  }

  if (time > duration) {
    time = duration;
  }

  return time;
};
