import Hazard from 'app/src/images/Hazard';
import React from 'react';
import styled from 'styled-components';
import Text from 'shared/react/components/basic/Text';

const NoProductsPermissions = ({ updatePermissionsClicked }) => {
  return (
    <NoPermissionContainer>
      <PermissionsTitleContainer>
        <Hazard />
        <PermissionsTitle>Shopify permissions</PermissionsTitle>
      </PermissionsTitleContainer>
      <PermissionsText>
        In order to add product to you Tolstoy you need to update your permission settings in your
        <PermissionsLink onClick={updatePermissionsClicked}>
          Shopify account settings.
        </PermissionsLink>
      </PermissionsText>
    </NoPermissionContainer>
  );
};

const NoPermissionContainer = styled.div`
  display: grid;
  margin: 8px 16px;
  max-width: 465px;
`;

const PermissionsTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray4};
  padding-bottom: 8px;
  margin-bottom: 12px;
`;

const PermissionsTitle = styled(Text)`
  font-size: 16px;
  font-weight: 600;
  padding: 3px 8px;
`;

const PermissionsText = styled(Text)`
  font-size: 14px;
  max-width: 100%;
`;

const PermissionsLink = styled.a`
  font-size: 14px;
  font-weight: 600;
  margin-left: 4px;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
`;

export default NoProductsPermissions;
