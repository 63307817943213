import { API, graphqlOperation } from 'aws-amplify';
import { vodConnectionSuggestionByAppKey } from 'src/graphql/queries';
import { updateVodConnectionSuggestion as updateVodConnectionSuggestionMutation } from 'src/graphql/mutations';
import { onCreateVodConnectionSuggestionByAppKey } from 'src/graphql/subscriptions';
import { graphqlRequest } from 'src/helpers/API';
import {
  vodConnectionSuggestion as VodConnectionSuggestion,
  VodConnectionSuggestionStatus,
} from 'src/types/entities';
import Utils from 'src/utils';
import { Observable } from 'zen-observable-ts';

export const fetchPendingVodConnectionSuggestions = async ({
  appKey,
}): Promise<VodConnectionSuggestion[]> => {
  try {
    const pendingSuggestions = [];
    let nextToken = null;

    do {
      const response = await graphqlRequest(
        graphqlOperation(vodConnectionSuggestionByAppKey, {
          appKey,
          limit: 500,
          nextToken,
          filter: {
            status: {
              eq: VodConnectionSuggestionStatus.pending,
            },
          },
        })
      );

      pendingSuggestions.push(...response.data.vodConnectionSuggestionByAppKey.items);
      nextToken = response.data.vodConnectionSuggestionByAppKey.nextToken;
    } while (nextToken);

    return pendingSuggestions;
  } catch (error) {
    Utils.logError('Failed to fetch VodConnectionSuggestions', error);
    return [];
  }
};

export const getCreateVodConnectionSuggestionSubscription = ({ appKey }) => {
  return API.graphql(
    graphqlOperation(onCreateVodConnectionSuggestionByAppKey, { appKey })
  ) as Observable<object>;
};

export const updateVodConnectionSuggestion = async ({ id, status }) => {
  try {
    await API.graphql(
      graphqlOperation(updateVodConnectionSuggestionMutation, { input: { id, status } })
    );
  } catch (error) {
    Utils.logError('Failed to update VodConnectionSuggestion', error);
  }
};
