import React from 'react';
import AnalyticsEmailChartBox from './AnalyticsEmailChartBox';
import ChartHeader from '../chart/ChartHeader';
import ComposedChart from '../chart/ComposedChart';
import Utils from 'app/src/utils';
import { ANALYTICS_EMAIL_CHARTS } from 'app/src/constants/analytics.constants';
import { EmailEventsOvertimeRecord } from 'app/src/services/cube/types/getEmailEventsOvertimeQuery.types';
import AnalyticsEmailOpensTooltip from 'app/src/complex_components/analytics/email/AnalyticsEmailOpensTooltip';

type Props = {
  emailEventsOvertime: EmailEventsOvertimeRecord[] | null;
  isLoadingEmailEventsOvertime: boolean;
};

const AnalyticsEmailOpensOvertime = ({
  emailEventsOvertime,
  isLoadingEmailEventsOvertime,
}: Props) => {
  const { title, bars, lines } = ANALYTICS_EMAIL_CHARTS.EMAIL_OPENS;
  const totalEmailOpens = Utils.sumBy(emailEventsOvertime, record => record.numOfEmailOpens);
  const formattedTotalEmailOpens = Utils.formatNumber({ value: totalEmailOpens });

  const meanEmailOpens = Utils.meanBy(emailEventsOvertime, record => record.numOfEmailOpens);
  const referenceLines = [
    {
      y: meanEmailOpens,
      stroke: 'neutralGrey',
    },
  ];

  const yTickFormatter = (value: string) => {
    return Utils.formatNumber({ value });
  };

  return (
    <AnalyticsEmailChartBox shouldShowLoader={isLoadingEmailEventsOvertime}>
      <ChartHeader subtitle={formattedTotalEmailOpens} title={title} />
      <ComposedChart
        data={emailEventsOvertime || []}
        bars={bars}
        lines={lines}
        margin={{ left: -20 }}
        referenceLines={referenceLines}
        renderCustomTooltip={AnalyticsEmailOpensTooltip}
        shouldShowEmptyState={!emailEventsOvertime}
        yTickFormatter={yTickFormatter}
      />
    </AnalyticsEmailChartBox>
  );
};

export default AnalyticsEmailOpensOvertime;
