import { VodConnectionType, vodLabel as VodLabel } from 'app/src/types/entities';
import { getVodConnectionTypeKey } from 'app/src/utils/vodConnections.utils';
import { useVodLabelsActions } from 'src/context/VodLabelsStore';
import useCreateVodConnection from './vod-connections/useCreateVodConnection';

const useCreateIntegrationLabels = () => {
  const { getVodLabelByName, createVodLabel, getVodLabelById } = useVodLabelsActions();
  const { createVodConnection } = useCreateVodConnection();

  const createIntegrationLabels = async (
    labels: string[],
    video?
  ): Promise<VodLabel[] | undefined> => {
    if (!labels?.length) {
      return;
    }

    const promises = labels.map(
      (label: string) =>
        getVodLabelById(label) || getVodLabelByName(label) || createVodLabel({ name: label })
    );

    const currentLabels = await Promise.all(promises);

    if (!video) {
      return currentLabels;
    }

    const vodConnectionPromises = currentLabels.map(({ id: labelId }) => {
      const newConnection = {
        vodAssetId: video.id,
        typeKey: getVodConnectionTypeKey({ appKey: video.appKey, id: labelId }),
        type: VodConnectionType.vodLabelId,
        vodLabelId: labelId,
        productId: null,
        externalProductId: null,
        tag: null,
        appUrl: null,
        provider: null,
        vodSource: null,
        variantIds: [],
      };
      return createVodConnection(newConnection);
    });

    await Promise.all(vodConnectionPromises);
  };

  return { createIntegrationLabels };
};

export default useCreateIntegrationLabels;
