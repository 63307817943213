import React from 'react';
import { useTheme } from 'styled-components';

function MacroFull({ fill, props }) {
  const theme = useTheme();

  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 3.5C16 2.6875 15.3125 2 14.5 2H5.5C4.65625 2 4 2.6875 4 3.5V18L10 14.5L16 18V3.5Z"
        fill={fill || theme.colors.pink7}
      />
    </svg>
  );
}

export default MacroFull;
