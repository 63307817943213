import GlobalTheme from 'shared/react/theme/GlobalTheme';

export enum DesignSizes {
  XSMALL = 'xsmall',
  SMALL = 'small',
  NORMAL = 'normal',
  LARGE = 'large',
}

export enum DesignWeights {
  LIGHT = 'light',
  SOLID = 'solid',
  SQUARE = 'square',
}

export enum DesignStates {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning',
  INFO = 'info',
  VIOLET = 'violet',
  LIGHT = 'light',
  DARK = 'dark',
}

export const AppTheme = {
  ...GlobalTheme,
  designSizes: DesignSizes,
  designWeights: DesignWeights,
  designStates: DesignStates,
};
