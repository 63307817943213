import { useEffect, useRef, useState } from 'react';

function useOverflowDetector() {
  const [overflow, setOverflow] = useState<boolean>(false);
  const ref = useRef<HTMLElement>();

  const update = () => {
    if (ref.current == undefined) {
      return;
    }

    const newState =
      ref.current.offsetWidth < ref.current.scrollWidth ||
      ref.current.offsetHeight < ref.current.scrollHeight;
    if (newState === overflow) {
      return;
    }

    setOverflow(newState);
  };

  useEffect(() => {
    update();
  });

  return [ref, overflow] as const;
}

export default useOverflowDetector;
