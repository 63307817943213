import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { FEATURE_ECOM_FEED } from 'app/src/constants/appFeatures.constants';
import Routes from '../../../helpers/Routes';
import { track } from 'app/src/helpers/Tracker';
import PromotionBadge from './PromotionBadge';
import { useProjects } from 'app/src/context/ProjectsStore';
import { useHistory } from 'react-router-dom';
import { navigateToBuilder } from 'app/src/utils/navigation.utils';
import { useApps } from 'app/src/context/AppsStore';

const titles = [
  'Triple the average time people spend on your site with our new',
  'Increase conversions with shoppable video with our new',
  'Increase brand loyality by displaying video testimonials with our new',
];
const ECOM_TITLE_CHANGE_TIMEOUT = 60000;

const EcomFeedBadge = ({ tab }) => {
  const [, { createProject }] = useProjects();
  const [{ shopify }] = useApps();
  const [spinningTitleIndex, setSpinningTitleIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const history = useHistory();
  const shouldAddPaddingTop = tab !== Routes.getDashboardBaseRoute();

  const openFeedCreationFlow = async () => {
    setLoading(true);
    track('Create New Feed Click', {
      banner: true,
      location,
    });
    const project = await createProject({ isEcomFeed: true, appUrl: shopify?.appUrl });
    navigateToBuilder(history, project.id, '');
    setLoading(false);
  };

  useEffect(() => {
    const titlesInterval = setInterval(() => {
      setSpinningTitleIndex(curr => (curr + 1) % 3);
    }, ECOM_TITLE_CHANGE_TIMEOUT);

    return () => {
      clearInterval(titlesInterval);
    };
  }, []);

  return (
    <LayoutRoot shouldAddPaddingTop={shouldAddPaddingTop}>
      <PromotionBadge
        title="Swipeable Feed!"
        /* when the feed landing page goes live then uncomment:
        titleOnClick={navigateToFeedLandingPage}*/
        subtitle={titles[spinningTitleIndex]}
        backgroundColor={theme.colors.pink6}
        navigateBlue={openFeedCreationFlow}
        textBlue="Create a feed now"
        subtitleBeforeTitle={true}
        showIcon={false}
        featureKey={FEATURE_ECOM_FEED}
        disabled={loading}
      />
    </LayoutRoot>
  );
};

export default EcomFeedBadge;

const LayoutRoot = styled.div`
  margin-bottom: 12px;
  padding-top: ${({ shouldAddPaddingTop }) => (shouldAddPaddingTop ? '16px' : '')};
`;
