import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { useParams } from 'react-router-dom';
import { mergeWith, isArray } from 'lodash';
import { useProjects } from 'app/src/context/ProjectsStore';
import { useUser } from 'app/src/context/userStore/UserStore';
import ComponentWithLoader from 'app/src/complex_components/ComponentWithLoader';
import useQuery from 'app/src/hooks/useQuery';
import LookAndFeelEditor from 'app/src/pages/project/pages/look_and_feel/editor/LookAndFeelEditor';
import LookAndFeelPreview from 'app/src/pages/project/pages/look_and_feel/preview/LookAndFeelPreview';
import { LookAndFeelProvider } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import { useVideos } from 'app/src/context/VideosStore';
import Utils from 'app/src/utils';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import { FEATURE_EDIT_GIF_THUMBNAIL } from 'app/src/constants/appFeatures.constants';
import {
  EDITOR_CATEGORIES,
  EMAIL_TV_PAGE_HEADER_ID,
  SAVE_SETTINGS_ERROR_MESSAGE,
  SAVE_SETTINGS_SUCCESS_MESSAGE,
} from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';
import { getCustomizationSettings } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.service';
import { FEED_EMBEDS } from 'shared/react/constants/tolstoy.constants';
import useUpdateDirtyForm from 'app/src/hooks/useUpdateDirtyForm';
import useSelectPublishMethodModal from 'src/pages/modals/publish/hooks/useSelectPublishMethodModal';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { publishMethodOptions } from 'app/src/types/entities';
import { useApps } from 'app/src/context/AppsStore';

function LookAndFeelPage() {
  const theme = useTheme();
  const { projectId } = useParams();
  const category = useQuery().get('selectedCategory');
  const [{ shopify }, { updateHeroProject }] = useApps();
  const { getFeatureEnabled } = useFeatureActions();
  const [, { setSnackbar, setErrorSnackbar }] = useSnackBar();
  const [{ project }, { updateProject, publishProject }] = useProjects({ projectId });
  const { openSelectPublishMethodModalIfNeeded } = useSelectPublishMethodModal(project);
  const [{ account }] = useUser();
  const firstStep = project.steps.items.find(step => step.name === project.stepsOrder[0]) || {};
  const [{ video }, { trimVideo, updateVideo, getVideoById }] = useVideos(firstStep.videoId);
  const isEditThumbnailEnabled = getFeatureEnabled(FEATURE_EDIT_GIF_THUMBNAIL);
  const { player, branding } = useDesignSettingsContext();
  const [isUnsaved, setUnsaved] = useState(false);
  const [isEditingBubblePosition, setIsEditingBubblePosition] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [customizationSettings, setCustomizationSettings] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [startTime, setStartTime] = useState(0);
  const [editorRightLocation, setEditorRightLocation] = useState(547);
  const videoRef = useRef();
  const videosRefs = useRef([]);
  const videosToUpdateRef = useRef({});
  const [currThumbnailStepIndex, setCurrThumbnailStepIndex] = useState(0);
  const [currThumbnailStepStartTime, setCurrThumbnailStepStartTime] = useState(0);
  const prevHeaderIdRef = useRef();

  const getSelectedCategoryFromQueryParams = () => {
    if (category) {
      setSelectedCategory(category);
    }
  };

  const init = () => {
    const customizationSettings = getCustomizationSettings(
      project,
      player.branching.startScreenText,
      player.controls,
      theme,
      player.branching.contactForm,
      player.feedCards.text,
      player.feedCards.buttonsDesign.primary
    );
    if (customizationSettings) {
      prevHeaderIdRef.current = customizationSettings?.emailSettings[EMAIL_TV_PAGE_HEADER_ID];
      setCustomizationSettings(customizationSettings);
    }
  };

  useEffect(() => {
    getSelectedCategoryFromQueryParams();
  }, [category]);

  useEffect(() => {
    openSelectPublishMethodModalIfNeeded();

    init();
  }, [project, account]);

  useEffect(() => {
    setStartTime(JSON.parse(video?.trimSettings || '{}').gifStartTime);
  }, [video]);

  useEffect(() => {
    if (customizationSettings?.emailSettings[EMAIL_TV_PAGE_HEADER_ID]) {
      prevHeaderIdRef.current = customizationSettings?.emailSettings[EMAIL_TV_PAGE_HEADER_ID];
    }
  }, [customizationSettings?.emailSettings[EMAIL_TV_PAGE_HEADER_ID]]);

  async function saveProject(params) {
    if (!isChanged && params.target) {
      return;
    }

    let currProject = project;
    if (!params.target) {
      currProject = { ...currProject, ...params };
    }

    try {
      const newProject = await updateProject(currProject);
      if (newProject) {
        await publishProject(newProject.id);
        setSnackbar(SAVE_SETTINGS_SUCCESS_MESSAGE);
      } else {
        setErrorSnackbar(SAVE_SETTINGS_ERROR_MESSAGE);
      }

      setIsChanged(false);
    } catch (err) {
      Utils.logError('Failed to save look and feel settings', err);
      setErrorSnackbar('Failed to save settings.');
    }
  }

  const updateVideoTrimSettings = async (video, newStartTime) => {
    const trimSettings = JSON.parse(video?.trimSettings || '{}');
    const originalGifStartTime = trimSettings.gifStartTime;

    const isOriginalGifTimeNullAndHasTime =
      newStartTime && Utils.isNullOrUndefined(originalGifStartTime) && isEditThumbnailEnabled;

    const isTimeDifferent =
      !Utils.isNullOrUndefined(originalGifStartTime) &&
      !Utils.isNullOrUndefined(newStartTime) &&
      originalGifStartTime !== newStartTime &&
      isEditThumbnailEnabled;

    if (isOriginalGifTimeNullAndHasTime || isTimeDifferent) {
      const body = { endTime: 10, startTime: newStartTime, key: video.id, type: 'gif' };
      const newVideo = {
        ...video,
        trimSettings: JSON.stringify({ ...trimSettings, gifStartTime: newStartTime }),
      };

      await updateVideo(newVideo);
      await trimVideo(body);
    }
  };

  const handleEmbedUpdateTrims = async () => {
    const updateTrimPromises = Object.values(videosToUpdateRef.current).map(
      ({ currVideo, currVideoStartTime }) => {
        return updateVideoTrimSettings(currVideo, currVideoStartTime);
      }
    );

    videosToUpdateRef.current = {};
    await Promise.allSettled(updateTrimPromises);
  };

  async function handleSaveClick() {
    setSaving(true);

    if (customizationSettings) {
      const noText = customizationSettings.collectFields.find(field => {
        return !field.name;
      });

      if (noText) {
        setErrorSnackbar('Custom fields must have a name');
        setSaving(false);
        return;
      }
    }

    const newCustomizationSettings = {
      ...customizationSettings,
    };

    delete newCustomizationSettings.border;
    delete newCustomizationSettings.popupMode;

    newCustomizationSettings.widgetSettings = JSON.stringify(
      newCustomizationSettings.widgetSettings
    );
    newCustomizationSettings.playerSettings = JSON.stringify(
      newCustomizationSettings.playerSettings
    );

    newCustomizationSettings.heroSettings = JSON.stringify({
      ...newCustomizationSettings.heroSettings,
      branding,
    });
    newCustomizationSettings.emailSettings = JSON.stringify(newCustomizationSettings.emailSettings);
    newCustomizationSettings.videoCollectionCampaignSettings = JSON.stringify(
      newCustomizationSettings.videoCollectionCampaignSettings
    );

    await saveProject(newCustomizationSettings);

    if (project.publishMethod === publishMethodOptions.hero && shopify) {
      await updateHeroProject(project.id);
    }

    if (FEED_EMBEDS.includes(project.publishMethod)) {
      await handleEmbedUpdateTrims();
    } else {
      await updateVideoTrimSettings(video, startTime);
    }

    if (customizationSettings?.emailSettings[EMAIL_TV_PAGE_HEADER_ID] !== prevHeaderIdRef.current) {
      console.log('headerId has changed');
    }

    setSaving(false);
    setUnsaved(false);
    setIsEditingBubblePosition(false);
  }

  function setWidgetSettingsProperty(key, value) {
    const newCustomizationSettings = { ...customizationSettings };
    const { widgetSettings } = newCustomizationSettings;
    widgetSettings[key] = value;
    setCustomizationSettings(newCustomizationSettings);
    setUnsaved(true);
  }

  const setHeroSettingsProperty = (key, value) => {
    const newCustomizationSettings = { ...customizationSettings };
    const { heroSettings } = newCustomizationSettings;
    heroSettings[key] = value;
    setCustomizationSettings(newCustomizationSettings);
    setUnsaved(true);
  };

  function setPlayerSettingsProperty(key, value) {
    const newCustomizationSettings = { ...customizationSettings };
    const { playerSettings } = newCustomizationSettings;
    playerSettings[key] = value;
    setCustomizationSettings(newCustomizationSettings);
    setUnsaved(true);
  }

  function setCustomizationSettingsProperty(key, value) {
    const newCustomizationSettings = { ...customizationSettings };
    newCustomizationSettings[key] = value;
    setCustomizationSettings(newCustomizationSettings);
    setUnsaved(true);
  }

  function setEmailSettingsProperty(key, value) {
    const newCustomizationSettings = { ...customizationSettings };
    const { emailSettings } = newCustomizationSettings;
    emailSettings[key] = value;
    setCustomizationSettings(newCustomizationSettings);
    setUnsaved(true);
  }

  function updateCustomizationSettings(data) {
    const updatedCustomizationSettings = mergeWith({}, customizationSettings, data, (_, b) =>
      isArray(b) ? b : undefined
    );
    setCustomizationSettings(updatedCustomizationSettings);
    setUnsaved(true);
  }

  const onCancel = () => {
    init();
    setStartTime(JSON.parse(video?.trimSettings || '{}').gifStartTime);
    if (FEED_EMBEDS.includes(project.publishMethod) && project.steps?.items?.length) {
      const currVideo = getVideoById(project.steps.items?.[currThumbnailStepIndex]?.videoId);
      if (currVideo) {
        setCurrThumbnailStepStartTime(JSON.parse(currVideo?.trimSettings || '{}').gifStartTime);
      }
    }

    setUnsaved(false);
    setIsEditingBubblePosition(false);
    videosToUpdateRef.current = {};
  };

  const onSetStartTime = value => {
    setUnsaved(true);
    setStartTime(value);
  };

  const getProviderValue = () => {
    return {
      project,
      customizationSettings,
      updateCustomizationSettings,
      setCustomizationSettingsProperty,
      setWidgetSettingsProperty,
      setPlayerSettingsProperty,
      setHeroSettingsProperty,
      setEmailSettingsProperty,
      setUnsaved,
      selectedCategory,
      setSelectedCategory,
      categories: EDITOR_CATEGORIES,
      startTime,
      setStartTime: onSetStartTime,
      isEditThumbnailEnabled,
      editorRightLocation,
      setEditorRightLocation,
      videoRef,
      videosRefs,
      videosToUpdateRef,
      setIsEditingBubblePosition,
      isEditingBubblePosition,
      isSaving,
      playerBranchingSettings: player.branching,
      currThumbnailStepIndex,
      setCurrThumbnailStepIndex,
      currThumbnailStepStartTime,
      setCurrThumbnailStepStartTime,
    };
  };

  const onSaveClickCallback = useCallback(handleSaveClick, [
    customizationSettings,
    project,
    video,
    startTime,
    isEditThumbnailEnabled,
    currThumbnailStepIndex,
    currThumbnailStepStartTime,
  ]);

  useUpdateDirtyForm(isUnsaved, {
    isLoading: isSaving,
    onSaveClick: onSaveClickCallback,
    onDiscardClick: onCancel,
  });

  return (
    <LookAndFeelProvider value={getProviderValue()}>
      <LayoutRoot isSaving={isSaving || isEditingBubblePosition}>
        <ComponentWithLoader
          isLoading={!customizationSettings || !project}
          renderLoader={indicator => (
            <LoadingIndicatorContainer>{indicator}</LoadingIndicatorContainer>
          )}
        >
          <MainContainer>
            <LookAndFeelEditor />
            <LookAndFeelPreview />
          </MainContainer>
        </ComponentWithLoader>
      </LayoutRoot>
    </LookAndFeelProvider>
  );
}

export default LookAndFeelPage;

const LayoutRoot = styled.div`
  height: 100%;
  pointer-events: ${({ isSaving }) => isSaving && 'none'};
`;

const MainContainer = styled(HorizontalFlex)`
  gap: 36px;
  height: 100%;
  overflow: hidden;

  @media (${({ theme }) => theme.breakpoints.previewMax}) {
    gap: 0px;
  }
`;

const LoadingIndicatorContainer = styled.div`
  padding: 24px;
`;
