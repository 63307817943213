import React from 'react';

const TiktokSmallIcon = () => {
  return (
    <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 6.563c-1.406.03-2.719-.407-3.844-1.22v5.595c0 3.906-4.25 6.343-7.625 4.406C.156 13.375.156 8.5 3.531 6.53a5.103 5.103 0 0 1 3.25-.625V8.72c-1.718-.563-3.375.969-3 2.719.406 1.75 2.531 2.437 3.875 1.218.469-.437.75-1.062.75-1.719V0h2.75c0 .25 0 .469.063.719a3.695 3.695 0 0 0 1.687 2.5A3.748 3.748 0 0 0 15 3.844v2.719Z"
        fill="#fff"
      />
    </svg>
  );
};

export default TiktokSmallIcon;
