import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { useFolders } from 'app/src/context/FoldersStore';
import { track } from 'app/src/helpers/Tracker';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { useProjects } from 'app/src/context/ProjectsStore';
import { Container, Modal } from 'app/src/styled_components';
import Button from 'shared/react/components/complex/Button';
import PlusIcon from 'app/src/images/PlusIcon';
import Types from 'shared/react/theme/Types';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import { TextH5, TextSmall } from 'shared/react/components/complex/Text';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import BasicSelect from 'shared/react/components/basic/basic_select/BasicSelect';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { RENAME_FOLDER_MODAL_KEY } from 'app/src/constants/modals.constants';
import { MODAL_STATE } from 'app/src/pages/modals/folder_modal/folders.constants';
import { FolderType } from 'app/src/types/entities';
import { useVideos } from 'app/src/context/VideosStore';

const FOLDER_TEXT = {
  [FolderType.videos]: 'Video',
  [FolderType.projects]: 'Project',
  [FolderType.onsites]: 'Project',
};

const MoveFolderModal = () => {
  const [, { getDisplayedFoldersV2 }] = useFolders();
  const [
    {
      modalProps: { ids, type, appUrl },
    },
    { setCurrentModal, setCurrentModalType, clearModalState, setModalProps },
  ] = useModal();
  const [, { updateVideo, getVideoById }] = useVideos();
  const [{ project }, { updateProject, getProjectById }] = useProjects({
    projectId: ids[0],
  });

  const selectedProjects = ids?.flatMap(id => {
    return getProjectById(id) || [];
  });

  const folders = getDisplayedFoldersV2(type);
  const selectedProjectFolder = folders?.find(folder => folder?.id === project?.folder);

  const [folder, setFolder] = useState(selectedProjectFolder || '');
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const selectOptions = [...folders, { id: null, name: 'No folder' }];

  const handleCreateNewFolder = () => {
    setCurrentModal(RENAME_FOLDER_MODAL_KEY);
    setCurrentModalType(MODAL_STATE.new);
    setModalProps({ type: type || FolderType.projects, ids, appUrl });
  };

  const moveProject = async (folder = null) => {
    if (!project) {
      return;
    }

    const promises = selectedProjects.map(project => {
      const data = { ...project, folder };

      return updateProject(data);
    });

    return Promise.all(promises);
  };

  const moveVideos = async (folder = null) => {
    const videos = ids?.flatMap(id => getVideoById(id) || []);

    const promises = videos.map(video => {
      const data = { ...video, folder };
      return updateVideo(data);
    });

    return Promise.all(promises);
  };

  const onClose = () => {
    clearModalState();
  };

  const handleMoveFolder = async ({ folder }) => {
    setLoading(true);
    if (type === FolderType.videos) {
      await moveVideos(folder?.id);
    } else {
      await moveProject(folder?.id);
    }
    onClose();
  };

  const handleSave = async () => {
    if (folder !== selectedProjectFolder) {
      track('Move Project To Folder Click');
    }
    await handleMoveFolder({ folder });
  };

  return (
    <Modal open={true} onClose={onClose} width="340px">
      <ModalContainer>
        <HeaderContainer>
          <HeaderText>Move {FOLDER_TEXT[type] || 'Tolstoy'}</HeaderText>
          <Button
            showLeftIcon={true}
            leftIcon={<PlusIcon fill={theme.colors.blue12} />}
            type={Types.Secondary}
            onClick={handleCreateNewFolder}
          >
            New folder
          </Button>
        </HeaderContainer>
        <MoveSelectors>
          <MoveToFolder>
            <InputHeader>Folder name</InputHeader>
            <CustomSelect
              value={folder?.name || selectedProjectFolder?.name || 'Choose a folder'}
              items={selectOptions}
              onChange={folder => setFolder(folder)}
            />
          </MoveToFolder>
        </MoveSelectors>
        <ButtonsContainer>
          <Button disabled={loading} onClick={onClose} type={Types.Ghost}>
            Cancel
          </Button>
          <Button loading={loading} onClick={handleSave}>
            Move
          </Button>
        </ButtonsContainer>
      </ModalContainer>
    </Modal>
  );
};

const ModalContainer = styled(Container)`
  min-width: 500px;
  padding: 24px;
  gap: 40px;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    min-width: 200px;
  } ;
`;

const HeaderContainer = styled(HorizontalFlexWrap)`
  justify-content: space-between;
`;

const HeaderText = styled(TextH5)``;

const ButtonsContainer = styled(Gap8HorizontalFlexWrap)`
  justify-content: flex-end;
`;

const CustomSelect = styled(BasicSelect)`
  width: 100%;
  font-size: 16px;
  font-weight: 400;
`;

const InputHeader = styled(TextSmall)`
  font-weight: 700;
  margin-bottom: 4px;
`;

const MoveSelectors = styled(VerticalFlex)`
  gap: 24px;
`;

const MoveToFolder = styled.div``;

export default MoveFolderModal;
