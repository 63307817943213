import React from 'react';
import styled from 'styled-components';

function Dot({ ...props }) {
  return <LayoutRoot {...props} />;
}

export default Dot;

const LayoutRoot = styled.div`
  height: 12px;
  width: 12px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  display: inline-block;
`;
