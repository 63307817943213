import CopyCodeButtonPricing from 'app/src/complex_components/pricing/CopyCodeButtonPricing';
import { TextBody, TextH6 } from 'shared/react/components/complex/Text';
import Stepper from 'app/src/complex_components/Stepper';
import React from 'react';
import styled from 'styled-components';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import SetLiveTogglePricing from 'app/src/complex_components/pricing/SetLiveTogglePricing';
import Utils from 'app/src/utils';
import { track } from 'app/src/helpers/Tracker';
import { SetLiveContainer } from 'app/src/pages/modals/publish/widget/common';
import { useUser } from 'app/src/context/userStore/UserStore';
import { useBilling } from 'app/src/hooks/useBillings';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';

const WidgetStepper = ({ live, project, loading, onSetLive }) => {
  const [{ user, account }] = useUser();
  const { hasReachedLiveWidgetsLimit } = useBilling();
  const [, { setSnackbar }] = useSnackBar();

  function copyCode() {
    Utils.copyToClipboard(getCode());
    setSnackbar('Copied to Clipboard!');
  }

  function getCode() {
    track('Copy Code Click', { location: 'share' });
    const appKey = account?.appKey || user?.appKey || '';
    return `
    <script>tolstoyAppKey="${appKey}"</script>
    <script type="module"
      async
      src="https://widget.gotolstoy.com/we/widget.js"
    >
    </script>
    <script
      type="text/javascript"
      nomodule
      async
      src="https://widget.gotolstoy.com/widget/widget.js"
    ></script>`;
  }

  return (
    <Stepper
      stepComponents={[
        <FirstStep key={1}>
          Copy the code
          <CopyCodeContainer>
            <CopyCodeButtonPricing
              disabled={!project || (!live && hasReachedLiveWidgetsLimit)}
              onClick={copyCode}
              location="Widget"
              tooltipHeader="widget"
            />
          </CopyCodeContainer>
        </FirstStep>,
        <div key={2}>
          <EmailStepperText>Paste the code into your header</EmailStepperText>
        </div>,
        <div key={3}>
          <SetLiveContainer>
            <TextH6>Set live</TextH6>
            <SetLiveTogglePricing
              disabled={loading}
              checked={live}
              onChange={onSetLive}
              loading={loading}
            />
          </SetLiveContainer>
        </div>,
      ]}
    />
  );
};

export const FirstStep = styled(Gap8HorizontalFlexWrap)`
  font-weight: 800;
  font-size: 16px;
`;

export const EmailStepperText = styled(TextBody)`
  font-weight: 800;
  color: ${({ theme }) => theme.colors.black};
`;

const CopyCodeContainer = styled.div`
  margin-top: -10px;
`;

export default WidgetStepper;
