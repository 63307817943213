import React from 'react';
import styled from 'styled-components';
import Input from 'material-ui-chip-input';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  chipContainer: {
    display: 'flex',
    overflow: 'scroll',
  },
  clickable: {
    width: '100%',
    overflow: 'unset',
    margin: ' 0 8px 2px 0',
  },
});

const ChipInput = props => {
  const classes = useStyles();
  return (
    <CustomChipInput
      classes={{ chipContainer: props.value?.length ? classes.chipContainer : '' }}
      allowDuplicates={false}
      {...props}
    />
  );
};

const CustomChipInput = styled(Input)`
  .MuiChip-clickable {
    margin: ${({ variant }) => (variant === 'standard' ? '6px 0 2px' : '')};
  }
`;

export default ChipInput;
