import { useEffect } from 'react';
import useFeedState from 'shared/react/components/complex/context/hooks/useFeedState';

const isBehindOtherElement = element => {
  if (!element) {
    return;
  }
  const boundingRect = element.getBoundingClientRect();
  // adjust coordinates to get more accurate results
  const left = boundingRect.left + 1;
  const right = boundingRect.right - 1;
  const top = boundingRect.top + 1;
  const bottom = boundingRect.bottom - 1;

  if (document.elementFromPoint(left, top) !== element) return true;
  if (document.elementFromPoint(right, top) !== element) return true;
  if (document.elementFromPoint(left, bottom) !== element) return true;
  if (document.elementFromPoint(right, bottom) !== element) return true;

  return false;
};

const useKeyboardNavigation = onClose => {
  const [{ buttonsRef, buttonsRefLength, setButtonsRefLength }] = useFeedState();

  const previous = currentIndex => {
    const previousIndex = currentIndex - 1;
    const previousRefLength = buttonsRefLength - 1;

    const newIndex = previousIndex < 0 ? previousRefLength : previousIndex;
    const currentButton = buttonsRef.current[newIndex.toString()];

    if (currentButton?.disabled || !currentButton) {
      previous(newIndex);
      return;
    }

    currentButton?.focus();
  };

  const next = currentIndex => {
    const nextIndex = currentIndex + 1;
    const previousRefLength = buttonsRefLength - 1;

    const newIndex = nextIndex > previousRefLength ? 0 : nextIndex;
    const currentButton = buttonsRef.current[newIndex.toString()];
    if (currentButton?.disabled || !currentButton) {
      next(newIndex);
      return;
    }

    currentButton?.focus();
  };

  const findKey = element => {
    const res = Object.entries(buttonsRef.current).find(([_, value]) => {
      if (element === value) {
        return true;
      }
    });

    if (!res) {
      return null;
    }

    return +res[0];
  };

  const onKeyDown = (e, ...props) => {
    if (!Object.keys(buttonsRef?.current).length) {
      return;
    }

    const index = findKey(e.target);

    if (index === null) {
      return;
    }

    switch (e.key) {
      case 'Enter':
        e.target.click();
        e.preventDefault();
        break;
      case 'ArrowUp':
      case 'ArrowLeft':
        previous(index, ...props);
        e.preventDefault();
        break;
      case 'ArrowRight':
      case 'ArrowDown':
        // Lock tab navigation inside the Tolstoy Player
        // case 'Tab':
        next(index, ...props);
        e.preventDefault();
        break;
    }
  };

  const setNextRef = ref => {
    if (!ref) {
      return;
    }

    const isExists = Object.values(buttonsRef.current).find(value => {
      return value === ref;
    });

    if (isExists) {
      return;
    }

    const length = Object.keys(buttonsRef.current).length;

    buttonsRef.current[length] = ref;
    setButtonsRefLength(length + 1);
  };

  const focusOnElement = (index = 0) => {
    buttonsRef.current?.[index]?.focus?.();
  };

  const resetRefs = () => {
    buttonsRef.current = {};
    setButtonsRefLength(0);
  };

  const isSelected = () => {
    return !!findKey(window.document.activeElement);
  };

  useEffect(() => {
    const onEscapeClick = e => {
      if (e.key === 'Escape') {
        onClose?.();
      }

      if (e.key === 'Tab' && !isBehindOtherElement(buttonsRef.current[0]) && !isSelected()) {
        focusOnElement();
        e.preventDefault();
      }
    };

    window.addEventListener('keydown', onEscapeClick);
    return () => window.removeEventListener('keydown', onEscapeClick);
  }, [onClose]);

  return { onKeyDown, setNextRef, focusOnElement, resetRefs };
};

export default useKeyboardNavigation;
