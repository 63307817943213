import React, { useEffect, useRef } from 'react';
import { track } from 'app/src/helpers/Tracker';
import { useBilling } from 'app/src/hooks/useBillings';
import styled from 'styled-components';
import Utils from '../../../../../utils';
import { safeScrollIntoViewIfNeeded } from 'app/src/utils/browser.utils';
import EditorMainContainer from '../../../../project/pages/look_and_feel/editor/editors/shared/EditorMainContainer';
import { useBrandingContext } from '../../BrandingContext';
import BrandingFontsV2 from '../../fonts/v2/BrandingFonts';
import EditorItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorItem';
import ScrollHereDiv from '../ScrollHereDiv';
import EditorSubheader from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorSubheader';
import { DEFAULT_DESIGN_SETTINGS } from 'shared/react/constants/design/defaultDesignSettings';
import BrandingExpander from 'app/src/pages/account/branding/editors/BrandingExpander';

const { font: DEFAULT_FONT, fontHeading: DEFAULT_FONT_HEADING } =
  DEFAULT_DESIGN_SETTINGS.branding.typography;

const FontEditor = ({
  settings = {},
  features = {},
  defaultSettings = {},
  onChange = () => {},
}) => {
  const { font, fontHeading } = settings;
  const { isPlayerLanguageEnabled, isAccountCustomFontUploadEnabled } = features;

  const { loading, scrollTo } = useBrandingContext();
  const { isFreePackage } = useBilling();
  const ref = useRef();

  const tooltipProps = {
    analyticsData: Utils.getAnalyticsLocationObject('Branding font Tooltip'),
    header: 'Brand font',
    shouldHide: isPlayerLanguageEnabled,
  };

  const updateSettings = update => onChange({ ...settings, ...update });

  const onFontHeadingChange = font => updateSettings({ fontHeading: font || DEFAULT_FONT });
  const onFontChange = font => updateSettings({ font: font || DEFAULT_FONT_HEADING });
  const handleResetFontHeadingToDefault = () => {
    track('Branding Font Reset To Default');

    updateSettings({ fontHeading: DEFAULT_FONT_HEADING });
  };
  const handleResetFontToDefault = () => {
    track('Branding Font Reset To Default');

    updateSettings({ font: DEFAULT_FONT });
  };

  useEffect(() => {
    if (scrollTo === 'font') {
      setTimeout(() => {
        safeScrollIntoViewIfNeeded(ref.current);
      }, 0);
    }
  }, [scrollTo]);

  const shouldHide = isFreePackage;

  if (shouldHide) {
    return null;
  }

  const disabled = loading;

  return (
    <BrandingExpander title="Typography">
      <ContainerWithBorderBottom tooltipProps={tooltipProps} disabled={disabled}>
        <EditorSubheader title="Heading" />
        <EditorItem text="Font family">
          <BrandingFontsV2
            font={fontHeading}
            loading={loading}
            disabled={disabled}
            weight="bold"
            isFontUploadEnabled={isAccountCustomFontUploadEnabled}
            onChange={onFontHeadingChange}
            onResetFont={handleResetFontHeadingToDefault}
          />
        </EditorItem>
      </ContainerWithBorderBottom>
      <ContainerWithBorderBottom tooltipProps={tooltipProps} disabled={disabled}>
        <EditorSubheader title="Body" />
        <EditorItem text="Font family">
          <BrandingFontsV2
            font={font}
            loading={loading}
            disabled={disabled}
            isFontUploadEnabled={isAccountCustomFontUploadEnabled}
            onChange={onFontChange}
            onResetFont={handleResetFontToDefault}
          />
        </EditorItem>
      </ContainerWithBorderBottom>
      <ScrollHereDiv ref={ref} />
    </BrandingExpander>
  );
};

const ContainerWithBorderBottom = styled(EditorMainContainer)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray3};
`;

export default FontEditor;
