import React from 'react';

const RedirectToLink = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.219 9.5c-.383 0-.656.3-.656.656v3.063c0 .136-.11.219-.22.219H2.032c-.136 0-.219-.083-.219-.22V4.907c0-.11.083-.218.22-.218h3.062a.648.648 0 0 0 .656-.657c0-.355-.3-.656-.656-.656H2.03A1.544 1.544 0 0 0 .5 4.906v8.313a1.53 1.53 0 0 0 1.531 1.531h8.313c.82 0 1.531-.684 1.531-1.531v-3.063c0-.355-.3-.656-.656-.656ZM13.844.75H9.25c-.383 0-.656.3-.656.656 0 .383.273.657.656.657h3.008l-6.754 6.78a.599.599 0 0 0 0 .903c.11.137.273.191.465.191a.544.544 0 0 0 .437-.19l6.782-6.755V6c0 .383.273.656.656.656A.648.648 0 0 0 14.5 6V1.406c0-.355-.3-.656-.656-.656Z"
        fill="#3D4FE0"
      />
    </svg>
  );
};

export default RedirectToLink;
