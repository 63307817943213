/** This function loadFont is duplicated from SharedUtils.loadFont */
function loadFont(sources, fontFamily) {
  const ITALIC_VALUE = 'italic';
  const NORMAL_VALUE = 'normal';
  const OTF_VALUE = '.otf';

  const newStyle = document.createElement('style');
  for (const sourceKey of Object.keys(sources)) {
    const url = sources[sourceKey];
    const isItalic = sourceKey.includes(ITALIC_VALUE);
    const fontWeight = sourceKey.replace(ITALIC_VALUE, '') || NORMAL_VALUE;
    const fontStyle = isItalic ? ITALIC_VALUE : NORMAL_VALUE;
    const isOTF = url.includes(OTF_VALUE);
    const fontFormat = isOTF ? 'opentype' : 'truetype';
    const httpsUrl = url.replace('http://', 'https://');

    newStyle.append(
      document.createTextNode(
        `@font-face { font-family: '${fontFamily}'; src: url("${httpsUrl}") format("${[
          fontFormat
        ]}"); font-weight: ${fontWeight}; font-style: ${fontStyle};}`
      )
    );
  }

  document.head.append(newStyle);
}

const setFontFamily = (fontFamily, retry = 1) => {
  if (retry === 3) {
    return;
  }

  if (!document.querySelector('#tolstoyWidgetElement')) {
    setTimeout(() => {
      setFontFamily(fontFamily, retry + 1);
    }, 1000);
    return;
  }

  document.querySelector('#tolstoyWidgetElement').style.fontFamily = fontFamily;
};

export function reloadFont(fontCustomizationSettings) {
  if (!fontCustomizationSettings) {
    return;
  }

  const { sources } = fontCustomizationSettings;
  const customFontFamily = 'tolstoy-custom-font-family';

  loadFont(sources, customFontFamily);

  setFontFamily(customFontFamily);
}
