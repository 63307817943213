import * as React from 'react';

const InfoIconV2 = ({ ...props }) => (
  <svg
    width={12}
    height={13}
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 .875A5.626 5.626 0 0 0 .375 6.5 5.611 5.611 0 0 0 6 12.125 5.626 5.626 0 0 0 11.625 6.5C11.625 3.406 9.094.875 6 .875Zm0 10.5A4.871 4.871 0 0 1 1.125 6.5c0-2.672 2.18-4.875 4.875-4.875 2.672 0 4.875 2.203 4.875 4.875 0 2.695-2.203 4.875-4.875 4.875Zm0-6.563a.555.555 0 0 0 .563-.562A.57.57 0 0 0 6 3.687a.555.555 0 0 0-.563.563c0 .328.235.563.563.563ZM7.125 8.75h-.75V6.125A.403.403 0 0 0 6 5.75h-.75a.385.385 0 0 0-.375.375c0 .21.164.375.375.375h.375v2.25h-.75a.385.385 0 0 0-.375.375c0 .21.164.375.375.375h2.25a.385.385 0 0 0 .375-.375.403.403 0 0 0-.375-.375Z"
      fill="#090A0B"
    />
  </svg>
);

export default InfoIconV2;
