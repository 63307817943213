import MuteIcon from "../icons/MuteIcon";
import UnmuteIcon from "../icons/UnMuteIcon";
import { FC } from "preact/compat";

type Props = {
  setIsMuted: (isMuted: boolean) => void;
  isMuted: boolean;
  includeMuteButton: boolean;
};

const OverlayMuteButton: FC<Props> = ({
  setIsMuted,
  isMuted,
  includeMuteButton,
}) => {
  const toggleIsMuted = (e) => {
    e.stopPropagation();
    setIsMuted(!isMuted);
  };

  if (!includeMuteButton) {
    return;
  }

  return (
    <button onClick={toggleIsMuted} className={`hero-overlay-mute-button`}>
      {isMuted ? <MuteIcon /> : <UnmuteIcon />}
    </button>
  );
};

export default OverlayMuteButton;
