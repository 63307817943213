import React from 'react';
import styled from 'styled-components';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import {
  STORIES_ALIGNMENT_PROPERTY_KEY,
  STORIES_BORDER_RADIUS_PROPERTY_KEY,
  STORIES_ALIGNMENTS,
  EMBED_STORY_MOTION,
  STORIES_MOTION_PROPERTY_KEY,
  STORIES_MAX_BORDER_RADIUS,
  STORIES_MIN_BORDER_RADIUS,
} from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import AlignCenterIcon from 'app/src/images/alignment_icons/AlignCenterIcon';
import AlignLeftIcon from 'app/src/images/alignment_icons/AlignLeftIcon';
import AlignRightIcon from 'app/src/images/alignment_icons/AlignRightIcon';
import EditorButtonGroupItem from '../shared/EditorButtonGroupItem';
import EditorItem from '../shared/EditorItem';
import EditorMainContainer from '../shared/EditorMainContainer';
import EditorNumericInputItem from '../shared/EditorNumericInputItem';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const FeedStoriesGeneralEditor = () => {
  const {
    customizationSettings: {
      widgetSettings: { storiesBorderRadius, storiesAlignment, storiesMotion },
    },
    setWidgetSettingsProperty,
  } = useLookAndFeelContext();

  const onBorderRadiusChange = value => {
    const validatedValue = Math.min(
      Math.max(value, STORIES_MIN_BORDER_RADIUS),
      STORIES_MAX_BORDER_RADIUS
    );
    setWidgetSettingsProperty(STORIES_BORDER_RADIUS_PROPERTY_KEY, validatedValue);
  };

  const options = [
    {
      icon: <AlignLeftIcon />,
      isEnabled: storiesAlignment === STORIES_ALIGNMENTS.left,
      onClick: () =>
        setWidgetSettingsProperty(STORIES_ALIGNMENT_PROPERTY_KEY, STORIES_ALIGNMENTS.left),
    },
    {
      icon: <AlignCenterIcon />,
      isEnabled: storiesAlignment === STORIES_ALIGNMENTS.center,
      onClick: () =>
        setWidgetSettingsProperty(STORIES_ALIGNMENT_PROPERTY_KEY, STORIES_ALIGNMENTS.center),
    },
    {
      icon: <AlignRightIcon />,
      isEnabled: storiesAlignment === STORIES_ALIGNMENTS.right,
      onClick: () =>
        setWidgetSettingsProperty(STORIES_ALIGNMENT_PROPERTY_KEY, STORIES_ALIGNMENTS.right),
    },
  ];

  const motionButtons = [
    {
      text: 'Static',
      isEnabled: storiesMotion === EMBED_STORY_MOTION.static,
      onClick: () =>
        setWidgetSettingsProperty(STORIES_MOTION_PROPERTY_KEY, EMBED_STORY_MOTION.static),
    },
    {
      text: 'Dynamic',
      isEnabled: storiesMotion === EMBED_STORY_MOTION.dynamic,
      onClick: () =>
        setWidgetSettingsProperty(STORIES_MOTION_PROPERTY_KEY, EMBED_STORY_MOTION.dynamic),
    },
  ];

  return (
    <LayoutRoot>
      <EditorMainContainer>
        <EditorNumericInputItem
          text="Border radius"
          suffix="px"
          onChange={onBorderRadiusChange}
          value={storiesBorderRadius}
        />
        <EditorItem text="Alignment">
          <EditorButtonGroupItem buttons={options} />
        </EditorItem>
        <EditorItem text="Motion">
          <EditorButtonGroupItem buttons={motionButtons} />
        </EditorItem>
      </EditorMainContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

export default FeedStoriesGeneralEditor;
