import React from 'react';
import styled, { css } from 'styled-components';
import Tooltip from 'app/src/basic_components/Tooltip';
import { OverlayIconButton } from 'app/src/basic_components/commonStyles';
import { PREVIEW_DESKTOP_KEY, PREVIEW_MOBILE_KEY } from 'app/src/constants/tolstoy.constants';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import DesktopIcon from 'app/src/images/DesktopIcon';
import MobileIcon from 'app/src/images/MobileIcon';
import { track } from 'app/src/helpers/Tracker';
import Separator from 'shared/react/components/basic/Separator';

function MobileDesktopPreviewToggle({
  selectedPreviewMode,
  setSelectedPreviewMode,
  leftTooltipText,
  rightTooltipText,
  ...props
}) {
  return (
    <LayoutRoot {...props}>
      <StyledVerticalFlex>
        <Tooltip text={leftTooltipText || 'Set to horizontal'}>
          <LeftOverlayIconButton
            name={PREVIEW_DESKTOP_KEY}
            onClick={() => {
              track('Recording Desktop Selected');
              setSelectedPreviewMode(PREVIEW_DESKTOP_KEY);
            }}
          >
            <StyledDesktopIcon
              selected={selectedPreviewMode === PREVIEW_DESKTOP_KEY}
              fill="white"
            />
          </LeftOverlayIconButton>
        </Tooltip>
      </StyledVerticalFlex>
      <StyledSeparator />
      <StyledVerticalFlex>
        <Tooltip text={rightTooltipText || 'Set to vertical'}>
          <RightOverlayIconButton
            name={PREVIEW_MOBILE_KEY}
            onClick={() => {
              track('Recording Mobile Selected');
              setSelectedPreviewMode(PREVIEW_MOBILE_KEY);
            }}
          >
            <StyledMobileIcon selected={selectedPreviewMode === PREVIEW_MOBILE_KEY} fill="white" />
          </RightOverlayIconButton>
        </Tooltip>
      </StyledVerticalFlex>
    </LayoutRoot>
  );
}

export default MobileDesktopPreviewToggle;

const LayoutRoot = styled.div`
  grid-row: 1;
  grid-column: 1;
  display: flex;
  flex-direction: row;
  justify-self: center;
  overflow: hidden;
  height: fit-content;
  z-index: 3;
  visibility: ${({ verticalOrientation }) => (verticalOrientation ? 'hidden' : 'visible')};

  @media (max-width: 450px) {
    visibility: hidden;
  }
`;

const StyledVerticalFlex = styled(VerticalFlex)`
  z-index: 4;
  align-items: center;
  justify-items: center;
  justify-content: center;
  height: 100%;
`;

const shared = ({ selected }) => css`
  opacity: ${selected ? 1 : 0.6};
`;

const StyledDesktopIcon = styled(DesktopIcon)`
  ${shared};
`;

const StyledMobileIcon = styled(MobileIcon)`
  ${shared};
`;

const RightOverlayIconButton = styled(OverlayIconButton)`
  border-radius: 0 100% 100% 0;
`;

const LeftOverlayIconButton = styled(OverlayIconButton)`
  border-radius: 100% 0 0 100%;
`;

const StyledSeparator = styled(Separator)`
  width: 1px;
  background: ${({ theme }) => theme.colors.white};
  height: auto;
`;
