import React from 'react';
import styled from 'styled-components';
import { TextSmall } from 'shared/react/components/basic/text/TextV2';

const FeedMultipleProductsTitle = ({ settings = {} }) => {
  const { titleEnabled, title } = settings;

  if (!titleEnabled) {
    return null;
  }

  return (
    <div>
      <DividerTitle>{title}</DividerTitle>
      <Divider />
    </div>
  );
};

const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.grayscaleGrey4};
  margin-left: -24px;
  margin-right: -24px;
`;

const DividerTitle = styled(TextSmall)`
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
  padding: 0 0 16px;
`;

export default FeedMultipleProductsTitle;
