import React from 'react';
import styled from 'styled-components';
import ViewsIcon from 'app/src/images/social/ViewsIcon';
import LikesIcon from 'app/src/images/social/LikesIcon';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextSmall } from 'shared/react/components/complex/Text';
import { IMPORT_FROM_INSTAGRAM } from 'app/src/constants/creation_method.constants';

const IntegrationVideoDetails = ({ viewCount, likeCount, integration }) => {
  const formatNumber = number => {
    const formatter = Intl.NumberFormat('en', { notation: 'compact' });
    return formatter.format(number);
  };

  if (integration === IMPORT_FROM_INSTAGRAM) {
    return null;
  }

  return (
    <>
      <ViewerCount>
        <ViewerCountText>
          <ViewsIcon /> {formatNumber(viewCount)}
        </ViewerCountText>
        <ViewerCountText>
          <LikesIcon /> {formatNumber(likeCount)}
        </ViewerCountText>
      </ViewerCount>
    </>
  );
};

const ViewerCount = styled(HorizontalFlex)`
  justify-content: space-between;
  width: 100%;
  grid-row: 1;
  grid-column: 1;
  z-index: 1;
  align-self: flex-end;
  border-radius: 8px;
  padding: 8px 12px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 28.12%, rgba(0, 0, 0, 0.48) 100%);
`;

const ViewerCountText = styled(TextSmall)`
  align-self: self-start;
  color: ${({ theme }) => theme.colors.white};
  align-items: center;
  display: flex;
  gap: 4px;
  & path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export default IntegrationVideoDetails;
