import React from 'react';

const ColorPalleteIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.938 24.5c0 .547-.43.938-.938.938-.547 0-.937-.391-.937-.938 0-.508.39-.937.937-.937a.95.95 0 0 1 .938.937m-.625-3.75c0-.508.39-.937.937-.937a.95.95 0 0 1 .938.937c0 .547-.43.938-.938.938-.547 0-.937-.391-.937-.938m5.625-1.25c0 .547-.43.938-.938.938-.547 0-.937-.391-.937-.938 0-.508.39-.937.937-.937a.95.95 0 0 1 .938.937m1.875 1.25c0-.508.39-.937.937-.937a.95.95 0 0 1 .938.937c0 .547-.43.938-.938.938-.547 0-.937-.391-.937-.938M31.227 27h-3.79c-1.054 0-1.875.86-1.875 1.875 0 .156 0 .273.04.39.078.43.234.782.39 1.172.235.547.508 1.094.508 1.641 0 1.25-.86 2.383-2.11 2.422H24a9.97 9.97 0 0 1-10-10c0-5.508 4.453-10 10-10 5.508 0 10 4.492 10 10 0 .04-.04.078-.04.117 0 1.445-1.288 2.383-2.733 2.383m1.484-2.383c0-.039.039-.078.039-.117 0-4.805-3.945-8.75-8.75-8.75-4.844 0-8.75 3.945-8.75 8.75A8.736 8.736 0 0 0 24 33.25h.352c.39 0 .898-.39.898-1.172 0-.195-.078-.469-.273-.898-.04-.078-.079-.117-.118-.196-.156-.39-.39-.898-.507-1.445-.04-.195-.04-.43-.04-.664a3.11 3.11 0 0 1 3.125-3.125h3.79c.976 0 1.484-.586 1.484-1.133"
      fill="#090A0B"
    />
  </svg>
);

export default ColorPalleteIcon;
