import React from 'react';
import QuizRuleChip from 'app/src/pages/project/pages/quiz/quiz_result/flow/rule/shared/QuizRuleChip';

function QuizChoice({ children, onClick }) {
  return (
    <QuizRuleChip onClick={onClick} tooltipText="Click to delete this choice">
      {children}
    </QuizRuleChip>
  );
}

export default QuizChoice;
