import React, { useEffect, useState, useDeferredValue } from 'react';
import 'react-virtualized/styles.css';
import DefaultHeader from 'shared/react/components/basic/basic_select/DefaultHeader';
import MenuWithContainer from 'shared/react/components/basic/MenuWithContainer';
import PlusIcon from 'app/src/images/PlusIcon';
import styled, { useTheme } from 'styled-components';
import useSelectAppProjects from 'app/src/hooks/useSelectedAppProjects';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { List as VirtualizedList } from 'react-virtualized';
import { OFFSITE_PUBLISH_METHODS } from 'app/src/constants/project.constants';
import { project as Project, publishMethodOptions } from 'app/src/types/entities';
import { SearchInput } from 'app/src/basic_components/input/InputWithIcon';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import { ONSITE_TARGET_PAGES } from 'src/pages/dashboard/constants/onsiteTargetPages.constants';

type Props = {
  className?: string;
  project: Project;
  onChange: (value: string | null) => void;
  value: string | null;
};

const VIRTUALIZED_LIST_STYLE = {
  height: 340,
  width: 328,
  rowHeight: 34,
};

const filterProjectsByTerm = (projects: Project[], term: string) => {
  if (!term) {
    return projects;
  }

  return projects.filter(project => project.name.toLowerCase().includes(term.toLowerCase()));
};

const useSourceProjects = (currentProject: Project) => {
  const { selectedAppProjects } = useSelectAppProjects();
  if (!selectedAppProjects || !currentProject) {
    return [];
  }

  return selectedAppProjects.filter((candidate: Project) => {
    if (!candidate.live) {
      return false;
    }

    if (candidate.publishMethod === publishMethodOptions.shopApp) {
      return false;
    }

    if (!candidate.feed || OFFSITE_PUBLISH_METHODS.includes(candidate.publishMethod)) {
      return false;
    }

    if (
      [ONSITE_TARGET_PAGES.ProductPages, ONSITE_TARGET_PAGES.SpecificProductPage].includes(
        currentProject.targetPage as ONSITE_TARGET_PAGES
      )
    ) {
      return candidate.targetPage === ONSITE_TARGET_PAGES.ProductPages;
    }

    return candidate.targetPage !== ONSITE_TARGET_PAGES.ProductPages;
  });
};

const VideoSourceSelect = ({ className, project, onChange, value }: Props) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>();
  const isMenuOpen = Boolean(menuAnchorEl);
  const [scrollIndex, setScrollIndex] = useState(0);

  const projects = useSourceProjects(project);
  const selectedProject = projects.find(project => project.id === value);

  const [searchTerm, setSearchTerm] = useState('');
  const deferredSearchTerm = useDeferredValue(searchTerm);
  const filteredProjects = filterProjectsByTerm(projects, deferredSearchTerm);

  const theme = useTheme();
  const virtualizedListHeight = Math.min(
    VIRTUALIZED_LIST_STYLE.height,
    filteredProjects.length * VIRTUALIZED_LIST_STYLE.rowHeight
  );

  useEffect(() => {
    setScrollIndex(filteredProjects.findIndex(({ id }) => id === value));
  }, [filteredProjects]);

  const handleMenuOpen: React.MouseEventHandler<HTMLElement> = e => {
    setMenuAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleSearchTermChange = e => {
    setSearchTerm(e.target.value);
  };

  const handleSearchTermClear = () => {
    setSearchTerm('');
  };

  const handleDedicatedPageClick = () => {
    onChange(null);
    handleMenuClose();
  };

  const renderItem = ({ index, key, style }) => {
    const project = filteredProjects[index];
    const isSelected = project.id === value;
    const clickHandler = () => {
      onChange(project.id);
      handleMenuClose();
    };

    return (
      <ListItem key={key} $isSelected={isSelected} onClick={clickHandler} style={style}>
        <ListItemText>{project.name}</ListItemText>
      </ListItem>
    );
  };

  return (
    <div className={className}>
      <SelectInput
        inputBackground={theme.colors.white}
        isOpen={isMenuOpen}
        itemsLength={filteredProjects.length}
        toggleIsOpen={handleMenuOpen}
        value={selectedProject?.name || 'New'}
      />
      <Menu onClose={handleMenuClose} open={isMenuOpen} anchorEl={menuAnchorEl}>
        <StyledSearchInput
          clearValue={handleSearchTermClear}
          onChange={handleSearchTermChange}
          placeholder="Search..."
          value={searchTerm}
        />
        <VirtualizedList
          height={virtualizedListHeight}
          rowCount={filteredProjects.length}
          rowHeight={VIRTUALIZED_LIST_STYLE.rowHeight}
          rowRenderer={renderItem}
          scrollToIndex={scrollIndex}
          width={VIRTUALIZED_LIST_STYLE.width}
        />
        <ListItem $isAction $isSelected={value == null} onClick={handleDedicatedPageClick}>
          <PlusIcon fill={theme.colors.primary} size={16} />
          <ListItemText>New</ListItemText>
        </ListItem>
      </Menu>
    </div>
  );
};

const SelectInput = styled(DefaultHeader)`
  border-radius: 8px;

  &,
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.neutralLighter};
  }
`;

const Menu = styled(MenuWithContainer)`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutralLighter};
  margin-top: 4px;
  padding: 4px;
  width: 336px;
`;

const StyledSearchInput = styled(SearchInput)`
  margin-bottom: 4px;
  max-height: 32px;

  input {
    border: none;
  }
`;

const ListItem = styled(HorizontalFlexCentered)<{ $isSelected: boolean; $isAction?: boolean }>`
  border-radius: 4px;
  border: 1px solid transparent;
  box-sizing: border-box;
  cursor: pointer;
  gap: 4px;
  height: 34px;
  padding: 0 8px;
  transition: 0.3s;
  background-clip: padding-box;
  background-color: ${({ $isSelected, theme }) =>
    $isSelected ? theme.colors.neutralLightest : undefined};
  color: ${({ $isAction, theme }) =>
    $isAction ? theme.colors.primary : theme.colors.neutralBlack};

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutralLightest};
  }
`;

const ListItemText = styled(TextTiny)`
  color: inherit;
  flex: 1;
  font-weight: 500;
`;

export default VideoSourceSelect;
