import { createContainer, createHook, createStore } from 'react-sweet-state';
import { graphqlRequest } from 'app/src/helpers/API';
import { API, graphqlOperation } from 'aws-amplify';
import { inAppNotificationsByTeamMemberId } from 'app/src/graphql/queries';
import { onCreateInAppNotificationByTeamMemberId } from 'app/src/graphql/subscriptions';
import Utils from 'app/src/utils';
import { updateInAppNotification } from 'app/src/graphql/mutations';

const IN_APP_NOTIFICATIONS_FETCH_SIZE = 100;

const initialState = {
  inAppNotifications: [],
  error: null,
  subscription: null,
  nextToken: null,
  reachedEnd: false,
  unreadInAppNotificationsCounter: 0,
};

const sortInAppNotifications = inAppNotifications => {
  return inAppNotifications.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
};

function filterInAppNotifications(inAppNotifications, filters = []) {
  if (!Object.keys(filters).length) {
    return inAppNotifications;
  }

  return inAppNotifications.filter(inAppNotification => {
    for (const key of filters) {
      if (inAppNotification[key]) {
        return true;
      }
    }

    return false;
  });
}

async function getInAppNotificationsByTeamMemberId(teamMemberId, nextToken) {
  try {
    const response = await graphqlRequest(
      graphqlOperation(inAppNotificationsByTeamMemberId, {
        teamMemberId,
        sortDirection: 'DESC',
        limit: IN_APP_NOTIFICATIONS_FETCH_SIZE,
        nextToken,
      })
    );
    return response.data.inAppNotificationsByTeamMemberId;
  } catch (error) {
    console.error(error);
    return {};
  }
}

async function getInAppNotifications(teamMemberId, currentNextToken, filters = {}) {
  let { items, nextToken } = await getInAppNotificationsByTeamMemberId(
    teamMemberId,
    currentNextToken
  );

  const sortedInAppNotifications = sortInAppNotifications(items);
  const filteredInAppNotifications = filterInAppNotifications(sortedInAppNotifications, filters);

  return {
    inAppNotifications: filteredInAppNotifications,
    nextToken,
  };
}

function addInAppNotifications(inAppNotifications, newInAppNotifications) {
  for (const newInAppNotification of newInAppNotifications) {
    if (!inAppNotifications.some(({ id }) => id === newInAppNotification)) {
      inAppNotifications.push(newInAppNotification);
    }
  }
}

const actions = {
  setNotificationRead:
    (notificationId, teamMemberId) =>
    async ({ getState, setState }) => {
      const { inAppNotifications } = getState();

      const inAppNotification = inAppNotifications.find(({ id }) => id === notificationId);

      inAppNotification.read = true;

      setState({
        inAppNotifications: [...inAppNotifications],
      });

      const input = { id: notificationId, teamMemberId, read: true };

      await graphqlRequest(
        graphqlOperation(updateInAppNotification, {
          input,
        })
      );
    },
  fetchInAppNotificationsByTeamMemberId:
    (teamMemberId, filters) =>
    async ({ getState, setState }) => {
      try {
        const { inAppNotifications, nextToken, reachedEnd } = getState();

        if (reachedEnd) {
          return;
        }

        const { inAppNotifications: newInAppNotifications, nextToken: newNextToken } =
          await getInAppNotifications(teamMemberId, nextToken, filters);

        addInAppNotifications(inAppNotifications, newInAppNotifications);

        const newState = {
          inAppNotifications: [...inAppNotifications],
          nextToken: newNextToken,
          reachedEnd: !newNextToken,
        };
        setState(newState);

        return newState;
      } catch (error) {
        console.error(error);
        return { inAppNotifications: [], nextToken: null };
      }
    },
  subscribeInAppNotifications:
    teamMemberId =>
    ({ setState, getState }) => {
      try {
        const subscription = API.graphql(
          graphqlOperation(onCreateInAppNotificationByTeamMemberId, { teamMemberId })
        ).subscribe({
          next: data => {
            const newInAppNotification = data?.value?.data?.onCreateInAppNotificationByTeamMemberId;
            const { inAppNotifications } = getState();
            if (
              !inAppNotifications.some(
                inAppNotification => inAppNotification.id === newInAppNotification.id
              )
            ) {
              inAppNotifications.push(newInAppNotification);
            }

            const sortedInAppNotifications = sortInAppNotifications([...inAppNotifications]);

            setState({ inAppNotifications: sortedInAppNotifications });
          },
          error: ({ error: { errors = [] } = {} }) => {
            Utils.logErrorMessage(
              errors[0]?.message || 'Error in onUpdateAccountSettings subscribe'
            );
          },
        });

        setState({ subscription });
      } catch (error) {
        Utils.logError('Cannot subscribe to accountSettings', error);
      }
    },
  unsubscribeInAppNotifications:
    () =>
    ({ getState, setState }) => {
      const { subscription } = getState();

      if (!subscription) {
        return;
      }

      subscription?.unsubscribe();

      setState({ subscription: null });
    },
  setAllNotificationsRead:
    () =>
    ({ getState, setState }) => {
      try {
        const { inAppNotifications } = getState();

        setState({
          inAppNotifications: inAppNotifications.map(newInAppNotification => {
            newInAppNotification.read = true;
            return newInAppNotification;
          }),
        });
      } catch (error) {
        console.error(error);
      }
    },
  clearState:
    () =>
    ({ setState }) => {
      setState({ inAppNotifications: [], error: null, nextToken: null });
    },
};

const InAppNotificationsStore = createStore({ initialState, actions, name: 'InAppNotifications' });

export const useInAppNotifications = createHook(InAppNotificationsStore);

export const InAppNotificationsContainer = createContainer(InAppNotificationsStore, {
  onCleanup:
    () =>
    ({ dispatch }) => {
      dispatch(actions.unsubscribeInAppNotifications());
    },
});
