import React from 'react';
import styled from 'styled-components';
import ColorPalettePopoverItem from './ColorPalettePopoverItem';

interface ColorPaletteListProps {
  palette: {
    label: string;
    value: string;
  }[];
  onChange?: (color: string) => void;
  onClose?: () => void;
  value?: string;
}

const ColorPaletteList = ({
  palette = [],
  onChange = () => {},
  onClose = () => {},
  value: selectedValue,
}: ColorPaletteListProps) => {
  const paletteColors = palette.map(({ label, value }, index) => {
    const onClick = () => {
      onChange(value);
      onClose();
    };

    return (
      <ColorPalettePopoverItem
        isSelected={selectedValue === value}
        key={index}
        label={label}
        value={value}
        onClick={onClick}
      />
    );
  });

  return <StyledColorPaletteList>{paletteColors}</StyledColorPaletteList>;
};

const StyledColorPaletteList = styled.div`
  max-height: 250px;
  overflow-y: scroll;
`;

export default ColorPaletteList;
