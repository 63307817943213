import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import ModalCard from 'app/src/complex_components/ModalCard';
import { TextH6, TextSubtitle } from 'shared/react/components/complex/Text';
import Button from 'shared/react/components/complex/Button';
import ProductField from './ProductField';
import { useResponses } from 'app/src/context/ResponsesStore';
import { useHistory, useLocation } from 'react-router-dom';
import { useProjects } from 'app/src/context/ProjectsStore';
import { EVENTS } from 'app/src/constants/tolstoy.constants';
import Types from 'shared/react/theme/Types';
import { CircularProgress } from '@material-ui/core';
import SnackBar from 'app/src/basic_components/SnackBar';
import { useUser } from 'app/src/context/userStore/UserStore';
import Utils from '../../utils';

const defaultRecommendation = {
  id: '',
  sku: '',
  title: '',
  productUrl: '',
  productImageUrl: '',
  key: 1,
};

let key = 1;

const ProductRecommendationReply = () => {
  const location = useLocation();
  const reply = location.state?.reply;
  const [inputs, setInputs] = useState([defaultRecommendation]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [errorSending, setErrorSending] = useState(false);
  const [isSentTry, setIsSentTry] = useState(false);
  const [{ products }, { createResponse, sendProductsEmail, getProducts }] = useResponses();
  const [{ projects }] = useProjects();
  const [{ user }] = useUser();
  const history = useHistory();

  const setValue = (newValue, i) => {
    const copyInputs = [...inputs];
    const key = copyInputs[i].key;
    if (newValue) {
      copyInputs[i] = {
        id: newValue[0],
        sku: newValue[1],
        title: newValue[2],
        productUrl: newValue[3],
        productImageUrl: newValue[4],
        key,
      };
    } else {
      copyInputs[i] = { ...defaultRecommendation, key };
    }
    setInputs(copyInputs);
  };

  const addInput = () => {
    key = ++key;
    setInputs([...inputs, { ...defaultRecommendation, key: `${key}` }]);
  };

  const removeProductField = i => {
    const copyInputs = [...inputs];
    copyInputs.splice(i, 1);
    setInputs(copyInputs);
  };

  const checkIsFormValid = (shouldCheck = isSentTry) => {
    if (!shouldCheck) {
      return;
    }

    const emptyResponses = [];
    const products = inputs.filter(({ id }, i) => {
      if (!id) {
        emptyResponses.push(i);
      }
    });

    if (emptyResponses.length) {
      const errors = new Array(products.length);
      emptyResponses.forEach(i => {
        errors[i] = 'Please select a product.';
      });
      setErrors(errors);
      return false;
    }

    setErrors([]);
    return true;
  };

  const onSend = async () => {
    setIsSentTry(true);
    const products = inputs.map(({ productImageUrl: imageUrl, productUrl, id, sku, title }) => {
      const prefix = productUrl.includes('?') ? '&' : '?';
      const url = `${productUrl}${prefix}tolstoy-product-recommendation=true&tolstoy-app-key=${Utils.getAppKey()}`;
      return { imageUrl, url, id, sku, title };
    });

    if (!checkIsFormValid(true)) {
      return;
    }

    const { sessionId, projectId } = reply;

    const name = reply.name || reply.email.split('@')[0];
    const body = {
      title: `Hi ${name},`,
      subtitle: "We've got some products especially for you",
      appKey: Utils.getAppKey(),
      to: reply.email,
      email: user.email,
      products,
      sessionId,
      replyProjectId: projectId,
      replyPublishId: reply.publishId,
    };

    setLoading(true);
    const res = await sendProductsEmail(body);

    if (!res) {
      setLoading(false);
      setErrorSending(true);
      return;
    }

    await createResponse(
      sessionId,
      projectId,
      reply.email,
      JSON.stringify(products),
      projectId,
      EVENTS.tolstoyProductsReply
    );

    setLoading(false);

    if (reply && reply.extraStateParams) {
      history.replace({
        pathname: `/dashboard/${projectId}/responses`,
        state: reply.extraStateParams,
      });
    } else {
      history.replace({
        pathname: `/dashboard/${projectId}/builder`,
        state: {
          reply: !!reply,
        },
      });
    }
  };

  useEffect(() => {
    const getProducts = async () => {
      if (user?.email) {
        try {
          await getProducts(user.email);
        } catch (err) {
          history.replace({
            pathname: `/dashboard/${reply.projectId}/builder`,
            state: {
              fetchProjects: !projects?.length,
            },
          });
        }
      }
    }

    getProducts();
  }, [user]);

  useEffect(() => {
    checkIsFormValid();
  }, [inputs]);

  const filteredProducts = useMemo(() => {
    const productIds = inputs.map(product => {
      return product.id;
    });
    return products.filter(product => {
      return !productIds.includes(product[0]);
    });
  }, [inputs, products]);

  return (
    <MainContainer>
      <CustomModalCard projectId={reply?.projectId} isReply={true}>
        {!products.length ? (
          <LoaderContainer>
            <CircularProgress />
          </LoaderContainer>
        ) : (
          <>
            <ContentContainer>
              <Header>Choose products to recommend</Header>
              <SubHeader>Choose products to recommend, These products</SubHeader>
              <SubHeader>will be emailed to the customer</SubHeader>
              <InputsContainer>
                {inputs.map((input, i) => {
                  return (
                    <ProductField
                      key={input.key}
                      products={filteredProducts}
                      setValue={setValue}
                      isRemovable={inputs.length > 1}
                      input={input}
                      i={i}
                      removeProductField={removeProductField}
                      loading={loading}
                      error={errors[i]}
                    />
                  );
                })}
              </InputsContainer>
              {inputs.length < 10 && (
                <AddProductButton
                  onClick={addInput}
                  leftIcon={<InputIcon>+</InputIcon>}
                  showLeftIcon={true}
                  type={Types.Ghost}
                  disabled={loading}
                >
                  Add Product
                </AddProductButton>
              )}
            </ContentContainer>
            <SendButton loading={loading} onClick={onSend}>
              Send Email
            </SendButton>
          </>
        )}
      </CustomModalCard>
      <SnackBar
        text={'There has been an error sending,Please try again'}
        open={errorSending}
        setOpen={() => setErrorSending(null)}
        severity="error"
      />
    </MainContainer>
  );
};

export default ProductRecommendationReply;

const MainContainer = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  justify-items: center;
  align-items: center;
  overflow-y: auto;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const CustomModalCard = styled(ModalCard)`
  width: 600px;
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 100%;
  }
`;

const ContentContainer = styled.div`
  padding: 25px;
  background: #f4f5f7;
  border-radius: 15px;
  width: 100%;
`;

const Header = styled(TextH6)`
  margin-bottom: 5px;
`;

const SubHeader = styled(TextSubtitle)`
  font-weight: 400;
`;

const InputsContainer = styled.div`
  display: grid;
  gap: 8px;
  margin: 15px 0;
  max-height: 400px;
  overflow: scroll;
`;

const AddProductButton = styled(Button)`
  margin-top: 15px;
`;

const InputIcon = styled(TextSubtitle)`
  margin-bottom: 2px;
`;

const SendButton = styled(Button)`
  margin-top: 20px;
`;
