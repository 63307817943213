import React from 'react';

const GorgiasIcon = props => (
  <svg
    width={24}
    height={26}
    viewBox="0 0 24 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.815 25.87h1.518c.352 0 .649-.302.667-.661V8.332c0-1.663-1.315-3.005-2.945-3.005h-1.944a.663.663 0 0 1-.648-.661V3.135c0-1.663-1.315-3.005-2.945-3.005H2.944C1.314.13 0 1.472 0 3.135v16.952c0 1.644 1.315 2.986 2.944 3.005H17.52a.66.66 0 0 1 .37.113l3.555 2.552a.665.665 0 0 0 .37.113Zm-3.537-5.178H2.98a.64.64 0 0 1-.648-.662V3.173c0-.36.297-.662.648-.662h12.5c.352 0 .649.303.649.662v1.493a.663.663 0 0 1-.648.661H6.167a.663.663 0 0 0-.648.662v11.244c0 .36.296.662.648.662h11.63a.663.663 0 0 0 .648-.662V8.37c0-.36.296-.662.648-.662H21c.352 0 .648.303.648.662v13.796c0 .34-.37.53-.63.34l-2.37-1.7a.658.658 0 0 0-.37-.114Zm-2.482-5.197h-7.63a.315.315 0 0 1-.314-.322V8.03c0-.17.13-.322.315-.322h7.63c.166 0 .314.133.314.322v7.143c0 .17-.13.322-.315.322Z"
      fill="#000"
    />
  </svg>
);

export default GorgiasIcon;
