import React from 'react';
import styled from 'styled-components';
import { TextBody } from 'shared/react/components/complex/Text';

function RuleSummaryItem({ attributeText, conditionText, valueText }) {
  return (
    <LayoutRoot>
      <TextBody>
        {attributeText}: <ItalicText>{conditionText}</ItalicText>
      </TextBody>
      <RightSideEllipsis>
        <SpanSupportForSpecialCharacter>{valueText}</SpanSupportForSpecialCharacter>
      </RightSideEllipsis>
    </LayoutRoot>
  );
}

export default RuleSummaryItem;

const LayoutRoot = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  justify-items: end;
  align-items: center;
  max-width: 100%;
  width: 100%;
  gap: 16px;
`;

const ItalicText = styled.span`
  font-style: italic;
`;

const SpanSupportForSpecialCharacter = styled.span`
  direction: ltr;
  unicode-bidi: bidi-override;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.black};
`;

const RightSideEllipsis = styled.div`
  direction: rtl;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
