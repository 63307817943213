import React from 'react';
import styled from 'styled-components';
import { useAccount } from 'app/src/context/AccountStore';
import AccountButton from 'app/src/pages/sidebar/account-button/AccountButton';
import Utils from 'app/src/utils';
import { broadcastReloadMessage } from 'app/src/utils/broadcast.utils';
import useNavigation from 'app/src/hooks/useNavigation';
import { useUser } from 'app/src/context/userStore/UserStore';
import WorkspacesCreateItem from 'app/src/pages/sidebar/account-menu/workspaces/WorkspacesCreateItem';
import { TextSmall } from 'shared/react/components/complex/Text';
import { useFeatureActions } from 'app/src/context/FeaturesStore';

const Workspaces = () => {
  const [, { updateAccountLoginDate, getNotCurrentWorkspaces, getCurrentWorkspace }] = useAccount();
  const { navigateToRoot } = useNavigation();
  const [{ accountLogoSettings }] = useUser();
  const { clearFeatures } = useFeatureActions();

  const workspaces = getNotCurrentWorkspaces();

  const handleTeamMemberClick = async workspace => {
    clearFeatures();
    await updateAccountLoginDate(workspace);

    broadcastReloadMessage();
    navigateToRoot();
  };

  const accountLogo = Utils.getLogoUrl(accountLogoSettings?.logo);
  const accountBackground = accountLogoSettings?.color;
  const name = getCurrentWorkspace()?.name;

  return (
    <LayoutRoot>
      <DisabledAccountButton
        accountBackground={accountBackground}
        accountLogo={accountLogo}
        accountName={name}
        header={name}
        subHeader={''}
        onClick={() => {}}
        showIcon={false}
      />
      {workspaces.map(workspace => {
        const logoSettings = Utils.safeParse(workspace.logoSettings);
        const logo = Utils.getLogoUrl(logoSettings?.logo);

        return (
          <StyledAccountButton
            accountLogo={logo}
            accountName={workspace.name}
            accountBackground={logoSettings?.backgroundColor}
            showIcon={false}
            key={workspace?.id}
            header={workspace.name}
            subHeader={workspace?.website}
            onClick={() => handleTeamMemberClick(workspace)}
          />
        );
      })}
      <WorkspacesCreateItem />
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div``;

const StyledAccountButton = styled(AccountButton)`
  padding: 4px;

  & ${TextSmall} {
    font-weight: 400;
  }
`;

const DisabledAccountButton = styled(AccountButton)`
  pointer-events: none;
  padding: 4px;
`;

export default Workspaces;
