import React from 'react';
import styled from 'styled-components';
import { useLeadFormEditorGroupContext } from 'app/src/pages/project/pages/look_and_feel/editor/editors/lead_form_editor/LeadFormEditorGroupContext';
import CollectField from 'app/src/pages/project/pages/look_and_feel/editor/editors/lead_form_editor/CollectField';
import EditorTextInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorTextInputItem';
import {
  COLLECT_PROPERTY_KEY,
  CONSENT_PROPERTY_KEY,
  LINK_PROPERTY_KEY,
  PLACEHOLDER_PROPERTY_KEY,
} from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import EditorHeader from '../shared/EditorHeader';
import EditorMainContainer from '../shared/EditorMainContainer';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const ConsentFieldEditor = () => {
  const { leadFormSettings, handleCollectFieldPropertyChanged } = useLeadFormEditorGroupContext();

  const { consentField = {} } = leadFormSettings;

  const { key, collect, placeholder, link, required } = consentField;

  return (
    <LayoutRoot>
      <EditorHeader title="Consent" />
      <EditorMainContainer>
        <CollectField
          text="Consent text"
          id={key}
          key={key}
          checked={collect}
          required={required}
          onChange={value =>
            handleCollectFieldPropertyChanged(CONSENT_PROPERTY_KEY, COLLECT_PROPERTY_KEY, value)
          }
        />
        <EditorTextInputItem
          text="Text"
          value={placeholder}
          placeholder="Consent text"
          onChange={value =>
            handleCollectFieldPropertyChanged(CONSENT_PROPERTY_KEY, PLACEHOLDER_PROPERTY_KEY, value)
          }
          disabled={!collect}
          maxLength={100}
          placeholderRtl
        />
        <EditorTextInputItem
          text="Page URL"
          value={link}
          placeholder="URL"
          onChange={value =>
            handleCollectFieldPropertyChanged(CONSENT_PROPERTY_KEY, LINK_PROPERTY_KEY, value)
          }
          disabled={!collect}
          maxLength={100}
        />
      </EditorMainContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

export default ConsentFieldEditor;
