import React, { useState } from 'react';
import styled from 'styled-components';
import {
  GOOGLE_DRIVE,
  INSTAGRAM_GRAPH,
  TIKTOK,
  YOUTUBE,
} from 'app/src/constants/intergrations.constants';
import { RECORDINGS, UPLOAD } from 'app/src/constants/recordingTypes.constants';
import NewVideoButtons from 'app/src/pages/dashboard/pages/videos/components/new_video/components/NewVideoButtons';
import useGetCreateVideoMenuItems from 'app/src/hooks/useGetCreateVideoMenuItems';
import MenuWithItems from 'app/src/complex_components/menu_items/MenuWithItems';
import { DesignSizes } from 'app/src/types/design-system';
import { WhereCondition } from 'app/src/pages/dashboard/utils/filterEntities';
import { isSourceSelected } from 'app/src/pages/dashboard/pages/videos/constants/videos.constants';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { AI_VIDEO } from 'app/src/constants/creation_method.constants';
import { vodAsset } from 'app/src/types/entities';

type Props = {
  videosFilters: WhereCondition;
  isModal?: boolean;
  onSelectedVideosAction?: (videos: vodAsset) => void;
};

const NewVideoButton = ({ videosFilters, isModal = false, onSelectedVideosAction }: Props) => {
  const [menuAnchor, setMenuAnchor] = useState(null);
  const currentVideoSource = videosFilters.uploadType?.in?.[0];
  const currentAppExternalIds = (videosFilters.appExternalId?.in || []) as string[];
  const [{ modalProps }] = useModal();

  const toggleNewVideoMenu = event => {
    if (!event || !event.currentTarget) {
      setMenuAnchor(null);
      return;
    }

    setMenuAnchor(menuAnchor ? null : event.currentTarget);
  };

  const { uploadFileItem, tiktokItem, recordingsItems } = useGetCreateVideoMenuItems({
    videoFolder: videosFilters.folder?.eq as string,
    toggleMenu: toggleNewVideoMenu,
    extraProps: modalProps,
  });

  const getMenuItems = () => {
    if (isSourceSelected(TIKTOK, currentVideoSource)) {
      return [tiktokItem];
    } else if (isSourceSelected(INSTAGRAM_GRAPH, currentVideoSource)) {
      return [];
    } else if (isSourceSelected(UPLOAD, currentVideoSource)) {
      return [uploadFileItem];
    } else if (isSourceSelected(RECORDINGS, currentVideoSource)) {
      return recordingsItems;
    } else if (isSourceSelected(GOOGLE_DRIVE, currentVideoSource)) {
      return [];
    } else if (isSourceSelected(AI_VIDEO, currentVideoSource)) {
      return [];
    } else if (isSourceSelected(YOUTUBE, currentVideoSource)) {
      return [];
    }

    return [uploadFileItem, ...recordingsItems, tiktokItem];
  };

  const menuItems = getMenuItems();

  return (
    <>
      <NewVideoButtons
        isModal={isModal}
        menuItems={menuItems}
        toggleNewVideoMenu={toggleNewVideoMenu}
        currentVideoSource={currentVideoSource as string}
        currentAppExternalIds={currentAppExternalIds}
        onSelectedVideosAction={onSelectedVideosAction}
      />
      <StyledMenuWithItems
        anchorElement={menuAnchor}
        menuItems={menuItems}
        onClose={() => setMenuAnchor(null)}
        size={DesignSizes.NORMAL}
        direction={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </>
  );
};

const StyledMenuWithItems = styled(MenuWithItems)`
  border-radius: 8px;
  padding: 8px;
  width: 236px;
`;

export default NewVideoButton;
