import React from 'react';
import AnalyticsPlayer from './analytics-player/AnalyticsPlayer';
import styled from 'styled-components';
import Utils from 'app/src/utils';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import AnalyticsTopConvertingVideoInsights from './AnalyticsTopConvertingVideoInsights';
import { AnalyticsTopConvertingVideoInsight } from './analytics-player/AnalyticsPlayer.types';

type Props = {
  metrics: Metrics;
  insights: AnalyticsTopConvertingVideoInsight[];
};

type Metrics = {
  id: string;
  conversionRate: number;
  currency: string;
  plays: number;
  totalRevenue: number;
};

const AnalyticsTopConvertingVideoItem = ({ metrics, insights }: Props) => {
  return (
    <LayoutRoot>
      <MetricsContainer>
        <TotalRevenueRow>
          <TextTiny>Total Revenue</TextTiny>
          <TextTiny>
            {Utils.formatNumber({
              currency: metrics.currency,
              formatted: false,
              isPercentage: false,
              letter: false,
              value: metrics.totalRevenue,
            })}
          </TextTiny>
        </TotalRevenueRow>
        <ConversionRow>
          <TextTiny>Conversion</TextTiny>
          <TextTiny>{Utils.getPercentage(metrics.conversionRate, 1)}%</TextTiny>
        </ConversionRow>
      </MetricsContainer>
      <PlayerContainer>
        <AnalyticsPlayer vodAssetId={metrics.id} metrics={metrics} />
      </PlayerContainer>
      <AnalyticsTopConvertingVideoInsights insights={insights} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  gap: 4px;
  height: 100%;
`;

const PlayerContainer = styled.div`
  height: 362px;
  min-height: 362px;

  @media (max-width: 1366px) {
    height: 348px;
    min-height: 348px;
  }
`;

const MetricsContainer = styled(VerticalFlex)`
  gap: 4px;
  padding: 8px;
`;

const MetricsRow = styled(Gap8HorizontalFlex)`
  justify-content: space-between;

  & > :last-child {
    font-weight: 600;
  }
`;

const TotalRevenueRow = styled(MetricsRow)`
  & > * {
    color: ${({ theme }) => theme.colors.neutralBlack};
  }
`;

const ConversionRow = styled(MetricsRow)`
  & > * {
    color: ${({ theme }) => theme.colors.neutralDark};
  }
`;

export default AnalyticsTopConvertingVideoItem;
