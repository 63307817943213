import { rgbToHex as rgbToHexMUI } from '@material-ui/core';

export const hexToRgb = (hex = '') => {
  const newHex = hex.replace(/^#/, '');
  const bigint = parseInt(newHex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return { r, g, b };
};

export function rgbToHex(r, g, b) {
  return rgbToHexMUI(`rgb(${r},${g},${b})`);
}
