import React, { useEffect } from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import { useBilling } from '../../hooks/useBillings';
import { navigateToPlans, navigateToTeamMembers } from '../../utils/navigation.utils';
import { useHistory } from 'react-router-dom';
import { useAccount } from 'app/src/context/AccountStore';

const BillingPage = () => {
  const history = useHistory();
  const [{ isAccountOwner }] = useAccount();
  const { isFreePackage, openBillingPage } = useBilling();

  useEffect(() => {
    if (isFreePackage) {
      navigateToPlans(history, true);
      return;
    }

    if (!isAccountOwner) {
      navigateToTeamMembers(history, true);
      return;
    }

    openBillingPage();
    history.goBack();
  }, [isFreePackage, isAccountOwner, openBillingPage]);

  return (
    <StyledBillingPage>
      <CircularProgress size={24} />
    </StyledBillingPage>
  );
};

export default BillingPage;

const StyledBillingPage = styled.div`
  display: grid;
  place-content: center;
`;
