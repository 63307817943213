import React from 'react';

const RefreshIcon = props => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.64 7.273a.554.554 0 0 1 .376.704 5.27 5.27 0 0 1-5.04 3.773c-1.851 0-3.562-1.008-4.5-2.578v2.39a.54.54 0 0 1-.562.563.555.555 0 0 1-.562-.562v-3.75a.57.57 0 0 1 .562-.563h3.75c.328 0 .563.258.563.563a.555.555 0 0 1-.563.562H2.297a4.15 4.15 0 0 0 3.68 2.25c1.828 0 3.468-1.219 3.96-2.977a.554.554 0 0 1 .704-.375m.422-6.398a.57.57 0 0 1 .563.563v3.75a.555.555 0 0 1-.562.562h-3.75a.54.54 0 0 1-.563-.562c0-.305.234-.563.563-.563h2.343C8.953 3.289 7.546 2.375 6 2.375c-1.828 0-3.469 1.242-3.984 2.977-.07.304-.375.468-.68.375-.305-.07-.469-.399-.399-.68C1.595 2.82 3.68 1.25 6 1.25c1.852 0 3.563 1.031 4.5 2.602V1.438c0-.305.234-.563.563-.563"
        fill="#090A0B"
      />
    </svg>
  );
};

export default RefreshIcon;
