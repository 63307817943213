import React from 'react';
import IntegrationsImportFromAccountVideos from 'app/src/pages/creation_flow/right_section/integrations_common/IntegrationsImportFromAccountVideos';
import { IMPORT_FROM_INSTAGRAM } from 'app/src/constants/creation_method.constants';
import { useInstagram } from 'app/src/context/InstagramStore';
import useIntegrationsVideos from 'app/src/hooks/useIntegrationsVideos';

const InstagramImportFromAccountVideos = ({ isLoading }) => {
  const { instagram: instagramApp } = useIntegrationsVideos();
  const [{ accountVideos, initialized, isWaitingForResponse }, { fetchByAccount }] = useInstagram();

  return (
    <IntegrationsImportFromAccountVideos
      fetchData={() => fetchByAccount(instagramApp)}
      accountVideos={accountVideos}
      app={instagramApp}
      initialized={initialized}
      integration={IMPORT_FROM_INSTAGRAM}
      isLoading={isLoading}
      isWaitingForResponse={isWaitingForResponse}
    />
  );
};

export default InstagramImportFromAccountVideos;
