import React from 'react';
import BackArrowIcon from 'app/src/images/dashboard_v2/BoldArrowIcon';
import styled, { useTheme } from 'styled-components';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';

const BackButton = ({ onClick, dataTestId, className }) => {
  const theme = useTheme();

  return (
    <IconContainer onClick={onClick} data-test-id={dataTestId} className={className}>
      <BackArrowIcon fill={theme.colors.primary} />
    </IconContainer>
  );
};

const IconContainer = styled(HorizontalFlexWrap)`
  align-items: center;
  padding: 8px;
  background: ${({ theme }) => theme.colors.primaryLight};
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: ${({ theme }) => theme.colors.primaryLightHover};
  }

  &:active,
  &:focus {
    background: ${({ theme }) => theme.colors.primaryLightActive};
  }
`;

export default BackButton;
