import { PLAYER_EVENTS } from 'player-events';
import { REACT_APP_FACEBOOK_ANALYTICS_KEY } from 'player/src/config/player.config';

function loadFbScript() {
  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
}

const sendFacebookAnalyticsEvent = params => {
  const { eventName, facebookAnalyticsID: faId, playlist, text: value, collectInfoType } = params;
  if (!window.fbq || !faId) {
    return;
  }
  const category = `tolstoy-${playlist}`;

  if (eventName === PLAYER_EVENTS.sessionStart) {
    sendCustomFacebookEvent(faId, `${category}-click`, { value: 'Start Tolstoy' });
  } else if (eventName === PLAYER_EVENTS.clickCta) {
    sendCustomFacebookEvent(faId, `${category}-click`, { value });
  } else if (eventName === PLAYER_EVENTS.submitInput) {
    sendCustomFacebookEvent(faId, `${category}-input`, { value });
  } else if (eventName === PLAYER_EVENTS.collectInfo) {
    sendCustomFacebookEvent(faId, `${category}-${PLAYER_EVENTS.collectInfo}`, {
      value: collectInfoType,
    });
  } else if (eventName === PLAYER_EVENTS.videoResponse) {
    sendCustomFacebookEvent(faId, `${category}-${PLAYER_EVENTS.videoResponse}`);
  } else if (eventName === PLAYER_EVENTS.imageResponse) {
    sendCustomFacebookEvent(faId, `${category}-${PLAYER_EVENTS.imageResponse}`);
  } else if (eventName === PLAYER_EVENTS.audioResponse) {
    sendCustomFacebookEvent(faId, `${category}-${PLAYER_EVENTS.audioResponse}`);
  } else if (eventName === PLAYER_EVENTS.sessionEnd) {
    sendCustomFacebookEvent(faId, `${category}-${PLAYER_EVENTS.sessionEnd}`);
  }
};

const sendCustomFacebookEvent = (faId, event, data) => {
  window.fbq('trackSingleCustom', faId, event, data);
};

const initFacebookAnalytics = faId => {
  if (!faId || !window.fbq) {
    return;
  }
  window.fbq('init', REACT_APP_FACEBOOK_ANALYTICS_KEY);
  window.fbq('track', 'PageView');
  window.fbq('init', faId);
  window.fbq('trackSingle', faId, 'PageView');
};

export { initFacebookAnalytics, loadFbScript, sendFacebookAnalyticsEvent };
