import React from 'react';

const ShareArrowIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.26 14.978a10.985 10.985 0 0 1 10.216-8.546l.955-.044V3.047a.047.047 0 0 1 .081-.033L18.95 9.45l-.057.057-6.38 6.38a.048.048 0 0 1-.033.014.047.047 0 0 1-.047-.047v-3.456l-.784-.173A11.7 11.7 0 0 0 1.26 14.978Z"
      stroke="#fff"
      strokeWidth="2"
    />
  </svg>
);

export default ShareArrowIcon;
