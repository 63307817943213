import * as React from 'react';

const LinkedInIcon = ({ borderColor, ...props }) => (
  <svg
    width={49}
    height={48}
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.656 32V20.32h-3.633V32h3.633ZM17.82 18.758c1.172 0 2.11-.977 2.11-2.149 0-1.132-.938-2.07-2.11-2.07-1.133 0-2.07.938-2.07 2.07 0 1.172.938 2.149 2.07 2.149ZM33.21 32h.04v-6.406c0-3.125-.703-5.547-4.375-5.547-1.758 0-2.93.976-3.438 1.875h-.039V20.32h-3.476V32h3.633v-5.781c0-1.524.273-2.969 2.148-2.969s1.914 1.719 1.914 3.086V32h3.594Z"
      fill="#3D4FE0"
    />
    <path
      d="M24.5 47c-12.703 0-23-10.297-23-23h-2c0 13.807 11.193 25 25 25v-2Zm23-23c0 12.703-10.297 23-23 23v2c13.807 0 25-11.193 25-25h-2Zm-23-23c12.703 0 23 10.297 23 23h2c0-13.807-11.193-25-25-25v2Zm0-2C10.693-1-.5 10.193-.5 24h2c0-12.703 10.297-23 23-23v-2Z"
      fill={borderColor || '#fff'}
    />
  </svg>
);

export default LinkedInIcon;
