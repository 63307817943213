import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ModalCard from 'app/src/complex_components/ModalCard';
import { BaseVideoSize } from 'app/src/basic_components/BaseVideoSize';
import PlayIcon from 'app/src/images/PlayIcon';
import TimeBar from './TimeBar';
import OutlinedVIcon from 'app/src/images/OutlinedVIcon';
import { useVideos } from 'app/src/context/VideosStore';
import { v4 as uuidv4 } from 'uuid';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { MINIMUM_TRIMMING_SECONDS } from 'app/src/constants/editStep.constants';
import Button from 'shared/react/components/complex/Button';
import Types from 'shared/react/theme/Types';
import RevertIcon from 'app/src/images/edit_step_icons/RevertIcon';
import Routes from '../../helpers/Routes';
import VolumeOffIcon from 'app/src/images/volume_off_icon.svg';
import VolumeUpIcon from 'app/src/images/volume_up_icon.svg';
import IconButton from 'app/src/complex_components/IconButton';
import { getTime } from './trimService';
import { track } from '../../helpers/Tracker';
import { toTimeString } from '../../utils/video.utils';
import { useProjects } from 'app/src/context/ProjectsStore';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import VideoDurationInputs from 'app/src/pages/project/pages/look_and_feel/editor/editors/gif_settings_editor/VideoDurationInputs';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import ClockIcon from 'app/src/images/ClockIcon';
import { VIDEO_STATUS } from 'shared/react/constants/video.constants';
import PauseIcon from 'app/src/images/PauseIcon';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';

const VideoTrimPage = () => {
  const [videoStartTime, setVideoStartTime] = useState(0);
  const [videoEndTime, setVideoEndTime] = useState(1);
  const [movingIndex, setMovingIndex] = useState(null);
  const [currTime, setCurrTime] = useState(0);
  const [loading, setLoading] = useState(false);
  const [mute, setMute] = useState(true);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const history = useHistory();
  const { videoId } = useParams();
  const location = useLocation();
  const [, { setErrorSnackbar }] = useSnackBar();
  const projectId = location?.state?.projectId || '';
  const stepId = location?.state?.stepId || '';
  const from = location?.state?.from || '';
  const path = location?.state?.path || '';
  const [{ video, data }, { trimVideo, createVideo, deleteVideo, getVideoUrl, getVideo }] =
    useVideos(videoId);
  const [{ modalProps }, { setModalProps }] = useModal();
  const [{ step, project }, { updateProjectStep, publishProject }] = useProjects({
    stepId,
    projectId,
  });

  const videoRef = useRef();
  const intervalRef = useRef();
  const timeoutRef = useRef();
  const videoLengthContainerRef = useRef();
  const duration = videoRef?.current?.duration;

  const setVideoCurrentTime = time => {
    clearTimeout(timeoutRef.current);
    clearInterval(intervalRef.current);
    timeoutRef.current = setTimeout(() => {
      videoRef.current.currentTime = time;
      setCurrTime(time);
    }, 300);
  };

  const onPlay = time => {
    if (isPlaying) {
      pauseVideo();
      clearInterval(intervalRef.current);
      return;
    }

    const isStartOrFinish = videoStartTime === currTime || videoEndTime === currTime;
    clearInterval(intervalRef.current);
    videoRef.current.play();
    setIsPlaying(true);

    if (!time || typeof time !== 'number') {
      time = isStartOrFinish ? videoStartTime : currTime;
    }

    intervalRef.current = setInterval(() => {
      if (!videoRef?.current) {
        clearInterval(intervalRef.current);
        return;
      }

      if (time >= videoEndTime) {
        videoRef.current.pause();
        setIsPlaying(false);
        setCurrTime(videoEndTime);
        clearInterval(intervalRef.current);
        return;
      }

      time += 0.1;
      setCurrTime(time);
    }, 100);
  };

  const changeEndTime = time => {
    if (time - videoStartTime < MINIMUM_TRIMMING_SECONDS || !isVideoReady) {
      return;
    }

    clearTimeout(timeoutRef.current);

    setVideoEndTime(time);

    if (videoRef.current.currentTime > time) {
      videoRef.current.currentTime = time;
    }
  };

  const changeStartTime = time => {
    if (videoEndTime - time < MINIMUM_TRIMMING_SECONDS || !isVideoReady) {
      return;
    }

    clearTimeout(timeoutRef.current);

    setVideoStartTime(time);
  };

  const onMouseMove = event => {
    if (movingIndex === null || loading || !isVideoReady) {
      return;
    }

    const time = getTime(event, videoLengthContainerRef, duration);

    if (time === videoEndTime || time === videoStartTime) {
      return;
    }

    if (movingIndex === 1) {
      changeEndTime(time);
    } else {
      changeStartTime(time);
    }

    clearInterval(intervalRef.current);
    videoRef.current.pause();
  };

  const onDurationChange = event => {
    setVideoEndTime(event.target.duration);
  };

  const getVideoByStockAssetUrl = url => {
    const urlSegments = url?.split('/');
    const lastSegment = urlSegments?.[urlSegments?.length - 1];
    const copiedVideoId = lastSegment?.split('.')?.[0];

    return data.find(({ id }) => {
      return id === copiedVideoId;
    });
  };

  const onSave = async () => {
    setLoading(true);
    track('Trim Video Save Click');
    const uuid = uuidv4();
    const trimSettings = { startTime: videoStartTime, endTime: videoEndTime };
    let currVideo = video;

    if (currVideo?.stockAsset?.videoUrl) {
      const assetVideo = getVideoByStockAssetUrl(currVideo.stockAsset.videoUrl);
      currVideo = assetVideo ?? currVideo;
    }

    if (!currVideo) {
      setLoading(false);
      setErrorSnackbar("Couldn't trim video. Please contact support.");
      return;
    }

    if (video.isHDR === null && !currVideo?.stockAsset?.videoUrl) {
      currVideo = await getVideo(videoId);
    }

    const name = `${currVideo.name} Trimmed`;
    const newVideo = {
      ...currVideo,
      stockAsset: null,
      id: uuid,
      name,
      status: VIDEO_STATUS.uploading,
      uploadType: 'trim',
      originalVOD: videoId,
      trimSettings: JSON.stringify(trimSettings),
      externalId: currVideo.externalId || undefined,
      duration: Math.floor(videoEndTime - videoStartTime),
      vodConnections: undefined,
    };
    delete newVideo.createdAt;
    delete newVideo.updatedAt;
    await createVideo(newVideo);

    try {
      await trimVideo({
        ...trimSettings,
        key: currVideo.id,
        newId: uuid,
      });

      moveBack(uuid);
      setLoading(false);
    } catch (err) {
      await deleteVideo(uuid);
      setLoading(false);
    }
  };

  const moveBack = videoId => {
    if (from === 'edit-step') {
      moveBackToEditStep(videoId);
    }

    if (from === 'builder-edit-step') {
      moveBackToNewEditStep(videoId);
    }
  };
  const moveBackToNewEditStep = async videoId => {
    let currentProject = project;
    let currentStep = step;
    if (modalProps.project || modalProps.step) {
      currentProject = modalProps.project || project;
      currentStep = modalProps.step || step;
    }

    const input = { ...currentStep, videoId };
    await updateProjectStep(input, currentProject);
    publishProject(projectId);

    setModalProps({ ...modalProps, step: input });

    history.push(path);
  };

  const moveBackToEditStep = videoId => {
    const state = { reply: location?.state?.reply, shouldRetry: true };
    if (videoId) {
      state.video = { id: videoId };
    }

    const pathname = `${Routes.getEditStepBaseRoute()}/${projectId ? `${projectId}` : ''}${
      stepId ? `/${stepId}` : ''
    }`;

    history.push({
      pathname,
      state,
    });
  };

  const onRevert = async () => {
    setLoading(true);
    track('Revert Trim Video Click');
    moveBack(video.originalVOD);
    setLoading(false);
  };

  const onBarClick = event => {
    if (movingIndex) {
      return;
    }

    clearInterval(intervalRef.current);
    videoRef.current.pause();

    const time = getTime(event, videoLengthContainerRef, duration);
    videoRef.current.currentTime = time;
    setCurrTime(time);
    pauseVideo();
  };

  const onCancel = () => {
    history.goBack();
  };

  const onCanPlay = () => {
    setIsVideoReady(true);
  };

  const pauseVideo = () => {
    clearInterval(intervalRef.current);
    videoRef?.current?.pause?.();
    setIsPlaying(false);
  };

  useEffect(() => {
    setVideoCurrentTime(videoStartTime);
  }, [videoStartTime, videoEndTime]);

  useEffect(() => {
    videoRef?.current?.addEventListener('durationchange', onDurationChange);

    return () => {
      videoRef?.current?.removeEventListener('durationchange', onDurationChange);
    };
  }, [videoRef]);

  const shouldAllowToChange = videoEndTime !== duration || videoStartTime !== 0;

  const turnOff = () => setMovingIndex(null);

  return (
    <LayoutRoot onTouchEnd={turnOff} onMouseUp={turnOff}>
      <ModalCard shouldShowXButton={false}>
        <VideoContainer>
          <Video
            as="video"
            muted={mute}
            playsInline
            disablePictureInPicture={true}
            ref={videoRef}
            src={getVideoUrl(video)}
            onClick={pauseVideo}
            onLoadedMetadata={onCanPlay}
          />
          <MuteButtonContainer>
            <MuteButton
              onClick={() => setMute(!mute)}
              src={mute ? VolumeOffIcon : VolumeUpIcon}
              disabled={loading}
            />
          </MuteButtonContainer>
          <BarContainer onTouchMove={onMouseMove} onMouseMove={onMouseMove}>
            <ResumeButton onClick={onPlay}>{isPlaying ? <PauseIcon /> : <PlayIcon />}</ResumeButton>
            <TimeBar
              videoStartTime={videoStartTime}
              videoEndTime={videoEndTime}
              setMovingIndex={setMovingIndex}
              duration={duration}
              onBarClick={onBarClick}
              videoLengthContainerRef={videoLengthContainerRef}
              currVideoTime={currTime || 0}
            />
            <DurationContainer>{toTimeString(duration)}</DurationContainer>
            {!!video?.originalVOD && (
              <RevertButton onClick={onRevert}>
                <RevertIcon />
                Revert
              </RevertButton>
            )}
          </BarContainer>
        </VideoContainer>
        <TimesContainer>
          <ClockIcon />
          <VideoDurationInputs
            startTime={videoStartTime}
            endTime={videoEndTime}
            setStartTime={setVideoStartTime}
            setEndTime={setVideoEndTime}
            duration={duration}
            minDifference={MINIMUM_TRIMMING_SECONDS}
          />
        </TimesContainer>
        <ButtonsContainer>
          <Button onClick={onCancel} disabled={loading} type={Types.Secondary}>
            Cancel
          </Button>
          <Button
            onClick={onSave}
            loading={loading}
            disabled={!shouldAllowToChange || !isVideoReady}
            showRightIcon={true}
            rightIcon={<OutlinedVIcon />}
          >
            Save
          </Button>
        </ButtonsContainer>
      </ModalCard>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  display: grid;
  align-items: center;
  height: 100%;
  justify-content: center;
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 100%;
  }
`;

const VideoContainer = styled.div`
  display: grid;
  margin-bottom: 24px;
`;

const Video = styled(BaseVideoSize)`
  grid-column: 1;
  grid-row: 1;
  background: ${({ theme }) => theme.colors.black};
  border-radius: 10px 10px 0 0;
`;

const BarContainer = styled.div`
  display: grid;
  gap: 16px;
  z-index: 1;
  background: rgba(9, 10, 11, 0.6);
  backdrop-filter: blur(20px);
  border-radius: 0px 0px 10px 10px;
  align-self: self-end;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  flex-wrap: nowrap;
  grid-template-columns: auto 1fr auto;

  grid-template-areas: 'resume time duration revert ';
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    grid-template-areas:
      'time time time'
      'resume revert revert';
    padding: 12px 40px;
    align-items: flex-end;
    gap: 0;
    width: 100%;
  }
`;

const BarButton = styled.div`
  background: rgba(9, 10, 11, 0.3);
  border-radius: 40px;
  padding: 12px 13px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  min-width: 40px;
  min-height: 40px;
  line-height: 11px;
`;

const ResumeButton = styled(BarButton)`
  grid-area: resume;
`;
const RevertButton = styled(BarButton)`
  grid-area: revert;
  padding: 13px 24px;
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    margin: 12px 0 0 12px;
    width: 95%;
    justify-self: center;
  }

  & svg {
    margin-right: 6px;
  }
`;

const TimesContainer = styled(Gap8HorizontalFlexWrap)`
  justify-content: center;
  margin-bottom: 24px;
  align-items: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
`;

const MuteButtonContainer = styled.div`
  padding: 4px;
  grid-column: 1;
  grid-row: 1;
`;

const MuteButton = styled(IconButton)`
  color: white;
  filter: drop-shadow(0 3px 2px rgba(0, 0, 0, 1));
`;

const DurationContainer = styled.div`
  grid-area: duration;
  color: ${({ theme }) => theme.colors.white};
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    display: none;
  } ;
`;

export default VideoTrimPage;
