import { useEffect, useMemo } from 'react';
import { useBilling } from 'src/hooks/useBillings';
import { useAnalytics } from 'src/context/AnalyticsStore';
import { getMaxPageViews, parsePrice } from 'src/utils/pricing';

type PricingUsageState = {
  maxViews: number;
  price: number;
  progressPercent: number;
  isPricingDisabled: boolean;
  currentMonthPageView: number;
  isShopifySubscription: boolean;
};

export const usePricingUsage = (): PricingUsageState => {
  const { isPricingEnabled, packageGroup, currentPackage, isShopifySubscription } = useBilling();
  const [{ currentMonthPageView }, { fetchCurrentMonthPageView }] = useAnalytics();
  const isPricingDisabled = !isPricingEnabled || !packageGroup;

  useEffect(() => {
    if (isPricingDisabled || currentMonthPageView || currentMonthPageView === 0) {
      return;
    }

    fetchCurrentMonthPageView();
  }, [currentMonthPageView, isPricingDisabled, fetchCurrentMonthPageView]);

  const pricingState = useMemo(() => {
    const maxViews = getMaxPageViews(currentPackage);
    const progressPercent = Math.round((currentMonthPageView / maxViews) * 100);

    return {
      maxViews,
      isShopifySubscription,
      progressPercent,
      isPricingDisabled,
      price: parsePrice(currentPackage?.details?.price),
      currentMonthPageView: currentMonthPageView || 0,
    };
  }, [currentMonthPageView, currentPackage, isPricingDisabled]);

  return pricingState;
};
