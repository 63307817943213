import React from 'react';

function NotificationCalendarInviteIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" viewBox="0 0 40 40">
      <rect width="40" height="40" fill="#F8F3E7" rx="10" />
      <path
        fill="#FFC728"
        d="M25.5 14H24v-1c0-.531-.469-1-1-1-.563 0-1 .469-1 1v1h-4v-1c0-.531-.469-1-1-1-.563 0-1 .469-1 1v1h-1.5a1.5 1.5 0 00-1.5 1.5V17h14v-1.5c0-.813-.688-1.5-1.5-1.5zM13 26.5c0 .844.656 1.5 1.5 1.5h11a1.5 1.5 0 001.5-1.5V18H13v8.5z"
      />
    </svg>
  );
}

export default NotificationCalendarInviteIcon;
