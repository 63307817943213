import React, { useState, SyntheticEvent, useEffect } from 'react';
import styled from 'styled-components';
import { isOneAppSource } from 'app/src/utils/app.utils';
import {
  INSTAGRAM_GRAPH,
  SYNTHESIA,
  TIKTOK,
  GOOGLE_DRIVE,
  DROPBOX,
} from 'app/src/constants/intergrations.constants';
import {
  DangerButton,
  WhiteButton,
  SecondaryButton,
  GhostButton,
} from 'shared/react/components/basic/button-v2/BasicButton';
import { RECORDINGS, UPLOAD } from 'app/src/constants/recordingTypes.constants';
import { useApps } from 'app/src/context/AppsStore';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import useIntegrationsVideos from 'app/src/hooks/useIntegrationsVideos';
import DisconnectIcon from 'app/src/images/DisconnectIcon';
import MenuWithItems from 'app/src/complex_components/menu_items/MenuWithItems';
import { MenuItemProps } from 'app/src/complex_components/menu_items/types/MenuItem.types';
import { isSourceSelected } from 'app/src/pages/dashboard/pages/videos/constants/videos.constants';
import SynthesiaEmptyStateButton from 'app/src/pages/dashboard/pages/videos/components/empty-states/components/synthesia-empty-state-button/SynthesiaEmptyStateButton';
import { useGoogleDrive } from 'app/src/context/GoogleDriveStore';
import DropboxButton from 'app/src/pages/dashboard/pages/videos/components/empty-states/components/dropbox-button/DropboxButton';
import { vodAsset } from 'app/src/types/entities';
import { sensitiveDataFilter } from 'shared/react/components/basic/SensitiveData';
import { useDrawer } from 'app/src/context/ui_store/DrawerStore';
import { GlobalDrawerType } from 'app/src/complex_components/global_drawer/types/globalDrawer.types';
import CogIcon from 'app/src/images/CogIcon';

type Props = {
  toggleNewVideoMenu: (e: SyntheticEvent) => void;
  menuItems: MenuItemProps[];
  isModal?: boolean;
  currentVideoSource: string;
  currentAppExternalIds?: string[];
  onSelectedVideosAction?: (videos: vodAsset[]) => void;
};

const NewVideoButtons = ({
  toggleNewVideoMenu,
  menuItems,
  currentVideoSource,
  currentAppExternalIds = [],
  onSelectedVideosAction,
}: Props) => {
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [{ apps, shopify }] = useApps();
  const {
    tiktokApps,
    instagramApps,
    handleInstagramConnect,
    handleTiktokConnect,
    handleTikTokDisconnect,
    disconnectInstagramByAppExternalId,
    getTiktokUsername,
    getInstagramUsername,
    googleDrive,
  } = useIntegrationsVideos();
  const [{ pickingFolder }, { pickFolder }] = useGoogleDrive();
  const isOneSource = isOneAppSource(apps, currentAppExternalIds);
  const isOneAppSelected = currentAppExternalIds.length === 1;
  const isTikTok = isSourceSelected(TIKTOK, currentVideoSource) && isOneSource;
  const isGoogleDrive = isSourceSelected(GOOGLE_DRIVE, currentVideoSource) && isOneSource;
  const isInstagramSourceSelected = isSourceSelected(INSTAGRAM_GRAPH, currentVideoSource);
  const isInstagram = isInstagramSourceSelected && isOneSource;
  const isInstagramOneAppAvailable =
    isInstagram && (isOneAppSelected || instagramApps.length === 1);
  const isTikTokOnAppAvailable = isTikTok && (isOneAppSelected || tiktokApps.length === 1);
  const isSynthesia = isSourceSelected(SYNTHESIA, currentVideoSource);
  const shouldHideDisconnectButton = isInstagram && instagramApps.length > 1 && !isOneAppSelected;
  const [{ currentDrawer }, { setCurrentDrawer }] = useDrawer();

  const onInstagramSettingsClick = () => {
    setCurrentDrawer({ currentDrawer: GlobalDrawerType.InstagramSettings });
  };

  const onConnectClick = e => {
    if (isTikTok) {
      if (!tiktokApps.length) {
        handleTiktokConnect();
        return;
      }

      setMenuAnchor(e.currentTarget);
    } else if (isInstagram) {
      if (!instagramApps.length) {
        handleInstagramConnect();
        return;
      }

      setMenuAnchor(e.currentTarget);
    }
  };

  const onDisconnectClick = () => {
    if (isTikTokOnAppAvailable) {
      const appExternalId = isOneAppSelected
        ? currentAppExternalIds[0]
        : tiktokApps[0]?.appExternalId;

      handleTikTokDisconnect(appExternalId);
    } else if (isInstagramOneAppAvailable) {
      const appExternalId = isOneAppSelected
        ? currentAppExternalIds[0]
        : instagramApps[0]?.appExternalId;

      disconnectInstagramByAppExternalId(appExternalId);
    }
  };

  const getConnectButtonText = () => {
    if (isTikTok) {
      if (!tiktokApps.length) {
        return 'Connect';
      }

      if (isTikTokOnAppAvailable) {
        const appExternalId = isOneAppSelected
          ? currentAppExternalIds[0]
          : tiktokApps[0]?.appExternalId;

        return `@${getTiktokUsername({ appExternalId })}`;
      }

      return 'TikTok';
    } else if (isInstagram) {
      if (!instagramApps.length) {
        return 'Connect';
      }

      if (isInstagramOneAppAvailable) {
        const appExternalId = isOneAppSelected
          ? currentAppExternalIds[0]
          : instagramApps[0]?.appExternalId;

        return `@${getInstagramUsername({ appExternalId })}`;
      }

      return 'Instagram';
    }

    return null;
  };

  const onButtonClick = e => {
    if (menuItems.length === 1) {
      menuItems[0].onClick();
      return;
    }

    toggleNewVideoMenu(e);
  };

  const getText = () => {
    if (isTikTok) {
      return 'Import from URL';
    } else if (isInstagram) {
      return 'Import from Instagram';
    } else if (isSourceSelected(UPLOAD, currentVideoSource)) {
      return 'Upload file';
    } else if (isSourceSelected(RECORDINGS, currentVideoSource)) {
      return 'Record video';
    } else {
      return 'New asset';
    }
  };

  const buttonText = getText();

  const getConnectButton = () => {
    const connectButtonText = getConnectButtonText();

    if (!isInstagram && !isTikTok) {
      return null;
    }

    if ((!tiktokApps.length && isTikTok) || (!instagramApps.length && isInstagram)) {
      return <DangerButton onClick={onConnectClick}>{connectButtonText}</DangerButton>;
    }

    return (
      <StyledGhostButton className="notranslate" onClick={onConnectClick}>
        {connectButtonText}
      </StyledGhostButton>
    );
  };

  const closeMenu = () => {
    setMenuAnchor(null);
  };

  const getButton = () => {
    if (currentVideoSource === DROPBOX) {
      return <DropboxButton onSelectedVideosAction={onSelectedVideosAction} />;
    }

    if (isSynthesia) {
      return <SynthesiaEmptyStateButton isRedButton={true} />;
    }

    if (isInstagram && isInstagramOneAppAvailable && shopify) {
      return (
        <GhostButton onClick={onInstagramSettingsClick}>
          <CogIcon />
        </GhostButton>
      );
    }

    if (!menuItems.length) {
      return null;
    }

    if (isTikTok) {
      return <SecondaryButton onClick={onButtonClick}>{buttonText}</SecondaryButton>;
    }

    return <DangerButton onClick={onButtonClick}>{buttonText}</DangerButton>;
  };

  const getGoogleDriveFolderButton = () => {
    if (isGoogleDrive && googleDrive) {
      return (
        <SecondaryButton onClick={pickFolder} disabled={pickingFolder}>
          Select root folder
        </SecondaryButton>
      );
    }

    return null;
  };

  useEffect(() => {
    if (!currentDrawer) {
      return;
    }

    return () => {
      if (currentDrawer === GlobalDrawerType.InstagramSettings) {
        setCurrentDrawer({ currentDrawer: null });
      }
    };
  }, [currentDrawer]);

  return (
    <LayoutRoot>
      {getButton()}
      {getConnectButton()}
      {getGoogleDriveFolderButton()}
      <Menu
        anchorElement={menuAnchor}
        onClose={closeMenu}
        menuItems={[
          {
            onClick: onDisconnectClick,
            text: 'Disconnect',
            hidden: shouldHideDisconnectButton,
            icon: <DisconnectIcon />,
          },
        ]}
        direction={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlex)``;

const StyledGhostButton = styled(WhiteButton)`
  max-width: 200px;
  overflow: hidden;
  justify-content: left;
  ${sensitiveDataFilter()}
`;

const Menu = styled(MenuWithItems)`
  width: ${({ anchorElement }) => anchorElement?.offsetWidth}px;
`;

export default NewVideoButtons;
