import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { RowData } from '../../types/ProductsTable.types';
import ProductRow from '../product_row/ProductRow';

type Props = {
  products: RowData[];
  fetchProducts: () => void;
  removeProduct: (productId: string) => void;
};

const ProductsTable: FC<Props> = ({ products, fetchProducts, removeProduct }) => {
  const [expandedId, setExpandedId] = useState(products?.[0]?.id);

  const toggleExpanded = (id: string) => () => {
    setExpandedId(currentId => (id === currentId ? null : id));
  };

  const expandNext = (id: string) => {
    const index = products.findIndex(product => product.id === id);

    if (index === products.length - 1) {
      return;
    }

    setExpandedId(products[index + 1].id);
  };

  return (
    <TableWrapper>
      {products.map(product => (
        <ProductRow
          key={product.id}
          product={product}
          isExpanded={expandedId === product.id}
          toggleExpanded={toggleExpanded}
          expandNext={expandNext}
          fetchProducts={fetchProducts}
          removeProduct={removeProduct}
        />
      ))}
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  margin: 24px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0 8px;
`;

export default ProductsTable;
