import React from 'react';
import styled, { useTheme } from 'styled-components';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import { TextSmall } from 'shared/react/components/complex/Text';
import VIcon from 'app/src/images/VIcon';

function FontListItem({ style, isSelected, font, onFontItemClick }) {
  const theme = useTheme();

  return (
    <LayoutRoot
      style={style}
      isSelected={isSelected}
      onClick={() => onFontItemClick(font)}
    >
      <MainContainer>
        <FontFamily>{font.family}</FontFamily>
        <IconContainer isVisible={isSelected}>
          <VIcon fill={theme.colors.primary} width={16} />
        </IconContainer>
      </MainContainer>
    </LayoutRoot>
  );
}

export default FontListItem;

const LayoutRoot = styled.div`
  display: grid;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: ${({ theme }) => theme.colors.gray5};
  }
`;

const MainContainer = styled(Gap8HorizontalFlexWrap)`
  align-items: center;
  padding: 0 8px;
  flex-flow: row nowrap;
`;

const IconContainer = styled.div`
  display: grid;
  align-items: center;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

const FontFamily = styled(TextSmall)`
  width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray2};
`;
