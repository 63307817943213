import { OnboardingTemplateKey } from 'app/src/pages/dashboard/pages/homepage/onboarding/templates/constants/onboardingTemplates.constants';

export type OnboardingStatusTemplate = {
  stageIndex: number;
  isDone: boolean;
  projectId: string;
};

export const DEFAULT_ONBOARDING_STATUS_TEMPLATES: Record<
  OnboardingTemplateKey,
  OnboardingStatusTemplate
> = {
  pdpStories: {
    stageIndex: 0,
    isDone: false,
    projectId: '',
  },
  homepageCarousel: {
    stageIndex: 0,
    isDone: false,
    projectId: '',
  },
  shopAppProject: {
    stageIndex: 0,
    isDone: false,
    projectId: '',
  },
};
