import React from 'react';
import styled from 'styled-components';
import Utils from '../../utils';

// eslint-disable-next-line react/display-name
const Input = React.forwardRef(
  ({ onChange, name, placeholder, value, disabled, ...props }, ref) => {
    const handleChange = e => {
      onChange(e.target.value);
    };

    if (value && disabled) {
      onChange('');
    }

    return (
      <LeadFormInputField
        ref={ref}
        placeholder={placeholder || name}
        {...props}
        onChange={handleChange}
        value={value}
        disabled={disabled}
      />
    );
  }
);

const LeadFormInputField = styled.input`
  color: ${props => props.color};
  border-color: ${props => props.borderColor};
  border-bottom-width: 2px;
  &::placeholder {
    color: ${props => props.color};
    opacity: 0.5 !important;
    -webkit-transition: color 350ms linear;
    -ms-transition: color 350ms linear;
    transition: color 350ms linear;
    font-size: ${({ placeholder }) => (placeholder?.length > 20 ? '22px' : '')};
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: ${props => props.color} !important;
  }

  margin-left: ${({ theme }) => (Utils.isInnerTheme(theme) ? '18px' : 'initial')};
  height: ${({ theme }) => (Utils.isInnerTheme(theme) ? '14px' : '23px')};
  width: 100%;
  justify-self: center;
  align-self: center;
  font-size: ${({ theme }) => (Utils.isInnerTheme(theme) ? '14px' : '24px')};
  font-weight: 600;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: solid;
  appearance: none;
  background-color: transparent !important;
  -webkit-transition: background-color 0ms linear;
  -ms-transition: background-color 0ms linear;
  transition: background-color 0ms linear;
  outline: none;
  padding-bottom: 7px;

  &::placeholder {
    opacity: 0.8; /* Firefox */
    font-weight: 600;
  }
`;

export default Input;
