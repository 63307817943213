import { useFeatures } from 'app/src/context/FeaturesStore';
import { useEffect, useState } from 'react';

const useGetFeatures = () => {
  const [widgetFeatures, setWidgetFeature] = useState({});
  const [playerFeatures, setPlayerFeature] = useState({});
  const [{ data }] = useFeatures();

  useEffect(() => {
    const newWidgetFeatures = {};
    const newPlayerFeatures = {};

    for (const [key, feature] of Object.entries(data)) {
      if (!feature) {
        return;
      }

      if (feature.widget) {
        newWidgetFeatures[key] = feature.settings;
      }

      if (feature.player) {
        newPlayerFeatures[key] = feature.settings;
      }
    }

    setPlayerFeature(newPlayerFeatures);
    setWidgetFeature(newWidgetFeatures);
  }, [data]);

  return { widgetFeatures, playerFeatures };
};

export default useGetFeatures;
