import React from 'react';
import styled, { css } from 'styled-components';
import BasicAccordion from 'shared/react/components/BasicAccordion';
import Separator from 'shared/react/components/basic/Separator';
import Gap8VerticalFlex from '../../flex_layouts/Gap8VerticalFlex';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';
import {
  FEED_PRODUCT_DESCRIPTION_CONTENT_DATA_TYPE,
  FEED_PRODUCT_DESCRIPTION_DATA_TYPE,
  FEED_PRODUCT_DESCRIPTION_TITLE_DATA_TYPE,
} from 'shared/react/constants/feedPreviewDataTypes.constants';
import { TextBodyBold, TextSmall } from 'shared/react/components/basic/text/TextV2';
import useIsMilk from 'shared/react/hooks/useIsMilk';
import useProjectConfig from '../../context/hooks/useProjectConfig';
import { getDesignCss } from 'shared/react/utils/addSharedCss';

const FeedProductDescription = ({ descriptionHtml }) => {
  const [, { t }] = useTranslation('feed.description');
  const { player, feedDesign } = useDesignSettingsContext();
  const [{ playerSettings }] = useProjectConfig();
  const { headerColorText, bodyColorText } = player.feedCards.text;
  const isMilkMakeup = useIsMilk();
  const { productDescriptionCollapsedByDefault = false, productDescriptionAlwaysShow = false } =
    playerSettings || {};

  if (!descriptionHtml) {
    return null;
  }

  if (productDescriptionAlwaysShow) {
    return (
      <Content
        dangerouslySetInnerHTML={{ __html: descriptionHtml }}
        data-type={FEED_PRODUCT_DESCRIPTION_CONTENT_DATA_TYPE}
        bodyColorText={bodyColorText}
        $design={feedDesign?.inFeedPDP?.description}
      />
    );
  }

  return (
    <LayoutRoot data-type={FEED_PRODUCT_DESCRIPTION_DATA_TYPE}>
      <BasicAccordion
        header={
          <CustomAccordionHeader
            data-type={FEED_PRODUCT_DESCRIPTION_TITLE_DATA_TYPE}
            headerColorText={headerColorText}
            isMilk={isMilkMakeup}
          >
            {isMilkMakeup ? 'What It Is' : t('description')}
          </CustomAccordionHeader>
        }
        startExpanded={!productDescriptionCollapsedByDefault}
      >
        <Content
          dangerouslySetInnerHTML={{ __html: descriptionHtml }}
          data-type={FEED_PRODUCT_DESCRIPTION_CONTENT_DATA_TYPE}
          bodyColorText={bodyColorText}
          $design={feedDesign?.inFeedPDP?.description}
        />
      </BasicAccordion>
      <Separator />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)`
  text-align: left;
  line-height: 22px;
  img {
    max-width: 100%;
    height: auto;
  }
  svg {
    width: 30px;
    height: 30px;
  }
`;

const milkCss = css`
  font-size: 26px;
  line-height: 30px;
  letter-spacing: -1px;
  text-transform: capitalize;
`;

const CustomAccordionHeader = styled(TextBodyBold)`
  width: 100%;
  padding: 8px 0px;
  color: ${({ headerColorText, theme }) => headerColorText || theme.colors.black};
  text-align: initial;
  font-weight: 600;
  ${({ isMilk }) => (isMilk ? milkCss : '')};
`;

const Content = styled(TextSmall)`
  color: ${({ bodyColorText, theme }) => bodyColorText || theme.colors.black};
  text-align: initial;
  ${({ $design }) => getDesignCss($design)};
`;

export default FeedProductDescription;
