import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import XButton from 'app/src/complex_components/XButton';
import BasicMenu from 'app/src/complex_components/BasicMenu';
import RuleCondition from 'app/src/pages/project/pages/rules_and_triggers/editor/shared/RuleCondition';
import BasicInput from 'app/src/basic_components/BasicInput';
import { TextSmall, TextTiny } from 'shared/react/components/complex/Text';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';

import {
  ANY_VALUE_KEY,
  BOOLEAN_CONDITION_TYPE,
  CONTAINS_KEY,
  DATE_CONDITION_TYPE,
  EQUALS_KEY,
  FIRST_SEEN_AT_KEY,
  IN_KEY,
  LAST_SEEN_AT_KEY,
  NOT_CONTAINS_KEY,
  NOT_EQUALS_KEY,
  NUMBER_CONDITION_TYPE,
  SESSION_COUNT_KEY,
  SIGNED_UP_AT_KEY,
  TOLSTOY_IMPRESSIONS_SESSION_COUNT_KEY,
  TOLSTOY_PLAY_SESSION_COUNT_KEY,
} from 'app/src/constants/ruleGroups.constants';
import {
  booleanConditionMenuItems,
  booleanValuesMenuItems,
  conditionPlaceholders,
  dateConditionMenuItems,
  dateConditionTypes,
  defaultConditionPlaceholder,
  generalConditionTypesMenuItems,
  numberConditionMenuItems,
  numberConditionTypes,
  typePlaceholders,
} from 'app/src/pages/project/pages/rules_and_triggers/Rule.constants';
import Utils from 'app/src/utils';
import { track } from 'app/src/helpers/Tracker';
import { useRulesContext } from 'app/src/pages/project/pages/rules_and_triggers/RulesContext';
import { useRulesEditorContext } from 'app/src/pages/project/pages/rules_and_triggers/editor/RulesEditorContext';
import ExcludingRuleIcon from 'app/src/images/ExcludingRuleIcon';

function Rule({
  isInvalid,
  setRuleGroup,
  clearInvalidRules,
  rule,
  ruleKey,
  andConditionIndex,
  orConditionIndex,
  attributes,
  orConditions,
}) {
  const { ruleGroup } = useRulesContext();
  const { deleteRule } = useRulesEditorContext();

  const [ref, setRef] = useState(null);
  const currentAttribute = getRuleAttribute();
  const theme = useTheme();

  function handleConditionTypeChange(condition) {
    track('Advanced Rules Condition Changed');
    handlePropertyChange('condition', condition);
    if (condition === ANY_VALUE_KEY) {
      handleValueChange('');
    }
  }

  function handlePropertyChange(key, value) {
    const newRuleGroup = {
      ...ruleGroup,
    };
    const andCondition = newRuleGroup.rules[andConditionIndex];
    const rule = andCondition.find(condition => condition.key === ruleKey);
    rule[key] = value;

    setRuleGroup(newRuleGroup);
  }

  function clearInvalidRule() {
    clearInvalidRules(andConditionIndex, orConditionIndex);
  }

  function handleValueChange(value) {
    clearInvalidRule();
    const newRuleGroup = {
      ...ruleGroup,
    };
    const andCondition = newRuleGroup.rules[andConditionIndex];
    const rule = andCondition.find(condition => condition.key === ruleKey);
    rule.value = value;
    setRuleGroup(newRuleGroup);
  }

  function getRuleAttribute(type = rule.type) {
    return attributes.find(attribute => attribute.key === type);
  }

  function getMenuItems(type) {
    type = rule.type || type;

    const specificDataType = getRuleAttribute(type).type;

    const specificMenuItems = {
      [SIGNED_UP_AT_KEY]: dateConditionMenuItems,
      [FIRST_SEEN_AT_KEY]: dateConditionMenuItems,
      [LAST_SEEN_AT_KEY]: dateConditionMenuItems,
      [DATE_CONDITION_TYPE]: dateConditionMenuItems,
      [NUMBER_CONDITION_TYPE]: numberConditionMenuItems,
      [SESSION_COUNT_KEY]: numberConditionMenuItems,
      [BOOLEAN_CONDITION_TYPE]: booleanConditionMenuItems,
      [TOLSTOY_PLAY_SESSION_COUNT_KEY]: numberConditionMenuItems,
      [TOLSTOY_IMPRESSIONS_SESSION_COUNT_KEY]: numberConditionMenuItems,
    };

    return (
      specificMenuItems[type] ||
      specificMenuItems[specificDataType] ||
      generalConditionTypesMenuItems
    );
  }

  function getRuleConditionTitle() {
    const menuItems = getMenuItems();
    return menuItems.find(menuItem => menuItem.key === rule.condition)?.title;
  }

  function getInputType() {
    const specificDataType = currentAttribute.key;

    const numberDataTypes = [
      NUMBER_CONDITION_TYPE,
      SESSION_COUNT_KEY,
      TOLSTOY_IMPRESSIONS_SESSION_COUNT_KEY,
      TOLSTOY_PLAY_SESSION_COUNT_KEY,
    ];
    if (
      numberDataTypes.includes(specificDataType) ||
      numberConditionTypes.includes(rule.condition)
    ) {
      return NUMBER_CONDITION_TYPE;
    }
    if (specificDataType === DATE_CONDITION_TYPE || dateConditionTypes.includes(rule.type)) {
      return DATE_CONDITION_TYPE;
    }

    return null;
  }

  const hasConditionsMenu = getMenuItems().length > 1;

  function getInputPlaceholder() {
    if (isInvalid) {
      return 'Please enter a URL';
    }

    const specificDataType = currentAttribute.key;

    let conditionPlaceholder =
      conditionPlaceholders[rule.condition] || conditionPlaceholders[specificDataType];
    if (!Utils.isNullOrUndefined(conditionPlaceholder)) {
      return conditionPlaceholder;
    }

    if (specificDataType) {
      const typePlaceholder = typePlaceholders[specificDataType];

      if (!Utils.isNullOrUndefined(typePlaceholder)) {
        return typePlaceholder;
      }
    }

    return defaultConditionPlaceholder;
  }

  const getIsRulesContradicting = () => {
    return orConditions.some((condition, i) => {
      if (i === orConditionIndex) {
        return;
      }

      const isSameValue = rule.value && condition.value === rule.value;

      const isContainRuleContradicts =
        condition.condition === CONTAINS_KEY && rule.condition === NOT_CONTAINS_KEY;

      if (isContainRuleContradicts && isSameValue) {
        return true;
      }

      const isEqualsRuleContradicts =
        condition.condition === NOT_EQUALS_KEY && rule.condition === EQUALS_KEY;

      if (isEqualsRuleContradicts && isSameValue) {
        return true;
      }
    });
  };

  useEffect(() => {
    if (!ref || !!rule.value?.length) {
      return;
    }

    ref.focus();
  }, [ref]);

  const isValueContainerDisabled = rule.condition === ANY_VALUE_KEY;
  const isBoolean = currentAttribute.key === BOOLEAN_CONDITION_TYPE;
  const isInCondition = rule.condition === IN_KEY;

  const isNegativeCondition = getIsRulesContradicting();

  return (
    <LayoutRoot>
      <TitleContainer>
        <TextSmall>{currentAttribute.title}</TextSmall>
        {isNegativeCondition && (
          <ExcludingContainer>
            <ExcludingRuleIcon />
            <ExcludingTitle>Contradicting rules</ExcludingTitle>
          </ExcludingContainer>
        )}
      </TitleContainer>
      <MainContainer>
        <BasicMenu
          selectedMenuItemKey={rule.condition}
          menuItems={getMenuItems()}
          onMenuItemClick={menuItem => handleConditionTypeChange(menuItem.key)}
        >
          <RuleCondition hasMenu={hasConditionsMenu} isNegativeCondition={isNegativeCondition}>
            {getRuleConditionTitle()}
          </RuleCondition>
        </BasicMenu>

        <RuleValueContainer
          isBoolean={isBoolean}
          error={isInvalid}
          isNegativeCondition={isNegativeCondition}
          disabled={isValueContainerDisabled}
        >
          <Input
            rule={rule}
            handleValueChange={handleValueChange}
            isBoolean={isBoolean}
            isValueContainerDisabled={isValueContainerDisabled}
            isInvalid={isInvalid}
            setRef={setRef}
            getInputType={getInputType}
            getInputPlaceholder={getInputPlaceholder}
            isInCondition={isInCondition}
          />
        </RuleValueContainer>
        <StyledXButton
          size={16}
          fill={theme.colors.danger}
          onClick={() => deleteRule(andConditionIndex, ruleKey)}
        />
      </MainContainer>
    </LayoutRoot>
  );
}

const Input = ({
  rule,
  handleValueChange,
  isBoolean,
  isValueContainerDisabled,
  isInvalid,
  getInputType,
  getInputPlaceholder,
  setRef,
  isInCondition,
}) => {
  if (isBoolean) {
    return (
      <BasicMenu
        selectedMenuItemKey={rule.condition}
        menuItems={booleanValuesMenuItems}
        onMenuItemClick={menuItem => handleValueChange(menuItem.key)}
      >
        <RuleCondition hasMenu={true}>{`${rule.value}`}</RuleCondition>
      </BasicMenu>
    );
  }

  return (
    <StyledBasicInput
      disabled={isValueContainerDisabled}
      error={isInvalid}
      type={getInputType()}
      ref={setRef}
      maxLength={isInCondition ? 10000 : 1000}
      value={rule.value}
      placeholder={getInputPlaceholder()}
      onChange={e => {
        e.stopPropagation();
        handleValueChange(e.target.value);
      }}
    />
  );
};

export default Rule;

const LayoutRoot = styled(Gap8VerticalFlex)``;

const MainContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
`;

const TitleContainer = styled(Gap8HorizontalFlexWrap)``;

const ExcludingContainer = styled(HorizontalFlexWrap)`
  gap: 4px;
  align-items: center;
`;

const ExcludingTitle = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.red5};
`;

const RuleValueContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: ${({ isBoolean }) => (isBoolean ? '' : '8px')};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  border: 1px solid
    ${({ error, isNegativeCondition, theme }) =>
      error || isNegativeCondition ? theme.colors.red2 : theme.colors.gray3};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1.0)};
`;

const StyledXButton = styled(XButton)`
  padding-right: 0;
`;

const StyledBasicInput = styled(BasicInput)`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
`;
