import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import ProIcon from 'app/src/images/ProIcon';
import { TextBody, TextH5 } from 'shared/react/components/complex/Text';
import StandardModal from 'app/src/pages/modals/StandardModal';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import Button from 'shared/react/components/complex/Button';
import { track } from 'app/src/helpers/Tracker';
import Utils from 'app/src/utils';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';
import useIsMounted from 'shared/react/hooks/useIsMounted';
import Types from 'shared/react/theme/Types';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';

const RemoveAddonModal = () => {
  const [{ modalProps }, { setCurrentModal }] = useModal();
  const [, { removeAddon }] = useFeatures();
  const [, { setErrorSnackbar }] = useSnackBar();
  const isMounted = useIsMounted();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const { removeModalText, addonId, name, setEnabled } = modalProps;

  const onClose = () => {
    track(`${name} Addon Remove Canceled`);
    setCurrentModal('');
  };

  const handleRemoveAddonClicked = async () => {
    setLoading(true);
    const { error, returnUrl } = (await removeAddon(addonId)) || {};

    if (!isMounted()) {
      return;
    }

    if (returnUrl) {
      track(`Shopify ${name} Remove Addon Click`);
      return Utils.openInTheSameTab(returnUrl);
    }

    setLoading(false);

    if (error) {
      setErrorSnackbar('Something went wrong. please try again');
      return;
    }

    track(`${name} Addon Downgraded`);
    setEnabled(false);
    onClose();
  };

  return (
    <StandardModal open onClose={onClose} width="440px">
      <LayoutRoot>
        <ProIcon fill={theme.colors.black} width={28} height={20} />
        <ContentContainer>
          <TextH5>Are you sure?</TextH5>
          <ModalBody>
            <StyledTextBody>You will no longer enjoy the add-on.</StyledTextBody>
            <SubtitleText>{removeModalText}</SubtitleText>
          </ModalBody>
        </ContentContainer>
        <ButtonContainer>
          <Button onClick={handleRemoveAddonClicked} loading={loading} type={Types.Secondary}>
            Remove add-on
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ButtonContainer>
      </LayoutRoot>
    </StandardModal>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  gap: 24px;
`;

const StyledTextBody = styled(TextBody)`
  font-weight: 800;
`;

const SubtitleText = styled(TextBody)`
  color: ${({ theme }) => theme.colors.ghostDark};
`;

const ContentContainer = styled(VerticalFlex)`
  gap: 24px;
  margin-bottom: 16px;
`;

const ModalBody = styled(Gap8VerticalFlex)``;

const ButtonContainer = styled(Gap8HorizontalFlexWrap)`
  justify-content: flex-end;
`;

export default RemoveAddonModal;
