import styled from 'styled-components';

export default styled.input<{ error?: boolean }>`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid
    ${({ theme, error }) => (error ? theme.colors.warning : theme.colors.ghostLight)};
  border-radius: 8px;
  padding: 10px 16px;
  height: 32px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  outline: none;
`;
