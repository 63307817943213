import React from 'react';
import CarouselDots from './CarouselDots';
import { LEFT, RIGHT } from './carousel.constants';
import CarouselArrow from './CarouselArrow';
import { useFeedIsMobile } from 'shared/react/hooks/useIsMobile';
import styled from 'styled-components';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

const MAX_DOTS = 5;

const getOperatorResult = (direction, selectedIndex) => {
  if (direction === RIGHT) {
    return selectedIndex + 1;
  }

  return selectedIndex - 1;
};

const CarouselNavigation = ({
  itemsLength,
  minimumItemsLength = 1,
  scrollIndexIntoView,
  selectedIndex,
  onArrowClick,
  onDotClick,
  fullScreen,
  isZoomedIn,
  withoutArrows,
}) => {
  const isMobile = useFeedIsMobile();
  const numberOfDots = Math.min(itemsLength, MAX_DOTS);

  const getSelectedDot = () => {
    const overflowMiddle = Math.floor(MAX_DOTS / 2);
    if (itemsLength <= MAX_DOTS || selectedIndex <= overflowMiddle) {
      return selectedIndex;
    }

    if (selectedIndex >= itemsLength - overflowMiddle) {
      const countFromEnd = itemsLength - selectedIndex;
      return MAX_DOTS - countFromEnd;
    }

    return Math.floor(overflowMiddle);
  };

  const selectedDot = getSelectedDot();

  const reverseIndex = {
    [RIGHT]: 0,
    [LEFT]: itemsLength - 1,
  };

  const getNextIndex = direction => {
    const nextIndex = getOperatorResult(direction, selectedIndex);
    if (nextIndex >= itemsLength || nextIndex < 0) {
      return reverseIndex[direction];
    }

    return nextIndex;
  };

  const handleArrowClick = direction => {
    onArrowClick?.();
    const nextIndex = getNextIndex(direction);
    scrollIndexIntoView(nextIndex);
  };

  const getNewIndexByDot = dotIndex => {
    if (dotIndex === MAX_DOTS - 1) {
      return itemsLength - 1;
    }

    if (dotIndex === 0) {
      return 0;
    }

    const jump = dotIndex - selectedDot;
    return selectedIndex + jump;
  };

  const handleDotClick = dotIndex => {
    onDotClick?.();
    const newIndex = getNewIndexByDot(dotIndex);
    scrollIndexIntoView(newIndex);
  };

  const getCarouselArrow = direction => {
    if (withoutArrows) {
      return '';
    }

    return (
      <CarouselArrow
        direction={direction}
        onClick={handleArrowClick}
        largePadding={fullScreen && !isMobile}
      />
    );
  };

  if (itemsLength < minimumItemsLength || isZoomedIn) {
    return null;
  }

  return (
    <LayoutRoot>
      <CarouselDots
        selectedDot={selectedDot}
        onClick={handleDotClick}
        numberOfDots={numberOfDots}
      />
      {getCarouselArrow(LEFT)}
      {getCarouselArrow(RIGHT)}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)`
  justify-content: center;
`;

export default CarouselNavigation;
