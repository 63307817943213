import React, { useState } from 'react';
import styled from 'styled-components';
import { TextH5, TextSmall } from 'shared/react/components/complex/Text';
import Gap16HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap16HorizontalFlexWrap';
import Button from 'shared/react/components/complex/Button';
import Types from 'shared/react/theme/Types';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import BasicInput from 'app/src/basic_components/BasicInput';
import { useUser } from 'app/src/context/userStore/UserStore';
import Utils from '../../../utils';
import { Label } from 'app/src/styled_components';
import { v4 as uuidv4 } from 'uuid';

function EditCalendarModalContent({ onClose }) {
  const [, { updateUser, getUser }] = useUser();
  const user = getUser();
  const [url, setUrl] = useState(user?.calendars?.[0]?.url);
  const [name, setName] = useState(user?.calendars?.[0]?.name);
  const [isLoading, setLoading] = useState(false);
  const [isUrlError, setIsUrlError] = useState(false);
  const [isNameError, setIsNameError] = useState(false);

  async function handleKeyDown(event) {
    setIsNameError(false);
    setIsUrlError(false);
    if (event.keyCode === 13) {
      await handleSave();
    }
  }

  const handleSave = async () => {
    setLoading(true);
    if (!Utils.isValidUrl(url) && url !== '') {
      setIsUrlError(true);
      setLoading(false);
      return;
    }

    if (!name && url) {
      setIsNameError(true);
      setLoading(false);
      return;
    }

    const uuid = uuidv4();
    await updateUser({ id: user.id, calendars: [{ id: uuid, name, url }] });
    setLoading(false);
    onClose();
  };

  return (
    <LayoutRoot>
      <TextH5>Edit calendar information</TextH5>
      <FieldsContainer>
        <StyledVerticalFlex>
          <TextSmall>Calendar name</TextSmall>
          <StyledBasicInput
            placeholder="Calendar name"
            value={name}
            onChange={e => setName(e.target.value)}
            onKeyDown={handleKeyDown}
            isError={isNameError}
            maxLength={30}
          />
          <ErrorLabel isError={isNameError}>Please enter a name</ErrorLabel>
        </StyledVerticalFlex>
        <StyledVerticalFlex>
          <TextSmall>Calendar URL</TextSmall>
          <StyledBasicInput
            placeholder="Calendar URL"
            value={url}
            onChange={e => setUrl(e.target.value)}
            onKeyDown={handleKeyDown}
            isError={isUrlError}
            maxLength={200}
          />
          <ErrorLabel isError={isUrlError}>Please enter a valid URL</ErrorLabel>
        </StyledVerticalFlex>
      </FieldsContainer>
      <StyledGap8HorizontalFlex>
        <Button type={Types.Ghost} onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleSave} loading={isLoading}>
          Save
        </Button>
      </StyledGap8HorizontalFlex>
    </LayoutRoot>
  );
}

export default EditCalendarModalContent;

const LayoutRoot = styled(VerticalFlex)`
  gap: 24px;
`;

const StyledGap8HorizontalFlex = styled(Gap8HorizontalFlexWrap)`
  align-self: end;
`;

const StyledVerticalFlex = styled(VerticalFlex)`
  gap: 4px;
`;

const StyledBasicInput = styled(BasicInput)`
  min-width: 275px;
  border: ${({ isError, theme }) =>
    isError ? `1px solid ${theme.colors.error}` : `1px solid ${theme.colors.gray3}`};
  padding: 12px;
  border-radius: 10px;
  width: 100%;
  height: 44px;
`;

const FieldsContainer = styled(Gap16HorizontalFlexWrap)``;

const ErrorLabel = styled(Label)`
  color: ${({ theme }) => theme.colors.error};
  visibility: ${({ isError }) => (isError ? 'visible' : 'hidden')};
`;
