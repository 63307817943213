import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';
import FeedMultipleProductDetails from 'shared/react/components/complex/feed/feed_products/feed_multiple_products/FeedMultipleProductDetails';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import FeedFocusedProduct from 'shared/react/components/complex/feed/feed_products/feed_multiple_products/FeedFocusedProduct';
import useFeedCart from 'shared/react/components/complex/context/hooks/useFeedCart';
import { getProductsWithVariantsFromStep } from 'shared/react/utils/feedProducts.utils';
import FeedBackButton from 'shared/react/components/basic/FeedBackButton';
import { useIsMobile } from 'shared/react/hooks/useIsMobile';
import { useFeedStateContext } from 'shared/react/components/complex/context/FeedStateContext';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';
import FeedMultipleProductsDivider from './FeedMultipleProductsDivider';
import useIsFenty from 'shared/react/hooks/useIsFenty';
import FeedMultipleProductsTitle from 'shared/react/components/complex/feed/feed_products/feed_multiple_products/FeedMultipleProductsTitle';
import useFeedProducts from 'shared/react/components/complex/context/hooks/useFeedProducts';
import Utils from 'shared/react/utils/utils';
import useTracker from 'shared/react/components/complex/context/hooks/useTracker';
import { PLAYER_TYPES } from 'shared/react/constants/tolstoy.constants';

const FeedMultipleProducts = ({
  currentStepKey,
  isFullWidth,
  isHorizontal,
  landingPage,
  handleBackButton,
  priorityProductId,
}) => {
  const [, { t }] = useTranslation('feed.cart');
  const [{ playerSettings }, { getCurrentStep }] = useProjectConfig();
  const [{ numberOfCartItems }] = useFeedCart();
  const [focusedProduct, setFocusedProduct] = useState(null);
  const [focusedVariantId, setFocusedVariantId] = useState(null);
  const currentStep = getCurrentStep(currentStepKey);
  const isMobile = useIsMobile();
  const [priorityProduct] = useFeedProducts(priorityProductId);
  const { isPlayerPreview, currentPageDbProductId } = useFeedStateContext();
  const [, { track }] = useTracker();

  const setFocusedProductWithVariant = (product, variantId) => {
    setFocusedProduct(product);
    setFocusedVariantId(variantId);

    const products = product ? JSON.stringify(product) : '';
    const productIds = product?.id ? JSON.stringify([product?.id]) : '';
    const productNames = `${product?.title || ''}`;

    track('clickViewProduct', {
      ...Utils.getAnalyticsLocationObject(PLAYER_TYPES.feed),
      products,
      productIds,
      productNames,
    });
  };

  const { multipleProductsSettings } = playerSettings;
  const isFenty = useIsFenty();

  const productsWithVariants = getProductsWithVariantsFromStep(currentStep, {
    priorityProductId: priorityProductId || currentPageDbProductId,
  });

  useEffect(() => {
    if (isFenty || !priorityProduct || !isMobile) {
      return;
    }

    setFocusedProduct(priorityProduct);
  }, [priorityProductId]);

  if (focusedProduct && !isFenty) {
    return (
      <FeedFocusedProduct
        focusedProduct={focusedProduct}
        focusedVariantId={focusedVariantId}
        setFocusedProduct={setFocusedProduct}
        currentStepKey={currentStepKey}
        isFullWidth={isFullWidth}
        isHorizontal={isHorizontal}
        disableZoomIn={landingPage}
        priorityProductId={priorityProductId}
        closeModal={handleBackButton}
      />
    );
  }

  const shouldShowBackButton = !!isMobile || isPlayerPreview;

  return (
    <LayoutRoot isCartOpen={!!numberOfCartItems}>
      {shouldShowBackButton && <FeedBackButton onClick={handleBackButton} />}
      <ProductsContainer>
        <FeedMultipleProductsTitle settings={multipleProductsSettings} />
        {productsWithVariants.map(({ id, variantId }, index) => (
          <React.Fragment key={`${id}_${variantId}`}>
            <FeedMultipleProductDetails
              productId={id}
              variantId={variantId}
              currentStepKey={currentStepKey}
              setFocusedProductWithVariant={setFocusedProductWithVariant}
              isHorizontal={isHorizontal}
              multipleProductsSettings={multipleProductsSettings}
            />
            <FeedMultipleProductsDivider
              productsWithVariants={productsWithVariants}
              settings={multipleProductsSettings}
              index={index}
            />
          </React.Fragment>
        ))}
      </ProductsContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  width: 100%;
  height: 100%;
`;

const ProductsContainer = styled(LayoutRoot)`
  box-sizing: border-box;
  overflow: auto;
  gap: 16px;
  padding: 20px 24px ${({ isCartOpen }) => (isCartOpen ? '170px' : '')};
`;

export default FeedMultipleProducts;
