import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { ONBOARDING } from 'app/src/constants/ui.constants';
import Routes from '../../helpers/Routes';
import { useHistory } from 'react-router-dom';
import { useUser } from 'app/src/context/userStore/UserStore';

import {
  COMPANY_MONTHLY_TRAFFIC_DATA_TEST_ID,
  COMPANY_VERTICAL_DATA_TEST_ID,
  CONTINUE_BUTTON_DATA_TEST_ID,
} from 'app/src/constants/dataTestIds.constants';
import { track } from '../../helpers/Tracker';
import TolstoyEmptyScreen from 'app/src/layouts/tolstoy_empty_screen/TolstoyEmptyScreen';
import Button from 'shared/react/components/complex/Button';
import Types from 'shared/react/theme/Types';
import Gap16HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap16HorizontalFlexWrap';
import { useApps } from 'app/src/context/AppsStore';
import OnboardingContent from 'app/src/pages/onboarding/OnboardingContent';
import { ECOMMERCE } from 'app/src/constants/accountSettings.constants';
import Video from 'app/src/images/VideoIcon';
import { hideChat, showChat } from 'app/src/utils/support.utils';
import CreateNewTolstoyButtonMenu from 'app/src/pages/dashboard/pages/libraries/CreateNewTolstoyButtonMenu';

const Onboarding = () => {
  const [data, setData] = useState({
    [COMPANY_MONTHLY_TRAFFIC_DATA_TEST_ID]: '',
  });
  const [stage, setStage] = useState(1);
  const [otherVerticalValue, setOtherVerticalValue] = useState('');
  const [otherRoleValue, setOtherRoleValue] = useState('');
  const [isLoading, setIsLoading] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const [, { updateAccountSettings }] = useUser();
  const [{ selectedAppUrl, shopify }] = useApps();
  const theme = useTheme();

  const moveToNextPage = () => {
    history.push({
      pathname: Routes.getDashboardBaseRoute(),
      state: {
        fromSignup: true,
      },
    });
  };

  const changeData = (value, type) => {
    let newData = { [type]: value };

    newData = { ...data, ...newData };
    if (type === COMPANY_VERTICAL_DATA_TEST_ID) {
      newData = { ...newData, companyRole: null, monthlyTraffic: null };
    }

    setData(newData);

    track('OnBoarding Question Clicked', { question: `${type}-${value}` });
  };

  const onContinue = async (e, continueClicked = true) => {
    if (stage === 1 && continueClicked) {
      setStage(2);
      return;
    }

    if (!continueClicked && !data.companyVertical) {
      return moveToNextPage();
    }

    if (isLoading) {
      return;
    }

    if (continueClicked) {
      track('OnBoarding Continue Click');
    }

    setIsLoading(true);
    const error = await updateAccountSettings(data);

    if (error?.message) {
      setIsLoading(false);
      return;
    }

    if (continueClicked) {
      track('Create New Click Finish Onboarding');
      setAnchorEl(e.target);
      setIsLoading(false);
      return;
    }

    moveToNextPage();
  };

  const onSkip = async () => {
    track('OnBoarding Close Click');
    await onContinue(null, false);
  };

  useEffect(() => {
    if (!shopify) {
      return;
    }

    let newData = { [COMPANY_VERTICAL_DATA_TEST_ID]: ECOMMERCE };
    newData = { ...data, ...newData };
    setData(newData);
  }, [shopify]);

  useEffect(() => {
    hideChat();

    track('OnBoarding Page Open');

    return () => {
      showChat();
    };
  }, []);

  const getShouldShowContinue = () => {
    if (stage === 1) {
      return data.companyVertical && data.companySize && (data.companyRole || data.monthlyTraffic);
    }

    return data.useCase;
  };

  const shouldShowContinue = getShouldShowContinue();
  const isLastStage = stage === 2;

  return (
    <OnboardingContainer>
      <OnboardingContent
        onAnswerClick={changeData}
        data={data}
        stage={stage}
        isShopify={!!shopify}
        otherVerticalValue={otherVerticalValue}
        setOtherVerticalValue={setOtherVerticalValue}
        otherRoleValue={otherRoleValue}
        setOtherRoleValue={setOtherRoleValue}
      />
      <ButtonsContainer>
        <Button onClick={onSkip} type={Types.Secondary}>
          {isLastStage ? 'Done' : 'Skip'}
        </Button>
        <Button
          showLeftIcon={isLastStage}
          leftIcon={<Video fill={theme.colors.white} />}
          data-test-id={CONTINUE_BUTTON_DATA_TEST_ID}
          loading={isLoading}
          disabled={!shouldShowContinue}
          onClick={onContinue}
        >
          {isLastStage ? 'Create my first Tolstoy' : 'Continue'}
        </Button>
      </ButtonsContainer>
      <CreateNewTolstoyButtonMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        setLoading={setIsLoading}
        location={ONBOARDING}
        appUrl={selectedAppUrl}
      />
    </OnboardingContainer>
  );
};

const OnboardingContainer = styled(TolstoyEmptyScreen)`
  gap: 64px;
  overflow: unset;
  padding: 16px 0;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    gap: 24px;
  }
`;

const ButtonsContainer = styled(Gap16HorizontalFlexWrap)`
  align-self: flex-end;
  align-content: flex-end;
  position: fixed;
  right: 24px;
  bottom: 23px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    position: relative;
    bottom: unset;
  }
`;

export default Onboarding;
