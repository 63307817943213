import React from 'react';
import styled from 'styled-components';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import ShopifyLogoIcon from 'app/src/images/creation_method_icons/ShopifyLogoIcon';
import StraightArrowRightIcon from 'app/src/images/creation_method_icons/StraightArrowRightIcon';
import Utils from 'app/src/utils';
import { track } from 'app/src/helpers/Tracker';
import { PrimaryButton } from 'shared/react/components/basic/button-v2/BasicButton';

type Props = {
  appUrl: string;
};

const ShopifyInstallText = ({ appUrl }: Props) => {
  const handleOpenDeepLink = () => {
    track('Navigate To Shopify Click');
    Utils.handleOpenDeepLink(appUrl);
  };

  return (
    <LayoutRoot>
      Before you can set this Tolstoy live, please enable our app on your theme.
      <DeepLinkButton onClick={handleOpenDeepLink}>
        <ShopifyLogoIcon />
        Go To Shopify
        <StraightArrowRightIcon />
      </DeepLinkButton>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)``;

const DeepLinkButton = styled(PrimaryButton)`
  margin-right: auto;

  & svg {
    width: 14px;
    height: 14px;
  }

  & path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export default ShopifyInstallText;
