import React from 'react';
import { track } from 'player/src/tracker/Tracker';
import Utils from 'player/src/utils';
import styled from 'styled-components';
import tolstoyIconName from 'player/src/assets/tolstoy_logo_name_white.svg';
import tolstoyIcon from 'player/src/assets/tolstoy_quotation_white.svg';

const WaterMark = ({
  setNextRef,
  onKeyDown,
  isFeedModeActive,
  hidePoweredBy,
  semiPoweredBy,
  isPanelExpanded,
  disableGradient,
}) => {
  const handleTolstoyClick = () => {
    track('tolstoyClick');
    Utils.openInNewTab(
      'https://www.gotolstoy.com/?utm_source=watermark&utm_medium=player&utm_campaign=logo'
    );
  };

  if (isFeedModeActive || hidePoweredBy) {
    return null;
  }

  return (
    <WatermarkContainer
      ref={setNextRef}
      onClick={handleTolstoyClick}
      isPanelExpanded={isPanelExpanded}
      aria-label="Go to Tolstoy website"
      onKeyDown={onKeyDown}
      disableGradient={disableGradient}
    >
      <PoweredByImg src={semiPoweredBy ? tolstoyIcon : tolstoyIconName} alt="Powered by Tolstoy" />
    </WatermarkContainer>
  );
};

const WatermarkContainer = styled.button`
  border: none;
  z-index: 40;
  display: flex;
  gap: 8px;
  position: ${({ isPanelExpanded }) => (isPanelExpanded ? 'relative' : 'absolute')};
  width: ${({ isPanelExpanded }) => (isPanelExpanded ? '100%' : '')};
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  height: 32px;
  background: ${({ theme, disableGradient }) =>
    disableGradient
      ? theme.getHexOpacity(theme.colors.black1, 0)
      : `linear-gradient(180deg, ${theme.getHexOpacity(theme.colors.black1, 0)} 0%, ${
          theme.colors.black1
        } 100%)`};
  cursor: pointer;
`;

const PoweredByImg = styled.img`
  height: 14px;
  opacity: 0.5;
`;

export default WaterMark;
