import React from 'react';
import { track } from 'app/src/helpers/Tracker';
import ResetToDefaultInputWrapper from 'app/src/complex_components/ResetToDefaultInputWrapper';
import EditorSubheader from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorSubheader';
import EditorTextInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorTextInputItem';
import EditorColorInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorColorInputItem';

type Props = {
  settings: {
    ctaText: string;
    color: string;
  };
  defaultSettings?: {
    ctaText: string;
    color: string;
  };
  disabled?: boolean;
  onChange?: any;
};

const ContactFormEditor = ({
  settings,
  defaultSettings,
  disabled = false,
  onChange = () => {},
}: Props) => {
  const { color, ctaText = '' } = settings;

  const updateSettings = update => onChange({ ...settings, ...update });
  const onCtaTextUpdate = ctaText => updateSettings({ ctaText });
  const onColorUpdate = color => updateSettings({ color });

  const resetCtaText = () => {
    track('Branding Reset To Default', { property: 'ctaText' });
    updateSettings(defaultSettings.ctaText);
  };
  const resetColorText = () => {
    track('Branding Reset To Default', { property: 'backgrounColor' });
    updateSettings(defaultSettings.ctaText);
  };

  return (
    <>
      <EditorSubheader title="Contact form" />

      <ResetToDefaultInputWrapper onClick={resetCtaText}>
        <EditorTextInputItem
          text="CTA text"
          placeholder="CTA text"
          disabled={disabled}
          value={ctaText}
          onChange={onCtaTextUpdate}
          maxLength={200}
          maxWidth="303px"
        />
      </ResetToDefaultInputWrapper>
      <ResetToDefaultInputWrapper onClick={resetColorText}>
        <EditorColorInputItem text="Color" color={color} onChange={onColorUpdate} height="50px" />
      </ResetToDefaultInputWrapper>
    </>
  );
};

export default ContactFormEditor;
