import WebpageGlobeIcon from 'src/images/audience/journey/WebpageGlobeIcon';
import { JourneyDrawerSummaryStepKey } from '../../journey-drawer-content/journey-drawer-step/types/JourneyDrawerStep.types';
import OutlinedPlayIcon from 'src/images/audience/journey/OutlinedPlayIcon';
import OutlinedDollarSignIcon from 'src/images/audience/journey/OutlinedDollarSignIcon';
import OutlinedAccountIcon from 'src/images/audience/journey/OutlinedAccountIcon';

export const JOURNEY_DRAWER_SUMMARY_STEP_SETTINGS = {
  [JourneyDrawerSummaryStepKey.isReturningCustomer]: {
    label: 'Returning customer',
    Icon: OutlinedAccountIcon,
  },
  [JourneyDrawerSummaryStepKey.watchedVideos]: {
    label: 'Video',
    Icon: OutlinedPlayIcon,
  },
  [JourneyDrawerSummaryStepKey.purchases]: {
    label: 'Purchase',
    Icon: OutlinedDollarSignIcon,
  },
};
