export const REPLY_TEXT_DATA_TEST_ID = 'reply-text';
export const REPLY_MACRO_DATA_TEST_ID = 'reply-macro';
export const SMALL_PRODUCT_VIEW_DATA_TEST_ID = 'small-product-view';
export const QUICK_ADD_BUTTON_DATA_TEST_ID = 'quick-add-button';
export const BUY_NOW_DATA_TEST_ID = 'buy-now-button';
export const BASIC_BUTTON_DATA_TEST_ID = 'basic-button';

// look and feel
export const SUCCESS_SNACK_BAR_TEST_ID = 'success-snack-bar';
export const SNACK_BAR_TEST_ID = 'snack-bar';
export const RECTANGLE_TEST_ID = 'rectangle-shape-widget';
export const CIRCLE_TEST_ID = 'circle-shape-widget';
export const LARGE_SIZE_DATA_TEST_ID = 'large-size-widget';
export const MEDIUM_SIZE_DATA_TEST_ID = 'medium-size-widget';
export const SMALL_SIZE_DATA_TEST_ID = 'small-size-widget';
export const BORDER_OFF_DATA_TEST_ID = 'border-off';
export const BORDER_ON_DATA_TEST_ID = 'border-on';

// General
export const BASIC_SELECT_FIRST_OPTION_DATA_TEST_ID = 'basic-select-first-option';

// Feed
export const FEED_DESCRIPTION_TEXT_TEST_ID = 'feedDescriptionText';
export const FEED_SECONDARY_BUTTON_TEST_ID = 'feedSecondaryButton';
export const FEED_SIDE_PANEL_PRODUCT_HEADER_TEST_ID = 'feedSidePanelDesriptionHeader';
export const FEED_SIDE_PANEL_CONTAINER_TEST_ID = 'feedSidePanelContainer';
export const FEED_NAVIGATION_ARROW_TEST_ID = 'feedNavigationArrow';
export const FEED_MUTE_BUTTON_TEST_ID = 'feedMuteButton';
