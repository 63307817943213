import React, { useState } from 'react';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import styled from 'styled-components';
import { TextBodyLeading, TextSmall } from 'shared/react/components/complex/Text';
import useIntegrationsVideos from 'app/src/hooks/useIntegrationsVideos';
import TiktokIcon from 'app/src/images/dashboard_v2/tiktok.png';
import InstagramIcon from 'app/src/images/dashboard_v2/instagram.png';
import SynthesiaIcon from 'app/src/images/integrations/synthesia.png';
import GoogleDriveIcon from 'app/src/images/integrations/googleDrive.png';
import YoutubeIcon from 'app/src/images/integrations/youtubeIcon.png';
import { IMPORT_FROM_INTEGRATION_LOCATIONS } from 'app/src/constants/trackingLocations.constants';
import {
  DROPBOX,
  GOOGLE_DRIVE,
  INSTAGRAM_GRAPH,
  SYNTHESIA,
  TIKTOK,
  YOUTUBE,
} from 'app/src/constants/intergrations.constants';
import {
  PrimaryButton,
  WhiteButton,
  DangerButton,
} from 'shared/react/components/basic/button-v2/BasicButton';
import VideosEmptySpaceBox from 'app/src/pages/dashboard/pages/videos/components/empty-states/VideosEmptySpaceBox';
import { RECORDINGS, SCREEN_RECORDING, UPLOAD } from 'app/src/constants/recordingTypes.constants';
import UploadIcon from 'app/src/images/dashboard_v2/UploadIcon';
import FilledVideoIcon from 'app/src/images/creation_method_icons/FilledVideoIcon';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import {
  FolderEmptyState,
  SearchEmptyState,
  SourceEmptyState,
} from 'app/src/pages/dashboard/components/empty-states/EmptyState';
import NewVideoButton from 'app/src/pages/dashboard/pages/videos/components/new_video/NewVideoButton';
import { vodAsset } from 'app/src/types/entities';
import { pick } from 'lodash';
import filterEntities, { WhereCondition } from 'app/src/pages/dashboard/utils/filterEntities';
import { CREATION_FLOW_MODAL_KEY } from 'app/src/constants/modals.constants';
import { IMPORT_FROM_TIKTOK, UPLOAD_FILE } from 'app/src/constants/creation_method.constants';
import { useModalActions } from 'app/src/context/ui_store/ModalStore';
import { DesignSizes } from 'app/src/types/design-system';
import MenuWithItems from 'app/src/complex_components/menu_items/MenuWithItems';
import useGetCreateVideoMenuItems from 'app/src/hooks/useGetCreateVideoMenuItems';
import { useApps } from 'app/src/context/AppsStore';
import SynthesiaEmptyStateButton from 'app/src/pages/dashboard/pages/videos/components/empty-states/components/synthesia-empty-state-button/SynthesiaEmptyStateButton';
import {
  FEATURE_GOOGLE_DRIVE_INTEGRATION,
  FEATURE_YOUTUBE_INTEGRATION,
} from 'shared/react/constants/features.constants';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import dropboxIcon from 'app/src/images/integrations/dropboxIcon.svg';
import DropboxEmptyStateButton from 'app/src/pages/dashboard/pages/videos/components/empty-states/components/dropbox-button/DropboxButton';
import { useYoutube } from 'app/src/context/YoutubeStore';
import { hasAppliedFilters } from 'app/src/pages/dashboard/components/filters/filtersConfig';

type Props = {
  videosFilters: WhereCondition;
  onSelectedVideosAction: (videos: vodAsset[]) => void;
  isModal?: boolean;
};

const VideosEmptyState = ({ onSelectedVideosAction, videosFilters, isModal = false }: Props) => {
  const { setCurrentModal, setModalProps } = useModalActions();
  const [{ synthesia }] = useApps();
  const [{ error: youtubeError }] = useYoutube();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const { getFeatureEnabled } = useFeatureActions();
  const {
    handleTiktokConnect,
    handleInstagramConnect,
    handleGoogleDriveConnect,
    handleYoutubeConnect,
    tiktok,
    instagram,
    youtube,
    googleDrive,
  } = useIntegrationsVideos(IMPORT_FROM_INTEGRATION_LOCATIONS.videoInbox);
  const source = videosFilters.uploadType?.in?.[0];

  const shouldShowAll = !source;

  const toggleNewVideoMenu = event => {
    if (!event || !event.currentTarget) {
      setMenuAnchor(null);
      return;
    }

    setMenuAnchor(menuAnchor ? null : event.currentTarget);
  };

  const onMenuItemClick = defaultSelectedMethod => () => {
    setModalProps({
      isLeftPanelVisible: false,
      defaultSelectedMethod,
      shouldCreateProject: false,
      videoFolder: videosFilters?.folder?.eq,
    });

    setCurrentModal(CREATION_FLOW_MODAL_KEY);
    toggleNewVideoMenu(null);
  };

  const items = [
    {
      imageSrc: TiktokIcon,
      hidden: (!shouldShowAll && source !== TIKTOK) || tiktok,
      app: 'tiktok',
      button: <PrimaryButton onClick={handleTiktokConnect}>Connect</PrimaryButton>,
    },
    {
      imageSrc: InstagramIcon,
      hidden: (!shouldShowAll && source !== INSTAGRAM_GRAPH) || instagram,
      app: 'instagram',
      button: <PrimaryButton onClick={handleInstagramConnect}>Connect</PrimaryButton>,
    },
    {
      imageSrc: GoogleDriveIcon,
      hidden:
        (!shouldShowAll && source !== GOOGLE_DRIVE) ||
        googleDrive ||
        !getFeatureEnabled(FEATURE_GOOGLE_DRIVE_INTEGRATION),
      app: 'googleDrive',
      button: <PrimaryButton onClick={handleGoogleDriveConnect}>Connect</PrimaryButton>,
    },
    {
      imageSrc: YoutubeIcon,
      hidden:
        (!shouldShowAll && source !== YOUTUBE) ||
        youtube ||
        !getFeatureEnabled(FEATURE_YOUTUBE_INTEGRATION),
      app: 'youtube',
      button: <PrimaryButton onClick={handleYoutubeConnect}>Connect</PrimaryButton>,
    },
    {
      imageSrc: SynthesiaIcon,
      hidden: shouldShowAll || source !== SYNTHESIA || synthesia,
      app: SYNTHESIA,
      button: <SynthesiaEmptyStateButton />,
    },
    {
      text: 'Select files to upload',
      app: 'upload',
      hidden: !shouldShowAll && source !== UPLOAD,
      button: (
        <StyledGhostButton onClick={onMenuItemClick(UPLOAD_FILE)}>
          <UploadIcon />
          Upload
        </StyledGhostButton>
      ),
    },
    {
      text: 'Record video',
      app: 'record',
      hidden: !shouldShowAll && ![RECORDINGS, SCREEN_RECORDING].includes(source as string),
      button: (
        <StyledGhostButton onClick={toggleNewVideoMenu}>
          <FilledVideoIcon /> Record
        </StyledGhostButton>
      ),
    },
  ];

  const isTiktok = source === TIKTOK;
  const isInstagram = source === INSTAGRAM_GRAPH;
  const isSynthesia = source === SYNTHESIA;
  const isGoogleDrive = source === GOOGLE_DRIVE;
  const isDropbox = source === DROPBOX;
  const isYoutube = source === YOUTUBE;
  const { recordingsItems } = useGetCreateVideoMenuItems({ toggleMenu: toggleNewVideoMenu });

  const isEmptySearch = hasAppliedFilters(videosFilters);
  if (isEmptySearch) {
    return <SearchEmptyState />;
  }

  if (videosFilters.folder?.eq) {
    return <FolderEmptyState createButton={<NewVideoButton videosFilters={videosFilters} />} />;
  }

  if (youtubeError && isYoutube) {
    return (
      <ErrorContainer isModal={isModal}>
        <Header>Issue with YouTube integration</Header>
        <TextSmall>{youtubeError.split(',')[0]},</TextSmall>
        <TextSmall>{youtubeError.split(',')[1]}</TextSmall>
      </ErrorContainer>
    );
  }

  if (tiktok && isTiktok) {
    return (
      <SourceEmptyState
        title="No items in TikTok"
        createButton={
          <DangerButton onClick={onMenuItemClick(IMPORT_FROM_TIKTOK)}>Import from URL</DangerButton>
        }
      />
    );
  }

  if (isDropbox) {
    return (
      <LayoutRoot>
        <Header>Effortlessly Share Your Videos: Uploading from Dropbox Made Easy</Header>
        <Integrations>
          <DropboxEmptyStateButton onSelectedVideosAction={onSelectedVideosAction} />
        </Integrations>
      </LayoutRoot>
    );
  }

  if (instagram && isInstagram) {
    return <SourceEmptyState title="No items in Instagram" />;
  }

  if (synthesia && isSynthesia) {
    return <SourceEmptyState title="No items in Synthesia" />;
  }

  if (googleDrive && isGoogleDrive) {
    return <SourceEmptyState title="No items in Google Drive" />;
  }
  const filteredItems = items.filter(item => !item.hidden);

  return (
    <LayoutRoot isModal={isModal}>
      <Header>Connect sources to start creating Tolstoys</Header>
      <Integrations>
        {filteredItems.map(integration => {
          return <VideosEmptySpaceBox key={integration.app} {...integration} />;
        })}
      </Integrations>
      <StyledMenuWithItems
        anchorElement={menuAnchor}
        menuItems={recordingsItems}
        onClose={() => setMenuAnchor(null)}
        size={DesignSizes.NORMAL}
        direction={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)<{ isModal: boolean }>`
  padding-top: ${({ isModal }) => (isModal ? '40px' : '140px')};
  align-items: center;
  gap: 8px;
`;

const ErrorContainer = styled(LayoutRoot)`
  margin-left: 16px;
`;

const Header = styled(TextBodyLeading)`
  font-weight: 500;
`;

const Integrations = styled(Gap16VerticalFlex)`
  margin: 8px 0 40px;
`;

const StyledGhostButton = styled(WhiteButton)`
  gap: 6px;

  svg {
    width: 14px;
    height: 14px;
  }

  path {
    fill: ${props => props.theme.colors.black};
  }
`;

const StyledMenuWithItems = styled(MenuWithItems)`
  border-radius: 8px;
  padding: 8px;
  width: 236px;
`;

export default VideosEmptyState;
