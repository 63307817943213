import { useEffect } from 'react';
import { useTopBarContext } from 'app/src/context/TopBarStore';
import { DirtyForm } from 'app/src/pages/dashboard/components/top-bar/types/TopBar.types';

const useUpdateDirtyForm = (isDirty: boolean, dirtyFormToUpdate: DirtyForm) => {
  const { dirtyForm: activeDirtyForm, setDirtyForm } = useTopBarContext();

  useEffect(() => {
    if (dirtyFormToUpdate.reply) {
      return setDirtyForm(dirtyFormToUpdate);
    }

    setDirtyForm(isDirty ? dirtyFormToUpdate : null);
  }, [
    isDirty,
    dirtyFormToUpdate.onSaveClick,
    dirtyFormToUpdate?.isLoading,
    dirtyFormToUpdate?.isCancelling,
    activeDirtyForm?.reply,
  ]);

  useEffect(() => {
    return () => {
      setDirtyForm(null);
    };
  }, []);
};

export default useUpdateDirtyForm;
