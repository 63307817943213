import React from 'react';
import styled from 'styled-components';
import FeedDurationBar from 'shared/react/components/complex/feed/feed_controls/FeedDurationBar';
import FeedMuteButton from './FeedMuteButton';
import Gap8VerticalFlex from '../../flex_layouts/Gap8VerticalFlex';
import FeedCartButton from 'shared/react/components/complex/feed/feed_controls/FeedCartButton';
import FeedShareButton from 'shared/react/components/complex/feed/feed_share/FeedShareButton';
import FeedMenuButton from './FeedMenuButton';
import FeedHandle from 'shared/react/components/complex/feed/feed_overlay/FeedHandle';
import FeedCarouselNavigation from '../feed_overlay/FeedCarouselNavigation';

const FeedControls = ({ videoRef, step, scrollLeft, scrollRight }) => {
  return (
    <LayoutRoot>
      <ActionButtonsContainer>
        <FeedMenuButton currentStepKey={step.key} videoRef={videoRef} videoId={step.videoId} />
        <FeedCartButton currentStepKey={step.key} />
        <FeedShareButton currentStep={step.key} />
        <FeedMuteButton videoRef={videoRef} step={step} />
        <FeedHandle step={step} />
        <FeedCarouselNavigation
          scrollLeft={scrollLeft}
          scrollRight={scrollRight}
          step={step}
          isFullWidth={true}
        />
      </ActionButtonsContainer>
      <StyledFeedDurationBar videoRef={videoRef} isShowDuration />
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  width: 100%;
  svg {
    width: 18px;
    height: 18px;
  }
`;

const ActionButtonsContainer = styled(Gap8VerticalFlex)`
  position: relative;
  width: 100%;
  align-items: flex-end;
  padding: 8px;
  box-sizing: border-box;
`;

const StyledFeedDurationBar = styled(FeedDurationBar)`
  width: 100%;
  box-sizing: border-box;
`;

export default FeedControls;
