import React from 'react';
import EmailBadgeIcon from 'app/src/images/EmailBadgeIcon';
import JourneyDrawerStep from './JourneyDrawerStep';
import LineClampWrapper from 'shared/react/components/basic/LineClampWrapper';
import styled from 'styled-components';
import { JourneyDrawerStepType } from './types/JourneyDrawerStep.types';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import { useModalActions } from 'src/context/ui_store/ModalStore';
import { useVideoActions } from 'src/context/VideosStore';
import { VIDEO_PREVIEW_MODAL_KEY } from 'src/constants/modals.constants';

type Props = {
  data: JourneyDrawerStepType;
};

const JourneyEmailClickStep = ({ data }: Props) => {
  const { setCurrentId, setCurrentModal } = useModalActions();
  const { getVideoById } = useVideoActions();
  const { videoId, createdAt } = data;

  const getVodAssetName = (vodAssetId: string) => {
    const video = getVideoById(vodAssetId);

    return video?.name || vodAssetId;
  };

  const onClick = () => {
    setCurrentId(videoId);
    setCurrentModal(VIDEO_PREVIEW_MODAL_KEY);
  };

  const videoName = getVodAssetName(videoId);
  return (
    <JourneyDrawerStep header="Clicked GIF" createdAt={createdAt} Icon={EmailBadgeIcon}>
      <LineClampWrapper>
        <StyledTextTiny onClick={onClick}>{videoName}</StyledTextTiny>
      </LineClampWrapper>
    </JourneyDrawerStep>
  );
};

const StyledTextTiny = styled(TextTiny)`
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  color: ${({ theme }) => theme.colors.neutralDark};

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export default JourneyEmailClickStep;
