import React from 'react';
import Input from './Input';
import { Autocomplete } from '@material-ui/lab';

export { createFilterOptions } from '@material-ui/lab/Autocomplete';

const AutoComplete = props => {
  return (
    <Autocomplete {...props} renderInput={params => <Input {...params} {...props.inputprops} />} />
  );
};

export default AutoComplete;
