export const NODE_ENV = import.meta.env.MODE;
export const IS_DEV = import.meta.env.DEV;
export const IS_PROD = import.meta.env.PROD;

export const API_URL = import.meta.env.VITE_API_URL;
export const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;
export const BASE_URL = import.meta.env.VITE_BASE_URL;

export const SHOPIFY_APP_BLOCK_EXTENSION_UUID = import.meta.env
  .VITE_SHOPIFY_APP_BLOCK_EXTENSION_UUID;
export const SENTRY_RELEASE = import.meta.env.VITE_SENTRY_RELEASE;

export const TOLSTOY_PRICING_ENTERPRISE_ID = import.meta.env.VITE_TOLSTOY_PRICING_ENTERPRISE_ID;
export const TOLSTOY_PRICING_ONBOARDING_ID = import.meta.env.VITE_TOLSTOY_PRICING_ONBOARDING_ID;
export const TOLSTOY_SHOPIFY_REVIEW_ID = import.meta.env.VITE_TOLSTOY_SHOPIFY_REVIEW_ID;

export const CUBE_KEY = import.meta.env.VITE_CUBE_KEY;
export const CUBE_URL = import.meta.env.VITE_CUBE_URL;

export const GOOGLE_FONTS_API_KEY = import.meta.env.VITE_GOOGLE_FONTS_API_KEY;
export const PEXELS_API_KEY = import.meta.env.VITE_PEXELS_API_KEY;

export const OPEN_EMAIL_IMAGE = import.meta.env.VITE_OPEN_EMAIL_IMAGE;

export const PUBLISH_URL = import.meta.env.VITE_PUBLISH_URL;
export const HERO_PUBLISH_URL = import.meta.env.VITE_HERO_PUBLISH_URL;
export const PUBLISH_HUBSPOT_URL = import.meta.env.VITE_PUBLISH_HUBSPOT_URL;
export const INSTALL_INTERCOM_URL = import.meta.env.VITE_INSTALL_INTERCOM_URL;
export const INSTALL_WIX_URL = import.meta.env.VITE_INSTALL_WIX_URL;
export const INSTALL_OUTREACH_URL = import.meta.env.VITE_INSTALL_OUTREACH_URL;
export const INSTALL_SALESLOFT_URL = import.meta.env.VITE_INSTALL_SALESLOFT_URL;
export const INSTALL_TIKTOK_URL = import.meta.env.VITE_INSTALL_TIKTOK_URL;
export const INSTALL_YOTPO_URL = import.meta.env.VITE_INSTALL_YOTPO_URL;
export const WIX_BASE_URL = import.meta.env.VITE_WIX_BASE_URL;
export const PUBLISH_WIX_URL = import.meta.env.VITE_PUBLISH_WIX_URL;
export const SHOPIFY_URL = import.meta.env.VITE_SHOPIFY_URL;
export const PUBLISH_BIG_COMMERCE_URL = import.meta.env.VITE_PUBLISH_BIG_COMMERCE_URL;
export const INSTALL_SHOPIFY_URL = import.meta.env.VITE_INSTALL_SHOPIFY_URL;
export const INSTALL_GOOGLE_DRIVE_URL = import.meta.env.VITE_INSTALL_GOOGLE_DRIVE_URL;
export const INSTALL_YOUTUBE_URL = import.meta.env.VITE_INSTALL_YOUTUBE_URL;
export const INSTALL_INSTAGRAM_GRAPH_URL = import.meta.env.VITE_INSTALL_INSTAGRAM_GRAPH_URL;
export const MONDAY_CREATE_BOARD_URL = import.meta.env.VITE_MONDAY_CREATE_BOARD_URL;

export const VIDEO_INPUT = import.meta.env.VITE_VIDEO_INPUT;
export const VIDEO_OUTPUT = import.meta.env.VITE_VIDEO_OUTPUT;
export const ASSETS_OUTPUT = import.meta.env.VITE_ASSETS_OUTPUT;
export const IMAGE_OUTPUT = import.meta.env.VITE_IMAGE_OUTPUT;

export const STUDIO_DOMAIN = import.meta.env.VITE_STUDIO_DOMAIN;

export const APP_TYPE = 'app';

export default {
  NODE_ENV,
  IS_DEV,
  IS_PROD,

  API_URL,
  API_BASE_URL,
  BASE_URL,

  SHOPIFY_APP_BLOCK_EXTENSION_UUID,
  SENTRY_RELEASE,

  TOLSTOY_PRICING_ENTERPRISE_ID,
  TOLSTOY_PRICING_ONBOARDING_ID,
  TOLSTOY_SHOPIFY_REVIEW_ID,

  CUBE_KEY,
  CUBE_URL,

  GOOGLE_FONTS_API_KEY,
  PEXELS_API_KEY,

  OPEN_EMAIL_IMAGE,

  PUBLISH_URL,
  PUBLISH_HUBSPOT_URL,
  INSTALL_INTERCOM_URL,
  INSTALL_WIX_URL,
  INSTALL_OUTREACH_URL,
  INSTALL_SALESLOFT_URL,
  INSTALL_TIKTOK_URL,
  INSTALL_YOTPO_URL,
  WIX_BASE_URL,
  PUBLISH_WIX_URL,
  SHOPIFY_URL,
  PUBLISH_BIG_COMMERCE_URL,
  INSTALL_SHOPIFY_URL,
  INSTALL_GOOGLE_DRIVE_URL,
  INSTALL_YOUTUBE_URL,
  INSTALL_INSTAGRAM_GRAPH_URL,
  MONDAY_CREATE_BOARD_URL,

  VIDEO_INPUT,
  VIDEO_OUTPUT,
  ASSETS_OUTPUT,
  IMAGE_OUTPUT,

  APP_TYPE,
  STUDIO_DOMAIN,
};
