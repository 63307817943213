import { App } from 'src/types/app';
import { DEFAULT_MESSAGE } from 'src/constants/ugc';

export const getDefaultInstagramApprovalHashtag = userName => `#yes${userName}`;

export const getUgcMessage = instagramApp => {
  const { message } = instagramApp?.data.ugcSettings || {};

  return message || DEFAULT_MESSAGE;
};

export const makeHashtag = (hashtag: string) => {
  if (!hashtag) {
    return '';
  }

  return hashtag.startsWith('#') ? hashtag : `#${hashtag}`;
};

export const makeUsername = (username: string) => {
  if (!username) {
    return '';
  }

  return username.startsWith('@') ? username : `@${username}`;
};

export const getSocialUsername = (app: App) => {
  return makeUsername(app?.data?.userName);
};
