import { getRequest } from '../helpers/API';
import { createContainer, createHook, createStore } from 'react-sweet-state';

const initialState = {
  readyMade: [],
  selectedFilter: 'ecommerce',
  loading: false,
  error: null,
};

const actions = {
  fetchReadyMade:
    () =>
    async ({ getState, setState }) => {
      if (!getState().readyMade.length) {
        const response = await getRequest('project-actions', '/actions/ready-made/info');
        setState({ readyMade: response });
      }
    },
  updateSelectedFilter:
    selectedFilter =>
    ({ setState }) => {
      setState({ selectedFilter });
    },
};

const ReadyMadeStore = createStore({ initialState, actions, name: 'ReadyMade' });

export const useReadyMade = createHook(ReadyMadeStore);

export const ReadyMadeContainer = createContainer(ReadyMadeStore);
