import React, { useState } from 'react';
import styled from 'styled-components';
import { CARD_LAYOUT_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import VerticalFlex from 'player/src/components/common/VerticalFlex';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextSmall } from 'shared/react/components/basic/text/TextV2';
import CrossedEyeIcon from 'src/images/CrossedEyeIcon';
import useLocalStorage from 'src/hooks/useLocalStorage';
import SelectCaseModal from 'app/src/pages/dashboard/components/cases/SelectCaseModal';
import { CaseType } from 'app/src/pages/dashboard/components/cases/cases.constants';

const SKIP_SHOP_ONBOARDING_MODAL = 'skip-shop-onboarding-modal';

const ShopAppCard = ({ item }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [skipOnboardingModal, setSkipOnboardingModal] = useLocalStorage(
    SKIP_SHOP_ONBOARDING_MODAL,
    '0'
  );

  const getDraftBadge = () => {
    if (item.isLive !== false) {
      return null;
    }

    return <CrossedEyeIconStyled />;
  };

  const onCardClick = () => {
    if (!parseInt(skipOnboardingModal) || item.key === 'shop-home') {
      setIsModalOpen(true);
      return;
    }

    item.onClick();
  };

  const handleModalButtonClick = () => {
    setSkipOnboardingModal('1');
    setIsModalOpen(false);
    item.onClick();
  };

  return (
    <CardContainer data-test-id={CARD_LAYOUT_TEST_ID} onClick={onCardClick}>
      <CardHeader>
        {item.icon}
        <TextSmall>{item.title}</TextSmall>
        {getDraftBadge()}
      </CardHeader>
      <CardImageWrapper>
        <CardImage src={item.imageSrc} />
      </CardImageWrapper>
      <SelectCaseModal
        open={isModalOpen}
        onClick={handleModalButtonClick}
        onClose={() => setIsModalOpen(false)}
        type={CaseType.SHOP_VIDEOS}
      />
    </CardContainer>
  );
};

const CardContainer = styled(VerticalFlex)`
  height: 302px;
  width: 386px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutralLight};
  flex-wrap: nowrap;
  overflow: hidden;
  cursor: pointer;
`;

const CardHeader = styled(HorizontalFlex)`
  padding: 16px 12px;
  gap: 4px;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
`;

const CardImageWrapper = styled(HorizontalFlex)`
  padding-top: 16px;
  background-color: ${({ theme }) => theme.colors.grapePurpleDark};
`;

const CardImage = styled.img`
  width: 251px;
  margin: 0 auto;
`;

const CrossedEyeIconStyled = styled(CrossedEyeIcon)`
  width: 12px;
  height: 12px;
  margin-left: auto;
`;

export default ShopAppCard;
