import React from 'react';
import styled from 'styled-components';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import EditorToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorToggleItem';
import EditorSubheader from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorSubheader';
import { useBrandingContext } from '../../../BrandingContext';

type Props = {
  settings?: any;
  features?: any;
  onChange?: any;
};

const MuteEditor = ({ settings = {}, features = {}, onChange = () => {} }: Props) => {
  const { loading } = useBrandingContext();
  const { isInitialMuted, isFeedMuted } = settings;
  const { isBehaviourCustomizationEnabled } = features;
  const isDisabled = !isBehaviourCustomizationEnabled || loading;
  const handleIsInitialMutedChange = (value: boolean) => onChange({ isInitialMuted: value });
  const handleIsFeedMutedChange = (value: boolean) => onChange({ isFeedMuted: value });

  return (
    <LayoutRoot>
      <EditorSubheader title="Mute" />
      <EditorBody>
        <EditorToggleItem
          text="Sound removed from videos by default"
          checked={isFeedMuted}
          onChange={handleIsFeedMutedChange}
        />
        <EditorToggleItem
          disabled={isDisabled}
          text="Always start on mute"
          checked={isInitialMuted}
          onChange={handleIsInitialMutedChange}
          tooltipText="After viewer clicks the Tolstoy, play the videos on mute first, on all devices."
        />
      </EditorBody>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)``;

const EditorBody = styled(VerticalFlex)``;

export default MuteEditor;
