import React from 'react';
import { useVideoCardProducts } from './useVideoCardProducts';
import { useVideoCardSuggestedProducts } from 'app/src/pages/dashboard/pages/videos/components/video_card/components/video-card-details/components/interactive-video-card-products/useVideoCardSuggestedProducts';
import { VideoCardSuggestedLabel } from 'app/src/pages/dashboard/pages/videos/components/video_card/components/video-card-details/components/interactive-video-card-products/components/VideoCardSuggestedLabel';
import { VideoCardProductLabel } from 'app/src/pages/dashboard/pages/videos/components/video_card/components/video-card-details/components/interactive-video-card-products/components/VideoCardProductLabel';
import TagIcon from 'app/src/images/sidebar-v2/onsite/TagIcon';
import styled from 'styled-components';

const InteractiveVideoCardProducts = ({ isProductsTaggingEnabled, onVideoChoose, video }) => {
  /**
   * This is not optimal way to get products.
   * We should get products outside the card.
   * Ideally card can't use any hooks cause of the virtual scroll re-renders
   */
  const { loading: productsLoading, product, extraProductsCount } = useVideoCardProducts(video);
  const {
    handleSuggestionRejected,
    handleSuggestionAccepted,
    loading: suggestionsLoading,
    suggestedProduct,
    suggestedConnection,
    hasSuggestion,
  } = useVideoCardSuggestedProducts(video);

  const isLoading = suggestionsLoading || productsLoading;
  const hasNoProducts = !product && !hasSuggestion;

  if (!isProductsTaggingEnabled || onVideoChoose) {
    return null;
  }

  if (isLoading || hasNoProducts) {
    return (
      <div className="align flex items-center justify-center gap-1 rounded border p-1.5">
        <StyledTagIcon />
        <div className="font-medium">{isLoading ? 'Products' : 'Tag products'}</div>
      </div>
    );
  }

  if (product) {
    return <VideoCardProductLabel product={product} extraProductsCount={extraProductsCount} />;
  }

  return (
    <VideoCardSuggestedLabel
      suggestedProduct={suggestedProduct}
      suggestedConnection={suggestedConnection}
      onSuggestionAccepted={handleSuggestionAccepted}
      onSuggestionRejected={handleSuggestionRejected}
    />
  );
};

const StyledTagIcon = styled(TagIcon)`
  width: 12px;
  height: 12px;
`;

export default InteractiveVideoCardProducts;
