import React from 'react';
import styled from 'styled-components';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { RedirectButton } from 'app/src/pages/sidebar/redirect-buttons/RedirectButton';

const RedirectButtons = ({ buttons, showTextOnMobile }) => {
  return (
    <LayoutRoot>
      {buttons.map(button => (
        <RedirectButton key={button.text} showTextOnMobile={showTextOnMobile} {...button}>
          {button.text}
        </RedirectButton>
      ))}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)`
  width: 100%;
`;

export default RedirectButtons;
