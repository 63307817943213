import React, { useState } from 'react';
import Utils from '../utils';
import SnackBar from 'app/src/basic_components/SnackBar';
import styled from 'styled-components';
import BasicInput from 'app/src/basic_components/BasicInput';
import Copy from 'app/src/images/copy.svg';
import IconButton from './IconButton';

export default function CopyInput({ label, value, onClick, isSecret, ...props }) {
  const [successBar, setSuccessBar] = useState(false);

  function copy() {
    Utils.copyToClipboard(value);
    setSuccessBar(true);
    if (onClick) {
      onClick();
    }
  }

  return (
    <LayoutRoot>
      <MainContainer {...props}>
        <StyledBasicInput
          onChange={() => {}}
          label={label}
          type={isSecret ? 'password' : 'text'}
          value={value}
          disabled
        />
        <EndAdornment>
          <CopyButton src={Copy} onClick={copy} />
        </EndAdornment>
      </MainContainer>
      <SnackBar
        text="Copied to Clipboard!"
        open={successBar}
        setOpen={setSuccessBar}
        severity="success"
      />
    </LayoutRoot>
  );
}

const LayoutRoot = styled.div``;

const MainContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  border: 1px solid ${({ theme }) => theme.colors.gray3};
  border-radius: 10px;
  width: 100%;
  background: white;
`;

const StyledBasicInput = styled(BasicInput)`
  padding: 12px;
  color: ${({ theme }) => theme.colors.ghostDark};
  opacity: 1;
`;

const EndAdornment = styled.div`
  width: fit-content;
  justify-self: end;
  align-self: center;
  margin-right: 4px;
`;

const CopyButton = styled(IconButton)`
  background: transparent;
  transition: 0.3s;

  &:hover,
  &:focus,
  &:active {
    background: #e6e6e6;
  }

  padding: 14px;
`;
