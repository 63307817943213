import React from 'react';
import styled from 'styled-components';
import { IMAGE_TYPE } from '../../../constants/attachments_types.constants';
import Gap8HorizontalFlexWrap from '../flex_layouts/Gap8HorizontalFlexWrap';
import Utils from '../../../utils/utils';

const Attachments = ({ attachments }) => {
  if (!attachments?.length) {
    return null;
  }

  const getAttachmentType = ({ fileName, url, type }) => {
    const components = {
      [IMAGE_TYPE]: (
        <Image onClick={() => Utils.openInNewTab(url)} src={url} alt={fileName} key={url} />
      ),
    };

    return components[type];
  };

  return (
    <LayoutRoot>
      {attachments.map(attachment => {
        return getAttachmentType(attachment);
      })}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlexWrap)`
  overflow: auto;
  max-width: 350px;
  flex-wrap: nowrap;
`;

const Image = styled.img`
  width: 100%;
  height: 122px;
  cursor: pointer;
  border-radius: 8px;
`;

export default Attachments;
