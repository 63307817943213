import React from 'react';
import { DOWN, UP } from 'shared/react/components/complex/carousel/carousel.constants';
import ArrowIcon from 'shared/react/images/feed/ArrowIcon';
import BackdropButton from 'shared/react/components/complex/BackdropButton';
import { FEED_NAVIGATION_ARROW_TEST_ID } from 'shared/react/constants/dataTestIds.constants';

const getAriaLabel = direction => {
  if (direction === UP) {
    return 'Move to previous video';
  }

  return 'Move to next video';
};

const NavigationArrowButton = ({ onClick, direction }) => {
  const shouldFlip = direction === DOWN;
  const ariaLabel = getAriaLabel(direction);

  return (
    <BackdropButton
      data-test-id={`${FEED_NAVIGATION_ARROW_TEST_ID}_${direction}`}
      onClick={onClick} 
      ariaLabel={ariaLabel}
    >
      <ArrowIcon flip={shouldFlip} />
    </BackdropButton>
  );
};

export default NavigationArrowButton;
