import React from 'react';

const InstallationIcon = ({ fill = '#3D4FE0' }) => {
  return (
    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.047.695 8.109 3.82c.235.196.391.47.391.743v2.109l4.258 4.258a3.05 3.05 0 0 1 3.476.586l4.375 4.375a1.203 1.203 0 0 1 0 1.757l-2.5 2.5a1.205 1.205 0 0 1-1.757 0l-4.375-4.375a3.036 3.036 0 0 1-.547-3.515L7.172 8H5.023a.962.962 0 0 1-.742-.352L1.156 3.586a.957.957 0 0 1 .117-1.25L2.836.773a.946.946 0 0 1 1.21-.078ZM1.742 16.008l5.82-5.82 2.422 2.421a4.044 4.044 0 0 0 .118 2.54l-4.61 4.609c-.469.469-1.172.742-1.875.742A2.617 2.617 0 0 1 1 17.883c0-.703.273-1.406.742-1.875ZM21 6.125a5.648 5.648 0 0 1-3.242 5.117l-.625-.625a4.18 4.18 0 0 0-4.063-1.094L9.75 6.164v-.039C9.75 3.039 12.25.5 15.375.5c.39 0 .781.078 1.172.156.43.078.547.625.234.938l-2.5 2.5a.588.588 0 0 0-.156.43v2.265c0 .313.273.625.625.625h2.227a.627.627 0 0 0 .43-.195l2.5-2.5c.312-.313.859-.196.937.234.078.39.156.781.156 1.172ZM5.062 17.375a.95.95 0 0 0-.937-.938.925.925 0 0 0-.938.938c0 .547.391.938.938.938a.926.926 0 0 0 .938-.938Z"
        fill={fill}
      />
    </svg>
  );
};

export default InstallationIcon;
