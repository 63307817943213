import SearchIcon from 'app/src/images/Search';
import styled from 'styled-components';
import React from 'react';
import SearchBarXIcon from 'app/src/images/SearchBarXIcon';

const SearchBar = ({ onChange, value, placeholder, clearValue, searchInputStyle }) => {
  return (
    <LayoutRoot>
      <IconsContainer>
        <SearchIconContainer>
          <SearchIcon />
        </SearchIconContainer>
        {!!value && (
          <CloseContainer onClick={clearValue}>
            <SearchBarXIcon />
          </CloseContainer>
        )}
      </IconsContainer>
      <SearchInput
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        {...searchInputStyle}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
`;

const IconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 14px;
  grid-row: 1;
  grid-column: 1;
`;

const SearchInput = styled.input`
  width: 100%;
  background: ${({ theme, background }) => background || theme.colors.gray5};
  border: none;
  border-radius: 10px;
  padding: 8px 36px;
  grid-row: 1;
  grid-column: 1;
  font-size: 14px;

  &:focus {
    outline: none;
  }
`;

const SearchIconContainer = styled.div`
  z-index: 1;
  line-height: 0;
`;

const CloseContainer = styled.div`
  cursor: pointer;
  font-size: 12px;
  z-index: 1;
  color: ${({ theme }) => theme.colors.ghostDark};
  line-height: 0;
`;

export default SearchBar;
