import React from 'react';
import styled from 'styled-components';
import { TextBody } from 'shared/react/components/complex/Text';

const TeamMemberText = ({ text, className }) => {
  return <StyledTeamMemberText className={className}>{text}</StyledTeamMemberText>;
};

export default TeamMemberText;

const StyledTeamMemberText = styled(TextBody)``;
