import React from 'react';
import styled from 'styled-components';

import { useSelectedApp } from 'src/context/AppsStore';
import { PageContainer, PageMain } from 'src/pages/dashboard/components/page/Page';
import TopBar from '../../components/top-bar/TopBar';
import ProductsTable from './components/products_table/ProductsTable';
import { SHOPIFY } from 'src/constants/intergrations.constants';
import useProductsWithSuggestedVideos from 'src/hooks/useProductsWithSuggestedVideos';
import ComponentWithLoader from 'src/complex_components/ComponentWithLoader';
import PageTitle from './components/page_title/PageTitle';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

const AiTagging = () => {
  const [selectedApp] = useSelectedApp();
  const { products, isLoading, fetchProducts, removeProduct } =
    useProductsWithSuggestedVideos(selectedApp);

  const { appUrl, app } = selectedApp || {};
  const isShopify = app === SHOPIFY;

  if (!isShopify || !appUrl) {
    return null;
  }

  return (
    <ComponentWithLoader
      isLoading={isLoading}
      renderLoader={circularProgress => <LoadingWrapper>{circularProgress}</LoadingWrapper>}
    >
      <PageContainer>
        <PageMain>
          <TopBar leftContent={<>&nbsp;</>} />
          <Container>
            <PageTitle isEmpty={!products.length} />
            <ProductsTable
              products={products}
              fetchProducts={fetchProducts}
              removeProduct={removeProduct}
            />
          </Container>
        </PageMain>
      </PageContainer>
    </ComponentWithLoader>
  );
};

const LoadingWrapper = styled(HorizontalFlexCentered)`
  height: 100%;
`;

const Container = styled.div`
  overflow: auto;
`;

export default AiTagging;
