import { createContainer, createHook, createStore } from 'react-sweet-state';
import { getRequest, graphqlRequest } from 'app/src/helpers/API';
import { API, graphqlOperation } from 'aws-amplify';
import { integrationVodAssetByAppKey } from 'app/src/graphql/queries';
import { onCreateOrUpdateIntegrationVodAssetByAppKey } from 'app/src/graphql/subscriptions';
import Utils from 'app/src/utils';
import {
  createIntegrationVodAsset,
  deleteIntegrationVodAsset,
  updateIntegrationVodAsset,
} from 'app/src/graphql/mutations';
import { INTEGRATION_VOD_ASSET_STATUS_TYPES } from 'app/src/constants/video.constants';
import { FEATURE_SEMI_AUTO_IMPORT } from 'app/src/constants/appFeatures.constants';

const initialState = {
  assets: [],
  unseen: 0,
};

const actions = {
  fetch:
    () =>
    async ({ setState }, { appKey }) => {
      let nextToken = 1;
      let unseen = 0;
      const assets = [];
      while (nextToken) {
        try {
          const response = await graphqlRequest(
            graphqlOperation(integrationVodAssetByAppKey, {
              appKey,
              sortDirection: 'DESC',
              nextToken: nextToken === 1 ? null : nextToken,
            })
          );

          const vodAssets = response.data.integrationVodAssetByAppKey.items;
          unseen = vodAssets.reduce((acc, asset) => (acc += +!asset.seen), unseen);
          nextToken = response.data.integrationVodAssetByAppKey.nextToken;
          assets.push(...vodAssets);
        } catch (error) {
          Utils.logError('Failed to fetch integration vod assets', error);
          if (!error.data?.integrationVodAssetByAppKey) {
            nextToken = null;
            setState({ error, loading: false });
            return;
          }

          nextToken = error.data.integrationVodAssetByAppKey.nextToken || null;
          assets.push(...error.data.integrationVodAssetByAppKey.items);
        }
      }

      setState({ assets, unseen });
    },
  updateIntegrationVodAsset:
    asset =>
    async ({ setState, getState }) => {
      const newAsset = { ...asset };
      delete newAsset.updatedAt;
      delete newAsset.createdAt;
      const response = await graphqlRequest(
        graphqlOperation(updateIntegrationVodAsset, { input: newAsset })
      );

      const integrationVodAsset = response?.data?.updateIntegrationVodAsset;
      const assets = getState().assets;
      let unseen = getState().unseen;
      let newUnseen;

      const newAssets = [...assets].map(asset => {
        if (asset.id === integrationVodAsset.id) {
          if (asset.seen !== integrationVodAsset.seen) {
            newUnseen = unseen - 1;
          }

          return integrationVodAsset;
        }

        return asset;
      });

      setState({ assets: newAssets, unseen: newUnseen });
    },
  removeIntegrationVodAssets:
    assets =>
    async ({ dispatch }) => {
      const promises = assets.map(asset => {
        const newAsset = {
          ...asset,
          status: INTEGRATION_VOD_ASSET_STATUS_TYPES.Removed,
          seen: true,
        };

        return dispatch(actions.updateIntegrationVodAsset(newAsset));
      });
      await Promise.all(promises);
    },
  deleteIntegrationVodAsset: id => () => {
    return graphqlRequest(graphqlOperation(deleteIntegrationVodAsset, { input: { id } }));
  },
  deleteIntegrationVodAssetsByAppId:
    appName =>
    async ({ getState, setState, dispatch }) => {
      const { assets, unseen } = getState();
      let newAssets = [];
      let newUnseenNumber = unseen;
      const promises = assets.flatMap(asset => {
        if (asset.app === appName) {
          if (!asset.seen) {
            newUnseenNumber -= 1;
          }

          return dispatch(actions.deleteIntegrationVodAsset(asset.id));
        }

        newAssets.push(asset);
      });
      await Promise.all(promises);
      setState({ assets: newAssets, unseen: newUnseenNumber });
    },
  markVodAssetAsSeen:
    () =>
    async ({ getState, dispatch }) => {
      const ghostAccount = Utils.isGhostAccount();
      if (ghostAccount) {
        return;
      }

      const { assets } = getState();
      const promises = assets.flatMap(asset => {
        if (asset.seen) {
          return [];
        }

        const newAsset = { ...asset, seen: true };
        return dispatch(actions.updateIntegrationVodAsset(newAsset));
      });

      await Promise.all(promises);
    },
  createIntegrationVodAsset:
    ({
      appName,
      owner,
      appId,
      id: externalId,
      externalCreatedAt,
      videoUrl,
      title,
      posterUrl,
      vodId,
    }) =>
    async ({ setState, getState }, { appKey }) => {
      const params = {
        appKey: appKey,
        owner,
        app: appName,
        appId,
        externalId,
        externalCreatedAt,
        vodId,
        seen: true,
        title,
        thumbnailUrl: posterUrl,
        downloadUrl: videoUrl,
        status: INTEGRATION_VOD_ASSET_STATUS_TYPES.Imported,
      };

      const response = await graphqlRequest(
        graphqlOperation(createIntegrationVodAsset, { input: params })
      );

      const integrationVodAsset = response?.data?.createIntegrationVodAsset;
      const assets = getState().assets;
      setState({ assets: [...assets, integrationVodAsset] });
    },
  subscribeIntegrationVodAssets:
    () =>
    ({ setState, getState }, { appKey }) => {
      try {
        const subscription = API.graphql(
          graphqlOperation(onCreateOrUpdateIntegrationVodAssetByAppKey, { appKey })
        ).subscribe({
          next: data => {
            const integrationVodAsset =
              data?.value?.data?.onCreateOrUpdateIntegrationVodAssetByAppKey;
            const { assets, unseen } = getState();
            const exists = assets.find(asset => asset.id === integrationVodAsset.id);
            let newUnseen = unseen;
            let newAssets = [...assets];

            if (!exists) {
              if (!integrationVodAsset.seen) {
                newUnseen += 1;
              }

              newAssets.unshift(integrationVodAsset);
            } else {
              newAssets = assets.map(asset => {
                if (asset.id === integrationVodAsset.id) {
                  if (integrationVodAsset.seen && !asset.seen) {
                    newUnseen -= 1;
                  }

                  return integrationVodAsset;
                }

                return asset;
              });
            }

            setState({ unseen: newUnseen, assets: newAssets });
          },
          error: ({ error: { errors = [] } = {} }) => {
            Utils.logErrorMessage(
              errors[0]?.message || 'Error in onUpdateProjectByAppKey subscribe'
            );
          },
        });

        setState({ subscription });
      } catch (error) {
        Utils.logError('Cannot subscribe to integration vod assets', error);
      }
    },
  requestIntegrationAssets:
    (shouldGetNextCursor = false, appName) =>
    async (_, { appKey, features }) => {
      if (!features[FEATURE_SEMI_AUTO_IMPORT]?.enabled) {
        return;
      }

      await getRequest(
        'video-integration',
        `/integrations/get-videos/${appKey}?shouldGetNextCursor=${shouldGetNextCursor}${
          appName ? `&appName=${appName}` : ''
        }`
      );
    },
  findVideoByExternalId:
    externalId =>
    ({ getState }) => {
      const { assets } = getState();
      return assets.find(asset => asset.externalId === externalId);
    },
  unsubscribeProjects:
    () =>
    ({ getState, setState }) => {
      getState().subscription?.unsubscribe();

      setState({ subscription: null });
    },
  clearStore:
    () =>
    ({ setState }) => {
      setState({ ...initialState });
    },
};

const IntegrationVodAssetStore = createStore({
  initialState,
  actions,
  name: 'IntegrationVodAssetStore',
});

export const useIntegrationVodAsset = createHook(IntegrationVodAssetStore);

export const IntegrationVodAssetContainer = createContainer(IntegrationVodAssetStore, {
  onInit:
    () =>
    ({ dispatch }) => {
      dispatch(actions.subscribeIntegrationVodAssets());
      dispatch(actions.fetch());
    },
  onCleanup:
    () =>
    ({ dispatch }) => {
      dispatch(actions.clearStore());
      dispatch(actions.unsubscribeProjects());
    },
});
