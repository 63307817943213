import React from 'react';

const VideoPlayerIcon = () => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="44" height="44" rx="8" fill="#FFE4D5" />
    <path
      d="M29.6953 13.7891L24.7734 18.7109L24.7344 18.75H21.1016L26.1016 13.7891L26.1406 13.75H29.5C29.5781 13.75 29.6172 13.7891 29.6953 13.7891ZM27.3516 18.75L31.4531 14.6875C31.7656 15.1172 32 15.6641 32 16.25V18.75H27.3516ZM19.8516 13.75H23.4844L18.5234 18.7109L18.4844 18.75H14.8516L19.8516 13.7891L19.8906 13.75H19.8516ZM17.2344 13.75L12.2734 18.7109L12.2344 18.75H12V16.25C12 14.8828 13.0938 13.75 14.5 13.75H17.2344ZM12 20H32V28.75C32 30.1562 30.8672 31.25 29.5 31.25H14.5C13.0938 31.25 12 30.1562 12 28.75V20ZM20.4375 22.5781C20.2422 22.6953 20.125 22.9297 20.125 23.125V28.125C20.125 28.3594 20.2422 28.5938 20.4375 28.7109C20.6328 28.7891 20.9062 28.7891 21.0625 28.6719L24.8125 26.1719C25.0078 26.0547 25.125 25.8594 25.125 25.625C25.125 25.4297 25.0078 25.2344 24.8125 25.1172L21.0625 22.6172C20.9062 22.5 20.6328 22.5 20.4375 22.5781Z"
      fill="#FD7830"
    />
  </svg>
);

export default VideoPlayerIcon;
