import React from 'react';
import styled from 'styled-components';
import Radio from '@material-ui/core/Radio';

type Props = {
  disabled?: boolean;
  checked?: boolean;
  onClick?: () => void;
};

const RadioButtonV2 = ({ disabled, ...props }: Props) => {
  return (
    <LayoutRoot disabled={disabled}>
      <CustomRadio
        disableRipple
        color="default"
        checkedIcon={disabled ? '' : <CheckedSpan />}
        icon={<span />}
        disabled={disabled}
        {...props}
      />
    </LayoutRoot>
  );
};

const CheckedSpan = styled.span`
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 100%;
  transition: 0.3s;

  &:before {
    display: block;
    width: 16px;
    height: 16px;
    background-image: ${({ theme }) =>
      `radial-gradient(${theme.colors.white},${theme.colors.white} 28%,transparent 32%)`};
    content: '';
  }
`;

const LayoutRoot = styled.div<{ disabled: boolean }>`
  .MuiRadio-root {
    padding: 0 !important;
  }

  .MuiIconButton-label {
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.colors.neutralLight : theme.colors.white};
    transition: 0.3s;
    border: 1px solid
      ${({ theme, disabled }) => (disabled ? theme.colors.neutralDark : theme.colors.black)};

    input:disabled ~ & {
      boxshadow: none;
    }
  }

  .MuiIconButton-label:hover {
    background-color: ${({ theme }) => theme.colors.neutralLightest};
  }

  &:hover ${CheckedSpan} {
    background-color: ${({ theme }) => theme.colors.primaryHover};
  }
`;
const CustomRadio = styled(Radio)``;

export default RadioButtonV2;
