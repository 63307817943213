import React from 'react';
import MenuItem from 'app/src/complex_components/MenuItem';
import { useAccount } from 'app/src/context/AccountStore';
import Utils from 'app/src/utils';
import { STATUS_PENDING } from 'app/src/constants/teamMembers.constants';
import UserBox from 'app/src/basic_components/UserBox';
import AllTeammatesIcon from 'app/src/images/dashboard_v2/AllTeammatesIcon';

const TeamMembersMenuItems = ({ onClose, selectedTeamMember, onTeamMemberClicked }) => {
  const [{ teamMembers }] = useAccount();

  const getMenuItem = teamMember => {
    if (teamMember.status === STATUS_PENDING) {
      return;
    }

    const {
      user: { logoSettings: logoSettingsString, firstName, lastName, email, owner },
    } = teamMember;
    const logoSettings = JSON.parse(logoSettingsString || '{}');
    const userLogo = Utils.getLogoUrl(logoSettings.logo);
    const title = Utils.getUserName(firstName, lastName, email);

    return {
      componentIcon: (
        <UserBox
          userImageSrc={userLogo}
          name={title}
          backgroundColor={userLogo ? 'transparent' : logoSettings.color}
          scale="32px"
        />
      ),
      title,
      selected: selectedTeamMember?.title === title,
      onClick: () => {
        if (selectedTeamMember?.title === title) {
          onTeamMemberClicked({});
          return;
        }

        onTeamMemberClicked({ owner, title });
      },
    };
  };

  const items = teamMembers.flatMap(teamMember => {
    const menuItem = getMenuItem(teamMember);

    if (!menuItem) {
      return [];
    }

    return (
      <MenuItem
        key={menuItem.title}
        menuItem={menuItem}
        onClose={onClose}
        onClick={menuItem.onClick}
        selected={menuItem.selected}
      />
    );
  });

  const allMenuItem = { title: 'All', componentIcon: <AllTeammatesIcon /> };
  const allOnClick = () => onTeamMemberClicked({});

  return (
    <>
      <MenuItem
        selected={!selectedTeamMember?.title}
        menuItem={allMenuItem}
        onClick={allOnClick}
        onClose={onClose}
      />
      {items}
    </>
  );
};

export default TeamMembersMenuItems;
