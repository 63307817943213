import React from 'react';
import styled, { css } from 'styled-components';
import DashedInput from 'app/src/basic_components/DashedInput';
import DashedTextArea from 'app/src/basic_components/DashedTextArea';
import { TEXTAREA_INPUT_TYPE } from 'app/src/constants/ui.constants';

const AnswerTextField = ({
  placeholder,
  value,
  onChange,
  error,
  maxLength = 50,
  type,
  dataTestId,
  ...props
}) => {
  const getPlaceholder = () => {
    if (error) {
      return error.message;
    }

    return placeholder;
  };

  if (type === TEXTAREA_INPUT_TYPE) {
    return (
      <Textarea
        onChange={onChange}
        value={value || ''}
        maxLength={maxLength}
        placeholder={getPlaceholder()}
        error={!!error}
        data-test-id={dataTestId}
        {...props}
      />
    );
  }

  return (
    <Input
      onChange={onChange}
      value={value || ''}
      maxLength={maxLength}
      placeholder={getPlaceholder()}
      error={!!error}
      data-test-id={dataTestId}
      {...props}
    />
  );
};

const PlaceholderCss = css`
  ::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme, error }) => (error ? theme.colors.warning : theme.colors.gray4)};
  }
`;

const Input = styled(DashedInput)`
  ${PlaceholderCss};
`;

const Textarea = styled(DashedTextArea)`
  ${PlaceholderCss};
`;

export default AnswerTextField;
