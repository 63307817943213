import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { GridContainer } from 'app/src/pages/dashboard/components/page/Page';
import OffsiteCard from '../offsite_card/OffsiteCard';
import OffsiteDuplicateCard from 'app/src/pages/dashboard/pages/offsite/components/offsite_card/OffsiteDuplicateCard';
import OffsiteEmptyState from 'app/src/pages/dashboard/pages/offsite/components/offsite-empty-state/OffsiteEmptyState';
import { project } from 'app/src/types/entities';
import GridLoading from 'app/src/pages/dashboard/components/loading/GridLoading';

type Props = {
  items?: project[];
  selectedItems: string[];
  toggleSelected: (id: string) => void;
  fetchedProjects?: boolean;
};

const OffsiteGrid = forwardRef<HTMLDivElement, Props>(
  ({ items, selectedItems, toggleSelected, fetchedProjects }, ref) => {
    if (!fetchedProjects) {
      return <GridLoading />;
    }

    if (!items.length) {
      return <OffsiteEmptyState />;
    }

    return (
      <GridStyled ref={ref}>
        <OffsiteDuplicateCard />
        {!!items.length &&
          items.map(item => (
            <OffsiteCard
              key={item.id}
              project={item}
              toggleSelected={toggleSelected}
              isSelected={selectedItems.includes(item.id)}
              isSelectionMode={!!selectedItems.length}
            />
          ))}
      </GridStyled>
    );
  }
);
OffsiteGrid.displayName = 'CampaignGrid';

const GridStyled = styled(GridContainer)`
  grid-auto-rows: 234px;
`;

export default OffsiteGrid;
