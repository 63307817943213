import React from 'react';

const NotWorkingMicIcon = () => {
  return (
    <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m17.749 13.602-4.348-3.418c.41-.711.684-1.532.684-2.434V6.656c0-.355-.3-.656-.656-.656-.328 0-.656.3-.656.656V7.75a3.48 3.48 0 0 1-.383 1.586l-.711-.547c.136-.328.219-.684.219-1.039l-.028-4.375c0-1.45-1.148-2.625-2.598-2.625C7.823.75 6.62 1.926 6.62 3.375v1.504L1.562.914a.604.604 0 0 0-.903.11.604.604 0 0 0 .11.902l16.16 12.66a.532.532 0 0 0 .41.164.673.673 0 0 0 .52-.246.604.604 0 0 0-.11-.902ZM10.585 7.75c0 .082-.027.137-.027.191L7.96 5.891V3.375c0-.71.574-1.313 1.312-1.313.711 0 1.313.602 1.313 1.313V7.75Zm.656 5.688H9.93v-.903a5.401 5.401 0 0 0 1.586-.52l-1.176-.93c-.465.138-.93.22-1.422.165-1.832-.191-3.145-1.805-3.145-3.637v-.11l-1.285-1.01c-.027.054-.027.109-.027.163l-.027.903c0 2.433 1.722 4.62 4.129 4.949v.93H7.304c-.383 0-.657.3-.657.656 0 .383.274.656.657.656h3.937a.648.648 0 0 0 .657-.656.665.665 0 0 0-.657-.656Z"
        fill="#FA536E"
      />
    </svg>
  );
};

export default NotWorkingMicIcon;
