import { IMPORTANT_UGC_PERMISSION, UGC_WEBHOOK_PERMISSION } from 'src/constants/ugc';

export const checkUgcPermissionGranted = app => {
  if (!app || !app?.data.scopes) {
    return false;
  }

  return app.data.scopes.includes(IMPORTANT_UGC_PERMISSION);
};

export const checkWebhookPermissionsGranted = app => {
  if (!app || !app?.data.scopes) {
    return false;
  }

  return app.data.scopes.includes(UGC_WEBHOOK_PERMISSION);
};

export const checkUgcPermissionsGranted = app => {
  return checkUgcPermissionGranted(app) && checkWebhookPermissionsGranted(app);
};
