import React from 'react';
import ProjectCardV2 from 'app/src/pages/dashboard/pages/libraries/tolstoys_tab/project_card_v2/ProjectCardV2';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

const ProjectCardLoadingState = ({ setSelectedTolstoys, selectedTolstoys }) => {
  const project = {
    id: 'loading-state',
    createdAt: new Date(),
    name: 'Creating new Tolstoy...',
  };

  return (
    <LayoutRoot>
      <StyledProjectCardV2
        project={project}
        disabled
        setSelectedTolstoys={setSelectedTolstoys}
        selectedTolstoys={selectedTolstoys}
      />
      <StyledCircularProgress size={48} />
    </LayoutRoot>
  );
};

export default ProjectCardLoadingState;

const LayoutRoot = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
`;

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
`;

const StyledProjectCardV2 = styled(ProjectCardV2)`
  opacity: 0.5;
  pointer-events: none;
`;
