import React, { useEffect, useState, SyntheticEvent } from 'react';
import styled from 'styled-components';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import { SAVE_DATA_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import { useHistory } from 'react-router-dom';
import { useModalActions } from 'src/context/ui_store/ModalStore';
import { UNSAVED_CHANGES_MODAL_KEY } from 'src/constants/modals.constants';
import { PrimaryButton, GhostButton } from 'shared/react/components/basic/button-v2/BasicButton';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextH6 } from 'shared/react/components/basic/text/TextV2';
import { useTopBarContext } from 'app/src/context/TopBarStore';
import { TopBarButtonProps } from './types/TopBar.types';
import TopBarActionButton from './TopBarActionButton';

type Props = {
  title?: string;
  renderSaveTitle?: (title: string) => React.ReactNode;
  onSave: (e: SyntheticEvent) => void;
  isSaving: boolean;
};

const SaveTopBar = ({ title, renderSaveTitle, onSave, isSaving }: Props) => {
  const { dirtyForm } = useTopBarContext();
  const history = useHistory();
  const { setCurrentModal, setModalProps } = useModalActions();
  const [blockedLocation, setBlockedLocation] = useState(null);
  const [isReadyToNavigate, setIsReadyToNavigate] = useState(false);

  const {
    onDiscardClick,
    isLoading,
    isCancelling,
    reply,
    isSaveDisabled,
    hideCancel,
    disableHistoryBlocking,
  } = dirtyForm || {};

  const openUnsavedChangesModal = callback => {
    setModalProps({ onLeavePage: callback });
    setCurrentModal(UNSAVED_CHANGES_MODAL_KEY);
  };

  const getTitleText = () => {
    if (title) {
      return title;
    }

    if (reply) {
      return 'Send Reply';
    }

    return 'Do you want to save changes?';
  };

  const getTitle = () => {
    const title = getTitleText();

    if (renderSaveTitle) {
      return renderSaveTitle(title);
    }

    return <SaveTitle>{title}</SaveTitle>;
  };

  const buttons: TopBarButtonProps[] = [
    {
      key: 'discard',
      children: 'Discard',
      onClick: onDiscardClick,
      iconOnly: false,
      buttonComponent: DiscardButton,
      hidden: hideCancel,
      disabled: isLoading || isCancelling || isSaving,
      isLoading: isCancelling,
    },
    {
      key: 'save',
      children: 'Save',
      onClick: onSave,
      iconOnly: false,
      buttonComponent: PrimaryButton,
      hidden: false,
      disabled: isLoading || isCancelling || isSaveDisabled || isSaving,
      isLoading: isLoading || isSaving,
      dataTestId: SAVE_DATA_TEST_ID,
    },
  ];

  useEffect(() => {
    if (disableHistoryBlocking) {
      return;
    }

    return history.block(nextLocation => {
      if (isReadyToNavigate) {
        // Navigate normally
        return true;
      }

      setBlockedLocation(nextLocation);
      openUnsavedChangesModal(() => setIsReadyToNavigate(true));

      // Block navigation
      return false;
    });
  }, [disableHistoryBlocking, isReadyToNavigate, history]);

  useEffect(() => {
    if (disableHistoryBlocking) {
      return;
    }

    if (isReadyToNavigate && blockedLocation) {
      // Retry navigation
      history.push(blockedLocation.pathname);
    }
  }, [disableHistoryBlocking, isReadyToNavigate, blockedLocation, history]);

  return (
    <LayoutRoot>
      {getTitle()}
      <ButtonsContainer>
        {buttons.map(button => (
          <TopBarActionButton {...button} key={button.key} />
        ))}
      </ButtonsContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)`
  background: ${({ theme }) => theme.colors.neutralDeep};
  padding: 16px 24px;
  height: 64px;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    gap: 8px;
  }
`;

const SaveTitle = styled(TextH6)`
  color: ${({ theme }) => theme.colors.neutralLight};
`;

const ButtonsContainer = styled(Gap8HorizontalFlexWrap)``;

const DiscardButton = styled(GhostButton)`
  color: ${({ theme }) => theme.colors.neutralLight};
`;

export default SaveTopBar;
