import OverlayButtons from "./OverlayButtons";
import OverlayDescription from "./OverlayDescription";
import { FC } from "preact/compat";
import { HeroSettings } from "types/config";

type Props = {
  heroSettings: HeroSettings;
  isMobile: boolean;
  onSendEvent: (eventName: string) => void;
};

const OverlayDescriptionAndButtons: FC<Props> = ({
  heroSettings,
  isMobile,
  onSendEvent,
}) => {
  if (!heroSettings?.layoutSettings) {
    return null;
  }

  const {
    headerTextCustomization,
    subheaderTextCustomization,
    primaryButton,
    secondaryButton,
    layoutSettings,
  } = heroSettings || {};

  const shouldHideOnMobile =
    isMobile && layoutSettings?.hideTextAndButtonsOnMobile;
  const {
    desktopAlignment,
    mobileAlignment,
    desktopPosition,
    mobilePosition,
    buttonSpacing,
    stackButtonsOnMobile,
  } = layoutSettings || {};
  const alignment = isMobile ? mobileAlignment : desktopAlignment;

  const addLayoutSettings = () => {
    const alignment = {
      top: "flex-start",
      center: "center",
      middle: "center",
      bottom: "flex-end",
      right: "flex-end",
      left: "flex-start",
    };
    const style = {
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
    };

    if (isMobile) {
      const [vertical, horizontal] = mobilePosition.toLowerCase().split(" ");
      style.textAlign = mobileAlignment;
      style.justifyContent = alignment[vertical];
      style.alignItems = alignment[horizontal];
    } else {
      const [vertical, horizontal] = desktopPosition.toLowerCase().split(" ");
      style.textAlign = desktopAlignment;
      style.justifyContent = alignment[vertical];
      style.alignItems = alignment[horizontal];
    }

    return style;
  };

  if (
    shouldHideOnMobile ||
    (!headerTextCustomization?.text &&
      !subheaderTextCustomization?.text &&
      !primaryButton?.enabled &&
      !secondaryButton?.enabled)
  ) {
    return null;
  }

  return (
    <div
      style={{
        ...addLayoutSettings(),
        gap: `${layoutSettings?.verticalSpacing}px`,
        height: "100%",
      }}
      className="hero-overlay-content"
    >
      <div
        className="hero-overlay-text-buttons"
        style={{
          gap: `${layoutSettings?.verticalSpacing}px`,
        }}
      >
        <OverlayDescription
          headerSettings={headerTextCustomization}
          subHeaderSettings={subheaderTextCustomization}
          verticalSpacing={layoutSettings?.verticalSpacing}
          isMobile={isMobile}
        />
        <OverlayButtons
          primaryButton={primaryButton}
          secondaryButton={secondaryButton}
          shouldStackOnMobile={stackButtonsOnMobile}
          buttonSpacing={buttonSpacing}
          onSendEvent={onSendEvent}
          alignment={alignment}
          isMobile={isMobile}
        />
      </div>
    </div>
  );
};

export default OverlayDescriptionAndButtons;
