import React from 'react';
import styled, { useTheme } from 'styled-components';
import AnswerContainer from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/AnswerContainer';
import { getLetterIcon } from 'shared/react/utils/icons';
import DeleteIcon from 'app/src/images/DeleteIcon';
import GoTo from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/GoTo';
import AnswerTextEditor from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/custom/AnswerTextEditor';

const Answer = ({
  answer,
  index,
  stepsOrder,
  onChooseAnswerNext,
  handleAnswerChanged,
  handleRemoveAnswerClicked,
  onCollectViewerInfo,
  error,
  isLoading,
  multipleChoiceSelection,
  ...props
}) => {
  const theme = useTheme();
  const icon = getLetterIcon(index, theme.colors.black, 24);

  return (
    <LayoutRoot error={!!error}>
      <AnswerTextEditor
        answer={answer}
        index={index}
        handleAnswerChanged={handleAnswerChanged}
        error={error}
        icon={icon}
        isLoading={isLoading}
        {...props}
      />
      <GoTo
        onCollectViewerInfo={onCollectViewerInfo}
        isProductType={false}
        stepsOrder={stepsOrder}
        onChooseAnswerNext={onChooseAnswerNext}
        index={index}
        multipleChoiceSelection={multipleChoiceSelection}
        {...answer}
      />
      <TrashIconContainer onClick={() => handleRemoveAnswerClicked(index)}>
        <DeleteIcon fill={theme.colors.danger} />
      </TrashIconContainer>
    </LayoutRoot>
  );
};

const TrashIconContainer = styled.div`
  cursor: pointer;
  visibility: hidden;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    position: absolute;
    visibility: visible;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    top: 6px;
    right: 4px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => theme.getHexOpacity(theme.colors.white, 80)};
  }
`;

const LayoutRoot = styled(AnswerContainer)`
  transition: 0.3s;
  position: relative;
  grid-template-columns: 1fr auto auto;
  height: 56px;
  gap: 0;

  background: ${({ theme, error }) =>
    error ? theme.getHexOpacity(theme.colors.warningLight, 40) : ''};
  &:hover {
    background: ${({ theme, error }) =>
      error ? theme.getHexOpacity(theme.colors.warningLight, 60) : theme.colors.gray1};

    & ${TrashIconContainer} {
      visibility: visible;
    }
  }
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    grid-template-columns: 1fr auto;
  }
`;

export default Answer;
