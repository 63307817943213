import React from 'react';
import { project, vodAsset } from 'src/types/entities';
import styled from 'styled-components';
import { STEP_TYPE } from 'app/src/pages/project/pages/hero/constants';
import Utils from 'shared/react/utils/utils';
import { GhostButton, PrimaryButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { track } from 'app/src/helpers/Tracker';
import { LIBRARY_MODAL_KEY } from 'app/src/constants/modals.constants';
import { YOUTUBE } from 'app/src/constants/intergrations.constants';
import { useModalActions } from 'app/src/context/ui_store/ModalStore';
import { useProjectActions } from 'app/src/context/ProjectsStore';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextBodyLeading } from 'shared/react/components/complex/Text';

type Props = {
  project: project;
};

const HeroYoutubeStep = ({ project }: Props) => {
  const step = project?.steps?.items.find(({ description }) => description === STEP_TYPE.youtube);
  if (!step) {
    return;
  }

  return (
    <LayoutRoot>
      <Iframe src={Utils.getYoutubeEmbedUrl(step?.videoId)} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div``;

const Iframe = styled.iframe`
  border-radius: 4px;
  border: none;
`;

export default HeroYoutubeStep;
