import React from 'react';

const TypeIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.156 1.031 10.22 4.97l-.032.03H7.28l4-3.969.031-.031H14c.063 0 .094.031.156.031ZM12.281 5l3.281-3.25c.25.344.438.781.438 1.25v2h-3.719Zm-6-4h2.907l-3.97 3.969-.03.031H2.28l4-3.969L6.313 1H6.28ZM4.188 1 .217 4.969.189 5H0V3c0-1.094.875-2 2-2h2.188ZM0 6h16v7c0 1.125-.906 2-2 2H2c-1.125 0-2-.875-2-2V6Zm6.75 2.063a.539.539 0 0 0-.25.437v4c0 .188.094.375.25.469.156.062.375.062.5-.031l3-2a.499.499 0 0 0 .25-.438.488.488 0 0 0-.25-.406l-3-2C7.125 8 6.906 8 6.75 8.063Z"
        fill="#B3B6BC"
      />
    </svg>
  );
};

export default TypeIcon;
