import React, { useState } from 'react';
import styled from 'styled-components';
import { useVideos } from 'app/src/context/VideosStore';
import EmbedCarouselPreviewPlayButton from 'app/src/pages/project/pages/look_and_feel/preview/embed_carousel_preview/EmbedCarouselPreviewPlayButton';
import DefaultTilePreview from 'app/src/images/default_tile_preview.png';
import { CAROUSEL_TILE_NAME_LOCATION_TYPES } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import EmbedCarouselTileName from 'app/src/pages/project/pages/look_and_feel/preview/embed_carousel_preview/EmbedCarouselTileName';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { getVideoBorderRadius } from 'shared/react/components/complex/feed/feed_utils/feed.utils';

type Props = {
  size: number;
  videoId?: string | null;
  description?: string | null;
  widgetSettings?: any;
};

const PublishEmbedCarouselPreviewTile = ({ size, videoId, description, widgetSettings }: Props) => {
  const {
    carouselBorder,
    carouselBorderWidth,
    carouselBorderColor,
    carouselShape,
    carouselBorderRadius,
    carouselTileNameEnabled,
    carouselTileNameLocation,
    carouselTileNameTextSize,
    carouselTileNameTextColor,
    carouselTileNameBackgroundEnabled,
    carouselTileNameBackgroundColor,
    carouselTileNameBackgroundTransparancy,
  } = widgetSettings || {};
  const [, { getVideoById, getVideoPreview }] = useVideos();
  const [, { handleVideoLoadError }] = useVideos();
  const video = getVideoById(videoId);
  const [videoPreviewUrl, setVideoPreviewUrl] = useState(getVideoPreview(video));
  const isOver = carouselTileNameLocation === CAROUSEL_TILE_NAME_LOCATION_TYPES.over;

  const videoBorderRadius = getVideoBorderRadius(widgetSettings);
  const onVideoLoadError = () => {
    handleVideoLoadError(video, videoPreviewUrl, setVideoPreviewUrl, true);
  };

  const getVideoContainerStyle = () => {
    if (!carouselBorder || !carouselBorderWidth || !carouselBorderColor) {
      return { borderStyle: '', background: '' };
    }

    const borderWidth = carouselBorderWidth / size;
    const borderColor = carouselBorderColor;
    const borderStyle = `${borderWidth}px solid ${borderColor}`;

    return { borderStyle, background: borderColor };
  };

  const { borderStyle, background } = getVideoContainerStyle();

  return (
    <TileContainer isOver={isOver}>
      <VideoContainer
        borderStyle={borderStyle}
        background={background}
        borderRadius={carouselBorderRadius / size}
        size={size}
      >
        <BackgroundImage
          src={DefaultTilePreview}
          ratio={carouselShape}
          borderRadius={videoBorderRadius}
        />
        <Video
          borderRadius={videoBorderRadius}
          src={videoPreviewUrl}
          onError={onVideoLoadError}
          ratio={carouselShape}
          muted={true}
        />
        <EmbedCarouselPreviewPlayButton widgetSettingsPreview={widgetSettings} size={size} />
      </VideoContainer>
      <EmbedCarouselTileName
        size={size}
        text={description}
        carouselTileNameEnabled={carouselTileNameEnabled}
        carouselTileNameLocation={carouselTileNameLocation}
        carouselTileNameTextSize={carouselTileNameTextSize}
        carouselTileNameTextColor={carouselTileNameTextColor}
        carouselTileNameBackgroundEnabled={carouselTileNameBackgroundEnabled}
        carouselTileNameBackgroundColor={carouselTileNameBackgroundColor}
        carouselTileNameBackgroundTransparancy={carouselTileNameBackgroundTransparancy}
        carouselBorderRadius={carouselBorderRadius}
      />
    </TileContainer>
  );
};

const BackgroundImage = styled.img<{ ratio: string; borderRadius: number }>`
  aspect-ratio: ${({ ratio }) => ratio};
  object-fit: cover;
  border-radius: ${({ borderRadius }) => borderRadius}px;
  grid-column: 1;
  grid-row: 1;
  height: 100%;
  width: 100%;
`;

const Video = styled.video<{ ratio: string; borderRadius: number }>`
  aspect-ratio: ${({ ratio }) => ratio};
  object-fit: cover;
  border-radius: ${({ borderRadius }) => borderRadius}px;
  grid-column: 1;
  grid-row: 1;
  height: 100%;
  width: 100%;
`;

const VideoContainer = styled.div<{
  borderStyle: string;
  borderRadius: number;
  background: string;
  size: number;
}>`
  border: ${({ borderStyle }) => borderStyle};
  border-radius: ${({ borderRadius }) => borderRadius}px;
  background: ${({ background }) => background};
  transition: 0.3s;
  display: grid;
  pointer-events: auto;
  overflow: hidden;
  aspect-ratio: 9/16;
  max-height: ${({ size }) => 300 / size}px;
`;

const TileContainer = styled(VerticalFlex)`
  position: ${({ isOver }) => (isOver ? 'relative' : '')};
  gap: ${({ isOver }) => (isOver ? '' : '8px')};
`;

export default PublishEmbedCarouselPreviewTile;
