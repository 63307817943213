import classNames from 'classnames';
import { PLAYER_EVENTS } from 'player-events';
import usePlayerCommands from 'player/src/hooks/usePlayerCommands';
import useVideoTracking from 'player/src/hooks/useVideoTracking';
import React, { useEffect, useRef, useState } from 'react';
import useFeedState from 'shared/react/components/complex/context/hooks/useFeedState';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';
import { URL_KEY } from 'shared/react/constants/generalKeys.constants';
import { TOLSTOY_PLAYER_READY_MESSAGE } from 'shared/react/constants/messages.constants.js';
import { URL_SAME_TAB } from 'shared/react/constants/playerSettings.constants';
import UrlHelper from 'shared/react/utils/urlHelper';
import { getLocalStorageItem, testLocalStorage } from '../../actions/localStorage.actions.js';
import { createVideoResponse } from '../../actions/response.actions';
import Constants from '../../constants';
import {
  BUBBLE,
  CHAT_PLATFORMS,
  CHAT_RESPONSE_KEY,
  GCLID_KEY,
  LEAD_FORM_RESPONSE_KEY,
  OPEN_INTERCOM_RESPONSE_KEY,
  SIDE_BAR,
  VIDEO_RESPONSE_KEY,
} from '../../constants/tolstoy.constants';
import feedProjectIds from '../../customData/feedProjectIds.json';
import useHover from '../../hooks/useHover';
import useInterval from '../../hooks/useInterval';
import { addAnalyticsData, getSessionId, resetSession, track } from '../../tracker/Tracker';
import Utils from '../../utils';
import CustomVideo from '../CustomVideo';
import AnswerTypes from './answer-types/AnswerTypes';
import { postMessage } from './controls/controls.utils';
import {
  closePlayerInsideIntercomChat,
  findStepByKey,
  getLeadFormStateKey,
  getPosterUrl,
  getSentInCurrentSessionKey,
  getVideoUrl,
} from './player.utils';
import VideoOverlay from './video-overlay/VideoOverlay';

const getIsFeed = projectId => {
  if (feedProjectIds.data.tolstoy.includes(projectId)) {
    return true;
  }
  if (feedProjectIds.data.brokeSurgeryFeed.includes(projectId)) {
    return true;
  }
  if (feedProjectIds.data.yotpo.includes(projectId)) {
    return true;
  }
  if (feedProjectIds.data.smsBumpYotpo.includes(projectId)) {
    return true;
  }
  return false;
};

export const FIRST_INTERACTION_WITH_MUTED_AUTOPLAY = 'first-interaction-with-muted-autoplay';

function Player(props) {
  const {
    className,
    playlist,
    landingPage,
    startStep,
    wide,
    startPlay,
    collectInfo,
    accountId,
    cacheLeadFormAnswers,
    projectId,
    verticalOrientation,
    firstVideoLoaded,
    setFirstVideoLoaded,
    previewMode,
    parentUrl,
    replyPublishId,
    tolstoyType,
    isReply,
    playerType,
    chatLandingPage,
    gclid,
    autoplay,
    tolstoyInteractionDateVerification,
    shouldShowChatLandingPage,
    playerResolution,
    publishId,
  } = props;

  const [
    {
      isInitialMutedByDefault,
      showAutoplayUnmute,
      setShowAutoplayUnmute,
      isInitialMute,
      setIsInitialMute,
    },
  ] = useFeedState();
  const isStylist = tolstoyType === 'readyMade_stylist_response';
  const [resultVideoBlob, setResultVideoBlob] = useState(null);
  const [hasCacheLeadFormAnswers, setCacheLeadFormAnswers] = useState(cacheLeadFormAnswers);
  const [imageFile, setImageFile] = useState(null);
  const [firstVideo, setFirstVideo] = useState(findStepByKey(startStep, playlist));
  const [secondVideo, setSecondVideo] = useState(null);
  const [showStartScreen, setShowStartScreen] = useState(true);
  const [controlsShown, setControlsShown] = useState(false);
  const [showEndScreen, setShowEndScreen] = useState(false);
  const [isMovingToNextStep, setMovingToNextStep] = useState(false);
  const [transitioningFromVideoId, setTransitioningFromVideoId] = useState(null);
  const [previousVideoKeys, setPreviousVideoKeys] = useState([]);
  const [played, setPlayed] = useState(0);
  const [started, setStarted] = useState(false);
  const [videoDuration, setVideoDuration] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [userPause, setUserPause] = useState(false);
  const [elementsCount, setElementsCount] = useState(0);
  const [chapterPickerOpen, setChapterPickerOpen] = useState(false);
  const [canAccessStorage, setCanAccessStorage] = useState(false);
  const [goToAfterLeadFormStep, setGoToAfterLeadFormStep] = useState(isStylist ? 'end' : null);
  const [pushNextVideoKey, setPushNextVideoKey] = useState(null);
  const [currentSubtitles, setCurrentSubtitles] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isIntegrationLoading, setIsIntegrationLoading] = useState(false);
  const [answerKey, setAnswerKey] = useState('');
  const [promoCode, setPromoCode] = useState(null);
  const [videoSpeed, setVideoSpeed] = useState('1');
  const [nextStep, setNextStep] = useState(isStylist ? 'end' : '');
  const [nextStepType, setNextStepType] = useState(isStylist ? VIDEO_RESPONSE_KEY : '');
  const [selectedAnswerKeys, setSelectedAnswerKeys] = useState([]);
  const [calendlyWidgetSettings, setCalendlyWidgetSettings] = useState(null);
  const [isFeedModeActive, setIsFeedModeActive] = useState(getIsFeed(projectId));
  const [isDiscoverShown, setIsDiscoverShown] = useState(false);
  const [feedVideos, setFeedVideos] = useState([]);
  const [feedVideo, setFeedVideo] = useState(null);
  const [supportOpenChat, setSupportOpenChat] = useState(null);
  const [shouldShowFeedSupportButton, setShouldShowFeedSupportButton] = useState(false);
  const [shouldRememberSeenFeedTolstoys, setShouldRememberSeenFeedTolstoys] = useState(true);
  const [discoverText, setDiscoverText] = useState(null);
  const [discoverHeader, setDiscoverHeader] = useState(null);
  const [supportOnlyOnDiscover, setSupportOnlyOnDiscover] = useState(null);
  const [supportText, setSupportText] = useState(null);
  const answerRef = useRef(null);
  const firstVideoRef = useRef(null);
  const secondVideoRef = useRef(null);
  const onCanPlayVideoFunctionRef = useRef(null);
  let selectedVideo = getSelectedVideo();
  const shouldShowThread = !started && isReply;
  const isProducts = selectedVideo?.type === 'products' || selectedVideo?.type === 'shoppable';
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [userMuted, setUserMuted] = useState(isInitialMutedByDefault);
  const [quizAnswersKeys, setQuizAnswersKeys] = useState([]);
  const [showQuizEndScreen, setShowQuizEndScreen] = useState(false);
  const [sessionStartReported, setSessionStartReported] = useState(false);
  const [shouldInitializeVideoTracking, setShouldInitializeVideoTracking] = useState(false);
  const [{ direction }] = useTranslation();

  const canPlayThroughVideoRef = useRef(false);
  const [ref, isHovered] = useHover();
  const isEmbed = playerType === 'embed';
  const isWidget = playerType === BUBBLE;
  const { trackVideoLoop } = useVideoTracking({
    videoRef: getVideoRef(false),
    videoId: selectedVideo?.videoId,
    isDisabled: !shouldInitializeVideoTracking,
    isCurrentVideo: shouldInitializeVideoTracking,
  });

  const prepareToPlay = () => {
    setShowEndScreen(false);
    firstVideoRef.current?.parentElement?.parentElement?.focus?.();
  };
  const onCanPlayThrough = () => {
    canPlayThroughVideoRef.current = true;
    const videoRef = getActiveVideoRefCurrent();
    const currentTime = videoRef.currentTime + 0.001;
    setPlayed(currentTime / videoDuration);
    if (!videoRef.textTracks.length) {
      return;
    }

    const tracks = videoRef.textTracks[0];
    const cues = tracks?.cues;

    if (!cues?.length) {
      return;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const cue of cues) {
      if (cue.startTime <= currentTime && cue.endTime > currentTime) {
        handleCueEnter.apply(cue);
        return;
      }
    }
  };

  const triggerAutoplay = () => {
    console.log('autoPlay ');
    track(PLAYER_EVENTS.autoplayStart);
    setUserMuted(true);
    setShowAutoplayUnmute(true);
    prepareToPlay();
    moveTo('start');
  };

  window.tolstoyAutoplay = triggerAutoplay;

  window.tolstoyPlay = ({ partName, partNumber }) => {
    console.log('play ');
    prepareToPlay();
    if (partName) {
      const part = playlist.find(p => p.question === partName);
      setPushNextVideoKey(part.key);
    } else if (partNumber) {
      const part = playlist[partNumber - 1] || playlist[0];
      setPushNextVideoKey(part.key);
    } else {
      start();
    }
  };

  useInterval(() => {
    let videoRefCurrent = getVideoRefCurrent();
    if (!videoRefCurrent || transitioningFromVideoId) {
      return;
    }

    setIsLoading(videoRefCurrent.readyState < videoRefCurrent.HAVE_FUTURE_DATA);
  }, 1000);

  const resetData = restartSession => {
    setResultVideoBlob(null);
    setImageFile(null);
    setGoToAfterLeadFormStep(null);
    setCalendlyWidgetSettings(null);
    setQuizAnswersKeys([]);
    setShowQuizEndScreen(false);
    setIsIntegrationLoading(false);
    setPreviousVideoKeys([]);
    setShowEndScreen(false);
    setPlayed(0);
    clearControls();
    if (restartSession) {
      resetSession(false);
    }
  };

  const turnOffFeedMode = () => {
    setIsFeedModeActive(false);
    setFirstVideoLoaded(false);
    resetData();
  };

  window.tolstoyReset = () => {
    if (transitioningFromVideoId) {
      setTimeout(() => {
        window.tolstoyReset();
      }, 0);
      return;
    }
    if (feedVideo || isFeedModeActive) {
      setIsDiscoverShown(true);
    }
    console.log('reset');
    resetData();
    closeModal();
    let video = findStepByKey(startStep, playlist);
    if (firstVideo) {
      setSecondVideo(video);
      setFirstVideo(null);
    } else {
      setFirstVideo(video);
      setSecondVideo(null);
    }
    pause();
  };

  function clearControls() {
    setControlsShown(false);
  }

  function getSelectedVideo() {
    if (transitioningFromVideoId) {
      if (firstVideo && firstVideo.key === transitioningFromVideoId) {
        return firstVideo;
      } else {
        return secondVideo;
      }
    }
    return firstVideo || secondVideo;
  }

  function getActiveVideoRefCurrent() {
    return getVideoRefCurrent(false);
  }

  function getNextVideoRefCurrent() {
    return getVideoRefCurrent(true);
  }

  function getVideoRefCurrent(next) {
    return getVideoRef(next).current;
  }

  function getVideoRef(next) {
    if (firstVideo && firstVideo.key === selectedVideo.key) {
      return next ? secondVideoRef : firstVideoRef;
    } else {
      return next ? firstVideoRef : secondVideoRef;
    }
  }

  function getVideoTime() {
    const videoRef = getActiveVideoRefCurrent();
    if (videoRef) {
      return Math.round(videoRef.currentTime * 10) / 10;
    }
    return 0;
  }

  function replay(showControls) {
    track('videoReplay');
    setShowEndScreen(false);
    setUserPause(false);
    setPlaying(true);
    setControlsShown(showControls);
    setPlayed(0);
    trackVideoLoop();
    let videoRef = getActiveVideoRefCurrent();
    if (videoRef) {
      videoRef.currentTime = 0;
      play({ videoRef });
    }
  }

  function handlePlayPause(e) {
    e?.stopPropagation();
    if (!started && showStartScreen) {
      start();
      return;
    }
    if (playing) {
      if (isInitialMute || showAutoplayUnmute) {
        muteVideo(false);
        setUserMuted(false);
        setIsInitialMute(false);
        setShowAutoplayUnmute(false);
        return;
      }

      track('videoPause', { videoTime: getVideoTime() });
      setUserPause(true);
      pause();
    } else {
      track('videoResume');
      if (autoplay && !sessionStartReported) {
        track(PLAYER_EVENTS.sessionStart, {});
        setSessionStartReported(true);
      }
      setUserPause(false);
      play();
    }
  }

  async function play({ videoRef, mute, nextVideo, cancelUserPause, withRetry } = {}) {
    if (!videoRef) {
      videoRef = getActiveVideoRefCurrent();
    }

    if (!videoRef || isDiscoverShown) {
      return;
    }

    if (videoRef.paused) {
      videoRef.muted = userMuted || mute;
      try {
        await videoRef.play();
        setUserPause(false);
      } catch (err) {
        console.log('ios reject autoplay ', err);

        if (withRetry) {
          return err;
        }

        const videoUrl = nextVideo || getSelectedVideo();
        if (!isFeedModeActive) {
          videoRef.poster = getPosterUrl(videoUrl, accountId);
        }
        setFirstVideoLoaded(true);
        if (!cancelUserPause) {
          setUserPause(true);
        }
        setPlaying(false);
        return err;
      }
    }
    setPlaying(true);
  }

  function pause() {
    const videoRef = getActiveVideoRefCurrent();
    if (videoRef) {
      if (!videoRef.paused) {
        videoRef.pause();
      }
      setPlaying(false);
    }
  }

  function resumePause(e) {
    if (e.keyCode === 32 && !nextStepType) {
      handlePlayPause(e);
    }
  }

  function start() {
    const features = isWidget ? { tolstoyInteractionDateVerification } : {};
    track(PLAYER_EVENTS.sessionStart, {}, features);
    setSessionStartReported(true);
    if (collectInfo?.afterStep?.includes('start')) {
      collectInfoForm('start');
    } else {
      moveTo('start');
    }
  }

  function onVideoReplacementTransitionCompleted(
    videoRef,
    nextVideoRef,
    firstVideoActive,
    backNavigation,
    restart
  ) {
    setAnswerKey('');
    setNextStepType(null);
    pause();

    if (videoRef) {
      videoRef.muted = true;
      videoRef.classList.add('video-exit-transition');
    }

    if (nextVideoRef) {
      nextVideoRef.style.zIndex = 1;

      let duration = nextVideoRef.duration;
      setVideoDuration(duration);
    }

    setTransitioningFromVideoId(null);
    setMovingToNextStep(false);
    setSelectedAnswerKeys([]);

    if (firstVideoActive) {
      setFirstVideo(null);
    } else {
      setSecondVideo(null);
    }

    if (backNavigation && !restart) {
      setControlsShown(true);
    }
  }

  function handleCueEnter() {
    if (canPlayThroughVideoRef.current) {
      setCurrentSubtitles(this.text.replace('<v ->', '').replace('</v>', ''));
    }
  }

  function handleCueExit() {
    if (canPlayThroughVideoRef.current) {
      setCurrentSubtitles(null);
    }
  }

  function addSubtitlesChangeListener(nextVideoRef) {
    if (!nextVideoRef.textTracks.length) {
      return;
    }
    setCurrentSubtitles(null);

    const tracks = nextVideoRef.textTracks[0];

    tracks.mode = 'hidden';
    const cues = tracks?.cues;

    if (!cues?.length) {
      return;
    }

    if (cues[0].startTime === 0) {
      handleCueEnter.apply(cues[0]);
    }

    for (let i = 0; i < cues.length; i++) {
      cues[i].onenter = handleCueEnter;
      cues[i].onexit = handleCueExit;
    }
  }

  function onButtonHeartbeatAnimationCompleted(
    firstVideoActive,
    backNavigation,
    restart,
    nextVideo,
    isEmbedRestart
  ) {
    if (backNavigation) {
      previousVideoKeys.pop();
      setPreviousVideoKeys([...previousVideoKeys]);
    }
    setUserPause(false);

    const videoRef = getActiveVideoRefCurrent();
    clearControls();
    setIsLoading(true);

    let nextVideoRef = getNextVideoRefCurrent();
    if (nextVideoRef) {
      nextVideoRef.currentTime = 0;
      addSubtitlesChangeListener(nextVideoRef);

      play({ videoRef: nextVideoRef, nextVideo });
    }

    const onVideoReplacement = () =>
      onVideoReplacementTransitionCompleted(
        videoRef,
        nextVideoRef,
        firstVideoActive,
        backNavigation,
        restart
      );

    if (isEmbedRestart) {
      onVideoReplacement();
      return;
    }

    onCanPlayVideoFunctionRef.current = onVideoReplacement;
  }

  const onCanPlay = () => {
    canPlayThroughVideoRef.current = false;
    setPlayed(0);
    onCanPlayVideoFunctionRef.current?.();
    onCanPlayVideoFunctionRef.current = null;
  };

  const getUrlValue = () => {
    let url = answerRef.current.value;

    if (url.startsWith('/')) {
      const origin = new URL(parentUrl).origin;
      url = `${origin}${url}`;
    }

    if (!gclid) {
      return url || '';
    }

    return Utils.addUrlParam(url, GCLID_KEY, gclid);
  };

  const shouldOpenInSameTab = () => {
    if (getUrlValue().substring(0, 6) === '_self:') {
      return true;
    }

    return answerRef.current?.next === URL_SAME_TAB;
  };

  const moveToUrl = () => {
    setAnswerKey('');
    const value = getUrlValue();
    if (shouldOpenInSameTab()) {
      const url = value.replace('_self:', '');
      const replyUrl = Utils.addUrlParam(url, 'tolstoy-reply', replyPublishId);
      window.top.location.href = replyPublishId ? replyUrl : url;
    } else {
      const url = UrlHelper.enforceProtocol(value);
      const replyUrl = Utils.addUrlParam(url, 'tolstoy-reply', replyPublishId);
      if (UrlHelper.isValidUrl(url)) {
        window.open(replyPublishId ? replyUrl : url, '_blank');
      }
    }
    if (answerRef.current.type === 'end') {
      moveTo('end');
      setNextStepType(null);
    }
  };

  const shouldRestartOnEmbed = () => {
    const ref = getVideoRef();

    return (
      (showStartScreen || nextStepType === LEAD_FORM_RESPONSE_KEY) &&
      isEmbed &&
      ref?.current?.src?.includes('preview_embed')
    );
  };

  const moveToStart = () => {
    if (shouldRestartOnEmbed()) {
      handleRestart(false);
      return;
    }
    setShowStartScreen(false);
    setPlayed(0);
    setStarted(true);
    setAnswerKey('');
    setUserPause(false);
    clearControls();
    const videoRef = getActiveVideoRefCurrent();
    if (videoRef) {
      addSubtitlesChangeListener(videoRef);

      videoRef.currentTime = 0;
    }

    Utils.scheduleCallbackToNextRender(() => setShouldInitializeVideoTracking(true));

    if (!isFeedModeActive) {
      safePlay();
    }
  };

  const safePlay = async () => {
    const error = await play({ withRetry: true, mute: userMuted });
    if (!error) {
      setIsInitialMute(userMuted);
      return;
    }

    if (error.name === 'NotAllowedError') {
      setShowAutoplayUnmute(true);
      setUserMuted(true);
      setIsInitialMute(true);
      return play({ mute: true });
    }
  };

  const moveToEnd = () => {
    clearControls();
    track('sessionEnd');
    pause();
    setShowQuizEndScreen(true);
    setNextStepType(null);
    setNextStep(null);
    setAnswerKey('');
    setShowEndScreen(true);
  };

  const moveToPart = (key, buttonRef, backNavigation, restart) => {
    const currentVideo = firstVideo || secondVideo;
    const firstVideoActive = firstVideo != null;

    if (currentVideo.key === key && !isEmbed) {
      setNextStepType(null);
      setNextStep(null);
      setAnswerKey('');
      replay(false);
      return;
    }

    if (buttonRef) {
      buttonRef.classList.add(backNavigation ? 'back-button-clicked' : 'btn-default-clicked');
    }

    if (!backNavigation) {
      setPreviousVideoKeys([...previousVideoKeys, currentVideo.key]);
    }

    setTransitioningFromVideoId(currentVideo.key);

    let step = findStepByKey(key, playlist);

    if (firstVideo) {
      setSecondVideo(step);
    } else {
      setFirstVideo(step);
    }

    if (!nextStepType) {
      setNextStepType(null);
    }

    const onAnimationComplete = () => {
      const isEmbedRestart = currentVideo.key === key && isEmbed;
      onButtonHeartbeatAnimationCompleted(
        firstVideoActive,
        backNavigation,
        restart,
        step,
        isEmbedRestart
      );

      if (isEmbedRestart) {
        setShowStartScreen(false);
        setPlayed(0);
        setStarted(true);
        setAnswerKey('');
        setUserPause(false);
        setPlaying(true);
      }
    };

    setTimeout(
      onAnimationComplete,
      backNavigation
        ? Constants.ButtonBackHeartbeatAnimationMilliseconds
        : Constants.ButtonNextHeartbeatAnimationMilliseconds
    );
  };

  const openIntegration = () => {
    setNextStepType(null);
    const step = getSelectedVideo();
    const isDefaultResponseEnabled = step?.defaultResponsesEnabled;
    let value;
    if (isDefaultResponseEnabled) {
      value = step.defaultResponses.find(answer => answer.key === answerKey)?.value;
    } else {
      value = step.answers.find(answer => answer.key === answerKey)?.value;
    }

    postMessage(CHAT_PLATFORMS[value]);
    setIsIntegrationLoading(true);
    if (value === OPEN_INTERCOM_RESPONSE_KEY) {
      closePlayerInsideIntercomChat();
    }
  };

  function moveTo(key, buttonRef, backNavigation, restart) {
    if (key === URL_KEY || key === URL_SAME_TAB) {
      moveToUrl();
    } else if (key === CHAT_RESPONSE_KEY) {
      openIntegration();
    } else if (key === 'start') {
      moveToStart();
    } else if (key === 'end') {
      moveToEnd();
    } else {
      moveToPart(key, buttonRef, backNavigation, restart);
    }
  }

  const shouldSkipLeadForm = leadFormState => {
    if (!leadFormState) {
      return false;
    }

    const fieldsThatAreNotCollected = collectInfo.fields.filter(
      field => field.collect && !field.value && field.key !== 'consent'
    );

    return !fieldsThatAreNotCollected.length;
  };

  function collectInfoForm(next, buttonRef, isCustom) {
    if (hasCacheLeadFormAnswers && !previewMode && !isCustom) {
      const leadFormState = canAccessStorage && getLocalStorageItem(getLeadFormStateKey(projectId));

      if (shouldSkipLeadForm(leadFormState)) {
        setGoToAfterLeadFormStep(null);
        if (SIDE_BAR === next) {
          closeModal();
          play();
          return;
        }

        const sessionId = getSessionId();
        const leadFormSentInSessionKey = getSentInCurrentSessionKey(sessionId);
        if (!sessionStorage.getItem(leadFormSentInSessionKey)) {
          track('collectInfo', JSON.parse(leadFormState));
          sessionStorage.setItem(leadFormSentInSessionKey, 'true');
        }

        moveTo(next, buttonRef);
        return;
      }
    }
    setCacheLeadFormAnswers(true);
    setShowStartScreen(false);
    pause();
    if ((collectInfo.fields || []).some(f => f.collect)) {
      collectInfo.fields.forEach(f => {
        if (f.value) {
          f.collect = false;
        }
      });
    } else {
      collectInfo.fields = [
        { name: 'email', type: 'email', required: true, collect: true, value: '' },
      ];
    }

    if (isCustom || isStylist) {
      setGoToAfterLeadFormStep(null);
    }

    setNextStepType(LEAD_FORM_RESPONSE_KEY);
    setNextStep(next);
    setControlsShown(true);
  }

  function onLoaded() {
    if (showStartScreen && startPlay) {
      start();
    }
  }

  let enterTransition = firstVideoLoaded && (showStartScreen || userPause);

  function onImageUploadCancel() {
    setImageFile(null);
    closeModal();
  }

  const closeModal = () => {
    setAnswerKey('');
    setNextStep(null);
    setNextStepType(null);
    setGoToAfterLeadFormStep(null);
  };

  function handleGoBack() {
    setShowEndScreen(false);
    replay(true);
  }

  function handleRestart(restartSession = true) {
    resetData(restartSession);
    moveTo(startStep, null, true, true);
  }

  const toggleFullscreen = async () => {
    if (isFullscreen) {
      await document.exitFullscreen();
      setIsFullscreen(false);
      return;
    }

    const elementId = Utils.isMobile() ? 'videoContainer' : 'playerContainer';
    await document.getElementById(elementId).requestFullscreen();

    setIsFullscreen(true);
  };

  const toggleSafariFullscreen = async () => {
    if (isFullscreen) {
      if (Utils.isMobile()) {
        await document.getElementById('videoContainer').webkitExitFullscreen();
      } else {
        await document.webkitExitFullscreen();
      }
      setIsFullscreen(false);
      return;
    }

    if (Utils.isMobile()) {
      await document.getElementById('videoContainer').webkitEnterFullscreen();
    } else {
      await document.getElementById('playerContainer').webkitRequestFullscreen();
    }
    setIsFullscreen(true);
  };

  const handleFullscreenExit = () => {
    if (
      !document.fullscreenElement &&
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement &&
      !document.getElementById('videoContainer').webkitDisplayingFullscreen
    ) {
      setIsFullscreen(false);
    }
  };

  const initFeed = async () => {
    let feedData;
    if (feedProjectIds.data.tolstoy.includes(projectId)) {
      feedData = await import(
        /* webpackChunkName: "feed-json-tolstoy" */ '../../customData/feed.json'
      );
    }

    if (feedProjectIds.data.brokeSurgeryFeed.includes(projectId)) {
      feedData = await import(
        /* webpackChunkName: "feed-json-brooke-surgery" */ '../../customData/brokeSurgeryFeed.json'
      );
    }

    if (feedProjectIds.data.yotpo.includes(projectId)) {
      feedData = await import(
        /* webpackChunkName: "feed-json-yotpo" */ '../../customData/yotpoKnowlageBase.json'
      );
    }
    if (feedProjectIds.data.smsBumpYotpo.includes(projectId)) {
      feedData = await import(
        /* webpackChunkName: "feed-json-sms-bump-yotpo" */ '../../customData/smsBumpYotpo.json'
      );
    }

    if (!feedData) {
      return;
    }

    setShouldShowFeedSupportButton(feedData.showSupport);
    setDiscoverText(feedData.discoverText);
    setDiscoverHeader(feedData.discoverHeader);
    setSupportOnlyOnDiscover(feedData.supportOnlyOnDiscover);
    setSupportOpenChat(feedData.supportOpenChat);
    setFeedVideos(feedData.data);
    setSupportText(feedData.supportText);
    setShouldRememberSeenFeedTolstoys(feedData.rememberSeenTolstoy ?? true);
    setIsFeedModeActive(true);
    setIsDiscoverShown(true);
    setFirstVideoLoaded(true);
    setStarted(1);
    setShowStartScreen(false);
    addAnalyticsData({
      libraryLayout: true,
      videoName: 'Discover',
    });
  };

  const handleVideoSpeed = speed => {
    setVideoSpeed(speed);
  };

  const handleMuteUnMute = () => {
    getActiveVideoRefCurrent().muted = !userMuted;
    setUserMuted(!userMuted);
  };

  const muteVideo = mute => {
    getActiveVideoRefCurrent().muted = mute;
    setUserMuted(mute);
  };

  usePlayerCommands({
    videoRef: getActiveVideoRefCurrent(),
    handleMuteClick: handleMuteUnMute,
    handleVideoSpeed,
    handlePlayPause,
    publishId,
    currentStep: selectedVideo,
    started,
  });

  const sharedProps = {
    ...props,
    videoSpeed,
    isFeedModeActive,
    feedVideo,
    showStartScreen,
    shouldShowThread,
    selectedVideo,
    controlsShown,
    setControlsShown,
    showEndScreen,
    setPlayed,
    videoDuration,
    videoTime: getVideoTime,
    transitioningFromVideoId,
    pause,
    userMuted,
    play,
    userPause,
    getActiveVideoRefCurrent,
    answerKey,
    setChapterPickerOpen,
    setResultVideoBlob,
    setImageFile,
    setCalendlyWidgetSettings,
    setGoToAfterLeadFormStep,
    collectInfoForm,
    moveTo,
    nextStep,
    supportOnlyOnDiscover,
    discoverHeader,
    setShowQuizEndScreen,
    showQuizEndScreen,
    setIsLoading,
    isInitialMute,
  };

  function getCustomVideo(video, videoRef, videoUrl, index) {
    return (
      <CustomVideo
        {...sharedProps}
        key={index}
        isEmbed={isEmbed}
        video={video}
        videoRef={videoRef}
        onCanPlay={onCanPlay}
        addSubtitlesChangeListener={addSubtitlesChangeListener}
        videoContain={video.videoContain}
        isFirstVideo={startStep === video.key}
        videoUrl={videoUrl}
        videoOwner={video.videoOwner || accountId}
        setElementsCount={setElementsCount}
        playing={playing}
        setVideoDuration={setVideoDuration}
        goToEnd={() => moveTo('end')}
        videoId={video.videoId}
        videoKey={video.key}
        onLoaded={onLoaded}
        handleFullscreenExit={handleFullscreenExit}
        isFullscreen={isFullscreen}
        videoType={video.type}
        isProducts={isProducts}
        onCanPlayThrough={onCanPlayThrough}
        canPlayThroughVideo={canPlayThroughVideoRef.current}
        shouldShowChatLandingPage={shouldShowChatLandingPage}
        playerResolution={playerResolution}
      />
    );
  }

  const getVideoOverlay = () => {
    return (
      <VideoOverlay
        {...sharedProps}
        isProducts={isProducts}
        setQuizAnswersKeys={setQuizAnswersKeys}
        quizAnswersKeys={quizAnswersKeys}
        supportOpenChat={supportOpenChat}
        setIsDiscoverShown={setIsDiscoverShown}
        isDiscoverShown={isDiscoverShown}
        handlePlayPause={handlePlayPause}
        isHovered={isHovered}
        replay={replay}
        played={played}
        setVideoSpeed={setVideoSpeed}
        elementsCount={elementsCount}
        selectedAnswerKeys={selectedAnswerKeys}
        isMovingToNextStep={isMovingToNextStep}
        currentSubtitles={currentSubtitles}
        setMovingToNextStep={setMovingToNextStep}
        previousVideoKeys={previousVideoKeys}
        answerRef={answerRef}
        setSelectedAnswerKeys={setSelectedAnswerKeys}
        setAnswerKey={setAnswerKey}
        setNextStep={setNextStep}
        setNextStepType={setNextStepType}
        nextStepType={nextStepType}
        setIsIntegrationLoading={setIsIntegrationLoading}
        promoCode={promoCode}
        setPromoCode={setPromoCode}
        toggleFullscreen={toggleFullscreen}
        toggleSafariFullscreen={toggleSafariFullscreen}
        muteVideo={muteVideo}
        isVertical={verticalOrientation}
        isChatLandingPage={chatLandingPage}
        started={started}
        playerWidth={ref?.current?.clientWidth}
        setVideo={setFeedVideo}
        feedVideos={feedVideos}
        turnOffFeedMode={turnOffFeedMode}
        setIsFeedModeActive={setIsFeedModeActive}
        enterTransition={enterTransition}
        isLoading={isLoading}
        isIntegrationLoading={isIntegrationLoading}
        onGoBack={handleGoBack}
        onRestart={handleRestart}
        shouldShowFeedSupportButton={shouldShowFeedSupportButton}
        shouldRememberSeenFeedTolstoys={shouldRememberSeenFeedTolstoys}
        discoverText={discoverText}
        showAutoplayUnmute={showAutoplayUnmute}
        setShowAutoplayUnmute={setShowAutoplayUnmute}
        supportText={supportText}
        isEmbed={isEmbed}
        sessionStartReported={sessionStartReported}
        setSessionStartReported={setSessionStartReported}
        shouldShowChatLandingPage={shouldShowChatLandingPage}
      />
    );
  };

  useEffect(() => {
    if (isStylist && playing) {
      setNextStepType(VIDEO_RESPONSE_KEY);
      setGoToAfterLeadFormStep('end');
    }
  }, [playing]);

  useEffect(() => {
    setGoToAfterLeadFormStep(isStylist ? 'end' : null);
  }, [tolstoyType]);

  useEffect(() => {
    if (!transitioningFromVideoId && pushNextVideoKey) {
      setPushNextVideoKey(null);
      moveTo(pushNextVideoKey);
      start();
    }
  }, [transitioningFromVideoId, pushNextVideoKey]);

  useEffect(() => {
    initFeed();
  }, [projectId]);

  useEffect(() => {
    window.parent.postMessage({ eventName: TOLSTOY_PLAYER_READY_MESSAGE }, '*');
    window.postMessage({ eventName: TOLSTOY_PLAYER_READY_MESSAGE }, '*');
    document.addEventListener('fullscreenchange', handleFullscreenExit);

    setCanAccessStorage(testLocalStorage());
    if ((isEmbed || landingPage) && autoplay) {
      triggerAutoplay();
    }
  }, []);

  return (
    <div
      tabIndex="0"
      ref={ref}
      onKeyDown={resumePause}
      className={classNames(className, 'player-wrapper', {
        'player-wrapper-mobile': !wide,
        'player-wrapper-landing-page': landingPage,
        'player-wrapper-vertical': verticalOrientation,
        'player-wrapper-chat': shouldShowChatLandingPage,
        'player-wrapper-rtl': direction === 'rtl',
      })}
      id="playerContainer"
      aria-label="Tolstoy interactive video"
      role="group"
    >
      {secondVideo && getCustomVideo(secondVideo, secondVideoRef, getVideoUrl(secondVideo), 1)}
      {firstVideo && getCustomVideo(firstVideo, firstVideoRef, getVideoUrl(firstVideo), 2)}
      {getVideoOverlay()}
      <AnswerTypes
        {...sharedProps}
        selectedVideo={selectedVideo}
        allowCancel={isStylist}
        canAccessStorage={canAccessStorage}
        calendlyWidgetSettings={calendlyWidgetSettings}
        resultVideoBlob={resultVideoBlob}
        closeModal={closeModal}
        imageFile={imageFile}
        nextStepType={nextStepType}
        onImageUploadCancel={onImageUploadCancel}
        goToAfterLeadFormStep={goToAfterLeadFormStep}
        chapterPickerOpen={chapterPickerOpen}
        controlsShown={controlsShown}
        onVideoUpload={createVideoResponse}
      />
    </div>
  );
}

export default Player;
