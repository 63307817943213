import React, { useRef, useState } from 'react';
import { useProductPageSettings } from 'app/src/context/ProductPageSettingsStore';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import InputWithIcon from 'app/src/basic_components/InputWithIcon';
import styled from 'styled-components';
import EarthIcon from 'app/src/images/EarthIcon';
import Utils from 'app/src/utils';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';
import { PRODUCT_SOURCES } from 'app/src/constants/publish.constants';
import ProductItem from 'app/src/pages/modals/publish/embed/feed_embed_widgets/feed_embed_widgets_shopify_products/ProductItem';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { EMBED_WIDGETS_NAMES } from 'app/src/constants/ui.constants';

const EmbedPDPByUrl = ({ project, widgetType }) => {
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const timeoutRef = useRef(null);
  const [, { setErrorSnackbar }] = useSnackBar();
  const [
    { products },
    {
      createProductPageSettings,
      deleteProductPageSettings,
      getProductPageSettingByProductId,
      getIsProductAlreadyPublished,
    },
  ] = useProductPageSettings({ publishId: project?.publishId, widgetType });

  const fetchProductFromUrl = async url => {
    if (!Utils.isValidUrl(url)) {
      return;
    }
    const urlWithoutParams = url.split('?')[0];

    const existingProducts = getProductPageSettingByProductId(urlWithoutParams);
    const isProductAlreadyPublished = getIsProductAlreadyPublished(existingProducts, widgetType);

    if (isProductAlreadyPublished) {
      setErrorSnackbar(
        `A different ${EMBED_WIDGETS_NAMES[widgetType]} is already previewed on this page.`
      );
      return;
    }

    setLoading(true);
    await createProductPageSettings({
      publishId: project.publishId,
      widgetType,
      productUrl: urlWithoutParams,
      productSource: PRODUCT_SOURCES.url,
    });

    setValue('');
    setLoading(false);
  };

  const onInputValueChange = e => {
    const { value } = e.target;
    clearTimeout(timeoutRef.current);
    setValue(value);

    timeoutRef.current = setTimeout(() => {
      fetchProductFromUrl(value);
    }, 600);
  };

  const deleteProduct = async product => {
    setLoading(true);

    await deleteProductPageSettings({ id: product.id });

    setLoading(false);
  };
  return (
    <LayoutRoot>
      <Input
        value={value}
        onChange={onInputValueChange}
        icon={<EarthIcon />}
        placeholder="Enter a link of product page"
      />
      <SelectedItemsContainer>
        {products.map(product => {
          return (
            <MenuItem
              disabled={loading}
              key={product.productUrl}
              product={{ ...product, title: product.productUrl }}
              deleteProduct={() => deleteProduct(product)}
            />
          );
        })}
      </SelectedItemsContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)`
  margin-top: 4px;
`;

const SelectedItemsContainer = styled(VerticalFlex)`
  margin-top: 6px;
  gap: 6px;
`;

const Input = styled(InputWithIcon)`
  height: 48px;
  width: 336px;
`;

const MenuItem = styled(ProductItem)`
  border-radius: 8px;
  padding: 0px 8px;
  box-shadow: 0px 0px 2px 0px #00000040;
  width: 336px;
`;

export default EmbedPDPByUrl;
