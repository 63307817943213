import React, { useState } from 'react';
import styled from 'styled-components';
import { PrimaryButton } from 'shared/react/components/basic/button-v2/BasicButton';
import ExpanderTopArrowIcon from 'app/src/images/creation_method_icons/ExpanderTopArrowIcon';
import PublishInstructionsModal from './PublishInstructionsModal';

const PublishButton = ({ project }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div>
      <Button onClick={() => setExpanded(!expanded)}>
        Publish
        <ExpanderContainer $expanded={expanded}>
          <ExpanderTopArrowIcon />
        </ExpanderContainer>
      </Button>
      {expanded && <PublishInstructionsModal project={project} />}
    </div>
  );
};

const Button = styled(PrimaryButton)`
  width: 120px;
`;

const ExpanderContainer = styled.div<{ $expanded: boolean }>`
  transform: rotate(${({ $expanded }) => ($expanded ? 0 : 180)}deg) translateY(-1px);

  & svg path {
    fill: white;
  }
`;

export default PublishButton;
