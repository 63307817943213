export const URL_KEY = 'url';
export const EXIT_INTENT_KEY = 'exitIntent';
export const EMAIL_KEY = 'email';
export const SIGNED_UP_AT_KEY = 'signedUpAt';
export const SESSION_COUNT_KEY = 'sessionCount';
export const LAST_SEEN_AT_KEY = 'lastSeenAt';
export const FIRST_SEEN_AT_KEY = 'firstSeenAt';
export const EQUALS_KEY = 'equals';
export const GREATER_THAN_KEY = 'greaterThan';
export const LESS_THAN_KEY = 'lessThan';
export const IN_THE_LAST_X_DAYS_KEY = 'inTheLastXDays';
export const NOT_IN_THE_LAST_X_DAYS_KEY = 'notInTheLastXDays';
export const ANY_VALUE_KEY = 'anyValue';
export const NOT_EQUALS_KEY = 'notEquals';
export const STARTS_WITH_KEY = 'startsWith';
export const ENDS_WITH_KEY = 'endsWith';
export const CONTAINS_KEY = 'contains';
export const NOT_CONTAINS_KEY = 'notContains';
export const DATE_CONDITION_TYPE = 'date';
export const NUMBER_CONDITION_TYPE = 'number';
export const BOOLEAN_CONDITION_TYPE = 'boolean';
export const IN_KEY = 'in';
export const NOT_IN_KEY = 'notIn';
export const TOLSTOY_PLAY_SESSION_COUNT_KEY = 'tolstoyPlayCount';
export const TOLSTOY_IMPRESSIONS_SESSION_COUNT_KEY = 'tolstoyImpressionCount';
export const DELAY_TRIGGER_TYPE = 'delayTrigger';
export const HIDE_VOD_ASSET_TYPE = 'hideVodAsset';
export const ORDER_FEED_TYPE = 'orderFeed';
