import React from 'react';

const ScreenRecordingIcon = () => {
  return (
    <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.125.5H1.875C1.242.5.75 1.016.75 1.625v7.5a1.11 1.11 0 0 0 1.125 1.125h4.5L6 11.375H4.312a.555.555 0 0 0-.562.563c0 .328.234.562.563.562h6.375a.555.555 0 0 0 .562-.563.57.57 0 0 0-.563-.562H9l-.375-1.125h4.5c.61 0 1.125-.492 1.125-1.125v-7.5A1.14 1.14 0 0 0 13.125.5Zm-.375 8.25H2.25V2h10.5v6.75Z"
        fill="#50545E"
      />
    </svg>
  );
};

export default ScreenRecordingIcon;
