import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Utils from 'app/src/utils';
import Expander from 'shared/react/components/complex/expanders/Expander';
import WidgetSettingsEditorGroup from 'app/src/pages/project/pages/look_and_feel/editor/editors/widget_settings_editor/WidgetSettingsEditorGroup';
import PlayerEditorGroup from 'app/src/pages/project/pages/look_and_feel/editor/editors/player_editor/PlayerEditorGroup';
import LandingPageEditorGroup from 'app/src/pages/project/pages/look_and_feel/editor/editors/landing_page_editor/LandingPageEditorGroup';
import LeadFormEditorGroup from 'app/src/pages/project/pages/look_and_feel/editor/editors/lead_form_editor/LeadFormEditorGroup';
import LandingPageIcon from 'shared/react/images/look_and_feel/LandingPageIcon';
import LeadFormIcon from 'shared/react/images/look_and_feel/LeadFormIcon';
import FeedIcon from 'shared/react/images/look_and_feel/FeedIcon';
import ProductDetailsIcon from 'shared/react/images/look_and_feel/ProductDetailsIcon';
import FeedAppearanceEditorGroup from 'app/src/pages/project/pages/look_and_feel/editor/editors/feed_editor/FeedAppearanceEditorGroup';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import {
  BRANDING_CATEGORY_KEY,
  CAROUSEL_TYPE,
  EMBED_CAROUSEL_CATEGORY_KEY,
  FEED_CATEGORY_KEY,
  GIF_CATEGORY_KEY,
  HERO_CATEGORY_KEY,
  LANDING_PAGE_CATEGORY_KEY,
  LEAD_FORM_CATEGORY_KEY,
  PLAYER_CATEGORY_KEY,
  PRODUCT_DETAILS_CATEGORY_KEY,
  STORIES_CATEGORY_KEY,
  TILE_CATEGORY_KEY,
  VIDEO_PLAYER_CATEGORY_KEY,
  WIDGET_SETTINGS_CATEGORY_KEY,
  HERO_OVERLAY_CATEGORY_KEY,
} from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import FeedCarouselEditorGroup from 'app/src/pages/project/pages/look_and_feel/editor/editors/feed_carousel_editor/FeedCarouselEditorGroup';
import {
  FEATURE_WIDGET_CAROUSEL_SETTINGS,
  FEATURE_WIDGET_STORIES_SETTINGS,
  FEATURE_DESCRIPTION_CLOSE_FEED_PDP,
  FEATURE_APP_USE_CREATOR_PROFILE,
} from 'shared/react/constants/features.constants';
import { useFeatures } from 'app/src/context/FeaturesStore';
import StoriesSettingsIcon from 'shared/react/images/look_and_feel/StoriesSettingsIcon';
import FeedStoriesEditorGroup from 'app/src/pages/project/pages/look_and_feel/editor/editors/feed_stories_editor/FeedStoriesEditorGroup';
import { useProjects } from 'app/src/context/ProjectsStore';
import BrandingEditorGroup from './editors/branding_editor/BrandingEditorGroup';
import ProductDetailsEditorGroup from './editors/product_details_editor/ProductDetailsEditorGroup';
import ColorPalleteIcon from 'app/src/images/branding_editor_icons/ColorPalleteIcon';
import VideoPlayerIcon from 'shared/react/images/look_and_feel/VideoPlayerIcon';
import FeedVideoPlayerEditorGroup from 'app/src/pages/project/pages/look_and_feel/editor/editors/feed-video-player/FeedVideoPlayerEditorGroup';
import { publishMethodOptions } from 'app/src/types/entities';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import HeroEditor from 'app/src/pages/project/pages/look_and_feel/editor/editors/hero_editor/HeroEditor';
import TileEditorGroup from './editors/tile_editor/TileEditorGroup';
import GifEditor from 'app/src/pages/project/pages/look_and_feel/editor/editors/gif_editor/GifEditor';
import GifIcon from 'app/src/images/GifIcon';
import HeroOverlayEditorGroup from 'app/src/pages/project/pages/look_and_feel/editor/editors/hero-overlay-editor/HeroOverlayEditorGroup';
import Branding from './editors/video-collection-campaign/components/Branding';
import Offer from './editors/video-collection-campaign/components/Offer';
import Examples from './editors/video-collection-campaign/components/Examples';
import HowTo from './editors/video-collection-campaign/components/HowTo';

function LookAndFeelEditor() {
  const [, { getFeatureEnabled }] = useFeatures();
  const {
    categories,
    selectedCategory,
    setSelectedCategory,
    customizationSettings: {
      feed,
      widgetSettings: { isFullscreenFeed, carouselType },
    },
    setEditorRightLocation,
    isSaving,
    isEditingBubblePosition,
  } = useLookAndFeelContext();
  const layoutRootRef = useRef();
  const { projectId } = useParams();
  const [{ project }] = useProjects({ projectId });
  const isFeedCarouselEnabled = getFeatureEnabled(FEATURE_WIDGET_CAROUSEL_SETTINGS);
  const isFeedStoriesEnabled = getFeatureEnabled(FEATURE_WIDGET_STORIES_SETTINGS);
  const isOpenPdpOnFeedProductClickEnabled = getFeatureEnabled(FEATURE_DESCRIPTION_CLOSE_FEED_PDP);
  const isUseCreatorProfileEnabled = getFeatureEnabled(FEATURE_APP_USE_CREATOR_PROFILE);
  const publishMethod = project?.publishMethod;
  const isHero = publishMethod === publishMethodOptions.hero;
  const isEmail = project.publishMethod === publishMethodOptions.email;

  const hideStories =
    !feed ||
    !isFeedStoriesEnabled ||
    (publishMethod && publishMethod !== publishMethodOptions.stories);
  const hideCarousel =
    !feed ||
    !isFeedCarouselEnabled ||
    (publishMethod && publishMethod !== publishMethodOptions.carousel);
  const hideLandingPage =
    feed || (publishMethod && publishMethod !== publishMethodOptions.landingPage);

  const isTvChannel = project.publishMethod === publishMethodOptions.tvChannel;
  const isDiscover = !!project.discover;
  const isSpotlightCarousel = carouselType === CAROUSEL_TYPE.SPOTLIGHT;
  const isTile = publishMethod === publishMethodOptions.tile;
  const isVideoCollectionCampaign = publishMethod === publishMethodOptions.videoCollectionCampaign;
  const shouldHideProductDetails =
    isHero || isOpenPdpOnFeedProductClickEnabled || isDiscover || isSpotlightCarousel || isTile;

  const getFeedHiddenItems = () => {
    const hiddenItems = [];

    if (publishMethod === publishMethodOptions.videoPage || isOpenPdpOnFeedProductClickEnabled) {
      hiddenItems.push('view-feed-in-fullscreen');
    }

    if (
      ![publishMethodOptions.stories, publishMethodOptions.carousel].includes(publishMethod) ||
      isFullscreenFeed
    ) {
      hiddenItems.push('feed-player-position');
    }

    if (!isUseCreatorProfileEnabled) {
      hiddenItems.push('show-creator-profile-link');
    }

    return hiddenItems;
  };

  const videoCollectionCampaignCategories = [
    'brandingEditor',
    'offerEditor',
    'examplesEditor',
    'howToEditor',
  ];

  const videoCollectionCampaignEditors = {
    brandingEditor: {
      title: 'Branding',
      icon: <ColorPalleteIcon />,
      content: <Branding />,
      isExpanded: true,
    },
    offerEditor: {
      title: 'Offer',
      icon: <LandingPageIcon />,
      content: <Offer />,
    },
    examplesEditor: {
      title: 'Examples',
      icon: <FeedIcon />,
      content: <Examples />,
    },
    howToEditor: {
      title: 'How to',
      icon: <LandingPageIcon />,
      content: <HowTo />,
    },
  };
  const editors = {
    [GIF_CATEGORY_KEY]: {
      title: 'GIF',
      icon: <GifIcon />,
      content: <GifEditor />,
      hidden: !isEmail,
    },
    [VIDEO_PLAYER_CATEGORY_KEY]: {
      title: 'Video Player',
      icon: <VideoPlayerIcon />,
      content: <FeedVideoPlayerEditorGroup />,
      hidden: !feed || true || isHero,
    },
    [FEED_CATEGORY_KEY]: {
      title: 'Video feed',
      icon: <FeedIcon />,
      content: <FeedAppearanceEditorGroup hiddenItems={getFeedHiddenItems()} />,
      hidden: (!feed && !isTvChannel) || isHero || isSpotlightCarousel || isTile,
    },
    [PRODUCT_DETAILS_CATEGORY_KEY]: {
      title: 'Product Card',
      icon: <ProductDetailsIcon />,
      content: <ProductDetailsEditorGroup />,
      hidden: shouldHideProductDetails,
    },
    [STORIES_CATEGORY_KEY]: {
      title: 'Widget',
      icon: <StoriesSettingsIcon />,
      content: <FeedStoriesEditorGroup />,
      hidden: isHero || hideStories,
    },
    [EMBED_CAROUSEL_CATEGORY_KEY]: {
      title: 'Widget',
      icon: <StoriesSettingsIcon />,
      content: <FeedCarouselEditorGroup />,
      hidden: isHero || hideCarousel,
    },
    [TILE_CATEGORY_KEY]: {
      title: 'Widget',
      icon: <StoriesSettingsIcon />,
      content: <TileEditorGroup />,
      hidden: !isTile,
    },
    [PLAYER_CATEGORY_KEY]: {
      title: 'Player',
      icon: <FeedIcon />,
      content: <PlayerEditorGroup />,
      hidden: isHero || !!feed || isTvChannel,
    },
    [WIDGET_SETTINGS_CATEGORY_KEY]: {
      title: 'Widget',
      icon: <StoriesSettingsIcon />,
      content: <WidgetSettingsEditorGroup />,
      hidden:
        isHero ||
        (publishMethod &&
          ![publishMethodOptions.bubble, publishMethodOptions.unassigned].includes(publishMethod)),
    },
    [LANDING_PAGE_CATEGORY_KEY]: {
      title: 'Landing page',
      icon: <LandingPageIcon />,
      content: <LandingPageEditorGroup />,
      hidden: hideLandingPage || isHero,
    },
    [LEAD_FORM_CATEGORY_KEY]: {
      title: 'Contact form',
      icon: <LeadFormIcon />,
      content: <LeadFormEditorGroup />,
      hidden: !!feed || isTvChannel || isHero,
    },
    [HERO_CATEGORY_KEY]: {
      title: 'Hero',
      icon: <StoriesSettingsIcon />,
      content: <HeroEditor />,
      hidden: !isHero,
    },
    [BRANDING_CATEGORY_KEY]: {
      title: 'Branding',
      icon: <ColorPalleteIcon />,
      content: <BrandingEditorGroup />,
      hidden: false,
    },
    [HERO_OVERLAY_CATEGORY_KEY]: {
      title: 'Text & Buttons',
      icon: <StoriesSettingsIcon />,
      content: <HeroOverlayEditorGroup />,
      hidden: !isHero,
    },
    ...videoCollectionCampaignEditors,
  };

  useEffect(() => {
    const { right } = layoutRootRef?.current?.getBoundingClientRect() || {};
    if (right) {
      setEditorRightLocation(right);
    }
  }, [layoutRootRef]);

  return (
    <LayoutRoot ref={layoutRootRef}>
      {(isVideoCollectionCampaign ? videoCollectionCampaignCategories : categories)
        .filter(category => !editors[category].hidden)
        .map(category => {
          const { title, icon, content, isExpanded } = editors[category];
          return (
            <CustomExpander
              key={title}
              title={title}
              showShadow={false}
              icon={icon}
              showHeaderBottomBorder
              isExpanded={
                Utils.isNullOrUndefined(selectedCategory)
                  ? isExpanded
                  : category === selectedCategory
              }
              setExpanded={isExpanded => setSelectedCategory(isExpanded ? category : '')}
              data-test-id={category}
              shouldGray={isEditingBubblePosition || isSaving}
            >
              {content}
            </CustomExpander>
          );
        })}
    </LayoutRoot>
  );
}

export default LookAndFeelEditor;

const LayoutRoot = styled(Gap16VerticalFlex)`
  min-width: 387px;
  max-width: 387px;
  margin: 0px 0 24px;
  height: 100%;
  overflow-y: auto;
`;

const CustomExpander = styled(Expander)`
  background: ${({ theme, shouldGray }) => shouldGray && theme.colors.gray1};
  filter: ${({ shouldGray }) => shouldGray && 'grayscale(1)'};
`;
