import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import MicIcon from '../../../images/MicIcon';
import NotWorkingMicIcon from '../../../images/NotWorkingMicIcon';

const BARS_LENGTH = 10;
let notWorkingTimeout = null;
const AudioTest = ({ stream, setMicNotWorking, micNotWorking }) => {
  const [coloredBars, setColoredBars] = useState(0);
  const volumeInterval = useRef(null);

  const colorBars = vol => {
    const numberOfBarsToColor = Math.round(vol / 1.7);
    setColoredBars(Math.round(numberOfBarsToColor / BARS_LENGTH));

    if (notWorkingTimeout && numberOfBarsToColor) {
      clearTimeout(notWorkingTimeout);
      notWorkingTimeout = null;
    }

    if (!numberOfBarsToColor && !notWorkingTimeout && !micNotWorking) {
      notWorkingTimeout = setTimeout(() => {
        setMicNotWorking(true);
      }, 1000);
    }

    if (micNotWorking && !notWorkingTimeout) {
      setMicNotWorking(false);
    }
  };

  const initAudioBars = mediaStream => {
    const audioContext = new AudioContext();
    const audioSource = audioContext.createMediaStreamSource(mediaStream);
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 512;
    analyser.minDecibels = -127;
    analyser.maxDecibels = 0;
    analyser.smoothingTimeConstant = 0.4;
    audioSource.connect(analyser);
    const volumes = new Uint8Array(analyser.frequencyBinCount);
    return () => {
      analyser.getByteFrequencyData(volumes);
      let volumeSum = 0;
      for (const volume of volumes) {
        volumeSum += volume;
      }
      const averageVolume = volumeSum / volumes.length;
      colorBars(averageVolume);
    };
  };

  const startAudio = async () => {
    try {
      const volumeCallback = initAudioBars(stream);
      volumeInterval.current = setInterval(volumeCallback, 100);
    } catch (err) {
      console.log(err);
    }
  };

  const getBars = () => {
    const bars = new Array(10).fill('');
    return bars.map((_, i) => {
      return <Bar key={i} marked={coloredBars >= i} />;
    });
  };

  useEffect(() => {
    if (!stream) {
      return;
    }

    startAudio();

    return () => {
      clearInterval(volumeInterval.current);
    };
  }, [stream]);

  if (!stream) {
    return <MicIcon />;
  }

  if (micNotWorking) {
    return (
      <>
        <NotWorkingMicIcon />
        <NotWorkingText>No sound</NotWorkingText>
      </>
    );
  }

  return (
    <>
      <MicIcon />
      <BarContainer>{getBars()}</BarContainer>
    </>
  );
};

const BarContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const Bar = styled.div`
  background: ${({ theme, marked }) => (marked ? theme.colors.white : theme.colors.gray4)};
  width: 1px;
  height: 14px;
`;

const NotWorkingText = styled.div`
  color: ${({ theme }) => theme.colors.danger};
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
`;

export default AudioTest;
