import React from 'react';
import styled from 'styled-components';
import { useLeadFormEditorGroupContext } from 'app/src/pages/project/pages/look_and_feel/editor/editors/lead_form_editor/LeadFormEditorGroupContext';
import CollectField from 'app/src/pages/project/pages/look_and_feel/editor/editors/lead_form_editor/CollectField';
import Utils from 'app/src/utils';
import { COLLECT_PROPERTY_KEY } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import EditorHeader from '../shared/EditorHeader';
import EditorMainContainer from '../shared/EditorMainContainer';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const DefaultFieldsEditor = () => {
  const { leadFormSettings, handleCollectFieldPropertyChanged } = useLeadFormEditorGroupContext();

  const { predefinedFields } = leadFormSettings;

  return (
    <LayoutRoot>
      <EditorHeader title="Data collection" />
      <EditorMainContainer>
        {predefinedFields.map(({ name, key, collect, required }) => (
          <CollectField
            key={key}
            id={key}
            text={Utils.capitalizeFirstLetter(name)}
            required={required}
            checked={collect}
            onChange={value => handleCollectFieldPropertyChanged(key, COLLECT_PROPERTY_KEY, value)}
          />
        ))}
      </EditorMainContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

export default DefaultFieldsEditor;
