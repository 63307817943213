import { getIsAspectRatioSupported } from 'widget/src/utils/css.utils';
import { getNextNonImageIndex } from 'widget/src/utils/assets.utils.js';
import { getEmbedProductId } from 'widget/src/utils/script.utils.js';
import { setImpressionByPublishId } from '../../../common/analytics.js';
import { reCreateResolutions } from '../../../common/reCreateResolutions.js';
import { getShop } from '../../../common/shop.js';
import { EVENT_NAMES } from '../../../constants/events.constants.js';
import {
  JPG_EXTENSION,
  WEBP_AVATAR_EXTENSION,
  WEBP_EXTENSION
} from '../../../constants/extensions.js';
import { FEATURE_CAROUSEL_SHOW_DOTS } from '../../../constants/features.constants.js';
import { WIDGETS_MESSAGES, WIDGET_MESSAGES_EVENTS } from '../../../constants/messages.constants.js';
import {
  CAROUSEL_EMBED_WIDGET,
  DOM_EXCEPTION_ERRORS,
  IN_TILE_CAROUSEL_EMBED_WIDGET
} from '../../../constants/widgetConstants.js';
import { safePlayVideo } from '../../../utils/dom.utils.js';
import { getFeatureIsEnabled, getIsPlayerLazy } from '../../../utils/feature.utils.js';
import { getIsIOS, logDevelopmentError } from '../../../utils/utils.js';
import Modal from '../../Modal/Modal.js';
import { ASPECT_RATIO_9_16 } from '../Embed/embedConstants.js';
import {
  getConfig,
  getEmbedPublishId,
  getPublicId,
  onViewPortChangeHandler,
  reloadFontIfNeeded,
  stopAllVideos,
  stopDynamicVideos,
  shouldForceMute
} from '../embedWidgets.utils.js';
import style from './carousel.module.css';
import { getBaseUrl, setVideoSourceOnEnd } from './carousel.utils.js';
import {
  DEFAULT_DOT_OPACITY,
  DEFAULT_TILE_HEIGHT,
  EMBED_CAROUSEL_MOTION,
  MAX_TILE_NUMBER,
  MIN_TILE_NUMBER,
  MIN_WIDTH_FOR_TILE,
  PUBLIC_CLASSES,
  SPACING_HORIZONTAL
} from './carouselConstants.js';
import getHTML from './carouselHtml.js';

/**
 * @param {string} attribute
 * @return {undefined|boolean|null}
 */
function parseBool(attribute) {
  switch (attribute) {
    case 'undefined':
      return undefined;
    case 'null':
      return null;
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return attribute;
  }
}

class Carousel {
  constructor() {
    this.start = this.start.bind(this);
    this.init = this.init.bind(this);
    this.onDrag = this.onDrag.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onDragStop = this.onDragStop.bind(this);
    this.handlePageView = this.handlePageView.bind(this);
    this.handleView = this.handleView.bind(this);
    this.handleDynamicVideos = this.handleDynamicVideos.bind(this);
    this.onViewPortChange = this.onViewPortChange.bind(this);
    this.onVideoEnd = this.onVideoEnd.bind(this);
    this.stopDynamicVideos = this.stopDynamicVideos.bind(this);
    this.setIsDynamicVideoRunning = this.setIsDynamicVideoRunning.bind(this);
    this.modalMessageHandler = this.modalMessageHandler.bind(this);
    this.tilesNumber = 0;
    this.selectedDot = 0;
    this.scrollX = 0;
    this.timeout = null;
    this.dotsNumber = 0;
    this.isDynamicVideoRunning = false;
    this.dynamicVideoIndex = 0;
    this.isPlayInTileFirst = false;

    /**
     * Indicates if the carousel must be muted
     * @type {boolean}
     */
    this.isMuted = false;
    this.isCreateResolutionCalled = false;
    this.tileHeight = DEFAULT_TILE_HEIGHT;
  }

  get isScrollAvailable() {
    return this.data.carouselEmbed.carouselSteps.length - this.tilesNumber > 0;
  }

  getElement(type) {
    return window.document.querySelector(
      `[data-tolstoy-element="${getPublicId(type, this.data.publishId)}"]`
    );
  }

  getAllElements(type) {
    return window.document.querySelectorAll(
      `[data-tolstoy-element="${getPublicId(type, this.data.publishId)}"]`
    );
  }

  setTileWidthByVideosContainer(videosContainer) {
    const {
      carouselSpacingHorizontal = SPACING_HORIZONTAL,
      carouselItemSizeType = 'responsive',
      carouselItemHeight = DEFAULT_TILE_HEIGHT
    } = this.data.carouselEmbed;
    const stepsLength = this.data.carouselEmbed.carouselSteps.length;
    let tilesNumber = this.tilesNumber;

    if (videosContainer.clientWidth <= 450 && stepsLength >= 2) {
      tilesNumber += 0.4;
    }

    const parentWidth = videosContainer.clientWidth;

    let calculatedTileWidth;
    if (carouselItemSizeType === 'responsive') {
      calculatedTileWidth =
        (parentWidth + carouselSpacingHorizontal) / tilesNumber - carouselSpacingHorizontal;
      this.tileHeight = calculatedTileWidth / ASPECT_RATIO_9_16;
    } else {
      this.tileHeight = carouselItemHeight;
      calculatedTileWidth = carouselItemHeight * ASPECT_RATIO_9_16;
    }

    const gridColumnString = `${Math.max(calculatedTileWidth, MIN_WIDTH_FOR_TILE)}px`;

    videosContainer.style.gridTemplateColumns = `repeat(${stepsLength}, ${gridColumnString})`;
    videosContainer.style.justifyContent = this.isScrollAvailable ? 'start' : 'center';

    if (!getIsAspectRatioSupported()) {
      videosContainer.style.height = `${this.tileHeight}px`;
    }
  }

  setTileWidth() {
    const videosContainers = this.getAllElements(PUBLIC_CLASSES.videosContainer);

    for (const videosContainer of videosContainers) {
      this.setTileWidthByVideosContainer(videosContainer);
    }
  }

  reinitVideos() {
    const videos = this.getAllElements(PUBLIC_CLASSES.video);

    for (const [index, video] of videos.entries()) {
      if (!video.src) {
        continue;
      }

      const step = this.data.carouselEmbed.carouselSteps[index];

      const videoSource = getBaseUrl({ step, height: this.tileHeight });
      if (video.src === videoSource) {
        continue;
      }

      if (index === this.dynamicVideoIndex) {
        setVideoSourceOnEnd(video, videoSource);
        continue;
      }

      // If autoplay is true, we set it as an empty string to avoid auto playing all videos, it will be later set in handleDynamicVideos
      if (video.autoplay) {
        video.src = '';
        continue;
      }

      video.src = videoSource;
    }
  }

  getNumberOfTiles() {
    const {
      carouselItemSizeType = 'responsive',
      carouselItemsPerRow = 5,
      carouselItemHeight = DEFAULT_TILE_HEIGHT,
      carouselSpacingHorizontal = SPACING_HORIZONTAL,
      carouselSteps
    } = this.data.carouselEmbed;
    const videosContainerElement = this.getElement(PUBLIC_CLASSES.videosContainer);
    const videosContainerWidth = videosContainerElement.clientWidth;
    const videoContainer = this.getElement(PUBLIC_CLASSES.videoContainer);
    const videoContainerWidth = videoContainer?.clientWidth;

    if (!videosContainerElement) {
      return;
    }

    if (videoContainerWidth && videoContainerWidth > videosContainerWidth) {
      this.tilesNumber = 1;
      return;
    }

    const itemWidth = carouselItemHeight * ASPECT_RATIO_9_16;
    const amount = Math.floor(
      (videosContainerWidth + carouselSpacingHorizontal) / (itemWidth + carouselSpacingHorizontal)
    );

    if (videosContainerWidth <= 450 && amount < carouselSteps?.length) {
      if (amount < MIN_TILE_NUMBER) {
        this.tilesNumber = Math.max(amount, 1);
        return;
      }

      this.tilesNumber = MIN_TILE_NUMBER;
      return;
    }

    if (carouselItemSizeType === 'responsive') {
      this.tilesNumber = carouselItemsPerRow;
      return;
    }

    this.tilesNumber = Math.max(Math.min(amount, MAX_TILE_NUMBER), MIN_TILE_NUMBER);
  }

  onVideoEnd() {
    if (this.data.loopFirstVideo) {
      return this.handleDynamicVideos();
    }

    const videoPlayingIndex = this.dynamicVideoIndex;
    const stepsLength = this.data.carouselEmbed.carouselSteps.length;

    if (videoPlayingIndex + 1 >= this.tilesNumber - 1 && videoPlayingIndex < stepsLength) {
      this.onDotClick(videoPlayingIndex + 2 - this.tilesNumber);
    }

    this.dynamicVideoIndex = getNextNonImageIndex(
      this.data.carouselEmbed.carouselSteps,
      this.dynamicVideoIndex
    );

    this.handleDynamicVideos();
  }

  handleDynamicVideos() {
    const videoPlayingIndex = this.dynamicVideoIndex;
    const videos = this.getAllElements(PUBLIC_CLASSES.video);
    const video = videos[videoPlayingIndex];

    if (!videos?.length) {
      return;
    }

    const onVideoError = error => {
      if (error.name !== DOM_EXCEPTION_ERRORS.notAllowedError) {
        throw error;
      }

      if (videoPlayingIndex !== 0) {
        return;
      }

      if (getIsIOS()) {
        this.modal.loadFullPlayer();
      }
    };

    if (!video) {
      this.dynamicVideoIndex = 0;
      this.onDotClick(0);
      this.handleDynamicVideos();
      return;
    }

    if (!video.src) {
      const step = this.data.carouselEmbed.carouselSteps[videoPlayingIndex];
      video.src = getBaseUrl({ step, height: this.tileHeight });

      if (getIsIOS()) {
        try {
          video.autoplay = 'true';
        } catch (error) {
          onVideoError(error);
        }

        video.addEventListener('ended', this.onVideoEnd, { once: true });
        return;
      }

      video.addEventListener('canplay', () => safePlayVideo({ video, onError: onVideoError }), {
        once: true
      });
    }

    safePlayVideo({ video, onError: onVideoError });

    video.addEventListener('ended', this.onVideoEnd, { once: true });
  }

  handleHoverOverVideos() {
    const videos = this.getAllElements(PUBLIC_CLASSES.videoContainer);

    for (const video of videos) {
      video.addEventListener('mouseenter', event => {
        event.target.children[0].play();
        event.target.children[0].loop = true;
      });

      video.addEventListener('mouseleave', event => {
        event.target.children[0].loop = false;
        event.target.children[0].pause();
        event.target.children[0].currentTime = 0;
      });
    }
  }

  handleCarouselMotion(motion) {
    if (this.isPlayInTileFirst) {
      return;
    }

    if (motion === EMBED_CAROUSEL_MOTION.dynamic) {
      this.handleDynamicEvents();
    }

    if (motion === EMBED_CAROUSEL_MOTION.hoverOver) {
      this.handleHoverOverVideos();
    }
  }

  initDots() {
    const dotsNumber = this.data.carouselEmbed.carouselSteps.length - this.tilesNumber;
    this.dotsNumber = dotsNumber ? dotsNumber + 1 : 0;
  }

  renderDots() {
    const dotsNumber = this.data.carouselEmbed.carouselSteps.length - this.tilesNumber;
    const container = this.getElement(PUBLIC_CLASSES.dotsContainer);

    if (!container) {
      return;
    }

    if (!dotsNumber) {
      container.innerHTML = '';
      return;
    }

    let html = '';
    for (let index = 0; index <= dotsNumber; index++) {
      html += `<div
        ${this.selectedDot === index ? "style = 'opacity: 1'" : ''}
        class="${style.dot} ${PUBLIC_CLASSES.dot}"
        data-tolstoy-element="${getPublicId(PUBLIC_CLASSES.dot, this.data.publishId)}"
      ></div>`;
    }

    container.innerHTML = html;
  }

  addExpandButtonOnClick() {
    const containers = this.getAllElements(PUBLIC_CLASSES.expandButton);

    for (const [index, container] of containers.entries()) {
      if (container.getAttribute('hasClickEvent')) {
        continue;
      }

      container.addEventListener('click', event => {
        clearTimeout(this.timeout);
        event.stopPropagation();

        stopAllVideos({
          videoClass: PUBLIC_CLASSES.video,
          publishId: this.data.publishId
        });
        this.sendInTileCarouselEvent({ eventName: EVENT_NAMES.embedExpand });
        this.start(index);
      });

      container.setAttribute('hasClickEvent', 'true');
    }
  }

  addMuteButtonOnClick() {
    const isFeedMuted = this.data.design?.player?.controls?.isFeedMuted;

    // because of video-level mute setting number of muteButtons can be less than videos. DO NOT USE [index] to associate mute button and video
    const muteButtons = this.getAllElements(PUBLIC_CLASSES.muteButton);

    /** @type {Array<HTMLVideoElement>} */
    const videos = Array.from(this.getAllElements(PUBLIC_CLASSES.video).values());

    for (const muteButton of muteButtons.values()) {
      const video = videos.find(
        video => video.dataset.tolstoyVideoId === muteButton.dataset.tolstoyVideoId
      );
      if (muteButton.getAttribute('hasClickEvent')) {
        continue;
      }

      muteButton.addEventListener('click', event => {
        event.stopPropagation();

        if (this.isMuted) {
          this.sendInTileCarouselEvent({ eventName: EVENT_NAMES.embedUnmute });
        } else {
          this.sendInTileCarouselEvent({ eventName: EVENT_NAMES.embedMute });
        }

        this.isMuted = !this.isMuted;

        const isSoundAllowed = parseBool(video.dataset.tolstoyIsSoundAllowed);
        video.muted = this.isMuted || shouldForceMute(isFeedMuted, isSoundAllowed);

        const videosContainer = this.getElement(PUBLIC_CLASSES.videosContainer);

        videosContainer.dataset.carouselMuted = String(this.isMuted);
      });

      muteButton.setAttribute('hasClickEvent', 'true');
    }
  }

  handlePlayInTileEventListener(container, index) {
    const playButton = this.getAllElements(PUBLIC_CLASSES.playButtonContainer)[index];
    const controlsContainer = this.getAllElements(PUBLIC_CLASSES.controlsContainer)[index];

    if (container.getAttribute('isPlayingInTile')) {
      playButton.style.setProperty('display', 'flex');
      controlsContainer.style.setProperty('display', 'none');
      container.removeAttribute('isPlayingInTile');
      return;
    }

    playButton.style.setProperty('display', 'none');
    controlsContainer.style.setProperty('display', 'flex');
    container.setAttribute('isPlayingInTile', true);
  }

  sendInTileCarouselEvent({ eventName, params = {} }) {
    this.modal.sendEvent(eventName, { playerVariant: IN_TILE_CAROUSEL_EMBED_WIDGET, ...params });
  }

  handlePlayInTileFirst(index, container) {
    const isFeedMuted = this.data.design?.player?.controls?.isFeedMuted;
    const videos = this.getAllElements(PUBLIC_CLASSES.video);
    const video = videos[index];
    const step = this.data.carouselEmbed.carouselSteps[index];

    if (!video.src) {
      video.src = getBaseUrl({ step, isPlayInTileFirst: true, height: this.tileHeight });
      video.addEventListener('canplay', video.play, { once: true });
      video.addEventListener('play', () => this.handlePlayInTileEventListener(container, index));
      video.addEventListener('pause', () => this.handlePlayInTileEventListener(container, index));
    }

    if (container.getAttribute('isPlayingInTile')) {
      this.sendInTileCarouselEvent({ eventName: EVENT_NAMES.embedPause });
      return video.pause();
    }

    this.sendInTileCarouselEvent({ eventName: EVENT_NAMES.embedPlay });
    stopAllVideos({
      videoClass: PUBLIC_CLASSES.video,
      publishId: this.data.publishId
    });

    video.muted = this.isMuted || shouldForceMute(isFeedMuted, step.isSoundAllowed);
    video.play();
  }

  addOnVideoClickEvents() {
    const containers = this.getAllElements(PUBLIC_CLASSES.videoContainer);

    for (const [index, container] of containers.entries()) {
      if (container.getAttribute('hasClickEvent')) {
        continue;
      }

      container?.addEventListener('keypress', event => {
        if (event.code === 'Space' || event.code === 'Enter') {
          this.start(index);
        }
      });

      container.addEventListener('click', () => {
        clearTimeout(this.timeout);

        if (this.isPlayInTileFirst) {
          return this.handlePlayInTileFirst(index, container);
        }

        this.start(index);
      });
      container.setAttribute('hasClickEvent', 'true');
    }
  }

  addOnDotClickEvent() {
    const dots = this.getAllElements(PUBLIC_CLASSES.dot);

    for (const removeListener of this.removeDotEventListenerArray || []) {
      removeListener();
    }

    this.removeDotEventListenerArray = [];

    for (const [index, dot] of dots.entries()) {
      const onClick = () => this.onDotClick(index);
      dot.addEventListener('click', onClick);

      this.removeDotEventListenerArray = [
        ...this.removeDotEventListenerArray,
        () => dot.removeEventListener('click', onClick)
      ];
    }
  }

  getPreviousNextDisplaySettings() {
    const isMobileScreen = window.innerWidth <= 450;
    if (isMobileScreen) {
      return 'none';
    }

    if (this.isScrollAvailable) {
      return 'flex';
    }

    return 'none';
  }

  addPreviousNextElements() {
    const previousButton = this.getElement(PUBLIC_CLASSES.previousButton);
    const nextButton = this.getElement(PUBLIC_CLASSES.nextButton);
    const display = this.getPreviousNextDisplaySettings();
    previousButton.style.display = display;
    nextButton.style.display = display;
  }

  paintSelectedDot(index) {
    const dots = this.getAllElements(PUBLIC_CLASSES.dot);

    for (const [dotIndex, dot] of dots.entries()) {
      if (dotIndex === index) {
        dot.style.opacity = 1;
        continue;
      }

      dot.style.opacity = DEFAULT_DOT_OPACITY;
    }
  }

  onDotClick(index) {
    const container = this.getElement(PUBLIC_CLASSES.videosContainer);
    if (!container) {
      return;
    }

    this.paintSelectedDot(index);
    this.selectedDot = index;

    let childWidth = this.getVideoWidth() * index;

    if (index === 0) {
      childWidth = 0;
    }

    container.scrollTo({ left: childWidth, behavior: 'smooth' });
    this.scrollX = childWidth;
  }

  initTiles() {
    const videosContainerElement = this.getElement(PUBLIC_CLASSES.videosContainer);
    if (!videosContainerElement) {
      return;
    }

    this.getNumberOfTiles();
    this.setTileWidth();
    this.reinitVideos();
    this.initDots();
    this.addPreviousNextElements();
    if (getFeatureIsEnabled({ config: this.data, featureKey: FEATURE_CAROUSEL_SHOW_DOTS })) {
      this.renderDots();
      this.addOnDotClickEvent();
    }
  }

  setSizeOfElements() {
    const container = this.getElement(PUBLIC_CLASSES.carouselContainer);
    if (!container) {
      return;
    }

    container.style.maxWidth = `${window.innerWidth}px`;
  }

  addScreenListeners() {
    if (!('ResizeObserver' in window)) {
      return;
    }

    const container = this.getElement(PUBLIC_CLASSES.videosContainer);
    let containerWidth = container?.clientWidth;
    let observerCount = 0;
    const observer = new window.ResizeObserver(entries => {
      if (container?.clientWidth === containerWidth) {
        return;
      }

      containerWidth = container.clientWidth;

      if (observerCount >= 10) {
        observer.disconnect();
      }

      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || entries.length === 0) {
          return;
        }

        this.setSizeOfElements();
        this.initTiles();
        this.onDotClick(0);
        observerCount++;
      });
    });
    observer.observe(container);
  }

  addPreviousNextEvents() {
    const previousButton = this.getElement(PUBLIC_CLASSES.previousButton);
    const nextButton = this.getElement(PUBLIC_CLASSES.nextButton);

    nextButton.addEventListener('click', () => {
      if (this.selectedDot + 1 > this.dotsNumber - 1) {
        this.onDotClick(0);
        return;
      }

      this.onDotClick(this.selectedDot + 1);
    });

    previousButton.addEventListener('click', () => {
      if (this.selectedDot - 1 < 0) {
        this.onDotClick(this.dotsNumber - 1);
        return;
      }

      this.onDotClick(this.selectedDot - 1);
    });
  }

  changeVideoPointerEvents(value) {
    const containers = this.getAllElements(PUBLIC_CLASSES.videoContainer);

    for (const container of containers) {
      container.style.pointerEvents = value;
    }
  }

  onDragStart(event) {
    this.timeout = setTimeout(() => {
      this.startDragLocation = event.pageX || event.changedTouches[0].clientX;
      this.isDragging = true;
      this.changeVideoPointerEvents('none');
    }, 200);
  }

  getVideoWidth() {
    const { carouselSpacingHorizontal = SPACING_HORIZONTAL } = this.data.carouselEmbed;
    const videoContainer = this.getElement(PUBLIC_CLASSES.videoContainer);

    const containerWidth = videoContainer.getBoundingClientRect().width;
    return containerWidth + carouselSpacingHorizontal;
  }

  paintScrollDots(x) {
    const childWidth = this.getVideoWidth();
    let selectedDot = Math.round(x / childWidth);

    if (selectedDot < 0) {
      selectedDot = 0;
    }

    if (selectedDot >= this.dotsNumber) {
      selectedDot = this.dotsNumber - 1;
    }

    this.selectedDot = selectedDot;
    this.paintSelectedDot(selectedDot);
    return selectedDot;
  }

  onDragStop() {
    this.changeVideoPointerEvents('all');

    if (!this.isDragging) {
      clearTimeout(this.timeout);
      return;
    }

    this.isDragging = false;
    this.scrollX = this.draggedX;
    const selectedDot = this.paintScrollDots(this.draggedX);

    if (window.innerWidth > 450) {
      this.onDotClick(selectedDot);
    }
  }

  onDrag(event) {
    event.preventDefault();

    if (!this.isDragging) {
      return;
    }

    const container = this.getElement(PUBLIC_CLASSES.videosContainer);

    const x = event.pageX || event.changedTouches[0].clientX;
    let scrollX = this.scrollX + (this.startDragLocation - x);

    if (scrollX > container.scrollWidth - container.offsetWidth) {
      scrollX = container.scrollWidth - container.offsetWidth;
    }

    if (scrollX < 0) {
      scrollX = 0;
    }

    this.draggedX = scrollX;

    container.scrollTo({ left: scrollX });
  }

  addSlider() {
    const carousel = this.getElement(PUBLIC_CLASSES.videosContainer);
    carousel.addEventListener('mousedown', this.onDragStart);
    carousel.addEventListener('mousemove', this.onDrag);
    carousel.addEventListener('mouseleave', this.onDragStop);
    carousel.addEventListener('mouseup', this.onDragStop);

    carousel.addEventListener('scroll', event => {
      if (this.isDragging) {
        return;
      }

      this.scrollX = event.target.scrollLeft;
      this.paintScrollDots(event.target.scrollLeft);
    });
  }

  stopDynamicVideos() {
    stopDynamicVideos({
      videoClass: PUBLIC_CLASSES.video,
      onVideoEnd: this.onVideoEnd,
      publishId: this.data.publishId,
      setIsDynamicVideoRunning: this.setIsDynamicVideoRunning
    });
  }

  handleOpenEvent(data) {
    if (data.eventData?.stopDynamicEmbed) {
      this.stopDynamicVideos();
    }
  }

  setIsDynamicVideoRunning(value) {
    this.isDynamicVideoRunning = value;
  }

  onViewPortChange() {
    onViewPortChangeHandler({
      videoClass: PUBLIC_CLASSES.video,
      isDynamicVideoRunning: this.isDynamicVideoRunning,
      setIsDynamicVideoRunning: this.setIsDynamicVideoRunning,
      dynamicVideoHandler: this.handleDynamicVideos,
      publishId: this.data.publishId,
      onVideoEnd: this.onVideoEnd
    });
  }

  modalMessageHandler({ data }) {
    if (data.name !== WIDGETS_MESSAGES) {
      return;
    }

    const handler = {
      [WIDGET_MESSAGES_EVENTS.OPEN]: data => this.handleOpenEvent(data),
      [WIDGET_MESSAGES_EVENTS.CLOSE]: data => this.onViewPortChange(data)
    };

    const eventFunction = handler[data.type];
    eventFunction?.(data);
  }

  handleDynamicEvents() {
    this.onViewPortChange();
    window.addEventListener('load', this.onViewPortChange);
    window.addEventListener('scroll', this.onViewPortChange);
    window.addEventListener('message', this.modalMessageHandler);
  }

  initSkeleton(element, extraData) {
    const carouselEmbed = {
      carouselSteps: [{}, {}, {}, {}, {}],
      carouselBorderColor: null,
      carouselBorderWidth: null,
      carouselBorder: null,
      carouselSpacingHorizontal: SPACING_HORIZONTAL,
      carouselPlayButtonOpacity: 0.1,
      carouselBorderRadius: 8,
      carouselTitle: null,
      skeleton: true,
      carouselShape: '9/16',
      ...extraData,
      carouselMotion: 'static'
    };

    const data = { carouselEmbed, publishId: 'skeleton' };
    this.data = data;

    element.innerHTML = getHTML(data);
    this.addPreviousNextElements();
    this.addScreenListeners();
  }

  overrideDataByQueryParam(data) {
    const queryParams = new URLSearchParams(window.location.search);

    if (queryParams.get('tolstoyCarouselMotion') === EMBED_CAROUSEL_MOTION.dynamic) {
      data.carouselEmbed.carouselMotion = EMBED_CAROUSEL_MOTION.dynamic;
    }

    if (queryParams.get('tolstoyLoadAll')) {
      data.carouselEmbed.loadAll = true;
    }

    const numberVideos = Math.min(
      data.carouselEmbed.carouselSteps.length,
      Number(queryParams.get('tolstoyNumVideos'))
    );
    if (queryParams.get('tolstoyNumVideos')) {
      data.carouselEmbed.carouselSteps = data.carouselEmbed.carouselSteps.slice(0, numberVideos);
    }
  }

  addOnErrorEventToVideos() {
    const { publishId } = this.data;
    const videos = document.querySelectorAll(`.${PUBLIC_CLASSES.video}`);

    for (const video of videos) {
      video.addEventListener('error', async event => {
        const isNotMediaError = event.type === 'error' && event.target.error === null;

        if (isNotMediaError) {
          if (video.poster.includes(WEBP_AVATAR_EXTENSION)) {
            video.poster = video.poster.replace(WEBP_AVATAR_EXTENSION, WEBP_EXTENSION);
          } else if (video.poster.includes(WEBP_EXTENSION)) {
            video.poster = video.poster.replace(WEBP_EXTENSION, JPG_EXTENSION);
          }

          if (publishId && !this.isCreateResolutionCalled) {
            await reCreateResolutions(publishId);

            this.isCreateResolutionCalled = true;
          }
        }
      });
    }
  }

  async init(element) {
    const carouselId = getEmbedPublishId(element);
    const productId = getEmbedProductId(element);
    const appUrl = window.Shopify?.shop || getShop();
    const tags = element.dataset?.tags?.split(',')?.filter(Boolean) || '';

    if (!carouselId && !productId && !tags?.length) {
      this.initialized = false;
      return;
    }

    try {
      const data = await getConfig({
        publishId: carouselId,
        productId,
        widgetType: CAROUSEL_EMBED_WIDGET,
        tags,
        appUrl
      });

      if (!data || data.disabled) {
        this.initialized = false;
        element.innerHTML = '';
        return;
      }

      this.initElements(element, data, productId);
    } catch (error) {
      logDevelopmentError(error);
      this.initialized = false;
    }
  }

  initElements(element, data, productId) {
    try {
      const carouselData = data.carouselEmbed;

      this.overrideDataByQueryParam(data);
      this.isPlayInTileFirst = carouselData.carouselPlayInTileFirst;

      data.playerType = CAROUSEL_EMBED_WIDGET;
      data.productId = productId;
      data.loopFirstVideo = element?.dataset?.loopFirstVideo === 'true';

      if (getIsPlayerLazy(data)) {
        data.playerLazy = true;
      }

      this.data = data;
      if (this.data.design?.player?.controls?.isFeedMuted) {
        // mute the feed if global setting is enabled
        this.isMuted = true;
      }

      element.innerHTML = getHTML(data);

      this.dynamicVideoIndex = getNextNonImageIndex(this.data.carouselEmbed.carouselSteps);

      if (window.location.origin !== process.env.VITE_BASE_URL) {
        this.modal = new Modal(this.data);
      }

      this.initTiles();
      this.handleCarouselMotion(carouselData.carouselMotion);
      this.addScreenListeners();
      this.addOnVideoClickEvents(carouselData.carouselPlayInTileFirst);
      this.addPreviousNextEvents();
      this.addSlider();
      reloadFontIfNeeded(this.data);
      this.addOnErrorEventToVideos();
      setImpressionByPublishId(data.publishId, false);

      if (this.isPlayInTileFirst) {
        this.addExpandButtonOnClick();
        this.addMuteButtonOnClick();
      }

      if (window?.safari) {
        const stepsContainerElement = this.getElement(PUBLIC_CLASSES.videosContainer);
        stepsContainerElement.classList.remove(style.videosContainerSafariWorkaround);
      }

      this.initialized = true;
    } catch (error) {
      logDevelopmentError(error);
      this.initialized = false;
    }
  }

  getIsInitialized() {
    return this.initialized;
  }

  start(index) {
    this.modal?.open(index);
  }

  getEventParams() {
    const params = { currentPageProductId: this.data.productId };

    if (this.isPlayInTileFirst) {
      params.playerVariant = IN_TILE_CAROUSEL_EMBED_WIDGET;
    }

    return params;
  }

  handlePageView() {
    if (this.data.playerLazy) {
      this.modal.sendPageView(this.getEventParams());
    }
  }

  handleView() {
    setImpressionByPublishId(this.data.publishId, true);
    this.modal.sendEmbedView(this.getEventParams());
  }
}

export default Carousel;
