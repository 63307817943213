import React from 'react';
import { VideosPageVodAsset } from 'app/src/pages/dashboard/pages/videos/types/videos.types';
import {
  useGalleryAssetsCount,
  useImageAssetsCount,
  useTrendingVodAssetsCount,
  useVideoAssetsCount,
} from 'app/src/context/VideosStore';
import VideosSidebar, {
  VideosSidebarProps,
} from 'app/src/pages/dashboard/pages/videos/components/side-bar/VideosSidebar';

type Props = {
  videos: VideosPageVodAsset;
} & Omit<VideosSidebarProps, 'assetCounts'>;

export const VideosSidebarWithPrefetchedCounts = ({ videos, ...otherProps }: Props) => {
  const [videosCount] = useVideoAssetsCount(videos);
  const [imagesCount] = useImageAssetsCount(videos);
  const [galleriesCount] = useGalleryAssetsCount();
  const [trendingVideosCount] = useTrendingVodAssetsCount();

  const assetCounts = {
    videosCount,
    imagesCount,
    galleriesCount,
    trendingVideosCount,
  };

  return <VideosSidebar assetCounts={assetCounts} {...otherProps}></VideosSidebar>;
};
