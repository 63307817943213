import React from 'react';

const ImageIcon = ({ fill = '#7D8087' }) => {
  return (
    <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 .25H2C1.156.25.5.93.5 1.75v7.5c0 .844.656 1.5 1.5 1.5h9c.82 0 1.5-.656 1.5-1.5v-7.5c0-.82-.68-1.5-1.5-1.5Zm-7.875 1.5c.61 0 1.125.516 1.125 1.125C4.25 3.508 3.734 4 3.125 4A1.11 1.11 0 0 1 2 2.875c0-.61.492-1.125 1.125-1.125Zm7.828 7.313a.404.404 0 0 1-.328.187H2.422a.386.386 0 0 1-.352-.188c-.047-.14-.047-.28.047-.398l1.64-2.25a.366.366 0 0 1 .305-.164c.118 0 .211.07.282.164l.773 1.055 2.18-3.282A.374.374 0 0 1 7.625 4c.117 0 .234.07.305.188l3 4.5c.07.117.07.257.023.374Z"
        fill={fill}
      />
    </svg>
  );
};
export default ImageIcon;
