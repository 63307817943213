import React, { useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import Button from 'shared/react/components/complex/Button';
import Utils from '../../../../utils';

const ENTER_KEY = 13;

const LinkPopup = ({ onSave, onClose, defaultText }) => {
  const [displayText, setDisplayText] = useState(defaultText);
  const [link, setLink] = useState('');
  const [linkError, setLinkError] = useState(false);
  const popupRef = useRef();

  const theme = useTheme();

  const onSaveClick = () => {
    if (!Utils.isValidUrl(link)) {
      setLinkError(true);
      return;
    }
    onSave(displayText, link);
  };

  const onLinkChange = e => {
    setLinkError(false);
    setLink(e.target.value);
  };

  useEffect(() => {
    const onEnterKey = e => {
      if (!displayText || !link || e.which !== ENTER_KEY) {
        return;
      }
      onSaveClick();
    };

    popupRef?.current?.addEventListener('keydown', onEnterKey);

    return () => {
      popupRef?.current?.removeEventListener('keydown', onEnterKey);
    };
  }, [popupRef, link, displayText]);

  return (
    <LayoutRoot ref={popupRef}>
      <LinkPopupContent>
        <InputContainer>
          Text to display
          <BasicInput
            value={displayText}
            onChange={e => setDisplayText(e.target.value)}
            placeholder="Input text"
          />
        </InputContainer>
        <InputContainer>
          Link address
          <BasicInput
            placeholder="Input link"
            value={link}
            error={linkError}
            onChange={onLinkChange}
          />
          {linkError && <ErrorMessage>Link is not valid</ErrorMessage>}
        </InputContainer>
        <ButtonsContainer>
          <Button onClick={onClose} type={theme.types.Ghost}>
            Cancel
          </Button>
          <Button onClick={onSaveClick} disabled={!link || !displayText}>
            Save
          </Button>
        </ButtonsContainer>
      </LinkPopupContent>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  position: absolute;
  width: 368px;
  height: 234px;
  padding: 16px;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.white};
  bottom: -10px;
  bottom: 0;
  left: 24px;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 4%);
`;

const LinkPopupContent = styled.div`
  display: grid;
  gap: 16px;
`;

const InputContainer = styled.div`
  display: grid;
  gap: 4px;
  position: relative;
`;

const BasicInput = styled.input`
  outline: none;
  color: ${({ error, theme }) => (error ? theme.colors.red2 : theme.colors.gray2)};
  background: white;
  min-height: 40px;
  padding: 8px;
  border: 1px solid ${({ theme, error }) => (error ? theme.colors.danger : theme.colors.gray3)};
  border-radius: 8px;
  width: 336px;
  &::-webkit-input-placeholder {
    color: ${({ error, theme }) => (error ? theme.colors.red1 : theme.colors.gray4)};
  }
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1.0)};
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.danger};
  position: absolute;
  bottom: -25px;
`;

export default LinkPopup;
