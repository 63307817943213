import { TABLE_COLUMN_KEYS } from 'app/src/constants/cube.constants';
import React from 'react';
import { numberComparator } from '../../../../complex_components/analytics/analyticsTable.utils';
import AnalyticsTableCell from 'app/src/complex_components/analytics/AnalyticsTableCell';

export const getAudienceTableColumns = () => {
  return [
    {
      field: TABLE_COLUMN_KEYS.email,
      headerName: 'Email',
      flex: 1.3,
      renderCell: ({ value }) => {
        return <AnalyticsTableCell text={value} />;
      },
    },
    {
      field: TABLE_COLUMN_KEYS.createdAt,
      headerName: 'Last seen',
      flex: 1,
      valueGetter: ({ value }) =>
        new Date(value).toLocaleDateString('en-us', {
          weekday: 'long',
          month: 'short',
          day: 'numeric',
        }),
      renderCell: ({ value }) => {
        return <AnalyticsTableCell text={value} />;
      },
    },

    {
      field: TABLE_COLUMN_KEYS.numOfOrders,
      headerName: 'Total orders',
      flex: 1,
      renderCell: ({ value }) => {
        return <AnalyticsTableCell text={value} />;
      },
      valueGetter: ({ row }) => row.numOfOrders,
      sortComparator: numberComparator,
    },
    {
      field: TABLE_COLUMN_KEYS.numOfVideosWatched,
      headerName: 'Videos watched',
      flex: 1,
      renderCell: ({ value }) => {
        return <AnalyticsTableCell text={Number(value).toLocaleString()} />;
      },
      sortComparator: numberComparator,
    },
  ];
};
