import React from 'react';
import styled from 'styled-components';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

type Props = {
  storiesStoryNameEnabled: boolean;
  storiesStoryNameFontSize: number;
  storiesStoryNameColor: string;
  partName: string;
  size: number;
};

const DesignStoriesPreviewStoryName = ({
  storiesStoryNameEnabled,
  storiesStoryNameFontSize,
  storiesStoryNameColor,
  partName,
  size,
}: Props) => {
  if (!storiesStoryNameEnabled) {
    return null;
  }

  return (
    <LayoutRoot
      storiesStoryNameFontSize={storiesStoryNameFontSize / size}
      storiesStoryNameColor={storiesStoryNameColor}
    >
      <EllipsisTextOverflowWrapper>
        <div>{partName}</div>
      </EllipsisTextOverflowWrapper>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  font-size: ${({ storiesStoryNameFontSize }) => storiesStoryNameFontSize}px;
  color: ${({ storiesStoryNameColor }) => storiesStoryNameColor};
  align-items: center;
`;

export default DesignStoriesPreviewStoryName;
