import React from 'react';

const MarkAsUnread = () => {
  return (
    <svg width="18" height="16" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.3346 1.9987C17.3346 1.08203 16.5846 0.332031 15.668 0.332031H2.33464C1.41797 0.332031 0.667969 1.08203 0.667969 1.9987V11.9987C0.667969 12.9154 1.41797 13.6654 2.33464 13.6654H15.668C16.5846 13.6654 17.3346 12.9154 17.3346 11.9987V1.9987ZM15.668 1.9987L9.0013 6.16536L2.33464 1.9987H15.668ZM15.668 11.9987H2.33464V3.66536L9.0013 7.83203L15.668 3.66536V11.9987Z"
        fill="#C8C9C9"
      />
    </svg>
  );
};

export default MarkAsUnread;
