import { INTERNAL_EVENTS } from '../../constants/internalEvents.constants.js';
import { TOLSTOY_PLAYER_READY_MESSAGE } from '../../constants/messages.constants.js';
import InternalMessaging from '../../messaging/internal/InternalMessaging.js';
import { INTERNAL_MESSAGES_TO_SUBSCRIBE } from './constants/modalManager.constants.js';

const modalIframes = {};
const modalReadyStates = {};
const transmissionIdToModalId = {};
const modalMessagingReadyMessages = [];

class ModalManager {
  constructor() {
    if (ModalManager.instance) {
      // eslint-disable-next-line no-constructor-return
      return ModalManager.instance;
    }

    ModalManager.instance = this;
  }

  registerIframe({ modalId, modalIframe }) {
    modalIframes[modalId] = modalIframe;
  }

  postInternalMessage({ modalId, data }) {
    delete data.modalId;
    const transmissionId = InternalMessaging.postMessage(data);
    transmissionIdToModalId[transmissionId] = modalId;
  }

  handleModalMessagingReady({ modalId }) {
    modalReadyStates[modalId] = true;

    const iframe = modalIframes[modalId];

    for (const message of modalMessagingReadyMessages) {
      const { transmissionId } = message;
      const modalIdByTransmission = transmissionIdToModalId[transmissionId];
      const isGenericMessage = !modalIdByTransmission;
      const isMessageForCurrentModal = modalIdByTransmission === modalId;

      if (isGenericMessage || isMessageForCurrentModal) {
        this.postMessageToIframe({ iframe, message });
      }
    }
  }

  eventHandler = ({ data = {} }) => {
    const { modalId, eventName } = data;

    if (!modalId) {
      return;
    }

    switch (eventName) {
      case INTERNAL_EVENTS.addToCart:
      case INTERNAL_EVENTS.openRebuyCart:
      case INTERNAL_EVENTS.openKendoModal:
      case INTERNAL_EVENTS.getProductsMetafields:
      case INTERNAL_EVENTS.productCardClick:
      case INTERNAL_EVENTS.loginWithMultipassUrlRequest:
      case INTERNAL_EVENTS.cartItemQuantityChange:
      case INTERNAL_EVENTS.cartDataRequest:
      case INTERNAL_EVENTS.productRecommendationsRequest:
      case INTERNAL_EVENTS.requestProductsUpdate:
      case INTERNAL_EVENTS.moveToUrl:
      case INTERNAL_EVENTS.showFeedProductModal:
      case INTERNAL_EVENTS.reportModalOpen:
      case INTERNAL_EVENTS.reportModalClose:
      case TOLSTOY_PLAYER_READY_MESSAGE:
        return this.postInternalMessage({ modalId, data });
      case INTERNAL_EVENTS.modalMessagingReady:
        this.postInternalMessage({ modalId, data });
        return this.handleModalMessagingReady({ modalId });
      case INTERNAL_EVENTS.toggleFeedCloseButton:
        return this.postInternalMessage({ modalId, data });
      default:
        return null;
    }
  };

  internalEventHandler = ({ data }) => {
    this.postMessageToModal(data);
  };

  postMessageToIframe({ iframe, message }) {
    iframe.contentWindow?.postMessage(message, '*');
  }

  postMessageToAllIframes({ message }) {
    modalMessagingReadyMessages.push(message);

    for (const [modalId, iframe] of Object.entries(modalIframes)) {
      const isIframeReady = modalReadyStates[modalId];
      if (!isIframeReady) {
        continue;
      }

      this.postMessageToIframe({ iframe, message });
    }
  }

  postMessageToModal(data) {
    const { transmissionId, shouldEndTransmission = true, ...message } = data;

    const modalId = transmissionIdToModalId[transmissionId];
    if (!modalId) {
      return this.postMessageToAllIframes({ message });
    }

    const isIframeReady = modalReadyStates[modalId];
    if (!isIframeReady) {
      modalMessagingReadyMessages.push(data);
      return;
    }

    const iframe = modalIframes[modalId];
    this.postMessageToIframe({ iframe, message });

    if (shouldEndTransmission) {
      delete transmissionIdToModalId[transmissionId];
    }
  }

  initInternalMessagingSubscriptions = () => {
    InternalMessaging.subscribeMultipleEvents({
      eventNames: INTERNAL_MESSAGES_TO_SUBSCRIBE,
      callback: this.internalEventHandler
    });
  };

  init() {
    this.initInternalMessagingSubscriptions();
    window.addEventListener('message', this.eventHandler);
  }
}

const instance = new ModalManager();
Object.freeze(instance);
export default instance;
