import React, { useEffect } from 'react';
import styled from 'styled-components';

import { TextH2Bold, TextBody, TextTiny } from 'shared/react/components/basic/text/TextV2';
import BasicInput from 'src/basic_components/BasicInput';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import { TextSubtitle } from 'shared/react/components/complex/Text';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import { FEATURE_ACCOUNT_TRACKING } from 'shared/react/constants/features.constants';
import Utils from 'src/utils';
import { FIND_GOOGLE_ANALYTICS_HELP_ARTICLE } from 'src/constants/url.constants';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { useModalActions } from 'src/context/ui_store/ModalStore';
import { RESET_PROJECTS_GOOGLE_ANALYTICS_MODAL_KEY } from 'src/constants/modals.constants';
import { useApps } from 'src/context/AppsStore';
import useGoogleAnalytics from 'src/hooks/useGoogleAnalytics';

const GOOGLE_TAG_PLACEHOLDER = 'G-1AAA11AAAA';

type Props = {
  loading: boolean;
  onUpdate: () => void;
  setAccountFeaturesToUpdate: (value: any) => void;
  accountFeaturesToUpdate: { [key: string]: any };
  appDataToUpdate: { [key: string]: any };
  setAppDataToUpdate: (value: any) => void;
};

const TrackingInformation = ({
  loading,
  onUpdate,
  accountFeaturesToUpdate,
  setAccountFeaturesToUpdate,
  appDataToUpdate,
  setAppDataToUpdate,
}: Props) => {
  const { setCurrentModal } = useModalActions();
  const [{ selectedApp }] = useApps();
  const { googleAnalyticsId: currentGoogleAnalyticsId } = useGoogleAnalytics();

  const [googleAnalyticsID, setGoogleAnalyticsID] =
    React.useState<string>(currentGoogleAnalyticsId);

  useEffect(() => {
    setGoogleAnalyticsID(currentGoogleAnalyticsId);
  }, [currentGoogleAnalyticsId]);

  const onGoogleTagChange = value => {
    onUpdate();

    setGoogleAnalyticsID(value);
    if (selectedApp) {
      setAppDataToUpdate({ ...appDataToUpdate, googleAnalyticsID: value });
      return;
    }

    setAccountFeaturesToUpdate({
      ...accountFeaturesToUpdate,
      [FEATURE_ACCOUNT_TRACKING]: {
        ...accountFeaturesToUpdate?.[FEATURE_ACCOUNT_TRACKING],
        googleAnalyticsID: value,
      },
    });
  };

  const onFindGoogleTagClick = () => {
    Utils.openInNewTab(FIND_GOOGLE_ANALYTICS_HELP_ARTICLE);
  };

  const onOverrideLinkClick = () => {
    setCurrentModal(RESET_PROJECTS_GOOGLE_ANALYTICS_MODAL_KEY);
  };

  return (
    <LayoutRoot>
      <TitleContainer>
        <TextH2Bold>Event Tracking</TextH2Bold>
        <TextBodyNeutralDark>
          Use the following to add global tracking details for all Tolstoys. You can overwrite them
          in each Tolstoy’s settings.
        </TextBodyNeutralDark>
      </TitleContainer>
      <InfoItemsContainer>
        <InfoItem>
          <InfoItemTitleContainer>
            <InfoItemTitle>Google Analytics</InfoItemTitle>
          </InfoItemTitleContainer>
          <StyledBasicInput
            placeholder={GOOGLE_TAG_PLACEHOLDER}
            value={googleAnalyticsID}
            disabled={loading}
            onChange={e => onGoogleTagChange(e.target.value)}
          />
          <LinkContainer onClick={onFindGoogleTagClick}>
            <LinkText>Find your Google tag ID</LinkText>
          </LinkContainer>
          <TextBodyNeutralDark>
            To overwrite all previous individual Tolstoys’ tracking ID’s click{' '}
            <OverrideLinkText onClick={onOverrideLinkClick}>here</OverrideLinkText>
          </TextBodyNeutralDark>
        </InfoItem>
      </InfoItemsContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)``;

const LinkContainer = styled(Gap8HorizontalFlexWrap)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.blue1};
`;

const LinkText = styled(TextTiny)`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.blue1};
`;

const TextBodyNeutralDark = styled(TextBody)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.neutralDark};
`;

const OverrideLinkText = styled(TextBody)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.blue1};
  cursor: pointer;
`;

const TitleContainer = styled.div``;

const InfoItem = styled(Gap8VerticalFlex)`
  align-items: start;
`;

const InfoItemTitleContainer = styled(Gap8HorizontalFlexWrap)`
  min-width: 120px;
`;

const InfoItemTitle = styled(TextSubtitle)``;

const InfoItemsContainer = styled(Gap16VerticalFlex)``;

const StyledBasicInput = styled(BasicInput)`
  border: 1px solid ${({ theme }) => theme.colors.gray3};
  background: ${({ theme }) => theme.colors.white};
  padding: 12px;
  border-radius: 10px;
  max-width: 412px;
  width: 100%;
`;

export default TrackingInformation;
