import React from 'react';

const TwitterIcon = ({ fill = '#0AF', width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.93 8.438v.546c0 5.43-4.102 11.641-11.64 11.641-2.345 0-4.493-.664-6.29-1.836.313.04.625.078.977.078a8.247 8.247 0 0 0 5.078-1.758c-1.797-.039-3.32-1.21-3.828-2.851.273.039.507.078.78.078.352 0 .743-.078 1.056-.156a4.107 4.107 0 0 1-3.282-4.024v-.039c.547.313 1.211.469 1.875.508A4.036 4.036 0 0 1 2.82 7.227c0-.782.196-1.485.547-2.07a11.9 11.9 0 0 0 8.477 4.296 3.849 3.849 0 0 1-.117-.937 4.101 4.101 0 0 1 4.101-4.102 4.1 4.1 0 0 1 3.008 1.29 8.663 8.663 0 0 0 2.578-1.016 4.007 4.007 0 0 1-1.797 2.265 7.687 7.687 0 0 0 2.344-.625 8.703 8.703 0 0 1-2.031 2.11Z"
        fill={fill}
      />
    </svg>
  );
};
export default TwitterIcon;
