import React from 'react';

const TolstoyTVIcon = () => {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.6953 0.789062L12.7734 5.71094L12.7344 5.75H9.10156L14.1016 0.789062L14.1406 0.75H17.5C17.5781 0.75 17.6172 0.789062 17.6953 0.789062ZM15.3516 5.75L19.4531 1.6875C19.7656 2.11719 20 2.66406 20 3.25V5.75H15.3516ZM7.85156 0.75H11.4844L6.52344 5.71094L6.48438 5.75H2.85156L7.85156 0.789062L7.89062 0.75H7.85156ZM5.23438 0.75L0.273438 5.71094L0.234375 5.75H0V3.25C0 1.88281 1.09375 0.75 2.5 0.75H5.23438ZM0 7H20V15.75C20 17.1562 18.8672 18.25 17.5 18.25H2.5C1.09375 18.25 0 17.1562 0 15.75V7ZM8.4375 9.57812C8.24219 9.69531 8.125 9.92969 8.125 10.125V15.125C8.125 15.3594 8.24219 15.5938 8.4375 15.7109C8.63281 15.7891 8.90625 15.7891 9.0625 15.6719L12.8125 13.1719C13.0078 13.0547 13.125 12.8594 13.125 12.625C13.125 12.4297 13.0078 12.2344 12.8125 12.1172L9.0625 9.61719C8.90625 9.5 8.63281 9.5 8.4375 9.57812Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default TolstoyTVIcon;
