import React from 'react';

const TolstoyLogoWithWord = ({ height = '48', width = '246' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 246 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M138.529 0H145.262V37.824H138.529V0Z" fill="#090A0B" />
      <path
        d="M97.3269 37.824H90.0289V7.76125H80.0587V1.33617H107.451V7.76125H97.3269V37.824Z"
        fill="#090A0B"
      />
      <path
        d="M14.9739 11.9347V4.26238C7.5018 6.60855 2.03112 12.7946 0.459596 20.2371C0.159376 21.4213 0 22.6611 0 23.9379V27.8982H0.0277982C0.50778 35.7688 7.0422 42.0049 15.0351 42.0049C23.3393 42.0049 30.0702 35.274 30.0702 26.9698C30.0702 18.6655 23.3375 11.9347 15.0351 11.9347H14.9739Z"
        fill="#090A0B"
      />
      <path
        d="M50.3443 34.3326H50.4055V42.0049C57.8776 39.6587 63.3464 33.4727 64.918 26.0283C65.2182 24.846 65.3794 23.6062 65.3794 22.3293V18.369H65.3516C64.8716 10.4984 58.3354 4.26238 50.3443 4.26238C42.0401 4.26238 35.3092 10.9932 35.3092 19.2975C35.3092 27.5999 42.0401 34.3326 50.3443 34.3326Z"
        fill="#090A0B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.63 12.335C111.613 12.335 106.269 17.7834 106.269 25.2852C106.269 32.7888 111.613 38.2873 119.63 38.2873C127.442 38.2873 132.94 32.9427 132.94 25.2852C132.942 17.5758 127.442 12.335 119.63 12.335ZM119.63 32.2236C115.776 32.2236 113.103 29.1918 113.103 25.2852C113.103 21.3286 115.724 18.3468 119.63 18.3468C123.331 18.3468 126.105 21.1729 126.105 25.2852C126.105 29.3975 123.227 32.2236 119.63 32.2236Z"
        fill="#090A0B"
      />
      <path
        d="M160.393 38.2873C166.972 38.2873 170.106 34.896 170.106 30.476C170.106 25.4909 166.921 24.3086 161.472 22.3052C159.005 21.3805 157.771 20.6596 157.771 19.6329C157.771 18.2967 159.107 17.7834 160.136 17.7834C161.985 17.7834 164.248 18.6062 166.15 20.4557L169.439 15.4706C166.561 13.2616 163.735 12.335 160.292 12.335C155.101 12.335 151.196 15.2649 151.196 19.7867C151.196 24.6681 154.793 26.6214 158.957 28.0094C162.195 29.088 163.479 29.6532 163.479 30.7336C163.479 31.9679 162.401 32.7888 160.447 32.7888C157.671 32.7888 155.514 31.8122 153.457 29.9108L150.219 34.5883C152.942 36.9512 156.385 38.2873 160.393 38.2873Z"
        fill="#090A0B"
      />
      <path
        d="M182.919 38.2873C186.311 38.2873 187.954 37.5664 189.548 36.6936L187.852 31.2989C186.722 31.8641 185.49 32.1717 184.667 32.1717C183.073 32.1717 182.508 31.1451 182.508 28.9342V18.2968H189.292V12.6945H182.508V3.8028L175.775 4.52184V12.6945H172.795V18.2949H175.775V29.9108C175.775 35.5631 178.603 38.2873 182.919 38.2873Z"
        fill="#090A0B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M204.85 12.335C196.833 12.335 191.489 17.7834 191.489 25.2852C191.489 32.7888 196.833 38.2873 204.85 38.2873C212.661 38.2873 218.16 32.9427 218.16 25.2852C218.16 17.5758 212.661 12.335 204.85 12.335ZM204.85 32.2236C200.995 32.2236 198.323 29.1918 198.323 25.2852C198.323 21.3286 200.944 18.3468 204.85 18.3468C208.551 18.3468 211.325 21.1729 211.325 25.2852C211.325 29.3975 208.447 32.2236 204.85 32.2236Z"
        fill="#090A0B"
      />
      <path
        d="M224.815 48H231.649L245.167 12.6945H238.33L232.626 28.1113H232.524L226.409 12.6945H219.213L229.287 36.2822L224.815 48Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default TolstoyLogoWithWord;
