import { SAVED_LABEL_NAME, useVodLabels } from 'app/src/context/VodLabelsStore';
import { getVodConnectionTypeKey } from 'app/src/utils/vodConnections.utils';
import { VodConnectionType } from 'app/src/types/entities';
import { useCreateVodConnection } from 'app/src/hooks/vod-connections';
import { useInstagram } from 'app/src/context/InstagramStore';
import { useApps } from 'app/src/context/AppsStore';

const useCreateLabelsByUGC = ({ video }) => {
  const [{ vodLabels }, { createVodLabel }] = useVodLabels();
  const { createVodConnection } = useCreateVodConnection();
  const [{ selectedUGCCategory }] = useInstagram();
  const [{ instagramApps }] = useApps();
  const { ugcHashtag } = JSON.parse(video?.externalProviderData || '{}');
  const isTaggedMedia = !!instagramApps.find(
    app => `@${app.data.userName}` === ugcHashtag || (!ugcHashtag && app.id === selectedUGCCategory)
  );

  const createVodLabelByCategory = async () => {
    let promises = [];
    const approvedLabel = vodLabels.find(label => label.name === SAVED_LABEL_NAME.approvedUGC);
    const taggedLabel = vodLabels.find(label => label.name === SAVED_LABEL_NAME.taggedUGC);
    const taggedMedia = vodLabels.find(
      label => label.name === (ugcHashtag || `#${selectedUGCCategory}`)
    );

    if (!isTaggedMedia && !taggedMedia) {
      promises.push(createVodLabel({ name: ugcHashtag || `#${selectedUGCCategory}` }));
    } else if (taggedMedia) {
      promises.push(Promise.resolve(taggedMedia));
    }

    if (isTaggedMedia && !taggedLabel) {
      promises.push(createVodLabel({ name: SAVED_LABEL_NAME.taggedUGC }, true));
    } else if (isTaggedMedia && taggedLabel) {
      promises.push(Promise.resolve(taggedLabel));
    }

    if (!approvedLabel) {
      promises.push(createVodLabel({ name: SAVED_LABEL_NAME.approvedUGC }, true));
    } else {
      promises.push(Promise.resolve(approvedLabel));
    }

    return await Promise.all(promises);
  };

  const createConnectionsFromLabels = async vodLabels => {
    const promises = vodLabels.map(({ id }) => {
      return createVodConnection({
        vodAssetId: video.id,
        typeKey: getVodConnectionTypeKey({ appKey: video.appKey, id }),
        type: VodConnectionType.vodLabelId,
        vodLabelId: id,
        productId: null,
        externalProductId: null,
        tag: null,
        appUrl: null,
        provider: null,
        vodSource: null,
        variantIds: [],
      });
    });

    return Promise.all(promises);
  };

  const createLabelsByUGC = async () => {
    const vodLabels = await createVodLabelByCategory();
    return createConnectionsFromLabels(vodLabels);
  };

  return createLabelsByUGC;
};

export default useCreateLabelsByUGC;
