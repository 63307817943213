import React from 'react';

const TrophyIcon = () => {
  return (
    <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.313 3.89c-.141-.515-.61-.89-1.125-.89h-4.735a8.503 8.503 0 0 0 0-1.547c0-.797-.656-1.453-1.5-1.453H9c-.844 0-1.5.656-1.5 1.453A8.507 8.507 0 0 0 7.5 3H2.766c-.516 0-.985.375-1.125.89 0 .141-.891 3.985 1.593 7.641C4.97 14.156 7.922 15.984 12 17.062a2 2 0 0 1 1.5 1.922c0 1.125-.938 2.016-2.063 2.016h-.187A2.25 2.25 0 0 0 9 23.25c0 .422.328.75.75.75h10.453a.74.74 0 0 0 .75-.75 2.25 2.25 0 0 0-2.25-2.25h-.187c-1.125 0-2.063-.89-2.063-2.016a2 2 0 0 1 1.5-1.922c4.078-1.078 7.031-2.906 8.766-5.53 2.484-3.657 1.593-7.5 1.593-7.641ZM5.108 10.313C3.797 8.392 3.703 6.376 3.75 5.25H7.5c.281 2.813.984 6.188 2.719 8.906-2.297-.937-4.031-2.203-5.11-3.844Zm13.688-3.421-1.688 1.64.422 2.344c.047.234-.234.422-.468.328L15 10.078l-2.11 1.125c-.234.094-.468-.094-.421-.328l.375-2.344-1.688-1.64c-.187-.188-.094-.47.14-.516l2.392-.328 1.03-2.156a.277.277 0 0 1 .516 0l1.079 2.156 2.343.328c.235.047.328.328.14.516Zm6.047 3.421c-1.078 1.641-2.813 2.907-5.11 3.844 1.735-2.719 2.438-6.094 2.72-8.906h3.75c.046 1.125-.048 3.14-1.36 5.063Z"
        fill="#FFC728"
      />
    </svg>
  );
};

export default TrophyIcon;
