import React from 'react';
import EditorItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorItem';
import OnOffToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/OnOffToggleItem';
import { EditorItemProps, OnOffToggleItemProps } from '../../../types/lookAndFeel.types';

type Props = EditorItemProps & OnOffToggleItemProps;

const EditorToggleItem = ({
  checked,
  checkedText,
  uncheckedText,
  onChange,
  dataTestId,
  disabled,
  ...props
}: Props) => {
  return (
    <EditorItem {...props} disabled={disabled}>
      <OnOffToggleItem
        dataTestId={dataTestId}
        checked={checked}
        onChange={onChange}
        checkedText={checkedText}
        uncheckedText={uncheckedText}
        disabled={disabled}
      />
    </EditorItem>
  );
};

export default EditorToggleItem;
