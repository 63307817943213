import { useApps } from 'app/src/context/AppsStore';
import { useInstagram } from 'app/src/context/InstagramStore';
import { useEffect } from 'react';

export const useInstagramLibraryVideos = () => {
  const [{ instagramApps, initialized: appsInitialized }] = useApps();

  const [
    {
      accountVideos: instagramVideos,
      initialized: instagramInitialized,
      isLoading,
      gotAllVideos: gotAllInstagram,
    },
    { fetchByApps: fetchInstagramAppsVideos },
  ] = useInstagram();
  const initialized = (!instagramApps.length && appsInitialized) || instagramInitialized;

  useEffect(() => {
    if (!initialized && instagramApps.length) {
      fetchInstagramAppsVideos(instagramApps);
    }
  }, [initialized, instagramApps]);

  const loadMore = async () => {
    if (initialized && !isLoading && !gotAllInstagram) {
      await fetchInstagramAppsVideos(instagramApps);
    }
  };

  return { videos: instagramVideos, initialized, loadMore, isFetchingNextPage: isLoading };
};
