export const EVENTS_NAMES = {
  VISIBILITY_CHANGE: 'visibilitychange',
  MODAL_CLOSE: 'tolstoyModalClose',
  PLAY: 'tolstoyPlay',
  ADD_TO_CART: 'addToCart',
  CART_ITEM_QUANTITY_CHANGE: 'cartItemQuantityChange',
};

export const PRODUCT_METAFIELDS_EVENTS = {
  getProductsMetafields: 'getProductsMetafields',
  returnProductsMetafields: 'returnProductsMetafields',
};
