import React from 'react';

const ImageIcon = ({ fill, darkBackground }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0015 19.7221C15.371 19.7221 19.7238 15.3693 19.7238 9.99984C19.7238 4.63039 15.371 0.277588 10.0015 0.277588C4.6321 0.277588 0.279297 4.63039 0.279297 9.99984C0.279297 15.3693 4.6321 19.7221 10.0015 19.7221Z"
        fill={fill || '#FFFFFF'}
      />
      <path
        d="M7.62956 6.69271C7.01432 6.69271 6.53581 7.19401 6.53581 7.78646C6.53581 8.40167 7.01432 8.88017 7.62956 8.88017C8.22201 8.88017 8.72335 8.40167 8.72335 7.78646C8.72335 7.19401 8.22201 6.69271 7.62956 6.69271ZM14.3744 4.6875H5.62435C4.80403 4.6875 4.16602 5.34831 4.16602 6.14583V13.4375C4.16602 14.2578 4.80403 14.8958 5.62435 14.8958H14.3744C15.1719 14.8958 15.8327 14.2578 15.8327 13.4375V6.14583C15.8327 5.34831 15.1719 4.6875 14.3744 4.6875ZM14.7389 13.3008L11.6172 9.0625C11.526 8.94858 11.4121 8.88017 11.2754 8.88017C11.1158 8.88017 11.0019 8.94858 10.9108 9.0625L8.49543 12.3665L7.65234 11.3183C7.5612 11.2044 7.44727 11.1588 7.31055 11.1588C7.17383 11.1588 7.03711 11.2044 6.96875 11.3183L5.25977 13.4602C5.25977 13.4602 5.25977 13.4603 5.25977 13.4375V6.14583C5.25977 5.96354 5.41927 5.78125 5.62435 5.78125H14.3744C14.5567 5.78125 14.7389 5.96354 14.7389 6.14583V13.3008Z"
        fill={darkBackground ? '#FFFFFF' : '#2F3137'}
      />
    </svg>
  );
};

export default ImageIcon;
