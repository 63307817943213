import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import React from 'react';
import NavigateToButton from '../NavigateToButton';

const NavigateToWhiteButton = ({ analyticsData, navigateFunction, text }) => {
  const theme = useTheme();
  const { projectId } = useParams();

  if (!navigateFunction || !text) {
    return null;
  }

  return (
    <NavigateToButton
      analyticsData={analyticsData}
      navigateFunction={navigateFunction}
      text={text}
      backgroundColor={theme.colors.white}
      textColor={theme.colors.blue12}
      projectId={projectId}
    />
  );
};

export default NavigateToWhiteButton;
