import styled from 'styled-components';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';

const Badge = styled(HorizontalFlexWrap)`
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 4px 8px;
`;

const PrimaryBadge = styled(Badge)`
  color: ${({ theme }) => theme.colors.green8};
  background-color: ${({ theme }) => theme.colors.green7};
`;

const DefaultBadge = styled(Badge)`
  color: ${({ theme }) => theme.colors.gray2};
  background-color: ${({ theme }) => theme.colors.gray3};
`;

const SuccessBadge = styled(Badge)`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.success};
`;

const SuccessLightBadge = styled(Badge)`
  color: ${({ theme }) => theme.colors.successDark};
  background-color: ${({ theme }) => theme.colors.successLight};
`;

const InfoBadge = styled(Badge)`
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.info};
`;

const InfoLightBadge = styled(Badge)`
  color: ${({ theme }) => theme.colors.infoDark};
  background-color: ${({ theme }) => theme.colors.infoLight};
`;

export {
  Badge,
  PrimaryBadge,
  DefaultBadge,
  SuccessBadge,
  InfoBadge,
  InfoLightBadge,
  SuccessLightBadge,
};
