import React from 'react';
import styled from 'styled-components';
import Utils from 'app/src/utils';
import { track } from 'app/src/helpers/Tracker';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import ResetToDefaultInputWrapper from 'app/src/complex_components/ResetToDefaultInputWrapper';
import EditorColorInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorColorInputItem';
import EditorMainContainer from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorMainContainer';
import { useBrandingContext } from '../../../BrandingContext';
import EditorSubheader from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorSubheader';
import {
  BODY_COLOR_TEXT_TYPE,
  DISCOUNT_COLOR_TEXT_TYPE,
  HEADER_COLOR_TEXT_TYPE,
} from 'shared/react/constants/feature-constants/feedButtonsCustomization.constants';

type Props = {
  settings?: any;
  features?: any;
  defaultSettings?: any;
  onChange?: any;
};

const TextEditor = ({
  settings = {},
  features = {},
  defaultSettings = {},
  onChange = () => {},
}: Props) => {
  const { loading } = useBrandingContext();

  const { headerColorText, bodyColorText, discountedColorText } = settings;

  const updateSettings = update => onChange({ ...settings, ...update });
  const handleHeaderColorChange = settings => updateSettings({ headerColorText: settings });
  const handleBodyColorChange = settings => updateSettings({ bodyColorText: settings });
  const handleDiscountColorChange = settings => updateSettings({ discountedColorText: settings });

  const handleResetToDefault = type => {
    track('Branding Reset To Default', { property: type });
    updateSettings({ [type]: defaultSettings[type] });
  };

  const disabled = loading;

  return (
    <LayoutRoot>
      <EditorSubheader title="Text" />
      <EditorContainer>
        <ResetToDefaultInputWrapper
          disabled={disabled}
          onClick={() => handleResetToDefault(HEADER_COLOR_TEXT_TYPE)}
        >
          <EditorColorInputItem
            text="Header color"
            color={headerColorText}
            onChange={handleHeaderColorChange}
            height="50px"
            disabled={disabled}
          />
        </ResetToDefaultInputWrapper>
        <ResetToDefaultInputWrapper
          disabled={disabled}
          onClick={() => handleResetToDefault(BODY_COLOR_TEXT_TYPE)}
        >
          <EditorColorInputItem
            text="Body color"
            color={bodyColorText}
            onChange={handleBodyColorChange}
            height="50px"
            disabled={disabled}
          />
        </ResetToDefaultInputWrapper>
      </EditorContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

const EditorContainer = styled(EditorMainContainer)`
  padding: 16px 0 0;
`;

export default TextEditor;
