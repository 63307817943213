import React from 'react';
import styled from 'styled-components';

import { getTimeSince } from 'app/src/utils/date.utils';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';
import { CardDetails, CardName } from 'app/src/pages/dashboard/components/card/Card';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import TeamMemberDetails from 'app/src/complex_components/TeamMemberDetails';

const OffsiteCardDetails = ({ project }) => {
  const { name, updatedAt, stepsCount = 0, totalResponses = 0 } = project;
  return (
    <DetailsWrapper>
      <NameWrapper>
        <EllipsisTextOverflowWrapper>
          <CardName className="notranslate" title={name}>
            {name}
          </CardName>
        </EllipsisTextOverflowWrapper>
      </NameWrapper>
      <TeamMemberDetails
        textColor={undefined}
        asset={project}
        fontSize="12px"
        fontWeight={500}
        imageSize="24px"
      />
      <BottomRow>
        {getTimeSince(updatedAt)} • {stepsCount} parts • {totalResponses || 0} responses
      </BottomRow>
    </DetailsWrapper>
  );
};

const DetailsWrapper = styled(CardDetails)`
  gap: 8px;
`;

const NameWrapper = styled(Gap8HorizontalFlex)`
  justify-content: space-between;
`;

const BottomRow = styled(Gap8HorizontalFlex)`
  align-items: center;
  justify-content: space-between;
  font-size: 10px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.ghostDark};
`;

export default OffsiteCardDetails;
