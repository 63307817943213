import React, { useState } from 'react';
import AreYouSureModal from 'app/src/pages/modals/AreYouSureModal';
import { useFolders } from 'app/src/context/FoldersStore';
import { track } from 'app/src/helpers/Tracker';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { DELETE_FOLDER_MODAL_KEY } from 'app/src/constants/modals.constants';

const DeleteFolderModal = () => {
  const [, { deleteFolder }] = useFolders();
  const [{ currentModal, currentFolderId }, { setCurrentModal, setCurrentFolderId }] = useModal();
  const [loading, setLoading] = useState(false);

  const onClose = () => {
    setCurrentFolderId('');
    setCurrentModal('');
  };

  const onDeleteFolder = async () => {
    setLoading(true);
    track('Delete Folder Click');
    await deleteFolder(currentFolderId);
    setCurrentModal('');
  };

  return (
    <AreYouSureModal
      open={currentModal === DELETE_FOLDER_MODAL_KEY}
      loading={loading}
      titleText="Are you sure you want to delete this folder? (Don't worry, your Tolstoys won't be deleted.)"
      secondaryButtonClicked={onClose}
      actionButtonClicked={onDeleteFolder}
    />
  );
};

export default DeleteFolderModal;
