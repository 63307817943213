import React from 'react';
import styled from 'styled-components';
import { TextBody } from 'shared/react/components/complex/Text';

const TeamMembersEmptyState = () => {
  return (
    <StyledTeamMembersEmptyState>
      <TextBody fontSize="24px" fontWeight="600" lineHeight="30px">
        Invite team members to access projects easily
      </TextBody>
    </StyledTeamMembersEmptyState>
  );
};

export default TeamMembersEmptyState;

const StyledTeamMembersEmptyState = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  border-radius: 16px;
`;
