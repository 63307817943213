import * as React from 'react';

const QuizRulePlusIcon = ({ ...props }) => (
  <svg
    width={10}
    height={11}
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.875 5.5a.57.57 0 0 0-.563-.563h-3.75v-3.75A.57.57 0 0 0 5 .625a.555.555 0 0 0-.563.563v3.75H.688a.555.555 0 0 0-.562.562c0 .328.234.563.563.563h3.75v3.75a.54.54 0 0 0 .562.562.555.555 0 0 0 .563-.563v-3.75h3.75a.555.555 0 0 0 .562-.562Z"
      fill="#2F3137"
    />
  </svg>
);

export default QuizRulePlusIcon;
