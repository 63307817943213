import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import DesignStoriesPreviewTitle from 'app/src/pages/project/pages/look_and_feel/preview/feed_stories_preview/DesignStoriesPreviewTitle';
import DesignStoriesPreviewTile from 'app/src/pages/project/pages/look_and_feel/preview/feed_stories_preview/DesignStoriesPreviewTile';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { useVideos } from 'app/src/context/VideosStore';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import {
  DEFAULT_STORIES_ITEMS_PER_ROW,
  DEFAULT_STORIES_ITEMS_SIZE_TYPE,
  DEFAULT_STORIES_ITEMS_SPACING,
  DEFAULT_STORIES_SIZE,
  EMAIL_SETTINGS_CATEGORY_KEY,
  LOOK_AND_FEEL_PREVIEW_CONTENT_WIDTH,
  STORIES_ALIGNMENTS,
  STORIES_ITEMS_SHAPE_TYPES,
  STORIES_ITEMS_SIZE_TYPES,
  STORIES_MIN_ITEM_FIXED_SIZE,
} from '../../LookAndFeelPage.constants';
import DefaultStoriesPreviewTile from './DefaultStoriesPreviewTile';
import { useDynamicConditionsContext } from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/context/DynamicConditionsContext';

type Props = {
  project: any;
  storiesTitleEnabled: boolean;
  storiesTitleText: string;
  storiesTitleFontSize: number;
  storiesTitleFontWeight: string;
  storiesTitleColor: string;
  storiesAlignment: string;
  size?: number;
  currThumbnailStepIndex: number;
  selectedCategory: string;
  storiesItemsSizeType?: string;
  storiesItemsPerRow?: number;
  storiesCircleSize?: number;
  storiesItemsSpacing?: number;
  storiesRectangleWidth?: number;
  storiesRectangleHeight?: number;
  storiesItemsShape?: string;
};

const DesignStoriesPreview = ({
  project,
  storiesTitleEnabled,
  storiesTitleText,
  storiesTitleFontSize,
  storiesTitleFontWeight,
  storiesTitleColor,
  storiesAlignment,
  size = 1,
  currThumbnailStepIndex,
  selectedCategory,
  storiesItemsSizeType = DEFAULT_STORIES_ITEMS_SIZE_TYPE,
  storiesItemsPerRow = DEFAULT_STORIES_ITEMS_PER_ROW,
  storiesCircleSize = DEFAULT_STORIES_SIZE,
  storiesItemsSpacing = DEFAULT_STORIES_ITEMS_SPACING,
  storiesRectangleWidth,
  storiesRectangleHeight,
  storiesItemsShape,
  ...props
}: Props) => {
  const [, { getVideoById, getVideoAvatar }] = useVideos();
  const [parentWidth, setParentWidth] = useState(LOOK_AND_FEEL_PREVIEW_CONTENT_WIDTH);
  const previewRef = useRef();
  const { vodAssetIds } = useDynamicConditionsContext();

  const proportionalCircleSize = storiesCircleSize / size;
  const proportionalRectangleWidth = storiesRectangleWidth / size;
  const proportionalSpacing = storiesItemsSpacing / size;

  const getNumberOfTiles = () => {
    const numberOfTilesWithoutSpacing = 1;

    if (storiesItemsSizeType === STORIES_ITEMS_SIZE_TYPES.responsive) {
      return storiesItemsPerRow;
    }

    if (storiesItemsShape === STORIES_ITEMS_SHAPE_TYPES.rectangle) {
      const tilesWithSpacingsWidth = parentWidth - proportionalRectangleWidth;
      const tileWithSpacing = proportionalRectangleWidth + proportionalSpacing;

      const numberOfTilesWithSpacings = Math.floor(tilesWithSpacingsWidth / tileWithSpacing);

      return numberOfTilesWithSpacings + numberOfTilesWithoutSpacing;
    }

    const tilesWithSpacingsWidth = parentWidth - proportionalCircleSize;
    const tileWithSpacing = proportionalCircleSize + proportionalSpacing;

    const numberOfTilesWithSpacings = Math.floor(tilesWithSpacingsWidth / tileWithSpacing);

    return numberOfTilesWithSpacings + numberOfTilesWithoutSpacing;
  };

  const getCircleSize = numberOfTiles => {
    if (storiesItemsSizeType === STORIES_ITEMS_SIZE_TYPES.responsive) {
      const circleSize = parentWidth / numberOfTiles - proportionalSpacing;
      return Math.max(circleSize, STORIES_MIN_ITEM_FIXED_SIZE / size);
    }

    return proportionalCircleSize;
  };

  const getStoriesGap = (numberOfTiles, circleSize) => {
    if (storiesItemsSizeType === STORIES_ITEMS_SIZE_TYPES.fixed) {
      return proportionalSpacing;
    }

    const circlesTotalWidth = numberOfTiles * circleSize;
    const spacingsTotalWidth = (numberOfTiles - 1) * proportionalSpacing;
    const totalWidth = circlesTotalWidth + spacingsTotalWidth;

    if (totalWidth <= parentWidth) {
      return proportionalSpacing;
    }

    return parentWidth / numberOfTiles - circleSize;
  };

  const getAlignment = () => {
    switch (storiesAlignment) {
      case STORIES_ALIGNMENTS.right:
        return 'flex-end';
      case STORIES_ALIGNMENTS.center:
        return 'center';
      default:
        return 'flex-start';
    }
  };

  const numberOfTiles = getNumberOfTiles();
  const circleSize = getCircleSize(numberOfTiles);
  const storiesGap = getStoriesGap(numberOfTiles, circleSize);
  const alignItems = getAlignment();

  let offset = 0;

  if (selectedCategory === EMAIL_SETTINGS_CATEGORY_KEY && numberOfTiles <= currThumbnailStepIndex) {
    offset = Math.max(currThumbnailStepIndex - 1, offset);
  }

  const steps = project.steps.items.slice(offset, numberOfTiles + offset);

  let videosWithDescriptions = [];
  if (project?.dynamic) {
    videosWithDescriptions = vodAssetIds.map(videoId => {
      const video = getVideoById(videoId);
      return { video, description: video?.description };
    });
  } else {
    videosWithDescriptions = steps.map(({ description, videoId }) => {
      const video = getVideoById(videoId);
      return { video, description };
    });
  }
  const defaultTilesNumber = Math.max(numberOfTiles - videosWithDescriptions.length, 0);
  console.log({ defaultTilesNumber, numberOfTiles });
  return (
    <LayoutRoot
      size={size}
      ref={ref => {
        previewRef.current = ref;
        setParentWidth(ref?.parentElement?.offsetWidth);
      }}
    >
      <DesignStoriesPreviewTitle
        storiesTitleEnabled={storiesTitleEnabled}
        storiesTitleText={storiesTitleText}
        storiesTitleFontSize={storiesTitleFontSize}
        storiesTitleFontWeight={storiesTitleFontWeight}
        storiesTitleColor={storiesTitleColor}
        alignItems={alignItems}
        size={size}
      />
      <TilesContainer size={size} alignItems={alignItems} gap={storiesGap}>
        {videosWithDescriptions.slice(0, numberOfTiles).map(({ description, video }, index) => {
          const avatarUrl = getVideoAvatar(video);
          const isEditingThumbnail =
            index + offset === currThumbnailStepIndex &&
            selectedCategory === EMAIL_SETTINGS_CATEGORY_KEY;
          return (
            <DesignStoriesPreviewTile
              key={video.id}
              partName={description}
              avatarUrl={avatarUrl}
              size={size}
              video={video}
              isEditingThumbnail={isEditingThumbnail}
              index={index + offset}
              storiesItemsSizeType={storiesItemsSizeType}
              storiesCircleSize={circleSize}
              storiesRectangleWidth={storiesRectangleWidth}
              storiesRectangleHeight={storiesRectangleHeight}
              storiesItemsShape={storiesItemsShape}
              {...props}
            />
          );
        })}
        {[...Array(defaultTilesNumber)].map((_, i) => (
          <DefaultStoriesPreviewTile
            key={i}
            size={size}
            storiesCircleSize={circleSize}
            storiesRectangleWidth={storiesRectangleWidth}
            storiesRectangleHeight={storiesRectangleHeight}
            storiesItemsShape={storiesItemsShape}
            storiesItemsSizeType={storiesItemsSizeType}
            {...props}
          />
        ))}
      </TilesContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  gap: ${({ size }) => 8 / size}px;
`;

const TilesContainer = styled(HorizontalFlex)`
  gap: ${({ size, gap }) => (gap || DEFAULT_STORIES_ITEMS_SPACING) / size}px;
  justify-content: ${({ alignItems }) => alignItems || 'flex-start'};
`;

export default DesignStoriesPreview;
