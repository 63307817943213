import React from 'react';
import styled from 'styled-components';
import VerticalVideoCardEmptyStateBase from './VerticalVideoCardEmptyStateBase';

type Props = {
  shouldRender: boolean;
};

const VerticalVideoCardBackdrop = ({ shouldRender }: Props) => {
  if (!shouldRender) {
    return null;
  }

  return (
    <LayoutRoot>
      <Card index={1} />
      <Card index={2} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
`;

const Card = styled(VerticalVideoCardEmptyStateBase)<{ index: number }>`
  border: 1px solid ${({ theme }) => theme.colors.neutralGray};
  position: absolute;
  left: ${({ index }) => index * 3}px;
  top: ${({ index }) => index * 3}px;
  z-index: ${({ index }) => 1 / index};
  cursor: default;
`;

export default VerticalVideoCardBackdrop;
