import React from 'react';
import styled from 'styled-components';
import { Gap16HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextH6 } from 'shared/react/components/basic/text/TextV2';

type Props = {
  teamMembersCount: string;
};

const TeamMembersTitle = ({ teamMembersCount }: Props) => {
  return (
    <LayoutRoot>
      <TextH6>Team Members ({teamMembersCount})</TextH6>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16HorizontalFlex)`
  align-items: center;
`;

export default TeamMembersTitle;
