import React, { useState } from 'react';
import styled from 'styled-components';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Tooltip from 'app/src/basic_components/Tooltip';
import { useBilling } from 'app/src/hooks/useBillings';
import RespondentNotificationImage from 'app/src/images/tooltip_images/RespondentNotificationImage';
import { FEATURE_WHITE_LABEL } from 'shared/react/constants/features.constants';
import { useFeatureActions } from 'src/context/FeaturesStore';
import Utils from '../../../../../utils';
import PremiumFeatureTooltip from '../../PremiumFeatureTooltip';
import { useBrandingContext } from 'app/src/pages/account/branding/BrandingContext';
import UploadLogo from './UploadLogo';
import BrandingExpander from 'app/src/pages/account/branding/editors/BrandingExpander';
import { ACCOUNT_LOGO_NAME, PLAYER_LOGO_NAME } from 'app/src/utils/branding.utils';
import EditorMainContainer from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorMainContainer';

const LogoEditor = () => {
  const { loading, clearErrors, logoSettings, updateLogoSettings } = useBrandingContext();
  const { getFeatureEnabled } = useFeatureActions();
  const { isPricingEnabled, isFreePackage } = useBilling();
  const [isUploadingEmailLogo, setUploadingEmailLogo] = useState(false);
  const [isUploadingPlayerLogo, setUploadingPlayerLogo] = useState(false);
  const { logoUrl, playerLogoUrl } = logoSettings || {};

  const handleSetAccountEmailLogo = logoUrl => {
    updateLogoSettings({ logoUrl });
  };
  const handleSetPlayerLogo = playerLogoUrl => {
    updateLogoSettings({
      playerLogoUrl,
      showAccountLogo: !!playerLogoUrl,
    });
  };

  const isWhiteLabelEnabled = getFeatureEnabled(FEATURE_WHITE_LABEL);
  const shouldHideLandingPageItem = !isPricingEnabled && !isWhiteLabelEnabled;

  return (
    <LayoutRoot>
      <BrandingExpander title="Logo">
        <EditorMainContainer>
          <PremiumFeatureTooltip
            header="brand Player logo"
            analyticsData={Utils.getAnalyticsLocationObject('Branding Landing Page Tooltip')}
            marginTop="-8px"
            shouldHide={!isFreePackage}
          >
            <UploadLogo
              imageSrc={playerLogoUrl}
              loading={loading}
              onClear={() => handleSetPlayerLogo(null)}
              setLogo={handleSetPlayerLogo}
              clearErrors={clearErrors}
              type={PLAYER_LOGO_NAME}
              uploading={isUploadingPlayerLogo}
              setUploading={setUploadingPlayerLogo}
              disabled={isFreePackage}
              name="Player logo"
              shouldHide={shouldHideLandingPageItem}
              showButtonWithoutBackground
            />
          </PremiumFeatureTooltip>
          <StyledTooltip
            header="RESPONDENT NOTIFICATIONS"
            tooltipImage={<RespondentNotificationImage />}
            placement="right"
          >
            <ContentContainer>
              <UploadLogo
                imageSrc={logoUrl}
                loading={loading}
                onClear={() => handleSetAccountEmailLogo(null)}
                setLogo={handleSetAccountEmailLogo}
                clearErrors={clearErrors}
                type={ACCOUNT_LOGO_NAME}
                uploading={isUploadingEmailLogo}
                setUploading={setUploadingEmailLogo}
                name="Emails logo"
                showButtonWithoutBackground
              />
            </ContentContainer>
          </StyledTooltip>
        </EditorMainContainer>
      </BrandingExpander>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

const ContentContainer = styled(Gap16VerticalFlex)``;

const StyledTooltip = styled(Tooltip)`
  max-width: 250px;
  align-items: center;
  flex-direction: column;
  padding: 8px;
`;

export default LogoEditor;
