import * as React from 'react';

const QuizAddFlowIcon = props => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 8c0-4.406 3.563-8 8-8 4.406 0 8 3.594 8 8 0 4.438-3.594 8-8 8-4.438 0-8-3.563-8-8Zm8 3.5a.74.74 0 0 0 .75-.75v-2h2A.74.74 0 0 0 11.5 8a.76.76 0 0 0-.75-.75h-2v-2A.76.76 0 0 0 8 4.5a.74.74 0 0 0-.75.75v2h-2A.74.74 0 0 0 4.5 8c0 .438.313.75.75.75h2v2c0 .438.313.75.75.75Z"
      fill="#090A0B"
    />
  </svg>
);

export default QuizAddFlowIcon;
