import React, { useState } from 'react';
import styled from 'styled-components';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import { TextSmall } from 'shared/react/components/complex/Text';
import { ChevronRight } from '@material-ui/icons';
import Separator from 'shared/react/components/basic/Separator';
import MenuItem from 'app/src/complex_components/MenuItemV2';
import Utils from 'shared/react/utils/utils';

const MIN_CONTENT_WIDTH = 256;

const OtherMenuItem = ({
  otherMenuItems,
  answer,
  anchorEl,
  onAnswerTypeClick,
  onAnswerValueChange,
  value,
  customHeader,
  headerName = 'Other',
  inputState,
  error,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const clientRect = anchorEl?.getBoundingClientRect();
  const contentWidth =
    clientRect?.width < MIN_CONTENT_WIDTH ? MIN_CONTENT_WIDTH : clientRect?.width;
  const isEndScreen = clientRect?.right + contentWidth * 2 < window.innerWidth;

  const checkIfValueIsSelected = () => {
    if (inputState) {
      return true;
    }

    const item = otherMenuItems.find(({ key }) => {
      return answer.type === key || answer.next === key;
    });

    return !!item;
  };

  const getHeader = () => {
    if (customHeader) {
      return customHeader;
    }
    return (
      <>
        <Header>Other</Header> <Separator />
      </>
    );
  };

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const isValueSelected = checkIfValueIsSelected();

  return (
    <LayoutRoot onClick={toggleIsOpen}>
      <TextContainer isValueSelected={isValueSelected}>{headerName}</TextContainer>
      <OtherIcon>
        <ChevronRight />
      </OtherIcon>
      <OtherContent
        width={contentWidth}
        isValueSelected={isValueSelected}
        isEndScreen={isEndScreen}
        isOpen={isOpen}
        onClick={Utils.stopPropagation}
      >
        {getHeader()}
        {otherMenuItems.map(({ key, ...props }) => {
          const isSelected = answer.type === key || answer.next === key;
          return (
            <MenuItem
              onClick={closeAfterClick => onAnswerTypeClick(key, closeAfterClick)}
              key={key}
              isSelected={isSelected}
              value={value}
              onAnswerValueChange={onAnswerValueChange}
              error={error}
              {...props}
            />
          );
        })}
      </OtherContent>
    </LayoutRoot>
  );
};

const OtherContent = styled.div`
  min-width: 256px;
  background: white;
  border-radius: 12px;
  box-shadow: 0px 15px 35px rgba(50, 50, 93, 0.1), 0px 5px 15px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: absolute;
  ${({ isEndScreen, width }) => (isEndScreen ? `left:${width}px` : `right:${width}px`)};
  bottom: 0;
  max-height: 265px;
  overflow: auto;
  z-index: 1;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    left: 0;
  }
`;

const LayoutRoot = styled(HorizontalFlexWrap)`
  padding: 15px 20px;
  gap: 12px;
  cursor: pointer;
  transition: 0.3s;
  align-items: center;
  position: relative;

  justify-content: space-between;

  &:hover {
    background: ${({ theme }) => theme.colors.gray32};
  }

  &:last-child {
    border-radius: 0 0 12px 12px;
  }

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    position: unset;
    z-index: 2;
  }
`;

const OtherIcon = styled.div`
  line-height: 0;
`;

const TextContainer = styled(TextSmall)`color ${({ isValueSelected, theme }) =>
  isValueSelected ? theme.colors.primary : ''}`;

const Header = styled.div`
  color: ${({ theme }) => theme.colors.gray25};
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding: 20px 0 8px 20px;
`;

export default OtherMenuItem;
