import React from 'react';

const CurvedLightningIcon = ({ fill = '#3D4FE0' }) => {
  return (
    <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.93 1.555a.728.728 0 0 0-.258-.89C7.39.452 7.016.476 6.734.686l-6 5.25a.753.753 0 0 0-.187.844c.094.282.375.469.703.469h2.602l-1.805 4.219a.728.728 0 0 0 .258.89c.28.211.656.188.937-.023l6-5.25c.235-.211.305-.54.211-.844a.768.768 0 0 0-.703-.469H6.125L7.93 1.555Z"
        fill={fill}
      />
    </svg>
  );
};

export default CurvedLightningIcon;
