import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { track } from 'app/src/helpers/Tracker';
import { useApps } from 'app/src/context/AppsStore';
import { useProductActions } from 'app/src/context/ProductsStore';
import NoProductsPermissions from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/products/NoProductsPermissions';
import { useFormContext } from 'react-hook-form';
import { FEATURE_QUIZ_PRODUCTS_RESULT } from 'app/src/constants/appFeatures.constants';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { VIEW_PRODUCT } from 'shared/react/constants/shopifyMessaging.constants';
import { safeScrollIntoViewIfNeeded } from 'app/src/utils/browser.utils';
import SearchProductsDisplay from './SearchProductsDisplay';
import { PRODUCT_CTA_TYPES } from 'shared/react/constants/feed.constants';

const EditProductsButtons = ({
  project,
  isLoadingProducts,
  setLoadingProducts,
  isProductResultStep,
  hasNoPermissions,
  setHasNoPermissions,
}) => {
  const { setValue, watch } = useFormContext();
  const [, { getSettingsByKey }] = useFeatures();
  const [products, productsAppUrl, productCtaType] = watch([
    'products',
    'productsAppUrl',
    'productCtaType',
  ]);
  const [{ magento, shopify, productsImport }, { getAppUsingUrl }] = useApps();
  const { updateAppPermissions, createProducts } = useProductActions();
  const ref = useRef();
  const isFeed = project.feed;
  const productsLimit = getSettingsByKey(FEATURE_QUIZ_PRODUCTS_RESULT)?.productsLimit;
  const [selectedApp, setSelectedApp] = useState(null);

  const setProducts = products => {
    setValue('products', products);
  };

  const handleHasNoPermissions = () => {
    setHasNoPermissions(true);
  };

  const handleRemoveProduct = product => {
    setLoadingProducts(true);
    const { id, productId } = product;
    track('Products Section Product Removed', {
      id,
      productId,
    });

    setProducts(products.filter(({ productId }) => productId !== product.productId));
    setLoadingProducts(false);
  };

  const handleSearchProductSelect = async product => {
    const { productId } = product;
    setLoadingProducts(true);
    track('Products Section Product Added', {
      productId,
    });

    if (products.find(product => product.productId === productId)) {
      setLoadingProducts(false);
      return;
    }

    if (isFeed && !productCtaType) {
      if (magento) {
        setValue('productCtaType', PRODUCT_CTA_TYPES.buyNow);
      } else if (shopify) {
        setValue('productCtaType', PRODUCT_CTA_TYPES.addToCart);
      } else {
        setValue('productCtaType', VIEW_PRODUCT);
      }
    }

    let newProduct = { ...product };
    if (shopify || magento || productsImport) {
      const [createdProduct] = await createProducts([productId], productsAppUrl);
      newProduct = createdProduct;
    }

    setProducts([...products, newProduct]);
    setTimeout(() => {
      safeScrollIntoViewIfNeeded(ref.current);
      setLoadingProducts(false);
    }, 0);
  };

  const updatePermissionsClicked = () => {
    if (!selectedApp) {
      return;
    }

    const { appUrl } = selectedApp;

    updateAppPermissions(appUrl);
    setHasNoPermissions(false);
  };

  const setProductCtaType = value => {
    setValue('productCtaType', value);
  };

  useEffect(() => {
    if (!project || (!shopify && !productsAppUrl)) {
      return;
    }

    setSelectedApp(getAppUsingUrl(project?.appUrl || productsAppUrl));
  }, [productsAppUrl, project.appUrl, shopify]);

  if (hasNoPermissions) {
    return (
      <SearchContainer>
        <NoProductsPermissions updatePermissionsClicked={updatePermissionsClicked} />
      </SearchContainer>
    );
  }

  const showSearchContainer =
    !isProductResultStep || (isProductResultStep && products?.length < productsLimit);
  return (
    <SearchProductsDisplay
      isLoadingProducts={isLoadingProducts}
      showSearchContainer={showSearchContainer}
      products={products}
      selectedApp={selectedApp}
      handleSearchProductSelect={handleSearchProductSelect}
      handleHasNoPermissions={handleHasNoPermissions}
      handleRemoveProduct={handleRemoveProduct}
      project={project}
      scrollAnchorRef={ref}
      productCtaType={productCtaType}
      setProductCtaType={setProductCtaType}
    />
  );
};

const SearchContainer = styled.div`
  padding: 12px 16px 0;
`;

export default EditProductsButtons;
