import React from 'react';
import PlayArrowIcon from 'app/src/images/PlayArrowIcon';
import styled from 'styled-components';
import Utils from 'app/src/utils';
import { AnalyticsPlayerOverlayContentProps } from './AnalyticsPlayer.types';
import { secondsToMinutesSecondsDisplay } from 'app/src/utils/time.utils';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import HorizontalFlex, {
  HorizontalFlexCentered,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

const OverlayMetrics = ({ className, metrics, video }: AnalyticsPlayerOverlayContentProps) => {
  return (
    <LayoutRoot className={className}>
      <LeftColumn>
        <PlayArrowIcon />
        <Text>{Utils.formatNumberToString(metrics.plays || 0)}</Text>
      </LeftColumn>
      <Text>{secondsToMinutesSecondsDisplay(video.duration || 0)}</Text>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)`
  justify-content: space-between;
  padding: 8px 12px;
`;

const LeftColumn = styled(HorizontalFlexCentered)`
  gap: 4px;
`;

const Text = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  line-height: 12px;
`;

export default OverlayMetrics;
