import { INSTAGRAM, INSTAGRAM_GRAPH, TIKTOK } from 'app/src/constants/intergrations.constants';

export const RECORD_VIDEO = 'recordVideo';
export const UPLOAD_FILE = 'uploadFile';
export const UPLOAD_HERO_FILE = 'uploadHeroFile';
export const RECORD_SCREEN = 'recordScreen';
export const LIBRARY = 'library';
export const TOLSTOY_LIBRARY = 'tolstoyLibrary';
export const START_FROM_TEMPLATE = 'startFromTemplate';
export const STOCK_VIDEOS = 'stockVideos';
export const IMPORT_FROM_TIKTOK = 'importFromTiktok';
export const IMPORT_FROM_SYNTHESIA = 'importFromSynthesia';
export const IMPORT_FROM_INSTAGRAM = 'importFromInstagram';
export const IMPORT_FROM_GOOGLE_DRIVE = 'importFromGoogleDrive';
export const IMPORT_FROM_DROPBOX = 'importFromDropbox';
export const IMPORT_FROM_YOUTUBE = 'importFromYoutube';
export const VIDEO_INPUT = 'videoInput';
export const AUDIO_INPUT = 'audioInput';
export const AI_VIDEO = 'aiVideo';
export const RECORDING_MINIMUM_FILE_SIZE = 0.1;
export const INPUT_METHODS = {
  text: 'text',
  select: 'select',
  radio: 'radio',
};

export const METHOD_BY_APPS = {
  [TIKTOK]: IMPORT_FROM_TIKTOK,
  [INSTAGRAM]: IMPORT_FROM_INSTAGRAM,
  [INSTAGRAM_GRAPH]: IMPORT_FROM_INSTAGRAM,
};

export enum CreationMethod {
  instagram = 'importFromInstagram',
  tiktok = 'importFromTiktok',
  recordVideo = 'recordVideo',
  uploadFile = 'uploadFile',
  recordScreen = 'recordScreen',
  library = 'library',
  tolstoyLibrary = 'tolstoyLibrary',
  startFromTemplate = 'startFromTemplate',
  stockVideos = 'stockVideos',
  aiVideo = 'aiVideo',
}
