import { useEffect, useState } from 'react';
import { useApps } from 'app/src/context/AppsStore';
import { SHOPIFY } from 'app/src/constants/intergrations.constants';
import { SHOPIFY_ACCESS_SCOPES } from 'app/src/constants/shopify.constants';

const usePrimaryShopifyApp = () => {
  const [{ apps, initialized, loading }] = useApps();
  const [hasUpdatedAccessScopes, setHasUpdatedAccessScopes] = useState(true);
  const [primaryShopifyApp, setPrimaryShopifyApp] = useState(null);

  const getHasUpdatedAccessScopes = () => {
    const appData = JSON.parse(primaryShopifyApp.data || '{}');

    return SHOPIFY_ACCESS_SCOPES.every(scope => appData?.accessScopes?.includes(scope));
  };

  const getSortedAppsByOldest = apps => {
    return [...apps].sort((a, b) => {
      return new Date(a.createdAt) - new Date(b.createdAt);
    });
  };

  const getPrimaryShopifyApp = () => {
    const shopifyApps = apps.filter(({ app }) => app === SHOPIFY);

    const sortedShopifyApps = getSortedAppsByOldest(shopifyApps);

    return sortedShopifyApps[0];
  };

  useEffect(() => {
    if (!initialized || loading) {
      return;
    }

    setPrimaryShopifyApp(getPrimaryShopifyApp());
  }, [initialized, loading, apps]);

  useEffect(() => {
    if (!primaryShopifyApp) {
      return;
    }

    setHasUpdatedAccessScopes(getHasUpdatedAccessScopes());
  }, [primaryShopifyApp]);

  return { primaryShopifyApp, hasUpdatedAccessScopes };
};

export default usePrimaryShopifyApp;
