import { css } from 'styled-components';

export const PublishModalBaseCss = css`
  position: fixed;
  right: 24px;
  padding: 16px;
  z-index: 100;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
`;
