import { useState, useEffect } from 'react';
import Utils from 'src/utils';
import { useUser } from 'src/context/userStore/UserStore';
import { useFeatureActions } from 'src/context/FeaturesStore';
import { FEATURE_WHITE_LABEL } from 'shared/react/constants/features.constants';

const DEFAULT_BRANDING_LOGO_SETTINGS = {
  logoUrl: '',
  playerLogoUrl: '',
  showAccountLogo: false,
};

interface BrandingLogoSettings {
  logoUrl: string;
  playerLogoUrl: string;
  showAccountLogo: boolean;
}

interface UseBrandingLogoSettingsResult {
  logoSettings: BrandingLogoSettings;
  saveLogoSettings: (logoSettings: BrandingLogoSettings) => void;
}

export const useBrandingLogoSettings = (): UseBrandingLogoSettingsResult => {
  const [logoSettings, setLogoSettings] = useState(DEFAULT_BRANDING_LOGO_SETTINGS);
  const [{ account, loading: isAccountLoading }, { updateAccountSettings }] = useUser();
  const { getSettingsByKey, updateAccountFeatureSettings, getFeatureEnabled } = useFeatureActions();
  const isWhiteLabelEnabled = getFeatureEnabled(FEATURE_WHITE_LABEL);

  useEffect(() => {
    if (isAccountLoading) {
      return;
    }

    setLogoSettings({
      logoUrl: Utils.getLogoUrl(account?.logoUrl),
      playerLogoUrl: account?.playerLogoUrl,
      showAccountLogo: account?.playerLogoUrl,
    });
  }, [account]);

  const saveLogoSettings = (update: BrandingLogoSettings): Promise<any[]> => {
    const { logoUrl, playerLogoUrl, showAccountLogo: newShowAccountLogo } = update;
    const { showAccountLogo } = getSettingsByKey(FEATURE_WHITE_LABEL) || {};
    const promises = [];

    promises.push(updateAccountSettings({ logoUrl, playerLogoUrl }));

    if (showAccountLogo !== newShowAccountLogo && isWhiteLabelEnabled) {
      const featureToUpdate = {
        [FEATURE_WHITE_LABEL]: {
          showAccountLogo: newShowAccountLogo,
        },
      };

      promises.push(updateAccountFeatureSettings(featureToUpdate));
    }

    return Promise.all(promises);
  };

  return {
    logoSettings,
    saveLogoSettings,
  };
};
