import React from 'react';
import { useParams } from 'react-router-dom';
import {
  FEATURE_APP_PRICING,
  FEATURE_ECOM_FEED,
  FEATURE_WIDGET_AUTOPLAY,
} from 'app/src/constants/appFeatures.constants';
import { useFeatures } from 'app/src/context/FeaturesStore';
import Routes from '../../../helpers/Routes';
import { useBilling } from 'app/src/hooks/useBillings';
import styled, { useTheme } from 'styled-components';
import { isDateFromTheLastSevenDays } from 'app/src/utils/date.utils';
import { navigateToPlans, navigateToWidgetSettings } from 'app/src/utils/navigation.utils';
import EcomFeedBadge from './EcomFeedBadge';
import PromotionBadge from './PromotionBadge';
export const BADGE_CLOSED = '-badge-closed';

const PromotionBadges = ({ tab }) => {
  const [, { getFeatureEnabled, getSettingsByKey }] = useFeatures();
  const { projectId } = useParams();
  const { lockTolstoyCreation } = useBilling();
  const theme = useTheme();
  const shouldAddPaddingTop = tab !== Routes.getDashboardBaseRoute();

  const isFeatureBadgeClosed = featureKey => {
    const previousCloseActionDate = localStorage.getItem(featureKey + BADGE_CLOSED);
    if (!previousCloseActionDate) {
      return false;
    }

    if (isDateFromTheLastSevenDays(previousCloseActionDate)) {
      return true;
    }

    localStorage.removeItem(featureKey + BADGE_CLOSED);
    return false;
  };

  const shouldShowEcomFeedBadge =
    !projectId &&
    !lockTolstoyCreation &&
    getFeatureEnabled(FEATURE_ECOM_FEED) &&
    getSettingsByKey(FEATURE_ECOM_FEED)?.showBanner &&
    !isFeatureBadgeClosed(FEATURE_ECOM_FEED);

  const shouldShowPricingBanner =
    !projectId &&
    getFeatureEnabled(FEATURE_APP_PRICING) &&
    getSettingsByKey(FEATURE_APP_PRICING)?.showBanner &&
    !isFeatureBadgeClosed(FEATURE_APP_PRICING);

  const shouldShowAutoplayBadge = false;

  if (shouldShowEcomFeedBadge) {
    return <EcomFeedBadge tab={tab} />;
  }

  if (shouldShowAutoplayBadge) {
    return (
      <AutoplayPromotionBadge>
        <PromotionBadge
          title="Triple your number of viewers"
          subtitle="by having your widget open automatically"
          navigateBlue={props => navigateToWidgetSettings(...props)}
          textBlue="Try it out"
          featureKey={FEATURE_WIDGET_AUTOPLAY}
        />
      </AutoplayPromotionBadge>
    );
  }

  if (shouldShowPricingBanner) {
    return (
      <AutoplayPromotionBadge shouldAddPaddingTop={shouldAddPaddingTop}>
        <PromotionBadge
          title="Special offer"
          subtitle="We just launched new pricing plans and have a special offer for you"
          navigateBlue={navigateToPlans}
          textBlue="Check it out"
          featureKey={FEATURE_APP_PRICING}
          backgroundColor={theme.colors.pink6}
          showIcon={false}
        />
      </AutoplayPromotionBadge>
    );
  }

  return null;
};

export default PromotionBadges;

const AutoplayPromotionBadge = styled.div`
  margin-bottom: 12px;
  padding-top: ${({ shouldAddPaddingTop }) => (shouldAddPaddingTop ? '16px' : '')};
`;
