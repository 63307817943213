import React, { useEffect } from 'react';
import ShopifyStoreModal from 'app/src/pages/modals/ShopifyStoreModal';
import usePrimaryShopifyApp from 'app/src/hooks/usePrimaryShopifyApp';
import { HAS_SEEN_SHOPIFY_REINSTALL_MODAL_KEY } from 'app/src/constants/shopify.constants';
import { getUrlWithParams } from 'app/src/utils/url.utils';
import { INSTALL_SHOPIFY_URL } from 'app/src/config/app.config';

const ReinstallShopifyModal = () => {
  const { primaryShopifyApp } = usePrimaryShopifyApp();

  const onPrimaryButtonClick = () => {
    const { appUrl, appKey } = primaryShopifyApp;

    const installUrl = getUrlWithParams(INSTALL_SHOPIFY_URL, {
      shop: appUrl,
      appKey,
    });

    window.open(installUrl, '_self');
  };

  useEffect(() => {
    sessionStorage.setItem(HAS_SEEN_SHOPIFY_REINSTALL_MODAL_KEY, 'true');
  }, []);

  return (
    <ShopifyStoreModal
      title="Reinstall Shopify integration"
      subtitle={
        "We've updated our Shopify app permissions. Please reinstall the Tolstoy app in order to enjoy the best of Tolstoy."
      }
      primaryButtonText="Reinstall"
      onPrimaryButtonClick={onPrimaryButtonClick}
    />
  );
};

export default ReinstallShopifyModal;
