import { useProjectActions } from 'app/src/context/ProjectsStore';
import Routes, { projectTabs } from 'app/src/helpers/Routes';
import { useHistory } from 'react-router-dom';
import Utils from 'src/utils';
import { publishMethodOptions } from 'app/src/types/entities';
import { serializeAnalyticsSearchParams } from 'app/src/complex_components/analytics/analyticsSearchParams';

const useNavigation = () => {
  const { getProjectById } = useProjectActions();
  const history = useHistory();
  const locationState = history?.location?.state;

  const currentPathName = history.location.pathname;

  const push = (route, locationState = {}) => {
    history.push(route, locationState);
  };

  const getBackToEditorState = path => {
    return { backToEditorLocation: path };
  };

  const getProjectTabPath = (projectId, tab, folderId?) => {
    let currentLocation = currentPathName;
    if (currentLocation.includes(projectId)) {
      const currentPathWithoutProject = currentLocation.split(`/${projectId}`)[0];
      return `${currentPathWithoutProject}/${projectId}/${tab}`;
    }

    if (currentLocation === Routes.getDashboardV2OnsiteCreateCaseRoute()) {
      currentLocation = Routes.getDashboardV2OnsiteRoute(folderId || 'all');
    }

    if (currentLocation === Routes.getDashboardV2OffsiteCreateCaseRoute()) {
      currentLocation = Routes.getDashboardV2OffsiteRoute(folderId || 'all');
    }

    if (currentLocation.endsWith('/')) {
      currentLocation = currentLocation.slice(0, -1);
    }

    return `${currentLocation}/${projectId}/${tab}`;
  };

  const navigateToProjectTab = (project, tab) => {
    const location = getProjectTabPath(project.id, tab, project.folder);
    push(location, locationState);
  };

  const getProjectPathWithCategory = projectId => {
    const project = getProjectById(projectId);

    if (project?.publishMethod === publishMethodOptions.shopApp) {
      return Routes.getShopRoute();
    }

    return Utils.isOnsite(project)
      ? Routes.getDashboardV2OnsiteRoute('all')
      : Routes.getDashboardV2OffsiteRoute('all');
  };

  const getAccountAnalyticsRoute = ({ projectIds = [], tab = null } = {}) => {
    projectIds = projectIds?.filter(Boolean);
    const analyticsRoute = Routes.getAccountAnalyticsRoute();

    if (!projectIds?.length && !tab) {
      return analyticsRoute;
    }

    const publishIds = projectIds.map(id => getProjectById(id)?.publishId).filter(Boolean);
    const analyticsSearchParams = serializeAnalyticsSearchParams({ publishIds, tab });

    return `${analyticsRoute}?${analyticsSearchParams}`;
  };

  const getProjectRootRoute = projectId => {
    const pathWithCategory = getProjectPathWithCategory(projectId);
    return `${pathWithCategory}/${projectId}`;
  };

  const navigateToProjectResponses = projectId => {
    const pathWithCategory = getProjectPathWithCategory(projectId);
    push(`${pathWithCategory}/${projectId}/${projectTabs.responses}`);
  };

  const navigateToDashboardV2Videos = (source = '', state = undefined) => {
    push(Routes.getDashbaordV2VideosRoute(source), state);
  };

  const navigateToDashboardLibrary = (source = '', state = undefined) => {
    push(Routes.getDashboardLibraryRoute(source), state);
  };

  const navigateToIntegrations = focusedItem => {
    push(Routes.getDashboardV2IntegrationsRoute(focusedItem));
  };

  const navigateToDashboardV2Onsite = (route = 'all') => {
    push(Routes.getDashboardV2OnsiteRoute(route));
  };

  const navigateToDashboardOnsiteCreateCase = () => {
    push(Routes.getDashboardV2OnsiteCreateCaseRoute());
  };

  const navigateToDashboardV2Offsite = (route = 'all') => {
    push(Routes.getDashboardV2OffsiteRoute(route));
  };

  const navigateToDashboardOffsiteCreateCase = () => {
    push(Routes.getDashboardV2OffsiteCreateCaseRoute());
  };

  const navigateToShopStoreFeeds = () => {
    push(Routes.getShopRoute());
  };

  const navigateToShopHomeFeed = () => {
    push(Routes.getShopHomeFeedRoute());
  };

  const navigateToDashboardV2Threads = (route = '') => {
    push(Routes.getDashboardV2ThreadsRoute(route));
  };

  const navigateToDashboardV2MyProfile = () => {
    push(Routes.getDashboardV2MyProfileBaseRoute());
  };

  const navigateToDashboardV2Analytics = () => {
    push(Routes.getDashboardV2AnalyticsRoute());
  };

  const navigateToInbox = () => {
    push(Routes.getInboxBaseRoute());
  };

  const navigateToAccountAnalytics = ({ projectIds = [], tab = null } = {}) => {
    const analyticsRoute = getAccountAnalyticsRoute({ projectIds, tab });
    return push(analyticsRoute);
  };

  const navigateToAccountInstall = (queryString = '') => {
    push(
      `${Routes.getDashboardInstallationRoute()}${queryString}`,
      getBackToEditorState(currentPathName)
    );
  };
  const navigateToRoot = () => {
    push(Routes.getRootRoute());
  };

  const navigateToMyProfile = () => {
    push(Routes.getMyProfileRoute());
  };

  const navigateToCreateWorkspaceMessage = () => {
    return push(Routes.getTolstoyMessageCreateWorkspaceRoute());
  };

  const navigateToBuilder = (projectId, stepId) => {
    return push(
      `${Routes.getDashboardBaseRoute()}/${projectId}/builder${stepId ? `/${stepId}` : ''}`
    );
  };

  const navigateToPreviousPage = () => {
    history.goBack();
  };

  const navigateToPreviousBuilderPage = backToEditorLocation => {
    push(backToEditorLocation);
  };

  const navigateToHomepage = () => {
    push(Routes.getOnboardingTemplatesRoute());
  };

  const navigateToOnboardingTemplates = () => {
    push(Routes.getOnboardingTemplatesRoute());
  };

  const navigateToProducts = () => {
    push(Routes.getProductsRoute());
  };

  const navigateToAiTagging = () => {
    push(Routes.getAiTaggingRoute());
  };

  const navigateToOnboardingTemplateStage = stageKey => {
    push(Routes.getOnboardingTemplatesStageRoute(stageKey));
  };

  const navigateToProjectDesign = projectId => {
    const pathWithCategory = getProjectPathWithCategory(projectId);
    push(`${pathWithCategory}/${projectId}/${projectTabs.design}`);
  };

  const navigateToProjectInstallation = projectId => {
    const pathWithCategory = getProjectPathWithCategory(projectId);
    push(`${pathWithCategory}/${projectId}/${projectTabs.installation}`);
  };

  const navigateToProjectVideos = projectId => {
    const pathWithCategory = getProjectPathWithCategory(projectId);
    push(`${pathWithCategory}/${projectId}/`);
  };

  const navigateToAudience = () => {
    push(Routes.getAudienceRoute());
  };

  const navigateToUgc = (source = '') => {
    push(Routes.getDashbaordV2UgcRoute(source));
  };

  return {
    navigateToUgc,
    navigateToDashboardV2Videos,
    navigateToDashboardLibrary,
    navigateToDashboardV2Onsite,
    navigateToDashboardOnsiteCreateCase,
    navigateToDashboardOffsiteCreateCase,
    navigateToDashboardV2Offsite,
    navigateToDashboardV2Analytics,
    navigateToDashboardV2Threads,
    navigateToShopStoreFeeds,
    navigateToShopHomeFeed,
    navigateToInbox,
    getAccountAnalyticsRoute,
    navigateToAccountAnalytics,
    navigateToIntegrations,
    navigateToRoot,
    navigateToMyProfile,
    navigateToCreateWorkspaceMessage,
    navigateToBuilder,
    navigateToProjectTab,
    getProjectTabPath,
    navigateToDashboardV2MyProfile,
    navigateToAccountInstall,
    navigateToProjectResponses,
    navigateToProjectDesign,
    navigateToProjectInstallation,
    navigateToProjectVideos,
    navigateToPreviousPage,
    navigateToHomepage,
    navigateToProducts,
    navigateToAiTagging,
    navigateToOnboardingTemplates,
    navigateToOnboardingTemplateStage,
    getBackToEditorState,
    navigateToPreviousBuilderPage,
    navigateToAudience,
    getProjectRootRoute,
    currentPathName,
    locationState,
  };
};

export default useNavigation;
