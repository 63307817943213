import React from 'react';
import styled from 'styled-components';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import Tab from './Tab';
import { track } from 'app/src/helpers/Tracker';

const Tabs = ({ tabs, selectedTab, setSelectedTab, location, isSmall }) => {
  const onTabChanged = tab => {
    if (tab === selectedTab) {
      return;
    }

    track('Tab Click', { tab, location });
    setSelectedTab(tab);
  };

  return (
    <LayoutRoot>
      {tabs.map(({ key, badgeText, dataTestId, name }) => (
        <Tab
          key={key}
          tab={key}
          isSelected={selectedTab === key}
          isSmall={isSmall}
          onTabChanged={onTabChanged}
          badgeText={badgeText}
          dataTestId={dataTestId}
          name={name}
        />
      ))}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlexWrap)`
  gap: 24px;
  flex-wrap: nowrap;

  @media (${({ theme }) => theme.breakpoints.dashboardTabletMax}) {
    flex-wrap: unset;
    overflow: visible;
    padding-bottom: 8px;
  }
`;

export default Tabs;
