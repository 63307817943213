import React from 'react';
import useFeedFeatures from 'shared/react/components/complex/context/hooks/useFeedFeatures';
import useFeedState from 'shared/react/components/complex/context/hooks/useFeedState';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';
import FeedBrandedButton from 'shared/react/components/complex/feed/feed_buttons/FeedBrandedButton';
import FeedItemAdded from 'shared/react/components/complex/feed/feed_products/feed_buy_now_button/FeedItemAdded';
import FeedQuickAddButtonContent from 'shared/react/components/complex/feed/feed_products/feed_multiple_products/feed_quick_add_button/FeedQuickAddButtonContent';
import {
  FEATURE_FEED_HIDE_BUY_NOW_BUTTON,
  FEATURE_FEED_OPEN_PDP_IN_SAME_TAB,
} from 'shared/react/constants/features.constants';
import { PRODUCT_CTA_TYPES } from 'shared/react/constants/feed.constants';
import {
  ADD_TO_CART_ERROR_MESSAGE,
  ADD_TO_CART_SOLD_OUT_MESSAGE,
  ADD_TO_CART_SUCCESS_MESSAGE,
} from 'shared/react/constants/shopifyMessaging.constants';
import { isProductsImportProvider, isUrlProvider } from 'shared/react/utils/feedProducts.utils';
import Utils from 'shared/react/utils/utils';
import { BUY_NOW_DATA_TEST_ID } from '../../../../../constants/dataTestIds.constants';
import useFeedProductButton from '../../hooks/useFeedProductButton';
import useIsFenty from 'shared/react/hooks/useIsFenty';

const FeedBuyNowButton = ({
  selectedVariantId,
  isVariantAvailableForSale,
  isDisabled,
  appUrl,
  currentStepKey,
  isQuickAdd,
  productHandle,
  ecomPlatform,
  onBuyNowClick,
  productId,
  quantityPickerState,
  handleProductFocused,
  isViewProduct,
  externalProductId,
  ...props
}) => {
  const [, { t }] = useTranslation('feed.buyNowButton');
  const [{ isAppPreview }, { getCurrentStep }] = useProjectConfig();
  const [{ shopifyResponse, setShopifyResponse }] = useFeedState();
  const [, { getFeatureSettingsByKey }] = useFeedFeatures();
  const currentStep = getCurrentStep(currentStepKey);
  const { productCtaType } = currentStep;
  const isFenty = useIsFenty();
  const isAddToCart =
    productCtaType === PRODUCT_CTA_TYPES.addToCart && !isViewProduct && !Utils.getIsLandingPage();
  const isOpenProductPage = productCtaType === PRODUCT_CTA_TYPES.openProductPage;
  const isUrl = isUrlProvider(ecomPlatform);
  const isProductsImport = isProductsImportProvider(ecomPlatform);
  const { shouldHide } = getFeatureSettingsByKey(FEATURE_FEED_HIDE_BUY_NOW_BUTTON);
  const { isOpenPdpInSameTab } = getFeatureSettingsByKey(FEATURE_FEED_OPEN_PDP_IN_SAME_TAB);
  const { markUserAndRedirect, handleAddToCart, handleOpenInTheSameTab, isLoading } =
    useFeedProductButton({
      selectedVariantId,
      appUrl,
      currentStepKey,
      productHandle,
      ecomPlatform,
      productId,
      ctaType: productCtaType,
      externalProductId,
    });

  const onClick = () => {
    if (isViewProduct) {
      handleProductFocused();
      return;
    }

    if (isAppPreview) {
      return;
    }

    if (isOpenPdpInSameTab) {
      return handleOpenInTheSameTab();
    }

    if (onBuyNowClick) {
      onBuyNowClick();
      return;
    }

    if (isUrl) {
      return markUserAndRedirect('clickViewProduct');
    }

    if (isAddToCart) {
      return handleAddToCart(quantityPickerState?.quantity);
    }

    if (isOpenProductPage) {
      return markUserAndRedirect('openProductPageClick');
    }

    return markUserAndRedirect('clickCart');
  };

  const getShopifyResponseButtonText = () => {
    const { eventName, variantId } = shopifyResponse;
    if (variantId !== selectedVariantId) {
      return getButtonText(true);
    }

    setTimeout(() => {
      setShopifyResponse(null);
    }, 5000);

    switch (eventName) {
      case ADD_TO_CART_SUCCESS_MESSAGE:
        return <FeedItemAdded />;
      case ADD_TO_CART_SOLD_OUT_MESSAGE:
        if (isFenty) {
          return `${t('soldOutShopify')}`.toUpperCase();
        }
        return t('soldOutShopify');
      case ADD_TO_CART_ERROR_MESSAGE:
        return t('errorAddingToCart');
    }
  };

  const getButtonText = ignoreShopifyResponse => {
    if (isViewProduct) {
      return t('viewProduct');
    }

    if (isUrl) {
      return t('viewProduct');
    }

    if (isOpenProductPage) {
      return t(PRODUCT_CTA_TYPES.openProductPage);
    }

    if (shopifyResponse && !ignoreShopifyResponse) {
      return getShopifyResponseButtonText();
    }
    if (!isVariantAvailableForSale) {
      return t('soldOut');
    }

    if (isProductsImport) {
      return t('viewProduct');
    }

    if (!isAddToCart) {
      return t('buyNow');
    }

    if (isQuickAdd) {
      return <FeedQuickAddButtonContent />;
    }

    return t('addToCart');
  };

  if (shouldHide) {
    return null;
  }

  return (
    <FeedBrandedButton
      onClick={onClick}
      buttonText={getButtonText()}
      isLoading={isLoading()}
      isDisabled={isDisabled && !isUrl}
      dataTestId={BUY_NOW_DATA_TEST_ID}
      quantityPickerState={quantityPickerState}
      isAddToCart={isAddToCart}
      {...props}
    />
  );
};

export default FeedBuyNowButton;
