import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Stepper from 'app/src/complex_components/Stepper';
import { TextBodyLeadingBold, TextSmall } from 'shared/react/components/complex/Text';
import WidgetSettings from 'app/src/pages/modals/publish/widget/WidgetSettings';
import Utils from 'app/src/utils';
import { track } from 'app/src/helpers/Tracker';
import FeedEmbedWidgetsShopifyStepperOtherPages from 'app/src/pages/modals/publish/embed/feed_embed_widgets/FeedEmbedWidgetsShopifyStepperOtherPages';
import FeedEmbedWidgetsShopifyStepperProductPage from 'app/src/pages/modals/publish/embed/feed_embed_widgets/feed_embed_widgets_shopify_products/FeedEmbedWidgetsShopifyStepperProductPage';
import InfoFullIcon from 'app/src/images/InfoFullIcon';
import { Gap16HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { EMBED_WIDGETS_NAMES } from 'app/src/constants/ui.constants';
import CopyTextField from 'app/src/complex_components/CopyTextField';
import { PAGE_TYPES } from 'app/src/constants/publish.constants';
import { useProductPageSettingsActions } from 'app/src/context/ProductPageSettingsStore';
import SetLiveToggle from './SetLiveToggle';
import usePublishFeature from './hooks/usePublishFeature';

const FeedEmbedWidgetsShopifyStepper = ({
  handleCustomizeAppearance,
  setSnackbar,
  project,
  widgetType,
  getProductCode,
  getCode,
  ...props
}) => {
  const { isProductPage } = useProductPageSettingsActions();
  const [currentPage, setCurrentPage] = useState(PAGE_TYPES.default);
  const { shouldShowPublishToggle } = usePublishFeature(project);
  const isPdp = currentPage === PAGE_TYPES.product;
  const widgetName = EMBED_WIDGETS_NAMES[widgetType];
  const { dynamic: isDynamic } = project;

  const copyPublishId = () => {
    Utils.copyToClipboard(project.publishId);
    setSnackbar('The code copied to your clipboard!');
    track(`Copy Embed ${Utils.upperCaseFirstChar(widgetType)} Publish Id Click`, { isDynamic });
  };

  const getContent = () => {
    const contentProps = { project, ...props, copyPublishId, widgetType };
    if (currentPage === PAGE_TYPES.default || project.dynamic) {
      return <FeedEmbedWidgetsShopifyStepperOtherPages {...contentProps} />;
    }

    return <FeedEmbedWidgetsShopifyStepperProductPage {...contentProps} />;
  };

  useEffect(() => {
    if (!project) {
      return;
    }

    if (project.dynamic) {
      setCurrentPage(PAGE_TYPES.default);
      return;
    }

    const isPDP = isProductPage(project);

    setCurrentPage(isPDP ? PAGE_TYPES.product : PAGE_TYPES.default);
  }, [project]);

  const getFirstStep = () => {
    if (isDynamic) {
      return (
        <Step key={1}>
          <TextBodyLeadingBold>Place {widgetType} in theme</TextBodyLeadingBold>
          <TextSmall>
            Go to Shopify themes → Products → <b>Default product</b> → ‘Add section’ → ‘Tolstoy{' '}
            {widgetType}‘
          </TextSmall>
        </Step>
      );
    }
  };

  const code = isPdp ? getProductCode() : getCode();
  const steps = [
    getFirstStep(),
    getContent(),
    <Step key="setLiveToggle" disabled={!shouldShowPublishToggle}>
      <SetLiveToggle project={project} widgetType={widgetType} />
    </Step>,
  ];
  const filteredSteps = steps.filter(step => step !== null);

  return (
    <LayoutRoot>
      <Stepper stepComponents={filteredSteps} />
      <HavingTroubleNote>
        <StyledInfoIcon />
        <NoteText>
          <Text>
            If you can’t find “Tolstoy {Utils.capitalizeFirstLetter(widgetName)}” in your shopify
            sections copy the following code:
          </Text>
          <CopyInput value={code} putCopyInEnd={true} disabled />
          <Text>and paste it your PDPs liquid, in the position you desire.</Text>
        </NoteText>
      </HavingTroubleNote>
      <WidgetSettings handleCustomizeAppearance={handleCustomizeAppearance} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div``;

const Step = styled(VerticalFlex)`
  gap: 12px;
`;

const NoteText = styled(VerticalFlex)`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  gap: 12px;
`;

const Text = styled(NoteText)``;

const CopyInput = styled(CopyTextField)`
  .MuiInputBase-input {
    color: ${({ theme }) => theme.colors.gray34};
    font-size: 12px;
    padding: 12px;
  }

  .MuiInputBase-root {
    border: ${({ theme }) => theme.colors.gray38};
    background: ${({ theme }) => theme.colors.gray37};
    border-radius: 10px;
  }
`;

const StyledInfoIcon = styled(InfoFullIcon)`
  min-width: 20px;
  min-height: 20px;
`;

const HavingTroubleNote = styled(Gap16HorizontalFlex)`
  box-sizing: border-box;
  align-items: flex-start;
  padding: 16px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.ghostLight};
  border-radius: 8px;
  margin-top: 16px;
`;

export default FeedEmbedWidgetsShopifyStepper;
