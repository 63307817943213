import React from 'react';
import styled from 'styled-components';
import { RIGHT } from 'shared/react/components/complex/carousel_slider/carouselSlider.constants';
import HorizontalExpanderIcon from './HorizontalExpanderIcon';

const CarouselSliderArrowIcon = ({ direction }) => <StyledExpanderIcon direction={direction} />;

const StyledExpanderIcon = styled(HorizontalExpanderIcon)`
  transform: scale(${({ direction }) => (direction === RIGHT ? '-1' : '1')}, 1);
  height: 100%;
  width: 100%;

  & path {
    fill: ${({ theme }) => theme.colors.black};
  }
`;

export default CarouselSliderArrowIcon;
