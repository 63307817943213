import React from 'react';

function Filters() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="12" fill="#F4F5F7" />
      <path
        d="M28.75 19.25H23.75C23.0469 19.25 22.5 19.8359 22.5 20.5C22.5 21.2031 23.0469 21.75 23.75 21.75H28.75C29.4141 21.75 30 21.2031 30 20.5C30 19.8359 29.4141 19.25 28.75 19.25ZM28.75 25.5H23.75C23.0469 25.5 22.5 26.0859 22.5 26.75C22.5 27.4531 23.0469 28 23.75 28H28.75C29.4141 28 30 27.4531 30 26.75C30 26.0859 29.4141 25.5 28.75 25.5ZM27.5 15.5H28.75C29.4141 15.5 30 14.9531 30 14.25C30 13.5859 29.4141 13 28.75 13H27.5C26.7969 13 26.25 13.5859 26.25 14.25C26.25 14.9531 26.7969 15.5 27.5 15.5ZM22.8516 13H10.8594C10.1172 13 9.72656 13.8594 10.1953 14.4062L15 20.0703V25.6562C15 25.8906 15.0781 26.125 15.2734 26.2422L17.6172 27.8828C18.0859 28.2344 18.75 27.8828 18.75 27.2969V20.0703L23.5156 14.4062C23.9844 13.8594 23.5938 13 22.8516 13Z"
        fill="#2F3137"
      />
    </svg>
  );
}

export default Filters;
