import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import CopyLink from 'app/src/complex_components/CopyLink';
import { Checkbox } from '@material-ui/core';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextBodyLeading, TextSmall, TextTiny } from 'shared/react/components/complex/Text';
import SocialShareButtons from 'shared/react/components/complex/SocialShareButtons';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import FormInput from 'app/src/complex_components/FormInput';
import { useHistory } from 'react-router-dom';
import Utils from 'app/src/utils';
import FacebookIcon from 'app/src/images/share/FacebookIcon';
import TwitterIcon from 'app/src/images/share/TwitterIcon';
import LinkedinIcon from 'app/src/images/share/LinkedinIcon';
import WhatsappIcon from 'app/src/images/share/WhatsappIcon';
import { track } from 'app/src/helpers/Tracker';
import { DASHBOARD_TABLET_MAX_WIDTH } from 'shared/react/utils/mediaQuery';
import { getUrlParams } from 'app/src/utils/url.utils';
import { navigateToShareType } from 'app/src/utils/navigation.utils';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import InfoIconWithTooltip from 'app/src/complex_components/InfoIconWithTooltip';
import usePlayerUrl from '../../../hooks/usePlayerUrl';

const SocialShare = ({ project, closeModal, ...props }) => {
  const { playerUrl } = usePlayerUrl(project?.id);
  const history = useHistory();
  const theme = useTheme();

  const [isAddTracking, setIsAddTracking] = useState(true);
  const [email, setEmail] = useState('');

  const extendedPlayerUrl = `${playerUrl}${isAddTracking ? email && `?email=${email}` : ''}`;
  const facebookUrl = Utils.getFacebookShareUrl(extendedPlayerUrl);
  const twitterUrl = Utils.getTwitterShareUrl(extendedPlayerUrl);
  const linkedInUrl = Utils.getLinkedInShareUrl(extendedPlayerUrl);
  const whatsappUrl = Utils.getWhatsappShareUrl(extendedPlayerUrl);
  const urlParams = getUrlParams();
  const isPublish = urlParams.get('share');

  const toggleTracking = () => setIsAddTracking(!isAddTracking);

  const socialButtonsBorderColor = theme.colors.ghostLight;

  const shareButtons = [
    { icon: <FacebookIcon borderColor={socialButtonsBorderColor} />, getUrl: () => facebookUrl },
    { icon: <TwitterIcon borderColor={socialButtonsBorderColor} />, getUrl: () => twitterUrl },
    { icon: <LinkedinIcon borderColor={socialButtonsBorderColor} />, getUrl: () => linkedInUrl },
    { icon: <WhatsappIcon borderColor={socialButtonsBorderColor} />, getUrl: () => whatsappUrl },
  ];

  const trackCopyClick = () => {
    track('Copy Sharable Link Click', { location: 'Share' });
  };

  const shareWithEmailClick = () => {
    closeModal();
    navigateToShareType(history, 'email');
  };

  return (
    <ShareContainer {...props}>
      <LinkContainer>
        <CopyContainer>
          <CopyText>Sharable link</CopyText>
          <CopyLink onCopy={trackCopyClick} value={extendedPlayerUrl} disabled={!project} />
        </CopyContainer>
        <TrackingContainer>
          <AddTrackingContainer>
            <Checkbox size="small" checked={isAddTracking} onChange={toggleTracking} />
            <TrackingText>Add tracking</TrackingText>
            <InfoIconWithTooltip
              title="Keep track of who interacts with your Tolstoy by attaching the viewer's email to the url."
              margin="0 4px"
            />
          </AddTrackingContainer>
          {isAddTracking && (
            <CustomInput
              placeholder="viewer email"
              onChange={e => setEmail(e.target.value)}
              value={email}
            />
          )}
        </TrackingContainer>
        {!isPublish && (
          <TextSmall fontWeight="500">
            Want to send your Tolstoy via email, with a GIF?
            <EmailShareLink onClick={shareWithEmailClick}>Click here</EmailShareLink>
          </TextSmall>
        )}
      </LinkContainer>
      <SocialContainer>
        <SubHeaderText>Share to social media</SubHeaderText>
        <SocialShareButtons shareButtons={shareButtons} canShareLink={() => project?.publishId} />
      </SocialContainer>
    </ShareContainer>
  );
};

const ShareContainer = styled(VerticalFlex)`
  gap: 40px;

  @media (${DASHBOARD_TABLET_MAX_WIDTH}) {
    flex-wrap: unset;
    overflow: auto;
  }
`;

const LinkContainer = styled(Gap16VerticalFlex)``;

const TrackingContainer = styled(Gap8VerticalFlex)`
  flex-wrap: nowrap;

  .MuiCheckbox-root {
    padding-left: 0;
  }
`;

const AddTrackingContainer = styled(HorizontalFlexWrap)`
  align-items: center;
  min-height: 40px;
  flex-wrap: nowrap;
`;

const CopyContainer = styled.div``;

const CopyText = styled(TextTiny)`
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 2px;
`;

const TrackingText = styled(TextSmall)`
  margin-top: 2px;
  width: max-content;
`;

const CustomInput = styled(FormInput)`
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  min-width: 200px;
  max-width: 300px;
`;

const SocialContainer = styled.div``;

const SubHeaderText = styled(TextBodyLeading)`
  font-weight: 700;
`;

const EmailShareLink = styled.button`
  border: none;
  background: unset;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.blue1};
  font-weight: bold;
`;

export default SocialShare;
