import React from 'react';
import styled from 'styled-components';
import FeedCartEmptyState from './FeedCartEmptyState';
import FeedFocusedProduct from 'shared/react/components/complex/feed/feed_products/feed_multiple_products/FeedFocusedProduct';
import FeedCartItem from './FeedCartItem';
import useFeedState from 'shared/react/components/complex/context/hooks/useFeedState';
import useTracker from '../../context/hooks/useTracker';
import { CART_ITEM_QUANTITY_CHANGE } from '../../../../constants/shopifyMessaging.constants';
import { PLAYER_TYPES } from '../../../../constants/tolstoy.constants';
import Utils from 'shared/react/utils/utils';
import VerticalFlex from '../../flex_layouts/VerticalFlex';
import { EVENTS_NAMES } from 'shared/react/constants/events.constants';

const FeedCartBody = ({
  cartItems,
  onClickBack,
  focusedProduct,
  setFocusedProduct,
  currentStepKey,
  isMobile,
  getDisplayedPriceByCents,
  isEmptyState,
}) => {
  const [, { track }] = useTracker();
  const [{ messagingRequestDetails, setMessagingRequestDetails }] = useFeedState();

  const handleUpdateItemQuantity = (variantId, newQuantity, shopifyActionKey) => {
    track(EVENTS_NAMES.CART_ITEM_QUANTITY_CHANGE, {
      ...Utils.getAnalyticsLocationObject(PLAYER_TYPES.feed),
      text: variantId,
    });
    setMessagingRequestDetails({ action: shopifyActionKey, variantId: variantId });

    Utils.postMessageToWindow({
      eventName: CART_ITEM_QUANTITY_CHANGE,
      variantId,
      quantity: newQuantity,
    });
  };

  if (isEmptyState) {
    return <FeedCartEmptyState onClickBack={onClickBack} />;
  }

  if (focusedProduct) {
    return (
      <FeedFocusedProduct
        focusedProduct={focusedProduct}
        setFocusedProduct={setFocusedProduct}
        currentStepKey={currentStepKey}
        isFullWidth={isMobile}
        disableZoomIn={true}
      />
    );
  }

  return (
    <LayoutRoot>
      {cartItems.map((item, index) => (
        <FeedCartItem
          key={index}
          item={item}
          messagingRequestDetails={messagingRequestDetails}
          onUpdateItemQuantity={handleUpdateItemQuantity}
          setFocusedProduct={setFocusedProduct}
          getDisplayedPriceByCents={getDisplayedPriceByCents}
        />
      ))}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  gap: 40px;
  flex-grow: 1;
  padding: 8px 24px 24px 24px;
  overflow: auto;
`;

export default FeedCartBody;
