import React from 'react';
import ColorInput from 'app/src/complex_components/color-input/ColorInput';
import EditorItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorItem';
import styled from 'styled-components';

function EditorColorInputItem({ text, ...props }) {
  return (
    <EditorItem text={text}>
      <CustomColorInput {...props} />
    </EditorItem>
  );
}

const CustomColorInput = styled(ColorInput)`
  max-width: 196px;

  .MuiOutlinedInput-root {
    height: 40px;
  }
`;

export default EditorColorInputItem;
