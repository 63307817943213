import React from 'react';
import ChartStatsEmptyState from './ChartStatsEmptyState';
import styled from 'styled-components';
import Tooltip from 'app/src/basic_components/Tooltip';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';

type RowDefinition = {
  formattedValue: React.ReactNode;
  name: string;
  tooltipText?: string;
  hidden?: boolean;
};

type Props = {
  rows: RowDefinition[];
  shouldShowEmptyState?: boolean;
};

const ChartRow = ({ row }: { row: RowDefinition }) => {
  if (row.hidden) {
    return null;
  }

  return (
    <Tooltip text={row.tooltipText || ''} placement="right">
      <Row>
        {row.name}: {row.formattedValue}
      </Row>
    </Tooltip>
  );
};

export const ChartStats = ({ rows, shouldShowEmptyState = false }: Props) => {
  if (shouldShowEmptyState) {
    return (
      <LayoutRoot>
        <ChartStatsEmptyState />
      </LayoutRoot>
    );
  }

  return (
    <LayoutRoot>
      {rows.map((row, idx) => (
        <ChartRow key={idx} row={row} />
      ))}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  align-items: flex-end;
  gap: 6px;
`;

const Row = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralDarker};
  font-size: 12px;
`;
