import React from 'react';
import { SubHeader } from 'app/src/layouts/tolstoy_empty_screen/emptyScreenCss';
import TextButton from 'app/src/complex_components/TextButton';
import styled from 'styled-components';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import { TextH4 } from 'shared/react/components/complex/Text';
import Gap16HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap16HorizontalFlexWrap';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { COMPANY_USE_CASE_OPTIONS, ECOMMERCE } from 'app/src/constants/accountSettings.constants';
import { USE_CASE_DATA_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import videoMessagingGif from 'app/src/images/onboarding/video_messaging.gif';
import videoMessagingEcomGif from 'app/src/images/onboarding/video_messaging_ecom.gif';
import videoOnSiteGif from 'app/src/images/onboarding/video_on_site.gif';
import videoOnSiteEcomGif from 'app/src/images/onboarding/video_on_site_ecom.gif';

const OnboardingCompanyQuestions = ({ data, onAnswerClick }) => {
  const getCurrentGif = () => {
    const isEcommerce = data.companyVertical === ECOMMERCE;
    const isVideoMessaging = data.useCase === 'Video messaging';

    if (isEcommerce) {
      if (isVideoMessaging) {
        return videoMessagingEcomGif;
      }
      return videoOnSiteEcomGif;
    }

    if (isVideoMessaging) {
      return videoMessagingGif;
    }
    return videoOnSiteGif;
  };

  return (
    <>
      <HeaderContainer>
        <Header>What are you planning to use Tolstoy for?</Header>
      </HeaderContainer>
      <ChoicesContainer>
        <Choices>
          {COMPANY_USE_CASE_OPTIONS.map(title => {
            const isSelected = title === data[USE_CASE_DATA_TEST_ID];
            return (
              <TextButton
                value={title}
                onClick={value => onAnswerClick(value, USE_CASE_DATA_TEST_ID)}
                isSelected={isSelected}
                key={title}
                title={title}
                data-test-id={title}
              />
            );
          })}
        </Choices>
      </ChoicesContainer>
      <SubHeaderContainer>
        <SubHeader>Don’t worry you can always do everything.</SubHeader>
      </SubHeaderContainer>
      <Gif src={getCurrentGif()} />
    </>
  );
};

const HeaderContainer = styled(Gap16VerticalFlex)`
  max-width: 76ch;
`;

const Header = styled(TextH4)`
  text-align: center;
  font-weight: 700;
  max-width: 525px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    font-size: 24px;
  }
`;

const Choices = styled(Gap16HorizontalFlexWrap)`
  max-width: 831px;
  justify-content: center;
`;

const ChoicesContainer = styled(VerticalFlex)`
  align-items: center;
  gap: 24px;
`;

const SubHeaderContainer = styled(HorizontalFlexWrap)`
  justify-content: center;
`;

const Gif = styled.img`
  max-width: 400px;
  border-radius: 16px;
`;

export default OnboardingCompanyQuestions;
