import React from 'react';
import AnalyticsEmailEmptyState from '../empty/AnalyticsEmailEmptyState';
import AnalyticsEmailMetricsGrid from './AnalyticsEmailMetricsGrid';
import AnalyticsEmailRecipientsTable from './AnalyticsEmailRecipientsTable';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import styled from 'styled-components';
import useEmailAnalytics from './useEmailAnalytics';
import { DesignSizes } from 'shared/react/theme/DesignSystem';
import AnalyticsMetrics from 'app/src/complex_components/analytics/AnalyticsMetrics';

type Props = {
  shouldBlur: boolean;
};

const TOP_METRICS = [
  {
    id: 'emailCTR',
    color: 'neutralBlack',
    formatted: false,
    isPercentage: true,
    mainTitle: 'CTR',
  },
  {
    id: 'conversionRate',
    color: 'neutralBlack',
    formatted: false,
    isPercentage: true,
    mainTitle: 'Conversion Rate',
  },
];

const AnalyticsEmail = ({ shouldBlur }: Props) => {
  const {
    currency,
    emailConversionsOvertime,
    emailEventsOvertime,
    emailRecipients,
    emailCTR,
    conversionRate,
    isLoadingEmailConversionsOvertime,
    isLoadingEmailEventsOvertime,
    isLoadingEmailRecipients,
  } = useEmailAnalytics();

  if (shouldBlur) {
    return <AnalyticsEmailEmptyState />;
  }

  return (
    <LayoutRoot>
      <StyledAnalyticsMetrics
        size={DesignSizes.SMALL}
        data={{ emailCTR, conversionRate }}
        metrics={TOP_METRICS}
      />
      <AnalyticsEmailMetricsGrid
        currency={currency}
        emailConversionsOvertime={emailConversionsOvertime}
        emailEventsOvertime={emailEventsOvertime}
        isLoadingEmailConversionsOvertime={isLoadingEmailConversionsOvertime}
        isLoadingEmailEventsOvertime={isLoadingEmailEventsOvertime}
      />
      <AnalyticsEmailRecipientsTable
        emailRecipients={emailRecipients}
        isLoadingEmailRecipients={isLoadingEmailRecipients}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)`
  padding-bottom: 64px;
`;

const StyledAnalyticsMetrics = styled(AnalyticsMetrics)`
  width: 50%;
  & > *:first-child {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    & > * {
      max-width: unset;
      width: 100%;
    }
  }
`;

export default AnalyticsEmail;
