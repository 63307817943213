import useFeedAccount from 'shared/react/components/complex/context/hooks/useFeedAccount';
import { FENTY_APPKEY } from 'shared/react/constants/clientsAppKey.constants';

export const FENTY_MAX_QUANTITY = 5;

const useIsFenty = () => {
  const [{ appKey }] = useFeedAccount();
  return appKey === FENTY_APPKEY;
};

export const useIsQuickShop = product => {
  return false;
  // const isFenty = useIsFenty();
  // return isFenty && product?.variants?.some(variant => variant?.title !== 'Default Title');
};

export default useIsFenty;
