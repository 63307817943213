import React from 'react';

const HubspotIcon = props => (
  <svg
    width={24}
    height={26}
    viewBox="0 0 24 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.435 18.49c-1.86 0-3.366-1.49-3.366-3.33s1.507-3.331 3.366-3.331c1.859 0 3.366 1.491 3.366 3.331 0 1.84-1.507 3.33-3.366 3.33Zm1.008-9.743V5.784a2.279 2.279 0 0 0 1.329-2.056V3.66c0-1.254-1.037-2.279-2.303-2.279H17.4c-1.266 0-2.302 1.025-2.302 2.279v.068c0 .908.547 1.69 1.329 2.056v2.963a6.562 6.562 0 0 0-3.104 1.352L5.102 3.77a2.52 2.52 0 0 0 .092-.641C5.196 1.709 4.034.557 2.6.556 1.166.554.002 1.703 0 3.122c-.002 1.42 1.16 2.572 2.594 2.574.467 0 .9-.131 1.28-.345l8.086 6.226a6.417 6.417 0 0 0-1.09 3.583c0 1.387.442 2.67 1.19 3.725l-2.459 2.434a2.122 2.122 0 0 0-.61-.098 2.123 2.123 0 0 0-2.134 2.111c0 1.167.956 2.112 2.134 2.112a2.123 2.123 0 0 0 2.135-2.112c0-.21-.041-.41-.1-.603l2.433-2.407a6.575 6.575 0 0 0 3.976 1.335c3.626 0 6.565-2.909 6.565-6.497 0-3.248-2.411-5.932-5.557-6.413Z"
      fill="#F95C35"
    />
  </svg>
);

export default HubspotIcon;
