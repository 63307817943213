import * as React from 'react';

const AudioResponseAnswerIcon = ({ fill, ...props }) => {
  return (
    <svg
      width={14}
      height={21}
      viewBox="0 0 14 21"
      fill={fill || '#7D8087'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7 14.25c2.07 0 3.75-1.68 3.75-3.75V4.25a3.751 3.751 0 00-7.5 0v6.25c0 2.07 1.68 3.75 3.75 3.75zM4.5 4.367c0-1.289.938-2.46 2.227-2.578C8.21 1.633 9.5 2.805 9.5 4.25H7.625A.642.642 0 007 4.875c0 .352.273.625.625.625H9.5v1.25H7.625A.642.642 0 007 7.375c0 .352.273.625.625.625H9.5v1.25H7.625A.642.642 0 007 9.875c0 .352.273.625.625.625H9.5A2.515 2.515 0 016.727 13c-1.29-.117-2.227-1.29-2.227-2.578V4.367zM12.625 8a.642.642 0 00-.625.625V10.5c0 2.89-2.46 5.195-5.352 5C3.992 15.344 2 13 2 10.344V8.625C2 8.312 1.687 8 1.375 8a.642.642 0 00-.625.625v1.68c0 3.242 2.383 6.093 5.625 6.406v2.539h-2.5a.642.642 0 00-.625.625c0 .352.273.625.625.625h6.25a.642.642 0 00.625-.625c0-.313-.313-.625-.625-.625h-2.5v-2.54c3.125-.312 5.625-2.968 5.625-6.21V8.625c0-.313-.313-.625-.625-.625z" />
    </svg>
  );
};

export default AudioResponseAnswerIcon;
