import React from 'react';
import { DangerButton } from 'shared/react/components/basic/button-v2/BasicButton';
import useNavigation from 'app/src/hooks/useNavigation';

const NewFromCaseOnsiteButton = () => {
  const { navigateToDashboardOnsiteCreateCase } = useNavigation();

  const handleClick = () => {
    navigateToDashboardOnsiteCreateCase();
  };

  return <DangerButton onClick={handleClick}>Create Tolstoy</DangerButton>;
};

export default NewFromCaseOnsiteButton;
