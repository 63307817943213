import { isAfter, subWeeks } from 'date-fns';
import Utils from '../utils';

const SHORT_MONTH_NAMES = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const TIME_UNITS_IN_SECONDS = {
  year: 31536000,
  month: 2592000,
  day: 86400,
  hour: 3600,
  minute: 60,
};

const formatUnitName = (unit, value) => {
  if (value === 0 || Math.floor(value) > 1) {
    return `${unit}s`;
  }

  return unit;
};

export const getDiffInSeconds = (date1, date2) => {
  const dif = date1.getTime() - date2.getTime();
  const secondsDiff = Math.abs(dif / 1000);

  return secondsDiff;
};

export const getShortMonthAndDate = fullDate => {
  const date = new Date(fullDate);
  if (Number.isNaN(date.getDate())) {
    return '';
  }

  return `${SHORT_MONTH_NAMES[date.getMonth()]} ${date.getDate()}`;
};

export const isToday = someDate => {
  return isDaysBefore(someDate, 0);
};

export const isYesterday = someDate => {
  return isDaysBefore(someDate, 1);
};

export const isDaysAgoInRange = (date, start, end) => {
  for (let i = start; i < end; i++) {
    if (isDaysBefore(date, i)) {
      return i;
    }
  }

  return null;
};

export const getWeekDayOfDate = date => {
  const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  return weekday[date.getDay()];
};

export const isDateInThisWeek = date => {
  const todayObj = new Date();
  const todayDate = todayObj.getDate();
  const todayDay = todayObj.getDay();

  const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));

  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

  return date >= firstDayOfWeek && date <= lastDayOfWeek;
};

export const isInTheLastDays = (date, numberOfDays) => {
  const daysBeforeDate = new Date();
  daysBeforeDate.setDate(daysBeforeDate.getDate() - numberOfDays);

  return date <= daysBeforeDate;
};

export const isDaysBefore = (date, numberOfDays) => {
  const daysBeforeDate = new Date();
  daysBeforeDate.setDate(daysBeforeDate.getDate() - numberOfDays);

  return (
    date.getDate() === daysBeforeDate.getDate() &&
    date.getMonth() === daysBeforeDate.getMonth() &&
    date.getFullYear() === daysBeforeDate.getFullYear()
  );
};

export const getTimeSince = dateString => {
  const date = new Date(dateString);

  const seconds = Math.max(Math.floor((new Date() - date) / 1000), 0);

  let interval = 0;
  const timeSince = Object.entries(TIME_UNITS_IN_SECONDS).flatMap(([unit, value]) => {
    interval = seconds / value;

    if (interval > 1) {
      return `${Math.floor(interval)} ${formatUnitName(unit, interval)} ago`;
    }

    return [];
  });

  return timeSince[0] || Math.floor(seconds) + ` ${formatUnitName('second', seconds)} ago`;
};

export const formatAMPM = date => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours || 12;
  return `${hours}:${Utils.addZeroToNumberIfNeeded(minutes)} ${ampm}`;
};

export const isDateFromTheLastSevenDays = dateString => {
  const time = Date.parse(dateString);
  const previousWeek = subWeeks(new Date(), 1);
  return isAfter(time, previousWeek);
};
