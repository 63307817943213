import React from 'react';
import styled from 'styled-components';
import ThinVIcon from 'shared/react/images/ThinVIcon';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';

const FeedItemAdded = () => {
  const [, { t }] = useTranslation('feed.buyNowButton');

  return (
    <LayoutRoot>
      <StyledThinVIcon /> {t('added')}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlex)`
  align-items: center;
`;

const StyledThinVIcon = styled(ThinVIcon)`
  & path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export default FeedItemAdded;
