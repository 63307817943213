import React from 'react';

const BackArrowIcon = props => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.594 1.375c-.5-.5-1.281-.469-1.75.031l-5.5 5.75a1.226 1.226 0 0 0 0 1.719l5.5 5.75c.219.25.562.375.906.375.281 0 .594-.094.844-.344.5-.469.531-1.25.031-1.75L5.969 8.031l4.656-4.906c.5-.5.469-1.281-.031-1.75Z"
      fill="#3D4FE0"
    />
  </svg>
);

export default BackArrowIcon;
