import React from 'react';

function BIcon({ fill, darkBackground, size = 20 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={12} cy={12} r={12} fill={fill || '#FFFFFF'} />
      <path
        d="M9.303 15.5h2.717c1.782 0 2.893-.792 2.893-2.387 0-.88-.418-1.496-1.155-1.837.572-.341.87-.88.87-1.551 0-1.463-1.035-2.255-2.817-2.255H9.303v8.03Zm2.64-3.41c1.122 0 1.43.341 1.43.99s-.462 1.012-1.353 1.012h-1.188V12.09h1.111Zm-.12-3.212c.901 0 1.264.264 1.264.847 0 .561-.33.935-1.276.935h-.979V8.878h.99Z"
        fill={darkBackground ? '#FFFFFF' : '#2F3137'}
      />
    </svg>
  );
}

export default BIcon;
