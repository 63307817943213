import React from 'react';
import styled from 'styled-components';
import NewTolstoyMenuContent from 'app/src/pages/create_new_tolstoy/NewTolstoyMenuContent';
import NewTolstoyChoosePage from 'app/src/pages/create_new_tolstoy/NewTolstoyChoosePage';
import { usePublishMethods } from 'app/src/hooks/usePublishMethods/usePublishMethods';

const NewTolstoyMenuItems = ({
  menuItems,
  handleHover,
  shouldAskWhichPage,
  isChoosePage,
  onPageClick,
  ...props
}) => {
  const { pages } = usePublishMethods();

  if (shouldAskWhichPage && isChoosePage) {
    return pages.map(page => {
      return <NewTolstoyChoosePage key={page.route} {...page} onClick={onPageClick} />;
    });
  }

  return menuItems.map((item, i) => (
    <React.Fragment key={item.id}>
      <NewTolstoyMenuContent {...item} {...props} handleHover={() => handleHover(item)} />
      {i !== menuItems.length - 1 && <Separator />}
    </React.Fragment>
  ));
};

const Separator = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.colors.gray3};
`;

export default NewTolstoyMenuItems;
