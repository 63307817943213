import type { ResultSet } from '@cubejs-client/core';
import { CUBE_PROPERTY_KEYS } from 'app/src/constants/cube.constants';
import { VIDEOS_PROD } from 'app/src/constants/analytics.constants';
import { VideosConversionMetrics } from './types/getVideosConversionMetrics.types';

export const getVideosConversionMetrics = (rawMetrics: ResultSet): VideosConversionMetrics[] => {
  return rawMetrics?.tablePivot().map(data => {
    return {
      id: data[`${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.id}`],
      conversionRate: +data[`${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.conversionRate}`] || 0,
      currency: data[`${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.currency}`],
      plays: +data[`${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.plays}`] || 0,
      totalRevenue: +data[`${VIDEOS_PROD}.${CUBE_PROPERTY_KEYS.totalRevenue}`] || 0,
    } as VideosConversionMetrics;
  });
};
