import React from 'react';

const DuplicateIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 15h7c.531 0 1-.438 1-1v-1.5c0-.25.219-.5.5-.5.25 0 .5.25.5.5V14c0 1.125-.906 2-2 2H2c-1.125 0-2-.875-2-2V7c0-1.094.875-2 2-2h1.5c.25 0 .5.25.5.5 0 .281-.25.5-.5.5H2c-.563 0-1 .469-1 1v7c0 .563.438 1 1 1ZM5 2c0-1.094.875-2 2-2h7c1.094 0 2 .906 2 2v7c0 1.125-.906 2-2 2H7c-1.125 0-2-.875-2-2V2Zm2 8h7c.531 0 1-.438 1-1V2c0-.531-.469-1-1-1H7c-.563 0-1 .469-1 1v7c0 .563.438 1 1 1Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default DuplicateIcon;
