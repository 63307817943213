import { useFeedSnackbarContext } from 'shared/react/components/complex/context/FeedSnackbarContext';

const useFeedSnackbar = () => {
  const { snackbarText, setSnackbarText } = useFeedSnackbarContext() || {};

  const actions = { setSnackbarText };

  return [{ snackbarText }, actions];
};

export default useFeedSnackbar;
