import React from 'react';
import styled from 'styled-components';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionHeader from './AccordionHeader';

function Accordion({
  children,
  isExpanded,
  setExpanded,
  headerPadding,
  tooltipText,
  isSidebarOpen,
  headerText,
  draggingOver,
  mobileHeaderPadding,
  ...props
}) {
  return (
    <StyledAccordion
      expanded={isExpanded}
      square
      onChange={() => setExpanded(!isExpanded)}
      TransitionProps={{ unmountOnExit: true, timeout: 300 }}
      {...props}
    >
      <AccordionHeader
        headerPadding={headerPadding}
        tooltipText={tooltipText}
        isExpanded={isExpanded}
        mobileHeaderPadding={mobileHeaderPadding}
        headerText={headerText}
        isSidebarOpen={isSidebarOpen}
      />
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  );
}

export default Accordion;

const StyledAccordion = styled(MuiAccordion)`
  && {
    background: transparent;
    box-shadow: none;
  }

  &:before {
    display: none;
  }

  &.MuiAccordion-root.Mui-expanded {
    margin: 0;
  }

  overflow-x: auto;
  max-width: 100%;
  width: 100%;
`;

const StyledAccordionDetails = styled(MuiAccordionDetails)`
  padding: 0 0 20px;
`;
