import {
  useAnalytics,
  useAnalyticsEmailConversions,
  useEmailTopMetrics,
} from 'app/src/context/AnalyticsStore';
import { useEffect } from 'react';
import { useProjectActions } from 'app/src/context/ProjectsStore';
import { useAnalyticsProjects } from 'app/src/complex_components/analytics/useAnalyticsProjects';

const EMPTY_PUBLISH_ID = '';

const useEmailAnalytics = () => {
  const { getEmailCampaignsBySourceProjectId } = useProjectActions();
  const [
    {
      emailEventsOvertime,
      emailRecipients,
      isLoadingEmailMetricsOvertime,
      isLoadingEmailEventsOvertime,
      isLoadingEmailRecipients,
      rates,
    },
    { fetchEmailMetricsOvertime, fetchEmailEventsOvertime, fetchEmailRecipients, getAllFilters },
  ] = useAnalytics();
  const { hasSelectedProjects, selectedProjects } = useAnalyticsProjects();
  const [{ emailConversionsOvertime, currency }] = useAnalyticsEmailConversions();
  const [{ emailCTR, conversionRate }] = useEmailTopMetrics(emailRecipients);
  const filters = getAllFilters();
  const filterKey = JSON.stringify(filters);
  const isLoadingEmailConversionsOvertime = isLoadingEmailMetricsOvertime || !rates;
  const emailCampaignProjects = selectedProjects?.flatMap(p =>
    getEmailCampaignsBySourceProjectId(p.id)
  );

  useEffect(() => {
    if (!hasSelectedProjects) {
      fetchEmailEventsOvertime();
      fetchEmailRecipients();
      fetchEmailMetricsOvertime();
      return;
    }

    const campaignPublishIds = [
      ...new Set(emailCampaignProjects?.map(({ publishId }) => publishId)),
    ];
    const publishIds = campaignPublishIds?.length ? campaignPublishIds : [EMPTY_PUBLISH_ID];

    fetchEmailEventsOvertime({ publishIds });
    fetchEmailRecipients({ publishIds });
    fetchEmailMetricsOvertime({ publishIds });
  }, [hasSelectedProjects, filterKey]);

  return {
    currency,
    emailConversionsOvertime,
    emailEventsOvertime,
    emailRecipients,
    emailCTR,
    conversionRate,
    isLoadingEmailConversionsOvertime,
    isLoadingEmailEventsOvertime,
    isLoadingEmailRecipients,
  } as const;
};

export default useEmailAnalytics;
