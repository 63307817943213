import React from 'react';
import styled, { useTheme } from 'styled-components';
import { TextBody } from 'shared/react/components/complex/Text';
import Tooltip from 'app/src/basic_components/Tooltip';

const UserBox = ({
  name,
  icon = undefined,
  userImageSrc,
  fontSize = '16px',
  color = undefined,
  backgroundColor = undefined,
  fontWeight = 'bold',
  badgeText = undefined,
  badgeTooltipText = undefined,
  ...props
}) => {
  const firstChar = name?.[0]?.toUpperCase() || 'U';
  const theme = useTheme();
  const userIcon = userImageSrc ? <UserIcon alt="icon" src={userImageSrc} /> : null;

  return (
    <ResponseWriter
      userIcon={!!userIcon}
      $backgroundColor={backgroundColor || theme.colors.pink7}
      {...props}
    >
      {userIcon || icon || (
        <TextBody
          fontWeight={fontWeight}
          fontSize={fontSize}
          textColor={color || theme.colors.red3}
        >
          {firstChar}
        </TextBody>
      )}
      {badgeText && (
        <Badge>
          <Tooltip header={badgeTooltipText} placement="right">
            <BadgeTextContainer>
              <BadgeText>{badgeText}</BadgeText>
            </BadgeTextContainer>
          </Tooltip>
        </Badge>
      )}
    </ResponseWriter>
  );
};

const ResponseWriter = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: ${({ $backgroundColor, userIcon }) => (userIcon ? '' : $backgroundColor)};
  border-radius: 10px;
  width: ${({ scale }) => scale || '40px'};
  height: ${({ scale }) => scale || '40px'};
  transition: 0.5s;
  visibility: ${({ visibility }) => visibility};
  &:hover {
    background: ${({ $backgroundColor, theme, userIcon }) =>
      userIcon ? '' : theme.getHexOpacity($backgroundColor, 85)};
  }
`;

const UserIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
`;

const Badge = styled.div`
  position: absolute;
  right: -12px;
  top: -8px;
  background: ${({ theme }) => theme.colors.info};
  padding: 2px 4px;
  border-radius: 4px;
  opacity: 1;
`;

const BadgeText = styled.div`
  font-size: 10px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
`;

const BadgeTextContainer = styled.div``;

export default UserBox;
