import React from 'react';
import styled from 'styled-components';

const SubtitlesContainer = ({
  isVertical,
  isControlsShown,
  isProducts,
  onClick,
  currentSubtitles,
  customLayout,
}) => {
  return (
    <LayoutRoot
      isVertical={isVertical}
      isProducts={isProducts}
      isControlsShown={isControlsShown}
      onClick={onClick}
    >
      <Subtitles visible={!!currentSubtitles} style={customLayout.subtitles}>
        {currentSubtitles}
      </Subtitles>
    </LayoutRoot>
  );
};

export default SubtitlesContainer;

const LayoutRoot = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  z-index: 1;
  min-height: ${({ isProducts }) => (isProducts ? '68px' : '0')};
  transition: 0.3s;
  grid-area: subtitles;
  margin: 0 20px ${({ isControlsShown }) => (isControlsShown ? '0px' : '30px')};
`;

const Subtitles = styled.div`
  background: ${({ theme }) => theme.getHexOpacity(theme.colors.black, 60)};
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  width: fit-content;
  padding: 6px 8px;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  word-break: break-word;
`;
