import { getLocalStorageItem, setLocalStorageItem } from 'player/src/actions/localStorage.actions';

const getFormattedViewedStepsKeys = (configStepKeys, viewedStepsKeys) => {
  const existingViewedStepsKeys = viewedStepsKeys.filter(key => configStepKeys.includes(key));
  return existingViewedStepsKeys.sort(
    (keyA, keyB) => configStepKeys.indexOf(keyA) - configStepKeys.indexOf(keyB)
  );
};

const sortConfigStepsByViewedSteps = (configSteps, formattedViewedStepsKeys) => {
  formattedViewedStepsKeys.forEach(viewedStepKey => {
    const viewedStepIndex = configSteps.findIndex(({ key }) => key === viewedStepKey);
    const viewedStep = configSteps.splice(viewedStepIndex, 1)[0];
    configSteps.push(viewedStep);
  });
};

const getHasWatchedAllSteps = (configStepsKeys, formattedViewedStepsKeys) => {
  return JSON.stringify(configStepsKeys) === JSON.stringify(formattedViewedStepsKeys);
};

const reorderStepsByViewedSteps = (config, viewedStepsKeys) => {
  if (!viewedStepsKeys?.length) {
    return;
  }

  const configStepsKeys = config.steps.map(({ key }) => key);
  const formattedViewedStepsKeys = getFormattedViewedStepsKeys(configStepsKeys, viewedStepsKeys);
  if (getHasWatchedAllSteps(configStepsKeys, formattedViewedStepsKeys)) {
    return;
  }

  sortConfigStepsByViewedSteps(config.steps, formattedViewedStepsKeys);
};

const getLocalStorageKey = publishId => {
  return `${publishId}-viewed-steps-keys`;
};

const getViewedStepsKeys = publishId => {
  return JSON.parse(getLocalStorageItem(getLocalStorageKey(publishId)) || '[]');
};

export const setViewedStepKey = (stepKey, publishId) => {
  const viewedStepsKeys = getViewedStepsKeys(publishId);
  viewedStepsKeys.push(stepKey);
  const filteredStepsKeys = [...new Set(viewedStepsKeys)];
  setLocalStorageItem(getLocalStorageKey(publishId), JSON.stringify(filteredStepsKeys));
};

export const handleReorderStepsByViewedSteps = config => {
  if (config.disabled) {
    return;
  }

  const viewedStepsKeys = getViewedStepsKeys(config.publishId);
  reorderStepsByViewedSteps(config, viewedStepsKeys);
};
