export const TAPCART_CUSTOM_BLOCK_BASE_URL = 'custom-blocks.tapcart.com';

export const getIsTapcart = () => {
  if (window?.Tapcart?.isInitialized) {
    return true;
  }

  if (document.location?.host?.includes(TAPCART_CUSTOM_BLOCK_BASE_URL)) {
    return true;
  }

  return false;
};

export const getTapcartDeviceId = () => {
  const deviceId = window?.Tapcart?.variables?.device?.id;

  return deviceId?.toLowerCase();
};
