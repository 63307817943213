import React, { useState } from 'react';
import styled from 'styled-components';
import HorizontalFlex, {
  Gap8HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { useHistory, useParams } from 'react-router-dom';
import { useFolders } from 'app/src/context/FoldersStore';
import { useProjects } from 'app/src/context/ProjectsStore';
import { TextBody } from 'shared/react/components/complex/Text';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';
import FilledBottomArrow from 'app/src/images/FilledBottomArrow';
import ProjectMenu from 'app/src/pages/dashboard/components/menus/ProjectMenu';
import { FatChevronLeftIcon } from 'app/src/images/sidebar-v2/FatChevronIcon';
import { PROJECT_MENU_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import { Badge } from 'shared/react/components/basic/badge/Badge';
import { PUBLISH_METHODS } from 'shared/react/constants/tolstoy.constants';

const Breadcrumbs = () => {
  const [anchorElement, setAnchorElement] = useState(null);
  const { folderId, projectId } = useParams();
  const [, { getFolderById }] = useFolders({});
  const [{ project }] = useProjects({ projectId });
  const history = useHistory();
  const publishMethod = project?.publishMethod;

  const onBackClick = () => {
    const route = history.location.pathname.split(projectId)[0];
    history.push(route.slice(0, -1));
  };

  const getItems = () => {
    const folder = getFolderById(folderId)?.name;
    const crumbs = [];
    if (folder) {
      crumbs.push({ text: folder, onClick: onBackClick });
    }

    crumbs.push({ text: project?.name });
    return crumbs;
  };

  const onOpenMenu = event => {
    setAnchorElement(event?.currentTarget);
  };

  const items = getItems();

  return (
    <LayoutRoot>
      <IconContainer onClick={onBackClick}>
        <FatChevronLeftIcon />
      </IconContainer>

      <Crumbs>
        {items.map(({ text, onClick }, i) => {
          return (
            <React.Fragment key={text || `breadcrumbs-${i}`}>
              <StyledOverflow onClick={onClick} hasOnClick={!!onClick}>
                <Text hasOnClick={!!onClick}>{text}</Text>
              </StyledOverflow>
              <Separator>/</Separator>
            </React.Fragment>
          );
        })}
      </Crumbs>
      <IconContainer data-test-id={PROJECT_MENU_TEST_ID} onClick={onOpenMenu}>
        <FilledBottomArrow />
      </IconContainer>
      {!!project && (
        <ProjectMenu
          anchorElement={anchorElement}
          setAnchorElement={setAnchorElement}
          project={project}
          inProject={true}
        />
      )}
      {publishMethod && (
        <CustomBadge>{PUBLISH_METHODS[publishMethod]?.text || publishMethod}</CustomBadge>
      )}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlex)`
  align-items: center;
`;

const IconContainer = styled(HorizontalFlex)`
  cursor: pointer;

  path {
    fill: ${({ theme }) => theme.colors.neutralBlack};
  }

  svg {
    width: 12px;
    height: 12px;
  }
`;

const Crumbs = styled(HorizontalFlex)`
  background: ${({ theme }) => theme.colors.white};
  align-items: center;
  gap: 4px;

  &:last-child {
    display: none;
  }
`;

const StyledOverflow = styled(EllipsisTextOverflowWrapper)<{ hasOnClick: boolean }>`
  cursor: ${({ hasOnClick }) => (hasOnClick ? 'pointer' : '')};
`;

const Text = styled(TextBody)<{ hasOnClick: boolean }>`
  line-height: 22px;
  letter-spacing: 0.0015em;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutralBlack};
  transition: 0.3s;

  &:hover {
    color: ${({ theme, hasOnClick }) => (hasOnClick ? theme.colors.neutralDarker : '')};
  }
`;

const Separator = styled(TextBody)`
  line-height: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutralDark};

  &:last-of-type {
    display: none;
  }
`;

const CustomBadge = styled(Badge).attrs(({ theme }) => {
  return {
    size: theme.designSizes.NORMAL,
    weight: theme.designWeights.SQUARE,
    state: theme.designStates.LIGHT,
  };
})``;

export default Breadcrumbs;
