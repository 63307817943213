import React from 'react';
import styled from 'styled-components';
import Button from 'shared/react/components/complex/Button';
import ModalCard from 'app/src/complex_components/ModalCard';

function VideoRecorderError({ retry }) {
  return (
    <LayoutRoot>
      <ModalCard>
        <MainContainer>
          <Title>Please grant camera & microphone permissions and try again.</Title>
          <Button onClick={retry} width="200px">
            Try again
          </Button>
        </MainContainer>
      </ModalCard>
    </LayoutRoot>
  );
}

export default VideoRecorderError;

const LayoutRoot = styled.div`
  display: grid;
  height: 100%;
  align-content: center;
  justify-items: center;
`;

const MainContainer = styled.div`
  display: grid;
  gap: 8px;
  height: 100%;
  align-content: center;
  justify-items: center;
`;

const Title = styled.div`
  text-align: center;
  font-size: 20px;
  width: 400px;
  color: ${({ theme }) => theme.colors.gray12};
  margin-bottom: 16px;
`;
