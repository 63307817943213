import React from 'react';
import styled from 'styled-components';
import { TextSmall } from 'shared/react/components/complex/Text';
import AddRuleButton from 'app/src/pages/project/pages/rules_and_triggers/editor/shared/AddRuleButton';
import { useRulesContext } from 'app/src/pages/project/pages/rules_and_triggers/RulesContext';
import { nanoid } from 'nanoid';
import { CONTAINS_KEY } from 'app/src/constants/ruleGroups.constants';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import RuleGroupContainer from 'app/src/pages/project/pages/rules_and_triggers/editor/shared/RuleGroupContainer';

function RulesPagesEditor() {
  const { getWhereAndConditions, addRule, whereAttributes, setWhereAttributes } = useRulesContext();
  const andConditions = getWhereAndConditions();

  const onAddRule = () => {
    addRule({
      key: nanoid(5),
      type: 'url',
      condition: CONTAINS_KEY,
      value: '',
      behaviors: ['visibility'],
    });
  };

  return (
    <LayoutRoot>
      <TitleContainer>
        <TextSmall>Set where to display or hide the widget.</TextSmall>
        <AddRuleButton onClick={onAddRule} />
      </TitleContainer>
      <RuleGroupContainer
        andConditions={andConditions}
        attributes={whereAttributes}
        setAttributes={setWhereAttributes}
      />
    </LayoutRoot>
  );
}

export default RulesPagesEditor;

const LayoutRoot = styled(Gap8VerticalFlex)``;

const TitleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`;
