import React from 'react';
import styled from 'styled-components';

import StandardModal from './StandardModal';
import Button from 'shared/react/components/complex/Button';
import Types from 'shared/react/theme/Types';
import { TextSubtitle } from 'shared/react/components/complex/Text';
import { DELETE_MODAL_CONFIRMATION_DATA_TEST_ID } from 'app/src/constants/dataTestIds.constants';

export default function AreYouSureModal({
  open,
  secondaryButtonClicked = null,
  actionButtonClicked = null,
  loading = false,
  titleText = '',
  actionButtonText = '',
  actionLevel = null,
  secondaryButtonText = '',
  closeButtonClicked = null,
}) {
  return (
    <StandardModal open={open} onClose={closeButtonClicked || secondaryButtonClicked} width="340px">
      <ModalContainer>
        <TextSubtitle>{titleText || 'Are You Sure?'}</TextSubtitle>
        <ButtonContainer>
          <Button type={Types.Ghost} onClick={secondaryButtonClicked}>
            {secondaryButtonText || 'No'}
          </Button>
          <ActionButton
            disabled={loading}
            loading={loading}
            type={actionLevel || Types.Danger}
            onClick={actionButtonClicked}
            data-test-id={DELETE_MODAL_CONFIRMATION_DATA_TEST_ID}
          >
            {actionButtonText || 'Delete'}
          </ActionButton>
        </ButtonContainer>
      </ModalContainer>
    </StandardModal>
  );
}

const ModalContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  gap: 20px;
  padding-top: 10px;
`;

const ButtonContainer = styled.div`
  align-self: end;
  display: flex;
  justify-content: flex-end;
  justify-self: end;
`;

const ActionButton = styled(Button)`
  grid-row: 1;
  grid-column: 1;
  opacity: ${props => (props.loading ? '0.4' : '1.0')};
  margin-left: 7px;
`;
