import React from 'react';
import styled from 'styled-components';

function StyledBadge({ counter, ...props }) {
  const formattedCounter = counter > 99 ? '99+' : counter;
  return !counter ? null : <Container {...props}>{formattedCounter}</Container>;
}

const Container = styled.div`
  color: white;
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  max-height: 18px;
  padding: 2px 5px;
  background: ${({ theme }) => theme.colors.red4};
  border-radius: 16px;
`;

export default StyledBadge;
