import styled from 'styled-components';
import BasicButton from './BasicButton';

const GhostButton = styled(BasicButton)`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.gray2};
  border: 1px solid ${({ theme }) => theme.colors.gray4};

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.ghostButtonBorderHover};
    background: ${({ theme }) => theme.colors.white};
  }
`;

export default GhostButton;
