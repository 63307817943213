import React from 'react';

const PreviewIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.5 1C8.21875 1 8 0.78125 8 0.5C8 0.25 8.21875 0 8.5 0H13.5C13.75 0 14 0.25 14 0.5V5.5C14 5.78125 13.75 6 13.5 6C13.2188 6 13 5.78125 13 5.5V1.71875L5.84375 8.875C5.65625 9.0625 5.3125 9.0625 5.125 8.875C4.9375 8.6875 4.9375 8.34375 5.125 8.15625L12.2812 1H8.5ZM0 2.5C0 1.6875 0.65625 1 1.5 1H5.5C5.75 1 6 1.25 6 1.5C6 1.78125 5.75 2 5.5 2H1.5C1.21875 2 1 2.25 1 2.5V12.5C1 12.7812 1.21875 13 1.5 13H11.5C11.75 13 12 12.7812 12 12.5V8.5C12 8.25 12.2188 8 12.5 8C12.75 8 13 8.25 13 8.5V12.5C13 13.3438 12.3125 14 11.5 14H1.5C0.65625 14 0 13.3438 0 12.5V2.5Z"
      fill="#090A0B"
    />
  </svg>
);

export default PreviewIcon;
