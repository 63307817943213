import React, { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useVideos } from 'app/src/context/VideosStore';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import StandardModalV2 from 'app/src/pages/modals/StandardModalV2';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextH2Bold } from 'shared/react/components/basic/text/TextV2';
import CaptionsEditorWrapper from './CaptionsEditorWrapper';
import CaptionsVideoPreview from './CaptionsVideoPreview';
import { Button } from '@tolstoy/ui-library/core';
import { useSnackBarActions } from 'src/context/ui_store/SnackBarStore';
import { Upload } from 'lucide-react';

const MAX_VTT_FILE_SIZE = 1024 * 1024;

type Props = {
  closeModal: () => void;
};

const CaptionsEditorModal = ({ closeModal }: Props) => {
  const [subtitlesBlob, setSubtitlesBlob] = useState<Blob | null>(null);
  const [uploadedVTTContent, setUploadedVTTContent] = useState<string | null>(null);
  const [{ modalProps }] = useModal();
  const { videoId } = modalProps;
  const [{ video }, { getVideoUrl, getVideoPoster }] = useVideos(videoId);
  const { setSnackbar, setErrorSnackbar } = useSnackBarActions();

  const videoSrc = getVideoUrl(video);

  const handleFileUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        if (file.type !== 'text/vtt') {
          setErrorSnackbar('Invalid file type. Please upload a VTT file.');
          return;
        }

        if (file.size > MAX_VTT_FILE_SIZE) {
          setErrorSnackbar('File size exceeds the limit of 1MB.');
          return;
        }

        try {
          const text = await file.text();
          const blob = new Blob([text], { type: 'text/vtt' });
          setSubtitlesBlob(blob);
          setUploadedVTTContent(text);
          setSnackbar('VTT file uploaded successfully.');
        } catch (error) {
          console.error('Error reading file:', error);
          setErrorSnackbar('Error reading the file. Please try again.');
        }
      }
    },
    [setErrorSnackbar, setSnackbar]
  );

  const buttonText = useMemo(() => {
    return uploadedVTTContent ? 'Change file' : 'Upload file';
  }, [subtitlesBlob, uploadedVTTContent]);

  const handleUploadClick = () => {
    document.getElementById('vtt-file-input')?.click();
  };

  return (
    <StyledStandardModal open={true} onClose={closeModal}>
      <LayoutRoot>
        <CaptionsVideoPreview
          poster={getVideoPoster(video)}
          src={videoSrc}
          subtitlesBlob={subtitlesBlob}
        />
        <ContentContainer>
          <TextH2Bold>Edit Captions</TextH2Bold>
          <UploadContainer>
            <Button variant="outline" onClick={handleUploadClick}>
              <Upload className="mr-2 h-4 w-4" />
              {buttonText}
            </Button>
            <input
              id="vtt-file-input"
              type="file"
              accept=".vtt"
              onChange={handleFileUpload}
              style={{ display: 'none' }}
            />
          </UploadContainer>
          <CaptionsEditorWrapper
            video={video}
            subtitlesBlob={subtitlesBlob}
            setSubtitlesBlob={setSubtitlesBlob}
            uploadedVTTContent={uploadedVTTContent}
            onClose={closeModal}
          />
        </ContentContainer>
      </LayoutRoot>
    </StyledStandardModal>
  );
};

const StyledStandardModal = styled(StandardModalV2)`
  height: 100%;
  width: 800px;
`;

const LayoutRoot = styled(HorizontalFlex)`
  max-height: 533px;
`;

const ContentContainer = styled(Gap16VerticalFlex)`
  padding: 24px 0;
  width: 600px;

  & > * {
    padding: 0 24px;
  }

  &:last-child {
    padding-bottom: 16px;
  }
`;

const UploadContainer = styled.div`
  margin-bottom: 16px;
`;

export default CaptionsEditorModal;
