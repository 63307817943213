import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useVideos } from 'app/src/context/VideosStore';
import { useLocation, useParams } from 'react-router-dom';
import { useProjects } from 'app/src/context/ProjectsStore';
import PreviewSection from './PreviewSection';
import SubtitlesSection from './SubtitlesSection';
import SnackBar from 'app/src/basic_components/SnackBar';
import ModalCard from 'app/src/complex_components/ModalCard';
import Utils from '../../utils';
import ComponentWithLoader from 'app/src/complex_components/ComponentWithLoader';

const SNACKBAR_STATE = {
  success: {
    text: 'Subtitles saved successfully.',
    key: 'success',
  },
  error: {
    text: 'Subtitles failed to save.',
    key: 'error',
  },
};

export default function SubtitlesEditor() {
  const location = useLocation();
  const { projectId, stepId } = useParams();
  const [{ project, step = {} }, { updateProject, publishProject }] = useProjects({
    projectId,
    stepId,
  });
  const [videos, { getSubtitlesUrl, getVideoUrl, uploadSubtitles }] = useVideos();
  const video = location.state?.video;
  step.owner = video?.owner || project?.owner || Utils.getOwner();
  step.videoId = video.id;
  const [resultVideoBlob] = useState(location.state?.resultVideoBlob);
  const [subtitlesBlob, setSubtitlesBlob] = useState(null);
  const [subtitles, setSubtitles] = useState('');
  const [videoUrl, setVideoUrl] = useState(getVideoUrl(video));
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [successBar, setSuccessBar] = useState(false);
  const [severity, setSeverity] = useState(SNACKBAR_STATE.success.key);

  const onSaveSuccess = subtitles => {
    setSubtitles(subtitles);
    setSaving(false);
    setSeverity(SNACKBAR_STATE.success.key);
    setSuccessBar(true);
  };

  const onSaveFailure = error => {
    console.log(error);
    setSaving(false);
    setSeverity(SNACKBAR_STATE.error.key);
    setSuccessBar(true);
  };

  const onSave = async () => {
    setSaving(true);
    const subtitles = await subtitlesBlob.text();
    try {
      await uploadSubtitles({ projectId, videoId: video.id, subtitles });
      await publishProject(projectId);
      onSaveSuccess(subtitles);
    } catch (err) {
      onSaveFailure(err);
    }
  };

  const saveSubtitleEnabled = async subtitlesEnabled => {
    const updatedProject = await updateProject({ ...project, subtitlesEnabled });
    if (updatedProject) {
      await publishProject(updatedProject.id);
    }
  };

  useEffect( () => {
    const getSubtitles = async () => {
      if (!step || !project || !loading) {
        return;
      }

      setVideoUrl(
        getVideoUrl(location.state?.video || videos.data.find(video => video.id === step?.videoId))
      );

      try {
        const response = await fetch(`${getSubtitlesUrl(step)}?timestamp=${new Date().getTime()}`);

        if (!response.ok) {
          throw new Error('getSubtitlesUrl failed');
        }

        const blob = await response.blob();
        const text = await blob.text();
        setSubtitles(text);
        setSubtitlesBlob(blob);
      } catch (e) {
        console.log(e);
        setSubtitles('WEBVTT\n');
      } finally {
        setLoading(false);
      }
    }

    getSubtitles();
  }, [project, videos]);

  const previewedVideoSrc = resultVideoBlob || videoUrl;

  return (
    <LayoutRoot>
      <StyledModalCard shouldShowXButton={false}>
        <MainContainer>
          <ComponentWithLoader isLoading={loading}>
            <SubtitlesSection
              subtitlesEnabled={project?.subtitlesEnabled}
              saveSubtitleEnabled={saveSubtitleEnabled}
              subtitles={subtitles}
              setSubtitlesBlob={setSubtitlesBlob}
              onSave={onSave}
              loading={saving}
            />
          </ComponentWithLoader>
          <PreviewSection previewedVideoSrc={previewedVideoSrc} subtitlesBlob={subtitlesBlob} />
          <SnackBar
            text={SNACKBAR_STATE[severity].text}
            open={successBar}
            setOpen={setSuccessBar}
            severity={severity}
          />
        </MainContainer>
      </StyledModalCard>
    </LayoutRoot>
  );
}

const LayoutRoot = styled.div`
  display: grid;
  height: 100%;
  width: 100%;
  justify-self: center;
  overflow-y: auto;
`;

const StyledModalCard = styled(ModalCard)`
  width: fit-content;
  justify-self: center;
`;

const MainContainer = styled.div`
  display: grid;
  align-self: start;
  justify-self: center;
  justify-items: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.previewMax}) {
    grid-template-columns: auto;
    grid-template-rows: auto 1fr;
    gap: 12px;
    padding: 0;
    margin-top: 0;
    margin-bottom: 8px;
  }
`;
