import React from 'react';

const FolderOutlinedIcon = () => {
  return (
    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.969 2c1.125 0 2 .906 2 2v8c0 1.125-.907 2-2 2h-12c-1.125 0-2-.875-2-2V2c0-1.094.875-2 2-2h3.687c.532 0 1.032.219 1.407.594L9.5 2h5.469ZM16 12V4c0-.531-.469-1-1-1H9.062L7.375 1.312A1.017 1.017 0 0 0 6.656 1H3c-.563 0-1 .469-1 1v10c0 .563.438 1 1 1h12c.531 0 1-.438 1-1Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default FolderOutlinedIcon;
