import { useState, useEffect } from 'react';
import Utils from 'src/utils';
import { useApps } from 'src/context/AppsStore';
import { useFeatureSettings } from 'src/context/FeaturesStore';
import { DesignSettings } from 'src/types/design';
import { FEATURE_DESIGN_ACCOUNT_SETTINGS } from 'shared/react/constants/features.constants';
import { DEFAULT_DESIGN_SETTINGS } from 'shared/react/constants/design';
import { SAVE_IN_DB_APP_TYPES } from 'src/constants/design';

export const useDesignSettings = (): DesignSettings => {
  const [designSettings, setDesignSettings] = useState(DEFAULT_DESIGN_SETTINGS);
  const [{ selectedApp, loading: isAppLoading }] = useApps();
  const [featureSettings] = useFeatureSettings(FEATURE_DESIGN_ACCOUNT_SETTINGS);

  useEffect(() => {
    if (isAppLoading) {
      return;
    }

    const appSettings = JSON.parse(selectedApp?.settings || '{}');
    const shouldUseAppDeisgnSettings =
      selectedApp && SAVE_IN_DB_APP_TYPES.includes(selectedApp.app);

    const designSettings = Utils.mergeListOfObjects([
      DEFAULT_DESIGN_SETTINGS,
      featureSettings,
      shouldUseAppDeisgnSettings ? appSettings.design : {},
    ]);

    setDesignSettings(designSettings);
  }, [selectedApp, featureSettings]);

  return designSettings;
};
