export const PUBLIC_CLASSES = {
  embed: 'tolstoy-embed',
  iframe: 'tolstoy-embed-iframe',
  videoPreview: 'tolstoy-embed-video-preview',
  videoContainer: 'tolstoy-embed-video-container',
  playButtonContainer: 'tolstoy-embed-play-button-container',
  startText: 'tolstoy-embed-start-text',
  startButtonContainer: 'tolstoy-embed-start-button-container',
  startButton: 'tolstoy-embed-start-button',
  embedContainer: 'tolstoy-embed-container'
};

export const ASPECT_RATIO_9_16 = 9 / 16;
