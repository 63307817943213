import { css } from 'styled-components';
import {
  CAROUSEL_ARROW_DATA_TYPE,
  CAROUSEL_DATA_TYPE,
  FEED_BUTTON_DATA_TYPE,
  FEED_BUTTON_TEXT_DATA_TYPE,
  FEED_CART_BUTTON_DATA_TYPE,
  FEED_CURRENT_STEP_DETAILS_DATA_TYPE,
  FEED_PRODUCT_DATA_TYPE,
  FEED_PRODUCT_DESCRIPTION_DATA_TYPE,
  FEED_PRODUCT_OPTIONS_BUY_NOW_DATA_TYPE,
  FEED_SHARE_COPY_LINK_DATA_TYPE,
  FEED_SHARE_LINK_DATA_TYPE,
  FEED_SHARE_SOCIAL_BUTTONS_DATA_TYPE,
  MORE_BUTTON_DATA_TYPE,
  MULTI_PRODUCT_SECTION_DATA_TYPE,
  PRODUCT_DETAILS_DATA_TYPE,
  PRODUCT_DISCOUNTED_PRICE_DATA_TYPE,
  PRODUCT_HEADER_DATA_TYPE,
  PRODUCT_IMAGE_DATA_TYPE,
  PRODUCT_OPTION_HEADER_DATA_TYPE,
  PRODUCT_PRICE_DATA_TYPE,
  SELECT_INPUT_CONTAINER_DATA_TYPE,
  SMALL_PRODUCT_HEADER_DATA_TYPE,
  SMALL_PRODUCT_PRICE_DATA_TYPE,
} from 'shared/react/constants/feedPreviewDataTypes.constants';
import { BASIC_SELECT_HEADER_DATA_TYPE } from 'shared/react/constants/ui.constants.js';
import Utils from 'shared/react/utils/utils';

const FeedSmallProductViewCss = css`
  & ${Utils.getDataType(SMALL_PRODUCT_HEADER_DATA_TYPE)} {
    font-size: 18px;
    line-height: 18px;
    font-weight: 800;
  }

  & ${Utils.getDataType(SMALL_PRODUCT_PRICE_DATA_TYPE)} {
    font-size: 16px;
    font-weight: normal;
    line-height: 18px;
  }

  & ${Utils.getDataType(MORE_BUTTON_DATA_TYPE)} {
    min-width: 48px;
  }
`;

const ProductCss = css`
  & ${Utils.getDataType(PRODUCT_HEADER_DATA_TYPE)} {
    font-size: 18px;
    line-height: 18px;
    font-weight: 800;
  }

  & ${Utils.getDataType(PRODUCT_PRICE_DATA_TYPE)} {
    font-size: 16px;
    font-weight: normal;
    line-height: 18px;
  }

  & ${Utils.getDataType(PRODUCT_DISCOUNTED_PRICE_DATA_TYPE)} {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
  }

  & ${Utils.getDataType(PRODUCT_DETAILS_DATA_TYPE)} {
    gap: 4px;
  }

  & ${Utils.getDataType(PRODUCT_IMAGE_DATA_TYPE)} {
    max-width: 80px;
    height: 100px;
  }
`;

const FeedMultipleProductDetailsCss = css`
  & ${Utils.getDataType(MULTI_PRODUCT_SECTION_DATA_TYPE)} {
    gap: 32px;
    height: 100px;
  }
`;

const FeedProductCss = css`
  & ${Utils.getDataType(FEED_PRODUCT_DATA_TYPE)} {
    gap: 16px;
  }

  & ${Utils.getDataType(FEED_PRODUCT_OPTIONS_BUY_NOW_DATA_TYPE)} {
    gap: 30px;
  }
`;

const FeedButtonCss = css`
  & ${Utils.getDataType(FEED_BUTTON_DATA_TYPE)} {
    padding: 11px 12px;
  }

  & ${Utils.getDataType(FEED_BUTTON_TEXT_DATA_TYPE)} {
    font-size: 16px;
  }
`;

const FeedProductOptionSelectCss = css`
  & ${Utils.getDataType(PRODUCT_OPTION_HEADER_DATA_TYPE)} {
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
  }

  & ${Utils.getDataType(SELECT_INPUT_CONTAINER_DATA_TYPE)} {
    padding: 9px 16px;
  }

  & ${Utils.getDataType(BASIC_SELECT_HEADER_DATA_TYPE)} {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
  }

  & ${Utils.getDataType(FEED_PRODUCT_DESCRIPTION_DATA_TYPE)} {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
  }
`;

const CarouselCss = css`
  & ${Utils.getDataType(CAROUSEL_DATA_TYPE)} {
    max-height: 150px;
  }
`;

const CarouselArrowCss = css`
  & ${Utils.getDataType(CAROUSEL_ARROW_DATA_TYPE)} {
    display: none;
  }
`;

const FeedCurrentStepDetailsCss = css`
  & ${Utils.getDataType(FEED_CURRENT_STEP_DETAILS_DATA_TYPE)} {
    display: none;
  }
`;

const FeedShareCss = css`
  & ${Utils.getDataType(FEED_SHARE_COPY_LINK_DATA_TYPE)} {
    font-size: 14px;
    line-height: 16px;
  }

  & ${Utils.getDataType(FEED_SHARE_LINK_DATA_TYPE)} {
    font-size: 14px;
    line-height: 16px;
  }

  & ${Utils.getDataType(FEED_SHARE_SOCIAL_BUTTONS_DATA_TYPE)} {
    & svg {
      width: 16px;
      height: 16px;
    }

    margin-bottom: -4px;
  }
`;

const FeedCartButtonCss = css`
  & ${Utils.getDataType(FEED_CART_BUTTON_DATA_TYPE)} {
    display: ${({ isShoppableSidePanel, isEcomPlatformConnected }) =>
      !isShoppableSidePanel || !isEcomPlatformConnected ? 'none' : ''};
  }
`;

export default css`
  ${FeedSmallProductViewCss};
  ${ProductCss};
  ${FeedProductCss};
  ${FeedMultipleProductDetailsCss};
  ${FeedProductOptionSelectCss};
  ${CarouselCss};
  ${CarouselArrowCss};
  ${FeedButtonCss};
  ${FeedCurrentStepDetailsCss};
  ${FeedShareCss};
  ${FeedCartButtonCss};
`;
