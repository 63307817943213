import React from 'react';
import styled from 'styled-components';
import EditorItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorItem';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { LightTextSmall } from 'shared/react/components/complex/Text';
import RedirectToLink from 'app/src/images/RedirectToLink';
import EditorMainContainer from '../shared/EditorMainContainer';

const GoToBranding = ({ text, navigateTo }) => {
  return (
    <LayoutRoot>
      <EditorItem text={text}>
        <Gap8HorizontalFlex onClick={navigateTo}>
          <Change>Change</Change>
          <RedirectToLink />
        </Gap8HorizontalFlex>
      </EditorItem>
    </LayoutRoot>
  );
};

export default GoToBranding;

const LayoutRoot = styled(EditorMainContainer)`
  cursor: pointer;
`;

const Change = styled(LightTextSmall)`
  color: ${({ theme }) => theme.colors.primary};
`;
