import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PRODUCTS_RESULT } from 'app/src/constants/editStep.constants';
import { useProjects } from 'app/src/context/ProjectsStore';
import { useQuizContext } from 'app/src/pages/project/pages/quiz/QuizContext';
import BasicSelect from 'shared/react/components/basic/basic_select/BasicSelect';
import styled, { useTheme } from 'styled-components';
import {
  DEFAULT_QUIZ_STEP_OVERLAY_TEXT,
  defaultQuizProductStepData,
} from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/editStepDefaultData';
import { navigateToStep } from 'app/src/utils/navigation.utils';
import useAddStep from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/useAddStep';
import ComponentWithOverlayLoader from 'shared/react/components/basic/ComponentWithOverlayLoader';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';
import Utils from 'shared/react/utils/utils';

const CREATE_NEW = 'CREATE_NEW';
const DEFAULT_TEXT = 'Choose product result page';

const ProductResultsMenu = ({
  quizResultId,
  currentProductResult = '',
  setCurrentProductResult,
  errorHelperText,
  quizResult,
}) => {
  const [productResults, setProductResults] = useState([]);
  const [isCreatingNewResultPart, setIsCreatingNewResultPart] = useState(false);
  const [, { setSnackbar }] = useSnackBar();
  const [currentProductResultDescription, setCurrentProductResultDescription] =
    useState(DEFAULT_TEXT);
  const history = useHistory();
  const { onSetProductResult, project, saveQuizResults, isUnsaved } = useQuizContext();
  const { projectId } = useParams();
  const theme = useTheme();
  const [, { getProjectById, createProjectStep, publishProject, getStepByName }] = useProjects();
  const { quizProductsStepLimitPassed } = useAddStep(project);

  const getProductResults = () => {
    const project = getProjectById(projectId);
    return project?.steps?.items.filter(({ type }) => type === PRODUCTS_RESULT);
  };

  const onAddProductResult = async () => {
    setIsCreatingNewResultPart(true);
    const newStep = {
      ...defaultQuizProductStepData(false, true),
      projectId: project.id,
    };

    const newProject = await createProjectStep(newStep, project);
    const lastStepName = newProject.stepsOrder[newProject.stepsOrder.length - 1];
    const step = getStepByName(lastStepName, newProject);
    await publishProject(newProject.id);
    const newQuizResults = { ...quizResult, stepName: step.name };
    await saveQuizResults([newQuizResults]);
    if (!isUnsaved) {
      navigateToStep(history, project.id, step.id);
      setSnackbar('Step has been created');
    }
    setIsCreatingNewResultPart(false);
    onSetProductResult(quizResultId, step.name);
    setCurrentProductResult(step.name);

    setCurrentProductResultDescription(`Result step #${productResults.length + 1}`);
  };

  const handleClick = async listItem => {
    const ProductResultName = listItem.id;

    if (ProductResultName === CREATE_NEW) {
      await onAddProductResult();
      return;
    }
    setCurrentProductResult(ProductResultName);
    setCurrentProductResultDescription(listItem.name);
    onSetProductResult(quizResultId, ProductResultName);
  };

  const getItems = () => {
    const productResultsData = getProductResults();
    if (!productResultsData.length) {
      return;
    }

    setProductResults(productResultsData);
    if (currentProductResult) {
      const chosenItem = productResultsData.filter(({ name }) => name === currentProductResult)[0];
      setCurrentProductResultDescription(chosenItem?.description);
      return;
    }

    setCurrentProductResultDescription(DEFAULT_TEXT);
  };

  useEffect(() => {
    getItems();
  }, []);

  useEffect(() => {
    let index = null;
    const result = productResults.find(({ name }, i) => {
      index = i;
      return name === currentProductResult;
    });

    if (!result) {
      return;
    }
    const currOverlayText =
      result?.overlayText !== DEFAULT_QUIZ_STEP_OVERLAY_TEXT && result?.overlayText;

    setCurrentProductResultDescription(
      result?.description || currOverlayText || `Result step #${index + 1}`
    );
  }, [currentProductResult, productResults]);

  const items = productResults.map(({ name, description, overlayText }, i) => {
    const currOverlayText = overlayText !== DEFAULT_QUIZ_STEP_OVERLAY_TEXT && overlayText;
    return {
      id: name,
      name: description || currOverlayText || `Result step #${i + 1}`,
    };
  });

  if (!quizProductsStepLimitPassed) {
    items.unshift({ id: CREATE_NEW, name: 'Create a new products step' });
  }

  return (
    <LayoutRoot isLoading={isCreatingNewResultPart} loaderColor={theme.colors.primary}>
      <StyledSelect
        onClick={Utils.stopPropagation}
        items={items}
        value={errorHelperText || currentProductResultDescription}
        onChange={handleClick}
        loading={isCreatingNewResultPart}
        error={errorHelperText}
      />
    </LayoutRoot>
  );
};

export default ProductResultsMenu;

const LayoutRoot = styled(ComponentWithOverlayLoader)`
  width: auto;
  align-items: inherit;
`;

const StyledSelect = styled(BasicSelect)`
  width: 280px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  justify-content: center;
  & > * {
    border: 1px solid ${({ theme, error }) => (error ? theme.colors.warning : theme.colors.gray5)};
    font-weight: 800;
    color: ${({ theme }) => theme.colors.black};
  }
`;
