import React from 'react';

const PromoCodeIcon = ({ fill, darkBackground }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.002 19.722c5.369 0 9.722-4.353 9.722-9.722C19.724 4.63 15.37.278 10 .278 4.633.278.28 4.63.28 10c0 5.37 4.353 9.722 9.723 9.722Z"
        fill={fill || '#FFFFFF'}
      />
      <path
        d="M4.896 5.742v4.261c0 .297.16.707.365.912l4.033 4.033a1.265 1.265 0 0 0 1.8 0l3.623-3.623a1.265 1.265 0 0 0 0-1.8l-4.033-4.033c-.205-.205-.615-.388-.911-.388H5.535a.637.637 0 0 0-.639.638ZM6.72 7.656c0-.387.32-.729.73-.729a.75.75 0 0 1 .729.73c0 .41-.342.729-.73.729a.72.72 0 0 1-.729-.73Z"
        fill={darkBackground ? '#FFFFFF' : '#2F3137'}
      />
    </svg>
  );
};

export default PromoCodeIcon;
