import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { App } from 'src/types/app';
import CogIcon from 'src/images/CogIcon';
import RefreshIcon from 'app/src/images/RefreshIcon';
import Tooltip from 'src/basic_components/tooltip-v2/Tooltip';
import TopBar from 'src/pages/dashboard/components/top-bar/TopBar';
import { SearchInput } from 'app/src/basic_components/input/InputWithIcon';
import { GhostButton } from 'shared/react/components/basic/button-v2/BasicButton';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

import UgcItem from './UgcItem';

type Props = {
  ugcApps: App[];
  hashtags: string[];
  selectedApp: App;
  selectedHashtag: string;
  isLoading: boolean;
  isRefreshing: boolean;
  onSelectApp: (id: string) => void;
  onSearch: (hashtag) => void;
  onRefresh: () => void;
  onSettingsClick: () => void;
  onRemoveHashtag: (hashtag: string) => void;
};

export const UgcSearchTopBar = ({
  ugcApps,
  hashtags,
  selectedApp,
  selectedHashtag,
  isLoading,
  isRefreshing,
  onSearch,
  onRefresh,
  onSelectApp,
  onSettingsClick,
  onRemoveHashtag,
}: Props) => {
  const [searchHashtag, setSearchHashtag] = useState<string>('');

  useEffect(() => {
    setSearchHashtag('');
  }, [selectedApp, selectedHashtag]);

  const handleSearchChange = e => {
    const value = e.target.value;
    const hashtag = value.startsWith('#') ? value : `#${value}`;
    setSearchHashtag(hashtag);
  };

  const handleSearch = e => {
    if (e.code === 'Enter') {
      const hashtag = searchHashtag.replace('#', '');
      onSearch(hashtag);
    }
  };

  const leftContent = (
    <LeftContainer>
      <StyledSearchInput
        value={searchHashtag}
        placeholder="Search a hashtag"
        onKeyDown={handleSearch}
        onChange={handleSearchChange}
      />

      {ugcApps.map(({ data, id }) => (
        <Tooltip key={id} text="Posts your account is tagged in" placement="bottom">
          <UgcItem
            onClick={() => onSelectApp(id)}
            isSelected={!selectedHashtag && selectedApp.id === id}
            title={`@${data.userName}`}
          />
        </Tooltip>
      ))}

      {hashtags.map(hashtag => (
        <UgcItem
          key={hashtag}
          onClick={() => onSearch(hashtag)}
          isSelected={selectedHashtag === hashtag}
          title={`#${hashtag}`}
          onRemoveCategory={() => onRemoveHashtag(hashtag)}
        />
      ))}
    </LeftContainer>
  );

  const rightContent = (
    <Gap8HorizontalFlex>
      <GhostButton onClick={onSettingsClick}>
        <CogIcon />
      </GhostButton>
      <GhostButton onClick={onRefresh} loading={isLoading}>
        <SpinIcon $isRefreshing={isRefreshing} />
      </GhostButton>
    </Gap8HorizontalFlex>
  );
  return <TopBar leftContent={leftContent} rightContent={rightContent} />;
};

const LeftContainer = styled(HorizontalFlexWrap)`
  gap: 4px;
`;

const StyledSearchInput = styled(SearchInput)`
  width: 221px;
  height: 32px;
  margin-right: 24px;
`;

const SpinIcon = styled(RefreshIcon)`
  animation: ${({ $isRefreshing }) => ($isRefreshing ? 'spin 1s linear infinite' : '')};

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export default UgcSearchTopBar;
