import React, { useState } from 'react';

import { useModalActions } from 'app/src/context/ui_store/ModalStore';
import AreYouSureModal from 'app/src/pages/modals/AreYouSureModal';
import { useProjectActions } from 'app/src/context/ProjectsStore';
import { useSnackBarActions } from 'src/context/ui_store/SnackBarStore';
import { useApps } from 'src/context/AppsStore';

const undefinedProps = {
  actionLevel: undefined,
  secondaryButtonText: undefined,
  closeButtonClicked: undefined,
};

const ResetProjectsGoogleAnalyticsModal = () => {
  const { resetProjectsGoogleAnalytics } = useProjectActions();
  const { clearModalState } = useModalActions();
  const { setSnackbar, setErrorSnackbar } = useSnackBarActions();
  const [{ selectedAppUrl }] = useApps();

  const [loading, setLoading] = useState(false);

  const onClose = () => {
    clearModalState();
  };

  const onResetProjectsGoogleAnalytics = async () => {
    setLoading(true);

    const isResetSuccessfully = await resetProjectsGoogleAnalytics(selectedAppUrl);

    if (!isResetSuccessfully) {
      setErrorSnackbar('Something went wrong, please try again later');
    } else {
      setSnackbar('Google Analytics has been successfully overwritten for all projects');
    }

    setLoading(false);
    onClose();
  };

  return (
    <AreYouSureModal
      open={true}
      loading={loading}
      actionButtonText="Reset"
      titleText="Project Google Analytics will be overridden permanently. Are you sure?"
      secondaryButtonClicked={onClose}
      actionButtonClicked={onResetProjectsGoogleAnalytics}
      {...undefinedProps}
    />
  );
};

export default ResetProjectsGoogleAnalyticsModal;
