import React from 'react';
import styled from 'styled-components';
import { JourneyDrawerStepType } from './types/JourneyDrawerStep.types';
import JourneyDrawerStep from './JourneyDrawerStep';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import OutlinedDollarSignIcon from 'src/images/audience/journey/OutlinedDollarSignIcon';

type Props = {
  data: JourneyDrawerStepType;
};

const JourneyDrawerPurchaseStep = ({ data }: Props) => {
  const { createdAt, orderId } = data;
  return (
    <JourneyDrawerStep header="Purchased" createdAt={createdAt} Icon={OutlinedDollarSignIcon}>
      <StyledTextTiny>Order ID: {orderId}</StyledTextTiny>
    </JourneyDrawerStep>
  );
};

const StyledTextTiny = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralDark};
`;

export default JourneyDrawerPurchaseStep;
