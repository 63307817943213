import React from 'react';

const PauseIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#pauseIcon)">
        <path
          d="M4.375 2.5c-.633 0-1.125.516-1.125 1.125v6.75A1.11 1.11 0 0 0 4.375 11.5h.75c.61 0 1.125-.492 1.125-1.125v-6.75A1.14 1.14 0 0 0 5.125 2.5h-.75Zm4.5 0c-.633 0-1.125.516-1.125 1.125v6.75A1.11 1.11 0 0 0 8.875 11.5h.75c.61 0 1.125-.492 1.125-1.125v-6.75A1.14 1.14 0 0 0 9.625 2.5h-.75Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="pauseIcon">
          <path fill="#fff" d="M3 2.5h8v9H3z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PauseIcon;
