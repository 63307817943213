import React, { useState, SyntheticEvent } from 'react';
import styled, { css } from 'styled-components';
import { TextTiny } from 'shared/react/components/complex/Text';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { PLAYLIST_TAG_MODES } from './constants';
import { Playlist } from './types';
import TagDropdown from './TagDropdown';

export type PlaylistTagStyles = {
  $mode?: string;
};

export type PlaylistTagProps = PlaylistTagStyles & {
  onClick?: (e?: SyntheticEvent) => void;
  name: string;
  subName?: string;
  ctaText?: string;
  shouldShowCtaText?: boolean;
  onUpdateRuleCondition?: (playlist: Playlist, newCondition: string) => void;
  playlist?: Playlist;
  isSelectConditionDisabled?: boolean;
};

const PlaylistTag = ({
  name,
  subName,
  $mode,
  ctaText,
  shouldShowCtaText = false,
  onClick,
  playlist,
  onUpdateRuleCondition,
  isSelectConditionDisabled,
}: PlaylistTagProps) => {
  const isCtaVisible = shouldShowCtaText && ctaText;
  const [shouldShowDropdownToggle, setShouldShowDropdownToggle] = useState(false);

  return (
    <PlaylistTagStyled
      $mode={$mode}
      onClick={onClick}
      onMouseEnter={() => setShouldShowDropdownToggle(true)}
      onMouseLeave={() => setShouldShowDropdownToggle(false)}
    >
      <PlaylistName $mode={$mode}>
        {name} {subName && <SubName>{subName}</SubName>}
      </PlaylistName>
      {isCtaVisible && (
        <>
          <VerticalSeparator />
          <CtaText>{ctaText}</CtaText>
        </>
      )}

      <TagDropdown
        mode={$mode}
        playlist={playlist}
        onUpdateRuleCondition={onUpdateRuleCondition}
        isDisabled={isSelectConditionDisabled || !!isCtaVisible}
        shouldShowDropdownToggle={shouldShowDropdownToggle}
      />
    </PlaylistTagStyled>
  );
};

const PlaylistTagStyled = styled(HorizontalFlexCentered)<PlaylistTagStyles>`
  padding: 8px 12px;
  border-radius: 16px;
  cursor: pointer;
  user-select: none;
  gap: 6px;
  position: relative;

  ${({ $mode, theme }) => {
    switch ($mode) {
      case PLAYLIST_TAG_MODES.OUTLINE:
      default:
        return css`
          border: 1px solid ${theme.colors.neutralLight};
          background-color: ${theme.colors.white};
        `;
      case PLAYLIST_TAG_MODES.WARNING_FILLED:
        return css`
          border: 1px solid ${theme.colors.fireRed};
          background-color: ${theme.colors.neutralLighter};
        `;
      case PLAYLIST_TAG_MODES.FILLED:
        return css`
          border: 1px solid ${theme.colors.neutralLightest};
          background-color: ${theme.colors.neutralBlack};
        `;
      case PLAYLIST_TAG_MODES.GRAYED_OUT:
        return css`
          border: 1px solid ${theme.colors.neutralDark};
        `;
    }
  }}
`;

const PlaylistName = styled(TextTiny)`
  font-weight: 500;
  display: flex;
  align-items: center;
  ${({ $mode, theme }) => {
    switch ($mode) {
      case PLAYLIST_TAG_MODES.OUTLINE:
      default:
        return css`
          color: ${theme.colors.neutralBlack};
        `;
      case PLAYLIST_TAG_MODES.FILLED:
        return css`
          color: ${theme.colors.white};
        `;
      case PLAYLIST_TAG_MODES.WARNING_FILLED:
        return css`
          color: ${theme.colors.fireRed};
        `;
      case PLAYLIST_TAG_MODES.GRAYED_OUT:
        return css`
          color: ${theme.colors.neutralDark};
        `;
    }
  }}
`;

const CtaText = styled(PlaylistName)`
  color: ${({ theme }) => theme.colors.primary};
`;

const VerticalSeparator = styled.div`
  width: 1px;
  height: 100%;
`;

const SubName = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralDark};
  margin-left: 5px;
`;

export default PlaylistTag;
