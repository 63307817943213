import { useCallback } from 'react';
import { useSwipeUpAnimationContext } from 'shared/react/components/complex/context/SwipeUpAnimationContext';
import { SWIPE_UP_ANIMATION_LOCAL_STORAGE_KEY } from 'shared/react/constants/feed.constants';
import { setLocalStorageItem } from 'player/src/actions/localStorage.actions';

const useSwipeUpAnimation = () => {
  const { hasSeenSwipeUpAnimation, setHasSeenSwipeUpAnimation } = useSwipeUpAnimationContext();

  const markAnimationAsSeen = () => {
    setLocalStorageItem(SWIPE_UP_ANIMATION_LOCAL_STORAGE_KEY, true);
    setHasSeenSwipeUpAnimation(true);
  };

  const handleSwipeUpAnimationSeen = useCallback(() => {
    if (hasSeenSwipeUpAnimation) {
      return;
    }

    return markAnimationAsSeen();
  }, [hasSeenSwipeUpAnimation]);

  const actions = { handleSwipeUpAnimationSeen };

  return [{ hasSeenSwipeUpAnimation }, actions];
};

export default useSwipeUpAnimation;
