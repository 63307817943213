import React from 'react';

const FolderOutlinedIcon = () => {
  return (
    <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5 1.75H6.445L5.297.695A1.491 1.491 0 0 0 4.242.25H1.5C.656.25 0 .93 0 1.75v7.5c0 .844.656 1.5 1.5 1.5h9c.82 0 1.5-.656 1.5-1.5v-6c0-.82-.68-1.5-1.5-1.5Zm-9-.375h2.742c.094 0 .188.047.258.117L6 2.875h4.5c.188 0 .375.188.375.375V4h-9.75V1.75c0-.188.164-.375.375-.375Zm9 8.25h-9a.37.37 0 0 1-.375-.375V5.125h9.75V9.25c0 .21-.188.375-.375.375Z"
        fill="#50545E"
      />
    </svg>
  );
};

export default FolderOutlinedIcon;
