import React from 'react';
import styled from 'styled-components';
import HorizontalFlex from '../../../flex_layouts/HorizontalFlex';

const FeedProductRechargeHighlightRecurringHeader = ({
  shouldHide,
  backgroundColor,
  borderRadius,
  leftContent,
  rightContent,
}) => {
  if (shouldHide) {
    return null;
  }

  return (
    <LayoutRoot backgroundColor={backgroundColor} borderRadius={borderRadius}>
      <HeaderPart>{leftContent}</HeaderPart>
      <HeaderPart>{rightContent}</HeaderPart>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)`
  background: ${({ backgroundColor }) => backgroundColor};
  color: ${({ theme }) => theme.colors.white};
  padding: 4px ${({ borderRadius }) => 8 + borderRadius / 2}px;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  border-radius: ${({ borderRadius }) =>
    borderRadius ? `${borderRadius}px ${borderRadius}px 0 0` : ''};
`;

const HeaderPart = styled.span``;

export default FeedProductRechargeHighlightRecurringHeader;
