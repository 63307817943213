import React from 'react';

const LikesIcon = () => {
  return (
    <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m6.5 2.125.328-.398C7.531.812 8.703.39 9.828.578c1.547.258 2.672 1.57 2.672 3.117v.282c0 .984-.422 1.921-1.125 2.578l-4.242 3.96c-.164.165-.399.235-.633.235-.258 0-.492-.07-.656-.234L1.602 6.555A3.524 3.524 0 0 1 .5 3.977v-.282A3.147 3.147 0 0 1 3.148.578c1.125-.187 2.297.235 3 1.149l.352.398Zm0 .61-.633-.774A2.824 2.824 0 0 0 3.22.93 2.814 2.814 0 0 0 .875 3.695v.282c0 .867.352 1.71.984 2.296l4.243 3.961a.548.548 0 0 0 .398.141c.14 0 .281-.047.375-.14l4.242-3.962a3.158 3.158 0 0 0 1.008-2.296v-.282c0-1.36-1.008-2.53-2.367-2.765a2.824 2.824 0 0 0-2.649 1.03l-.609.774Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default LikesIcon;
