import React from 'react';
import EditorHeader from '../shared/EditorHeader';
import EditorMainContainer from '../shared/EditorMainContainer';
import EditorTextInputItem from '../shared/EditorTextInputItem';
import OnOffToggleItem from '../shared/OnOffToggleItem';

type MiniButtonConfig = {
  text: string;
  enabled: boolean;
};

type Props = {
  title: string;
  buttonConfig: MiniButtonConfig;
  onChange?: (buttonConfig: MiniButtonConfig) => void;
};

const MiniProductDetailsButtonEditor = ({ title, buttonConfig, onChange = () => {} }: Props) => {
  const { enabled, text } = buttonConfig || {};

  const handleOnChange = (updateConfig = {}) => onChange({ ...buttonConfig, ...updateConfig });
  const handleOnToggleChange = () => handleOnChange({ enabled: !enabled });
  const handleButtonTextChange = value => handleOnChange({ text: value });

  return (
    <>
      <EditorHeader title={title}>
        <OnOffToggleItem checked={enabled} onChange={handleOnToggleChange} />
      </EditorHeader>
      <EditorMainContainer shouldHide={!enabled}>
        <EditorTextInputItem
          placeholder="Button text"
          text="Text"
          value={text}
          maxWidth="200px"
          onChange={handleButtonTextChange}
        />
      </EditorMainContainer>
    </>
  );
};

export default MiniProductDetailsButtonEditor;
