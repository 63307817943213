import React from 'react';
import styled from 'styled-components';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import TolstoyCodeInstallationStep from '../tolstoy_code_step/TolstoyCodeInstallationStep';
import ConversionCodeInstallationStep from '../conversion_code_step/ConversionCodeInstallationStep';
import { app as AppEntity } from 'src/types/entities';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import InstallationCompletedButton from '../InstallationCompletedButton';
import useAccountInstallation from 'src/hooks/useAccountInstallation';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';

type Props = {
  app?: AppEntity;
  startExpanded?: boolean;
};

const DefaultAppInstallationSteps = ({ app, startExpanded }: Props) => {
  const [{ isInstallationCompleted }] = useAccountInstallation('');

  return (
    <AppWrapper>
      <AccordionsWrapper>
        <TolstoyCodeInstallationStep app={app} startExpanded={startExpanded} />
        <ConversionCodeInstallationStep app={app} />
      </AccordionsWrapper>
      <HorizontalFlexWrap>
        <InstallationCompletedButton app={app} />
        {isInstallationCompleted && <SuccessIcon />}
      </HorizontalFlexWrap>
    </AppWrapper>
  );
};

const AppWrapper = styled(Gap16VerticalFlex)`
  margin-bottom: 40px;
`;

const AccordionsWrapper = styled(Gap8VerticalFlex)``;

const SuccessIcon = styled(CheckCircleIcon)`
  fill: ${({ theme }) => theme.colors.success};
`;

export default DefaultAppInstallationSteps;
