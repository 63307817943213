import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { FEATURE_EXPORT_ANALYTICS } from 'app/src/constants/appFeatures.constants';
import { useAnalytics } from 'app/src/context/AnalyticsStore';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { track } from 'app/src/helpers/Tracker';
import AnalyticsTable from '../AnalyticsTable';
import { getOrdersTableColumns } from 'app/src/complex_components/analytics/conversion/getOrdersTableColumns';
import { ANALYTICS_TABLE_KEYS } from 'app/src/constants/analytics.constants';
import { useDrawerActions } from 'src/context/ui_store/DrawerStore';
import { DesignSizes } from 'shared/react/theme/DesignSystem';
import useAnalyticsOverview from 'app/src/hooks/useAnalyticsOverview';
import AnalyticsMetrics from 'app/src/complex_components/analytics/AnalyticsMetrics';

const TOP_METRICS = [
  {
    id: 'fmtTolstoyOrdersTotalCount',
    color: 'neutralBlack',
    formatted: true,
    mainTitle: 'Tolstoy Orders',
  },
  {
    id: 'tolstoyOrdersOfTotalOrders',
    color: 'neutralBlack',
    formatted: false,
    isPercentage: true,
    mainTitle: 'Tolstoy Orders of Total Orders',
  },
];

const AnalyticsConversionOrdersTable = () => {
  const [{ conversionOrders, isLoadingConversionOrders }] = useAnalytics();
  const analyticsOverviewData = useAnalyticsOverview();

  const { clearDrawerState } = useDrawerActions();
  const [isLoading, setLoading] = useState(false);
  const [, { getFeatureEnabled }] = useFeatures();
  const isExportEnabled = getFeatureEnabled(FEATURE_EXPORT_ANALYTICS);
  const columns = getOrdersTableColumns();

  const trackClick = () => {
    track('Account Analytics Export to CSV Clicked');
  };

  useEffect(() => {
    return () => {
      clearDrawerState();
    };
  }, []);

  return (
    <LayoutRoot>
      <StyledAnalyticsMetrics
        size={DesignSizes.SMALL}
        data={analyticsOverviewData}
        metrics={TOP_METRICS}
        loading={isLoading}
      />
      <AnalyticsTable
        rows={conversionOrders}
        columns={columns}
        isExportEnabled={isExportEnabled}
        isLoading={isLoadingConversionOrders || isLoading}
        setLoading={setLoading}
        onDownloadCsv={trackClick}
        tableName={ANALYTICS_TABLE_KEYS.orders}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)``;

const StyledAnalyticsMetrics = styled(AnalyticsMetrics)`
  width: 50%;
  & > *:first-child {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    & > * {
      max-width: unset;
      width: 100%;
    }
  }
`;

export default AnalyticsConversionOrdersTable;
