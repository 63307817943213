import React from 'react';

const HowtoIcon = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 0C15.0938 0 16 0.90625 16 2V11C16 12.0938 15.0938 13 14 13H9.5L5.59375 15.875C5.34375 16.0625 5 15.9062 5 15.5938V13H2C0.875 13 0 12.125 0 10.9688V2C0 0.90625 0.875 0 2 0H14ZM14.5 11V2C14.5 1.75 14.25 1.5 14 1.5H2C1.71875 1.5 1.5 1.75 1.5 2V11C1.5 11.2812 1.71875 11.5 2 11.5H6.5V13.375L9 11.5H14C14.25 11.5 14.5 11.2812 14.5 11ZM7.78125 8.375C8.25 8.375 8.625 8.75 8.625 9.1875C8.625 9.65625 8.25 10 7.78125 10C7.34375 10 7 9.65625 7 9.1875C7 8.75 7.34375 8.375 7.78125 8.375ZM8.65625 3C9.6875 3 10.5 3.8125 10.5 4.8125C10.5 5.4375 10.1562 6.03125 9.59375 6.375L8.40625 7.09375V7.15625C8.40625 7.46875 8.125 7.75 7.78125 7.75C7.4375 7.75 7.15625 7.46875 7.15625 7.15625V6.71875C7.15625 6.53125 7.25 6.3125 7.46875 6.1875L8.9375 5.3125C9.125 5.21875 9.21875 5.03125 9.21875 4.8125C9.21875 4.5 8.96875 4.25 8.65625 4.25H7.3125C7 4.25 6.75 4.5 6.75 4.8125C6.75 5.15625 6.4375 5.4375 6.125 5.4375C5.78125 5.4375 5.5 5.15625 5.5 4.8125C5.5 3.8125 6.28125 3 7.3125 3H8.65625Z"
        fill="#2F3137"
      />
    </svg>
  );
};

export default HowtoIcon;
