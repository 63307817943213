import React, { forwardRef, HTMLAttributes, ReactNode } from 'react';
import { cn } from 'src/utils/styles';
import { Label } from '@tolstoy/ui-library/core';

export enum LayoutType {
  Row = 'row',
  Column = 'column',
}

interface InputFieldProps extends HTMLAttributes<HTMLDivElement> {
  label: string;
  isRow?: boolean;
  children: ReactNode;
}

const InputField = forwardRef<HTMLDivElement, InputFieldProps>(
  ({ label, children, className, isRow = false, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        `grid w-full gap-2`,
        isRow && 'grid-flow-col items-center',
        className
      )}
      {...props}
    >
      <Label>{label}</Label>
      {children}
    </div>
  )
);

InputField.displayName = 'InputField';

export default InputField;
