import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useFeedSnackbar from 'shared/react/components/complex/context/hooks/useFeedSnackbar';
import { Gap16HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import CircleCheck from 'shared/react/images/share/CircleCheck';
import { TextSmall } from 'shared/react/components/complex/Text';

const SNACKBAR_LIFETIME = 3000;
const SNACKBAR_ANIMATION_TIME = 150;
const SNACKBAR_LIFETIME_WITH_ANIMATION = SNACKBAR_LIFETIME + SNACKBAR_ANIMATION_TIME;

const FeedGlobalSnackbar = () => {
  const [{ snackbarText }, { setSnackbarText }] = useFeedSnackbar();
  const [isOpen, setIsOpen] = useState(!!snackbarText);

  useEffect(() => {
    if (!snackbarText) {
      return;
    }

    setIsOpen(true);

    setTimeout(() => {
      setIsOpen(false);
    }, SNACKBAR_LIFETIME);

    setTimeout(() => {
      setSnackbarText('');
    }, SNACKBAR_LIFETIME_WITH_ANIMATION);
  }, [snackbarText]);

  return (
    <LayoutRoot isOpen={isOpen}>
      <Content>
        <CircleCheck />
        <SnackbarText>{snackbarText}</SnackbarText>
      </Content>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  position: fixed;
  top: 16px;
  right: 0;
  width: 25vw;
  transition: ${SNACKBAR_ANIMATION_TIME}ms;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
  z-index: 14;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    width: 100%;
  }
`;

const Content = styled(Gap16HorizontalFlex)`
  align-items: center;
  padding: 23px 16px;
  background: ${({ theme }) => theme.colors.successLight};
  justify-content: center;
  margin: 16px;
  border-radius: 12px;
`;

const SnackbarText = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.successDark};
  font-weight: 700;
  line-height: 19px;
`;

export default FeedGlobalSnackbar;
