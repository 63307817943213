import React from 'react';

const BottomArrowIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="17"
      fill="none"
      viewBox="0 0 19 17"
      {...props}
    >
      <path
        fill="#B3B6BC"
        d="M1.406 7.57a.662.662 0 00.04.899l8.124 7.5a.704.704 0 00.86 0l8.125-7.5a.662.662 0 00.039-.899.662.662 0 00-.899-.039L10 14.641 2.305 7.53a.587.587 0 00-.43-.156.72.72 0 00-.469.195z"
      />
    </svg>
  );
};

export default BottomArrowIcon;
