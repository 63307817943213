import React from 'react';
import styled from 'styled-components';

import Rule from 'app/src/pages/project/pages/rules_and_triggers/editor/shared/Rule';

function RuleGroup({
  invalidRules,
  clearInvalidRules,
  orConditions,
  setRuleGroup,
  andConditions,
  andConditionIndex,
  onSave,
  attributes,
  setAttributes,
}) {
  function isInvalid(orConditionIndex) {
    return invalidRules?.some(
      invalidRule =>
        invalidRule.andConditionIndex === andConditionIndex &&
        invalidRule.orConditionIndex === orConditionIndex
    );
  }

  return (
    <LayoutRoot>
      {orConditions.map((rule, orConditionIndex) => (
        <Rule
          isInvalid={isInvalid(orConditionIndex)}
          setRuleGroup={setRuleGroup}
          clearInvalidRules={clearInvalidRules}
          rule={rule}
          key={rule.key}
          andConditions={andConditions}
          ruleKey={rule.key}
          andConditionIndex={andConditionIndex}
          orConditionIndex={orConditionIndex}
          orConditions={orConditions}
          onSave={onSave}
          attributes={attributes}
          setAttributes={setAttributes}
        />
      ))}
    </LayoutRoot>
  );
}

export default RuleGroup;

const LayoutRoot = styled.div`
  display: grid;
  gap: 16px;
  width: 100%;
`;
