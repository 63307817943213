import React from 'react';

const SmsIcon = props => (
  <svg
    width={32}
    height={33}
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28 0H4C1.75 0 0 1.813 0 4v18c0 2.25 1.75 4 4 4h6v5.25c0 .625.688 1 1.188.625L19 26h9c2.188 0 4-1.75 4-4V4c0-2.188-1.813-4-4-4ZM10.437 15c-.25 1.25-1.312 2-2.874 2-.25 0-.5 0-.75-.063-.5-.062-1.063-.25-1.5-.375-.5-.187-.875-.812-.688-1.312.188-.563.688-.875 1.188-.688.437.188.875.376 1.312.438.688.125 1.313-.063 1.313-.313.062-.374-.688-.5-1.5-.75-.75-.25-2.813-.812-2.438-2.874.25-1.5 1.688-2.25 3.625-2 .375.062.813.187 1.188.312.5.188.812.75.624 1.313-.187.5-.75.812-1.312.624-.25-.124-.563-.187-.813-.25-.687-.124-1.312.063-1.312.313-.063.375.563.5 1.25.688 1.063.312 3.063.874 2.688 2.937ZM20 16c0 .563-.5 1-1 1-.563 0-1-.438-1-1v-3l-1.25 1.625c-.375.5-1.188.5-1.563 0L14 13v3c0 .563-.5 1-1 1-.563 0-1-.438-1-1v-6c0-.375.25-.813.625-.938.438-.124.875 0 1.125.376L16 12.374l2.188-2.938c.25-.374.687-.5 1.125-.374.375.124.687.562.687.937v6Zm7.438-1c-.25 1.25-1.313 2-2.875 2-.25 0-.5 0-.75-.063-.5-.062-1.063-.25-1.5-.375-.5-.187-.875-.812-.688-1.312.188-.563.688-.875 1.188-.688.437.188.875.376 1.312.438.688.125 1.313-.063 1.313-.313.062-.374-.688-.5-1.5-.75-.75-.25-2.813-.812-2.438-2.874.25-1.5 1.688-2.25 3.625-2 .375.062.813.187 1.188.312.5.188.812.75.625 1.313-.188.5-.75.812-1.313.624-.25-.124-.563-.187-.813-.25-.687-.124-1.312.063-1.312.313-.063.375.563.5 1.25.688 1.063.312 3.063.874 2.688 2.937Z"
      fill="#01BE81"
    />
  </svg>
);

export default SmsIcon;
