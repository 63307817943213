import React from 'react';
import styled from 'styled-components';

import { TextSmall } from 'shared/react/components/basic/text/TextV2';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import CodeBracketsIcon from 'src/images/creation_method_icons/CodeBracketsIcon';
import BackArrowIcon from 'src/images/dashboard_v2/BoldArrowIcon';
import { useApps } from 'src/context/AppsStore';

type InstallationMethodToggleProps = {
  onInstallationMethodToggle: () => void;
  showManualInstallCode: boolean;
  isDisabled: boolean;
};

type InstallationToggleProps = {
  showManualInstallCode: boolean;
};

const InstallationMethodToggle = ({
  onInstallationMethodToggle,
  showManualInstallCode,
  isDisabled,
}: InstallationMethodToggleProps) => {
  const [{ shopify: isShopify }] = useApps();

  const onToggleClick = () => {
    if (isDisabled) {
      return;
    }

    onInstallationMethodToggle();
  };

  if (!isShopify) {
    return null;
  }

  return (
    <LayoutRoot onClick={onToggleClick} isDisabled={isDisabled}>
      <InstallationToggle showManualInstallCode={showManualInstallCode} />
    </LayoutRoot>
  );
};

const InstallationToggle = ({ showManualInstallCode }: InstallationToggleProps) => {
  if (showManualInstallCode) {
    return (
      <>
        <BackArrowIcon width="14px" height="14px" />
        <LinkText>Back to use Shopify section</LinkText>
      </>
    );
  }

  return (
    <>
      <CodeBracketsIcon />
      <LinkText>Use code instead</LinkText>
    </>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlex)<{ isDisabled: boolean }>`
  & div {
    color: ${({ isDisabled, theme }) => (isDisabled ? theme.colors.neutralGray : '')};
  }

  & > svg {
    & path {
      fill: ${({ isDisabled, theme }) =>
        isDisabled ? theme.colors.neutralGray : theme.colors.blue1};
    }
  }
  cursor: ${({ isDisabled }) => (isDisabled ? 'unset' : 'pointer')};
  align-items: center;
`;

const LinkText = styled(TextSmall)`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.blue1};
`;

export default InstallationMethodToggle;
