import React, { useState } from 'react';
import StraightArrowRightIcon from 'app/src/images/creation_method_icons/StraightArrowRightIcon';
import { track } from 'app/src/helpers/Tracker';
import CheckMarkIcon from 'app/src/images/CheckMarkIcon';
import useNavigation from 'app/src/hooks/useNavigation';
import useAccountInstallation from 'app/src/hooks/useAccountInstallation';
import styled from 'styled-components';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { SuccessButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { app } from 'app/src/types/entities';

type Props = {
  appUrl: string;
  currentApp: app;
};

const ShopifyInstallDescription = ({ currentApp, appUrl }: Props) => {
  const { navigateToAccountInstall } = useNavigation();
  const [isLoading, setIsLoading] = useState(false);
  const [{ isInstallationCompleted: isAccountInstallationCompleted, toggleAccountInstallation }] =
    useAccountInstallation(currentApp?.id);

  const onAccountInstallationClick = () => {
    const accountQueryString = `?appUrl=${appUrl}`;

    track('Navigate To Account Installation Click');
    navigateToAccountInstall(accountQueryString);
  };

  const onCompletedClick = async () => {
    setIsLoading(true);
    await toggleAccountInstallation();
    setIsLoading(false);
  };

  const getCompleteButtonText = () => {
    if (isAccountInstallationCompleted) {
      return 'Completed';
    }

    return (
      <>
        Complete
        <CheckMarkIcon />
      </>
    );
  };

  return (
    <div>
      <AdvancedInstallContainer onClick={onAccountInstallationClick}>
        <DescriptionText>Advanced installation</DescriptionText>
        <StraightArrowRightIcon />
      </AdvancedInstallContainer>
      <CompleteButtonContainer>
        <CustomSuccessButton
          disabled={isAccountInstallationCompleted}
          onClick={onCompletedClick}
          isLoading={isLoading}
        >
          {getCompleteButtonText()}
        </CustomSuccessButton>
      </CompleteButtonContainer>
    </div>
  );
};

const DescriptionText = styled(TextTiny)`
  font-weight: 600;
`;

const AdvancedInstallContainer = styled(HorizontalFlex)`
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  padding: 6px 10px;
  gap: 6px;

  & svg {
    transform: rotate(-45deg);
  }

  & path {
    fill: ${({ theme }) => theme.colors.black};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.neutralBlack};
  }
`;

const CompleteButtonContainer = styled(HorizontalFlex)`
  justify-content: flex-end;
`;

const CustomSuccessButton = styled(SuccessButton).attrs(({ theme }) => ({
  size: theme.designSizes.small,
}))``;

export default ShopifyInstallDescription;
