import React from 'react';

function CIcon({ fill, darkBackground, size = 20 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={12} cy={12} r={12} fill={fill || '#FFFFFF'} />
      <path
        d="M12.3 15.61c1.596 0 2.938-.924 3.642-2.288l-1.397-.737c-.407.858-1.177 1.496-2.277 1.496-1.474 0-2.574-1.155-2.574-2.596 0-1.474 1.11-2.618 2.607-2.618 1.023 0 1.727.506 2.178 1.342l1.342-.858A4.057 4.057 0 0 0 12.3 7.36c-2.321 0-4.125 1.727-4.125 4.125 0 2.288 1.639 4.125 4.125 4.125Z"
        fill={darkBackground ? '#FFFFFF' : '#2F3137'}
      />
    </svg>
  );
}

export default CIcon;
