import React from 'react';
import styled from 'styled-components';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { TextH5, TextSubtitle } from 'shared/react/components/complex/Text';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import ApiKey from './ApiKey';
import AppKey from './AppKey';
import Plan from './Plan';
import LanguageSelector from './LanguageSelector';
import BasicInput from 'app/src/basic_components/BasicInput';
import InfoIconWithTooltip from 'app/src/complex_components/InfoIconWithTooltip';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';

const AccountInformation = ({
  packageType,
  multipassSecret,
  isShopifyPlus,
  updateMultipassSecret,
  onUpdate,
  loading,
}) => {
  return (
    <LayoutRoot>
      <TitleContainer>
        <TextH5>Account information</TextH5>
      </TitleContainer>
      <Gap16VerticalFlex>
        <Gap8VerticalFlex>
          <TextSubtitle>API key</TextSubtitle>
          <ApiKey />
        </Gap8VerticalFlex>
        <Gap8VerticalFlex>
          <TextSubtitle>App key</TextSubtitle>
          <AppKey />
        </Gap8VerticalFlex>
        <Gap8VerticalFlex isVisible={!!packageType}>
          <TextSubtitle>Plan</TextSubtitle>
          <Plan packageType={packageType} />
        </Gap8VerticalFlex>
        <Gap8VerticalFlex>
          <TextSubtitle>App Language</TextSubtitle>
          <TextTinyGrey>{`This will impact the language you see in the Tolstoy dashboard. To change what your customers see, go to Branding > Settings`}</TextTinyGrey>
          <LanguageSelector />
        </Gap8VerticalFlex>
        <Gap8VerticalFlex>
          <InfoItem>
            <InfoItemTitleContainer>
              <InfoItemTitle>Multipass Secret</InfoItemTitle>
              <InfoIconWithTooltip title={'Available only for Shopify Plus'} />
            </InfoItemTitleContainer>
            <StyledBasicInput
              value={multipassSecret}
              disabled={loading || !isShopifyPlus}
              onChange={e => {
                updateMultipassSecret(e.target.value);
                onUpdate();
              }}
            />
          </InfoItem>
        </Gap8VerticalFlex>
      </Gap16VerticalFlex>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)``;

const TextTinyGrey = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralDark};
`;

const TitleContainer = styled.div``;
const InfoItem = styled(VerticalFlex)`
  gap: 4px;
  align-items: start;
`;

const InfoItemTitleContainer = styled(Gap8HorizontalFlexWrap)`
  min-width: 120px;
`;

const InfoItemTitle = styled(TextSubtitle)``;

const StyledBasicInput = styled(BasicInput)`
  border: 1px solid ${({ theme }) => theme.colors.gray3};
  background: ${({ theme }) => theme.colors.white};
  padding: 12px;
  border-radius: 10px;
  max-width: 412px;
  width: 100%;
`;

export default AccountInformation;
