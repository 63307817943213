import React, { useEffect } from 'react';
import styled from 'styled-components';
import { EDIT_STEP_LAYOUT } from 'app/src/constants/builder.constants';
import { useParams } from 'react-router-dom';
import { useProjects } from 'app/src/context/ProjectsStore';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import BuilderEditStep from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/BuilderEditStep';
import TolstoyList from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/tolstoy_list/TolstoyList';
import useNavigation from 'app/src/hooks/useNavigation';
import { getIsDerivedProject, getSteps } from 'app/src/utils/project.utils';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import { publishMethodOptions } from 'app/src/types/entities';
import DerivedProjectGrid from './tolstoy_list/DerivedProjectGrid';

const Builder = () => {
  const { projectId, stepId } = useParams();
  const [currentProjectState] = useProjects({ projectId, stepId });
  const sourceProjectId = currentProjectState.project?.sourceProjectId;
  const [sourceProjectState] = useProjects({ projectId: sourceProjectId });
  const isDerivedProject = getIsDerivedProject(currentProjectState.project);
  const { project, step } = isDerivedProject ? sourceProjectState : currentProjectState;

  const showOnlyBuilder = false;
  const { navigateToProjectTab } = useNavigation();

  const getContent = () => {
    if (isDerivedProject) {
      return <DerivedProjectGrid project={project} />;
    }
    if (!step && !showOnlyBuilder) {
      return <TolstoyList project={project} />;
    }

    return (
      <EditStepLayout>
        <TolstoyList
          layout={EDIT_STEP_LAYOUT}
          project={project}
          selectedStepId={stepId}
          step={step}
        />
        {project.publishMethod !== publishMethodOptions.shopApp && (
          <BuilderEditStep step={step} project={project} />
        )}
      </EditStepLayout>
    );
  };

  useEffect(() => {
    if (step) {
      return;
    }

    const steps = getSteps(project);
    const firstStepId = steps[0]?.id;

    if (firstStepId) {
      navigateToProjectTab(project, firstStepId);
    }
  }, []);

  return <LayoutRoot>{getContent()}</LayoutRoot>;
};

const LayoutRoot = styled(Gap16VerticalFlex)`
  width: 100%;
  height: 100%;
  padding: 0;
  overflow: auto;
  margin-top: 24px;
`;

const EditStepLayout = styled(VerticalFlex)`
  gap: 16px;

  @media (${({ theme }) => theme.breakpoints.laptopMax}) {
    gap: 8px;
  }
`;

export default Builder;
