import React from 'react';
import { useTheme } from 'styled-components';

function ChatResponseIcon({ fill }) {
  const theme = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" fill="none" viewBox="0 0 12 13">
      <g clipPath="url(#clip0)">
        <path
          fill={fill || theme.colors.ghostDark}
          stroke={fill || theme.colors.ghostDark}
          strokeWidth="0.3"
          d="M2.153 9.851a.299.299 0 01-.493-.247l.493.247zm-.51-2.179L1.51 9.593h0a.448.448 0 00.107.322h0c.161.19.446.212.634.05l2.66-2.287h3.022c.53 0 1.013-.217 1.363-.566.35-.35.566-.832.566-1.363v-3.97c0-.53-.216-1.013-.566-1.363A1.924 1.924 0 007.933-.15H1.78A1.92 1.92 0 00.416.417c-.35.35-.566.832-.566 1.363v3.969c0 .53.217 1.013.566 1.362.32.32.75.528 1.227.561zm8.971-4.924l-.194-.038.016.197c.003.033.004.068.005.107v3.968c0 .731-.6 1.33-1.33 1.33H5.32l-.044.044-1.76 1.79-.25.255H7.138l2.67 2.363h0l.002.002a.413.413 0 00.68-.34v-.002l-.136-1.957a1.89 1.89 0 001.24-.553c.343-.343.556-.816.556-1.338V4.607c0-.522-.214-.995-.556-1.337a1.896 1.896 0 00-.981-.522zM2.275 6.805v-.027H1.78c-.283 0-.54-.115-.727-.303A1.026 1.026 0 01.75 5.75v-3.97c0-.282.115-.539.303-.726C1.24.865 1.497.75 1.78.75h6.152c.283 0 .54.115.726.303h0c.188.187.303.444.303.727v3.969c0 .282-.115.539-.302.726a1.026 1.026 0 01-.727.303H4.746a.447.447 0 00-.313.127L2.481 8.583l.092-1.324h0a.45.45 0 00-.298-.454zM2.207 4.22c0 .249.202.45.45.45h3.14a.45.45 0 000-.9h-3.14a.45.45 0 00-.45.45zm0-1.485c0 .248.202.45.45.45h4.451a.45.45 0 000-.9H2.657a.45.45 0 00-.45.45z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H12V12.714H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default ChatResponseIcon;
