import React, { useEffect, useState } from 'react';

import { PageContainer } from 'app/src/pages/dashboard/components/page/Page';
import { track } from 'app/src/helpers/Tracker';
import { VideosPageVodAsset } from 'app/src/pages/dashboard/pages/videos/types/videos.types';
import { WhereCondition } from 'app/src/pages/dashboard/utils/filterEntities';
import { VideosSidebarWithLazyCounts } from 'app/src/pages/dashboard/pages/videos/components/side-bar/VideosSidebarWithLazyCounts';
import { VodAssetsLibraryPage } from 'app/src/pages/dashboard/pages/videos/components/library-pages/VodAssetsLibraryPage';
import { LibraryPageProps } from 'app/src/pages/dashboard/pages/videos/components/library-pages/library-pages-types';
import {
  getMediaSource,
  MediaLibrarySource,
} from 'app/src/pages/dashboard/pages/videos/hooks/media/mediaLibraryUtils';
import { InstagramLibraryPage } from 'app/src/pages/dashboard/pages/videos/components/library-pages/InstagramLibraryPage';
import { YouTubeLibraryPage } from 'app/src/pages/dashboard/pages/videos/components/library-pages/YouTubeLibraryPage';
import { TikTokLibraryPage } from 'app/src/pages/dashboard/pages/videos/components/library-pages/TikTokLibraryPage';
import { DropboxLibraryPage } from 'app/src/pages/dashboard/pages/videos/components/library-pages/DropboxLibraryPage';
import { GoogleDriveLibraryPage } from 'app/src/pages/dashboard/pages/videos/components/library-pages/GoogleDriveLibraryPage';
import { SynthesiaLibraryPage } from 'app/src/pages/dashboard/pages/videos/components/library-pages/SynthesiaLibraryPage';
import {
  getDuplicateSource,
  SOURCES_UPLOAD_TYPE,
} from 'app/src/constants/videoSources.constants.js';
import { useParams } from 'react-router-dom';

type Props = {
  videosFilters: WhereCondition;
  setVideosFilters: (filters: WhereCondition, forceReplace?: boolean) => void;
  shouldSidebarRedirect?: boolean;
  onVideoChoose?: (video: VideosPageVodAsset) => void;
  className?: string;
  hideSidebar?: boolean;
};

const VideosV2 = ({
  videosFilters,
  setVideosFilters,
  shouldSidebarRedirect = true,
  onVideoChoose,
  className,
  hideSidebar,
}: Props) => {
  const [selectedVideos, setSelectedVideos] = useState<string[]>([]);

  const unselectAll = () => {
    setSelectedVideos([]);
  };

  const toggleSelected = (videoId: string) => {
    if (!selectedVideos.includes(videoId)) {
      track('Video Card Selected');
      setSelectedVideos([...selectedVideos, videoId]);
      return;
    }

    track('Video Card Select Canceled');
    setSelectedVideos(selectedVideos.filter(id => id !== videoId));
  };

  const { source } = useParams<{ source: string }>();
  useEffect(() => {
    const currentUploadType = SOURCES_UPLOAD_TYPE[source];
    if (!currentUploadType) {
      return;
    }

    const duplicatedUploadTypes = currentUploadType.map(getDuplicateSource);
    const uploadType = [...currentUploadType, ...duplicatedUploadTypes];
    setVideosFilters({ ...videosFilters, folder: null, uploadType: { in: uploadType } });
  }, [source]);

  const libraryPageProps: LibraryPageProps = {
    videosFilters: videosFilters,
    setVideosFilters: setVideosFilters,
    onVideoChoose: onVideoChoose,
    selectedVideos: selectedVideos,
    setSelectedVideos: setSelectedVideos,
    toggleSelected: toggleSelected,
  };
  const mediaSource = getMediaSource(videosFilters);

  return (
    <PageContainer className={className}>
      <VideosSidebarWithLazyCounts
        videosFilters={videosFilters}
        setVideosFilters={setVideosFilters}
        unselectAll={unselectAll}
        shouldSidebarRedirect={shouldSidebarRedirect}
        hideSidebar={hideSidebar}
      />
      {!mediaSource && <VodAssetsLibraryPage {...libraryPageProps} />}
      {mediaSource === MediaLibrarySource.INSTAGRAM && (
        <InstagramLibraryPage {...libraryPageProps} />
      )}
      {mediaSource === MediaLibrarySource.YOUTUBE && <YouTubeLibraryPage {...libraryPageProps} />}
      {mediaSource === MediaLibrarySource.TIKTOK && <TikTokLibraryPage {...libraryPageProps} />}
      {mediaSource === MediaLibrarySource.DROPBOX && <DropboxLibraryPage {...libraryPageProps} />}
      {mediaSource === MediaLibrarySource.GOOGLE_DRIVE && (
        <GoogleDriveLibraryPage {...libraryPageProps} />
      )}
      {mediaSource === MediaLibrarySource.SYNTHESIA && (
        <SynthesiaLibraryPage {...libraryPageProps} />
      )}
    </PageContainer>
  );
};

export default VideosV2;
