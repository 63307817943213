import React, { useEffect, useState } from 'react';
import DefaultFieldsEditor from 'app/src/pages/project/pages/look_and_feel/editor/editors/lead_form_editor/DefaultFieldsEditor';
import EditorGroup from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorGroup';
import { LeadFormEditorGroupProvider } from 'app/src/pages/project/pages/look_and_feel/editor/editors/lead_form_editor/LeadFormEditorGroupContext';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import ComponentWithLoader from 'app/src/complex_components/ComponentWithLoader';
import CustomFieldsEditor from 'app/src/pages/project/pages/look_and_feel/editor/editors/lead_form_editor/CustomFieldsEditor';
import ConsentFieldEditor from 'app/src/pages/project/pages/look_and_feel/editor/editors/lead_form_editor/ConsentFieldEditor';
import CollectionSettingsEditor from 'app/src/pages/project/pages/look_and_feel/editor/editors/lead_form_editor/CollectionSettingsEditor';
import AppearanceEditor from 'app/src/pages/project/pages/look_and_feel/editor/editors/lead_form_editor/AppearanceEditor';
import {
  COLLECT_FIELDS_PROPERTY_KEY,
  CONSENT_KEY,
  EMAIL_KEY,
  NAME_KEY,
  PHONE_KEY,
} from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';

function LeadFormEditorGroup() {
  const {
    customizationSettings: { collectFields },
    setCustomizationSettingsProperty,
  } = useLookAndFeelContext();

  const [leadFormSettings, setLeadFormSettings] = useState(null);

  useEffect(() => {
    let nameField;
    let emailField;
    let phoneField;
    let consentField;
    let customCollectFields = [];

    for (let field of collectFields) {
      switch (field.key) {
        case CONSENT_KEY:
          consentField = field;
          break;
        case NAME_KEY:
          nameField = field;
          break;
        case EMAIL_KEY:
          emailField = field;
          break;
        case PHONE_KEY:
          phoneField = field;
          break;
        default:
          customCollectFields.push(field);
          break;
      }
    }

    setLeadFormSettings({
      predefinedFields: [nameField, emailField, phoneField],
      customFields: [...customCollectFields],
      consentField,
    });
  }, [collectFields]);

  const handleCollectFieldPropertyChanged = (key, propertyKey, value) => {
    const collectField = collectFields.find(collectField => collectField.key === key);

    collectField[propertyKey] = value;

    setCustomizationSettingsProperty(COLLECT_FIELDS_PROPERTY_KEY, [...collectFields]);
  };

  const getProviderValue = () => {
    return {
      leadFormSettings,
      setCollectAfterAnyResponse: value => {
        setCustomizationSettingsProperty('collectAfterAnyResponse', value);
      },
      setCollectFields: collectFields => {
        setCustomizationSettingsProperty(COLLECT_FIELDS_PROPERTY_KEY, [...collectFields]);
      },
      handleCollectFieldPropertyChanged,
    };
  };

  return (
    <LeadFormEditorGroupProvider value={getProviderValue()}>
      <ComponentWithLoader isLoading={!leadFormSettings}>
        <EditorGroup
          editors={[
            { key: 'appearance', component: <AppearanceEditor /> },
            { key: 'collection-settings', component: <CollectionSettingsEditor /> },
            { key: 'default-fields', component: <DefaultFieldsEditor /> },
            { key: 'custom-fields', component: <CustomFieldsEditor /> },
            { key: 'consent-field', component: <ConsentFieldEditor /> },
          ]}
        />
      </ComponentWithLoader>
    </LeadFormEditorGroupProvider>
  );
}

export default LeadFormEditorGroup;
