import React from 'react';

const CogIcon = ({ fill = '#2F3137' }) => {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.602 4.414c.093.211.023.422-.141.586l-1.008.914c.024.188.024.399.024.586 0 .21 0 .422-.024.61l1.008.913a.503.503 0 0 1 .14.586c-.093.282-.21.54-.351.797l-.117.188a5.719 5.719 0 0 1-.516.726c-.14.188-.375.235-.586.164l-1.289-.398c-.328.234-.68.422-1.031.586l-.305 1.336a.555.555 0 0 1-.422.422 7.034 7.034 0 0 1-1.992 0 .555.555 0 0 1-.422-.422l-.304-1.336a5.58 5.58 0 0 1-1.032-.586l-1.289.398a.541.541 0 0 1-.586-.14 8.633 8.633 0 0 1-.515-.75l-.117-.188a4.84 4.84 0 0 1-.352-.797c-.094-.21-.023-.421.14-.586l1.008-.914C1.5 6.922 1.5 6.711 1.5 6.5c0-.188 0-.398.023-.586L.516 5C.352 4.836.28 4.625.375 4.414c.094-.281.21-.539.352-.797l.117-.187c.164-.258.328-.516.515-.75a.541.541 0 0 1 .586-.14l1.29.397c.328-.234.68-.445 1.03-.585l.305-1.336a.555.555 0 0 1 .422-.422A7 7 0 0 1 6 .5c.328 0 .656.047.984.094.211.023.375.21.422.422l.305 1.336c.375.14.703.351 1.031.586l1.29-.399a.541.541 0 0 1 .585.14c.188.235.352.493.516.75l.117.188c.14.258.258.516.375.797h-.023ZM6 8.375c1.031 0 1.875-.82 1.875-1.875A1.88 1.88 0 0 0 6 4.625 1.866 1.866 0 0 0 4.125 6.5 1.85 1.85 0 0 0 6 8.375Z"
        fill={fill}
      />
    </svg>
  );
};

export default CogIcon;
