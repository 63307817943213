import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import FormInput from 'app/src/complex_components/FormInput';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { useDebounce } from 'app/src/hooks/useDebounce';
import { track } from 'app/src/helpers/Tracker';
import TikTokWithNameIcon from 'app/src/images/TikTokWithNameIcon';
import Tooltip from 'app/src/basic_components/Tooltip';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';
import { useVideos } from 'app/src/context/VideosStore';
import { v4 as uuidv4 } from 'uuid';
import TikTokImportFromLink from 'app/src/pages/creation_flow/right_section/import_tiktok_link/TikTokImportFromLink';
import TikTokImportFromAccount from 'app/src/pages/creation_flow/right_section/import_tiktok_link/importFromAccount/TikTokImportFromAccount';
import { useTikTok } from 'app/src/context/TikTokStore';
import { useApps } from 'app/src/context/AppsStore';
import { useInfiniteScroll } from 'app/src/hooks/useInfiniteScroll';
import { VIDEO_STATUS } from 'shared/react/constants/video.constants';
import HorizontalFlex, {
  Gap16HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import BasicButton from 'shared/react/components/basic/button/button/BasicButton';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { TIKTOK } from 'app/src/constants/intergrations.constants';
import { getHandleFromUrl } from 'shared/react/utils/social';

const Errors = {
  badLink: 'Please enter a valid TikTok url',
  serverError: 'There have been an error, Please try again later',
  null: '',
};

const ImportTikTok = ({ onVideoChoose, isLoading }) => {
  const [linkUrl, setLinkUrl] = useState('');
  const [videoUrl, setVideoUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isImportingVideo, setImportingVideo] = useState(false);
  const [consentChecked, setConsentChecked] = useState(false);
  const [error, setError] = useState('');
  const [{ gotAllVideos }, { downloadTikTokVideo, checkTiktokUrl, fetchByApps }] = useTikTok();
  const [, { setErrorSnackbar }] = useSnackBar();
  const [{ data: videos }, { createVideo, deleteVideo }] = useVideos();
  const [{ tiktokApps }] = useApps();
  const [
    {
      modalProps: { videoFolder },
    },
  ] = useModal();
  const debouncedLinkUrl = useDebounce(linkUrl, 600);
  const containerRef = useRef(null);
  const isImportByUrl = true;

  const fetchMoreVideos = async () => {
    if (!gotAllVideos) {
      await fetchByApps(tiktokApps);
    }
  };
  useInfiniteScroll(containerRef, fetchMoreVideos);

  const getVideoFromLink = async () => {
    if (!debouncedLinkUrl) {
      return;
    }
    setVideoUrl('');
    const linkUrl = debouncedLinkUrl.trim();
    if (checkTiktokUrl(linkUrl)) {
      setError(Errors.badLink);
      return;
    }

    setLoading(true);

    setVideoUrl(debouncedLinkUrl);
    setLoading(false);
  };

  useEffect(() => {
    getVideoFromLink();
  }, [debouncedLinkUrl]);

  const handleLinkChanged = e => {
    const value = e.target.value;
    setError(null);

    if (!value) {
      setVideoUrl(null);
    }

    setLinkUrl(value.trim());
  };

  const handleImportVideo = async () => {
    track('TikTok Import Url Click');
    setImportingVideo(true);

    const name = `Recorded Video #${videos.length + 1}`;
    const uuid = uuidv4();

    const video = await createVideo({
      id: uuid,
      name,
      status: VIDEO_STATUS.uploading,
      uploadType: 'tikTok',
      mirror: false,
      folder: videoFolder,
      externalProviderData: JSON.stringify({
        provider: TIKTOK,
        handle: getHandleFromUrl({ url: linkUrl }),
      }),
    });

    if (!video) {
      setErrorSnackbar('Error downloading the video, Please try again later');
      setImportingVideo(false);
      return;
    }

    const res = await downloadTikTokVideo(videoUrl, video.owner, video.id, linkUrl);

    if (!res) {
      setErrorSnackbar('Error downloading the video, Please try again later');
      setImportingVideo(false);
      await deleteVideo({ id: video.id });
      return;
    }

    await onVideoChoose(video);

    setImportingVideo(false);
  };

  const getContent = () => {
    if (isImportByUrl) {
      return;
    }

    return <TikTokImportFromAccount isLoading={isLoading} />;
  };

  const tooltipText =
    videoUrl && !consentChecked ? 'Agree that you have sharing rights for this content.' : '';

  return (
    <LayoutRoot ref={containerRef}>
      <TopContainer>
        <TikTokIconStyled />
        <ContentInner>
          <UrlWrapper>
            <TextFieldContainer>
              <LinkTextField
                variant="outlined"
                value={linkUrl}
                maxLength={100}
                disabled={loading}
                onChange={handleLinkChanged}
                placeholder="Paste your TikTok video link here"
                autoFocus
              />
            </TextFieldContainer>
            <Tooltip placement="bottom" text={tooltipText}>
              <ImportButton
                onClick={handleImportVideo}
                disabled={!videoUrl || !consentChecked || isImportingVideo}
              >
                Import
              </ImportButton>
            </Tooltip>
          </UrlWrapper>
          {isImportByUrl && (
            <TikTokImportFromLink
              error={error}
              videoUrl={videoUrl}
              loading={loading}
              setConsentChecked={setConsentChecked}
              consentChecked={consentChecked}
              setError={setError}
            />
          )}
        </ContentInner>
      </TopContainer>
      {getContent()}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  flex-grow: 1;
  margin: 16px;
  padding: 16px;
  gap: 32px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.gray5};
  overflow: auto;
`;

const TopContainer = styled(Gap16HorizontalFlex)``;

const TikTokIconStyled = styled(TikTokWithNameIcon)`
  width: 140px;
  height: 40px;
  flex-shrink: 0;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    display: none;
  }
`;

const LinkTextField = styled(FormInput)`
  grid-row: 1;
  grid-column: 1;
  width: 100%;
  border-radius: 8px;
`;

const TextFieldContainer = styled.div`
  display: grid;
  width: 100%;
`;

const ContentInner = styled(VerticalFlex)`
  flex-grow: 1;
`;

const UrlWrapper = styled(HorizontalFlex)`
  position: relative;
  flex-wrap: nowrap;
  margin-bottom: 8px;
`;

const ImportButton = styled(BasicButton)`
  position: absolute;
  right: 4px;
  top: 4px;
  bottom: 4px;
  border-radius: 8px;
`;

export default ImportTikTok;
