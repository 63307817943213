import React from 'react';

const VideoIcon = ({ fill = '#FA536E', background = '#FFE0EC' }) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="24" fill={background} />
      <path
        d="M27 19.5C27 18.6875 26.3125 18 25.5 18H16.5C15.6562 18 15 18.6875 15 19.5V28.5C15 29.3438 15.6562 30 16.5 30H25.5C26.3125 30 27 29.3438 27 28.5V19.5ZM33 20C33 19.2188 32.0625 18.75 31.4062 19.1875L28 21.5625V26.4688L31.4062 28.8438C32.0625 29.2812 33 28.8125 33 28.0312V20Z"
        fill={fill}
      />
    </svg>
  );
};

export default VideoIcon;
