import React from 'react';
import EditorSubheader from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorSubheader';
import EditorButtonGroupItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorButtonGroupItem';
import { IMAGE_BACKGROUND_TYPE, IMAGE_FIT_TYPE } from 'shared/react/constants/feed.constants';

const PRODUCT_IMAGE_FIT_VALUES_LIST = [
  {
    text: 'Fill',
    value: IMAGE_FIT_TYPE.COVER,
  },
  {
    text: 'Fit',
    value: IMAGE_FIT_TYPE.CONTAIN,
  },
];

const PRODUCT_IMAGE_BACKGROUND_VALUES_LIST = [
  {
    text: 'Solid',
    value: IMAGE_BACKGROUND_TYPE.SOLID,
  },
  {
    text: 'Blurry',
    value: IMAGE_BACKGROUND_TYPE.BLURRY,
  },
];

type Props = {
  settings?: any;
  disabled?: boolean;
  onChange?: any;
};

const ProductImageEditor = ({ settings = {}, disabled = false, onChange = () => {} }: Props) => {
  const { feedImageFitType, feedImageBackgroundType } = settings;

  const updateSettings = update => onChange({ ...settings, ...update });
  const handleProductImageFitChange = settings => updateSettings({ feedImageFitType: settings });
  const handleProductImageBackgroundChange = settings =>
    updateSettings({ feedImageBackgroundType: settings });

  const getImageButton = ({ text, value }) => ({
    text: text,
    isEnabled: feedImageFitType === value,
    onClick: () => handleProductImageFitChange(value),
  });
  const imageObjectFitButtons = PRODUCT_IMAGE_FIT_VALUES_LIST.map(getImageButton);

  const getImageBackgroundButton = ({ text, value }) => ({
    text: text,
    isEnabled: feedImageBackgroundType === value,
    onClick: () => handleProductImageBackgroundChange(value),
  });
  const imageBackgroundButtons = PRODUCT_IMAGE_BACKGROUND_VALUES_LIST.map(getImageBackgroundButton);

  return (
    <>
      <EditorSubheader title="Product images" />

      <EditorButtonGroupItem text="Image fit" buttons={imageObjectFitButtons} disabled={disabled} />
      <EditorButtonGroupItem
        text="Image background"
        buttons={imageBackgroundButtons}
        disabled={disabled || feedImageFitType !== IMAGE_FIT_TYPE.CONTAIN}
      />
    </>
  );
};

export default ProductImageEditor;
