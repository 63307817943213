import React from 'react';
import styled from 'styled-components';
import ExpanderBottomArrow from 'shared/react/images/expander_bottom_arrow.svg';
import { NON_BREAKING_SPACE_UNICODE } from 'shared/react/constants/unicode.constants';

const doNothing = () => {};

const FontMenuToggle = React.forwardRef(({ onClick, value, isOpen, disabled, fontWeight }, ref) => {
  return (
    <LayoutRoot ref={ref} onClick={disabled ? doNothing : onClick} isOpen={isOpen}>
      <MainContainer>
        <Title>
          {value?.name || value} {fontWeight ? `- ${fontWeight}` : ''}
        </Title>
        <img src={ExpanderBottomArrow} alt="Expander arrow" />
      </MainContainer>
    </LayoutRoot>
  );
});

FontMenuToggle.displayName = 'FontMenuToggle';

export default FontMenuToggle;

const LayoutRoot = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray3};
  padding: 12px;
  background: white;
  cursor: pointer;
  min-height: 46px;
  min-width: 180px;
  border-radius: 10px 10px ${({ isOpen }) => (isOpen ? '0px 0px' : '10px 10px')};
`;

const MainContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.colors.gray2};
  height: ${({ customHeight }) => (customHeight ? `${customHeight}px` : '100%')};

  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.gray4};
  }

  opacity: ${({ disabled }) => (disabled ? 0.4 : 1.0)};
`;

const Title = styled.div`
  max-width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;

  &::after {
    content: '${NON_BREAKING_SPACE_UNICODE}';
  }
`;
