import { TextField } from '@material-ui/core';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

const Input = forwardRef(({ maxLength, maxWidth, inputProps = {}, onChange, ...props }, ref) => {
  return (
    <CustomTextField
      variant="outlined"
      ref={ref}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{ maxLength: maxLength || 50, ...inputProps }}
      $maxWidth={maxWidth}
      onChange={onChange}
      {...props}
    />
  );
});

Input.displayName = 'Input';

const CustomTextField = styled(TextField)`
  &.MuiFormControl-root {
    border-radius: 4px;
  }

  .MuiOutlinedInput-root {
    border-radius: 4px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme }) => theme.colors.gray3} !important;
  }

  .MuiOutlinedInput-input {
    border-radius: 4px;
  }

  max-width: ${({ $maxWidth }) => $maxWidth || ''};
`;
export default Input;
