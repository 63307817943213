import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { FilterDashedBlock } from 'app/src/pages/dashboard/components/filters/components/filter-block/FilterBlock';
import React, { SyntheticEvent } from 'react';
import { SortDirection } from 'app/src/pages/dashboard/pages/videos/hooks/search/searchRequests';

export type DateSortingMenuProps = {
  dateSortDirection?: SortDirection;
  setDateSortDirection?: (sortDirection: SortDirection) => void;
};

export const DateSortingMenu = ({
  dateSortDirection,
  setDateSortDirection,
}: DateSortingMenuProps) => {
  const isDesc = dateSortDirection === SortDirection.desc;
  const toggleSortingDirection = (event?: SyntheticEvent) => {
    const newDirection = isDesc ? SortDirection.asc : SortDirection.desc;
    setDateSortDirection(newDirection);
  };

  return (
    <>
      <FilterDashedBlock onClick={toggleSortingDirection}>
        {isDesc && <ArrowDownward />}
        {!isDesc && <ArrowUpward />}
        Date
      </FilterDashedBlock>
    </>
  );
};
