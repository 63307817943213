import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/styles';
import styled from 'styled-components';
import { Checkbox } from '@material-ui/core';
import Tooltip from 'app/src/basic_components/Tooltip';
import { TextBody, TextSmall } from 'shared/react/components/complex/Text';
import { QUIZ_TABLE_RECORD_INPUT_DATA_TEST_ID } from 'app/src/constants/dataTestIds.constants';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    padding: 0,
    display: 'flex',
    position: 'relative',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    height: 40,
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const CustomizedTable = ({
  loading,
  headers,
  names,
  onChange,
  quiz,
  selectedKeys,
  setSelectedKeys,
}) => {
  const setKeys = i => {
    if (selectedKeys.includes(i)) {
      if (selectedKeys.length === 1) {
        return setSelectedKeys([]);
      }
      const keys = selectedKeys.filter(key => {
        return key !== i;
      });
      setSelectedKeys(keys);
    } else {
      setSelectedKeys([...selectedKeys, i]);
    }
  };

  return (
    <Container>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headers.map(step => (
                <TableCell key={step}>
                  <BoldHeader>{step}</BoldHeader>
                </TableCell>
              ))}
              <TableCell align="center">
                <BoldHeader>Redirect to link</BoldHeader>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {quiz.map((row, i) => {
              const isRowSelected = selectedKeys.includes(i);
              return (
                <StyledTableRow key={i}>
                  {row.map((value, j) => {
                    const key = `${i}-${j}`;

                    if (j === headers.length) {
                      return (
                        <StyledTableCell key={key} align="left">
                          <CheckboxContainer>
                            <Tooltip text="Select Multiple">
                              <Checkbox checked={isRowSelected} onChange={() => setKeys(i)} />
                            </Tooltip>
                          </CheckboxContainer>
                          <Input
                            data-test-id={QUIZ_TABLE_RECORD_INPUT_DATA_TEST_ID}
                            placeholder="You can link to products, calendars, checkout, etc."
                            value={value}
                            onChange={ev => onChange(ev, i)}
                            maxLength={2000}
                            disabled={loading || isRowSelected}
                          />
                        </StyledTableCell>
                      );
                    }
                    return (
                      <TableCell title={names[value]} key={key} align="left">
                        <CellContainer>
                          <Text>{names[value] || '----'}</Text>
                        </CellContainer>
                      </TableCell>
                    );
                  })}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

const Container = styled.div`
  .MuiTableCell-root {
    padding: 8px;
  }

  .MuiTableContainer-root {
    overflow: unset;
  }
`;

const BoldHeader = styled(TextSmall)`
  font-weight: bold;
`;

const Text = styled(TextBody)`
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const CheckboxContainer = styled.div`
  position: absolute;
  top: 16.5%;
`;

const CellContainer = styled.div``;

const Input = styled.input`
  width: 100%;
  border: none;
  border-radius: 3%;
  height: 100%;
  padding: 14px;
  border-color: blue;
  min-width: 400px;
  padding-left: 40px;
`;

export default CustomizedTable;
