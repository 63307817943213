import React from 'react';
import AnalyticsEmailChartBox from 'app/src/complex_components/analytics/email/AnalyticsEmailChartBox';
import AnalyticsEmailClicksTooltip from './AnalyticsEmailClicksTooltip';
import ChartHeader from '../chart/ChartHeader';
import ComposedChart from '../chart/ComposedChart';
import Utils from 'app/src/utils';
import { ANALYTICS_EMAIL_CHARTS } from 'app/src/constants/analytics.constants';
import { EmailEventsOvertimeRecord } from 'app/src/services/cube/types/getEmailEventsOvertimeQuery.types';

type Props = {
  emailEventsOvertime: EmailEventsOvertimeRecord[] | null;
  isLoadingEmailEventsOvertime: boolean;
};

const AnalyticsEmailClicksOvertime = ({
  emailEventsOvertime,
  isLoadingEmailEventsOvertime,
}: Props) => {
  const { title, bars, lines } = ANALYTICS_EMAIL_CHARTS.EMAIL_CLICKS;
  const totalEmailClicks = Utils.sumBy(emailEventsOvertime, record => record.numOfEmailClicks);

  const formattedTotalEmailClicksRate = Utils.formatNumber({
    value: totalEmailClicks,
    isPercentage: false,
  });

  const meanEmailOpens = Utils.meanBy(emailEventsOvertime, record => record.emailClicksRate);
  const referenceLines = [
    {
      y: meanEmailOpens,
      stroke: 'neutralGrey',
    },
  ];

  const yTickFormatter = (value: string) => {
    return Utils.formatNumber({ value, isPercentage: false });
  };

  return (
    <AnalyticsEmailChartBox shouldShowLoader={isLoadingEmailEventsOvertime}>
      <ChartHeader subtitle={formattedTotalEmailClicksRate} title={title} />
      <ComposedChart
        data={emailEventsOvertime || []}
        bars={bars}
        lines={lines}
        margin={{ left: -25 }}
        referenceLines={referenceLines}
        renderCustomTooltip={AnalyticsEmailClicksTooltip}
        shouldShowEmptyState={!emailEventsOvertime}
        yTickFormatter={yTickFormatter}
      />
    </AnalyticsEmailChartBox>
  );
};

export default AnalyticsEmailClicksOvertime;
