import React, { useEffect, useRef, useState } from 'react';
import { PrimaryButton, Button } from 'shared/react/components/basic/button-v2/BasicButton';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextTiny, TextSmall } from 'shared/react/components/basic/text/TextV2';
import HorizontalFlex, {
  Gap8HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import MenuWithContainer from 'shared/react/components/basic/MenuWithContainer';
import BasicRadio from 'shared/react/components/basic/BasicRadio';

type Props = {
  anchorEl: null | HTMLElement;
  product: any;
  open: boolean;
  onDone: (optionId: string) => void;
  onClose: () => void;
};

const AddNewVariant = ({ product, anchorEl, open, onDone, onClose }: Props) => {
  const { options = [], variants = [] } = product;
  const [selectedOptions, setSelectedOptions] = useState({});
  const ref = useRef<HTMLDivElement>();
  const isOptionSelected = (key, value) => selectedOptions[key] === value;

  const selectOption = (key, value) => setSelectedOptions({ ...selectedOptions, [key]: value });
  const selectedVariant = variants.find(variant => {
    return Object.entries(selectedOptions).every(
      ([key, value]) => variant.selectedOptions[key] === value
    );
  });

  const handleDone = () => {
    onDone(`${selectedVariant?.id}`);
  };

  useEffect(() => {
    if (!open) {
      return;
    }

    const initialState = Object.entries(options).reduce(
      (acc, [key, option]: [any, any]) => ({
        ...acc,
        [key]: option.selectedValue || options.values[0],
      }),
      {}
    );
    setSelectedOptions(initialState);
  }, [open, options]);

  return (
    <MenuWithContainer
      open={open}
      anchorEl={anchorEl}
      direction={{ vertical: 'bottom', horizontal: 'left' }}
      onClose={onClose}
      className=""
    >
      <LayoutRoot>
        <Content ref={ref}>
          {Object.entries(options).map(([optionKey, option]: [any, any]) => (
            <OptionsGroup key={optionKey}>
              <OptionGroupTitle key={optionKey}>{option.name}</OptionGroupTitle>
              {option.values.map(value => (
                <OptionContainer key={value} onClick={() => selectOption(optionKey, value)}>
                  <BasicRadio checked={isOptionSelected(optionKey, value)} />
                  <OptionLabel key={value}>{value}</OptionLabel>
                </OptionContainer>
              ))}
            </OptionsGroup>
          ))}
        </Content>
        <Actions>
          <Button onClick={onClose}>Cancel</Button>
          <PrimaryButton onClick={handleDone} disabled={!selectedVariant}>
            Done
          </PrimaryButton>
        </Actions>
      </LayoutRoot>
    </MenuWithContainer>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  min-width: 200px;
  max-width: 500px;
  z-index: 2000;
  border: 1px solid ${({ theme }) => theme.colors.neutralLight};
`;

const Content = styled(VerticalFlex)`
  padding: 8px;
  max-height: 400px;
  overflow-y: auto;
`;

const Actions = styled(Gap8HorizontalFlex)`
  padding: 8px;
  justify-content: flex-end;
  border-top: 1px solid ${({ theme }) => theme.colors.neutralLight};
`;

const OptionGroupTitle = styled(TextTiny)`
  padding: 8px;
  color: ${({ theme }) => theme.colors.neutralDark};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutralLight};
`;

const OptionContainer = styled(HorizontalFlex)`
  padding: 8px;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.neutralLightest};
  }

  & svg path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const OptionLabel = styled(TextSmall)`
  margin-left: 8px;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const OptionsGroup = styled(VerticalFlex)`
  padding: 4px 0;
`;

export default AddNewVariant;
