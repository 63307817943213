import React from 'react';
import styled from 'styled-components';
import Tooltip from './Tooltip';
import InfoIcon from 'shared/react/images/InfoIcon';

const IconWithTooltip = ({ direction, text, icon, infoIconFill }) => {
  return (
    <LayoutRoot>
      <Tooltip text={text} direction={direction}>
        {icon || <InfoIcon fill={infoIconFill} />}
      </Tooltip>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  height: 20px;
`;

export default IconWithTooltip;
