import { isEmpty } from 'lodash';
import APP_CONFIG from 'app/src/config/app.config';
import { createActionsHook, createContainer, createHook, createStore } from 'react-sweet-state';
import { isUrlProvider } from 'shared/react/utils/feedProducts.utils';
import { MAGENTO, PRODUCTS_IMPORT } from '../constants/intergrations.constants';
import { getRequest, postRequest } from '../helpers/API';
import Utils from '../utils';
import { debounce } from 'lodash/index';

const initialState = {
  products: {},
  ecomProducts: {},
  shoppingProducts: {},
  loadingProducts: false,
  productsCount: null,
  isLoadingProductsCount: true,
};

/**
 * Debounces a burst of get requests by batching all requests into one.
 */
const createDebouncedFetch = (queue: any[]) => {
  return debounce((fetchFunction: (ids: string[]) => void) => {
    fetchFunction(queue.splice(0));
  }, 10);
};

const idsFetchQueue = [];

const debouncedFetchByIds = createDebouncedFetch(idsFetchQueue);

const privateActions = {
  getProductData: function (product) {
    const {
      id,
      productId,
      dbProductId,
      imageUrl,
      title,
      price,
      currencyCode,
      currencySymbol,
      appUrl,
      options = [],
      variants = [],
      ecomPlatform,
      selectedOptions,
      availableForSale,
      productUrl,
      isEligibleOnShop,
      handle,
      tags,
    } = product;

    const normalizedVariants = (variants.edges || variants).map(v => v.node || v);

    if (isUrlProvider(ecomPlatform)) {
      return {
        id: dbProductId || id,
        imageUrl,
        productId: productId || id,
        title,
        ecomPlatform,
        appUrl,
        productUrl,
        options,
        variants: normalizedVariants,
        isOutOfStock: !normalizedVariants.some(
          v => v.availableForSale || v.isVariantAvailableForSale
        ),
      };
    }

    if (ecomPlatform === MAGENTO || ecomPlatform === PRODUCTS_IMPORT) {
      return {
        id: dbProductId || id,
        imageUrl,
        productId: productId || id,
        price,
        currencyCode,
        currencySymbol,
        selectedOptions,
        title,
        options,
        variants: normalizedVariants,
        isOutOfStock: !availableForSale,
      };
    }

    return {
      id: dbProductId || id,
      productId: productId || id,
      imageUrl,
      title,
      price: price || 0,
      currencyCode,
      currencySymbol,
      appUrl,
      options,
      variants: normalizedVariants,
      isOutOfStock: !normalizedVariants.some(
        v => v.availableForSale || v.isVariantAvailableForSale
      ),
      isEligibleOnShop,
      handle,
      tags,
    };
  },
};

const actions = {
  createProducts:
    (productIds, appUrl) =>
    async (state, { appKey }) => {
      try {
        const response = await postRequest('product-actions', '/actions/products/createMany', {
          body: {
            appKey,
            productIds,
            appUrl,
          },
        });

        if (response.err) {
          Utils.logError('failed to create product', response.err);
        }

        const filteredProducts = response.filter(product => !isEmpty(product));
        return filteredProducts.map(privateActions.getProductData);
      } catch (error) {
        console.log(error);
        return null;
      }
    },
  getSearchProducts: body => async () => {
    return postRequest('product-actions', '/actions/products/search', {
      body,
    });
  },
  getSearchProductsV2: body => async () => {
    return postRequest('product-actions', '/actions/v2/products/search', {
      body,
    });
  },
  getProductsWithSuggestedVideos: appUrl => async () => {
    return postRequest('ai', '/products/suggested-videos', {
      body: { appUrl },
    });
  },
  triggerSearchV2Migration: appUrl => () => {
    return postRequest('product-actions', '/actions/v2/products/migrate-to-search-v2', {
      body: { appUrl },
    });
  },
  filterExistingProducts: (existing, products) => () => {
    const existingIds = existing.map(product => {
      return product.productId;
    });

    return products.filter(product => !existingIds.includes(product.productId));
  },
  getProductsDebounced:
    ids =>
    async ({ dispatch }) => {
      idsFetchQueue.push(...ids);
      debouncedFetchByIds(async idsToFetch => {
        await dispatch(actions.getProducts(idsToFetch));
      });
    },
  getProducts:
    ids =>
    async ({ setState, getState }) => {
      try {
        const { products } = getState();
        setState({ loadingProducts: true });

        const idsToFetch = ids.filter(id => !products[id]);
        let mergedProducts = products;

        if (idsToFetch.length) {
          const response = await postRequest('product-actions', '/actions/v2/get', {
            body: { productIds: ids },
          });

          const productsData = Object.entries(response).reduce(
            (acc, [id, product]) => ({
              ...acc,
              [id]: privateActions.getProductData(product),
            }),
            {}
          );

          mergedProducts = { ...products, ...productsData };
          setState({ products: mergedProducts });
        }

        setState({ loadingProducts: false });
        return ids.map(id => mergedProducts[id]).filter(Boolean);
      } catch (err) {
        setState({ loadingProducts: false });
        return [];
      }
    },
  getProductsByProductsIds:
    productIds =>
    async ({ setState, getState }) => {
      const { shoppingProducts } = getState();
      const newProductIds = productIds.filter(productId => !shoppingProducts[productId]);

      if (!newProductIds.length) {
        return productIds.map(productId => {
          return shoppingProducts[productId];
        });
      }

      const res = await postRequest('product-actions', '/actions/v2/shopify/get', {
        body: { productIds: newProductIds },
      });

      const newShoppingProducts = { ...shoppingProducts };
      res.filter(Boolean).forEach(product => {
        newShoppingProducts[product.productId] = product;
      });

      setState({ shoppingProducts: newShoppingProducts });

      return productIds
        .map(productId => {
          return newShoppingProducts[productId];
        })
        .filter(Boolean);
    },
  importProducts: body => async () => {
    return postRequest('products-import', '/import-products', {
      body,
    });
  },
  updateProductsIntegration: body => async () => {
    return postRequest('products-import', '/update-integration', {
      body,
    });
  },
  getEcomProducts:
    project =>
    async ({ getState, setState }) => {
      const { ecomProducts: currentEcomProducts } = getState();
      if (!getShouldRefetchecomProducts(currentEcomProducts, project)) {
        return;
      }

      const { publishId } = project;
      const newEcomProducts = await getRequest(
        'product-actions',
        `/actions/products-get-many/${publishId}`
      );

      setState({ ecomProducts: newEcomProducts });
    },

  updateAppPermissions: shopifyUrl => () => {
    window.open(`${APP_CONFIG.INSTALL_SHOPIFY_URL}?shop=${shopifyUrl}`, '_blank');
  },
  fetchMetaDataFromUrl:
    url =>
    async (_, { appKey }) => {
      try {
        const product = await postRequest('product-actions', '/actions/v2/create-by-url', {
          body: { appUrl: url, appKey },
        });

        return product;
      } catch (err) {
        console.log(err);
        return { err: "Couldn't get the info from the url" };
      }
    },
  getProductsCount:
    appUrl =>
    async ({ getState, setState }) => {
      const { productsCount: storeProductsCount } = getState();
      if (!Utils.isNullOrUndefined(storeProductsCount)) {
        return storeProductsCount;
      }

      setState({ isLoadingProductsCount: true });

      const { productsCount } = await postRequest('product-actions', '/actions/products/count', {
        body: { appUrl },
      });

      setState({ productsCount, isLoadingProductsCount: false });
      return productsCount;
    },
  clearProductsCount:
    () =>
    ({ setState }) => {
      setState({ productsCount: null });
    },
};

const getDbProductsIds = ({ steps }) => {
  return steps.items.flatMap(({ products }) => (products || []).map(({ id }) => id));
};

const getShouldRefetchecomProducts = (ecomProducts, project) => {
  const productIds = getDbProductsIds(project);
  return !productIds.every(productId => Object.keys(ecomProducts).includes(productId));
};

const ProductsStore = createStore({ initialState, actions, name: 'Products' });

export const useProducts = createHook(ProductsStore);

export const useProductActions = createActionsHook(ProductsStore);

export const ProductsContainer = createContainer(ProductsStore);
