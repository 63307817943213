import React from 'react';
import AnalyticsOverviewProductCTRTooltip from './AnalyticsOverviewProductCTRTooltip';
import ChartBox from '../chart/ChartBox';
import ChartHeader from '../chart/ChartHeader';
import ComposedChart from '../chart/ComposedChart';
import useAnalyticsOverview from 'app/src/hooks/useAnalyticsOverview';
import Utils from 'app/src/utils';
import { ChartStats } from '../chart/ChartStats';
import {
  ANALYTICS_OVERVIEW_BAR_CHARTS,
  ANALYTICS_OVERVIEW_STATS,
} from 'app/src/constants/analytics.constants';

const AnalyticsOverviewProductCTR = () => {
  const {
    productClicksOvertime,
    formattedProductCTR,
    formattedNumOfProductClicks,
    isLoadingProductClicksOvertime,
  } = useAnalyticsOverview();
  const { title, bars, lines } = ANALYTICS_OVERVIEW_BAR_CHARTS.PRODUCT_CTR;
  const shouldShowEmptyState = !isLoadingProductClicksOvertime && !productClicksOvertime?.length;

  const statsRows = [
    {
      name: ANALYTICS_OVERVIEW_STATS.PRODUCT_CLICKS,
      formattedValue: formattedNumOfProductClicks,
      tooltipText: ANALYTICS_OVERVIEW_STATS.PRODUCT_CLICKS_TOOLTIP,
    },
  ];

  const yTickFormatter = (value: string) => {
    return Utils.formatNumber({ value, isPercentage: true });
  };

  return (
    <ChartBox shouldShowLoader={isLoadingProductClicksOvertime}>
      <ChartHeader
        subtitle={isLoadingProductClicksOvertime ? '' : formattedProductCTR}
        title={title}
        titleTooltipText={ANALYTICS_OVERVIEW_STATS.PRODUCT_CTR_TOOLTIP}
      />
      <ChartStats rows={statsRows} shouldShowEmptyState={shouldShowEmptyState} />
      <ComposedChart
        bars={bars}
        data={productClicksOvertime || []}
        lines={lines}
        renderCustomTooltip={AnalyticsOverviewProductCTRTooltip}
        shouldShowEmptyState={shouldShowEmptyState}
        yTickFormatter={yTickFormatter}
      />
    </ChartBox>
  );
};

export default AnalyticsOverviewProductCTR;
