import React, { useState } from 'react';
import styled from 'styled-components';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import AnalyticsTable from 'app/src/complex_components/analytics/AnalyticsTable';
import { useAnalytics } from 'app/src/context/AnalyticsStore';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import { FEATURE_EXPORT_ANALYTICS } from 'shared/react/constants/features.constants';
import { getOnsiteTableColumns } from 'app/src/complex_components/analytics/onsite/getOnsiteTableColumns';
import { ANALYTICS_TABLE_KEYS } from 'app/src/constants/analytics.constants';
import { track } from 'app/src/helpers/Tracker';
import { useProjectActions } from 'app/src/context/ProjectsStore';
import useNavigation from 'app/src/hooks/useNavigation';
import { BASE_URL } from 'app/src/config/app.config';
import { useAnalyticsProjects } from 'app/src/complex_components/analytics/useAnalyticsProjects';

const AnalyticsOnsitePagesTable = () => {
  const { getProjectByPublishId } = useProjectActions();
  const [{ onsiteMetrics, isLoadingOnsiteMetrics }] = useAnalytics();
  const { selectedProjects } = useAnalyticsProjects();
  const { getFeatureEnabled } = useFeatureActions();
  const [isLoading, setLoading] = useState(false);
  const { getAccountAnalyticsRoute } = useNavigation();

  const isExportEnabled = getFeatureEnabled(FEATURE_EXPORT_ANALYTICS);

  const getTolstoyName = (publishId: string) => {
    return getProjectByPublishId(publishId)?.name;
  };

  const getProjectAnalyticsRouteByPublishId = (publishId: string) => {
    const projectId = getProjectByPublishId(publishId)?.id;

    const projectRoute = getAccountAnalyticsRoute({ projectIds: [projectId] });

    return `${BASE_URL}${projectRoute}`;
  };

  const trackClick = () => {
    track('Onsite Account Analytics Export to CSV Clicked');
  };

  const columns = getOnsiteTableColumns({
    projects: selectedProjects,
    getTolstoyName,
    getProjectAnalyticsRouteByPublishId,
  });

  return (
    <LayoutRoot>
      <AnalyticsTable
        rows={onsiteMetrics}
        columns={columns}
        isExportEnabled={isExportEnabled}
        isLoading={isLoadingOnsiteMetrics || isLoading}
        setLoading={setLoading}
        tableName={ANALYTICS_TABLE_KEYS.onsite}
        onDownloadCsv={trackClick}
        title={undefined}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)``;

export default AnalyticsOnsitePagesTable;
