import React from 'react';
import EditorMainContainer from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorMainContainer';
import EditorButtonGroupItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorButtonGroupItem';
import EditorNumericInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorNumericInputItem';
import {
  DEFAULT_HERO_CAPTION_LOCATION,
  HERO_CAPTION_PROPERTY_KEY,
  HERO_YOUTUBE_AUTOPLAY_PROPERTY_KEY,
  HERO_YOUTUBE_LOOP_PROPERTY_KEY,
  STORIES_ITEMS_SIZE_TYPES,
  WIDGET_HEADER_LOCATIONS,
} from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import EditorTextInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorTextInputItem';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import TopDirectionIcon from 'app/src/images/directons/TopDirectionIcon';
import CenterDirectionIcon from 'app/src/images/directons/CenterDirectionIcon';
import BottomDirectionIcon from 'app/src/images/directons/BottomDirectionIcon';
import InfoIconWithTooltip from 'app/src/complex_components/InfoIconWithTooltip';
import styled from 'styled-components';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import EditorOnOffToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorOnOffToggleItem';
import { STEP_TYPE } from 'app/src/pages/project/pages/hero/constants';

const HeroEditor = () => {
  const {
    project,
    customizationSettings: {
      heroSettings: {
        captions = '',
        captionLocation = DEFAULT_HERO_CAPTION_LOCATION,
        videoSizeType = STORIES_ITEMS_SIZE_TYPES.responsive,
        width,
        height,
        youtubeAutoplay,
        youtubeLoop,
        includeMuteButton,
      },
    },
    setHeroSettingsProperty,
  } = useLookAndFeelContext();

  const setCaptionsLocation = location => setHeroSettingsProperty('captionLocation', location);
  const isFixed = videoSizeType === STORIES_ITEMS_SIZE_TYPES.fixed;
  const onItemsSizeTypeClick = type => setHeroSettingsProperty('videoSizeType', type);
  const isYoutubeHero =
    project?.steps?.items?.find(({ description }) => description === STEP_TYPE.youtube) || false;

  const locationButtons = [
    {
      icon: <TopDirectionIcon />,
      isEnabled: captionLocation === WIDGET_HEADER_LOCATIONS.top,
      onClick: () => setCaptionsLocation(WIDGET_HEADER_LOCATIONS.top),
    },
    {
      icon: <CenterDirectionIcon />,
      isEnabled: captionLocation === WIDGET_HEADER_LOCATIONS.center,
      onClick: () => setCaptionsLocation(WIDGET_HEADER_LOCATIONS.center),
    },
    {
      icon: <BottomDirectionIcon />,
      isEnabled: captionLocation === WIDGET_HEADER_LOCATIONS.bottom,
      onClick: () => setCaptionsLocation(WIDGET_HEADER_LOCATIONS.bottom),
    },
  ];

  const videoSizeTypes = [
    {
      text: (
        <InfoTextContainer>
          <CapitalizedText>{STORIES_ITEMS_SIZE_TYPES.responsive}</CapitalizedText>
          <InfoIconWithTooltip
            title={'To ensure that the hero video seamlessly covers the full width of the scree'}
            tooltipPlacement="top"
          />
        </InfoTextContainer>
      ),
      isEnabled: videoSizeType === STORIES_ITEMS_SIZE_TYPES.responsive,
      onClick: () => onItemsSizeTypeClick(STORIES_ITEMS_SIZE_TYPES.responsive),
    },
    {
      text: <CapitalizedText>{STORIES_ITEMS_SIZE_TYPES.fixed}</CapitalizedText>,
      isEnabled: isFixed,
      onClick: () => onItemsSizeTypeClick(STORIES_ITEMS_SIZE_TYPES.fixed),
    },
  ];

  if (isYoutubeHero) {
    return (
      <EditorMainContainer>
        <>
          <EditorOnOffToggleItem
            text="YouTube loop"
            checked={!!youtubeLoop}
            onChange={value => setHeroSettingsProperty(HERO_YOUTUBE_LOOP_PROPERTY_KEY, value)}
          />
          <EditorOnOffToggleItem
            text="YouTube autoplay"
            checked={!!youtubeAutoplay}
            onChange={value => setHeroSettingsProperty(HERO_YOUTUBE_AUTOPLAY_PROPERTY_KEY, value)}
          />
        </>
      </EditorMainContainer>
    );
  }

  return (
    <EditorMainContainer>
      {captions && (
        <>
          <EditorTextInputItem
            text="Captions"
            placeholder="Let's talk"
            value={captions || ''}
            maxLength={35}
            onChange={value => setHeroSettingsProperty(HERO_CAPTION_PROPERTY_KEY, value)}
          />
          <EditorButtonGroupItem text="Captions location" buttons={locationButtons} />
        </>
      )}
      <EditorButtonGroupItem text="Hero size" buttons={videoSizeTypes} />
      {!!isFixed && (
        <>
          <EditorNumericInputItem
            text="Width"
            suffix="px"
            onChange={(value: number) => setHeroSettingsProperty('width', value)}
            value={width}
          />
          <EditorNumericInputItem
            text="Height"
            suffix="px"
            onChange={(value: number) => setHeroSettingsProperty('height', value)}
            value={height}
          />
        </>
      )}
      <EditorOnOffToggleItem
        text="Include mute button"
        checked={!!includeMuteButton}
        onChange={value => setHeroSettingsProperty('includeMuteButton', value)}
        key={'includeMuteButton'}
      />
    </EditorMainContainer>
  );
};

const InfoTextContainer = styled(Gap8HorizontalFlex)`
  align-items: center;
`;

const CapitalizedText = styled.span`
  text-transform: capitalize;
`;

export default HeroEditor;
