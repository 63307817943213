import { project as Project } from 'app/src/types/entities';
import styled from 'styled-components';

export const getLiquidCode = (isShopify: boolean, project: Project) => {
  const publishMethod = project?.publishMethod;
  const publishId = project?.publishId;

  if (!isShopify) {
    return `
      <tolstoy-${publishMethod}
        id="${publishId}"
        class="tolstoy-${publishMethod}"
        data-product-id="PRODUCT_ID">
      </tolstoy-${publishMethod}>
    `;
  }

  return `
    <tolstoy-${publishMethod}
      data-tags="{% for tag in product.tags -%}{{ tag }},{%- endfor %}"
      class="tolstoy-${publishMethod}"
      data-publish-id="${publishId}" 
      data-product-id="{{ product.id }}">
    </tolstoy-${publishMethod}>
  `;
};

export const getTVLiquidCode = (project: Project, dataHeaderId: string) => {
  return `
    <div
      id="tolstoy-tv-container"
      data-publish-id="${project.publishId}"
      data-header-id="${dataHeaderId}"
      class="tolstoy-video-page"
    />  
  `;
};
