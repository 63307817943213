import React from 'react';
import styled from 'styled-components';
import Utils from '../../utils';

// eslint-disable-next-line react/display-name
const Checkbox = React.forwardRef(({ onChange, value, name, theme, disabled }, ref) => {
  const onChangeEvent = event => {
    onChange(event.currentTarget.checked);
  };

  return (
    <StyledCheckbox theme={theme}>
      <input
        type="checkbox"
        ref={ref}
        checked={value}
        onChange={onChangeEvent}
        disabled={disabled}
      />
      <span>{name}</span>
    </StyledCheckbox>
  );
});

const StyledCheckbox = styled.label`
  display: flex;
  text-align: left;
  font-size: ${({ theme }) => (Utils.isInnerTheme(theme) ? '14px' : '16px')};
  margin-left: ${({ theme }) => (Utils.isInnerTheme(theme) ? '18px' : 'initial')};
`;

export default Checkbox;
