import React from 'react';
import StandardModal from 'app/src/pages/modals/StandardModal';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import styled from 'styled-components';
import CreateNewTolstoy from 'app/src/pages/create_new_tolstoy/CreateNewTolstoy';

const CreateTolstoyModal = () => {
  const [
    {
      modalProps: { location, onCreate, onCreateInteractive },
    },
    { clearModalState },
  ] = useModal();

  return (
    <StandardModal onClose={clearModalState} removeDefaultPadding disableEnforceFocus open={true}>
      <CustomCreateNewTolstoy
        onCreateProject={onCreate}
        onCreateInteractive={onCreateInteractive}
        location={location}
      />
    </StandardModal>
  );
};

const CustomCreateNewTolstoy = styled(CreateNewTolstoy)`
  border: 16px;
`;

export default CreateTolstoyModal;
