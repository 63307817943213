import React, { useState } from 'react';
import ShareArrowIcon from 'shared/react/images/feed/ShareArrowIcon';
import useFeedModal from 'shared/react/components/complex/context/hooks/useFeedModal';
import { SHARE_MODAL_KEY } from 'shared/react/constants/modals.constants';
import BackdropButton from '../../BackdropButton';
import useFeedFeatures from 'shared/react/components/complex/context/hooks/useFeedFeatures';
import { FEATURE_FEED_HIDE_SHARE } from 'shared/react/constants/features.constants';
import { useFeedIsMobile } from 'shared/react/hooks/useIsMobile';
import ShareMenu from './ShareMenu';
import useFeedState from 'shared/react/components/complex/context/hooks/useFeedState';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';

const FeedShareButton = ({ currentStep, ...props }) => {
  const [, { pushModal }] = useFeedModal();
  const [, { getFeatureSettingsByKey }] = useFeedFeatures();
  const { shouldHide: isHideShareFeatureEnabled } = getFeatureSettingsByKey(FEATURE_FEED_HIDE_SHARE);
  // isUseMuxVideo indicates secret video project with mux enabled
  const [{ isTapcart }] = useFeedState();
  const [{ isUseMuxVideo }] = useProjectConfig();
  const shouldHide = isHideShareFeatureEnabled || isUseMuxVideo || isTapcart;
  const isMobile = useFeedIsMobile();

  const [anchorEl, setAnchorEl] = useState(null);

  const onClick = e => {
    e.stopPropagation();
    if (isMobile) {
      pushModal({ key: SHARE_MODAL_KEY });
      return;
    }

    setAnchorEl(e.currentTarget);
  };

  if (shouldHide) {
    return null;
  }

  return (
    <>
      <BackdropButton {...props} onClick={onClick} ariaLabel="Share">
        <ShareArrowIcon />
      </BackdropButton>
      <ShareMenu currentStep={currentStep} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </>
  );
};

export default FeedShareButton;
