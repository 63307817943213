import React from 'react';
import {
  PRODUCT_PRICE_CURRENCY_SYMBOL_DATA_TYPE,
  PRODUCT_PRICE_NUMBER_DATA_TYPE
} from 'shared/react/constants/feedPreviewDataTypes.constants';

const FeedProductPriceDisplay = ({ price: {currencySymbol, formattedPrice} }) => {
  return <>
    <span data-type={PRODUCT_PRICE_CURRENCY_SYMBOL_DATA_TYPE}>{currencySymbol}</span>
    <span data-type={PRODUCT_PRICE_NUMBER_DATA_TYPE}>{formattedPrice}</span>
  </>
}

export default FeedProductPriceDisplay;
