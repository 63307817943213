import React from 'react';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import AreYouSureModal from 'app/src/pages/modals/AreYouSureModal';
import Types from 'shared/react/theme/Types';

const TITLE_TEXT = 'This page contains unsaved changes. Do you still wish to leave the page?';
const YES_TEXT = 'Yes';
const NO_TEXT = 'No';

const UnsavedChangesModal = () => {
  const [
    {
      modalProps: { onLeavePage },
    },
    { setCurrentModal, setModalProps },
  ] = useModal();

  const closeModal = () => {
    setCurrentModal('');
    setModalProps({});
  };

  const onClickYes = () => {
    closeModal();
    onLeavePage();
  };

  const onClickNo = () => {
    closeModal();
  };

  return (
    <AreYouSureModal
      open
      actionButtonClicked={onClickYes}
      secondaryButtonClicked={onClickNo}
      loading={false}
      titleText={TITLE_TEXT}
      actionButtonText={YES_TEXT}
      actionLevel={Types.Primary}
      secondaryButtonText={NO_TEXT}
      closeButtonClicked={onClickNo}
    />
  );
};

export default UnsavedChangesModal;
