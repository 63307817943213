import React from 'react';

const BlackTikTokIcon = props => (
  <svg
    width={42}
    height={43}
    viewBox="0 0 42 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M40.25 17.702c-3.691.082-7.137-1.065-10.09-3.195V29.17c0 10.24-11.156 16.628-20.015 11.55-8.86-5.16-8.86-17.94 0-23.1 2.543-1.475 5.578-2.048 8.53-1.638v7.372c-4.51-1.474-8.859 2.54-7.874 7.127 1.066 4.587 6.644 6.389 10.172 3.194a6.192 6.192 0 0 0 1.968-4.505V.5h7.22c0 .655 0 1.229.163 1.884.492 2.703 2.051 5.079 4.43 6.553 1.559 1.065 3.527 1.638 5.496 1.638v7.127Z"
      fill="#2F3137"
    />
  </svg>
);
export default BlackTikTokIcon;
