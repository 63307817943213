import React from 'react';

const LibraryIcon = ({ fill = '#50545E' }) => {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 2H8.594L7.063.594A1.988 1.988 0 0 0 5.655 0H2C.875 0 0 .906 0 2v10c0 1.125.875 2 2 2h12c1.094 0 2-.875 2-2V4c0-1.094-.906-2-2-2ZM2 1.5h3.656c.125 0 .25.063.344.156L8 3.5h6c.25 0 .5.25.5.5v1h-13V2c0-.25.219-.5.5-.5Zm12 11H2a.494.494 0 0 1-.5-.5V6.5h13V12c0 .281-.25.5-.5.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default LibraryIcon;
