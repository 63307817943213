import React, { useState } from 'react';
import styled from 'styled-components';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import EmbedPreview from 'app/src/pages/modals/publish/previews/EmbedPreview';
import PublishPreview from 'app/src/pages/modals/publish/choose_publish_method/PublishPreview';
import WidgetPreview from 'app/src/pages/modals/publish/previews/WidgetPreview';
import { SHAPE_TYPES } from 'app/src/pages/modals/publish/widget/common';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import HorizontalFlex, {
  Gap16HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import PublishEmbedCarouselPreview from 'app/src/pages/modals/publish/previews/PublishEmbedCarouselPreview';
import PublishStoriesPreview from 'app/src/pages/modals/publish/previews/PublishStoriesPreview';
import { PUBLISH_PREVIEW_SIZE } from 'app/src/constants/ui.constants';
import {
  FEATURE_PUBLISH_V2,
  FEATURE_WIDGET_STORIES_SETTINGS,
} from 'shared/react/constants/features.constants';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { PUBLISH_METHODS } from 'shared/react/constants/tolstoy.constants';
import { PrimaryButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { useProjects } from 'src/context/ProjectsStore';
import { TextH5, TextSmall } from 'shared/react/components/basic/text/TextV2';
import InfoIconV2 from 'src/images/InfoIconV2';
import WidgetLiveButton from './WidgetLiveButton';
import { publishMethodOptions } from 'app/src/types/entities';

const ChoosePublishMethod = ({
  project,
  onPublishMethodChanged,
  loading,
  live,
  onSetLive,
  showOnlyWidget,
}) => {
  const [, { getFeatureEnabled }] = useFeatures();
  const [, { updateProject }] = useProjects();
  const [isLoading, setIsLoading] = useState(false);
  const isFeedStoriesEnabled = getFeatureEnabled(FEATURE_WIDGET_STORIES_SETTINGS);
  const isPublishV2Enabled = getFeatureEnabled(FEATURE_PUBLISH_V2);
  const [selectedPublishMethod, setSelectedPublishMethod] = useState();
  const widgetSettings = JSON.parse(project?.widgetSettings || '{}');
  const isFeed = project?.feed;

  const onSetPublishMethodClick = async () => {
    setIsLoading(true);
    try {
      if (isPublishV2Enabled) {
        await updateProject({ ...project, publishMethod: selectedPublishMethod });
      }

      setIsLoading(false);
      onPublishMethodChanged(selectedPublishMethod);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const previews = [
    {
      key: publishMethodOptions.bubble,
      header: 'Widget',
      liveButton: showOnlyWidget ? (
        <WidgetLiveButton
          disabled={loading}
          checked={live}
          onChange={onSetLive}
          loading={loading}
        />
      ) : null,
      preview: (
        <WidgetPreview
          border={widgetSettings.border}
          themeColor={widgetSettings.themeColor}
          themeTextColor={widgetSettings.themeTextColor}
          bubbleText={widgetSettings.bubbleText}
          bubbleTextEnabled={widgetSettings.bubbleTextEnabled}
          bubbleObjectPosition={widgetSettings.bubbleObjectPosition}
          widgetHeader={project?.widgetHeader}
          project={project}
          widgetPosition={project?.widgetPosition || 'bottomRight'}
          notificationBadge={project?.widgetNotificationBadge}
          isVertical={!!project?.verticalOrientation}
          shape={project?.widgetShape || SHAPE_TYPES.rectangle}
          widgetSize={project?.widgetSize || 'm'}
          bubbleMarginBottom={widgetSettings.bubbleMarginBottom}
          bubbleMarginSide={widgetSettings.bubbleMarginSide}
        />
      ),
    },
  ];

  if (showOnlyWidget) {
    return (
      <OnlyWidgetContainer>
        {previews.map(props => (
          <PublishPreview
            isSelected={selectedPublishMethod === props.key}
            onClick={() => setSelectedPublishMethod(props.key)}
            {...props}
            key={props.header}
          />
        ))}
      </OnlyWidgetContainer>
    );
  }

  if (isFeed) {
    previews.push({
      key: publishMethodOptions.carousel,
      header: PUBLISH_METHODS[publishMethodOptions.carousel].text,
      preview: (
        <PublishEmbedCarouselPreview
          project={project}
          widgetSettings={widgetSettings}
          size={PUBLISH_PREVIEW_SIZE}
        />
      ),
    });
  } else {
    previews.push({
      key: publishMethodOptions.embed,
      header: PUBLISH_METHODS[publishMethodOptions.embed].text,
      preview: <EmbedPreview project={project} isVertical={!!project?.verticalOrientation} />,
    });
  }

  if (isFeed && isFeedStoriesEnabled) {
    previews.push({
      key: publishMethodOptions.stories,
      header: PUBLISH_METHODS[publishMethodOptions.stories].text,
      preview: (
        <PublishStoriesPreview project={project} {...widgetSettings} size={PUBLISH_PREVIEW_SIZE} />
      ),
    });
  }

  const publishMethodDisplayName =
    PUBLISH_METHODS[selectedPublishMethod]?.text || selectedPublishMethod;

  return (
    <LayoutRoot>
      <HeaderContainer>
        <Header>Choose your layout</Header>
      </HeaderContainer>
      <Content>
        {previews.map(props => (
          <PublishPreview
            isSelected={selectedPublishMethod === props.key}
            onClick={() => setSelectedPublishMethod(props.key)}
            {...props}
            key={props.header}
          />
        ))}
      </Content>
      <SaveSection>
        {isPublishV2Enabled && (
          <WarningBox>
            <InfoIconV2 width={20} height={20} />
            <TextSmall>
              The Tolstoy will be set as {publishMethodDisplayName} permanently <br />
              Don’t worry, you will be able to duplicate the Tolstoy as any of the other layouts.
            </TextSmall>
          </WarningBox>
        )}
        <ButtonContainer>
          <PrimaryButton
            isLoading={isLoading}
            disabled={!selectedPublishMethod}
            onClick={onSetPublishMethodClick}
          >
            {!selectedPublishMethod ? 'Select type' : `Set as ${publishMethodDisplayName}`}
          </PrimaryButton>
        </ButtonContainer>
      </SaveSection>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  height: 100%;
  gap: 56px;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    gap: 40px;
  }
`;

const HeaderContainer = styled(Gap8VerticalFlex)``;

const Header = styled(TextH5)``;

const Content = styled(HorizontalFlex)`
  gap: 56px;
  justify-content: center;
  height: 100%;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    grid-auto-flow: unset;
  }

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    margin-top: 16px;
    flex-direction: column-reverse;
    height: unset;
    padding-bottom: 24px;
  }
`;

const SaveSection = styled(VerticalFlex)``;

const WarningBox = styled(Gap16HorizontalFlex)`
  border: 1px solid ${({ theme }) => theme.colors.neutralLight};
  padding: 16px;
  width: 100%;
  border-radius: 4px;
`;

const ButtonContainer = styled(HorizontalFlex)`
  margin-top: 24px;
  justify-content: flex-end;
`;

const OnlyWidgetContainer = styled(Content)`
  margin-top: 0;
  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    margin-bottom: 8px;
  } ;
`;

export default ChoosePublishMethod;
