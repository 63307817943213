import React, { useState } from 'react';
import styled from 'styled-components';
import Button from 'shared/react/components/complex/Button';
import Types from 'shared/react/theme/Types';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import Separator from 'shared/react/components/basic/Separator';
import Expander from 'shared/react/components/complex/expanders/Expander';
import PublishVideoPageNonShopifyStepper from './PublishVideoPageNonShopifyStepper';
import PublishVideoPageShopifyStepper from './PublishVideoPageShopifyStepper';

const PublishVideoPageShopifyContent = ({
  onClickCustomizeAppearance,
  onGetHelpClick,
  onClickCopyCode,
  getCode,
  setHeaderId,
  publishId,
}) => {
  const [isExpanded, setExpanded] = useState(false);

  return (
    <LayoutRoot>
      <PublishVideoPageShopifyStepper onGetHelpClick={onGetHelpClick} publishId={publishId} />
      <CustomizeAppeareance fullWidth onClick={onClickCustomizeAppearance} type={Types.Secondary}>
        Customize appearance
      </CustomizeAppeareance>
      <Separator />
      <StyledExpander
        subtitle={'Implement Video Page using code'}
        headerStyle={{ paddingRight: 0 }}
        contentContainerStyle={{ paddingBottom: 16 }}
      >
        <PublishVideoPageNonShopifyStepper
          getCode={getCode}
          onClickCopyCode={onClickCopyCode}
          setHeaderId={setHeaderId}
          isExpanded={isExpanded}
          setExpanded={setExpanded}
        />
      </StyledExpander>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)`
  padding-bottom: 16px;
`;

const CustomizeAppeareance = styled(Button)`
  width: 192px;
`;

const StyledExpander = styled(Expander)`
  border: 1px solid ${({ theme }) => theme.colors.gray3};
  border-radius: 24px;
  padding: 4px 16px;
  gap: 12px;
  width: fit-content;
`;

export default PublishVideoPageShopifyContent;
