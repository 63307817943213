import React from 'react';
import LineClampWrapper from 'shared/react/components/basic/LineClampWrapper';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import ImageWithFallback from 'shared/react/images/ImageWithFallback';
import Utils from 'shared/react/utils/utils';
import VerticalVideoCardEmptyState from 'src/basic_components/vertical-video-card/VerticalVideoCardEmptyState';
import { useVideos } from 'src/context/VideosStore';
import PlayIcon from 'src/images/PlayIcon';
import { SearchProduct } from 'src/types/common';
import styled from 'styled-components';
import ProductTile from '../ProductTile';
import VerticalVideoCardAssetsBadge from './VerticalVideoCardAssetsBadge';
import VerticalVideoCardBackdrop from './VerticalVideoCardBackdrop';
import { AssetRenditionsService } from 'shared/react/services/assets';

type Props = {
  vodAssetId: string;
  onClick: () => void;
  onEmptyStateClick: () => void;
  product: SearchProduct;
  numOfVodAssets: number;
  isLoading: boolean;
};

const VerticalVideoCard = ({
  vodAssetId,
  onClick,
  onEmptyStateClick,
  product,
  numOfVodAssets,
  isLoading,
}: Props) => {
  const [{ video }] = useVideos(vodAssetId);

  if (!video) {
    return (
      <VerticalVideoCardEmptyState
        product={product}
        onClick={onEmptyStateClick}
        isLoading={isLoading}
      />
    );
  }

  const posterUrl = AssetRenditionsService.getAssetPosterLarge(video);
  const isMultipleVodAssets = numOfVodAssets > 1;
  return (
    <LayoutRoot onClick={onClick}>
      <StyledImage src={posterUrl} />
      <Content isMultipleVodAssets={isMultipleVodAssets}>
        <VerticalVideoCardAssetsBadge numOfVodAssets={numOfVodAssets} />
        <BottomContent>
          <StyledTextTiny>
            <LineClampWrapper className="notranslate">{video.name}</LineClampWrapper>
          </StyledTextTiny>
          <DurationContainer>
            <PlayIcon />
            <StyledTextTiny>{Utils.secondsToTimeString(video.duration, false)}</StyledTextTiny>
          </DurationContainer>
          <StyledProductTile imageUrl={product.imageUrl} title={product.title} />
        </BottomContent>
      </Content>
      <VerticalVideoCardBackdrop shouldRender={isMultipleVodAssets} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  max-width: 255px;
  max-height: 337px;
  position: relative;
`;

const StyledImage = styled(ImageWithFallback)`
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
  border: 1px solid ${({ theme }) => theme.colors.neutralGray};
`;

const StyledProductTile = styled(ProductTile)`
  width: 100%;
`;

const Content = styled(Gap8VerticalFlex)<{ isMultipleVodAssets: boolean }>`
  position: absolute;
  inset: 0;
  padding: 8px;
  justify-content: ${({ isMultipleVodAssets }) =>
    isMultipleVodAssets ? 'space-between' : 'flex-end'};
`;

const StyledTextTiny = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.white};
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  align-self: flex-start;
`;

const BottomContent = styled(Gap8VerticalFlex)`
  align-self: flex-end;
  width: 100%;
`;

const DurationContainer = styled(HorizontalFlex)`
  justify-content: space-between;
  align-items: center;
`;

export default VerticalVideoCard;
