import React from 'react';

const OmnisendIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={40}
    width={150}
    viewBox="0 0 200 25"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.63 20.606c-2.837 0-4.79-2.365-4.79-5.443 0-3.079 1.953-5.443 4.79-5.443 2.836 0 4.79 2.364 4.79 5.443 0 3.078-1.924 5.443-4.79 5.443Zm0-13.493c-3.944 0-7.567 3.375-7.567 8.038s3.629 8.038 7.566 8.038c3.938 0 7.566-3.37 7.566-8.038 0-4.67-3.629-8.038-7.566-8.038Zm28.056 0a5.879 5.879 0 0 0-4.839 2.516h-.532a5.166 5.166 0 0 0-8.467-.605h-.442l-.538-1.76h-1.814V22.99h2.776v-8.957c0-2.891 1.766-4.53 3.81-4.53 2.716 0 3.09 2.867 3.09 5.915v7.572h2.777v-9.175c.09-2.77 1.814-4.312 3.81-4.312 2.716 0 3.09 2.867 3.09 5.915v7.572h2.777v-8.528c0-4.342-1.893-7.36-5.516-7.36m17.473.011a5.873 5.873 0 0 0-4.615 1.947h-.375l-.562-1.814h-1.815V22.97h2.758v-8.94c0-2.763 1.887-4.53 4.234-4.53 3.103 0 3.574 3.116 3.574 5.916v7.572h2.758V14.46c0-4.373-1.971-7.36-5.957-7.36"
      fill="#7d8086"
    />
    <path
      d="M100.828 7.27H98.07v15.731h2.758V7.27ZM99.45 4.083a1.857 1.857 0 1 0 0-3.714 1.857 1.857 0 0 0 0 3.714Z"
      fill="#7d8086"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111.146 13.736c-1.542-.412-3.024-.92-3.024-2.365 0-1.295 1.131-2.117 2.583-2.117 1.669 0 2.903 1.077 2.903 2.238h2.564c0-2.172-1.923-4.476-5.443-4.476-2.897 0-5.202 1.579-5.202 4.385 0 3.151 2.934 4.004 5.075 4.572 1.736.472 3.278.974 3.278 2.583 0 1.609-1.385 2.268-2.867 2.268-1.736 0-3.405-1.01-3.405-2.964h-2.552c0 2.873 2.364 5.238 5.987 5.238 3.091 0 5.444-1.736 5.444-4.67 0-3.247-2.934-4.063-5.329-4.692m11.467-.254c.502-2.522 2.268-4.131 4.603-4.131 2.334 0 3.949 1.705 4.324 4.13h-8.927Zm4.639-6.466c-3.943 0-7.481 3.024-7.481 7.917s3.538 8.165 7.511 8.165a7.36 7.36 0 0 0 6.786-4.572h-2.903a4.354 4.354 0 0 1-3.883 2.014c-2.715 0-4.542-1.815-4.796-4.76h11.927c.315-5.958-3.375-8.764-7.161-8.764Zm18.429.097a5.854 5.854 0 0 0-4.609 1.947h-.381l-.562-1.814h-1.815V22.97h2.764v-8.94c0-2.763 1.881-4.53 4.234-4.53 3.109 0 3.574 3.116 3.574 5.916v7.572h2.764V14.46c0-4.373-1.977-7.36-5.963-7.36m17.116 13.47c-2.353 0-4.706-2.045-4.706-5.444s2.353-5.443 4.706-5.443c2.074 0 4.518 1.579 4.518 5.443 0 3.865-2.42 5.444-4.518 5.444ZM167.333.61v8.976a5.444 5.444 0 0 0-4.736-2.516c-3.986 0-7.258 3.363-7.258 8.026s3.266 8.026 7.258 8.026a5.395 5.395 0 0 0 4.488-2.171h.496l.605 2.014H170V.61h-2.667Z"
      fill="#7d8086"
    />
    <path
      d="M18.616.684H3.901A3.895 3.895 0 0 0 0 4.579v14.715a3.895 3.895 0 0 0 3.901 3.895h6.701v-3.224c0-3.109-.387-5.492-2.697-6.181v-2.546c3.756.338 5.619 3.132 5.619 7.723v4.234h5.092a3.895 3.895 0 0 0 3.895-3.895V4.579A3.895 3.895 0 0 0 18.616.684Zm-7.258 8.128a2.16 2.16 0 1 1-.012-4.318 2.16 2.16 0 0 1 .012 4.318Z"
      fill="#7d8086"
    />
  </svg>
);

export default OmnisendIcon;
