import styled from 'styled-components';
import { useProductActions } from 'app/src/context/ProductsStore';
import { useRules } from 'app/src/context/RulesStore';
import { useVodConnection } from 'app/src/context/VodConnectionStore';
import { sortEntities } from 'app/src/hooks/use-dynamic-video-ids-by-project/checkRules';
import TableRow from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-products-table/components/TableRow';
import {
  EXPANDED_ROW_HEIGHT,
  HEADER_HEIGHT,
  ROW_HEIGHT,
  VIDEO_ROW_GAP,
  VIDEO_ROW_HEIGHT,
  VIDEO_TILE_WIDTH,
  getFirstColumnWidth,
} from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-products-table/dynamicProductsTable.constants';
import { RowData } from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-products-table/types/dynamicProductsTable.type';
import { project as Project, rule as RuleType } from 'app/src/types/entities';
import React, { useEffect, useRef, useState } from 'react';
import { AutoSizer, Column, InfiniteLoader, Table, WindowScroller } from 'react-virtualized';
import 'react-virtualized/styles.css'; // import default styles
import {
  TableFirstHeaderText,
  TableHeaderText,
} from 'src/basic_components/virtualized-table/VirtualizedHeaders';
import { AnalyticsDataProps } from 'src/types/common/common';
import { useApps } from 'src/context/AppsStore';
import { useOpenVideoPickerModal } from 'src/hooks/modals';
import { VodConnectionType } from 'src/types/entities';
import {
  useVodProjectIdConnectionActions,
  useCreateVodConnection,
} from 'src/hooks/vod-connections';
import Spinner from 'app/src/basic_components/Spinner';

type Props = {
  vodAssetIds: string[];
  project: Project;
  productIds: string[];
  setProductIds: (productIds: string[]) => void;
  containerRef: React.RefObject<HTMLDivElement>;
  analyticsData?: AnalyticsDataProps;
  videoRules?: RuleType[];
  setVideoRules?: (rules: RuleType[]) => void;
  loading?: boolean;
};

const DynamicProductsTable = ({
  vodAssetIds,
  project,
  productIds,
  setProductIds,
  containerRef,
  analyticsData,
  videoRules,
  setVideoRules,
}: Props) => {
  const [{ vodConnections, productsByVodAssetMap }, { deleteConnectionByProductId }] =
    useVodConnection();
  const { getProductsByProductsIds } = useProductActions();
  const [{ loading: loadingRules }] = useRules();
  const [{ selectedAppUrl }] = useApps();
  const openVideoPickerModal = useOpenVideoPickerModal();
  const { createVodConnection } = useCreateVodConnection();
  const { createVideoProjectIdConnection, deleteVodProjectIdConnection } =
    useVodProjectIdConnectionActions();

  const [expandedIndex, setExpandedIndex] = useState(null);
  const [products, setProducts] = useState([]);
  const [productsNumberOfAssets, setProductsNumberOfAssets] = useState({});
  const [rows, setRows] = useState<RowData[]>([{ isLoading: true }]);
  const [isLoading, setIsLoading] = useState(false);
  const tableRef = useRef<any>();
  const productIdsRef = useRef(productIds);
  const newProductIdConnectionRef = useRef();

  const isRowLoaded = ({ index }) => {
    if (isLoading) {
      return false;
    }

    return !!rows[index];
  };

  const loadMoreRows = async ({ startIndex, stopIndex, skipLoadingCheck = false }) => {
    if (!productIds.length || (isLoading && !skipLoadingCheck) || startIndex >= productIds.length) {
      return;
    }

    setIsLoading(true);

    const currentProducts = await getProductsByProductsIds(
      productIds.slice(startIndex, stopIndex + 1)
    );

    if (productIds !== productIdsRef.current) {
      return;
    }

    const newRows = currentProducts.map(
      ({ id, title: name, imageUrl, productId, ecomPlatform }) => {
        const videoAssetIds = sortEntities(
          productsNumberOfAssets[productId],
          [videoRules],
          productId
        );

        return {
          productPage: { imageUrl, name, productId, id, ecomPlatform },
          videosNumber: productsNumberOfAssets[productId].length,
          videoAssetIds,
        };
      }
    );

    const currentRows = [...rows, ...newRows].filter(row => !row.isLoading);
    setProducts([...products, ...currentProducts]);
    setRows(currentRows);
    setIsLoading(false);
  };

  const getRowHeight = (index, width) => {
    const length = rows[expandedIndex]?.videoAssetIds?.length;

    if (index !== expandedIndex || !length) {
      return ROW_HEIGHT;
    }

    const gapLength = VIDEO_ROW_GAP * (length - 1);
    const rowLength = length * VIDEO_TILE_WIDTH + gapLength;
    const numberOfTiles = Math.floor(width / VIDEO_TILE_WIDTH);

    const numberOfRows = Math.ceil(rowLength / width);
    const gapWidth = numberOfTiles - VIDEO_ROW_GAP;

    if (numberOfRows >= 4) {
      return EXPANDED_ROW_HEIGHT + VIDEO_ROW_HEIGHT * 4 + gapWidth;
    }

    return EXPANDED_ROW_HEIGHT + VIDEO_ROW_HEIGHT * numberOfRows + gapWidth;
  };

  useEffect(() => {
    if (!vodAssetIds?.length || loadingRules) {
      setRows([]);
      setProductIds([]);
      return;
    }

    const productIds = new Set<string>();
    const productVideosNumber = {};

    vodConnections?.forEach(({ externalProductId, vodAssetId, appUrl }) => {
      if (vodAssetIds.includes(vodAssetId) && externalProductId && appUrl === project.appUrl) {
        productIds.add(externalProductId);
        productVideosNumber[externalProductId] = productVideosNumber[externalProductId]
          ? [...productVideosNumber[externalProductId], vodAssetId]
          : [vodAssetId];
      }
    });

    setProductsNumberOfAssets(productVideosNumber);
    setProductIds([...productIds].filter(Boolean));
    setRows([{ isLoading: true }]);

    return () => {
      setProductIds([]);
      setRows([]);
    };
  }, [vodAssetIds]);

  useEffect(() => {
    productIdsRef.current = productIds;

    if (!productIds.length) {
      setRows([]);
      return;
    }

    setProducts([]);
    setRows([]);

    loadMoreRows({ startIndex: 0, stopIndex: 10, skipLoadingCheck: true });
  }, [productIds]);

  useEffect(() => {
    if (!tableRef.current) {
      return;
    }

    tableRef.current.recomputeRowHeights();
    tableRef.current.forceUpdate();
  }, [expandedIndex]);

  const addManualVideo = async video => {
    if (!video.id || !project.id || !newProductIdConnectionRef.current) {
      return;
    }

    const {
      id: productId,
      productId: externalProductId,
      ecomPlatform: provider,
    } = newProductIdConnectionRef.current;

    return Promise.all([
      createVideoProjectIdConnection({
        appUrl: selectedAppUrl,
        projectId: project.id,
        vodAssetId: video.id,
      }),
      createVodConnection({
        provider,
        productId,
        externalProductId,
        vodAssetId: video.id,
        appUrl: selectedAppUrl,
        type: VodConnectionType.productId,
      }),
    ]);
  };

  const removeManualVideo = async ({ video, rowData }) => {
    const externalProductId = rowData.productPage?.productId;
    const shouldRemoveProjectIdConnection =
      productsByVodAssetMap[video.id]?.[project.appUrl]?.length <= 1;

    if (!video.id || !externalProductId) {
      return;
    }

    if (shouldRemoveProjectIdConnection) {
      await deleteVodProjectIdConnection({ vodAssetId: video.id, projectId: project.id });
    }

    return deleteConnectionByProductId({
      externalProductId,
      vodAssetId: video.id,
    });
  };

  const onVideoPickerModalClosed = () => {
    newProductIdConnectionRef.current = null;
  };

  const onOpenVideoPickerModal = () =>
    openVideoPickerModal({
      onVideoPicked: addManualVideo,
      onModalClosed: onVideoPickerModalClosed,
    });

  const onAddManualVideoClick = rowData => {
    newProductIdConnectionRef.current = rowData.productPage;

    onOpenVideoPickerModal();
  };

  const labelRenderer = ({ label }) => <TableHeaderText>{label}</TableHeaderText>;
  return (
    <LayoutRoot>
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        rowCount={productIds.length}
      >
        {({ onRowsRendered, registerChild }) => (
          <WindowScroller scrollElement={containerRef.current}>
            {({ height = 0, scrollTop }) => {
              return (
                <AutoSizer disableHeight>
                  {({ width }) => {
                    const firstColumnWidth = getFirstColumnWidth(width);
                    const tileWidth = width - firstColumnWidth;
                    return (
                      <Table
                        autoHeight
                        height={height}
                        width={width}
                        scrollTop={scrollTop}
                        onRowsRendered={onRowsRendered}
                        ref={ref => {
                          registerChild(ref);
                          tableRef.current = ref;
                        }}
                        rowHeight={({ index }) => getRowHeight(index, width)}
                        rowCount={rows.length}
                        headerHeight={HEADER_HEIGHT}
                        rowGetter={({ index }) => rows[index]}
                        flexGrow={1}
                        overscanRowCount={5}
                        rowRenderer={props => (
                          <TableRow
                            firstColumnWidth={firstColumnWidth}
                            tileWidth={tileWidth}
                            expandedIndex={expandedIndex}
                            setExpandedIndex={setExpandedIndex}
                            analyticsData={analyticsData}
                            videoRules={videoRules}
                            setVideoRules={setVideoRules}
                            project={project}
                            onAddManualVideo={onAddManualVideoClick}
                            onRemoveManualVideo={removeManualVideo}
                            {...props}
                          />
                        )}
                      >
                        <Column
                          label="Product Page"
                          dataKey="productPage"
                          positon
                          width={firstColumnWidth}
                          headerRenderer={({ label }) => (
                            <TableFirstHeaderText>{label}</TableFirstHeaderText>
                          )}
                        />
                        <Column
                          label="Videos"
                          dataKey="videosNumber"
                          width={tileWidth}
                          headerRenderer={labelRenderer}
                        />
                        <Column width={50} dataKey="" label="" />
                      </Table>
                    );
                  }}
                </AutoSizer>
              );
            }}
          </WindowScroller>
        )}
      </InfiniteLoader>
      {isLoading && <Spinner size={32} />}
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  padding: 0 0 24px 10px;

  .ReactVirtualized__Table__headerRow {
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutralLight};
  }
`;

export default DynamicProductsTable;
