import filterEntities from 'app/src/pages/dashboard/utils/filterEntities';
import { DEFAULT_OFFSITE_FILTERS } from 'app/src/constants/filters';
import { project } from 'app/src/types/entities';
import { useProjectActions } from 'app/src/context/ProjectsStore';

const useAllOffsiteProjects = () => {
  const { getDisplayedProjects } = useProjectActions();

  return filterEntities<project>(getDisplayedProjects(), DEFAULT_OFFSITE_FILTERS);
};

export default useAllOffsiteProjects;
