import React from 'react';

const AlignRightIcon = props => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.25 13.75A.76.76 0 0 0 14 13a.74.74 0 0 0-.75-.75h-7.5A.722.722 0 0 0 5 13c0 .406.313.75.75.75h7.5Zm0-4A.76.76 0 0 0 14 9a.74.74 0 0 0-.75-.75H.75A.722.722 0 0 0 0 9c0 .406.313.75.75.75h12.5Zm0-5.5h-7.5A.722.722 0 0 0 5 5c0 .406.313.75.75.75h7.5A.76.76 0 0 0 14 5a.74.74 0 0 0-.75-.75Zm0-2.5A.76.76 0 0 0 14 1a.74.74 0 0 0-.75-.75H.75A.722.722 0 0 0 0 1c0 .406.313.75.75.75h12.5Z"
      fill="#50545E"
    />
  </svg>
);

export default AlignRightIcon;
