import React from 'react';

const SearchIcon = props => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m19.688 18.04-4.844-4.845c.86-1.328 1.406-2.89 1.406-4.57C16.25 4.172 12.578.5 8.125.5 3.633.5 0 4.172 0 8.625a8.119 8.119 0 0 0 8.125 8.125c1.64 0 3.203-.508 4.531-1.367l4.844 4.844c.352.39.938.39 1.328 0l.86-.86c.39-.39.39-.976 0-1.328ZM3.125 8.624c0-2.734 2.227-5 5-5 2.734 0 5 2.266 5 5 0 2.773-2.266 5-5 5-2.773 0-5-2.227-5-5Z"
      fill="#2F3137"
    />
  </svg>
);

export default SearchIcon;
