import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useVideos } from 'src/context/VideosStore';
import ProductTile from 'src/basic_components/ProductTile';
import StandardModalV2 from 'src/pages/modals/StandardModalV2';
import { TextH3Bold } from 'shared/react/components/basic/text/TextV2';
import { ModalAssetPreview } from 'src/complex_components/global_modal/common/assets';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import VerticalVideoCardAddVideo from 'src/basic_components/vertical-video-card/VerticalVideoCardAddVideo';
import VerticalVideoPreviewCard from 'src/basic_components/vertical-video-preview-card/VerticalVideoPreviewCard';
import ModalFooterButtons from '../common/ModalFooterButtons';
import useAddProductVideos from './hooks/useAddProductVideos';

type Props = {
  closeModal: () => void;
};

const AddProductVideosModal = ({ closeModal }: Props) => {
  const {
    handleAddVideoClick,
    removeVideo,
    save,
    discard,
    vodAssetIds,
    currentProductData,
    isDirty,
    isLoading,
    isSaving,
    isInitialized,
  } = useAddProductVideos();
  const [selectedVodAssetId, setSelectedVodAssetId] = useState(vodAssetIds[0]);
  const [{ video: asset }] = useVideos(selectedVodAssetId);
  const { title, imageUrl } = currentProductData;

  const handleCloseModal = () => {
    discard();
    closeModal();
  };

  const handleSave = async () => {
    await save();
    closeModal();
  };

  useEffect(() => {
    if (!isInitialized || selectedVodAssetId) {
      return;
    }

    setSelectedVodAssetId(vodAssetIds[0]);
  }, [isInitialized, vodAssetIds, selectedVodAssetId]);

  return (
    <StyledStandardModal open onClose={handleCloseModal}>
      <LayoutRoot>
        <ModalAssetPreview asset={asset} />
        <Content>
          <StyledHeader>Product related videos</StyledHeader>
          <ProductTile imageUrl={imageUrl} title={title} />
          <VideoTilesContainer>
            {vodAssetIds.map(vodAssetId => (
              <VerticalVideoPreviewCard
                key={vodAssetId}
                vodAssetId={vodAssetId}
                onClick={() => setSelectedVodAssetId(vodAssetId)}
                isSelected={selectedVodAssetId === vodAssetId}
                onDeleteClick={removeVideo}
              />
            ))}
            <StyledVerticalVideoCardAddVideo onClick={handleAddVideoClick} />
          </VideoTilesContainer>
          <ModalFooterButtons
            isDiscardDisabled={isLoading || isSaving}
            isSaveDisabled={!isDirty || isLoading || isSaving}
            isSaveLoading={isSaving}
            onDiscard={handleCloseModal}
            onSave={handleSave}
          />
        </Content>
      </LayoutRoot>
    </StyledStandardModal>
  );
};

const StyledStandardModal = styled(StandardModalV2)`
  height: 100%;
`;

const LayoutRoot = styled(HorizontalFlex)`
  max-height: 533px;
`;

const Content = styled(Gap8VerticalFlex)`
  padding: 24px;
  width: 448px;
`;

const StyledHeader = styled(TextH3Bold)`
  padding-bottom: 4px;
`;

const VideoTilesContainer = styled(Gap8HorizontalFlexWrap)`
  padding: 8px;
  overflow-y: auto;
  height: 100%;
`;

const StyledVerticalVideoCardAddVideo = styled(VerticalVideoCardAddVideo)`
  height: 156px;
  max-width: 119px;
  border-radius: 6px;
`;

export default AddProductVideosModal;
