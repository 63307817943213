import React from 'react';

const ExportToCSVIcon = ({ ...props }) => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m13.563 7.602-1.876-1.875c-.234-.211-.585-.211-.796 0-.235.234-.235.585 0 .796l.937.915H5.313A.555.555 0 0 0 4.75 8c0 .328.234.563.563.563h6.515l-.914.937a.466.466 0 0 0-.164.375c0 .164.047.305.164.398a.513.513 0 0 0 .774 0l1.874-1.875c.235-.21.235-.562 0-.796ZM8.686 9.5a.555.555 0 0 0-.562.563V11c0 .21-.188.375-.375.375h-6A.37.37 0 0 1 1.375 11V2.023a.37.37 0 0 1 .375-.375H5.5V3.5c0 .422.328.75.75.75h1.852v1.688c0 .328.234.562.539.562.28 0 .609-.234.609-.563v-2.18a1.49 1.49 0 0 0-.445-1.054L7.047.945A1.491 1.491 0 0 0 5.992.5H1.727C.907.5.227 1.18.25 2v9c0 .844.656 1.5 1.5 1.5h6c.82 0 1.5-.656 1.5-1.5v-.938a.57.57 0 0 0-.563-.562Z"
        fill="#2F3137"
      />
    </svg>
  );
};

export default ExportToCSVIcon;
