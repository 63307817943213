import React from 'react';
import styled from 'styled-components';

import CaptionsEditorContent from './CaptionsEditorContent';
import CaptionsEditorModalButtons from './CaptionsEditorModalButtons';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import useVideoCaptions from './useVideoCaptions';

type Props = {
  video: object;
  setSubtitlesBlob: (blob: Blob) => void;
  subtitlesBlob: Blob | null;
  uploadedVTTContent: string | null;
  onClose: () => void;
};

const CaptionsEditorWrapper = ({
  video,
  setSubtitlesBlob,
  subtitlesBlob,
  uploadedVTTContent,
  onClose,
}: Props) => {
  const { onSave, subtitles, isLoading } = useVideoCaptions({
    video,
    subtitlesBlob,
    setSubtitlesBlob,
    uploadedVTTContent,
  });

  return (
    <LayoutRoot>
      <CaptionsEditorContent
        subtitles={subtitles}
        setSubtitlesBlob={setSubtitlesBlob}
        uploadedVTTContent={uploadedVTTContent}
      />
      <CaptionsEditorModalButtons onDiscard={onClose} onSave={onSave} isLoading={isLoading} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)`
  justify-content: space-between;
  width: 100%;
  height: 75%;
`;

export default CaptionsEditorWrapper;
