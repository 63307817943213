import React from 'react';
import styled from 'styled-components';
import BasicSelect from 'shared/react/components/basic/basic_select/BasicSelect';
import { TextSmall } from 'shared/react/components/complex/Text';
import { useAccount } from 'app/src/context/AccountStore';
import { useProjects } from 'app/src/context/ProjectsStore';
import {
  INBOX_TOLSTOY_CONTAINER_TEST_ID,
  INBOX_FILTERS_CONTAINER_TEST_ID,
} from 'app/src/constants/dataTestIds.constants';

const DEFAULT_USER_ITEMS = [
  { id: '', name: 'All', value: '' },
  { id: 'null', name: 'Unassign', value: null },
];
const DEFAULT_PROJECT_ITEMS = [{ id: '', name: 'All' }];

function getSelectUsers(teamMembers, user) {
  const items = teamMembers.map(({ id, email }) => ({
    id: id || user.id,
    name: email,
    value: id || user.id,
  }));
  return [...DEFAULT_USER_ITEMS, ...items];
}

function getSelectProjects(projects) {
  const items = projects.map(({ id, name }) => ({ id, name }));
  return [...DEFAULT_PROJECT_ITEMS, ...items];
}

const SessionsFilters = ({
  isOpened,
  projects,
  teamMembers,
  user,
  onAssigneeChange,
  onTolstoyChange,
  inboxFilters,
}) => {
  const [, { getTeamMemberById }] = useAccount();
  const [{ project = 'All' }] = useProjects({ projectId: inboxFilters.projectId });
  const assignee = getTeamMemberById(inboxFilters?.assignee)?.email || 'All';

  const handleAssigneeChanged = ({ name, value }) => {
    if (name === assignee) {
      return;
    }
    onAssigneeChange(value);
  };
  const handleProjectChanged = ({ id, name }) => {
    if (name === project) {
      return;
    }
    onTolstoyChange(id);
  };

  const showAssignee = teamMembers.length > 1;

  return (
    <SessionsFiltersContainer
      isOpened={isOpened}
      data-test-id={INBOX_FILTERS_CONTAINER_TEST_ID}
    >
      <TolstoyContainer data-test-id={INBOX_TOLSTOY_CONTAINER_TEST_ID}>
        <StyledTextSmall>Tolstoy</StyledTextSmall>
        <StyledSelect
          items={getSelectProjects(projects)}
          value={project}
          onChange={option => handleProjectChanged(option)}
        />
      </TolstoyContainer>
      {showAssignee && (
        <AssigneeContainer>
          <StyledTextSmall>Assignee</StyledTextSmall>
          <StyledSelect
            items={getSelectUsers(teamMembers, user)}
            value={assignee}
            onChange={option => handleAssigneeChanged(option)}
          />
        </AssigneeContainer>
      )}
    </SessionsFiltersContainer>
  );
};

export default SessionsFilters;

const SessionsFiltersContainer = styled.div`
  grid-area: filters;
  display: ${({ isOpened }) => (isOpened ? 'flex' : 'none')};
  flex-direction: column;
  height: 100%;
  width: 312px;
  gap: 16px;
  border-right: 1px solid ${({ theme }) => theme.colors.gray3};
  overflow: hidden;
`;

const TolstoyContainer = styled.div`
  margin: 0 auto;
`;

const AssigneeContainer = styled.div`
  margin: 0 auto;
`;

const StyledSelect = styled(BasicSelect)`
  min-width: 242px;
`;

const StyledTextSmall = styled(TextSmall)`
  margin: 5px;
`;
