import React from 'react';
import styled from 'styled-components';
import MinusIcon from '../../../../images/MinusIcon';
import Gap8VerticalFlex from '../../flex_layouts/Gap8VerticalFlex';
import HorizontalFlex, {
  Gap16HorizontalFlex,
  HorizontalFlexCentered,
} from '../../flex_layouts/HorizontalFlex';
import QuizFlowTrashIcon from 'shared/react/images/quiz/QuizFlowTrashIcon';
import ThinPlusIcon from '../../../../images/ThinPlusIcon';
import ComponentWithLoader from 'shared/react/components/basic/ComponentWithLoader';
import HorizontalFlexWrap from '../../flex_layouts/HorizontalFlexWrap';
import useFeedProducts from 'shared/react/components/complex/context/hooks/useFeedProducts';
import {
  ADD_TO_CART,
  CART_PRODUCT_CLEAR,
  REMOVE_FROM_CART,
} from '../../../../constants/shopifyMessaging.constants';
import FeedProductImage from '../feed_products/FeedProductImage';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';

const FeedCartItem = ({
  item,
  messagingRequestDetails,
  onUpdateItemQuantity,
  setFocusedProduct,
  getDisplayedPriceByCents,
}) => {
  const [, { getProductByVariantId }] = useFeedProducts();
  const { title, price, quantity, imageUrl, variantId, optionsWithValues } = item;
  const product = getProductByVariantId(variantId);
  const { player } = useDesignSettingsContext();
  const { feedImageFitType } = player.feedCards.productDetailsCard.productImages;

  const displayedPrice = getDisplayedPriceByCents(price);
  const displayedOptionsWithValues = optionsWithValues
    .flatMap(({ name, value }) => {
      if (name === 'Title' && value === 'Default Title') {
        return null;
      }
      return `${name}: ${value}`;
    })
    .join(' | ');

  const getSubscriptionText = () => {
    const subscriptionName = item.sellingPlanAllocation?.selling_plan.name;
    if (!subscriptionName) {
      return '';
    }

    return `Subscription : ${subscriptionName}`;
  };

  const handleClickProduct = () => {
    setFocusedProduct(product);
  };

  const onClickAdd = () => {
    onUpdateItemQuantity(variantId, quantity + 1, ADD_TO_CART);
  };

  const onClickRemove = () => {
    onUpdateItemQuantity(variantId, quantity - 1, REMOVE_FROM_CART);
  };

  const onClickClear = () => {
    onUpdateItemQuantity(variantId, 0, CART_PRODUCT_CLEAR);
  };

  const isLoading = action => {
    return (
      messagingRequestDetails.action === action && messagingRequestDetails.variantId === variantId
    );
  };

  const actionsDisabled = !!messagingRequestDetails.action;
  const subscriptionText = getSubscriptionText();
  return (
    <LayoutRoot>
      <FeedProductImage
        src={imageUrl}
        onClick={handleClickProduct}
        imageFit={feedImageFitType}
      />
      <ItemDetailsContainer>
        <ItemTitle>{title}</ItemTitle>
        <ItemExtraDetails>
          <ItemDetails>{displayedOptionsWithValues}</ItemDetails>
          <ItemDetails>{subscriptionText}</ItemDetails>
        </ItemExtraDetails>
        <ItemPrice>{displayedPrice}</ItemPrice>
        <ItemControls>
          <QuantityButtons>
            <QuantityButton onClick={onClickRemove} disabled={actionsDisabled}>
              <ComponentWithLoader isLoading={isLoading(REMOVE_FROM_CART)}>
                <MinusIcon />
              </ComponentWithLoader>
            </QuantityButton>
            <QuantityText>{quantity}</QuantityText>
            <QuantityButton onClick={onClickAdd} disabled={actionsDisabled}>
              <ComponentWithLoader isLoading={isLoading(ADD_TO_CART)}>
                <ThinPlusIcon />
              </ComponentWithLoader>
            </QuantityButton>
          </QuantityButtons>
          <DeleteButton onClick={onClickClear} disabled={actionsDisabled}>
            <ComponentWithLoader isLoading={isLoading(CART_PRODUCT_CLEAR)}>
              <QuizFlowTrashIcon />
            </ComponentWithLoader>
          </DeleteButton>
        </ItemControls>
      </ItemDetailsContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)`
  gap: 24px;
  height: 100%;
  max-height: 150px;
  flex-shrink: 0;
`;

const ItemDetailsContainer = styled(Gap8VerticalFlex)`
  height: 100%;
  width: 100%;
  text-align: left;
`;

const ItemTitle = styled.div`
  font-weight: 600;
`;

const ItemDetails = styled(HorizontalFlexWrap)`
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const ItemPrice = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.gray2};
`;

const ItemControls = styled(HorizontalFlex)`
  justify-content: space-between;
  margin-top: 24px;
`;

const QuantityButtons = styled(Gap16HorizontalFlex)``;

const ControlButton = styled.button`
  border-radius: 100%;
  transition: 0.2s;
  cursor: pointer;
  width: 28px;
  height: 28px;
  border: unset;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: ${({ disabled }) => (disabled ? '' : 'scale(1.2)')};
  }

  & svg {
    height: 16px;
    width: 16px;

    & path {
      fill: ${({ theme }) => theme.colors.ghostDark};
    }
  }
`;

const QuantityButton = styled(ControlButton)`
  border: 1px solid ${({ theme }) => theme.colors.ghostLight};
  background: ${({ theme }) => theme.colors.white};
`;

const QuantityText = styled(HorizontalFlexCentered)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

const DeleteButton = styled(ControlButton)`
  background: ${({ theme }) => theme.colors.gray5};
`;

const ItemExtraDetails = styled(VerticalFlex)``;

export default FeedCartItem;
