import React, { useMemo, useState } from 'react';
import { useProjects } from 'app/src/context/ProjectsStore';
import { useDashboard } from 'app/src/context/ui_store/DashboardStore';
import FolderItem from 'app/src/pages/dashboard/pages/libraries/project_filters/FolderItem';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import Pencil from 'app/src/images/grey_pencil.svg';
import {
  DELETE_FOLDER_MODAL_KEY,
  RENAME_FOLDER_MODAL_KEY,
} from 'app/src/constants/modals.constants';
import DeleteIcon from 'app/src/images/project_card_icons/delete_icon.svg';
import { MODAL_STATE } from 'app/src/pages/modals/folder_modal/folders.constants';
import { TextSmall } from 'shared/react/components/complex/Text';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { track } from 'app/src/helpers/Tracker';
import {
  FOLDER_EXTRA_BUTTONS_DATA_TEST_ID,
  FOLDER_TRASH_ICON_DATA_TEST_ID,
} from 'app/src/constants/dataTestIds.constants';

const CONTENT_HEIGHT = 80;

const TeamMembersMenuItems = ({ folders, onClose, distanceFromTopScreen }) => {
  const [anchorEl, setAnchorEl] = useState();
  const [folderMenu, setFolderMenu] = useState();
  const [{ selectedFolder }, { setSelectedFolder }] = useDashboard();
  const [{ projects }] = useProjects();
  const [, { setCurrentModal, setCurrentFolderId, setCurrentModalType }] = useModal();

  const foldersMapping = useMemo(() => {
    const mapping = {};
    projects.forEach(({ folder }) => {
      const folderNumber = mapping[folder];
      mapping[folder] = folderNumber ? folderNumber + 1 : 1;
    });

    return mapping;
  }, [projects]);

  const onAllClick = () => {
    setSelectedFolder(null);
    onClose();
  };

  const onNotInAFolderClick = () => {
    setSelectedFolder({ id: null, name: 'Not in a folder' });
    onClose();
  };

  const handleOpenModalByKey = (modalKey, type = '') => {
    track('Folder Menu Item Click', { type: modalKey });
    setCurrentFolderId(folderMenu.id);
    setCurrentModalType(type);
    setCurrentModal(modalKey);
    onClose();
  };

  const onOpenMenu = (e, folder) => {
    setAnchorEl(e.target);
    setFolderMenu(folder);
  };

  const MENU_ITEMS = [
    {
      title: 'Rename',
      icon: Pencil,
      onClick: () => handleOpenModalByKey(RENAME_FOLDER_MODAL_KEY, MODAL_STATE.rename),
    },
    {
      title: 'Delete',
      icon: DeleteIcon,
      dataTestId: FOLDER_TRASH_ICON_DATA_TEST_ID,
      onClick: () => handleOpenModalByKey(DELETE_FOLDER_MODAL_KEY),
    },
  ];

  const menuItems = folders?.flatMap((folder, i) => {
    if (!folder) {
      return [];
    }

    const onClick = () => {
      if (selectedFolder?.id === folder.id) {
        onAllClick();
        return;
      }
      onClose();
      setSelectedFolder(folder);
    };

    return (
      <FolderItem
        name={folder.name}
        number={foldersMapping[folder.id] || 0}
        onClick={onClick}
        dataTestId={`${FOLDER_EXTRA_BUTTONS_DATA_TEST_ID}-${i}`}
        selected={selectedFolder?.id === folder.id}
        showMenu={true}
        folder={folder}
        onOpenMenu={onOpenMenu}
      />
    );
  });

  const menuTop =
    anchorEl?.getBoundingClientRect?.()?.bottom - distanceFromTopScreen - CONTENT_HEIGHT;

  return (
    <>
      <FolderItem
        name={'All'}
        number={projects.length}
        onClick={onAllClick}
        selected={!selectedFolder}
        showMenu={false}
      />
      <FolderItem
        name="Not in a folder"
        number={foldersMapping?.null}
        onClick={onNotInAFolderClick}
        selected={selectedFolder?.id === null}
        showMenu={false}
      />

      {menuItems}

      {!!anchorEl && (
        <FolderMenuItems top={menuTop}>
          {MENU_ITEMS.map(({ icon, title, onClick, dataTestId }) => (
            <MenuItemContainer data-test-id={dataTestId} onClick={onClick} key={title}>
              <IconContainer>
                <img src={icon} alt={title} />
              </IconContainer>
              <FolderTitle>{title}</FolderTitle>
            </MenuItemContainer>
          ))}
        </FolderMenuItems>
      )}
    </>
  );
};

const FolderMenuItems = styled(VerticalFlex)`
  position: absolute;
  background: ${({ theme }) => theme.colors.white};
  left: 100%;
  border-radius: 12px;
  overflow: hidden;
  top: ${({ top }) => top}px;

  .MuiPaper-rounded {
    border-radius: 12px;
  }
`;

const MenuItemContainer = styled(HorizontalFlexWrap)`
  padding: 11px 16px;
  flex-wrap: nowrap;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  max-width: 270px;
  cursor: pointer;
  transition: 0.3s;
  & svg {
    min-width: 14px;
  }
  background: ${({ theme, selected }) =>
    selected ? theme.colors.primaryLight : theme.colors.white};

  &:hover {
    background: ${({ theme }) => theme.colors.primaryLightHover};
  }

  &:last-of-type {
    border-radius: 0 0 12px 12px;
  }
`;

const IconContainer = styled(HorizontalFlexWrap)``;

const FolderTitle = styled(TextSmall)`
  width: 100%;
`;

export default TeamMembersMenuItems;
