import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Conversation from 'app/src/pages/project/pages/inbox/Conversation';
import { CircularProgress } from '@material-ui/core';
import ConversationEmptyState from 'app/src/pages/project/pages/inbox/ConversationEmptyState';
import { useResponses } from 'app/src/context/ResponsesStore';
import { EVENTS } from 'app/src/constants/tolstoy.constants';
import ConversationReply from 'app/src/pages/project/pages/inbox/ConversationReply';
import { useUser } from 'app/src/context/userStore/UserStore';

const InboxConversation = ({
  conversation,
  loading,
  responses,
  status,
  empty,
  project,
  isInbox,
}) => {
  const [replies, setReplies] = useState([]);
  const [, { createResponse, sendReplyEmail, sendReplyEmailV2 }] = useResponses();
  const [{ user }] = useUser();
  const currentConversationId = useRef(conversation?.id);

  useEffect(() => {
    return () => {
      currentConversationId.current = null;
    };
  }, []);

  useEffect(() => {
    setReplies([]);
    currentConversationId.current = conversation?.id;
  }, [conversation?.id]);

  const getConversationName = () => conversation?.name || conversation?.email || 'Anonymous';

  const onTextReply = async (textReply, attachments) => {
    const res = await sendReplyEmailV2({
      publishId: project.publishId,
      sessionId: conversation.id,
      to: [conversation.email],
      replyProjectId: project.id,
      videoId: null,
      text: textReply,
      compose: false,
      attachments: attachments,
      fromEmail: user?.email,
    });

    if (!res) {
      return;
    }

    const reply = await createResponse(
      conversation.id,
      null,
      conversation.email,
      textReply,
      project.id,
      EVENTS.tolstoyTextReply,
      attachments
    );

    if (currentConversationId.current === conversation.id) {
      setReplies([...replies, reply]);
    }
  };

  const onMacroReply = async (publishId, videoId, macroId) => {
    const res = await sendReplyEmail(
      publishId,
      conversation.id,
      [conversation.email],
      project.id,
      videoId,
      null
    );

    if (!res) {
      return;
    }

    const reply = await createResponse(
      conversation.id,
      macroId,
      conversation.email,
      videoId,
      project.id,
      EVENTS.tolstoyReply
    );

    setReplies([...replies, reply]);
  };

  const updatedResponses = responses ? [...responses, ...replies] : responses;

  return (
    <StyledConversation isInbox={isInbox}>
      <Conversation
        name={getConversationName()}
        responses={updatedResponses}
        project={project}
        url={conversation?.parentUrl}
      />
      {conversation?.email && project && (
        <ConversationReply
          project={project}
          conversation={conversation}
          onTextReply={onTextReply}
          onMacroReply={onMacroReply}
        />
      )}
      {conversation && !responses && (
        <LoadingMask>
          <CircularProgress size={24} />
        </LoadingMask>
      )}
      {!conversation && !responses && !loading && (
        <ConversationEmptyState status={status} empty={empty} />
      )}
    </StyledConversation>
  );
};

export default InboxConversation;

const StyledConversation = styled.div`
  position: relative;
  grid-area: conversation;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
  height: 100%;
  min-width: 640px;
  background-color: #f9fafc;
  padding: ${({ isInbox }) => (isInbox ? '94px 24px 24px 24px' : '24px')};
  border-radius: 16px 0 0 0;
`;

const LoadingMask = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`;
