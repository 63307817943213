import React from 'react';
import { getPosterUrl } from 'player/src/components/player/player.utils';
import { Helmet } from 'react-helmet-async';
import Utils from 'player/src/utils';

const SiteTags = ({ playlist, startStep, accountId, conversationName, verticalOrientation }) => {
  if (!playlist || Utils.getIsAppPreview()) {
    return null;
  }

  const firstStep = playlist.find(step => step.key === startStep);
  const imageSuffix = '.0000000.jpg';
  const previewSuffix = '_preview.mp4';
  const gifSuffix = '.mp4.gif';
  const thumbImageSuffix = `.thumb${imageSuffix}`;
  const poster = getPosterUrl(firstStep, accountId);

  const videoPoster = poster.replace(imageSuffix, previewSuffix);
  const gifPoster = poster.replace(imageSuffix, gifSuffix);
  const facebookPoster = poster.replace(imageSuffix, thumbImageSuffix);
  const imageWidth = verticalOrientation ? '140' : '250';
  const imageHeight = verticalOrientation ? '250' : '140';
  window.prerenderReady = true;
  return (
    <div>
      <Helmet>
        <title>Tolstoy - {conversationName}</title>
        <meta name="description" content={conversationName} />

        {/* Google / Search Engine Tags */}
        <meta name="medium" content="video" />

        <meta itemProp="name" content={conversationName} />
        <meta itemProp="description" content="Tolstoy" />
        <meta itemProp="video" content={videoPoster} />
        <meta property="video:type" content="video/gif" />
        <meta itemProp="image" content={gifPoster} />
        <meta itemProp="image" content={poster} />

        {/* Facebook Meta Tags */}
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={conversationName} />
        <meta property="og:description" content="Tolstoy" />
        <meta property="og:video" content={videoPoster} />
        <meta property="og:video:type" content="video/gif" />
        <meta property="og:image:type" content="image/gif" />
        <meta property="og:image" content={gifPoster} />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image" content={facebookPoster} />
        <meta property="og:image:width" content={imageWidth} />
        <meta property="og:image:height" content={imageHeight} />
        <meta property="og:video:type" content="video/mp4" />
        <meta property="og:video" content={videoPoster} />
        <meta property="og:video:secure_url" content={videoPoster} />

        {/* Twitter Meta Tags */}
        <meta name="twitter:site" content="@gotolstoy" />
        <meta name="twitter:url" content={window.location.href} />
        <meta name="twitter:title" content={conversationName || 'Tolstoy'} />
        <meta name="twitter:description" content="Tolstoy" />
        <meta name="twitter:card" content="player" />
        <meta
          name="twitter:player"
          content={`${window.location.origin}${window.location.pathname}?host&url=https://twitter.com&playerType=embed`}
        />
        <meta name="twitter:player:width" content="960" />
        <meta name="twitter:player:height" content="711" />
        <meta name="twitter:video" content={videoPoster} />
        <meta name="twitter:image" content={gifPoster} />
        <meta name="twitter:image" content={poster} />
        {Utils.isIos() && (
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1,user-scalable=0"
          />
        )}
      </Helmet>
    </div>
  );
};

export default SiteTags;
