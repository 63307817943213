import styled from 'styled-components';
import { Select as MUISelect } from '@material-ui/core';

export const Button = styled.button`
  background: ${props =>
    props.disabled
      ? props.theme.colors.gray9
      : props.secondary
        ? props.theme.colors.gray10
        : props.theme.colors.blue5};
  color: ${props => (props.secondary ? props.theme.colors.gray8 : props.theme.colors.white)};
  border-radius: 50px;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  min-width: 100px;
  width: auto;
  height: 36px;
  padding: 0 30px;
  border: none;
  cursor: ${props => (props.disabled ? 'normal' : 'pointer')};
  outline: none;
  transition: 0.2s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &:hover {
    transition: 0.2s;
    opacity: ${props => (props.loading ? 0.4 : props.disabled ? 1 : 0.8)};
  }
`;

export const TextField = styled.input`
  background-color: ${({ theme }) => theme.colors.gray11};
  height: 32px;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 4px;
  padding: 0 7px;
`;

export const Select = styled(MUISelect)`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.gray11};
  &.MuiInput-underline:before,
  &.MuiInput-underline:after,
  &.MuiInput-underline:active,
  &.MuiInput-underline:hover {
    border: none !important;
  }
  .MuiSelect-selectMenu {
    padding-left: 7px;
    font-size: 14px;
  }
  .MuiSelect-icon {
    color: ${({ theme }) => theme.colors.gray12};
    width: 0.8em;
  }
`;
