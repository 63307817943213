import React from 'react';
import styled from 'styled-components';
import { TextBody, TextOverline } from 'shared/react/components/complex/Text';
import Utils from '../../utils';
import ZapierLogo from 'app/src/images/zapier_logo.svg';
import ArrowUpRightFromSquare from 'app/src/images/ArrowUpRightFromSquare';
import OutlinedVideoIcon from 'app/src/images/video_icon/OutlinedVideoIcon';
import {
  InfoLightBadge,
  SuccessBadge,
  SuccessLightBadge,
} from 'shared/react/components/basic/Badge';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import Gap16HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap16HorizontalFlexWrap';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';
import ConnectButton from 'app/src/pages/integrations/ConnectButton';

function Integration({
  integration: {
    icon,
    description,
    title,
    containsZapierLogo,
    iconHeight,
    help,
    isVideoCreationTool,
    extraContent,
    userName,
    updateAppBadge,
    ...props
  },
  focused,
}) {
  const onHelpClick = () => {
    Utils.openInNewTab(help);
  };

  return (
    <LayoutRoot excludeTopTranslation $isFocused={focused}>
      <MainContainer>
        <HeaderContainer>
          <StyledImg alt="Integration" src={icon} height={iconHeight || '56px'} />
          <ConnectButton {...props} />
        </HeaderContainer>

        <TitleContainer>
          <TextOverline>{title}</TextOverline>
          {containsZapierLogo && <ZapierImg height="24px" src={ZapierLogo} alt="Zapier" />}
          {isVideoCreationTool && (
            <VideoCreationToolContainer>
              <StyledSuccessBadge>
                <LeftImage>
                  <OutlinedVideoIcon />
                </LeftImage>
                Video creation tool
              </StyledSuccessBadge>
            </VideoCreationToolContainer>
          )}
          {userName && (
            <VideoCreationToolContainer>
              <EllipsisTextOverflowWrapper>
                <StyledLightSuccessBadge>@{userName}</StyledLightSuccessBadge>
              </EllipsisTextOverflowWrapper>
            </VideoCreationToolContainer>
          )}
          {updateAppBadge && (
            <VideoCreationToolContainer>
              <EllipsisTextOverflowWrapper>
                <InfoBadge>We updated our integration, please reconnect.</InfoBadge>
              </EllipsisTextOverflowWrapper>
            </VideoCreationToolContainer>
          )}
        </TitleContainer>
        <TextBody>{description}</TextBody>
        {extraContent}
        {help && (
          <LinkContainer onClick={onHelpClick}>
            Show me how
            <IconContainer>
              <ArrowUpRightFromSquare />
            </IconContainer>
          </LinkContainer>
        )}
      </MainContainer>
    </LayoutRoot>
  );
}

export default Integration;

const StyledImg = styled.img`
  height: ${({ height }) => height};
`;

const LayoutRoot = styled.div`
  display: grid;
  height: fit-content;
  gap: 16px;
  border-radius: 16px;
  background-color: white;
  max-width: 500px;
  min-height: 260px;
  padding: 16px;
  box-shadow: ${({ $isFocused, theme }) => $isFocused ? `0 0 10px ${theme.colors.warning}` : 'none'};
`;

const MainContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr;
  border-radius: 16px;
  background-color: white;
  gap: 8px;
`;

const HeaderContainer = styled(Gap16HorizontalFlexWrap)`
  justify-content: space-between;
  align-items: center;
`;

const TitleContainer = styled(Gap8HorizontalFlexWrap)`
  margin-top: 8px;
  align-items: center;
  justify-content: flex-start;
  min-height: 28px;
`;

const LinkContainer = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  &:hover {
    color: ${({ theme }) => theme.colors.primaryHover};
  }
`;

const IconContainer = styled.div`
  margin-left: 4px;
`;

const VideoCreationToolContainer = styled.div`
  white-space: nowrap;
  font-size: 14px;
  letter-spacing: normal;
  font-weight: 600;
  line-height: 18px;
  max-width: 300px;

  & svg {
    width: 16px;
    height: 12px;
  }
`;

const LeftImage = styled(HorizontalFlexWrap)`
  align-items: center;
  margin-right: 6px;
`;

const ZapierImg = styled.img`
  position: relative;
  top: -2px;
  height: 24px;
`;

const StyledSuccessBadge = styled(SuccessBadge)`
  border-radius: 100px;
`;

const InfoBadge = styled(InfoLightBadge)`
  border-radius: 100px;
  font-weight: 700;
  font-size: 12px;
`;

const StyledLightSuccessBadge = styled(SuccessLightBadge)``;
