export const openIntercom = (setModalVisible, setButtonContainerVisible) => {
  if (typeof window.Intercom === 'function') {
    console.log('Initializing Intercom...');
    window.Intercom('boot', {
      app_id: 'oai7bdqq',
      hide_default_launcher: false,
    });

    setModalVisible(false);
    setButtonContainerVisible(false);

    setTimeout(() => {
      window.Intercom('showNewMessage');
    }, 500);
  } else {
    console.error('Intercom not available.');
  }
};
