import React, { FC } from 'react';

import styled from 'styled-components';
import { TextSmall, TextTiny } from 'shared/react/components/complex/Text';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import BasicAccordionV2, { StepName, StepNumber } from 'src/basic_components/BasicAccordionV2';

const columns = [
  { name: 'id', required: true },
  { name: 'title', required: true },
  { name: 'descriptionHtml' },
  { name: 'url', required: true },
  { name: 'imageUrl', required: true },
  { name: 'images' },
  { name: 'price', required: true },
  { name: 'compareAtPrice' },
  { name: 'currencyCode' },
  { name: 'currencySymbol' },
  { name: 'inventory' },
];

const Step1Requirements: FC = () => {
  return (
    <BasicAccordionV2
      header={
        <>
          <StepNumber>Step 1</StepNumber>
          <StepName>Ensure your CSV file column names</StepName>
        </>
      }
    >
      <Content>
        <Title>
          Before linking your CSV file, please ensure it matches the column naming convention as
          listed below
        </Title>
        <Subtitle>* These fields are mandatory</Subtitle>

        <FileStructure>
          {columns.map((column, i) => (
            <Column key={column.name}>
              <ColumnNumber isRequired={column.required}>
                {column.required && <RequiredMark>*</RequiredMark>}
                {i + 1}
              </ColumnNumber>
              <ColumnName>{column.name}</ColumnName>
              <EmptyCell>&nbsp;</EmptyCell>
            </Column>
          ))}
        </FileStructure>
      </Content>
    </BasicAccordionV2>
  );
};

const Content = styled(Gap8VerticalFlex)`
  padding: 16px 32px;
  overflow-x: scroll;
  overflow-y: hidden;
`;

const Title = styled(TextSmall)`
  color: ${props => props.theme.colors.neutralDark};
`;

const Subtitle = styled(TextTiny)`
  color: ${props => props.theme.colors.fireRed};
`;

const ColumnName = styled(TextTiny)`
  display: flex;
  align-items: center;
  padding: 0 12px;
  height: 24px;
  border: 1px solid ${props => props.theme.colors.neutralGray};
  background: ${props => props.theme.colors.neutralLightest};
`;

const FileStructure = styled(HorizontalFlex)`
  margin-top: 8px;
`;

const Column = styled(VerticalFlex)`
  align-items: center;
  margin-left: -1px;
  &:first-of-type ${ColumnName} {
    border-top-left-radius: 4px;
  }
  &:last-of-type ${ColumnName} {
    border-top-right-radius: 4px;
  }
`;

const ColumnNumber = styled(TextTiny)<{ isRequired?: boolean }>`
  padding: 4px 0;
  color: ${({ theme, isRequired }) =>
    isRequired ? theme.colors.fireRed : theme.colors.neutralGray};
`;

const RequiredMark = styled.span``;

const EmptyCell = styled.div`
  width: 100%;
  height: 12px;
  border-left: 1px solid ${props => props.theme.colors.neutralGray};
  border-right: 1px solid ${props => props.theme.colors.neutralGray};
`;

export default Step1Requirements;
