import axios from 'axios';

class HttpService {
  static async get(url, numberOfAttempts, attempt) {
    try {
      return await axios.get(url);
    } catch (e) {
      console.log('Network Error', e);

      if (!numberOfAttempts) {
        return null;
      }

      if (attempt && attempt > numberOfAttempts) {
        return null;
      }

      return await this.get(url, numberOfAttempts, attempt ? attempt + 1 : 1);
    }
  }

  static async post(url, headers, body) {
    try {
      return await axios.post(url, JSON.parse(body), { headers });
    } catch (e) {
      console.log('Network Error', e);
      console.log('Network Online Status', window.navigator?.onLine);
    }
  }
}

export default HttpService;
