import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ExpanderTopArrow from '../images/expander_top_arrow.svg';
import HorizontalFlexWrap from './complex/flex_layouts/HorizontalFlexWrap';

const BasicAccordion = ({ header, children, startExpanded, handleClick = (isExpanded) => {} }) => {
  const [isExpanded, setIsExpanded] = useState(!!startExpanded);

  useEffect(() => {
    handleClick(isExpanded);
  }, [isExpanded]);

  return (
    <LayoutRoot>
      <HeaderLayout
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      >
        {header}
        <Image src={ExpanderTopArrow} isExpanded={isExpanded} alt="Expander arrow" />
      </HeaderLayout>
      {!!isExpanded && children}
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div``;

const HeaderLayout = styled(HorizontalFlexWrap)`
  cursor: pointer;
  gap: 16px;
  flex-wrap: nowrap;
`;

const Image = styled.img`
  transform: rotate(${({ isExpanded }) => (isExpanded ? 0 : 180)}deg);
  transition: 0.2s ease-out;
  object-fit: contain;
`;

export default BasicAccordion;
