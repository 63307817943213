import { useBilling } from 'app/src/hooks/useBillings';
import { getIsAnotherUserPersonalLibrary, useProjects } from 'app/src/context/ProjectsStore';
import { useDashboard } from 'app/src/context/ui_store/DashboardStore';

const useCanCreateProject = () => {
  const { lockTolstoyCreation } = useBilling();
  const [{ isCreatingDuplication, isCreatingNewTolstoy }] = useDashboard();
  const [{ showPersonalLibrary }] = useProjects();

  const isLocked = lockTolstoyCreation;
  const disabled =
    (isCreatingNewTolstoy && !isCreatingDuplication) ||
    getIsAnotherUserPersonalLibrary(showPersonalLibrary) ||
    isLocked;

  return disabled;
};

export default useCanCreateProject;
