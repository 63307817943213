import { useVideoActions } from 'app/src/context/VideosStore';
import { useVodConnection } from 'src/context/VodConnectionStore';
import { getOrderVodRuleTypeKey, getHideVodRuleTypeKey } from 'app/src/context/RulesStore';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';
import { VodAssetPayload } from '../types';

const useGetVideoPayload = ({ vodAssetIds, videoRules, project, productsMap }) => {
  const { getVideoById } = useVideoActions();
  const [{ productsByVodAssetMap, projectIdsByVodAssetIdMap }] = useVodConnection();
  const { player } = useDesignSettingsContext();
  const { hideOOSProducts } = player.feedCards.productDetailsCard.stock;

  const getVideoPayload = (videoId, index, product?: any): VodAssetPayload | undefined => {
    if (!vodAssetIds.includes(videoId)) {
      return;
    }

    const video = getVideoById(videoId);

    if (!video) {
      return;
    }

    const orderTypeKey = getOrderVodRuleTypeKey(videoId, index, product?.productId);
    const hideTypeKey = getHideVodRuleTypeKey(videoId, product?.productId);
    const isLocked = !!videoRules?.find(({ value }) => value === orderTypeKey);
    const isHidden = !!videoRules?.find(({ value }) => value === hideTypeKey);
    const isDisabled = !!videoRules?.find(({ value }) =>
      [orderTypeKey, hideTypeKey].includes(value)
    );
    const vodProductIds = productsByVodAssetMap?.[videoId]?.[project?.appUrl] || [];
    const isOutOfStock =
      !!vodProductIds.length &&
      !vodProductIds.some(productId => !productsMap?.[productId]?.isOutOfStock);
    const hasManualVodConnection =
      projectIdsByVodAssetIdMap?.[videoId]?.some(
        connection => connection.projectId === project.id
      ) || false;

    return {
      video,
      order: index,
      isLocked,
      isHidden,
      isDisabled,
      isOutOfStock,
      hasManualVodConnection,
      shouldHideOOSProducts: hideOOSProducts,
    };
  };

  return getVideoPayload;
};

export default useGetVideoPayload;
