import React from 'react';
import styled from 'styled-components';
import { Gap16HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import TolstoyLogoWithWord from 'app/src/images/TolstoyLogoWithWord';
import GoToNextAnswerIcon from 'app/src/images/GoToNextAnswerIcon';
import HubspotLogoWithWord from 'app/src/images/HubspotLogoWithWord';
import { TextSmall } from 'shared/react/components/complex/Text';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';

const HubspotSyncDirection = () => {
  const icons = [
    <TolstoyLogoWithWord key={0} width={100} height={20} />,
    <GoToNextAnswerIcon key={1} />,
    <HubspotLogoWithWord key={2} />,
  ];

  return (
    <LayoutRoot>
      <Header>Sync direction</Header>
      <SyncContainer>{icons.map(icon => icon)}</SyncContainer>
    </LayoutRoot>
  );
};

const Header = styled(TextSmall)``;

const LayoutRoot = styled(Gap16VerticalFlex)``;

const SyncContainer = styled(Gap16HorizontalFlex)`
  align-items: center;
`;

export default HubspotSyncDirection;
