import React from 'react';

const BagIcon = () => {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 4V3C4 1.34375 5.3125 0 7 0C8.65625 0 10 1.34375 10 3V4H12.5C13.3125 4 14 4.6875 14 5.5V13C14 14.6562 12.6562 16 11 16H3C1.3125 16 0 14.6562 0 13V5.5C0 4.6875 0.65625 4 1.5 4H4ZM5 4H9V3C9 1.90625 8.09375 1 7 1C5.875 1 5 1.90625 5 3V4ZM1.5 5C1.21875 5 1 5.25 1 5.5V13C1 14.125 1.875 15 3 15H11C12.0938 15 13 14.125 13 13V5.5C13 5.25 12.75 5 12.5 5H10V7.5C10 7.78125 9.75 8 9.5 8C9.21875 8 9 7.78125 9 7.5V5H5V7.5C5 7.78125 4.75 8 4.5 8C4.21875 8 4 7.78125 4 7.5V5H1.5Z"
        fill="#2F3137"
      />
    </svg>
  );
};

export default BagIcon;
