import React from 'react';
import useAppColorPalette from 'src/hooks/apps-store/useAppColorPalette';
import ColorPalettePopover, {
  ColorPalettePopoverProps,
} from './ColorPalettePopover/ColorPalettePopover';

const ColorPalettePicker = ({
  open,
  anchorEl,
  onChange,
  onClose,
  value,
}: ColorPalettePopoverProps) => {
  const palette = useAppColorPalette();

  return (
    <ColorPalettePopover
      open={open}
      palette={palette}
      anchorEl={anchorEl}
      onChange={onChange}
      onClose={onClose}
      value={value}
    />
  );
};

export default ColorPalettePicker;
