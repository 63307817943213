import React, { useState } from 'react';
import styled from 'styled-components';
import TableVideoCard from '../dynamic-pdp/TableVideoCard';
import useDrag from 'app/src/hooks/useDrag';
import AddNewVideoButton, {
  AddNewVideoButtonModes,
} from '../add-new-video-button/AddNewVideoButton';
import { VodAssetPayload } from '../../types';
import { track } from 'src/helpers/Tracker';

type Props = {
  isLoading: boolean;
  analyticsData?: {
    [key: string]: string;
  };
  videos: VodAssetPayload[];
  canHide: boolean;
  canOrder: boolean;
  canLock: boolean;
  canAddManualVideo: boolean;
  canRemoveManualVideo: boolean;
  hideVideo: (videoId: string) => void;
  showVideo: (videoId: string, order: number) => void;
  lockVideo: (videoId: string, order: number) => void;
  unlockVideo: (videoId: string, order: number) => void;
  moveVideo: (params: { index: number; draggingFromIndex: number }) => void;
  removeManualVideo: (params: { videoId: string }) => Promise<any>;
  addManualVideo: (params: { videoId: string }) => Promise<any>;
  openProductsModal: ({ video, analyticsData }) => void;
  openVideoPickerModal: ({ onVideoPicked, onModalClosed }: any) => void;
};

const DynamicNonPDP = ({
  videos,
  canHide,
  canOrder,
  canLock,
  canAddManualVideo,
  canRemoveManualVideo,
  isLoading,
  analyticsData,
  hideVideo,
  showVideo,
  lockVideo,
  unlockVideo,
  moveVideo,
  addManualVideo,
  removeManualVideo,
  openVideoPickerModal,
  openProductsModal,
}: Props) => {
  const { onDragOver, handleDragStart, handleDrop, draggingToIndex, draggingFromIndex } = useDrag({
    onDrop,
  });
  const [vodConnectionDeletingMap, setVodConnectionDeletingMap] = useState({});

  function onDrop(index) {
    if (!canOrder) {
      return;
    }

    moveVideo({ index, draggingFromIndex });
  }

  const onAddManualVideo = async video => {
    if (!video?.id) {
      return;
    }

    track('Vod Connections Created', {
      location: 'Dynamic Videos Grid',
    });

    await addManualVideo({ videoId: video.id });
  };

  const onRemoveManualVideo = async video => {
    if (vodConnectionDeletingMap[video.id]) {
      return;
    }

    setVodConnectionDeletingMap(prevState => ({ ...prevState, [video.id]: true }));

    await removeManualVideo({ videoId: video.id });

    setVodConnectionDeletingMap(prevState => ({ ...prevState, [video.id]: false }));
  };

  const onOpenVideoPickerModal = () => openVideoPickerModal({ onVideoPicked: onAddManualVideo });

  const onVideoClick = video => openProductsModal({ video, analyticsData });

  return (
    <LayoutRoot>
      {!isLoading &&
        videos.map((videoPayload, index) => {
          const {
            video,
            isLocked,
            isHidden,
            isDisabled,
            isOutOfStock,
            hasManualVodConnection,
            shouldHideOOSProducts,
          } = videoPayload || {};

          if (vodConnectionDeletingMap[video.id] || !video) {
            return null;
          }

          return (
            <React.Fragment key={video.id}>
              {draggingToIndex === index && !isDisabled && (
                <div
                  onDragStart={e => handleDragStart(e, index)}
                  onDrop={e => handleDrop(e, index, isDisabled)}
                  onDragOver={e => onDragOver(e, index)}
                />
              )}
              <StyledSortableElement
                onDragStart={e => handleDragStart(e, index)}
                onDrop={e => handleDrop(e, index, isDisabled)}
                onDragOver={e => onDragOver(e, index)}
              >
                <TableVideoCard
                  video={video}
                  isDragged={index === draggingFromIndex}
                  isDragDisabled={isDisabled}
                  isLocked={isLocked}
                  isHidden={isHidden}
                  isOutOfStock={isOutOfStock}
                  shouldHideOOSProducts={shouldHideOOSProducts}
                  hasManualVodConnection={hasManualVodConnection}
                  canHide={canHide}
                  canLock={canLock}
                  onClick={onVideoClick}
                  onRemove={canRemoveManualVideo ? () => onRemoveManualVideo(video) : null}
                  hideVideo={() => hideVideo(video.id)}
                  showVideo={() => showVideo(video.id, index)}
                  lockVideo={() => lockVideo(video.id, index)}
                  unlockVideo={() => unlockVideo(video.id, index)}
                />
              </StyledSortableElement>
            </React.Fragment>
          );
        })}
      {canAddManualVideo && (
        <AddNewVideoButton mode={AddNewVideoButtonModes.BigCard} onClick={onOpenVideoPickerModal} />
      )}
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  grid-template-columns: repeat(auto-fill, minmax(171px, 1fr));
  display: grid;
  gap: 16px;
`;

const StyledSortableElement = styled.div``;

export default DynamicNonPDP;
