import React, { useState } from 'react';
import styled from 'styled-components';

import { Select, MenuItem } from '@material-ui/core';
import { TextBody } from 'shared/react/components/complex/Text';
import InitialsBadge from 'app/src/complex_components/InitialsBadge';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import { CircularProgress } from '@material-ui/core';
import { useSessions } from 'app/src/context/SessionsStore';
import Unassigned from 'app/src/images/Unassigned';
import { getInitials } from './contacts.utils';
import Utils from '../../utils';

function getBadgeText(assignee) {
  return getInitials(assignee?.email);
}

const ContactBar = ({ hide, session = {}, teamMembers, user, onUpdateSessionStatus }) => {
  const [loading, setLoading] = useState(false);
  const [, { updateSessionProperties }] = useSessions();
  const { id, name, email, archived, assignee: assigneeId = 'null' } = session;
  const assignee = teamMembers.find(member => member.id === assigneeId);

  const handleAssignChanged = async e => {
    setLoading(true);
    await updateSessionProperties(id, { assignee: e.target.value });
    setLoading(false);
  };

  const handleToggleArchive = async session => {
    setLoading(true);
    await onUpdateSessionStatus(session);
    setLoading(false);
  };

  if (hide || !id || !teamMembers.length) {
    return null;
  }

  const archivedText = archived ? 're-open ticket' : 'close ticket';
  const showAssign = teamMembers.length > 1;
  const hasAssignee = assignee !== 'null';
  const logoSettings = Utils.safeParse(assignee?.user?.logoSettings);
  const logo = Utils.getLogoUrl(logoSettings?.logo);

  return (
    <Container>
      <ContactInfoContainer>
        <ContactName>{name}</ContactName>
        <ContactEmail>{email}</ContactEmail>
      </ContactInfoContainer>
      <ActionsContainer>
        {showAssign && (
          <AssignContact title="assign">
            {hasAssignee ? (
              <InitialsBadge
                imageSrc={logo}
                backgroundColor={logoSettings?.color}
                initials={getBadgeText(assignee)}
              />
            ) : (
              <Unassigned />
            )}
            <AssignSelect
              labelId="assignee-label"
              id="assignee"
              value={assigneeId}
              disableUnderline
              onChange={handleAssignChanged}
            >
              <MenuItem value={'null'}>Unassign</MenuItem>
              {teamMembers.map(({ id, email }) => (
                <MenuItem key={email} value={id || user.id}>
                  {email}
                </MenuItem>
              ))}
            </AssignSelect>
          </AssignContact>
        )}
        <ArchiveButton archived={archived} onClick={() => handleToggleArchive(session)}>
          {archivedText}
        </ArchiveButton>
        {loading && <StyledCircularProgress size={14} />}
      </ActionsContainer>
    </Container>
  );
};

export default ContactBar;

const Container = styled(HorizontalFlexWrap)`
  flex-wrap: unset;
  position: relative;
  align-items: center;
  margin: 23px 23px 0 23px;
  padding: 24px;
  height: 20px;
  background: ${({ theme }) => theme.colors.gray1};
  border-radius: 12px;
  box-shadow: 0 20px 23px 5px rgba(0, 0, 0, 0.05);
  z-index: 2;
  grid-area: conversation;
  justify-content: space-between;
`;

const ContactInfoContainer = styled(HorizontalFlexWrap)`
  justify-content: space-between;
  gap: 12px;
`;

const ContactName = styled(TextBody)`
  font-weight: 700;
  font-size: 14px;
  line-height: 34px;
  color: ${({ theme }) => theme.colors.black};
`;

const ContactEmail = styled(TextBody)`
  font-size: 14px;
  line-height: 34px;
  color: ${({ theme }) => theme.colors.ghostDark};
`;

const ActionsContainer = styled(HorizontalFlexWrap)`
  justify-content: space-between;
  gap: 12px;
`;

const ArchiveButton = styled(TextBody)`
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  margin: 10px;
  padding: 4px 12px;
  cursor: pointer;
  color: ${({ archived, theme }) => (archived ? theme.colors.warning : theme.colors.successDark)};
  background: ${({ archived, theme }) =>
    archived ? theme.colors.warningLight : theme.colors.successLight};
  border-radius: 16px;
`;

const AssignContact = styled(TextBody)`
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.ghostDark};
`;

const AssignSelect = styled(Select)`
  display: inline-flex;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
  font-size: 14px;
  font-weight: bold;

  .MuiSelect-selectMenu {
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
  }

  .MuiSelect-select:focus {
    background: none;
  }

  .MuiSelect-icon {
    display: none;
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  margin-top: 15px;
  right: 15px;
`;
