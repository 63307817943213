import React from 'react';
import styled from 'styled-components';
import Tab from './Tab';
import { DASHBOARD_TABLET_MAX_WIDTH } from 'shared/react/utils/mediaQuery';
import Gap16HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap16HorizontalFlexWrap';

const Tabs = ({ tabs, selectedTabKey, onTabChanged }) => {
  return (
    <LayoutRoot>
      {tabs.map(tab => (
        <Tab key={tab.key} tab={tab} selectedTabKey={selectedTabKey} onTabChanged={onTabChanged} />
      ))}
    </LayoutRoot>
  );
};

export default Tabs;

const LayoutRoot = styled(Gap16HorizontalFlexWrap)`
  @media (${DASHBOARD_TABLET_MAX_WIDTH}) {
    flex-wrap: unset;
    overflow: auto;
  }
`;
