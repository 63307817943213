import styled from 'styled-components';

export const Input = styled.input`
  padding: 6px 8px;
  background: transparent;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.colors.neutralDark};
  border: 1px solid ${({ theme }) => theme.colors.neutralGray};
  font-size: 12px;
  border-radius: 4px;
`;
