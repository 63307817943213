import React from 'react';

const MinusIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.125 10.5C18.125 9.64062 17.4219 8.9375 16.5625 8.9375H3.4375C2.53906 8.9375 1.875 9.64062 1.875 10.5C1.875 11.3984 2.53906 12.0625 3.4375 12.0625H16.5625C17.4219 12.0625 18.125 11.3984 18.125 10.5Z"
        fill="#FA536E"
      />
    </svg>
  );
};

export default MinusIcon;
