import React from 'react';

const RoundedPlayIcon = ({ ...props }) => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.5.875A5.626 5.626 0 0 0 .875 6.5 5.611 5.611 0 0 0 6.5 12.125 5.626 5.626 0 0 0 12.125 6.5C12.125 3.406 9.594.875 6.5.875Zm2.719 6.117L5.844 9.055a.666.666 0 0 1-.282.093A.57.57 0 0 1 5 8.586V4.46c0-.305.234-.563.563-.563a.63.63 0 0 1 .28.07L9.22 6.032a.571.571 0 0 1 .281.492.563.563 0 0 1-.281.47Z"
        fill="#3D4FE0"
      />
    </svg>
  );
};

export default RoundedPlayIcon;
