import Colors from 'shared/react/constants/colors';

const white = Colors.white;
const black = Colors.black;

const DEFAULT_FEED_BUTTON_COLOR = '';
const DEFAULT_FEED_BUTTON_BORDER_RADIUS = 10;
const DEFAULT_FEED_HEADER_COLOR_TEXT_TYPE = Colors.black1;
const DEFAULT_FEED_BODY_COLOR_TEXT_TYPE = Colors.black1;
const DEFAULT_FEED_DISCOUNT_COLOR_TEXT_TYPE = Colors.red7;

const DEFAULT_FEED_PRIMARY_TEXT_COLOR_TYPE = white;
const DEFAULT_FEED_PRIMARY_BACKGROUND_COLOR_TYPE = black;
const DEFAULT_FEED_PRIMARY_BUTTON_BORDER_TYPE = false;
const DEFAULT_FEED_PRIMARY_BUTTON_BORDER_THICKNESS_TYPE = 0.5;
const DEFAULT_FEED_PRIMARY_BORDER_COLOR_TYPE = '';
const DEFAULT_FEED_SECONDARY_TEXT_COLOR_TYPE = black;
const DEFAULT_FEED_SECONDARY_BACKGROUND_COLOR_TYPE = white;
const DEFAULT_FEED_SECONDARY_BUTTON_BORDER_TYPE = true;
const DEFAULT_FEED_SECONDARY_BUTTON_BORDER_THICKNESS_TYPE = 0.5;
const DEFAULT_FEED_SECONDARY_BORDER_COLOR_TYPE = black;

export const FEED_BUTTON_PRIMARY_COLOR_TYPE = 'primaryColor';
export const FEED_BUTTON_BORDER_RADIUS_TYPE = 'borderRadius';

export const FEED_BUTTON_HAS_PRODUCT_PRICE = 'primaryHasProductPrice';
export const FEED_BUTTON_HAS_QUANTITY_PICKER = 'primaryHasQuantityPicker';
export const FEED_PRIMARY_TEXT_COLOR_TYPE = 'primaryTextColor';
export const FEED_PRIMARY_BACKGROUND_COLOR_TYPE = 'primaryBackgroundColor';
export const FEED_PRIMARY_BUTTON_BORDER_TYPE = 'primaryButtonBorderEnabled';
export const FEED_PRIMARY_BUTTON_BORDER_THICKNESS_TYPE = 'primaryButtonBorderThickness';
export const FEED_PRIMARY_BORDER_COLOR_TYPE = 'primaryBorderColor';
export const FEED_SECONDARY_TEXT_COLOR_TYPE = 'secondaryTextColor';
export const FEED_SECONDARY_BACKGROUND_COLOR_TYPE = 'secondaryBackgroundColor';
export const FEED_SECONDARY_BUTTON_BORDER_TYPE = 'secondaryButtonBorderEnabled';
export const FEED_SECONDARY_BUTTON_BORDER_THICKNESS_TYPE = 'secondaryButtonBorderThickness';
export const FEED_SECONDARY_BORDER_COLOR_TYPE = 'secondaryBorderColor';

export const HEADER_COLOR_TEXT_TYPE = 'headerColorText';
export const BODY_COLOR_TEXT_TYPE = 'bodyColorText';
export const DISCOUNT_COLOR_TEXT_TYPE = 'discountedColorText';

export const FEED_BUTTONS_DEFAULT_SETTINGS = {
  [FEED_BUTTON_PRIMARY_COLOR_TYPE]: DEFAULT_FEED_BUTTON_COLOR,
  [FEED_BUTTON_BORDER_RADIUS_TYPE]: DEFAULT_FEED_BUTTON_BORDER_RADIUS,
  [FEED_PRIMARY_TEXT_COLOR_TYPE]: DEFAULT_FEED_PRIMARY_TEXT_COLOR_TYPE,
  [FEED_PRIMARY_BACKGROUND_COLOR_TYPE]: DEFAULT_FEED_PRIMARY_BACKGROUND_COLOR_TYPE,
  [FEED_PRIMARY_BUTTON_BORDER_TYPE]: DEFAULT_FEED_PRIMARY_BUTTON_BORDER_TYPE,
  [FEED_PRIMARY_BUTTON_BORDER_THICKNESS_TYPE]: DEFAULT_FEED_PRIMARY_BUTTON_BORDER_THICKNESS_TYPE,
  [FEED_PRIMARY_BORDER_COLOR_TYPE]: DEFAULT_FEED_PRIMARY_BORDER_COLOR_TYPE,
  [FEED_SECONDARY_TEXT_COLOR_TYPE]: DEFAULT_FEED_SECONDARY_TEXT_COLOR_TYPE,
  [FEED_SECONDARY_BACKGROUND_COLOR_TYPE]: DEFAULT_FEED_SECONDARY_BACKGROUND_COLOR_TYPE,
  [FEED_SECONDARY_BUTTON_BORDER_TYPE]: DEFAULT_FEED_SECONDARY_BUTTON_BORDER_TYPE,
  [FEED_SECONDARY_BUTTON_BORDER_THICKNESS_TYPE]:
    DEFAULT_FEED_SECONDARY_BUTTON_BORDER_THICKNESS_TYPE,
  [FEED_SECONDARY_BORDER_COLOR_TYPE]: DEFAULT_FEED_SECONDARY_BORDER_COLOR_TYPE,
};

export const FEED_TEXT_DEFAULT_SETTINGS = {
  [HEADER_COLOR_TEXT_TYPE]: DEFAULT_FEED_HEADER_COLOR_TEXT_TYPE,
  [BODY_COLOR_TEXT_TYPE]: DEFAULT_FEED_BODY_COLOR_TEXT_TYPE,
  [DISCOUNT_COLOR_TEXT_TYPE]: DEFAULT_FEED_DISCOUNT_COLOR_TEXT_TYPE,
};
