import { PUBLISH_METHOD_OPTIONS, PUBLISH_METHODS } from 'shared/react/constants/tolstoy.constants';
import { CURRENCY_CODES } from 'shared/react/constants/currency.constants';

const getStepKey = ({ key, name }) => {
  return key || name;
};

const constructSingleStepFeedMapping = steps => {
  const [step] = steps;
  return {
    [getStepKey(step)]: {
      current: { ...step, isCurrentVideo: true },
    },
  };
};

const getSecondaryKey = key => {
  return `${key}_2`;
};

const constructTwoStepFeedStep = (primaryStep, secondaryStep) => {
  return {
    prev: { ...secondaryStep, secondaryKey: getSecondaryKey(secondaryStep.key) },
    current: { ...primaryStep, isCurrentVideo: true },
    next: secondaryStep,
  };
};

export const getIsTwoStepFeed = steps => {
  return steps.length === 2;
};

const constructTwoStepFeedMapping = steps => {
  const stepsMap = {};

  const [firstStep, secondStep] = steps;

  stepsMap[getStepKey(firstStep)] = constructTwoStepFeedStep(firstStep, secondStep);
  stepsMap[getStepKey(secondStep)] = constructTwoStepFeedStep(secondStep, firstStep);

  return stepsMap;
};

export const constructStepsMapping = (steps, isSingleStepFeed) => {
  if (isSingleStepFeed) {
    return constructSingleStepFeedMapping(steps);
  }

  if (getIsTwoStepFeed(steps)) {
    return constructTwoStepFeedMapping(steps);
  }

  const stepsMap = {};
  const lastStep = steps[steps.length - 1];
  const firstStep = steps[0];

  steps.forEach((step, index) => {
    stepsMap[getStepKey(step)] = {
      prev: steps[index - 1] || lastStep,
      current: { ...step, isCurrentVideo: true },
      next: steps[index + 1] || firstStep,
    };
  });

  return stepsMap;
};

const getIsStepOutOfView = ({ key, adjacentSteps }) => {
  return !adjacentSteps.some(step => key === step.key);
};

export const getOutOfViewSteps = ({ steps, adjacentSteps }) => {
  return steps.flatMap(step => {
    const { key } = step;
    if (!getIsStepOutOfView({ key, adjacentSteps })) {
      return [];
    }

    return {
      ...step,
      isStepOutOfView: true,
    };
  });
};

export const getAdjacentSteps = ({ stepsMap, currentStepKey }) => {
  return Object.values(stepsMap[currentStepKey]);
};

export const getVariantsImagesSourcesMap = ({ images, variants }) => {
  if (!variants || !images) {
    return;
  }

  const imagesMap = {};

  variants.forEach(({ id, imageId }) => {
    const imageSrcIndex = images.findIndex(({ id }) => id === imageId);

    if (imageSrcIndex !== -1) {
      imagesMap[id] = imageSrcIndex;
    }
  });

  return imagesMap;
};

export const getProductImages = ({ product, selectedVariant }) => {
  const sku = selectedVariant?.sku;

  const variantImages = product?.images?.filter(({ src }) => src.includes(`_${sku}_`)) || [];

  if (variantImages.length) {
    return { images: variantImages, isVariantImagesExclusively: true };
  }

  if (product?.images?.length) {
    return { images: product.images };
  }

  return { images: [{ src: product.imageUrl }] };
};

export const getVariantSelectedValues = productOptions => {
  return Object.values(productOptions).map(({ selectedValue }) => selectedValue);
};

export const getIsSingleStep = steps => {
  return steps.length === 1;
};

export const getIsPlayerVisibleUponStartup = playerType => {
  return !(
    playerType === PUBLISH_METHODS[PUBLISH_METHOD_OPTIONS.carousel].widgetType ||
    playerType === PUBLISH_METHODS[PUBLISH_METHOD_OPTIONS.bubble].widgetType ||
    playerType === PUBLISH_METHODS[PUBLISH_METHOD_OPTIONS.stories].widgetType
  );
};

export const getFormattedCurrencyCode = currencyCode => {
  if (currencyCode === CURRENCY_CODES.usd) {
    return '';
  }

  return `${currencyCode} `;
};

export const getVideoIndexFromTarget = target => {
  return +target.dataset.videoIndex;
};

export const getHasOptions = product => {
  const { options } = product;
  if (!Object.keys(options || {}).length) {
    return false;
  }

  return !Object.values(options).every(({ values }) => values.length === 1);
};

export const getVideoBorderRadius = ({
  carouselBorder = false,
  carouselBorderRadius = 16,
  carouselBorderWidth = 1,
} = {}) => {
  if (!carouselBorder) {
    return carouselBorderRadius;
  }

  return carouselBorderRadius - carouselBorderWidth;
};
