import { getRequest, postRequest } from './API';
import Utils from '../utils';

export const getSignedUrl = (type, path) => {
  return getRequest('upload-url', '/actions/upload/app/get-signed-url', {
    queryStringParameters: {
      type,
      path,
    },
  });
};

export const getSignedImageUrl = path => {
  return getRequest('upload-url', '/api/get-image-url', {
    queryStringParameters: { path },
  });
};

const verifyPublishInputInit = path => {
  return postRequest('video-converter', '/verify-publish-input-init', {
    body: {
      path,
    },
  });
};

export const upload = (url, file, progressCallback) => {
  return new Promise(function (resolve, reject) {
    const xhr = new XMLHttpRequest();

    xhr.onreadystatechange = () => {
      if (xhr.readyState !== 4) {
        return;
      }

      if (xhr.status === 200) {
        resolve(xhr);
      } else {
        reject(xhr.statusText);
      }
    };

    if (progressCallback) {
      xhr.upload.onprogress = event => {
        if (!event.lengthComputable) {
          return;
        }

        const percentComplete = parseInt((event.loaded / file.size) * 100);
        progressCallback(percentComplete);
      };
    }

    xhr.open('PUT', url);
    try {
      xhr.send(file);
    } catch (error) {
      reject(xhr.statusText + error);
    }

    xhr.addEventListener('error', error => {
      reject(xhr.statusText + error);
    });
  });
};

const invalidateByUrl = async url => {
  try {
    const response = await postRequest('publish-actions', '/actions/projects/invalidateByUrl', {
      body: {
        appKey: Utils.getAppKey(),
        url,
      },
    });
    if (response.err) {
      Utils.logError('failed to invalidate url', response.err);
    }

    return response;
  } catch (error) {
    return null;
  }
};

export const uploadToS3 = async (type, path, file, progressCallback, shouldInvalidate = false) => {
  const { uploadURL } = await getSignedUrl(type, path);
  await upload(uploadURL, file, progressCallback);

  if (shouldInvalidate) {
    await invalidateByUrl(Utils.getAssetsUrl() + path);
  }

  if (type === 'video') {
    verifyPublishInputInit(path);
  }
};
