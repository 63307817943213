import { useTrendingVodAssets } from 'app/src/context/VideosStore';
import { getVodConnectionTypeKey } from 'app/src/utils/vodConnections.utils';
import { VodConnectionType } from 'app/src/types/entities';
import { DynamicRuleTypes } from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/constants/dynamicConditions.constants';

const useVodSourceVodConnections = () => {
  const [videos] = useTrendingVodAssets();

  const getVodTrendingVodConnections = ruleGroup => {
    const hasTrendingRule = ruleGroup?.rules?.some(ruleArray =>
      ruleArray.find(rule => rule.type === DynamicRuleTypes.trending)
    );

    if (!hasTrendingRule) {
      return [];
    }

    return videos.flatMap(video => {
      return {
        vodAssetId: video.id,
        typeKey: getVodConnectionTypeKey({
          appKey: video.appKey,
          id: DynamicRuleTypes.trending,
        }),
        type: VodConnectionType.trending,
      };
    });
  };

  return getVodTrendingVodConnections;
};

export default useVodSourceVodConnections;
