import { nanoid } from 'nanoid';
import { CONTAINS_KEY, EQUALS_KEY, NOT_CONTAINS_KEY } from 'src/constants/ruleGroups.constants';
import { DynamicRuleTypes } from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/constants/dynamicConditions.constants';
import { DEFAULT_EMAIL_UTM_PARAM, DEFAULT_TV_PAGE_URL } from 'app/src/constants/project.constants';

export const createRule = (rule = {}) => ({
  key: nanoid(5),
  type: 'url',
  condition: CONTAINS_KEY,
  value: '',
  behaviors: ['visibility'],
  ...rule,
});

export const getDefaultDynamicRule = () => {
  return createRule({
    condition: EQUALS_KEY,
    value: true,
    type: DynamicRuleTypes.taggedProduct,
    behaviors: [],
  });
};

export const getTrendingVideosRule = () => {
  return createRule({
    condition: EQUALS_KEY,
    value: true,
    type: DynamicRuleTypes.trending,
    behaviors: [],
  });
};

export const getAllowNonTaggedVideosRule = () => {
  return createRule({
    condition: EQUALS_KEY,
    value: true,
    type: DynamicRuleTypes.allowNonTaggedVideos,
    behaviors: [],
  });
};

export const getManualVodRule = (projectId: string) => {
  return createRule({
    condition: EQUALS_KEY,
    value: projectId,
    type: DynamicRuleTypes.vodProjectId,
    behaviors: [],
  });
};

export const getEmailRule = (projectId: string) => {
  return createRule({
    value: DEFAULT_EMAIL_UTM_PARAM + projectId,
  });
};

export const getNotTvPageRule = () => {
  return createRule({
    condition: NOT_CONTAINS_KEY,
    value: DEFAULT_TV_PAGE_URL,
  });
};

export const isRuleUsedInRuleGroup = (ruleGroup, rule) => {
  const { rules = [] } = ruleGroup;

  return rules.some(andRules =>
    andRules.some(({ type, value }) => type === rule.type && value === rule.value)
  );
};
