export const EMAIL_LAYOUT_SINGLE_TILE = 'singleTile';
export const EMAIL_LAYOUT_CAROUSEL = 'carousel';

export const EMAIL_LAYOUT_TYPE_OPTIONS = [
  { id: EMAIL_LAYOUT_SINGLE_TILE, name: 'Single Tile' },
  { id: EMAIL_LAYOUT_CAROUSEL, name: 'Carousel' },
];

export const EMAIL_LAYOUT_MAX_ASSETS_COUNT = {
  [EMAIL_LAYOUT_SINGLE_TILE]: 1,
  [EMAIL_LAYOUT_CAROUSEL]: 3,
};
