import APP_CONFIG from 'app/src/config/app.config';
import AUTOMATION_TOOLS from 'app/src/pages/share/email/emailAutomationToolsList';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import usePlayerUrl from 'app/src/hooks/usePlayerUrl';
import { appendParamsToUrl } from 'app/src/utils/url.utils';
import { EMAIL_LAYOUT_CAROUSEL } from 'app/src/pages/share/email/email-layout-type.constants';
import {
  EmailAsset,
  useEmailAssets,
  useProductIdVariable,
} from 'app/src/pages/share/email/email-assets';
import { getEmailSnippet } from 'app/src/pages/share/email/email-snippets';
import { project as Project } from 'app/src/types/entities';
import { SHOP_APP_TARGET } from 'app/src/constants/project.constants';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import { useProjects } from 'app/src/context/ProjectsStore';
import CopyCode, { CLIPBOARD_DATA_FORMAT_HTML } from 'src/complex_components/CopyCode';
import { FlexDirection } from 'src/types/common';
import ComponentWithLoader from 'src/complex_components/ComponentWithLoader';
import CopyToClipboardIcon from 'src/images/dashboard_v2/CopyToClipboardIcon';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import AutomationToolsSelect from './AutomationToolsSelect';
import useHostProjectParams from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/hooks/useHostProjectParams';
import { getProjectEmailSettings } from 'app/src/utils/project.utils';
import { ProjectEmailSettings } from 'app/src/types/project-email-settings';

type EmailSnippetBuilderProps = {
  onSnippetChange: (snippet: string) => void;
  project: Project;
  snippet: string;
  emailSnippet: string;
  isCopyCodeLoading: boolean;
  automationTool: (typeof AUTOMATION_TOOLS)[number] | { id: string };
  setAutomationTool?: (tool: (typeof AUTOMATION_TOOLS)[number]) => void;
  shouldHideAutomationToolsSelect?: boolean;
};

type GetAssetActionUrlProps = {
  asset: EmailAsset;
  dynamicActionUrl: string;
  staticActionUrl: string;
  emailSettings?: ProjectEmailSettings;
};

const AUTOMATION_TOOLS_WITH_PRODUCT_FLOW_SUPPORT = AUTOMATION_TOOLS.filter(
  tool => tool.hasProductVariableSupport
);

const getAssetActionUrl = ({
  asset,
  dynamicActionUrl,
  staticActionUrl,
  emailSettings,
}: GetAssetActionUrlProps) => {
  let resultUrl = '';
  if (asset.dynamic) {
    const { productId, ...otherUrlParams } = asset.actionUrlParams;
    const baseUrl = appendParamsToUrl(dynamicActionUrl, otherUrlParams);
    resultUrl = productId ? `${baseUrl}&productId=${productId}` : baseUrl;
  } else {
    resultUrl = appendParamsToUrl(staticActionUrl, asset.actionUrlParams);
  }

  return emailSettings?.utmParam ? `${resultUrl}&${emailSettings?.utmParam}` : resultUrl;
};

const EmailSnippetBuilder = ({
  project,
  onSnippetChange,
  emailSnippet,
  isCopyCodeLoading,
  automationTool,
  setAutomationTool,
  shouldHideAutomationToolsSelect,
}: EmailSnippetBuilderProps) => {
  const [{ project: sourceProject = project }] = useProjects({
    projectId: project.sourceProjectId || project.id,
  });

  const { isTargetSpecificProductPage } = useHostProjectParams();
  const isPDP = sourceProject?.targetPage === SHOP_APP_TARGET.productPage;
  const productIdVariable = useProductIdVariable(sourceProject, automationTool.id);
  const assets = useEmailAssets({ project, productIdVariable });
  const emailSettings = getProjectEmailSettings(project);

  const { playerUrl } = usePlayerUrl(project?.id);
  const widgetUrl = project.widgetUrls?.[0];
  const staticActionUrl = widgetUrl || playerUrl;
  const dynamicActionUrl = `${APP_CONFIG.API_URL}/settings/${project.publishId}/email-campaign/tiles/action`;

  useEffect(() => {
    const newSnippet = getEmailSnippet({
      assets,
      automationTool: automationTool.id,
      email: '',
      emailLayoutType: { id: EMAIL_LAYOUT_CAROUSEL },
      getAssetActionUrl: asset =>
        getAssetActionUrl({
          asset,
          dynamicActionUrl,
          staticActionUrl,
          emailSettings,
        }),
      pixelTracking: true,
      project,
    });
    onSnippetChange(newSnippet);
  }, [assets, automationTool, project, dynamicActionUrl, staticActionUrl]);

  const toolsList =
    isPDP && !isTargetSpecificProductPage
      ? AUTOMATION_TOOLS_WITH_PRODUCT_FLOW_SUPPORT
      : AUTOMATION_TOOLS;

  return (
    <LayoutRoot>
      <AutomationToolsSelect
        onChange={setAutomationTool}
        value={automationTool}
        items={toolsList}
        shouldHide={shouldHideAutomationToolsSelect}
      />
      <Row>
        <RowHeader>Copy code</RowHeader>
        <CopyCode
          clipboardDataFormat={CLIPBOARD_DATA_FORMAT_HTML}
          codeToCopy={emailSnippet}
          direction={FlexDirection.row}
          hideCodeBox
          buttonText={
            <ComponentWithLoader isLoading={isCopyCodeLoading}>
              <>
                Copy code
                <CopyToClipboardIcon />
              </>
            </ComponentWithLoader>
          }
        />
      </Row>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)`
  padding: 0 8px;
`;

const Row = styled(Gap8VerticalFlex)``;

const RowHeader = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralGrey};
  font-weight: 500;
`;

export default EmailSnippetBuilder;
