import React from 'react';
import styled from 'styled-components';
import Tooltip from 'app/src/basic_components/Tooltip';
import InfoIcon from 'shared/react/images/InfoIcon';

const InfoIconWithTooltip = ({
  title,
  iconFill,
  alignToCenter,
  margin,
  tooltipPlacement,
  customIcon,
  containerWidth,
  ...props
}) => {
  if (!title) {
    return null;
  }

  return (
    <Tooltip containerWidth={containerWidth} text={title} placement={tooltipPlacement}>
      <IconContainer alignToCenter={alignToCenter} margin={margin} {...props}>
        {customIcon || <InfoIcon fill={iconFill} />}
      </IconContainer>
    </Tooltip>
  );
};

const IconContainer = styled.div`
  display: ${({ alignToCenter }) => (alignToCenter ? 'flex' : '')};
  align-items: ${({ alignToCenter }) => (alignToCenter ? 'center' : '')};
  height: ${({ alignToCenter }) => (alignToCenter ? '100%' : '')};
  width: ${({ alignToCenter }) => (alignToCenter ? '100%' : '')};
  line-height: 0;
  margin: ${({ margin }) => margin};
`;

export default InfoIconWithTooltip;
