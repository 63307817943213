import defaultTheme from './default';

import { createTheme } from '@material-ui/core/styles';

const overrides = {
  typography: {
    fontFamily: ['Nunito Sans', 'sans-sarif'].join(','), // there need add Gliber reference,your font family name should be place the first position
    h1: {
      fontSize: '3rem',
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.64rem',
    },
    h4: {
      fontSize: '1.5rem',
    },
    h5: {
      fontSize: '1.285rem',
    },
    h6: {
      fontSize: '1.142rem',
    },
  },
};

export default {
  default: createTheme({ ...defaultTheme, ...overrides }),
};
