import React from 'react';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextH4, TextSmall, TextTiny } from 'shared/react/components/basic/text/TextV2';
import HorizontalFlex, {
  Gap8HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import {
  buttonsByType,
  CASE_CONTENT,
  CaseType,
  emailPlatformsByType,
  pagesByType,
  PERKS_LAYOUT_ONE_COLUMN,
  PERKS_LAYOUT_TWO_COLUMNS,
  perksByType,
} from './cases.constants';
import CasesGallery from 'app/src/pages/dashboard/pages/onsites/components/gallery/CasesGallery';

type Props = {
  caseType: CaseType;
};

const CaseContent = ({ caseType, ...props }: Props) => {
  const {
    title,
    description,
    images,
    pages,
    perks,
    perksLayout = PERKS_LAYOUT_TWO_COLUMNS,
    buttons,
    emailPlatforms,
  } = CASE_CONTENT[caseType];

  const getPerks = () => {
    if (!perks?.length) {
      return;
    }

    const PerksContainer =
      perksLayout === PERKS_LAYOUT_ONE_COLUMN ? ItemsContainerOneColumn : ItemsContainerTwoColumns;
    return (
      <>
        <SectionTitle>Video Type</SectionTitle>
        <PerksContainer>
          {perks.map(perk => {
            const { label, icon } = perksByType[perk];
            return (
              <Item key={label} $border>
                {icon}
                <TextSmall>{label}</TextSmall>
              </Item>
            );
          })}
        </PerksContainer>
      </>
    );
  };

  const getButtons = () => {
    if (!buttons?.length) {
      return;
    }
    return (
      <>
        <SectionTitle>Buttons</SectionTitle>
        <ItemsContainerTwoColumns>
          {buttons.map(buttonType => {
            const { label } = buttonsByType[buttonType];
            return (
              <Item key={label}>
                <TextSmall>{label}</TextSmall>
              </Item>
            );
          })}
        </ItemsContainerTwoColumns>
      </>
    );
  };

  const getPages = () => {
    if (!pages?.length) {
      return;
    }
    return (
      <>
        <SectionTitle>Pages</SectionTitle>
        <ItemsContainerOneColumn>
          {pages.map(pageType => {
            const { label, icon } = pagesByType[pageType];
            return (
              <Item key={label}>
                {icon}
                <TextSmall>{label}</TextSmall>
              </Item>
            );
          })}
        </ItemsContainerOneColumn>
      </>
    );
  };

  const getEmailPlatforms = () => {
    if (!emailPlatforms?.length) {
      return;
    }
    return (
      <>
        <SectionTitle>Supported email platforms</SectionTitle>
        <ItemsContainerOneColumn>
          {emailPlatforms.map(emailPlatform => {
            const { label, icon } = emailPlatformsByType[emailPlatform];
            return (
              <Item key={label}>
                {icon}
                <TextSmall>{label}</TextSmall>
              </Item>
            );
          })}
        </ItemsContainerOneColumn>
      </>
    );
  };

  return (
    <LayoutRoot {...props}>
      <Main>
        <MainInner>
          <TextTiny>Description</TextTiny>
          <Description>{description}</Description>
          {getPages()}
          {getPerks()}
          {getButtons()}
          {getEmailPlatforms()}
        </MainInner>
      </Main>
      <CasesGallery images={images} title={title} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)`
  padding: 0 24px;
  gap: 32px;
`;

const Description = styled(TextH4)`
  margin: 8px 0 56px 0;
`;

const Main = styled(VerticalFlex)`
  position: relative;
  flex-grow: 1;
  min-width: 300px;
  padding-bottom: 24px;
`;

const MainInner = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
`;

const SectionTitle = styled(TextTiny)`
  margin-top: 16px;
`;

const Item = styled(Gap8HorizontalFlex)`
  align-items: center;
  height: 48px;
`;

const ItemsContainerOneColumn = styled(VerticalFlex)`
  padding-left: 8px;

  & ${Item} {
    &:not(:first-of-type) {
      border-top: 1px solid ${({ theme }) => theme.colors.neutralLighter};
    }

    &:not(:first-of-type):last-of-type {
      border-bottom: 1px solid ${({ theme }) => theme.colors.neutralLighter};
    }
  }
`;

const ItemsContainerTwoColumns = styled.div`
  display: grid;
  padding-left: 8px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 16px;

  & ${Item} {
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutralLighter};
  }
`;

export default CaseContent;
