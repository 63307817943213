import { useEffect, useState } from 'react';
import { FolderType, project } from 'app/src/types/entities';
import { useDashboard } from 'app/src/context/ui_store/DashboardStore';
import { useProjectActions } from 'app/src/context/ProjectsStore';
import filterEntities, { WhereCondition } from 'app/src/pages/dashboard/utils/filterEntities';
import useNavigation from 'app/src/hooks/useNavigation';
import { useFolders } from 'app/src/context/FoldersStore';
import { useModalActions } from 'app/src/context/ui_store/ModalStore';
import {
  RENAME_FOLDER_MODAL_KEY,
  DELETE_FOLDER_MODAL_KEY,
} from 'app/src/constants/modals.constants';
import { MODAL_STATE } from 'app/src/pages/modals/folder_modal/folders.constants';
import { track } from 'app/src/helpers/Tracker';

type Props = {
  isOffsite?: boolean;
};

export const useSidebarFolderItems = ({ isOffsite = false }: Props = {}) => {
  const [{ offsiteFilters, onSiteFilters }, { setOnSiteFilters, setOffsiteFilters }] =
    useDashboard();
  const { navigateToDashboardV2Offsite, navigateToDashboardV2Onsite } = useNavigation();
  const { getDisplayedProjects } = useProjectActions();
  const { setCurrentModal, setCurrentModalType, setModalProps, setCurrentFolderId } =
    useModalActions();
  const [foldersMapping, setFoldersMapping] = useState<{ [key: string]: number }>({});
  const [totalProjects, setTotalProjects] = useState(0);

  const filters = isOffsite ? offsiteFilters : onSiteFilters;
  const appUrl = filters?.appUrl?.in[0] as string;
  const selectedFolderId = filters.folder?.eq as string;
  const type = isOffsite ? FolderType.projects : FolderType.onsites;

  const [{ folders }] = useFolders({ type, appUrl });

  useEffect(() => {
    const newFoldersMapping = {};
    const { layout, folder, playlists, ...folderFilters } = filters;
    const projects = filterEntities<project>(getDisplayedProjects(), folderFilters);

    projects.forEach(({ folder }) => {
      const folderNumber = newFoldersMapping[folder];
      newFoldersMapping[folder] = folderNumber ? folderNumber + 1 : 1;
    });

    setFoldersMapping(newFoldersMapping);
    setTotalProjects(projects.length);
  }, [offsiteFilters, onSiteFilters]);

  const setFilters = (updatedFilters: WhereCondition) => {
    const route = updatedFilters?.folder?.eq || 'all';

    if (isOffsite) {
      setOffsiteFilters(updatedFilters);
      navigateToDashboardV2Offsite(route as string);
    } else {
      setOnSiteFilters(updatedFilters);
      navigateToDashboardV2Onsite(route as string);
    }
  };

  const createNewFolder = () => {
    setCurrentModal(RENAME_FOLDER_MODAL_KEY);
    setModalProps({ type, appUrl });
    setCurrentModalType(MODAL_STATE.new);
  };

  const handleOpenModalByKey = (folderId: string, modalKey: string, type = '') => {
    track('Folder Menu Item Click', { type: modalKey });
    setCurrentFolderId(folderId);
    setCurrentModalType(type);
    setCurrentModal(modalKey);
  };

  const resetFolder = () => {
    setFilters({ folder: null });
  };

  const setSelectedFolder = folder => setFilters({ folder: { eq: folder.id } });

  const onRename = folder =>
    handleOpenModalByKey(folder.id, RENAME_FOLDER_MODAL_KEY, MODAL_STATE.rename);

  const onDelete = folder => handleOpenModalByKey(folder.id, DELETE_FOLDER_MODAL_KEY);

  return {
    folders,
    totalProjects,
    foldersMapping,
    selectedFolderId,
    resetFolder,
    setSelectedFolder,
    createNewFolder,
    onRename,
    onDelete,
  };
};

export default useSidebarFolderItems;
