import styled from 'styled-components';
import { Modal as MUIModal } from '@material-ui/core';

export const Container = styled.div`
  outline: none;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.31);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;
`;

export const Modal = styled(MUIModal)`
  display: flex;
  justify-content: center;
  ${({ $isFixedHeight }) => ($isFixedHeight ? 'padding-top: 8vw;' : 'align-items: center;')};
  z-index: ${({ 'z-index': zIndex }) => (zIndex ? `${zIndex} !important` : '')};
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 0;
  text-align: left;
  cursor: pointer;
  padding: ${props => props?.padding || '15px'};
  background: ${({ theme }) => theme.colors.white};
  background: ${props => props?.background || props.theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray15};
  box-sizing: border-box;
  border-radius: ${props => props?.borderRadius || '14px'};
  transition: 0.3s;
  min-height: ${props => props?.minHeight || '0px'};
  text-decoration: ${props => props?.textDecoration || 'none'};

  &:hover {
    transition: 0.3s;
    filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.1));
  }

  &:focus {
    outline: none;
  }

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: ${props => props?.mobileWidth || '250px'};
  }
`;

export const CardImage = styled.img`
  margin-top: 16px;
  border-radius: 4px;
  object-fit: cover;
  max-height: 114px;
`;

export const CardTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.blue8};
`;

export const CardDescription = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.gray8};
`;
