import React from 'react';
import styled, { useTheme } from 'styled-components';
import { track } from 'app/src/helpers/Tracker';
import { useVideoActions, useVideos } from 'app/src/context/VideosStore';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import {
  CAPTIONS_EDITOR_MODAL_KEY,
  MOVE_TOLSTOY_MODAL_KEY,
  SET_THUMBNAIL_MODAL_KEY,
  VIDEO_ADD_CREATOR_PROFILE_MODAL_KEY,
  VIDEO_DELETE_MODAL_KEY,
  VIDEO_RENAME_MODAL_KEY,
} from 'app/src/constants/modals.constants';
import DownloadIcon from 'app/src/images/DownloadIcon';
import ArrowRight from 'app/src/images/folders/ArrowRight';
import { FolderType } from 'app/src/types/entities';
import PencilIcon from 'app/src/images/PencilIcon';
import MenuWithItems from 'app/src/complex_components/menu_items/MenuWithItems';
import TrashIcon from 'app/src/images/sidebar-v2/TrashIcon';
import ThumbnailEditIcon from 'app/src/images/dashboard_v2/ThumbnailEditIcon';
import CaptionsIcon from 'src/images/edit_step_icons/CaptionsIcon';
import { AssetsUtilsService } from 'shared/react/services/assets';
import { UGC_INSTAGRAM_GRAPH } from 'app/src/constants/intergrations.constants';
import PlusIcon from 'app/src/images/dashboard_v2/PlusIcon';
import ArrowUpRightFromSquare from 'app/src/images/ArrowUpRightFromSquare';
import useCreateLabelsByUGC from 'app/src/complex_components/global_modal/ugc-request-modal/useCreateLabelsByUGC';
import Utils from 'shared/react/utils/utils';
import AtIcon from 'app/src/images/AtIcon';

/*
TODO: This component gets 'isPending' and 'isApproved' only to hide most and show some actions for UGC
  1. move all actions in this component to a hook
  2. create a custom MenuItems for UGC (combine hook and MenuItems component)
*/
const VideoCardMenuItems = ({
  video,
  isMenuOpen,
  setMenuOpen,
  method,
  onSelectedVideosAction,
  isPending,
  isApproved,
}) => {
  const [, { setCurrentId, setCurrentModal, setCurrentIds, setModalProps }] = useModal();
  const { updateVideo } = useVideoActions();
  const [, { downloadVideo }] = useVideos();
  const theme = useTheme();
  const isVideoAsset = AssetsUtilsService.isVideoAsset(video);
  const createLabelsByUGC = useCreateLabelsByUGC({ video });

  const onAddToLibraryUGC = async e => {
    await updateVideo({ id: video.id, uploadType: UGC_INSTAGRAM_GRAPH });
    await createLabelsByUGC();
  };

  const onViewPostUGC = async e => {
    track('UGC View Post Click', { videoId: video.id, status: 'approved' });

    const { postUrl } = JSON.parse(video.externalProviderData);
    Utils.openInNewTab(postUrl);
  };

  const handleRenameClick = e => {
    e?.stopPropagation();
    track('Video Card Menu Item Click', { type: VIDEO_RENAME_MODAL_KEY });
    onSelectedVideosAction([video]);
    setCurrentId(video.id);
    setCurrentModal(VIDEO_RENAME_MODAL_KEY);
  };

  const handleAddCreatorProfileClick = e => {
    e?.stopPropagation();
    const type = VIDEO_ADD_CREATOR_PROFILE_MODAL_KEY;
    track('Video Card Menu Item Click', { type });
    setCurrentId(video.id);
    setCurrentModal(type);
  };

  const handleDeleteClick = e => {
    e?.stopPropagation();
    track('Video Card Menu Item Click', { type: VIDEO_DELETE_MODAL_KEY });
    setCurrentIds([video.id]);
    setCurrentModal(VIDEO_DELETE_MODAL_KEY);
  };

  const handleCloseMenu = () => {
    setMenuOpen(null);
  };

  const handleDownload = async e => {
    e?.stopPropagation();
    track('Video Card Menu Item Click', { type: 'Download' });
    await downloadVideo(video);
  };

  const handleOpenModalByKey = modalKey => {
    track('Video Card Menu Item Click', { type: modalKey });
    onSelectedVideosAction([video]);
    setCurrentModal(modalKey);
    setModalProps({ ids: [video.id], type: FolderType.videos });
  };

  const handleMoveVideo = () => {
    handleOpenModalByKey(MOVE_TOLSTOY_MODAL_KEY);
    setModalProps({ ids: [video.id], type: FolderType.videos });
  };

  const onSetThumbnail = () => {
    handleOpenModalByKey(SET_THUMBNAIL_MODAL_KEY);
    setModalProps({ id: video.id });
  };

  const handleOpenCaptionsEditor = () => {
    handleOpenModalByKey(CAPTIONS_EDITOR_MODAL_KEY);
    setModalProps({ videoId: video.id });
  };

  const MENU_ITEMS = [
    {
      text: 'Set thumbnail',
      icon: <ThumbnailEditIcon />,
      onClick: onSetThumbnail,
      hidden:
        isPending ||
        isApproved ||
        !isVideoAsset ||
        video.duration <= 3 ||
        !video ||
        (video?.stockAsset?.videoUrl && !video?.stockAsset?.hasOriginal),
    },
    {
      text: 'Rename',
      icon: <PencilIcon />,
      onClick: handleRenameClick,
      hidden: isPending || isApproved,
    },
    {
      text: 'Download',
      onClick: handleDownload,
      icon: <DownloadIcon />,
      hidden: video?.app || isPending || isApproved,
    },
    {
      text: 'Move Asset',
      icon: <ArrowRight fill={theme.colors.ghostDark} />,
      onClick: handleMoveVideo,
      hidden: isPending || isApproved,
    },
    {
      text: 'Edit Captions',
      icon: <CaptionsIcon />,
      onClick: handleOpenCaptionsEditor,
      hidden: !isVideoAsset || isPending || isApproved,
    },
    {
      text: 'Add to Library',
      icon: <PlusIcon />,
      onClick: onAddToLibraryUGC,
      hidden: !isPending,
    },
    {
      text: 'Edit creator handle',
      icon: <AtIcon />,
      onClick: handleAddCreatorProfileClick,
      hidden: !isVideoAsset || isPending || isApproved,
    },
    {
      text: isPending ? 'Remove from pending' : 'Delete',
      icon: <TrashIcon />,
      onClick: handleDeleteClick,
      hidden: !!method || isApproved,
    },
    {
      text: 'View Post',
      icon: <ArrowUpRightFromSquare />,
      onClick: onViewPostUGC,
      hidden: !isApproved,
    },
  ];

  return (
    <CustomMenuItems anchorElement={isMenuOpen} onClose={handleCloseMenu} menuItems={MENU_ITEMS} />
  );
};

const CustomMenuItems = styled(MenuWithItems)`
  min-width: 168px;
`;

export default VideoCardMenuItems;
