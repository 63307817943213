import PublishVideoIcon from 'app/src/images/PublishVideoIcon';
import React from 'react';
import styled, { css } from 'styled-components';
import Button from 'shared/react/components/complex/Button';
import { DASHBOARD_TABLET_MAX_WIDTH } from 'shared/react/utils/mediaQuery';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Utils from 'app/src/utils';
import { track } from 'app/src/helpers/Tracker';
import Routes from 'app/src/helpers/Routes';
import { useOnboarding } from 'app/src/context/ui_store/OnboardingStore';
import { useHistory, useLocation } from 'react-router-dom';
import { useApps } from 'app/src/context/AppsStore';
import { prepareShareParam } from 'app/src/pages/share/share.utils';
import { useShareTypes } from 'app/src/context/ui_store/ShareTypesStore';
import { EMAIL_KEY, LINK_KEY, WIDGET_KEY } from 'app/src/constants/tolstoy.constants';

const ShareButton = ({ project }) => {
  const isMobile = useMediaQuery(`(${DASHBOARD_TABLET_MAX_WIDTH})`) || Utils.isMobile();
  const [{ isShareButtonHighlighted }, { setHighlightShareButton }] = useOnboarding();
  const [, { isShopifyOrWix }] = useApps();
  const [{ lastUsedShareType }] = useShareTypes();
  const history = useHistory();
  const { pathname: currentLocation } = useLocation();

  const getShareParams = () => {
    if (lastUsedShareType) {
      return prepareShareParam(lastUsedShareType);
    }

    if (isShopifyOrWix()) {
      return prepareShareParam(WIDGET_KEY);
    }

    if (project.tolstoyType === 'readyMade_collect_reviews') {
      return prepareShareParam(EMAIL_KEY);
    }

    return prepareShareParam(LINK_KEY);
  };

  const handleShareTolstoy = () => {
    track('Share Click');

    setHighlightShareButton(false);

    const shareParams = getShareParams();
    history.push(
      `${currentLocation.replace(Routes.getProjectIdParameter(), project.id)}${shareParams}`
    );
  };

  return (
    <StyledShareButton
      highlighting={isShareButtonHighlighted}
      onClick={handleShareTolstoy}
      leftIcon={<PublishVideoIcon />}
      showLeftIcon={true}
      padding="0 20px 0 4px"
      textWidth="max-content"
      fullWidth={isMobile}
    >
      Publish Tolstoy
    </StyledShareButton>
  );
};

export default ShareButton;

const highlightingAnimationCSS = css`
  @keyframes highlighting {
    0% { opacity: 1; }
    100% { opacity: 0.6; }
  }

  animation: highlighting 0.5s infinite alternate;
`;

const StyledShareButton = styled(Button)`
  z-index: ${({ highlighting }) => (highlighting ? 11 : 0)};
  @media (${({ theme }) => theme.breakpoints.dashboardTabletMax}) {
    grid-row: 1;
    grid-column: 2;
  }

  ${({ highlighting }) => highlighting ? highlightingAnimationCSS : ''}
`;
