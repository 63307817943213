import React from 'react';
import styled from 'styled-components';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import ImageWithLoader from 'shared/react/components/complex/ImageWithLoader';
import ModalWrapper from 'shared/react/components/basic/ModalWrapper';
import Portal from 'shared/react/components/basic/Portal';
import XIcon from 'shared/react/images/XIcon';
import BackdropButton from 'shared/react/components/complex/BackdropButton';

const FeedProductImagesModal = ({
  imageSrc,
  isOpen,
  isMobile,
  onClose,
  setIsOpen,
  hideBackgroundOverlay,
  children,
  ...props
}) => {
  return (
    <Portal>
      <StyledModalWrapper
        isOpen={isOpen}
        isMobile={isMobile}
        setIsOpen={setIsOpen}
        onClose={onClose}
        backgroundZIndex={50}
        disableAutoOpen
        {...props}
      >
        <Container isMobile={isMobile}>
          <XButton onClick={onClose}>
            <BackdropButton>
              <XIcon size={18} />
            </BackdropButton>
          </XButton>
          <ImageWithLoader src={imageSrc} />
        </Container>
      </StyledModalWrapper>
    </Portal>
  );
};

const XButton = styled(HorizontalFlexCentered)`
  position: absolute;
  z-index: 1;
  right: 8px;
  top: 8px;
  width: 40px;
  height: 40px;
  cursor: pointer;

  & svg {
    width: 18px;
    height: 18px;
  }

  & path {
    fill: ${({ theme }) => theme.colors.neutralDarker};
  }
`;

const StyledModalWrapper = styled(ModalWrapper)`
  top: 50%;
  left: 50%;
  transform: translate(${({ isOpen }) => (isOpen ? '-50%, -50%' : '-50%, 100vh')});
  max-width: 100%;
  max-height: 100%;
  background: unset;
  border-radius: unset;
  z-index: 51;
  align-content: center;

  & img {
    border-radius: unset;
    object-fit: contain;
    max-height: 100vh;
    max-width: 100vw;
  }
`;

const Container = styled.div`
  position: relative;
  width: max-content;
`;

export default FeedProductImagesModal;
