import React from 'react';

const MacroIcon = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x=".5" width="16" height="16" rx="8" fill="#F4F5F7" />
      <path
        d="M13 3.625A1.14 1.14 0 0 0 11.875 2.5h-6.75C4.492 2.5 4 3.016 4 3.625V14.5l4.5-2.625L13 14.5V3.625Z"
        fill="#50545E"
      />
    </svg>
  );
};

export default MacroIcon;
