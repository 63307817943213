export const boxShadows = {
  elevation1: '0px 2px 4px 0px rgba(50, 50, 93, 0.10)',
  elevation2: '0px 1px 3px -1px rgba(0, 0, 0, 0.05), 0px 2px 10px 0px rgba(50, 50, 93, 0.10)',
  elevation3: '0px 2px 5px 0px rgba(0, 0, 0, 0.04), 0px 5px 15px 0px rgba(50, 50, 93, 0.10)',
  elevation4: '0px 5px 10px 0px rgba(0, 0, 0, 0.05), 0px 10px 25px 0px rgba(50, 50, 93, 0.10)',
  elevation6: '0px 5px 15px 0px rgba(0, 0, 0, 0.07), 0px 15px 35px 0px rgba(50, 50, 93, 0.10)',
  elevation8: '0px 3px 10px -3px rgba(0, 0, 0, 0.10), 0px 6px 40px -2px rgba(50, 50, 93, 0.30)',
  elevation9: '0px 5px 10px -2px rgba(0, 0, 0, 0.10), 0px 15px 45px 0px rgba(50, 50, 93, 0.25)',
  elevation12: '0px 10px 25px -4px rgba(0, 0, 0, 0.16), 0px 30px 50px -4px rgba(50, 50, 93, 0.25)',
  elevation16:
    '0px -6px 15px -10px rgba(0, 0, 0, 0.25), 0px 8px 16px -8px rgba(0, 0, 0, 0.30), 0px 25px 50px -5px rgba(50, 50, 93, 0.45)',
  elevation24:
    '0px -12px 36px -8px rgba(0, 0, 0, 0.25), 0px 18px 36px -18px rgba(0, 0, 0, 0.30), 0px 38px 68px -12px rgba(50, 50, 93, 0.60)',
};
