import React from 'react';
import styled from 'styled-components';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextTiny } from 'shared/react/components/complex/Text';
const phases = ['Store', 'Parts', 'Rules'];

const ExportPhases = ({ activeIndex, lastIndex }) => {
  return (
    <LayoutRoot>
      {phases.map((phaseName, index) => {
        const phaseIndex = index + 1;
        return (
          <HorizontalFlex key={index}>
            <Item>
              <Circle isActive={activeIndex === phaseIndex}>{phaseIndex}</Circle>
              <TextTiny>{phaseName}</TextTiny>
            </Item>
            {<Line isLast={index === lastIndex} />}
          </HorizontalFlex>
        );
      })}
    </LayoutRoot>
  );
};

export default ExportPhases;

const LayoutRoot = styled(HorizontalFlex)`
  justify-content: center;
  align-items: center;
`;

const Item = styled(LayoutRoot)`
  gap: 8px;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    gap: 0px;
  } ;
`;

const Circle = styled(LayoutRoot)`
  border-radius: 50px;
  background: ${({ theme, isActive }) => (isActive ? theme.colors.primary : theme.colors.gray3)};
  color: ${({ theme, isActive }) => (isActive ? theme.colors.white : theme.colors.black)};
  font-size: 12px;
  font-weight: 600;
  width: 20px;
  height: 20px;
  margin-right: 8px;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    margin: 12px;
  } ;
`;

const Line = styled.div`
  width: 34px;
  height: 1px;
  background: ${({ theme }) => theme.colors.ghostLight};
  flex: none;
  order: 1;
  flex-grow: 0;
  display: ${({ isLast }) => (isLast ? 'none' : '')};
  margin: 8px;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    display: none;
  } ;
`;
