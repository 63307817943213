import React from 'react';
import { useTheme } from 'styled-components';
import CogIcon from 'src/images/CogIcon';
import CurvedLightningIcon from 'app/src/images/creation_method_icons/CurvedLightningIcon';
import { ButtonGroupV2 } from 'src/complex_components/button-group/ButtonGroupV2';

export type FeedTypeToggle = {
  isDynamic: boolean;
  onChange: (isDynamic: boolean) => void;
};

const FeedTypeToggleButtons = ({ isDynamic, onChange }) => {
  const theme = useTheme();
  const getColor = isEnabled => (isEnabled ? theme.colors.primary : theme.colors.gray27);

  const buttons = [
    {
      isEnabled: isDynamic,
      value: true,
      onClick: () => onChange(true),
      icon: (
        <>
          <CurvedLightningIcon fill={getColor(isDynamic)} />
          &nbsp;Automated
        </>
      ),
    },
    {
      isEnabled: !isDynamic,
      value: false,
      onClick: () => onChange(false),
      icon: (
        <>
          <CogIcon fill={getColor(!isDynamic)} />
          &nbsp;Manual
        </>
      ),
    },
  ];

  return <ButtonGroupV2 buttons={buttons} />;
};

export default FeedTypeToggleButtons;
