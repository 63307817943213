import React from 'react';
import { useTheme } from 'styled-components';

function GoToLeadFormIcon({ fill }) {
  const theme = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" fill="none" viewBox="0 0 12 16">
      <path
        fill={fill || theme.colors.ghostDark}
        d="M8 0v4h4L8 0zM7 4V0H1.5A1.5 1.5 0 000 1.5v13c0 .844.656 1.5 1.5 1.5h9a1.5 1.5 0 001.5-1.5V5H8c-.563 0-1-.438-1-1zm1.5 9h-5a.494.494 0 01-.5-.5c0-.25.219-.5.5-.5h5c.25 0 .5.25.5.5 0 .281-.25.5-.5.5zm0-2h-5a.494.494 0 01-.5-.5c0-.25.219-.5.5-.5h5c.25 0 .5.25.5.5 0 .281-.25.5-.5.5zM9 8.5c0 .281-.25.5-.5.5h-5a.494.494 0 01-.5-.5c0-.25.219-.5.5-.5h5c.25 0 .5.25.5.5z"
      ></path>
    </svg>
  );
}

export default GoToLeadFormIcon;
