import { publishMethodOptions } from 'app/src/types/entities';

export const LAYOUTS = [
  publishMethodOptions.stories,
  publishMethodOptions.carousel,
  publishMethodOptions.hero,
  publishMethodOptions.videoPage,
  publishMethodOptions.bubble,
  publishMethodOptions.tile,
];

export const LAYOUT_LABELS = {
  [publishMethodOptions.stories]: 'Stories',
  [publishMethodOptions.carousel]: 'Carousel',
  [publishMethodOptions.hero]: 'Hero',
  [publishMethodOptions.videoPage]: 'TV Page',
  [publishMethodOptions.bubble]: 'Widget',
  [publishMethodOptions.tile]: 'Tile',
};
