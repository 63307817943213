import { useUser } from 'app/src/context/userStore/UserStore';
import { usePackages } from 'app/src/context/PackagesStore';
import {
  FEATURE_APP_LIMITS,
  FEATURE_APP_PRICING,
  FEATURE_LIMIT_ANALYTICS,
  FEATURE_LIMIT_IN_APP_NOTIFICATIONS,
  FEATURE_LIMIT_RESPONSES,
  FEATURE_WIDGET_SET_LIVE,
} from 'app/src/constants/appFeatures.constants';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { useProjects } from 'app/src/context/ProjectsStore';
import { filterVideosWithoutReadyMades } from '../limits';
import { useVideos } from 'app/src/context/VideosStore';
import Utils from '../utils';
import { BILLING_PACKAGE_TYPES } from '../constants/billings.constants';
import { getIsUnlimited } from '../utils/features.utils';
import { getPackageDetails } from 'app/src/complex_components/billings/BillingPackages';
import { useAccount } from 'app/src/context/AccountStore';
import { PAYMENT_ROLE_LITE_KEY } from '../constants/teamMembers.constants';
import { postRequest } from '../helpers/API';
import { useApps } from 'app/src/context/AppsStore';
import { track } from 'app/src/helpers/Tracker';
import {
  FEATURE_BILLING_ENABLED,
  FEATURE_EMBED_SET_LIVE,
} from 'shared/react/constants/features.constants';
import Routes from 'app/src/helpers/Routes';
import { useCallback } from 'react';

const PAYMENTS_PROVIDER = {
  shopify: 'shopify',
  stripe: 'stripe',
};

export const useBilling = () => {
  const [{ account, user }] = useUser();
  const [{ isAccountOwner, teamMembers }, { findUser }] = useAccount();
  const [{ paymentProvider, cancelAt, failedInvoiceUrl }, { getPackage, getStripeSessionLink }] =
    usePackages();
  const [, { getFeatureEnabled, getSettingsByKey }] = useFeatures();
  const [videos] = useVideos();
  const [{ numberOfTeamMemberTolstoys }] = useProjects();
  const [{ shopify }] = useApps();

  const maxNumberOfAllowedTolstoys =
    getSettingsByKey(FEATURE_APP_LIMITS).maxNumberOfAllowedTolstoys || 10;
  const hasReachedAnalyticsLimit =
    getSettingsByKey(FEATURE_LIMIT_ANALYTICS).hasReachedLimit || false;
  const maxNumberOfAllowedVideos = getSettingsByKey(FEATURE_APP_LIMITS).maxNumberOfAllowedVideos;
  const hasReachedResponsesLimit = getSettingsByKey(FEATURE_LIMIT_RESPONSES)?.hasReachedLimit;
  const hasReachedInAppNotificationsLimit = getSettingsByKey(
    FEATURE_LIMIT_IN_APP_NOTIFICATIONS
  )?.hasReachedLimit;
  const isPricingEnabled = getFeatureEnabled(FEATURE_APP_PRICING);
  const embedSetLiveEnabled = getFeatureEnabled(FEATURE_EMBED_SET_LIVE);

  const filteredVideosLength = filterVideosWithoutReadyMades(videos);

  const hasReachedVideosLimit =
    !getIsUnlimited(maxNumberOfAllowedVideos) && filteredVideosLength >= maxNumberOfAllowedVideos;
  const isShopifySubscription = paymentProvider === PAYMENTS_PROVIDER.shopify;

  const getHasReachedTolstoysLimit = () => {
    if (getIsUnlimited(maxNumberOfAllowedTolstoys)) {
      return false;
    }

    if (!isPricingEnabled) {
      return false;
    }

    return numberOfTeamMemberTolstoys >= maxNumberOfAllowedTolstoys;
  };

  const shouldLockTolstoyCreation = () => {
    if (!isPricingEnabled) {
      return false;
    }

    return getHasReachedTolstoysLimit() || hasReachedVideosLimit;
  };

  const getHasReachedLiveWidgetsLimit = () => {
    const isWidgetSetLiveEnabled = getFeatureEnabled(FEATURE_WIDGET_SET_LIVE);

    if (!isWidgetSetLiveEnabled) {
      return true;
    }

    const { maxNumberOfAllowedLiveWidgets } = getSettingsByKey(FEATURE_WIDGET_SET_LIVE) || {};

    if (!maxNumberOfAllowedLiveWidgets && maxNumberOfAllowedLiveWidgets !== 0) {
      return false;
    }

    return maxNumberOfAllowedLiveWidgets <= (account?.liveIds?.length || 0);
  };

  const getHasMoreLiveWidgetsThanLimit = () => {
    const reachedLiveWidgetsLimit = getHasReachedLiveWidgetsLimit();

    if (!reachedLiveWidgetsLimit) {
      return false;
    }

    const { maxNumberOfAllowedLiveWidgets } = getSettingsByKey(FEATURE_WIDGET_SET_LIVE) || {};

    return maxNumberOfAllowedLiveWidgets < (account?.liveIds?.length || 0);
  };

  const openBillingPage = useCallback(() => {
    if (isShopifySubscription) {
      track('Open Shopify Billing Page');
      return openShopifyBillingPage();
    }

    track('Open Stripe Billing Page');
    return openStripeBillingPage({ sameTab: false });
  }, [isShopifySubscription]);

  const openShopifyBillingPage = () => {
    const link = `https://${shopify.appUrl}/admin/app_installations/app/tolstoy`;
    return Utils.openInNewTab(link);
  };

  const openStripeBillingPage = async ({ sameTab } = {}) => {
    const link = await getStripeSessionLink();
    if (sameTab) {
      return Utils.openInTheSameTab(link);
    }

    return Utils.openInNewTab(link);
  };

  const downgradeToFreePackage = history => {
    return history.push(Routes.getDowngradeBaseRoute());
  };

  const getPackageTrialDays = packageInfo => {
    if (account?.trialEndAt || !packageInfo) {
      return 0;
    }

    return packageInfo?.trialPeriodDays || 0;
  };

  const getPackageActionText = packageId => {
    const packageInfo = getPackage(packageId);
    const numOfTrialDays = getPackageTrialDays(packageInfo);

    if (!numOfTrialDays) {
      const { buttonText } = getPackageDetails(packageInfo || {});
      return buttonText;
    }

    return `Start free ${numOfTrialDays}-day trial`;
  };

  const getEmailRequestBody = () => {
    const accountOwner = teamMembers.find(({ role }) => role === 'owner');
    const body = {
      to: accountOwner.email,
      userEmail: user.email,
      userId: user.owner,
    };

    return body;
  };

  const sendCreatorProRequestToAdmin = () => {
    return postRequest('user-actions', '/actions/users/role-change', {
      body: getEmailRequestBody(),
    });
  };

  const sendUpgradePlanRequestToOwner = () => {
    return postRequest('user-actions', '/actions/users/upgrade-account-plan-request', {
      body: getEmailRequestBody(),
    });
  };

  const handleTrackOpenSubscriptionPage = () => {
    if (isShopifySubscription) {
      track('Open Shopify Subscription Page');
      return;
    }

    track('Open Stripe Subscription Page');
  };

  const getUserPaymentRole = () => {
    return findUser(account?.owner)?.paymentRole;
  };

  const currentPackage = getPackage(account?.packageId);
  const hasReachedTolstoysLimit = getHasReachedTolstoysLimit();
  const hasReachedLiveWidgetsLimit = getHasReachedLiveWidgetsLimit();
  const hasMoreLiveWidgetsThanLimit = getHasMoreLiveWidgetsThanLimit();

  const currentUserPaymentRole = getUserPaymentRole();
  const isPaymentRoleLight = currentUserPaymentRole === PAYMENT_ROLE_LITE_KEY;
  const isFreePackage = isPricingEnabled && currentPackage?.type === BILLING_PACKAGE_TYPES.free;
  const isProPackage = isPricingEnabled && currentPackage?.type === BILLING_PACKAGE_TYPES.pro;
  const isBusinessPackage =
    isPricingEnabled && currentPackage?.type === BILLING_PACKAGE_TYPES.business;
  const isEnterprisePackage = currentPackage?.type === BILLING_PACKAGE_TYPES.enterprise;
  const isPayingSelfServicePackage = isProPackage || isBusinessPackage;
  const isPaying = !!paymentProvider;
  const billingPageFeatureEnabled = getFeatureEnabled(FEATURE_BILLING_ENABLED) && isPaying;
  const isBillingPageEnabled = billingPageFeatureEnabled && isAccountOwner;
  const isLiteRolePayingSelfServicePackage = isPayingSelfServicePackage && isPaymentRoleLight;
  const hidePricingTooltip = !isPricingEnabled;
  const hidePublishContent = isFreePackage;

  return {
    currentPackage,
    packageGroup: account?.packageGroup,
    isPricingEnabled,
    currentUserPaymentRole,
    isPaymentRoleLight,
    isFreePackage,
    isProPackage,
    isEnterprisePackage,
    isPayingSelfServicePackage,
    isBillingPageEnabled,
    getPackageActionText,
    hasReachedTolstoysLimit,
    hasReachedVideosLimit,
    lockTolstoyCreation: shouldLockTolstoyCreation(),
    hasReachedInAppNotificationsLimit,
    hasReachedLiveWidgetsLimit,
    hasMoreLiveWidgetsThanLimit,
    openStripeBillingPage,
    openBillingPage,
    openShopifyBillingPage,
    downgradeToFreePackage,
    maxNumberOfAllowedTolstoys,
    maxNumberOfAllowedVideos,
    hasReachedAnalyticsLimit,
    hasReachedResponsesLimit,
    sendCreatorProRequestToAdmin,
    sendUpgradePlanRequestToOwner,
    getPackageTrialDays,
    handleTrackOpenSubscriptionPage,
    isShopifySubscription,
    cancelAt,
    failedInvoiceUrl,
    embedSetLiveEnabled,
    isLiteRolePayingSelfServicePackage,
    hidePricingTooltip,
    hidePublishContent,
  };
};
