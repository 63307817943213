import React, { FunctionComponent } from 'react';
import { Input } from '@tolstoy/ui-library/core';
import InputField from './InputField';

interface TextInputProps {
  label: string;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  maxLength?: number;
  disabled?: boolean;
}

const TextInput: FunctionComponent<TextInputProps> = ({
  label,
  placeholder,
  value,
  onChange,
  maxLength,
  disabled,
}) => (
  <InputField label={label}>
    <Input
      placeholder={placeholder}
      value={value}
      onChange={e => {
        const value = e.target.value ?? '';
        onChange(value);
      }}
      maxLength={maxLength}
      disabled={disabled}
    />
  </InputField>
);

export default TextInput;
