import React from 'react';

const ThreadsIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 16.154"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.692 4.231h-3.846v.961h3.846a.99.99 0 0 1 .962.962v5.769a.969.969 0 0 1-.962.962h-1.923v1.502l-2.644-1.502H10a.95.95 0 0 1-.962-.962v-.961h-.961v.961c0 1.082.841 1.923 1.893 1.923h2.915l3.245 1.863c.09.06.15.06.24.06.18 0 .361-.12.361-.36v-1.563h.961c1.052 0 1.923-.841 1.923-1.893V6.154a1.936 1.936 0 0 0-1.923-1.923Zm-4.807 3.846V2.308A1.937 1.937 0 0 0 10.962.385H2.308A1.917 1.917 0 0 0 .385 2.308v5.769C.385 9.159 1.226 10 2.308 10l.961.03v1.532c0 .241.15.361.361.361.06 0 .12 0 .21-.06L7.115 10h3.847a1.917 1.917 0 0 0 1.923-1.923Zm-6.04.961-2.614 1.503V9.038H2.308a.949.949 0 0 1-.962-.961V2.308c0-.511.421-.962.962-.962h8.654a.99.99 0 0 1 .961.962v5.769a.968.968 0 0 1-.961.961H6.845Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default ThreadsIcon;
