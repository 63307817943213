import React from 'react';
import styled from 'styled-components';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

type Props = {
  storiesTitleEnabled: boolean;
  storiesTitleText: string;
  storiesTitleFontSize: number;
  storiesTitleFontWeight: string;
  storiesTitleColor: string;
  alignItems: string;
  size: number;
};

const DesignStoriesPreviewTitle = ({
  storiesTitleEnabled,
  storiesTitleText,
  storiesTitleFontSize,
  storiesTitleFontWeight,
  storiesTitleColor,
  alignItems,
  size,
}: Props) => {
  if (!storiesTitleEnabled) {
    return null;
  }

  return (
    <LayoutRoot
      storiesTitleFontSize={storiesTitleFontSize}
      storiesTitleFontWeight={storiesTitleFontWeight}
      storiesTitleColor={storiesTitleColor}
      alignItems={alignItems}
      size={size}
    >
      {storiesTitleText}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)`
  font-size: ${({ storiesTitleFontSize, size }) => storiesTitleFontSize / size}px;
  font-weight: ${({ storiesTitleFontWeight }) => storiesTitleFontWeight};
  color: ${({ storiesTitleColor }) => storiesTitleColor};
  justify-content: ${({ alignItems }) => alignItems || 'flex-start'};
`;

export default DesignStoriesPreviewTitle;
