import React, { useState } from 'react';
import styled from 'styled-components';
import { TextBody } from 'shared/react/components/complex/Text';
import TolstoyBracesIcon from 'app/src/images/TolstoyBracesIcon';
import { startWidget } from '../../helpers/Widget';
import { track } from '../../helpers/Tracker';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { TABLET_MAX_WIDTH } from 'shared/react/utils/mediaQuery';
import Utils from '../../utils';

const shouldLaunch = false;
const TolstoyWidgetLauncher = () => {
  const isMobile = useMediaQuery(`(${TABLET_MAX_WIDTH})`) || Utils.isMobile();
  const [opened, setOpened] = useState(false);

  const openTolstoyDiscover = () => {
    track(`Discover Click`);
    startWidget();
    setOpened(true);
  };

  window.tolstoyWidget?.on('onWidgetClose', () => setOpened(false));

  if (!shouldLaunch) {
    return null;
  }

  return (
    <Launcher opened={opened} isMobile={isMobile} onClick={openTolstoyDiscover}>
      <Text>
        <ExtensionIconSpan>
          <TolstoyBracesIcon fill="#FA536E" />
        </ExtensionIconSpan>
        Discover
      </Text>
    </Launcher>
  );
};

export default TolstoyWidgetLauncher;

const Launcher = styled.div`
  visibility: ${({ isMobile, opened }) => (isMobile || opened ? 'hidden' : '')};
  position: fixed;
  display: flex;
  background: ${({ theme }) => theme.colors.white};
  top: 47%;
  right: -45px;
  transform: rotate(-90deg);
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 8px 8px 0 0;
  box-shadow: -5px 0px 15px 1px #dadce0;
  z-index: 3;
  cursor: pointer;
`;

const Text = styled(TextBody)`
  font-weight: normal;
  color: ${({ theme }) => theme.colors.blue2};
`;

const ExtensionIconSpan = styled.span`
  margin-right: 8px;
  vertical-align: middle;
`;
