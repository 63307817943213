import React from 'react';
import styled from 'styled-components';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import { TextSmall } from 'shared/react/components/complex/Text';
import ButtonGroup from 'app/src/complex_components/button-group/ButtonGroup';
import { STOP_SHOWING_WIDGET_TO } from 'app/src/pages/project/pages/rules_and_triggers/Rule.constants';
import { useRulesContext } from 'app/src/pages/project/pages/rules_and_triggers/RulesContext';
import NumberInput from 'app/src/pages/project/pages/rules_and_triggers/editor/event_triggers/NumberInput';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';

const Restrictions = ({ restrictionRule }) => {
  const { setRuleGroup, ruleGroup, setUnsaved } = useRulesContext();
  if (!restrictionRule) {
    return null;
  }
  const isClickedXSelected = restrictionRule.type === STOP_SHOWING_WIDGET_TO.clickedX;

  const changeRuleGroupValue = (type, value) => {
    restrictionRule[type] = value;
    setRuleGroup({ ...ruleGroup });
    setUnsaved(true);
  };

  const buttons = [
    {
      text: 'Clicked X',
      isEnabled: isClickedXSelected,
      onClick: () => changeRuleGroupValue('type', STOP_SHOWING_WIDGET_TO.clickedX),
    },
    {
      text: 'Viewed the Tolstoy',
      isEnabled: restrictionRule.type === STOP_SHOWING_WIDGET_TO.viewed,
      onClick: () => changeRuleGroupValue('type', STOP_SHOWING_WIDGET_TO.viewed),
    },
  ];

  return (
    <LayoutRoot>
      <TypeContainer>
        <Text>Stop showing widget to viewers who</Text>
        <ButtonGroup buttons={buttons} />
      </TypeContainer>
      <ValuesContainer>
        {!isClickedXSelected && (
          <InputContainer>
            <NumberInput
              type="number"
              min="1"
              max="10"
              step="1"
              onChange={e => changeRuleGroupValue('value', e.target.value)}
              value={restrictionRule.value}
            />
            <Text>times</Text>
          </InputContainer>
        )}
        <InputContainer>
          <Text>For</Text>
          <NumberInput
            type="number"
            min="1"
            max="48"
            step="1"
            onChange={e => changeRuleGroupValue('limit', e.target.value)}
            value={restrictionRule.limit}
          />
          <Text>hours</Text>
        </InputContainer>
      </ValuesContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)``;

const TypeContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

const Text = styled(TextSmall)``;

const ValuesContainer = styled(HorizontalFlexWrap)`
  gap: 24px;
`;

const InputContainer = styled(Gap8HorizontalFlexWrap)`
  align-items: center;
`;

export default Restrictions;
