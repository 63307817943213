import React from 'react';
import styled from 'styled-components';
import { TABS } from 'app/src/constants/editStep.constants';
import { getIsFeed } from 'app/src/context/ProjectsStore';
import ReviewCustomPart from './ReviewCustomPart';
import ReviewFeedPart from './ReviewFeedPart';
import ReviewShoppablePart from './ReviewShoppablePart';

const ReviewPart = props => {
  const { project, step } = props;

  const getPart = () => {
    if (getIsFeed(project)) {
      return <ReviewFeedPart {...props} />;
    }

    if (step.type === TABS.products) {
      return <ReviewShoppablePart {...props} />;
    }

    return <ReviewCustomPart step={step} />;
  };

  return <LayoutRoot>{getPart()}</LayoutRoot>;
};
export default ReviewPart;

const LayoutRoot = styled.div`
  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    max-width: 280px;
  } ;
`;
