import styled from 'styled-components';

const Text = styled.div`
  background-color: ${props => props.backgroundColor || 'transparent'};
  color: ${props => props.textColor || props.theme.colors.black};
  font-size: ${props => props.fontSize || '12px'};
  letter-spacing: ${props => props.letterSpacing || 'normal'};
  font-weight: ${props => props.fontWeight || 'normal'};
  line-height: ${props => props.lineHeight || 'normal'};
  width: ${props => props.width || 'fit-content'};

  &:hover {
    color: ${props => props.hoverTextColor || props.textColor};
  }

  &:focus,
  &:active {
    color: ${props => props.activeTextColor || props.textColor};
  }
`;

export default Text;
