import React from 'react';

function MyWorkspaceIcon({ fill = '#3D4FE0' }) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 5h-2V4c0-1.094-.906-2-2-2H8c-1.125 0-2 .906-2 2v1H4c-1.125 0-2 .906-2 2v8c0 1.125.875 2 2 2h12c1.094 0 2-.875 2-2V7c0-1.094-.906-2-2-2ZM8 3.5h4c.25 0 .5.25.5.5v1h-5V4c0-.25.219-.5.5-.5Zm-4 3h12c.25 0 .5.25.5.5v3h-13V7c0-.25.219-.5.5-.5Zm12 9H4a.494.494 0 0 1-.5-.5v-3.5H8v.5c0 .563.438 1 1 1h2c.531 0 1-.438 1-1v-.5h4.5V15c0 .281-.25.5-.5.5Z"
        fill={fill}
      />
    </svg>
  );
}

export default MyWorkspaceIcon;
