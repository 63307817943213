import React from 'react';
import MenuWithItems, { MenuItemType } from 'app/src/complex_components/menu_items/MenuWithItems';
import styled from 'styled-components';
import FontMenuToggle from 'app/src/pages/account/branding/fonts/FontMenuToggle';
import EditorItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorItem';
import { FONT_WEIGHTS } from 'app/src/helpers/Font';
import {
  TOLSTOY_CUSTOM_HEADING_FONT_FAMILY,
  TOLSTOY_CUSTOM_FONT_FAMILY,
} from 'shared/react/constants/playerSettings.constants';

const EditorFontFamilyAndWeight = ({ fonts, onChange, value, fontWeight }) => {
  const [anchorRef, setAnchorRef] = React.useState();

  const { font, fontHeading } = fonts;
  const fontItems = [] as MenuItemType[];

  const getTitle = () => {
    if (value === TOLSTOY_CUSTOM_FONT_FAMILY) {
      return font.family;
    }

    if (value === TOLSTOY_CUSTOM_HEADING_FONT_FAMILY) {
      return fontHeading.family;
    }

    return `System default `;
  };

  if (font.family) {
    fontItems.push({ text: <TitleText>{font.family}</TitleText>, key: font.family });
  }

  const fontWeights = Object.keys(font.sources);

  fontWeights.forEach(weight => {
    fontItems.push({
      text: (
        <SubText>
          {FONT_WEIGHTS[weight]} ({weight})
        </SubText>
      ),
      onClick: () => onChange(TOLSTOY_CUSTOM_FONT_FAMILY, weight),
      isSelected: value === TOLSTOY_CUSTOM_FONT_FAMILY && fontWeight === weight,
      key: `${font.family}-${weight}`,
    } as MenuItemType);
  });

  if (fontHeading.family) {
    fontItems.push({
      text: <TitleText>{fontHeading.family}</TitleText>,
      key: fontHeading.family,
    } as MenuItemType);
  }

  const fontHeadingWeights = Object.keys(fontHeading.sources);

  fontHeadingWeights.forEach(weight => {
    fontItems.push({
      text: (
        <SubText>
          {FONT_WEIGHTS[weight]} ({weight})
        </SubText>
      ),
      key: `${font.family}-${weight}`,
      onClick: () => onChange(TOLSTOY_CUSTOM_HEADING_FONT_FAMILY, weight),
      isSelected: value === TOLSTOY_CUSTOM_HEADING_FONT_FAMILY && fontWeight == weight,
    } as MenuItemType);
  });

  if (!fontItems.length) {
    for (const [key, value] of Object.entries(FONT_WEIGHTS)) {
      fontItems.push({
        text: <SubText>System default - {key}</SubText>,
        key: `${key}-${value}`,
        onClick: () => onChange(TOLSTOY_CUSTOM_FONT_FAMILY, key),
        isSelected: fontWeight == key,
      } as MenuItemType);
    }
  }

  return (
    <EditorItem text="Font">
      <FontMenuToggle
        fontWeight={fontWeight}
        onClick={e => setAnchorRef(e.target)}
        value={getTitle()}
      />
      <MenuWithItems
        anchorElement={anchorRef}
        onClose={() => setAnchorRef(null)}
        menuItems={fontItems}
        direction={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </EditorItem>
  );
};

const TitleText = styled.span`
  font-weight: 700;
`;

const SubText = styled.span`
  margin-left: 4px;
`;

export default EditorFontFamilyAndWeight;
