import React, { useState, useEffect } from 'react';
import {
  Button,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Switch,
} from '@tolstoy/ui-library/core';
import { useApps } from 'app/src/context/AppsStore';
import { useDrawerActions } from 'app/src/context/ui_store/DrawerStore';
import { DEFAULT_INSTAGRAM_IMPORT_RANGE } from 'app/src/constants/instagram.constants';
import { useInstagramActions } from 'app/src/context/InstagramStore';
import { startInstagramImport } from 'app/src/services/instagram/InstagramApiService';

const PERIOD_OPTIONS = [
  { value: 180, label: '6 months' },
  { value: 270, label: '9 months' },
  { value: 365, label: '1 year' },
  { value: 730, label: '2 years' },
  { value: 0, label: 'All time' },
];

const ImportRangeSelector = ({ importRange, setImportRange }) => (
  <div className="relative mt-4">
    <label className="mb-2 block text-sm">Import older Instagram content going back</label>
    <Select value={importRange} onValueChange={setImportRange}>
      <SelectTrigger className="w-full rounded-md border border-gray-300 px-3 py-2 text-left focus:border-gray-300 focus:outline-none focus:ring-0">
        <SelectValue placeholder="Select a period" />
      </SelectTrigger>
      <SelectContent className="absolute z-[1001] max-h-60 overflow-auto rounded-md border border-gray-200 bg-white shadow-lg">
        {PERIOD_OPTIONS.map(option => (
          <SelectItem
            key={option.value}
            value={option.value}
            className="p-2 pl-8 hover:bg-gray-100"
          >
            {option.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  </div>
);

const InstagramSettingsDrawer = () => {
  const [{ instagramApps }, { updateApp }] = useApps();
  const { setCurrentDrawer } = useDrawerActions();
  const { getProductsForOldVideos } = useInstagramActions();
  const instagramApp = instagramApps[0];

  const [autotagFuture, setAutotagFuture] = useState(false);
  const [autotagExisting, setAutotagExisting] = useState(false);
  const [importOlder, setImportOlder] = useState(false);
  const [importRange, setImportRange] = useState(PERIOD_OPTIONS[0].value);
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseDrawerClick = () => {
    setCurrentDrawer({ currentDrawer: null });
  };

  useEffect(() => {
    if (instagramApp?.settings) {
      setAutotagFuture(instagramApp.settings.shouldTagProducts || false);
      setAutotagExisting(instagramApp.settings.searchForOldVideos || false);
      setImportOlder(instagramApp.settings.importOlderContent || false);
      setImportRange(
        instagramApp.importRange >= 0 ? instagramApp.importRange : PERIOD_OPTIONS[0].value
      );
    }
  }, [instagramApp]);

  const handleSave = async () => {
    setIsLoading(true);
    const settings = JSON.stringify({
      ...instagramApp.settings,
      shouldTagProducts: autotagFuture,
      searchForOldVideos: autotagExisting,
      importOlderContent: importOlder,
    });

    try {
      let data = instagramApp.data;
      if (importOlder && importRange > instagramApp.importRange) {
        data = { ...data, lastSuccessfulImport: null };
      }

      const innerImportRange = importOlder ? importRange : DEFAULT_INSTAGRAM_IMPORT_RANGE;

      await updateApp(instagramApp.id, {
        importRange: innerImportRange,
        settings,
        data: JSON.stringify(data),
      });
      await startInstagramImport(instagramApp.id);
      if (autotagFuture && autotagExisting) {
        await getProductsForOldVideos();
      }
    } catch (error) {
      console.error('Error saving settings:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="relative w-[600px] p-6">
      <h2 className="mb-6 text-2xl font-bold">Instagram settings</h2>

      <div className="mb-8 space-y-6">
        <h3 className="text-lg font-semibold">Autotag</h3>
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <label className="mr-4 flex-grow text-sm">
              Apply Instagram Shopping product tags to future videos added to your Tolstoy library
            </label>
            <Switch
              checked={autotagFuture}
              onCheckedChange={setAutotagFuture}
              className="data-[state=checked]:bg-[#3D4FE0]"
            />
          </div>
          <div className="flex items-center justify-between">
            <label className="mr-4 flex-grow text-sm">
              Apply Instagram Shopping product tags to existing videos in your Tolstoy library
            </label>
            <Switch
              checked={autotagExisting}
              onCheckedChange={setAutotagExisting}
              disabled={!autotagFuture}
              className="data-[state=checked]:bg-[#3D4FE0]"
            />
          </div>
        </div>
      </div>

      <div className="mb-8 space-y-6">
        <h3 className="text-lg font-semibold">Imported content</h3>
        <p className="text-sm">
          Tolstoy automatically imports all content from 90 days before you connected. All previous
          content can be loaded and reviewed from the &quot;Instagram&quot; tab and will be imported
          once tagged with a product or playlist.
        </p>

        <div className="flex items-center justify-between">
          <label className="text-sm">Import older content</label>
          <Switch
            checked={importOlder}
            onCheckedChange={setImportOlder}
            className="data-[state=checked]:bg-[#3D4FE0]"
          />
        </div>
        {importOlder && (
          <ImportRangeSelector importRange={importRange} setImportRange={setImportRange} />
        )}
      </div>

      <div className="flex justify-end space-x-4">
        <Button
          className="rounded border border-[#3D4FE0] bg-white px-4 py-2 text-[#3D4FE0] transition-colors duration-200 hover:border-[#2A37B1] hover:bg-[#F4F5F7] hover:text-[#2A37B1]"
          onClick={handleCloseDrawerClick}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          disabled={isLoading}
          className="rounded bg-[#3D4FE0] px-4 py-2 text-white hover:bg-[#3D4FE0]/90"
        >
          {isLoading ? 'Saving...' : 'Save'}
        </Button>
      </div>
    </div>
  );
};

export default InstagramSettingsDrawer;
