import React, { useState } from 'react';
import styled from 'styled-components';
import { track } from 'app/src/helpers/Tracker';

import { VIDEO_CARD_DATA_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import { SYNTHESIA } from 'app/src/constants/intergrations.constants';
import { useAppActions, useSelectedApp } from 'app/src/context/AppsStore';
import { assetType as AssetType } from 'src/types/entities';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import ThreeVerticalDotsIcon from 'app/src/images/dashboard_v2/ThreeVerticalDotsIcon';
import {
  CardContainer,
  CardImageWrapper,
  ContextMenuIcon,
} from 'app/src/pages/dashboard/components/card/Card';
import CardControls from 'app/src/pages/dashboard/components/card/CardControls';
import CardImage from 'app/src/pages/dashboard/components/card/CardImage';
import ImageIcon from 'src/complex_components/rich-text-editor/icons/ImageIcon';
import VideoCardTime from 'app/src/pages/dashboard/pages/videos/components/video_card/VideoCardTime';
import VideoCardDetails from 'app/src/pages/dashboard/pages/videos/components/video_card/components/video-card-details/VideoCardDetails';
import SoundIcon from 'app/src/pages/dashboard/pages/videos/components/video_card/SoundIcon';
import { FEATURE_DYNAMIC_FEED } from 'shared/react/constants/features.constants';
import { VIDEO_STATUS } from 'shared/react/constants/video.constants';
import { AssetRenditionsService } from 'shared/react/services/assets';
import VideoCardMenuItems from './VideoCardMenuItems';
import Utils from 'app/src/utils';
import { useVideoSelectorForModal } from 'app/src/pages/dashboard/pages/videos/components/video_card/video-switcher/useVideoSelectorForModal';

const VideoCard = ({
  video,
  toggleSelected,
  isSelected,
  isSelectionMode,
  onSelectedVideosAction,
  method,
  onVideoChoose,
  isDisableCardHoverState,
  analyticsData,
  isTagProductsTooltipShown,
  isTagLabelsTooltipShown,
}) => {
  const { getFeatureEnabled } = useFeatureActions();
  const { getProductsCatalogApp } = useAppActions();
  const { openVideoInModal } = useVideoSelectorForModal({ analyticsData });
  const [isMenuOpen, setMenuOpen] = useState(null);
  const isDynamicEnabled = getFeatureEnabled(FEATURE_DYNAMIC_FEED);
  const productsCatalogApp = getProductsCatalogApp();
  const isProductsTaggingEnabled = isDynamicEnabled && productsCatalogApp;
  const posterUrl = AssetRenditionsService.getAssetPosterLarge(video);
  const isImage = video?.type === AssetType.image;

  const openMenu = e => {
    e.stopPropagation();
    track('Video Card Menu Click', analyticsData);
    setMenuOpen(e.currentTarget);
  };

  const handleVideoClick = event => {
    if (event.target.tagName === 'A' && event.target.classList.contains('handleLink')) {
      // do not open modal if there was a click on a handle link
      return;
    }

    if (isSelectionMode) {
      toggleSelected(video.id);
      return;
    }

    onSelectedVideosAction?.([video]);

    if (onVideoChoose) {
      onVideoChoose(video);
      return;
    }

    openVideoInModal(video);
  };

  const shouldShowHoverState = !isDisableCardHoverState && !onVideoChoose;
  const shouldShowCardBorder = !!onVideoChoose;
  const shouldCover = method === SYNTHESIA && !!video?.app;
  const isImageAssetLoading = isImage && video.status && video.status !== VIDEO_STATUS.done;
  const isVideoAssetLoading =
    !video?.stockAsset?.posterUrl && video.status === VIDEO_STATUS.uploading;
  const isCardImageLoading = isVideoAssetLoading || isImageAssetLoading;

  const [selectedApp] = useSelectedApp();

  const settings = Utils.safeParse(selectedApp?.settings);
  const isFeedMuted = settings?.design?.player?.controls?.isFeedMuted ?? false;

  return (
    <CardContainer data-test-id={VIDEO_CARD_DATA_TEST_ID} onClick={handleVideoClick}>
      <CardImageWrapper>
        {isImage && (
          <ImageIconContainer>
            <ImageIcon />
          </ImageIconContainer>
        )}
        <CardImage shouldCover={shouldCover} imageSrc={posterUrl} isLoading={isCardImageLoading} />
        <IconContainer>
          <SoundIcon isFeedMuted={isFeedMuted} isSoundAllowed={video.isSoundAllowed}></SoundIcon>
        </IconContainer>
        {shouldShowHoverState && (
          <CardControls
            isSelected={isSelected}
            toggleSelected={() => toggleSelected(video.id)}
            isSelectionMode={isSelectionMode}
          >
            <ContextMenuIcon onClick={openMenu}>
              <ThreeVerticalDotsIcon />
            </ContextMenuIcon>
            <VideoCardMenuItems
              method={method}
              video={video}
              isMenuOpen={isMenuOpen}
              setMenuOpen={setMenuOpen}
              onSelectedVideosAction={onSelectedVideosAction}
            />
          </CardControls>
        )}
        {!isImage && <VideoCardTime duration={video?.duration} />}
      </CardImageWrapper>

      <StyledVideoCardDetails
        isProductsTaggingEnabled={isProductsTaggingEnabled}
        shouldShowBorder={shouldShowCardBorder}
        video={video}
        onSelectedVideosAction={onSelectedVideosAction}
        analyticsData={analyticsData}
        isTagProductsTooltipShown={isTagProductsTooltipShown}
        isTagLabelsTooltipShown={isTagLabelsTooltipShown}
      />
    </CardContainer>
  );
};

const StyledVideoCardDetails = styled(VideoCardDetails)`
  border-width: ${({ shouldShowBorder }) => (shouldShowBorder ? '0px 1px 1px 1px' : '0')};
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.neutralLighter};
`;

const IconContainer = styled.div`
  position: absolute;
  bottom: 4px;
  left: 12px; // 8px of padding + some space to make it visually aligned with select checkbox
`;

const ImageIconContainer = styled.div`
  position: absolute;
  left: 4px;
  z-index: 1;
`;

export default VideoCard;
