import { DELAY_PAGE_VIEW, GCLID_KEY } from 'player/src/constants/tolstoy.constants';

export const getUrlParams = () => {
  const params = new URLSearchParams(window.location.search);
  const passedParams = {};

  const isIframe = window.self !== window.top;
  if (params.has('url') || isIframe) {
    passedParams.playerType = 'embed';
  }

  if (params.has('playerType')) {
    passedParams.playerType = params.get('playerType');
  }

  if (params.has('dontDownload')) {
    passedParams.dontDownload = params.get('dontDownload') === 'true';
  }

  if (params.has('token')) {
    passedParams.token = params.get('token');
  }

  if (params.has(GCLID_KEY)) {
    passedParams.gclid = params.get(GCLID_KEY);
  }

  if (params.has(DELAY_PAGE_VIEW)) {
    passedParams.delayPageView = true;
  }

  return passedParams;
};

export const constructEventsData = config => {
  const eventsData = {
    accountId: config.userId,
    appKey: config.appKey,
    playlist: config.name,
    publishId: config.publishId,
    projectId: config.id,
  };

  if (config.googleAnalyticsID) {
    eventsData.googleAnalyticsID = config.googleAnalyticsID;
  }

  if (config.facebookAnalyticsID) {
    eventsData.facebookAnalyticsID = config.facebookAnalyticsID;
  }

  return eventsData;
};

export const getCustomParams = () => {
  const params = new URLSearchParams(window.location.search);
  const customParams = {};
  const keys = [...params.keys()].filter(key => {
    const lowerCaseKey = key.toLowerCase();
    return lowerCaseKey.startsWith('custom_') || lowerCaseKey.startsWith('utm_');
  });

  keys.forEach(key => {
    const value = params.get(key);
    if (key.length < 50 && value.length < 100) {
      customParams[key] = value;
    }
  });

  return customParams;
};
