import SearchIcon from 'app/src/images/Search';
import styled from 'styled-components';
import React, { useState } from 'react';
import SearchBarXIcon from 'app/src/images/SearchBarXIcon';
import { shadeColor } from 'shared/react/utils/color';
import { track } from 'app/src/helpers/Tracker';

const SearchBarV2 = ({ onChange, value, placeholder, clearValue, ...props }) => {
  const [isFocused, setFocused] = useState(false);

  const onClose = e => {
    e.stopPropagation();
    track('Search Cleared');
    setFocused(false);
    clearValue();
  };

  const onOpen = () => {
    track('Search Click');
    setFocused(true);
  };

  return (
    <LayoutRoot onClick={onOpen} {...props}>
      <IconsContainer>
        <SearchIconContainer>
          <SearchIcon />
        </SearchIconContainer>
        {!!value && (
          <CloseContainer onClick={onClose}>
            <SearchBarXIcon />
          </CloseContainer>
        )}
      </IconsContainer>
      <ConversationsSearchInput
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        isFocused={isFocused}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  display: grid;
  width: 100%;
  position: relative;
  height: 40px;
`;

const ConversationsSearchInput = styled.input`
  background-color: ${({ theme, value }) => (value ? theme.colors.white : theme.colors.gray5)};
  border: 1px solid ${({ theme }) => theme.colors.ghostLight};
  border-radius: 10px;
  padding: 0 36px;
  grid-row: 1;
  grid-column: 1;
  transition: 0.3s;
  width: 240px;

  &:hover {
    background-color: ${({ theme }) => shadeColor(theme.colors.gray5, -1)};
  }

  &:focus {
    background-color: ${({ theme }) => theme.colors.white};
    outline: none;
  }
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 100%;
  }
`;

const IconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 14px;
  grid-row: 1;
  grid-column: 1;
`;

const SearchIconContainer = styled.div`
  z-index: 1;
  line-height: 0;
`;

const CloseContainer = styled.div`
  cursor: pointer;
  font-size: 12px;
  z-index: 1;
  color: ${({ theme }) => theme.colors.ghostDark};
  line-height: 0;
`;

export default SearchBarV2;
