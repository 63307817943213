import React from 'react';
import styled from 'styled-components';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

const ImagePreviewContainer = ({ src }) => {
  return (
    <ImageContainer>
      <Image src={src} />
    </ImageContainer>
  );
};

const ImageContainer = styled(HorizontalFlexCentered)`
  background: ${({ theme }) => theme.colors.gray35};
  border-radius: 10px;
`;

const Image = styled.img`
  min-width: 150px;
  min-height: 320px;
  box-shadow: 0px 10px 25px 0px #32325d1a;
  border-radius: 12px;
  width: 150px;
  height: 320px;
`;

export default ImagePreviewContainer;
