export const VIDEO_RENAME_MODAL_KEY = 'videoRenameModal';
export const VIDEO_DELETE_MODAL_KEY = 'videoDeleteModal';
export const VIDEO_ALLOW_SOUND_MODAL_KEY = 'videoAllowSoundModal';
export const VIDEO_DISALLOWED_SOUND_MODAL_KEY = 'videoDisallowedSoundModal';
export const VIDEO_PREVIEW_MODAL_KEY = 'videoPreviewModal';
export const VIDEO_ADD_CREATOR_PROFILE_MODAL_KEY = 'videoAddCreatorProfileModal';
export const TOLSTOY_RENAME_MODAL_KEY = 'tolstoyRenameModal';
export const TOLSTOY_COPY_TO_WORKSPACE_MODAL_KEY = 'tolstoyCopyToWorkspaceModal';
export const DELETE_TOLSTOY_MODAL_KEY = 'deleteTolstoyModal';
export const SHARE_MODAL_KEY = 'shareModal';
export const PUBLISH_MODAL_KEY = 'publishModal';
export const TRIAL_MODAL_KEY = 'trialModal';
export const INSTALLATION_MODAL_KEY = 'installationModal';
export const RENAME_FOLDER_MODAL_KEY = 'renameFolderModal';
export const DELETE_FOLDER_MODAL_KEY = 'deleteFolderModal';
export const MOVE_TOLSTOY_MODAL_KEY = 'moveTolstoyModal';
export const SET_THUMBNAIL_MODAL_KEY = 'SET_THUMBNAIL_MODAL_KEY';
export const CAPTIONS_EDITOR_MODAL_KEY = 'captionsEditorModal';
export const SHOPIFY_ALREADY_INSTALLED_MODAL_KEY = 'shopifyAlreadyInstalledModal';
export const HIJACK_SHOPIFY_STORE_MODAL_KEY = 'hijackShopifyStoreModal';
export const SHOPIFY_INSTALLATION_FAILED_MODAL_KEY = 'shopifyStoreInstallationFailedModal';
export const CREATION_FLOW_MODAL_KEY = 'creationFlowModalKey';
export const DELETE_STEP_MODAL_KEY = 'deleteStepModalKey';
export const EDIT_STEP_MODAL_KEY = 'editStepModalKey';
export const REMOVE_ADDON_MODAL_KEY = 'removeAddonModalKey';
export const INVITE_REFERRAL_MODAL_KEY = 'inviteReferralModalKey';
export const REQUEST_OWNER_TO_UPGRADE_PLAN_KEY = 'RequestOwnerToUpgradePlanModalKey';
export const TRANSFER_OWNERSHIP_MODAL_KEY = 'transferOwnershipModalKey';
export const TEAM_MEMBER_INVITE_MODAL_KEY = 'teamMemberInviteModalKey';
export const EXPORT_TO_STORE_MODAL_KEY = 'exportToStoreModalKey';
export const CONNECT_TO_MAGENTO_MODAL_KEY = 'connectToMagentoModalKey';
export const HUBSPOT_MAPPING_MODAL_KEY = 'hubspotMappingModalKey';
export const UPLOAD_FILES_MODAL_KEY = 'uploadFilesModalKey';
export const CREATE_TOLSTOY_MODAL_KEY = 'createTolstoyModalKey';
export const IMPORT_VIDEOS_TO_EXISTING_TOLSTOY_MODAL_KEY = 'importVideosToExistingTolstoyKey';
export const ADD_PRODUCTS_MODAL_KEY = 'addProductsModalKey';
export const REINSTALL_SHOPIFY_MODAL_KEY = 'reinstallShopifyModalKey';
export const UNSAVED_CHANGES_MODAL_KEY = 'unsavedChangesModalKey';
export const SELECT_PUBLISH_METHOD_MODAL_KEY = 'selectPublishMethodModalKey';
export const RESET_PROJECTS_GOOGLE_ANALYTICS_MODAL_KEY = 'resetProjectsGoogleAnalyticsModalKey';
export const ADD_PRODUCT_VIDEOS_MODAL_KEY = 'addProductVideosModalKey';
export const ADD_EDIT_PLAYLIST_MODAL_KEY = 'addEditPlaylistModalKey';
export const CONFIRM_MODAL_KEY = 'confirmModalKey';
export const INSTAGRAM_GRAPH_KEY = 'instagramGraphKey';
export const LIBRARY_MODAL_KEY = 'libraryModalKey';
export const UGC_REQUEST_MODAL_KEY = 'UGCRequestModalKey';
export const GALLERY_MODAL_KEY = 'galleryModalKey';
export const IMAGE_LIBRARY_MODAL_KEY = 'imageLibraryModalKey';
export const INSTAGRAM_TAG_PRODUCTS_MODAL_KEY = 'instagramTagProductsModalKey';
export const SELECT_PRODUCT_MODAL_KEY = 'selectProductModalKey';
export const INSTAGRAM_CONNECT_MODAL_KEY = 'instagramConnectModalKey';
