import React from 'react';
import TopBar from 'src/pages/dashboard/components/top-bar/TopBar';
import { TextH3Bold } from 'shared/react/components/basic/text/TextV2';
import { PrimaryButton } from 'shared/react/components/basic/button-v2/BasicButton';
import StraightArrowRightIcon from 'src/images/creation_method_icons/StraightArrowRightIcon';
import PaymentRequiredModal from 'src/pages/project/components/project-top-bar/components/set-live-button/PaymentRequiredModal';

type Props = {
  isUpgradeTooltipOpen: boolean;
  onGrantPermissions: () => void;
};

export const UgcPermissionsTopBar = ({ isUpgradeTooltipOpen, onGrantPermissions }: Props) => {
  return (
    <TopBar
      leftContent={<TextH3Bold>Source your brand videos from any Instagram account</TextH3Bold>}
      rightContent={
        <>
          {isUpgradeTooltipOpen && (
            <PaymentRequiredModal
              headerText="Upgrade to a paid plan to access your UGC."
              subHeaderText="You can cancel at any point during your free trial period, without any charges."
              onCancel={onGrantPermissions}
            />
          )}
          <PrimaryButton onClick={onGrantPermissions}>
            Grant Instagram permissions <StraightArrowRightIcon />
          </PrimaryButton>
        </>
      }
    />
  );
};

export default UgcPermissionsTopBar;
