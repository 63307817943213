import React from 'react';

const EmptyShoppingBag = ({ width = 24, height = 24, fill = '#090A0B' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.75 8.875h-2.531V7.469A4.231 4.231 0 0 0 12 3.25a4.209 4.209 0 0 0-4.219 4.219v1.406H5.25c-.633 0-1.125.527-1.125 1.125v8.438a2.777 2.777 0 0 0 2.813 2.812h10.125a2.798 2.798 0 0 0 2.812-2.813V10c0-.598-.527-1.125-1.125-1.125ZM9.469 7.469A2.543 2.543 0 0 1 12 4.937c1.371 0 2.531 1.16 2.531 2.532v1.406H9.47V7.469Zm8.719 10.968c0 .633-.528 1.125-1.125 1.125H6.938a1.11 1.11 0 0 1-1.125-1.125v-7.875H7.78v1.97c0 .491.352.843.844.843a.833.833 0 0 0 .844-.844v-1.969h5.062v1.97c0 .491.352.843.844.843a.833.833 0 0 0 .844-.844v-1.969h1.968v7.876Z"
        fill={fill}
      />
    </svg>
  );
};

export default EmptyShoppingBag;
