import React from 'react';
import { TextSmallBold } from 'shared/react/components/basic/text/TextV2';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import styled from 'styled-components';

type Props = {
  text: string;
  isSelected: boolean;
  Icon: React.FC;
  onClick: () => void;
};

const AnalyticsToggleFilter = ({ text, isSelected, Icon, onClick }: Props) => {
  return (
    <LayoutRoot isSelected={isSelected} onClick={onClick}>
      <Icon />
      <TextSmallBold>{text}</TextSmallBold>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlex)<{ isSelected: boolean }>`
  background: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.primaryLight : theme.colors.white};
  cursor: pointer;
  border-radius: 12px;
  padding: 10px;
  align-items: center;

  & svg {
    & path {
      fill: ${({ theme, isSelected }) => (isSelected ? theme.colors.primary : '')};
    }
    height: 20px;
    width: 20px;
  }

  & > * {
    color: ${({ theme, isSelected }) => (isSelected ? theme.colors.primary : '')};
  }
`;

export default AnalyticsToggleFilter;
