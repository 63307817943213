import React from 'react';
import styled from 'styled-components';
import HubspotHeaders from 'app/src/pages/modals/hubspot_mapping/hubspot_table/HubspotHeaders';
import HubspotFields from 'app/src/pages/modals/hubspot_mapping/hubspot_table/HubspotFields';
import { useHubspot } from 'app/src/context/integrations/HubspotStore';
import TolstoyLoader from 'app/src/basic_components/TolstoyLoader';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

const HubspotMappingTable = ({
  addingField,
  onAddField,
  fields,
  onRemoveField,
  onToggleDefault,
}) => {
  const [{ properties }] = useHubspot();

  if (!Object.keys(properties).length) {
    return (
      <Center>
        <TolstoyLoader />
      </Center>
    );
  }

  return (
    <LayoutRoot>
      <HubspotHeaders />
      <HubspotFields
        fields={fields}
        onToggleDefault={onToggleDefault}
        onRemoveField={onRemoveField}
        onAddField={onAddField}
        addingField={addingField}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  overflow: auto;
  max-height: 450px;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    max-height: 300px;
  } ;
`;

const Center = styled(HorizontalFlexCentered)``;

export default HubspotMappingTable;
