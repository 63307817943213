import React from 'react';
import EditorGroup from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorGroup';
import {
  PLAYER_SETTINGS_KEY,
  PLAYER_CONTROLS_KEY,
  PLAYER_FEED_CARDS_KEY,
} from '../../branding.constants';
import SettingsEditor from './settings/SettingsEditor';
import FeedCardsEditor from './feedCards/FeedCardsEditor';
import ControlsEditor from './controls/ControlsEditor';

const PlayerEditorGroup = ({
  settings = {},
  features = {},
  defaultSettings = {},
  onChange = () => {},
}) => {
  const updateSettings = (update, options) => onChange({ ...settings, ...update }, options);
  const onPlayerSettingsChange = (settings, options) => updateSettings({ settings }, options);
  const onControlsChange = (settings, options) => updateSettings({ controls: settings }, options);
  const onFeedCardsChange = (settings, options) => updateSettings({ feedCards: settings }, options);

  return (
    <EditorGroup
      editors={[
        {
          key: PLAYER_SETTINGS_KEY,
          component: (
            <SettingsEditor
              settings={settings.settings}
              features={features}
              defaultSettings={defaultSettings.settings}
              onChange={onPlayerSettingsChange}
            />
          ),
        },
        {
          key: PLAYER_CONTROLS_KEY,
          component: (
            <ControlsEditor
              settings={settings.controls}
              features={features}
              onChange={onControlsChange}
            />
          ),
        },
        {
          key: PLAYER_FEED_CARDS_KEY,
          component: (
            <FeedCardsEditor
              settings={settings.feedCards}
              features={features}
              defaultSettings={defaultSettings.feedCards}
              onChange={onFeedCardsChange}
            />
          ),
        },

      ]}
    />
  );
};

export default PlayerEditorGroup;
