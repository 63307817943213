import React from 'react';
import ChartTooltip from 'app/src/complex_components/analytics/chart-tooltip/ChartTooltip';
import Utils from 'app/src/utils';
import { ANALYTICS_OVERVIEW_STATS } from 'app/src/constants/analytics.constants';
import { getShortMonthAndDate } from 'app/src/utils/date.utils';
import { TooltipPayload } from 'app/src/complex_components/analytics/chart-tooltip/ChartTooltip.types';
import { useTheme } from 'styled-components';

type Props = {
  active: boolean;
  payload: [TooltipPayload<number>, TooltipPayload<number>];
  label: string;
};

const AnalyticsOverviewConversionRateTooltip = ({ active, payload, label }: Props) => {
  const theme = useTheme();
  if (!active || !payload?.length) {
    return null;
  }

  const [conversionRateData] = payload;
  const { payload: conversionRate } = conversionRateData;

  const lineGroups = [
    {
      key: ANALYTICS_OVERVIEW_STATS.TOLSTOY_CONVERSION_RATE_USERS,
      header: ANALYTICS_OVERVIEW_STATS.TOLSTOY_CONVERSION_RATE_USERS,
      bulletColor: theme.colors.successDark,
      value: Utils.formatNumber({
        value: conversionRate.tolstoyConversionRate,
        isPercentage: true,
      }),
    },
    // todo: uncomment when we have enough site activity data to show more accurate conversion rate
    // {
    //   key: ANALYTICS_OVERVIEW_STATS.BASELINE_TOLSTOY_CONVERSION_RATE_USERS,
    //   header: ANALYTICS_OVERVIEW_STATS.BASELINE_TOLSTOY_CONVERSION_RATE_USERS,
    //   bulletColor: theme.colors.neutralLight,
    //   value: Utils.formatNumber({
    //     value: conversionRate.baselineConversionRate,
    //     isPercentage: true,
    //   }),
    // },
  ];

  return <ChartTooltip label={getShortMonthAndDate(label)} lineGroups={lineGroups} />;
};

export default AnalyticsOverviewConversionRateTooltip;
