import styled, { useTheme } from 'styled-components';
import React, { useState, useEffect } from 'react';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';

const TransitionAttributes =
  'background-color, border-bottom-color, border-top-color, border-left-color, border-right-color';

function LeadFormPreview() {
  const theme = useTheme();
  const {
    customizationSettings: { collectText: formText, collectFields: inputFields },
  } = useLookAndFeelContext();
  const [placeholderFirstPart, setPlaceholderFirstPart] = useState('');
  const [placeholderLink, setPlaceholderLink] = useState('');
  const [placeholderSecondPart, setPlaceholderSecondPart] = useState('');
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [currentValue, setCurrentValue] = useState('');
  const [currentFieldIndex, setCurrentFieldIndex] = useState(0);

  const fields = inputFields.filter(f => f.collect && f.type !== 'checkbox');
  const checkboxField = inputFields.find(f => f.collect && f.type === 'checkbox');
  const defaultBackgroundColor = theme.colors.pink2;

  function onBackClicked(e) {
    e.stopPropagation();

    if (!canGoBack) {
      return;
    }
    setCurrentValue('');
    setCurrentFieldIndex(currentFieldIndex - 1);
  }

  function isCheckBoxField() {
    const checkBoxFieldIndex = 0;
    return checkboxField && currentFieldIndex === checkBoxFieldIndex;
  }

  function onCurrentValueChanged(e) {
    const newValue = e.target.value;

    setCurrentValue(newValue);
  }

  useEffect(() => {
    setCurrentFieldIndex(0);
  }, [fields.length]);

  useEffect(() => {
    if (checkboxField && checkboxField.link !== '') {
      const urlRegex = /\{{(.*)}}/i;
      const match = checkboxField.placeholder.match(urlRegex);
      if (match) {
        const placeholderResult = checkboxField.placeholder.split(match[0]);
        setPlaceholderFirstPart(placeholderResult[0]);
        setPlaceholderSecondPart(placeholderResult[1]);
        setPlaceholderLink(
          <a rel="noopener noreferrer" target="_blank" href={checkboxField.link}>
            {match[1]}
          </a>
        );
      } else {
        setPlaceholderFirstPart(checkboxField.placeholder);
        setPlaceholderSecondPart(null);
        setPlaceholderLink(null);
      }
    }
  }, [fields.length, checkboxField, checkboxField?.placeholder]);

  const currentField = fields[currentFieldIndex];

  if (!fields || !currentField) {
    return null;
  }

  const fieldPlaceholder = {
    email: 'Your email',
    name: 'Your name',
    phone: 'Your phone number',
  };

  const actionButtonText = currentFieldIndex === fields.length - 1 ? 'Submit' : 'Next';
  const canGoBack = currentFieldIndex > 0;

  return (
    <LeadFormModal backgroundColor={currentField?.backgroundColor || defaultBackgroundColor}>
      <LeadFormModalContent>
        <LeadFormLeftPanel>
          <LeadFormXButtonMobile />
          <LeadFormModalEllipseLeft />
          <LeadFormLeftPanelText>{formText}</LeadFormLeftPanelText>
        </LeadFormLeftPanel>
        <LeadFormRightPanel>
          <LeadFormXButtonDesktop />

          <LeadFormInputFieldContainer>
            <LeadFormInputField
              color={currentField?.backgroundColor}
              required={currentField.required}
              value={currentValue}
              name={currentField.name}
              maxLength={200}
              onChange={onCurrentValueChanged}
              placeholder={
                (currentField.required ? '*' : '') +
                (currentField.custom ? currentField.name : fieldPlaceholder[currentField.name])
              }
            />
            {isCheckBoxField() && (
              <LeadFormCheckboxContainer>
                <LeadFormInputCheckbox
                  type="checkbox"
                  name="consent"
                  checked={checkboxValue}
                  required={checkboxField.required}
                  onChange={e => setCheckboxValue(e.target.checked)}
                />
                <LeadFormCheckboxTextContainer>
                  <span>{placeholderFirstPart}</span>
                  {placeholderLink}
                  {placeholderSecondPart && <span>{placeholderSecondPart}</span>}
                </LeadFormCheckboxTextContainer>
              </LeadFormCheckboxContainer>
            )}
          </LeadFormInputFieldContainer>

          <LeadFormRightButtonsContainer>
            <LeadFormButton
              name="back-button"
              value="Back"
              disabled={!canGoBack}
              type="submit"
              onClick={onBackClicked}
            />
            <LeadFormNextButtonContainer>
              <LeadFormButton
                name="next-button"
                type="submit"
                backgroundColor={currentField?.backgroundColor}
                value={actionButtonText}
              />
            </LeadFormNextButtonContainer>
          </LeadFormRightButtonsContainer>
        </LeadFormRightPanel>
      </LeadFormModalContent>
    </LeadFormModal>
  );
}

export default LeadFormPreview;

const LeadFormModal = styled.form`
  color: ${({ backgroundColor }) => backgroundColor};
  background: ${({ backgroundColor }) => backgroundColor};
  border-color: ${({ backgroundColor }) => backgroundColor};
  -webkit-transition: background-color 350ms linear;
  -ms-transition: background-color 350ms linear;
  transition: background-color 350ms linear;
  max-height: 430px;
  pointer-events: all;

  &::placeholder {
    color: ${({ backgroundColor }) => backgroundColor};
    opacity: 0.5 !important;
    -webkit-transition: color 350ms linear;
    -ms-transition: color 350ms linear;
    transition: color 350ms linear;
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: ${({ backgroundColor }) => backgroundColor} !important;
  }

  width: 100%;
  max-width: 791px;
  height: 100%;
  z-index: 1;
  border-radius: 10px;
`;

const LeadFormModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  overflow-x: hidden;
  overflow-y: auto;
  background: transparent;
  min-height: 430px;

  @media (${({ theme }) => theme.breakpoints.dashboardTabletMax}) {
    grid-template-columns: auto;
    grid-template-rows: 1fr 1fr;
  }
`;

const LeadFormLeftPanel = styled.div`
  display: grid;
  align-self: start;
`;

const LeadFormXButtonMobile = styled.img`
  grid-row: 1;
  grid-column: 1;
`;

const LeadFormModalEllipseLeft = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  background: ${({ theme }) => theme.colors.pink4};
  opacity: 0.5;
  border-radius: 50%;
  justify-self: center;
  align-self: end;
  filter: blur(104px);
  grid-row: 1;
  grid-column: 1;
`;

const LeadFormLeftPanelText = styled.div`
  justify-self: start;
  padding: 20px 10px 20px 44px;
  align-self: center;
  max-width: 383px;
  grid-row: 1;
  grid-column: 1;
  text-align: start;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 150.9%;
  color: ${({ theme }) => theme.colors.white};

  @media (${({ theme }) => theme.breakpoints.dashboardTabletMax}) {
    justify-self: center;
    padding-left: 16px;
  }
`;

const LeadFormRightPanel = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 220px;
  margin: 16px 16px 16px 0px;
  border-radius: 10px;
  background: white;

  @media (${({ theme }) => theme.breakpoints.dashboardTabletMax}) {
    margin-top: 0;
    margin-left: 16px;
  }
`;

const LeadFormXButtonDesktop = styled.img`
  grid-row: 1;
  grid-column: 1;
`;

const LeadFormInputFieldContainer = styled.div`
  display: grid;
  justify-self: center;
  align-self: center;
  gap: 16px;
  width: 80%;
  grid-row: 1;
  grid-column: 1;
`;

const LeadFormInputField = styled.input`
  color: ${({ color }) => color};
  border-color: ${({ color }) => color};
  &::placeholder {
    color: ${({ color }) => color};
    opacity: 0.5 !important;
    -webkit-transition: color 350ms linear;
    -ms-transition: color 350ms linear;
    transition: color 350ms linear;
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: ${({ color }) => color} !important;
  }

  width: 100%;
  justify-self: center;
  align-self: center;
  font-size: 24px;
  font-weight: 600;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: solid;
  appearance: none;
  background-color: transparent !important;
  -webkit-transition: background-color 0ms linear;
  -ms-transition: background-color 0ms linear;
  transition: background-color 0ms linear;
  outline: none;
  padding-bottom: 7px;

  &::placeholder {
    opacity: 0.8; /* Firefox */
    font-weight: 600;
    font-size: 24px;
  }
`;

const LeadFormCheckboxContainer = styled.div`
  width: 100%;
  text-align: start;
  align-items: center;
  display: flex;
`;

const LeadFormInputCheckbox = styled.input`
  width: 20px;
  height: 20px;
  min-height: 18px;
  min-width: 18px;
`;

const LeadFormCheckboxTextContainer = styled.div`
  white-space: initial;
  margin-left: 10px;
  font-size: 16px;
`;

const LeadFormRightButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-self: end;
  justify-self: end;
  margin: 20px;
  gap: 8px;
  grid-row: 1;
  grid-column: 1;
`;

const LeadFormButton = styled.input`
  -webkit-appearance: none;
  text-align: center;
  height: 42px;
  width: 90px;
  padding: 10px;
  outline: none;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  grid-row: 1;
  grid-column: 1;
  color: ${({ disabled, backgroundColor, theme }) =>
    !disabled && backgroundColor ? theme.colors.white : theme.colors.gray8};
  background: ${({ disabled, backgroundColor }) =>
    !disabled && backgroundColor ? backgroundColor : 'transparent'};
  border-color: ${({ disabled, backgroundColor }) =>
    !disabled && backgroundColor ? backgroundColor : 'transparent'};
  -webkit-transition: ${TransitionAttributes}, 350ms linear;
  -ms-transition: ${TransitionAttributes}, 350ms linear;
  transition: ${TransitionAttributes}, 350ms linear;
  border-radius: 6px;
  box-shadow: none;
  border-style: solid;

  &:hover {
    opacity: ${({ disabled }) => (disabled ? 0.4 : 0.9)};
  }
`;

const LeadFormNextButtonContainer = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
`;
