import React from 'react';

const YoutubeSmallIcon = () => {
  return (
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.164 1.223a2 2 0 0 1 .517.9C16.016 3.38 16.016 6 16.016 6s0 2.62-.335 3.876a2.01 2.01 0 0 1-1.414 1.424c-1.251.336-6.251.336-6.251.336s-5 0-6.251-.336A2.01 2.01 0 0 1 .35 9.876C.016 8.62.016 6 .016 6s0-2.62.334-3.876A2.01 2.01 0 0 1 1.765.7c1.25-.336 6.25-.336 6.25-.336s5 0 6.252.336c.34.093.65.273.897.523M10.561 6 6.38 3.62v4.76z"
        fill="#B3B6BC"
      />
    </svg>
  );
};

export default YoutubeSmallIcon;
