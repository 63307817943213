import styled from 'styled-components';

const DEFAULT_SIZE = '18px';

interface ColorCircleProps {
  color: string;
  size?: string;
  disabled?: boolean;
  hasBorder?: boolean;
}

const ColorCircle = styled.div<ColorCircleProps>`
  height: ${({ size }) => size || DEFAULT_SIZE};
  width: ${({ size }) => size || DEFAULT_SIZE};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  border: ${({ hasBorder, theme }) => (hasBorder ? `1px solid ${theme.colors.ghostDark}` : 'none')};
  border-radius: 50px;
  background: ${props => props.color};
`;

export default ColorCircle;
