import React from 'react';
import styled from 'styled-components';
import { CheckboxV2 } from 'app/src/complex_components/Checkbox';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextSmall } from 'shared/react/components/complex/Text';

export type GroupItem = {
  isSelected?: boolean;
  name: string;
  value: any;
  id: string;
};

export type Group = {
  name: string;
  items: GroupItem[];
};

export type GroupSelectMenuItemProps = {
  group: Group;
  onItemSelect: (item: GroupItem) => void;
};

export const GroupSelectMenuItem = ({ group, onItemSelect }: GroupSelectMenuItemProps) => (
  <>
    <ItemContainer>
      <GroupName>{group.name}</GroupName>
    </ItemContainer>
    {group.items.map(item => (
      <ItemContainer key={item.id} onClick={() => onItemSelect(item)}>
        <Checkbox checked={item.isSelected} />
        <Text>{item.name}</Text>
      </ItemContainer>
    ))}
  </>
);

const Text = styled(TextSmall)`
  font-weight: 500;
`;

const GroupName = styled(Text)`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutralDark};
`;

const Checkbox = styled(CheckboxV2)`
  padding: 4px;
`;

const ItemContainer = styled(HorizontalFlex)`
  align-items: center;
  padding: 6px 8px;
  cursor: pointer;
  gap: 8px;
`;

export default GroupSelectMenuItem;
