import React from 'react';
import styled from 'styled-components';
import { CAROUSEL_DOT_SIZE } from './carousel.constants';

const CarouselDot = ({ selected, onClick, index }) => {
  return <LayoutRoot selected={selected} onClick={() => onClick(index)} />;
};

const LayoutRoot = styled.div`
  width: ${CAROUSEL_DOT_SIZE};
  height: ${CAROUSEL_DOT_SIZE};
  border-radius: 50%;
  background: ${({ theme, selected }) => (selected ? theme.colors.black : 'transparent')};
  border: 1px solid
    ${({ theme, selected }) => (selected ? theme.colors.black : theme.colors.ghostLight)};
  cursor: ${({ selected }) => (selected ? 'default' : 'pointer')};
  transition: 0.15s;
`;

export default CarouselDot;
