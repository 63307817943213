import React from 'react';
import styled from 'styled-components';
import EditorOnOffToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorOnOffToggleItem';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import {
  COLLECT_AFTER_SELECT_DATA_TEST_ID,
  COLLECT_AFTER_SWITCH_DATA_TEST_ID,
} from 'app/src/constants/dataTestIds.constants';
import Utils from 'app/src/utils';
import EditorItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorItem';
import {
  COLLECT_AFTER_ANY_RESPONSE_PROPERTY_KEY,
  COLLECT_AFTER_STEP_PROPERTY_KEY,
  HIDE_AFTER_SUBMISSION_PROPERTY_KEY,
  START_KEY,
} from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import BasicSelect from 'shared/react/components/basic/basic_select/BasicSelect';
import EditorHeader from '../shared/EditorHeader';
import EditorMainContainer from '../shared/EditorMainContainer';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const CollectionSettingsEditor = () => {
  const {
    customizationSettings: { collectAfterAnyResponse, collectAfterStep, cacheLeadFormAnswers },
    setCustomizationSettingsProperty,
    project,
  } = useLookAndFeelContext();

  const preparePartName = index => {
    return `Part ${index + 1}`;
  };

  const getPartName = stepIndex => {
    const currentCollectAt = collectAfterStep?.[0];
    stepIndex = stepIndex || project.stepsOrder.indexOf(currentCollectAt);

    if (Utils.isNullOrUndefined(stepIndex) || stepIndex < 0) {
      return 'Start';
    }

    return preparePartName(stepIndex);
  };

  const getPartOptions = () => {
    const parts =
      project?.steps?.items?.map((step, i) => ({
        key: i,
        id: step.name,
        value: step.name,
        name: preparePartName(i),
      })) || [];
    return [
      {
        key: START_KEY,
        id: START_KEY,
        value: START_KEY,
        name: 'Start',
      },
      ...parts,
    ];
  };

  return (
    <LayoutRoot>
      <EditorHeader title="Collect data settings" />
      <EditorMainContainer>
        <EditorOnOffToggleItem
          text="Collect details after responses"
          tooltipText="Ask for contact details after a response. If contact details have not been collected yet."
          checked={collectAfterAnyResponse}
          onChange={value =>
            setCustomizationSettingsProperty(COLLECT_AFTER_ANY_RESPONSE_PROPERTY_KEY, value)
          }
        />
        <EditorOnOffToggleItem
          text="Hide lead form after submission"
          tooltipText="Respondents will only be allowed to fill out the Contact form once."
          checked={cacheLeadFormAnswers}
          onChange={value =>
            setCustomizationSettingsProperty(HIDE_AFTER_SUBMISSION_PROPERTY_KEY, value)
          }
        />
        <EditorOnOffToggleItem
          text="Collect details after part"
          tooltipText="Place your contact form after a specific part, no matter what button the viewer clicks."
          checked={collectAfterStep?.length}
          dataTestId={COLLECT_AFTER_SWITCH_DATA_TEST_ID}
          onChange={() =>
            setCustomizationSettingsProperty(
              COLLECT_AFTER_STEP_PROPERTY_KEY,
              !collectAfterStep?.length ? ['start'] : []
            )
          }
        />
        {!!collectAfterStep?.length && (
          <EditorItem text="Collect at">
            <StyledBasicSelect
              value={getPartName()}
              items={getPartOptions()}
              customHeight="34px"
              onChange={step =>
                setCustomizationSettingsProperty(COLLECT_AFTER_STEP_PROPERTY_KEY, [step.value])
              }
              inputPadding="6.5px 16px"
              data-test-id={COLLECT_AFTER_SELECT_DATA_TEST_ID}
            />
          </EditorItem>
        )}
      </EditorMainContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

const StyledBasicSelect = styled(BasicSelect)`
  padding: 0;
  max-width: 196px;
`;

export default CollectionSettingsEditor;
