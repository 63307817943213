import React from 'react';
import styled from 'styled-components';
import XWithCircles from 'app/src/images/XWithCircles';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

type Props = {
  title: string | React.ReactNode;
  isSelected: boolean;
  onClick: () => void;
  className?: string;
  onRemoveCategory?: () => void;
};

export const UgcItem = ({ title, isSelected, onRemoveCategory, ...props }: Props) => {
  const onRemove = e => {
    e.stopPropagation();
    onRemoveCategory();
  };

  return (
    <LayoutRoot $isSelected={isSelected} {...props}>
      <Text $isSelected={isSelected}>{title}</Text>
      {!!onRemoveCategory && !isSelected && (
        <RemoveButton>
          <XWithCircles onClick={onRemove} />
        </RemoveButton>
      )}
    </LayoutRoot>
  );
};

const RemoveButton = styled.div`
  position: absolute;
  padding: 4px 8px;
  background: ${({ theme }) => theme.colors.white};
  display: none;
  right: 0;
  top: 0;
  border-radius: 0 100px 100px 0;
  height: 100%;
  align-items: center;
`;

const LayoutRoot = styled(HorizontalFlex)`
  background: ${({ $isSelected, theme }) =>
    $isSelected ? theme.colors.black : theme.colors.white};
  padding: 8px 12px;
  border-radius: 16px;
  border: 1px solid
    ${({ theme, $isSelected }) =>
      $isSelected ? theme.colors.neutralBlack : theme.colors.neutralLight};
  cursor: pointer;
  position: relative;

  &:hover ${RemoveButton} {
    display: flex;
  }
`;

const Text = styled(TextTiny)`
  color: ${({ $isSelected, theme }) => ($isSelected ? theme.colors.white : theme.colors.black)};
`;

export default UgcItem;
