import React from 'react';

const ShopIcon = ({ ...props }) => {
  return (
    <svg
      width="47"
      height="26"
      viewBox="0 0 47 26"
      fill="none"
      y1={20}
      x1={20}
      y2={0}
      x2={0}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M46.27 14.525c-.057-3.232-2.414-5.707-5.478-5.707-1.293 0-2.47.471-3.357 1.355-.056.056-.114 0-.114-.057V9.059c0-.057-.057-.179-.178-.179h-2.53c-.056 0-.177.057-.177.18v14.76c0 .057.057.179.178.179h2.593c.056 0 .178-.057.178-.179v-4.823c0-.057.056-.115.114-.057.886.821 2 1.292 3.3 1.292 3.064 0 5.478-2.468 5.478-5.708h-.007Zm-6.014 3.182c-1.77 0-3.064-1.413-3.064-3.232 0-1.82 1.294-3.233 3.064-3.233s3.064 1.355 3.064 3.232c0 1.878-1.292 3.233-3.064 3.233ZM9.53 16.588c0-2.176-2.122-2.825-3.943-3.233-1.828-.413-2.707-.585-2.707-1.292 0-.65.529-.998 1.65-.998.999 0 1.707.413 2.235 1.29.057.057.115.057.178.057l2.065-1.056c.057-.057.115-.115.057-.179-.886-1.527-2.473-2.29-4.593-2.29C1.707 8.888 0 10.243 0 12.418c0 2.291 2.121 2.883 3.944 3.297 1.885.413 2.706.585 2.706 1.292 0 .707-.585 1.057-1.828 1.057-1.12 0-1.942-.529-2.414-1.47-.057-.057-.115-.115-.178-.057l-2.064.885c-.057.057-.115.115-.057.179.822 1.647 2.53 2.589 4.771 2.589 2.886 0 4.65-1.355 4.65-3.59v-.011Zm11.54-3.119c-.056-2.704-1.828-4.646-4.299-4.646-1.178 0-2.236.414-3 1.178-.056.057-.114 0-.114-.057V5.179c0-.057-.057-.179-.178-.179h-2.593c-.057 0-.178.057-.178.179v14.763c0 .057.057.178.178.178h2.593c.057 0 .178-.057.178-.178V13.47c0-1.234.942-2.234 2.236-2.234 1.293 0 2.236.942 2.236 2.234v6.472c0 .057.057.178.18.178H20.9c.057 0 .178-.057.178-.178V13.47h-.006l-.001-.001Zm12.072 1.235c0-3.646-2.886-6.293-6.771-6.293-1.415 0-2.765.414-3.708 1.057-.057.057-.057.115-.057.177l1.179 1.94c.057.057.115.115.178.057a4.755 4.755 0 0 1 2.414-.649c2.293 0 3.942 1.591 3.942 3.704 0 1.827-1.356 3.176-3.064 3.176-1.414 0-2.357-.821-2.357-1.94 0-.65.293-1.178 1-1.591.056-.057.115-.115.056-.178l-1.057-1.826c-.057-.057-.115-.057-.178-.057-1.471.528-2.47 1.826-2.47 3.589 0 2.647 2.12 4.587 5.006 4.587 3.415 0 5.886-2.355 5.886-5.764v.013l.001-.002Z"
        fill="#090a0b"
      />
    </svg>
  );
};

export default ShopIcon;
