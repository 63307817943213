import React from 'react';

const PublishVideoIcon = props => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="32" height="32" rx="16" fill="#01BE81" />
      <path
        d="M21 11.75V19.25C21 19.6875 20.6562 20 20.25 20C19.8125 20 19.5 19.6875 19.5 19.25V13.5625L12.2812 20.7812C12.125 20.9375 11.9375 21 11.75 21C11.5312 21 11.3438 20.9375 11.2188 20.7812C10.9062 20.5 10.9062 20.0312 11.2188 19.75L18.4375 12.5H12.75C12.3125 12.5 12 12.1875 12 11.75C12 11.3438 12.3125 11 12.75 11H20.25C20.6562 11 21 11.3438 21 11.75Z"
        fill="white"
      />
    </svg>
  );
};

export default PublishVideoIcon;
