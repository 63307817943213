import { app as dbAppEntity } from 'app/src/types/entities';
import { App } from 'app/src/types/app';

export const parseDbApp = (app: dbAppEntity): App => {
  const data = JSON.parse(app?.data || 'null');
  const settings = JSON.parse(app?.settings || 'null');

  return {
    ...app,
    settings,
    data: {
      ...data,
      name: data.name || data.userName,
    },
  };
};

export const isOneAppSource = (apps: App[] = [], appExternalIds: string[] = []) => {
  const selectedSourceMap = {};

  for (const app of apps) {
    if (!appExternalIds.includes(app.appExternalId)) {
      continue;
    }

    if (selectedSourceMap[app.app]) {
      return false;
    }

    selectedSourceMap[app.app] = true;
  }

  return true;
};

export const repalceOrAddApp = ({ newApp, apps }) => {
  const isAppInArray = apps.some(item => item.id === newApp.id);

  if (isAppInArray) {
    return apps.map(app => (newApp.id === app.id ? newApp : app));
  }

  return [...apps, newApp];
};
