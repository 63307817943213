import React from 'react';
import styled from 'styled-components';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const MILK_MAKEUP_SUB_HEADER_COLOR = '#747474';
const MILK_MAKEUP_HEADER_COLOR = '#545452';
const DEFAULT_CATEGORY = 'default';

const ProductColorVariant = ({
  color,
  selectedVariant,
  handleProductOptionSelected,
  variants,
  extraVariantData,
  optionPosition,
  selectedValue,
}) => {
  const title = selectedValue;
  const categoriesMap = {};

  const categories = Object.entries(extraVariantData).map(([variantId, value]) => {
    const category = value.shade_category;
    categoriesMap[category] = categoriesMap[category]
      ? [...categoriesMap[category], variantId]
      : [variantId];

    return category;
  });

  const filteredCategories = [...new Set(categories)];

  if (!categories.length) {
    categories.push(DEFAULT_CATEGORY);
    categoriesMap[DEFAULT_CATEGORY] = Object.keys(color);
  }

  const onColorSelected = variantId => {
    const variant = variants.find(({ id }) => id === +variantId);
    handleProductOptionSelected(optionPosition, variant.selectedOptions[optionPosition]);
  };

  return (
    <LayoutRoot>
      {filteredCategories.map(category => {
        return (
          <Gap8VerticalFlex key={category}>
            <HeaderContainer>
              {category !== DEFAULT_CATEGORY && <Header>{category || ''}</Header>}{' '}
              {categoriesMap[category].includes(`${selectedVariant?.id}`) && (
                <SubHeader>{title}</SubHeader>
              )}
            </HeaderContainer>
            <Colors>
              {categoriesMap[category].map(variantId => (
                <Color
                  onClick={() => onColorSelected(variantId)}
                  isSelected={+variantId === +selectedVariant?.id}
                  color={color[variantId][0]}
                />
              ))}
            </Colors>
          </Gap8VerticalFlex>
        );
      })}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  gap: 24px;
`;

const HeaderContainer = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 1;
  text-align: left;
`;

const Header = styled.span`
  text-align: left;
  color: ${MILK_MAKEUP_HEADER_COLOR};
`;

const SubHeader = styled.span`
  font-weight: 400;
  font-size: 17px;
  line-height: 1.1;

  color: ${MILK_MAKEUP_SUB_HEADER_COLOR};
`;

const Colors = styled(HorizontalFlex)`
  gap: 12px;
`;

const Color = styled.div`
  width: 25px;
  height: 25px;
  background: ${({ color }) => color};
  border-radius: 100%;
  cursor: pointer;
  border: 1px solid ${({ isSelected, theme }) => (isSelected ? theme.colors.black : 'transparent')};
`;

export default ProductColorVariant;
