import React from 'react';
import styled from 'styled-components';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import EditorColorInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorColorInputItem';
import EditorOnOffToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorOnOffToggleItem';
import EditorTextInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorTextInputItem';
import {
  BORDER_PROPERTY_KEY,
  THEME_COLOR_PROPERTY_KEY,
  WIDGET_BORDER_COLOR_PROPERTY_KEY,
  WIDGET_HEADER_LOCATION_KEY,
  WIDGET_HEADER_LOCATIONS,
  WIDGET_HEADER_PROPERTY_KEY,
} from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import {
  BORDER_OFF_DATA_TEST_ID,
  BORDER_ON_DATA_TEST_ID,
} from 'shared/react/constants/dataTestIds.constants';
import EditorButtonGroupItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorButtonGroupItem';
import TopDirectionIcon from 'app/src/images/directons/TopDirectionIcon';
import CenterDirectionIcon from 'app/src/images/directons/CenterDirectionIcon';
import BottomDirectionIcon from 'app/src/images/directons/BottomDirectionIcon';
import { track } from 'app/src/helpers/Tracker';
import GhostButton from 'shared/react/components/basic/button/button/GhostButton';
import EditorItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorItem';
import PenIcon from 'app/src/images/PenIcon';
import EditorHeader from '../shared/EditorHeader';
import EditorMainContainer from '../shared/EditorMainContainer';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

function AppearanceEditor() {
  const {
    customizationSettings: {
      widgetHeader,
      widgetSettings: { border, themeColor, widgetHeaderLocation },
    },
    setCustomizationSettingsProperty,
    setWidgetSettingsProperty,
    setIsEditingBubblePosition,
  } = useLookAndFeelContext();

  const setCaptionsLocation = location => {
    track('Widget Caption Location Change', { location });
    setWidgetSettingsProperty(WIDGET_HEADER_LOCATION_KEY, location);
  };

  const locationButtons = [
    {
      icon: <TopDirectionIcon />,
      isEnabled: widgetHeaderLocation === WIDGET_HEADER_LOCATIONS.top,
      onClick: () => setCaptionsLocation(WIDGET_HEADER_LOCATIONS.top),
    },
    {
      icon: <CenterDirectionIcon />,
      isEnabled: widgetHeaderLocation === WIDGET_HEADER_LOCATIONS.center || !widgetHeaderLocation,
      onClick: () => setCaptionsLocation(WIDGET_HEADER_LOCATIONS.center),
    },
    {
      icon: <BottomDirectionIcon />,
      isEnabled: widgetHeaderLocation === WIDGET_HEADER_LOCATIONS.bottom,
      onClick: () => setCaptionsLocation(WIDGET_HEADER_LOCATIONS.bottom),
    },
  ];

  const onBorderToggleOff = value => {
    setWidgetSettingsProperty(BORDER_PROPERTY_KEY, value);

    if (!value) {
      setCustomizationSettingsProperty(WIDGET_BORDER_COLOR_PROPERTY_KEY, '');
    }
  };

  const toggleEditBubblePosition = () => {
    setIsEditingBubblePosition(prevState => !prevState);
  };

  return (
    <LayoutRoot>
      <EditorHeader title="Appearance" />
      <EditorMainContainer>
        <EditorItem text="Video alignment">
          <GhostButton onClick={toggleEditBubblePosition}>
            <PenIcon />
            Reposition
          </GhostButton>
        </EditorItem>
        <EditorOnOffToggleItem
          text="Border"
          checked={!!border}
          dataTestId={border ? BORDER_OFF_DATA_TEST_ID : BORDER_ON_DATA_TEST_ID}
          onChange={onBorderToggleOff}
        />
        <EditorTextInputItem
          text="Caption"
          placeholder="Let's talk"
          value={widgetHeader || ''}
          maxLength={35}
          onChange={value => setCustomizationSettingsProperty(WIDGET_HEADER_PROPERTY_KEY, value)}
        />
        <EditorButtonGroupItem text="Caption location" buttons={locationButtons} />
        <EditorColorInputItem
          text="Theme color"
          color={themeColor}
          onChange={value => setWidgetSettingsProperty(THEME_COLOR_PROPERTY_KEY, value)}
        />
      </EditorMainContainer>
    </LayoutRoot>
  );
}

const LayoutRoot = styled(VerticalFlex)``;

export default AppearanceEditor;
