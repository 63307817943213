import React from 'react';
import styled from 'styled-components';
import Menu from 'shared/react/components/basic/Menu';
import Separator from 'shared/react/components/basic/Separator';
import { TextSmall } from 'shared/react/components/complex/Text';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';

export const BasicMenuV2Wrapper = ({ children, anchorEl, onClose, className, ...props }) => {
  return (
    <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={onClose} {...props}>
      <Content className={className}>{children}</Content>
    </Menu>
  );
};

export const BasicMenuV2Item = ({ text, icon, onClick, dataTestId, ...props }) => {
  return (
    <ButtonContainer onClick={onClick} data-test-id={dataTestId} {...props}>
      {!!icon && <IconContainer>{icon}</IconContainer>}
      <TextContainer>{text}</TextContainer>
    </ButtonContainer>
  );
};

const BasicMenuV2 = ({ anchorEl, onClose, items, ...props }) => {
  return (
    <BasicMenuV2Wrapper anchorEl={anchorEl} open={!!anchorEl} onClose={onClose} {...props}>
      {items.map(({ icon, text, onClick, dataTestId }) => {
        return (
          <React.Fragment key={text}>
            <BasicMenuV2Item text={text} icon={icon} onClick={onClick} dataTestId={dataTestId} />
            <CustomSeparator />
          </React.Fragment>
        );
      })}
    </BasicMenuV2Wrapper>
  );
};

const Content = styled.div`
  min-width: 156px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  position: relative;
  overflow: hidden;
`;

const CustomSeparator = styled(Separator)`
  margin: 0 10px;
  width: inherit;

  &:last-of-type {
    display: none;
  }
`;

const IconContainer = styled(VerticalFlex)`
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`;

const ButtonContainer = styled(HorizontalFlexWrap)`
  padding: 15px 20px;
  gap: 12px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  transition: 0.3s;
  flex-wrap: nowrap;
  align-items: center;

  &:hover {
    background: ${({ theme }) => theme.colors.gray32};
  }

  && path {
    transition: 0.3s;
    fill: ${({ theme, isSelected, disabled }) => {
      if (disabled) {
        return theme.colors.gray4;
      }

      if (isSelected) {
        return theme.colors.primary;
      }

      return '';
    }};
  }

  &:last-child {
    border-radius: 0 0 12px 12px;
  }
`;

const TextContainer = styled(TextSmall)`
  transition: 0.3s;
  color: ${({ theme, isSelected, disabled }) => {
    if (disabled) {
      return theme.colors.gray4;
    }

    if (isSelected) {
      return theme.colors.primary;
    }

    return theme.colors.black;
  }};
`;

export default BasicMenuV2;
