import React from 'react';

const CircularLinkIcon = props => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 20 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15 2h-3.25a.74.74 0 0 0-.75.75c0 .438.313.75.75.75H15c1.906 0 3.5 1.594 3.5 3.5 0 1.938-1.594 3.5-3.5 3.5h-3.25a.74.74 0 0 0-.75.75c0 .438.313.75.75.75H15c2.75 0 5-2.219 5-5 0-2.75-2.25-5-5-5Zm-6.75 8.5H5A3.494 3.494 0 0 1 1.5 7c0-1.906 1.563-3.5 3.5-3.5h3.25A.74.74 0 0 0 9 2.75.76.76 0 0 0 8.25 2H5C2.219 2 0 4.25 0 7c0 2.781 2.219 5 5 5h3.25a.74.74 0 0 0 .75-.75.76.76 0 0 0-.75-.75ZM6 7c0 .438.313.75.75.75h6.5A.74.74 0 0 0 14 7a.76.76 0 0 0-.75-.75h-6.5A.74.74 0 0 0 6 7Z"
      fill="#50545E"
    />
  </svg>
);

export default CircularLinkIcon;
