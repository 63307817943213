import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { usePlaylistsAvailability } from 'src/hooks/playlists';
import { useRulesActions } from 'app/src/context/RulesStore';
import { useVodLabels } from 'app/src/context/VodLabelsStore';
import { EQUALS_KEY } from 'app/src/constants/ruleGroups.constants';
import { useModalActions } from 'app/src/context/ui_store/ModalStore';
import { CREATION_FLOW_MODAL_KEY } from 'app/src/constants/modals.constants';
import { DynamicRuleTypes } from '../../constants/dynamicConditions.constants';
import PlaylistList from './PlaylistList';
import { Playlist } from './types';
import { DEFAULT_MAX_VISIBLE_PLAYLISTS } from './constants';
import {
  getPlaylists,
  getIsRuleSelected,
  getVideosModalSelectedMethod,
  getVideosModalDefaultFilterProps,
} from './utils';

const PlaylistsPicker = ({
  rules = [],
  isProjectProductPage = false,
  updateRules,
  onTagMoreProducts,
}) => {
  const [playlists, setPlaylists] = useState([]);
  const [maxVisiblePlaylists, setMaxVisiblePlaylists] = useState(DEFAULT_MAX_VISIBLE_PLAYLISTS);
  const [{ vodLabels }] = useVodLabels();
  const { createRule } = useRulesActions();
  const playlistAvailability = usePlaylistsAvailability();
  const { setCurrentModal } = useModalActions();
  const connectingPlaylistRef = useRef<Playlist>(null);

  const addRule = rule => updateRules([...rules, rule]);
  const removeRule = ({ value, type }) => {
    const filteredRules = rules.filter(rule => !getIsRuleSelected({ value, type, rule }));

    updateRules(filteredRules);
  };

  const addPlaylistRule = ({ value, type, condition = EQUALS_KEY }) => {
    const newRule = createRule({
      condition,
      value,
      type,
      behaviors: [],
    });

    return addRule(newRule);
  };

  const updatePlaylistRule = ({ value, type, updatedFields }) => {
    const updatedRules = rules.map(rule => {
      if (getIsRuleSelected({ value, type, rule })) {
        return { ...rule, ...updatedFields };
      }

      return rule;
    });

    updateRules(updatedRules);
  };

  const openVideosModal = (type, value) => {
    const defaultSelectedMethod = getVideosModalSelectedMethod(type, value);
    const defaultVideoFilterProps = getVideosModalDefaultFilterProps(type, value);

    setCurrentModal(CREATION_FLOW_MODAL_KEY, {
      isLeftPanelVisible: false,
      shouldCreateProject: false,
      defaultSelectedMethod,
      defaultVideoFilterProps,
    });
  };

  const onUnavailablePlaylistClick = (playlist: Playlist) => {
    const { value, type } = playlist;
    connectingPlaylistRef.current = playlist;

    if (type === DynamicRuleTypes.taggedProduct) {
      return onTagMoreProducts();
    }

    return openVideosModal(type, value);
  };

  const onPlaylistClick = (playlist: Playlist) => {
    const { value, type, condition, isAvailable } = playlist;

    if (!isAvailable) {
      return onUnavailablePlaylistClick(playlist);
    }

    if (!condition) {
      return addPlaylistRule({ value, type });
    }

    return removeRule({ value, type });
  };

  const handleUpdateRuleCondition = (playlist, newRuleCondition) => {
    const { value, type, condition } = playlist;

    if (!condition) {
      return addPlaylistRule({ value, type, condition: newRuleCondition });
    }

    updatePlaylistRule({ value, type, updatedFields: { condition: newRuleCondition } });
  };

  useEffect(() => {
    const { playlists, totalVisibleAmount } = getPlaylists({
      vodLabels,
      rules,
      playlistAvailability,
      defaultVisiblePlaylistsAmount: DEFAULT_MAX_VISIBLE_PLAYLISTS,
    });

    if (connectingPlaylistRef.current) {
      const { value, type } = connectingPlaylistRef.current;
      const { isAvailable } =
        playlists.find(playlist => playlist.type === type && playlist.value === value) || {};

      if (isAvailable) {
        addPlaylistRule({ value, type });
      }

      connectingPlaylistRef.current = null;
    }

    setPlaylists(playlists);
    setMaxVisiblePlaylists(totalVisibleAmount);
  }, [vodLabels, rules, playlistAvailability]);

  return (
    <LayoutRoot>
      <PlaylistList
        playlists={playlists}
        onPlaylistClick={onPlaylistClick}
        onUpdateRuleCondition={handleUpdateRuleCondition}
        maxVisiblePlaylists={maxVisiblePlaylists}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  margin: 16px 0;
`;

export default PlaylistsPicker;
