import React from 'react';
import styled from 'styled-components';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import Button from 'shared/react/components/basic/Button';
import GenericBadgeContent from './GenericBadgeContent';

const SUBTITLE_VISIBLE_BREAKPOINT = 'max-width: 380px';

const GenericBadge = ({
  title,
  titleOnClick,
  subtitle,
  handleCloseClick,
  icon,
  backgroundColor,
  titleColor,
  subtitlesColor,
  buttons,
  closeButtonBackgroundColor,
  closeButtonColor,
  closeButtonHoverColor,
  subtitleBeforeTitle,
}) => {
  const showCloseButton = !!handleCloseClick;

  return (
    <StyledBadge backgroundColor={backgroundColor}>
      <GenericBadgeContent
        title={title}
        subtitle={subtitle}
        icon={icon}
        titleColor={titleColor}
        subtitlesColor={subtitlesColor}
        subtitleBeforeTitle={subtitleBeforeTitle}
        showCloseButton={showCloseButton}
        titleOnClick={titleOnClick}
      />
      <StyledButtons>{buttons}</StyledButtons>
      {showCloseButton && (
        <SubscriptionBadgeCloseButton
          onClick={handleCloseClick}
          color={closeButtonColor}
          backgroundColor={closeButtonBackgroundColor}
          hoverColor={closeButtonHoverColor}
        >
          X
        </SubscriptionBadgeCloseButton>
      )}
    </StyledBadge>
  );
};

export default GenericBadge;

const StyledBadge = styled(HorizontalFlexWrap)`
  gap: 24px;
  justify-content: center;
  align-items: center;
  width: calc(100% - 10px);
  position: relative;
  top: 0;
  border-radius: 58px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 8px 16px 8px 6px;
  box-shadow: 0 3px 15px ${({ theme }) => theme.colors.blackBoxShadow};
  flex-wrap: nowrap;
  min-height: 40px;
  margin: 0 5px;

  @media (${SUBTITLE_VISIBLE_BREAKPOINT}) {
    flex-direction: column;
    gap: 10px;
  }
`;

const SubscriptionBadgeCloseButton = styled(Button)`
  width: fit-content;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding: 5px 7.5px;
  border-radius: 24px;
  margin-left: auto;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  grid-column: unset;
  grid-row: unset;

  &:hover {
    background-color: ${({ hoverColor, theme }) => hoverColor || theme.colors.white};
  }
`;

const StyledButtons = styled(HorizontalFlexWrap)`
  gap: 8px;
`;
