import React, { useState } from 'react';
import styled from 'styled-components';
import { Tooltip } from '@material-ui/core';
import ProIcon from 'app/src/images/ProIcon';
import UpgradeButton from 'app/src/complex_components/billings/UpgradeButton';
import { TextSmall, TextTiny } from 'shared/react/components/complex/Text';
import { useBilling } from 'app/src/hooks/useBillings';
import { useAccount } from 'app/src/context/AccountStore';
import UpgradeToProModal from './billings/modal/UpgradeToProModal';

const PricingTooltip = ({
  text,
  header,
  analyticsData,
  placement,
  children,
  marginTop,
  marginBottom,
  shouldHide,
  fullWidthContainer,
  containerWidth,
  requestUserUpgrade = true,
  ...props
}) => {
  const { isFreePackage, isPaymentRoleLight, hidePricingTooltip } = useBilling();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [{ isAccountOwner }] = useAccount();

  if (hidePricingTooltip) {
    return children;
  }

  const getOnButtonClick = () => {
    if (!requestUserUpgrade || !isFreePackage || isAccountOwner || !isPaymentRoleLight) {
      return null;
    }

    return () => setIsModalOpen(true);
  };

  const getTooltipBody = () => {
    if (isModalOpen) {
      return '';
    }

    return (
      <React.Fragment>
        <TooltipContainer>
          <TitleContainer>
            <ProBadgeContainer>
              <ProIcon />
            </ProBadgeContainer>
            <TooltipTitle>{header.toUpperCase()}</TooltipTitle>
          </TitleContainer>
          <TextContainer>
            {text}
            <UpgradeButtonContainer>
              <UpgradeButton onButtonClick={getOnButtonClick()} analyticsData={analyticsData} />
            </UpgradeButtonContainer>
          </TextContainer>
        </TooltipContainer>
      </React.Fragment>
    );
  };

  return (
    <StyledTooltip
      title={getTooltipBody()}
      disableFocusListener
      disableTouchListener
      disableInteractive
      arrow={!isModalOpen}
      interactive
      placement={placement}
      enterDelay={0}
      marginTop={marginTop}
      marginBottom={marginBottom}
      shouldHide={shouldHide}
    >
      <PricingTooltipContainer
        {...props}
        containerWidth={containerWidth}
        fullWidthContainer={fullWidthContainer}
      >
        <>
          <UpgradeToProModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />
          {children}
        </>
      </PricingTooltipContainer>
    </StyledTooltip>
  );
};

const getContainerWidth = ({ fullWidthContainer, containerWidth }) => {
  if (fullWidthContainer) {
    return '100%';
  }

  return containerWidth || '';
};

const ToBeStyledTooltip = ({ className, ...props }) => (
  <Tooltip {...props} classes={{ tooltip: className }} />
);

const StyledTooltip = styled(ToBeStyledTooltip)`
  display: ${({ shouldHide }) => (shouldHide ? 'none' : '')};
  background: ${({ theme }) => theme.colors.gray2};
  padding: 0;
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};

  & .MuiTooltip-arrow::before {
    background: ${({ theme }) => theme.colors.gray2};
  }
`;

export const PricingTooltipContainer = styled.div`
  width: ${getContainerWidth};
`;

const TooltipContainer = styled.div`
  z-index: 100;
  display: flex;
  flex-flow: row wrap;
  padding: 15px 15px 11px;
  border-radius: 5px;
  gap: 8px;
  max-width: 225px;
`;

const TitleContainer = styled.div`
  display: flex;
`;

const TooltipTitle = styled(TextSmall)`
  font-weight: 800;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.white};
`;

const ProBadgeContainer = styled.div`
  margin-right: 8px;
  display: flex;
`;

const TextContainer = styled(TextTiny)`
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
`;

const UpgradeButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 5px;
`;

export default PricingTooltip;
