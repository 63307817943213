import React, { useCallback, useEffect, useRef } from 'react';
import {
  project as projectType,
  publishMethodOptions,
  RuleGroupType,
} from 'app/src/types/entities';
import { useRules } from 'app/src/context/RulesStore';
import {
  MatchConditions,
  VIDEO_RULES,
} from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/constants/dynamicConditions.constants';
import useUpdateDirtyForm from 'app/src/hooks/useUpdateDirtyForm';
import { useBuilderFormContext } from 'app/src/context/BuilderFormStore';
import { useDynamicConditionsContext } from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/context/DynamicConditionsContext';
import { useApps } from 'app/src/context/AppsStore';
import PlaylistsPicker from './components/playlists-picker/PlaylistsPicker';
import { useProjectActions } from 'src/context/ProjectsStore';
import { SHOP_APP_TARGET } from 'src/constants/project.constants';
import useSaveRules from './hooks/useSaveRules';

type Props = {
  project: projectType;
  isProjectProductPage?: boolean;
  isDiscover?: boolean;
  onTagMoreProducts: () => void;
};

const DynamicConditions = ({
  project,
  isProjectProductPage = false,
  isDiscover = false,
  onTagMoreProducts,
}: Props) => {
  const [{ shopify, selectedAppUrl }, { getAppUsingUrl }] = useApps();
  const { publishToShopApp } = useProjectActions();
  const [
    { rules: currentRuleGroup, loading },
    { fetchRules, createDefaultDynamicRule, saveRules: createOrUpdateRules },
  ] = useRules();
  const {
    builderForm: { isDirty: isBuilderDirty, dirtyFormToUpdate },
  } = useBuilderFormContext();
  const isCreatingRuleGroup = useRef(false);
  const saveRules = useSaveRules(project);

  const { methods, matchConditions, rules, setRules } = useDynamicConditionsContext();
  const {
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitting },
  } = methods;

  const isShopAppHomeProject =
    project?.publishMethod === publishMethodOptions.shopApp &&
    project?.targetPage === SHOP_APP_TARGET.homePage;

  const setInitialData = () => {
    if (!currentRuleGroup) {
      const defaultRule = createDefaultDynamicRule();

      reset({ rules: [defaultRule], matchConditions: MatchConditions.matchAny, videoRules: [] });
      return;
    }

    const currentRules = currentRuleGroup?.rules.flatMap(rule => rule);
    const rules = currentRules.filter(({ type }) => !VIDEO_RULES.includes(type));
    const videoRules = currentRules.filter(({ type }) => VIDEO_RULES.includes(type));

    reset({ rules, matchConditions: MatchConditions.matchAny, videoRules: videoRules });
  };

  const onSaveClick = useCallback(async () => {
    await handleSubmit(saveRules)();

    if (isShopAppHomeProject && getAppUsingUrl(selectedAppUrl).isShopAppEligible) {
      await publishToShopApp(project.publishId);
    }

    dirtyFormToUpdate.onSaveClick(project);
  }, [dirtyFormToUpdate.onSaveClick, currentRuleGroup, project]);

  const onDiscardClick = useCallback(() => {
    dirtyFormToUpdate.onDiscardClick();
    setInitialData();
  }, [dirtyFormToUpdate.onDiscardClick, currentRuleGroup, isSubmitting]);

  const fetchOrCreateRules = useCallback(async () => {
    const fetchedRules = await fetchRules(project.id, RuleGroupType.dynamic);

    if (!fetchedRules && !fetchedRules?.error && !isCreatingRuleGroup.current) {
      const rules = shopify ? [createDefaultDynamicRule()] : [];
      isCreatingRuleGroup.current = true;
      await saveRules({ rules, matchConditions, videoRules: [] });
      isCreatingRuleGroup.current = false;
    }
  }, [project]);

  const shouldShowTopBar = isDirty || isBuilderDirty || isSubmitting;

  useUpdateDirtyForm(shouldShowTopBar, {
    ...dirtyFormToUpdate,
    disableHistoryBlocking: true,
    onSaveClick,
    onDiscardClick,
  });

  useEffect(() => {
    if (
      (currentRuleGroup?.projectId === project?.id || !project || loading) &&
      currentRuleGroup?.type != RuleGroupType.bubble
    ) {
      return;
    }

    fetchOrCreateRules();
  }, [currentRuleGroup, project, fetchOrCreateRules]);

  useEffect(setInitialData, [currentRuleGroup]);

  return (
    !isDiscover && (
      <PlaylistsPicker
        rules={rules}
        isProjectProductPage={isProjectProductPage}
        updateRules={setRules}
        onTagMoreProducts={onTagMoreProducts}
      />
    )
  );
};

export default DynamicConditions;
