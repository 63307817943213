import React from 'react';
import { ExternalLink } from 'lucide-react';

interface PlansUsageBillingProps {
  extraFee: number;
  packagePrice: number;
  priceForExtraViews: number;
  onBillingClick: () => void;
}

export const PlansUsageBilling = ({
  extraFee,
  priceForExtraViews,
  packagePrice,
  onBillingClick,
}: PlansUsageBillingProps) => {
  const hasExtraFee = extraFee > 0;

  return (
    <div className="flex-1">
      <div className="flex flex-col gap-3 rounded-2xl bg-white p-4 shadow-lg">
        <div className="flex cursor-pointer items-center gap-2" onClick={onBillingClick}>
          <p className="text-lg font-semibold text-black">Billing</p>
          <ExternalLink className="h-4 w-4 text-blue-600" />
        </div>
        <p className="text-slate-500">
          Your plan has a base cost of ${packagePrice.toLocaleString()}/month, and additional
          impressions are charged at ${priceForExtraViews.toLocaleString()}/1000 views.
          {hasExtraFee && (
            <>
              &nbsp;Your current usage fee for this month is estimated at $
              {extraFee.toLocaleString()}.
            </>
          )}
        </p>
        <p className="text-slate-500">
          Tolstoy calculates usage per calendar month. Read more about how Shopify bills for
          usage-based apps&nbsp;
          <a
            target="_blank"
            rel="noreferrer"
            className="font-medium text-blue-600 underline hover:no-underline dark:text-blue-500"
            href="https://help.shopify.com/en/manual/your-account/manage-billing/your-invoice/apps"
          >
            here
          </a>
          .
        </p>
      </div>
    </div>
  );
};
