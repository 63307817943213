import { WhereCondition } from 'app/src/pages/dashboard/utils/filterEntities';
import { useMemo } from 'react';
import {
  SearchVodAssetsPageResponse,
  SortDirection,
} from 'app/src/pages/dashboard/pages/videos/hooks/search/searchRequests';
import { useInfiniteQuery } from '@tanstack/react-query';
import { postRequest } from 'app/src/helpers/API';
import { compileVodAssetSearchRequest } from 'app/src/pages/dashboard/pages/videos/hooks/search/vodAssetSearchRequestCompiler';
import { useCurrentStoreApp } from 'app/src/context/helper/useCurrentStoreApp';

type UsePaginatedVodAssetsProps = {
  videosFilters: WhereCondition;
  dateSortDirection: SortDirection;
  enabled?: boolean;
};

const ASSETS_PAGE_SIZE = 30;

export const usePaginatedVodAssets = ({
  videosFilters,
  dateSortDirection,
  enabled = true,
}: UsePaginatedVodAssetsProps) => {
  const { appUrl, appKey } = useCurrentStoreApp();

  const searchRequest = compileVodAssetSearchRequest(
    appKey,
    appUrl,
    videosFilters,
    dateSortDirection
  );

  const shouldRunQuery = enabled && !!appKey;

  const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage, refetch } =
    useInfiniteQuery<SearchVodAssetsPageResponse>({
      queryKey: ['searchVodAssets', searchRequest],
      enabled: shouldRunQuery,
      queryFn: async ({ pageParam }) => {
        const request = {
          ...searchRequest,
          size: ASSETS_PAGE_SIZE,
          from: pageParam,
        };
        const data: SearchVodAssetsPageResponse = await postRequest('vod-asset', '/search', {
          body: request,
        });
        return data;
      },
      initialPageParam: 0,
      getNextPageParam: lastPage => {
        const nextFrom = lastPage.from + lastPage.itemsCount;
        const hasNext = nextFrom < lastPage.total;
        return hasNext ? nextFrom : null;
      },
    });

  const vodAssets = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.pages.flatMap(page => page.items);
  }, [data]);

  const loadMore = async () => {
    if (shouldRunQuery && !isLoading && !isFetchingNextPage && hasNextPage) {
      await fetchNextPage();
    }
  };

  const refetchQuery = async () => {
    if (shouldRunQuery) {
      return refetch();
    }
  };

  return {
    videos: vodAssets,
    isLoading: !appKey || isLoading,
    isFetchingNextPage,
    loadMore,
    refetch: refetchQuery,
  };
};
