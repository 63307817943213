import React from 'react';
import styled from 'styled-components';
import Card from 'app/src/basic_components/Card';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

const EmailPreview = ({ emailAssets }) => {
  const getWhiteField = () => {
    return (
      <WhiteField>
        <DimgrayBadge />
        <GrayField />
      </WhiteField>
    );
  };

  return (
    <LayoutRoot>
      <CardContainer disabled>
        <ContentGrayLine>
          {getWhiteField()}
          {getWhiteField()}
          <ImageContainer $centered={emailAssets.length > 1}>
            {emailAssets.map(video => (
              <ImageWrapper key={video.gifUrl}>
                <ImageTag src={video.gifUrl} />
              </ImageWrapper>
            ))}
          </ImageContainer>
        </ContentGrayLine>
      </CardContainer>
    </LayoutRoot>
  );
};

export default EmailPreview;

const LayoutRoot = styled.div`
  display: flex;
  flex-direction: column;

  width: 370px;
  height: 230px;
`;

const CardContainer = styled(Card)`
  grid-row: 1;
  grid-column: 1;
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const GrayLine = styled.div`
  background: ${({ theme }) => theme.colors.gray5};
  border-radius: 8px;
`;

const ContentGrayLine = styled(GrayLine)`
  display: grid;
  height: 100%;
  padding: 16px;
  align-content: flex-start;
  gap: 8px;
`;

const WhiteField = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  height: 16px;
  padding: 5px 4px 5px 8px;
`;

const DimgrayBadge = styled.div`
  background: ${({ theme }) => theme.colors.ghostLight};
  height: 6px;
  width: 24px;
  border-radius: 3px;
`;

const GrayField = styled(GrayLine)`
  width: 124px;
  margin-left: 8px;
`;

const ImageTag = styled.img`
  border-radius: 6px;
  height: 119px;
`;

const ImageWrapper = styled.div`
  height: 100%;
  border-radius: 6px;
`;

const ImageContainer = styled(Gap8HorizontalFlex)`
  justify-content: ${({ $centered }) => ($centered ? 'center' : 'flex-start')};
  height: 119px;
`;
