import React from 'react';

function AccountProfileIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" fill="none" viewBox="0 0 14 16">
      <path
        fill="#50545E"
        d="M8.5 9.5h-3A5.488 5.488 0 000 15c0 .563.438 1 1 1h12c.531 0 1-.438 1-1 0-3.031-2.469-5.5-5.5-5.5zm-7 5a4.021 4.021 0 014-3.5h3a4.014 4.014 0 013.969 3.5H1.5zM7 8c2.188 0 4-1.781 4-4 0-2.188-1.813-4-4-4-2.219 0-4 1.813-4 4 0 2.219 1.781 4 4 4zm0-6.5c1.375 0 2.5 1.125 2.5 2.5 0 1.406-1.125 2.5-2.5 2.5A2.468 2.468 0 014.5 4c0-1.375 1.094-2.5 2.5-2.5z"
      />
    </svg>
  );
}

export default AccountProfileIcon;
