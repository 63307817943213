import React from 'react';
import Cases from 'app/src/pages/dashboard/components/cases/Cases';
import NewFromScratchOffsiteButton from './components/NewFromScratchOffsiteButton';
import Sidebar from './components/sidebar/Sidebar';
import styled from 'styled-components';
import TopBar from '../../components/top-bar/TopBar';
import useNavigation from 'app/src/hooks/useNavigation';
import {
  CASE_TYPES_OFFSITE,
  CASE_TYPE_GUIDES,
} from 'app/src/pages/dashboard/components/cases/cases.constants';
import { FatChevronLeftIcon } from 'app/src/images/sidebar-v2/FatChevronIcon';
import { PageContainer, PageMain } from 'app/src/pages/dashboard/components/page/Page';
import { TextBody } from 'shared/react/components/basic/text/TextV2';
import { useDashboard } from 'app/src/context/ui_store/DashboardStore';
import HorizontalFlex, {
  Gap16HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { useApps } from 'app/src/context/AppsStore';

const CreateCase = () => {
  const { navigateToDashboardV2Offsite } = useNavigation();
  const [{ offsiteFilters }] = useDashboard();
  const { folder } = offsiteFilters;
  const [{ shopify: isShopify }] = useApps();

  const handleBackClick = () => {
    navigateToDashboardV2Offsite((folder?.eq as string) || 'all');
  };

  return (
    <PageContainer>
      <Sidebar />
      <PageMain>
        <TopBar
          leftContent={
            <Header>
              <IconContainer onClick={handleBackClick}>
                <FatChevronLeftIcon />
              </IconContainer>
              <TextBody>Start with a popular use case</TextBody>
            </Header>
          }
          rightContent={<NewFromScratchOffsiteButton />}
        />
        <Cases
          isOffsite
          types={isShopify ? CASE_TYPES_OFFSITE : []}
          guide={isShopify ? CASE_TYPE_GUIDES.offsite : undefined}
        />
      </PageMain>
    </PageContainer>
  );
};

const Header = styled(Gap16HorizontalFlex)`
  align-items: center;
`;

const IconContainer = styled(HorizontalFlex)`
  cursor: pointer;

  path {
    fill: ${({ theme }) => theme.colors.neutralBlack};
  }

  svg {
    width: 12px;
    height: 12px;
  }
`;

export default CreateCase;
