import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import HeartIcon from 'shared/react/images/feed/HeartIcon';
import useWishlist from 'shared/react/hooks/useWishlist';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import useCustomerLogin from 'shared/react/components/complex/context/hooks/useCustomerLogin';
import { CUSTOMER_LOGIN_STATE } from 'shared/react/constants/feature-constants/customerLogin.constants';
import useFeedModal from 'shared/react/components/complex/context/hooks/useFeedModal';
import { WISHLIST_MODAL_KEY } from 'shared/react/constants/modals.constants';
import useIsFenty from 'shared/react/hooks/useIsFenty';
import useTracker from 'shared/react/components/complex/context/hooks/useTracker';
import useFeedState from 'shared/react/components/complex/context/hooks/useFeedState';
import useIsPrincessPolly from 'shared/react/hooks/useIsPrincessPolly';

const FeedLikeButton = ({
  product,
  vodAssetId,
  videoRef,
  backdrop = true,
  className,
  onModalOpenExternal,
  onModalCloseExternal,
}) => {
  const isPrincessPolly = useIsPrincessPolly();
  const [{}, { handleAddProductToWishlist, handleRemoveProductFromWishlist, isProductInWishlist }] =
    useWishlist();
  const [{ setIsWishlistRequestInProcess, isTapcart }] = useFeedState();
  const isFenty = useIsFenty();
  const [{ playerSettings }] = useProjectConfig();

  const hideWishlist = playerSettings?.hideWishlist || isTapcart || false;
  const [, { track }] = useTracker();
  const [, { pushModal }] = useFeedModal();
  const [inWishlist, setInWishlist] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [{ customerLoginState }] = useCustomerLogin();

  const onModalClose = () => {
    if (videoRef) {
      videoRef.current.play();
    }
  };

  const onModalOpen = () => {
    if (videoRef) {
      videoRef.current.pause();
    }
  };

  const pushCustomerModal = () => {
    requestAnimationFrame(() => {
      pushModal({
        key: WISHLIST_MODAL_KEY,
        props: {
          onClose: onModalCloseExternal || onModalClose,
          videoRef,
        },
      });
    });
  };

  const handleOpenCustomerModal = () => {
    if (onModalOpenExternal) {
      onModalOpenExternal();
    } else {
      onModalOpen();
    }
    track('customerLoginModalOpen');
    pushCustomerModal();
  };

  const onClick = async e => {
    e.stopPropagation();
    if (customerLoginState !== CUSTOMER_LOGIN_STATE.LOGGED_IN) {
      handleOpenCustomerModal();
      return;
    }

    if (isAdding) {
      return;
    }

    setIsAdding(true);
    setInWishlist(!inWishlist);
    setIsWishlistRequestInProcess(true);
    if (inWishlist) {
      await handleRemoveProductFromWishlist(product.id);
      track('productRemovedFromWishlist', {
        productId: product.id,
        vodAssetId,
        appKey: product.appKey,
      });
    } else {
      await handleAddProductToWishlist(product.id, vodAssetId, product.appKey);
      track('productAddedToWishlist', {
        productId: product.id,
        vodAssetId,
        appKey: product.appKey,
      });
    }
    setIsWishlistRequestInProcess(false);
    setIsAdding(false);
  };

  if (isPrincessPolly || hideWishlist) {
    return null;
  }

  useEffect(() => {
    setInWishlist(isProductInWishlist(product.id));
  }, [product]);

  if (backdrop && !isFenty) {
    return (
      <FeedViewIconWrapper className={className} $isAdding={isAdding} onClick={onClick}>
        <StyledHeartIcon $isAdding={isAdding} $isWhite={backdrop} $inWishlist={inWishlist} />
      </FeedViewIconWrapper>
    );
  }

  return (
    <ProductViewIconWrapper className={className} $isAdding={isAdding} onClick={onClick}>
      <StyledHeartIcon $isWhite={backdrop && !isFenty} $inWishlist={inWishlist} />
    </ProductViewIconWrapper>
  );
};

const StyledHeartIcon = styled(HeartIcon)`
  width: 18px;
  height: 18px;

  & path {
    transition: stroke 0.24s ease, fill 0.24s ease, stroke-width 0.24s ease;
    stroke: ${props => (props.$isWhite ? props.theme.colors.white : props.theme.colors.black)};
    fill: ${props =>
      props.$inWishlist
        ? props.$isWhite
          ? props.theme.colors.white
          : props.theme.colors.black
        : 'transparent'};

    animation: ${props => (props.$inWishlist ? `icon-path 0.24s ease forwards` : 'none')} @keyframes
      icon-path {
      50% {
        stroke: ${props => (props.$isWhite ? props.theme.colors.white : props.theme.colors.black)};
        fill: transparent;
      }
      100% {
        stroke: ${props => (props.$isWhite ? props.theme.colors.white : props.theme.colors.black)};
        fill: ${props => (props.$isWhite ? props.theme.colors.white : props.theme.colors.black)};
      }
    }
  }
`;

const FeedViewIconWrapper = styled(HorizontalFlexCentered)`
  cursor: ${({ $isAdding }) => ($isAdding ? 'default' : 'pointer')};
  background: ${({ theme }) => theme.getHexOpacity(theme.colors.white, 20)};
  border-radius: 50%;
  backdrop-filter: blur(10px);
  transition: 0.3s;
  min-width: 30px;
  width: 30px;
  height: 30px;

  &:hover {
    background: ${({ theme }) => theme.getHexOpacity(theme.colors.white, 30)};
  }
`;

const ProductViewIconWrapper = styled(HorizontalFlexCentered)`
  cursor: ${({ $isAdding }) => ($isAdding ? 'progress' : 'pointer')};
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  box-shadow: 0 1px 2px rgb(23 23 34 / 12%);
  border: 1px solid rgb(23 23 34 / 12%);
  background: ${({ theme }) => theme.colors.white};
  transition: box-shadow ease 0.12s;
`;

export default FeedLikeButton;
