import React from 'react';

const TolstoyIconName = ({ fill = '#FA536E', width = 131, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 131 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M73.505 0h3.573v18.912h-3.573V0ZM51.643 18.912H47.77V3.881h-5.29V.668h14.535v3.213h-5.372v15.031ZM7.945 5.967V2.131C3.981 3.304 1.078 6.397.244 10.118A7.111 7.111 0 0 0 0 11.97v1.98h.015c.254 3.935 3.722 7.053 7.963 7.053 4.406 0 7.978-3.365 7.978-7.517s-3.573-7.518-7.978-7.518h-.033Zm18.768 11.199h.033v3.836c3.965-1.173 6.866-4.266 7.7-7.988a7.1 7.1 0 0 0 .245-1.85v-1.98h-.015c-.254-3.935-3.723-7.053-7.963-7.053-4.406 0-7.978 3.366-7.978 7.518 0 4.15 3.572 7.517 7.978 7.517Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.477 6.167c-4.254 0-7.09 2.725-7.09 6.476s2.836 6.5 7.09 6.5c4.145 0 7.063-2.672 7.063-6.5 0-3.855-2.918-6.476-7.063-6.476Zm0 9.945c-2.045 0-3.463-1.516-3.463-3.47 0-1.978 1.39-3.469 3.463-3.469 1.964 0 3.436 1.413 3.436 3.47 0 2.056-1.527 3.469-3.436 3.469Z"
        fill={fill}
      />
      <path
        d="M85.107 19.144c3.49 0 5.153-1.696 5.153-3.906 0-2.492-1.69-3.084-4.58-4.085-1.31-.463-1.965-.823-1.965-1.337 0-.668.71-.924 1.255-.924.981 0 2.182.411 3.191 1.336l1.745-2.493c-1.527-1.104-3.026-1.568-4.853-1.568-2.755 0-4.826 1.465-4.826 3.726 0 2.441 1.908 3.418 4.118 4.112 1.718.539 2.399.822 2.399 1.362 0 .617-.572 1.027-1.609 1.027-1.473 0-2.617-.488-3.709-1.439l-1.718 2.34c1.445 1.18 3.272 1.849 5.399 1.849Zm11.953 0c1.799 0 2.67-.36 3.517-.797l-.9-2.698c-.6.283-1.254.437-1.69.437-.846 0-1.146-.513-1.146-1.619V9.148h3.6v-2.8h-3.6V1.9l-3.573.36v4.086h-1.58v2.8h1.58v5.808c0 2.827 1.501 4.189 3.791 4.189Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.696 6.167c-4.254 0-7.09 2.725-7.09 6.476s2.836 6.5 7.09 6.5c4.145 0 7.062-2.672 7.062-6.5 0-3.855-2.917-6.476-7.062-6.476Zm0 9.945c-2.045 0-3.463-1.516-3.463-3.47 0-1.978 1.39-3.469 3.463-3.469 1.964 0 3.436 1.413 3.436 3.47 0 2.056-1.527 3.469-3.436 3.469Z"
        fill={fill}
      />
      <path
        d="M119.289 24h3.627l7.172-17.653h-3.627l-3.027 7.709h-.054l-3.245-7.709h-3.818l5.345 11.794L119.289 24Z"
        fill={fill}
      />
    </svg>
  );
};

export default TolstoyIconName;
