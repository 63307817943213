import React, { useRef } from 'react';
import ChoiceButton from '../../../../../shared/react/components/complex/ChoiceButton';
import ImageIconV2 from '../../../../../shared/react/images/ImageIconV2';
import { getShouldContrastColor } from '../../../../../shared/react/utils/color';

function FileUploadAnswerButton({
  id,
  onClick,
  onImageSelected,
  hasSubtitles,
  index,
  customLayout,
  dataTestId,
  disabled,
  brandColor,
  text,
  ...props
}) {
  const inputRef = useRef(null);

  function onFilesSelected(e) {
    const file = e.target.files[0];
    onImageSelected(file);
    onClick();
  }

  function handleClick() {
    inputRef.current.click();
  }

  const darkBackground = getShouldContrastColor(brandColor);
  return (
    <>
      <ChoiceButton
        hasSubtitles={hasSubtitles}
        index={index}
        icon={<ImageIconV2 fill={brandColor} darkBackground={darkBackground} />}
        text={text}
        onClick={handleClick}
        inputRef={inputRef}
        customLayout={customLayout}
        dataTestId={dataTestId}
        disabled={disabled}
        {...props}
      />

      <input
        ref={inputRef}
        id={id}
        style={{ display: 'none' }}
        onChange={onFilesSelected}
        type="file"
        accept="image/*,application/pdf"
      />
    </>
  );
}

export default FileUploadAnswerButton;
