import {
  EMBED_WIDGETS_CLASSNAMES,
  WIDGET_SCRIPT_PARAMETERS
} from '../constants/widgetConstants.js';
import { WIDGET_DOMAIN } from '../config/widget.config.js';

// Backward compatibility for old widget scripts
const getParameterByUrlParams = (script, parameterName) => {
  const source = script.src;
  const url = new URL(source);
  return url.searchParams.get(parameterName);
};

// Support for Module scripts
const getParameterByAttribute = (script, parameterName) => {
  return script.getAttribute(`data-${parameterName}`);
};

export const getParameterFromWidgetScripts = parameterName => {
  const scripts = document.querySelectorAll(`script[src*="${WIDGET_DOMAIN}"]`);

  for (const script of scripts) {
    const value =
      getParameterByAttribute(script, parameterName) ||
      getParameterByUrlParams(script, parameterName);

    if (value) {
      return value;
    }
  }
};

export const getEmbedProductId = element => {
  const productId = element?.dataset?.productId;

  if (productId === '{{ product.id }}') {
    return null;
  }

  return productId;
};

export const getCurrentPageProductId = () => {
  /**
   * Check if the product ID is passed as a parameter on the widget script
   */
  const widgetScriptProductId = getParameterFromWidgetScripts(WIDGET_SCRIPT_PARAMETERS.productId);
  if (widgetScriptProductId) {
    return widgetScriptProductId;
  }

  /**
   * Check if the product ID is passed as a parameter on an embedded widget
   */
  const { stories, carousel, embed, tile } = EMBED_WIDGETS_CLASSNAMES;
  const elements = document.querySelectorAll(`.${stories}, .${carousel}, .${embed}, .${tile}`);

  for (const element of elements) {
    const productId = getEmbedProductId(element);

    if (productId) {
      return productId;
    }
  }
};
