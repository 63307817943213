import { CircularProgress } from '@material-ui/core';
import { REFERRAL_COOKIE } from 'app/src/constants/billings.constants';
import {
  HAS_SHOPIFY_INSTALLED,
  SHOPIFY,
  SHOPIFY_INSTALLATION_FAILED,
  STORE_CONNECTED_TO_ANOTHER_ACCOUNT,
} from 'app/src/constants/intergrations.constants';
import { AUTOMATION_TOOL_SALESLOFT } from 'app/src/constants/share.constants';
import {
  APP_KEY_LOCAL_STORAGE_KEY,
  DELAY_FETCH_FEATURES_QUERY_PARAM,
  FIRST_CSM_WIDGET_LOCAL_STORAGE_KEY,
  FIRST_CSM_WIDGET_QUERY_PARAM,
  GHOST_ACCOUNT_LOCAL_STORAGE_KEY,
  GHOST_ACCOUNT_QUERY_PARAM,
  LOGIN_APP_KEY_LOCAL_STORAGE_KEY,
  NON_QUALIFIED_EMAILS,
  PAYMENT_SUCCESS_QUERY_PARAM,
  TEMP_ID_LOCAL_STORAGE_KEY,
  TEMP_ID_QUERY_PARAM,
} from 'app/src/constants/tolstoy.constants';
import { REDIRECT_PATH_SESSION_STORAGE_KEY } from 'app/src/constants/ui.constants';
import { useAppActions } from 'app/src/context/AppsStore';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import { useUnauthenticatedStateActions } from 'app/src/context/UnauthenticatedStateStore';
import { useExtensionActions } from 'app/src/context/ui_store/ExtensionStore';
import { useUserActions } from 'app/src/context/userStore/UserStore';
import useNavigation from 'app/src/hooks/useNavigation';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FEATURE_CUSTOM_SIDE_BAR } from 'shared/react/constants/features.constants';
import styled from 'styled-components';
import Routes from '../../helpers/Routes';
import { track } from '../../helpers/Tracker';
import { identify } from '../../helpers/Widget';
import useQuery from '../../hooks/useQuery';
import Utils from '../../utils';
import { IS_DEV } from 'app/src/config/app.config';

function Root() {
  const history = useHistory();
  const query = useQuery();
  const { toggleExtensionInstallModal } = useExtensionActions();
  const { getUser, fetch: fetchUser, updateUser, updateAccountSettings } = useUserActions();
  const { fetchFeatures, setIsExpectingDelayedFetch, getSettingsByKey } = useFeatureActions();
  const { completeShopifyAuth, fetch: fetchApps } = useAppActions();
  const { getQueryParamByKey } = useUnauthenticatedStateActions();
  const [loading, setLoading] = useState();
  const [delayFetchFeaturesTimeoutId, setDelayFetchFeaturesTimeoutId] = useState(null);
  const hasAlreadyInstalledShopify = query.get(HAS_SHOPIFY_INSTALLED);
  const storeConnectedToAnotherAccount = query.get(STORE_CONNECTED_TO_ANOTHER_ACCOUNT);
  const {
    navigateToDashboardV2Onsite,
    navigateToDashboardLibrary,
    navigateToDashboardV2Analytics,
    navigateToDashboardV2Threads,
    navigateToDashboardV2Offsite,
    navigateToShopApp,
  } = useNavigation();

  const redirectToDashboard = () => {
    const sessionRedirectPath = sessionStorage.getItem(REDIRECT_PATH_SESSION_STORAGE_KEY);
    const pathname = sessionRedirectPath || Routes.getDashboardLibraryRoute('');
    sessionStorage.removeItem(REDIRECT_PATH_SESSION_STORAGE_KEY);

    history.replace({
      pathname,
    });
  };

  const onSignUp = async user => {
    track('Signup Successful');
    // Google Tag Manager
    if (NON_QUALIFIED_EMAILS.some(email => user.email.includes(email))) {
      window.dataLayer?.push({ event: 'sign_up_non_qualified' });
    } else {
      window.dataLayer?.push({ event: 'sign_up' });
    }
    // Referral tracking for First Promoter
    await referralSignupTracking(user);
    // Tolstoy Widget identified users
    await identify(user, { projectsLength: 0, hasShared: false });

    fetchApps();
    const viewedTolstoys = Utils.getCookieValue('tolstoy-viewed').split(',');
    await updateUser({ id: user.id, hasLoggedIn: true, viewedTolstoys });

    redirectToDashboard();
  };

  const referralSignupTracking = async ({ email, appKey }) => {
    if (IS_DEV) {
      return;
    }
    if (Utils.getCookieValue('tolstoy_gclid')) {
      return;
    }
    const referral = Utils.getCookieValue(REFERRAL_COOKIE);
    if (!referral) {
      return;
    }
    window.fpr('referral', { email, uid: appKey });
    await updateAccountSettings({ referral, appKey });
  };

  const getIntegrationData = () => {
    let integration = localStorage.getItem('integration') || query.get('integration');
    const integrationToken = localStorage.getItem('integrationToken') || query.get('token');
    const isExtensionInstall = query.get('extensionInstall');
    const shopifyShop = localStorage.getItem('shopifyShop') || query.get('shop');
    const urlParams = localStorage.getItem('urlParams') || window.location.search;

    if (isExtensionInstall) {
      toggleExtensionInstallModal(true);
    }

    if (!integration && query.get('return_to')) {
      integration = AUTOMATION_TOOL_SALESLOFT;
    }
    return { integration, integrationToken, shopifyShop, urlParams };
  };

  const setGhostAccount = () => {
    const ghostAccount = !!query.get(GHOST_ACCOUNT_QUERY_PARAM);
    if (ghostAccount) {
      localStorage.setItem(GHOST_ACCOUNT_LOCAL_STORAGE_KEY, ghostAccount);
    }
    return ghostAccount;
  };

  const clearIntegrationRelatedDataFromLocalStorage = () => {
    localStorage.removeItem('integration');
    localStorage.removeItem('integrationToken');
    localStorage.removeItem('integrationReturnTo');
    localStorage.removeItem('shopifyShop');
    localStorage.removeItem('urlParams');
  };

  const redirectToIntegration = (integration, integrationToken, user) => {
    setLoading(false);
    clearIntegrationRelatedDataFromLocalStorage();
    const { appKey, email, owner: userId } = user;
    Utils.moveToIntegration(integration, email, integrationToken, userId, appKey);
  };

  const googleTagManagerPurchaseEvent = ({ appKey }) => {
    window.dataLayer.push({
      event: 'purchase',
      appKey,
      package: query.get('name') || 'Pro package',
      price: query.get('price') || '19',
    });
  };

  const handleFinishShopifyAuth = async ({ user }) => {
    const { appKey } = user;
    const queryTempId = getQueryParamByKey(TEMP_ID_QUERY_PARAM);
    const localStorageTempId = localStorage.getItem(TEMP_ID_LOCAL_STORAGE_KEY);
    const currentAppKey = Utils.getAppKey();

    return completeShopifyAuth({
      tempId: queryTempId || localStorageTempId,
      appKey: currentAppKey || appKey,
    });
  };

  useEffect(() => {
    const checkUser = async () => {
      setLoading(true);
      if (query.get(FIRST_CSM_WIDGET_QUERY_PARAM)) {
        localStorage.setItem(FIRST_CSM_WIDGET_LOCAL_STORAGE_KEY, true);
      }

      const appKey = query.get(APP_KEY_LOCAL_STORAGE_KEY);
      if (appKey) {
        localStorage.setItem(LOGIN_APP_KEY_LOCAL_STORAGE_KEY, appKey);
      }

      const tempId = query.get(TEMP_ID_QUERY_PARAM);
      if (tempId) {
        localStorage.setItem(TEMP_ID_LOCAL_STORAGE_KEY, tempId);
      }

      if (hasAlreadyInstalledShopify) {
        localStorage.setItem(HAS_SHOPIFY_INSTALLED, 'true');
      }

      if (storeConnectedToAnotherAccount) {
        localStorage.setItem(STORE_CONNECTED_TO_ANOTHER_ACCOUNT, 'true');
      }

      const ghostAccount = setGhostAccount();

      const { integration, integrationToken, shopifyShop, urlParams } = getIntegrationData();

      const integrationRoute = Utils.getIntegrationRoute({
        path: Routes.getSignupRoute(),
        integration,
        integrationToken,
        shopifyShop,
        urlParams,
      });

      try {
        const userData = await getUser();
        if (!userData) {
          setLoading(false);
          history.replace(integrationRoute, {
            ghostAccount,
          });
          return;
        }

        const { user } = await fetchUser();

        if (query.get(PAYMENT_SUCCESS_QUERY_PARAM)) {
          googleTagManagerPurchaseEvent(user);
        }

        if (query.get(DELAY_FETCH_FEATURES_QUERY_PARAM)) {
          setIsExpectingDelayedFetch(true);
          const timeoutId = setTimeout(async () => {
            await fetchFeatures(user.appKey);
            setIsExpectingDelayedFetch(false);
          }, 10000);
          setDelayFetchFeaturesTimeoutId(timeoutId);
        }

        if (integration === SHOPIFY) {
          const { storeConnectedToAnotherAccount, errorMessage } = await handleFinishShopifyAuth({
            user,
            urlParams,
          });
          clearIntegrationRelatedDataFromLocalStorage();
          if (storeConnectedToAnotherAccount) {
            localStorage.setItem(STORE_CONNECTED_TO_ANOTHER_ACCOUNT, 'true');
          } else if (errorMessage) {
            localStorage.setItem(SHOPIFY_INSTALLATION_FAILED, 'true');
          } else {
            sessionStorage.setItem(
              REDIRECT_PATH_SESSION_STORAGE_KEY,
              Routes.getOnboardingTemplatesRoute()
            );
          }
        } else if (integration) {
          await redirectToIntegration(integration, integrationToken, user);
          return;
        }

        setLoading(false);
        if (user && !user.hasLoggedIn) {
          await onSignUp(user);
        } else {
          const sessionRedirectPath = sessionStorage.getItem(REDIRECT_PATH_SESSION_STORAGE_KEY);
          const pathname = sessionRedirectPath || Routes.getOnboardingTemplatesRoute();
          const customSidebarOptions = getSettingsByKey(FEATURE_CUSTOM_SIDE_BAR);

          if (customSidebarOptions?.methods && !sessionRedirectPath) {
            const routes = {
              videos: navigateToDashboardLibrary,
              onsite: () => navigateToDashboardV2Onsite(),
              shopApp: () => navigateToShopApp(),
              offsite: () => navigateToDashboardV2Offsite(),
              threads: navigateToDashboardV2Threads,
              analytics: navigateToDashboardV2Analytics,
            };

            const currentPage =
              routes[customSidebarOptions?.methods[0]] || navigateToDashboardLibrary;

            currentPage();
            return;
          }

          sessionStorage.removeItem(REDIRECT_PATH_SESSION_STORAGE_KEY);

          history.replace({
            pathname,
            state: {
              ghostAccount,
            },
          });
        }
      } catch (err) {
        setLoading(false);
        history.replace(integrationRoute);
      }
    };
    checkUser();

    return () => {
      setIsExpectingDelayedFetch(false);
      clearTimeout(delayFetchFeaturesTimeoutId);
    };
  }, [history]);

  if (loading) {
    return (
      <LoadingContainer>
        <CircularProgress size={24} />
      </LoadingContainer>
    );
  }

  return <div />;
}

export default Root;

const LoadingContainer = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  place-content: center;
`;
