import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { TextBodyLeadingBold } from 'shared/react/components/complex/Text';
import EditCustomButtons from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/custom/EditCustomButtons';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Separator from 'shared/react/components/basic/Separator';
import Gap16HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap16HorizontalFlexWrap';
import Button from 'shared/react/components/complex/Button';
import Types from 'shared/react/theme/Types';
import Sizes from 'shared/react/theme/Sizes';
import ExpanderBottomArrow from 'app/src/images/ExpanderBottomArrow';
import AnswersTypeMenu from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/AnswersTypeMenu';
import { NEXT, TABS, TYPE } from 'app/src/constants/editStep.constants';
import DefaultButtons from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/default/DefaultButtons';
import NextMenuItems from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/NextMenuItems';
import { DEFAULT_RESPONSE_OVERLAY_TEXT } from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/editStepDefaultData';
import { useFormContext } from 'react-hook-form';
import {
  EDIT_BUTTONS_TEST_ID,
  EDIT_BUTTONS_TYPE_TEST_ID,
} from 'app/src/constants/dataTestIds.constants';
import EditFeedSidePanel from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/feed/EditFeedSidePanel';
import { SHOPABLE_SIDE_PANEL_TYPE } from 'shared/react/constants/feed.constants';
import {
  ANSWER_NEXT_WITH_VALUE,
  ANSWER_TYPES_WITH_VALUE,
} from 'app/src/constants/tolstoy.constants';
import { SAME_TAB_SUFFIX } from 'shared/react/constants/playerSettings.constants';
import { URL_KEY } from 'app/src/constants/ruleGroups.constants';
import InvisibleDiv from 'app/src/basic_components/InvisibleDiv';
import { useApps } from 'app/src/context/AppsStore';
import ProductEditor from './feed/ProductEditor';

const EditButtons = ({
  project,
  isLoading,
  step,
  isLoadingProducts,
  setLoadingProducts,
  isProductResultStep,
}) => {
  const { setValue, watch, clearErrors, reply, isFetchByUrlFeatureEnabled } = useFormContext();
  const [, { isEcomPlatformConnected }] = useApps();
  const [
    answers,
    stepType,
    defaultResponsesEnabled,
    defaultResponses,
    overlayText,
    multipleChoiceSelection,
    feedSidePanelType,
  ] = watch([
    'answers',
    'type',
    'defaultResponsesEnabled',
    'defaultResponses',
    'overlayText',
    'multipleChoiceSelection',
    'feedSidePanelType',
  ]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [answerAnchorEl, setAnswerAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const selectedAnswer = defaultResponsesEnabled
    ? defaultResponses[selectedIndex]
    : answers[selectedIndex];
  const scrollRef = useRef();
  const isFeed = project.feed;
  const [hasNoPermissions, setHasNoPermissions] = useState(false);

  const onChooseButtonsType = e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const onChooseAnswerNext = (e, selectedIndex) => {
    e.stopPropagation();
    setAnswerAnchorEl(e.currentTarget);
    setSelectedIndex(selectedIndex);
  };

  function setDefaultTabOverlayText(tab) {
    if (overlayText === step.overlayText) {
      return step.overlayText;
    }

    if (tab === 'default') {
      return DEFAULT_RESPONSE_OVERLAY_TEXT;
    }
    return step.overlayText || '';
  }

  const onChangeSelectedTab = key => {
    setValue('overlayText', setDefaultTabOverlayText(key));
  };

  const setAnswers = answers => {
    if (defaultResponsesEnabled) {
      setValue('defaultResponses', answers);
      return;
    }
    setValue('answers', answers);
  };

  const setStepType = type => {
    setValue(TYPE, type);
  };

  const setDefaultResponsesEnabled = type => {
    setValue('defaultResponsesEnabled', type);
  };

  const getCurrentAnswers = () => {
    const currentAnswers = defaultResponsesEnabled ? defaultResponses : answers;
    return currentAnswers;
  };

  const checkResetWhenMovingBetweenNextToType = (property, value, answer) => {
    if (
      property === TYPE &&
      ANSWER_TYPES_WITH_VALUE.includes(value) &&
      ANSWER_NEXT_WITH_VALUE.includes(answer.next)
    ) {
      return true;
    }

    if (
      property === NEXT &&
      ANSWER_NEXT_WITH_VALUE.includes(value) &&
      ANSWER_TYPES_WITH_VALUE.includes(answer.type)
    ) {
      return true;
    }

    return false;
  };

  const checkResetWhenChangingTheSameProperty = (property, value, previousValue) => {
    if (
      property === NEXT &&
      ANSWER_NEXT_WITH_VALUE.includes(value) &&
      ANSWER_NEXT_WITH_VALUE.includes(previousValue)
    ) {
      return true;
    }

    if (
      property === TYPE &&
      ANSWER_TYPES_WITH_VALUE.includes(value) &&
      ANSWER_TYPES_WITH_VALUE.includes(previousValue)
    ) {
      return true;
    }

    return false;
  };

  const checkIfOnlyCheckboxChanged = (property, value, previousValue) => {
    return (
      property === NEXT &&
      ((value === URL_KEY && previousValue === URL_KEY + SAME_TAB_SUFFIX) ||
        value === URL_KEY + SAME_TAB_SUFFIX)
    );
  };

  const shouldResetValue = (property, value, answer, previousValue) => {
    if (checkIfOnlyCheckboxChanged(property, value, previousValue)) {
      return false;
    }

    return (
      checkResetWhenMovingBetweenNextToType(property, value, answer) ||
      checkResetWhenChangingTheSameProperty(property, value, previousValue)
    );
  };

  const handleAnswerChanged = ({ value, index, property }) => {
    const list = [...getCurrentAnswers()];

    const shouldChangeAllNextAnswers =
      multipleChoiceSelection && property === NEXT && stepType === TABS.custom;

    if (shouldChangeAllNextAnswers) {
      list.forEach(answer => {
        answer.next = value;
      });
    }

    const previousValue = list[index][property];
    list[index][property] = value;
    clearErrors(`${defaultResponsesEnabled ? 'defaultResponses' : 'answers'}[${index}]`);

    if (shouldResetValue(property, value, list[index], previousValue)) {
      list[index].value = '';
    }

    setAnswers(list);
  };

  const onModalPropertyChanged = (value, property) => {
    handleAnswerChanged({ value, index: selectedIndex, property });
  };

  const getHeaderButtonText = () => {
    if (!isFeed) {
      if (stepType === 'products') {
        return TABS.products;
      }
      return stepType;
    }

    if (feedSidePanelType) {
      return feedSidePanelType;
    }

    return SHOPABLE_SIDE_PANEL_TYPE;
  };

  const getHeaderText = () => {
    if (isFeed) {
      return 'Video Card';
    }

    if (isProductResultStep) {
      return 'Edit products';
    }

    return 'Edit buttons';
  };

  const shouldShowAnswerTypeButton =
    (isFetchByUrlFeatureEnabled || !isFeed || isEcomPlatformConnected()) && !isProductResultStep;

  const getHeader = () => {
    if (!shouldShowAnswerTypeButton) {
      return '';
    }

    return (
      <HeaderContainer>
        <Header data-test-id={EDIT_BUTTONS_TEST_ID}>{getHeaderText()}</Header>
        <ButtonContainer>
          <Button
            disabled={!!isLoading}
            size={Sizes.Small}
            padding="15px 10px"
            type={Types.Ghost}
            transparentBackground={true}
            onClick={onChooseButtonsType}
            borderColorType={() => 'transparent'}
            rightIcon={<ExpanderBottomArrow />}
            showRightIcon={true}
            data-test-id={EDIT_BUTTONS_TYPE_TEST_ID}
          >
            {getHeaderButtonText()}
          </Button>
        </ButtonContainer>
      </HeaderContainer>
    );
  };

  const getContent = () => {
    if (isFeed) {
      return (
        <EditFeedSidePanel
          project={project}
          isLoadingProducts={isLoadingProducts}
          setLoadingProducts={setLoadingProducts}
          hasNoPermissions={hasNoPermissions}
          setHasNoPermissions={setHasNoPermissions}
        />
      );
    }

    if (isProductResultStep) {
      return (
        <ProductEditor
          project={project}
          isLoadingProducts={isLoadingProducts}
          setLoadingProducts={setLoadingProducts}
          isProductResultStep={isProductResultStep}
          hasNoPermissions={hasNoPermissions}
          setHasNoPermissions={setHasNoPermissions}
        />
      );
    }

    if (stepType === TABS.default) {
      return (
        <DefaultButtons
          project={project}
          answers={defaultResponses}
          onChooseAnswerNext={onChooseAnswerNext}
        />
      );
    }

    if (stepType === TABS.products || stepType === 'products') {
      return (
        <ProductEditor
          project={project}
          isLoadingProducts={isLoadingProducts}
          setLoadingProducts={setLoadingProducts}
          hasNoPermissions={hasNoPermissions}
          setHasNoPermissions={setHasNoPermissions}
        />
      );
    }

    return (
      <EditCustomButtons
        answers={answers}
        project={project}
        setAnswers={setAnswers}
        isLoading={isLoading}
        defaultResponsesEnabled={defaultResponsesEnabled}
        onChooseAnswerNext={onChooseAnswerNext}
        setSelectedIndex={setSelectedIndex}
        handleAnswerChanged={handleAnswerChanged}
        onModalPropertyChanged={onModalPropertyChanged}
        selectedAnswer={selectedAnswer}
        multipleChoiceSelection={multipleChoiceSelection}
      />
    );
  };

  return (
    <>
      <Separator />
      <LayoutRoot>
        <AccordionContent>
          {!isProductResultStep && getHeader()}
          <Content>{getContent()}</Content>
        </AccordionContent>
        <InvisibleDiv ref={scrollRef} />
      </LayoutRoot>
      <AnswersTypeMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        setSelectedTab={onChangeSelectedTab}
        setStepType={setStepType}
        setDefaultResponsesEnabled={setDefaultResponsesEnabled}
        isFeed={isFeed}
      />
      <NextMenuItems
        anchorEl={answerAnchorEl}
        setAnchorEl={setAnswerAnchorEl}
        answer={selectedAnswer}
        project={project}
        reply={reply}
        onModalPropertyChanged={onModalPropertyChanged}
        step={step}
      />
    </>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  overflow: auto;
  height: 100%;
`;

const Header = styled(TextBodyLeadingBold)``;

const HeaderContainer = styled(Gap16HorizontalFlexWrap)`
  align-items: center;
  padding: 16px 24px 0;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    padding: 16px 16px 0;
  }
`;

const ButtonContainer = styled.div`
  background: ${({ theme }) => theme.colors.gray3};
  border-radius: 46px;
  overflow: hidden;

  & div:first-letter {
    text-transform: uppercase;
  }
`;

const AccordionContent = styled(VerticalFlex)`
  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    padding: 0 16px 0;
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
`;

export default EditButtons;
