import React from 'react';
import styled from 'styled-components';

const OutlinedAccountIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 3.5c1.5 0 2.75 1.25 2.75 2.75C10.75 7.781 9.5 9 8 9a2.734 2.734 0 0 1-2.75-2.75C5.25 4.75 6.469 3.5 8 3.5Zm0 4c.688 0 1.25-.531 1.25-1.25C9.25 5.562 8.687 5 8 5c-.719 0-1.25.563-1.25 1.25 0 .719.531 1.25 1.25 1.25ZM8 0c4.406 0 8 3.594 8 8 0 4.438-3.594 8-8 8-4.438 0-8-3.563-8-8 0-4.406 3.563-8 8-8Zm0 14.5a6.479 6.479 0 0 0 3.875-1.281c-.531-1.031-1.594-1.719-2.781-1.719H6.875c-1.188 0-2.25.656-2.781 1.719A6.503 6.503 0 0 0 8 14.5Zm5-2.344A6.445 6.445 0 0 0 14.5 8c0-3.563-2.938-6.5-6.5-6.5-3.594 0-6.5 2.938-6.5 6.5 0 1.594.531 3.031 1.469 4.156C3.813 10.844 5.25 10 6.875 10h2.219c1.625 0 3.062.844 3.906 2.156Z"
        fill="#7D8087"
      />
    </svg>
  );
};

export default OutlinedAccountIcon;
