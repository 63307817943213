import { getTolstoyRecreateResolution, setTolstoyRecreateResolution } from './cache.js';
import { createResolutions } from './requests.js';

const IS_ACTIVE_MIGRATION = false;

export const reCreateResolutions = async publishId => {
  if (!IS_ACTIVE_MIGRATION) {
    return;
  }

  if (!getTolstoyRecreateResolution(publishId)) {
    await createResolutions(publishId);
    setTolstoyRecreateResolution(publishId);
  }
};
