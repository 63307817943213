import React from 'react';
import styled, { css } from 'styled-components';
import { useFeedIsMobile } from 'shared/react/hooks/useIsMobile';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import StartScreenPlayButton from 'player/src/components/player/StartScreenPlayButton';
import FeedNavigation from 'shared/react/components/complex/feed/feed_overlay/FeedNavigation';
import ComponentWithOverlayLoader from 'shared/react/components/basic/ComponentWithOverlayLoader';
import FeedSwipeUpForMoreWrapper from 'player/src/components/feed/feed_overlay/FeedSwipeUpForMoreWrapper';
import UnmuteAutoplayButtonWrapper from 'player/src/components/player/video-overlay/UnmuteAutoplayButtonWrapper';
import Utils from 'player/src/utils';

// create a new stacking context for Safari
// https://linear.app/tolstoy/issue/RND-6600/fix-some-controls-appear-behind-the-video-on-the-first-second-on
const FORCE_NEW_STACKING_CONTEXT_OPACITY = Utils.isSafari() || Utils.isIphone() ? 0.99 : '';

const FeedOverlay = ({
  videoRef,
  audioRef,
  handleNavigateToStep,
  isCurrentVideo,
  nextStepName,
  handlePlayPause,
  isPlaying,
  step,
  numOfSteps,
  isFeed,
  isLoadingVideo,
  currentCaptions,
  isPlayerPreview,
  scrollLeft,
  scrollRight,
}) => {
  const isMobile = useFeedIsMobile();

  if (!isCurrentVideo) {
    return null;
  }

  const isShowLoader = isLoadingVideo && isPlaying;
  const shouldShowGradient = isMobile && (step.products?.length || step.videoDescription);

  return (
    <LayoutRoot
      isFeed={isFeed}
      numOfSteps={numOfSteps}
      shouldShowGradient={shouldShowGradient}
      role="group"
      aria-label="Video controls"
      aria-owns="navigationArrows"
    >
      <BackdropCenteredAbsoluteContainer isHidden={isPlayerPreview}>
        <UnmuteAutoplayButtonWrapper />
        <FeedSwipeUpForMoreWrapper isLoading={isShowLoader} />
      </BackdropCenteredAbsoluteContainer>
      <Content isLoading={isShowLoader}>
        <CenteredAbsoluteContainer>
          <StyledStartScreenPlayButton
            hidePlay={isPlaying}
            onClick={handlePlayPause}
            showStartScreen={true}
          />
        </CenteredAbsoluteContainer>
        <FeedNavigation
          handleNavigateToStep={handleNavigateToStep}
          nextStepName={nextStepName}
          videoRef={videoRef}
          audioRef={audioRef}
          step={step}
          handlePlayPause={handlePlayPause}
          currentCaptions={currentCaptions}
          scrollLeft={scrollLeft}
          scrollRight={scrollRight}
        />
      </Content>
    </LayoutRoot>
  );
};

const productsGradient = css`
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 55%,
    ${({ theme }) => theme.getHexOpacity(theme.colors.black, 45)} 100%
  );
`;

const LayoutRoot = styled.div`
  position: absolute;
  z-index: ${({ isFeed, numOfSteps }) => (isFeed && numOfSteps === 1 ? 3 : 1)};
  width: 100%;
  height: 100%;
  pointer-events: none;
  top: 0;
  box-sizing: border-box;
  ${({ shouldShowGradient }) => (shouldShowGradient ? productsGradient : '')};
  opacity: ${FORCE_NEW_STACKING_CONTEXT_OPACITY};

  & [role='button'] {
    pointer-events: auto !important;
    -webkit-tap-highlight-color: transparent;
  }
`;

const Content = styled(ComponentWithOverlayLoader)`
  gap: 36px;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  position: relative;
  align-items: flex-end;
  pointer-events: none;

  & > * {
    pointer-events: auto;
  }
`;

const CenteredAbsoluteContainer = styled(VerticalFlex)`
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
`;

const BackdropCenteredAbsoluteContainer = styled(CenteredAbsoluteContainer)`
  height: 100%;
  z-index: 2;
  background: ${({ theme }) => theme.getHexOpacity(theme.colors.black, 40)};
  gap: 160px;
  display: ${({ isHidden }) => (isHidden ? 'none' : '')};

  :empty {
    display: none;
  }
`;

const StyledStartScreenPlayButton = styled(StartScreenPlayButton)`
  display: flex;
  transition: 0.2s;
  pointer-events: auto;
`;

export default FeedOverlay;
