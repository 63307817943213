import React, { FC } from 'react';
import styled from 'styled-components';

import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextH3Bold } from 'shared/react/components/basic/text/TextV2';
import { TextSmall } from 'shared/react/components/complex/Text';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import Step1Requirements from './Step1Requirements';
import Step2ImportFile from './Step2ImportFile';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';

const ImportCsv: FC = () => {
  return (
    <Container>
      <Header>
        <TextH3Bold>Upload from CSV</TextH3Bold>
        <OptionalMark>(Optional)</OptionalMark>
      </Header>

      <VerticalFlex>
        <Step1Requirements />
        <Step2ImportFile />
      </VerticalFlex>
    </Container>
  );
};

const Container = styled(Gap16VerticalFlex)``;

const Header = styled(Gap8HorizontalFlex)`
  align-items: baseline;
`;

const OptionalMark = styled(TextSmall)`
  color: ${props => props.theme.colors.neutralDark};
`;

export default ImportCsv;
