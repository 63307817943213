import styled from 'styled-components';
import HorizontalFlex, {
  HorizontalFlexCentered,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import UserBox from 'src/basic_components/UserBox';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import MenuItemV3, { smallMenuItemCss } from 'app/src/complex_components/menu_items/MenuItemV3';

export const FilterBlockDefault = styled(HorizontalFlexCentered)`
  height: 32px;
  padding: 8px;
  gap: 4px;
  font-size: 12px;
  user-select: none;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;

export const FilterDashedBlock = styled(FilterBlockDefault)`
  border: 1px dashed ${({ theme }) => theme.colors.neutralLight};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.neutralDark};
  font-weight: 500;

  svg {
    width: 11px;
    height: 11px;
    pointer-events: none;
  }

  path {
    fill: ${({ theme }) => theme.colors.neutralDark};
  }
`;

export const FilterBlock = styled(FilterBlockDefault)`
  gap: 6px;
  background: ${({ theme }) => theme.colors.neutralLightest};
  color: ${({ theme }) => theme.colors.neutralDarkest};
  font-weight: 600;
`;

export const FilterName = styled(FilterBlock)`
  & svg {
    width: 14px;
    height: 14px;
  }

  & path {
    fill: ${({ theme }) => theme.colors.neutralDarkest};
  }
`;

export const FilterComparator = styled(FilterBlockDefault)`
  background: ${({ theme }) => theme.colors.neutralLightest};
  color: ${({ theme }) => theme.colors.neutralDark};
  font-weight: 500;
`;

export const FilterGroup = styled(HorizontalFlex)`
  gap: 1px;

  & > :first-child {
    border-radius: 4px 0 0 4px;
  }

  & > :last-child {
    border-radius: 0 4px 4px 0;
  }
`;

export const TeamMemberImage = styled(UserBox)`
  border-radius: 50%;
`;

export const MenuLayout = styled(VerticalFlex)<{ minHeight?: number }>`
  min-width: 250px;
  border-radius: 4px;
  padding: 4px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow:
    0px 2px 10px rgba(50, 50, 93, 0.1),
    0px 1px 3px -1px rgba(0, 0, 0, 0.05);
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : '')};
  overflow: auto;
`;

export const FilterTypeRow = styled(MenuItemV3).attrs(({ theme }) => ({
  size: theme.designSizes.SMALL,
}))`
  ${smallMenuItemCss};
  padding: 6px 8px;
  align-items: center;
  cursor: pointer;

  .MuiCheckbox-root {
    padding: 0;
  }
`;
