import React from 'react';

const ContactFormIconV2 = ({ fill, darkBackground }) => (
  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 19.722c5.37 0 9.723-4.353 9.723-9.722C19.723 4.63 15.37.277 10 .277 4.63.277.278 4.63.278 10c0 5.369 4.353 9.722 9.723 9.722Z"
      fill={fill || '#FFFFFF'}
    />
    <path
      d="M12.041 4.125v2.917h2.917l-2.917-2.917Zm-.729 2.917V4.125h-4.01c-.615 0-1.094.501-1.094 1.094v9.479a1.08 1.08 0 0 0 1.094 1.094h6.562c.593 0 1.094-.479 1.094-1.094V7.77h-2.917a.72.72 0 0 1-.729-.73Zm1.094 6.562H8.76a.36.36 0 0 1-.365-.364c0-.183.16-.365.365-.365h3.646c.182 0 .364.182.364.365a.375.375 0 0 1-.364.364Zm0-1.458H8.76a.36.36 0 0 1-.365-.365c0-.182.16-.364.365-.364h3.646c.182 0 .364.182.364.364a.375.375 0 0 1-.364.365Zm.364-1.823a.375.375 0 0 1-.364.364H8.76a.36.36 0 0 1-.365-.364c0-.182.16-.365.365-.365h3.646c.182 0 .364.183.364.365Z"
      fill={darkBackground ? '#FFFFFF' : '#2F3137'}
    />
  </svg>
);

export default ContactFormIconV2;
