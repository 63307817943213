import styled from 'styled-components';

export const BaseVideoSize = styled.div`
  width: 960px;
  height: 540px;
  overflow: hidden;
  border-radius: 10px;

  @media (max-width: 1560px) {
    width: 800px;
  }

  @media (max-width: 1000px) {
    width: 700px;
  }

  @media (max-width: 740px) {
    width: 440px;
  }

  @media (max-height: 811px) {
    width: 720px;
    height: 405px;
  }
  @media (max-height: 680px) {
    width: 560px;
    height: 315px;
  }

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 100%;

    @media (max-height: 830px) {
      height: 630px;
    }
    @media (max-height: 740px) {
      height: 540px;
    }
    @media (max-height: 650px) {
      height: 450px;
    }
  }
`;
