import React, { useLayoutEffect } from 'react';
import SiteTags from 'player/src/components/SiteTags';
import Feed from 'player/src/components/feed/Feed';
import RootProviders from 'player/src/components/feed/RootProviders';
import { constructEventsData, getCustomParams, getUrlParams } from 'player/src/tracker/EventsData';
import { getCurrentSessionFromLocalStorage } from 'player/src/actions/localStorage.actions';
import { addUrlParamsToEventsData } from 'player/src/actions/eventData.actions';
import { initAnalytics } from 'player/src/tracker/Tracker';
import VideoWasntFound from '../player/VideoWasntFound';
import CustomStyle from './CustomStyle';
import Utils from 'player/src/utils';

const FeedWrapper = ({
  config,
  searchControls,
  products,
  isProductsInitialized,
  className,
  isPlayerPreview = false,
}) => {
  useLayoutEffect(() => {
    if (!config || isPlayerPreview) {
      return;
    }

    const eventsData = constructEventsData(config);

    const currentSession = getCurrentSessionFromLocalStorage(config.publishId);

    addUrlParamsToEventsData(eventsData, currentSession);

    const passedParams = getUrlParams(eventsData);

    const customParams = getCustomParams();
    initAnalytics(eventsData, passedParams.delayPageView, customParams, config.featureSettings);
  }, [config]);

  if (!config) {
    return null;
  }

  const isOriginAllowed = config.allowedOrigins
    ? Utils.checkIfParentUrlIsAllowed(config.allowedOrigins)
    : true;
  if (config?.disabled || !isOriginAllowed) {
    return <VideoWasntFound />;
  }

  const { steps: playlist, startStep, userId: accountId, name: conversationName } = config;
  return (
    <>
      <SiteTags
        playlist={playlist}
        startStep={startStep}
        accountId={accountId}
        conversationName={conversationName}
        verticalOrientation={config.verticalOrientation}
      />
      <CustomStyle config={config} />
      <RootProviders
        config={config}
        products={products}
        isProductsInitialized={isProductsInitialized}
        searchControls={searchControls}
        isPlayerPreview={isPlayerPreview}
      >
        <Feed className={className} config={config} />
      </RootProviders>
    </>
  );
};

export default FeedWrapper;
