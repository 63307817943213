import React, { useEffect, useState, useCallback } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  useSensor,
  useSensors,
  MouseSensor,
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import PlusIcon from 'app/src/images/PlusIcon';
import Trash from 'app/src/images/Trash';
import styled, { useTheme } from 'styled-components';
import StandardModalV2 from 'app/src/pages/modals/StandardModalV2';
import { useModal, useModalActions } from 'app/src/context/ui_store/ModalStore';
import { vodAsset } from 'app/src/types/entities';

import { ASSET_TYPE, AssetRenditionsService } from 'shared/react/services/assets';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { useGalleryAssetsCount, useVideoActions } from 'app/src/context/VideosStore';
import ModalFooterButtons from 'app/src/complex_components/global_modal/common/ModalFooterButtons';
import { WhiteButton } from 'shared/react/components/basic/button-v2/BasicButton';
import SearchProducts from 'app/src/complex_components/search-products/SearchProducts';
import { useSelectedProductsContext } from 'app/src/complex_components/global_modal/video/add_products_modal/SelectedProductsContext';
import { IMAGE_LIBRARY_MODAL_KEY } from 'app/src/constants/modals.constants';
import SelectedProductsWithSuggestions from 'app/src/complex_components/global_modal/video/add_products_modal/SelectedProductsWithSuggestions';
import { useVodConnection } from 'app/src/context/VodConnectionStore';
import { useAppActions } from 'app/src/context/AppsStore';
import { TextH3Bold } from 'shared/react/components/basic/text/TextV2';
import { DEFAULT_GALLERY_NAME, MAX_GALLERY_IMAGES } from 'app/src/constants/gallery.constants';
import DraggableThumbnail from 'app/src/complex_components/global_modal/gallery-modal/DraggableThumbnail';
import { useProductActions } from 'app/src/context/ProductsStore';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { VIDEO_STATUS } from 'shared/react/constants/video.constants';
import DefaultProjectPlaceholderV2 from 'app/src/images/dashboard_v2/default_project_placeholder_v2.svg';
import MusicIcon from 'app/src/images/MusicIcon';
import SoundLibrary from 'app/src/complex_components/global_modal/gallery-modal/SoundLibrary';
import CloseIcon from 'app/src/images/CloseIcon';
import { SOUND_LIBRARY } from 'app/src/constants/soundLibrary.constants';
import { useVideoSwitcherInModal } from 'app/src/pages/dashboard/pages/videos/components/video_card/useVideoSwitcherInModal';
import {
  LeftButton,
  RightButton,
} from 'app/src/pages/dashboard/pages/videos/components/video_card/video-switcher/buttons';
import { useLocation } from 'react-router-dom';
import Routes from 'app/src/helpers/Routes';

type Props = {
  closeModal: () => void;
};

type ModalProps = {
  selectedImages: vodAsset[];
  gallery: vodAsset;
};

const GalleryModal = ({ closeModal }: Props) => {
  const { pathname } = useLocation();
  const isVideosDashboard = Routes.isVideosDashboard(pathname);
  const [{ modalProps, currentIds }] = useModal();
  const theme = useTheme();
  const { selectedImages, gallery } = modalProps as ModalProps;
  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 10 } }),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  const { setCurrentModal, setCurrentIds, updateModalProps } = useModalActions();
  const { getAppUsingUrl } = useAppActions();

  const [
    { selectedProducts, selectedTags, isSaving, vodAssetIds, isLoading, isDirty },
    { toggleTag, addProduct, setSelectedProducts, save, init },
  ] = useSelectedProductsContext();

  const [isSelectProductPopupOpened, setIsSelectProductPopupOpened] = useState<boolean>(false);
  const { moveToVideo } = useVideoSwitcherInModal({
    shouldMove: useCallback(
      () => !isSelectProductPopupOpened && !isDirty && !isSaving && !isLoading,
      [isDirty, isLoading, isSaving, isSelectProductPopupOpened]
    ),
  });

  const { getProducts } = useProductActions();

  const [
    {
      vodConnectionProductIds: vodConnectionProductIdsFromStore,
      vodConnectionVariantsByProductIds,
      selectedAppUrl,
    },
  ] = useVodConnection({ vodAssetId: gallery?.id || currentIds[0] });

  const selectedApp = getAppUsingUrl(selectedAppUrl);
  const [imageGalleryList, setImageGalleryList] = useState([]);

  const [activeImagePreviewIndex, setActiveImagePreviewIndex] = useState(0);
  const [isGallerySaving, setIsGallerySaving] = useState(false);
  const [addVodConnectionsToGallery, setAddVodConnectionsToGallery] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isSoundLibraryOpen, setIsSoundLibraryOpen] = useState(false);
  const [selectedTrack, setSelectedTrack] = useState(null);

  const { updateVideo, createVideo } = useVideoActions();
  const [galleryAssetsCount] = useGalleryAssetsCount();

  const galleryName = gallery?.name || `${DEFAULT_GALLERY_NAME} ${galleryAssetsCount + 1}`;

  const modalFooterButtonsLoading = isGallerySaving || isSaving || isLoading;

  const canAddMoreImages = imageGalleryList?.length < MAX_GALLERY_IMAGES;
  const posterUrl =
    imageGalleryList && imageGalleryList[activeImagePreviewIndex]
      ? imageGalleryList[activeImagePreviewIndex].posterUrl
      : DefaultProjectPlaceholderV2;

  const getProductsData = async () => {
    const existingProducts = await getProducts(vodConnectionProductIdsFromStore);

    existingProducts.forEach(product => {
      if (!selectedProducts.some(selectedProduct => selectedProduct.id === product.id)) {
        addProduct(product);
      }
    });

    init({
      initialProducts: existingProducts,
      initialTags: [],
      selectedProducts: existingProducts,
      selectedTags: [],
      initialVariantsByProducts: vodConnectionVariantsByProductIds,
      variantsByProducts: vodConnectionVariantsByProductIds,
    });
  };

  const handleDragStart = event => {
    setIsDragging(true);
  };

  const handleDragEnd = event => {
    setIsDragging(false);
    const { active, over } = event;
    if (!over) {
      return;
    }

    if (active.id !== over.id) {
      const oldIndex = imageGalleryList.findIndex(item => item.id === active.id);
      const newIndex = imageGalleryList.findIndex(item => item.id === over.id);

      if (oldIndex === -1 || newIndex === -1) {
        return;
      }

      const updatedGalleryList = Array.from(imageGalleryList);
      const [movedItem] = updatedGalleryList.splice(oldIndex, 1);
      updatedGalleryList.splice(newIndex, 0, movedItem);

      setImageGalleryList(updatedGalleryList);
    }
  };

  const selectImage = index => {
    setActiveImagePreviewIndex(index);
  };

  const onGallerySave = async () => {
    setIsGallerySaving(true);

    const selectedImagesIds = imageGalleryList.map(image => image.id);
    const galleryInput = {
      name: galleryName,
      type: ASSET_TYPE.GALLERY,
      status: VIDEO_STATUS.done,
      stockAsset: {
        posterUrl: imageGalleryList[0].posterUrl,
        audioUrl: selectedTrack?.link || null,
      },
      referenceIds: selectedImagesIds,
    };

    if (gallery?.id) {
      const { vodConnections, __typename, ...galleryWithoutVodConnections } = gallery;
      await updateVideo({ ...galleryWithoutVodConnections, ...galleryInput });
    } else {
      const createdGallery = await createVideo(galleryInput);
      setCurrentIds([createdGallery.id]);
    }

    setAddVodConnectionsToGallery(true);
  };

  const handleCloseModal = () => {
    closeModal();
  };

  const onAddImageClick = () => {
    setCurrentModal(IMAGE_LIBRARY_MODAL_KEY);
  };

  const onRemoveImageClick = imageId => {
    if (imageGalleryList.length <= 1) {
      return;
    }

    const updatedGalleryList = imageGalleryList.filter(image => image.id !== imageId);
    const updatedSelectedImages = selectedImages.filter(image => image.id !== imageId);
    setImageGalleryList(updatedGalleryList);
    updateModalProps({ selectedImages: updatedSelectedImages });

    if (imageGalleryList[activeImagePreviewIndex]?.id === imageId) {
      setActiveImagePreviewIndex(prevIndex => Math.max(0, prevIndex - 1));
    }
  };

  const toggleSoundLibrary = () => {
    setIsSoundLibraryOpen(!isSoundLibraryOpen);
  };

  const closeSoundLibrary = () => {
    setIsSoundLibraryOpen(false);
  };

  const onTrackSelect = track => {
    setSelectedTrack(track);
  };

  const removeTrack = e => {
    e.stopPropagation();

    setSelectedTrack(null);
  };

  useEffect(() => {
    if (!vodConnectionProductIdsFromStore?.length || isSaving) {
      return;
    }

    getProductsData();
  }, [
    vodAssetIds,
    vodConnectionProductIdsFromStore,
    vodConnectionVariantsByProductIds,
    isSaving,
    selectedAppUrl,
  ]);

  useEffect(() => {
    const newImageGalleryList = selectedImages
      ?.slice(0, MAX_GALLERY_IMAGES)
      .map((image: vodAsset) => {
        const posterUrl = AssetRenditionsService.getAssetPosterLarge(image);
        return { posterUrl, id: image.id };
      });

    if (gallery && gallery.stockAsset?.audioUrl) {
      for (const category in SOUND_LIBRARY) {
        const track = SOUND_LIBRARY[category].find(
          track => track.link === gallery.stockAsset?.audioUrl
        );
        if (track) {
          setSelectedTrack(track);
        }
      }
    }

    setImageGalleryList(newImageGalleryList || []);
  }, []);

  useEffect(() => {
    const saveVodConnections = async () => {
      await save();
      handleCloseModal();
    };

    if (addVodConnectionsToGallery) {
      saveVodConnections();
    }
  }, [addVodConnectionsToGallery]);

  return (
    <StyledStandardModal onClose={closeModal} open>
      <HorizontalFlex>
        {isVideosDashboard && <LeftButton moveToVideo={moveToVideo} />}
        <StyledImageGallery>
          <AddMusicWrapper>
            <StyledWhiteButton onClick={toggleSoundLibrary}>
              <MusicIcon />
              {selectedTrack ? (
                <>
                  {selectedTrack.name}
                  <RemoveSoundButton
                    onClick={e => {
                      removeTrack(e);
                    }}
                  >
                    <CloseIcon fill={theme.colors.neutralBlack} />
                  </RemoveSoundButton>
                </>
              ) : (
                'Add Sound'
              )}
            </StyledWhiteButton>
          </AddMusicWrapper>
          <StyledGalleryContainer>
            <StyledMainImageContainer>
              <StyledMainImage src={posterUrl} alt={`image ${activeImagePreviewIndex}`} />
            </StyledMainImageContainer>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={imageGalleryList.map(item => item.id)}
                strategy={verticalListSortingStrategy}
              >
                <StyledThumbnailsOverlay>
                  {imageGalleryList.map((img, index) => (
                    <ThumbnailWrapper key={img.id} isDragging={isDragging}>
                      <DraggableThumbnail
                        key={img.id}
                        id={img.id}
                        src={img?.posterUrl}
                        index={index}
                        onSelect={selectImage}
                      />
                      <TrashIconWrapper onClick={() => onRemoveImageClick(img.id)}>
                        <Trash fill={theme.colors.white} width={16} height={16} />
                      </TrashIconWrapper>
                    </ThumbnailWrapper>
                  ))}
                  {canAddMoreImages && (
                    <AddNewImage onClick={() => onAddImageClick()}>
                      <StyledPlusIcon />
                    </AddNewImage>
                  )}
                </StyledThumbnailsOverlay>
              </SortableContext>
            </DndContext>
            <SoundLibrary
              isOpen={isSoundLibraryOpen}
              selectedTrack={selectedTrack}
              onClose={closeSoundLibrary}
              onTrackSelect={onTrackSelect}
            ></SoundLibrary>
          </StyledGalleryContainer>
        </StyledImageGallery>
        <StyledProductChoose>
          <div>
            <StyledGalleryName> {galleryName} </StyledGalleryName>
            <StyledProductChooseContent>
              <StyledSearchProducts
                selectedProducts={selectedProducts}
                onProductSelected={addProduct}
                location="Gallery Modal"
                onHasNoPermissions={undefined}
                getProductComponent={undefined}
                isLoading={undefined}
                isSaving={isSaving}
                setSelectedProducts={setSelectedProducts}
                onTagClick={toggleTag}
                selectedTags={selectedTags}
                selectedApp={selectedApp}
                autoFocus={true}
                setIsSelectProductPopupOpened={setIsSelectProductPopupOpened}
              />
              <SelectedProductsWithSuggestionsWrapper>
                <SelectedProductsWithSuggestions vodAssetId={gallery?.id || currentIds[0]} />
              </SelectedProductsWithSuggestionsWrapper>
            </StyledProductChooseContent>
          </div>
          <ModalFooterButtons
            isDiscardDisabled={modalFooterButtonsLoading}
            isSaveDisabled={modalFooterButtonsLoading}
            isSaveLoading={modalFooterButtonsLoading}
            onDiscard={handleCloseModal}
            onSave={onGallerySave}
          />
        </StyledProductChoose>
        {isVideosDashboard && <RightButton moveToVideo={moveToVideo} />}
      </HorizontalFlex>
    </StyledStandardModal>
  );
};

const StyledStandardModal = styled(StandardModalV2)`
  width: 800px;
  height: 600px;
`;

const StyledImageGallery = styled.div`
  position: relative;
  width: 308px;
  height: 600px;
`;

const StyledProductChoose = styled(VerticalFlex)`
  padding: 26px 16px;
  width: 492px;
  justify-content: space-between;
`;

const StyledProductChooseContent = styled.div``;
const StyledGalleryContainer = styled.div`
  overflow: hidden;
  position: relative;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.neutralDark};
`;

const StyledMainImageContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const StyledMainImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease-in-out;
`;

const StyledThumbnailsOverlay = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 8px;
  gap: 8px;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(3px);
`;

const AddMusicWrapper = styled(HorizontalFlex)`
  justify-content: center;
  width: 308px;
  position: absolute;
  top: 10px;
  z-index: 9;
`;

const StyledWhiteButton = styled(WhiteButton)`
  color: ${({ theme }) => theme.colors.neutralBlack};
  padding: 9px 12px;
`;

const RemoveSoundButton = styled(HorizontalFlex)`
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
`;

const AddNewImage = styled.div`
  width: 67px;
  height: 89px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.5);
  border: 0.5px solid ${({ theme }) => theme.colors.neutralPale};
  color: ${({ theme }) => theme.colors.neutralPale};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
`;

const StyledPlusIcon = styled(PlusIcon)`
  & path {
    fill: ${({ theme }) => theme.colors.neutralPale};
  }

  width: 16px;
  height: 16px;
`;

const StyledSearchProducts = styled(SearchProducts)`
  margin-top: 24px;
  margin-bottom: 24px;
`;

const ThumbnailWrapper = styled.div`
  position: relative;
  svg {
    display: none;
    position: absolute;
    top: 8px;
    right: 8px;
  }
  &:hover img {
    opacity: ${({ isDragging }) => (isDragging ? '1' : '0.75')};
  }

  &:hover svg {
    display: ${({ isDragging }) => (isDragging ? 'none' : 'block')};
    cursor: pointer;
  }
`;

const StyledGalleryName = styled(TextH3Bold)`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 2.4em;
  line-height: 1.2em;

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
`;

const SelectedProductsWithSuggestionsWrapper = styled(VerticalFlex)`
  overflow: scroll;
  max-height: 375px;
`;

const TrashIconWrapper = styled.div``;

export default GalleryModal;
