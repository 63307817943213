import React from 'react';
import {
  allAttributeTitles,
  allConditionsTitles,
} from 'app/src/pages/project/pages/rules_and_triggers/Rule.constants';
import RuleSummaryItem from 'app/src/pages/project/pages/rules_and_triggers/summary/RuleSummaryItem';

function RuleSummaryItemContainer({ rule }) {
  const attribute = allAttributeTitles.find(({ key }) => rule.type === key)?.title || rule.type;
  const condition = allConditionsTitles.find(({ key }) => rule.condition === key)?.title;
  return (
    <RuleSummaryItem
      attributeText={attribute}
      conditionText={condition}
      valueText={rule.limit || rule.value}
    />
  );
}

export default RuleSummaryItemContainer;
