import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { BolderTextBody } from 'shared/react/components/complex/Text';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { getLetterIcon } from 'shared/react/utils/icons';
import { URL_KEY } from 'app/src/constants/ruleGroups.constants';
import EmptyState from '../EmptyState';
import AnswerEdit from './AnswerEdit';

const ReviewCustomPart = ({ step }) => {
  const theme = useTheme();
  const [answers, setAnswers] = useState(step.answers);

  const updateUrl = (newValue, answerIndex) => {
    const updatedAnswer = step.answers[answerIndex];
    updatedAnswer.value = newValue;

    const newAnswers = step.answers.map((answer, index) => {
      if (answerIndex === index) {
        return updatedAnswer;
      }

      return answer;
    });

    step.answers = newAnswers;
    setAnswers(newAnswers);
  };

  useEffect(() => {
    setAnswers(step.answers);
  }, [step]);

  const numberOfLinkAnswers = answers.filter(({ next }) => next === 'url').length;

  if (!numberOfLinkAnswers) {
    return <EmptyState />;
  }

  return (
    <LayoutRoot>
      {answers.map(({ text, value, next }, index) => {
        if (next !== URL_KEY) {
          return null;
        }

        return (
          <AnswerLayout key={index}>
            <AnswerHeader>
              {getLetterIcon(index, theme.colors.black, 24)}
              <StyledBolderTextBody>{text}</StyledBolderTextBody>
            </AnswerHeader>
            <AnswerEdit index={index} value={value} updateUrl={updateUrl} />
          </AnswerLayout>
        );
      })}
    </LayoutRoot>
  );
};

export default ReviewCustomPart;

const AnswerHeader = styled(HorizontalFlex)`
  align-items: center;
  gap: 12px;
`;

const AnswerLayout = styled(VerticalFlex)`
  gap: 20px;
`;

const LayoutRoot = styled(VerticalFlex)`
  gap: 36px;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    width: 280px;
  } ;
`;

const StyledBolderTextBody = styled(BolderTextBody)`
  color: ${({ theme }) => theme.colors.gray2};
`;
