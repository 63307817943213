import React from 'react';
import { track } from 'app/src/helpers/Tracker';
import ResetToDefaultInputWrapper from 'app/src/complex_components/ResetToDefaultInputWrapper';
import EditorToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorToggleItem';
import EditorColorInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorColorInputItem';
import EditorSubheader from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorSubheader';

type Props = {
  settings: {
    iconColor: string;
    shouldDisplayIcon: boolean;
  };
  defaultSettings?: {
    iconColor: string;
    shouldDisplayIcon: boolean;
  };
  disabled?: boolean;
  onChange?: any;
};

const DisplayIconsEditor = ({
  settings,
  defaultSettings,
  disabled = false,
  onChange = () => {},
}: Props) => {
  const { iconColor, shouldDisplayIcon = false } = settings;

  const updateSettings = update => onChange({ ...settings, ...update });
  const onShouldDisplayIconChange = shouldDisplayIcon => updateSettings({ shouldDisplayIcon });
  const onIconColorChange = iconColor => updateSettings({ iconColor });

  const resetBranColorToDefault = () => {
    track('Branding Reset To Default', { property: 'brandColor' });
    onIconColorChange(defaultSettings.iconColor);
  };

  return (
    <>
      <EditorSubheader title="Display icons" />

      <EditorToggleItem
        disabled={disabled}
        text="Display icons"
        checked={shouldDisplayIcon}
        onChange={onShouldDisplayIconChange}
      />
      <ResetToDefaultInputWrapper disabled={disabled} onClick={resetBranColorToDefault}>
        <EditorColorInputItem
          text="Icon color"
          color={iconColor}
          onChange={onIconColorChange}
          height="50px"
          disabled={disabled}
        />
      </ResetToDefaultInputWrapper>
    </>
  );
};

export default DisplayIconsEditor;
