import React from 'react';
import { useTheme } from 'styled-components';
import { getLetterIcon } from 'shared/react/utils/icons';
import DefaultAnswersIcon from 'app/src/images/creation_method_icons/DefaultAnswersIcon';
import ProductsAnswersIcon from 'app/src/images/creation_method_icons/ProductsAnswersIcon';
import BasicMenuV2 from 'app/src/complex_components/BasicMenuV2';
import Utils from 'app/src/utils';
import {
  DESCRIPTION_SIDE_PANEL_TYPE,
  SHOPABLE_SIDE_PANEL_TYPE,
} from 'shared/react/constants/feed.constants';
import DescriptionAnswersIcon from 'app/src/images/creation_method_icons/DescriptionAnswersIcon';
import { track } from 'app/src/helpers/Tracker';
import { useFormContext } from 'react-hook-form';
import {
  FEED_MENU_ITEM_DESCRIPTION_TEST_ID,
  FEED_MENU_ITEM_SHOPPABLE_TEST_ID,
} from 'app/src/constants/dataTestIds.constants';
import { TABS } from 'app/src/constants/editStep.constants';
import { useApps } from 'app/src/context/AppsStore';

const AnswersTypeMenu = ({
  setAnchorEl,
  anchorEl,
  setDefaultResponsesEnabled,
  setStepType,
  stepType,
  isFeed,
}) => {
  const [{ shopify }, { isEcomPlatformConnected }] = useApps();
  const { setValue, isFetchByUrlFeatureEnabled } = useFormContext();
  const theme = useTheme();

  const onClose = () => {
    setAnchorEl(null);
  };

  const onChoose = key => {
    setDefaultResponsesEnabled(false);
    setStepType(key);
    onClose();
  };

  const onDefaultResponses = () => {
    setDefaultResponsesEnabled(true);
    setStepType(TABS.default);
    onClose();
  };

  const getDefaultMenuItems = () => {
    const items = [
      {
        text: 'Default',
        isEnabled: stepType === TABS.default,
        onClick: onDefaultResponses,
        icon: <DefaultAnswersIcon />,
      },
      {
        text: 'Custom',
        isEnabled: stepType === TABS.custom,
        onClick: () => onChoose(TABS.custom),
        icon: getLetterIcon(0, theme.colors.black),
      },
    ];

    if (shopify || isFetchByUrlFeatureEnabled) {
      items.push({
        text: 'Shoppable',
        isEnabled: stepType === TABS.products,
        onClick: () => onChoose(TABS.products),
        icon: <ProductsAnswersIcon />,
      });
    }

    return items;
  };

  const setFeedSidePanelType = type => {
    setValue('feedSidePanelType', type);
  };

  const handleChoiceClick = (e, type) => {
    e.stopPropagation();

    track('Side Panel Type Click', { type });

    setFeedSidePanelType(type);

    onClose(e);
  };

  const getFeedMenuItems = () => {
    const items = [
      {
        text: Utils.upperCaseFirstChar(DESCRIPTION_SIDE_PANEL_TYPE),
        onClick: e => handleChoiceClick(e, DESCRIPTION_SIDE_PANEL_TYPE),
        icon: <DescriptionAnswersIcon />,
        dataTestId: FEED_MENU_ITEM_DESCRIPTION_TEST_ID,
      },
    ];

    if (isEcomPlatformConnected() || isFetchByUrlFeatureEnabled) {
      return [
        {
          text: Utils.upperCaseFirstChar(SHOPABLE_SIDE_PANEL_TYPE),
          onClick: e => handleChoiceClick(e, SHOPABLE_SIDE_PANEL_TYPE),
          icon: <ProductsAnswersIcon />,
          dataTestId: FEED_MENU_ITEM_SHOPPABLE_TEST_ID,
        },
        ...items,
      ];
    }

    return items;
  };

  const getItems = () => {
    if (isFeed) {
      return getFeedMenuItems();
    }

    return getDefaultMenuItems();
  };

  return <BasicMenuV2 onClose={onClose} items={getItems()} anchorEl={anchorEl} />;
};

export default AnswersTypeMenu;
