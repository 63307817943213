import { CREATION_FLOW_MODAL_KEY } from 'app/src/constants/modals.constants';
import { LIBRARY } from 'app/src/constants/creation_method.constants';
import { useModalActions } from 'app/src/context/ui_store/ModalStore';

type OpenVideoPickerModalProps = {
  onVideoPicked: (video: any) => void;
  onModalClosed?: () => void;
  shouldBreakAfterVideoPicked?: boolean;
};

export const useOpenVideoPickerModal = () => {
  const { setCurrentModal, setModalProps } = useModalActions();

  const openVideoPickerModal = ({
    onVideoPicked,
    onModalClosed,
    shouldBreakAfterVideoPicked,
  }: OpenVideoPickerModalProps) => {
    setModalProps({
      isLeftPanelVisible: false,
      shouldCreateProject: false,
      defaultSelectedMethod: LIBRARY,
      onVideoClick: onVideoPicked,
      onModalClosed,
      shouldBreakAfterVideoPicked,
    });

    setCurrentModal(CREATION_FLOW_MODAL_KEY);
  };

  return openVideoPickerModal;
};
