export const PORT = import.meta.env.VITE_PORT;
export const NODE_ENV = import.meta.env.MODE;
export const REACT_APP_GOOGLE_ANALYTICS_KEY = import.meta.env.VITE_REACT_APP_GOOGLE_ANALYTICS_KEY;
export const REACT_APP_FACEBOOK_ANALYTICS_KEY = import.meta.env
  .VITE_REACT_APP_FACEBOOK_ANALYTICS_KEY;
export const REACT_APP_VIDEO_OUTPUT = import.meta.env.VITE_REACT_APP_VIDEO_OUTPUT;
export const REACT_APP_API_BASE_URL = import.meta.env.VITE_REACT_APP_API_BASE_URL;
export const REACT_APP_CONFIG_URL_CDN = import.meta.env.VITE_REACT_APP_CONFIG_URL_CDN;
export const REACT_APP_PROJECTS_URL = import.meta.env.VITE_REACT_APP_PROJECTS_URL;
export const REACT_APP_IMAGE_OUTPUT = import.meta.env.VITE_REACT_APP_IMAGE_OUTPUT;
export const REACT_APP_ZEROBOUNCE_KEY = import.meta.env.VITE_REACT_APP_ZEROBOUNCE_KEY;
export const REACT_APP_ASSETS_OUTPUT = import.meta.env.VITE_REACT_APP_ASSETS_OUTPUT;
export const REACT_APP_MUX_DATA_KEY = import.meta.env.VITE_REACT_APP_MUX_DATA_KEY;
export const REACT_APP_SENTRY_RELEASE = import.meta.env.VITE_REACT_APP_SENTRY_RELEASE;
export const REACT_APP_BASE_URL = import.meta.env.VITE_BASE_URL;

const ENVS = {
  PORT,
  REACT_APP_GOOGLE_ANALYTICS_KEY,
  REACT_APP_FACEBOOK_ANALYTICS_KEY,
  REACT_APP_VIDEO_OUTPUT,
  REACT_APP_API_BASE_URL,
  REACT_APP_CONFIG_URL_CDN,
  REACT_APP_PROJECTS_URL,
  REACT_APP_IMAGE_OUTPUT,
  REACT_APP_ZEROBOUNCE_KEY,
  REACT_APP_ASSETS_OUTPUT,
  REACT_APP_MUX_DATA_KEY,
  REACT_APP_SENTRY_RELEASE,
  REACT_APP_BASE_URL,
};

export default ENVS;
