import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import { TextBody } from 'shared/react/components/complex/Text';
import Button from 'shared/react/components/complex/Button';
import VIcon from 'app/src/images/VIcon';
import { useAccount } from 'app/src/context/AccountStore';
import { track } from '../../helpers/Tracker';
import { BILLING_PACKAGE_TYPES } from 'app/src/constants/billings.constants';
import { useBilling } from 'app/src/hooks/useBillings';
import FeaturesTooltips from './tooltips/FeaturesTooltips';
import InfoIconWithTooltip from 'app/src/complex_components/InfoIconWithTooltip';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { REQUEST_OWNER_TO_UPGRADE_PLAN_KEY } from 'app/src/constants/modals.constants';
import { NON_BREAKING_SPACE_UNICODE } from 'shared/react/constants/unicode.constants';

const DEFAULT_DISCOUNT_PERCENT = 60;

const getShouldShowPricePerMonth = price => {
  if (price[0] !== '$') {
    return false;
  }
  const priceWithSign = +price.substring(1);
  return !isNaN(priceWithSign) && priceWithSign;
};

const getDiscountPercentText = ({ discount, discountPercent }) => {
  if (!discount) {
    return '';
  }

  return `${discountPercent || DEFAULT_DISCOUNT_PERCENT}% Discount`;
};

const BillingPackage = ({
  id,
  name,
  type,
  active,
  title,
  price,
  limitKeys = [],
  limitValues = [],
  featuresTitle,
  features = [],
  notes = [],
  onClick,
  ownerWarning,
  discount,
  discountPercent,
}) => {
  const theme = useTheme();
  const [{ isAccountOwner }] = useAccount();
  const [{ isExpectingDelayedFetch }] = useFeatures();
  const [, { setCurrentModal }] = useModal();
  const { getPackageActionText } = useBilling();
  const [loading, setLoading] = useState(false);

  const onPlanClick = async () => {
    if (active) {
      return;
    }
    if (!isAccountOwner && ownerWarning) {
      setCurrentModal(REQUEST_OWNER_TO_UPGRADE_PLAN_KEY);
      track('Plan Click Non Owner Warning', {
        packageId: id,
        packageName: name,
      });
      return;
    }
    setLoading(true);

    await onClick(id, type);

    setLoading(false);
  };

  const shouldShowPricePerMonth = getShouldShowPricePerMonth(price);

  return (
    <>
      <StyledBillingPackage active={active} data-type={type}>
        {discount && !active && <BestValue>Limited time offer</BestValue>}
        <StyledBillingCurrentBadge />
        <BillingPackageHeader>
          <BillingPackageTitle>{title}</BillingPackageTitle>
          <BillingPackagePrice>
            <BillingPackagePriceStarting visible={shouldShowPricePerMonth}>
              Starting at
            </BillingPackagePriceStarting>
            <BillingPackagePricePerSeat>
              <BillingPackagePriceTitle>{price}</BillingPackagePriceTitle>
              <BillingPackagePriceMonth visible={shouldShowPricePerMonth}>
                / Creator / month
                <InfoIconWithTooltip title="Billed monthly" tooltipPlacement="right" />
              </BillingPackagePriceMonth>
            </BillingPackagePricePerSeat>
            <BillingPackageDiscount>
              <BillingPackageDiscountPrice discount={discount}>
                {discount ? '$19' : ' '}
              </BillingPackageDiscountPrice>
            </BillingPackageDiscount>
          </BillingPackagePrice>
        </BillingPackageHeader>
        <BillingPackageMainContent>
          <BillingPackageLimits>
            {limitKeys.map((limitKey, index) => {
              const limitValue = limitValues[index];
              return (
                <BillingPackageLimit key={`${limitKey}-${limitValue}`}>
                  <BillingPackageLimitKey>{limitKey}</BillingPackageLimitKey>
                  <TextContainer>
                    <BillingPackageLimitValue>{limitValue}</BillingPackageLimitValue>
                    <FeaturesTooltips limitKey={limitKey} feature={limitValue} />
                  </TextContainer>
                </BillingPackageLimit>
              );
            })}
          </BillingPackageLimits>
          <BillingPackageLimitDiscount visable={discount}>
            <BillingPackageLimitDiscountBanner>
              {getDiscountPercentText({ discount, discountPercent })}
            </BillingPackageLimitDiscountBanner>
            <BillingPackageLimitDiscountValidUntil>
              $5 per 1K views
            </BillingPackageLimitDiscountValidUntil>
          </BillingPackageLimitDiscount>
        </BillingPackageMainContent>
        <BillingPackageFooter>
          <BillingPackageButton
            onClick={onPlanClick}
            loading={loading || isExpectingDelayedFetch}
            fullWidth
            dataType={type}
          >
            {!active ? getPackageActionText(id) : 'Current plan'}
          </BillingPackageButton>
        </BillingPackageFooter>
        <BillingPackageSeparator />
        <BillingPackageFeatures>
          <BillingPackageFeaturesTitle>{featuresTitle}</BillingPackageFeaturesTitle>
          {features.map(feature => (
            <BillingPackageFeature key={feature}>
              <VIcon fill={theme.colors.success} width="16px" height="16px" />
              <TextContainer>
                {feature}
                <FeaturesTooltips feature={feature} />
              </TextContainer>
            </BillingPackageFeature>
          ))}
        </BillingPackageFeatures>
        {notes.map(note => (
          <BillingPackageNote key={note}>{note}</BillingPackageNote>
        ))}
      </StyledBillingPackage>
    </>
  );
};

export default BillingPackage;

const StyledBillingCurrentBadge = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  padding: 5px 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  top: -14px;

  &::after {
    content: 'Current';
  }
`;

const BillingPackagePrice = styled(VerticalFlex)`
  gap: 4px;
`;

const BillingPackagePricePerSeat = styled(Gap8HorizontalFlexWrap)`
  align-items: center;
`;

const BillingPackagePriceTitle = styled(TextBody)`
  font-weight: 800;
  font-size: 56px;
  line-height: 56px;

  @media (max-width: 1600px) {
    font-size: 44px;
    line-height: 44px;
  }

  @media (max-width: 1500px) {
    font-size: 40px;
    line-height: 40px;
  }
`;

const BillingPackagePriceText = styled(TextBody)`
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.ghostDark};
  white-space: pre-line;

  @media (max-width: 1600px) {
    font-size: 14px;
    line-height: 16px;
  }

  @media (max-width: 1500px) {
    font-size: 12px;
    line-height: 14px;
  }
`;

const BillingPackagePriceMonth = styled(BillingPackagePriceText)`
  align-self: end;
  display: ${({ visible }) => (!visible ? 'none' : 'flex')};
  align-items: center;
`;

const BillingPackagePriceStarting = styled(BillingPackagePriceText)`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};

  &::after {
    content: '${NON_BREAKING_SPACE_UNICODE}';
  }
`;

const BillingPackageButton = styled(Button)``;

const StyledBillingPackage = styled(VerticalFlex)`
  position: relative;
  background: ${({ theme }) => theme.colors.white};
  border: 4px solid ${({ theme, active }) => (active ? theme.colors.primaryLight : 'transparent')};
  box-shadow: ${({ active }) => (active ? '0px 4px 24px rgba(0, 0, 0, 0.25)' : '')};
  border-radius: 24px;
  max-width: 395px;
  padding: 32px;
  gap: 24px;
  height: 100%;
  flex: 1;

  @media (max-width: 1500px) {
    padding: 28px;
  }

  @media (${({ theme }) => theme.breakpoints.dashboardTabletMax}) {
    max-width: unset;
  }

  & > ${StyledBillingCurrentBadge} {
    display: ${({ active }) => (active ? 'initial' : 'none')};
  }

  &[data-type=${BILLING_PACKAGE_TYPES.free}] {
    ${BillingPackagePriceTitle} {
      color: ${({ theme }) => theme.colors.ghostDark};
    }

    [data-type=${BILLING_PACKAGE_TYPES.free}] {
      background: ${({ theme }) => theme.colors.white};
      border: 1px solid ${({ theme }) => theme.colors.gray4};
    }

    ${BillingPackageButton} > * > * {
      color: ${({ theme }) => theme.colors.black};
    }
  }

  &[data-type=${BILLING_PACKAGE_TYPES.pro}] {
    ${BillingPackagePriceTitle} {
      color: ${({ theme }) => theme.colors.blue7};
    }
    [data-type=${BILLING_PACKAGE_TYPES.pro}] {
      background: ${({ theme }) => theme.colors.blue7};
    }
  }

  &[data-type=${BILLING_PACKAGE_TYPES.enterprise}] {
    ${BillingPackagePriceTitle} {
      color: ${({ theme }) => theme.colors.success};
    }
    [data-type=${BILLING_PACKAGE_TYPES.enterprise}] {
      background: ${({ theme }) => theme.colors.success};
    }
  }
`;

const BestValue = styled.div`
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  padding: 5px 10px;
  border-radius: 12px 12px 0px 0px;
  background: ${({ theme }) => theme.colors.blue7};
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
`;

const BillingPackageHeader = styled(VerticalFlex)`
  gap: 12px;
`;

const BillingPackageTitle = styled(TextBody)`
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.black};
`;

const BillingPackageMainContent = styled(VerticalFlex)``;

const BillingPackageLimits = styled(VerticalFlex)``;

const BillingPackageLimit = styled(HorizontalFlexWrap)`
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;

  &:not(:last-child) {
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.ghostLight}`};
  }

  * {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    @media (max-width: 1600px) {
      font-size: 13px;
      line-height: 22px;
    }

    @media (max-width: 1500px) {
      font-size: 12px;
      line-height: 20px;
    }
  }
`;

const BillingPackageLimitDiscount = styled(HorizontalFlexWrap)`
  justify-content: space-between;
  align-items: center;
  position: relative;
  top: -6px;
  height: 24px;
  visibility: ${({ visable }) => (visable ? 'visible' : 'hidden')};

  & > * {
    display: ${({ visable }) => (visable ? '' : 'none')};
  }
`;

const BillingPackageLimitDiscountBanner = styled.div`
  display: grid;
  place-content: center;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.blue7};
  width: 89px;
  height: 19px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
`;

const BillingPackageLimitDiscountValidUntil = styled(TextBody)`
  color: ${({ theme }) => theme.colors.danger};
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  position: relative;

  &:after {
    color: ${({ theme }) => theme.colors.danger};
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid;
    border-color: inherit;
  }
`;

const BillingPackageLimitKey = styled(TextBody)`
  color: ${({ theme }) => theme.colors.ghostDark};
`;

const BillingPackageLimitValue = styled(TextBody)`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.black};
`;

const BillingPackageDiscount = styled.span`
  position: relative;
  color: ${({ theme }) => theme.colors.danger};
  font-size: 56px;
  line-height: 56px;
  width: fit-content;
  margin-top: 2px;

  @media (max-width: 1600px) {
    font-size: 44px;
    line-height: 44px;
  }

  @media (max-width: 1500px) {
    font-size: 40px;
    line-height: 40px;
  }

  &:after {
    color: ${({ theme }) => theme.colors.danger};
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    right: 0;
    border-top: 2px solid;
    border-color: inherit;
  }
`;

const BillingPackageDiscountPrice = styled.span`
  display: inline-block;
  visibility: ${({ discount }) => (discount ? '' : 'hidden')};
`;

const BillingPackageSeparator = styled.div`
  width: 100%;
  height: 2px;
  background: ${({ theme }) => theme.colors.gray5};
`;

const BillingPackageFeaturesTitle = styled(TextBody)`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.black};
`;

const BillingPackageFeatures = styled(VerticalFlex)`
  gap: 10px;
`;

const BillingPackageFeature = styled(Gap8HorizontalFlexWrap)`
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  @media (max-width: 1600px) {
    font-size: 14px;
    line-height: 20px;
  }

  @media (max-width: 1500px) {
    font-size: 13px;
    line-height: 18px;
  }
`;

const BillingPackageNote = styled(TextBody)`
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.gray2};
`;

const BillingPackageFooter = styled(Gap8VerticalFlex)`
  align-items: center;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
