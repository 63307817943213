import React from 'react';
import BasicSelect from 'shared/react/components/basic/basic_select/BasicSelect';
import EditorItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorItem';
import styled from 'styled-components';

const EditorSelect = ({ text, tooltipText, customHeight = 36, customSelectWidth, ...props }) => {
  return (
    <EditorItem text={text} tooltipText={tooltipText}>
      <CustomBasicSelect
        customHeight={customHeight}
        customSelectWidth={customSelectWidth}
        {...props}
      />
    </EditorItem>
  );
};

const CustomBasicSelect = styled(BasicSelect)`
  width: ${({ customSelectWidth }) => (customSelectWidth ? `${customSelectWidth}px` : '200px')};
`;

export default EditorSelect;
