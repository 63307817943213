import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useUser } from 'app/src/context/userStore/UserStore';
import { useHistory } from 'react-router-dom';
import { useApps } from 'app/src/context/AppsStore';
import { SHAPE_TYPES } from 'app/src/pages/modals/publish/widget/common';
import { track } from 'app/src/helpers/Tracker';
import { DASHBOARD_TABLET_MAX_WIDTH } from 'shared/react/utils/mediaQuery';
import { projectTabs } from 'app/src/helpers/Routes';
import { identify } from 'app/src/helpers/Widget';
import WidgetPreview from 'app/src/pages/modals/publish/previews/WidgetPreview';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import ComponentWithLoader from 'app/src/complex_components/ComponentWithLoader';
import WidgetSettings from 'app/src/pages/modals/publish/widget/WidgetSettings';
import LeftContainerContent from 'app/src/pages/modals/publish/widget/LeftContainerContent';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { useIsMobile } from 'shared/react/hooks/useIsMobile';
import useNavigation from 'app/src/hooks/useNavigation';

function Widget({ project, live, onSetLive, loading }) {
  const history = useHistory();
  const isMobile = useIsMobile();
  const [{ user, account }, { updateUser, fetch }] = useUser();
  const [, { setSnackbar }] = useSnackBar();
  const [apps, { getProjectApp, setProjectApp }] = useApps();
  const [appActive, setAppActive] = useState(false);
  const { navigateToProjectTab } = useNavigation();
  const storeApp = apps.wix || apps.shopify || apps.bigcommerce;
  const source = storeApp?.app;
  const appUrl = storeApp?.appUrl;
  const isIntegrationApiSetWidget = source === 'wix' || source === 'bigcommerce';
  const showButtons = source !== 'wix' || !source;

  useEffect(() => {
    setWidgetCustomizationSettings();
  }, [project, account, getProjectApp]);

  useEffect(() => {
    if (!user?.appKey || !account?.appKey) {
      fetch();
    }
    if (user && !user.hasShared) {
      updateUser({ id: user.id, hasShared: true });
      identify(user, { hasShared: true });
    }
  }, [user, account]);

  const setWidgetCustomizationSettings = () => {
    if (project) {
      setAppActive(getProjectApp(source, project.id)?.active);
    }
  };

  const handleIntegrationsResponse = (res, checked, excludeSuccessAlert) => {
    if (res.ok) {
      setAppActive(checked);
      if (!excludeSuccessAlert) {
        setSnackbar(`Tolstoy Widget ${checked ? 'added to' : 'removed from'} Site!`);
      }
      return;
    }

    const errorMessage = res.error?.response?.data;
    if (errorMessage === 'site does not have a connected domain') {
      setSnackbar('Site does not have a connected domain', 'warning');
      return;
    }

    setSnackbar('Error adding to Site!', 'error');
  };

  const publishAppChanged = async (app, checked, excludeSuccessAlert) => {
    const res = await setProjectApp(app, project.id, checked, project.publishId);
    handleIntegrationsResponse(res, checked, excludeSuccessAlert);
  };

  async function handlePublishAppChange() {
    track('Add To Site Click', { location: 'share' });
    const activate = !appActive;
    await publishAppChanged(source, false, activate);
    if (activate) {
      await publishAppChanged(source, true);
    }
  }

  function navigateToRoute(route) {
    const body = {
      pathname: route,
      state: {
        projectId: project.id,
      },
    };

    if (isMobile) {
      history.replace(body);
    } else {
      history.push(body);
    }
  }

  function handleTriggerRules() {
    navigateToProjectTab(project, projectTabs.rules);
  }

  function handleCustomizeAppearance() {
    navigateToProjectTab(project, projectTabs.design);
  }

  const shouldShowLoading = !account?.appKey || !user?.appKey;
  let widgetSettings = {};
  if (project?.widgetSettings) {
    widgetSettings = JSON.parse(project.widgetSettings);
  }

  return (
    <LayoutRoot>
      <ComponentWithLoader isLoading={shouldShowLoading || !project}>
        <MainContainer>
          <LeftContainer>
            <LeftContainerContent
              isStoreApp={!!storeApp}
              isIntegrationApiSetWidget={isIntegrationApiSetWidget}
              loading={loading}
              appUrl={appUrl}
              appActive={appActive}
              handlePublishAppChange={handlePublishAppChange}
              onSetLive={onSetLive}
              live={live}
              showButtons={showButtons}
              project={project}
            />
            <WidgetSettings
              showRulesAndTriggers={showButtons}
              handleCustomizeAppearance={handleCustomizeAppearance}
              handleTriggerRules={handleTriggerRules}
            />
          </LeftContainer>
          <PreviewContainer>
            <WidgetPreview
              border={widgetSettings.border}
              themeColor={widgetSettings.themeColor}
              themeTextColor={widgetSettings.themeTextColor}
              bubbleText={widgetSettings.bubbleText}
              bubbleTextEnabled={widgetSettings.bubbleTextEnabled}
              widgetHeader={project?.widgetHeader}
              project={project}
              widgetPosition={project?.widgetPosition || 'bottomRight'}
              notificationBadge={project?.widgetNotificationBadge}
              isVertical={!!project?.verticalOrientation}
              shape={project?.widgetShape || SHAPE_TYPES.rectangle}
              widgetSize={project?.widgetSize || 'm'}
              bubbleObjectPosition={widgetSettings.bubbleObjectPosition}
              bubbleMarginBottom={widgetSettings.bubbleMarginBottom}
              bubbleMarginSide={widgetSettings.bubbleMarginSide}
            />
          </PreviewContainer>
        </MainContainer>
      </ComponentWithLoader>
    </LayoutRoot>
  );
}

export default Widget;

const LayoutRoot = styled.div`
  height: 100%;

  @media (${DASHBOARD_TABLET_MAX_WIDTH}) {
    flex-wrap: unset;
    overflow: auto;
  }
`;

const MainContainer = styled(HorizontalFlex)`
  gap: 24px;
  height: 100%;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    display: grid;
    grid-template-columns: 1fr auto;
    flex-wrap: unset;
    overflow: auto;
  }
`;

const LeftContainer = styled(VerticalFlex)`
  flex-shrink: 0;
`;

const PreviewContainer = styled(VerticalFlex)`
  align-items: center;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    display: none;
  }
`;
