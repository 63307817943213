import React from 'react';

const AnalyticsIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.805.984a.581.581 0 0 1-.04.86l-6.874 6.25a.613.613 0 0 1-.86 0l-4.61-4.649-6.444 4.688a.584.584 0 0 1-.86-.117.584.584 0 0 1 .117-.86l6.875-5c.235-.195.586-.156.82.04l4.571 4.57 6.445-5.82a.581.581 0 0 1 .86.038ZM5.375 13.875v2.5c0 1.055-.86 1.875-1.875 1.875a1.851 1.851 0 0 1-1.875-1.875v-2.5c0-1.016.82-1.875 1.875-1.875 1.016 0 1.875.86 1.875 1.875Zm-1.25 2.5v-2.5c0-.313-.313-.625-.625-.625a.642.642 0 0 0-.625.625v2.5c0 .352.273.625.625.625a.642.642 0 0 0 .625-.625Zm2.5-6.25c0-1.016.82-1.875 1.875-1.875 1.016 0 1.875.86 1.875 1.875v6.25c0 1.055-.86 1.875-1.875 1.875a1.851 1.851 0 0 1-1.875-1.875v-6.25ZM8.5 9.5a.642.642 0 0 0-.625.625v6.25c0 .352.273.625.625.625a.642.642 0 0 0 .625-.625v-6.25c0-.313-.313-.625-.625-.625Zm6.875 3.125v3.75c0 1.055-.86 1.875-1.875 1.875a1.851 1.851 0 0 1-1.875-1.875v-3.75c0-1.016.82-1.875 1.875-1.875 1.016 0 1.875.86 1.875 1.875Zm-1.25 3.75v-3.75c0-.313-.313-.625-.625-.625a.642.642 0 0 0-.625.625v3.75c0 .352.273.625.625.625a.642.642 0 0 0 .625-.625Zm2.5-6.25c0-1.016.82-1.875 1.875-1.875 1.016 0 1.875.86 1.875 1.875v6.25c0 1.055-.86 1.875-1.875 1.875a1.851 1.851 0 0 1-1.875-1.875v-6.25ZM18.5 9.5a.642.642 0 0 0-.625.625v6.25c0 .352.273.625.625.625a.642.642 0 0 0 .625-.625v-6.25c0-.313-.313-.625-.625-.625Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default AnalyticsIcon;
