import { CURRENCY_SYMBOL_MAP } from 'shared/react/constants/currency.constants';
import Utils from 'app/src/utils';
import { compareAsc } from 'date-fns';

export const numberComparator = (number1: string, number2: string) => {
  return +number1 - +number2;
};

export const stringComparator = (string1: string, string2: string) => {
  return string1.localeCompare(string2);
};

export const dateComparator = (date1: string, date2: string) => {
  return compareAsc(new Date(date1), new Date(date2));
};

export const formatPrice = (price: string | number, currency: string) => {
  if (!+price) {
    return '—';
  }

  const currencySymbol = CURRENCY_SYMBOL_MAP[currency] || currency;

  const formattedPrice = Utils.formatNumberToString(price, 0, 1, false);

  return `${currencySymbol}${formattedPrice}`;
};
