import React from 'react';

const ScreenRecordingIcon = ({ fill = '#50545E', background = '#F4F5F7' }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="16" fill={background} />
      <path
        d="M23.5 8H8.5C7.65625 8 7 8.6875 7 9.5V19.5C7 20.3438 7.65625 21 8.5 21H14.5L14 22.5H11.75C11.3125 22.5 11 22.8438 11 23.25C11 23.6875 11.3125 24 11.75 24H20.25C20.6562 24 21 23.6875 21 23.25C21 22.8438 20.6562 22.5 20.25 22.5H18L17.5 21H23.5C24.3125 21 25 20.3438 25 19.5V9.5C25 8.6875 24.3125 8 23.5 8ZM23 19H9V10H23V19Z"
        fill={fill}
      />
    </svg>
  );
};

export default ScreenRecordingIcon;
