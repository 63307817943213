import React from 'react';

function GIcon({ fill, darkBackground, size = 20 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={12} cy={12} r={12} fill={fill || '#FFFFFF'} />
      <path
        d="M12.119 15.61c2.387 0 3.927-1.859 3.927-4.015v-.616h-4.169v1.463h2.519c-.286 1.023-1.111 1.683-2.277 1.683-1.54 0-2.651-1.166-2.651-2.64 0-1.441 1.133-2.64 2.596-2.64 1.045 0 1.804.473 2.299 1.386l1.342-.814c-.682-1.21-1.98-2.057-3.652-2.057-2.376 0-4.103 1.837-4.103 4.125 0 2.222 1.661 4.125 4.169 4.125Z"
        fill={darkBackground ? '#FFFFFF' : '#2F3137'}
      />
    </svg>
  );
}

export default GIcon;
