import React from 'react';
import styled from 'styled-components';
import { useLookAndFeelContext } from '../../LookAndFeelContext';
import VideoPagePreview from './VideoPagePreview';

const VideoPagePreviewContainer = () => {
  const { project } = useLookAndFeelContext();

  return (
    <LayoutRoot>
      <VideoPagePreview project={project} size={1} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  && {
    min-width: unset;
  }
`;

export default VideoPagePreviewContainer;
