import React from 'react';
import styled from 'styled-components';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { DarkerGrayLine } from 'app/src/pages/modals/publish/previews/static_preview_items/commonPreviewItems';

const PreviewMobileNotch = () => {
  return (
    <LayoutRoot>
      <Line />
      <Dot />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)`
  gap: 4px;
  align-self: center;
`;

const Line = styled(DarkerGrayLine)`
  width: 23px;
  height: 4px;
`;

const Dot = styled(DarkerGrayLine)`
  width: 4px;
  height: 4px;
  border-radius: 50%;
`;

export default PreviewMobileNotch;
