import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { TextH6, TextSmall } from 'shared/react/components/complex/Text';
import { useHistory, useParams } from 'react-router-dom';
import { useProjects } from 'app/src/context/ProjectsStore';
import {
  AccordionTitle,
  NotificationLevelContainer,
  SectionSubtitle,
  StyledSelect,
} from 'app/src/basic_components/commonStyles';
import ChipInput from 'app/src/basic_components/ChipInput';
import Utils from 'app/src/utils';
import { MenuItem } from '@material-ui/core';
import NotificationSettingsContainer from 'app/src/pages/notifications/NotificationSettingsContainer';
import { fetchNotificationSettings, useAccount } from 'app/src/context/AccountStore';
import { FEATURE_TEAM_MEMBER_NOTIFICATION_SETTINGS } from 'app/src/constants/appFeatures.constants';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { useUser } from 'app/src/context/userStore/UserStore';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import Button from 'shared/react/components/complex/Button';
import Types from 'shared/react/theme/Types';
import Routes from 'app/src/helpers/Routes';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import InfoIconWithTooltip from 'app/src/complex_components/InfoIconWithTooltip';
import useProjectType from 'app/src/hooks/useProjectType';
import BasicAccordion from 'shared/react/components/BasicAccordion';

function Notifications({ getProjectNotificationSettingsId }) {
  const history = useHistory();
  const { projectId } = useParams();
  const [, { getUser }] = useUser();
  const [, { getProjectById }] = useProjects();
  const [{ teamMembers }] = useAccount();
  const [, { getSettingsByKey }] = useFeatures();
  const project = getProjectById(projectId);
  const user = getUser();
  const currentTeamMember = getCurrentTeamMember();
  const { projectDisplayType } = useProjectType();
  const { setValue, watch } = useFormContext();
  const containerRef = useRef();

  const [
    emailNotifications,
    emailSettings,
    inAppSettings,
    viewerNotificationLevel,
    isEmailShowAnonymous,
    isInAppShowAnonymous,
  ] = watch([
    'emailNotifications',
    'emailSettings',
    'inAppSettings',
    'viewerNotificationLevel',
    'isEmailShowAnonymous',
    'isInAppShowAnonymous',
  ]);

  function getCurrentTeamMember() {
    return teamMembers?.find(({ userId }) => userId === user.owner);
  }

  function getTeamMemberNotificationSettingsId() {
    return currentTeamMember?.notificationSettingsId;
  }

  function updateNotificationSettings(notificationSettings) {
    const { email: emailNotificationSettings, inApp: inAppNotificationSettings } =
      notificationSettings;

    setValue('isEmailShowAnonymous', emailNotificationSettings?.showAnonymous, false);
    setValue('emailSettings', emailNotificationSettings?.enabled || [], false);
    setValue('isInAppShowAnonymous', inAppNotificationSettings?.showAnonymous, false);
    setValue('inAppSettings', inAppNotificationSettings?.enabled || [], false);
  }

  async function loadTeamMemberNotificationSettings() {
    const teamMemberNotificationSettingsId = getTeamMemberNotificationSettingsId();
    let teamMemberNotificationSettings = null;
    if (teamMemberNotificationSettingsId) {
      teamMemberNotificationSettings = await fetchNotificationSettings(
        teamMemberNotificationSettingsId,
        user.owner
      );
    }

    const notificationSettings =
      teamMemberNotificationSettings || getSettingsByKey(FEATURE_TEAM_MEMBER_NOTIFICATION_SETTINGS);

    if (!notificationSettings) {
      return;
    }

    updateNotificationSettings(notificationSettings);
  }

  async function loadNotificationSettings() {
    const projectNotificationSettingsId = getProjectNotificationSettingsId();
    if (projectNotificationSettingsId) {
      const projectNotificationSettings = await fetchNotificationSettings(
        projectNotificationSettingsId,
        user.owner
      );
      if (projectNotificationSettings) {
        updateNotificationSettings(projectNotificationSettings);
        return;
      }
    }
    await loadTeamMemberNotificationSettings();
  }

  useEffect(() => {
    loadNotificationSettings();
  }, [teamMembers?.length]);

  function handleAddEmail(email) {
    const newEmailNotifications = [...emailNotifications, email];
    setValue('emailNotifications', newEmailNotifications);
  }

  function handleDeleteEmail(email) {
    const newEmailNotifications = emailNotifications.filter(e => e !== email);
    setValue('emailNotifications', newEmailNotifications);
  }

  function handleViewerNotificationLevelChange(e) {
    setValue('viewerNotificationLevel', e.target.value);
  }

  function setRestored(value) {
    setValue('isRestored', value);
  }

  const handleSetEmailSettingProperty = (propertyName, enabled) => {
    setRestored(false);
    setValue(
      'emailSettings',
      enabled
        ? [...emailSettings, propertyName]
        : emailSettings.filter(emailSetting => emailSetting !== propertyName)
    );
  };

  const handleSetInAppSettingProperty = (propertyName, enabled) => {
    setValue(
      'inAppSettings',
      enabled
        ? [...inAppSettings, propertyName]
        : inAppSettings.filter(inAppSetting => inAppSetting !== propertyName)
    );
  };

  const handleSetInAppShowAnonymous = enabled => {
    setRestored(false);
    setValue('isInAppShowAnonymous', enabled);
  };

  const handleSetEmailShowAnonymous = enabled => {
    setRestored(false);
    setValue('isEmailShowAnonymous', enabled);
  };

  const handleRestoreClick = async () => {
    setRestored(true);
    await loadTeamMemberNotificationSettings();
  };

  const handleGoToMainSettingsClick = () => {
    history.push(Routes.getTeamMemberNotificationsRoute());
  };

  const handleAccordionClick = isExpanding => {
    if (!isExpanding) {
      return;
    }

    containerRef?.current.scrollIntoView({ behavior: 'smooth' });
  };

  const disabled = !project || !currentTeamMember || project.owner !== currentTeamMember.userId;
  return (
    <LayoutRoot>
      <MainContainer>
        <BasicAccordion
          header={<AccordionTitle>Notifications</AccordionTitle>}
          startExpanded={false}
          handleClick={handleAccordionClick}
        >
          <div ref={containerRef}>
            <SectionSubtitle>
              Set which notifications you’d like to receive. You can add multiple emails to receive
              notifications.
            </SectionSubtitle>

            <ChipInput
              value={emailNotifications}
              variant="outlined"
              label={'Email notifications'}
              onBeforeAdd={Utils.isEmail}
              onAdd={handleAddEmail}
              onDelete={handleDeleteEmail}
            />
            <Gap8VerticalFlex>
              {disabled && (
                <SettingsDisabledText>
                  * Notification settings are disabled, please contact the Tolstoy&apos;s creator to
                  edit them.
                </SettingsDisabledText>
              )}
              <StyledEmailNotifications
                disabled={disabled}
                emailSettings={emailSettings}
                setEmailSettingProperty={handleSetEmailSettingProperty}
                isEmailShowAnonymous={isEmailShowAnonymous}
                setEmailShowAnonymous={handleSetEmailShowAnonymous}
                isInAppShowAnonymous={isInAppShowAnonymous}
                inAppSettings={inAppSettings}
                setInAppShowAnonymous={handleSetInAppShowAnonymous}
                setInAppSettingProperty={handleSetInAppSettingProperty}
              />
              <MainSettingsButtonsContainer>
                <Button onClick={handleGoToMainSettingsClick}>Edit main settings</Button>
                <Button disabled={disabled} type={Types.Secondary} onClick={handleRestoreClick}>
                  Restore to main settings
                </Button>
              </MainSettingsButtonsContainer>
            </Gap8VerticalFlex>
            <VerticalOrientationTitle>Respondent Notifications</VerticalOrientationTitle>
            <SectionSubtitle>
              {`Respondent notifications allow you to automatically email someone when they answer your 
            ${projectDisplayType}. Configure which notifications your respondents will receive.`}
            </SectionSubtitle>

            <AccountNotificationLevelContainer>
              <StyledSelect
                name="viewerNotificationLevel"
                label="Respondent event notifications"
                variant="outlined"
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                value={viewerNotificationLevel || 1}
                onChange={handleViewerNotificationLevelChange}
              >
                <MenuItem value={4}>User has reached end</MenuItem>
                <MenuItem value={3}>Video response</MenuItem>
                <MenuItem value={2}>Contact form response</MenuItem>
                <MenuItem value={1}>None</MenuItem>
              </StyledSelect>
              <InfoIconWithTooltip
                title="Choose events that will trigger email notifications for your audience."
                alignToCenter
              />
            </AccountNotificationLevelContainer>
          </div>
        </BasicAccordion>
      </MainContainer>
    </LayoutRoot>
  );
}

export default Notifications;

const LayoutRoot = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    padding: 16px;
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 630px;
`;

const VerticalOrientationTitle = styled(TextH6)`
  margin-top: 40px;
`;

const AccountNotificationLevelContainer = styled(NotificationLevelContainer)`
  margin-top: 0;
  & + & {
    margin-top: 24px;
  }
`;

const SettingsDisabledText = styled(TextSmall)`
  margin-top: 16px;
  color: ${({ theme }) => theme.colors.gray2};
`;

const StyledEmailNotifications = styled(NotificationSettingsContainer)`
  margin-top: 8px;
  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.6;
          pointer-events: none;
          filter: grayscale(100%);
        `
      : ''}
`;

const MainSettingsButtonsContainer = styled(Gap8HorizontalFlexWrap)`
  margin-top: 16px;
`;
