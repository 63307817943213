import * as React from 'react';

const AsteriskIcon = ({ fill, ...props }) => (
  <svg
    width={10}
    height={11}
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.547 8.125a.565.565 0 0 0-.211-.75L6.125 5.5l3.21-1.852c.259-.14.352-.492.212-.773-.164-.258-.516-.352-.774-.188L5.563 4.54V.813A.57.57 0 0 0 5 .25a.555.555 0 0 0-.563.563v3.726L1.204 2.688a.537.537 0 0 0-.75.187c-.164.281-.07.633.188.773L3.875 5.5.641 7.375a.537.537 0 0 0-.188.75c.094.188.281.281.492.281a.472.472 0 0 0 .258-.07l3.234-1.852v3.704c0 .328.235.562.563.562a.555.555 0 0 0 .563-.563V6.485l3.21 1.852a.578.578 0 0 0 .773-.21Z"
      fill={fill || '#090A0B'}
    />
  </svg>
);

export default AsteriskIcon;
