import React from 'react';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextOverline, TextTiny } from 'shared/react/components/complex/Text';
import usePlayerUrl from '../../../hooks/usePlayerUrl';

const LinkPreview = ({ gifUrl }) => {
  const { domain } = usePlayerUrl();

  return (
    <LayoutRoot>
      <Gif src={gifUrl} />
      <TextContainer>
        <Header>Check this video out!</Header>
        <Subtitle>{domain}</Subtitle>
      </TextContainer>
    </LayoutRoot>
  );
};

export default LinkPreview;

const LayoutRoot = styled(VerticalFlex)`
  background: ${({ theme }) => theme.colors.gray5};
  border-radius: 16px;
  max-width: 208px;
  height: min-content;
  box-shadow: 0 5px 15px rgba(50, 50, 93, 0.1), 0 2px 5px rgba(0, 0, 0, 0.06);

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    display: none;
  }
`;

const TextContainer = styled(VerticalFlex)`
  padding: 12px;
  gap: 4px;
`;

const Header = styled(TextOverline)`
  font-size: 12px;
  line-height: 12px;
`;

const Subtitle = styled(TextTiny)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.ghostDark};
`;

const Gif = styled.img`
  max-width: 208px;
  max-height: 230px;
  border-radius: 12px;
`;
