export const EXTENSION_EVENT_KEY = 'tolstoyExtension';
export const SHOULD_GET_SIGN_URL = 'shouldGetSignUrl';
export const IS_UPLOAD_FINISHED = 'isUploadFinished';
export const GET_SIGN_URL = 'getSignUrl';
export const GET_RECORDING_DATA = 'getRecordingData';
export const RECORDING_DATA_PROCESSED = 'recordingDataProcessed';
export const UPLOAD_FINISH = 'uploadFinish';
export const UPLOAD_FAIL = 'uploadFail';
export const SEND_URL = 'sendUrl';
export const OPEN_EXTENSION = 'openExtension';
export const LOGOUT = 'logout';
export const LOGIN = 'login';
export const PROJECT_CREATED = 'projectCreated';
