import { createPortal } from 'react-dom';
import usePortal from 'shared/react/hooks/usePortal';

const Portal = ({ children }) => {
  const target = usePortal('portal');

  return createPortal(children, target);
};

export default Portal;
