import React, { SyntheticEvent, useState } from 'react';
import { ComparisonOperator, WhereCondition } from 'src/pages/dashboard/utils/filterEntities';
import useActions from 'src/pages/dashboard/components/filters/hooks/useActions';
import ComparatorMenu from 'src/pages/dashboard/components/filters/components/comparator-menu/ComparatorMenu';
import FilterByKeyMenu from 'src/pages/dashboard/components/filters/components/filter-by-key-menu/FilterByKeyMenu';
import CurrentFiltersGroup from 'app/src/pages/dashboard/components/filters/components/current-filters/CurrentFiltersGroup';
import { MenuVariants } from 'app/src/pages/dashboard/components/filters/constants/filter.constants';

type Props = {
  filters: WhereCondition;
  setFilters: (filters: { [key: string]: ComparisonOperator }) => void;
  actions: ReturnType<typeof useActions>;
};

const defaultMenuDataState = { ref: null, key: null, type: null };

const CurrentFilters = ({ filters, setFilters, actions }: Props) => {
  const [menuData, setMenuData] = useState(defaultMenuDataState);

  const onMenuToggle = (type: MenuVariants, key?: string) => (event?: SyntheticEvent) => {
    if (!event || menuData.ref) {
      setMenuData(defaultMenuDataState);
      return;
    }

    setMenuData({ ref: event.target, key, type });
  };

  const onRemoveFilter = (key: string) => {
    setFilters({ [key]: undefined });
  };

  const MenuComponent =
    menuData.type === MenuVariants.comparator ? ComparatorMenu : FilterByKeyMenu;

  return (
    <>
      {Object.entries(filters).map(([key, filter]) => {
        return (
          <CurrentFiltersGroup
            onRemoveFilter={onRemoveFilter}
            actions={actions}
            key={key}
            currentKey={key}
            filter={filter}
            onMenuToggle={onMenuToggle}
          />
        );
      })}
      <MenuComponent
        filterKey={menuData.key}
        anchorRef={menuData.ref}
        onClose={onMenuToggle(menuData.type)}
        currentFilter={filters[menuData.key]}
        setFilters={setFilters}
        actions={actions}
      />
    </>
  );
};

export default CurrentFilters;
