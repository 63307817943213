import React from 'react';

const DescriptionAnswersIcon = () => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="#E2EDFE"
      d="M12 0a12 12 0 0 1 12 12 12 12 0 0 1-12 12A12 12 0 0 1 0 12 12 12 0 0 1 12 0z"
    />
    <path
      d="M16.5 15.25h-9a.755.755 0 0 0-.75.75c0 .422.328.75.75.75h9c.398 0 .75-.328.75-.75a.771.771 0 0 0-.75-.75Zm-9-4.5h9c.398 0 .75-.328.75-.75a.771.771 0 0 0-.75-.75h-9a.755.755 0 0 0-.75.75c0 .422.328.75.75.75Zm0-3h5.25c.399 0 .75-.328.75-.75a.77.77 0 0 0-.75-.75H7.5a.755.755 0 0 0-.75.75c0 .422.328.75.75.75Zm0 6h5.25c.399 0 .75-.328.75-.75a.77.77 0 0 0-.75-.75H7.5a.755.755 0 0 0-.75.75c0 .422.328.75.75.75Z"
      fill="#3D4FE0"
    />
  </svg>
);

export default DescriptionAnswersIcon;
