import React from 'react';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import styled from 'styled-components';
import useFeedProducts from 'shared/react/components/complex/context/hooks/useFeedProducts';
import FeedProductsVariants from 'shared/react/components/complex/feed/feed_products/feed_product_variants/FeedProductsVariants';

const FeedProductOptions = ({
  productOptions,
  handleProductOptionSelected,
  product,
  selectedVariant,
  variants,
  ...props
}) => {
  const [, { getProductExtraData }] = useFeedProducts();
  const { color, variants: extraVariantData } = getProductExtraData(product?.id) || {};

  if (!Object.entries(productOptions).length) {
    return null;
  }

  return (
    <LayoutRoot {...props}>
      {Object.entries(productOptions).map(([position, { values, selectedValue, name }]) => (
        <FeedProductsVariants
          key={name}
          name={name}
          color={color}
          values={values}
          selectedValue={selectedValue}
          handleProductOptionSelected={handleProductOptionSelected}
          optionPosition={position}
          selectedVariant={selectedVariant}
          variants={variants}
          extraVariantData={extraVariantData}
        />
      ))}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)``;

export default FeedProductOptions;
