import React from 'react';
import styled from 'styled-components';
import { BASIC_BUTTON_DATA_TEST_ID } from '../../constants/dataTestIds.constants';
import Utils from '../../utils/utils';

function Button({
  children,
  width,
  height,
  onClick,
  borderRadius,
  fontWeight,
  borderColor,
  hoverBorderColor,
  activeBorderColor,
  leftIcon,
  rightIcon,
  capitalize,
  backgroundColor,
  hoverBackgroundColor,
  activeBackgroundColor,
  textColor,
  hoverTextColor,
  activeTextColor,
  fontSize,
  disabled,
  padding,
  dataType,
  dataTestId = BASIC_BUTTON_DATA_TEST_ID,
  ...props
}) {
  backgroundColor = backgroundColor || 'black';

  return (
    <LayoutRoot
      data-test-id={dataTestId}
      data-type={dataType}
      width={width}
      height={height}
      padding={children ? (!Utils.isNullOrUndefined(padding) ? padding : '0 20px') : '0'}
      borderRadius={borderRadius}
      fontWeight={fontWeight}
      onClick={onClick}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      hoverBorderColor={hoverBorderColor}
      activeBorderColor={activeBorderColor}
      hoverBackgroundColor={hoverBackgroundColor}
      activeBackgroundColor={activeBackgroundColor}
      textColor={textColor}
      capitalize={capitalize}
      hoverTextColor={hoverTextColor}
      activeTextColor={activeTextColor}
      fontSize={fontSize}
      disabled={disabled}
      {...props}
    >
      {leftIcon && <LeftImage marginRight={children ? '6px' : '0'}>{leftIcon}</LeftImage>}
      {children}
      {rightIcon && <RightImage marginLeft={children ? '6px' : '0'}>{rightIcon}</RightImage>}
    </LayoutRoot>
  );
}

export default Button;

const LayoutRoot = styled.div`
  z-index: 1;
  grid-row: 1;
  grid-column: 1;
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  text-align: center;
  text-transform: ${props => (props.capitalize ? 'capitalize' : 'none')};
  font-weight: ${props => props.fontWeight || 400};
  padding: ${props => props.padding};
  background: ${props => props.backgroundColor};
  border: ${props => (props.borderColor ? '1px solid black' : '0')};
  border-color: ${props => props.borderColor || 'black'};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  opacity: ${props => (props.disabled ? '0.25' : '1')};
  cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
  width: fit-content;
  min-width: ${props => props.width || 'fit-content'};
  height: ${props => props.height || 'fit-content'};
  border-radius: ${props => props.borderRadius || '0'};
  font-size: ${props => props.fontSize || '12px'};
  line-height: ${props => props.fontSize || '12px'};
  color: ${props => props.textColor || 'white'};
  transition: 0.3s;

  &:hover {
    color: ${props => props.hoverTextColor || props.textColor};
    background: ${props => props.hoverBackgroundColor};
    border-color: ${props => props.hoverBorderColor};
  }

  &:focus,
  &:active {
    color: ${props => props.activeTextColor || props.textColor};
    background: ${props => props.activeBackgroundColor};
    border-color: ${props => props.activeBorderColor};
  }
`;

const LeftImage = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${props => props.marginRight};
`;

const RightImage = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${props => props.marginLeft};
`;
