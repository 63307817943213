import React from 'react';
import styled from 'styled-components';
import { TextBody, TextBodyLeading, TextH6, TextTiny } from 'shared/react/components/complex/Text';
import Card from 'app/src/basic_components/Card';
import UploadMethodTooltip from 'app/src/pages/creation_flow/right_section/templates/UploadMethodTooltip';

const TYPE_DELIMITER = '_';

const getFormattedType = type => {
  if (type.replaceAll) {
    return type.replaceAll(TYPE_DELIMITER, ' ');
  }
  if (type.includes(TYPE_DELIMITER)) {
    // old browser support
    return getFormattedType(type.replace(TYPE_DELIMITER, ' '));
  }
  return type;
};

const getDisplayType = type => {
  const formattedType = getFormattedType(type);
  return `${formattedType[0].toUpperCase()}${formattedType.slice(1)}`;
};

function UploadMethodOption({
  icon,
  title,
  subtitle,
  onClick,
  centerText,
  isNew,
  type,
  disabled,
  hasReachedVideosLimit,
  isSafari,
  tooltipPlacement,
  ...props
}) {
  return (
    <StyledCard
      data-testid="UploadMethodOption-Card"
      onClick={onClick}
      shadowAnimationsEnabled={!disabled}
      disabled={disabled}
    >
      {isNew && (
        <NewContainer>
          <NewText>new</NewText>
        </NewContainer>
      )}
      {type && (
        <StyledType type={type}>
          <TextTiny>{getDisplayType(type)}</TextTiny>
        </StyledType>
      )}
      <UploadMethodTooltip
        disabled={disabled}
        isSafari={isSafari}
        tooltipPlacement={tooltipPlacement}
      >
        <LayoutRoot {...props}>
          <IconContainer>{icon}</IconContainer>
          <TextContainer center={centerText}>
            <Title>{title}</Title>
            {subtitle && <SubtitleText>{subtitle}</SubtitleText>}
          </TextContainer>
        </LayoutRoot>
      </UploadMethodTooltip>
    </StyledCard>
  );
}

export default UploadMethodOption;

const StyledCard = styled(Card)`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const LayoutRoot = styled.div`
  display: grid;
  grid-template-columns: ${({ horizontal }) => (horizontal ? 'auto 1fr' : 'auto')};
  gap: 8px;
  padding: 24px;
  align-items: center;
  height: 100%;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    grid-template-columns: auto 1fr;
    column-gap: 24px;
    padding: 24px;
  }
`;

const Title = styled(TextH6)``;

const TextContainer = styled.div`
  position: relative;
  display: grid;
  align-self: center;
  justify-self: ${props => (props.center ? 'center' : 'start')};
  justify-items: ${props => (props.center ? 'center' : 'start')};
`;

const NewContainer = styled.div`
  position: absolute;
  background: #d3f8ec;
  padding: 4px 12px;
  border-radius: 4px;
  top: -12px;
  right: 20px;
`;

const NewText = styled(TextBodyLeading)`
  color: ${({ theme }) => theme.colors.success};
`;

const SubtitleText = styled(TextBody)`
  white-space: pre-line;
`;

const IconContainer = styled.div`
  min-width: 56px;
`;

const StyledType = styled.div`
  position: absolute;
  right: 0;
  margin-top: 7px;
  margin-right: 10px;
  padding: 3px 8px;
  background-color: ${({ type }) => (type === 'guide' ? '#FFC728' : '#bbfdf7')};
  border-radius: 12px;
`;
