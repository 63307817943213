import React from 'react';

const ReplayIcon = () => (
  <svg width={20} height={17} viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.665 16.795a1.07 1.07 0 0 1-.793.193 8.174 8.174 0 0 1-3.362-1.33 8.148 8.148 0 0 1-1.384-1.194A8.064 8.064 0 0 1 2.044 13a8.147 8.147 0 0 1-.816-6.196 8.115 8.115 0 0 1 1.411-2.86 8.124 8.124 0 0 1 2.396-2.098 8.147 8.147 0 0 1 6.196-.816 8.116 8.116 0 0 1 2.86 1.411 8.124 8.124 0 0 1 2.099 2.396 8.145 8.145 0 0 1 .522 7.08l-1.751-1.471c.15-.68.228-1.62.135-2.32a6.072 6.072 0 0 0-2.304-3.993 6.036 6.036 0 0 0-2.114-1.039 6.015 6.015 0 0 0-4.572.603c-.707.41-1.302.94-1.77 1.546a6.036 6.036 0 0 0-1.04 2.114A6.016 6.016 0 0 0 4.7 13.015c.306.332.65.626 1.017.877a6.007 6.007 0 0 0 2.493.982 1.069 1.069 0 0 1 .889 1.217c-.047.29-.205.538-.426.696l-.007.008Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.707 8.6c.194 1.926.383 3.847.572 5.771 1.925-.193 3.846-.386 5.771-.575-2.114-1.732-4.228-3.464-6.346-5.195h.003Z"
      fill="#fff"
    />
  </svg>
);

export default ReplayIcon;
