import React from 'react';
import styled from 'styled-components';
import EditorSliderItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorSliderItem';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import {
  CAROUSEL_TYPE,
  CAROUSEL_BORDER_RADIUS_PROPERTY_KEY,
  CAROUSEL_MOTION_PROPERTY_KEY,
  CAROUSEL_PLAY_IN_TILE_FIRST_PROPERTY_KEY,
  CAROUSEL_SPACING_HORIZONTAL_PROPERTY_KEY,
  CAROUSEL_PADDING_HORIZONTAL_PROPERTY_KEY,
  CAROUSEL_PADDING_VERTICAL_PROPERTY_KEY,
  EMBED_CAROUSEL_MOTION,
  EMBED_CAROUSEL_ITEM_SIZE_TYPE,
  CAROUSEL_ITEM_SIZE_TYPE_PROPERTY_KEY,
  CAROUSEL_ITEMS_PER_ROW_PROPERTY_KEY,
  CAROUSEL_ITEM_HEIGHT_PROPERTY_KEY,
  MINIMUM_NUMBER_OF_TILES_PROPERTY_KEY,
  VIDEOS_FROM_LAST_X_DAYS_KEY,
} from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import EditorButtonGroupItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorButtonGroupItem';
import EditorOnOffToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorOnOffToggleItem';
import {
  FEATURE_WIDGET_SPOTLIGHT_CAROUSEL,
  FEATURE_CAROUSEL_PLAY_IN_TILE_FIRST,
} from 'app/src/constants/appFeatures.constants';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import EditorNumericInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorNumericInputItem';
import { SpacingHorizontalIcon } from 'app/src/images/feedCarouselEditor/SpacingHorizontalIcon';
import { PaddingHorizontalIcon } from 'app/src/images/feedCarouselEditor/PaddingHorizontalIcon';
import { PaddingVerticalIcon } from 'app/src/images/feedCarouselEditor/PaddingVerticalIcon';
import MinimumNumberOfVideos from 'src/pages/project/pages/look_and_feel/editor/editors/shared/MinimumNumberOfVideos';

export const FeedDefaultCarouselSettings = ({ disabled }) => {
  const { getFeatureEnabled } = useFeatureActions();
  const isPlayInTileFirstEnabled = getFeatureEnabled(FEATURE_CAROUSEL_PLAY_IN_TILE_FIRST);
  const isSpotlightCarouselEnabled = getFeatureEnabled(FEATURE_WIDGET_SPOTLIGHT_CAROUSEL);

  const {
    customizationSettings: {
      widgetSettings: {
        carouselBorderRadius,
        carouselMotion,
        carouselPlayInTileFirst,
        carouselItemSizeType,
        carouselItemsPerRow,
        carouselItemHeight,
        carouselSpacingHorizontal,
        carouselPaddingHorizontal,
        carouselPaddingVertical,
        minimumNumberOfTiles,
        videosFromLastXDays,
        carouselType,
      },
    },
    setWidgetSettingsProperty,
    updateCustomizationSettings,
  } = useLookAndFeelContext();
  const isSpotlightCarousel =
    isSpotlightCarouselEnabled && carouselType === CAROUSEL_TYPE.SPOTLIGHT;

  const onPlayerAndWidgetSettingChange = (propertyKey, value) => {
    updateCustomizationSettings({
      playerSettings: {
        [propertyKey]: value,
      },
      widgetSettings: {
        [propertyKey]: value,
      },
    });
  };

  const onBorderRadiusChange = (event, value) => {
    setWidgetSettingsProperty(CAROUSEL_BORDER_RADIUS_PROPERTY_KEY, value);
  };

  const onPlayVideoInTileChange = value => {
    setWidgetSettingsProperty(CAROUSEL_PLAY_IN_TILE_FIRST_PROPERTY_KEY, value);
  };

  const onItemsPerRowChange = value => {
    setWidgetSettingsProperty(CAROUSEL_ITEMS_PER_ROW_PROPERTY_KEY, value);
  };
  const onItemHeightChange = value => {
    setWidgetSettingsProperty(CAROUSEL_ITEM_HEIGHT_PROPERTY_KEY, value);
  };
  const onHorizontalSpacingChange = value => {
    setWidgetSettingsProperty(CAROUSEL_SPACING_HORIZONTAL_PROPERTY_KEY, value);
  };

  const onMinimumNumberOfTilesChange = value => {
    setWidgetSettingsProperty(MINIMUM_NUMBER_OF_TILES_PROPERTY_KEY, value);
  };

  const onHorizontalPaddingChange = value => {
    setWidgetSettingsProperty(CAROUSEL_PADDING_HORIZONTAL_PROPERTY_KEY, value);
  };

  const onVerticalPaddingChange = value => {
    setWidgetSettingsProperty(CAROUSEL_PADDING_VERTICAL_PROPERTY_KEY, value);
  };

  const onVideosFromLastXDaysChange = value => {
    onPlayerAndWidgetSettingChange(VIDEOS_FROM_LAST_X_DAYS_KEY, value);
  };

  const motionButtons = [
    {
      text: 'Static',
      isEnabled: carouselMotion === EMBED_CAROUSEL_MOTION.static,
      onClick: () =>
        setWidgetSettingsProperty(CAROUSEL_MOTION_PROPERTY_KEY, EMBED_CAROUSEL_MOTION.static),
    },
    {
      text: 'Dynamic',
      isEnabled: carouselMotion === EMBED_CAROUSEL_MOTION.dynamic,
      onClick: () =>
        setWidgetSettingsProperty(CAROUSEL_MOTION_PROPERTY_KEY, EMBED_CAROUSEL_MOTION.dynamic),
    },
    {
      text: 'Hover over',
      isEnabled: carouselMotion === EMBED_CAROUSEL_MOTION.hoverOver,
      onClick: () =>
        setWidgetSettingsProperty(CAROUSEL_MOTION_PROPERTY_KEY, EMBED_CAROUSEL_MOTION.hoverOver),
    },
  ];

  const itemSizeTypeButtons = [
    {
      text: 'Responsive',
      isEnabled: carouselItemSizeType === EMBED_CAROUSEL_ITEM_SIZE_TYPE.responsive,
      onClick: () =>
        setWidgetSettingsProperty(
          CAROUSEL_ITEM_SIZE_TYPE_PROPERTY_KEY,
          EMBED_CAROUSEL_ITEM_SIZE_TYPE.responsive
        ),
    },
    {
      text: 'Fixed',
      isEnabled: carouselItemSizeType === EMBED_CAROUSEL_ITEM_SIZE_TYPE.fixed,
      onClick: () =>
        setWidgetSettingsProperty(
          CAROUSEL_ITEM_SIZE_TYPE_PROPERTY_KEY,
          EMBED_CAROUSEL_ITEM_SIZE_TYPE.fixed
        ),
    },
  ];

  return (
    <>
      <MinimumNumberOfVideos
        value={minimumNumberOfTiles}
        onChange={onMinimumNumberOfTilesChange}
      />
      <StyledEditorOnOffToggleItem
        text="Show videos from the last 30 days"
        checked={videosFromLastXDays}
        onChange={onVideosFromLastXDaysChange}
        tooltipText="Toggle on to display only the videos in your playlist that were created within the last 30 days. This setting ensures the playlist remains updated with the most recent content."
        isToggleEnabled={isSpotlightCarousel}
      />
      <EditorSliderItem
        onChange={onBorderRadiusChange}
        text="Border radius"
        value={carouselBorderRadius}
        min={0}
        max={50}
        disabled={disabled || isSpotlightCarousel}
      />
      <EditorButtonGroupItem
        text="Motion"
        buttons={motionButtons}
        disabled={disabled || isSpotlightCarousel}
      />
      <StyledEditorOnOffToggleItem
        text="Play video in tile"
        checked={carouselPlayInTileFirst}
        onChange={onPlayVideoInTileChange}
        tooltipText="Click on play to start the video. Click on the expand arrow to open the full feed."
        isToggleEnabled={isPlayInTileFirstEnabled}
        disabled={isSpotlightCarousel}
      />

      <EditorButtonGroupItem
        text="Item size"
        buttons={itemSizeTypeButtons}
        disabled={disabled || isSpotlightCarousel}
      />
      {carouselItemSizeType === EMBED_CAROUSEL_ITEM_SIZE_TYPE.responsive && (
        <EditorNumericInputItem
          text="Items per row"
          value={carouselItemsPerRow}
          onChange={onItemsPerRowChange}
          min={2}
          max={6}
          disabled={disabled || isSpotlightCarousel}
        />
      )}
      {carouselItemSizeType === EMBED_CAROUSEL_ITEM_SIZE_TYPE.fixed && (
        <EditorNumericInputItem
          text="Item height"
          suffix="px"
          value={carouselItemHeight}
          onChange={onItemHeightChange}
          min={142}
          max={800}
          disabled={disabled || isSpotlightCarousel}
        />
      )}

      <EditorNumericInputItem
        text="Horizontal spacing"
        suffix="px"
        value={carouselSpacingHorizontal}
        onChange={onHorizontalSpacingChange}
        tooltipText={<SpacingHorizontalIcon />}
        max={56}
        disabled={disabled || isSpotlightCarousel}
      />
      <EditorNumericInputItem
        text="Horizontal padding"
        suffix="px"
        value={carouselPaddingHorizontal}
        onChange={onHorizontalPaddingChange}
        tooltipText={<PaddingHorizontalIcon />}
        max={120}
        disabled={disabled}
      />
      <EditorNumericInputItem
        text="Vertical padding"
        suffix="px"
        value={carouselPaddingVertical}
        onChange={onVerticalPaddingChange}
        tooltipText={<PaddingVerticalIcon />}
        max={120}
        disabled={disabled || isSpotlightCarousel}
      />
    </>
  );
};

const StyledEditorOnOffToggleItem = styled(EditorOnOffToggleItem)`
  display: ${({ isToggleEnabled }) => (isToggleEnabled ? '' : 'none')};
`;
