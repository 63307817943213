import React from 'react';

const EmailSmsCampaignIcon = () => {
  return (
    <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36.4099 6.98903L36.5 6.91406V6.79688V5C36.5 4.60619 36.3056 4.23331 36.0362 3.96385C35.7667 3.69439 35.3938 3.5 35 3.5H5C4.14889 3.5 3.5 4.24706 3.5 5V6.79688V6.91361L3.5895 6.98856L16.5583 17.8479L16.5596 17.8489C18.4488 19.4097 21.473 19.4097 23.3623 17.8489L23.363 17.8484L36.4099 6.98903ZM3.9106 11.449L3.5 11.1048V11.6406V25C3.5 25.8412 4.15881 26.5 5 26.5H35C35.7529 26.5 36.5 25.8511 36.5 25V11.6406V11.1073L36.0901 11.4484L25.3907 20.3517C25.3901 20.3522 25.3895 20.3527 25.3889 20.3532C23.8649 21.5717 21.9663 22.3281 20 22.3281C17.9545 22.3281 16.0563 21.571 14.5334 20.3534L3.9106 11.449ZM5 0.25H35C37.5963 0.25 39.75 2.40369 39.75 5V25C39.75 27.6719 37.5989 29.75 35 29.75H5C2.32557 29.75 0.25 27.6744 0.25 25V5C0.25 2.40114 2.32811 0.25 5 0.25Z"
        fill="url(#paint0_linear_29354_74612)"
        stroke="white"
        strokeWidth="0.5"
      />
      <defs>
        <linearGradient
          id="paint0_linear_29354_74612"
          x1="0"
          y1="30"
          x2="40"
          y2="30"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4558D0" />
          <stop offset="0.5" stopColor="#C852BE" />
          <stop offset="1" stopColor="#FBC276" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default EmailSmsCampaignIcon;
