import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Stepper from 'app/src/complex_components/Stepper';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import Utils from 'app/src/utils';
import { track } from 'app/src/helpers/Tracker';
import CopyCodeButtonPricing from 'app/src/complex_components/pricing/CopyCodeButtonPricing';
import CodeContainerPricing from 'app/src/complex_components/pricing/CodeContainerPricing';
import { TextBodyLeadingBold, TextSmall } from 'shared/react/components/complex/Text';
import InfoIconWithTooltip from 'app/src/complex_components/InfoIconWithTooltip';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import WidgetSettings from 'app/src/pages/modals/publish/widget/WidgetSettings';
import { projectTabs } from 'app/src/helpers/Routes';
import { useHistory } from 'react-router-dom';
import { STORIES_CATEGORY_KEY } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';
import { useApps } from 'app/src/context/AppsStore';
import { useBilling } from 'app/src/hooks/useBillings';
import FeedEmbedWidgetsShopifyStepper from 'app/src/pages/modals/publish/embed/feed_embed_widgets/FeedEmbedWidgetsShopifyStepper';
import { PUBLISH_METHODS } from 'shared/react/constants/tolstoy.constants';
import EmbedCategories from 'app/src/pages/modals/publish/embed/EmbedCategories';
import { PAGE_TYPES } from 'app/src/constants/publish.constants';
import EmbedPDPByUrl from 'app/src/pages/modals/publish/embed/feed_embed_widgets/EmbedPDPByUrl';
import {
  FEATURE_FETCH_BY_URL,
  FEATURE_SHOPIFY_TAGS,
} from 'app/src/constants/appFeatures.constants';
import { useFeatures } from 'app/src/context/FeaturesStore';
import useNavigation from 'app/src/hooks/useNavigation';
import { useProductPageSettingsActions } from 'app/src/context/ProductPageSettingsStore';
import SetLiveToggle from './SetLiveToggle';
import usePublishFeature from './hooks/usePublishFeature';
import { publishMethodOptions } from 'app/src/types/entities';

function FeedEmbedWidgetsStepper({ project, widgetType }) {
  const [, { setSnackbar }] = useSnackBar();
  const [{ shopify }] = useApps();
  const { embedSetLiveEnabled } = useBilling();
  const history = useHistory();
  const [, { getFeatureEnabled }] = useFeatures();
  const { shouldShowPublishToggle } = usePublishFeature(project);
  const { isProductPage } = useProductPageSettingsActions();
  const isFetchByUrlFeatureEnabled = getFeatureEnabled(FEATURE_FETCH_BY_URL);
  const [currentPage, setCurrentPage] = useState(PAGE_TYPES.default);
  const disableProducts = !project || !embedSetLiveEnabled;
  const { dynamic: isDynamic } = project;
  const { getProjectTabPath } = useNavigation();
  const isShopifyTagsEnabled = getFeatureEnabled(FEATURE_SHOPIFY_TAGS);

  const getWidgetCode = () => {
    return `<script>tolstoyAppKey="${project.appKey}"</script>
      <script
        type="module"
        async
        src="https://widget.gotolstoy.com/we/widget.js"
      >
      </script>
      <script
        type="text/javascript"
        nomodule
        async
        src="https://widget.gotolstoy.com/widget/widget.js"
      ></script>
`;
  };

  const getCode = () => {
    if (isDynamic) {
      if (isShopifyTagsEnabled) {
        return `<div data-tags="{% for tag in product.tags -%}{{ tag }},{%- endfor %}" data-publish-id='${project.publishId}' data-product-id="{{ product.id }}" class='tolstoy-${widgetType}'></div>`;
      }

      return `<div  data-publish-id='${project.publishId}' data-product-id="{{ product.id }}" class='tolstoy-${widgetType}'></div>`;
    }

    if (currentPage === PAGE_TYPES.product) {
      return `<div data-publish-id='YOUR_FALLBACK_PUBLISH_ID' class='tolstoy-${widgetType}'></div>`;
    }

    return `<div data-publish-id='${project.publishId}' class='tolstoy-${widgetType}'></div>`;
  };

  const getProductCode = () => {
    if (isShopifyTagsEnabled) {
      return `<div data-tags="{% for tag in product.tags -%}{{ tag }},{%- endfor %}" data-product-id="{{ product.id }}" class='tolstoy-${widgetType}'></div>`;
    }

    return `<div  data-product-id="{{ product.id }}" class='tolstoy-${widgetType}'></div>`;
  };

  const copyWidgetCode = () => {
    Utils.copyToClipboard(getWidgetCode());
    setSnackbar('The code copied to your clipboard!');
    track('Copy Feed Widget Code Click');
  };

  const copyCode = () => {
    Utils.copyToClipboard(getCode());
    setSnackbar('The code copied to your clipboard!');
    track(`Copy Embed ${Utils.upperCaseFirstChar(widgetType)} Code Click`);
  };

  const copyProductCode = () => {
    Utils.copyToClipboard(getProductCode());
    setSnackbar('The code copied to your clipboard!');
    track(`Copy Embed ${Utils.upperCaseFirstChar(widgetType)} Code Click`);
  };

  const getSelectedCategory = () => {
    if (widgetType === PUBLISH_METHODS[publishMethodOptions.carousel].widgetType) {
      return publishMethodOptions.carousel;
    }

    return STORIES_CATEGORY_KEY;
  };

  const handleCustomizeAppearance = () => {
    const path = getProjectTabPath(project.id, projectTabs.design);
    history.push(`${path}?selectedCategory=${getSelectedCategory()}`);
  };

  useEffect(() => {
    if (!project || !shopify) {
      return;
    }

    const isPDP = isProductPage(project);

    setCurrentPage(isPDP ? PAGE_TYPES.product : PAGE_TYPES.default);
  }, [project]);

  const disabledCopyCode = !project || !embedSetLiveEnabled;

  const getContent = () => {
    if (currentPage !== PAGE_TYPES.product) {
      return [];
    }

    return [
      <Step key={4}>
        <Header>Select product pages to publish on </Header>
        <EmbedPDPByUrl disabled={disableProducts} project={project} widgetType={widgetType} />
      </Step>,
    ];
  };

  const getPagesContent = () => {
    if (!isFetchByUrlFeatureEnabled) {
      return [];
    }

    return [
      <Step key={2}>
        <StyledTextContainer>
          Where would you like to publish your {widgetType}?
        </StyledTextContainer>
        <EmbedCategories currentPage={currentPage} setCurrentPage={setCurrentPage} />
      </Step>,
    ];
  };

  if (shopify) {
    return (
      <FeedEmbedWidgetsShopifyStepper
        copyCode={copyCode}
        getCode={getCode}
        getProductCode={getProductCode}
        copyProductCode={copyProductCode}
        handleCustomizeAppearance={handleCustomizeAppearance}
        setSnackbar={setSnackbar}
        project={project}
        widgetType={widgetType}
      />
    );
  }

  return (
    <>
      <Stepper
        stepComponents={[
          <Step key={1}>
            <FirstStepTop>
              <TextContainer>
                <Header>Copy code and paste it in your {`site's`} head</Header>
              </TextContainer>
              <CopyCodeButtonPricing
                disabled={disabledCopyCode}
                onClick={copyWidgetCode}
                location="Embed"
                tooltipHeader="embed"
              />
            </FirstStepTop>
            <TextSmallContainer>
              If you haven’t done this already
              <InfoIconWithTooltip title="If you’ve published a Tolstoy widget in the past then you can skip this step" />
            </TextSmallContainer>
            <CodeContainerPricing getCode={getWidgetCode} hide={disabledCopyCode} />
          </Step>,
          ...getPagesContent(),
          <Step key={3}>
            <FirstStepTop>
              <Header>
                Copy code and paste it on your
                {currentPage === PAGE_TYPES.product ? ' product page' : ''} template
              </Header>
              <CopyCodeContainer>
                <CopyCodeButtonPricing
                  disabled={disabledCopyCode}
                  onClick={copyCode}
                  location="Embed"
                  tooltipHeader="embed"
                />
              </CopyCodeContainer>
            </FirstStepTop>
            <CodeContainerPricing getCode={getCode} hide={disabledCopyCode} />
          </Step>,
          ...getContent(),
          <Step key="setLiveToggle" disabled={!shouldShowPublishToggle}>
            <SetLiveToggle project={project} widgetType={widgetType} />
          </Step>,
        ]}
      />
      <StyledWidgetSettings handleCustomizeAppearance={handleCustomizeAppearance} />
    </>
  );
}

const Step = styled.div`
  font-weight: 800;
  font-size: 16px;
`;

const FirstStepTop = styled(Gap8HorizontalFlexWrap)`
  align-items: center;
`;

const CopyCodeContainer = styled.div`
  margin-top: -10px;
`;

const TextContainer = styled(VerticalFlex)`
  gap: 2px;
`;

const TextSmallContainer = styled(TextSmall)`
  display: flex;
  gap: 4px;
  margin-bottom: 4px;
`;

const StyledWidgetSettings = styled(WidgetSettings)`
  margin-top: 40px;
`;

const Header = styled(TextBodyLeadingBold)`
  font-weight: 700;
`;

const StyledTextContainer = styled(Header)`
  margin-bottom: 12px;
`;

export default FeedEmbedWidgetsStepper;
