import Utils from 'app/src/utils';
import { CUBE_PROPERTY_KEYS } from 'app/src/constants/cube.constants';
import { EmailEventsOvertimeRecord } from './types/getEmailEventsOvertimeQuery.types';
import { EVENTS } from 'app/src/constants/tolstoy.constants';
import { PLAYER_USER_LOG } from 'app/src/constants/analytics.constants';
import { ResultSet } from '@cubejs-client/core';

const DEFAULT_EVENT_METRICS = {
  emailClicksRate: 0,
  numOfEmailClicks: 0,
  numOfEmailOpens: 0,
};

const getEventKey = (event: string, campaign: string, property: string) => {
  if (campaign) {
    return `${event},${campaign},${PLAYER_USER_LOG}.${property}`;
  }
  return `${event},${PLAYER_USER_LOG}.${property}`;
};

const getEmailEventsOvertime = (rawEmailEventsOvertime: ResultSet): EmailEventsOvertimeRecord[] => {
  const campaignKey = `${PLAYER_USER_LOG}.${CUBE_PROPERTY_KEYS.campaign}`;
  const campaigns = rawEmailEventsOvertime.tablePivot()?.map(data => data[campaignKey] || '');
  const uniqueCampaigns = Array.from(new Set(campaigns)) as string[];

  const countEvents = (record: Record<string, number>) =>
    uniqueCampaigns.reduce(
      (acc, campaign) => {
        const campaignName = campaign || 'Other';
        const campaignMetrics =
          acc.attributionByCampaignType[campaignName] || DEFAULT_EVENT_METRICS;

        const emailClicksKey = getEventKey(EVENTS.emailClick, campaign, CUBE_PROPERTY_KEYS.count);
        const numOfEmailClicks = record[emailClicksKey] || 0;
        const campaignNumOfEmailClicks = campaignMetrics.numOfEmailClicks + numOfEmailClicks;
        const totalNumOfEmailClicks = acc.numOfEmailClicks + numOfEmailClicks;

        const emailOpensKey = getEventKey(EVENTS.openEmail, campaign, CUBE_PROPERTY_KEYS.count);
        const numOfEmailOpens = record[emailOpensKey] || 0;
        const campaignNumOfEmailOpens = campaignMetrics.numOfEmailOpens + numOfEmailOpens;
        const totalNumOfEmailOpens = acc.numOfEmailOpens + numOfEmailOpens;

        const campaignEmailClicksRate = Utils.getPercentage(
          campaignNumOfEmailClicks,
          campaignNumOfEmailOpens
        );
        const totalEmailClicksRate = Utils.getPercentage(
          totalNumOfEmailClicks,
          totalNumOfEmailOpens
        );

        const metrics = {
          numOfEmailClicks: acc.numOfEmailClicks + numOfEmailClicks,
          numOfEmailOpens: acc.numOfEmailOpens + numOfEmailOpens,
          emailClicksRate: totalEmailClicksRate,
          attributionByCampaignType: {
            ...acc.attributionByCampaignType,
          },
        } as EmailEventsOvertimeRecord;

        const isCampaignMetricsEmpty = !campaignNumOfEmailClicks && !campaignNumOfEmailOpens;
        if (!isCampaignMetricsEmpty) {
          metrics.attributionByCampaignType[campaignName] = {
            numOfEmailClicks: campaignNumOfEmailClicks,
            numOfEmailOpens: campaignNumOfEmailOpens,
            emailClicksRate: campaignEmailClicksRate,
          };
        }

        return metrics;
      },
      {
        ...DEFAULT_EVENT_METRICS,
        attributionByCampaignType: {},
      } as EmailEventsOvertimeRecord
    );

  return rawEmailEventsOvertime.chartPivot()?.map(({ x, xValues, ...data }) => {
    return {
      x,
      xValues,
      ...countEvents(data),
    };
  });
};

export default getEmailEventsOvertime;
