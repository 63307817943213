import {
  AUDIO_RESPONSE_KEY,
  FREE_TEXT_RESPONSE_KEY,
  IMAGE_RESPONSE_KEY,
  VIDEO_RESPONSE_KEY,
} from '../../../constants/tolstoy.constants';

export function isAnyResponse(type) {
  return (
    type === FREE_TEXT_RESPONSE_KEY ||
    type === IMAGE_RESPONSE_KEY ||
    type === VIDEO_RESPONSE_KEY ||
    type === AUDIO_RESPONSE_KEY ||
    type === 'DateTime' ||
    type === 'City'
  );
}

export function postMessage(name, data) {
  window.parent.postMessage({ name, data }, '*');
}
