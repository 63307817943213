import React from 'react';
import styled from 'styled-components';
import Portal from 'shared/react/components/basic/Portal';

const Modal = ({ open, children, canCloseModal = true, onClose, className }) => {
  const closeMenu = () => {
    if (!canCloseModal) {
      return;
    }

    onClose();
  };

  if (!open) {
    return null;
  }

  return (
    <Portal>
      <LayoutRoot onClick={closeMenu}>
        <Content onClick={e => e.stopPropagation()} className={className}>
          {children}
        </Content>
      </LayoutRoot>
    </Portal>
  );
};

const LayoutRoot = styled.div`
  position: fixed;
  z-index: 1300;
  inset: 0;
  background: ${({ theme }) => theme.getHexOpacity(theme.colors.black, 20)};
`;

const Content = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.white};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  transition: 0.3s opacity;
`;

export default Modal;
