import React from 'react';
import ChartTooltip from 'app/src/complex_components/analytics/chart-tooltip/ChartTooltip';
import { ANALYTICS_OVERVIEW_STATS } from 'app/src/constants/analytics.constants';
import { getShortMonthAndDate } from 'app/src/utils/date.utils';
import { TooltipPayload } from 'app/src/complex_components/analytics/chart-tooltip/ChartTooltip.types';
import { useTheme } from 'styled-components';

type AnalyticsOverviewAovTooltipProps = {
  active: boolean;
  payload: [TooltipPayload<string>, TooltipPayload<string>];
  label: string;
};

const AnalyticsOverviewAovTooltip = ({
  active,
  payload,
  label,
}: AnalyticsOverviewAovTooltipProps) => {
  const theme = useTheme();
  if (!active || !payload?.length) {
    return null;
  }

  const [aovData] = payload;
  const { payload: aov } = aovData;

  const lineGroups = [
    {
      key: ANALYTICS_OVERVIEW_STATS.TOLSTOY_CONVERSION_RATE_USERS,
      header: ANALYTICS_OVERVIEW_STATS.TOLSTOY_CONVERSION_RATE_USERS,
      bulletColor: theme.colors.successDark,
      value: aov.tolstoyDailyAveragePriceWithCurrency,
    },
    {
      key: ANALYTICS_OVERVIEW_STATS.BASELINE_TOLSTOY_CONVERSION_RATE_USERS,
      header: ANALYTICS_OVERVIEW_STATS.BASELINE_TOLSTOY_CONVERSION_RATE_USERS,
      bulletColor: theme.colors.neutralLight,
      value: aov.baselineDailyAveragePriceWithCurrency,
    },
  ];

  return <ChartTooltip label={getShortMonthAndDate(label)} lineGroups={lineGroups} />;
};

export default AnalyticsOverviewAovTooltip;
