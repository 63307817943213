import { useParams } from 'react-router-dom';
import { useProjects } from 'src/context/ProjectsStore';
import { ONSITE_TARGET_PAGES } from 'src/pages/dashboard/constants/onsiteTargetPages.constants';
import { project as ProjectType } from 'src/types/entities';
import Utils from 'src/utils';

type ReturnProps = {
  isTargetSpecificProductPage: boolean;
  targetExternalProductId: string;
  updateTargetExternalProductId: (externalProductId: string) => Promise<ProjectType>;
};

const useHostProjectParams = (): ReturnProps => {
  // @ts-ignore-next-line
  const { projectId } = useParams();
  const [{ project }, { updateProject }] = useProjects({ projectId });

  const isTargetSpecificProductPage =
    project?.targetPage === ONSITE_TARGET_PAGES.SpecificProductPage;
  const emailSettings = Utils.safeParse(project?.emailSettings);
  const targetExternalProductId = emailSettings.externalProductId;

  const updateTargetExternalProductId = (externalProductId: string) => {
    const updatedEmailSettings = {
      ...emailSettings,
      externalProductId,
    };

    return updateProject({
      ...project,
      emailSettings: JSON.stringify(updatedEmailSettings),
    });
  };

  return {
    isTargetSpecificProductPage,
    targetExternalProductId,
    updateTargetExternalProductId,
  };
};

export default useHostProjectParams;
