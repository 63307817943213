import React from 'react';
import styled from 'styled-components';
import BackMenuItem from 'app/src/complex_components/menu_items/BackMenuItem';
import MenuItemV3 from 'app/src/complex_components/menu_items/MenuItemV3';
import { ChevronRightIcon } from 'shared/react/images/ChevronIcon';
import { AccountMenuButtons } from 'app/src/pages/sidebar/account-menu/useAccountMenuButtons';

type Props = {
  items: AccountMenuButtons;
  currentKey?: string;
  setCurrentKey: (key: string) => void;
};

const MenuItems = ({ items, currentKey, setCurrentKey }: Props) => {
  let currentItems = items;
  let currentLabel = '';

  currentKey?.split('.').forEach(key => {
    if (!key) {
      return;
    }

    currentLabel = currentItems[key].label;
    currentItems = currentItems[key].items;
  });

  const arrayItems = Object.values(currentItems).map(value => {
    return value;
  });

  const onItemWithChildrenClick = key => {
    setCurrentKey(key);
  };

  return (
    <LayoutRoot>
      <BackMenuItem currentLabel={currentLabel} setCurrentKey={setCurrentKey} />
      {arrayItems.map(({ label, onClick, icon, key, dataTestId }) => {
        const onItemClick = onClick || (() => onItemWithChildrenClick(key));
        return (
          <MenuItemV3
            key={label}
            onClick={onItemClick}
            icon={icon}
            endIcon={onClick ? '' : <ChevronRightIcon />}
            dataTestId={dataTestId}
          >
            {label}
          </MenuItemV3>
        );
      })}
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div``;

export default MenuItems;
