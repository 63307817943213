import React from 'react';
import styled from 'styled-components';
import { PDP_CONFIG_KEY } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { useLookAndFeelContext } from '../../../LookAndFeelContext';
import EditorHeader from '../shared/EditorHeader';
import ProductDetailsButtonEditor from './ProductDetailsButtonEditor';
import MiniProductDetailsButtonEditor from './MiniProductDetailsButtonEditor';

const ProductDetailsButtonsEditor = () => {
  const { customizationSettings, setCustomizationSettingsProperty, project } =
    useLookAndFeelContext();
  const pdpConfig = customizationSettings.pdpConfig || {};

  const { dynamic: isDynamic } = project;

  const updateConfig = update => {
    const newPdpConfig = { ...pdpConfig, ...update };

    setCustomizationSettingsProperty(PDP_CONFIG_KEY, newPdpConfig);
  };
  const onPrimaryButtonChange = primaryButton => updateConfig({ primaryButton });
  const onSecondaryButtonChange = secondaryButton => updateConfig({ secondaryButton });
  const onMiniCtaButtonChange = miniCtaButton => updateConfig({ miniCtaButton });

  if (!isDynamic) {
    return null;
  }

  return (
    <LayoutRoot>
      <EditorHeader title="Product buttons" />

      <ProductDetailsButtonEditor
        title="Primary"
        buttonConfig={pdpConfig.primaryButton}
        onChange={onPrimaryButtonChange}
      />
      <ProductDetailsButtonEditor
        title="Secondary"
        buttonConfig={pdpConfig.secondaryButton}
        onChange={onSecondaryButtonChange}
      />
      <MiniProductDetailsButtonEditor
        title="Mini CTA"
        buttonConfig={pdpConfig.miniCtaButton}
        onChange={onMiniCtaButtonChange}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

export default ProductDetailsButtonsEditor;
