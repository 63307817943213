import React from 'react';
import useFeedModal from 'shared/react/components/complex/context/hooks/useFeedModal';
import {
  PRODUCT_MODAL_KEY,
  SHARE_MODAL_KEY,
  CART_MODAL_KEY,
  MENU_MODAL_KEY,
  CUSTOMER_LOGIN_MODAL,
  REPORT_MODAL_KEY,
  SEARCH_MODAL_KEY,
  WISHLIST_MODAL_KEY,
} from 'shared/react/constants/modals.constants';
import ProductModal from 'shared/react/components/complex/feed/feed_products/ProductModal';
import FeedShareModal from 'shared/react/components/complex/feed/feed_share/FeedShareModal';
import FeedCart from 'shared/react/components/complex/feed/feed_cart/FeedCart';
import CustomerLoginModal from './customer_login/CustomerLoginModal';
import FeedMenuModal from 'shared/react/components/complex/feed/feed_menu/FeedMenuModal';
import FeedReportModal from 'shared/react/components/complex/feed/feed_report/FeedReportModal';
import FeedSearchModal from 'shared/react/components/complex//feed/feed_search/FeedSearchModal';
import FeedWishlist from 'shared/react/components/complex/feed/feed_wishlist/FeedWishlist';

const PlayerGlobalModal = ({ currentStep }) => {
  const [{ currentModal }] = useFeedModal();

  switch (currentModal?.key) {
    case PRODUCT_MODAL_KEY:
      return <ProductModal />;
    case CART_MODAL_KEY:
      return <FeedCart />;
    case SHARE_MODAL_KEY:
      return <FeedShareModal currentStep={currentStep} />;
    case MENU_MODAL_KEY:
      return <FeedMenuModal />;
    case REPORT_MODAL_KEY:
      return <FeedReportModal />;
    case CUSTOMER_LOGIN_MODAL:
      return <CustomerLoginModal />;
    case SEARCH_MODAL_KEY:
      return <FeedSearchModal />;
    case WISHLIST_MODAL_KEY:
      return <FeedWishlist />;
    default:
      return null;
  }
};

export default PlayerGlobalModal;
