import React from 'react';
import styled from 'styled-components';

import HorizontalFlex, {
  Gap8HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { Button, GhostButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { TextSmall } from 'shared/react/components/basic/text/TextV2';

type Button = {
  onClick: () => void;
  isEnabled: boolean;
  text?: string;
  dataTestId?: string;
  icon?: React.ReactNode;
  hidden?: boolean;
};

type Props = {
  buttons: Button[];
};

const RightButtonsGroup = ({ buttons }: Props) => {
  if (!buttons.length) {
    return null;
  }

  return (
    <Container>
      {buttons.flatMap(({ text, onClick, isEnabled, dataTestId, icon, hidden }, index) => {
        if (hidden) {
          return [];
        }

        return (
          <ButtonContainer
            key={`buttonGroup-${index}`}
            onClick={isEnabled ? () => {} : onClick}
            data-test-id={dataTestId}
          >
            <StyledGhostButton isEnabled={isEnabled}>
              <ButtonContent isEnabled={isEnabled}>
                {icon}
                {text && <ButtonText isEnabled={isEnabled}>{text}</ButtonText>}
              </ButtonContent>
            </StyledGhostButton>
          </ButtonContainer>
        );
      })}
    </Container>
  );
};

const Container = styled(Gap8HorizontalFlex)``;

const StyledGhostButton = styled(GhostButton)<{ isEnabled: boolean }>`
  border: 1px solid
    ${({ theme, isEnabled }) =>
      isEnabled ? theme.colors.neutralDarker : theme.colors.neutralLight};
`;

const ButtonContent = styled(HorizontalFlex)<{ isEnabled: boolean }>`
  align-items: center;

  > svg {
    & path {
      fill: ${({ theme, isEnabled }) =>
        isEnabled ? theme.colors.neutralBlack : theme.colors.neutralDarker};
    }
  }
`;

const ButtonContainer = styled(HorizontalFlex)``;

const ButtonText = styled(TextSmall)<{ isEnabled: boolean }>`
  white-space: pre;
  padding-left: 4px;
  color: ${({ theme, isEnabled }) =>
    isEnabled ? theme.colors.neutralBlack : theme.colors.neutralDarker};
`;

export default RightButtonsGroup;
