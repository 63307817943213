import { VideosPageVodAsset } from 'app/src/pages/dashboard/pages/videos/types/videos.types';
import { ASSET_TYPE } from 'shared/react/services/assets';
import useUpdateVideosMultiSelectState from 'app/src/hooks/useUpdateVideosMultiSelectBarState';
import { VIDEO_STATUS } from 'shared/react/constants/video.constants';
import useCreateIntegrationLabels from 'app/src/hooks/useCreateIntegrationLabels';
import useCreateIntegrationVideo from 'app/src/hooks/use-create-integration-video/useCreateIntegrationVideo';
import { useGoogleDrive } from 'app/src/context/GoogleDriveStore';
import { useCallback } from 'react';

export type UseOnSelectedVideosActionProps = {
  videos: any[];
  method: string;

  selectedVideos: string[];
  setSelectedVideos: (selected: string[]) => void;
};

const VIDEO_IMPORTING_STATUSES = [VIDEO_STATUS.uploading, VIDEO_STATUS.done];

/**
 * This hook contains common media library functions extracted from the Videos.tsx
 */
export const useOnSelectedVideosAction = ({
  videos,
  method,
  selectedVideos,
  setSelectedVideos,
}: UseOnSelectedVideosActionProps) => {
  const { createIntegrationLabels } = useCreateIntegrationLabels();
  const { importMultipleFiles } = useCreateIntegrationVideo();
  const [{ videoFoldersById }] = useGoogleDrive();

  const getNotImportedVideos = useCallback(
    (videoAssets: VideosPageVodAsset[] | null) => {
      const foundVideos =
        videoAssets || selectedVideos.map(id => videos.find(video => video.id === id));

      return foundVideos.filter(video => {
        return video?.externalId && !VIDEO_IMPORTING_STATUSES.includes(video.status);
      });
    },
    [selectedVideos]
  );

  const onSelectedVideosAction = useCallback(
    async (video: VideosPageVodAsset[] | null) => {
      if (!method) {
        return;
      }

      const videosForFilter = videos;
      if (video && video[0]?.type === ASSET_TYPE.GALLERY) {
        const gallery = video[0];
        await onSelectedVideosAction(
          videosForFilter.filter(currentVideo =>
            gallery.referenceIds.includes(currentVideo.externalId)
          )
        );
      }

      const currentVideos = getNotImportedVideos(video);

      if (!currentVideos.length) {
        return;
      }

      const createLabelsPromises = currentVideos.map(video => {
        return createIntegrationLabels(videoFoldersById[video?.parents?.[0]], video);
      });

      await Promise.all([
        createLabelsPromises,
        importMultipleFiles({ videos: currentVideos, method }),
      ]);
      setSelectedVideos([]);
    },
    [
      createIntegrationLabels,
      getNotImportedVideos,
      importMultipleFiles,
      method,
      setSelectedVideos,
      videoFoldersById,
      videos,
    ]
  );

  useUpdateVideosMultiSelectState({
    videos: videos,
    onSelectedVideosAction: onSelectedVideosAction,
    selectedVideos: selectedVideos,
    setSelectedVideos: setSelectedVideos,
    shouldShowDelete: !method,
    disableDownload: !!getNotImportedVideos(null).length,
  });

  return { onSelectedVideosAction };
};
