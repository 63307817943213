import React from 'react';
import styled from 'styled-components';
import useFeedState from 'shared/react/components/complex/context/hooks/useFeedState';
import useFeedCart from 'shared/react/components/complex/context/hooks/useFeedCart';
import HorizontalFlex, {
  Gap8HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import BagIcon from 'shared/react/images/feed/BagIcon';
import { CART_MODAL_KEY } from 'shared/react/constants/modals.constants';
import FeedCartTitle from './FeedCartTitle';
import FeedCheckoutButton from 'shared/react/components/complex/feed/feed_buttons/FeedCheckoutButton';
import { FEED_ANALYTICS_LOCATIONS } from 'shared/react/constants/analyticsLocations.constants';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';
import useKeyboardNavigation from 'player/src/hooks/useKeyboardNavigation';

const FeedCartSummary = ({ onClickViewCart, currentModal }) => {
  const [{ isShopifyStore, isCheckoutShoppingBagEnabled }] = useFeedState();
  const { onKeyDown, setNextRef } = useKeyboardNavigation();
  const [{ numberOfCartItems }] = useFeedCart();
  const [, { t }] = useTranslation('feed.cart');

  if (
    !isCheckoutShoppingBagEnabled ||
    currentModal === CART_MODAL_KEY ||
    !isShopifyStore ||
    !numberOfCartItems
  ) {
    return null;
  }

  return (
    <LayoutRoot>
      <Content>
        <ItemsContainer>
          <FeedCartTitle itemsQuantity={numberOfCartItems} />
          <ItemsContainerRight
            onClick={onClickViewCart}
            role="button"
            aria-label={'View cart'}
            tabIndex="0"
            onKeyDown={onKeyDown}
            ref={setNextRef}
          >
            <StyledBagIcon />
            <ViewBagText>{t('viewCart')}</ViewBagText>
          </ItemsContainerRight>
        </ItemsContainer>
        <FeedCheckoutButton
          buttonText={t('goToCheckout')}
          location={FEED_ANALYTICS_LOCATIONS.feedCartSummary}
        />
      </Content>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  z-index: 11;
`;

const Content = styled(Gap16VerticalFlex)`
  padding: 24px;
  box-shadow: 0 3px 34px ${({ theme }) => theme.getHexOpacity(theme.colors.black, 15)};
  border-radius: 24px;
  background: ${({ theme }) => theme.colors.white};
`;

const ItemsContainer = styled(HorizontalFlex)`
  justify-content: space-between;
`;

const ItemsContainerRight = styled(Gap8HorizontalFlex)`
  align-items: center;
  cursor: pointer;
`;

const StyledBagIcon = styled(BagIcon)``;

const ViewBagText = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.gray27};
`;

export default FeedCartSummary;
