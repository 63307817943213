import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import useOnClickOutside from '../../../../shared/react/hooks/useOutsideClick';
import DropdownIcon from '../../assets/images/Dropdown';

const Select = ({ options, onChange, value, name }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFading, setIsFading] = useState(false);

  const containerRef = useRef({});

  const toggle = () => {
    if (isOpen) {
      return closeSelect();
    }
    setIsOpen(!isOpen);
  };

  const closeSelect = () => {
    if (isOpen) {
      setIsFading(true);
      setTimeout(() => {
        setIsOpen(false);
        setIsFading(false);
      }, 280);
    }
  };

  const onOptionClicked = value => {
    onChange(value);
    setIsFading(true);
    setTimeout(() => {
      setIsOpen(false);
      setIsFading(false);
    }, 280);
  };

  useOnClickOutside([containerRef], closeSelect);
  return (
    <Main>
      <Label>{name}</Label>
      <DropDownContainer title={name}>
        <DropDownHeader isOpen={isOpen} onClick={toggle}>
          {value || 'Choose Value'}
        </DropDownHeader>
        <IconContainer>
          <DropdownIcon />
        </IconContainer>
        {isOpen && (
          <DropDownListContainer isFading={isFading} ref={containerRef}>
            <DropDownList>
              {options.map(option => (
                <ListItem
                  selected={value === option}
                  onClick={() => onOptionClicked(option)}
                  key={option}
                >
                  {option}
                </ListItem>
              ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    </Main>
  );
};

const Main = styled.div`
  height: 100%;
  width: 100%;
  text-align: left;
`;

const DropDownContainer = styled.div`
  position: relative;
  margin: 10px auto 0;
  height: 33px;
`;

const Label = styled.label`
  margin-bottom: 5px;
  font-size: 17px;
  font-weight: 500;
`;

const DropDownHeader = styled.div`
  padding: 0.4em 1em 0.4em 1em;
  font-size: 1.2rem;
  background: #ffffff;
  text-align: left;
  background: ${({ isOpen }) => (isOpen ? '#f3f5ff' : '')};
  cursor: pointer;
  transition: 0.4s;
  border: 2px solid #ff005c;
  border-radius: 18px;

  &:hover {
    background: #f3f5ff;
  }
`;

const IconContainer = styled.div`
  position: absolute;
  top: 35%;
  right: 15px;
`;

const DropDownListContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100px;
  margin-top: 5px;
  z-index: 1;
  overflow: auto;
  box-shadow: 0px 0px 4px 2px #d4d4d4;
  animation: ${({ isFading }) => (isFading ? 'fadeOut' : 'fadeIn')} 0.3s;
  @keyframes fadeIn {
    from {
      transform: scale(0);
      transform-origin: top;
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    from {
      transform: scale(1);
      opacity: 1;
    }
    to {
      opacity: 0;
      transform: scale(0);
      transform-origin: top;
    }
  }
`;

const DropDownList = styled.ul`
  width: 100%;
  padding: 0;
  height: 23px;
  margin: 0;
  background: #ffffff;
  box-sizing: border-box;
  font-size: 1.3rem;
`;

const ListItem = styled.li`
  background: ${({ selected }) => (selected ? '#f3f5ff' : 'inherit')};
  list-style: none;
  padding: 6px 0px 6px 10px;
  cursor: pointer;
  transition: 0.4s;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    background: #f3f5ff;
  }
`;

export default Select;
