import React from 'react';
import styled from 'styled-components';
import FeedProductImage from '../feed_products/FeedProductImage';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';

const FeedWishlistProductGrid = ({ products, customerWishlist, isSidePanel, onProductClick }) => {
  const productsArray = Object.values(products);

  const wishlistProductIds = new Set(customerWishlist.map(item => item.externalProductId));
  const wishlistProducts = productsArray.filter(product => wishlistProductIds.has(product.id));

  return (
    <>
      <StyledTextTiny>Your wishlist</StyledTextTiny>
      <GridContainer $isSidePanel={isSidePanel}>
        {Array.isArray(wishlistProducts) &&
          wishlistProducts.map(product => (
            <ImageWrapper key={`image_${product.id}`}>
              <FeedProductImage
                key={product.id}
                src={product.imageUrl}
                alt={product.name}
                onClick={e => onProductClick(e, product)}
              />
            </ImageWrapper>
          ))}
      </GridContainer>
    </>
  );
};

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 107px);
  gap: 2px;
  padding: 16px;
  margin: ${({ $isSidePanel }) => ($isSidePanel ? '0' : '0 auto')}; ;
`;

const ImageWrapper = styled.div`
  width: 107px;
  height: 107px;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  & div {
    max-width: 107px;
  }
`;

const StyledTextTiny = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralGray};
  display: flex;
  justify-content: flex-start;
  padding-top: 16px;
  padding-left: 20px;
`;

export default FeedWishlistProductGrid;
