import React from 'react';
import styled from 'styled-components';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { Line } from './ChartTooltip.types';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';

const ChartTooltipLineText = ({ header, value }: Line) => {
  return (
    <LayoutRoot>
      <TextTiny>{header}</TextTiny>
      <TextTiny>{value}</TextTiny>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlex)`
  justify-content: space-between;
  padding: 0 8px;

  & * {
    color: ${({ theme }) => theme.colors.neutralGrey};
  }
`;

export default ChartTooltipLineText;
