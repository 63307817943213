import React from 'react';
import LineClampWrapper from 'shared/react/components/basic/LineClampWrapper';
import styled from 'styled-components';
import Tooltip from 'app/src/basic_components/Tooltip';
import useOverflowDetector from 'app/src/hooks/useOverflowDetector';
import { AnalyticsPlayerOverlayContentProps } from './AnalyticsPlayer.types';
import { TextSmall } from 'shared/react/components/basic/text/TextV2';
import { VerticalFlexCentered } from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const OverlayCaption = ({ className, video }: AnalyticsPlayerOverlayContentProps) => {
  const [contentRef, isContentOverflow] = useOverflowDetector();
  const content = video.name || video.id;

  return (
    <LayoutRoot className={className}>
      <Tooltip className="notranslate" text={isContentOverflow ? content : ''}>
        <LineClampWrapper numberOfLines={1}>
          <Text className="notranslate" ref={contentRef}>
            {content}
          </Text>
        </LineClampWrapper>
      </Tooltip>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlexCentered)`
  backdrop-filter: blur(6px);
  background-color: rgba(9, 10, 11, 0.25);
  padding: 12px 8px;
  pointer-events: auto;
`;

const Text = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
`;

export default OverlayCaption;
