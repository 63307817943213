import React, { FC } from 'react';
import EditorItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorItem';
import styled from 'styled-components';
import HorizontalFlex, {
  HorizontalFlexCentered,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import TextInput from 'app/src/basic_components/TextInput';
import NumericInput from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/NumericInput';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { ChevronDownIcon, ChevronRightIcon, ChevronUpIcon } from 'shared/react/images/ChevronIcon';

interface EditorNumericInputItemProps {
  text: string;
  suffix?: string;
  onChange: (value: number) => void;
  placeholder?: string;
  value: number;
  disabled?: boolean;
  min?: number;
  max?: number;
  width?: string;
  height?: string;
  tooltipText?: React.ReactNode;
}

const EditorNumericInputItem: FC<EditorNumericInputItemProps> = ({
  text,
  suffix,
  disabled,
  width = '104px',
  onChange,
  value,
  min,
  max,
  ...props
}) => {
  const onUpClick = () => {
    if (value >= max) {
      onChange(max);
      return;
    }

    onChange(value + 1);
  };

  const onDownClick = () => {
    if (value <= min) {
      onChange(min);
      return;
    }

    onChange(value - 1);
  };

  return (
    <EditorItem {...props} disabled={disabled} text={text}>
      <LayoutRoot>
        <NumericInput
          disabled={disabled}
          width={width}
          onInputChange={onChange}
          {...props}
          suffix={suffix}
          value={value}
          min={min}
          max={max}
        />
        {suffix && <Suffix>{suffix}</Suffix>}
        <IconContainers>
          <StyledChevronUpIcon onClick={onUpClick} />
          <StyledChevronDownIcon onClick={onDownClick} />
        </IconContainers>
      </LayoutRoot>
    </EditorItem>
  );
};

export default EditorNumericInputItem;

const LayoutRoot = styled(HorizontalFlex)`
  border: ${({ theme }) => `1px solid ${theme.colors.ghostLight}`};
  border-radius: 6px;
  min-width: 72px;
  height: 40px;

  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
`;

const Suffix = styled(HorizontalFlexCentered)`
  width: 28px;
  height: 39px;
  background: ${({ theme }) => theme.colors.white};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.ghostLight}`};
  border-radius: 0 4px 4px 0;
  padding: 13px 8px 13px 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
`;

const IconContainers = styled(VerticalFlex)`
  align-items: center;
  justify-content: center;
  padding-right: 8px;
`;

const StyledChevronUpIcon = styled(ChevronUpIcon)`
  cursor: pointer;
`;

const StyledChevronDownIcon = styled(ChevronDownIcon)`
  cursor: pointer;
`;
