import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useProductActions } from 'app/src/context/ProductsStore';
import { Card } from 'app/src/styled_components';
import { TextSmall } from 'shared/react/components/complex/Text';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import ImageWithFallback from 'shared/react/images/ImageWithFallback';

import {
  DEFAULT_BORDER_RADIUS,
  DEFAULT_CARD_HEIGHT,
  DEFAULT_CARD_WIDTH,
  DEFAULT_CONTAINER_HEIGHT,
  DEFAULT_CONTAINER_WIDTH,
  DEFAULT_DESCRIPTION_HEIGHT,
  DEFAULT_DESCRIPTION_WIDTH,
  DEFAULT_FONT_SIZE,
  DEFAULT_GAP,
  DEFAULT_IMAGE_HEIGHT,
  DEFAULT_PADDING,
} from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/products_result_preview/productsCarousel.constants';

const ProductsCarousel = ({ products, containerRef, productRef, size, step, width, height }) => {
  const { getProducts } = useProductActions();
  const [productsList, setProductsList] = useState([]);
  const shouldCenterProducts = productsList?.length && productsList.length < 3;

  const fetchProducts = async () => {
    if (!step) {
      setProductsList(products);
      return;
    }

    if (!step.products) {
      return;
    }

    const productIds = step?.products?.map(product => product.id);
    const list = await getProducts(productIds);
    setProductsList(list);
  };

  useEffect(() => {
    fetchProducts();
  }, [step, products]);

  return (
    <CarouselContainer width={width} height={height}>
      <ProductsContainer
        ref={containerRef}
        borderRadius={DEFAULT_BORDER_RADIUS / size}
        width={DEFAULT_CONTAINER_WIDTH / size}
        height={DEFAULT_CONTAINER_HEIGHT / size}
        gap={DEFAULT_GAP / size}
        shouldCenter={shouldCenterProducts}
      >
        {productsList.map(({ productId, imageUrl, title }) => {
          return (
            <ProductContainer
              key={productId}
              borderRadius={DEFAULT_BORDER_RADIUS / size}
              width={DEFAULT_CARD_WIDTH / size}
              height={DEFAULT_CARD_HEIGHT / size}
            >
              <StyledImageWithFallback
                src={imageUrl}
                ref={productRef}
                borderRadius={DEFAULT_BORDER_RADIUS / size}
                height={DEFAULT_IMAGE_HEIGHT / size}
              />
              <EllipsisTextOverflowWrapper>
                <ProductDescription
                  padding={DEFAULT_PADDING / size}
                  width={DEFAULT_DESCRIPTION_WIDTH / size}
                  height={DEFAULT_DESCRIPTION_HEIGHT / size}
                  fontSize={DEFAULT_FONT_SIZE / size}
                >
                  {title}
                </ProductDescription>
              </EllipsisTextOverflowWrapper>
            </ProductContainer>
          );
        })}
      </ProductsContainer>
    </CarouselContainer>
  );
};

export default ProductsCarousel;

const ProductContainer = styled(Card)`
  padding: 0;
  border-radius: ${({ borderRadius }) => `${borderRadius}px`};
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  box-shadow: 0 10px 25px rgba(50, 50, 93, 0.1), 0 5px 10px rgba(0, 0, 0, 0.05);
  flex-shrink: 0;
`;

const ProductsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ shouldCenter }) => (shouldCenter ? 'center' : 'flex-start')};
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  gap: ${({ gap }) => `${gap}px`};
  border-radius: ${({ borderRadius }) => `${borderRadius}px`};
  overflow: hidden;
  background: ${({ theme }) => theme.colors.gray3};
`;

const ProductDescription = styled(TextSmall)`
  padding: ${({ padding }) => `${padding}px`};
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '')};
`;

const CarouselContainer = styled(HorizontalFlexWrap)`
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  gap: 6px;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  background: ${({ theme }) => theme.colors.gray3};
`;

const StyledImageWithFallback = styled(ImageWithFallback)`
  height: ${({ height }) => `${height}px`};
  border-radius: ${({ borderRadius }) => `${borderRadius}px`};
  flex-grow: 1};
`;
