import React from 'react';
import styled from 'styled-components';
import { getFeedActionButtonOnClick } from 'shared/react/components/complex/feed/feed_utils/feedActionButton.utils';
import {
  DESCRIPTION_SIDE_PANEL_TYPE,
  PRIMARY_LIGHT_BUTTON_TYPE,
} from 'shared/react/constants/feed.constants';
import { TextBody } from 'shared/react/components/complex/Text';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';
import useTracker from 'shared/react/components/complex/context/hooks/useTracker';
import FeedBrandedButton from 'shared/react/components/complex/feed/feed_buttons/FeedBrandedButton';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { FEATURE_FEED_OPEN_DESCRIPTION_CTA_IN_SAME_TAB } from 'shared/react/constants/features.constants';
import useFeedFeatures from 'shared/react/components/complex/context/hooks/useFeedFeatures';

const FeedSmallDescriptionView = ({ step, isDescriptionLineClamp, setDescriptionLineClamp }) => {
  const [, { t }] = useTranslation('feed.description');
  const [, { track }] = useTracker();
  const [, { getFeatureSettingsByKey }] = useFeedFeatures();
  const { feedSidePanelDescription, videoDescription, feedDescriptionActionButton } = step;
  const { text, type, value, isOpenInANewTab, enabled } = feedDescriptionActionButton || {};
  const { isOpenDescriptionCtaInSameTab } = getFeatureSettingsByKey(
    FEATURE_FEED_OPEN_DESCRIPTION_CTA_IN_SAME_TAB
  );

  const getIsOpenInaNewTab = () => {
    if (isOpenDescriptionCtaInSameTab) {
      return false;
    }

    return isOpenInANewTab;
  };

  const onClick = () => {
    track(`${DESCRIPTION_SIDE_PANEL_TYPE}ActionButtonClick`, { type });
    getFeedActionButtonOnClick({
      type,
      value,
      isOpenInANewTab: getIsOpenInaNewTab(),
    });
  };

  const handleTextClick = e => {
    e.stopPropagation();
    e.target.scrollTop = 0;
    setDescriptionLineClamp(!isDescriptionLineClamp);
  };

  return (
    <LayoutRoot>
      <EllipsisTextOverflowWrapper>
        <StyledTextBody isDescriptionLineClamp={isDescriptionLineClamp} onClick={handleTextClick}>
          {feedSidePanelDescription || videoDescription}
        </StyledTextBody>
      </EllipsisTextOverflowWrapper>
      <StyledFeedBrandedButton
        onClick={onClick}
        buttonText={text || t('seeMore')}
        type={PRIMARY_LIGHT_BUTTON_TYPE}
        hide={!enabled || !type}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)`
  overflow: hidden;
  cursor: pointer;
  pointer-events: auto !important;
`;

const StyledTextBody = styled(TextBody)`
  color: ${({ theme }) => theme.colors.white};
  text-align: left;
  -webkit-line-clamp: ${({ isDescriptionLineClamp }) => (isDescriptionLineClamp ? 2 : '')};
  overflow: ${({ isDescriptionLineClamp }) => (isDescriptionLineClamp ? 'hidden' : 'auto')};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: break-spaces;
  word-break: break-word;
  font-weight: 500;
`;

const StyledFeedBrandedButton = styled(FeedBrandedButton)`
  padding: 11px;
`;

export default FeedSmallDescriptionView;
