import styled from 'styled-components';
import Button from 'shared/react/components/basic/Button';
import Select from 'app/src/basic_components/Select';
import { TextH6, TextSmall, TextSubtitle } from 'shared/react/components/complex/Text';
import FormInput from 'app/src/complex_components/FormInput';

export const CircleButton = styled(Button)`
  border-radius: 100%;
  padding: 10px;
  background: ${({ backgroundColor, theme }) => backgroundColor || theme.colors.primary};
`;

export const OverlayIconButton = styled.div`
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 100%;
  border: none;
  background-color: rgba(9, 10, 11, 0.3);
  transition: 0.2s;
`;

export const ConfigContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  border-radius: 16px;
  padding: 32px 40px;
  background-color: white;

  @media (${({ theme }) => theme.breakpoints.dashboardTabletMax}) {
    padding: 24px;
  }
`;

export const StyledSelect = styled(Select)`
  margin-right: 20px;
  & .MuiSelect-select:focus {
    background: white;
  }
`;

export const SectionSubtitle = styled(TextSmall)`
  margin-top: 2px;
  margin-bottom: 24px;
`;

export const NotificationLevelContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
`;

export const AccordionTitle = styled(TextH6)`
  margin-bottom: 2px;
`;

export const SectionTitle = styled(TextSubtitle)`
  margin-top: 24px;
`;

export const AnalyticsTextField = styled(FormInput)`
  margin-top: 8px;
`;
