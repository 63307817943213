import moveArray from 'array-move';
import { sortEntities } from 'src/hooks/use-dynamic-video-ids-by-project/checkRules';
import { useVodProjectIdConnectionActions } from 'src/hooks/vod-connections';

export type UseVideosActions = {
  moveNonPDPVideo: (params: { index: number; draggingFromIndex: number }) => void;
  addManualNonPDPVideo: (videoId: string) => Promise<any>;
  removeManualNonPDPVideo: (videoId: string) => Promise<any>;
};

const useDynamicTypeVideosActions = ({
  project,
  vodAssetIds,
  setVodAssetIds,
  videoRules,
}): UseVideosActions => {
  const { createVideoProjectIdConnection, deleteVodProjectIdConnection } =
    useVodProjectIdConnectionActions();

  const moveNonPDPVideo = ({ index, draggingFromIndex }) => {
    const newVodAssetIds = moveArray([...vodAssetIds], draggingFromIndex, index);

    const sortedEntities = sortEntities(newVodAssetIds, [videoRules], null);

    setVodAssetIds(sortedEntities);
  };

  const addManualNonPDPVideo = async videoId =>
    createVideoProjectIdConnection({
      appUrl: project?.appUrl,
      projectId: project.id,
      vodAssetId: videoId,
    });

  const removeManualNonPDPVideo = async videoId =>
    deleteVodProjectIdConnection({ vodAssetId: videoId, projectId: project.id });

  return {
    moveNonPDPVideo,
    addManualNonPDPVideo,
    removeManualNonPDPVideo,
  };
};

export default useDynamicTypeVideosActions;
