import React, { useState } from 'react';
import Tooltip from 'app/src/basic_components/Tooltip';
import IconButton from 'app/src/complex_components/IconButton';
import { useQuizContext } from 'app/src/pages/project/pages/quiz/QuizContext';
import QuizRulePlusIcon from 'shared/react/images/quiz/QuizRulePlusIcon';
import MenuItemV2 from 'app/src/complex_components/MenuItemV2';
import MenuWithContainer from 'shared/react/components/basic/MenuWithContainer';

function AddChoiceButton({ choices, setChoices, getChoices, quizResultId, flowId, rule }) {
  const { quizResults, setQuizResults, setUnsaved } = useQuizContext();
  const [anchorEl, setAnchorEl] = useState(false);
  const isDisabled = !choices.length;

  const handleAddChoice = (quizResultId, flowId, stepId, choice) => {
    const newQuizResults = [...quizResults];
    const quizResult = newQuizResults.find(quizResult => quizResult.id === quizResultId);
    const flow = quizResult.flows.find(flow => flow.id === flowId);

    const rule = flow.rules.find(rule => rule.stepId === stepId);

    rule.choices.push(choice);
    setQuizResults(newQuizResults);
    setChoices(getChoices(rule.choices, stepId));
    setUnsaved(true);
  };

  return (
    <>
      <Tooltip
        text={isDisabled ? 'You have already added all possible choices.' : ''}
        placement="right"
      >
        <IconButton
          disabled={isDisabled}
          height="24px"
          width="24px"
          padding="0"
          onClick={e => setAnchorEl(e.target)}
        >
          <QuizRulePlusIcon />
        </IconButton>
      </Tooltip>
      <MenuWithContainer anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
        {choices.map(({ key, value }) => {
          return (
            <MenuItemV2
              key={key}
              title={value}
              onClick={() => handleAddChoice(quizResultId, flowId, rule.stepId, key)}
            />
          );
        })}
      </MenuWithContainer>
    </>
  );
}

export default AddChoiceButton;
