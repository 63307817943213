import React from 'react';

function CircledPlusIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="none" viewBox="0 0 15 14">
      <path
        fill="#fff"
        d="M7.5.188A6.564 6.564 0 00.937 6.75 6.547 6.547 0 007.5 13.313a6.564 6.564 0 006.563-6.563c0-3.61-2.954-6.563-6.563-6.563zm2.625 7.218H8.156v1.969c0 .383-.3.656-.656.656a.632.632 0 01-.656-.656V7.406H4.875a.632.632 0 01-.656-.656c0-.355.273-.656.656-.656h1.969V4.125c0-.355.273-.656.656-.656.355 0 .656.3.656.656v1.969h1.969c.355 0 .656.3.656.656 0 .383-.3.656-.656.656z"
      ></path>
    </svg>
  );
}

export default CircledPlusIcon;
