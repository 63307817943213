import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { OverlayIconButton } from 'app/src/basic_components/commonStyles';
import * as Sentry from '@sentry/react';
import { Menu, MenuItem } from '@material-ui/core';
import { TextSmall } from 'shared/react/components/complex/Text';
import VideoIcon from 'app/src/images/VideoIcon';
import MobileDesktopPreviewToggle from 'app/src/pages/creation_flow/right_section/video_container/video_overlay/MobileDesktopPreviewToggle';
import { track } from 'app/src/helpers/Tracker';
import AudioTest from 'shared/react/components/complex/recording/AudioTest';

const ChooseDevices = ({
  constrains,
  setConstraints,
  constrainsNames,
  setConstraintsNames,
  selectedPreviewMode,
  setSelectedPreviewMode,
  isResultPreview,
  isScreenRecording,
  stream,
  VIDEOINPUT,
  AUDIOINPUT,
}) => {
  const [devices, setDevices] = useState([]);
  const [micNotWorking, setMicNotWorking] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currSelectedName, setCurrSelectedName] = React.useState('');

  const getDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      setDevices(devices);
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  };

  const handleClick = (e, name) => {
    track(`Choose ${VIDEOINPUT ? 'Video' : 'Audio'} Input Button Click`);
    setAnchorEl(e.currentTarget);
    setCurrSelectedName(name);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrSelectedName('');
  };

  const onChoose = (value, label) => {
    const isSelected = label === constrainsNames[currSelectedName];
    if (isSelected) {
      return;
    }
    setConstraints({ ...constrains, [currSelectedName.split('Input')[0]]: value });
    setConstraintsNames({ ...constrainsNames, [currSelectedName]: label });
    localStorage.setItem(`${currSelectedName}-device`, JSON.stringify({ value, label }));
    handleClose();
  };

  const inputItem = ({ deviceId, label }, type) => {
    const isSelected = label === constrainsNames[type];

    return (
      <MenuItem
        selected={isSelected}
        key={`${deviceId} ${label}`}
        onClick={() => onChoose(deviceId, label)}
      >
        <Title>{label}</Title>
      </MenuItem>
    );
  };

  const { audioInputs, videoInputs } = useMemo(() => {
    const audioInputs = [];
    const videoInputs = [];
    let hasNames = false;
    devices.forEach(device => {
      if (device.kind === AUDIOINPUT.toLocaleLowerCase()) {
        if (device.label) {
          hasNames = true;
        }
        audioInputs.push(device);
      }
      if (device.kind === VIDEOINPUT.toLocaleLowerCase()) {
        if (device.label) {
          hasNames = true;
        }
        videoInputs.push(device);
      }
    });
    if (!hasNames) {
      getDevices();
    }
    return { audioInputs, videoInputs };
  }, [devices]);

  useEffect(() => {
    if (!devices.length) {
      getDevices();
    }
  }, [devices]);

  if (!devices.length) {
    return <></>;
  }

  return (
    <Container>
      <InputButtonsContainer>
        {!isResultPreview && !isScreenRecording && videoInputs.length && (
          <VideoButton name={VIDEOINPUT} onClick={e => handleClick(e, VIDEOINPUT)} isLast={false}>
            <VideoIcon fill="white" />
          </VideoButton>
        )}

        {!isResultPreview && audioInputs.length && (
          <AudioButton name={AUDIOINPUT} onClick={e => handleClick(e, AUDIOINPUT)} isLast={true}>
            <AudioTest
              stream={stream}
              micNotWorking={micNotWorking}
              setMicNotWorking={setMicNotWorking}
            />
          </AudioButton>
        )}
      </InputButtonsContainer>

      <MobileDesktopPreviewToggle
        selectedPreviewMode={selectedPreviewMode}
        setSelectedPreviewMode={setSelectedPreviewMode}
      />

      <CustomMenu
        anchorEl={anchorEl}
        keepMounted
        open={currSelectedName === VIDEOINPUT}
        onClose={handleClose}
      >
        {videoInputs.map(input => inputItem(input, VIDEOINPUT))}
      </CustomMenu>

      <CustomMenu
        anchorEl={anchorEl}
        keepMounted
        open={currSelectedName === AUDIOINPUT}
        onClose={handleClose}
      >
        {audioInputs.map(input => inputItem(input, AUDIOINPUT))}
      </CustomMenu>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: fit-content;
  padding: 16px;
  z-index: 2;
  grid-row: 1;
  grid-column: 1;
  display: grid;
`;

const CustomMenu = styled(Menu).attrs(() => ({
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  PaperProps: {
    style: {
      maxHeight: 400,
      maxWidth: '40ch',
    },
  },
  getContentAnchorEl: undefined,
}))``;

const Title = styled(TextSmall)`
  max-width: 50ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const InputButtonsContainer = styled.div`
  gap: 8px;
  grid-row: 1;
  grid-column: 1;
  display: flex;
  flex-direction: row;
  justify-self: start;
`;

const AudioButton = styled(OverlayIconButton)`
  width: 100%;
  border-radius: 100px;
  gap: 8px;
  padding: 0 14px;
`;

const VideoButton = styled(OverlayIconButton)`
  min-width: 40px;
`;

export default ChooseDevices;
