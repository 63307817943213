import React from 'react';

const VideosIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5.75h-15A2.492 2.492 0 0 0 0 3.25v12.5c0 1.406 1.094 2.5 2.5 2.5h15c1.367 0 2.5-1.094 2.5-2.5V3.25c0-1.367-1.133-2.5-2.5-2.5ZM16.914 2l-3.75 3.75h-4.18L12.734 2h4.18Zm-5.937 0-3.75 3.75h-4.18L6.797 2h4.18ZM1.25 3.25C1.25 2.586 1.797 2 2.5 2h2.54L1.29 5.75h-.04v-2.5Zm17.5 12.5c0 .703-.586 1.25-1.25 1.25h-15c-.703 0-1.25-.547-1.25-1.25V7h17.5v8.75Zm0-10h-3.828l3.398-3.398c.274.234.43.546.43.898v2.5ZM8.008 15.633c.156.078.351.117.508.117.195 0 .39-.04.586-.156l4.14-2.696A1.03 1.03 0 0 0 13.75 12c0-.39-.195-.703-.508-.898l-4.14-2.657a1.01 1.01 0 0 0-1.094-.039 1.083 1.083 0 0 0-.508.899v5.39c0 .39.195.742.508.938Zm.742-5.938L12.305 12 8.75 14.344V9.695Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default VideosIcon;
