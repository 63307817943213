import React from 'react';
import EmailOpenedIcon from 'app/src/images/EmailOpenedIcon';
import FormattedLink from 'app/src/basic_components/FormattedLink';
import JourneyDrawerStep from './JourneyDrawerStep';
import styled from 'styled-components';
import useNavigation from 'app/src/hooks/useNavigation';
import { BASE_URL } from 'app/src/config/app.config';
import { JourneyDrawerStepType } from './types/JourneyDrawerStep.types';
import { useProjectActions } from 'app/src/context/ProjectsStore';

type Props = {
  data: JourneyDrawerStepType;
};

const JourneyEmailOpenStep = ({ data }: Props) => {
  const { createdAt, publishId } = data;
  const { getProjectByPublishId } = useProjectActions();
  const { getProjectRootRoute } = useNavigation();
  const project = getProjectByPublishId(publishId);
  const projectUrl = `${BASE_URL}${getProjectRootRoute(project?.id)}`;

  return (
    <JourneyDrawerStep header="Opened email" createdAt={createdAt} Icon={EmailOpenedIcon}>
      <StyledFormattedLink
        isDisabled={!project}
        text={project?.name || `Deleted Tolstoy (${publishId})`}
        url={projectUrl}
      />
    </JourneyDrawerStep>
  );
};

const StyledFormattedLink = styled(FormattedLink)`
  align-items: flex-start;
  width: fit-content;
`;

export default JourneyEmailOpenStep;
