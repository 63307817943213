import React from 'react';
import styled from 'styled-components';
import { TextSmall } from 'shared/react/components/complex/Text';
import Button from 'shared/react/components/complex/Button';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { useHistory } from 'react-router-dom';
import Routes from 'app/src/helpers/Routes';

function EmptyStateContainer({ onClosePanel }) {
  const history = useHistory();

  const handleNotificationSettingsClick = () => {
    onClosePanel();
    history.push(Routes.getTeamMemberNotificationsRoute());
  };

  return (
    <LayoutRoot>
      <TextSmall>
        You haven&apos;t received any notifications yet.
        <br />
        In the meanwhile, you can check your notification settings and make sure everything is set
        properly.
      </TextSmall>
      <StyledButton onClick={handleNotificationSettingsClick}>
        Edit notification settings
      </StyledButton>
    </LayoutRoot>
  );
}

export default EmptyStateContainer;

const LayoutRoot = styled(Gap16VerticalFlex)`
  padding: 8px 16px 0;
`;

const StyledButton = styled(Button)`
  justify-self: end;
`;
