import { css } from 'styled-components';

export const getMirroredVideo = () => {
  return css`
    transform: rotateY(180deg);

    &::-webkit-media-controls-panel {
      transform: rotateY(180deg);
    }
  `;
};
