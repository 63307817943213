import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Utils from 'src/utils';
import { App } from 'src/types/app';
import { track } from 'src/helpers/Tracker';
import SharedUtils from 'shared/react/utils/utils';
import { useAppActions } from 'src/context/AppsStore';
import { UgcRequestStep } from './UgcRequest.constants';
import { useVideoActions } from 'src/context/VideosStore';
import { useModal } from 'src/context/ui_store/ModalStore';
import StandardModalV2 from 'src/pages/modals/StandardModalV2';
import { getSocialUsername, makeHashtag } from 'src/utils/ugc';
import { VideosPageVodAsset } from 'src/pages/dashboard/pages/videos/types/videos.types';
import UGCRequest from 'src/complex_components/global_modal/ugc-request-modal/UGCRequest';
import useCreateIntegrationVideo from 'src/hooks/use-create-integration-video/useCreateIntegrationVideo';
import useCreateLabelsByUGC from 'src/complex_components/global_modal/ugc-request-modal/useCreateLabelsByUGC';
import {
  UGC_INSTAGRAM_GRAPH,
  UGC_INSTAGRAM_GRAPH_PENDING,
} from 'src/constants/intergrations.constants';
import { getStep, getDmUrl, getCurrentDate } from './UgcRequest.utils';
import { LoadState } from './UgcRequest.constants';
import { InstagramApiService } from 'src/services/instagram';

type Props = {
  closeModal: () => void;
};

type ModalProps = {
  video: VideosPageVodAsset;
  selectedApp: App;
  selectedUGCCategory?: string;
};

const UGCRequestModal = ({ closeModal }: Props) => {
  const [{ modalProps }] = useModal();
  const { updateVideo } = useVideoActions();
  const { updateApp } = useAppActions();
  const { importMultipleFiles } = useCreateIntegrationVideo();

  const { video, selectedApp, selectedUGCCategory } = modalProps as ModalProps;
  const isPending = video?.uploadType === UGC_INSTAGRAM_GRAPH_PENDING;
  const ugcCategory = !selectedUGCCategory
    ? getSocialUsername(selectedApp)
    : makeHashtag(selectedUGCCategory);

  const [loadState, setLoadState] = useState<LoadState | null>(null);
  const createLabelsByUGC = useCreateLabelsByUGC({ video });
  const [scrapedUsername, setScrapedUsername] = useState('');
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [hasFetchedUsername, setHasFetchedUsername] = useState(false);
  const [step, setStep] = useState(getStep({ video, isRequestSent }));
  const { username: assetUsername, postUrl } = Utils.safeParse(video.externalProviderData);
  const username = assetUsername || scrapedUsername;

  useEffect(() => {
    const newStep = getStep({ video, isRequestSent });

    if (newStep !== step) {
      setStep(newStep);
    }
  }, [video, step, isRequestSent]);

  useEffect(() => {
    if (!postUrl || hasFetchedUsername || username || step !== UgcRequestStep.Request) {
      return;
    }

    setLoadState(LoadState.LoadingUsername);

    InstagramApiService.getUsernameFromPost(postUrl)
      .then(res => {
        const { username } = res;
        setScrapedUsername(username);
      })
      .catch(error => {
        console.error('[UGCRequestModal] error fetching username', error);
      })
      .finally(() => {
        setLoadState(null);
        setHasFetchedUsername(true);
      });
  }, [video, step, hasFetchedUsername, username, postUrl]);

  const onGoToPost = () => {
    track('UGC View Post Click', { videoId: video.id });

    SharedUtils.openInNewTab(postUrl);
  };

  const moveToPending = async () => {
    setLoadState(LoadState.Importing);

    await importMultipleFiles({
      videos: [{ ...video, username }],
      method: UGC_INSTAGRAM_GRAPH_PENDING,
      ugcHashtag: ugcCategory,
    });

    const today = getCurrentDate();
    const numberOfSendsToday = selectedApp.settings?.numberOfSendsDate?.[today] + 1 || 1;

    await updateApp(selectedApp.id, {
      settings: JSON.stringify({
        ...(selectedApp.settings || {}),
        numberOfSendsDate: { [today]: numberOfSendsToday },
      }),
    });

    await createLabelsByUGC();

    setLoadState(null);
    setIsRequestSent(true);
  };

  const onRequest = async text => {
    track('UGC Copy & Go To Profile', { videoId: video.id });
    const url = username ? getDmUrl(username) : postUrl;

    SharedUtils.copyToClipboard(text);
    SharedUtils.openInNewTab(url);
    if (step === UgcRequestStep.Request) {
      await moveToPending();
    }
  };

  const onImport = async () => {
    setLoadState(LoadState.Importing);
    track('UGC Add To Library Click', {
      videoId: video.id,
      isPending: isPending,
    });

    if (isPending) {
      await updateVideo({ id: video.id, uploadType: UGC_INSTAGRAM_GRAPH });
    } else {
      await importMultipleFiles({
        videos: [{ ...video, username }],
        method: UGC_INSTAGRAM_GRAPH,
        ugcHashtag: ugcCategory,
      });
    }

    await createLabelsByUGC();

    setLoadState(null);
    closeModal();
  };

  return (
    <StyledStandardModal disableEnforceFocus onClose={closeModal} open>
      <UGCRequest
        step={step}
        video={video}
        username={username}
        selectedApp={selectedApp}
        loadState={loadState}
        onGoToPost={onGoToPost}
        onRequest={onRequest}
        onAddToLibrary={onImport}
      />
    </StyledStandardModal>
  );
};

const StyledStandardModal = styled(StandardModalV2)`
  width: 800px;
  height: 533px;
`;

export default UGCRequestModal;
