import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { BaseVideoSize } from 'app/src/basic_components/BaseVideoSize';
import { AssetsUtilsService } from 'shared/react/services/assets';

const UploadFilesVideoPreview = ({
  videoReady,
  setVideoReady,
  uploadedBlob,
  onCreateNewVideo,
  isCreationFlow,
}) => {
  const videoRef = useRef();
  const isImage = AssetsUtilsService.isImageFileSupported(uploadedBlob);

  useEffect(() => {
    setVideoReady(isImage);
    if (!uploadedBlob || !videoRef.current) {
      return;
    }

    videoRef.current.src = URL.createObjectURL(uploadedBlob);
  }, [uploadedBlob]);

  const handleCanPlay = ({ target }) => {
    const videoSizeInvalid = !target.videoHeight || !target.videoWidth;
    if (videoSizeInvalid && uploadedBlob) {
      onCreateNewVideo({ blob: uploadedBlob, mirror: false, videoInvalid: videoSizeInvalid });
      return;
    }

    videoRef.current.play();
    setVideoReady(true);
  };

  if (isImage) {
    return <Image src={URL.createObjectURL(uploadedBlob)} isCreationFlow={isCreationFlow} />;
  }

  return (
    <Video
      ref={videoRef}
      autoplay
      as="video"
      loop
      playsInline
      disablePictureInPicture={true}
      muted
      controls={true}
      onLoadedMetadata={handleCanPlay}
      hidden={!videoReady}
      isCreationFlow={isCreationFlow}
    />
  );
};

const sizeCss = css`
  width: ${({ isCreationFlow }) => (isCreationFlow ? '960px' : '100%')};
  height: ${({ isCreationFlow }) => (isCreationFlow ? '540px' : '100%')};
`;

const Video = styled(BaseVideoSize)`
  background: ${({ theme }) => theme.colors.black1};
  object-fit: contain;
  transition: opacity 250ms;
  border-radius: 0;
  z-index: ${({ hidden }) => (hidden ? '-1' : '1')};
  ${sizeCss}

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const Image = styled.img`
  ${sizeCss}
  object-fit: contain;
  border-radius: 0;
  z-index: 1;
`;

export default UploadFilesVideoPreview;
