import React from 'react';

const VOutlinedIcon = ({ fill = '#7D8087' }) => (
  <svg width={16} height={11} viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 2.031A.684.684 0 0 0 15 1a.684.684 0 0 0-1.031 0L6 8.969 2 5A.684.684 0 0 0 .969 5a.684.684 0 0 0 0 1.031l4.5 4.5c.125.156.312.219.531.219a.622.622 0 0 0 .5-.219l8.5-8.5Z"
      fill={fill}
    />
  </svg>
);

export default VOutlinedIcon;
