import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Portal from 'shared/react/components/basic/Portal';
import useOnClickOutside from 'shared/react/hooks/useOutsideClick';
import { BREAKPOINT_OPTIONS } from 'shared/react/constants/menu.constants';
import useKeyPress from 'shared/react/hooks/useKeyPress';

const MIN_END_SCREEN_DISTANCE = 10;

const Menu = ({
  open,
  onClose,
  anchorEl,
  children,
  direction = { vertical: 'bottom', horizontal: 'center' },
  breakpoint = BREAKPOINT_OPTIONS.grow,
  canCloseMenu,
  className,
  endScreenDistance = MIN_END_SCREEN_DISTANCE,
  contentEndLocation = 'bottom',
  closeOnEnter = false,
}) => {
  const [ref, setRef] = useState(null);
  const [transition, setTransition] = useState(true);
  const closeMenu = () => {
    if (canCloseMenu?.() || !open) {
      return;
    }

    setTransition(true);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  const onEnterClick = () => {
    if (closeOnEnter) {
      closeMenu();
    }
  };

  useKeyPress('Enter', onEnterClick);
  useOnClickOutside([{ current: ref }], closeMenu);

  const onResize = () => {
    if (!ref || !anchorEl) {
      return;
    }

    const clientRect = anchorEl.getBoundingClientRect();
    let newTop = clientRect[direction.vertical];
    let newLeft = clientRect[direction.horizontal];

    if (direction.horizontal === 'center') {
      const diff = ref.clientWidth / 2;
      newLeft = clientRect.right - diff - (clientRect.right - clientRect.left) / 2;
    }

    if (direction.horizontal === 'left') {
      newLeft = clientRect.left - ref.clientWidth - endScreenDistance;
    }

    if (direction.vertical === 'center') {
      const diff = ref.clientHeight / 2;
      newTop = clientRect.bottom - diff - (clientRect.bottom - clientRect.top) / 2;
    }

    if (contentEndLocation === 'top' && direction.vertical === 'top') {
      newTop = newTop - ref.clientHeight;
      if (newTop - ref.clientHeight <= 0) {
        newTop = 0;
      }
    }

    if (newTop + ref.clientHeight > window.innerHeight - MIN_END_SCREEN_DISTANCE) {
      if (breakpoint === BREAKPOINT_OPTIONS.moveToTop) {
        newTop = clientRect.top - ref.clientHeight;
      } else {
        const diff = ref.clientHeight + newTop - window.innerHeight + endScreenDistance;
        newTop = newTop - diff;
      }
    }

    if (newLeft + ref.clientWidth > window.innerWidth) {
      const diff = ref.clientWidth + newLeft - window.innerWidth + endScreenDistance;
      newLeft = newLeft - diff;
    }

    if (newLeft < 0) {
      newLeft = 0;
    }

    ref.style.top = `${newTop}px`;
    ref.style.left = `${newLeft}px`;
  };

  useEffect(() => {
    if (open) {
      setTransition(true);
    }
    setTransition(false);
  }, [open]);

  useEffect(() => {
    if (!ref?.clientHeight) {
      return;
    }

    onResize();
  }, [children, ref]);

  useEffect(() => {
    if (!ref || !open) {
      return;
    }

    const resizeObserver = new ResizeObserver(entries => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || entries.length === 0) {
          return;
        }

        onResize();
      });
    });
    resizeObserver.observe(ref);
    return () => resizeObserver?.disconnect();
  }, [ref, open]);

  if (!open) {
    return null;
  }

  return (
    <Portal>
      <Content
        onClick={e => e.stopPropagation()}
        className={className}
        transition={transition}
        ref={setRef}
      >
        {children}
      </Content>
    </Portal>
  );
};

const Content = styled.div`
  position: absolute;
  left: -9999px;
  top: -9999px;
  z-index: 999999;
  transition: 0.3s opacity;
  opacity: ${({ transition }) => (transition ? 0 : 1)};
`;

export default Menu;
