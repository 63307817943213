import React from 'react';
import styled from 'styled-components';

import VideoGrid from 'shared/react/components/complex/VideoGrid';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import FeedSearchProductCarousel from 'shared/react/components/complex/feed/feed_search/FeedSearchProductCarousel';
import { FEED_TYPES } from 'shared/react/constants/feed.constants';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';

const FeedSearchContent = ({
  isMobile,
  videos,
  products,
  trendingVideos,
  onVideoClick,
  isLoading,
  searchTerm,
  currentStepKey,
}) => {
  const [, { t }] = useTranslation('feed.search');

  const handleSearchVideoClick = (video, index) => {
    onVideoClick({ feedType: FEED_TYPES.search, video, index });
  };

  const handleTrendingVideoClick = (video, index) => {
    onVideoClick({ feedType: FEED_TYPES.trending, video, index });
  };

  const hasSearchVideos = !isLoading && !!videos?.length;

  return (
    <LayoutRoot>
      {hasSearchVideos && <Title>{t('similarVideos')}</Title>}
      <StyledVideoGrid
        isMobile={isMobile}
        videos={videos}
        onVideoClick={handleSearchVideoClick}
        isLoading={isLoading}
        emptyStateMessage={'No videos found'}
      />

      <SectionDivider
        title={t('similarProducts')}
        isHidden={!products?.length}
        hasTopBorder={hasSearchVideos}
      />
      <FeedSearchProductCarousel
        searchTerm={searchTerm}
        isMobile={isMobile}
        products={products}
        currentStepKey={currentStepKey}
      />

      <SectionDivider
        title={t('trendingVideos')}
        isHidden={!trendingVideos?.length}
        hasTopBorder={products?.length || hasSearchVideos}
      />
      <StyledVideoGrid
        isMobile={isMobile}
        videos={trendingVideos}
        onVideoClick={handleTrendingVideoClick}
      />
    </LayoutRoot>
  );
};

const SectionDivider = ({ title, isHidden, hasTopBorder }) => {
  if (isHidden) {
    return null;
  }

  return (
    <SectionDividerWrapper>
      <Divider hidden={!hasTopBorder} />
      <Title>{title}</Title>
    </SectionDividerWrapper>
  );
};

const LayoutRoot = styled.div`
  overflow: scroll;
`;

const StyledVideoGrid = styled(VideoGrid)`
  max-width: 500px;
  margin: auto;
`;

const SectionDividerWrapper = styled.div`
  padding-top: 8px;
`;

const Divider = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${({ theme }) => theme.colors.neutralLightest};
`;

const Title = styled(HorizontalFlex)`
  font-weight: 600;
  font-size: 16px;
  padding: 8px;
  justify-content: flex-start;
`;

export default FeedSearchContent;
