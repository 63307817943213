import React, { useEffect, useId, useRef, useState } from 'react';
import ComponentWithLoader from 'shared/react/components/basic/ComponentWithLoader';
import Overlay from './Overlay';
import PauseVideoIcon from 'app/src/images/video_icon/PauseVideoIcon';
import PlayArrowIcon from 'app/src/images/PlayArrowIcon';
import styled from 'styled-components';
import { AnalyticsPlayerBaseMetrics } from './AnalyticsPlayer.types';
import { useAnalyticsPlayersManager } from './AnalyticsPlayersManager';
import { useVideoById } from 'app/src/context/VideosStore';

type AnalyticsPlayerProps = {
  metrics: AnalyticsPlayerBaseMetrics;
  vodAssetId: string;
};

const AnalyticsPlayer = ({ vodAssetId, metrics }: AnalyticsPlayerProps) => {
  const { activePlayer, togglePlayer } = useAnalyticsPlayersManager();
  const [video, { getVideo, getVideoUrl, getVideoPosterLarge }] = useVideoById(vodAssetId);
  const playerId = useId();
  const [metadataLoaded, setMetadataLoaded] = useState(false);
  const videoRef = useRef<HTMLVideoElement>();

  useEffect(() => {
    getVideo(vodAssetId);
  }, [getVideo, vodAssetId]);

  const isPlayerActive = Boolean(video && activePlayer === playerId);
  useEffect(() => {
    if (!metadataLoaded) {
      return;
    }

    if (isPlayerActive) {
      videoRef.current?.play();
      return;
    }

    videoRef.current?.pause();
  }, [isPlayerActive, metadataLoaded]);

  const poster = video ? getVideoPosterLarge(video) : undefined;
  const VideoIcon = isPlayerActive ? PauseVideoIcon : PlayArrowIcon;

  return (
    <CustomLoader isLoading={!video} size={25}>
      <LayoutRoot>
        <Video
          disablePictureInPicture
          loop
          muted
          onClick={() => togglePlayer(playerId)}
          onLoadedMetadata={() => setMetadataLoaded(true)}
          playsInline
          poster={poster}
          ref={videoRef}
          src={getVideoUrl(video)}
        />
        <VideoIconContainer>
          <VideoIcon />
        </VideoIconContainer>
        <Overlay metrics={metrics} video={video} />
      </LayoutRoot>
    </CustomLoader>
  );
};

const LayoutRoot = styled.div`
  background: ${({ theme }) => theme.colors.black};
  height: 100%;
  position: relative;
`;

const CustomLoader = styled(ComponentWithLoader)`
  margin: 90% auto;
`;

const Video = styled.video`
  aspect-ratio: 9 / 16;
  cursor: pointer;
  height: 100%;
  object-fit: contain;
  width: auto;
`;

const VideoIconContainer = styled.div`
  position: absolute;
  bottom: 50%;
  left: 50%;
  pointer-events: none;

  svg {
    transform: scale(1.5) translate(-50%, 50%);
  }

  path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export default AnalyticsPlayer;
