import React from 'react';
import { useResponses } from 'app/src/context/ResponsesStore';
import styled from 'styled-components';

const ImageResponse = ({ response, children }) => {
  const [, { getImageResponseUrl }] = useResponses();
  const imageUrl = getImageResponseUrl(response);

  const onImageClick = () => {
    window.open(imageUrl, '_blank');
  };

  return (
    <>
      {children}
      <Image src={imageUrl} onClick={onImageClick} />
    </>
  );
};

export default ImageResponse;

const Image = styled.img`
  width: 100%;
  height: 269px;
  object-fit: cover;
  border-radius: 16px;
  border: none;
  cursor: pointer;

  :hover {
    border: 2px solid ${({ theme }) => theme.colors.blue5};
  }
`;
