import React from 'react';

const ChilliPiperIcon = ({ height = 32, width = 30 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 30 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.492 6.502s1.35-.236 2.361 0c0 0 1.49-1.178 1.519-5.098L26.412 0s1.63 3.477-.224 7.142c0 0 1.349.922 1.546 1.483l-.194.62a20.559 20.559 0 0 0-6.207-2.104l.16-.639ZM0 31.991c.013 0 6.376.316 13.511-2.805a36.668 36.668 0 0 1-5.117-2.875A32.484 32.484 0 0 1 0 31.99Zm23.467-10.43a24.165 24.165 0 0 1-6.286-2.21c-1.366 1.965-3.108 4.016-5.284 5.799 0 0 2.277-2.373 4.352-6.304a24.343 24.343 0 0 1-1.697-1.068 30.843 30.843 0 0 1-4.596 6.784 32.457 32.457 0 0 0 5.84 3.171 31.04 31.04 0 0 0 7.67-6.173ZM19.498 10.34l2.245.552s-.917 3.009-3.083 6.712a22.497 22.497 0 0 0 6.976 2.307 33.72 33.72 0 0 0 4.231-7.568 21.793 21.793 0 0 0-12.23-3.81 34.1 34.1 0 0 1-1.992 7.182c.584.432 1.187.836 1.81 1.211a31.644 31.644 0 0 0 2.043-6.586"
      fill="#FF5722"
    />
  </svg>
);

export default ChilliPiperIcon;
