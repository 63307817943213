export const VIDEO_INBOX_LOCATION = 'Video inbox';

export const ONBOARDING_LOCATION = 'Onboarding';

export const IMPORT_FROM_INTEGRATION_LOCATIONS = {
  integrations: 'Integration page',
  creationModal: 'Creation modal',
  videoInbox: VIDEO_INBOX_LOCATION,
  onboarding: ONBOARDING_LOCATION,
};
