import React from 'react';

const ResetToDefaultIcon = () => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.82 6.117a.656.656 0 0 0-.383.602v3.5c0 .383.274.656.657.656h3.5a.656.656 0 0 0 .601-.383c.082-.246.028-.547-.164-.71L8.61 8.331c.903-.848 2.079-1.395 3.391-1.395a4.82 4.82 0 0 1 4.813 4.813c0 2.68-2.16 4.813-4.813 4.813-1.148 0-2.242-.383-3.09-1.094-.273-.246-.683-.192-.93.082a.682.682 0 0 0 .082.93A6.183 6.183 0 0 0 12 17.874c3.363 0 6.125-2.734 6.125-6.125 0-3.363-2.762-6.125-6.125-6.125-1.668 0-3.172.684-4.32 1.805L6.53 6.28c-.164-.191-.465-.246-.71-.164Z"
      fill="#50545E"
    />
  </svg>
);

export default ResetToDefaultIcon;
