import { createGlobalStyle } from 'styled-components';

const FeedGlobalStyle = createGlobalStyle`
  html {
    overflow: hidden;
  }

  body {
    background: ${({ theme }) => theme.colors.white};
  }
  
  #portal {
    font-family: ${({ customFontFamily }) =>
      customFontFamily ? customFontFamily : 'Nunito Sans, sans-serif'};
    color: ${({ theme }) => theme.colors.gray2};

    h1, h2, h3, h4, h5, h6 {
      font-family: ${({ customHeadingFontFamily }) =>
        customHeadingFontFamily ? customHeadingFontFamily : 'Nunito Sans, sans-serif'};
    }
  }
`;

export default FeedGlobalStyle;
