import { useModalActions } from 'app/src/context/ui_store/ModalStore';
import { useVodAssets } from 'app/src/context/VideosStore';
import { useCallback } from 'react';
import { ASSET_TYPE, AssetRenditionsService } from 'shared/react/services/assets';
import {
  GALLERY_MODAL_KEY,
  ADD_PRODUCTS_MODAL_KEY,
  VIDEO_PREVIEW_MODAL_KEY,
} from 'app/src/constants/modals.constants';
import { TIKTOK } from 'shared/react/constants/intergrations.constants';
import { track } from 'app/src/helpers/Tracker';
import { FEATURE_DYNAMIC_FEED } from 'shared/react/constants/features.constants';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import { useAppActions } from 'app/src/context/AppsStore';

export const useVideoSelectorForModal = ({ analyticsData }) => {
  const { getFeatureEnabled } = useFeatureActions();
  const isDynamicEnabled = getFeatureEnabled(FEATURE_DYNAMIC_FEED);
  const { getProductsCatalogApp } = useAppActions();
  const productsCatalogApp = getProductsCatalogApp();
  const isProductsTaggingEnabled = isDynamicEnabled && productsCatalogApp;
  const { setCurrentId, setCurrentModal, setCurrentIds, setModalProps } = useModalActions();
  const [videos] = useVodAssets();

  const openVideoInModal = useCallback(
    video => {
      if (video.type === ASSET_TYPE.GALLERY) {
        setCurrentIds([video.id]);

        const videoMap = new Map(videos.map(asset => [asset.id, asset]));
        const selectedImages = video.referenceIds
          .map(refId => videoMap.get(refId))
          .filter(asset => asset !== undefined);

        setModalProps({ selectedImages, gallery: video });
        setCurrentModal(GALLERY_MODAL_KEY);
        return;
      }

      const videoUrl = video?.app !== TIKTOK && video?.videoUrl;
      const posterUrl = AssetRenditionsService.getAssetPosterLarge(video);

      if (isProductsTaggingEnabled) {
        track('Video Card Add Products Click', analyticsData);
        setCurrentIds([video.id]);
        setCurrentModal(ADD_PRODUCTS_MODAL_KEY);
        setModalProps({ thumbnail: posterUrl, videoUrl, analyticsData });
        return;
      }

      track('Video Card Click', analyticsData);
      setCurrentId(video.id);
      setModalProps({ videoUrl, thumbnail: posterUrl });
      setCurrentModal(VIDEO_PREVIEW_MODAL_KEY);
    },
    [
      analyticsData,
      isProductsTaggingEnabled,
      setCurrentId,
      setCurrentIds,
      setCurrentModal,
      setModalProps,
      videos,
    ]
  );

  return { openVideoInModal };
};
