import React, { useState } from 'react';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import Carousel from 'widget/src/widgets/EmbedWidgets/Carousel/Carousel';
import { useDynamicConditionsContext } from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/context/DynamicConditionsContext';
import useWidgetPreviewInit from 'src/hooks/preview/useWidgetPreviewSettings';
import styled from 'styled-components';
import EmbedCarouselPreview from 'app/src/pages/project/pages/look_and_feel/preview/embed_carousel_preview/EmbedCarouselPreview';
import { embedCarouselPreviewSize } from 'app/src/pages/project/pages/look_and_feel/preview/embed_carousel_preview/EmbedPreviewCss';

const EmbedCarouselPreviewContainer = () => {
  const [element, setElement] = useState(null);
  const [storyClass] = useState(new Carousel());
  const { project, customizationSettings } = useLookAndFeelContext();
  const { vodAssetIds } = useDynamicConditionsContext();
  useWidgetPreviewInit(
    element,
    storyClass,
    project,
    vodAssetIds,
    customizationSettings,
    'carousel'
  );

  return (
    <EmbedCarouselPreview>
      <LayoutRoot ref={setElement} />
    </EmbedCarouselPreview>
  );
};

const LayoutRoot = styled.div`
  height: 100%;

  .tolstoy-carousel-title {
    padding: 0 0 24px 0;
  }

  .tolstoy-carousel-container {
    margin: 0 auto;
  }

  ${embedCarouselPreviewSize}
`;

export default EmbedCarouselPreviewContainer;
