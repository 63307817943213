import React from 'react';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import AreYouSureModal from 'app/src/pages/modals/AreYouSureModal';

const ConfirmModal = () => {
  const [
    {
      modalProps: {
        actionLevel,
        secondaryButtonText,
        closeButtonClicked,
        actionButtonClicked,
        titleText,
        actionButtonText,
        secondaryButtonClicked,
        loading,
      },
    },
    { clearModalState },
  ] = useModal();

  return (
    <AreYouSureModal
      loading={loading}
      actionButtonText={actionButtonText}
      titleText={titleText}
      actionLevel={actionLevel}
      secondaryButtonText={secondaryButtonText}
      secondaryButtonClicked={secondaryButtonClicked || clearModalState}
      actionButtonClicked={actionButtonClicked}
      closeButtonClicked={closeButtonClicked || clearModalState}
      open
    />
  );
};

export default ConfirmModal;
