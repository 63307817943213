import React, { useState } from 'react';
import styled from 'styled-components';
import { PrimaryButton, LinkButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { useParams } from 'react-router-dom';
import { useProjects } from 'app/src/context/ProjectsStore';
import RefreshIcon from 'src/images/dashboard_v2/RefreshIcon';
import Utils from 'src/utils';
import SetLiveButton from 'app/src/pages/project/components/project-top-bar/components/set-live-button/SetLiveButton';
import { useApps } from 'src/context/AppsStore';

const ShopAppButtons = () => {
  const { projectId } = useParams();
  const [{ selectedApp }] = useApps();
  const [isSyncInProgress, setIsSyncInProgress] = useState(false);
  const [{ project = {} }, { publishToShopApp, unpublishFromShopApp }] = useProjects({ projectId });
  const { live: isLive } = project;

  const isShopAppEligible = selectedApp?.isShopAppEligible;

  const onSyncClick = async () => {
    setIsSyncInProgress(true);
    try {
      await publishToShopApp(project.publishId);
    } catch (e) {
      console.log(e);
      Utils.logError('Shop App: publish error', e);
    }
    setIsSyncInProgress(false);
  };

  const onToggleEntryPointsClick = async () => {
    setIsSyncInProgress(true);
    try {
      const action = isLive ? unpublishFromShopApp : publishToShopApp;

      await action(project.publishId);
    } catch (e) {
      Utils.logError('Shop App: publish/unpublish error', e);
      console.log(e);
    }
    setIsSyncInProgress(false);
  };

  const getSecondaryButton = () => {
    if (isShopAppEligible) {
      return (
        <PrimaryButton onClick={onSyncClick} isLoading={isSyncInProgress} disabled={!isLive}>
          <SyncIcon />
          Sync videos
        </PrimaryButton>
      );
    }

    return (
      <LinkButton as="a" href="https://apps.shopify.com/shop" target="__blank">
        Install Shop channel
      </LinkButton>
    );
  };

  return (
    <>
      {getSecondaryButton()}

      <SetLiveButton
        isLive={isLive}
        onSetLive={onToggleEntryPointsClick}
        onSetUnLive={onToggleEntryPointsClick}
        isLoading={isSyncInProgress}
        disabled={!isShopAppEligible}
      />
    </>
  );
};

const SyncIcon = styled(RefreshIcon)`
  & path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export default ShopAppButtons;
