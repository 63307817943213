import React from 'react';
import { SmallProductImage } from './SmallProductImage';

export type VideoCardProductLabelProps = {
  product: any;
  extraProductsCount: number;
};

export const VideoCardProductLabel = ({
  product,
  extraProductsCount,
}: VideoCardProductLabelProps) => {
  return (
    <div className="flex gap-2">
      <div className="flex items-center gap-1 rounded border p-1">
        <SmallProductImage imageFit="cover" src={product.imageUrl} />
        <div className="max-w-36 truncate text-ellipsis font-medium">{product.title}</div>
      </div>
      <div className="min-w-8 rounded border p-1.5 text-center font-medium">
        +{extraProductsCount || null}
      </div>
    </div>
  );
};
