import React from 'react';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextSmall, BolderTextBody } from 'shared/react/components/complex/Text';
import useProjectType from 'app/src/hooks/useProjectType';

function EmptyState() {
  const { projectDisplayType } = useProjectType();

  return (
    <LayoutRoot>
      <BolderTextBody>Decide when and how to show your {projectDisplayType}!</BolderTextBody>
      <TextSmall>
        You can set any rules you’d like to make your {projectDisplayType} appear to specific users
        on specific pages, etc.
      </TextSmall>
    </LayoutRoot>
  );
}

export default EmptyState;

const LayoutRoot = styled(VerticalFlex)`
  gap: 24px;
`;
