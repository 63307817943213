import styled from 'styled-components';
import React, { useState, useMemo } from 'react';
import { Smartphone, Monitor } from 'lucide-react';
import { Switch, Input, Label } from '@tolstoy/ui-library/core';

import { useFeatureActions } from 'app/src/context/FeaturesStore';
import { FEATURE_WIDGET_SPOTLIGHT_CAROUSEL } from 'app/src/constants/appFeatures.constants';
import {
  CAROUSEL_TYPE,
  VIDEOS_FROM_LAST_X_DAYS_KEY,
  SPOTLIGHT_SETTINGS_PROPERTY_KEY,
  MINIMUM_NUMBER_OF_TILES_PROPERTY_KEY,
  SPOTLIGHT_CAROUSEL_CONTENT_TILE_TYPE,
  CAROUSEL_PADDING_HORIZONTAL_PROPERTY_KEY,
  SPOTLIGHT_CAROUSEL_CONTENT_TILE_TYPE_PROPERTY_KEY,
  SPOTLIGHT_CAROUSEL_CONTENT_TILE_FALLBACK_PROPERTY_KEY,
} from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import { useLookAndFeelContext } from 'src/pages/project/pages/look_and_feel/LookAndFeelContext';
import {
  DEFAULT_SPOTLIGHT_SETTINGS_MOBILE,
  DEFAULT_SPOTLIGHT_SETTINGS_DESKTOP,
} from 'src/pages/project/pages/look_and_feel/constants';
import { SpotlightSettingsType, SpotlightSizingMode, ControlSettings, PlayMode, MainTilePosition, ScrollMode } from 'src/pages/project/pages/look_and_feel/types';
import MinimumNumberOfVideos from 'src/pages/project/pages/look_and_feel/editor/editors/shared/MinimumNumberOfVideos';
import EditorOnOffToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorOnOffToggleItem';
import { EditorItem, SingleToggleItem, SingleToggleGroup } from 'src/pages/project/pages/look_and_feel/editor/editors/shared/ui-library';

import { FeedSpotlightFixedEditor } from './FeedSpotlightFixedEditor';
import { SpotlightControlButtonEditor } from './SpotlightControlButtonEditor';
import { FeedSpotlightResponsiveEditor } from './FeedSpotlightResponsiveEditor';

// TODO move to settings
const SETTINGS_MODE_TYPE = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
}

const DEVICE_SETTING_ITEMS: SingleToggleItem[] = [
  {
    value: 'mobile',
    component: <Smartphone className="text-gray-500 h-4 w-4" />,
  },
  {
    value: 'desktop',
    component: <Monitor className="text-gray-500 h-4 w-4" />,
  },
];

const SIZING_MODE_ITEMS = [
  {
    value: 'responsive',
    label: 'Responsive',
  },
  {
    value: 'fixed',
    label: 'Fixed',
  },
];

const PLAY_MODE_ITEMS = [
  {
    value: PlayMode.Fixed,
    label: 'Center tile',
  },
  {
    value: PlayMode.Dynamic,
    label: 'Left to Right',
  },
];

const CONTENT_TILE_ITEMS = [
  {
    value: SPOTLIGHT_CAROUSEL_CONTENT_TILE_TYPE.PRODUCT,
    label: 'Product',
  },
  {
    value: SPOTLIGHT_CAROUSEL_CONTENT_TILE_TYPE.VIDEO_NAME,
    label: 'Video name',
  },
  {
    value: SPOTLIGHT_CAROUSEL_CONTENT_TILE_TYPE.NONE,
    label: 'None',
  },
];

interface FeedSpotlightGeneralSettingsProps {
  disabled?: boolean;
}

export const FeedSpotlightGeneralSettings = ({ disabled }: FeedSpotlightGeneralSettingsProps) => {
  const { getFeatureEnabled } = useFeatureActions();
  const isSpotlightCarouselEnabled = getFeatureEnabled(FEATURE_WIDGET_SPOTLIGHT_CAROUSEL);
  const {
    customizationSettings: {
      widgetSettings: {
        carouselType,
        videosFromLastXDays,
        minimumNumberOfTiles,
        spotlightSettings = {},
        carouselPaddingHorizontal,
        spotlightCarouselContentTileType,
        spotlightCarouselContentTileFallback,
      },
    },
    setWidgetSettingsProperty,
    updateCustomizationSettings,
  } = useLookAndFeelContext();
  const isSpotlightCarousel =
    isSpotlightCarouselEnabled && carouselType === CAROUSEL_TYPE.SPOTLIGHT;
  
  const [settingsMode, setSettingsMode] = useState(SETTINGS_MODE_TYPE.DESKTOP);
  const config: SpotlightSettingsType = useMemo(() => {
    return {
      mobile: {
        ...DEFAULT_SPOTLIGHT_SETTINGS_MOBILE,
        ...(spotlightSettings.mobile || {}),
      },
      desktop: {
        ...DEFAULT_SPOTLIGHT_SETTINGS_DESKTOP,
        ...(spotlightSettings.desktop || {}),
      },
    }
  }, [spotlightSettings]);

  const isMobile = settingsMode === SETTINGS_MODE_TYPE.MOBILE;
  const isDesktop = settingsMode === SETTINGS_MODE_TYPE.DESKTOP;
  const activeConfig = isMobile ? config.mobile : config.desktop;

  const isResponsive = activeConfig.sizingMode === SpotlightSizingMode.Responsive;
  const marginX = activeConfig.marginX || carouselPaddingHorizontal || 0;
  const fallbackText = activeConfig.fallbackText || spotlightCarouselContentTileFallback;
  const contentTileType = activeConfig.contentTileType || spotlightCarouselContentTileType;
  const isProductTileEnabled = contentTileType === SPOTLIGHT_CAROUSEL_CONTENT_TILE_TYPE.PRODUCT;

  const updateSpotlightSettings = (update = {}) => {
    const value = {
      ...config,
      mobile: {
        ...config.mobile,
        ...(isMobile ? update : {}),
      },
      desktop: {
        ...config.desktop,
        ...(isDesktop ? update : {}),
      },
    };

    setWidgetSettingsProperty(SPOTLIGHT_SETTINGS_PROPERTY_KEY, value);
  }
  
  if (!isSpotlightCarousel) {
    return null;
  }

  const onProductHeightChange = e => {
    const product = {
      ...activeConfig.product,
      height: parseInt(e.target.value) || 0,
    };

    updateSpotlightSettings({ product });
  }

  const setMarginX = (e) => {
    const marginX = parseInt(e.target.value) || 0;

    updateSpotlightSettings({ marginX });
    // resetting global setting as more specific was set
    setWidgetSettingsProperty(CAROUSEL_PADDING_HORIZONTAL_PROPERTY_KEY, null);
  }

  const onFallbackTextChange = e => {
    const fallbackText = e.target.value;

    updateSpotlightSettings({ fallbackText });
    // resetting global setting as more specific was set
    setWidgetSettingsProperty(SPOTLIGHT_CAROUSEL_CONTENT_TILE_FALLBACK_PROPERTY_KEY, '');
  }

  const onContentTileTypeChange = (contentTileType) => {
    updateSpotlightSettings({ contentTileType });
    // resetting global setting as more specific was set
    setWidgetSettingsProperty(SPOTLIGHT_CAROUSEL_CONTENT_TILE_TYPE_PROPERTY_KEY, '');
  }

  const onPlayerAndWidgetSettingChange = (propertyKey, value) => {
    updateCustomizationSettings({
      playerSettings: {
        [propertyKey]: value,
      },
      widgetSettings: {
        [propertyKey]: value,
      },
    });
  };

  const updateControlSettings = (update: Partial<ControlSettings> = {}) => {
    const controls = {
      ...activeConfig.controls,
      ...update
    };

    updateSpotlightSettings({ controls });
  };

  const onMinimumNumberOfTilesChange = value => {
    setWidgetSettingsProperty(MINIMUM_NUMBER_OF_TILES_PROPERTY_KEY, value);
  };

  const onVideosFromLastXDaysChange = value => {
    onPlayerAndWidgetSettingChange(VIDEOS_FROM_LAST_X_DAYS_KEY, value);
  };

  const handlePlayModeChange = value => {
    const update = value === PlayMode.Dynamic ? {
      playMode: PlayMode.Dynamic,
      scrollMode: ScrollMode.Finite,
      mainTilePosition: MainTilePosition.Start,
    } : {
      playMode: PlayMode.Fixed,
      scrollMode: ScrollMode.Infinite,
      mainTilePosition: MainTilePosition.Center,
    };

    updateSpotlightSettings(update);
  };
  
  return (
    <div className="flex flex-col gap-4">
      <MinimumNumberOfVideos
        value={minimumNumberOfTiles}
        onChange={onMinimumNumberOfTilesChange}
      />
      <StyledEditorOnOffToggleItem
        text="Show videos from the last 30 days"
        checked={videosFromLastXDays}
        onChange={onVideosFromLastXDaysChange}
        tooltipText="Toggle on to display only the videos in your playlist that were created within the last 30 days. This setting ensures the playlist remains updated with the most recent content."
        isToggleEnabled={isSpotlightCarousel}
      />

      <EditorItem label="Spotlight Settings">
        <SingleToggleGroup
          value={settingsMode}
          items={DEVICE_SETTING_ITEMS}
          onChange={setSettingsMode}
        />
      </EditorItem>
      <EditorItem label="Video plays in">
        <SingleToggleGroup
          value={activeConfig.playMode}
          items={PLAY_MODE_ITEMS}
          onChange={handlePlayModeChange}
        />
      </EditorItem>
      <EditorItem label="Content under tile">
        <SingleToggleGroup
          value={contentTileType}
          items={CONTENT_TILE_ITEMS}
          onChange={onContentTileTypeChange}
        />
      </EditorItem>
      <EditorItem label="Sizing Mode">
        <SingleToggleGroup
          value={activeConfig.sizingMode}
          items={SIZING_MODE_ITEMS}
          onChange={sizingMode => updateSpotlightSettings({ sizingMode })}
        />
      </EditorItem>

      {isResponsive ? (
        <FeedSpotlightResponsiveEditor
          config={activeConfig}
          onUpdate={updateSpotlightSettings}
        />
      ) : (
        <FeedSpotlightFixedEditor
          config={activeConfig}
          onUpdate={updateSpotlightSettings}
        />
      )}

      <EditorItem label="Horizontal padding" labelClassName="text-gray-700 font-medium">
        <Input
          className="flex-1 max-w-24"
          value={marginX}
          onChange={setMarginX}
        />
      </EditorItem>

      <EditorItem label="Display arrows">
        <Switch
          checked={activeConfig.shouldShowArrows}
          onCheckedChange={shouldShowArrows => updateSpotlightSettings({ shouldShowArrows })}
        />
      </EditorItem>

      {isProductTileEnabled && (
        <div className="flex flex-col gap-4">
          <Label className="text-sm font-semibold text-gray-900">
            Product tile
          </Label>
          <EditorItem label="Height" labelClassName="text-gray-700 font-medium">
            <Input
              className="flex-1 max-w-24"
              placeholder="Height"
              value={activeConfig.product.height}
              onChange={onProductHeightChange}
            />
          </EditorItem>
        </div>
      )}

      <EditorItem label="Fallback text">
        <Input
          className="flex-1"
          placeholder="Fallback text"
          value={fallbackText}
          onChange={onFallbackTextChange}
          disabled={disabled}
        />
      </EditorItem>

      <SpotlightControlButtonEditor
        label="Play button"
        settings={activeConfig.controls?.play}
        onUpdate={play => updateControlSettings({ play })}
      />
      <SpotlightControlButtonEditor
        label="Mute button"
        settings={activeConfig.controls?.mute}
        onUpdate={mute => updateControlSettings({ mute })}
      />
    </div>
  );
};

const StyledEditorOnOffToggleItem = styled(EditorOnOffToggleItem)`
  display: ${({ isToggleEnabled }) => (isToggleEnabled ? '' : 'none')};
`;

