import React from 'react';
import EditorNumericInputItem from '../../../../../project/pages/look_and_feel/editor/editors/shared/EditorNumericInputItem';

type Props = {
  radius: number;
  disabled?: boolean;
  onChange?: any;
};

const BorderRadiusEditor = ({ radius, disabled = false, onChange = () => {} }: Props) => {
  return (
    <EditorNumericInputItem
      text="Border radius"
      suffix="px"
      value={radius}
      onChange={onChange}
      height="50px"
      disabled={disabled}
    />
  );
};

export default BorderRadiusEditor;
