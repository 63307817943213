import React from 'react';
import styled from 'styled-components';
import { TextSmall } from 'shared/react/components/complex/Text';
import IosSwitch from 'app/src/basic_components/IosSwitch';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import { track } from 'app/src/helpers/Tracker';
import { useRulesContext } from 'app/src/pages/project/pages/rules_and_triggers/RulesContext';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { FEATURE_WIDGET_DELAY_TRIGGER } from 'shared/react/constants/features.constants';
import { useRules } from 'app/src/context/RulesStore';
import { DELAY_TRIGGER_TYPE } from 'app/src/constants/ruleGroups.constants';
import NumberInput from 'app/src/pages/project/pages/rules_and_triggers/editor/event_triggers/NumberInput';
import Tooltip from 'app/src/basic_components/Tooltip';

const ZERO_CHAR_CODE = 30;
const NINE_CHAR_CODE = 39;
const MAX_SECONDS_DELAY = 30;

const ShowAfter = ({ disabled, tooltipText }) => {
  const {
    delayTriggerEnabled,
    delayTriggerSeconds = 1,
    ruleGroup,
    setRuleGroup,
    setUnsaved,
  } = useRulesContext();
  const [{ invalidRules }, { clearInvalidRulesByType }] = useRules();
  const [, { getFeatureEnabled }] = useFeatures();

  if (!getFeatureEnabled(FEATURE_WIDGET_DELAY_TRIGGER)) {
    return null;
  }

  const error = invalidRules?.find(({ type }) => type === DELAY_TRIGGER_TYPE);

  const updateState = ({ key, value }) => {
    const newRuleGroup = {
      ...ruleGroup,
      [key]: value,
    };

    setRuleGroup(newRuleGroup);
    setUnsaved(true);
  };

  const handleDelayToggleChanged = e => {
    const value = e.target.checked;
    track('Rules Delay Trigger Toggled', { value });

    updateState({ key: 'delayTriggerEnabled', value });
  };

  const validateDelayInput = e => {
    const newDelay = +e.target.value;

    const charCode = e.which ? e.which : e.keyCode;
    if (charCode < ZERO_CHAR_CODE || charCode > NINE_CHAR_CODE) {
      return '';
    }

    if (newDelay > MAX_SECONDS_DELAY) {
      return '';
    }

    return newDelay;
  };

  const handleDelayChanged = e => {
    if (error) {
      clearInvalidRulesByType({ type: DELAY_TRIGGER_TYPE });
    }

    if (e.target.value === '') {
      updateState({ key: 'delayTriggerSeconds', value: e.target.value });
      return;
    }

    const newDelay = validateDelayInput(e);
    if (!newDelay) {
      return;
    }

    track('Rules Delay Trigger Seconds Updated', { value: newDelay });

    updateState({ key: 'delayTriggerSeconds', value: newDelay });
  };

  return (
    <LayoutRoot>
      <Tooltip placement="right" text={disabled ? tooltipText : ''}>
        <LayoutRoot>
          <IosSwitch
            checked={!!delayTriggerEnabled}
            onChange={handleDelayToggleChanged}
            disabled={disabled}
          />
          <TextSmall>Show after</TextSmall>
          <NumberInput
            disabled={!delayTriggerEnabled}
            type="number"
            min="1"
            max="30"
            step="1"
            onChange={handleDelayChanged}
            value={delayTriggerSeconds}
            error={error}
          />
          <TextSmall>seconds</TextSmall>
        </LayoutRoot>
      </Tooltip>
    </LayoutRoot>
  );
};

export default ShowAfter;

const LayoutRoot = styled(HorizontalFlexWrap)`
  gap: 10px;
  align-items: center;
`;
