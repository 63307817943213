import React from 'react';
import { LEFT, RIGHT } from './carouselSlider.constants';
import CarouselSliderArrow from './CarouselSliderArrow';

const CarouselSliderNavigation = React.forwardRef(
  ({ isVisible, onArrowClick, scrollToNext, scrollToPrevious }, arrowRefs) => {
    const handleArrowClick = direction => {
      if (direction === RIGHT) {
        scrollToNext();
      } else {
        scrollToPrevious();
      }

      onArrowClick?.();
    };

    const getCarouselArrow = direction => {
      return (
        <CarouselSliderArrow
          direction={direction}
          onClick={handleArrowClick}
          ref={ref => (arrowRefs.current[direction] = ref)}
        />
      );
    };

    if (!isVisible) {
      return null;
    }

    return (
      <>
        {getCarouselArrow(LEFT)}
        {getCarouselArrow(RIGHT)}
      </>
    );
  }
);

CarouselSliderNavigation.displayName = 'CarouselSliderNavigation';

export default CarouselSliderNavigation;
