import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import ComponentWithLoader from 'app/src/complex_components/ComponentWithLoader';
import { NO_VIDEOS_PROJECT_IMAGE_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

import DefaultProjectPlaceholderV2 from 'app/src/images/dashboard_v2/default_project_placeholder_v2.svg';

type Props = {
  imageSrc: string;
  videoStatus?: string;
  className?: string;
  shouldCover?: boolean;
  posterUrl?: string;
  isLoading?: boolean;
};

const CardImage = ({ imageSrc, className, shouldCover, isLoading = false }: Props) => {
  const [videoImage, setVideoImage] = useState<string>(imageSrc);
  const [isError, setIsError] = useState(false);

  const onImageError = () => {
    // @TODO transform DefaultProjectPlaceholderV2 to a React component
    setVideoImage(DefaultProjectPlaceholderV2 as unknown as string);
    setIsError(true);
  };

  useEffect(() => {
    setVideoImage(imageSrc);
  }, [imageSrc, isLoading]);

  return (
    <ComponentWithLoader
      isLoading={isLoading}
      renderLoader={indicator => <LoadingIndicatorContainer>{indicator}</LoadingIndicatorContainer>}
    >
      <StyledImage
        onError={onImageError}
        src={videoImage}
        className={className}
        data-test-id={isError ? NO_VIDEOS_PROJECT_IMAGE_TEST_ID : null}
        shouldCover={shouldCover || isError}
      />
    </ComponentWithLoader>
  );
};

const posAbsoluteStyles = css`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const LoadingIndicatorContainer = styled(HorizontalFlexCentered)`
  ${posAbsoluteStyles};
  background: ${({ theme }) => theme.colors.gray32};
`;

const StyledImage = styled.img<{ shouldCover: boolean }>`
  ${posAbsoluteStyles};
  object-fit: ${({ shouldCover }) => (shouldCover ? 'cover' : 'contain')};
  background: ${({ theme, shouldCover }) => (shouldCover ? '' : theme.colors.black)};
`;

export default CardImage;
