import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { EDIT_BUTTONS_TYPE_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import ExpanderBottomArrow from 'app/src/images/ExpanderBottomArrow';
import Button from 'shared/react/components/complex/Button';
import Types from 'shared/react/theme/Types';
import Sizes from 'shared/react/theme/Sizes';
import GoToNextAnswerIcon from 'app/src/images/GoToNextAnswerIcon';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import { TextSmall } from 'shared/react/components/complex/Text';
import { useQuizContext } from 'app/src/pages/project/pages/quiz/QuizContext';
import ResultTypeMenu from 'app/src/pages/project/pages/quiz/quiz_result/ResultTypeMenu';
import { useApps } from 'app/src/context/AppsStore';
import Utils from 'shared/react/utils/utils';

const LeadsTo = ({ isLoading, chosenType, setChosenType, quizResultId }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { onSetResultType } = useQuizContext();
  const theme = useTheme();
  const [{ shopify }] = useApps();

  if (!shopify) {
    return null;
  }

  const onChooseButtonsType = e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const getHeader = () => {
    return (
      <ButtonContainer>
        <Button
          disabled={!!isLoading}
          size={Sizes.Small}
          padding="15px 10px"
          type={Types.Primary}
          transparentBackground={true}
          onClick={onChooseButtonsType}
          borderColorType={() => 'transparent'}
          rightIcon={<ExpanderBottomArrow fill={theme.colors.white} />}
          showRightIcon={true}
          data-test-id={EDIT_BUTTONS_TYPE_TEST_ID}
        >
          {chosenType}
        </Button>
      </ButtonContainer>
    );
  };

  const handleSetResultType = type => {
    setChosenType(type);
    onSetResultType(quizResultId, type);
  };

  return (
    <MainContainer onClick={Utils.stopPropagation}>
      <LeftContainer>
        <TextSmall> Leads To </TextSmall>
      </LeftContainer>
      <RightContainer>
        <GoToNextAnswerIcon />
        {getHeader()}
        <ResultTypeMenu
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          chosenType={chosenType}
          setChosenType={handleSetResultType}
        />
      </RightContainer>
    </MainContainer>
  );
};

export default LeadsTo;

const MainContainer = styled(Gap8HorizontalFlexWrap)`
  min-height: 52px;
  padding: 0 16px;
  align-items: center;
  border: 2px solid transparent;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.gray5};
  flex-wrap: nowrap;

  &:last-child {
    border-radius: 0 0 16px 16px;
  }

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.primary};
  }
`;

const LeftContainer = styled(Gap8HorizontalFlexWrap)`
  flex-wrap: nowrap;
  align-items: center;
`;

const RightContainer = styled(LeftContainer)`
  justify-content: flex-end;
`;

const ButtonContainer = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 46px;
  overflow: hidden;

  & div:first-letter {
    text-transform: uppercase;
  }
`;
