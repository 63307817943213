import React from 'react';
import PricingBadge from 'app/src/complex_components/billings/badges/PricingBadge';

const PricingBanner = ({ text, analyticsData, ...props }) => {
  return (
    <PricingBadge
      padding="12px 16px"
      borderRadius="8px"
      subtitle="This is a premium feature, upgrade to keep it and enjoy other amazing features."
      text={text}
      shouldShowProIcon
      analyticsData={analyticsData}
      {...props}
    />
  );
};

export default PricingBanner;
