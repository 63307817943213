import colors from '../constants/colors';
import types from './Types';
import { getHexOpacity } from '../utils/color';
import breakpoints from './BreakPoints';
import { boxShadows } from './BoxShadows';

const GlobalTheme = {
  colors,
  getHexOpacity,
  types,
  breakpoints,
  boxShadows,
};

export default GlobalTheme;
