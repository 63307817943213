import * as React from 'react';

const TwitterIcon = ({ borderColor, ...props }) => (
  <svg
    width={49}
    height={48}
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M32.43 20.438c.78-.586 1.484-1.29 2.03-2.11a7.687 7.687 0 0 1-2.343.625 4.007 4.007 0 0 0 1.797-2.265c-.781.468-1.68.82-2.578 1.015a4.102 4.102 0 0 0-3.008-1.289 4.101 4.101 0 0 0-4.101 4.102c0 .312.039.625.117.937a11.9 11.9 0 0 1-8.477-4.297c-.351.586-.547 1.29-.547 2.07 0 1.407.703 2.657 1.836 3.399-.664-.04-1.328-.195-1.875-.508v.04a4.107 4.107 0 0 0 3.281 4.023c-.312.078-.703.156-1.054.156-.274 0-.508-.04-.781-.078.507 1.64 2.03 2.812 3.828 2.851a8.247 8.247 0 0 1-5.078 1.758c-.352 0-.665-.039-.977-.078 1.797 1.172 3.945 1.836 6.29 1.836 7.538 0 11.64-6.21 11.64-11.64v-.547Z"
      fill="#3D4FE0"
    />
    <path
      d="M24.5 47c-12.703 0-23-10.297-23-23h-2c0 13.807 11.193 25 25 25v-2Zm23-23c0 12.703-10.297 23-23 23v2c13.807 0 25-11.193 25-25h-2Zm-23-23c12.703 0 23 10.297 23 23h2c0-13.807-11.193-25-25-25v2Zm0-2C10.693-1-.5 10.193-.5 24h2c0-12.703 10.297-23 23-23v-2Z"
      fill={borderColor || '#fff'}
    />
  </svg>
);

export default TwitterIcon;
