import { EMBED_CAROUSEL_MOTION } from '../Carousel/carouselConstants.js';

export const PUBLIC_CLASSES = {
  story: 'tolstoy-stories',
  storiesContainer: 'tolstoy-stories-container',
  tilesContainer: 'tolstoy-stories-tiles-container',
  tileName: 'tolstoy-stories-tile-name',
  storyTile: 'tolstoy-stories-tile',
  storyImage: 'tolstoy-stories-story-image',
  storyVideo: 'tolstoy-stories-story-video',
  plusTile: 'tolstoy-stories-plus-tile',
  storyName: 'tolstoy-stories-story-name',
  titleContainer: 'tolstoy-stories-title',
  plusTileContainer: 'tolstoy-stories-plus-tile-container',
  previousButton: 'tolstoy-stories-previous-button',
  nextButton: 'tolstoy-stories-next-button'
};

export const POSTER_SUFFIX = '.0000000.jpg';
export const AVATAR_IMAGE_PREFIX = '.avatar';
export const STORIES_IMAGE_EXTENSION = `${AVATAR_IMAGE_PREFIX}${POSTER_SUFFIX}`;

export const EMBED_STORY_MOTION = {
  static: EMBED_CAROUSEL_MOTION.static,
  dynamic: EMBED_CAROUSEL_MOTION.dynamic
};

export const STORIES_ITEMS_SIZE_TYPES = {
  responsive: 'responsive',
  fixed: 'fixed'
};

export const STORIES_NAME_LOCATION = {
  below: 'below',
  overlay: 'overlay'
};

export const DEFAULT_STORIES_ITEMS_SIZE_TYPE = STORIES_ITEMS_SIZE_TYPES.fixed;
export const DEFAULT_STORIES_ITEMS_PER_ROW = 8;
export const DEFAULT_STORIES_SIZE = 80;
export const DEFAULT_STORIES_SPACING = 8;
export const STORIES_ITEM_MAX_FIXED_SIZE = 256;
