import React, { useState, FC } from 'react';
import styled from 'styled-components';
import { project as Project } from 'app/src/types/entities';
import InstallStepBase from 'app/src/pages/project/pages/installation/components/shared/InstallStepBase';
import { EmbedWidgetsShopifyInstall } from 'app/src/types/install';
import { TextSmall, TextTiny } from 'shared/react/components/basic/text/TextV2';
import ShopifyLogoIcon from 'app/src/images/creation_method_icons/ShopifyLogoIcon';
import StraightArrowRightIcon from 'app/src/images/creation_method_icons/StraightArrowRightIcon';
import { PrimaryButton } from 'shared/react/components/basic/button-v2/BasicButton';
import HorizontalFlex, {
  Gap8HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import ExpanderTopArrowIcon from 'app/src/images/creation_method_icons/ExpanderTopArrowIcon';
import Utils from 'app/src/utils';
import AddBlockManuallySteps from './AddBlockManuallySteps';
import { cleanStoreName } from 'app/src/utils/shopify.utils';

type AddToThemeStepProps = {
  project: Project;
  isDisabled: boolean;
};

const AddToThemeStep: FC<AddToThemeStepProps> = ({ project, isDisabled }) => {
  const [isToggleExpanded, setIsToggleExpanded] = useState(false);

  const { publishMethod, name, installStep, appUrl } = project ?? {};
  const stepTitle = `Add "${name}" ${publishMethod} to "${cleanStoreName(appUrl)}" theme`;
  const currentStepName = EmbedWidgetsShopifyInstall.addSection;

  const startExpanded = !installStep;

  const handleOpenDeepLink = async () => {
    const deepLink = isToggleExpanded
      ? Utils.getThemesDeepLink(appUrl)
      : Utils.getAddTolstoyBlockDeepLink(project);

    Utils.openInNewTab(deepLink);
  };

  return (
    <InstallStepBase
      isDisabled={isDisabled}
      stepNumber={1}
      stepHeader={stepTitle}
      startExpanded={startExpanded}
      currentStepName={currentStepName}
      project={project}
    >
      <TextSmallNeutralDark>
        {isToggleExpanded ? (
          'Click below to choose the Shopify theme'
        ) : (
          <>
            Click below to select location and add &quot;{name}&quot; {publishMethod} to your{' '}
            <b>active Shopify theme</b>.
          </>
        )}
      </TextSmallNeutralDark>
      <DeepLinkButtonContainer>
        <DeepLinkButton onClick={handleOpenDeepLink}>
          <ShopifyLogoIcon />
          {isToggleExpanded ? 'Go To Shopify' : `Add Tolstoy ${publishMethod} section to theme`}
          <StraightArrowRightIcon />
        </DeepLinkButton>
      </DeepLinkButtonContainer>

      <SpoilerToggle onClick={() => setIsToggleExpanded(!isToggleExpanded)}>
        <TextTiny>
          To add Tolstoy to <b>another theme</b>, Show manual steps
        </TextTiny>
        <ExpanderTopArrowIconWrapper $isExpanded={isToggleExpanded}>
          <ExpanderTopArrowIcon />
        </ExpanderTopArrowIconWrapper>
      </SpoilerToggle>

      {isToggleExpanded && <AddBlockManuallySteps project={project} />}
    </InstallStepBase>
  );
};

const TextSmallNeutralDark = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.neutralDark};
`;

const DeepLinkButtonContainer = styled(Gap8HorizontalFlex)`
  align-items: center;
`;

const DeepLinkButton = styled(PrimaryButton)`
  & svg {
    width: 14px;
    height: 14px;
  }

  & path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const SpoilerToggle = styled(HorizontalFlex)`
  align-items: center;
  cursor: pointer;

  & > div {
    color: ${({ theme }) => theme.colors.grapePurple};
  }
`;

const ExpanderTopArrowIconWrapper = styled.div<{ $isExpanded: boolean }>`
  margin-left: 8px;
  transform: rotate(${({ $isExpanded }) => ($isExpanded ? 0 : 180)}deg);

  & svg {
    width: 10px;
    height: 10px;
  }

  & path {
    fill: ${({ theme }) => theme.colors.grapePurple};
  }
`;

export default AddToThemeStep;
