import React, { useState } from 'react';
import styled from 'styled-components';
import { Trash2 } from 'lucide-react';

import {
  SUBTITLES_PART_X_DATA_TEST_ID,
  SUBTITLES_DELETE_CONTAINER_TEST_ID,
} from 'app/src/constants/dataTestIds.constants';

function isValidTimePart(string) {
  return string.length === 2 && /^[0-5][0-9]$/.test(string);
}

function isValidMilliseconds(string) {
  return string.length === 3 && /^[0-9]{3}$/.test(string);
}

function validateTime(value) {
  const [time, milliseconds] = value.split('.');
  if (!isValidMilliseconds(milliseconds)) {
    return false;
  }

  const [minutes, seconds] = time.split(':');
  return isValidTimePart(minutes) && isValidTimePart(seconds);
}

function parseTime(times) {
  const [from, to] = times.split(' --> ');
  return [from.replace('00:', ''), to.replace('00:', '')];
}

const SubtitlePart = ({ text, times, index, onPartChanged, onPartDeleted }) => {
  // times are in this format '00:00:00.000 --> 00:00:00.000'
  const [from, to] = parseTime(times);
  const [content, setContent] = useState(text.trim());
  const [fromTime, setFromTime] = useState(from);
  const [toTime, setToTime] = useState(to);

  const savePart = () => {
    onPartChanged(index, `\n${content}\n`, `00:${fromTime} --> 00:${toTime}`);
  };

  const handleFromTimeChanged = value => {
    if (!validateTime(value)) {
      setFromTime(from);
      return;
    }

    setFromTime(value);
    savePart();
  };

  const handleToTimeChanged = value => {
    if (!validateTime(value)) {
      setToTime(to);
      return;
    }

    setToTime(value);
    savePart();
  };

  return (
    <Container>
      <TextArea
        data-test-id={`${SUBTITLES_PART_X_DATA_TEST_ID}-${index}`}
        rows="2"
        maxLength="200"
        value={content}
        onChange={e => setContent(e.target.value)}
        onBlur={savePart}
      />
      <DeleteContainer
        // onMouseDown is used instead of onClick because click is blocked by TextArea's blur event
        // https://stackoverflow.com/questions/9335325/blur-event-stops-click-event-from-working
        onMouseDown={() => onPartDeleted(index)}
        data-test-id={SUBTITLES_DELETE_CONTAINER_TEST_ID}
      >
        <Trash2 width="16" height="16" />
      </DeleteContainer>
      <TimesSection>
        <TimeInput
          isTop={true}
          value={fromTime}
          onChange={e => setFromTime(e.target.value)}
          onBlur={e => handleFromTimeChanged(e.target.value)}
        />
        <TimeInput
          isTop={false}
          value={toTime}
          onChange={e => setToTime(e.target.value)}
          onBlur={e => handleToTimeChanged(e.target.value)}
        />
      </TimesSection>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  display: flex;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    padding: 16px;
  }
`;

const TextArea = styled.textarea`
  width: 80%;
  padding: 15px;
  resize: none;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.colors.neutralLighter}`};
  && {
    &:focus {
      outline-color: ${({ theme }) => theme.colors.blue6};
    }
  }
`;

const TimesSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 20%;
`;

const TimeInput = styled.input`
  height: 44px;
  padding: 10px;
  width: 100%;
  border-radius: 8px;
  text-align: center;
  margin: ${({ isTop }) => (isTop ? '0 0 5px 0' : '5px 0 0 0')};
  border: ${({ theme }) => `1px solid ${theme.colors.neutralLighter}`};

  && {
    &:focus {
      outline-color: ${({ theme }) => theme.colors.blue6};
    }
  }
`;

const DeleteContainer = styled.div`
  cursor: pointer;
`;

export default SubtitlePart;
