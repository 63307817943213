import React from 'react';
import { useAnalytics } from 'app/src/context/AnalyticsStore';
import { track } from 'app/src/helpers/Tracker';
import UniqueIcon from 'app/src/images/UniqueIcon';
import AnalyticsToggleFilter from './AnalyticsToggleFilter';

const AnalyticsUniqueFilter = ({ showFilter }) => {
  const [{ unique }, { setUnique }] = useAnalytics();

  const onChange = () => {
    track('Analytics Unique Changed', { unique: !unique });
    setUnique(!unique);
  };

  if (!showFilter) {
    return null;
  }

  return (
    <AnalyticsToggleFilter
      text={unique ? 'Unique' : 'All'}
      onClick={onChange}
      Icon={UniqueIcon}
      isSelected={unique}
    />
  );
};

export default AnalyticsUniqueFilter;
