import { FEATURE_DYNAMIC_FEED } from 'app/src/constants/appFeatures.constants';
import {
  createVodConnection,
  deleteVodConnection,
  updateVodConnection,
} from 'app/src/graphql/mutations';
import { vodConnectionByAppKey, vodConnectionByAppKeySortByType } from 'app/src/graphql/queries';
import {
  onCreateVodConnectionByAppKey,
  onDeleteVodConnectionByAppKey,
  onUpdateVodConnectionByAppKey,
} from 'app/src/graphql/subscriptions';
import { graphqlRequest } from 'app/src/helpers/API';
import Utils from 'app/src/utils';
import {
  getDistinctNumberOfProducts,
  getLabelsByVodAssetId,
  getNumberOfProductVideos,
  getProductConnections,
  getProductsAndVariantsByVodAssetMap,
  getProjectIdByVodAssetIdMap,
  getTagsByVodAssetId,
  getVodAssetIdsByLabelId,
  getVodConnectionByProductId,
  getVodConnectionByTag,
  getVodConnectionByVodAssetId,
  getVodConnectionByVodLabelId,
  getVodConnectionTags,
  getVodConnectionTypeKey,
} from 'app/src/utils/vodConnections.utils';
import { API, graphqlOperation } from 'aws-amplify';
import { createActionsHook, createContainer, createHook, createStore } from 'react-sweet-state';
import { FEATURE_APP_LOAD_CONNECTIONS_WITH_PAGINATION } from 'shared/react/constants/features.constants';
import { VOD_CONNECTION_TYPES } from 'shared/react/constants/vodConnection.constants';

const initialState = {
  initialized: false,
  vodConnections: [],
  productsByVodAssetMap: {},
  vodAssetIdsByExternalProductId: {},
  variantsByVodAssetMap: {},
  tagsByVodAssetMap: {},
  labelsByVodAssetMap: {},
  vodAssetIdsByLabelId: {},
  projectIdsByVodAssetIdMap: {},
  vodConnectionByVodAssetId: {},
  numberOfProductVideos: 0,
  numberOfProducts: 0,
  selectedAppUrl: '',
  tags: [],
  isConnectionsWithPagination: false,
};

const v2Actions = {
  getNumberOfProductsByVodAssetId: (vodAsset, appUrl) => {
    let number = 0;
    vodAsset?.vodConnections?.items.forEach(({ appUrl: connectionAppUrl, type, variantIds }) => {
      if (appUrl !== connectionAppUrl || type !== VOD_CONNECTION_TYPES.productId) {
        return;
      }

      if (variantIds?.length) {
        number += variantIds.length;
        return;
      }

      number++;
    });

    return number;
  },
};

const actions = {
  fetchTags:
    () =>
    async ({ setState, dispatch }, { appKey }) => {
      try {
        const vodConnections = [];
        let nextToken = null;

        do {
          const response = await graphqlRequest(
            graphqlOperation(vodConnectionByAppKeySortByType, {
              appKey,
              limit: 500,
              nextToken,
              type: {
                eq: 'tag',
              },
            })
          );

          vodConnections.push(...response.data.vodConnectionByAppKey.items);
          nextToken = response.data.vodConnectionByAppKey.nextToken;
        } while (nextToken);

        setState({ tags: vodConnections.flat(), vodConnections: vodConnections.flat() });
        dispatch(actions.setConnectionsData());
      } catch (error) {
        Utils.logError('Failed to fetch VodConnections', error);
      }
    },
  fetch:
    () =>
    async ({ setState, dispatch }, { appKey }) => {
      try {
        const vodConnections = [];
        let nextToken = null;

        do {
          const response = await graphqlRequest(
            graphqlOperation(vodConnectionByAppKey, {
              appKey,
              limit: 500,
              nextToken,
            })
          );

          vodConnections.push(...response.data.vodConnectionByAppKey.items);
          nextToken = response.data.vodConnectionByAppKey.nextToken;
        } while (nextToken);

        const selectedAppUrl = vodConnections[0]?.appUrl || '';

        const sortedConnections = vodConnections.flat().sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });

        setState({ vodConnections: sortedConnections, selectedAppUrl, initialized: true });

        dispatch(actions.setConnectionsData());
      } catch (error) {
        Utils.logError('Failed to fetch VodConnections', error);
      }
    },
  setConnectionsData:
    () =>
    ({ getState, setState }) => {
      const { vodConnections, selectedAppUrl } = getState();
      const { productsByVodAssetMap, variantsByVodAssetMap, vodAssetIdsByExternalProductId } =
        getProductsAndVariantsByVodAssetMap(vodConnections, selectedAppUrl);

      const tagsByVodAssetMap = getTagsByVodAssetId(vodConnections);

      const labelsByVodAssetMap = getLabelsByVodAssetId(vodConnections);

      const vodAssetIdsByLabelId = getVodAssetIdsByLabelId(vodConnections);

      const numberOfProductVideos = getNumberOfProductVideos(productsByVodAssetMap);

      const productConnections = getProductConnections(vodConnections, selectedAppUrl);

      const numberOfProducts = getDistinctNumberOfProducts(productConnections);

      const vodConnectionByVodAssetId = getVodConnectionByVodAssetId(vodConnections);

      const projectIdsByVodAssetIdMap = getProjectIdByVodAssetIdMap(vodConnections);

      const tags = getVodConnectionTags(vodConnections);

      setState({
        productsByVodAssetMap,
        variantsByVodAssetMap,
        vodAssetIdsByExternalProductId,
        numberOfProductVideos,
        numberOfProducts,
        productConnections,
        tagsByVodAssetMap,
        labelsByVodAssetMap,
        projectIdsByVodAssetIdMap,
        vodConnectionByVodAssetId,
        vodAssetIdsByLabelId,
        tags: [...tags],
      });
    },
  // Don't use this action unless necessary, use useCreateVodConnection instead
  createVodConnection:
    ({
      productId,
      externalProductId,
      type,
      provider,
      vodAssetId,
      appUrl,
      tag,
      vodLabelId,
      variantIds,
      projectId,
      videoConnections,
      orderIndex,
    }) =>
    async ({ getState, setState, dispatch }, { appKey }) => {
      if (
        dispatch(
          actions.getIsConnectionAlreadyExists({
            productId,
            vodAssetId,
            vodLabelId,
            tag,
            appUrl,
            projectId,
            videoConnections,
          })
        )
      ) {
        return;
      }

      const typeKey = getVodConnectionTypeKey({
        appKey,
        id: externalProductId || tag || vodLabelId || projectId,
      });

      const input = {
        appKey,
        typeKey,
        productId,
        externalProductId,
        type,
        provider,
        vodAssetId,
        appUrl,
        tag,
        vodLabelId,
        variantIds,
        projectId,
        orderIndex,
      };

      const response = await graphqlRequest(graphqlOperation(createVodConnection, { input }));

      const newVodConnection = response.data.createVodConnection;

      const { vodConnections } = getState();
      setState({ vodConnections: [...vodConnections, newVodConnection] });

      dispatch(actions.setConnectionsData());
    },
  updateVodConnectionVariants:
    ({ productId, vodAssetId, variantIds, orderIndex }) =>
    async ({ getState, setState, dispatch }) => {
      const { vodConnections } = getState();

      const productConnection = getVodConnectionByProductId({
        vodConnections,
        vodAssetId,
        externalProductId: productId,
      });

      const input = {
        id: productConnection.id,
        variantIds,
        orderIndex,
      };
      const res = await graphqlRequest(graphqlOperation(updateVodConnection, { input }));

      const newConnection = res.data.updateVodConnection;
      const { vodConnections: newVodConnections } = getState();

      const updatedConnections = newVodConnections.map(connection => {
        if (connection.id !== newConnection.id) {
          return connection;
        }

        if (connection.vodAssetId === vodAssetId && connection.externalProductId === productId) {
          return { ...newConnection, variantIds };
        }

        return newConnection;
      });

      setState({ vodConnections: updatedConnections });

      dispatch(actions.setConnectionsData());
    },
  deleteVodConnection:
    id =>
    async ({ getState, setState, dispatch }) => {
      await graphqlRequest(graphqlOperation(deleteVodConnection, { input: { id } }));

      const { vodConnections } = getState();

      const newConnections = vodConnections.filter(connection => connection.id !== id);

      setState({ vodConnections: newConnections });

      dispatch(actions.setConnectionsData());
    },
  getProductsByVodAssetId:
    (vodAssetId, appUrl) =>
    ({ getState }) => {
      const { productsByVodAssetMap, selectedAppUrl } = getState();

      const vodAssetProductIdsByStore = productsByVodAssetMap[vodAssetId] || {};

      const vodAssetAppUrl = appUrl || selectedAppUrl;

      return vodAssetAppUrl
        ? vodAssetProductIdsByStore?.[vodAssetAppUrl] || []
        : Object.values(vodAssetProductIdsByStore).flatMap(storeProducts => storeProducts);
    },
  getProductsByVodAssetIds:
    (vodAssetIds, appUrl) =>
    ({ dispatch, getState }) => {
      const { selectedAppUrl } = getState();
      const vodAssetAppUrl = appUrl || selectedAppUrl;

      const productIds = vodAssetIds.reduce((acc, vodAssetId) => {
        return [...acc, ...dispatch(actions.getProductsByVodAssetId(vodAssetId, vodAssetAppUrl))];
      }, []);
      return [...new Set(productIds)];
    },
  getNumberOfProductsByVodAssetId:
    (video, appUrl) =>
    ({ getState }, { getFeatureEnabled }) => {
      const { variantsByVodAssetMap, selectedAppUrl } = getState();
      appUrl = appUrl || selectedAppUrl;

      if (getFeatureEnabled(FEATURE_APP_LOAD_CONNECTIONS_WITH_PAGINATION)) {
        return v2Actions.getNumberOfProductsByVodAssetId(video, appUrl);
      }

      const variantsByProducts = variantsByVodAssetMap?.[video.id]?.[appUrl] || {};
      const number = Object.values(variantsByProducts).reduce(
        (acc, variantIds) => acc + (variantIds.length || 1),
        0
      );

      return number || 0;
    },
  deleteConnectionByProductId:
    ({ vodAssetId, externalProductId }) =>
    async ({ getState, dispatch }) => {
      const { vodConnections } = getState();

      const connection = getVodConnectionByProductId({
        vodConnections,
        vodAssetId,
        externalProductId,
      });

      return dispatch(actions.deleteVodConnection(connection.id));
    },
  deleteConnectionByTag:
    ({ tag, vodAssetId, appUrl }) =>
    async ({ dispatch, getState }) => {
      const { vodConnections } = getState();
      const connection = getVodConnectionByTag({ vodConnections, vodAssetId, tag, appUrl });
      return dispatch(actions.deleteVodConnection(connection.id));
    },
  deleteConnectionByVodLabel:
    ({ vodLabelId, vodAssetId }) =>
    async ({ dispatch, getState }) => {
      const { vodConnections } = getState();
      const connection = getVodConnectionByVodLabelId({ vodConnections, vodAssetId, vodLabelId });
      return dispatch(actions.deleteVodConnection(connection.id));
    },
  setSelectedAppUrl:
    selectedAppUrl =>
    ({ setState, dispatch }) => {
      setState({ selectedAppUrl });
      dispatch(actions.setConnectionsData());
    },
  clearStore:
    () =>
    ({ setState }) => {
      setState({ ...initialState });
    },

  getIsConnectionAlreadyExists:
    ({ vodAssetId, appUrl, productId, tag, vodLabelId, projectId, videoConnections }) =>
    ({ dispatch }) => {
      if (
        dispatch(
          actions.getIsProductConnectionAlreadyExists({
            productId,
            vodAssetId,
            appUrl,
            videoConnections,
          })
        )
      ) {
        return true;
      }

      if (
        dispatch(
          actions.getIsTagConnectionAlreadyExists({ vodAssetId, tag, appUrl, videoConnections })
        )
      ) {
        return true;
      }

      if (
        dispatch(
          actions.getIsVodLabelConnectionAlreadyExists({ vodAssetId, vodLabelId, videoConnections })
        )
      ) {
        return true;
      }

      if (
        dispatch(
          actions.getIsProjectIdConnectionAlreadyExists({ vodAssetId, projectId, videoConnections })
        )
      ) {
        return true;
      }

      return false;
    },
  getIsProductConnectionAlreadyExists:
    ({ productId, vodAssetId, appUrl, videoConnections = [] }) =>
    ({ getState }) => {
      const { productsByVodAssetMap, isConnectionsWithPagination } = getState();
      if (isConnectionsWithPagination) {
        return !!videoConnections?.find(
          connection => connection.appUrl === appUrl && productId === connection.productId
        );
      }

      return productsByVodAssetMap[vodAssetId]?.[appUrl]?.includes(productId);
    },
  getIsTagConnectionAlreadyExists:
    ({ tag, vodAssetId, appUrl, videoConnections = [] }) =>
    ({ getState }) => {
      const { tagsByVodAssetMap, isConnectionsWithPagination } = getState();
      if (isConnectionsWithPagination) {
        return !!videoConnections.find(
          connection => connection.appUrl === appUrl && tag === connection.tag
        );
      }

      return tagsByVodAssetMap[vodAssetId]?.[appUrl]?.includes(tag);
    },
  getIsVodLabelConnectionAlreadyExists:
    ({ vodLabelId, vodAssetId, videoConnections = [] }) =>
    ({ getState }) => {
      const { labelsByVodAssetMap, isConnectionsWithPagination } = getState();
      if (isConnectionsWithPagination) {
        return !!videoConnections.find(connection => vodLabelId === connection.vodLabelId);
      }

      return labelsByVodAssetMap[vodAssetId]?.includes(vodLabelId);
    },
  getIsProjectIdConnectionAlreadyExists:
    ({ vodAssetId, projectId, videoConnections = [] }) =>
    ({ getState }) => {
      const { projectIdsByVodAssetIdMap, isConnectionsWithPagination } = getState();
      if (isConnectionsWithPagination) {
        return !!videoConnections.find(connection => projectId === connection.projectId);
      }

      return projectIdsByVodAssetIdMap[vodAssetId]?.some(
        connection => connection.projectId === projectId
      );
    },
  getHasVodConnectionsTypesByVodAssetId:
    (vodAsset, types) =>
    ({ getState }) => {
      const { vodConnectionByVodAssetId, selectedAppUrl } = getState();
      return !!vodConnectionByVodAssetId[vodAsset.id]?.find(({ type, appUrl }) => {
        if (type === VOD_CONNECTION_TYPES.vodLabelId) {
          return types.includes(type);
        }

        const isMatchingAppUrl = !selectedAppUrl || appUrl === selectedAppUrl;
        return isMatchingAppUrl && types.includes(type);
      });
    },
  getHasVodLabelByVodAssetId:
    (vodAsset, args) =>
    ({ getState }) => {
      const { vodConnectionByVodAssetId } = getState();
      return !!vodConnectionByVodAssetId[vodAsset.id]?.find(({ vodLabelId }) =>
        args?.includes?.(vodLabelId)
      );
    },
  getHasTagsByVodAssetId:
    (vodAsset, args) =>
    ({ getState }) => {
      const { vodConnectionByVodAssetId, selectedAppUrl } = getState();
      return !!vodConnectionByVodAssetId[vodAsset.id]?.find(({ tag, appUrl }) => {
        const isMatchingAppUrl = !selectedAppUrl || appUrl === selectedAppUrl;
        return isMatchingAppUrl && args?.includes?.(tag);
      });
    },
  getHasProductByVodAssetId:
    (vodAsset, args) =>
    ({ getState }) => {
      const { vodConnectionByVodAssetId, selectedAppUrl } = getState();
      return !!vodConnectionByVodAssetId[vodAsset.id]?.find(({ externalProductId, appUrl }) => {
        const isMatchingAppUrl = !selectedAppUrl || appUrl === selectedAppUrl;
        return isMatchingAppUrl && args?.includes?.(externalProductId);
      });
    },
  getVodConnectionByProductId:
    externalProductId =>
    ({ getState }) => {
      const { vodConnections } = getState();

      return vodConnections.filter(
        connection => connection.externalProductId === externalProductId
      );
    },
  subscribeCreateVodConnection:
    () =>
    ({ setState, getState, dispatch }, { appKey, addVodConnectionToVodAsset }) => {
      try {
        const subscription = API.graphql(
          graphqlOperation(onCreateVodConnectionByAppKey, { appKey })
        ).subscribe({
          next: data => {
            const { vodConnections, isConnectionsWithPagination } = getState();
            const newConnection = data?.value?.data?.onCreateVodConnectionByAppKey;

            if (isConnectionsWithPagination) {
              addVodConnectionToVodAsset(newConnection);
              return;
            }

            const existingConnection = vodConnections.find(
              connection => connection.id === newConnection.id
            );

            if (existingConnection) {
              return;
            }

            setState({ vodConnections: [...vodConnections, newConnection] });

            dispatch(actions.setConnectionsData());
          },
          error: ({ error: { errors = [] } = {} }) => {
            Utils.logErrorMessage(errors[0]?.message || 'Error in onCreateVodConnection subscribe');
          },
        });

        setState({ createSubscription: subscription });
      } catch (error) {
        Utils.logError('Cannot subscribe to create vodConnections', error);
      }
    },
  subscribeUpdateVodConnection:
    () =>
    ({ setState, getState, dispatch }, { appKey, updateVodConnectionToVodAsset }) => {
      try {
        const subscription = API.graphql(
          graphqlOperation(onUpdateVodConnectionByAppKey, { appKey })
        ).subscribe({
          next: data => {
            const { vodConnections, isConnectionsWithPagination } = getState();
            const updatedConnection = data?.value?.data?.onUpdateVodConnectionByAppKey;

            if (isConnectionsWithPagination) {
              updateVodConnectionToVodAsset(updatedConnection);
              return;
            }

            const updatedConnections = vodConnections.map(connection => {
              if (connection.id === updatedConnection.id) {
                return updatedConnection;
              }

              return connection;
            });

            setState({ vodConnections: updatedConnections });

            dispatch(actions.setConnectionsData());
          },
          error: ({ error: { errors = [] } = {} }) => {
            Utils.logErrorMessage(errors[0]?.message || 'Error in onUpdateVodConnection subscribe');
          },
        });

        setState({ updateSubscription: subscription });
      } catch (error) {
        Utils.logError('Cannot subscribe to update vodConnections', error);
      }
    },
  subscribeDeleteVodConnection:
    () =>
    ({ setState, getState, dispatch }, { appKey, removeVodConnectionToVodAsset }) => {
      try {
        const subscription = API.graphql(
          graphqlOperation(onDeleteVodConnectionByAppKey, { appKey })
        ).subscribe({
          next: data => {
            const { vodConnections, isConnectionsWithPagination } = getState();
            const deletedConnection = data?.value?.data?.onDeleteVodConnectionByAppKey;

            if (isConnectionsWithPagination) {
              removeVodConnectionToVodAsset(deletedConnection);
              return;
            }

            const newVodConnections = vodConnections.filter(
              connection => connection.id !== deletedConnection.id
            );

            if (vodConnections.length === newVodConnections.length) {
              return;
            }

            setState({ vodConnections: newVodConnections });

            dispatch(actions.setConnectionsData());
          },
          error: ({ error: { errors = [] } = {} }) => {
            Utils.logErrorMessage(errors[0]?.message || 'Error in onDeleteVodConnection subscribe');
          },
        });

        setState({ deleteSubscription: subscription });
      } catch (error) {
        Utils.logError('Cannot subscribe to delete vodConnections', error);
      }
    },
  unsubscribeCreateConnection:
    () =>
    ({ getState, setState }) => {
      getState().createSubscription?.unsubscribe();

      setState({ createSubscription: null });
    },
  unsubscribeDeleteConnection:
    () =>
    ({ getState, setState }) => {
      getState().deleteSubscription?.unsubscribe();

      setState({ deleteSubscription: null });
    },
  unsubscribeUpdateConnection:
    () =>
    ({ getState, setState }) => {
      getState().updateSubscription?.unsubscribe();

      setState({ updateSubscription: null });
    },
};

const VodConnectionStore = createStore({
  initialState,
  actions,
  name: 'VodConnectionStore',
});

const vodConnectionsSelector = (state, args) => {
  const vodConnectionProductIds =
    state.productsByVodAssetMap[args?.vodAssetId]?.[state.selectedAppUrl] || [];

  const vodConnectionVariantsByProductIds =
    state.variantsByVodAssetMap[args?.vodAssetId]?.[state.selectedAppUrl] || {};

  return {
    ...state,
    vodConnectionProductIds,
    vodConnectionVariantsByProductIds,
  };
};

const vodConnectionByProductIdSelector = (state, args) => {
  const vodConnectionsByProductId = state.vodConnections.filter(
    connection => connection.externalProductId === args?.productId
  );

  return vodConnectionsByProductId;
};

export const useVodConnectionByProductId = createHook(VodConnectionStore, {
  selector: vodConnectionByProductIdSelector,
});

const vodConnectionTagNamesSelector = (state, vodAssetId) => {
  const vodConnectionProductIds = state.tagsByVodAssetMap[vodAssetId]?.[state.selectedAppUrl] || [];

  return vodConnectionProductIds;
};

const projectIdsByVodAssetIdMapSelector = state => state.projectIdsByVodAssetIdMap;
const vodAssetIdsByExternalProductIdMapSelector = state => state.vodAssetIdsByExternalProductId;
const vodAssetIdsByLabelIdSelector = state => state.vodAssetIdsByLabelId;

export const useVodConnectionByTagNames = createHook(VodConnectionStore, {
  selector: vodConnectionTagNamesSelector,
});

export const useVodAssetIdsByLabelId = createHook(VodConnectionStore, {
  selector: vodAssetIdsByLabelIdSelector,
});

const useVodConnectionLabelsByVodSelector = (state, vod) => {
  if (state.isConnectionsWithPagination) {
    return vod.vodConnections?.items
      ?.filter(connection => connection.type === VOD_CONNECTION_TYPES.vodLabelId)
      .map(({ vodLabelId }) => vodLabelId);
  }

  const vodConnectionsByTag = state.labelsByVodAssetMap[vod.id];

  return vodConnectionsByTag;
};

export const useVodConnectionLabelsByVod = createHook(VodConnectionStore, {
  selector: useVodConnectionLabelsByVodSelector,
});

export const useProjectIdsByVodAssetIdMap = createHook(VodConnectionStore, {
  selector: projectIdsByVodAssetIdMapSelector,
});

export const useVodAssetIdsByExternalProductIdMap = createHook(VodConnectionStore, {
  selector: vodAssetIdsByExternalProductIdMapSelector,
});

const vodConnectionByTagsSelector = (state, appUrl) => {
  const vodConnectionsByTag = state.vodConnections.filter(
    vodConnection => vodConnection.appUrl === appUrl && vodConnection.type === 'tag'
  );

  return vodConnectionsByTag;
};

const vodConnectionsListSelector = state => state.vodConnections;

export const useVodConnectionByTag = createHook(VodConnectionStore, {
  selector: vodConnectionByTagsSelector,
});

export const useVodConnection = createHook(VodConnectionStore, {
  selector: vodConnectionsSelector,
});

export const useVodConnectionsList = createHook(VodConnectionStore, {
  selector: vodConnectionsListSelector,
});

export const useVodConnectionActions = createActionsHook(VodConnectionStore);

export const VodConnectionContainer = createContainer(VodConnectionStore, {
  onInit:
    () =>
    ({ dispatch, setState }, { features, getFeatureEnabled }) => {
      if (!features[FEATURE_DYNAMIC_FEED]?.enabled) {
        return;
      }

      const isConnectionsWithPagination = getFeatureEnabled(
        FEATURE_APP_LOAD_CONNECTIONS_WITH_PAGINATION
      );

      setState({ isConnectionsWithPagination });

      // Daniil infrastructure
      // if (isConnectionsWithPagination) {
      //   dispatch(actions.fetchTags());
      // } else {
      dispatch(actions.fetch());
      // }

      if (!window.Cypress) {
        dispatch(actions.subscribeCreateVodConnection());
        dispatch(actions.subscribeDeleteVodConnection());
        dispatch(actions.subscribeUpdateVodConnection());
      }
    },
  onCleanup:
    () =>
    ({ dispatch }) => {
      dispatch(actions.unsubscribeCreateConnection());
      dispatch(actions.unsubscribeDeleteConnection());
      dispatch(actions.unsubscribeUpdateConnection());
      dispatch(actions.clearStore());
    },
});
