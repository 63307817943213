import React from 'react';
import styled, { useTheme } from 'styled-components';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { FIELD_KEYS } from 'app/src/pages/modals/hubspot_mapping/hubspot_table/hubspotTable.constants';
import { TextSmall } from 'shared/react/components/complex/Text';
import { useHubspot } from 'app/src/context/integrations/HubspotStore';
import Utils from 'app/src/utils';
import { getAnswerImage } from 'app/src/images/getAnswerImage';
import { LEAD_FORM_RESPONSE_KEY } from 'app/src/constants/tolstoy.constants';
import CursorIcon from 'app/src/images/CursorIcon';
import IosSwitch from 'app/src/basic_components/IosSwitch';
import DeleteIcon from 'app/src/images/DeleteIcon';
import FilledVideoIcon from 'app/src/images/video_icon/FilledVideoIcon';

const TOLSTOY_FIELD_KEY = 'tolstoyField';

const HubspotField = ({ field, projectFields, onRemoveField, onToggleDefault }) => {
  const [{ properties }] = useHubspot();
  const theme = useTheme();

  const getIcon = key => {
    if (key !== TOLSTOY_FIELD_KEY) {
      return;
    }

    if (field.tolstoyField.step === 'contactForm') {
      return getAnswerImage({ type: LEAD_FORM_RESPONSE_KEY });
    }

    if (field.tolstoyField.key === 'Video') {
      return <FilledVideoIcon />;
    }

    return getAnswerImage({ type: field.tolstoyField.key, defaultIcon: <CursorIcon /> });
  };

  const removeField = () => {
    onRemoveField(field.hubspotField);
  };

  const onToggleEnabled = () => {
    onToggleDefault(field.hubspotField);
  };

  const getFieldText = (value, key) => {
    if (field.mappingType === 'Default') {
      if (key === 'enabled') {
        return <IosSwitch checked={field.enabled} onChange={onToggleEnabled} />;
      }

      if (key === TOLSTOY_FIELD_KEY) {
        return `${Utils.capitalizeFirstLetter(field.tolstoyField.key)} - Contact form`;
      }
    }

    if (key === 'enabled') {
      return (
        <TrashIconContainer onClick={removeField}>
          <DeleteIcon fill={theme.colors.danger} />
        </TrashIconContainer>
      );
    }

    const projectField = projectFields.find(field => {
      if (field?.step === value?.step && field.id === value.key) {
        return field;
      }
    });

    if (properties[value]) {
      return properties[value].label;
    }

    if (projectField) {
      return projectField.name;
    }

    return value;
  };

  return (
    <LayoutRoot>
      {FIELD_KEYS.map(({ key, width, defaultText }) => {
        const fieldValue = getFieldText(field[key] || defaultText, key);
        const icon = getIcon(key);

        return (
          <Field width={width} shouldColor={key === TOLSTOY_FIELD_KEY} key={key}>
            {icon}
            {fieldValue}
          </Field>
        );
      })}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)``;

const Field = styled(TextSmall)`
  border-right: 1px solid ${({ theme }) => theme.colors.gray3};
  border-top: 1px solid ${({ theme }) => theme.colors.gray3};
  padding: 16px;
  min-width: ${({ width }) => width}px;
  max-width: ${({ width }) => width}px;
  gap: 8px;
  display: flex;
  align-items: center;

  & svg {
    width: ${({ shouldColor }) => shouldColor && '12px'};
  }

  & path {
    fill: ${({ theme, shouldColor }) => shouldColor && theme.colors.danger};
  }

  &:last-of-type {
    border-right: none;
  }
`;

const TrashIconContainer = styled.div`
  cursor: pointer;
`;

export default HubspotField;
