import OverlayButton from "./OverlayButton";
import { FC } from "preact/compat";
import { Button } from "../types/config";

type Props = {
  shouldStackOnMobile: boolean;
  primaryButton: Button;
  secondaryButton: Button;
  buttonSpacing: number;
  onSendEvent: (eventName: string) => void;
  isMobile: boolean;
  alignment: string;
};

const OverlayButtons: FC<Props> = ({
  primaryButton,
  secondaryButton,
  shouldStackOnMobile,
  buttonSpacing,
  onSendEvent,
  isMobile,
  alignment,
}) => {
  if (!primaryButton.enabled && !secondaryButton.enabled) {
    return null;
  }

  const style = {
    flexDirection: "row",
    gap: `${buttonSpacing}px`,
    justifyContent: alignment,
  };

  if (shouldStackOnMobile && isMobile) {
    style.flexDirection = "column";
  }

  return (
    <div style={style} className="hero-button-container">
      <OverlayButton
        {...primaryButton}
        isMobile={isMobile}
        onSendEvent={onSendEvent}
      />
      <OverlayButton
        {...secondaryButton}
        isMobile={isMobile}
        onSendEvent={onSendEvent}
      />
    </div>
  );
};

export default OverlayButtons;
