import React from 'react';
import styled from 'styled-components';

import CardImage from 'app/src/pages/dashboard/components/card/CardImage';
import { useVideoActions } from 'app/src/context/VideosStore';
import PlayIcon from 'app/src/images/dashboard_v2/PlayIcon';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import useDynamicVideoIdsByProject from 'app/src/hooks/use-dynamic-video-ids-by-project/useDynamicVideoIdsByProject';
import { useRuleGroupByProjectId } from 'app/src/context/RulesStore';
import { publishMethodOptions } from 'app/src/types/entities';
import { AssetRenditionsService } from 'shared/react/services/assets';

const getPaddedSteps = (videoIds: string[], length: number) =>
  Array.from({ length }, (_, i) => videoIds?.[i]);

type ImagePartsProps = {
  videoIds: string[];
  renderPoster: (videoId: string) => React.ReactNode;
  count?: number;
  rounded?: boolean;
};

const ImageParts = ({ videoIds, renderPoster, count = 1, rounded = false }: ImagePartsProps) => (
  <>
    {getPaddedSteps(videoIds, count).map((videoId, i) => (
      <FullSizeImage rounded={rounded} key={`${videoId}-${i}`}>
        {renderPoster(videoId)}
      </FullSizeImage>
    ))}
  </>
);

type CardImageProps = {
  publishMethod: string;
  videoIds: string[];
  renderPoster: (videoId: string) => React.ReactNode;
};

const CardImageByType = ({ publishMethod, ...props }: CardImageProps) => {
  switch (publishMethod) {
    case publishMethodOptions.carousel:
      return (
        <CarouselLayout>
          <ImageParts {...props} count={3} />
        </CarouselLayout>
      );
    case publishMethodOptions.stories:
      return (
        <StoriesLayout>
          <ImageParts {...props} count={4} rounded />
        </StoriesLayout>
      );
    case publishMethodOptions.bubble:
      return (
        <WidgetLayout>
          <ImageParts {...props} rounded />
        </WidgetLayout>
      );
    case publishMethodOptions.embed:
      return (
        <EmbedLayout>
          <ImageParts {...props} />
        </EmbedLayout>
      );
    case publishMethodOptions.videoPage:
    case publishMethodOptions.tvChannel:
      return (
        <TvPageLayout>
          <ImageParts {...props} />
        </TvPageLayout>
      );
    default:
      return (
        <EmbedLayout>
          <ImageParts {...props} />
        </EmbedLayout>
      );
  }
};

const OnsiteCardImage = ({ project }) => {
  const { getVideoById } = useVideoActions();
  const [ruleGroup] = useRuleGroupByProjectId(project.id);
  const { videoIds: dynamicVideoIds } = useDynamicVideoIdsByProject(
    project,
    ruleGroup,
    project.dynamic
  );

  const renderPoster = (videoId: string) => {
    if (videoId) {
      const poster = AssetRenditionsService.getAssetPoster(getVideoById(videoId));

      return <Image imageSrc={poster} />;
    }

    return <PlayIcon />;
  };

  const videoIds = project.dynamic
    ? dynamicVideoIds
    : project.steps?.items?.map(({ videoId }) => videoId);

  return (
    <CardImageByType
      publishMethod={project.publishMethod}
      videoIds={videoIds}
      renderPoster={renderPoster}
    />
  );
};

const Image = styled(CardImage)`
  object-fit: cover;
`;

const FullSizeImage = styled(HorizontalFlex)`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ rounded }) => (rounded ? '50%' : '8px')};
  align-items: center;
  justify-content: center;
`;

const Grid = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  gap: 8px;
  padding: 12px;
  overflow: hidden;
`;

const CarouselLayout = styled(Grid)`
  grid-template-columns: repeat(3, 80px);
  grid-template-rows: 138px;
`;

const StoriesLayout = styled(Grid)`
  grid-template-columns: repeat(4, 64px);
  grid-template-rows: 64px;
  transform: translateX(-37px);
`;

const CommonLayoutStyles = styled.div`
  position: absolute;
`;

const WidgetLayout = styled(CommonLayoutStyles)`
  bottom: 12px;
  right: 12px;
  width: 80px;
  height: 80px;
`;

const EmbedLayout = styled(CommonLayoutStyles)`
  top: 20px;
  bottom: 20px;
  left: 12px;
  right: 12px;
`;

const TvPageLayout = styled(CommonLayoutStyles)`
  width: 100%;
  height: 100%;
  padding: 12px;
  background: ${({ theme }) => theme.colors.black};
`;

export default OnsiteCardImage;
