import React from 'react';

import EmailSnippetBuilder from './EmailSnippetBuilder';
import { project as Project } from 'app/src/types/entities';
import AUTOMATION_TOOLS from 'app/src/pages/share/email/emailAutomationToolsList';
import { AUTOMATION_TOOL_KLAVIYO } from 'src/constants/share.constants';
import styled from 'styled-components';
import { TextSmall } from 'shared/react/components/basic/text/TextV2';
import editTemplateImage from '../assets/abandonment-revival/edit-template.png';
import pasteCodeImage from '../assets/abandonment-revival/paste-code.png';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import previewGifFirstImage from '../assets/abandonment-revival/preview-gif-1.png';
import previewGifSecondImage from '../assets/abandonment-revival/preview-gif-2.png';
import { EmailPublishStepsFactoryFunction } from 'app/src/pages/project/components/project-top-bar/components/set-live-button/components/publish-modal/components/EmailPublishStepsFactoryFunction';

const KLAVIYO_FLOWS_URL = 'https://www.klaviyo.com/flows';

export const getPublishKlaviyoFlowSteps: EmailPublishStepsFactoryFunction = ({
  automationTool,
  ...props
}) => [
  {
    title: 'Copy code',
    body: (
      <EmailSnippetBuilder
        automationTool={{ id: AUTOMATION_TOOL_KLAVIYO }}
        shouldHideAutomationToolsSelect
        {...props}
      />
    ),
  },
  {
    title: 'Edit Klaviyo template',
    body: (
      <StepContainer>
        <TextSmall>
          Go to a flow in{' '}
          <a href={KLAVIYO_FLOWS_URL} target="_blank">
            Klaviyo
          </a>{' '}
          and edit the email template.
        </TextSmall>
        <Image src={editTemplateImage} alt="Edit Template" />
      </StepContainer>
    ),
  },
  {
    title: 'Paste code in template',
    body: (
      <StepContainer>
        <TextSmall>
          Paste the code using a block's source code or paste it in the HTML editor.
        </TextSmall>
        <Image src={pasteCodeImage} alt="Paste Code" />
        <TextSmall>
          Please note, the GIF won't show in Klaviyo's editor, follow the next step to preview the
          GIF.
        </TextSmall>
      </StepContainer>
    ),
  },
  {
    title: 'Preview GIF',
    body: (
      <StepContainer>
        <TextSmall>In the template builder, click on Preview & test.</TextSmall>
        <Image src={previewGifFirstImage} alt="Preview GIF" />
        <TextSmall>Swipe through the events to see the different GIF variations.</TextSmall>
        <Image src={previewGifSecondImage} alt="Preview GIF" />
      </StepContainer>
    ),
  },
  {
    title: `GIF Doesn't show?`,
    body: (
      <StepContainer>
        <TextSmall>
          Make sure you're viewing an event that features a product with 3 or more videos.
        </TextSmall>
      </StepContainer>
    ),
    isTip: true,
  },
];

const Image = styled.img`
  max-width: 320px;
`;

const StepContainer = styled(Gap16VerticalFlex)`
  padding: 0 32px 0 16px;
`;
