import React from 'react';

const ProductDetailsIcon = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.25 17v15h10V17zm17.5 0H26.5v15h6.25zm0-1.25H34v17.5H14v-17.5zm-5 2.5h3.75v1.25h-3.75zm3.75 2.5V22h-3.75v-1.25zm-3.75 2.5h3.75v1.25h-3.75z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default ProductDetailsIcon;
