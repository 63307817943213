import * as React from 'react';

const QuizFlowTrashIcon = ({ ...props }) => (
  <svg
    width={12}
    height={13}
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.688 2.375H8.93l-.797-1.313A1.113 1.113 0 0 0 7.172.5H4.805c-.399 0-.75.21-.961.563l-.797 1.312H1.312a.555.555 0 0 0-.562.563c0 .328.234.562.563.562H1.5l.492 7.945c.024.61.516 1.055 1.125 1.055H8.86c.61 0 1.102-.445 1.125-1.055L10.5 3.5h.188a.555.555 0 0 0 .562-.563.57.57 0 0 0-.563-.562Zm-5.883-.75h2.367l.445.75H4.36l.446-.75Zm4.054 9.75H3.117L2.625 3.5h6.727l-.493 7.875Z"
      fill="#FA536E"
    />
  </svg>
);

export default QuizFlowTrashIcon;
