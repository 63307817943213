import React from 'react';
import CopyCodeButtonPricing from 'app/src/complex_components/pricing/CopyCodeButtonPricing';
import styled from 'styled-components';
import { TextBodyLeadingBold, TextSmall } from 'shared/react/components/complex/Text';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { useBilling } from 'app/src/hooks/useBillings';
import Utils from 'app/src/utils';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

const FeedEmbedWidgetsShopifyStepperOtherPages = ({ project, copyPublishId, widgetType }) => {
  const { embedSetLiveEnabled } = useBilling();

  const disabledCopyCode = !project || !embedSetLiveEnabled;

  return [
    <TextContainer key={3}>
      <ShopifyV2Container>
        <TextBodyLeadingBold>Copy code</TextBodyLeadingBold>
        <TextSmallContainer>
          Copy publish ID:
          {
            <CopyAndPublishId>
              <CopyPublishId onClick={copyPublishId}>{project?.publishId}</CopyPublishId>
              <SmallerCopyCodeButtonPricing
                disabled={disabledCopyCode}
                onClick={copyPublishId}
                location="Embed"
                tooltipHeader="embed"
              />
            </CopyAndPublishId>
          }
          and paste it in the Shopify
        </TextSmallContainer>
        <TextSmallContainer>{`themes → ‘Add section’ → ‘Tolstoy ${Utils.upperCaseFirstChar(
          widgetType
        )}’`}</TextSmallContainer>
      </ShopifyV2Container>
    </TextContainer>,
  ];
};

const CopyAndPublishId = styled(HorizontalFlex)`
  gap: 6px;
  align-items: center;
  justify-content: center;
`;

const ShopifyV2Container = styled(VerticalFlex)`
  gap: 2px;
`;

const TextContainer = styled.div`
  align-items: center;
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: 1fr auto;
`;

const TextSmallContainer = styled(TextSmall)`
  display: flex;
  align-items: center;
  gap: 4px;
  line-height: 20px;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const CopyPublishId = styled(TextSmallContainer)`
  color: ${({ theme }) => theme.colors.danger};
  background: ${({ theme }) => theme.colors.gray3};
  border-radius: 2px;
`;

const SmallerCopyCodeButtonPricing = styled(CopyCodeButtonPricing)`
  width: 32px;
  height: 32px;
  padding: 8px;
`;

export default FeedEmbedWidgetsShopifyStepperOtherPages;
