import Utils from 'src/utils';
import { assetType as AssetType } from 'src/types/entities';
import { ALL_UGC_UPLOAD_TYPES } from 'src/constants/intergrations.constants';
import { VideosPageVodAsset } from 'app/src/pages/dashboard/pages/videos/types/videos.types';
import {
  UGC_INSTAGRAM_GRAPH,
  UGC_INSTAGRAM_GRAPH_DENIED,
  UGC_INSTAGRAM_GRAPH_PENDING,
} from 'src/constants/intergrations.constants';
import { FilterType, SortField, SortOrder } from './constants';
import { Filters, Filter } from './types';

/**
 * Formats a date string from the format "YYYY-MM-DDTHH:MM:SS.sssZ" to "Month DD, YYYY".
 *
 * @param {string} dateStr - The date string in the format "YYYY-MM-DDTHH:MM:SS.sssZ".
 * @returns {string} The formatted date string in the format "Month DD, YYYY".
 *
 * @example
 * // Returns "July 12, 2024"
 * formatUgcItemDate("2024-07-12T10:34:05.289Z");
 */
export const formatUgcItemDate = (isoDateString: string): string => {
  const date = new Date(isoDateString);
  return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
};

export const isAssetImported = (uploadType?: string) => {
  return [UGC_INSTAGRAM_GRAPH, UGC_INSTAGRAM_GRAPH_DENIED, UGC_INSTAGRAM_GRAPH_PENDING].includes(
    uploadType
  );
};

export const getUgcHashtags = assets => {
  const hashtags = assets.map(asset => {
    const { ugcHashtag } = Utils.safeParse(asset.externalProviderData);
    return ugcHashtag;
  });

  return [...new Set(hashtags)];
};

export const updateFilters = (filters: Filters, changedGroup: string, value: string) => {
  return Object.entries(filters).reduce((updatedFilters, [group, groupFilters]) => {
    if (group !== changedGroup) {
      updatedFilters[group] = groupFilters;
      return updatedFilters;
    }

    updatedFilters[group] = groupFilters.map(filter =>
      filter.value === value ? { ...filter, isSelected: !filter.isSelected } : filter
    );

    return updatedFilters;
  }, {});
};

// Filters
const isOfMediaType = (asset: VideosPageVodAsset, filter: Filter): boolean => {
  switch (filter.value) {
    case AssetType.gallery:
    case AssetType.image:
      return asset.type === filter.value;
    case AssetType.video:
      return ![AssetType.gallery, AssetType.image].includes(asset.type);
    default:
      return false;
  }
};

const isOfUgcStatus = (asset: VideosPageVodAsset, filter: Filter): boolean => {
  // Not requested
  if (!filter.value) {
    return !ALL_UGC_UPLOAD_TYPES.includes(asset.uploadType);
  }

  return asset.uploadType === filter.value;
};

const isOfSource = (asset: VideosPageVodAsset, filter: Filter): boolean => {
  const { ugcHashtag } = Utils.safeParse(asset.externalProviderData);

  return ugcHashtag === filter.value;
};

export const validateAsset = (asset: VideosPageVodAsset, filter: Filter): boolean => {
  switch (filter.type) {
    case FilterType.MediaType:
      return isOfMediaType(asset, filter);
    case FilterType.Source:
      return isOfSource(asset, filter);
    case FilterType.UgcStatus:
      return isOfUgcStatus(asset, filter);
    default:
      return false;
  }
};

export const sortAssets = (a, b, sort) => {
  if (sort.field === SortField.CreatedAt) {
    const dateA = new Date(a.createdAt).getTime();
    const dateB = new Date(b.createdAt).getTime();

    return sort.order === SortOrder.ASC ? dateA - dateB : dateB - dateA;
  }

  if (sort.field === SortField.LikeCount) {
    const { likeCount: externalLikeCountA } = Utils.safeParse(a.externalProviderData);
    const { likeCount: externalLikeCountB } = Utils.safeParse(b.externalProviderData);
    const likeCountA = a.likeCount || externalLikeCountA || 0;
    const likeCountB = b.likeCount || externalLikeCountB || 0;

    return sort.order === SortOrder.ASC ? likeCountA - likeCountB : likeCountB - likeCountA;
  }

  return 0;
};
