import React from 'react';
import styled from 'styled-components';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { ANALYTICS_TABLE_COLUMN_GROUPS } from 'app/src/constants/analytics.constants';

const getGroups = ({ columns }) => {
  const groupsMap = new Map();
  columns.forEach(({ group, groupName: name, width, flex }) => {
    if (!groupsMap.has(group)) {
      groupsMap.set(group, { width, flex });
    } else {
      const { width: prevWidth, flex: prevFlex } = groupsMap.get(group);
      groupsMap.set(group, { name, width: prevWidth + width, flex: prevFlex + flex });
    }
  });

  return Array.from(groupsMap.entries());
};

const AnalyticsTableGroup = ({ type, name, width, flex }) => {
  return (
    <StyledAnalyticsTableGroup componentWidth={width} flex={flex}>
      <AnalyticsTableGroupText data-type={type}>{name}</AnalyticsTableGroupText>
    </StyledAnalyticsTableGroup>
  );
};

const AnalyticsTableGroupsHeader = ({ columns }) => {
  const groups = getGroups({ columns });

  if (groups.length < 2) {
    return null;
  }

  return (
    <LayoutRoot>
      {groups.map(([key, props], index) => (
        <AnalyticsTableGroup key={key || index} type={key} {...props} />
      ))}
    </LayoutRoot>
  );
};

export default AnalyticsTableGroupsHeader;

const LayoutRoot = styled(HorizontalFlex)`
  height: 39px;
`;

const StyledAnalyticsTableGroup = styled(HorizontalFlex)`
  position: relative;
  width: ${({ componentWidth }) => (componentWidth ? `${componentWidth}px` : '')};
  flex: ${({ flex }) => flex};
`;

const AnalyticsTableGroupText = styled(HorizontalFlex)`
  position: absolute;
  bottom: -9px;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 17px;
  line-height: 23px;
  color: ${({ theme }) => theme.colors.gray4};
  text-align: center;
  font-weight: 500;

  &[data-type$='-${ANALYTICS_TABLE_COLUMN_GROUPS.spacer}'] {
    z-index: 1;
    top: 0;
    bottom: unset;
    background: ${({ theme }) => theme.colors.gray5};
  }
`;
