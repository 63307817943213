import SharedUtils from 'shared/react/utils/utils';
import { EQUALS_KEY } from 'src/constants/ruleGroups.constants';
import { useApps } from 'src/context/AppsStore';
import { useProjectActions, useProjects } from 'src/context/ProjectsStore';
import { useRulesActions } from 'src/context/RulesStore';
import { useOnboardingActions } from 'src/context/ui_store/OnboardingStore';
import { useUser } from 'src/context/userStore/UserStore';
import useCreateIntegrationLabels from 'src/hooks/useCreateIntegrationLabels';
import {
  DEFAULT_ONBOARDING_STATUS_TEMPLATES,
  OnboardingStatusTemplate,
} from 'src/pages/dashboard/pages/homepage/onboarding/constants/onboarding.constants';
import { DynamicRuleTypes } from 'src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/constants/dynamicConditions.constants';
import { publishMethodOptions, RuleGroupType } from 'src/types/entities';
import Utils from 'src/utils';
import {
  ONBOARDING_TEMPLATES,
  ONBOARDING_TEMPLATES_PRE_MADE_LABELS,
  OnboardingTemplateKey,
} from '../templates/constants/onboardingTemplates.constants';
import { ONSITE_TARGET_PAGES } from 'app/src/pages/dashboard/constants/onsiteTargetPages.constants';
import { RulesService } from 'app/src/services/rules';

type OnboardingStatuses = {
  templates: {
    [key: string]: {
      stageIndex: number;
      isDone: boolean;
      projectId: string;
    };
  };
};

type UseAccountOnboardingType = {
  onboardingStatuses: OnboardingStatuses;
  updateOnboardingStatuses: (templateKey: string, payload: any) => Promise<void>;
  handleCreateTemplateProject: (templateKey: string) => Promise<void>;
};

const useAccountOnboarding = (): UseAccountOnboardingType => {
  const [{ account }, { updateAccountSettings }] = useUser();
  const { setActiveTemplateProjectId } = useOnboardingActions();
  const { createProject } = useProjectActions();
  const { saveRules, createDefaultDynamicRule } = useRulesActions();
  const { createIntegrationLabels } = useCreateIntegrationLabels();
  const { createRule } = useRulesActions();
  const [{ selectedAppUrl, shopify }] = useApps();
  const [{ projects }] = useProjects();

  const getOnboardingStatuses = () => {
    if (!account?.onboardingStatuses) {
      return { templates: DEFAULT_ONBOARDING_STATUS_TEMPLATES };
    }

    const accountOnboardingStatuses = Utils.safeParse(account.onboardingStatuses).templates;
    const filteredAccountOnboardingStatuses = Object.entries(accountOnboardingStatuses).reduce(
      (acc, [key, template]) => {
        if (key === OnboardingTemplateKey.shopAppProject) {
          return { ...acc, [key]: template };
        }

        const { projectId } = template as OnboardingStatusTemplate;
        if (!projectId) {
          return acc;
        }

        const project = projects.find(({ id }) => id === projectId);
        if (!project) {
          return acc;
        }

        return { ...acc, [key]: template };
      },
      {}
    );

    return {
      templates: {
        ...DEFAULT_ONBOARDING_STATUS_TEMPLATES,
        ...filteredAccountOnboardingStatuses,
      },
    };
  };
  const onboardingStatuses = getOnboardingStatuses();

  const updateOnboardingStatuses = async (templateKey: OnboardingTemplateKey, payload: any) => {
    const onboardingStatusesCopy = SharedUtils.deepCopyObject(onboardingStatuses);
    onboardingStatusesCopy.templates[templateKey] = {
      ...onboardingStatusesCopy.templates[templateKey],
      ...payload,
    };

    return updateAccountSettings({
      onboardingStatuses: JSON.stringify(onboardingStatusesCopy),
    });
  };

  const createHomepageCarouselRules = async () => {
    const [homepageLabel] = await createIntegrationLabels([
      ONBOARDING_TEMPLATES_PRE_MADE_LABELS.homepage,
    ]);

    const homepageRule = createRule({
      condition: EQUALS_KEY,
      value: homepageLabel.id,
      type: DynamicRuleTypes.vodLabel,
      behaviors: [],
    });

    return [[homepageRule], [RulesService.getAllowNonTaggedVideosRule()]];
  };

  const getRulesByTemplateKey = async (templateKey: OnboardingTemplateKey) => {
    switch (templateKey) {
      case OnboardingTemplateKey.pdpStories:
        return [[createDefaultDynamicRule()]];
      case OnboardingTemplateKey.homepageCarousel:
        return createHomepageCarouselRules();
    }
  };

  const createDefaultDynamicProject = async (templateKey: OnboardingTemplateKey) => {
    const { projectProps } = ONBOARDING_TEMPLATES[templateKey];

    const project = await createProject({
      input: undefined,
      vertical: undefined,
      newTolstoyName: undefined,
      isEcomFeed: true,
      appUrl: selectedAppUrl || shopify?.appUrl,
      ...projectProps,
    });

    const rules = await getRulesByTemplateKey(templateKey);
    const ruleGroup = { rules, enabled: true };
    await saveRules(ruleGroup, project, RuleGroupType.dynamic);

    return project;
  };

  const handleCreateTemplateProject = async (templateKey: OnboardingTemplateKey) => {
    const currentProjectId = onboardingStatuses.templates[templateKey].projectId;

    if (templateKey === OnboardingTemplateKey.shopAppProject) {
      const project = projects.find(
        ({ publishMethod, appUrl, targetPage }) =>
          appUrl === selectedAppUrl &&
          publishMethod === publishMethodOptions.shopApp &&
          targetPage === ONSITE_TARGET_PAGES.ProductPages
      );
      setActiveTemplateProjectId(project.id);
      return;
    }

    if (currentProjectId) {
      setActiveTemplateProjectId(currentProjectId);
      return;
    }

    const project = await createDefaultDynamicProject(templateKey);

    setActiveTemplateProjectId(project.id);

    const onboardingStatusesPayload = {
      projectId: project.id,
    };

    return updateOnboardingStatuses(templateKey, onboardingStatusesPayload);
  };

  return {
    onboardingStatuses,
    updateOnboardingStatuses,
    handleCreateTemplateProject,
  };
};

export default useAccountOnboarding;
