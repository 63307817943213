import React from 'react';

const BuildingIcon = () => {
  return (
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 3.75A.74.74 0 0 1 2.75 3h1.5a.76.76 0 0 1 .75.75v1.5a.74.74 0 0 1-.75.75h-1.5A.722.722 0 0 1 2 5.25v-1.5ZM3 4v1h1V4H3Zm6.25-1a.76.76 0 0 1 .75.75v1.5a.74.74 0 0 1-.75.75h-1.5A.722.722 0 0 1 7 5.25v-1.5A.74.74 0 0 1 7.75 3h1.5ZM9 5V4H8v1h1ZM2 7.75A.74.74 0 0 1 2.75 7h1.5a.76.76 0 0 1 .75.75v1.5a.74.74 0 0 1-.75.75h-1.5A.722.722 0 0 1 2 9.25v-1.5ZM3 8v1h1V8H3Zm6.25-1a.76.76 0 0 1 .75.75v1.5a.74.74 0 0 1-.75.75h-1.5A.722.722 0 0 1 7 9.25v-1.5A.74.74 0 0 1 7.75 7h1.5ZM9 9V8H8v1h1Zm-7 7c-1.125 0-2-.875-2-2V2C0 .906.875 0 2 0h8c1.094 0 2 .906 2 2v12c0 1.125-.906 2-2 2H2ZM1 2v12c0 .563.438 1 1 1h2v-2c0-1.094.875-2 2-2 1.094 0 2 .906 2 2v2h2c.531 0 1-.438 1-1V2c0-.531-.469-1-1-1H2c-.563 0-1 .469-1 1Zm6 11c0-.531-.469-1-1-1-.563 0-1 .469-1 1v2h2v-2Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default BuildingIcon;
