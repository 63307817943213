import React from 'react';

const ExtensionIcon = () => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 9c0-1.094-.688-2-1.5-2-.438 0-.844.25-1.094.656A.815.815 0 0 1 12.75 8a.722.722 0 0 1-.75-.75V5c0-.531-.469-1-1-1H8.75A.722.722 0 0 1 8 3.25c0-.25.125-.5.344-.656C8.75 2.344 9 1.938 9 1.5 9 .687 8.094 0 7 0 5.875 0 5 .688 5 1.5c0 .438.219.844.625 1.094C5.844 2.75 6 3 6 3.25c0 .438-.344.75-.781.75H1c-.563 0-1 .469-1 1v2.25c0 .438.313.75.75.75.25 0 .5-.125.656-.344C1.656 7.25 2.063 7 2.5 7 3.313 7 4 7.906 4 9c0 1.125-.688 2-1.5 2a1.27 1.27 0 0 1-1.094-.625C1.25 10.156 1 10 .75 10c-.438 0-.75.344-.75.781V15c0 .563.438 1 1 1h4.219c.437 0 .781-.313.781-.75 0-.25-.156-.5-.375-.656A1.27 1.27 0 0 1 5 13.5c0-.813.875-1.5 2-1.5 1.094 0 2 .688 2 1.5 0 .438-.25.844-.656 1.094A.815.815 0 0 0 8 15.25c0 .438.313.75.75.75H11c.531 0 1-.438 1-1v-4.219c0-.437.313-.781.75-.781.25 0 .5.156.656.375.25.406.656.625 1.094.625.813 0 1.5-.875 1.5-2Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default ExtensionIcon;
