import React from 'react';

function CalendlyResponseButtonIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="22"
      fill="none"
      viewBox="0 0 14 16"
      {...props}
    >
      <path
        fill="#e6ab4a"
        d="M12.5 2H11V1c0-.531-.469-1-1-1-.563 0-1 .469-1 1v1H5V1c0-.531-.469-1-1-1-.563 0-1 .469-1 1v1H1.5A1.5 1.5 0 000 3.5V5h14V3.5c0-.813-.688-1.5-1.5-1.5zM0 14.5c0 .844.656 1.5 1.5 1.5h11a1.5 1.5 0 001.5-1.5V6H0v8.5zm3.75-6h6.5a.76.76 0 01.75.75.74.74 0 01-.75.75h-6.5A.722.722 0 013 9.25a.74.74 0 01.75-.75zm0 3h3.5a.76.76 0 01.75.75.74.74 0 01-.75.75h-3.5a.722.722 0 01-.75-.75.74.74 0 01.75-.75z"
      ></path>
    </svg>
  );
}

export default CalendlyResponseButtonIcon;
