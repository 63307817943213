import React from 'react';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';
import EditorColorInputItem from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorColorInputItem';
import EditorFontFamilyAndWeight from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorFontFamilyAndWeight';
import EditorHeader from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorHeader';
import EditorItem from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorItem';
import EditorMainContainer from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorMainContainer';
import EditorNumericInputItem from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorNumericInputItem';
import { Textarea } from 'ui-library/core';

type Props = {
  setFunction: (value: { [key: string]: string | number }) => void;
  title: string;
  desktopSizes: { min: number; max: number };
  mobileSizes: { min: number; max: number };
  textCustomization: {
    text: string;
    font: string;
    fontWeight: number;
    desktopFontSize: number;
    mobileFontSize: number;
    color: string;
  };
};

const HeroOverlayTextCustomizationEditor = ({
  textCustomization,
  setFunction,
  title,
  desktopSizes,
  mobileSizes,
}: Props) => {
  const { text, font, fontWeight, desktopFontSize, mobileFontSize, color } =
    textCustomization || {};
  const {
    branding: { typography },
  } = useDesignSettingsContext();

  const onChange = (key, value) => {
    setFunction({ [key]: value });
  };

  const onTextChange = value => {
    onChange('text', value);
  };

  const onUpdateFontAndWeight = (font: string, fontWeight: number) => {
    setFunction({ font, fontWeight });
  };

  return (
    <>
      <EditorHeader title={title} />
      <EditorMainContainer>
        <EditorFontFamilyAndWeight
          onChange={onUpdateFontAndWeight}
          value={font}
          fontWeight={fontWeight}
          fonts={typography}
        />
        <EditorItem text="Text">
          <Textarea
            className="h-8 resize-none"
            value={text}
            onChange={e => onTextChange(e.target.value)}
          />
        </EditorItem>

        <EditorNumericInputItem
          text="Desktop font size"
          suffix="px"
          onChange={value => onChange('desktopFontSize', value)}
          value={desktopFontSize}
          min={desktopSizes.min}
          max={desktopSizes.max}
        />
        <EditorNumericInputItem
          text="Mobile font size"
          suffix="px"
          onChange={value => onChange('mobileFontSize', value)}
          value={mobileFontSize}
          min={mobileSizes.min}
          max={mobileSizes.max}
        />
        <EditorColorInputItem
          text="Text color"
          color={color}
          onChange={value => onChange('color', value)}
          height="50px"
          maxWidth="160px"
          key={'color'}
        />
      </EditorMainContainer>
    </>
  );
};

export default HeroOverlayTextCustomizationEditor;
