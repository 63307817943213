import React from 'react';
import {
  CategoryHeader,
  Header,
  HeaderContainer,
  Textarea,
} from 'app/src/layouts/tolstoy_empty_screen/emptyScreenCss';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const DowngradeImproveProduct = ({ improveProductValue, setImproveProductValue }) => {
  return (
    <>
      <CustomHeaderContainer>
        <Header>What is the main reason you want to cancel your subscription? </Header>
      </CustomHeaderContainer>
      <RecommendationsContainer>
        <CategoryHeader>Please write any recommendations</CategoryHeader>
        <CustomTextArea
          value={improveProductValue}
          onChange={e => setImproveProductValue(e.target.value)}
          placeholder="Your sincere opinion here"
        />
      </RecommendationsContainer>
    </>
  );
};

const CustomHeaderContainer = styled(HeaderContainer)`
  margin: 56px 0 70px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    margin: 40px 0;
  }
`;

const RecommendationsContainer = styled(VerticalFlex)`
  gap: 24px;
  height: 100%;
`;

const CustomTextArea = styled(Textarea)`
  height: 80%;
`;

export default DowngradeImproveProduct;
