import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import Utils from 'shared/react/utils/utils';
import ProductsCarousel from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/products_result_preview/ProductsCarousel';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { TABLET_MAX_WIDTH } from 'shared/react/utils/mediaQuery';
const GAP = 24;
const WIDTH = 202;
const NO_PRODUCTS_MESSAGE = 'Add product from the side bar drop down.';

const ProductsResultPreview = ({ project, defaultTilesNumber = 3, size = 1, ...props }) => {
  const ref = useRef();
  const productRef = useRef();
  const { watch } = useFormContext();
  const isMobile = useMediaQuery(`(${TABLET_MAX_WIDTH})`) || Utils.isMobile();
  const [selectedDot, setSelectedDot] = useState(0);
  const [numberOfTilesOnScreen, setNumberOfTilesOnScreen] = useState(defaultTilesNumber);
  const cardWidthWithGap = WIDTH + GAP;
  const [products, overlayText, overlayTextColor] = watch([
    'products',
    'overlayText',
    'overlayTextColor',
  ]);
  const dotNumbers = products.length - numberOfTilesOnScreen;

  const onDotClick = i => {
    setSelectedDot(i);
    let childWidth = cardWidthWithGap * i;
    if (i === 0) {
      childWidth = 0;
    }
    ref.current.scrollTo({ left: childWidth, behavior: 'smooth' });
  };

  useEffect(() => {
    if (products.length < defaultTilesNumber) {
      setNumberOfTilesOnScreen(products.length);
      return;
    }

    setNumberOfTilesOnScreen(defaultTilesNumber);
  }, [products]);

  if (!products.length) {
    return (
      <LayoutRoot {...props}>
        <Title color={overlayTextColor}>{NO_PRODUCTS_MESSAGE}</Title>
      </LayoutRoot>
    );
  }

  return (
    <LayoutRoot {...props}>
      {
        <TitleWrapper overlayText={overlayText}>
          <Title color={overlayTextColor}>{overlayText}</Title>
        </TitleWrapper>
      }
      <ProductsCarousel
        products={products}
        productRef={productRef}
        containerRef={ref}
        size={isMobile ? size + 1 : size}
      />
      <DotsContainer dotNumbers={dotNumbers}>
        {Utils.range(0, dotNumbers).map((dot, i) => {
          return (
            <Dot size={size} onClick={() => onDotClick(i)} key={i} selected={i === selectedDot} />
          );
        })}
      </DotsContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)`
  background: ${({ theme }) => theme.colors.gray3};
  color: ${({ theme }) => theme.colors.gray3};
  border-radius: 24px;
  padding: 32px;
  width: 880px;
  height: 532px;
  justify-content: center;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    height: 320px;
  }
`;

const TitleWrapper = styled(HorizontalFlexWrap)`
  visibility: ${({ overlayText }) => (overlayText ? '' : 'hidden')};
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  color: ${({ color, theme }) => color || theme.colors.black};
  font-size: 18px;
  font-weight: 700;
  align-self: center;
`;

const DotsContainer = styled(HorizontalFlexWrap)`
  flex-wrap: nowrap;
  justify-content: center;
  gap: 6px;
  visibility: ${({ dotNumbers }) => (dotNumbers ? '' : 'hidden')};
`;

const Dot = styled.div`
  width: ${({ size }) => 12 / size}px;
  height: ${({ size }) => 12 / size}px;
  border-radius: 100%;
  background: ${({ theme }) => theme.colors.gray4};
  opacity: ${({ selected }) => (selected ? 1 : 0.3)};
  cursor: pointer;
  transition: 0.3s;
  pointer-events: auto;
  &:hover {
    opacity: ${({ selected }) => (selected ? 1 : 0.6)};
  }
`;

export default ProductsResultPreview;
