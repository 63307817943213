import React from 'react';

const ChatIcon = () => {
  return (
    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 0C4.5625 0 1 2.9375 1 6.5C1 8.0625 1.65625 9.46875 2.78125 10.5938C2.375 12.1875 1.0625 13.5625 1.0625 13.5938C1 13.6562 0.96875 13.7812 1 13.875C1.0625 13.9688 1.125 14 1.25 14C3.3125 14 4.875 13.0312 5.625 12.4062C6.65625 12.7812 7.78125 13 9 13C13.4062 13 17 10.0938 17 6.5C17 2.9375 13.4062 0 9 0ZM10.9688 3.5C11.5312 3.5 11.9688 3.96875 11.9688 4.5C11.9688 5.0625 11.5312 5.5 10.9688 5.5C10.4375 5.5 9.96875 5.0625 9.96875 4.5C9.96875 3.96875 10.4375 3.5 10.9688 3.5ZM6.96875 3.5C7.53125 3.5 7.96875 3.96875 7.96875 4.5C7.96875 5.0625 7.53125 5.5 6.96875 5.5C6.4375 5.5 5.96875 5.0625 5.96875 4.5C5.96875 3.96875 6.4375 3.5 6.96875 3.5ZM12.3125 8.5C11.4688 9.46875 10.2812 10 8.96875 10C7.6875 10 6.5 9.46875 5.65625 8.5C5.40625 8.1875 5.4375 7.71875 5.75 7.4375C6.0625 7.15625 6.53125 7.21875 6.8125 7.53125C7.34375 8.15625 8.125 8.5 8.96875 8.5C9.84375 8.5 10.625 8.15625 11.1562 7.53125C11.4375 7.21875 11.9062 7.15625 12.2188 7.4375C12.5312 7.71875 12.5625 8.1875 12.3125 8.5Z"
        fill="#7D8087"
      />
    </svg>
  );
};

export default ChatIcon;
