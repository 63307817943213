import React, { useEffect, useRef, useState } from 'react';
import StandardModal from '../modals/StandardModal';
import styled, { useTheme } from 'styled-components';
import { TextH5, TextSmall } from 'shared/react/components/complex/Text';
import Button from 'shared/react/components/complex/Button';
import Types from 'shared/react/theme/Types';
import UserBox from 'app/src/basic_components/UserBox';
import { uploadLogo, verifyImageDimensions } from '../../helpers/Image';
import SnackBar from 'app/src/basic_components/SnackBar';
import Utils from '../../utils';
import { MAX_IMAGE_SIZE } from 'app/src/constants/account.constants';

const LOADING_STATES = { saving: 'SAVING', uploadingLogo: 'UPLOADING_LOGO' };
const LOGO_MAX_WIDTH = 1536;
const LOGO_MAX_HEIGHT = 1536;

const ChangeIconModal = ({
  open,
  closeModal,
  name,
  user,
  updateFunction,
  logoSettings,
  routeKey,
}) => {
  const theme = useTheme();
  const [selectedColor, setSelectedColor] = useState(logoSettings?.color);
  const [userLogo, setUserLogo] = useState(logoSettings?.logo);
  const [loadingState, setLoadingStateState] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const logoInputRef = useRef();

  const defaultColors = [
    theme.colors.success,
    theme.colors.warning,
    theme.colors.info,
    theme.colors.primary,
    theme.colors.danger,
    theme.colors.pink7,
  ];

  const onSave = async () => {
    setLoadingStateState(LOADING_STATES.saving);
    const logoSettings = JSON.stringify({ logo: userLogo, color: selectedColor });
    await updateFunction({ ...user, logoSettings });
    setLoadingStateState(null);
    closeModal();
  };

  const onUploadImage = async e => {
    const file = e.currentTarget.files[0];

    setLoadingStateState(LOADING_STATES.uploadingLogo);

    const url = await uploadLogoFile(file, routeKey);

    setTimeout(() => {
      setUserLogo(`${url}?timestamp=${Date.now()}`);
      setLoadingStateState(null);
    }, 2000);
  };

  const uploadLogoFile = async (file, key) => {
    const validDimensions = await verifyImageDimensions({
      width: LOGO_MAX_WIDTH,
      height: LOGO_MAX_HEIGHT,
      file,
    });

    if (!validDimensions) {
      setErrorMessage(`Please upload an image smaller than ${LOGO_MAX_WIDTH}x${LOGO_MAX_HEIGHT}.`);
      return;
    }

    if (file.size > MAX_IMAGE_SIZE) {
      setErrorMessage(`Please upload an image smaller than 1MB.`);
    }

    const logoUrl = await uploadLogo({ file, key });
    if (logoUrl) {
      return logoUrl;
    }

    setErrorMessage('Failed to upload the logo. Please try again.');
  };

  const onButtonClick = () => {
    if (userLogo) {
      setUserLogo(null);
      return;
    }

    logoInputRef?.current?.click();
  };

  useEffect(() => {
    setSelectedColor(logoSettings?.color || theme.colors.danger);
    setUserLogo(logoSettings?.logo);
  }, [logoSettings, open]);

  return (
    <StandardModal removeDefaultPadding={true} maxWidth={'479px'} onClose={closeModal} open={open}>
      <ModalContainer>
        <UpperContainer>
          <Header>Upload an avatar or pick a color</Header>
          <SubHeader>Upload jpeg or png. Max. size 1mb</SubHeader>
        </UpperContainer>
        <ImageContainer>
          <Avatar
            fontSize="33px"
            fontWeight="900"
            color="white"
            backgroundColor={selectedColor}
            userImageSrc={Utils.getLogoUrl(userLogo)}
            name={name}
            scale={'88px'}
          />
          <Button
            onClick={onButtonClick}
            loading={loadingState === LOADING_STATES.uploadingLogo}
            type={Types.Secondary}
          >
            {userLogo ? 'Clear' : 'Upload logo'}
          </Button>
          <Input ref={logoInputRef} type="file" accept=".png" onChange={onUploadImage} />
        </ImageContainer>
        <ColorsContainer>
          {defaultColors.map(color => (
            <Color onClick={() => setSelectedColor(color)} key={color} color={color} />
          ))}
        </ColorsContainer>
        <ButtonsContainer>
          <Button onClick={closeModal} type={Types.Ghost}>
            Cancel
          </Button>
          <Button
            onClick={onSave}
            disabled={loadingState}
            loading={loadingState === LOADING_STATES.saving}
          >
            Save
          </Button>
        </ButtonsContainer>
      </ModalContainer>
      <SnackBar
        text={errorMessage}
        open={errorMessage}
        setOpen={() => setErrorMessage(null)}
        severity="error"
      />
    </StandardModal>
  );
};

const ModalContainer = styled.div`
  padding: 24px;
  display: grid;
  gap: 48px;
`;

const UpperContainer = styled.div`
  display: grid;
  gap: 8px;
`;

const Header = styled(TextH5)``;

const SubHeader = styled(TextSmall)``;

const ImageContainer = styled.div`
  display: flex;
  gap: 24px;
`;

const Avatar = styled(UserBox)`
  border-radius: 100%;
`;

const ColorsContainer = styled(ImageContainer)`
  gap: 16px;
`;

const Color = styled.div`
  width: 32px;
  border-radius: 100%;
  height: 32px;
  background: ${({ color }) => color};
  cursor: pointer;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

const Input = styled.input`
  display: none;
`;

export default ChangeIconModal;
