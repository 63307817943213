import React from 'react';
import styled from 'styled-components';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import SHARE_ICONS from 'shared/react/components/complex/share/shareIcons';
import { getSocialPlatformLink } from 'shared/react/utils/share';
import { FEED_SHARE_COPY_LINK_DATA_TYPE } from '../../../constants/feedPreviewDataTypes.constants';
import { SOCIAL_PLATFORMS_KEYS } from '../../../constants/share.constants';
import useTranslation from '../context/hooks/useTranslation';
import { BolderTextSmall } from '../Text';
import { TOLSTOY_VIDEO_ID_URL_PARAM } from 'player/src/constants/urls.constants';

const ShareSocialButtons = ({
  publishId,
  isFeed,
  handleButtonClick,
  iconsFillColor,
  shouldHideCopyLink,
  currentStep,
  onKeyDown,
  setNextRef = () => {},
  ...props
}) => {
  const [, { t }] = useTranslation();

  const playerUrl = `${location.origin.replace('play', 'player')}/${
    isFeed ? 'feed/' : ''
  }${publishId}${currentStep ? `?${TOLSTOY_VIDEO_ID_URL_PARAM}=${currentStep.videoId}` : ''}`;

  return (
    <LayoutRoot
      shouldAdjustSpacing={!shouldHideCopyLink}
      shouldAddPadding={!shouldHideCopyLink}
      {...props}
      role="group"
      aria-label="Share platforms"
    >
      {SHARE_ICONS.flatMap(({ Icon, key }) => {
        const url = getSocialPlatformLink(key, playerUrl);
        const isCopyLink = key === SOCIAL_PLATFORMS_KEYS.link;
        if (isCopyLink && shouldHideCopyLink) {
          return [];
        }

        return (
          <SocialButton
            aria-label={key}
            role="button"
            tabIndex="0"
            ref={setNextRef}
            onKeyDown={onKeyDown}
            key={key}
            hasText={isCopyLink}
            onClick={() => handleButtonClick(url, key)}
            isFeed={isFeed}
          >
            <Item addPadding={isCopyLink}>
              <Icon fill={iconsFillColor} />
              {isCopyLink && (
                <BolderTextSmall data-type={FEED_SHARE_COPY_LINK_DATA_TYPE}>
                  {t('common.copyLink')}
                </BolderTextSmall>
              )}
            </Item>
          </SocialButton>
        );
      })}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)`
  justify-content: ${({ shouldAdjustSpacing }) => (shouldAdjustSpacing ? 'space-between' : '')};
  gap: ${({ shouldAdjustSpacing }) => (shouldAdjustSpacing ? '' : '24px')};
  padding: ${({ shouldAddPadding }) => (shouldAddPadding ? '24px 0' : '')};
`;

const Item = styled(HorizontalFlex)`
  gap: 4px;
  padding: ${({ shouldAddPadding }) => (shouldAddPadding ? '0 16px' : '')};
`;

const SocialButton = styled(HorizontalFlexWrap)`
  border-radius: ${({ hasText }) => (hasText ? '40px' : '100%')};
  border: 1px solid ${({ theme }) => theme.colors.ghostLight};
  transition: 0.2s;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: ${({ isFeed }) => (isFeed ? 7 : 16)}px;

  &:hover {
    transform: scale(1.2);
  }
`;

export default ShareSocialButtons;
