import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styled from 'styled-components';

const DraggableThumbnail = ({ id, src, index, onSelect }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: id.toString(),
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <StyledThumbnail
        src={src}
        alt={`Thumbnail ${index}`}
        onClick={() => {
          onSelect(index);
        }}
      ></StyledThumbnail>
    </div>
  );
};

const StyledThumbnail = styled.img`
  width: 67px;
  height: 89px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 2px;
`;

export default DraggableThumbnail;
