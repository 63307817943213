import React from 'react';
import styled from 'styled-components';
import Separator from 'shared/react/components/basic/Separator';

const BillingPackageSeparator = () => {
  return (
    <BillingPackageSeparatorContainer>
      <StyledBillingPackageSeparator />
    </BillingPackageSeparatorContainer>
  );
};

export default BillingPackageSeparator;

const BillingPackageSeparatorContainer = styled.div`
  padding-bottom: 8px;
`;

const StyledBillingPackageSeparator = styled(Separator)`
  background-color: ${({ theme }) => theme.colors.gray35};
`;
