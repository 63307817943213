export const SHOPABLE_SIDE_PANEL_TYPE = 'shoppable';
export const DESCRIPTION_SIDE_PANEL_TYPE = 'description';
export const PRIMARY_DARK_BUTTON_TYPE = 'primaryDark';
export const PRIMARY_LIGHT_BUTTON_TYPE = 'primaryLight';
export const SECONDARY_BUTTON_TYPE = 'secondary';
export const SWIPE_UP_ANIMATION_LOCAL_STORAGE_KEY = 'tolstoy-swipe-up-animation-seen';

export const PRODUCT_CTA_TYPES = {
  addToCart: 'addToCart',
  buyNow: 'buyNow',
  openProductPage: 'openProductPage',
  customUrl: 'customUrl',
  closePlayer: 'closePlayer',
};

export const CART_CTA_TYPES = [PRODUCT_CTA_TYPES.addToCart, PRODUCT_CTA_TYPES.buyNow];

export const INTERSECTION_THRESHOLDS = {
  snap: 1,
  preSnap: 0.75,
  outOfView: 0,
};

export const VIDEO_INDEXES = {
  prev: 0,
  current: 1,
  next: 2,
};

export const IMAGE_FIT_TYPE = {
  COVER: 'cover',
  CONTAIN: 'contain',
};

export const IMAGE_BACKGROUND_TYPE = {
  BLURRY: 'blurry',
  SOLID: 'solid',
};

export const YOTPO_REFETCH_TIMEOUT = 1000 * 60 * 60 * 3; // 3 hours

export const DEFAULT_QUANTITY = 1;

export const FEED_TYPES = {
  default: 'default',
  search: 'search',
  trending: 'trending',
};

export const FEED_TABS = { VIDEOS: 'videos', WISHLIST: 'wishlist' };

export const TOP_TABS = [
  {
    key: 'videos',
    text: 'Videos',
  },
  {
    key: 'wishlist',
    text: 'Wishlist',
  },
];

export const FENTY_QUICK_SHOP_TEXT = 'QUICK SHOP';
