export enum ONSITE_TARGET_PAGES {
  HomePages = 'home-pages',
  ProductPages = 'product-pages',
  SpecificProductPage = 'specific-product-page',
  SpecificPage = 'specific-page',
  Collections = 'collections',
  TvPages = 'tv-pages',
  TvChannel = 'tv-channel',
  OtherPages = 'other-pages',
  AllPages = 'all',
}
