import React from 'react';

function FillArrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" fill="none" viewBox="0 0 8 5">
      <path
        fill="#090A0B"
        d="M7.516 1.29c.21-.212.28-.54.164-.821A.76.76 0 007 0H1a.768.768 0 00-.703.469.758.758 0 00.164.82l3 3c.14.14.328.211.539.211.188 0 .375-.07.516-.21l3-3z"
      ></path>
    </svg>
  );
}

export default FillArrow;
