import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import Menu from 'shared/react/components/basic/Menu';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { BREAKPOINT_OPTIONS } from '../../../../constants/menu.constants';
import { SOCIAL_PLATFORMS_KEYS } from '../../../../constants/share.constants';
import { getSocialPlatformLink } from '../../../../utils/share';
import Utils from '../../../../utils/utils';
import useTracker from '../../context/hooks/useTracker';
import useTranslation from '../../context/hooks/useTranslation';
import shareIcons from '../../share/shareIcons';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';
import useKeyboardNavigation from 'player/src/hooks/useKeyboardNavigation';

const ShareMenu = ({ anchorEl, setAnchorEl, currentStep }) => {
  const [, { t }] = useTranslation();
  const theme = useTheme();
  const [, { track }] = useTracker();
  const [{ publishId }] = useProjectConfig();
  const { onKeyDown, setNextRef } = useKeyboardNavigation();

  const playerUrl = Utils.getPlayerShareUrl(publishId, currentStep);

  const handleSocialButtonClick = (link, type) => {
    track('Open Share Link', { type });

    Utils.openInNewTab(getSocialPlatformLink(type, link));
  };

  const handleCopyLinkClick = () => {
    Utils.copyToClipboard(playerUrl);
  };

  const getShareOptions = () => {
    const shareOptions = [];
    shareIcons.map(({ key: platform, Icon }) => {
      if (platform === SOCIAL_PLATFORMS_KEYS.link) {
        return shareOptions.push({
          key: platform,
          icon: <Icon fill={theme.colors.black} width={20} height={20} />,
          action: handleCopyLinkClick,
          text: t('common.copyLink'),
        });
      }

      shareOptions.push({
        key: platform,
        text: `${t('common.shareOn')} ${platform}`,
        icon: <Icon fill={theme.colors.black} width={20} height={20} />,
        action: () => handleSocialButtonClick(playerUrl, platform),
      });
    });

    return shareOptions;
  };

  const handleMenuClosed = () => {
    setAnchorEl(null);
  };

  const handleOptionClicked = (e, action) => {
    e.stopPropagation();
    action();
    handleMenuClosed();
  };

  return (
    <StyledMenu
      direction={{
        vertical: 'top',
        horizontal: 'center',
      }}
      breakpoint={BREAKPOINT_OPTIONS.moveToTop}
      getContentAnchorEl={null}
      anchorEl={anchorEl}
      disableAutoFocusItem
      open={!!anchorEl}
      onClick={handleMenuClosed}
      onClose={handleMenuClosed}
      role="menu"
    >
      <Container>
        <Text>{t('interactive.endScreen.buttons.share')}</Text>
        {getShareOptions().map(({ key, icon, text, action }) => {
          return (
            <ShareOptionContainer
              key={key}
              onClick={e => handleOptionClicked(e, action)}
              role="menuitem"
              aria-label={text}
              ref={setNextRef}
              onKeyDown={onKeyDown}
              tabIndex="0"
            >
              {icon}
              {text}
            </ShareOptionContainer>
          );
        })}
      </Container>
    </StyledMenu>
  );
};

const StyledMenu = styled(Menu)`
  background: ${({ theme }) => theme.colors.white};
  padding: 16px;
  border-radius: 8px;
  margin: -8px 0;
  box-shadow: 0px 5px 10px -2px rgba(0, 0, 0, 0.1);
  filter: drop-shadow(0px 15px 45px rgba(50, 50, 93, 0.25));
`;

const Container = styled(VerticalFlex)`
  min-width: 216px;
  border-radius: 32px;
  gap: 16px;
`;

const MenuCss = css`
  text-align: start;
  color: ${({ theme }) => theme.colors.black};
`;

const Text = styled.div`
  ${MenuCss};
  font-weight: 590;
  font-size: 18px;
  line-height: 24px;
  padding: 0 16px;
`;

const ShareOptionContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  ${MenuCss};
  font-weight: 510;
  font-size: 16px;
  line-height: 16px;
  margin: 0 8px;
  border-radius: 8px;
  height: 40px;
  user-select: none;
  gap: 8px;

  &:hover {
    background: ${({ theme }) => theme.colors.gray5};
    cursor: pointer;
  }
`;

export default ShareMenu;
