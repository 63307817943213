import React, { FunctionComponent, useEffect, useState } from 'react';
import { useLookAndFeelContext } from '../../../../LookAndFeelContext';
import EditorMainContainer from '../../shared/EditorMainContainer';
import TextArea from '../../shared/ui-library/TextArea';

enum howToSection {
  VIDEO_INSTRUCTIONS = 'videoInstructions',
  CONTENT_INSTRUCTIONS = 'contentInstructions',
  LIGHTS_INSTRUCTIONS = 'lightsInstructions',
}

const howToSections = [
  {
    key: howToSection.VIDEO_INSTRUCTIONS,
    title: 'Video instructions',
  },
  {
    key: howToSection.CONTENT_INSTRUCTIONS,
    title: 'Content instructions',
  },
  {
    key: howToSection.LIGHTS_INSTRUCTIONS,
    title: 'Lights instructions',
  },
];

const initialState = {
  [howToSection.VIDEO_INSTRUCTIONS]: '',
  [howToSection.CONTENT_INSTRUCTIONS]: '',
  [howToSection.LIGHTS_INSTRUCTIONS]: '',
};

const HowTo: FunctionComponent = () => {
  const [state, setState] = useState(initialState);
  const {
    customizationSettings: {
      videoCollectionCampaignSettings: {
        landingPage: landingPageSettings, // `video-recorder` landingPage settings
      },
    },
    updateCustomizationSettings,
  } = useLookAndFeelContext();

  useEffect(() => {
    if (landingPageSettings?.howTo) {
      setState(prev => ({ ...prev, ...landingPageSettings.howTo }));
    }
  }, [landingPageSettings]);

  const updateProperty = (key: string, value: string | string[]): void => {
    setState(prev => ({ ...prev, [key]: value }));
    updateCustomizationSettings({
      videoCollectionCampaignSettings: {
        landingPage: {
          howTo: {
            [key]: value,
          },
        },
      },
    });
  };

  return (
    <EditorMainContainer shouldHide={false}>
      {howToSections.map(({ key, title }) => (
        <TextArea
          key={key}
          label={title}
          placeholder={title}
          value={state[key]}
          onChange={value => {
            updateProperty(key, value);
          }}
          splitLines={true}
        />
      ))}
    </EditorMainContainer>
  );
};

export default HowTo;
