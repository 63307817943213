import React, { FunctionComponent, useEffect, useState } from 'react';
import { useLookAndFeelContext } from '../../../../LookAndFeelContext';
// import EditorSubheader from '../../shared/EditorSubheader';
import EditorMainContainer from '../../shared/EditorMainContainer';
import TextInput from '../../shared/ui-library/TextInput';

const Examples: FunctionComponent = () => {
  const [state, setState] = useState('');

  const {
    customizationSettings: {
      videoCollectionCampaignSettings: {
        examplesWidgetPublishId, // `video-recorder` examples widget publishId
      },
    },
    updateCustomizationSettings,
  } = useLookAndFeelContext();

  useEffect(() => {
    setState(examplesWidgetPublishId ?? '');
  }, [examplesWidgetPublishId]);

  const updateProperty = (value: string): void => {
    setState(value ?? '');
    updateCustomizationSettings({
      videoCollectionCampaignSettings: {
        examplesWidgetPublishId: value,
      },
    });
  };

  return (
    <>
      {/* <EditorSubheader title="Give your customers examples to inspire them to create a video and shoowcase what content you want them to create." /> */}
      <EditorMainContainer shouldHide={false}>
        <TextInput label="Examples project Publish ID" value={state} onChange={updateProperty} />
      </EditorMainContainer>
    </>
  );
};

export default Examples;
