import React from 'react';

const RefreshIcon = props => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2.086 5.258c.164-.516.469-.985.867-1.406a3.791 3.791 0 0 1 5.32 0l.399.398H7.5a.755.755 0 0 0-.75.75c0 .422.328.75.75.75h2.977a.74.74 0 0 0 .75-.75V2a.755.755 0 0 0-.75-.75.771.771 0 0 0-.75.75v1.219l-.399-.422a5.277 5.277 0 0 0-7.43 0A5.297 5.297 0 0 0 .656 4.766a.75.75 0 0 0 .469.96c.375.141.82-.07.96-.468ZM.539 7.297a.668.668 0 0 0-.328.187.668.668 0 0 0-.188.329C0 7.835 0 7.859 0 7.905V11c0 .422.328.75.75.75.398 0 .75-.328.75-.75V9.805l.398.422a5.277 5.277 0 0 0 7.43 0 5.49 5.49 0 0 0 1.242-1.97.75.75 0 0 0-.468-.96c-.375-.14-.82.07-.961.469-.164.515-.47.984-.868 1.406a3.791 3.791 0 0 1-5.32 0l-.398-.422H3.75c.398 0 .75-.328.75-.75a.771.771 0 0 0-.75-.75h-3c-.047 0-.07.023-.117.023-.024 0-.07 0-.094.024Z" />
    </svg>
  );
};

export default RefreshIcon;
