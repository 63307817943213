import React from 'react';
import styled from 'styled-components';
import { TextBody, TextH3, TextSmall } from 'shared/react/components/complex/Text';

const TemplateRightSection = ({ selectedTemplate, hasExplanation, getFooter, isMobile }) => {
  return (
    <RightPanel hasExplanation={hasExplanation}>
      {hasExplanation && (
        <ExplanationContainer>
          <ExplanationTitle>{selectedTemplate.explanation.title}</ExplanationTitle>
          <ExplanationDescription>
            {selectedTemplate.explanation.description} <br />
          </ExplanationDescription>
          {!isMobile && getFooter()}
          {selectedTemplate.explanation.recommendation && (
            <Recommendation>{selectedTemplate.explanation.recommendation}</Recommendation>
          )}
        </ExplanationContainer>
      )}
    </RightPanel>
  );
};

const RightPanel = styled.div`
  display: flex;
  flex-direction: row;
  grid-row: ${({ hasExplanation }) => (hasExplanation ? 1 : 2)};
  grid-column: 2;
  column-gap: 40px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    grid-column: 1;
    flex-direction: column-reverse;
  }
`;

const ExplanationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    padding: 16px 20px 8px 20px;
  }
`;

const ExplanationTitle = styled(TextH3)`
  font-weight: bold;
  max-width: fit-content;
`;

const ExplanationDescription = styled(TextBody)`
  max-width: 320px;
  font-weight: 400;
`;

const Recommendation = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.ghostDark};
  max-width: 320px;
`;

export default TemplateRightSection;
