import {
  addProductToWishlist,
  getWishlist,
  removeProductFromWishlist,
  isCustomerLoggedIn,
} from '../utils/wishlist.utils';
import Utils from 'shared/react/utils/utils';

const useWishlist = () => {
  let customerWishlist = [];
  let getCustomerWishlistRetryCount = 0;

  const actions = {
    handleAddProductToWishlist: async (productId, vodAssetId, appKey) => {
      try {
        customerWishlist = (await actions.getCustomerWishlist()) || [];
      } catch (error) {
        Utils.logError('Error getting customer wishlist', error);
      }
      try {
        await addProductToWishlist(productId, vodAssetId, appKey);
        document.dispatchEvent(
          new CustomEvent('status:item:save', {
            detail: {
              productId: productId,
            },
          })
        );
        customerWishlist.push({ externalProductId: productId, vodAssetId });
        localStorage.setItem('customerWishlist', JSON.stringify(customerWishlist));
      } catch (error) {
        Utils.logError('Error adding product to wishlist', error);
      }
    },

    handleRemoveProductFromWishlist: async productId => {
      try {
        customerWishlist = await actions.getCustomerWishlist();
      } catch (error) {
        Utils.logError('Error getting customer wishlist', error);
      }

      try {
        await removeProductFromWishlist(productId);
        customerWishlist = customerWishlist.filter(item => item.externalProductId !== productId);
        localStorage.setItem('customerWishlist', JSON.stringify(customerWishlist));
      } catch (error) {
        Utils.logError('Error removing product from wishlist', error);
      }
    },

    setCustomerWishlist: async () => {
      customerWishlist = await getWishlist();
      if (customerWishlist && Array.isArray(customerWishlist)) {
        localStorage.setItem('customerWishlist', JSON.stringify(customerWishlist));
      }
    },

    getCustomerWishlist: async () => {
      customerWishlist = localStorage.getItem('customerWishlist');
      if (customerWishlist) {
        customerWishlist = JSON.parse(customerWishlist);
      } else {
        getCustomerWishlistRetryCount++;
        if (getCustomerWishlistRetryCount < 3) {
          await actions.setCustomerWishlist();
          return actions.getCustomerWishlist();
        }
      }
      return customerWishlist;
    },

    isProductInWishlist(productId) {
      if (!isCustomerLoggedIn()) {
        return false;
      }
      actions.getCustomerWishlist();
      return customerWishlist?.some(item => item.externalProductId === productId);
    },
  };

  return [customerWishlist, actions];
};

export default useWishlist;
