import React from 'react';

const CursorIcon = () => {
  return (
    <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.5 1.25v11.563c0 .406.281.687.688.687a.622.622 0 0 0 .5-.219l2.593-2.969 1.813 3.657a.99.99 0 0 0 1.343.437.99.99 0 0 0 .438-1.344L6.094 9.5H9.78c.406 0 .719-.281.719-.688a.653.653 0 0 0-.25-.5L1.687.689C1.563.594 1.375.5 1.22.5.812.5.5.844.5 1.25Z"
        fill="#FFC728"
      />
    </svg>
  );
};

export default CursorIcon;
