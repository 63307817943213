import React, { useState } from 'react';
import styled from 'styled-components';
import StandardModal from 'app/src/pages/modals/StandardModal';
import { useResponses } from 'app/src/context/ResponsesStore';

const PdfResponse = ({ response, children }) => {
  const [, { getImageResponseUrl }] = useResponses();
  const pdfUrl = getImageResponseUrl(response);
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <LayoutRoot onClick={() => setModalOpen(!isModalOpen)}>
      {children}
      <PdfPreview src={pdfUrl} type="application/pdf" />
      <StandardModal
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        width="66vw"
        height="90vh"
      >
        <PdfViewer src={pdfUrl} type="application/pdf" />
      </StandardModal>
    </LayoutRoot>
  );
};

export default PdfResponse;

const LayoutRoot = styled.div`
  cursor: pointer;
`;

const PdfPreview = styled.embed`
  width: 100%;
  height: 250px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.black};
  pointer-events: none;
`;

const PdfViewer = styled.embed`
  height: 100%;
  border-radius: 10px;
  margin: 16px;
  border: 1px solid ${({ theme }) => theme.colors.ghostLight};
`;
