import React from 'react';

const ProductsIcon = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.125 5.813c0-.508.39-.938.938-.938A.95.95 0 0 1 5 5.813c0 .546-.43.937-.938.937-.546 0-.937-.39-.937-.938ZM7.969 1.75c.468 0 .976.234 1.328.586l6.719 6.719a2.523 2.523 0 0 1 0 3.554l-5.196 5.196a2.523 2.523 0 0 1-3.554 0l-6.72-6.72A1.929 1.929 0 0 1 0 9.759V3.625C0 2.609.82 1.75 1.875 1.75h6.094Zm-6.563 8.438 6.758 6.757c.469.47 1.29.47 1.758 0l5.234-5.234c.469-.469.469-1.29 0-1.758L8.398 3.195A.627.627 0 0 0 7.968 3H1.876a.642.642 0 0 0-.625.625v6.133c0 .156.04.312.156.43Zm10.625-8.243c.235-.234.625-.234.899 0l5.508 5.274c2.07 1.953 2.07 5.273 0 7.226l-4.883 4.649a.613.613 0 0 1-.86 0c-.234-.274-.234-.664 0-.899l4.883-4.648a3.797 3.797 0 0 0 0-5.43l-5.547-5.273c-.234-.235-.234-.625 0-.899Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default ProductsIcon;
