import React from 'react';
import styled, { useTheme } from 'styled-components';
import { TextBody } from 'shared/react/components/complex/Text';

const SelectedAnswer = ({ response, userAlreadyReplied }) => {
  const theme = useTheme();

  if (!response.value || userAlreadyReplied) {
    return null;
  }

  return (
    <StyledSelectedAnswer>
      <TextBody title={response.value} textColor={theme.colors.success}>
        {response.value}
      </TextBody>
    </StyledSelectedAnswer>
  );
};

export default SelectedAnswer;

const StyledSelectedAnswer = styled.div`
  background-color: ${({ theme }) => theme.colors.successLight};
  padding: 8px 16px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  > * {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
