export const EXTERNAL_EVENTS_TO_SUBSCRIBE = {
  addToCart: 'tolstoy_add_to_cart',
  spotlightCarouselQuickShopClick: 'tolstoy_spotlight_carousel_quick_shop_click',
  productCardClick: 'tolstoy_product_card_click'
};

export const EXTERNAL_EVENTS_TO_POST = {
  addToCartSuccess: 'tolstoy_add_to_cart_success',
  addToCartError: 'tolstoy_add_to_cart_error',
  rejectCookiePolicy: 'tolstoy_reject_cookie_policy',
  tolstoyZIndexChange: 'tolstoy_z_index_change',
  urlLocaleUpdate: 'tolstoy_url_locale_update',
  productUpdate: 'tolstoy_product_update'
};

export const ALLOWED_EXTERNAL_EVENTS_TO_SUBSCRIBE = Object.values(EXTERNAL_EVENTS_TO_SUBSCRIBE);

export const ALLOWED_EXTERNAL_EVENTS_TO_POST = new Set([
  EXTERNAL_EVENTS_TO_POST.addToCartSuccess,
  EXTERNAL_EVENTS_TO_POST.addToCartError,
  EXTERNAL_EVENTS_TO_POST.rejectCookiePolicy,
  EXTERNAL_EVENTS_TO_POST.tolstoyZIndexChange,
  EXTERNAL_EVENTS_TO_POST.urlLocaleUpdate,
  EXTERNAL_EVENTS_TO_POST.productUpdate
]);
