import { useState, useEffect, useRef } from 'react';
import { postRequest } from 'app/src/helpers/API';
import { FEATURE_TOLY_CHATBOT } from 'app/src/constants/appFeatures.constants';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { useTolyChat } from 'app/src/context/TolyChatStore';
import { openIntercom } from './specialActions';
import Utils from 'src/utils';

const useTolyChatBotLogic = () => {
  const [, { getFeatureEnabled }] = useFeatures();
  const isTolyChatBotEnabled = getFeatureEnabled(FEATURE_TOLY_CHATBOT);
  const [state, actions] = useTolyChat();
  const [userInput, setUserInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [isButtonContainerVisible, setButtonContainerVisible] = useState(true);
  const [showSuggestionButtons, setShowSuggestionButtons] = useState(true);
  const [showTalkToSupport, setShowTalkToSupport] = useState(true);
  const [isImageModalOpen, setImageModalOpen] = useState(false);
  const [currentImageSrc, setCurrentImageSrc] = useState('');
  const [hasSentDefaultMessage, setHasSentDefaultMessage] = useState(false);
  const [hasUserSentMessage, setHasUserSentMessage] = useState(false);

  const chatOutputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isTolyChatBotEnabled) {
      window.intercomSettings = {
        hide_default_launcher: true,
      };
      window.Intercom?.('boot');
    }
  }, [isTolyChatBotEnabled]);

  const openImageModal = src => {
    setCurrentImageSrc(src);
    setImageModalOpen(true);
  };

  const closeImageModal = () => {
    setImageModalOpen(false);
    setCurrentImageSrc('');
  };

  const scrollToBottom = () => {
    chatOutputRef.current?.scrollTo({
      top: chatOutputRef.current.scrollHeight,
      behavior: 'smooth',
    });
  };

  const sendDefaultMessage = async () => {
    if (hasSentDefaultMessage) return;

    setIsTyping(true);
    const defaultMessage = state.defaultMessage;
    try {
      const response = await postRequest('open-ai', `/open-ai/chat`, {
        body: { question: defaultMessage, threadId: state.threadId },
      });

      if (response) {
        actions.addToConversation({ role: 'ai', content: response.answer });
        await handleAIResponse(response);
      }
    } catch (error) {
      console.error('Error in sendDefaultMessage:', error);

      actions.addToConversation({
        role: 'ai',
        content: "Hi, I'm Toly your Tolstoy Assistant! How can I assist you today?",
      });
    } finally {
      setIsTyping(false);
    }

    setHasSentDefaultMessage(true);
  };

  const handleSuggestionClick = async message => {
    setShowSuggestionButtons(false);
    if (message !== 'Talk to Support') {
      setShowTalkToSupport(false);
    }

    actions.addToConversation({ role: 'user', content: message });

    setIsTyping(true);

    try {
      const response = await postRequest('open-ai', `/open-ai/chat`, {
        body: { question: message, threadId: state.threadId },
      });

      setIsTyping(false);

      if (response) {
        actions.addToConversation({ role: 'ai', content: response.answer });
        await handleAIResponse(response);
      }
    } catch (error) {
      console.error('Error in handleSuggestionClick:', error);
      setIsTyping(false);
      actions.addToConversation({
        role: 'ai',
        content:
          "Aw snap! 🙈 I didn't quite get that. Can you repeat the question, or would you like me to connect to support team?",
      });
    }
  };

  useEffect(() => {
    const lastUserMessage = state.conversationHistory
      .slice()
      .reverse()
      .find(m => m.role === 'user');

    if (lastUserMessage && lastUserMessage.content !== 'Talk to Support') {
      const timeoutId = setTimeout(() => {
        setShowTalkToSupport(true);
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [state.conversationHistory]);

  useEffect(() => {
    scrollToBottom();
  }, [state.conversationHistory]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput(event.target.value);
  };

  const handleAIResponse = async data => {
    if (data.functionName === 'openIntercom') {
      openIntercom(actions.setModalVisible, setButtonContainerVisible);
    }
    if (data.threadId && !state.threadId) {
      actions.setThreadId(data.threadId);
    }
  };

  const getAIResponse = async () => {
    try {
      const response = await postRequest('open-ai', `/open-ai/chat`, {
        body: { question: userInput, threadId: state.threadId },
      });
      setIsTyping(false);
      return response;
    } catch (error) {
      console.error('Error in getAIResponse:', error);
      return {
        answer:
          "Aw snap! 🙈 I didn't quite get that. Can you repeat the question, or would you like me to connect to support team?",
      };
    }
  };

  const sendQuestion = async () => {
    if (userInput.trim()) {
      actions.addToConversation({ role: 'user', content: userInput });
      setUserInput('');

      setIsTyping(true);

      setShowSuggestionButtons(false);

      const response = await getAIResponse();
      setShowTalkToSupport(true);
      setHasUserSentMessage(true);

      setIsTyping(false);

      if (response) {
        actions.addToConversation({ role: 'ai', content: response.answer });
        await handleAIResponse(response);
      }
    }
  };

  useEffect(() => {
    if (Utils.isNullOrUndefined(state.initialUserInput)) {
      return;
    }

    setUserInput(state.initialUserInput);
  }, [state.initialUserInput]);

  return {
    shouldRender: isTolyChatBotEnabled,
    sendDefaultMessage,
    isModalVisible: state.isModalVisible,
    toggleModal: actions.toggleModal,
    userInput,
    handleInputChange,
    sendQuestion,
    isTyping,
    isButtonContainerVisible,
    isLogoOpen: state.isLogoOpen,
    state,
    chatOutputRef,
    showSuggestionButtons,
    handleSuggestionClick,
    showTalkToSupport,
    isImageModalOpen,
    currentImageSrc,
    openImageModal,
    closeImageModal,
    hasSentDefaultMessage,
    hasUserSentMessage,
  };
};

export default useTolyChatBotLogic;
