import React from 'react';
import styled from 'styled-components';
import { useAnalytics } from 'app/src/context/AnalyticsStore';
import AnalyticsTable from '../AnalyticsTable';
import AnalyticsTolstoyStep from '../AnalyticsTolstoyStep';
import AnalyticsTolstoyStepAnswer from '../AnalyticsTolstoyStepAnswer';
import { useAnalyticsProjects } from 'app/src/complex_components/analytics/useAnalyticsProjects';

const numberComparator = (v1, v2) => {
  return +v1 - +v2;
};

const columns = [
  {
    field: 'stepName',
    headerName: 'Part',
    flex: 1,
    renderCell: AnalyticsTolstoyStep,
  },
  {
    field: 'answerKey',
    headerName: 'Answer',
    flex: 1,
    renderCell: AnalyticsTolstoyStepAnswer,
  },
  {
    field: 'clicks',
    headerName: 'Click',
    flex: 1,
    sortComparator: numberComparator,
  },
];

const feedColumns = [
  {
    field: 'videoName',
    headerName: 'Video name',
    flex: 1,
    renderCell: AnalyticsTolstoyStep,
  },
  {
    field: 'count',
    headerName: 'Number of views',
    flex: 1,
    renderCell: AnalyticsTolstoyStepAnswer,
  },
  {
    field: 'totalTimeAverage',
    headerName: 'Average time',
    flex: 1,
    sortComparator: numberComparator,
  },
];

const AnalyticsEngagementTolstoyClicksTable = () => {
  const [{ engagementTolstoyClicks = [], engagementFeedClicks = [] }, { shouldShowFeedTable }] =
    useAnalytics();
  const { selectedProjects } = useAnalyticsProjects();

  const hasFeedProject = selectedProjects?.some(project => project.feed);
  if (hasFeedProject) {
    if (!shouldShowFeedTable()) {
      return null;
    }

    return (
      <StyledAnalyticsEngagementTolstoyClicksTable>
        <AnalyticsTable rows={engagementFeedClicks} columns={feedColumns} title="Videos Watched" />
      </StyledAnalyticsEngagementTolstoyClicksTable>
    );
  }

  return (
    <StyledAnalyticsEngagementTolstoyClicksTable>
      <AnalyticsTable rows={engagementTolstoyClicks} columns={columns} title="Answers Collected" />
    </StyledAnalyticsEngagementTolstoyClicksTable>
  );
};

export default AnalyticsEngagementTolstoyClicksTable;

const StyledAnalyticsEngagementTolstoyClicksTable = styled.div`
  grid-area: clicks;
`;
