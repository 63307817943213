import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Snackbar as CustomSnackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import styled from 'styled-components';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';
import {
  SNACK_BAR_TEST_ID,
  SUCCESS_SNACK_BAR_TEST_ID,
} from 'shared/react/constants/dataTestIds.constants';

const GlobalSnackBar = ({
  alertProps,
  verticalPosition,
  horizontalPosition,
  autoHideDuration = 3000,
  ...props
}) => {
  const [{ snackbarSeverity, snackbarText, snackbarOpen, hideDuration }, { setSnackbar }] =
    useSnackBar();

  const closeSnackBar = () => {
    setSnackbar('', snackbarSeverity);
  };

  const dataTestId = snackbarSeverity === 'success' ? SUCCESS_SNACK_BAR_TEST_ID : SNACK_BAR_TEST_ID;

  return (
    <CustomSnackbar
      anchorOrigin={{
        vertical: verticalPosition || 'top',
        horizontal: horizontalPosition || 'center',
      }}
      autoHideDuration={hideDuration || autoHideDuration}
      onClose={closeSnackBar}
      data-test-id={dataTestId}
      open={snackbarOpen}
      {...props}
    >
      <Alert
        iconMapping={{ success: <SuccessIcon fontSize="inherit" /> }}
        onClose={closeSnackBar}
        severity={snackbarSeverity}
      >
        {snackbarText}
      </Alert>
    </CustomSnackbar>
  );
};

const SuccessIcon = styled(CheckCircleIcon)`
  fill: ${({ theme }) => theme.colors.success};
`;

export default GlobalSnackBar;
