import React from 'react';
import styled from 'styled-components';
import Answer from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/default/Answer';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';

const DefaultButtons = ({ answers, project, onChooseAnswerNext }) => {
  return (
    <LayoutRoot>
      <AnswersContainer>
        {answers.map((answer, i) => {
          return (
            <Answer
              answer={answer}
              index={i}
              key={i}
              stepsOrder={project.stepsOrder}
              onChooseAnswerNext={onChooseAnswerNext}
            />
          );
        })}
      </AnswersContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)``;

const AnswersContainer = styled(VerticalFlex)``;

export default DefaultButtons;
