import { UPLOAD } from 'app/src/constants/recordingTypes.constants';
import { EQUALS_KEY, NOT_EQUALS_KEY } from 'app/src/constants/ruleGroups.constants';
import { SOURCES_UPLOAD_TYPE_LIST } from 'app/src/constants/videoSources.constants.js';
import { TIKTOK, GOOGLE_DRIVE, INSTAGRAM_GRAPH } from 'app/src/constants/intergrations.constants';
import { AI_VIDEO, LIBRARY, UPLOAD_FILE } from 'app/src/constants/creation_method.constants';
import { DynamicRuleTypes } from '../../constants/dynamicConditions.constants';
import { assetType } from 'src/types/entities';

export const DEFAULT_MAX_VISIBLE_PLAYLISTS = 20;

export const DEFAULT_GROUP_NAME = 'Other';
export const GROUP_NAMES_BY_PLAYLIST_TYPE = {
  [DynamicRuleTypes.vodLabel]: 'Custom Playlists',
  [DynamicRuleTypes.vodSource]: 'Source',
};

export const PLAYLIST_TAG_MODES = {
  OUTLINE: 'outline',
  FILLED: 'filled',
  WARNING_FILLED: 'warning_filled',
  GRAYED_OUT: 'grayed_out',
};

export const CONDITION_PLAYLIST_MODE = {
  [EQUALS_KEY]: PLAYLIST_TAG_MODES.FILLED,
  [NOT_EQUALS_KEY]: PLAYLIST_TAG_MODES.WARNING_FILLED,
};

export const PRODUCT_TAGGED_PLAYLIST = {
  value: true,
  condition: EQUALS_KEY,
  name: 'Product tagged',
  type: DynamicRuleTypes.taggedProduct,
};

export const ALL_IMAGES_PLAYLIST = {
  value: assetType.image,
  condition: EQUALS_KEY,
  name: 'All images',
  type: DynamicRuleTypes.vodType,
};

export const TRENDING_VIDEOS_PLAYLIST = {
  value: true,
  condition: EQUALS_KEY,
  name: 'Trending Videos',
  type: DynamicRuleTypes.trending,
};

export const SOURCE_PLAYLISTS_LABELS = {
  [UPLOAD]: 'Uploaded',
  [INSTAGRAM_GRAPH]: 'Instagram',
  [GOOGLE_DRIVE]: 'Google Drive',
  [TIKTOK]: 'TikTok',
  [AI_VIDEO]: 'AI Videos',
};

export const SOURCE_PLAYLIST_CTA_TEXT = 'Connect';
export const SOURCE_PLAYLISTS_WITH_CTA_TEXT = [INSTAGRAM_GRAPH, GOOGLE_DRIVE, TIKTOK];

export const VOD_SOURCE_CONDITION_OPTIONS = SOURCES_UPLOAD_TYPE_LIST.flatMap(source => {
  const name = SOURCE_PLAYLISTS_LABELS[source];

  if (!name) {
    return [];
  }

  return {
    name,
    value: source,
    type: DynamicRuleTypes.vodSource,
  };
});

export const PLAYLIST_TYPE_VIDEOS_MODAL_PAGE = {
  [UPLOAD]: UPLOAD_FILE,
  [TIKTOK]: LIBRARY,
  [GOOGLE_DRIVE]: LIBRARY,
  [INSTAGRAM_GRAPH]: LIBRARY,
  [AI_VIDEO]: LIBRARY,
};
