import React from 'react';
import Tooltip from 'src/basic_components/tooltip-v2/Tooltip';

type Props = {
  children: React.ReactNode;
  isAccountInstallCompleted?: boolean;
};

const AccountInstallNotCompletedTooltip = ({
  children,
  isAccountInstallCompleted = false,
}: Props) => {
  const tooltipPlacement = 'bottom';
  const tooltipText =
    'You need to complete the account installation before you can move on to these Tolstoy Installation process';

  return (
    <Tooltip text={tooltipText} placement={tooltipPlacement} hidden={isAccountInstallCompleted}>
      {children}
    </Tooltip>
  );
};

export default AccountInstallNotCompletedTooltip;
