import React from 'react';
import styled from 'styled-components';
import {
  CAROUSEL_TILE_NAME_LOCATION_TYPES,
  CAROUSEL_TITE_NAME_BACKGROUND_COLOR_PROPERTY_KEY,
  CAROUSEL_TITE_NAME_BACKGROUND_ENABLED_PROPERTY_KEY,
  CAROUSEL_TITE_NAME_BACKGROUND_TRANSPARANCY_PROPERTY_KEY,
  CAROUSEL_TITE_NAME_ENABLED_PROPERTY_KEY,
  CAROUSEL_TITE_NAME_LOCATION_PROPERTY_KEY,
  CAROUSEL_TITE_NAME_TEXT_COLOR_PROPERTY_KEY,
  CAROUSEL_TITE_NAME_TEXT_SIZE_PROPERTY_KEY,
} from '../../../LookAndFeelPage.constants';
import { useLookAndFeelContext } from '../../../LookAndFeelContext';
import EditorHeader from '../shared/EditorHeader';
import EditorMainContainer from '../shared/EditorMainContainer';
import OnOffToggleItem from '../shared/OnOffToggleItem';
import EditorButtonGroupItem from '../shared/EditorButtonGroupItem';
import EditorSelect from '../shared/EditorSelect';
import EditorColorInputItem from '../shared/EditorColorInputItem';
import EditorOnOffToggleItem from '../shared/EditorOnOffToggleItem';
import EditorSliderItem from '../shared/EditorSliderItem';
import Separator from 'shared/react/components/basic/Separator';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Utils from 'shared/react/utils/utils';

const fontSizes = Utils.range(12, 40);

const FeedCarouselTileNameEditor = ({ disabled }) => {
  const {
    customizationSettings: {
      widgetSettings: {
        carouselTileNameEnabled,
        carouselTileNameLocation,
        carouselTileNameTextSize,
        carouselTileNameTextColor,
        carouselTileNameBackgroundEnabled,
        carouselTileNameBackgroundColor,
        carouselTileNameBackgroundTransparancy,
      },
    },
    setWidgetSettingsProperty,
  } = useLookAndFeelContext();

  const isTileNameUnderVideo = carouselTileNameLocation === CAROUSEL_TILE_NAME_LOCATION_TYPES.under;
  const isTileNameOverVideo = carouselTileNameLocation === CAROUSEL_TILE_NAME_LOCATION_TYPES.over;

  const onEnableTile = () => {
    setWidgetSettingsProperty(CAROUSEL_TITE_NAME_ENABLED_PROPERTY_KEY, !carouselTileNameEnabled);
  };

  const onLocationClick = locationType => {
    setWidgetSettingsProperty(CAROUSEL_TITE_NAME_LOCATION_PROPERTY_KEY, locationType);
  };

  const onTextSizeChange = ({ id }) => {
    setWidgetSettingsProperty(CAROUSEL_TITE_NAME_TEXT_SIZE_PROPERTY_KEY, id);
  };

  const onTextColorChange = value => {
    setWidgetSettingsProperty(CAROUSEL_TITE_NAME_TEXT_COLOR_PROPERTY_KEY, value);
  };

  const onEnableBackground = () => {
    setWidgetSettingsProperty(
      CAROUSEL_TITE_NAME_BACKGROUND_ENABLED_PROPERTY_KEY,
      !carouselTileNameBackgroundEnabled
    );
  };

  const onBackgroundColorChange = value => {
    setWidgetSettingsProperty(CAROUSEL_TITE_NAME_BACKGROUND_COLOR_PROPERTY_KEY, value);
  };

  const onTransparancyChange = (event, value) => {
    setWidgetSettingsProperty(CAROUSEL_TITE_NAME_BACKGROUND_TRANSPARANCY_PROPERTY_KEY, value);
  };

  const locationButtons = [
    {
      text: <CapitalizedText>{CAROUSEL_TILE_NAME_LOCATION_TYPES.under}</CapitalizedText>,
      isEnabled: isTileNameUnderVideo,
      onClick: () => onLocationClick(CAROUSEL_TILE_NAME_LOCATION_TYPES.under),
    },
    {
      text: <CapitalizedText>{CAROUSEL_TILE_NAME_LOCATION_TYPES.over}</CapitalizedText>,
      isEnabled: isTileNameOverVideo,
      onClick: () => onLocationClick(CAROUSEL_TILE_NAME_LOCATION_TYPES.over),
    },
  ];

  return (
    <LayoutRoot>
      <EditorHeader
        title="Tile name"
        disabled={disabled}
        proFeature
        isWhite={!carouselTileNameEnabled}
      >
        <OnOffToggleItem
          checked={carouselTileNameEnabled}
          onChange={onEnableTile}
          disabled={disabled}
        />
      </EditorHeader>
      <EditorMainContainer shouldHide={!carouselTileNameEnabled}>
        <EditorButtonGroupItem text="Location" buttons={locationButtons} />
        <Separator />
        <SmallerEditorSelect
          onChange={onTextSizeChange}
          text="Text size"
          value={carouselTileNameTextSize}
          items={fontSizes}
          disabled={disabled}
        />
        <EditorColorInputItem
          text="Text color"
          color={carouselTileNameTextColor}
          onChange={onTextColorChange}
          disabled={disabled}
        />
        {isTileNameOverVideo && <Separator />}
        {isTileNameOverVideo && (
          <EditorOnOffToggleItem
            text="Background"
            checked={carouselTileNameBackgroundEnabled}
            onChange={onEnableBackground}
          />
        )}
        {isTileNameOverVideo && carouselTileNameBackgroundEnabled && (
          <EditorColorInputItem
            text="Color"
            color={carouselTileNameBackgroundColor}
            onChange={onBackgroundColorChange}
            disabled={disabled}
          />
        )}
        {isTileNameOverVideo && carouselTileNameBackgroundEnabled && (
          <EditorSliderItem
            onChange={onTransparancyChange}
            text={'Transparency'}
            value={carouselTileNameBackgroundTransparancy}
            min={0}
            step={5}
            max={100}
            disabled={disabled}
          />
        )}
      </EditorMainContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

const SmallerEditorSelect = styled(EditorSelect)`
  max-width: 80px;
`;

const CapitalizedText = styled.span`
  text-transform: capitalize;
`;

export default FeedCarouselTileNameEditor;
