export const SIGNUP_REGEX = [
  { regex: '(.*[a-z].*)', message: 'Your password must have lowercase characters.' },
  { regex: '(.*[A-Z].*)', message: 'Your password must have uppercase characters.' },
  { regex: '(.*[0-9].*)', message: 'Your password must have numeric characters.' },
  {
    regex: '[`!@!#$%^&*()_+\\-=\\[\\]{};\':"\\\\|,.<>\\/?~]',
    message: 'Your password must have symbol characters.',
  },
  { regex: '.{8,}', message: 'Minimum password length is 8 characters.' },
];
