import React from 'react';
import { useTheme } from 'styled-components';

function XIcon({ size, fill }) {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || '24'}
      height={size || '24'}
      viewBox="0 0 18 18"
    >
      <path
        fill={fill || theme.colors.blue2}
        d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
      />
    </svg>
  );
}

export default XIcon;
