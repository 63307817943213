import React from 'react';
import styled from 'styled-components';
import CreateNewTolstoyButton from 'app/src/pages/create_new_tolstoy/CreateNewTolstoyButton';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextH5 } from 'shared/react/components/complex/Text';

const EmptyStateBody = () => {
  return (
    <LayoutRoot>
      <StyledTextH5>
        Hey there, you have no Tolstoys yet <br /> Let’s do it now.
      </StyledTextH5>
      <CreateNewTolstoyButton location="Dashboard Empty State" />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  gap: 32px;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const StyledTextH5 = styled(TextH5)`
  font-weight: 800;
`;

export default EmptyStateBody;
