import React, { useEffect, useState } from 'react';
import { useAnalytics } from 'src/context/AnalyticsStore';
import { useDrawer } from 'src/context/ui_store/DrawerStore';
import styled from 'styled-components';
import JourneyDrawerHeader from './JourneyDrawerHeader';
import Separator from 'shared/react/components/basic/Separator';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import JourneyDrawerContent from './journey-drawer-content/JourneyDrawerContent';
import { getJourneySummary } from './journeyDrawer.utils';

const JourneyDrawer = () => {
  const [{ dateFilter }, { getViewerJourney }] = useAnalytics();
  const [{ drawerProps }] = useDrawer();
  const [isLoading, setIsLoading] = useState(false);
  const [journeysSteps, setJourneysSteps] = useState([]);
  const [journeySummary, setJourneySummary] = useState([]);
  const { email } = drawerProps;

  const fetchJourneyMetrics = async () => {
    setIsLoading(true);

    const result = await getViewerJourney(email);

    setJourneysSteps(result.reverse());
    setJourneySummary(getJourneySummary({ journeysSteps: result }));

    setIsLoading(false);
  };

  useEffect(() => {
    if (!email) {
      return;
    }

    fetchJourneyMetrics();
  }, [email, dateFilter]);

  return (
    <LayoutRoot>
      <JourneyDrawerHeader email={email} journeySummary={journeySummary} />
      <Separator />
      <JourneyDrawerContent isLoading={isLoading} journeySteps={journeysSteps} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)`
  width: 440px;
  height: 100%;
  overflow: hidden;
`;

export default JourneyDrawer;
