import React from 'react';
import useFeedFeatures from 'shared/react/components/complex/context/hooks/useFeedFeatures';
import useFeedState from 'shared/react/components/complex/context/hooks/useFeedState';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';
import useTracker from 'shared/react/components/complex/context/hooks/useTracker';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';
import FeedBrandedButton from 'shared/react/components/complex/feed/feed_buttons/FeedBrandedButton';
import FeedItemAdded from 'shared/react/components/complex/feed/feed_products/feed_buy_now_button/FeedItemAdded';
import { getFeedActionButtonOnClick } from 'shared/react/components/complex/feed/feed_utils/feedActionButton.utils';
import { FEATURE_FEED_OPEN_PDP_IN_SAME_TAB } from 'shared/react/constants/features.constants';
import {
  PRIMARY_DARK_BUTTON_TYPE,
  PRODUCT_CTA_TYPES,
  SHOPABLE_SIDE_PANEL_TYPE,
} from 'shared/react/constants/feed.constants';
import { URL_KEY } from 'shared/react/constants/generalKeys.constants';
import { TOLSTOY_CLOSE_MODAL_MESSAGE } from 'shared/react/constants/messages.constants';
import {
  ADD_TO_CART_ERROR_MESSAGE,
  ADD_TO_CART_SOLD_OUT_MESSAGE,
  ADD_TO_CART_SUCCESS_MESSAGE,
} from 'shared/react/constants/shopifyMessaging.constants';
import { isUrlProvider } from 'shared/react/utils/feedProducts.utils';
import UrlHelper from 'shared/react/utils/urlHelper';
import Utils from 'shared/react/utils/utils';
import { BUY_NOW_DATA_TEST_ID } from '../../../../../constants/dataTestIds.constants';
import { CART_CTA_TYPES, FENTY_QUICK_SHOP_TEXT } from '../../../../../constants/feed.constants';
import useFeedProductButton from '../../hooks/useFeedProductButton';
import { OPEN_KENDO_MODAL_MESSAGE } from '../../../../../constants/messages.constants';
import useIsFenty from 'shared/react/hooks/useIsFenty';

const FeedCtaButton = ({
  selectedVariantId,
  appUrl,
  currentStepKey,
  productHandle,
  ecomPlatform,
  onBuyNowClick,
  productId,
  buttonConfig = {},
  buttonType = PRIMARY_DARK_BUTTON_TYPE,
  isVariantAvailableForSale,
  quantityPickerState,
  handleProductFocused,
  externalProductId,
  isViewProduct,
  isMultiVariant,
  viewProductCtaSettings = {},
  isQuickShopEnabled,
  isMultipleProductDetails,
  ...props
}) => {
  const { enabled, ctaType, buttonText, value: customUrl, isOpenInANewTab } = buttonConfig;
  const { ctaWithVariants = {}, ctaWithoutVariants = {} } = viewProductCtaSettings;

  const [, { t }] = useTranslation('feed.buyNowButton');
  const [, { track }] = useTracker();
  const [{ publishId, isAppPreview }] = useProjectConfig();
  const [, { getFeatureSettingsByKey }] = useFeedFeatures();
  const [{ shopifyResponse, setShopifyResponse }] = useFeedState();
  const { isOpenPdpInSameTab } = getFeatureSettingsByKey(FEATURE_FEED_OPEN_PDP_IN_SAME_TAB);
  const isFenty = useIsFenty();
  const { markUserAndRedirect, handleAddToCart, handleOpenInTheSameTab, isLoading } =
    useFeedProductButton({
      selectedVariantId,
      appUrl,
      currentStepKey,
      productHandle,
      ecomPlatform,
      productId,
      ctaType,
      externalProductId,
    });

  const isAddToCart =
    (isFenty && isMultipleProductDetails) ||
    (!isMultipleProductDetails &&
      ctaType === PRODUCT_CTA_TYPES.addToCart &&
      !Utils.getIsLandingPage() &&
      !isViewProduct);
  const isOpenProductPage = ctaType === PRODUCT_CTA_TYPES.openProductPage;
  const isCustomUrl = ctaType === PRODUCT_CTA_TYPES.customUrl;
  const isClosePlayer = ctaType === PRODUCT_CTA_TYPES.closePlayer;
  const isUrl = isUrlProvider(ecomPlatform);
  const isCartCtaType = CART_CTA_TYPES.includes(ctaType);

  const clickCustomUrl = () => {
    const utmParams = UrlHelper.getUtmParams();
    const url = UrlHelper.appendSearchParamsToUrl(customUrl, utmParams);

    track(`${SHOPABLE_SIDE_PANEL_TYPE}ActionButtonClick`, { type: URL_KEY });
    getFeedActionButtonOnClick({ type: URL_KEY, value: url, isOpenInANewTab });
  };

  const onClick = () => {
    if (isQuickShopEnabled) {
      Utils.postMessageToWindow({
        eventName: OPEN_KENDO_MODAL_MESSAGE,
        productHandle,
        quantity: quantityPickerState?.quantity,
      });
      return;
    }

    if (isMultipleProductDetails && isFenty) {
      handleAddToCart();
      return;
    }

    if (isMultipleProductDetails) {
      handleProductFocused();
      return;
    }

    if (isViewProduct && isMultiVariant) {
      handleProductFocused();
      return;
    }

    if (isAppPreview) {
      return;
    }

    if (isOpenPdpInSameTab) {
      return handleOpenInTheSameTab();
    }

    if (onBuyNowClick) {
      onBuyNowClick();
      return;
    }

    if (isUrl) {
      return markUserAndRedirect('clickViewProduct');
    }

    if (isAddToCart) {
      return handleAddToCart(quantityPickerState?.quantity);
    }

    if (isOpenProductPage) {
      return markUserAndRedirect('openProductPageClick');
    }

    if (isCustomUrl && UrlHelper.isValidUrl(customUrl)) {
      return clickCustomUrl();
    }

    if (isClosePlayer) {
      return Utils.postMessageToWindow({ name: TOLSTOY_CLOSE_MODAL_MESSAGE, publishId });
    }

    return markUserAndRedirect('clickCart');
  };

  const getShopifyResponseButtonText = () => {
    const { eventName, variantId } = shopifyResponse;
    if (variantId !== selectedVariantId) {
      return getButtonText(true);
    }

    setTimeout(() => {
      setShopifyResponse(null);
    }, 5000);

    switch (eventName) {
      case ADD_TO_CART_SUCCESS_MESSAGE:
        return <FeedItemAdded />;
      case ADD_TO_CART_SOLD_OUT_MESSAGE:
        if (isFenty) {
          return `${t('soldOutShopify')}`.toUpperCase();
        }
        return t('soldOutShopify');
      case ADD_TO_CART_ERROR_MESSAGE:
        return t('errorAddingToCart');
    }
  };

  const getCartCtaText = () => {
    if (isFenty && isMultipleProductDetails) {
      return ctaWithVariants.buttonText || t('addToCart');
    }

    if (isMultipleProductDetails) {
      return ctaWithVariants.buttonText || t('viewProduct');
    }

    if (isViewProduct) {
      return ctaWithVariants.buttonText || t('viewProduct');
    }

    if (!isVariantAvailableForSale) {
      return t('soldOut');
    }

    return buttonText;
  };

  const getButtonText = ignoreShopifyResponse => {
    if (isQuickShopEnabled) {
      return FENTY_QUICK_SHOP_TEXT;
    }

    if (shopifyResponse && !ignoreShopifyResponse) {
      return getShopifyResponseButtonText();
    }

    if (isCartCtaType) {
      return getCartCtaText();
    }

    return buttonText;
  };

  if (!enabled) {
    return null;
  }

  const getIsButtonDisabled = () => {
    if (isCartCtaType) {
      return !isVariantAvailableForSale && !isUrl && !isViewProduct;
    }

    return false;
  };

  const isDisabled = getIsButtonDisabled();

  return (
    <FeedBrandedButton
      onClick={onClick}
      buttonText={getButtonText()}
      isLoading={isLoading()}
      isDisabled={isDisabled}
      dataTestId={BUY_NOW_DATA_TEST_ID}
      type={buttonType}
      isAddToCart={isAddToCart}
      quantityPickerState={quantityPickerState}
      isQuickShopEnabled={isQuickShopEnabled}
      {...props}
    />
  );
};

export default FeedCtaButton;
