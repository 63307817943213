import React from 'react';
import chart from './images/chart.png';
import table from './images/table.png';
import styled from 'styled-components';

const ENGAGEMENT_METRICS = [
  { subtitle: 'Total Revenue', number: '$38,562' },
  { subtitle: 'Avg. Order Value', number: '$45.56' },
  { subtitle: 'Played Tolstoy', number: '45%' },
];

const AnalyticsConversionEmptyState = () => {
  return (
    <LayoutRoot>
      <MetricsContainer>
        {ENGAGEMENT_METRICS.map(({ subtitle, number }) => (
          <Metric key={subtitle}>
            <MetricHeader>{subtitle}</MetricHeader>
            <MetricNumber>{number}</MetricNumber>
          </Metric>
        ))}
      </MetricsContainer>
      <ChartImage src={chart} />
      <ChartImage src={table} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  display: grid;
  gap: 50px;
`;

const MetricsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const Metric = styled.div`
  width: 236px;
  height: 168px;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.white};
`;

const MetricHeader = styled.div`
  color: ${({ theme }) => theme.colors.ghostDark};
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.0015em;
`;

const MetricNumber = styled.div`
  color: ${({ theme }) => theme.colors.gray27};
  font-weight: 300;
  font-size: 48px;
  line-height: 100%;
  filter: blur(8px);
`;

const ChartImage = styled.img`
  width: 100%;
`;

export default AnalyticsConversionEmptyState;
