import VideosTopBar from 'app/src/pages/dashboard/pages/videos/components/top-bar/VideosTopBar';
import VideosContent from 'app/src/pages/dashboard/pages/videos/components/videos-content/VideosContent';
import { PageMain } from 'app/src/pages/dashboard/components/page/Page';
import React from 'react';
import { LibraryPageProps } from 'app/src/pages/dashboard/pages/videos/components/library-pages/library-pages-types';
import {
  MEDIA_SOURCE_IMPORT_METHODS,
  MediaLibrarySource,
} from 'app/src/pages/dashboard/pages/videos/hooks/media/mediaLibraryUtils';
import { useOnSelectedVideosAction } from 'app/src/pages/dashboard/pages/videos/hooks/useOnSelectedVideosAction';
import { useInMemoryVideoFilters } from 'app/src/pages/dashboard/pages/videos/hooks/useInMemoryVideoFilters';
import { useGlobalVodAssets } from 'app/src/pages/dashboard/pages/videos/hooks/useGlobalVodAssets';
import { useGoogleDriveLibraryVideos } from 'app/src/pages/dashboard/pages/videos/hooks/media/useGoogleDriveLibraryVideos';

export const GoogleDriveLibraryPage = React.forwardRef(
  (
    {
      videosFilters,
      setVideosFilters,
      onVideoChoose,
      selectedVideos,
      setSelectedVideos,
      toggleSelected,
    }: LibraryPageProps,
    containerRef
  ) => {
    const method = MEDIA_SOURCE_IMPORT_METHODS[MediaLibrarySource.GOOGLE_DRIVE];

    const { videos, initialized, loadMore, isFetchingNextPage } = useGoogleDriveLibraryVideos();

    const { onSelectedVideosAction } = useOnSelectedVideosAction({
      videos,
      method,
      selectedVideos,
      setSelectedVideos,
    });

    const { globalVodAssets } = useGlobalVodAssets(videos);
    const { filteredVideos } = useInMemoryVideoFilters(globalVodAssets, videosFilters);

    return (
      <PageMain>
        <VideosTopBar
          isModal={!!onVideoChoose}
          videosFilters={videosFilters}
          setVideosFilters={setVideosFilters}
          onSelectedVideosAction={onSelectedVideosAction}
        />
        <VideosContent
          ref={containerRef}
          videos={filteredVideos}
          selectedVideos={selectedVideos}
          toggleSelected={toggleSelected}
          onSelectedVideosAction={onSelectedVideosAction}
          initialized={initialized}
          method={method}
          videosFilters={videosFilters}
          onVideoChoose={onVideoChoose}
          isLoading={isFetchingNextPage}
          onScrollEndCallback={loadMore}
        />
      </PageMain>
    );
  }
);
GoogleDriveLibraryPage.displayName = 'GoogleDriveLibraryPage';
