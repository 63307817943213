import React, { useMemo, useState } from 'react';
import AnalyticsTable from '../AnalyticsTable';
import styled from 'styled-components';
import { ANALYTICS_TABLE_KEYS } from 'app/src/constants/analytics.constants';
import { EmailRecipient } from 'app/src/services/cube/types/getEmailRecipients.types';
import { FEATURE_EXPORT_ANALYTICS } from 'shared/react/constants/features.constants';
import { track } from 'app/src/helpers/Tracker';
import { useDrawerActions } from 'app/src/context/ui_store/DrawerStore';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import {
  getAnalyticsEmailRecipientsColumns,
  HEADER_LEFT_ALIGN_CLASS_NAME,
} from './getAnalyticsEmailRecipientsColumns';
import {
  GlobalDrawerType,
  SetCurrentDrawerArgs,
} from 'app/src/complex_components/global_drawer/types/globalDrawer.types';

type Props = {
  emailRecipients: EmailRecipient[] | null;
  hideColumns?: string[];
  isLoadingEmailRecipients: boolean;
};

const AnalyticsEmailRecipientsTable = ({
  emailRecipients,
  hideColumns,
  isLoadingEmailRecipients,
}: Props) => {
  const { getFeatureEnabled } = useFeatureActions();
  const [isLoading, setLoading] = useState(false);
  const { setCurrentDrawer } = useDrawerActions();

  const isExportEnabled = getFeatureEnabled(FEATURE_EXPORT_ANALYTICS);

  const columns = useMemo(
    () =>
      getAnalyticsEmailRecipientsColumns({
        hideColumns,
      }),
    [hideColumns?.length]
  );

  const trackDownloadCsv = () => {
    const message = 'Email Recipients Export to CSV Clicked';
    track(message);
  };

  const handleRowClick = ({ row }) => {
    const drawerArgs = {
      currentDrawer: GlobalDrawerType.Journey,
      drawerProps: { email: row.email },
    } as SetCurrentDrawerArgs;

    setCurrentDrawer(drawerArgs);
  };

  return (
    <StyledAnalyticsTable
      columns={columns}
      headerHeight={48}
      isExportEnabled={isExportEnabled}
      isLoading={isLoading || isLoadingEmailRecipients}
      onDownloadCsv={trackDownloadCsv}
      onRowClick={handleRowClick}
      rowHeight={48}
      rows={emailRecipients || []}
      setLoading={setLoading}
      tableName={ANALYTICS_TABLE_KEYS.recipients}
      title={undefined}
    />
  );
};

const StyledAnalyticsTable = styled(AnalyticsTable)`
  height: 65vh;

  .MuiDataGrid-root {
    border-radius: 4px;
    box-shadow:
      0 2px 10px 0 rgba(50, 50, 93, 0.1),
      0 1px 3px -1px rgba(0, 0, 0, 0.05);
  }

  .MuiDataGrid-columnHeader {
    color: ${({ theme }) => theme.colors.neutralDarker};
  }

  .${HEADER_LEFT_ALIGN_CLASS_NAME} .MuiDataGrid-columnHeaderTitleContainer {
    justify-content: flex-start;
  }
`;

export default AnalyticsEmailRecipientsTable;
