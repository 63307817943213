import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import IconButton from 'app/src/complex_components/IconButton';
import { useApps } from 'app/src/context/AppsStore';
import VerticalMenuIcon from 'app/src/images/dashboard_v2/VerticalMenuIcon';
import Tooltip from 'app/src/basic_components/Tooltip';
import MenuItem from 'app/src/complex_components/MenuItem';
import { useQuizContext } from 'app/src/pages/project/pages/quiz/QuizContext';
import QuizMakeDefaultIcon from 'shared/react/images/quiz/QuizMakeDefaultIcon';
import QuizDeleteIcon from 'shared/react/images/quiz/QuizDeleteIcon';
import QuizFallbackIcon from 'shared/react/images/quiz/QuizFallbackIcon';
import { TextSmall } from 'shared/react/components/complex/Text';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import KlaviyoMailingListsMenu from 'app/src/pages/project/pages/quiz/quiz_result/KlaviyoMailingListsMenu';
import MenuWithContainer from 'shared/react/components/basic/MenuWithContainer';
import Utils from 'shared/react/utils/utils';

function QuizResultRightContainer({ icon, quizResult }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { onDeleteQuizResult, onSetFallback } = useQuizContext();
  const theme = useTheme();
  const { id: quizResultId, klaviyoListId } = quizResult;
  const [, { getIsKlaviyo }] = useApps();

  const menuItems = [
    {
      key: 'fallback',
      title: 'Set as fallback',
      componentIcon: <QuizMakeDefaultIcon />,
      onClick: () => onSetFallback(quizResult.id),
    },
    {
      key: 'delete',
      title: 'Delete',
      textColor: theme.colors.red6,
      componentIcon: <QuizDeleteIcon />,
      onClick: () => onDeleteQuizResult(quizResult.id),
    },
  ];

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  function handleOpenMenu(e) {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }

  return (
    <LayoutRoot onClick={Utils.stopPropagation}>
      {quizResult.isFallback && (
        <FallbackContainer>
          <QuizFallbackIcon />
          <FallbackText>Fallback</FallbackText>
        </FallbackContainer>
      )}
      {getIsKlaviyo() && (
        <KlaviyoMailingListsMenu quizResultId={quizResultId} chosenListId={klaviyoListId} />
      )}
      <IconButton onClick={handleOpenMenu}>
        <VerticalMenuIcon />
      </IconButton>
      {icon}
      <Menu open={!!anchorEl} onClose={handleCloseMenu} disableAutoFocusItem anchorEl={anchorEl}>
        {menuItems.map(menuItem => (
          <Tooltip key={menuItem.key} text={menuItem.tooltipTitle || ''} placement="right">
            <MenuItem
              key={menuItem.key}
              menuItem={{
                ...menuItem,
              }}
              onClose={handleCloseMenu}
            />
          </Tooltip>
        ))}
      </Menu>
    </LayoutRoot>
  );
}

export default QuizResultRightContainer;

const LayoutRoot = styled(Gap8HorizontalFlexWrap)`
  align-items: center;
  justify-content: flex-end;
`;

const FallbackContainer = styled(Gap8HorizontalFlexWrap)`
  align-items: center;
  margin: 8px;
`;

const FallbackText = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.black};
`;

const Menu = styled(MenuWithContainer)`
  padding: 4px 0;
`;
