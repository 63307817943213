import Routes from '../../helpers/Routes';
import { navigateToEmptyInbox } from '../../utils/navigation.utils';

export const getIsCompose = location => {
  return location?.state?.reply?.compose;
};

export const navigateToSession = (history, id, state = {}) => {
  const inboxWithSessionId = Routes.getDashboardV2ThreadsRoute(id);
  history.replace(inboxWithSessionId, state);
};

export const navigateToNextSession = (history, { sessions, session }) => {
  const nextSessionIndex = getNextSession({ sessions, session });

  if (!sessions[nextSessionIndex]) {
    return navigateToEmptyInbox(history);
  }

  return navigateToSession(history, sessions[nextSessionIndex].id, {});
};

const getNextSession = ({ sessions, session }) => {
  const sessionIndex = sessions.indexOf(session);
  if (sessions.length === sessionIndex + 1) {
    return sessionIndex - 1;
  }

  return sessionIndex + 1;
};

export const getInitials = name => {
  return name ? name.substring(0, 2).toUpperCase() : '';
};
