import { useQuery } from '@tanstack/react-query';
import { postRequest } from 'app/src/helpers/API';
import {
  SearchFilterName,
  SearchVodAssetsPageResponse,
  SearchVodAssetsRequestFilter,
  SortDirection,
  TypeFilterValues,
} from 'app/src/pages/dashboard/pages/videos/hooks/search/searchRequests';
import Utils from 'app/src/utils';

export type CountQueryFilter = {
  satisfies?: SearchVodAssetsRequestFilter[];
  notSatisfies?: SearchVodAssetsRequestFilter[];
};

export class CountQueryFilters {
  static videos = {
    satisfies: [{ name: SearchFilterName.type, filterValue: TypeFilterValues.video }],
  };
  static images = {
    satisfies: [{ name: SearchFilterName.type, filterValue: TypeFilterValues.image }],
  };
  static gallery = {
    satisfies: [{ name: SearchFilterName.type, filterValue: TypeFilterValues.gallery }],
  };
}

export const useVodAssetCountQuery = (searchFilters: CountQueryFilter) => {
  const appKey = Utils.getAppKey();

  const { data: itemsCount, isLoading } = useQuery({
    queryKey: ['getVodAssetsCount', appKey, searchFilters],
    queryFn: async () => {
      const request = {
        appKey,
        dateSort: SortDirection.desc,
        size: 0,
        from: 0,
        ...searchFilters,
      };
      const data: SearchVodAssetsPageResponse = await postRequest('vod-asset', '/search', {
        body: request,
      });

      return data.total;
    },
  });

  return { count: itemsCount || 0, isLoading };
};
