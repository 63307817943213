import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDebounce } from 'app/src/hooks/useDebounce';
import { DEBOUNCE_TIME } from 'app/src/constants/builder.constants';
import { TextSmall } from 'shared/react/components/basic/text/TextV2';
import { SearchInput } from 'app/src/basic_components/input/InputWithIcon';
import MenuWithContainer from 'shared/react/components/basic/MenuWithContainer';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import GroupSelectMenuItem, { Group, GroupItem } from './GroupSelectMenuItem';

export type GroupSelectMenuProps = {
  groups: Group[];
  anchorElement: any;
  withSearch?: boolean;
  searchPlaceholder?: string;

  $width?: number | string;
  $height?: number | string;
  $maxWidth?: number | string;
  $maxHeight?: number | string;

  onItemSelect?: (item?: GroupItem) => void;
  onDone?: () => void;
  onClose?: () => void;
};

export const GroupSelectMenu = ({
  groups,
  anchorElement,
  withSearch = false,
  searchPlaceholder = 'Search...',

  $width,
  $height,
  $maxWidth,
  $maxHeight,

  onItemSelect = () => {},
  onDone = () => {},
  onClose = () => {},
}: GroupSelectMenuProps) => {
  const [filteredGroups, setFilteredGroups] = useState(groups);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, DEBOUNCE_TIME);

  useEffect(() => {
    const newGroups = groups.flatMap(group => {
      const newItems = group.items.filter(item =>
        item.name.toLowerCase().includes(searchValue.toLowerCase())
      );

      if (!newItems.length) {
        return [];
      }

      return {
        ...group,
        items: newItems,
      };
    });

    setFilteredGroups(newGroups);
  }, [debouncedSearchValue, groups]);

  return (
    <Menu
      direction={{ vertical: 'bottom', horizontal: 'center' }}
      anchorEl={anchorElement}
      open={!!anchorElement}
      $width={$width}
      $height={$height}
      $maxWidth={$maxWidth}
      $maxHeight={$maxHeight}
      onClose={onClose}
    >
      {withSearch && (
        <SearchStyled
          value={searchValue}
          placeholder={searchPlaceholder}
          onChange={e => setSearchValue(e.target.value || '')}
          withoutBorder
        />
      )}
      <Content>
        {filteredGroups.map(group => (
          <GroupSelectMenuItem key={group.name} group={group} onItemSelect={onItemSelect} />
        ))}
      </Content>
      <ButtonContainer onClick={onDone}>
        <DoneButton>Done</DoneButton>
      </ButtonContainer>
    </Menu>
  );
};

const Menu = styled(MenuWithContainer)`
  ${({ $width }) => ($width ? `width: ${$width}px;` : '')}
  ${({ $height }) => ($height ? `height: ${$height}px;` : '')}
  ${({ $maxWidth }) => ($maxWidth ? `max-width: ${$maxWidth}px;` : '')}
  ${({ $maxHeight }) => ($maxHeight ? `max-height: ${$maxHeight}px;` : '')}

  padding: 4px;
  margin-top: 4px;
`;

const Content = styled(VerticalFlex)`
  flex: 1 1 100%;
  overflow-y: auto;
`;

const DoneButton = styled(TextSmall)`
  padding: 8px 0;
  color: ${({ theme }) => theme.colors.primary};
`;

const ButtonContainer = styled(HorizontalFlex)`
  border-top: 1px solid ${({ theme }) => theme.colors.neutralLighter};
  justify-content: center;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.neutralLightest};
  }

  &:hover ${DoneButton} {
    color: ${({ theme }) => theme.colors.primaryHover};
  }
`;

const SearchStyled = styled(SearchInput)`
  border: none;
`;

export default GroupSelectMenu;
