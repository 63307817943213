import React from 'react';

const CircledCheckmarkIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.3594 25.9375C17.8906 26.4062 17.0312 26.4062 16.5625 25.9375L11.5625 20.9375C11.0938 20.4688 11.0938 19.6094 11.5625 19.1406C12.0312 18.6719 12.8906 18.6719 13.3594 19.1406L17.5 23.2812L26.5625 14.1406C27.0312 13.6719 27.8906 13.6719 28.3594 14.1406C28.8281 14.6094 28.8281 15.4688 28.3594 15.9375L18.3594 25.9375ZM40 20C40 31.0938 31.0156 40 20 40C8.90625 40 0 31.0938 0 20C0 8.98438 8.90625 0 20 0C31.0156 0 40 8.98438 40 20ZM20 2.5C10.3125 2.5 2.5 10.3906 2.5 20C2.5 29.6875 10.3125 37.5 20 37.5C29.6094 37.5 37.5 29.6875 37.5 20C37.5 10.3906 29.6094 2.5 20 2.5Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default CircledCheckmarkIcon;
