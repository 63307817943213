import React from 'react';

const MovieIcon = () => (
  <svg width={12} height={11} viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5.25h-9C.656.25 0 .93 0 1.75v7.5c0 .844.656 1.5 1.5 1.5h9c.82 0 1.5-.656 1.5-1.5v-7.5c0-.82-.68-1.5-1.5-1.5ZM2.625 9.625H1.5a.37.37 0 0 1-.375-.375V7.562h1.5v2.063Zm0-3.188h-1.5V4.563h1.5v1.875Zm0-3h-1.5V1.75c0-.188.164-.375.375-.375h1.125v2.063ZM8.25 9.625h-4.5V6.062h4.5v3.563Zm0-4.688h-4.5V1.376h4.5v3.563Zm2.625 4.313c0 .21-.188.375-.375.375H9.375V7.562h1.5V9.25Zm0-2.813h-1.5V4.563h1.5v1.875Zm0-3h-1.5V1.376H10.5c.188 0 .375.188.375.375v1.688Z"
      fill="#50545E"
    />
  </svg>
);

export default MovieIcon;
