import React from 'react';
import EditorGroup from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorGroup';
import AppearanceEditor from 'app/src/pages/project/pages/look_and_feel/editor/editors/landing_page_editor/AppearanceEditor';
import FeaturesEditor from 'app/src/pages/project/pages/look_and_feel/editor/editors/landing_page_editor/FeaturesEditor';

function LandingPageEditorGroup() {
  return (
    <EditorGroup
      editors={[
        { key: 'appearance', component: <AppearanceEditor />, hidden: true },
        { key: 'features', component: <FeaturesEditor /> },
      ]}
    />
  );
}

export default LandingPageEditorGroup;
