/* eslint no-restricted-syntax: 0 */ // --> OFF
export const getLocalStorageItem = key => {
  try {
    return localStorage.getItem(key);
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const setLocalStorageItem = (key, value) => {
  try {
    localStorage.setItem(key, value);
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const testLocalStorage = () => {
  try {
    localStorage.setItem('test-storage-access', 'true');
    return true;
  } catch (e) {
    return false;
  }
};

export const getCurrentSessionFromLocalStorage = publishId => {
  try {
    const sessions = JSON.parse(getLocalStorageItem('tolstoy-sessions'));
    if (!sessions) {
      return {};
    }

    return sessions.find(session => session.publishId === publishId) || {};
  } catch (err) {
    console.log(err);
    return {};
  }
};

const replaceCurrentSession = (sessions, currentSession) => {
  const newSessions = sessions.filter(session => session.publishId !== currentSession.publishId);
  newSessions.push(currentSession);
  return newSessions;
};

export const setCurrentSession = currentSession => {
  try {
    const sessions = JSON.parse(getLocalStorageItem('tolstoy-sessions')) || [];
    setLocalStorageItem(
      'tolstoy-sessions',
      JSON.stringify(replaceCurrentSession(sessions, currentSession))
    );
  } catch (err) {
    console.log(err);
  }
};
