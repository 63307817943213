export const projectsWithSubtitles = [
  '88d3a49c-d31d-42e1-a005-909816f3637f',
  'c5412168-68f0-4366-b24a-28e7e8f750cd',
  '1ed1b548-96df-47e8-bfde-91e170a0f96d',
  '54848a57-b916-4cf5-892c-ba878edda1a3',
  '8f48beb8-d38a-4fd4-be86-f45b84b2d4bc',
  'c11ea579-18b3-4fe9-95b7-32117ef80a41',
  '6ecfea4b-5547-4646-9011-3bb0a6fe8410',
  '5a1a67f5-4366-4e49-a6b5-afe6aa5c9643',
  'df0a7547-3298-449f-b2ff-cc35d748bb74',
];

export const projectsWithChapters = [
  '54848a57-b916-4cf5-892c-ba878edda1a3',
  '9abcb6f9-e705-4dae-a297-feb71dfacce6',
  '0d1abc91-78e0-4434-be95-cd80daa0d1d7',
];

export const projectsWithNoPoweredBy = [
  '939ab74d-bbac-4c8f-8aa7-1b283e8dd9e4',
  'd9ee8766-c23d-4d67-9a29-fa80c713c3cf',
  '1b0f3302-6238-4bd6-835c-ebf5c1d107ea',
  '45e09551-7d0e-444d-b656-10aa8ef415f0',
  '15c0aa80-f767-43e9-8d07-becf217b0fbe',
  '686637b0-6374-49ae-979a-0a9f37ccff96',
  'a2e3a4ae-3911-49cb-82b7-28cd5fd5968d',
  '6a56490c-efba-4c2d-a43a-4ae99e32df78',
];

export const projectsWithSemiPoweredBy = [
  'a66d4c5e-a59a-4b9c-831d-45a2094583e0',
  '6023e145-3690-4a93-aade-ae404d2928cf',
  'f8dea0b9-636a-4ce7-be15-0e83e100b5f2',
  'b81c0dd8-88d0-4b22-8613-c7bfbc7fcbdd',
];

export const projectsWithNoBackButton = [
  '02a5219e-3086-4c75-a5a3-2440c724db0a',
  '3d9bf977-c61d-4dd3-8b56-e5c5a9349c65',
  '8468e35e-8c36-4e0a-98e8-f64b98ec310b',
];

export const accountsWithSubtitles = [];

export const accountsWithNoPoweredBy = [
  '75783039-e2ed-41bb-a0af-da86af9d8cdc',
  '5d9f9d3a-6e28-42dd-b01b-8c736bf188e0',
  'Google_110492630675513307776',
  'Google_114071149886368070668',
  'Google_104683029847200262642',
  '764e3793-6227-4b0e-a33b-e28d97c86912',
  '1a56b496-443a-4e50-8f41-28b9366d909a',
  '4dc62738-9140-463e-8554-80c802b0f897',
  'a3f25f33-b30e-4d85-bfed-48e9bd19c60d',
  'Google_117480248061165086823',
  'Google_110561245304751209144',
  'Google_101073561583390072915',
  '8dc647c2-ae3f-41f2-9ad7-3838f0874249',
  'd46fa365-64f0-4065-8c85-f79c1df2ee3c',
  '3f7c819b-1e4c-43ba-840f-a1c96edb8715',
  '37954d82-7570-4a34-8163-ee4b26422ac6',
  'Google_115104014282288377617',
];

export const accountsWithSemiPoweredBy = [
  'acfd6edc-a2b3-46e3-b46d-3ea7a701d5fb',
  '1f3653d2-861a-46db-b4ed-368915ac3d05',
  '9d58c6ce-0a23-451c-a1b6-963dc95024d4',
  'Google_117690669142373058300',
  'da4f0989-4107-4dba-a147-dc942460352b',
  '21f155a8-2d6f-4768-a3b6-9922806b05dd',
  '157fbd1e-7e03-4a82-a44c-4a5a2c89b960',
  'a8f62a30-b008-4026-a318-c312f19d5104',
  '66b15359-a9d5-47f8-8941-3351f3e9fbf2',
];

export const accountsWithBrandLogo = [
  'Google_107697449642333288355',
  'Google_112549390459064744740',
  '75783039-e2ed-41bb-a0af-da86af9d8cdc',
  'Google_100917841728078133106',
  'Google_117480248061165086823',
  'Google_114071149886368070668',
  '36520cf9-a5f8-48e8-9785-87890d523a2b',
  '44654065-2172-476f-ba22-408cbc9650c4',
  'af403c2a-bc51-4f8d-9996-556fea9740b7',
  '50b1358a-9778-4509-972c-9437dcd87376',
  'd32642ae-9315-4f6b-b9dc-e0f313203d07',
  'Google_102756892450584433340',
];

export const projectsWithCustomEndScreens = {
  '18f65935-10c8-44f2-ae51-95f49bbeaaa2': {
    ctaUrl: 'https://level1620.com/discount/TOLSTOY35',
    message:
      "🎉 Congratulations! You've earned a 35% discount on your purchase. To redeem, just click below to shop, and the discount will be applied at checkout.",
    ctaText: 'SHOP WITH 35% DISCOUNT NOW',
  },
  'b9a56ce8-3010-456c-9251-24dcb4cd6ded': {
    message: 'Congrats! Your discount code is: "TENOFF"',
  },
  'df0a7547-3298-449f-b2ff-cc35d748bb74': {
    ctaUrl: 'https://matics.live/resources/',
    message:
      "Thank you for contacting Matics. We'll be in touch really soon! Learn more about smart manufacturing:",
    ctaText: 'Explore Our Resources',
  },
};

export const accountsWithCustomEndScreens = {
  Google_114071149886368070668: {
    message: 'Ya está. Muchas gracias',
    isNormal: true,
    restartText: 'Empezar',
    backText: 'Atrás',
    shareText: 'Compartir',
  },
  Google_102756892450584433340: {
    message:
      'תודה רבה!\n\nפרטייך התקבלו בהצלחה.\n\nנמשיך מיד לאחר אימות הפרטים שמסרת.\n\nמייל המשך יישלח אליך לכתובת המייל שמילאת בטופס.\n\nIintoo Israel',
    isNormal: true,
    restartText: 'אתחול',
    backText: 'אחורה',
    shareText: 'שיתוף',
  },
  'debcce2c-0874-4e5e-a570-956f8d22bd43': {
    message: 'Obrigado por entrar em contato!',
    isNormal: true,
    restartText: 'Recomeçar',
    backText: 'Voltar',
    shareText: 'Compartilhar',
  },
  '47fe13c5-4ee3-461f-8e0e-9989a1abc261': {
    message:
      'Du er færdig med din video-rejse. Tak for at du kiggede med. Del gerne med andre, som kan have gavn af videoerne:) Du er altid velkommen til at ringe til os på telefon 71 70 00 00',
  },
  'f54ba5fa-e902-46b9-837f-9f6a14e114dd': {
    message: 'Click the close button to go back to shop this best-seller!',
    isNormal: true,
  },
};

export const accountsWithCustomButtons = {
  Google_114071149886368070668: {
    sendText: 'Enviar',
    stopText: 'Parada',
    startText: 'Grabar',
    cancelText: 'Cancelar',
  },
};

export const accountsWithNoViralEndScreens = [
  'a7ad7bae-2629-41a1-b381-2bfb7f264229',
  'Google_114071149886368070668',
  'a8f62a30-b008-4026-a318-c312f19d5104',
  '66b15359-a9d5-47f8-8941-3351f3e9fbf2',
  '9bcf1cda-d50a-4765-a946-597780d5a3dd',
  'Google_104683029847200262642',
  '764e3793-6227-4b0e-a33b-e28d97c86912',
  '1a829a96-67c7-4c03-8920-99bb562e169d',
  '1a56b496-443a-4e50-8f41-28b9366d909a',
  '4dc62738-9140-463e-8554-80c802b0f897',
  'a3f25f33-b30e-4d85-bfed-48e9bd19c60d',
  'Google_117480248061165086823',
  'Google_110561245304751209144',
  'Google_102429869081189166073',
  'f4a60317-14c9-42ee-b744-454b0f20d39c',
  'Google_101073561583390072915',
  '8dc647c2-ae3f-41f2-9ad7-3838f0874249',
  'd46fa365-64f0-4065-8c85-f79c1df2ee3c',
  '3f7c819b-1e4c-43ba-840f-a1c96edb8715',
  '37954d82-7570-4a34-8163-ee4b26422ac6',
  '8866084f-af4a-4cea-a471-63b5ad698b27',
  'Google_115104014282288377617',
  'Google_101450598848253110348',
  'Google_116512884009405052562',
  'b88ec0b3-c091-40d5-ac0c-b3a31d39512c',
];

export const projectsWithCustomStart = {
  '18f65935-10c8-44f2-ae51-95f49bbeaaa2': {
    text: 'Watch this video to get 35% discount',
  },
  '87b7528c-5d35-4ac0-bc4c-a7c0e07de927': {
    text: '',
  },
  '8ab116f3-143c-49c9-a523-91fb993c90fc': {
    text: '',
  },
  '44547881-7b32-460c-aad5-2405a467496e': {
    text: '1 min timer in hand to choose the next product and RECEIVE A GIFT 🎁 !',
  },
  '752cea32-42c0-42fe-a6c6-bf215e8771b0': {
    text: 'Watch Demo',
  },
  'ca9a1804-e038-4a88-ad09-a30b407f8c0d': {
    text: 'Click here to chat',
  },
  'bab77199-d535-48cf-aebd-60f820985d16': {
    text: '',
  },
  '8cb4d538-531a-4944-9b3b-b5c5fe5228f8': {
    text: '',
  },
  '8afa29d6-c242-413a-a098-9e4b3f912e26': {
    text: 'KOALA CEO and timeshare owner Mike Kennedy explains how it works',
  },
  'b1e25db7-9b97-44ed-b9e5-879e2aa6d346': {
    text: 'Check out our beginner guide!',
  },
  'd6bee3fb-ee75-4e62-9444-b2d630243043': {
    text: 'Meet your future team and learn more about role',
  },
};

export const accountsWithCustomStart = {
  Google_102217969887301223858: {
    text: '',
  },
  'ef29f9a4-9a4c-4a1c-b58c-539cb178a25c': {
    text: '',
  },
  '9d58c6ce-0a23-451c-a1b6-963dc95024d4': {
    text: '',
  },
  Google_114071149886368070668: {
    text: '',
  },
  '96925af5-3653-4e50-87b6-5b9daf24cb30': {
    text: 'Dialog mit uns starten',
  },
  '6d57b8a0-a82b-4f6f-a696-4c1de8d49757': {
    text: '',
  },
  'e3651f99-164b-4ed0-b666-cef4b28d8d8c': {
    text: 'Begin an interactive video',
  },
  '29cd505d-e067-4a82-9dec-66fb16a7dce7': {
    text: '',
  },
  Google_111951626436616345504: {
    text: '',
  },
  '8866084f-af4a-4cea-a471-63b5ad698b2': {
    text: '',
  },
  'f27c22eb-0387-4571-8637-887f2679f78a': {
    text: '',
  },
  '3f7c819b-1e4c-43ba-840f-a1c96edb8715': {
    text: 'Play an interactive video',
  },
  'dd5ca516-79fe-4426-a6d2-3aaf4b761728': {
    text: 'Begin an interactive eLearning session',
  },
  '3fa1302a-2572-4273-89b7-34032a39bee3': {
    text: 'Scopri l’Offerta',
  },
  '184bcd06-8f3f-43b9-927c-cd8b0d784f09': {
    text: 'Lancer nos vidéos interactives',
  },
};

export const accountsWithCustomLeadFormText = {
  'de97b4d8-bf3a-4647-ac66-6a211f645449': {
    yourEmail: 'Deine E-Mail Adresse',
    back: 'Zurück',
    submit: 'Absenden',
  },
  '96925af5-3653-4e50-87b6-5b9daf24cb30': {
    submit: 'Einreichen',
    back: 'Zurück',
    next: 'Weiter',
    yourEmail: 'Deine Email',
    yourName: 'Dein Vorname',
    yourPhone: 'Deine Telefon-Nummer',
    emailError: 'Bitte sicherstellen, dass du eine korrekte Email Adresse eingegeben hast.',
  },
  Google_107249997319586630573: {
    submit: 'Abschicken',
    back: 'Zurück',
    next: 'Weiter',
    yourEmail: 'Ihre E-Mail’',
    yourName: 'Ihr Name',
    yourPhone: 'Ihre Telefonnummer',
    emailError: 'Bitte sicherstellen, dass du eine korrekte Email Adresse eingegeben hast.',
  },
  Google_114071149886368070668: {
    submit: 'Enviar',
    back: 'Volver',
    next: 'Enviar',
    yourEmail: 'Correo electrónico',
    yourName: 'Nombre',
    yourPhone: 'Teléfono',
    emailError: 'Asegúrese de que su dirección de correo electrónico sea válida.',
  },
  Google_113656183751585117801: {
    submit: 'Envoyer',
    back: 'Retour',
    next: 'Suivant',
    yourEmail: 'Ton email',
  },
  '4b3ac61f-6b8a-47c9-8f69-f8cb00e8663d': {
    yourEmail: 'Indtast email',
  },
  '620e17d8-37f5-481c-b858-43f9d282bb55': {
    yourEmail: 'El. paštas',
    yourName: 'Vardas',
    yourPhone: 'Telefonas',
  },
  'debcce2c-0874-4e5e-a570-956f8d22bd43': {
    submit: 'Enviar',
    back: 'Voltar',
    next: 'Próximo',
    yourName: 'Nome',
    yourPhone: 'Telefone (com DDD)',
  },
  Google_115267168809653490689: {
    submit: 'Enviar',
    back: 'Voltar',
    next: 'Próximo',
  },
  '47fe13c5-4ee3-461f-8e0e-9989a1abc261': {
    yourName: 'Dit navn',
    yourEmail: 'Din e-mail',
    yourPhone: 'Dit telefonnummer',
    submit: 'Send',
    back: 'Tilbage',
    next: 'Weiter',
  },
  'a667bc2c-c1a2-462e-aaf5-3aa9cc43cdcf': {
    yourName: 'Your first name',
  },
  '019bd2b7-c950-4f6c-8465-b614d4758992': {
    yourName: 'Seu nome',
    yourEmail: 'Seu email',
    yourPhone: 'Seu telefone',
    submit: 'Send',
    back: 'de volta',
    next: 'Próximo',
  },
  Google_102756892450584433340: {
    yourName: 'שם מלא',
    yourEmail: 'כתובת המייל',
    yourPhone: 'מספר טלפון',
    submit: 'שליחה',
    back: 'קודם',
    next: 'הבא',
  },
};

export const accountsWithCustomFreeTextText = {
  Google_114071149886368070668: {
    submit: 'Enviar',
    textPlaceHolder: 'Responde aquí...',
  },
};

export const freeTextConfiguration = {
  '4b38a8fa-e9ee-41e9-9828-abdb83d5383c': {
    JcRyP: {
      placeholder: {
        text: 'Improve first response time',
      },
    },
    w5E1V: {
      placeholder: {
        text: 'ie delay in launching website',
      },
    },
  },
  '8ae90483-5347-4c62-a50d-1cc9016e957d': {
    usogv: {
      placeholder: {
        text: 'Tell us about your furniture project',
      },
    },
  },
  'beaa754b-19fe-491c-8784-5b0cc2894698': {
    '9uep1': {
      maxLength: 10,
    },
    pzfU5: {
      maxLength: 250,
    },
  },
};

export const projectsWithCustomLayout = {
  '8000a21c-4f23-4128-abb2-25f417c84885': {
    answerButton: {
      background: '#2E6CF4',
      border: 0,
    },
  },
  'ba2e7e14-ee5b-469e-9856-3b7e29f4f00f': {
    answerButton: {
      background: '#2E6CF4',
      border: 0,
    },
  },
  '4c069ac7-f520-4f30-b2ad-d85d1aaa4b87': {
    subtitles: {
      fontSize: '26px',
    },
  },
  '93599ec1-5c06-45ed-a058-b5eb2407b015': {
    answerButton: {
      background: '#344A9A',
      borderRadius: 0,
    },
    letterIcon: {
      display: 'none',
    },
    durationBar: {
      color: '#344A9A',
      shouldAlwaysShowControlsOnMobile: 'true',
    },
  },
  '993f2567-1fad-4bdc-8d6f-74710ea4c0e7': {
    answerButton: {
      background: '#344A9A',
      borderRadius: 0,
    },
    letterIcon: {
      display: 'none',
    },
    durationBar: {
      color: '#344A9A',
      shouldAlwaysShowControlsOnMobile: 'true',
    },
  },
  'd6671430-780f-4890-801d-099761d25bf3': {
    answerButton: {
      background: '#344A9A',
      borderRadius: 0,
    },
    letterIcon: {
      display: 'none',
    },
    durationBar: {
      color: '#344A9A',
      shouldAlwaysShowControlsOnMobile: 'true',
    },
  },
  '617cb018-87fd-4af8-9372-abe48f417bff': {
    answerButton: {
      background: '#F8C8D8',
      borderRadius: 0,
      color: '#604596',
    },
    letterIcon: {
      display: 'none',
    },
    durationBar: {
      color: '#F8C8D8',
      shouldAlwaysShowControlsOnMobile: 'true',
    },
  },
  '688157f1-a0ad-4bc9-95d2-c7520f762f45': {
    answerButton: {
      background: '#F8C8D8',
      borderRadius: 0,
      color: '#604596',
    },
    letterIcon: {
      display: 'none',
    },
    durationBar: {
      color: '#F8C8D8',
      shouldAlwaysShowControlsOnMobile: 'true',
    },
  },
  '9ac0583a-ca47-418b-ab1d-a5bf3716b9d2': {
    answerButton: {
      background: '#F8C8D8',
      borderRadius: 0,
      color: '#604596',
    },
    letterIcon: {
      display: 'none',
    },
    durationBar: {
      color: '#F8C8D8',
      shouldAlwaysShowControlsOnMobile: 'true',
    },
  },
  '78539378-46fd-446c-a9ad-461102221502': {
    answerButton: {
      background: '#344A9A',
      borderRadius: 0,
    },
    letterIcon: {
      display: 'none',
    },
    durationBar: {
      color: '#344A9A',
      shouldAlwaysShowControlsOnMobile: 'true',
    },
  },
  '55372cdc-6781-4fda-a592-b46335540726': {
    answerButton: {
      background: '#F8C8D8',
      borderRadius: 0,
      color: '#604596',
    },
    letterIcon: {
      display: 'none',
    },
    durationBar: {
      color: '#F8C8D8',
      shouldAlwaysShowControlsOnMobile: 'true',
    },
  },
  'bfa440d0-de7a-428b-8551-5bb19bbc5d25': {
    answerButton: {
      background: '#344A9A',
      borderRadius: 0,
    },
    letterIcon: {
      display: 'none',
    },
    durationBar: {
      color: '#344A9A',
      shouldAlwaysShowControlsOnMobile: 'true',
    },
  },
  '5fd06b55-b3a0-4b0e-9e18-83907cb69809': {
    answerButton: {
      background: '#344A9A',
      borderRadius: 0,
    },
    letterIcon: {
      display: 'none',
    },
    durationBar: {
      color: '#344A9A',
      shouldAlwaysShowControlsOnMobile: 'true',
    },
  },
  'a8ca4ec4-2cef-41e6-85eb-550920d2e797': {
    answerButton: {
      background: '#F8C8D8',
      borderRadius: 0,
      color: '#604596',
    },
    letterIcon: {
      display: 'none',
    },
    durationBar: {
      color: '#F8C8D8',
      shouldAlwaysShowControlsOnMobile: 'true',
    },
  },
  'c57c6e6d-5cf9-49a5-9c16-b57e34894a87': {
    answerButton: {
      background: '#344A9A',
      borderRadius: 0,
    },
    letterIcon: {
      display: 'none',
    },
    durationBar: {
      color: '#344A9A',
      shouldAlwaysShowControlsOnMobile: 'true',
    },
  },
  '1a620acc-7a25-490a-acee-c740d45f5164': {
    answerButton: {
      background: '#344A9A',
      borderRadius: 0,
    },
    letterIcon: {
      display: 'none',
    },
    durationBar: {
      color: '#344A9A',
      shouldAlwaysShowControlsOnMobile: 'true',
    },
  },
  '40b39daf-ae60-43b2-bc17-df2b43d9d871': {
    letterIcon: {
      display: 'none',
    },
  },
  '79ef6459-20d1-4689-a479-3d67f813eba5': {
    letterIcon: {
      display: 'none',
    },
  },
  '8208580c-81a5-44d3-a0a6-74f9c75d1486': {
    subtitles: {
      direction: 'rtl',
    },
    answerButton: {
      background: '#F8C8D8',
      borderRadius: 0,
      color: '#604596',
      direction: 'rtl',
    },
    letterIcon: {
      display: 'none',
    },
    durationBar: {
      color: '#F8C8D8',
      shouldAlwaysShowControlsOnMobile: 'true',
    },
  },
  '6843480b-63bb-4a67-8cfe-5c430bab6a54': {
    subtitles: {
      direction: 'rtl',
    },
    answerButton: {
      background: '#F8C8D8',
      borderRadius: 0,
      color: '#604596',
      direction: 'rtl',
    },
    letterIcon: {
      display: 'none',
    },
    durationBar: {
      color: '#F8C8D8',
      shouldAlwaysShowControlsOnMobile: 'true',
    },
  },
  'a268a1b9-5761-49eb-a8a9-d5ad63865015': {
    answerButton: {
      background: '#FFF',
      borderRadius: 0,
      color: '#090A0B',
    },
  },
  '61f4a509-6df2-422c-93c2-6b4219165bf5': {
    overlayText: {
      fontSize: 16,
    },
  },
  '20017b39-cd8a-4638-b8dd-1b8594570663': {
    subtitles: {
      direction: 'rtl',
    },
    answerButton: {
      direction: 'rtl',
    },
  },
  'ff8ce38c-3ba4-4af4-9e92-68a5db2ce7ef': {
    overlayText: {
      fontSize: 18,
    },
  },
};

export const accountsWithCustomChapterPicker = {
  '67042b1c-da0f-4f97-a77d-b10268722e0c': {
    hideSeparator: true,
    hideChaptersRegex: '^NIIV!',
  },
};

export const projectsWithQuiz = [
  '8a229714-d4aa-4066-8089-bb9d25a4c42a',
  '98b5c8ca-f109-43ea-841b-de454ef90226',
];

export const accountsWithoutFullscreen = [
  'dd5ca516-79fe-4426-a6d2-3aaf4b761728',
  '67042b1c-da0f-4f97-a77d-b10268722e0c',
];

export const projectsWithControlsHidden = [
  '866d756f-303c-4f61-b725-8e474e8da670',
  '1696c82f-b7f8-4887-8ec6-b20ec2873703',
  '9e3441f2-aad4-48ab-9e65-96873b9b0bc0',
];

export const appKeysWithControlsHidden = ['70cb4ef4-1b65-47eb-b272-6f66358031af'];

export const accountsWithLogRocket = [
  '29029fb5-5f55-4ebd-bdbd-91daa2f95bf4', //alfaphox.com
  '28e50e15-d146-4f2d-9f4f-bb34ad6d6f5b', //trainiac.fit
  'a8adfc8f-3c28-4b4e-8c99-c992a9ea2a9f',
];
