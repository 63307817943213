import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHubspot } from 'app/src/context/integrations/HubspotStore';
import { TextSubtitle } from 'shared/react/components/complex/Text';
import HubspotSyncDirection from 'app/src/pages/modals/hubspot_mapping/HubspotSyncDirection';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import HubspotResolveConflicts from 'app/src/pages/modals/hubspot_mapping/HubspotResolveConflicts';
import { useForm, FormProvider } from 'react-hook-form';
import BasicButton from 'shared/react/components/basic/button/button/BasicButton';
import { getDefaultValues } from 'app/src/pages/modals/hubspot_mapping/hubspotMappingDefaultValues';
import HubspotMappingTable from 'app/src/pages/modals/hubspot_mapping/hubspot_table/HubspotMappingTable';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import AddButton from 'app/src/basic_components/AddButton';
import { FIELDS_KEY } from 'app/src/pages/modals/hubspot_mapping/hubspotMapping.constants';
import { useApps } from 'app/src/context/AppsStore';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { useProjectApps } from 'app/src/context/ProjectAppStore';
import ComponentWithOverlayLoader from 'shared/react/components/basic/ComponentWithOverlayLoader';
import { CircularProgress } from '@material-ui/core';
import { track } from 'app/src/helpers/Tracker';

const HubspotMapping = ({ closeModal }) => {
  const [{ properties }, { fetchHubspotUserProperties }] = useHubspot();
  const [{ currentProjectId: projectId }] = useModal();
  const [{ hubspot }] = useApps();
  const [{ projectApp }, { createProjectApp, updateProjectApp }] = useProjectApps({
    projectId,
    appId: hubspot.id,
  });
  const methods = useForm({ defaultValues: getDefaultValues() });
  const [addingField, setAddingField] = useState(false);
  const {
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { isDirty, isSubmitting: isLoading },
  } = methods;
  const fields = watch(FIELDS_KEY);

  const updateStepValueOverride = useCallback(
    (target, value, shouldDirty = true) => setValue(target, value, { shouldDirty }),
    []
  );

  useEffect(() => {
    if (properties.length) {
      return;
    }

    fetchHubspotUserProperties();
  }, []);

  const onSave = async values => {
    if (!isDirty) {
      closeModal();
      return;
    }

    track('Hubspot Mapping Save Changes');

    const newProjectApp = {
      ...projectApp,
      appId: hubspot.id,
      appKey: hubspot.appKey,
      projectId,
      owner: hubspot.owner,
      active: true,
      data: JSON.stringify(values),
    };

    if (projectApp) {
      await updateProjectApp(newProjectApp);
    } else {
      await createProjectApp(newProjectApp);
    }

    closeModal();
  };

  const onAddNewField = () => {
    if (addingField) {
      return;
    }
    track('Add Hubspot Mapping Field');

    setAddingField(true);
  };

  const onAddField = newField => {
    setAddingField(false);
    const newFields = [...fields, newField];
    updateStepValueOverride(FIELDS_KEY, newFields);
  };

  const onRemoveField = hubspotField => {
    const newFields = [...fields].filter(field => field.hubspotField !== hubspotField);
    track('Remove Hubspot Mapping Field');
    updateStepValueOverride(FIELDS_KEY, newFields);
  };

  const onToggleDefault = hubspotField => {
    const newFields = [...fields].map(field => {
      if (hubspotField === field.hubspotField) {
        return { ...field, enabled: !field.enabled };
      }

      return field;
    });

    updateStepValueOverride(FIELDS_KEY, newFields);
  };

  useEffect(() => {
    if (projectApp) {
      reset(JSON.parse(projectApp.data));
    }
  }, [projectApp]);

  return (
    <FormProvider {...methods} setValue={updateStepValueOverride}>
      <LayoutRoot>
        <Header>Map Hubspot Fields</Header>
        <HubspotSyncDirection />
        <HubspotResolveConflicts />
        <HubspotMappingTable
          onRemoveField={onRemoveField}
          fields={fields}
          onAddField={onAddField}
          addingField={addingField}
          onToggleDefault={onToggleDefault}
        />
        <ButtonsContainer>
          <AddButton onClick={onAddNewField}>Add new</AddButton>
          <OverlayLoader customLoader={<CircularProgress size={20} />} isLoading={isLoading}>
            <Button disabled={!isDirty || isLoading} onClick={handleSubmit(onSave)}>
              Save
            </Button>
          </OverlayLoader>
        </ButtonsContainer>
      </LayoutRoot>
    </FormProvider>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  padding: 24px;
  gap: 40px;
`;

const Header = styled(TextSubtitle)``;

const ButtonsContainer = styled(HorizontalFlex)`
  justify-content: space-between;
  align-items: center;
`;

const Button = styled(BasicButton)`
  width: 90px;
`;

const OverlayLoader = styled(ComponentWithOverlayLoader)`
  width: unset;
`;

export default HubspotMapping;
