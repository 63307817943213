import React from 'react';
import styled from 'styled-components';
import Separator from 'shared/react/components/basic/Separator';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import MobileDesktopPreviewToggle from 'app/src/pages/creation_flow/right_section/video_container/video_overlay/MobileDesktopPreviewToggle';
import { OverlayIconButton } from 'app/src/basic_components/commonStyles';

const PreviewToggle = ({ isFeed, selectedPreviewMode, setSelectedPreviewMode }) => {
  if (!isFeed) {
    return null;
  }

  return (
    <LayoutRoot>
      <StyledMobileDesktopPreviewToggle
        selectedPreviewMode={selectedPreviewMode}
        setSelectedPreviewMode={setSelectedPreviewMode}
        leftTooltipText="Desktop preview"
        rightTooltipText="Mobile preview"
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  justify-content: center;
  align-items: center;
`;

const StyledMobileDesktopPreviewToggle = styled(MobileDesktopPreviewToggle)`
  border-radius: 10px;
  width: min-content;
  z-index: 1;

  & > ${Separator} {
    background: ${({ theme }) => theme.colors.ghostLight};
  }

  & ${OverlayIconButton} {
    border-radius: 0;
    background: ${({ theme }) => theme.colors.gray5};
    height: 28px;
    width: 32px;

    & > svg {
      width: 12px;
      height: 12px;
    }

    & path {
      fill: ${({ theme }) => theme.colors.gray27};
    }
  }
`;

export default PreviewToggle;
