import React from 'react';

const ShareIcon = ({ fill = '#7D8087', props }) => (
  <svg width={18} height={17} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m17.75 5.469-4.5-4.25c-.313-.282-.781-.282-1.063.031-.28.313-.28.781.032 1.063l3.125 2.968H10.25c-1.281-.062-2.469.469-3.344 1.344A4.746 4.746 0 0 0 5.5 10.031v2.75c0 .406.313.75.75.75a.76.76 0 0 0 .75-.75v-2.75c0-.906.313-1.719.938-2.344.625-.593 1.375-.937 2.312-.906h5.094L12.219 9.72c-.313.281-.313.75-.031 1.062.156.156.343.25.562.25.156 0 .344-.094.5-.219l4.5-4.25c.156-.156.25-.343.25-.53a.783.783 0 0 0-.25-.563Zm-6.5 7.562a.722.722 0 0 0-.75.75v1.5c0 .125-.125.25-.25.25h-8.5a.246.246 0 0 1-.25-.25v-8.5c0-.156.094-.25.25-.25H3.5a.76.76 0 0 0 .75-.75.74.74 0 0 0-.75-.75H1.75c-.969 0-1.75.782-1.75 1.75v8.5C0 16.22.781 17 1.75 17h8.5c.938 0 1.75-.781 1.75-1.719v-1.5a.74.74 0 0 0-.75-.75Z"
      fill={fill}
    />
  </svg>
);

export default ShareIcon;
