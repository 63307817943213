import React, { useState } from 'react';
import VideoModal from 'app/src/pages/modals/VideoModal';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { useVideos } from 'app/src/context/VideosStore';

const PreviewVideoModal = () => {
  const [
    {
      currentId,
      modalProps: { videoUrl: currentVideoUrl },
    },
    { setCurrentModal, setCurrentId },
  ] = useModal();
  const [{ video }, { getVideoUrl, handleVideoLoadError }] = useVideos(currentId);
  const [videoUrl, setVideoUrl] = useState(getVideoUrl(video));

  const onClose = () => {
    setCurrentId('');
    setCurrentModal('');
  };

  return (
    <VideoModal
      open={true}
      src={currentVideoUrl || videoUrl}
      onClose={onClose}
      onError={() => handleVideoLoadError(video, videoUrl, setVideoUrl)}
    />
  );
};

export default PreviewVideoModal;
