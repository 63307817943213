import { SHOPIFY_SUFFIX } from 'app/src/constants/shopify.constants';
import { SHOPIFY } from 'src/constants/intergrations.constants';
import { SyncTagsMode } from 'src/constants/shop.constants';
import { app as AppEntity } from 'src/types/entities';
import Utils from 'src/utils';

export const cleanStoreName = appUrl => (appUrl || '').replace(SHOPIFY_SUFFIX, '');

export const getPrimaryApp = (apps: AppEntity[]) => {
  return apps?.find(app => app.syncTagsMode === SyncTagsMode.primary);
};

export const getShopifyApps = (apps: AppEntity[]) => {
  return apps?.filter(({ app }) => app === SHOPIFY);
};

export const formatHandle = handle => {
  if (!handle) {
    return '';
  }

  const handleParts = handle.split('-');

  return handleParts.map(part => Utils.upperCaseFirstChar(part)).join(' ');
};

export const formatShopifyPageName = (pageName: string) => {
  const handle = pageName.split('/').pop();

  if (!handle) {
    return pageName;
  }

  return formatHandle(handle);
};
