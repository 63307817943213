import APP_CONFIG from 'app/src/config/app.config';
import { navigateToDashboard } from 'app/src/utils/navigation.utils';
import { API, graphqlOperation } from 'aws-amplify';
import { customAlphabet } from 'nanoid';
import {
  createActionsHook,
  createContainer,
  createHook,
  createStore,
  defaults,
} from 'react-sweet-state';
import { FEATURE_PUBLISH_V2 } from 'shared/react/constants/features.constants';
import { PUBLISH_METHODS_WITH_LIVE_FIELD } from 'shared/react/constants/tolstoy.constants';
import { publishMethodOptions } from 'src/types/entities';
import { PRODUCTS, TABS } from '../constants/editStep.constants';
import { EXPORT_TO_STORE_MODAL_KEY, TOLSTOY_RENAME_MODAL_KEY } from '../constants/modals.constants';
import { cases, CaseType } from 'app/src/pages/dashboard/components/cases/cases.constants';
import {
  DEFAULT_PDP_CONFIG,
  DEFAULT_HOMEPAGE_CONFIG,
  DEFAULT_PLAYER_SETTINGS,
  DEFAULT_WIDGET_SETTINGS,
  PROJECT_TYPES,
  SUPPORTED_TYPE_KEYS,
  TAPCART_PLAYER_SETTINGS,
  DEFAULT_EMAIL_SETTINGS,
  DEFAULT_VIDEO_COLLECTION_CAMPAIGN_SETTINGS,
} from '../constants/project.constants';
import { PROJECT_LIBRARY_UI_SETTING_KEY } from 'app/src/constants/uiSettings.constants';
import { createProject, updateProject, updateProjectStep } from '../graphql/mutations';
import { getProject, projectsByTypeKey } from '../graphql/queries';
import { onProjectChangeByAppKey } from '../graphql/subscriptions';
import { deleteRequest, getRequest, graphqlRequest, postRequest } from '../helpers/API';
import Utils from '../utils';
import { ONSITE_TARGET_PAGES } from 'app/src/pages/dashboard/constants/onsiteTargetPages.constants';
import { getProjectDefaultSettings } from 'app/src/utils/project.utils';

defaults.devtools = true;

const initialState = {
  initialized: false,
  replies: {},
  projects: [],
  showPersonalLibrary: null,
  fetchedProjects: false,
  loading: true,
  creating: false,
  error: null,
  subscription: null,
  numberOfTeamMemberTolstoys: null,
};

export const getUserPersonalAndTeamFilter = () => {
  return {};
};

const getTolstoyStepType = type => {
  if (!type) {
    return TABS.custom;
  }

  if (type === TABS.products) {
    return PRODUCTS;
  }

  return type;
};

const getTolstoyTypeKey = (appKey, type) => {
  const suffix = SUPPORTED_TYPE_KEYS[type] || SUPPORTED_TYPE_KEYS.tolstoy;
  return `${appKey}_${suffix}`;
};

const getIsDeletedTypeKey = typeKey => {
  return typeKey?.split('_')[1] === SUPPORTED_TYPE_KEYS.deleted;
};

const getSortedProjects = response => {
  const projects = response.data.projectsByTypeKey.items;
  projects.forEach(project => sortSteps(project));
  return projects || [];
};

const isPersonalProject = ({ owner, private: privateProject, typeKey }) => {
  return owner === Utils.getOwner() && privateProject && !typeKey.includes('deleted');
};

const getHasPersonalProject = projects => {
  return projects.some(isPersonalProject);
};

const getProjectLibrary = project => {
  if (!project.private) {
    return false;
  }

  if (project.owner === Utils.getOwner()) {
    return true;
  }

  return project.owner;
};

const getInitialLibrary = ({ currentShowPersonalLibrary, withoutChangingLibrary, projects }) => {
  if (withoutChangingLibrary) {
    return currentShowPersonalLibrary;
  }

  const selectedProjectId = getUrlSelectedProjectId();
  const selectedProject = projects.find(({ id }) => id === selectedProjectId);
  if (selectedProject) {
    return getProjectLibrary(selectedProject);
  }

  const prevLibrary = Utils.getAppSetting(PROJECT_LIBRARY_UI_SETTING_KEY);
  if (!Utils.isNullOrUndefined(prevLibrary)) {
    return prevLibrary;
  }

  return getHasPersonalProject(projects);
};

const getUrlSelectedProjectId = () => {
  return window.location.pathname?.split('/')?.[2];
};

const getTeamMemberProjects = projects => {
  return projects.filter(project => project.owner === Utils.getOwner());
};

const getNumberOfTeamMemberTolstoys = projects => {
  return getTeamMemberProjects(projects).length;
};

export const getIsAnotherUserPersonalLibrary = showPersonalLibrary => {
  return showPersonalLibrary && showPersonalLibrary !== true;
};

export const getIsFeed = ({ feed } = {}) => !!feed;

export const getIsDynamic = ({ dynamic } = {}) => !!dynamic;

export const getIsFeedStepShoppable = ({ feedSidePanelType } = {}) =>
  feedSidePanelType === TABS.products;

const getProjectName = ({ newTolstoyName, numberOfProjects = 0, namePrefix }) => {
  if (newTolstoyName) {
    return newTolstoyName;
  }

  return `${namePrefix} #${numberOfProjects + 1}`;
};

const actions = {
  setLoading:
    loading =>
    ({ setState }) => {
      setState({ loading });
    },
  fetchProjects:
    ({ withoutLoading, withoutChangingLibrary } = {}) =>
    async ({ getState, setState }, { appKey, features, isAccountOwner }) => {
      if (!withoutLoading) {
        setState({ loading: true, projects: [] });
      }

      try {
        let nextToken = null;
        let projects = [];
        do {
          const response = await graphqlRequest(
            graphqlOperation(projectsByTypeKey, {
              appKey,
              typeKey: getTolstoyTypeKey(appKey),
              limit: 500,
              sortDirection: 'DESC',
              nextToken,
              ...getUserPersonalAndTeamFilter({ features, isAccountOwner }),
            })
          );
          const nextProjects = getSortedProjects(response);
          projects = [...projects, ...nextProjects];

          nextToken = response.data.projectsByTypeKey.nextToken;
        } while (nextToken);

        const { showPersonalLibrary: currentShowPersonalLibrary } = getState();
        setState({
          projects,
          initialized: true,
          loading: false,
          fetchedProjects: true,
          showPersonalLibrary: getInitialLibrary({
            currentShowPersonalLibrary,
            withoutChangingLibrary,
            projects,
          }),
          numberOfTeamMemberTolstoys: getNumberOfTeamMemberTolstoys(projects),
        });
        return projects;
      } catch (error) {
        handleError(error, setState);
      }
    },
  getOrFetchProjectById:
    projectId =>
    async ({ getState, setState }) => {
      if (!projectId) {
        return null;
      }

      const { projects, replies } = getState();

      const project = replies[projectId] || projects.find(p => p.id === projectId);
      if (project) {
        return project;
      }

      try {
        const response = await graphqlRequest(graphqlOperation(getProject, { id: projectId }));
        const reply = response.data.getProject;

        replies[projectId] = reply;

        setState({ replies });

        return reply;
      } catch (error) {
        Utils.logError('cannot find project', error);
      }

      return {};
    },
  getProjectById:
    projectId =>
    ({ getState }) => {
      if (!projectId) {
        return null;
      }

      const projects = getState().projects;

      return projects.find(p => p.id === projectId);
    },
  isContactFormActivated: project => () => {
    if (!project) {
      return null;
    }

    return project.collectAfterStep?.length || project.collectAfterAnyResponse;
  },
  getProjectByPublishId:
    projectPublishId =>
    ({ getState }) => {
      if (!projectPublishId) {
        return null;
      }

      const { projects } = getState();

      return projects.find(p => p.publishId === projectPublishId);
    },
  getEmailCampaignsBySourceProjectId:
    projectId =>
    ({ getState, dispatch }) => {
      if (!projectId) {
        return null;
      }

      const { projects } = getState();
      const derivedProjects = projects.filter(p => {
        return p.publishMethod === publishMethodOptions.email && p.sourceProjectId === projectId;
      });

      const currentProject = dispatch(actions.getProjectById(projectId));
      if (currentProject?.publishMethod === publishMethodOptions.email) {
        return [currentProject, ...derivedProjects];
      }

      return derivedProjects;
    },
  createProject:
    ({
      input = undefined,
      type = 'custom',
      vertical = true,
      newTolstoyName = undefined,
      isEcomFeed,
      publishMethod,
      dynamic = false,
      appUrl,
      folder = undefined,
      targetPage = undefined,
      widgetSettings = {},
      name = '',
      namePrefix = undefined,
      discover = undefined,
      isCreateShopifyTvPage = false,
      useCaseName = undefined,
      emailSettings = {},
      videoCollectionCampaignSettings = {},
      sourceProjectId = undefined,
    }) =>
    async ({ getState, setState }, { appKey, getFeatureEnabled, saveRules }) => {
      const state = getState();
      const numberOfProjects = state.projects.length;
      const numberOfFeeds = state.projects.filter(getIsFeed).length;
      const isTapcartUseCase = useCaseName === cases[CaseType.TAPCART_APP].name;
      const isEmail = publishMethod === publishMethodOptions.email;
      const defaultName = getProjectName({
        newTolstoyName,
        numberOfProjects: isEcomFeed ? numberOfFeeds : numberOfProjects,
        namePrefix: namePrefix || (isEcomFeed ? 'Feed' : 'Tolstoy'),
      });
      setState({ creating: true });
      try {
        input = input || {
          name: name || defaultName,
          description: '',
          emailNotifications: [Utils.getUser().email],
          fastForwardEnabled: true,
          verticalOrientation:
            publishMethod === publishMethodOptions.bubble ? true : Utils.isMobile(),
          stepsOrder: [],
          chatLandingPage: false,
          collectAfterAnyResponse: true,
          widgetSettings: JSON.stringify({
            ...widgetSettings,
            ...DEFAULT_WIDGET_SETTINGS,
            ...getProjectDefaultSettings({ publishMethod }),
          }),
          playerSettings: JSON.stringify(
            isTapcartUseCase ? TAPCART_PLAYER_SETTINGS : DEFAULT_PLAYER_SETTINGS
          ),
          emailSettings: JSON.stringify({
            ...DEFAULT_EMAIL_SETTINGS,
            ...emailSettings,
          }),
          videoCollectionCampaignSettings: JSON.stringify({
            ...DEFAULT_VIDEO_COLLECTION_CAMPAIGN_SETTINGS,
            ...videoCollectionCampaignSettings,
          }),
          dynamic,
          folder,
          targetPage,
          discover,
          useCaseName,
          sourceProjectId,
          pdpConfig:
            targetPage === ONSITE_TARGET_PAGES.HomePages
              ? DEFAULT_HOMEPAGE_CONFIG
              : DEFAULT_PDP_CONFIG,
        };

        input.feed = isEcomFeed;
        input.private = state.showPersonalLibrary;
        const nanoid = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyz', 13);
        input.publishId = nanoid();
        input.tolstoyType = type || 'custom';
        if (vertical) {
          input.verticalOrientation = vertical;
        }

        input.owner = Utils.getOwner();
        input.appKey = appKey;
        input.publishMethod = publishMethod;
        input.typeKey = getTolstoyTypeKey(input.appKey, type);
        input.appUrl = appUrl;

        const isPublishV2Enabled = getFeatureEnabled(FEATURE_PUBLISH_V2);
        if (isPublishV2Enabled && PUBLISH_METHODS_WITH_LIVE_FIELD.includes(publishMethod)) {
          input.live = false;
        }

        const response = await graphqlRequest(graphqlOperation(createProject, { input }));
        const project = response.data.createProject;

        if (isCreateShopifyTvPage) {
          const { widgetUrls } = await getRequest(
            'shopify',
            `/create-shopify-tv-page?publishId=${project.publishId}&isTapcartTv=${isTapcartUseCase}&appUrl=${appUrl}&withHeader=${isEmail}`
          );
          project.widgetUrls = widgetUrls;
        }

        if (type === 'reply') {
          return project;
        }

        const projects = [project, ...state.projects];
        setState({ projects, numberOfTeamMemberTolstoys: getNumberOfTeamMemberTolstoys(projects) });

        if (appUrl && publishMethod === publishMethodOptions.bubble) {
          saveRules(
            {
              rules: [],
              enabled: true,
              showOnDomains: [appUrl],
            },
            project
          );
        }

        return project;
      } catch (error) {
        handleError(error, setState);
      } finally {
        setState({ creating: false });
      }
    },
  updateProject:
    input =>
    async ({ getState, setState }) => {
      try {
        delete input.updatedAt;
        delete input.createdAt;
        delete input.steps;

        if (getIsDeletedTypeKey(input.typeKey)) {
          input.typeKey = getTolstoyTypeKey(input.appKey);
        }

        const response = await graphqlRequest(
          graphqlOperation(updateProject, {
            input: {
              ...input,
              stepsCount: input.stepsOrder.length,
              collectAfterStep: input.collectAfterStep || [],
            },
          })
        );
        const project = response.data.updateProject;

        sortSteps(project);

        const projects = getState().projects;
        projects[projects.findIndex(p => p.id === project.id)] = project;
        setState({ projects: [...projects] });

        return project;
      } catch (error) {
        handleError(error, setState);
        return null;
      }
    },
  deleteProject:
    input =>
    async ({ getState, setState }, { appKey }) => {
      try {
        input.typeKey = getTolstoyTypeKey(appKey, PROJECT_TYPES.deleted);
        await graphqlRequest(graphqlOperation(updateProject, { input }));
        const projects = getState().projects;
        const index = projects.findIndex(p => p.id === input.id);
        projects.splice(index, 1);

        setState({
          projects: [...projects],
          numberOfTeamMemberTolstoys: getNumberOfTeamMemberTolstoys(projects),
        });
      } catch (error) {
        handleError(error, setState);
      }
    },
  createProjectStep:
    (input, currentProject) =>
    async ({ getState, setState }) => {
      try {
        const step = await postRequest('project-actions', '/actions/projects/steps', {
          body: {
            step: input,
          },
        });

        let updatedProject;

        const addStepToProject = project => {
          const newProject = { ...project };
          if (!newProject.steps.items) {
            newProject.steps.items = [];
          }

          if (!newProject.stepsOrder) {
            newProject.stepsOrder = [];
          }

          newProject.steps.items.push(step);
          newProject.stepsOrder.push(step.name);
          return newProject;
        };

        const newProjects = [...getState().projects].map(project => {
          if (project.id === currentProject.id) {
            const newProject = addStepToProject(project);
            updatedProject = newProject;
            return newProject;
          }

          return project;
        });

        setState({ projects: newProjects });
        return updatedProject || addStepToProject(currentProject);
      } catch (error) {
        handleError(error, setState);
        return null;
      }
    },
  updateProjectStep:
    (input, project) =>
    async ({ setState, getState }) => {
      const stepIndex = project.steps.items.findIndex(i => i.id === input.id);
      const originalStep = project.steps.items[stepIndex];
      project.steps.items[stepIndex] = input;
      try {
        cleanStep(input);
        setState({ projects: [...getState().projects] });

        const response = await graphqlRequest(graphqlOperation(updateProjectStep, { input }));
        const step = response.data.updateProjectStep;

        step.type = getTolstoyStepType(step?.type);
        const stepIndex = project.steps.items.findIndex(i => i.id === input.id);
        project.steps.items[stepIndex] = step;

        const projects = getState().projects.map(currentProject => {
          if (currentProject.id === project.id) {
            return project;
          }

          return currentProject;
        });

        setState({ projects: projects });
        return project;
      } catch (error) {
        project.steps.items[stepIndex] = originalStep;
        setState({ projects: [...getState().projects] });

        handleError(error, setState);
        return null;
      }
    },
  deleteProjectStep:
    (input, project) =>
    async ({ getState, setState }, { appKey }) => {
      try {
        const { steps, stepsOrder } = await deleteRequest(
          'project-actions',
          '/actions/projects/steps',
          {
            body: {
              stepId: input.id,
              appKey,
            },
          }
        );

        project.steps.items = steps;
        project.stepsOrder = stepsOrder;
        sortSteps(project);
        setState({ projects: [...getState().projects] });

        return project;
      } catch (error) {
        handleError(error, setState);
        return null;
      }
    },
  resetProjectsGoogleAnalytics:
    appUrl =>
    async ({ getState, setState }, { appKey }) => {
      try {
        await postRequest('project-actions', '/actions/projects/reset-projects-google-analytics', {
          body: {
            appKey,
            appUrl,
          },
        });

        const { projects } = getState();

        const newProjects = projects.map(project => {
          if (project.appUrl !== appUrl) {
            return project;
          }

          const newProject = { ...project };
          delete newProject.googleAnalyticsID;
          return newProject;
        });

        setState({ projects: newProjects });
        return true;
      } catch (error) {
        handleError(error, setState);
        return null;
      }
    },
  invalidateProjectsConfigs:
    appUrl =>
    async ({ setState }, { appKey }) => {
      try {
        const response = await postRequest(
          'publish-actions',
          '/actions/projects/clear-configs-by-app-key',
          {
            body: {
              appKey,
              appUrl,
            },
          }
        );
        if (response.err) {
          Utils.logError('failed to clear config', response.err);
        }

        return response;
      } catch (error) {
        handleError(error, setState);
        return null;
      }
    },
  cloneProject:
    ({ projectId, tolstoyType, title, steps, privateLibrary, appKey, publishMethod }) =>
    async ({ getState, setState }, { appKey: currentAppKey }) => {
      const { showPersonalLibrary } = getState();

      try {
        const response = await postRequest('project-actions', '/actions/projects/copy', {
          body: {
            appKey: appKey || currentAppKey,
            projectId,
            tolstoyType,
            title,
            steps,
            private: privateLibrary ?? showPersonalLibrary,
            publishMethod,
          },
        });
        if (response.err) {
          Utils.logError("the project was duplicated but wasn't published", response.err);
        }

        return response;
      } catch (error) {
        handleError(error, setState);
        return null;
      }
    },
  exportProjectResponses:
    projectId =>
    async ({ setState }, { appKey }) => {
      try {
        await postRequest('project-actions', '/actions/projects/responses/export', {
          body: {
            appKey,
            projectId,
          },
        });
      } catch (error) {
        handleError(error, setState);
      }
    },
  publishProject:
    projectId =>
    async ({ setState, getState }, { appKey }) => {
      try {
        if (!projectId) {
          return 'no project';
        }

        await postRequest('project-actions', '/actions/projects/publish', {
          body: {
            projectId,
          },
        });

        const response = await graphqlRequest(
          graphqlOperation(updateProject, { input: { id: projectId } })
        );
        const updatedProject = response.data.updateProject;
        if (updatedProject.discover) {
          await postRequest(
            'project-actions',
            '/actions/projects/enable-video-recommendation-feature',
            {
              body: {
                appKey,
              },
            }
          );
        }

        const newProjects = getState().projects.map(project => {
          if (project.id === updatedProject.id) {
            return updatedProject;
          }

          return project;
        });

        setState({ projects: newProjects });
      } catch (error) {
        handleError(error, setState);
        return error;
      }
    },
  getQuizOptionDisabled:
    (project, isEnabled, maxNumberOfPartsInTolstoy, maxNumberOfAnswersInTolstoy) => () => {
      if (!Utils.isQuiz(project?.tolstoyType)) {
        return false;
      }

      if (!isEnabled) {
        return true;
      }

      const partsNumber = project?.steps?.items?.length;
      let stepsAnswersLength = 0;

      project?.steps?.items?.forEach(step => {
        const answersLength = step?.answers?.length;
        if (answersLength > stepsAnswersLength) {
          stepsAnswersLength = answersLength;
        }
      });

      if (
        maxNumberOfPartsInTolstoy < partsNumber ||
        stepsAnswersLength > maxNumberOfAnswersInTolstoy
      ) {
        return true;
      }

      return false;
    },
  copyMacroSteps:
    (fromProjectId, toProjectId) =>
    async ({ getState, setState }) => {
      const { steps: newSteps, project: projectUpdates } = await postRequest(
        'project-actions',
        '/actions/copy-parts',
        {
          body: {
            fromProjectId,
            toProjectId,
          },
        }
      );

      const { projects } = getState();

      const newProjects = projects.map(project => {
        if (toProjectId === project.id) {
          const updatedProject = {
            ...project,
            ...projectUpdates,
            widgetSettings: project.widgetSettings,
            steps: { items: [...project.steps.items, ...newSteps] },
          };

          sortSteps(updatedProject);

          return updatedProject;
        }

        return project;
      });

      setState({ projects: newProjects });
    },
  getStepByName: (firstStepName, project) => () => {
    return project?.steps?.items.find(step => {
      return step.name === firstStepName;
    });
  },
  getStepDescription: (project, step) => () => {
    if (!step) {
      return null;
    }

    const { description, name: stepName } = step;

    if (description) {
      return description;
    }

    if (!project) {
      return stepName;
    }

    const partNumber = project.stepsOrder.indexOf(stepName);

    if (partNumber !== -1) {
      return `Part ${partNumber + 1}`;
    }

    return stepName;
  },
  getDisplayedProjects:
    () =>
    ({ getState }) => {
      const { projects } = getState();

      return projects;
    },
  subscribeProjects:
    () =>
    ({ setState, getState }, { appKey, history, setSnackbar }) => {
      try {
        const subscription = API.graphql(
          graphqlOperation(onProjectChangeByAppKey, { appKey })
        ).subscribe({
          next: data => {
            const updatedProject = data?.value?.data?.onProjectChangeByAppKey;
            if (updatedProject?.typeKey?.match(SUPPORTED_TYPE_KEYS.reply)) {
              return;
            }

            const { projects } = getState();
            let newProjects;
            if (updatedProject.typeKey.includes(SUPPORTED_TYPE_KEYS.deleted)) {
              let isDeleted = false;
              newProjects = projects.filter(project => {
                if (
                  project.id !== updatedProject.id ||
                  project.typeKey.includes(SUPPORTED_TYPE_KEYS.deleted) ||
                  project.publishMethod.includes(publishMethodOptions.studio)
                ) {
                  return project;
                }

                isDeleted = true;
              });

              if (window.location.href.includes(updatedProject.id) && isDeleted) {
                setSnackbar('This project has been deleted', 'warning');
                navigateToDashboard(history);
              }
            } else if (!projects.find(({ id }) => id === updatedProject.id)) {
              sortSteps(updatedProject);

              newProjects = [updatedProject, ...projects];
            } else {
              sortSteps(updatedProject);
              newProjects = projects.map(project => {
                if (project.id === updatedProject.id) {
                  return updatedProject;
                }

                return project;
              });
            }

            setState({ projects: newProjects });
          },
          subscribeProjectStep: () => () => {},
          error: ({ error: { errors = [] } = {} }) => {
            Utils.logErrorMessage(
              errors[0]?.message || 'Error in onUpdateProjectByAppKey subscribe'
            );
          },
        });

        setState({ subscription });
      } catch (error) {
        Utils.logError('Cannot subscribe to projects', error);
      }
    },
  unsubscribeProjects:
    () =>
    ({ getState, setState }) => {
      getState().subscription?.unsubscribe();

      setState({ subscription: null });
    },
  clearProjectsStore:
    () =>
    ({ setState }) => {
      const { subscription, ...newState } = initialState;

      setState(newState);
    },
  duplicateProject:
    ({ project, setLoaders, setCurrentProjectId, setCurrentModal, publishMethod }) =>
    async ({ dispatch }) => {
      setLoaders(true, publishMethod);

      const { projectId } = await dispatch(
        actions.cloneProject({
          projectId: project.id,
          privateLibrary: project.privateLibrary,
          publishMethod: publishMethod || project?.publishMethod,
        })
      );

      if (!projectId) {
        setLoaders(false, '');
        return;
      }

      const projects = await dispatch(
        actions.fetchProjects({ withoutLoading: true, withoutChangingLibrary: true })
      );
      setLoaders(false, '');
      setCurrentProjectId(projects[0].id);
      setCurrentModal(TOLSTOY_RENAME_MODAL_KEY);
    },
  finishExportProjectToStore:
    (updatedProject, ruleGroup) =>
    async ({ setState, getState }) => {
      const projects = getState().projects;
      projects[projects.findIndex(p => p.id === updatedProject.id)] = updatedProject;
      setState({ projects: [...projects] });

      const { publishId } = await postRequest(
        'project-actions',
        '/actions/projects/export-to-store',
        {
          body: {
            projectId: updatedProject.id,
            publishId: updatedProject.publishId,
            steps: updatedProject.steps.items,
            ruleGroup,
            appKey: updatedProject.appKey,
          },
        }
      );
      return publishId;
    },
  startExportProjectToStore:
    (project, setLoaders, setCurrentProjectId, setCurrentModal) =>
    async ({ dispatch }) => {
      setLoaders(true);

      const { projectId } = await dispatch(
        actions.cloneProject({
          projectId: project.id,
          privateLibrary: project.privateLibrary,
        })
      );

      if (!projectId) {
        setLoaders(false);
        return;
      }

      const projects = await dispatch(
        actions.fetchProjects({ withoutLoading: true, withoutChangingLibrary: true })
      );
      setLoaders(false);
      setCurrentProjectId(projects[0].id);
      setCurrentModal(EXPORT_TO_STORE_MODAL_KEY);
    },
  getFirstStep:
    ({ steps, stepsOrder }) =>
    () => {
      return steps.items.find(step => step?.name === stepsOrder[0]);
    },
  getProjectUrl: (publishId, isFeed) => () => {
    return `${APP_CONFIG.PUBLISH_URL}/${isFeed ? 'feed/' : ''}${publishId}`;
  },
  publishToShopApp:
    publishId =>
    async ({ dispatch }) => {
      await postRequest('shop', `/publish/${publishId}`);
      dispatch(actions.updateIsLive(publishId, true));
    },
  unpublishFromShopApp:
    publishId =>
    async ({ dispatch }) => {
      await postRequest('shop', `/unpublish/${publishId}`);
      dispatch(actions.updateIsLive(publishId, false));
    },
  updateIsLive:
    (publishId, isLive) =>
    ({ getState, setState, dispatch }) => {
      const targetProject = dispatch(actions.getProjectByPublishId(publishId));
      if (!targetProject) {
        return;
      }

      const nextProjects = getState().projects.map(project => {
        if (project.id === targetProject.id) {
          return {
            ...targetProject,
            live: isLive,
          };
        }

        return project;
      });

      setState({ projects: nextProjects });
    },
  getIsAnyProjectLive:
    () =>
    ({ getState }) => {
      const { projects } = getState();

      return projects.some(({ live }) => live);
    },
  getShopProjects:
    () =>
    ({ getState }) => {
      const { projects } = getState();
      return projects.filter(({ publishMethod }) => publishMethod === publishMethodOptions.shopApp);
    },
};

const handleError = (error, setState) => {
  Utils.logError(null, error);
  setState({ error, loading: false });
};

const sortSteps = project => {
  if (!project) {
    return;
  }

  const getSortedProjectSteps = (projectSteps, stepsOrder) => {
    return [...projectSteps].sort(
      (a, b) => stepsOrder.indexOf(a.name) - stepsOrder.indexOf(b.name)
    );
  };

  project.steps.items = getSortedProjectSteps(project.steps.items, project.stepsOrder);
};

const cleanStep = step => {
  ['createdAt', 'updatedAt', 'owner', '__typename'].forEach(e => delete step[e]);
};

const ProjectsStore = createStore({ initialState, actions, name: 'Projects' });

const projectsSelector = (state, args) => {
  const project = state.projects?.find(project => project.id === args?.projectId);
  const step = project?.steps?.items.find(step => step?.id === args?.stepId);

  return {
    ...state,
    project,
    step,
  };
};

const liveProjectsSelector = state => {
  const liveProjects = state.projects?.filter(project => project.live) || [];

  return {
    liveProjects,
  };
};

export const useProjects = createHook(ProjectsStore, { selector: projectsSelector });

export const useLiveProjects = createHook(ProjectsStore, { selector: liveProjectsSelector });

export const useProjectActions = createActionsHook(ProjectsStore);

export const ProjectsContainer = createContainer(ProjectsStore, {
  onInit:
    () =>
    ({ dispatch }) => {
      dispatch(actions.subscribeProjects());
      dispatch(actions.fetchProjects());
    },
  onCleanup:
    () =>
    ({ dispatch }) => {
      dispatch(actions.clearProjectsStore());
      dispatch(actions.unsubscribeProjects());
    },
});
