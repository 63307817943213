import React from 'react';
import styled from 'styled-components';
import PreviewMobileNotch from 'app/src/pages/modals/publish/previews/static_preview_items/PreviewMobileNotch';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import PreviewContentGroup from 'app/src/pages/modals/publish/previews/static_preview_items/PreviewContentGroup';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import { SmallSphere } from 'app/src/pages/modals/publish/previews/static_preview_items/commonPreviewItems';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';

const EmbedCarouselStaticPreview = ({ children }) => {
  return (
    <LayoutRoot>
      <TopContainer>
        <PreviewMobileNotch />
        <PreviewContentGroup />
      </TopContainer>
      <PreviewContainer>
        <SmallSphere />
        {children}
      </PreviewContainer>
      <PreviewContentGroup />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)`
  width: 100%;
  padding: 10px 12px;
`;

const TopContainer = styled(VerticalFlex)`
  gap: 10px;
`;

const PreviewContainer = styled(Gap8VerticalFlex)``;

export default EmbedCarouselStaticPreview;
