import React from 'react';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { VerticalFlexCentered } from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import ShopIcon from 'app/src/pages/dashboard/components/cases/images/icons/ShopIcon';
import styled from 'styled-components';

type Props = {};

const OnboardingTemplatesEndShopHeader = () => {
  return (
    <LayoutRoot>
      Your videos are now live
      <BottomLineContainer>
        on <StyledShopIcon /> app!
      </BottomLineContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlexCentered)``;

const BottomLineContainer = styled(HorizontalFlex)`
  gap: 14px;
`;

const StyledShopIcon = styled(ShopIcon)`
  transform: translateY(5px) scale(1.3);
`;

export default OnboardingTemplatesEndShopHeader;
