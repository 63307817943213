export const TABLET_MEDIA_QUERY = 840;
export const DASHBOARD_TABLET_BREAKPOINT = 1000;
export const MOBILE_BREAKPOINT = 450;
export const PREVIEW_BREAKPOINT = 1080;
export const HORIZONTAL_MODAL_HEIGHT_BREAKPOINT = 360;
export const SMALL_PHONE_HEIGHT_BREAKPOINT = 600;
export const HORIZONTAL_EDIT_STEP_BREAKPOINT = 1343;
export const VERTICAL_EDIT_STEP_BREAKPOINT = 1043;
export const LAPTOP_SCREEN_BREAKPOINT = 835;

export const TABLET_MAX_WIDTH = `max-width: ${TABLET_MEDIA_QUERY}px`;
export const MOBILE_MAX_WIDTH = `max-width: ${MOBILE_BREAKPOINT}px`;
export const DASHBOARD_TABLET_MAX_WIDTH = `max-width: ${DASHBOARD_TABLET_BREAKPOINT}px`;
export const PREVIEW_MAX_WIDTH = `max-width: ${PREVIEW_BREAKPOINT}px`;
export const HORIZONTAL_MODAL_MAX_HEIGHT = `max-height: ${HORIZONTAL_MODAL_HEIGHT_BREAKPOINT}px`;
export const SMALL_PHONE_MAX_HEIGHT = `max-height: ${SMALL_PHONE_HEIGHT_BREAKPOINT}px`;
export const HORIZONTAL_EDIT_STEP_MAX_WIDTH = `max-width: ${HORIZONTAL_EDIT_STEP_BREAKPOINT}px`;
export const VERTICAL_EDIT_STEP_MAX_WIDTH = `max-width: ${VERTICAL_EDIT_STEP_BREAKPOINT}px`;
export const LAPTOP_MAX_HEIGHT = `max-height: ${LAPTOP_SCREEN_BREAKPOINT}px`;
