import Spinner from 'app/src/basic_components/Spinner';
import React from 'react';
import ComponentWithOverlayLoader from 'shared/react/components/basic/ComponentWithOverlayLoader';

type Props = {
  isLoading: boolean;
  size?: number;
  children: React.ReactNode;
  shouldApplyLoadingOpacity?: boolean;
};

const OverlaySpinner = ({ isLoading, size = 40, children, ...props }: Props) => {
  return (
    <ComponentWithOverlayLoader
      customLoader={<Spinner size={size} />}
      isLoading={isLoading}
      {...props}
    >
      {children}
    </ComponentWithOverlayLoader>
  );
};

export default OverlaySpinner;
