import * as React from 'react';

const KlaviyoIcon = props => (
  <svg
    width={80}
    height={40}
    viewBox="0 0 80 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m.556 9.201 12.168-8.065a1.712 1.712 0 0 1 1.712 0l12.168 8.062c.477.239.69.809.452 1.299a.918.918 0 0 1-.452.452l-1.629 1.075c-3.99-6.298-12.314-8.17-18.61-4.191a13.574 13.574 0 0 0-4.19 4.191L.532 10.95a.988.988 0 0 1-.423-1.315c.105-.181.264-.34.448-.433Zm13.005-.095a10.182 10.182 0 0 0-8.59 4.761l2.797 1.856a6.734 6.734 0 0 1 9.251-2.295 6.763 6.763 0 0 1 2.294 2.295l2.796-1.856a9.993 9.993 0 0 0-8.548-4.761Zm0 6.738a3.445 3.445 0 0 0-2.968 1.75l1.883 1.235a1.69 1.69 0 0 0 2.176 0l1.883-1.235a3.393 3.393 0 0 0-2.974-1.75Zm21.126-5.837 3.047 4.376h-2.147l-2.306-3.396v3.396h-1.75V5.71h1.75v3.463l2.188-3.463h2.014l-2.796 4.297Zm6.057-4.269h-1.75v8.673h4.467v-1.75h-2.758l.04-6.923Zm7.886-.318 3.646 8.979h-1.963l-.344-.942h-2.876l-.343.942h-1.976L48.42 5.42h.21Zm.703 6.417-.782-2.241-.809 2.24h1.591Zm5.395-1.286-1.79-4.816h-1.964l3.646 8.979h.172l3.645-8.979h-1.972l-1.737 4.816Zm5.049 3.86h1.75V5.738h-1.75v8.673Zm8.672-8.673-1.763 2.999-1.762-2.999h-2.11l3.01 4.762v3.911h1.724v-3.914l3.035-4.762H68.45v.003Zm11.08 4.176a4.5 4.5 0 0 1-4.495 4.497 4.498 4.498 0 0 1 0-8.994c2.494.016 4.495 2.018 4.495 4.497Zm-1.683 0a2.797 2.797 0 1 0-5.593 0 2.807 2.807 0 0 0 2.797 2.798 2.796 2.796 0 0 0 2.796-2.785v-.013Z"
      fill="#7D8087"
    />
  </svg>
);

export default KlaviyoIcon;
