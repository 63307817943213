import { App } from 'app/src/types/app';
import { getRequest } from 'app/src/helpers/API';
import { getSearchParamsPath } from 'app/src/utils/url.utils';
import { INSTAGRAM, INSTAGRAM_GRAPH } from 'app/src/constants/intergrations.constants';
import {
  AppOptionsMap,
  VideoByAppResult,
  VideosByAppOptions,
  InstagramVideo,
  MediaByAppResult,
} from 'app/src/types/integrations';
import { v4 as uuidv4 } from 'uuid';
import { VideosPageVodAsset } from 'app/src/pages/dashboard/pages/videos/types/videos.types';

const FIRST_QUERY_PAGE_SIZE = 120;

export const normalizeInstagramVideoData = (data, owner, i) => {
  const { description, videoUrl, posterUrl, imageUrl, timestamp, type } = data;
  const id = uuidv4();

  return {
    ...data,
    externalId: data.id,
    id,
    type,
    newId: id,
    videoUrl,
    app: INSTAGRAM_GRAPH,
    createdAt: new Date(timestamp || new Date()).toISOString(),
    owner,
    stockAsset: { posterUrl: posterUrl || imageUrl, videoUrl },
    name: description || '',
    uploadType: INSTAGRAM_GRAPH,
  };
};

const normalizeUgcVideoData = (video, owner, i) => {
  const { username, postUrl } = video;
  const formattedVideo = {
    ...video,
    externalProviderData: JSON.stringify({ username, postUrl, posterUrl: video.imageUrl }),
  };

  return normalizeInstagramVideoData(formattedVideo, owner, i);
};

const getVideosByAppId = async (
  appId: string,
  options: VideosByAppOptions = {}
): Promise<VideoByAppResult<InstagramVideo>> => {
  const {
    cursor: nextCursor,
    appType = INSTAGRAM_GRAPH,
    maxNumberOfVideos = FIRST_QUERY_PAGE_SIZE,
  } = options;
  const searchParams = getSearchParamsPath({
    appId,
    nextCursor,
    maxNumberOfVideos: `${maxNumberOfVideos}`,
  });

  const APIKey = appType === INSTAGRAM ? 'instagram' : 'instagram-graph';
  const url = `/${APIKey}/get-videos-list?${searchParams}`;

  return getRequest(APIKey, url);
};

const getVideosByApp = async (app: App, options: VideosByAppOptions = {}) => {
  return getVideosByAppId(app.id, options);
};

export const getVideosByApps = async (
  apps: App[] = [],
  optionsMap: AppOptionsMap<VideosByAppOptions> = {}
) => {
  return await Promise.all(apps.map(app => getVideosByApp(app, optionsMap[app.id])));
};

type GetMediaByTaggingParams = {
  appId: string;
  options: VideosByAppOptions;
  accountVideosLength: number;
  owner: string;
};

export const getMediaByTagging = async ({
  appId,
  options = {},
  owner,
  accountVideosLength,
}: GetMediaByTaggingParams): Promise<MediaByAppResult<VideosPageVodAsset>> => {
  const { cursor: nextCursor } = options;
  const searchParams = getSearchParamsPath({
    appId,
    nextCursor,
  });

  const url = `/instagram-graph/get-videos-by-user-tagging?${searchParams}`;

  const res = (await getRequest('instagram-graph', url)) as MediaByAppResult<VideosPageVodAsset>;
  const { media } = res;

  return {
    ...res,
    media: media.map((video, i) => normalizeUgcVideoData(video, owner, i + accountVideosLength)),
  };
};

type GetMediaByHashtagParams = {
  appId: string;
  hashtag: string;
  options: VideosByAppOptions;
  accountVideosLength: number;
  owner: string;
};

export const getMediaByHashtag = async ({
  appId,
  hashtag,
  options = {},
  accountVideosLength,
  owner,
}: GetMediaByHashtagParams): Promise<MediaByAppResult<VideosPageVodAsset>> => {
  const { cursor: nextCursor, hashtagId } = options;
  const searchParams = getSearchParamsPath({
    appId,
    nextCursor,
    hashtag,
    hashtagId,
  });

  const url = `/instagram-graph/get-videos-by-hashtag?${searchParams}`;

  const res = (await getRequest('instagram-graph', url)) as MediaByAppResult<VideosPageVodAsset>;
  const { media } = res;

  return {
    ...res,
    media: media?.map((video, i) => normalizeUgcVideoData(video, owner, i + accountVideosLength)),
  };
};

export const getProductsForOldVideos = (appKey: string) => {
  return getRequest(
    'instagram-graph',
    `/instagram-graph/get-products-for-fetched-videos?appKey=${appKey}`
  );
};

export const startInstagramImport = async (appId: string) => {
  return getRequest('instagram-graph', `/instagram-graph/import-instagram-list?appId=${appId}`);
};

export const getUsernameFromPost = async (postUrl: string) => {
  return getRequest('instagram-graph', `/instagram-graph/username-from-post?url=${postUrl}`);
};
