import React, { FC } from 'react';
import styled from 'styled-components';
import { VerticalFlexCentered } from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextSmall } from 'shared/react/components/complex/Text';
import CsvFileIcon from 'src/images/CsvFileIcon';

const ImportFileHeader: FC = () => {
  return (
    <Container>
      <CsvFileIcon />
      <Title>Paste your CSV file url in here and click connect or enter key on your keyboard</Title>
    </Container>
  );
};

const Container = styled(VerticalFlexCentered)`
  max-width: 324px;
  gap: 16px;
  text-align: center;
`;

const Title = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.neutralDark};
`;

export default ImportFileHeader;
