import React from 'react';
import styled from 'styled-components';

const ToolBarButton = ({
  onClick,
  style,
  component: Component,
  icon: Icon,
  isSelected,
  ...props
}) => {
  if (Component) {
    return <Component {...props} />;
  }

  const onClickButton = e => {
    e.preventDefault();
    onClick(e, style);
  };

  return (
    <IconContainer onMouseDown={onClickButton} isSelected={isSelected}>
      <Icon fill={isSelected ? 'white' : undefined} />
    </IconContainer>
  );
};

const IconContainer = styled.div`
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background: ${({ isSelected, theme }) => (isSelected ? theme.colors.gray2 : '')};
  justify-content: center;
  width: 20px;
  height: 20px;
  transition: 0.3s;
  &:hover {
    background: ${({ isSelected, theme }) =>
      isSelected ? theme.colors.gray2 : theme.colors.ghostLight};
  }
`;

export default ToolBarButton;
