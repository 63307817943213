import React from 'react';

const MultipleTagsIcon = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.125 5.8125C3.125 5.30469 3.51562 4.875 4.0625 4.875C4.57031 4.875 5 5.30469 5 5.8125C5 6.35938 4.57031 6.75 4.0625 6.75C3.51562 6.75 3.125 6.35938 3.125 5.8125ZM7.96875 1.75C8.4375 1.75 8.94531 1.98438 9.29688 2.33594L16.0156 9.05469C16.9922 10.0312 16.9922 11.6328 16.0156 12.6094L10.8203 17.8047C9.84375 18.7812 8.24219 18.7812 7.26562 17.8047L0.546875 11.0859C0.195312 10.7344 0 10.2266 0 9.75781V3.625C0 2.60938 0.820312 1.75 1.875 1.75H7.96875ZM1.40625 10.1875L8.16406 16.9453C8.63281 17.4141 9.45312 17.4141 9.92188 16.9453L15.1562 11.7109C15.625 11.2422 15.625 10.4219 15.1562 9.95312L8.39844 3.19531C8.28125 3.07812 8.125 3 7.96875 3H1.875C1.52344 3 1.25 3.3125 1.25 3.625V9.75781C1.25 9.91406 1.28906 10.0703 1.40625 10.1875ZM12.0312 1.94531C12.2656 1.71094 12.6562 1.71094 12.9297 1.94531L18.4375 7.21875C20.5078 9.17188 20.5078 12.4922 18.4375 14.4453L13.5547 19.0938C13.3203 19.3281 12.9297 19.3281 12.6953 19.0938C12.4609 18.8203 12.4609 18.4297 12.6953 18.1953L17.5781 13.5469C19.1016 12.0625 19.1016 9.60156 17.5781 8.11719L12.0312 2.84375C11.7969 2.60938 11.7969 2.21875 12.0312 1.94531Z"
        fill="#090A0B"
      />
    </svg>
  );
};
export default MultipleTagsIcon;
