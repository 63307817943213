import React from 'react';
import ChoiceButton from 'shared/react/components/complex/ChoiceButton';
import { getLogoIcon } from 'shared/react/utils/icons';
import styled from 'styled-components';

function ButtonPreview({
  index,
  text,
  circle,
  type,
  brandColor,
  onClick,
  stepAnswersCount,
  showButtonsIndex,
  borderRadius,
  ...props
}) {
  const icon = getLogoIcon(type || '', brandColor);

  return (
    <StyledChoiceButton
      key={index}
      index={index}
      text={text}
      circle={circle}
      icon={icon}
      borderRadius={borderRadius}
      brandColor={brandColor}
      onClick={onClick}
      stepAnswersCount={stepAnswersCount}
      showButtonsIndex={showButtonsIndex}
      {...props}
    />
  );
}

const StyledChoiceButton = styled(ChoiceButton)`
  @media (${({ theme }) => theme.breakpoints.laptopMax}) {
    min-width: ${props => (props.stepAnswersCount % 3 ? 'calc(100% * (1 / 5) + 7em)' : '160px')};
    max-width: 200px;
  }
`;

export default ButtonPreview;
