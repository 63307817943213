import React from 'react';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';
import ColorCircle from 'src/basic_components/ColorCircle';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const MINIMUM_LIGHT_BRIGHTNESS = 240;
const DEFAULT_CIRCLE_SIZE = '24px';

const ColorPalettePopoverItem = ({ label, value, onClick, isSelected }) => {
  return (
    <HorizontalFlexWrap isSelected={isSelected} onClick={onClick}>
      <ColorCircleContainer>
        <ColorCircle
          hasBorder={tinycolor(value).getBrightness() > MINIMUM_LIGHT_BRIGHTNESS}
          color={value}
          size={DEFAULT_CIRCLE_SIZE}
        />
      </ColorCircleContainer>
      <Gap4VerticalFlex>
        <LabelContainer>{label}</LabelContainer>
        <ColorValueContainer>{value}</ColorValueContainer>
      </Gap4VerticalFlex>
    </HorizontalFlexWrap>
  );
};

const HorizontalFlexWrap = styled(Gap8HorizontalFlexWrap)`
  height: 50px;
  padding: 4px 8px;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  background: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.neutralLightest : 'transparent'};

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutralLightest};
  }
`;

const Gap4VerticalFlex = styled(VerticalFlex)`
  gap: 4px;
`;

const ColorCircleContainer = styled.div`
  grid-column: 1;
  grid-row: 1 / span 2;
`;

const LabelContainer = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.black};
`;

const ColorValueContainer = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.ghostDark};
`;

export default ColorPalettePopoverItem;
