import * as React from 'react';

const InfoFullIcon = ({ ...props }) => (
  <svg
    width={20}
    height={21}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 20.5c5.508 0 10-4.453 10-10 0-5.508-4.492-10-10-10-5.547 0-10 4.492-10 10a9.97 9.97 0 0 0 10 10Zm-1.563-6.875h.938v-2.5h-.938c-.546 0-.937-.39-.937-.938 0-.507.39-.937.938-.937h1.874a.95.95 0 0 1 .938.938v3.437h.313a.95.95 0 0 1 .937.938c0 .546-.43.937-.938.937H8.438c-.547 0-.938-.39-.938-.938 0-.507.39-.937.938-.937ZM10 8c-.703 0-1.25-.547-1.25-1.25 0-.664.547-1.25 1.25-1.25.664 0 1.25.586 1.25 1.25C11.25 7.453 10.664 8 10 8Z"
      fill="#52555B"
    />
  </svg>
);

export default InfoFullIcon;
