import React from 'react';

const LandingIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M0.21875 2.0625C0.40625 1.75 0.65625 1.46875 1 1.28125C1.21875 1.15625 1.5 1.0625 1.78125 1.03125C1.84375 1.03125 1.90625 1 2 1H14C15.0938 1 16 1.90625 16 3V13C16 14.125 15.0938 15 14 15H2C0.875 15 0 14.125 0 13V3C0 2.9375 0 2.875 0 2.8125C0.03125 2.53125 0.09375 2.28125 0.21875 2.0625ZM1.5 13C1.5 13.2812 1.71875 13.5 2 13.5H14C14.25 13.5 14.5 13.2812 14.5 13V5H1.5V13Z"
        fill="#2F3137"
      />
    </svg>
  );
};

export default LandingIcon;
