export const END_STEP = 'end';
export const FREE_TEXT_RESPONSE_KEY = 'Input';
export const LEAD_FORM_RESPONSE_KEY = 'MultiInput';
export const VIDEO_RESPONSE_KEY = 'Video';
export const AUDIO_RESPONSE_KEY = 'Audio';
export const IMAGE_RESPONSE_KEY = 'Image';
export const CALENDLY_RESPONSE_KEY = 'Calendly';
export const PROMO_CODE_RESPONSE_KEY = 'PromoCode';
export const CHAT_RESPONSE_KEY = 'Chat';
export const LINK_RESPONSE_KEY = 'Link';
export const LINK_NEXT_KEY = 'url';
export const OPEN_INTERCOM_RESPONSE_KEY = 'Intercom';
export const OPEN_GORGIAS_RESPONSE_KEY = 'Gorgias';
export const OPEN_TAWK_TO_RESPONSE_KEY = 'TawkTo';
export const OPEN_LIVE_CHAT_RESPONSE_KEY = 'LiveChat';
export const OPEN_HUBSPOT_RESPONSE_KEY = 'HubSpot';
export const OPEN_HELP_SCOUT_RESPONSE_KEY = 'HelpScout';
export const OPEN_DRIFT_RESPONSE_KEY = 'Drift';
export const OPEN_ZENDESK_RESPONSE_KEY = 'Zendesk';
export const OPEN_FRESHDESK_RESPONSE_KEY = 'Freshdesk';
export const OPEN_REAMAZE_RESPONSE_KEY = 'Re:amaze';
export const OPEN_TIDIO_RESPONSE_KEY = 'Tidio';
export const OPEN_CERTAINLY_RESPONSE_KEY = 'Certainly';
export const OPEN_ADA_RESPONSE_KEY = 'Ada';
export const EVENTS = {
  pageView: 'pageView',
  conversion: 'conversion',
  sessionStart: 'sessionStart',
  sessionEnd: 'sessionEnd',
  clickCta: 'clickCta',
  calendarInvite: 'calendarInvite',
  submitInput: 'submitInput',
  collectInfo: 'collectInfo',
  videoResponse: 'videoResponse',
  audioResponse: 'audioResponse',
  imageResponse: 'imageResponse',
  quizResult: 'quizResult',
  tolstoyReply: 'tolstoyReply',
  tolstoyProductsReply: 'tolstoyProductsReply',
  tolstoyTextReply: 'tolstoyTextReply',
  openEmail: 'openEmail',
  emailClick: 'emailClick',
  clickViewProduct: 'clickViewProduct',
  openProductPageClick: 'openProductPageClick',
  feedProductModalOpen: 'feedProductModalOpen',
};
export const FIRST_CSM_WIDGET_QUERY_PARAM = 'first_csm';
export const FIRST_CSM_WIDGET_LOCAL_STORAGE_KEY = 'tolstoyFirstCsm';
export const GHOST_ACCOUNT_QUERY_PARAM = 'ghostAccount';
export const GHOST_ACCOUNT_LOCAL_STORAGE_KEY = 'ghostAccount';
export const TEMP_ID_QUERY_PARAM = 'tempId';
export const TEMP_ID_LOCAL_STORAGE_KEY = 'tempId';
export const PAYMENT_SUCCESS_QUERY_PARAM = 'paymentSuccess';
export const DELAY_FETCH_FEATURES_QUERY_PARAM = 'delayFetchFeatures';
export const PREVIEW_MOBILE_KEY = 'mobilePreview';
export const PREVIEW_DESKTOP_KEY = 'desktopPreview';
export const APP_KEY_LOCAL_STORAGE_KEY = 'appKey';
export const LOGIN_APP_KEY_LOCAL_STORAGE_KEY = 'loginAppKey';
export const INVITE_SESSION_STORAGE_KEY = 'tolstoyInvite';
export const TOLSTOY_GROUP_LOCAL_STORAGE_KEY = 'tolstoy-group';
export const APP_SETTINGS_LOCAL_STORAGE_KEY = 'tolstoy-app-settings';
export const REQUEST_TO_UPGRADE_PLAN_DATE_LOCAL_STORAGE_KEY =
  'tolstoy-request-to-upgrade-plan-date';
export const RENEW_SUBSCRIPTION_DATE_LOCAL_STORAGE_KEY = 'tolstoy-renew-subscription-date';
export const WIDGET_KEY = 'widget';
export const PUBLISH_KEY = 'publish';
export const LINK_KEY = 'link';
export const EMAIL_KEY = 'email';
export const QR_KEY = 'qr';
export const LINK_NAME = 'Link';
export const EMAIL_NAME = 'Email';
export const QR_NAME = 'QR';
export const ANSWER_TYPES_WITH_VALUE = [PROMO_CODE_RESPONSE_KEY, CALENDLY_RESPONSE_KEY];
export const ANSWER_NEXT_WITH_VALUE = [CHAT_RESPONSE_KEY, LINK_NEXT_KEY];
export const CHAT_PLATFORMS = [
  {
    id: OPEN_INTERCOM_RESPONSE_KEY,
    name: 'Intercom',
  },
  {
    id: OPEN_GORGIAS_RESPONSE_KEY,
    name: 'Gorgias',
  },
  {
    id: OPEN_TAWK_TO_RESPONSE_KEY,
    name: 'tawk.to',
  },
  {
    id: OPEN_LIVE_CHAT_RESPONSE_KEY,
    name: 'LiveChat',
  },
  {
    id: OPEN_HUBSPOT_RESPONSE_KEY,
    name: 'HubSpot',
  },
  {
    id: OPEN_HELP_SCOUT_RESPONSE_KEY,
    name: 'HelpScout',
  },
  {
    id: OPEN_DRIFT_RESPONSE_KEY,
    name: 'Drift',
  },
  {
    id: OPEN_ZENDESK_RESPONSE_KEY,
    name: 'Zendesk',
  },
  {
    id: OPEN_FRESHDESK_RESPONSE_KEY,
    name: 'Freshdesk',
  },
  {
    id: OPEN_REAMAZE_RESPONSE_KEY,
    name: 'Re:amaze',
  },
  {
    id: OPEN_ADA_RESPONSE_KEY,
    name: 'Ada',
  },
  {
    id: OPEN_TIDIO_RESPONSE_KEY,
    name: 'Tidio',
  },
  {
    id: OPEN_CERTAINLY_RESPONSE_KEY,
    name: 'Certainly',
  },
];
export const STEP_KEY = 'step';
export const PRODUCTS_KEY = 'Products';
export const NON_QUALIFIED_EMAILS = ['gmail', 'outlook', 'icloud'];
export const CUBE_JS_KEY = 'cubejsToken';
export const STUDIO_VIDEO_GENERATION_PATH = '/studio';
