import { createContainer, createHook, createStore } from 'react-sweet-state';

const initialState = {
  lastUsedShareType: false,
  lastUsedEmailTabType: false,
  lastUsedPublishType: false,
};

const actions = {
  setLastUsedShareTypes:
    props =>
    ({ getState, setState }) => {
      setState({ ...getState(), ...props });
    },
  setLastUsedPublishTypes:
    props =>
    ({ getState, setState }) => {
      setState({ ...getState(), ...props });
    },
  clearShareTypes:
    () =>
    ({ setState }) => {
      setState({ ...initialState });
    },
};

const ShareTypesStore = createStore({ initialState, actions });

export const useShareTypes = createHook(ShareTypesStore);

export const ShareTypesContainer = createContainer(ShareTypesStore);
