import React, { useState } from 'react';
import styled from 'styled-components';
import StandardModal from '../../../pages/modals/StandardModal';
import { TextH5, TextSmall } from 'shared/react/components/complex/Text';
import Button from 'shared/react/components/complex/Button';
import TrophyIcon from 'app/src/images/TrophyIcon';
import VSuccessIcon from 'app/src/images/VSuccessIcon';
import { useBilling } from 'app/src/hooks/useBillings';
import { navigateToBilling } from 'app/src/utils/navigation.utils';
import { useHistory } from 'react-router-dom';

const TEXT = {
  true: {
    false: {
      header: 'You approved members request to become a Creator',
      button: 'Manage team',
      icon: <VSuccessIcon />,
    },
    true: {
      header: 'You’re all good!',
      description: 'Someone already approved this request,',
      button: 'Manage team',
      icon: <VSuccessIcon />,
    },
  },

  false: {
    false: {
      header: 'Request to upgrade',
      description:
        'Request to be promoted to Creator Pro to get unlimited recordings and other cool features.',
      button: 'Send request now',
      icon: <TrophyIcon />,
    },
    true: {
      header: 'Request was sent',
      description:
        'We’ve sent your request to the admin of your account. We will inform you as soon as we hear from him.',
      button: 'Back to home',
      icon: <VSuccessIcon />,
    },
  },
};
const localStorageKey = 'requestProUpgradeFromAdmin';

const checkDateRequested = () => {
  const sentDatePlusDay = localStorage.getItem(localStorageKey);
  if (!sentDatePlusDay) {
    return false;
  }
  return +sentDatePlusDay > Date.now();
};

const setLocalStorageDateSelected = () => {
  const date = new Date();
  date.setDate(date.getDate() + 1);
  localStorage.setItem(localStorageKey, date.getTime());
};

const UpgradeToProModal = ({ open, onClose, isApprove = false, userEmail }) => {
  const [isRequested, setIsRequested] = useState(checkDateRequested());
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { sendCreatorProRequestToAdmin } = useBilling();

  const onSendRequest = async () => {
    if (isRequested || isApprove) {
      onClose();
      return;
    }
    setIsLoading(true);
    await sendCreatorProRequestToAdmin();
    setLocalStorageDateSelected();
    setIsLoading(false);
    setIsRequested(true);
  };

  const onNavigateToBilling = () => {
    navigateToBilling(history);
  };

  let { icon, header, description, button } = TEXT[isApprove][isRequested || !!userEmail];

  if (isApprove && !!userEmail) {
    description += ` ${userEmail} is a Creator.`;
  }

  return (
    <StandardModal onClose={onClose} removeDefaultPadding={true} width={'424px'} open={open}>
      <LayoutRoot>
        <UpperContainer>
          <HeaderContainer>
            {icon}
            <Header>{header}</Header>
          </HeaderContainer>
          {description && <Description>{description}</Description>}
        </UpperContainer>
        <ButtonContainer>
          <Button onClick={onSendRequest} loading={isLoading}>
            {button}
          </Button>
        </ButtonContainer>
        {isApprove && (
          <PlanAndBilling onClick={onNavigateToBilling}>
            See plan and billing details
          </PlanAndBilling>
        )}
      </LayoutRoot>
    </StandardModal>
  );
};

const LayoutRoot = styled.div`
  padding: 24px;
`;

const UpperContainer = styled.div`
  display: grid;
  gap: 8px;
`;

const HeaderContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const Header = styled(TextH5)`
  font-weight: 800;
`;

const Description = styled(TextSmall)`
  font-weight: 600;
`;

const PlanAndBilling = styled(TextSmall)`
  font-size: 12px;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.gray2};
  padding-top: 10px;
`;

const ButtonContainer = styled.div`
  padding-top: 48px;
`;

export default UpgradeToProModal;
