import React from 'react';
import styled from 'styled-components';

import { TextBody } from 'shared/react/components/complex/Text';
import StyledBadge from 'app/src/complex_components/StyledBadge';
import { getSessionDateOrTime } from 'app/src/context/SessionsStore';
import SessionMenu, { StyledSessionMenu } from './SessionMenu';
import { useProjects } from 'app/src/context/ProjectsStore';
import Utils from '../../utils';
import { getInitials } from './contacts.utils';
import SessionAssignee from './SessionAssignee';
import { CONTACT_CARD_TEST_ID } from 'app/src/constants/dataTestIds.constants';

const ContactCard = ({
  session,
  onSessionClick,
  active,
  onUpdateSessionStatus,
  user,
  teamMembers,
}) => {
  const [, { getProjectById }] = useProjects();

  const { id, name, email, read, projectId, lastEventAt, assignee: assigneeId } = session || {};
  const onClick = () => onSessionClick(session);
  const cardName = name || email;
  const initials = getInitials(cardName);

  const dateOrTime = getSessionDateOrTime(lastEventAt);

  const project = getProjectById(projectId);
  const projectName = project?.name ? `@${project?.name}` : '';

  return (
    <StyledContactCard data-test-id={CONTACT_CARD_TEST_ID} key={id} onClick={onClick}>
      <ContactCardContainer active={active}>
        <ContactCardInitials>{initials}</ContactCardInitials>
        {!read && (
          <ContactCardCounter>
            <StyledBadge counter={1} />
          </ContactCardCounter>
        )}
        <ContactCardTitle active={active} title={cardName}>
          {cardName}
          <SessionMenu
            session={session}
            project={project}
            active={active}
            onUpdateSessionStatus={onUpdateSessionStatus}
          />
        </ContactCardTitle>
        <ContactCardDateAndTime unread={!read} title={dateOrTime}>
          {dateOrTime}
        </ContactCardDateAndTime>
        <ContactCardContent title={projectName}>{projectName}</ContactCardContent>
        <SessionAssignee
          active={active}
          user={user}
          teamMembers={teamMembers}
          assigneeId={assigneeId}
        />
      </ContactCardContainer>
    </StyledContactCard>
  );
};

export default ContactCard;

const ContactCardInitials = styled.div`
  position: relative;
  grid-area: initials;
  height: 40px;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

const StyledContactCard = styled.div`
  height: 104px;
  margin: 0 16px;
  cursor: pointer;
  &:nth-of-type(odd) ${ContactCardInitials} {
    background-color: #d3f8ec;
  }
  &:nth-of-type(even) ${ContactCardInitials} {
    background-color: #ffaac9;
  }
  &:nth-of-type(3n + 2) ${ContactCardInitials} {
    background-color: #a5a6f6;
  }
`;

const ContactCardContainer = styled.div`
  display: grid;
  grid-template-areas:
    'initials title'
    'initials content'
    'gap gap'
    'assignee date';
  grid-template-columns: 40px auto;
  grid-template-rows: auto auto 8px 24px;
  column-gap: 10px;
  align-items: center;
  height: 100%;
  border-radius: 20px;
  padding: 16px;
  cursor: pointer;
  background-color: ${({ active, theme }) => (active ? theme.colors.black : theme.colors.white)};
  &:hover {
    background-color: ${({ active, theme }) => (active ? theme.colors.black : theme.colors.gray5)};
  }
  ${Utils.isMobile()
    ? ''
    : `&:hover ${StyledSessionMenu} {
    visibility: initial;
  }`}
`;

const ContactCardText = styled(TextBody)`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  align-self: end;
`;

const ContactCardTitle = styled(ContactCardText)`
  position: relative;
  padding-right: 10px;
  grid-area: title;
  font-size: 16px;
  line-height: 24px;
  color: ${({ active, theme }) => (active ? theme.colors.white : theme.colors.black)};
`;

const ContactCardContent = styled(ContactCardText)`
  grid-area: content;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.ghostDark};
  align-self: start;
  display: ${({ title }) => (title ? 'initial' : 'list-item')};
`;

const ContactCardDateAndTime = styled(ContactCardText)`
  grid-area: date;
  font-weight: 600;
  font-size: 12px;
  color: ${({ unread, theme }) => (unread ? theme.colors.blue15 : theme.colors.gray25)};
  text-align: end;
`;

const ContactCardCounter = styled.div`
  display: flex;
  grid-area: initials;
  position: relative;
  bottom: 18px;
  left: 29px;
`;
