import { PLAYER_EVENTS } from 'player-events';
import { getSessionId, getTolstoysActivity, pushTolstoyActivity } from '../common/cache.js';
import { WIDGET_MESSAGES_EVENTS } from '../constants/messages.constants.js';
import { EMBED_WIDGET } from '../constants/widgetConstants.js';

export const handleOpenOrCloseEvent = ({ type, publishId }) => {
  if (!type || !publishId) {
    return;
  }

  const timeStamp = Date.now();
  const sessionId = getSessionId();
  pushTolstoyActivity({ publishId, timeStamp, type, sessionId });
};

export const handleVideoPauseAndResumeEvents = event => {
  const { videoPause, videoResume } = PLAYER_EVENTS;

  const { publishId, playerType } = event.data;

  if (playerType !== EMBED_WIDGET) {
    return;
  }

  if (![videoPause, videoResume].includes(event.data.name)) {
    return;
  }

  const now = Date.now();
  const sessionId = getSessionId();

  const eventType =
    event.data.name === videoPause ? WIDGET_MESSAGES_EVENTS.PAUSE : WIDGET_MESSAGES_EVENTS.PLAY;

  pushTolstoyActivity({
    publishId,
    timeStamp: now,
    type: eventType,
    sessionId
  });
};

export const isTolstoyOpen = ({ publishId, sessionId }) => {
  if (!publishId || !sessionId) {
    return false;
  }

  const activities = getTolstoysActivity()?.[publishId];
  if (!activities?.length) {
    return false;
  }

  const lastActivity = activities[activities.length - 1];
  return lastActivity.type === WIDGET_MESSAGES_EVENTS.OPEN && lastActivity.sessionId === sessionId;
};
