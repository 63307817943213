import React, { useEffect, useState } from 'react';
import BasicSelect from 'shared/react/components/basic/basic_select/BasicSelect';
import styled, { useTheme } from 'styled-components';

const LanguageSelector = () => {
  const DEFAULT_LANGUAGE = 'English';
  const theme = useTheme();
  const [languages, setLanguages] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState(DEFAULT_LANGUAGE);

  useEffect(() => {
    if (!window.Localize) {
      return;
    }
    window.Localize.getAvailableLanguages(handelAvailableLanguages);
  }, []);

  const handelAvailableLanguages = (err, languages) => {
    if (err) {
      console.error('Error fetching languages:', err);
      return;
    }

    const transformedLanguages = languages.map(({ code, name }) => ({
      id: code,
      name: name,
    }));
    setLanguages(transformedLanguages);
    getAndSetCurrentLanguage(transformedLanguages);
  }

  const getAndSetCurrentLanguage = (transformedLanguages) => {
    const currentLanguageCode = Localize.getLanguage();
    const currentLanguage = transformedLanguages.find(
      (language) => language.id === currentLanguageCode
    )?.name || DEFAULT_LANGUAGE;
    setCurrentLanguage(currentLanguage);
  }

  const handleChange = (selectedLanguage) => {
    setCurrentLanguage(selectedLanguage.name);
    if (window.Localize) {
      window.Localize.setLanguage(selectedLanguage.id);
    }
  };

  return (
    <StyledBasicSelect
      className="notranslate"
      items={languages}
      inputBackground={theme.colors.white}
      value={currentLanguage}
      onChange={handleChange}
    />
  );
};

const StyledBasicSelect = styled(BasicSelect)`
  max-width: 412px;
`;

export default LanguageSelector;