import React, { useState } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';

import CopyIcon from 'app/src/images/Copy';
import Utils from '../utils';
import SnackBar from 'app/src/basic_components/SnackBar';
import styled from 'styled-components';
import Theme from 'shared/react/theme/Theme';
import { CircleButton } from 'app/src/basic_components/commonStyles';

export default function CopyTextField({
  label,
  value,
  onClick,
  iconTextColor,
  copyIconCircleBackground,
  putCopyInEnd,
  ...props
}) {
  const [successBar, setSuccessBar] = useState(false);
  const copyComponent = <CopyIcon fill={iconTextColor} />;

  function copy() {
    Utils.copyToClipboard(value);
    setSuccessBar(true);
    if (onClick) {
      onClick();
    }
  }

  return (
    <LayoutRoot>
      <TextField
        label={label}
        variant="outlined"
        InputProps={{
          readOnly: true,
          endAdornment: !putCopyInEnd && (
            <CustomInputAdornment position="end">
              <CircleButton onClick={copy} backgroundColor={copyIconCircleBackground}>
                {copyComponent}
              </CircleButton>
            </CustomInputAdornment>
          ),
        }}
        value={value}
        {...props}
      />
      {!!putCopyInEnd && (
        <CustomCircleButton onClick={copy} backgroundColor={copyIconCircleBackground}>
          {copyComponent}
        </CustomCircleButton>
      )}
      <SnackBar
        text="Copied to Clipboard!"
        open={successBar}
        setOpen={setSuccessBar}
        severity="success"
      />
    </LayoutRoot>
  );
}

const LayoutRoot = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 8px;
`;

const CustomCircleButton = styled(CircleButton)`
  grid-row: unset;
  grid-column: unset;
  max-height: 32px;
  max-width: 32px;
  & > * {
    padding: 0;
  }
`;

const CustomInputAdornment = styled(InputAdornment)`
  background: ${Theme.primary.main.background.normal};
`;
