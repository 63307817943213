import React, { useState } from 'react';
import styled, { useTheme, css } from 'styled-components';
import TextInput from 'app/src/basic_components/TextInput';
import { LINK_KEY } from 'app/src/constants/tolstoy.constants';
import EmptyShoppingBag from 'app/src/images/EmptyShoppingBag';
import LeadsTo from 'app/src/pages/project/pages/quiz/quiz_result/LeadsTo';
import ProductResultsMenu from 'app/src/pages/project/pages/quiz/quiz_result/ProductResultsMenu';
import QuizResult from 'app/src/pages/project/pages/quiz/quiz_result/QuizResult';
import Expander from 'shared/react/components/complex/expanders/Expander';
import QuizResultLinkIcon from 'shared/react/images/quiz/QuizResultLinkIcon';
import { useQuizContext } from 'app/src/pages/project/pages/quiz/QuizContext';
import QuizResultRightContainer from 'app/src/pages/project/pages/quiz/quiz_result/QuizResultRightContainer';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import ComponentWithOverlayLoader from 'shared/react/components/basic/ComponentWithOverlayLoader';
import Utils from 'shared/react/utils/utils';

function QuizResultExpander({ quizResult, index }) {
  const theme = useTheme();
  const { deletingQuizResultIds, onSetResultUrl, errors } = useQuizContext();
  const [chosenType, setChosenType] = useState(quizResult.type === 'step' ? 'Products' : LINK_KEY);
  const [productResult, setProductResult] = useState(quizResult.stepName);
  const errorHelperText = errors?.[index];

  const getResultSelection = () => {
    if (chosenType === LINK_KEY) {
      return (
        <UrlContainer
          error={!!errorHelperText}
          onClick={Utils.stopPropagation}
          placeholder={'Paste link here'}
          value={quizResult.url || ''}
          onChange={e => onSetResultUrl(index, e.target.value)}
          label={errorHelperText}
          inputProps={{
            maxLength: 500,
          }}
        />
      );
    }

    return (
      <ProductResultsMenu
        quizResultId={quizResult.id}
        quizResult={quizResult}
        errorHelperText={errorHelperText}
        currentProductResult={productResult}
        setCurrentProductResult={setProductResult}
      />
    );
  };

  const getIcon = () => {
    if (chosenType === LINK_KEY) {
      return <QuizResultLinkIcon />;
    }
    return (
      <IconContainer background={theme.colors.gray3}>
        <EmptyShoppingBag />
      </IconContainer>
    );
  };

  const getHeaderLeftContainer = () => {
    return (
      <LayoutRoot>
        <LeadsTo
          chosenType={chosenType}
          setChosenType={setChosenType}
          quizResultId={quizResult.id}
        />
        {getIcon()}
        {getResultSelection()}
      </LayoutRoot>
    );
  };

  const isBeingDeleted = deletingQuizResultIds.includes(quizResult.id);
  return (
    <ComponentWithOverlayLoader isLoading={isBeingDeleted} loaderColor={theme.colors.primary}>
      <Expander
        key={quizResult.id}
        title={quizResult.title}
        subtitle={quizResult.url}
        isLoading={isBeingDeleted}
        icon={<QuizResultLinkIcon />}
        isDefaultExpanded={quizResult.isDefaultExpanded}
        headerStyle={css`
          background: ${({ theme }) => theme.colors.white};
          border-radius: 16px;
        `}
        contentContainerStyle={css`
          padding: 16px;
        `}
        renderHeaderLeftContainer={getHeaderLeftContainer()}
        renderHeaderRightContainer={icon => (
          <QuizResultRightContainer icon={icon} quizResult={quizResult} />
        )}
      >
        <QuizResult quizResult={quizResult} />
      </Expander>
    </ComponentWithOverlayLoader>
  );
}

export default QuizResultExpander;

const UrlContainer = styled(TextInput)`
  background: ${({ theme }) => theme.colors.white};
  max-width: 280px;
  height: 48px;

  .MuiOutlinedInput-input {
    padding: 16px;
    overflow: auto;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme, error }) => (error ? theme.colors.warning : theme.colors.gray5)} !important;
  }
`;

const IconContainer = styled(VerticalFlex)`
  background: ${({ background }) => background};
  border-radius: 12px;
  width: 48px;
  height: 48px;
  padding: 12px;
  justify-content: center;
  align-items: center;
`;

const LayoutRoot = styled(Gap8HorizontalFlexWrap)`
  align-items: center;
  flex-wrap: nowrap;
`;
