import { TABLE_COLUMN_KEYS } from 'app/src/constants/cube.constants';
import SharedUtils from 'shared/react/utils/utils';
import {
  formatPrice,
  numberComparator,
  stringComparator,
} from 'app/src/complex_components/analytics/analyticsTable.utils';
import React from 'react';
import AnalyticsOverviewVideoNameCell from 'app/src/complex_components/analytics/videos/AnalyticsOverviewVideoNameCell';
import {
  ANALYTICS_TABLE_COLUMN_CLASSNAMES,
  ANALYTICS_TABLE_COLUMN_GROUPS,
  SPACER_COLUMN_ATTRIBUTES,
} from 'app/src/constants/analytics.constants';
import AnalyticsTableCell from 'app/src/complex_components/analytics/AnalyticsTableCell';

type Props = {
  getVodAssetName: (vodAssetId: string) => string;
  getVodAssetSource: (vodAssetId: string) => string;
};

export const getVideosTableColumns = ({ getVodAssetName, getVodAssetSource }: Props) => {
  return [
    {
      field: TABLE_COLUMN_KEYS.id,
      headerName: 'Video',
      flex: 2,
      valueGetter: ({ value }) => getVodAssetName(value),
      renderCell: ({ row }) => {
        return (
          <AnalyticsOverviewVideoNameCell vodAssetId={row.id} videoName={getVodAssetName(row.id)} />
        );
      },
      sortComparator: stringComparator,
      group: ANALYTICS_TABLE_COLUMN_GROUPS.general,
    },
    {
      field: TABLE_COLUMN_KEYS.plays,
      headerName: 'Plays',
      flex: 1,
      sortComparator: numberComparator,
      renderCell: ({ value }) => {
        return <AnalyticsTableCell text={Number(value).toLocaleString()} />;
      },
      group: ANALYTICS_TABLE_COLUMN_GROUPS.general,
    },
    {
      field: TABLE_COLUMN_KEYS.source,
      headerName: 'Source',
      flex: 1,
      valueGetter: ({ row }) => getVodAssetSource(row.id),
      renderCell: ({ value }) => {
        return <AnalyticsTableCell text={value} />;
      },
      valueGetterField: TABLE_COLUMN_KEYS.id,
      sortComparator: stringComparator,
      group: ANALYTICS_TABLE_COLUMN_GROUPS.general,
    },
    {
      field: 'conversionStart',
      group: `${ANALYTICS_TABLE_COLUMN_GROUPS.conversion}-${ANALYTICS_TABLE_COLUMN_GROUPS.spacer}`,
      ...SPACER_COLUMN_ATTRIBUTES,
    },
    {
      field: TABLE_COLUMN_KEYS.numOfOrders,
      headerName: 'Total orders',
      flex: 1,
      renderCell: ({ value }) => {
        return <AnalyticsTableCell text={Number(value).toLocaleString()} />;
      },
      sortComparator: numberComparator,
      group: ANALYTICS_TABLE_COLUMN_GROUPS.conversion,
      groupName: 'Conversion',
    },
    {
      field: TABLE_COLUMN_KEYS.conversionRate,
      headerName: 'Conversion rate',
      flex: 1,
      renderCell: ({ value }) => {
        return <AnalyticsTableCell text={`${SharedUtils.safeRoundNumber(value * 100, 1)}%`} />;
      },
      group: ANALYTICS_TABLE_COLUMN_GROUPS.conversion,
      groupName: 'Conversion',
    },
    {
      field: TABLE_COLUMN_KEYS.averageOrderValue,
      headerName: 'AOV',
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <AnalyticsTableCell
            text={formatPrice(SharedUtils.safeRoundNumber(row.averageOrderValue, 1), row.currency)}
          />
        );
      },
      sortComparator: numberComparator,
      group: ANALYTICS_TABLE_COLUMN_GROUPS.conversion,
      groupName: 'Conversion',
    },
    {
      field: TABLE_COLUMN_KEYS.inVideoRevenue,
      headerName: 'In-video revenue',
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <AnalyticsTableCell
            text={formatPrice(SharedUtils.safeRoundNumber(row.inVideoRevenue, 1), row.currency)}
          />
        );
      },
      sortComparator: numberComparator,
      group: ANALYTICS_TABLE_COLUMN_GROUPS.conversion,
      groupName: 'Conversion',
    },
    {
      field: TABLE_COLUMN_KEYS.postVideoRevenue,
      headerName: 'Post-video revenue',
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <AnalyticsTableCell
            text={formatPrice(SharedUtils.safeRoundNumber(row.postVideoRevenue, 1), row.currency)}
          />
        );
      },
      sortComparator: numberComparator,
      group: ANALYTICS_TABLE_COLUMN_GROUPS.conversion,
      groupName: 'Conversion',
    },
    {
      field: TABLE_COLUMN_KEYS.indirectRevenue,
      headerName: 'Indirect revenue',
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <AnalyticsTableCell
            text={formatPrice(SharedUtils.safeRoundNumber(row.indirectRevenue, 1), row.currency)}
          />
        );
      },
      sortComparator: numberComparator,
      group: ANALYTICS_TABLE_COLUMN_GROUPS.conversion,
      groupName: 'Conversion',
    },
    // {
    //   field: TABLE_COLUMN_KEYS.totalRevenue,
    //   group: ANALYTICS_TABLE_COLUMN_GROUPS.conversion,
    //   groupName: 'Conversion',
    //   hide: true,
    //   sortComparator: numberComparator,
    // },
    {
      field: 'engagementStart',
      group: `${ANALYTICS_TABLE_COLUMN_GROUPS.engagement}-${ANALYTICS_TABLE_COLUMN_GROUPS.spacer}`,
      ...SPACER_COLUMN_ATTRIBUTES,
    },
    {
      field: TABLE_COLUMN_KEYS.bounceRate,
      headerName: 'Bounce rate',
      flex: 1,
      renderCell: ({ value }) => {
        return <AnalyticsTableCell text={`${SharedUtils.safeRoundNumber(value * 100, 1)}%`} />;
      },
      description:
        'Bounce rate - Percentage of users who skipped the video after watching less than 2 seconds',
      group: ANALYTICS_TABLE_COLUMN_GROUPS.engagement,
      groupName: 'Engagement',
    },
    {
      field: TABLE_COLUMN_KEYS.averageWatchPercentage,
      headerName: 'Average watch time',
      flex: 1,
      renderCell: ({ value }) => {
        return <AnalyticsTableCell text={`${SharedUtils.safeRoundNumber(value * 100, 1)}%`} />;
      },
      group: ANALYTICS_TABLE_COLUMN_GROUPS.engagement,
      groupName: 'Engagement',
    },
    {
      field: TABLE_COLUMN_KEYS.watchedAllPercentage,
      headerName: 'Completed video',
      flex: 1,
      headerClassName: ANALYTICS_TABLE_COLUMN_CLASSNAMES.lastColumn,
      cellClassName: ANALYTICS_TABLE_COLUMN_CLASSNAMES.lastColumn,
      renderCell: ({ value }) => {
        return <AnalyticsTableCell text={`${SharedUtils.safeRoundNumber(value * 100, 1)}%`} />;
      },
      group: ANALYTICS_TABLE_COLUMN_GROUPS.engagement,
      groupName: 'Engagement',
    },
  ];
};
