import React, { FC, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { TextBody } from 'shared/react/components/complex/Text';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { useIsMobile } from 'shared/react/hooks/useIsMobile';

import UploadIcon from 'app/src/images/dashboard_v2/UploadIcon';

type Props = {
  videoReady?: boolean;
  isCreationFlow?: boolean;
  onDropFiles: (e: SyntheticEvent) => void;
  onSelectFilesClick: () => void;
};

const UploadFilesEmptyState: FC<Props> = ({
  videoReady,
  onDropFiles,
  onSelectFilesClick,
  isCreationFlow,
  children,
}) => {
  const isMobile = useIsMobile();

  const handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  if (videoReady) {
    return null;
  }

  return (
    <>
      <EmptyStateContainer
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={onDropFiles}
        isCreationFlow={isCreationFlow}
      >
        <UploadIconStyled />
        <EmptyStateText>
          {!isMobile && 'Drag & drop or'}
          <EmptyStateBrowseButton onClick={onSelectFilesClick}>choose file</EmptyStateBrowseButton>
          to upload
        </EmptyStateText>
        {children}
      </EmptyStateContainer>
    </>
  );
};

const EmptyStateContainer = styled(Gap8VerticalFlex)`
  align-items: center;
  justify-content: center;
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
  border: 1px dashed ${({ theme }) => theme.colors.gray4};
  border-radius: 8px;
  margin: ${({ isCreationFlow }) => (isCreationFlow ? '16px' : '')};
  background: ${({ isCreationFlow, theme }) => (isCreationFlow ? '' : theme.colors.gray32)};

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    margin: 0px;
  }
`;

const UploadIconStyled = styled(UploadIcon)`
  width: 40px;
  height: 40px;
`;

const EmptyStateText = styled(TextBody)`
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.gray2};
`;

const EmptyStateBrowseButton = styled.button`
  border: none;
  background: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.blue7};
  text-decoration: underline;
  cursor: pointer;
`;

export default UploadFilesEmptyState;
