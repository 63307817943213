import React, { FC } from 'react';
import styled from 'styled-components';
import { TextH5, TextSmall } from 'shared/react/components/basic/text/TextV2';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import StraightArrowRightIcon from 'src/images/creation_method_icons/StraightArrowRightIcon';
import Utils from 'src/utils';

interface BackToEditorButtonProps {
  saveReminder?: boolean;
  onClick: () => void;
}

const BackToEditorButton: FC<BackToEditorButtonProps> = props => {
  const isMobile = Utils.isMobile();
  const { saveReminder, onClick } = props;

  return (
    <BackToEditorContainer onClick={onClick} isMobile={isMobile}>
      <LeftArrowIcon />
      <BackText>Back to Tolstoy editor</BackText>
      {saveReminder && (
        <SaveReminderText>
          Don&apos;t forget to <b>save</b> first
        </SaveReminderText>
      )}
    </BackToEditorContainer>
  );
};

const BackToEditorContainer = styled(HorizontalFlex)`
  position: fixed;
  bottom: 32px;
  left: ${({ isMobile }) => (isMobile ? '50%' : 'calc(50% + 200px)')};
  transform: translate(-50%, 0);
  padding: 16px 32px;
  background: ${({ theme }) => theme.colors.neutralDarkest};
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  min-width: ${({ saveReminder }) => (saveReminder ? '350px' : '270px')};
  cursor: pointer;
  z-index: 1000;
`;

const LeftArrowIcon = styled(StraightArrowRightIcon)`
  transform: rotate(180deg);
  margin-right: 12px;
`;

const BackText = styled(TextH5)`
  color: ${({ theme }) => theme.colors.white};
`;

const SaveReminderText = styled(TextSmall)`
  margin-left: 12px;
  color: ${({ theme }) => theme.colors.neutralGray};
`;

export default BackToEditorButton;
