import React from 'react';
import CardImage from 'app/src/pages/dashboard/components/card/CardImage';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import PlayIcon from 'app/src/images/dashboard_v2/PlayIcon';
import styled from 'styled-components';
import useDynamicVideoIdsByProject from 'app/src/hooks/use-dynamic-video-ids-by-project/useDynamicVideoIdsByProject';
import { AssetRenditionsService } from 'shared/react/services/assets';
import { publishMethodOptions } from 'app/src/types/entities';
import { useRuleGroupByProjectId } from 'app/src/context/RulesStore';
import { useVideoActions } from 'app/src/context/VideosStore';

const getPaddedSteps = (videoIds: string[], length: number) =>
  Array.from({ length }, (_, i) => videoIds?.[i]);

type ImagePartsProps = {
  videoIds: string[];
  renderPoster: (videoId: string) => React.ReactNode;
  count?: number;
  rounded?: boolean;
};

const ImageParts = ({ videoIds, renderPoster, count = 1, rounded = false }: ImagePartsProps) => (
  <>
    {getPaddedSteps(videoIds, count).map((videoId, i) => (
      <FullSizeImage rounded={rounded} key={`${videoId}-${i}`}>
        {renderPoster(videoId)}
      </FullSizeImage>
    ))}
  </>
);

type CardImageProps = {
  publishMethod: string;
  videoIds: string[];
  renderPoster: (videoId: string) => React.ReactNode;
};

const CardImageByType = ({ publishMethod, ...props }: CardImageProps) => {
  switch (publishMethod) {
    case publishMethodOptions.email:
      return (
        <CarouselLayout>
          <ImageParts {...props} count={3} />
        </CarouselLayout>
      );
    case publishMethodOptions.landingPage:
      return (
        <LandingPageLayout>
          <ImageParts {...props} />
        </LandingPageLayout>
      );
    default:
      return (
        <LandingPageLayout>
          <ImageParts {...props} />
        </LandingPageLayout>
      );
  }
};

const OffsiteCardImage = ({ project }) => {
  const { getVideoById } = useVideoActions();
  const [ruleGroup] = useRuleGroupByProjectId(project.id);
  const { videoIds: dynamicVideoIds } = useDynamicVideoIdsByProject(
    project,
    ruleGroup,
    project.dynamic
  );

  const renderPoster = (videoId: string) => {
    if (videoId) {
      const poster = AssetRenditionsService.getAssetPoster(getVideoById(videoId));
      return <CardImage imageSrc={poster} />;
    }

    return <PlayIcon />;
  };

  const videoIds = project.dynamic
    ? dynamicVideoIds
    : project.steps?.items?.map(({ videoId }) => videoId);

  return (
    <CardImageByType
      publishMethod={project.publishMethod}
      videoIds={videoIds}
      renderPoster={renderPoster}
    />
  );
};

const FullSizeImage = styled(HorizontalFlex)`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.white};
  align-items: center;
  justify-content: center;

  img {
    aspect-ratio: 9 / 16;
  }
`;

const Grid = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  gap: 4px;
  padding: 12px;
  overflow: hidden;
`;

const CarouselLayout = styled(Grid)`
  grid-template-columns: repeat(3, minmax(72px, 80px));
  grid-template-rows: 138px;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  img {
    object-fit: cover;
  }
`;

const CommonLayoutStyles = styled.div`
  position: absolute;
`;

const LandingPageLayout = styled(CommonLayoutStyles)`
  width: 100%;
  height: 100%;
  padding: 12px;
`;

export default OffsiteCardImage;
