import Utils from 'app/src/utils';

const getCanInviteMoreTeamMembers = ({ teamMembers, emails, maxTeamMembers }) => {
  return teamMembers.length + emails.length <= maxTeamMembers;
};

const getIfAlreadyTeamMember = ({ teamMembers, emails }) => {
  return teamMembers.find(({ email }) => emails.includes(email));
};

const getEmailNotValid = ({ emails = [] }) => {
  return emails.find(email => !Utils.isEmail(email));
};

export const getEmailsErrorMessage = ({ teamMembers, emails, maxTeamMembers }) => {
  const emailNotValid = getEmailNotValid({ emails });
  if (emailNotValid) {
    return `${emailNotValid} is not a valid email address`;
  }

  const alreadyTeamMember = getIfAlreadyTeamMember({ teamMembers, emails });
  if (alreadyTeamMember) {
    return `${alreadyTeamMember.email} is already a team member`;
  }

  const canInviteMore = getCanInviteMoreTeamMembers({ teamMembers, emails, maxTeamMembers });
  if (!canInviteMore) {
    return `Team members limit is: ${maxTeamMembers}`;
  }

  return '';
};
