import {
  CONTAINS_KEY,
  EMAIL_KEY,
  ENDS_WITH_KEY,
  EQUALS_KEY,
  GREATER_THAN_KEY,
  IN_THE_LAST_X_DAYS_KEY,
  LESS_THAN_KEY,
  NOT_CONTAINS_KEY,
  NOT_EQUALS_KEY,
  NOT_IN_KEY,
  NOT_IN_THE_LAST_X_DAYS_KEY,
  ANY_VALUE_KEY,
  SIGNED_UP_AT_KEY,
  STARTS_WITH_KEY,
  URL_KEY,
  SESSION_COUNT_KEY,
  LAST_SEEN_AT_KEY,
  FIRST_SEEN_AT_KEY,
  IN_KEY,
  TOLSTOY_PLAY_SESSION_COUNT_KEY,
  TOLSTOY_IMPRESSIONS_SESSION_COUNT_KEY,
} from 'app/src/constants/ruleGroups.constants';

export const whereBaseAttributes = [
  {
    key: URL_KEY,
    title: 'URL',
  },
];

export const STOP_SHOWING_WIDGET_TO = {
  clickedX: 'stopShowingWidgetToClickedX',
  viewed: 'stopShowingWidgetToViewed',
};

export const stopShowingAttributes = [
  {
    key: STOP_SHOWING_WIDGET_TO.clickedX,
    title: 'Clicked X',
  },
  {
    key: STOP_SHOWING_WIDGET_TO.viewed,
    title: 'Viewed the Tolstoy',
  },
];

export const whoVisitorAttributes = [
  {
    key: SESSION_COUNT_KEY,
    title: 'Session count',
  },
  {
    key: LAST_SEEN_AT_KEY,
    title: 'Last seen at',
  },
  {
    key: FIRST_SEEN_AT_KEY,
    title: 'First seen at',
  },
  { key: TOLSTOY_PLAY_SESSION_COUNT_KEY, title: 'Play count' },
  { key: TOLSTOY_IMPRESSIONS_SESSION_COUNT_KEY, title: 'Impressions count' },
];

export const whoUserAttributes = [
  {
    key: EMAIL_KEY,
    title: 'Email',
  },
  {
    key: SIGNED_UP_AT_KEY,
    title: 'Signed up at',
  },
].concat(whoVisitorAttributes);

export const generalConditionTypesMenuItems = [
  {
    key: ANY_VALUE_KEY,
    title: 'Any value',
  },
  {
    key: EQUALS_KEY,
    title: 'Equals to',
  },
  {
    key: NOT_EQUALS_KEY,
    title: 'Not equals to',
  },
  {
    key: STARTS_WITH_KEY,
    title: 'Starts with',
  },
  {
    key: ENDS_WITH_KEY,
    title: 'Ends with',
  },
  {
    key: CONTAINS_KEY,
    title: 'Contains',
  },
  {
    key: NOT_CONTAINS_KEY,
    title: 'Does not contains',
  },
  { key: IN_KEY, title: 'In' },
  { key: NOT_IN_KEY, title: 'Not in' },
];

export const dateConditionMenuItems = [
  {
    key: GREATER_THAN_KEY,
    title: 'After',
  },
  {
    key: LESS_THAN_KEY,
    title: 'Before',
  },
  {
    key: IN_THE_LAST_X_DAYS_KEY,
    title: 'In the last X days',
  },
  {
    key: NOT_IN_THE_LAST_X_DAYS_KEY,
    title: 'Not in the last X days',
  },
];
export const numberConditionMenuItems = [
  {
    key: EQUALS_KEY,
    title: 'Equals',
  },
  { key: NOT_EQUALS_KEY, title: 'Not equals to' },
  {
    key: GREATER_THAN_KEY,
    title: 'Greater than',
  },
  {
    key: LESS_THAN_KEY,
    title: 'Less than',
  },
];

export const booleanConditionMenuItems = [
  {
    key: EQUALS_KEY,
    title: 'Equals',
  },
  { key: NOT_EQUALS_KEY, title: 'Not equals to' },
];

export const booleanValuesMenuItems = [
  {
    key: true,
    title: 'true',
  },
  {
    key: false,
    title: 'false',
  },
];

export const defaultConditionPlaceholder = 'Enter text';
const urlPlaceholder = 'Phrase or URL';
const emailPlaceholder = 'Enter an email address';
const daysFromTodayPlaceholder = 'How many days back from today?';
const enterNumberPlaceholder = 'Enter a number';
const inPlaceholder = 'Value 1, Value 2';

export const DESKTOP = 'desktop';
export const MOBILE = 'mobile';
export const ALL = 'all';
export const NONE = 'none';

const enterDatePlaceholder = 'Enter a date';
export const typePlaceholders = {
  [EMAIL_KEY]: emailPlaceholder,
  [URL_KEY]: urlPlaceholder,
};

export const conditionPlaceholders = {
  [ANY_VALUE_KEY]: '',
  [URL_KEY]: urlPlaceholder,
  [IN_KEY]: inPlaceholder,
  [NOT_EQUALS_KEY]: inPlaceholder,
  [EMAIL_KEY]: emailPlaceholder,
  [IN_THE_LAST_X_DAYS_KEY]: daysFromTodayPlaceholder,
  [NOT_IN_THE_LAST_X_DAYS_KEY]: daysFromTodayPlaceholder,
  [SESSION_COUNT_KEY]: enterNumberPlaceholder,
  date: enterDatePlaceholder,
  number: enterNumberPlaceholder,
};

export const numberConditionTypes = [IN_THE_LAST_X_DAYS_KEY, NOT_IN_THE_LAST_X_DAYS_KEY];
export const dateConditionTypes = [SIGNED_UP_AT_KEY, LAST_SEEN_AT_KEY, FIRST_SEEN_AT_KEY];
export const stringConditionTypes = [EMAIL_KEY, URL_KEY];

export const negativeConditions = [
  NOT_CONTAINS_KEY,
  NOT_EQUALS_KEY,
  NOT_IN_KEY,
  NOT_IN_THE_LAST_X_DAYS_KEY,
];

export const allAttributeTitles = [
  ...whereBaseAttributes,
  ...whoVisitorAttributes,
  ...whoUserAttributes,
  ...stopShowingAttributes,
];
export const allConditionsTitles = [
  ...generalConditionTypesMenuItems,
  ...dateConditionMenuItems,
  ...numberConditionMenuItems,
  ...booleanConditionMenuItems,
];

export const HEADLESS = 'Headless';
