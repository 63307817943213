import { useApps } from 'app/src/context/AppsStore';
import { useEffect } from 'react';
import { useSynthesia } from 'app/src/context/SynthesiaStore';

export const useSynthesiaLibraryVideos = () => {
  const [{ initialized: appsInitialized, synthesia }] = useApps();

  const [
    { videos: synthesiaVideos, loading, initialized: synthesiaInitialized },
    { fetchSynthesia },
  ] = useSynthesia();

  const initialized = (!synthesia && appsInitialized) || synthesiaInitialized;
  useEffect(() => {
    if (!initialized) {
      fetchSynthesia(synthesia?.token);
    }
  }, [initialized]);

  const loadMore = async () => {
    // No pagination for Synthesia
  };

  return { videos: synthesiaVideos, initialized, loadMore, isFetchingNextPage: loading };
};
