import React from 'react';
import styled, { css } from 'styled-components';
import BasicRadio from 'shared/react/components/basic/BasicRadio';
import HorizontalFlex, { Gap8HorizontalFlex } from '../../../flex_layouts/HorizontalFlex';
import { BolderTextSmall } from 'shared/react/components/complex/Text';
import Utils from 'shared/react/utils/utils';

const FeedProductRechargePlanRadio = ({
  selectedValue,
  optionValue,
  onChange,
  price,
  currencySymbol,
  isOnetime,
  isOnetimeSelected,
  borderRadius,
  checkedColor,
  children,
}) => {
  const displayedPrice = Utils.getDisplayedPrice({
    price,
    currencySymbol,
  });

  const handleChange = () => {
    onChange(optionValue);
  };

  return (
    <LayoutRoot
      isOnetime={isOnetime}
      isOnetimeSelected={isOnetimeSelected}
      borderRadius={borderRadius}
    >
      <BasicRadio
        checked={selectedValue === optionValue}
        onChange={handleChange}
        checkedColor={checkedColor}
      >
        <RadioContent>{children}</RadioContent>
      </BasicRadio>
      <Price>{displayedPrice}</Price>
    </LayoutRoot>
  );
};

const OnetimeCss = css`
  padding: 0 8px;
  border: 1px solid
    ${({ theme, isOnetimeSelected }) =>
      isOnetimeSelected ? theme.colors.gray4 : theme.colors.gray3};
  border-radius: ${({ borderRadius }) => (borderRadius ? `${borderRadius}px` : '')};
  height: 40px;
`;

const LayoutRoot = styled(HorizontalFlex)`
  align-items: center;
  justify-content: space-between;
  ${({ isOnetime }) => isOnetime && OnetimeCss}
`;

const RadioContent = styled(Gap8HorizontalFlex)`
  font-size: 14px;
  align-items: center;
`;

const Price = styled(BolderTextSmall)``;

export default FeedProductRechargePlanRadio;
