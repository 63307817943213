import React from 'react';
import { PageContainer, PageMain } from 'src/pages/dashboard/components/page/Page';
import Onboarding from 'src/pages/dashboard/pages/homepage/onboarding/Onboarding';

const Homepage = () => {
  return (
    <PageContainer>
      <PageMain>
        <Onboarding />
      </PageMain>
    </PageContainer>
  );
};

export default Homepage;
