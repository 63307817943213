/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../types/entities";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createVodAsset = /* GraphQL */ `mutation CreateVodAsset(
  $input: CreateVodAssetInput!
  $condition: ModelvodAssetConditionInput
) {
  createVodAsset(input: $input, condition: $condition) {
    id
    type
    owner
    appKey
    appExternalId
    createdAt
    name
    description
    creatorProfile {
      url
    }
    isSoundAllowed
    status
    uploadType
    stockAsset {
      videoUrl
      posterUrl
      gifUrl
      hasOriginal
      shopifyFileId
      previewUrl
      previewShopifyFileId
      shopifyPosterUrl
      posterShopifyFileId
      avatarUrl
      audioUrl
      gifShopifyFileId
    }
    duration
    folder
    width
    height
    isVertical
    isHDR
    trimSettings
    originalVOD
    mirror
    recorderVersion
    originalSize
    externalId
    externalProviderData
    isMigratingToShopify
    shopifyUploadingStatus
    isEverUsed
    captionsSettings
    referenceIds
    vodConnections {
      items {
        id
        appKey
        vodAssetId
        productId
        externalProductId
        collectionId
        provider
        tag
        createdAt
        typeKey
        type
        appUrl
        label
        vodLabelId
        vodSource
        vodType
        variantIds
        projectId
        orderIndex
        source
        updatedAt
      }
      nextToken
    }
    analysis
    autoGenerated
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateVodAssetMutationVariables,
  APITypes.CreateVodAssetMutation
>;
export const updateVodAsset = /* GraphQL */ `mutation UpdateVodAsset(
  $input: UpdateVodAssetInput!
  $condition: ModelvodAssetConditionInput
) {
  updateVodAsset(input: $input, condition: $condition) {
    id
    type
    owner
    appKey
    appExternalId
    createdAt
    name
    description
    creatorProfile {
      url
    }
    isSoundAllowed
    status
    uploadType
    stockAsset {
      videoUrl
      posterUrl
      gifUrl
      hasOriginal
      shopifyFileId
      previewUrl
      previewShopifyFileId
      shopifyPosterUrl
      posterShopifyFileId
      avatarUrl
      audioUrl
      gifShopifyFileId
    }
    duration
    folder
    width
    height
    isVertical
    isHDR
    trimSettings
    originalVOD
    mirror
    recorderVersion
    originalSize
    externalId
    externalProviderData
    isMigratingToShopify
    shopifyUploadingStatus
    isEverUsed
    captionsSettings
    referenceIds
    vodConnections {
      items {
        id
        appKey
        vodAssetId
        productId
        externalProductId
        collectionId
        provider
        tag
        createdAt
        typeKey
        type
        appUrl
        label
        vodLabelId
        vodSource
        vodType
        variantIds
        projectId
        orderIndex
        source
        updatedAt
      }
      nextToken
    }
    analysis
    autoGenerated
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateVodAssetMutationVariables,
  APITypes.UpdateVodAssetMutation
>;
export const deleteVodAsset = /* GraphQL */ `mutation DeleteVodAsset(
  $input: DeleteVodAssetInput!
  $condition: ModelvodAssetConditionInput
) {
  deleteVodAsset(input: $input, condition: $condition) {
    id
    type
    owner
    appKey
    appExternalId
    createdAt
    name
    description
    creatorProfile {
      url
    }
    isSoundAllowed
    status
    uploadType
    stockAsset {
      videoUrl
      posterUrl
      gifUrl
      hasOriginal
      shopifyFileId
      previewUrl
      previewShopifyFileId
      shopifyPosterUrl
      posterShopifyFileId
      avatarUrl
      audioUrl
      gifShopifyFileId
    }
    duration
    folder
    width
    height
    isVertical
    isHDR
    trimSettings
    originalVOD
    mirror
    recorderVersion
    originalSize
    externalId
    externalProviderData
    isMigratingToShopify
    shopifyUploadingStatus
    isEverUsed
    captionsSettings
    referenceIds
    vodConnections {
      items {
        id
        appKey
        vodAssetId
        productId
        externalProductId
        collectionId
        provider
        tag
        createdAt
        typeKey
        type
        appUrl
        label
        vodLabelId
        vodSource
        vodType
        variantIds
        projectId
        orderIndex
        source
        updatedAt
      }
      nextToken
    }
    analysis
    autoGenerated
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteVodAssetMutationVariables,
  APITypes.DeleteVodAssetMutation
>;
export const createVodResponseAsset = /* GraphQL */ `mutation CreateVodResponseAsset(
  $input: CreateVodResponseAssetInput!
  $condition: ModelvodResponseAssetConditionInput
) {
  createVodResponseAsset(input: $input, condition: $condition) {
    id
    owner
    appKey
    createdAt
    status
    uploadType
    duration
    width
    height
    isVertical
    isHDR
    mirror
    originalSize
    email
    projectId
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateVodResponseAssetMutationVariables,
  APITypes.CreateVodResponseAssetMutation
>;
export const updateVodResponseAsset = /* GraphQL */ `mutation UpdateVodResponseAsset(
  $input: UpdateVodResponseAssetInput!
  $condition: ModelvodResponseAssetConditionInput
) {
  updateVodResponseAsset(input: $input, condition: $condition) {
    id
    owner
    appKey
    createdAt
    status
    uploadType
    duration
    width
    height
    isVertical
    isHDR
    mirror
    originalSize
    email
    projectId
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateVodResponseAssetMutationVariables,
  APITypes.UpdateVodResponseAssetMutation
>;
export const deleteVodResponseAsset = /* GraphQL */ `mutation DeleteVodResponseAsset(
  $input: DeleteVodResponseAssetInput!
  $condition: ModelvodResponseAssetConditionInput
) {
  deleteVodResponseAsset(input: $input, condition: $condition) {
    id
    owner
    appKey
    createdAt
    status
    uploadType
    duration
    width
    height
    isVertical
    isHDR
    mirror
    originalSize
    email
    projectId
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteVodResponseAssetMutationVariables,
  APITypes.DeleteVodResponseAssetMutation
>;
export const createProject = /* GraphQL */ `mutation CreateProject(
  $input: CreateProjectInput!
  $condition: ModelprojectConditionInput
) {
  createProject(input: $input, condition: $condition) {
    id
    owner
    appKey
    typeKey
    createdAt
    publishId
    name
    folder
    targetPage
    verticalOrientation
    widgetPosition
    widgetHeader
    widgetUrls
    description
    emailNotifications
    notificationLevel
    viewerNotificationLevel
    chaptersEnabled
    subtitlesEnabled
    stepsOrder
    collectText
    collectFields {
      key
      name
      type
      placeholder
      link
      required
      collect
      custom
      textColor
      borderColor
      backgroundColor
    }
    collectAfterStep
    cacheLeadFormAnswers
    googleAnalyticsID
    facebookAnalyticsID
    totalResponses
    unreadResponses
    tolstoyType
    steps {
      items {
        id
        owner
        appKey
        projectId
        videoId
        name
        type
        description
        script
        question
        questionVisible
        answerTime
        answers {
          key
          text
          next
          value
          type
          disabled
        }
        products {
          id
        }
        productsAppUrl
        videoContain
        overlayText
        overlayTextColor
        multipleChoiceSelection
        defaultResponses {
          key
          text
          next
          value
          type
          disabled
        }
        defaultResponsesEnabled
        sideBarButtonsEnabled
        feedProductActionButton {
          type
          url
          text
          isOpenInANewTab
          value
          enabled
        }
        feedSidePanelType
        feedDescriptionActionButton {
          type
          url
          text
          isOpenInANewTab
          value
          enabled
        }
        feedSidePanelDescription
        productCtaType
        createdAt
        updatedAt
      }
      nextToken
    }
    stepsCount
    widgetBorderColor
    widgetShape
    widgetSize
    widgetNotificationBadge
    videoResolutions
    fastForwardEnabled
    rulesEnabled
    macro
    hideChatPlatform
    private
    chatLandingPage
    widgetSettings
    notificationSettingsId
    collectAfterAnyResponse
    startScreenText
    feed
    feedAutoScroll
    feedShowUnviewedStepsFirst
    language
    showButtonsIndex
    publishMethod
    dynamic
    feedProductCtaType
    playerSettings
    appUrl
    live
    primaryButton {
      enabled
      ctaType
      buttonText
      value
      isOpenInANewTab
    }
    secondaryButton {
      enabled
      ctaType
      buttonText
      value
      isOpenInANewTab
    }
    pdpConfig {
      primaryButton {
        enabled
        ctaType
        buttonText
        value
        isOpenInANewTab
      }
      secondaryButton {
        enabled
        ctaType
        buttonText
        value
        isOpenInANewTab
      }
      miniCtaButton {
        enabled
        text
      }
    }
    installationCompleted
    installStep
    discover
    useCaseName
    heroSettings
    sourceProjectId
    emailSettings
    videoCollectionCampaignSettings
    secretVideoSettings
    integrationSettings
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateProjectMutationVariables,
  APITypes.CreateProjectMutation
>;
export const updateProject = /* GraphQL */ `mutation UpdateProject(
  $input: UpdateProjectInput!
  $condition: ModelprojectConditionInput
) {
  updateProject(input: $input, condition: $condition) {
    id
    owner
    appKey
    typeKey
    createdAt
    publishId
    name
    folder
    targetPage
    verticalOrientation
    widgetPosition
    widgetHeader
    widgetUrls
    description
    emailNotifications
    notificationLevel
    viewerNotificationLevel
    chaptersEnabled
    subtitlesEnabled
    stepsOrder
    collectText
    collectFields {
      key
      name
      type
      placeholder
      link
      required
      collect
      custom
      textColor
      borderColor
      backgroundColor
    }
    collectAfterStep
    cacheLeadFormAnswers
    googleAnalyticsID
    facebookAnalyticsID
    totalResponses
    unreadResponses
    tolstoyType
    steps {
      items {
        id
        owner
        appKey
        projectId
        videoId
        name
        type
        description
        script
        question
        questionVisible
        answerTime
        answers {
          key
          text
          next
          value
          type
          disabled
        }
        products {
          id
        }
        productsAppUrl
        videoContain
        overlayText
        overlayTextColor
        multipleChoiceSelection
        defaultResponses {
          key
          text
          next
          value
          type
          disabled
        }
        defaultResponsesEnabled
        sideBarButtonsEnabled
        feedProductActionButton {
          type
          url
          text
          isOpenInANewTab
          value
          enabled
        }
        feedSidePanelType
        feedDescriptionActionButton {
          type
          url
          text
          isOpenInANewTab
          value
          enabled
        }
        feedSidePanelDescription
        productCtaType
        createdAt
        updatedAt
      }
      nextToken
    }
    stepsCount
    widgetBorderColor
    widgetShape
    widgetSize
    widgetNotificationBadge
    videoResolutions
    fastForwardEnabled
    rulesEnabled
    macro
    hideChatPlatform
    private
    chatLandingPage
    widgetSettings
    notificationSettingsId
    collectAfterAnyResponse
    startScreenText
    feed
    feedAutoScroll
    feedShowUnviewedStepsFirst
    language
    showButtonsIndex
    publishMethod
    dynamic
    feedProductCtaType
    playerSettings
    appUrl
    live
    primaryButton {
      enabled
      ctaType
      buttonText
      value
      isOpenInANewTab
    }
    secondaryButton {
      enabled
      ctaType
      buttonText
      value
      isOpenInANewTab
    }
    pdpConfig {
      primaryButton {
        enabled
        ctaType
        buttonText
        value
        isOpenInANewTab
      }
      secondaryButton {
        enabled
        ctaType
        buttonText
        value
        isOpenInANewTab
      }
      miniCtaButton {
        enabled
        text
      }
    }
    installationCompleted
    installStep
    discover
    useCaseName
    heroSettings
    sourceProjectId
    emailSettings
    videoCollectionCampaignSettings
    secretVideoSettings
    integrationSettings
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateProjectMutationVariables,
  APITypes.UpdateProjectMutation
>;
export const deleteProject = /* GraphQL */ `mutation DeleteProject(
  $input: DeleteProjectInput!
  $condition: ModelprojectConditionInput
) {
  deleteProject(input: $input, condition: $condition) {
    id
    owner
    appKey
    typeKey
    createdAt
    publishId
    name
    folder
    targetPage
    verticalOrientation
    widgetPosition
    widgetHeader
    widgetUrls
    description
    emailNotifications
    notificationLevel
    viewerNotificationLevel
    chaptersEnabled
    subtitlesEnabled
    stepsOrder
    collectText
    collectFields {
      key
      name
      type
      placeholder
      link
      required
      collect
      custom
      textColor
      borderColor
      backgroundColor
    }
    collectAfterStep
    cacheLeadFormAnswers
    googleAnalyticsID
    facebookAnalyticsID
    totalResponses
    unreadResponses
    tolstoyType
    steps {
      items {
        id
        owner
        appKey
        projectId
        videoId
        name
        type
        description
        script
        question
        questionVisible
        answerTime
        answers {
          key
          text
          next
          value
          type
          disabled
        }
        products {
          id
        }
        productsAppUrl
        videoContain
        overlayText
        overlayTextColor
        multipleChoiceSelection
        defaultResponses {
          key
          text
          next
          value
          type
          disabled
        }
        defaultResponsesEnabled
        sideBarButtonsEnabled
        feedProductActionButton {
          type
          url
          text
          isOpenInANewTab
          value
          enabled
        }
        feedSidePanelType
        feedDescriptionActionButton {
          type
          url
          text
          isOpenInANewTab
          value
          enabled
        }
        feedSidePanelDescription
        productCtaType
        createdAt
        updatedAt
      }
      nextToken
    }
    stepsCount
    widgetBorderColor
    widgetShape
    widgetSize
    widgetNotificationBadge
    videoResolutions
    fastForwardEnabled
    rulesEnabled
    macro
    hideChatPlatform
    private
    chatLandingPage
    widgetSettings
    notificationSettingsId
    collectAfterAnyResponse
    startScreenText
    feed
    feedAutoScroll
    feedShowUnviewedStepsFirst
    language
    showButtonsIndex
    publishMethod
    dynamic
    feedProductCtaType
    playerSettings
    appUrl
    live
    primaryButton {
      enabled
      ctaType
      buttonText
      value
      isOpenInANewTab
    }
    secondaryButton {
      enabled
      ctaType
      buttonText
      value
      isOpenInANewTab
    }
    pdpConfig {
      primaryButton {
        enabled
        ctaType
        buttonText
        value
        isOpenInANewTab
      }
      secondaryButton {
        enabled
        ctaType
        buttonText
        value
        isOpenInANewTab
      }
      miniCtaButton {
        enabled
        text
      }
    }
    installationCompleted
    installStep
    discover
    useCaseName
    heroSettings
    sourceProjectId
    emailSettings
    videoCollectionCampaignSettings
    secretVideoSettings
    integrationSettings
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteProjectMutationVariables,
  APITypes.DeleteProjectMutation
>;
export const createProjectStep = /* GraphQL */ `mutation CreateProjectStep(
  $input: CreateProjectStepInput!
  $condition: ModelprojectStepConditionInput
) {
  createProjectStep(input: $input, condition: $condition) {
    id
    owner
    appKey
    projectId
    videoId
    name
    type
    description
    script
    question
    questionVisible
    answerTime
    answers {
      key
      text
      next
      value
      type
      disabled
    }
    products {
      id
    }
    productsAppUrl
    videoContain
    overlayText
    overlayTextColor
    multipleChoiceSelection
    defaultResponses {
      key
      text
      next
      value
      type
      disabled
    }
    defaultResponsesEnabled
    sideBarButtonsEnabled
    feedProductActionButton {
      type
      url
      text
      isOpenInANewTab
      value
      enabled
    }
    feedSidePanelType
    feedDescriptionActionButton {
      type
      url
      text
      isOpenInANewTab
      value
      enabled
    }
    feedSidePanelDescription
    productCtaType
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateProjectStepMutationVariables,
  APITypes.CreateProjectStepMutation
>;
export const updateProjectStep = /* GraphQL */ `mutation UpdateProjectStep(
  $input: UpdateProjectStepInput!
  $condition: ModelprojectStepConditionInput
) {
  updateProjectStep(input: $input, condition: $condition) {
    id
    owner
    appKey
    projectId
    videoId
    name
    type
    description
    script
    question
    questionVisible
    answerTime
    answers {
      key
      text
      next
      value
      type
      disabled
    }
    products {
      id
    }
    productsAppUrl
    videoContain
    overlayText
    overlayTextColor
    multipleChoiceSelection
    defaultResponses {
      key
      text
      next
      value
      type
      disabled
    }
    defaultResponsesEnabled
    sideBarButtonsEnabled
    feedProductActionButton {
      type
      url
      text
      isOpenInANewTab
      value
      enabled
    }
    feedSidePanelType
    feedDescriptionActionButton {
      type
      url
      text
      isOpenInANewTab
      value
      enabled
    }
    feedSidePanelDescription
    productCtaType
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateProjectStepMutationVariables,
  APITypes.UpdateProjectStepMutation
>;
export const deleteProjectStep = /* GraphQL */ `mutation DeleteProjectStep(
  $input: DeleteProjectStepInput!
  $condition: ModelprojectStepConditionInput
) {
  deleteProjectStep(input: $input, condition: $condition) {
    id
    owner
    appKey
    projectId
    videoId
    name
    type
    description
    script
    question
    questionVisible
    answerTime
    answers {
      key
      text
      next
      value
      type
      disabled
    }
    products {
      id
    }
    productsAppUrl
    videoContain
    overlayText
    overlayTextColor
    multipleChoiceSelection
    defaultResponses {
      key
      text
      next
      value
      type
      disabled
    }
    defaultResponsesEnabled
    sideBarButtonsEnabled
    feedProductActionButton {
      type
      url
      text
      isOpenInANewTab
      value
      enabled
    }
    feedSidePanelType
    feedDescriptionActionButton {
      type
      url
      text
      isOpenInANewTab
      value
      enabled
    }
    feedSidePanelDescription
    productCtaType
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteProjectStepMutationVariables,
  APITypes.DeleteProjectStepMutation
>;
export const createUserSettings = /* GraphQL */ `mutation CreateUserSettings(
  $input: CreateUserSettingsInput!
  $condition: ModeluserSettingsConditionInput
) {
  createUserSettings(input: $input, condition: $condition) {
    id
    owner
    email
    firstName
    lastName
    lastLogin
    source
    token
    appKey
    hasLoggedIn
    hasShared
    viewedTolstoys
    calendars {
      id
      name
      url
    }
    emailAutomationTool
    logoSettings
    googleInfo
    crmData
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateUserSettingsMutationVariables,
  APITypes.CreateUserSettingsMutation
>;
export const updateUserSettings = /* GraphQL */ `mutation UpdateUserSettings(
  $input: UpdateUserSettingsInput!
  $condition: ModeluserSettingsConditionInput
) {
  updateUserSettings(input: $input, condition: $condition) {
    id
    owner
    email
    firstName
    lastName
    lastLogin
    source
    token
    appKey
    hasLoggedIn
    hasShared
    viewedTolstoys
    calendars {
      id
      name
      url
    }
    emailAutomationTool
    logoSettings
    googleInfo
    crmData
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserSettingsMutationVariables,
  APITypes.UpdateUserSettingsMutation
>;
export const deleteUserSettings = /* GraphQL */ `mutation DeleteUserSettings(
  $input: DeleteUserSettingsInput!
  $condition: ModeluserSettingsConditionInput
) {
  deleteUserSettings(input: $input, condition: $condition) {
    id
    owner
    email
    firstName
    lastName
    lastLogin
    source
    token
    appKey
    hasLoggedIn
    hasShared
    viewedTolstoys
    calendars {
      id
      name
      url
    }
    emailAutomationTool
    logoSettings
    googleInfo
    crmData
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserSettingsMutationVariables,
  APITypes.DeleteUserSettingsMutation
>;
export const createAccountSettings = /* GraphQL */ `mutation CreateAccountSettings(
  $input: CreateAccountSettingsInput!
  $condition: ModelaccountSettingsConditionInput
) {
  createAccountSettings(input: $input, condition: $condition) {
    id
    appKey
    owner
    name
    logoSettings
    widgetSettings
    downgradeInfo
    liveIds
    url
    logoUrl
    playerLogoUrl
    companyVertical
    companySize
    companyRole
    monthlyTraffic
    useCase
    packageId
    token
    paymentProvider
    paymentSubscriptions
    paymentIdentifier
    packageGroup
    joinedPackageAt
    trialEndAt
    referral
    crmData
    trialDays
    googleAnalyticsID
    appsInstallationStatuses
    onboardingStatuses
    assetAnalysis
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateAccountSettingsMutationVariables,
  APITypes.CreateAccountSettingsMutation
>;
export const updateAccountSettings = /* GraphQL */ `mutation UpdateAccountSettings(
  $input: UpdateAccountSettingsInput!
  $condition: ModelaccountSettingsConditionInput
) {
  updateAccountSettings(input: $input, condition: $condition) {
    id
    appKey
    owner
    name
    logoSettings
    widgetSettings
    downgradeInfo
    liveIds
    url
    logoUrl
    playerLogoUrl
    companyVertical
    companySize
    companyRole
    monthlyTraffic
    useCase
    packageId
    token
    paymentProvider
    paymentSubscriptions
    paymentIdentifier
    packageGroup
    joinedPackageAt
    trialEndAt
    referral
    crmData
    trialDays
    googleAnalyticsID
    appsInstallationStatuses
    onboardingStatuses
    assetAnalysis
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateAccountSettingsMutationVariables,
  APITypes.UpdateAccountSettingsMutation
>;
export const deleteAccountSettings = /* GraphQL */ `mutation DeleteAccountSettings(
  $input: DeleteAccountSettingsInput!
  $condition: ModelaccountSettingsConditionInput
) {
  deleteAccountSettings(input: $input, condition: $condition) {
    id
    appKey
    owner
    name
    logoSettings
    widgetSettings
    downgradeInfo
    liveIds
    url
    logoUrl
    playerLogoUrl
    companyVertical
    companySize
    companyRole
    monthlyTraffic
    useCase
    packageId
    token
    paymentProvider
    paymentSubscriptions
    paymentIdentifier
    packageGroup
    joinedPackageAt
    trialEndAt
    referral
    crmData
    trialDays
    googleAnalyticsID
    appsInstallationStatuses
    onboardingStatuses
    assetAnalysis
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteAccountSettingsMutationVariables,
  APITypes.DeleteAccountSettingsMutation
>;
export const createApp = /* GraphQL */ `mutation CreateApp(
  $input: CreateAppInput!
  $condition: ModelappConditionInput
) {
  createApp(input: $input, condition: $condition) {
    id
    owner
    appKey
    app
    active
    data
    token
    appExternalId
    appUrl
    importSettings
    importRange
    syncTagsMode
    syndicateBy
    googleAnalyticsID
    settings
    isShopAppEligible
    taxonomyId
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateAppMutationVariables,
  APITypes.CreateAppMutation
>;
export const updateApp = /* GraphQL */ `mutation UpdateApp(
  $input: UpdateAppInput!
  $condition: ModelappConditionInput
) {
  updateApp(input: $input, condition: $condition) {
    id
    owner
    appKey
    app
    active
    data
    token
    appExternalId
    appUrl
    importSettings
    importRange
    syncTagsMode
    syndicateBy
    googleAnalyticsID
    settings
    isShopAppEligible
    taxonomyId
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateAppMutationVariables,
  APITypes.UpdateAppMutation
>;
export const deleteApp = /* GraphQL */ `mutation DeleteApp(
  $input: DeleteAppInput!
  $condition: ModelappConditionInput
) {
  deleteApp(input: $input, condition: $condition) {
    id
    owner
    appKey
    app
    active
    data
    token
    appExternalId
    appUrl
    importSettings
    importRange
    syncTagsMode
    syndicateBy
    googleAnalyticsID
    settings
    isShopAppEligible
    taxonomyId
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteAppMutationVariables,
  APITypes.DeleteAppMutation
>;
export const createProjectApp = /* GraphQL */ `mutation CreateProjectApp(
  $input: CreateProjectAppInput!
  $condition: ModelprojectAppConditionInput
) {
  createProjectApp(input: $input, condition: $condition) {
    id
    appId
    appKey
    projectId
    owner
    active
    data
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateProjectAppMutationVariables,
  APITypes.CreateProjectAppMutation
>;
export const updateProjectApp = /* GraphQL */ `mutation UpdateProjectApp(
  $input: UpdateProjectAppInput!
  $condition: ModelprojectAppConditionInput
) {
  updateProjectApp(input: $input, condition: $condition) {
    id
    appId
    appKey
    projectId
    owner
    active
    data
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateProjectAppMutationVariables,
  APITypes.UpdateProjectAppMutation
>;
export const deleteProjectApp = /* GraphQL */ `mutation DeleteProjectApp(
  $input: DeleteProjectAppInput!
  $condition: ModelprojectAppConditionInput
) {
  deleteProjectApp(input: $input, condition: $condition) {
    id
    appId
    appKey
    projectId
    owner
    active
    data
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteProjectAppMutationVariables,
  APITypes.DeleteProjectAppMutation
>;
export const createResponse = /* GraphQL */ `mutation CreateResponse(
  $input: CreateResponseInput!
  $condition: ModelresponseConditionInput
) {
  createResponse(input: $input, condition: $condition) {
    id
    owner
    appKey
    stepName
    projectId
    anonymousId
    sessionId
    type
    value
    email
    name
    phone
    createdAt
    read
    collectInfo
    answerType
    parentUrl
    isProfanity
    attachments {
      fileName
      url
      type
    }
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateResponseMutationVariables,
  APITypes.CreateResponseMutation
>;
export const updateResponse = /* GraphQL */ `mutation UpdateResponse(
  $input: UpdateResponseInput!
  $condition: ModelresponseConditionInput
) {
  updateResponse(input: $input, condition: $condition) {
    id
    owner
    appKey
    stepName
    projectId
    anonymousId
    sessionId
    type
    value
    email
    name
    phone
    createdAt
    read
    collectInfo
    answerType
    parentUrl
    isProfanity
    attachments {
      fileName
      url
      type
    }
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateResponseMutationVariables,
  APITypes.UpdateResponseMutation
>;
export const deleteResponse = /* GraphQL */ `mutation DeleteResponse(
  $input: DeleteResponseInput!
  $condition: ModelresponseConditionInput
) {
  deleteResponse(input: $input, condition: $condition) {
    id
    owner
    appKey
    stepName
    projectId
    anonymousId
    sessionId
    type
    value
    email
    name
    phone
    createdAt
    read
    collectInfo
    answerType
    parentUrl
    isProfanity
    attachments {
      fileName
      url
      type
    }
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteResponseMutationVariables,
  APITypes.DeleteResponseMutation
>;
export const createInAppNotification = /* GraphQL */ `mutation CreateInAppNotification(
  $input: CreateInAppNotificationInput!
  $condition: ModelinAppNotificationConditionInput
) {
  createInAppNotification(input: $input, condition: $condition) {
    id
    owner
    teamMemberId
    projectId
    sessionId
    type
    value
    email
    createdAt
    read
    expirationUnixTime
    token
    stepName
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateInAppNotificationMutationVariables,
  APITypes.CreateInAppNotificationMutation
>;
export const updateInAppNotification = /* GraphQL */ `mutation UpdateInAppNotification(
  $input: UpdateInAppNotificationInput!
  $condition: ModelinAppNotificationConditionInput
) {
  updateInAppNotification(input: $input, condition: $condition) {
    id
    owner
    teamMemberId
    projectId
    sessionId
    type
    value
    email
    createdAt
    read
    expirationUnixTime
    token
    stepName
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateInAppNotificationMutationVariables,
  APITypes.UpdateInAppNotificationMutation
>;
export const deleteInAppNotification = /* GraphQL */ `mutation DeleteInAppNotification(
  $input: DeleteInAppNotificationInput!
  $condition: ModelinAppNotificationConditionInput
) {
  deleteInAppNotification(input: $input, condition: $condition) {
    id
    owner
    teamMemberId
    projectId
    sessionId
    type
    value
    email
    createdAt
    read
    expirationUnixTime
    token
    stepName
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteInAppNotificationMutationVariables,
  APITypes.DeleteInAppNotificationMutation
>;
export const createConferenceRoomConnection = /* GraphQL */ `mutation CreateConferenceRoomConnection(
  $input: CreateConferenceRoomConnectionInput!
  $condition: ModelconferenceRoomConnectionConditionInput
) {
  createConferenceRoomConnection(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateConferenceRoomConnectionMutationVariables,
  APITypes.CreateConferenceRoomConnectionMutation
>;
export const updateConferenceRoomConnection = /* GraphQL */ `mutation UpdateConferenceRoomConnection(
  $input: UpdateConferenceRoomConnectionInput!
  $condition: ModelconferenceRoomConnectionConditionInput
) {
  updateConferenceRoomConnection(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateConferenceRoomConnectionMutationVariables,
  APITypes.UpdateConferenceRoomConnectionMutation
>;
export const deleteConferenceRoomConnection = /* GraphQL */ `mutation DeleteConferenceRoomConnection(
  $input: DeleteConferenceRoomConnectionInput!
  $condition: ModelconferenceRoomConnectionConditionInput
) {
  deleteConferenceRoomConnection(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteConferenceRoomConnectionMutationVariables,
  APITypes.DeleteConferenceRoomConnectionMutation
>;
export const createConferenceRoom = /* GraphQL */ `mutation CreateConferenceRoom(
  $input: CreateConferenceRoomInput!
  $condition: ModelconferenceRoomConditionInput
) {
  createConferenceRoom(input: $input, condition: $condition) {
    id
    owner
    roomId
    userId
    active
    email
    appToken
    lastSeenAt
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateConferenceRoomMutationVariables,
  APITypes.CreateConferenceRoomMutation
>;
export const updateConferenceRoom = /* GraphQL */ `mutation UpdateConferenceRoom(
  $input: UpdateConferenceRoomInput!
  $condition: ModelconferenceRoomConditionInput
) {
  updateConferenceRoom(input: $input, condition: $condition) {
    id
    owner
    roomId
    userId
    active
    email
    appToken
    lastSeenAt
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateConferenceRoomMutationVariables,
  APITypes.UpdateConferenceRoomMutation
>;
export const deleteConferenceRoom = /* GraphQL */ `mutation DeleteConferenceRoom(
  $input: DeleteConferenceRoomInput!
  $condition: ModelconferenceRoomConditionInput
) {
  deleteConferenceRoom(input: $input, condition: $condition) {
    id
    owner
    roomId
    userId
    active
    email
    appToken
    lastSeenAt
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteConferenceRoomMutationVariables,
  APITypes.DeleteConferenceRoomMutation
>;
export const createConferenceRoomActivity = /* GraphQL */ `mutation CreateConferenceRoomActivity(
  $input: CreateConferenceRoomActivityInput!
  $condition: ModelconferenceRoomActivityConditionInput
) {
  createConferenceRoomActivity(input: $input, condition: $condition) {
    id
    roomName
    createdAt
    expirationUnixTime
    updatedAt
    appToken
    type
  }
}
` as GeneratedMutation<
  APITypes.CreateConferenceRoomActivityMutationVariables,
  APITypes.CreateConferenceRoomActivityMutation
>;
export const updateConferenceRoomActivity = /* GraphQL */ `mutation UpdateConferenceRoomActivity(
  $input: UpdateConferenceRoomActivityInput!
  $condition: ModelconferenceRoomActivityConditionInput
) {
  updateConferenceRoomActivity(input: $input, condition: $condition) {
    id
    roomName
    createdAt
    expirationUnixTime
    updatedAt
    appToken
    type
  }
}
` as GeneratedMutation<
  APITypes.UpdateConferenceRoomActivityMutationVariables,
  APITypes.UpdateConferenceRoomActivityMutation
>;
export const deleteConferenceRoomActivity = /* GraphQL */ `mutation DeleteConferenceRoomActivity(
  $input: DeleteConferenceRoomActivityInput!
  $condition: ModelconferenceRoomActivityConditionInput
) {
  deleteConferenceRoomActivity(input: $input, condition: $condition) {
    id
    roomName
    createdAt
    expirationUnixTime
    updatedAt
    appToken
    type
  }
}
` as GeneratedMutation<
  APITypes.DeleteConferenceRoomActivityMutationVariables,
  APITypes.DeleteConferenceRoomActivityMutation
>;
export const createSession = /* GraphQL */ `mutation CreateSession(
  $input: CreateSessionInput!
  $condition: ModelsessionConditionInput
) {
  createSession(input: $input, condition: $condition) {
    id
    owner
    assignee
    appKey
    projectId
    createdAt
    updatedAt
    lastEventAt
    sessionTime
    name
    email
    phone
    read
    archived
    actionNeeded
    totalEvents
    totalResponses
    totalReplies
    parentUrl
    videoResponse
    imageResponse
    audioResponse
    textResponse
    formResponse
    reachedEnd
    isSpam
  }
}
` as GeneratedMutation<
  APITypes.CreateSessionMutationVariables,
  APITypes.CreateSessionMutation
>;
export const updateSession = /* GraphQL */ `mutation UpdateSession(
  $input: UpdateSessionInput!
  $condition: ModelsessionConditionInput
) {
  updateSession(input: $input, condition: $condition) {
    id
    owner
    assignee
    appKey
    projectId
    createdAt
    updatedAt
    lastEventAt
    sessionTime
    name
    email
    phone
    read
    archived
    actionNeeded
    totalEvents
    totalResponses
    totalReplies
    parentUrl
    videoResponse
    imageResponse
    audioResponse
    textResponse
    formResponse
    reachedEnd
    isSpam
  }
}
` as GeneratedMutation<
  APITypes.UpdateSessionMutationVariables,
  APITypes.UpdateSessionMutation
>;
export const deleteSession = /* GraphQL */ `mutation DeleteSession(
  $input: DeleteSessionInput!
  $condition: ModelsessionConditionInput
) {
  deleteSession(input: $input, condition: $condition) {
    id
    owner
    assignee
    appKey
    projectId
    createdAt
    updatedAt
    lastEventAt
    sessionTime
    name
    email
    phone
    read
    archived
    actionNeeded
    totalEvents
    totalResponses
    totalReplies
    parentUrl
    videoResponse
    imageResponse
    audioResponse
    textResponse
    formResponse
    reachedEnd
    isSpam
  }
}
` as GeneratedMutation<
  APITypes.DeleteSessionMutationVariables,
  APITypes.DeleteSessionMutation
>;
export const createAccountIdentifiedUsers = /* GraphQL */ `mutation CreateAccountIdentifiedUsers(
  $input: CreateAccountIdentifiedUsersInput!
  $condition: ModelaccountIdentifiedUsersConditionInput
) {
  createAccountIdentifiedUsers(input: $input, condition: $condition) {
    id
    owner
    appKey
    userId
    email
    phone
    name
    lastSeenAt
    firstSeenAt
    signedUpAt
    sessionCount
    customAttributes
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateAccountIdentifiedUsersMutationVariables,
  APITypes.CreateAccountIdentifiedUsersMutation
>;
export const updateAccountIdentifiedUsers = /* GraphQL */ `mutation UpdateAccountIdentifiedUsers(
  $input: UpdateAccountIdentifiedUsersInput!
  $condition: ModelaccountIdentifiedUsersConditionInput
) {
  updateAccountIdentifiedUsers(input: $input, condition: $condition) {
    id
    owner
    appKey
    userId
    email
    phone
    name
    lastSeenAt
    firstSeenAt
    signedUpAt
    sessionCount
    customAttributes
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateAccountIdentifiedUsersMutationVariables,
  APITypes.UpdateAccountIdentifiedUsersMutation
>;
export const deleteAccountIdentifiedUsers = /* GraphQL */ `mutation DeleteAccountIdentifiedUsers(
  $input: DeleteAccountIdentifiedUsersInput!
  $condition: ModelaccountIdentifiedUsersConditionInput
) {
  deleteAccountIdentifiedUsers(input: $input, condition: $condition) {
    id
    owner
    appKey
    userId
    email
    phone
    name
    lastSeenAt
    firstSeenAt
    signedUpAt
    sessionCount
    customAttributes
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteAccountIdentifiedUsersMutationVariables,
  APITypes.DeleteAccountIdentifiedUsersMutation
>;
export const createRuleGroup = /* GraphQL */ `mutation CreateRuleGroup(
  $input: CreateRuleGroupInput!
  $condition: ModelruleGroupConditionInput
) {
  createRuleGroup(input: $input, condition: $condition) {
    id
    appKey
    projectId
    publishId
    owner
    rules {
      key
      type
      condition
      value
      limit
      behaviors
    }
    enabled
    visitorModeEnabled
    exitIntent
    delayTriggerEnabled
    delayTriggerSeconds
    showOnDevice
    showOnDomains
    abTestId
    type
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateRuleGroupMutationVariables,
  APITypes.CreateRuleGroupMutation
>;
export const updateRuleGroup = /* GraphQL */ `mutation UpdateRuleGroup(
  $input: UpdateRuleGroupInput!
  $condition: ModelruleGroupConditionInput
) {
  updateRuleGroup(input: $input, condition: $condition) {
    id
    appKey
    projectId
    publishId
    owner
    rules {
      key
      type
      condition
      value
      limit
      behaviors
    }
    enabled
    visitorModeEnabled
    exitIntent
    delayTriggerEnabled
    delayTriggerSeconds
    showOnDevice
    showOnDomains
    abTestId
    type
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateRuleGroupMutationVariables,
  APITypes.UpdateRuleGroupMutation
>;
export const deleteRuleGroup = /* GraphQL */ `mutation DeleteRuleGroup(
  $input: DeleteRuleGroupInput!
  $condition: ModelruleGroupConditionInput
) {
  deleteRuleGroup(input: $input, condition: $condition) {
    id
    appKey
    projectId
    publishId
    owner
    rules {
      key
      type
      condition
      value
      limit
      behaviors
    }
    enabled
    visitorModeEnabled
    exitIntent
    delayTriggerEnabled
    delayTriggerSeconds
    showOnDevice
    showOnDomains
    abTestId
    type
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteRuleGroupMutationVariables,
  APITypes.DeleteRuleGroupMutation
>;
export const createIdentifyAttribute = /* GraphQL */ `mutation CreateIdentifyAttribute(
  $input: CreateIdentifyAttributeInput!
  $condition: ModelidentifyAttributeConditionInput
) {
  createIdentifyAttribute(input: $input, condition: $condition) {
    id
    appKey
    owner
    key
    type
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateIdentifyAttributeMutationVariables,
  APITypes.CreateIdentifyAttributeMutation
>;
export const updateIdentifyAttribute = /* GraphQL */ `mutation UpdateIdentifyAttribute(
  $input: UpdateIdentifyAttributeInput!
  $condition: ModelidentifyAttributeConditionInput
) {
  updateIdentifyAttribute(input: $input, condition: $condition) {
    id
    appKey
    owner
    key
    type
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateIdentifyAttributeMutationVariables,
  APITypes.UpdateIdentifyAttributeMutation
>;
export const deleteIdentifyAttribute = /* GraphQL */ `mutation DeleteIdentifyAttribute(
  $input: DeleteIdentifyAttributeInput!
  $condition: ModelidentifyAttributeConditionInput
) {
  deleteIdentifyAttribute(input: $input, condition: $condition) {
    id
    appKey
    owner
    key
    type
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteIdentifyAttributeMutationVariables,
  APITypes.DeleteIdentifyAttributeMutation
>;
export const createInvite = /* GraphQL */ `mutation CreateInvite(
  $input: CreateInviteInput!
  $condition: ModelinviteConditionInput
) {
  createInvite(input: $input, condition: $condition) {
    id
    owner
    appKey
    email
    role
    status
    token
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateInviteMutationVariables,
  APITypes.CreateInviteMutation
>;
export const updateInvite = /* GraphQL */ `mutation UpdateInvite(
  $input: UpdateInviteInput!
  $condition: ModelinviteConditionInput
) {
  updateInvite(input: $input, condition: $condition) {
    id
    owner
    appKey
    email
    role
    status
    token
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateInviteMutationVariables,
  APITypes.UpdateInviteMutation
>;
export const deleteInvite = /* GraphQL */ `mutation DeleteInvite(
  $input: DeleteInviteInput!
  $condition: ModelinviteConditionInput
) {
  deleteInvite(input: $input, condition: $condition) {
    id
    owner
    appKey
    email
    role
    status
    token
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteInviteMutationVariables,
  APITypes.DeleteInviteMutation
>;
export const createAccountTeamMember = /* GraphQL */ `mutation CreateAccountTeamMember(
  $input: CreateAccountTeamMemberInput!
  $condition: ModelaccountTeamMemberConditionInput
) {
  createAccountTeamMember(input: $input, condition: $condition) {
    id
    owner
    appKey
    email
    role
    status
    inviteToken
    createdAt
    userId
    teamMemberId
    lastLoginAt
    user {
      items {
        id
        owner
        email
        firstName
        lastName
        lastLogin
        source
        token
        appKey
        hasLoggedIn
        hasShared
        viewedTolstoys
        calendars {
          id
          name
          url
        }
        emailAutomationTool
        logoSettings
        googleInfo
        crmData
        createdAt
        updatedAt
      }
      nextToken
    }
    account {
      items {
        id
        appKey
        owner
        name
        logoSettings
        widgetSettings
        downgradeInfo
        liveIds
        url
        logoUrl
        playerLogoUrl
        companyVertical
        companySize
        companyRole
        monthlyTraffic
        useCase
        packageId
        token
        paymentProvider
        paymentSubscriptions
        paymentIdentifier
        packageGroup
        joinedPackageAt
        trialEndAt
        referral
        crmData
        trialDays
        googleAnalyticsID
        appsInstallationStatuses
        onboardingStatuses
        assetAnalysis
        createdAt
        updatedAt
      }
      nextToken
    }
    notificationSettingsId
    paymentRole
    unreadInAppNotificationsCounter
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateAccountTeamMemberMutationVariables,
  APITypes.CreateAccountTeamMemberMutation
>;
export const updateAccountTeamMember = /* GraphQL */ `mutation UpdateAccountTeamMember(
  $input: UpdateAccountTeamMemberInput!
  $condition: ModelaccountTeamMemberConditionInput
) {
  updateAccountTeamMember(input: $input, condition: $condition) {
    id
    owner
    appKey
    email
    role
    status
    inviteToken
    createdAt
    userId
    teamMemberId
    lastLoginAt
    user {
      items {
        id
        owner
        email
        firstName
        lastName
        lastLogin
        source
        token
        appKey
        hasLoggedIn
        hasShared
        viewedTolstoys
        calendars {
          id
          name
          url
        }
        emailAutomationTool
        logoSettings
        googleInfo
        crmData
        createdAt
        updatedAt
      }
      nextToken
    }
    account {
      items {
        id
        appKey
        owner
        name
        logoSettings
        widgetSettings
        downgradeInfo
        liveIds
        url
        logoUrl
        playerLogoUrl
        companyVertical
        companySize
        companyRole
        monthlyTraffic
        useCase
        packageId
        token
        paymentProvider
        paymentSubscriptions
        paymentIdentifier
        packageGroup
        joinedPackageAt
        trialEndAt
        referral
        crmData
        trialDays
        googleAnalyticsID
        appsInstallationStatuses
        onboardingStatuses
        assetAnalysis
        createdAt
        updatedAt
      }
      nextToken
    }
    notificationSettingsId
    paymentRole
    unreadInAppNotificationsCounter
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateAccountTeamMemberMutationVariables,
  APITypes.UpdateAccountTeamMemberMutation
>;
export const deleteAccountTeamMember = /* GraphQL */ `mutation DeleteAccountTeamMember(
  $input: DeleteAccountTeamMemberInput!
  $condition: ModelaccountTeamMemberConditionInput
) {
  deleteAccountTeamMember(input: $input, condition: $condition) {
    id
    owner
    appKey
    email
    role
    status
    inviteToken
    createdAt
    userId
    teamMemberId
    lastLoginAt
    user {
      items {
        id
        owner
        email
        firstName
        lastName
        lastLogin
        source
        token
        appKey
        hasLoggedIn
        hasShared
        viewedTolstoys
        calendars {
          id
          name
          url
        }
        emailAutomationTool
        logoSettings
        googleInfo
        crmData
        createdAt
        updatedAt
      }
      nextToken
    }
    account {
      items {
        id
        appKey
        owner
        name
        logoSettings
        widgetSettings
        downgradeInfo
        liveIds
        url
        logoUrl
        playerLogoUrl
        companyVertical
        companySize
        companyRole
        monthlyTraffic
        useCase
        packageId
        token
        paymentProvider
        paymentSubscriptions
        paymentIdentifier
        packageGroup
        joinedPackageAt
        trialEndAt
        referral
        crmData
        trialDays
        googleAnalyticsID
        appsInstallationStatuses
        onboardingStatuses
        assetAnalysis
        createdAt
        updatedAt
      }
      nextToken
    }
    notificationSettingsId
    paymentRole
    unreadInAppNotificationsCounter
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteAccountTeamMemberMutationVariables,
  APITypes.DeleteAccountTeamMemberMutation
>;
export const createTeamMemberSettings = /* GraphQL */ `mutation CreateTeamMemberSettings(
  $input: CreateTeamMemberSettingsInput!
  $condition: ModelteamMemberSettingsConditionInput
) {
  createTeamMemberSettings(input: $input, condition: $condition) {
    id
    teamMemberId
    notificationSettingsId
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateTeamMemberSettingsMutationVariables,
  APITypes.CreateTeamMemberSettingsMutation
>;
export const updateTeamMemberSettings = /* GraphQL */ `mutation UpdateTeamMemberSettings(
  $input: UpdateTeamMemberSettingsInput!
  $condition: ModelteamMemberSettingsConditionInput
) {
  updateTeamMemberSettings(input: $input, condition: $condition) {
    id
    teamMemberId
    notificationSettingsId
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateTeamMemberSettingsMutationVariables,
  APITypes.UpdateTeamMemberSettingsMutation
>;
export const deleteTeamMemberSettings = /* GraphQL */ `mutation DeleteTeamMemberSettings(
  $input: DeleteTeamMemberSettingsInput!
  $condition: ModelteamMemberSettingsConditionInput
) {
  deleteTeamMemberSettings(input: $input, condition: $condition) {
    id
    teamMemberId
    notificationSettingsId
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteTeamMemberSettingsMutationVariables,
  APITypes.DeleteTeamMemberSettingsMutation
>;
export const createNotificationSettings = /* GraphQL */ `mutation CreateNotificationSettings(
  $input: CreateNotificationSettingsInput!
  $condition: ModelnotificationSettingsConditionInput
) {
  createNotificationSettings(input: $input, condition: $condition) {
    id
    owner
    projectId
    email {
      showAnonymous
      enabled
      digest
    }
    inApp {
      showAnonymous
      enabled
      digest
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateNotificationSettingsMutationVariables,
  APITypes.CreateNotificationSettingsMutation
>;
export const updateNotificationSettings = /* GraphQL */ `mutation UpdateNotificationSettings(
  $input: UpdateNotificationSettingsInput!
  $condition: ModelnotificationSettingsConditionInput
) {
  updateNotificationSettings(input: $input, condition: $condition) {
    id
    owner
    projectId
    email {
      showAnonymous
      enabled
      digest
    }
    inApp {
      showAnonymous
      enabled
      digest
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateNotificationSettingsMutationVariables,
  APITypes.UpdateNotificationSettingsMutation
>;
export const deleteNotificationSettings = /* GraphQL */ `mutation DeleteNotificationSettings(
  $input: DeleteNotificationSettingsInput!
  $condition: ModelnotificationSettingsConditionInput
) {
  deleteNotificationSettings(input: $input, condition: $condition) {
    id
    owner
    projectId
    email {
      showAnonymous
      enabled
      digest
    }
    inApp {
      showAnonymous
      enabled
      digest
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteNotificationSettingsMutationVariables,
  APITypes.DeleteNotificationSettingsMutation
>;
export const createFolder = /* GraphQL */ `mutation CreateFolder(
  $input: CreateFolderInput!
  $condition: ModelfolderConditionInput
) {
  createFolder(input: $input, condition: $condition) {
    id
    owner
    name
    appKey
    private
    type
    appUrl
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateFolderMutationVariables,
  APITypes.CreateFolderMutation
>;
export const updateFolder = /* GraphQL */ `mutation UpdateFolder(
  $input: UpdateFolderInput!
  $condition: ModelfolderConditionInput
) {
  updateFolder(input: $input, condition: $condition) {
    id
    owner
    name
    appKey
    private
    type
    appUrl
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateFolderMutationVariables,
  APITypes.UpdateFolderMutation
>;
export const deleteFolder = /* GraphQL */ `mutation DeleteFolder(
  $input: DeleteFolderInput!
  $condition: ModelfolderConditionInput
) {
  deleteFolder(input: $input, condition: $condition) {
    id
    owner
    name
    appKey
    private
    type
    appUrl
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteFolderMutationVariables,
  APITypes.DeleteFolderMutation
>;
export const createAccountUser = /* GraphQL */ `mutation CreateAccountUser(
  $input: CreateAccountUserInput!
  $condition: ModelaccountUserConditionInput
) {
  createAccountUser(input: $input, condition: $condition) {
    id
    appKey
    email
    name
    phone
    createdAt
    updatedAt
    lastSeenAt
    emailVerified
    sessionCount
    customAttributes
  }
}
` as GeneratedMutation<
  APITypes.CreateAccountUserMutationVariables,
  APITypes.CreateAccountUserMutation
>;
export const updateAccountUser = /* GraphQL */ `mutation UpdateAccountUser(
  $input: UpdateAccountUserInput!
  $condition: ModelaccountUserConditionInput
) {
  updateAccountUser(input: $input, condition: $condition) {
    id
    appKey
    email
    name
    phone
    createdAt
    updatedAt
    lastSeenAt
    emailVerified
    sessionCount
    customAttributes
  }
}
` as GeneratedMutation<
  APITypes.UpdateAccountUserMutationVariables,
  APITypes.UpdateAccountUserMutation
>;
export const deleteAccountUser = /* GraphQL */ `mutation DeleteAccountUser(
  $input: DeleteAccountUserInput!
  $condition: ModelaccountUserConditionInput
) {
  deleteAccountUser(input: $input, condition: $condition) {
    id
    appKey
    email
    name
    phone
    createdAt
    updatedAt
    lastSeenAt
    emailVerified
    sessionCount
    customAttributes
  }
}
` as GeneratedMutation<
  APITypes.DeleteAccountUserMutationVariables,
  APITypes.DeleteAccountUserMutation
>;
export const createAccountUsage = /* GraphQL */ `mutation CreateAccountUsage(
  $input: CreateAccountUsageInput!
  $condition: ModelaccountUsageConditionInput
) {
  createAccountUsage(input: $input, condition: $condition) {
    id
    appKey
    featureId
    usage
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateAccountUsageMutationVariables,
  APITypes.CreateAccountUsageMutation
>;
export const updateAccountUsage = /* GraphQL */ `mutation UpdateAccountUsage(
  $input: UpdateAccountUsageInput!
  $condition: ModelaccountUsageConditionInput
) {
  updateAccountUsage(input: $input, condition: $condition) {
    id
    appKey
    featureId
    usage
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateAccountUsageMutationVariables,
  APITypes.UpdateAccountUsageMutation
>;
export const deleteAccountUsage = /* GraphQL */ `mutation DeleteAccountUsage(
  $input: DeleteAccountUsageInput!
  $condition: ModelaccountUsageConditionInput
) {
  deleteAccountUsage(input: $input, condition: $condition) {
    id
    appKey
    featureId
    usage
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteAccountUsageMutationVariables,
  APITypes.DeleteAccountUsageMutation
>;
export const createPlayerConfig = /* GraphQL */ `mutation CreatePlayerConfig(
  $input: CreatePlayerConfigInput!
  $condition: ModelplayerConfigConditionInput
) {
  createPlayerConfig(input: $input, condition: $condition) {
    id
    appKey
    publishId
    projectId
    config
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreatePlayerConfigMutationVariables,
  APITypes.CreatePlayerConfigMutation
>;
export const updatePlayerConfig = /* GraphQL */ `mutation UpdatePlayerConfig(
  $input: UpdatePlayerConfigInput!
  $condition: ModelplayerConfigConditionInput
) {
  updatePlayerConfig(input: $input, condition: $condition) {
    id
    appKey
    publishId
    projectId
    config
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdatePlayerConfigMutationVariables,
  APITypes.UpdatePlayerConfigMutation
>;
export const deletePlayerConfig = /* GraphQL */ `mutation DeletePlayerConfig(
  $input: DeletePlayerConfigInput!
  $condition: ModelplayerConfigConditionInput
) {
  deletePlayerConfig(input: $input, condition: $condition) {
    id
    appKey
    publishId
    projectId
    config
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeletePlayerConfigMutationVariables,
  APITypes.DeletePlayerConfigMutation
>;
export const createWidgetConfig = /* GraphQL */ `mutation CreateWidgetConfig(
  $input: CreateWidgetConfigInput!
  $condition: ModelwidgetConfigConditionInput
) {
  createWidgetConfig(input: $input, condition: $condition) {
    id
    appKey
    publishId
    projectId
    config
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateWidgetConfigMutationVariables,
  APITypes.CreateWidgetConfigMutation
>;
export const updateWidgetConfig = /* GraphQL */ `mutation UpdateWidgetConfig(
  $input: UpdateWidgetConfigInput!
  $condition: ModelwidgetConfigConditionInput
) {
  updateWidgetConfig(input: $input, condition: $condition) {
    id
    appKey
    publishId
    projectId
    config
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateWidgetConfigMutationVariables,
  APITypes.UpdateWidgetConfigMutation
>;
export const deleteWidgetConfig = /* GraphQL */ `mutation DeleteWidgetConfig(
  $input: DeleteWidgetConfigInput!
  $condition: ModelwidgetConfigConditionInput
) {
  deleteWidgetConfig(input: $input, condition: $condition) {
    id
    appKey
    publishId
    projectId
    config
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteWidgetConfigMutationVariables,
  APITypes.DeleteWidgetConfigMutation
>;
export const createAccountPackage = /* GraphQL */ `mutation CreateAccountPackage(
  $input: CreateAccountPackageInput!
  $condition: ModelaccountPackageConditionInput
) {
  createAccountPackage(input: $input, condition: $condition) {
    id
    appKey
    packageId
    createdAt
    updatedAt
    app
    featuresPackageId
    group
  }
}
` as GeneratedMutation<
  APITypes.CreateAccountPackageMutationVariables,
  APITypes.CreateAccountPackageMutation
>;
export const updateAccountPackage = /* GraphQL */ `mutation UpdateAccountPackage(
  $input: UpdateAccountPackageInput!
  $condition: ModelaccountPackageConditionInput
) {
  updateAccountPackage(input: $input, condition: $condition) {
    id
    appKey
    packageId
    createdAt
    updatedAt
    app
    featuresPackageId
    group
  }
}
` as GeneratedMutation<
  APITypes.UpdateAccountPackageMutationVariables,
  APITypes.UpdateAccountPackageMutation
>;
export const deleteAccountPackage = /* GraphQL */ `mutation DeleteAccountPackage(
  $input: DeleteAccountPackageInput!
  $condition: ModelaccountPackageConditionInput
) {
  deleteAccountPackage(input: $input, condition: $condition) {
    id
    appKey
    packageId
    createdAt
    updatedAt
    app
    featuresPackageId
    group
  }
}
` as GeneratedMutation<
  APITypes.DeleteAccountPackageMutationVariables,
  APITypes.DeleteAccountPackageMutation
>;
export const createPackage = /* GraphQL */ `mutation CreatePackage(
  $input: CreatePackageInput!
  $condition: ModelpackageConditionInput
) {
  createPackage(input: $input, condition: $condition) {
    id
    name
    description
    stripeProducts {
      type
      price
    }
    trialPeriodDays
    maxTrialPeriodDays
    type
    details
    group
    order
    featuresPackageId
    addons
    app
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreatePackageMutationVariables,
  APITypes.CreatePackageMutation
>;
export const updatePackage = /* GraphQL */ `mutation UpdatePackage(
  $input: UpdatePackageInput!
  $condition: ModelpackageConditionInput
) {
  updatePackage(input: $input, condition: $condition) {
    id
    name
    description
    stripeProducts {
      type
      price
    }
    trialPeriodDays
    maxTrialPeriodDays
    type
    details
    group
    order
    featuresPackageId
    addons
    app
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdatePackageMutationVariables,
  APITypes.UpdatePackageMutation
>;
export const deletePackage = /* GraphQL */ `mutation DeletePackage(
  $input: DeletePackageInput!
  $condition: ModelpackageConditionInput
) {
  deletePackage(input: $input, condition: $condition) {
    id
    name
    description
    stripeProducts {
      type
      price
    }
    trialPeriodDays
    maxTrialPeriodDays
    type
    details
    group
    order
    featuresPackageId
    addons
    app
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeletePackageMutationVariables,
  APITypes.DeletePackageMutation
>;
export const createExperiment = /* GraphQL */ `mutation CreateExperiment(
  $input: CreateExperimentInput!
  $condition: ModelexperimentConditionInput
) {
  createExperiment(input: $input, condition: $condition) {
    id
    featureId
    name
    description
    expireAt
    type
    groups
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateExperimentMutationVariables,
  APITypes.CreateExperimentMutation
>;
export const updateExperiment = /* GraphQL */ `mutation UpdateExperiment(
  $input: UpdateExperimentInput!
  $condition: ModelexperimentConditionInput
) {
  updateExperiment(input: $input, condition: $condition) {
    id
    featureId
    name
    description
    expireAt
    type
    groups
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateExperimentMutationVariables,
  APITypes.UpdateExperimentMutation
>;
export const deleteExperiment = /* GraphQL */ `mutation DeleteExperiment(
  $input: DeleteExperimentInput!
  $condition: ModelexperimentConditionInput
) {
  deleteExperiment(input: $input, condition: $condition) {
    id
    featureId
    name
    description
    expireAt
    type
    groups
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteExperimentMutationVariables,
  APITypes.DeleteExperimentMutation
>;
export const createPayment = /* GraphQL */ `mutation CreatePayment(
  $input: CreatePaymentInput!
  $condition: ModelpaymentConditionInput
) {
  createPayment(input: $input, condition: $condition) {
    id
    subscriptionId
    appKey
    reportAt
    usagePrice
    usageQuantity
    type
    provider
    identifier
    packageId
    packageType
    dailyUsagePrice
    aggregatedPrice
    aggregatedUsageQuantity
    dailyUsageQuantity
    seatsPrice
    price
    interval
    numberOfProTeamMembers
    invoiceId
    invoiceItemId
    paymentSubscriptionId
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreatePaymentMutationVariables,
  APITypes.CreatePaymentMutation
>;
export const updatePayment = /* GraphQL */ `mutation UpdatePayment(
  $input: UpdatePaymentInput!
  $condition: ModelpaymentConditionInput
) {
  updatePayment(input: $input, condition: $condition) {
    id
    subscriptionId
    appKey
    reportAt
    usagePrice
    usageQuantity
    type
    provider
    identifier
    packageId
    packageType
    dailyUsagePrice
    aggregatedPrice
    aggregatedUsageQuantity
    dailyUsageQuantity
    seatsPrice
    price
    interval
    numberOfProTeamMembers
    invoiceId
    invoiceItemId
    paymentSubscriptionId
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdatePaymentMutationVariables,
  APITypes.UpdatePaymentMutation
>;
export const deletePayment = /* GraphQL */ `mutation DeletePayment(
  $input: DeletePaymentInput!
  $condition: ModelpaymentConditionInput
) {
  deletePayment(input: $input, condition: $condition) {
    id
    subscriptionId
    appKey
    reportAt
    usagePrice
    usageQuantity
    type
    provider
    identifier
    packageId
    packageType
    dailyUsagePrice
    aggregatedPrice
    aggregatedUsageQuantity
    dailyUsageQuantity
    seatsPrice
    price
    interval
    numberOfProTeamMembers
    invoiceId
    invoiceItemId
    paymentSubscriptionId
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeletePaymentMutationVariables,
  APITypes.DeletePaymentMutation
>;
export const createQuizResult = /* GraphQL */ `mutation CreateQuizResult(
  $input: CreateQuizResultInput!
  $condition: ModelquizResultConditionInput
) {
  createQuizResult(input: $input, condition: $condition) {
    id
    projectId
    appKey
    owner
    createdAt
    url
    title
    imageUrl
    flows {
      id
      type
      rules {
        stepId
        choices
      }
    }
    isFallback
    klaviyoListId
    stepName
    type
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateQuizResultMutationVariables,
  APITypes.CreateQuizResultMutation
>;
export const updateQuizResult = /* GraphQL */ `mutation UpdateQuizResult(
  $input: UpdateQuizResultInput!
  $condition: ModelquizResultConditionInput
) {
  updateQuizResult(input: $input, condition: $condition) {
    id
    projectId
    appKey
    owner
    createdAt
    url
    title
    imageUrl
    flows {
      id
      type
      rules {
        stepId
        choices
      }
    }
    isFallback
    klaviyoListId
    stepName
    type
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateQuizResultMutationVariables,
  APITypes.UpdateQuizResultMutation
>;
export const deleteQuizResult = /* GraphQL */ `mutation DeleteQuizResult(
  $input: DeleteQuizResultInput!
  $condition: ModelquizResultConditionInput
) {
  deleteQuizResult(input: $input, condition: $condition) {
    id
    projectId
    appKey
    owner
    createdAt
    url
    title
    imageUrl
    flows {
      id
      type
      rules {
        stepId
        choices
      }
    }
    isFallback
    klaviyoListId
    stepName
    type
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteQuizResultMutationVariables,
  APITypes.DeleteQuizResultMutation
>;
export const createEcomProduct = /* GraphQL */ `mutation CreateEcomProduct(
  $input: CreateEcomProductInput!
  $condition: ModelecomProductConditionInput
) {
  createEcomProduct(input: $input, condition: $condition) {
    id
    productId
    appKey
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateEcomProductMutationVariables,
  APITypes.CreateEcomProductMutation
>;
export const updateEcomProduct = /* GraphQL */ `mutation UpdateEcomProduct(
  $input: UpdateEcomProductInput!
  $condition: ModelecomProductConditionInput
) {
  updateEcomProduct(input: $input, condition: $condition) {
    id
    productId
    appKey
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateEcomProductMutationVariables,
  APITypes.UpdateEcomProductMutation
>;
export const deleteEcomProduct = /* GraphQL */ `mutation DeleteEcomProduct(
  $input: DeleteEcomProductInput!
  $condition: ModelecomProductConditionInput
) {
  deleteEcomProduct(input: $input, condition: $condition) {
    id
    productId
    appKey
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteEcomProductMutationVariables,
  APITypes.DeleteEcomProductMutation
>;
export const createProductPageSettings = /* GraphQL */ `mutation CreateProductPageSettings(
  $input: CreateProductPageSettingsInput!
  $condition: ModelproductPageSettingsConditionInput
) {
  createProductPageSettings(input: $input, condition: $condition) {
    id
    productId
    productUrl
    abTestId
    publishId
    appKey
    widgetType
    productSource
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateProductPageSettingsMutationVariables,
  APITypes.CreateProductPageSettingsMutation
>;
export const updateProductPageSettings = /* GraphQL */ `mutation UpdateProductPageSettings(
  $input: UpdateProductPageSettingsInput!
  $condition: ModelproductPageSettingsConditionInput
) {
  updateProductPageSettings(input: $input, condition: $condition) {
    id
    productId
    productUrl
    abTestId
    publishId
    appKey
    widgetType
    productSource
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductPageSettingsMutationVariables,
  APITypes.UpdateProductPageSettingsMutation
>;
export const deleteProductPageSettings = /* GraphQL */ `mutation DeleteProductPageSettings(
  $input: DeleteProductPageSettingsInput!
  $condition: ModelproductPageSettingsConditionInput
) {
  deleteProductPageSettings(input: $input, condition: $condition) {
    id
    productId
    productUrl
    abTestId
    publishId
    appKey
    widgetType
    productSource
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductPageSettingsMutationVariables,
  APITypes.DeleteProductPageSettingsMutation
>;
export const createAbTest = /* GraphQL */ `mutation CreateAbTest(
  $input: CreateAbTestInput!
  $condition: ModelabTestConditionInput
) {
  createAbTest(input: $input, condition: $condition) {
    id
    appKey
    owner
    options {
      publishId
      probability
    }
    endAt
    startAt
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateAbTestMutationVariables,
  APITypes.CreateAbTestMutation
>;
export const updateAbTest = /* GraphQL */ `mutation UpdateAbTest(
  $input: UpdateAbTestInput!
  $condition: ModelabTestConditionInput
) {
  updateAbTest(input: $input, condition: $condition) {
    id
    appKey
    owner
    options {
      publishId
      probability
    }
    endAt
    startAt
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateAbTestMutationVariables,
  APITypes.UpdateAbTestMutation
>;
export const deleteAbTest = /* GraphQL */ `mutation DeleteAbTest(
  $input: DeleteAbTestInput!
  $condition: ModelabTestConditionInput
) {
  deleteAbTest(input: $input, condition: $condition) {
    id
    appKey
    owner
    options {
      publishId
      probability
    }
    endAt
    startAt
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteAbTestMutationVariables,
  APITypes.DeleteAbTestMutation
>;
export const createIntegrationVodAsset = /* GraphQL */ `mutation CreateIntegrationVodAsset(
  $input: CreateIntegrationVodAssetInput!
  $condition: ModelintegrationVodAssetConditionInput
) {
  createIntegrationVodAsset(input: $input, condition: $condition) {
    id
    appKey
    owner
    app
    appId
    externalId
    externalCreatedAt
    vodId
    seen
    title
    thumbnailUrl
    downloadUrl
    status
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateIntegrationVodAssetMutationVariables,
  APITypes.CreateIntegrationVodAssetMutation
>;
export const updateIntegrationVodAsset = /* GraphQL */ `mutation UpdateIntegrationVodAsset(
  $input: UpdateIntegrationVodAssetInput!
  $condition: ModelintegrationVodAssetConditionInput
) {
  updateIntegrationVodAsset(input: $input, condition: $condition) {
    id
    appKey
    owner
    app
    appId
    externalId
    externalCreatedAt
    vodId
    seen
    title
    thumbnailUrl
    downloadUrl
    status
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateIntegrationVodAssetMutationVariables,
  APITypes.UpdateIntegrationVodAssetMutation
>;
export const deleteIntegrationVodAsset = /* GraphQL */ `mutation DeleteIntegrationVodAsset(
  $input: DeleteIntegrationVodAssetInput!
  $condition: ModelintegrationVodAssetConditionInput
) {
  deleteIntegrationVodAsset(input: $input, condition: $condition) {
    id
    appKey
    owner
    app
    appId
    externalId
    externalCreatedAt
    vodId
    seen
    title
    thumbnailUrl
    downloadUrl
    status
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteIntegrationVodAssetMutationVariables,
  APITypes.DeleteIntegrationVodAssetMutation
>;
export const createVodConnection = /* GraphQL */ `mutation CreateVodConnection(
  $input: CreateVodConnectionInput!
  $condition: ModelvodConnectionConditionInput
) {
  createVodConnection(input: $input, condition: $condition) {
    id
    appKey
    vodAssetId
    productId
    externalProductId
    collectionId
    provider
    tag
    createdAt
    typeKey
    type
    appUrl
    label
    vodLabelId
    vodSource
    vodType
    variantIds
    projectId
    orderIndex
    source
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateVodConnectionMutationVariables,
  APITypes.CreateVodConnectionMutation
>;
export const updateVodConnection = /* GraphQL */ `mutation UpdateVodConnection(
  $input: UpdateVodConnectionInput!
  $condition: ModelvodConnectionConditionInput
) {
  updateVodConnection(input: $input, condition: $condition) {
    id
    appKey
    vodAssetId
    productId
    externalProductId
    collectionId
    provider
    tag
    createdAt
    typeKey
    type
    appUrl
    label
    vodLabelId
    vodSource
    vodType
    variantIds
    projectId
    orderIndex
    source
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateVodConnectionMutationVariables,
  APITypes.UpdateVodConnectionMutation
>;
export const deleteVodConnection = /* GraphQL */ `mutation DeleteVodConnection(
  $input: DeleteVodConnectionInput!
  $condition: ModelvodConnectionConditionInput
) {
  deleteVodConnection(input: $input, condition: $condition) {
    id
    appKey
    vodAssetId
    productId
    externalProductId
    collectionId
    provider
    tag
    createdAt
    typeKey
    type
    appUrl
    label
    vodLabelId
    vodSource
    vodType
    variantIds
    projectId
    orderIndex
    source
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteVodConnectionMutationVariables,
  APITypes.DeleteVodConnectionMutation
>;
export const createCustomer = /* GraphQL */ `mutation CreateCustomer(
  $input: CreateCustomerInput!
  $condition: ModelcustomerConditionInput
) {
  createCustomer(input: $input, condition: $condition) {
    id
    appKey
    appUrl
    externalId
    googleId
    anonymousIds
    email
    phone
    firstName
    lastName
    acceptsMarketing
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateCustomerMutationVariables,
  APITypes.CreateCustomerMutation
>;
export const updateCustomer = /* GraphQL */ `mutation UpdateCustomer(
  $input: UpdateCustomerInput!
  $condition: ModelcustomerConditionInput
) {
  updateCustomer(input: $input, condition: $condition) {
    id
    appKey
    appUrl
    externalId
    googleId
    anonymousIds
    email
    phone
    firstName
    lastName
    acceptsMarketing
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateCustomerMutationVariables,
  APITypes.UpdateCustomerMutation
>;
export const deleteCustomer = /* GraphQL */ `mutation DeleteCustomer(
  $input: DeleteCustomerInput!
  $condition: ModelcustomerConditionInput
) {
  deleteCustomer(input: $input, condition: $condition) {
    id
    appKey
    appUrl
    externalId
    googleId
    anonymousIds
    email
    phone
    firstName
    lastName
    acceptsMarketing
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteCustomerMutationVariables,
  APITypes.DeleteCustomerMutation
>;
export const createVodLabel = /* GraphQL */ `mutation CreateVodLabel(
  $input: CreateVodLabelInput!
  $condition: ModelvodLabelConditionInput
) {
  createVodLabel(input: $input, condition: $condition) {
    id
    appKey
    name
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateVodLabelMutationVariables,
  APITypes.CreateVodLabelMutation
>;
export const updateVodLabel = /* GraphQL */ `mutation UpdateVodLabel(
  $input: UpdateVodLabelInput!
  $condition: ModelvodLabelConditionInput
) {
  updateVodLabel(input: $input, condition: $condition) {
    id
    appKey
    name
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateVodLabelMutationVariables,
  APITypes.UpdateVodLabelMutation
>;
export const deleteVodLabel = /* GraphQL */ `mutation DeleteVodLabel(
  $input: DeleteVodLabelInput!
  $condition: ModelvodLabelConditionInput
) {
  deleteVodLabel(input: $input, condition: $condition) {
    id
    appKey
    name
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteVodLabelMutationVariables,
  APITypes.DeleteVodLabelMutation
>;
export const createSuggestedProductAcceptance = /* GraphQL */ `mutation CreateSuggestedProductAcceptance(
  $input: CreateSuggestedProductAcceptanceInput!
  $condition: ModelsuggestedProductAcceptanceConditionInput
) {
  createSuggestedProductAcceptance(input: $input, condition: $condition) {
    id
    appKey
    vodAssetId
    externalProductId
    accepted
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateSuggestedProductAcceptanceMutationVariables,
  APITypes.CreateSuggestedProductAcceptanceMutation
>;
export const updateSuggestedProductAcceptance = /* GraphQL */ `mutation UpdateSuggestedProductAcceptance(
  $input: UpdateSuggestedProductAcceptanceInput!
  $condition: ModelsuggestedProductAcceptanceConditionInput
) {
  updateSuggestedProductAcceptance(input: $input, condition: $condition) {
    id
    appKey
    vodAssetId
    externalProductId
    accepted
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateSuggestedProductAcceptanceMutationVariables,
  APITypes.UpdateSuggestedProductAcceptanceMutation
>;
export const deleteSuggestedProductAcceptance = /* GraphQL */ `mutation DeleteSuggestedProductAcceptance(
  $input: DeleteSuggestedProductAcceptanceInput!
  $condition: ModelsuggestedProductAcceptanceConditionInput
) {
  deleteSuggestedProductAcceptance(input: $input, condition: $condition) {
    id
    appKey
    vodAssetId
    externalProductId
    accepted
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteSuggestedProductAcceptanceMutationVariables,
  APITypes.DeleteSuggestedProductAcceptanceMutation
>;
export const createCustomerWishList = /* GraphQL */ `mutation CreateCustomerWishList(
  $input: CreateCustomerWishListInput!
  $condition: ModelcustomerWishListConditionInput
) {
  createCustomerWishList(input: $input, condition: $condition) {
    id
    appKey
    appUrl
    vodAssetId
    externalCustomerId
    email
    externalProductId
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateCustomerWishListMutationVariables,
  APITypes.CreateCustomerWishListMutation
>;
export const updateCustomerWishList = /* GraphQL */ `mutation UpdateCustomerWishList(
  $input: UpdateCustomerWishListInput!
  $condition: ModelcustomerWishListConditionInput
) {
  updateCustomerWishList(input: $input, condition: $condition) {
    id
    appKey
    appUrl
    vodAssetId
    externalCustomerId
    email
    externalProductId
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateCustomerWishListMutationVariables,
  APITypes.UpdateCustomerWishListMutation
>;
export const deleteCustomerWishList = /* GraphQL */ `mutation DeleteCustomerWishList(
  $input: DeleteCustomerWishListInput!
  $condition: ModelcustomerWishListConditionInput
) {
  deleteCustomerWishList(input: $input, condition: $condition) {
    id
    appKey
    appUrl
    vodAssetId
    externalCustomerId
    email
    externalProductId
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteCustomerWishListMutationVariables,
  APITypes.DeleteCustomerWishListMutation
>;
export const createVodConnectionSuggestion = /* GraphQL */ `mutation CreateVodConnectionSuggestion(
  $input: CreateVodConnectionSuggestionInput!
  $condition: ModelvodConnectionSuggestionConditionInput
) {
  createVodConnectionSuggestion(input: $input, condition: $condition) {
    id
    appKey
    appUrl
    createdAt
    vodAssetId
    externalProductId
    status
    source
    method
    methodVersion
    score
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateVodConnectionSuggestionMutationVariables,
  APITypes.CreateVodConnectionSuggestionMutation
>;
export const updateVodConnectionSuggestion = /* GraphQL */ `mutation UpdateVodConnectionSuggestion(
  $input: UpdateVodConnectionSuggestionInput!
  $condition: ModelvodConnectionSuggestionConditionInput
) {
  updateVodConnectionSuggestion(input: $input, condition: $condition) {
    id
    appKey
    appUrl
    createdAt
    vodAssetId
    externalProductId
    status
    source
    method
    methodVersion
    score
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateVodConnectionSuggestionMutationVariables,
  APITypes.UpdateVodConnectionSuggestionMutation
>;
export const deleteVodConnectionSuggestion = /* GraphQL */ `mutation DeleteVodConnectionSuggestion(
  $input: DeleteVodConnectionSuggestionInput!
  $condition: ModelvodConnectionSuggestionConditionInput
) {
  deleteVodConnectionSuggestion(input: $input, condition: $condition) {
    id
    appKey
    appUrl
    createdAt
    vodAssetId
    externalProductId
    status
    source
    method
    methodVersion
    score
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteVodConnectionSuggestionMutationVariables,
  APITypes.DeleteVodConnectionSuggestionMutation
>;
