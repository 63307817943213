import { isUrlProvider } from 'shared/react/utils/feedProducts.utils';
import {
  CHAT_RESPONSE_KEY,
  END_STEP,
  LINK_RESPONSE_KEY,
} from 'app/src/constants/tolstoy.constants';
import { URL_KEY } from 'app/src/constants/ruleGroups.constants';
import { URL_SAME_TAB } from 'shared/react/constants/playerSettings.constants';
import { PRODUCT_CTA_TYPES } from 'shared/react/constants/feed.constants';

const getProductDescription = productCtaType => {
  if (productCtaType === PRODUCT_CTA_TYPES.addToCart) {
    return 'Cart';
  }

  if (productCtaType === PRODUCT_CTA_TYPES.openProductPage) {
    return 'Product';
  }

  return 'Buy now';
};

export const getNextStepDescription = ({
  stepsOrder,
  nextStep,
  type,
  isProductType,
  productCtaType,
  ecomPlatform,
}) => {
  if (isUrlProvider(ecomPlatform)) {
    return 'View Product';
  }

  if (isProductType) {
    return getProductDescription(productCtaType);
  }

  if (nextStep === CHAT_RESPONSE_KEY) {
    return 'Chat';
  }

  if (nextStep === URL_KEY || nextStep === URL_SAME_TAB || type === LINK_RESPONSE_KEY) {
    return LINK_RESPONSE_KEY;
  }

  if (nextStep === END_STEP) {
    return 'End';
  }

  const index = stepsOrder.findIndex(x => x === nextStep);
  if (index < 0) {
    return '';
  }

  return 'Part ' + (index + 1);
};
