import React, { useState, useCallback } from 'react';
import AreYouSureModal from 'app/src/pages/modals/AreYouSureModal';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { useVideos } from 'app/src/context/VideosStore';
import useIsMounted from 'shared/react/hooks/useIsMounted';
import Types from 'shared/react/theme/Types';

const VideoAllowDisallowSoundModal = ({ shouldAllow }) => {
  const [{ currentIds }, { setCurrentModal, setCurrentId }] = useModal();
  const [{ data: videos }, { updateVideo }] = useVideos();
  const [loading, setLoading] = useState(false);
  const isMounted = useIsMounted();

  const onClose = useCallback(() => {
    setCurrentId('');
    setCurrentModal('');
  }, [setCurrentId, setCurrentModal]);

  const onActionButtonClick = useCallback(async () => {
    setLoading(true);
    const selectedVideos = videos.filter(video => currentIds.includes(video.id));
    const promises = selectedVideos.map(video => {
      return updateVideo({ ...video, isSoundAllowed: shouldAllow });
    });

    await Promise.all(promises);
    if (!isMounted()) {
      return;
    }

    onClose();
  }, [currentIds, isMounted, onClose, shouldAllow, updateVideo, videos]);

  return (
    <AreYouSureModal
      closeButtonClicked={onClose}
      open={true}
      loading={loading}
      actionButtonClicked={onActionButtonClick}
      secondaryButtonClicked={onClose}
      actionButtonText="Yes"
      actionLevel={Types.Primary}
    />
  );
};

export default VideoAllowDisallowSoundModal;
