import { PricingPackage } from 'src/types/pricing';

export const parseViewsPrice = (viewsPrice: string): number => {
  if (!viewsPrice) {
    return 0;
  }

  const numberPart = viewsPrice.split(' ')[0];
  const number = parseInt(numberPart.replace(/,/g, ''), 10);
  return number;
};

export const getMaxPageViews = (pricingPackage: PricingPackage): number => {
  const { maxPageViews, viewsPrice } = pricingPackage?.details || {};
  return parseInt(maxPageViews) || parseViewsPrice(viewsPrice) || 0;
};
