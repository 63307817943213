import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { TextField } from '../../styled_components';
import Button from 'shared/react/components/complex/Button';
import StandardModal from './StandardModal';
import { TextSubtitle } from 'shared/react/components/complex/Text';
import Types from 'shared/react/theme/Types';
import {
  RENAME_MODAL_INPUT_TEST_ID,
  RENAME_MODAL_SAVE_BUTTON_TEST_ID,
} from 'app/src/constants/dataTestIds.constants';

export default function RenameModal({ name, placeholder, open, onClose, onRename, isRenaming }) {
  const [nameText, setNameText] = useState(name);
  const inputRef = useRef();

  function onChange(e) {
    setNameText(e.target.value);
  }

  useEffect(() => {
    setNameText(name);

    setTimeout(() => {
      inputRef.current?.select();
    }, 50);
  }, [open, name]);

  return (
    <StandardModal open={open} onClose={onClose} width="350px">
      <StyledTitle>Rename</StyledTitle>
      <TextField
        ref={inputRef}
        autoFocus
        name="name"
        variant="outlined"
        placeholder={placeholder}
        value={nameText}
        onChange={onChange}
        data-test-id={RENAME_MODAL_INPUT_TEST_ID}
      />
      <ButtonContainer>
        <CancelButton type={Types.Ghost} onClick={onClose}>
          Cancel
        </CancelButton>
        <Button
          loading={isRenaming}
          onClick={() => onRename(nameText)}
          data-test-id={RENAME_MODAL_SAVE_BUTTON_TEST_ID}
        >
          Save
        </Button>
      </ButtonContainer>
    </StandardModal>
  );
}

const StyledTitle = styled(TextSubtitle)`
  margin-bottom: 12px;
`;

const CancelButton = styled(Button)`
  margin-right: 10px;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`;
