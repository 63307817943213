import React from 'react';

const DesktopIcon = () => (
  <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.75.5H3.25A2.492 2.492 0 0 0 .75 3v11.25c0 1.406 1.094 2.5 2.5 2.5h5.82l-.742 2.5H6.375a.642.642 0 0 0-.625.625c0 .352.273.625.625.625h11.25a.642.642 0 0 0 .625-.625c0-.313-.313-.625-.625-.625h-1.992l-.742-2.5h5.859c1.367 0 2.5-1.094 2.5-2.5V3c0-1.367-1.133-2.5-2.5-2.5Zm-6.406 18.75H9.617l.781-2.5h3.165l.78 2.5Zm7.656-5c0 .703-.586 1.25-1.25 1.25H3.25c-.703 0-1.25-.547-1.25-1.25V3c0-.664.547-1.25 1.25-1.25h17.5c.664 0 1.25.586 1.25 1.25v11.25Z"
      fill="#090A0B"
    />
  </svg>
);

export default DesktopIcon;
