export const VIDEO_STATUS = {
  done: 'done',
  migrating: 'migrating',
  uploading: 'uploading',
  processing: 'processing',
  invalid: 'invalid',
};

export const UPLOAD_TYPES = {
  upload: 'upload',
  aiVideo: 'aiVideo',
};
