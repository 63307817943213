import React from 'react';
import styled from 'styled-components';

import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

const CustomerLoginHeader = ({ handleCloseModal }) => {
  return (
    <LayoutRoot>
      <CloseModal onClick={handleCloseModal}>✕</CloseModal>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlexCentered)`
  gap: 12px;
`;

const CloseModal = styled.div`
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 15px;
  font-weight: 600;
  color: black;
  z-index: 99;
`;

const Image = styled.img`
  width: 120px;
  height: 40px;
`;

export default CustomerLoginHeader;
