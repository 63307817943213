import React, { FC } from 'react';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import CopyCode from 'app/src/complex_components/CopyCode';
import { FlexDirection } from 'app/src/types/common/common';
import styled from 'styled-components';
import { VerticalFlexCentered } from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextSmall } from 'shared/react/components/basic/text/TextV2';
import InstallStepBase, { InstallStepBaseProps } from './InstallStepBase';

type InstallStepSimpleProps = InstallStepBaseProps & {
  image?: JSX.Element;
  codeToCopy?: string;
  codeToDisplay?: JSX.Element | string;
  copyText?: string;
  text: JSX.Element | string;
  description?: JSX.Element | string;
  isOptional?: boolean;
};

const InstallStepSimple: FC<InstallStepSimpleProps> = ({
  isDisabled,
  stepNumber,
  stepHeader,
  headerOptionalText,
  startExpanded,
  currentStepName,
  project,

  image,
  codeToCopy,
  codeToDisplay,
  copyText,
  text,
  description,
}) => {
  return (
    <InstallStepBase
      isDisabled={isDisabled}
      stepNumber={stepNumber}
      stepHeader={stepHeader}
      headerOptionalText={headerOptionalText}
      startExpanded={startExpanded}
      currentStepName={currentStepName}
      project={project}
    >
      <TextSmallNeutralDark>{text}</TextSmallNeutralDark>
      <Gap8HorizontalFlexWrap>
        {!!codeToCopy && (
          <CopyCodeWrapper>
            <CustomCopyCode
              codeToCopy={codeToCopy}
              codeToDisplay={codeToDisplay || codeToCopy}
              buttonText={copyText}
              direction={FlexDirection.column}
            />
          </CopyCodeWrapper>
        )}
        {image && <ImageContainer>{image}</ImageContainer>}
      </Gap8HorizontalFlexWrap>
      {description && <DescriptionText>{description}</DescriptionText>}
    </InstallStepBase>
  );
};

const ImageContainer = styled(VerticalFlexCentered)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.neutralLightest};
  padding: 16px;
  border-radius: 4px;
`;

const CopyCodeWrapper = styled(Gap8HorizontalFlex)`
  max-width: 50%;
  margin-right: 48px;
`;

const TextSmallNeutralDark = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.neutralDark};
`;

const DescriptionText = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.grapePurple};
`;

const CustomCopyCode = styled(CopyCode)`
  padding: 8px 10px;
`;

export default InstallStepSimple;
