import { VIDEO_OUTPUT } from '../../../config/widget.config.js';
import { WEBP_AVATAR_EXTENSION, WEBP_EXTENSION } from '../../../constants/extensions.js';
import { DEFAULT_TILE_HEIGHT } from './carouselConstants.js';

const SHOPIFY_PREVIEW_HEIGHT = 480;

const getExtension = ({ isMobile, isStory, isPlayInTileFirst, isTile }) => {
  if (isPlayInTileFirst || isTile) {
    return '_640.mp4';
  }

  if (isMobile || isStory) {
    return '_preview.mp4';
  }

  return '_preview_embed.mp4';
};

export const getBaseUrl = ({
  step,
  isStory,
  isPlayInTileFirst,
  height = DEFAULT_TILE_HEIGHT,
  isTile
}) => {
  const { videoOwner, stockAsset, videoId } = step;

  const isMobile = window.innerWidth <= 450;
  const extension = getExtension({ isMobile, isStory, isPlayInTileFirst, isTile });
  const stockUrl = stockAsset?.videoUrl;
  const isShopifyFiles = Boolean(stockAsset?.shopifyFileId);
  const previewUrl = stockAsset?.previewUrl;
  const hasOriginal = stockAsset?.hasOriginal;
  const gifUrl = stockAsset?.gifUrl;
  const isDuplicatedShopifyFiles = isShopifyFiles && !hasOriginal;

  if (previewUrl && !isPlayInTileFirst && height < SHOPIFY_PREVIEW_HEIGHT && !isTile) {
    return previewUrl;
  }

  if (stockUrl && isShopifyFiles) {
    return stockUrl;
  }

  if (stockUrl && !isShopifyFiles) {
    if (stockUrl?.includes('vimeo')) {
      return stockUrl;
    }

    const fileWithoutExtension = stockUrl.split('.mp4')[0];
    return `${fileWithoutExtension}${extension}`;
  }

  if (gifUrl && isDuplicatedShopifyFiles) {
    const fileWithoutExtension = gifUrl.split('.mp4')[0];
    return `${fileWithoutExtension}${extension}`;
  }

  return `${VIDEO_OUTPUT}/public/${videoOwner}/${videoId}/${videoId}${extension}`;
};

export const getImageUrl = ({ videoOwner, stockAsset, videoId }, extension, posterSettings) => {
  if (!videoId) {
    return '';
  }

  const posterUrl = stockAsset?.posterUrl;
  const hasOriginal = stockAsset?.hasOriginal;
  const shopifyPosterUrl = stockAsset?.shopifyPosterUrl;

  if (shopifyPosterUrl && posterSettings?.useShopifyPoster) {
    const { shopifyAttributes = {}, shopifySpotlightAttributes = {} } = posterSettings;
    const params = { ...shopifyAttributes, ...shopifySpotlightAttributes };
    const searchParams = new URLSearchParams(params);
    return `${shopifyPosterUrl}&${searchParams.toString()}`;
  }

  if (posterUrl?.endsWith(extension) || (posterUrl && !hasOriginal)) {
    return posterUrl;
  }

  return `${VIDEO_OUTPUT}/public/${videoOwner}/${videoId}/${videoId}${extension}`;
};

export const getImageUrlByStep = (step, posterSettings) => {
  const isMobileScreen = window.innerWidth <= 450;
  const fileExtension = isMobileScreen ? WEBP_AVATAR_EXTENSION : WEBP_EXTENSION;

  return getImageUrl(step, fileExtension, posterSettings);
};

export const setVideoSourceOnEnd = (video, source) => {
  const setVideoSource = () => {
    // If autoplay is true, we set it as an empty string to avoid auto playing all videos, it will be later set in handleDynamicVideos
    if (video.autoplay) {
      video.src = '';
      return;
    }

    video.src = source;
  };

  video.addEventListener('ended', setVideoSource, { once: true });
};
