import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, useParams } from 'react-router-dom';
import Routes from 'src/helpers/Routes';
import { useUgcApps } from 'src/hooks/ugc';
import { useBilling } from 'src/hooks/useBillings';
import useNavigation from 'src/hooks/useNavigation';
import Spinner from 'app/src/basic_components/Spinner';
import { checkUgcPermissionsGranted } from 'src/utils/ugc';
import { useInstagramUgcSelectedCategory } from 'src/context/InstagramStore';
import { PageContainer, PageMain, PagePanel } from 'src/pages/dashboard/components/page/Page';
import {
  UGC_SEARCH,
  UGC_PENDING,
  UGC_APPROVED,
  UGC_PERMISSIONS,
} from 'src/constants/navigation.constants';

import {
  UgcSideBar,
  UgcSearchPage,
  UgcPendingPage,
  UgcApprovedPage,
  UgcGrantPermissionsPage,
} from './components';

const UgcPage = () => {
  const { ugcApps, loading } = useUgcApps();
  const { isFreePackage } = useBilling();
  const { navigateToUgc } = useNavigation();
  const { source } = useParams() as { source: string };
  const [selectedApp, setSelectedApp] = useState(ugcApps[0]);
  const [selectedUGCCategory] = useInstagramUgcSelectedCategory();
  const hasPermission = !isFreePackage && checkUgcPermissionsGranted(selectedApp);

  useEffect(() => {
    if (selectedApp) {
      const existingApp = ugcApps.find(app => app.id === selectedApp.id);
      if (existingApp) {
        setSelectedApp(existingApp);
      } else {
        return;
      }
    }

    setSelectedApp(ugcApps[0]);
  }, [ugcApps]);

  if (loading) {
    return (
      <PageContainer>
        <Spinner />
      </PageContainer>
    );
  }

  if (hasPermission && source === UGC_PERMISSIONS) {
    return <Redirect to={Routes.getDashbaordV2UgcRoute(UGC_SEARCH)} />;
  }

  if (!hasPermission && source !== UGC_PERMISSIONS) {
    return <Redirect to={Routes.getDashbaordV2UgcRoute(UGC_PERMISSIONS)} />;
  }

  return (
    <PageContainer>
      <PagePanel>
        <UgcSideBar source={source} selectSource={navigateToUgc} />
      </PagePanel>
      <PageMain>
        <Switch>
          <Route path={Routes.getDashbaordV2UgcRoute(UGC_SEARCH)}>
            <UgcSearchPage
              ugcApps={ugcApps}
              selectedApp={selectedApp}
              selectedUGCCategory={selectedUGCCategory}
              onSelectApp={setSelectedApp}
            />
          </Route>
          <Route path={Routes.getDashbaordV2UgcRoute(UGC_PENDING)}>
            <UgcPendingPage selectedApp={selectedApp} selectedUGCCategory={selectedUGCCategory} />
          </Route>
          <Route path={Routes.getDashbaordV2UgcRoute(UGC_APPROVED)}>
            <UgcApprovedPage selectedApp={selectedApp} selectedUGCCategory={selectedUGCCategory} />
          </Route>
          <Route path={Routes.getDashbaordV2UgcRoute(UGC_PERMISSIONS)}>
            <UgcGrantPermissionsPage />
          </Route>
          <Redirect to={Routes.getDashbaordV2UgcRoute(UGC_SEARCH)} />
        </Switch>
      </PageMain>
    </PageContainer>
  );
};

export default UgcPage;
