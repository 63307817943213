import React from 'react';

const CircleExclamation = ({ fill = '#FD7830' }) => {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 0.875C2.88281 0.875 0.375 3.40625 0.375 6.5C0.375 9.61719 2.88281 12.125 6 12.125C9.09375 12.125 11.625 9.61719 11.625 6.5C11.625 3.40625 9.09375 0.875 6 0.875ZM5.4375 4.0625C5.4375 3.75781 5.67188 3.5 6 3.5C6.30469 3.5 6.5625 3.75781 6.5625 4.0625V7.0625C6.5625 7.39062 6.30469 7.625 6 7.625C5.67188 7.625 5.4375 7.39062 5.4375 7.0625V4.0625ZM6 9.875C5.57812 9.875 5.25 9.54688 5.25 9.14844C5.25 8.75 5.57812 8.42188 6 8.42188C6.39844 8.42188 6.72656 8.75 6.72656 9.14844C6.72656 9.54688 6.39844 9.875 6 9.875Z"
        fill={fill}
      />
    </svg>
  );
};

export default CircleExclamation;
