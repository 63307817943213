import React from 'react';

const Success = (props = {}) => {
  return (
    <svg
      {...props}
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.25 1C11.25 1.21094 11.1562 1.39844 11.0156 1.53906L5.01562 7.53906C4.875 7.67969 4.6875 7.75 4.5 7.75C4.28906 7.75 4.10156 7.67969 3.96094 7.53906L0.960938 4.53906C0.820312 4.39844 0.75 4.21094 0.75 4C0.75 3.57812 1.07812 3.25 1.5 3.25C1.6875 3.25 1.875 3.34375 2.01562 3.48438L4.5 5.94531L9.96094 0.484375C10.1016 0.34375 10.2891 0.25 10.5 0.25C10.8984 0.25 11.25 0.578125 11.25 1Z"
        fill="#077F5A"
      />
    </svg>
  );
};

export default Success;
