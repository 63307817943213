import React from 'react';
import { Info } from 'lucide-react';
import { Progress } from 'ui-library/core';
import { useHistory } from 'react-router-dom';

import Routes from 'src/helpers/Routes';
import { usePricingUsage } from 'src/hooks/pricing';

export const PricingUsage = () => {
  const history = useHistory();
  const {
    maxViews,
    progressPercent,
    isPricingDisabled,
    isShopifySubscription,
    currentMonthPageView,
  } = usePricingUsage();

  const redirectToPricing = () => {
    history.push(Routes.getPlansRoute());
  };

  if (isPricingDisabled || Number.isNaN(progressPercent) || !isShopifySubscription) {
    return null;
  }

  const progressLabel = `${currentMonthPageView.toLocaleString()} / ${maxViews.toLocaleString()} impressions`;

  return (
    <div className="flex cursor-pointer flex-col gap-2 px-2" onClick={redirectToPricing}>
      <div className="text-xs">{progressLabel}</div>
      <Progress
        className="h-1 bg-slate-200"
        indicatorClassName="bg-gradient-to-r from-blue-500 to-blue-700"
        value={progressPercent}
      />
    </div>
  );
};
