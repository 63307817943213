import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TextH5, TextSmall } from 'shared/react/components/complex/Text';
import BasicInput from 'app/src/basic_components/BasicInput';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import Button from 'shared/react/components/complex/Button';
import Types from 'shared/react/theme/Types';
import Gap16HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap16HorizontalFlexWrap';
import { useUser } from 'app/src/context/userStore/UserStore';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

function EditProfileInformationModalContent({ onClose }) {
  const [, { updateUser, getUser }] = useUser();
  const user = getUser();
  const [firstName, setFirstName] = useState(user?.firstName);
  const [lastName, setLastName] = useState(user?.lastName);
  const [isLoading, setLoading] = useState(false);

  async function handleEnterKeyClick(event) {
    if (event.keyCode === 13) {
      await handleSave();
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleEnterKeyClick);
    return () => {
      window.removeEventListener('keydown', handleEnterKeyClick);
    };
  }, []);

  const handleSave = async () => {
    setLoading(true);
    await updateUser({ id: user.id, firstName, lastName });
    setLoading(false);
    onClose();
  };

  return (
    <LayoutRoot>
      <TextH5>Edit personal information</TextH5>

      <Gap16HorizontalFlexWrap>
        <StyledVerticalFlex>
          <TextSmall>First name</TextSmall>
          <StyledBasicInput
            placeholder="First name"
            value={firstName}
            onChange={e => {
              setFirstName(e.target.value);
            }}
          />
        </StyledVerticalFlex>
        <StyledVerticalFlex>
          <TextSmall>Last name</TextSmall>
          <StyledBasicInput
            placeholder="Last name"
            value={lastName}
            onChange={e => {
              setLastName(e.target.value);
            }}
          />
        </StyledVerticalFlex>
      </Gap16HorizontalFlexWrap>

      <StyledGap8HorizontalFlex>
        <Button type={Types.Ghost} onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleSave} loading={isLoading}>
          Save
        </Button>
      </StyledGap8HorizontalFlex>
    </LayoutRoot>
  );
}

export default EditProfileInformationModalContent;

const LayoutRoot = styled(VerticalFlex)`
  gap: 24px;
`;

const StyledGap8HorizontalFlex = styled(Gap8HorizontalFlexWrap)`
  align-self: end;
`;

const StyledVerticalFlex = styled(VerticalFlex)`
  gap: 4px;
`;

const StyledBasicInput = styled(BasicInput)`
  min-width: 275px;
  border: 1px solid ${({ theme }) => theme.colors.gray3};
  padding: 12px;
  border-radius: 10px;
  width: 100%;
`;
