import React from 'react';

const FilledVideoIcon = () => {
  return (
    <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.625 1.813A1.33 1.33 0 0 0 9.312.5H1.439C.699.5.125 1.102.125 1.813v7.875C.125 10.425.699 11 1.438 11h7.875c.71 0 1.312-.574 1.312-1.313V1.814Zm5.25.437c0-.684-.82-1.094-1.395-.71L11.5 3.616V7.91l2.98 2.078c.575.383 1.395-.027 1.395-.71V2.25Z"
        fill="#fff"
      />
    </svg>
  );
};

export default FilledVideoIcon;
