import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import CopyCode from 'app/src/complex_components/CopyCode';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { FlexDirection } from 'app/src/types/common';
import CopyToClipboardIcon from 'app/src/images/dashboard_v2/CopyToClipboardIcon';
import React from 'react';
import styled from 'styled-components';

export const getStepsForTapcartPDP = project => {
  const tapcartPDPData = [
    {
      title: 'HTML',
      code: `<tolstoy-block
    data-publish-id="${project.publishId}"
    data-block-type="pdp-carousel"
></tolstoy-block>`,
    },
    {
      title: 'CSS',
      code: `* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}`,
    },
    {
      title: 'Javascript',
      code: `function createTolstoyBlock() {
  const script = document.createElement('script');
  script.setAttribute('type', 'module');
  script.setAttribute('defer', '');
  script.setAttribute('src', 'https://widget.gotolstoy.com/we/widget.js');
  script.setAttribute('data-app-key', '${project.appKey}');
  
  document.head.appendChild(script);
} 

createTolstoyBlock();`,
    },
  ];

  return [
    {
      title: (
        <>
          Follow the{' '}
          <Link href="https://share.synthesia.io/af544780-c82a-4cff-b60f-049686e44cb7">
            installation instructions
          </Link>{' '}
          to add your feed to Tapcart using custom blocks with the below code
        </>
      ),
      body: (
        <Gap8VerticalFlex>
          {tapcartPDPData.map(data => (
            <VerticalFlex key={data.title}>
              <div>{data.title}</div>
              <CopyCode
                direction={FlexDirection.row}
                codeToCopy={data.code}
                padding="8px"
                buttonText={<CopyToClipboardIcon />}
              />
            </VerticalFlex>
          ))}
        </Gap8VerticalFlex>
      ),
    },
  ];
};

const Link = styled.a`
  color: ${({ theme }) => theme.colors.primary};

  &:hover {
    color: ${({ theme }) => theme.colors.primaryHover};
  }
`;
