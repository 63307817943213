import React from 'react';
import styled from 'styled-components';
import { HorizontalFlexCentered } from './flex_layouts/HorizontalFlex';
import BadgeNumber from 'shared/react/components/basic/BadgeNumber';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';
import useKeyboardNavigation from 'player/src/hooks/useKeyboardNavigation';

const BackdropButton = ({
  children,
  onClick,
  badgeNumber,
  dataType,
  isSmall,
  ariaLabel = 'button',
  ...props
}) => {
  const { player } = useDesignSettingsContext();
  const { onKeyDown, setNextRef } = useKeyboardNavigation();

  const { feedButtonsBorderRadius, opacity, backgroundColor, color } = player?.controls || {};

  return (
    <LayoutRoot
      onClick={onClick}
      data-type={dataType}
      role="button"
      ref={setNextRef}
      onKeyDown={onKeyDown}
      aria-label={ariaLabel}
      tabIndex="0"
    >
      <BadgeContainer>
        <BadgeNumber value={badgeNumber} />
      </BadgeContainer>
      <IconContainer
        borderRadius={feedButtonsBorderRadius}
        opacity={opacity}
        backgroundColor={backgroundColor}
        color={color}
        isSmall={isSmall}
        {...props}
      >
        {children}
      </IconContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  cursor: pointer;
  position: relative;
`;

const IconContainer = styled(HorizontalFlexCentered)`
  background: ${({ theme, opacity = 20, backgroundColor = theme.colors.black }) =>
    theme.getHexOpacity(backgroundColor, opacity * 100)};
  border-radius: ${({ borderRadius = 100 }) => `${borderRadius}px`};
  backdrop-filter: blur(10px);
  transition: 0.3s;
  width: ${({ isSmall }) => (isSmall ? '32px' : '40px')};
  height: ${({ isSmall }) => (isSmall ? '32px' : '40px')};

  &:hover {
    background: ${({ theme, opacity = 20, backgroundColor = theme.colors.black }) =>
      theme.getHexOpacity(backgroundColor, opacity * 100 + 10)};
  }

  svg,
  svg path {
    fill: ${({ theme, color = theme.colors.white }) => color};
  }
`;

const BadgeContainer = styled.div`
  position: absolute;
  z-index: 1;
  transform: translate(20%, -20%);
  top: 0;
  right: 0;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    transform: translate(30%, -30%);
  }
`;

export default BackdropButton;
