import React from 'react';

export const PaddingHorizontalIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.5 0c.25 0 .5.25.5.5v15c0 .281-.25.5-.5.5a.494.494 0 0 1-.5-.5V.5c0-.25.219-.5.5-.5ZM11 11.5A1.5 1.5 0 0 1 9.5 13h-3A1.48 1.48 0 0 1 5 11.5v-7A1.5 1.5 0 0 1 6.5 3h3c.813 0 1.5.688 1.5 1.5v7Zm-1 0v-7c0-.25-.25-.5-.5-.5h-3c-.281 0-.5.25-.5.5v7c0 .281.219.5.5.5h3c.25 0 .5-.219.5-.5Zm-8 4c0 .281-.25.5-.5.5a.494.494 0 0 1-.5-.5V.5c0-.25.219-.5.5-.5.25 0 .5.25.5.5v15Z"
      fill="#fff"
    />
  </svg>
);
