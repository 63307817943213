import React, { useCallback, useEffect } from 'react';
import { Modal } from '@material-ui/core';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import styled from 'styled-components';
import { VerticalFlexCentered } from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import TopBar from 'app/src/pages/dashboard/components/top-bar/TopBar';
import useUpdateDirtyForm from 'app/src/hooks/useUpdateDirtyForm';
import { useBuilderFormContext } from 'app/src/context/BuilderFormStore';
import BuilderEditStep from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/BuilderEditStep';

const EditStepModal = ({ closeModal }) => {
  const [
    {
      modalProps: { step, project, reply },
    },
  ] = useModal();
  const {
    resetBuilderForm,
    builderForm: { dirtyFormToUpdate },
  } = useBuilderFormContext();

  const onSaveClick = useCallback(() => {
    dirtyFormToUpdate.onSaveClick(project, step);
    closeModal();
  }, [dirtyFormToUpdate.onSaveClick, step?.id, project]);

  const onDiscardClick = useCallback(() => {
    closeModal();
  }, []);

  useUpdateDirtyForm(true, {
    ...dirtyFormToUpdate,
    onSaveClick,
    onDiscardClick,
  });

  useEffect(() => {
    return () => {
      resetBuilderForm();
    };
  }, []);

  return (
    <Modal disableEnforceFocus open={true}>
      <ContentContainer>
        <TopBar />
        <CustomEditStep step={step} project={project} reply={reply} />
      </ContentContainer>
    </Modal>
  );
};

const ContentContainer = styled(VerticalFlexCentered)`
  height: 100%;
  width: 100%;
  overflow: auto;

  & > * {
    width: 95%;
  }

  & > :first-child {
    border-radius: 4px 4px 0 0;
  }

  & > :last-child {
    border-radius: 0 0 4px 4px;
  }
`;

const CustomEditStep = styled(BuilderEditStep)`
  overflow: unset;
  display: flex;
  flex-direction: column-reverse;
  padding: 0;
  max-height: 800px;
  background: ${({ theme }) => theme.colors.white};

  @media (${({ theme }) => theme.breakpoints.dashboardTabletMax}) {
    max-height: 100%;
  }
`;

export default EditStepModal;
