import type { PivotRow, ResultSet } from '@cubejs-client/core';
import Utils from 'app/src/utils';
import { convertRevenueToUsd } from 'app/src/services/cube/cube.utils';
import { CUBE_PROPERTY_KEYS } from 'app/src/constants/cube.constants';
import { CURRENCY_CODES } from 'shared/react/constants/currency.constants';
import {
  EmailConversionOvertimeRecord,
  BaseEmailConversionMetrics,
} from './types/getEmailConvertions.types';

type Props = {
  emailMetricsOvertime: ResultSet;
  rates: Record<string, number>;
};

type GroupByCampaignProps = {
  rates: Record<string, number>;
  yValues: PivotRow['yValuesArray'];
};

type GetValueAggregatorProps = {
  campaignMetrics?: BaseEmailConversionMetrics;
  metrics: EmailConversionOvertimeRecord;
  rates: Record<string, number>;
  row: [string[], number];
};

const DEFAULT_EVENT_METRICS = {
  conversionRate: 0,
  directRevenue: 0,
  indirectRevenue: 0,
  inVideoRevenue: 0,
  numOfEmailClicks: 0,
  numOfOrders: 0,
  postVideoRevenue: 0,
};

const getValueAggregator = ({ metrics, campaignMetrics, row, rates }: GetValueAggregatorProps) => {
  const [[, currency, key], currentValue] = row;
  const [, currentProp] = key.split('.');

  return (prop: string, isCurrency = false) => {
    if (currentProp !== prop) {
      return {
        campaign: campaignMetrics[prop],
        total: metrics[prop],
      };
    }

    let value = Number.isFinite(+currentValue) ? +currentValue : 0;
    if (isCurrency) {
      value = convertRevenueToUsd({ revenue: value, rate: rates[currency] });
    }

    return {
      campaign: campaignMetrics[prop] + value,
      total: metrics[prop] + value,
    };
  };
};

const groupByCampaign = ({ yValues, rates }: GroupByCampaignProps) => {
  let metrics = {
    ...DEFAULT_EVENT_METRICS,
    attributionByCampaignType: {},
  } as EmailConversionOvertimeRecord;

  for (const row of yValues) {
    const [[campaign]] = row;
    const campaignName = campaign || 'Other';
    const campaignMetrics =
      metrics.attributionByCampaignType[campaignName] || DEFAULT_EVENT_METRICS;

    const getAggregatedValue = getValueAggregator({
      metrics,
      campaignMetrics,
      row,
      rates,
    });

    const indirectRevenue = getAggregatedValue(CUBE_PROPERTY_KEYS.indirectRevenue, true);
    const inVideoRevenue = getAggregatedValue(CUBE_PROPERTY_KEYS.inVideoRevenue, true);
    const postVideoRevenue = getAggregatedValue(CUBE_PROPERTY_KEYS.postVideoRevenue, true);
    const totalDirectRevenue = inVideoRevenue.total + postVideoRevenue.total;
    const campaignDirectRevenue = inVideoRevenue.campaign + postVideoRevenue.campaign;

    const numOfEmailClicks = getAggregatedValue(CUBE_PROPERTY_KEYS.numOfEmailClicks);
    const numOfOrders = getAggregatedValue(CUBE_PROPERTY_KEYS.numOfOrders);

    const campaignConversionRate = Utils.getPercentage(
      numOfOrders.campaign,
      numOfEmailClicks.campaign
    );
    const totalConversionRate = Utils.getPercentage(numOfOrders.total, numOfEmailClicks.total);

    metrics = {
      conversionRate: totalConversionRate,
      currency: CURRENCY_CODES.usd,
      directRevenue: totalDirectRevenue,
      indirectRevenue: indirectRevenue.total,
      inVideoRevenue: inVideoRevenue.total,
      numOfEmailClicks: numOfEmailClicks.total,
      numOfOrders: numOfOrders.total,
      postVideoRevenue: postVideoRevenue.total,
      attributionByCampaignType: {
        ...metrics.attributionByCampaignType,
      },
    } as EmailConversionOvertimeRecord;

    const isCampaignMetricsEmpty = !numOfEmailClicks.campaign && !numOfOrders.campaign;
    if (!isCampaignMetricsEmpty) {
      metrics.attributionByCampaignType[campaignName] = {
        conversionRate: campaignConversionRate,
        indirectRevenue: indirectRevenue.campaign,
        inVideoRevenue: inVideoRevenue.campaign,
        directRevenue: campaignDirectRevenue,
        numOfEmailClicks: numOfEmailClicks.campaign,
        numOfOrders: numOfOrders.campaign,
        postVideoRevenue: postVideoRevenue.campaign,
      };
    }
  }

  return metrics;
};

const getMostCommonCurrency = (emailMetricsOvertimePivot: PivotRow[]) => {
  let mostCommonCurrency = CURRENCY_CODES.usd;
  if (!emailMetricsOvertimePivot) {
    return mostCommonCurrency;
  }

  const currencies: Record<string, number> = {};
  for (const { yValuesArray } of emailMetricsOvertimePivot) {
    for (const [[_, currency]] of yValuesArray) {
      if (!currency) {
        continue;
      }

      currencies[currency] = (currencies[currency] || 0) + 1;
      if (currencies[currency] > currencies[mostCommonCurrency]) {
        mostCommonCurrency = currency;
      }
    }
  }

  return mostCommonCurrency;
};

const convertValueToCurrency = (value: number, currency: string, rates: Record<string, number>) => {
  if (currency === CURRENCY_CODES.usd) {
    return value;
  }

  return value * rates[currency];
};

const convertRecordToCurrency = (
  record: EmailConversionOvertimeRecord,
  currency: string,
  rates: Record<string, number>
): EmailConversionOvertimeRecord => {
  record.currency = currency;

  const objectsToConvert = [record, ...Object.values(record.attributionByCampaignType)];

  for (const obj of objectsToConvert) {
    obj.directRevenue = convertValueToCurrency(obj.directRevenue, currency, rates);
    obj.inVideoRevenue = convertValueToCurrency(obj.inVideoRevenue, currency, rates);
    obj.indirectRevenue = convertValueToCurrency(obj.indirectRevenue, currency, rates);
    obj.postVideoRevenue = convertValueToCurrency(obj.postVideoRevenue, currency, rates);
  }

  return record;
};

const getEmailConversionsOvertime = ({ emailMetricsOvertime, rates }: Props) => {
  const pivot = emailMetricsOvertime?.pivot();
  const mostCommonCurrency = getMostCommonCurrency(pivot);

  const emailConversionsOvertime = pivot?.map(({ xValues, yValuesArray }) => {
    const recordInUsd = {
      x: xValues[0],
      xValues,
      ...groupByCampaign({ rates, yValues: yValuesArray }),
    };
    return convertRecordToCurrency(recordInUsd, mostCommonCurrency, rates);
  });

  return {
    currency: mostCommonCurrency,
    emailConversionsOvertime,
  };
};

export default getEmailConversionsOvertime;
