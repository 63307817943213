import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import StandardModal from 'app/src/pages/modals/StandardModal';
import { TextBody, TextH5, TextTiny } from 'shared/react/components/complex/Text';
import { BILLING_PACKAGE_TYPES, SHOW_TRIAL_MODAL } from 'app/src/constants/billings.constants';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import ProIcon from 'app/src/images/ProIcon';
import { usePackages } from 'app/src/context/PackagesStore';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import Button from 'shared/react/components/complex/Button';
import { useAccount } from 'app/src/context/AccountStore';
import { useBilling } from 'app/src/hooks/useBillings';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { TRIAL_MODAL_KEY } from 'app/src/constants/modals.constants';
import { prepareTeamMembersToUpdateMap } from 'app/src/utils/pricing.utils';
import { navigateToRoot } from 'app/src/utils/navigation.utils';
import { useHistory } from 'react-router-dom';

const TrialModal = () => {
  const [{ packages }, { upgradePackage }] = usePackages();
  const [{ teamMembers }, { updatePaymentRole }] = useAccount();
  const [{ currentModal }, { setCurrentModal }] = useModal();

  const history = useHistory();
  const [proPackage, setProPackage] = useState({});
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const { isPricingEnabled, handleTrackOpenSubscriptionPage } = useBilling();

  const onClose = () => {
    localStorage.removeItem(SHOW_TRIAL_MODAL);
    setCurrentModal('');
  };

  useEffect(() => {
    if (!isPricingEnabled) {
      onClose();
      return;
    }

    const proPackageData = packages.find(({ type }) => type === BILLING_PACKAGE_TYPES.pro);
    setProPackage(proPackageData);
  }, []);

  const onSubmit = async () => {
    setLoading(true);

    const teamMembersToUpdateMap = prepareTeamMembersToUpdateMap(teamMembers);

    await updatePaymentRole({ teamMembersToUpdateMap });

    const link = await upgradePackage(proPackage.id);

    if (!link) {
      return navigateToRoot(history);
    }

    handleTrackOpenSubscriptionPage();

    setLoading(false);
    localStorage.removeItem(SHOW_TRIAL_MODAL);

    document.location.href = link;
  };

  const trialDays = proPackage?.trialPeriodDays;
  return (
    <StandardModal open={currentModal === TRIAL_MODAL_KEY} onClose={onClose} width="440px">
      <LayoutRoot>
        <ProIcon fill={theme.colors.black} width={28} height={20} />
        <ModalBody>
          <TextH5>Start free {trialDays}-day trial</TextH5>
          <StyledTextBody>
            Almost there, we only need one more thing before we get you started on the Pro trial.
          </StyledTextBody>
        </ModalBody>
        <ButtonContainer>
          <Button onClick={onSubmit} loading={loading}>
            Submit credit card info
          </Button>
          <StyledTextTiny>
            Payment only starts after {trialDays} days. You can cancel any time.
          </StyledTextTiny>
        </ButtonContainer>
      </LayoutRoot>
    </StandardModal>
  );
};

export default TrialModal;

const LayoutRoot = styled(VerticalFlex)`
  gap: 24px;
`;

const StyledTextBody = styled(TextBody)`
  color: ${({ theme }) => theme.colors.ghostDark};
`;

const ModalBody = styled(Gap8VerticalFlex)`
  margin-bottom: 16px;
`;

const ButtonContainer = styled(Gap16VerticalFlex)`
  align-items: flex-end;
`;

const StyledTextTiny = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.ghostDark};
`;
