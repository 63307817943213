import React from 'react';

const PlayIcon = ({ fill = '#fff' }) => (
  <svg width={11} height={11} viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.131 4.619c.301.194.494.537.494.881 0 .365-.193.709-.494.881l-6.188 3.781c-.322.193-.731.215-1.053.022-.322-.172-.515-.516-.515-.903V1.719c0-.365.193-.709.516-.881A.982.982 0 0 1 2.944.86l6.188 3.76Z"
      fill={fill}
    />
  </svg>
);

export default PlayIcon;
