import React from 'react';
import styled from 'styled-components';
import BasicButton from 'shared/react/components/basic/button/button/BasicButton';
import { IMPORT_FROM_TIKTOK } from 'app/src/constants/creation_method.constants';
import TikTokIcon from 'app/src/images/TikTokIcon';
import InstagramIcon from 'app/src/images/InstagramIcon';

const IntegrationsButton = ({ children, integration, ...props }) => {
  return (
    <Button {...props}>
      <IntegrationIcon integration={integration} />
      {children}
    </Button>
  );
};

const IntegrationIcon = ({ integration }) => {
  if (integration === IMPORT_FROM_TIKTOK) {
    return <TikTokIcon />;
  }

  return <InstagramIcon />;
};

const Button = styled(BasicButton)`
  max-width: 198px;
  gap: 6px;
  padding: 12px 24px;

  & svg {
    width: 14px;
    height: 12px;
  }

  & path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export default IntegrationsButton;
