import React from 'react';
import styled from 'styled-components';
import RuleGroup from 'app/src/pages/project/pages/rules_and_triggers/editor/shared/RuleGroup';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { useRules } from 'app/src/context/RulesStore';
import { useRulesContext } from 'app/src/pages/project/pages/rules_and_triggers/RulesContext';

function RuleGroupContainer({ andConditions, emptyState, attributes, setAttributes }) {
  const [{ invalidRules }, { clearInvalidRules }] = useRules();
  const { setRuleGroup, setUnsaved } = useRulesContext();

  if (!andConditions?.length) {
    return (
      <LayoutRoot>
        <MainContainer>{emptyState}</MainContainer>
      </LayoutRoot>
    );
  }

  return (
    <LayoutRoot>
      <MainContainer>
        <RuleGroupsContainer>
          {andConditions.map((andCondition, i, array) => (
            <div key={andCondition.orConditions[0].key}>
              <RuleGroup
                invalidRules={invalidRules}
                clearInvalidRules={clearInvalidRules}
                orConditions={andCondition.orConditions}
                andConditions={andConditions}
                setRuleGroup={ruleGroup => {
                  setRuleGroup(ruleGroup);
                  setUnsaved(true);
                }}
                andConditionIndex={andCondition.originalIndex}
                isLastRule={array.length - 1 === i}
                attributes={attributes}
                setAttributes={setAttributes}
              />
            </div>
          ))}
        </RuleGroupsContainer>
      </MainContainer>
    </LayoutRoot>
  );
}

export default RuleGroupContainer;

const LayoutRoot = styled(VerticalFlex)`
  width: 100%;
`;

const MainContainer = styled(VerticalFlex)`
  width: 100%;
`;

const RuleGroupsContainer = styled(Gap16VerticalFlex)`
  margin: 8px 0;
`;
