import React from 'react';

const XIcon = ({ ...props }) => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.563 10.313 7.772 7.5l2.79-2.79a.513.513 0 0 0 0-.772.513.513 0 0 0-.774 0L7 6.726l-2.813-2.79a.513.513 0 0 0-.773 0 .513.513 0 0 0 0 .774l2.79 2.79-2.79 2.813a.513.513 0 0 0 0 .773.513.513 0 0 0 .773 0L7 8.296l2.79 2.79c.21.234.562.234.773 0a.513.513 0 0 0 0-.774Z"
      fill="#090A0B"
    />
  </svg>
);

export default XIcon;
