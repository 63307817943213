import { useEffect, useState } from 'react';
import { useVideos } from 'app/src/context/VideosStore';
import DefaultProjectPlaceholderV2 from 'app/src/images/dashboard_v2/default_project_placeholder_v2.svg';

export const useVideoAssets = project => {
  const [, { getVideoPosterLarge, getFirstVideoByProject }] = useVideos();
  const [largeProjectImage, setLargeProjectImage] = useState();
  const { stepsOrder, steps } = project || {};

  const getLargeProjectImage = () => {
    const firstVideo = getFirstVideoByProject(project);
    if (!firstVideo) {
      return DefaultProjectPlaceholderV2;
    }

    return getVideoPosterLarge(firstVideo);
  };

  useEffect(() => {
    if (!project) {
      return;
    }

    setLargeProjectImage(getLargeProjectImage());
  }, [stepsOrder, steps]);

  const smallProjectImage = (largeProjectImage || '').replace('0000000.jpg', 'thumb.0000000.jpg');

  return {
    largeProjectImage,
    smallProjectImage,
  };
};
