import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import InputRange from 'react-input-range';
import Utils from 'shared/react/utils/utils';
import Duration from 'shared/react/components/basic/Duration';
import { TextTiny } from 'shared/react/components/complex/Text';
import useTracker from 'shared/react/components/complex/context/hooks/useTracker';
import HorizontalFlex, { HorizontalFlexCentered } from '../../flex_layouts/HorizontalFlex';
import VerticalFlex from '../../flex_layouts/VerticalFlex';
import { useFeedIsMobile } from '../../../../hooks/useIsMobile';
import SharedUtils from 'shared/react/utils/utils';
import { TOLSTOY_PLAYER_VIDEO_METADATA_CHANGE_MESSAGE } from 'shared/react/constants/messages.constants';

const FeedDurationBar = ({ videoRef, isShowDuration, ...props }) => {
  const [, { track }] = useTracker();
  const isMobile = useFeedIsMobile();
  const [timePlayed, setTimePlayed] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const concurrentVideoRef = useRef(null);

  const handleTimeUpdate = e => {
    if (concurrentVideoRef.current !== videoRef.current) {
      return;
    }

    SharedUtils.postMessageToWindow({
      name: TOLSTOY_PLAYER_VIDEO_METADATA_CHANGE_MESSAGE,
      duration: e.target.duration,
      currentTime: e.target.currentTime,
    });

    setTimePlayed(e.target.currentTime);
  };

  const handleVideoSeek = value => {
    videoRef.current.currentTime = value;
  };

  const handleVideoSeekCompleted = () => {
    track('videoSeeked', { ...Utils.getAnalyticsLocationObject('Feed'), videoTime: timePlayed });
  };

  useEffect(() => {
    if (!videoRef?.current) {
      return;
    }

    videoRef.current.addEventListener('timeupdate', handleTimeUpdate);
    concurrentVideoRef.current = videoRef.current;

    return () => {
      concurrentVideoRef.current.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [videoRef?.current]);

  useEffect(() => {
    if (!videoRef.current?.duration) {
      return;
    }

    const duration = videoRef.current.duration;
    if (isNaN(duration)) {
      return;
    }

    const roundedDuration = Math.round(duration);
    if (totalTime === roundedDuration) {
      return;
    }

    setTotalTime(roundedDuration);
  }, [videoRef?.current?.duration, totalTime]);

  return (
    <LayoutRoot {...props}>
      <InputRangeContainer isMobile={isMobile}>
        <InputRange
          maxValue={videoRef.current?.duration || 0.1}
          minValue={0}
          step={0.001}
          value={timePlayed || 0}
          onChange={handleVideoSeek}
          onChangeComplete={handleVideoSeekCompleted}
        />
      </InputRangeContainer>
      {isShowDuration && (
        <DurationsContainer>
          {[timePlayed, totalTime].map((time, i) => (
            <DurationText key={i}>
              <Duration seconds={time} />
            </DurationText>
          ))}
        </DurationsContainer>
      )}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  width: 100%;
`;

const InputRangeContainer = styled(HorizontalFlexCentered)`
  height: ${({ isMobile }) => (isMobile ? 8 : 4)}px;

  & .input-range {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  & .input-range__track {
    background: ${({ theme }) => theme.getHexOpacity(theme.colors.white, 45)};
    transition: left 0.3s ease-out, width 0.3s ease-out;
    height: 100%;
    border-radius: 0px;
  }

  & .input-range__track--active {
    background: ${({ theme }) => theme.colors.white};
  }

  &:hover .input-range__slider {
    display: initial;
    background: ${({ theme }) => theme.colors.white};
  }

  & .input-range__label {
    display: none;
  }
`;

const DurationsContainer = styled(HorizontalFlex)`
  justify-content: space-between;
  padding: 0 8px;
  height: 36px;
`;

const DurationText = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.white};
  align-self: center;
  padding: 8px;
`;

export default FeedDurationBar;
