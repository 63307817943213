import React from 'react';

const UploadIcon = props => (
  <svg
    width="18"
    height="21"
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.531.695 2.906 6.32a.662.662 0 0 0 0 .899.662.662 0 0 0 .899 0l4.57-4.57v10.976c0 .352.273.625.625.625a.642.642 0 0 0 .625-.625V2.648l4.531 4.57a.662.662 0 0 0 .899 0 .662.662 0 0 0 0-.898L9.43.695A.627.627 0 0 0 9 .5a.647.647 0 0 0-.469.195Zm7.969 12.93v3.75c0 1.055-.86 1.875-1.875 1.875H3.375A1.851 1.851 0 0 1 1.5 17.375v-3.75c0-.313-.313-.625-.625-.625a.642.642 0 0 0-.625.625v3.75c0 1.758 1.367 3.125 3.125 3.125h11.25a3.11 3.11 0 0 0 3.125-3.125v-3.75c0-.313-.313-.625-.625-.625a.642.642 0 0 0-.625.625Z"
      fill="#090A0B"
    />
  </svg>
);

export default UploadIcon;
