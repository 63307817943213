import React from 'react';
import styled from 'styled-components';
import { TextH6 } from 'shared/react/components/basic/text/TextV2';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import ProductTag from 'app/src/complex_components/search-products/product-tags/ProductTag';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import { FEATURE_SHOPIFY_TAGS } from 'shared/react/constants/features.constants';
import Separator from 'shared/react/components/basic/Separator';
import FilledTrashIcon from 'app/src/images/sidebar-v2/FilledTrashIcon';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import { useSelectedProductsContext } from 'app/src/complex_components/global_modal/video/add_products_modal/SelectedProductsContext';

const ShopifyTags = () => {
  const { getFeatureEnabled } = useFeatureActions();
  const [{ selectedTags }, { removeTag }] = useSelectedProductsContext();
  const shopifyTagsEnabled = getFeatureEnabled(FEATURE_SHOPIFY_TAGS);

  if (!shopifyTagsEnabled) {
    return null;
  }

  return (
    <LayoutRoot>
      <Header>{selectedTags.length} tags</Header>
      <TagsContainer>
        {selectedTags.map(tag => (
          <React.Fragment key={tag}>
            <ProductTag tag={tag} endIcon={<FilledTrashIcon onClick={() => removeTag(tag)} />} />
            <StyledSeparator />
          </React.Fragment>
        ))}
      </TagsContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)`
  width: 100%;
`;

const Header = styled(TextH6)`
  font-weight: 500;
`;

const TagsContainer = styled(Gap8HorizontalFlexWrap)``;

const StyledSeparator = styled(Separator)`
  flex-shrink: 0;
  background: ${({ theme }) => theme.colors.neutralLighter};

  &:last-of-type {
    display: none;
  }
`;

export default ShopifyTags;
