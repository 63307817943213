import React from 'react';
import styled from 'styled-components';
import HorizontalFlex from '../../flex_layouts/HorizontalFlex';
import { milkConditionCss } from 'shared/react/components/clientsCss.constants';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';
import useIsMilk from 'shared/react/hooks/useIsMilk';
import useIsFenty, { FENTY_MAX_QUANTITY } from 'shared/react/hooks/useIsFenty';
import ThinPlusIcon from 'shared/react/images/feed/ThinPlusIcon';
import ThinMinusIcon from 'shared/react/images/feed/ThinMinusIcon';

const FeedQuantityPicker = ({ isAddToCart, quantityPickerState }) => {
  const { player } = useDesignSettingsContext();
  const { hasQuantityPicker } = player.feedCards.productDetailsCard.primaryButton.settings;
  const isMilk = useIsMilk();
  const isFenty = useIsFenty();

  if (!hasQuantityPicker || !isAddToCart) {
    return null;
  }

  const { quantity, setQuantity } = quantityPickerState || {};

  const onAddQuantity = () => {
    if (isFenty) {
      setQuantity(Math.min(FENTY_MAX_QUANTITY, quantity + 1));
      return;
    }

    setQuantity(quantity + 1);
  };

  const isDisabled = isFenty && quantity >= FENTY_MAX_QUANTITY;

  return (
    <QuantityPickerContainer $isFenty={isFenty} onClick={e => e.stopPropagation()}>
      <ChangeQuantityButton
        $isFenty={isFenty}
        isMilk={isMilk}
        onClick={() => setQuantity(Math.max(1, quantity - 1))}
      >
        <ThinMinusIcon />
      </ChangeQuantityButton>
      <ChangeQuantityInput $isFenty={isFenty} isMilk={isMilk} value={quantity} />
      <ChangeQuantityButton
        $isFenty={isFenty}
        $isDisabled={isDisabled}
        isMilk={isMilk}
        onClick={onAddQuantity}
      >
        <ThinPlusIcon />
      </ChangeQuantityButton>
    </QuantityPickerContainer>
  );
};

const QuantityPickerContainer = styled(HorizontalFlex)`
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
  width: ${({ $isFenty }) => ($isFenty ? '72px' : '90px')};
  overflow: hidden;
  justify-content: space-between;
`;

const ChangeQuantityInput = styled.input`
  width: ${({ $isFenty }) => ($isFenty ? '24px' : '30px')};
  border: none;
  text-align: center;
  outline: 0;
  font-size: 19px;
  line-height: 18px;
  font-weight: 600;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ $isFenty }) => ($isFenty ? '14px' : '19px')};
  font-weight: ${({ $isFenty }) => ($isFenty ? '700' : '600')};
  padding: ${({ $isFenty }) => ($isFenty ? '2px 0 0 0' : '0')};
  ${milkConditionCss}
`;

const ChangeQuantityButton = styled.button`
  width: ${({ $isFenty }) => ($isFenty ? '24px' : '30px')};
  border: none;
  background-color: ${({ theme }) => theme.colors.white};
  cursor: ${({ $isDisabled }) => ($isDisabled ? '' : 'pointer')};

  font-size: 16px;

  ${milkConditionCss}

  svg {
    height: 8px;
  }

  path {
    fill: ${({ theme, $isDisabled }) => ($isDisabled ? '#b1b1b1' : theme.colors.black)};
  }

  &:active {
    background-color: ${({ theme, $isDisabled }) =>
      $isDisabled ? '' : theme.colors.neutralLighter};
  }
`;

export default FeedQuantityPicker;
