import React from 'react';
import styled from 'styled-components';
import ExpanderIcon from '../../../../images/ExpanderIcon';
import HorizontalFlex from '../../flex_layouts/HorizontalFlex';
import FeedCartTitle from './FeedCartTitle';
import useKeyboardNavigation from 'player/src/hooks/useKeyboardNavigation';

const FeedCartHeader = ({ shouldHide, numberOfCartItems, onClickGoBack }) => {
  const { onKeyDown, setNextRef } = useKeyboardNavigation();

  if (shouldHide) {
    return null;
  }

  return (
    <LayoutRoot>
      <BackButton
        onClick={onClickGoBack}
        role="button"
        aria-label="Back button"
        tabIndex="0"
        onKeyDown={onKeyDown}
        ref={setNextRef}
      >
        <StyledExpanderIcon />
      </BackButton>
      <FeedCartTitle itemsQuantity={numberOfCartItems} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)`
  gap: 12px;
  padding: 24px;
  align-items: center;
`;

const BackButton = styled(HorizontalFlex)`
  gap: 6px;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  background: ${({ theme }) => theme.getHexOpacity(theme.colors.white, 60)};
  border-radius: 12px;
`;

const StyledExpanderIcon = styled(ExpanderIcon)`
  transform: rotate(90deg);
  width: 20px;
  height: 20px;

  & path {
    fill: ${({ theme }) => theme.colors.black};
  }
`;

export default FeedCartHeader;
