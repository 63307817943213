import tinycolor from 'tinycolor2';
import Colors from 'shared/react/constants/colors';

const primary = Colors.blue10;
const secondary = Colors.blue5;
const warning = Colors.yellow3;
const success = Colors.green3;
const info = Colors.purple2;

const lightenRate = 7.5;
const darkenRate = 15;

export default {
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary).lighten(lightenRate).toHexString(),
      dark: tinycolor(primary).darken(darkenRate).toHexString(),
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary).lighten(lightenRate).toHexString(),
      dark: tinycolor(secondary).darken(darkenRate).toHexString(),
      contrastText: Colors.white,
    },
    warning: {
      main: warning,
      light: tinycolor(warning).lighten(lightenRate).toHexString(),
      dark: tinycolor(warning).darken(darkenRate).toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success).lighten(lightenRate).toHexString(),
      dark: tinycolor(success).darken(darkenRate).toHexString(),
    },
    info: {
      main: info,
      light: tinycolor(info).lighten(lightenRate).toHexString(),
      dark: tinycolor(info).darken(darkenRate).toHexString(),
    },
    text: {
      primary: Colors.gray17,
      secondary: Colors.gray18,
      hint: Colors.gray19,
    },
    background: {
      default: Colors.gray7,
      light: Colors.gray16,
    },
  },
  customShadows: {
    widget: `0px 3px 11px 0px ${({ theme }) => theme.colors.gray20}, 0 3px 3px -2px ${({ theme }) =>
      theme.colors.gray21}, 0 1px 8px 0 ${({ theme }) => theme.colors.gray22}`,
    widgetDark: `0px 3px 18px 0px ${({ theme }) => theme.colors.blue11}, 0 3px 3px -2px ${({
      theme,
    }) => theme.colors.gray21}, 0 1px 8px 0 ${({ theme }) => theme.colors.gray22}`,
    widgetWide: `0px 12px 33px 0px ${({ theme }) => theme.colors.gray20}, 0 3px 3px -2px ${({
      theme,
    }) => theme.colors.gray21}, 0 1px 8px 0 ${({ theme }) => theme.colors.gray22}`,
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: `${({ theme }) => theme.colors.gray17}1A`,
      },
    },
    MuiMenu: {
      paper: {
        boxShadow: `0px 3px 11px 0px ${({ theme }) => theme.colors.gray20}, 0 3px 3px -2px ${({
          theme,
        }) => theme.colors.gray21}, 0 1px 8px 0 ${({ theme }) => theme.colors.gray22}`,
      },
    },
    MuiSelect: {
      icon: {
        color: Colors.gray19,
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: `${({ theme }) => theme.colors.gray16} !important`,
          '&:focus': {
            backgroundColor: Colors.gray16,
          },
        },
      },
      button: {
        '&:hover, &:focus': {
          backgroundColor: Colors.gray16,
        },
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: Colors.white,
      },
    },
    MuiTableRow: {
      root: {
        height: 56,
      },
    },
    MuiTooltip: {
      tooltip: {
        color: Colors.white,
        fontSize: '16px',
        backgroundColor: Colors.ghostDark,
      },
      arrow: {
        color: Colors.ghostDark,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: '1px solid rgba(224, 224, 224, .5)',
      },
      head: {
        fontSize: '0.95rem',
      },
      body: {
        fontSize: '0.95rem',
      },
    },
  },
};
