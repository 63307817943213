import React from 'react';
import styled from 'styled-components';
import Separator from 'shared/react/components/basic/Separator';
import MenuItemV3 from './MenuItemV3';
import { LeftChevronIcon } from 'shared/react/images/ChevronIcon';

type Props = {
  currentLabel: string | null;
  setCurrentKey: (key: null) => void;
  className?: string;
};

const BackMenuItem = ({ currentLabel, setCurrentKey, className }: Props) => {
  if (!currentLabel) {
    return null;
  }

  return (
    <>
      <MenuItemV3
        className={className}
        icon={<LeftChevronIcon className={undefined} />}
        onClick={() => setCurrentKey(null)}
      >
        {currentLabel}
      </MenuItemV3>
      <CustomSeparator />
    </>
  );
};

const CustomSeparator = styled(Separator)`
  margin: 8px 0px;
`;

export default BackMenuItem;
