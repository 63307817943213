import Modal from 'app/src/basic_components/Modal';
import XIcon from 'app/src/images/dashboard_v2/XIcon';
import React from 'react';
import styled from 'styled-components';

type Props = {
  open: boolean;
  onClose: () => void;
  canCloseModal?: boolean;
  showCloseIcon?: boolean;
  className?: string;
  children: React.ReactNode;
};

const StandardModalV2 = ({
  children,
  onClose,
  open,
  canCloseModal = true,
  className,
  showCloseIcon = true,
}: Props) => {
  const handleCloseModalClick = () => {
    if (!canCloseModal) {
      return;
    }

    onClose();
  };

  return (
    <StyledModal open={open} canCloseModal={canCloseModal} onClose={onClose} className={undefined}>
      <LayoutRoot className={className}>
        {showCloseIcon && (
          <CloseModal onClick={handleCloseModalClick}>
            <XIcon />
          </CloseModal>
        )}
        {children}
      </LayoutRoot>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  border-radius: 8px;
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    top: 0;
    left: 0;
    transform: inherit;
    height: 100%;
  }
`;

const LayoutRoot = styled.div`
  position: relative;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 100vw;
  }
`;

const CloseModal = styled.div`
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 2;
`;

export default StandardModalV2;
