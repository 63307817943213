import styled from 'styled-components';
import BasicButton from './BasicButton';

const LightButton = styled(BasicButton)`
  background: ${({ theme }) => theme.colors.primaryLight};
  color: ${({ theme }) => theme.colors.primary};
  border: none;

  &:hover {
    border: none;
    background: ${({ theme }) => theme.colors.primaryLightHover};
  }
`;

export default LightButton;
