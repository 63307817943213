import React from 'react';
import styled from 'styled-components';
import { useProjects } from 'app/src/context/ProjectsStore';
import ProjectCardPreview from 'app/src/images/macroEmptyState/ProjectCardPreview';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import SaveAsMacroMenuOption from 'app/src/images/macroEmptyState/SaveAsMacroMenuOption';
import Arrow from 'app/src/images/macroEmptyState/Arrow';
import AddMacroPreview from 'app/src/images/macroEmptyState/AddMacroPreview';
import Gap16HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap16HorizontalFlexWrap';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import { MACROS_MODAL_HEADER_DATA_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import VideoCard from 'app/src/pages/dashboard/pages/videos/components/video_card/VideoCard';
import PreviewStepContainer from 'app/src/pages/project/pages/inbox/macros/PreviewStepContainer';

const CUSTOM_MAX_WIDTH = 'max-width: 1250px';

const ChooseMacro = props => {
  const [{ projects }] = useProjects();
  const macros = projects.filter(project => project.macro && project.stepsCount);

  return (
    <>
      <ModalHeaderContainer>
        <Header data-test-id={MACROS_MODAL_HEADER_DATA_TEST_ID}>
          {macros?.length ? 'Choose from Macro library' : 'Set up your first Macro'}
        </Header>
      </ModalHeaderContainer>
      <CardsContainer isMacros={!!macros?.length}>
        {!macros?.length && (
          <EmptyState>
            <TopContainer>
              <FirstStepContainer>
                <PreviewStepContainer
                  headerText="1. Save your first Macro"
                  descriptionText={`Click on a Tolstoy's menu and pick Save as macro`}
                />
                <StyledProjectCardPreview />
              </FirstStepContainer>
              <SecondStepContainer>
                <PreviewStepContainer
                  headerText="2. Re-use Macros"
                  descriptionText="Click the macro tile button on your dashboard to add one from the library"
                />
              </SecondStepContainer>
            </TopContainer>
            <BottomContainer>
              <BottomLeft>
                <StyledArrow />
                <StyledSaveAsMacroMenuOption />
              </BottomLeft>
              <BottomRight>
                <StyledAddMacroPreview />
              </BottomRight>
            </BottomContainer>
          </EmptyState>
        )}
        {macros?.map(macro => (
          <VideoCard key={macro.id} macro={macro} {...props} />
        ))}
      </CardsContainer>
    </>
  );
};

const TopContainer = styled(Gap16HorizontalFlexWrap)`
  align-items: center;
  gap: 64px;
`;

const BottomContainer = styled(TopContainer)`
  justify-content: center;
`;

const BottomRight = styled(HorizontalFlexWrap)`
  align-self: flex-start;
`;

const BottomLeft = styled(Gap8HorizontalFlexWrap)``;

const FirstStepContainer = styled(Gap8HorizontalFlexWrap)``;

const ModalHeaderContainer = styled.div`
  text-align: center;
`;

const Header = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 42px;
  padding-bottom: 20px;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-height: 450px;
  overflow-y: scroll;
  width: 100%;
  justify-content: ${({ isMacros }) => (isMacros ? 'left' : 'space-evenly')};

  @media (${({ theme }) => theme.breakpoints.dashboardTabletMax}) {
    justify-content: left;
  }
`;

const EmptyState = styled.div`
  @media (${({ theme }) => theme.breakpoints.dashboardTabletMax}) {
    flex-direction: column;
  }
`;

const SecondStepContainer = styled(VerticalFlex)``;

const StyledArrow = styled(Arrow)`
  margin-top: 50px;

  @media (${CUSTOM_MAX_WIDTH}) {
    display: none;
  }
`;

const StyledAddMacroPreview = styled(AddMacroPreview)`
  margin-left: 16px;
  @media (${CUSTOM_MAX_WIDTH}) {
    display: none;
  }
`;

const StyledSaveAsMacroMenuOption = styled(SaveAsMacroMenuOption)`
  @media (${CUSTOM_MAX_WIDTH}) {
    display: none;
  }
`;

const StyledProjectCardPreview = styled(ProjectCardPreview)`
  @media (${CUSTOM_MAX_WIDTH}) {
    display: none;
  }
`;

export default ChooseMacro;
