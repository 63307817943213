import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getIsFeed, useProjects } from 'app/src/context/ProjectsStore';
import { navigateToBranding } from 'app/src/utils/navigation.utils';
import { PLAYER_SCROLL_TO_KEY } from 'app/src/pages/account/branding/branding.constants';
import EditorGroup from '../shared/EditorGroup';
import GoToBranding from './GoToBranding';

const BrandingEditorGroup = () => {
  const history = useHistory();
  const { projectId } = useParams();
  const [{ project }] = useProjects({ projectId });
  const isFeed = getIsFeed(project);

  return (
    <EditorGroup
      editors={[
        {
          key: 'logo',
          component: (
            <GoToBranding
              text="Logo"
              navigateTo={() => navigateToBranding(history, projectId, 'logo')}
            />
          ),
        },
        {
          key: 'font',
          component: (
            <GoToBranding
              text="Font"
              navigateTo={() => navigateToBranding(history, projectId, 'font')}
            />
          ),
        },
        {
          key: 'buttons',
          component: (
            <GoToBranding
              text="Buttons"
              navigateTo={() =>
                navigateToBranding(history, projectId, isFeed ? PLAYER_SCROLL_TO_KEY : 'buttons')
              }
            />
          ),
        },
      ]}
    />
  );
};

export default BrandingEditorGroup;
