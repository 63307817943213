import React from 'react';
import styled from 'styled-components';
import AtomButton from '../basic/Button';
import Sizes from '../../theme/Sizes';
import ColorModes from '../../theme/ColorModes';
import Theme from '../../theme/Theme';
import Types from '../../theme/Types';
import BorderRadiusTypes from '../../theme/BorderRadiusTypes';
import BorderColorTypes from '../../theme/BorderColorTypes';
import ButtonText from './ButtonText';
import LoadingIndicator from '../basic/LoadingIndicator';

function Button({
  children,
  size,
  showLeftIcon,
  showRightIcon,
  leftIcon,
  rightIcon,
  type,
  fullWidth,
  colorMode,
  disabled,
  disableColorStateChanges,
  letterSpacing,
  height,
  width,
  loading,
  borderRadiusType,
  borderColorType,
  padding,
  transparentBackground,
  onClick,
  textWidth,
  justifyContent,
  dataType,
  dataTestId,
  ...props
}) {
  function getStateBackgroundColor(colorGroup) {
    if (!colorGroup) {
      return null;
    }

    return getColorModeGroup(colorGroup).background;
  }

  function getTheme() {
    const themeType = type || Types.Primary;

    return Theme[themeType];
  }

  function getBackgroundColor() {
    return getStateBackgroundColor(getTheme()) || Theme[Types.Primary].main;
  }

  function getImageColor() {
    const colorGroup = getTheme();
    if (!colorGroup) {
      return null;
    }

    return getColorModeGroup(colorGroup).arrow;
  }

  function getColorModeGroup(colorGroup) {
    if (disabled) {
      return colorGroup.main;
    }
    switch (colorMode) {
      default:
      case ColorModes.Main:
        return colorGroup.main;
      case ColorModes.Dark:
        return colorGroup.dark;
      case ColorModes.Light:
        return colorGroup.light;
    }
  }

  function getBorderColor(state) {
    const colorGroup = getTheme();
    if (!colorGroup) {
      return null;
    }

    let border = getColorModeGroup(colorGroup).border;
    if (!border) {
      return null;
    }
    let borderElement = border[state];
    return (borderColorType || BorderColorTypes.FullyColored)(borderElement);
  }

  function getMinWidth() {
    if (fullWidth) {
      return '100%';
    }
    const ghost = type === Types.Ghost;
    switch (size) {
      default:
      case Sizes.Regular:
        return children ? '84px' : ghost ? '39px' : '40px';
      case Sizes.Small:
        return children ? '59px' : ghost ? '30px' : '31px';
      case Sizes.Large:
        return children ? '106px' : ghost ? '47px' : '48px';
      case Sizes.ExtraLarge:
        return children ? '129px' : ghost ? '55px' : '56px';
      case Sizes.FitContent:
        return 'fit-content';
    }
  }

  function getHeight() {
    const ghost = type === Types.Ghost;
    switch (size) {
      default:
      case Sizes.Regular:
        return children ? '40px' : ghost ? '39px' : '40px';
      case Sizes.Small:
        return children ? '28px' : ghost ? '27px' : '28px';
      case Sizes.Large:
        return children ? '48px' : ghost ? '47px' : '48px';
      case Sizes.ExtraLarge:
        return children ? '56px' : ghost ? '55px' : '56px';
      case Sizes.FitContent:
        return 'fit-content';
    }
  }

  function getBorderRadius() {
    let borderRadius;

    switch (size) {
      default:
      case Sizes.Regular:
        borderRadius = '20px';
        break;
      case Sizes.Large:
        borderRadius = '24px';
        break;
      case Sizes.Small:
        borderRadius = '65px';
        break;
      case Sizes.ExtraLarge:
        borderRadius = '32px';
        break;
    }

    return (borderRadiusType || BorderRadiusTypes.FullyCornered)(borderRadius);
  }

  function getImageSize() {
    switch (size) {
      default:
      case Sizes.Regular:
        return '14px';
      case Sizes.Large:
        return '16px';
      case Sizes.ExtraLarge:
        return '20px';
    }
  }

  function getArrowImage(flip) {
    if (leftIcon && flip) {
      return leftIcon;
    }
    if (rightIcon && !flip) {
      return rightIcon;
    }
    return (
      <svg
        width={getImageSize()}
        height={getImageSize()}
        viewBox="0 0 13 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Path
          arrowColor={getImageColor()?.normal}
          d={
            flip
              ? 'M5.125 0.9375C4.91406 0.703125 4.5625 0.703125 4.35156 0.9375L1.16406 4.125C0.929688 4.33594 0.929688 4.6875 1.16406 4.89844L4.35156 8.08594C4.5625 8.32031 4.91406 8.32031 5.125 8.08594C5.35938 7.875 5.35938 7.52344 5.125 7.3125L2.89844 5.0625H12.4375C12.7422 5.0625 13 4.82812 13 4.5C13 4.19531 12.7422 3.9375 12.4375 3.9375H2.89844L5.125 1.71094C5.24219 1.61719 5.3125 1.47656 5.3125 1.3125C5.3125 1.17188 5.24219 1.03125 5.125 0.9375Z'
              : 'M6.938.164c-.235-.21-.586-.21-.797.023a.56.56 0 00.023.797l3.117 2.977H1.312a.542.542 0 00-.562.562c0 .305.234.563.563.563H9.28L6.164 8.039a.56.56 0 00-.023.797c.21.234.562.234.796.023l4.125-3.937a.549.549 0 00.165-.399.565.565 0 00-.165-.421L6.939.164z'
          }
        />
      </svg>
    );
  }

  let backgroundColor = transparentBackground ? 'transparent' : getBackgroundColor()?.normal;
  let hoverBackgroundColor = transparentBackground ? 'transparent' : getBackgroundColor()?.hover;
  let activeBackgroundColor = transparentBackground ? 'transparent' : getBackgroundColor()?.active;
  return (
    <LayoutRoot
      fullWidth={fullWidth}
      hoverArrowColor={getImageColor()?.hover}
      activeArrowColor={getImageColor()?.active}
      disabled={disabled}
      {...props}
    >
      <StyledAtomButton
        disableColorStateChanges={disableColorStateChanges}
        disabled={disabled || loading}
        onClick={onClick}
        backgroundColor={backgroundColor}
        hoverBackgroundColor={disableColorStateChanges ? backgroundColor : hoverBackgroundColor}
        activeBackgroundColor={disableColorStateChanges ? backgroundColor : activeBackgroundColor}
        borderColor={getBorderColor('normal')}
        hoverBorderColor={!disableColorStateChanges && getBorderColor('hover')}
        activeBorderColor={!disableColorStateChanges && getBorderColor('active')}
        width={width || getMinWidth()}
        height={height || getHeight()}
        borderRadius={getBorderRadius()}
        padding={padding}
        leftIcon={showLeftIcon && getArrowImage(true)}
        rightIcon={showRightIcon && getArrowImage(false)}
        justifyContent={justifyContent}
        dataType={dataType}
        dataTestId={dataTestId}
      >
        {children && (
          <ButtonText
            type={type}
            colorMode={colorMode}
            disabled={disabled}
            size={size}
            letterSpacing={letterSpacing}
            width={textWidth}
          >
            {children}
          </ButtonText>
        )}
      </StyledAtomButton>

      {loading && <StyledCircularProgress size={20} />}
    </LayoutRoot>
  );
}

export default Button;

const Path = styled.path`
  fill: ${props => props.arrowColor};
`;

const LayoutRoot = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  width: ${props => (props.fullWidth ? '100%' : 'fit-content')};
  pointer-events: ${props => (props.disabled ? 'none' : '')};

  &:hover ${Path} {
    fill: ${props => props.hoverArrowColor};
  }

  &:focus,
  &:active ${Path} {
    fill: ${props => props.activeArrowColor};
  }
`;

const StyledCircularProgress = styled(LoadingIndicator)`
  grid-row: 1;
  grid-column: 1;
  z-index: 2;
`;

const StyledAtomButton = styled(AtomButton)`
  cursor: ${props => (props.disableColorStateChanges ? 'default' : 'pointer')};
`;
