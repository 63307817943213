import { uploadToS3 } from './S3Upload';
import { MAX_IMAGE_SIZE } from 'app/src/constants/account.constants';
import Utils from 'app/src/utils';
import SharedUtils from 'shared/react/utils/utils';
import { v4 as uuidv4 } from 'uuid';

export const LOGO_MAX_WIDTH = 1536;
export const LOGO_MAX_HEIGHT = 1536;

export const verifyImageDimensions = async ({ width, height, file }) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = function () {
      const img = new Image();

      img.onload = function () {
        resolve(img.width <= width && img.height <= height);
      };

      img.onerror = reject;

      img.src = fileReader.result;
    };

    fileReader.readAsDataURL(file);
  });
};

export const uploadLogo = async ({
  file,
  key,
  shouldInvalidate = false,
  type = 'assets',
  keyPrefix = 'public/',
}) => {
  try {
    await uploadToS3(type, `${keyPrefix}${key}`, file, null, shouldInvalidate);
    return key;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const uploadLogoFile = async (file, name, setErrorMessage, appKey) => {
  if (!file) {
    return;
  }

  const validDimensions = await verifyImageDimensions({
    width: LOGO_MAX_WIDTH,
    height: LOGO_MAX_HEIGHT,
    file,
  });

  if (file.size > MAX_IMAGE_SIZE) {
    const formattedSize = SharedUtils.formatBytes(MAX_IMAGE_SIZE);
    setErrorMessage(`Please upload an image smaller than ${formattedSize}.`);
    return;
  }

  if (!validDimensions) {
    setErrorMessage(
      `Please upload an image smaller than ${LOGO_MAX_WIDTH}x${LOGO_MAX_HEIGHT} pixels.`
    );
    return;
  }

  appKey = appKey || Utils.getAppKey();

  const logoUrl = await uploadLogo({
    file,
    key: `assets/${appKey}/${name}`,
    shouldInvalidate: true,
  });
  if (logoUrl) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(`${logoUrl}?${Date.now()}`);
      }, 2000);
    });
  }

  setErrorMessage('Failed to upload the logo. Please try again.');
};

export const uploadImageFile = async (file, setErrorMessage) => {
  if (!file) {
    return;
  }

  if (file.size > MAX_IMAGE_SIZE) {
    const formattedSize = SharedUtils.formatBytes(MAX_IMAGE_SIZE);
    setErrorMessage(`Please upload an image smaller than ${formattedSize}.`);
    return;
  }

  const id = uuidv4();
  const prefix = 'responses/';
  const logoUrl = await uploadLogo({
    file,
    key: `${Utils.getOwner()}/${id}/${id}.png`,
    type: 'image',
    keyPrefix: prefix,
  });

  if (logoUrl) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(`${prefix}${logoUrl}?${Date.now()}`);
      }, 2000);
    });
  }

  setErrorMessage('Failed to upload the logo. Please try again.');
  return false;
};
