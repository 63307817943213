import React from 'react';
import ChartTooltipLineText from 'app/src/complex_components/analytics/chart-tooltip/ChartTooltipLineText';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { Line } from './ChartTooltip.types';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';

type Props = {
  bulletColor?: string;
  header: string;
  lines?: Line[];
  value?: string;
};

const ChartTooltipLineGroup = ({ lines = [], bulletColor, header, value }: Props) => {
  return (
    <Gap16VerticalFlex>
      <HeaderContainer>
        <HeaderText $bulletColor={bulletColor}>{header}</HeaderText>
        <TextTiny>{value}</TextTiny>
      </HeaderContainer>
      <LinesContainer>
        {lines.map(({ header, ...rest }) => (
          <ChartTooltipLineText key={header} header={header} {...rest} />
        ))}
      </LinesContainer>
    </Gap16VerticalFlex>
  );
};

const HeaderContainer = styled(Gap8HorizontalFlex)`
  justify-content: space-between;
  padding: 0 8px;

  & > * {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const HeaderText = styled(TextTiny)<{ $bulletColor?: string }>`
  align-items: center;
  display: flex;
  gap: 4px;

  &:before {
    background-color: ${({ $bulletColor, theme }) => $bulletColor || theme.colors.white};
    border-radius: 50%;
    content: '';
    display: block;
    height: 8px;
    width: 8px;
  }
`;

const LinesContainer = styled(VerticalFlex)`
  gap: 12px;
  padding-left: 12px;

  &:empty {
    display: none;
  }
`;

export default ChartTooltipLineGroup;
