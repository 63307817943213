import React from 'react';
import styled from 'styled-components';

import { TextH6, TextSmall } from 'shared/react/components/basic/text/TextV2';
import { app as AppEntity } from 'app/src/types/entities';
import TolstoyCodeInstallation from './TolstoyCodeInstallation';
import { getIsTolstoyCodeAddedAutomatically } from '../helper';
import BasicAccordionV2 from 'src/basic_components/BasicAccordionV2';
import { useBilling } from 'app/src/hooks/useBillings';
import PublishFree from 'app/src/pages/project/pages/installation/components/free/PublishFree';
import { useLocation } from 'react-router-dom';

type Props = {
  app: AppEntity;
  startExpanded?: boolean;
};

const TolstoyCodeInstallationStep: React.FC<Props> = ({ app, startExpanded = false }) => {
  const { hidePublishContent } = useBilling();
  const integration = app?.app;
  const isCodeAddedAutomatically = getIsTolstoyCodeAddedAutomatically(integration);
  const { hash } = useLocation();

  if (hidePublishContent) {
    return <PublishFree />;
  }

  return (
    <BasicAccordionV2
      startExpanded={startExpanded || hash === '#code'}
      header={
        <>
          <LeftHeaderTitle>Step 1</LeftHeaderTitle>
          <RightHeaderTitle>Enable Tolstoy on your store</RightHeaderTitle>
          {isCodeAddedAutomatically && <TextSmallGrey>(Automatically Applied)</TextSmallGrey>}
        </>
      }
    >
      <TolstoyCodeInstallation app={app} />
    </BasicAccordionV2>
  );
};

const LeftHeaderTitle = styled(TextH6)`
  padding-right: 8px;
  color: ${({ theme }) => theme.colors.neutralDarker};
`;

const RightHeaderTitle = styled(TextH6)`
  padding: 0 8px;
  border-left: ${({ theme }) => `1px solid ${theme.colors.ghostLight}`};
`;

const TextSmallGrey = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.neutralGray};
`;

export default TolstoyCodeInstallationStep;
