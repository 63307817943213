import React from 'react';
import Utils from '../../utils';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import { TextBody } from 'shared/react/components/complex/Text';
import ComponentWithLoader from '../ComponentWithLoader';

const BillingPackageUsageCurrent = ({ showTotal, currentValue, total }) => {
  const isLoading = currentValue === null;

  return (
    <StyledBillingPackageUsageCurrent>
      <ComponentWithLoader isLoading={isLoading}>
        <span>{Utils.addCommasToNumber(currentValue)}</span>
      </ComponentWithLoader>
      {showTotal && (
        <>
          /{Utils.addCommasToNumber(total)}
          {!total && <CircularProgress size={24} />}
        </>
      )}
    </StyledBillingPackageUsageCurrent>
  );
};

export default BillingPackageUsageCurrent;

const StyledBillingPackageUsageCurrent = styled(TextBody)`
  font-weight: 800;
  font-size: 56px;
  line-height: 64px;
  color: ${({ theme }) => theme.colors.gray4};
  display: flex;
  align-items: center;

  & > span {
    color: ${({ theme }) => theme.colors.black};
  }

  &:first-child {
    height: 64px;
  }
`;
