import React from 'react';
import { useTheme } from 'styled-components';

function LinkIcon({ fill, width, ...props }) {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '20'}
      height="16"
      fill="none"
      viewBox="0 0 20 16"
      {...props}
    >
      <path
        fill={fill || theme.colors.ghostDark}
        d="M18.688 1.313A4.405 4.405 0 0015.563 0c-1.126 0-2.25.438-3.126 1.313l-1.343 1.343c.469.25.906.563 1.312.938.094.094.188.219.25.312l1.188-1.187C14.313 2.28 14.906 2 15.563 2c.656 0 1.25.281 1.718.719a2.49 2.49 0 010 3.437l-4.156 4.157a2.49 2.49 0 01-3.438 0c-.437-.47-.718-1.063-.718-1.72 0-.655.25-1.25.687-1.718l.156-.094a1.388 1.388 0 00-.25-.343 1.348 1.348 0 00-1-.438c-.374 0-.718.156-1 .406-.937 1.688-.718 3.875.72 5.313.874.875 2 1.281 3.124 1.281 1.125 0 2.25-.406 3.125-1.281l4.156-4.156C19.595 6.655 20 5.5 19.97 4.313c0-1.094-.438-2.157-1.282-3zM7.313 12.124l-1.188 1.188a2.49 2.49 0 01-3.438 0 2.49 2.49 0 010-3.438l4.157-4.156A2.386 2.386 0 018.562 5c.688 0 1.25.281 1.72.719a2.49 2.49 0 010 3.437l-.126.094c.063.125.156.25.25.344.25.281.625.406 1 .406s.719-.125 1-.375c.938-1.688.719-3.875-.719-5.313A4.405 4.405 0 008.563 3c-1.126 0-2.25.438-3.126 1.313L1.282 8.468C.406 9.344 0 10.469 0 11.594s.406 2.281 1.281 3.125c.875.875 2 1.281 3.125 1.281s2.25-.406 3.125-1.281l1.344-1.344a6.307 6.307 0 01-1.313-.938 2.184 2.184 0 01-.25-.312z"
      ></path>
    </svg>
  );
}

export default LinkIcon;
