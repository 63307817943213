import React from 'react';
import styled from 'styled-components';
import ToggleSetting from 'app/src/complex_components/ToggleSetting';
import { Gap16HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextH5 } from 'shared/react/components/complex/Text';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { useApps } from 'app/src/context/AppsStore';
import LightButton from 'shared/react/components/basic/button/button/LightButton';

const IntegrationNotifyToggle = ({ appName, icon, enabled, projectId, appsWithActions }) => {
  const [, { setNotifyAppInProject }] = useApps();
  const action = appsWithActions[appName];

  return (
    <LayoutRoot>
      <IntegrationNotifyText>
        {!!icon && <IconContainer>{icon}</IconContainer>}
        <AppTitle>{appName}</AppTitle>
        {!!action && <ActionButton onClick={action.onClick}>{action.text}</ActionButton>}
      </IntegrationNotifyText>
      <ToggleSetting
        enabled={enabled}
        onEnabledChange={() => setNotifyAppInProject(appName, projectId, !enabled)}
      />
    </LayoutRoot>
  );
};

export default IntegrationNotifyToggle;

const IntegrationNotifyText = styled(Gap16HorizontalFlex)`
  align-items: center;

  & div:first-letter {
    text-transform: uppercase;
  }
`;

const LayoutRoot = styled(Gap16HorizontalFlex)`
  align-items: center;
  justify-content: space-between;
`;

const AppTitle = styled(TextH5)`
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
`;

const IconContainer = styled(VerticalFlex)`
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  padding: 12px;
  gap: 10px;
  border: 1px solid ${({ theme }) => theme.colors.ghostLight};
  border-radius: 10px;
`;

const ActionButton = styled(LightButton)`
  padding: 6px 12px;
`;
