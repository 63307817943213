import React from 'react';
import ComponentWithLoader from 'src/complex_components/ComponentWithLoader';

import GhostsEmptyState from 'src/complex_components/GhostsEmptyState';

type Props = {
  clearSearch: () => void;
  isLoading: boolean;
};

const ProductsTableEmptyState = ({ clearSearch, isLoading }: Props) => {
  return (
    <ComponentWithLoader isLoading={isLoading}>
      <GhostsEmptyState
        header="No products found."
        subheader="Please try searching for a different product."
        onClick={clearSearch}
        buttonContent="Clear search"
      />
    </ComponentWithLoader>
  );
};

export default ProductsTableEmptyState;
