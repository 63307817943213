import React from 'react';
import FeedCartSummary from 'shared/react/components/complex/feed/feed_cart/FeedCartSummary';
import FeedProduct from 'shared/react/components/complex/feed/feed_products/FeedProduct';
import FeedMultipleProducts from 'shared/react/components/complex/feed/feed_products/feed_multiple_products/FeedMultipleProducts';
import { OPEN_REBUY_CART_MESSAGE } from 'shared/react/constants/messages.constants';
import { CART_MODAL_KEY } from 'shared/react/constants/modals.constants';
import SharedUtils from 'shared/react/utils/utils';
import styled from 'styled-components';
import useFeedModal from '../../context/hooks/useFeedModal';
import useFeedState from '../../context/hooks/useFeedState';
import useProjectConfig from '../../context/hooks/useProjectConfig';
import useIsFenty from 'shared/react/hooks/useIsFenty';

const FeedProducts = ({
  isFullWidth,
  isMultipleProducts,
  product,
  currentStepKey,
  isHorizontal,
  landingPage,
  handleBackButton,
  priorityProductId,
  isMultiProductDisabled = false,
}) => {
  const [{ currentModal }, { pushModal, popModal }] = useFeedModal();
  const [{ isAppPreview, isShoppable }] = useProjectConfig();
  const [{ isRebuyAppInstalled }] = useFeedState();
  const isFenty = useIsFenty();

  const handleClickCart = () => {
    if (isRebuyAppInstalled) {
      SharedUtils.postMessageToWindow({ eventName: OPEN_REBUY_CART_MESSAGE });
      return;
    }

    if (currentModal?.key === CART_MODAL_KEY) {
      return popModal();
    }

    pushModal({
      key: CART_MODAL_KEY,
      props: {
        currentStepKey: currentStepKey,
      },
    });
  };

  const getContent = () => {
    if ((isMultipleProducts || isFenty) && !isMultiProductDisabled) {
      return (
        <FeedMultipleProducts
          currentStepKey={currentStepKey}
          isFullWidth={isFullWidth}
          isHorizontal={isHorizontal}
          landingPage={landingPage}
          handleBackButton={handleBackButton}
          priorityProductId={priorityProductId}
        />
      );
    }

    return (
      <FeedProduct
        product={product}
        currentStepKey={currentStepKey}
        disableZoomIn={isAppPreview || isHorizontal || landingPage}
        isFullWidth={isFullWidth}
        isHorizontal={isHorizontal}
        handleBackButton={handleBackButton}
      />
    );
  };

  if (!isShoppable) {
    return null;
  }

  return (
    <LayoutRoot
      isFullWidth={isFullWidth}
      role="group"
      aria-label="Feed products"
      id={`feedProducts-${currentStepKey}`}
    >
      {getContent()}
      <FeedCartSummary onClickViewCart={handleClickCart} currentModal={currentModal} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  max-width: ${({ isFullWidth }) => (isFullWidth ? '100%' : '25vw')};
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
  overflow: hidden;
`;

export default FeedProducts;
