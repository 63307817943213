import React, { FC } from 'react';
import styled from 'styled-components';
import { project as Project } from 'app/src/types/entities';
import { useApps } from 'app/src/context/AppsStore';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import AddToThemeStep from 'app/src/pages/project/pages/installation/components/stories_and_carousel/installation-steps/AddToThemeStep';
import AddPublishIdStep from 'app/src/pages/project/pages/installation/components/stories_and_carousel/installation-steps/AddPublishIdStep';
import ManualInstallationStep from 'app/src/pages/project/pages/installation/components/stories_and_carousel/installation-steps/ManualInstallationStep';
import InstallSetLiveStep from 'app/src/pages/project/pages/installation/components/shared/InstallSetLiveStep';
import { EmbedWidgetsInstall, EmbedWidgetsShopifyInstall } from 'app/src/types/install';

type ProjectInstallationStepsProps = {
  project: Project;
  isDisabled: boolean;
  showManualInstallCode: boolean;
};

const ProjectInstallationSteps: FC<ProjectInstallationStepsProps> = ({
  project,
  showManualInstallCode,
  isDisabled,
}) => {
  const [{ shopify: isShopify }] = useApps();

  const shouldShowManualInstallCode = showManualInstallCode || !isShopify;

  if (shouldShowManualInstallCode) {
    return (
      <LayoutRoot>
        <ManualInstallationStep project={project} isShopify={isShopify} isDisabled={isDisabled} />
        <InstallSetLiveStep
          isDisabled={isDisabled}
          project={project}
          stepNumber={2}
          startExpanded={project.installStep === EmbedWidgetsInstall.addCode}
        />
      </LayoutRoot>
    );
  }

  return (
    <LayoutRoot>
      <AddToThemeStep project={project} isDisabled={isDisabled} />
      <AddPublishIdStep project={project} isDisabled={isDisabled} />
      <InstallSetLiveStep
        isDisabled={isDisabled}
        project={project}
        startExpanded={project.installStep === EmbedWidgetsShopifyInstall.enterPublishId}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)``;

export default ProjectInstallationSteps;
