import React from 'react';
import ChartTooltip from 'app/src/complex_components/analytics/chart-tooltip/ChartTooltip';
import Utils from 'app/src/utils';
import { getShortMonthAndDate } from 'app/src/utils/date.utils';
import {
  ProductClicksOvertimeRecord,
  BaseProductClickMetrics,
} from 'app/src/services/cube/types/getProductClicksOvertime.types';
import { useTheme } from 'styled-components';

type Payload = {
  payload: ProductClicksOvertimeRecord;
};

type Props = {
  active: boolean;
  payload: [Payload];
  label: string;
};

const getPlayerTypeHeader = (playerType: string) => {
  if (playerType.toLowerCase() === 'tv') {
    return 'TV';
  }

  return playerType
    .replace(/[^\w\s]+/g, ' ')
    .split(' ')
    .map(word => Utils.upperCaseFirstChar(word))
    .join(' ');
};

const getProductCTRSummary = (metrics: BaseProductClickMetrics) => {
  const formattedProductCTR = Utils.formatNumber({
    value: metrics.productCTR,
    isPercentage: true,
  });
  const formattedNumOfProductClicks = Utils.formatNumber({
    value: metrics.numOfProductClicks,
  });

  return `${formattedProductCTR} (${formattedNumOfProductClicks})`;
};

const AnalyticsOverviewProductCTRTooltip = ({ active, payload, label }: Props) => {
  const theme = useTheme();
  if (!active || !payload?.length) {
    return null;
  }

  const [productClicksData] = payload;
  const { payload: productClicks } = productClicksData;

  const metricsByPlayerType = Object.values(productClicks.metricsByPlayerType)
    .sort((a, b) => b.productCTR - a.productCTR)
    .filter(
      playerTypeMetrics => playerTypeMetrics.playerType && playerTypeMetrics.numOfProductClicks > 0
    );

  const lineGroups = [
    {
      key: 'overall',
      header: 'Overall',
      bulletColor: theme.colors.successDark,
      value: getProductCTRSummary(productClicks),
      lines: metricsByPlayerType.map(playerTypeMetrics => ({
        key: playerTypeMetrics.playerType,
        header: getPlayerTypeHeader(playerTypeMetrics.playerType),
        value: getProductCTRSummary(playerTypeMetrics),
      })),
    },
  ];

  return <ChartTooltip label={getShortMonthAndDate(label)} lineGroups={lineGroups} />;
};

export default AnalyticsOverviewProductCTRTooltip;
