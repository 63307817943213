import React from 'react';
import styled from 'styled-components';
import BadgeContainer from 'app/src/complex_components/badge/BadgeContainer';
import { APPROVE_IMPORT_DATA_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import { TextSmall } from 'shared/react/components/complex/Text';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import BasicButton from 'shared/react/components/basic/button/button/BasicButton';
import { useImportVideos } from 'app/src/context/ui_store/ImportVideosStore';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

const ImportMultipleBadge = ({ onImportClick, isLoading }) => {
  const [{ selectedVideosToImport }, { resetSelectedVideosToImport }] = useImportVideos();

  const numSelected = selectedVideosToImport.length;

  const onClearSelection = () => {
    resetSelectedVideosToImport();
  };

  if (!numSelected) {
    return null;
  }

  const getContent = () => {
    return (
      <Content>
        <Text>{numSelected} Selected</Text>
        <ButtonsContainer>
          {numSelected && (
            <CancelButton
              disabled={!!isLoading}
              transparentBackground={true}
              onClick={onClearSelection}
              borderColorType={() => 'transparent'}
            >
              Cancel
            </CancelButton>
          )}
          <Button
            disabled={!!isLoading}
            onClick={onImportClick}
            loading={isLoading}
            data-test-id={APPROVE_IMPORT_DATA_TEST_ID}
          >
            Import
          </Button>
        </ButtonsContainer>
      </Content>
    );
  };

  return (
    <LayoutRoot>
      <BadgeContainer>{getContent()}</BadgeContainer>
    </LayoutRoot>
  );
};

export default ImportMultipleBadge;

const LayoutRoot = styled.div`
  position: absolute;
  justify-self: center;
  z-index: 100;
  bottom: 12px;
  width: 450px;
  left: 50%;
  transform: translateX(-50%);

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    width: 100%;
    left: unset;
    transform: unset;
  }
`;

const Content = styled(HorizontalFlex)`
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    gap: 8px;
  }
`;

const Text = styled(TextSmall)`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.white};
`;

const ButtonsContainer = styled(Gap8HorizontalFlexWrap)`
  align-items: center;
`;

const Button = styled(BasicButton)`
  border-radius: 12px;
  width: fit-content;
  height: 24px;
`;

const CancelButton = styled(Button)`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};

  &:hover {
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
  }
`;
