import React from 'react';
import AddProductsModal from 'app/src/complex_components/global_modal/video/add_products_modal/AddProductsModal';
import { SelectedProductsProvider } from 'app/src/complex_components/global_modal/video/add_products_modal/SelectedProductsContext';

type Props = {
  closeModal: () => void;
};
const AddProductsModalWrapper = ({ closeModal }: Props) => {
  return (
    <SelectedProductsProvider>
      <AddProductsModal closeModal={closeModal} />
    </SelectedProductsProvider>
  );
};

export default AddProductsModalWrapper;
