import React from 'react';

const UserIcon = () => (
  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 8c1.406 0 2.719-.75 3.438-2a3.983 3.983 0 0 0 0-4C9.717.781 8.405 0 7 0 5.562 0 4.25.781 3.531 2a3.978 3.978 0 0 0 0 4C4.25 7.25 5.562 8 7 8ZM5.562 9.5C2.47 9.5 0 12 0 15.094c0 .5.406.906.906.906h12.156c.5 0 .938-.406.938-.906C14 12 11.5 9.5 8.406 9.5H5.562Z"
      fill="#B3B6BC"
    />
  </svg>
);

export default UserIcon;
