import React from 'react';
import { useDashboard } from 'app/src/context/ui_store/DashboardStore';
import styled from 'styled-components';
import AllFilterIcon from 'app/src/images/dashboard_v2/tolstoy_types/AllFilterIcon';
import PublishMethodFilterMenuItem from './PublishMethodFilterMenuItem';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { PUBLISH_METHODS } from 'shared/react/constants/tolstoy.constants';

const PublishMethodFilterMenuItems = ({ onClose }) => {
  const [{ selectedPublishMethod }, { setSelectedPublishMethod }] = useDashboard();

  const onClick = value => {
    setSelectedPublishMethod(value);
    onClose();
  };

  return (
    <>
      <PublishMethodFilterMenuItem
        icon={
          <FilterIcon>
            <AllFilterIcon />
          </FilterIcon>
        }
        title="All"
        isSelected={!selectedPublishMethod}
        onClick={() => onClick(null)}
      />
      {Object.keys(PUBLISH_METHODS).map(key => (
        <PublishMethodFilterMenuItem
          key={key}
          publishMethod={key}
          isSelected={selectedPublishMethod === key}
          onClick={() => onClick(key)}
        />
      ))}
    </>
  );
};

const FilterIcon = styled(HorizontalFlexCentered)`
  width: 24px;
  height: 24px;
  border: 1px solid ${({ theme }) => theme.colors.gray35};
  border-radius: 100%;

  & svg {
    width: 12px;
    height: 12px;
  }

  & path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export default PublishMethodFilterMenuItems;
