import React from 'react';
import PartsPhase from './PartsPhase';
import PublishPhase from './PublishPhase';
import ReviewRules from './review_rules/ReviewRules';
import StorePhase from './StorePhase';

const ModalContent = props => {
  const { activeIndex } = props;

  switch (activeIndex) {
    case 1:
      return <StorePhase {...props} />;
    case 2:
      return <PartsPhase {...props} />;
    case 3:
      return <ReviewRules {...props} />;
    case 4:
      return <PublishPhase {...props} />;
    default:
      return null;
  }
};

export default ModalContent;
