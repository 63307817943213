import React, { useState, FC } from 'react';
import BasicAccordionV2 from 'app/src/basic_components/BasicAccordionV2';
import styled from 'styled-components';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextH6, TextSmall } from 'shared/react/components/basic/text/TextV2';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import { useProjectActions } from 'app/src/context/ProjectsStore';
import { project as Project } from 'app/src/types/entities';
import { PrimaryButton } from 'shared/react/components/basic/button-v2/BasicButton';
import StraightArrowRightIcon from 'app/src/images/creation_method_icons/StraightArrowRightIcon';

export type InstallStepBaseProps = {
  isDisabled: boolean;
  stepNumber?: number;
  stepHeader: JSX.Element | string;
  headerOptionalText?: string;
  startExpanded?: boolean;
  currentStepName?: string;
  project?: Project;
};

const InstallStepBase: FC<InstallStepBaseProps> = ({
  isDisabled,
  stepNumber,
  stepHeader,
  headerOptionalText,
  startExpanded,
  currentStepName,
  project,
  children,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const { updateProject } = useProjectActions();
  const onCloseAccordion = () => {
    setIsAccordionOpen(true);
    setIsAccordionOpen(false);
  };

  const onCompletedClick = async () => {
    setIsLoading(true);
    await updateProject({ ...project, installStep: currentStepName });
    setIsLoading(false);
    onCloseAccordion();
  };

  return (
    <BasicAccordionV2
      isDisabled={isDisabled}
      startExpanded={startExpanded || isAccordionOpen}
      header={
        <>
          {!!stepNumber && <LeftHeaderTitle>Step {stepNumber}</LeftHeaderTitle>}
          <RightHeaderTitle>{stepHeader}</RightHeaderTitle>
          {!!headerOptionalText && <OptionalText>{headerOptionalText}</OptionalText>}
        </>
      }
    >
      <Content>
        {children}
        {!!currentStepName && (
          <ButtonContainer>
            <PrimaryButton onClick={onCompletedClick} isLoading={isLoading}>
              Next
              <StraightArrowRightIcon />
            </PrimaryButton>
          </ButtonContainer>
        )}
      </Content>
    </BasicAccordionV2>
  );
};

const LeftHeaderTitle = styled(TextH6)`
  padding-right: 8px;
  color: ${({ theme }) => theme.colors.neutralDarker};
  border-right: ${({ theme }) => `1px solid ${theme.colors.ghostLight}`};
`;

const RightHeaderTitle = styled(TextH6)`
  padding: 0 8px;
`;

const OptionalText = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.neutralDark};
`;

const Content = styled(Gap16VerticalFlex)``;

const ButtonContainer = styled(HorizontalFlex)`
  justify-content: flex-end;

  & svg {
    transform: rotate(90deg);
  }
`;

export default InstallStepBase;
