import React from 'react';
import styled from 'styled-components';
import { useModalActions } from 'app/src/context/ui_store/ModalStore';
import { VIDEO_PREVIEW_MODAL_KEY } from 'app/src/constants/modals.constants';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import LineClampWrapper from 'shared/react/components/basic/LineClampWrapper';

type Props = {
  vodAssetId: string;
  videoName: string;
};
const AnalyticsOverviewVideoNameCell = ({ vodAssetId, videoName }: Props) => {
  const { setCurrentId, setCurrentModal } = useModalActions();

  const onClick = () => {
    setCurrentId(vodAssetId);
    setCurrentModal(VIDEO_PREVIEW_MODAL_KEY);
  };

  return (
    <LayoutRoot onClick={onClick}>
      <LineClampWrapper>{videoName}</LineClampWrapper>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  line-height: 16px;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export default AnalyticsOverviewVideoNameCell;
