import React from 'react';
import styled from 'styled-components';
import MarkAsReadIcon from 'app/src/images/MarkAsRead';
import MarkAsUnreadIcon from 'app/src/images/MarkAsUnread';
import ArchiveIcon from 'app/src/images/Archive';
import Unarchive from 'app/src/images/Unarchive';
import { track } from 'app/src/helpers/Tracker';
import Tooltip from 'app/src/basic_components/Tooltip';
import SpamIcon from 'app/src/images/SpamIcon';

const ConversationBoxHeaderIcons = ({ id, read, archived, isSpam, onActionClick }) => {
  const conversationAction = (properties, type) => e => {
    e.stopPropagation();
    onActionClick(properties);
    track('Inbox update session properties', {
      type,
      sessionId: id,
    });
  };

  return (
    <StyledConversationBoxHeaderIcons>
      {!isSpam && (
        <Tooltip placement="top" text="Report spam">
          <ConversationBoxHeaderIcon
            onClick={conversationAction({ isSpam: true, archived: false })}
          >
            <SpamIcon fill="#C8C9C9" />
          </ConversationBoxHeaderIcon>
        </Tooltip>
      )}
      {(archived || isSpam) && (
        <Tooltip placement="top" text="Move to inbox">
          <ConversationBoxHeaderIcon
            onClick={conversationAction({ archived: false, isSpam: false })}
          >
            <Unarchive color="#C8C9C9" />
          </ConversationBoxHeaderIcon>
        </Tooltip>
      )}
      {!archived && !isSpam && (
        <Tooltip placement="top" text="Archive">
          <ConversationBoxHeaderIcon
            onClick={conversationAction({ archived: true, isSpam: false })}
          >
            <ArchiveIcon color="#C8C9C9" />
          </ConversationBoxHeaderIcon>
        </Tooltip>
      )}
      {read && (
        <Tooltip placement="top" text="Mark as unread">
          <ConversationBoxHeaderIcon onClick={conversationAction({ read: false })}>
            <MarkAsUnreadIcon />
          </ConversationBoxHeaderIcon>
        </Tooltip>
      )}
      {!read && (
        <Tooltip placement="top" text="Mark as read">
          <ConversationBoxHeaderIcon onClick={conversationAction({ read: true })}>
            <MarkAsReadIcon />
          </ConversationBoxHeaderIcon>
        </Tooltip>
      )}
    </StyledConversationBoxHeaderIcons>
  );
};

export default ConversationBoxHeaderIcons;

const StyledConversationBoxHeaderIcons = styled.div`
  display: flex;
  gap: 16px;
`;

const ConversationBoxHeaderIcon = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: unset;
  cursor: pointer;
  padding: 0;
  position: relative;
  &:after {
    opacity: 0;
    content: '';
    display: block;
    bottom: -8px;
    left: -8px;
    right: -8px;
    top: -8px;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.gray28};
    border-radius: 50%;
    transition-property: opacity;
    transition-duration: 500ms;
  }
  &:hover:after {
    opacity: 1;
  }
`;
