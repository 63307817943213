import React, { useMemo } from 'react';

import { App } from 'src/types/app';
import { useVideos } from 'src/context/VideosStore';
import { UGC_INSTAGRAM_GRAPH } from 'src/constants/intergrations.constants';

import { UgcPageContent } from '../layout';
import UgcApprovedTopBar from './UgcApprovedTopBar';

type Props = {
  selectedApp: App;
  selectedUGCCategory: string;
};

const EMPTY_STATE = {
  title: 'No approved posts',
  subTitle: 'Ask content use permission in the search tab first.',
};

export const UgcApprovedPage = ({ selectedApp, selectedUGCCategory }: Props) => {
  const [{ data }] = useVideos();
  const approvedVideos = useMemo(
    () => data.filter(video => video.uploadType === UGC_INSTAGRAM_GRAPH),
    [data]
  );

  return (
    <>
      <UgcApprovedTopBar />
      <UgcPageContent
        videos={approvedVideos}
        selectedApp={selectedApp}
        selectedUGCCategory={selectedUGCCategory}
        emptyState={EMPTY_STATE}
      />
    </>
  );
};

export default UgcApprovedPage;
