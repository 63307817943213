import React, { useState } from 'react';
import styled from 'styled-components';
import Utils from 'app/src/utils';
import { TextSmall } from 'shared/react/components/complex/Text';
import HorizontalFlex, {
  Gap16HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import LinkIconV2 from 'shared/react/images/LinkIconV2';
import PublishVideoPageContent from './PublishVideoPageContent';
import VideoPagePreview from 'app/src/pages/project/pages/look_and_feel/preview/video_page_preview/VideoPagePreview';

const HELP_TIPS_ARTICLE =
  'https://help.gotolstoy.com/en/articles/7019588-adding-tolstoy-video-page-for-shopify';

const PublishVideoPage = ({ project }) => {
  const [headerId, setHeaderId] = useState('');
  const isFeed = project.feed;

  const onGetHelpClick = article => {
    Utils.openInNewTab(article);
  };

  const getCode = () => {
    const dataHeaderId = headerId ? ` data-header-id='${headerId}'` : ``;
    return `<div id='tolstoy-tv-container' data-publish-id='${project.publishId}'${dataHeaderId} class='tolstoy-video-page' />`;
  };

  return (
    <LayoutRoot>
      <LeftContainer>
        <PublishVideoPageContent
          project={project}
          getCode={getCode}
          onGetHelpClick={onGetHelpClick}
          headerId={headerId}
          setHeaderId={setHeaderId}
        />
      </LeftContainer>
      <RightContainer>
        <VideoPagePreview project={project} size={1.4} />
        {isFeed && HELP_TIPS_ARTICLE && (
          <HelpAndTips onClick={() => onGetHelpClick(HELP_TIPS_ARTICLE)}>
            <HelpAndTipsText>Get help & tips</HelpAndTipsText>
            <LinkIconV2 />
          </HelpAndTips>
        )}
      </RightContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16HorizontalFlex)`
  height: 100%;
  overflow-y: auto;
  padding-right: 12px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    flex-wrap: wrap;
    overflow: auto;
  }
`;

const LeftContainer = styled.div`
  max-height: 400px;
  max-width: 550px;
  width: 100%;
  flex-shrink: 0;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 100%;
    max-width: unset;
  }
`;

const RightContainer = styled(Gap16VerticalFlex)`
  align-items: center;
  width: 100%;
  position: sticky;
  top: 0;
  flex-grow: 0;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    display: none;
  }
`;

const HelpAndTips = styled(HorizontalFlex)`
  gap: 4px;
  cursor: pointer;
`;

const HelpAndTipsText = styled(TextSmall)`
  font-weight: 600;
`;

export default PublishVideoPage;
