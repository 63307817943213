import { publishMethodOptions } from 'app/src/types/entities';

const CAMPAIGN_DEFAULT_PROJECT_TYPES = [
  publishMethodOptions.landingPage,
  publishMethodOptions.email,
  publishMethodOptions.videoCollectionCampaign,
  null, // empty publish method
];

export const DEFAULT_OFFSITE_FILTERS = {
  publishMethod: {
    in: CAMPAIGN_DEFAULT_PROJECT_TYPES,
  },
};
