import React from 'react';
import styled from 'styled-components';
import useProjectConfig from '../../context/hooks/useProjectConfig';
import HorizontalFlexWrap from '../../flex_layouts/HorizontalFlexWrap';

const FeedProductShipping = () => {
  const [{ playerSettings }] = useProjectConfig();

  const {
    productDetailsShippingEnabled: enabled,
    productDetailsShippingText: text,
    productDetailsShippingFontColor: fontColor,
    productDetailsShippingFontWeight: fontWeight,
  } = playerSettings || {};

  if (!enabled) {
    return null;
  }

  return (
    <LayoutRoot fontColor={fontColor} fontWeight={fontWeight}>
      {text}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlexWrap)`
  color: ${({ fontColor, theme }) => fontColor || theme.colors.black};
  font-weight: ${({ fontWeight }) => fontWeight || '400'};
  text-align: left;
  font-size: 14px;
`;

export default FeedProductShipping;
