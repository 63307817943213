import { createActionsHook, createContainer, createHook, createStore } from 'react-sweet-state';
import { sendOpenExtension } from '../../helpers/Extension';

const initialState = {
  addExtensionModalOpen: false,
  extensionInstalledModalOpen: false,
};

const actions = {
  toggleAddExtensionModal:
    isModalOpen =>
    ({ setState }) => {
      setState({ addExtensionModalOpen: isModalOpen });
    },
  toggleExtensionInstallModal:
    isModalOpen =>
    ({ setState }) => {
      setState({ extensionInstalledModalOpen: isModalOpen });
    },
  openModalOrExtension:
    (from, extraData, onNotChrome) =>
    ({ setState }) => {
      if (window.navigator.vendor !== 'Google Inc.') {
        onNotChrome();
        return;
      }

      if (isExtensionInstalled()) {
        sendOpenExtension(from, extraData);
        return;
      }

      setState({ addExtensionModalOpen: true });
    },
};

export const isExtensionInstalled = () => {
  const extension = document.getElementById('tolstoy-extension');
  return !!extension;
};

const ExtensionStore = createStore({ initialState, actions });

export const useExtension = createHook(ExtensionStore);

export const useExtensionActions = createActionsHook(ExtensionStore);

export const ExtensionContainer = createContainer(ExtensionStore);
