import { EVENT_NAMES } from 'widget/src/constants/events.constants.js';
import {
  sendAnalyticsToExternalSource,
  setImpressionByPublishId
} from 'widget/src/common/analytics.js';
import { handleTolstoyStart } from 'widget/src/common/eventsRegistration.js';
import { PLAYER_EVENT_NAMES } from 'player-events';
import { getCurrentPageProductId } from 'widget/src/utils/script.utils.js';
import { getAndSetAnonymousId, getSessionId } from '../../common/cache.js';
import {
  createPlayerEvent,
  sendEvent as createPlayerEventImmediately
} from '../../common/requests.js';
import { uuidv4 } from '../../utils/cache.utils.js';

class Analytics {
  constructor({ config, playerType }) {
    this.config = config;
    this.publishId = config.publishId;
    this.playerType = playerType;
    this.sessionId = getSessionId() || uuidv4();
    this.anonymousId = getAndSetAnonymousId();
    this.currentPageProductId = getCurrentPageProductId();
    this.errors = {};
  }

  getIsMobile() {
    return window.screen.width <= 450 || window.screen.height <= 450;
  }

  getAnalyticsParams(dynamicParams = {}) {
    const { id, appKey, name, googleAnalyticsID } = this.config;
    const pageUrl = window.location.href.split('?')[0];
    const storeUrl = window.Shopify?.shop;
    const errors = this.errors;

    return {
      appKey,
      publishId: this.publishId,
      sessionId: this.sessionId,
      anonymousId: this.anonymousId,
      currentPageProductId: this.currentPageProductId,
      isMobile: this.getIsMobile(),
      pageUrl,
      storeUrl,
      appUrl: storeUrl,
      googleAnalyticsID,
      projectId: id,
      playlist: name,
      timestamp: new Date().toISOString(),
      parentUrl: window.location.href,
      playerType: this.playerType,
      customParams: errors ? JSON.stringify(errors) : undefined, // temporary solution to send errors
      ...dynamicParams
    };
  }

  sendEventToAllSources(params) {
    createPlayerEvent(params);
    sendAnalyticsToExternalSource({ data: { ...params, name: params.eventName } });
  }

  /**
   * Send event to Tolstoy and external sources immediately
   * Always prefer using sendEvent over this method, unless the event must be sent immediately
   * @param {object} dynamicParams - additional params to send, make sure they exist in `tolstoy-serverless/common/validate-event-fields.js`
   * @param {boolean} excludeExternalSources - if true, will not send the event to external sources, like GA
   * @returns {void}
   */
  sendEventImmediately(dynamicParams = {}, excludeExternalSources = false) {
    const params = this.getAnalyticsParams(dynamicParams);
    createPlayerEventImmediately(params);

    if (excludeExternalSources) {
      return;
    }

    sendAnalyticsToExternalSource({ data: { ...params, name: params.eventName } });
  }

  /**
   * Send event to Tolstoy and external sources
   * Always prefer using this over sendEventImmediately
   * @param {object} dynamicParams - additional params to send, make sure they exist in `tolstoy-serverless/common/validate-event-fields.js`
   * @param {boolean} excludeExternalSources - if true, will not send the event to external sources, like GA
   * @returns {void}
   */
  sendEvent(dynamicParams = {}, excludeExternalSources = false) {
    const params = this.getAnalyticsParams(dynamicParams);
    createPlayerEvent(params);

    if (excludeExternalSources) {
      return;
    }

    sendAnalyticsToExternalSource({ data: { ...params, name: params.eventName } });
  }

  handlePageView() {
    setImpressionByPublishId(this.publishId, false);
    this.sendEvent({ eventName: EVENT_NAMES.pageView });
  }

  handleEmbedView() {
    setImpressionByPublishId(this.publishId, true);
    this.sendEvent({ eventName: EVENT_NAMES.embedView });
  }

  handleSessionStart() {
    const params = this.getAnalyticsParams({ eventName: EVENT_NAMES.sessionStart });
    this.sendEventToAllSources(params);
    handleTolstoyStart({ data: { ...params, name: PLAYER_EVENT_NAMES.sessionStart } });
  }

  handleVideoLoaded(dynamicParams = {}) {
    const params = this.getAnalyticsParams({
      ...dynamicParams,
      eventName: EVENT_NAMES.videoLoaded
    });

    this.sendEventToAllSources(params);
  }

  handleVideoWatched(dynamicParams = {}) {
    const params = this.getAnalyticsParams({
      ...dynamicParams,
      eventName: EVENT_NAMES.videoWatched
    });

    this.sendEventToAllSources(params);
  }
}

export default Analytics;
