export const CONFIG_URL_CDN = process.env.VITE_CONFIG_URL_CDN;
export const CONFIG_URL = process.env.VITE_CONFIG_URL;
export const API_BASE_URL = process.env.VITE_API_BASE_URL;
export const API_LB_BASE_URL = process.env.VITE_API_LB_BASE_URL;
export const API_LB_CDN_BASE_URL = process.env.VITE_API_LB_CDN_BASE_URL;
export const GET_RULE_GROUPS_URL = process.env.VITE_GET_RULE_GROUPS_URL;
export const PLAYER_URL = process.env.VITE_PLAYER_URL;
export const WIDGET_DOMAIN = process.env.VITE_WIDGET_DOMAIN;
export const VIDEO_OUTPUT = process.env.VITE_VIDEO_OUTPUT;
export const CREATE_VIDEO_RESOLUTIONS = process.env.VITE_CREATE_VIDEO_RESOLUTIONS;
export const SITE_ACTIVITY_EVENT_URL = process.env.VITE_SITE_ACTIVITY_EVENT_URL;
export const PAGE_VISIT_EVENT_URL = process.env.VITE_PAGE_VISIT_EVENT_URL;
export const NODE_ENV = process.env.MODE;

const ENVS = {
  CONFIG_URL_CDN,
  CONFIG_URL,
  API_BASE_URL,
  API_LB_BASE_URL,
  API_LB_CDN_BASE_URL,
  GET_RULE_GROUPS_URL,
  PLAYER_URL,
  WIDGET_DOMAIN,
  VIDEO_OUTPUT,
  CREATE_VIDEO_RESOLUTIONS,
  SITE_ACTIVITY_EVENT_URL,
  PAGE_VISIT_EVENT_URL,
  NODE_ENV
};

export default ENVS;
