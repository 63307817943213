import React from 'react';
import styled from 'styled-components';

import { TextBodyBold, TextTiny, TextSmall } from 'shared/react/components/basic/text/TextV2';
import FeedButton from '../feed_buttons/FeedButton';
import Gap8VerticalFlex from '../../flex_layouts/Gap8VerticalFlex';
import CircledCheckmarkIcon from 'shared/react/images/feed/CircledCheckmarkIcon';
import VerticalFlex, { VerticalFlexCentered } from '../../flex_layouts/VerticalFlex';
import HorizontalFlex from '../../flex_layouts/HorizontalFlex';
import FilledReportIcon from 'shared/react/images/feed/FilledReportIcon';
import HideIcon from 'shared/react/images/feed/HideIcon';
import Gap16VerticalFlex from '../../flex_layouts/Gap16VerticalFlex';

const ReportConfirmation = ({ onCloseReport }) => {
  return (
    <LayoutRoot>
      <HeaderWrapper>
        <CircledCheckmarkIcon />
        <TextBodyBold>Thanks for letting us know</TextBodyBold>
        <TextTiny>We use these reports to:</TextTiny>
      </HeaderWrapper>

      <ContentWrapper>
        <DescriptionItem>
          <IconWrapper>
            <FilledReportIcon />
          </IconWrapper>
          <TextSmall>
            Understand problems people are having with different types of content on Tolstoy.
          </TextSmall>
        </DescriptionItem>

        <DescriptionItem>
          <IconWrapper>
            <HideIcon />
          </IconWrapper>
          <TextSmall>Show you less of this kind of content in the future.</TextSmall>
        </DescriptionItem>
      </ContentWrapper>

      <Footer>
        <CloseButton onClick={onCloseReport} buttonText="Close" />
      </Footer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  gap: 24px;
  height: 100%;
`;

const HeaderWrapper = styled(VerticalFlexCentered)`
  gap: 8px;
`;

const ContentWrapper = styled(Gap16VerticalFlex)``;

const DescriptionItem = styled(HorizontalFlex)`
  gap: 24px;
  text-align: left;

  & path {
    fill: ${({ theme }) => theme.colors.black};
  }
`;

const Footer = styled(Gap8VerticalFlex)`
  margin-top: auto;
  padding: 24px 8px 8px 8px;
  justify-content: flex-end;
`;

const IconWrapper = styled.div`
  & > svg {
    width: 20px;
    height: 20px;
  }
`;

const CloseButton = styled(FeedButton)`
  background-color: ${({ theme }) => theme.colors.primary};
`;

export default ReportConfirmation;
