import * as React from 'react';
import { useTheme } from 'styled-components';

const QuizIcon = () => {
  const theme = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="37" fill="none" viewBox="0 0 38 37">
      <path
        stroke="url(#paint0_linear)"
        strokeLinecap="round"
        strokeWidth="3"
        d="M12.737 16.71l4.661 4.661a1 1 0 001.414 0L36 4.184"
      ></path>
      <path
        stroke="url(#paint1_linear)"
        strokeLinecap="round"
        strokeWidth="3"
        d="M34.21 18.5v12.526a3.579 3.579 0 01-3.578 3.58H5.579A3.579 3.579 0 012 31.025V5.973a3.579 3.579 0 013.579-3.578h19.684"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="24.368"
          x2="24.368"
          y1="4.184"
          y2="22.081"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors.blue1}></stop>
          <stop offset="0.479" stopColor={theme.colors.purple1}></stop>
          <stop offset="1" stopColor={theme.colors.yellow1}></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="18.105"
          x2="18.105"
          y1="2.395"
          y2="34.61"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors.blue1}></stop>
          <stop offset="0.479" stopColor={theme.colors.purple1}></stop>
          <stop offset="1" stopColor={theme.colors.yellow1}></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default QuizIcon;
