import { useAppActions } from 'app/src/context/AppsStore';
import { useProductActions } from 'app/src/context/ProductsStore';
import { useVodConnection } from 'app/src/context/VodConnectionStore';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { track } from 'app/src/helpers/Tracker';
import { VodConnectionType } from 'app/src/types/entities';
import Utils from 'app/src/utils';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import useCreateVodConnection from 'src/hooks/vod-connections/useCreateVodConnection';
import { useVideoActions, useVideoById } from 'app/src/context/VideosStore';

type InitState = Pick<
  SelectedProductsProviderState,
  | 'initialProducts'
  | 'initialTags'
  | 'initialVariantsByProducts'
  | 'selectedProducts'
  | 'selectedTags'
  | 'variantsByProducts'
>;
type ResetState = Pick<
  SelectedProductsProviderState,
  'selectedProducts' | 'selectedTags' | 'variantsByProducts'
>;

export type SelectedProductsProviderState = {
  initialProducts: any[];
  initialTags: any[];
  initialVariantsByProducts: Record<string, string[]>;
  selectedProducts: any[];
  selectedProductsWithVariants: { product: any; variantId: string }[];
  selectedTags: string[];
  variantsByProducts: Record<string, string[]>;
  isDirty: boolean;
  isSaving: boolean;
  isLoading: boolean;
  vodAssetIds?: string[];
};

export type SelectedProductsProviderActions = {
  init: (state: InitState) => void;
  reset: (state: ResetState) => void;
  setSelectedProducts: (products: any[]) => void;
  setIsLoading: (value: boolean) => void;
  addProduct: (product: any) => void;
  removeProduct: (productId: string, variantId: string) => void;
  addVariant: (productId: string, variantId: string) => void;
  removeTag: (tag: string) => void;
  toggleTag: (tag: string) => void;
  save: () => void;
  reorderProducts: (productsOrder) => void;
  setIsSaving: (value: boolean) => void;
};

export type SelectedProductsProviderContext = [
  SelectedProductsProviderState,
  SelectedProductsProviderActions,
];

const SelectedProductsContext = createContext<SelectedProductsProviderContext>(undefined);

export const useSelectedProductsContext = () => useContext(SelectedProductsContext);

export const SelectedProductsProvider = ({ children }) => {
  const [initialProducts, setInitialProducts] = useState([]);
  const [initialTags, setInitialTags] = useState([]);
  const [initialVariantsByProducts, setInitialVariantsByProducts] = useState({});
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [variantsByProducts, setVariantsByProducts] = useState({});
  const [isDirty, setIsDirty] = useState(false);
  const [selectedProductsWithVariants, setSelectedProductsWithVariants] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { createProducts, getProducts } = useProductActions();
  const [
    {
      currentIds: vodAssetIds,
      modalProps: { analyticsData },
    },
  ] = useModal();

  const [
    {
      selectedAppUrl,
      vodConnectionProductIds: vodConnectionProductIdsFromStore,
      isConnectionsWithPagination,
      vodConnectionByVodAssetId,
    },
    {
      deleteConnectionByTag,
      deleteConnectionByProductId,
      getProductsByVodAssetId,
      updateVodConnectionVariants,
    },
  ] = useVodConnection({ vodAssetId: vodAssetIds[0] });

  const [video] = useVideoById(vodAssetIds[0]);
  const { getVideoById } = useVideoActions();
  const vodConnections = video?.vodConnections?.items || [];
  const productIdsFromVodConnections = vodConnections
    .map(({ productId, appUrl }) => selectedAppUrl === appUrl && productId)
    .filter(Boolean);
  const vodConnectionProductIds = productIdsFromVodConnections || vodConnectionProductIdsFromStore;

  const { createMultipleVodConnections } = useCreateVodConnection();
  const { getAppUsingUrl } = useAppActions();

  const selectedApp = getAppUsingUrl(selectedAppUrl);
  const selectedAppSettings = JSON.parse(selectedApp?.settings || '{}');
  const shouldAutoCreateTagByPrefix = selectedAppSettings?.shouldAutoCreateTagByPrefix;
  const tagsPrefixes = selectedAppSettings?.tagsPrefix;

  const isMultipleTagging = vodAssetIds.length > 1;

  const getSelectedVariants = (selectedProducts, variants = variantsByProducts) => {
    const selectedProductsWithVariants = selectedProducts.reduce((acc, product) => {
      if (!product) {
        return acc;
      }

      if (!variants[product.id]?.length) {
        return [...acc, { product }];
      }

      return [...acc, ...variants[product.id]?.map(variantId => ({ product, variantId }))];
    }, []);

    setSelectedProductsWithVariants(selectedProductsWithVariants);
  };

  const getProviderValue = (): SelectedProductsProviderContext => {
    const state: SelectedProductsProviderState = {
      initialProducts,
      initialTags,
      initialVariantsByProducts,
      selectedProducts,
      selectedProductsWithVariants,
      selectedTags,
      variantsByProducts,
      isDirty,
      isSaving,
      isLoading,
      vodAssetIds,
    };

    const createProductsIfNeeded = async () => {
      const productIdsToCreate = selectedProducts
        .filter(product => product?.productId && !product?.id)
        .map(product => product.productId);

      if (!productIdsToCreate.length) {
        return;
      }

      setIsLoading(true);
      const createdProducts = await createProducts(productIdsToCreate, selectedApp.appUrl);
      const ecomProducts = await getProducts(createdProducts.map(p => p.id));

      const updatedSelectedProducts = selectedProducts.filter(Boolean).map(selectedProduct => {
        if (selectedProduct.id) {
          return selectedProduct;
        }

        const externalProductId = selectedProduct.externalProductId || selectedProduct?.productId;

        return ecomProducts.find(ecomProduct => ecomProduct.productId === externalProductId);
      });
      getSelectedVariants(updatedSelectedProducts);
      setSelectedProducts(updatedSelectedProducts);
      setIsLoading(false);
    };

    const getNewUpdatedDeletedProducts = () => {
      const newProducts = selectedProducts.filter(
        ({ id }) => !vodConnectionProductIds.includes(id)
      );

      const definedInitialProducts = initialProducts.filter(Boolean);

      const deletedProducts = definedInitialProducts.filter(
        ({ productId }) => !selectedProducts.find(product => product.productId === productId)
      );

      const updatedProducts = selectedProducts.filter(Boolean).filter(({ id }, i) => {
        const initialProduct = definedInitialProducts.find(product => id === product.id);
        if (!initialProduct) {
          return false;
        }

        const currentConnections = isConnectionsWithPagination
          ? vodConnections
          : vodConnectionByVodAssetId[vodAssetIds[0]];

        const currentConnection = currentConnections?.find(
          ({ productId, appUrl }) => selectedAppUrl === appUrl && productId === id
        );

        if ((currentConnection?.orderIndex ?? false) || currentConnection?.orderIndex !== i) {
          return true;
        }

        const initialVariants = initialVariantsByProducts[id];
        const variants = variantsByProducts[id];
        const isUpdated =
          initialVariants?.length !== variants?.length ||
          variants?.some(v => !initialVariants?.includes(v));

        return isUpdated;
      });

      return { deletedProducts, newProducts, updatedProducts };
    };

    const getNewAndDeletedTags = () => {
      const newTags = selectedTags.filter(tag => !initialTags.includes(tag));
      const deletedTags = initialTags.filter(tag => !selectedTags.includes(tag));
      return { deletedTags, newTags };
    };

    const deleteProducts = async products => {
      if (isMultipleTagging || !products.length) {
        return;
      }

      track('Vod Connection Deleted', analyticsData);
      const promises = products.map(({ productId }) => {
        return deleteConnectionByProductId({
          vodAssetId: vodAssetIds[0],
          externalProductId: productId,
        });
      });

      return Promise.all(promises);
    };

    const deleteTags = async tags => {
      const isMultipleTagging = vodAssetIds.length > 1;
      if (isMultipleTagging || !tags.length) {
        return;
      }

      return Promise.all(
        tags.map(tag =>
          deleteConnectionByTag({ tag, vodAssetId: vodAssetIds[0], appUrl: selectedApp.appUrl })
        )
      );
    };

    const createTags = tags => {
      if (!tags.length) {
        return;
      }

      const type = VodConnectionType.tag;

      track('Vod Connections Created', { type, isMultipleTagging, ...analyticsData });

      return Promise.all(
        tags.map(tag =>
          createMultipleVodConnections({
            productId: undefined,
            externalProductId: undefined,
            type,
            provider: selectedApp.app,
            appUrl: selectedApp.appUrl,
            vodAssetIds,
            tag,
          })
        )
      );
    };

    const setSelectedTagsByPrefixesIfNeeded = useCallback(() => {
      if (
        !shouldAutoCreateTagByPrefix ||
        !tagsPrefixes?.length ||
        !selectedProducts?.length ||
        !isDirty
      ) {
        return;
      }

      const rawProductTags = selectedProducts.flatMap(product => product.tags).filter(Boolean);
      const productTags = rawProductTags.flatMap(tags => tags.split(',').map(tag => tag.trim()));
      const tagsByPrefix = productTags.filter(tag =>
        tagsPrefixes.some(prefix => tag.startsWith(prefix))
      );

      if (!tagsByPrefix.length) {
        return;
      }

      const uniqueTags = [...new Set([...selectedTags, ...tagsByPrefix])];

      setSelectedTags(uniqueTags);
    }, [selectedProducts, selectedTags, shouldAutoCreateTagByPrefix, tagsPrefixes, isDirty]);

    const createProductsConnections = async products => {
      if (!products.length) {
        return;
      }

      track('Vod Connections Created', {
        type: VodConnectionType.productId,
        isMultipleTagging,
        ...analyticsData,
      });

      return Promise.all(products.map(createProductConnections));
    };

    const createProductConnections = product => {
      if (!product) {
        Utils.logError('Failed to create product');
        return;
      }

      const orderIndex = selectedProducts.findIndex(p => p.id === product.id);

      const currentVodAssetIds = vodAssetIds.flatMap(id => {
        const video = getVideoById(id);
        let productIds = [];
        if (isConnectionsWithPagination) {
          video?.vodConnections?.items
            .map(vodConnection => {
              return vodConnection.appUrl === selectedAppUrl && vodConnection.productId;
            })
            .filter(Boolean);
        } else {
          productIds = getProductsByVodAssetId(id);
        }

        const currentProduct = productIds.find(productId => productId === product.id);
        return currentProduct ? [] : id;
      });

      if (!currentVodAssetIds.length) {
        return;
      }

      return createMultipleVodConnections({
        productId: product.id,
        externalProductId: product.productId,
        variantIds: variantsByProducts[product.id],
        type: VodConnectionType.productId,
        tag: undefined,
        provider: selectedApp.app,
        vodAssetIds: currentVodAssetIds,
        orderIndex,
        appUrl: selectedApp.appUrl,
      });
    };

    const updateProductsConnections = products => {
      return Promise.all(
        products.map(product => {
          const index = selectedProducts.findIndex(
            currentProduct => product.productId === currentProduct.productId
          );

          return updateVodConnectionVariants({
            productId: product.productId,
            vodAssetId: vodAssetIds[0],
            variantIds: variantsByProducts[product.id],
            orderIndex: index,
          });
        })
      );
    };

    useEffect(() => {
      createProductsIfNeeded();
      setSelectedTagsByPrefixesIfNeeded();
    }, [selectedProducts]);

    const actions: SelectedProductsProviderActions = {
      init: state => {
        const {
          initialProducts,
          initialTags,
          initialVariantsByProducts,
          selectedProducts,
          selectedTags,
          variantsByProducts,
        } = state;

        setInitialProducts(initialProducts);
        setInitialTags(initialTags);
        setInitialVariantsByProducts(initialVariantsByProducts);
        getSelectedVariants(selectedProducts, variantsByProducts);
        setSelectedProducts(selectedProducts);
        setSelectedTags(selectedTags);
        setVariantsByProducts(variantsByProducts);
        setIsDirty(false);
        setIsSaving(false);
        setIsLoading(false);
      },
      reset: state => {
        const { selectedProducts, selectedTags, variantsByProducts } = state;
        getSelectedVariants(selectedProducts, variantsByProducts);
        setSelectedProducts(selectedProducts);
        setSelectedTags(selectedTags);
        setVariantsByProducts(variantsByProducts);
      },
      setSelectedProducts: products => {
        const newProducts = products.map(product => {
          const currentProduct = initialProducts.find(
            ({ productId }) => productId === product.productId
          );

          return currentProduct || product;
        });

        getSelectedVariants(newProducts);
        setSelectedProducts(newProducts);
        setIsDirty(true);
      },
      setIsLoading,
      addProduct: product => {
        getSelectedVariants([...selectedProducts, product]);
        setSelectedProducts([...selectedProducts, product]);
        setIsDirty(true);
      },
      addVariant: (productId, variantId) => {
        const variantsSet = new Set(variantsByProducts[productId] || []);
        variantsSet.add(variantId);
        getSelectedVariants(selectedProducts, {
          ...variantsByProducts,
          [productId]: [...variantsSet],
        });
        setVariantsByProducts({
          ...variantsByProducts,
          [productId]: [...variantsSet],
        });
        setIsDirty(true);
      },
      removeProduct: (productId, variantId) => {
        const productToDelete = selectedProducts.find(product => product.productId === productId);

        if (!productToDelete) {
          return;
        }

        const variantsSet = new Set(variantsByProducts[productToDelete.id] || []);
        variantsSet.delete(variantId);
        setVariantsByProducts({
          ...variantsByProducts,
          [productToDelete.id]: variantsSet.size ? [...variantsSet] : undefined,
        });

        if (!variantsSet.size) {
          const updatedProducts = selectedProducts.filter(
            product => product.productId !== productId
          );
          getSelectedVariants(updatedProducts);
          setSelectedProducts(updatedProducts);
        }

        setIsDirty(true);
      },
      removeTag: tag => {
        setSelectedTags(selectedTags.filter(selectedTag => selectedTag !== tag));
        setIsDirty(true);
      },
      reorderProducts: newProducts => {
        getSelectedVariants(newProducts);
        setSelectedProducts(newProducts);
        setIsDirty(true);
      },
      toggleTag: tag => {
        if (selectedTags.includes(tag)) {
          actions.removeTag(tag);
          return;
        }

        setSelectedTags([...selectedTags, tag]);
        setIsDirty(true);
      },
      save: async () => {
        setIsSaving(true);
        setIsLoading(true);

        const { deletedProducts, newProducts, updatedProducts } = getNewUpdatedDeletedProducts();

        const { deletedTags, newTags } = getNewAndDeletedTags();

        await Promise.all([
          deleteProducts(deletedProducts),
          deleteTags(deletedTags),
          createTags(newTags),
          createProductsConnections(newProducts),
          updateProductsConnections(updatedProducts),
        ]);

        setIsSaving(false);
        setIsLoading(false);
      },
      setIsSaving,
    };

    return [state, actions];
  };

  return (
    <SelectedProductsContext.Provider value={getProviderValue()}>
      {children}
    </SelectedProductsContext.Provider>
  );
};
