// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck adding this because for some reason you can't pass array to path in ts file
import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { Route, Switch, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Sidebar from 'app/src/pages/sidebar/Sidebar';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import Routes, { projectTabs } from 'app/src/helpers/Routes';
import ProjectEdit from 'app/src/pages/project/pages/project-edit/ProjectEdit';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import ProjectTabs from 'app/src/pages/project/components/project-tabs/ProjectTabs';
import ProjectTopBar from 'app/src/pages/project/components/project-top-bar/ProjectTopBar';
import LookAndFeelPage from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage';
import ComponentWithLoader from 'shared/react/components/basic/ComponentWithLoader';
import { useProjects } from 'app/src/context/ProjectsStore';
import RulesPage from 'app/src/pages/project/pages/rules_and_triggers/RulesPage';
import QuizPage from 'app/src/pages/project/pages/quiz/QuizPage';
import ColorfulWrapper from 'app/src/basic_components/ColorfulWrapper';
import InstallationPage from 'app/src/pages/project/pages/installation/InstallationPage';
import GeneralSettingsPage from 'app/src/pages/project/pages/settings/GeneralSettingsPage';
import Inbox from 'app/src/pages/project/pages/inbox/Inbox';
import useShopifyAppSelect from 'src/complex_components/shopify-apps-select/useShopifyAppSelect';
import { DynamicConditionsProvider } from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/context/DynamicConditionsContext';
import { MatchConditions } from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/constants/dynamicConditions.constants';
import { DynamicTypeFormValues } from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/DynamicTypeContent';
import { getRules } from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/helpers/getRulesArray';
import useDynamicVideoIdsByProject from 'app/src/hooks/use-dynamic-video-ids-by-project/useDynamicVideoIdsByProject';
import { useBuilderFormContext } from 'app/src/context/BuilderFormStore';
import { useRulesActions } from 'app/src/context/RulesStore';
import { publishMethodOptions } from 'app/src/types/entities';
import { SHOP_APP_TARGET } from 'src/constants/project.constants';

const ProjectPage = () => {
  const { projectId } = useParams();
  const [{ project }, { getProjectById }] = useProjects({ projectId });
  const { createDefaultDynamicRule } = useRulesActions();
  const { handleSelectedAppChange } = useShopifyAppSelect();
  const containerRef = useRef(null);
  const sourceProject = getProjectById(project?.sourceProjectId) || project;

  const defaultDynamicRule = createDefaultDynamicRule();

  const methods = useForm<DynamicTypeFormValues>({
    defaultValues: {
      rules: [defaultDynamicRule],
      matchConditions: MatchConditions.matchAny,
      videoRules: [],
    },
  });
  const { watch, setValue } = methods;
  const isShopAppPdp =
    sourceProject?.publishMethod === publishMethodOptions.shopApp &&
    sourceProject?.targetPage === SHOP_APP_TARGET.productPage;
  const [rules, matchConditions, videoRules] = watch(['rules', 'matchConditions', 'videoRules']);
  const { getProjectIsFeedTypeDynamic } = useBuilderFormContext();
  const isProjectFeedTypeDynamic = getProjectIsFeedTypeDynamic(sourceProject);

  const ruleGroup = useMemo(() => {
    const currentRules = isShopAppPdp ? [defaultDynamicRule] : rules;
    return {
      enabled: true,
      rules: getRules({ rules: currentRules, matchConditions, videoRules }),
      appKey: sourceProject?.appKey,
    };
  }, [rules, matchConditions, isShopAppPdp, sourceProject]);
  const { videoIds: vodAssetIds } = useDynamicVideoIdsByProject(
    sourceProject,
    ruleGroup,
    isProjectFeedTypeDynamic
  );

  const setRules = value => {
    setValue('rules', value, { shouldDirty: true });
  };

  const setMatchConditions = value => {
    setValue('matchConditions', value, { shouldDirty: true });
  };

  const setVideoRules = value => {
    setValue('videoRules', value, { shouldDirty: true });
  };

  useEffect(() => {
    if (!project) {
      return;
    }

    handleSelectedAppChange(project.appUrl);
  }, [project]);

  useEffect(() => {
    if (!videoRules.length) {
      return;
    }

    const newVideoRules = videoRules.filter(({ value }) => {
      if (vodAssetIds.find(id => value.includes(id))) {
        return true;
      }

      return false;
    });

    setValue('videoRules', newVideoRules, { shouldDirty: true });
  }, [vodAssetIds]);

  return (
    <ColorfulWrapper>
      <LayoutRoot>
        <Sidebar />
        <Content>
          <DynamicConditionsProvider
            value={{
              methods,
              vodAssetIds,
              rules,
              matchConditions,
              videoRules,
              setRules,
              setMatchConditions,
              setVideoRules,
            }}
          >
            <ProjectTopBar />
            <CustomLoader isLoading={!project}>
              <ProjectTabs project={project} />
              <Pages ref={containerRef}>
                <Switch>
                  <Route path={Routes.getProjectTabRoute(projectTabs.design)} exact>
                    <LookAndFeelPage />
                  </Route>
                  <Route path={Routes.getProjectTabRoute(projectTabs.settings)} exact>
                    <GeneralSettingsPage />
                  </Route>
                  <Route path={Routes.getProjectTabRoute(projectTabs.quiz)} exact>
                    <QuizPage />
                  </Route>
                  <Route path={Routes.getProjectTabRoute(projectTabs.rules)} exact>
                    <RulesPage />
                  </Route>
                  <Route path={Routes.getProjectTabRoute(projectTabs.responses)} exact>
                    <Inbox closeSidebar={() => {}} />
                  </Route>
                  <Route path={Routes.getProjectTabRoute(projectTabs.installation)} exact>
                    <InstallationPage project={project} />
                  </Route>
                  <Route path={Routes.getProjectTabRoute(projectTabs.videos)}>
                    <ProjectEdit containerRef={containerRef} project={project} />
                  </Route>
                </Switch>
              </Pages>
            </CustomLoader>
          </DynamicConditionsProvider>
        </Content>
      </LayoutRoot>
    </ColorfulWrapper>
  );
};

const LayoutRoot = styled(HorizontalFlex)`
  height: 100vh;
  align-items: stretch;
`;

const Content = styled(VerticalFlex)`
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.neutralLightest};
`;

const CustomLoader = styled(ComponentWithLoader)`
  margin: 12px;
`;

const Pages = styled(VerticalFlex)`
  overflow: auto;
  height: 100%;
  width: 100%;

  & > * {
    padding: 0 24px 24px;
  }
`;

export default ProjectPage;
