import { REACT_APP_VIDEO_OUTPUT } from 'player/src/config/player.config';
import Utils from 'player/src/utils';
import {
  MP4_EXTENSION,
  WATERMARK_EXTENSION,
} from 'shared/react/constants/fileExtensions.constants';
import { TOLSTOY_KEY } from 'shared/react/constants/tolstoy.constants';
import { VIDEO_STATUS } from 'shared/react/constants/video.constants';
import { MUX, MUX_CDN, VIDEO_PROVIDERS } from 'shared/react/constants/videoProviders.constants';
import { PLAYER_RESOLUTIONS } from 'player/src/constants/playerResolutions.constants';

export const getPosterUrl = (video, owner) => {
  if (video.stockAsset?.posterUrl) {
    return video.stockAsset.posterUrl;
  }

  const baseUrl = getVideoBaseUrl(video.videoOwner, owner);
  return `${baseUrl}/${video.videoId}/${video?.videoId}.0000000.jpg`;
};

export const getVideoUrl = video => {
  return video.stockAsset?.videoUrl;
};

export const getVideoBaseUrl = (videoOwner, owner) => {
  return `https://${REACT_APP_VIDEO_OUTPUT}/public/${videoOwner || owner}`;
};

export const getGifUrl = video => {
  return video.stockAsset?.gifUrl || video.stockAsset?.posterUrl;
};

export const getGifUrlByOwner = video => {
  if (video.stockAsset) {
    return getGifUrl(video);
  }

  return `${getVideoBaseUrl(video.videoOwner, video.owner)}/${video.videoId}/${
    video.videoId
  }.mp4.gif`;
};

export const getLeadFormStateKey = projectId => {
  return `tolstoy-${projectId}-lead-form-displayed`;
};

export const getSentInCurrentSessionKey = sessionId => {
  return `tolstoy-${sessionId}-lead-form-sent`;
};

export const findStepByKey = (key, playlist) => {
  return playlist.find(video => video.key === key);
};

export const findAnswerByType = (steps, type) => {
  for (let i = 0; i < steps.length; i++) {
    const answer = steps[i].answers.find(answer => answer.type === type || answer.next === type);
    if (answer) {
      return answer;
    }
  }
};

const getMuxVideoUrl = ({ res, step }) => {
  const { externalProviderData } = step;
  const { videoUrl, status } = externalProviderData || {};

  if (!videoUrl || status !== VIDEO_STATUS.done) {
    return resolveVideoSrc({ res, step });
  }

  return videoUrl;
};

const getVideoResolution = ({ res, step, hidePoweredBy }) => {
  const { uploadType } = step;
  const isAiVideo = Utils.getIsAiVideo(uploadType);

  if (hidePoweredBy || !isAiVideo) {
    return res ? `_${res}.${MP4_EXTENSION}` : `.${MP4_EXTENSION}`;
  }

  return res
    ? `${WATERMARK_EXTENSION}_${res}.${MP4_EXTENSION}`
    : `${WATERMARK_EXTENSION}.${MP4_EXTENSION}`;
};

const getVideoSrc = ({ res, step, hidePoweredBy = false }) => {
  const { videoOwner, videoId } = step;
  const baseUrl = getVideoBaseUrl(videoOwner);
  const videoUrl = getVideoUrl(step);

  const videoRes = getVideoResolution({ res, step, hidePoweredBy });
  if (!videoUrl) {
    return `${baseUrl}/${videoId}/${videoId}${videoRes}`;
  }

  if (VIDEO_PROVIDERS.some(provider => videoUrl.includes(provider))) {
    return videoUrl;
  }

  if (step?.stockAsset?.shopifyFileId) {
    return videoUrl;
  }

  return `${videoUrl.split(`.${MP4_EXTENSION}`)[0]}${videoRes}`;
};

export const getBasicVideoSrc = ({ res, step }) => {
  const { videoOwner, videoId } = step;
  const baseUrl = getVideoBaseUrl(videoOwner);

  const videoRes = res ? `_${res}.${MP4_EXTENSION}` : `.${MP4_EXTENSION}`;

  return `${baseUrl}/${videoId}/${videoId}${videoRes}`;
};

export const resolveVideoSrc = ({ res, step, isUseMuxVideo, hidePoweredBy }) => {
  if (Utils.isLightHouse(navigator.userAgent)) {
    res = PLAYER_RESOLUTIONS.preview;
  }

  if (isUseMuxVideo) {
    return getMuxVideoUrl({ res, step });
  }

  let src = getVideoSrc({ res, step, hidePoweredBy });

  return src + '#t=0.01';
};

export const closePlayerInsideIntercomChat = () => {
  window.INTERCOM_MESSENGER_SHEET_LIBRARY?.closeSheet();
};

export const getProviderName = ({ videoSrc, isUseMuxVideo }) => {
  if (isUseMuxVideo && videoSrc.includes(MUX_CDN)) {
    return MUX;
  }

  return TOLSTOY_KEY;
};
