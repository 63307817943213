import React, { useRef, createRef } from 'react';
import styled from 'styled-components';

import Utils from 'shared/react/utils/utils';
import LoadingIndicator from 'shared/react/components/basic/LoadingIndicator';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextSmallBold } from 'shared/react/components/basic/text/TextV2';
import { useFeedIsMobile } from 'shared/react/hooks/useIsMobile';

const MOBILE_AUTOPLAY_INDEXES = [0];

const VideoGrid = ({
  videos,
  onVideoClick,
  isLoading,
  emptyStateMessage,
  isMobile,
  settings = {},
}) => {
  const videoRefs = useRef(videos.map(() => createRef()));
  const isMobileFeed = useFeedIsMobile();
  const isSafari = Utils.isSafari();

  const playFirstVideo = () => {
    const firstVideoEl = videoRefs.current[0];

    if (!firstVideoEl) {
      return;
    }

    try {
      firstVideoEl.muted = true;
      firstVideoEl.play();
    } catch (error) {
      return;
    }
  };

  const pauseFirstVideo = () => {
    const firstVideoEl = videoRefs.current[0];

    if (!firstVideoEl) {
      return;
    }

    try {
      firstVideoEl.pause();
    } catch (error) {
      return;
    }
  };

  const handlePlayVideo = index => {
    if (index === 0 || isSafari) {
      return;
    }

    pauseFirstVideo();

    try {
      videoRefs.current.muted = true;
      videoRefs.current[index]?.play();
    } catch {
      // do nothing
    }
  };

  const handlePauseVideo = index => {
    if (index === 0 || isSafari) {
      return;
    }

    try {
      videoRefs.current[index]?.pause();
    } catch (error) {
      return;
    }

    playFirstVideo();
  };

  if (isLoading) {
    return (
      <EmptyStateWrapper>
        <StyledLoadingIndicator />
      </EmptyStateWrapper>
    );
  }

  if (!isLoading && !videos?.length) {
    return (
      <EmptyStateWrapper>
        <EmptyStateMessage>{emptyStateMessage}</EmptyStateMessage>
      </EmptyStateWrapper>
    );
  }

  const shouldAutoPlay = index => {
    if (isMobile) {
      return MOBILE_AUTOPLAY_INDEXES.includes(index);
    }

    return index === 0;
  };

  return (
    <LayoutRoot settings={settings}>
      {videos?.map((video, index) => (
        <VideoCard key={video.id}>
          <VideoItem
            ref={el => (videoRefs.current[index] = el)}
            key={video.id}
            src={video.videoUrl}
            poster={video.posterUrl}
            onMouseEnter={() => handlePlayVideo(index)}
            onMouseLeave={() => handlePauseVideo(index)}
            loop
            muted
            playsInline
            autoPlay={shouldAutoPlay(index)}
            onClick={() => {
              onVideoClick(video, index);
            }}
            $isMobile={isMobileFeed}
          />
          <VideoInfo />
        </VideoCard>
      ))}
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  width: 100%;
  display: grid;
  box-sizing: border-box;
  grid-gap: 4px;
  padding: 8px;

  @media (max-width: 768px) {
    grid-template-columns: ${({ settings }) => `repeat(${settings?.mobileColumnCount || 3}, 1fr)`};
  }

  @media (min-width: 769px) {
    grid-template-columns: ${({ settings }) => `repeat(${settings?.desktopColumnCount || 3}, 1fr)`};
  }
`;

const EmptyStateWrapper = styled(VerticalFlex)`
  min-height: 20px;
  justify-content: start;
  position: relative;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const EmptyStateMessage = styled(TextSmallBold)`
  text-align: center;
  color: ${({ theme }) => theme.colors.neutralGray};
`;

const VideoCard = styled(VerticalFlex)`
  position: relative;
`;

const VideoItem = styled.video`
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  max-height: ${({ $isMobile }) => ($isMobile ? '160px' : '250px')};
  object-fit: cover;
  aspect-ratio: 9 / 16;
  cursor: pointer;
  border-radius: 4px;
`;

const VideoInfo = styled.div`
  padding: 8px;
`;

export default VideoGrid;
