export const ONSITE_SIDEBAR_LOCAL_STORAGE_SETTINGS_KEY = 'onsite-sidebar-settings';
export const VIDEOS_SIDEBAR_LOCAL_STORAGE_SETTINGS_KEY = 'videos-sidebar-settings';

export const VIDEOS_SIDEBAR_PLAYLIST_GROUPS = {
  SOURCES: 'Sources',
  CUSTOM_PLAYLISTS: 'Playlists',
};

export const VIDEOS_SIDEBAR_DEFAULT_SETTINGS = {
  [VIDEOS_SIDEBAR_PLAYLIST_GROUPS.SOURCES]: true,
  [VIDEOS_SIDEBAR_PLAYLIST_GROUPS.CUSTOM_PLAYLISTS]: true,
};

export const ONSITE_SIDEBAR_PLAYLIST_GROUPS = {
  LAYOUTS: 'Layouts',
  FOLDERS: 'Folders',
  PLAYLISTS: 'Playlists',
};

export const ONSITE_SIDEBAR_DEFAULT_SETTINGS = {
  [ONSITE_SIDEBAR_PLAYLIST_GROUPS.LAYOUTS]: true,
  [ONSITE_SIDEBAR_PLAYLIST_GROUPS.FOLDERS]: false,
  [ONSITE_SIDEBAR_PLAYLIST_GROUPS.PLAYLISTS]: false,
};
