import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { TextSubtitle } from 'shared/react/components/complex/Text';
import Conversations from 'app/src/pages/project/pages/inbox/Conversations';
import Utils from 'app/src/utils';
import InboxExportToCsv from 'app/src/pages/project/pages/inbox/InboxExportToCsv';
import ConversationsSearchBox from 'app/src/pages/project/pages/inbox/ConversationsSearchBox';

const groupConversations = conversations => {
  const todayConversations = [];
  const recentlyConversations = [];
  conversations?.forEach(conversation => {
    if (Utils.isDateToday(conversation.createdAt)) {
      todayConversations.push(conversation);
    } else {
      recentlyConversations.push(conversation);
    }
  });
  return [
    { title: 'Today', conversations: todayConversations },
    { title: 'Recently', conversations: recentlyConversations },
  ].filter(({ conversations }) => conversations.length > 0);
};

const getTitle = (name, counter) => {
  let title = name;
  if (counter) {
    title += ` (${counter})`;
  }
  return title;
};

const InboxConversations = ({
  onEmailChange,
  selectedConversation,
  conversations,
  status,
  loadingConversations,
  onConversationSelect,
  updateConversation,
  conversationsRef,
  counter,
}) => {
  const conversationsByDate = groupConversations(conversations);
  const theme = useTheme();

  return (
    <StyledConversations>
      {status && (
        <ConversationsTitle textColor={theme.colors.primaryActive}>
          {getTitle(status.name, counter)}
          <InboxExportToCsv status={status} />
        </ConversationsTitle>
      )}
      {onEmailChange && (
        <ConversationContainer>
          <ConversationsSearchBox onEmailChange={onEmailChange} />
        </ConversationContainer>
      )}
      <Conversations
        selectedConversation={selectedConversation}
        loadingConversations={loadingConversations}
        conversationsByDate={conversationsByDate}
        onConversationClick={onConversationSelect}
        updateConversation={updateConversation}
        conversationsRef={conversationsRef}
      />
    </StyledConversations>
  );
};

export default InboxConversations;

const StyledConversations = styled.div`
  grid-area: conversations;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};
  padding-top: 15px;
`;

const padding = css`
  padding-left: 14px;
  padding-right: 16px;
`;

const ConversationsTitle = styled(TextSubtitle)`
  padding-bottom: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ${padding}
`;

const ConversationContainer = styled.div`
  ${padding}
`;
