import React from 'react';

function NotificationPlayedIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" viewBox="0 0 40 40">
      <rect width="40" height="40" fill="#FFE0EC" rx="10" />
      <path
        fill="#990038"
        d="M17.5 27c.25 0 .531-.063.781-.219l9-5.5c.438-.25.719-.75.719-1.281 0-.5-.281-1-.719-1.25l-9-5.5a1.485 1.485 0 00-.781-.219c-.875 0-1.5.688-1.5 1.469v11c0 .813.625 1.5 1.5 1.5z"
      />
    </svg>
  );
}

export default NotificationPlayedIcon;
