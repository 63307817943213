import React from 'react';
import { FixedSizeGrid } from 'react-window';
import { FixedGridProps, ChildrenAsFunction } from './FixedGrid.types';
import {
  getColumnCount,
  getColumnWidth,
  getRowCount,
  getStyles,
  getGridSize,
} from './FixedGrid.utils';

/*
  itemCount or rowCount is required
  breakpoints or columnCount is required
*/
export const FixedGrid = React.forwardRef(
  (
    {
      width,
      height,
      itemCount,
      breakpoints,

      rowCount,
      rowHeight,
      columnCount,
      columnWidth,

      gap = 0,
      style = {},
      padding = 0,
      paddingTop = 0,
      paddingBottom = 0,
      paddingLeft = 0,
      paddingRight = 0,

      children,

      onItemsRendered,
      ...props
    }: FixedGridProps,
    ref: React.Ref<any>
  ) => {
    const { gridWidth, gridHeight } = getGridSize({
      width,
      height,
      padding,
      paddingTop,
      paddingBottom,
      paddingLeft,
      paddingRight,
    });
    const gridColumnCount = getColumnCount({ gridWidth, breakpoints, columnCount });
    const gridColumnWidth = getColumnWidth({
      gridWidth,
      columnCount: gridColumnCount,
      gap,
      columnWidth,
    });
    const gridRowCount = getRowCount({ rowCount, itemCount, columnCount: gridColumnCount });

    const handleOnItemsRendered = props => {
      if (onItemsRendered) {
        onItemsRendered({ ...props, columnCount: gridColumnCount });
      }
    };

    /*
    react-window works on absolute positioning
    so to make sure scoll bar is in far right (and/or bottom) we need to add right/bottom padding
    and use top/left margin to account for paddingLeft && paddingTop (because absolute positioning)
  */
    const resultGridHeight = gridHeight + (paddingBottom || padding);
    const resultGridWidth = gridWidth + (paddingRight || padding);
    const gridStyle = {
      marginTop: paddingTop || padding,
      marginLeft: paddingLeft || padding,
      ...style,
    };

    return (
      <FixedSizeGrid
        ref={ref}
        width={resultGridWidth}
        height={resultGridHeight}
        rowHeight={rowHeight + gap}
        rowCount={gridRowCount}
        columnWidth={gridColumnWidth}
        columnCount={gridColumnCount}
        onItemsRendered={handleOnItemsRendered}
        style={gridStyle}
        {...props}
      >
        {props => {
          const itemIndex = props.rowIndex * gridColumnCount + props.columnIndex;
          const styles = getStyles({
            gap,
            style: props.style,
            rowCount: gridRowCount,
            rowIndex: props.rowIndex,
            columnCount: gridColumnCount,
            columnIndex: props.columnIndex,
          });

          return (
            <div style={styles}>
              {typeof children === 'function'
                ? (children as ChildrenAsFunction)({ ...props, itemIndex })
                : children}
            </div>
          );
        }}
      </FixedSizeGrid>
    );
  }
);

FixedGrid.displayName = 'FixedGrid';

export default FixedGrid;
