import React from 'react';
import styled from 'styled-components';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { useVodConnectionByTag } from 'app/src/context/VodConnectionStore';
import { FEATURE_SHOPIFY_TAGS } from 'shared/react/constants/features.constants';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import ProductTag from 'app/src/complex_components/search-products/product-tags/ProductTag';
import { TextTiny, TextXTiny } from 'shared/react/components/basic/text/TextV2';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { CheckboxV2 } from 'app/src/complex_components/Checkbox';
import Separator from 'shared/react/components/basic/Separator';
import { useApps } from 'src/context/AppsStore';
import { vodConnection, VodConnectionType } from 'app/src/types/entities';

type Props = {
  searchValue: string;
  selectedTags: string[];
  appUrl: string;
  onTagClick: (label: string) => void;
  isSingleSelect?: boolean;
  vodConnections: vodConnection[];
};

const ProductTags = ({
  searchValue,
  selectedTags = [],
  appUrl,
  onTagClick,
  isSingleSelect,
  vodConnections: vodConnectionsFromVideo,
}: Props) => {
  const { getFeatureEnabled } = useFeatureActions();
  const [{ shopify: isShopify }, { getAppUsingUrl }] = useApps();
  const selectedApp = getAppUsingUrl(appUrl);
  const [vodConnectionsFromDB] = useVodConnectionByTag(appUrl);
  const shopifyTagsEnabled = isShopify && getFeatureEnabled(FEATURE_SHOPIFY_TAGS);

  const getVodConnections = () => {
    if (vodConnectionsFromDB?.length) {
      return vodConnectionsFromDB;
    }

    return vodConnectionsFromVideo?.filter(
      ({ appUrl: connectionAppUrl, type }) =>
        connectionAppUrl === appUrl && type === VodConnectionType.tag
    );
  };

  const vodConnections = getVodConnections();

  const getSearchTags = () => {
    const existingTags = vodConnections
      .filter(({ tag }) => tag.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
      .map(({ tag }) => tag);

    const selectedTagsFilter = selectedTags?.filter(
      tag =>
        tag.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) && tag !== searchValue
    );

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    const values = [searchValue.trim(), ...selectedTagsFilter, ...existingTags];
    const settings = JSON.parse(selectedApp?.settings || '{}');
    if (settings?.tagsPrefix?.length) {
      const newValues = settings?.tagsPrefix.map(val => {
        return `${val}${searchValue.trim()}`;
      });

      values.push(...newValues);
    }

    return [...new Set(values)];
  };

  if (!shopifyTagsEnabled || !searchValue.trim()) {
    return null;
  }

  const autoCompleteTags = getSearchTags();
  return (
    <LayoutRoot>
      <Header>Shopify tags</Header>
      <TagsContainer>
        {autoCompleteTags.map(tag => (
          <Tag key={tag} onClick={() => onTagClick(tag)}>
            {!isSingleSelect && <CheckboxV2 checked={selectedTags?.includes(tag)} />}
            <ProductTag tag={tag} />
            <StyledSeparator />
          </Tag>
        ))}
      </TagsContainer>
      <Disclaimer>Make sure to type the exact text as in Shopify, case sensitive.</Disclaimer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)``;

const Header = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralDarker};
  padding: 0 8px;
`;

const TagsContainer = styled(Gap8VerticalFlex)`
  width: 100%;
  max-height: 125px;
  .MuiButtonBase-root {
    padding: 0;
  }
`;

const Tag = styled(Gap8HorizontalFlex)`
  cursor: pointer;
  transition: 0.3s;
  padding: 0px 8px;
  height: 28px;
  align-items: center;

  &:hover {
    background: ${({ theme }) => theme.colors.neutralLightest};
  }
`;

const StyledSeparator = styled(Separator)`
  flex-shrink: 0;
  background: ${({ theme }) => theme.colors.neutralLighter};

  &:last-of-type {
    display: none;
  }
`;

const Disclaimer = styled(TextXTiny)`
  padding: 2px 12px 0;
`;

export default ProductTags;
