// Login Page
export const LOGIN_ACTION_BUTTON_DATA_TEST_ID = 'login-action-button';
export const PASSWORD_DATA_TEST_ID = 'password';
export const EMAIL_DATA_TEST_ID = 'email';

// Project Tabs
export const RESPONSES_TAB_DATA_TEST_ID = 'responses';
export const LEAD_FORM_TAB_DATA_TEST_ID = 'lead-form';
export const VIDEOS_TAB_DATA_TEST_ID = 'look-and-feel';
export const DESIGN_TAB_DATA_TEST_ID = 'tab-videos';

// Signup
export const SIGN_UP_BUTTON_DATA_TEST_ID = 'sign-up-button';
export const I_AGREE_CHECKBOX_DATA_TEST_ID = 'i-agree';
export const READY_MADE_TOLSTOY_BUTTON_DATA_TEST_ID = 'ready-made-tolstoy-button';
export const VIDEO_SUPPORT_DATA_TEST_ID = 'support';
export const PREVIEW_READY_MADE_NEXT_BUTTON_DATA_TEST_ID = 'preview-ready-made-next-button';

// Onboarding
export const COMPANY_SIZE_DATA_TEST_ID = 'companySize';
export const COMPANY_MONTHLY_TRAFFIC_DATA_TEST_ID = 'monthlyTraffic';
export const COMPANY_VERTICAL_DATA_TEST_ID = 'companyVertical';
export const COMPANY_ROLE_DATA_TEST_ID = 'companyRole';
export const USE_CASE_DATA_TEST_ID = 'useCase';
export const CONTINUE_BUTTON_DATA_TEST_ID = 'continue-button';

// Lead Form
export const CREATE_TOLSTOY_BUTTON_DATA_TEST_ID = 'create-tolstoy-button';
export const CREATE_TOLSTOY_MENU_DATA_TEST_ID = 'create-now-button';
export const COLLECT_AFTER_SELECT_DATA_TEST_ID = 'collect-after-select';
export const COLLECT_AFTER_SWITCH_DATA_TEST_ID = 'collect-after-ios-switch';

// Folders
export const FOLDER_EXTRA_BUTTONS_DATA_TEST_ID = 'extra-buttons';
export const FOLDER_TRASH_ICON_DATA_TEST_ID = 'trash-icon';
export const CREATE_NEW_FOLDER_BUTTON_DATA_TEST_ID = 'create-new-folder-button';
export const TYPE_FOLDER_NAME_DATA_TEST_ID = 'type-folder-name';
export const FOLDER_RENAME_MODAL_SAVE_DATA_TEST_ID = 'folder-rename-modal-save';
export const FOLDER_ITEM_DATA_TEST_ID = 'folder-item';

// Custom Playlists
export const CUSTOM_PLAYLIST_EXTRA_BUTTONS_DATA_TEST_ID = 'custom-playlist-extra-buttons';
export const CREATE_CUSTOM_PLAYLIST_BUTTON_DATA_TEST_ID = 'create-custom-playlist-button';
export const TYPE_CUSTOM_PLAYLIST_NAME_DATA_TEST_ID = 'type-custom-playlist-name';
export const CUSTOM_PLAYLIST_RENAME_MODAL_SAVE_DATA_TEST_ID = 'custom-playlist-rename-modal-save';
export const CUSTOM_PLAYLIST_ITEM_DATA_TEST_ID = 'custom-playlist-item';

// Sidebar
export const SIDEBAR_EDITABLE_ITEM_TRASH_ICON_DATA_TEST_ID = 'sidebar-editable-item-trash-icon';

// Macros
export const ADD_MACRO_TILE_DATA_TEST_ID = 'add-macro-tile';
export const MACROS_MODAL_HEADER_DATA_TEST_ID = 'macros-modal-header';
export const CLOSE_MODAL_DATA_TEST_ID = 'close-modal';
export const SAVE_AS_MACRO_DATA_TEST_ID = 'save-as-macro';
export const REMOVE_MACRO_DATA_TEST_ID = 'remove-macro';
export const ADD_MACRO_BUTTON_DATA_TEST_ID = 'add-macro-button';
export const DELETE_MODAL_CONFIRMATION_DATA_TEST_ID = 'delete-modal-yes';
export const STEP_IMAGE_CONTAINER_DATA_TEST_ID = 'step-image-container';
export const STOCK_GALLERY_IMAGE_CONTAINER_DATA_TEST_ID = 'stock-gallery-image-container';
export const STOCK_GALLERY_LAYOUT_ROOT_DATA_TEST_ID = 'stock-gallery-layout-root';

// Sidebar
export const SIDEBAR_VIDEOS_TAB_TEST_ID = 'sidebar-videos-tab';
export const SIDEBAR_ONSITE_TAB_TEST_ID = 'sidebar-onsite-tab';
export const SIDEBAR_OFFSITE_TAB_TEST_ID = 'sidebar-offsite-tab';
export const SIDEBAR_SHOP_APP_TAB_TEST_ID = 'sidebar-shop-app-tab';
export const SIDEBAR_THREADS_TAB_TEST_ID = 'sidebar-threads-tab';
export const SIDEBAR_ACCOUNT_TEST_ID = 'sidebar-account';
export const SIDEBAR_HOMEPAGE_TEST_ID = 'sidebar-homepage';

// Publish
export const EMBED_CODE_BOX_DATA_TEST_ID = 'embed-code';

//Builder
export const CARD_LAYOUT_TEST_ID = 'Card-LayoutRoot';

// Edit step
export const EDIT_CAPTIONS_DATA_TEST_ID = 'edit-captions';
export const SUBTITLES_SECTION_LOADER_DATA_TEST_ID = 'subtitles-section-loader';
export const SUBTITLES_PART_X_DATA_TEST_ID = 'subtitles-part';
export const SUBTITLES_DELETE_CONTAINER_TEST_ID = 'SubtitlesDeleteContainer';

// Quiz
export const QUIZ_LAYOUT_ROOT_DATA_TEST_ID = 'quiz-layout-root';
export const QUIZ_TABLE_RECORD_INPUT_DATA_TEST_ID = 'quiz-table-record-input';

// Save badge
export const SAVE_DATA_TEST_ID = 'save-button';

// approve multi import badge
export const APPROVE_IMPORT_DATA_TEST_ID = 'approve-import-button';

// Creation flow
export const LIBRARY_TAB_TEST_ID = 'LibraryTab';
export const EXIT_MODAL_TEST_ID = 'exitModal';
export const STEP_PART_CONTAINER_TEST_ID = 'stepPartContainer';
export const STOCK_VIDEOS_TAB_TEST_ID = 'stockVideosTab';

// Creation flow - edit step
export const EDIT_BUTTONS_TYPE_TEST_ID = 'EditButtonsType';
export const EDIT_BUTTONS_TEST_ID = 'EditButtons';
export const EDIT_VIDEO_SETTINGS_TEST_ID = 'EditVideoSettings';
export const DELETE_STEP_TEST_ID = 'DeleteStep';
export const CHOICES_CUSTOM_INPUT_TEST_ID = 'ChoicesCustomInput';
export const ADD_STEP_TEST_ID = 'AddStep';
export const ADD_CHOICE_TEST_ID = 'AddChoice';
export const PROJECT_MENU_TEST_ID = 'projectMenu';
export const PROJECT_DUPLICATION_MENU_TEST_ID = 'projectDuplicationMenu';
export const PROJECT_NAME_TEST_ID = 'projectName';
export const ADD_STEP_RIGHT_MENU_TEST_ID = 'addStepRightMenu';

// Dashboard
export const DASHBOARD_BREADCRUMBS_BACK_TEST_ID = 'dashboardBreadcrumbsBack';
export const EMPTY_STATE_TEST_ID = 'emptyState';
export const FOLDERS_FILTER_TEST_ID = 'foldersFilter';
export const NO_VIDEOS_PROJECT_IMAGE_TEST_ID = 'noVideosProjectImage';
export const CREATE_NEW_TOLSTOY_DATA_TEST_ID = 'createNewTolstoy';
export const CREATE_NEW_FEED_DATA_TEST_ID = 'createNewFeed';
export const CREATE_NEW_WIDGET_DATA_TEST_ID = 'createNewBubble';
export const CREATE_NEW_EMBED_DATA_TEST_ID = 'createNewEmbed';
export const CREATE_NEW_STORIES_DATA_TEST_ID = 'createNewStories';
export const LOADING_DATA_TEST_ID = 'loadingDataTestId';

// Share
export const Share_BUTTON_TEST_ID = 'shareButton';

// Publish
export const PUBLISH_BUTTON_TEST_ID = 'publishButton';

// Project menu items
export const DELETE_PROJECT_MENU_ITEM_TEST_ID = 'deleteProjectMenuItem';
export const RENAME_PROJECT_MENU_ITEM_TEST_ID = 'renameProjectMenuItem';

// Inbox
export const CONTACT_CARD_TEST_ID = 'conversationBox';
export const INBOX_TOLSTOY_CONTAINER_TEST_ID = 'InboxTolstoyContainer';
export const INBOX_VIDEO_CONTENT_TEST_ID = 'InvboxVideoContent';
export const INBOX_FILTERS_CONTAINER_TEST_ID = 'InboxFiltersContainer';
export const INBOX_STATUS_CONTAINER_TEST_ID = 'InboxStatusContainer';

// Feeds
export const PRODUCT_SEARCH_INPUT_TEST_ID = 'productSearchInput';
export const PRODUCT_SEARCH_RESULT_TEST_ID = 'productSearchResult';
export const LINK_BUTTON_SWITCH_TEST_ID = 'linkButtonSwitch';
export const FEED_MENU_ITEM_SHOPPABLE_TEST_ID = 'feedMenuItemShoppable';
export const FEED_MENU_ITEM_DESCRIPTION_TEST_ID = 'feedMenuItemDescription';
export const FEED_ACTION_BUTTON_TEXT_INPUT_TEST_ID = 'feedActionButtonTextInput';
export const FEED_ACTION_BUTTON_URL_INPUT_TEST_ID = 'feedActionButtonUrlInput';
export const FEED_DESCRIPTION_TEXTAREA_EDITOR_TEST_ID = 'feedDescriptionTextareaEditor';

// Rename
export const RENAME_MODAL_INPUT_TEST_ID = 'renameModalInput';
export const RENAME_MODAL_SAVE_BUTTON_TEST_ID = 'renameModalSaveButton';

//integrations
export const CONNECT_MAGENTO_CONFIRMATION_DATA_TEST_ID = 'connectToMagentoConfirmationButton';
export const INTEGRATIONS_TAB_DATA_TEST_ID = 'integrationsTab';

//Videos tab
export const VIDEO_CARD_DATA_TEST_ID = 'videoCard';

// Add products modal
export const ADD_PRODUCTS_MODAL_SAVE_DATA_TEST_ID = 'addProductsModalSave';
export const ADD_PRODUCTS_MODAL_REMOVE_PRODUCT_DATA_TEST_ID = 'addProductsModalRemoveProduct';
