import HttpService from 'shared/react/services/httpService';
import APP_CONFIG from 'app/src/config/app.config';

export const getPlayerProducts = async (vodAssetIds, appUrl, appKey) => {
  return HttpService.post(
    `${APP_CONFIG.API_URL}/products/actions/v2/get-by-vod-asset-ids`,
    {},
    JSON.stringify({
      vodAssetIds,
      appUrl,
      isShopifyStore: false,
      currentProductId: '',
      appKey,
    })
  );
};
