import { useState } from 'react';
import { removeFalsy } from 'app/src/context/ui_store/DashboardStore';

const useModalVideoFilters = (defaultVideoFilterProps = {}) => {
  const [videosFilters, setVideosFilters] = useState(defaultVideoFilterProps);

  const onSetVideoFilters = (newVideosFilters, forceReplace) => {
    const setFilter = previousState => {
      const newFilters = forceReplace
        ? { ...newVideosFilters }
        : { ...previousState, ...newVideosFilters };
      removeFalsy(newFilters);
      return newFilters;
    };

    setVideosFilters(setFilter);
  };

  return { videosFilters, setVideosFilters: onSetVideoFilters };
};

export default useModalVideoFilters;
