import { productPageSettingsByAppKey } from 'app/src/graphql/queries';
import { createActionsHook, createContainer, createHook, createStore } from 'react-sweet-state';
import { graphqlRequest } from 'app/src/helpers/API';
import { graphqlOperation } from 'aws-amplify';
import { createProductPageSettings, deleteProductPageSettings } from 'app/src/graphql/mutations';
import { productPageSettings, project as projectEntity } from 'app/src/types/entities';
import { ONSITE_TARGET_PAGES } from 'app/src/pages/dashboard/constants/onsiteTargetPages.constants';

type State = {
  productsSettings: productPageSettings[];
};
const initialState: State = {
  productsSettings: [],
};

const actions = {
  getProductPageSettingsByAppKey:
    () =>
    async ({ setState }, { appKey }) => {
      const response = await graphqlRequest(
        graphqlOperation(productPageSettingsByAppKey, {
          appKey,
        })
      );

      const productsSettings = response.data.productPageSettingsByAppKey.items;
      setState({ productsSettings });
    },
  createProductPageSettings:
    ({ productId, publishId, widgetType, productUrl, productSource }) =>
    async ({ getState, setState }, { appKey }) => {
      const input = { productId, publishId, appKey, widgetType, productSource, productUrl };
      const response = await graphqlRequest(graphqlOperation(createProductPageSettings, { input }));
      const productSettings = response.data.createProductPageSettings;

      setState({ productsSettings: [...getState().productsSettings, productSettings] });
    },
  deleteProductPageSettings:
    input =>
    async ({ getState, setState }) => {
      await graphqlRequest(graphqlOperation(deleteProductPageSettings, { input }));
      const { productsSettings } = getState();
      const newProductsSettings = productsSettings.filter(product => product.id !== input.id);

      setState({ productsSettings: newProductsSettings });
    },
  getProductPageSettingByProductId:
    id =>
    ({ getState }) => {
      const { productsSettings } = getState();
      return productsSettings.filter(({ productId }) => productId === id);
    },
  getIsProductAlreadyPublished: (existingProducts, widgetType) => () => {
    return existingProducts.some(product => product.widgetType === widgetType);
  },
  getProductPageSettingsByPublishId:
    publishId =>
    ({ getState }) => {
      return getState().productsSettings?.filter(productSettings => {
        return productSettings.publishId === publishId;
      });
    },
  isProductPage:
    (project: projectEntity = {} as projectEntity) =>
    ({ dispatch }) => {
      return (
        !!dispatch(actions.getProductPageSettingsByPublishId(project.publishId)).length ||
        project.targetPage === ONSITE_TARGET_PAGES.ProductPages
      );
    },
  clearState:
    () =>
    ({ setState }) => {
      setState(initialState);
    },
};

const ProductPageSettingsStore = createStore({
  initialState,
  actions,
  name: 'ProductPageSettings',
});

type SelectorProps = {
  publishId?: string;
  widgetType?: string;
};

const productsPageSelector = (state, { publishId, widgetType }: SelectorProps) => {
  const products = state.productsSettings?.filter(productSettings => {
    if (publishId && widgetType) {
      return productSettings.publishId === publishId && productSettings.widgetType === widgetType;
    }

    if (publishId) {
      return productSettings.publishId === publishId;
    }
  });

  return {
    ...state,
    products,
  };
};

type Actions = typeof actions;

type SelectorState = State & {
  products: productPageSettings[];
};

export const useProductPageSettingsActions = createActionsHook(ProductPageSettingsStore);

export const useProductPageSettings = createHook<State, Actions, SelectorState, SelectorProps>(
  ProductPageSettingsStore,
  {
    selector: productsPageSelector,
  }
);

export const ProductPageSettingsContainer = createContainer(ProductPageSettingsStore, {
  onInit:
    () =>
    ({ dispatch }) => {
      dispatch(actions.getProductPageSettingsByAppKey());
    },
  onCleanup:
    () =>
    ({ dispatch }) => {
      dispatch(actions.clearState());
    },
});
