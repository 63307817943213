import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useFeedProducts from 'shared/react/components/complex/context/hooks/useFeedProducts';
import Utils from 'shared/react/utils/utils';
import { PLAYER_TYPES } from 'shared/react/constants/tolstoy.constants';
import useTracker from 'shared/react/components/complex/context/hooks/useTracker';
import {
  getVariantSelectedValues,
  getHasOptions,
} from 'shared/react/components/complex/feed/feed_utils/feed.utils';
import FeedProductContent from 'shared/react/components/complex/feed/feed_products/FeedProductContent';
import {
  MULTI_PRODUCT_SECTION_DATA_TYPE,
  PRODUCT_DISCOUNTED_PRICE_DATA_TYPE,
  PRODUCT_HEADER_DATA_TYPE,
  PRODUCT_PRICE_DATA_TYPE,
} from 'shared/react/constants/feedPreviewDataTypes.constants';
import FeedQuickAddButton from 'shared/react/components/complex/feed/feed_products/feed_multiple_products/feed_quick_add_button/FeedQuickAddButton';
import FeedMultipleProductOptions from 'shared/react/components/complex/feed/feed_products/feed_multiple_products/FeedMultipleProductOptions';
import { getNewVariant, getProductOptionsFromVariant } from 'shared/react/utils/feedProducts.utils';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';
import {
  SHOW_FEED_PRODUCT_MODAL_MESSAGE,
  TOLSTOY_MOVE_TO_URL,
} from 'shared/react/constants/messages.constants';
import { useIsMobile } from 'shared/react/hooks/useIsMobile';
import FeedProductImage from '../FeedProductImage';
import HorizontalFlex, {
  Gap16HorizontalFlex,
  Gap8HorizontalFlex,
} from '../../../flex_layouts/HorizontalFlex';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';
import useEcomNavigation from 'shared/react/components/complex/feed/hooks/useEcomNavigation';
import useIsFenty from 'shared/react/hooks/useIsFenty';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import useIsMilk from 'shared/react/hooks/useIsMilk';
import FeedLikeButton from '../../feed_controls/FeedLikeButton';
import useIsPrincessPolly from 'shared/react/hooks/useIsPrincessPolly';
import { getDesignCss } from 'shared/react/utils/addSharedCss';

const getIsProductEmpty = (product = {}) => {
  return !Object.keys(product).length;
};

const FeedMultipleProductDetails = ({
  productId,
  variantId,
  currentStepKey,
  setFocusedProductWithVariant,
  isHorizontal,
  multipleProductsSettings = {},
}) => {
  const isMobile = useIsMobile();
  const [{ isAppPreview, pdpConfig, dynamic: isDynamic }, { getCurrentStep }] = useProjectConfig();
  const [product] = useFeedProducts(productId);
  const [, { track }] = useTracker();
  const [productOptions, setProductOptions] = useState({});
  const [selectedVariant, setSelectedVariant] = useState({});
  const currentStep = getCurrentStep(currentStepKey);
  const [isShowingOptions, setIsShowingOptions] = useState(false);
  const isProductLoaded = !!Object.keys(product).length;
  const { player, feedDesign } = useDesignSettingsContext();
  const { feedImageFitType } = player.feedCards.productDetailsCard.productImages;
  const { moveToPDPOnTitleClick = false } = player.feedCards.productDetailsCard.advanced;
  const { hasQuantityPicker: primaryHasQuantityPicker } =
    player.feedCards.productDetailsCard.primaryButton.settings;

  const hasVariantOptions = getHasOptions(product);
  const { ctaSettings } = multipleProductsSettings;
  const { getPDPUrl } = useEcomNavigation();
  const isFenty = useIsFenty();
  const isMilk = useIsMilk();
  const isPP = useIsPrincessPolly();
  const isMultiVariant = product?.variants?.length > 1;

  const handleProductOptionSelected = (optionPosition, value) => {
    const newOption = productOptions[optionPosition];
    newOption.selectedValue = value;
    track('productOptionSelected', Utils.getAnalyticsLocationObject(PLAYER_TYPES.feed));
    setProductOptions({ ...productOptions, [optionPosition]: newOption });
  };

  const onImageOrTitleClick = e => {
    e?.stopPropagation();
    const pdpUrl = getPDPUrl({ product });
    if (!isProductLoaded || !pdpUrl || !moveToPDPOnTitleClick) {
      handleProductFocused();
      return;
    }

    Utils.postMessageToWindow({
      eventName: TOLSTOY_MOVE_TO_URL,
      url: pdpUrl,
    });
  };

  const handleProductFocused = () => {
    if (!isProductLoaded) {
      return;
    }

    if (!isMobile) {
      Utils.postMessageToWindow({ name: SHOW_FEED_PRODUCT_MODAL_MESSAGE, isOpen: true });
    }

    setFocusedProductWithVariant(product, selectedVariant?.id);
  };

  useEffect(() => {
    if (!isProductLoaded) {
      return;
    }

    const variant = product.variants.find(v => `${v.id}` === `${variantId}`);
    setSelectedVariant(variant || product.variants[0]);
    setProductOptions(getProductOptionsFromVariant(product, variant));
  }, [product]);

  const variantsSelectedValues = getVariantSelectedValues(productOptions);
  useEffect(() => {
    if (!isProductLoaded) {
      return;
    }

    const newVariant = getNewVariant(product, productOptions);
    setSelectedVariant(newVariant);
  }, [variantsSelectedValues]);

  const getQuickAddButtonOnClick = () => {
    if (isAppPreview) {
      return () => {};
    }

    return () => setIsShowingOptions(true);
  };

  const getBuyNowOnClick = () => {
    if (!isHorizontal) {
      return null;
    }

    return handleProductFocused;
  };

  if (getIsProductEmpty(product)) {
    return null;
  }

  const { imageUrl, appUrl, handle: productHandle, ecomPlatform } = product;
  return (
    <LayoutRoot $isFenty={isFenty} data-type={MULTI_PRODUCT_SECTION_DATA_TYPE}>
      <ProductDetailsContainer>
        <ProductDetails>
          <FeedProductImage
            src={Utils.getShopifyImageAndSetHeight(selectedVariant?.imageUrl || imageUrl, 200)}
            imageFit={feedImageFitType}
            onClick={onImageOrTitleClick}
          />
          <ProductDetailsHeader>
            <Gap16VerticalFlex>
              <StyledFeedProductContent
                product={product}
                variantId={variantId}
                selectedVariant={selectedVariant}
                isMultipleProductDetails={true}
                onClick={handleProductFocused}
                onTitleClick={onImageOrTitleClick}
                $isFenty={isFenty}
                $isMilk={isMilk}
                $isPP={isPP}
                $design={feedDesign?.inFeedPDP}
              />
            </Gap16VerticalFlex>
            <FeedLikeButton product={product} vodAssetId={currentStep.videoId} backdrop={false} />
          </ProductDetailsHeader>
        </ProductDetails>
        <FeedQuickAddButton
          onClick={getQuickAddButtonOnClick()}
          hasVariantOptions={hasVariantOptions}
          selectedVariant={selectedVariant}
          appUrl={appUrl}
          currentStepKey={currentStepKey}
          productHandle={productHandle}
          ecomPlatform={ecomPlatform}
          onBuyNowClick={getBuyNowOnClick()}
          productId={productId}
          buttonConfig={pdpConfig?.primaryButton}
          isDynamic={isDynamic}
          handleProductFocused={handleProductFocused}
          isMultiVariant={isMultiVariant}
          ctaSettings={ctaSettings}
          externalProductId={product.id}
          primaryHasQuantityPicker={primaryHasQuantityPicker}
          isMultipleProductDetails={true}
          product={product}
          design={feedDesign?.inFeedPDP?.primaryCtaButton}
        />
        <FeedMultipleProductOptions
          hasOptions={hasVariantOptions}
          isShowingOptions={isShowingOptions}
          setIsShowingOptions={setIsShowingOptions}
          options={productOptions}
          selectedVariant={selectedVariant}
          appUrl={appUrl}
          currentStepKey={currentStepKey}
          handleProductOptionSelected={handleProductOptionSelected}
          productHandle={productHandle}
          ecomPlatform={ecomPlatform}
          productId={productId}
          primaryHasQuantityPicker={primaryHasQuantityPicker}
          design={feedDesign?.inFeedPDP}
        />
      </ProductDetailsContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16HorizontalFlex)`
  flex-wrap: nowrap;
  max-height: ${({ $isFenty }) => ($isFenty ? '144px' : '210px')};
  align-items: flex-start;
  height: 100%;
  flex-shrink: 0;
`;

const ProductDetailsContainer = styled(Gap8VerticalFlex)`
  height: 100%;
  width: 100%;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
`;

const ProductDetails = styled(Gap8HorizontalFlex)`
  height: 100%;
`;

const ProductDetailsHeader = styled(HorizontalFlex)`
  justify-content: space-between;
  width: 100%;
  gap: 12px;
`;

const StyledFeedProductContent = styled(FeedProductContent)`
  text-align: left;

  & ${Utils.getDataType(PRODUCT_HEADER_DATA_TYPE)} {
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 100%;
    ${({ $design }) =>
      getDesignCss({
        ...($design?.productHeader || {}),
        fontSize: $design?.productHeader.fontSize ? $design?.productHeader?.fontSize - 4 : 20,
      })};
    font-size: ${({ $isMilk, $isFenty, $isPP }) =>
      $isPP ? '18px' : $isFenty ? '14px' : $isMilk ? '24px' : ''};
    font-weight: ${({ $isFenty, $isPP }) => ($isPP ? '500' : $isFenty ? '700' : '')};
    line-height: ${({ $isMilk, $isFenty, $isPP }) =>
      $isPP ? '20px' : $isFenty ? '18px' : $isMilk ? '26px' : ''};
  }

  & ${Utils.getDataType(PRODUCT_PRICE_DATA_TYPE)} {
    font-size: 14px;
    font-weight: 600;
    ${({ $design }) => getDesignCss($design?.productPrice)};
    font-size: ${({ $isPP }) => ($isPP ? '16px' : '')};
    font-weight: ${({ $isPP }) => ($isPP ? '500' : '')};
  }

  & ${Utils.getDataType(PRODUCT_DISCOUNTED_PRICE_DATA_TYPE)} {
    font-size: 14px;
    line-height: 26px;
    font-weight: 600;
    ${({ $design }) => getDesignCss($design?.productPrice)};
    ${({ $design }) => getDesignCss($design?.salePrice)};
    font-size: ${({ $isPP }) => ($isPP ? '16px' : '')};
    font-weight: ${({ $isPP }) => ($isPP ? '500' : '')};
  }
`;

export default FeedMultipleProductDetails;
