import { getPlayerFeedCustomStyle } from '../../utils/features.utils';

const head = document.head || document.getElementsByTagName('head')[0];

let initialized = false;

const createStyleTag = customStyle => {
  const style = document.createElement('style');

  style.type = 'text/css';
  style.appendChild(document.createTextNode(customStyle));

  return style;
};
const appendStyleToHead = customStyle => {
  const styleTag = createStyleTag(customStyle);

  head.appendChild(styleTag);
};
const CustomStyle = ({ config }) => {
  const customStyle = getPlayerFeedCustomStyle(config);

  if (!customStyle || initialized) {
    return null;
  }

  appendStyleToHead(customStyle);

  initialized = true;

  return null;
};

export default CustomStyle;
