import React from 'react';
import styled from 'styled-components';
import HorizontalFlex, {
  HorizontalFlexCentered,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TagTabs } from 'app/src/complex_components/global_modal/video/add_products_modal/constants/addProductsModal.constants';
import ShopifySmallIcon from 'app/src/images/integrations/ShopifySmallIcon';
import { TextSmall } from 'shared/react/components/basic/text/TextV2';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import { FEATURE_SHOPIFY_TAGS } from 'shared/react/constants/features.constants';
import { app } from 'src/types/entities';
import { SHOPIFY } from 'src/constants/intergrations.constants';
import { useSelectedProductsContext } from 'app/src/complex_components/global_modal/video/add_products_modal/SelectedProductsContext';

type Props = {
  selectedTab: TagTabs;
  setSelectedTab: (tab: TagTabs) => void;
  selectedApp: app;
};

const AddProductModalTabs = ({ selectedTab, setSelectedTab, selectedApp }: Props) => {
  const { getFeatureEnabled } = useFeatureActions();
  const [{ selectedProductsWithVariants, selectedTags }] = useSelectedProductsContext();
  const shopifyTagsEnabled = getFeatureEnabled(FEATURE_SHOPIFY_TAGS);

  const tabs = [
    {
      title: 'Products',
      number: selectedProductsWithVariants.length,
      isSelected: selectedTab === TagTabs.Products,
      onClick: () => setSelectedTab(TagTabs.Products),
    },
    {
      title: (
        <Header isSelected={selectedTab === TagTabs.Tags}>
          <ShopifySmallIcon /> Tags
        </Header>
      ),
      number: selectedTags.length,
      isSelected: selectedTab === TagTabs.Tags,
      onClick: () => setSelectedTab(TagTabs.Tags),
      isHidden: !shopifyTagsEnabled || selectedApp?.app !== SHOPIFY,
    },
  ];

  const currentTabs = tabs.filter(tab => !tab.isHidden);

  if (currentTabs.length === 1) {
    return null;
  }

  return (
    <LayoutRoot>
      {currentTabs.map(({ title, number, isSelected, onClick }, i) => {
        return (
          <Tab isSelected={isSelected} onClick={onClick} key={i}>
            <Header isSelected={isSelected}>{title}</Header>
            <NumberContainer>
              <Header isSelected={isSelected}>{number}</Header>
            </NumberContainer>
          </Tab>
        );
      })}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutralLight};
  margin: 0 24px;
  gap: 24px;

  && {
    padding: 0;
  }
`;

const Header = styled(TextSmall)<{ isSelected: boolean }>`
  line-height: 14px;
  display: flex;
  gap: 6px;
  color: ${({ theme, isSelected }) => (isSelected ? theme.colors.black : theme.colors.neutralDark)};

  path {
    fill: ${({ theme, isSelected }) =>
      isSelected ? theme.colors.black : theme.colors.neutralDark};
  }
`;

const Tab = styled(HorizontalFlex)`
  gap: 6px;
  align-items: center;
  border-bottom: 2px solid
    ${({ theme, isSelected }) => (isSelected ? theme.colors.black : 'transparent')};
  padding: 0 0 8px;
  cursor: pointer;
`;

const NumberContainer = styled(HorizontalFlexCentered)`
  border-radius: 16px;
  padding: 2px 8px;
  background: ${({ theme }) => theme.colors.neutralLighter};
`;

export default AddProductModalTabs;
