import EditorNumericInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorNumericInputItem';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import {
  HERO_DESKTOP_ALIGNMENT,
  HERO_MOBILE_ALIGNMENT,
  STORIES_ALIGNMENTS,
} from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import EditorMainContainer from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorMainContainer';
import { AlignLeftIcon, AlignCenterIcon, AlignRightIcon } from 'lucide-react';
import React from 'react';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import EditorButtonGroupItem from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorButtonGroupItem';
import EditorHeader from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorHeader';
import EditorItem from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorItem';
import EditorOnOffToggleItem from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorOnOffToggleItem';
import EditorSelect from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorSelect';
import styled from 'styled-components';

const POSITIONS = [
  'Top Left',
  'Top Center',
  'Top Right',
  'Middle Left',
  'Middle Center',
  'Middle Right',
  'Bottom Left',
  'Bottom Center',
  'Bottom Right',
];
const HeroOverlayLayoutEditor = () => {
  const {
    customizationSettings: {
      heroSettings: { layoutSettings },
    },
    setHeroSettingsProperty,
  } = useLookAndFeelContext();
  const {
    desktopAlignment,
    mobileAlignment,
    desktopPosition,
    mobilePosition,
    stackButtonsOnMobile,
    hideTextAndButtonsOnMobile,
    buttonSpacing,
    verticalSpacing,
  } = layoutSettings || {};

  const setLayoutSettings = (key: string, value: string | number) => {
    setHeroSettingsProperty('layoutSettings', { ...layoutSettings, [key]: value });
  };

  const onLayoutDesktopPostionChange = (value: { id: string }) => {
    setLayoutSettings('desktopPosition', value.id);
  };

  const onLayoutMobilePostionChange = (value: { id: string }) => {
    setLayoutSettings('mobilePosition', value.id);
  };

  const desktopContentAlignment = [
    {
      icon: <AlignLeftIcon />,
      isEnabled: desktopAlignment === STORIES_ALIGNMENTS.left,
      onClick: () => setLayoutSettings(HERO_DESKTOP_ALIGNMENT, STORIES_ALIGNMENTS.left),
    },
    {
      icon: <AlignCenterIcon />,
      isEnabled: desktopAlignment === STORIES_ALIGNMENTS.center,
      onClick: () => setLayoutSettings(HERO_DESKTOP_ALIGNMENT, STORIES_ALIGNMENTS.center),
    },
    {
      icon: <AlignRightIcon />,
      isEnabled: desktopAlignment === STORIES_ALIGNMENTS.right,
      onClick: () => setLayoutSettings(HERO_DESKTOP_ALIGNMENT, STORIES_ALIGNMENTS.right),
    },
  ];

  const mobileContentAlignment = [
    {
      icon: <AlignLeftIcon />,
      isEnabled: mobileAlignment === STORIES_ALIGNMENTS.left,
      onClick: () => setLayoutSettings(HERO_MOBILE_ALIGNMENT, STORIES_ALIGNMENTS.left),
    },
    {
      icon: <AlignCenterIcon />,
      isEnabled: mobileAlignment === STORIES_ALIGNMENTS.center,
      onClick: () => setLayoutSettings(HERO_MOBILE_ALIGNMENT, STORIES_ALIGNMENTS.center),
    },
    {
      icon: <AlignRightIcon />,
      isEnabled: mobileAlignment === STORIES_ALIGNMENTS.right,
      onClick: () => setLayoutSettings(HERO_MOBILE_ALIGNMENT, STORIES_ALIGNMENTS.right),
    },
  ];

  const positionOptions = POSITIONS.map(position => ({
    name: position,
    id: position,
  }));

  return (
    <LayoutRoot>
      <EditorHeader title="Layout" />

      <EditorMainContainer>
        <SmallerEditorSelect
          onChange={onLayoutDesktopPostionChange}
          text="Desktop position"
          value={desktopPosition}
          items={positionOptions}
        />

        <EditorItem text="Desktop alignment">
          <EditorButtonGroupItem buttons={desktopContentAlignment} />
        </EditorItem>

        <EditorNumericInputItem
          text="Button spacing"
          suffix="px"
          onChange={value => setLayoutSettings('buttonSpacing', value)}
          value={buttonSpacing}
          min={12}
          max={32}
        />
        <EditorNumericInputItem
          text="Vertical spacing"
          suffix="px"
          onChange={value => setLayoutSettings('verticalSpacing', value)}
          value={verticalSpacing}
          min={12}
          max={32}
        />
        <SmallerEditorSelect
          onChange={onLayoutMobilePostionChange}
          text="Mobile position"
          value={mobilePosition}
          items={positionOptions}
        />
        <EditorItem text="Mobile alignment">
          <EditorButtonGroupItem buttons={mobileContentAlignment} />
        </EditorItem>
        <EditorOnOffToggleItem
          text="Stack buttons on mobile"
          checked={!!stackButtonsOnMobile}
          onChange={value => setLayoutSettings('stackButtonsOnMobile', value)}
        />
        <EditorOnOffToggleItem
          text="Hide text and buttons on mobile"
          checked={!!hideTextAndButtonsOnMobile}
          onChange={value => setLayoutSettings('hideTextAndButtonsOnMobile', value)}
        />
      </EditorMainContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

const SmallerEditorSelect = styled(EditorSelect)`
  max-width: 170px;
`;

export default HeroOverlayLayoutEditor;
