import {
  AUTOMATION_TOOL_ACTIVECAMPAIGN,
  AUTOMATION_TOOL_EMARSYS,
  AUTOMATION_TOOL_HUBSPOT,
  AUTOMATION_TOOL_KLAVIYO,
  AUTOMATION_TOOL_MAILCHIMP,
  AUTOMATION_TOOL_OMNISEND,
  AUTOMATION_TOOL_SAILTHRU,
  AUTOMATION_TOOL_SALESFORCE,
  AUTOMATION_TOOL_SALESLOFT,
  AUTOMATION_TOOL_SHOPIFY,
  AUTOMATION_TOOL_YOTPO,
} from 'app/src/constants/share.constants';

const toOneLine = (str: string, separator = '') => str.replace(/\n+\s*/g, separator).trim();

export const getAutomationToolEmailVariable = (automationTool: string) => {
  switch (automationTool) {
    case AUTOMATION_TOOL_SALESLOFT:
      return '{{email_address}}';
    case AUTOMATION_TOOL_KLAVIYO:
      return '{{ email }}';
    case AUTOMATION_TOOL_HUBSPOT:
      return '{{ contact.email }}';
    case AUTOMATION_TOOL_MAILCHIMP:
      return '*|EMAIL|*';
    case AUTOMATION_TOOL_OMNISEND:
      return '[[contact.email]]';
    case AUTOMATION_TOOL_SALESFORCE:
      return '{{Recipient.Email}}';
    case AUTOMATION_TOOL_SHOPIFY:
      return '{{ data.customer.email }}';
    case AUTOMATION_TOOL_YOTPO:
      return '{{profile.email}}';
    case AUTOMATION_TOOL_ACTIVECAMPAIGN:
      return '%EMAIL%';
    case AUTOMATION_TOOL_EMARSYS:
      return '{{ contact.3 }}';
    case AUTOMATION_TOOL_SAILTHRU:
      return '{email}';
    default:
      return '{{email}}';
  }
};

export const getAutomationToolProductIdVariable = (automationTool: string) => {
  switch (automationTool) {
    case AUTOMATION_TOOL_KLAVIYO:
      return `{% firstof event.items.0.product_id event.extra.line_items.0.product_id event.ProductID event|lookup:'Product ID' %}`;
    case AUTOMATION_TOOL_OMNISEND:
      return toOneLine(`
      [% if event.raw.ProductID != blank %]
      [[event.raw.ProductID]]
      [% elsif event.raw.items[0].product_id != blank %]
      [[event.raw.items[0].product_id]]
      [% elsif event.added_item.product_id != blank %]
      [[event.added_item.product_id]]
      [% else %]
      [[event.raw.line_items[0].product_id]]
      [% endif %]`);
    default:
      return undefined;
  }
};
