import React, { useRef } from 'react';
import styled from 'styled-components';
import InstagramNameIcon from 'app/src/images/InstagramNameIcon';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextH6 } from 'shared/react/components/complex/Text';
import InstagramImportFromAccount from 'app/src/pages/creation_flow/right_section/import_from_instagram/InstagramImportFromAccount';
import { useInstagram } from 'app/src/context/InstagramStore';
import { useInfiniteScroll } from 'app/src/hooks/useInfiniteScroll';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import useIntegrationsVideos from 'app/src/hooks/useIntegrationsVideos';

const ImportFromInstagram = ({ isLoading }) => {
  const [{ cursor }, { fetchByAccount }] = useInstagram();
  const { instagram } = useIntegrationsVideos();
  const containerRef = useRef(null);

  const fetchMoreVideos = async () => {
    if (cursor) {
      await fetchByAccount(instagram);
    }
  };
  useInfiniteScroll(containerRef, fetchMoreVideos);

  return (
    <LayoutRoot ref={containerRef}>
      <Container>
        <Header>
          <StyledTextH6>Import from</StyledTextH6>
          <InstagramNameIcon />
        </Header>
        <InstagramImportFromAccount isLoading={isLoading} />
      </Container>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  padding: 40px;
`;

const Container = styled(VerticalFlex)`
  gap: 46px;
  width: 688px;
  align-items: center;
`;

const Header = styled(Gap8HorizontalFlex)``;

const StyledTextH6 = styled(TextH6)`
  line-height: 24px;
`;

export default ImportFromInstagram;
