import React from 'react';
import styled from 'styled-components';
import Utils from '../../utils/utils';
import HorizontalFlexWrap from './flex_layouts/HorizontalFlexWrap';
import SocialButton from './SocialButton';

function SocialShareButtons({ shareButtons, canShareLink, withLoading }) {
  const openLink = link => {
    if (canShareLink && !canShareLink()) {
      return;
    }

    Utils.openInNewTab(link);
  };

  const handleSocialButtonClick = async getUrl => {
    const url = await getUrl();
    openLink(url);
  };

  return (
    <LayoutRoot>
      {shareButtons.map(({ icon, getUrl }, i) => (
        <SocialButton
          key={i}
          withLoading={withLoading}
          onClick={() => handleSocialButtonClick(getUrl)}
        >
          {icon}
        </SocialButton>
      ))}
    </LayoutRoot>
  );
}

export default SocialShareButtons;

const LayoutRoot = styled(HorizontalFlexWrap)`
  gap: 24px;
  margin-top: 8px;
`;
