import React from 'react';
import Share from 'app/src/pages/share/Share';
import StandardModal from 'app/src/pages/modals/StandardModal';
import { SHARE } from 'app/src/constants/searchParams.constants';
import { track } from 'app/src/helpers/Tracker';
import { navigateToShareType } from 'app/src/utils/navigation.utils';
import { useShareTypes } from 'app/src/context/ui_store/ShareTypesStore';
import { useProjects } from 'app/src/context/ProjectsStore';
import { useHistory } from 'react-router-dom';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { useIsMobile } from 'shared/react/hooks/useIsMobile';

const ShareModal = () => {
  const [{ lastUsedShareType }, { setLastUsedShareTypes }] = useShareTypes();
  const [{ currentProjectId }, { setCurrentModal }] = useModal();
  const [{ project }] = useProjects({ projectId: currentProjectId });
  const urlParams = new URLSearchParams(window.location.search);
  const shareType = urlParams.get(SHARE);
  const history = useHistory();
  const isMobile = useIsMobile();

  const handleModalClose = () => {
    setCurrentModal('');
    history.replace({
      pathname: window.location.pathname,
    });
  };

  const handleModalTabChanged = tab => {
    setLastUsedShareTypes({ lastUsedShareType: tab.key });
    track(`Share ${tab.name} Tab Click`);
    navigateToShareType(history, tab.key, currentProjectId);
  };

  return (
    <StandardModal
      open={shareType && true}
      maxWidth="980px"
      height={isMobile ? null : '530px'}
      onClose={handleModalClose}
      removeDefaultPadding
    >
      <Share
        selectedTabKey={lastUsedShareType || shareType}
        project={project}
        onTabChanged={handleModalTabChanged}
      />
    </StandardModal>
  );
};

export default ShareModal;
