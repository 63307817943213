import React, { useEffect, useRef, useState } from 'react';
import FoldersV2 from 'app/src/pages/dashboard/pages/libraries/folders_v2/FoldersV2';
import TolstoysLibrary from 'app/src/pages/dashboard/pages/libraries/tolstoys_tab/TolstoysLibrary';
import useProjectFilter from 'app/src/pages/modals/folder_modal/useProjectFilter';
import { useProjects } from 'app/src/context/ProjectsStore';
import { useFolders } from 'app/src/context/FoldersStore';
import { useDashboard } from 'app/src/context/ui_store/DashboardStore';
import styled from 'styled-components';
import ProjectFilters from 'app/src/pages/dashboard/pages/libraries/project_filters/ProjectFilters';
import SortV2 from 'app/src/pages/dashboard/pages/libraries/sort_v2/SortV2';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import SearchBarV2 from 'app/src/pages/dashboard/pages/libraries/dashboard_toolbar/SearchBarV2';
import { DEFAULT_NUMBER_OF_CARDS_TO_SHOW } from 'app/src/constants/ui.constants';
import {
  FEATURE_DASHBOARD_V2_NEW_FOLDERS,
  FEATURE_REMOVE_FOLDERS_DESIGN,
} from 'app/src/constants/appFeatures.constants';
import { useInfiniteScroll } from 'app/src/hooks/useInfiniteScroll';
import TolstoysTabEmptyState from 'app/src/pages/dashboard/pages/libraries/tolstoys_tab/empty_state/TolstoysTabEmptyState';
import { useFeatures } from 'app/src/context/FeaturesStore';
import TolstoysBadge from 'app/src/pages/dashboard/pages/libraries/tolstoys_tab/TolstoysBadge';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';

const TolstoysTab = ({
  searchValue,
  setSearchValue,
  debouncedSearchValue,
  disableExtraTolstoyClick,
  onProjectClick,
  isModalMode,
}) => {
  const [{ projects, loading: projectsLoading }, { getDisplayedProjects }] = useProjects();
  const [{ loading: foldersLoading }, { getDisplayedFoldersV2 }] = useFolders();
  const [
    {
      sortBy,
      ascending,
      projectFilters,
      selectedTeamMember,
      selectedFolder,
      selectedPublishMethod,
    },
    { setProjectFilters, resetProjectFilters, setSelectedTeamMember, setSelectedFolder },
  ] = useDashboard();
  const [shownCardsNumber, setShownCardsNumber] = useState(DEFAULT_NUMBER_OF_CARDS_TO_SHOW);
  const [, { getFeatureEnabled }] = useFeatures();
  const libraryRef = useRef();
  const isNewFolderView = getFeatureEnabled(FEATURE_DASHBOARD_V2_NEW_FOLDERS);
  const noFolders = getFeatureEnabled(FEATURE_REMOVE_FOLDERS_DESIGN);
  const [displayedProjects, setDisplayedProjects] = useState([]);
  const [selectedTolstoys, setSelectedTolstoys] = useState([]);

  const { folderMapping, projectsWithoutFolders, folders } = useProjectFilter(
    getDisplayedFoldersV2(),
    displayedProjects,
    debouncedSearchValue,
    sortBy,
    ascending,
    projectFilters,
    selectedFolder?.id,
    noFolders,
    selectedPublishMethod
  );

  const getShownProjects = () => {
    if (selectedFolder?.id !== null) {
      return folderMapping[selectedFolder.id];
    }

    return projectsWithoutFolders;
  };

  const onCancel = () => {
    setSelectedTolstoys([]);
  };

  const onChange = e => {
    setSearchValue(e.target.value);
  };

  const clearValue = () => {
    setSearchValue('');
  };

  const fetchMore = () => {
    if (shownCardsNumber >= getShownProjects().length) {
      setDoneScroll();
    }

    setShownCardsNumber(shownCardsNumber + DEFAULT_NUMBER_OF_CARDS_TO_SHOW);
  };

  const { setDoneScroll } = useInfiniteScroll(libraryRef, fetchMore);

  useEffect(() => {
    if (projectsLoading) {
      return;
    }

    setDisplayedProjects(getDisplayedProjects());
  }, [projectsLoading, projects]);

  useEffect(() => {
    setShownCardsNumber(DEFAULT_NUMBER_OF_CARDS_TO_SHOW);
    setDoneScroll(false);
  }, [projectFilters, searchValue]);

  useEffect(() => {
    setSelectedTolstoys([]);
  }, [displayedProjects]);

  const shouldShowEmptyState = !displayedProjects?.length && !folders?.length && !projectsLoading;
  if (shouldShowEmptyState) {
    return <TolstoysTabEmptyState />;
  }

  const isFolderView = !!selectedFolder;
  const shouldShowEmptyStateBody = !projectsLoading && !displayedProjects?.length;
  return (
    <>
      <LayoutRoot isModalMode={isModalMode}>
        <SettingsContainer>
          <ProjectFilters
            selectedFolder={selectedFolder}
            folders={getDisplayedFoldersV2()}
            projectFilters={projectFilters}
            setProjectFilters={setProjectFilters}
            resetProjectFilters={resetProjectFilters}
            selectedTeamMember={selectedTeamMember}
            setSelectedTeamMember={setSelectedTeamMember}
            selectedPublishMethod={selectedPublishMethod}
          />
          <SortSearchContainer>
            <SearchBarV2 value={searchValue} onChange={onChange} clearValue={clearValue} />
            <SortV2 />
          </SortSearchContainer>
        </SettingsContainer>
        <FoldersProjectsContainer ref={libraryRef}>
          {!noFolders && !isFolderView && !isNewFolderView && (
            <FoldersV2
              folders={folders}
              folderMapping={folderMapping}
              loading={foldersLoading}
              setSelectedFolder={setSelectedFolder}
              searchValue={searchValue}
            />
          )}

          <TolstoysLibrary
            projects={getShownProjects()}
            shownCardsNumber={shownCardsNumber}
            shouldShowEmptyStateBody={shouldShowEmptyStateBody}
            folders={folders}
            folderMapping={folderMapping}
            setSelectedTolstoys={setSelectedTolstoys}
            selectedTolstoys={selectedTolstoys}
            searchValue={searchValue}
            disableExtraTolstoyClick={disableExtraTolstoyClick}
            onProjectClick={onProjectClick}
            isModalMode={isModalMode}
          />
        </FoldersProjectsContainer>
      </LayoutRoot>
      <TolstoysBadge
        selectedTolstoys={selectedTolstoys}
        onCancel={onCancel}
        projects={displayedProjects}
      />
    </>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)`
  overflow: hidden;
  padding: ${({ isModalMode }) => (isModalMode ? '16px 0 0 16px' : '0')};
`;

const SettingsContainer = styled(HorizontalFlexWrap)`
  justify-content: space-between;
  padding: 0 24px 0 0;
  align-items: center;
  gap: 28px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    flex-wrap: nowrap;
    flex-direction: column;
    padding: 0;
    gap: 16px;
  }
`;

const SortSearchContainer = styled(Gap8HorizontalFlexWrap)`
  flex-wrap: nowrap;
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 100%;
  }
`;

const FoldersProjectsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  overflow-y: auto;
`;

export default TolstoysTab;
