import { css } from 'styled-components';
import Utils from 'shared/react/utils/utils';
import {
  FEED_BUTTON_TEXT_DATA_TYPE,
  FEED_QUICK_ADD_BUTTON_CONTENT_DATA_TYPE,
  PRODUCT_DETAILS_DATA_TYPE,
  PRODUCT_HEADER_DATA_TYPE,
  PRODUCT_PRICE_DATA_TYPE,
  PRODUCT_DISCOUNTED_PRICE_DATA_TYPE,
} from 'shared/react/constants/feedPreviewDataTypes.constants';

const FeedProductContentCss = css`
  & ${Utils.getDataType(PRODUCT_HEADER_DATA_TYPE)} {
    line-height: 16px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  & ${Utils.getDataType(PRODUCT_PRICE_DATA_TYPE)} {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
  }

  & ${Utils.getDataType(PRODUCT_DISCOUNTED_PRICE_DATA_TYPE)} {
    font-size: 22px;
    line-height: 26px;
  }

  & ${Utils.getDataType(PRODUCT_DETAILS_DATA_TYPE)} {
    gap: 4px;
  }
`;

const FeedButtonCss = css`
  & ${Utils.getDataType(FEED_BUTTON_TEXT_DATA_TYPE)} {
    font-size: 10px;
    line-height: 10px;
    white-space: nowrap;
  }
`;

const FeedQuickAddButtonContentCss = css`
  & ${Utils.getDataType(FEED_QUICK_ADD_BUTTON_CONTENT_DATA_TYPE)} {
    gap: 4px;
  }
`;

export default css`
  ${FeedProductContentCss};
  ${FeedButtonCss};
  ${FeedQuickAddButtonContentCss};
`;
