import React from 'react';
import { PagePanel } from 'app/src/pages/dashboard/components/page/Page';
import useActions from 'app/src/pages/dashboard/components/filters/hooks/useActions';
import { WhereCondition, ComparatorEnum } from 'app/src/pages/dashboard/utils/filterEntities';
import {
  SidebarItem,
  SidebarExpander,
  SidebarFolderItems,
} from 'src/pages/dashboard/components/sidebar';
import { useSidebarFolderItems } from 'src/pages/dashboard/hooks/sidebar';

const Sidebar = () => {
  const {
    folders,
    totalProjects,
    foldersMapping,
    selectedFolderId,
    onRename,
    onDelete,
    resetFolder,
    createNewFolder,
    setSelectedFolder,
  } = useSidebarFolderItems({ isOffsite: true });

  return (
    <PagePanel>
      <SidebarItem
        name="All"
        count={totalProjects}
        isSelected={!selectedFolderId}
        onClick={resetFolder}
        isTopLevel
      />
      <SidebarExpander
        title="Folders"
        onPlusClick={createNewFolder}
        withPlustButton
        isDefaultExpanded
      >
        <SidebarFolderItems
          folders={folders}
          foldersMapping={foldersMapping}
          selectedFolderId={selectedFolderId}
          onClick={setSelectedFolder}
          onEdit={onRename}
          onDelete={onDelete}
        />
      </SidebarExpander>
    </PagePanel>
  );
};

export default Sidebar;
