import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import {
  ANSWER_NEXT_WITH_VALUE,
  ANSWER_TYPES_WITH_VALUE,
  AUDIO_RESPONSE_KEY,
  CALENDLY_RESPONSE_KEY,
  CHAT_RESPONSE_KEY,
  FREE_TEXT_RESPONSE_KEY,
  IMAGE_RESPONSE_KEY,
  LEAD_FORM_RESPONSE_KEY,
  LINK_NEXT_KEY,
  PROMO_CODE_RESPONSE_KEY,
  VIDEO_RESPONSE_KEY,
} from 'app/src/constants/tolstoy.constants';
import LeadFormAnswerIcon from 'app/src/images/GoToLeadFormIcon';
import FreeTextAnswerIcon from 'app/src/images/GoToFreeTextIcon';
import AudioResponseAnswerIcon from 'app/src/images/AudioResponseAnswerIcon';
import ImageResponseAnswerIcon from 'app/src/images/ImageResponseAnswerIcon';
import CalendlyIcon from 'app/src/images/calendars/CalendlyIcon';
import PromoCodeIcon from 'app/src/images/PromoCodeIcon';
import FilledVideoIcon from 'app/src/images/video_icon/FilledVideoIcon';
import Menu from 'shared/react/components/basic/Menu';
import Separator from 'shared/react/components/basic/Separator';
import MenuItemV2 from 'app/src/complex_components/MenuItemV2';
import OtherMenuItem from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/OtherMenuItem';
import { INPUT_METHODS } from 'app/src/constants/creation_method.constants';
import { useUser } from 'app/src/context/userStore/UserStore';
import XIcon from 'app/src/images/XIcon';

const AnswerTypeMenu = ({ anchorEl, setAnchorEl, onModalPropertyChanged, answer }) => {
  const [error, setError] = useState(false);
  const theme = useTheme();
  const [{ user }] = useUser();
  const disabled = ANSWER_NEXT_WITH_VALUE.includes(answer?.next);
  const names = { [CHAT_RESPONSE_KEY]: 'Chat', [LINK_NEXT_KEY]: 'Link' };
  const firstCalendar = user?.calendars?.[0];

  const canCloseMenu = () => {
    const cantClose = ANSWER_TYPES_WITH_VALUE.includes(answer.type) && !answer.value;
    if (cantClose) {
      setError(true);
    }

    return cantClose;
  };

  const getOptions = () => {
    const options = [];

    if (firstCalendar) {
      options.push({
        value: firstCalendar.url,
        label: firstCalendar.name,
      });
    }

    options.push({
      label: 'Other',
      value: answer?.value === firstCalendar?.url ? '' : answer?.value,
      type: INPUT_METHODS.text,
    });

    return options;
  };

  const menuItems = [
    {
      key: FREE_TEXT_RESPONSE_KEY,
      title: 'Free text',
      icon: <FreeTextAnswerIcon />,
    },

    {
      key: VIDEO_RESPONSE_KEY,
      title: 'Video response',
      icon: <FilledVideoIcon />,
    },
    {
      key: AUDIO_RESPONSE_KEY,
      title: 'Audio response',
      icon: <AudioResponseAnswerIcon />,
    },
    {
      key: CALENDLY_RESPONSE_KEY,
      title: 'Calendar',
      icon: <CalendlyIcon />,
      inputType: firstCalendar ? INPUT_METHODS.radio : INPUT_METHODS.text,
      closeAfterClick: false,
      inputPlaceHolder: 'Paste link',
      disabled,
      disabledTooltipText: `Calendar cannot be used while using ${names[answer?.next]}.`,
      options: getOptions(),
    },
    {
      key: LEAD_FORM_RESPONSE_KEY,
      title: 'Contact form',
      icon: <LeadFormAnswerIcon />,
    },
  ];

  const otherMenuItems = [
    {
      key: IMAGE_RESPONSE_KEY,
      title: 'Image response',
      tooltipTitle: 'Supported file types: JPG, PNG, PDF',
      icon: <ImageResponseAnswerIcon />,
    },
    {
      key: PROMO_CODE_RESPONSE_KEY,
      title: 'Promo code',
      icon: <PromoCodeIcon />,
      inputType: INPUT_METHODS.text,
      closeAfterClick: false,
      inputPlaceHolder: 'Paste code',
      disabled,
      disabledTooltipText: `Promo code cannot be used while using ${names[answer?.next]}.`,
    },
  ];

  const onAnswerTypeClick = (key, closeAfterClick) => {
    setError(false);
    if (answer.type === key) {
      onModalPropertyChanged('', 'type');
    } else {
      onModalPropertyChanged(key, 'type');
      if (key === CALENDLY_RESPONSE_KEY) {
        onModalPropertyChanged(firstCalendar?.url, 'value');
        return;
      }
    }

    if (closeAfterClick) {
      onClose();
    }
  };

  const onAnswerValueChange = value => {
    setError(false);
    onModalPropertyChanged(value, 'value');
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  if (!anchorEl) {
    return null;
  }

  return (
    <Menu
      closeOnEnter={true}
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={onClose}
      canCloseMenu={canCloseMenu}
    >
      <Content>
        <Header>Collect viewer info</Header>
        <Separator />
        {menuItems.map(({ key, ...props }) => {
          const isSelected = answer?.type === key;
          return (
            <MenuItemV2
              onClick={closeAfterClick => onAnswerTypeClick(key, closeAfterClick)}
              key={key}
              isSelected={isSelected}
              value={answer.value}
              onAnswerValueChange={onAnswerValueChange}
              error={error}
              {...props}
            />
          );
        })}
        <Separator />
        <OtherMenuItem
          otherMenuItems={otherMenuItems}
          answer={answer}
          anchorEl={anchorEl}
          value={answer.value}
          onAnswerTypeClick={onAnswerTypeClick}
          onAnswerValueChange={onAnswerValueChange}
          error={error}
        />
        {answer.type && (
          <ClearContainer>
            <Separator />
            <MenuItemV2
              key=""
              title="Clear"
              icon={<XIcon size={16} fill={theme.colors.warning} />}
              onClick={() => onAnswerTypeClick('', true)}
            />
          </ClearContainer>
        )}
      </Content>
    </Menu>
  );
};

const Content = styled.div`
  min-width: 256px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  box-shadow: 0px 15px 35px rgba(50, 50, 93, 0.1), 0px 5px 15px rgba(0, 0, 0, 0.07);
  position: relative;
  border-radius: 12px;
  & path {
    fill: ${({ theme }) => theme.colors.ghostDark};
  }
`;

const Header = styled.div`
  color: ${({ theme }) => theme.colors.gray25};
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding: 20px 0 8px 20px;
`;

const ClearContainer = styled.div`
  & path {
    fill: ${({ theme }) => theme.colors.warning};
  }
`;

export default AnswerTypeMenu;
