import React from 'react';
import styled from 'styled-components';
import IosSwitch from 'app/src/basic_components/IosSwitch';
import { useRulesContext } from 'app/src/pages/project/pages/rules_and_triggers/RulesContext';
import {
  ALL,
  DESKTOP,
  MOBILE,
} from 'app/src/pages/project/pages/rules_and_triggers/Rule.constants';
import Utils from 'shared/react/utils/utils';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import { TextSmall } from 'shared/react/components/complex/Text';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

function RulesDevicesEditor() {
  const { onDeviceShowOnChange, showOnDevice } = useRulesContext();

  const isMobileEnabled = showOnDevice === MOBILE;
  const isDesktopEnabled = showOnDevice === DESKTOP;
  const isBothEnabled = showOnDevice === ALL || Utils.isNullOrUndefined(showOnDevice);

  return (
    <LayoutRoot>
      <ButtonContainer>
        <IosSwitch
          checked={isMobileEnabled || isBothEnabled}
          onChange={() => onDeviceShowOnChange(MOBILE)}
        />
        <TextSmall>Display on mobile</TextSmall>
      </ButtonContainer>
      <ButtonContainer>
        <IosSwitch
          checked={isDesktopEnabled || isBothEnabled}
          onChange={() => onDeviceShowOnChange(DESKTOP)}
        />
        <TextSmall>Display on desktop</TextSmall>
      </ButtonContainer>
    </LayoutRoot>
  );
}

export default RulesDevicesEditor;

const LayoutRoot = styled(VerticalFlex)`
  gap: 38px;

  padding: 8px 0 0px;
`;

const ButtonContainer = styled(Gap8HorizontalFlexWrap)`
  align-items: center;
`;
