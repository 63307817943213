import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import HubspotField from 'app/src/pages/modals/hubspot_mapping/hubspot_table/HubspotField';
import HubspotAddField from 'app/src/pages/modals/hubspot_mapping/hubspot_table/HubspotAddField';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { useProjects } from 'app/src/context/ProjectsStore';
import InvisibleDiv from 'app/src/basic_components/InvisibleDiv';

const HubspotFields = ({ addingField, onAddField, fields, onRemoveField, onToggleDefault }) => {
  const [{ currentProjectId: projectId }] = useModal();
  const [{ project }] = useProjects({ projectId });
  const ref = useRef();

  const projectFields = useMemo(() => {
    const options = [];

    if (!project) {
      return options;
    }

    if (project?.collectFields?.length) {
      project.collectFields.forEach(field => {
        if (field.name === 'consent') {
          return;
        }

        options.push({
          id: field.name,
          step: 'contactForm',
          name: `${field.name} - Contact form`,
        });
      });
    }

    project?.steps?.items.forEach((step, i) => {
      const responses = { Input: 'Text', Video: 'Video', Audio: 'Audio', Image: 'Image' };
      const partIndex = i + 1;

      if (step.defaultResponsesEnabled) {
        step.defaultResponses.forEach(answer => {
          options.push({
            answer: answer.key,
            id: answer.type,
            step: step.name,
            name: `Part ${partIndex} - ${answer.text} - ${responses[answer.type]} Response`,
          });
        });
        return;
      }

      const stepField = {
        id: step.name,
        step: step.name,
        name: `Button Click - Part ${partIndex}`,
      };

      step.answers.forEach(answer => {
        if (Object.keys(responses).includes(answer.type)) {
          options.push({
            answer: answer.key,
            id: answer.type,
            step: step.name,
            name: `Part ${partIndex} - ${answer.text} - ${responses[answer.type]} Response`,
          });
        }
      });

      options.push(stepField);
    });

    return options;
  }, [project, fields]);

  useEffect(() => {
    if (addingField) {
      ref.current?.scrollIntoView();
    }
  }, [addingField]);

  return (
    <LayoutRoot>
      {fields.map(field => {
        return (
          <HubspotField
            projectFields={projectFields}
            key={field.hubspotField}
            field={field}
            onRemoveField={onRemoveField}
            onToggleDefault={onToggleDefault}
          />
        );
      })}
      {addingField && (
        <HubspotAddField fields={fields} projectFields={projectFields} onAddField={onAddField} />
      )}
      <InvisibleDiv ref={ref} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

export default HubspotFields;
