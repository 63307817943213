import React, { ReactElement } from 'react';
import useNavigation from 'app/src/hooks/useNavigation';
import OutlinedSettingsIcon from 'app/src/images/sidebar-v2/account-menu/OutlinedSettingsIcon';
import IntegrationsOutlinedIcon from 'app/src/images/sidebar-v2/account-menu/IntegrationsOutlinedIcon';
import HelpIcon from 'app/src/images/sidebar-v2/account-menu/HelpIcon';
import CircleHelpIcon from 'app/src/images/sidebar-v2/account-menu/CircleHelpIcon';
import DeveloperPortalIcon from 'app/src/images/DeveloperPortalIcon';
import SupportIcon from 'app/src/images/SupportIcon';
import { track } from 'app/src/helpers/Tracker';
import { openChat } from 'app/src/utils/support.utils';
import { INTEGRATIONS_TAB_DATA_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import NotificationIcon from 'app/src/images/sidebar-v2/NotificationIcon';
import { useAccountActions } from 'app/src/context/AccountStore';

interface AccountMenuButton {
  icon: ReactElement;
  label: string;
  onClick?: () => void;
  key?: string;
  dataTestId?: string;
  items?: AccountMenuButtons;
}
export type AccountMenuButtons = Record<string, AccountMenuButton>;

const useAccountMenuButtons = (onClose: () => void) => {
  const { navigateToIntegrations, navigateToDashboardV2MyProfile } = useNavigation();
  const { toggleNotificationListOpen } = useAccountActions();

  const navigate = func => () => {
    onClose();
    func();
  };

  const openSupport = () => {
    openChat();
    onClose();
    track(`Support Click`, {
      location: 'Help Menu',
    });
  };

  const handleOpenHelpCenter = () => {
    track('Help Center Click', {
      location: 'Help Menu',
    });
    onClose();

    window.open('https://help.gotolstoy.com', '_blank');
  };

  const openDeveloperPortal = () => {
    track('Help Center Click', {
      location: 'Developer Portal',
    });
    onClose();
    window.open('https://developers.gotolstoy.com/', '_blank');
  };

  const onNotificationsClick = () => {
    track('In App Notifications Sidebar Click');
    toggleNotificationListOpen();
    onClose();
  };

  const buttons: AccountMenuButtons = {
    settings: {
      onClick: navigate(navigateToDashboardV2MyProfile),
      icon: <OutlinedSettingsIcon />,
      label: 'Settings',
    },
    integrations: {
      onClick: navigate(navigateToIntegrations),
      icon: <IntegrationsOutlinedIcon />,
      label: 'Integrations',
      dataTestId: INTEGRATIONS_TAB_DATA_TEST_ID,
    },
    notifications: {
      onClick: onNotificationsClick,
      icon: <NotificationIcon />,
      label: 'Notifications',
    },
    help: {
      label: 'Help',
      icon: <CircleHelpIcon />,
      key: 'help',
      items: {
        helpCenter: {
          onClick: handleOpenHelpCenter,
          label: 'Help Center',
          icon: <HelpIcon />,
        },
        developerPortal: {
          onClick: openDeveloperPortal,
          label: 'Developer Portal',
          icon: <DeveloperPortalIcon />,
        },
        support: {
          onClick: openSupport,
          label: 'Support',
          icon: <SupportIcon />,
        },
      },
    },
  };
  return { buttons };
};

export default useAccountMenuButtons;
