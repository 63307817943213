import React from 'react';
import DowngradeMainReasons from 'app/src/pages/downgrade/DowngradeMainReasons';
import DowngradeImproveProduct from 'app/src/pages/downgrade/DowngradeImproveProduct';
import DowngradeAreYouSure from 'app/src/pages/downgrade/DowngradeAreYouSure';

const DowngradeContent = ({ stage, ...props }) => {
  if (stage === 1) {
    return <DowngradeMainReasons {...props} />;
  }
  if (stage === 2) {
    return <DowngradeImproveProduct {...props} />;
  }

  return <DowngradeAreYouSure />;
};

export default DowngradeContent;
