import React from 'react';
import styled from 'styled-components';
import AutoSizer from 'react-virtualized-auto-sizer';

import { App } from 'src/types/app';
import { getSocialUsername } from 'src/utils/ugc';
import Spinner from 'app/src/basic_components/Spinner';
import { InfiniteGrid } from 'src/complex_components/grid';
import { useModalActions } from 'src/context/ui_store/ModalStore';
import { UGC_REQUEST_MODAL_KEY } from 'src/constants/modals.constants';
import { TextSmallBold } from 'shared/react/components/basic/text/TextV2';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { VideosPageVodAsset } from 'app/src/pages/dashboard/pages/videos/types/videos.types';

import UgcMediaItem from './UgcMediaItem';
import UgcGhostsEmptyState from './UgcGhostsEmptyState';
import { GRID_BREAKPOINTS, GRID_ROW_HEIGHT, GRID_GAP } from '../../constants';
import { PendingVodConnectionSuggestionsProvider } from 'src/hooks/vod-connection-suggestions/PendingVodConnectionsContext';

type Props = {
  selectedApp: App;
  selectedUGCCategory: string;
  videos: VideosPageVodAsset[];

  title?: string;
  isLoading?: boolean;
  mediaLabel?: string;
  overscan?: number;
  emptyState?: {
    title: string;
    subTitle: string;
  };
  loadMore?: (startIndex: number, stopIndex: number) => void;
};

export const UgcPageContent = ({
  videos = [],
  title = '',
  selectedApp,
  selectedUGCCategory,
  overscan,
  mediaLabel,
  emptyState,
  isLoading = false,
  loadMore = () => {},
}: Props) => {
  const { setCurrentModal } = useModalActions();

  const openUgcRequestModal = video => {
    setCurrentModal(UGC_REQUEST_MODAL_KEY, { video, selectedApp, selectedUGCCategory });
  };

  const renderAsset = index => {
    if (index >= videos.length) {
      return null;
    }

    const asset = videos[index];

    return (
      <UgcMediaItem
        key={asset.id}
        asset={asset}
        defaultHashtag={mediaLabel || getSocialUsername(selectedApp)}
        onViewPostClick={openUgcRequestModal}
      />
    );
  };

  if (isLoading && !videos?.length) {
    return (
      <div className="flex justify-center">
        <Spinner />
      </div>
    );
  }

  if (!videos.length) {
    return (
      <UgcGhostsEmptyState
        title={emptyState?.title || 'No posts found'}
        subTitle={emptyState?.subTitle}
      />
    );
  }

  return (
    <PendingVodConnectionSuggestionsProvider>
      <Container>
        {title && <TextSmallBold>{title}</TextSmallBold>}
        <GridContainer>
          <AutoSizer>
            {({ width, height }) => (
              <>
                <InfiniteGrid
                  width={width}
                  height={height}
                  gap={GRID_GAP}
                  paddingRight={GRID_GAP}
                  rowHeight={GRID_ROW_HEIGHT}
                  itemCount={videos.length}
                  breakpoints={GRID_BREAKPOINTS}
                  overscan={overscan}
                  loadMore={loadMore}
                >
                  {({ itemIndex }) => renderAsset(itemIndex)}
                </InfiniteGrid>
                {isLoading && (
                  <SpinnerContainer width={width}>
                    <Spinner />
                  </SpinnerContainer>
                )}
              </>
            )}
          </AutoSizer>
        </GridContainer>
      </Container>
    </PendingVodConnectionSuggestionsProvider>
  );
};

const Container = styled(VerticalFlex)`
  flex: 1;
  padding: 24px 0 0 24px;
  gap: 24px;
  height: 100%;
  box-sizing: border-box;
`;

// seems like AutoSizer get the width and height of the parent container
// so we need to set this "fill" grid container to fill in the flex container
// otherwise the grid will be cut off at the bottom
const GridContainer = styled(HorizontalFlex)`
  flex: 1;
`;

const SpinnerContainer = styled(HorizontalFlex)<{ width: number }>`
  width: ${({ width }) => width}px;
  padding: 12px 0;
  justify-content: center;
  position: absolute;
  bottom: 0;
  background: ${({ theme }) => theme.colors.white};
`;

export default UgcPageContent;
