import React from 'react';

const FilledTrashIcon = props => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.914.922A.721.721 0 0 1 4.57.5h2.836c.282 0 .54.164.657.422l.187.328h2.25c.398 0 .75.352.75.75 0 .422-.352.75-.75.75h-9A.74.74 0 0 1 .75 2c0-.398.328-.75.75-.75h2.25l.164-.328ZM1.5 3.5h9V11c0 .844-.68 1.5-1.5 1.5H3A1.48 1.48 0 0 1 1.5 11V3.5ZM3.75 5a.385.385 0 0 0-.375.375v5.25c0 .21.164.375.375.375a.385.385 0 0 0 .375-.375v-5.25A.403.403 0 0 0 3.75 5ZM6 5a.385.385 0 0 0-.375.375v5.25c0 .21.164.375.375.375a.385.385 0 0 0 .375-.375v-5.25A.403.403 0 0 0 6 5Zm2.25 0a.385.385 0 0 0-.375.375v5.25c0 .21.164.375.375.375a.385.385 0 0 0 .375-.375v-5.25A.403.403 0 0 0 8.25 5Z"
        fill="#7D8087"
      />
    </svg>
  );
};

export default FilledTrashIcon;
