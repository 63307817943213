import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import WidgetPreview from 'app/src/pages/project/pages/look_and_feel/preview/widget_preview/WidgetPreview';
import LeadFormPreview from 'app/src/pages/project/pages/look_and_feel/preview/lead_form_preview/LeadFormPreview';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import EmailPreview from 'app/src/pages/project/pages/look_and_feel/preview/gif_preview/EmailPreview';
import LandingPagePreview from 'app/src/pages/project/pages/look_and_feel/preview/landing_page_preview/LandingPagePreview';
import PlayerPreview from 'app/src/pages/project/pages/look_and_feel/preview/player_preview/PlayerPreview';
import {
  BRANDING_CATEGORY_KEY,
  EMBED_CAROUSEL_CATEGORY_KEY,
  FEED_CATEGORY_KEY,
  EMAIL_SETTINGS_CATEGORY_KEY,
  LANDING_PAGE_CATEGORY_KEY,
  LEAD_FORM_CATEGORY_KEY,
  LOOK_AND_FEEL_PREVIEW_WIDTH,
  PLAYER_CATEGORY_KEY,
  PRODUCT_DETAILS_CATEGORY_KEY,
  STORIES_CATEGORY_KEY,
  VIDEO_PAGE_CATEGORY_KEY,
  WIDGET_SETTINGS_CATEGORY_KEY,
  GIF_CATEGORY_KEY,
  HERO_OVERLAY_CATEGORY_KEY,
  HERO_CATEGORY_KEY,
} from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import EmbedCarouselPreviewContainer from 'app/src/pages/project/pages/look_and_feel/preview/embed_carousel_preview/EmbedCarouselPreviewContainer';
import DesignStoriesPreviewContainer from 'app/src/pages/project/pages/look_and_feel/preview/feed_stories_preview/DesignStoriesPreviewContainer';
import { getIsFeed } from 'app/src/context/ProjectsStore';
import VideoPagePreviewContainer from './video_page_preview/VideoPagePreviewContainer';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { publishMethodOptions } from 'app/src/types/entities';
import HeroPreview from 'app/src/pages/project/pages/look_and_feel/preview/hero-preview/HeroPreview';

function LookAndFeelPreview() {
  const { selectedCategory, project } = useLookAndFeelContext();
  const publishMethod = project?.publishMethod;
  const isFeed = getIsFeed(project);

  const getDefaultCategoryPreview = () => {
    switch (publishMethod) {
      case publishMethodOptions.stories:
        return STORIES_CATEGORY_KEY;
      case publishMethodOptions.carousel:
        return EMBED_CAROUSEL_CATEGORY_KEY;
      case publishMethodOptions.bubble:
        return WIDGET_SETTINGS_CATEGORY_KEY;
      case publishMethodOptions.embed:
        return PLAYER_CATEGORY_KEY;
      case publishMethodOptions.email:
      case publishMethodOptions.landingPage:
        return EMAIL_SETTINGS_CATEGORY_KEY;
      case publishMethodOptions.videoPage:
      case publishMethodOptions.tvChannel:
        return VIDEO_PAGE_CATEGORY_KEY;
      case publishMethodOptions.hero:
      case publishMethodOptions.tile:
      case publishMethodOptions.videoCollectionCampaign:
        return null;
      default:
        return isFeed ? STORIES_CATEGORY_KEY : WIDGET_SETTINGS_CATEGORY_KEY;
    }
  };

  const [lastSelectedCategory, setLastSelectedCategory] = useState(
    selectedCategory ?? getDefaultCategoryPreview()
  );

  useEffect(() => {
    if (selectedCategory) {
      setLastSelectedCategory(selectedCategory);
    }
  }, [selectedCategory]);

  const widgetPreview = <WidgetPreview />;

  const getFeedPreview = () => {
    switch (publishMethod) {
      case publishMethodOptions.stories:
        return <DesignStoriesPreviewContainer />;
      case publishMethodOptions.carousel:
        return <EmbedCarouselPreviewContainer />;
      case publishMethodOptions.email:
      case publishMethodOptions.landingPage:
        return <EmailPreview />;
      case publishMethodOptions.videoPage:
        return <VideoPagePreviewContainer />;
      default:
        return widgetPreview;
    }
  };

  const previews = {
    [WIDGET_SETTINGS_CATEGORY_KEY]: widgetPreview,
    [EMAIL_SETTINGS_CATEGORY_KEY]: <EmailPreview />,
    [GIF_CATEGORY_KEY]: <EmailPreview />,
    [LANDING_PAGE_CATEGORY_KEY]: <LandingPagePreview />,
    [PLAYER_CATEGORY_KEY]: <PlayerPreview />,
    [LEAD_FORM_CATEGORY_KEY]: <LeadFormPreview />,
    [FEED_CATEGORY_KEY]: getFeedPreview(),
    [EMBED_CAROUSEL_CATEGORY_KEY]: <EmbedCarouselPreviewContainer />,
    [STORIES_CATEGORY_KEY]: <DesignStoriesPreviewContainer />,
    [VIDEO_PAGE_CATEGORY_KEY]: <VideoPagePreviewContainer />,
    [PRODUCT_DETAILS_CATEGORY_KEY]: <PlayerPreview />,
    [BRANDING_CATEGORY_KEY]: getFeedPreview(),
    [HERO_OVERLAY_CATEGORY_KEY]: <HeroPreview />,
    [HERO_CATEGORY_KEY]: <HeroPreview />,
  };

  const isPlayerPreview = [PRODUCT_DETAILS_CATEGORY_KEY, PLAYER_CATEGORY_KEY].includes(
    lastSelectedCategory
  );
  return (
    <LayoutRoot isPlayerPreview={isPlayerPreview}>{previews[lastSelectedCategory]}</LayoutRoot>
  );
}

export default LookAndFeelPreview;

const LayoutRoot = styled(Gap8VerticalFlex)<{ isPlayerPreview: boolean }>`
  padding: 0px 24px 24px 0;
  min-height: 520px;
  width: 100%;
  position: sticky;
  top: 0;
  align-self: ${({ isPlayerPreview }) => (isPlayerPreview ? 'center' : '')};

  & > * {
    min-width: ${LOOK_AND_FEEL_PREVIEW_WIDTH}px;
  }

  @media (${({ theme }) => theme.breakpoints.laptopMax}) {
    align-self: unset;
  }
`;
