import React, { useState } from 'react';
import styled from 'styled-components';
import TolstoyEmptyScreen from 'app/src/layouts/tolstoy_empty_screen/TolstoyEmptyScreen';
import Gap16HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap16HorizontalFlexWrap';
import Button from 'shared/react/components/complex/Button';
import Types from 'shared/react/theme/Types';
import { useUser } from 'app/src/context/userStore/UserStore';
import { useHistory } from 'react-router-dom';
import DowngradeContent from 'app/src/pages/downgrade/DowngradeContent';
import { usePackages } from 'app/src/context/PackagesStore';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';
import { navigateToRoot } from 'app/src/utils/navigation.utils';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { FEATURE_PRICING_DOWNGRADE } from 'app/src/constants/appFeatures.constants';

const DowngradePage = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [otherValue, setOtherValue] = useState('');
  const [stage, setStage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [additionalInfoValue, setAdditionalInfoValue] = useState('');
  const [improveProductValue, setImproveProductValue] = useState('');
  const [, { updateAccountSettings }] = useUser();
  const [, { unsubscribe }] = usePackages();
  const [, { setSnackbar }] = useSnackBar();
  const [, { getFeatureEnabled }] = useFeatures();
  const history = useHistory();

  if (!getFeatureEnabled(FEATURE_PRICING_DOWNGRADE)) {
    navigateToRoot(history);
    return null;
  }

  const onAnswerClick = value => {
    let newOptions;
    if (selectedOptions.includes(value)) {
      newOptions = selectedOptions.filter(option => {
        return option !== value;
      });
    } else {
      newOptions = [...selectedOptions, value];
    }

    setSelectedOptions(newOptions);
  };

  const onContinueClick = async () => {
    if (stage === 1) {
      setStage(2);
      return;
    }

    if (stage === 2) {
      setStage(3);
      return;
    }

    setIsLoading(true);

    const downgradeInfo = {
      selectedOptions,
      additionalInfo: additionalInfoValue,
      improveProduct: improveProductValue,
    };

    if (otherValue) {
      downgradeInfo.selectedOptions.push(otherValue);
    }

    const data = { downgradeInfo: JSON.stringify(downgradeInfo) };
    await updateAccountSettings(data);

    const error = await unsubscribe();

    const severity = error ? 'error' : 'success';
    const text = error
      ? 'There have been a problem canceling your subscription. Please contact support@gotolstoy.com'
      : 'Your subscription has been canceled';

    setSnackbar(text, severity);
    setIsLoading(false);
    if (error) {
      return;
    }

    navigateToRoot(history);
  };

  const onCancelClick = () => {
    if (stage === 1) {
      history.goBack();
      return;
    }

    if (stage === 2) {
      setStage(1);
      return;
    }

    setStage(2);
  };

  const isDisabled = !otherValue && !selectedOptions.length;
  return (
    <LayoutRoot>
      <DowngradeContent
        setStage={setStage}
        stage={stage}
        selectedOptions={selectedOptions}
        otherValue={otherValue}
        setOtherValue={setOtherValue}
        onAnswerClick={onAnswerClick}
        additionalInfoValue={additionalInfoValue}
        setAdditionalInfoValue={setAdditionalInfoValue}
        improveProductValue={improveProductValue}
        setImproveProductValue={setImproveProductValue}
      />
      <ButtonsContainer>
        <Button onClick={onCancelClick} type={Types.Secondary}>
          Cancel
        </Button>
        <Button loading={isLoading} disabled={isDisabled} onClick={onContinueClick}>
          Continue
        </Button>
      </ButtonsContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(TolstoyEmptyScreen)``;

const ButtonsContainer = styled(Gap16HorizontalFlexWrap)`
  align-self: flex-end;
  align-content: flex-end;
  margin: 24px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    margin: 24px 0 24px 24px;
    padding-bottom: 24px;
  }
`;

export default DowngradePage;
