const DELAY = 4000;
let hasDelayPassed = false;
const eventQueue = [];

const processQueue = () => {
  while (eventQueue.length) {
    const { eventFunction, args } = eventQueue.shift();
    eventFunction(...args);
  }
};

setTimeout(() => {
  hasDelayPassed = true;
  processQueue();
}, DELAY);

/**
 * Sends an event after an initial delay
 * If sent before the delay has passed, the event is queued
 * If the delay has passed, the event is sent immediately
 *
 * @param {*} eventFunction
 */
export const sendWithInitialDelay =
  eventFunction =>
  (...args) => {
    if (hasDelayPassed) {
      return eventFunction(...args);
    }

    eventQueue.push({ eventFunction, args });
  };
