import React from 'react';
import PencilIcon from 'app/src/images/PencilIcon';
import TrashIcon from 'app/src/images/sidebar-v2/TrashIcon';
import { SIDEBAR_EDITABLE_ITEM_TRASH_ICON_DATA_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import SidebarItem from './SidebarItem';
import { SAVED_LABEL_NAMES } from 'app/src/context/VodLabelsStore';

type Props = {
  name: React.ReactNode;
  count?: string | number;
  dataTestId?: string;
  isSelected?: boolean;
  withEditMenu?: boolean;
  onClick?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
};

export const SidebarEditableItem = ({
  name,
  count,
  dataTestId,
  isSelected = false,
  withEditMenu = false,
  onClick,
  onEdit,
  onDelete,
}: Props) => {
  let menuItems = [
    {
      onClick: onEdit,
      icon: <PencilIcon />,
      text: 'Rename',
    },
    {
      onClick: onDelete,
      icon: <TrashIcon />,
      text: 'Delete',
      dataTestId: SIDEBAR_EDITABLE_ITEM_TRASH_ICON_DATA_TEST_ID,
    },
  ];

  if (SAVED_LABEL_NAMES.includes(name)) {
    menuItems = [];
  }

  return (
    <SidebarItem
      name={name}
      count={count}
      dataTestId={dataTestId}
      isSelected={isSelected}
      onClick={onClick}
      menuItems={menuItems}
      withEditMenu={withEditMenu}
    />
  );
};

export default SidebarEditableItem;
