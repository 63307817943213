import { PUBLIC_CLASSES as CAROUSEL_PUBLIC_CLASSES } from '../widgets/EmbedWidgets/Carousel/carouselConstants.js';
import { PUBLIC_CLASSES as EMBED_PUBLIC_CLASS } from '../widgets/EmbedWidgets/Embed/embedConstants.js';
import { PUBLIC_CLASSES as STORIES_PUBLIC_CLASSES } from '../widgets/EmbedWidgets/Story/storyConstants.js';
import { PUBLIC_CLASSES as TILE_PUBLIC_CLASSES } from '../widgets/EmbedWidgets/Tile/tileConstants.js';

export const CAROUSEL_EMBED_WIDGET = 'carousel';
export const IN_TILE_CAROUSEL_EMBED_WIDGET = 'inTileCarousel';
export const BUBBLE_WIDGET = 'bubble';
export const STORY_EMBED_WIDGET = 'stories';
export const EMBED_WIDGET = 'embed';
export const TILE_EMBED_WIDGET = 'tile';
export const CENTERED_MODAL_WIDGET = 'centeredModal';
export const STOP_SHOWING_WIDGET_TO = {
  clickedX: 'stopShowingWidgetToClickedX',
  viewed: 'stopShowingWidgetToViewed'
};
export const EMBED_WIDGETS_CLASSNAMES = {
  [CAROUSEL_EMBED_WIDGET]: CAROUSEL_PUBLIC_CLASSES.carousel,
  [STORY_EMBED_WIDGET]: STORIES_PUBLIC_CLASSES.story,
  [EMBED_WIDGET]: EMBED_PUBLIC_CLASS.embed,
  [TILE_EMBED_WIDGET]: TILE_PUBLIC_CLASSES.tile
};
export const SITE_ACTIVITY_EVENT_NAME = 'siteActivity';
export const PAGE_VISIT_EVENT_NAME = 'pageVisit';

export const DOM_EXCEPTION_ERRORS = { notAllowedError: 'NotAllowedError' };

export const DEFAULT_CART_QUANTITY = 1;

export const WIDGET_SCRIPT_PARAMETERS = {
  shop: 'shop',
  appKey: 'app-key',
  productId: 'product-id'
};

export const URL_PARAMETERS = {
  tolstoyAutoOpen: 'tolstoyAutoOpen',
  tolstoyStartVideo: 'tolstoyStartVideo'
};

export const PLACEHOLDER_PRODUCT_ID = 'PRODUCT_ID';

export const CAROUSEL_CREATOR_PROFILE_LINK_POSITION = {
  TOP: 'top',
  BOTTOM: 'bottom'
};
