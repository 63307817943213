import React, { useState } from 'react';
import styled from 'styled-components';
import { CardContainer, CardImageWrapper } from 'app/src/pages/dashboard/components/card/Card';
import OnsiteCardImage from './OnsiteCardImage';
import OnsiteCardDetails from './OnsiteCardDetails';
import { track } from 'src/helpers/Tracker';
import useNavigation from 'src/hooks/useNavigation';
import ProjectMenu from 'app/src/pages/dashboard/components/menus/ProjectMenu';
import { CARD_LAYOUT_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import OnsiteCardControls from './OnsiteCardControls';
import { publishMethodOptions } from 'src/types/entities';

const OnsiteCard = ({ project, toggleSelected, isSelected, isSelectionMode }) => {
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement>(null);
  const { navigateToProjectTab } = useNavigation();
  const isTvChannel = project.publishMethod === publishMethodOptions.tvChannel;

  const navigateToProject = () => {
    if (isSelectionMode) {
      toggleSelected(project.id);
      return;
    }

    track('Project Card Click');

    return navigateToProjectTab(project, '');
  };

  const openMenu = e => {
    e.stopPropagation();
    track('Project Card Menu Opened');
    setMenuAnchor(e.currentTarget);
  };

  const getCardControls = () => {
    if (isTvChannel) {
      return;
    }

    return (
      <OnsiteCardControls
        project={project}
        toggleSelected={toggleSelected}
        isSelected={isSelected}
        isSelectionMode={isSelectionMode}
        openMenu={openMenu}
      />
    );
  };

  return (
    <CardContainer data-test-id={CARD_LAYOUT_TEST_ID} onClick={navigateToProject}>
      <ImageWrapper>
        <OnsiteCardImage project={project} />
        {getCardControls()}
      </ImageWrapper>

      <OnsiteCardDetails project={project} />

      <ProjectMenu
        project={project}
        anchorElement={menuAnchor}
        setAnchorElement={setMenuAnchor}
        isOnsite={true}
      />
    </CardContainer>
  );
};

const ImageWrapper = styled(CardImageWrapper)`
  height: 160px;

  background: ${({ theme }) => theme.colors.neutralLighter};
`;

export default OnsiteCard;
