import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MenuWithContainer from 'shared/react/components/basic/MenuWithContainer';
import MenuItemV3, { menuItemSizesCss } from 'app/src/complex_components/menu_items/MenuItemV3';
import {
  MenuDirection,
  MenuItemProps,
} from 'app/src/complex_components/menu_items/types/MenuItem.types';
import { DesignSizes } from 'app/src/types/design-system';
import 'shared/react/components/basic/Separator';
import Separator from 'shared/react/components/basic/Separator';
import BackMenuItem from 'app/src/complex_components/menu_items/BackMenuItem';
import OverlaySpinner from 'app/src/basic_components/OverlaySpinner';
import { ChevronRightIcon } from 'shared/react/images/ChevronIcon';

export type MenuItemType = MenuItemProps & {
  separator?: boolean;
  items?: MenuItemType[];
};

type Props = {
  anchorElement: HTMLElement | null;
  onClose: () => void;
  menuItems: MenuItemType[];
  className?: string;
  direction?: MenuDirection;
  size?: DesignSizes;
  closeOnEnter?: boolean;
  contentEndLocation?: 'top' | 'bottom';
};

const MenuWithItems = ({
  menuItems,
  anchorElement,
  onClose,
  className,
  direction = { vertical: 'top', horizontal: 'right' },
  size = DesignSizes.SMALL,
  contentEndLocation = 'bottom',
  closeOnEnter,
}: Props) => {
  const [currentList, setCurrentList] = useState<MenuItemType | null>(null);
  const [loadingKey, setLoadingKey] = useState('');

  const onItemClick = async (menuItem: MenuItemType) => {
    if (menuItem.disabled) {
      return;
    }

    if (menuItem.items) {
      setCurrentList(menuItem);
      menuItem.onClick?.();
      return;
    }

    if (!menuItem.onClick) {
      return;
    }

    setLoadingKey(menuItem.text);
    await menuItem.onClick();
    onClose();
  };

  const getContent = (menuItem: MenuItemType, i) => {
    if (menuItem.hidden) {
      return null;
    }

    if (menuItem.separator) {
      return <StyledSeparator key={`separator-${i}`} />;
    }

    return (
      <OverlaySpinner
        key={`menuItem-${menuItem.key || menuItem.text || i}`}
        size={16}
        isLoading={loadingKey === menuItem.text || menuItem.isLoading}
      >
        <MenuItem
          {...menuItem}
          onClick={() => onItemClick(menuItem)}
          isLoading={loadingKey === menuItem.text}
          endIcon={menuItem.items ? <ChevronRightIcon /> : menuItem.endIcon}
          menuItemsHasOnClick={
            !!menuItem.onClick || !!menuItem.items || menuItem.showEndIconOnHover
          }
          size={size}
        >
          {menuItem.text}
        </MenuItem>
      </OverlaySpinner>
    );
  };

  useEffect(() => {
    setCurrentList(null);
    setLoadingKey('');
  }, [anchorElement]);

  const currentItems = currentList?.items || menuItems;
  return (
    <LayoutRoot
      anchorEl={anchorElement}
      open={!!anchorElement}
      onClose={onClose}
      direction={direction}
      className={className}
      isLoading={!!loadingKey}
      closeOnEnter={closeOnEnter}
      contentEndLocation={contentEndLocation}
      hasItems={!!currentItems.length}
    >
      <StyledBackMenuItem
        size={size}
        currentLabel={currentList?.text}
        setCurrentKey={setCurrentList}
      />
      {currentItems.map(getContent)}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(MenuWithContainer)<{ hasItems: boolean; isLoading: boolean }>`
  padding: ${({ hasItems }) => (hasItems ? '4px' : '')};
  width: 100%;
  min-width: 132px;
  pointer-events: ${({ isLoading }) => (isLoading ? 'none' : 'auto')};
`;

const MenuItem = styled(MenuItemV3)<{ menuItemsHasOnClick: boolean; size: DesignSizes }>`
  ${menuItemSizesCss};
  cursor: ${({ menuItemsHasOnClick }) => (menuItemsHasOnClick ? 'pointer' : 'default')};

  &:hover {
    background: ${({ menuItemsHasOnClick }) => (menuItemsHasOnClick ? '' : 'transparent')};
  }

  svg > path {
    fill: ${({ theme }) => theme.colors.black};
  }
`;

const StyledBackMenuItem = styled(BackMenuItem)<{ size: DesignSizes }>`
  ${menuItemSizesCss};

  svg > path {
    fill: ${({ theme }) => theme.colors.black};
  }
`;

const StyledSeparator = styled(Separator)`
  margin: 6px 0;
`;

export default MenuWithItems;
