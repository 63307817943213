import React from 'react';
import MenuWithContainer from 'shared/react/components/basic/MenuWithContainer';
import PublishMethodFilterMenuItems from './PublishMethodFilterMenuItems';
import styled from 'styled-components';
import Separator from 'shared/react/components/basic/Separator';
import { TextSmall } from 'shared/react/components/complex/Text';

const PublishMethodFilterMenu = ({ anchorEl, setAnchorEl }) => {
  const onClose = () => {
    setAnchorEl(false);
  };

  return (
    <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={onClose}>
      <Title>Type</Title>
      <CustomSeparator />

      <PublishMethodFilterMenuItems onClose={onClose} />
    </Menu>
  );
};

const CustomSeparator = styled(Separator)`
  background-color: ${({ theme }) => theme.colors.ghostLight};
  width: 240px;
`;

const Menu = styled(MenuWithContainer)`
  border-radius: 12px;
  padding: 8px 0;
`;

const Title = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.gray2};
  padding: 8px 16px 12px;
`;

export default PublishMethodFilterMenu;
