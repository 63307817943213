import React, { useState } from 'react';
import AreYouSureModal from 'app/src/pages/modals/AreYouSureModal';
import { useApps } from 'app/src/context/AppsStore';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { track } from 'app/src/helpers/Tracker';
import { identify } from 'app/src/helpers/Widget';
import { useProjects } from 'app/src/context/ProjectsStore';
import { useUser } from 'app/src/context/userStore/UserStore';
import { useRules } from 'app/src/context/RulesStore';
import { useProductPageSettings } from 'app/src/context/ProductPageSettingsStore';
import Utils from 'app/src/utils';
import { useHistory } from 'react-router-dom';

const DeleteTolstoyModal = () => {
  const [
    {
      modalProps: { ids },
    },
    { clearModalState },
  ] = useModal();
  const [{ project, projects }, { deleteProject }] = useProjects({ projectId: ids[0] });
  const [{ products: productPageSettings }, { deleteProductPageSettings }] = useProductPageSettings(
    {
      publishId: project?.publishId,
    }
  );
  const [, { getProjectApps, getApp, setProjectApp }] = useApps();
  const [{ user, account }, userState] = useUser();
  const [, { deleteRuleGroup, fetchRules }] = useRules();
  const [isDeleting, setDeleting] = useState(false);
  const history = useHistory();

  const getDeleteProjectAreYouSureText = () => {
    let text = 'This Tolstoy will be deleted permanently. Are you sure?';

    ids.forEach(id => {
      const projectApps = getProjectApps(id);
      if (projectApps.some(projectApp => projectApp.active)) {
        text =
          'Warning! Deleting this Tolstoy will permanently remove the widget from your site as well. Are you sure?';
      }
    });

    return text;
  };

  const handleDeleteTolstoyDenied = () => {
    track('Tolstoy Delete No Click');
    clearModalState();
  };

  const deleteProductPage = async products => {
    if (!products?.length) {
      return;
    }

    try {
      const promises = products.map(product => {
        return deleteProductPageSettings({ id: product.id });
      });

      await Promise.all(promises);
    } catch (err) {
      Utils.logError(`Error deleting product page settings`, err);
    }
  };

  const handleDeleteTolstoyApproved = async () => {
    track('Tolstoy Delete Yes Click');
    setDeleting(true);

    const liveIds = account?.liveIds?.filter(id => {
      return id !== project.publishId;
    });

    await userState.updateAccountSettings({ liveIds }, true);
    const promises = ids.map(async id => {
      const projectApps = getProjectApps(id);
      for (const { active, appId } of projectApps) {
        if (!active) {
          continue;
        }

        const app = getApp(appId);
        if (project && app) {
          await setProjectApp(app.app, project.id, false, project.publishId);
        }
      }

      const rule = await fetchRules(id);
      if (rule) {
        await deleteRuleGroup({ id: rule.id });
      }

      await deleteProductPage(productPageSettings);

      await deleteProject({ id: id });
    });

    await Promise.all(promises);
    const pathname = history.location.pathname.split('/');
    const path = pathname.slice(0, 3).join('/');
    history.push(path);

    await identify(user, { projectsLength: projects?.length });
    setDeleting(false);
    clearModalState();
  };

  return (
    <AreYouSureModal
      open={true}
      loading={isDeleting}
      titleText={getDeleteProjectAreYouSureText()}
      secondaryButtonClicked={handleDeleteTolstoyDenied}
      actionButtonClicked={handleDeleteTolstoyApproved}
    />
  );
};

export default DeleteTolstoyModal;
