import React, { SyntheticEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { pick, omit } from 'lodash';

import { SearchInput } from 'app/src/basic_components/input/InputWithIcon';
import { useDebounce } from 'app/src/hooks/useDebounce';
import { WhereCondition } from 'app/src/pages/dashboard/utils/filterEntities';
import filtersConfig from './filtersConfig';
import CurrentFilters from './components/current-filters/CurrentFilters';
import FilterTypesMenu from './components/filter-types-menu/FilterTypesMenu';
import { FilterDashedBlock } from './components/filter-block/FilterBlock';
import PlusIcon from 'src/images/PlusIcon';
import CrossIcon from 'src/images/dashboard_v2/CrossIcon';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import useActions from 'app/src/pages/dashboard/components/filters/hooks/useActions';
import useIsMounted from 'shared/react/hooks/useIsMounted';
import { SortDirection } from 'app/src/pages/dashboard/pages/videos/hooks/search/searchRequests';
import { DateSortingMenu } from 'app/src/pages/dashboard/components/sorting/DateSortingMenu';

type Props = {
  availableFilters: string[];
  existingFilters: WhereCondition;
  extraFiltersSlot?: React.JSX.Element | null;
  setFilters: (filters: { [key: string]: WhereCondition }, forceReplace?: boolean) => void;
  canSortDate?: boolean;
  dateSortDirection?: SortDirection;
  setDateSortDirection?: (sortDirection: SortDirection) => void;
};

const SEARCH_FILTER_NAME = 'name';

const Filters = ({
  availableFilters,
  existingFilters = {},
  extraFiltersSlot = null,
  setFilters,
  canSortDate,
  dateSortDirection,
  setDateSortDirection,
}: Props) => {
  const [menuRef, setMenuRef] = useState(null);
  const actions = useActions(setFilters);
  const isMounted = useIsMounted();

  const currentSearchFilter = existingFilters.name;
  const [searchValue, setSearchValue] = useState(currentSearchFilter?.contains || '');

  const debouncedSearchValue = useDebounce(searchValue, 300);

  const filtersMenuConfig = pick(filtersConfig, availableFilters);
  const currentFilters = pick(existingFilters, availableFilters) as WhereCondition;
  const hasFilters = Object.keys(currentFilters).length > 0;

  const onSearchValueChange = e => {
    if (!isMounted) {
      return;
    }

    setSearchValue(e.target.value);
  };

  useEffect(() => {
    if (debouncedSearchValue === existingFilters.name?.contains) {
      return;
    }

    // update filters on search input change
    setFilters({ name: { contains: debouncedSearchValue } });
  }, [debouncedSearchValue]);

  useEffect(() => {
    const newSearchValue = currentSearchFilter?.contains || '';
    if (searchValue === newSearchValue) {
      return;
    }

    if (!isMounted) {
      return;
    }

    // update search input when the value is changed from outside
    setSearchValue(newSearchValue);
  }, [currentSearchFilter?.contains]);

  const onFiltersMenuToggle = (event?: SyntheticEvent) => {
    if (!isMounted) {
      return;
    }

    if (!event || menuRef) {
      setMenuRef(null);
      return;
    }

    setMenuRef(event.target);
  };

  const onResetFilters = () => {
    const filtersToReset = [...availableFilters, SEARCH_FILTER_NAME];
    setFilters(omit(existingFilters, filtersToReset), true);
  };

  return (
    <LayoutRoot>
      <StyledSearchInput
        onChange={onSearchValueChange}
        value={searchValue}
        placeholder="Search..."
      />

      {canSortDate && (
        <DateSortingMenu
          dateSortDirection={dateSortDirection}
          setDateSortDirection={setDateSortDirection}
        ></DateSortingMenu>
      )}

      <FilterDashedBlock onClick={onFiltersMenuToggle}>
        <PlusIcon fill={undefined} size={undefined} /> {!hasFilters && 'Filter'}
      </FilterDashedBlock>

      {extraFiltersSlot}

      <CurrentFilters actions={actions} filters={currentFilters} setFilters={setFilters} />

      {hasFilters && (
        <FilterDashedBlock onClick={onResetFilters}>
          <CrossIcon /> Clear
        </FilterDashedBlock>
      )}

      <FilterTypesMenu
        anchorRef={menuRef}
        onClose={onFiltersMenuToggle}
        menuConfig={filtersMenuConfig}
        currentFilters={currentFilters}
        setFilters={setFilters}
        actions={actions}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlexWrap)`
  flex-grow: 1;
`;

const StyledSearchInput = styled(SearchInput)`
  width: 224px;
  height: 32px;
`;

export default Filters;
