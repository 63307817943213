import React from 'react';

const MusicIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
      <path
        d="M13.1953 1.66406C13.3828 1.80469 13.5 2.01562 13.5 2.25V3.9375V10.125C13.5 11.1797 12.4922 12 11.25 12C10.0078 12 9 11.1797 9 10.125C9 9.09375 10.0078 8.25 11.25 8.25C11.5078 8.25 11.7656 8.29688 12 8.36719V4.94531L6 6.75V11.625C6 12.6797 4.99219 13.5 3.75 13.5C2.50781 13.5 1.5 12.6797 1.5 11.625C1.5 10.5938 2.50781 9.75 3.75 9.75C4.00781 9.75 4.26562 9.79688 4.5 9.86719V6.1875V4.5C4.5 4.17188 4.71094 3.89062 5.01562 3.79688L12.5156 1.54688C12.75 1.47656 13.0078 1.52344 13.1953 1.66406Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default MusicIcon;
