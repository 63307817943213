import React from 'react';
import styled from 'styled-components';
import { track } from 'app/src/helpers/Tracker';
import EditorHeader from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorHeader';
import EditorSelect from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorSelect';
import EditorMainContainer from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorMainContainer';
import ResetToDefaultInputWrapper from 'app/src/complex_components/ResetToDefaultInputWrapper';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { LANGUAGES } from 'shared/react/constants/feature-constants/playerLangaugeCustomization.constants';
import { useBrandingContext } from '../../../BrandingContext';
import BrandingExpander from 'app/src/pages/account/branding/editors/BrandingExpander';

type Props = {
  settings?: any;
  features?: any;
  defaultSettings?: any;
  onChange?: any;
};

const getOptions = options => {
  return Object.entries(options).map(([value, name]) => {
    return {
      id: value,
      name,
      value,
    };
  });
};

const SettingsEditor = ({
  settings = {},
  features = {},
  defaultSettings = {},
  onChange = () => {},
}: Props) => {
  const { loading } = useBrandingContext();
  const { language } = settings;

  const updateSettings = (update, options?) => onChange({ ...settings, ...update }, options);
  const onLanguageSettingChanged = ({ value }) => {
    track('Player Language Change', { language: value, location: 'Branding' });
    updateSettings({ language: value.toLowerCase() });
  };

  const handleResetLanguageToDefault = () => {
    track('Player Language Reset To Default', { property: 'language' });
    updateSettings({ language: defaultSettings.language });
  };

  const disabled = loading;

  return (
    <LayoutRoot>
      <BrandingExpander title="Language">
        <EditorMainContainer>
          <ResetToDefaultInputWrapper disabled={disabled} onClick={handleResetLanguageToDefault}>
            <EditorSelect
              text="Front facing language"
              disabled={disabled}
              onChange={onLanguageSettingChanged}
              value={LANGUAGES[language]}
              items={getOptions(LANGUAGES)}
              tooltipText="Select language to apply to all system buttons and place holders. Contact support if the language you want does not appear on the list."
            />
          </ResetToDefaultInputWrapper>
        </EditorMainContainer>
      </BrandingExpander>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

export default SettingsEditor;
