import { useState } from 'react';
import useFeedAccount from 'shared/react/components/complex/context/hooks/useFeedAccount';
import useFeedFeatures from 'shared/react/components/complex/context/hooks/useFeedFeatures';
import useFeedProducts from 'shared/react/components/complex/context/hooks/useFeedProducts';
import useFeedState from 'shared/react/components/complex/context/hooks/useFeedState';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';
import useRecharge from 'shared/react/components/complex/context/hooks/useRecharge';
import useTracker from 'shared/react/components/complex/context/hooks/useTracker';
import { getFeedActionButtonOnClick } from 'shared/react/components/complex/feed/feed_utils/feedActionButton.utils';
import { REACT_APP_API_BASE_URL } from 'shared/react/config/shared.config';
import { FEATURE_FEED_OPEN_PDP_IN_SAME_TAB } from 'shared/react/constants/features.constants';
import { PRODUCT_CTA_TYPES } from 'shared/react/constants/feed.constants';
import { TOLSTOY_MOVE_TO_URL } from 'shared/react/constants/messages.constants';
import {
  RECHARGE_MESSAGING,
  REQUEST_RECHARGE_ADD_TO_CART_MESSAGE,
} from 'shared/react/constants/rechargeMessaging.constants';
import { ADD_TO_CART } from 'shared/react/constants/shopifyMessaging.constants';
import { PLAYER_TYPES } from 'shared/react/constants/tolstoy.constants';
import { isProductsImportProvider, isUrlProvider } from 'shared/react/utils/feedProducts.utils';
import UrlHelper from 'shared/react/utils/urlHelper';
import Utils from 'shared/react/utils/utils';
import useEcomNavigation from './useEcomNavigation';
import { EVENTS_NAMES } from 'shared/react/constants/events.constants';

/*
  Common logic of FeedBuyNowButton and FeedCtaButton (newer version)
  
*/

const useFeedProductButton = ({
  selectedVariantId,
  appUrl,
  currentStepKey,
  productHandle,
  ecomPlatform,
  productId,
  ctaType,
  externalProductId,
}) => {
  const [, { track, getSessionId }] = useTracker();
  const [{ projectId, isAppPreview }, { getCurrentStep }] = useProjectConfig();
  const [{ appKey }] = useFeedAccount();
  const [{ messagingRequestDetails, setMessagingRequestDetails, isProductsInitialized }] =
    useFeedState();
  const [{ isRechargeEnabled, rechargeCartItem, rechargeProducts }] = useRecharge();
  const [, { getFeatureSettingsByKey }] = useFeedFeatures();
  const [, { getProductNames }] = useFeedProducts();

  const [isBuyNowLoading, setIsBuyNowLoading] = useState(false);
  const currentStep = getCurrentStep(currentStepKey);
  const { videoId } = currentStep;
  const isOpenProductPage = ctaType === PRODUCT_CTA_TYPES.openProductPage;
  const isUrl = isUrlProvider(ecomPlatform);
  const isProductsImport = isProductsImportProvider(ecomPlatform);
  const isLandingPage = Utils.getIsLandingPage();
  const { isOpenPdpInSameTab } = getFeatureSettingsByKey(FEATURE_FEED_OPEN_PDP_IN_SAME_TAB);
  const { getPDPUrl } = useEcomNavigation();

  const getProductLink = () => {
    if (isUrl) {
      return appUrl;
    }

    if (isProductsImport) {
      return productHandle;
    }

    if (ecomPlatform === 'magento') {
      return `https://${appUrl}/${productHandle}.html`;
    }

    if (isOpenProductPage || isOpenPdpInSameTab) {
      return getPDPUrl({ product: { appUrl, handle: productHandle } });
    }

    const productLinkQueryString = new URLSearchParams({
      'attributes[tolstoyId]': projectId,
      'attributes[sessionId]': getSessionId(),
    });
    const currentRechargeProduct = rechargeProducts?.[productId];
    const isSubscriptionOnly =
      currentRechargeProduct?.subscription_options?.storefront_purchase_options ===
      'subscription_only';

    if (isSubscriptionOnly) {
      const variant = currentRechargeProduct?.variants.find(({ id }) => id === selectedVariantId);
      const sellingPlanId = variant.selling_plan_allocations[0].selling_plan_id;
      return `https://${appUrl}/cart/add?items[][selling_plan]=${sellingPlanId}&items[][id]=${selectedVariantId}&${productLinkQueryString}&return_to=/checkout`;
    }

    return `https://${appUrl}/cart/${selectedVariantId}:1?${productLinkQueryString}`;
  };

  const productLink = getProductLink();
  const utmParams = UrlHelper.getUtmParams();
  const buyNowUrl = UrlHelper.appendSearchParamsToUrl(productLink, utmParams);

  const markUserAndRedirect = eventName => {
    setIsBuyNowLoading(true);

    const productNames = getProductNames(currentStep);
    track(eventName, {
      ...Utils.getAnalyticsLocationObject(PLAYER_TYPES.feed),
      text: selectedVariantId,
      productNames,
      videoId,
    });

    const urlParams = new URLSearchParams({
      redirectUrl: buyNowUrl,
      checkout: new Date().toISOString(),
      appKey,
      anonymousId: window.anonymousId,
    });

    const url = `${REACT_APP_API_BASE_URL}/users/actions/user/redirect?` + urlParams;

    setIsBuyNowLoading(false);

    return Utils.openInNewTab(url);
  };

  const handleAddToCart = (quantity = 1) => {
    track(EVENTS_NAMES.ADD_TO_CART, {
      ...Utils.getAnalyticsLocationObject(PLAYER_TYPES.feed),
      text: selectedVariantId,
      videoId,
    });

    setMessagingRequestDetails({
      action: ADD_TO_CART,
      variantId: selectedVariantId,
      rechargeCartItem,
    });

    const value = {
      rechargeCartItem,
      productHandle,
      variantId: selectedVariantId,
      quantity,
      productId: externalProductId,
    };

    if (isRechargeEnabled && rechargeCartItem?.id) {
      return getFeedActionButtonOnClick({
        type: RECHARGE_MESSAGING,
        value,
        eventType: REQUEST_RECHARGE_ADD_TO_CART_MESSAGE,
      });
    }

    Utils.postMessageToWindow({
      eventName: ADD_TO_CART,
      ...value,
    });
  };

  const handleOpenInTheSameTab = () => {
    const productNames = getProductNames(currentStep);
    track('openProductPageClick', {
      ...Utils.getAnalyticsLocationObject(PLAYER_TYPES.feed),
      text: selectedVariantId,
      isOpenInTheSameTab: true,
      productNames,
      videoId,
    });

    if (isLandingPage) {
      return Utils.openInTheSameTab(buyNowUrl);
    }

    return Utils.postMessageToWindow({ name: TOLSTOY_MOVE_TO_URL, url: buyNowUrl });
  };

  const isLoading = () => {
    return (
      (messagingRequestDetails?.action &&
        messagingRequestDetails?.variantId === selectedVariantId) ||
      isBuyNowLoading ||
      (!isProductsInitialized && !isAppPreview)
    );
  };

  return {
    markUserAndRedirect,
    handleAddToCart,
    handleOpenInTheSameTab,
    isLoading,
  };
};

export default useFeedProductButton;
