import React from 'react';

const CenterDirectionIcon = () => {
  return (
    <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.66 5.809c.055.082.219.191.465.191.219 0 .383-.11.438-.191l1.968-1.97a.584.584 0 0 0 .219-.464c0-.355-.328-.656-.656-.656a.584.584 0 0 0-.465.219l-.848.847V1.406c0-.355-.3-.656-.656-.656-.383 0-.656.3-.656.656v2.38l-.875-.849a.57.57 0 0 0-.438-.218.665.665 0 0 0-.656.656c0 .191.055.355.191.465l1.97 1.969Zm.902 3.91a.64.64 0 0 0-.437-.192c-.11 0-.3 0-.465.192l-1.969 1.969a.544.544 0 0 0-.191.437c0 .383.3.656.656.656a.544.544 0 0 0 .438-.191l.875-.848v2.352c0 .383.273.656.656.656a.648.648 0 0 0 .656-.656v-2.352l.848.848c.11.137.273.191.465.191.492 0 .656-.465.656-.656a.57.57 0 0 0-.219-.438L6.562 9.72Zm5.032-2.625H.656c-.383 0-.656.3-.656.656 0 .383.273.656.656.656h10.938a.648.648 0 0 0 .656-.656c0-.355-.3-.656-.656-.656Z"
        fill="#50545E"
      />
    </svg>
  );
};

export default CenterDirectionIcon;
