import { useUser } from 'app/src/context/userStore/UserStore';

const useHasResourcePermissions = (resource, isAccountOwner) => {
  const [{ user }] = useUser();

  if (resource?.owner === user?.owner) {
    return true;
  }

  if (isAccountOwner) {
    return true;
  }

  return !resource?.private;
};

export default useHasResourcePermissions;
