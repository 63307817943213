import React from 'react';

const ResetFiltersIcon = () => (
  <svg width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.703 2.672a.562.562 0 0 0-.328.515v3c0 .329.234.563.563.563h3c.21 0 .421-.117.515-.328.07-.211.024-.469-.14-.61L4.093 4.57C4.868 3.844 5.876 3.375 7 3.375A4.131 4.131 0 0 1 11.125 7.5 4.116 4.116 0 0 1 7 11.625a4.107 4.107 0 0 1-2.648-.938c-.235-.21-.586-.164-.797.07a.584.584 0 0 0 .07.798A5.3 5.3 0 0 0 7 12.75a5.256 5.256 0 0 0 5.25-5.25c0-2.883-2.367-5.25-5.25-5.25-1.43 0-2.719.586-3.703 1.547l-.985-.985c-.14-.164-.398-.21-.609-.14Z"
      fill="#50545E"
    />
  </svg>
);

export default ResetFiltersIcon;
