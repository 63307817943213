import React from 'react';
import PricingTooltip from 'app/src/complex_components/PricingTooltip';
import Utils from 'app/src/utils';
import Tooltip from 'app/src/basic_components/Tooltip';
import { useBilling } from 'app/src/hooks/useBillings';

const AddStepsTooltip = ({
  maxNumberOfPartsInTolstoy,
  isLocked,
  children,
  isQuizOptionDisabled,
  placement = 'bottom',
  hidden,
}) => {
  const { hidePricingTooltip } = useBilling();

  if (hidden) {
    return <div>{children}</div>;
  }

  if (isQuizOptionDisabled) {
    return (
      <Tooltip placement="bottom" text="Keep it simple, we allow 5 parts per quiz">
        <div>{children}</div>
      </Tooltip>
    );
  }

  if (hidePricingTooltip) {
    return (
      <Tooltip
        header="You have reached parts limit"
        text="The maximum number of parts per Tolstoy is 30. Need help? Contact support."
        placement={placement}
        hidden={!isLocked}
      >
        <div>{children}</div>
      </Tooltip>
    );
  }

  return (
    <PricingTooltip
      header="PARTS COUNT"
      text={`You have reached your limit of ${maxNumberOfPartsInTolstoy} parts per Tolstoy.`}
      placement={placement}
      analyticsData={Utils.getAnalyticsLocationObject('Add Part Tooltip')}
      shouldHide={!isLocked}
    >
      {children}
    </PricingTooltip>
  );
};

export default AddStepsTooltip;
