import React, { useRef } from 'react';
import styled from 'styled-components';
import { toTimeString } from '../../utils/video.utils';
import Utils from 'shared/react/utils/utils';

const TIME_BAR_BREAKPOINT_WIDTH = 36;
const TimeBar = ({
  videoStartTime,
  videoEndTime,
  setMovingIndex,
  duration,
  videoLengthContainerRef,
  currVideoTime,
  onBarClick,
}) => {
  const barWidthPercentage = ((videoEndTime - videoStartTime) / duration) * 100 || 100;
  const barLeftPercentage = (videoStartTime / duration) * 100 || 0;
  const timeBarRef = useRef();

  const isTimeBarBreakPoint = timeBarRef?.current?.clientWidth < TIME_BAR_BREAKPOINT_WIDTH;

  return (
    <VideoLengthContainer ref={videoLengthContainerRef}>
      <VideoLengthBar />
      <CurrentTimeBar
        onClick={onBarClick}
        ref={timeBarRef}
        left={barLeftPercentage}
        width={barWidthPercentage}
      >
        {[videoStartTime, videoEndTime].map((time, i) => (
          <LineContainer
            isTimeBarBreakPoint={isTimeBarBreakPoint}
            isFirst={i}
            onMouseDown={() => setMovingIndex(i)}
            onTouchStart={() => setMovingIndex(i)}
            key={time}
          >
            <Line />
          </LineContainer>
        ))}
      </CurrentTimeBar>
      <TooltipContainer left={barLeftPercentage - 4} width={timeBarRef?.current?.clientWidth}>
        {[videoStartTime, videoEndTime].map(time => (
          <TimeTooltip
            key={time}
            onMouseDown={Utils.stopPropagation}
            onMouseMove={Utils.stopPropagation}
          >
            {toTimeString(time)}
          </TimeTooltip>
        ))}
      </TooltipContainer>
      <VideoTime time={(currVideoTime / duration) * 100} />
    </VideoLengthContainer>
  );
};

const DefaultLocation = styled.div`
  grid-area: 1;
  grid-column: 1;
`;

const VideoLengthContainer = styled.div`
  display: grid;
  min-height: 37px;
  border-radius: 8px;
  grid-area: time;
  position: relative;
`;

const VideoLengthBar = styled(DefaultLocation)`
  background: ${({ theme }) => theme.colors.white};
  opacity: 0.2;
  border-radius: 8px;
`;

const CurrentTimeBar = styled(DefaultLocation)`
  z-index: 1;
  background: ${({ theme }) => theme.colors.danger};

  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: ${({ width }) => width}%;
  left: ${({ left }) => left}%;
`;

const TooltipContainer = styled(CurrentTimeBar)`
  z-index: 0;
  position: absolute;
  top: -56px;
  background: inherit;
  width: ${({ width }) => width + 50}px;
  gap: 8px;
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    left: -12px;
    width: 100%;
  }
`;

const TimeTooltip = styled.div`
  border-radius: 5px;
  padding: 8px 16px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 1px #00000054;
  pointer-events: none;
  user-select: none;
`;

const LineContainer = styled.div`
  position: relative;
  cursor: pointer;
  user-select: none;
  ${({ isTimeBarBreakPoint }) => (isTimeBarBreakPoint ? null : 'padding:8px')};
`;

const Line = styled.div`
  background: ${({ theme }) => theme.colors.white};
  opacity: 0.6;
  height: 16px;
  width: 2px;
  position: relative;
  pointer-events: none;
  user-select: none;
`;

const VideoTime = styled(DefaultLocation)`
  z-index: 1;
  background: ${({ theme }) => theme.colors.white};
  left: ${({ time }) => time}%;
  height: 100%;
  width: 2px;
  position: absolute;
  border-radius: 2px;
  transition: 0.1s;
`;

export default TimeBar;
