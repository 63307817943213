import { nanoid } from 'nanoid';
import {
  AUDIO_RESPONSE_KEY,
  CALENDLY_RESPONSE_KEY,
  CHAT_RESPONSE_KEY,
  END_STEP,
  FREE_TEXT_RESPONSE_KEY,
  IMAGE_RESPONSE_KEY,
  LEAD_FORM_RESPONSE_KEY,
  LINK_RESPONSE_KEY,
  PROMO_CODE_RESPONSE_KEY,
  VIDEO_RESPONSE_KEY,
} from 'app/src/constants/tolstoy.constants';
import PromoCodeIcon from 'app/src/images/PromoCodeIcon';
import ChatResponseIcon from 'app/src/images/ChatResponseIcon';
import React from 'react';
import { TABS } from 'app/src/constants/editStep.constants';
import {
  DESCRIPTION_SIDE_PANEL_TYPE,
  SHOPABLE_SIDE_PANEL_TYPE,
} from 'shared/react/constants/feed.constants';
import { PRODUCTS_RESULT } from 'app/src/constants/editStep.constants';

export const DEFAULT_RESPONSE_OVERLAY_TEXT = 'Want to send a response?';
export const DEFAULT_QUIZ_STEP_OVERLAY_TEXT = 'Your Quiz Results:';

export const getDefaultAnswer = () => {
  return { text: '', next: END_STEP, type: '', value: '', key: nanoid(5) };
};

export const getDefaultReplyAnswer = () => {
  return {
    key: nanoid(5),
    next: 'url',
    text: 'Answer with link',
    type: LINK_RESPONSE_KEY,
    value: '',
  };
};

export const answerTypesWithLinks = [
  LINK_RESPONSE_KEY,
  CALENDLY_RESPONSE_KEY,
  PROMO_CODE_RESPONSE_KEY,
];

const defaultReplyAnswers = [
  { key: 't8RN9', next: END_STEP, text: 'Answer with video', type: VIDEO_RESPONSE_KEY, value: '' },
  {
    key: '-aaTh',
    next: END_STEP,
    text: 'Answer with text',
    type: FREE_TEXT_RESPONSE_KEY,
    value: '',
  },
];

const defaultResponses = [
  {
    key: 't8RND',
    next: END_STEP,
    text: 'Video answer',
    type: VIDEO_RESPONSE_KEY,
    value: '',
    disabled: false,
  },
  {
    key: '-aaTA',
    next: END_STEP,
    text: 'Audio answer',
    type: AUDIO_RESPONSE_KEY,
    value: '',
    disabled: false,
  },
  {
    key: '-aaTB',
    next: END_STEP,
    text: 'Text answer',
    type: FREE_TEXT_RESPONSE_KEY,
    value: '',
    disabled: false,
  },
];

export const getDefaultReplyAnswers = () => {
  return defaultReplyAnswers.map(({ ...properties }) => properties);
};

export const getDefaultResponsesAnswers = () => {
  return defaultResponses.map(({ ...properties }) => properties);
};

const getFeedSidePanelType = (isFeed, isEcomPlatform) => {
  if (!isFeed) {
    return undefined;
  }

  if (!isEcomPlatform) {
    return DESCRIPTION_SIDE_PANEL_TYPE;
  }

  return SHOPABLE_SIDE_PANEL_TYPE;
};

export const defaultStepData = (isFeed, isEcomPlatform) => {
  return {
    questionVisible: false,
    question: '',
    script: '',
    videoBlob: null,
    video: null,
    answerTime: '2',
    description: '',
    sideBarButtonsEnabled: false,
    answers: [],
    type: TABS.custom,
    multipleChoiceSelection: false,
    videoContain: false,
    products: null,
    projectId: '',
    overlayText: '',
    overlayTextColor: '#FFFFFF',
    defaultResponsesEnabled: false,
    defaultResponses: getDefaultResponsesAnswers(),
    productsAppUrl: null,
    feedSidePanelType: getFeedSidePanelType(isFeed, isEcomPlatform),
  };
};

export const defaultQuizProductStepData = () => {
  return {
    ...defaultStepData(false, true),
    type: PRODUCTS_RESULT,
    overlayText: DEFAULT_QUIZ_STEP_OVERLAY_TEXT,
  };
};

export const getMenuItems = handleAnswerTypeChanged => {
  return [
    {
      key: '',
      title: 'Next step',
      onClick: (e, index) => handleAnswerTypeChanged('', index),
    },
    {
      key: FREE_TEXT_RESPONSE_KEY,
      title: 'Free text',
      onClick: (e, index) => handleAnswerTypeChanged(FREE_TEXT_RESPONSE_KEY, index),
    },
    {
      key: LEAD_FORM_RESPONSE_KEY,
      title: 'Contact form',
      onClick: (e, index) => handleAnswerTypeChanged(LEAD_FORM_RESPONSE_KEY, index),
    },
    {
      key: VIDEO_RESPONSE_KEY,
      title: 'Video response',
      onClick: (e, index) => handleAnswerTypeChanged(VIDEO_RESPONSE_KEY, index),
    },
    {
      key: AUDIO_RESPONSE_KEY,
      title: 'Audio response',
      onClick: (e, index) => handleAnswerTypeChanged(AUDIO_RESPONSE_KEY, index),
    },
    {
      key: IMAGE_RESPONSE_KEY,
      title: 'Image response',
      onClick: (e, index) => handleAnswerTypeChanged(IMAGE_RESPONSE_KEY, index),
      tooltipTitle: 'Supported file types: JPG, PNG, PDF',
    },
    {
      key: CALENDLY_RESPONSE_KEY,
      title: 'Calendar',
      onClick: (e, index) => handleAnswerTypeChanged(CALENDLY_RESPONSE_KEY, index),
    },
    {
      key: PROMO_CODE_RESPONSE_KEY,
      title: 'Promo code',
      componentIcon: <PromoCodeIcon />,
      onClick: (e, index) => handleAnswerTypeChanged(PROMO_CODE_RESPONSE_KEY, index),
    },
    {
      key: CHAT_RESPONSE_KEY,
      title: 'Chat',
      componentIcon: <ChatResponseIcon />,
      onClick: (e, index) => handleAnswerTypeChanged(CHAT_RESPONSE_KEY, index),
    },
    {
      key: LINK_RESPONSE_KEY,
      title: 'Link',
      onClick: (e, index) => handleAnswerTypeChanged(LINK_RESPONSE_KEY, index),
    },
  ];
};
