import React from 'react';

const FilledUnmuteIcon = () => (
  <svg width="20" height="17" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.758.867A1.349 1.349 0 0 0 11.25.75c-.313 0-.625.117-.86.352L5.118 5.75H1.875C.82 5.75 0 6.61 0 7.625v3.71c0 1.017.82 1.837 1.875 1.837h3.242l5.274 4.687c.234.313.546.391.859.391.156 0 .313 0 .508-.078.43-.235.742-.664.742-1.172V2c0-.469-.313-.938-.742-1.133Zm-1.133 14.766-4.766-4.258H1.875v-3.71h3.984l4.766-4.259v12.227Zm5.469-9.024a1.053 1.053 0 0 0-.586-.195.962.962 0 0 0-.742.352.942.942 0 0 0 .156 1.328c.43.351.703.86.703 1.406a1.85 1.85 0 0 1-.703 1.445.942.942 0 0 0-.156 1.328.962.962 0 0 0 .742.352c.195 0 .43-.078.586-.195.898-.742 1.406-1.797 1.406-2.93 0-1.094-.508-2.148-1.406-2.89Zm2.383-2.89c-.196-.117-.391-.196-.625-.196-.274 0-.508.118-.704.352-.351.39-.273.977.118 1.328 1.328 1.055 2.109 2.656 2.109 4.297 0 1.68-.781 3.281-2.11 4.336-.39.351-.468.937-.117 1.328.196.234.43.352.704.352.234 0 .43-.079.625-.196 1.757-1.445 2.773-3.554 2.773-5.82 0-2.227-1.016-4.336-2.773-5.781Z"
      fill="#fff"
    />
  </svg>
);

export default FilledUnmuteIcon;
