import Types from './Types';
import Colors from '../constants/colors';

const white = Colors.white;
const black = Colors.black;

let theme = {};

theme[Types.Primary] = {
  main: {
    background: {
      normal: Colors.primary,
      hover: Colors.primaryHover,
      active: Colors.primaryActive,
    },
    text: {
      normal: white,
      hover: white,
      active: white,
    },
    arrow: {
      normal: white,
      hover: white,
      active: white,
    },
  },
  light: {
    background: {
      normal: Colors.primaryLight,
      hover: Colors.primaryLight,
      active: Colors.primaryLight,
    },
    text: {
      normal: black,
      hover: black,
      active: black,
    },
  },
  dark: {
    background: {
      normal: Colors.primaryDark,
      hover: Colors.primaryDark,
      active: Colors.primaryDark,
    },
    text: {
      normal: white,
      hover: white,
      active: white,
    },
  },
};
theme[Types.Secondary] = {
  main: {
    background: {
      normal: Colors.primaryLight,
      hover: Colors.primaryLightHover,
      active: Colors.primaryLightActive,
    },
    text: {
      normal: Colors.primary,
      hover: Colors.primary,
      active: Colors.primary,
    },
    arrow: {
      normal: Colors.primary,
      hover: Colors.primary,
      active: Colors.primary,
    },
  },
  light: {
    background: {
      normal: Colors.primaryLight,
      hover: Colors.primaryLight,
      active: Colors.primaryLight,
    },
    text: {
      normal: Colors.primary,
      hover: Colors.primary,
      active: Colors.primary,
    },
  },
  dark: {
    background: {
      normal: Colors.primaryLight,
      hover: Colors.primaryLight,
      active: Colors.primaryLight,
    },
    text: {
      normal: Colors.primary,
      hover: Colors.primary,
      active: Colors.primary,
    },
  },
};
theme[Types.Ghost] = {
  main: {
    background: {
      normal: white,
      hover: white,
      active: white,
    },
    text: {
      normal: black,
      hover: black,
      active: Colors.primaryDark,
    },
    border: {
      normal: Colors.ghostLight,
      hover: Colors.ghostDark,
      active: Colors.primaryDark,
    },
    arrow: {
      normal: black,
      hover: black,
      active: Colors.primaryDark,
    },
  },
  light: {
    background: {
      normal: Colors.primaryLight,
      hover: Colors.primaryLight,
      active: Colors.primaryLight,
    },
    text: {
      normal: Colors.primary,
      hover: Colors.primary,
      active: Colors.primary,
    },
    border: {
      normal: Colors.ghostLight,
      hover: Colors.ghostDark,
      active: Colors.primaryDark,
    },
  },
  dark: {
    background: {
      normal: Colors.primaryLight,
      hover: Colors.primaryLight,
      active: Colors.primaryLight,
    },
    text: {
      normal: Colors.primary,
      hover: Colors.primary,
      active: Colors.primary,
    },
    border: {
      normal: Colors.ghostLight,
      hover: Colors.ghostDark,
      active: Colors.primaryDark,
    },
  },
};
theme[Types.SecondaryGhost] = {
  main: {
    background: {
      normal: 'transparent',
      hover: 'transparent',
      active: 'transparent',
    },
    text: {
      normal: Colors.primary,
      hover: Colors.primary,
      active: Colors.primary,
    },
    border: {
      normal: Colors.primary,
      hover: Colors.primary,
      active: Colors.primary,
    },
    arrow: {
      normal: Colors.primary,
      hover: Colors.primary,
      active: Colors.primary,
    },
  },
  light: {
    background: {
      normal: Colors.primaryLight,
      hover: Colors.primaryLight,
      active: Colors.primaryLight,
    },
    text: {
      normal: Colors.primary,
      hover: Colors.primary,
      active: Colors.primary,
    },
  },
  dark: {
    background: {
      normal: Colors.primaryLight,
      hover: Colors.primaryLight,
      active: Colors.primaryLight,
    },
    text: {
      normal: Colors.primary,
      hover: Colors.primary,
      active: Colors.primary,
    },
  },
};
theme[Types.Warning] = {
  main: {
    background: {
      normal: Colors.warning,
      hover: Colors.warning,
      active: Colors.warning,
    },
    text: {
      normal: white,
      hover: white,
      active: white,
    },
  },
  light: {
    background: {
      normal: Colors.warningLight,
      hover: Colors.warningLight,
      active: Colors.warningLight,
    },
    text: {
      normal: black,
      hover: black,
      active: black,
    },
  },
  dark: {
    background: {
      normal: Colors.warningDark,
      hover: Colors.warningDark,
      active: Colors.warningDark,
    },
    text: {
      normal: white,
      hover: white,
      active: white,
    },
  },
};
theme[Types.Success] = {
  main: {
    background: {
      normal: Colors.success,
      hover: Colors.successHover,
      active: Colors.successActive,
    },
    text: {
      normal: white,
      hover: white,
      active: white,
    },
  },
  light: {
    background: {
      normal: Colors.successLight,
      hover: Colors.successLight,
      active: Colors.successLight,
    },
    text: {
      normal: black,
      hover: black,
      active: black,
    },
  },
  dark: {
    background: {
      normal: Colors.successDark,
      hover: Colors.successDark,
      active: Colors.successDark,
    },
    text: {
      normal: white,
      hover: white,
      active: white,
    },
  },
};
theme[Types.Danger] = {
  main: {
    background: {
      normal: Colors.danger,
      hover: Colors.pink2,
      active: Colors.danger,
    },
    text: {
      normal: white,
      hover: white,
      active: white,
    },
  },
  light: {
    background: {
      normal: Colors.dangerLight,
      hover: Colors.dangerLight,
      active: Colors.dangerLight,
    },
    text: {
      normal: black,
      hover: black,
      active: black,
    },
  },
  dark: {
    background: {
      normal: Colors.dangerDark,
      hover: Colors.dangerDark,
      active: Colors.dangerDark,
    },
    text: {
      normal: white,
      hover: white,
      active: white,
    },
  },
};
theme[Types.Info] = {
  main: {
    background: {
      normal: Colors.info,
      hover: Colors.info,
      active: Colors.info,
    },
    text: {
      normal: white,
      hover: white,
      active: white,
    },
  },
  light: {
    background: {
      normal: Colors.infoLight,
      hover: Colors.infoLight,
      active: Colors.infoLight,
    },
    text: {
      normal: black,
      hover: black,
      active: black,
    },
  },
  dark: {
    background: {
      normal: Colors.infoDark,
      hover: Colors.infoDark,
      active: Colors.infoDark,
    },
    text: {
      normal: white,
      hover: white,
      active: white,
    },
  },
};
theme[Types.Neutral] = {
  1: Colors.black,
  2: Colors.black2,
  3: '#2F3137',
  4: Colors.gray2,
  5: Colors.ghostDark,
  6: Colors.gray4,
  7: Colors.ghostLight,
  8: Colors.gray3,
  9: Colors.gray5,
};

const Theme = theme;
export default Theme;
