import React, { FC } from 'react';
import styled from 'styled-components';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import { embedCarouselPreviewSize } from 'app/src/pages/project/pages/look_and_feel/preview/embed_carousel_preview/EmbedPreviewCss';

const BREAKPOINT = '1350px';
const SECOND_BREAKPOINT = '1160px';

const EmbedCarouselPreview: FC = ({ children }) => {
  return (
    <LayoutRoot>
      <GrayLine />
      {children}
      <GrayLine />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.gray4};
  border-radius: 4px;
  padding: 24px 0;
  gap: 24px;
  height: 100%;

  ${embedCarouselPreviewSize}
`;

const GrayLine = styled.div`
  height: 100%;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.neutralLightest};
  margin: 0 24px;
`;

export default EmbedCarouselPreview;
