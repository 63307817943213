import { CircularProgress } from '@material-ui/core';
import React, { FC, ReactElement } from 'react';

type Props = {
  isLoading?: boolean;
  renderLoader?: (el?: ReactElement) => ReactElement;
  size?: number;
  children?: ReactElement | ReactElement[];
};

const ComponentWithLoader: FC<Props> = ({ isLoading, renderLoader, children, size, ...props }) => {
  if (isLoading) {
    const circularProgress = <CircularProgress size={size || 24} {...props} />;
    if (renderLoader) {
      return renderLoader(circularProgress);
    }
    return circularProgress;
  }

  return <>{children}</>;
};

export default ComponentWithLoader;
