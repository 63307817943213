import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import BasicAccordion from 'shared/react/components/BasicAccordion';
import { AccordionTitle } from 'app/src/basic_components/commonStyles';
import {
  GORGIAS,
  GREEN_HOUSE,
  HUBSPOT,
  INTERCOM,
  KLAVIYO,
  MONDAY,
  OUTREACH,
} from 'app/src/constants/intergrations.constants';
import { useApps } from 'app/src/context/AppsStore';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import GorgiasIcon from 'app/src/images/integration_notification_settings_icons/GorgiasIcon';
import GreenHouseIcon from 'app/src/images/integration_notification_settings_icons/GreenhouseIcon';
import HubspotIcon from 'app/src/images/integration_notification_settings_icons/HubspotIcon';
import IntercomIcon from 'app/src/images/integration_notification_settings_icons/IntercomIcon';
import KlaviyoIcon from 'app/src/images/integration_notification_settings_icons/KlaviyoIcon';
import MondayIcon from 'app/src/images/integration_notification_settings_icons/MondayIcon';
import OutreachIcon from 'app/src/images/integration_notification_settings_icons/OutreachIcon';
import IntegrationNotifyToggle from './IntegrationNotifyToggle';
import { HUBSPOT_MAPPING_MODAL_KEY } from 'app/src/constants/modals.constants';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { track } from 'app/src/helpers/Tracker';

const integrationIcons = {
  [MONDAY]: <MondayIcon />,
  [GORGIAS]: <GorgiasIcon />,
  [GREEN_HOUSE]: <GreenHouseIcon />,
  [INTERCOM]: <IntercomIcon />,
  [HUBSPOT]: <HubspotIcon />,
  [OUTREACH]: <OutreachIcon />,
  [KLAVIYO]: <KlaviyoIcon />,
};

const IntegrationsNotificationSettings = () => {
  const [, { getNotifiableActiveApps }] = useApps();
  const [, { setCurrentModal, setCurrentProjectId }] = useModal();
  const { projectId } = useParams();

  const notifiableActiveApps = getNotifiableActiveApps(projectId);

  const openHubspotModal = () => {
    track('Hubspot Mapping Fields Click');
    setCurrentProjectId(projectId);
    setCurrentModal(HUBSPOT_MAPPING_MODAL_KEY);
  };

  const appsWithActions = {
    [HUBSPOT]: {
      key: HUBSPOT,
      onClick: openHubspotModal,
      text: 'Map fields',
    },
  };

  if (!Object.keys(notifiableActiveApps).length) {
    return null;
  }

  return (
    <LayoutRoot>
      <MainContainer>
        <BasicAccordion
          header={<AccordionTitle>Integrations</AccordionTitle>}
          startExpanded={false}
        >
          <ToggleSettingsContainer>
            {Object.entries(notifiableActiveApps).map(([appName, notify]) => {
              return (
                <IntegrationNotifyToggle
                  key={appName}
                  appName={appName}
                  icon={integrationIcons[appName]}
                  enabled={notify}
                  projectId={projectId}
                  appsWithActions={appsWithActions}
                />
              );
            })}
          </ToggleSettingsContainer>
        </BasicAccordion>
      </MainContainer>
    </LayoutRoot>
  );
};

export default IntegrationsNotificationSettings;

const LayoutRoot = styled.div`
  display: grid;
  align-items: start;
  align-content: start;
  padding: 24px;
  overflow-y: auto;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    padding: 16px;
  }
`;

const MainContainer = styled(Gap16VerticalFlex)`
  max-width: 400px;
`;

const ToggleSettingsContainer = styled(Gap16VerticalFlex)`
  padding: 24px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  margin-top: 16px;
`;
