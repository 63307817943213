import { URL_PARAMS } from 'shared/react/constants/url.constants';
import { TOLSTOY_CUSTOM_FONT_FAMILY } from '../constants/playerSettings.constants';
import { REACT_APP_BASE_URL, REACT_APP_API_BASE_URL } from 'shared/react/config/shared.config';
import {
  TOLSTOY_AUTO_OPEN_URL_PARAM,
  TOLSTOY_VIDEO_ID_URL_PARAM,
} from 'player/src/constants/urls.constants';

// Shared utils
class Utils {
  static iterateElementChildrenRecursively(element, action) {
    for (let i = 0; i < element.childNodes.length; i++) {
      const child = element.childNodes[i];
      this.iterateElementChildrenRecursively(child);
      action?.(child);
    }
  }
  static loadFont(sources, fontFamily) {
    const ITALIC_VALUE = 'italic';
    const NORMAL_VALUE = 'normal';
    const OTF_VALUE = '.otf';

    const newStyle = document.createElement('style');
    for (const sourceKey of Object.keys(sources)) {
      const url = sources[sourceKey];
      const isItalic = sourceKey.includes(ITALIC_VALUE);
      const fontWeight = sourceKey.replace(ITALIC_VALUE, '') || NORMAL_VALUE;
      const fontStyle = isItalic ? ITALIC_VALUE : NORMAL_VALUE;
      const isOTF = url.includes(OTF_VALUE);
      const fontFormat = isOTF ? 'opentype' : 'truetype';
      const httpsUrl = url.replace('http://', 'https://');

      newStyle.append(
        document.createTextNode(
          `@font-face { font-family: '${fontFamily}'; src: url("${httpsUrl}") format("${[
            fontFormat,
          ]}"); font-weight: ${fontWeight}; font-style: ${fontStyle};}`
        )
      );
    }

    document.head.append(newStyle);
  }

  static reloadFont(fontCustomization, setFunction, fontFamilyName = TOLSTOY_CUSTOM_FONT_FAMILY) {
    if (!fontCustomization) {
      return;
    }

    const { family: fontFamily, sources } = fontCustomization;

    if (!fontFamily || !sources) {
      return;
    }
    const fixedFamilyName = fontFamilyName.replace(/[\s()]/g, '-');

    this.loadFont(sources, fixedFamilyName);
    setFunction(fixedFamilyName);
  }

  static removeQueryString(url) {
    return url?.split('?')[0] || '';
  }

  static isNullOrUndefined(value) {
    return value === null || value === undefined;
  }

  static openInNewTab(url) {
    if (!url.match(/^https?:\/\//i)) {
      url = 'https://' + url;
    }
    const win = window.open(url, '_blank');
    win.focus();
  }

  static openInTheSameTab(url, isPreserveHistory = false) {
    if (!url.match(/^https?:\/\//i)) {
      url = 'https://' + url;
    }

    if (isPreserveHistory) {
      return window.open(url, '_self');
    }

    window.location.replace(url);
  }

  static isSafari() {
    return window.safari !== undefined || (navigator.vendor.match(/apple/i) || '').length > 0;
  }

  static isIos() {
    return (
      /iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    );
  }

  static isIphone() {
    return navigator.platform === 'iPhone' || navigator.platform === 'iPhone Simulator';
  }

  static isAndroid() {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('android') > -1; //&& ua.indexOf("mobile");
  }

  static isMobile() {
    return this.isAndroid() || this.isIos();
  }

  static addZeroIfNeeded = value => {
    return value < 10 ? `0${value}` : value;
  };

  static secondsToTime = (seconds, includeHours = true, showZeroSeconds) => {
    if (!seconds && !showZeroSeconds) {
      return;
    }

    const hoursValue = Math.floor(seconds / 3600);
    const minutesValue = Math.floor((seconds / 60) % 60);
    const secondsValue = Math.floor(seconds % 60);

    return `${includeHours ? `${this.addZeroIfNeeded(hoursValue)}:` : ''}${this.addZeroIfNeeded(
      minutesValue
    )}:${this.addZeroIfNeeded(secondsValue)}`;
  };

  static millisecondsToTime = (milliseconds, includeHours, showZeroSeconds) => {
    return this.secondsToTime(milliseconds / 1000, includeHours, showZeroSeconds);
  };

  static secondsToTimeString = (seconds, isLetterFormat = true) => {
    if (!seconds) {
      return;
    }

    const min = Math.floor(seconds / 60);
    const sec = this.addZeroIfNeeded(Math.floor(seconds % 60));

    if (isLetterFormat) {
      return `${min}m ${sec}s`;
    }

    return `${min}:${sec}`;
  };

  static formatBytes(bytes, decimals = 0) {
    if (!bytes) {
      return '0 Bytes';
    }

    const kilobyte = 1024;
    const units = ['Bytes', 'KB', 'MB', 'GB'];

    const unitIndex = Math.floor(Math.log(bytes) / Math.log(kilobyte));
    const size = parseFloat((bytes / Math.pow(kilobyte, unitIndex)).toFixed(decimals));
    const unitType = units[unitIndex];

    return `${size}${unitType}`;
  }

  static getAnalyticsLocationObject = location => {
    return { location };
  };

  static range(start, end) {
    const numbers = [];
    for (let i = start; i <= end; i++) {
      numbers.push({ id: i, name: i });
    }
    return numbers;
  }

  static postMessageToWindow(message, targetOrigin = '*') {
    const modalId = this.getModalId();

    return window.parent.postMessage({ ...message, modalId }, targetOrigin);
  }

  static postMessage(message, targetOrigin = '*') {
    return window.postMessage(message, targetOrigin);
  }

  static stopPropagation(e) {
    e.stopPropagation();
  }

  static getDataType(dataType) {
    return `[data-type="${dataType}"]`;
  }

  static downloadText(fileData, filename, type = 'text/plain', suffix = '.txt') {
    const blob = new Blob([fileData], { type });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = `${filename}${suffix}`;
    link.href = url;
    link.click();
  }

  static copyToClipboard(text) {
    let textarea;
    let result;

    try {
      textarea = document.createElement('textarea');
      textarea.setAttribute('readonly', true);
      textarea.setAttribute('contenteditable', true);
      textarea.style.position = 'fixed';
      textarea.value = text;

      document.body.appendChild(textarea);

      textarea.focus();
      textarea.select();

      const range = document.createRange();
      range.selectNodeContents(textarea);

      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);

      textarea.setSelectionRange(0, textarea.value.length);
      result = document.execCommand('copy');
    } catch (err) {
      console.error(err);
      result = null;
    } finally {
      document.body.removeChild(textarea);
    }

    return result;
  }

  static logError({ exception, message }) {
    if (!window.Sentry) {
      return console.error(exception, message);
    }

    if (message) {
      return window.Sentry.captureException(exception, {
        tags: {
          message: message,
        },
      });
    }

    return window.Sentry.captureException(exception);
  }

  static getIsShopifyCDN(videoUrl) {
    if (!videoUrl) {
      return false;
    }

    return videoUrl.includes('cdn.shopify.com');
  }

  static deepCopyObject(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  static isValidHTMLElementId = value => {
    // is empty string or starting with a letter and containing only letters, digits, "_", ":", "."
    return /^$|^[A-Za-z][-A-Za-z\d_:.]*$/.test(value);
  };

  static numberToLocaleString = number => Number(number).toLocaleString();

  static isRound(number) {
    return Number.isInteger(number);
  }

  static getDisplayedPrice = ({ price, currencySymbol = '' }) => {
    if (!price) {
      return '';
    }

    const priceNumber = +price;
    const formattedPrice = priceNumber.toFixed(this.isRound(priceNumber) ? 0 : 2);

    return `${currencySymbol}${formattedPrice}`;
  };

  static getDisplayedPriceSegments = ({ price, currencySymbol = '' }) => {
    if (!price) {
      return {
        currencySymbol: '',
        formattedPrice: '',
      };
    }

    const priceNumber = +price;
    const formattedPrice = priceNumber.toFixed(this.isRound(priceNumber) ? 0 : 2);

    return {
      currencySymbol,
      formattedPrice,
    };
  };

  static getParentUrl = () => {
    const params = new URLSearchParams(window.location.search);

    return params.get('url') || '';
  };

  static getSiteUrl = () => {
    const parentUrl = this.getParentUrl();

    if (!parentUrl) {
      return '';
    }

    return new URL(parentUrl).origin;
  };

  static getIsLandingPage() {
    return !window.location.search.includes('host');
  }

  static validateEmail(email) {
    if (!email) {
      return false;
    }

    const EMAIL_REGEX =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return String(email).toLowerCase().match(EMAIL_REGEX);
  }

  static safeRoundNumber = (value, digits = 1) => {
    const number = +value;

    if (isNaN(number)) {
      return 0;
    }

    if (Number.isInteger(number)) {
      return number;
    }

    return parseFloat(number.toFixed(digits));
  };

  static getParam(param) {
    const params = new URLSearchParams(window.location.search);
    return params.get(param);
  }

  static getModalId() {
    return this.getParam(URL_PARAMS.modalId);
  }

  static isFirefox() {
    return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  }

  static getIsAppPreview() {
    return window.location.origin === REACT_APP_BASE_URL;
  }

  static replaceToNewPlayerUrl(url) {
    return url.replace('play', 'player');
  }

  static getPlayerShareUrl(publishId, currentStep) {
    const playerUrl = `${this.replaceToNewPlayerUrl(location.origin)}`;
    const parentUrl = this.getParentUrl();

    let url = new URL(parentUrl || playerUrl);
    if (parentUrl) {
      url.searchParams.append(TOLSTOY_AUTO_OPEN_URL_PARAM, publishId);
    }

    url.searchParams.append('utm_source', 'tolstoy');
    url.searchParams.append('utm_medium', 'feed_share');

    if (currentStep) {
      url.searchParams.append(TOLSTOY_VIDEO_ID_URL_PARAM, currentStep);
    }

    return url.href;
  }

  static getPageWithPlayerUrl(publishId, currentStep) {
    const playerUrl = `${this.replaceToNewPlayerUrl(location.origin)}`;
    const parentUrl = this.getParentUrl();

    let url = new URL(parentUrl || playerUrl);
    if (parentUrl) {
      url.searchParams.append(TOLSTOY_AUTO_OPEN_URL_PARAM, publishId);
    }

    if (currentStep) {
      url.searchParams.append(TOLSTOY_VIDEO_ID_URL_PARAM, currentStep);
    }

    return url.href;
  }

  static reloadPageWithPlayer(publishId, currentStep) {
    window.parent.location.href = this.getPageWithPlayerUrl(publishId, currentStep);
  }

  static getYoutubeEmbedUrl(videoId) {
    return `https://www.youtube.com/embed/${videoId}`;
  }

  static getCustomerGoogleAuthUrl(appUrl, appKey, redirectUrl) {
    return `${REACT_APP_API_BASE_URL}/customer/auth/google/login?appUrl=${appUrl}&appKey=${appKey}&redirectUrl=${redirectUrl}`;
  }
  static uppercaseFirstChar(text) {
    if (!text?.length) {
      return '';
    }

    return `${text[0]?.toUpperCase()}${text?.slice(1)}`;
  }

  static getShopifyImageAndSetHeight = (src, height) => {
    if (Utils.getIsShopifyCDN(src)) {
      return `${src}&height=${height}`;
    }

    return src;
  };
}

export default Utils;
