import React from 'react';
import styled from 'styled-components';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import GoToNextAnswerIcon from 'app/src/images/GoToNextAnswerIcon';
import Tooltip from 'app/src/basic_components/Tooltip';
import AnswerNext from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/tolstoy_list/answer/AnswerNext';
import { getAnswerImage } from 'app/src/images/getAnswerImage';
import PlusIcon from 'app/src/images/PlusIcon';

const GoTo = ({
  type,
  onCollectViewerInfo,
  index,
  next,
  stepsOrder,
  isProductType,
  onChooseAnswerNext,
  multipleChoiceSelection,
  productCtaType,
  isFeed,
  ecomPlatform,
  ...props
}) => {
  const onResponseTypeClick = e => {
    onCollectViewerInfo(e, index);
  };

  const getTypeTooltipText = () => {
    if (multipleChoiceSelection) {
      return 'When using multiple selection, you cannot enable button actions. To add button actions, disable multiple selection in the advanced section.';
    }

    if (type) {
      return '';
    }

    return 'Add interaction';
  };

  const getNextTooltipText = () => {
    if (multipleChoiceSelection) {
      return 'This will update all buttons destinations. When using multiple selection, all buttons must lead to the same next step. To enable different destinations, disable multiple selection in the advanced section.';
    }

    return '';
  };

  return (
    <LayoutRoot {...props}>
      {!!onCollectViewerInfo && (
        <div onClick={onResponseTypeClick}>
          <Tooltip text={getTypeTooltipText()}>
            <IconContainer>{getAnswerImage({ type, defaultIcon: <PlusIcon /> })}</IconContainer>
          </Tooltip>
        </div>
      )}
      <GoToNextAnswerIcon />
      <Tooltip text={getNextTooltipText()}>
        <AnswerNext
          stepsOrder={stepsOrder}
          next={next}
          type={type}
          isProductType={isProductType}
          onChooseAnswerNext={onChooseAnswerNext}
          index={index}
          productCtaType={productCtaType}
          isFeed={isFeed}
          ecomPlatform={ecomPlatform}
        />
      </Tooltip>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlexWrap)`
  padding: 6px 8px 6px 16px;
  gap: 8px;
  align-items: center;
  border-radius: 45px;
  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    padding: 6px;
    border-radius: 16px;
    max-width: fit-content;
    justify-content: space-evenly;
  }
`;

const IconContainer = styled.div`
  border-radius: 65px;
  position: relative;
  padding: 6px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.primaryLight};
  cursor: pointer;

  & path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

export default GoTo;
