import React, { useState } from 'react';
import styled from 'styled-components';
import { useAccount } from 'app/src/context/AccountStore';
import { MenuItem } from '@material-ui/core';
import { useAnalytics } from 'app/src/context/AnalyticsStore';
import { useProjects } from 'app/src/context/ProjectsStore';
import AnalyticsFilter from './AnalyticsFilter';
import { track } from 'app/src/helpers/Tracker';
import Utils from 'app/src/utils';

const ALL = 'all';

const getPublishIdsByOwner = (projects, owner) => {
  const publishIds = projects.flatMap(({ owner: projectOwner, publishId }) => {
    if (owner !== projectOwner) {
      return [];
    }
    return publishId;
  });
  return publishIds;
};

const getFilteredTeamMember = (teamMembers, isAccountOwner) => {
  if (!isAccountOwner) {
    return teamMembers.filter(({ userId }) => userId === Utils.getOwner());
  }
  return teamMembers.filter(({ userId }) => userId);
};

const AnalyticsOwnerFilter = () => {
  const [owner, setOwner] = useState(ALL);
  const [, { setPublishIds }] = useAnalytics();
  const [{ projects }] = useProjects();
  const [{ teamMembers, isAccountOwner }, { findUser }] = useAccount();

  const onOwnerUpdate = newOwner => {
    setOwner(newOwner);

    track('Analytics Owner Filter Click');

    if (newOwner === ALL) {
      setPublishIds(null);
      return;
    }

    const publishIds = getPublishIdsByOwner(projects, newOwner);
    setPublishIds(publishIds);
  };

  const getSelectedValue = value => {
    if (value === ALL) {
      return 'All';
    }
    return findUser(value)?.email;
  };

  const filteredTeamMembers = getFilteredTeamMember(teamMembers, isAccountOwner);

  return (
    <StyledAnalyticsOwnerFilter>
      <AnalyticsFilter
        value={owner}
        onChange={onOwnerUpdate}
        getSelectedValue={getSelectedValue}
        label="Creator"
      >
        <MenuItem key={ALL} value={ALL}>
          All
        </MenuItem>
        {filteredTeamMembers.map(({ email, userId }) => (
          <MenuItem
            key={email}
            value={userId}
            disabled={!getPublishIdsByOwner(projects, userId).length}
          >
            {email}
          </MenuItem>
        ))}
      </AnalyticsFilter>
    </StyledAnalyticsOwnerFilter>
  );
};

export default AnalyticsOwnerFilter;

const StyledAnalyticsOwnerFilter = styled.div`
  display: flex;
  gap: 24px;
`;
