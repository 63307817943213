import useMediaQuery from 'shared/react/hooks/useMediaQuery';
import Utils from 'shared/react/utils/utils';
import { useTheme } from 'styled-components';

export const useFeedIsMobile = () => {
  const theme = useTheme();
  if (Utils.getIsAppPreview()) {
    return true;
  }

  return useMediaQuery(`(${theme.breakpoints.mobileMax})`) || Utils.isMobile();
};

export const useIsMobile = () => {
  const theme = useTheme();

  return useMediaQuery(`(${theme.breakpoints.mobileMax})`) || Utils.isMobile();
};

export const useIsTablet = () => {
  const theme = useTheme();

  return useMediaQuery(`(${theme.breakpoints.tabletMax})`) || Utils.isMobile();
};
