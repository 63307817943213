import React from 'react';

const AudioIcon = ({ fill, darkBackground }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0015 19.7221C15.371 19.7221 19.7238 15.3693 19.7238 9.99984C19.7238 4.63039 15.371 0.277588 10.0015 0.277588C4.6321 0.277588 0.279297 4.63039 0.279297 9.99984C0.279297 15.3693 4.6321 19.7221 10.0015 19.7221Z"
        fill={fill || '#FFFFFF'}
      />
      <path
        d="M10.0007 11.9791C11.2083 11.9791 12.1882 11.0221 12.1882 9.79158V6.14575C12.1882 4.93807 11.2083 3.95825 10.0007 3.95825C8.77015 3.95825 7.81315 4.93807 7.81315 6.14575V9.79158C7.81315 11.0221 8.77015 11.9791 10.0007 11.9791ZM13.4642 8.33325C13.1452 8.33325 12.9173 8.58392 12.9173 8.88009V9.79158C12.9173 11.4778 11.5046 12.8222 9.79557 12.7083C8.29167 12.6171 7.08398 11.2043 7.08398 9.67767V8.88009C7.08398 8.58392 6.83333 8.33325 6.53711 8.33325C6.2181 8.33325 5.99023 8.58392 5.99023 8.88009V9.63209C5.99023 11.6601 7.42578 13.483 9.45382 13.7564V14.5312H8.54232C8.10938 14.5312 7.79037 14.8958 7.81315 15.3059C7.81315 15.4882 7.97266 15.6249 8.17773 15.6249H11.8236C12.0058 15.6249 12.1654 15.4882 12.1654 15.3059C12.1882 14.8958 11.8692 14.5312 11.459 14.5312H10.5475V13.7793C12.4844 13.5058 14.0111 11.8196 14.0111 9.79158V8.88009C14.0111 8.58392 13.7604 8.33325 13.4642 8.33325Z"
        fill={darkBackground ? '#FFFFFF' : '#2F3137'}
      />
    </svg>
  );
};

export default AudioIcon;
