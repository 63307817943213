import React from 'react';
import styled from 'styled-components';
import AnswerContainer from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/AnswerContainer';
import Gap16HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap16HorizontalFlexWrap';
import GoTo from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/GoTo';
import {
  defaultResponsesBackgroundColors,
  defaultResponsesIconColors,
  defaultResponsesIcons,
} from 'app/src/constants/defaultResponseData';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';

const Answer = ({ answer, index, stepsOrder, onCollectViewerInfo, onChooseAnswerNext }) => {
  const answerColor = defaultResponsesBackgroundColors[answer.type];
  const textColor = defaultResponsesIconColors[answer.type];
  const Icon = defaultResponsesIcons[answer.type];
  return (
    <LayoutRoot>
      <IfContainer>
        <AnswerType background={answerColor}>
          <IconContainer>
            <Icon fill={textColor} />
          </IconContainer>
          <AnswerText textColor={textColor}>{answer.text}</AnswerText>
        </AnswerType>
      </IfContainer>
      <CustomGoTo
        onCollectViewerInfo={onCollectViewerInfo}
        onChooseAnswerNext={onChooseAnswerNext}
        isProductType={false}
        stepsOrder={stepsOrder}
        index={index}
        {...answer}
      />
      <div />
    </LayoutRoot>
  );
};

const IfContainer = styled(Gap16HorizontalFlexWrap)`
  align-items: center;
  flex-wrap: nowrap;
`;

const AnswerType = styled(Gap8HorizontalFlexWrap)`
  background: ${({ background }) => background};
  padding: 6px 16px;
  border-radius: 26px;
  align-items: center;
`;
const AnswerText = styled.div`
  color: ${({ textColor }) => textColor};
`;

const LayoutRoot = styled(AnswerContainer)`
  gap: 0;
  &:hover {
    background: ${({ theme }) => theme.colors.gray1};
  }

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    gap: 0;
  }
`;

const IconContainer = styled.div`
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
`;

const CustomGoTo = styled(GoTo)`
  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    max-width: 100%;
    padding: 6px 6px 6px 8px;
  }
`;

export default Answer;
