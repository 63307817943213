import React from 'react';
import styled from 'styled-components';
import { TextBody } from 'shared/react/components/complex/Text';

function InitialsBadge({ initials, imageSrc, ...props }) {
  return <Badge {...props}>{imageSrc ? <Image src={imageSrc} alt={initials} /> : initials}</Badge>;
}

export default InitialsBadge;

const Badge = styled(TextBody)`
  font-weight: bold;
  font-size: 9px;
  display: grid;
  place-content: center;
  width: 16px;
  height: 16px;
  line-height: 18px;
  text-align: center;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme, backgroundColor }) => backgroundColor || theme.colors.blue12};
`;

const Image = styled.img`
  width: 16px;
  height: 16px;
  border-radius: inherit;
`;
