import React from 'react';
import styled from 'styled-components';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import usePlayerPreviewSettings from 'app/src/hooks/preview/usePlayerPreviewSettings';
import PlayerWrapper from 'player/src/components/player/PlayerWrapper';
import FeedWrapper from 'player/src/components/feed/FeedWrapper';

import 'player/src/assets/client.scss';
import 'player/src/assets/input_range.scss';
import 'player/src/assets/input_modal.scss';
import 'player/src/index.css';
import { useDynamicConditionsContext } from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/context/DynamicConditionsContext';

function PlayerPreview() {
  const { project, customizationSettings } = useLookAndFeelContext();
  const { vodAssetIds } = useDynamicConditionsContext();
  const { playerSettings, products, isProductsInitialized } = usePlayerPreviewSettings(
    project,
    customizationSettings,
    vodAssetIds
  );

  if (!playerSettings.name || (project.feed && !vodAssetIds.length) || project.quiz) {
    return null;
  }

  if (!project.feed) {
    return (
      <LayoutRoot>
        <PlayerWrapper
          isProductsInitialized={isProductsInitialized}
          products={products}
          config={playerSettings}
        />
      </LayoutRoot>
    );
  }

  return (
    <LayoutRoot>
      <FeedWrapperRoot
        isProductsInitialized={isProductsInitialized}
        products={products}
        config={playerSettings}
        isPlayerPreview
        searchControls={{}}
      />
    </LayoutRoot>
  );
}

const LayoutRoot = styled(HorizontalFlex)`
  width: 100%;
`;

const FeedWrapperRoot = styled(FeedWrapper)`
  height: 600px;
  min-width: 337.5px;
  width: 337.5px;
  max-width: 276px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.boxShadows.elevation4};
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
`;

export default PlayerPreview;
