import React from 'react';
import { useAnalytics } from 'app/src/context/AnalyticsStore';
import AnalyticsMetrics from '../AnalyticsMetrics';
import Utils from 'shared/react/utils/utils';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import styled from 'styled-components';
import { EVENTS } from 'src/constants/tolstoy.constants';

const AnalyticsEngagementMetrics = () => {
  const [{ engagementMetrics, unique }, { getTimeOnPageData }] = useAnalytics();

  const getUniqueKey = key => {
    return unique ? `${key}Unique` : key;
  };

  const ENGAGEMENT_METRICS = [
    {
      id: getUniqueKey(EVENTS.pageView),
      color: 'pink7',
      subtitle: 'impressions',
      footerText: 'Opened email',
      footerValueId: 'openEmail',
      tooltipText: `Impressions track the number of times your Tolstoy was displayed to${
        unique ? ' unique' : ''
      } visitors`,
    },
    {
      id: getUniqueKey(EVENTS.sessionStart),
      color: 'blue7',
      subtitle: 'Plays',
      footerPercentageFromId: getUniqueKey(EVENTS.pageView),
      footerText: 'Play rate',
    },
    {
      id: getUniqueKey(EVENTS.clickCta),
      color: 'success',
      subtitle: 'Interactions',
      footerPercentageFromId: getUniqueKey(EVENTS.sessionStart),
      footerText: 'Interaction rate',
    },
  ];

  const getTimeOnPageMetrics = () => {
    return [
      {
        id: 'averageTimeSpent',
        color: 'warning',
        subtitle: 'Avg. session duration',
        footerValueId: 'totalTimeSpent',
        footerText: 'Total time',
        formatted: true,
        nonUnique: true,
      },
    ];
  };

  const getTimeOnPageMetricsData = () => {
    const timeOnPageData = getTimeOnPageData();

    if (!timeOnPageData) {
      return null;
    }

    const {
      averageTimeOnTolstoy: averageTimeOnTolstoyMilliseconds = 0,
      sumTimeOnTolstoy: sumTimeOnTolstoyMilliseconds = 0,
    } = timeOnPageData;

    const averageTimeOnTolstoy = Utils.millisecondsToTime(
      averageTimeOnTolstoyMilliseconds,
      false,
      true
    );
    const sumTimeOnTolstoy = Utils.millisecondsToTime(sumTimeOnTolstoyMilliseconds, true, true);

    return {
      averageTimeSpent: averageTimeOnTolstoy,
      totalTimeSpent: sumTimeOnTolstoy,
    };
  };

  return (
    <LayoutRoot>
      <AnalyticsMetrics data={engagementMetrics} metrics={ENGAGEMENT_METRICS} isUnique={unique} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)`
  gap: 36px;
`;

export default AnalyticsEngagementMetrics;
