import { VideosPageVodAsset } from 'app/src/pages/dashboard/pages/videos/types/videos.types';
import { useMemo } from 'react';
import { useVideos } from 'app/src/context/VideosStore';
import { VIDEO_STATUS } from 'shared/react/constants/video.constants';

export const useGlobalVodAssets = (videos: VideosPageVodAsset[]) => {
  const [{ data: globalVideosData }, { getVideoByExternalId }] = useVideos();

  const globalVodAssets = useMemo(() => {
    // Intentionally depending on data, so it triggers re-render when data is changed
    // It keeps mapped videos up to date with a global state
    if (!globalVideosData.length) {
      return videos;
    }

    return videos.map(video => {
      const vodAsset = getVideoByExternalId(video.externalId);
      if (!vodAsset || vodAsset?.status === VIDEO_STATUS.invalid) {
        return video;
      }

      const isDone = vodAsset.status !== 'done';
      const createdAt = video.createdAt || vodAsset.createdAt;
      const stockAsset = isDone ? vodAsset.stockAsset : video.stockAsset;
      return { ...vodAsset, createdAt, stockAsset };
    });
  }, [getVideoByExternalId, globalVideosData, videos]);

  return { globalVodAssets };
};
