import React from 'react';
import AnalyticsMetrics from '../AnalyticsMetrics';
import AnalyticsOverviewAov from './AnalyticsOverviewAov';
import AnalyticsOverviewConversionRate from './AnalyticsOverviewConversionRate';
import AnalyticsOverviewEmptyState from '../empty/AnalyticsOverviewEmptyState';
import AnalyticsOverviewProductCTR from './AnalyticsOverviewProductCTR';
import AnalyticsOverviewRevenue from './AnalyticsOverviewRevenue';
import AnalyticsOverviewViewers from './AnalyticsOverviewViewers';
import AnalyticsPlayersManager from './analytics-player/AnalyticsPlayersManager';
import AnalyticsTopConvertingVideos from './AnalyticsTopConvertingVideos';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import styled from 'styled-components';
import useAnalyticsOverview from 'app/src/hooks/useAnalyticsOverview';
import {
  ANALYTICS_OVERVIEW_STATS,
  CHANNEL_FILTER_OPTIONS,
} from 'app/src/constants/analytics.constants';
import { DesignSizes } from 'shared/react/theme/DesignSystem';
import { useAnalytics } from 'app/src/context/AnalyticsStore';

const getDefaultValue = ({ isTapcart }) => {
  if (isTapcart) {
    return 'n/a';
  }
};

const getNoDataTitleTooltip = ({ isTapcart }) => {
  if (isTapcart) {
    return 'Please request access to the Cart API from your Tapcart CSM to receive conversion data';
  }
};

const getTopMetrics = ({ isTapcart }) => {
  const TOP_METRICS = [
    {
      id: 'fmtTotalUniqueUsersSessionStarts',
      color: 'neutralBlack',
      formatted: true,
      headerTooltipText: ANALYTICS_OVERVIEW_STATS.TOLSTOY_VIEWERS_TOOLTIP,
      mainTitle: 'Tolstoy Viewers',
    },
    {
      id: 'totalRevenueSubtitle',
      color: 'neutralBlack',
      formatted: true,
      headerTooltipText: 'Total revenue from video viewers from selected period',
      mainTitle: 'Total Viewer Revenue',
      defaultValue: getDefaultValue({ isTapcart }),
      noDataTitleTooltipText: getNoDataTitleTooltip({ isTapcart }),
    },
    {
      id: 'tolstoyConversionRate',
      benchmark: 'conversionRate',
      color: 'neutralBlack',
      headerTooltipText: 'Percentage of users who made a purchase after watching a video',
      isPercentage: true,
      mainTitle: 'Viewer Conversion Rate',
      defaultValue: getDefaultValue({ isTapcart }),
      noDataTitleTooltipText: getNoDataTitleTooltip({ isTapcart }),
    },
    {
      id: 'formattedTolstoyOrdersAov',
      color: 'neutralBlack',
      formatted: true,
      headerTooltipText: 'Average order value of users who watched video',
      mainTitle: 'Viewer AOV',
      defaultValue: getDefaultValue({ isTapcart }),
      noDataTitleTooltipText: getNoDataTitleTooltip({ isTapcart }),
    },
    {
      id: 'formattedOverallNumOfProductInteractions',
      color: 'neutralBlack',
      formatted: true,
      headerTooltipText: 'Number of product interactions',
      mainTitle: 'Product Clicks',
      isHidden: !isTapcart,
    },
    {
      id: 'formattedClickThoughRate',
      color: 'neutralBlack',
      formatted: true,
      headerTooltipText: 'Click Through Rate',
      mainTitle: 'Click Through Rate',
      isHidden: !isTapcart,
    },
  ];

  return TOP_METRICS.filter(metric => !metric.isHidden);
};

const AnalyticsOverview = ({ setSelectedTab, shouldBlur }) => {
  const [{ channel: selectedChannel }] = useAnalytics();

  if (shouldBlur) {
    return <AnalyticsOverviewEmptyState />;
  }

  const analyticsOverviewData = useAnalyticsOverview();
  const isTapcart = selectedChannel === CHANNEL_FILTER_OPTIONS.tapcart;
  const isLoading = analyticsOverviewData?.totalRevenueShouldShowLoader;
  const topMetrics = getTopMetrics({ isTapcart });

  return (
    <LayoutRoot>
      <StyledAnalyticsMetrics
        size={DesignSizes.SMALL}
        data={analyticsOverviewData}
        metrics={topMetrics}
        loading={isLoading}
      />
      <AnalyticsPlayersManager>
        <AnalyticsTopConvertingVideos setSelectedTab={setSelectedTab} />
        <MetricsLayout>
          <AnalyticsOverviewViewers />
          <AnalyticsOverviewRevenue />
          <AnalyticsOverviewConversionRate />
          <AnalyticsOverviewAov />
          <AnalyticsOverviewProductCTR />
        </MetricsLayout>
      </AnalyticsPlayersManager>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)`
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1192px;
  padding-bottom: 24px;
  width: 100%;
`;

const StyledAnalyticsMetrics = styled(AnalyticsMetrics)`
  & > *:first-child {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));

    & > * {
      max-width: unset;
      width: 100%;
    }
  }
`;

const MetricsLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 16px;
  width: 100%;
`;

export default AnalyticsOverview;
