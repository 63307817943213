import React from 'react';
import {
  PrimaryButton,
  SecondaryButton,
} from 'shared/react/components/basic/button-v2/BasicButton';
import GreenCheckCircleIcon from 'src/images/dashboard_v2/GreenCheckCircleIcon';
import styled from 'styled-components';

type Props = {
  isAppInstalled: boolean;
  onClick: () => void;
};

const OnboardingTemplatesIntegrationButton = ({ isAppInstalled, onClick }: Props) => {
  if (isAppInstalled) {
    return (
      <DisabledButton>
        <GreenCheckCircleIcon />
        Connected
      </DisabledButton>
    );
  }

  return <ConnectButton onClick={onClick}>Connect</ConnectButton>;
};

const ConnectButton = styled(PrimaryButton).attrs(({ theme }) => ({
  size: theme.designSizes.NORMAL,
}))`
  width: 100%;
`;

const DisabledButton = styled(SecondaryButton).attrs(({ theme }) => ({
  size: theme.designSizes.NORMAL,
}))`
  width: 100%;
  pointer-events: none;
`;

export default OnboardingTemplatesIntegrationButton;
