import React from 'react';

const ClosedFolder = ({ fill }) => {
  return (
    <svg width="19" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5 2H8.5L6.5 0H1.5C0.65625 0 0 0.6875 0 1.5V4H16V3.5C16 2.6875 15.3125 2 14.5 2ZM0 12.5C0 13.3438 0.65625 14 1.5 14H14.5C15.3125 14 16 13.3438 16 12.5V5H0V12.5Z"
        fill={fill || '#50545E'}
      />
    </svg>
  );
};

export default ClosedFolder;
