import React from 'react';

function HorizontalExpanderIcon({ isExpanded, fill = '#090A0B', ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      transform={`scale(${isExpanded ? '-' : ''}1 1)`}
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <rect width="24" height="24" fill="#fff" rx="12" />
      <path
        fill={fill}
        d="M13.078 17.398a.56.56 0 00.797.024.56.56 0 00.023-.797l-3.937-4.102 3.937-4.125a.56.56 0 00-.023-.796.56.56 0 00-.797.023l-4.312 4.5a.636.636 0 00-.141.398c0 .118.047.258.14.375l4.313 4.5z"
      />
    </svg>
  );
}

export default HorizontalExpanderIcon;
