import React from 'react';
import styled from 'styled-components';
import ContactCard from './ContactCard';
import { CircularProgress } from '@material-ui/core';
import SessionsFiltersBar from './SessionsFiltersBar';
import ConversationsSearchBox from 'app/src/pages/project/pages/inbox/ConversationsSearchBox';
import ContactsListLockState from 'app/src/images/contacts_list_lock_state.png';

const ContactsList = ({
  loading,
  sessions,
  selectedSessionId,
  onFiltersClicked,
  onSessionClick,
  contactsRef,
  status,
  onStatusChange,
  onUpdateSessionStatus,
  children,
  user,
  teamMembers,
  onEmailChange,
  isBlur,
  inboxFilters,
}) => {
  return (
    <StyledContactsList isBlur={isBlur}>
      <SessionsFiltersBar
        status={status}
        onFiltersClicked={onFiltersClicked}
        onStatusChange={onStatusChange}
        inboxFilters={inboxFilters}
      />
      <SearchContainer>
        <ConversationsSearchBox onEmailChange={onEmailChange} />
      </SearchContainer>
      <ContactCards ref={contactsRef} isBlur={isBlur}>
        <ContactCardContent
          isBlur={isBlur}
          onSessionClick={onSessionClick}
          onUpdateSessionStatus={onUpdateSessionStatus}
          user={user}
          teamMembers={teamMembers}
          selectedSessionId={selectedSessionId}
          sessions={sessions}
        />
        {!loading && children}
        {loading && (
          <ContactsListLoadingContainer>
            <CircularProgress size={24} />
          </ContactsListLoadingContainer>
        )}
      </ContactCards>
    </StyledContactsList>
  );
};

const ContactCardContent = ({
  isBlur,
  sessions,
  selectedSessionId,
  onSessionClick,
  onUpdateSessionStatus,
  user,
  teamMembers,
}) => {
  if (isBlur) {
    return <ContactsListLockStateImage src={ContactsListLockState} />;
  }

  return (
    <>
      {sessions.map(session => (
        <ContactCard
          active={session.id === selectedSessionId}
          key={session.id}
          session={session}
          onSessionClick={onSessionClick}
          onUpdateSessionStatus={onUpdateSessionStatus}
          user={user}
          teamMembers={teamMembers}
        />
      ))}
    </>
  );
};

export default ContactsList;

const StyledContactsList = styled.div`
  grid-area: sessions;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: white;
  width: 312px;
  overflow: hidden;
  gap: 8px;
  padding-top: 8px;
  & > * {
    margin-bottom: 0;
  }
`;

const ContactCards = styled.div`
  display: flex;
  overflow-x: auto;
  flex-direction: column;
  gap: 8px;
  margin-bottom: ${({ isBlur }) => (isBlur ? 0 : '8px')};
  filter: ${({ isBlur }) => (isBlur ? 'blur(8px)' : '')};
  pointer-events: ${({ isBlur }) => (isBlur ? 'none' : '')};
`;

const ContactsListLockStateImage = styled.img``;

const ContactsListLoadingContainer = styled.div`
  padding-top: 8px;
  display: flex;
  justify-content: center;
  padding-bottom: 8px;
`;

const SearchContainer = styled.div`
  margin: 0px 16px 8px;
`;
