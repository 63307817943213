import React from 'react';
import styled from 'styled-components';

type Props = {
  className?: string;
};

export const FatChevronLeftIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="7"
      height="11"
      viewBox="0 0 7 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M.46 4.984a.723.723 0 0 0 0 1.055l4.5 4.5a.723.723 0 0 0 1.056 0 .723.723 0 0 0 0-1.055L2.055 5.5l3.96-3.96a.723.723 0 0 0 0-1.056.723.723 0 0 0-1.054 0l-4.5 4.5Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export const FatChevronRightIcon = styled(FatChevronLeftIcon)`
  transform: rotate(180deg);
`;
