import React, { useEffect, useRef, useState } from 'react';
import SearchBar from 'app/src/complex_components/search_bar/SearchBar';

const ConversationsSearchBox = ({ onEmailChange }) => {
  const [email, setEmail] = useState('');
  const [emailChangeTimeoutId, setEmailChangeTimeoutId] = useState(null);
  const rendered = useRef(true);

  useEffect(() => {
    return () => {
      rendered.current = false;
    };
  }, []);


  const onChange = e => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    clearTimeout(emailChangeTimeoutId);
    const timeoutId = setTimeout(() => {
      if (rendered.current) {
        onEmailChange(newEmail);
      }
    }, 500);
    setEmailChangeTimeoutId(timeoutId);
  };

  const clearValue = () => {
    onChange({ target: { value: '' } });
  };

  return (
    <SearchBar
      clearValue={clearValue}
      value={email}
      onChange={onChange}
      placeholder="Search Email"
    />
  );
};

export default ConversationsSearchBox;
