import React from 'react';

const VSuccessIcon = () => {
  return (
    <svg width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.75 3.79688C22.5 3.04688 22.5 1.875 21.75 1.125C21 0.421875 19.8281 0.421875 19.125 1.125L8.4375 11.8125L3.75 7.125C3 6.42188 1.82812 6.42188 1.125 7.125C0.375 7.875 0.375 9.04688 1.125 9.79688L7.125 15.7969C7.45312 16.1719 7.96875 16.3594 8.4375 16.3594C8.90625 16.3594 9.375 16.1719 9.75 15.7969L21.75 3.79688Z"
        fill="#01BE81"
      />
    </svg>
  );
};

export default VSuccessIcon;
