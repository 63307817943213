import HttpService from 'shared/react/services/httpService';
import { REACT_APP_API_BASE_URL } from '../config/shared.config';

const fetchProducts = async publishId => {
  return await HttpService.get(
    `${REACT_APP_API_BASE_URL}/products/actions/products-get-many/${publishId}`
  );
};
const fetchSearchProductsBySearchTerm = async ({ term, appUrl, customLimit }) => {
  const { data, status } = await HttpService.post(
    `${REACT_APP_API_BASE_URL}/products/actions/v2/products/get-many-by-term`,
    {},
    JSON.stringify({ term, appUrl, customLimit })
  );

  if (status !== 200) {
    return;
  }

  return data;
};

const fetchDynamicProducts = async ({
  vodAssetIds,
  shopifyStoreUrl,
  isShopifyStore,
  currentPageProductId,
  appKey,
}) => {
  return HttpService.post(
    `${REACT_APP_API_BASE_URL}/products/actions/v2/get-by-vod-asset-ids`,
    {},
    JSON.stringify({
      vodAssetIds,
      appUrl: shopifyStoreUrl,
      isShopifyStore,
      currentProductId: currentPageProductId,
      appKey,
    })
  );
};

const fetchProductReviews = async productId => {
  return await HttpService.get(`${REACT_APP_API_BASE_URL}/yotpo/product-rating/${productId}`);
};

export {
  fetchDynamicProducts,
  fetchProductReviews,
  fetchProducts,
  fetchSearchProductsBySearchTerm,
};
