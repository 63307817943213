import React from 'react';

const UGCIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5 0C12.5938 0 13.5 0.90625 13.5 2V14C13.5 15.125 12.5938 16 11.5 16H4.5C3.375 16 2.5 15.125 2.5 14V2C2.5 0.90625 3.375 0 4.5 0H11.5ZM12 14V2C12 1.75 11.75 1.5 11.5 1.5H4.5C4.21875 1.5 4 1.75 4 2V14C4 14.2812 4.21875 14.5 4.5 14.5H11.5C11.75 14.5 12 14.2812 12 14ZM9 12.5C9.25 12.5 9.5 12.75 9.5 13C9.5 13.2812 9.25 13.5 9 13.5H7C6.71875 13.5 6.5 13.2812 6.5 13C6.5 12.75 6.71875 12.5 7 12.5H9Z"
        fill="#2F3137"
      />
    </svg>
  );
};

export default UGCIcon;
