import React, { useEffect, useMemo, useState } from 'react';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import HeaderAndSubheader from 'src/basic_components/HeaderAndSubheader';
import { ONBOARDING_LOCATION } from 'src/constants/trackingLocations.constants';
import { useProjects } from 'src/context/ProjectsStore';
import { useRuleGroupByProjectId } from 'src/context/RulesStore';
import { useOnboarding } from 'src/context/ui_store/OnboardingStore';
import useDynamicVideoIdsByProject from 'src/hooks/use-dynamic-video-ids-by-project/useDynamicVideoIdsByProject';
import {
  MatchConditions,
  VIDEO_RULES,
} from 'src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/constants/dynamicConditions.constants';
import { getRules } from 'src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/helpers/getRulesArray';
import Utils from 'src/utils';
import styled from 'styled-components';
import useAccountOnboardingTemplates from '../../../hooks/useAccountOnboardingTemplates';
import OnboardingTemplatesReviewVideosContent from './OnboardingTemplatesReviewVideosContent';
import OnboardingTemplatesReviewProductsEmptyState from './OnboardingTemplatesReviewVideosEmptyState';
import { getDefaultDynamicRule } from 'src/services/rules/RulesService';

const OnboardingTemplatesReviewProducts = ({ containerRef }) => {
  const [{ activeTemplateProjectId }, { setIsTemplatesNextStepBlocked }] = useOnboarding();
  const { getTemplateAnalyticsData, getStageProps } = useAccountOnboardingTemplates();
  const [{ project = {} }] = useProjects({ projectId: activeTemplateProjectId });
  const [projectRuleGroup] = useRuleGroupByProjectId(activeTemplateProjectId);
  const [productIds, setProductIds] = useState([]);

  const defaultRule = getDefaultDynamicRule();
  const rules = projectRuleGroup?.rules ?? [[defaultRule]];
  const { header, subheader, emptyStateHeader, emptyStateSubheader } = getStageProps();

  const matchConditions = rules?.length > 1 ? MatchConditions.matchAny : MatchConditions.matchAll;

  const currentRules = rules?.flat();
  const normalRules = currentRules.filter(({ type }) => !VIDEO_RULES.includes(type));
  const videoRules = currentRules.filter(({ type }) => VIDEO_RULES.includes(type));

  const matchConditionsRuleGroup = useMemo(() => {
    return {
      enabled: true,
      rules: getRules({ rules: normalRules, matchConditions, videoRules }),
      appKey: project.appKey,
    };
  }, [projectRuleGroup?.rules, matchConditions]);

  const { videoIds: vodAssetIds } = useDynamicVideoIdsByProject(
    project,
    matchConditionsRuleGroup,
    project.dynamic
  );

  useEffect(() => {
    if (!vodAssetIds?.length) {
      setIsTemplatesNextStepBlocked(true);
      return;
    }

    setIsTemplatesNextStepBlocked(false);
  }, [vodAssetIds?.length]);

  if (!vodAssetIds?.length) {
    return (
      <OnboardingTemplatesReviewProductsEmptyState
        header={emptyStateHeader}
        subheader={emptyStateSubheader}
      />
    );
  }

  const analyticsData = {
    ...Utils.getAnalyticsLocationObject(ONBOARDING_LOCATION),
    ...getTemplateAnalyticsData(),
  };
  return (
    <LayoutRoot>
      <HeaderAndSubheader header={header} subheader={subheader} />
      <TableContainer>
        <OnboardingTemplatesReviewVideosContent
          vodAssetIds={vodAssetIds}
          project={project}
          productIds={productIds}
          setProductIds={setProductIds}
          containerRef={containerRef}
          analyticsData={analyticsData}
        />
      </TableContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  gap: 32px;
  width: 100%;
  align-self: flex-start;
  background: ${({ theme }) => theme.colors.neutralLightest};
`;

const TableContainer = styled.div`
  width: 100%;
  padding: 0 64px;

  & > * {
    width: 100%;
  }
`;

export default OnboardingTemplatesReviewProducts;
