import { CUBE_PROPERTY_KEYS } from 'app/src/constants/cube.constants';
import { CURRENCY_CODES } from 'shared/react/constants/currency.constants';
import { convertRevenueToUsd } from 'app/src/services/cube/cube.utils';
import { GetDailyDataProps, ResolveAvgPriceProps } from './types/getDailyAveragesData.types';

const getIsCountKey = (key: string) => key.includes(CUBE_PROPERTY_KEYS.count);

const getCountKey = (key: string) =>
  key.replace(CUBE_PROPERTY_KEYS.avgPrice, CUBE_PROPERTY_KEYS.count);

const resolveAvgPrice = ({ totalAverageInUsd, rates, currency }: ResolveAvgPriceProps) => {
  if (currency === CURRENCY_CODES.usd) {
    return totalAverageInUsd;
  }

  return totalAverageInUsd * rates[currency];
};

export const getDailyAveragesData = ({ conversions, currency, rates }: GetDailyDataProps) => {
  let dailyTotalCount = 0;
  let dailySumPriceInUsd = 0;

  for (const [key, value] of Object.entries(conversions)) {
    if (getIsCountKey(key)) {
      dailyTotalCount += value;
      continue;
    }

    const conversionCurrency = key.split(',')[0];
    const rate = rates[conversionCurrency];
    const revenueInUsd = convertRevenueToUsd({ revenue: value, rate });
    const count = conversions[getCountKey(key)];

    dailySumPriceInUsd += revenueInUsd * count;
  }

  const totalAverageInUsd = dailySumPriceInUsd / dailyTotalCount || 0;

  const dailyAveragePrice = resolveAvgPrice({ totalAverageInUsd, rates, currency });

  return {
    dailyAveragePrice,
    dailyTotalCount,
  };
};
