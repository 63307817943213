import React from 'react';

import { useModal } from 'src/context/ui_store/ModalStore';
import styled from 'styled-components';
import StandardModalV2 from '../StandardModalV2';
import ChoosePublishMethod from './choose_publish_method/ChoosePublishMethod';

type Props = {
  closeModal: () => void;
  onCancelSelectPublishMethod?: () => void;
};

const SelectPublishMethodModal = ({ closeModal }: Props) => {
  const [
    {
      modalProps: { project, onCancelSelectPublishMethod },
    },
  ] = useModal();

  const handleCancelSelectPublishMethod = () => {
    closeModal();
    onCancelSelectPublishMethod?.();
  };

  return (
    <StandardModalV2 open={true} onClose={handleCancelSelectPublishMethod}>
      <ContentWrapper>
        <ChoosePublishMethod
          project={project}
          onPublishMethodChanged={closeModal}
          loading={false}
          live={false}
          onSetLive={() => {}}
          showOnlyWidget={false}
        />
      </ContentWrapper>
    </StandardModalV2>
  );
};

const ContentWrapper = styled.div`
  padding: 40px;
  width: 770px;
`;

export default SelectPublishMethodModal;
