import React, { useEffect, useState } from 'react';
import { useOnboarding } from 'src/context/ui_store/OnboardingStore';
import DynamicNonPDP from 'src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-non-pdp/DynamicNonPDP';
import DynamicProductsTable from 'src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-products-table/DynamicProductsTable';
import { project as Project, vodAsset as VodAsset } from 'src/types/entities';
import { OnboardingTemplateKey } from '../../constants/onboardingTemplates.constants';
import { useVideoActions } from 'src/context/VideosStore';

type Props = {
  vodAssetIds: string[];
  project: Project;
  productIds: string[];
  setProductIds: (productIds: string[]) => void;
  containerRef: React.RefObject<HTMLDivElement>;
  analyticsData: any;
};

const OnboardingTemplatesReviewVideosContent = ({
  vodAssetIds,
  project,
  productIds,
  setProductIds,
  containerRef,
  analyticsData,
}: Props) => {
  const [vodAssets, setVodAssets] = useState<VodAsset[]>([]);
  const { getVideoById } = useVideoActions();
  const [{ activeTemplateKey }] = useOnboarding();

  const getVodAssets = () => {
    return vodAssetIds.flatMap(id => getVideoById(id) || []);
  };

  useEffect(() => {
    setVodAssets(getVodAssets());
  }, [vodAssetIds]);

  if (activeTemplateKey === OnboardingTemplateKey.homepageCarousel) {
    return (
      <DynamicNonPDP
        loading={false}
        vodAssets={vodAssets}
        setVodAssets={setVodAssets}
        project={project}
      />
    );
  }

  return (
    <DynamicProductsTable
      vodAssetIds={vodAssetIds}
      project={project}
      productIds={productIds}
      setProductIds={setProductIds}
      containerRef={containerRef}
      analyticsData={analyticsData}
    />
  );
};

export default OnboardingTemplatesReviewVideosContent;
