/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../types/entities";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getVodAsset = /* GraphQL */ `query GetVodAsset($id: ID!) {
  getVodAsset(id: $id) {
    id
    type
    owner
    appKey
    appExternalId
    createdAt
    name
    description
    creatorProfile {
      url
    }
    isSoundAllowed
    status
    uploadType
    stockAsset {
      videoUrl
      posterUrl
      gifUrl
      hasOriginal
      shopifyFileId
      previewUrl
      previewShopifyFileId
      shopifyPosterUrl
      posterShopifyFileId
      avatarUrl
      audioUrl
      gifShopifyFileId
    }
    duration
    folder
    width
    height
    isVertical
    isHDR
    trimSettings
    originalVOD
    mirror
    recorderVersion
    originalSize
    externalId
    externalProviderData
    isMigratingToShopify
    shopifyUploadingStatus
    isEverUsed
    captionsSettings
    referenceIds
    vodConnections {
      items {
        id
        appKey
        vodAssetId
        productId
        externalProductId
        collectionId
        provider
        tag
        createdAt
        typeKey
        type
        appUrl
        label
        vodLabelId
        vodSource
        vodType
        variantIds
        projectId
        orderIndex
        source
        updatedAt
      }
      nextToken
    }
    analysis
    autoGenerated
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetVodAssetQueryVariables,
  APITypes.GetVodAssetQuery
>;
export const listVodAssets = /* GraphQL */ `query ListVodAssets(
  $filter: ModelvodAssetFilterInput
  $limit: Int
  $nextToken: String
) {
  listVodAssets(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      owner
      appKey
      appExternalId
      createdAt
      name
      description
      creatorProfile {
        url
      }
      isSoundAllowed
      status
      uploadType
      stockAsset {
        videoUrl
        posterUrl
        gifUrl
        hasOriginal
        shopifyFileId
        previewUrl
        previewShopifyFileId
        shopifyPosterUrl
        posterShopifyFileId
        avatarUrl
        audioUrl
        gifShopifyFileId
      }
      duration
      folder
      width
      height
      isVertical
      isHDR
      trimSettings
      originalVOD
      mirror
      recorderVersion
      originalSize
      externalId
      externalProviderData
      isMigratingToShopify
      shopifyUploadingStatus
      isEverUsed
      captionsSettings
      referenceIds
      vodConnections {
        items {
          id
          appKey
          vodAssetId
          productId
          externalProductId
          collectionId
          provider
          tag
          createdAt
          typeKey
          type
          appUrl
          label
          vodLabelId
          vodSource
          vodType
          variantIds
          projectId
          orderIndex
          source
          updatedAt
        }
        nextToken
      }
      analysis
      autoGenerated
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListVodAssetsQueryVariables,
  APITypes.ListVodAssetsQuery
>;
export const getVodResponseAsset = /* GraphQL */ `query GetVodResponseAsset($id: ID!) {
  getVodResponseAsset(id: $id) {
    id
    owner
    appKey
    createdAt
    status
    uploadType
    duration
    width
    height
    isVertical
    isHDR
    mirror
    originalSize
    email
    projectId
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetVodResponseAssetQueryVariables,
  APITypes.GetVodResponseAssetQuery
>;
export const listVodResponseAssets = /* GraphQL */ `query ListVodResponseAssets(
  $filter: ModelvodResponseAssetFilterInput
  $limit: Int
  $nextToken: String
) {
  listVodResponseAssets(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      appKey
      createdAt
      status
      uploadType
      duration
      width
      height
      isVertical
      isHDR
      mirror
      originalSize
      email
      projectId
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListVodResponseAssetsQueryVariables,
  APITypes.ListVodResponseAssetsQuery
>;
export const getProject = /* GraphQL */ `query GetProject($id: ID!) {
  getProject(id: $id) {
    id
    owner
    appKey
    typeKey
    createdAt
    publishId
    name
    folder
    targetPage
    verticalOrientation
    widgetPosition
    widgetHeader
    widgetUrls
    description
    emailNotifications
    notificationLevel
    viewerNotificationLevel
    chaptersEnabled
    subtitlesEnabled
    stepsOrder
    collectText
    collectFields {
      key
      name
      type
      placeholder
      link
      required
      collect
      custom
      textColor
      borderColor
      backgroundColor
    }
    collectAfterStep
    cacheLeadFormAnswers
    googleAnalyticsID
    facebookAnalyticsID
    totalResponses
    unreadResponses
    tolstoyType
    steps {
      items {
        id
        owner
        appKey
        projectId
        videoId
        name
        type
        description
        script
        question
        questionVisible
        answerTime
        answers {
          key
          text
          next
          value
          type
          disabled
        }
        products {
          id
        }
        productsAppUrl
        videoContain
        overlayText
        overlayTextColor
        multipleChoiceSelection
        defaultResponses {
          key
          text
          next
          value
          type
          disabled
        }
        defaultResponsesEnabled
        sideBarButtonsEnabled
        feedProductActionButton {
          type
          url
          text
          isOpenInANewTab
          value
          enabled
        }
        feedSidePanelType
        feedDescriptionActionButton {
          type
          url
          text
          isOpenInANewTab
          value
          enabled
        }
        feedSidePanelDescription
        productCtaType
        createdAt
        updatedAt
      }
      nextToken
    }
    stepsCount
    widgetBorderColor
    widgetShape
    widgetSize
    widgetNotificationBadge
    videoResolutions
    fastForwardEnabled
    rulesEnabled
    macro
    hideChatPlatform
    private
    chatLandingPage
    widgetSettings
    notificationSettingsId
    collectAfterAnyResponse
    startScreenText
    feed
    feedAutoScroll
    feedShowUnviewedStepsFirst
    language
    showButtonsIndex
    publishMethod
    dynamic
    feedProductCtaType
    playerSettings
    appUrl
    live
    primaryButton {
      enabled
      ctaType
      buttonText
      value
      isOpenInANewTab
    }
    secondaryButton {
      enabled
      ctaType
      buttonText
      value
      isOpenInANewTab
    }
    pdpConfig {
      primaryButton {
        enabled
        ctaType
        buttonText
        value
        isOpenInANewTab
      }
      secondaryButton {
        enabled
        ctaType
        buttonText
        value
        isOpenInANewTab
      }
      miniCtaButton {
        enabled
        text
      }
    }
    installationCompleted
    installStep
    discover
    useCaseName
    heroSettings
    sourceProjectId
    emailSettings
    videoCollectionCampaignSettings
    secretVideoSettings
    integrationSettings
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetProjectQueryVariables,
  APITypes.GetProjectQuery
>;
export const listProjects = /* GraphQL */ `query ListProjects(
  $filter: ModelprojectFilterInput
  $limit: Int
  $nextToken: String
) {
  listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      appKey
      typeKey
      createdAt
      publishId
      name
      folder
      targetPage
      verticalOrientation
      widgetPosition
      widgetHeader
      widgetUrls
      description
      emailNotifications
      notificationLevel
      viewerNotificationLevel
      chaptersEnabled
      subtitlesEnabled
      stepsOrder
      collectText
      collectFields {
        key
        name
        type
        placeholder
        link
        required
        collect
        custom
        textColor
        borderColor
        backgroundColor
      }
      collectAfterStep
      cacheLeadFormAnswers
      googleAnalyticsID
      facebookAnalyticsID
      totalResponses
      unreadResponses
      tolstoyType
      steps {
        items {
          id
          owner
          appKey
          projectId
          videoId
          name
          type
          description
          script
          question
          questionVisible
          answerTime
          answers {
            key
            text
            next
            value
            type
            disabled
          }
          products {
            id
          }
          productsAppUrl
          videoContain
          overlayText
          overlayTextColor
          multipleChoiceSelection
          defaultResponses {
            key
            text
            next
            value
            type
            disabled
          }
          defaultResponsesEnabled
          sideBarButtonsEnabled
          feedProductActionButton {
            type
            url
            text
            isOpenInANewTab
            value
            enabled
          }
          feedSidePanelType
          feedDescriptionActionButton {
            type
            url
            text
            isOpenInANewTab
            value
            enabled
          }
          feedSidePanelDescription
          productCtaType
          createdAt
          updatedAt
        }
        nextToken
      }
      stepsCount
      widgetBorderColor
      widgetShape
      widgetSize
      widgetNotificationBadge
      videoResolutions
      fastForwardEnabled
      rulesEnabled
      macro
      hideChatPlatform
      private
      chatLandingPage
      widgetSettings
      notificationSettingsId
      collectAfterAnyResponse
      startScreenText
      feed
      feedAutoScroll
      feedShowUnviewedStepsFirst
      language
      showButtonsIndex
      publishMethod
      dynamic
      feedProductCtaType
      playerSettings
      appUrl
      live
      primaryButton {
        enabled
        ctaType
        buttonText
        value
        isOpenInANewTab
      }
      secondaryButton {
        enabled
        ctaType
        buttonText
        value
        isOpenInANewTab
      }
      pdpConfig {
        primaryButton {
          enabled
          ctaType
          buttonText
          value
          isOpenInANewTab
        }
        secondaryButton {
          enabled
          ctaType
          buttonText
          value
          isOpenInANewTab
        }
        miniCtaButton {
          enabled
          text
        }
      }
      installationCompleted
      installStep
      discover
      useCaseName
      heroSettings
      sourceProjectId
      emailSettings
      videoCollectionCampaignSettings
      secretVideoSettings
      integrationSettings
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListProjectsQueryVariables,
  APITypes.ListProjectsQuery
>;
export const getProjectStep = /* GraphQL */ `query GetProjectStep($id: ID!) {
  getProjectStep(id: $id) {
    id
    owner
    appKey
    projectId
    videoId
    name
    type
    description
    script
    question
    questionVisible
    answerTime
    answers {
      key
      text
      next
      value
      type
      disabled
    }
    products {
      id
    }
    productsAppUrl
    videoContain
    overlayText
    overlayTextColor
    multipleChoiceSelection
    defaultResponses {
      key
      text
      next
      value
      type
      disabled
    }
    defaultResponsesEnabled
    sideBarButtonsEnabled
    feedProductActionButton {
      type
      url
      text
      isOpenInANewTab
      value
      enabled
    }
    feedSidePanelType
    feedDescriptionActionButton {
      type
      url
      text
      isOpenInANewTab
      value
      enabled
    }
    feedSidePanelDescription
    productCtaType
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetProjectStepQueryVariables,
  APITypes.GetProjectStepQuery
>;
export const listProjectSteps = /* GraphQL */ `query ListProjectSteps(
  $filter: ModelprojectStepFilterInput
  $limit: Int
  $nextToken: String
) {
  listProjectSteps(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      appKey
      projectId
      videoId
      name
      type
      description
      script
      question
      questionVisible
      answerTime
      answers {
        key
        text
        next
        value
        type
        disabled
      }
      products {
        id
      }
      productsAppUrl
      videoContain
      overlayText
      overlayTextColor
      multipleChoiceSelection
      defaultResponses {
        key
        text
        next
        value
        type
        disabled
      }
      defaultResponsesEnabled
      sideBarButtonsEnabled
      feedProductActionButton {
        type
        url
        text
        isOpenInANewTab
        value
        enabled
      }
      feedSidePanelType
      feedDescriptionActionButton {
        type
        url
        text
        isOpenInANewTab
        value
        enabled
      }
      feedSidePanelDescription
      productCtaType
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListProjectStepsQueryVariables,
  APITypes.ListProjectStepsQuery
>;
export const getUserSettings = /* GraphQL */ `query GetUserSettings($id: ID!) {
  getUserSettings(id: $id) {
    id
    owner
    email
    firstName
    lastName
    lastLogin
    source
    token
    appKey
    hasLoggedIn
    hasShared
    viewedTolstoys
    calendars {
      id
      name
      url
    }
    emailAutomationTool
    logoSettings
    googleInfo
    crmData
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetUserSettingsQueryVariables,
  APITypes.GetUserSettingsQuery
>;
export const listUserSettingss = /* GraphQL */ `query ListUserSettingss(
  $filter: ModeluserSettingsFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserSettingss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      email
      firstName
      lastName
      lastLogin
      source
      token
      appKey
      hasLoggedIn
      hasShared
      viewedTolstoys
      calendars {
        id
        name
        url
      }
      emailAutomationTool
      logoSettings
      googleInfo
      crmData
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListUserSettingssQueryVariables,
  APITypes.ListUserSettingssQuery
>;
export const getAccountSettings = /* GraphQL */ `query GetAccountSettings($id: ID!) {
  getAccountSettings(id: $id) {
    id
    appKey
    owner
    name
    logoSettings
    widgetSettings
    downgradeInfo
    liveIds
    url
    logoUrl
    playerLogoUrl
    companyVertical
    companySize
    companyRole
    monthlyTraffic
    useCase
    packageId
    token
    paymentProvider
    paymentSubscriptions
    paymentIdentifier
    packageGroup
    joinedPackageAt
    trialEndAt
    referral
    crmData
    trialDays
    googleAnalyticsID
    appsInstallationStatuses
    onboardingStatuses
    assetAnalysis
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetAccountSettingsQueryVariables,
  APITypes.GetAccountSettingsQuery
>;
export const listAccountSettingss = /* GraphQL */ `query ListAccountSettingss(
  $filter: ModelaccountSettingsFilterInput
  $limit: Int
  $nextToken: String
) {
  listAccountSettingss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      appKey
      owner
      name
      logoSettings
      widgetSettings
      downgradeInfo
      liveIds
      url
      logoUrl
      playerLogoUrl
      companyVertical
      companySize
      companyRole
      monthlyTraffic
      useCase
      packageId
      token
      paymentProvider
      paymentSubscriptions
      paymentIdentifier
      packageGroup
      joinedPackageAt
      trialEndAt
      referral
      crmData
      trialDays
      googleAnalyticsID
      appsInstallationStatuses
      onboardingStatuses
      assetAnalysis
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListAccountSettingssQueryVariables,
  APITypes.ListAccountSettingssQuery
>;
export const getApp = /* GraphQL */ `query GetApp($id: ID!) {
  getApp(id: $id) {
    id
    owner
    appKey
    app
    active
    data
    token
    appExternalId
    appUrl
    importSettings
    importRange
    syncTagsMode
    syndicateBy
    googleAnalyticsID
    settings
    isShopAppEligible
    taxonomyId
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<APITypes.GetAppQueryVariables, APITypes.GetAppQuery>;
export const listApps = /* GraphQL */ `query ListApps($filter: ModelappFilterInput, $limit: Int, $nextToken: String) {
  listApps(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      appKey
      app
      active
      data
      token
      appExternalId
      appUrl
      importSettings
      importRange
      syncTagsMode
      syndicateBy
      googleAnalyticsID
      settings
      isShopAppEligible
      taxonomyId
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListAppsQueryVariables, APITypes.ListAppsQuery>;
export const getProjectApp = /* GraphQL */ `query GetProjectApp($id: ID!) {
  getProjectApp(id: $id) {
    id
    appId
    appKey
    projectId
    owner
    active
    data
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetProjectAppQueryVariables,
  APITypes.GetProjectAppQuery
>;
export const listProjectApps = /* GraphQL */ `query ListProjectApps(
  $filter: ModelprojectAppFilterInput
  $limit: Int
  $nextToken: String
) {
  listProjectApps(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      appId
      appKey
      projectId
      owner
      active
      data
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListProjectAppsQueryVariables,
  APITypes.ListProjectAppsQuery
>;
export const getResponse = /* GraphQL */ `query GetResponse($id: ID!) {
  getResponse(id: $id) {
    id
    owner
    appKey
    stepName
    projectId
    anonymousId
    sessionId
    type
    value
    email
    name
    phone
    createdAt
    read
    collectInfo
    answerType
    parentUrl
    isProfanity
    attachments {
      fileName
      url
      type
    }
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetResponseQueryVariables,
  APITypes.GetResponseQuery
>;
export const listResponses = /* GraphQL */ `query ListResponses(
  $filter: ModelresponseFilterInput
  $limit: Int
  $nextToken: String
) {
  listResponses(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      appKey
      stepName
      projectId
      anonymousId
      sessionId
      type
      value
      email
      name
      phone
      createdAt
      read
      collectInfo
      answerType
      parentUrl
      isProfanity
      attachments {
        fileName
        url
        type
      }
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListResponsesQueryVariables,
  APITypes.ListResponsesQuery
>;
export const getInAppNotification = /* GraphQL */ `query GetInAppNotification($id: ID!) {
  getInAppNotification(id: $id) {
    id
    owner
    teamMemberId
    projectId
    sessionId
    type
    value
    email
    createdAt
    read
    expirationUnixTime
    token
    stepName
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetInAppNotificationQueryVariables,
  APITypes.GetInAppNotificationQuery
>;
export const listInAppNotifications = /* GraphQL */ `query ListInAppNotifications(
  $filter: ModelinAppNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  listInAppNotifications(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      teamMemberId
      projectId
      sessionId
      type
      value
      email
      createdAt
      read
      expirationUnixTime
      token
      stepName
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListInAppNotificationsQueryVariables,
  APITypes.ListInAppNotificationsQuery
>;
export const getConferenceRoomConnection = /* GraphQL */ `query GetConferenceRoomConnection($id: ID!) {
  getConferenceRoomConnection(id: $id) {
    id
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetConferenceRoomConnectionQueryVariables,
  APITypes.GetConferenceRoomConnectionQuery
>;
export const listConferenceRoomConnections = /* GraphQL */ `query ListConferenceRoomConnections(
  $filter: ModelconferenceRoomConnectionFilterInput
  $limit: Int
  $nextToken: String
) {
  listConferenceRoomConnections(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListConferenceRoomConnectionsQueryVariables,
  APITypes.ListConferenceRoomConnectionsQuery
>;
export const getConferenceRoom = /* GraphQL */ `query GetConferenceRoom($id: ID!) {
  getConferenceRoom(id: $id) {
    id
    owner
    roomId
    userId
    active
    email
    appToken
    lastSeenAt
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetConferenceRoomQueryVariables,
  APITypes.GetConferenceRoomQuery
>;
export const listConferenceRooms = /* GraphQL */ `query ListConferenceRooms(
  $filter: ModelconferenceRoomFilterInput
  $limit: Int
  $nextToken: String
) {
  listConferenceRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      roomId
      userId
      active
      email
      appToken
      lastSeenAt
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListConferenceRoomsQueryVariables,
  APITypes.ListConferenceRoomsQuery
>;
export const getConferenceRoomActivity = /* GraphQL */ `query GetConferenceRoomActivity($id: ID!) {
  getConferenceRoomActivity(id: $id) {
    id
    roomName
    createdAt
    expirationUnixTime
    updatedAt
    appToken
    type
  }
}
` as GeneratedQuery<
  APITypes.GetConferenceRoomActivityQueryVariables,
  APITypes.GetConferenceRoomActivityQuery
>;
export const listConferenceRoomActivitys = /* GraphQL */ `query ListConferenceRoomActivitys(
  $filter: ModelconferenceRoomActivityFilterInput
  $limit: Int
  $nextToken: String
) {
  listConferenceRoomActivitys(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      roomName
      createdAt
      expirationUnixTime
      updatedAt
      appToken
      type
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListConferenceRoomActivitysQueryVariables,
  APITypes.ListConferenceRoomActivitysQuery
>;
export const getSession = /* GraphQL */ `query GetSession($id: ID!) {
  getSession(id: $id) {
    id
    owner
    assignee
    appKey
    projectId
    createdAt
    updatedAt
    lastEventAt
    sessionTime
    name
    email
    phone
    read
    archived
    actionNeeded
    totalEvents
    totalResponses
    totalReplies
    parentUrl
    videoResponse
    imageResponse
    audioResponse
    textResponse
    formResponse
    reachedEnd
    isSpam
  }
}
` as GeneratedQuery<
  APITypes.GetSessionQueryVariables,
  APITypes.GetSessionQuery
>;
export const listSessions = /* GraphQL */ `query ListSessions(
  $filter: ModelsessionFilterInput
  $limit: Int
  $nextToken: String
) {
  listSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      assignee
      appKey
      projectId
      createdAt
      updatedAt
      lastEventAt
      sessionTime
      name
      email
      phone
      read
      archived
      actionNeeded
      totalEvents
      totalResponses
      totalReplies
      parentUrl
      videoResponse
      imageResponse
      audioResponse
      textResponse
      formResponse
      reachedEnd
      isSpam
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListSessionsQueryVariables,
  APITypes.ListSessionsQuery
>;
export const getAccountIdentifiedUsers = /* GraphQL */ `query GetAccountIdentifiedUsers($id: ID!) {
  getAccountIdentifiedUsers(id: $id) {
    id
    owner
    appKey
    userId
    email
    phone
    name
    lastSeenAt
    firstSeenAt
    signedUpAt
    sessionCount
    customAttributes
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetAccountIdentifiedUsersQueryVariables,
  APITypes.GetAccountIdentifiedUsersQuery
>;
export const listAccountIdentifiedUserss = /* GraphQL */ `query ListAccountIdentifiedUserss(
  $filter: ModelaccountIdentifiedUsersFilterInput
  $limit: Int
  $nextToken: String
) {
  listAccountIdentifiedUserss(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      userId
      email
      phone
      name
      lastSeenAt
      firstSeenAt
      signedUpAt
      sessionCount
      customAttributes
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListAccountIdentifiedUserssQueryVariables,
  APITypes.ListAccountIdentifiedUserssQuery
>;
export const getRuleGroup = /* GraphQL */ `query GetRuleGroup($id: ID!) {
  getRuleGroup(id: $id) {
    id
    appKey
    projectId
    publishId
    owner
    rules {
      key
      type
      condition
      value
      limit
      behaviors
    }
    enabled
    visitorModeEnabled
    exitIntent
    delayTriggerEnabled
    delayTriggerSeconds
    showOnDevice
    showOnDomains
    abTestId
    type
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetRuleGroupQueryVariables,
  APITypes.GetRuleGroupQuery
>;
export const listRuleGroups = /* GraphQL */ `query ListRuleGroups(
  $filter: ModelruleGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listRuleGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      appKey
      projectId
      publishId
      owner
      rules {
        key
        type
        condition
        value
        limit
        behaviors
      }
      enabled
      visitorModeEnabled
      exitIntent
      delayTriggerEnabled
      delayTriggerSeconds
      showOnDevice
      showOnDomains
      abTestId
      type
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListRuleGroupsQueryVariables,
  APITypes.ListRuleGroupsQuery
>;
export const getIdentifyAttribute = /* GraphQL */ `query GetIdentifyAttribute($id: ID!) {
  getIdentifyAttribute(id: $id) {
    id
    appKey
    owner
    key
    type
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetIdentifyAttributeQueryVariables,
  APITypes.GetIdentifyAttributeQuery
>;
export const listIdentifyAttributes = /* GraphQL */ `query ListIdentifyAttributes(
  $filter: ModelidentifyAttributeFilterInput
  $limit: Int
  $nextToken: String
) {
  listIdentifyAttributes(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      owner
      key
      type
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListIdentifyAttributesQueryVariables,
  APITypes.ListIdentifyAttributesQuery
>;
export const getInvite = /* GraphQL */ `query GetInvite($id: ID!) {
  getInvite(id: $id) {
    id
    owner
    appKey
    email
    role
    status
    token
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<APITypes.GetInviteQueryVariables, APITypes.GetInviteQuery>;
export const listInvites = /* GraphQL */ `query ListInvites(
  $filter: ModelinviteFilterInput
  $limit: Int
  $nextToken: String
) {
  listInvites(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      appKey
      email
      role
      status
      token
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListInvitesQueryVariables,
  APITypes.ListInvitesQuery
>;
export const getAccountTeamMember = /* GraphQL */ `query GetAccountTeamMember($id: ID!) {
  getAccountTeamMember(id: $id) {
    id
    owner
    appKey
    email
    role
    status
    inviteToken
    createdAt
    userId
    teamMemberId
    lastLoginAt
    user {
      items {
        id
        owner
        email
        firstName
        lastName
        lastLogin
        source
        token
        appKey
        hasLoggedIn
        hasShared
        viewedTolstoys
        calendars {
          id
          name
          url
        }
        emailAutomationTool
        logoSettings
        googleInfo
        crmData
        createdAt
        updatedAt
      }
      nextToken
    }
    account {
      items {
        id
        appKey
        owner
        name
        logoSettings
        widgetSettings
        downgradeInfo
        liveIds
        url
        logoUrl
        playerLogoUrl
        companyVertical
        companySize
        companyRole
        monthlyTraffic
        useCase
        packageId
        token
        paymentProvider
        paymentSubscriptions
        paymentIdentifier
        packageGroup
        joinedPackageAt
        trialEndAt
        referral
        crmData
        trialDays
        googleAnalyticsID
        appsInstallationStatuses
        onboardingStatuses
        assetAnalysis
        createdAt
        updatedAt
      }
      nextToken
    }
    notificationSettingsId
    paymentRole
    unreadInAppNotificationsCounter
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetAccountTeamMemberQueryVariables,
  APITypes.GetAccountTeamMemberQuery
>;
export const listAccountTeamMembers = /* GraphQL */ `query ListAccountTeamMembers(
  $filter: ModelaccountTeamMemberFilterInput
  $limit: Int
  $nextToken: String
) {
  listAccountTeamMembers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      email
      role
      status
      inviteToken
      createdAt
      userId
      teamMemberId
      lastLoginAt
      user {
        items {
          id
          owner
          email
          firstName
          lastName
          lastLogin
          source
          token
          appKey
          hasLoggedIn
          hasShared
          viewedTolstoys
          calendars {
            id
            name
            url
          }
          emailAutomationTool
          logoSettings
          googleInfo
          crmData
          createdAt
          updatedAt
        }
        nextToken
      }
      account {
        items {
          id
          appKey
          owner
          name
          logoSettings
          widgetSettings
          downgradeInfo
          liveIds
          url
          logoUrl
          playerLogoUrl
          companyVertical
          companySize
          companyRole
          monthlyTraffic
          useCase
          packageId
          token
          paymentProvider
          paymentSubscriptions
          paymentIdentifier
          packageGroup
          joinedPackageAt
          trialEndAt
          referral
          crmData
          trialDays
          googleAnalyticsID
          appsInstallationStatuses
          onboardingStatuses
          assetAnalysis
          createdAt
          updatedAt
        }
        nextToken
      }
      notificationSettingsId
      paymentRole
      unreadInAppNotificationsCounter
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListAccountTeamMembersQueryVariables,
  APITypes.ListAccountTeamMembersQuery
>;
export const getTeamMemberSettings = /* GraphQL */ `query GetTeamMemberSettings($id: ID!) {
  getTeamMemberSettings(id: $id) {
    id
    teamMemberId
    notificationSettingsId
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetTeamMemberSettingsQueryVariables,
  APITypes.GetTeamMemberSettingsQuery
>;
export const listTeamMemberSettingss = /* GraphQL */ `query ListTeamMemberSettingss(
  $filter: ModelteamMemberSettingsFilterInput
  $limit: Int
  $nextToken: String
) {
  listTeamMemberSettingss(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      teamMemberId
      notificationSettingsId
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListTeamMemberSettingssQueryVariables,
  APITypes.ListTeamMemberSettingssQuery
>;
export const getNotificationSettings = /* GraphQL */ `query GetNotificationSettings($id: ID!) {
  getNotificationSettings(id: $id) {
    id
    owner
    projectId
    email {
      showAnonymous
      enabled
      digest
    }
    inApp {
      showAnonymous
      enabled
      digest
    }
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetNotificationSettingsQueryVariables,
  APITypes.GetNotificationSettingsQuery
>;
export const listNotificationSettingss = /* GraphQL */ `query ListNotificationSettingss(
  $filter: ModelnotificationSettingsFilterInput
  $limit: Int
  $nextToken: String
) {
  listNotificationSettingss(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      projectId
      email {
        showAnonymous
        enabled
        digest
      }
      inApp {
        showAnonymous
        enabled
        digest
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListNotificationSettingssQueryVariables,
  APITypes.ListNotificationSettingssQuery
>;
export const getFolder = /* GraphQL */ `query GetFolder($id: ID!) {
  getFolder(id: $id) {
    id
    owner
    name
    appKey
    private
    type
    appUrl
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<APITypes.GetFolderQueryVariables, APITypes.GetFolderQuery>;
export const listFolders = /* GraphQL */ `query ListFolders(
  $filter: ModelfolderFilterInput
  $limit: Int
  $nextToken: String
) {
  listFolders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      name
      appKey
      private
      type
      appUrl
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListFoldersQueryVariables,
  APITypes.ListFoldersQuery
>;
export const getAccountUser = /* GraphQL */ `query GetAccountUser($id: ID!) {
  getAccountUser(id: $id) {
    id
    appKey
    email
    name
    phone
    createdAt
    updatedAt
    lastSeenAt
    emailVerified
    sessionCount
    customAttributes
  }
}
` as GeneratedQuery<
  APITypes.GetAccountUserQueryVariables,
  APITypes.GetAccountUserQuery
>;
export const listAccountUsers = /* GraphQL */ `query ListAccountUsers(
  $filter: ModelaccountUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listAccountUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      appKey
      email
      name
      phone
      createdAt
      updatedAt
      lastSeenAt
      emailVerified
      sessionCount
      customAttributes
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListAccountUsersQueryVariables,
  APITypes.ListAccountUsersQuery
>;
export const getAccountUsage = /* GraphQL */ `query GetAccountUsage($id: ID!) {
  getAccountUsage(id: $id) {
    id
    appKey
    featureId
    usage
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetAccountUsageQueryVariables,
  APITypes.GetAccountUsageQuery
>;
export const listAccountUsages = /* GraphQL */ `query ListAccountUsages(
  $filter: ModelaccountUsageFilterInput
  $limit: Int
  $nextToken: String
) {
  listAccountUsages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      appKey
      featureId
      usage
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListAccountUsagesQueryVariables,
  APITypes.ListAccountUsagesQuery
>;
export const getPlayerConfig = /* GraphQL */ `query GetPlayerConfig($id: ID!) {
  getPlayerConfig(id: $id) {
    id
    appKey
    publishId
    projectId
    config
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetPlayerConfigQueryVariables,
  APITypes.GetPlayerConfigQuery
>;
export const listPlayerConfigs = /* GraphQL */ `query ListPlayerConfigs(
  $filter: ModelplayerConfigFilterInput
  $limit: Int
  $nextToken: String
) {
  listPlayerConfigs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      appKey
      publishId
      projectId
      config
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListPlayerConfigsQueryVariables,
  APITypes.ListPlayerConfigsQuery
>;
export const getWidgetConfig = /* GraphQL */ `query GetWidgetConfig($id: ID!) {
  getWidgetConfig(id: $id) {
    id
    appKey
    publishId
    projectId
    config
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetWidgetConfigQueryVariables,
  APITypes.GetWidgetConfigQuery
>;
export const listWidgetConfigs = /* GraphQL */ `query ListWidgetConfigs(
  $filter: ModelwidgetConfigFilterInput
  $limit: Int
  $nextToken: String
) {
  listWidgetConfigs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      appKey
      publishId
      projectId
      config
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListWidgetConfigsQueryVariables,
  APITypes.ListWidgetConfigsQuery
>;
export const getAccountPackage = /* GraphQL */ `query GetAccountPackage($id: ID!) {
  getAccountPackage(id: $id) {
    id
    appKey
    packageId
    createdAt
    updatedAt
    app
    featuresPackageId
    group
  }
}
` as GeneratedQuery<
  APITypes.GetAccountPackageQueryVariables,
  APITypes.GetAccountPackageQuery
>;
export const listAccountPackages = /* GraphQL */ `query ListAccountPackages(
  $filter: ModelaccountPackageFilterInput
  $limit: Int
  $nextToken: String
) {
  listAccountPackages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      appKey
      packageId
      createdAt
      updatedAt
      app
      featuresPackageId
      group
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListAccountPackagesQueryVariables,
  APITypes.ListAccountPackagesQuery
>;
export const getPackage = /* GraphQL */ `query GetPackage($id: ID!) {
  getPackage(id: $id) {
    id
    name
    description
    stripeProducts {
      type
      price
    }
    trialPeriodDays
    maxTrialPeriodDays
    type
    details
    group
    order
    featuresPackageId
    addons
    app
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetPackageQueryVariables,
  APITypes.GetPackageQuery
>;
export const listPackages = /* GraphQL */ `query ListPackages(
  $filter: ModelpackageFilterInput
  $limit: Int
  $nextToken: String
) {
  listPackages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      stripeProducts {
        type
        price
      }
      trialPeriodDays
      maxTrialPeriodDays
      type
      details
      group
      order
      featuresPackageId
      addons
      app
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListPackagesQueryVariables,
  APITypes.ListPackagesQuery
>;
export const getExperiment = /* GraphQL */ `query GetExperiment($id: ID!) {
  getExperiment(id: $id) {
    id
    featureId
    name
    description
    expireAt
    type
    groups
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetExperimentQueryVariables,
  APITypes.GetExperimentQuery
>;
export const listExperiments = /* GraphQL */ `query ListExperiments(
  $filter: ModelexperimentFilterInput
  $limit: Int
  $nextToken: String
) {
  listExperiments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      featureId
      name
      description
      expireAt
      type
      groups
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListExperimentsQueryVariables,
  APITypes.ListExperimentsQuery
>;
export const getPayment = /* GraphQL */ `query GetPayment($id: ID!) {
  getPayment(id: $id) {
    id
    subscriptionId
    appKey
    reportAt
    usagePrice
    usageQuantity
    type
    provider
    identifier
    packageId
    packageType
    dailyUsagePrice
    aggregatedPrice
    aggregatedUsageQuantity
    dailyUsageQuantity
    seatsPrice
    price
    interval
    numberOfProTeamMembers
    invoiceId
    invoiceItemId
    paymentSubscriptionId
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetPaymentQueryVariables,
  APITypes.GetPaymentQuery
>;
export const listPayments = /* GraphQL */ `query ListPayments(
  $filter: ModelpaymentFilterInput
  $limit: Int
  $nextToken: String
) {
  listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      subscriptionId
      appKey
      reportAt
      usagePrice
      usageQuantity
      type
      provider
      identifier
      packageId
      packageType
      dailyUsagePrice
      aggregatedPrice
      aggregatedUsageQuantity
      dailyUsageQuantity
      seatsPrice
      price
      interval
      numberOfProTeamMembers
      invoiceId
      invoiceItemId
      paymentSubscriptionId
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListPaymentsQueryVariables,
  APITypes.ListPaymentsQuery
>;
export const getQuizResult = /* GraphQL */ `query GetQuizResult($id: ID!) {
  getQuizResult(id: $id) {
    id
    projectId
    appKey
    owner
    createdAt
    url
    title
    imageUrl
    flows {
      id
      type
      rules {
        stepId
        choices
      }
    }
    isFallback
    klaviyoListId
    stepName
    type
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetQuizResultQueryVariables,
  APITypes.GetQuizResultQuery
>;
export const listQuizResults = /* GraphQL */ `query ListQuizResults(
  $filter: ModelquizResultFilterInput
  $limit: Int
  $nextToken: String
) {
  listQuizResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      projectId
      appKey
      owner
      createdAt
      url
      title
      imageUrl
      flows {
        id
        type
        rules {
          stepId
          choices
        }
      }
      isFallback
      klaviyoListId
      stepName
      type
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListQuizResultsQueryVariables,
  APITypes.ListQuizResultsQuery
>;
export const getEcomProduct = /* GraphQL */ `query GetEcomProduct($id: ID!) {
  getEcomProduct(id: $id) {
    id
    productId
    appKey
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetEcomProductQueryVariables,
  APITypes.GetEcomProductQuery
>;
export const listEcomProducts = /* GraphQL */ `query ListEcomProducts(
  $filter: ModelecomProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listEcomProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      productId
      appKey
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListEcomProductsQueryVariables,
  APITypes.ListEcomProductsQuery
>;
export const getProductPageSettings = /* GraphQL */ `query GetProductPageSettings($id: ID!) {
  getProductPageSettings(id: $id) {
    id
    productId
    productUrl
    abTestId
    publishId
    appKey
    widgetType
    productSource
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetProductPageSettingsQueryVariables,
  APITypes.GetProductPageSettingsQuery
>;
export const listProductPageSettingss = /* GraphQL */ `query ListProductPageSettingss(
  $filter: ModelproductPageSettingsFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductPageSettingss(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      productId
      productUrl
      abTestId
      publishId
      appKey
      widgetType
      productSource
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListProductPageSettingssQueryVariables,
  APITypes.ListProductPageSettingssQuery
>;
export const getAbTest = /* GraphQL */ `query GetAbTest($id: ID!) {
  getAbTest(id: $id) {
    id
    appKey
    owner
    options {
      publishId
      probability
    }
    endAt
    startAt
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<APITypes.GetAbTestQueryVariables, APITypes.GetAbTestQuery>;
export const listAbTests = /* GraphQL */ `query ListAbTests(
  $filter: ModelabTestFilterInput
  $limit: Int
  $nextToken: String
) {
  listAbTests(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      appKey
      owner
      options {
        publishId
        probability
      }
      endAt
      startAt
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListAbTestsQueryVariables,
  APITypes.ListAbTestsQuery
>;
export const getIntegrationVodAsset = /* GraphQL */ `query GetIntegrationVodAsset($id: ID!) {
  getIntegrationVodAsset(id: $id) {
    id
    appKey
    owner
    app
    appId
    externalId
    externalCreatedAt
    vodId
    seen
    title
    thumbnailUrl
    downloadUrl
    status
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetIntegrationVodAssetQueryVariables,
  APITypes.GetIntegrationVodAssetQuery
>;
export const listIntegrationVodAssets = /* GraphQL */ `query ListIntegrationVodAssets(
  $filter: ModelintegrationVodAssetFilterInput
  $limit: Int
  $nextToken: String
) {
  listIntegrationVodAssets(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      owner
      app
      appId
      externalId
      externalCreatedAt
      vodId
      seen
      title
      thumbnailUrl
      downloadUrl
      status
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListIntegrationVodAssetsQueryVariables,
  APITypes.ListIntegrationVodAssetsQuery
>;
export const getVodConnection = /* GraphQL */ `query GetVodConnection($id: ID!) {
  getVodConnection(id: $id) {
    id
    appKey
    vodAssetId
    productId
    externalProductId
    collectionId
    provider
    tag
    createdAt
    typeKey
    type
    appUrl
    label
    vodLabelId
    vodSource
    vodType
    variantIds
    projectId
    orderIndex
    source
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetVodConnectionQueryVariables,
  APITypes.GetVodConnectionQuery
>;
export const listVodConnections = /* GraphQL */ `query ListVodConnections(
  $filter: ModelvodConnectionFilterInput
  $limit: Int
  $nextToken: String
) {
  listVodConnections(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      appKey
      vodAssetId
      productId
      externalProductId
      collectionId
      provider
      tag
      createdAt
      typeKey
      type
      appUrl
      label
      vodLabelId
      vodSource
      vodType
      variantIds
      projectId
      orderIndex
      source
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListVodConnectionsQueryVariables,
  APITypes.ListVodConnectionsQuery
>;
export const getCustomer = /* GraphQL */ `query GetCustomer($id: ID!) {
  getCustomer(id: $id) {
    id
    appKey
    appUrl
    externalId
    googleId
    anonymousIds
    email
    phone
    firstName
    lastName
    acceptsMarketing
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetCustomerQueryVariables,
  APITypes.GetCustomerQuery
>;
export const listCustomers = /* GraphQL */ `query ListCustomers(
  $filter: ModelcustomerFilterInput
  $limit: Int
  $nextToken: String
) {
  listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      appKey
      appUrl
      externalId
      googleId
      anonymousIds
      email
      phone
      firstName
      lastName
      acceptsMarketing
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListCustomersQueryVariables,
  APITypes.ListCustomersQuery
>;
export const getVodLabel = /* GraphQL */ `query GetVodLabel($id: ID!) {
  getVodLabel(id: $id) {
    id
    appKey
    name
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetVodLabelQueryVariables,
  APITypes.GetVodLabelQuery
>;
export const listVodLabels = /* GraphQL */ `query ListVodLabels(
  $filter: ModelvodLabelFilterInput
  $limit: Int
  $nextToken: String
) {
  listVodLabels(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      appKey
      name
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListVodLabelsQueryVariables,
  APITypes.ListVodLabelsQuery
>;
export const getSuggestedProductAcceptance = /* GraphQL */ `query GetSuggestedProductAcceptance($id: ID!) {
  getSuggestedProductAcceptance(id: $id) {
    id
    appKey
    vodAssetId
    externalProductId
    accepted
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetSuggestedProductAcceptanceQueryVariables,
  APITypes.GetSuggestedProductAcceptanceQuery
>;
export const listSuggestedProductAcceptances = /* GraphQL */ `query ListSuggestedProductAcceptances(
  $filter: ModelsuggestedProductAcceptanceFilterInput
  $limit: Int
  $nextToken: String
) {
  listSuggestedProductAcceptances(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      vodAssetId
      externalProductId
      accepted
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListSuggestedProductAcceptancesQueryVariables,
  APITypes.ListSuggestedProductAcceptancesQuery
>;
export const getCustomerWishList = /* GraphQL */ `query GetCustomerWishList($id: ID!) {
  getCustomerWishList(id: $id) {
    id
    appKey
    appUrl
    vodAssetId
    externalCustomerId
    email
    externalProductId
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetCustomerWishListQueryVariables,
  APITypes.GetCustomerWishListQuery
>;
export const listCustomerWishLists = /* GraphQL */ `query ListCustomerWishLists(
  $filter: ModelcustomerWishListFilterInput
  $limit: Int
  $nextToken: String
) {
  listCustomerWishLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      appKey
      appUrl
      vodAssetId
      externalCustomerId
      email
      externalProductId
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListCustomerWishListsQueryVariables,
  APITypes.ListCustomerWishListsQuery
>;
export const getVodConnectionSuggestion = /* GraphQL */ `query GetVodConnectionSuggestion($id: ID!) {
  getVodConnectionSuggestion(id: $id) {
    id
    appKey
    appUrl
    createdAt
    vodAssetId
    externalProductId
    status
    source
    method
    methodVersion
    score
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetVodConnectionSuggestionQueryVariables,
  APITypes.GetVodConnectionSuggestionQuery
>;
export const listVodConnectionSuggestions = /* GraphQL */ `query ListVodConnectionSuggestions(
  $filter: ModelvodConnectionSuggestionFilterInput
  $limit: Int
  $nextToken: String
) {
  listVodConnectionSuggestions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      appUrl
      createdAt
      vodAssetId
      externalProductId
      status
      source
      method
      methodVersion
      score
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListVodConnectionSuggestionsQueryVariables,
  APITypes.ListVodConnectionSuggestionsQuery
>;
export const videosByOwner = /* GraphQL */ `query VideosByOwner(
  $owner: String
  $sortDirection: ModelSortDirection
  $filter: ModelvodAssetFilterInput
  $limit: Int
  $nextToken: String
) {
  videosByOwner(
    owner: $owner
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      owner
      appKey
      appExternalId
      createdAt
      name
      description
      creatorProfile {
        url
      }
      isSoundAllowed
      status
      uploadType
      stockAsset {
        videoUrl
        posterUrl
        gifUrl
        hasOriginal
        shopifyFileId
        previewUrl
        previewShopifyFileId
        shopifyPosterUrl
        posterShopifyFileId
        avatarUrl
        audioUrl
        gifShopifyFileId
      }
      duration
      folder
      width
      height
      isVertical
      isHDR
      trimSettings
      originalVOD
      mirror
      recorderVersion
      originalSize
      externalId
      externalProviderData
      isMigratingToShopify
      shopifyUploadingStatus
      isEverUsed
      captionsSettings
      referenceIds
      vodConnections {
        items {
          id
          appKey
          vodAssetId
          productId
          externalProductId
          collectionId
          provider
          tag
          createdAt
          typeKey
          type
          appUrl
          label
          vodLabelId
          vodSource
          vodType
          variantIds
          projectId
          orderIndex
          source
          updatedAt
        }
        nextToken
      }
      analysis
      autoGenerated
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.VideosByOwnerQueryVariables,
  APITypes.VideosByOwnerQuery
>;
export const videosByAppKey = /* GraphQL */ `query VideosByAppKey(
  $appKey: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelvodAssetFilterInput
  $limit: Int
  $nextToken: String
) {
  videosByAppKey(
    appKey: $appKey
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      owner
      appKey
      appExternalId
      createdAt
      name
      description
      creatorProfile {
        url
      }
      isSoundAllowed
      status
      uploadType
      stockAsset {
        videoUrl
        posterUrl
        gifUrl
        hasOriginal
        shopifyFileId
        previewUrl
        previewShopifyFileId
        shopifyPosterUrl
        posterShopifyFileId
        avatarUrl
        audioUrl
        gifShopifyFileId
      }
      duration
      folder
      width
      height
      isVertical
      isHDR
      trimSettings
      originalVOD
      mirror
      recorderVersion
      originalSize
      externalId
      externalProviderData
      isMigratingToShopify
      shopifyUploadingStatus
      isEverUsed
      captionsSettings
      referenceIds
      vodConnections {
        items {
          id
          appKey
          vodAssetId
          productId
          externalProductId
          collectionId
          provider
          tag
          createdAt
          typeKey
          type
          appUrl
          label
          vodLabelId
          vodSource
          vodType
          variantIds
          projectId
          orderIndex
          source
          updatedAt
        }
        nextToken
      }
      analysis
      autoGenerated
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.VideosByAppKeyQueryVariables,
  APITypes.VideosByAppKeyQuery
>;
export const videosByExternalId = /* GraphQL */ `query VideosByExternalId(
  $externalId: String
  $sortDirection: ModelSortDirection
  $filter: ModelvodAssetFilterInput
  $limit: Int
  $nextToken: String
) {
  videosByExternalId(
    externalId: $externalId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      owner
      appKey
      appExternalId
      createdAt
      name
      description
      creatorProfile {
        url
      }
      isSoundAllowed
      status
      uploadType
      stockAsset {
        videoUrl
        posterUrl
        gifUrl
        hasOriginal
        shopifyFileId
        previewUrl
        previewShopifyFileId
        shopifyPosterUrl
        posterShopifyFileId
        avatarUrl
        audioUrl
        gifShopifyFileId
      }
      duration
      folder
      width
      height
      isVertical
      isHDR
      trimSettings
      originalVOD
      mirror
      recorderVersion
      originalSize
      externalId
      externalProviderData
      isMigratingToShopify
      shopifyUploadingStatus
      isEverUsed
      captionsSettings
      referenceIds
      vodConnections {
        items {
          id
          appKey
          vodAssetId
          productId
          externalProductId
          collectionId
          provider
          tag
          createdAt
          typeKey
          type
          appUrl
          label
          vodLabelId
          vodSource
          vodType
          variantIds
          projectId
          orderIndex
          source
          updatedAt
        }
        nextToken
      }
      analysis
      autoGenerated
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.VideosByExternalIdQueryVariables,
  APITypes.VideosByExternalIdQuery
>;
export const videoResponsesByOwner = /* GraphQL */ `query VideoResponsesByOwner(
  $owner: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelvodResponseAssetFilterInput
  $limit: Int
  $nextToken: String
) {
  videoResponsesByOwner(
    owner: $owner
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      createdAt
      status
      uploadType
      duration
      width
      height
      isVertical
      isHDR
      mirror
      originalSize
      email
      projectId
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.VideoResponsesByOwnerQueryVariables,
  APITypes.VideoResponsesByOwnerQuery
>;
export const videoResponsesByAppKey = /* GraphQL */ `query VideoResponsesByAppKey(
  $appKey: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelvodResponseAssetFilterInput
  $limit: Int
  $nextToken: String
) {
  videoResponsesByAppKey(
    appKey: $appKey
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      createdAt
      status
      uploadType
      duration
      width
      height
      isVertical
      isHDR
      mirror
      originalSize
      email
      projectId
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.VideoResponsesByAppKeyQueryVariables,
  APITypes.VideoResponsesByAppKeyQuery
>;
export const projectsByOwner = /* GraphQL */ `query ProjectsByOwner(
  $owner: String
  $sortDirection: ModelSortDirection
  $filter: ModelprojectFilterInput
  $limit: Int
  $nextToken: String
) {
  projectsByOwner(
    owner: $owner
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      typeKey
      createdAt
      publishId
      name
      folder
      targetPage
      verticalOrientation
      widgetPosition
      widgetHeader
      widgetUrls
      description
      emailNotifications
      notificationLevel
      viewerNotificationLevel
      chaptersEnabled
      subtitlesEnabled
      stepsOrder
      collectText
      collectFields {
        key
        name
        type
        placeholder
        link
        required
        collect
        custom
        textColor
        borderColor
        backgroundColor
      }
      collectAfterStep
      cacheLeadFormAnswers
      googleAnalyticsID
      facebookAnalyticsID
      totalResponses
      unreadResponses
      tolstoyType
      steps {
        items {
          id
          owner
          appKey
          projectId
          videoId
          name
          type
          description
          script
          question
          questionVisible
          answerTime
          answers {
            key
            text
            next
            value
            type
            disabled
          }
          products {
            id
          }
          productsAppUrl
          videoContain
          overlayText
          overlayTextColor
          multipleChoiceSelection
          defaultResponses {
            key
            text
            next
            value
            type
            disabled
          }
          defaultResponsesEnabled
          sideBarButtonsEnabled
          feedProductActionButton {
            type
            url
            text
            isOpenInANewTab
            value
            enabled
          }
          feedSidePanelType
          feedDescriptionActionButton {
            type
            url
            text
            isOpenInANewTab
            value
            enabled
          }
          feedSidePanelDescription
          productCtaType
          createdAt
          updatedAt
        }
        nextToken
      }
      stepsCount
      widgetBorderColor
      widgetShape
      widgetSize
      widgetNotificationBadge
      videoResolutions
      fastForwardEnabled
      rulesEnabled
      macro
      hideChatPlatform
      private
      chatLandingPage
      widgetSettings
      notificationSettingsId
      collectAfterAnyResponse
      startScreenText
      feed
      feedAutoScroll
      feedShowUnviewedStepsFirst
      language
      showButtonsIndex
      publishMethod
      dynamic
      feedProductCtaType
      playerSettings
      appUrl
      live
      primaryButton {
        enabled
        ctaType
        buttonText
        value
        isOpenInANewTab
      }
      secondaryButton {
        enabled
        ctaType
        buttonText
        value
        isOpenInANewTab
      }
      pdpConfig {
        primaryButton {
          enabled
          ctaType
          buttonText
          value
          isOpenInANewTab
        }
        secondaryButton {
          enabled
          ctaType
          buttonText
          value
          isOpenInANewTab
        }
        miniCtaButton {
          enabled
          text
        }
      }
      installationCompleted
      installStep
      discover
      useCaseName
      heroSettings
      sourceProjectId
      emailSettings
      videoCollectionCampaignSettings
      secretVideoSettings
      integrationSettings
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ProjectsByOwnerQueryVariables,
  APITypes.ProjectsByOwnerQuery
>;
export const projectsByAppKey = /* GraphQL */ `query ProjectsByAppKey(
  $appKey: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelprojectFilterInput
  $limit: Int
  $nextToken: String
) {
  projectsByAppKey(
    appKey: $appKey
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      typeKey
      createdAt
      publishId
      name
      folder
      targetPage
      verticalOrientation
      widgetPosition
      widgetHeader
      widgetUrls
      description
      emailNotifications
      notificationLevel
      viewerNotificationLevel
      chaptersEnabled
      subtitlesEnabled
      stepsOrder
      collectText
      collectFields {
        key
        name
        type
        placeholder
        link
        required
        collect
        custom
        textColor
        borderColor
        backgroundColor
      }
      collectAfterStep
      cacheLeadFormAnswers
      googleAnalyticsID
      facebookAnalyticsID
      totalResponses
      unreadResponses
      tolstoyType
      steps {
        items {
          id
          owner
          appKey
          projectId
          videoId
          name
          type
          description
          script
          question
          questionVisible
          answerTime
          answers {
            key
            text
            next
            value
            type
            disabled
          }
          products {
            id
          }
          productsAppUrl
          videoContain
          overlayText
          overlayTextColor
          multipleChoiceSelection
          defaultResponses {
            key
            text
            next
            value
            type
            disabled
          }
          defaultResponsesEnabled
          sideBarButtonsEnabled
          feedProductActionButton {
            type
            url
            text
            isOpenInANewTab
            value
            enabled
          }
          feedSidePanelType
          feedDescriptionActionButton {
            type
            url
            text
            isOpenInANewTab
            value
            enabled
          }
          feedSidePanelDescription
          productCtaType
          createdAt
          updatedAt
        }
        nextToken
      }
      stepsCount
      widgetBorderColor
      widgetShape
      widgetSize
      widgetNotificationBadge
      videoResolutions
      fastForwardEnabled
      rulesEnabled
      macro
      hideChatPlatform
      private
      chatLandingPage
      widgetSettings
      notificationSettingsId
      collectAfterAnyResponse
      startScreenText
      feed
      feedAutoScroll
      feedShowUnviewedStepsFirst
      language
      showButtonsIndex
      publishMethod
      dynamic
      feedProductCtaType
      playerSettings
      appUrl
      live
      primaryButton {
        enabled
        ctaType
        buttonText
        value
        isOpenInANewTab
      }
      secondaryButton {
        enabled
        ctaType
        buttonText
        value
        isOpenInANewTab
      }
      pdpConfig {
        primaryButton {
          enabled
          ctaType
          buttonText
          value
          isOpenInANewTab
        }
        secondaryButton {
          enabled
          ctaType
          buttonText
          value
          isOpenInANewTab
        }
        miniCtaButton {
          enabled
          text
        }
      }
      installationCompleted
      installStep
      discover
      useCaseName
      heroSettings
      sourceProjectId
      emailSettings
      videoCollectionCampaignSettings
      secretVideoSettings
      integrationSettings
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ProjectsByAppKeyQueryVariables,
  APITypes.ProjectsByAppKeyQuery
>;
export const projectsByAppKeySortByPublishMethod = /* GraphQL */ `query ProjectsByAppKeySortByPublishMethod(
  $appKey: String
  $publishMethod: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelprojectFilterInput
  $limit: Int
  $nextToken: String
) {
  projectsByAppKeySortByPublishMethod(
    appKey: $appKey
    publishMethod: $publishMethod
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      typeKey
      createdAt
      publishId
      name
      folder
      targetPage
      verticalOrientation
      widgetPosition
      widgetHeader
      widgetUrls
      description
      emailNotifications
      notificationLevel
      viewerNotificationLevel
      chaptersEnabled
      subtitlesEnabled
      stepsOrder
      collectText
      collectFields {
        key
        name
        type
        placeholder
        link
        required
        collect
        custom
        textColor
        borderColor
        backgroundColor
      }
      collectAfterStep
      cacheLeadFormAnswers
      googleAnalyticsID
      facebookAnalyticsID
      totalResponses
      unreadResponses
      tolstoyType
      steps {
        items {
          id
          owner
          appKey
          projectId
          videoId
          name
          type
          description
          script
          question
          questionVisible
          answerTime
          answers {
            key
            text
            next
            value
            type
            disabled
          }
          products {
            id
          }
          productsAppUrl
          videoContain
          overlayText
          overlayTextColor
          multipleChoiceSelection
          defaultResponses {
            key
            text
            next
            value
            type
            disabled
          }
          defaultResponsesEnabled
          sideBarButtonsEnabled
          feedProductActionButton {
            type
            url
            text
            isOpenInANewTab
            value
            enabled
          }
          feedSidePanelType
          feedDescriptionActionButton {
            type
            url
            text
            isOpenInANewTab
            value
            enabled
          }
          feedSidePanelDescription
          productCtaType
          createdAt
          updatedAt
        }
        nextToken
      }
      stepsCount
      widgetBorderColor
      widgetShape
      widgetSize
      widgetNotificationBadge
      videoResolutions
      fastForwardEnabled
      rulesEnabled
      macro
      hideChatPlatform
      private
      chatLandingPage
      widgetSettings
      notificationSettingsId
      collectAfterAnyResponse
      startScreenText
      feed
      feedAutoScroll
      feedShowUnviewedStepsFirst
      language
      showButtonsIndex
      publishMethod
      dynamic
      feedProductCtaType
      playerSettings
      appUrl
      live
      primaryButton {
        enabled
        ctaType
        buttonText
        value
        isOpenInANewTab
      }
      secondaryButton {
        enabled
        ctaType
        buttonText
        value
        isOpenInANewTab
      }
      pdpConfig {
        primaryButton {
          enabled
          ctaType
          buttonText
          value
          isOpenInANewTab
        }
        secondaryButton {
          enabled
          ctaType
          buttonText
          value
          isOpenInANewTab
        }
        miniCtaButton {
          enabled
          text
        }
      }
      installationCompleted
      installStep
      discover
      useCaseName
      heroSettings
      sourceProjectId
      emailSettings
      videoCollectionCampaignSettings
      secretVideoSettings
      integrationSettings
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ProjectsByAppKeySortByPublishMethodQueryVariables,
  APITypes.ProjectsByAppKeySortByPublishMethodQuery
>;
export const projectsByPublishId = /* GraphQL */ `query ProjectsByPublishId(
  $publishId: String
  $sortDirection: ModelSortDirection
  $filter: ModelprojectFilterInput
  $limit: Int
  $nextToken: String
) {
  projectsByPublishId(
    publishId: $publishId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      typeKey
      createdAt
      publishId
      name
      folder
      targetPage
      verticalOrientation
      widgetPosition
      widgetHeader
      widgetUrls
      description
      emailNotifications
      notificationLevel
      viewerNotificationLevel
      chaptersEnabled
      subtitlesEnabled
      stepsOrder
      collectText
      collectFields {
        key
        name
        type
        placeholder
        link
        required
        collect
        custom
        textColor
        borderColor
        backgroundColor
      }
      collectAfterStep
      cacheLeadFormAnswers
      googleAnalyticsID
      facebookAnalyticsID
      totalResponses
      unreadResponses
      tolstoyType
      steps {
        items {
          id
          owner
          appKey
          projectId
          videoId
          name
          type
          description
          script
          question
          questionVisible
          answerTime
          answers {
            key
            text
            next
            value
            type
            disabled
          }
          products {
            id
          }
          productsAppUrl
          videoContain
          overlayText
          overlayTextColor
          multipleChoiceSelection
          defaultResponses {
            key
            text
            next
            value
            type
            disabled
          }
          defaultResponsesEnabled
          sideBarButtonsEnabled
          feedProductActionButton {
            type
            url
            text
            isOpenInANewTab
            value
            enabled
          }
          feedSidePanelType
          feedDescriptionActionButton {
            type
            url
            text
            isOpenInANewTab
            value
            enabled
          }
          feedSidePanelDescription
          productCtaType
          createdAt
          updatedAt
        }
        nextToken
      }
      stepsCount
      widgetBorderColor
      widgetShape
      widgetSize
      widgetNotificationBadge
      videoResolutions
      fastForwardEnabled
      rulesEnabled
      macro
      hideChatPlatform
      private
      chatLandingPage
      widgetSettings
      notificationSettingsId
      collectAfterAnyResponse
      startScreenText
      feed
      feedAutoScroll
      feedShowUnviewedStepsFirst
      language
      showButtonsIndex
      publishMethod
      dynamic
      feedProductCtaType
      playerSettings
      appUrl
      live
      primaryButton {
        enabled
        ctaType
        buttonText
        value
        isOpenInANewTab
      }
      secondaryButton {
        enabled
        ctaType
        buttonText
        value
        isOpenInANewTab
      }
      pdpConfig {
        primaryButton {
          enabled
          ctaType
          buttonText
          value
          isOpenInANewTab
        }
        secondaryButton {
          enabled
          ctaType
          buttonText
          value
          isOpenInANewTab
        }
        miniCtaButton {
          enabled
          text
        }
      }
      installationCompleted
      installStep
      discover
      useCaseName
      heroSettings
      sourceProjectId
      emailSettings
      videoCollectionCampaignSettings
      secretVideoSettings
      integrationSettings
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ProjectsByPublishIdQueryVariables,
  APITypes.ProjectsByPublishIdQuery
>;
export const projectsByTypeKey = /* GraphQL */ `query ProjectsByTypeKey(
  $typeKey: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelprojectFilterInput
  $limit: Int
  $nextToken: String
) {
  projectsByTypeKey(
    typeKey: $typeKey
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      typeKey
      createdAt
      publishId
      name
      folder
      targetPage
      verticalOrientation
      widgetPosition
      widgetHeader
      widgetUrls
      description
      emailNotifications
      notificationLevel
      viewerNotificationLevel
      chaptersEnabled
      subtitlesEnabled
      stepsOrder
      collectText
      collectFields {
        key
        name
        type
        placeholder
        link
        required
        collect
        custom
        textColor
        borderColor
        backgroundColor
      }
      collectAfterStep
      cacheLeadFormAnswers
      googleAnalyticsID
      facebookAnalyticsID
      totalResponses
      unreadResponses
      tolstoyType
      steps {
        items {
          id
          owner
          appKey
          projectId
          videoId
          name
          type
          description
          script
          question
          questionVisible
          answerTime
          answers {
            key
            text
            next
            value
            type
            disabled
          }
          products {
            id
          }
          productsAppUrl
          videoContain
          overlayText
          overlayTextColor
          multipleChoiceSelection
          defaultResponses {
            key
            text
            next
            value
            type
            disabled
          }
          defaultResponsesEnabled
          sideBarButtonsEnabled
          feedProductActionButton {
            type
            url
            text
            isOpenInANewTab
            value
            enabled
          }
          feedSidePanelType
          feedDescriptionActionButton {
            type
            url
            text
            isOpenInANewTab
            value
            enabled
          }
          feedSidePanelDescription
          productCtaType
          createdAt
          updatedAt
        }
        nextToken
      }
      stepsCount
      widgetBorderColor
      widgetShape
      widgetSize
      widgetNotificationBadge
      videoResolutions
      fastForwardEnabled
      rulesEnabled
      macro
      hideChatPlatform
      private
      chatLandingPage
      widgetSettings
      notificationSettingsId
      collectAfterAnyResponse
      startScreenText
      feed
      feedAutoScroll
      feedShowUnviewedStepsFirst
      language
      showButtonsIndex
      publishMethod
      dynamic
      feedProductCtaType
      playerSettings
      appUrl
      live
      primaryButton {
        enabled
        ctaType
        buttonText
        value
        isOpenInANewTab
      }
      secondaryButton {
        enabled
        ctaType
        buttonText
        value
        isOpenInANewTab
      }
      pdpConfig {
        primaryButton {
          enabled
          ctaType
          buttonText
          value
          isOpenInANewTab
        }
        secondaryButton {
          enabled
          ctaType
          buttonText
          value
          isOpenInANewTab
        }
        miniCtaButton {
          enabled
          text
        }
      }
      installationCompleted
      installStep
      discover
      useCaseName
      heroSettings
      sourceProjectId
      emailSettings
      videoCollectionCampaignSettings
      secretVideoSettings
      integrationSettings
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ProjectsByTypeKeyQueryVariables,
  APITypes.ProjectsByTypeKeyQuery
>;
export const projectsByFolder = /* GraphQL */ `query ProjectsByFolder(
  $folder: String
  $sortDirection: ModelSortDirection
  $filter: ModelprojectFilterInput
  $limit: Int
  $nextToken: String
) {
  projectsByFolder(
    folder: $folder
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      typeKey
      createdAt
      publishId
      name
      folder
      targetPage
      verticalOrientation
      widgetPosition
      widgetHeader
      widgetUrls
      description
      emailNotifications
      notificationLevel
      viewerNotificationLevel
      chaptersEnabled
      subtitlesEnabled
      stepsOrder
      collectText
      collectFields {
        key
        name
        type
        placeholder
        link
        required
        collect
        custom
        textColor
        borderColor
        backgroundColor
      }
      collectAfterStep
      cacheLeadFormAnswers
      googleAnalyticsID
      facebookAnalyticsID
      totalResponses
      unreadResponses
      tolstoyType
      steps {
        items {
          id
          owner
          appKey
          projectId
          videoId
          name
          type
          description
          script
          question
          questionVisible
          answerTime
          answers {
            key
            text
            next
            value
            type
            disabled
          }
          products {
            id
          }
          productsAppUrl
          videoContain
          overlayText
          overlayTextColor
          multipleChoiceSelection
          defaultResponses {
            key
            text
            next
            value
            type
            disabled
          }
          defaultResponsesEnabled
          sideBarButtonsEnabled
          feedProductActionButton {
            type
            url
            text
            isOpenInANewTab
            value
            enabled
          }
          feedSidePanelType
          feedDescriptionActionButton {
            type
            url
            text
            isOpenInANewTab
            value
            enabled
          }
          feedSidePanelDescription
          productCtaType
          createdAt
          updatedAt
        }
        nextToken
      }
      stepsCount
      widgetBorderColor
      widgetShape
      widgetSize
      widgetNotificationBadge
      videoResolutions
      fastForwardEnabled
      rulesEnabled
      macro
      hideChatPlatform
      private
      chatLandingPage
      widgetSettings
      notificationSettingsId
      collectAfterAnyResponse
      startScreenText
      feed
      feedAutoScroll
      feedShowUnviewedStepsFirst
      language
      showButtonsIndex
      publishMethod
      dynamic
      feedProductCtaType
      playerSettings
      appUrl
      live
      primaryButton {
        enabled
        ctaType
        buttonText
        value
        isOpenInANewTab
      }
      secondaryButton {
        enabled
        ctaType
        buttonText
        value
        isOpenInANewTab
      }
      pdpConfig {
        primaryButton {
          enabled
          ctaType
          buttonText
          value
          isOpenInANewTab
        }
        secondaryButton {
          enabled
          ctaType
          buttonText
          value
          isOpenInANewTab
        }
        miniCtaButton {
          enabled
          text
        }
      }
      installationCompleted
      installStep
      discover
      useCaseName
      heroSettings
      sourceProjectId
      emailSettings
      videoCollectionCampaignSettings
      secretVideoSettings
      integrationSettings
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ProjectsByFolderQueryVariables,
  APITypes.ProjectsByFolderQuery
>;
export const stepsByProjectId = /* GraphQL */ `query StepsByProjectId(
  $projectId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelprojectStepFilterInput
  $limit: Int
  $nextToken: String
) {
  stepsByProjectId(
    projectId: $projectId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      projectId
      videoId
      name
      type
      description
      script
      question
      questionVisible
      answerTime
      answers {
        key
        text
        next
        value
        type
        disabled
      }
      products {
        id
      }
      productsAppUrl
      videoContain
      overlayText
      overlayTextColor
      multipleChoiceSelection
      defaultResponses {
        key
        text
        next
        value
        type
        disabled
      }
      defaultResponsesEnabled
      sideBarButtonsEnabled
      feedProductActionButton {
        type
        url
        text
        isOpenInANewTab
        value
        enabled
      }
      feedSidePanelType
      feedDescriptionActionButton {
        type
        url
        text
        isOpenInANewTab
        value
        enabled
      }
      feedSidePanelDescription
      productCtaType
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.StepsByProjectIdQueryVariables,
  APITypes.StepsByProjectIdQuery
>;
export const stepsByOwner = /* GraphQL */ `query StepsByOwner(
  $owner: String
  $sortDirection: ModelSortDirection
  $filter: ModelprojectStepFilterInput
  $limit: Int
  $nextToken: String
) {
  stepsByOwner(
    owner: $owner
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      projectId
      videoId
      name
      type
      description
      script
      question
      questionVisible
      answerTime
      answers {
        key
        text
        next
        value
        type
        disabled
      }
      products {
        id
      }
      productsAppUrl
      videoContain
      overlayText
      overlayTextColor
      multipleChoiceSelection
      defaultResponses {
        key
        text
        next
        value
        type
        disabled
      }
      defaultResponsesEnabled
      sideBarButtonsEnabled
      feedProductActionButton {
        type
        url
        text
        isOpenInANewTab
        value
        enabled
      }
      feedSidePanelType
      feedDescriptionActionButton {
        type
        url
        text
        isOpenInANewTab
        value
        enabled
      }
      feedSidePanelDescription
      productCtaType
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.StepsByOwnerQueryVariables,
  APITypes.StepsByOwnerQuery
>;
export const stepsByAppKey = /* GraphQL */ `query StepsByAppKey(
  $appKey: String
  $sortDirection: ModelSortDirection
  $filter: ModelprojectStepFilterInput
  $limit: Int
  $nextToken: String
) {
  stepsByAppKey(
    appKey: $appKey
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      projectId
      videoId
      name
      type
      description
      script
      question
      questionVisible
      answerTime
      answers {
        key
        text
        next
        value
        type
        disabled
      }
      products {
        id
      }
      productsAppUrl
      videoContain
      overlayText
      overlayTextColor
      multipleChoiceSelection
      defaultResponses {
        key
        text
        next
        value
        type
        disabled
      }
      defaultResponsesEnabled
      sideBarButtonsEnabled
      feedProductActionButton {
        type
        url
        text
        isOpenInANewTab
        value
        enabled
      }
      feedSidePanelType
      feedDescriptionActionButton {
        type
        url
        text
        isOpenInANewTab
        value
        enabled
      }
      feedSidePanelDescription
      productCtaType
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.StepsByAppKeyQueryVariables,
  APITypes.StepsByAppKeyQuery
>;
export const stepsByVideoId = /* GraphQL */ `query StepsByVideoId(
  $videoId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelprojectStepFilterInput
  $limit: Int
  $nextToken: String
) {
  stepsByVideoId(
    videoId: $videoId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      projectId
      videoId
      name
      type
      description
      script
      question
      questionVisible
      answerTime
      answers {
        key
        text
        next
        value
        type
        disabled
      }
      products {
        id
      }
      productsAppUrl
      videoContain
      overlayText
      overlayTextColor
      multipleChoiceSelection
      defaultResponses {
        key
        text
        next
        value
        type
        disabled
      }
      defaultResponsesEnabled
      sideBarButtonsEnabled
      feedProductActionButton {
        type
        url
        text
        isOpenInANewTab
        value
        enabled
      }
      feedSidePanelType
      feedDescriptionActionButton {
        type
        url
        text
        isOpenInANewTab
        value
        enabled
      }
      feedSidePanelDescription
      productCtaType
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.StepsByVideoIdQueryVariables,
  APITypes.StepsByVideoIdQuery
>;
export const userSettingsByEmail = /* GraphQL */ `query UserSettingsByEmail(
  $email: String
  $sortDirection: ModelSortDirection
  $filter: ModeluserSettingsFilterInput
  $limit: Int
  $nextToken: String
) {
  userSettingsByEmail(
    email: $email
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      email
      firstName
      lastName
      lastLogin
      source
      token
      appKey
      hasLoggedIn
      hasShared
      viewedTolstoys
      calendars {
        id
        name
        url
      }
      emailAutomationTool
      logoSettings
      googleInfo
      crmData
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.UserSettingsByEmailQueryVariables,
  APITypes.UserSettingsByEmailQuery
>;
export const userSettingsByOwner = /* GraphQL */ `query UserSettingsByOwner(
  $owner: String
  $sortDirection: ModelSortDirection
  $filter: ModeluserSettingsFilterInput
  $limit: Int
  $nextToken: String
) {
  userSettingsByOwner(
    owner: $owner
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      email
      firstName
      lastName
      lastLogin
      source
      token
      appKey
      hasLoggedIn
      hasShared
      viewedTolstoys
      calendars {
        id
        name
        url
      }
      emailAutomationTool
      logoSettings
      googleInfo
      crmData
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.UserSettingsByOwnerQueryVariables,
  APITypes.UserSettingsByOwnerQuery
>;
export const userSettingsByToken = /* GraphQL */ `query UserSettingsByToken(
  $token: String
  $sortDirection: ModelSortDirection
  $filter: ModeluserSettingsFilterInput
  $limit: Int
  $nextToken: String
) {
  userSettingsByToken(
    token: $token
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      email
      firstName
      lastName
      lastLogin
      source
      token
      appKey
      hasLoggedIn
      hasShared
      viewedTolstoys
      calendars {
        id
        name
        url
      }
      emailAutomationTool
      logoSettings
      googleInfo
      crmData
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.UserSettingsByTokenQueryVariables,
  APITypes.UserSettingsByTokenQuery
>;
export const userSettingsByAppKey = /* GraphQL */ `query UserSettingsByAppKey(
  $appKey: String
  $sortDirection: ModelSortDirection
  $filter: ModeluserSettingsFilterInput
  $limit: Int
  $nextToken: String
) {
  userSettingsByAppKey(
    appKey: $appKey
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      email
      firstName
      lastName
      lastLogin
      source
      token
      appKey
      hasLoggedIn
      hasShared
      viewedTolstoys
      calendars {
        id
        name
        url
      }
      emailAutomationTool
      logoSettings
      googleInfo
      crmData
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.UserSettingsByAppKeyQueryVariables,
  APITypes.UserSettingsByAppKeyQuery
>;
export const accountSettingsByAppKey = /* GraphQL */ `query AccountSettingsByAppKey(
  $appKey: String
  $sortDirection: ModelSortDirection
  $filter: ModelaccountSettingsFilterInput
  $limit: Int
  $nextToken: String
) {
  accountSettingsByAppKey(
    appKey: $appKey
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      owner
      name
      logoSettings
      widgetSettings
      downgradeInfo
      liveIds
      url
      logoUrl
      playerLogoUrl
      companyVertical
      companySize
      companyRole
      monthlyTraffic
      useCase
      packageId
      token
      paymentProvider
      paymentSubscriptions
      paymentIdentifier
      packageGroup
      joinedPackageAt
      trialEndAt
      referral
      crmData
      trialDays
      googleAnalyticsID
      appsInstallationStatuses
      onboardingStatuses
      assetAnalysis
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.AccountSettingsByAppKeyQueryVariables,
  APITypes.AccountSettingsByAppKeyQuery
>;
export const accountSettingsByOwner = /* GraphQL */ `query AccountSettingsByOwner(
  $owner: String
  $sortDirection: ModelSortDirection
  $filter: ModelaccountSettingsFilterInput
  $limit: Int
  $nextToken: String
) {
  accountSettingsByOwner(
    owner: $owner
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      owner
      name
      logoSettings
      widgetSettings
      downgradeInfo
      liveIds
      url
      logoUrl
      playerLogoUrl
      companyVertical
      companySize
      companyRole
      monthlyTraffic
      useCase
      packageId
      token
      paymentProvider
      paymentSubscriptions
      paymentIdentifier
      packageGroup
      joinedPackageAt
      trialEndAt
      referral
      crmData
      trialDays
      googleAnalyticsID
      appsInstallationStatuses
      onboardingStatuses
      assetAnalysis
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.AccountSettingsByOwnerQueryVariables,
  APITypes.AccountSettingsByOwnerQuery
>;
export const accountSettingsByPackageId = /* GraphQL */ `query AccountSettingsByPackageId(
  $packageId: String
  $sortDirection: ModelSortDirection
  $filter: ModelaccountSettingsFilterInput
  $limit: Int
  $nextToken: String
) {
  accountSettingsByPackageId(
    packageId: $packageId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      owner
      name
      logoSettings
      widgetSettings
      downgradeInfo
      liveIds
      url
      logoUrl
      playerLogoUrl
      companyVertical
      companySize
      companyRole
      monthlyTraffic
      useCase
      packageId
      token
      paymentProvider
      paymentSubscriptions
      paymentIdentifier
      packageGroup
      joinedPackageAt
      trialEndAt
      referral
      crmData
      trialDays
      googleAnalyticsID
      appsInstallationStatuses
      onboardingStatuses
      assetAnalysis
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.AccountSettingsByPackageIdQueryVariables,
  APITypes.AccountSettingsByPackageIdQuery
>;
export const accountSettingsByToken = /* GraphQL */ `query AccountSettingsByToken(
  $token: String
  $sortDirection: ModelSortDirection
  $filter: ModelaccountSettingsFilterInput
  $limit: Int
  $nextToken: String
) {
  accountSettingsByToken(
    token: $token
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      owner
      name
      logoSettings
      widgetSettings
      downgradeInfo
      liveIds
      url
      logoUrl
      playerLogoUrl
      companyVertical
      companySize
      companyRole
      monthlyTraffic
      useCase
      packageId
      token
      paymentProvider
      paymentSubscriptions
      paymentIdentifier
      packageGroup
      joinedPackageAt
      trialEndAt
      referral
      crmData
      trialDays
      googleAnalyticsID
      appsInstallationStatuses
      onboardingStatuses
      assetAnalysis
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.AccountSettingsByTokenQueryVariables,
  APITypes.AccountSettingsByTokenQuery
>;
export const accountSettingsByPaymentIdentifier = /* GraphQL */ `query AccountSettingsByPaymentIdentifier(
  $paymentIdentifier: String
  $sortDirection: ModelSortDirection
  $filter: ModelaccountSettingsFilterInput
  $limit: Int
  $nextToken: String
) {
  accountSettingsByPaymentIdentifier(
    paymentIdentifier: $paymentIdentifier
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      owner
      name
      logoSettings
      widgetSettings
      downgradeInfo
      liveIds
      url
      logoUrl
      playerLogoUrl
      companyVertical
      companySize
      companyRole
      monthlyTraffic
      useCase
      packageId
      token
      paymentProvider
      paymentSubscriptions
      paymentIdentifier
      packageGroup
      joinedPackageAt
      trialEndAt
      referral
      crmData
      trialDays
      googleAnalyticsID
      appsInstallationStatuses
      onboardingStatuses
      assetAnalysis
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.AccountSettingsByPaymentIdentifierQueryVariables,
  APITypes.AccountSettingsByPaymentIdentifierQuery
>;
export const appsByOwner = /* GraphQL */ `query AppsByOwner(
  $owner: String
  $sortDirection: ModelSortDirection
  $filter: ModelappFilterInput
  $limit: Int
  $nextToken: String
) {
  appsByOwner(
    owner: $owner
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      app
      active
      data
      token
      appExternalId
      appUrl
      importSettings
      importRange
      syncTagsMode
      syndicateBy
      googleAnalyticsID
      settings
      isShopAppEligible
      taxonomyId
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.AppsByOwnerQueryVariables,
  APITypes.AppsByOwnerQuery
>;
export const appsByAppKey = /* GraphQL */ `query AppsByAppKey(
  $appKey: String
  $sortDirection: ModelSortDirection
  $filter: ModelappFilterInput
  $limit: Int
  $nextToken: String
) {
  appsByAppKey(
    appKey: $appKey
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      app
      active
      data
      token
      appExternalId
      appUrl
      importSettings
      importRange
      syncTagsMode
      syndicateBy
      googleAnalyticsID
      settings
      isShopAppEligible
      taxonomyId
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.AppsByAppKeyQueryVariables,
  APITypes.AppsByAppKeyQuery
>;
export const appsByExternalId = /* GraphQL */ `query AppsByExternalId(
  $appExternalId: String
  $sortDirection: ModelSortDirection
  $filter: ModelappFilterInput
  $limit: Int
  $nextToken: String
) {
  appsByExternalId(
    appExternalId: $appExternalId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      app
      active
      data
      token
      appExternalId
      appUrl
      importSettings
      importRange
      syncTagsMode
      syndicateBy
      googleAnalyticsID
      settings
      isShopAppEligible
      taxonomyId
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.AppsByExternalIdQueryVariables,
  APITypes.AppsByExternalIdQuery
>;
export const appsByAppUrl = /* GraphQL */ `query AppsByAppUrl(
  $appUrl: String
  $sortDirection: ModelSortDirection
  $filter: ModelappFilterInput
  $limit: Int
  $nextToken: String
) {
  appsByAppUrl(
    appUrl: $appUrl
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      app
      active
      data
      token
      appExternalId
      appUrl
      importSettings
      importRange
      syncTagsMode
      syndicateBy
      googleAnalyticsID
      settings
      isShopAppEligible
      taxonomyId
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.AppsByAppUrlQueryVariables,
  APITypes.AppsByAppUrlQuery
>;
export const appsByImportSettings = /* GraphQL */ `query AppsByImportSettings(
  $importSettings: ImportSettings
  $sortDirection: ModelSortDirection
  $filter: ModelappFilterInput
  $limit: Int
  $nextToken: String
) {
  appsByImportSettings(
    importSettings: $importSettings
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      app
      active
      data
      token
      appExternalId
      appUrl
      importSettings
      importRange
      syncTagsMode
      syndicateBy
      googleAnalyticsID
      settings
      isShopAppEligible
      taxonomyId
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.AppsByImportSettingsQueryVariables,
  APITypes.AppsByImportSettingsQuery
>;
export const projectAppsByProjectId = /* GraphQL */ `query ProjectAppsByProjectId(
  $projectId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelprojectAppFilterInput
  $limit: Int
  $nextToken: String
) {
  projectAppsByProjectId(
    projectId: $projectId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appId
      appKey
      projectId
      owner
      active
      data
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ProjectAppsByProjectIdQueryVariables,
  APITypes.ProjectAppsByProjectIdQuery
>;
export const projectAppsByOwner = /* GraphQL */ `query ProjectAppsByOwner(
  $owner: String
  $sortDirection: ModelSortDirection
  $filter: ModelprojectAppFilterInput
  $limit: Int
  $nextToken: String
) {
  projectAppsByOwner(
    owner: $owner
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appId
      appKey
      projectId
      owner
      active
      data
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ProjectAppsByOwnerQueryVariables,
  APITypes.ProjectAppsByOwnerQuery
>;
export const projectAppsByAppKey = /* GraphQL */ `query ProjectAppsByAppKey(
  $appKey: String
  $sortDirection: ModelSortDirection
  $filter: ModelprojectAppFilterInput
  $limit: Int
  $nextToken: String
) {
  projectAppsByAppKey(
    appKey: $appKey
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appId
      appKey
      projectId
      owner
      active
      data
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ProjectAppsByAppKeyQueryVariables,
  APITypes.ProjectAppsByAppKeyQuery
>;
export const responsesByProjectId = /* GraphQL */ `query ResponsesByProjectId(
  $projectId: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelresponseFilterInput
  $limit: Int
  $nextToken: String
) {
  responsesByProjectId(
    projectId: $projectId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      stepName
      projectId
      anonymousId
      sessionId
      type
      value
      email
      name
      phone
      createdAt
      read
      collectInfo
      answerType
      parentUrl
      isProfanity
      attachments {
        fileName
        url
        type
      }
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ResponsesByProjectIdQueryVariables,
  APITypes.ResponsesByProjectIdQuery
>;
export const responsesByOwner = /* GraphQL */ `query ResponsesByOwner(
  $owner: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelresponseFilterInput
  $limit: Int
  $nextToken: String
) {
  responsesByOwner(
    owner: $owner
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      stepName
      projectId
      anonymousId
      sessionId
      type
      value
      email
      name
      phone
      createdAt
      read
      collectInfo
      answerType
      parentUrl
      isProfanity
      attachments {
        fileName
        url
        type
      }
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ResponsesByOwnerQueryVariables,
  APITypes.ResponsesByOwnerQuery
>;
export const responsesByAppKey = /* GraphQL */ `query ResponsesByAppKey(
  $appKey: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelresponseFilterInput
  $limit: Int
  $nextToken: String
) {
  responsesByAppKey(
    appKey: $appKey
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      stepName
      projectId
      anonymousId
      sessionId
      type
      value
      email
      name
      phone
      createdAt
      read
      collectInfo
      answerType
      parentUrl
      isProfanity
      attachments {
        fileName
        url
        type
      }
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ResponsesByAppKeyQueryVariables,
  APITypes.ResponsesByAppKeyQuery
>;
export const responsesBySessionId = /* GraphQL */ `query ResponsesBySessionId(
  $sessionId: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelresponseFilterInput
  $limit: Int
  $nextToken: String
) {
  responsesBySessionId(
    sessionId: $sessionId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      stepName
      projectId
      anonymousId
      sessionId
      type
      value
      email
      name
      phone
      createdAt
      read
      collectInfo
      answerType
      parentUrl
      isProfanity
      attachments {
        fileName
        url
        type
      }
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ResponsesBySessionIdQueryVariables,
  APITypes.ResponsesBySessionIdQuery
>;
export const responsesByEmail = /* GraphQL */ `query ResponsesByEmail(
  $email: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelresponseFilterInput
  $limit: Int
  $nextToken: String
) {
  responsesByEmail(
    email: $email
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      stepName
      projectId
      anonymousId
      sessionId
      type
      value
      email
      name
      phone
      createdAt
      read
      collectInfo
      answerType
      parentUrl
      isProfanity
      attachments {
        fileName
        url
        type
      }
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ResponsesByEmailQueryVariables,
  APITypes.ResponsesByEmailQuery
>;
export const inAppNotificationsByOwner = /* GraphQL */ `query InAppNotificationsByOwner(
  $owner: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelinAppNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  inAppNotificationsByOwner(
    owner: $owner
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      teamMemberId
      projectId
      sessionId
      type
      value
      email
      createdAt
      read
      expirationUnixTime
      token
      stepName
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.InAppNotificationsByOwnerQueryVariables,
  APITypes.InAppNotificationsByOwnerQuery
>;
export const inAppNotificationsByTeamMemberId = /* GraphQL */ `query InAppNotificationsByTeamMemberId(
  $teamMemberId: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelinAppNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  inAppNotificationsByTeamMemberId(
    teamMemberId: $teamMemberId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      teamMemberId
      projectId
      sessionId
      type
      value
      email
      createdAt
      read
      expirationUnixTime
      token
      stepName
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.InAppNotificationsByTeamMemberIdQueryVariables,
  APITypes.InAppNotificationsByTeamMemberIdQuery
>;
export const conferenceRoomsByOwner = /* GraphQL */ `query ConferenceRoomsByOwner(
  $owner: String
  $sortDirection: ModelSortDirection
  $filter: ModelconferenceRoomFilterInput
  $limit: Int
  $nextToken: String
) {
  conferenceRoomsByOwner(
    owner: $owner
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      roomId
      userId
      active
      email
      appToken
      lastSeenAt
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ConferenceRoomsByOwnerQueryVariables,
  APITypes.ConferenceRoomsByOwnerQuery
>;
export const conferenceRoomActivityByRoomName = /* GraphQL */ `query ConferenceRoomActivityByRoomName(
  $roomName: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelconferenceRoomActivityFilterInput
  $limit: Int
  $nextToken: String
) {
  conferenceRoomActivityByRoomName(
    roomName: $roomName
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      roomName
      createdAt
      expirationUnixTime
      updatedAt
      appToken
      type
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ConferenceRoomActivityByRoomNameQueryVariables,
  APITypes.ConferenceRoomActivityByRoomNameQuery
>;
export const sessionsByProjectId = /* GraphQL */ `query SessionsByProjectId(
  $projectId: String
  $lastEventAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelsessionFilterInput
  $limit: Int
  $nextToken: String
) {
  sessionsByProjectId(
    projectId: $projectId
    lastEventAt: $lastEventAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      assignee
      appKey
      projectId
      createdAt
      updatedAt
      lastEventAt
      sessionTime
      name
      email
      phone
      read
      archived
      actionNeeded
      totalEvents
      totalResponses
      totalReplies
      parentUrl
      videoResponse
      imageResponse
      audioResponse
      textResponse
      formResponse
      reachedEnd
      isSpam
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.SessionsByProjectIdQueryVariables,
  APITypes.SessionsByProjectIdQuery
>;
export const sessionsByOwner = /* GraphQL */ `query SessionsByOwner(
  $owner: String
  $lastEventAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelsessionFilterInput
  $limit: Int
  $nextToken: String
) {
  sessionsByOwner(
    owner: $owner
    lastEventAt: $lastEventAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      assignee
      appKey
      projectId
      createdAt
      updatedAt
      lastEventAt
      sessionTime
      name
      email
      phone
      read
      archived
      actionNeeded
      totalEvents
      totalResponses
      totalReplies
      parentUrl
      videoResponse
      imageResponse
      audioResponse
      textResponse
      formResponse
      reachedEnd
      isSpam
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.SessionsByOwnerQueryVariables,
  APITypes.SessionsByOwnerQuery
>;
export const sessionsByEmail = /* GraphQL */ `query SessionsByEmail(
  $email: String
  $lastEventAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelsessionFilterInput
  $limit: Int
  $nextToken: String
) {
  sessionsByEmail(
    email: $email
    lastEventAt: $lastEventAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      assignee
      appKey
      projectId
      createdAt
      updatedAt
      lastEventAt
      sessionTime
      name
      email
      phone
      read
      archived
      actionNeeded
      totalEvents
      totalResponses
      totalReplies
      parentUrl
      videoResponse
      imageResponse
      audioResponse
      textResponse
      formResponse
      reachedEnd
      isSpam
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.SessionsByEmailQueryVariables,
  APITypes.SessionsByEmailQuery
>;
export const sessionByAppKey = /* GraphQL */ `query SessionByAppKey(
  $appKey: String
  $lastEventAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelsessionFilterInput
  $limit: Int
  $nextToken: String
) {
  sessionByAppKey(
    appKey: $appKey
    lastEventAt: $lastEventAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      assignee
      appKey
      projectId
      createdAt
      updatedAt
      lastEventAt
      sessionTime
      name
      email
      phone
      read
      archived
      actionNeeded
      totalEvents
      totalResponses
      totalReplies
      parentUrl
      videoResponse
      imageResponse
      audioResponse
      textResponse
      formResponse
      reachedEnd
      isSpam
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.SessionByAppKeyQueryVariables,
  APITypes.SessionByAppKeyQuery
>;
export const accountIdentifiedUsersByOwner = /* GraphQL */ `query AccountIdentifiedUsersByOwner(
  $owner: String
  $sortDirection: ModelSortDirection
  $filter: ModelaccountIdentifiedUsersFilterInput
  $limit: Int
  $nextToken: String
) {
  accountIdentifiedUsersByOwner(
    owner: $owner
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      userId
      email
      phone
      name
      lastSeenAt
      firstSeenAt
      signedUpAt
      sessionCount
      customAttributes
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.AccountIdentifiedUsersByOwnerQueryVariables,
  APITypes.AccountIdentifiedUsersByOwnerQuery
>;
export const accountIdentifiedUsersByAppKey = /* GraphQL */ `query AccountIdentifiedUsersByAppKey(
  $appKey: String
  $sortDirection: ModelSortDirection
  $filter: ModelaccountIdentifiedUsersFilterInput
  $limit: Int
  $nextToken: String
) {
  accountIdentifiedUsersByAppKey(
    appKey: $appKey
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      userId
      email
      phone
      name
      lastSeenAt
      firstSeenAt
      signedUpAt
      sessionCount
      customAttributes
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.AccountIdentifiedUsersByAppKeyQueryVariables,
  APITypes.AccountIdentifiedUsersByAppKeyQuery
>;
export const accountIdentifiedUsersByEmail = /* GraphQL */ `query AccountIdentifiedUsersByEmail(
  $email: String
  $sortDirection: ModelSortDirection
  $filter: ModelaccountIdentifiedUsersFilterInput
  $limit: Int
  $nextToken: String
) {
  accountIdentifiedUsersByEmail(
    email: $email
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      userId
      email
      phone
      name
      lastSeenAt
      firstSeenAt
      signedUpAt
      sessionCount
      customAttributes
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.AccountIdentifiedUsersByEmailQueryVariables,
  APITypes.AccountIdentifiedUsersByEmailQuery
>;
export const accountIdentifiedUsersByUserId = /* GraphQL */ `query AccountIdentifiedUsersByUserId(
  $userId: String
  $sortDirection: ModelSortDirection
  $filter: ModelaccountIdentifiedUsersFilterInput
  $limit: Int
  $nextToken: String
) {
  accountIdentifiedUsersByUserId(
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      userId
      email
      phone
      name
      lastSeenAt
      firstSeenAt
      signedUpAt
      sessionCount
      customAttributes
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.AccountIdentifiedUsersByUserIdQueryVariables,
  APITypes.AccountIdentifiedUsersByUserIdQuery
>;
export const ruleGroupsByProjectId = /* GraphQL */ `query RuleGroupsByProjectId(
  $projectId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelruleGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  ruleGroupsByProjectId(
    projectId: $projectId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      projectId
      publishId
      owner
      rules {
        key
        type
        condition
        value
        limit
        behaviors
      }
      enabled
      visitorModeEnabled
      exitIntent
      delayTriggerEnabled
      delayTriggerSeconds
      showOnDevice
      showOnDomains
      abTestId
      type
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.RuleGroupsByProjectIdQueryVariables,
  APITypes.RuleGroupsByProjectIdQuery
>;
export const ruleGroupsByAppKey = /* GraphQL */ `query RuleGroupsByAppKey(
  $appKey: String
  $sortDirection: ModelSortDirection
  $filter: ModelruleGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  ruleGroupsByAppKey(
    appKey: $appKey
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      projectId
      publishId
      owner
      rules {
        key
        type
        condition
        value
        limit
        behaviors
      }
      enabled
      visitorModeEnabled
      exitIntent
      delayTriggerEnabled
      delayTriggerSeconds
      showOnDevice
      showOnDomains
      abTestId
      type
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.RuleGroupsByAppKeyQueryVariables,
  APITypes.RuleGroupsByAppKeyQuery
>;
export const ruleGroupsByOwner = /* GraphQL */ `query RuleGroupsByOwner(
  $owner: String
  $sortDirection: ModelSortDirection
  $filter: ModelruleGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  ruleGroupsByOwner(
    owner: $owner
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      projectId
      publishId
      owner
      rules {
        key
        type
        condition
        value
        limit
        behaviors
      }
      enabled
      visitorModeEnabled
      exitIntent
      delayTriggerEnabled
      delayTriggerSeconds
      showOnDevice
      showOnDomains
      abTestId
      type
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.RuleGroupsByOwnerQueryVariables,
  APITypes.RuleGroupsByOwnerQuery
>;
export const ruleGroupsByAbTestId = /* GraphQL */ `query RuleGroupsByAbTestId(
  $abTestId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelruleGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  ruleGroupsByAbTestId(
    abTestId: $abTestId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      projectId
      publishId
      owner
      rules {
        key
        type
        condition
        value
        limit
        behaviors
      }
      enabled
      visitorModeEnabled
      exitIntent
      delayTriggerEnabled
      delayTriggerSeconds
      showOnDevice
      showOnDomains
      abTestId
      type
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.RuleGroupsByAbTestIdQueryVariables,
  APITypes.RuleGroupsByAbTestIdQuery
>;
export const identifyAttributesByAppKey = /* GraphQL */ `query IdentifyAttributesByAppKey(
  $appKey: String
  $sortDirection: ModelSortDirection
  $filter: ModelidentifyAttributeFilterInput
  $limit: Int
  $nextToken: String
) {
  identifyAttributesByAppKey(
    appKey: $appKey
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      owner
      key
      type
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.IdentifyAttributesByAppKeyQueryVariables,
  APITypes.IdentifyAttributesByAppKeyQuery
>;
export const identifyAttributesByOwner = /* GraphQL */ `query IdentifyAttributesByOwner(
  $owner: String
  $sortDirection: ModelSortDirection
  $filter: ModelidentifyAttributeFilterInput
  $limit: Int
  $nextToken: String
) {
  identifyAttributesByOwner(
    owner: $owner
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      owner
      key
      type
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.IdentifyAttributesByOwnerQueryVariables,
  APITypes.IdentifyAttributesByOwnerQuery
>;
export const invitesByAppKey = /* GraphQL */ `query InvitesByAppKey(
  $appKey: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelinviteFilterInput
  $limit: Int
  $nextToken: String
) {
  invitesByAppKey(
    appKey: $appKey
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      email
      role
      status
      token
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.InvitesByAppKeyQueryVariables,
  APITypes.InvitesByAppKeyQuery
>;
export const invitesByOwner = /* GraphQL */ `query InvitesByOwner(
  $owner: String
  $sortDirection: ModelSortDirection
  $filter: ModelinviteFilterInput
  $limit: Int
  $nextToken: String
) {
  invitesByOwner(
    owner: $owner
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      email
      role
      status
      token
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.InvitesByOwnerQueryVariables,
  APITypes.InvitesByOwnerQuery
>;
export const invitesByToken = /* GraphQL */ `query InvitesByToken(
  $token: String
  $sortDirection: ModelSortDirection
  $filter: ModelinviteFilterInput
  $limit: Int
  $nextToken: String
) {
  invitesByToken(
    token: $token
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      email
      role
      status
      token
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.InvitesByTokenQueryVariables,
  APITypes.InvitesByTokenQuery
>;
export const accountTeamMembersByAppKey = /* GraphQL */ `query AccountTeamMembersByAppKey(
  $appKey: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelaccountTeamMemberFilterInput
  $limit: Int
  $nextToken: String
) {
  accountTeamMembersByAppKey(
    appKey: $appKey
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      email
      role
      status
      inviteToken
      createdAt
      userId
      teamMemberId
      lastLoginAt
      user {
        items {
          id
          owner
          email
          firstName
          lastName
          lastLogin
          source
          token
          appKey
          hasLoggedIn
          hasShared
          viewedTolstoys
          calendars {
            id
            name
            url
          }
          emailAutomationTool
          logoSettings
          googleInfo
          crmData
          createdAt
          updatedAt
        }
        nextToken
      }
      account {
        items {
          id
          appKey
          owner
          name
          logoSettings
          widgetSettings
          downgradeInfo
          liveIds
          url
          logoUrl
          playerLogoUrl
          companyVertical
          companySize
          companyRole
          monthlyTraffic
          useCase
          packageId
          token
          paymentProvider
          paymentSubscriptions
          paymentIdentifier
          packageGroup
          joinedPackageAt
          trialEndAt
          referral
          crmData
          trialDays
          googleAnalyticsID
          appsInstallationStatuses
          onboardingStatuses
          assetAnalysis
          createdAt
          updatedAt
        }
        nextToken
      }
      notificationSettingsId
      paymentRole
      unreadInAppNotificationsCounter
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.AccountTeamMembersByAppKeyQueryVariables,
  APITypes.AccountTeamMembersByAppKeyQuery
>;
export const accountTeamMembersByOwner = /* GraphQL */ `query AccountTeamMembersByOwner(
  $owner: String
  $sortDirection: ModelSortDirection
  $filter: ModelaccountTeamMemberFilterInput
  $limit: Int
  $nextToken: String
) {
  accountTeamMembersByOwner(
    owner: $owner
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      email
      role
      status
      inviteToken
      createdAt
      userId
      teamMemberId
      lastLoginAt
      user {
        items {
          id
          owner
          email
          firstName
          lastName
          lastLogin
          source
          token
          appKey
          hasLoggedIn
          hasShared
          viewedTolstoys
          calendars {
            id
            name
            url
          }
          emailAutomationTool
          logoSettings
          googleInfo
          crmData
          createdAt
          updatedAt
        }
        nextToken
      }
      account {
        items {
          id
          appKey
          owner
          name
          logoSettings
          widgetSettings
          downgradeInfo
          liveIds
          url
          logoUrl
          playerLogoUrl
          companyVertical
          companySize
          companyRole
          monthlyTraffic
          useCase
          packageId
          token
          paymentProvider
          paymentSubscriptions
          paymentIdentifier
          packageGroup
          joinedPackageAt
          trialEndAt
          referral
          crmData
          trialDays
          googleAnalyticsID
          appsInstallationStatuses
          onboardingStatuses
          assetAnalysis
          createdAt
          updatedAt
        }
        nextToken
      }
      notificationSettingsId
      paymentRole
      unreadInAppNotificationsCounter
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.AccountTeamMembersByOwnerQueryVariables,
  APITypes.AccountTeamMembersByOwnerQuery
>;
export const accountTeamMembersByInviteToken = /* GraphQL */ `query AccountTeamMembersByInviteToken(
  $inviteToken: String
  $sortDirection: ModelSortDirection
  $filter: ModelaccountTeamMemberFilterInput
  $limit: Int
  $nextToken: String
) {
  accountTeamMembersByInviteToken(
    inviteToken: $inviteToken
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      email
      role
      status
      inviteToken
      createdAt
      userId
      teamMemberId
      lastLoginAt
      user {
        items {
          id
          owner
          email
          firstName
          lastName
          lastLogin
          source
          token
          appKey
          hasLoggedIn
          hasShared
          viewedTolstoys
          calendars {
            id
            name
            url
          }
          emailAutomationTool
          logoSettings
          googleInfo
          crmData
          createdAt
          updatedAt
        }
        nextToken
      }
      account {
        items {
          id
          appKey
          owner
          name
          logoSettings
          widgetSettings
          downgradeInfo
          liveIds
          url
          logoUrl
          playerLogoUrl
          companyVertical
          companySize
          companyRole
          monthlyTraffic
          useCase
          packageId
          token
          paymentProvider
          paymentSubscriptions
          paymentIdentifier
          packageGroup
          joinedPackageAt
          trialEndAt
          referral
          crmData
          trialDays
          googleAnalyticsID
          appsInstallationStatuses
          onboardingStatuses
          assetAnalysis
          createdAt
          updatedAt
        }
        nextToken
      }
      notificationSettingsId
      paymentRole
      unreadInAppNotificationsCounter
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.AccountTeamMembersByInviteTokenQueryVariables,
  APITypes.AccountTeamMembersByInviteTokenQuery
>;
export const accountTeamMembersByUserId = /* GraphQL */ `query AccountTeamMembersByUserId(
  $userId: String
  $lastLoginAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelaccountTeamMemberFilterInput
  $limit: Int
  $nextToken: String
) {
  accountTeamMembersByUserId(
    userId: $userId
    lastLoginAt: $lastLoginAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      email
      role
      status
      inviteToken
      createdAt
      userId
      teamMemberId
      lastLoginAt
      user {
        items {
          id
          owner
          email
          firstName
          lastName
          lastLogin
          source
          token
          appKey
          hasLoggedIn
          hasShared
          viewedTolstoys
          calendars {
            id
            name
            url
          }
          emailAutomationTool
          logoSettings
          googleInfo
          crmData
          createdAt
          updatedAt
        }
        nextToken
      }
      account {
        items {
          id
          appKey
          owner
          name
          logoSettings
          widgetSettings
          downgradeInfo
          liveIds
          url
          logoUrl
          playerLogoUrl
          companyVertical
          companySize
          companyRole
          monthlyTraffic
          useCase
          packageId
          token
          paymentProvider
          paymentSubscriptions
          paymentIdentifier
          packageGroup
          joinedPackageAt
          trialEndAt
          referral
          crmData
          trialDays
          googleAnalyticsID
          appsInstallationStatuses
          onboardingStatuses
          assetAnalysis
          createdAt
          updatedAt
        }
        nextToken
      }
      notificationSettingsId
      paymentRole
      unreadInAppNotificationsCounter
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.AccountTeamMembersByUserIdQueryVariables,
  APITypes.AccountTeamMembersByUserIdQuery
>;
export const accountTeamMembersByTeamMemberId = /* GraphQL */ `query AccountTeamMembersByTeamMemberId(
  $teamMemberId: String
  $sortDirection: ModelSortDirection
  $filter: ModelaccountTeamMemberFilterInput
  $limit: Int
  $nextToken: String
) {
  accountTeamMembersByTeamMemberId(
    teamMemberId: $teamMemberId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      email
      role
      status
      inviteToken
      createdAt
      userId
      teamMemberId
      lastLoginAt
      user {
        items {
          id
          owner
          email
          firstName
          lastName
          lastLogin
          source
          token
          appKey
          hasLoggedIn
          hasShared
          viewedTolstoys
          calendars {
            id
            name
            url
          }
          emailAutomationTool
          logoSettings
          googleInfo
          crmData
          createdAt
          updatedAt
        }
        nextToken
      }
      account {
        items {
          id
          appKey
          owner
          name
          logoSettings
          widgetSettings
          downgradeInfo
          liveIds
          url
          logoUrl
          playerLogoUrl
          companyVertical
          companySize
          companyRole
          monthlyTraffic
          useCase
          packageId
          token
          paymentProvider
          paymentSubscriptions
          paymentIdentifier
          packageGroup
          joinedPackageAt
          trialEndAt
          referral
          crmData
          trialDays
          googleAnalyticsID
          appsInstallationStatuses
          onboardingStatuses
          assetAnalysis
          createdAt
          updatedAt
        }
        nextToken
      }
      notificationSettingsId
      paymentRole
      unreadInAppNotificationsCounter
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.AccountTeamMembersByTeamMemberIdQueryVariables,
  APITypes.AccountTeamMembersByTeamMemberIdQuery
>;
export const accountTeamMembersByEmail = /* GraphQL */ `query AccountTeamMembersByEmail(
  $email: String
  $sortDirection: ModelSortDirection
  $filter: ModelaccountTeamMemberFilterInput
  $limit: Int
  $nextToken: String
) {
  accountTeamMembersByEmail(
    email: $email
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      appKey
      email
      role
      status
      inviteToken
      createdAt
      userId
      teamMemberId
      lastLoginAt
      user {
        items {
          id
          owner
          email
          firstName
          lastName
          lastLogin
          source
          token
          appKey
          hasLoggedIn
          hasShared
          viewedTolstoys
          calendars {
            id
            name
            url
          }
          emailAutomationTool
          logoSettings
          googleInfo
          crmData
          createdAt
          updatedAt
        }
        nextToken
      }
      account {
        items {
          id
          appKey
          owner
          name
          logoSettings
          widgetSettings
          downgradeInfo
          liveIds
          url
          logoUrl
          playerLogoUrl
          companyVertical
          companySize
          companyRole
          monthlyTraffic
          useCase
          packageId
          token
          paymentProvider
          paymentSubscriptions
          paymentIdentifier
          packageGroup
          joinedPackageAt
          trialEndAt
          referral
          crmData
          trialDays
          googleAnalyticsID
          appsInstallationStatuses
          onboardingStatuses
          assetAnalysis
          createdAt
          updatedAt
        }
        nextToken
      }
      notificationSettingsId
      paymentRole
      unreadInAppNotificationsCounter
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.AccountTeamMembersByEmailQueryVariables,
  APITypes.AccountTeamMembersByEmailQuery
>;
export const teamMemberSettingsByTeamMemberId = /* GraphQL */ `query TeamMemberSettingsByTeamMemberId(
  $teamMemberId: String
  $sortDirection: ModelSortDirection
  $filter: ModelteamMemberSettingsFilterInput
  $limit: Int
  $nextToken: String
) {
  teamMemberSettingsByTeamMemberId(
    teamMemberId: $teamMemberId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      teamMemberId
      notificationSettingsId
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.TeamMemberSettingsByTeamMemberIdQueryVariables,
  APITypes.TeamMemberSettingsByTeamMemberIdQuery
>;
export const notificationSettingsByOwner = /* GraphQL */ `query NotificationSettingsByOwner(
  $owner: String
  $sortDirection: ModelSortDirection
  $filter: ModelnotificationSettingsFilterInput
  $limit: Int
  $nextToken: String
) {
  notificationSettingsByOwner(
    owner: $owner
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      projectId
      email {
        showAnonymous
        enabled
        digest
      }
      inApp {
        showAnonymous
        enabled
        digest
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.NotificationSettingsByOwnerQueryVariables,
  APITypes.NotificationSettingsByOwnerQuery
>;
export const foldersByOwner = /* GraphQL */ `query FoldersByOwner(
  $owner: String
  $sortDirection: ModelSortDirection
  $filter: ModelfolderFilterInput
  $limit: Int
  $nextToken: String
) {
  foldersByOwner(
    owner: $owner
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      name
      appKey
      private
      type
      appUrl
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.FoldersByOwnerQueryVariables,
  APITypes.FoldersByOwnerQuery
>;
export const foldersByAppKey = /* GraphQL */ `query FoldersByAppKey(
  $appKey: String
  $sortDirection: ModelSortDirection
  $filter: ModelfolderFilterInput
  $limit: Int
  $nextToken: String
) {
  foldersByAppKey(
    appKey: $appKey
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      name
      appKey
      private
      type
      appUrl
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.FoldersByAppKeyQueryVariables,
  APITypes.FoldersByAppKeyQuery
>;
export const accountUsersByAppKey = /* GraphQL */ `query AccountUsersByAppKey(
  $appKey: String
  $sortDirection: ModelSortDirection
  $filter: ModelaccountUserFilterInput
  $limit: Int
  $nextToken: String
) {
  accountUsersByAppKey(
    appKey: $appKey
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      email
      name
      phone
      createdAt
      updatedAt
      lastSeenAt
      emailVerified
      sessionCount
      customAttributes
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.AccountUsersByAppKeyQueryVariables,
  APITypes.AccountUsersByAppKeyQuery
>;
export const accountUsersByEmail = /* GraphQL */ `query AccountUsersByEmail(
  $email: String
  $lastSeenAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelaccountUserFilterInput
  $limit: Int
  $nextToken: String
) {
  accountUsersByEmail(
    email: $email
    lastSeenAt: $lastSeenAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      email
      name
      phone
      createdAt
      updatedAt
      lastSeenAt
      emailVerified
      sessionCount
      customAttributes
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.AccountUsersByEmailQueryVariables,
  APITypes.AccountUsersByEmailQuery
>;
export const accountUsageByAppKey = /* GraphQL */ `query AccountUsageByAppKey(
  $appKey: String
  $sortDirection: ModelSortDirection
  $filter: ModelaccountUsageFilterInput
  $limit: Int
  $nextToken: String
) {
  accountUsageByAppKey(
    appKey: $appKey
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      featureId
      usage
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.AccountUsageByAppKeyQueryVariables,
  APITypes.AccountUsageByAppKeyQuery
>;
export const playerConfigsByAppKey = /* GraphQL */ `query PlayerConfigsByAppKey(
  $appKey: String
  $sortDirection: ModelSortDirection
  $filter: ModelplayerConfigFilterInput
  $limit: Int
  $nextToken: String
) {
  playerConfigsByAppKey(
    appKey: $appKey
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      publishId
      projectId
      config
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.PlayerConfigsByAppKeyQueryVariables,
  APITypes.PlayerConfigsByAppKeyQuery
>;
export const playerConfigsByPublishId = /* GraphQL */ `query PlayerConfigsByPublishId(
  $publishId: String
  $sortDirection: ModelSortDirection
  $filter: ModelplayerConfigFilterInput
  $limit: Int
  $nextToken: String
) {
  playerConfigsByPublishId(
    publishId: $publishId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      publishId
      projectId
      config
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.PlayerConfigsByPublishIdQueryVariables,
  APITypes.PlayerConfigsByPublishIdQuery
>;
export const playerConfigsByProjectId = /* GraphQL */ `query PlayerConfigsByProjectId(
  $projectId: String
  $sortDirection: ModelSortDirection
  $filter: ModelplayerConfigFilterInput
  $limit: Int
  $nextToken: String
) {
  playerConfigsByProjectId(
    projectId: $projectId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      publishId
      projectId
      config
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.PlayerConfigsByProjectIdQueryVariables,
  APITypes.PlayerConfigsByProjectIdQuery
>;
export const widgetConfigsByAppKey = /* GraphQL */ `query WidgetConfigsByAppKey(
  $appKey: String
  $sortDirection: ModelSortDirection
  $filter: ModelwidgetConfigFilterInput
  $limit: Int
  $nextToken: String
) {
  widgetConfigsByAppKey(
    appKey: $appKey
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      publishId
      projectId
      config
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.WidgetConfigsByAppKeyQueryVariables,
  APITypes.WidgetConfigsByAppKeyQuery
>;
export const widgetConfigsByPublishId = /* GraphQL */ `query WidgetConfigsByPublishId(
  $publishId: String
  $sortDirection: ModelSortDirection
  $filter: ModelwidgetConfigFilterInput
  $limit: Int
  $nextToken: String
) {
  widgetConfigsByPublishId(
    publishId: $publishId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      publishId
      projectId
      config
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.WidgetConfigsByPublishIdQueryVariables,
  APITypes.WidgetConfigsByPublishIdQuery
>;
export const widgetConfigsByProjectId = /* GraphQL */ `query WidgetConfigsByProjectId(
  $projectId: String
  $sortDirection: ModelSortDirection
  $filter: ModelwidgetConfigFilterInput
  $limit: Int
  $nextToken: String
) {
  widgetConfigsByProjectId(
    projectId: $projectId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      publishId
      projectId
      config
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.WidgetConfigsByProjectIdQueryVariables,
  APITypes.WidgetConfigsByProjectIdQuery
>;
export const accountPackagesByAppKey = /* GraphQL */ `query AccountPackagesByAppKey(
  $appKey: String
  $sortDirection: ModelSortDirection
  $filter: ModelaccountPackageFilterInput
  $limit: Int
  $nextToken: String
) {
  accountPackagesByAppKey(
    appKey: $appKey
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      packageId
      createdAt
      updatedAt
      app
      featuresPackageId
      group
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.AccountPackagesByAppKeyQueryVariables,
  APITypes.AccountPackagesByAppKeyQuery
>;
export const packagesByType = /* GraphQL */ `query PackagesByType(
  $type: String
  $sortDirection: ModelSortDirection
  $filter: ModelpackageFilterInput
  $limit: Int
  $nextToken: String
) {
  packagesByType(
    type: $type
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      description
      stripeProducts {
        type
        price
      }
      trialPeriodDays
      maxTrialPeriodDays
      type
      details
      group
      order
      featuresPackageId
      addons
      app
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.PackagesByTypeQueryVariables,
  APITypes.PackagesByTypeQuery
>;
export const packagesByGroup = /* GraphQL */ `query PackagesByGroup(
  $group: String
  $order: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelpackageFilterInput
  $limit: Int
  $nextToken: String
) {
  packagesByGroup(
    group: $group
    order: $order
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      description
      stripeProducts {
        type
        price
      }
      trialPeriodDays
      maxTrialPeriodDays
      type
      details
      group
      order
      featuresPackageId
      addons
      app
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.PackagesByGroupQueryVariables,
  APITypes.PackagesByGroupQuery
>;
export const paymentsBySubscriptionId = /* GraphQL */ `query PaymentsBySubscriptionId(
  $subscriptionId: String
  $reportAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelpaymentFilterInput
  $limit: Int
  $nextToken: String
) {
  paymentsBySubscriptionId(
    subscriptionId: $subscriptionId
    reportAt: $reportAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      subscriptionId
      appKey
      reportAt
      usagePrice
      usageQuantity
      type
      provider
      identifier
      packageId
      packageType
      dailyUsagePrice
      aggregatedPrice
      aggregatedUsageQuantity
      dailyUsageQuantity
      seatsPrice
      price
      interval
      numberOfProTeamMembers
      invoiceId
      invoiceItemId
      paymentSubscriptionId
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.PaymentsBySubscriptionIdQueryVariables,
  APITypes.PaymentsBySubscriptionIdQuery
>;
export const paymentsByProvider = /* GraphQL */ `query PaymentsByProvider(
  $provider: String
  $reportAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelpaymentFilterInput
  $limit: Int
  $nextToken: String
) {
  paymentsByProvider(
    provider: $provider
    reportAt: $reportAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      subscriptionId
      appKey
      reportAt
      usagePrice
      usageQuantity
      type
      provider
      identifier
      packageId
      packageType
      dailyUsagePrice
      aggregatedPrice
      aggregatedUsageQuantity
      dailyUsageQuantity
      seatsPrice
      price
      interval
      numberOfProTeamMembers
      invoiceId
      invoiceItemId
      paymentSubscriptionId
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.PaymentsByProviderQueryVariables,
  APITypes.PaymentsByProviderQuery
>;
export const paymentsByInvoiceItemId = /* GraphQL */ `query PaymentsByInvoiceItemId(
  $invoiceItemId: String
  $reportAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelpaymentFilterInput
  $limit: Int
  $nextToken: String
) {
  paymentsByInvoiceItemId(
    invoiceItemId: $invoiceItemId
    reportAt: $reportAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      subscriptionId
      appKey
      reportAt
      usagePrice
      usageQuantity
      type
      provider
      identifier
      packageId
      packageType
      dailyUsagePrice
      aggregatedPrice
      aggregatedUsageQuantity
      dailyUsageQuantity
      seatsPrice
      price
      interval
      numberOfProTeamMembers
      invoiceId
      invoiceItemId
      paymentSubscriptionId
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.PaymentsByInvoiceItemIdQueryVariables,
  APITypes.PaymentsByInvoiceItemIdQuery
>;
export const paymentsByInvoiceId = /* GraphQL */ `query PaymentsByInvoiceId(
  $invoiceId: String
  $reportAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelpaymentFilterInput
  $limit: Int
  $nextToken: String
) {
  paymentsByInvoiceId(
    invoiceId: $invoiceId
    reportAt: $reportAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      subscriptionId
      appKey
      reportAt
      usagePrice
      usageQuantity
      type
      provider
      identifier
      packageId
      packageType
      dailyUsagePrice
      aggregatedPrice
      aggregatedUsageQuantity
      dailyUsageQuantity
      seatsPrice
      price
      interval
      numberOfProTeamMembers
      invoiceId
      invoiceItemId
      paymentSubscriptionId
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.PaymentsByInvoiceIdQueryVariables,
  APITypes.PaymentsByInvoiceIdQuery
>;
export const paymentsByAppKey = /* GraphQL */ `query PaymentsByAppKey(
  $appKey: String
  $reportAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelpaymentFilterInput
  $limit: Int
  $nextToken: String
) {
  paymentsByAppKey(
    appKey: $appKey
    reportAt: $reportAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      subscriptionId
      appKey
      reportAt
      usagePrice
      usageQuantity
      type
      provider
      identifier
      packageId
      packageType
      dailyUsagePrice
      aggregatedPrice
      aggregatedUsageQuantity
      dailyUsageQuantity
      seatsPrice
      price
      interval
      numberOfProTeamMembers
      invoiceId
      invoiceItemId
      paymentSubscriptionId
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.PaymentsByAppKeyQueryVariables,
  APITypes.PaymentsByAppKeyQuery
>;
export const quizResultsByProjectId = /* GraphQL */ `query QuizResultsByProjectId(
  $projectId: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelquizResultFilterInput
  $limit: Int
  $nextToken: String
) {
  quizResultsByProjectId(
    projectId: $projectId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      projectId
      appKey
      owner
      createdAt
      url
      title
      imageUrl
      flows {
        id
        type
        rules {
          stepId
          choices
        }
      }
      isFallback
      klaviyoListId
      stepName
      type
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.QuizResultsByProjectIdQueryVariables,
  APITypes.QuizResultsByProjectIdQuery
>;
export const quizResultsByOwner = /* GraphQL */ `query QuizResultsByOwner(
  $owner: String
  $sortDirection: ModelSortDirection
  $filter: ModelquizResultFilterInput
  $limit: Int
  $nextToken: String
) {
  quizResultsByOwner(
    owner: $owner
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      projectId
      appKey
      owner
      createdAt
      url
      title
      imageUrl
      flows {
        id
        type
        rules {
          stepId
          choices
        }
      }
      isFallback
      klaviyoListId
      stepName
      type
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.QuizResultsByOwnerQueryVariables,
  APITypes.QuizResultsByOwnerQuery
>;
export const quizResultsByAppKey = /* GraphQL */ `query QuizResultsByAppKey(
  $appKey: String
  $sortDirection: ModelSortDirection
  $filter: ModelquizResultFilterInput
  $limit: Int
  $nextToken: String
) {
  quizResultsByAppKey(
    appKey: $appKey
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      projectId
      appKey
      owner
      createdAt
      url
      title
      imageUrl
      flows {
        id
        type
        rules {
          stepId
          choices
        }
      }
      isFallback
      klaviyoListId
      stepName
      type
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.QuizResultsByAppKeyQueryVariables,
  APITypes.QuizResultsByAppKeyQuery
>;
export const ecomProductByProductId = /* GraphQL */ `query EcomProductByProductId(
  $productId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelecomProductFilterInput
  $limit: Int
  $nextToken: String
) {
  ecomProductByProductId(
    productId: $productId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      productId
      appKey
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.EcomProductByProductIdQueryVariables,
  APITypes.EcomProductByProductIdQuery
>;
export const ecomProductByProductIdSortByAppKey = /* GraphQL */ `query EcomProductByProductIdSortByAppKey(
  $productId: ID
  $appKey: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelecomProductFilterInput
  $limit: Int
  $nextToken: String
) {
  ecomProductByProductIdSortByAppKey(
    productId: $productId
    appKey: $appKey
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      productId
      appKey
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.EcomProductByProductIdSortByAppKeyQueryVariables,
  APITypes.EcomProductByProductIdSortByAppKeyQuery
>;
export const ecomProductByAppKey = /* GraphQL */ `query EcomProductByAppKey(
  $appKey: String
  $sortDirection: ModelSortDirection
  $filter: ModelecomProductFilterInput
  $limit: Int
  $nextToken: String
) {
  ecomProductByAppKey(
    appKey: $appKey
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      productId
      appKey
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.EcomProductByAppKeyQueryVariables,
  APITypes.EcomProductByAppKeyQuery
>;
export const productPageSettingsByAppKey = /* GraphQL */ `query ProductPageSettingsByAppKey(
  $appKey: String
  $sortDirection: ModelSortDirection
  $filter: ModelproductPageSettingsFilterInput
  $limit: Int
  $nextToken: String
) {
  productPageSettingsByAppKey(
    appKey: $appKey
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      productId
      productUrl
      abTestId
      publishId
      appKey
      widgetType
      productSource
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ProductPageSettingsByAppKeyQueryVariables,
  APITypes.ProductPageSettingsByAppKeyQuery
>;
export const productPageSettingsByProductId = /* GraphQL */ `query ProductPageSettingsByProductId(
  $productId: String
  $sortDirection: ModelSortDirection
  $filter: ModelproductPageSettingsFilterInput
  $limit: Int
  $nextToken: String
) {
  productPageSettingsByProductId(
    productId: $productId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      productId
      productUrl
      abTestId
      publishId
      appKey
      widgetType
      productSource
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ProductPageSettingsByProductIdQueryVariables,
  APITypes.ProductPageSettingsByProductIdQuery
>;
export const productPageSettingsByProductUrl = /* GraphQL */ `query ProductPageSettingsByProductUrl(
  $productUrl: String
  $sortDirection: ModelSortDirection
  $filter: ModelproductPageSettingsFilterInput
  $limit: Int
  $nextToken: String
) {
  productPageSettingsByProductUrl(
    productUrl: $productUrl
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      productId
      productUrl
      abTestId
      publishId
      appKey
      widgetType
      productSource
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ProductPageSettingsByProductUrlQueryVariables,
  APITypes.ProductPageSettingsByProductUrlQuery
>;
export const productPageSettingsByAbTestId = /* GraphQL */ `query ProductPageSettingsByAbTestId(
  $abTestId: String
  $sortDirection: ModelSortDirection
  $filter: ModelproductPageSettingsFilterInput
  $limit: Int
  $nextToken: String
) {
  productPageSettingsByAbTestId(
    abTestId: $abTestId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      productId
      productUrl
      abTestId
      publishId
      appKey
      widgetType
      productSource
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ProductPageSettingsByAbTestIdQueryVariables,
  APITypes.ProductPageSettingsByAbTestIdQuery
>;
export const abTestByAppKey = /* GraphQL */ `query AbTestByAppKey(
  $appKey: String
  $sortDirection: ModelSortDirection
  $filter: ModelabTestFilterInput
  $limit: Int
  $nextToken: String
) {
  abTestByAppKey(
    appKey: $appKey
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      owner
      options {
        publishId
        probability
      }
      endAt
      startAt
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.AbTestByAppKeyQueryVariables,
  APITypes.AbTestByAppKeyQuery
>;
export const integrationVodAssetByAppKey = /* GraphQL */ `query IntegrationVodAssetByAppKey(
  $appKey: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelintegrationVodAssetFilterInput
  $limit: Int
  $nextToken: String
) {
  integrationVodAssetByAppKey(
    appKey: $appKey
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      owner
      app
      appId
      externalId
      externalCreatedAt
      vodId
      seen
      title
      thumbnailUrl
      downloadUrl
      status
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.IntegrationVodAssetByAppKeyQueryVariables,
  APITypes.IntegrationVodAssetByAppKeyQuery
>;
export const integrationVodAssetByExternalId = /* GraphQL */ `query IntegrationVodAssetByExternalId(
  $externalId: String
  $sortDirection: ModelSortDirection
  $filter: ModelintegrationVodAssetFilterInput
  $limit: Int
  $nextToken: String
) {
  integrationVodAssetByExternalId(
    externalId: $externalId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      owner
      app
      appId
      externalId
      externalCreatedAt
      vodId
      seen
      title
      thumbnailUrl
      downloadUrl
      status
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.IntegrationVodAssetByExternalIdQueryVariables,
  APITypes.IntegrationVodAssetByExternalIdQuery
>;
export const integrationVodAssetByAppId = /* GraphQL */ `query IntegrationVodAssetByAppId(
  $appId: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelintegrationVodAssetFilterInput
  $limit: Int
  $nextToken: String
) {
  integrationVodAssetByAppId(
    appId: $appId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      owner
      app
      appId
      externalId
      externalCreatedAt
      vodId
      seen
      title
      thumbnailUrl
      downloadUrl
      status
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.IntegrationVodAssetByAppIdQueryVariables,
  APITypes.IntegrationVodAssetByAppIdQuery
>;
export const vodConnectionByAppKey = /* GraphQL */ `query VodConnectionByAppKey(
  $appKey: String
  $sortDirection: ModelSortDirection
  $filter: ModelvodConnectionFilterInput
  $limit: Int
  $nextToken: String
) {
  vodConnectionByAppKey(
    appKey: $appKey
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      vodAssetId
      productId
      externalProductId
      collectionId
      provider
      tag
      createdAt
      typeKey
      type
      appUrl
      label
      vodLabelId
      vodSource
      vodType
      variantIds
      projectId
      orderIndex
      source
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.VodConnectionByAppKeyQueryVariables,
  APITypes.VodConnectionByAppKeyQuery
>;
export const vodConnectionByAppKeySortByType = /* GraphQL */ `query VodConnectionByAppKeySortByType(
  $appKey: String
  $type: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelvodConnectionFilterInput
  $limit: Int
  $nextToken: String
) {
  vodConnectionByAppKeySortByType(
    appKey: $appKey
    type: $type
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      vodAssetId
      productId
      externalProductId
      collectionId
      provider
      tag
      createdAt
      typeKey
      type
      appUrl
      label
      vodLabelId
      vodSource
      vodType
      variantIds
      projectId
      orderIndex
      source
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.VodConnectionByAppKeySortByTypeQueryVariables,
  APITypes.VodConnectionByAppKeySortByTypeQuery
>;
export const vodConnectionByTypeKey = /* GraphQL */ `query VodConnectionByTypeKey(
  $typeKey: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelvodConnectionFilterInput
  $limit: Int
  $nextToken: String
) {
  vodConnectionByTypeKey(
    typeKey: $typeKey
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      vodAssetId
      productId
      externalProductId
      collectionId
      provider
      tag
      createdAt
      typeKey
      type
      appUrl
      label
      vodLabelId
      vodSource
      vodType
      variantIds
      projectId
      orderIndex
      source
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.VodConnectionByTypeKeyQueryVariables,
  APITypes.VodConnectionByTypeKeyQuery
>;
export const vodConnectionByVodAssetId = /* GraphQL */ `query VodConnectionByVodAssetId(
  $vodAssetId: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelvodConnectionFilterInput
  $limit: Int
  $nextToken: String
) {
  vodConnectionByVodAssetId(
    vodAssetId: $vodAssetId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      vodAssetId
      productId
      externalProductId
      collectionId
      provider
      tag
      createdAt
      typeKey
      type
      appUrl
      label
      vodLabelId
      vodSource
      vodType
      variantIds
      projectId
      orderIndex
      source
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.VodConnectionByVodAssetIdQueryVariables,
  APITypes.VodConnectionByVodAssetIdQuery
>;
export const customersByAppKey = /* GraphQL */ `query CustomersByAppKey(
  $appKey: String
  $sortDirection: ModelSortDirection
  $filter: ModelcustomerFilterInput
  $limit: Int
  $nextToken: String
) {
  customersByAppKey(
    appKey: $appKey
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      appUrl
      externalId
      googleId
      anonymousIds
      email
      phone
      firstName
      lastName
      acceptsMarketing
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.CustomersByAppKeyQueryVariables,
  APITypes.CustomersByAppKeyQuery
>;
export const customersByExternalId = /* GraphQL */ `query CustomersByExternalId(
  $externalId: String
  $sortDirection: ModelSortDirection
  $filter: ModelcustomerFilterInput
  $limit: Int
  $nextToken: String
) {
  customersByExternalId(
    externalId: $externalId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      appUrl
      externalId
      googleId
      anonymousIds
      email
      phone
      firstName
      lastName
      acceptsMarketing
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.CustomersByExternalIdQueryVariables,
  APITypes.CustomersByExternalIdQuery
>;
export const customersByEmail = /* GraphQL */ `query CustomersByEmail(
  $email: String
  $sortDirection: ModelSortDirection
  $filter: ModelcustomerFilterInput
  $limit: Int
  $nextToken: String
) {
  customersByEmail(
    email: $email
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      appUrl
      externalId
      googleId
      anonymousIds
      email
      phone
      firstName
      lastName
      acceptsMarketing
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.CustomersByEmailQueryVariables,
  APITypes.CustomersByEmailQuery
>;
export const vodLabelByAppKey = /* GraphQL */ `query VodLabelByAppKey(
  $appKey: String
  $sortDirection: ModelSortDirection
  $filter: ModelvodLabelFilterInput
  $limit: Int
  $nextToken: String
) {
  vodLabelByAppKey(
    appKey: $appKey
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      name
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.VodLabelByAppKeyQueryVariables,
  APITypes.VodLabelByAppKeyQuery
>;
export const suggestedProductAcceptanceByAppKey = /* GraphQL */ `query SuggestedProductAcceptanceByAppKey(
  $appKey: String
  $sortDirection: ModelSortDirection
  $filter: ModelsuggestedProductAcceptanceFilterInput
  $limit: Int
  $nextToken: String
) {
  suggestedProductAcceptanceByAppKey(
    appKey: $appKey
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      vodAssetId
      externalProductId
      accepted
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.SuggestedProductAcceptanceByAppKeyQueryVariables,
  APITypes.SuggestedProductAcceptanceByAppKeyQuery
>;
export const suggestedProductAcceptanceByVodAssetId = /* GraphQL */ `query SuggestedProductAcceptanceByVodAssetId(
  $vodAssetId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelsuggestedProductAcceptanceFilterInput
  $limit: Int
  $nextToken: String
) {
  suggestedProductAcceptanceByVodAssetId(
    vodAssetId: $vodAssetId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      vodAssetId
      externalProductId
      accepted
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.SuggestedProductAcceptanceByVodAssetIdQueryVariables,
  APITypes.SuggestedProductAcceptanceByVodAssetIdQuery
>;
export const suggestedProductAcceptanceByExternalProductId = /* GraphQL */ `query SuggestedProductAcceptanceByExternalProductId(
  $externalProductId: String
  $sortDirection: ModelSortDirection
  $filter: ModelsuggestedProductAcceptanceFilterInput
  $limit: Int
  $nextToken: String
) {
  suggestedProductAcceptanceByExternalProductId(
    externalProductId: $externalProductId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      vodAssetId
      externalProductId
      accepted
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.SuggestedProductAcceptanceByExternalProductIdQueryVariables,
  APITypes.SuggestedProductAcceptanceByExternalProductIdQuery
>;
export const customerWishListByAppKey = /* GraphQL */ `query CustomerWishListByAppKey(
  $appKey: String
  $sortDirection: ModelSortDirection
  $filter: ModelcustomerWishListFilterInput
  $limit: Int
  $nextToken: String
) {
  customerWishListByAppKey(
    appKey: $appKey
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      appUrl
      vodAssetId
      externalCustomerId
      email
      externalProductId
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.CustomerWishListByAppKeyQueryVariables,
  APITypes.CustomerWishListByAppKeyQuery
>;
export const customerWishListByAppUrl = /* GraphQL */ `query CustomerWishListByAppUrl(
  $appUrl: String
  $sortDirection: ModelSortDirection
  $filter: ModelcustomerWishListFilterInput
  $limit: Int
  $nextToken: String
) {
  customerWishListByAppUrl(
    appUrl: $appUrl
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      appUrl
      vodAssetId
      externalCustomerId
      email
      externalProductId
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.CustomerWishListByAppUrlQueryVariables,
  APITypes.CustomerWishListByAppUrlQuery
>;
export const customerWishListByVodAssetId = /* GraphQL */ `query CustomerWishListByVodAssetId(
  $vodAssetId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelcustomerWishListFilterInput
  $limit: Int
  $nextToken: String
) {
  customerWishListByVodAssetId(
    vodAssetId: $vodAssetId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      appUrl
      vodAssetId
      externalCustomerId
      email
      externalProductId
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.CustomerWishListByVodAssetIdQueryVariables,
  APITypes.CustomerWishListByVodAssetIdQuery
>;
export const customerWishListByEmail = /* GraphQL */ `query CustomerWishListByEmail(
  $email: String
  $sortDirection: ModelSortDirection
  $filter: ModelcustomerWishListFilterInput
  $limit: Int
  $nextToken: String
) {
  customerWishListByEmail(
    email: $email
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      appUrl
      vodAssetId
      externalCustomerId
      email
      externalProductId
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.CustomerWishListByEmailQueryVariables,
  APITypes.CustomerWishListByEmailQuery
>;
export const customerWishListByExternalCustomerId = /* GraphQL */ `query CustomerWishListByExternalCustomerId(
  $externalCustomerId: String
  $sortDirection: ModelSortDirection
  $filter: ModelcustomerWishListFilterInput
  $limit: Int
  $nextToken: String
) {
  customerWishListByExternalCustomerId(
    externalCustomerId: $externalCustomerId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      appUrl
      vodAssetId
      externalCustomerId
      email
      externalProductId
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.CustomerWishListByExternalCustomerIdQueryVariables,
  APITypes.CustomerWishListByExternalCustomerIdQuery
>;
export const customerWishListByExternalCustomerIdSortByAppUrl = /* GraphQL */ `query CustomerWishListByExternalCustomerIdSortByAppUrl(
  $externalCustomerId: String
  $appUrl: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelcustomerWishListFilterInput
  $limit: Int
  $nextToken: String
) {
  customerWishListByExternalCustomerIdSortByAppUrl(
    externalCustomerId: $externalCustomerId
    appUrl: $appUrl
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      appUrl
      vodAssetId
      externalCustomerId
      email
      externalProductId
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.CustomerWishListByExternalCustomerIdSortByAppUrlQueryVariables,
  APITypes.CustomerWishListByExternalCustomerIdSortByAppUrlQuery
>;
export const vodConnectionSuggestionByAppKey = /* GraphQL */ `query VodConnectionSuggestionByAppKey(
  $appKey: String
  $sortDirection: ModelSortDirection
  $filter: ModelvodConnectionSuggestionFilterInput
  $limit: Int
  $nextToken: String
) {
  vodConnectionSuggestionByAppKey(
    appKey: $appKey
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      appUrl
      createdAt
      vodAssetId
      externalProductId
      status
      source
      method
      methodVersion
      score
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.VodConnectionSuggestionByAppKeyQueryVariables,
  APITypes.VodConnectionSuggestionByAppKeyQuery
>;
export const vodConnectionSuggestionByVodAssetId = /* GraphQL */ `query VodConnectionSuggestionByVodAssetId(
  $vodAssetId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelvodConnectionSuggestionFilterInput
  $limit: Int
  $nextToken: String
) {
  vodConnectionSuggestionByVodAssetId(
    vodAssetId: $vodAssetId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      appKey
      appUrl
      createdAt
      vodAssetId
      externalProductId
      status
      source
      method
      methodVersion
      score
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.VodConnectionSuggestionByVodAssetIdQueryVariables,
  APITypes.VodConnectionSuggestionByVodAssetIdQuery
>;
