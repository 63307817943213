import React from 'react';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import styled from 'styled-components';
import { PublishModalBaseCss } from 'app/src/pages/project/components/project-top-bar/components/set-live-button/css';
import { Link } from '@material-ui/core';
import { INSTALLATION_MODAL_KEY } from 'app/src/constants/modals.constants';
import { useModalActions } from 'app/src/context/ui_store/ModalStore';
import { useApps } from 'app/src/context/AppsStore';
import useNavigation from 'app/src/hooks/useNavigation';

const InstallationRequiredModal = (props: React.HTMLAttributes<HTMLDivElement>) => {
  const { setCurrentModal } = useModalActions();
  const [{ shopify: isShopify }] = useApps();
  const { navigateToAccountInstall } = useNavigation();

  const handleInstallButtonClick = () => {
    if (!isShopify) {
      navigateToAccountInstall();
      return;
    }

    setCurrentModal(INSTALLATION_MODAL_KEY);
  };

  return (
    <LayoutRoot {...props}>
      <TextTiny>Before you can publish you need to complete the setup (2 min).</TextTiny>
      <InstallLink onClick={handleInstallButtonClick}>Set up →</InstallLink>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  ${PublishModalBaseCss};
  top: 58px;
  background: ${({ theme }) => theme.colors.info};
  display: flex;
  align-items: baseline;
  gap: 8px;
`;

const InstallLink = styled(Link)`
  font-weight: bold;
  cursor: pointer;
  min-width: 62px;
`;

export default InstallationRequiredModal;
