import React from 'react';
import styled from 'styled-components';
import {
  project as Project,
  rule as RuleType,
  rule,
  vodAsset as VodAsset,
} from 'app/src/types/entities';
import TableVideoCard from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/video-card/TableVideoCard';
import { useVodConnectionActions } from 'app/src/context/VodConnectionStore';
import { NOT_EQUALS_KEY } from 'app/src/constants/ruleGroups.constants';
import { DynamicRuleTypes } from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/constants/dynamicConditions.constants';
import { UPLOAD_HERO_FILE } from 'app/src/constants/creation_method.constants';

type Props = {
  loading: boolean;
  project: Project;
  rules: rule[];
  vodAssets: VodAsset[];
  videoRules?: RuleType[];
  setVideoRules?: (rules: RuleType[]) => void;
};

const DynamicDiscover = ({
  loading,
  project,
  rules,
  vodAssets,
  videoRules,
  setVideoRules,
}: Props) => {
  const { getIsVodLabelConnectionAlreadyExists } = useVodConnectionActions();
  const filteredVodAssets = vodAssets.filter(({ createdAt, id, uploadType }) => {
    if (uploadType === UPLOAD_HERO_FILE) {
      return false;
    }

    const matches = rules.find(({ value, condition, type }) => {
      if (
        type === DynamicRuleTypes.vodLabel &&
        condition === NOT_EQUALS_KEY &&
        getIsVodLabelConnectionAlreadyExists({ vodAssetId: id, vodLabelId: value })
      ) {
        return true;
      }
    });

    if (matches) {
      return false;
    }

    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

    return new Date(createdAt) > oneYearAgo;
  });

  return (
    <LayoutRoot>
      {!loading &&
        filteredVodAssets.map((video, index) => {
          return (
            <TableVideoCard
              key={video.id}
              video={video}
              index={index}
              isDragDisabled={true}
              project={project}
              canLock={false}
              isDiscover={true}
              videoRules={videoRules}
              setVideoRules={setVideoRules}
            />
          );
        })}
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  grid-template-columns: repeat(auto-fill, minmax(171px, 1fr));
  display: grid;
  gap: 16px;
`;

export default DynamicDiscover;
