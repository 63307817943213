import React from 'react';
import Utils from 'shared/react/utils/utils';
import SetLiveTogglePricing from 'app/src/complex_components/pricing/SetLiveTogglePricing';
import styled from 'styled-components';
import { TextSmall } from 'shared/react/components/complex/Text';
import { useApps } from 'app/src/context/AppsStore';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

const WidgetLiveButton = ({ ...props }) => {
  const [{ shopify }] = useApps();

  if (!shopify) {
    return null;
  }

  return (
    <SetLiveButton onClick={Utils.stopPropagation}>
      <SetLiveTogglePricing {...props} />
      <LiveText>Live</LiveText>
    </SetLiveButton>
  );
};

const LiveText = styled(TextSmall)``;

const SetLiveButton = styled(Gap8HorizontalFlex)`
  align-items: center;
`;

export default WidgetLiveButton;
