import { rule, VodConnectionType, vodConnection, project as Project } from 'app/src/types/entities';
import { getVodConnectionTypeKey } from 'app/src/utils/vodConnections.utils';
import { EQUALS_KEY, NOT_EQUALS_KEY } from 'app/src/constants/ruleGroups.constants';
import { DynamicRuleTypes } from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/constants/dynamicConditions.constants';
import { RulesService } from 'src/services/rules';
import { VodAsset } from 'src/types/VodAsset';
import Utils from 'src/utils';

const PRODUCT_KEY = 'product';
const LABEL_KEY = 'label';
const TRENDING_KEY = 'trending';
const NEGATIVE_RULES_CONDITIONS = [NOT_EQUALS_KEY];
const MAX_SORT_TIMES = 10;

const isWithinLast30Days = ({ createdAt }: VodAsset) => {
  const created = new Date(createdAt);

  const today = new Date();

  const threshold = 30 * 24 * 60 * 60 * 1000;

  return today.getTime() - created.getTime() <= threshold;
};

const filterByCreatedAt = ({ project, videos, vodId }) => {
  const { videosFromLastXDays } = Utils.safeParse(project?.playerSettings);
  if (!videosFromLastXDays) {
    return false;
  }

  const video = videos.find(({ id }) => id === vodId);
  if (!video) {
    return false;
  }

  return !isWithinLast30Days(video);
};

const addTypeKeyIfNeeded = (connection: vodConnection, videosMap: { [key: string]: string[] }) => {
  if (connection.type === VodConnectionType.productId) {
    videosMap[connection.vodAssetId].push(
      getVodConnectionTypeKey({ appKey: connection.appKey, id: PRODUCT_KEY })
    );
  }

  if (connection.type === VodConnectionType.vodLabelId) {
    videosMap[connection.vodAssetId].push(
      getVodConnectionTypeKey({ appKey: connection.appKey, id: LABEL_KEY })
    );
  }
};

export const getTypeKeysMap = (vodConnections: vodConnection[]): { [key: string]: string[] } => {
  const videosMap = {};

  for (const connection of vodConnections) {
    if (!videosMap[connection.vodAssetId]) {
      videosMap[connection.vodAssetId] = [];
    }

    videosMap[connection.vodAssetId].push(connection.typeKey);
    addTypeKeyIfNeeded(connection, videosMap);
  }

  return videosMap;
};

const checkRules = (value: string, condition: string, typeKeys: string[]) => {
  if (condition === EQUALS_KEY) {
    return typeKeys.includes(value);
  }

  if (condition === NOT_EQUALS_KEY) {
    return !typeKeys.includes(value);
  }

  return false;
};

export const checkAndRules = (andRules: rule[], typeKeys: string[], appKey: string) => {
  const hasOnlyNotEqualsRules = andRules.every(({ condition }) => condition === NOT_EQUALS_KEY);

  if (hasOnlyNotEqualsRules) {
    return false;
  }

  return andRules.every(({ condition, value: conditionValue, type }) => {
    if (type === DynamicRuleTypes.taggedProduct) {
      return checkRules(getVodConnectionTypeKey({ appKey, id: PRODUCT_KEY }), condition, typeKeys);
    }

    if (type === DynamicRuleTypes.taggedLabel) {
      return checkRules(getVodConnectionTypeKey({ appKey, id: LABEL_KEY }), condition, typeKeys);
    }

    if (type === DynamicRuleTypes.trending) {
      return checkRules(getVodConnectionTypeKey({ appKey, id: TRENDING_KEY }), condition, typeKeys);
    }

    return checkRules(getVodConnectionTypeKey({ appKey, id: conditionValue }), condition, typeKeys);
  });
};

const filterVideoRules = rules => {
  return rules.filter(rule => {
    return rule.some(({ type }) => {
      if ([DynamicRuleTypes.hideVodAsset, DynamicRuleTypes.orderFeed].includes(type)) {
        return false;
      }

      return true;
    });
  });
};

const getOrRules = orRules => {
  const currentOrRules = filterVideoRules(orRules);

  const isAny = currentOrRules.length > 1;

  if (!isAny) {
    return currentOrRules;
  }

  const rules = currentOrRules.flatMap(rule => rule);

  const filteredRules = rules.filter(({ condition }) => {
    return NEGATIVE_RULES_CONDITIONS.includes(condition);
  });

  if (!filteredRules.length) {
    return currentOrRules;
  }

  return currentOrRules.map(andRules => {
    return [...andRules, ...filteredRules];
  });
};

const checkOrRules = (orRules: rule[][], typeKeys: string[], appKey: string) => {
  const updatedOrRules = getOrRules(orRules);

  return updatedOrRules.some(andRules => {
    return checkAndRules(andRules, typeKeys, appKey);
  });
};

const getSortRules = rules => {
  return rules.flat().filter(currentRule => {
    return currentRule?.type === DynamicRuleTypes.orderFeed;
  });
};

const getSortPositions = (rules = []) => {
  return rules.reduce((acc, { value }) => {
    const [assetId, index, ...productId] = value.split('_');
    const currentProductName = productId.join('_');
    if (!currentProductName) {
      acc[assetId] = +index;
    } else {
      if (!acc[currentProductName]) {
        acc[currentProductName] = { [assetId]: +index };
      } else {
        acc[currentProductName][assetId] = +index;
      }
    }

    return acc;
  }, {});
};

//  this function sorts the asset IDs based on the order defined in the orderMap.
//  IDs with higher order values come first, followed by IDs with defined but lower order values.
//  Finally, any IDs without a defined order are appended to the end.

const sort = (vodAssetIds, currentProductId, orderMap) => {
  const currentOrderMap = currentProductId ? orderMap[currentProductId] : orderMap;
  const maxOrder = Math.max(...Object.values(currentOrderMap)) || 0;
  const buckets = new Array(maxOrder + 1).fill(null);
  const unorderedIds = [];

  for (const id of vodAssetIds) {
    const order = currentOrderMap[id];
    if (order !== undefined) {
      buckets[order] = id;
    } else {
      unorderedIds.push(id);
    }
  }

  let unOrderedIdsUsed = -1;
  const result = buckets.map(id => {
    if (id === null) {
      unOrderedIdsUsed++;
      return unorderedIds[unOrderedIdsUsed];
    }

    return id;
  });

  unorderedIds.splice(0, unOrderedIdsUsed + 1);
  result.push(...unorderedIds);

  return result;
};

export const sortEntities = (videoIds, rules, currentProductId) => {
  const sortRules = getSortRules(rules);

  if (!sortRules.length || !videoIds.length) {
    return videoIds;
  }

  const sortedPosition = getSortPositions(sortRules);

  const objectEntries = currentProductId
    ? Object.entries(sortedPosition[currentProductId] || {})
    : Object.entries(sortedPosition);

  if (!objectEntries.length) {
    return videoIds;
  }

  const newSorted = sort(videoIds, currentProductId, sortedPosition);

  return newSorted;
};

export const getVodAssetIdsFromDynamicRules = ({
  project,
  ruleGroup,
  vodConnections,
  videos,
}: {
  project?: Project;
  ruleGroup: any;
  vodConnections: vodConnection[];
  videos: VodAsset[];
}): string[] => {
  const videoIdsSet = new Set<string>();
  const { rules, appKey } = ruleGroup;
  const manualVodRule = RulesService.getManualVodRule(project.id);
  const shouldAddManualVodRule =
    project.id && !RulesService.isRuleUsedInRuleGroup(ruleGroup, manualVodRule);
  const orRules = shouldAddManualVodRule ? [...rules, [manualVodRule]] : rules;

  if (!orRules.length) {
    return [];
  }

  const videosMap = getTypeKeysMap(vodConnections);

  for (const [vodId, typeKeys] of Object.entries(videosMap)) {
    if (filterByCreatedAt({ project, videos, vodId })) {
      continue;
    }

    if (!checkOrRules(orRules, typeKeys, appKey)) {
      continue;
    }

    videoIdsSet.add(vodId);
  }

  const videoIds = Array.from(videoIdsSet);

  const sortedVideos = sortEntities(videoIds, orRules, null);

  return sortedVideos;
};
