import React from 'react';
import styled from 'styled-components';
import EditorHeader from '../shared/EditorHeader';
import EditorMainContainer from '../shared/EditorMainContainer';
import EditorNumericInputItem from '../shared/EditorNumericInputItem';
import { useLookAndFeelContext } from '../../../LookAndFeelContext';
import {
  TILE_BORDER_RADIUS_PROPERTY_KEY,
  TILE_HORIZONTAL_MARGIN_PROPERTY_KEY,
  TILE_VERTICAL_MARGIN_PROPERTY_KEY,
} from '../../../LookAndFeelPage.constants';
import { PaddingHorizontalIcon } from 'src/images/feedCarouselEditor/PaddingHorizontalIcon';
import { PaddingVerticalIcon } from 'src/images/feedCarouselEditor/PaddingVerticalIcon';

const TileLayoutEditor = () => {
  const {
    customizationSettings: {
      widgetSettings: { tileHorizontalMargin, tileVerticalMargin, tileBorderRadius },
    },
    setWidgetSettingsProperty,
  } = useLookAndFeelContext();

  const handleHorizontalMarginChange = (value: number) => {
    setWidgetSettingsProperty(TILE_HORIZONTAL_MARGIN_PROPERTY_KEY, value);
  };

  const handleVerticalMarginChange = (value: number) => {
    setWidgetSettingsProperty(TILE_VERTICAL_MARGIN_PROPERTY_KEY, value);
  };

  const handleBorderRadiusChange = (value: number) => {
    setWidgetSettingsProperty(TILE_BORDER_RADIUS_PROPERTY_KEY, value);
  };

  return (
    <LayoutRoot>
      <EditorHeader title="Layout" />
      <EditorMainContainer>
        <EditorNumericInputItem
          text="Horizontal padding"
          suffix="px"
          value={tileHorizontalMargin}
          onChange={handleHorizontalMarginChange}
          tooltipText={<PaddingHorizontalIcon />}
          min={0}
        />
        <EditorNumericInputItem
          text="Vertical padding"
          suffix="px"
          value={tileVerticalMargin}
          onChange={handleVerticalMarginChange}
          tooltipText={<PaddingVerticalIcon />}
          min={0}
        />
        <EditorNumericInputItem
          text="Border radius"
          suffix="px"
          value={tileBorderRadius}
          onChange={handleBorderRadiusChange}
          min={0}
        />
      </EditorMainContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div``;

export default TileLayoutEditor;
