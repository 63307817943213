import React from 'react';

const RulesEventTriggersIcon = ({ ...props }) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={40} height={40} rx={10} fill="#FFE5D7" />
    <path
      d="M24.938 21.531a.792.792 0 0 0-.22-.843l-8.5-7.5a.716.716 0 0 0-.78-.094c-.282.094-.438.375-.438.656v11.5c0 .344.188.625.469.719a.866.866 0 0 0 .281.031.719.719 0 0 0 .563-.25L18.655 23l1.438 3.063a1.292 1.292 0 0 0 1.687.593 1.292 1.292 0 0 0 .594-1.687L20.937 22h3.282a.79.79 0 0 0 .718-.469Z"
      fill="#FD7830"
    />
  </svg>
);

export default RulesEventTriggersIcon;
