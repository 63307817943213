import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import BasesColorInput from 'app/src/complex_components/color-input/ColorInput';
import InputField from './InputField';

interface TextInputProps {
  label: string;
  isRow?: boolean;
  [key: string]: unknown;
}

const TextInput: FunctionComponent<TextInputProps> = ({ label, isRow, ...props }) => (
  <InputField label={label} isRow={isRow}>
    <StyledColorInput {...(props as any)} />
  </InputField>
);

const StyledColorInput = styled(BasesColorInput)`
  .MuiOutlinedInput-root {
    height: 40px;
  }
`;

export default TextInput;
