import React from 'react';
import styled from 'styled-components';

import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import EditorSubheader from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorSubheader';
import { useBrandingContext } from '../../../BrandingContext';
import BorderRadiusEditor from 'app/src/pages/account/branding/editors/player/branching/BorderRadiusEditor';
import EditorSliderItem from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorSliderItem';
import EditorButtonGroupItem from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorButtonGroupItem';
import Colors from 'shared/react/constants/colors';

type Props = {
  settings?: any;
  onChange?: any;
};

const BACKGROUND_COLOR_LIST = [
  {
    text: 'Black',
    value: Colors.black,
  },
  {
    text: 'White',
    value: Colors.white,
  },
];

const INVERTED_COLOR = {
  [Colors.black]: Colors.white,
  [Colors.white]: Colors.black,
};

const FeedButtonsEditor = ({ settings = {}, onChange = () => {} }: Props) => {
  const { loading } = useBrandingContext();
  const { feedButtonsBorderRadius = 100, opacity, backgroundColor } = settings;
  const isDisabled = loading;

  const onCheckoutBagEnabledChange = feedButtonsBorderRadius =>
    onChange({ feedButtonsBorderRadius });
  const handleCardOpacityChange = (event, opacity) => onChange({ opacity });
  const handleColorChange = backgroundColor =>
    onChange({ backgroundColor, color: INVERTED_COLOR[backgroundColor] });

  const getBackgroundColorButton = ({ text, value }) => ({
    text,
    isEnabled: backgroundColor === value,
    onClick: () => handleColorChange(value),
  });
  const backgroundColorButtons = BACKGROUND_COLOR_LIST.map(getBackgroundColorButton);

  return (
    <LayoutRoot>
      <EditorSubheader title="Feed buttons" />
      <BorderRadiusEditor
        radius={feedButtonsBorderRadius}
        disabled={isDisabled}
        onChange={onCheckoutBagEnabledChange}
      />

      <EditorSliderItem
        onChange={handleCardOpacityChange}
        text="Button opacity"
        value={opacity}
        min={0}
        max={1}
        step={0.01}
        disabled={isDisabled}
      />

      <EditorButtonGroupItem
        text="Button color"
        buttons={backgroundColorButtons}
        disabled={isDisabled}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)``;

export default FeedButtonsEditor;
