import React, { useEffect, useRef, useState } from 'react';

import { PageContainer, PageMain } from 'app/src/pages/dashboard/components/page/Page';

import { useVirtualizedList } from 'app/src/hooks/useInfiniteScroll';
import { track } from 'app/src/helpers/Tracker';
import useVideosFilters from 'app/src/pages/dashboard/pages/videos/hooks/useVideosFilters';
import { CreationMethod } from 'app/src/constants/creation_method.constants';
import useCreateIntegrationVideo from 'app/src/hooks/use-create-integration-video/useCreateIntegrationVideo';
import { useParams } from 'react-router-dom';
import { IMPORT_METHODS } from 'app/src/hooks/use-create-integration-video/useCreateIntegrationVideo.constants';
import { SOURCES_NAME_BY_KEY } from 'app/src/constants/integration.constants';
import { VideosPageVodAsset } from 'app/src/pages/dashboard/pages/videos/types/videos.types';
import { WhereCondition } from 'app/src/pages/dashboard/utils/filterEntities';
import useUpdateVideosMultiSelectState from 'src/hooks/useUpdateVideosMultiSelectBarState';
import VideosTopBar from './components/top-bar/VideosTopBar';
import { useGoogleDrive } from 'app/src/context/GoogleDriveStore';
import useCreateIntegrationLabels from 'app/src/hooks/useCreateIntegrationLabels';
import { VIDEO_STATUS } from 'shared/react/constants/video.constants';
import VideosContent from 'app/src/pages/dashboard/pages/videos/components/videos-content/VideosContent';
import { ASSET_TYPE } from 'shared/react/services/assets';
import { VideosSidebarWithPrefetchedCounts } from 'app/src/pages/dashboard/pages/videos/components/side-bar/VideosSidebarWithPrefetchedCounts';

const VIDEO_IMPORTING_STATUSES = [VIDEO_STATUS.uploading, VIDEO_STATUS.done];

type Props = {
  videosFilters: WhereCondition;
  setVideosFilters: (filters: WhereCondition, forceReplace?: boolean) => void;
  shouldSidebarRedirect?: boolean;
  onVideoChoose?: (video: VideosPageVodAsset) => void;
  className?: string;
  hideSidebar?: boolean;
};

const Videos = ({
  videosFilters,
  setVideosFilters,
  shouldSidebarRedirect = true,
  onVideoChoose,
  className,
  hideSidebar,
}: Props) => {
  const [selectedVideos, setSelectedVideos] = useState<string[]>([]);
  const [isTrendingPlaylist, setIsTrendingPlaylist] = useState<boolean>(false);

  const containerRef = useRef();
  const {
    videos: currentVideos,
    initialized,
    unfilteredVideos,
    onScrollEndCallback,
  } = useVideosFilters({ videosFilters, setVideosFilters });

  const { importMultipleFiles } = useCreateIntegrationVideo();
  const { renderCount, isLoading, isDone } = useVirtualizedList(
    containerRef,
    currentVideos.length,
    onScrollEndCallback,
    videosFilters
  );
  const [{ videoFoldersById }] = useGoogleDrive();
  const { createIntegrationLabels } = useCreateIntegrationLabels();
  const videosForFilter = currentVideos;
  const { source } = useParams();
  const currentVideoSource = videosFilters.uploadType?.in?.[0];
  const method = IMPORT_METHODS[currentVideoSource as CreationMethod]
    ? (currentVideoSource as CreationMethod)
    : null;

  const getNotImportedVideos = (video: VideosPageVodAsset[] | null) => {
    const videos = video || selectedVideos.map(id => currentVideos.find(video => video.id === id));

    return videos.filter(video => {
      return video?.externalId && !VIDEO_IMPORTING_STATUSES.includes(video.status);
    });
  };

  const onSelectedVideosAction = async (video: VideosPageVodAsset[] | null) => {
    if (!method) {
      return;
    }

    if (video && video[0]?.type === ASSET_TYPE.GALLERY) {
      const gallery = video[0];
      await onSelectedVideosAction(
        videosForFilter.filter(currentVideo =>
          gallery.referenceIds.includes(currentVideo.externalId)
        )
      );
    }

    const currentVideos = getNotImportedVideos(video);

    if (!currentVideos.length) {
      return;
    }

    const createLabelsPromises = currentVideos.map(video => {
      return createIntegrationLabels(videoFoldersById[video?.parents?.[0]], video);
    });

    await Promise.all([
      createLabelsPromises,
      importMultipleFiles({ videos: currentVideos, method }),
    ]);
    setSelectedVideos([]);
  };

  const unselectAll = () => {
    setSelectedVideos([]);
  };

  const toggleSelected = (videoId: string) => {
    if (!selectedVideos.includes(videoId)) {
      track('Video Card Selected');
      setSelectedVideos([...selectedVideos, videoId]);
      return;
    }

    track('Video Card Select Canceled');
    setSelectedVideos(selectedVideos.filter(id => id !== videoId));
  };

  const videos = currentVideos;
  const numberOfVideosText = `${currentVideos.length} ${
    SOURCES_NAME_BY_KEY[source] || source || ''
  } videos shown`;

  useUpdateVideosMultiSelectState({
    videos: videos,
    onSelectedVideosAction: onSelectedVideosAction,
    selectedVideos: selectedVideos,
    setSelectedVideos: setSelectedVideos,
    shouldShowDelete: !method,
    disableDownload: !!getNotImportedVideos(null).length,
  });

  useEffect(() => {
    setIsTrendingPlaylist(!!videosFilters.trending);
  }, [videosFilters]);

  return (
    <PageContainer className={className}>
      <VideosSidebarWithPrefetchedCounts
        videosFilters={videosFilters}
        setVideosFilters={setVideosFilters}
        unselectAll={unselectAll}
        shouldSidebarRedirect={shouldSidebarRedirect}
        hideSidebar={hideSidebar}
        videos={unfilteredVideos}
      />
      <PageMain>
        <VideosTopBar
          isModal={!!onVideoChoose}
          videosFilters={videosFilters}
          setVideosFilters={setVideosFilters}
          onSelectedVideosAction={onSelectedVideosAction}
          canFilterLive={true}
        />
        <VideosContent
          ref={containerRef}
          isTrendingPlaylist={isTrendingPlaylist}
          videos={videos}
          selectedVideos={selectedVideos}
          toggleSelected={toggleSelected}
          onSelectedVideosAction={onSelectedVideosAction}
          initialized={initialized}
          method={method}
          videosFilters={videosFilters}
          onVideoChoose={onVideoChoose}
          isLoading={isLoading}
          isDone={isDone}
          numberOfVideosText={numberOfVideosText}
          onScrollEndCallback={onScrollEndCallback}
        />
      </PageMain>
    </PageContainer>
  );
};

export default Videos;
