import useGetFeatures from 'app/src/hooks/preview/useGetFeatures';
import { useEffect, useState } from 'react';
import { useVideoActions } from 'app/src/context/VideosStore';
import { useDesignSettings } from 'app/src/hooks/design';
import { project } from 'app/src/types/entities';
import { useProducts } from 'app/src/context/ProductsStore';
import { useVodConnection } from 'app/src/context/VodConnectionStore';
import { getPlayerProducts } from 'app/src/services/products/productsApiService';
import Utils from 'app/src/utils';

const PREVIEW_VOD_ASSETS_LIMIT = 8;

const usePlayerPreviewSettings = (
  project: project,
  customizationSettings,
  vodAssetIds: [string]
) => {
  const vodAssetIdsForPreview = vodAssetIds?.slice(0, PREVIEW_VOD_ASSETS_LIMIT) || [];
  const [currentProducts, setCurrentProducts] = useState({});
  const [playerSettings, setPlayerSettings] = useState({});
  const [isProductsInitialized, setIsProductsInitialized] = useState(false);
  const [{ ecomProducts }, { getEcomProducts }] = useProducts();
  const [
    {
      vodConnections,
      isConnectionsWithPagination,
      selectedAppUrl: currentAppUrl,
      vodConnectionByVodAssetId,
    },
  ] = useVodConnection();
  const { getVideoById } = useVideoActions();
  const { playerFeatures } = useGetFeatures();
  const design = useDesignSettings();
  const selectedAppUrl = project.appUrl || currentAppUrl;

  const getVodConnectionProductIds = video => {
    if (!isConnectionsWithPagination) {
      return (vodConnectionByVodAssetId[vodAssetIds[0]] || [])
        .filter(connection => connection.appUrl === selectedAppUrl && connection.productId)
        .sort((a, b) => a.orderIndex - b.orderIndex)
        .map(({ productId }) => productId);
    }

    return video?.vodConnections?.items
      .sort((a, b) => a.orderIndex - b.orderIndex)
      .map(connection => connection.appUrl === selectedAppUrl && connection.productId)
      .filter(Boolean);
  };

  const prepareStepsUsingVodAssetIds = () => {
    return vodAssetIdsForPreview.slice(0, PREVIEW_VOD_ASSETS_LIMIT).map(id => {
      const video = getVideoById(id);
      const products = getVodConnectionProductIds(video).map(productId => {
        return { id: productId };
      });

      return {
        key: video?.name,
        videoId: video?.id,
        videoOwner: video?.owner,
        stockAsset: video?.stockAsset,
        partName: video?.name,
        externalProviderData: Utils.safeParse(video?.externalProviderData),
        products,
      };
    });
  };

  const prepareStepsUsingSteps = steps => {
    return steps.map(step => {
      const video = getVideoById(step.videoId);
      return {
        ...step,
        key: step?.name || video?.name,
        videoId: video?.id,
        videoOwner: video?.owner,
        stockAsset: video?.stockAsset,
        partName: step?.question || step?.description || video?.name,
        externalProviderData: Utils.safeParse(video?.externalProviderData),
      };
    });
  };

  const prepareSteps = () => {
    if (project.dynamic) {
      return prepareStepsUsingVodAssetIds();
    }

    return prepareStepsUsingSteps(project.steps.items);
  };

  const getProducts = async () => {
    if (!project.dynamic) {
      await getEcomProducts(project);
    } else {
      const products = await getPlayerProducts(
        vodAssetIdsForPreview,
        project.appUrl,
        project.appKey
      );
      setCurrentProducts(products?.data || {});
    }
    setIsProductsInitialized(true);
  };

  useEffect(() => {
    if (!project || Object.keys(products).length) {
      return;
    }

    getProducts();
  }, [project, vodAssetIds]);

  useEffect(() => {
    const playerSettings = JSON.parse(project.playerSettings || '{}');
    const collectInfo = {
      text: project.collectText,
      fields: project.collectFields,
      afterStep: project.collectAfterStep,
      collectAfterAnyResponse: project.collectAfterAnyResponse,
    };

    const settings = {
      featureSettings: playerFeatures,
      ...project,
      collectInfo,
      steps: prepareSteps() || [],
      design,
      playerSettings,
      ...customizationSettings,
    };

    setPlayerSettings(settings);
  }, [project, playerFeatures, customizationSettings, vodAssetIds, vodConnections]);

  const products = project.dynamic ? currentProducts : ecomProducts;

  return { playerSettings, products, isProductsInitialized };
};

export default usePlayerPreviewSettings;
