import React from 'react';

const HomePageIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.016 10.031c.273.235.273.625.039.899-.196.273-.586.273-.86.039l-1.445-1.29v7.696a3.11 3.11 0 0 1-3.125 3.125H6.375c-1.758 0-3.125-1.367-3.125-3.125V9.68l-1.484 1.289c-.274.234-.664.234-.86-.04-.234-.273-.234-.663.04-.898L11.57.695a.566.566 0 0 1 .82 0l10.626 9.336ZM6.375 19.25h2.5V13c0-.664.547-1.25 1.25-1.25h3.75c.664 0 1.25.586 1.25 1.25v6.25h2.5c1.016 0 1.875-.82 1.875-1.875v-8.79l-7.5-6.6-7.5 6.6v8.79a1.85 1.85 0 0 0 1.875 1.875Zm3.75 0h3.75V13h-3.75v6.25Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default HomePageIcon;
