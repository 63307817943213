import React from 'react';
import BasicSelect from 'shared/react/components/basic/basic_select/BasicSelect';
import ShopifyMenuItem from 'app/src/complex_components/shopify-apps-select/components/shopify-menu-item/ShopifyMenuItem';
import styled from 'styled-components';
import Utils from 'shared/react/utils/utils';
import { SELECT_INPUT_CONTAINER_DATA_TYPE } from 'shared/react/constants/feedPreviewDataTypes.constants';
import CrownIcon from 'src/images/dashboard_v2/CrownIcon';
import { SyncTagsMode } from 'src/constants/shop.constants';
import useShopifyAppSelect from './useShopifyAppSelect';
import { APPS_SELECT_ALL_STORES_KEY } from 'src/constants/ui.constants';
import useIsCurrentPathName from 'src/hooks/useIsCurrentPathName';
import { app as AppEntity } from 'src/types/entities';

const defaultSelectUndefinedProps = {
  inputBackground: undefined,
  inputPadding: undefined,
  inputBoxSizing: undefined,
  renderHeader: undefined,
  loading: undefined,
  itemsPadding: undefined,
  error: undefined,
};

type Props = {
  appUrl: string;
  setAppUrl: (appUrl: string) => void;
  className?: string;
  isDisabled?: boolean;
};

type AppItems = {
  id: string;
  name: JSX.Element;
  value: AppEntity | object;
  icon?: JSX.Element;
};

const formatAppsItems = (apps: AppEntity[], showAllStoresOption: boolean) => {
  const appItems = apps.map(app => {
    const isPrimaryApp = app.syncTagsMode === SyncTagsMode.primary;

    return {
      id: app.appUrl,
      name: <ShopifyMenuItem appUrl={app.appUrl} />,
      value: app,
      icon: isPrimaryApp && <CrownIcon />,
    };
  }) as AppItems[];

  if (showAllStoresOption) {
    appItems.push({
      id: APPS_SELECT_ALL_STORES_KEY,
      name: <ShopifyMenuItem appUrl={'All Stores'} />,
      value: {},
    });
  }

  return appItems;
};

const ShopifyAppsSelect = ({ appUrl, setAppUrl, className, isDisabled }: Props) => {
  const { shopifyApps, shouldHideAppSelect } = useShopifyAppSelect();
  const { isAnalyticsPath } = useIsCurrentPathName();

  const shopifyAppsItems = formatAppsItems(shopifyApps, isAnalyticsPath);
  const selectedAppItem = shopifyAppsItems.find(({ id }) => id === appUrl);

  const onChange = (value: { id: string }) => {
    setAppUrl(value.id);
  };

  if (shouldHideAppSelect) {
    return null;
  }

  return (
    <CustomSelect
      {...defaultSelectUndefinedProps}
      items={shopifyAppsItems}
      value={selectedAppItem}
      onChange={onChange}
      itemWhiteSpace={'nowrap'}
      itemOverflow={'hidden'}
      borderRadius={4}
      customHeight={32}
      inputPadding={'0 8px'}
      shouldHideHeaderBorder={true}
      className={className}
      isDisabled={isDisabled}
    />
  );
};

const CustomSelect = styled(BasicSelect)<{ isDisabled?: boolean }>`
  & ${Utils.getDataType(SELECT_INPUT_CONTAINER_DATA_TYPE)} {
    pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
    padding: 7px 8px;
    display: flex;
    align-items: center;
    min-height: 32px;
    & div {
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
    }
  }
`;
export default ShopifyAppsSelect;
