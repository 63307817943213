import * as React from 'react';

const QuizFallbackIcon = ({ ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.5 17.25V6.75A.76.76 0 0 0 5.75 6a.74.74 0 0 0-.75.75v10.5c0 .438.313.75.75.75a.74.74 0 0 0 .75-.75Zm7-1.781-2.875-2.719h7.594c.437 0 .781-.313.781-.75 0-.406-.344-.75-.781-.75h-7.594L13.5 8.562A.753.753 0 0 0 13.719 8a.831.831 0 0 0-.188-.5c-.281-.313-.75-.313-1.062-.031l-4.25 4a.755.755 0 0 0 0 1.094l4.25 4c.312.28.781.28 1.062-.032.281-.312.281-.781-.031-1.062Z"
      fill="#090A0B"
    />
  </svg>
);

export default QuizFallbackIcon;
