import React from 'react';
import { PlaylistItem } from '../../../types/playlist.types';
import SidebarEditableItem from '../item/SidebarEditableItem';

type Props = {
  folders: PlaylistItem[];
  foldersMapping: { [key: string]: number };
  selectedFolderId?: string;
  onClick?: (playlist: PlaylistItem) => void;
  onEdit?: (playlist: PlaylistItem) => void;
  onDelete?: (playlist: PlaylistItem) => void;
};

export const SidebarFolderItems = ({
  folders = [],
  foldersMapping = {},
  selectedFolderId,
  onClick,
  onEdit,
  onDelete,
}: Props) =>
  folders.map(folder => (
    <SidebarEditableItem
      key={folder.id}
      name={folder.name}
      count={foldersMapping[folder.id]}
      isSelected={selectedFolderId === folder.id}
      onClick={() => onClick(folder)}
      onEdit={() => onEdit(folder)}
      onDelete={() => onDelete(folder)}
      withEditMenu
    />
  ));

export default SidebarFolderItems;
