import { Button } from "types/config";
import { uuidv4 } from "widget/src/utils/cache.utils.js";

export const wrapStorageAction = (action) => {
  try {
    return action();
  } catch (error) {
    return null;
  }
};

export const getAnonymousId = () => {
  const ANONYMOUS_ID_KEY = "tolstoy-anonymousId";
  const COOKIE_POLICY_KEY = "tolstoy-cookie-policy";
  const fromLocalStorage = wrapStorageAction(() =>
    localStorage.getItem(ANONYMOUS_ID_KEY)
  );

  if (fromLocalStorage) {
    return fromLocalStorage;
  }

  const anonymousIdCookie = document.cookie
    .split("; ")
    .find((c) => c.startsWith(ANONYMOUS_ID_KEY));
  const fromCookie = anonymousIdCookie ? anonymousIdCookie.split("=")[1] : "";

  if (fromCookie) {
    return fromCookie;
  }

  const anonymousId = uuidv4();
  const isCookiePolicyRejected = wrapStorageAction(() =>
    localStorage.getItem(COOKIE_POLICY_KEY)
  );

  if (isCookiePolicyRejected) {
    return anonymousId;
  }

  const cookie = "tolstoy-anonymousId=" + anonymousId + "; path=/; Secure";
  const domain = new URL(window.location).host;
  wrapStorageAction(() => (document.cookie = cookie + "; domain=." + domain));
  wrapStorageAction(() => (document.cookie = cookie));
};

export const getIsMobile = () => {
  if (window.innerWidth <= 450) {
    return true;
  }

  const userAgentMobile =
    (window.navigator &&
      window.navigator.userAgentData &&
      window.navigator.userAgentData.mobile) ||
    (window.navigator && !window.navigator.userAgentData);
  const orientationType =
    (screen && screen.orientation && screen.orientation.type) || "";

  return !!(userAgentMobile && orientationType.startsWith("portrait"));
};

export const isMobileSafari = () =>
  /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream; // Check for MSStream to exclude Edge on iOS

export const getFromLocalStorage = (key) => {
  wrapStorageAction(() => localStorage.getItem(key));
};

export const setToLocalStorage = (key, value) => {
  wrapStorageAction(() => localStorage.setItem(key, value));
};

export const getPublishIdFromUrl = () => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get("publishId");
};

export const getYoutubeEmbedUrl = (videoId, loop, youtubeAutoplay) => {
  let url = `https://www.youtube.com/embed/${videoId}?`;

  if (loop) {
    url += `loop=1&playlist=${videoId}&`;
  }

  if (youtubeAutoplay) {
    url += `autoplay=1&mute=1`;
  }

  return url;
};

export const createStyleObject = (
  isMobile: boolean,
  {
    backgroundColor,
    borderRadius,
    color,
    desktopFontSize,
    fontWeight,
    font,
    mobileFontSize,
  }: Partial<Button>
) => {
  const style = {
    backgroundColor,
    borderRadius,
    color: color,
    fontSize: isMobile ? mobileFontSize : desktopFontSize,
    fontWeight,
    fontFamily: font,
    lineHeight: isMobile
      ? `${mobileFontSize + 2}px`
      : `${desktopFontSize + 2}px`,
  };

  return style;
};

export const loadFont = (sources, fontFamily) => {
  const ITALIC_VALUE = "italic";
  const NORMAL_VALUE = "normal";
  const OTF_VALUE = ".otf";

  const newStyle = document.createElement("style");
  for (const sourceKey of Object.keys(sources)) {
    const url = sources[sourceKey];
    const isItalic = sourceKey.includes(ITALIC_VALUE);
    const fontWeight = sourceKey.replace(ITALIC_VALUE, "") || NORMAL_VALUE;
    const fontStyle = isItalic ? ITALIC_VALUE : NORMAL_VALUE;
    const isOTF = url.includes(OTF_VALUE);
    const fontFormat = isOTF ? "opentype" : "truetype";
    const httpsUrl = url.replace("http://", "https://");

    newStyle.append(
      document.createTextNode(
        `@font-face { font-family: '${fontFamily}'; src: url("${httpsUrl}") format("${[
          fontFormat,
        ]}"); font-weight: ${fontWeight}; font-style: ${fontStyle};}`
      )
    );
  }

  document.head.append(newStyle);
};
