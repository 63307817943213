import React from 'react';
import styled from 'styled-components';
import ResetToDefaultIcon from 'app/src/images/ResetToDefaultIcon';
import Tooltip from 'app/src/basic_components/Tooltip';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';

const ResetToDefaultInputWrapper = ({
  onClick,
  children,
  disabled = false,
  tooltipText = 'Reset to default',
  ...props
}) => {
  return (
    <LayoutRoot disabled={disabled}>
      <ResetToDefaultIconContainer {...props} onClick={onClick} disabled={disabled}>
        <StyledTooltip text={tooltipText} placement="right">
          <IconContainer>
            <ResetToDefaultIcon />
          </IconContainer>
        </StyledTooltip>
      </ResetToDefaultIconContainer>
      {children}
    </LayoutRoot>
  );
};

export default ResetToDefaultInputWrapper;

const ResetToDefaultIconContainer = styled.div`
  right: 0;
  top: 0;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 24px;
  border-radius: 50%;
  height: 24px;
  border: 1px solid ${({ theme }) => theme.colors.gray3};
  background: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  transition: transform 0.15s;
  transform: translate(45%, -45%);
  z-index: 10;
  opacity: 0;
  display: ${({ disabled }) => (disabled ? 'none' : 'flex')};

  &:hover {
    transform: scale(1.1) translate(45%, -45%);
  }
`;

const LayoutRoot = styled.div`
  position: relative;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : '')};

  &:hover ${ResetToDefaultIconContainer} {
    opacity: 1;
  }
`;

const StyledTooltip = styled(Tooltip)``;

const IconContainer = styled(HorizontalFlexWrap)`
  justify-content: center;
  align-items: center;
`;
