import { DesignSizes } from 'app/src/types/design-system';
import styled, { css, keyframes } from 'styled-components';

const spinner = keyframes`
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
`;

export const spinnerCss = css`
  &:after {
    content: '';
    display: ${({ isLoading }) => (isLoading ? 'block' : 'none')};
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 2px solid transparent;
    border-top-color: ${({ theme }) => theme.colors.primary};
    border-radius: 50%;
    animation: ${spinner} 1s linear infinite;
  }
`;

const getTransparentColor = ({ theme, disabled, isLoading }) => {
  if (isLoading) {
    return 'transparent';
  }

  return disabled ? theme.colors.neutralGray : '';
};

const disabledCss = css`
  background-color: ${({ theme, disabled, isLoading }) =>
    disabled || isLoading ? theme.colors.disabled : ''};

  color: ${getTransparentColor};

  & path {
    fill: ${getTransparentColor};
  }
`;

const xsmallButtonCss = css`
  padding: 6px 10px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  gap: 6px;
  border-radius: 4px;
  ${({ iconOnly }) => iconOnly && ''}
`;

const smallButtonCss = css`
  padding: 9px 12px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  gap: 6px;
  border-radius: 4px;
`;

const normalButtonCss = css`
  padding: 12px 16px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  gap: 8px;
  border-radius: 6px;
`;

const largeButtonCss = css`
  padding: 12px 24px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  gap: 8px;
  border-radius: 8px;
`;

const buttonSizesCssMap = {
  [DesignSizes.XSMALL]: xsmallButtonCss,
  [DesignSizes.SMALL]: smallButtonCss,
  [DesignSizes.NORMAL]: normalButtonCss,
  [DesignSizes.LARGE]: largeButtonCss,
};

export const sizesCss = css`
  ${({ size = DesignSizes.SMALL }) => buttonSizesCssMap[size]}
`;

const xsmallIconOnlyCss = css`
  height: 24px;
  width: 24px;
  padding: 0px;

  & svg {
    height: 12px;
    width: 12px;
  }
`;

const smallIconOnlyCss = css`
  height: 32px;
  width: 32px;
  padding: 0px;

  & svg {
    height: 14px;
    width: 14px;
  }
`;

const normalIconOnlyCss = css`
  height: 40px;
  width: 40px;
  padding: 0px;

  & svg {
    height: 16px;
    width: 16px;
  }
`;

const largeIconOnlyCss = css`
  height: 48px;
  width: 48px;
  padding: 0px;

  & svg {
    height: 24px;
    width: 24px;
  }
`;

const buttonIconOnlyCssMap = {
  [DesignSizes.XSMALL]: xsmallIconOnlyCss,
  [DesignSizes.SMALL]: smallIconOnlyCss,
  [DesignSizes.NORMAL]: normalIconOnlyCss,
  [DesignSizes.LARGE]: largeIconOnlyCss,
};

const iconOnlyCss = css`
  ${({ iconOnly = false, size = DesignSizes.SMALL }) => iconOnly && buttonIconOnlyCssMap[size]}
`;

export const Button = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  white-space: nowrap;
  transition: 0.3s;

  pointer-events: ${({ disabled, isLoading }) => (disabled || isLoading ? 'none' : 'auto')};
  cursor: ${({ disabled, isLoading }) => (disabled || isLoading ? 'auto' : 'pointer')};
  user-select: none;

  ${sizesCss}
  ${iconOnlyCss}
  ${spinnerCss}

  && {
    ${disabledCss}
  }

  &&:hover {
    ${disabledCss}
  }
`;

export const DangerButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.dangerV2};
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    background: ${({ theme }) => theme.colors.dangerV2Hover};
  }

  &:focus {
    background: ${({ theme }) => theme.colors.dangerV2Focus};
  }
`;

export const OrangeButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.fruityOrange};
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    background: ${({ theme }) => theme.colors.dangerV2Hover};
  }

  &:focus {
    background: ${({ theme }) => theme.colors.dangerV2Focus};
  }
`;

export const WarningButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.info};
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    background: ${({ theme }) => theme.colors.infoHover};
  }

  &:focus {
    background: ${({ theme }) => theme.colors.infoFocus};
  }
`;

export const PrimaryButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    background: ${({ theme }) => theme.colors.primaryHover};
  }

  &:focus {
    background: ${({ theme }) => theme.colors.primaryActive};
  }
`;

export const SecondaryButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.neutralLightest};
  color: ${({ theme }) => theme.colors.black};

  &:hover {
    background: ${({ theme }) => theme.colors.neutralLighter};
    color: ${({ theme }) => theme.colors.primary};
  }

  &:focus {
    background: ${({ theme }) => theme.colors.neutralLighter};
  }

  &:hover path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

export const WhiteButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.neutralLight};
  color: ${({ theme }) => theme.colors.neutralDarker};

  &:hover {
    background: ${({ theme }) => theme.colors.neutralLightest};
  }

  &:focus {
    background: ${({ theme }) => theme.colors.neutralLight};
  }
`;

export const GhostButton = styled(Button)`
  background-color: ${({ theme }) => theme.getHexOpacity(theme.colors.white, 1)};
  border: 1px solid ${({ theme }) => theme.colors.neutralLight};
  color: ${({ theme }) => theme.colors.neutralDarker};

  &:hover {
    background-color: ${({ theme }) => theme.getHexOpacity(theme.colors.white, 10)};
    border: 1px solid ${({ theme }) => theme.colors.neutralGray};
  }

  &:focus {
    background-color: ${({ theme }) => theme.getHexOpacity(theme.colors.white, 10)};
    border: 1px solid ${({ theme }) => theme.colors.neutralDarker};
  }
`;

export const SuccessButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.success};
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    background: ${({ theme }) => theme.colors.successHover};
  }

  &:focus {
    background: ${({ theme }) => theme.colors.successActive};
  }
`;

export const LinkButton = styled(Button)`
  border: none;
  color: ${({ theme }) => theme.colors.primary};
  background: transparent;

  &:hover {
    color: ${({ theme }) => theme.colors.primaryHover};
  }

  &:active {
    box-shadow: 0px 0px 0px 3px rgba(64, 117, 255, 0.35);
  }
`;
