import React from 'react';

const GifIcon = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="12"
        y="12"
        width="24"
        height="24"
        rx="5"
        ry="5"
        fill="none"
        stroke="black"
        strokeWidth="2"
      />
      <text
        x="50%"
        y="50%"
        dy=".15em"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize="10"
        fontFamily="Arial"
        fontWeight="bold"
        fill="black"
      >
        GIF
      </text>
    </svg>
  );
};

export default GifIcon;
