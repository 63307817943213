import React from 'react';

const OutlinedCrossIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 0H4C1.75 0 0 1.813 0 4v20c0 2.25 1.75 4 4 4h20c2.188 0 4-1.75 4-4V4c0-2.188-1.813-4-4-4Zm-4.063 15.5h-4.5V20c0 .875-.624 1.5-1.5 1.5a1.48 1.48 0 0 1-1.5-1.5v-4.5h-4.5a1.48 1.48 0 0 1-1.5-1.5c0-.813.688-1.5 1.5-1.5h4.5V8c0-.813.688-1.5 1.5-1.5.876 0 1.5.688 1.5 1.5v4.5h4.5c.875 0 1.5.688 1.5 1.5 0 .875-.625 1.5-1.5 1.5Z"
        fill="#fff"
      />
    </svg>
  );
};

export default OutlinedCrossIcon;
