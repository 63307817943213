import React, { useEffect } from 'react';
import { TextBodyBold } from 'shared/react/components/basic/text/TextV2';
import { TextTiny } from 'shared/react/components/complex/Text';
import { VerticalFlexCentered } from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { INSTAGRAM_GRAPH, TIKTOK, GOOGLE_DRIVE } from 'src/constants/intergrations.constants';
import { IMPORT_FROM_INTEGRATION_LOCATIONS } from 'src/constants/trackingLocations.constants';
import { VIDEO_SOURCE_NAME_BY_KEY } from 'src/constants/videoSources.constants.js';
import { useAppActions } from 'src/context/AppsStore';
import { useOnboarding } from 'src/context/ui_store/OnboardingStore';
import useIntegrationsVideos from 'src/hooks/useIntegrationsVideos';
import styled from 'styled-components';
import OnboardingTemplatesIntegrationButton from './OnboardingTemplatesIntegrationButton';
import Utils from 'app/src/utils';
import { useGoogleDrive } from 'app/src/context/GoogleDriveStore';

type Props = {
  integrationKey: string;
  subText: string;
  Icon: React.ElementType;
};

const OnboardingTemplatesIntegrationBox = ({ integrationKey, subText, Icon }: Props) => {
  const [{ activeTemplateKey }] = useOnboarding();
  const { getHasApp } = useAppActions();

  const integrationLocationKey = `${IMPORT_FROM_INTEGRATION_LOCATIONS.onboarding}_${activeTemplateKey}`;
  const { googleDrive, handleInstagramConnect, handleTiktokConnect, handleGoogleDriveConnect } =
    useIntegrationsVideos(integrationLocationKey);
  const [{ rootFolderId: rootFolderIdFromStore }, { pickFolder }] = useGoogleDrive();

  const integrationKeyToHandler = {
    [INSTAGRAM_GRAPH]: handleInstagramConnect,
    [TIKTOK]: handleTiktokConnect,
    [GOOGLE_DRIVE]: handleGoogleDriveConnect,
  };

  const header = VIDEO_SOURCE_NAME_BY_KEY[integrationKey];

  const isAppInstalled = getHasApp(integrationKey);

  const getRootFolderId = () => {
    if (rootFolderIdFromStore !== undefined) {
      return rootFolderIdFromStore;
    }

    const data = Utils.safeParse(googleDrive?.data);
    return data?.rootFolderId;
  };

  const rootFolderId = getRootFolderId();

  useEffect(() => {
    if (googleDrive && rootFolderId === undefined) {
      pickFolder();
    }
  }, [rootFolderId, googleDrive]);

  return (
    <LayoutRoot>
      <Content>
        <Icon />
        <Header>{header}</Header>
        <Subtext>{subText}</Subtext>
      </Content>
      <OnboardingTemplatesIntegrationButton
        isAppInstalled={isAppInstalled}
        onClick={integrationKeyToHandler[integrationKey]}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlexCentered)`
  gap: 56px;
  border: 1px solid ${({ theme }) => theme.colors.neutralGray};
  border-radius: 4px;
  padding: 56px 32px 40px 32px;
  flex-grow: 1;
`;

const Content = styled(VerticalFlexCentered)`
  gap: 8px;
  width: 300px;
  height: 150px;
`;

const Header = styled(TextBodyBold)`
  color: ${({ theme }) => theme.colors.neutralDarker};
  text-align: center;
`;

const Subtext = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralDark};
  text-align: center;
`;

export default OnboardingTemplatesIntegrationBox;
