import React, { useMemo } from 'react';
import styled from 'styled-components';
import usePlayerPreviewSettings from 'app/src/hooks/preview/usePlayerPreviewSettings';
import FeedWrapper from 'player/src/components/feed/FeedWrapper';
import { useVodConnectionsList } from 'app/src/context/VodConnectionStore';
import { useVideos } from 'app/src/context/VideosStore';

const BrandingPreview = ({ settings }) => {
  const [vodConnections] = useVodConnectionsList();
  const [{ data }] = useVideos();

  const { videos, appUrl } = useMemo(() => {
    let arr = [];
    let appUrl = '';
    for (const connection of vodConnections) {
      if (arr.length) {
        break;
      }

      if (connection?.productId) {
        arr.push(connection.vodAssetId);
        appUrl = connection.appUrl;
      }
    }

    if (!arr.length) {
      return [data[0]?.id] || [];
    }

    return { videos: arr, appUrl };
  }, [vodConnections]);

  const project = useMemo(() => {
    return { dynamic: true, appUrl };
  }, [appUrl]);

  const { playerSettings, products, isProductsInitialized } = usePlayerPreviewSettings(
    project,
    project,
    videos
  );

  if (!playerSettings?.steps?.length) {
    return null;
  }

  const newSettings = { ...playerSettings, design: settings };

  return (
    <LayoutRoot>
      <FeedWrapperRoot
        isProductsInitialized={isProductsInitialized}
        products={products}
        config={newSettings}
        isPlayerPreview
        searchControls={{}}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  position: sticky;
  margin-top: 97px;
  display: grid;
  grid-template-rows: auto 1fr;
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : '')};

  @media (${({ theme }) => theme.breakpoints.dashboardTabletMax}) {
    display: none;
  }
`;

const FeedWrapperRoot = styled(FeedWrapper)`
  height: 600px;
  min-width: 337.5px;
  width: 337.5px;
  max-width: 276px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.boxShadows.elevation4};
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
`;

export default BrandingPreview;
