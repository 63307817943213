import React from 'react';
import styled from 'styled-components';
import Tooltip from 'app/src/basic_components/Tooltip';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Button from 'shared/react/components/basic/Button';
import Types from 'shared/react/theme/Types';
import { TextTiny } from 'shared/react/components/complex/Text';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { navigateToLibrary } from 'app/src/utils/navigation.utils';
import { track } from 'app/src/helpers/Tracker';
import Utils from 'app/src/utils';

const UploadMethodTooltip = ({ disabled, isSafari, tooltipPlacement, children }) => {
  const getTooltipText = () => {
    if (isSafari) {
      return 'Record screen works best on Google Chrome via our extension';
    }

    if (disabled) {
      return (
        <TooltipBody>
          <TooltipText>{`Hey! It looks like you've reached your videos limit`}</TooltipText>
          <ManageLibraryButton />
        </TooltipBody>
      );
    }

    return '';
  };

  return (
    <Tooltip text={getTooltipText()} interactive={!isSafari} placement={tooltipPlacement}>
      {children}
    </Tooltip>
  );
};

const ManageLibraryButton = () => {
  const history = useHistory();
  let { projectId = '', stepId = '' } = useParams();
  const location = useLocation();
  const reply = location.state?.reply;

  const onClick = () => {
    track(
      'Manage Library Button Clicked',
      Utils.getAnalyticsLocationObject('Choose Upload Method')
    );
    navigateToLibrary(history, projectId, stepId, reply);
  };

  return (
    <StyledButton type={Types.Secondary} height="24px" padding="5px 10px" onClick={onClick}>
      Manage library
    </StyledButton>
  );
};

export default UploadMethodTooltip;

const TooltipBody = styled(VerticalFlex)`
  gap: 8px;
`;

const StyledButton = styled(Button)`
  align-self: flex-end;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.primaryLight};
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 600;
`;

const TooltipText = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
`;
