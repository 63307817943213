import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FeedAnswerTextEditor from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/feed/FeedAnswerTextEditor';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import LinkIcon from 'shared/react/images/share/LinkIcon';
import { useFormContext } from 'react-hook-form';
import Utils from 'app/src/utils';
import Separator from 'shared/react/components/basic/Separator';
import {
  FEED_ACTION_BUTTON_TEXT_INPUT_TEST_ID,
  FEED_ACTION_BUTTON_URL_INPUT_TEST_ID,
} from 'app/src/constants/dataTestIds.constants';
import { isEqual } from 'lodash';
import HorizontalFlex, {
  Gap16HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { CheckboxV2 } from 'src/complex_components/Checkbox';
import { TextSmall } from 'shared/react/components/basic/text/TextV2';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';

const FeedActionButtonEditorFields = ({ buttonType, getNewFeedActionButton }) => {
  const { setValue, watch } = useFormContext();
  const [button] = watch([buttonType]);
  const [isUrlError, setIsUrlError] = useState(false);

  const setFeedActionButton = newFeedActionButton => {
    if (isEqual(newFeedActionButton, button)) {
      return;
    }

    setValue(buttonType, newFeedActionButton);
  };

  const handleActionButtonChanged = ({ value, property }) => {
    const newFeedActionButton = getNewFeedActionButton();
    newFeedActionButton[property] = value;
    setFeedActionButton(newFeedActionButton);
  };

  const handleCheckboxChange = () => {
    const newFeedActionButton = getNewFeedActionButton();
    newFeedActionButton.isOpenInANewTab = !button?.isOpenInANewTab;
    setFeedActionButton(newFeedActionButton);
  };

  useEffect(() => {
    const buttonUrl = button?.value;
    if (!buttonUrl) {
      return;
    }

    if (!Utils.isValidUrl(buttonUrl)) {
      setIsUrlError({ message: 'Please enter a valid URL' });
      return;
    }

    if (isUrlError) {
      setIsUrlError(false);
    }
  }, [button?.value]);

  if (!button?.enabled) {
    return null;
  }

  return (
    <LayoutRoot>
      <Content>
        <FeedAnswerTextEditor
          placeholder="Type button text here"
          onChange={handleActionButtonChanged}
          value={button?.text}
          maxLength={50}
          property="text"
          dataTestId={FEED_ACTION_BUTTON_TEXT_INPUT_TEST_ID}
        />
        <VerticalSeparator />
        <LinkIconContainer>
          <StyledLinkIcon />
        </LinkIconContainer>
        <FeedAnswerTextEditor
          onChange={handleActionButtonChanged}
          value={button?.value}
          maxLength={400}
          placeholder="Paste URL"
          dataTestId={FEED_ACTION_BUTTON_URL_INPUT_TEST_ID}
          error={isUrlError}
          property="value"
        />
      </Content>
      <CheckboxContainer>
        <CheckboxV2
          checked={button?.isOpenInANewTab}
          onChange={handleCheckboxChange}
          style={{ padding: 0 }}
        />
        <TextSmall>Open in a new tab</TextSmall>
      </CheckboxContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)``;

const Content = styled(Gap16HorizontalFlex)`
  align-items: center;
  width: 100%;
  height: 100%;
`;

const LinkIconContainer = styled(VerticalFlex)`
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.black};
  border-radius: 50%;
  padding: 5px;
`;

const StyledLinkIcon = styled(LinkIcon)`
  width: 14px;
  height: 14px;

  & path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const VerticalSeparator = styled(Separator)`
  width: 1px;
  align-self: stretch;
  height: unset;
  flex-shrink: 0;
`;

const CheckboxContainer = styled(HorizontalFlex)`
  gap: 4px;
  padding: 0 5px;
  align-items: center;
`;

export default FeedActionButtonEditorFields;
