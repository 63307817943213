import React from 'react';
import styled from 'styled-components';
import PlayIcon from 'app/src/images/PlayIcon';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

type Props = {
  size: number;
  widgetSettingsPreview?: any;
};

const EmbedCarouselPreviewPlayButton = ({ size, widgetSettingsPreview }: Props) => {
  const {
    carouselPlayButtonBackground,
    carouselPlayButtonBackgroundColor,
    carouselPlayButtonBorder,
    carouselPlayButtonBorderColor,
    carouselPlayButtonOpacity,
  } = widgetSettingsPreview ?? {};
  return (
    <LayoutRoot
      size={size}
      background={carouselPlayButtonBackground}
      backgroundColor={carouselPlayButtonBackgroundColor}
      border={carouselPlayButtonBorder}
      borderColor={carouselPlayButtonBorderColor}
      opacity={carouselPlayButtonOpacity}
    >
      <PlayIcon fill={carouselPlayButtonBorder ? carouselPlayButtonBorderColor : undefined} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)`
  width: ${({ size }) => 64 / size}px;
  height: ${({ size }) => 64 / size}px;
  grid-row: 1;
  grid-column: 1;
  border-radius: 100%;
  opacity: ${({ opacity }) => opacity};
  background: ${({ background, backgroundColor }) =>
    background ? backgroundColor : 'transparent'};
  border: ${({ border }) => (border ? 2 : 0)}px solid ${({ borderColor }) => borderColor};
  align-self: center;
  justify-self: center;
  justify-content: center;
  align-items: center;
  z-index: 3;

  & svg {
    width: ${({ size }) => 24 / size}px;
    height: ${({ size }) => 24 / size}px;
  }
`;

export default EmbedCarouselPreviewPlayButton;
