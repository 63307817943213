import React from 'react';
import styled from 'styled-components';
import { EMBED_CODE_BOX_DATA_TEST_ID } from 'app/src/constants/dataTestIds.constants';

const CodeContainerPricing = ({ getCode, hide }) => {
  if (hide) {
    return null;
  }

  return (
    <StyledCodeContainerPricing data-test-id={EMBED_CODE_BOX_DATA_TEST_ID}>
      {getCode()}
    </StyledCodeContainerPricing>
  );
};

export default CodeContainerPricing;

const StyledCodeContainerPricing = styled.div`
  padding: 12px;
  background: ${({ theme }) => theme.colors.gray5};
  border: 1px solid ${({ theme }) => theme.colors.gray3};
  border-radius: 8px;
  width: 420px;
  word-wrap: break-word;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    padding: 16px;
  }

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    width: auto;
  }
`;
