import React from 'react';

function NotificationTextResponseIcon() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="10" fill="#E2EDFE" />
      <path
        d="M26.5 14.25C26.5 13.5625 25.9375 13 25.25 13H14.75C14.0312 13 13.5 13.5625 13.5 14.25V15.75C13.5 16.4688 14.0312 17 14.75 17C15.4375 17 16 16.4688 16 15.75V15.5H18.75C18.75 15.5625 18.75 15.5938 18.75 15.625V25H17.5C16.9375 25 16.5 25.4688 16.5 26C16.5 26.5625 16.9375 27 17.5 27H22.5C23.0312 27 23.5 26.5625 23.5 26C23.5 25.4688 23.0312 25 22.5 25H21.25V15.625C21.25 15.5938 21.2188 15.5625 21.2188 15.5H24V15.75C24 16.4688 24.5312 17 25.25 17C25.9375 17 26.5 16.4688 26.5 15.75V14.25Z"
        fill="#3D4FE0"
      />
    </svg>
  );
}

export default NotificationTextResponseIcon;
