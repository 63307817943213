import React from 'react';
import styled, { useTheme } from 'styled-components';
import UserBox from 'app/src/basic_components/UserBox';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import { TextSmall } from 'shared/react/components/complex/Text';
import Utils from 'app/src/utils';
import { useAccount } from 'app/src/context/AccountStore';

const TeamMemberDetails = ({
  asset,
  imageSize = '32px',
  fontSize,
  fontWeight = 700,
  textColor,
}) => {
  const [{ teamMembers }] = useAccount();
  const theme = useTheme();

  const getOwnerTeamMember = () => {
    return teamMembers.find(({ userId }) => userId === asset.owner) || teamMembers[0];
  };

  const getTeamMemberDetails = () => {
    const ownerTeamMember = getOwnerTeamMember();
    if (!ownerTeamMember) {
      return {};
    }

    const {
      user: { logoSettings: logoSettingsString, firstName, lastName, email },
    } = ownerTeamMember;
    const logoSettings = JSON.parse(logoSettingsString || '{}');
    const userLogo = Utils.getLogoUrl(logoSettings.logo);

    return {
      userLogo,
      userName: Utils.getUserName(firstName, lastName, email),
      color: logoSettings.color,
    };
  };

  if (!asset) {
    return null;
  }

  const { userLogo, userName, color } = getTeamMemberDetails();
  return (
    <LayoutRoot>
      <UserBox
        name={userName}
        userImageSrc={userLogo}
        backgroundColor={userLogo ? 'transparent' : color}
        color={theme.colors.white}
        scale={imageSize}
        fontSize={fontSize}
      />
      <TextSmall textColor={textColor} fontSize={fontSize} fontWeight={fontWeight}>
        {userName}
      </TextSmall>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlexWrap)`
  align-items: center;
`;

export default TeamMemberDetails;
