import React, { useState } from 'react';
import styled from 'styled-components';
import RedirectButtons from 'app/src/pages/sidebar/redirect-buttons/RedirectButtons';
import useSideBarButtons from 'app/src/pages/sidebar/useSideBarButtons';
import { PagePanel } from 'app/src/pages/dashboard/components/page/Page';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import AccountButton from 'app/src/pages/sidebar/account-button/AccountButton';
import { useAccount } from 'app/src/context/AccountStore';
import { useUser } from 'app/src/context/userStore/UserStore';
import Utils from 'app/src/utils';
import AccountMenu from 'app/src/pages/sidebar/account-menu/AccountMenu';
import { SIDEBAR_ACCOUNT_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import InAppNotificationsPanel from 'app/src/complex_components/notifications/InAppNotificationsPanel';
import BackToEditorButton from './BackToEditorButton';
import { useHistory } from 'react-router-dom';
import { track } from 'src/helpers/Tracker';
import useNavigation from 'src/hooks/useNavigation';
import ShopifyAppsSelect from 'src/complex_components/shopify-apps-select/ShopifyAppsSelect';
import useShopifyAppSelect from 'src/complex_components/shopify-apps-select/useShopifyAppSelect';

import { PricingUsage } from './PricingUsage';

const Sidebar = () => {
  const { pageButtons } = useSideBarButtons();
  const { navigateToPreviousBuilderPage } = useNavigation();
  const [, { getCurrentWorkspace }] = useAccount();
  const [{ accountLogoSettings, user }] = useUser();
  const [anchorElement, setAnchorElement] = useState(null);

  const { isAppSelectDisabled, selectedAppUrl, handleSelectedAppChange } = useShopifyAppSelect();

  const history = useHistory();
  const backToEditorLocation = history?.location?.state?.backToEditorLocation;

  const accountLogo = Utils.getLogoUrl(accountLogoSettings?.logo);
  const accountBackground = accountLogoSettings?.color;
  const name = getCurrentWorkspace()?.name;

  const onAccountClick = e => {
    setAnchorElement(e.currentTarget);
  };

  const handleNavigateToEditor = () => {
    track('Back To Editor Click');
    navigateToPreviousBuilderPage(backToEditorLocation);
  };

  let fullName = null;
  if (user?.firstName && user?.lastName) {
    fullName = `${user?.firstName || ''} ${user?.lastName}`;
  }

  return (
    <>
      <PagePanelSpaceBetween>
        <Gap8VerticalFlex>
          <ShopifyAppsSelect
            appUrl={selectedAppUrl}
            setAppUrl={handleSelectedAppChange}
            isDisabled={isAppSelectDisabled}
          />
          <RedirectButtons showTextOnMobile={false} buttons={pageButtons} />
        </Gap8VerticalFlex>

        <BottomContainer>
          <PricingUsage />
          <AccountButton
            accountBackground={accountBackground}
            accountLogo={accountLogo}
            accountName={name}
            header={name}
            subHeader={fullName || user?.email}
            onClick={onAccountClick}
            dataTestId={SIDEBAR_ACCOUNT_TEST_ID}
          />
        </BottomContainer>
        <AccountMenu setAnchorElement={setAnchorElement} anchorElement={anchorElement} />
        {backToEditorLocation && <BackToEditorButton onClick={handleNavigateToEditor} />}
      </PagePanelSpaceBetween>
      <InAppNotificationsPanel />
    </>
  );
};

const PagePanelSpaceBetween = styled(PagePanel)`
  justify-content: space-between;
  position: relative;
`;

const BottomContainer = styled(Gap8VerticalFlex)``;

export default Sidebar;
