import React from 'react';

const TopRightArrowIcon = () => {
  return (
    <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.75 1.5A.771.771 0 0 0 7 .75H1.75A.755.755 0 0 0 1 1.5c0 .422.328.75.75.75h3.422L.46 6.984a.723.723 0 0 0 0 1.055.723.723 0 0 0 1.055 0L6.25 3.33v3.42c0 .422.328.75.75.75.398 0 .75-.328.75-.75V1.5Z"
        fill="#fff"
      />
    </svg>
  );
};

export default TopRightArrowIcon;
