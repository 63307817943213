import React from 'react';
import Utils from '../../utils';
import styled, { css } from 'styled-components';
import StartScreenPlayButton from './StartScreenPlayButton';
import { DEFAULT_PLAY_BUTTON_SIZE } from 'shared/react/constants/feature-constants/playButtonCustomization.constants';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';

const StartScreen = ({
  landingPage,
  shouldShowThread,
  userPause,
  showEndScreen,
  handlePlayPause,
  enterTransition,
  showStartScreen,
  transitioningFromVideoId,
  hidePlay,
  customStart,
  hideStartScreenDefaultText,
  autoplay,
  isEmbed,
}) => {
  const [{ direction }, { t }] = useTranslation();

  if ((isEmbed || landingPage) && autoplay && !userPause) {
    return null;
  }

  const getPlayButtonText = () => {
    const isDefaultStartText =
      (customStart?.text || customStart) === 'Begin an interactive conversation';

    if (isDefaultStartText) {
      return t('interactive.startScreenText');
    }

    if (!Utils.isNullOrUndefined(customStart)) {
      return customStart.text || customStart;
    }

    if (!hideStartScreenDefaultText) {
      return t('interactive.startScreenText');
    }
  };

  const shouldShowStartScreenText = customStart?.text !== '' && !hidePlay;
  return (
    <LayoutRoot
      onClick={handlePlayPause}
      landingPage={landingPage}
      shouldShowThread={shouldShowThread}
      userPaused={userPause && !showEndScreen}
      enterTransition={enterTransition}
      showStartScreen={showStartScreen}
      aria-label="Start/Stop Video"
      aria-hidden={showEndScreen}
      role="button"
      aria-modal="true"
    >
      <PauseScreen enterTransition={enterTransition}>
        <StartScreenPlayButton
          showStartScreen={showStartScreen}
          hidePlay={hidePlay}
          transitioningFromVideoId={transitioningFromVideoId}
        />
        {shouldShowStartScreenText && (
          <StartText
            enterTransition={showStartScreen}
            playButtonSize={DEFAULT_PLAY_BUTTON_SIZE}
            direction={direction}
          >
            {getPlayButtonText()}
          </StartText>
        )}
      </PauseScreen>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  display: flex;
  position: ${({ landingPage }) => (landingPage ? 'absolute' : 'fixed')};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  border-radius: 10px;
  background: ${({ enterTransition }) =>
    enterTransition ? 'linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.6))' : ''};
  transition: all 250ms;
  z-index: ${({ userPaused, showStartScreen }) => {
    if (userPaused) {
      return 10000;
    }

    if (showStartScreen) {
      return 42;
    }

    return 1;
  }};

  @media only screen and (max-width: 450px), screen and (hover: none) and (pointer: coarse) {
    border-radius: 0;
    position: ${({ shouldShowThread }) => (shouldShowThread ? 'absolute' : '')};
  }
`;

const TransitionCss = css`
  opacity: ${({ enterTransition }) => (enterTransition ? 1 : 0)};
  transform: scale(${({ enterTransition }) => (enterTransition ? 1 : 0.9)});
  transition: all 250ms;
`;

const PauseScreen = styled.div`
  z-index: 20;
  display: flex;
  justify-content: center;
  justify-items: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  ${TransitionCss};
  width: 100%;
`;

const StartText = styled.div`
  position: absolute;
  direction: ${({ direction }) => direction};
  top: ${({ playButtonSize }) => playButtonSize}px;
  margin-top: 20px;
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
  opacity: 0.8;
  font-size: 18px;
  line-height: 21px;
  transition: all 0.2s ease-in-out;
  text-align: center;
  ${TransitionCss};
`;

export default StartScreen;
