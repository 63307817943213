import React from 'react';
import { Input } from '@tolstoy/ui-library/core';
import { EditorItem } from 'src/pages/project/pages/look_and_feel/editor/editors/shared/ui-library';

interface SpotlightDimensionEditorProps {
  x?: number;
  y?: number;
  labelX?: string;
  labelY?: string;
  hideX?: boolean;
  hideY?: boolean;
  onUpdate: (x: number, y: number) => void;
}

export const SpotlightDimensionEditor = ({
  x = 0,
  y = 0,
  labelX,
  labelY,
  hideX = false,
  hideY = false,
  onUpdate,
}: SpotlightDimensionEditorProps) => {
  const onHorizontalChange = (e) => {
    const newX = parseInt(e.target.value) || 0;

    onUpdate(newX, y);
  };

  const onVerticalChange = (e) => {
    const newY = parseInt(e.target.value) || 0;

    onUpdate(x, newY);
  };

  return (
    <>
      {!hideX && (
        <EditorItem label={labelX || ''} labelClassName="text-gray-700 font-medium">
          <Input
            className="flex-1 max-w-24"
            value={x}
            onChange={onHorizontalChange}
          />
        </EditorItem>
      )}
      {!hideY && (
        <EditorItem label={labelY || ''} labelClassName="text-gray-700 font-medium">
          <Input
            className="flex-1 max-w-24"
            value={y}
            onChange={onVerticalChange}
          />
        </EditorItem>
      )}
    </>
  );
};
