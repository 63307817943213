import React from 'react';

const CloseIcon = ({ fill = '#FFFFFF' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path
        d="M10.75 9.71875C11.0312 10.0312 11.0312 10.5 10.75 10.7812C10.4375 11.0938 9.96875 11.0938 9.6875 10.7812L6 7.0625L2.28125 10.7812C1.96875 11.0938 1.5 11.0938 1.21875 10.7812C0.90625 10.5 0.90625 10.0312 1.21875 9.71875L4.9375 6L1.21875 2.28125C0.90625 1.96875 0.90625 1.5 1.21875 1.21875C1.5 0.90625 1.96875 0.90625 2.25 1.21875L6 4.96875L9.71875 1.25C10 0.9375 10.4688 0.9375 10.75 1.25C11.0625 1.53125 11.0625 2 10.75 2.3125L7.03125 6L10.75 9.71875Z"
        fill={fill}
      />
    </svg>
  );
};

export default CloseIcon;
