import React from 'react';
import { TextSmall } from 'shared/react/components/basic/text/TextV2';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { app as AppEntity } from 'src/types/entities';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { CheckboxV2 } from 'src/complex_components/Checkbox';
import styled from 'styled-components';

type Props = {
  apps: AppEntity[];
  primaryId: string;
  onChange: (id: string) => void;
  selectedIds: string[];
  isDisabled?: boolean;
};

const SyncTagsStoresList = ({ apps, primaryId, onChange, selectedIds, isDisabled }: Props) => {
  return (
    <StoresList isDisabled={isDisabled}>
      <StoreItem>
        <StoreText>Stores</StoreText>
      </StoreItem>
      {apps
        ?.filter(app => app.id !== primaryId)
        .map(app => (
          <StoreItem as="label" key={app.id}>
            <Checkbox
              onChange={() => onChange(app.id)}
              checked={selectedIds.includes(app.id)}
              disabled={isDisabled}
            />
            <StoreText>{app.appUrl}</StoreText>
          </StoreItem>
        ))}
    </StoresList>
  );
};

const StoresList = styled(Gap8VerticalFlex)<{ isDisabled: boolean }>`
  opacity: ${({ isDisabled }) => (isDisabled ? 0.4 : 1)};
`;

const StoreItem = styled(Gap8HorizontalFlex)`
  align-items: center;
`;

const StoreText = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.neutralDarkest};
`;

const Checkbox = styled(CheckboxV2)`
  padding: 0;
  margin: 0;
`;

export default SyncTagsStoresList;
