import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { useVideos } from 'app/src/context/VideosStore';
import { TextBody } from 'shared/react/components/complex/Text';
import {
  STOCK_GALLERY_IMAGE_CONTAINER_DATA_TEST_ID,
  STOCK_GALLERY_LAYOUT_ROOT_DATA_TEST_ID,
} from 'app/src/constants/dataTestIds.constants';
import Header from 'app/src/pages/creation_flow/right_section/stock_videos/Header';
import { track } from 'app/src/helpers/Tracker';
import ComponentWithLoader from 'app/src/complex_components/ComponentWithLoader';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import { PEXELS_API_KEY } from 'app/src/config/app.config';

const getVideoImage = ({ image, video_pictures }) => {
  return image || video_pictures?.[0]?.picture;
};

const PEXELS_BASE_URL = 'https://api.pexels.com/videos/';
const PEXELS_PAGE_SIZE = 100;
const PEXELS_AUTHORIZATION = PEXELS_API_KEY;

const fetchPexelsVideos = async query => {
  let url = PEXELS_BASE_URL;
  if (!query) {
    url += `popular?per_page=${PEXELS_PAGE_SIZE}`;
  } else {
    url += `search?query=${query}&per_page=${PEXELS_PAGE_SIZE}`;
  }
  const res = await fetch(url, { headers: { authorization: PEXELS_AUTHORIZATION } });
  return res.json();
};

const StockVideos = ({ onVideoChoose, isLoading }) => {
  const searchTimeout = useRef(null);
  const [, { createStockVideo }] = useVideos();
  const [isLoadingVideos, setIsLoadingVideos] = useState(true);
  const [isLoadedOnce, setLoadedOnce] = useState(false);
  const [stockVideos, setStockVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const onPexelsResponse = response => {
    if (!response || response.total_results === 0 || !response.videos) {
      setStockVideos([]);
      return;
    }

    searchTimeout.current = null;
    setStockVideos(response.videos);
  };

  const fetchVideos = async query => {
    try {
      const videos = await fetchPexelsVideos(query);
      setLoadedOnce(true);
      onPexelsResponse(videos);
    } catch (error) {
      Sentry.captureException(error);
    }
    setIsLoadingVideos(false);
  };

  useEffect(() => {
    fetchVideos('sun');
  }, []);

  const selectVideo = async video => {
    if (isLoading) {
      return;
    }
    setSelectedVideo(video.id);

    track('Stock Video Click');

    let stockVideo = {
      videoUrl: video.video_files[0].link,
      posterUrl: getVideoImage(video),
      creator: video.user?.name,
      creatorPageUrl: video.user?.url,
    };

    const newVideo = await createStockVideo(stockVideo);
    if (newVideo?.stockAsset) {
      newVideo.stockAsset.creator = stockVideo.creator;
      newVideo.stockAsset.creatorPageUrl = stockVideo.creatorPageUrl;
    }
    onVideoChoose(newVideo);
  };

  function handleSearchVideos(message) {
    track('Stock Videos Searched', {
      value: message,
    });
    setIsLoadingVideos(true);
    setStockVideos(
      stockVideos.map(x => {
        x.image = null;
        return x;
      })
    );

    fetchVideos(message);
  }

  const getContent = () => {
    if (isLoadingVideos && !isLoadedOnce) {
      return (
        <CircularProgressContainer>
          <CircularProgress size={32} />
        </CircularProgressContainer>
      );
    }

    return <ContentContainer>{getVideos()}</ContentContainer>;
  };

  const getVideos = () => {
    if (!stockVideos.length) {
      return <EmptyStateContainer>No available stock videos.</EmptyStateContainer>;
    }

    return (
      <VideosContainer data-test-id={STOCK_GALLERY_LAYOUT_ROOT_DATA_TEST_ID}>
        {stockVideos.map(video => {
          const videoImage = getVideoImage(video);
          return (
            <ImageContainer
              data-test-id={STOCK_GALLERY_IMAGE_CONTAINER_DATA_TEST_ID}
              key={video.id}
            >
              <ComponentWithLoader isLoading={!videoImage}>
                <Image key={video.id} src={videoImage} onClick={() => selectVideo(video)} />
              </ComponentWithLoader>
              {video.id === selectedVideo && (
                <LoadingContainer>
                  <CircularProgress size={24} />
                </LoadingContainer>
              )}
            </ImageContainer>
          );
        })}
      </VideosContainer>
    );
  };

  return (
    <LayoutRoot>
      <Header
        isLoadingVideos={isLoadingVideos}
        searchTimeout={searchTimeout}
        onSearchVideos={handleSearchVideos}
        isLoadedOnce={isLoadedOnce}
      />

      {getContent()}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  gap: 24px;
  justify-items: center;
  overflow-y: auto;
  align-content: center;
  align-items: center;
  justify-self: center;
  height: 100%;
  width: 100%;
  padding-top: 40px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 100%;
    padding: 40px 16px;
  }
`;

const CircularProgressContainer = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  height: 200px;
`;

const LoadingContainer = styled(HorizontalFlexWrap)`
  grid-column: 1;
  grid-row: 1;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.getHexOpacity(theme.colors.black, 15)};
`;

const EmptyStateContainer = styled(TextBody)`
  justify-self: center;
  align-self: start;
  font-size: 20px;
  margin-top: 40px;
`;

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  grid-column: 1;
  grid-row: 1;

  &:hover {
    transform: scale(
      1.2
    ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    transition: 0.3s;
  }
`;

const ImageContainer = styled.div`
  display: grid;
  width: 100%;
  justify-items: center;
  align-items: center;
  height: 200px;
  cursor: pointer;
  background: lightgrey;
  border-radius: 10px;
  border: 2px solid white;
  overflow: hidden;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    height: 140px;
  }

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.blue5};
  }
`;

const VideosContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(150px, 300px));
  justify-items: center;
  justify-self: center;
  align-items: start;
  justify-content: center;
  align-self: start;
  gap: 10px;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  overflow-y: auto;
  width: 100%;
  margin-bottom: 10px;

  @media (max-width: 610px) {
    grid-template-columns: auto;
  }
`;

const ContentContainer = styled.div`
  overflow: auto;
  width: 100%;
`;

export default StockVideos;
