import React from 'react';
import ProjectCardMenuItems from 'app/src/pages/dashboard/pages/libraries/tolstoys_tab/project_card_v2/ProjectCardMenuItems';
import styled from 'styled-components';
import MenuWithContainer from 'shared/react/components/basic/MenuWithContainer';

const ProjectCardMenu = ({ project, isMenuOpen, setMenuOpen, isFeed, inProject }) => {
  const open = !!isMenuOpen;

  const handleCloseMenu = () => {
    setMenuOpen(null);
  };

  return (
    <Menu
      direction={{ horizontal: 'right', vertical: 'bottom' }}
      anchorEl={isMenuOpen}
      open={open}
      onClose={handleCloseMenu}
    >
      <ProjectCardMenuItems
        isFeed={isFeed}
        project={project}
        setMenuOpen={setMenuOpen}
        inProject={inProject}
      />
    </Menu>
  );
};

const Menu = styled(MenuWithContainer)`
  padding: 4px 0;
`;

export default ProjectCardMenu;
