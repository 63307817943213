import React from 'react';
import styled from 'styled-components';

import { TextSmall, TextTiny } from 'shared/react/components/basic/text/TextV2';
import CodeSection from '../CodeSection';

const getConversionCode = appKey => {
  return `<script type="text/javascript">
        (function(){
        window.tolstoyConversion = {
            appKey: "${appKey}",
            appUrl: "DOMAIN (MyShopify domain or site domain)",
            currency: "CURRENCY",
            email: "USER_EMAIL", 
            items: [
              {
                discounts: [{ amount: "DISCOUNT_AMOUNT (optional)" }],
                price: "ITEM_PRICE",
                productId: "ITEM_PRODUCT_ID",
                quantity: "ITEM_QUANTITY",
                title: "ITEM_TITLE",
                totalPrice: "ITEM_TOTAL_PRICE (price * quantity - discounts)",   
                variantId: "ITEM_VARIANT_ID",
              }
            ],
            orderId: "ORDER_ID", 
            presentmentCurrency: "PRESENTMENT_CURRENCY (optional)",
            totalPrice: "TOTAL_PRICE", 
        };
        var s=document.createElement("script"); s.type="text/javascript"; s.async=true;s.src="https://widget.gotolstoy.com/track.js"; var x=document.getElementsByTagName("script")[0]; x.parentNode.insertBefore(s,x)})(); 
        </script>`;
};

const ConversionCode = ({ appKey }) => {
  return (
    <TextTinyDark>
      {`<script type="text/javascript">`}
      <br />
      {`(function() {`}
      <IndentContainer>
        {`window.tolstoyConversion = {`}
        <IndentContainer>
          {`appKey: "${appKey}", `}
          <br />
          {`appUrl: "`}
          <TextTinyRed>DOMAIN (MyShopify domain or site domain)</TextTinyRed>
          {`",`}
          <br />
          {`currency: "`}
          <TextTinyRed>CURRENCY</TextTinyRed>
          {`",`}
          <br />
          {`email: "`}
          <TextTinyRed>USER_EMAIL</TextTinyRed>
          {`",`}
          <br />
          {`items: [`}
          <br />
          <IndentContainer>
            {`{`}
            <br />
            <IndentContainer>
              {`discounts: [{ amount: "`}
              <TextTinyRed>DISCOUNT_AMOUNT (optional)</TextTinyRed>
              {`" }],`}
              <br />
              {`price: "`}
              <TextTinyRed>ITEM_PRICE</TextTinyRed>
              {`",`}
              <br />
              {`productId: "`}
              <TextTinyRed>ITEM_PRODUCT_ID</TextTinyRed>
              {`",`}
              <br />
              {`quantity: "`}
              <TextTinyRed>ITEM_QUANTITY</TextTinyRed>
              {`",`}
              <br />
              {`title: "`}
              <TextTinyRed>ITEM_TITLE</TextTinyRed>
              {`",`}
              <br />
              {`totalPrice: "`}
              <TextTinyRed>ITEM_TOTAL_PRICE (price * quantity - discounts)</TextTinyRed>
              {`",`}
              <br />
              {`variantId: "`}
              <TextTinyRed>ITEM_VARIANT_ID</TextTinyRed>
              {`"`}
              <br />
            </IndentContainer>
            {`}`}
            <br />
          </IndentContainer>
          {`],`}
          <br />
          {`orderId: "`}
          <TextTinyRed>ORDER_ID</TextTinyRed>
          {`", `}
          <br />
          {`presentmentCurrency: "`}
          <TextTinyRed>PRESENTMENT_CURRENCY (optional)</TextTinyRed>
          {`",`}
          <br />
          {`totalPrice: "`}
          <TextTinyRed>TOTAL_PRICE</TextTinyRed>
          {`"`}
          <br />
        </IndentContainer>
        {`};`}
        <br />
        {`var s=document.createElement("script");`}
        <br />
        {`s.type="text/javascript";`} <br />
        {`s.async=true;s.src="https://widget.gotolstoy.com/track.js";`}
        <br />
        {`var x=document.getElementsByTagName("script")[0];`} <br />
        {`x.parentNode.insertBefore(s,x)})();`}
      </IndentContainer>
      {`</script>;`}
    </TextTinyDark>
  );
};

const CodeSectionDescription = () => (
  <TextSmallDark>
    Add the code below to your purchase thank you page, make sure to change{' '}
    <TextSmallRed>these fields</TextSmallRed>
  </TextSmallDark>
);

type Props = {
  appKey: string;
  isCodeAddedAutomatically?: boolean;
};

const ConversionCodeSection: React.FC<Props> = ({ appKey, isCodeAddedAutomatically }) => {
  const conversionCode = getConversionCode(appKey);
  const automaticSectionDescription =
    'Tolstoy tracks your video performance to show you analytics.';

  return (
    <CodeSection
      code={conversionCode}
      displayCode={<ConversionCode appKey={appKey} />}
      codeSectionDescription={<CodeSectionDescription />}
      automaticSectionDescription={automaticSectionDescription}
      isCodeAddedAutomatically={isCodeAddedAutomatically}
    />
  );
};

const TextSmallDark = styled(TextSmall)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.neutralDark};
`;

const TextSmallRed = styled(TextSmall)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.red1};
`;

const TextTinyRed = styled(TextTiny)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.red1};
`;

const TextTinyDark = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralDark};
`;

const IndentContainer = styled.div`
  margin-left: 20px;
`;

export default ConversionCodeSection;
