import React from 'react';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextSmall } from 'shared/react/components/complex/Text';
import MenuItem from 'app/src/complex_components/MenuItem';
import BuildingIcon from 'app/src/images/dashboard_v2/BuildingIcon';
import GreenVIconV2 from 'app/src/images/dashboard_v2/GreenVIconV2';
import { useProjects } from 'app/src/context/ProjectsStore';
import AccountProfileIcon from 'app/src/images/dashboard_v2/AccountProfileIcon';
import { track } from 'app/src/helpers/Tracker';

const ShareToLibraryMenuItems = ({ project, onClose, setLoading }) => {
  const [, { updateProject }] = useProjects();

  const handleMoveProject = async isPrivateProject => {
    if (isPrivateProject === project.private) {
      return;
    }

    track('Project Card Share To Library Menu Item Click', { isPrivateProject });
    setLoading(true);
    await updateProject({ ...project, private: isPrivateProject });
    setLoading(false);
  };

  const { private: isPrivate } = project;
  const MENU_ITEMS = [
    {
      title: 'Personal',
      componentIcon: isPrivate ? <GreenVIconV2 /> : <AccountProfileIcon />,
      onClick: () => handleMoveProject(true),
    },
    {
      title: 'Share to team',
      componentIcon: !isPrivate ? <GreenVIconV2 /> : <BuildingIcon />,
      onClick: () => handleMoveProject(false),
    },
  ];

  return (
    <LayoutRoot>
      <StyledTextSmall>Share to</StyledTextSmall>
      <Separator />
      <MenuItemsContainer>
        {MENU_ITEMS.map(menuItem => {
          return (
            <MenuItem
              key={menuItem.title}
              menuItem={menuItem}
              onClose={onClose}
              customPadding="16px 4px"
            />
          );
        })}
      </MenuItemsContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.ghostLight};
`;

const StyledTextSmall = styled(TextSmall)`
  padding: 20px 16px 12px 16px;
`;

const MenuItemsContainer = styled(VerticalFlex)`
  padding: 0 0 8px;
`;

export default ShareToLibraryMenuItems;
