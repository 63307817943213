import React from 'react';
import FeedStoriesGeneralEditor from 'app/src/pages/project/pages/look_and_feel/editor/editors/feed_stories_editor/FeedStoriesGeneralEditor';
import EditorGroup from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorGroup';
import FeedStoriesBorderEditor from 'app/src/pages/project/pages/look_and_feel/editor/editors/feed_stories_editor/FeedStoriesBorderEditor';
import FeedStoriesTitleEditor from 'app/src/pages/project/pages/look_and_feel/editor/editors/feed_stories_editor/FeedStoriesTitleEditor';
import FeedStoriesStoryNameEditor from 'app/src/pages/project/pages/look_and_feel/editor/editors/feed_stories_editor/FeedStoriesStoryNameEditor';
import FeedStoriesLayoutEditor from './FeedStoriesLayoutEditor';

const FeedStoriesEditorGroup = () => {
  return (
    <EditorGroup
      editors={[
        {
          key: 'general',
          component: <FeedStoriesGeneralEditor />,
        },
        {
          key: 'layout',
          component: <FeedStoriesLayoutEditor />,
        },
        {
          key: 'border',
          component: <FeedStoriesBorderEditor />,
        },
        {
          key: 'title',
          component: <FeedStoriesTitleEditor />,
        },
        {
          key: 'storyName',
          component: <FeedStoriesStoryNameEditor />,
        },
      ]}
    />
  );
};

export default FeedStoriesEditorGroup;
