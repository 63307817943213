import React from 'react';

function AnswersTimeIndicationIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <rect width="20" height="20" x="20" fill="#FA536E" rx="10" transform="rotate(90 20 0)"></rect>
      <path
        fill="#fff"
        d="M6.168 7.64h.013c1.757 0 3.182 1.44 3.182 3.215 0 1.776-1.424 3.215-3.182 3.215-1.69 0-3.074-1.333-3.175-3.016H3v-.847c0-.273.034-.538.097-.791A4.555 4.555 0 016.168 6v1.64zM13.666 12.43h-.013c-1.757 0-3.182-1.44-3.182-3.215C10.471 7.439 11.896 6 13.653 6c1.69 0 3.074 1.333 3.175 3.016h.006v.847c0 .273-.034.538-.097.79a4.554 4.554 0 01-3.071 3.417v-1.64z"
      ></path>
    </svg>
  );
}

export default AnswersTimeIndicationIcon;
