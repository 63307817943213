import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useVideos } from 'app/src/context/VideosStore';

const RIGHT_LEFT_PADDING = 50;

const FolderCardImages = ({ projects, currentRef }) => {
  const [numberOfCards, setNumberOfCards] = useState(0);
  const [, { getVideoPoster, getVideoById }] = useVideos();

  const getNumberOfCards = () => {
    const containerWidth = currentRef?.clientWidth - RIGHT_LEFT_PADDING;

    const numberOfCardsWithoutGap = Math.floor(containerWidth / 54);
    const gapWidth = (numberOfCardsWithoutGap - 1) * 8;
    const containerWidthWithGap = containerWidth - gapWidth;
    return Math.floor(containerWidthWithGap / 54) * 3;
  };

  const updateNumberOfCards = () => {
    setNumberOfCards(getNumberOfCards());
  };

  useEffect(() => {
    if (!currentRef) {
      return;
    }

    updateNumberOfCards();
  }, [currentRef?.clientWidth]);

  const stepsImages = projects.map((project, i) => {
    if (i >= numberOfCards) {
      return null;
    }

    const video = getVideoById(project.steps.items[0]?.videoId);
    if (!video) {
      return null;
    }

    return getVideoPoster(video);
  });
  return (
    <ProjectsImages>
      {stepsImages?.map((src, i) => {
        if (!src || i >= numberOfCards) {
          return null;
        }

        return <Image key={i} src={src} />;
      })}
    </ProjectsImages>
  );
};

const ProjectsImages = styled.div`
  grid-row: 1;
  grid-column: 1;
  z-index: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, 54px);
  grid-template-rows: repeat(auto-fill, 54px);
  gap: 8px;
  align-content: center;
  justify-content: center;
  padding: 64px 24px 8px;
  align-self: flex-start;
`;

const Image = styled.img`
  width: 54px;
  height: 54px;
  border-radius: 10px;
  object-fit: cover;
`;

export default FolderCardImages;
