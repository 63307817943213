// TODO: migrate to enum
export const TOLSTOY_ID = 'interactive';
export const FEED_ID = 'feed';
export const QUIZ_ID = 'quiz';

// TODO: include descriptions
export const TOLSTOY_TYPES_TEXT_MAP = {
  [TOLSTOY_ID]: 'Branching',
  [FEED_ID]: 'Swipeable',
};
