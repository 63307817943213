import React from 'react';
import styled from 'styled-components';
import OnOffToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/OnOffToggleItem';
import EditorHeader from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorHeader';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import {
  STORIES_MOBILE_TITLE_FONT_SIZE_PROPERTY_KEY,
  STORIES_TITLE_COLOR_PROPERTY_KEY,
  STORIES_TITLE_ENABLED_PROPERTY_KEY,
  STORIES_TITLE_FONT_SIZE_PROPERTY_KEY,
  STORIES_TITLE_FONT_WEIGHT_PROPERTY_KEY,
  STORIES_TITLE_TEXT_PROPERTY_KEY,
} from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import EditorColorInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorColorInputItem';
import EditorTextInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorTextInputItem';
import EditorSelect from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorSelect';
import Utils from 'shared/react/utils/utils';
import EditorSliderItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorSliderItem';
import EditorMainContainer from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorMainContainer';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const fontSizes = Utils.range(12, 40);

const FeedStoriesTitleEditor = () => {
  const {
    customizationSettings: {
      widgetSettings: {
        storiesTitleEnabled,
        storiesTitleColor,
        storiesTitleText,
        storiesTitleFontSize,
        storiesTitleFontWeight,
        storiesMobileTitleFontSize,
      },
    },
    setWidgetSettingsProperty,
  } = useLookAndFeelContext();

  const onEnableTitle = () => {
    setWidgetSettingsProperty(STORIES_TITLE_ENABLED_PROPERTY_KEY, !storiesTitleEnabled);
  };

  const onTextChange = value => {
    setWidgetSettingsProperty(STORIES_TITLE_TEXT_PROPERTY_KEY, value);
  };

  const onColorChange = value => {
    setWidgetSettingsProperty(STORIES_TITLE_COLOR_PROPERTY_KEY, value);
  };

  const onFontSizeChange = ({ id }) => {
    setWidgetSettingsProperty(STORIES_TITLE_FONT_SIZE_PROPERTY_KEY, id);
  };

  const onMobileFontSizeChange = ({ id }) => {
    setWidgetSettingsProperty(STORIES_MOBILE_TITLE_FONT_SIZE_PROPERTY_KEY, id);
  };

  const onFontWeightChange = (event, value) => {
    setWidgetSettingsProperty(STORIES_TITLE_FONT_WEIGHT_PROPERTY_KEY, value);
  };

  return (
    <LayoutRoot>
      <EditorHeader title="Title" isWhite={!storiesTitleEnabled}>
        <OnOffToggleItem checked={storiesTitleEnabled} onChange={onEnableTitle} />
      </EditorHeader>
      <EditorMainContainer shouldHide={!storiesTitleEnabled}>
        <EditorTextInputItem
          placeholder="Title goes here"
          text="Text"
          value={storiesTitleText}
          onChange={onTextChange}
        />
        <SmallerEditorSelect
          onChange={onFontSizeChange}
          text="Text size"
          value={storiesTitleFontSize}
          items={fontSizes}
        />
        <SmallerEditorSelect
          onChange={onMobileFontSizeChange}
          text="Mobile text size"
          value={storiesMobileTitleFontSize}
          items={fontSizes}
        />
        <EditorSliderItem
          onChange={onFontWeightChange}
          text="Text weight"
          value={storiesTitleFontWeight}
          min={100}
          step={100}
          max={900}
        />
        <EditorColorInputItem
          text="Text color"
          color={storiesTitleColor}
          onChange={onColorChange}
        />
      </EditorMainContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

const SmallerEditorSelect = styled(EditorSelect)`
  max-width: 80px;
`;

export default FeedStoriesTitleEditor;
