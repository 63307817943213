import AnalyticsEngagementEmptyState from 'app/src/complex_components/analytics/empty/AnalyticsEngagementEmptyState';
import React from 'react';
import AnalyticsOnsitePagesTable from 'app/src/complex_components/analytics/onsite/AnalyticsOnsitePagesTable';

type Props = {
  shouldBlur: boolean;
};

const AnalyticsOnsite = ({ shouldBlur }: Props) => {
  if (shouldBlur) {
    return <AnalyticsEngagementEmptyState />;
  }

  return <AnalyticsOnsitePagesTable />;
};

export default AnalyticsOnsite;
