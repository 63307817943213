import React from 'react';
import styled from 'styled-components';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import EditorToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorToggleItem';
import EditorSubheader from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorSubheader';
import { CHECKOUT_SHOPPING_BAG_SETTING_TOOLTIP_TEXT } from 'app/src/constants/ui.constants';
import { useBrandingContext } from '../../../BrandingContext';

type Props = {
  settings?: any;
  onChange?: any;
};

const ShoppingCardEditor = ({ settings = {}, onChange = () => {} }: Props) => {
  const { checkoutShoppingBagEnabled } = settings;
  const { loading: isDisabled } = useBrandingContext();
  const onCheckoutBagEnabledChange = setting => onChange({ checkoutShoppingBagEnabled: setting });

  return (
    <LayoutRoot>
      <EditorSubheader title="Shopping cart" />
      <EditorToggleItem
        disabled={isDisabled}
        text="Checkout & shopping bag"
        checked={checkoutShoppingBagEnabled}
        onChange={onCheckoutBagEnabledChange}
        tooltipText={CHECKOUT_SHOPPING_BAG_SETTING_TOOLTIP_TEXT}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)``;

export default ShoppingCardEditor;
