import React from 'react';
import styled, { useTheme } from 'styled-components';
import BasicSelect from 'shared/react/components/basic/basic_select/BasicSelect';

const BrandingBasicSelectWrapper = ({ options, currentValue, onChange, ...props }) => {
  const theme = useTheme();

  const getOptions = () => {
    return Object.entries(options).map(([value, name]) => {
      return {
        id: value,
        name,
        value,
      };
    });
  };

  return (
    <StyledBasicSelect
      items={getOptions()}
      value={options[currentValue]}
      onChange={({ value }) => onChange(value)}
      inputBackground={theme.colors.white}
      {...props}
    />
  );
};

const StyledBasicSelect = styled(BasicSelect)`
  font-size: 16px;
`;

export default BrandingBasicSelectWrapper;
