import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CircularProgress, MenuItem, Select } from '@material-ui/core';
import {
  PAYMENT_ROLE_LITE_KEY,
  PAYMENT_ROLE_LITE_TEXT,
  PAYMENT_ROLE_PREMIUM_KEY,
  PAYMENT_ROLE_PRO_KEY,
} from 'app/src/constants/teamMembers.constants';
import Tooltip from 'app/src/basic_components/Tooltip';

const PAYMENT_ROLE_KEYS = [PAYMENT_ROLE_LITE_KEY, PAYMENT_ROLE_PRO_KEY];

const LITE_TOOLTIP_TEXT = 'Limited sharing and recording capabilities.';

const PRO_TOOLTIP_TEXT = 'Advanced recording capabilities and sharing functionality.';

export const getFormattedPaymentRole = (paymentRole = PAYMENT_ROLE_LITE_KEY) => {
  if (paymentRole !== PAYMENT_ROLE_PRO_KEY) {
    return PAYMENT_ROLE_LITE_TEXT;
  }

  return PAYMENT_ROLE_PREMIUM_KEY;
};

const TeamMemberPaymentRoleSelectedValue = ({ text, loading }) => {
  const tooltipText = text === PAYMENT_ROLE_PRO_KEY ? PRO_TOOLTIP_TEXT : LITE_TOOLTIP_TEXT;

  return (
    <Tooltip text={tooltipText} placement="bottom">
      <StyledTeamMemberPaymentRoleSelectedValue role={text} loading={loading ? loading : undefined}>
        <TeamMemberPaymentRoleSelectedValueLoading size={14} />
        <TeamMemberPaymentRoleSelectedValueText>
          {getFormattedPaymentRole(text)}
        </TeamMemberPaymentRoleSelectedValueText>
      </StyledTeamMemberPaymentRoleSelectedValue>
    </Tooltip>
  );
};

const TeamMemberPaymentRole = ({
  disabled,
  teamMember,
  paymentRole: initialPaymentRole,
  onTeamMemberPaymentRoleUpdate,
}) => {
  const [paymentRole, setPaymentRole] = useState(initialPaymentRole);

  useEffect(() => {
    setPaymentRole(initialPaymentRole);
  }, [initialPaymentRole]);

  const onPaymentRoleUpdate = e => {
    const newPaymentRole = e.target.value;

    if (newPaymentRole === paymentRole) {
      return;
    }

    setPaymentRole(newPaymentRole);
    onTeamMemberPaymentRoleUpdate({ teamMember, paymentRole: newPaymentRole });
  };

  return (
    <TeamMemberPaymentSelect
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
      disableUnderline
      value={paymentRole || PAYMENT_ROLE_LITE_KEY}
      onChange={onPaymentRoleUpdate}
      renderValue={value => <TeamMemberPaymentRoleSelectedValue text={value} />}
      disabled={disabled}
    >
      {PAYMENT_ROLE_KEYS.map(paymentRoleKey => {
        return (
          <MenuItem key={paymentRoleKey} value={paymentRoleKey}>
            {getFormattedPaymentRole(paymentRoleKey)}
          </MenuItem>
        );
      })}
    </TeamMemberPaymentSelect>
  );
};

export default TeamMemberPaymentRole;

const TeamMemberPaymentSelect = styled(Select)`
  .MuiSelect-select:focus {
    border-radius: 12px;
    background-color: transparent;
  }

  svg.Mui-disabled {
    display: none;
  }
`;

const TeamMemberPaymentRoleSelectedValueText = styled.span``;

const TeamMemberPaymentRoleSelectedValueLoading = styled(CircularProgress)`
  position: absolute;
`;

const StyledTeamMemberPaymentRoleSelectedValue = styled.div`
  position: relative;
  display: grid;
  place-items: center;
  background: ${({ role, theme }) =>
    role === PAYMENT_ROLE_PRO_KEY ? theme.colors.primaryLight : theme.colors.gray3};
  border-radius: 12px;
  padding: 5px 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.gray2};

  & > ${TeamMemberPaymentRoleSelectedValueLoading} {
    display: ${({ loading }) => (loading ? 'initial' : 'none')};
  }

  & > ${TeamMemberPaymentRoleSelectedValueText} {
    visibility: ${({ loading }) => (loading ? 'hidden' : 'initial')};
  }
`;
