export const BILLING_PACKAGE_TYPES = {
  free: 'free',
  pro: 'pro',
  business: 'business',
  enterprise: 'enterprise',
};

export const BILLING_PACKAGE_TEXTS = {
  free: 'Starter',
  pro: 'Pro',
  business: 'Business',
  enterprise: 'Enterprise',
};

export const SHOW_TRIAL_MODAL = 'showTrialModal';

export const REFERRAL_COOKIE = '_fprom_tid';
