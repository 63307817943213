import React from 'react';
import styled, { useTheme } from 'styled-components';
import { TextBody } from 'shared/react/components/complex/Text';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

const BillingPackageBasePrice = ({ value, packageInfo }) => {
  const theme = useTheme();
  const { color = theme.colors.black, isBasePricePerMonth, fullBasePrice } = packageInfo;

  return (
    <StyledBillingPackageBasePrice color={color}>
      <BillingPackageFullPrice visible={fullBasePrice}>{fullBasePrice}</BillingPackageFullPrice>
      {value}
      <BillingPackageMonthText visible={isBasePricePerMonth} />
    </StyledBillingPackageBasePrice>
  );
};

export default BillingPackageBasePrice;

const StyledBillingPackageBasePrice = styled(HorizontalFlex)`
  position: relative;
  align-items: end;
  gap: 2px;
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  color: ${({ color }) => color};
  padding-bottom: 8px;
`;

const BillingPackageFullPrice = styled(TextBody)`
  display: ${({ visible }) => (visible ? '' : 'none')};
  position: absolute;
  transform: translateY(-100%);
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.gray36};
  text-decoration-line: line-through;
`;

const BillingPackageMonthText = styled(TextBody)`
  display: ${({ visible }) => (visible ? '' : 'none')};
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  color: ${({ theme }) => theme.colors.gray34};
  &:after {
    content: '/month';
  }
`;
