import React, { FC } from 'react';
import AccountInstallationSection from 'app/src/pages/project/pages/installation/components/shared/account/AccountInstallationSection';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { project as Project } from 'app/src/types/entities';
import AccountInstallNotCompletedTooltip from './AccountInstallNotCompletedTooltip';
import { useAppActions } from 'src/context/AppsStore';
import useAccountInstallation from 'src/hooks/useAccountInstallation';

type Props = {
  project: Project;
  hidePublishContent?: boolean;
};

const AccountInstallationWrapper: FC<Props> = ({ project, hidePublishContent, children }) => {
  const { getAppUsingUrl } = useAppActions();
  const currentApp = getAppUsingUrl(project.appUrl);
  const [{ isInstallationCompleted: isAccountInstallationCompleted }] = useAccountInstallation(
    currentApp?.id
  );

  return (
    <LayoutRoot>
      {!hidePublishContent && (
        <AccountInstallationSection currentApp={currentApp} project={project} />
      )}
      <AccountInstallNotCompletedTooltip isAccountInstallCompleted={isAccountInstallationCompleted}>
        {children}
      </AccountInstallNotCompletedTooltip>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  gap: 40px;
`;
export default AccountInstallationWrapper;
