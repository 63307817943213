import React from 'react';
import styled from 'styled-components';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';
import Utils from 'shared/react/utils/utils';

const FeedButtonPrice = ({ isAddToCart, price, currencySymbol, isMultipleProductDetails }) => {
  const { player } = useDesignSettingsContext();
  const { includeProductPrice } = player.feedCards.productDetailsCard.primaryButton.settings;

  if (!isAddToCart || !includeProductPrice || isMultipleProductDetails) {
    return null;
  }

  return (
    <ButtonPriceContainer>
      {Utils.getDisplayedPrice({
        price,
        currencySymbol,
      })}
    </ButtonPriceContainer>
  );
};

const ButtonPriceContainer = styled.div``;

export default FeedButtonPrice;
