import React from 'react';

function DeveloperPortalIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="21" fill="none" viewBox="0 0 26 21">
      <path
        fill="#50545E"
        d="M7.18 5.11c-.196-.274-.625-.274-.86-.04l-5.625 5c-.156.117-.195.274-.195.47 0 .155.04.312.195.429l5.625 5a.628.628 0 00.43.195.54.54 0 00.43-.234c.234-.235.234-.664-.04-.86l-5.077-4.53L7.14 5.968c.273-.196.273-.625.039-.86zm18.086 4.96l-5.625-5c-.235-.234-.664-.234-.86.04-.234.234-.234.663.04.859l5.077 4.57-5.078 4.531c-.273.196-.273.625-.039.86a.557.557 0 00.469.234c.117 0 .273-.078.39-.195l5.626-5a.54.54 0 00.234-.43.557.557 0 00-.234-.469zM16.32.54c-.351-.08-.703.077-.82.39L9.25 19.68c-.078.351.078.703.39.82h.235c.234 0 .469-.156.586-.39l6.25-18.75c.078-.352-.078-.704-.39-.82z"
      />
    </svg>
  );
}

export default DeveloperPortalIcon;
