import React from 'react';
import EditorNumericInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorNumericInputItem';

const MinimumNumberOfVideos = props => {
  return (
    <EditorNumericInputItem
      text="Minimum number of videos"
      tooltipText="The widget will not appear at all unless the minimum number of videos is met (for example: if you set the minimum number to 3, and a certain product has only 2 videos, no videos will appear at all.)"
      min={1}
      max={10}
      {...props}
    />
  );
};

export default MinimumNumberOfVideos;
