import React from 'react';
import styled from 'styled-components';
import BackdropButton from '../../BackdropButton';
import FilledBagIcon from 'shared/react/images/feed/FilledBagIcon';
import useFeedModal from '../../context/hooks/useFeedModal';
import useFeedCart from '../../context/hooks/useFeedCart';
import { CART_MODAL_KEY } from 'shared/react/constants/modals.constants';
import Utils from 'shared/react/utils/utils';
import { SHOW_FEED_CART_MOBILE_MESSAGE } from 'shared/react/constants/messages.constants';
import { FEED_CART_BUTTON_DATA_TYPE } from '../../../../constants/feedPreviewDataTypes.constants';
import useFeedState from 'shared/react/components/complex/context/hooks/useFeedState';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';

const FeedCartButton = ({ currentStepKey, videoRef, audioRef, isMobile, ...props }) => {
  const [{ isAppPreview, isShoppable, feedIsAnyStepShoppable }] = useProjectConfig();
  const [{ numberOfCartItems }] = useFeedCart();
  const [{ currentModal }, { pushModal, popModal }] = useFeedModal();
  const [{ isShopifyStore, isCheckoutShoppingBagEnabled = true }] = useFeedState();

  const onCloseCartModal = () => {
    if (isMobile) {
      videoRef.current.play();
      audioRef?.current?.play();
      Utils.postMessageToWindow({ name: SHOW_FEED_CART_MOBILE_MESSAGE, isOpen: false });
    }
  };

  const handleClickCart = e => {
    e.stopPropagation();
    if (isAppPreview) {
      return;
    }

    if (currentModal?.key === CART_MODAL_KEY) {
      return popModal();
    }

    if (isMobile) {
      videoRef.current.pause();
      audioRef?.current?.pause();
      Utils.postMessageToWindow({ name: SHOW_FEED_CART_MOBILE_MESSAGE, isOpen: true });
    }
    pushModal({
      key: CART_MODAL_KEY,
      props: {
        onClose: onCloseCartModal,
        currentStepKey: currentStepKey,
      },
    });
  };

  if (
    !isCheckoutShoppingBagEnabled ||
    (!isAppPreview && !isShopifyStore) ||
    feedIsAnyStepShoppable === false ||
    !isShoppable
  ) {
    return null;
  }

  return (
    <BackdropButton
      onClick={handleClickCart}
      badgeNumber={numberOfCartItems}
      dataType={FEED_CART_BUTTON_DATA_TYPE}
      ariaLabel="Add to cart"
      {...props}
    >
      <StyledFilledBagIcon />
    </BackdropButton>
  );
};

const StyledFilledBagIcon = styled(FilledBagIcon)`
  height: 20px;
  width: 20px;

  & path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export default FeedCartButton;
