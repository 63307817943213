import React from 'react';
import styled from 'styled-components';
import Gap16VerticalFlex from '../../flex_layouts/Gap16VerticalFlex';
import VerticalFlex from '../../flex_layouts/VerticalFlex';
import FeedBrandedButton from '../feed_buttons/FeedBrandedButton';
import FilledBagIcon from '../../../../images/feed/FilledBagIcon';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';

const FeedCartEmptyState = ({ onClickBack }) => {
  const [, { t }] = useTranslation('feed.cart');

  return (
    <LayoutRoot>
      <TopContainer>
        <StyledFilledBagIcon />
        <EmptyPlaceholder>{t('empty')}</EmptyPlaceholder>
      </TopContainer>
      <FeedBrandedButton buttonText={t('continueShopping')} onClick={onClickBack} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 80px;
`;

const TopContainer = styled(Gap16VerticalFlex)`
  align-items: center;
`;

const EmptyPlaceholder = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
`;

const StyledFilledBagIcon = styled(FilledBagIcon)`
  height: 80px;
  width: 70px;

  & path {
    fill: ${({ theme }) => theme.colors.gray3};
  }
`;

export default FeedCartEmptyState;
