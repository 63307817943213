import React from 'react';
import styled, { useTheme } from 'styled-components';
import FillArrow from 'app/src/images/FillArrow';
import { TextSmall, TextTiny } from 'shared/react/components/complex/Text';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import UserBox from 'app/src/basic_components/UserBox';

const AccountItem = ({
  disabled,
  selected,
  title,
  subtitle,
  onClick,
  open,
  customPadding,
  accountImageSrc,
  color,
}) => {
  const theme = useTheme();

  const handleClicked = e => {
    if (disabled) {
      return;
    }

    onClick?.(e);
  };

  return (
    <LayoutRoot onClick={handleClicked} disabled={disabled} customPadding={customPadding}>
      <UserBox
        fontSize="20px"
        fontWeight="900"
        color={theme.colors.white}
        backgroundColor={color || theme.colors.red4}
        name={title}
        userImageSrc={accountImageSrc}
      />

      <TextContainer>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </TextContainer>
      {selected && (
        <ArrowIcon open={open}>
          <FillArrow />
        </ArrowIcon>
      )}
    </LayoutRoot>
  );
};

export default AccountItem;

const LayoutRoot = styled(Gap8HorizontalFlexWrap)`
  align-items: center;
  flex-wrap: nowrap;
  padding: ${({ customPadding }) => (customPadding ? customPadding : '8px')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  flex-grow: 1;
`;

const Title = styled(TextSmall)`
  width: 100%;
  word-break: break-all;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Subtitle = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.gray4};
  width: 100%;
  word-break: break-all;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ArrowIcon = styled.div`
  padding-right: ${({ open }) => (open ? '0px' : '2px')};
  padding-left: ${({ open }) => (open ? '2px' : '0px')};
  transform: ${({ open }) => (open ? 'rotate(180deg)' : '')};
`;
