import { createStore, createHook, createContainer } from 'react-sweet-state';
import { graphqlOperation } from 'aws-amplify';
import { graphqlRequest, postRequest } from '../helpers/API';
import { quizResultsByProjectId } from '../graphql/queries';
import { createQuizResult, deleteQuizResult, updateQuizResult } from '../graphql/mutations';
import Utils from '../utils';

const initialState = {};

const actions = {
  fetchQuizResults: projectId => async () => {
    if (!projectId) return;
    try {
      const response = await graphqlRequest(
        graphqlOperation(quizResultsByProjectId, { projectId, appKey: Utils.getAppKey() })
      );
      return response.data.quizResultsByProjectId.items;
    } catch (error) {
      console.error(error);
      return null;
    }
  },
  publishQuiz: publishId => async () => {
    try {
      await postRequest('publish-actions', '/actions/projects/quiz-v2', {
        body: {
          publishId,
        },
      });
    } catch (error) {
      console.error('Publish quiz failed', error);
    }
  },
  deleteQuizResult: quizResultId => async () => {
    try {
      const response = await graphqlRequest(
        graphqlOperation(deleteQuizResult, {
          input: { id: quizResultId },
        })
      );
      const deletedQuizResult = response?.data?.deleteQuizResult;

      return !!deletedQuizResult;
    } catch (error) {
      Utils.logErrorMessage(JSON.stringify(error));
      return false;
    }
  },
  createQuizResult: input => async () => {
    try {
      const response = await graphqlRequest(graphqlOperation(createQuizResult, { input }));
      return response?.data?.createQuizResult;
    } catch (error) {
      Utils.logErrorMessage(JSON.stringify(error));
      return null;
    }
  },
  updateQuizResults: quizResults => () => {
    return quizResults.map(quizResult => {
      const input = { ...quizResult };
      delete input.updatedAt;
      delete input.isDefaultExpanded;

      return graphqlRequest(graphqlOperation(updateQuizResult, { input }));
    });
  },
};

const QuizV2Store = createStore({ initialState, actions, name: 'QuizV2' });

export const useQuizV2 = createHook(QuizV2Store);

export const QuizV2Container = createContainer(QuizV2Store);
