import React, { ReactNode } from 'react';
import VerticalFlex, {
  VerticalFlexCentered,
} from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import styled from 'styled-components';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextBody, TextSmall } from 'shared/react/components/complex/Text';
import { DangerButton } from 'shared/react/components/basic/button-v2/BasicButton';
import FolderIcon from 'app/src/images/dashboard_v2/FolderIcon';
import MagnifyingGlassIcon from 'app/src/pages/dashboard/components/empty-states/icons/MagnifyingGlassIcon';
import VideoIcon from 'app/src/pages/dashboard/components/empty-states/icons/VideoIcon';
import WebsiteIcon from 'app/src/images/sidebar-v2/WebsiteIcon';

type Props = {
  icon?: ReactNode;
  title?: string;
  onCreate?: () => void;
  subTitle?: string;
  createButton?: ReactNode;
};

const EmptyState = ({ icon, title, onCreate, subTitle, createButton }: Props) => {
  const getCreateButton = () => {
    if (createButton) {
      return createButton;
    }

    if (onCreate) {
      return <DangerButton onClick={onCreate}>Create</DangerButton>;
    }
    return '';
  };

  return (
    <LayoutRoot>
      {icon}
      <TextContainer>
        <Title>{title}</Title>
        {subTitle && <SubTitle>{subTitle}</SubTitle>}
      </TextContainer>
      {getCreateButton()}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlexCentered)`
  gap: 24px;
  height: 100%;
  & svg {
    width: 90px;
    height: 80px;
  }

  & path {
    fill: ${({ theme }) => theme.colors.neutralGray};
  }
`;

const TextContainer = styled(VerticalFlex)`
  gap: 4px;
  align-items: center;
`;

const Title = styled(TextBody)`
  font-weight: 500;
`;

const SubTitle = styled(TextSmall)`
  font-weight: 500;
`;

export const FolderEmptyState = styled(EmptyState).attrs(() => ({
  icon: <FolderIcon />,
  title: 'No items in folder',
}))``;

export const SearchEmptyState = styled(EmptyState).attrs(() => ({
  icon: <MagnifyingGlassIcon />,
  title: 'No results matching',
  subTitle: 'Adjust the search or filter',
}))``;

export const PageEmptyState = styled(EmptyState).attrs(() => ({
  icon: <WebsiteIcon />,
}))``;

export const SourceEmptyState = styled(EmptyState).attrs(() => ({
  icon: <VideoIcon />,
}))``;

export const PreviewContainer = styled(VerticalFlex)`
  gap: 12px;
  height: 320px;
  width: 230px;
  padding: 12px;
  border: 1px dashed ${({ theme }) => theme.colors.neutralGray};
  border-radius: 8px;
`;
