import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import PlusIcon from 'app/src/images/dashboard_v2/PlusIcon';
import { app as appEntity } from 'app/src/types/entities';
import DisconnectIcon from 'app/src/images/DisconnectIcon';
import MenuWithItems from 'src/complex_components/menu_items/MenuWithItems';
import ThreeVerticalDotsIcon from 'app/src/images/dashboard_v2/ThreeVerticalDotsIcon';

type Props = {
  onClick?: () => void;
  initialized: boolean;
  app: appEntity;
  onDisconnectClick: () => void;
  userName?: string;
};

const SidebarIntegrationSideButton = ({
  onClick,
  initialized,
  app,
  onDisconnectClick,
  userName,
}: Props) => {
  const theme = useTheme();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const onAddClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClick();
  };

  const onMenuClick = e => {
    e.stopPropagation();
    setMenuAnchor(e.currentTarget);
  };

  if (!initialized || (!app?.active && !onClick)) {
    return null;
  }

  const menuItems = [
    {
      text: `@${userName}`,
      color: theme.colors.neutralDarker,
      hidden: !userName,
    },
    { separator: true, hidden: !userName },
    { onClick: onDisconnectClick, text: 'Disconnect', icon: <DisconnectIcon /> },
  ];

  if (app?.active) {
    return (
      <SidebarIntegrationSideButtonLayoutRoot onClick={onMenuClick}>
        <IconContainer>
          <ThreeVerticalDotsIcon />
        </IconContainer>
        <MenuWithItems
          anchorElement={menuAnchor}
          onClose={() => setMenuAnchor(null)}
          menuItems={menuItems}
        />
      </SidebarIntegrationSideButtonLayoutRoot>
    );
  }

  return (
    <DisconnectedLayoutRoot onClick={onAddClick}>
      <PlusIcon />
    </DisconnectedLayoutRoot>
  );
};

const IconContainer = styled(HorizontalFlexCentered)`
  width: 20px;
  height: 20px;
  padding: 3px 4px;
  border-radius: 16px;
  transition: 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
  }

  & > svg {
    width: 12px;
    height: 12px;
  }

  & svg > path {
    fill: ${({ theme }) => theme.colors.black};
  }
`;

export const SidebarIntegrationSideButtonLayoutRoot = styled(HorizontalFlexCentered)`
  border-radius: 4px;
  padding: 6px;
  width: 24px;
  height: 24px;
  display: none;

  svg {
    width: 12px;
    height: 12px;
  }

  path {
    fill: ${({ theme }) => theme.colors.neutralDarker};
  }

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    display: flex;
  }
`;

const DisconnectedLayoutRoot = styled(SidebarIntegrationSideButtonLayoutRoot)`
  border: 1px solid ${({ theme }) => theme.colors.neutralLight};
  display: flex;
`;

export default SidebarIntegrationSideButton;
