import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  createOrUpdateNotificationSettings,
  fetchNotificationSettings,
  removeNotificationSettings,
} from 'app/src/context/AccountStore';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { useProjects } from 'app/src/context/ProjectsStore';
import { useUser } from 'app/src/context/userStore/UserStore';
import Utils from 'app/src/utils';
import GeneralSettings from 'app/src/pages/project/pages/settings/GeneralSettings';
import Notifications from 'app/src/pages/project/pages/settings/Notifications';
import { FEATURE_QUIZ_V2 } from 'shared/react/constants/features.constants';
import { FormProvider, useForm } from 'react-hook-form';
import IntegrationsNotificationSettings from './IntegrationsNotificationSettings';
import TrackingSettings from './TrackingSettings';
import SnackBar from 'app/src/basic_components/SnackBar';
import useUpdateDirtyForm from 'app/src/hooks/useUpdateDirtyForm';
import { getIsDerivedProject, getProjectEmailSettings } from 'app/src/utils/project.utils';
import { useApps } from 'app/src/context/AppsStore';

const SAVE_STATE = {
  success: 'success',
  error: 'error',
};

const GeneralSettingsPage = () => {
  const { projectId } = useParams();
  const [, { getUser }] = useUser();
  const [{ selectedApp }, { updateApp, setPrimaryHeroProject }] = useApps();
  const [{ project }, { updateProject, publishProject }] = useProjects({ projectId });
  const [, { getFeatureEnabled }] = useFeatures();
  const isDerivedProject = getIsDerivedProject(project);
  const emailSettings = getProjectEmailSettings(project);
  const isQuizV2Enabled = getFeatureEnabled(FEATURE_QUIZ_V2);
  const user = getUser();
  const methods = useForm({ defaultValues: { ...project } });
  const [saveState, setSaveState] = useState('');
  const [isChanged, setIsChanged] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setLoading] = useState(false);
  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isDirty, dirtyFields, isSubmitting: isSaving },
  } = methods;
  const appSettings = JSON.parse(selectedApp?.settings || '{}');

  const onSaveProject = async params => {
    if (!isChanged && params.target) {
      return;
    }

    setLoading(true);
    setSaveState('');

    let currProject = project;
    if (!params.target) {
      currProject = { ...currProject, ...params };
    }

    try {
      const newProject = await updateProject(currProject);
      if (newProject) {
        await publishProject(newProject.id);
        setSaveState(SAVE_STATE.success);
      } else {
        setSaveState(SAVE_STATE.error);
      }

      setIsChanged(false);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setSaveState(SAVE_STATE.error);
      setErrorMessage('');
      setLoading(false);
    }
  };

  const getSnackBarText = () => {
    if (saveState === SAVE_STATE.success) {
      return 'Changes have been saved!';
    }

    return errorMessage || 'There have been an error saving';
  };

  const getNotificationSettings = async () => {
    const projectNotificationSettingsId = getProjectNotificationSettingsId();

    if (projectNotificationSettingsId) {
      const projectNotificationSettings = await fetchNotificationSettings(
        projectNotificationSettingsId,
        user.owner
      );
      if (!projectNotificationSettings) {
        return;
      }

      const { email: emailNotificationSettings, inApp: inAppNotificationSettings } =
        projectNotificationSettings;

      return {
        emailSettings: emailNotificationSettings?.enabled || [],
        inAppSettings: inAppNotificationSettings?.enabled || [],
        isEmailShowAnonymous: emailNotificationSettings?.showAnonymous,
        isInAppShowAnonymous: inAppNotificationSettings?.showAnonymous,
      };
    }
  };

  const resetToDefault = async project => {
    const initialState = {
      isSubtitlesEnabled: project?.subtitlesEnabled,
      isFastForwardEnabled: project?.fastForwardEnabled,
      isChatLandingPage: project?.chatLandingPage || false,
      isQuiz: Utils.isQuiz(project?.tolstoyType),
      tolstoyName: project?.name || '',
      language: project.language || '',
      googleAnalyticsID: project?.googleAnalyticsID,
      facebookAnalyticsID: project?.facebookAnalyticsID || '',
      emailNotifications: project?.emailNotifications || [],
      emailSettings: [],
      inAppSettings: [],
      viewerNotificationLevel: project?.viewerNotificationLevel,
      isEmailShowAnonymous: false,
      isInAppShowAnonymous: false,
      isRestored: false,
      sourceProjectId: project?.sourceProjectId,
      campaignTileAction: emailSettings?.tileAction,
      primaryHeroPublishId: appSettings.primaryHeroPublishId,
    };

    const notificationSettings = await getNotificationSettings();

    reset({ ...initialState, ...notificationSettings });
  };

  const getOnlyDirtyFields = updatedSettings => {
    const projectProp2formKey = {
      tolstoyType: 'isQuiz',
      name: 'tolstoyName',
      subtitlesEnabled: 'isSubtitlesEnabled',
      fastForwardEnabled: 'isFastForwardEnabled',
      chatLandingPage: 'isChatLandingPage',
      googleAnalyticsID: 'googleAnalyticsID',
      facebookAnalyticsID: 'facebookAnalyticsID',
      language: 'language',
      dynamic: 'dynamic',
      sourceProjectId: 'sourceProjectId',
      emailSettings: 'campaignTileAction',
    };

    const onlyDirty = {};
    Object.entries(updatedSettings).forEach(([key, value]) => {
      if (projectProp2formKey[key] in dirtyFields) {
        onlyDirty[key] = value;
      }
    });

    return onlyDirty;
  };

  const getTolstoyType = isQuiz => {
    let tolstoyType = null;
    if (Utils.isQuiz(project.tolstoyType)) {
      if (!isQuiz) {
        tolstoyType = project.tolstoyType.replace('_quiz', '');
        tolstoyType = project.tolstoyType.replace('quiz', '');
      }
    } else {
      if (isQuiz) {
        const suffix = isQuizV2Enabled ? '_v2' : '';
        tolstoyType = `${project.tolstoyType}_quiz${suffix}`;
      }
    }

    return tolstoyType;
  };

  const getGeneralFieldsForUpdate = ({
    isQuiz,
    tolstoyName,
    isSubtitlesEnabled,
    isFastForwardEnabled,
    isChatLandingPage,
    googleAnalyticsID,
    facebookAnalyticsID,
    language,
    dynamic,
    sourceProjectId,
    campaignTileAction,
  }) => {
    let tolstoyType = getTolstoyType(isQuiz);

    const upToDateSettings = {
      name: tolstoyName,
      subtitlesEnabled: isSubtitlesEnabled,
      fastForwardEnabled: isFastForwardEnabled,
      chatLandingPage: isChatLandingPage,
      tolstoyType,
      googleAnalyticsID,
      facebookAnalyticsID,
      language,
      dynamic,
      sourceProjectId,
      emailSettings: JSON.stringify({
        ...emailSettings,
        tileAction: campaignTileAction,
      }),
    };

    return getOnlyDirtyFields(upToDateSettings);
  };

  const handleNotRestored = async (
    isEmailShowAnonymous,
    emailSettings,
    isInAppShowAnonymous,
    inAppSettings
  ) => {
    const notificationSettingsId = getProjectNotificationSettingsId();
    const response = await createOrUpdateNotificationSettings(notificationSettingsId, {
      owner: user.owner,
      email: {
        showAnonymous: isEmailShowAnonymous,
        enabled: emailSettings,
      },
      inApp: {
        showAnonymous: isInAppShowAnonymous,
        enabled: inAppSettings,
      },
      projectId,
    });
    return response?.id;
  };

  const getNotificationsFieldsForUpdate = async ({
    isRestored,
    isEmailShowAnonymous,
    emailSettings,
    isInAppShowAnonymous,
    inAppSettings,
    viewerNotificationLevel,
    emailNotifications,
  }) => {
    let newNotificationSettingsId = null;

    if (!isRestored) {
      newNotificationSettingsId = await handleNotRestored(
        isEmailShowAnonymous,
        emailSettings,
        isInAppShowAnonymous,
        inAppSettings
      );
    } else if (project?.notificationSettingsId) {
      await removeNotificationSettings(project.notificationSettingsId);
    }

    return {
      emailNotifications,
      viewerNotificationLevel,
      notificationSettingsId: newNotificationSettingsId,
    };
  };

  const onSave = async settings => {
    const general = getGeneralFieldsForUpdate(settings);
    const notifications = await getNotificationsFieldsForUpdate(settings);
    const updatedSettings = { ...general, ...notifications };
    await onSaveProject(updatedSettings);

    if (settings.primaryHeroPublishId === appSettings.primaryHeroPublishId) {
      return;
    }

    const newAppSettings = JSON.stringify({
      ...appSettings,
      primaryHeroPublishId: settings.primaryHeroPublishId,
    });
    await updateApp(selectedApp.id, { settings: newAppSettings });
    if (settings.primaryHeroPublishId) {
      await setPrimaryHeroProject(project.id);
    }
  };

  const onCancel = () => {
    resetToDefault(project);
  };

  const getProjectNotificationSettingsId = () => project?.notificationSettingsId;

  const updateAndOverride = (target, value, dirty = true) =>
    setValue(target, value, { shouldDirty: dirty });

  const onSaveCallback = useCallback(onSave, [isDirty]);

  const onSaveClickCallback = useCallback(() => {
    handleSubmit(onSaveCallback)();
  }, [onSaveCallback]);

  useEffect(() => {
    resetToDefault(project);
  }, [project, selectedApp]);

  useUpdateDirtyForm(isDirty, {
    isLoading: isSaving,
    onSaveClick: onSaveClickCallback,
    onDiscardClick: onCancel,
  });

  const getSettingsPanels = () => {
    if (isDerivedProject) {
      return <GeneralSettings isLoading={isLoading} setLoading={setLoading} />;
    }

    return (
      <>
        <GeneralSettings isLoading={isLoading} setLoading={setLoading} />
        <TrackingSettings />
        <Notifications getProjectNotificationSettingsId={getProjectNotificationSettingsId} />
        <IntegrationsNotificationSettings />
      </>
    );
  };

  return (
    <FormProvider {...methods} setValue={updateAndOverride}>
      <LayoutRoot>{getSettingsPanels()}</LayoutRoot>
      <SnackBar
        setOpen={() => setSaveState('')}
        text={getSnackBarText()}
        open={!!saveState}
        severity={saveState || 'success'}
      />
    </FormProvider>
  );
};

export default GeneralSettingsPage;

const LayoutRoot = styled.div`
  padding-top: 24px;
`;
