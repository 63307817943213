import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TextBody } from 'shared/react/components/complex/Text';
import Button from 'shared/react/components/complex/Button';
import { navigateToRoot } from '../../utils/navigation.utils';
import { useHistory } from 'react-router-dom';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import Types from 'shared/react/theme/Types';
import { useAccount } from 'app/src/context/AccountStore';
import SnackBar from 'app/src/basic_components/SnackBar';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { FEATURE_CREATE_WORKSPACE } from 'app/src/constants/appFeatures.constants';

const CANNOT_CREATE_WORKSPACE_ERROR_MESSAGE =
  'Failed to create a new workspace, please try again later';

const CreateWorkspace = () => {
  const history = useHistory();
  const [, { getFeatureEnabled }] = useFeatures();
  const [, { createWorkspace }] = useAccount();
  const [loading, setLoading] = useState(false);
  const [workspaceName, setWorkspaceName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!getFeatureEnabled(FEATURE_CREATE_WORKSPACE)) {
      onCancelClick();
    }
  }, []);

  const onWorkspaceNameUpdate = e => {
    setWorkspaceName(e.target.value);
  };

  const onCancelClick = () => {
    navigateToRoot(history);
  };

  const onCreateClick = async () => {
    if (!workspaceName) {
      return;
    }

    setLoading(true);
    const created = await createWorkspace(workspaceName);
    setLoading(false);

    if (created) {
      navigateToRoot(history);
    }

    setErrorMessage(CANNOT_CREATE_WORKSPACE_ERROR_MESSAGE);
  };

  return (
    <StyledCreateWorkspace>
      <SnackBar
        text={errorMessage}
        open={errorMessage}
        setOpen={() => setErrorMessage('')}
        severity="error"
      />
      <CreateWorkspaceText>Give your new workspace a name.</CreateWorkspaceText>
      <CreateWorkspaceContent>
        <CreateWorkspaceNameInput
          autoFocus
          value={workspaceName}
          onChange={onWorkspaceNameUpdate}
        />
        <CreateWorkspaceButtons>
          <Button type={Types.Ghost} onClick={onCancelClick}>
            Cancel
          </Button>
          <Button
            type={Types.Secondary}
            disabled={!workspaceName}
            onClick={onCreateClick}
            loading={loading}
          >
            Create
          </Button>
        </CreateWorkspaceButtons>
      </CreateWorkspaceContent>
    </StyledCreateWorkspace>
  );
};

export default CreateWorkspace;

const StyledCreateWorkspace = styled(VerticalFlex)`
  align-items: center;
`;

const CreateWorkspaceContent = styled(VerticalFlex)`
  align-items: center;
  gap: 55px;
`;

const CreateWorkspaceText = styled(TextBody)`
  margin-bottom: 168px;
  font-weight: bold;
  font-size: 40px;
  line-height: 44px;
  color: ${({ theme }) => theme.colors.black};
`;

const CreateWorkspaceNameInput = styled.input`
  border: none;
  border-bottom: 5px solid ${({ theme }) => theme.colors.blue7};
  background: none;
  max-width: 538px;
  font-weight: 800;
  font-size: 56px;
  line-height: 64px;
  outline: none;
  padding-bottom: 10px;
  text-align: center;
`;

const CreateWorkspaceButtons = styled(HorizontalFlexWrap)`
  gap: 16px;
`;
