import React from 'react';
import { ArrowDownWideNarrow, ArrowUpWideNarrow } from 'lucide-react';
import { Button, DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from 'ui-library/core';
import { SortOrder, SortField } from '../../../constants';
import OrderDropdown from './OrderDropdown';
import FieldsDropdown from './FieldsDropdown';

interface Field {
  value: string;
  label: string;
}

type SortProps = {
  selectedFieldValue: SortField;
  order: SortOrder;
  fields: Field[];
  onSort: (sort: { field: SortField; order: SortOrder }) => void;
};

export const UgcSort = ({ selectedFieldValue, order, fields, onSort }: SortProps) => {
  const field = fields.find(field => field.value === selectedFieldValue);
  const btnText = field ? 'Sort (1)' : 'Sort';
  const Icon = order === SortOrder.DESC ? ArrowDownWideNarrow : ArrowUpWideNarrow;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="sm">
          <Icon className="mr-2 h-4 w-4" />
          {btnText}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <div className="flex gap-2 p-3">
          <FieldsDropdown
            selectedFieldValue={selectedFieldValue}
            fields={fields}
            onSelect={(field: SortField) => onSort({ field, order })}
          />
          <OrderDropdown
            order={order}
            onOrderChange={order => onSort({ field: selectedFieldValue, order })}
          />
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default UgcSort;
