import React from 'react';

const SeeItLiveIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.875 4.625C6.09375 4.46875 6.40625 4.46875 6.625 4.625L11.125 7.375C11.3438 7.5 11.5 7.75 11.5 8C11.5 8.28125 11.3438 8.53125 11.125 8.65625L6.625 11.4062C6.40625 11.5312 6.09375 11.5625 5.875 11.4062C5.625 11.2812 5.5 11.0312 5.5 10.75V5.25C5.5 5 5.625 4.75 5.875 4.625ZM16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0C12.4062 0 16 3.59375 16 8ZM8 1.5C4.40625 1.5 1.5 4.4375 1.5 8C1.5 11.5938 4.40625 14.5 8 14.5C11.5625 14.5 14.5 11.5938 14.5 8C14.5 4.4375 11.5625 1.5 8 1.5Z"
        fill="#2F3137"
      />
    </svg>
  );
};

export default SeeItLiveIcon;
