import React from 'react';

const MarkAsRead = () => {
  return (
    <svg width="18" height="16" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.335 2.38376L2.55143 6.03746L9.335 2.38376ZM9.335 2.38376L16.1186 6.03746L9.335 2.38376ZM2.48226 6.08268L9.335 9.73694L16.1877 6.08268C16.1661 6.06578 16.143 6.05067 16.1187 6.03753L2.48226 6.08268ZM2.48226 6.08268C2.50392 6.06578 2.527 6.05067 2.55129 6.03753L2.48226 6.08268ZM9.66461 0.983085L9.6646 0.983078C9.5633 0.928545 9.45005 0.9 9.335 0.9C9.21995 0.9 9.1067 0.928545 9.0054 0.983078L9.00539 0.983085L1.89207 4.81361C1.59231 4.97497 1.34182 5.21446 1.16716 5.50667C0.992495 5.79888 0.900179 6.13292 0.9 6.47335V6.4734V13.5022C0.9 14.1603 1.16144 14.7915 1.62679 15.2568C2.09215 15.7222 2.72331 15.9836 3.38143 15.9836H15.2886C15.9467 15.9836 16.5778 15.7222 17.0432 15.2568C17.5086 14.7915 17.77 14.1603 17.77 13.5022V6.4734V6.47335C17.7698 6.13292 17.6775 5.79888 17.5028 5.50667C17.3282 5.21446 17.0777 4.97497 16.7779 4.81361L9.66461 0.983085ZM9.66186 11.1376L16.3793 7.55688V13.5022C16.3793 13.7915 16.2644 14.0689 16.0598 14.2734C15.8553 14.478 15.5778 14.5929 15.2886 14.5929H3.38143C3.09215 14.5929 2.81473 14.478 2.61018 14.2734C2.40563 14.0689 2.29071 13.7915 2.29071 13.5022V7.55688L9.00814 11.1376L9.00818 11.1376C9.10879 11.1912 9.22102 11.2192 9.335 11.2192C9.44898 11.2192 9.56121 11.1912 9.66182 11.1376L9.66186 11.1376Z"
        fill="#C8C9C9"
        stroke="#C8C9C9"
        strokeWidth="0.2"
      />
    </svg>
  );
};

export default MarkAsRead;
