import React, { useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { WhiteButton, PrimaryButton } from 'shared/react/components/basic/button-v2/BasicButton';
import useUploadFile from 'src/hooks/useUploadFile';
import { useVideos } from 'src/context/VideosStore';
import { VIDEO_STATUS } from 'shared/react/constants/video.constants';
import { UPLOAD_HERO_FILE } from 'src/constants/creation_method.constants';
import Utils from 'src/utils';
import { useProjectActions } from 'src/context/ProjectsStore';
import { project as projectType, projectStep } from 'app/src/types/entities';
import { STEP_TYPE } from 'src/pages/project/pages/hero/constants';
import RefreshIcon from 'src/images/dashboard_v2/RefreshIcon';
import UploadIcon from 'src/images/dashboard_v2/UploadIcon';
import { useSnackBarActions } from 'src/context/ui_store/SnackBarStore';

type Props = {
  project: projectType;
  step: projectStep;
  stepType: STEP_TYPE;
  limit: number;
  onProgress: (progress: number) => void;
  onFileUploaded: () => void;
};

const FileInput = ({ project, stepType, step, limit, onProgress, onFileUploaded }: Props) => {
  const [isUploading, setIsUploading] = useState(false);
  const { setErrorSnackbar } = useSnackBarActions();
  const { createProjectStep, updateProjectStep } = useProjectActions();
  const { checkVideoFile } = useUploadFile();
  const [{ data: videos }, { createVideo, uploadVideoAsset }] = useVideos();

  const getIsFileValid = async (file: File) => {
    if (!file) {
      return false;
    }

    const { isValid, duration } = await checkVideoFile(file);

    if (!isValid) {
      return false;
    }

    if (duration > limit) {
      setErrorSnackbar(`Video is too long. It should be up to ${limit} seconds`);
      return false;
    }

    return true;
  };

  const createNewVideoAndUpload = async (file: File) => {
    const uuid = uuidv4();
    const name = file.name || `Recorded Video #${videos.length + 1}`;

    await createVideo({
      id: uuid,
      name,
      status: VIDEO_STATUS.uploading,
      uploadType: UPLOAD_HERO_FILE,
      originalSize: `${Utils.sizeInBytesToMb(file.size)}MB`,
    });

    const response = await uploadVideoAsset(
      undefined,
      {
        uuid,
        blob: file,
      },
      (uuid, progress) => onProgress(progress.replace('%', ''))
    );

    return { response, videoId: uuid };
  };

  const createOrUpdateStep = async videoId => {
    const newStep = {
      videoId,
      description: stepType,
      projectId: project.id,
    };

    if (step) {
      await updateProjectStep({ ...step, ...newStep }, project);
    } else {
      await createProjectStep(newStep, project);
    }
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];

    const isFileValid = await getIsFileValid(selectedFile);

    if (!isFileValid) {
      return;
    }

    setIsUploading(true);

    const { response, videoId } = await createNewVideoAndUpload(selectedFile);

    if (response?.error) {
      setIsUploading(false);
      return;
    }

    await createOrUpdateStep(videoId);

    setIsUploading(false);
    onFileUploaded();
  };

  const input = (
    <InputFile type="file" accept="video/*" onChange={onFileChange} disabled={isUploading} />
  );

  if (step) {
    return (
      <WhiteButton as="label" disabled={isUploading} isLoading={isUploading}>
        <RefreshIconStyled />
        Replace {input}
      </WhiteButton>
    );
  }

  return (
    <PrimaryButton as="label" disabled={isUploading} isLoading={isUploading}>
      <UploadIconStyled />
      Upload {input}
    </PrimaryButton>
  );
};

const InputFile = styled.input`
  display: none;
`;

const UploadIconStyled = styled(UploadIcon)`
  width: 14px;
  height: 14px;

  & path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const RefreshIconStyled = styled(RefreshIcon)`
  width: 14px;
  height: 14px;

  & path {
    fill: ${({ theme }) => theme.colors.neutralDarker};
  }
`;

export default FileInput;
