import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import ExpanderTopArrowIcon from 'src/images/creation_method_icons/ExpanderTopArrowIcon';
import { TextH6 } from 'shared/react/components/basic/text/TextV2';
import Utils from 'shared/react/utils/utils';

type Props = {
  startExpanded?: boolean;
  header?: React.ReactNode;
  isDisabled?: boolean;
};

const BasicAccordionV2: React.FC<Props> = ({
  header,
  children,
  startExpanded = false,
  isDisabled = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(!isDisabled && !!startExpanded);
  const containerRef = useRef(null);

  const handleToggleAccordion = () => {
    if (isDisabled) {
      return;
    }

    setIsExpanded(isExpanded => !isExpanded);
  };

  useEffect(() => {
    if (Utils.isNullOrUndefined(startExpanded) || isDisabled) {
      return;
    }

    setIsExpanded(startExpanded);
  }, [startExpanded]);

  useEffect(() => {
    if (!containerRef.current || !isExpanded) {
      return;
    }

    containerRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [isExpanded, containerRef]);

  return (
    <LayoutRoot isDisabled={isDisabled}>
      <HeaderLayout isExpanded={isExpanded} onClick={handleToggleAccordion} isDisabled={isDisabled}>
        {header}
        <ExpanderArrowWrapper isExpanded={isExpanded} isDisabled={isDisabled}>
          <ExpanderTopArrowIcon />
        </ExpanderArrowWrapper>
      </HeaderLayout>
      {!!isExpanded && <ContentWrapper ref={containerRef}>{children}</ContentWrapper>}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)<{ isDisabled: boolean }>`
  & div {
    color: ${({ theme, isDisabled }) => (isDisabled ? theme.colors.neutralLight : '')};
  }
  border: 1px solid ${({ theme }) => theme.colors.ghostLight};
  border-radius: 4px;
`;

const ContentWrapper = styled.div`
  padding: 16px 32px;
  padding-top: 16px;
`;

const HeaderLayout = styled(HorizontalFlex)<{ isExpanded: boolean; isDisabled: boolean }>`
  padding: 16px;
  align-items: center;
  cursor: ${({ isDisabled }) => (isDisabled ? 'initial' : 'pointer')};
  border-bottom: ${({ theme, isExpanded }) =>
    isExpanded && `1px solid ${theme.colors.neutralLight}`};
`;

const ExpanderArrowWrapper = styled.div<{ isExpanded: boolean; isDisabled: boolean }>`
  margin-left: auto;
  transform: rotate(${({ isExpanded }) => (isExpanded ? 0 : 180)}deg);
  transition: 0.2s ease-out;

  & svg {
    width: 14px;
    height: 14px;
  }

  & path {
    fill: ${({ theme, isDisabled }) =>
      isDisabled ? theme.colors.neutralLight : theme.colors.neutralDarker};
  }
`;

export const StepNumber = styled(TextH6)`
  padding-right: 8px;
  color: ${({ theme }) => theme.colors.neutralDarker};
`;

export const StepName = styled(TextH6)`
  padding: 0 8px;
  border-left: ${({ theme }) => `1px solid ${theme.colors.ghostLight}`};
`;

export default BasicAccordionV2;
