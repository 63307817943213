import * as React from 'react';

const QuizRuleXIcon = ({ ...props }) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="#EAECF0" d="M0 0h16v16H0z" />
    <path
      d="M12.156 11.031 8.902 7.75l3.254-3.254a.599.599 0 0 0 0-.902.599.599 0 0 0-.902 0L8 6.848 4.719 3.594a.599.599 0 0 0-.903 0 .599.599 0 0 0 0 .902L7.07 7.75l-3.254 3.281a.599.599 0 0 0 0 .903.599.599 0 0 0 .903 0L8 8.68l3.254 3.254a.599.599 0 0 0 .902 0 .599.599 0 0 0 0-.903Z"
      fill="#FA536E"
    />
  </svg>
);

export default QuizRuleXIcon;
