import React from 'react';

const FilledHeartIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.102 7.555A3.52 3.52 0 0 1 0 4.977v-.118c0-1.64 1.172-3.046 2.79-3.304a3.37 3.37 0 0 1 2.929.914L6 2.75l.281-.281a3.32 3.32 0 0 1 2.907-.914A3.35 3.35 0 0 1 12 4.859v.118c0 .984-.422 1.921-1.125 2.578l-4.242 3.96c-.164.165-.399.235-.633.235-.258 0-.492-.07-.656-.234z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default FilledHeartIcon;
