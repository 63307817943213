import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useUser } from 'app/src/context/userStore/UserStore';
import { CircularProgress } from '@material-ui/core';
import WorkspaceInformation from './WorkspaceInformation';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import BaseDetails from '../BaseDetails';
import Utils from '../../../utils';
import { useAccount } from 'app/src/context/AccountStore';
import { useBilling } from 'app/src/hooks/useBillings';
import StickyTopBar from 'app/src/pages/dashboard/components/top-bar/StickyTopBar';
import useUpdateDirtyForm from 'app/src/hooks/useUpdateDirtyForm';
import { useApps } from 'app/src/context/AppsStore';

const ACCOUNT_LOGO_NAME = 'account_logo.png';

function MyWorkspacePage() {
  const [{ account, accountLogoSettings }, { updateAccountSettings }] = useUser();
  const [
    { apps, selectedApp },
    { getMultipassSecret, getIsSelectedShopifyPlus, updateMultipassSecret },
  ] = useApps();
  const [{ isAccountOwner }] = useAccount();
  const { currentPackage } = useBilling();
  const [isUnsaved, setIsUnsaved] = useState(false);
  const [accountName, setAccountName] = useState(account?.name || '');
  const [accountUrl, setAccountUrl] = useState(account?.url || '');
  const [companySize, setCompanySize] = useState(account?.companySize);
  const [companyVertical, setCompanyVertical] = useState(account?.companyVertical);
  const [companyUseCase, setCompanyUseCase] = useState(account?.useCase);
  const [multipassSecret, setMultipassSecret] = useState();
  const [isShopifyPlus, setIsShopifyPlus] = useState();
  const [shouldUseCache, setShouldUseCache] = useState(false);
  const widgetSettings = Utils.safeParse(account?.widgetSettings);

  const [loading, setLoading] = useState(false);

  const updateCompanyUseCase = value => {
    setCompanyUseCase(value);
    setIsUnsaved(true);
  };

  const onSave = async () => {
    const account = {};
    setLoading(true);
    account.name = accountName;
    account.url = accountUrl;
    account.companySize = companySize;
    account.companyVertical = companyVertical;
    account.useCase = companyUseCase;

    account.widgetSettings = JSON.stringify({
      ...widgetSettings,
      shouldUseCache,
    });

    await updateAccountSettings(account);
    if (isShopifyPlus) {
      await updateMultipassSecret(multipassSecret);
    }
    setLoading(false);
    setIsUnsaved(false);
  };

  const onSaveCallback = useCallback(onSave, [
    accountName,
    accountUrl,
    companySize,
    companyVertical,
    companyUseCase,
    multipassSecret,
    shouldUseCache,
  ]);

  useEffect(() => {
    if (!account) {
      return;
    }

    setAccountName(account.name || '');
    setAccountUrl(account.url || '');
    setCompanySize(account.companySize);
    setCompanyVertical(account.companyVertical);
    setCompanyUseCase(account.useCase);
    setShouldUseCache(widgetSettings.shouldUseCache);

    setIsUnsaved(false);
  }, [account]);

  useEffect(() => {
    setMultipassSecret(getMultipassSecret());
    setIsShopifyPlus(getIsSelectedShopifyPlus());
  }, [apps, selectedApp]);

  useUpdateDirtyForm(isUnsaved, {
    isLoading: loading,
    hideCancel: true,
    onSaveClick: onSaveCallback,
  });

  if (!account?.owner) {
    return <StyledCircularProgress size={20} />;
  }

  return (
    <LayoutRoot>
      <StickyTopBar leftContent={'My workspace'} dirtyTitle={'My workspace'} />
      <Content>
        <MainContainer>
          <BaseDetails
            user={account}
            updateFunction={updateAccountSettings}
            header={Utils.upperCaseFirstChar(account?.name)}
            headerPlaceholder={'Company Name'}
            subHeader={account?.url}
            subHeaderPlaceholder={'Website'}
            logoSettings={accountLogoSettings}
            avatarName={account?.name}
            canEdit={isAccountOwner}
            routeKey={`assets/${account.appKey}/${ACCOUNT_LOGO_NAME}`}
            location={'Workspace'}
          />
          <WorkspaceInformation
            loading={loading}
            packageType={currentPackage?.type}
            accountName={accountName}
            accountUrl={accountUrl}
            companySize={companySize}
            companyVertical={companyVertical}
            companyUseCase={companyUseCase}
            multipassSecret={multipassSecret}
            isShopifyPlus={isShopifyPlus}
            updateMultipassSecret={setMultipassSecret}
            updateNumberOfEmployees={setCompanySize}
            updateCompanyVertical={setCompanyVertical}
            updateAccountName={setAccountName}
            updateAccountUrl={setAccountUrl}
            onUpdate={() => setIsUnsaved(true)}
            updateCompanyUseCase={updateCompanyUseCase}
            shouldUseCache={shouldUseCache}
            updateShouldUseCache={setShouldUseCache}
          />
        </MainContainer>
      </Content>
    </LayoutRoot>
  );
}

const LayoutRoot = styled(VerticalFlex)``;

const Content = styled(VerticalFlex)`
  width: 100%;
  height: 100%;
  padding: 32px;
`;

const MainContainer = styled(VerticalFlex)`
  gap: 40px;
  width: 420px;
`;

const StyledCircularProgress = styled(CircularProgress)`
  align-self: start;
  margin: 32px;
`;

export default MyWorkspacePage;
