import React, { useState } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import Utils from 'app/src/utils';
import ColorfulWrapper from 'app/src/basic_components/ColorfulWrapper';
import VerticalFlex, {
  VerticalFlexCentered,
} from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextBody, TextH1, TextSmall } from 'shared/react/components/basic/text/TextV2';
import shopifyInstallTolstoyImage from 'app/src/images/installation/shopify-install-tolstoy.jpg';
import {
  PrimaryButton,
  SecondaryButton,
} from 'shared/react/components/basic/button-v2/BasicButton';
import LinkButton from 'app/src/complex_components/LinkButton';
import StraightArrowRightIcon from 'app/src/images/creation_method_icons/StraightArrowRightIcon';
import RedirectToLink from 'app/src/images/RedirectToLink';
import { useApps } from 'app/src/context/AppsStore';
import useAccountInstallation from 'app/src/hooks/useAccountInstallation';
import { LIQUID_FILENAMES } from 'shared/react/constants/tolstoy.constants';
import LoadingIndicator from 'shared/react/components/basic/LoadingIndicator';
import StandardModal from 'app/src/pages/modals/StandardModal';
import useNavigation from 'app/src/hooks/useNavigation';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

const InstallationModal = ({ closeModal }) => {
  const [{ selectedApp }] = useApps();
  const [{ toggleAccountInstallation }] = useAccountInstallation(selectedApp?.id);
  const [isOpenThemeClicked, setIsOpenThemeClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { navigateToAccountInstall } = useNavigation();

  const handleOpenTheme = () => {
    setIsOpenThemeClicked(true);

    const liquidFilename = LIQUID_FILENAMES.tvChannelButton;
    const deepLink = Utils.getAppEmbedDeepLink(selectedApp?.appUrl, liquidFilename);
    Utils.openInNewTab(deepLink);
  };

  const handleSetComplete = async () => {
    setIsLoading(true);
    await toggleAccountInstallation();
    closeModal();
  };

  const handleSkipInstallation = () => {
    closeModal();
  };

  const handleUseCode = async () => {
    setIsLoading(true);
    await toggleAccountInstallation();
    navigateToAccountInstall('#code');
    closeModal();
  };

  const ButtonComponent = isOpenThemeClicked ? OpenThemeUsedButton : OpenThemeButton;

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <StandardModal fullscreen={true} open={true} excludeXButton={true}>
      <ColorfulWrapper>
        <LayoutRoot>
          {isLoading && (
            <LoadingOverlay>
              <LoadingIndicator size={80} />
            </LoadingOverlay>
          )}
          <Content>
            <Wrapper>
              <TextH1>Setup guide</TextH1>
              <DescriptionText>
                To finish your set up, please enable Tolstoy on your active theme by clicking the
                button below and then
                <br />
                <strong>clicking “Save” in your Shopify admin.</strong>
              </DescriptionText>
              <VerticalFlexCentered>
                <ButtonComponent onClick={handleOpenTheme}>
                  Open my theme
                  <RedirectToLink />
                </ButtonComponent>
                <UseCodeInstead>
                  <TextSmall>or</TextSmall>
                  <LinkButton text="use code instead →" onClick={handleUseCode} underlined />
                </UseCodeInstead>
              </VerticalFlexCentered>
              <img
                src={shopifyInstallTolstoyImage}
                width="100%"
                alt="Shopify Tolstoy installation"
              />
              <ButtonsContainer>
                {isOpenThemeClicked && (
                  <SetCompleteButton onClick={handleSetComplete}>
                    I&apos;ve enabled Tolstoy
                  </SetCompleteButton>
                )}
                <LinkButton
                  text="Skip"
                  onClick={handleSkipInstallation}
                  icon={<StyledStraightArrowRightIcon />}
                  iconPosition="right"
                />
              </ButtonsContainer>
            </Wrapper>
          </Content>
        </LayoutRoot>
      </ColorfulWrapper>
    </StandardModal>
  );
};

const LayoutRoot = styled.div`
  position: relative;
  min-height: 100vh;
  overflow-y: auto;
  background: ${({ theme }) => theme.colors.neutralLight};
`;

const Content = styled(VerticalFlex)`
  width: 100%;
  padding: 32px;
  align-items: center;
`;

const Wrapper = styled(VerticalFlex)`
  width: 900px;
  gap: 32px;
  align-items: center;
`;

const DescriptionText = styled(TextBody)`
  text-align: center;
`;

const ButtonsContainer = styled(VerticalFlex)`
  width: 100%;
  align-items: flex-end;
  gap: 16px;
`;

const OpenThemeButton = styled(PrimaryButton)`
  width: 300px;

  & svg path {
    fill: white;
  }
`;

const OpenThemeUsedButton = styled(SecondaryButton)`
  width: 300px;
`;

const SetCompleteButton = styled(PrimaryButton)`
  width: 200px;
`;

const StyledStraightArrowRightIcon = styled(StraightArrowRightIcon)`
  & path {
    fill: ${({ theme }) => theme.colors.black};
  }
`;

const LoadingOverlay = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 400px;
  background: ${({ theme }) => rgba(theme.colors.white, 0.8)};
  z-index: 1;
`;

const UseCodeInstead = styled(HorizontalFlex)`
  margin-top: 8px;
  gap: 4px;
`;

export default InstallationModal;
