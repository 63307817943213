import React from 'react';
import styled from 'styled-components';
import EditorHeader from '../shared/EditorHeader';
import OnOffToggleItem from '../shared/OnOffToggleItem';
import EditorMainContainer from '../shared/EditorMainContainer';
import { useLookAndFeelContext } from '../../../LookAndFeelContext';
import {
  PRODUCT_DETAILS_SALES_BADGE_BACKGROUND_COLOR_PROPERTY_KEY,
  PRODUCT_DETAILS_SALES_BADGE_BORDER_COLOR_PROPERTY_KEY,
  PRODUCT_DETAILS_SALES_BADGE_BORDER_ENABLED_PROPERTY_KEY,
  PRODUCT_DETAILS_SALES_BADGE_BORDER_WIDTH_PROPERTY_KEY,
  PRODUCT_DETAILS_SALES_BADGE_ENABLED_PROPERTY_KEY,
  PRODUCT_DETAILS_SALES_BADGE_FONT_COLOR_PROPERTY_KEY,
  PRODUCT_DETAILS_SALES_BADGE_TEXT_PROPERTY_KEY,
} from '../../../LookAndFeelPage.constants';
import EditorColorInputItem from '../shared/EditorColorInputItem';
import EditorTextInputItem from '../shared/EditorTextInputItem';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import EditorSliderItem from '../shared/EditorSliderItem';
import EditorOnOffToggleItem from '../shared/EditorOnOffToggleItem';

const ProductDetailsSalesBadgeEditor = () => {
  const {
    customizationSettings: {
      playerSettings: {
        productDetailsSalesBadgeEnabled,
        productDetailsSalesBadgeText,
        productDetailsSalesBadgeFontColor,
        productDetailsSalesBadgeBackgroundColor,
        productDetailsSalesBadgeBorderEnabled,
        productDetailsSalesBadgeBorderWidth,
        productDetailsSalesBadgeBorderColor,
      },
    },
    setPlayerSettingsProperty,
  } = useLookAndFeelContext();

  const onEnableSalesBadge = () => {
    setPlayerSettingsProperty(
      PRODUCT_DETAILS_SALES_BADGE_ENABLED_PROPERTY_KEY,
      !productDetailsSalesBadgeEnabled
    );
  };

  const onTextChange = value => {
    setPlayerSettingsProperty(PRODUCT_DETAILS_SALES_BADGE_TEXT_PROPERTY_KEY, value);
  };

  const onColorChange = value => {
    setPlayerSettingsProperty(PRODUCT_DETAILS_SALES_BADGE_FONT_COLOR_PROPERTY_KEY, value);
  };

  const onBackgroundColorChange = value => {
    setPlayerSettingsProperty(PRODUCT_DETAILS_SALES_BADGE_BACKGROUND_COLOR_PROPERTY_KEY, value);
  };

  const onEnableBorder = () => {
    setPlayerSettingsProperty(
      PRODUCT_DETAILS_SALES_BADGE_BORDER_ENABLED_PROPERTY_KEY,
      !productDetailsSalesBadgeBorderEnabled
    );
  };

  const onBorderWidthChange = (event, value) => {
    setPlayerSettingsProperty(PRODUCT_DETAILS_SALES_BADGE_BORDER_WIDTH_PROPERTY_KEY, value);
  };

  const onBorderColorChange = value => {
    setPlayerSettingsProperty(PRODUCT_DETAILS_SALES_BADGE_BORDER_COLOR_PROPERTY_KEY, value);
  };

  return (
    <LayoutRoot>
      <EditorHeader title="Sales badge" isWhite={!productDetailsSalesBadgeEnabled}>
        <OnOffToggleItem checked={productDetailsSalesBadgeEnabled} onChange={onEnableSalesBadge} />
      </EditorHeader>
      <EditorMainContainer shouldHide={!productDetailsSalesBadgeEnabled}>
        <EditorTextInputItem
          placeholder="Sale"
          text="Text"
          value={productDetailsSalesBadgeText}
          onChange={onTextChange}
        />
        <EditorColorInputItem
          text="Text color"
          color={productDetailsSalesBadgeFontColor}
          onChange={onColorChange}
        />
        <EditorColorInputItem
          text="Background color"
          color={productDetailsSalesBadgeBackgroundColor}
          onChange={onBackgroundColorChange}
        />
        <EditorOnOffToggleItem
          text="Border"
          checked={productDetailsSalesBadgeBorderEnabled}
          onChange={onEnableBorder}
        />
        {productDetailsSalesBadgeBorderEnabled && (
          <EditorSliderItem
            onChange={onBorderWidthChange}
            text="Border thickness"
            value={productDetailsSalesBadgeBorderWidth}
            min={1}
            max={10}
          />
        )}
        {productDetailsSalesBadgeBorderEnabled && (
          <EditorColorInputItem
            text="Border color"
            color={productDetailsSalesBadgeBorderColor}
            onChange={onBorderColorChange}
          />
        )}
      </EditorMainContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

export default ProductDetailsSalesBadgeEditor;
