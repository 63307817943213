import React from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import styled from 'styled-components';

const Textarea = props => {
  return <CustomTextArea inputProps={{ maxLength: 500 }} {...props} />;
};

const CustomTextArea = styled(TextareaAutosize)`
  padding: 18.5px 14px;
  border-radius: 4px;
  border: 0.5px solid #c4c4c4;
`;

export default Textarea;
