const slash = '/';
const stepIdParameter = ':stepId';
const projectIdParameter = ':projectId';
const sessionIdParameter = ':sessionId?';
const sourceParameter = ':source?';
const stepIdParameterOptional = ':stepId?';
const projectIdParameterOptional = ':projectId?';
const folderIdParameterOptional = ':folderId?';
const stepIdWithSlashParameter = slash + stepIdParameter;
const projectIdWithSlashParameter = slash + projectIdParameter;
const sessionIdWithSlashParameter = slash + sessionIdParameter;
const stageParameterOptional = ':stage?';
const dashboardBaseName = 'dashboard';
const videosV2BaseName = 'videos';
const carouselsBaseName = 'carousels';
const projectsV2BaseName = 'onsite';
const offsiteBaseName = 'offsite';
const shopAppBaseName = 'shop-app';
const threadsV2BaseName = 'thread';
const analyticsV2BaseName = 'analytics';
const accountBaseName = 'account';
const myProfileBaseName = 'my-profile';
const teamMemberNotificationsBaseName = 'team-member-notification';
const plansBaseName = 'plans';
const billingBaseName = 'billing';
const workspaceBaseName = 'workspace';
const teamBaseName = 'team';
const brandingBaseName = 'branding';
const accountNotificationsBaseName = 'account-notifications';
const inboxBaseName = 'inbox';
const homepageBaseName = 'home';
const productsBaseName = 'products';
const aiTaggingBaseName = 'ai-tagging';
const templatesBaseName = 'templates';
const audienceBaseName = 'audience';
const editStepBaseName = 'edit-step';
const editSubtitlesBaseName = 'edit-subtitles';
const libraryBaseName = 'library';
const videolibraryBaseName = 'video_library';
const importLinkBaseName = 'import-link';
const analyticsBaseName = 'analytics';
const accountAnalyticsBaseName = 'analytics';
const settingsBaseName = 'settings';
const leadFormBaseName = 'lead-form';
const reachedEndBaseName = 'reached-end';
export const responsesBaseName = 'responses';
const generalBaseName = 'general';
const notificationsBaseName = 'notifications';
const lookAndFeelBaseName = 'look-and-feel';
const designBaseName = 'design';
const installationBaseName = 'installation';
const trackingBaseName = 'tracking';
const rulesAndTriggersBaseName = 'rules-and-triggers';
const videoBaseName = 'video';
const audioBaseName = 'audio';
const ugcBaseName = 'ugc';
const syncTagsSuffix = 'sync-tags';
const myProfileSuffix = myProfileBaseName;
const teamMemberNotificationsSuffix = teamMemberNotificationsBaseName;
const workspaceSuffix = workspaceBaseName;
const installationSuffix = installationBaseName;
const trackingSuffix = trackingBaseName;
const plansSuffix = plansBaseName;
const billingSuffix = billingBaseName;
const teamSuffix = teamBaseName;
const brandingSuffix = brandingBaseName;
const accountNotificationsSuffix = accountNotificationsBaseName;
const generalSettingsSuffix = settingsBaseName + slash + generalBaseName;
const notificationSettingsSuffix = settingsBaseName + slash + notificationsBaseName;
const lookAndFeelSettingsSuffix = settingsBaseName + slash + lookAndFeelBaseName;
const rulesAndTriggersSettingsSuffix = settingsBaseName + slash + rulesAndTriggersBaseName;
const videoResponsesSuffix = responsesBaseName + slash + videoBaseName;
const audioResponsesSuffix = responsesBaseName + slash + audioBaseName;
const leadFormResponsesSuffix = responsesBaseName + slash + leadFormBaseName;
const reachedEndResponsesSuffix = responsesBaseName + slash + reachedEndBaseName;
const loginBaseName = 'login';
const logoutBaseName = 'logout';
const signupBaseName = 'signup';
const resetPasswordBaseName = 'reset-password';
const integrationsBaseName = 'integrations';
const teamMembersBaseName = 'team-members';
const builderBaseName = 'builder';
const quizBaseName = 'quiz';
const quizBaseNameSuffix = builderBaseName + slash + quizBaseName;
const productRecommendationReply = 'product-recommendation-reply';
const onboardingBaseName = 'onboarding';
const tolstoyMessageBaseName = 'message';
const chromeExtensionMessage = 'extension-upload';
const inviteInvalid = 'invite-invalid';
const createWorkspace = 'create-workspace';
const videoTrimmingBaseName = 'video-trim';
const videoTrimVideoId = ':videoId';
const canvasBaseName = 'canvas';
const downgradeBaseName = 'downgrade';
const videoFilesBaseName = 'video_files';
const closeWindowBaseName = 'close_window';
const videoInboxBaseName = 'video_inbox';
const shopHomeFeedBaseName = 'home-feed';
const errorBaseName = 'error';

export const projectTabs = {
  videos: stepIdParameterOptional,
  design: designBaseName,
  settings: settingsBaseName,
  rules: rulesAndTriggersBaseName,
  responses: responsesBaseName,
  installation: installationBaseName,
  quiz: quizBaseName,
};

// For each of the route groups below - the order is important. The most specific route (with the most parameters)
// should start first, and reduce itself as it goes.
class Routes {
  static getSlash() {
    return slash;
  }

  static getProjectIdParameter() {
    return projectIdParameterOptional;
  }

  static getStepIdOptionalParameter() {
    return stepIdParameterOptional;
  }

  static getStepParameter() {
    return stepIdParameter;
  }

  static getSessionIdParameter() {
    return sessionIdParameter;
  }

  static getRootRoute() {
    return slash;
  }

  static getCanvasRoute() {
    return slash + canvasBaseName;
  }

  static closeWindowAfterIntegrationRoute() {
    return slash + closeWindowBaseName;
  }

  static getDashboardBaseRoute() {
    return slash + dashboardBaseName;
  }

  static getErrorRoute() {
    return slash + errorBaseName;
  }

  static getDashboardV2BaseRoute() {
    return '';
  }

  static getDashbaordV2VideosRoute(source = sourceParameter) {
    return this.getDashboardV2BaseRoute() + slash + videosV2BaseName + slash + source;
  }

  static getDashboardCarouselRoute() {
    return this.getDashboardV2BaseRoute() + slash + carouselsBaseName;
  }

  static getDashboardLibraryRoute(source = sourceParameter) {
    return this.getDashboardV2BaseRoute() + slash + videolibraryBaseName + slash + source;
  }

  static getDashbaordV2InboxRoute() {
    return this.getDashboardV2BaseRoute() + slash + inboxBaseName;
  }

  static getHomepageRoute() {
    return this.getDashboardV2BaseRoute() + slash + homepageBaseName;
  }

  static getProductsRoute() {
    return this.getDashboardV2BaseRoute() + slash + productsBaseName;
  }

  static getAiTaggingRoute() {
    return this.getDashboardV2BaseRoute() + slash + aiTaggingBaseName;
  }

  static getOnboardingRoute() {
    return this.getHomepageRoute() + slash + onboardingBaseName;
  }

  static getOnboardingTemplatesRoute() {
    return this.getOnboardingRoute() + slash + templatesBaseName;
  }

  static getAudienceRoute() {
    return this.getDashboardV2BaseRoute() + slash + audienceBaseName;
  }

  static getOnboardingTemplatesStageRoute(stage = stageParameterOptional) {
    return this.getOnboardingRoute() + slash + templatesBaseName + slash + stage;
  }

  static getDashboardV2OnsiteRoute(folderId = folderIdParameterOptional) {
    return this.getDashboardV2BaseRoute() + slash + projectsV2BaseName + slash + folderId;
  }

  static getDashboardV2OnsiteCreateCaseRoute() {
    return this.getDashboardV2BaseRoute() + slash + projectsV2BaseName + slash + 'create';
  }

  static getDashboardV2OffsiteRoute(folderId = folderIdParameterOptional) {
    return this.getDashboardV2BaseRoute() + slash + offsiteBaseName + slash + folderId;
  }

  static getDashboardV2OffsiteCreateCaseRoute() {
    return this.getDashboardV2BaseRoute() + slash + offsiteBaseName + slash + 'create';
  }

  static getDashbaordV2UgcRoute(source = sourceParameter) {
    return this.getDashboardV2BaseRoute() + slash + ugcBaseName + slash + source;
  }

  static getDashboardV2DiscoverRoute() {
    return this.getDashboardV2BaseRoute() + slash + 'discover';
  }

  static getDashboardV2DiscoverProjectRoute(projectId = projectIdParameterOptional) {
    return this.getDashboardV2DiscoverRoute() + slash + projectId;
  }

  static getDashboardV2OnsiteProjectRoute(
    projectId = projectIdParameterOptional,
    folderId = folderIdParameterOptional
  ) {
    return this.getDashboardV2OnsiteRoute(folderId) + slash + projectId;
  }

  static getShopRoute() {
    return this.getDashboardV2BaseRoute() + slash + shopAppBaseName;
  }

  static getShopHomeFeedRoute() {
    return this.getShopRoute() + slash + shopHomeFeedBaseName;
  }

  static getShopAppProjectRoute(projectId = projectIdParameterOptional) {
    return this.getShopRoute() + slash + projectId;
  }

  static getDashboardV2OffsiteProjectRoute(
    projectId = projectIdParameterOptional,
    folderId = folderIdParameterOptional
  ) {
    return this.getDashboardV2OffsiteRoute(folderId) + slash + projectId;
  }

  static getProjectTabRoute(tab = projectTabs.videos, projectId) {
    const tabs = [
      this.getDashboardV2OnsiteProjectRoute(projectId),
      this.getDashboardV2DiscoverProjectRoute(projectId),
      this.getDashboardV2OffsiteProjectRoute(projectId),
      this.getShopAppProjectRoute(projectId),
    ];

    return tabs.map(route => {
      return `${route}/${tab}`;
    });
  }

  static getDashboardV2ThreadsRoute(route = sessionIdParameter) {
    return this.getDashboardV2BaseRoute() + slash + threadsV2BaseName + slash + route;
  }

  static getDashboardV2AnalyticsRoute() {
    return this.getDashboardV2BaseRoute() + slash + analyticsV2BaseName;
  }

  static getDashboardV2AccountSettingsRoute() {
    return this.getDashboardV2BaseRoute() + slash + analyticsV2BaseName;
  }

  static getDashboardV2IntegrationsRoute(focusedItem) {
    const focusedQueryString = focusedItem ? `?focused=${focusedItem}` : '';
    return this.getDashboardV2BaseRoute() + slash + integrationsBaseName + focusedQueryString;
  }

  static getDashboardV2AccountBaseRoute() {
    return this.getDashboardV2BaseRoute() + slash + accountBaseName;
  }

  static getDashboardV2MyProfileBaseRoute() {
    return this.getDashboardV2AccountBaseRoute() + slash + myProfileBaseName;
  }

  static getDashboardV2TeamMemberNotificationsRoute() {
    return this.getDashboardV2AccountBaseRoute() + slash + this.getTeamMemberNotificationsSuffix();
  }

  static getDashboardV2MyWorkspaceRoute() {
    return this.getDashboardV2AccountBaseRoute() + slash + this.getMyWorkspaceSuffix();
  }

  static getDashboardInstallationRoute() {
    return this.getDashboardV2AccountBaseRoute() + slash + this.getInstallationSuffix();
  }

  static getDashboardTrackingRoute() {
    return this.getDashboardV2AccountBaseRoute() + slash + this.getTrackingSuffix();
  }

  static getDashboardV2TeamRoute() {
    return this.getDashboardV2AccountBaseRoute() + slash + this.getTeamSuffix();
  }

  static getDashboardV2BrandingRoute() {
    return this.getDashboardV2AccountBaseRoute() + slash + this.getBrandingSuffix();
  }

  static getDashboardV2AccountNotificationsRoute() {
    return this.getDashboardV2AccountBaseRoute() + slash + this.getAccountNotificationsSuffix();
  }

  static getDashboardV2PlansRoute() {
    return this.getDashboardV2AccountBaseRoute() + slash + this.getPlansSuffix();
  }

  static getDashboardV2BillingRoute() {
    return this.getDashboardV2AccountBaseRoute() + slash + this.getBillingSuffix();
  }

  static getVideoFilesBaseRoute() {
    return slash + videoFilesBaseName;
  }

  static getVideoInboxBaseRoute() {
    return this.getVideoFilesBaseRoute() + slash + videoInboxBaseName;
  }

  static getTolstoyMessageBaseRoute() {
    return slash + tolstoyMessageBaseName + slash;
  }

  static getVideoTrimmingBaseRoute() {
    return slash + videoTrimmingBaseName;
  }

  static getVideoTrimmingRoute() {
    return this.getVideoTrimmingBaseRoute() + slash + videoTrimVideoId;
  }

  static getTolstoyMessageExtensionRoute() {
    return this.getTolstoyMessageBaseRoute() + chromeExtensionMessage;
  }

  static getTolstoyMessageInviteInvalidRoute() {
    return this.getTolstoyMessageBaseRoute() + inviteInvalid;
  }

  static getTolstoyMessageCreateWorkspaceRoute() {
    return this.getTolstoyMessageBaseRoute() + createWorkspace;
  }

  static getAccountBaseRoute() {
    return slash + accountBaseName;
  }

  static getInboxWithSessionIdRoute() {
    return slash + inboxBaseName + sessionIdWithSlashParameter;
  }

  static getInboxBaseRoute() {
    return slash + inboxBaseName;
  }

  static getDowngradeBaseRoute() {
    return slash + downgradeBaseName;
  }

  static getDashboardWithSlashBaseRoute() {
    return slash + dashboardBaseName + slash;
  }

  static getDashboardWithProjectIdRoute(projectId = projectIdParameterOptional) {
    return this.getDashboardWithSlashBaseRoute() + projectId;
  }

  static getBuilderRoute() {
    return this.getDashboardWithProjectIdRoute() + slash + builderBaseName;
  }

  static getBuilderV2Route() {
    return (
      this.getDashboardWithProjectIdRoute() +
      slash +
      builderBaseName +
      slash +
      stepIdParameterOptional
    );
  }

  static getBuilderWithNewCreationFlowRoute() {
    return (
      this.getDashboardWithProjectIdRoute() +
      slash +
      builderBaseName +
      slash +
      stepIdParameterOptional
    );
  }

  static getBuilderV2RouteWithoutStepId() {
    return this.getDashboardWithProjectIdRoute() + slash + builderBaseName;
  }

  static getQuizRoute() {
    return this.getBuilderRoute() + slash + quizBaseName;
  }

  static getQuizV2Route() {
    return this.getDashboardWithProjectIdRoute() + slash + `${quizBaseName}-v2`;
  }

  static getAccountAnalyticsRoute() {
    return slash + accountAnalyticsBaseName;
  }

  static getSettingsRoute() {
    return this.getDashboardWithProjectIdRoute() + slash + generalSettingsSuffix;
  }

  static getLeadFormConfigurationRoute() {
    return this.getDashboardWithProjectIdRoute() + slash + leadFormBaseName;
  }

  static getResponsesRoute() {
    return this.getDashboardWithProjectIdRoute() + slash + responsesBaseName;
  }

  static getResponsesTabsRoutes() {
    return [
      Routes.getResponsesRoute(),
      Routes.getVideoResponsesRoute(),
      Routes.getAudioResponsesRoute(),
      Routes.getLeadFormResponsesRoute(),
      Routes.getReachedEndResponsesRoute(),
    ];
  }

  static getResponseSuffixesThatSupportSessionFetch() {
    return [
      Routes.getVideoResponsesSuffix(),
      Routes.getAudioResponsesSuffix(),
      Routes.getLeadFormResponsesSuffix(),
      Routes.getReachedEndResponsesSuffix(),
    ];
  }

  static getNotificationsSettingsRoute() {
    return this.getDashboardWithProjectIdRoute() + slash + notificationSettingsSuffix;
  }

  static getLookAndFeelSettingsRoute(projectId) {
    return this.getDashboardWithProjectIdRoute(projectId) + slash + lookAndFeelSettingsSuffix;
  }

  static getRulesPageSettingsRoute() {
    return this.getDashboardWithProjectIdRoute() + slash + rulesAndTriggersSettingsSuffix;
  }

  static getVideoResponsesRoute() {
    return this.getDashboardWithProjectIdRoute() + slash + videoResponsesSuffix;
  }

  static getAudioResponsesRoute() {
    return this.getDashboardWithProjectIdRoute() + slash + audioResponsesSuffix;
  }

  static getLeadFormResponsesRoute() {
    return this.getDashboardWithProjectIdRoute() + slash + leadFormResponsesSuffix;
  }

  static getReachedEndResponsesRoute() {
    return this.getDashboardWithProjectIdRoute() + slash + reachedEndResponsesSuffix;
  }

  static getGeneralSettingsSuffix() {
    return generalSettingsSuffix;
  }

  static getNotificationsSettingsSuffix() {
    return notificationSettingsSuffix;
  }

  static getLookAndFeelSettingsSuffix() {
    return lookAndFeelSettingsSuffix;
  }

  static getRulesPageSettingsSuffix() {
    return rulesAndTriggersSettingsSuffix;
  }

  static getVideoResponsesSuffix() {
    return videoResponsesSuffix;
  }

  static getAudioResponsesSuffix() {
    return audioResponsesSuffix;
  }

  static getLeadFormResponsesSuffix() {
    return leadFormResponsesSuffix;
  }

  static getQuizBaseNameSuffix() {
    return quizBaseNameSuffix;
  }

  static getReachedEndResponsesSuffix() {
    return reachedEndResponsesSuffix;
  }

  static getLoginRoute() {
    return slash + loginBaseName;
  }

  static getLogoutRoute() {
    return slash + logoutBaseName;
  }

  static getSignupRoute() {
    return slash + signupBaseName;
  }

  static getResetPasswordRoute() {
    return slash + resetPasswordBaseName;
  }

  static getEditStepBaseRoute() {
    return slash + editStepBaseName;
  }

  static getSubtitlesEditorBaseRoute() {
    return slash + editSubtitlesBaseName;
  }

  static getImportLinkBaseRoute() {
    return slash + importLinkBaseName;
  }

  static getOnboardingBaseRoute() {
    return slash + onboardingBaseName;
  }

  static getRoutes(baseRoute) {
    return [
      baseRoute + projectIdWithSlashParameter + stepIdWithSlashParameter,
      baseRoute + projectIdWithSlashParameter,
      baseRoute,
    ];
  }

  static getEditStepRoutes() {
    return this.getRoutes(this.getEditStepBaseRoute());
  }

  static getSubtitlesEditorRoutes() {
    return this.getRoutes(this.getSubtitlesEditorBaseRoute());
  }

  static getImportLinkRoutes() {
    return this.getRoutes(this.getImportLinkBaseRoute());
  }

  static getIntegrationsRoute() {
    return slash + integrationsBaseName;
  }

  static getTeamMembersRoute() {
    return this.getSlash() + teamMembersBaseName;
  }

  static getProductRecommendationReplyRoute() {
    return `/${productRecommendationReply}`;
  }

  static getMyProfileSuffix() {
    return myProfileSuffix;
  }

  static getTeamMemberNotificationsSuffix() {
    return teamMemberNotificationsSuffix;
  }

  static getMyProfileRoute() {
    return this.getAccountBaseRoute() + slash + this.getMyProfileSuffix();
  }

  static getTeamMemberNotificationsRoute() {
    return this.getAccountBaseRoute() + slash + this.getTeamMemberNotificationsSuffix();
  }

  static getMyWorkspaceSuffix() {
    return workspaceSuffix;
  }

  static getMyWorkspaceRoute() {
    return this.getAccountBaseRoute() + slash + this.getMyWorkspaceSuffix();
  }

  static getInstallationSuffix() {
    return installationSuffix;
  }

  static getTrackingSuffix() {
    return trackingSuffix;
  }

  static getTeamSuffix() {
    return teamSuffix;
  }

  static getTeamRoute() {
    return this.getAccountBaseRoute() + slash + this.getTeamSuffix();
  }

  static getBrandingSuffix() {
    return brandingSuffix;
  }

  static getSyncTagsSuffix() {
    return syncTagsSuffix;
  }

  static getBrandingRoute() {
    return this.getAccountBaseRoute() + slash + this.getBrandingSuffix();
  }

  static getSyncTagsRoute() {
    return this.getAccountBaseRoute() + slash + this.getSyncTagsSuffix();
  }

  static getAccountNotificationsSuffix() {
    return accountNotificationsSuffix;
  }

  static getAccountNotificationsRoute() {
    return this.getAccountBaseRoute() + slash + this.getAccountNotificationsSuffix();
  }

  static getPlansSuffix() {
    return plansSuffix;
  }

  static getPlansRoute() {
    return this.getAccountBaseRoute() + slash + this.getPlansSuffix();
  }

  static getBillingSuffix() {
    return billingSuffix;
  }

  static getBillingRoute() {
    return this.getAccountBaseRoute() + slash + this.getBillingSuffix();
  }

  static getRouteWithProjectId(route, projectId) {
    return route.replace(Routes.getProjectIdParameter(), projectId);
  }

  static getLookAndFeelRouteWithProjectId(projectId) {
    return this.getRouteWithProjectId(this.getLookAndFeelSettingsRoute(), projectId);
  }

  static getV2ProjectRoutes() {
    return [...this.getProjectTabRoute(), ...this.getProjectTabRoute('')];
  }

  static getV2DashboardRoutes() {
    return [
      this.getDashboardV2DiscoverRoute(),
      this.getDashboardV2OnsiteRoute(),
      this.getDashbaordV2VideosRoute(),
      this.getDashboardV2OffsiteRoute(),
      this.getDashboardV2BaseRoute(),
      this.getDashboardV2AnalyticsRoute(),
      this.getDashboardV2ThreadsRoute(),
      this.getDashboardV2IntegrationsRoute(),
      this.getDashboardV2MyProfileBaseRoute(),
      this.getDashboardV2PlansRoute(),
      this.getDashboardV2BillingRoute(),
      this.getDashboardV2AccountNotificationsRoute(),
      this.getDashboardV2BrandingRoute(),
      this.getDashboardV2TeamRoute(),
      this.getDashboardV2MyWorkspaceRoute(),
      this.getDashboardInstallationRoute(),
      this.getDashboardTrackingRoute(),
      this.getDashboardV2TeamMemberNotificationsRoute(),
      this.getDashboardBaseRoute(),
      this.getShopRoute(),
      this.getShopHomeFeedRoute(),
      this.getHomepageRoute(),
      this.getOnboardingRoute(),
      this.getOnboardingTemplatesRoute(),
      this.getOnboardingTemplatesStageRoute(),
      this.getSyncTagsRoute(),
      this.getProductsRoute(),
      this.getAiTaggingRoute(),
      this.getDashboardV2OnsiteCreateCaseRoute(),
      this.getAudienceRoute(),
      this.getDashboardCarouselRoute(),
      this.getDashbaordV2UgcRoute(),
      this.getDashboardLibraryRoute(),
    ];
  }

  static getAccountRoutes() {
    return [
      this.getMyProfileRoute(),
      this.getTeamMemberNotificationsRoute(),
      this.getMyWorkspaceRoute(),
      this.getTeamRoute(),
      this.getAccountNotificationsRoute(),
      this.getPlansRoute(),
      this.getBillingRoute(),
      this.getBrandingRoute(),
    ];
  }

  static getRoutesWithoutToolbar() {
    return [editStepBaseName, editSubtitlesBaseName, libraryBaseName, productRecommendationReply];
  }

  static getRoutesWithBackButton() {
    return [
      editStepBaseName,
      editSubtitlesBaseName,
      libraryBaseName,
      integrationsBaseName,
      productRecommendationReply,
      importLinkBaseName,
    ];
  }

  static isVideosDashboard(pathname) {
    return (
      pathname.startsWith(Routes.getDashbaordV2VideosRoute('')) ||
      pathname.startsWith(Routes.getDashboardLibraryRoute(''))
    );
  }

  static getAuthRoutes() {
    return [
      this.getLoginRoute(),
      this.getSignupRoute(),
      this.getResetPasswordRoute(),
      this.getTolstoyMessageInviteInvalidRoute(),
    ];
  }
}

export default Routes;
