import React from 'react';

const PPStarEmpty = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
    <path
      fill="#fffff"
      d="M38 15H23.485L19 .757 14.515 15H0l11.743 8.418-4.486 13.911L19 28.536l11.742 8.863-4.485-13.994zM26.925 32.02l-7.92-5.978-7.928 5.936 3.01-9.332-7.885-5.653h9.775L19 7.393l3.023 9.6h9.768l-7.876 5.638z"
    />
  </svg>
);

export default PPStarEmpty;
