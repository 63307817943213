import { useEffect, useState } from 'react';
import { useSelectedApp } from 'src/context/AppsStore';
import Utils from 'src/utils';

const useBestSellingProducts = () => {
  const [selectedApp] = useSelectedApp();
  const [bestSellingProductIds, setBestSellingProductIds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleBestSellingProductsChange = () => {
    const { bestSellingProducts } = Utils.safeParse(selectedApp.data);
    if (!bestSellingProducts) {
      setIsLoading(false);
      return;
    }

    const { bestSellingProductsMap, isDoneCalculating } = bestSellingProducts;
    if (!isDoneCalculating) {
      setIsLoading(false);
      return;
    }

    const bestSellingProductIds = Object.keys(bestSellingProductsMap).sort((a, b) => {
      return bestSellingProductsMap[b] - bestSellingProductsMap[a];
    });

    setBestSellingProductIds(bestSellingProductIds);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!selectedApp) {
      setIsLoading(false);
      return;
    }

    handleBestSellingProductsChange();
  }, [selectedApp]);

  return {
    bestSellingProductIds,
    isLoading,
  };
};

export default useBestSellingProducts;
