import EditorNumericInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorNumericInputItem';
import React from 'react';
import {
  STORIES_ITEM_MAX_FIXED_SIZE,
  STORIES_ITEMS_SHAPE_TYPES,
  STORIES_ITEMS_SIZE_TYPES,
  STORIES_LAYOUT_ITEMS_RECTANGLE_SIZE_HEIGHT_PROPERTY_KEY,
  STORIES_LAYOUT_ITEMS_RECTANGLE_SIZE_WIDTH_PROPERTY_KEY,
  STORIES_LAYOUT_ITEMS_SIZE_PROPERTY_KEY,
  STORIES_MIN_ITEM_FIXED_SIZE,
} from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';

const FeedStoriesLayoutSizeEditor = () => {
  const {
    customizationSettings: {
      widgetSettings: {
        storiesItemsSizeType: itemsSizeType,
        storiesCircleSize: fixedItemsSize,
        storiesItemsShape: itemsShape,
        [STORIES_LAYOUT_ITEMS_RECTANGLE_SIZE_WIDTH_PROPERTY_KEY]: rectangleWidth,
        [STORIES_LAYOUT_ITEMS_RECTANGLE_SIZE_HEIGHT_PROPERTY_KEY]: rectangleHeight,
      },
    },
    setWidgetSettingsProperty,
  } = useLookAndFeelContext();

  const isFixedSizeType = itemsSizeType === STORIES_ITEMS_SIZE_TYPES.fixed;
  const isCircleShape = itemsShape === STORIES_ITEMS_SHAPE_TYPES.circle;

  const onStoriesSizeChange = (value: number) => {
    setWidgetSettingsProperty(STORIES_LAYOUT_ITEMS_SIZE_PROPERTY_KEY, value);
  };

  const onStoriesWidthChange = (value: number) => {
    const validatedValue = Math.min(
      Math.max(value, STORIES_MIN_ITEM_FIXED_SIZE),
      STORIES_ITEM_MAX_FIXED_SIZE
    );

    setWidgetSettingsProperty(
      STORIES_LAYOUT_ITEMS_RECTANGLE_SIZE_WIDTH_PROPERTY_KEY,
      validatedValue
    );
  };
  const onStoriesHeightChange = (value: number) => {
    setWidgetSettingsProperty(STORIES_LAYOUT_ITEMS_RECTANGLE_SIZE_HEIGHT_PROPERTY_KEY, value);
  };

  const onStoriesSizeBlur = (value: number) => {
    onStoriesSizeChange(value);
  };

  if (!isFixedSizeType) {
    return null;
  }

  if (!isCircleShape) {
    return (
      <>
        <EditorNumericInputItem
          text="Width"
          suffix="px"
          onChange={onStoriesWidthChange}
          value={rectangleWidth}
          max={STORIES_ITEM_MAX_FIXED_SIZE}
        />
        <EditorNumericInputItem
          text="Height"
          suffix="px"
          onChange={onStoriesHeightChange}
          value={rectangleHeight}
          max={STORIES_ITEM_MAX_FIXED_SIZE}
        />
      </>
    );
  }

  return (
    <EditorNumericInputItem
      text="Size"
      suffix="px"
      onChange={onStoriesSizeChange}
      value={fixedItemsSize}
    />
  );
};

export default FeedStoriesLayoutSizeEditor;
