import styled from 'styled-components';
import Gap16HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap16HorizontalFlexWrap';
import Types from 'shared/react/theme/Types';
import React from 'react';
import Button from 'shared/react/components/complex/Button';

export const WidgetSettings = ({
  showRulesAndTriggers,
  handleCustomizeAppearance,
  handleTriggerRules,
  className,
}) => {
  return (
    <LayoutRoot className={className}>
      <PromotionButton fullWidth onClick={handleCustomizeAppearance} type={Types.Secondary}>
        Customize appearance
      </PromotionButton>
      {showRulesAndTriggers && (
        <PromotionButton fullWidth onClick={handleTriggerRules} type={Types.Ghost}>
          Set rules & triggers
        </PromotionButton>
      )}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16HorizontalFlexWrap)`
  margin-top: 26px;
  width: 100%;
`;

const PromotionButton = styled(Button)`
  width: 192px;
`;

export default WidgetSettings;
