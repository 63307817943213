import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextBody } from 'shared/react/components/complex/Text';
import DropDown from 'src/basic_components/DropDown';
import { CURRENCY_SYMBOL_MAP } from 'shared/react/constants/currency.constants';
import Utils from 'src/utils';

type Props = {
  isLoading: boolean;
  app: any;
  updateIntegration: (appData) => void;
};

const ImportFileCurrency: FC<Props> = ({ updateIntegration, app, isLoading }) => {
  const [currency, setCurrency] = useState<string>('');

  useEffect(() => {
    const appData = Utils.safeParse(app?.data);
    setCurrency(appData?.currency);
  }, [app]);

  const onClick = (code: string) => () => {
    if (isLoading) {
      return;
    }

    setCurrency(code);

    updateIntegration({
      data: {
        currency: code,
      },
    });
  };

  if (!app?.active) {
    return null;
  }

  return (
    <Container>
      <Title>Default currency code</Title>
      <CurrencyDropDown
        menuItems={Object.keys(CURRENCY_SYMBOL_MAP).map(code => ({
          title: code,
          key: code,
          onClick: onClick(code),
        }))}
      >
        {currency}
      </CurrencyDropDown>
    </Container>
  );
};

const Container = styled(VerticalFlex)`
  align-items: start;
  gap: 4px;
`;

const CurrencyDropDown = styled(DropDown)`
  border: 1px solid ${({ theme }) => theme.colors.neutralGray};
  border-radius: 4px;
  padding: 12px;
  gap: 12px;

  > div {
    color: ${({ theme }) => theme.colors.neutralDark};
  }

  > img {
    opacity: 0.7;
  }
`;

const Title = styled(TextBody)``;

export default ImportFileCurrency;
