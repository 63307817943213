import StandardModal from 'app/src/pages/modals/StandardModal';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from 'shared/react/components/complex/Button';
import TrophyIcon from 'app/src/images/TrophyIcon';
import Gap16HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap16HorizontalFlexWrap';
import { TextBody } from 'shared/react/components/complex/Text';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { useAccount } from 'app/src/context/AccountStore';
import { track } from 'app/src/helpers/Tracker';
import { useBilling } from 'app/src/hooks/useBillings';
import Utils from 'app/src/utils';
import useIsMounted from 'shared/react/hooks/useIsMounted';

const TITLE = 'Request to upgrade';
const BODY_PARTS = [
  'Only the account owner',
  'can upgrade this account. Click below to ask them to upgrade now and get unlimited recordings and other premium features.',
];
const BODY_REQUEST_SENT = 'We’ve sent your request to the owner of the account';
const BUTTON_TEXT = 'Send request now';

const getBodyText = (accountOwner, requestSent) => {
  if (requestSent) {
    return BODY_REQUEST_SENT;
  }
  if (!accountOwner?.email) {
    return BODY_PARTS.join(' ');
  }

  return BODY_PARTS.join(` (${accountOwner.email}) `);
};

const RequestOwnerToUpgradePlanModal = ({ closeModal }) => {
  const [{ teamMembers }] = useAccount();
  const { sendUpgradePlanRequestToOwner } = useBilling();
  const [requestSent, setRequestSend] = useState(!!Utils.getRequestToUpgradePlanDate());
  const [loading, setLoading] = useState(false);
  const isMounted = useIsMounted();
  const accountOwner = teamMembers?.find(({ role }) => role === 'owner');

  useEffect(() => {
    track('Request Owner To Upgrade Plan Modal Loaded');
  }, []);

  const sendRequestToUpgrade = async () => {
    setLoading(true);
    await sendUpgradePlanRequestToOwner();
    track('Request Owner To Upgrade Plan Sent');

    Utils.setRequestToUpgradePlanDate();

    if (isMounted) {
      setRequestSend(true);
      setLoading(false);
    }
  };

  const onClose = () => {
    track('Request Owner To Upgrade Plan Modal Closed');
    closeModal();
  };

  return (
    <StandardModal onClose={onClose} removeDefaultPadding={true} width="424px" open={true}>
      <StyledRequestOwnerToUpgradePlanModal>
        <RequestToUpgradePlanContent>
          <RequestToUpgradePlanHeader>
            <TrophyIcon />
            <RequestToUpgradePlanTitle>{TITLE}</RequestToUpgradePlanTitle>
          </RequestToUpgradePlanHeader>
          <RequestToUpgradePlanBody>
            {getBodyText(accountOwner, requestSent)}
          </RequestToUpgradePlanBody>
        </RequestToUpgradePlanContent>
        <RequestToUpgradePlanButton
          onClick={sendRequestToUpgrade}
          disabled={requestSent}
          loading={loading}
        >
          {BUTTON_TEXT}
        </RequestToUpgradePlanButton>
      </StyledRequestOwnerToUpgradePlanModal>
    </StandardModal>
  );
};

export default RequestOwnerToUpgradePlanModal;

const StyledRequestOwnerToUpgradePlanModal = styled(VerticalFlex)`
  padding: 24px;
  gap: 48px;
`;

const RequestToUpgradePlanContent = styled(Gap8VerticalFlex)``;

const RequestToUpgradePlanHeader = styled(Gap16HorizontalFlexWrap)``;

const RequestToUpgradePlanTitle = styled(TextBody)`
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.black};
`;

const RequestToUpgradePlanBody = styled(TextBody)`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
`;

const RequestToUpgradePlanButton = styled(Button)``;
