import React from 'react';
import styled from 'styled-components';
import FolderMenuItem from 'app/src/pages/dashboard/components/folder/FolderMenuItem';
import InstagramSmallIcon from 'app/src/images/integrations/InstagramSmallIcon';
import {
  DROPBOX,
  GOOGLE_DRIVE,
  INSTAGRAM_GRAPH,
  SYNTHESIA,
  TIKTOK,
  YOUTUBE,
} from 'app/src/constants/intergrations.constants';
import TiktokSmallIcon from 'app/src/images/integrations/TiktokSmallIcon';
import useNavigation from 'app/src/hooks/useNavigation';
import { RECORDINGS, UPLOAD } from 'app/src/constants/recordingTypes.constants';
import UploadFileIcon from 'app/src/images/creation_method_icons/UploadFileIcon';
import OutlinedVideoIcon from 'app/src/images/video_icon/OutlinedVideoIcon';
import useIntegrationsVideos from 'app/src/hooks/useIntegrationsVideos';
import SidebarIntegrationSideButton, {
  SidebarIntegrationSideButtonLayoutRoot,
} from 'app/src/pages/dashboard/pages/videos/components/side-bar/SidebarIntegrationSideButton';
import { useApps } from 'app/src/context/AppsStore';
import { App } from 'app/src/types/app';
import { WhereCondition } from 'app/src/pages/dashboard/utils/filterEntities';
import { isSourceSelected } from 'app/src/pages/dashboard/pages/videos/constants/videos.constants';
import {
  getDuplicateSource,
  SOURCES_UPLOAD_TYPE,
} from 'app/src/constants/videoSources.constants.js';
import SynthesiaSmallIcon from 'app/src/images/integrations/SynthesiaSmallIcon';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import GoogleDriveIcon from 'app/src/images/integrations/GoogleDriveIcon';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import {
  FEATURE_AI_VIDEOS,
  FEATURE_GOOGLE_DRIVE_INTEGRATION,
  FEATURE_YOUTUBE_INTEGRATION,
} from 'shared/react/constants/features.constants';
import SidebarIntegrationMenu, { SidebarIntegrationMenuLayoutRoot } from './SidebarIntegrationMenu';
import { AI_VIDEO } from 'app/src/constants/creation_method.constants';
import AiIcon from 'app/src/images/dashboard_v2/AiIcon';
import DropboxIcon from 'app/src/images/integrations/DropboxIcon';
import YoutubeSmallIcon from 'app/src/images/integrations/YoutubeSmallIcon';
import { ASSET_TYPE } from 'shared/react/services/assets';
import { getTypeFilterValue } from 'src/utils/filters.utils';

type Props = {
  unselectAll: () => void;
  shouldSidebarRedirect: boolean;
  videosFilters: any;
  setVideosFilters: (filters: WhereCondition) => void;
  onSelectSource?: (e: any, source: string | null) => void;
};

const VideoInboxSideBar = ({
  unselectAll,
  shouldSidebarRedirect,
  setVideosFilters,
  videosFilters,
  onSelectSource = () => {},
}: Props) => {
  const { navigateToDashboardLibrary, locationState } = useNavigation();
  const [{ initialized, shopify: isShopify }] = useApps();
  const { getFeatureEnabled } = useFeatureActions();
  const {
    tiktokApps,
    instagramApps,
    googleDriveProfile,
    synthesia,
    googleDrive,
    youtube,
    youtubeProfile,
    handleGoogleDriveConnect,
    handleYoutubeDisconnect,
    handleYoutubeConnect,
    handleInstagramConnect,
    handleTiktokConnect,
    handleInstagramDisconnect,
    handleTikTokDisconnect,
    handleSynthesiaDisconnect,
    handleGoogleDriveDisconnect,
  } = useIntegrationsVideos();

  const currentVideoSource = videosFilters.uploadType?.in?.[0];
  const currentAppId = videosFilters.appId?.eq;

  const setSelectedApp = (source: string | null, app?: App, e) => {
    unselectAll();

    if (shouldSidebarRedirect) {
      navigateToDashboardLibrary(source, locationState);
    }

    const currentUploadType = SOURCES_UPLOAD_TYPE[source];
    const duplicatedUploadTypes = currentUploadType?.map(getDuplicateSource);
    const uploadType = [...currentUploadType, ...duplicatedUploadTypes];
    const appFilters = app?.appExternalId ? { appExternalId: { in: [app?.appExternalId] } } : {};

    onSelectSource(e, source);
    setVideosFilters({
      folder: null,
      labels: null,
      uploadType: { in: uploadType },
      type: getTypeFilterValue(ASSET_TYPE.VIDEO),
      ...appFilters,
    });

    return;
  };

  const items = [
    {
      icon: <InstagramSmallIcon />,
      text: 'Instagram',
      isSelected: isSourceSelected(INSTAGRAM_GRAPH, currentVideoSource),
      key: INSTAGRAM_GRAPH,
      endIcon: (
        <SidebarIntegrationMenu
          apps={instagramApps}
          initialized={initialized}
          selectedAppId={currentAppId}
          onConnect={handleInstagramConnect}
          onDisconnect={handleInstagramDisconnect}
          onSelect={app => setSelectedApp(INSTAGRAM_GRAPH, app)}
        />
      ),
    },
    {
      icon: <YoutubeSmallIcon />,
      text: 'YouTube',
      isSelected: isSourceSelected(YOUTUBE, currentVideoSource),
      hidden: !getFeatureEnabled(FEATURE_YOUTUBE_INTEGRATION),
      key: YOUTUBE,
      endIcon: (
        <SidebarIntegrationSideButton
          app={youtube}
          initialized={initialized}
          onClick={handleYoutubeConnect}
          onDisconnectClick={handleYoutubeDisconnect}
          userName={youtubeProfile?.channelName || youtubeProfile?.name}
        />
      ),
    },
    {
      icon: <TiktokSmallIcon />,
      text: 'TikTok',
      isSelected: isSourceSelected(TIKTOK, currentVideoSource),
      key: TIKTOK,
      endIcon: (
        <SidebarIntegrationMenu
          apps={tiktokApps}
          initialized={initialized}
          selectedAppId={currentAppId}
          onConnect={handleTiktokConnect}
          onDisconnect={handleTikTokDisconnect}
          onSelect={app => setSelectedApp(TIKTOK, app)}
        />
      ),
    },
    {
      icon: <DropboxIcon />,
      text: 'Dropbox',
      isSelected: isSourceSelected(DROPBOX, currentVideoSource),
      key: DROPBOX,
    },
    {
      icon: <GoogleDriveIcon />,
      text: 'Google Drive',
      isSelected: isSourceSelected(GOOGLE_DRIVE, currentVideoSource),
      key: GOOGLE_DRIVE,
      hidden: !getFeatureEnabled(FEATURE_GOOGLE_DRIVE_INTEGRATION),
      endIcon: (
        <SidebarIntegrationSideButton
          app={googleDrive}
          initialized={initialized}
          onClick={handleGoogleDriveConnect}
          onDisconnectClick={handleGoogleDriveDisconnect}
          userName={googleDriveProfile?.name}
        />
      ),
    },
    {
      icon: <SynthesiaSmallIcon />,
      text: 'Synthesia',
      isSelected: isSourceSelected(SYNTHESIA, currentVideoSource),
      key: SYNTHESIA,
      endIcon: (
        <SidebarIntegrationSideButton
          app={synthesia}
          initialized={initialized}
          onDisconnectClick={handleSynthesiaDisconnect}
          onClick={() => setSelectedApp(SYNTHESIA)}
        />
      ),
    },
    {
      icon: <AiIcon />,
      text: 'AI Videos',
      isSelected: isSourceSelected(AI_VIDEO, currentVideoSource),
      hidden: !getFeatureEnabled(FEATURE_AI_VIDEOS) || !isShopify,
      key: AI_VIDEO,
    },
    {
      icon: <UploadFileIcon />,
      text: 'Uploaded',
      isSelected: isSourceSelected(UPLOAD, currentVideoSource),
      key: UPLOAD,
    },
    {
      icon: <OutlinedVideoIcon />,
      text: 'Recordings',
      isSelected: isSourceSelected(RECORDINGS, currentVideoSource),
      key: RECORDINGS,
    },
  ];

  return (
    <LayoutRoot>
      {items.map(({ text, key, ...props }) => {
        return (
          <StyledFolderMenuItem
            onClick={e => setSelectedApp(key, null, e)}
            key={text}
            setSelectedApp={setSelectedApp}
            text={text}
            showDefaultEndIcon={false}
            {...props}
          />
        );
      })}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  gap: 4px;
  overflow: auto;
`;

const StyledFolderMenuItem = styled(FolderMenuItem)`
  &:hover ${SidebarIntegrationSideButtonLayoutRoot}, &:hover ${SidebarIntegrationMenuLayoutRoot} {
    display: flex;
  }
`;

export default VideoInboxSideBar;
