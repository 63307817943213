import React from 'react';

const ArrowDownToLineIcon = () => {
  return (
    <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.938 9.625H1.061a.555.555 0 0 0-.562.563c0 .328.234.562.563.562h7.875a.555.555 0 0 0 .562-.563.57.57 0 0 0-.563-.562Zm-1.36-5.25L5.562 6.531V.836A.576.576 0 0 0 5 .25c-.328 0-.563.258-.563.586V6.53L2.398 4.375A.549.549 0 0 0 2 4.211a.636.636 0 0 0-.398.14c-.235.212-.235.563-.024.797l3 3.188c.211.234.61.234.82 0l3-3.188c.211-.234.211-.585-.023-.796-.234-.211-.586-.211-.797.023Z"
        fill="#fff"
      />
    </svg>
  );
};

export default ArrowDownToLineIcon;
