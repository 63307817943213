import React from 'react';
import styled from 'styled-components';
import EditorSliderItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorSliderItem';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import {
  CAROUSEL_BORDER_COLOR_PROPERTY_KEY,
  CAROUSEL_BORDER_PROPERTY_KEY,
  CAROUSEL_BORDER_WIDTH_PROPERTY_KEY,
} from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import EditorColorInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorColorInputItem';
import OnOffToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/OnOffToggleItem';
import EditorHeader from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorHeader';
import EditorMainContainer from '../shared/EditorMainContainer';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const FeedCarouselBorderEditor = ({ disabled }) => {
  const {
    customizationSettings: {
      widgetSettings: { carouselBorderColor, carouselBorderWidth, carouselBorder },
    },
    setWidgetSettingsProperty,
  } = useLookAndFeelContext();

  const onBorderWidthChange = (event, value) => {
    setWidgetSettingsProperty(CAROUSEL_BORDER_WIDTH_PROPERTY_KEY, value);
  };

  const onColorChange = value => {
    setWidgetSettingsProperty(CAROUSEL_BORDER_COLOR_PROPERTY_KEY, value);
  };

  const onEnableBorder = () => {
    setWidgetSettingsProperty(CAROUSEL_BORDER_PROPERTY_KEY, !carouselBorder);
  };

  return (
    <LayoutRoot>
      <EditorHeader title="Border" disabled={disabled} proFeature isWhite={!carouselBorder}>
        <OnOffToggleItem checked={carouselBorder} onChange={onEnableBorder} disabled={disabled} />
      </EditorHeader>
      <EditorMainContainer shouldHide={!carouselBorder}>
        <EditorSliderItem
          onChange={onBorderWidthChange}
          text="Border thickness"
          value={carouselBorderWidth}
          min={1}
          max={10}
          disabled={disabled}
        />
        <EditorColorInputItem
          text="Border color"
          color={carouselBorderColor}
          onChange={onColorChange}
          disabled={disabled}
        />
      </EditorMainContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

export default FeedCarouselBorderEditor;
