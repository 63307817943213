import { intercomShow, intercomUpdate } from 'app/src/utils/intercom.utils';

export const hideChat = () => {
  intercomUpdate({ hide_default_launcher: true });
};

export const showChat = () => {
  intercomUpdate({ hide_default_launcher: false });
};

export const openChat = () => {
  intercomShow();
};
