import React from 'react';
import PublishPricingTooltip from './PublishPricingTooltip';
import Copy from 'app/src/images/Copy';
import styled from 'styled-components';
import BasicButton from 'shared/react/components/basic/button/button/BasicButton';

const CopyCodeButtonPricing = ({ tooltipHeader, disabled, onClick, location, className }) => {
  return (
    <PublishPricingTooltip
      header={tooltipHeader}
      location={`${location} Copy Code`}
      hide={!disabled}
    >
      <Button disabled={disabled} onClick={onClick} className={className}>
        <Copy />
      </Button>
    </PublishPricingTooltip>
  );
};

const Button = styled(BasicButton)`
  border-radius: 100%;
  height: 40px;
  width: 40px;
  padding: 12px;
  flex-shrink: 0;
`;

export default CopyCodeButtonPricing;
