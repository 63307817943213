import React from 'react';
import styled from 'styled-components';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import NotificationItem from './NotificationItem';
import supportedNotifications from './supportedNotifications.json';

function NotificationSettingsContainer({
  emailSettings,
  inAppSettings,
  setEmailSettingProperty,
  setInAppSettingProperty,
  isEmailShowAnonymous,
  isInAppShowAnonymous,
  setEmailShowAnonymous,
  setInAppShowAnonymous,
  ...props
}) {
  if (!emailSettings) {
    return null;
  }

  return (
    <LayoutRoot {...props}>
      <EmailTogglesContainer>
        <NotificationItem
          key={'showAnonymous'}
          isEmailEnabled={isEmailShowAnonymous}
          isInAppEnabled={isInAppShowAnonymous}
          supportedNotification={{
            key: 'showAnonymous',
            title: 'Show anonymous',
            tooltipText:
              'Get notified on events even when you don’t have the viewer’s email (you won’t be able to respond)',
          }}
          setEmailSettingProperty={(_, enabled) => setEmailShowAnonymous(enabled)}
          setInAppSettingProperty={(_, enabled) => setInAppShowAnonymous(enabled)}
        />

        <ToggleSeparator />
        {supportedNotifications.map(supportedNotification => (
          <NotificationItem
            key={supportedNotification.key}
            isEmailEnabled={emailSettings?.includes(supportedNotification.key)}
            isInAppEnabled={inAppSettings?.includes(supportedNotification.key)}
            supportedNotification={supportedNotification}
            setEmailSettingProperty={setEmailSettingProperty}
            setInAppSettingProperty={setInAppSettingProperty}
          />
        ))}
      </EmailTogglesContainer>
    </LayoutRoot>
  );
}

export default NotificationSettingsContainer;

const LayoutRoot = styled(Gap16VerticalFlex)`
  gap: 16px;
  width: 444px;
  background: white;
  padding: 16px 8px 16px 16px;
  border-radius: 12px;
`;

const EmailTogglesContainer = styled(Gap16VerticalFlex)``;

const ToggleSeparator = styled.div`
  height: 1px;
  width: 209px;
  background: ${({ theme }) => theme.colors.ghostLight};
`;
