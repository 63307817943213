import React from 'react';
import { Label } from '@tolstoy/ui-library/core';

import { SpotlightDeviceSettings } from 'src/pages/project/pages/look_and_feel/types';

import { SpotlightDimensionEditor } from './SpotlightDimensionEditor';

interface FeedSpotlightFixedEditorProps {
  config: SpotlightDeviceSettings;
  onUpdate: (value: Partial<SpotlightDeviceSettings>) => void;
}

export const FeedSpotlightFixedEditor = ({ config, onUpdate }: FeedSpotlightFixedEditorProps) => {
  const {
    item,
    video,
    centerVideo,
  } = config || {};

  const onPaddingUpdate = (x, y) => {
    const value = {
      paddingX: Math.round(x / 2),
      paddingY: Math.round(y / 2),
    };

    onUpdate({ item: value, centerItem: value });
  };

  const onVideoUpdate = (width, height) => onUpdate({ video: { width, height } });
  const onCenterVideoUpdate = (width, height) => onUpdate({ centerVideo: { width, height } });

  return (
    <div className="flex flex-col gap-4">
      <Label className="text-sm font-semibold text-gray-900">
        Main tile
      </Label>
      <SpotlightDimensionEditor
        labelX="Width"
        labelY="Height"
        x={centerVideo.width}
        y={centerVideo.height}
        onUpdate={onCenterVideoUpdate}
      />
      <Label className="text-sm font-semibold text-gray-900">
        Other tiles
      </Label>
      <SpotlightDimensionEditor
        labelX="Width"
        labelY="Height"
        x={video.width}
        y={video.height}
        onUpdate={onVideoUpdate}
      />
      <SpotlightDimensionEditor
        x={item.paddingX * 2}
        labelX="Spacing between tiles"
        hideY
        onUpdate={onPaddingUpdate}
      />
    </div>
  );
};
