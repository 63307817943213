import React, { useState } from 'react';
import styled from 'styled-components';
import VideoResponse from 'app/src/pages/project/pages/inbox/ConversationComponents/VideoResponse';
import PlayIcon from 'app/src/images/Play';
import { INBOX_VIDEO_CONTENT_TEST_ID } from 'app/src/constants/dataTestIds.constants';

const VideoContent = ({ response, setVideoModalSrc, children }) => {
  const [videoLoaded, setVideoLoaded] = useState(false);
  const onVideoLoaded = () => setVideoLoaded(true);
  return (
    <>
      {children}
      <StyledVideoContent data-test-id={INBOX_VIDEO_CONTENT_TEST_ID}>
        <VideoResponse
          response={response}
          setVideoModalSrc={setVideoModalSrc}
          onVideoLoaded={onVideoLoaded}
        />
        {videoLoaded && (
          <VideoPlayButton>
            <PlayIconContainer>
              <PlayIcon />
            </PlayIconContainer>
          </VideoPlayButton>
        )}
      </StyledVideoContent>
    </>
  );
};

export default VideoContent;

const StyledVideoContent = styled.div`
  position: relative;
  width: 200px;
`;

const VideoPlayButton = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  pointer-events: none;
`;

const PlayIconContainer = styled.div`
  display: flex;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
`;
