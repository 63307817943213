import React from 'react';

const BuildingIcon = ({ fill = '#7D8087' }) => (
  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.625 8h-1.25a.38.38 0 0 0-.375.375v1.25a.36.36 0 0 0 .375.375h1.25A.38.38 0 0 0 12 9.625v-1.25A.403.403 0 0 0 11.625 8Zm0 3h-1.25a.38.38 0 0 0-.375.375v1.25a.36.36 0 0 0 .375.375h1.25a.38.38 0 0 0 .375-.375v-1.25a.403.403 0 0 0-.375-.375Zm-5-2h-1.25A.38.38 0 0 0 5 9.375v1.25a.36.36 0 0 0 .375.375h1.25A.38.38 0 0 0 7 10.625v-1.25A.403.403 0 0 0 6.625 9Zm5-4h-1.25a.38.38 0 0 0-.375.375v1.25a.36.36 0 0 0 .375.375h1.25A.38.38 0 0 0 12 6.625v-1.25A.403.403 0 0 0 11.625 5Zm-5 7h-1.25a.38.38 0 0 0-.375.375v1.25a.36.36 0 0 0 .375.375h1.25A.38.38 0 0 0 7 13.625v-1.25A.403.403 0 0 0 6.625 12Zm8-7h-1.25a.38.38 0 0 0-.375.375v1.25a.36.36 0 0 0 .375.375h1.25A.38.38 0 0 0 15 6.625v-1.25A.403.403 0 0 0 14.625 5ZM16 2H9c-1.125 0-2 .906-2 2v2H4c-1.125 0-2 .906-2 2v9.25c0 .438.313.75.75.75a.74.74 0 0 0 .75-.75V8c0-.25.219-.5.5-.5h4.5V4c0-.25.219-.5.5-.5h7c.25 0 .5.25.5.5v13.25c0 .438.313.75.75.75a.74.74 0 0 0 .75-.75V4c0-1.094-.906-2-2-2Zm-1.375 6h-1.25a.38.38 0 0 0-.375.375v1.25a.36.36 0 0 0 .375.375h1.25A.38.38 0 0 0 15 9.625v-1.25A.403.403 0 0 0 14.625 8Zm0 3h-1.25a.38.38 0 0 0-.375.375v1.25a.36.36 0 0 0 .375.375h1.25a.38.38 0 0 0 .375-.375v-1.25a.403.403 0 0 0-.375-.375Z"
      fill={fill}
    />
  </svg>
);

export default BuildingIcon;
