import React, { useEffect } from 'react';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import ProgressBar from 'src/basic_components/ProgressBar';
import ComponentWithLoader from 'src/complex_components/ComponentWithLoader';
import { useApps } from 'src/context/AppsStore';
import { useProducts } from 'src/context/ProductsStore';
import { useVodConnection } from 'src/context/VodConnectionStore';
import Utils from 'src/utils';
import styled from 'styled-components';

const ProductsProgressBar = () => {
  const [{ numberOfProducts: numberOfTaggedProducts }] = useVodConnection();
  const [{ isLoadingProductsCount, productsCount }, { getProductsCount }] = useProducts();
  const [{ selectedAppUrl }] = useApps();

  useEffect(() => {
    if (!selectedAppUrl) {
      return;
    }

    getProductsCount(selectedAppUrl);
  }, [selectedAppUrl]);

  const progressPercentage = Utils.getPercentage(numberOfTaggedProducts, productsCount);
  const formattedProgressPercentage = isLoadingProductsCount ? '' : ` ${progressPercentage}%`;
  return (
    <LayoutRoot>
      <TextTiny>
        Products with videos:
        {formattedProgressPercentage}
      </TextTiny>
      <ComponentWithLoader isLoading={isLoadingProductsCount} size={16}>
        <ProgressBar progressPercentage={progressPercentage} />
      </ComponentWithLoader>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlex)`
  padding: 0 80px;
  align-items: center;
`;

export default ProductsProgressBar;
