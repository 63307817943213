import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import {
  FEED_AUTO_SCROLL_PROPERTY_KEY,
  FEED_FULLSCREEN_PROPERTY_KEY,
  FEED_SHOW_DESCRIPTION_IF_NO_PRODUCTS_PROPERTY_KEY,
  FEED_START_ON_MUTE,
  HIDE_WISHLIST_PROPERTY_KEY,
  IS_SHOPPABLE_PROPERTY_KEY,
  SHOW_CREATOR_PROFILE_LINK_PROPERTY_KEY,
  SUBTITLES_ENABLED_PROPERTY_KEY,
  WIDGET_POSITION_PROPERTY_KEY,
  VIDEOS_FROM_LAST_X_DAYS_KEY,
  DISABLE_MULTI_PRODUCT_VIEW,
} from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import EditorOnOffToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorOnOffToggleItem';
import React from 'react';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import styled from 'styled-components';
import EditorButtonGroupItem from '../shared/EditorButtonGroupItem';
import EditorHeader from '../shared/EditorHeader';
import EditorMainContainer from '../shared/EditorMainContainer';

const FeedBehaviourEditor = ({ hiddenItems }) => {
  const {
    customizationSettings: {
      feedAutoScroll,
      widgetPosition,
      subtitlesEnabled,
      widgetSettings: { isFullscreenFeed },
      playerSettings: {
        isShoppable,
        videosFromLastXDays,
        showDescriptionIfNoProducts,
        feedStartOnMute,
        hideWishlist,
        showCreatorProfileLink = false,
        disableMultiProductView = false,
      },
    },
    setCustomizationSettingsProperty,
    setWidgetSettingsProperty,
    setPlayerSettingsProperty,
  } = useLookAndFeelContext();

  const onPlayerAndWidgetSettingChange = (propertyKey, value) => {
    setPlayerSettingsProperty(propertyKey, value);
    setWidgetSettingsProperty(propertyKey, value);
  };

  const onShopabaleVideosChange = value => {
    onPlayerAndWidgetSettingChange(IS_SHOPPABLE_PROPERTY_KEY, value);
  };

  const onVideosFromLastXDaysChange = value => {
    onPlayerAndWidgetSettingChange(VIDEOS_FROM_LAST_X_DAYS_KEY, value);
  };

  const toggleItems = [
    {
      id: 'make-videos-shoppable',
      text: 'Make videos shoppable',
      checked: isShoppable,
      onChange: value => onShopabaleVideosChange(value),
    },
    {
      id: 'view-feed-in-fullscreen',
      text: 'View feed in fullscreen',
      checked: isFullscreenFeed,
      onChange: value => setWidgetSettingsProperty(FEED_FULLSCREEN_PROPERTY_KEY, value),
    },
    {
      id: 'auto-scroll-to-next-video',
      text: 'Auto scroll to next video',
      checked: feedAutoScroll,
      onChange: value => setCustomizationSettingsProperty(FEED_AUTO_SCROLL_PROPERTY_KEY, value),
    },
    {
      id: 'showDescriptionIfNoProducts',
      text: 'Auto-Display Video Description',
      tooltipText:
        'When enabled, videos sourced from social media without product tags will automatically display their original post description.',
      checked: showDescriptionIfNoProducts,
      onChange: value =>
        setPlayerSettingsProperty(FEED_SHOW_DESCRIPTION_IF_NO_PRODUCTS_PROPERTY_KEY, value),
    },
    {
      id: 'show-creator-profile-link',
      text: 'Display creator handle',
      checked: showCreatorProfileLink,
      onChange: value => setPlayerSettingsProperty(SHOW_CREATOR_PROFILE_LINK_PROPERTY_KEY, value),
    },
    {
      id: 'feedStartOnMute',
      text: 'Always start on mute',
      tooltipText: 'When enabled, videos feed will start playing muted',
      checked: feedStartOnMute,
      onChange: value => setPlayerSettingsProperty(FEED_START_ON_MUTE, value),
    },
    {
      id: 'hideWishlist',
      text: 'Hide wishlist',
      checked: hideWishlist,
      onChange: value => setPlayerSettingsProperty(HIDE_WISHLIST_PROPERTY_KEY, value),
    },
    {
      id: 'captionsEnabled',
      text: 'Enable video captions',
      checked: subtitlesEnabled,
      onChange: value => setCustomizationSettingsProperty(SUBTITLES_ENABLED_PROPERTY_KEY, value),
    },
    {
      id: 'videos-from-last-X-days',
      text: 'Show videos from the last 30 days',
      tooltipText:
        'Toggle on to display only the videos in your playlist that were created within the last 30 days. This setting ensures the playlist remains updated with the most recent content.',
      checked: videosFromLastXDays,
      onChange: value => onVideosFromLastXDaysChange(value),
    },
    {
      id: 'disableMultiProductView',
      text: 'Disable multi-product view',
      tooltipText: 'In mobile view, skip multi-product view',
      checked: disableMultiProductView,
      onChange: value => setPlayerSettingsProperty(DISABLE_MULTI_PRODUCT_VIEW, value),
    },
  ];

  const positionButtons = [
    {
      text: 'Left',
      isEnabled: widgetPosition === 'bottomLeft',
      onClick: () => setCustomizationSettingsProperty(WIDGET_POSITION_PROPERTY_KEY, 'bottomLeft'),
    },
    {
      text: 'Right',
      isEnabled: widgetPosition === 'bottomRight',
      onClick: () => setCustomizationSettingsProperty(WIDGET_POSITION_PROPERTY_KEY, 'bottomRight'),
    },
  ];

  return (
    <LayoutRoot>
      <EditorHeader title="Behaviour" />
      <EditorMainContainer>
        {toggleItems.flatMap(({ id, text, checked, onChange, tooltipText }) => {
          if (hiddenItems?.includes(id)) {
            return [];
          }

          return (
            <EditorOnOffToggleItem
              key={id}
              text={text}
              checked={checked}
              onChange={onChange}
              tooltipText={tooltipText}
            />
          );
        })}
        {!hiddenItems.includes('feed-player-position') && (
          <EditorButtonGroupItem text="Player position" buttons={positionButtons} />
        )}
      </EditorMainContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

export default FeedBehaviourEditor;
