import React from 'react';
import styled from 'styled-components';
import { HexColorPicker as ColorfulHexColorPicker } from 'react-colorful';
import ColorPickerTranslator from 'app/src/complex_components/color-palette/ColorPalettePopover/components/ColorPickerTranslator';

interface HexColorPickerProps {
  color: string;
  value: string;
  onChange?: (value: string) => void;
}

const HexColorPicker = ({ color, onChange, value }: HexColorPickerProps) => {
  return (
    <StyledHexColorPicker>
      <ColorfulHexColorPicker color={color || value} onChange={onChange} />
      <ColorPickerTranslator value={color} onChange={onChange} />
    </StyledHexColorPicker>
  );
};

const StyledHexColorPicker = styled.div`
  height: 316px;

  .react-colorful {
    width: 100%;
    height: 100%;
  }

  .react-colorful__hue {
    margin: 20px 8px;
    max-height: 12px;
    border-radius: 10px;
  }

  .react-colorful__pointer {
    border-width: 6px;
  }
`;

export default HexColorPicker;
