import React from 'react';

const RightArrow = ({ fill = '#50545E' }) => {
  return (
    <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.12656 6.75635L2.62656 1.00635C2.15781 0.506348 1.37656 0.475098 0.876562 0.975098C0.376562 1.44385 0.345312 2.2251 0.845312 2.7251L5.50156 7.6001L0.845312 12.5063C0.345312 13.0063 0.376562 13.7876 0.876562 14.2563C1.12656 14.5063 1.43906 14.6001 1.72031 14.6001C2.06406 14.6001 2.40781 14.4751 2.62656 14.2251L8.12656 8.4751C8.59531 8.00635 8.59531 7.2251 8.12656 6.75635Z"
        fill={fill}
      />
    </svg>
  );
};

export default RightArrow;
