import React from 'react';
import styled from 'styled-components';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { TextH4 } from 'shared/react/components/basic/text/TextV2';
import HorizontalFlex, {
  Gap8HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import ShopifyLogoIcon from 'src/images/creation_method_icons/ShopifyLogoIcon';
import TolstoyCodeInstallationStep from '../tolstoy_code_step/TolstoyCodeInstallationStep';
import ConversionCodeInstallationStep from '../conversion_code_step/ConversionCodeInstallationStep';
import Utils from 'src/utils';
import { app as AppEntity } from 'src/types/entities';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import InstallationCompletedButton from '../InstallationCompletedButton';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import useAccountInstallation from 'src/hooks/useAccountInstallation';

type Props = {
  app?: AppEntity;
  startExpanded?: boolean;
};

const ShopifyAppInstallationSteps: React.FC<Props> = ({ app, startExpanded }) => {
  const formattedStoreName = Utils.getFormattedStoreName(app?.appUrl);
  const [{ isInstallationCompleted }] = useAccountInstallation(app?.id);

  return (
    <AppWrapper>
      <TitleSectionWrapper>
        <AppNameWrapper>
          <ShopifyLogoIcon />
          <TextH4>{formattedStoreName}</TextH4>
          {isInstallationCompleted && <SuccessIcon />}
        </AppNameWrapper>
        <InstallationCompletedButton app={app} />
      </TitleSectionWrapper>

      <AccordionsWrapper>
        <TolstoyCodeInstallationStep app={app} startExpanded={startExpanded} />
        <ConversionCodeInstallationStep app={app} />
      </AccordionsWrapper>
    </AppWrapper>
  );
};

const AppWrapper = styled(Gap16VerticalFlex)`
  margin-bottom: 40px;
`;

const TitleSectionWrapper = styled(HorizontalFlex)`
  justify-content: space-between;
`;

const AccordionsWrapper = styled(Gap8VerticalFlex)``;

const AppNameWrapper = styled(Gap8HorizontalFlex)`
  align-items: center;

  & svg {
    width: 20px;
    height: 20px;
  }
`;

const SuccessIcon = styled(CheckCircleIcon)`
  fill: ${({ theme }) => theme.colors.success};
`;

export default ShopifyAppInstallationSteps;
