import React, { useEffect, useState } from 'react';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import ImageWithFallback from 'shared/react/images/ImageWithFallback';
import styled, { useTheme } from 'styled-components';
import { GhostButton } from 'shared/react/components/basic/button-v2/BasicButton';
import XIcon from 'src/images/dashboard_v2/XIcon';
import ThinVIcon from 'shared/react/images/ThinVIcon';
import LineClampWrapper from 'shared/react/components/basic/LineClampWrapper';
import { TextSmall, TextTiny } from 'shared/react/components/basic/text/TextV2';
import { useSelectedProductsContext } from '../../../../SelectedProductsContext';
import { track } from 'src/helpers/Tracker';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import { FEATURE_SHOW_PRODUCT_HANDLE } from 'app/src/constants/appFeatures.constants';
import { formatHandle } from 'src/utils/shopify.utils';

type Props = {
  productId: string;
  title: string;
  imageUrl: string;
  handle: string;
  onSuggestionRejected: ({ externalProductId, vodConnectionSuggestionId }) => Promise<void>;
  onSuggestionAccepted: ({ externalProductId, vodConnectionSuggestionId }) => Promise<void[]>;
  vodAssetId: string;
  vodConnectionSuggestionId: string;
};

const SuggestedProduct = ({
  productId,
  title,
  imageUrl,
  handle,
  onSuggestionRejected,
  onSuggestionAccepted,
  vodAssetId,
  vodConnectionSuggestionId,
}: Props) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [, { setIsSaving: setIsAddProductsModalSaving }] = useSelectedProductsContext();

  const handleSuggestionRejected = async () => {
    setIsLoading(true);

    await onSuggestionRejected({ externalProductId: productId, vodConnectionSuggestionId });

    setIsLoading(false);
  };

  const handleSuggestionAccepted = async () => {
    setIsAddProductsModalSaving(true);
    setIsLoading(true);

    await onSuggestionAccepted({ externalProductId: productId, vodConnectionSuggestionId });

    setIsLoading(false);
    setIsAddProductsModalSaving(false);
  };

  useEffect(() => {
    track('Vod Connection Suggestion Viewed', {
      vodAssetId,
      vodConnectionSuggestionId,
      externalProductId: productId,
    });
  }, []);

  return (
    <LayoutRoot>
      <Gap8HorizontalFlex>
        <StyledImageWithFallback src={imageUrl} />
        <VerticalFlex>
          <TextSmall>
            <LineClampWrapper>{title}</LineClampWrapper>
          </TextSmall>
          <ProductSubtitle handle={handle} />
        </VerticalFlex>
      </Gap8HorizontalFlex>
      <Actions>
        <ActionButton
          onClick={handleSuggestionRejected}
          disabled={isLoading}
          hoverColor={theme.colors.danger}
        >
          <XIcon />
        </ActionButton>
        <ActionButton
          onClick={handleSuggestionAccepted}
          disabled={isLoading}
          hoverColor={theme.colors.grapePurple}
        >
          <ThinVIcon />
        </ActionButton>
      </Actions>
    </LayoutRoot>
  );
};

const ProductSubtitle = ({ handle }) => {
  const { getFeatureEnabled } = useFeatureActions();
  const isShowProductHandleEnabled = getFeatureEnabled(FEATURE_SHOW_PRODUCT_HANDLE);

  if (!isShowProductHandleEnabled) {
    return null;
  }

  return <StyledTextTiny>{formatHandle(handle)}</StyledTextTiny>;
};

const LayoutRoot = styled(Gap8HorizontalFlex)`
  padding: 8px;
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.colors.grapePurple};
  justify-content: space-between;
  transition: 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutralLightest};
  }
`;

const StyledImageWithFallback = styled(ImageWithFallback)`
  width: 64px;
  height: 64px;
  border-radius: 2px;
  object-fit: cover;
  flex-shrink: 0;
  box-shadow: 0 0 3px rgba(50, 50, 93, 0.1);
`;

const Actions = styled(Gap8HorizontalFlex)<{ hoverColor: string }>`
  align-items: flex-start;
  justify-self: flex-end;
`;

const ActionButton = styled(GhostButton)`
  padding: 5px;

  svg {
    width: 12px;
    height: 12px;

    path {
      fill: ${({ theme }) => theme.colors.neutralBlack};
    }
  }

  &:hover {
    svg {
      path {
        fill: ${({ hoverColor }) => hoverColor};
      }
    }
  }
`;

const StyledTextTiny = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralDark};
`;

export default SuggestedProduct;
