import React, { useMemo } from 'react';
import styled from 'styled-components';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { useAnalytics } from 'app/src/context/AnalyticsStore';
import AnalyticsTable from '../AnalyticsTable';
import AnalyticsTolstoyLink from '../AnalyticsTolstoyLink';
import { PLAYER_USER_LOG } from 'app/src/constants/analytics.constants';
import Utils from 'app/src/utils';

const numberComparator = (v1, v2) => {
  return +v1 - +v2;
};

const columns = [
  {
    field: 'tolstoy',
    headerName: 'Tolstoy',
    flex: 1,
    minWidth: 150,
    renderCell: AnalyticsTolstoyLink,
  },
  {
    field: 'pageView',
    headerName: 'Page View',
    flex: 1,
    sortComparator: numberComparator,
  },
  {
    field: 'sessionStart',
    headerName: 'Click Play',
    flex: 1,
    sortComparator: numberComparator,
  },
  {
    field: 'formattedPlayRate',
    headerName: 'Play Rate',
    flex: 1,
    sortComparator: (a, b) => parseFloat(a) - parseFloat(b),
  },
  {
    field: 'clickCta',
    headerName: 'Button Click',
    flex: 1,
    sortComparator: numberComparator,
  },
  {
    field: 'sidebarClick',
    headerName: 'Sidebar Click',
    flex: 1,
    sortComparator: numberComparator,
  },
  {
    field: 'collectInfo',
    headerName: 'Collect Contact Details',
    flex: 1,
    sortComparator: numberComparator,
  },
  {
    field: 'textResponse',
    headerName: 'Text Response',
    flex: 1,
    sortComparator: numberComparator,
  },
  {
    field: 'videoResponse',
    headerName: 'Video Response',
    flex: 1,
    sortComparator: numberComparator,
  },
  {
    field: 'audioResponse',
    headerName: 'Audio Response',
    flex: 1,
    sortComparator: numberComparator,
  },
];

const getRows = resultSet => {
  if (!resultSet) {
    return [];
  }
  const tableData = resultSet?.tablePivot({
    x: [`${PLAYER_USER_LOG}.publishid`, 'measures'],
    y: [`${PLAYER_USER_LOG}.eventname`],
  });
  const rows = tableData.map((data, id) => {
    const sessionStart = data['sessionStart'] || 0;
    const pageView = data['pageView'] || 0;
    const playRate = Utils.getPercentage(sessionStart, pageView);
    const formattedPlayRate = Utils.formatNumber({
      value: playRate,
      isPercentage: true,
      decimalPlaces: 2,
    });

    return {
      id,
      tolstoy: data[`${PLAYER_USER_LOG}.publishid`],
      sessionStart,
      pageView,
      playRate,
      formattedPlayRate,
      clickCta: data['clickCta'] || 0,
      textResponse: data['submitInput'] || 0,
      videoResponse: data['videoResponse'] || 0,
      audioResponse: data['audioResponse'] || 0,
      collectInfo: data['collectInfo'] || 0,
      sidebarClick: data['sidebarClick'] || 0,
    };
  });
  return rows;
};

const AnalyticsEngagementTolstoysEventTable = () => {
  const [{ engagementTolstoysEvent }] = useAnalytics();
  const rows = useMemo(() => getRows(engagementTolstoysEvent), [engagementTolstoysEvent]);

  return (
    <StyledAnalyticsEngagementTolstoysEventTable>
      <AnalyticsTable rows={rows} columns={columns} title="Tolstoy Events" />
    </StyledAnalyticsEngagementTolstoysEventTable>
  );
};

export default AnalyticsEngagementTolstoysEventTable;

const StyledAnalyticsEngagementTolstoysEventTable = styled(Gap8VerticalFlex)`
  grid-area: events;
`;
