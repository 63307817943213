import React from 'react';
import styled, { useTheme } from 'styled-components';
import MacroIcon from '../icons/MacroIcon';
import RightArrow from 'shared/react/images/RightArrow';
import ProductIcon from 'shared/react/images/ProductIcon';
import AudioIcon from 'shared/react/images/AudioIcon';
import { REPLY_MACRO_DATA_TEST_ID } from 'shared/react/constants/dataTestIds.constants';
import VideoReplyIcon from '../icons/VideoReplyIcon';
import ScreenRecordingIcon from '../icons/ScreenRecordingIcon';

const ReplyOptions = ({
  multiLine,
  onVideoReply,
  onProductReply,
  onMacroReply,
  onScreenRecording,
  onClear,
  onAudioReply,
}) => {
  const theme = useTheme();

  if (multiLine) {
    return (
      <ButtonWithBackground onClick={onClear}>
        <RightArrow />
      </ButtonWithBackground>
    );
  }

  return (
    <StyledReplyBarButtons>
      {onVideoReply && (
        <ReplyBarIconButton onClick={onVideoReply}>
          <VideoReplyIcon />
        </ReplyBarIconButton>
      )}
      {onProductReply && (
        <ButtonWithBackground onClick={onProductReply}>
          <ProductIcon width={12} height={16} />
        </ButtonWithBackground>
      )}
      {onScreenRecording && (
        <ReplyBarIconButton onClick={onScreenRecording}>
          <ScreenRecordingIcon />
        </ReplyBarIconButton>
      )}
      {onMacroReply && (
        <ReplyBarIconButton onClick={onMacroReply} data-test-id={REPLY_MACRO_DATA_TEST_ID}>
          <MacroIcon />
        </ReplyBarIconButton>
      )}
      {onAudioReply && (
        <ReplyBarIconButton onClick={onAudioReply}>
          <AudioIcon
            width={32}
            height={32}
            iconFill={theme.colors.gray2}
            circleFill={theme.colors.gray5}
          />
        </ReplyBarIconButton>
      )}
    </StyledReplyBarButtons>
  );
};

const StyledReplyBarButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  padding-right: 22px;

  & > * {
    cursor: pointer;
  }
`;

const ReplyBarIconButton = styled.button`
  display: grid;
  border: none;
  outline: none;
  cursor: pointer;
  background: inherit;
  padding: 0;
  transition: 0.3s;

  &:hover {
    transform: scale(1.2);
  }
`;

const ButtonWithBackground = styled(ReplyBarIconButton)`
  flex-shrink: 0;
  border-radius: 50px;
  background-color: ${({ theme }) => theme.colors.gray5};
`;

export default ReplyOptions;
