import React from 'react';
import AnalyticsMultiSelect from './AnalyticsMultiSelect';
import useSelectedAppProjects from 'app/src/hooks/useSelectedAppProjects';
import { project as Project } from 'app/src/types/entities';
import { useAnalytics } from 'app/src/context/AnalyticsStore';

const AnalyticsProjectFilter = () => {
  const [{ publishIds }, { setPublishIds }] = useAnalytics();

  const { selectedAppProjects } = useSelectedAppProjects();

  const getProjectName = (project: Project) => project.name;
  const getProjectPublishId = (project: Project) => project.publishId;

  const projectsPublishIds = selectedAppProjects?.map(getProjectPublishId);
  const allProjectsShouldBeSelected = !publishIds?.length;
  const filterValue = allProjectsShouldBeSelected ? projectsPublishIds : publishIds;

  const handleFilterChange = (newPublishIds: string[]) => {
    if (!newPublishIds.length || newPublishIds.length === projectsPublishIds.length) {
      setPublishIds(null);
      return;
    }

    setPublishIds(newPublishIds);
  };

  return (
    <AnalyticsMultiSelect
      getOptionId={getProjectPublishId}
      getOptionLabel={getProjectName}
      getOptionValue={getProjectPublishId}
      label="Project"
      onConfirm={handleFilterChange}
      options={selectedAppProjects}
      value={filterValue}
    />
  );
};

export default AnalyticsProjectFilter;
