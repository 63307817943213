import React from 'react';
import styled from 'styled-components';
import Separator from 'shared/react/components/basic/Separator';
import SelectedProduct from 'app/src/complex_components/global_modal/video/add_products_modal/SelectedProduct';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

type Props = {
  // selectedProducts, isLoading, onRemove must be defined as props
  // instead of being a part of SelectedProductsContext
  // because component is reused in different flows
  selectedProducts?: any[];
  selectedProductsWithVariants?: { product: any; variantId: string }[];
  isLoading: boolean;
  onRemove: (productId: string, variantId?: string) => void;
  allowSelectVariants?: boolean;
  reorder?: (selectedProducts) => void;
};

const SelectedProductsList = ({
  selectedProducts,
  selectedProductsWithVariants,
  isLoading,
  onRemove,
  allowSelectVariants,
  reorder,
}: Props) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  const handleDragEnd = event => {
    const { active, over } = event;
    if (active?.id === over?.id) {
      return;
    }

    const oldIndex = selectedProductsWithVariants.findIndex(
      ({ product, variantId }) => `${product.id}_${variantId}` === active.id
    );

    const oldProduct = selectedProductsWithVariants[oldIndex];
    const newIndex = selectedProductsWithVariants.findIndex(
      ({ product, variantId }) => `${product.id}_${variantId}` === over.id
    );

    const newArray = arrayMove(selectedProductsWithVariants, oldIndex, newIndex);
    const newSelectedProducts = newArray.reduce((acc, { product, variantId }, i) => {
      if (
        acc.find(({ productId }) => productId === product.productId) ||
        (product.productId === oldProduct.product.productId && oldProduct.variantId !== variantId)
      ) {
        return acc;
      }

      return [...acc, product];
    }, []);

    reorder(newSelectedProducts);
  };

  if (allowSelectVariants) {
    const productKeys = selectedProductsWithVariants.map(
      ({ product, variantId }) => `${product.id}_${variantId}`
    );

    return (
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={productKeys} strategy={verticalListSortingStrategy}>
          <LayoutRoot isLoading={isLoading}>
            {selectedProductsWithVariants.map(({ product, variantId }) => {
              const key = `${product?.productId || product?.id}_${variantId}`;
              return (
                <React.Fragment key={key}>
                  <SelectedProduct
                    product={product}
                    variantId={variantId}
                    onRemove={onRemove}
                    allowSelectVariants={allowSelectVariants}
                  />
                  <StyledSeparator />
                </React.Fragment>
              );
            })}
          </LayoutRoot>
        </SortableContext>
      </DndContext>
    );
  }

  return (
    <LayoutRoot isLoading={isLoading}>
      {selectedProducts.map(product => {
        return (
          <React.Fragment key={product?.productId || product?.id}>
            <SelectedProduct
              product={product}
              onRemove={onRemove}
              allowSelectVariants={allowSelectVariants}
            />
            <StyledSeparator />
          </React.Fragment>
        );
      })}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  min-height: 280px;
  position: relative;
  opacity: ${({ isLoading }) => (isLoading ? 0.5 : 1)};
  width: 100%;
`;

const StyledSeparator = styled(Separator)`
  flex-shrink: 0;
  background: ${({ theme }) => theme.colors.neutralLighter};

  &:last-of-type {
    display: none;
  }
`;

export default SelectedProductsList;
