import React, { useState } from 'react';
import styled from 'styled-components';
import HorizontalFlex, {
  Gap8HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextSmall } from 'shared/react/components/complex/Text';
import ImageWithFallback from 'shared/react/images/ImageWithFallback';
import TrashButton from 'app/src/basic_components/TrashButton';
import { TRASH_BUTTON_DATA_TYPE } from 'app/src/constants/ui.constants';
import { CircularProgress } from '@material-ui/core';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';

const ProductItem = ({ product, onClick, className, deleteProduct, disabled }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { imageUrl, title } = product;

  const onProductClick = () => {
    if (disabled || !onClick) {
      return;
    }

    onClick(product);
  };

  const onDeleteClick = () => {
    setIsLoading(true);
    deleteProduct(product);
  };

  const getEndIcon = () => {
    if (!isLoading) {
      return <TrashButton onClick={onDeleteClick} />;
    }

    return <CircularProgress size={20} />;
  };

  return (
    <LayoutRoot disabled={disabled} className={className} onClick={onProductClick}>
      <LeftContainer>
        {!!imageUrl && <StyledImageWithFallback src={imageUrl} />}
        <EllipsisTextOverflowWrapper>
          <Text>{title}</Text>
        </EllipsisTextOverflowWrapper>
      </LeftContainer>
      {!!deleteProduct && <DeleteContainer>{getEndIcon()}</DeleteContainer>}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)`
  padding: 8px;
  align-items: center;
  transition: 0.3s;
  width: 100%;
  justify-content: space-between;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : '')};
  &:hover {
    & ${`[data-type="${TRASH_BUTTON_DATA_TYPE}"]`} {
      opacity: 1;
    }
  }
`;

const LeftContainer = styled(Gap8HorizontalFlex)`
  padding: 8px;
  align-items: center;
`;

const StyledImageWithFallback = styled(ImageWithFallback)`
  height: 32px;
  width: 32px;
`;

const Text = styled(TextSmall)`
  direction: rtl;
`;

const DeleteContainer = styled(HorizontalFlex)`
  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    & > * {
      position: relative;
      top: 0;
    }
  }
`;

export default ProductItem;
