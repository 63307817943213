import React from 'react';
import styled from 'styled-components';
import { TextSmall } from 'shared/react/components/complex/Text';
import { MenuItem as MuiMenuItem } from '@material-ui/core';
import Utils from 'shared/react/utils/utils';

const MenuItem = React.forwardRef(
  (
    {
      children,
      index,
      menuItem,
      selected,
      width,
      onClose,
      textMaxWidth,
      onClick,
      textColor,
      ...props
    },
    ref
  ) => {
    return (
      <LayoutRoot
        ref={ref}
        icon={menuItem.icon || menuItem.componentIcon}
        onKeyDown={Utils.stopPropagation}
        value={menuItem.value || menuItem.title}
        hidden={menuItem.hidden}
        selected={selected || menuItem.selected}
        width={width}
        onClick={e => {
          onClose(e);
          if (menuItem.onClick) {
            menuItem.onClick(e, index);
          }
          if (onClick) {
            onClick(e, index);
          }
        }}
        {...props}
      >
        {children || (
          <MenuItemContent removeStartIconSpacing={menuItem.removeStartIconSpacing}>
            {menuItem.icon ? (
              <img src={menuItem.icon} alt={menuItem.title} />
            ) : menuItem.componentIcon ? (
              menuItem.componentIcon
            ) : (
              <div />
            )}
            <Title textColor={menuItem.textColor} maxWidth={textMaxWidth}>
              {menuItem.title}
            </Title>
            {menuItem.endAdornment}
          </MenuItemContent>
        )}
      </LayoutRoot>
    );
  }
);

MenuItem.displayName = 'MenuItem';

export default MenuItem;

const LayoutRoot = styled(MuiMenuItem)`
  width: ${props => props.width || '100%'};
  min-width: 200px;
  background-color: ${({ selected = 'initial' }) => selected};
  display: ${({ hidden }) => (hidden ? 'none' : '')};

  && {
    padding: ${({ icon, customPadding }) => {
      if (customPadding) {
        return customPadding;
      }

      if (icon) {
        return '8px 0';
      }

      return '8px';
    }};
    font-family: 'Nunito Sans', sans-serif;
  }
`;

const Title = styled(TextSmall)`
  max-width: ${({ maxWidth = '160px' }) => maxWidth};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ textColor }) => textColor};
`;

const MenuItemContent = styled.div`
  display: grid;
  grid-template-columns: ${props => (props.removeStartIconSpacing ? '0' : '52px')} 1fr auto;
  justify-items: center;
  align-items: center;
`;
