import Tooltip from 'app/src/basic_components/Tooltip';
import DownloadIcon from 'app/src/images/edit_step_icons/DownloadIcon';
import CaptionsIcon from 'app/src/images/edit_step_icons/CaptionsIcon';
import ClockIcon from 'app/src/images/edit_step_icons/ClockIcon';
import MovieIcon from 'shared/react/images/MovieIcon';
import TrimIcon from 'app/src/images/edit_step_icons/TrimIcon';
import VideoContainIcon from 'app/src/images/edit_step_icons/VideoContainIcon';
import React, { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import ComponentWithLoader from './ComponentWithLoader';
import { useVideos } from 'app/src/context/VideosStore';
import { useFormContext } from 'react-hook-form';
import FillScreenIcon from 'app/src/images/edit_step_icons/FillScreenIcon';
import Routes from 'app/src/helpers/Routes';
import { useHistory } from 'react-router-dom';
import { track } from 'app/src/helpers/Tracker';
import { MINIMUM_TRIMMING_SECONDS } from 'app/src/constants/editStep.constants';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import FilledClockIcon from 'app/src/images/edit_step_icons/FilledClockIcon';
import { secondsToMinutesSecondsDisplay } from 'app/src/utils/time.utils';
import EditAnswersTimeButton from 'app/src/complex_components/EditAnswersTimeButton';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import { EDIT_CAPTIONS_DATA_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import { useModal } from 'app/src/context/ui_store/ModalStore';

const VideoSettingsToolbar = ({
  video,
  project,
  onChangeVideoClick,
  step,
  isLoading,
  isVideoReady,
  videoDuration,
  isMobilePreview,
  videoRef,
  isEditingAnswersTime,
  setEditingAnswersTime,
  videoLoaded,
  ...props
}) => {
  const [{ loading: downloadVideoLoading }, { downloadVideo }] = useVideos();
  const [, { clearModalState }] = useModal();
  const history = useHistory();
  const { setValue, watch, reply } = useFormContext();
  const [answerTime, videoContain] = watch(['answerTime', 'videoContain']);

  const { macro: isMacro, id: projectId, feed: isFeed } = project;

  const setAnswerTime = useCallback(time => {
    setValue('answerTime', time);
  }, []);

  const toggleVideoContain = () => {
    setValue('videoContain', !videoContain);
  };

  const handleEditAnswerTimeClicked = useCallback(
    e => {
      if (e) {
        e.stopPropagation();
      }
      track('Answers Time Click', {
        state: isEditingAnswersTime ? 'save' : 'edit',
      });

      setEditingAnswersTime(!isEditingAnswersTime);
      const videoRefCurrent = videoRef?.current;

      if (!videoRefCurrent) {
        return;
      }

      if (isEditingAnswersTime) {
        setAnswerTime?.(answerTime);
        videoRefCurrent.play();
        return;
      }
      videoRefCurrent.pause();
      videoRefCurrent.currentTime = answerTime;
    },
    [isEditingAnswersTime, setEditingAnswersTime, answerTime, setAnswerTime]
  );

  const handleCancelEditTime = useCallback(
    e => {
      e.stopPropagation();
      setEditingAnswersTime(!isEditingAnswersTime);
      const videoRefCurrent = videoRef?.current;
      setAnswerTime(step?.answerTime);

      if (!videoRefCurrent) {
        return;
      }

      videoRefCurrent.play();
    },
    [videoRef, isEditingAnswersTime, setEditingAnswersTime, setAnswerTime]
  );

  const onEditSubtitlesClick = () => {
    history.push({
      pathname: `${Routes.getSubtitlesEditorBaseRoute()}/${projectId}/${step.id || ''}`,
      state: {
        startFromTemplateExcluded: true,
        reply,
        video,
      },
    });
  };

  const videoTooShortForTrimming = videoDuration <= MINIMUM_TRIMMING_SECONDS;
  const isDuplicateVideo = video?.stockAsset?.videoUrl?.includes('gotolstoy.com');
  const isStockAsset = video?.stockAsset && !video?.stockAsset?.hasOriginal && !isDuplicateVideo;
  const isTrimmingDisabled = isLoading || !isVideoReady || videoTooShortForTrimming || isStockAsset;

  const onTrimVideoClick = () => {
    track('Trim Video Click');
    if (!reply) {
      clearModalState();
    }

    history.push({
      pathname: `${Routes.getVideoTrimmingBaseRoute()}/${video.id}`,
      state: {
        path: history.location.pathname,
        projectId: projectId,
        stepId: step.id,
        reply,
        from: 'builder-edit-step',
      },
    });
  };

  const getTrimmingTooltipText = () => {
    if (videoTooShortForTrimming) {
      return 'Video is too short to trim';
    }

    if (isStockAsset) {
      return 'Cant trim ready-made';
    }

    if (isVideoReady) {
      return 'Trimming';
    }

    return "Please wait, we're building the video";
  };

  const getPlacement = key => {
    if (key === 'Buttons appearance time' && isEditingAnswersTime) {
      return 'top';
    }
    if (isMobilePreview) {
      return 'left';
    }
    return 'right';
  };

  const toolbarSettings = [
    {
      key: 'Buttons appearance time',
      tooltipText: 'Buttons appearance time',
      icon: isEditingAnswersTime ? <FilledClockIcon /> : <ClockIcon />,
      hidden: isFeed,
      onClick: handleEditAnswerTimeClicked,
      disabled: false,
    },
    {
      key: 'Trim',
      tooltipText: getTrimmingTooltipText(),
      icon: <TrimIcon />,
      hidden: false,
      onClick: onTrimVideoClick,
      disabled: isTrimmingDisabled,
    },
    {
      key: 'Captions',
      tooltipText: 'Captions',
      icon: <CaptionsIcon />,
      hidden: isMacro || isFeed,
      onClick: onEditSubtitlesClick,
      disabled: false,
      dataTestId: EDIT_CAPTIONS_DATA_TEST_ID,
    },
    {
      key: 'Fit to Screen',
      tooltipText: 'Fit to Screen',
      icon: <VideoContainIcon />,
      hidden: !videoContain,
      onClick: toggleVideoContain,
      disabled: false,
    },
    {
      key: 'Fill Screen',
      tooltipText: 'Fill Screen',
      icon: <FillScreenIcon />,
      hidden: videoContain,
      onClick: toggleVideoContain,
      disabled: false,
    },
    {
      key: 'Change Video',
      tooltipText: 'Change Video',
      icon: <MovieIcon />,
      hidden: false,
      onClick: onChangeVideoClick,
      disabled: false,
    },
    {
      key: 'Download',
      tooltipText: 'Download',
      icon: (
        <ComponentWithLoader isLoading={downloadVideoLoading} size={'auto'}>
          <DownloadIcon />
        </ComponentWithLoader>
      ),
      hidden: false,
      onClick: () => downloadVideo(video),
      disabled: downloadVideoLoading,
    },
  ];

  const buttonsAppearanceTimePopover = useMemo(() => {
    return (
      <PopoverButtonsAppearanceTime isOpen={isEditingAnswersTime}>
        <StyledEditAnswersTimeContainer isEditing={isEditingAnswersTime}>
          <AnswerTimeDisplay>{secondsToMinutesSecondsDisplay(answerTime)}</AnswerTimeDisplay>
          <EditAnswersTimeButton
            isVideoReady={isVideoReady}
            handleCancelEditTime={handleCancelEditTime}
            isEditing={isEditingAnswersTime}
            onChangeTime={handleEditAnswerTimeClicked}
            rounded={true}
          />
        </StyledEditAnswersTimeContainer>
      </PopoverButtonsAppearanceTime>
    );
  }, [
    isEditingAnswersTime,
    answerTime,
    isVideoReady,
    handleCancelEditTime,
    handleEditAnswerTimeClicked,
  ]);

  if (!videoLoaded) {
    return null;
  }

  return (
    <LayoutRoot isMobilePreview={isMobilePreview}>
      <SettingsToolbar
        isMobilePreview={isMobilePreview}
        isSettingButtonsAppearOpen={isEditingAnswersTime}
        {...props}
      >
        {buttonsAppearanceTimePopover}
        {toolbarSettings.map(
          ({ key, tooltipText, icon, hidden, onClick, disabled, dataTestId }) => {
            if (hidden) return;

            const onTooltipClick = e => {
              e.stopPropagation();
              if (disabled) {
                return;
              }
              onClick(e);
            };

            return (
              <Tooltip
                key={key}
                placement={getPlacement(key)}
                text={tooltipText}
                onClick={onTooltipClick}
                data-test-id={dataTestId}
              >
                <IconContainer>{icon}</IconContainer>
              </Tooltip>
            );
          }
        )}
      </SettingsToolbar>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  display: grid;
  grid-row: 1;
  grid-column: 1;
  z-index: 3;
  align-items: center;
  width: 0px;
`;

const SettingsToolbar = styled(VerticalFlex)`
  background: ${({ theme }) => theme.getHexOpacity(theme.colors.white, 80)};
  box-shadow: -2px 0px 9px -3px #00000040;
  text-align: center;
  padding: 4px 0px;
  border-radius: ${({ isSettingButtonsAppearOpen }) =>
    isSettingButtonsAppearOpen ? '0px 0px 16px 0px' : '0px 16px 16px 0px'};

  ${({ isMobilePreview }) => isMobilePreview && mobilePreviewCss}
`;

const mobilePreviewCss = css`
  position: relative;
  left: -100%;
  border-radius: 16px 0px 0px 16px;
  background: ${({ theme }) => theme.colors.white};
`;

const IconContainer = styled(HorizontalFlexWrap)`
  padding: 10px 12px;
  cursor: pointer;

  & svg {
    width: 16px;
    height: 16px;
  }

  & path {
    fill: ${({ theme }) => theme.colors.black};
  }

  &:hover svg {
    transition: all 0.3s ease-in-out;
    transform: scale(1.2);
  }
`;

const PopoverButtonsAppearanceTime = styled(HorizontalFlexWrap)`
  position: relative;
  left: 100%;
`;

const StyledEditAnswersTimeContainer = styled(Gap8HorizontalFlexWrap)`
  align-items: center;
  position: absolute;
  top: -4px;
  width: max-content;
  background: ${({ theme }) => theme.getHexOpacity(theme.colors.white, 80)};
  border-radius: 0px 16px 16px 0px;
  padding: 6px 8px;
  height: 40px;
  box-shadow: 6px 0px 9px -3px #00000040;
  transition: opacity 0.5s ease-in-out;

  opacity: ${({ isEditing }) => (isEditing ? 1 : 0)};
  pointer-events: ${({ isEditing }) => (isEditing ? 'auto' : 'none')};
`;

const AnswerTimeDisplay = styled.div`
  padding: 4px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.gray2};
`;

export default VideoSettingsToolbar;
