export const POWERED_BY_URL =
  'https://www.gotolstoy.com/?utm_source=watermark&utm_medium=player&utm_campaign=logo_header';

export const IS_SHOPIFY_STORE = 'isShopifyStore';
export const SHOPIFY_STORE_URL_PARAM = 'shopifyStoreUrl';
export const SHOPIFY_ROOT_ROUTE_PARAM = 'shopifyRootRoute';
export const CURRENT_PAGE_DB_PRODUCT_ID_URL_PARAM = 'currentPageDbProductId';
export const PRODUCT_ID_URL_PARAM = 'productId';
export const VARIANT_ID_URL_PARAM = 'variant';
export const IS_TAPCART_URL_PARAM = 'isTapcart';
export const TOLSTOY_VIDEO_ID_URL_PARAM = 'tolstoyStartVideo';
export const TOLSTOY_AUTO_OPEN_URL_PARAM = 'tolstoyAutoOpen';
export const SHOPIFY_PREVIEW_DOMAIN_NAME = 'shopifypreview.com';
