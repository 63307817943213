import React from 'react';
import styled from 'styled-components';
import { TextSmall } from 'shared/react/components/basic/text/TextV2';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';

const FeedMultipleProductsDivider = ({ settings = {}, index, productsWithVariants }) => {
  const [, { t }] = useTranslation('feed.cart');
  const { divider, dividerTitle } = settings;

  const isDividerEnabled = divider?.enabled ?? true;
  const dividerTitleText = dividerTitle?.text ?? t('relatedProducts');
  const isDividerTitleEnabled = !index && (dividerTitle?.enabled ?? true);
  if (
    !isDividerEnabled ||
    productsWithVariants?.length <= 1 ||
    productsWithVariants?.length - 1 === index
  ) {
    return null;
  }

  if (!isDividerTitleEnabled) {
    return <Divider />;
  }

  return (
    <div>
      {isDividerEnabled && isDividerTitleEnabled && <Divider />}
      {isDividerTitleEnabled && <DividerTitle>{dividerTitleText}</DividerTitle>}
      {isDividerEnabled && isDividerTitleEnabled && <Divider />}
    </div>
  );
};

const Divider = styled.div`
  min-height: 1px;
  background-color: ${({ theme }) => theme.colors.grayscaleGrey4};
  margin-left: -24px;
  margin-right: -24px;
`;

const DividerTitle = styled(TextSmall)`
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
  padding: 16px 0 16px;
`;

export default FeedMultipleProductsDivider;
