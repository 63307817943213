import { ComparisonOperator, WhereCondition } from 'app/src/pages/dashboard/utils/filterEntities';
import {
  SearchFilterName,
  SearchVodAssetsRequest,
  SearchVodAssetsRequestFilter,
  SortDirection,
  TaggedInFilterValues,
} from 'app/src/pages/dashboard/pages/videos/hooks/search/searchRequests';
import { FilterKeys } from 'app/src/pages/dashboard/components/filters/filtersConfig';

class RequestFilters {
  private satisfies: SearchVodAssetsRequestFilter[] = [];
  private notSatisfies: SearchVodAssetsRequestFilter[] = [];

  addSatisfies = (filter: SearchVodAssetsRequestFilter) => {
    this.satisfies.push(filter);
  };

  addNotSatisfies = (filter: SearchVodAssetsRequestFilter) => {
    this.notSatisfies.push(filter);
  };

  addFilter = (isSatisfies: boolean, filter: SearchVodAssetsRequestFilter) => {
    if (isSatisfies) {
      this.addSatisfies(filter);
      return;
    }

    this.addNotSatisfies(filter);
  };

  addFromSatisfiesArgs = (name: SearchFilterName, operator: ComparisonOperator) => {
    const { isSatisfies, args } = getSatisfiesArgs(operator);
    if (!args.length) {
      return;
    }

    this.addFilter(isSatisfies, { name, filterValue: args });
  };

  addFromInOperator = (name: SearchFilterName, operator: ComparisonOperator) => {
    const isSatisfies = !!operator.in;
    const values = isSatisfies ? operator.in : operator.notIn;
    if (!values?.length) {
      return;
    }

    this.addFilter(isSatisfies, { name, filterValue: values });
  };

  getFilterFields = () => {
    return { satisfies: this.satisfies, notSatisfies: this.notSatisfies };
  };
}

const getSatisfiesArgs = (operator: ComparisonOperator) => {
  const isSatisfies = !!operator.satisfies;
  const args = isSatisfies ? operator.satisfies.args : operator.notSatisfies.args;
  return { isSatisfies, args: args || [] };
};

const addTypeToRequestFilters = (operator: ComparisonOperator, requestFilters: RequestFilters) => {
  const assetType = operator.in.filter(value => !!value)[0];
  if (!assetType) {
    return;
  }

  requestFilters.addSatisfies({
    name: SearchFilterName.type,
    filterValue: assetType,
  });
};

const addNameToRequestFilters = (operator: ComparisonOperator, requestFilters: RequestFilters) => {
  const text = operator.contains;
  if (!text) {
    return;
  }

  requestFilters.addSatisfies({
    name: SearchFilterName.text,
    filterValue: text,
  });
};

const addHasSuggestionsToRequestFilters = (requestFilters: RequestFilters, appUrl: string) => {
  requestFilters.addSatisfies({
    name: SearchFilterName.tagged,
    filterValue: { taggedIn: [TaggedInFilterValues.suggestedProducts], shopifyUrl: appUrl },
  });
};

const addTaggedToRequestFilters = (
  operator: ComparisonOperator,
  requestFilters: RequestFilters,
  appUrl: string
) => {
  const { isSatisfies, args } = getSatisfiesArgs(operator);
  if (!args.length) {
    return;
  }

  const filterValue = { taggedIn: args, shopifyUrl: appUrl };
  requestFilters.addFilter(isSatisfies, { name: SearchFilterName.tagged, filterValue });
};

const addDateToRequestFilters = (operator: ComparisonOperator, requestFilters: RequestFilters) => {
  const { isSatisfies, args } = getSatisfiesArgs(operator);
  if (!args.length) {
    return;
  }

  const [start, end] = args;
  requestFilters.addFilter(isSatisfies, {
    name: SearchFilterName.date,
    filterValue: { start: start.toISOString(), end: end.toISOString() },
  });
};

const compileFilterKey = (
  filterKey: string,
  operator: ComparisonOperator,
  requestfilters: RequestFilters,
  appUrl: string
): void => {
  switch (filterKey) {
    case FilterKeys.type:
      return addTypeToRequestFilters(operator, requestfilters);
    case FilterKeys.name:
      return addNameToRequestFilters(operator, requestfilters);
    case FilterKeys.source:
      return requestfilters.addFromSatisfiesArgs(SearchFilterName.source, operator);
    case FilterKeys.hasSuggestions:
      return addHasSuggestionsToRequestFilters(requestfilters, appUrl);
    case FilterKeys.tagged:
      return addTaggedToRequestFilters(operator, requestfilters, appUrl);
    case FilterKeys.products:
      return requestfilters.addFromSatisfiesArgs(SearchFilterName.products, operator);
    case FilterKeys.labels:
      return requestfilters.addFromSatisfiesArgs(SearchFilterName.labels, operator);
    case FilterKeys.tags:
      return requestfilters.addFromSatisfiesArgs(SearchFilterName.tags, operator);
    case FilterKeys.owner:
      return requestfilters.addFromInOperator(SearchFilterName.owner, operator);
    case FilterKeys.appExternalId:
      return requestfilters.addFromInOperator(SearchFilterName.appExternalId, operator);
    case FilterKeys.uploadType:
      return requestfilters.addFromInOperator(SearchFilterName.uploadType, operator);
    case FilterKeys.date:
      return addDateToRequestFilters(operator, requestfilters);
  }
};

export const compileVodAssetSearchRequest = (
  appKey: string,
  appUrl: string,
  videosFilters: WhereCondition,
  dateSortDirection: SortDirection
): Omit<SearchVodAssetsRequest, 'size' | 'from'> => {
  // intentionally leave this console log here
  // This feature is WIP and not available on prod yet.
  const requestFilters = new RequestFilters();

  for (const [filterKey, operator] of Object.entries(videosFilters)) {
    compileFilterKey(filterKey, operator, requestFilters, appUrl);
  }

  return {
    ...requestFilters.getFilterFields(),
    dateSort: dateSortDirection,
    appKey: appKey,
  };
};
