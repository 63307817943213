import { DEFAULT_PDP_CONFIG } from 'app/src/constants/project.constants';
import { SHAPE_TYPES } from 'app/src/pages/project/pages/look_and_feel/common';
import {
  DEFAULT_CAROUSEL_ITEM_HEIGHT,
  DEFAULT_CAROUSEL_ITEMS_PER_ROW,
  DEFAULT_CAROUSEL_PADDING_HORIZONTAL,
  DEFAULT_CAROUSEL_PADDING_VERTICAL,
  DEFAULT_CAROUSEL_SPACING_HORIZONTAL,
  DEFAULT_CAROUSEL_TILE_NAME_BACKGROUND_TRANSPARENCY,
  DEFAULT_CAROUSEL_TILE_NAME_LOCATION,
  DEFAULT_COLLECT_FIELDS,
  DEFAULT_COLLECT_TEXT,
  DEFAULT_PRODUCT_DETAILS_SUBSCRIPTIONS_DESCRIPTION_LEFT,
  DEFAULT_PRODUCT_DETAILS_SUBSCRIPTIONS_DESCRIPTION_RIGHT,
  DEFAULT_STORIES_ITEMS_PER_ROW,
  DEFAULT_STORIES_ITEMS_SIZE_TYPE,
  DEFAULT_STORIES_ITEMS_SPACING,
  DEFAULT_STORIES_SIZE,
  STORIES_ITEMS_SHAPE_TYPES,
  EMAIL_KEY,
  EMBED_CAROUSEL_ITEM_SIZE_TYPE,
  EMBED_CAROUSEL_MOTION,
  EMBED_STORY_MOTION,
  STORIES_BORDER_TYPES,
  CAROUSEL_TYPE,
  STORIES_LAYOUT_ITEMS_SHAPE_PROPERTY_KEY,
  STORIES_LAYOUT_ITEMS_RECTANGLE_SIZE_WIDTH_PROPERTY_KEY,
  STORIES_LAYOUT_ITEMS_RECTANGLE_SIZE_HEIGHT_PROPERTY_KEY,
  SPOTLIGHT_CAROUSEL_CONTENT_TILE_TYPE,
  STORIES_NAME_LOCATION,
  TILE_SHOULD_SHOW_CAPTIONS_PROPERTY_KEY,
} from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';

import {
  FEED_BEHAVIOUR_DEFAULT_SETTINGS,
  FEED_BEHAVIOUR_SHOPPING_BAG_ENABLED_TYPE,
} from 'shared/react/constants/feature-constants/feedCheckoutCustomization.constants';
import {
  DEFAULT_CAROUSEL_EMBED_PLAY_BUTTON_BACKGROUND_COLOR,
  DEFAULT_CAROUSEL_EMBED_PLAY_BUTTON_BORDER_COLOR,
  DEFAULT_CAROUSEL_EMBED_PLAY_BUTTON_OPACITY,
  DEFAULT_PLAY_BUTTON_START_SCREEN_TEXT,
} from 'shared/react/constants/feature-constants/playButtonCustomization.constants';
import { PRODUCT_CTA_TYPES } from 'shared/react/constants/feed.constants';
import { ONSITE_TARGET_PAGES } from 'app/src/pages/dashboard/constants/onsiteTargetPages.constants';
import Utils from 'app/src/utils';

const DEFAULT_HERO_SETTINGS = {
  captions: false,
  captionLocation: 'center',
  videoSizeType: 'responsive',
  width: 0,
  height: 0,
  layoutSettings: {
    desktopPosition: 'Middle Left',
    mobilePosition: 'Middle Left',
    desktopAlignment: 'center',
    mobileAlignment: 'center',
    stackButtonsOnMobile: false,
    hideTextAndButtonsOnMobile: false,
    buttonSpacing: 8,
    verticalSpacing: 8,
  },
  headerTextCustomization: {
    text: '',
    font: '',
    desktopFontSize: 24,
    mobileFontSize: 24,
    fontWeight: 600,
    color: '#000000',
  },
  subheaderTextCustomization: {
    text: '',
    font: '',
    desktopFontSize: 16,
    mobileFontSize: 16,
    fontWeight: 400,
    color: '#000000',
  },
  primaryButton: {
    enabled: false,
    text: '',
    color: '#000000',
    backgroundColor: '#FFFFFF',
    font: '',
    fontWeight: 400,
    desktopFontSize: 16,
    mobileFontSize: 16,
    borderRadius: 0,
    horizontalPadding: 8,
    verticalPadding: 8,
  },
  secondaryButton: {
    enabled: false,
    text: '',
    color: '#000000',
    backgroundColor: '#FFFFFF',
    font: '',
    desktopFontSize: 16,
    fontWeight: 400,
    mobileFontSize: 16,
    borderRadius: 0,
    horizontalPadding: 8,
    verticalPadding: 8,
  },
};

export const getCustomizationSettings = (
  project,
  playerStartText,
  playerControlsSettings,
  theme,
  contactFormSettings,
  textSettings,
  primaryButtonSettings
) => {
  if (!project) {
    return null;
  }

  const { fallbackCollectFields, fallbackCollectText } = getCollectDefaults(contactFormSettings);

  const { bodyColorText: accountTextColor } = textSettings;
  const {
    backgroundColor: accountButtonBackgroundColor,
    textColor: accountButtonTextColor,
    border: { color: accountButtonBorderColor },
  } = primaryButtonSettings;

  const {
    chaptersEnabled,
    widgetPosition,
    verticalOrientation,
    widgetHeader,
    widgetUrls,
    widgetBorderColor,
    widgetShape,
    widgetNotificationBadge,
    widgetSettings,
    fastForwardEnabled,
    subtitlesEnabled,
    chatLandingPage,
    popupMode,
    collectFields,
    cacheLeadFormAnswers,
    collectAfterAnyResponse,
    collectAfterStep,
    collectText,
    feed,
    startScreenText,
    feedAutoScroll,
    showButtonsIndex,
    feedProductCtaType,
    playerSettings,
    pdpConfig,
    targetPage,
    heroSettings,
    emailSettings,
    videoCollectionCampaignSettings,
  } = project;

  const customizationSettings = {
    chaptersEnabled,
    widgetPosition: widgetPosition || 'bottomRight',
    verticalOrientation: verticalOrientation ?? true,
    widgetHeader,
    widgetUrls: widgetUrls || [],
    border: !!widgetBorderColor,
    widgetShape: widgetShape || SHAPE_TYPES.circle,
    widgetSize: project.widgetSize || 'm',
    widgetNotificationBadge: !!widgetNotificationBadge,
    fastForwardEnabled,
    subtitlesEnabled,
    chatLandingPage: !!chatLandingPage,
    collectFields: collectFields || fallbackCollectFields,
    cacheLeadFormAnswers,
    collectAfterAnyResponse,
    collectAfterStep,
    collectText: collectText || fallbackCollectText,
    popupMode: !!popupMode,
    feed,
    startScreenText: startScreenText ?? playerStartText ?? DEFAULT_PLAY_BUTTON_START_SCREEN_TEXT,
    feedAutoScroll,
    showButtonsIndex,
    feedProductCtaType: feedProductCtaType || PRODUCT_CTA_TYPES.addToCart,
    pdpConfig: pdpConfig || DEFAULT_PDP_CONFIG,
    targetPage,
  };

  const parsedWidgetSettings = JSON.parse(widgetSettings || '{}');
  const {
    themeColor,
    themeTextColor,
    border,
    bubbleText,
    bubbleTextEnabled,
    autoplayEnabled,
    slideEnabled,
    slideFrom,
    carouselShape,
    carouselBorderRadius,
    carouselMotion,
    carouselBorderColor,
    carouselBorderWidth,
    carouselBorder,
    carouselFontSize,
    carouselFontColor,
    carouselTitle,
    carouselTitleText,
    carouselTitleFontWeight,
    carouselTileNameEnabled,
    carouselTileNameLocation,
    carouselTileNameTextSize,
    carouselTileNameTextColor,
    carouselTileNameBackgroundEnabled,
    carouselTileNameBackgroundColor,
    carouselTileNameBackgroundTransparancy,
    carouselPlayButtonBackground,
    carouselPlayButtonBackgroundColor,
    carouselPlayButtonBorder,
    carouselPlayButtonBorderColor,
    carouselPlayButtonOpacity,
    carouselPlayInTileFirst,
    carouselItemSizeType,
    carouselItemsPerRow,
    carouselItemHeight,
    carouselSpacingHorizontal,
    carouselPaddingHorizontal,
    carouselPaddingVertical,
    isFullscreenFeed,
    keepSettingsOnMobile,
    widgetHeaderLocation,
    storiesBorderRadius,
    storiesBorderEnabled,
    storiesBorderWidth,
    storiesBorderColor,
    storiesBorderType,
    storiesBorderOpacity,
    storiesTitleEnabled,
    storiesTitleText,
    storiesTitleFontSize,
    storiesMobileTitleFontSize,
    storiesTitleFontWeight,
    storiesTitleColor,
    storiesStoryNameType,
    storiesStoryNameTextAlign,
    storiesStoryNameAlignment,
    storiesStoryNamePadding,
    storiesStoryNameFontWeight,
    storiesStoryNameEnabled,
    storiesStoryNameFontSize,
    storiesStoryNameColor,
    storiesAlignment,
    storiesMotion,
    storiesItemsSizeType,
    storiesItemsPerRow,
    storiesCircleSize,
    storiesItemsSpacing,
    storiesItemsShape,
    storiesRectangleWidth,
    storiesRectangleHeight,
    bubbleObjectPosition,
    bubbleMarginBottom,
    bubbleMarginSide,
    minimumNumberOfTiles,
    carouselType,
    spotlightSettings,
    spotlightCarouselContentTileType,
    spotlightCarouselContentTileFallback,
    tileHorizontalMargin,
    tileVerticalMargin,
    tileBorderRadius,
    tileShouldShowControls,
    tileShouldShowCaptions,
    tilePlayButtonHasBackground,
    tilePlayButtonBackgroundColor,
    tilePlayButtonBackgroundOpacity,
    tilePlayButtonHasBorder,
    tilePlayButtonBorderColor,
  } = parsedWidgetSettings;

  const parsedPlayerSettings = JSON.parse(playerSettings || '{}');
  const {
    productDetailsPriceEnabled,
    productDetailsCheckoutShoppingBagEnabled,
    productDetailsShippingEnabled,
    productDetailsShippingText,
    productDetailsShippingFontColor,
    productDetailsShippingFontWeight,
    productDetailsSalesBadgeEnabled,
    productDetailsSalesBadgeText,
    productDetailsSalesBadgeFontColor,
    productDetailsSalesBadgeBackgroundColor,
    productDetailsSalesBadgeBorderEnabled,
    productDetailsSalesBadgeBorderWidth,
    productDetailsSalesBadgeBorderColor,
    productDetailsStockEnabled,
    productDetailsStockInColor,
    productDetailsStockOutColor,
    productDetailsRecommendedProductsEnabled,
    productDetailsRecommendedProductsTitle,
    productDetailsRecommendedProductsNameEnabled,
    productDetailsRecommendedProductsPriceEnabled,
    productDetailsSubscriptionsEnabled,
    productDetailsSubscriptionsHighlightRecurringEnabled,
    productDetailsSubscriptionsDescriptionLeft,
    productDetailsSubscriptionsDescriptionRight,
    productDescriptionCollapsedByDefault,
    productDescriptionAlwaysShow,
    isShoppable,
    videosFromLastXDays,
    showDescriptionIfNoProducts,
    feedStartOnMute,
    multipleProductsSettings,
    hideWishlist,
    disableMultiProductView,
  } = parsedPlayerSettings;

  const { black, black1, white, red4 } = theme.colors;

  customizationSettings.widgetSettings = {
    themeColor: themeColor || accountButtonBackgroundColor || black,
    bubbleTextEnabled,
    themeTextColor: themeTextColor || accountButtonTextColor || theme.colors.white,
    border,
    autoplayEnabled: autoplayEnabled || false,
    bubbleText: bubbleText || 'Chat with me',
    slideEnabled: slideEnabled ?? true,
    slideFrom: slideFrom || 'side',
    carouselShape: carouselShape || '9/16',
    carouselBorderRadius: carouselBorderRadius ?? 0,
    carouselMotion: carouselMotion || EMBED_CAROUSEL_MOTION.dynamic,
    carouselBorderColor: carouselBorderColor || accountButtonBorderColor || black,
    carouselBorderWidth: carouselBorderWidth || 2,
    carouselBorder: carouselBorder || false,
    carouselFontSize: carouselFontSize || 20,
    carouselFontColor: carouselFontColor || accountTextColor || black,
    carouselTitle: carouselTitle ?? carouselType !== CAROUSEL_TYPE.SPOTLIGHT,
    carouselTitleText: carouselTitleText || 'Shop the feed',
    carouselTitleFontWeight: carouselTitleFontWeight || 700,
    carouselTileNameEnabled: carouselTileNameEnabled || false,
    carouselTileNameLocation: carouselTileNameLocation || DEFAULT_CAROUSEL_TILE_NAME_LOCATION,
    carouselTileNameTextSize: carouselTileNameTextSize || 16,
    carouselTileNameTextColor: carouselTileNameTextColor || accountTextColor || black,
    carouselTileNameBackgroundEnabled: carouselTileNameBackgroundEnabled || false,
    carouselTileNameBackgroundColor: carouselTileNameBackgroundColor || black,
    carouselTileNameBackgroundTransparancy:
      carouselTileNameBackgroundTransparancy ?? DEFAULT_CAROUSEL_TILE_NAME_BACKGROUND_TRANSPARENCY,
    carouselPlayInTileFirst: carouselPlayInTileFirst || false,
    carouselPlayButtonBackground: carouselPlayButtonBackground ?? false,
    carouselPlayButtonBackgroundColor:
      carouselPlayButtonBackgroundColor ?? DEFAULT_CAROUSEL_EMBED_PLAY_BUTTON_BACKGROUND_COLOR,
    carouselPlayButtonBorder: carouselPlayButtonBorder ?? true,
    carouselPlayButtonBorderColor:
      carouselPlayButtonBorderColor ?? DEFAULT_CAROUSEL_EMBED_PLAY_BUTTON_BORDER_COLOR,
    carouselPlayButtonOpacity:
      carouselPlayButtonOpacity ?? DEFAULT_CAROUSEL_EMBED_PLAY_BUTTON_OPACITY,
    carouselItemSizeType: carouselItemSizeType ?? EMBED_CAROUSEL_ITEM_SIZE_TYPE.responsive,
    carouselItemsPerRow: carouselItemsPerRow ?? DEFAULT_CAROUSEL_ITEMS_PER_ROW,
    carouselItemHeight: carouselItemHeight ?? DEFAULT_CAROUSEL_ITEM_HEIGHT,
    carouselSpacingHorizontal: carouselSpacingHorizontal ?? DEFAULT_CAROUSEL_SPACING_HORIZONTAL,
    carouselPaddingHorizontal: carouselPaddingHorizontal ?? DEFAULT_CAROUSEL_PADDING_HORIZONTAL,
    carouselPaddingVertical: carouselPaddingVertical ?? DEFAULT_CAROUSEL_PADDING_VERTICAL,
    isFullscreenFeed: isFullscreenFeed ?? false,
    keepSettingsOnMobile: keepSettingsOnMobile ?? false,
    widgetHeaderLocation: widgetHeaderLocation || 'center',
    storiesBorderRadius: storiesBorderRadius ?? 104,
    storiesBorderEnabled: storiesBorderEnabled ?? true,
    storiesBorderWidth: storiesBorderWidth ?? 1,
    storiesBorderColor: storiesBorderColor || accountButtonBorderColor || theme.colors.pink9,
    storiesBorderType: storiesBorderType || STORIES_BORDER_TYPES.solid,
    storiesBorderOpacity: storiesBorderOpacity ?? 1,
    storiesTitleEnabled: storiesTitleEnabled ?? true,
    storiesTitleText: storiesTitleText || 'Shop the feed',
    storiesTitleFontSize: storiesTitleFontSize || 16,
    storiesMobileTitleFontSize: storiesMobileTitleFontSize || 16,
    storiesTitleFontWeight: storiesTitleFontWeight || 700,
    storiesTitleColor: storiesTitleColor || accountTextColor || black,
    storiesStoryNameEnabled: storiesStoryNameEnabled ?? false,
    storiesStoryNameType: storiesStoryNameType || STORIES_NAME_LOCATION.below,
    storiesStoryNameTextAlign: storiesStoryNameTextAlign || 'center',
    storiesStoryNameAlignment: storiesStoryNameAlignment || 'end',
    storiesStoryNamePadding: storiesStoryNamePadding ?? '8px 8px',
    storiesStoryNameFontWeight: storiesStoryNameFontWeight || 600,
    storiesStoryNameFontSize: storiesStoryNameFontSize || 12,
    storiesStoryNameColor: storiesStoryNameColor || accountTextColor || black,
    storiesAlignment:
      storiesAlignment || (targetPage === ONSITE_TARGET_PAGES.ProductPages ? 'left' : 'center'),
    storiesMotion: storiesMotion || EMBED_STORY_MOTION.dynamic,
    storiesItemsSizeType: storiesItemsSizeType || DEFAULT_STORIES_ITEMS_SIZE_TYPE,
    storiesItemsPerRow: storiesItemsPerRow || DEFAULT_STORIES_ITEMS_PER_ROW,
    storiesCircleSize: storiesCircleSize || DEFAULT_STORIES_SIZE,
    storiesItemsSpacing: storiesItemsSpacing ?? DEFAULT_STORIES_ITEMS_SPACING,
    [STORIES_LAYOUT_ITEMS_SHAPE_PROPERTY_KEY]:
      storiesItemsShape || STORIES_ITEMS_SHAPE_TYPES.circle,
    [STORIES_LAYOUT_ITEMS_RECTANGLE_SIZE_WIDTH_PROPERTY_KEY]: storiesRectangleWidth || 80,
    [STORIES_LAYOUT_ITEMS_RECTANGLE_SIZE_HEIGHT_PROPERTY_KEY]: storiesRectangleHeight || 120,
    bubbleObjectPosition: bubbleObjectPosition || { x: 50, y: 50 },
    bubbleMarginBottom: bubbleMarginBottom ?? 24,
    bubbleMarginSide: bubbleMarginSide ?? 24,
    isShoppable: isShoppable ?? true,
    showCreatorProfileLink: parsedWidgetSettings.showCreatorProfileLink ?? false,
    carouselCreatorProfileLinkPosition: parsedWidgetSettings.carouselCreatorProfileLinkPosition,
    minimumNumberOfTiles: minimumNumberOfTiles || 1,
    videosFromLastXDays: !!videosFromLastXDays,
    carouselType: carouselType || CAROUSEL_TYPE.DEFAULT,
    spotlightCarouselContentTileFallback: spotlightCarouselContentTileFallback || '',
    spotlightSettings: spotlightSettings || {},
    spotlightCarouselContentTileType:
      spotlightCarouselContentTileType || SPOTLIGHT_CAROUSEL_CONTENT_TILE_TYPE.PRODUCT,
    tileHorizontalMargin: tileHorizontalMargin ?? 16,
    tileVerticalMargin: tileVerticalMargin ?? 0,
    tileBorderRadius: tileBorderRadius ?? 16,
    tileShouldShowControls: tileShouldShowControls ?? false,
    [TILE_SHOULD_SHOW_CAPTIONS_PROPERTY_KEY]: tileShouldShowCaptions ?? false,
    tilePlayButtonHasBackground: tilePlayButtonHasBackground ?? true,
    tilePlayButtonBackgroundColor: tilePlayButtonBackgroundColor || white,
    tilePlayButtonBackgroundOpacity: tilePlayButtonBackgroundOpacity || 0.5,
    tilePlayButtonHasBorder: tilePlayButtonHasBorder ?? false,
    tilePlayButtonBorderColor: tilePlayButtonBorderColor || black,
  };

  customizationSettings.playerSettings = {
    productDetailsPriceEnabled: productDetailsPriceEnabled ?? true,
    productDetailsCheckoutShoppingBagEnabled:
      productDetailsCheckoutShoppingBagEnabled ??
      playerControlsSettings.checkoutShoppingBagEnabled ??
      FEED_BEHAVIOUR_DEFAULT_SETTINGS[FEED_BEHAVIOUR_SHOPPING_BAG_ENABLED_TYPE],
    productDetailsShippingEnabled: productDetailsShippingEnabled ?? false,
    productDetailsShippingText: productDetailsShippingText || '',
    productDetailsShippingFontColor: productDetailsShippingFontColor || accountTextColor || black1,
    productDetailsShippingFontWeight: productDetailsShippingFontWeight || 400,
    productDetailsSalesBadgeEnabled: productDetailsSalesBadgeEnabled ?? true,
    productDetailsSalesBadgeText: productDetailsSalesBadgeText || 'Sale',
    productDetailsSalesBadgeFontColor:
      productDetailsSalesBadgeFontColor || accountButtonTextColor || white,
    productDetailsSalesBadgeBackgroundColor:
      productDetailsSalesBadgeBackgroundColor || accountButtonBackgroundColor || black,
    productDetailsSalesBadgeBorderEnabled: productDetailsSalesBadgeBorderEnabled ?? false,
    productDetailsSalesBadgeBorderWidth: productDetailsSalesBadgeBorderWidth ?? 0,
    productDetailsSalesBadgeBorderColor: productDetailsSalesBadgeBorderColor || black1,
    productDetailsStockEnabled: productDetailsStockEnabled ?? true,
    productDetailsStockInColor: productDetailsStockInColor || accountTextColor || black1,
    productDetailsStockOutColor: productDetailsStockOutColor || red4,
    productDetailsRecommendedProductsEnabled: productDetailsRecommendedProductsEnabled ?? true,
    productDetailsRecommendedProductsTitle:
      productDetailsRecommendedProductsTitle || 'You may also like',
    productDetailsRecommendedProductsNameEnabled:
      productDetailsRecommendedProductsNameEnabled ?? true,
    productDetailsRecommendedProductsPriceEnabled:
      productDetailsRecommendedProductsPriceEnabled ?? true,
    productDetailsSubscriptionsEnabled: productDetailsSubscriptionsEnabled ?? false,
    productDetailsSubscriptionsHighlightRecurringEnabled:
      productDetailsSubscriptionsHighlightRecurringEnabled ?? true,
    productDetailsSubscriptionsDescriptionLeft:
      productDetailsSubscriptionsDescriptionLeft ??
      DEFAULT_PRODUCT_DETAILS_SUBSCRIPTIONS_DESCRIPTION_LEFT,
    productDetailsSubscriptionsDescriptionRight:
      productDetailsSubscriptionsDescriptionRight ??
      DEFAULT_PRODUCT_DETAILS_SUBSCRIPTIONS_DESCRIPTION_RIGHT,
    productDescriptionCollapsedByDefault: productDescriptionCollapsedByDefault ?? false,
    productDescriptionAlwaysShow: productDescriptionAlwaysShow ?? false,
    isShoppable: isShoppable ?? true,
    videosFromLastXDays: !!videosFromLastXDays,
    hideWishlist: hideWishlist ?? false,
    showCreatorProfileLink: parsedPlayerSettings.showCreatorProfileLink ?? false,
    showDescriptionIfNoProducts: showDescriptionIfNoProducts ?? true,
    feedStartOnMute: feedStartOnMute ?? false,
    disableMultiProductView: disableMultiProductView ?? false,
    multipleProductsSettings,
  };

  const newHeroSettings = Utils.safeParse(heroSettings) || {};
  customizationSettings.heroSettings =
    { ...DEFAULT_HERO_SETTINGS, ...newHeroSettings } || DEFAULT_HERO_SETTINGS;
  customizationSettings.emailSettings = Utils.safeParse(emailSettings) || {};
  customizationSettings.videoCollectionCampaignSettings =
    Utils.safeParse(videoCollectionCampaignSettings) || {};

  return customizationSettings;
};

const getCollectDefaults = contactFormSettings => {
  let collectFields = DEFAULT_COLLECT_FIELDS;
  let collectText = DEFAULT_COLLECT_TEXT;

  if (contactFormSettings.ctaText !== undefined) {
    collectText = contactFormSettings.ctaText;
  }

  if (contactFormSettings.color !== undefined) {
    collectFields = DEFAULT_COLLECT_FIELDS.map(field => {
      if (field.name !== EMAIL_KEY) {
        return field;
      }

      return {
        ...field,
        backgroundColor: contactFormSettings.color,
      };
    });
  }

  return { fallbackCollectFields: collectFields, fallbackCollectText: collectText };
};
