import React from 'react';
import ChartBox from '../chart/ChartBox';
import ChartHeader from '../chart/ChartHeader';
import ComposedChart from 'app/src/complex_components/analytics/chart/ComposedChart';
import AnalyticsOverviewConversionRateTooltip from './AnalyticsOverviewConversionRateTooltip';
import useAnalyticsOverview from 'app/src/hooks/useAnalyticsOverview';
import Utils from 'app/src/utils';
import {
  ANALYTICS_OVERVIEW_BAR_CHARTS,
  ANALYTICS_OVERVIEW_STATS,
} from 'app/src/constants/analytics.constants';

const AnalyticsOverviewConversionRate = () => {
  const {
    conversionRatesOvertimeData,
    // formattedBaselineConversionRate,
    formattedTolstoyConversionRate,
    shouldShowConversionRatesEmptyState,
    shouldShowConversionRatesLoader,
  } = useAnalyticsOverview();
  const { title, bars, lines } = ANALYTICS_OVERVIEW_BAR_CHARTS.CONVERSION_RATE;

  // todo: uncomment when we have enough site activity data to show more accurate conversion rate
  // const statsRows = [
  //   {
  //     name: ANALYTICS_OVERVIEW_STATS.BASELINE_TOLSTOY_CONVERSION_RATE_USERS,
  //     formattedValue: formattedBaselineConversionRate,
  //   },
  // ];

  const yTickFormatter = (value: string) => {
    return Utils.formatNumber({ value, isPercentage: true });
  };

  return (
    <ChartBox shouldShowLoader={shouldShowConversionRatesLoader}>
      <ChartHeader
        subtitle={shouldShowConversionRatesEmptyState ? '' : formattedTolstoyConversionRate}
        title={title}
        titleTooltipText={ANALYTICS_OVERVIEW_STATS.CONVERSION_RATE_TOOLTIP}
      />
      {/*<ChartStats rows={statsRows} shouldShowEmptyState={shouldShowConversionRatesEmptyState} />*/}
      <ComposedChart
        data={conversionRatesOvertimeData}
        bars={bars}
        lines={lines}
        renderCustomTooltip={AnalyticsOverviewConversionRateTooltip}
        shouldShowEmptyState={shouldShowConversionRatesEmptyState}
        yTickFormatter={yTickFormatter}
      />
    </ChartBox>
  );
};

export default AnalyticsOverviewConversionRate;
