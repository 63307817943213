import React from 'react';
import VideoModal from 'app/src/complex_components/VideoModal';
import { PUBLISH_URL, TOLSTOY_PRICING_ONBOARDING_ID } from 'app/src/config/app.config';

const PricingOnboardingModal = ({ userEmail, ...props }) => {
  const src = `${PUBLISH_URL}/${TOLSTOY_PRICING_ONBOARDING_ID}?host&email=${userEmail}`;
  return <VideoModal src={src} {...props} />;
};

export default PricingOnboardingModal;
