import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useLeadFormEditorGroupContext } from 'app/src/pages/project/pages/look_and_feel/editor/editors/lead_form_editor/LeadFormEditorGroupContext';
import CollectField from 'app/src/pages/project/pages/look_and_feel/editor/editors/lead_form_editor/CollectField';
import PlusIcon from 'app/src/images/PlusIcon';
import Button from 'shared/react/components/complex/Button';
import Sizes from 'shared/react/theme/Sizes';
import Types from 'shared/react/theme/Types';
import { nanoid } from 'nanoid';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import {
  COLLECT_PROPERTY_KEY,
  NAME_PROPERTY_KEY,
} from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import TextInput from 'app/src/basic_components/TextInput';
import EditorMainContainer from '../shared/EditorMainContainer';
import EditorHeader from '../shared/EditorHeader';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const CustomFieldsEditor = () => {
  const theme = useTheme();
  const {
    customizationSettings: { collectFields },
  } = useLookAndFeelContext();
  const {
    leadFormSettings: { customFields },
    handleCollectFieldPropertyChanged,
    setCollectFields,
  } = useLeadFormEditorGroupContext();

  const defaultBackgroundColor = theme.colors.pink2;

  const handleAddCustomField = () => {
    const modifiedCollectFields = [...collectFields];
    modifiedCollectFields.push({
      type: 'text',
      custom: true,
      collect: true,
      required: false,
      backgroundColor: defaultBackgroundColor,
      key: nanoid(4),
    });
    setCollectFields(modifiedCollectFields);
  };

  const removeCustomField = key => {
    const newCollectFields = [...collectFields];
    const filteredCollectFields = newCollectFields.filter(field => {
      return field.key !== key;
    });

    setCollectFields(filteredCollectFields);
  };

  const handleNameChange = (key, value) => {
    handleCollectFieldPropertyChanged(key, NAME_PROPERTY_KEY, value);
  };

  return (
    <LayoutRoot>
      <EditorHeader title="Custom fields" isWhite={!customFields?.length}>
        <Button
          size={Sizes.Small}
          type={Types.Success}
          showRightIcon
          disabled={customFields.length > 4}
          padding="0 10px"
          rightIcon={<PlusIcon />}
          onClick={handleAddCustomField}
        >
          Add
        </Button>
      </EditorHeader>
      {customFields.length > 0 && (
        <EditorMainContainer>
          {customFields.map(({ name, key, collect, required }) => (
            <CollectField
              id={key}
              key={key}
              text={name}
              required={required}
              removeCustomField={() => removeCustomField(key)}
              renderTextLayout={() => (
                <StyledTextInput
                  value={name}
                  placeholder="Name"
                  onChange={e => handleNameChange(key, e.target.value)}
                  maxLength={25}
                />
              )}
              checked={collect}
              onChange={value =>
                handleCollectFieldPropertyChanged(key, COLLECT_PROPERTY_KEY, value)
              }
            />
          ))}
        </EditorMainContainer>
      )}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

const StyledTextInput = styled(TextInput)`
  max-width: 196px;
`;

export default CustomFieldsEditor;
