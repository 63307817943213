import Cases from 'app/src/pages/dashboard/components/cases/Cases';
import {
  CASE_TYPES_HOME_NO_SHOPIFY,
  CASE_TYPES_HOME_SHOP,
} from 'app/src/pages/dashboard/components/cases/cases.constants';
import React from 'react';
import { TextH1 } from 'shared/react/components/basic/text/TextV2';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { useApps } from 'src/context/AppsStore';
import styled from 'styled-components';

const OnboardingTemplatePicker = () => {
  const [{ shopify: isShopify }] = useApps();

  const getTypes = () => {
    if (!isShopify) {
      return CASE_TYPES_HOME_NO_SHOPIFY;
    }

    return CASE_TYPES_HOME_SHOP;
  };

  return (
    <LayoutRoot>
      <TextH1>Start with a popular use case</TextH1>
      <Content>
        <Cases types={getTypes()} isOnboarding />
      </Content>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  width: 100%;
  align-items: center;
`;

const Content = styled(HorizontalFlex)`
  gap: 110px;
  width: 100%;
`;

export default OnboardingTemplatePicker;
