import React from 'react';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import EditorHeader from '../shared/EditorHeader';
import OnOffToggleItem from '../shared/OnOffToggleItem';
import EditorMainContainer from '../shared/EditorMainContainer';
import EditorColorInputItem from '../shared/EditorColorInputItem';
import { useLookAndFeelContext } from '../../../LookAndFeelContext';
import {
  PRODUCT_DETAILS_STOCK_ENABLED_PROPERTY_KEY,
  PRODUCT_DETAILS_STOCK_IN_COLOR_PROPERTY_KEY,
  PRODUCT_DETAILS_STOCK_OUT_COLOR_PROPERTY_KEY,
} from '../../../LookAndFeelPage.constants';

const ProductDetailsStockEditor = () => {
  const {
    customizationSettings: {
      playerSettings: {
        productDetailsStockEnabled,
        productDetailsStockInColor,
        productDetailsStockOutColor,
      },
    },
    setPlayerSettingsProperty,
  } = useLookAndFeelContext();

  const onEnableStock = () => {
    setPlayerSettingsProperty(
      PRODUCT_DETAILS_STOCK_ENABLED_PROPERTY_KEY,
      !productDetailsStockEnabled
    );
  };

  const onStockInColorChange = value => {
    setPlayerSettingsProperty(PRODUCT_DETAILS_STOCK_IN_COLOR_PROPERTY_KEY, value);
  };

  const onStockOutColorChange = value => {
    setPlayerSettingsProperty(PRODUCT_DETAILS_STOCK_OUT_COLOR_PROPERTY_KEY, value);
  };

  return (
    <LayoutRoot>
      <EditorHeader title="Show in stock / out of stock" isWhite={!productDetailsStockEnabled}>
        <OnOffToggleItem checked={productDetailsStockEnabled} onChange={onEnableStock} />
      </EditorHeader>
      <EditorMainContainer shouldHide={!productDetailsStockEnabled}>
        <EditorColorInputItem
          text="In stock color"
          color={productDetailsStockInColor}
          onChange={onStockInColorChange}
        />
        <EditorColorInputItem
          text="Out of stock color"
          color={productDetailsStockOutColor}
          onChange={onStockOutColorChange}
        />
      </EditorMainContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

export default ProductDetailsStockEditor;
