import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { WindowScroller, AutoSizer, List as VirtualizedList } from 'react-virtualized';
import { BaseText } from 'shared/react/components/complex/Text';
import styled from 'styled-components';
import useAppFontPalette from 'app/src/hooks/apps-store/useAppFontPalette';
import { useUser } from 'app/src/context/userStore/UserStore';
import { useIsSelectedShopifyApp } from 'app/src/context/AppsStore';
import { track } from 'app/src/helpers/Tracker';
import { getDefaultFontSrc } from 'app/src/utils/branding.utils';
import FontListItem from '../FontListItem';
import UploadFont from '../UploadFont';
import APP_CONFIG from 'app/src/config/app.config';
import 'react-virtualized/styles.css';
import MenuWithContainer from 'shared/react/components/basic/MenuWithContainer';
import { SearchInput } from 'app/src/basic_components/input/InputWithIcon';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';

const GET_GOOGLE_FONTS_BASE_ADDRESS = 'https://www.googleapis.com/webfonts/v1/webfonts?key=';
const VIRTUALIZED_LIST_STYLE = {
  height: 400,
  width: 270,
  rowHeight: 44,
};

const filterOutPaletteFonts = (googleFonts, fontPalette) => {
  const paletteFonts = [];

  const filteredFonts = googleFonts.filter(googleFont => {
    const isInPalette = fontPalette.some(paletteFont => {
      const googleFontFamily = googleFont.family.toLowerCase();
      const paletteFontFamily = paletteFont.fontFamily.toLowerCase();

      return googleFontFamily === paletteFontFamily;
    });

    if (isInPalette) {
      paletteFonts.push(googleFont);
    }

    return !isInPalette;
  });

  return {
    paletteFonts,
    googleFonts: filteredFonts,
  };
};

const FontsMenu = ({
  selectedFontFamily,
  openButtonRef,
  loading,
  isFontUploadEnabled,
  onChange,
  onClose,
  weight,
  isMenuOpen,
}) => {
  const [containerRef, setContainerRef] = useState();
  const [fonts, setFonts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [scrollIndex, setScrollIndex] = useState(0);
  const [uploadingFont, setUploadingFont] = useState(false);

  const [{ account }] = useUser();
  const [isShopify] = useIsSelectedShopifyApp();
  const fontPalette = useAppFontPalette();
  const [paletteFonts, setPaletteFonts] = useState([]);

  const fetchFonts = async () => {
    const response = await axios.get(
      `${GET_GOOGLE_FONTS_BASE_ADDRESS}${APP_CONFIG.GOOGLE_FONTS_API_KEY}`
    );

    const fonts = response?.data?.items || [];
    const { paletteFonts, googleFonts } = isShopify
      ? filterOutPaletteFonts(fonts, fontPalette)
      : { googleFonts: fonts, paletteFonts: [] };

    setPaletteFonts(paletteFonts);
    setFonts(googleFonts);
  };

  const isFontIncludesTerm = font => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const lowerCaseFontFamily = font?.family?.toLowerCase();
    return lowerCaseFontFamily?.includes(lowerCaseSearchTerm);
  };

  function getFilteredFonts(fonts) {
    return searchTerm ? fonts.filter(isFontIncludesTerm) : fonts;
  }

  const filteredFonts = getFilteredFonts(fonts);
  const filteredPaletteFonts = getFilteredFonts(paletteFonts);

  const shouldShowShopifyFonts = isShopify && filteredPaletteFonts.length > 0;

  const handleFontItemClick = font => {
    const { family, files: sources } = font;
    track('Branding Font Item Click', { family });

    onChange({ family, sources });
    onClose();
  };
  const handleClearFontClick = () => onChange({ family: '', sources: {} });

  const renderFont = (font, { key, style }) => (
    <FontListItem
      key={key}
      style={style}
      isSelected={selectedFontFamily === font.family}
      font={font}
      onFontItemClick={handleFontItemClick}
    />
  );
  const renderVirtualFontItem = ({ index, key, style }) => {
    const filteredFont = filteredFonts[index];

    return renderFont(filteredFont, { key, style });
  };
  const renderPaletteFontItem = font => {
    const style = {
      height: VIRTUALIZED_LIST_STYLE.rowHeight,
    };

    return renderFont(font, { key: font.family, style });
  };

  const handleSearchTermChanged = e => {
    setSearchTerm(e.target.value);
  };

  const handleClearSearchTerm = () => {
    setSearchTerm('');
  };

  useEffect(() => {
    setScrollIndex(filteredFonts.findIndex(({ family }) => selectedFontFamily === family));
  }, [filteredFonts]);

  useEffect(() => {
    fetchFonts();
  }, []);

  return (
    <LayoutRoot onClose={onClose} open={isMenuOpen} anchorEl={openButtonRef?.current}>
      <HeaderContainer>
        <Header>Fonts</Header>
        <UploadFont
          fontSrc={getDefaultFontSrc({ account, family: selectedFontFamily })}
          loading={loading}
          onClear={handleClearFontClick}
          setFont={handleFontItemClick}
          uploading={uploadingFont}
          setUploading={setUploadingFont}
          disabled={!isFontUploadEnabled}
          weight={weight}
        />
      </HeaderContainer>
      <HorizontalSeparator />
      <SearchInput
        placeholder={'Search fonts'}
        value={searchTerm}
        onChange={handleSearchTermChanged}
        clearValue={handleClearSearchTerm}
      />
      <FontListContainer ref={setContainerRef}>
        {shouldShowShopifyFonts && (
          <ShopifyFontContainer>
            <ShopifyFontLabel>Your fonts from Shopify</ShopifyFontLabel>
            {filteredPaletteFonts.map(renderPaletteFontItem)}
          </ShopifyFontContainer>
        )}
        <HorizontalSeparator />
        <WindowScroller scrollElement={containerRef}>
          {({ height, onChildScroll, scrollTop }) => (
            <AutoSizer>
              {() => (
                <VirtualizedList
                  autoHeight
                  height={height}
                  width={VIRTUALIZED_LIST_STYLE.width}
                  scrollToIndex={scrollIndex}
                  rowRenderer={renderVirtualFontItem}
                  rowCount={filteredFonts.length}
                  rowHeight={VIRTUALIZED_LIST_STYLE.rowHeight}
                  onScroll={onChildScroll}
                  scrollTop={scrollTop}
                />
              )}
            </AutoSizer>
          )}
        </WindowScroller>
      </FontListContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(MenuWithContainer)`
  max-width: 300px;
  min-width: 282px;
  padding: 8px;
  gap: 8px;
`;

const Header = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralDark};
`;

const HorizontalSeparator = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.colors.gray3};
`;

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr repeat(2, auto);
  border-radius: 1px;
  align-items: center;
  padding: 0 8px;
`;

const ShopifyFontContainer = styled.div``;

const ShopifyFontLabel = styled(BaseText)`
  font-weight: 500;
  padding: 0 8px;
  margin: 0px 0 8px;

  color: ${({ theme }) => theme.colors.ghostDark};
`;

const FontListContainer = styled.div`
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 8px;
`;

export default FontsMenu;
