import { REACT_APP_API_BASE_URL } from '../config/shared.config';
import Utils from 'shared/react/utils/utils';

export const addProductToWishlist = async (productId, vodAssetId, appKey) => {
  try {
    const response = await fetch(
      `${REACT_APP_API_BASE_URL}/customers/actions/add-product-to-wishlist`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
        body: JSON.stringify({
          appKey,
          vodAssetId,
          externalProductId: productId,
        }),
      }
    );

    return await response.json();
  } catch (error) {
    Utils.logError('Error adding product to wishlist', error);
  }
};

export const removeProductFromWishlist = async productId => {
  try {
    const response = await fetch(
      `${REACT_APP_API_BASE_URL}/customers/actions/remove-product-from-wishlist`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
        body: JSON.stringify({ productId }),
      }
    );

    return await response.json();
  } catch (error) {
    Utils.logError('Error removing product from wishlist', error);
  }
};

export const getWishlist = async () => {
  try {
    const response = await fetch(
      `${REACT_APP_API_BASE_URL}/customers/actions/get-customer-wishlist`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
    return await response.json();
  } catch (error) {
    Utils.logError('Error getting customer wishlist', error);
  }
};

export const setAuthToken = async (customerId, appUrl) => {
  if (!(customerId && appUrl)) {
    return;
  }

  try {
    const response = await fetch(`${REACT_APP_API_BASE_URL}/customers/actions/get-customer-auth`, {
      method: 'POST',
      body: JSON.stringify({ customerId, appUrl }),
    });

    const { token } = await response.json();

    if (token) {
      setAuthTokenToLocalStorage(token);
    }
  } catch {
    // do nothing
  }
};

export const setAuthTokenToLocalStorage = token => {
  localStorage.setItem('customerToken', token);
};

export const getAuthToken = () => {
  return localStorage.getItem('customerToken');
};

export const isCustomerLoggedIn = () => {
  return !!getAuthToken();
};
