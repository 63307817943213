import React from 'react';

const XIconWithCircle = props => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.219 4.742c.14-.14.398-.14.539 0L6 5.984l1.219-1.242c.14-.14.398-.14.539 0a.4.4 0 0 1 0 .54L6.516 6.5l1.242 1.242a.4.4 0 0 1 0 .54.397.397 0 0 1-.54 0L6 7.038 4.758 8.281a.397.397 0 0 1-.54 0 .397.397 0 0 1 0-.539L5.462 6.5 4.219 5.281a.397.397 0 0 1 0-.539ZM12 6.5c0 3.328-2.695 6-6 6-3.328 0-6-2.672-6-6 0-3.305 2.672-6 6-6 3.305 0 6 2.695 6 6ZM6 1.25A5.256 5.256 0 0 0 .75 6.5 5.241 5.241 0 0 0 6 11.75a5.256 5.256 0 0 0 5.25-5.25c0-2.883-2.367-5.25-5.25-5.25Z"
        fill="#7D8087"
      />
    </svg>
  );
};

export default XIconWithCircle;
