import React from 'react';
import { TextH3Bold, TextSmall } from 'shared/react/components/basic/text/TextV2';
import { OverflowContainer } from 'app/src/pages/dashboard/components/page/Page';
import VideoGrid from 'app/src/pages/dashboard/pages/videos/components/video_grid/VideoGrid';
import NumberOfEntities from 'app/src/pages/dashboard/pages/videos/components/number-of-entities/NumberOfEntities';
import styled from 'styled-components';
import { Gap16HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { VideosPageVodAsset } from 'app/src/pages/dashboard/pages/videos/types/videos.types';
import { WhereCondition } from 'app/src/pages/dashboard/utils/filterEntities';
import { AnalyticsDataProps } from 'app/src/types/common';
import { useParams } from 'react-router-dom';
import GhostsEmptyState from 'app/src/complex_components/GhostsEmptyState';
import Types from 'shared/react/theme/Types';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { IMAGE_LIBRARY_MODAL_KEY } from 'app/src/constants/modals.constants';
import { isOnlyTypeFilterSelected } from 'app/src/utils/filters.utils';
import { ASSET_TYPE } from 'shared/react/services/assets';
import CollectUGC from './collect_ugc/CollectUGC';
import { UGC_COLLECT } from '../../constants/videos.constants';

type Props = {
  isTrendingPlaylist?: boolean;
  videos: VideosPageVodAsset[];
  selectedVideos: string[];
  isDone?: boolean;
  numberOfVideosText?: string;
  toggleSelected?: (id: string) => void;
  isLoading?: boolean;
  initialized: boolean;
  onSelectedVideosAction?: (video?: VideosPageVodAsset[]) => void;
  method?: string;
  videosFilters?: WhereCondition;
  onVideoChoose?: (video: VideosPageVodAsset) => void;
  isDisableCardHoverState?: boolean;
  analyticsData?: AnalyticsDataProps;
  isAddTagProductsTooltipToFirstCard?: boolean;
  isAddTagLabelsTooltipToFirstCard?: boolean;
  onScrollEndCallback?: () => void;
  isSelectionMode?: boolean;
};

const VideosContent = React.forwardRef(
  (
    {
      isTrendingPlaylist,
      videos,
      selectedVideos,
      toggleSelected,
      onSelectedVideosAction,
      initialized,
      method,
      videosFilters,
      onVideoChoose,
      isLoading,
      isDone,
      numberOfVideosText,
      onScrollEndCallback,
      isSelectionMode,
    }: Props,
    containerRef
  ) => {
    const { source } = useParams<{ source: string }>();
    const [, { setCurrentModal }] = useModal();
    const getIsGalleriesPlaylistSelected = () =>
      isOnlyTypeFilterSelected(videosFilters, ASSET_TYPE.GALLERY);
    const isGalleriesPlaylistSelected = getIsGalleriesPlaylistSelected();

    if (source?.includes(UGC_COLLECT)) {
      return <CollectUGC />;
    }

    if (initialized && isGalleriesPlaylistSelected && (!videos.length || videos.length === 0)) {
      return (
        <GhostsEmptyState
          header="You have no image carousels"
          subheader="Upload images and create a new carousel to show on your site."
          onClick={() => {
            setCurrentModal(IMAGE_LIBRARY_MODAL_KEY);
          }}
          buttonContent="Create new carousel"
          buttonType={Types.Danger}
        />
      );
    }

    return (
      <>
        {isTrendingPlaylist && (
          <>
            <Header>
              <TextH3Bold>Trending Videos</TextH3Bold>
            </Header>
            <SubHeader>
              <SubHeaderText>
                Trending videos update weekly from your social media based on user engagement
              </SubHeaderText>
            </SubHeader>
          </>
        )}
        <OverflowContainer>
          <VideoGrid
            ref={containerRef}
            items={videos}
            selectedItems={selectedVideos}
            toggleSelected={toggleSelected}
            onSelectedVideosAction={onSelectedVideosAction}
            initialized={initialized}
            method={method}
            videosFilters={videosFilters}
            onVideoChoose={onVideoChoose}
            onScrollEndCallback={onScrollEndCallback}
            isSelectionMode={isSelectionMode}
            isLoading={isLoading}
          />
          <NumberOfEntities
            text={numberOfVideosText}
            shouldShow={numberOfVideosText && isDone && videos.length}
          />
        </OverflowContainer>
      </>
    );
  }
);

VideosContent.displayName = 'VideosContent';

const LayoutRoot = styled.div`
  padding: 24px;
  height: 100%;
`;

const Header = styled(Gap16HorizontalFlex)`
  padding-top: 16px;
  padding-left: 24px;
  align-items: center;
  justify-content: space-between;
`;

const SubHeader = styled(Gap16HorizontalFlex)`
  padding-top: 8px;
  padding-left: 24px;
  align-items: center;
  justify-content: space-between;
`;

const SubHeaderText = styled(TextSmall)`
  font-weight: 500;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.neutralDark};

  &::first-letter {
    text-transform: uppercase;
  }
`;

export default VideosContent;
