import React from 'react';
import LineClampWrapper from 'shared/react/components/basic/LineClampWrapper';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import ImageWithFallback from 'shared/react/images/ImageWithFallback';
import styled from 'styled-components';
import Tooltip from './tooltip-v2/Tooltip';
import ComponentWithLoader from 'src/complex_components/ComponentWithLoader';

type Props = {
  title: string;
  imageUrl: string;
  button?: React.ReactElement;
  isLoading?: boolean;
  disableTooltip?: boolean;
  onClick?: () => void;
  className?: string;
  titleNumberOfLines?: number;
};

const ProductTile = ({
  title,
  imageUrl,
  button,
  isLoading,
  disableTooltip,
  onClick,
  className,
  titleNumberOfLines = 4,
}: Props) => {
  return (
    <LayoutRoot
      $isLoading={isLoading}
      $hasClickEvent={!!onClick}
      className={className}
      onClick={onClick}
    >
      <ComponentWithLoader isLoading={isLoading}>
        <Content>
          <StyledImage src={imageUrl} alt="Product" />
          <Tooltip className="notranslate" text={disableTooltip ? '' : title}>
            <StyledTextTiny>
              <LineClampWrapper className="notranslate" numberOfLines={titleNumberOfLines}>
                {title}
              </LineClampWrapper>
            </StyledTextTiny>
          </Tooltip>
        </Content>
        {button}
      </ComponentWithLoader>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)<{ $isLoading: boolean; $hasClickEvent: boolean }>`
  gap: 12px;
  align-items: flex-start;
  padding: 4px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.ghostLight};
  cursor: default;
  justify-content: ${({ $isLoading }) => ($isLoading ? 'center' : 'space-between')};
  cursor: ${({ $hasClickEvent }) => ($hasClickEvent ? 'pointer' : 'default')};
`;

const Content = styled(HorizontalFlex)`
  gap: 12px;
`;

const StyledImage = styled(ImageWithFallback)`
  width: 56px;
  height: 70px;
  border-radius: 4px 2px 2px 4px;
`;

const StyledTextTiny = styled(TextTiny)`
  font-weight: 600;
  padding: 4px 4px 4px 0;
`;

export default ProductTile;
