import React from 'react';
import { project as Project } from 'app/src/types/entities';
import Tooltip from 'app/src/basic_components/tooltip-v2/Tooltip';
import LightningIcon from 'app/src/images/dashboard_v2/LightningIcon';
import HammerIcon from 'app/src/images/dashboard_v2/HammerIcon';

type Props = {
  project: Project;
};

const CardProjectTypeIcon = ({ project }: Props) => {
  return (
    <Tooltip text={project.dynamic ? 'Automated' : 'Manual'}>
      {project.dynamic ? <LightningIcon /> : <HammerIcon />}
    </Tooltip>
  );
};

export default CardProjectTypeIcon;
