import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { assetType as AssetType } from 'src/types/entities';
import Tooltip from 'app/src/basic_components/tooltip-v2/Tooltip';
import { vodAsset } from 'app/src/types/entities';
import { TextSmall } from 'shared/react/components/basic/text/TextV2';
import ImageIcon from 'src/complex_components/rich-text-editor/icons/ImageIcon';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';
import VideoCardLabels from 'app/src/pages/dashboard/pages/videos/components/video_card/components/video-card-details/components/video-card-labels/VideoCardLabels';
import {
  VirtualizedVideosImage,
  VirtualizedVideosImageContainer,
} from 'src/basic_components/virtualized-table/VirtualizedRows';
import { AssetRenditionsService } from 'shared/react/services/assets';
import TableVideoOverlay from './TableVideoOverlay';

const POSTER_LOAD_TIMEOUT = 4500;
const POSTER_LOAD_TRIES = 1;

type Props = {
  video: vodAsset;
  isLocked?: boolean;
  isHidden?: boolean;
  isDragged?: boolean;
  isDiscover?: boolean;
  isOutOfStock?: boolean;
  isDragDisabled?: boolean;
  shouldHideOOSProducts?: boolean;
  hasManualVodConnection?: boolean;
  canLock?: boolean;
  canHide?: boolean;
  onClick?: (video) => void;
  onRemove?: () => void;
  hideVideo: () => void;
  showVideo: () => void;
  lockVideo: () => void;
  unlockVideo: () => void;
};

const TableVideoCard = ({
  video,
  isLocked,
  isHidden,
  isDragged,
  isDiscover = false,
  isOutOfStock,
  isDragDisabled,
  shouldHideOOSProducts,
  hasManualVodConnection,
  canLock = true,
  canHide = false,
  onClick = video => {},
  onRemove,
  hideVideo,
  showVideo,
  lockVideo,
  unlockVideo,
}: Props) => {
  const counter = useRef(0);
  const timeoutRef = useRef<null | NodeJS.Timeout>(null);
  const [isImageBroken, setIsImageBroken] = useState(false);
  const shouldShowRemoveButton = onRemove && hasManualVodConnection;
  const isVideoNameLight = isOutOfStock && shouldHideOOSProducts;
  const isImage = video?.type === AssetType.image;

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  if (!video) {
    return null;
  }

  const handleImageLoadError = e => {
    const target = e.target;
    if (counter.current >= POSTER_LOAD_TRIES) {
      setIsImageBroken(true);
      return;
    }

    timeoutRef.current = setTimeout(() => {
      counter.current++;
      target.src = AssetRenditionsService.getAssetPoster(video);
    }, POSTER_LOAD_TIMEOUT);
  };

  const handleClick = () => onClick?.(video);

  return (
    <LayoutRoot isDiscover={isDiscover} isDragged={isDragged} onClick={handleClick}>
      <VirtualizedVideosImageContainer>
        <Tooltip placement="top" hidden={!isLocked} text="Unlock video to reorder">
          {isImage && (
            <ImageIconContainer>
              <ImageIcon />
            </ImageIconContainer>
          )}
          <VirtualizedVideosImage
            draggable={!isDragDisabled}
            src={isImageBroken ? null : AssetRenditionsService.getAssetPoster(video)}
            onError={handleImageLoadError}
          />
          <StyledVideoOverlay
            isLocked={isLocked}
            isHidden={isHidden}
            isDiscover={isDiscover}
            isOutOfStock={isOutOfStock}
            canLock={canLock}
            canHide={canHide}
            canRemove={shouldShowRemoveButton}
            onRemove={onRemove}
            hideVideo={hideVideo}
            showVideo={showVideo}
            lockVideo={lockVideo}
            unlockVideo={unlockVideo}
          />
        </Tooltip>
      </VirtualizedVideosImageContainer>
      <EllipsisTextOverflowWrapper>
        <VideoName className="notranslate" isLight={isVideoNameLight}>
          {video.name}
        </VideoName>
      </EllipsisTextOverflowWrapper>

      {!!isDiscover && (
        <LabelsContainer>
          <VideoCardLabels video={video} />
        </LabelsContainer>
      )}
    </LayoutRoot>
  );
};

const VideoName = styled(TextSmall)<{ isLight: boolean }>`
  padding: 4px 8px;
  color: ${({ theme, isLight }) =>
    isLight ? theme.colors.neutralDark : theme.colors.neutralBlack};
`;

const StyledVideoOverlay = styled(TableVideoOverlay)``;

const LayoutRoot = styled(VerticalFlex)<{ isDragged: boolean; isDiscover: boolean }>`
  position: relative;
  background: ${({ theme }) => theme.colors.white};
  padding: 2px;
  max-height: ${({ isDiscover }) => (isDiscover ? '162px' : '126px')};

  box-shadow: 0 2px 4px rgba(50, 50, 93, 0.1);
  border-radius: 4px;
  cursor: pointer;
  filter: ${({ isDragged }) => (isDragged ? 'opacity(0.5)' : '')};

  &:hover ${StyledVideoOverlay} {
    display: flex;
  }
`;

const LabelsContainer = styled.div`
  margin: 0 8px;
`;

const ImageIconContainer = styled.div`
  position: absolute;
  left: 4px;
  bottom: 0;
  z-index: 1;
`;

export default TableVideoCard;
