import React, { useState } from 'react';
import styled from 'styled-components';
import { TextSmall } from 'shared/react/components/complex/Text';
import ExpanderBottomArrow from 'shared/react/images/expander_bottom_arrow.svg';
import { Menu } from '@material-ui/core';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import MenuItem from 'app/src/complex_components/MenuItem';

function DropDown({ children, menuItems, ...props }) {
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClose() {
    setAnchorEl(null);
  }

  function handleOpen(e) {
    setAnchorEl(e.currentTarget);
  }

  return (
    <>
      <MainContainer onClick={handleOpen} {...props}>
        <TextSmall>{children}</TextSmall>
        <img src={ExpanderBottomArrow} alt="Expander arrow" />
      </MainContainer>

      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        disableAutoFocusItem
        open={Boolean(anchorEl)}
        keepMounted
        onClick={handleClose}
        onClose={handleClose}
      >
        {menuItems?.map(menuItem =>
          menuItem.hidden ? null : (
            <CustomMenuItem
              key={menuItem.key}
              menuItem={menuItem}
              textMaxWidth={props.textMaxWidth}
              onClose={handleClose}
            />
          )
        )}
      </Menu>
    </>
  );
}

export default DropDown;

const MainContainer = styled(HorizontalFlexWrap)`
  gap: 4px;
  cursor: pointer;
  transition: 0.25s;
  align-items: center;

  &:hover {
    opacity: 0.7;
  }
`;

const CustomMenuItem = styled(MenuItem)`
  && {
    padding: 8px 8px 8px 0;
  }
`;
