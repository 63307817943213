import React from 'react';

const OutreachScissorsIcon = props => (
  <svg
    width={12}
    height={13}
    viewBox="0 0 12 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.79 3.008a.577.577 0 0 0 .21-.446c0-.374-.328-.562-.586-.562a.563.563 0 0 0-.351.14L6.726 5.774 4.969 4.297c.164-.352.281-.75.281-1.172C5.25 1.695 4.055.5 2.625.5A2.636 2.636 0 0 0 0 3.125 2.62 2.62 0 0 0 2.625 5.75c.61 0 1.172-.21 1.617-.586L5.86 6.5 4.242 7.86a2.512 2.512 0 0 0-1.617-.61A2.636 2.636 0 0 0 0 9.875 2.62 2.62 0 0 0 2.625 12.5 2.636 2.636 0 0 0 5.25 9.875c0-.398-.117-.797-.281-1.148l6.82-5.72ZM2.624 4.625a1.48 1.48 0 0 1-1.5-1.5c0-.82.656-1.5 1.5-1.5.82 0 1.5.68 1.5 1.5 0 .844-.68 1.5-1.5 1.5Zm0 6.75a1.48 1.48 0 0 1-1.5-1.5c0-.82.656-1.5 1.5-1.5.82 0 1.5.68 1.5 1.5 0 .844-.68 1.5-1.5 1.5ZM8.18 6.992l-.867.75 3.75 3.14a.551.551 0 0 0 .374.118c.235 0 .563-.188.563-.54a.577.577 0 0 0-.21-.444L8.18 6.992Z"
      fill="#3D4FE0"
    />
  </svg>
);

export default OutreachScissorsIcon;
