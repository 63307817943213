import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import CsvFileIcon from 'src/images/CsvFileIcon';
import {
  GhostButton,
  PrimaryButton,
  SuccessButton,
} from 'shared/react/components/basic/button-v2/BasicButton';
import { useAppActions } from 'src/context/AppsStore';
import { useProductActions } from 'src/context/ProductsStore';
import LoadingIndicator from 'shared/react/components/basic/LoadingIndicator';
import RefreshIcon from 'src/images/dashboard_v2/RefreshIcon';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import DeleteIconFilled from 'src/images/DeleteIconFilled';
import ImportFileWarnings, { WarningType } from './ImportFileWarnings';
import Utils from 'src/utils';
import { TextTiny } from 'shared/react/components/complex/Text';
import { useProjectActions } from 'src/context/ProjectsStore';

type Props = {
  app: any;
  account: any;
  isLoading: boolean;
  updateIntegration: (appData) => void;
};

const ImportFileInput: FC<Props> = ({ app, account, isLoading, updateIntegration }) => {
  const { fetch: fetchApps, removeApp } = useAppActions();
  const { importProducts } = useProductActions();
  const { fetchProjects } = useProjectActions();

  const [url, setUrl] = useState('');
  const [isImporting, setIsImporting] = useState(false);
  const [error, setError] = useState('');
  const [warnings, setWarnings] = useState<WarningType[]>([]);
  const isButtonDisabled = isLoading || isImporting;

  useEffect(() => {
    const appData = Utils.safeParse(app?.data);
    setUrl(appData?.originalUrl || '');
  }, [app]);

  const clearErrors = () => {
    setError('');
    setWarnings([]);
  };

  const onSubmit = async event => {
    event.preventDefault();
    setWarnings([]);

    if (!url) {
      setError('Please enter a valid url');
      return;
    }

    setError('');
    setIsImporting(true);

    try {
      const response = await importProducts({
        appKey: account.appKey,
        ownerId: account.owner,
        url,
      });

      if (response?.error) {
        setError(response?.error);
        setIsImporting(false);
        return;
      }

      const refetchPromises = [
        fetchApps(),
        fetchProjects({ withoutLoading: true, withoutChangingLibrary: true }),
      ];
      await Promise.all(refetchPromises);

      if (response?.warnings) {
        setWarnings(response.warnings);
      }
    } catch (error) {
      console.log(error);
      setError(`${error}`);
    }

    setIsImporting(false);
  };

  const onDelete = async (event: SyntheticEvent) => {
    event.preventDefault();
    clearErrors();
    await updateIntegration({ active: false });
    removeApp(app);
  };

  const onUrlChange = async event => {
    setUrl(event.target.value);
  };

  const getSubmitButton = () => {
    if (!app) {
      return <CreateButton disabled={isButtonDisabled}>Connect</CreateButton>;
    }

    return (
      <UpdateButton disabled={isButtonDisabled}>
        <Refresh />
      </UpdateButton>
    );
  };

  const getDeleteButton = () => {
    if (!app) {
      return null;
    }

    return (
      <DeleteButton disabled={isButtonDisabled} onClick={onDelete}>
        <DeleteIcon />
      </DeleteButton>
    );
  };

  return (
    <Form onSubmit={onSubmit}>
      <InputRow>
        <InputContainer>
          {isImporting ? <Spinner size={16} /> : <CsvIcon />}
          <UrlInput type="text" value={url} onChange={onUrlChange} integrationExists={!!app} />
          {getSubmitButton()}
        </InputContainer>
        {getDeleteButton()}
      </InputRow>
      {isImporting && <InfoText>Processing...</InfoText>}
      {!!error && <ErrorText>{error}</ErrorText>}
      <ImportFileWarnings warnings={warnings} />
    </Form>
  );
};

const iconStyles = css`
  position: absolute;
  left: 16px;
  pointer-events: none;
`;

const buttonStyles = css`
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
  padding: 7px 9px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
`;

const InputRow = styled(Gap8HorizontalFlex)`
  width: 100%;
  justify-content: center;
`;

const InputContainer = styled(Gap8HorizontalFlex)`
  position: relative;
  width: 100%;
  max-width: 454px;
`;

const InfoText = styled(TextTiny)`
  align-self: center;
  color: ${({ theme }) => theme.colors.neutralDark};
`;

const ErrorText = styled(TextTiny)`
  align-self: center;
  color: ${({ theme }) => theme.colors.fireRed};
`;

const UrlInput = styled.input<{ integrationExists?: boolean }>`
  width: 100%;
  padding: 10px ${({ integrationExists }) => (integrationExists ? '40px' : '86px')} 10px 40px;
  font-size: 14px;
  line-height: 18px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.neutralLight};
  overflow: hidden;
`;

const Spinner = styled(LoadingIndicator)`
  ${iconStyles};
  top: 12px;
`;

const CsvIcon = styled(CsvFileIcon)`
  ${iconStyles};
  width: 16px;
  height: 16px;
  top: 50%;
  transform: translateY(-50%);
`;

const Refresh = styled(RefreshIcon)`
  fill: ${({ theme }) => theme.colors.white};
`;

const DeleteIcon = styled(DeleteIconFilled)`
  fill: ${({ theme }) => theme.colors.neutralDarker};
  width: 16px;
  height: 16px;
`;

const CreateButton = styled(PrimaryButton)`
  ${buttonStyles}
`;

const UpdateButton = styled(SuccessButton)`
  ${buttonStyles}
`;

const DeleteButton = styled(GhostButton)``;

export default ImportFileInput;
