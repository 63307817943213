import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { project as projectType, publishMethodOptions } from 'app/src/types/entities';
import ProjectContent from 'app/src/pages/project/pages/project-edit/components/project-content/ProjectContent';
import Hero from 'app/src/pages/project/pages/hero/Hero';
import { useParams } from 'react-router-dom';
import { useProjects } from 'app/src/context/ProjectsStore';
import { useBuilderFormContext } from 'app/src/context/BuilderFormStore';
import useUpdateDirtyForm from 'app/src/hooks/useUpdateDirtyForm';
import { HeaderText } from 'app/src/pages/project/pages/project-edit/components/shared/shared';
import { SHOP_APP_TARGET } from 'src/constants/project.constants';
import { getIsDerivedProject } from 'app/src/utils/project.utils';

type Props = {
  project: projectType;
  containerRef: React.RefObject<HTMLDivElement>;
};

const ProjectEdit = ({ project, containerRef }: Props) => {
  const { projectId, stepId } = useParams();
  const [{ step }] = useProjects({ projectId, stepId });
  const [headerText, setHeaderText] = useState('');
  const isDerivedProject = getIsDerivedProject(project);
  const isShopAppPdp =
    project?.publishMethod === publishMethodOptions.shopApp &&
    project?.targetPage === SHOP_APP_TARGET.productPage;
  const {
    resetBuilderForm,
    getProjectIsFeedTypeDynamic,
    getProjectTypeDiscover,
    builderForm: { isDirty, dirtyFormToUpdate },
  } = useBuilderFormContext();

  const getListHeader = () => {
    if (dirtyFormToUpdate.isLoading || isShopAppPdp || isDerivedProject) {
      return;
    }

    if (getProjectIsFeedTypeDynamic(project) && !getProjectTypeDiscover(project)) {
      return setHeaderText('Playlists');
    }

    if (getProjectTypeDiscover(project)) {
      return setHeaderText(
        `Shoppers will be shown recommended videos selected from the library below.`
      );
    }

    if (project?.feed) {
      return setHeaderText('Videos');
    }

    return setHeaderText('Parts');
  };

  const onSaveClick = useCallback(() => {
    dirtyFormToUpdate.onSaveClick(project, step);
  }, [dirtyFormToUpdate.onSaveClick, step?.id, project]);

  const onDiscardClick = useCallback(() => {
    dirtyFormToUpdate.onDiscardClick(step);
  }, [dirtyFormToUpdate.onDiscardClick, step?.id]);

  useUpdateDirtyForm(isDirty, {
    ...dirtyFormToUpdate,
    disableHistoryBlocking: true,
    onSaveClick,
    onDiscardClick,
  });

  useEffect(() => {
    resetBuilderForm();

    return () => {
      resetBuilderForm();
    };
  }, []);

  useEffect(() => {
    getListHeader();
  }, [project, getProjectIsFeedTypeDynamic(project)]);

  if (project.publishMethod === publishMethodOptions.hero) {
    return <Hero project={project} />;
  }

  return (
    <LayoutRoot isShopApp={project.publishMethod === publishMethodOptions.shopApp}>
      <Header>
        <HeaderText isDiscover={getProjectTypeDiscover(project)}>{headerText}</HeaderText>
      </Header>
      <ProjectContent project={project} containerRef={containerRef} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div<{ isShopApp: boolean }>`
  && {
    padding-top: ${({ isShopApp }) => (isShopApp ? '24px' : '0px')};
  }
`;

const Header = styled.div``;

export default ProjectEdit;
