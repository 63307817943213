import { useEffect, useState } from 'react';

import useFeedAccount from 'shared/react/components/complex/context/hooks/useFeedAccount';
import {
  fetchVideosBySearchTerm,
  fetchVideosByVideoId,
} from 'shared/react/services/feed-videos.service';
import useTracker from 'shared/react/components/complex/context/hooks/useTracker';
import useFeedState from 'shared/react/components/complex/context/hooks/useFeedState';
import useFeedTrendingVideos from 'shared/react/components/complex/context/hooks/useFeedTrendingVideos';
import { FEED_TYPES } from 'shared/react/constants/feed.constants';

const MAX_VIDEOS_COUNT = 12;
const VIDEOS_SCORE_THRESHOLD = 0.6;

const useFeedSearch = ({ videoId, trackingData = {} }) => {
  const { trendingVideos } = useFeedTrendingVideos();
  const [{ appKey }] = useFeedAccount();
  const [isLoading, setIsLoading] = useState(false);
  const [videos, setVideos] = useState([]);
  const [, { track }] = useTracker();
  const [{ searchControls }] = useFeedState();
  const { loadSearchFeed } = searchControls;
  const { currentVideoUrl } = trackingData;

  const filterSearchResults = ({
    videos,
    videoId = null,
    scoreThreshold = VIDEOS_SCORE_THRESHOLD,
  }) => {
    return videos.filter(video => video.id !== videoId && video.score >= scoreThreshold);
  };

  const formatTopResults = ({ videos, topResultsCount = 6 }) => {
    if (!videos.length) {
      return;
    }

    const topResults = videos.slice(0, topResultsCount);

    return topResults.map(({ id, score, videoUrl }) => ({
      id,
      score,
      videoUrl,
    }));
  };

  const getVideosBySearchTerm = async searchText => {
    try {
      setIsLoading(true);
      const { data, status } = await fetchVideosBySearchTerm({
        appKey,
        searchText,
        threshold: VIDEOS_SCORE_THRESHOLD,
        limit: MAX_VIDEOS_COUNT,
      });

      if (status !== 200) {
        return;
      }

      const trackData = {
        searchText,
        topSixResults: formatTopResults({ videos: data }),
      };
      track('fetchVideosBySearchTerm', { formData: JSON.stringify(trackData) });

      const filteredVideo = filterSearchResults({ videos: data });
      setVideos(filteredVideo);
      return filteredVideo;
    } catch (error) {
      return;
    } finally {
      setIsLoading(false);
    }
  };

  const getVideosByVideoId = async videoId => {
    try {
      setIsLoading(true);
      const { data, status } = await fetchVideosByVideoId({
        appKey,
        videoIds: [videoId],
        threshold: VIDEOS_SCORE_THRESHOLD,
        limit: MAX_VIDEOS_COUNT,
      });

      if (status !== 200) {
        return;
      }

      const trackData = {
        currentVideoUrl,
        videoId,
        topSixResults: formatTopResults({ videos: data }),
      };

      track('fetchVideosByVideoId', { formData: JSON.stringify(trackData) });
      const filteredVideo = filterSearchResults({ videos: data, videoId });

      setVideos(filteredVideo);
      return filteredVideo;
    } catch (error) {
      return;
    } finally {
      setIsLoading(false);
    }
  };

  const sortNewVideoFeed = (array, videoId) => {
    const videoIdIndex = array.indexOf(videoId);

    if (videoIdIndex === -1) {
      return array;
    }

    const beforeVideoId = array.slice(0, videoIdIndex);
    const afterVideoId = array.slice(videoIdIndex);

    return [...afterVideoId, ...beforeVideoId];
  };

  const loadNewVideoFeed = ({ feedType, videoId }) => {
    const newVideos = feedType === FEED_TYPES.search ? videos : trendingVideos;
    const vodAssetIds = newVideos?.map(({ id }) => id);
    const sortedVodAssetIds = sortNewVideoFeed(vodAssetIds, videoId);

    loadSearchFeed(sortedVodAssetIds);
  };

  useEffect(() => {
    if (!videoId) {
      return;
    }

    getVideosByVideoId(videoId);
  }, []);

  return {
    videos,
    trendingVideos,
    isLoading,
    getVideosBySearchTerm,
    loadNewVideoFeed,
  };
};

export default useFeedSearch;
