export enum GlobalDrawerType {
  Journey = 'Journey',
  UGCSettings = 'UGCSettings',
  InstagramSettings = 'InstagramSettings',
}

export type DrawerStyles = {
  top?: number;
};

export type SetCurrentDrawerArgs = {
  currentDrawer: GlobalDrawerType;
  drawerProps?: any;
  drawerStyles?: DrawerStyles;
};
