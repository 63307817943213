import React from 'react';

const VideoIconV2 = props => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.75 6.375c0-1.016-.86-1.875-1.875-1.875H2.625C1.57 4.5.75 5.36.75 6.375v11.25A1.85 1.85 0 0 0 2.625 19.5h11.25c1.016 0 1.875-.82 1.875-1.875V6.375Zm7.5.625c0-.977-1.172-1.563-1.992-1.016L17 8.954v6.132l4.258 2.969c.82.547 1.992-.04 1.992-1.016V7Z"
      fill="#fff"
    />
  </svg>
);

export default VideoIconV2;
