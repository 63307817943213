import React from 'react';
import styled from 'styled-components';
import Utils from 'app/src/utils';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { TextSmall } from 'shared/react/components/complex/Text';

const WIDGET_RULES_HELP_ACTICLE_URL =
  'https://help.gotolstoy.com/en/articles/6404076-how-to-use-tolstoy-rules-triggers-2-0';

const WidgetRules = () => {
  const openHelpArticle = () => {
    Utils.openInNewTab(WIDGET_RULES_HELP_ACTICLE_URL);
  };

  return (
    <LayoutRoot>
      <CustomText>
        Need some help? Check out our <LinkSpan onClick={openHelpArticle}>help article.</LinkSpan>
      </CustomText>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)``;

const CustomText = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.gray2};
`;

const LinkSpan = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
  transition: 0.3s;
  &:hover {
    color: ${({ theme }) => theme.colors.primaryHover};
  }
`;

export default WidgetRules;
