import React from 'react';
import styled from 'styled-components';
import FeedActionButtonEditor from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/feed/FeedActionButtonEditor';
import { useFormContext } from 'react-hook-form';
import { TextSubtitle } from 'shared/react/components/complex/Text';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import FeedAnswerTextEditor from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/feed/FeedAnswerTextEditor';
import { DESCRIPTION_SIDE_PANEL_TYPE } from 'shared/react/constants/feed.constants';
import { TEXTAREA_INPUT_TYPE } from 'app/src/constants/ui.constants';
import { FEED_DESCRIPTION_TEXTAREA_EDITOR_TEST_ID } from 'app/src/constants/dataTestIds.constants';

const FeedDescriptionEditor = () => {
  const { setValue, watch } = useFormContext();
  const [feedSidePanelDescription, feedSidePanelType] = watch([
    'feedSidePanelDescription',
    'feedSidePanelType',
  ]);

  const setFeedSidePanelDescriptionIfNeeded = newValue => {
    if (newValue === feedSidePanelDescription) {
      return;
    }

    setValue('feedSidePanelDescription', newValue);
  };

  const setFeedSidePanelTypeIfNeeded = newValue => {
    if (newValue === feedSidePanelType) {
      return;
    }

    setValue('feedSidePanelType', newValue);
  };

  const handleDescriptionChanged = ({ value }) => {
    if (feedSidePanelType !== DESCRIPTION_SIDE_PANEL_TYPE) {
      setFeedSidePanelTypeIfNeeded(DESCRIPTION_SIDE_PANEL_TYPE);
    }

    setFeedSidePanelDescriptionIfNeeded(value);
  };

  return (
    <>
      <ItemContainer>
        <StyledTextSubtitle>Description</StyledTextSubtitle>
        <FeedAnswerTextEditor
          placeholder="Type a description for your video"
          onChange={handleDescriptionChanged}
          value={feedSidePanelDescription}
          maxLength={2000}
          property="text"
          type={TEXTAREA_INPUT_TYPE}
          maxTextareaRows={6}
          dataTestId={FEED_DESCRIPTION_TEXTAREA_EDITOR_TEST_ID}
        />
      </ItemContainer>
      <FeedActionButtonEditor buttonType="feedDescriptionActionButton" />
    </>
  );
};

const ItemContainer = styled(VerticalFlex)`
  gap: 24px;
  justify-content: center;
  width: 100%;
`;

const StyledTextSubtitle = styled(TextSubtitle)``;

export default FeedDescriptionEditor;
