import { IMAGE_SIZE, IMAGE_SIZE_SUFFIX, DEFAULT_IMAGE_EXTENSION } from './constants';

class ImageRenditionsServiceSingleton {
  init = ({ outputBucketUrl }) => {
    this.outputBucketUrl = outputBucketUrl;
  };

  getOwnerPath = ({ owner }) => {
    if (!this.outputBucketUrl) {
      throw new Error(
        'ImageRenditionsServiceSingleton is not initialized correctly. Missing outputBucketUrl.'
      );
    }

    return `${this.outputBucketUrl}/public/${owner}`;
  };

  getRenditionUrl = (video, { size = IMAGE_SIZE.M } = {}) => {
    if (video.stockAsset?.posterUrl) {
      return video.stockAsset.posterUrl;
    }

    const route = `${this.getOwnerPath(video)}/${video.id}/`;
    const fileName = `${video.id}${IMAGE_SIZE_SUFFIX[size]}${DEFAULT_IMAGE_EXTENSION}`;

    return `${route}${fileName}`;
  };
}

export const ImageRenditionsService = new ImageRenditionsServiceSingleton();

export default ImageRenditionsService;
