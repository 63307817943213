import React from 'react';
import MessageContainer from './MessageContainer';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import ExtensionUpload from './ExtensionUpload';
import Routes from '../../helpers/Routes';
import InviteInvalid from './InviteInvalid';
import CreateWorkspace from './CreateWorkspace';

const TolstoyMessage = () => {
  return (
    <MessageContainer>
      <Content>
        <Switch>
          <Route path={Routes.getTolstoyMessageExtensionRoute()}>
            <ExtensionUpload />
          </Route>
          <Route path={Routes.getTolstoyMessageInviteInvalidRoute()}>
            <InviteInvalid />
          </Route>
          <Route path={Routes.getTolstoyMessageCreateWorkspaceRoute()}>
            <CreateWorkspace />
          </Route>
        </Switch>
      </Content>
    </MessageContainer>
  );
};

const Content = styled.div`
  margin-top: 85px;
  display: grid;
  gap: 64px;
  justify-items: center;
`;

export default TolstoyMessage;
