import React from 'react';
import useFeedProducts from 'shared/react/components/complex/context/hooks/useFeedProducts';
import { useFeedStateContext } from 'shared/react/components/complex/context/FeedStateContext';
import FeedSmallProductView from 'shared/react/components/complex/feed/feed_overlay_mobile/FeedSmallProductView';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { FEED_SMALL_PRODUCT_VIEW_MAX_HEIGHT } from 'shared/react/constants/ui.constants';
import { getProductsWithVariantsFromStep } from 'shared/react/utils/feedProducts.utils';
import styled, { css } from 'styled-components';
import DragToScrollContainer from 'shared/react/components/complex/DragToScrollContainer';

const SmallProductWrapper = props => {
  const [products, { getProductsData, getCurrentProduct, getExternalProductIds }] =
    useFeedProducts();
  const { currentPageDbProductId } = useFeedStateContext();

  const { step, isInteractive, verticalOrientation, isProductsExpanded } = props;
  const { product, isMultipleProducts } = getProductsData(step);
  const isHorizontal = !!isInteractive && !verticalOrientation;
  const externalProductIds = getExternalProductIds(step);

  if (!isMultipleProducts) {
    const variantId = step?.products?.find(p => p?.id === product?.dbProductId)?.variantIds?.[0];

    return (
      <SingleProductContainer
        isInteractive={isInteractive}
        verticalOrientation={verticalOrientation}
        isProductsExpanded={isProductsExpanded}
      >
        <FeedSmallProductView
          {...props}
          isHorizontal={!verticalOrientation}
          product={product}
          variantId={variantId}
          externalProductIds={externalProductIds}
          isMultipleProducts={isMultipleProducts}
        />
      </SingleProductContainer>
    );
  }

  const productsWithVariants = getProductsWithVariantsFromStep(step, {
    priorityProductId: currentPageDbProductId,
  });

  if (isHorizontal) {
    const currentProduct = getCurrentProduct(step);
    const variantId = step?.products?.find(p => p?.id === product?.dbProductId)?.variantIds?.[0];

    return (
      <FeedSmallProductView
        {...props}
        product={currentProduct}
        variantId={variantId}
        isHorizontal
        numberOfProducts={productsWithVariants.length}
        isMultipleProducts={isMultipleProducts}
      />
    );
  }

  return (
    <MultipleProductsContainer
      isProductsExpanded={isProductsExpanded}
      isInteractive={isInteractive}
    >
      {productsWithVariants.map(({ id, variantId }) => (
        <FeedSmallProductView
          {...props}
          key={`${id}_${variantId}`}
          product={products[id]}
          variantId={variantId}
          externalProductIds={externalProductIds}
          isMultipleProducts={isMultipleProducts}
        />
      ))}
    </MultipleProductsContainer>
  );
};

const TransformCss = css`
  max-height: ${({ isProductsExpanded }) =>
    isProductsExpanded ? FEED_SMALL_PRODUCT_VIEW_MAX_HEIGHT : 0}px;
  transform: translateY(${({ isProductsExpanded }) => (isProductsExpanded ? 0 : '100%')});
  transition: 0.3s ease-out;
  opacity: ${({ isProductsExpanded }) => (isProductsExpanded ? 1 : 0)};
`;

const MultipleProductsContainer = styled(DragToScrollContainer)`
  display: flex;
  gap: 8px;

  overflow-x: auto;
  padding: 0 8px 0px;
  margin: 0 0 32px;
  pointer-events: auto !important;

  ${({ isInteractive }) => !isInteractive && TransformCss};

  & > * {
    flex-shrink: 0;
    max-width: 95%;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
`;

const SingleProductContainer = styled(Gap8HorizontalFlex)`
  padding: ${({ isInteractive, verticalOrientation }) =>
    isInteractive && verticalOrientation ? '16px' : ''};
  width: 100%;
  justify-content: flex-end;
  ${({ isInteractive }) => !isInteractive && TransformCss};
`;

export default SmallProductWrapper;
