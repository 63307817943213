import { useState } from 'react';
import {
  DirtyForm,
  ProjectsMultiSelectBarState,
  VideosMultiSelectBarState,
} from 'app/src/pages/dashboard/components/top-bar/types/TopBar.types';

const useTopBar = () => {
  const [dirtyForm, setDirtyForm] = useState<DirtyForm | null>(null);
  const [projectsMultiSelectState, setProjectsMultiSelectState] =
    useState<ProjectsMultiSelectBarState | null>(null);
  const [videosMultiSelectState, setVideosMultiSelectState] =
    useState<VideosMultiSelectBarState | null>(null);

  return {
    dirtyForm,
    setDirtyForm,
    projectsMultiSelectState,
    setProjectsMultiSelectState,
    videosMultiSelectState,
    setVideosMultiSelectState,
  };
};

export default useTopBar;
