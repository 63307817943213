import React, { ReactElement, useState } from 'react';
import { TextSmall } from 'shared/react/components/basic/text/TextV2';
import CopyCode from 'app/src/complex_components/CopyCode';
import styled from 'styled-components';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { useApps } from 'app/src/context/AppsStore';
import {
  getLiquidCode,
  getTVLiquidCode,
} from 'app/src/pages/project/pages/installation/components/installation.helpers';
import { project as Project, publishMethodOptions } from 'app/src/types/entities';
import ExpanderTopArrowIcon from 'app/src/images/creation_method_icons/ExpanderTopArrowIcon';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import CodeBracketsIcon from 'app/src/images/creation_method_icons/CodeBracketsIcon';

type Props = {
  project: Project;
  noSpoiler?: boolean;
};

const UseCodeInstead = ({ project, noSpoiler }: Props) => {
  const { publishMethod, publishId } = project;
  const [{ shopify: isShopify }] = useApps();
  const [isExpanded, setIsExpanded] = useState(noSpoiler);

  const isTV = [publishMethodOptions.tvChannel, publishMethodOptions.videoPage].includes(
    publishMethod
  );

  const liquidCode = isTV
    ? getTVLiquidCode(project, 'HEADER_ID')
    : getLiquidCode(isShopify, project);

  const getDisplayCode = () => {
    if (isTV) {
      return (
        <>
          {'<div'}
          <br />
          <IndentContainer>
            {'id="tolstoy-tv-container"'}
            <br />
            {'data-publish-id="${project.publishId}"'}
            <br />
            {'data-header-id="'}
            <TextSmallRed>HEADER_ID</TextSmallRed>
            {'"'}
            <br />
            {'class="tolstoy-video-page"'}
            <br />
          </IndentContainer>
          {'/>'}
        </>
      );
    }

    if (!isShopify) {
      return (
        <>
          {`<tolstoy-${publishMethod}`}
          <br />
          <IndentContainer>
            {`id="${publishId}"`}
            <br />
            {`class="tolstoy-${publishMethod}"`}
            <br />
            {'data-product-id="'}
            <TextSmallRed>PRODUCT_ID</TextSmallRed>
            {'">'}
            <br />
          </IndentContainer>
          {`</tolstoy-${publishMethod}>`}
        </>
      );
    }

    return liquidCode;
  };

  if (publishMethod === publishMethodOptions.hero) {
    return null;
  }

  let description: string | ReactElement = 'Copy the following code, and place it in your page.';
  if (isTV) {
    description = (
      <>
        Copy code and paste it on an empty page. Make sure to change
        <TextSmallRed>this field</TextSmallRed>. You can find that value by inspecting your site
        header and taking the element id.
      </>
    );
  } else if (!isShopify) {
    description = (
      <>
        Copy the following code, and place it in your site&apos;s code. Make sure to change the{' '}
        <TextSmallRed>PRODUCT_ID</TextSmallRed>.
      </>
    );
  }

  return (
    <LayoutRoot>
      {!noSpoiler && (
        <Header onClick={() => setIsExpanded(!isExpanded)}>
          <CodeBracketsIcon />
          <HeaderText>No app blocks? Use code instead</HeaderText>
          <ExpanderTopArrowIconWrapper $isExpanded={isExpanded}>
            <ExpanderTopArrowIcon />
          </ExpanderTopArrowIconWrapper>
        </Header>
      )}
      {isExpanded && (
        <Content>
          <CopyText>{description}</CopyText>
          <CopyCode codeToCopy={liquidCode} codeToDisplay={getDisplayCode()} padding="8px" />
        </Content>
      )}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)`
  padding-bottom: 8px;
`;

const Header = styled(HorizontalFlex)`
  align-items: center;
  gap: 8px;
  cursor: pointer;

  & svg path {
    fill: ${({ theme }) => theme.colors.grapePurple};
  }
`;

const HeaderText = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.grapePurple};
`;

const ExpanderTopArrowIconWrapper = styled.div<{ $isExpanded: boolean }>`
  transform: rotate(${({ $isExpanded }) => ($isExpanded ? 0 : 180)}deg) translateY(-2px);

  & svg {
    width: 12px;
  }
`;

const Content = styled(Gap8VerticalFlex)`
  margin: 16px;
`;

const CopyText = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.neutralDark};
`;

const TextSmallRed = styled(TextSmall)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.red1};
`;

const IndentContainer = styled.div`
  margin-left: 16px;
`;

export default UseCodeInstead;
