import React from 'react';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Separator from 'shared/react/components/basic/Separator';

const EditorGroup = ({ editors, ...props }) => {
  const filteredEditors = editors.filter(({ hidden }) => !hidden);
  return (
    <VerticalFlex>
      {filteredEditors.map(({ key, component }, i) => (
        <EditorContainer key={key} {...props}>
          <Editor>{component}</Editor>
          {filteredEditors.length - 1 !== i && <Separator />}
        </EditorContainer>
      ))}
    </VerticalFlex>
  );
};

const EditorContainer = styled(VerticalFlex)``;

const Editor = styled.div``;

export default EditorGroup;
