import React from 'react';

const Search = () => {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.8125 12.0391L8.67188 8.89844C9.35156 8.07812 9.72656 7.02344 9.72656 5.875C9.72656 3.20312 7.52344 1 4.85156 1C2.15625 1 0 3.20312 0 5.875C0 8.57031 2.17969 10.75 4.85156 10.75C5.97656 10.75 7.03125 10.375 7.875 9.69531L11.0156 12.8359C11.1328 12.9531 11.2734 13 11.4375 13C11.5781 13 11.7188 12.9531 11.8125 12.8359C12.0469 12.625 12.0469 12.2734 11.8125 12.0391ZM1.125 5.875C1.125 3.8125 2.78906 2.125 4.875 2.125C6.9375 2.125 8.625 3.8125 8.625 5.875C8.625 7.96094 6.9375 9.625 4.875 9.625C2.78906 9.625 1.125 7.96094 1.125 5.875Z"
        fill="#50545E"
      />
    </svg>
  );
};

export default Search;
