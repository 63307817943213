import React from 'react';
import styled from 'styled-components';
import { getIsFeed } from 'app/src/context/ProjectsStore';
import ReviewPart from './review_parts/ReviewPart';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { BolderTextBody, TextBody } from 'shared/react/components/complex/Text';

const PartsPhase = ({ project, stepIndex, selectedStore, lastReviewedStep }) => {
  const currentStep = project?.steps?.items[stepIndex];
  const header = `${getIsFeed(project) ? 'Video' : 'Part'} ${stepIndex + 1}`;
  const alreadyReviewed = stepIndex <= lastReviewedStep;
  if (!currentStep) {
    return null;
  }

  return (
    <LayoutRoot>
      <HeaderLayout>
        <TextBody>{header}</TextBody>
        <BolderTextBody>{currentStep.description}</BolderTextBody>
      </HeaderLayout>
      <ReviewPart
        project={project}
        step={currentStep}
        selectedStore={selectedStore}
        alreadyReviewed={alreadyReviewed}
      />
    </LayoutRoot>
  );
};

export default PartsPhase;

const HeaderLayout = styled(Gap8HorizontalFlex)`
  align-items: center;
`;

const LayoutRoot = styled(Gap16VerticalFlex)`
  width: 420px;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    max-width: 280px;
  } ;
`;
