export type AnchorElementProps = {
  anchorElement: HTMLElement | null;
  setAnchorElement?: (element: HTMLElement | null) => void;
};

export enum FlexDirection {
  row = 'row',
  column = 'column',
}

export type AnalyticsDataProps = {
  [key: string]: string | number | boolean;
};

export type GenericObject = {
  [key: string]: any;
};

export type SearchProduct = {
  title: string;
  imageUrl: string;
  productId: string;
};

export type SearchProductsPayload = {
  term?: string;
  appUrl: string;
  appKey: string;
  customLimit?: number;
  nextPageFrom?: number;
  isSearchByIds?: boolean;
  ids?: string[];
};
