import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { app as App } from 'app/src/types/entities';
import { TextTiny } from 'shared/react/components/complex/Text';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import {
  PrimaryButton,
  SecondaryButton,
} from 'shared/react/components/basic/button-v2/BasicButton';
import ShopifyAppMultiSelectItem from './ShopifyAppMultiSelectItem';

interface ShopifyAppsMultiSelectProps {
  shopifyApps: App[];
  selectedAppUrl: string;
  onSave: (apps: App[]) => void;
  onCancel: () => void;
}

const ShopifyAppsMultiSelect = ({
  shopifyApps = [],
  selectedAppUrl,
  onSave,
  onCancel,
}: ShopifyAppsMultiSelectProps) => {
  const [selectedApps, setSelectedApps] = useState({ [selectedAppUrl]: true });

  const updateSelectedApps = update => setSelectedApps({ ...selectedApps, ...update });

  useEffect(() => {
    updateSelectedApps({ [selectedAppUrl]: true });
  }, [selectedAppUrl]);

  const onChange = app => {
    updateSelectedApps({ [app.appUrl]: !selectedApps[app.appUrl] });
  };
  const handleSave = () => {
    const appToSave = shopifyApps.filter(app => selectedApps[app.appUrl]);

    onSave(appToSave);
  };

  const items = shopifyApps.map(app => {
    const handleOnChange = () => onChange(app);

    return (
      <ShopifyAppMultiSelectItem
        key={app.appUrl}
        app={app}
        selected={selectedApps[app.appUrl]}
        onChange={handleOnChange}
      />
    );
  });

  return (
    <StyledShopifyAppsMultiSelect>
      <Header>Choose store to save to</Header>
      <ItemsContainer>{items}</ItemsContainer>
      <ButtonContainer>
        <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton onClick={handleSave}>Save to selected stores</PrimaryButton>
      </ButtonContainer>
    </StyledShopifyAppsMultiSelect>
  );
};

const StyledShopifyAppsMultiSelect = styled(VerticalFlex)`
  max-width: 300px;
  max-height: 280px;
  padding: 8px;
  background: ${({ theme }) => theme.colors.white};
`;

const ItemsContainer = styled.div`
  flex: 1 1 auto;
  overflow-y: scroll;
`;

const Header = styled(TextTiny)`
  padding: 12px 8px;
  width: 100%;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.ghostDark};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutralLight};
`;

const ButtonContainer = styled(HorizontalFlex)`
  padding: 16px 8px 8px;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.colors.neutralLight};
`;

export default ShopifyAppsMultiSelect;
