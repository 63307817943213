import React from 'react';

const ArrowBottomToRightIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.79 6.82a.583.583 0 0 0 0-.867L8.663 2.391c-.351-.305-.914-.047-.914.445V4.71C3.977 4.734 1 5.508 1 9.07c0 1.429.914 2.859 1.945 3.609.328.234.774-.07.657-.446-1.055-3.398.492-4.289 4.148-4.336V9.96c0 .469.563.726.914.422L12.79 6.82Z"
        fill="#50545E"
      />
    </svg>
  );
};

export default ArrowBottomToRightIcon;
