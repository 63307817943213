import React from 'react';
import styled from 'styled-components';
import { PageContainer, PageMain } from 'app/src/pages/dashboard/components/page/Page';
import TopBar from '../../components/top-bar/TopBar';
import NewFromScratchOnsiteButton from 'app/src/pages/dashboard/pages/onsites/components/NewFromScratchOnsiteButton';
import Cases from 'app/src/pages/dashboard/components/cases/Cases';
import { TextBody } from 'shared/react/components/basic/text/TextV2';
import HorizontalFlex, {
  Gap16HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { FatChevronLeftIcon } from 'app/src/images/sidebar-v2/FatChevronIcon';
import useNavigation from 'app/src/hooks/useNavigation';
import { useDashboard } from 'app/src/context/ui_store/DashboardStore';
import Sidebar from 'app/src/pages/dashboard/pages/onsites/components/sidebar/Sidebar';
import { CASE_TYPES_ONSITE } from 'app/src/pages/dashboard/components/cases/cases.constants';

const CreateCase = () => {
  const { navigateToDashboardV2Onsite } = useNavigation();
  const [{ onSiteFilters }, { setOnSiteFilters }] = useDashboard();
  const { folder } = onSiteFilters;

  const handleBackClick = () => {
    navigateToDashboardV2Onsite((folder?.eq as string) || 'all');
  };

  return (
    <PageContainer>
      <Sidebar filters={onSiteFilters} setFilters={setOnSiteFilters} />
      <PageMain>
        <TopBar
          leftContent={
            <Header>
              <IconContainer onClick={handleBackClick}>
                <FatChevronLeftIcon />
              </IconContainer>
              <TextBody>Start with a popular use case</TextBody>
            </Header>
          }
          rightContent={<NewFromScratchOnsiteButton />}
        />
        <Cases types={CASE_TYPES_ONSITE} />
      </PageMain>
    </PageContainer>
  );
};

const Header = styled(Gap16HorizontalFlex)`
  align-items: center;
`;

const IconContainer = styled(HorizontalFlex)`
  cursor: pointer;

  path {
    fill: ${({ theme }) => theme.colors.neutralBlack};
  }

  svg {
    width: 12px;
    height: 12px;
  }
`;

export default CreateCase;
