import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import StandardModal from './StandardModal';
import { TextH5, TextSmall } from 'shared/react/components/complex/Text';
import BasicSelect from 'shared/react/components/basic/basic_select/BasicSelect';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import Button from 'shared/react/components/complex/Button';
import Types from 'shared/react/theme/Types';
import { useProjects } from 'app/src/context/ProjectsStore';
import SnackBar from 'app/src/basic_components/SnackBar';
import { track } from 'app/src/helpers/Tracker';
import { useAccount } from 'app/src/context/AccountStore';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import AccountItem from 'app/src/complex_components/AccountItem';

const CopyToWorkspaceModal = ({
  open,
  onClose,
  workspaces,
  project,
  duplicateToCurrentWorkspace,
}) => {
  const [{ showPersonalLibrary }, { cloneProject }] = useProjects();
  const [, { getCurrentWorkspace }] = useAccount();
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [copyingProject, setCopyingProject] = useState(false);
  const [error, setError] = useState(false);
  const [successBar, setSuccessBar] = useState(false);
  const [privateLibrary, setPrivateLibrary] = useState(showPersonalLibrary);
  const currentWorkspace = getCurrentWorkspace();

  useEffect(() => {
    setPrivateLibrary(showPersonalLibrary);
  }, [showPersonalLibrary]);

  const getCurrentWorkspaceSubtitle = id => {
    if (id === currentWorkspace?.id) {
      return 'Current workspace';
    }
  };

  const prepareMenuItems = () => {
    return workspaces.map(workspace => {
      return {
        id: workspace.id,
        name: (
          <AccountItem
            key={workspace.id}
            title={workspace.name}
            subtitle={getCurrentWorkspaceSubtitle(workspace.id)}
            customPadding="0px"
          />
        ),
        appKey: workspace.appKey,
        workspaceName: workspace.name,
        hidden: currentWorkspace.appKey === workspace.appKey,
      };
    });
  };

  const handleCopyClicked = async () => {
    setCopyingProject(true);
    setError(false);

    if (selectedWorkspace?.id === currentWorkspace?.id) {
      setCopyingProject(false);
      onClose();
      await duplicateToCurrentWorkspace(project, privateLibrary);
      return;
    }

    track('Copy to Workspace Confirmed');

    const res = await cloneProject({
      projectId: project.id,
      appKey: selectedWorkspace.appKey,
      privateLibrary,
      publishMethod: project.publishMethod,
    });

    if (!res || res?.err) {
      setError(true);
    }

    setSuccessBar(true);
    setCopyingProject(false);
    onClose();
  };

  const handleCancelClick = () => {
    track('Copy to Workspace Canceled');
    onClose();
  };

  const menuItems = useMemo(() => prepareMenuItems(), [workspaces]);
  const filteredItems = menuItems.filter(({ hidden }) => !hidden);
  const getHiddenWorkspace = menuItems.filter(({ hidden }) => hidden);

  useEffect(() => {
    if (!selectedWorkspace) {
      setSelectedWorkspace(filteredItems[0]);
    }
  }, [menuItems]);

  return (
    <LayoutRoot>
      <StandardModal open={open} onClose={onClose} width="494px">
        <ModalBody>
          <ModalHeader>Copy to workspace</ModalHeader>
          <LibraryContainer>
            <LibraryHeader>From</LibraryHeader>
            <StyledBasicSelect
              items={getHiddenWorkspace}
              value={getHiddenWorkspace[0]}
              inputPadding="8px"
              itemsPadding="2px 8px"
              customHeight="40"
              onChange={setSelectedWorkspace}
            />
          </LibraryContainer>
          <LibraryContainer>
            <LibraryHeader>To</LibraryHeader>
            <StyledBasicSelect
              items={filteredItems}
              value={selectedWorkspace || filteredItems[0]}
              inputPadding="8px"
              itemsPadding="2px 8px"
              customHeight="40"
              onChange={setSelectedWorkspace}
            />
          </LibraryContainer>

          <ButtonsContainer>
            <Button type={Types.Ghost} onClick={handleCancelClick}>
              Cancel
            </Button>
            <Button onClick={handleCopyClicked} disabled={copyingProject} loading={copyingProject}>
              Copy
            </Button>
          </ButtonsContainer>
        </ModalBody>
      </StandardModal>
      <SnackBar
        text={
          error
            ? 'There was a problem copying your Tolstoy'
            : `Tolstoy copied to ${selectedWorkspace?.workspaceName} Workspace!`
        }
        open={successBar}
        setOpen={setSuccessBar}
        severity={error ? 'error' : 'success'}
      />
    </LayoutRoot>
  );
};

export default CopyToWorkspaceModal;

const LayoutRoot = styled.div``;

const ModalBody = styled(Gap16VerticalFlex)``;

const ModalHeader = styled(TextH5)``;

const StyledBasicSelect = styled(BasicSelect)``;

const LibraryContainer = styled(HorizontalFlexWrap)`
  width: 100%;
  gap: 4px;
`;

const LibraryHeader = styled(TextSmall)`
  font-weight: 700;
`;

const ButtonsContainer = styled(Gap8HorizontalFlexWrap)`
  justify-content: flex-end;
`;
