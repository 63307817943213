import React from 'react';

const LinkIcon = ({ fill = '#7D8087' }) => {
  return (
    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.516 1.484A3.304 3.304 0 0 0 12.172.5c-.844 0-1.688.328-2.344.984L8.82 2.492c.352.188.68.422.985.703.07.07.14.164.187.235l.89-.89c.352-.33.798-.54 1.29-.54s.937.21 1.289.54c.328.35.539.796.539 1.288s-.21.938-.54 1.29l-3.116 3.116a1.867 1.867 0 0 1-2.578 0 1.867 1.867 0 0 1 0-2.578l.093-.07a1.041 1.041 0 0 0-.187-.258A1.011 1.011 0 0 0 6.922 5c-.281 0-.54.117-.727.305-.726 1.265-.562 2.906.516 3.984a3.28 3.28 0 0 0 2.344.961c.843 0 1.687-.305 2.343-.96l3.118-3.118c.68-.68.984-1.547.96-2.414 0-.844-.328-1.64-.96-2.274Zm-8.532 8.11-.89.89a1.867 1.867 0 0 1-2.578 0c-.329-.351-.54-.796-.54-1.289 0-.492.212-.937.54-1.289L5.633 4.79c.351-.328.797-.539 1.289-.539.492 0 .937.21 1.289.54.328.35.539.796.539 1.288s-.21.938-.54 1.29l-.093.07c.047.093.117.187.188.257.187.211.468.305.75.305.28 0 .539-.094.726-.281.727-1.266.563-2.907-.515-3.985a3.304 3.304 0 0 0-2.344-.984c-.844 0-1.688.328-2.344.984L1.461 6.852A3.28 3.28 0 0 0 .5 9.195 3.279 3.279 0 0 0 3.805 12.5c.843 0 1.687-.305 2.343-.96l1.008-1.009a4.725 4.725 0 0 1-.984-.703c-.07-.07-.14-.164-.188-.234Z"
        fill={fill}
      />
    </svg>
  );
};

export default LinkIcon;
