import React, { useState } from 'react';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import Story from 'widget/src/widgets/EmbedWidgets/Story/Story';
import { useDynamicConditionsContext } from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/context/DynamicConditionsContext';
import useWidgetPreviewInit from 'src/hooks/preview/useWidgetPreviewSettings';
import EmbedCarouselPreview from 'app/src/pages/project/pages/look_and_feel/preview/embed_carousel_preview/EmbedCarouselPreview';
import styled from 'styled-components';

const DesignStoriesPreviewContainer = () => {
  const [element, setElement] = useState(null);
  const [storyClass] = useState(new Story());
  const { project, customizationSettings } = useLookAndFeelContext();
  const { vodAssetIds } = useDynamicConditionsContext();
  useWidgetPreviewInit(element, storyClass, project, vodAssetIds, customizationSettings, 'stories');

  return (
    <EmbedCarouselPreview>
      <LayoutRoot ref={setElement} />
    </EmbedCarouselPreview>
  );
};

const LayoutRoot = styled.div`
  width: 90%;
  place-self: center;
`;

export default DesignStoriesPreviewContainer;
