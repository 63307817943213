import React from 'react';

import { TextSmall } from 'shared/react/components/basic/text/TextV2';
import Utils from 'src/utils';
import pasteCodeImage from '../assets/trending-videos/paste-code.png';
import EmailSnippetBuilder from './EmailSnippetBuilder';
import { project as Project } from 'app/src/types/entities';
import AUTOMATION_TOOLS from 'app/src/pages/share/email/emailAutomationToolsList';
import styled from 'styled-components';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import { EmailPublishStepsFactoryFunction } from 'app/src/pages/project/components/project-top-bar/components/set-live-button/components/publish-modal/components/EmailPublishStepsFactoryFunction';

export const getPublishAllPlatrofmsSteps: EmailPublishStepsFactoryFunction = ({
  automationTool,
  ...props
}) => [
  {
    title: 'Copy code',
    body: <EmailSnippetBuilder automationTool={automationTool} {...props} />,
  },
  {
    title: 'Paste code into template',
    body: (
      <StepContainer>
        <TextSmall>
          Go to an email template in {Utils.upperCaseFirstChar(automationTool?.id)}. Paste the code
          using a block's source code or paste it in the HTML editor.
        </TextSmall>
        <Image src={pasteCodeImage} alt="Trending videos paste code" />
      </StepContainer>
    ),
  },
  {
    title: `GIF Doesn't show?`,
    body: (
      <StepContainer>
        <TextSmall>Make sure your project features 3 or more videos.</TextSmall>
      </StepContainer>
    ),
    isTip: true,
  },
];

const Image = styled.img`
  max-width: 320px;
`;

const StepContainer = styled(Gap16VerticalFlex)`
  padding: 0 32px 0 16px;
`;
