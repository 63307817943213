import {
  CREATED_BY_ME_PROJECT_FILTER,
  CREATED_BY_TEAMMATE_PROJECT_FILTER,
  LIVE_PROJECT_FILTER,
  FOLDER_FILTER,
  PUBLISH_METHOD_FILTER,
  CREATED_BY_TEAMMATE_VIDEO_FILES_FILTER,
  CREATED_BY_ME_VIDEO_FILES_FILTER,
} from 'app/src/constants/filters.constants';
import { ASSET_TYPE } from 'shared/react/services/assets';
import { ComparatorEnum } from 'app/src/pages/dashboard/utils/filterEntities';

export const getDefaultProjectFilters = ({ isAccountOwner }) => {
  const projectFiltersKeys = [
    isAccountOwner ? CREATED_BY_TEAMMATE_PROJECT_FILTER : CREATED_BY_ME_PROJECT_FILTER,
    FOLDER_FILTER,
    PUBLISH_METHOD_FILTER,
    LIVE_PROJECT_FILTER,
  ];

  return Object.fromEntries(projectFiltersKeys.map(key => [key, false]));
};

export const getDefaultVideoFilesFilters = ({ isAccountOwner }) => {
  const projectFiltersKeys = [
    isAccountOwner ? CREATED_BY_TEAMMATE_VIDEO_FILES_FILTER : CREATED_BY_ME_VIDEO_FILES_FILTER,
  ];

  return Object.fromEntries(projectFiltersKeys.map(key => [key, false]));
};

export const isArchiveFolder = filters => filters?.folder?.eq === 'archive';

export const getTypeFilterValue = type => {
  switch (type) {
    case ASSET_TYPE.IMAGE:
      return { [ComparatorEnum.in]: [ASSET_TYPE.IMAGE] };
    case ASSET_TYPE.GALLERY:
      return { [ComparatorEnum.in]: [ASSET_TYPE.GALLERY] };
    default:
    case ASSET_TYPE.VIDEO:
      return { [ComparatorEnum.in]: [ASSET_TYPE.VIDEO, null, undefined] };
  }
};

export const isTypeFilterSelected = (videosFilters, type) => {
  const selectedTypes = videosFilters.type?.[ComparatorEnum.in] || [];
  const typeFilterValue = getTypeFilterValue(type);
  const typeValues = typeFilterValue[ComparatorEnum.in];
  const allTypesExist =
    selectedTypes.length && selectedTypes.every(value => typeValues.includes(value));

  return allTypesExist;
};

export const isOnlyTypeFilterSelected = (videosFilters, type) => {
  const areAllOtherFiltersEmpty =
    !videosFilters.folder &&
    !videosFilters.labels &&
    !videosFilters.trending &&
    !videosFilters.uploadType;

  return areAllOtherFiltersEmpty && isTypeFilterSelected(videosFilters, type);
};

export const hasSuggestionsSelected = filters =>
  Boolean(filters?.hasSuggestions?.[ComparatorEnum.satisfies]?.args[0]) === true;
