import { useCarouselContext } from 'shared/react/components/complex/context/CarouselContext';

const useFeedCarousel = () => {
  const carouselContext = useCarouselContext();

  const actions = {};
  return [carouselContext, actions];
};

export default useFeedCarousel;
