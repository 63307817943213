import React from 'react';
import styled from 'styled-components';
import PlusIcon from 'app/src/images/dashboard_v2/PlusIcon';
import { TextSmall } from 'shared/react/components/complex/Text';
import HorizontalFlex, {
  HorizontalFlexCentered,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

type Props = {
  title: React.ReactNode;
  dataTestId?: string;
  withPlustButton?: boolean;
  onPlusClick?: () => void;
};

export const SidebarExpanderTitle = ({
  title,
  dataTestId,
  withPlustButton = false,
  onPlusClick = () => {},
}: Props) => {
  const handlePlusClick = e => {
    e.stopPropagation();
    onPlusClick();
  };

  return (
    <CategoryHeader>
      <CategoryHeaderText>{title}</CategoryHeaderText>
      {withPlustButton && (
        <CounterContainer data-test-id={dataTestId} onClick={handlePlusClick}>
          <PlusIcon />
        </CounterContainer>
      )}
    </CategoryHeader>
  );
};

const CategoryHeader = styled(HorizontalFlex)`
  flex: 1;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px;
`;

const CounterContainer = styled(HorizontalFlexCentered)`
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
  }

  & svg > path {
    fill: ${({ theme }) => theme.colors.neutralDark};
  }
`;

const CategoryHeaderText = styled(TextSmall)`
  font-weight: 500;
`;

export default SidebarExpanderTitle;
