import React from 'react';

function RightArrow({ fill }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" fill="none" viewBox="0 0 11 10">
      <path
        fill={fill}
        d="M6.438.164c-.235-.21-.586-.21-.797.023a.56.56 0 00.023.797l3.117 2.977H.812a.542.542 0 00-.562.562c0 .305.234.563.563.563H8.78L5.664 8.039a.56.56 0 00-.023.797c.21.234.562.234.796.023l4.125-3.937a.549.549 0 00.165-.399.565.565 0 00-.165-.421L6.439.164z"
      ></path>
    </svg>
  );
}

export default RightArrow;
