import { CUBE_PROPERTY_KEYS } from 'src/constants/cube.constants';
import { EntryPointLocation } from 'src/constants/shop.constants';

const IMPRESSIONS_SETTINGS = {
  id: CUBE_PROPERTY_KEYS.numOfImpressions,
  mainTitle: 'Impressions',
  headerTooltipText: 'Number of impressions of your videos on Shop app home screen',
};

const CLICKS_SETTINGS = {
  id: CUBE_PROPERTY_KEYS.numOfSessionStarts,
  mainTitle: 'Clicks',
  headerTooltipText:
    'Number of video clicks from Shop app home screen (equivalent to views in Shopify analytics)',
};

const CTR_SETTINGS = {
  id: 'clickThroughRate',
  mainTitle: 'CTR',
  isPercentage: true,
  headerTooltipText: 'Click Through Rate - percentage of clicks from impressions',
};

const VIDEOS_WATCHED_SETTINGS = {
  id: CUBE_PROPERTY_KEYS.numOfVideosWatched,
  mainTitle: 'Videos watched',
  headerTooltipText: 'Number of videos watched within the feed',
};

const PRODUCTS_CLICKED_SETTINGS = {
  id: CUBE_PROPERTY_KEYS.numOfProductsClicked,
  mainTitle: 'Products clicked',
  headerTooltipText: 'Number of products clicked from videos within the feed',
};

const PRODUCTS_FAVORITES_SETTINGS = {
  id: CUBE_PROPERTY_KEYS.numOfProductFavorites,
  mainTitle: 'Products favorited',
  headerTooltipText: 'Number of product favorites from videos within the feed',
};

const OPEN_STORE_SCREEN_SETTINGS = {
  id: CUBE_PROPERTY_KEYS.numOfOpenStoreScreen,
  mainTitle: 'Opened store profile',
  headerTooltipText:
    'Number of times the store profile screen was opened from videos within the feed',
};

const NAVIGATE_TO_STORE_SETTINGS = {
  id: CUBE_PROPERTY_KEYS.numOfNavigateToStore,
  mainTitle: 'Navigated to store',
  headerTooltipText:
    'Number of times users navigated to the Shop store page from videos within the feed',
};

const FOLLOWED_STORE_SETTINGS = {
  id: CUBE_PROPERTY_KEYS.numOfFollowedStore,
  mainTitle: 'Followed store',
  headerTooltipText: 'Number of times users followed the store from videos within the feed',
};

const FEED_METRICS = [
  VIDEOS_WATCHED_SETTINGS,
  PRODUCTS_CLICKED_SETTINGS,
  PRODUCTS_FAVORITES_SETTINGS,
  OPEN_STORE_SCREEN_SETTINGS,
  FOLLOWED_STORE_SETTINGS,
  NAVIGATE_TO_STORE_SETTINGS,
];

const RAIL_SUBHEADER = 'Carousel';
const FEED_SUBHEADER = 'Feed';

export const SHOP_METRICS_SETTINGS = {
  [EntryPointLocation.homeFeed]: {
    header: 'Home feed',
    railMetrics: {
      subheader: RAIL_SUBHEADER,
      metrics: [IMPRESSIONS_SETTINGS, CLICKS_SETTINGS, CTR_SETTINGS],
    },
    feedMetrics: {
      subheader: FEED_SUBHEADER,
      metrics: FEED_METRICS,
    },
  },
  [EntryPointLocation.storePage]: {
    header: 'Store page',
    railMetrics: {
      subheader: RAIL_SUBHEADER,
      metrics: [
        {
          ...IMPRESSIONS_SETTINGS,
          headerTooltipText: 'Number of impressions of your videos on your Shop store page',
        },
        {
          ...CLICKS_SETTINGS,
          headerTooltipText:
            'Number of video clicks from your Shop store page (equivalent to views in Shopify analytics)',
        },
        CTR_SETTINGS,
      ],
    },
    feedMetrics: {
      subheader: FEED_SUBHEADER,
      metrics: FEED_METRICS,
    },
  },
  [EntryPointLocation.productPage]: {
    header: 'Product pages',
    railMetrics: {
      subheader: RAIL_SUBHEADER,
      metrics: [
        {
          ...IMPRESSIONS_SETTINGS,
          headerTooltipText: 'Number of impressions of your videos on your Shop product pages',
        },
        {
          ...CLICKS_SETTINGS,
          headerTooltipText:
            'Number of video clicks from your Shop product pages (equivalent to views in Shopify analytics)',
        },
        CTR_SETTINGS,
      ],
    },
    feedMetrics: {
      subheader: FEED_SUBHEADER,
      metrics: FEED_METRICS,
    },
  },
};
