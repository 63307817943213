import { onCreateOrUpdateAppByAppKey } from 'app/src/graphql/subscriptions';
import { track } from 'app/src/helpers/Tracker';
import { API, graphqlOperation } from 'aws-amplify';
import axios from 'axios';
import { createActionsHook, createContainer, createHook, createStore } from 'react-sweet-state';
import { v4 as uuidv4 } from 'uuid';
import {
  BIG_COMMERCE,
  GORGIAS,
  GREEN_HOUSE,
  HUBSPOT,
  INSTAGRAM_GRAPH,
  INTERCOM,
  KLAVIYO,
  MAGENTO,
  MONDAY,
  OUTREACH,
  PRODUCTS_CATALOG_INTEGRATIONS,
  PRODUCTS_IMPORT,
  SHOPIFY,
  SHOPIFY_PLUS,
  TIKTOK,
  WIX,
} from '../constants/intergrations.constants';
import { createApp, createProjectApp, deleteApp, updateProjectApp } from '../graphql/mutations';
import { appsByAppKey, projectAppsByAppKey } from '../graphql/queries';
import { getRequest, graphqlRequest, postRequest } from '../helpers/API.js';
import Utils from '../utils';
import APP_CONFIG from 'app/src/config/app.config';
import { customUpdateApp } from 'app/src/graphql/custom_mutations';
import { parseDbApp, repalceOrAddApp } from 'app/src/utils/app.utils';
import { GlobalDrawerType } from 'app/src/complex_components/global_drawer/types/globalDrawer.types';

const initialState = {
  initialized: false,
  loading: false,
  error: null,
  apps: [],
  projectApps: [],
  wix: null,
  shopify: null,
  bigcommerce: null,
  synthesia: null,
  gorgias: null,
  intercom: null,
  hubspot: null,
  monday: null,
  outreach: null,
  klaviyo: null,
  magento: null,
  instagram: null,
  tiktok: null,
  instagramGraph: null,
  productsImport: null,
  selectedApp: null,
  selectedAppUrl: null,
  instagramApps: [],
  tiktokApps: [],
  previousSelectedApp: null,
};

const APP_NAMES = {
  wix: WIX,
  shopify: SHOPIFY,
  bigcommerce: BIG_COMMERCE,
};

const NOTIFIABLE_APPS = [MONDAY, GORGIAS, GREEN_HOUSE, INTERCOM, HUBSPOT, OUTREACH, KLAVIYO];

const actions = {
  fetch:
    () =>
    async ({ getState, setState }, { appKey }) => {
      if (getState().loading) return;
      appKey = appKey || Utils.getAppKey();
      if (!appKey) return;

      setState({ loading: true });
      try {
        const {
          data: {
            appsByAppKey: { items: apps },
          },
        } = await graphqlRequest(graphqlOperation(appsByAppKey, { appKey }));

        const {
          data: {
            projectAppsByAppKey: { items: projectApps },
          },
        } = await graphqlRequest(graphqlOperation(projectAppsByAppKey, { appKey }));

        const redirectUri = getRedirectUri();
        if (redirectUri) {
          clearRedirectUri();
          window.open(redirectUri, '_self');
        }

        const activeApps = apps.filter(({ active }) => active);

        const state = {
          ...initialState,
          apps: activeApps,
          projectApps,
          loading: false,
          initialized: true,
        };
        for (const app of activeApps) {
          if (app.app === INSTAGRAM_GRAPH) {
            const currentInstagramApps = state.instagramApps || [];
            state.instagramApps = [...currentInstagramApps, parseDbApp(app)];
          }

          if (app.app === TIKTOK) {
            const currentTiktokApps = state.tiktokApps || [];
            state.tiktokApps = [...currentTiktokApps, parseDbApp(app)];
          }

          state[app.app] = app;
        }

        setState(state);
      } catch (error) {
        console.error(error);
        setState({ error, loading: false });
      }
    },
  setSelectedApp:
    appUrl =>
    ({ setState, dispatch, getState }) => {
      const { selectedApp } = getState();
      const newSelectedApp = dispatch(actions.getAppUsingUrl(appUrl));

      if (selectedApp) {
        setState({
          previousSelectedApp: selectedApp,
        });
      }

      setState({
        selectedApp: newSelectedApp,
        selectedAppUrl: appUrl,
      });
      return newSelectedApp;
    },
  getAppsName:
    () =>
    ({ getState }) => {
      const { apps } = getState();
      return apps.map(app => app.app);
    },
  getAppsUrls:
    () =>
    ({ getState }) => {
      const { apps } = getState();
      return apps.flatMap(({ appUrl }) => appUrl || []);
    },
  getApp:
    appId =>
    ({ getState }) => {
      const { apps } = getState();
      return apps.find(({ id }) => id === appId) || {};
    },
  isShopifyOrWix:
    () =>
    ({ getState }) => {
      const { shopify, wix } = getState();

      return shopify || wix;
    },
  getIsShopifyPlus:
    () =>
    ({ getState }) => {
      try {
        const { apps } = getState();
        return apps.some(
          app => app.app === SHOPIFY && JSON.parse(app.data)?.shopifyPlan === SHOPIFY_PLUS
        );
      } catch (e) {
        return false;
      }
    },
  getIsSelectedShopifyPlus:
    () =>
    ({ getState }) => {
      try {
        const { selectedApp } = getState();
        return (
          selectedApp.app === SHOPIFY && JSON.parse(selectedApp.data)?.shopifyPlan === SHOPIFY_PLUS
        );
      } catch (e) {
        return false;
      }
    },
  getIsKlaviyo:
    () =>
    ({ getState }) => {
      const { klaviyo } = getState();

      return !!klaviyo;
    },
  getMultipassSecret:
    () =>
    ({ getState }) => {
      try {
        const { selectedApp } = getState();
        const isShopifyPlusApp =
          selectedApp.app === SHOPIFY && JSON.parse(selectedApp.data)?.shopifyPlan === SHOPIFY_PLUS;

        if (!isShopifyPlusApp) {
          return false;
        }

        return JSON.parse(selectedApp.data)?.multipassSecret;
      } catch (e) {
        return false;
      }
    },
  updateMultipassSecret:
    multipassSecret =>
    ({ getState, dispatch }) => {
      try {
        const { selectedApp } = getState();
        const isShopifyPlusApp =
          selectedApp.app === SHOPIFY && JSON.parse(selectedApp.data)?.shopifyPlan === SHOPIFY_PLUS;

        if (!isShopifyPlusApp) {
          return false;
        }

        const appData = JSON.parse(selectedApp.data);
        appData.multipassSecret = multipassSecret;
        dispatch(actions.updateApp(selectedApp.id, { data: JSON.stringify(appData) }));
      } catch (e) {
        return false;
      }
    },
  getProjectApp:
    (appName, projectId) =>
    ({ getState, dispatch }) => {
      const app = getState()[appName];
      if (!app) {
        return {};
      }

      return dispatch(actions.findProjectApp(app.id, projectId)) || {};
    },
  getIsProjectAppNotified:
    (appName, projectId) =>
    ({ getState, dispatch }) => {
      const app = getState()[appName];
      if (!app) {
        return false;
      }

      const projectApp = dispatch(actions.findProjectApp(app.id, projectId)) || {};

      const data = JSON.parse(projectApp?.data || '{}');
      if (data?.notify !== undefined) {
        return data.notify;
      }

      return true;
    },
  getNotifiableActiveApps:
    projectId =>
    ({ getState, dispatch }) => {
      const { apps } = getState();
      const activeNotifiableApps = NOTIFIABLE_APPS.filter(appName => {
        const currentApp = apps.find(({ app }) => app === appName);
        return !!currentApp?.active;
      });

      const appNotifyStatus = {};
      activeNotifiableApps.forEach(appName => {
        appNotifyStatus[appName] = dispatch(actions.getIsProjectAppNotified(appName, projectId));
      });
      return appNotifyStatus;
    },
  getProjectApps:
    projectId =>
    ({ getState }) => {
      const { projectApps } = getState();
      return projectApps.filter(projectApp => projectApp.projectId === projectId);
    },

  updateApp:
    (appId, input, options) =>
    async ({ getState, setState }) => {
      try {
        const { isBatchUpdate = false } = options || {};
        const { loading, apps, selectedApp, instagramApps } = getState();

        if (loading && !isBatchUpdate) {
          return;
        }

        setState({ loading: true });

        const response = await graphqlRequest(
          graphqlOperation(customUpdateApp, {
            id: appId,
            ...input,
          })
        );
        const updatedApp = response.data.updateApp;

        const updatedAppIndex = apps.findIndex(app => app.id === updatedApp.id);
        apps[updatedAppIndex] = updatedApp;

        setState({ apps: [...apps], loading: false });
        if (selectedApp?.appUrl === updatedApp?.appUrl) {
          setState({ selectedApp: updatedApp });
        }

        if (updatedApp.app === INSTAGRAM_GRAPH) {
          const newInstagramApps = instagramApps.map(app => {
            if (app.id === updatedApp.id) {
              return parseDbApp(updatedApp);
            }

            return app;
          });

          setState({ instagramApps: newInstagramApps });
        }

        return updatedApp;
      } catch (error) {
        console.log('app update error', error);
        setState({ loading: false });
        return null;
      }
    },
  createApp:
    (app, apiKey, appUrl) =>
    async ({ getState, setState }, { appKey }) => {
      const { loading, apps, instagramApps, tiktokApps } = getState();

      if (loading) {
        return;
      }

      setState({ loading: true });
      const owner = Utils.getOwner();
      const response = await graphqlRequest(
        graphqlOperation(createApp, {
          input: {
            id: uuidv4(),
            appKey,
            active: true,
            appExternalId: uuidv4(),
            owner,
            token: apiKey,
            app,
            appUrl,
          },
        })
      );
      const createdApp = response.data.createApp;
      apps.push(createdApp);

      const state = {};
      for (const app of apps) {
        state[app.app] = app;
      }

      if (createdApp.app === INSTAGRAM_GRAPH) {
        state.instagramApps = [...instagramApps, parseDbApp(createdApp)];
      }

      if (createdApp.app === TIKTOK) {
        state.tiktokApps = [...tiktokApps, parseDbApp(createdApp)];
      }

      setState({ ...state, apps: [...apps], loading: false });
    },
  findProjectApp:
    (appId, projectId) =>
    ({ getState }) => {
      const matchProjectAndApp = projectApp => {
        const sameApp = projectApp.appId === appId;
        const sameProject = projectApp.projectId === projectId;
        return sameApp && sameProject;
      };

      const { projectApps } = getState();
      return projectApps.find(matchProjectAndApp);
    },
  setNotifyAppInProject:
    (appName, projectId, notify) =>
    async ({ getState, setState, dispatch }) => {
      const { loading, projectApps } = getState();
      if (loading) {
        return;
      }

      setState({ loading: true });
      track('App Notification Settings Changed', { appName, projectId, notify });

      try {
        const app = getState()[appName] || {};
        const projectApp = dispatch(actions.findProjectApp(app.id, projectId));

        if (projectApp) {
          const input = {
            id: projectApp.id,
            data: JSON.stringify({ ...JSON.parse(projectApp?.data || {}), notify }),
          };

          const response = await graphqlRequest(graphqlOperation(updateProjectApp, { input }));
          projectApps[projectApps.findIndex(({ id }) => id === projectApp.id)] =
            response.data.updateProjectApp;
        } else {
          const input = {
            projectId,
            appId: app.id,
            active: true,
            data: JSON.stringify({ notify }),
            owner: Utils.getOwner(),
            appKey: Utils.getAppKey(),
          };

          const response = await graphqlRequest(graphqlOperation(createProjectApp, { input }));
          projectApps.push(response.data.createProjectApp);
        }

        setState({ projectApps: [...projectApps], loading: false });
        return { ok: true };
      } catch (error) {
        console.error('error in setProjectApp', error);
        console.error('set widget response', error?.response?.data);
        setState({ error, loading: false });
        return { ok: false, error };
      }
    },
  setProjectApp:
    (appName, projectId, active, publishId) =>
    async ({ getState, setState, dispatch }) => {
      const { loading, projectApps } = getState();

      if (loading) return;
      setState({ loading: true });
      const appKey = Utils.getAppKey();
      try {
        const app = getState()[appName] || {};
        if (appName === APP_NAMES.shopify) {
          await addWidgetToShopify(app.appUrl, app.token, appKey, active);
        } else if (appName === APP_NAMES.wix) {
          const response = await addWidgetToWix(app.token, publishId, active);
          console.log('addWidgetToWix', response);
        } else if (appName === APP_NAMES.bigcommerce) {
          const response = await addWidgetToBigCommerce(
            app.appExternalId,
            app.token,
            appKey,
            active
          );
          console.log('addWidgetToBigCommerce', response);
        }

        const projectApp = dispatch(actions.findProjectApp(app.id, projectId));
        if (projectApp) {
          const input = { id: projectApp.id, active: active };
          const response = await graphqlRequest(graphqlOperation(updateProjectApp, { input }));
          projectApps[projectApps.findIndex(({ id }) => id === projectApp.id)] =
            response.data.updateProjectApp;
        } else {
          const input = {
            projectId,
            appId: app.id,
            active,
            owner: Utils.getOwner(),
            appKey: Utils.getAppKey(),
          };
          const response = await graphqlRequest(graphqlOperation(createProjectApp, { input }));
          projectApps.push(response.data.createProjectApp);
        }

        setState({ projectApps: [...projectApps], loading: false });
        return { ok: true };
      } catch (error) {
        console.error('error in setProjectApp', error);
        console.error('set widget response', error?.response?.data);
        setState({ error, loading: false });
        return { ok: false, error };
      }
    },
  getWixEmbedRedirectLink:
    publishId =>
    ({ getState }) => {
      const {
        wix: { token },
      } = getState();
      return `${APP_CONFIG.WIX_BASE_URL}/embed?token=${token}&publishId=${publishId}`;
    },
  createOutreachResource:
    ({ type, gifUrl, appKey, name, publishId, token, appId, email }) =>
    () => {
      return axios.post(`${APP_CONFIG.API_URL}/outreach/outreach/resource`, {
        appKey,
        publishId,
        gifUrl,
        type,
        projectName: name,
        token,
        appId,
        email,
      });
    },
  authenticateToken:
    (apiKey, integrationName, appUrl) =>
    async ({ dispatch }) => {
      try {
        const { isAuthenticated } = await postRequest(integrationName, `/${integrationName}/auth`, {
          body: {
            apiKey,
            appUrl,
          },
        });
        if (isAuthenticated) {
          await dispatch(actions.createApp(integrationName, apiKey, appUrl));
        }

        return isAuthenticated;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
  authenticateKlaviyoToken:
    apiKey =>
    async ({ dispatch }) => {
      return dispatch(actions.authenticateToken(apiKey, 'klaviyo'));
    },
  authenticateMagentoToken:
    (apiKey, appUrl) =>
    async ({ dispatch }) => {
      return dispatch(actions.authenticateToken(apiKey, 'magento', appUrl));
    },
  fetchKlaviyoMailingLists:
    () =>
    async ({ getState }) => {
      try {
        const {
          klaviyo: { token },
        } = getState();
        if (!token) {
          console.info('No Klaviyo integration set. returning empty list');
          return [];
        }

        const res = await postRequest('klaviyo', '/klaviyo/lists', {
          body: {
            apiKey: token,
          },
        });
        if (res) {
          return res;
        }

        return [];
      } catch (e) {
        console.error('Error fetching klaviyo lists', e);
        return [];
      }
    },
  deleteApp:
    app =>
    async ({ dispatch }) => {
      try {
        await graphqlRequest(graphqlOperation(deleteApp, { input: { id: app.id } }));
        dispatch(actions.removeApp(app));
        return true;
      } catch (err) {
        console.log('error removing app', err);
        return false;
      }
    },
  getAppUsingUrl:
    appUrl =>
    ({ getState }) => {
      const { apps } = getState();

      if (!appUrl) {
        return;
      }

      return apps.find(app => app.appUrl === appUrl);
    },
  removeApp:
    app =>
    ({ getState, setState }) => {
      const state = getState();

      state.apps = state.apps.filter(({ id }) => id !== app.id);

      if (app.app === INSTAGRAM_GRAPH) {
        state.instagramApps = state.instagramApps.filter(({ id }) => id !== app.id);
      }

      if (app.app === TIKTOK) {
        state.tiktokApps = state.tiktokApps.filter(({ id }) => id !== app.id);
      }

      delete state[app.app];

      setState(state);
    },
  clearApps:
    () =>
    ({ setState }) => {
      setState({ ...initialState });
    },
  subscribeApps:
    () =>
    ({ setState, getState }, { appKey, setSnackbar, clearModalState, setCurrentDrawer }) => {
      try {
        const subscription = API.graphql(
          graphqlOperation(onCreateOrUpdateAppByAppKey, { appKey })
        ).subscribe({
          next: data => {
            const newApp = data?.value?.data?.onCreateOrUpdateAppByAppKey;
            if (!newApp) {
              return;
            }

            const { apps, instagramApps, tiktokApps } = getState();
            const isNew = !apps.find(({ id }) => id === newApp.id);

            const newApps = repalceOrAddApp({ apps, newApp });
            const newState = {};

            if (newApp.app === INSTAGRAM_GRAPH) {
              newState.instagramApps = repalceOrAddApp({
                apps: instagramApps,
                newApp: parseDbApp(newApp),
              });
            }

            if (newApp.app === TIKTOK) {
              newState.tiktokApps = repalceOrAddApp({
                apps: tiktokApps,
                newApp: parseDbApp(newApp),
              });
            }

            if (isNew) {
              const { userName } = Utils.safeParse(newApp?.data);
              if (userName) {
                setSnackbar(`${userName} has been successfully connected`, 'success', 10000);
                setCurrentDrawer({ currentDrawer: GlobalDrawerType.InstagramSettings });
                clearModalState();
              }
            }

            setState({ apps: newApps, [newApp.app]: newApp, ...newState });
          },
          error: ({ error: { errors = [] } = {} }) => {
            Utils.logErrorMessage(errors[0]?.message || 'Error in onUpdateApp subscribe');
          },
        });
        setState({ subscription });
      } catch (error) {
        Utils.logError('Cannot subscribe to apps', error);
      }
    },
  unsubscribeApps:
    () =>
    ({ getState, setState }) => {
      getState().subscription?.unsubscribe();

      setState({ subscription: null });
    },
  isEcomPlatformConnected:
    () =>
    ({ getState }) => {
      const { shopify, magento, productsImport } = getState();
      return !!(shopify || magento || productsImport);
    },
  isAppFromEcomPlatform: storeApp => () => {
    return [SHOPIFY, MAGENTO, PRODUCTS_IMPORT].includes(storeApp.app);
  },
  processHeroProject: projectId => () =>
    getRequest('shopify', `/process-hero-project/${projectId}`),
  toggleHeroProject: projectId => () =>
    postRequest('shopify', `/process-hero-project/${projectId}`),
  updateHeroProject: projectId => () => getRequest('shopify', `/update-hero-config/${projectId}`),
  setPrimaryHeroProject: projectId => () =>
    postRequest('shopify', `/set-primary-hero/${projectId}`),

  createShopifyTvPage:
    (publishId, { withHeader = false } = {}) =>
    () =>
      getRequest(
        'shopify',
        `/create-shopify-tv-page?publishId=${publishId}&withHeader=${withHeader}`
      ),
  updateSyncTagsSettings:
    (primaryId, replicaIds, syndicateBy) =>
    (_, { appKey }) =>
      postRequest('shopify', `/sync-vod-tags-settings`, {
        body: {
          appKey,
          primaryId,
          replicaIds,
          syndicateBy,
        },
      }),
  completeShopifyAuth:
    ({ tempId, appKey }) =>
    async (_, { appKey: currentAppKey }) => {
      try {
        const response = await postRequest('shopify', '/shopify/complete-shopify-auth', {
          body: {
            appKey: appKey || currentAppKey,
            tempId,
          },
        });
        if (response.err) {
          Utils.logError('the installation was not finished properly', response.err);
          return { errorMessage: 'the installation was not finished properly' };
        }

        return response;
      } catch (error) {
        Utils.logError('the installation was not finished properly', error);
        return { errorMessage: 'the installation was not finished properly' };
      }
    },
  getProductsCatalogApp:
    () =>
    ({ getState }) => {
      const { apps } = getState();
      return apps?.find(appItem => PRODUCTS_CATALOG_INTEGRATIONS.includes(appItem.app));
    },
  getHasApp:
    appType =>
    ({ getState }) => {
      const { apps } = getState();
      return !!apps?.find(appItem => appItem.app === appType);
    },
};

const getRedirectUri = () => {
  return localStorage.getItem('redirect-uri');
};

const clearRedirectUri = () => {
  localStorage.removeItem('redirect-uri');
};

const addWidgetToShopify = async (appUrl, token, appKey, active) => {
  return axios.put(`${APP_CONFIG.SHOPIFY_URL}/widget`, {
    shop: appUrl,
    token,
    appKey,
    active,
  });
};

const addWidgetToBigCommerce = async (appExternalId, token, appKey, active) => {
  return axios.put(APP_CONFIG.PUBLISH_BIG_COMMERCE_URL, {
    appExternalId,
    token,
    appKey,
    active,
  });
};

const addWidgetToWix = async (token, publishId, active) => {
  return axios.put(APP_CONFIG.PUBLISH_WIX_URL, { token, publishId, active });
};

const selectedApp = ({ selectedApp }) => selectedApp;
const isSelectedShopifyApp = ({ selectedApp }) => selectedApp?.app === SHOPIFY;

const selectInstagramApps = ({ instagramApps, loading }) => ({ instagramApps, loading });

const AppsStore = createStore({ initialState, actions, name: 'Apps' });

export const useSelectedApp = createHook(AppsStore, { selector: selectedApp });
export const useInstagramApps = createHook(AppsStore, { selector: selectInstagramApps });
export const useIsSelectedShopifyApp = createHook(AppsStore, { selector: isSelectedShopifyApp });

export const useApps = createHook(AppsStore);

export const useAppActions = createActionsHook(AppsStore);

export const AppsContainer = createContainer(AppsStore, {
  onInit:
    () =>
    ({ dispatch }) => {
      dispatch(actions.fetch());
      dispatch(actions.subscribeApps());
    },
  onCleanup:
    () =>
    ({ dispatch }) => {
      dispatch(actions.clearApps());
      dispatch(actions.unsubscribeApps());
    },
});
