import React from 'react';
import { ContextMenuIcon } from 'app/src/pages/dashboard/components/card/Card';
import CardControls from 'app/src/pages/dashboard/components/card/CardControls';
import useHasResourcePermissions from 'src/hooks/useHasResourcePermissions';
import { useAccount } from 'src/context/AccountStore';
import { track } from 'src/helpers/Tracker';
import Utils from 'src/utils';
import usePlayerUrl from 'src/hooks/usePlayerUrl';
import { useSnackBarActions } from 'src/context/ui_store/SnackBarStore';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import ThreeVerticalDotsIcon from 'app/src/images/dashboard_v2/ThreeVerticalDotsIcon';
import LinkIcon from 'src/images/dashboard_v2/LinkIcon';

const OnsiteCardControls = ({ project, toggleSelected, isSelected, isSelectionMode, openMenu }) => {
  const { setSnackbar } = useSnackBarActions();
  const [{ isAccountOwner }] = useAccount();
  const hasMenuPermissions = useHasResourcePermissions(project, isAccountOwner);
  const { playerUrl } = usePlayerUrl(project?.id);

  const copyLink = e => {
    e.stopPropagation();
    track('Copy Sharable Link Click', { location: 'Project Card' });
    Utils.copyToClipboard(playerUrl);
    setSnackbar('Link Copied to Clipboard');
  };

  return (
    <CardControls
      isSelected={isSelected}
      toggleSelected={() => toggleSelected(project.id)}
      isSelectionMode={isSelectionMode}
    >
      <Gap8VerticalFlex>
        {hasMenuPermissions && (
          <ContextMenuIcon onClick={openMenu}>
            <ThreeVerticalDotsIcon />
          </ContextMenuIcon>
        )}
        {!project.dynamic && (
          <ContextMenuIcon onClick={copyLink}>
            <LinkIcon />
          </ContextMenuIcon>
        )}
      </Gap8VerticalFlex>
    </CardControls>
  );
};

export default OnsiteCardControls;
