import React, { useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { TextSmall } from 'shared/react/components/complex/Text';
import WindowActionBarButtons from 'app/src/complex_components/WindowActionBarButtons';
import InteractiveVideo from 'app/src/complex_components/InteractiveVideo';
import tinycolor from 'tinycolor2';
const MORE_TOLSTOYS_COLOR_LIGHT_RATE = 7.5;

const TemplatePreview = ({
  selectedTemplate,
  selectedReadyMade,
  hasExplanation,
  isMobile,
  hasMultipleReadyMades,
  readyMades,
  setSelectedReadyMade,
}) => {
  const isCollectReviews = selectedTemplate?.key === 'collect_reviews';
  const [videoContainerHeight, setVideoContainerHeight] = useState(null);
  const videoContainerRef = useRef(null);
  const theme = useTheme();
  const handleReadyMadeSelected = readyMade => {
    setSelectedReadyMade(readyMade);
  };

  const getSelectReviews = () => {
    if (!isCollectReviews) {
      return;
    }

    return (
      <EmailPreview>
        <SectionContainer>
          <SectionLabel>From:</SectionLabel>
          <SectionValue>customer-service@company.com</SectionValue>
        </SectionContainer>
        <SectionContainer>
          <SectionLabel>Subject:</SectionLabel>
          <SectionValue>Leave us a review ⭐⭐⭐</SectionValue>
        </SectionContainer>
      </EmailPreview>
    );
  };

  const getContent = () => {
    if (selectedReadyMade?.url?.includes('gif')) {
      return <StyledGifContainer src={selectedReadyMade.url} />;
    }

    return (
      <StyledInteractiveVideo
        src={selectedReadyMade.url}
        width="100%"
        isCollectReviews={isCollectReviews}
        backgroundColor={theme.colors.gray24}
        borderRadius="16px"
        height={hasExplanation && isMobile ? '400px' : videoContainerHeight}
        title="Preview Tolstoy"
      />
    );
  };

  const getPreviewContainer = () => {
    if (isCollectReviews) {
      return (
        <TolstoyPreviewContainer isCollectReviews={isCollectReviews}>
          <StyledWindowActionBarButtons />
          {getSelectReviews()}
          {getContent()}
        </TolstoyPreviewContainer>
      );
    }

    return getContent();
  };

  const getReadyMades = () => {
    if (!hasMultipleReadyMades) {
      return null;
    }

    return (
      <MoreTolstoysContainer>
        {readyMades.map((readyMade, i) => (
          <PosterImage
            src={readyMade.posterImageUrl}
            key={`${readyMade.id}}+${i}`}
            isSelected={readyMade.id === selectedReadyMade.id}
            selectedColor={tinycolor(theme.colors.primary)
              .lighten(MORE_TOLSTOYS_COLOR_LIGHT_RATE)
              .toHexString()}
            hoverColor={tinycolor(theme.colors.primaryHover)
              .lighten(MORE_TOLSTOYS_COLOR_LIGHT_RATE)
              .toHexString()}
            activeColor={tinycolor(theme.colors.primaryActive)
              .lighten(MORE_TOLSTOYS_COLOR_LIGHT_RATE)
              .toHexString()}
            onClick={() => handleReadyMadeSelected(readyMade)}
          />
        ))}
      </MoreTolstoysContainer>
    );
  };

  useEffect(() => {
    if (!videoContainerRef.current) {
      return;
    }
    setVideoContainerHeight(
      isMobile ? videoContainerRef.current.getBoundingClientRect().height + 'px' : '100%'
    );
  }, [videoContainerRef, setVideoContainerHeight, isMobile]);

  return (
    <Content
      ref={videoContainerRef}
      isCollectReviews={isCollectReviews}
      hasExplanation={hasExplanation}
    >
      {getReadyMades()}
      {videoContainerHeight && getPreviewContainer()}
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  align-self: start;
  gap: 8px;
  grid-column: 1;
  height: 500px;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    height: 100%;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
  }
`;

const PosterImage = styled.img`
  border: 4px solid ${props => (props.isSelected ? props.selectedColor : 'transparent')};
  background: ${props => (props.isSelected ? props.selectedColor : 'transparent')};
  object-fit: cover;
  cursor: pointer;
  border-radius: 16px;
  width: 64px;
  height: 64px;
  overflow: hidden;

  &:hover {
    border: 4px solid ${props => (props.isSelected ? props.selectedColor : props.hoverColor)};
    background: ${props => (props.isSelected ? props.selectedColor : props.hoverColor)};
  }

  &:active {
    border: 4px solid ${props => (props.isSelected ? props.selectedColor : props.activeColor)};
    background: ${props => (props.isSelected ? props.selectedColor : props.activeColor)};
  }
`;

const MoreTolstoysContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  align-items: center;
  background: ${({ theme }) => theme.colors.gray24};
  border-radius: 24px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100%;
  width: fit-content;
  min-width: 96px;
  height: fit-content;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    overflow-x: auto;
    overflow-y: hidden;
    padding-top: 8px;
    padding-bottom: 8px;
    gap: 8px;
    flex-direction: row;
    min-height: 84px;
  }
`;

const SectionContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.white};
  padding: 16px 24px;
`;

const SectionLabel = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.gray4};
`;

const SectionValue = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.gray4};
`;

const StyledGifContainer = styled.img`
  width: 100%;
  height: inherit;
  border-radius: 16px;
`;

const StyledWindowActionBarButtons = styled(WindowActionBarButtons)`
  margin: 12px 16px;
`;

const EmailPreview = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 8px 0;
`;

const StyledInteractiveVideo = styled(InteractiveVideo)``;

const TolstoyPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 12px solid
    ${({ isCollectReviews, theme }) => (isCollectReviews ? 'transparent' : theme.colors.black)};
  padding: ${({ isCollectReviews }) => (isCollectReviews ? 8 : 0)}px;
  border-width: ${({ isCollectReviews }) => (isCollectReviews ? 6 : 12)}px;
  border-radius: ${({ isCollectReviews }) => (isCollectReviews ? 16 : 40)}px;
  background: ${({ isCollectReviews, theme }) =>
    isCollectReviews ? theme.colors.gray23 : theme.colors.white};
  overflow: hidden;
`;

export default TemplatePreview;
