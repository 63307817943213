import React from 'react';

const PlayArrowIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={11} height={12} fill="none" {...props}>
      <path
        d="M9.541 4.248c1.382.76 1.382 2.745 0 3.504L2.964 11.37C1.631 12.103 0 11.139 0 9.617V2.383C0 .86 1.631-.103 2.964.63z"
        fill="#fff"
      />
    </svg>
  );
};

export default PlayArrowIcon;
