import { createStore, createHook } from 'react-sweet-state';

const initialState = {
  threadId: null,
  conversationHistory: [],
  defaultMessage: 'Hi Toly',
  isModalVisible: false,
  isLogoOpen: true,
  initialUserInput: '',
};

const actions = {
  setThreadId:
    threadId =>
    ({ setState }) => {
      setState({ threadId });
    },
  addToConversation:
    message =>
    ({ setState, getState }) => {
      const conversationHistory = [...getState().conversationHistory, message];
      setState({ conversationHistory });
    },
  setModalVisible:
    isModalVisible =>
    ({ setState }) => {
      setState({ isModalVisible });
    },
  toggleModal:
    (initialUserInput = '') =>
    ({ setState, getState }) => {
      const { isModalVisible, isLogoOpen } = getState();
      setState({ isModalVisible: !isModalVisible, isLogoOpen: !isLogoOpen, initialUserInput });
    },
};

const TolyChatStore = createStore({
  initialState,
  actions,
  name: 'TolyChatStore',
});

export const useTolyChat = createHook(TolyChatStore);
