import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DashedInput from 'app/src/basic_components/DashedInput';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import { TextBodyLeadingBold } from 'shared/react/components/complex/Text';
import { getIsFeed } from 'app/src/context/ProjectsStore';
import { useDebounce } from 'app/src/hooks/useDebounce';
import { DEBOUNCE_TIME } from 'app/src/constants/builder.constants';

const StepTitleV2 = ({ order, isProductResultStep, project }) => {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext();
  const [description] = watch(['description']);
  const [inputValue, setInputValue] = useState(description || '');
  const debouncedInputValue = useDebounce(inputValue, DEBOUNCE_TIME);

  const setStepDescription = value => {
    if (value === description) {
      return;
    }

    setValue('description', value);
  };

  const getStepNamePrefix = () => {
    if (isProductResultStep) {
      return 'Result';
    }
    if (getIsFeed(project)) {
      return `Video ${order + 1}`;
    }

    return `${t('step.name')} ${order + 1}`;
  };

  const onChange = e => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    setInputValue(description || '');
  }, [description]);

  useEffect(() => {
    setStepDescription(debouncedInputValue);
  }, [debouncedInputValue]);

  return (
    <TitleTextFieldContainer>
      <TitlePrefix>{getStepNamePrefix()}</TitlePrefix>
      <TitleTextField
        value={inputValue}
        placeholder="Give this part a name"
        onChange={onChange}
        maxLength="100"
      />
    </TitleTextFieldContainer>
  );
};

const Title = styled(TextBodyLeadingBold)`
  color: ${({ theme }) => theme.colors.black};
`;

const TitleTextField = styled(DashedInput)`
  font-style: normal;
  font-size: 16px;

  &&::placeholder {
    font-size: 14px;
    font-weight: 400;
  }
`;

const TitlePrefix = styled(Title)`
  margin-right: 8px;
  white-space: nowrap;
`;

const TitleTextFieldContainer = styled(HorizontalFlexWrap)`
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
`;

export default StepTitleV2;
