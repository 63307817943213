import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import QuizChoice from 'app/src/pages/project/pages/quiz/quiz_result/flow/rule/choices/QuizChoice';
import { useQuizContext } from 'app/src/pages/project/pages/quiz/QuizContext';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import { BolderTextSmall } from 'shared/react/components/complex/Text';
import AddChoiceButton from 'app/src/pages/project/pages/quiz/quiz_result/flow/rule/choices/AddChoiceButton';

function QuizChoiceList({ quizResultId, flowId, rule }) {
  const { quizResults, setQuizResults, setUnsaved, project } = useQuizContext();
  const [choices, setChoices] = useState([]);
  const step = project?.steps?.items?.find(step => step.name === rule.stepId);

  const getChoices = choices => {
    const filteredAnswers = step?.answers?.filter(({ key }) => !choices.includes(key));
    return filteredAnswers?.map(({ key, text }) => ({ key, id: key, title: text, value: text }));
  };

  useEffect(() => {
    const choices = getChoices(rule.choices) || [];
    setChoices(choices);
  }, []);

  const handleDeleteChoice = (quizResultId, flowId, stepId, clickedChoice) => {
    const newQuizResults = [...quizResults];
    const quizResult = newQuizResults.find(quizResult => quizResult.id === quizResultId);
    const flow = quizResult.flows.find(flow => flow.id === flowId);

    const rule = flow.rules.find(rule => rule.stepId === stepId);

    rule.choices = rule.choices.filter(choice => choice !== clickedChoice);
    setChoices(getChoices(rule.choices, stepId));
    setQuizResults(newQuizResults);
    setUnsaved(true);
  };

  const getAfterChoiceOption = i => {
    if (rule.choices.length - 1 !== i) {
      return <BolderTextSmall>or</BolderTextSmall>;
    }

    return (
      <AddChoiceButton
        choices={choices}
        getChoices={getChoices}
        quizResultId={quizResultId}
        setChoices={setChoices}
        flowId={flowId}
        rule={rule}
      />
    );
  };

  if (!rule.choices?.length) {
    return (
      <AddChoiceButton
        choices={choices}
        getChoices={getChoices}
        quizResultId={quizResultId}
        setChoices={setChoices}
        flowId={flowId}
        rule={rule}
      />
    );
  }

  return (
    <>
      {rule.choices.map((choice, i) => {
        const answer = step?.answers?.find(({ key, text }) => choice === key || text === choice);
        return (
          <ChoiceContainer key={choice}>
            <QuizChoice
              onClick={() => handleDeleteChoice(quizResultId, flowId, rule.stepId, choice)}
            >
              {answer?.text}
            </QuizChoice>
            {getAfterChoiceOption(i)}
          </ChoiceContainer>
        );
      })}
    </>
  );
}

export default QuizChoiceList;

const ChoiceContainer = styled(Gap8HorizontalFlexWrap)`
  align-items: center;
`;
