import React from 'react';

const FolderIcon = props => {
  return (
    <svg
      width="352"
      height="317"
      viewBox="0 0 352 317"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M328.188 34.871h-193.25L103.187 0H23.813C10.418 0 0 11.987 0 26.154v43.59h352v-8.719c0-14.166-10.914-26.154-23.812-26.154ZM0 290.846C0 305.558 10.418 317 23.813 317h304.375C341.086 317 352 305.558 352 290.846V69.743H0v221.103Z"
        fill="#fff"
      />
    </svg>
  );
};

export default FolderIcon;
