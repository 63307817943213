import React from 'react';
import styled from 'styled-components';
import BasicButton from 'shared/react/components/basic/button/button/BasicButton';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

const NavigationButtons = ({
  activeIndex,
  setActiveIndex,
  reviewLastStep = false,
  setStepIndex,
  stepIndex,
  handleModalClose,
  maxIndex,
  setStepReviewed,
  onSave,
  isStoreSelected,
}) => {
  const REVIEW_STEPS_INDEX = 2;

  const onBackClick = () => {
    if (activeIndex <= 1) {
      setActiveIndex(1);
      return;
    }

    if (activeIndex === REVIEW_STEPS_INDEX && stepIndex > 0) {
      setStepReviewed(stepIndex);
      setStepIndex(i => i - 1);
      return;
    }

    setActiveIndex(i => i - 1);
  };

  const onDoneClick = async () => {
    await onSave();
    handleModalClose();
  };

  const onNextStepClick = stepIndex => {
    setStepReviewed(stepIndex);
    setStepIndex(i => i + 1);
  };

  const onNextClick = async () => {
    if (activeIndex >= maxIndex) {
      await onDoneClick();
      return;
    }

    if (activeIndex === REVIEW_STEPS_INDEX && !reviewLastStep) {
      onNextStepClick(stepIndex);
      return;
    }

    if (activeIndex) {
      setActiveIndex(i => i + 1);
    }
  };

  const getNextText = () => {
    if (activeIndex === REVIEW_STEPS_INDEX && !reviewLastStep) {
      return 'Next Step';
    }

    if (activeIndex === maxIndex) {
      return 'Done';
    }

    return 'Next';
  };

  return (
    <LayoutRoot>
      <BackButton onClick={onBackClick}>Back</BackButton>
      <NextButton onClick={onNextClick} disabled={!isStoreSelected}>
        {getNextText()}
      </NextButton>
    </LayoutRoot>
  );
};

export default NavigationButtons;

const LayoutRoot = styled(HorizontalFlex)`
  align-items: flex-end;
  justify-content: flex-end;
  gap: 8px;
  flex-grow: 0;
  margin-bottom: 16px;
  margin-right: 24px;
  box-sizing: border-box;
`;

const NextButton = styled(BasicButton)`
  padding: 12px 24px;
  border-radius: 100px;
  color: ${({ theme }) => theme.colors.white};
  width: fit-content;
`;

const BackButton = styled(NextButton)`
  background: ${({ theme }) => theme.colors.primaryLight};
  color: ${({ theme }) => theme.colors.primary};

  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }
`;
