import React, { useState } from 'react';
import styled from 'styled-components';
import Tooltip from 'app/src/basic_components/Tooltip';
import AddRuleButton from 'app/src/pages/project/pages/rules_and_triggers/editor/shared/AddRuleButton';
import { useQuizFlowContext } from 'app/src/pages/project/pages/quiz/quiz_result/flow/QuizFlowContext';
import { useQuizContext } from 'app/src/pages/project/pages/quiz/QuizContext';
import MenuItemV2 from 'app/src/complex_components/MenuItemV2';
import MenuWithContainer from 'shared/react/components/basic/MenuWithContainer';

function FlowFooter({ quizResultId, flowId }) {
  const [anchorEl, setAnchorEl] = useState();
  const { quizResults, setQuizResults, setUnsaved } = useQuizContext();
  const { partMenuItems, setPartMenuItems } = useQuizFlowContext();

  const handleCreateRuleClick = (quizResultId, flowId, partKey) => {
    const newQuizResults = [...quizResults];
    const quizResult = newQuizResults.find(quizResult => quizResult.id === quizResultId);
    const flow = quizResult.flows.find(flow => flow.id === flowId);
    setPartMenuItems([...partMenuItems.filter(({ id }) => id !== partKey)]);

    flow.rules.push({
      stepId: partKey,
      choices: [],
    });
    setQuizResults(newQuizResults);
    setUnsaved(true);
  };

  const isDisabled = !partMenuItems.length;

  return (
    <LayoutRoot>
      <Tooltip
        text={isDisabled ? 'You have already added all possible parts.' : ''}
        placement="right"
      >
        <AddRuleButton onClick={e => setAnchorEl(e.target)} disabled={isDisabled} />
      </Tooltip>
      <MenuWithContainer anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
        {partMenuItems.map(({ title, value, id }) => {
          return (
            <MenuItemV2
              key={value}
              title={title}
              onClick={() => handleCreateRuleClick(quizResultId, flowId, id)}
            />
          );
        })}
      </MenuWithContainer>
    </LayoutRoot>
  );
}

export default FlowFooter;

const LayoutRoot = styled.div`
  width: fit-content;
`;
