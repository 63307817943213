export const STATUS_ALL = 'all';
export const STATUS_NEW = 'read';
export const STATUS_REPLIED = 'totalReplies';
export const STATUS_ARCHIVE = 'archived';
export const STATUS_IS_SPAM = 'isSpam';
export const PARENT_URL = 'parentUrl';
export const EMAIL_FILTER = 'emailCollected';
export const NOT_ANONYMOUS = 'notAnonymous';

export const NEW_RESPONSE_SECONDS = 120;
