import React from 'react';

const Filter = () => {
  return (
    <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.8828 0.882812C12.7188 0.507812 12.3203 0.25 11.8984 0.25H2.07812C1.65625 0.25 1.25781 0.507812 1.09375 0.882812C0.90625 1.25781 0.976562 1.70312 1.23438 2.00781L5.125 6.76562V8.35938C5.125 8.66406 5.26562 8.92188 5.5 9.10938L7.42188 10.5859C7.58594 10.7031 7.77344 10.75 7.96094 10.75C8.45312 10.75 8.85156 10.3516 8.85156 9.85938V6.76562L12.7422 2.00781C13 1.70312 13.0703 1.25781 12.8828 0.882812ZM7.91406 6.15625C7.79688 6.27344 7.75 6.4375 7.75 6.625V9.41406L6.25 8.24219V6.64844C6.25 6.46094 6.17969 6.29688 6.0625 6.15625L2.17188 1.39844H11.8047L7.91406 6.15625Z"
        fill="#7D8087"
      />
    </svg>
  );
};

export default Filter;
