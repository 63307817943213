import { TextH6 } from 'shared/react/components/complex/Text';
import React from 'react';
import { SetLiveContainer } from 'app/src/pages/modals/publish/widget/common';
import SetLiveTogglePricing from 'app/src/complex_components/pricing/SetLiveTogglePricing';
import IntegrationSetLiveContent from 'app/src/pages/modals/publish/widget/IntegrationSetLiveContent';

const IntegrationSetLive = ({ showSetLive, ...props }) => {
  const { loading, live, onSetLive } = props;

  return (
    <>
      {showSetLive && (
        <SetLiveContainer>
          <TextH6>Set Live</TextH6>
          <SetLiveTogglePricing disabled={loading} checked={live} onChange={onSetLive} />
        </SetLiveContainer>
      )}
      <IntegrationSetLiveContent {...props} />
    </>
  );
};

export default IntegrationSetLive;
