import React, { useState } from 'react';
import styled from 'styled-components';
import VideoWasntFound from './VideoWasntFound';
import classNames from 'classnames';
import PlayerLoader from 'player/src/components/PlayerLoader';
import PlayerContainer from 'player/src/components/v2/PlayerContainer';
import { FontFamilyCss } from 'player/src/components/player/FontFamilyCss';

const PlayerVideoContent = ({
  config,
  playlist,
  verticalOrientation,
  landingPage,
  isReply,
  state,
  pause,
  isMobile,
  customLayout,
  customFontFamily,
  shouldShowChatLandingPage,
  layout,
}) => {
  const [firstVideoLoaded, setFirstVideoLoaded] = useState(false);

  if (config?.disabled) {
    return <VideoWasntFound />;
  }

  if (!playlist) {
    return null;
  }

  return (
    <div
      className={classNames('player-main-container', {
        'player-main-container-vertical': verticalOrientation,
        'player-main-container-horizontal': !verticalOrientation,
        'landing-app': landingPage,
        'landing-app-horizontal': landingPage && !verticalOrientation,
        app: !landingPage,
        'player-main-container-reply': isReply,
        'player-position-not-relative': !state.landingPage,
        'player-main-container-vertical-chat':
          state.chatLandingPage && verticalOrientation && !isMobile,
        'player-main-container-chat': state.chatLandingPage,
      })}
    >
      {state.name && <UserName>Hey {state.name}, you have a new reply!</UserName>}
      <StyledPlayerContainer
        className={classNames({
          'enter-visibility-transition': firstVideoLoaded,
          'exit-visibility-transition': !firstVideoLoaded,
        })}
        setFirstVideoLoaded={setFirstVideoLoaded}
        firstVideoLoaded={firstVideoLoaded}
        startPause={pause}
        customLayout={customLayout}
        customFontFamily={customFontFamily}
        shouldShowChatLandingPage={shouldShowChatLandingPage}
        {...state}
      />
      <div
        className={classNames('player-loading-indicator-container', {
          'enter-visibility-transition': !firstVideoLoaded,
          'exit-visibility-transition': firstVideoLoaded,
        })}
      >
        <PlayerLoader />
      </div>
      <div className={`layout-${layout}`}></div>
    </div>
  );
};

const UserName = styled.div`
  position: absolute;
  top: -42px;
  left: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.0025em;
`;

const StyledPlayerContainer = styled(PlayerContainer)`
  ${FontFamilyCss};
`;

export default PlayerVideoContent;
