const MINIMUM_LIGHT_BRIGHTNESS = 160;

const getRgbByHex = hex => {
  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);
  return [r, g, b];
};

const getColorBrightness = hex => {
  const [r, g, b] = getRgbByHex(hex);
  return (r * 299 + g * 587 + b * 114) / 1000;
};

export const getShouldContrastColor = color => {
  if (!color) {
    return false;
  }

  return getColorBrightness(color) < MINIMUM_LIGHT_BRIGHTNESS;
};

// Used to shade colors instead of tinycolor to avoid installing it in the player
// https://stackoverflow.com/a/13532993

export const shadeColor = (color, percent) => {
  let [r, g, b] = getRgbByHex(color);

  r = Math.floor((r * (100 + percent)) / 100);
  g = Math.floor((g * (100 + percent)) / 100);
  b = Math.floor((b * (100 + percent)) / 100);

  r = r < 255 ? r : 255;
  g = g < 255 ? g : 255;
  b = b < 255 ? b : 255;

  const RR = r.toString(16).length === 1 ? '0' + r.toString(16) : r.toString(16);
  const GG = g.toString(16).length === 1 ? '0' + g.toString(16) : g.toString(16);
  const BB = b.toString(16).length === 1 ? '0' + b.toString(16) : b.toString(16);

  return '#' + RR + GG + BB;
};

const getOpacitySuffix = opacityPercentage => {
  return Math.round(opacityPercentage * 2.55)
    .toString(16)
    .toUpperCase()
    .padStart(2, '0');
};

export const getHexOpacity = (color, opacityPercentage) => {
  return `${color}${getOpacitySuffix(opacityPercentage)}`;
};
