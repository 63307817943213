import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  IMPORT_FROM_INSTAGRAM,
  IMPORT_FROM_TIKTOK,
  LIBRARY,
  TOLSTOY_LIBRARY,
  RECORD_SCREEN,
  RECORD_VIDEO,
  START_FROM_TEMPLATE,
  STOCK_VIDEOS,
  UPLOAD_FILE,
} from 'app/src/constants/creation_method.constants';
import VideoContainer from 'app/src/pages/creation_flow/right_section/video_container/VideoContainer';
import TolstoysTab from 'app/src/pages/dashboard/pages/libraries/tolstoys_tab/TolstoysTab';
import Templates from 'app/src/pages/creation_flow/right_section/templates/Templates';
import { rightSectionSize } from 'app/src/pages/creation_flow/creationFlowBreakpoints';
import StockVideos from 'app/src/pages/creation_flow/right_section/stock_videos/StockVideos';
import ModalHeader from 'app/src/pages/creation_flow/right_section/ModalHeader';
import Message from 'app/src/pages/creation_flow/right_section/Message';
import UploadFile from 'app/src/pages/creation_flow/right_section/upload_file/UploadFile';
import ImportTikTok from 'app/src/pages/creation_flow/right_section/import_tiktok_link/ImportTikTok';
import ImportFromInstagram from 'app/src/pages/creation_flow/right_section/import_from_instagram/ImportFromInstagram';
import { useImportVideos } from 'app/src/context/ui_store/ImportVideosStore';
import ImportMultipleBadge from './ImportMultipleBadge';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Videos from 'app/src/pages/dashboard/pages/videos/Videos';
import useModalVideoFilters from 'app/src/pages/creation_flow/right_section/hooks/useModalVideoFilters';
import { useDebounce } from 'app/src/hooks/useDebounce';

const RightSection = ({
  selectedMethod,
  uploadedBlob,
  onCreateNewVideo,
  resetVideoState,
  uploadProgress,
  onVideoChoose,
  onTolstoyChoose,
  onCopyTemplate,
  onClose,
  selectedPreviewMode,
  setSelectedPreviewMode,
  isLoading,
  clearSelectedMethod,
  uploadState,
  onMethodClick,
  onDropFiles,
  onCreateVideoFailure,
  videosUploadCompleted,
  numberOfParts,
  numberOfAllowedParts,
  onImportClick,
  isLeftPanelVisible,
  defaultVideoFilterProps,
}) => {
  const isUploadFile = selectedMethod === UPLOAD_FILE;
  const [, { resetSelectedVideosToImport }] = useImportVideos();
  const { videosFilters, setVideosFilters } = useModalVideoFilters(defaultVideoFilterProps);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 300);

  const onVideoClick = video => {
    if (!isLoading) {
      onVideoChoose(video);
    }
  };

  const onTolstoyClick = tolstoy => {
    if (!isLoading) {
      onTolstoyChoose(tolstoy);
    }
  };

  useEffect(() => {
    resetSelectedVideosToImport();
  }, [selectedMethod]);

  const getComponent = () => {
    if ([RECORD_VIDEO, RECORD_SCREEN].includes(selectedMethod)) {
      return (
        <VideoContainer
          onCreateNewVideo={onCreateNewVideo}
          selectedMethod={selectedMethod}
          uploadProgress={uploadProgress}
          selectedPreviewMode={selectedPreviewMode}
          setSelectedPreviewMode={setSelectedPreviewMode}
          uploadState={uploadState}
        />
      );
    }

    if (selectedMethod === UPLOAD_FILE) {
      return (
        <UploadFile
          uploadedBlob={uploadedBlob}
          onCreateNewVideo={onCreateNewVideo}
          onSelectFilesClick={() => onMethodClick(UPLOAD_FILE)}
          onDropFiles={onDropFiles}
          numberOfAllowedParts={numberOfAllowedParts}
          numberOfParts={numberOfParts}
        />
      );
    }

    if (selectedMethod === LIBRARY) {
      return (
        <StyledVideos
          videosFilters={videosFilters}
          setVideosFilters={setVideosFilters}
          shouldSidebarRedirect={false}
          onCreateNewVideo={onCreateNewVideo}
          onVideoChoose={onVideoClick}
        />
      );
    }

    if (selectedMethod === TOLSTOY_LIBRARY) {
      return (
        <TolstoysTab
          isLoading={isLoading}
          isModalMode={true}
          disableExtraTolstoyClick={true}
          onProjectClick={onTolstoyClick}
          debouncedSearchValue={debouncedSearchValue}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      );
    }

    if (selectedMethod === START_FROM_TEMPLATE) {
      return <Templates onCopyTemplate={onCopyTemplate} />;
    }

    if (selectedMethod === STOCK_VIDEOS) {
      return <StockVideos onVideoChoose={onVideoChoose} isLoading={isLoading} />;
    }

    if (selectedMethod === IMPORT_FROM_TIKTOK) {
      return <ImportTikTok onVideoChoose={onVideoChoose} isLoading={isLoading} />;
    }

    if (selectedMethod === IMPORT_FROM_INSTAGRAM) {
      return (
        <ImportFromInstagram
          onImportClick={onImportClick}
          isLoading={isLoading}
          onVideoChoose={onVideoChoose}
          onCreateVideoFailure={onCreateVideoFailure}
        />
      );
    }
  };

  return (
    <LayoutRoot fixedHeight={isLeftPanelVisible || selectedMethod !== IMPORT_FROM_TIKTOK}>
      <ModalHeader
        selectedMethod={selectedMethod}
        clearSelectedMethod={clearSelectedMethod}
        onClose={onClose}
      />
      <Message
        uploadProgress={uploadProgress}
        isUploadFile={isUploadFile}
        resetVideoState={resetVideoState}
        onCreateNewVideo={onCreateNewVideo}
        uploadState={uploadState}
        uploadedBlob={uploadedBlob}
        videosUploadCompleted={videosUploadCompleted}
      />
      <ComponentContainer>{getComponent()}</ComponentContainer>

      {[IMPORT_FROM_INSTAGRAM, IMPORT_FROM_TIKTOK].includes(selectedMethod) && (
        <ImportMultipleBadge
          onImportClick={() => onImportClick(selectedMethod)}
          isLoading={isLoading}
        />
      )}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  background: ${({ theme }) => theme.colors.white};
  overflow: hidden;

  ${rightSectionSize};
  ${({ fixedHeight = true }) => (fixedHeight ? undefined : 'min-height: auto;')};
`;

const ComponentContainer = styled.div`
  display: grid;
  flex-grow: 1;
  overflow: hidden;
`;

const StyledVideos = styled(Videos)`
  background-color: ${({ theme }) => theme.colors.gray5};
`;

export default RightSection;
