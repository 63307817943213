import { LightTextSmall } from 'shared/react/components/complex/Text';
import React from 'react';
import styled from 'styled-components';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import { SHOW_BUTTONS_INDEX_PROPERTY_KEY } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import EditorToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorToggleItem';
import ABCIcon from 'app/src/images/ABCIcon';
import EditorMainContainer from '../shared/EditorMainContainer';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

function ProFeaturesEditor() {
  const {
    customizationSettings: { showButtonsIndex },
    setCustomizationSettingsProperty,
    playerBranchingSettings,
  } = useLookAndFeelContext();

  const onToggle = value => {
    setCustomizationSettingsProperty(SHOW_BUTTONS_INDEX_PROPERTY_KEY, value);
  };

  const showButtonsIndexValue = showButtonsIndex ??  playerBranchingSettings.icons.shouldDisplayIcon ?? true;

  return (
    <LayoutRoot>
      <EditorMainContainer>
        <EditorToggleItem
          isPro={true}
          text={
            <Text>
              <ABCIcon />
              letters in front of answers
            </Text>
          }
          checked={showButtonsIndexValue}
          onChange={onToggle}
        />
      </EditorMainContainer>
    </LayoutRoot>
  );
}

const LayoutRoot = styled(VerticalFlex)``;

const Text = styled(LightTextSmall)`
  display: flex;
  align-items: center;
  gap: 4px;
`;
export default ProFeaturesEditor;
