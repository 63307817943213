import React, { useState } from 'react';
import styled from 'styled-components';
import PricingTooltip, { PricingTooltipContainer } from 'app/src/complex_components/PricingTooltip';
import UploadFilesEmptyState from 'app/src/complex_components/upload_files/UploadFilesEmptyState';
import UploadFilesVideoPreview from 'app/src/complex_components/upload_files/UploadFilesVideoPreview';
import InfoIcon from 'shared/react/images/InfoIcon';
import { TextSmall } from 'shared/react/components/complex/Text';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { useBilling } from 'app/src/hooks/useBillings';
import { useIsMobile } from 'shared/react/hooks/useIsMobile';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const UploadFile = ({
  uploadedBlob,
  onCreateNewVideo,
  onSelectFilesClick,
  onDropFiles,
  numberOfAllowedParts,
  numberOfParts,
}) => {
  const [videoReady, setVideoReady] = useState(false);
  const isMobile = useIsMobile();
  const { currentPackage = {} } = useBilling();

  const [
    {
      modalProps: { isFeed },
    },
  ] = useModal();

  const getRemainingPartsWarningText = () => {
    const partsUsage = `${numberOfParts}/${numberOfAllowedParts}`;
    const partsTerminology = isFeed ? 'videos' : 'parts';
    const remainingParts = Math.max(numberOfAllowedParts - numberOfParts, 0);
    return `You have used ${partsUsage} ${partsTerminology} for this Tolstoy, when selecting multiple videos, only
    ${remainingParts} videos will be uploaded.`;
  };

  return (
    <LayoutRoot>
      <UploadFilesEmptyState
        videoReady={videoReady}
        onDropFiles={onDropFiles}
        onSelectFilesClick={onSelectFilesClick}
        isCreationFlow={true}
      >
        <RemainingPartsMessage>
          <RemainingPartsText>{getRemainingPartsWarningText()}</RemainingPartsText>
          {!isMobile && (
            <PricingTooltip
              header="WANT MORE PARTS?"
              text={`In the ${currentPackage.name} version you are limited to ${numberOfAllowedParts} parts. If you need more, click below to unlock this feature.`}
              placement={'bottom'}
            >
              <InfoIcon />
            </PricingTooltip>
          )}
        </RemainingPartsMessage>
      </UploadFilesEmptyState>
      <UploadFilesVideoPreview
        videoReady={videoReady}
        setVideoReady={setVideoReady}
        uploadedBlob={uploadedBlob}
        onCreateNewVideo={onCreateNewVideo}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  width: 100%;
  height: 100%;
  overflow-y: auto;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 100%;
    padding: 16px 12px;
  }
`;

const RemainingPartsText = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.gray2};

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    text-align: center;
  }
`;

const RemainingPartsMessage = styled.div`
  position: absolute;
  bottom: 64px;
  left: 20%;
  right: 20%;
  line-height: 16px;
  text-align: center;

  ${RemainingPartsText}, ${PricingTooltipContainer} {
    display: inline;
    vertical-align: middle;
  }

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    bottom: 42px;
    left: 10%;
    right: 10%;
  }
`;

export default UploadFile;
