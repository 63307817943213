import React from 'react';

const ThreeVerticalDotsIcon = ({ fill = '#7A5B00' }) => (
  <svg width={4} height={11} viewBox="0 0 4 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.813 10.563c-.493 0-.915-.235-1.149-.657a1.313 1.313 0 1 1 2.273 0 1.262 1.262 0 0 1-1.124.656Zm0-3.75c-.493 0-.915-.235-1.149-.657a1.313 1.313 0 1 1 2.273 0 1.262 1.262 0 0 1-1.124.657ZM.5 1.75c0-.469.234-.89.656-1.125a1.313 1.313 0 1 1 0 2.273C.734 2.664.5 2.242.5 1.75Z"
      fill={fill}
    />
  </svg>
);
export default ThreeVerticalDotsIcon;
