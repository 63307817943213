import React from 'react';
import { TextSmall } from 'shared/react/components/complex/Text';
import EditorTextInputItem from '../shared/EditorTextInputItem';

const FeedCtaEditor = ({ title, buttonText, buttonTextPlaceholder, onButtonTextChange }) => {
  return (
    <>
      <TextSmall>{title}</TextSmall>
      <EditorTextInputItem
        placeholder={buttonTextPlaceholder}
        text="Text"
        value={buttonText}
        maxWidth="200px"
        onChange={onButtonTextChange}
      />
    </>
  );
};

export default FeedCtaEditor;
