import axios from 'axios';
import { REACT_APP_API_BASE_URL } from 'player/src/config/player.config';
import Utils from '../../utils';

export const getUploadUrl = async params => {
  const response = await axios.get(
    `${REACT_APP_API_BASE_URL}/upload/actions/upload/player/get-signed-url`,
    {
      params: { ...params, originalSize: `${Utils.sizeInBytesToMb(params.originalSize)}MB` },
    }
  );
  return response.data;
};

export const uploadToS3 = async (url, file, progressCallback) => {
  return new Promise(function (resolve, reject) {
    const xhr = new XMLHttpRequest();

    xhr.onreadystatechange = () => {
      if (xhr.readyState !== 4) {
        return;
      }
      if (xhr.status === 200) {
        resolve(xhr);
      } else {
        reject(xhr);
      }
    };

    if (progressCallback) {
      xhr.upload.onprogress = event => {
        if (!event.lengthComputable) {
          return;
        }

        const percentComplete = parseInt((event.loaded / file.size) * 100);
        progressCallback(percentComplete);
      };
    }

    xhr.open('PUT', url);
    xhr.send(file);
  });
};
