import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Card from 'app/src/basic_components/Card';
import { useVideos } from 'app/src/context/VideosStore';
import PreviewMobileNotch from 'app/src/pages/modals/publish/previews/static_preview_items/PreviewMobileNotch';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import PreviewContentGroup from 'app/src/pages/modals/publish/previews/static_preview_items/PreviewContentGroup';
import { GrayLine } from 'app/src/pages/modals/publish/previews/static_preview_items/commonPreviewItems';
import { useProjects } from 'app/src/context/ProjectsStore';

const EmbedPreview = ({ project, isVertical }) => {
  const [videoUrl, setVideoUrl] = useState('');
  const [, { getFirstStep }] = useProjects();
  const firstStep = project ? getFirstStep(project) : null;
  const [{ video }, { getVideoUrl, handleVideoLoadError }] = useVideos(firstStep?.videoId);

  const getInfo = () => {
    if (!project) {
      return;
    }

    const firstStep = getFirstStep(project);
    if (!firstStep) {
      return;
    }

    setVideoUrl(getVideoUrl(video));
  };

  useEffect(() => {
    getInfo();
  }, [project, video]);

  return (
    <LayoutRoot>
      <CardContainer disabled>
        <TopContainer>
          <PreviewMobileNotch />
          <VideoContainer isVertical={isVertical}>
            <VideoWrapper>
              <Video
                src={videoUrl}
                onError={() => handleVideoLoadError(video, videoUrl, setVideoUrl)}
                autoPlay
                loop
                muted
                playsInline
                allowFullScreen={false}
                disablePictureInPicture={true}
                controls={false}
              />
            </VideoWrapper>
          </VideoContainer>
        </TopContainer>
        <PreviewContentGroup />
        <Box />
      </CardContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  display: grid;
  height: 100%;
  min-height: 230px;
  width: 160px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 100%;
  }
`;

const CardContainer = styled(Card)`
  grid-row: 1;
  grid-column: 1;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
`;

const TopContainer = styled(VerticalFlex)`
  gap: 10px;
`;

const VideoWrapper = styled.div`
  height: 100%;
  border-radius: 6px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.3) 100%);
`;

const VideoContainer = styled.div`
  height: 89px;
  width: ${({ isVertical }) => (isVertical ? '' : '100%')};
`;

const Video = styled.video`
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 6px;
`;

const Box = styled(GrayLine)`
  width: 100%;
  height: 94px;
`;

export default EmbedPreview;
