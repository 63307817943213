import { useEffect, useRef, useState } from 'react';
import {
  TOLSTOY_ESCAPE_KEY_PRESSED_MESSAGE,
  TOLSTOY_MODAL_CLOSE_MESSAGE,
  TOLSTOY_PLAYER_MUTED_MESSAGE,
  TOLSTOY_PLAYER_PLAY_BACK_RATE_MESSAGE,
  TOLSTOY_PLAYER_PLAY_PAUSE_MESSAGE,
  TOLSTOY_PLAYER_SEEK_MESSAGE,
  TOLSTOY_WATCHED_PRODUCT_IDS_MESSAGE,
} from 'shared/react/constants/messages.constants';

import SharedUtils from 'shared/react/utils/utils';
import useTracker from 'shared/react/components/complex/context/hooks/useTracker';
import useKeyPress from 'shared/react/hooks/useKeyPress';
import { ASSET_TYPE } from 'shared/react/services/assets';
import useFeedProducts from 'shared/react/components/complex/context/hooks/useFeedProducts';
import { VIDEO_LOADED_TRACK_EVENT } from 'player/src/constants/tolstoy.constants';
import useFeedState from 'shared/react/components/complex/context/hooks/useFeedState';
import {
  RECHARGE_ADD_TO_CART_RESPONSE_MESSAGE,
  RECHARGE_WIDGET_DATA_MESSAGE,
} from 'shared/react/constants/rechargeMessaging.constants';
import useRecharge from 'shared/react/components/complex/context/hooks/useRecharge';

const ESCAPE_KEY = 'Escape';

const usePlayerCommands = ({
  videoRef = {},
  handleMuteClick,
  handlePlayPause,
  handleVideoSpeed,
  location = '',
  publishId,
  currentStep,
  started,
}) => {
  const [, { track }] = useTracker();
  const [products, { getProductsData }] = useFeedProducts();
  const [, { handleGetRechargeWidgetData, handleRechargePostToCartResponse }] = useRecharge();
  const [{ isProductsInitialized }] = useFeedState();
  const [seenStepKeys, setSeenStepKeys] = useState([]);
  const stepRef = useRef();

  const getProductIds = product => product.id;
  const getProductNames = product => product.title;

  const handleSeekVideo = ({ seconds }) => {
    videoRef.currentTime = seconds;
    const locationAnalytics = location ? SharedUtils.getAnalyticsLocationObject(location) : {};
    track('videoSeeked', { ...locationAnalytics, videoTime: seconds });
  };

  const handleEscapeKeyPressed = () => {
    SharedUtils.postMessageToWindow({ name: TOLSTOY_ESCAPE_KEY_PRESSED_MESSAGE, publishId });
  };

  const sendProductsEvent = products => {
    const productIds = products.map(getProductIds);

    SharedUtils.postMessageToWindow({
      name: TOLSTOY_WATCHED_PRODUCT_IDS_MESSAGE,
      publishId,
      productIds,
    });
  };

  const getProducts = () => {
    const { isMultipleProducts, product } = getProductsData(currentStep);

    if ((!product && !isMultipleProducts) || !products || !started) {
      return [];
    }

    if (product) {
      return [product];
    }

    const newProducts = currentStep.products.flatMap(({ id }) => {
      return products[id] || [];
    });

    return newProducts;
  };

  const onCanPlay = () => {
    const products = getProducts();
    const productIds = products.map(getProductIds);
    const productNames = products.map(getProductNames)?.join?.(',');

    track(VIDEO_LOADED_TRACK_EVENT, {
      productIds: JSON.stringify(productIds),
      products: JSON.stringify(products),
      text: currentStep.videoName,
      productNames,
      videoId: currentStep.videoId,
      type: currentStep.type || ASSET_TYPE.VIDEO,
    });
  };

  const handleTolstoyModalClose = () => {
    track(TOLSTOY_MODAL_CLOSE_MESSAGE);
  };

  useKeyPress(ESCAPE_KEY, handleEscapeKeyPressed);

  useEffect(() => {
    const handleMessages = ({ data }) => {
      const name = data.eventName || data;
      switch (name) {
        case TOLSTOY_MODAL_CLOSE_MESSAGE:
          return handleTolstoyModalClose();
        default:
          return null;
      }
    };

    window.addEventListener('message', handleMessages);

    return () => {
      window.removeEventListener('message', handleMessages);
    };
  }, []);

  useEffect(() => {
    const handleMessages = ({ data }) => {
      const name = data.eventName || data;
      switch (name) {
        case TOLSTOY_MODAL_CLOSE_MESSAGE:
          return handleTolstoyModalClose();
        case TOLSTOY_PLAYER_MUTED_MESSAGE:
          return handleMuteClick();
        case TOLSTOY_PLAYER_PLAY_PAUSE_MESSAGE:
          return handlePlayPause();
        case TOLSTOY_PLAYER_SEEK_MESSAGE:
          return handleSeekVideo(data);
        case TOLSTOY_PLAYER_PLAY_BACK_RATE_MESSAGE:
          return handleVideoSpeed?.(data.seconds);
        case RECHARGE_WIDGET_DATA_MESSAGE:
          return handleGetRechargeWidgetData(data);
        case RECHARGE_ADD_TO_CART_RESPONSE_MESSAGE:
          return handleRechargePostToCartResponse(data);
        default:
          return null;
      }
    };

    window.addEventListener('message', handleMessages);

    return () => {
      window.removeEventListener('message', handleMessages);
    };
  }, [
    videoRef,
    handleTolstoyModalClose,
    handleVideoSpeed,
    handlePlayPause,
    handleMuteClick,
    handleSeekVideo,
    handleRechargePostToCartResponse,
    handleGetRechargeWidgetData,
  ]);

  useEffect(() => {
    const products = getProducts();

    if (!isProductsInitialized || !products.length || seenStepKeys.includes(currentStep.key)) {
      return;
    }

    setSeenStepKeys([...seenStepKeys, currentStep.key]);

    sendProductsEvent(products);
  }, [currentStep, products, started, isProductsInitialized]);

  useEffect(() => {
    if (
      !videoRef ||
      stepRef.current?.key === currentStep?.key ||
      !started ||
      !isProductsInitialized
    ) {
      return;
    }

    stepRef.current = currentStep;

    if (videoRef?.currentTime || videoRef?.isImage) {
      onCanPlay();
      return;
    }

    videoRef.addEventListener('canplaythrough', onCanPlay, { once: true });
  }, [videoRef, products, currentStep, started, isProductsInitialized]);
};

export default usePlayerCommands;
