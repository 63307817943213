import React from 'react';
import styled from 'styled-components';
import { BASIC_SELECT_FIRST_OPTION_DATA_TEST_ID } from 'shared/react/constants/dataTestIds.constants';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';
import { Gap8HorizontalFlex } from '../../complex/flex_layouts/HorizontalFlex';

const DefaultItems = ({
  items,
  onClick,
  customPadding,
  fontColor,
  fontSize,
  whiteSpace,
  itemOverflow,
  setNextRef,
  onKeyDown,
}) => {
  return items.map((menuItem, i) =>
    menuItem.hidden ? null : (
      <ItemContainer
        key={menuItem?.id || menuItem}
        customPadding={customPadding}
        data-test-id={i === 0 ? BASIC_SELECT_FIRST_OPTION_DATA_TEST_ID : null}
        onClick={() => !menuItem.disabled && onClick(menuItem)}
        role="menuitem"
        aria-label={`select menu item: ${menuItem?.name || menuItem}`}
        tabIndex="0"
        onKeyDown={onKeyDown}
        ref={setNextRef}
        disabled={menuItem.disabled}
      >
        <EllipsisTextOverflowWrapper>
          <CustomMenuItem
            color={fontColor}
            size={fontSize}
            whiteSpace={whiteSpace}
            itemOverflow={itemOverflow}
            title={menuItem.disabled ? menuItem.tooltip : ''}
          >
            {menuItem.icon}
            {menuItem?.name || menuItem}
          </CustomMenuItem>
        </EllipsisTextOverflowWrapper>
      </ItemContainer>
    )
  );
};

const CustomMenuItem = styled(Gap8HorizontalFlex)`
  && {
    width: 100%;
    padding: 8px 8px 8px 0;
    color: ${({ color }) => (color ? color : '')};
  }
  & div {
    font-size: ${({ size }) => (size ? size : '')};
    white-space: ${({ whiteSpace }) => (whiteSpace ? whiteSpace : '')};
    overflow: ${({ itemOverflow }) => (itemOverflow ? itemOverflow : '')};
  }
`;

const ItemContainer = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: 0.3s;
  padding: ${({ customPadding }) => (customPadding ? customPadding : '0px 12px')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  &:hover {
    background: ${({ theme, disabled }) => !disabled && theme.colors.neutralLightest};
  }
`;

export default DefaultItems;
