import React from 'react';
import { track } from 'app/src/helpers/Tracker';
import Utils from 'app/src/utils';
import { useApps } from 'app/src/context/AppsStore';
import PublishVideoPageShopifyContent from './PublishVideoPageShopifyContent';
import PublishVideoPageNonShopifyContent from './PublishVideoPageNonShopifyContent';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';
import { useHistory } from 'react-router-dom';
import BlockFreeUserPublishMessage from 'app/src/pages/modals/publish/BlockFreeUserPublishMessage';
import { useBilling } from 'app/src/hooks/useBillings';
import { projectTabs } from 'app/src/helpers/Routes';
import useNavigation from 'app/src/hooks/useNavigation';

const PublishVideoPageContent = ({ project, getCode, onGetHelpClick, headerId, setHeaderId }) => {
  const [{ shopify }] = useApps();
  const history = useHistory();
  const [, { setSnackbar }] = useSnackBar();
  const { hidePublishContent } = useBilling();
  const { navigateToProjectTab } = useNavigation();

  const handleCopyClick = () => {
    Utils.copyToClipboard(getCode(headerId));
    setSnackbar('The code copied to your clipboard!', 'success');
    track('Copy Video Page Code Click');
  };

  const handleCustomizeAppearance = () => {
    navigateToProjectTab(project, projectTabs.design);
  };

  if (hidePublishContent) {
    return <BlockFreeUserPublishMessage type="Video page" />;
  }

  if (shopify) {
    return (
      <PublishVideoPageShopifyContent
        onClickCustomizeAppearance={handleCustomizeAppearance}
        onGetHelpClick={onGetHelpClick}
        onClickCopyCode={handleCopyClick}
        getCode={getCode}
        setHeaderId={setHeaderId}
        publishId={project.publishId}
      />
    );
  }

  return (
    <PublishVideoPageNonShopifyContent
      getCode={getCode}
      onClickCopyCode={handleCopyClick}
      setHeaderId={setHeaderId}
      onClickCustomizeAppearance={handleCustomizeAppearance}
    />
  );
};

export default PublishVideoPageContent;
