import { createActionsHook, createContainer, createHook, createStore } from 'react-sweet-state';

const initialState = {
  snackbarText: '',
  snackbarOpen: false,
  snackbarSeverity: 'success',
  hideDuration: 3000,
};

const actions = {
  setSnackbar:
    (text, severity = 'success', hideDuration) =>
    ({ setState }) => {
      setState({
        snackbarText: text,
        snackbarOpen: !!text,
        snackbarSeverity: severity,
        hideDuration,
      });
    },
  setErrorSnackbar:
    (errorText, hideDuration) =>
    ({ setState }) => {
      setState({
        snackbarText: errorText,
        snackbarOpen: !!errorText,
        snackbarSeverity: 'error',
        hideDuration: hideDuration,
      });
    },
  setInfoSnackbar:
    infoText =>
    ({ setState }) => {
      setState({ snackbarText: infoText, snackbarOpen: !!infoText, snackbarSeverity: 'info' });
    },
};

const SnackBarStore = createStore({ initialState, actions });

export const useSnackBar = createHook(SnackBarStore);

export const useSnackBarActions = createActionsHook(SnackBarStore);

export const SnackBarContainer = createContainer(SnackBarStore);
