import React, { useState } from 'react';
import styled from 'styled-components';
import Stepper from 'app/src/complex_components/Stepper';
import Button from 'shared/react/components/complex/Button';
import OutreachScissorsIcon from 'app/src/images/OutreachScissorsIcon';
import Types from 'shared/react/theme/Types';
import OutreachTemplateIcon from 'app/src/images/OutreachTemplateIcon';
import { TextBody } from 'shared/react/components/complex/Text';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import { useVideos } from 'app/src/context/VideosStore';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import SnackBar from 'app/src/basic_components/SnackBar';
import Utils from '../../../utils';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import { track } from 'app/src/helpers/Tracker';
import { useUser } from 'app/src/context/userStore/UserStore';

function EmailShareViaOutreach({ token, appId, createOutreachResource, project, firstStep }) {
  const [videos, { getVideoGif }] = useVideos();
  const [{ user }] = useUser();
  const firstProjectStep = project?.steps?.items[0];
  const video = videos.data.find(v => v.id === firstProjectStep?.videoId);
  const [successBar, setSuccessBar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [snackBarText, setSnackBarText] = useState('');

  const handleCreateResource = async type => {
    setError('');
    setLoading(true);
    track('Outreach resource created', { type });
    const gifUrl = getVideoGif(video);
    const { appKey, name, publishId } = project;
    try {
      await createOutreachResource({
        type,
        gifUrl,
        appKey,
        name,
        publishId,
        token,
        appId,
        email: user.email,
      });
      setSnackBarText(`Successfully created Outreach ${type}!`);
    } catch (err) {
      setError(`There was an error creating your ${type}, please try again!`);
      Utils.logError('Error creating outreach resource', err);
    }
    setSuccessBar(true);
    setLoading(false);
  };

  const isDisabled = !(!!video && !!project);
  return (
    <LayoutRoot>
      <Stepper
        stepComponents={[
          firstStep,
          <FirstStep key={2}>
            <StepperText>Create template/snippet</StepperText>
            <ButtonsContainer>
              <Button
                showLeftIcon
                leftIcon={<OutreachTemplateIcon />}
                disabled={isDisabled || loading}
                onClick={() => handleCreateResource('template')}
                loading={loading}
              >
                Create template
              </Button>
              <Button
                type={Types.Secondary}
                showLeftIcon
                leftIcon={<OutreachScissorsIcon />}
                disabled={isDisabled || loading}
                onClick={() => handleCreateResource('snippet')}
                loading={loading}
              >
                Create snippet
              </Button>
            </ButtonsContainer>
          </FirstStep>,
          <SecondStep key={3}>
            <StepperText>Your template/snippet is now in Outreach!</StepperText>
          </SecondStep>,
        ]}
      />
      <SnackBar
        text={error || snackBarText}
        open={successBar}
        setOpen={setSuccessBar}
        severity={error ? 'error' : 'success'}
      />
    </LayoutRoot>
  );
}

export default EmailShareViaOutreach;

const LayoutRoot = styled(VerticalFlex)``;

const SecondStep = styled(HorizontalFlexWrap)`
  gap: 12px;
`;

const FirstStep = styled(Gap16VerticalFlex)``;

const StepperText = styled(TextBody)`
  font-weight: 800;
  color: ${({ theme }) => theme.colors.black};
`;

const ButtonsContainer = styled(Gap8HorizontalFlexWrap)`
  margin-top: -8px;
`;
