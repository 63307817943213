import React, { useCallback, useEffect, useState } from 'react';
import { TextBody, TextH3Bold, TextSmall } from 'shared/react/components/basic/text/TextV2';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { SHOPIFY } from 'src/constants/intergrations.constants';
import { useApps } from 'src/context/AppsStore';
import useUpdateDirtyForm from 'src/hooks/useUpdateDirtyForm';
import StickyTopBar from 'src/pages/dashboard/components/top-bar/StickyTopBar';
import styled from 'styled-components';
import SyncTagsDropdown from './SyncTagsDropdown';
import SyncTagsStoresList from './SyncTagsStoresList';
import SyncTagsSwitcher from './SyncTagsSwitcher';
import { SyncTagsMode } from './constants';
import { SyndicateBy } from 'app/src/types/entities';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import { FEATURE_SHOPIFY_TAGS } from 'shared/react/constants/features.constants';
import EditorTextInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorTextInputItem';
import HorizontalFlex, {
  Gap8HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import TrashIcon from 'app/src/images/sidebar-v2/TrashIcon';
import { WhiteButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { CheckboxV2 } from 'src/complex_components/Checkbox';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';

type TagsSettings = {
  tagsPrefix?: string[];
  shouldAutoCreateTagByPrefix?: boolean;
};

const SyncTagsPage = () => {
  const [
    { apps: storeApps, initialized, selectedAppUrl },
    { updateSyncTagsSettings, fetch, updateApp },
  ] = useApps();
  const { getFeatureEnabled } = useFeatureActions();
  const shopifyTagsEnabled = getFeatureEnabled(FEATURE_SHOPIFY_TAGS);

  const apps = storeApps?.filter(app => app?.active && app?.app === SHOPIFY);

  const getPrimaryId = useCallback(
    () => apps.find(app => app?.syncTagsMode === SyncTagsMode.primary)?.id,
    [apps]
  );

  const getReplicaIds = useCallback(
    () => apps.filter(app => app?.syncTagsMode === SyncTagsMode.replica)?.map(app => app.id),
    [apps]
  );

  const [isAppsInitialized, setIsAppsInitialized] = useState(initialized);
  const [isUnsaved, setIsUnsaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tagsPrefix, setTagsPrefix] = useState(['']);
  const [primaryId, setPrimaryId] = useState(getPrimaryId());
  const primaryApp = apps?.find(app => app.id === primaryId);
  const selectedApp = apps?.find(({ appUrl }) => selectedAppUrl === appUrl);
  const selectedAppSettings = JSON.parse(selectedApp?.settings || '{}');
  const [replicaIds, setReplicaIds] = useState(getReplicaIds());
  const [syndicateBy, setSyndicateBy] = useState(primaryApp?.syndicateBy || SyndicateBy.sku);
  const [shouldAutoCreateTagByPrefix, setShouldAutoCreateTagByPrefix] = useState(
    selectedAppSettings?.shouldAutoCreateTagByPrefix
  );

  const onDiscardClick = () => {
    setIsUnsaved(false);
    setPrimaryId(getPrimaryId());
    setReplicaIds(getReplicaIds());
    setSyndicateBy(primaryApp?.syndicateBy || SyndicateBy.sku);
  };

  const onSaveClick = useCallback(async () => {
    setIsLoading(true);
    setIsUnsaved(false);

    const tagsSettings = {} as TagsSettings;
    const promises = [];

    if (checkIfPrefixChanged(tagsPrefix)) {
      tagsSettings.tagsPrefix = tagsPrefix.filter(Boolean);
    }

    if (shouldAutoCreateTagByPrefix !== selectedAppSettings?.shouldAutoCreateTagByPrefix) {
      tagsSettings.shouldAutoCreateTagByPrefix = shouldAutoCreateTagByPrefix;
    }

    if (Object.keys(tagsSettings).length) {
      promises.push(
        updateApp(selectedApp.id, {
          settings: JSON.stringify({ ...selectedAppSettings, ...tagsSettings }),
        })
      );
    }

    if (getIsTagsChanged(primaryId, replicaIds, syndicateBy)) {
      promises.push(updateSyncTagsSettings(primaryId, replicaIds, syndicateBy));
    }

    await Promise.all(promises);

    setIsLoading(false);

    fetch();
  }, [primaryId, replicaIds, syndicateBy, tagsPrefix, shouldAutoCreateTagByPrefix]);

  const getIsTagsChanged = (appId, replicas, syndicateBy) => {
    const originalAppId = getPrimaryId();
    const originalSyndicateBy = primaryApp?.syndicateBy || SyndicateBy.sku;
    const originalReplicas = getReplicaIds();

    const isReplicasSame =
      replicas.every(replicaId => originalReplicas.includes(replicaId)) &&
      replicas.length === originalReplicas.length;

    return originalAppId !== appId || originalSyndicateBy !== syndicateBy || !isReplicasSame;
  };

  const checkIfPrefixChanged = newPrefixes => {
    const sameLength = newPrefixes.length === selectedAppSettings?.tagsPrefix?.length;
    const includesAll = newPrefixes.every(prefix =>
      selectedAppSettings?.tagsPrefix?.includes(prefix)
    );

    return !sameLength || !includesAll;
  };

  const checkIfUnsaved = ({ appId, syndicateBy, replicas, newPrefixes }) => {
    if (newPrefixes) {
      setIsUnsaved(checkIfPrefixChanged(newPrefixes));
      return;
    }

    setIsUnsaved(getIsTagsChanged(appId, replicas, syndicateBy));
  };

  const onAppClick = ({ value: appId }) => {
    checkIfUnsaved({ appId, syndicateBy, replicas: replicaIds });
    setPrimaryId(appId);
    setReplicaIds(replicaIds.filter(replicaId => replicaId !== appId));
  };

  const getAppItems = () =>
    apps.map(app => ({
      text: app.appUrl,
      id: app.appUrl,
      name: app.appUrl,
      icon: null,
      value: app.id,
    }));

  const getSyndicateByItems = () => {
    return [
      {
        name: 'SKU (Stock-keeping unit)',
        id: 0,
        value: SyndicateBy.sku,
      },
      {
        name: 'Barcode (ISBN, UPC, GTIN, etc.)',
        id: 1,
        value: SyndicateBy.barcode,
      },
    ];
  };

  const onSyndicateEdit = item => {
    checkIfUnsaved({ appId: primaryId, syndicateBy: item.value, replicas: replicaIds });
    setSyndicateBy(item.value);
  };

  const onToggleSyncChange = async () => {
    if (primaryId) {
      checkIfUnsaved({ appId: null, syndicateBy, replicas: replicaIds });
      setPrimaryId(null);
      setReplicaIds([]);
      return;
    }

    const newPrimaryId = getPrimaryId() || apps[0]?.id;
    checkIfUnsaved({ appId: newPrimaryId, syndicateBy, replicas: replicaIds });
    setPrimaryId(newPrimaryId);
  };

  const onReplicaIdsChange = id => {
    if (replicaIds.includes(id)) {
      checkIfUnsaved({
        appId: primaryId,
        syndicateBy,
        replicas: replicaIds.filter(replicaId => replicaId !== id),
      });
      setReplicaIds(replicaIds.filter(replicaId => replicaId !== id));
      return;
    }

    checkIfUnsaved({ appId: primaryId, syndicateBy, replicas: [...replicaIds, id] });
    setReplicaIds([...replicaIds, id]);
  };

  const onTagsPrefixChange = (value, i) => {
    const newPrefixes = [...tagsPrefix];
    newPrefixes[i] = value;
    checkIfUnsaved({ newPrefixes: newPrefixes });
    setTagsPrefix(newPrefixes);
  };

  const onDeletePrefix = i => {
    const newPrefixes = [...tagsPrefix];
    newPrefixes.splice(i, 1);
    checkIfUnsaved({ newPrefixes: newPrefixes });
    setTagsPrefix(newPrefixes);
  };
  useUpdateDirtyForm(isUnsaved, {
    isLoading,
    onSaveClick,
    onDiscardClick,
  });

  const handleAutoCreateTagByPrefixChange = async () => {
    setIsUnsaved(true);
    setShouldAutoCreateTagByPrefix((prevValue?: boolean) => !prevValue);
  };

  useEffect(() => {
    if (!isAppsInitialized && initialized) {
      setIsAppsInitialized(true);
      onDiscardClick();
    }
  }, [apps, initialized, isAppsInitialized]);

  useEffect(() => {
    setTagsPrefix(selectedAppSettings?.tagsPrefix || ['']);
    setShouldAutoCreateTagByPrefix(selectedAppSettings?.shouldAutoCreateTagByPrefix);
  }, [selectedApp]);

  const syndicateItems = getSyndicateByItems();
  const syndicateValue = syndicateItems.find(item => item.value === syndicateBy);
  return (
    <LayoutRoot>
      <StickyTopBar leftContent="Tags & labels" dirtyTitle="Tags & labels" />
      <Content>
        <TitleContainer>
          <TextH3Bold>Sync tags</TextH3Bold>
          <Subtitle>Automatically sync product tags across stores.</Subtitle>
        </TitleContainer>

        <SyncTagsSwitcher isChecked={!!primaryId} onChange={onToggleSyncChange} />

        <SyncTagsDropdown
          label="Master store"
          items={getAppItems()}
          selectedValue={apps.find(app => app.id === primaryId)?.appUrl}
          isDisabled={!primaryId}
          onChange={onAppClick}
        />

        <SyncTagsDropdown
          label="Syndicate by"
          items={syndicateItems}
          selectedValue={syndicateValue}
          isDisabled={!primaryId}
          onChange={onSyndicateEdit}
        />

        <SyncTagsStoresList
          apps={apps}
          primaryId={primaryId}
          onChange={onReplicaIdsChange}
          selectedIds={replicaIds}
          isDisabled={!primaryId}
        />
      </Content>

      {shopifyTagsEnabled && (
        <Content>
          <TitleContainer>
            <TextH3Bold>Tags</TextH3Bold>
            <Subtitle>Organize & Tag Easier: Leverage Prefix-Based Tagging</Subtitle>
          </TitleContainer>
          <Gap8VerticalFlex>
            <TagsContainer>
              {tagsPrefix.map((value, i) => {
                return (
                  <TagsPrefixContainer>
                    <TextInput
                      text="Tags prefix"
                      value={value}
                      placeholder="Enter tags prefix"
                      onChange={val => onTagsPrefixChange(val, i)}
                      maxLength={200}
                    />
                    {tagsPrefix.length > 1 && (
                      <DeleteContainer onClick={() => onDeletePrefix(i)}>
                        <TrashIcon />
                      </DeleteContainer>
                    )}
                  </TagsPrefixContainer>
                );
              })}
            </TagsContainer>
            <AddButton onClick={() => setTagsPrefix([...tagsPrefix, ''])}>+ Add prefix</AddButton>
          </Gap8VerticalFlex>
          <Gap8HorizontalFlex>
            <CheckboxV2
              checked={shouldAutoCreateTagByPrefix}
              onChange={handleAutoCreateTagByPrefixChange}
            />
            <TextSmall>
              Automatically create Tags when tagging products with corresponding Tags prefix
            </TextSmall>
          </Gap8HorizontalFlex>
        </Content>
      )}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  overflow-y: scroll;
`;

const Content = styled(Gap16VerticalFlex)`
  width: 420px;
  padding: 24px;
`;

const TitleContainer = styled(VerticalFlex)``;

const Subtitle = styled(TextBody)`
  color: ${props => props.theme.colors.neutralDarker};
`;

const TextInput = styled(EditorTextInputItem)`
  width: 100%;
  & input {
    width: 100%;
    background: ${({ theme }) => theme.colors.white};
  }
`;

const TagsContainer = styled(VerticalFlex)`
  max-height: 150px;
  overflow: auto;
`;

const TagsPrefixContainer = styled(Gap8HorizontalFlex)`
  align-items: center;
`;

const DeleteContainer = styled(HorizontalFlex)`
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  opacity: 0;

  ${TagsPrefixContainer}:hover & {
    opacity: 1;
  }
`;

const AddButton = styled(WhiteButton)`
  width: fit-content;
`;

export default SyncTagsPage;
