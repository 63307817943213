import React from 'react';

const CreateNewQuizIcon = props => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="#FD7830"
        d="M0.25 1.84375V9.14844C0.25 9.65625 0.523438 10.3594 0.875 10.7109L7.78906 17.625C8.64844 18.4844 10.0156 18.4844 10.875 17.625L17.0859 11.4141C17.9453 10.5547 17.9453 9.1875 17.0859 8.32812L10.1719 1.41406C9.82031 1.0625 9.11719 0.75 8.60938 0.75H1.34375C0.71875 0.75 0.25 1.25781 0.25 1.84375ZM3.375 5.125C3.375 4.46094 3.92188 3.875 4.625 3.875C5.28906 3.875 5.875 4.46094 5.875 5.125C5.875 5.82812 5.28906 6.375 4.625 6.375C3.92188 6.375 3.375 5.82812 3.375 5.125Z"
      />
    </svg>
  );
};

export default CreateNewQuizIcon;
