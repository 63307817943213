export enum SearchFilterName {
  type = 'type',
  text = 'text',
  source = 'source',
  tagged = 'tagged',
  products = 'products',
  uploadType = 'uploadType',
  labels = 'labels',
  tags = 'tags',
  owner = 'owner',
  suggestedProducts = 'suggestedProducts',
  appExternalId = 'appExternalId',
  date = 'date',
}

export enum TypeFilterValues {
  video = 'video',
  image = 'image',
  gallery = 'gallery',
}

export enum TaggedInFilterValues {
  tag = 'tag',
  productId = 'productId',
  vodLabelId = 'vodLabelId',
  suggestedProducts = 'suggestedProducts',
}

export enum SortDirection {
  asc = 'asc',
  desc = 'desc',
}

export type SearchVodAssetsPageResponse = {
  total: number;
  items: any[];
  from: number;
  itemsCount: number;
};

export type SearchVodAssetsRequestFilter = {
  name: string;
  filterValue: any;
};

export type SearchVodAssetsRequest = {
  appKey: string;
  satisfies?: SearchVodAssetsRequestFilter[];
  notSatisfies?: SearchVodAssetsRequestFilter[];
  size: number;
  from: number;
  dateSort: SortDirection;
};
