import React from 'react';

const PreMeetingDiscoveryQuestions = props => (
  <svg
    width={44}
    height={44}
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M37.385 6.615h3.077A1.538 1.538 0 0 1 42 8.154v24.615a1.539 1.539 0 0 1-1.538 1.539H3.538A1.539 1.539 0 0 1 2 32.769V8.154a1.538 1.538 0 0 1 1.538-1.539h3.077"
      stroke="url(#a)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.925 34.308 15.848 42"
      stroke="url(#b)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.078 34.308 28.155 42"
      stroke="url(#c)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.77 42h18.46"
      stroke="url(#d)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.848 8.154A6.154 6.154 0 1 1 22 14.308v3.077"
      stroke="url(#e)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.552 25.175a.807.807 0 1 1 .897 1.342.807.807 0 0 1-.897-1.342Z"
      fill="#000"
      stroke="url(#f)"
      strokeWidth={3}
    />
    <defs>
      <linearGradient id="a" x1={2} y1={34.308} x2={42} y2={34.308} gradientUnits="userSpaceOnUse">
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
      <linearGradient id="b" x1={15.848} y1={42} x2={18.925} y2={42} gradientUnits="userSpaceOnUse">
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
      <linearGradient id="c" x1={25.078} y1={42} x2={28.155} y2={42} gradientUnits="userSpaceOnUse">
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
      <linearGradient id="d" x1={12.77} y1={43} x2={31.231} y2={43} gradientUnits="userSpaceOnUse">
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={15.848}
        y1={17.385}
        x2={28.155}
        y2={17.385}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
      <linearGradient
        id="f"
        x1={19.693}
        y1={28.154}
        x2={24.309}
        y2={28.154}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
    </defs>
  </svg>
);

export default PreMeetingDiscoveryQuestions;
