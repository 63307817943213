import React from 'react';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import styled from 'styled-components';
import { TextSmall } from 'shared/react/components/complex/Text';

const NumberOfEntities = ({ text, shouldShow }) => {
  if (!shouldShow) {
    return null;
  }

  return (
    <LayoutRoot>
      <Text>{text}</Text>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlexCentered)`
  padding: 50px 0;
`;

const Text = styled(TextSmall)`
  font-weight: 500;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.neutralDark};

  &::first-letter {
    text-transform: uppercase;
  }
`;

export default NumberOfEntities;
