import React from 'react';
import Text from '../basic/Text';
import styled from 'styled-components';

/** @deprecated - use shared/react/components/basic/text/TextV2 instead */
function BaseText({
  children,
  textSettings,
  fontSize,
  lineHeight,
  fontWeight,
  letterSpacing,
  textColor,
  hoverTextColor,
  activeTextColor,
  backgroundColor,
  ...props
}) {
  return (
    <Text
      fontSize={fontSize}
      textColor={textColor}
      backgroundColor={backgroundColor}
      hoverTextColor={hoverTextColor}
      activeTextColor={activeTextColor}
      lineHeight={lineHeight}
      fontWeight={fontWeight}
      letterSpacing={letterSpacing}
      {...props}
    >
      {children}
    </Text>
  );
}

/** @deprecated - use shared/react/components/basic/text/TextV2 instead */
function getBaseText(
  children,
  fontSize,
  lineHeight,
  fontWeight,
  letterSpacing,
  textColor,
  hoverTextColor,
  activeTextColor,
  backgroundColor,
  { ...props }
) {
  return (
    <BaseText
      fontSize={fontSize}
      lineHeight={lineHeight}
      fontWeight={fontWeight}
      letterSpacing={letterSpacing}
      textColor={textColor}
      backgroundColor={backgroundColor}
      hoverTextColor={hoverTextColor}
      activeTextColor={activeTextColor}
      {...props}
    >
      {children}
    </BaseText>
  );
}

/** @deprecated - use shared/react/components/basic/text/TextV2 instead */
const TextH1 = styled(Text)`
  font-size: ${props => props.fontSize || '72px'};
  line-height: ${props => props.lineHeight || '88px'};
  font-weight: ${props => props.fontWeight || 'normal'};
  letter-spacing: ${props => props.letterSpacing || '-0.015em'};
`;

/** @deprecated - use shared/react/components/basic/text/TextV2 instead */
const TextH2 = styled(Text)`
  font-size: ${props => props.fontSize || '56px'};
  line-height: ${props => props.lineHeight || '64px'};
  font-weight: ${props => props.fontWeight || 600};
  letter-spacing: ${props => props.letterSpacing || '-0.005em'};
`;

/** @deprecated - use shared/react/components/basic/text/TextV2 instead */
const TextH3 = styled(Text)`
  font-size: ${props => props.fontSize || '40px'};
  line-height: ${props => props.lineHeight || '44px'};
  font-weight: ${props => props.fontWeight || 600};
  letter-spacing: ${props => props.letterSpacing || 'unset'};
`;

/** @deprecated - use shared/react/components/basic/text/TextV2 instead */
const TextH4 = styled(Text)`
  font-size: ${props => props.fontSize || '32px'};
  line-height: ${props => props.lineHeight || '40px'};
  font-weight: ${props => props.fontWeight || 600};
  letter-spacing: ${props => props.letterSpacing || '0.0025em'};
`;

/** @deprecated - use shared/react/components/basic/text/TextV2 instead */
const TextH5 = styled(Text)`
  font-size: ${props => props.fontSize || '24px'};
  line-height: ${props => props.lineHeight || '30px'};
  font-weight: ${props => props.fontWeight || 'bold'};
  letter-spacing: ${props => props.letterSpacing || 'unset'};
`;

/** @deprecated - use shared/react/components/basic/text/TextV2 instead */
const TextH6 = styled(Text)`
  font-size: ${props => props.fontSize || '20px'};
  line-height: ${props => props.lineHeight || '28px'};
  font-weight: ${props => props.fontWeight || 'bold'};
  letter-spacing: ${props => props.letterSpacing || '0.0015em'};
`;

/** @deprecated - use shared/react/components/basic/text/TextV2 instead */
const TextSubtitle = styled(Text)`
  font-size: ${props => props.fontSize || '16px'};
  line-height: ${props => props.lineHeight || '22px'};
  font-weight: ${props => props.fontWeight || 'bold'};
  letter-spacing: ${props => props.letterSpacing || '0.0015em'};
`;

/** @deprecated - use shared/react/components/basic/text/TextV2 instead */
const TextBodyLeading = styled(Text)`
  font-size: ${props => props.fontSize || '18px'};
  line-height: ${props => props.lineHeight || '26px'};
  font-weight: ${props => props.fontWeight || '600'};
  letter-spacing: ${props => props.letterSpacing || '0.0015em'};
`;

/** @deprecated - use shared/react/components/basic/text/TextV2 instead */
const TextBodyLeadingBold = styled(TextBodyLeading)`
  font-weight: 700;
`;

/** @deprecated - use shared/react/components/basic/text/TextV2 instead */
const TextBody = styled(Text)`
  font-size: ${props => props.fontSize || '16px'};
  line-height: ${props => props.lineHeight || '24px'};
  font-weight: ${props => props.fontWeight || 'normal'};
  letter-spacing: ${props => props.letterSpacing || 'unset'};
`;

/** @deprecated - use shared/react/components/basic/text/TextV2 instead */
const BolderTextBody = styled(TextBody)`
  font-weight: 700;
  line-height: 16px;
`;

/** @deprecated - use shared/react/components/basic/text/TextV2 instead */
const TextOverline = styled(Text)`
  font-size: ${props => props.fontSize || '14px'};
  line-height: ${props => props.lineHeight || '20px'};
  font-weight: ${props => props.fontWeight || 800};
  letter-spacing: ${props => props.letterSpacing || '0.05em'};
`;

/** @deprecated - use shared/react/components/basic/text/TextV2 instead */
const TextSmall = styled(Text)`
  font-size: ${props => props.fontSize || '14px'};
  line-height: ${props => props.lineHeight || '18px'};
  font-weight: ${props => props.fontWeight || 600};
  letter-spacing: ${props => props.letterSpacing || 'unset'};
`;

/** @deprecated - use shared/react/components/basic/text/TextV2 instead */
const LightTextSmall = styled(TextSmall)`
  font-weight: 400;
  line-height: 14px;
`;

/** @deprecated - use shared/react/components/basic/text/TextV2 instead */
const BolderTextSmall = styled(TextSmall)`
  font-weight: 700;
  line-height: 16px;
`;

/** @deprecated - use shared/react/components/basic/text/TextV2 instead */
const TextTiny = styled(Text)`
  font-size: ${props => props.fontSize || '12px'};
  line-height: ${props => props.lineHeight || '14px'};
  font-weight: ${props => props.fontWeight || 600};
  letter-spacing: ${props => props.letterSpacing || 'unset'};
`;
/** @deprecated - use shared/react/components/basic/text/TextV2 instead */
const LightTextTiny = styled(TextTiny)`
  font-weight: ${props => props.fontWeight || 500};
`;

export {
  BaseText,
  getBaseText,
  TextH1,
  TextH2,
  TextH3,
  TextH4,
  TextH5,
  TextH6,
  TextSubtitle,
  TextOverline,
  TextBodyLeading,
  TextBody,
  BolderTextBody,
  TextSmall,
  BolderTextSmall,
  LightTextSmall,
  TextTiny,
  LightTextTiny,
  TextBodyLeadingBold,
};
