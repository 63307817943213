import React from 'react';
import Pencil from 'app/src/images/grey_pencil.svg';
import DeleteIcon from 'app/src/images/project_card_icons/delete_icon.svg';
import MenuItem from 'app/src/complex_components/MenuItem';
import { Menu } from '@material-ui/core';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { MODAL_STATE } from 'app/src/pages/modals/folder_modal/folders.constants';
import {
  DELETE_FOLDER_MODAL_KEY,
  RENAME_FOLDER_MODAL_KEY,
} from 'app/src/constants/modals.constants';
import { track } from 'app/src/helpers/Tracker';

const FolderMenuItems = ({ folder, isMenuOpen, setMenuOpen }) => {
  const [, { setCurrentModal, setCurrentFolderId, setCurrentModalType }] = useModal();
  const open = !!isMenuOpen;

  const handleOpenModalByKey = (modalKey, type = '') => {
    track('Folder Menu Item Click', { type: modalKey });
    setCurrentFolderId(folder.id);
    setCurrentModalType(type);
    setCurrentModal(modalKey);
  };

  const MENU_ITEMS = [
    {
      title: 'Rename',
      icon: Pencil,
      onClick: () => handleOpenModalByKey(RENAME_FOLDER_MODAL_KEY, MODAL_STATE.rename),
    },
    {
      title: 'Delete',
      icon: DeleteIcon,
      onClick: () => handleOpenModalByKey(DELETE_FOLDER_MODAL_KEY),
    },
  ];

  const handleCloseMenu = e => {
    e.stopPropagation();
    setMenuOpen(false);
  };

  return (
    <Menu
      anchorEl={isMenuOpen}
      open={open}
      onClose={handleCloseMenu}
      PaperProps={{
        style: {
          maxHeight: 400,
          width: '216',
        },
      }}
    >
      {MENU_ITEMS.map(menuItem => (
        <MenuItem
          disabled={menuItem.disabled}
          key={menuItem.title}
          menuItem={menuItem}
          onClose={handleCloseMenu}
          data-test-id={menuItem.dataTestId || ''}
        />
      ))}
    </Menu>
  );
};

export default FolderMenuItems;
