import React, { useCallback, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import {
  PLAYER_CATEGORY_KEY,
  GENERAL_CATEGORY_KEY,
  PLAYER_SCROLL_TO_KEY,
} from './branding.constants';
import { BrandingProvider } from './BrandingContext';
import Menu from 'shared/react/components/basic/Menu';
import BrandingEditor from './editors/BrandingEditor';
import StickyTopBar from 'app/src/pages/dashboard/components/top-bar/StickyTopBar';
import useUpdateDirtyForm from 'app/src/hooks/useUpdateDirtyForm';
import BackToEditorButton from 'app/src/pages/sidebar/BackToEditorButton';
import ShopifyAppsMultiSelect from 'app/src/complex_components/shopify-apps-multiselect/ShopifyAppsMultiSelect';
import BrandingPreview from './BrandingPreview';
import { useDesignEditor } from 'src/hooks/design';
import { usePackageBrandingFeatures } from 'src/hooks/package';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';
import useShopifyAppSelect from 'src/complex_components/shopify-apps-select/useShopifyAppSelect';
import { useBrandingLogoSettings } from './hooks';

const DEFAULT_SHOULD_SAVE_MAP = {
  shouldSaveDesignSettings: false,
  shouldSaveLogoSettings: false,
};

const getDefaultCategory = scrollTo => {
  if (scrollTo === PLAYER_SCROLL_TO_KEY) {
    return PLAYER_CATEGORY_KEY;
  }

  return GENERAL_CATEGORY_KEY;
};

const BrandingPage = () => {
  const [loading, setLoading] = useState(false);
  const [clearErrors, setClearErrors] = useState(false);
  const [editorRightLocation, setEditorRightLocation] = useState(547);
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const scrollTo = searchParams.get('section');
  const [selectedCategory, setSelectedCategory] = useState(getDefaultCategory(scrollTo));
  const defaultDesignSettings = useDesignSettingsContext();
  const { designSettings, setDesignSettings, saveDesignSettings, updateDesignSettings } =
    useDesignEditor(defaultDesignSettings);
  const { logoSettings: defaultLogoSettings, saveLogoSettings } = useBrandingLogoSettings();
  const { selectedAppUrl, shopifyApps } = useShopifyAppSelect();
  const packageBrandingFeatures = usePackageBrandingFeatures();
  const [logoSettings, setLogoSettings] = useState(defaultLogoSettings);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isAppMultiselectOpen, setIsAppMultiselectOpen] = useState(false);
  const [shouldSaveMap, setShouldSaveMap] = useState(DEFAULT_SHOULD_SAVE_MAP);

  useEffect(() => {
    if (defaultDesignSettings !== designSettings) {
      setDesignSettings(defaultDesignSettings);
    }

    if (logoSettings !== defaultLogoSettings) {
      setLogoSettings(defaultLogoSettings);
    }
  }, [defaultDesignSettings, defaultLogoSettings]);

  const updateShouldSaveMap = update => setShouldSaveMap({ ...shouldSaveMap, ...update });

  const closeAppMultiselect = () => setIsAppMultiselectOpen(false);

  const onSaveButtonClick = async e => {
    if (!shopifyApps.length) {
      return onSave({ shouldSaveToAccount: true });
    }

    setAnchorEl(e?.currentTarget);
    setIsAppMultiselectOpen(true);
  };

  const onAppMultiselectSave = apps => onSave({ apps });
  const onSave = async ({ apps = [], shouldSaveToAccount = false }) => {
    const promises = [];

    closeAppMultiselect();
    setClearErrors(true);
    setLoading(true);

    if (shouldSaveMap.shouldSaveDesignSettings) {
      promises.push(saveDesignSettings({ apps, shouldSaveToAccount }));
    }

    if (shouldSaveMap.shouldSaveLogoSettings) {
      promises.push(saveLogoSettings(logoSettings));
    }

    await Promise.all(promises);

    setLoading(false);
    setClearErrors(false);
    setHasUnsavedChanges(false);
    setShouldSaveMap(DEFAULT_SHOULD_SAVE_MAP);
  };

  const onSaveButtonCallback = useCallback(onSaveButtonClick, [designSettings, shouldSaveMap]);

  const handleReset = () => {
    setHasUnsavedChanges(false);
    setShouldSaveMap(DEFAULT_SHOULD_SAVE_MAP);
    setDesignSettings(defaultDesignSettings);
    setLogoSettings(defaultLogoSettings);
  };

  const updateLogoSettings = update => {
    setHasUnsavedChanges(true);
    updateShouldSaveMap({ shouldSaveLogoSettings: true });
    setLogoSettings({ ...logoSettings, ...update });
  };

  // TODO remove unused values from context
  const getProviderValue = () => {
    return {
      loading,
      setLoading,
      isUnsaved: hasUnsavedChanges,
      setIsUnsaved: setHasUnsavedChanges,
      clearErrors,
      setClearErrors,
      selectedCategory,
      setSelectedCategory,
      editorRightLocation,
      setEditorRightLocation,
      scrollTo,
      logoSettings,
      updateLogoSettings,
    };
  };

  const handleNavigateToDesign = () => {
    history.push(location.state.previousUrl);
  };

  useUpdateDirtyForm(hasUnsavedChanges, {
    isLoading: loading,
    onSaveClick: onSaveButtonCallback,
    onDiscardClick: handleReset,
  });

  const handleSettingsChange = (settings = {}, options = {}) => {
    const { shouldTriggerUnsaved = true } = options;

    if (shouldTriggerUnsaved) {
      setHasUnsavedChanges(true);
      updateShouldSaveMap({ shouldSaveDesignSettings: true });
    }

    updateDesignSettings(settings);
  };

  return (
    <BrandingProvider value={getProviderValue()}>
      <LayoutRoot>
        <StickyTopBar leftContent="Branding" dirtyTitle="Branding" />
        <Content>
          <SettingsContainer>
            <BrandingEditor
              settings={designSettings}
              features={packageBrandingFeatures}
              defaultSettings={defaultDesignSettings}
              onChange={handleSettingsChange}
            />
          </SettingsContainer>
          <BrandingPreview settings={designSettings} logoSettings={logoSettings} />
        </Content>
      </LayoutRoot>
      {scrollTo && <BackToEditorButton onClick={handleNavigateToDesign} saveReminder />}
      <Menu open={isAppMultiselectOpen} anchorEl={anchorEl} onClose={closeAppMultiselect}>
        <ShopifyAppsMultiSelect
          shopifyApps={shopifyApps}
          selectedAppUrl={selectedAppUrl}
          onSave={onAppMultiselectSave}
          onCancel={closeAppMultiselect}
        />
      </Menu>
    </BrandingProvider>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

const Content = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  padding-right: 16px;
  gap: 120px;
  flex-grow: 1;
  border-left: 1px solid ${({ theme }) => theme.colors.neutralLighter};
  @media (${({ theme }) => theme.breakpoints.dashboardTabletMax}) {
    grid-template-columns: unset;
  }
`;

const SettingsContainer = styled(VerticalFlex)`
  gap: 40px;
  padding: 0 0 16px 0;
  background: ${({ theme }) => theme.colors.white};
  height: 93vh;
  overflow: auto;
`;

export default BrandingPage;
