import { VIDEO_STATUS } from 'shared/react/constants/video.constants';
import { useIntegrationVodAsset } from 'src/context/IntegrationVodAssetStore';
import { useVideoActions } from 'src/context/VideosStore';

const usePrepareIntegrationVideos = () => {
  const { getVideoById } = useVideoActions();
  const [, { findVideoByExternalId }] = useIntegrationVodAsset();

  const prepareIntegrationVideos = videos =>
    videos.map(video => {
      const currentVideo = getVideoById(findVideoByExternalId(video.externalId)?.vodId);

      if (currentVideo && currentVideo?.status !== VIDEO_STATUS.invalid) {
        const createdAt = video.createdAt || currentVideo.createdAt;
        const data = { ...currentVideo, createdAt };
        if (currentVideo.status !== 'done') {
          return { ...data, stockAsset: video.stockAsset };
        }

        return data;
      }

      return video;
    });

  return { prepareIntegrationVideos };
};

export default usePrepareIntegrationVideos;
