import React from 'react';
import { VolumeX } from 'lucide-react';

interface SoundIconProps {
  isFeedMuted: boolean;
  isSoundAllowed: boolean;
}

const SoundIcon: React.FC<SoundIconProps> = ({ isSoundAllowed, isFeedMuted }) => {
  const icon = <VolumeX color="white" strokeWidth={2} size={15} />;
  if (isSoundAllowed === true) {
    return null;
  }
  if (isSoundAllowed === false || isFeedMuted) {
    return icon;
  }
  return null;
};

export default SoundIcon;
