import React from 'react';

const Dropdown = props => {
  return (
    <svg
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.875 6.422a.56.56 0 00.023-.797.56.56 0 00-.796-.023L6.977 9.539 2.875 5.602a.56.56 0 00-.797.023.56.56 0 00.024.797l4.5 4.312c.117.094.257.141.375.141.14 0 .28-.047.398-.14l4.5-4.313z"
        fill="#B3B6BC"
      />
    </svg>
  );
};

export default Dropdown;
