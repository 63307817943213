import { useApps } from 'app/src/context/AppsStore';
import { useUser } from 'app/src/context/userStore/UserStore';
import { ECOMMERCE_ID } from 'app/src/constants/readyMade.constants';

const isEcommerce = (isShopify, account) => {
  return isShopify || account?.companyVertical?.toLowerCase() === ECOMMERCE_ID;
};

const useIsEcom = () => {
  const [{ shopify }] = useApps();
  const [{ account }] = useUser();
  return isEcommerce(shopify, account);
};

export default useIsEcom;
