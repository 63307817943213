import { Modal } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import {
  IMPORT_FROM_INSTAGRAM,
  IMPORT_FROM_TIKTOK,
  RECORD_SCREEN,
  RECORD_VIDEO,
  UPLOAD_FILE,
} from 'app/src/constants/creation_method.constants';
import { assetType as AssetType } from 'src/types/entities';
import { EDIT_STEP_MODAL_KEY, IMAGE_LIBRARY_MODAL_KEY } from 'app/src/constants/modals.constants';
import { SCREEN_RECORDING, VIDEO_RECORDING } from 'app/src/constants/recordingTypes.constants';
import { PREVIEW_DESKTOP_KEY } from 'app/src/constants/tolstoy.constants';
import { useApps } from 'app/src/context/AppsStore';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { useProjects } from 'app/src/context/ProjectsStore';
import { SUPPORTED_CONTENT_TYPES } from 'shared/react/services/assets';
import { ImageUploadService } from 'src/services/assets';
import { useExtension } from 'app/src/context/ui_store/ExtensionStore';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';
import { useUser } from 'app/src/context/userStore/UserStore';
import { useVideos } from 'app/src/context/VideosStore';
import { track } from 'app/src/helpers/Tracker';
import useIsEcom from 'app/src/hooks/UseIsEcom';
import useUploadFile from 'app/src/hooks/useUploadFile';
import CreationMethod from 'app/src/pages/creation_flow/creation_method/CreationMethod';
import { LOADING_STATE } from 'app/src/pages/creation_flow/creationFlowConstants';
import RightSection from 'app/src/pages/creation_flow/right_section/RightSection';
import { defaultStepData } from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/editStepDefaultData';
import Utils from 'app/src/utils';
import { navigateToDashboard } from 'app/src/utils/navigation.utils';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FEATURE_KEEP_CAPTIONS_CHANGED_VIDEO } from 'shared/react/constants/features.constants';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { INSTAGRAM } from 'app/src/constants/intergrations.constants';
import { ONBOARDING } from 'app/src/constants/ui.constants';
import { useImportVideos } from 'app/src/context/ui_store/ImportVideosStore';
import { VIDEO_STATUS } from 'shared/react/constants/video.constants';
import useCreateIntegrationVideo from 'app/src/hooks/use-create-integration-video/useCreateIntegrationVideo';
import useNavigation from 'app/src/hooks/useNavigation';
import useAddStep from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/useAddStep';

const ACCEPTED_CONTENT_TYPES = SUPPORTED_CONTENT_TYPES.join(',');

const getSelectedMethod = (isMobile, screenRecording, isEcom, defaultSelectedMethod) => {
  if (defaultSelectedMethod) {
    return defaultSelectedMethod;
  }

  if (isMobile) {
    return '';
  }
  if (screenRecording) {
    return SCREEN_RECORDING;
  }

  if (isEcom) {
    return UPLOAD_FILE;
  }

  return RECORD_VIDEO;
};

const CreationFlowModal = () => {
  const isMobile = Utils.isMobile();
  const [{ user = {} }] = useUser();
  const [, { getFeatureEnabled }] = useFeatures();
  const history = useHistory();
  const isEcom = useIsEcom();
  const [{ shopify }, { isEcomPlatformConnected }] = useApps();
  const [, { setErrorSnackbar }] = useSnackBar();
  const { checkFile } = useUploadFile();
  const [, { openModalOrExtension }] = useExtension();
  const [
    { data: videos },
    {
      createVideo,
      uploadVideoAsset,
      deleteVideo,
      uploadSubtitles,
      getSubtitlesUrlByVideo,
      updateVideo,
    },
  ] = useVideos();
  const [{ selectedVideosToImport }, { resetSelectedVideosToImport, onReachStepLimit }] =
    useImportVideos();
  const [
    {
      modalProps: {
        project,
        reply,
        screenRecording,
        step,
        isFeed,
        changedVideo,
        type,
        publishMethod,
        location,
        defaultSelectedMethod,
        isLeftPanelVisible = true,
        appUrl,
        shouldCreateProject = true,
        folder,
        targetPage,
        videoFolder,
        defaultVideoFilterProps,
        onVideoClick,
        onModalClosed = () => {},
        shouldBreakAfterVideoPicked = false,
      },
    },
    { setCurrentModal, setModalProps },
  ] = useModal();
  const shouldClose = useRef();
  const [
    ,
    {
      createProject,
      copyMacroSteps,
      createProjectStep,
      updateProjectStep,
      publishProject,
      getStepByName,
    },
  ] = useProjects();
  const [selectedMethod, setSelectedMethod] = useState(
    defaultSelectedMethod || getSelectedMethod(isMobile, screenRecording, isEcom)
  );
  const [uploadedBlob, setUploadedBlob] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [videosUploadCompleted, setVideosUploadCompleted] = useState({
    completed: 0,
    outOf: 0,
    numErrors: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [uploadState, setUploadState] = useState(null);
  const [isVertical, setIsVertical] = useState(null);
  const [selectedPreviewMode, setSelectedPreviewMode] = useState(PREVIEW_DESKTOP_KEY);
  const inputRef = useRef();
  const uploadProgressRef = useRef();
  const keepCaptionsChangedVideo = getFeatureEnabled(FEATURE_KEEP_CAPTIONS_CHANGED_VIDEO);
  const isScreenRecording = selectedMethod === SCREEN_RECORDING;
  const { importMultipleFiles: importFiles } = useCreateIntegrationVideo();
  const { navigateToProjectTab } = useNavigation();
  const { getMaxNumberOfParts } = useAddStep(project);

  const closeModal = () => {
    if (isLoading) {
      shouldClose.current = true;
      return;
    }
    if (location === IMAGE_LIBRARY_MODAL_KEY) {
      setCurrentModal(IMAGE_LIBRARY_MODAL_KEY);
      return;
    }
    setCurrentModal(null);
    if (location === ONBOARDING) {
      navigateToDashboard(history, {
        fromSignup: true,
      });
    }
  };

  const publish = projectId => {
    return publishProject(projectId);
  };

  const onClose = () => {
    closeModal();
    if (location === !IMAGE_LIBRARY_MODAL_KEY) {
      setModalProps({});
    }
    resetSelectedVideosToImport();
    onModalClosed();
  };

  const resetState = () => {
    setUploadedBlob(null);
    setIsVertical(null);
  };

  const clearSelectedMethod = () => {
    setSelectedMethod('');
  };

  const onMethodClick = key => {
    if (isLoading) {
      return;
    }

    track(`${key} Method Click`);

    setUploadedBlob(null);
    setIsVertical(null);

    if (key === UPLOAD_FILE) {
      if (key === selectedMethod || isMobile) {
        inputRef.current?.click();
        if (!isMobile) {
          return;
        }
      }
      inputRef.current.value = null;
    } else if (key === RECORD_SCREEN) {
      onScreenRecording();
      return;
    }

    setSelectedMethod(key);
  };

  const importMultipleFiles = async ({ videos, owner, method, isVertical }) => {
    if (!videos.length || !owner || !method) {
      return {};
    }

    const videosData = await importFiles({ videos, owner, method, videoFolder });

    if (!shouldCreateProject) {
      return {};
    }

    let newProject = project;
    const newSteps = [];
    for (const { videoId } of videosData) {
      if (shouldClose.current) {
        break;
      }

      if (!videoId) {
        console.log('import failed for video', videoId);
        continue;
      }

      const { newProject: currentProject, newStep: currentStep } = await onVideoChoose(
        { id: videoId },
        true,
        newProject,
        isVertical
      );

      if (!newProject) {
        newProject = currentProject;
      }

      newSteps.push(currentStep);
    }

    return { newProject, newStep: newSteps[0] };
  };

  const onImportClick = async method => {
    track(`Multi Import Video From ${method} Click`);
    setUploadState(LOADING_STATE.importVideos);
    setIsLoading(true);
    const numberOfAllowedParts = getMaxNumberOfParts();
    const { owner } = user;

    const numberOfParts = project?.steps.items.length || 0;

    let remainingParts = numberOfAllowedParts - numberOfParts;
    if (remainingParts < 1) {
      remainingParts = 1;
    }

    const videosToImport = selectedVideosToImport.slice(0, remainingParts);

    try {
      const { newProject, newStep } = await importMultipleFiles({
        videos: videosToImport,
        method,
      });

      if (!shouldCreateProject) {
        closeModal();
        return;
      }

      onFinish(newProject, newStep.id);
    } catch (e) {
      console.log('importMultipleFiles failed', e);
    }
    setIsLoading(false);
    onClose();

    setUploadProgress(null);
    uploadProgressRef.current = null;
  };

  const handleSingleOfManyVideoUpload = async ({
    file,
    multiVideoLength,
    newProject,
    isVertical,
  }) => {
    setUploadedBlob(file);

    const video = await onCreateNewVideo({
      blob: file,
      mirror: false,
      videoInvalid: false,
      multiVideoLength: multiVideoLength,
    });

    const { newProject: currentProject, newStep: currentStep } = await onVideoChoose(
      video,
      true,
      newProject,
      isVertical
    );

    return { currentProject, currentStep };
  };

  const uploadMultipleFiles = async files => {
    const promises = files.map(checkFile);

    const approvedVideos = await Promise.all(promises);

    uploadProgressRef.current = '0%';
    setUploadProgress('0%');
    setVideosUploadCompleted({
      completed: 0,
      numErrors: 0,
      outOf: approvedVideos.filter(({ file }) => !!file).length,
    });

    let newProject = project;

    const newSteps = [];

    for (const { file } of approvedVideos) {
      if (shouldClose.current) {
        break;
      }

      if (!file) {
        console.log('upload failed for file ', file);
        continue;
      }

      const { currentProject, currentStep } = await handleSingleOfManyVideoUpload({
        file,
        multiVideoLength: files.length,
        newProject,
        isVertical: promises[0].isVertical,
      });

      if (!newProject) {
        newProject = currentProject;
      }

      newSteps.push(currentStep);
    }

    if (videosUploadCompleted.numErrors > 0) {
      setErrorSnackbar(
        `There has been an error when uploading  ${videosUploadCompleted.numErrors} out of ${videosUploadCompleted.outOf} videos. Please try uploading them again.`
      );
    }

    if (!shouldCreateProject) {
      closeModal();
      return;
    }

    onFinish(newProject, newSteps[0].id);
    setIsLoading(false);
    onClose();

    setUploadProgress(null);
    uploadProgressRef.current = null;
  };

  const onFilesSelected = e => {
    const files = [...e.target.files];
    onUploadFiles(files);
  };

  const onDropFiles = e => {
    e.preventDefault();
    e.stopPropagation();
    const files = [...e.dataTransfer.files];
    onUploadFiles(files);
  };

  const onUploadFiles = async files => {
    const numberOfAllowedParts = getMaxNumberOfParts();

    const numberOfParts = project?.steps.items.length || 0;

    let remainingParts = numberOfAllowedParts - numberOfParts;
    if (remainingParts < 1) {
      remainingParts = 1;
    }

    const fileParts = files.slice(0, remainingParts);

    if (fileParts.length === 1) {
      const { file, isVertical = false } = await checkFile(fileParts[0]);
      if (file) {
        setIsVertical(isVertical);
        setUploadedBlob(file);
      }
      return;
    }

    await uploadMultipleFiles(fileParts);
  };

  const onScreenRecording = () => {
    const onNotChrome = () => {
      setSelectedMethod(RECORD_SCREEN);
    };

    const data = {};

    if (project) {
      data.projectId = project?.id;
    }

    if (step) {
      data.stepId = step?.id;
    }

    if (reply) {
      data.reply = reply;
    }

    openModalOrExtension('App Record Screen', data, onNotChrome);
  };

  const handleUploadProgress = (uuid, progress) => {
    uploadProgressRef.current = progress;
    setUploadProgress(progress);
  };

  const onCreateVideoFailure = async (err, video) => {
    const error = Utils.enrichErrorMessage(err, 'Video upload failed');
    Sentry.captureException(error);
    console.log('onVideoUploadCompleted failure', error);

    if (video) {
      await deleteVideo({ id: video.id });
    }
    if (videosUploadCompleted.completed) {
      setVideosUploadCompleted(state => ({
        ...state,
        numErrors: state.numErrors + 1,
      }));
      return;
    }
    setErrorSnackbar('Error uploading video, please try again.');
  };

  const onCreateNewVideo = async ({ blob, mirror, videoInvalid, multiVideoLength, uploadType }) => {
    if (blob.type.includes('image')) {
      return onCreateNewImage(blob);
    }

    const uuid = uuidv4();

    setUploadState(LOADING_STATE.uploadingVideo);
    setUploadProgress('0%');
    if (multiVideoLength) {
      setVideosUploadCompleted(state => ({
        ...state,
        completed: state.completed + 1,
      }));
    }
    setIsLoading(true);

    const recordingTypes = {
      [RECORD_SCREEN]: SCREEN_RECORDING,
      [UPLOAD_FILE]: 'upload',
      [RECORD_VIDEO]: VIDEO_RECORDING,
      [IMPORT_FROM_TIKTOK]: 'tikTok',
      [IMPORT_FROM_INSTAGRAM]: INSTAGRAM,
    };

    const recordingType = uploadType || recordingTypes[selectedMethod];

    const name = blob.name || `Recorded Video #${videos.length + 1}`;
    const { owner } = user;

    const video = await createVideo({
      id: uuid,
      owner,
      name,
      type: AssetType.video,
      status: VIDEO_STATUS.uploading,
      uploadType: recordingType,
      mirror,
      originalSize: `${Utils.sizeInBytesToMb(blob.size)}MB`,
      folder: videoFolder || null,
    });

    const response = await uploadVideoAsset(
      history,
      {
        uuid,
        blob,
        videoInvalid,
        owner,
        video: video || videos[0],
      },
      handleUploadProgress
    );

    if (response?.error || !response?.data) {
      await onCreateVideoFailure(response.error, response.video);
      setIsLoading(false);
      return;
    }

    if (multiVideoLength) {
      return video;
    }

    setUploadProgress(null);
    uploadProgressRef.current = null;
    await onVideoChoose(video, null, null, isVertical);
    setUploadProgress(null);
  };

  const onCreateNewImage = async file => {
    const id = uuidv4();
    const name = file.name.substring(0, file.name.lastIndexOf('.'));
    const { owner } = user;

    setIsLoading(true);

    const asset = {
      id,
      name,
      type: AssetType.image,
      uploadType: UPLOAD_FILE,
      status: VIDEO_STATUS.uploading,
      originalSize: `${Utils.sizeInBytesToMb(file.size)}MB`,
    };

    const createdImageAsset = await createVideo(asset);

    await ImageUploadService.uploadNewImage(id, file, { owner });

    setIsLoading(false);
    await onVideoChoose(createdImageAsset);
    onClose();

    return createdImageAsset;
  };

  const updateStep = async videoId => {
    const newStep = { ...step, videoId };
    await updateProjectStep(newStep, project);
    return newStep;
  };

  const updateVideoCaptions = async (videoId, changedVideo) => {
    const response = await fetch(getSubtitlesUrlByVideo(changedVideo));
    const blob = await response.blob();
    const subtitles = await blob.text();
    await uploadSubtitles({ projectId: project.id, videoId: videoId, subtitles });
  };

  const createNewStep = async (videoId, project) => {
    const newStep = {
      ...defaultStepData(isFeed, isEcomPlatformConnected()),
      videoId,
      projectId: project.id,
    };
    const newProject = await createProjectStep(newStep, project);
    const lastStepName = newProject.stepsOrder[newProject.stepsOrder.length - 1];
    const step = getStepByName(lastStepName, newProject);
    return step;
  };

  const createNewProject = async (videoId, isVertical) => {
    const verticalOrientation =
      selectedPreviewMode !== PREVIEW_DESKTOP_KEY || selectedMethod === IMPORT_FROM_TIKTOK;

    setUploadState(LOADING_STATE.creatingProject);
    const defaultVerticalOption = verticalOrientation && !isScreenRecording;
    const vertical = isVertical ?? defaultVerticalOption;
    const project = await createProject({
      type: reply ? 'reply' : type,
      vertical,
      isEcomFeed: isFeed,
      publishMethod,
      appUrl: appUrl || shopify?.appUrl,
      folder,
      targetPage,
    });

    let step;
    if (videoId) {
      setUploadState(LOADING_STATE.creatingStep);
      step = await createNewStep(videoId, project);
    }

    return { step, project };
  };

  const onFinish = (project, stepId = '') => {
    setUploadState(null);
    navigateToProjectTab(project, stepId);
  };

  const onTolstoyChoose = async fromProject => {
    setIsLoading(true);
    setUploadState(LOADING_STATE.creatingStep);
    let newProject = project;
    if (!newProject) {
      ({ project: newProject } = await createNewProject());
    }

    await copyMacroSteps(fromProject.id, newProject.id);
    onFinish(newProject);
    onClose();
  };

  const updateVodAssetIsEverUsed = async videoId => {
    return updateVideo({
      id: videoId,
      isEverUsed: true,
    });
  };

  const onVideoChoose = async (video, isMultipleFiles, newProject, isVertical) => {
    if (onVideoClick) {
      onVideoClick(video);
    }

    if (shouldBreakAfterVideoPicked) {
      return;
    }

    if (!shouldCreateProject) {
      if (!isMultipleFiles) {
        closeModal();
      }
      return {};
    }

    setIsLoading(true);
    const videoId = video.id;
    updateVodAssetIsEverUsed(videoId);

    if (!newProject) {
      newProject = project;
    }

    let newStep;
    if (step) {
      setUploadState(LOADING_STATE.updatingStep);
      newStep = await updateStep(videoId);
    } else if (newProject) {
      const createStepsMessage = isMultipleFiles
        ? LOADING_STATE.creatingSteps
        : LOADING_STATE.creatingStep;
      setUploadState(createStepsMessage);
      newStep = await createNewStep(videoId, newProject);
    } else {
      const { step, project } = await createNewProject(videoId, isVertical);
      newProject = project;
      newStep = step;
    }

    if (changedVideo && keepCaptionsChangedVideo) {
      await updateVideoCaptions(videoId, changedVideo);
    }

    await publish(newProject.id);
    if (reply) {
      setModalProps({ step: newStep, project: newProject, reply });
      setCurrentModal(EDIT_STEP_MODAL_KEY);
      return;
    }

    if (isMultipleFiles) {
      return { newProject, newStep };
    }

    onFinish(newProject, newStep.id);
    onClose();
  };

  const onCopyTemplate = project => {
    onFinish(project);
    onClose();
  };

  const getCreationMethod = () => {
    return (
      <CreationMethod
        headerText={isFeed ? 'Create Feed' : 'Create Tolstoy'}
        onMethodClick={onMethodClick}
        selectedMethod={selectedMethod}
        onClose={onClose}
        shouldShowTemplates={shouldShowTemplates}
        isEcom={isEcom}
      />
    );
  };

  const getRightSection = () => {
    return (
      <RightSection
        onCreateNewVideo={onCreateNewVideo}
        defaultVideoFilterProps={defaultVideoFilterProps}
        clearSelectedMethod={clearSelectedMethod}
        uploadedBlob={uploadedBlob}
        selectedMethod={selectedMethod}
        resetVideoState={resetState}
        onDropFiles={onDropFiles}
        onVideoChoose={onVideoChoose}
        onTolstoyChoose={onTolstoyChoose}
        uploadProgress={uploadProgressRef.current || uploadProgress}
        onCopyTemplate={onCopyTemplate}
        onClose={onClose}
        selectedPreviewMode={selectedPreviewMode}
        setSelectedPreviewMode={setSelectedPreviewMode}
        isLoading={isLoading}
        uploadState={uploadState}
        onMethodClick={onMethodClick}
        onCreateVideoFailure={onCreateVideoFailure}
        videosUploadCompleted={videosUploadCompleted}
        numberOfAllowedParts={getMaxNumberOfParts()}
        numberOfParts={project?.steps.items.length || 0}
        onImportClick={onImportClick}
        isLeftPanelVisible={isLeftPanelVisible}
      />
    );
  };

  const getContent = () => {
    if (!isMobile) {
      return (
        <>
          {isLeftPanelVisible && getCreationMethod()}
          {getRightSection()}
        </>
      );
    }

    if (selectedMethod) {
      return getRightSection();
    }

    return getCreationMethod();
  };

  useEffect(() => {
    const numberOfAllowedParts = getMaxNumberOfParts();

    const numberOfParts = project?.steps.items.length || 0;

    const remainingParts = numberOfAllowedParts - numberOfParts;
    if (remainingParts <= selectedVideosToImport.length) {
      onReachStepLimit();
    }
  }, [selectedVideosToImport.length]);

  useEffect(() => {
    if (defaultSelectedMethod) {
      setSelectedMethod(defaultSelectedMethod);
    }
  }, [defaultSelectedMethod]);

  const shouldShowTemplates = !project && !reply;

  return (
    <Modal disableEnforceFocus onClose={onClose} open={true}>
      <LayoutRoot>
        <ModalContainer>{getContent()}</ModalContainer>
        <HiddenInput
          onChange={onFilesSelected}
          type="file"
          accept={ACCEPTED_CONTENT_TYPES}
          ref={inputRef}
          multiple
        />
      </LayoutRoot>
    </Modal>
  );
};

const LayoutRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const ModalContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  display: flex;
  overflow: hidden;
  position: relative;
  max-width: 1272px;
  width: 100%;
  margin: 50px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    border-radius: 0;
    height: 100%;
    margin: 0px;
  }
`;

const HiddenInput = styled.input`
  display: none;
`;

export default CreationFlowModal;
