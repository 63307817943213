import React from 'react';
import styled from 'styled-components';

function SearchBar({
  placeholder,
  value,
  disabled,
  maxLength,
  startAdornment,
  endAdornment,
  onChange,
  dataTestId,
  ...props
}) {
  const getGridTemplateColumns = (startAdornment, endAdornment) => {
    if (startAdornment && endAdornment) {
      return 'auto 1fr auto';
    }

    if (startAdornment) {
      return 'auto 1fr';
    }

    if (endAdornment) {
      return '1fr auto';
    }

    return '1fr';
  };

  return (
    <LayoutRoot
      gridTemplateColumns={getGridTemplateColumns(startAdornment, endAdornment)}
      {...props}
    >
      {startAdornment}
      <Input
        type="text"
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disabled}
        onChange={onChange}
        value={value}
        data-test-id={dataTestId}
      />
      {endAdornment}
    </LayoutRoot>
  );
}
export default SearchBar;

const LayoutRoot = styled.div`
  display: grid;
  background: white;
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
`;

const Input = styled.input`
  outline: none;
  border: none;
  margin: 0;
  vertical-align: baseline;
  padding-top: 3px;
  ::placeholder {
    color: ${({ theme }) => theme.colors.gray2};
    font-size: 16px;
  }
  &:disabled {
    background: white;
  }
`;
