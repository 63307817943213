import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FEATURE_MAGENTO_INTEGRATION } from 'app/src/constants/appFeatures.constants';
import { CONNECT_TO_MAGENTO_MODAL_KEY } from 'app/src/constants/modals.constants';
import { useApps } from 'app/src/context/AppsStore';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import MagentoIcon from 'app/src/images/integrations/magento.svg';
import ShopifyIcon from 'app/src/images/shopify.png';
import WixIcon from 'app/src/images/wix.png';
import FreshSalesIcon from 'app/src/images/freashSalesIcon.png';
import LinkedInIcon from 'app/src/images/linkedinIcon.png';
import GorgiasIcon from 'app/src/images/gorgias.svg';
import IntercomIcon from 'app/src/images/intercom.svg';
import WordPressIcon from 'app/src/images/wordpress.svg';
import BigcommerceIcon from 'app/src/images/bigcommerce.svg';
import SynthesiaIcon from 'app/src/images/synthesia.svg';
import MondayIcon from 'app/src/images/monday.svg';
import KlaviyoIcon from 'app/src/images/klaviyo.svg';
import HubspotIcon from 'app/src/images/hubspot.svg';
import MailChimpIcon from 'app/src/images/mailchimp.svg';
import GreenHouseIcon from 'app/src/images/greenhouse.svg';
import SalesForceIcon from 'app/src/images/salesforce.svg';
import YotpoIcon from 'app/src/images/yotpo.svg';
import GoogleSheetsIcon from 'app/src/images/google_sheets.svg';
import TiktokIcon from 'app/src/images/tiktok.png';
import InstagramIcon from 'app/src/images/instagram.png';
import SlackIcon from 'app/src/images/slack.svg';
import OmnisendIcon from 'app/src/images/omnisend.svg';
import MakeIcon from 'app/src/images/make.svg';
import BilloIcon from 'app/src/images/billo.png';
import DIDIcon from 'app/src/images/d-id.svg';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import Integration from './Integration';
import { DASHBOARD_TABLET_MAX_WIDTH } from 'shared/react/utils/mediaQuery';
import OutreachLogo from 'app/src/images/outreach_blue_icon.svg';
import SalesloftLogo from 'app/src/images/salesloft_logo.png';
import SnackBar from 'app/src/basic_components/SnackBar';
import { useUser } from 'app/src/context/userStore/UserStore';
import { isExtensionInstalled } from 'app/src/context/ui_store/ExtensionStore';
import { InfoBadge } from 'shared/react/components/basic/Badge';
import { useTikTok } from 'app/src/context/TikTokStore';
import useIntegrationsVideos from 'app/src/hooks/useIntegrationsVideos';
import { IMPORT_FROM_INTEGRATION_LOCATIONS } from 'app/src/constants/trackingLocations.constants';
import { INSTAGRAM_BUSINESS_SHOW_TO_LINK } from 'app/src/constants/helpArticleLinks.constants';
import { SHOPIFY_SUFFIX } from 'app/src/constants/shopify.constants';
import APP_CONFIG from 'app/src/config/app.config';
import { YOTPO } from 'src/constants/intergrations.constants';
import { useProjectActions } from 'src/context/ProjectsStore';

function Integrations() {
  const [apps, { createApp, authenticateKlaviyoToken }] = useApps();
  const [, { setCurrentModal }] = useModal();
  const [{ account, user }] = useUser();
  const [{ firstUserInfo: tiktokInfo }, { fetchUserInfoByApps }] = useTikTok();
  const [saveApiKeyState, setSaveApiKeyState] = useState(false);
  const [loading, setLoading] = useState({});
  const [isError, setError] = useState(false);
  const { getFeatureEnabled } = useFeatureActions();
  const { invalidateProjectsConfigs } = useProjectActions();
  const {
    handleInstagramDisconnect: disconnectInstagram,
    handleTikTokDisconnect: disconnectTiktok,
    handleInstagramConnect: connectInstagram,
    handleTiktokConnect: connectTiktok,
    instagramProfile,
    hasOldInstagramIntegration,
    instagram,
    tiktok,
  } = useIntegrationsVideos(IMPORT_FROM_INTEGRATION_LOCATIONS.integrations);

  const handleSaveKlaviyoApiKey = async apiKey => {
    const isAuthenticated = await authenticateKlaviyoToken(apiKey);
    setError(!isAuthenticated);
    setSaveApiKeyState(true);
  };

  const handleSaveYotpoApiKey = async appKey => {
    await createApp(YOTPO, appKey);
    await invalidateProjectsConfigs();
  };

  const handleConnectToMagento = () => {
    if (!apps.magento) {
      setCurrentModal(CONNECT_TO_MAGENTO_MODAL_KEY);
    }
  };

  const handleSaveSynthesiaApiKey = async apiKey => {
    const res = await fetch('https://api.synthesia.io/v2/videos', {
      headers: { authorization: apiKey },
    });

    if (res.ok) {
      await createApp('synthesia', apiKey);
      setError(false);
    } else {
      setError(true);
    }
    setSaveApiKeyState(true);
  };

  const handleShopifyInput = storeName => {
    window.open(
      `${APP_CONFIG.INSTALL_SHOPIFY_URL}?shop=${storeName}.myshopify.com&appKey=${account.appKey}`,
      '_self'
    );
  };

  const handleTiktokConnect = async () => {
    setLoading({ ...loading, tiktok: true });
    await connectTiktok();
    setLoading({ ...loading, tiktok: false });
  };

  const handleInstagramConnect = async () => {
    setLoading({ ...loading, instagram: true });
    await connectInstagram();
    setLoading({ ...loading, instagram: false });
  };

  const handleTikTokDisconnect = async () => {
    setLoading({ ...loading, tiktok: true });
    await disconnectTiktok();
    setLoading({ ...loading, tiktok: false });
  };

  const handleInstagramDisconnect = async () => {
    setLoading({ ...loading, instagram: true });
    await disconnectInstagram();
    setLoading({ ...loading, instagram: false });
  };

  const integrations = [
    {
      title: 'Shopify',
      description:
        'Add your Tolstoy to your Shopify store to address and solve the unique needs and challenges of your business. Embed your Tolstoy on any landing page or add as a widget.',
      connected: apps.shopify,
      url: apps.shopify ? '' : 'https://apps.shopify.com/tolstoy',
      icon: ShopifyIcon,
      help: 'https://help.gotolstoy.com/en/articles/8149075-shopify-tolstoy-installation',
      onInputSave: handleShopifyInput,
      inputPlaceHolder: 'Your store name',
      inputFixedText: SHOPIFY_SUFFIX,
      connectedTooltipText: apps?.shopify?.appUrl,
    },
    {
      title: 'Wix',
      description:
        'Easily add your interactive Tolstoy to your site to draw viewers in and provide face to face interactions, even when you’re offline. Embed your Tolstoy on any landing page or add as a widget.',
      connected: apps.wix,
      url: apps.wix ? '' : 'https://www.wix.com/app-market/tolstoy-interactive-video',
      icon: WixIcon,
      help: 'https://help.gotolstoy.com/en/articles/5985544-wix-integration-101',
    },
    {
      title: 'Synthesia',
      description:
        'Import your Synthesia videos into Tolstoy, with 1 click! Synthesia lets you create professional AI videos from text in 60+ languages. No need for cameras or actors.',
      connected: apps.synthesia,
      url: apps.synthesia ? '' : 'https://www.bigcommerce.com/apps/tolstoy/',
      icon: SynthesiaIcon,
      onInputSave: handleSaveSynthesiaApiKey,
      inputPlaceHolder: 'Enter your Synthesia API key',
      isVideoCreationTool: true,
    },
    {
      title: 'Bigcommerce',
      description:
        'Easily embed Tolstoy on your site or add as a pop up to draw viewers in and provide face to face interactions, anytime and anywhere.',
      connected: apps.bigcommerce,
      url: apps.bigcommerce ? '' : 'https://www.bigcommerce.com/apps/tolstoy/',
      icon: BigcommerceIcon,
    },
    {
      title: 'Monday.com',
      description:
        'Send Tolstoy responses straight to your monday.com board to save lead details, stay organized, and allow your team to come together and act quickly.',
      connected: apps.monday,
      url: apps.monday ? '' : 'https://monday.com/',
      icon: MondayIcon,
      help: 'https://help.gotolstoy.com/en/articles/5067341-monday-com-app-integration',
    },
    {
      title: 'Intercom',
      description:
        'Instantly send videos inside Intercom messenger to resolve tickets faster and provide frictionless customer service. Get full visibility of viewer interactions directly inside Intercom.',
      connected: apps.intercom,
      url: apps.intercom ? '' : 'https://www.intercom.com/app-store/?app_package_code=tolstoy-reo3',
      icon: IntercomIcon,
      help: 'https://help.gotolstoy.com/en/articles/5438917-intercom-integration',
    },

    {
      title: 'HubSpot',
      description:
        'Create new leads and attach text, video, and contact form responses in HubSpot directly from a Tolstoy.',
      connected: apps.hubspot,
      icon: HubspotIcon,
      url: apps.hubspot ? '' : `${APP_CONFIG.PUBLISH_HUBSPOT_URL}?email=${user?.email}`,
      help: 'https://help.gotolstoy.com/en/articles/5444379-hubspot-integration',
    },
    {
      title: 'Freshsales by',
      description:
        'Create new contacts and leads directly from a Tolstoy, to level up your eCommerce marketing.',
      containsZapierLogo: true,
      connected: false,
      icon: FreshSalesIcon,
      url: 'https://zapier.com/apps/freshsales/integrations/tolstoy',
    },
    {
      title: 'Tiktok',
      containsZapierLogo: false,
      name: 'tiktok',
      description:
        'Easily upload multiple videos to your Tolstoy library by connecting your account to TikTok. Get notified when new videos are posted on TikTok for automatic uploading.',
      connected: tiktok,
      icon: TiktokIcon,
      onClick: handleTiktokConnect,
      loading: loading.tiktok,
      onDisconnect: handleTikTokDisconnect,
      userName: tiktokInfo?.userName,
    },
    {
      title: 'Instagram',
      containsZapierLogo: false,
      description:
        'Easily upload multiple Instagram videos to your Tolstoy library by connecting your Facebook Business account. Get notified when new videos are posted on Instagram for automatic uploading.',
      connected: instagram,
      icon: InstagramIcon,
      loading: loading.instagram,
      onClick: handleInstagramConnect,
      onDisconnect: handleInstagramDisconnect,
      userName: instagramProfile?.userName,
      updateAppBadge: hasOldInstagramIntegration,
      help: INSTAGRAM_BUSINESS_SHOW_TO_LINK,
    },
    {
      title: 'Linkedin',
      description:
        'Easily communicate with video via Linkedin.  Using the Tolstoy extension you can quickly record interactive videos and share them with one click in a LinkedIn message with a gif.',
      connected: isExtensionInstalled(),
      icon: LinkedInIcon,
      url: 'https://chrome.google.com/webstore/detail/tolstoy-free-screen-and-v/lddobacmfjhpmcdapikgehklfonmnoeg?hl=en',
      help: 'https://help.gotolstoy.com/en/articles/6361770-how-to-share-your-tolstoy-in-linkedin',
    },
    {
      title: 'Billo app - Find a Creator',
      description: 'Affordable & easy way to get custom-made eCom videos from vetted creators.',
      connected: false,
      icon: BilloIcon,
      url: 'http://billo.app/',
      isVideoCreationTool: true,
      extraContent: (
        <StyledInfoBadge>
          <BilloPromoContent>
            Use Code <span>TOLSTOY-25OFF</span> and get 25$ OFF 1st order.
          </BilloPromoContent>
        </StyledInfoBadge>
      ),
    },
    {
      title: 'D-ID — Create avatar from your picture',
      description:
        'Transform your photos into AI video presenters. Produce AI-powered, cost-effective videos for training materials, internal communications, marketing and more.',
      connected: false,
      icon: DIDIcon,
      url: 'https://create.d-id.com/',
      isVideoCreationTool: true,
    },
    {
      title: 'Gorgias',
      description:
        'Automatically send Tolstoy viewer interaction data directly to Gorgias. Open Gorgias chat within Tolstoy to support your customers via a frictionless experience.',
      connected: apps.gorgias,
      url: apps.gorgias ? '' : 'https://www.gorgias.com/apps/tolstoy',
      icon: GorgiasIcon,
      help: 'https://help.gotolstoy.com/en/articles/5403022-gorgias-app-integration',
    },
    {
      title: 'Salesloft',
      containsZapierLogo: false,
      description:
        'Use Tolstoy to add an interactive GIF directly in your Salesloft email editor. Automatically send Tolstoy viewer interaction data directly to Salesloft.',
      connected: apps.salesloft,
      icon: SalesloftLogo,
      iconHeight: '36px',
      url: 'https://app.gotolstoy.com/?return_to=https://app.salesloft.com/app/settings/integrations',
      help: 'https://help.gotolstoy.com/en/articles/5900597-salesloft-integration',
    },
    {
      title: 'Outreach',
      containsZapierLogo: false,
      description:
        'Create new Outreach templates and snippets directly from Tolstoy. Automatically send Tolstoy viewer interaction data directly to Outreach.',
      connected: apps.outreach,
      icon: OutreachLogo,
      url: `${APP_CONFIG.INSTALL_OUTREACH_URL}?userId=${user?.owner}&appKey=${user?.appKey}`,
      help: 'https://help.gotolstoy.com/en/articles/5880644-outreach-integration',
    },
    {
      title: 'Greenhouse',
      description: 'Integrate Tolstoy with your Greenhouse, for more details contact support.',
      connected: false,
      icon: GreenHouseIcon,
      url: '',
      openSupport: true,
    },
    {
      title: 'WordPress',
      description:
        'Easily add Tolstoy to your site as a pop up to draw viewers in and provide face to face interactions, anytime and anywhere.',
      connected: false,
      url: 'https://wordpress.org/plugins/tolstoy-video/',
      icon: WordPressIcon,
      help: 'https://help.gotolstoy.com/en/articles/5997664-wordpress-integration-101',
    },
    {
      title: 'Make',
      description:
        'Easily automate a variety of actions with Tolstoy data - add leads to your mailing lists, add interactions as rows of data, export interactions to CRM platforms, and more.',
      connected: false,
      url: 'https://www.make.com/en/hq/app-invitation/8e8bda8141a7c84259eac8c70fe03834',
      icon: MakeIcon,
      help: 'https://help.gotolstoy.com/en/articles/6166759-make-com-integration',
    },
    {
      title: 'Omnisend by',
      containsZapierLogo: true,
      description:
        'Create new contacts and attach responses in Omnisend directly from a Tolstoy to level up your eCommerce marketing.',
      connected: false,
      icon: OmnisendIcon,
      url: 'https://zapier.com/app/editor/template/624416',
      help: 'https://help.gotolstoy.com/en/articles/6003905-how-to-integrate-tolstoy-with-zapier-omnisend',
    },
    {
      title: 'Google Sheet by',
      containsZapierLogo: true,
      description:
        'Send viewer interaction data directly to Google Sheets. Organize your data and collaborate with your team easily.',
      connected: false,
      icon: GoogleSheetsIcon,
      url: 'https://zapier.com/app/editor/template/575222',
      help: 'https://help.gotolstoy.com/en/articles/6007720-how-to-integrate-tolstoy-with-zapier-google-sheets',
    },
    {
      title: 'Klaviyo by',
      containsZapierLogo: true,
      description:
        'Add new Klaviyo subscribers directly from Tolstoy responses to automate your lead capture and engagement.',
      connected: false,
      icon: KlaviyoIcon,
      url: 'https://zapier.com/app/editor/template/575070',
      help: 'https://help.gotolstoy.com/en/articles/5721010-how-to-integrate-tolstoy-with-zapier-klaviyo',
    },
    {
      title: 'MailChimp by',
      containsZapierLogo: true,
      description:
        'Send new leads to your Mailchimp subscriber lists, and tag them so they’re easy to organize.',
      connected: false,
      icon: MailChimpIcon,
      url: 'https://zapier.com/app/editor/template/575074',
      help: 'https://help.gotolstoy.com/en/articles/6004237-how-to-integrate-tolstoy-with-zapier-mailchimp',
    },
    {
      title: 'Salesforce by',
      containsZapierLogo: true,
      description:
        'Create new contacts directly from Tolstoy interactions and attach responses to a user record in Salesforce.',
      connected: false,
      icon: SalesForceIcon,
      url: 'https://zapier.com/app/editor/template/575204',
    },
    {
      title: 'Slack by',
      containsZapierLogo: true,
      description:
        'Send notifications to a channel or an individual in Slack with real-time Tolstoy responses. Integrate Tolstoy into your workflow so the right person can respond on their time.',
      connected: false,
      icon: SlackIcon,
      url: 'https://zapier.com/app/editor/template/575317',
      help: 'https://help.gotolstoy.com/en/articles/5999957-how-to-integrate-tolstoy-with-zapier-slack',
    },
    {
      title: 'Klaviyo',
      containsZapierLogo: false,
      description:
        'Add new Klaviyo subscribers directly from Tolstoy quiz results to automate your lead capture and engagement.',
      connected: apps.klaviyo,
      url: '',
      icon: KlaviyoIcon,
      onInputSave: handleSaveKlaviyoApiKey,
      inputPlaceHolder: 'Enter your Klaviyo private API key',
    },
    {
      title: 'Yotpo',
      description:
        "Yotpo's eCommerce retention marketing platform drives repeat shopper sales with connected solutions for reviews, SMS, email, loyalty, and subscriptions.",
      connected: apps.yotpo,
      url: 'https://reviews.yotpo.com/',
      icon: YotpoIcon,
      onInputSave: handleSaveYotpoApiKey,
      inputPlaceHolder: 'Enter your Yotpo app_key',
    },
  ];

  if (getFeatureEnabled(FEATURE_MAGENTO_INTEGRATION)) {
    integrations.push({
      title: 'Magento',
      containsZapierLogo: false,
      description:
        'Link Magento’s products catalog to your Tolstoy account and enioy Tolstoy’s shoppable features. Boosting your customers engagement and driving conversions.',
      connected: apps.magento,
      url: '',
      icon: MagentoIcon,
      onClick: handleConnectToMagento,
    });
  }

  useEffect(() => {
    if (!apps.tiktokApps.length || tiktokInfo) {
      return;
    }

    fetchUserInfoByApps(apps.tiktokApps);
  }, [apps.tiktok]);

  const getSnackbarText = () => {
    return isError
      ? 'Failed to authenticate the API key. Please check your input and try again.'
      : 'Successfully connected!';
  };

  const queryParams = new URLSearchParams(location.search);
  const focused = queryParams.get('focused');

  return (
    <>
      <LayoutRoot>
        <Container>
          <MainContainer>
            {integrations.map(integration => (
              <Integration
                key={integration.title}
                integration={integration}
                focused={integration.title === focused}
              />
            ))}
          </MainContainer>
        </Container>
      </LayoutRoot>
      <SnackBar
        setOpen={setSaveApiKeyState}
        text={getSnackbarText()}
        open={saveApiKeyState}
        severity={isError ? 'error' : 'success'}
      />
    </>
  );
}

export default Integrations;

const LayoutRoot = styled.div`
  display: flex;
  justify-items: center;
  height: 100%;
  width: 100%;
  background: white;
  overflow: hidden;

  @media (${DASHBOARD_TABLET_MAX_WIDTH}) {
    flex-direction: column;
    background: #f4f5f7;
    overflow: auto;
  }
`;

const MainContainer = styled(HorizontalFlexWrap)`
  gap: 20px;
  align-self: start;
  padding: 20px;
  height: 100%;
  overflow-y: auto;
  background: #f4f5f7;
`;

const Container = styled.div``;

const BilloPromoContent = styled.span`
  font-weight: 700;

  & > span {
    font-weight: 900;
  }
`;

const StyledInfoBadge = styled(InfoBadge)`
  padding: 8px 32px;
`;
