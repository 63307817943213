import React from 'react';
import styled from 'styled-components';
import IosSwitch from 'app/src/basic_components/IosSwitch';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import { OnOffToggleItemProps } from '../../../types/lookAndFeel.types';

const OnOffToggleItem = ({ onChange, dataTestId, checked, disabled }: OnOffToggleItemProps) => {
  return (
    <LayoutRoot>
      <IosSwitch
        data-test-id={dataTestId}
        checked={checked}
        onChange={e => onChange(e.target.checked)}
        disabled={disabled}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlexWrap)`
  align-items: center;
  flex-wrap: nowrap;
`;

export default OnOffToggleItem;
