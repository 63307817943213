import { getRequest } from 'app/src/helpers/API';
import { useQuery } from '@tanstack/react-query';
import { useUser } from 'app/src/context/userStore/UserStore';
import { useMemo } from 'react';

export const useTrendingVideos = () => {
  const [{ user }] = useUser();
  const appKey = user.appKey;

  const { data, isLoading } = useQuery({
    queryKey: ['getTrendingVideos', appKey],
    queryFn: async () => {
      return await getRequest('user-actions', `/actions/users/trending-videos?appKey=${appKey}`);
    },
  });

  const trendingVideos = useMemo(() => data || [], [data]);
  return { trendingVideos, isLoading };
};
