import React from 'react';
import styled, { useTheme } from 'styled-components';
import PlusIcon from 'app/src/images/PlusIcon';
import Types from 'shared/react/theme/Types';
import Sizes from 'shared/react/theme/Sizes';
import Button from 'shared/react/components/complex/Button';
import BorderColorTypes from 'shared/react/theme/BorderColorTypes';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import StraightArrowRightIcon from 'src/images/creation_method_icons/StraightArrowRightIcon';
import { MODE } from '../ColorPalettePopover.constants';

const ColorPalettePopoverHeader = ({ mode, onAddCustom, onBackToBrandColors }) => {
  const theme = useTheme();
  const isPaletteMode = mode === MODE.PALETTE;
  const btnProps: any = {
    showLeftIcon: true,
    size: Sizes.Regular,
    type: Types.SecondaryGhost,
    padding: 0,
    borderColorType: BorderColorTypes.FullyTransparent,
    leftIcon: isPaletteMode ? <PlusIcon fill={theme.colors.blue12} size="14" /> : <ArrowLeftIcon />,
    onClick: isPaletteMode ? onAddCustom : onBackToBrandColors,
  };

  const headerText = isPaletteMode ? 'Add custom' : 'Back to brand colors';

  return (
    <Header>
      {isPaletteMode && <div>Brand colors</div>}
      <Button {...btnProps}>{headerText}</Button>
    </Header>
  );
};

const Header = styled(HorizontalFlex)`
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.ghostDark};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutralLight};
`;

const ArrowLeftIcon = styled(StraightArrowRightIcon)`
  transform: rotate(180deg);
  & path {
    fill: ${({ theme }) => theme.colors.blue12};
  }
`;

export default ColorPalettePopoverHeader;
