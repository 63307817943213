import React from 'react';
import RuleSummaryItem from 'app/src/pages/project/pages/rules_and_triggers/summary/RuleSummaryItem';
import RuleSummaryItemContainer from 'app/src/pages/project/pages/rules_and_triggers/summary/RuleSummaryItemContainer';
import EmptyState from 'app/src/pages/project/pages/rules_and_triggers/summary/EmptyState';
import Utils from 'app/src/utils';
import { ALL } from 'app/src/pages/project/pages/rules_and_triggers/Rule.constants';

function RulesSummaryContent({ ruleGroup, showOnDevice, isEmptyState, showOnDomains }) {
  if (isEmptyState) {
    return <EmptyState />;
  }

  return (
    <>
      {!!showOnDomains?.length &&
        showOnDomains.map(url => (
          <RuleSummaryItem key={url} attributeText="Store" valueText={url} />
        ))}

      {!!ruleGroup?.exitIntent && (
        <RuleSummaryItem
          attributeText="Exit Intent"
          valueText={ruleGroup?.exitIntent ? 'On' : 'Off'}
        />
      )}
      {!!ruleGroup?.delayTriggerEnabled && (
        <RuleSummaryItem
          attributeText="Show After"
          valueText={
            !ruleGroup?.delayTriggerSeconds ? 'On' : `${ruleGroup.delayTriggerSeconds} sec`
          }
        />
      )}

      {ruleGroup?.rules?.flat().map(rule => (
        <RuleSummaryItemContainer key={rule.key} rule={rule} />
      ))}

      <RuleSummaryItem
        attributeText="Device"
        valueText={Utils.capitalizeFirstLetter(showOnDevice || ALL)}
      />
    </>
  );
}

export default RulesSummaryContent;
