import React from 'react';
import { EditThumbnailOptions } from 'app/src/complex_components/global_modal/tumbnail-edit/ThumbnaiEdit.constants';
import UploadImage from 'app/src/complex_components/global_modal/tumbnail-edit/components/UploadImage';
import SelectPart from 'app/src/complex_components/global_modal/tumbnail-edit/components/select-part/SelectPart';

type Props = {
  videoSrc: string;
  duration: number;
  startTime: number;
  thumbnail: File | null;
  editThumbnailOption: EditThumbnailOptions;
  onStartTimeChange: (startTime: number) => void;
  onThumbnailUpload: (file: File) => void;
};

const ThumbnailEditContent = ({
  editThumbnailOption,
  videoSrc,
  duration,
  startTime,
  thumbnail,
  onStartTimeChange,
  onThumbnailUpload,
}: Props) => {
  if (editThumbnailOption === EditThumbnailOptions.edit) {
    return (
      <SelectPart
        duration={duration}
        videoSrc={videoSrc}
        startTime={startTime}
        onStartTimeChange={onStartTimeChange}
      />
    );
  }

  return <UploadImage thumbnail={thumbnail} onThumbnailUpload={onThumbnailUpload} />;
};

export default ThumbnailEditContent;
