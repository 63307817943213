export const starType = {
  full: 'full',
  empty: 'empty',
  half: 'half',
};

const STARS_COUNT = 5;
const PRECISION = 0.25;

/**
 * 0 - 0.25 => empty
 * 0.25 - 0.75 => half
 * 0.75 - 1 => full
 */
export const getStarsArray = score => {
  const fullStars = Math.floor(score + PRECISION);
  const decimal = score % 1;
  const hasHalfStar = decimal > PRECISION && decimal < 1 - PRECISION;
  const emptyStars = STARS_COUNT - fullStars - (hasHalfStar ? 1 : 0);

  return [
    ...Array(fullStars).fill(starType.full),
    ...(hasHalfStar ? [starType.half] : []),
    ...Array(emptyStars).fill(starType.empty),
  ];
};
