import React, { useState } from 'react';
import Utils from '../utils';
import SnackBar from 'app/src/basic_components/SnackBar';
import styled, { css } from 'styled-components';
import Button from 'shared/react/components/complex/Button';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';

const CopyLink = ({ value, onCopy, onOpenLink, disabled, ...props }) => {
  const [successBar, setSuccessBar] = useState(false);

  function handleCopy() {
    onCopy?.();
    Utils.copyToClipboard(value);
    setSuccessBar(true);
  }

  function handleOpenLink() {
    if (disabled) {
      return;
    }

    onOpenLink?.();
    Utils.openInNewTab(value);
  }

  return (
    <CopyContainer {...props}>
      <EllipsisWrapper>
        <LinkContainer {...props} onClick={handleOpenLink} disabled={disabled}>
          {value}
        </LinkContainer>
      </EllipsisWrapper>

      <Button onClick={handleCopy} disabled={disabled}>
        Copy link
      </Button>
      <SnackBar
        text="Copied to Clipboard!"
        open={successBar}
        setOpen={setSuccessBar}
        severity="success"
      />
    </CopyContainer>
  );
};

const CopyContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 8px;
  justify-content: space-between;
`;

const EllipsisWrapper = styled(EllipsisTextOverflowWrapper)`
  width: unset;
`;

const LinkContainer = styled.div`
  padding: 16px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.gray5};
  opacity: ${({ disabled }) => (disabled ? 0.8 : 1)};
  color: ${({ disabled }) => (disabled ? 'transparent' : 'unset')};

  &:hover {
    ${({ disabled, theme }) =>
      disabled
        ? css`
            color: transparent;
            cursor: default;
          `
        : css`
            color: ${theme.colors.blue1};
            cursor: pointer;
          `}
  }
`;

export default CopyLink;
