import React, { useState } from 'react';
import styled from 'styled-components';
import Card from 'app/src/basic_components/Card';
import { TextBody, TextSmall } from 'shared/react/components/complex/Text';
import VerticalMenuIcon from 'app/src/images/dashboard_v2/VerticalMenuIcon';
import IconButton from 'app/src/complex_components/IconButton';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import ClosedFolder from 'app/src/images/folders/ClosedFolder';
import FolderMenuItems from 'app/src/pages/dashboard/pages/libraries/folders_v2/FolderMenuItems';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';
import { track } from 'app/src/helpers/Tracker';
import useHasResourcePermissions from 'app/src/hooks/useHasResourcePermissions';

const FolderCard = ({ folder, numberOfTolstoys, onClick }) => {
  const [isMenuOpen, setMenuOpen] = useState(null);

  const handleMenuOpened = e => {
    track('Folder Menu Click');
    e.stopPropagation();
    setMenuOpen(e.currentTarget);
  };

  const hasMenuPermissions = useHasResourcePermissions(folder);
  return (
    <StyledCard shadowAnimationsEnabled onClick={() => onClick(folder)}>
      <LayoutRoot>
        <LeftContainer>
          <FolderDetails>
            <ClosedFolder />
            <FolderNameContainer>
              <EllipsisTextOverflowWrapper>
                <FolderName title={folder.name}>{folder.name}</FolderName>
              </EllipsisTextOverflowWrapper>
            </FolderNameContainer>
          </FolderDetails>
          <TolstoysText>{numberOfTolstoys} Tolstoys</TolstoysText>
        </LeftContainer>
        {hasMenuPermissions && (
          <>
            <StyledIconButton onClick={handleMenuOpened}>
              <VerticalMenuIcon />
            </StyledIconButton>
            <FolderMenuItems isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} folder={folder} />
          </>
        )}
      </LayoutRoot>
    </StyledCard>
  );
};

export default FolderCard;

const StyledIconButton = styled(IconButton)`
  opacity: 0;
  margin: 8px;
  transition: 0.2s;
`;

const LayoutRoot = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;

  &:hover ${StyledIconButton} {
    opacity: 1;
  }
`;

const StyledCard = styled(Card)`
  border-radius: 12px;
  flex-shrink: 0;
  box-shadow: 0 2px 4px rgba(33, 36, 41, 0.06);

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 4px 8px rgba(33, 36, 41, 0.1);
  }
`;

const TolstoysText = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.ghostDark};
`;

const LeftContainer = styled(Gap16VerticalFlex)`
  padding: 16px;
  overflow: hidden;
`;

const FolderDetails = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  overflow: hidden;
  gap: 12px;
  flex-shrink: 0;
`;

const FolderName = styled(TextBody)`
  font-weight: 700;
`;

const FolderNameContainer = styled.div`
  display: flex;
  overflow: hidden;
`;
