import React from 'react';

type IconProps = React.HTMLAttributes<SVGElement>;

const ExpanderTopArrowIcon = (props: IconProps) => {
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.469 6.469A.746.746 0 0 0 .438 7.53a.746.746 0 0 0 1.062.032l5.469-5.25 5.5 5.25a.746.746 0 0 0 1.062-.032.746.746 0 0 0-.031-1.062l-6-5.75C7.344.594 7.156.5 6.969.5c-.157 0-.344.094-.5.219l-6 5.75Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default ExpanderTopArrowIcon;
