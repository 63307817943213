import React, { useState } from 'react';
import AutoComplete from 'app/src/basic_components/AutoComplete';
import RemoveAnswer from 'app/src/images/remove_answer.svg';
import styled, { useTheme } from 'styled-components';
import Tooltip from 'app/src/basic_components/Tooltip';
import IconButton from 'app/src/complex_components/IconButton';
import { TextTiny } from 'shared/react/components/complex/Text';

const ProductField = ({
  input,
  i,
  setValue,
  products,
  removeProductField,
  loading,
  error,
  isRemovable,
}) => {
  const [inputValue, setInputValue] = useState('');
  const theme = useTheme();

  return (
    <ProductContainer key={input.id}>
      <FieldContainer>
        <CustomAutoComplete
          value={input.title}
          key={i}
          autoComplete
          blurOnSelect
          onChange={(event, newValue) => {
            setValue(newValue, i);
            if (newValue) {
              setInputValue(newValue[2]);
            }
          }}
          inputValue={inputValue}
          disabled={loading}
          options={products}
          inputprops={{ label: `Product ${i + 1}` }}
          getOptionLabel={option => option[2] || ''}
          getOptionSelected={option => option[2] || ''}
          onInputChange={(event, newInputValue) => {
            if (!event || event?.type === 'blur') {
              return;
            }
            setInputValue(newInputValue);
          }}
        />
        {isRemovable && (
          <Tooltip text="Delete choice" placement="bottom">
            <RemoveButtonIcon
              onClick={() => removeProductField(i)}
              src={RemoveAnswer}
              hoverBackground={theme.colors.dangerLight}
              width="48px"
              height="48px"
              disabled={loading}
            />
          </Tooltip>
        )}
      </FieldContainer>
      {error && <ErrorText>{error}</ErrorText>}
    </ProductContainer>
  );
};

const CustomAutoComplete = styled(AutoComplete)`
  width: 100%;
`;

const ProductContainer = styled.div`
  margin-top: 6px;
`;

const FieldContainer = styled.div`
  display: flex;
`;

const RemoveButtonIcon = styled(IconButton)`
  margin: 0 0 0 4px;
`;

const ErrorText = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.error};
  font-weight: 400;
  margin: 4px 2px 0px;
`;

export default ProductField;
