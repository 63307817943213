import React from 'react';

const PauseVideoIcon = props => (
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.5 0h1C3.313 0 4 .688 4 1.5v9A1.5 1.5 0 0 1 2.5 12h-1A1.48 1.48 0 0 1 0 10.5v-9A1.5 1.5 0 0 1 1.5 0Zm6 0h1c.813 0 1.5.688 1.5 1.5v9A1.5 1.5 0 0 1 8.5 12h-1A1.48 1.48 0 0 1 6 10.5v-9A1.5 1.5 0 0 1 7.5 0Z"
      fill="#50545E"
    />
  </svg>
);

export default PauseVideoIcon;
