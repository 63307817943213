import React from 'react';

const FillScreenIcon = ({ fill }) => {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.70312 6.10156C6.77344 6.125 6.84375 6.125 6.9375 6.125H10.3125C10.6172 6.125 10.875 5.89062 10.875 5.5625C10.875 5.25781 10.6172 5 10.3125 5H8.27344L11.4375 1.83594C11.6719 1.625 11.6719 1.27344 11.4375 1.03906C11.2266 0.828125 10.875 0.828125 10.6641 1.03906L7.5 4.20312V2.1875C7.5 1.88281 7.24219 1.625 6.9375 1.625C6.60938 1.625 6.375 1.88281 6.375 2.1875V5.5625C6.375 5.63281 6.375 5.72656 6.39844 5.79688C6.46875 5.91406 6.5625 6.03125 6.70312 6.10156ZM5.27344 6.92188C5.20312 6.89844 5.13281 6.875 5.0625 6.875H1.6875C1.35938 6.875 1.125 7.13281 1.125 7.4375C1.125 7.76562 1.35938 8 1.6875 8H3.70312L0.539062 11.1641C0.304688 11.3984 0.304688 11.75 0.539062 11.9609C0.75 12.1953 1.10156 12.1953 1.3125 11.9609L4.5 8.79688V10.8125C4.5 11.1406 4.73438 11.375 5.0625 11.375C5.36719 11.375 5.625 11.1406 5.625 10.8125V7.4375C5.625 7.36719 5.60156 7.29688 5.57812 7.22656C5.50781 7.08594 5.41406 6.99219 5.27344 6.92188Z"
        fill={fill || '#090A0B'}
      />
    </svg>
  );
};

export default FillScreenIcon;
