import React from 'react';
import styled from 'styled-components';

import { ComparisonOperator, ComparatorEnum } from 'src/pages/dashboard/utils/filterEntities';
import filtersConfig from 'src/pages/dashboard/components/filters/filtersConfig';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import StopIcon from 'src/images/dashboard_v2/StopIcon';
import PlusIcon from 'src/images/PlusIcon';
import { getComparatorText } from 'src/pages/dashboard/components/filters/utils/comparatorUtils';
import { FilterTypeRow } from 'app/src/pages/dashboard/components/filters/components/filter-block/FilterBlock';

type Props = {
  filterKey: string;
  onClose: () => void;
  filter: ComparisonOperator;
  setFilters: (filters: { [key: string]: ComparisonOperator }) => void;
};

const getIcon = (comparator: ComparatorEnum) => {
  if ([ComparatorEnum.notIn, ComparatorEnum.notSatisfies].includes(comparator)) {
    return <StopIcon />;
  }

  return <PlusIcon fill={undefined} size={14} />;
};

const ComparatorMenuContent = ({ filterKey, onClose, filter, setFilters }: Props) => {
  const updateComparator = (comparator: ComparatorEnum) => {
    const value = Object.values(filter)[0];
    setFilters({ [filterKey]: { [comparator]: value } });
    onClose();
  };

  if (!filterKey) {
    return null;
  }

  return (
    <>
      {filtersConfig[filterKey].comparators.map((comparator: ComparatorEnum) => (
        <StyledFilterTypeRow
          icon={getIcon(comparator)}
          key={comparator}
          onClick={() => updateComparator(comparator)}
        >
          {getComparatorText(comparator, filter)}
        </StyledFilterTypeRow>
      ))}
    </>
  );
};

const StyledFilterTypeRow = styled(FilterTypeRow)`
  path {
    fill: ${({ theme }) => theme.colors.neutralBlack};
  }
`;

export default ComparatorMenuContent;
