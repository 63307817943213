import React, { useEffect, useId, useState, useMemo } from 'react';
import AnalyticsMultiSelectItem from './AnalyticsMultiSelectItem';
import ExpanderTopArrowIcon from 'app/src/images/creation_method_icons/ExpanderTopArrowIcon';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import MenuWithContainer from 'shared/react/components/basic/MenuWithContainer';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextBody, TextTiny } from 'shared/react/components/basic/text/TextV2';
import {
  PrimaryButton,
  SecondaryButton,
} from 'shared/react/components/basic/button-v2/BasicButton';
import HorizontalFlex, {
  Gap8HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import BasicInput from 'app/src/basic_components/BasicInput';

type Props<TOption, TValue> = {
  getOptionId: (option: TOption) => string;
  getOptionLabel: (option: TOption) => React.ReactNode;
  getOptionValue: (option: TOption) => TValue;
  label: string;
  onCancel?: () => void;
  onConfirm: (value: TValue[]) => void;
  options: TOption[];
  value: TValue[];
};

const AnalyticsMultiSelect = <TOption, TValue>({
  getOptionId,
  getOptionLabel,
  getOptionValue,
  label,
  onCancel,
  onConfirm,
  options,
  value,
}: Props<TOption, TValue>) => {
  const menuId = useId();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState<Record<string, boolean>>({});
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const newSelectedOptions = options.reduce((acc, option) => {
      acc[getOptionId(option)] = value.includes(getOptionValue(option));
      return acc;
    }, {});

    setSelectedOptions(newSelectedOptions);
  }, [value, options, getOptionId, getOptionValue]);

  const filteredAndSortedOptions = useMemo(() => {
    return options
      .filter(option =>
        getOptionLabel(option).toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
      .sort((a, b) => {
        const aSelected = selectedOptions[getOptionId(a)];
        const bSelected = selectedOptions[getOptionId(b)];
        if (aSelected === bSelected) {
          return 0;
        }

        return aSelected ? -1 : 1;
      });
  }, [options, selectedOptions, getOptionId, getOptionLabel, searchQuery]);

  const handleMenuOpen = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSearchQuery('');
  };

  const handleSelectAll = () => {
    setSelectedOptions(
      options.reduce((acc, option) => {
        acc[getOptionId(option)] = true;
        return acc;
      }, {})
    );
  };

  const handleClearAll = () => {
    setSelectedOptions({});
  };

  const handleConfirm = () => {
    const newValue = options
      .filter(option => selectedOptions[getOptionId(option)])
      .map(getOptionValue);

    handleMenuClose();
    onConfirm(newValue);
  };

  const handleCancel = () => {
    handleMenuClose();
    onCancel?.();
  };

  const handleItemClick = (option: TOption) => {
    const id = getOptionId(option);

    setSelectedOptions({
      ...selectedOptions,
      [id]: !selectedOptions[id],
    });
  };

  const getDisplayValue = () => {
    if (value.length === options.length) {
      return 'All';
    }

    if (value.length > 1) {
      return `${value.length} selected`;
    }

    const option = options.find(option => value.includes(getOptionValue(option)));
    if (option) {
      return getOptionLabel(option);
    }

    return value[0];
  };

  const items = filteredAndSortedOptions.map(option => {
    const handleClick = () => handleItemClick(option);
    const id = getOptionId(option);
    const label = getOptionLabel(option);
    const selected = selectedOptions[id] === true;

    return (
      <AnalyticsMultiSelectItem key={id} selected={selected} onClick={handleClick}>
        {label}
      </AnalyticsMultiSelectItem>
    );
  });

  return (
    <>
      <ValueContainer>
        <Label as="label" htmlFor={menuId}>
          {label}
        </Label>
        <Gap8HorizontalFlex onClick={handleMenuOpen}>
          <ValueText>{getDisplayValue()}</ValueText>
          <Icon $open={!!anchorEl} />
        </Gap8HorizontalFlex>
      </ValueContainer>
      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={handleMenuClose}>
        <MenuContent>
          <TopActionsContainer>
            <TextButton onClick={handleSelectAll}>Select all</TextButton>
            <span>-</span>
            <TextButton onClick={handleClearAll}>Clear</TextButton>
          </TopActionsContainer>
          <SearchContainer>
            <StyledBasicInput
              type="text"
              placeholder="Search options..."
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
            />
          </SearchContainer>
          <ItemsContainer>{items}</ItemsContainer>
          <BottomActionsContainer>
            <SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
            <PrimaryButton onClick={handleConfirm}>Select</PrimaryButton>
          </BottomActionsContainer>
        </MenuContent>
      </Menu>
    </>
  );
};

const ValueContainer = styled(Gap8VerticalFlex)`
  cursor: pointer;
`;

const ValueText = styled(TextBody)`
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
`;

const Label = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralDark};
`;

const Icon = styled(ExpanderTopArrowIcon)<{ $open: boolean }>`
  margin-top: 4px;
  transform: ${({ $open }) => ($open ? 'rotate(0)' : 'rotate(180deg)')};
  width: 12px;
`;

const Menu = styled(MenuWithContainer)`
  border-radius: 16px;
  box-shadow:
    0 10px 25px rgba(50, 50, 93, 0.1),
    0 5px 10px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  margin-top: 8px;
`;

const MenuContent = styled(VerticalFlex)`
  background: ${({ theme }) => theme.colors.white};
  max-height: 380px;
  max-width: 300px;
  padding: 8px;
`;

const TopActionsContainer = styled(HorizontalFlex)`
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  margin: 8px 16px 0;
`;

const SearchContainer = styled.div`
  padding: 8px 16px;
`;

const ItemsContainer = styled.div`
  flex: 1 1 auto;
  overflow-y: scroll;
`;

const BottomActionsContainer = styled(HorizontalFlex)`
  justify-content: space-between;
  padding: 16px 8px 8px;
`;

const TextButton = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`;

const StyledBasicInput = styled(BasicInput)`
  background: white;
  min-height: 40px;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray3};
  border-radius: 8px;
`;

export default AnalyticsMultiSelect;
