import React, { useState } from 'react';
import styled from 'styled-components';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import InstallStepSimple from 'app/src/pages/project/pages/installation/components/shared/InstallStepSimple';
import useTvInstallSteps from './useTvInstallSteps';
import { useAppActions } from 'app/src/context/AppsStore';
import useAccountInstallation from 'app/src/hooks/useAccountInstallation';
import InstallationTitle from 'app/src/pages/project/pages/installation/components/shared/InstallationTitle';
import { project as Project } from 'src/types/entities';

type Props = {
  project: Project;
};

const TvInstallation = ({ project }: Props) => {
  const [showManualInstallCode, setShowManualInstallCode] = useState(false);
  const { getAppUsingUrl } = useAppActions();
  const currentApp = getAppUsingUrl(project.appUrl);
  const [{ isInstallationCompleted: isAccountInstallationCompleted }] = useAccountInstallation(
    currentApp?.id
  );
  const steps = useTvInstallSteps(project, !isAccountInstallationCompleted, showManualInstallCode);

  return (
    <LayoutRoot>
      <InstallationTitle
        setShowManualInstallCode={setShowManualInstallCode}
        showManualInstallCode={showManualInstallCode}
        isAccountInstallationCompleted={isAccountInstallationCompleted}
      />
      <StepsContainer>
        {steps.map((step, index) => (
          <InstallStepSimple
            {...step}
            stepNumber={index + 1}
            key={step.stepHeader}
            project={project}
          />
        ))}
      </StepsContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)``;

const StepsContainer = styled(Gap8VerticalFlex)``;

export default TvInstallation;
