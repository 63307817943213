import React from 'react';
import styled from 'styled-components';
import HorizontalFlex, {
  Gap8HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextSmall } from 'shared/react/components/basic/text/TextV2';
import { useTopBarContext } from 'app/src/context/TopBarStore';
import { GhostButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { useAccount } from 'app/src/context/AccountStore';
import { useProjects } from 'app/src/context/ProjectsStore';
import { track } from 'app/src/helpers/Tracker';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import {
  MOVE_TOLSTOY_MODAL_KEY,
  DELETE_TOLSTOY_MODAL_KEY,
} from 'app/src/constants/modals.constants';
import Utils from 'app/src/utils';
import FilledTrashIcon from 'app/src/images/sidebar-v2/FilledTrashIcon';
import FilledFolderIcon from 'app/src/images/dashboard_v2/FilledFolderIcon';
import { TopBarButtonProps } from './types/TopBar.types';
import TopBarActionButton from './TopBarActionButton';
import { FolderType } from 'app/src/types/entities';

const ProjectsMultiSelectTopBar = () => {
  const { projectsMultiSelectState } = useTopBarContext();
  const [{ isAccountOwner }] = useAccount();
  const [{ projects }] = useProjects();
  const [, { setCurrentModal, setModalProps }] = useModal();
  const { selectedIds, isOnsite, onCancel, selectAll } = projectsMultiSelectState || {};

  const selectedProjects = projects.filter(({ id }) => selectedIds.includes(id));

  const canDeleteTolstoys =
    selectedProjects.every(({ owner }) => owner === Utils.getOwner()) || isAccountOwner;

  const onDelete = () => {
    if (!canDeleteTolstoys) {
      return;
    }

    track('Multiple Tolstoys Delete Click', { numberOfVideos: selectedIds.length });
    setCurrentModal(DELETE_TOLSTOY_MODAL_KEY);
    setModalProps({ ids: selectedIds });
    onCancel();
  };

  const onMoveToFolder = () => {
    const folderType = isOnsite ? FolderType.onsites : FolderType.projects;
    track('Multiple Tolstoys Move Click', { numberOfVideos: selectedIds.length });
    setCurrentModal(MOVE_TOLSTOY_MODAL_KEY);
    setModalProps({ ids: selectedIds, type: folderType });
    onCancel();
  };

  const selectButtons: TopBarButtonProps[] = [
    {
      key: 'select-all',
      children: <span>Select all</span>,
      onClick: selectAll,
      iconOnly: false,
      buttonComponent: StyledGhostButton,
      hidden: false,
    },
    {
      key: 'clear-all',
      children: <span>Clear all</span>,
      onClick: onCancel,
      iconOnly: false,
      buttonComponent: StyledGhostButton,
      hidden: false,
    },
  ];

  const actionButtons: TopBarButtonProps[] = [
    {
      key: 'delete',
      children: <FilledTrashIcon />,
      tooltipText: canDeleteTolstoys
        ? `Delete (${selectedIds.length})`
        : `Can't delete Tolstoys due to a selected Tolstoy created by another user`,
      onClick: onDelete,
      iconOnly: true,
      buttonComponent: StyledGhostButton,
      hidden: false,
      disabled: !canDeleteTolstoys,
    },
    {
      key: 'move-to-folder',
      children: <FilledFolderIcon />,
      tooltipText: `Move to folder (${selectedIds.length})`,
      onClick: onMoveToFolder,
      iconOnly: true,
      buttonComponent: StyledGhostButton,
    },
    {
      key: 'cancel',
      children: 'Cancel',
      onClick: onCancel,
      iconOnly: false,
      buttonComponent: StyledGhostButton,
      hidden: false,
    },
  ];

  return (
    <LayoutRoot>
      <Title>{selectedIds.length} selected</Title>
      <ButtonsContainer>
        {selectButtons.map(button => (
          <TopBarActionButton key={button.key} {...button} />
        ))}
      </ButtonsContainer>
      <Spacer />
      <ButtonsContainer>
        {actionButtons.map(button => (
          <TopBarActionButton key={button.key} {...button} />
        ))}
      </ButtonsContainer>
    </LayoutRoot>
  );
};

const Spacer = styled.div`
  flex-grow: 1;
`;

const LayoutRoot = styled(HorizontalFlex)`
  background: ${({ theme }) => theme.colors.neutralDeep};
  padding: 16px 24px;
  height: 64px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutralLight};
  column-gap: 10px;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    gap: 8px;
  }
`;

const Title = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.neutralGray};
`;

const ButtonsContainer = styled(Gap8HorizontalFlex)``;

const StyledGhostButton = styled(GhostButton)`
  color: ${({ theme }) => theme.colors.neutralLight};
  background-color: ${({ theme, disabled }) =>
    disabled && `${theme.colors.neutralDarker} !important`};

  & path {
    fill: ${({ theme }) => theme.colors.neutralLight};
  }
`;

export default ProjectsMultiSelectTopBar;
