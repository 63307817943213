import React from 'react';

const StockVideosIcon = () => {
  return (
    <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.625 15.125V3.25C1.57 3.25.75 4.11.75 5.125V15.75c0 1.406 1.094 2.5 2.5 2.5h14.375c1.016 0 1.875-.82 1.875-1.875H3.875c-.703 0-1.25-.547-1.25-1.25ZM20.75.75H7a2.492 2.492 0 0 0-2.5 2.5V12c0 1.406 1.094 2.5 2.5 2.5h13.75c1.367 0 2.5-1.094 2.5-2.5V3.25c0-1.367-1.133-2.5-2.5-2.5ZM8.875 12.625H7A.617.617 0 0 1 6.375 12v-1.563h2.5v2.188Zm0-4.063h-2.5V6.688h2.5v1.875Zm0-3.75h-2.5V3.25c0-.313.273-.625.625-.625h1.875v2.188ZM17 12.625h-6.25V8.562H17v4.063Zm0-5.938h-6.25V2.625H17v4.063ZM21.375 12a.642.642 0 0 1-.625.625h-1.875v-2.188h2.5V12Zm0-3.438h-2.5V6.688h2.5v1.875Zm0-3.75h-2.5V2.626h1.875c.313 0 .625.313.625.625v1.563Z"
        fill="#50545E"
      />
    </svg>
  );
};

export default StockVideosIcon;
