import React from 'react';

const ReplaceIcon = () => (
  <svg width={16} height={14} viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.1875 10.125C8.49219 10.125 8.75 10.3828 8.75 10.6875C8.75 11.0156 8.49219 11.25 8.1875 11.25H3.5C3.17188 11.25 2.9375 11.0156 2.9375 10.6875V4.92188L1.4375 6.39844C1.22656 6.63281 0.875 6.63281 0.664062 6.39844C0.429688 6.1875 0.429688 5.83594 0.664062 5.625L3.10156 3.1875C3.3125 2.95312 3.66406 2.95312 3.875 3.1875L6.3125 5.625C6.54688 5.83594 6.54688 6.1875 6.3125 6.39844C6.21875 6.51562 6.07812 6.5625 5.9375 6.5625C5.77344 6.5625 5.63281 6.51562 5.53906 6.39844L4.0625 4.92188V10.125H8.1875ZM15.3125 8.60156C15.5469 8.83594 15.5469 9.1875 15.3125 9.39844L12.875 11.8359C12.6641 12.0703 12.3125 12.0703 12.1016 11.8359L9.66406 9.39844C9.42969 9.1875 9.42969 8.83594 9.66406 8.625C9.75781 8.50781 9.89844 8.4375 10.0625 8.4375C10.2031 8.4375 10.3438 8.50781 10.4375 8.625L11.9375 10.1016V4.875H7.8125C7.48438 4.875 7.25 4.64062 7.25 4.3125C7.25 4.00781 7.48438 3.75 7.8125 3.75H12.5C12.8047 3.75 13.0625 4.00781 13.0625 4.3125V10.1016L14.5156 8.60156C14.7266 8.39062 15.0781 8.39062 15.3125 8.60156Z"
      fill="#50545E"
    />
  </svg>
);

export default ReplaceIcon;
