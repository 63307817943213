import React from 'react';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import PricingBanner from 'app/src/complex_components/billings/badges/PricingBanner';
import Utils from 'app/src/utils';
import NotificationListItem from 'app/src/complex_components/notifications/NotificationListItem';

function NotificationsLockedState() {
  return (
    <LayoutRoot>
      <StyledPricingBanner
        text="NOTIFICATIONS"
        analyticsData={Utils.getAnalyticsLocationObject('Notifications Banner')}
      />
      {Array(100)
        .fill('')
        .map((e, i) => (
          <NotificationListItem key={i} notification={{}} isFeatureDisabled={true} />
        ))}
    </LayoutRoot>
  );
}

export default NotificationsLockedState;

const LayoutRoot = styled(VerticalFlex)``;

const StyledPricingBanner = styled(PricingBanner)`
  margin: 0 16px 8px;
`;
