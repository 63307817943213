import React from 'react';
import styled from 'styled-components';
import ReplyBar from 'shared/react/components/complex/inbox/ReplyBar';

const CLPPreview = () => {
  const onVideoReply = () => {};
  const onScreenRecording = () => {};
  const onAudioReply = () => {};

  return (
    <LayoutRoot
      onScreenRecording={onScreenRecording}
      onVideoReply={onVideoReply}
      onAudioReply={onAudioReply}
      shouldInputAutoFocus={false}
    />
  );
};

const LayoutRoot = styled(ReplyBar)`
  padding: 0px;
`;

export default CLPPreview;
