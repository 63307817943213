import React from 'react';

const CartIcon = () => (
  <svg
    width={16}
    height={14.238}
    viewBox="0 0 16 14.238"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.978 8.862H5.859l.182.886h7.434c.426 0 .742.396.648.812l-.153.672a1.55 1.55 0 1 1-2.227 1.438 1.546 1.546 0 0 1 .466-1.15H6.402a1.546 1.546 0 0 1 .466 1.108 1.55 1.55 0 1 1-2.322-1.346L2.6 1.773H.665A.665.665 0 0 1 0 1.108V.665A.665.665 0 0 1 .665 0h2.839a.665.665 0 0 1 .651.531l.254 1.241h10.878c.426 0 .742.396.648.812l-1.31 5.76a.665.665 0 0 1-.648.518ZM11.3 4.652H9.97V3.545a.443.443 0 0 0-.443-.443h-.443a.443.443 0 0 0-.443.443v1.108h-1.33a.443.443 0 0 0-.443.443v.443a.443.443 0 0 0 .443.443h1.33V7.09a.443.443 0 0 0 .443.443h.443a.443.443 0 0 0 .443-.443V5.982h1.33a.443.443 0 0 0 .443-.443v-.443a.443.443 0 0 0-.443-.443Z"
      fill="#50545E"
    />
  </svg>
);

export default CartIcon;
