import { createActionsHook, createContainer, createHook, createStore } from 'react-sweet-state';
import { getRequest, graphqlRequest, postRequest } from '../helpers/API';
import { API, graphqlOperation } from 'aws-amplify';
import Utils from '../utils';
import {
  createNotificationSettings,
  deleteNotificationSettings,
  updateAccountTeamMember,
  updateNotificationSettings,
} from '../graphql/mutations';
import { APP_KEY_LOCAL_STORAGE_KEY, CUBE_JS_KEY } from '../constants/tolstoy.constants';
import * as Sentry from '@sentry/react';
import { getIsFeatureEnabled } from './FeaturesStore';
import {
  FEATURE_APP_PRICING,
  FEATURE_CREATE_WORKSPACE,
} from 'app/src/constants/appFeatures.constants';
import {
  customAccountTeamMembersByAppKey,
  customAccountTeamMembersByUserId,
} from '../graphql/custom_queries';
import { getNotificationSettings } from '../graphql/queries';
import { onCreateInAppNotificationByTeamMemberId } from '../graphql/subscriptions';
import { sendExtensionSignInEvent } from '../helpers/Extension';
import { TEAM_MEMBERS_ROLES } from 'app/src/constants/teamMembers.constants';

const initialState = {
  teamMembers: [],
  workspaces: [],
  loading: false,
  isAccountOwner: false,
  isNotificationListOpen: false,
  error: null,
  subscription: null,
};

const normalizeTeamMembers = teamMembers => {
  return teamMembers.map(member => {
    return { ...member, user: member.user.items[0], account: member.account.items[0] };
  });
};

const getTeamMembers = async appKey => {
  const { data } = await graphqlRequest(
    graphqlOperation(customAccountTeamMembersByAppKey, { appKey })
  );
  const teamMembers = data?.accountTeamMembersByAppKey?.items || [];

  const filteredTeamMembers = normalizeTeamMembers(teamMembers);
  return filteredTeamMembers;
};

const getAccountName = async (name, appKey) => {
  if (name) {
    return name;
  }

  const accountTeamMembersResponse = await graphqlRequest(
    graphqlOperation(customAccountTeamMembersByAppKey, { appKey })
  );

  const accountTeamMembers = accountTeamMembersResponse.data.accountTeamMembersByAppKey.items;
  const accountOwner = accountTeamMembers.find(({ role }) => role === 'owner');

  const accountOwnerName = accountOwner.email.split('@')[0];
  return Utils.capitalizeFirstLetter(accountOwnerName);
};

export const validateInvite = async inviteToken => {
  let isValid = false;

  if (inviteToken) {
    try {
      ({ isValid } = await postRequest('user-actions', '/actions/users/validate-invite', {
        body: {
          inviteToken,
        },
      }));
    } catch (error) {
      Utils.logError('invite not valid', error);
      isValid = false;
    }
  }

  if (!isValid) {
    Utils.clearInvite();
  }

  return isValid;
};

export const fetchCurrentAccountDetails = async (userId, loginAppKey) => {
  const response = await graphqlRequest(
    graphqlOperation(customAccountTeamMembersByUserId, {
      userId,
      sortDirection: 'DESC',
    })
  );

  const accounts = normalizeTeamMembers(response?.data?.accountTeamMembersByUserId?.items);

  if (!accounts?.length || !accounts[0].appKey) {
    Sentry.captureMessage(`could not fetch ${userId} account`);
    return;
  }

  let currentAccount;

  if (loginAppKey) {
    currentAccount = accounts.find(account => account.appKey === loginAppKey);
  }

  if (!currentAccount) {
    [currentAccount] = accounts;
  }

  const { appKey, role } = currentAccount;

  localStorage.setItem(APP_KEY_LOCAL_STORAGE_KEY, appKey);
  if (!Utils.isGhostAccount()) {
    sendExtensionSignInEvent();
  }

  setAccountLoginDate(currentAccount);

  return { appKey, isAccountOwner: role === 'owner', accounts };
};

export const fetchNotificationSettings = async (id, owner) => {
  const response = await graphqlRequest(
    graphqlOperation(getNotificationSettings, {
      id,
      owner,
      sortDirection: 'DESC',
    })
  );

  return response?.data?.getNotificationSettings;
};

export const getCubejsToken = async () => {
  try {
    const { cubejsToken } = await getRequest(
      'user-actions',
      `/actions/users/cube-security-token?appKey=${Utils.getAppKey()}`
    );
    if (cubejsToken) {
      localStorage.setItem(CUBE_JS_KEY, cubejsToken);
    }
  } catch (error) {
    Utils.logError('cannot get cubejs token', error);
  }
};

export const createOrUpdateNotificationSettings = async (id, input) => {
  const response = id
    ? await graphqlRequest(
        graphqlOperation(updateNotificationSettings, {
          input: {
            id,
            ...input,
          },
        })
      )
    : await graphqlRequest(graphqlOperation(createNotificationSettings, { input }));

  return response?.data?.[id ? 'updateNotificationSettings' : 'createNotificationSettings'];
};

export const removeNotificationSettings = async id => {
  await graphqlRequest(
    graphqlOperation(deleteNotificationSettings, {
      input: {
        id,
      },
    })
  );
};

const setAccountLoginDate = async ({ id }) => {
  const lastLoginAt = new Date().toISOString();
  return graphqlRequest(graphqlOperation(updateAccountTeamMember, { input: { id, lastLoginAt } }));
};

const actions = {
  getTeamMemberById:
    id =>
    ({ getState }) => {
      const { teamMembers } = getState();

      return teamMembers.find(member => member.id == id);
    },
  fetchTeamMembers:
    () =>
    async ({ setState }, { appKey }) => {
      try {
        const teamMembers = await getTeamMembers(appKey);

        setState({ teamMembers, loading: false });
      } catch (error) {
        console.error(error);
        setState({ error, loading: false });
      }
    },
  deleteTeamMember:
    invite =>
    async ({ setState, getState }) => {
      try {
        await postRequest('user-actions', '/actions/users/delete', {
          body: {
            id: invite.id,
          },
        });
        const { teamMembers } = getState();
        const newTeamMembers = teamMembers.filter(({ id }) => id !== invite.id);

        setState({ teamMembers: newTeamMembers });
      } catch (error) {
        console.error(error);
        setState({ error });
      }
    },
  updateTeamMember:
    (id, input) =>
    async ({ setState, getState }) => {
      try {
        const response = await graphqlRequest(
          graphqlOperation(updateAccountTeamMember, { input: { id, ...input } })
        );
        const newTeamMember = response?.data?.updateAccountTeamMember;
        const { teamMembers } = getState();
        const newTeamMembers = teamMembers.map(teamMember =>
          teamMember.id === newTeamMember.id ? newTeamMember : teamMember
        );

        setState({ teamMembers: newTeamMembers });
      } catch (error) {
        console.error(error);
        setState({ error });
      }
    },
  sendInvites:
    ({ emails, message, paymentRole }) =>
    async ({ setState }) => {
      try {
        const user = Utils.getUser();

        const response = await postRequest('user-actions', '/actions/users/invite', {
          body: {
            appKey: Utils.getAppKey(),
            emails,
            userEmail: user.email,
            message,
            paymentRole,
          },
        });
        return response;
      } catch (e) {
        Utils.logError('Sending invites error ', e);
        console.log(e);
        setState({ loading: false });
        return null;
      }
    },
  resendInvite: id => async () => {
    try {
      const response = await postRequest('user-actions', '/actions/users/resend-invite', {
        body: {
          id,
        },
      });
      return response;
    } catch (e) {
      Utils.logError('Resend invite error', e);
      console.log('Resend invite error ', JSON.stringify(e));
      return null;
    }
  },
  updateAccountLoginDate: account => () => {
    if (Utils.isGhostAccount()) {
      return;
    }

    return setAccountLoginDate(account);
  },
  fetchWorkspaces:
    () =>
    async ({ setState }, { accounts, isAccountOwner }) => {
      setState({ loading: true, isAccountOwner });

      const workspaces = await Promise.all(
        accounts?.map(async ({ id, appKey, account: { logoSettings, name: accountName } }) => {
          const name = await getAccountName(accountName, appKey);
          return { id, appKey, name, logoSettings };
        })
      );
      setState({ loading: false, workspaces });
    },
  getCurrentWorkspace:
    () =>
    ({ getState }, { appKey }) => {
      const { workspaces } = getState();

      return workspaces?.find(myWorkspace => myWorkspace.appKey === appKey);
    },
  getNotCurrentWorkspaces:
    () =>
    ({ getState }) => {
      const appKey = Utils.getAppKey();
      const { workspaces } = getState();

      return workspaces?.filter(workspace => workspace.appKey !== appKey);
    },
  acceptInvite: inviteToken => async () => {
    if (inviteToken) {
      try {
        await postRequest('user-actions', '/actions/users/accept-invite', {
          body: {
            inviteToken,
          },
        });
      } catch (error) {
        Utils.logError('cannot accept invite', error);
      } finally {
        Utils.clearInvite();
      }
    }
  },
  createWorkspace:
    workspaceName =>
    async (state, { appKey, features }) => {
      if (!getIsFeatureEnabled(features, FEATURE_CREATE_WORKSPACE)) {
        return false;
      }

      try {
        await postRequest('account-actions', '/actions/accounts/workspace', {
          body: {
            appKey,
            name: workspaceName,
          },
        });
        return true;
      } catch (e) {
        Utils.logError('Create workspace failed ', e);
        return false;
      }
    },
  findUser:
    userId =>
    ({ getState }) => {
      const { teamMembers } = getState();
      return teamMembers.find(teamMember => {
        return teamMember.userId === userId;
      });
    },
  updatePaymentRole:
    ({ teamMembersToUpdateMap }) =>
    async ({ getState, setState }, { appKey, features }) => {
      if (!getIsFeatureEnabled(features, FEATURE_APP_PRICING)) {
        return;
      }

      const { returnUrl } = await postRequest(
        'user-actions',
        '/actions/users/update-payment-role',
        {
          body: {
            teamMembersToUpdateMap,
            appKey,
          },
        }
      );

      const { teamMembers: oldTeamMembers } = getState();

      const newTeamMembers = oldTeamMembers.map(teamMember => {
        const newPaymentRole = teamMembersToUpdateMap[teamMember.id];
        if (newPaymentRole) {
          return {
            ...teamMember,
            paymentRole: newPaymentRole,
          };
        }

        return teamMember;
      });
      setState({ teamMembers: newTeamMembers });

      return returnUrl;
    },
  toggleNotificationListOpen:
    isOpen =>
    ({ getState, setState }) => {
      const { isNotificationListOpen } = getState();
      setState({ isNotificationListOpen: isOpen ?? !isNotificationListOpen });
    },
  subscribeNotificationCreated:
    accountTeamMemberId =>
    ({ setState, getState }) => {
      try {
        const subscription = API.graphql(
          graphqlOperation(onCreateInAppNotificationByTeamMemberId, {
            teamMemberId: accountTeamMemberId,
          })
        ).subscribe({
          next: () => {
            const { teamMembers, isNotificationListOpen } = getState();

            if (isNotificationListOpen) {
              return;
            }

            const newTeamMembers = [...teamMembers];
            const currentTeamMember = newTeamMembers?.find(
              ({ teamMemberId }) => teamMemberId === accountTeamMemberId
            );

            if (currentTeamMember) {
              currentTeamMember.unreadInAppNotificationsCounter++;
              setState({ teamMembers: newTeamMembers });
            }
          },
          error: ({ error: { errors = [] } = {} }) => {
            Utils.logErrorMessage(
              errors[0]?.message || 'Error in onUpdateAccountSettings subscribe'
            );
          },
        });

        setState({ subscription });
      } catch (error) {
        Utils.logError('Cannot subscribe to accountSettings', error);
      }
    },
  unsubscribeNotificationCreated:
    () =>
    ({ getState, setState }) => {
      const { subscription } = getState();

      if (!subscription) {
        return;
      }

      subscription?.unsubscribe();

      setState({ subscription: null });
    },
  setInAppNotificationsPanelOpened:
    accountTeamMemberId =>
    async ({ getState, setState }) => {
      try {
        if (Utils.isGhostAccount()) {
          return;
        }

        const { teamMembers } = getState();

        const newTeamMembers = [...teamMembers];
        const currentTeamMember = newTeamMembers?.find(({ id }) => id === accountTeamMemberId);

        if (!currentTeamMember?.unreadInAppNotificationsCounter) {
          return;
        }

        if (currentTeamMember) {
          currentTeamMember.unreadInAppNotificationsCounter = 0;
          setState({ teamMembers: newTeamMembers });
        }

        await graphqlRequest(
          graphqlOperation(updateAccountTeamMember, {
            input: {
              id: accountTeamMemberId,
              unreadInAppNotificationsCounter: 0,
            },
          })
        );
      } catch (error) {
        console.error(error);
      }
    },
  transferAccountOwnership:
    teamMember =>
    async ({ setState, getState }) => {
      try {
        await postRequest('account-actions', '/actions/accounts/change-owner', {
          body: {
            appKey: teamMember.appKey,
            newOwnerId: teamMember.userId,
          },
        });
        const { teamMembers } = getState();
        const updatedTeamMembers = teamMembers.map(member => {
          if (teamMember.id === member.id) {
            return { ...member, role: TEAM_MEMBERS_ROLES.owner };
          }

          return { ...member, role: TEAM_MEMBERS_ROLES.admin };
        });

        setState({ teamMembers: updatedTeamMembers });
      } catch (err) {
        Utils.logError(err);
        return err;
      }
    },
  setError:
    error =>
    ({ setState }) => {
      setState({ error });
    },
  getTeamMembers:
    () =>
    ({ getState }) => {
      const { teamMembers } = getState();
      return teamMembers;
    },
  getAccountAppKey:
    () =>
    (_, { appKey }) => {
      return appKey;
    },
};

const AccountStore = createStore({ initialState, actions, name: 'Account' });

export const useAccount = createHook(AccountStore);
export const useTeamMembers = createHook(AccountStore, { selector: state => state.teamMembers });

export const useAccountActions = createActionsHook(AccountStore);

export const AccountContainer = createContainer(AccountStore, {
  onInit:
    () =>
    ({ dispatch }) => {
      dispatch(actions.fetchWorkspaces());
      dispatch(actions.fetchTeamMembers());
    },
  onCleanup:
    () =>
    ({ dispatch }) => {
      dispatch(actions.unsubscribeNotificationCreated());
    },
});
