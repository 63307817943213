import React, { useState } from 'react';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import AreYouSureModal from 'app/src/pages/modals/AreYouSureModal';
import { useProjects } from 'app/src/context/ProjectsStore';
import Utils from 'app/src/utils';
import { useQuiz } from 'app/src/context/QuizStore';

const DeleteStepModal = () => {
  const [, { deleteProjectStep, publishProject }] = useProjects();
  const [
    {
      modalProps: { stepId, project },
    },
    { setCurrentModal, setModalProps },
  ] = useModal();
  const [, { updateQuiz }] = useQuiz();

  const [loading, setLoading] = useState(false);

  const onClose = () => {
    setCurrentModal('');
    setModalProps({});
  };

  const deleteStep = async () => {
    setLoading(true);
    const newProject = await deleteProjectStep({ id: stepId }, project);
    await publishProject(newProject.id);
    if (Utils.isQuiz(project?.tolstoyType)) {
      await updateQuiz(project);
    }
    setLoading(false);
    onClose();
  };

  return (
    <AreYouSureModal
      open={true}
      loading={loading}
      titleText="This part will be deleted permanently. Are you sure?"
      secondaryButtonClicked={onClose}
      actionButtonClicked={deleteStep}
    />
  );
};

export default DeleteStepModal;
