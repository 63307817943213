import React from 'react';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextSmall } from 'shared/react/components/complex/Text';
import BasicSelect from 'shared/react/components/basic/basic_select/BasicSelect';
import useKeyboardNavigation from 'player/src/hooks/useKeyboardNavigation';
import {
  PRODUCT_OPTION_DATA_TYPE,
  PRODUCT_OPTION_HEADER_DATA_TYPE,
} from 'shared/react/constants/feedPreviewDataTypes.constants';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';

const FeedProductOptionSelect = ({
  name,
  values,
  selectedValue,
  handleProductOptionSelected,
  optionPosition,
}) => {
  const {
    player: { feedCards },
  } = useDesignSettingsContext();
  const { onKeyDown, setNextRef } = useKeyboardNavigation();

  const { bodyColorText } = feedCards.text;
  const { radius: borderRadius } = feedCards.buttonsDesign.primary.border;

  return (
    <LayoutRoot data-type={PRODUCT_OPTION_DATA_TYPE}>
      <StyledTextSmall data-type={PRODUCT_OPTION_HEADER_DATA_TYPE} bodyColorText={bodyColorText}>
        {name}
      </StyledTextSmall>
      <StyledBasicSelect
        items={values}
        value={selectedValue}
        onChange={value => handleProductOptionSelected(optionPosition, value)}
        inputBoxSizing="border-box"
        borderRadius={borderRadius}
        itemFontColor={bodyColorText}
        bodyColorText={bodyColorText}
        onKeyDown={onKeyDown}
        setNextRef={setNextRef}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  gap: 4px;
`;

const StyledTextSmall = styled(TextSmall)`
  font-weight: 700;
  color: ${({ bodyColorText, theme }) => bodyColorText || theme.colors.black};
`;

const StyledBasicSelect = styled(BasicSelect)`
  && > div > div {
    color: ${({ bodyColorText, theme }) => bodyColorText || theme.colors.black};
  }
`;

export default FeedProductOptionSelect;
