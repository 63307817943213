import { FC } from "preact/compat";
import { TextCustomization } from "../types/config";
import { createStyleObject } from "../utils";

type Props = TextCustomization & {
  isMobile: boolean;
};

const OverlayDescriptionText: FC<Props> = ({ text, isMobile, ...props }) => {
  if (!text) {
    return null;
  }

  const style = createStyleObject(isMobile, props);

  const formattedText = text.split("\n").map((line, index) => {
    return (
      <div style={style} key={index}>
        {line}
      </div>
    );
  });

  return (
    <div style={style} className="hero-overlay-description">
      {formattedText}
    </div>
  );
};

export default OverlayDescriptionText;
