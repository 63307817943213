import React from 'react';
import AnalyticsMetrics from '../AnalyticsMetrics';
import { useAnalytics } from 'src/context/AnalyticsStore';
import { DesignSizes } from 'src/types/design-system';
import useAnalyticsOverview from 'src/hooks/useAnalyticsOverview';

const TOP_METRICS = [
  {
    id: 'fmtVideoWatched',
    color: 'neutralBlack',
    formatted: true,
    mainTitle: 'Video Plays',
  },
  {
    id: 'fmtAverageVideosWatchedPerSession',
    color: 'neutralBlack',
    formatted: true,
    mainTitle: 'Average Videos Watched',
    headerTooltipText: 'Average number of videos watched per session',
  },
];

const AnalyticsVideosMetrics = () => {
  const [{ isLoadingVideoMetrics }] = useAnalytics();
  const { fmtVideoWatched, fmtAverageVideosWatchedPerSession } = useAnalyticsOverview();

  return (
    <AnalyticsMetrics
      size={DesignSizes.SMALL}
      data={{ fmtVideoWatched, fmtAverageVideosWatchedPerSession }}
      metrics={TOP_METRICS}
      loading={isLoadingVideoMetrics}
      header={undefined}
      shouldFormatWithLetter={undefined}
      isUnique={undefined}
    />
  );
};

export default AnalyticsVideosMetrics;
