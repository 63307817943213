import React from 'react';
import { useExtension } from 'app/src/context/ui_store/ExtensionStore';
import styled from 'styled-components';
import { TextBody, TextH6 } from 'shared/react/components/complex/Text';
import ExtensionIcon from 'app/src/images/ExtensionIcon';
import ExtensionPin from 'app/src/images/extensionPin.png';
import TolstoyIconName from 'app/src/images/TolstoyIconName';
import Popup from './Popup';

const InstallExtensionModal = () => {
  const [{ extensionInstalledModalOpen }, { toggleExtensionInstallModal }] = useExtension();

  const onClose = () => {
    toggleExtensionInstallModal(false);
  };

  return (
    <Popup open={extensionInstalledModalOpen}>
      <TolstoyIconName />
      <Header>Tolstoy extension was successfully installed.</Header>
      <CloseModal onClick={onClose}>✕</CloseModal>
      <Text>
        Click on this icon
        <ExtensionIconSpan>
          <ExtensionIcon />
        </ExtensionIconSpan>
        to pin Tolstoy extension.
      </Text>
      <Img src={ExtensionPin} alt="pin the extension" />
    </Popup>
  );
};

const CloseModal = styled.div`
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 16px;
  font-weight: 600;
  color: black;
  z-index: 101;
`;

const Header = styled(TextH6)`
  font-weight: 800;
`;

const Text = styled(TextBody)`
  font-weight: normal;
`;

const Img = styled.img`
  width: 326px;
  height: 272px;
`;

const ExtensionIconSpan = styled.span`
  margin: 0 8px;
  vertical-align: middle;
`;

export default InstallExtensionModal;
