import React from 'react';
import styled from 'styled-components';

import { TextH6, TextSmall } from 'shared/react/components/basic/text/TextV2';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import BasicAccordionV2 from 'src/basic_components/BasicAccordionV2';
import { app as AppEntity } from 'app/src/types/entities';
import ConversionCodeSection from './ConversionCodeSection';
import { getIsConversionCodeAddedAutomatically } from '../helper';
import Utils from 'src/utils';

type Props = {
  app: AppEntity;
};

const ConversionCodeInstallationStep: React.FC<Props> = ({ app }) => {
  const appKey = app?.appKey || Utils.getAppKey();
  const integration = app?.app;
  const isCodeAddedAutomatically = getIsConversionCodeAddedAutomatically(integration);

  return (
    <BasicAccordionV2
      header={
        <>
          <LeftHeaderTitle>Step 2</LeftHeaderTitle>
          <RightHeaderTitle>Conversion Code</RightHeaderTitle>
          {isCodeAddedAutomatically && <TextSmallGrey>(Automatically Applied)</TextSmallGrey>}
        </>
      }
    >
      <Content>
        <ConversionCodeSection
          appKey={appKey}
          isCodeAddedAutomatically={isCodeAddedAutomatically}
        />
      </Content>
    </BasicAccordionV2>
  );
};

const Content = styled(Gap16VerticalFlex)`
  width: 50%;
`;

const LeftHeaderTitle = styled(TextH6)`
  padding-right: 8px;
  color: ${({ theme }) => theme.colors.neutralDarker};
`;

const RightHeaderTitle = styled(TextH6)`
  padding: 0 8px;
  border-left: ${({ theme }) => `1px solid ${theme.colors.ghostLight}`};
`;

const TextSmallGrey = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.neutralGray};
`;

export default ConversionCodeInstallationStep;
