import React from 'react';
import styled from 'styled-components';
import loadingIcon from 'player/src/assets/tolstoy_quotation.svg';

const PlayerLoader = () => {
  return (
    <StyledPlayerLoader>
      <img className="loading-icon" src={loadingIcon} width="64" height="37" alt="Loading..." />
    </StyledPlayerLoader>
  );
};

export default PlayerLoader;

const StyledPlayerLoader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: grid;
  place-content: center;
  background-color: ${({ theme }) => theme.colors.white};
`;
