import React from 'react';
import styled, { useTheme } from 'styled-components';
import VIcon from 'app/src/images/VIcon';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import FeaturesTooltips from 'app/src/complex_components/billings/tooltips/FeaturesTooltips';

const BillingPackageFeature = ({ feature }) => {
  const theme = useTheme();

  return (
    <StyledBillingPackageFeature>
      <IconContainer>
        <VIcon fill={theme.colors.successDark} width="12" height="12" />
      </IconContainer>
      {feature}
      <FeaturesTooltips feature={feature} />
    </StyledBillingPackageFeature>
  );
};

export default BillingPackageFeature;

const StyledBillingPackageFeature = styled(Gap8HorizontalFlex)`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray36};
`;

const IconContainer = styled.div`
  width: 20px;
  height: 20px;
  display: grid;
  place-content: center;
  background: ${({ theme }) => theme.colors.successLight};
  border-radius: 20px;
  flex-shrink: 0;
`;
