import React from 'react';
import { useTheme } from 'styled-components';

function PromoCodeIcon({ fill, ...props }) {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      fill="none"
      viewBox="0 0 14 15"
      {...props}
    >
      <path
        fill={fill || theme.colors.ghostDark}
        d="M0 .875C0 .406.375 0 .875 0h5.813c.406 0 .968.25 1.25.531l5.53 5.532a1.735 1.735 0 010 2.468L8.5 13.5a1.735 1.735 0 01-2.469 0L.5 7.969c-.281-.282-.5-.844-.5-1.25V.875zM2.5 3.5c0 .563.438 1 1 1 .531 0 1-.438 1-1 0-.531-.469-1-1-1-.563 0-1 .469-1 1z"
      ></path>
    </svg>
  );
}

export default PromoCodeIcon;
