import React from 'react';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import ProductEditor from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/feed/ProductEditor';
import { useFormContext } from 'react-hook-form';
import { DESCRIPTION_SIDE_PANEL_TYPE } from 'shared/react/constants/feed.constants';
import FeedDescriptionEditor from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/feed/FeedDescriptionEditor';

const EditFeedSidePanel = ({
  project,
  isLoadingProducts,
  setLoadingProducts,
  hasNoPermissions,
  setHasNoPermissions,
}) => {
  const { watch } = useFormContext();
  const [feedSidePanelType] = watch(['feedSidePanelType']);

  const isDescription = feedSidePanelType === DESCRIPTION_SIDE_PANEL_TYPE;
  const getEditor = () => {
    if (isDescription) {
      return <FeedDescriptionEditor />;
    }

    return (
      <ProductEditor
        project={project}
        isLoadingProducts={isLoadingProducts}
        setLoadingProducts={setLoadingProducts}
        hasNoPermissions={hasNoPermissions}
        setHasNoPermissions={setHasNoPermissions}
      />
    );
  };

  return <LayoutRoot isDescription={isDescription}>{getEditor()}</LayoutRoot>;
};

const LayoutRoot = styled(VerticalFlex)`
  padding: ${({ isDescription }) => (isDescription ? '32px 24px' : 0)};
  width: 100%;
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    padding: 16px;
  }
`;

export default EditFeedSidePanel;
