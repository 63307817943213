import React from 'react';

const WarningIcon = () => {
  return (
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.86 9.273 7.843.743A.973.973 0 0 0 6.977.25c-.329 0-.657.164-.868.492L1.117 9.273a.987.987 0 0 0 .867 1.477h10.008a.987.987 0 0 0 .867-1.477Zm-10.641.352 4.758-8.133 4.78 8.133H2.22ZM7 7.422a.736.736 0 0 0-.75.726c0 .399.328.727.75.727a.73.73 0 0 0 .727-.727A.73.73 0 0 0 7 7.422Zm-.563-3.61v2.25c0 .329.235.563.563.563a.555.555 0 0 0 .563-.563v-2.25A.57.57 0 0 0 7 3.25a.555.555 0 0 0-.563.563Z"
        fill="#652401"
      />
    </svg>
  );
};

export default WarningIcon;
