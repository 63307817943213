import React from 'react';
import { DangerButton } from 'shared/react/components/basic/button-v2/BasicButton';
import useNavigation from 'app/src/hooks/useNavigation';

const NewFromCaseOffsiteButton = () => {
  const { navigateToDashboardOffsiteCreateCase } = useNavigation();

  const handleClick = () => {
    navigateToDashboardOffsiteCreateCase();
  };

  return <DangerButton onClick={handleClick}>Create Tolstoy</DangerButton>;
};

export default NewFromCaseOffsiteButton;
