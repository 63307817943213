import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { ChevronDownIcon } from 'shared/react/images/ChevronIcon';
import { Button } from 'shared/react/components/basic/button-v2/BasicButton';
import useOnClickOutside from 'shared/react/hooks/useOutsideClick';
import { PLAYLIST_TAG_MODES } from './constants';
import { Playlist } from './types';
import { EQUALS_KEY, NOT_EQUALS_KEY } from 'src/constants/ruleGroups.constants';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

type TagDropdownProps = {
  mode?: string;
  playlist?: Playlist;
  isDisabled?: boolean;
  shouldShowDropdownToggle?: boolean;
  onUpdateRuleCondition?: (playlist: Playlist, newCondition: string) => void;
};

const TagDropdown = ({
  mode,
  playlist,
  isDisabled,
  shouldShowDropdownToggle,
  onUpdateRuleCondition,
}: TagDropdownProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownButtonRef = useRef(null);

  useOnClickOutside([dropdownRef, dropdownButtonRef], () => {
    setIsDropdownOpen(false);
  });

  const handleToggleDropdown = e => {
    e.stopPropagation();
    setIsDropdownOpen(prev => !prev);
  };

  const handleDropdownItemClick = e => {
    e.stopPropagation();

    const newRuleCondition =
      !playlist?.condition || playlist.condition === EQUALS_KEY ? NOT_EQUALS_KEY : EQUALS_KEY;

    setIsDropdownOpen(false);
    onUpdateRuleCondition(playlist, newRuleCondition);
  };

  if (isDisabled) {
    return null;
  }

  const getShouldShowDropdownToggle = () => {
    if (playlist?.condition === NOT_EQUALS_KEY) {
      return false;
    }

    return isDropdownOpen || shouldShowDropdownToggle;
  };

  return (
    <>
      <DropdownToggleButton
        ref={dropdownButtonRef}
        shouldShowDropdownToggle={getShouldShowDropdownToggle()}
      >
        <VerticalSeparator $mode={mode} />
        <IconWrapper $mode={mode} onClick={handleToggleDropdown}>
          <ChevronDownIcon />
        </IconWrapper>
      </DropdownToggleButton>

      {isDropdownOpen && (
        <Dropdown ref={dropdownRef}>
          <DropdownButton onClick={handleDropdownItemClick}>Exclude</DropdownButton>
        </Dropdown>
      )}
    </>
  );
};

const DropdownToggleButton = styled(HorizontalFlexCentered)<{
  shouldShowDropdownToggle?: boolean;
}>`
  height: 15px;
  visibility: ${({ shouldShowDropdownToggle }) =>
    shouldShowDropdownToggle ? 'visible' : 'hidden'};
`;

const IconWrapper = styled(HorizontalFlexCentered)<{ $mode?: string }>`
  position: absolute;
  border-radius: 0 16px 16px 0;
  right: 0;
  width: 25px;
  height: 100%;

  ${({ $mode, theme }) => {
    switch ($mode) {
      case PLAYLIST_TAG_MODES.OUTLINE:
      default:
        return css`
          background-color: ${theme.colors.white};
          & path {
            fill: ${({ theme }) => theme.colors.neutralBlack};
          }
        `;
      case PLAYLIST_TAG_MODES.FILLED:
        return css`
          background-color: ${theme.colors.neutralBlack};
          & path {
            fill: ${({ theme }) => theme.colors.white};
          }
        `;
      case PLAYLIST_TAG_MODES.WARNING_FILLED:
        return css`
          background-color: ${theme.colors.neutralLighter};
          & path {
            fill: ${({ theme }) => theme.colors.fireRed};
          }
        `;
      case PLAYLIST_TAG_MODES.GRAYED_OUT:
        return css`
          border: 1px solid ${theme.colors.neutralDark};
          & path {
            fill: ${({ theme }) => theme.colors.neutralDark};
          }
        `;
    }
  }};
`;

const VerticalSeparator = styled.div<{ $mode?: string }>`
  width: 1px;
  height: 100%;
  position: absolute;
  right: 25px;

  ${({ $mode, theme }) => {
    switch ($mode) {
      case PLAYLIST_TAG_MODES.OUTLINE:
      default:
        return css`
          background-color: ${theme.colors.neutralLight};
        `;
      case PLAYLIST_TAG_MODES.FILLED:
        return css`
          background-color: ${theme.colors.neutralLightest};
        `;
      case PLAYLIST_TAG_MODES.GRAYED_OUT:
      case PLAYLIST_TAG_MODES.WARNING_FILLED:
        return css`
          background-color: ${theme.colors.neutralDark};
        `;
    }
  }}
`;

const Dropdown = styled.div`
  position: absolute;
  top: 115%;
  right: 0;
  width: auto;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10;
  border-radius: 4px;
`;

const DropdownButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
`;

export default TagDropdown;
