import React from 'react';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import styled from 'styled-components';
import OverlaySpinner from 'app/src/basic_components/OverlaySpinner';

type Prop = {
  shouldShowLoader: boolean;
} & React.HTMLProps<HTMLDivElement>;

const ChartBox = ({ children, shouldShowLoader, ...props }: Prop) => {
  return (
    <OverlaySpinner isLoading={shouldShowLoader} size={24}>
      <LayoutRoot {...props}>{children}</LayoutRoot>
    </OverlaySpinner>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)`
  background: ${({ theme }) => theme.colors.white};
  box-shadow:
    0 1px 3px -1px rgba(0, 0, 0, 0.05),
    0 2px 10px 0 rgba(50, 50, 93, 0.1);
  padding: 8px 16px 16px;
  height: 350px;
  width: 100%;

  & > *:last-child {
    flex: 1;
  }
`;

export default ChartBox;
