import React from 'react';
import styled, { useTheme } from 'styled-components';
import Trash from 'app/src/images/Trash';

import { TextSmall } from 'shared/react/components/complex/Text';
import IconButton from 'app/src/complex_components/IconButton';
import { PRODUCT_SEARCH_RESULT_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import ImageWithFallback from 'shared/react/images/ImageWithFallback';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import LineClampWrapper from 'shared/react/components/basic/LineClampWrapper';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';
import { MAGENTO, PRODUCTS_IMPORT } from 'app/src/constants/intergrations.constants';

const DEFAULT_CURRENCY_SYMBOL = '$';

const MenuProduct = ({
  imageUrl,
  currencySymbol,
  price,
  title,
  onClick,
  onRemove,
  handle,
  selectedApp,
}) => {
  const theme = useTheme();

  return (
    <Container onClick={onClick} data-test-id={PRODUCT_SEARCH_RESULT_TEST_ID}>
      <Image src={imageUrl} width={50} height={50} borderRadius={8} />

      <VerticalFlex>
        <EllipsisTextOverflowWrapper>
          <Title title={title}>{title}</Title>
        </EllipsisTextOverflowWrapper>
        <PriceOrHandle
          currencySymbol={currencySymbol}
          price={price}
          handle={handle}
          selectedApp={selectedApp}
        />
      </VerticalFlex>

      <EndContainer>
        {onRemove && (
          <DeleteContainer onClick={onRemove}>
            <Trash fill={theme.colors.ghostDark} width={14} height={16} />
          </DeleteContainer>
        )}
      </EndContainer>
    </Container>
  );
};

const PriceOrHandle = ({ handle, price, currencySymbol, selectedApp }) => {
  const getProductUrl = () => {
    if (selectedApp?.app === MAGENTO) {
      return `https://${selectedApp?.appUrl}/${handle}`;
    }

    if (selectedApp?.app === PRODUCTS_IMPORT) {
      return handle;
    }

    return `https://${selectedApp?.appUrl}/products/${handle}`;
  };

  if (handle) {
    const productUrl = getProductUrl();
    return (
      <Handle title={productUrl}>
        <StyledLineClampWrapper numberOfLines={2}>{productUrl}</StyledLineClampWrapper>
      </Handle>
    );
  }

  return (
    <Price>
      {currencySymbol || DEFAULT_CURRENCY_SYMBOL}
      {price}
    </Price>
  );
};

const Container = styled.div`
  color: ${({ theme }) => theme.colors.white};
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 8px;
  align-items: center;
  transition: 0.3s;
  cursor: pointer;
  height: 100%;
  padding: 8px 16px;

  &:hover {
    background: ${({ theme }) => theme.colors.primaryLight};
  }
`;

const Image = styled(ImageWithFallback)`
  overflow: hidden;
  cursor: pointer;
  height: 64px;
  width: 64px;
`;

const Title = styled(TextSmall)`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.black};
`;

const EndContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Price = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.black};
`;

const DeleteContainer = styled(IconButton)`
  &:hover,
  &:focus,
  &:active {
    background: #e6e6e6;
  }

  margin-left: 8px;
  padding: 14px;
`;

const Handle = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.ghostDark};
`;

const StyledLineClampWrapper = styled(LineClampWrapper)`
  word-break: break-all;
`;

export default MenuProduct;
