import { Group } from 'src/complex_components/group-select-menu';
import { LIBRARY } from 'app/src/constants/creation_method.constants';
import { DynamicRuleTypes } from '../../constants/dynamicConditions.constants';
import {
  DEFAULT_GROUP_NAME,
  PLAYLIST_TAG_MODES,
  PRODUCT_TAGGED_PLAYLIST,
  CONDITION_PLAYLIST_MODE,
  SOURCE_PLAYLIST_CTA_TEXT,
  VOD_SOURCE_CONDITION_OPTIONS,
  GROUP_NAMES_BY_PLAYLIST_TYPE,
  SOURCE_PLAYLISTS_WITH_CTA_TEXT,
  PLAYLIST_TYPE_VIDEOS_MODAL_PAGE,
  DEFAULT_MAX_VISIBLE_PLAYLISTS,
  TRENDING_VIDEOS_PLAYLIST,
  ALL_IMAGES_PLAYLIST,
} from './constants';
import { Playlist } from './types';

export const sortSelectedPlaylistFirst = (a, b) => b.isSelected - a.isSelected;

export const getIsRuleSelected = ({ value, type, rule }) => {
  if (
    [DynamicRuleTypes.taggedProduct, DynamicRuleTypes.trending].includes(type) &&
    rule.type === type
  ) {
    return true;
  }

  return rule.value === value && rule.type === type;
};

const geLabelPlaylists = ({ vodLabels, rules, defaultVisibleAmount }) => {
  const labelPlaylists = vodLabels.map(({ id: value, name }) =>
    getPlaylist({
      rules,
      value,
      name,
      type: DynamicRuleTypes.vodLabel,
    })
  );

  const defaultVisiblelaylists = labelPlaylists.slice(0, defaultVisibleAmount);
  const remainingPlaylists = labelPlaylists
    .slice(defaultVisibleAmount)
    .sort(sortSelectedPlaylistFirst);

  const selectedRemainingPlaylistsAmount = remainingPlaylists.filter(
    playlist => playlist.isSelected
  ).length;

  return {
    playlists: [...defaultVisiblelaylists, ...remainingPlaylists],
    visibleAmount: defaultVisibleAmount + selectedRemainingPlaylistsAmount,
  };
};

export const getPlaylist = ({
  value,
  name,
  type,
  rules,
  ctaText = '',
  isAvailable = true,
  shouldShowCtaText = false,
  isSelectConditionDisabled = false,
}) => {
  const selectedRule = rules.find(rule => getIsRuleSelected({ value, type, rule }));
  const condition = selectedRule?.condition;
  const isSelected = !!selectedRule;
  const mode =
    isAvailable || isSelected
      ? CONDITION_PLAYLIST_MODE[condition] || PLAYLIST_TAG_MODES.OUTLINE
      : PLAYLIST_TAG_MODES.GRAYED_OUT;

  return {
    id: `${type}_${value}`,
    name,
    type,
    mode,
    value,
    ctaText,
    shouldShowCtaText,
    condition,
    isAvailable,
    isSelected,
    isSelectConditionDisabled,
  };
};

export const getPlaylists = ({
  vodLabels = [],
  rules = [],
  playlistAvailability = {},
  defaultVisiblePlaylistsAmount = DEFAULT_MAX_VISIBLE_PLAYLISTS,
}) => {
  const {
    isProductTaggedAvailable = true,
    getIsSourcePlaylistAvailable = () => true,
    getIsSourcePlaylistDisabled = () => false,
  } = playlistAvailability as any;

  const filteredVodSourceConditionOptions = VOD_SOURCE_CONDITION_OPTIONS.filter(source => {
    return !getIsSourcePlaylistDisabled(source.value) && getIsSourcePlaylistAvailable(source.value);
  });

  const sourcePlaylists = filteredVodSourceConditionOptions.map(source => {
    const isAvailable = getIsSourcePlaylistAvailable(source.value);
    const shouldShowCtaText = !isAvailable && SOURCE_PLAYLISTS_WITH_CTA_TEXT.includes(source.value);

    return getPlaylist({
      rules,
      ctaText: SOURCE_PLAYLIST_CTA_TEXT,
      isAvailable,
      shouldShowCtaText,
      ...source,
    });
  });

  const trendingVideosPlaylist = [
    getPlaylist({
      rules,
      ...TRENDING_VIDEOS_PLAYLIST,
      isAvailable: true,
    }),
  ];

  const defaultVisibleLabelPlaylistsAmount =
    defaultVisiblePlaylistsAmount - sourcePlaylists.length - 1;
  const { playlists: labelPlaylists, visibleAmount } = geLabelPlaylists({
    vodLabels,
    rules,
    defaultVisibleAmount: defaultVisibleLabelPlaylistsAmount,
  });

  const playlists = [
    getPlaylist({ rules, ...PRODUCT_TAGGED_PLAYLIST, isAvailable: isProductTaggedAvailable }),
    ...trendingVideosPlaylist,
    getPlaylist({ rules, ...ALL_IMAGES_PLAYLIST }),
    ...sourcePlaylists,
    ...labelPlaylists,
  ];
  const totalVisibleAmount =
    visibleAmount + sourcePlaylists.length + trendingVideosPlaylist.length + 1;

  return {
    playlists,
    totalVisibleAmount,
  };
};

export const compilePlaylistsMenuGroups = (playlists: Playlist[]): Group[] => {
  const groups = {};

  playlists.forEach(playlist => {
    const groupName = GROUP_NAMES_BY_PLAYLIST_TYPE[playlist.type] || DEFAULT_GROUP_NAME;

    if (!groups[groupName]) {
      groups[groupName] = {
        name: groupName,
        items: [],
      };
    }

    groups[groupName].items.push({
      name: playlist.name,
      value: playlist,
      id: `${playlist.type}-${playlist.value}`,
      isSelected: playlist.isSelected,
    });
  });

  return Object.values(groups);
};

export const getVideosModalSelectedMethod = (type, value) => {
  if (type === DynamicRuleTypes.vodSource) {
    return PLAYLIST_TYPE_VIDEOS_MODAL_PAGE[value];
  }

  return LIBRARY;
};

export const getVideosModalDefaultFilterProps = (type, value) => {
  if (type !== DynamicRuleTypes.vodSource) {
    return null;
  }

  return { uploadType: { in: [value] } };
};
