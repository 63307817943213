import React, { useMemo } from 'react';

import { App } from 'src/types/app';
import { useVideos } from 'src/context/VideosStore';
import { UGC_INSTAGRAM_GRAPH_PENDING } from 'src/constants/intergrations.constants';

import { UgcPageContent, UgcSort, UgcFilter } from '../layout';
import { useFilteredAssets } from '../../hooks';
import {
  GROUP_LABEL,
  MEDIA_TYPE_FILTERS,
  DEFAULT_SORT,
  FilterType,
  SORT_FIELDS,
} from '../../constants';
import { getUgcHashtags } from '../../utils';
import UgcPendingTopBar from './UgcPendingTopBar';

type Props = {
  selectedApp: App;
  selectedUGCCategory: string;
};

const EMPTY_STATE = {
  title: 'No pending posts',
  subTitle: 'Review videos in the Search tab and request usage rights',
};

export const UgcPendingPage = ({ selectedApp, selectedUGCCategory }: Props) => {
  const [{ data }] = useVideos();
  const defaultAssets = useMemo(
    () => data.filter(video => video.uploadType === UGC_INSTAGRAM_GRAPH_PENDING),
    [data]
  );

  const defaultFilters = useMemo(() => {
    const sources = getUgcHashtags(defaultAssets);

    return {
      [GROUP_LABEL.MEDIA_TYPE]: MEDIA_TYPE_FILTERS,
      [GROUP_LABEL.SOURCE]: sources.map((source: string) => ({
        value: source,
        label: source,
        type: FilterType.Source,
      })),
    };
  }, [defaultAssets]);
  const { assets, setFilters, filters, setSort, sort } = useFilteredAssets({
    assets: defaultAssets,
    defaultFilters,
    defaultSort: DEFAULT_SORT,
  });

  return (
    <>
      <UgcPendingTopBar />
      <div className="flex justify-end gap-2 px-6 py-4">
        <UgcSort
          selectedFieldValue={sort.field}
          order={sort.order}
          fields={SORT_FIELDS}
          onSort={setSort}
        />
        <UgcFilter filters={filters} onChange={setFilters} />
      </div>
      <UgcPageContent
        videos={assets}
        selectedApp={selectedApp}
        selectedUGCCategory={selectedUGCCategory}
        emptyState={EMPTY_STATE}
      />
    </>
  );
};

export default UgcPendingPage;
