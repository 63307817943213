import { css } from 'styled-components';

export const openSidePanelAnimation = css`
  animation: open 0.6s;
  margin-right: 0;

  @keyframes open {
    0% {
      margin-right: -100%;
    }

    100% {
      margin-right: 0;
    }
  }
`;

export const closeSidePanelAnimation = css`
  animation: close 1s ease-in-out;
  margin-right: -100%;

  @keyframes close {
    0% {
      margin-right: 0;
    }

    100% {
      margin-right: -100%;
    }
  }
`;
