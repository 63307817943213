import React, { useState } from 'react';
import styled from 'styled-components';
import { useProjects } from 'app/src/context/ProjectsStore';
import ExportToCsvIcon from 'app/src/images/ExportToCsv';
import { CircularProgress } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { STATUS_ALL } from 'app/src/constants/responsesInbox.constants';
import SnackBar from 'app/src/basic_components/SnackBar';

const InboxExportToCsv = ({ status }) => {
  const [, { exportProjectResponses }] = useProjects();
  const { projectId = '' } = useParams();
  const [exportCsvLoading, setExportCsvLoading] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const onExportCsvClick = () => {
    if (exportCsvLoading) {
      return;
    }
    setExportCsvLoading(true);
    exportProjectResponses(projectId);
    setTimeout(() => {
      setExportCsvLoading(false);
      setSnackBarOpen(true);
    }, 3000);
  };

  if (status.id !== STATUS_ALL) {
    return null;
  }

  return (
    <>
      <ConversationExportToCsvButton
        onClick={onExportCsvClick}
        loading={exportCsvLoading ? true : undefined}
        title="Export to csv"
      >
        {!exportCsvLoading && <ExportToCsvIcon />}
        {exportCsvLoading && <CircularProgress size={22} />}
      </ConversationExportToCsvButton>
      <SnackBar
        text="The file will be sent to your account email shortly"
        open={snackBarOpen}
        setOpen={() => setSnackBarOpen(false)}
        severity="success"
      />
    </>
  );
};

export default InboxExportToCsv;

const ConversationExportToCsvButton = styled.button`
  border: none;
  background-color: unset;
  cursor: ${({ loading }) => (loading ? 'default' : 'pointer')};
  padding: 0;
  position: relative;
  display: flex;
  &:after {
    opacity: 0;
    content: '';
    display: block;
    bottom: -6px;
    left: -9px;
    right: -6px;
    top: -6px;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.gray28};
    border-radius: 50%;
    transition-property: opacity;
    transition-duration: 500ms;
  }
  &:hover:after {
    opacity: 1;
  }
`;
