import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Gap16HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap16HorizontalFlexWrap';
import AnswerTextField from 'app/src/basic_components/AnswerTextField';
import { CHOICES_CUSTOM_INPUT_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import { useDebounce } from 'src/hooks/useDebounce';
import { DEBOUNCE_TIME } from 'app/src/constants/builder.constants';

const AnswerTextEditor = ({
  icon,
  isLoading,
  handleAnswerChanged,
  index,
  error,
  answer,
  placeholder,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(answer.text || '');
  const debouncedInputValue = useDebounce(inputValue, DEBOUNCE_TIME);

  const handleChange = value => {
    handleAnswerChanged({ value, index, property: 'text' });
  };

  const onChange = e => {
    if (isLoading) {
      return;
    }

    setInputValue(e.target.value);
  };

  useEffect(() => {
    setInputValue(answer.text || '');
  }, [answer.text]);

  useEffect(() => {
    handleChange(debouncedInputValue);
  }, [debouncedInputValue]);

  return (
    <LayoutRoot {...props}>
      <IconContainer>{icon}</IconContainer>
      <AnswerTextField
        onChange={onChange}
        value={inputValue}
        maxLength={50}
        placeholder={`Choice #${index + 1}`}
        error={error}
        dataTestId={CHOICES_CUSTOM_INPUT_TEST_ID}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16HorizontalFlexWrap)`
  align-items: center;
  flex-wrap: nowrap;
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
`;

export default AnswerTextEditor;
