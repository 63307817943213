import React, { useState } from 'react';
import styled from 'styled-components';
import SortIconV2 from 'app/src/images/dashboard_v2/SortIconV2';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import { useDashboard } from 'app/src/context/ui_store/DashboardStore';
import { track } from 'app/src/helpers/Tracker';
import SortV2Menu from 'app/src/pages/dashboard/pages/libraries/sort_v2/SortV2Menu';
import { shadeColor } from 'shared/react/utils/color';

const SortV2 = () => {
  const [{ sortBy, ascending }] = useDashboard();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentOption, setCurrentOption] = useState(sortBy || 'updatedAt');

  const handleSortMenuClicked = e => {
    track('Sort button clicked');
    setAnchorEl(e.currentTarget);
  };

  const sortOptions = {
    updatedAt: 'Modified',
    createdAt: 'Date',
    totalResponses: 'Total responses',
    totalUnreadResponses: 'Total unread responses',
  };

  const isSorted = ascending || currentOption !== 'createdAt';
  return (
    <>
      <LayoutRoot onClick={handleSortMenuClicked} isOpen={!!anchorEl} isSorted={isSorted}>
        <SortIconV2 />
      </LayoutRoot>
      <SortV2Menu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        sortOptions={sortOptions}
        currentOption={currentOption}
        setCurrentOption={setCurrentOption}
      />
    </>
  );
};

const LayoutRoot = styled(HorizontalFlexWrap)`
  gap: 6px;
  align-items: center;
  line-height: 13px;
  cursor: pointer;
  font-weight: 600;
  flex-wrap: nowrap;
  white-space: nowrap;
  background-color: ${({ theme, isOpen, isSorted }) =>
    isOpen || isSorted ? theme.colors.white : theme.colors.gray5};
  border: 1px solid ${({ theme }) => theme.colors.ghostLight};
  border-radius: 10px;
  padding: 0 12px;
  transition: 0.3s;

  &:hover {
    background-color: ${({ theme }) => shadeColor(theme.colors.gray5, -1)};
  }

  &:focus {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export default SortV2;
