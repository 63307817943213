import React from 'react';

const HubspotIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={80}
    height={40}
    viewBox="0 0 24.576 12.28"
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    <path
      d="M.744 4.04h.805v1.79h1.698V4.04h.806v4.4h-.806V6.605H1.55V8.43H.744zM6.604 7a.67.67 0 0 1-.667.667A.67.67 0 0 1 5.27 7V5.1h-.763V7a1.43 1.43 0 0 0 2.86 0V5.1h-.763V7m2.895-1.868a1.17 1.17 0 0 0-.898.36V4.04h-.766v2.692c0 1.008.73 1.698 1.547 1.698.9 0 1.707-.703 1.707-1.65 0-.935-.735-1.65-1.6-1.65zm.02 2.6c-.52 0-.916-.433-.916-.94s.397-.94.916-.94c.44 0 .837.434.837.94s-.397.94-.837.94zm2.858-2.397c0-.385.257-.507.537-.507.226 0 .525.17.72.38l.5-.586c-.25-.336-.757-.568-1.172-.568-.83 0-1.43.483-1.43 1.283 0 1.484 1.826 1.014 1.826 1.845 0 .256-.25.482-.537.482-.452 0-.6-.22-.806-.452l-.556.574c.354.434.794.654 1.32.654.788 0 1.423-.49 1.423-1.252 0-1.65-1.826-1.136-1.826-1.85m3.843-.236c-.82 0-1.547.7-1.547 1.698v2.7h.766V8.04c.255.25.52.36.898.36.855 0 1.6-.715 1.6-1.65 0-.947-.798-1.65-1.708-1.65zm.136 2.6c-.52 0-.916-.434-.916-.94s.397-.94.916-.94c.44 0 .837.434.837.94s-.397.94-.837.94zm7.21.043c-.452 0-.58-.195-.58-.495V5.903h.702V5.23h-.702v-.886l-.775.348v2.7c0 .7.476 1.038 1.13 1.038a1.57 1.57 0 0 0 .306-.024l.19-.696-.27.012"
      fill="#7d8086"
    />
    <path
      d="M21.543 5.942a1.683 1.683 0 0 0-.612-.608 1.64 1.64 0 0 0-.619-.214v-.803a.59.59 0 0 0 .365-.555c0-.337-.27-.6-.607-.6a.61.61 0 0 0-.612.61c0 .247.132.46.357.555v.803c-.18.026-.367.082-.538.17l-2.162-1.642a.656.656 0 0 0 .028-.178.676.676 0 1 0-.675.676.67.67 0 0 0 .347-.098l.14.107 1.94 1.398c-.103.094-.198.2-.275.322a1.5 1.5 0 0 0-.25.809v.06a1.605 1.605 0 0 0 .356 1.022l-.644.646a.52.52 0 0 0-.701.49.52.52 0 0 0 .522.522.52.52 0 0 0 .522-.522.515.515 0 0 0-.024-.157l.666-.666a1.71 1.71 0 0 0 .296.162 1.73 1.73 0 0 0 .692.145h.046a1.63 1.63 0 0 0 .79-.2c.26-.142.465-.336.62-.583a1.53 1.53 0 0 0 .24-.824v-.015c0-.297-.07-.57-.213-.82zM20.73 7.34c-.18.2-.388.325-.623.325h-.04a.85.85 0 0 1-.394-.104.91.91 0 0 1-.345-.314.734.734 0 0 1-.144-.428V6.77c0-.15.03-.292.1-.426a.92.92 0 0 1 .321-.351.717.717 0 0 1 .445-.134h.015a.9.9 0 0 1 .418.096c.134.072.244.17.33.3a.95.95 0 0 1 .147.42l.004.095a.83.83 0 0 1-.236.569z"
      fill="#7d8086"
    />
  </svg>
);

export default HubspotIcon;
