import React from 'react';

const SadTolstoyLogo = () => {
  return (
    <svg
      width="219"
      height="170"
      viewBox="0 0 219 170"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="218.713" height="170" rx="24" fill="#FFAAC9" />
      <path
        d="M62.9737 43.629L63.2237 44.062L63.6567 43.812L63.7215 43.7746C72.2749 38.8362 83.2658 41.8363 88.2641 50.4936C93.2624 59.1509 90.367 70.1681 81.8116 75.1076C73.577 79.8618 63.0886 77.2589 57.8566 69.3401L57.5971 68.9474L57.593 68.9497L55.3946 65.1421C54.6259 63.8106 54.0443 62.4235 53.6415 61.0109L53.6387 61.0014L53.6356 60.9919C50.8247 52.4416 52.5994 42.9232 58.5769 36.0135L62.9737 43.629Z"
        fill="black"
        stroke="black"
      />
      <path
        d="M134.972 67.6125L135.222 67.1795L134.789 66.9295L134.724 66.8921C126.167 61.952 123.272 50.9322 128.269 42.2769C133.268 33.6197 144.257 30.619 152.814 35.5591C161.048 40.3128 164.04 50.6992 159.799 59.1896L159.588 59.6107L159.592 59.6131L157.394 63.4208C156.626 64.7518 155.713 65.948 154.692 67.0013L154.685 67.0086L154.679 67.0161C148.678 73.7274 139.548 76.9503 130.575 75.228L134.972 67.6125Z"
        fill="black"
        stroke="black"
      />
      <path
        d="M47.9999 119.771C77.9999 97.0869 139.5 95.0869 173 119.771"
        stroke="black"
        strokeWidth="8"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default SadTolstoyLogo;
