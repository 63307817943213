import React from 'react';
import Utils from 'app/src/utils';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import Routes from 'app/src/helpers/Routes';
import APP_CONFIG from 'app/src/config/app.config';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
    Utils.logError(null, error);
  }

  redirectToErrorPage = () => {
    this.props.history.push(Routes.getErrorRoute());
    this.setState({ error: null });
  };

  removeError = () => {
    this.setState({ error: null });
  };

  render() {
    if (this.state.error) {
      const isDevelopment = APP_CONFIG.IS_DEV;
      if (!isDevelopment) {
        this.redirectToErrorPage();
      }

      return (
        <ErrorMessage>
          {isDevelopment ? (
            <div>
              <h2>{this.state.error.message}</h2>
              <div>{this.state.error.stack}</div>
              <button onClick={this.redirectToErrorPage}>redirect to root</button>
              <button onClick={this.removeError}>refresh the page</button>
            </div>
          ) : (
            ''
          )}
        </ErrorMessage>
      );
    }

    return this.props.children;
  }
}

const ErrorMessage = styled.div`
  margin: 16px;
`;

export default withRouter(ErrorBoundary);
