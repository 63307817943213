export enum JourneyDrawerStepEventName {
  impression = 'Impression',
  video = 'Watched Video',
  purchase = 'Purchase',
  addToCart = 'Added to Cart',
  openEmail = 'Opened email',
  emailClick = 'Clicked GIF',
}

export type JourneyDrawerStepType = {
  id: number;
  eventName: JourneyDrawerStepEventName;
  orderId: string;
  parentUrl: string;
  publishId: string;
  videoId: string;
  watchTime: number;
  createdAt: string;
};

export enum JourneyDrawerSummaryStepKey {
  isReturningCustomer = 'isReturningCustomer',
  watchedVideos = 'watchedVideos',
  purchases = 'purchases',
}

export type JourneyDrawerSummaryStep = {
  key: JourneyDrawerSummaryStepKey;
  value: number | boolean;
};
