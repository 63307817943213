import React from 'react';
import TolstoyLoader from 'app/src/basic_components/TolstoyLoader';
import { TOLSTOY_ID } from 'app/src/constants/CreateNewTolstoy.constants';
import { Gap16HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import ArrowRight from 'app/src/images/create_new_content/ArrowRight';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Gap16HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap16HorizontalFlexWrap';
import ComponentWithOverlayLoader from 'shared/react/components/basic/ComponentWithOverlayLoader';
import { publishMethodOptions } from 'app/src/types/entities';
import { TextSmall } from 'shared/react/components/basic/text/TextV2';

const isEmbeds = id =>
  [
    publishMethodOptions.videoPage,
    publishMethodOptions.stories,
    publishMethodOptions.carousel,
    publishMethodOptions.embed,
    publishMethodOptions.hero,
    publishMethodOptions.tile,
  ].includes(id);

const NewTolstoyMenuContent = ({
  id,
  text,
  handleCreateNew,
  dataTestId,
  description,
  handleHover,
  handlePublishMethodClick,
  chosenPublishMethod,
  openBeforeHover,
  isLoading,
  selectedPublishMethod,
  firstMainMenuItemId,
  isOffsite = false,
  isRecommended,
  icon,
}) => {
  const onClick = () => {
    if (chosenPublishMethod || isOffsite) {
      handleCreateNew(id, chosenPublishMethod);
      return;
    }
    handlePublishMethodClick(id);
  };

  return (
    <LoadingContainer
      customLoader={<TolstoyLoader size={40} />}
      isLoading={isLoading && (selectedPublishMethod === id || chosenPublishMethod === id)}
    >
      <MenuContent
        onClick={onClick}
        key={id}
        data-test-id={dataTestId}
        onMouseOver={handleHover}
        shouldHoverAtStart={openBeforeHover && (id === firstMainMenuItemId || id === TOLSTOY_ID)}
      >
        <TextContainer>
          <Gap16HorizontalFlex>
            <LeftText>
              {icon} {text} {!!isRecommended && <Recommended>(recommended)</Recommended>}
            </LeftText>
          </Gap16HorizontalFlex>
          {!!description && <Description>{description}</Description>}
        </TextContainer>
        {!isEmbeds(id) && !chosenPublishMethod && !isOffsite && <ArrowRight />}
      </MenuContent>
    </LoadingContainer>
  );
};

const TextContainer = styled(VerticalFlex)`
  gap: 4px;
`;

const Description = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.neutralDarker};
`;

const Recommended = styled.span`
  color: ${({ theme }) => theme.colors.ghostDark};
`;

const LeftText = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.black};
  opacity: ${({ disabled }) => (disabled ? 0.7 : '')};
  font-weight: 600;
  display: flex;
  gap: 8px;
  align-items: center;
`;

const MenuContent = styled(Gap16HorizontalFlexWrap)`
  width: 100%;
  background: ${({ theme, shouldHoverAtStart }) =>
    shouldHoverAtStart ? theme.colors.gray32 : theme.colors.white};
  padding: 12px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.3s;
  flex-wrap: nowrap;
  gap: 12px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray32};
  }
`;

const LoadingContainer = styled(ComponentWithOverlayLoader)`
  overflow: hidden;
  height: inherit;

  &:last-of-type {
    border-radius: 0 0 12px 12px;
  }
`;

export default NewTolstoyMenuContent;
