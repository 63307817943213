export const DesignSizes = {
  XSMALL: 'xsmall',
  SMALL: 'small',
  NORMAL: 'normal',
  LARGE: 'large',
};

export const DesignWeights = {
  LIGHT: 'light',
  SOLID: 'solid',
  SQUARE: 'square',
};

export const DesignStates = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  DANGER: 'danger',
  WARNING: 'warning',
  INFO: 'info',
  VIOLET: 'violet',
  LIGHT: 'light',
  DARK: 'dark',
};
