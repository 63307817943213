import React, { useEffect, useRef } from 'react';
import useQuery from 'app/src/hooks/useQuery';
import { WIDGET_SETTINGS_CATEGORY_KEY } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import LayoutEditor from 'app/src/pages/project/pages/look_and_feel/editor/editors/widget_settings_editor/LayoutEditor';
import AppearanceEditor from 'app/src/pages/project/pages/look_and_feel/editor/editors/widget_settings_editor/AppearanceEditor';
import AddOnsEditor from 'app/src/pages/project/pages/look_and_feel/editor/editors/widget_settings_editor/AddOnsEditor';
import EditorGroup from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorGroup';
import EntranceEditor from 'app/src/pages/project/pages/look_and_feel/editor/editors/widget_settings_editor/EntranceEditor';

function WidgetSettingsEditorGroup() {
  const layoutRef = useRef();
  const category = useQuery().get('selectedCategory');

  useEffect(() => {
    if (category === WIDGET_SETTINGS_CATEGORY_KEY) {
      layoutRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [category]);

  return (
    <EditorGroup
      editors={[
        { key: 'layout', component: <LayoutEditor ref={layoutRef} /> },
        { key: 'appearance', component: <AppearanceEditor /> },
        { key: 'widgetEntrance', component: <EntranceEditor /> },
        { key: 'addons', component: <AddOnsEditor /> },
      ]}
    />
  );
}

export default WidgetSettingsEditorGroup;
