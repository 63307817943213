import React from 'react';

const SearchBarXIcon = () => {
  return (
    <svg width={10} height={11} viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.406 10.14 5.47 5.5 9.406.883c.117-.164.117-.399-.047-.54-.164-.116-.398-.116-.539.048L5 4.937 1.156.391C1.016.227.781.227.617.344.453.484.453.719.57.883L4.508 5.5.57 10.14c-.117.165-.117.4.047.54a.45.45 0 0 0 .235.07c.117 0 .234-.023.304-.117L5 6.086l3.82 4.547c.07.094.188.117.305.117a.45.45 0 0 0 .234-.07c.164-.14.164-.375.047-.54Z"
        fill="#7D8087"
      />
    </svg>
  );
};

export default SearchBarXIcon;
