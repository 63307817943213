import React, { useState } from 'react';
import styled from 'styled-components';
import {
  FEED_CART_QUANTITY_DATA_TYPE,
  FEED_CART_SUBTOTAL_DATA_TYPE,
  FEED_CART_TITLE_DATA_TYPE,
} from '../../../../constants/feedPreviewDataTypes.constants';
import Utils from '../../../../utils/utils';
import useFeedCart from '../../context/hooks/useFeedCart';
import useFeedProducts from 'shared/react/components/complex/context/hooks/useFeedProducts';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';
import ModalWrapper from 'shared/react/components/basic/ModalWrapper';
import { useIsMobile } from 'shared/react/hooks/useIsMobile';
import useFeedModal from 'shared/react/components/complex/context/hooks/useFeedModal';
import FeedCartHeader from './FeedCartHeader';
import FeedCartBody from './FeedCartBody';
import FeedCartFooter from './FeedCartFooter';

const FeedCart = () => {
  const [{ currentModal }, { popModal }] = useFeedModal();
  const { onClose, currentStepKey } = currentModal.props;
  const [{ cart, numberOfCartItems }] = useFeedCart();
  const [products] = useFeedProducts();
  const [isOpen, setIsOpen] = useState(false);
  const [focusedProduct, setFocusedProduct] = useState(null);
  const isMobile = useIsMobile();
  const isEmptyState = !cart.items?.length;
  const { player } = useDesignSettingsContext();
  const { headerColorText, bodyColorText } = player.feedCards.text;

  const handleClickGoBack = () => {
    setIsOpen(false);
    onClose?.();
  };

  const getDisplayedPriceByCents = cents => {
    if (!cents) {
      return '0.00';
    }

    const fixedPrice = Number((cents / 100).toFixed(2)).toLocaleString();
    const productsArray = Object.values(products);
    const currencySymbol = productsArray[0]?.currencySymbol;

    return `${currencySymbol || `${cart.currencyCode} `}${fixedPrice}`;
  };

  return (
    <StyledModalWrapper
      onClose={popModal}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      hideBackgroundOverlay={!isMobile}
      bodyColorText={bodyColorText}
      headerColorText={headerColorText}
    >
      <FeedCartHeader
        shouldHide={!!focusedProduct}
        numberOfCartItems={numberOfCartItems}
        onClickGoBack={handleClickGoBack}
      />
      <FeedCartBody
        cartItems={cart.items}
        onClickBack={handleClickGoBack}
        focusedProduct={focusedProduct}
        setFocusedProduct={setFocusedProduct}
        currentStepKey={currentStepKey}
        isMobile={isMobile}
        getDisplayedPriceByCents={getDisplayedPriceByCents}
        isEmptyState={isEmptyState}
      />
      <FeedCartFooter
        shouldHide={isEmptyState || focusedProduct}
        cartTotalPrice={cart.totalPrice}
        getDisplayedPriceByCents={getDisplayedPriceByCents}
      />
    </StyledModalWrapper>
  );
};

const StyledModalWrapper = styled(ModalWrapper)`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 13;
  background-color: ${({ theme }) => theme.colors.white};
  max-width: 25vw;
  transition: 0.3s;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  & ${Utils.getDataType(FEED_CART_TITLE_DATA_TYPE)} {
    color: ${({ headerColorText, theme }) => headerColorText || theme.colors.black};
  }

  & ${Utils.getDataType(FEED_CART_SUBTOTAL_DATA_TYPE)} {
    color: ${({ bodyColorText, theme }) => bodyColorText || theme.colors.black};
  }

  & ${Utils.getDataType(FEED_CART_QUANTITY_DATA_TYPE)} {
    color: ${({ bodyColorText, theme }) => bodyColorText || theme.colors.black};
  }

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    max-width: unset;
  }
`;

export default FeedCart;
