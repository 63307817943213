import React from 'react';

const ChartStatsEmptyState = () => {
  return (
    <svg
      width="200"
      height="34"
      viewBox="0 0 200 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0" width="200" height="14" rx="6" fill="#E2EDFE" />
      <rect y="20" x="30" width="170" height="14" rx="6" fill="#E2EDFE" />
    </svg>
  );
};

export default ChartStatsEmptyState;
