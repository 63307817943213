import React from 'react';
import styled from 'styled-components';
import Menu from 'shared/react/components/basic/Menu';
import TeamMembersMenuItems from 'app/src/pages/dashboard/pages/libraries/project_filters/TeamMembersMenuItems';
import { TextSmall } from 'shared/react/components/complex/Text';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import Tooltip from 'app/src/basic_components/Tooltip';
import { track } from 'app/src/helpers/Tracker';
import Button from 'shared/react/components/complex/Button';
import CircledPlusIcon from 'app/src/images/CircledPlusIcon';
import Types from 'shared/react/theme/Types';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { TEAM_MEMBER_INVITE_MODAL_KEY } from 'app/src/constants/modals.constants';
import Separator from 'shared/react/components/basic/Separator';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const TeamMembersMenu = ({ anchorEl, setAnchorEl, selectedTeamMember, onTeamMemberClick }) => {
  const [, { setCurrentModal }] = useModal();

  const onClose = () => {
    setAnchorEl(false);
  };

  const handleTeamMemberClicked = teamMember => {
    track('Filters Team Member Click');
    onTeamMemberClick(teamMember);
  };

  const onInviteClick = () => {
    setCurrentModal(TEAM_MEMBER_INVITE_MODAL_KEY);
  };

  return (
    <StyledMenu open={!!anchorEl} anchorEl={anchorEl} onClose={onClose}>
      <MenuContent>
        <TopContainer>
          <StyledTextSmall>Created by</StyledTextSmall>
          <Tooltip text="Invite a new team member" placement="right">
            <ResetFilterContainer>
              <Button
                padding="0 8px"
                height="24px"
                leftIcon={<CircledPlusIcon />}
                showLeftIcon
                type={Types.Success}
                fullWidth
                onClick={onInviteClick}
              >
                Invite
              </Button>
            </ResetFilterContainer>
          </Tooltip>
        </TopContainer>
        <CustomSeparator />
        <TeamMembersMenuItems
          onClose={onClose}
          selectedTeamMember={selectedTeamMember}
          onTeamMemberClicked={handleTeamMemberClicked}
        />
      </MenuContent>
    </StyledMenu>
  );
};

const StyledMenu = styled(Menu)``;

const MenuContent = styled(VerticalFlex)`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  max-height: 300px;
  overflow: auto;

  @media (min-width: 600px) {
    & > .MuiMenuItem-root {
      min-height: 48px;
    }
  }
`;

const StyledTextSmall = styled(TextSmall)`
  ${({ theme }) => theme.colors.gray2}
`;

const CustomSeparator = styled(Separator)`
  background-color: ${({ theme }) => theme.colors.ghostLight};
`;

const TopContainer = styled(HorizontalFlexWrap)`
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  padding: 18px 16px 8px;
  align-items: center;
`;

const ResetFilterContainer = styled(HorizontalFlexWrap)`
  gap: 6px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;
  font-weight: 600;
  height: 100%;

  &:hover {
    transform: scale(1.1);
  }
`;

export default TeamMembersMenu;
