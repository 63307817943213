import React from 'react';

function EIcon({ fill, darkBackground, size = 20 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={12} cy={12} r={12} fill={fill || '#FFFFFF'} />
      <path
        d="M9.663 15.5h4.763v-1.463h-3.234V12.2h3.234v-1.463h-3.234V8.933h3.234V7.47H9.663v8.03Z"
        fill={darkBackground ? '#FFFFFF' : '#2F3137'}
      />
    </svg>
  );
}

export default EIcon;
