import React from 'react';
import { TextBodyBold, TextTiny } from 'shared/react/components/basic/text/TextV2';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import styled from 'styled-components';
import { JourneyDrawerSummaryStepType } from './journey-drawer-content/journey-drawer-step/types/JourneyDrawerStep.types';
import JourneyDrawerSummary from './journey-drawer-summary/JourneyDrawerSummary';
import { sensitiveDataFilter } from 'shared/react/components/basic/SensitiveData';

type Props = {
  email: string;
  journeySummary: JourneyDrawerSummaryStepType[];
};

const JourneyDrawerHeader = ({ email, journeySummary }: Props) => {
  return (
    <LayoutRoot>
      <StyledTextTiny>User journey</StyledTextTiny>
      <TextBodyBoldEmail>{email}</TextBodyBoldEmail>
      <JourneyDrawerSummary journeySummary={journeySummary} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  padding: 8px 16px 4px;
  gap: 4px;
`;

const StyledTextTiny = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralDark};
`;

const TextBodyBoldEmail = styled(TextBodyBold)`
  ${sensitiveDataFilter()}
`;

export default JourneyDrawerHeader;
