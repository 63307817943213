import React from 'react';
import AnalyticsTopConvertingVideoContent from './AnalyticsTopConvertingVideoContent';
import AnalyticsVideosPanel from './AnalyticsVideosPanel';
import useAnalyticsOverview from 'app/src/hooks/useAnalyticsOverview';

type Props = {
  setSelectedTab: (tab: string) => void;
};

const AnalyticsTopConvertingVideos = ({ setSelectedTab }: Props) => {
  const {
    topConvertingVideoMetrics = [],
    isLoadingTopConvertingVideoMetrics,
    isTopConvertingVideosEmpty,
    topConvertingVideoInsights,
  } = useAnalyticsOverview();

  return (
    <AnalyticsVideosPanel setSelectedTab={setSelectedTab} title="Highest converting videos">
      <AnalyticsTopConvertingVideoContent
        isEmpty={isTopConvertingVideosEmpty}
        isLoading={isLoadingTopConvertingVideoMetrics}
        videoMetrics={topConvertingVideoMetrics}
        videoInsights={topConvertingVideoInsights}
      />
    </AnalyticsVideosPanel>
  );
};

export default AnalyticsTopConvertingVideos;
