const WWW_PREFIX = 'www.';

// eslint-disable-next-line prefer-regex-literals
const URL_PATTERN = new RegExp(
  '^((([A-Za-z]{3,9}:(?:\\/\\/)?)(?:[\\-;:&=\\+\\$,\\w]+@)?[A-Za-z0-9\\.\\-]+|(?:www\\.|[\\-;:&=\\+\\$,\\w]+@)[A-Za-z0-9\\.\\-]+)((?:\\/[\\+~%\\/\\.\\w\\-_]*)?\\??(?:[\\-\\+=&;%@\\.\\w_]*)#?(?:[\\.\\!\\/\\\\\\w]*))?)',
  'i'
);

const getIsValidUrl = url => {
  if (!url) {
    return false;
  }

  return (
    Boolean(URL_PATTERN.test(url)) ||
    url.startsWith('tel:') ||
    url.startsWith('mailto:') ||
    url.startsWith('_self:')
  );
};

const getStrippedUrl = url => {
  const formattedUrl = url.replace(WWW_PREFIX, '');

  const urlInterface = new URL(formattedUrl);

  return `${urlInterface.origin}${urlInterface.pathname}${urlInterface.search}`;
};

export const getIsTheSameURL = (firstUrl, secondUrl) => {
  if (!getIsValidUrl(firstUrl) || !getIsValidUrl(secondUrl)) {
    return false;
  }

  return getStrippedUrl(firstUrl) === getStrippedUrl(secondUrl);
};

export const getUrlCustomParams = () => {
  const params = new URLSearchParams(window.location.search);
  const filteredParams = new URLSearchParams();

  for (const [key, value] of params) {
    const lowerCaseKey = key.toLowerCase();
    if (
      key.length < 50 &&
      value.length < 100 &&
      (lowerCaseKey.startsWith('custom_') || lowerCaseKey.startsWith('utm_'))
    ) {
      filteredParams.append(key, value);
    }
  }

  return filteredParams;
};

export const getFilteredSearchParams = (params = {}) => {
  const filteredParams = new URLSearchParams();

  for (const [key, value] of Object.entries(params)) {
    if (value !== null && value !== undefined && value !== '') {
      filteredParams.append(key, value);
    }
  }

  return filteredParams.toString();
};

export const isUrl = url => URL_PATTERN.test(url);
