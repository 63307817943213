let logRocketOnDownloadCallbacks = [];

const getIsLogRocketLoaded = () => {
  return !!window.LogRocket;
};

const logRocketOnLoad = () => {
  logRocketOnDownloadCallbacks.forEach(callback => {
    try {
      callback();
    } catch (error) {
      console.error(error);
    }
  });
  logRocketOnDownloadCallbacks = [];
};

export const logRocketLoad = () => {
  if (getIsLogRocketLoaded()) {
    return;
  }

  const logRocketLoader = document.createElement('script');

  logRocketLoader.src = 'https://cdn.lr-in-prod.com/LogRocket.min.js';
  logRocketLoader.onload = logRocketOnLoad;

  document.head.appendChild(logRocketLoader);
};

export const logRocketRunOnLoad = callback => {
  if (getIsLogRocketLoaded()) {
    callback();
    return;
  }

  logRocketOnDownloadCallbacks.push(callback);
};
