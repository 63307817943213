import React, { useState } from 'react';
import styled from 'styled-components';
import StandardModal from './StandardModal';
import { TextBody } from 'shared/react/components/complex/Text';
import TolstoyLogoWithWord from 'app/src/images/TolstoyLogoWithWord';
import { useAccount } from 'app/src/context/AccountStore';
import Utils from '../../utils';
import { navigateToInviteInvalidMessage, navigateToRoot } from '../../utils/navigation.utils';
import { useHistory } from 'react-router-dom';
import Button from 'shared/react/components/basic/Button';
import { track } from '../../helpers/Tracker';

const JoinWorkspaceModal = ({ open, onClose }) => {
  const history = useHistory();
  const [, { acceptInvite }] = useAccount();
  const [loading, setLoading] = useState(false);

  const { inviteToken, account = 'tolstoy user', accountName = 'tolstoy' } = Utils.getInvite();

  const onAcceptInviteClick = async () => {
    setLoading(true);
    await acceptInvite(inviteToken);
    track('Team member invite accepted', {
      location: 'modal',
    });
    navigateToRoot(history);
  };

  const onDecline = () => {
    Utils.clearInvite();
    onClose();
  };

  if (!inviteToken || inviteToken === 'null') {
    Utils.clearInvite();
    navigateToInviteInvalidMessage(history);
    return null;
  }

  return (
    <StandardModal open={open} onClose={onClose}>
      <StyledJoinWorkspaceModal>
        <LogoContainer>
          <TolstoyLogoWithWord />
        </LogoContainer>

        <JoinWorkspaceContent>
          <InviteText>{`${account} invited you to`}</InviteText>
          <JoinWorkspaceText>
            Join the <span>{Utils.capitalizeFirstLetter(accountName)}</span> workspace on Tolstoy
          </JoinWorkspaceText>
        </JoinWorkspaceContent>

        <ButtonsContainer>
          <JoinButton onClick={onAcceptInviteClick} loading={loading} disabled={loading}>
            Join
          </JoinButton>

          <DeclineButtonContainer>
            or
            <DeclineButton onClick={onDecline}>decline</DeclineButton>
          </DeclineButtonContainer>
        </ButtonsContainer>
      </StyledJoinWorkspaceModal>
    </StandardModal>
  );
};

export default JoinWorkspaceModal;

const StyledJoinWorkspaceModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  padding: 60px;
  max-width: 800px;
  text-align: center;
`;

const LogoContainer = styled.div`
  width: 245px;
  height: 48px;
`;

const JoinWorkspaceContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const InviteText = styled(TextBody)`
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
`;

const JoinWorkspaceText = styled(TextBody)`
  font-weight: 900;
  font-size: 48px;
  line-height: 65px;

  & > span {
    color: ${({ theme }) => theme.colors.danger};
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DeclineButtonContainer = styled.div`
  text-align: center;
  button {
    color: ${({ theme }) => theme.colors.blue7};
  }
`;

const DeclineButton = styled.button`
  color: ${({ theme }) => theme.colors.blue7};
  border: none;
  background: ${({ theme }) => theme.colors.white};
  text-decoration: underline;
  cursor: pointer;
`;

const JoinButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.blue7};
  border: none;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  font-size: 36px;
  line-height: 36px;
  padding: 12px 24px 14px;
  width: 220px;
  border-radius: 100px;
  cursor: pointer;
`;
