import React from 'react';
import styled from 'styled-components';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import OutlinedCrossIcon from 'app/src/images/creation_method_icons/OutlinedCrossIcon';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import { TextSmall } from 'shared/react/components/complex/Text';
import PricingTooltip from 'app/src/complex_components/PricingTooltip';
import { ADD_STEP_TEST_ID } from 'app/src/constants/dataTestIds.constants';

const AddFeedVideo = ({ addNewPartClicked, isLocked }) => {
  return (
    <PricingTooltip
      header="Upgrade for more parts"
      placement="bottom"
      text="In the free version you are limited to five parts. If you need more, click below to unlock this feature."
      shouldHide={!isLocked}
    >
      <StyledCard data-test-id={ADD_STEP_TEST_ID} isLocked={isLocked} onClick={addNewPartClicked}>
        <EmptyStateIconContainer>
          <OutlinedCrossIcon />
        </EmptyStateIconContainer>
        <Text isLocked={isLocked}>Add new video</Text>
      </StyledCard>
    </PricingTooltip>
  );
};

const StyledCard = styled(Gap16VerticalFlex)`
  cursor: ${({ isLocked }) => (isLocked ? 'default' : 'pointer')};
  background: ${({ theme }) => theme.colors.success};
  border-radius: 12px;
  overflow: hidden;
  transition: 0.3s;
  height: 112px;
  width: 144px;
  min-width: 144px;
  align-items: center;
  &:hover {
    background: ${({ theme }) => theme.colors.successHover};
  }

  & path {
    fill: ${({ theme, isLocked }) => (isLocked ? theme.colors.successDark : '')};
  }
`;

const EmptyStateIconContainer = styled(HorizontalFlexWrap)`
  width: 140px;
  padding-top: 24px;
  justify-content: center;
  align-items: center;
`;

const Text = styled(TextSmall)`
  color: ${({ theme, isLocked }) => (isLocked ? theme.colors.successDark : theme.colors.white)};
  font-weight: 700;
`;

export default AddFeedVideo;
