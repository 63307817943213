import React from 'react';

const OpenFolder = ({ fill }) => {
  return (
    <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.59375 5H15V3.5C15 2.6875 14.3125 2 13.5 2H8.5L6.5 0H1.5C0.65625 0 0 0.6875 0 1.5V11.7812L2.8125 6.125C3.15625 5.4375 3.84375 5 4.59375 5ZM16.9688 6H4.59375C4.21875 6 3.875 6.21875 3.71875 6.5625L0 14H13.9688C14.375 14 14.7188 13.8125 14.875 13.4688L17.875 7.46875C18.2188 6.8125 17.7188 6 16.9688 6Z"
        fill={fill || '#50545E'}
      />
    </svg>
  );
};

export default OpenFolder;
