import React from 'react';
import styled from 'styled-components';
import { TextTiny } from 'shared/react/components/complex/Text';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { cleanStoreName } from 'app/src/utils/shopify.utils';
import { sensitiveDataFilter } from 'shared/react/components/basic/SensitiveData';

const ShopifyMenuItem = ({ appUrl }) => {
  return (
    <LayoutRoot>
      <ShopifyMenuItemText>{cleanStoreName(appUrl)}</ShopifyMenuItemText>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlex)`
  align-items: center;
`;

const ShopifyMenuItemText = styled(TextTiny)`
  font-weight: 500;
  ${sensitiveDataFilter()}
`;

export default ShopifyMenuItem;
