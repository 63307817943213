import React from 'react';

const InfluencerIcon = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.09375 2.6875C9.03125 2.65625 9 2.59375 9 2.53125C9 2.4375 9.03125 2.375 9.09375 2.34375L10.6562 1.6875L11.3125 0.125C11.3438 0.0625 11.4062 0 11.5 0C11.5625 0 11.625 0.0625 11.6562 0.125L12.3125 1.6875L13.875 2.3125C13.9375 2.375 13.9688 2.4375 13.9688 2.5C13.9688 2.59375 13.9375 2.65625 13.875 2.6875L12.3125 3.34375L11.6562 4.90625C11.625 4.96875 11.5625 5.03125 11.4688 5.03125C11.4062 5.03125 11.3438 4.96875 11.2812 4.90625L10.6562 3.34375L9.09375 2.6875ZM16.9062 6.875C16.9375 6.90625 17 6.9375 17 7C17 7.0625 16.9375 7.125 16.875 7.15625L15.6562 7.6875L15.125 8.9375C15.0938 8.96875 15.0312 9.03125 14.9688 9.03125C14.9062 9.03125 14.875 8.96875 14.8438 8.9375L14.3125 7.6875L13.0938 7.15625C13.0312 7.125 12.9688 7.09375 12.9688 7.03125C12.9688 6.96875 13.0312 6.90625 13.0938 6.875L14.3125 6.34375L14.8438 5.125C14.875 5.0625 14.9375 5.03125 15 5.03125C15.0625 5.03125 15.0938 5.0625 15.125 5.125L15.6562 6.34375L16.9062 6.875ZM12.375 8.375C12.9688 8.4375 13.1875 9.1875 12.7812 9.59375L10.4062 11.9062L10.9688 15.1875C11 15.4375 10.9062 15.7188 10.6562 15.875C10.4375 16.0312 10.1562 16.0625 9.90625 15.9375L7 14.375L4.0625 15.9375C3.9375 16 3.84375 16 3.71875 16C3.3125 16 2.9375 15.625 3.03125 15.1875L3.5625 11.9062L1.21875 9.59375C0.78125 9.1875 1 8.4375 1.59375 8.375L4.875 7.875L6.34375 4.90625C6.46875 4.65625 6.71875 4.5 6.96875 4.5C7.25 4.5 7.5 4.65625 7.625 4.90625L9.09375 7.875L12.375 8.375ZM8.78125 11.375L10.5938 9.625L8.09375 9.25L7 7L5.875 9.25L3.375 9.625L5.1875 11.375L4.75 13.875L7 12.6875L9.21875 13.875L8.78125 11.375Z"
        fill="#2F3137"
      />
    </svg>
  );
};

export default InfluencerIcon;
