import HttpService from 'shared/react/services/httpService';
import { REACT_APP_API_BASE_URL } from 'shared/react/config/shared.config';

const fetchVideosBySearchTerm = async ({ appKey, searchText, threshold, limit }) => {
  return HttpService.post(
    `${REACT_APP_API_BASE_URL}/ai/videos/search-by-text`,
    {},
    JSON.stringify({ appKey, searchText, threshold, limit })
  );
};

const fetchVideosByVideoId = async ({ appKey, videoIds, threshold, limit }) => {
  return HttpService.post(
    `${REACT_APP_API_BASE_URL}/ai/videos/similar`,
    {},
    JSON.stringify({ appKey, videoIds, threshold, limit })
  );
};

const getRankedVideosByAppUrl = async ({ appUrl }) => {
  return HttpService.post(
    `${REACT_APP_API_BASE_URL}/ai/videos/ranked-videos`,
    {},
    JSON.stringify({
      appUrl,
    })
  );
};

export { fetchVideosBySearchTerm, fetchVideosByVideoId, getRankedVideosByAppUrl };
