import React from 'react';
import {
  OrangeButton,
  SecondaryButton,
  SuccessButton,
  PrimaryButton,
} from 'shared/react/components/basic/button-v2/BasicButton';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import useTolyChatBotLogic from 'src/pages/dashboard/components/toly-chatbot/useTolyChatBotLogic';
import { DesignSizes } from 'src/types/design-system';
import { intercomShow } from 'src/utils/intercom.utils';
import styled from 'styled-components';

type Props = {
  handleDoneClick: () => void;
  handlePauseClick: () => void;
  handleCancel: () => void;
  disabled: boolean;
  isEmail: boolean;
  justPublished: boolean;
  closeModal: () => void;
  isTrendingVideosEmail: boolean;
  onEmailFlowDone: () => void;
};

const PublishModalActionButtons = ({
  handleDoneClick,
  justPublished,
  handlePauseClick,
  handleCancel,
  disabled,
  isEmail,
  closeModal,
  isTrendingVideosEmail,
  onEmailFlowDone,
}: Props) => {
  const { toggleModal } = useTolyChatBotLogic();

  const getPreFilledMessage = () => {
    if (isTrendingVideosEmail) {
      return `Hi, my Trending videos GIF isn't showing`;
    }

    return `Hi, my Abandonment flows GIF isn't showing`;
  };

  const handleContactSupportClick = () => {
    const message = getPreFilledMessage();
    toggleModal(message);
  };

  if (isEmail) {
    return (
      <LayoutRoot>
        <SecondaryButton
          size={DesignSizes.SMALL}
          onClick={handleContactSupportClick}
          disabled={disabled}
        >
          Contact support
        </SecondaryButton>
        {justPublished && (
          <SecondaryButton size={DesignSizes.SMALL} onClick={handleCancel} disabled={disabled}>
            Cancel
          </SecondaryButton>
        )}
        {!justPublished && (
          <OrangeButton size={DesignSizes.SMALL} onClick={handlePauseClick} disabled={disabled}>
            Pause
          </OrangeButton>
        )}
        <SuccessButton
          size={DesignSizes.SMALL}
          onClick={() => {
            onEmailFlowDone();
            closeModal();
          }}
          disabled={disabled}
        >
          Done
        </SuccessButton>
      </LayoutRoot>
    );
  }

  if (justPublished) {
    return (
      <LayoutRoot>
        <SecondaryButton size={DesignSizes.SMALL} onClick={intercomShow} disabled={disabled}>
          ?
        </SecondaryButton>
        <SecondaryButton size={DesignSizes.SMALL} onClick={handleCancel} disabled={disabled}>
          Cancel
        </SecondaryButton>
        <SuccessButton size={DesignSizes.SMALL} onClick={handleDoneClick} disabled={disabled}>
          Done
        </SuccessButton>
      </LayoutRoot>
    );
  }

  return (
    <LayoutRoot>
      <SecondaryButton size={DesignSizes.SMALL} onClick={intercomShow} disabled={disabled}>
        ?
      </SecondaryButton>
      <OrangeButton size={DesignSizes.SMALL} onClick={handlePauseClick} disabled={disabled}>
        Pause
      </OrangeButton>
      <PrimaryButton size={DesignSizes.SMALL} onClick={handleDoneClick} disabled={disabled}>
        Done
      </PrimaryButton>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlex)`
  justify-content: flex-end;
  background: ${({ theme }) => theme.colors.white};
  margin: 0 -16px -16px -16px;
  padding: 8px 16px;
  box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.05);
`;

export default PublishModalActionButtons;
