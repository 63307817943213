import React from 'react';
import BadgeContent from 'app/src/pages/dashboard/pages/libraries/BadgeContent';
import BadgeButtons from 'app/src/pages/dashboard/pages/libraries/tolstoys_tab/BadgeButtons';
import styled from 'styled-components';
import Badge from 'app/src/complex_components/badge/Badge';
import { DASHBOARD_TABLET_MAX_WIDTH } from 'shared/react/utils/mediaQuery';

const TolstoysBadge = ({ selectedTolstoys, onCancel, projects, isFeed }) => {
  if (!selectedTolstoys.length) {
    return null;
  }

  return (
    <StyledBadge selectedAmount={selectedTolstoys.length} onCancel={onCancel}>
      <BadgeContent selectedAmount={selectedTolstoys.length} onCancel={onCancel}>
        <BadgeButtons
          projects={projects}
          onCancel={onCancel}
          isFeed={isFeed}
          selectedTolstoys={selectedTolstoys}
        />
      </BadgeContent>
    </StyledBadge>
  );
};

const StyledBadge = styled(Badge)`
  justify-content: space-between;
  width: 678px;

  @media (${DASHBOARD_TABLET_MAX_WIDTH}) {
    width: 450px;
  }
`;

export default TolstoysBadge;
