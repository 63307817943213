import React from 'react';
import styled from 'styled-components';
import EditProductsButtons from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/products/EditProductsButtons';
import ProductActionButtonEditor from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/feed/FeedActionButtonEditor';
import { TextSubtitle } from 'shared/react/components/complex/Text';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const ProductEditor = ({
  project,
  isLoadingProducts,
  setLoadingProducts,
  hasNoPermissions,
  setHasNoPermissions,
  isProductResultStep,
}) => {
  return (
    <>
      <ItemContainer>
        <StyledTextSubtitle>Shop button</StyledTextSubtitle>
        <EditProductsButtons
          project={project}
          isLoadingProducts={isLoadingProducts}
          hasNoPermissions={hasNoPermissions}
          setHasNoPermissions={setHasNoPermissions}
          setLoadingProducts={setLoadingProducts}
          isProductResultStep={isProductResultStep}
        />
      </ItemContainer>
      <ProductActionButtonEditor buttonType="feedProductActionButton" />
    </>
  );
};

const ItemContainer = styled(VerticalFlex)`
  justify-content: center;
  width: 100%;
`;

const StyledTextSubtitle = styled(TextSubtitle)`
  padding: 16px 24px 0;
`;

export default ProductEditor;
