import React from 'react';

const RulesLocationIcon = ({ ...props }) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={40} height={40} rx={10} fill="#E2EDFE" />
    <path
      d="M20 12.5c-4.156 0-7.5 3.375-7.5 7.5 0 4.156 3.344 7.5 7.5 7.5 4.125 0 7.5-3.344 7.5-7.5 0-4.125-3.375-7.5-7.5-7.5Zm5.188 4.5h-1.532c-.218-.844-.5-1.625-.843-2.281A6.176 6.176 0 0 1 25.188 17ZM22.5 20c0 .531-.063 1.031-.125 1.5h-4.781a12.063 12.063 0 0 1 0-3h4.781c.063.5.125 1 .125 1.5ZM20 26c-.688 0-1.594-1.125-2.125-3h4.219c-.532 1.875-1.438 3-2.094 3Zm-2.125-9c.531-1.844 1.438-3 2.125-3 .656 0 1.563 1.156 2.094 3h-4.219Zm-.719-2.281c-.343.656-.625 1.437-.843 2.281H14.78a6.176 6.176 0 0 1 2.375-2.281ZM14.188 18.5h1.874C16 19 16 19.5 16 20c0 .531 0 1.031.063 1.5h-1.875A5.802 5.802 0 0 1 14 20c0-.5.063-1 .188-1.5Zm.593 4.5h1.531c.22.875.5 1.656.844 2.313A6.037 6.037 0 0 1 14.781 23Zm8.031 2.313c.344-.657.625-1.438.844-2.313h1.532a6.036 6.036 0 0 1-2.375 2.313Zm2.97-3.813h-1.876a12.063 12.063 0 0 0 0-3h1.875c.125.5.219 1 .219 1.5 0 .531-.094 1.031-.219 1.5Z"
      fill="#3D4FE0"
    />
  </svg>
);

export default RulesLocationIcon;
