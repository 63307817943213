export const EMAIL_SINGLE_SEND = 'singleSend';
export const EMAIL_AUTOMATION_TOOLS = 'automationTools';
export const EMAIL_DOWNLOAD_GIF = 'downloadGif';

export const AUTOMATION_TOOL_KLAVIYO = 'klaviyo';
export const AUTOMATION_TOOL_MAILCHIMP = 'mailchimp';
export const AUTOMATION_TOOL_HUBSPOT = 'hubspot';
export const AUTOMATION_TOOL_OUTREACH = 'outreach';
export const EMAIL_PLATFORM_GMAIL = 'gmail';
export const AUTOMATION_TOOL_SALESLOFT = 'salesloft';
export const AUTOMATION_TOOL_SENDGRID = 'sendgrid';
export const AUTOMATION_TOOL_OMNISEND = 'omnisend';
export const AUTOMATION_TOOL_INTERCOM = 'intercom';
export const AUTOMATION_TOOL_AMPLEMARKET = 'ampleMarket';
export const AUTOMATION_TOOL_OUTPLAY = 'outPlay';
export const AUTOMATION_TOOL_SALESFORCE = 'salesforce';
export const AUTOMATION_TOOL_SHOPIFY = 'shopify';
export const AUTOMATION_TOOL_YOTPO = 'yotpo';
export const AUTOMATION_TOOL_ACTIVECAMPAIGN = 'activecampaign';
export const AUTOMATION_TOOL_EMARSYS = 'emarsys';
export const AUTOMATION_TOOL_SAILTHRU = 'sailthru';
export const AUTOMATION_TOOL_OTHER = 'other';
