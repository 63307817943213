import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { track } from '../tracker/Tracker';
import EndScreenBack from '../assets/end_screen_back.svg';
import EndScreenRestart from '../assets/end_screen_restart.svg';
import EndScreenShare from '../assets/end_screen_share.svg';
import copyIcon from '../assets/copy.svg';
import confettiIcon from '../assets/confetti.svg';
import Snackbar from './Snackbar';
import BlueButton from './BlueButton';
import { END_SCREEN_DATA_TEST_ID, END_TEXT_DATA_TEST_ID } from '../constants/dataTestIds.constants';
import Utils from 'shared/react/utils/utils';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';
import useKeyboardNavigation from 'player/src/hooks/useKeyboardNavigation';
import useFeedFeatures from 'shared/react/components/complex/context/hooks/useFeedFeatures';
import { FEATURE_PLAYER_HIDE_END_SCREEN } from 'player/src/constants/playerFeatures.constants';

const LinkCopiedSnackbarDuration = 3000;
const TRANSLATION_KEY = 'interactive.endScreen';

function EndScreen({
  customEndScreen,
  noViralEndScreen,
  hideBackButton,
  onGoBack,
  onRestart,
  promoCode,
  shouldShowChatLandingPage,
}) {
  const [, { t }] = useTranslation();
  const [, { getFeatureSettingsByKey }] = useFeedFeatures();
  const { shouldHide } = getFeatureSettingsByKey(FEATURE_PLAYER_HIDE_END_SCREEN);
  const [snackbarText, setSnackbarText] = useState('');
  const [buttons, setButtons] = useState([]);
  const { onKeyDown, setNextRef, focusOnElement } = useKeyboardNavigation();

  useEffect(() => {
    async function updateButtons() {
      const buttons = [
        {
          icon: EndScreenRestart,
          iconAlt: t(`${TRANSLATION_KEY}.buttons.restart`),
          text: customEndScreen?.restartText || t(`${TRANSLATION_KEY}.buttons.restart`),
          onClick: handleRestart,
        },
      ];

      if (!hideBackButton) {
        buttons.unshift({
          icon: EndScreenBack,
          iconAlt: t(`${TRANSLATION_KEY}.buttons.back`),
          text: customEndScreen?.backText || t(`${TRANSLATION_KEY}.buttons.back`),
          onClick: handleGoBack,
        });
      }

      buttons.push({
        icon: EndScreenShare,
        iconAlt: t(`${TRANSLATION_KEY}.buttons.share`),
        text: customEndScreen?.shareText || t(`${TRANSLATION_KEY}.buttons.share`),
        onClick: handleShare,
      });

      setButtons(buttons);
    }

    updateButtons();
  }, []);

  const onShare = () => {
    Utils.copyToClipboard(window.location.href.split('?')[0]);
    focusOnElement(0);
  };

  function handleGoBack() {
    onGoBack();
  }

  function handleRestart() {
    onRestart();
  }

  function handleShare() {
    track('shareClick');
    onShare();
    if (!snackbarText) {
      setSnackbarText(t('snackbar.copyLink'));

      setTimeout(() => {
        setSnackbarText('');
      }, LinkCopiedSnackbarDuration);
    }
  }

  const handleCopy = async () => {
    await Utils.copyToClipboard(promoCode);
    focusOnElement(0);

    if (!snackbarText) {
      setSnackbarText(t('snackbar.copyCode'));

      setTimeout(() => {
        setSnackbarText('');
      }, LinkCopiedSnackbarDuration);
    }
  };

  function handleCloseLinkCopiedSnackbar() {
    setSnackbarText('');
  }

  function handleGetStartedClick() {
    track('viralLinkEndScreenClick');
    window.open('https://www.gotolstoy.com', '_blank');
  }

  function getPromoCodeSection() {
    if (customEndScreen || !promoCode) {
      return null;
    }

    return (
      <PromoCodeContainer>
        <PromoCodeHeader>{t(`${TRANSLATION_KEY}.promoCode.text`)} </PromoCodeHeader>
        <CodeContainer>
          <PromoCode aria-label={`Promo code = ${promoCode}`}>{promoCode}</PromoCode>
          <Button
            ref={setNextRef}
            onKeyDown={onKeyDown}
            tabIndex="0"
            aria-label="Copy promo code button"
            title={t(`${TRANSLATION_KEY}.promoCode.buttonTitle`)}
            onClick={handleCopy}
          >
            <Icon src={copyIcon} />
          </Button>
        </CodeContainer>
      </PromoCodeContainer>
    );
  }

  function getCtaTextSection() {
    if (!customEndScreen?.ctaText) {
      return null;
    }

    return (
      <div
        className="dialog-submit dialog-submit-enabled"
        ref={setNextRef}
        onKeyDown={onKeyDown}
        tabIndex="0"
        role="link"
        onClick={() => {
          track('clickCta', { text: customEndScreen.ctaText });
          Utils.openInNewTab(customEndScreen.ctaUrl);
        }}
      >
        {customEndScreen.ctaText}
      </div>
    );
  }

  function getEndScreenButtonsSection() {
    if (customEndScreen && !customEndScreen.isNormal) {
      return;
    }

    return (
      <EndScreenButtonsContainer>
        <EndScreenButtons>
          {buttons.map(button => (
            <EndScreenButton
              key={button.text}
              onClick={button.onClick}
              ref={setNextRef}
              onKeyDown={onKeyDown}
              tabIndex="0"
              role="button"
            >
              <ButtonIcon src={button.icon} alt={button.iconAlt} />
              <EndScreenButtonText>{button.text}</EndScreenButtonText>
            </EndScreenButton>
          ))}

          <ShareSnackBar isVisible={snackbarText}>
            <Snackbar
              aria-label={`Snackbar ${snackbarText}`}
              text={snackbarText}
              onClose={handleCloseLinkCopiedSnackbar}
            />
          </ShareSnackBar>
        </EndScreenButtons>
      </EndScreenButtonsContainer>
    );
  }

  function getTextSection() {
    let message = customEndScreen?.message;

    if (message) {
      return <EndText aria-label={message}>{message}</EndText>;
    }

    return (
      <EndTextContainer data-test-id={END_TEXT_DATA_TEST_ID}>
        <EndText>
          <span>{t(`${TRANSLATION_KEY}.title.allDone`)},&nbsp;</span>
          <b>{t(`${TRANSLATION_KEY}.title.thankYou`)}</b>
        </EndText>
        <ConfettiIcon src={confettiIcon} alt="Confetti" />
      </EndTextContainer>
    );
  }

  if (shouldHide) {
    return null;
  }

  return (
    <EndScreenContainer
      noViralEndScreen={noViralEndScreen}
      data-test-id={END_SCREEN_DATA_TEST_ID}
      shouldShowChatLandingPage={shouldShowChatLandingPage}
      role="group"
      aria-label="End screen"
    >
      <TopPart>
        {getTextSection()}
        {getPromoCodeSection()}
        {getCtaTextSection()}
        {getEndScreenButtonsSection()}
      </TopPart>
      {!noViralEndScreen && (
        <>
          <Separator />
          <BottomPart>
            <BottomPartText>
              {t(`${TRANSLATION_KEY}.promo.promoText`)}
              <RedBottomPartText> Tolstoy</RedBottomPartText>
            </BottomPartText>
            <StyledBlueButton
              ref={setNextRef}
              onKeyDown={onKeyDown}
              tabIndex="0"
              role="link"
              onClick={handleGetStartedClick}
            >
              {t(`${TRANSLATION_KEY}.promo.buttonText`)}
            </StyledBlueButton>
          </BottomPart>
        </>
      )}
    </EndScreenContainer>
  );
}

export default EndScreen;

const EndScreenButtonsContainer = styled.div``;

const EndScreenButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 20px;
  position: relative;
`;

const PromoCodeContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`;

const PromoCodeHeader = styled.div`
  color: white;
  font-size: 18px;
  margin-top: 8px;
`;

const CodeContainer = styled.div`
  display: flex;
  margin-top: 6px;
  align-items: center;
`;

const PromoCode = styled(PromoCodeHeader)`
  font-weight: bold;
  margin: 0 0 0 5px;
`;

const Button = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #335aff;
  cursor: pointer;
  margin-left: 8px;
`;

const Icon = styled.img``;

const ButtonIcon = styled.img`
  width: 51px;
  height: 51px;
  align-self: center;
`;

const EndScreenContainer = styled.div`
  z-index: 50;
  transition: 0.3s;
  background-color: black;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: ${({ noViralEndScreen }) =>
    noViralEndScreen ? 'auto auto' : '4fr auto 3fr'};
  align-items: center;
  border-radius: 10px;

  @media only screen and (max-width: 450px), screen and (hover: none) and (pointer: coarse) {
    position: ${({ shouldShowChatLandingPage }) => (shouldShowChatLandingPage ? '' : 'fixed')};
    border-radius: 0;
  }
`;

const EndScreenButton = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: 8px;

  :hover {
    opacity: 0.6;
  }
`;

const EndScreenButtonText = styled.div`
  margin-top: 6px;
  color: white;
`;

const EndTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  @media (max-width: 330px) {
    flex-direction: column;
  }
`;

const EndText = styled.div`
  display: inline;
  flex-direction: row;
  align-items: center;
  color: white;
  font-size: 25px;
  max-width: 600px;
  white-space: pre-wrap;
`;

const ShareSnackBar = styled.div`
  position: absolute;
  top: 100px;
  left: 10px;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: all 250ms;
`;

const Separator = styled.div`
  height: 1px;
  width: 100%;
  background: #50545e;
`;

const TopPart = styled.div`
  display: grid;
  justify-items: center;
  align-self: end;
  margin-bottom: 40px;
  padding: 0 20px;
  @media only screen and (max-width: 450px) {
    align-self: center;
    margin-bottom: 0;
  }
`;

const BottomPart = styled.div`
  display: grid;
  gap: 24px;
  padding: 20px 8px;
`;

const BottomPartText = styled.span`
  display: inline-block;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 38px;
  text-align: center;
  letter-spacing: 0.0025em;
  color: #ffffff;
`;

const RedBottomPartText = styled.span`
  color: #fa536e;
`;

const StyledBlueButton = styled(BlueButton)`
  width: fit-content;
  justify-self: center;
`;

const ConfettiIcon = styled.img`
  margin-left: 8px;
  width: 40px;
`;
