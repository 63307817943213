import React from 'react';
import styled from 'styled-components';
import { project as Project, publishMethodOptions } from 'app/src/types/entities';
import StoriesPublishIdSrc from 'app/src/images/StoriesPublishIdInstallation.png';
import CarouselPublishIdSrc from 'app/src/images/CarouselPublishIdInstallation.png';
import { EmbedWidgetsShopifyInstall } from 'app/src/types/install';
import InstallStepSimple from 'app/src/pages/project/pages/installation/components/shared/InstallStepSimple';

type Props = {
  project: Project;
  isDisabled: boolean;
};

const getImageSrc = (publishMethod: string) => {
  if (publishMethod === publishMethodOptions.stories) {
    return StoriesPublishIdSrc;
  }

  return CarouselPublishIdSrc;
};

const AddPublishIdStep = ({ project, isDisabled }: Props) => {
  const publishId = project?.publishId;
  const imageSrc = getImageSrc(project?.publishMethod);
  const currentStepName = EmbedWidgetsShopifyInstall.enterPublishId;

  const startExpanded = project.installStep === EmbedWidgetsShopifyInstall.addSection;
  return (
    <InstallStepSimple
      image={<ProjectsInstallationImg src={imageSrc} />}
      codeToCopy={publishId}
      copyText="Copy Publish ID"
      isDisabled={isDisabled}
      text="Copy the ID below and place in the section added to theme in step 1"
      stepNumber={2}
      stepHeader={'Enter this Publish ID inside the Shopify section'}
      currentStepName={currentStepName}
      project={project}
      startExpanded={startExpanded}
    />
  );
};

const ProjectsInstallationImg = styled.img`
  width: 240px;
  max-width: 100%;
`;

export default AddPublishIdStep;
