import React from 'react';

const FilledMenuIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 15.5C10.6641 15.5 11.25 16.0859 11.25 16.75C11.25 17.4531 10.6641 18 10 18C9.29688 18 8.75 17.4531 8.75 16.75C8.75 16.0859 9.29688 15.5 10 15.5ZM10 9.25C10.6641 9.25 11.25 9.83594 11.25 10.5C11.25 11.2031 10.6641 11.75 10 11.75C9.29688 11.75 8.75 11.2031 8.75 10.5C8.75 9.83594 9.29688 9.25 10 9.25ZM10 5.5C9.29688 5.5 8.75 4.95312 8.75 4.25C8.75 3.58594 9.29688 3 10 3C10.6641 3 11.25 3.58594 11.25 4.25C11.25 4.95312 10.6641 5.5 10 5.5Z"
        fill="white"
      />
    </svg>
  );
};

export default FilledMenuIcon;
