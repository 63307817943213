import React from 'react';

function NotificationAudioResponseIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" viewBox="0 0 40 40">
      <rect width="40" height="40" fill="#D3F8EC" rx="10" />
      <path
        fill="#01BE81"
        d="M20 23c1.656 0 3-1.313 3-3v-5a3 3 0 00-3-3c-1.688 0-3 1.344-3 3v5c0 1.688 1.313 3 3 3zm4.75-5a.74.74 0 00-.75.75V20a4 4 0 01-4.281 4C17.656 23.875 16 21.937 16 19.844V18.75a.76.76 0 00-.75-.75.74.74 0 00-.75.75v1.031c0 2.782 1.969 5.282 4.75 5.657V26.5H18c-.594 0-1.031.5-1 1.063 0 .25.219.437.5.437h5c.25 0 .469-.188.469-.438C23 27 22.562 26.5 22 26.5h-1.25v-1.031c2.656-.375 4.75-2.688 4.75-5.469v-1.25a.76.76 0 00-.75-.75z"
      />
    </svg>
  );
}

export default NotificationAudioResponseIcon;
