import React, { useState, useRef } from 'react';
import styled from 'styled-components';

const DRAGGING_PIXELS_THRESHOLD = 5;
const DRAGGING_CLASS_NAME = 'dragging';

const DragToScrollContainer = ({ children, className }) => {
  const containerRef = useRef(null);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [hasDragOccurred, setHasDragOccurred] = useState(false);

  const onMouseDown = e => {
    setIsDragging(true);
    setStartX(e.clientX);
    setScrollLeft(containerRef.current.scrollLeft);
    setHasDragOccurred(false);
  };

  const onMouseMove = e => {
    if (!isDragging) {
      return;
    }

    e.preventDefault();
    const clientX = e.clientX;
    const dragDistance = clientX - startX;
    containerRef.current.scrollLeft = scrollLeft - dragDistance;

    const hasReachedDragThreshold = Math.abs(dragDistance) > DRAGGING_PIXELS_THRESHOLD;
    if (hasReachedDragThreshold) {
      setHasDragOccurred(true);
      containerRef.current.classList.add(DRAGGING_CLASS_NAME);
    }
  };

  const onDragEnd = e => {
    setIsDragging(false);
    containerRef.current.classList.remove(DRAGGING_CLASS_NAME);

    if (!hasDragOccurred) {
      return;
    }

    e.stopPropagation();
    setTimeout(() => {
      setHasDragOccurred(false);
    });
  };

  const onClickCapture = e => {
    if (hasDragOccurred) {
      e.stopPropagation();
    }
  };

  return (
    <LayoutRoot
      ref={containerRef}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onDragEnd}
      onMouseLeave={onDragEnd}
      onClickCapture={onClickCapture}
      className={className}
    >
      {children}
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  &.dragging {
    cursor: grabbing;

    > * {
      pointer-events: none;
      cursor: grab;
    }
  }
`;

export default DragToScrollContainer;
