import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ReadyMadeFilters from 'app/src/pages/creation_flow/right_section/templates/ReadyMadeFilters';
import UploadMethodOption from 'app/src/pages/creation_flow/right_section/templates/UploadMethodOption';
import { TextH5 } from 'shared/react/components/complex/Text';
import { CircularProgress } from '@material-ui/core';
import { useReadyMade } from 'app/src/context/ReadyMadeStore';
import { ALL_ID } from 'app/src/constants/readyMade.constants';
import PersonalStylistIcon from 'app/src/images/personal_stylist.png';
import WelcomeIcon from 'app/src/images/welcome_icon.png';
import OfferDiscountIcon from 'app/src/images/offer_discount_icon.png';
import CollectReviewsIcon from 'app/src/images/collect_reviews_icon.png';
import QuizIcon from 'app/src/images/QuizIcon';
import UnboxingIcon from 'app/src/images/unboxing_icon.png';
import SupportIcon from 'app/src/images/support_icon.png';
import ProductVideoIcon from 'app/src/images/product_video.png';
import InAppCSMIcon from 'app/src/images/in_app_csm.png';
import RecruitingIcon from 'app/src/images/recruiting.png';
import VideoQuizIcon from 'app/src/images/video_quiz.png';
import HappinessCheckInIcon from 'app/src/images/happiness_check_in.png';
import ExitIntent from 'app/src/images/readyMades/ExitIntent';
import ProductPitch from 'app/src/images/readyMades/ProductPitch';
import PreMeetingDiscoveryQuestions from 'app/src/images/readyMades/PreMeetingDiscoveryQuestions';
import PersonalIntroduction from 'app/src/images/readyMades/PersonalIntroduction';
import PostMeetingFollowUp from 'app/src/images/readyMades/PostMeetingFollowUp';
import LeadGeneration from 'app/src/images/readyMades/LeadGeneration';
import PersonalizedOutreachBlast from 'app/src/images/readyMades/PersonalizedOutreachBlast';
import Utils from 'app/src/utils';
import ShoppableVideoIcon from 'app/src/images/ready_mades_icons/ShoppableVideoIcon';
import EmailSmsCampaignIcon from 'app/src/images/ready_mades_icons/EmailSmsCampaignIcon';
import ProspectingIcon from 'app/src/images/ready_mades_icons/ProspectingIcon';

const TemplatesList = ({ setSelectedTemplate }) => {
  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  const [
    { readyMade: tolstoyReadyMadeDescriptions, selectedFilter },
    { fetchReadyMade, updateSelectedFilter },
  ] = useReadyMade();

  const isFilterSelected = filter => selectedFilter === filter;

  const onFilterClick = filter => {
    updateSelectedFilter(filter);
  };

  const getFilteredReadyMade = () => {
    return tolstoyReadyMadeDescriptions.filter(
      ({ categories = [] }) => selectedFilter === ALL_ID || categories.includes(selectedFilter)
    );
  };

  const icons = {
    shoppable_video: <ShoppableVideoIcon />,
    email_sms_campaigns: <EmailSmsCampaignIcon />,
    personal_stylist: <Icon alt="Personal Stylist" src={PersonalStylistIcon} />,
    welcome_visitors: <Icon alt="Welcome" src={WelcomeIcon} />,
    offer_discount: <Icon alt="Offer Discount" src={OfferDiscountIcon} />,
    collect_reviews: <Icon alt="Collect Reviews" src={CollectReviewsIcon} />,
    quiz: <QuizIcon />,
    unboxing: <Icon alt="Unboxing" src={UnboxingIcon} />,
    support: <Icon alt="Support" src={SupportIcon} />,
    product_video: <Icon alt="Product Video" src={ProductVideoIcon} />,
    in_app_csm: <Icon alt="In-App CSM" src={InAppCSMIcon} />,
    recruiting: <Icon alt="Recruiting" src={RecruitingIcon} />,
    video_quiz: <Icon alt="Video Quiz" src={VideoQuizIcon} />,
    happiness_check_in: <Icon alt="Happiness Check in" src={HappinessCheckInIcon} />,
    prospecting: <ProspectingIcon />,
    exit_intent: <ExitIntent />,
    product_pitch: <ProductPitch />,
    pre_meeting_discovery_questions: <PreMeetingDiscoveryQuestions />,
    personal_introduction: <PersonalIntroduction />,
    post_meeting_follow_up: <PostMeetingFollowUp />,
    lead_generation: <LeadGeneration />,
    personalized_outreach_blast: <PersonalizedOutreachBlast />,
  };

  const handleReadyMadeSelected = tolstoyTypeDescription => {
    setSelectedTemplate(tolstoyTypeDescription);
  };

  useEffect(() => {
    setScrollbarWidth(Utils.getScrollbarWidth());
  }, []);

  useEffect(() => {
    if (tolstoyReadyMadeDescriptions?.length) {
      return;
    }

    fetchReadyMade();
  }, [tolstoyReadyMadeDescriptions]);

  const getContent = () => {
    if (!tolstoyReadyMadeDescriptions.length) {
      return <StyledCircularProgress />;
    }

    const filteredReadyMade = getFilteredReadyMade();

    return (
      <ReadyMadesList scrollbarWidth={scrollbarWidth}>
        {filteredReadyMade.map(tolstoyTypeDescription => (
          <StyledUploadMethodOption
            type={tolstoyTypeDescription.type}
            key={tolstoyTypeDescription.title}
            title={tolstoyTypeDescription.title}
            icon={icons[tolstoyTypeDescription.key]}
            horizontal
            onClick={() => handleReadyMadeSelected(tolstoyTypeDescription)}
            data-test-id={tolstoyTypeDescription.key}
          />
        ))}
      </ReadyMadesList>
    );
  };

  return (
    <MainContainer>
      <Title>What do you want your Tolstoy to do?</Title>
      <ReadyMadeFilters isSelected={isFilterSelected} onFilterClick={onFilterClick} />
      <Content>{getContent()}</Content>
    </MainContainer>
  );
};

const StyledUploadMethodOption = styled(UploadMethodOption)`
  justify-items: center;
  justify-self: center;
  min-height: 120px;
  min-width: 240px;
  width: 280px;
  height: fit-content;
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    min-height: 80px;
  }
`;

const MainContainer = styled.div`
  display: grid;
  gap: 0;
  max-width: 957px;
  justify-items: center;
  margin-top: 40px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 100%;
    height: 100%;
    grid-template-rows: auto auto 1fr;
    gap: 8px;
    padding: 0;
    align-self: start;
    margin-bottom: 0;
  }
`;

const Title = styled(TextH5)`
  margin-bottom: 32px;
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    margin: 0px 0 16px 0;
    justify-self: start;
  }
`;

const Content = styled.div`
  display: grid;
  align-items: start;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 450px;
  width: 100%;
  margin-top: 8px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    height: 100%;
    width: 100%;
    grid-template-columns: auto;
    gap: 8px;
  }
`;

const ReadyMadesList = styled.div`
  display: grid;
  align-items: start;
  gap: 24px;
  padding: 16px 24px 24px 24px;
  height: fit-content;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  margin-right: -${({ scrollbarWidth }) => scrollbarWidth}px; // Move right to the size of the scrollbar.
  margin-bottom: 60px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    gap: 16px;
    grid-template-columns: auto;
    padding: 0;
    margin-right: 0;
  }
`;

const Icon = styled.img`
  width: 46px;
`;

const StyledCircularProgress = styled(CircularProgress)`
  align-self: center;
  justify-self: center;
`;

export default TemplatesList;
