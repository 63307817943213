export enum EmbedWidgetsShopifyInstall {
  addSection = 'addSection',
  enterPublishId = 'enterPublishId',
  install = 'install',
}

export const EMBED_WIDGETS_SHOPIFY_INSTALL_ORDER = [
  EmbedWidgetsShopifyInstall.addSection,
  EmbedWidgetsShopifyInstall.enterPublishId,
  EmbedWidgetsShopifyInstall.install,
];

export enum EmbedWidgetsInstall {
  addCode = 'addCode',
  install = 'install',
}

export enum ShopifyTvInstall {
  createTemplate = 'createTemplate',
  createNewVideoPage = 'createNewVideoPage',
  addSection = 'addSection',
  pasteId = 'pasteId',
  install = 'install',
}

export enum TvInstall {
  setYourSiteHeader = 'setYourSiteHeader',
}
