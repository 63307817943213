import React from 'react';
import InstagramConnectAccountState from 'app/src/pages/creation_flow/right_section/import_from_instagram/InstagramConnectAccountState';
import InstagramImportFromAccountVideos from 'app/src/pages/creation_flow/right_section/import_from_instagram/InstagramImportFromAccountVideos';
import useIntegrationsVideos from 'app/src/hooks/useIntegrationsVideos';

const InstagramImportFromAccount = ({ ...props }) => {
  const { instagram: instagramApp } = useIntegrationsVideos();

  if (!instagramApp?.active) {
    return <InstagramConnectAccountState />;
  }

  return <InstagramImportFromAccountVideos {...props} />;
};

export default InstagramImportFromAccount;
