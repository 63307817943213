import React from 'react';
import styled from 'styled-components';
import { useUser } from 'app/src/context/userStore/UserStore';
import { useAccount } from 'app/src/context/AccountStore';
import CollapsingToolbarArrowRight from 'app/src/images/CollapsingToolbarArrowRight';
import { TextTiny } from 'shared/react/components/complex/Text';

const ShareToLibraryMenuTriggerContent = ({ project, isOpen }) => {
  const [{ user }] = useUser();
  const [{ isAccountOwner }] = useAccount();

  const isPrivate = project.private;

  if (project.owner === user.owner) {
    return (
      <>
        <MenuText isPrivate={isPrivate} />
        <CollapsingToolbarArrowRight width={12} height={12} rotationDegrees={isOpen ? 270 : 90} />
      </>
    );
  }

  if (isAccountOwner) {
    return <MenuText isPrivate={isPrivate} />;
  }

  return null;
};

const MenuText = ({ isPrivate }) => {
  return <MenuTextLayoutRoot>{isPrivate ? 'Private' : 'Team'}</MenuTextLayoutRoot>;
};

const MenuTextLayoutRoot = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.ghostDark};
`;

export default ShareToLibraryMenuTriggerContent;
