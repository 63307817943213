import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import EditorOnOffToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorOnOffToggleItem';
import {
  SLIDE_ENABLED_PROPERTY_KEY,
  SLIDE_FROM_PROPERTY_KEY,
} from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import EditorSelect from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorSelect';
import AutoplayEditorToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/player_editor/AutoplayEditorToggleItem';
import { getIsFeed, useProjects } from 'app/src/context/ProjectsStore';
import { useParams } from 'react-router-dom';
import EditorHeader from '../shared/EditorHeader';
import EditorMainContainer from '../shared/EditorMainContainer';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const EntranceEditor = forwardRef((props, ref) => {
  const {
    customizationSettings: {
      widgetSettings: { slideEnabled, slideFrom },
    },
    setWidgetSettingsProperty,
  } = useLookAndFeelContext();
  const { projectId } = useParams();
  const [{ project }] = useProjects({ projectId });
  const isFeed = getIsFeed(project);

  const slideFromText = { bottom: 'Bottom', side: 'Side' };

  return (
    <LayoutRoot ref={ref}>
      <EditorHeader title="Widget entrance" />
      <EditorMainContainer>
        <EditorOnOffToggleItem
          text="Animation"
          checked={!!slideEnabled}
          onChange={value => setWidgetSettingsProperty(SLIDE_ENABLED_PROPERTY_KEY, value)}
        />
        <EditorSelect
          value={slideFromText[slideFrom]}
          text={'Slide from'}
          items={[
            { id: 'bottom', name: 'Bottom' },
            { id: 'side', name: 'Side' },
          ]}
          onChange={value => setWidgetSettingsProperty(SLIDE_FROM_PROPERTY_KEY, value.id)}
        />
        {!isFeed && <AutoplayEditorToggleItem />}
      </EditorMainContainer>
    </LayoutRoot>
  );
});

const LayoutRoot = styled(VerticalFlex)``;

EntranceEditor.displayName = 'EntranceEditor';
export default EntranceEditor;
