import EditorTextInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorTextInputItem';
import React from 'react';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';
import EditorColorInputItem from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorColorInputItem';
import EditorFontFamilyAndWeight from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorFontFamilyAndWeight';
import EditorHeader from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorHeader';
import EditorItem from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorItem';
import EditorMainContainer from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorMainContainer';
import EditorNumericInputItem from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorNumericInputItem';
import EditorOnOffToggleItem from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorOnOffToggleItem';
import { Textarea } from 'ui-library/core';

type Props = {
  setFunction: (value: { [key: string]: string | number }) => void;
  title: string;
  buttonSettings: {
    enabled: boolean;
    text: string;
    font: string;
    fontWeight: number;
    desktopFontSize: number;
    mobileFontSize: number;
    color: string;
    borderRadius: number;
    backgroundColor: string;
    url: string;
    isOpenInNewTab: boolean;
    horizontalPadding: number;
    verticalPadding: number;
  };
};

const HeroOverlayButtonCustomizationEditor = ({ setFunction, buttonSettings, title }: Props) => {
  const {
    branding: { typography },
  } = useDesignSettingsContext();
  const {
    enabled,
    text,
    font,
    fontWeight,
    desktopFontSize,
    mobileFontSize,
    color,
    borderRadius,
    backgroundColor,
    url,
    isOpenInNewTab,
    horizontalPadding,
    verticalPadding,
  } = buttonSettings || {};

  const onChange = (key, value) => {
    setFunction({ [key]: value });
  };

  const onTextChange = value => {
    onChange('text', value);
  };

  const onUpdateFontAndWeight = (font: string, fontWeight: number) => {
    setFunction({ font, fontWeight });
  };

  return (
    <>
      <EditorHeader title={title} />
      <EditorMainContainer>
        <EditorOnOffToggleItem
          text="Enable"
          checked={enabled}
          onChange={() => onChange('enabled', !enabled)}
        />
        {enabled && (
          <>
            <EditorTextInputItem
              maxLength={500}
              text="Link"
              value={url}
              maxWidth="190px"
              placeholder={'https://www.your-shop.com/your-page'}
              onChange={value => onChange('url', value)}
            />
            <EditorOnOffToggleItem
              text="Open in new tab"
              checked={isOpenInNewTab}
              onChange={() => onChange('isOpenInNewTab', !isOpenInNewTab)}
            />
            <EditorFontFamilyAndWeight
              onChange={onUpdateFontAndWeight}
              value={font}
              fontWeight={fontWeight}
              fonts={typography}
            />
            <EditorItem text="Text">
              <Textarea
                className="h-8 resize-none"
                value={text}
                onChange={e => onTextChange(e.target.value)}
              />
            </EditorItem>

            <EditorNumericInputItem
              text="Desktop font size"
              suffix="px"
              onChange={value => onChange('desktopFontSize', value)}
              value={desktopFontSize}
              min={12}
              max={32}
            />
            <EditorNumericInputItem
              text="Mobile font size"
              suffix="px"
              onChange={value => onChange('mobileFontSize', value)}
              value={mobileFontSize}
              min={10}
              max={24}
            />
            <EditorColorInputItem
              text="Text color"
              color={color}
              onChange={value => onChange('color', value)}
              height="50px"
              maxWidth="160px"
              key={'color'}
            />
            <EditorColorInputItem
              text="Background color"
              color={backgroundColor}
              onChange={value => onChange('backgroundColor', value)}
              height="50px"
              maxWidth="160px"
              key={'backgroundColor'}
            />
            <EditorNumericInputItem
              text="Border radius"
              suffix="px"
              onChange={value => onChange('borderRadius', value)}
              value={borderRadius}
              min={0}
              max={20}
            />
            <EditorNumericInputItem
              text="Horizontal padding"
              suffix="px"
              onChange={value => onChange('horizontalPadding', value)}
              value={horizontalPadding}
              min={0}
              max={100}
            />
            <EditorNumericInputItem
              text="Vertical padding"
              suffix="px"
              onChange={value => onChange('verticalPadding', value)}
              value={verticalPadding}
              min={0}
              max={100}
            />
          </>
        )}
      </EditorMainContainer>
    </>
  );
};

export default HeroOverlayButtonCustomizationEditor;
