import AnalyticsHeader from 'app/src/complex_components/analytics/AnalyticsHeader';
import PricingBanner from 'app/src/complex_components/billings/badges/PricingBanner';
import {
  ANALYTICS_CONTENT_CLASS_NAME,
  CONVERSION_KEY,
  ENGAGEMENT_KEY,
  OVERVIEW_KEY,
} from 'app/src/constants/analytics.constants';
import { useAnalytics } from 'app/src/context/AnalyticsStore';
import { useFeatures } from 'app/src/context/FeaturesStore';
import useIsEcom from 'app/src/hooks/UseIsEcom';
import { useBilling } from 'app/src/hooks/useBillings';
import React, { useEffect, useState } from 'react';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { FEATURE_ANALYTICS_OVERVIEW } from 'shared/react/constants/features.constants';
import styled from 'styled-components';
import Utils from '../../utils';
import PromotionBadges from '../billings/badges/PromotionBadges';
import AnalyticsContent from './AnalyticsContent';
import AnalyticsEmptyState from './AnalyticsEmptyState';
import ComponentWithOverlayLoader from 'shared/react/components/basic/ComponentWithOverlayLoader';
import { useApps } from 'app/src/context/AppsStore';
import { useAnalyticsSearchParams } from 'app/src/complex_components/analytics/analyticsSearchParams';

const getDefaultSelectedTab = (isAnalyticsOverviewEnabled, isEcom) => {
  if (isAnalyticsOverviewEnabled && isEcom) {
    return OVERVIEW_KEY;
  }

  if (isEcom) {
    return CONVERSION_KEY;
  }

  return ENGAGEMENT_KEY;
};

const Analytics = () => {
  const [{ selectedTab: storeSelectedTab }, { setSelectedTab: setStoreSelectedTab }] =
    useAnalytics();
  const { hasReachedAnalyticsLimit: shouldBlur } = useBilling();
  const [, { getFeatureEnabled }] = useFeatures();
  const isAnalyticsOverviewEnabled = getFeatureEnabled(FEATURE_ANALYTICS_OVERVIEW);
  const isEcom = useIsEcom();
  const initialParams = useAnalyticsSearchParams();
  const [{ initialized: appsInitialized }] = useApps();
  const [selectedTab, setSelectedTab] = useState(
    initialParams.tab || getDefaultSelectedTab(isAnalyticsOverviewEnabled, isEcom)
  );

  useEffect(() => {
    if (!appsInitialized || initialParams.tab) {
      return;
    }
    setSelectedTab(getDefaultSelectedTab(isAnalyticsOverviewEnabled, isEcom));
  }, [appsInitialized]);

  useEffect(() => {
    if (shouldBlur === false && selectedTab !== storeSelectedTab) {
      setStoreSelectedTab(selectedTab);
    }
  }, [selectedTab, shouldBlur]);

  if (!appsInitialized) {
    return (
      <ComponentWithOverlayLoader size={30} isLoading={true}>
        <LayoutRoot>
          <AnalyticsEmptyState />
        </LayoutRoot>
      </ComponentWithOverlayLoader>
    );
  }

  return (
    <LayoutRoot>
      <AnalyticsEmptyState />
      {shouldBlur && (
        <BadgeContainer>
          <PricingBanner
            text="ANALYTICS"
            analyticsData={Utils.getAnalyticsLocationObject('Analytics Banner')}
          />
        </BadgeContainer>
      )}
      {!shouldBlur && <PromotionBadges tab="analytics" />}
      <StyledAccountAnalytics>
        <AnalyticsHeader
          isAnalyticsOverviewEnabled={isAnalyticsOverviewEnabled}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          shouldBlur={shouldBlur}
        />
        <AccountAnalyticsContent
          className={ANALYTICS_CONTENT_CLASS_NAME}
          shouldBlur={shouldBlur}
          isEngagement={selectedTab === ENGAGEMENT_KEY}
        >
          <AnalyticsContent
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            shouldBlur={shouldBlur}
          />
        </AccountAnalyticsContent>
      </StyledAccountAnalytics>
    </LayoutRoot>
  );
};

export default Analytics;

const LayoutRoot = styled(HorizontalFlex)`
  overflow: hidden;
  height: 100%;
  position: relative;
`;

const StyledAccountAnalytics = styled(VerticalFlex)`
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
`;

const BadgeContainer = styled.div`
  padding: 10px 20px;
`;

const AccountAnalyticsContent = styled(VerticalFlex)`
  padding: 24px;
  flex-grow: 1;
  overflow-y: ${({ shouldBlur }) => (shouldBlur ? 'hidden' : 'auto')};
  gap: 50px;
  width: 100%;
  box-sizing: border-box;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    padding: 16px;
  }
`;
