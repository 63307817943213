import { css } from 'styled-components';

export const getDesignCss = ({
  font,
  fontSize,
  fontWeight,
  fontColor,
  borderToggle,
  borderThickness,
  borderColor,
  borderRadius,
  backgroundColor,
} = {}) => css`
  && {
    font-family: ${font};
  }
  font-size: ${fontSize}px;
  font-weight: ${fontWeight};
  color: ${fontColor};
  border-radius: ${borderRadius}px;
  line-height: ${fontSize + 2}px;
  background-color: ${backgroundColor};

  ${borderToggle &&
  css`
    border: ${borderThickness}px solid ${borderColor};
  `}
`;
