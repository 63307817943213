import React from 'react';

function TextResponseButtonIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="#3D4FE0"
        d="M21.75 3.375A1.88 1.88 0 0019.875 1.5H4.125A1.85 1.85 0 002.25 3.375v2.25c0 1.078.797 1.875 1.875 1.875A1.85 1.85 0 006 5.625V5.25h4.125V19.5H8.25c-.844 0-1.5.703-1.5 1.5 0 .844.656 1.5 1.5 1.5h7.5c.797 0 1.5-.656 1.5-1.5 0-.797-.703-1.5-1.5-1.5h-1.875V5.437c0-.046-.047-.093-.047-.187H18v.375c0 1.078.797 1.875 1.875 1.875a1.851 1.851 0 001.875-1.875v-2.25z"
      ></path>
    </svg>
  );
}

export default TextResponseButtonIcon;
