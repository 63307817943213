import { PLAYER_EVENT_NAMES, PLAYER_EVENTS } from 'player-events';
import { EVENT_NAMES } from '../constants/events.constants.js';
import {
  TOLSTOY_PLAYER_READY_MESSAGE,
  TOLSTOY_WATCHED_PRODUCT_IDS_MESSAGE
} from '../constants/messages.constants.js';
import InternalMessaging from '../messaging/internal/InternalMessaging.js';
import { INTERNAL_EVENTS } from '../constants/internalEvents.constants.js';
import {
  pushTolstoyViewedProducts,
  setPublishId,
  setSessionId,
  setTolstoyInteractionDate,
  updateTolstoyActivitySessionIdByPublishId
} from './cache.js';

let reamazeUi;
let originSmileDisplay = '';

export const handleEmbedPlayerReady = event => {
  if (event.data.eventName === TOLSTOY_PLAYER_READY_MESSAGE) {
    document.querySelector('#tolstoy')?.contentWindow.postMessage(
      {
        parentUrl: `${window.location.origin}${window.location.pathname}`.slice(0, 1999),
        name: 'setTolstoyData'
      },
      '*'
    );
  }
};

const setShopifyWidgetSmileDisplay = display => {
  const smileElement = document.querySelector('#smile-ui-container');

  if (display) {
    originSmileDisplay = smileElement?.style.display || '';
  }

  smileElement?.style?.setProperty('display', display || originSmileDisplay);
};

const getTryAndCatchWrapper = event => {
  let functionIndex = 0;
  return function_ => {
    try {
      function_();
    } catch (error) {
      fetch(`https://api.gotolstoy.com/events/event`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          eventName: 'widgetError',
          text: error.message,
          timestamp: new Date().toISOString(),
          parentUrl: window.location.href,
          appKey: event.data.appKey,
          publishId: event.data.publishId,
          sessionId: event.data.sessionId,
          source: event.data.name,
          answerKey: `${functionIndex}`,
          currentPageProductId: function_.toString(),
          formData: `${((error && error.stack) || '').slice(0, 1999)}`
        })
      });
    } finally {
      functionIndex++;
    }
  };
};

export const handleTolstoyStart = event => {
  if (
    event.data.name === PLAYER_EVENT_NAMES.sessionStart ||
    event.data.name === PLAYER_EVENTS.autoplayStart
  ) {
    const tryAndCatchWrapper = getTryAndCatchWrapper(event);

    tryAndCatchWrapper(() => setPublishId(event.data.publishId));
    tryAndCatchWrapper(() => setTolstoyInteractionDate(event.data.appKey));
    tryAndCatchWrapper(() => setSessionId(event.data.sessionId));
    tryAndCatchWrapper(() =>
      updateTolstoyActivitySessionIdByPublishId(event.data.publishId, event.data.sessionId)
    );
    tryAndCatchWrapper(() => setShopifyWidgetSmileDisplay('none'));
    tryAndCatchWrapper(() => sendAnonymousIdToShopifyMessaging(event.data.anonymousId));
  }
};

export const handleTolstoyModalClose = event => {
  if (event.data.name === EVENT_NAMES.tolstoyModalClose) {
    setShopifyWidgetSmileDisplay();
  }
};

const sendAnonymousIdToShopifyMessaging = anonymousId => {
  if (!window.Shopify) {
    return;
  }

  InternalMessaging.postMessage({
    eventName: INTERNAL_EVENTS.setAnonymousIdToCart,
    anonymousId
  });
};

export const handleWatchedProductIds = ({ data: { name, publishId, productIds } = {} }) => {
  if (name !== TOLSTOY_WATCHED_PRODUCT_IDS_MESSAGE) {
    return;
  }

  for (const productId of productIds) {
    pushTolstoyViewedProducts({ productId, publishId });
  }
};

export const handleOpenIntegration = event => {
  if (OPEN_INTEGRATIONS[event.data.name]) {
    OPEN_INTEGRATIONS[event.data.name](event.data);
  }
};

export const handleHideIntegration = event => {
  if (HIDE_INTEGRATIONS[event.data.name]) {
    setTimeout(() => HIDE_INTEGRATIONS[event.data.name](), 500);
  }
};

// https://docs.gorgias.com/gorgias-chat/advanced-customization-new-chat
const handleOpenGorgias = () => {
  if (window.GorgiasChat) {
    window.GorgiasChat.open?.();
    window.tolstoyWidget.hide();
    document.head.insertAdjacentHTML(
      'beforeend',
      `<style>#gorgias-chat-container iframe#chat-button { visibility: visible !important; display: block !important; }</style>`
    );
  }
};

const handleHideGorgias = () => {
  if (window.GorgiasChat) {
    window.GorgiasChat.close?.();
    document.head.insertAdjacentHTML(
      'beforeend',
      `<style>#gorgias-chat-container iframe#chat-button { visibility: hidden; display: none; }</style>`
    );
  }
};

// https://developers.ada.cx/reference/customize-chat
const handleOpenAda = () => {
  if (window.adaEmbed) {
    window.adaEmbed.toggle();
    window.tolstoyWidget.hide();
  }
};

const handleHideAda = () => {
  document.head.insertAdjacentHTML(
    'beforeend',
    `<style>#ada-button-frame { display: none; }</style>`
  );
};

// https://developers.freshchat.com/web-sdk/v2/
const handleOpenFreshChat = () => {
  if (window.fcWidget) {
    window.fcWidget.show();
    window.fcWidget.open();
    window.tolstoyWidget.hide();
  }
};

const handleHideFreshChat = () => {
  if (window.fcWidget) {
    window.fcWidget.close();
    window.fcWidget.hide();
    window.tolstoyWidget.show();
  }
};

// https://www.reamaze.com/developer
const handleOpenReamaze = () => {
  if (!reamazeUi && window.Reamaze?.popup) {
    window.Reamaze.popup();
    window.tolstoyWidget.hide();
    return;
  }

  if (window._support && reamazeUi && window.Reamaze?.reload) {
    window._support.ui = reamazeUi;
    window.Reamaze.reload();
    reamazeUi = null;
    window.tolstoyWidget.hide();
  }
};

const handleHideReamaze = () => {
  if (window._support?.ui && window.Reamaze?.reload) {
    reamazeUi = window._support.ui;
    delete window._support.ui;
    window.Reamaze.reload();
    window.tolstoyWidget.show();
  }
};

// https://developers.intercom.com/installing-intercom/docs/intercom-javascript
const handleOpenIntercom = () => {
  const timeout = setTimeout(() => clearInterval(interval), 9000);
  const interval = setInterval(() => {
    if (window.Intercom) {
      window.Intercom('show');
      window.tolstoyWidget.hide();

      clearInterval(interval);
      clearTimeout(timeout);

      window.Intercom('onHide', () => {
        window.tolstoyWidget.show();
      });
    }
  });
};

const handleHideIntercom = () => {
  const timeout = setTimeout(() => clearInterval(interval), 9000);
  const interval = setInterval(() => {
    if (window.Intercom) {
      window.Intercom('update', { hide_default_launcher: true });

      clearInterval(interval);
      clearTimeout(timeout);
    }
  }, 100);
};

// https://developer.tawk.to/jsapi/
const handleOpenTawkTo = () => {
  if (window.Tawk_API) {
    window.Tawk_API.maximize();
    window.tolstoyWidget.hide();
  }
};

function handleHideTawkTo() {
  if (window.Tawk_API) {
    window.Tawk_API.hideWidget();
  }
}

// https://developers.livechat.com/docs/extending-chat-widget/javascript-api/#about-the-api
const handleOpenLiveChat = () => {
  if (window.LiveChatWidget) {
    window.LiveChatWidget.call('maximize');
    window.tolstoyWidget.hide();
  }
};

function handleHideLiveChat() {
  if (window.LiveChatWidget) {
    window.LiveChatWidget.call('hide');
  }
}

// https://legacydocs.hubspot.com/docs/methods/conversations_api/widget_open
const handleOpenHubSpot = () => {
  if (window.HubSpotConversations && window.HubSpotConversations.widget) {
    window.HubSpotConversations.widget.open();
    window.tolstoyWidget.hide();
  }
};

function handleHideHubSpot() {
  if (window.HubSpotConversations && window.HubSpotConversations.widget) {
    window.HubSpotConversations.widget.close();
  }
}

// https://developer.helpscout.com/beacon-2/web/javascript-api/
const handleOpenHelpScout = () => {
  if (window.Beacon) {
    window.Beacon('open');
    window.tolstoyWidget.hide();
    window.Beacon('config', { display: { zIndex: '99999' } });
  }
};

const handleHideHelpScout = () => {
  if (window.Beacon) {
    window.Beacon('config', { display: { zIndex: '0' } });
  }
};

// https://devdocs.drift.com/docs/widget-start
const handleOpenDrift = () => {
  if (window.drift) {
    window.drift.api.toggleChat();
    window.tolstoyWidget.hide();
  }
};

const handleHideDrift = () => {
  if (window.drift) {
    window.drift.api.widget.hide();
  }
};

// https://developer.zendesk.com/documentation/zendesk-web-widget-sdks/sdks/web/sdk_api_reference/
const handleOpenZendesk = () => {
  if (window.zE) {
    window.zE('messenger', 'open');
    document
      .querySelector('[title="Button to launch messaging window"]')
      ?.style?.setProperty('display', 'block');
    window.tolstoyWidget.hide();
  }
};

const handleHideZendesk = () => {
  const timeout = setTimeout(() => clearInterval(interval), 5000);
  const interval = setInterval(() => {
    const zendekButton = document.querySelector('[title="Button to launch messaging window"]');
    const zendeMessage = document.querySelector('[title="Message from company"]');

    zendekButton?.style?.setProperty('display', 'none');
    zendeMessage?.parentElement?.style?.setProperty('display', 'none');

    if (zendekButton && zendeMessage) {
      clearInterval(interval);
      clearTimeout(timeout);
    }
  }, 100);

  if (window.zE) {
    window.zE('messenger', 'close');
  }
};

// https://docs.tidio.com/docs/other_methods
const handleOpenTidio = () => {
  if (window.tidioChatApi) {
    window.tidioChatApi.show();
    window.tidioChatApi.open();
    window.tolstoyWidget.hide();
  }
};

const handleHideTidio = () => {
  if (window.tidioChatApi) {
    window.tidioChatApi.close();
  }
};

// https://docs.tidio.com/docs/other_methods
const handleOpenCertainly = eventData => {
  if (window.certainly) {
    window.certainly.widgetStatus({ action: 'open' });
    window.tolstoyWidget.hide();

    // Settings for Festables
    switch (eventData.data.key) {
      case 'PmkMI': // Order support
        window.certainly.goTo({ module: '670442' });
        break;
      case '_23Hl': // Where's my order?
        window.certainly.goTo({ module: '670178' });
        break;
      case 'P9rfq': // Other info jams
        window.certainly.goTo({ module: '670179' });
        break;
      default:
        break;
    }
  }
};

const handleHidCertainly = () => {
  if (window.certainly) {
    window.certainly.widgetStatus({ action: 'hide' });
  }
};

const hideElementsBySelector = selector => {
  document.head.insertAdjacentHTML(
    'beforeend',
    `<style>${selector} { visibility: hidden; display: none; }</style>`
  );
};

const handleHideVfChat = () => {
  hideElementsBySelector('#vfChat+div');
  hideElementsBySelector('#vfChat+div+div');
};

const HIDE_FRESH_CHAT = 'tolstoyHideFreshChat';
const OPEN_FRESH_CHAT = 'tolstoyOpenFreshChat';
const HIDE_REAMAZE = 'tolstoyHideReamaze';
const OPEN_REAMAZE = 'tolstoyOpenReamaze';
const HIDE_ADA = 'tolstoyHideAda';
const OPEN_ADA = 'tolstoyOpenAda';
const HIDE_TIDIO = 'tolstoyHideTidio';
const OPEN_TIDIO = 'tolstoyOpenTidio';
const HIDE_CERTAINLY = 'tolstoyHideCertainly';
const OPEN_CERTAINLY = 'tolstoyOpenCertainly';
const HIDE_VF_CHAT = 'tolstoyHideVfChat';

const OPEN_INTEGRATIONS = {
  [PLAYER_EVENT_NAMES.openGorgias]: handleOpenGorgias,
  [PLAYER_EVENT_NAMES.openIntercom]: handleOpenIntercom,
  [PLAYER_EVENT_NAMES.openTawkTo]: handleOpenTawkTo,
  [PLAYER_EVENT_NAMES.openLiveChat]: handleOpenLiveChat,
  [PLAYER_EVENT_NAMES.openHubSpot]: handleOpenHubSpot,
  [PLAYER_EVENT_NAMES.openDrift]: handleOpenDrift,
  [PLAYER_EVENT_NAMES.openZendesk]: handleOpenZendesk,
  [PLAYER_EVENT_NAMES.openHelpScout]: handleOpenHelpScout,
  [OPEN_FRESH_CHAT]: handleOpenFreshChat,
  [OPEN_REAMAZE]: handleOpenReamaze,
  [OPEN_ADA]: handleOpenAda,
  [OPEN_TIDIO]: handleOpenTidio,
  [OPEN_CERTAINLY]: handleOpenCertainly
};

const HIDE_INTEGRATIONS = {
  [PLAYER_EVENT_NAMES.hideGorgias]: handleHideGorgias,
  [PLAYER_EVENT_NAMES.hideIntercom]: handleHideIntercom,
  [PLAYER_EVENT_NAMES.hideTawkTo]: handleHideTawkTo,
  [PLAYER_EVENT_NAMES.hideLiveChat]: handleHideLiveChat,
  [PLAYER_EVENT_NAMES.hideHubSpot]: handleHideHubSpot,
  [PLAYER_EVENT_NAMES.hideDrift]: handleHideDrift,
  [PLAYER_EVENT_NAMES.hideZendesk]: handleHideZendesk,
  [PLAYER_EVENT_NAMES.hideHelpScout]: handleHideHelpScout,
  [HIDE_FRESH_CHAT]: handleHideFreshChat,
  [HIDE_REAMAZE]: handleHideReamaze,
  [HIDE_ADA]: handleHideAda,
  [HIDE_VF_CHAT]: handleHideVfChat,
  [HIDE_TIDIO]: handleHideTidio,
  [HIDE_CERTAINLY]: handleHidCertainly
};
