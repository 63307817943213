import { useVideoActions } from 'src/context/VideosStore';
import { useVodConnectionActions } from 'src/context/VodConnectionStore';
import { CreateVodConnectionInput } from 'src/types/entities';

type CreateVodConnectionArgs = {
  vodAssetId: string;
} & CreateVodConnectionInput;

type CreateMultipleVodConnectionsArgs = {
  vodAssetIds: string[];
} & CreateVodConnectionInput;

const useCreateVodConnection = () => {
  const { createVodConnection } = useVodConnectionActions();
  const { getVideoById, updateVideo } = useVideoActions();

  const create = async ({ vodAssetId, ...args }: CreateVodConnectionArgs) => {
    const promises = [];

    const video = getVideoById(vodAssetId);

    const videoConnections = video?.vodConnections?.items;
    // Ignoring the next line because all parameters are being inferred as any in JS file
    // @ts-ignore-next-line
    promises.push(createVodConnection({ vodAssetId, videoConnections, ...args }));

    if (video && !video.isEverUsed) {
      video.isEverUsed = true;
      promises.push(updateVideo({ id: vodAssetId, isEverUsed: true }));
    }

    return Promise.all(promises);
  };

  const createMultiple = async ({ vodAssetIds, ...args }: CreateMultipleVodConnectionsArgs) => {
    const uniqueVodAssetIds = [...new Set(vodAssetIds)];

    const promises = uniqueVodAssetIds.map((vodAssetId: string) => create({ vodAssetId, ...args }));

    return Promise.all(promises);
  };

  return { createVodConnection: create, createMultipleVodConnections: createMultiple };
};

export default useCreateVodConnection;
