import React from 'react';

const AccountArrowsIcon = () => {
  return (
    <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.656 14.875a.432.432 0 0 1-.187-.344c0-.125.062-.25.156-.344l5.656-6.156L.625 1.844a.465.465 0 0 1 .031-.688.465.465 0 0 1 .688.032l6 6.5a.453.453 0 0 1 0 .656l-6 6.5a.465.465 0 0 1-.688.031Z"
        fill="#7D8087"
      />
    </svg>
  );
};

export default AccountArrowsIcon;
