import React, { lazy, Suspense, useEffect, useState } from 'react';
import styled from 'styled-components';
import { postMessage } from 'player/src/components/player/controls/controls.utils';
import {
  accountsWithBrandLogo,
  accountsWithCustomButtons,
  accountsWithCustomChapterPicker,
  accountsWithCustomEndScreens,
  accountsWithCustomFreeTextText,
  accountsWithCustomLeadFormText,
  accountsWithCustomStart,
  accountsWithLogRocket,
  accountsWithNoPoweredBy,
  accountsWithNoViralEndScreens,
  accountsWithoutFullscreen,
  accountsWithSemiPoweredBy,
  accountsWithSubtitles,
  projectsWithChapters,
  projectsWithCustomEndScreens,
  projectsWithCustomLayout,
  projectsWithCustomStart,
  projectsWithNoBackButton,
  projectsWithNoPoweredBy,
  projectsWithSemiPoweredBy,
  projectsWithSubtitles,
} from 'player/src/customData/customerSettings';

import { getCurrentSessionFromLocalStorage } from 'player/src/actions/localStorage.actions';
import { addUrlParamsToEventsData } from 'player/src/actions/eventData.actions';

import { getResponses } from 'player/src/actions/response.actions';
import { initAnalytics } from 'player/src/tracker/Tracker';
import Utils from 'player/src/utils';
import { findAnswerByType } from 'player/src/components/player/player.utils';
import { PLAYER_EVENT_NAMES } from 'player-events';
import {
  FEATURE_INTERACTION_DATE_VERIFICATION,
  FEATURE_PLAYER_BUTTONS,
  FEATURE_PLAYER_RESOLUTION,
} from 'shared/react/constants/features.constants';
import { APP_CONTAINER_CLASS_NAME } from 'player/src/constants/tolstoy.constants';
import tolstoyIconName from 'player/src/assets/tolstoy_logo_name.svg';
import SiteTags from 'player/src/components/SiteTags';
import { constructEventsData, getCustomParams, getUrlParams } from 'player/src/tracker/EventsData';
import { sentryConfigureScope } from 'player/src/utils/sentry.utils';
import { logRocketLoad, logRocketRunOnLoad } from 'player/src/utils/log-rocket.utils';
import { useIsMobile } from 'shared/react/hooks/useIsMobile';
import LandingPageContent from 'player/src/components/player/LandingPageContent';
import { PLAYER_RESOLUTIONS } from 'player/src/constants/playerResolutions.constants';
import { getIsWhiteLabelEnabled } from '../../utils/features.utils';
import RootProviders from '../feed/RootProviders';
import Interactive from './Interactive';
import VideoWasntFound from './VideoWasntFound';
import { setAuthToken } from 'shared/react/utils/wishlist.utils';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';

const ThreadContainer = lazy(() =>
  Utils.componentLoader(() =>
    import(/* webpackChunkName: "thread-page" */ '../thread/ThreadContainer')
  )
);

const PlayerWrapper = ({ config, products, isProductsInitialized }) => {
  const [state, setState] = useState({});
  const [isReply, setReply] = useState(
    window.location.href.includes('sessionId') && window.location.href.includes('replyProjectId')
  );
  const [landingPageLogoSrc, setLandingPageLogoSrc] = useState('');
  const isMobile = useIsMobile();
  const urlParams = new URLSearchParams(location.search);
  const disableThread = urlParams.get('disableThread') === 'true';
  const isWhiteLabelEnabled = getIsWhiteLabelEnabled(config);
  const [{ appUrl }] = useProjectConfig();

  const {
    appKey,
    accountId,
    landingPage,
    showAccountLogo,
    playlist,
    startStep,
    conversationName,
    projectId,
    verticalOrientation,
  } = state;

  useEffect(() => {
    if (!config) {
      return;
    }

    configProject(config);
  }, [config]);

  useEffect(() => {
    if (Utils.isNullOrUndefined(showAccountLogo)) {
      return;
    }

    if (showAccountLogo) {
      const accountLogoSrc = Utils.getAccountLogoUrl(appKey);
      setLandingPageLogoSrc(accountLogoSrc);
      return;
    }

    setLandingPageLogoSrc(tolstoyIconName);
  }, [showAccountLogo]);

  const getCustomStartText = (
    configId,
    accountStartScreenText,
    accountId,
    projectStartScreenText
  ) => {
    if (projectsWithCustomStart[configId]) {
      return projectsWithCustomStart[configId];
    }

    if (!Utils.isNullOrUndefined(projectStartScreenText)) {
      return projectStartScreenText;
    }

    if (Utils.isNullOrUndefined(accountStartScreenText)) {
      return accountsWithCustomStart[accountId];
    }

    return accountStartScreenText;
  };

  const getConfigFeatureSettings = (featureSettings = {}) => {
    let data = {};

    Object.values(featureSettings).forEach(featureValue => {
      data = { ...data, ...featureValue };
    });

    return data;
  };

  async function configProject(config) {
    let hasLogRocket = false;
    if (config.disabled) {
      return;
    }

    if (config.id === 'eb142988-c5fa-4352-b019-6868fdeb3b4b') {
      postMessage('tolstoyHideGorgias');
    }

    if (accountsWithLogRocket.includes(config.appKey)) {
      const onLoad = () => {
        window.LogRocket?.init('bhpcm6/a');
        window.LogRocket?.getSessionURL(sessionURL => {
          sentryConfigureScope({ key: 'sessionURL', value: sessionURL });
        });
      };
      logRocketLoad();
      logRocketRunOnLoad(onLoad);

      hasLogRocket = true;
    }

    const accountId = config.userId;
    const params = new URLSearchParams(window.location.search);
    const layout = params.get('layout');
    let name = '';

    const eventsData = constructEventsData(config);

    const currentSession = getCurrentSessionFromLocalStorage(config.publishId);

    if (currentSession?.publishId) {
      setReply(true);
    }

    addUrlParamsToEventsData(eventsData, currentSession);
    const passedParams = getUrlParams();

    let res;
    if (eventsData.replyProjectId && eventsData.sessionId) {
      res = (await getResponses(eventsData.sessionId, eventsData.replyProjectId)) || {};
    }

    if (config.collectInfo) {
      if (eventsData.email) {
        config.collectInfo.hasEmail = true;
      }
      config.collectInfo.fields = config.collectInfo?.fields?.filter(field => {
        return !eventsData[field.key];
      });
    }

    const isNoCollect = config.collectInfo?.fields?.every(field => {
      return !field.collect;
    });

    if (isNoCollect) {
      if (config.collectInfo?.afterStep) {
        config.collectInfo.afterStep = [];
        config.collectInfo.hasEmail = true;
      }
    }

    const customParams = getCustomParams();
    initAnalytics(eventsData, passedParams.delayPageView, customParams, config.featureSettings);

    let start = params.get('start');
    if (start && config.steps[start - 1]) {
      start = config.steps[start - 1].key;
    } else {
      start = config.startStep || config.steps[0].key;
    }

    const chatAnswer = findAnswerByType(config.steps, 'Chat');
    if (chatAnswer) {
      window.parent.postMessage(
        { name: PLAYER_EVENT_NAMES[`hide${chatAnswer.value.replace('Open', '')}`] },
        '*'
      );
    }

    const {
      hidePoweredBy,
      semiPoweredBy,
      showAccountLogo,
      noViralEndScreen,
      hideStartScreenDefaultText,
    } = getConfigFeatureSettings(config.featureSettings);

    const {
      [FEATURE_INTERACTION_DATE_VERIFICATION]: tolstoyInteractionDateVerification,
      [FEATURE_PLAYER_BUTTONS]: buttonsSettings,
      [FEATURE_PLAYER_RESOLUTION]: playerResolution = {
        minResolution: PLAYER_RESOLUTIONS['640'],
        mobileMinResolution: PLAYER_RESOLUTIONS['640'],
      },
    } = config.featureSettings;

    const { startScreenText, icons = {} } = config?.design?.player?.branching || {};
    const { iconColor: brandColor, shouldDisplayIcon: showButtonsIndex } = icons;
    const borderRadius = config?.design?.player?.feedCards?.buttonsDesign?.primary?.border?.radius;

    if (hasLogRocket && eventsData.sessionId) {
      logRocketRunOnLoad(() => {
        window.LogRocket?.identify(eventsData.sessionId, { email: eventsData.email });
      });
    }

    await setAuthToken(eventsData.customerId, appUrl);

    setState({
      appKey: config.appKey,
      token: passedParams.token,
      tolstoyType: config.tolstoyType,
      conversationName: config.name,
      hasSubtitles:
        projectsWithSubtitles.includes(config.id) ||
        accountsWithSubtitles.includes(accountId) ||
        config.subtitlesEnabled,
      hasChapters: projectsWithChapters.includes(config.id) || config.chaptersEnabled,
      hidePoweredBy:
        hidePoweredBy ||
        config.hidePoweredBy ||
        projectsWithNoPoweredBy.includes(config.id) ||
        accountsWithNoPoweredBy.includes(accountId),
      hideStartScreenDefaultText: hideStartScreenDefaultText || config.hideStartScreenDefaultText,
      semiPoweredBy:
        semiPoweredBy ||
        config.semiPoweredBy ||
        projectsWithSemiPoweredBy.includes(config.id) ||
        accountsWithSemiPoweredBy.includes(accountId),
      hideBackButton: projectsWithNoBackButton.includes(config.id),
      showAccountLogo:
        showAccountLogo || config.showAccountLogo || accountsWithBrandLogo.includes(accountId),
      customEndScreen:
        projectsWithCustomEndScreens[config.id] || accountsWithCustomEndScreens[accountId],
      noViralEndScreen:
        noViralEndScreen ||
        config.noViralEndScreen ||
        accountsWithNoViralEndScreens.includes(accountId),
      customStart: getCustomStartText(
        config.id,
        startScreenText,
        accountId,
        config.startScreenText
      ),
      customLeadFormText: accountsWithCustomLeadFormText[accountId] || {},
      customButtonsText: accountsWithCustomButtons[accountId] || {},
      customFreeTextText: accountsWithCustomFreeTextText[accountId] || {},
      customChapterPicker: accountsWithCustomChapterPicker[accountId] || {},
      customLayout: projectsWithCustomLayout[config.id] || {},
      baseUrl: config.baseUrl,
      cacheLeadFormAnswers: config.cacheLeadFormAnswers,
      layout,
      startStep: start,
      hidePlay: params.has('hide_play'),
      ...passedParams,
      name,
      pause: params.has('pause'),
      landingPage: !params.has('host') || Utils.getIsAppPreview(),
      playlist: config.steps,
      wide: params.has('wide'),
      startPlay: !window.safari && params.has('play'),
      collectInfo: config.collectInfo,
      verticalOrientation: config.verticalOrientation,
      projectId: config.id,
      accountId: accountId,
      previewMode: eventsData.preview || Utils.getIsAppPreview(),
      customerId: eventsData.customerId,
      fastForwardEnabled: config.fastForwardEnabled,
      replyPublishId: eventsData.replyPublishId,
      intercomConversationId: eventsData.intercomConversationId,
      videoResolution: eventsData.videoResolution,
      parentUrl: eventsData.parentUrl,
      isReply: isReply,
      disableFullscreen: accountsWithoutFullscreen.includes(accountId),
      chatLandingPage: config.chatLandingPage,
      publishId: config.publishId,
      email: eventsData.email,
      autoplay: config.autoplay,
      brandColor,
      borderRadius,
      tolstoyInteractionDateVerification,
      buttonsSettings,
      language: config?.language || config?.design?.player?.settings?.language,
      playerResolution,
      showButtonsIndex: config.showButtonsIndex ?? showButtonsIndex,
      ...res,
    });
  }

  const onLandingPageLogoError = () => {
    setLandingPageLogoSrc(tolstoyIconName);
    setState({ ...state, showAccountLogo: false });
  };

  const getShowLandingPageHeader = () => {
    if (Utils.getIsAppPreview()) {
      return false;
    }

    if (isMobile) {
      return false;
    }

    if (config?.disabled) {
      return true;
    }

    if (landingPage) {
      return true;
    }

    if (state?.isReply) {
      return true;
    }

    return false;
  };

  const getShowLandingPage = () => {
    if (disableThread) {
      return false;
    }

    if (landingPage && (state?.chatLandingPage || isReply)) {
      return true;
    }

    return false;
  };

  const shouldShowLandingPageHeader = getShowLandingPageHeader();
  const shouldShowChatLandingPage = getShowLandingPage();

  if (!config) {
    return null;
  }

  if (config?.disabled) {
    return <VideoWasntFound />;
  }

  return (
    <RootProviders
      config={config}
      products={products}
      isProductsInitialized={isProductsInitialized}
    >
      <div className={APP_CONTAINER_CLASS_NAME}>
        <SiteTags
          conversationName={conversationName}
          accountId={accountId}
          startStep={startStep}
          playlist={playlist}
          verticalOrientation={verticalOrientation}
        />
        {shouldShowLandingPageHeader && (
          <LandingHeaderContainer isReply={isReply}>
            <LandingPageContent
              landingPageLogoSrc={landingPageLogoSrc}
              showAccountLogo={showAccountLogo}
              onLandingPageLogoError={onLandingPageLogoError}
              state={state}
              projectId={projectId}
              isWhiteLabelEnabled={isWhiteLabelEnabled}
            />
          </LandingHeaderContainer>
        )}
        <Interactive
          config={config}
          state={state}
          shouldShowChatLandingPage={shouldShowChatLandingPage}
        />
        {projectId === 'a16bc09a-da75-4761-b1c9-fb01235eb7b7' && !state?.chatLandingPage && (
          <AccountContactContainer>
            Yotpo Contact Information:{' '}
            <a rel="noopener noreferrer" target="_blank" href="mailto:ups@yotpo.com">
              ups@yotpo.com
            </a>
          </AccountContactContainer>
        )}
        {shouldShowChatLandingPage && (
          <Suspense fallback={null}>
            <ThreadContainer
              isChatLandingPage={state?.chatLandingPage}
              isReply={isReply}
              playerState={state}
              appKey={appKey}
              updateSessionId={() => setReply(true)}
            />
          </Suspense>
        )}
      </div>
    </RootProviders>
  );
};

const LandingHeaderContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 2px 4px rgba(81, 107, 152, 0.08);
  min-height: 71px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 999999;
  ${({ isReply }) => isReply && 'z-index: 45'};

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    display: none;
    padding-left: 10px;
    max-height: 100%;
  }

  @media (max-height: 500px) {
    display: none;
  }
`;

const AccountContactContainer = styled.div`
  margin: 50px 0;
  color: ${({ theme }) => theme.colors.black};
`;

export default PlayerWrapper;
