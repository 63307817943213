import React from 'react';
import { useTheme } from 'styled-components';

function Trash({ fill, width, height }) {
  const theme = useTheme();

  return (
    <svg
      width={width || '12'}
      height={height || '13'}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.875 2.375H8.92969L8.13281 1.0625C7.92188 0.710938 7.57031 0.5 7.17188 0.5H4.80469C4.40625 0.5 4.05469 0.710938 3.84375 1.0625L3.04688 2.375H1.125C0.914062 2.375 0.75 2.5625 0.75 2.75V3.125C0.75 3.33594 0.914062 3.5 1.125 3.5H1.5V11C1.5 11.8438 2.15625 12.5 3 12.5H9C9.82031 12.5 10.5 11.8438 10.5 11V3.5H10.875C11.0625 3.5 11.25 3.33594 11.25 3.125V2.75C11.25 2.5625 11.0625 2.375 10.875 2.375ZM4.75781 1.69531C4.78125 1.67188 4.82812 1.625 4.89844 1.625H7.10156C7.14844 1.625 7.19531 1.67188 7.21875 1.69531L7.61719 2.375H4.35938L4.75781 1.69531ZM9 11.375H3C2.78906 11.375 2.625 11.2109 2.625 11V3.5H9.375V11C9.375 11.2109 9.1875 11.375 9 11.375ZM6 10.25C6.1875 10.25 6.375 10.0859 6.375 9.875V5C6.375 4.8125 6.1875 4.625 6 4.625C5.78906 4.625 5.625 4.8125 5.625 5V9.875C5.625 10.0859 5.78906 10.25 6 10.25ZM4.125 10.25C4.3125 10.25 4.5 10.0859 4.5 9.875V5C4.5 4.8125 4.3125 4.625 4.125 4.625C3.91406 4.625 3.75 4.8125 3.75 5V9.875C3.75 10.0859 3.91406 10.25 4.125 10.25ZM7.875 10.25C8.0625 10.25 8.25 10.0859 8.25 9.875V5C8.25 4.8125 8.0625 4.625 7.875 4.625C7.66406 4.625 7.5 4.8125 7.5 5V9.875C7.5 10.0859 7.66406 10.25 7.875 10.25Z"
        fill={fill || theme.colors.dangerDark}
      />
    </svg>
  );
}

export default Trash;
