import React, { useState } from 'react';
import { allConditionsTitles } from 'app/src/pages/project/pages/rules_and_triggers/Rule.constants';
import { URL_KEY } from 'app/src/constants/ruleGroups.constants';
import styled from 'styled-components';
import AnswerEdit from '../review_parts/AnswerEdit';
import { TextBody } from 'shared/react/components/complex/Text';

const RuleEditUrlItem = ({ rule }) => {
  const condition = allConditionsTitles.find(({ key }) => rule.condition === key)?.title;
  const [url, setUrl] = useState(rule.value);

  const updateUrl = newValue => {
    rule.value = newValue;
    setUrl(newValue);
  };

  if (rule.type !== URL_KEY) {
    return null;
  }

  return (
    <LayoutRoot>
      <TextBody>
        {URL_KEY.toUpperCase()} : <ItalicText>{condition}</ItalicText>
      </TextBody>
      <AnswerEdit value={url} updateUrl={updateUrl} />
    </LayoutRoot>
  );
};

export default RuleEditUrlItem;

const LayoutRoot = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  justify-items: end;
  align-items: center;
  width: 100%;
  gap: 16px;
`;

const ItalicText = styled.span`
  font-style: italic;
`;
