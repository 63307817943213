import * as React from 'react';

const QuizMakeDefaultIcon = ({ ...props }) => (
  <svg
    width={18}
    height={16}
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18 7.75a.757.757 0 0 0-.281-.563l-8.25-7A.689.689 0 0 0 9 0a.71.71 0 0 0-.5.188l-8.25 7A.719.719 0 0 0 0 7.75c0 .531.406.75.75.75.156 0 .344-.031.469-.156L2 7.688v7.062c0 .719.531 1.25 1.25 1.25h3c.688 0 1.25-.531 1.25-1.25V11h3v3.75c0 .719.531 1.25 1.219 1.25h3c.719 0 1.25-.531 1.25-1.25V7.687l.781.657c.125.125.313.156.469.156.343 0 .781-.25.781-.75Zm-3.531 6.75h-2.5v-3.75c0-.688-.531-1.25-1.219-1.25h-3.5c-.719 0-1.25.563-1.25 1.25v3.75H3.5v-8s-.031-.031-.031-.063L9 1.75l5.469 4.656V14.5Z"
      fill="#7D8087"
    />
  </svg>
);

export default QuizMakeDefaultIcon;
