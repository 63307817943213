import {
  UGC_INSTAGRAM_GRAPH,
  UGC_INSTAGRAM_GRAPH_DENIED,
  UGC_INSTAGRAM_GRAPH_PENDING,
} from 'src/constants/intergrations.constants';
import { assetType as AssetType } from 'src/types/entities';
import { Filters } from './types';

export const GRID_BREAKPOINTS = [
  { width: 1600, columns: 6 },
  { width: 1320, columns: 5 },
  { width: 992, columns: 4 },
  { width: 768, columns: 3 },
  { width: 576, columns: 2 },
  { width: 0, columns: 1 },
];

export const GRID_ROW_HEIGHT = 270;
export const GRID_GAP = 24;

export enum SortField {
  CreatedAt = 'createdAt',
  LikeCount = 'likeCount',
}

export enum SortOrder {
  DESC = 'desc',
  ASC = 'asc',
}

export const SORT_FIELDS = [
  { value: SortField.CreatedAt, label: 'Date' },
  { value: SortField.LikeCount, label: 'Likes' },
];

export const DEFAULT_SORT = {
  field: SortField.CreatedAt,
  order: SortOrder.DESC,
};

export const ORDER_LABEL = {
  [SortOrder.DESC]: 'Desc.',
  [SortOrder.ASC]: 'Asc.',
};

export enum FilterType {
  Source = 'source',
  UgcStatus = 'ugcStatus',
  MediaType = 'mediaType',
}

export const GROUP_LABEL = {
  SOURCE: 'Source',
  UGC_STATUS: 'Usage rights',
  MEDIA_TYPE: 'Media type',
};

export const UGC_STATUS_FILTERS = [
  {
    label: 'Not requested',
    value: '',
    type: FilterType.UgcStatus,
  },
  {
    label: 'Pending creator approval',
    value: UGC_INSTAGRAM_GRAPH_PENDING,
    type: FilterType.UgcStatus,
  },
  {
    label: 'Approved',
    value: UGC_INSTAGRAM_GRAPH,
    type: FilterType.UgcStatus,
  },
  {
    label: 'Denied',
    value: UGC_INSTAGRAM_GRAPH_DENIED,
    type: FilterType.UgcStatus,
  },
];

export const MEDIA_TYPE_FILTERS = [
  {
    label: 'Video',
    value: AssetType.video,
    type: FilterType.MediaType,
  },
  {
    label: 'Image',
    value: AssetType.image,
    type: FilterType.MediaType,
  },
  {
    label: 'Image carousel',
    value: AssetType.gallery,
    type: FilterType.MediaType,
  },
];

export const SEARCH_FILTERS: Filters = {
  [GROUP_LABEL.UGC_STATUS]: UGC_STATUS_FILTERS,
  [GROUP_LABEL.MEDIA_TYPE]: MEDIA_TYPE_FILTERS,
};
