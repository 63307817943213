import React, { FC } from 'react';

type Props = {
  className?: string;
  width?: number;
  height?: number;
};

const CrossedEyeIcon: FC<Props> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6263 5.36055C12.2744 4.67771 12.7737 3.93603 13.1242 3.29023C13.3236 2.92286 13.0442 2.5 12.6263 2.5C12.4162 2.5 12.2225 2.61158 12.1127 2.7906C11.8169 3.2726 11.1939 4.23745 10.6345 4.77488C9.70103 5.67536 8.49438 6.36256 7.01452 6.36256C5.51189 6.36256 4.30524 5.67536 3.37179 4.77488C2.71969 4.14837 2.22723 3.43238 1.88538 2.81467C1.77977 2.62382 1.58177 2.5 1.36366 2.5C0.953466 2.5 0.679253 2.91501 0.874292 3.27586C1.22515 3.92502 1.72705 4.67263 2.37999 5.36054L1.25013 6.97415C1.09174 7.20035 1.14671 7.51213 1.37292 7.67051C1.59912 7.8289 1.91089 7.77393 2.06928 7.54773L3.11843 6.04939C4.02113 6.78753 5.156 7.36035 6.50314 7.47785V9C6.50314 9.27614 6.72699 9.5 7.00314 9.5C7.27928 9.5 7.50314 9.27614 7.50314 9V7.47931C8.84341 7.3652 9.98172 6.79067 10.8879 6.04943L11.9371 7.54788C12.0955 7.77408 12.4072 7.82906 12.6334 7.67067C12.8596 7.51228 12.9146 7.20051 12.7562 6.9743L11.6263 5.36055Z"
        fill="#7D8087"
      />
    </svg>
  );
};

export default CrossedEyeIcon;
