import MenuWithItems from 'app/src/complex_components/menu_items/MenuWithItems';
import { useVodLabels } from 'app/src/context/VodLabelsStore';
import useCreateIntegrationLabels from 'app/src/hooks/useCreateIntegrationLabels';
import { VideosPageVodAsset } from 'app/src/pages/dashboard/pages/videos/types/videos.types';
import { AnalyticsDataProps, AnchorElementProps } from 'app/src/types/common/common';
import React from 'react';
import useKeyPress from 'shared/react/hooks/useKeyPress';
import { track } from 'src/helpers/Tracker';

type Props = AnchorElementProps & {
  searchValue: string;
  video: VideosPageVodAsset;
  onClose: () => void;
  connectionLabels: string[];
  setAddingLabelText: (addingLabelText: string) => void;
  setSearchValue: (searchValue: string) => void;
  onSelectedVideosAction?: (video: VideosPageVodAsset[] | null) => void;
  analyticsData?: AnalyticsDataProps;
};

const VideoLabelsMenu = ({
  anchorElement,
  searchValue,
  setSearchValue,
  video,
  onClose,
  connectionLabels,
  setAddingLabelText,
  onSelectedVideosAction,
  analyticsData,
}: Props) => {
  const [{ vodLabels }] = useVodLabels();
  const { createIntegrationLabels } = useCreateIntegrationLabels();

  const onAddLabel = async (name: string, id: string | null) => {
    track('Video Label Added', analyticsData);
    setAddingLabelText(name);
    onSelectedVideosAction?.([video]);
    await createIntegrationLabels([name], video);
    setAddingLabelText('');
    setSearchValue('');
  };

  const getFilteredLabels = () => {
    let valueExists = false;
    const filteredLabels = vodLabels.filter(label => {
      if (label.name.toLocaleLowerCase() === searchValue.toLocaleLowerCase()) {
        valueExists = true;
      }

      if (connectionLabels.includes(label.id)) {
        return false;
      }

      return label.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase());
    });

    return { filteredLabels, valueExists };
  };

  const sortItems = (a, b) => {
    if (a.text === searchValue) {
      return -1;
    }

    if (b.text === searchValue) {
      return 1;
    }

    return 0;
  };

  const getLabelItems = () => {
    const { filteredLabels, valueExists } = getFilteredLabels();

    const items = filteredLabels.slice(0, 5);

    const newItems = items.map(({ name, id }) => {
      return {
        text: name,
        onClick: () => onAddLabel(name, id),
      };
    });

    newItems.sort(sortItems);

    if (!valueExists && searchValue) {
      return [
        {
          text: searchValue,
          onClick: () => onAddLabel(searchValue, null),
        },
        ...newItems,
      ];
    }

    return newItems;
  };

  const onEnterClick = labels => {
    if (labelItems.length && anchorElement && searchValue === labels?.[0]?.text) {
      return labels[0].onClick;
    }

    return () => {};
  };

  const labelItems = getLabelItems();
  useKeyPress('Enter', onEnterClick(labelItems));

  return (
    <MenuWithItems
      direction={{ vertical: 'bottom', horizontal: 'center' }}
      anchorElement={anchorElement}
      onClose={onClose}
      menuItems={labelItems}
    />
  );
};

export default VideoLabelsMenu;
