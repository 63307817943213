import React from 'react';
import styled from 'styled-components';
import Logo from 'app/src/images/logo.svg';
import { useMediaQuery } from '@material-ui/core';
import ColorfulSeparator from 'app/src/basic_components/ColorfulSeparator';
import Utils from '../../utils';
import { TABLET_MAX_WIDTH } from 'shared/react/utils/mediaQuery';

function Toolbar() {
  const isMobile = useMediaQuery(`(${TABLET_MAX_WIDTH})`) || Utils.isMobile();

  return (
    <MainContainer>
      <ColorfulSeparator />
      <Container $isMobile={isMobile}>
        <Title width="162px" height="32px" src={Logo} />
      </Container>
    </MainContainer>
  );
}

export default Toolbar;

const MainContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  background-color: white;
`;

const Container = styled.div`
  height: 64px;
  display: grid;
  grid-template-columns: ${props =>
    props.backButtonVisible ? 'auto 1fr auto auto' : '1fr auto auto'};
  align-items: center;
  padding: ${props => (props.$isMobile ? '0 0 0 10px' : '0 32px 0 24px')};
  width: 100%;
  justify-self: center;
`;

const Title = styled.img`
  color: black;
  font-size: 24px;
  justify-self: start;
`;
