import React from 'react';
import { TextSmall, TextTiny } from 'shared/react/components/basic/text/TextV2';
import { useProjectConfigContext } from 'shared/react/components/complex/context/ProjectConfigContext';
import useFeedProducts from 'shared/react/components/complex/context/hooks/useFeedProducts';
import useFeedState from 'shared/react/components/complex/context/hooks/useFeedState';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';
import { getHasOptions } from 'shared/react/components/complex/feed/feed_utils/feed.utils';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import HorizontalFlex, {
  Gap8HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import {
  SMALL_PRODUCT_HEADER_DATA_TYPE,
  SMALL_PRODUCT_VIEW_DATA_TYPE,
} from 'shared/react/constants/feedPreviewDataTypes.constants';
import { FEED_SMALL_PRODUCT_VIEW_MAX_HEIGHT } from 'shared/react/constants/ui.constants';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';
import useIsMilk from 'shared/react/hooks/useIsMilk';
import styled from 'styled-components';
import { SMALL_PRODUCT_VIEW_DATA_TEST_ID } from '../../../../constants/dataTestIds.constants';
import useOpenProductModal from 'shared/react/components/complex/context/hooks/useOpenProductModal';
import useIsFenty, { useIsQuickShop } from 'shared/react/hooks/useIsFenty';
import FeedLikeButton from '../feed_controls/FeedLikeButton';
import useKeyboardNavigation from 'player/src/hooks/useKeyboardNavigation';
import { FENTY_QUICK_SHOP_TEXT, IMAGE_FIT_TYPE } from '../../../../constants/feed.constants';
import FeedSmallProductViewBottom from 'shared/react/components/complex/feed/feed_overlay_mobile/FeedSmallProductViewBottom';
import useIsPrincessPolly from 'shared/react/hooks/useIsPrincessPolly';
import LineClampWrapper from 'shared/react/components/basic/LineClampWrapper';
import FittedImage from '../../FittedImage';
import Utils from 'shared/react/utils/utils';
import { getDesignCss } from 'shared/react/utils/addSharedCss';

const FeedSmallProductView = ({
  step,
  product,
  variantId,
  isInteractive,
  isMobilePreview,
  pause,
  play,
  verticalOrientation,
  hidePoweredBy,
  isHorizontal,
  setPanelExpanded,
  numberOfProducts,
  landingPage,
  externalProductIds,
  isMultipleProducts,
}) => {
  const [, { getProductExtraData }] = useFeedProducts();
  const [{ setIsPlaying }] = useFeedState();
  const [, { t }] = useTranslation('feed');
  const { pdpConfig } = useProjectConfigContext();
  const isMilk = useIsMilk();
  const isFenty = useIsFenty();
  const isPP = useIsPrincessPolly();
  const { player, feedDesign } = useDesignSettingsContext();
  const { feedImageFitType } = player.feedCards.productDetailsCard.productImages;
  const {
    opacity: cardOpacity,
    textColor: cardTextColor,
    productTitleNumOfLines = 1,
    variantSubtitleNumOfLines = 1,
  } = player.feedCards.productDetailsCard?.invideoProductCard || {};
  const { product_subtitle: productSubtitle } = getProductExtraData(product?.id) || {};
  const { enabled: isMiniCtaConfigEnabled, text } = pdpConfig?.miniCtaButton || {};
  const isQuickShopEnabled = useIsQuickShop(product);
  const { handleOpenProductModal } = useOpenProductModal({
    step,
    isMobilePreview,
    verticalOrientation,
    landingPage,
  });

  const { onKeyDown, setNextRef } = useKeyboardNavigation();

  if (!product) {
    return null;
  }

  const getButtonText = () => {
    if (isQuickShopEnabled) {
      return FENTY_QUICK_SHOP_TEXT;
    }

    if (isFenty) {
      return `${t('buyNowButton.addToCart')}`.toUpperCase();
    }

    if (numberOfProducts) {
      return `${numberOfProducts} ${t('mobile.productView.more')}`;
    }

    if (isMiniCtaConfigEnabled && text) {
      return text;
    }

    return t('mobile.productView.shop');
  };

  const onModalClose = () => {
    if (!isInteractive) {
      setIsPlaying(true);
      return;
    }

    if (!isHorizontal) {
      play();
    }
    setPanelExpanded?.(false);
  };

  const onModalOpen = () => {
    if (!isInteractive) {
      setIsPlaying(false);
      return;
    }

    if (!isHorizontal) {
      pause();
    }

    setPanelExpanded?.(true);
  };

  const onOpenProductModal = e => {
    handleOpenProductModal(e, {
      product,
      variantId,
      externalProductIds,
      isQuickShopEnabled,
      onModalOpen,
      onModalClose,
      isMultipleProducts,
    });
  };

  const { imageUrl, title, variants } = product;

  const selectedVariant =
    variants?.find(({ id }) => `${id}` === `${variantId}`) || variants?.[0] || {};
  const hasOptions = getHasOptions(product);
  const shouldShowVariantTitle = variantId && hasOptions && !!selectedVariant.title;

  const subtitle = shouldShowVariantTitle ? selectedVariant.title : productSubtitle;
  const currentImageUrl = Utils.getShopifyImageAndSetHeight(
    selectedVariant?.imageUrl || imageUrl,
    150
  );

  return (
    <LayoutRoot
      onClick={onOpenProductModal}
      hidePoweredBy={hidePoweredBy}
      data-test-id={SMALL_PRODUCT_VIEW_DATA_TEST_ID}
      data-type={SMALL_PRODUCT_VIEW_DATA_TYPE}
      cardOpacity={cardOpacity}
      role="button"
      aria-label={`View Product: ${title}`}
      id="feedSmallProductView"
      ref={setNextRef}
      onKeyDown={onKeyDown}
      tabIndex="0"
    >
      <StyledFittedImage
        src={currentImageUrl}
        $isCoverFit={feedImageFitType === IMAGE_FIT_TYPE.COVER}
      />
      <Content $isFenty={isFenty}>
        <HeaderWrapper>
          <TextContainer>
            <LineClampWrapper numberOfLines={productTitleNumOfLines}>
              <ProductTitle
                data-type={SMALL_PRODUCT_HEADER_DATA_TYPE}
                $isMilk={isMilk}
                cardTextColor={cardTextColor}
                $isPP={isPP}
                $design={feedDesign?.feedProductCards?.productHeader}
              >
                {title}
              </ProductTitle>
            </LineClampWrapper>
            {subtitle && (
              <LineClampWrapper numberOfLines={variantSubtitleNumOfLines}>
                <ProductSubTitle
                  $isPP={isPP}
                  $isFenty={isFenty}
                  $isMilk={isMilk}
                  cardTextColor={cardTextColor}
                >
                  {subtitle}
                </ProductSubTitle>
              </LineClampWrapper>
            )}
          </TextContainer>
          <FeedLikeButton
            product={product}
            vodAssetId={step.videoId}
            onModalOpenExternal={onModalOpen}
            onModalCloseExternal={onModalClose}
          />
        </HeaderWrapper>
        <FeedSmallProductViewBottom
          selectedVariant={selectedVariant}
          product={product}
          cardTextColor={cardTextColor}
          getButtonText={getButtonText}
        />
      </Content>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)`
  width: 100%;
  background: ${({ theme, cardOpacity = 0.3 }) =>
    theme.getHexOpacity(theme.colors.white, cardOpacity * 100)};
  backdrop-filter: blur(6px);
  border-radius: 5px;
  max-height: ${FEED_SMALL_PRODUCT_VIEW_MAX_HEIGHT}px;
  z-index: 10;
  margin-bottom: ${({ hidePoweredBy }) => (hidePoweredBy ? 0 : '24px')};
  cursor: pointer;
  height: 100px;
  position: relative;
`;

const Content = styled(Gap8VerticalFlex)`
  text-align: left;
  width: 100%;
  padding: 8px 12px;
  justify-content: ${({ $isFenty }) => ($isFenty ? 'center' : 'space-between')};
`;

const TextContainer = styled(VerticalFlex)`
  gap: 4px;
`;

const StyledFittedImage = styled(FittedImage)`
  flex-shrink: 0;
  height: 100%;
  max-width: 72px;
  border-radius: 5px 0 0 5px;
  background: ${({ theme }) => theme.colors.white};

  & img {
    border-radius: ${({ $isCoverFit }) => ($isCoverFit ? '5px 0 0 5px' : '0')};
    height: ${({ $isCoverFit }) => ($isCoverFit ? '100%' : '')};
  }
`;

const HeaderWrapper = styled(Gap8HorizontalFlex)`
  justify-content: space-between;
  gap: 16px;
`;

const ProductTitle = styled(TextSmall)`
  color: ${({ theme, cardTextColor, $isFenty }) =>
    $isFenty ? theme.colors.black : cardTextColor || theme.colors.white};
  font-size: ${({ $isMilk, $isPP }) => ($isPP ? '16px' : $isMilk ? '20px' : '')};
  font-weight: ${({ $isMilk }) => ($isMilk ? '600' : '500')};
  line-height: ${({ $isMilk }) => ($isMilk ? '22px' : '')};
  ${({ $design }) => getDesignCss($design)};
`;

const ProductSubTitle = styled(TextTiny)`
  color: ${({ theme, cardTextColor }) => cardTextColor || theme.colors.white};
  letter-spacing: 0;
  font-weight: 400;
  font-size: ${({ $isFenty, $isPP, $isMilk }) => ($isFenty || $isPP || $isMilk ? '14px' : '')};
  line-height: ${({ $isFenty, $isPP, $isMilk }) => ($isFenty || $isPP || $isMilk ? '16px' : '')};
`;

export default FeedSmallProductView;
