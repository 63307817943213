import React from 'react';
import styled from 'styled-components';
import { LEFT, RIGHT } from './carousel.constants';
import CarouselArrowIcon from '../../../images/CarouselArrowIcon';
import { CAROUSEL_ARROW_DATA_TYPE } from 'shared/react/constants/feedPreviewDataTypes.constants';

const CarouselArrow = ({ direction, onClick, numberOfImages, largePadding }) => {
  if (numberOfImages < 2) {
    return null;
  }

  return (
    <LayoutRoot direction={direction} data-type={CAROUSEL_ARROW_DATA_TYPE}>
      <IconContainer onClick={() => onClick(direction)} largePadding={largePadding}>
        <CarouselArrowIcon direction={direction} />
      </IconContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  left: ${({ direction }) => (direction === LEFT ? 0 : '')};
  right: ${({ direction }) => (direction === RIGHT ? 0 : '')};
`;

const IconContainer = styled.div`
  display: flex;
  transition: background-color 0.3s;
  padding: ${({ largePadding }) => (largePadding ? '32px' : '8px 4px')};
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: ${({ theme }) => theme.getHexOpacity(theme.colors.ghostDark, 10)};
  }
`;

export default CarouselArrow;
