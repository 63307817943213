import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import {
  DEFAULT_STORIES_SIZE,
  STORIES_ITEMS_SHAPE_TYPES,
  STORIES_ITEMS_SIZE_TYPES,
} from '../../LookAndFeelPage.constants';
import DesignStoriesPreviewStoryName from './DesignStoriesPreviewStoryName';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import DefaultTilePreview from 'app/src/images/default_tile_preview.png';
import Tooltip from 'app/src/basic_components/Tooltip';

type Props = {
  size: number;
  storiesBorderRadius?: number;
  storiesBorderEnabled?: boolean;
  storiesBorderWidth?: number;
  storiesBorderColor?: string;
  storiesBorderType?: string;
  storiesBorderOpacity?: number;
  storiesStoryNameEnabled?: boolean;
  storiesStoryNameFontSize?: number;
  storiesStoryNameColor?: string;
  storiesCircleSize?: number;
  storiesRectangleWidth?: number;
  storiesRectangleHeight?: number;
  storiesItemsShape?: string;
  storiesItemsSizeType?: string;
};

const DefaultStoriesPreviewTile = ({
  size,
  storiesBorderRadius,
  storiesBorderEnabled,
  storiesBorderWidth,
  storiesBorderColor,
  storiesBorderType,
  storiesBorderOpacity,
  storiesStoryNameEnabled,
  storiesStoryNameFontSize,
  storiesStoryNameColor,
  storiesCircleSize = DEFAULT_STORIES_SIZE,
  storiesRectangleWidth,
  storiesRectangleHeight,
  storiesItemsShape,
  storiesItemsSizeType,
}: Props) => {
  const theme = useTheme();
  const proportionalBorderRadius =
    (storiesBorderRadius / size) * (storiesCircleSize / DEFAULT_STORIES_SIZE);

  const getBorder = () => {
    if (!storiesBorderEnabled) {
      return '';
    }

    const color = theme.getHexOpacity(storiesBorderColor, storiesBorderOpacity * 100);

    return `${storiesBorderWidth / size}px ${storiesBorderType} ${color}`;
  };

  return (
    <LayoutRoot size={size} storiesCircleSize={storiesCircleSize}>
      <StyledTooltip
        text="To see best results, tag a few more videos in the `Videos` section."
        placement="top"
      >
        <ImageContainer
          size={size}
          storiesCircleSize={storiesCircleSize}
          storiesRectangleWidth={storiesRectangleWidth}
          storiesRectangleHeight={storiesRectangleHeight}
          storiesItemsShape={storiesItemsShape}
          storiesItemsSizeType={storiesItemsSizeType}
        >
          <Image
            src={DefaultTilePreview}
            $borderRadius={proportionalBorderRadius}
            $storiesBorderEnabled={storiesBorderEnabled}
            $border={getBorder()}
          />
        </ImageContainer>
        <DesignStoriesPreviewStoryName
          storiesStoryNameEnabled={storiesStoryNameEnabled}
          storiesStoryNameFontSize={storiesStoryNameFontSize}
          storiesStoryNameColor={storiesStoryNameColor}
          partName="Name"
          size={size}
        />
      </StyledTooltip>
    </LayoutRoot>
  );
};

export default DefaultStoriesPreviewTile;

const LayoutRoot = styled(VerticalFlex)`
  gap: ${({ size }) => 8 / size}px;
  width: 100%;
  height: 100%;
`;

const sizeCss = css<{
  size: number;
  storiesItemsShape: string;
  storiesRectangleWidth: number;
  storiesRectangleHeight: number;
  storiesCircleSize: number;
  storiesItemsSizeType: string;
}>`
  ${({
    size,
    storiesItemsShape,
    storiesRectangleWidth,
    storiesRectangleHeight,
    storiesCircleSize,
    storiesItemsSizeType,
  }) => {
    if (storiesItemsShape === STORIES_ITEMS_SHAPE_TYPES.circle) {
      return css`
        height: ${storiesCircleSize / size}px;
        width: ${storiesCircleSize / size}px;
      `;
    }

    if (storiesItemsSizeType === STORIES_ITEMS_SIZE_TYPES.responsive) {
      return css`
        aspect-ratio: 9/16;
      `;
    }

    return css`
      width: ${storiesRectangleWidth}px;
      height: ${storiesRectangleHeight}px;
    `;
  }}}
`;

const ImageContainer = styled(VerticalFlex)`
  ${sizeCss}
`;

const Image = styled.img<{
  $storiesBorderEnabled: boolean;
  $borderRadius: number;
  $border: string;
}>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: ${({ $storiesBorderEnabled }) => ($storiesBorderEnabled ? '2px' : '')};
  border-radius: ${({ $borderRadius }) => $borderRadius}px;
  border: ${({ $border }) => $border};
  transition: 0.3s;
`;

const StyledTooltip = styled(Tooltip)`
  background-color: ${({ theme }) => theme.colors.primary};

  & .MuiTooltip-arrow::before {
    background: ${({ theme }) => theme.colors.primary};
  }
`;
