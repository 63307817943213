import React from 'react';
import styled from 'styled-components';
import BasicInput from 'app/src/basic_components/BasicInput';

const ReadOnlyInput = ({ label, value, ...props }) => {
  return (
    <LayoutRoot>
      <MainContainer {...props}>
        <StyledBasicInput onChange={() => {}} label={label} value={value} disabled />
      </MainContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div``;

const MainContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray3};
  border-radius: 10px;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
`;

const StyledBasicInput = styled(BasicInput)`
  padding: 12px;
  color: ${({ theme }) => theme.colors.ghostDark};
  opacity: 1;
`;

export default ReadOnlyInput;
