import { INSTAGRAM, TIKTOK } from '../constants/intergrations.constants';

export const getHandleFromUrl = ({ url }) => {
  return extractHandle(url);
};

/**
 * @param {string} url
 */
export function getProviderByUrl(url) {
  if (url.includes('tiktok.com')) return TIKTOK;
  if (url.includes('instagram.com')) return INSTAGRAM;
}

export const getProfileLink = (handle, provider = TIKTOK) => {
  if (provider === TIKTOK) return `https://www.tiktok.com/${handle}/`;
  if (provider === INSTAGRAM) return `https://www.instagram.com/${handle}/`;
};

export function ensureAtSign(str) {
  if (!str) return '';
  if (str[0] === '@') return str;
  else return '@' + str;
}

export function ensureProtocol(url) {
  if (!url.startsWith('http')) {
    if (url.startsWith('www')) {
      return 'https://' + url;
    } else {
      return 'https://www.' + url;
    }
  }
  return url;
}

// TODO: replace with URL.canParse when it will be widely adopted.
function canParse(url) {
  try {
    return !!new URL(url);
  } catch (error) {
    return false;
  }
}

function extractHandle(url) {
  url = ensureProtocol(url);

  if (!canParse(url)) {
    return null;
  }

  const urlObj = new URL(url);
  const pathname = urlObj.pathname;

  // Empty segments occur because of leading, trailing, or consecutive slashes in the URL's pathname.
  const segments = pathname.split('/').filter(segment => segment.length > 0);

  // first segment should be the username
  return segments[0] || null;
}

// // // Example usage // TODO: add as tests
// console.log(extractUsername('https://www.instagram.com/lenamahfouf/?img_index=1')); // Output: lenamahfouf
// console.log(extractUsername('https://www.instagram.com/leomessi')); // Output: leomessi
// console.log(extractUsername('www.instagram.com/leomessi')); // Output: leomessi
// console.log(extractUsername('instagram.com/leomessi')); // Output: leomessi
// console.log(extractUsername('https://www.tiktok.com/@khaby.lame?lang=uk')); // Output: @charlidamelio
// console.log(extractUsername('https://www.tiktok.com/@khaby.lame')); // Output: @leomessi
// console.log(extractUsername('www.tiktok.com/@khaby.lame')); // Output: @leomessi
// console.log(extractUsername('tiktok.com/@khaby.lame')); // Output: @leomessi
