export const getColumnCount = ({ gridWidth, breakpoints = [], columnCount }) => {
  if (columnCount) {
    return columnCount;
  }

  const { columns } = breakpoints.find(({ width }) => gridWidth > width) || {};

  return columns;
};

export const getColumnWidth = ({ gridWidth, columnCount, gap, columnWidth }) => {
  if (columnWidth) {
    return columnWidth + gap;
  }

  return Math.floor(gridWidth / columnCount);
};

export const getRowCount = ({ rowCount, itemCount, columnCount }) => {
  if (rowCount) {
    return rowCount;
  }

  return Math.ceil(itemCount / columnCount);
};

export const getStyles = ({ gap, style, rowCount, rowIndex, columnCount, columnIndex }) => {
  if (!gap) {
    return style;
  }

  const padding = gap / 2;
  const styles = {
    ...style,
    padding: padding,
  };

  if (rowIndex === 0) {
    styles.paddingTop = 0;
  }

  if (columnIndex === 0) {
    styles.paddingLeft = 0;
  }

  if (rowIndex === rowCount - 1) {
    styles.paddingBottom = 0;
  }

  if (columnIndex === columnCount - 1) {
    styles.paddingRight = 0;
  }

  return styles;
};

export const getGridSize = ({
  width,
  height,
  padding,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
}) => {
  const xPadding = padding * 2 || paddingLeft + paddingRight;
  const yPadding = padding * 2 || paddingTop + paddingBottom;
  const gridWidth = width - xPadding;
  const gridHeight = height - yPadding;

  return { gridWidth, gridHeight };
};
