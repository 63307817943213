import { v4 as uuidv4 } from 'uuid';
import { TIKTOK } from 'app/src/constants/intergrations.constants';
import { TiktokVideo, NormalizeTiktokVideoOptions } from 'app/src/types/integrations';

export const normalizeTikTokVideo = (
  video: Partial<TiktokVideo>,
  { owner, defaultName }: NormalizeTiktokVideoOptions
): TiktokVideo => {
  const { posterUrl, description, externalCreatedAt, videoUrl } = video;
  const id = uuidv4();

  return {
    ...video,
    videoUrl,
    id,
    externalId: video.id,
    newId: id,
    app: TIKTOK,
    createdAt: new Date(externalCreatedAt * 1000).toISOString(),
    owner,
    stockAsset: { posterUrl },
    name: description || defaultName,
    uploadType: TIKTOK,
  } as TiktokVideo;
};
