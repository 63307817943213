const TOLSTOY_EVENTS = {
  modalOpen: 'tolstoyModalOpen',
  playerReady: 'tolstoyPlayerReady',
  toggleFeedCloseButton: 'toggleFeedCloseButton',
  showFeedProductModal: 'showFeedProductModal',
  reportModalOpen: 'tolstoyReportModalOpen',
  reportModalClose: 'tolstoyReportModalClose',
  modalMessagingReady: 'tolstoyModalMessagingReady',
  closePlayer: 'tolstoyClosePlayer'
};

const REBUY_EVENTS = {
  isRebuyAppInstalled: 'tolstoyIsRebuyAppInstalled',
  openRebuyCart: 'tolstoyOpenRebuyCart',
  rebuyCartShown: 'rebuyCartShown',
  rebuyCartHidden: 'rebuyCartHidden'
};

const KENDO_EVENTS = {
  openKendoModal: 'tolstoyOpenKendoModal',
  changeZIndex: 'tolstoyChangeZIndex'
};

const TAPCART_EVENTS = {
  isTapcart: 'tolstoyIsTapcart',
  openTapcartProduct: 'tolstoyOpenTapcartProduct'
};

// milk makeup
const METAFIELDS_SUBSCRIBER_EVENTS = {
  getProductsMetafields: 'getProductsMetafields',
  returnProductsMetafields: 'returnProductsMetafields'
};

const AFTERPAY_EVENTS = {
  isAfterpayAppInstalled: 'tolstoyIsAfterpayAppInstalled'
};

const PLAYER_EVENTS = {
  productCardClick: 'tolstoyProductCardClick'
};

const SPOTLIGHT_EVENTS = {
  spotlightCarouselQuickShopClick: 'tolstoySpotlightCarouselQuickShopClick'
};

const EXTERNAL_SUBSCRIPTION_EVENTS = {
  externalEventSubscribed: 'tolstoyExternalEventSubscribed',
  productCardClickSubscribed: 'tolstoyProductCardClickSubscribed'
};

const CART_EVENTS = {
  setAnonymousIdToCart: 'tolstoySetAnonymousIdToCart',
  blockAnonymousIdToCart: 'tolstoyBlockAnonymousIdToCart',
  addToCart: 'tolstoyAddToCart',
  addToCartSuccess: 'tolstoyAddToCartSuccess',
  addToCartError: 'tolstoyAddToCartError',
  addToCartSoldOut: 'tolstoyAddToCartSoldOut',
  addToCartDisableDefault: 'tolstoyAddToCartDisableDefault',
  cartItemQuantityChange: 'tolstoyCartItemQuantityChange',
  cartItemQuantityChangeSuccess: 'tolstoyCartItemQuantityChangeSuccess',
  cartItemQuantityChangeError: 'tolstoyCartItemQuantityChangeError',
  cartDataRequest: 'tolstoyCartDataRequest',
  cartDataResponse: 'tolstoyCartDataResponse'
};

const MULTIPASS_EVENTS = {
  loginWithMultipassUrlRequest: 'tolstoyLoginWithMultipassUrl',
  loginWithMultipassUrlResponse: 'tolstoyLoginWithMultipassUrlResponseMessage'
};

const PRODUCT_RECOMMENDATIONS_EVENTS = {
  productRecommendationsRequest: 'tolstoyProductRecommendationsRequest',
  productRecommendationsResponse: 'tolstoyProductRecommendationsResponse'
};

const PRODUCT_EVENTS = {
  requestProductsUpdate: 'tolstoyRequestProductsUpdate',
  productUpdateResponse: 'tolstoyProductUpdateResponse',
  moveToUrl: 'tolstoyMoveToUrl',
  isNonBaseCurrency: 'tolstoyIsNonBaseCurrency'
};

const URL_EVENTS = {
  urlChange: 'urlChange',
  urlLocaleUpdate: 'tolstoyUrlLocaleUpdate'
};

const OPEN_COMMERCE_EVENTS = {
  openCommerceSettingsUpdate: 'tolstoyOpenCommerceSettingsUpdate'
};

export const INTERNAL_EVENTS = {
  ...TOLSTOY_EVENTS,
  ...REBUY_EVENTS,
  ...KENDO_EVENTS,
  ...AFTERPAY_EVENTS,
  ...METAFIELDS_SUBSCRIBER_EVENTS,
  ...PLAYER_EVENTS,
  ...SPOTLIGHT_EVENTS,
  ...EXTERNAL_SUBSCRIPTION_EVENTS,
  ...TAPCART_EVENTS,
  ...CART_EVENTS,
  ...MULTIPASS_EVENTS,
  ...PRODUCT_RECOMMENDATIONS_EVENTS,
  ...PRODUCT_EVENTS,
  ...URL_EVENTS,
  ...OPEN_COMMERCE_EVENTS,
  rejectCookiePolicy: 'rejectCookiePolicy'
};
