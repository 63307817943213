import React from 'react';

const CrossIcon = () => (
  <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.586 1.71a.552.552 0 0 0 0-.772C7.352.703 7 .703 6.789.938L4 3.727 1.21.914a.58.58 0 0 0-.795 0c-.234.234-.234.586 0 .797L3.204 4.5.413 7.29c-.234.233-.234.585 0 .796a.514.514 0 0 0 .774 0l2.789-2.79 2.812 2.79a.514.514 0 0 0 .774 0 .513.513 0 0 0 0-.774l-2.79-2.789 2.813-2.812Z"
      fill="#50545E"
    />
  </svg>
);

export default CrossIcon;
