import React from 'react';
import { useAnalytics } from 'app/src/context/AnalyticsStore';
import { track } from 'app/src/helpers/Tracker';
import { useFeatures } from 'app/src/context/FeaturesStore';
import {
  FEATURE_ACCOUNT_ANALYTICS,
  FEATURE_ANALYTICS_CALENDAR,
} from 'app/src/constants/appFeatures.constants';
import AnalyticsFilterWithCalendar from '../analytics-calendar/AnalyticsFilterWithCalendar';

const AnalyticsDateFilter = () => {
  const [, { getSettingsByKey }] = useFeatures();
  const [{ dateFilter: selectedDateFilter }, { setDateFilter }] = useAnalytics();
  const maxDateBack = getSettingsByKey(FEATURE_ANALYTICS_CALENDAR)?.maxDateBack || 60;

  const getDateFilters = () => {
    const { dateFilters } = getSettingsByKey(FEATURE_ACCOUNT_ANALYTICS);

    return dateFilters;
  };

  const onDateFilterClick = dateFilter => {
    if (dateFilter === selectedDateFilter) {
      return;
    }

    track('Analytics Date Filter Click', {
      dateFilter,
    });

    setDateFilter(dateFilter);
  };

  return (
    <AnalyticsFilterWithCalendar
      value={selectedDateFilter}
      onChange={onDateFilterClick}
      getSelectedValue={value => value}
      filters={getDateFilters()}
      maxDateBack={maxDateBack}
    />
  );
};

export default AnalyticsDateFilter;
