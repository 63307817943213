import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useRecharge from 'shared/react/components/complex/context/hooks/useRecharge';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';
import VerticalFlex from '../../../flex_layouts/VerticalFlex';
import Gap8VerticalFlex from '../../../flex_layouts/Gap8VerticalFlex';
import Gap16VerticalFlex from '../../../flex_layouts/Gap16VerticalFlex';
import {
  FEED_BUTTONS_DEFAULT_SETTINGS,
  FEED_PRIMARY_BACKGROUND_COLOR_TYPE,
} from 'shared/react/constants/feature-constants/feedButtonsCustomization.constants';
import HorizontalFlexWrap from '../../../flex_layouts/HorizontalFlexWrap';
import FeedProductRechargeHighlightRecurringHeader from 'shared/react/components/complex/feed/feed_products/feed_product_recharge/FeedProductRechargeHighlightRecurringHeader';
import FeedProductRechargePlanRadio from 'shared/react/components/complex/feed/feed_products/feed_product_recharge/FeedProductRechargePlanRadio';
import FeedProductRechargeSellingPlansDropdown from 'shared/react/components/complex/feed/feed_products/feed_product_recharge/FeedProductRechargeSellingPlansDropdown';
import IconWithTooltip from 'shared/react/components/basic/tooltip/IconWithTooltip';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';

const SellingPlanType = {
  ONE_TIME: 'ONE_TIME',
  RECURRING: 'RECURRING',
};

const defaultRechargeProduct = {
  prices: {},
  sellingPlans: [],
  maxPercentageDiscount: 0,
};

const getSubscribeMessage = (maxPercentageDiscount, rechargeWidgetSettings) => {
  return maxPercentageDiscount
    ? `${rechargeWidgetSettings.subscribeMessage} ${maxPercentageDiscount}%`
    : rechargeWidgetSettings.subscribeWithoutDiscountMessage;
};

const FeedProductRecharge = ({ productId, selectedVariantId, currencySymbol }) => {
  const [{ playerSettings }] = useProjectConfig();
  const { player } = useDesignSettingsContext();
  const {
    backgroundColor: primaryBackgroundColor,
    border: {
      radius: borderRadius,
    },
  } = player.feedCards.buttonsDesign.primary;
  const [
    { rechargeWidgetSettings, setRechargeCartItem, isRechargeEnabled },
    { getShouldShowRecharge, getRechargeProduct },
  ] = useRecharge();
  const rechargeProduct = getRechargeProduct(productId, selectedVariantId);
  const { prices, sellingPlans, maxPercentageDiscount } = rechargeProduct || defaultRechargeProduct;
  const sellingPlansOptions = sellingPlans.map(({ selling_plan_name }) => selling_plan_name);
  const primaryColor =
    primaryBackgroundColor ?? FEED_BUTTONS_DEFAULT_SETTINGS[FEED_PRIMARY_BACKGROUND_COLOR_TYPE];

  const [sellingPlanType, setSellingPlanType] = useState(SellingPlanType.ONE_TIME);
  const [sellingPlanName, setSellingPlanName] = useState(sellingPlansOptions?.[0] || null);
  const selectedSellingPlan = sellingPlans.find(
    ({ selling_plan_name }) => selling_plan_name === sellingPlanName
  );
  const subscribeMessage = getSubscribeMessage(maxPercentageDiscount, rechargeWidgetSettings);
  const isOnetimeSelected = sellingPlanType === SellingPlanType.ONE_TIME;
  const isRecurringSelected = sellingPlanType === SellingPlanType.RECURRING;

  const {
    productDetailsSubscriptionsHighlightRecurringEnabled: highlightRecurringEnabled,
    productDetailsSubscriptionsDescriptionLeft: descriptionLeft,
    productDetailsSubscriptionsDescriptionRight: descriptionRight,
  } = playerSettings || {};

  const shouldShowRecharge = getShouldShowRecharge({
    productId,
    variantId: selectedVariantId,
  });

  const { unit_price: onetimePrice, discounted_price: subscriptionPrice } = prices;

  const onChangeSellingPlanType = type => {
    if (sellingPlanType === type) {
      return;
    }

    setSellingPlanType(type);

    if (!sellingPlanName) {
      setSellingPlanName(sellingPlansOptions?.[0] || null);
    }
  };

  useEffect(() => {
    if (!selectedVariantId || !sellingPlanType || !selectedSellingPlan) {
      return;
    }

    setRechargeCartItem({
      id: selectedVariantId,
      sellingPlanId: selectedSellingPlan.selling_plan_id,
      sellingPlanGroupId: selectedSellingPlan.selling_plan_group_id,
      isSubscription: isRecurringSelected,
    });
  }, [selectedVariantId, sellingPlanType, sellingPlanName]);

  useEffect(() => {
    if (!isRechargeEnabled) {
      return;
    }

    setRechargeCartItem({});

    return () => {
      setRechargeCartItem({});
    };
  }, []);

  if (!shouldShowRecharge || !isRechargeEnabled) {
    return null;
  }

  const subscriptionTooltip = (
    <HowItWorks dangerouslySetInnerHTML={{ __html: rechargeWidgetSettings.howItWorks }} />
  );

  return (
    <LayoutRoot>
      <FeedProductRechargePlanRadio
        selectedValue={sellingPlanType}
        optionValue={SellingPlanType.ONE_TIME}
        onChange={onChangeSellingPlanType}
        price={onetimePrice}
        currencySymbol={currencySymbol}
        isOnetime={true}
        isOnetimeSelected={isOnetimeSelected}
        borderRadius={borderRadius}
        checkedColor={primaryColor}
      >
        {rechargeWidgetSettings.onetimeMessage}
      </FeedProductRechargePlanRadio>
      <BestValueOptionContainer
        highlightRecurringEnabled={highlightRecurringEnabled}
        primaryColor={primaryColor}
        borderRadius={borderRadius}
      >
        <FeedProductRechargeHighlightRecurringHeader
          shouldHide={!highlightRecurringEnabled}
          backgroundColor={primaryColor}
          borderRadius={borderRadius}
          leftContent={descriptionLeft}
          rightContent={descriptionRight}
        />
        <BestValueContent>
          <FeedProductRechargePlanRadio
            selectedValue={sellingPlanType}
            optionValue={SellingPlanType.RECURRING}
            onChange={onChangeSellingPlanType}
            price={subscriptionPrice}
            currencySymbol={currencySymbol}
            checkedColor={primaryColor}
          >
            <RadioMessage dangerouslySetInnerHTML={{ __html: subscribeMessage }} />
            {rechargeWidgetSettings.howItWorks && <IconWithTooltip text={subscriptionTooltip} />}
          </FeedProductRechargePlanRadio>
          <FeedProductRechargeSellingPlansDropdown
            shouldHide={!isRecurringSelected}
            items={sellingPlansOptions}
            value={sellingPlanName}
            setSellingPlanName={setSellingPlanName}
            borderRadius={borderRadius}
          />
        </BestValueContent>
      </BestValueOptionContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)`
  text-align: left;
`;

const BestValueOptionContainer = styled(VerticalFlex)`
  border: 1px solid
    ${({ theme, highlightRecurringEnabled, primaryColor }) =>
      highlightRecurringEnabled ? primaryColor : theme.colors.neutralGray};
  border-radius: ${({ borderRadius }) => (borderRadius ? `${borderRadius}px` : '')};
  min-height: 40px;
  justify-content: center;
`;

const BestValueContent = styled(Gap16VerticalFlex)`
  padding: 8px;
`;

const RadioMessage = styled.div``;

const HowItWorks = styled(HorizontalFlexWrap)`
  width: 250px;
  white-space: normal;
`;

export default FeedProductRecharge;
