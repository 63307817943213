import React, { useState } from 'react';
import styled from 'styled-components';
import ImageWithFallback from 'shared/react/images/ImageWithFallback';
import { TextBody, TextBodyLeading } from 'shared/react/components/complex/Text';
import {
  Gap16HorizontalFlex,
  Gap8HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';
import { CircularProgress } from '@material-ui/core';
import ComponentWithOverlayLoader from 'shared/react/components/basic/ComponentWithOverlayLoader';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { useApps } from 'app/src/context/AppsStore';
import IntegrationsButton from 'app/src/pages/creation_flow/right_section/integrations_common/IntegrationsButton';

const IntegrationsAccount = ({ initialized, userInfo, app, clearStore, integration }) => {
  const [, { deleteApp }] = useApps();
  const [isLoading, setIsLoading] = useState(false);

  const onDisconnect = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    const res = await deleteApp(app);

    if (res) {
      clearStore();
    }

    setIsLoading(false);
  };

  if (!userInfo || !initialized) {
    return null;
  }

  const { description, avatarUrl, userName } = userInfo;
  return (
    <LayoutRoot>
      <UserInfoContainer>
        <Image src={avatarUrl} />
        <UserInfo>
          <UserName>{userName}</UserName>
          <EllipsisTextOverflowWrapper>
            <Description>{description}</Description>
          </EllipsisTextOverflowWrapper>
        </UserInfo>
      </UserInfoContainer>
      <ButtonOverlay customLoader={<CircularProgress size={16} />} isLoading={isLoading}>
        <StyledIntegrationButton
          disabled={isLoading}
          integration={integration}
          onClick={onDisconnect}
        >
          Disconnect
        </StyledIntegrationButton>
      </ButtonOverlay>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlex)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const UserInfoContainer = styled(Gap16HorizontalFlex)``;

const Image = styled(ImageWithFallback)`
  width: 48px;
  height: 48px;
  border-radius: 100%;
`;

const UserInfo = styled(VerticalFlex)`
  justify-content: center;
`;

const UserName = styled(TextBodyLeading)``;

const Description = styled(TextBody)``;

const StyledIntegrationButton = styled(IntegrationsButton)`
  background: transparent;
  border: 1px solid ${({ theme }) => theme.colors.ghostLight};
  color: ${({ theme }) => theme.colors.gray2};
  height: 40px;

  &:hover {
    background: transparent;
    border: 1px solid ${({ theme }) => theme.colors.ghostLight};
  }

  & path {
    fill: ${({ theme }) => theme.colors.gray2};
  }
`;

const ButtonOverlay = styled(ComponentWithOverlayLoader)`
  width: auto;
`;

export default IntegrationsAccount;
