import React, { useRef } from 'react';
import styled from 'styled-components';
import { TextSubtitle, TextTiny } from '../../../../shared/react/components/complex/Text';
import Button from '../../../../shared/react/components/complex/Button';
import useOnClickOutside from '../../../../shared/react/hooks/useOutsideClick';
import { track } from '../../tracker/Tracker';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import ShareSocialButtons from 'shared/react/components/complex/share/ShareSocialButtons';
import Utils from 'shared/react/utils/utils';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';

const SocialShare = ({ publishId, closeModal, setSnackBarOpen, buttonRef, ...props }) => {
  const modalRef = useRef();
  const playerUrl = `${location.origin}/${publishId}`;
  const [, { t }] = useTranslation('interactive.landingPage.shareModal');

  const copyClick = () => {
    setSnackBarOpen(true);
    track('Copy Share Link');
    Utils.copyToClipboard(playerUrl);
  };
  const bottomOfShareButton = buttonRef.current?.getBoundingClientRect?.()?.bottom;

  const openLink = (link, name) => {
    if (!publishId) {
      return;
    }
    track('Open Share Link', { type: name });

    Utils.openInNewTab(link);
  };

  useOnClickOutside([modalRef], closeModal);

  return (
    <ShareContainer role="dialog" aria-modal="true" {...props}>
      <Content top={bottomOfShareButton + 20} ref={modalRef}>
        <LinkContainer>
          <CopyText>{t('sharableLink')}</CopyText>
          <CopyContainer>
            <InputContainer>{playerUrl}</InputContainer>
            <Button
              role="button"
              aria-label="Copy Tolstoy link"
              letterSpacing="0.2px"
              onClick={copyClick}
              tabIndex="0"
            >
              {t('copy')}
            </Button>
          </CopyContainer>
        </LinkContainer>
        <SocialContainer>
          <SubHeaderText>{t('shareToSocialMedia')}</SubHeaderText>
          <ShareSocialButtons
            publishId={publishId}
            handleButtonClick={openLink}
            shouldHideCopyLink
          />
        </SocialContainer>
      </Content>
    </ShareContainer>
  );
};

const ShareContainer = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #00000040;
  justify-content: flex-end;
  z-index: 100;
`;

const Content = styled.div`
  display: grid;
  gap: 40px;
  padding: 40px;
  cursor: default;
  z-index: 100;
  min-width: 558px;
  background: white;
  max-height: 214px;
  margin-right: 32px;
  margin-top: ${({ top }) => top || 0}px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  @media (${({ theme }) => theme.breakpoints.dashboardTabletMax}) {
    flex-wrap: unset;
    overflow: auto;
  }
`;

const LinkContainer = styled.div`
  display: grid;
  gap: 2px;
`;

const CopyContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 16px;
`;

const CopyText = styled(TextTiny)`
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 2px;
  letter-spacing: 0.2px;
`;

const SocialContainer = styled(Gap8VerticalFlex)``;

const SubHeaderText = styled(TextSubtitle)`
  font-weight: 700;
  letter-spacing: 0.2px;
`;

const InputContainer = styled.div`
  background: ${({ theme }) => theme.colors.gray5};
  border: 1px solid ${({ theme }) => theme.colors.gray5};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 13px 16px;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0.05px;
`;

export default SocialShare;
