import { project as Project } from 'app/src/types/entities';
import { useAnalytics } from 'app/src/context/AnalyticsStore';
import { useProjectActions } from 'app/src/context/ProjectsStore';

export const useAnalyticsProjects = () => {
  const [{ publishIds }] = useAnalytics();
  const { getProjectByPublishId } = useProjectActions();

  const selectedProjects: Project[] | null = publishIds
    ?.map((publishId: string) => getProjectByPublishId(publishId))
    ?.filter(Boolean);
  const hasSelectedProjects = Boolean(selectedProjects?.length);

  return {
    selectedProjects,
    hasSelectedProjects,
  } as const;
};
