import { ChevronRight, ChevronLeft } from 'lucide-react';
import React from 'react';
import styled from 'styled-components';
import { VideoMovementDirection } from 'app/src/pages/dashboard/pages/videos/components/video_card/video-switcher/VideoMovementDirection';

interface ArrowButtonProps {
  moveToVideo: (direction: VideoMovementDirection) => void;
}

export const RightButton = ({ moveToVideo }: ArrowButtonProps) => (
  <StyledButtons data-position="right" onClick={() => moveToVideo('next')}>
    <ChevronRight color="white" />
  </StyledButtons>
);

export const LeftButton = ({ moveToVideo }: ArrowButtonProps) => (
  <StyledButtons data-position="left" onClick={() => moveToVideo('prev')}>
    <ChevronLeft color="white" />
  </StyledButtons>
);

const StyledButtons = styled.button`
  --tolstoy-modal-window-switch-video-buttons-size: 40px;

  position: absolute;
  z-index: 1;
  background: rgba(0, 0, 0, 0.3);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  top: calc(50% - (var(--tolstoy-modal-window-switch-video-buttons-size) / 2));
  padding: 0;

  &:hover,
  &:active {
    background: rgba(0, 0, 0, 0.4);
  }

  &[data-position='left'] {
    left: 0;
    transform: translateX(-100%);

    & > svg {
      right: 2px;
    }
  }

  &[data-position='right'] {
    right: 0;
    transform: translateX(100%);

    & > svg {
      left: 2px;
    }
  }

  &:active > svg {
    transform: scale(0.9);
  }

  & > svg {
    position: relative;
    vertical-align: middle;
    transition: transform 80ms;
    width: var(--tolstoy-modal-window-switch-video-buttons-size);
    height: var(--tolstoy-modal-window-switch-video-buttons-size);
  }
`;
