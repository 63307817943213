import React from 'react';
import styled from 'styled-components';
import flowCardCss from 'app/src/pages/project/pages/quiz/quiz_result/flow/FlowCard';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import { BolderTextSmall } from 'shared/react/components/complex/Text';
import QuizAddFlowIcon from 'shared/react/images/quiz/QuizAddFlowIcon';
import { useQuizContext } from 'app/src/pages/project/pages/quiz/QuizContext';

function AddFlowButton({ quizResultId }) {
  const { onCreateFlowClick } = useQuizContext();

  return (
    <LayoutRoot onClick={() => onCreateFlowClick(quizResultId)}>
      <QuizAddFlowIcon />
      <BolderTextSmall>Add rule box</BolderTextSmall>
    </LayoutRoot>
  );
}

export default AddFlowButton;

const LayoutRoot = styled(Gap8HorizontalFlexWrap)`
  cursor: pointer;
  align-items: center;
  align-content: center;
  justify-content: center;
  ${flowCardCss};
  padding: 8px 16px;
  min-height: 40px;
`;
