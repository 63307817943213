import React, { useRef } from 'react';
import BottomArrowIcon from 'shared/react/images/BottomArrowIcon';
import styled, { useTheme } from 'styled-components';
import Separator from 'shared/react/components/basic/Separator';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import Gap16HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap16HorizontalFlexWrap';
import { TextBody } from 'shared/react/components/complex/Text';
import ComponentWithOverlayLoader from 'shared/react/components/basic/ComponentWithOverlayLoader';

const ButtonWithRightSelect = ({
  onExtraOptionsClicked,
  isLeftButtonLocked,
  isRightButtonLocked,
  shouldHide,
  leftContainerTestId,
  rightContainerTestId,
  onLeftContainerClick,
  leftText,
  textColor,
  icon,
  isOpen,
  isLoading,
  className,
  showArrow = true,
  useLayoutClick,
  showTextInMobile,
}) => {
  const ref = useRef();
  const theme = useTheme();

  const onExtraClick = () => {
    onExtraOptionsClicked({ target: ref.current });
  };

  return (
    <ComponentWithOverlayLoader isLoading={isLoading} loaderColor={theme.colors.primary}>
      <LayoutRoot
        isLoading={isLoading}
        isOpen={isOpen}
        ref={ref}
        shouldHide={shouldHide}
        onClick={useLayoutClick ? onExtraClick : () => {}}
      >
        <LeftContainer
          data-test-id={leftContainerTestId}
          isLocked={isLeftButtonLocked}
          onClick={onLeftContainerClick}
          className={className}
        >
          <IconContainer>{icon}</IconContainer>
          <LeftText
            color={textColor}
            disabled={isLeftButtonLocked}
            showTextInMobile={showTextInMobile}
          >
            {leftText}
          </LeftText>
        </LeftContainer>
        {showArrow && (
          <>
            <CustomSeparator isLocked={isRightButtonLocked} />
            <RightContainer
              data-test-id={rightContainerTestId}
              isLocked={isRightButtonLocked}
              onClick={onExtraClick}
            >
              <BottomArrowIcon width={16} height={16} />
            </RightContainer>
          </>
        )}
      </LayoutRoot>
    </ComponentWithOverlayLoader>
  );
};

const LayoutRoot = styled.div`
  display: ${({ shouldHide }) => (shouldHide ? 'none' : 'grid')};
  grid-template-columns: 1fr auto auto;
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ isOpen }) => (isOpen ? '12px 12px 0 0' : '12px')};
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.08);
  width: 100%;
  transition: 0.3s;
  opacity: ${({ isLoading }) => (isLoading ? 0.6 : 1)};
  pointer-events: ${({ isLoading }) => (isLoading ? 'none' : '')};
  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    background: inherit;
  } ;
`;

const LeftContainer = styled(Gap16HorizontalFlexWrap)`
  padding: 8px 16px 8px 8px;
  align-items: center;
  cursor: ${({ isLocked }) => (isLocked ? '' : 'pointer')};
  pointer-events: ${({ isLocked }) => (isLocked ? 'none' : '')};
  flex-wrap: nowrap;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    padding: 0;
  }

  & path {
    fill: ${({ theme, isLocked }) => (isLocked ? theme.colors.ghostLight : '')};
  }
`;

const RightContainer = styled(LeftContainer)`
  padding: 16px;
  visibility: ${({ isLocked }) => (isLocked ? 'hidden' : '')};
  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    display: none;
  } ;
`;

const CustomSeparator = styled(Separator)`
  width: 1px;
  height: auto;
  margin: 8px 0;
  visibility: ${({ isLocked }) => (isLocked ? 'hidden' : '')};
  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    display: none;
  } ;
`;

const IconContainer = styled(HorizontalFlexWrap)`
  min-width: 24px;
  min-height: 24px;
  align-content: center;
  flex-wrap: nowrap;
`;

const LeftText = styled(TextBody)`
  font-weight: 800;
  opacity: ${({ disabled }) => (disabled ? 0.7 : '')};
  width: max-content;
  color: ${({ color }) => color};

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    display: ${({ showTextInMobile }) => (showTextInMobile ? '' : 'none')};
  } ;
`;

export default ButtonWithRightSelect;
