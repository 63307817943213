import React from 'react';

const ThumbnailEditIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.25 2.5c.82 0 1.5.68 1.5 1.5v5.25c0 .844-.68 1.5-1.5 1.5H2.75a1.48 1.48 0 0 1-1.5-1.5V4c0-.82.656-1.5 1.5-1.5h10.5Zm0 1.125H2.75A.385.385 0 0 0 2.375 4v5.25c0 .21.164.375.375.375h10.5a.385.385 0 0 0 .375-.375V4a.403.403 0 0 0-.375-.375ZM1.25 13c0-.398.328-.75.75-.75h.75c.398 0 .75.352.75.75v.75c0 .422-.352.75-.75.75H2a.74.74 0 0 1-.75-.75V13Zm5.25-.75c.398 0 .75.352.75.75v.75c0 .422-.352.75-.75.75h-.75a.74.74 0 0 1-.75-.75V13c0-.398.328-.75.75-.75h.75Zm2.25.75c0-.398.328-.75.75-.75h.75c.398 0 .75.352.75.75v.75c0 .422-.352.75-.75.75H9.5a.74.74 0 0 1-.75-.75V13Zm5.25-.75c.398 0 .75.352.75.75v.75c0 .422-.352.75-.75.75h-.75a.74.74 0 0 1-.75-.75V13c0-.398.328-.75.75-.75H14Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default ThumbnailEditIcon;
