import React from 'react';
import { VerticalFlexCentered } from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import styled from 'styled-components';

type Props = {
  children?: React.ReactNode;
  onClick?: () => void;
  index?: number;
};

const VerticalVideoCardEmptyStateBase = ({ onClick, children, ...props }: Props) => {
  return (
    <LayoutRoot onClick={onClick} {...props}>
      {children}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlexCentered)`
  border: 1px dashed ${({ theme }) => theme.colors.neutralGray};
  background-color: ${({ theme }) => theme.colors.neutralLighter};
  border-radius: 12px;
  width: 100%;
  max-width: 255px;
  height: 337px;
  cursor: pointer;
  gap: 12px;
  position: relative;
`;

export default VerticalVideoCardEmptyStateBase;
