import React from 'react';

const PopcornIcon = () => (
  <svg width={20} height={21} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.594 8.273c.117.157.156.352.117.547l-3.125 11.25a.587.587 0 0 1-.586.43H5c-.313 0-.547-.156-.625-.43L1.25 8.82c-.04-.195 0-.39.117-.547A.668.668 0 0 1 1.875 8h16.25c.195 0 .352.117.469.273ZM14.492 19.25l2.774-10h-2.383l-1.172 8.242a.587.587 0 0 1-.586.508h-.117a.572.572 0 0 1-.508-.703l1.133-8.047h-3.008v8.125A.642.642 0 0 1 10 18a.617.617 0 0 1-.625-.625V9.25H6.328l1.094 8.047a.572.572 0 0 1-.508.703h-.078a.587.587 0 0 1-.586-.508L5.078 9.25H2.695l2.774 10h9.023ZM2.11 6.281c-.156.274-.078.664.235.82.117.079.195.079.312.079.196 0 .43-.117.547-.313a1.08 1.08 0 0 1 .781-.508c.235 0 .43-.117.508-.312.117-.195.117-.43 0-.586a.846.846 0 0 1 0-.86c.235-.39.664-.585 1.133-.468.156.039.352-.04.508-.156a.557.557 0 0 0 .234-.47c0-.39.274-.741.703-.859.313-.117.664-.039.938.157.156.117.39.156.586.078a.762.762 0 0 0 .39-.39.886.886 0 0 1 .664-.665c.547-.195 1.133.078 1.29.586v.04c.078.155.195.312.351.39.195.117.469.078.664-.04.274-.195.625-.273.938-.156.43.118.703.47.703.86 0 .195.078.351.234.469.156.117.352.195.508.156.469-.117.898.078 1.133.469a.847.847 0 0 1 0 .859.532.532 0 0 0 0 .586c.078.195.273.312.508.351.312 0 .625.196.78.47.196.312.587.429.86.234.313-.157.39-.547.235-.86-.235-.39-.625-.742-1.055-.898.078-.469 0-.938-.235-1.367-.39-.664-1.093-1.055-1.835-1.133-.196-.625-.743-1.172-1.446-1.406-.469-.157-.976-.118-1.445 0C11.29.695 10.235.344 9.259.618a2.2 2.2 0 0 0-1.133.82c-.469-.118-.977-.157-1.445 0-.703.234-1.25.742-1.446 1.406-.742.078-1.445.469-1.836 1.133a2.1 2.1 0 0 0-.234 1.367 2.07 2.07 0 0 0-1.055.937Z"
      fill="#090A0B"
    />
  </svg>
);

export default PopcornIcon;
