import React, { useEffect } from 'react';
import styled from 'styled-components';
import StandardModal from 'app/src/pages/modals/StandardModal';
import ShopifyLogo from 'app/src/images/shopify.png';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextBody, TextH5 } from 'shared/react/components/complex/Text';
import ChatWithSupport from 'app/src/complex_components/ChatWithSupport';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import Button from 'shared/react/components/complex/Button';
import Types from 'shared/react/theme/Types';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

const ShopifyStoreModal = ({
  title,
  subtitle,
  localStorageKey,
  primaryButtonText,
  onPrimaryButtonClick,
}) => {
  const [, { clearModalState }] = useModal();

  const onClose = () => {
    clearModalState();
  };

  useEffect(() => {
    if (localStorageKey) {
      localStorage.removeItem(localStorageKey);
    }
  }, [localStorageKey]);

  return (
    <StandardModal open width="440px" onClose={onClose}>
      <LayoutRoot>
        <TopContainer>
          <StyledImage src={ShopifyLogo} />
          <ContentContainer>
            <TextH5>{title}</TextH5>
            <TextBody>{subtitle}</TextBody>
            <ChatWithSupport />
          </ContentContainer>
        </TopContainer>
        <ButtonContainer>
          <Button type={Types.Secondary} onClick={onClose}>
            Close
          </Button>
          <PrimaryButton onClick={onPrimaryButtonClick} text={primaryButtonText} />
        </ButtonContainer>
      </LayoutRoot>
    </StandardModal>
  );
};

const PrimaryButton = ({ onClick, text }) => {
  if (!onClick && !text) {
    return null;
  }

  return <Button onClick={onClick}>{text}</Button>;
};

const LayoutRoot = styled(VerticalFlex)`
  gap: 40px;
  padding: 12px;
`;

const StyledImage = styled.img`
  height: 48px;
  width: 42px;
`;

const TopContainer = styled(VerticalFlex)`
  gap: 24px;
`;

const ContentContainer = styled(Gap8VerticalFlex)``;

const ButtonContainer = styled(Gap8HorizontalFlex)`
  justify-content: flex-end;
`;

export default ShopifyStoreModal;
