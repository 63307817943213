import React from 'react';
import styled from 'styled-components';
import AnalyticsEngagementTolstoysEventTable from './AnalyticsEngagementTolstoysEventTable';
import AnalyticsEngagementEmailCollectedTable from './AnalyticsEngagementEmailCollectedTable';
import AnalyticsEngagementTolstoyClicksTable from './AnalyticsEngagementTolstoyClicksTable';
import { useAnalyticsProjects } from '../useAnalyticsProjects';

const AnalyticsEngagementTables = () => {
  const { hasSelectedProjects } = useAnalyticsProjects();

  return (
    <StyledAnalyticsEngagementTables>
      {!hasSelectedProjects && <AnalyticsEngagementTolstoysEventTable />}
      {hasSelectedProjects && (
        <>
          <AnalyticsEngagementEmailCollectedTable />
          <AnalyticsEngagementTolstoyClicksTable />
        </>
      )}
    </StyledAnalyticsEngagementTables>
  );
};

export default AnalyticsEngagementTables;

const StyledAnalyticsEngagementTables = styled.div`
  grid-area: tables;
  display: grid;
  grid-template-areas:
    'events'
    'email'
    'clicks';
  gap: 50px;
`;
