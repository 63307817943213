import React from 'react';
import styled from 'styled-components';
import EditorHeader from '../shared/EditorHeader';
import OnOffToggleItem from '../shared/OnOffToggleItem';
import { useLookAndFeelContext } from '../../../LookAndFeelContext';
import {
  PRODUCT_DETAILS_SHIPPING_ENABLED_PROPERTY_KEY,
  PRODUCT_DETAILS_SHIPPING_TEXT_PROPERTY_KEY,
  PRODUCT_DETAILS_SHIPPING_FONT_COLOR_PROPERTY_KEY,
  PRODUCT_DETAILS_SHIPPING_FONT_WEIGHT_PROPERTY_KEY,
  FONT_WEIGHTS,
} from '../../../LookAndFeelPage.constants';
import EditorMainContainer from '../shared/EditorMainContainer';
import EditorTextInputItem from '../shared/EditorTextInputItem';
import EditorColorInputItem from '../shared/EditorColorInputItem';
import EditorButtonGroupItem from '../shared/EditorButtonGroupItem';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const ProductDetailsShippingEditor = () => {
  const {
    customizationSettings: {
      playerSettings: {
        productDetailsShippingEnabled,
        productDetailsShippingText,
        productDetailsShippingFontColor,
        productDetailsShippingFontWeight,
      },
    },
    setPlayerSettingsProperty,
  } = useLookAndFeelContext();

  const onEnableShipping = () => {
    setPlayerSettingsProperty(
      PRODUCT_DETAILS_SHIPPING_ENABLED_PROPERTY_KEY,
      !productDetailsShippingEnabled
    );
  };

  const onTextChange = value => {
    setPlayerSettingsProperty(PRODUCT_DETAILS_SHIPPING_TEXT_PROPERTY_KEY, value);
  };

  const onColorChange = value => {
    setPlayerSettingsProperty(PRODUCT_DETAILS_SHIPPING_FONT_COLOR_PROPERTY_KEY, value);
  };

  const onFontWeightChange = value => {
    setPlayerSettingsProperty(PRODUCT_DETAILS_SHIPPING_FONT_WEIGHT_PROPERTY_KEY, value);
  };

  const fontWeightButtons = [
    {
      text: 'Light',
      isEnabled: productDetailsShippingFontWeight === FONT_WEIGHTS.light,
      onClick: () => onFontWeightChange(FONT_WEIGHTS.light),
    },
    {
      text: 'Regular',
      isEnabled: productDetailsShippingFontWeight === FONT_WEIGHTS.normal,
      onClick: () => onFontWeightChange(FONT_WEIGHTS.normal),
    },
    {
      text: 'Bold',
      isEnabled: productDetailsShippingFontWeight === FONT_WEIGHTS.bold,
      onClick: () => onFontWeightChange(FONT_WEIGHTS.bold),
    },
  ];

  return (
    <LayoutRoot>
      <EditorHeader title="Shipping" isWhite={!productDetailsShippingEnabled}>
        <OnOffToggleItem checked={productDetailsShippingEnabled} onChange={onEnableShipping} />
      </EditorHeader>
      <EditorMainContainer shouldHide={!productDetailsShippingEnabled}>
        <EditorTextInputItem
          placeholder="Free Shipping Over $100 🚚"
          text="Text"
          value={productDetailsShippingText}
          onChange={onTextChange}
          maxWidth="240px"
          maxLength={200}
        />
        <EditorColorInputItem
          text="Text color"
          color={productDetailsShippingFontColor}
          onChange={onColorChange}
        />
        <EditorButtonGroupItem text="Font weight" buttons={fontWeightButtons} />
      </EditorMainContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

export default ProductDetailsShippingEditor;
