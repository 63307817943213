import React from 'react';

const TaggedIcon = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 0h3c.813 0 1.5.688 1.5 1.5v3A1.5 1.5 0 0 1 5 6H2A1.48 1.48 0 0 1 .5 4.5v-3A1.5 1.5 0 0 1 2 0Zm0 8h3c.813 0 1.5.688 1.5 1.5v3A1.5 1.5 0 0 1 5 14H2a1.48 1.48 0 0 1-1.5-1.5v-3A1.5 1.5 0 0 1 2 8Zm6.5-6.5A1.5 1.5 0 0 1 10 0h3c.813 0 1.5.688 1.5 1.5v3A1.5 1.5 0 0 1 13 6h-3a1.48 1.48 0 0 1-1.5-1.5v-3Zm3 6a.76.76 0 0 1 .75.75v2h2A.76.76 0 0 1 15 11a.74.74 0 0 1-.75.75h-2v2a.74.74 0 0 1-.75.75.722.722 0 0 1-.75-.75v-2h-2A.722.722 0 0 1 8 11a.74.74 0 0 1 .75-.75h2v-2a.74.74 0 0 1 .75-.75Z"
        fill="#B3B6BC"
      />
    </svg>
  );
};

export default TaggedIcon;
