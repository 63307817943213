import React from 'react';
import EditorGroup from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorGroup';
import HeroOverlayLayoutEditor from 'src/pages/project/pages/look_and_feel/editor/editors/hero-overlay-editor/HeroOverlayLayoutEditor';
import { useLookAndFeelContext } from 'src/pages/project/pages/look_and_feel/LookAndFeelContext';
import HeroOverlayTextCustomizationEditor from 'src/pages/project/pages/look_and_feel/editor/editors/hero-overlay-editor/HeroOverlayTextCustomizationEditor';
import HeroOverlayButtonCustomizationEditor from 'src/pages/project/pages/look_and_feel/editor/editors/hero-overlay-editor/HeroOverlayButtonCustomizationEditor';

const HeroOverlayEditorGroup = () => {
  const {
    customizationSettings: {
      heroSettings: {
        headerTextCustomization,
        subheaderTextCustomization,
        primaryButton,
        secondaryButton,
      },
    },
    setHeroSettingsProperty,
  } = useLookAndFeelContext();

  const setHeaderTextCustomization = (value: { [key: string]: string }) => {
    setHeroSettingsProperty('headerTextCustomization', {
      ...headerTextCustomization,
      ...value,
    });
  };

  const setSubHeaderTextCustomization = (value: { [key: string]: string }) => {
    setHeroSettingsProperty('subheaderTextCustomization', {
      ...subheaderTextCustomization,
      ...value,
    });
  };

  const setPrimaryButtonCustomization = (value: { [key: string]: string }) => {
    setHeroSettingsProperty('primaryButton', {
      ...primaryButton,
      ...value,
    });
  };

  const setSecondaryButtonCustomization = (value: { [key: string]: string }) => {
    setHeroSettingsProperty('secondaryButton', {
      ...secondaryButton,
      ...value,
    });
  };

  const editors = [
    { key: 'overlay', component: <HeroOverlayLayoutEditor /> },
    {
      key: 'headerTextCustomization',
      component: (
        <HeroOverlayTextCustomizationEditor
          textCustomization={headerTextCustomization}
          setFunction={setHeaderTextCustomization}
          title={'Header text'}
          desktopSizes={{ min: 16, max: 80 }}
          mobileSizes={{ min: 14, max: 48 }}
        />
      ),
    },
    {
      key: 'subheaderTextCustomization',
      component: (
        <HeroOverlayTextCustomizationEditor
          textCustomization={subheaderTextCustomization}
          title={'Subheader text'}
          setFunction={setSubHeaderTextCustomization}
          desktopSizes={{ min: 12, max: 64 }}
          mobileSizes={{ min: 10, max: 36 }}
        />
      ),
    },

    {
      key: 'PrimaryButtonCustomization',
      component: (
        <HeroOverlayButtonCustomizationEditor
          setFunction={setPrimaryButtonCustomization}
          title={'Primary button'}
          buttonSettings={primaryButton}
        />
      ),
    },
    {
      key: 'SecondaryButtonCustomization',
      component: (
        <HeroOverlayButtonCustomizationEditor
          setFunction={setSecondaryButtonCustomization}
          title={'Secondary button'}
          buttonSettings={secondaryButton}
        />
      ),
    },
  ];

  return <EditorGroup editors={editors} />;
};

export default HeroOverlayEditorGroup;
