import React from 'react';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { useApps } from 'src/context/AppsStore';
import StickyTopBar from '../../dashboard/components/top-bar/StickyTopBar';
import AppInstallationSteps from './app_installation/AppInstallationSteps';
import { BIG_COMMERCE, MAGENTO, SHOPIFY, WIX } from 'src/constants/intergrations.constants';
import useQuery from 'app/src/hooks/useQuery';
import ImportCsv from './components/import_csv/ImportCsv';
import { app as AppEntity } from 'src/types/entities';

const getApps = (apps: AppEntity[], selectedAppUrl: string) => {
  const nonShopifySupportedIntegrations = [BIG_COMMERCE, WIX, MAGENTO];

  const shopifyApps = apps?.filter(app => app?.active && app?.app === SHOPIFY);

  if (selectedAppUrl) {
    const selectedApp = shopifyApps?.find(app => app?.appUrl === selectedAppUrl);
    return [selectedApp];
  }

  if (shopifyApps?.length > 0) {
    return shopifyApps;
  }

  const nonShopifyApp = apps?.find(
    app => app?.active && nonShopifySupportedIntegrations.includes(app?.app)
  );

  return [nonShopifyApp];
};

const InstallationPage: React.FC = () => {
  const [{ apps: storeApps, selectedAppUrl }] = useApps();
  const appUrl = useQuery().get('appUrl');

  const apps = getApps(storeApps, selectedAppUrl);
  const isShopify = apps?.[0]?.app === SHOPIFY;
  const shopifyIndex = apps?.findIndex(app => app?.appUrl === appUrl);
  const startExpandedIndex = shopifyIndex > -1 ? shopifyIndex : 0;

  return (
    <LayoutRoot>
      <StickyTopBar leftContent="Installation" dirtyTitle="Installation" />
      <Content>
        {apps?.map((app, index) => (
          <AppInstallationSteps
            key={app?.id || `install-steps-${index}`}
            app={app}
            startExpanded={index === startExpandedIndex}
            isShopify={isShopify}
          />
        ))}

        {isShopify ? null : <ImportCsv />}
      </Content>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  overflow-y: scroll;
`;

const Content = styled(VerticalFlex)`
  padding: 24px;
`;

export default InstallationPage;
