import React from 'react';

const TextSideBar = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_f_2098_142124)">
        <path
          d="M25 46C36.5983 46 46 36.5983 46 25C46 13.4017 36.5983 4 25 4C13.4017 4 4 13.4017 4 25C4 28.8241 5.0227 32.413 6.8098 35.5L5.05 44.95L14.5 43.1902C17.691 45.0362 21.3135 46.0056 25 46Z"
          fill="black"
          fillOpacity="0.2"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45 25C45 36.046 36.046 45 25 45C21.489 45.0053 18.0391 44.0821 15 42.324L6 44L7.676 35C5.974 32.06 5 28.642 5 25C5 13.954 13.9543 5 25 5C36.0457 5 45 13.954 45 25ZM31.3714 22.1143C31.9179 22.1143 32.4 21.6321 32.4 21.0857V19.0286C32.4 18.45 31.9179 18 31.3714 18H19.0286C18.45 18 18 18.45 18 19.0286V21.0857C18 21.6321 18.45 22.1143 19.0286 22.1143C19.575 22.1143 20.0571 21.6321 20.0571 21.0857V20.0571H24.1714V30.3429H22.6286C22.05 30.3429 21.6 30.825 21.6 31.3714C21.6 31.8857 22.05 32.3679 22.6286 32.3679H27.7714C28.3179 32.3679 28.8 31.9179 28.8 31.3714C28.8 30.7928 28.3179 30.3429 27.7714 30.3429H26.2286V20.0571H30.3429V21.0857C30.3429 21.6321 30.7928 22.1143 31.3714 22.1143Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_f_2098_142124"
          x="0"
          y="0"
          width="50"
          height="50"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_2098_142124" />
        </filter>
      </defs>
    </svg>
  );
};

export default TextSideBar;
