import React, { useState } from 'react';
import { TextBodyBold } from 'shared/react/components/basic/text/TextV2';
import { useProjectActions } from 'src/context/ProjectsStore';
import { track } from 'src/helpers/Tracker';
import { project as ProjectType, publishMethodOptions } from 'src/types/entities';
import SetLiveTogglePricing from 'src/complex_components/pricing/SetLiveTogglePricing';
import { useSnackBar } from 'src/context/ui_store/SnackBarStore';
import styled from 'styled-components';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';

type SetLiveToggleProps = {
  project: ProjectType;
  widgetType?: publishMethodOptions;
};

const SetLiveToggle: React.FC<SetLiveToggleProps> = ({ project, widgetType }) => {
  const [, { setSnackbar, setErrorSnackbar }] = useSnackBar();
  const { updateProject } = useProjectActions();
  const [isLive, setIsLive] = useState(project?.live || false);
  const [isLoading, setIsLoading] = useState(false);

  const handleToggle = async () => {
    try {
      setIsLoading(true);
      track('Set Live Toggle Click:', { isLive });

      const updatedProject = await updateProject({ ...project, live: !isLive });

      setIsLive(!!updatedProject?.live);
      setIsLoading(false);
      setSnackbar('Changes have been saved!');
    } catch (err) {
      setErrorSnackbar('There have been an error saving');
      setIsLoading(false);
    }
  };

  return (
    <LayoutRoot>
      <TextBodyBold>Set Live</TextBodyBold>
      <SetLiveTogglePricing
        disabled={isLoading}
        checked={isLive}
        onChange={handleToggle}
        loading={isLoading}
        widgetType={widgetType}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlexWrap)`
  justify-items: center;
  align-items: center;
  padding-bottom: 8px;
`;

export default SetLiveToggle;
