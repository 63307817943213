import React from 'react';
import styled from 'styled-components';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import EditorOnOffToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorOnOffToggleItem';
import { CHAPTERS_ENABLED_PROPERTY_KEY } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import EditorHeader from '../shared/EditorHeader';
import EditorMainContainer from '../shared/EditorMainContainer';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

function FeaturesEditor() {
  const {
    customizationSettings: { chaptersEnabled },
    setCustomizationSettingsProperty,
  } = useLookAndFeelContext();

  return (
    <LayoutRoot>
      <EditorHeader title="Features" />
      <EditorMainContainer>
        <EditorOnOffToggleItem
          text="Chapters"
          checked={!!chaptersEnabled}
          onChange={value => setCustomizationSettingsProperty(CHAPTERS_ENABLED_PROPERTY_KEY, value)}
        />
      </EditorMainContainer>
    </LayoutRoot>
  );
}

export default FeaturesEditor;

const LayoutRoot = styled(VerticalFlex)``;
