import React from 'react';
import styled from 'styled-components';

import Utils from 'shared/react/utils/utils';
import ImageWithLoader from 'shared/react/components/complex/ImageWithLoader';
import CarouselSlider from 'shared/react/components/complex/carousel_slider/CarouselSlider';
import { BolderTextSmall, LightTextSmall } from 'shared/react/components/complex/Text';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import useOpenProductModal from 'shared/react/components/complex/context/hooks/useOpenProductModal';
import useTracker from 'shared/react/components/complex/context/hooks/useTracker';

const PRODUCT_MODAL_CONTENT_WIDTH = '50%';

const FeedSearchProductCarousel = ({ products, currentStepKey, isMobile, searchTerm }) => {
  const [, { track }] = useTracker();
  const { handleOpenProductModal } = useOpenProductModal({
    step: {},
    currentStepKey,
  });

  const handleRenderItem = product => {
    const { imageUrl, title, price } = product;
    const currencySymbol = product?.currencySymbol || products?.[0]?.currencySymbol;

    const displayedPrice = Utils.getDisplayedPrice({
      price: price / 100,
      currencySymbol,
    });

    const onProductClick = (event, product) => {
      const variantId = product?.variants?.[0]?.id;

      track('feedSearchProductClick', {
        formData: JSON.stringify({ searchText: searchTerm, productImage: product.imageUrl }),
      });

      const customProps = {
        customProduct: product,
        customContentWidth: !isMobile && PRODUCT_MODAL_CONTENT_WIDTH,
      };
      handleOpenProductModal(event, {
        product,
        variantId,
        externalProductIds: [products?.[0]?.id],
        customProps,
      });
    };

    return (
      <ProductContainer onClick={event => onProductClick(event, product)}>
        <StyledImageWithLoader src={imageUrl} />
        <ProductDetails>
          <ProductName>{title}</ProductName>
          <ProductPrice>{displayedPrice}</ProductPrice>
        </ProductDetails>
      </ProductContainer>
    );
  };

  if (!products?.length) {
    return null;
  }

  return (
    <LayoutRoot>
      <StyledCarousel
        items={products}
        renderItem={handleRenderItem}
        isHorizontal={isMobile}
        itemsPerPage={2.5}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)`
  overflow: scroll;
  padding-top: 8px;
  text-align: left;
  direction: ltr;
  color: ${({ theme }) => theme.colors.black};
`;

const StyledCarousel = styled(CarouselSlider)`
  width: ${({ isHorizontal }) => (isHorizontal ? '100%' : '')};
`;

const ProductName = styled(BolderTextSmall)`
  color: ${({ theme }) => theme.colors.black};
  font-size: 12px;
  text-align: left;
`;

const ProductPrice = styled(LightTextSmall)``;

const ProductContainer = styled(Gap8VerticalFlex)`
  overflow: hidden;
  cursor: pointer;

  &:hover ${ProductName} {
    text-decoration: underline;
  }
`;

const StyledImageWithLoader = styled(ImageWithLoader)`
  & img {
    height: 100%;
    width: 100%;
    aspect-ratio: 1;
    object-fit: contain;
    border-radius: 0px;
  }
`;

const ProductDetails = styled(VerticalFlex)`
  gap: 4px;
  padding: 0 16px;
`;

export default FeedSearchProductCarousel;
