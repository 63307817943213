import { CustomEventManager } from 'shared/react/services/custom-events';
import { ASSET_TYPE } from 'shared/react/services/assets';
import { defaultRef } from './constants';

const DURATION = 5;
const PLAYTIME_UPDATE_INTERVAL = 100;

const CUSTOM_EVENT_NAME = {
  TIMEUPDATE: 'timeupdate',
};

const CUSTOM_EVENTS = [CUSTOM_EVENT_NAME.TIMEUPDATE];

export class GalleryAssetRef {
  constructor(ref = defaultRef) {
    this.ref = ref;

    this._duration = DURATION;
    this._currentTime = 0;
    this._isPlaying = false;
    this._intervalId = null;
    this._loop = false;

    this.isGallery = true;
    this.isImage = false;
    this.isVideo = false;
    this.type = ASSET_TYPE.GALLERY;

    this._eventManager = new CustomEventManager();
  }

  get muted() {
    return true;
  }
  set muted(value) {}

  get currentTime() {
    return this._currentTime;
  }
  set currentTime(value) {
    this._currentTime = value;
  }

  get duration() {
    return this._duration;
  }
  get paused() {
    return !this._isPlaying;
  }

  async play() {
    if (this._isPlaying) {
      return;
    }

    this._isPlaying = true;
    this._intervalId = setInterval(() => {
      this._currentTime = Math.round(this._currentTime * 1000 + PLAYTIME_UPDATE_INTERVAL) / 1000;

      if (this._currentTime >= this._duration && this._loop) {
        this._currentTime = 0;
      }

      if (this._currentTime >= this._duration && !this._loop) {
        this.pause();
      }

      this._eventManager.emitEvent(CUSTOM_EVENT_NAME.TIMEUPDATE, { target: this });
    }, PLAYTIME_UPDATE_INTERVAL);
  }

  pause() {
    if (!this._isPlaying) {
      return;
    }

    this._isPlaying = false;
    clearInterval(this._intervalId);
  }

  addEventListener(eventName, callback) {
    if (CUSTOM_EVENTS.includes(eventName)) {
      return this._eventManager.addEventListener(eventName, callback);
    }

    return this.ref.addEventListener(eventName, callback);
  }

  removeEventListener(eventName, callback) {
    if (CUSTOM_EVENTS.includes(eventName)) {
      return this._eventManager.removeEventListener(eventName, callback);
    }

    return this.ref.removeEventListener(eventName, callback);
  }

  scrollIntoView() {
    return this.ref.scrollIntoView();
  }
}

export default GalleryAssetRef;
