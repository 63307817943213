import React, { useState } from 'react';
import styled from 'styled-components';
import { TextBodyLeading } from 'shared/react/components/complex/Text';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import XIcon from 'shared/react/images/XIcon';
import useTracker from 'shared/react/components/complex/context/hooks/useTracker';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import ModalWrapper from 'shared/react/components/basic/ModalWrapper';
import useFeedModal from 'shared/react/components/complex/context/hooks/useFeedModal';
import ReportIcon from 'shared/react/images/feed/FilledReportIcon';
import Gap16HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap16HorizontalFlexWrap';
import useKeyboardNavigation from 'player/src/hooks/useKeyboardNavigation';
import { REPORT_MODAL_KEY } from '../../../../constants/modals.constants';

const FeedMenuModal = () => {
  const [{ currentModal }, { pushModal, popModal }] = useFeedModal();
  const { onClose, currentStepKey, videoId, isMobile } = currentModal.props;
  const [, { track }] = useTracker();
  const { onKeyDown, setNextRef } = useKeyboardNavigation();

  const [isOpen, setIsOpen] = useState(false);

  const onMenuModalClose = () => {
    track('feedMenuModalClose');
    setIsOpen(false);

    popModal();
    onClose();
  };

  const onReportClick = () => {
    track('feedReportModalOpen');

    popModal();

    pushModal({
      key: REPORT_MODAL_KEY,
      props: {
        isMobile,
        onClose: onMenuModalClose,
        currentStepKey,
        videoId,
      },
    });
  };

  return (
    <StyledModalWrapper onClose={onMenuModalClose} isOpen={isOpen} setIsOpen={setIsOpen}>
      <LayoutRoot>
        <TopContainer>
          <XButton
            onClick={onMenuModalClose}
            role="button"
            tabIndex="0"
            aria-label="Menu close button"
            ref={setNextRef}
            onKeyDown={onKeyDown}
          >
            <XIcon />
          </XButton>
        </TopContainer>
        <MenuItem onClick={onReportClick}>
          <ReportIcon />
          <MenuItemText> Report </MenuItemText>
        </MenuItem>
      </LayoutRoot>
    </StyledModalWrapper>
  );
};

const StyledModalWrapper = styled(ModalWrapper)`
  bottom: 0;
  padding: 16px;
  border-radius: 10px 10px 0 0;
  width: 100vw;
  box-sizing: border-box;
  z-index: 50;
`;

const LayoutRoot = styled(VerticalFlex)`
  margin-bottom: 16px;
`;

const TopContainer = styled(HorizontalFlex)`
  justify-content: flex-end;
`;

const XButton = styled(VerticalFlex)`
  cursor: pointer;

  & svg {
    width: 18px;
    height: 18px;
  }
`;

const MenuItem = styled(Gap16HorizontalFlexWrap)`
  cursor: pointer;
`;

const MenuItemText = styled(TextBodyLeading)`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #e2506d;
`;

export default FeedMenuModal;
