import { useState, useMemo } from 'react';
import { VideosPageVodAsset } from 'app/src/pages/dashboard/pages/videos/types/videos.types';
import { Filters } from '../types';
import { validateAsset, sortAssets } from '../utils';
import { SortOrder, SortField } from '../constants';

type Props = {
  assets: VideosPageVodAsset[];
  defaultSort: {
    field: SortField;
    order: SortOrder;
  };
  defaultFilters: Filters;
};

export const useFilteredAssets = ({ assets, defaultSort, defaultFilters }: Props) => {
  const [filters, setFilters] = useState(defaultFilters);
  const [sort, setSort] = useState(defaultSort);

  const filteredAssets = useMemo(() => {
    const selectedCount = Object.values(filters)
      .flat()
      .filter(filter => filter.isSelected).length;
    let filteredAssets = assets;

    if (selectedCount) {
      filteredAssets = assets.filter(asset => {
        return Object.values(filters)
          .flat()
          .filter(filter => filter.isSelected)
          .some(filter => validateAsset(asset, filter));
      });
    }

    return filteredAssets.sort((a, b) => sortAssets(a, b, sort));
  }, [assets, filters, sort]);

  return {
    assets: filteredAssets,
    sort,
    filters,
    setSort,
    setFilters,
  };
};
