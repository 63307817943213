import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { getIsTapcartUseCase } from 'src/utils/cases.utils';
import { project as Project, publishMethodOptions } from 'app/src/types/entities';
import {
  OrangeButton,
  PrimaryButton,
  SuccessButton,
} from 'shared/react/components/basic/button-v2/BasicButton';
import ExpanderTopArrowIcon from 'app/src/images/creation_method_icons/ExpanderTopArrowIcon';
import useAccountInstallation from 'app/src/hooks/useAccountInstallation';
import { useApps } from 'app/src/context/AppsStore';
import PaymentRequiredModal from 'app/src/pages/project/components/project-top-bar/components/set-live-button/PaymentRequiredModal';
import { useBilling } from 'app/src/hooks/useBillings';
import InstallationRequiredModal from 'app/src/pages/project/components/project-top-bar/components/set-live-button/InstallationRequiredModal';
import PublishModal from 'src/pages/project/components/project-top-bar/components/set-live-button/components/publish-modal/PublishModal';
import useSetProjectLive from 'app/src/hooks/useSetProjectLive';
import { useUser } from 'app/src/context/userStore/UserStore';

type Props = {
  project: Project;
};

const PublishButton = ({ project }: Props) => {
  const { live, publishMethod, publishId, useCaseName } = project;
  const [isExpanded, setIsExpanded] = useState(false);
  const { isLoading, toggleWidget } = useSetProjectLive(project);
  const [{ account }] = useUser();
  const [{ selectedApp }] = useApps();
  const [{ isInstallationCompleted }] = useAccountInstallation(selectedApp?.id);
  const { isFreePackage } = useBilling();

  const newInstallationFlow = account?.createdAt > '2023-11-01';

  const isTapcart = getIsTapcartUseCase(useCaseName);
  const isBubble = publishMethod === publishMethodOptions.bubble;
  const isEmail = publishMethod === publishMethodOptions.email;
  const isLive = isBubble ? account?.liveIds?.includes(publishId) : live;
  const [justPublished, setJustPublished] = useState(isExpanded && !isLive);
  const installationBannerRequired = !isInstallationCompleted && newInstallationFlow;

  const handleClose = () => {
    setIsExpanded(false);
    setJustPublished(false);
  };

  const handleCancel = () => {
    setIsExpanded(false);
    if (justPublished) {
      toggleWidget(false);
    }
  };

  const handlePublishClick = () => {
    if ((isFreePackage || installationBannerRequired) && !isTapcart) {
      setIsExpanded(true);
      return;
    }

    if (!isExpanded && !isLive) {
      setJustPublished(true);
    }

    toggleWidget(true);

    if (!isBubble) {
      setIsExpanded(!isExpanded);
    }
  };

  const handlePauseClick = () => {
    toggleWidget(false);
    handleClose();
  };

  const handlePublishedClick = () => {
    setJustPublished(false);
    setIsExpanded(!isExpanded);
  };

  const buttonExpander = isBubble ? null : (
    <ExpanderContainer $isExpanded={isExpanded}>
      <ExpanderTopArrowIcon />
    </ExpanderContainer>
  );

  let button;
  if (!isLive) {
    button = (
      <PublishButtonStyled onClick={handlePublishClick} disabled={isLoading} isLoading={isLoading}>
        Publish
        {buttonExpander}
      </PublishButtonStyled>
    );
  } else if (isBubble) {
    button = (
      <PauseButton onClick={handlePauseClick} disabled={isLoading} isLoading={isLoading}>
        Pause
      </PauseButton>
    );
  } else {
    button = (
      <PublishedButton onClick={handlePublishedClick} disabled={isLoading} isLoading={isLoading}>
        Published
        {buttonExpander}
      </PublishedButton>
    );
  }

  let modal;
  if (!isExpanded) {
    modal = null;
  } else if (isFreePackage && !isTapcart) {
    modal = <PaymentRequiredModal onCancel={() => setIsExpanded(false)} />;
  } else if (installationBannerRequired && !isTapcart) {
    modal = <InstallationRequiredModal />;
  } else {
    modal = (
      <PublishModal
        project={project}
        isLoading={isLoading}
        justPublished={justPublished}
        toggleWidget={toggleWidget}
        onClose={handleClose}
        handleCancel={handleCancel}
      />
    );
  }

  return (
    <div>
      {button}
      {modal}
    </div>
  );
};

const ButtonBase = css`
  width: 120px;
`;

const PublishButtonStyled = styled(PrimaryButton)`
  ${ButtonBase};
`;

const PublishedButton = styled(SuccessButton)`
  ${ButtonBase};
`;

const PauseButton = styled(OrangeButton)`
  ${ButtonBase};
`;

const ExpanderContainer = styled.div<{ $isExpanded: boolean }>`
  transform: rotate(${({ $isExpanded }) => ($isExpanded ? 0 : 180)}deg) translateY(-1px);

  & svg path {
    fill: white;
  }
`;

export default PublishButton;
