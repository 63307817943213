import React from 'react';

const RevertIcon = () => (
  <svg width={12} height={11} viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.273.297c-.21-.07-.468-.024-.609.14L9.47 1.61C8.53.766 7.313.25 6 .25 3.68.25 1.594 1.82.937 4.047c-.07.305.094.61.399.703.305.07.61-.094.703-.398C2.531 2.617 4.172 1.375 6 1.375c1.008 0 1.945.398 2.672 1.031L7.289 3.812c-.164.141-.21.399-.14.61.093.21.304.328.538.328h3.375a.555.555 0 0 0 .563-.563V.813c0-.21-.14-.421-.352-.515Zm-.632 5.976c-.282-.07-.61.094-.68.399A4.17 4.17 0 0 1 6 9.625a4.076 4.076 0 0 1-2.695-1.008l1.382-1.406c.165-.14.211-.399.141-.61a.585.585 0 0 0-.516-.351H.938a.555.555 0 0 0-.562.563v3.375c0 .234.117.445.328.539.07.023.14.023.235.023a.47.47 0 0 0 .375-.164l1.195-1.172A5.182 5.182 0 0 0 6 10.75a5.274 5.274 0 0 0 5.04-3.773.587.587 0 0 0-.4-.704Z"
      fill="#fff"
    />
  </svg>
);

export default RevertIcon;
