import React from 'react';
import styled from 'styled-components';
import { TextBody } from 'shared/react/components/complex/Text';
import PageIcon from 'app/src/images/Page';

const UrlContent = ({ response: { value } }) => {
  return (
    <StyledUrlContent>
      <PageIcon />
      Page
      <UrlLink target="_blank" href={value} title={value}>
        {value}
      </UrlLink>
    </StyledUrlContent>
  );
};

export default UrlContent;

const StyledUrlContent = styled(TextBody)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray27};

  & > :first-child {
    flex-shrink: 0;
  }
`;

const UrlLink = styled.a`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
