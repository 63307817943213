import React from 'react';

const FentyStarHalfFilled = ({ color = 'black', opacity = 1 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
      <path
        d="m9 0 2.688 5.125 5.718 1-4.031 4.156.813 5.719L9 13.469 3.781 16l.844-5.719L.563 6.125l5.718-1z"
        fill={color}
        opacity={opacity}
      />
    </svg>
  );
};

export default FentyStarHalfFilled;
