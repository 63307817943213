import React from 'react';
import styled from 'styled-components';
import StandardModal from 'app/src/pages/modals/StandardModal';
import ChooseMacro from 'app/src/pages/project/pages/inbox/macros/ChooseMacro';

const MacrosModal = ({ open, closeModal, onMacroReply, ...props }) => {
  const handleMacroReply = async (publishId, videoId, macroId) => {
    await onMacroReply(publishId, videoId, macroId);
    closeModal();
  };

  return (
    <StandardModal onClose={closeModal} open={open} width="1050px">
      <Container>
        <ChooseMacro {...props} onMacroReply={handleMacroReply} />
      </Container>
    </StandardModal>
  );
};

const Container = styled.div`
  padding: 14px;
  min-height: 468px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    padding: 10px 10px;
  }

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    width: 100%;
    padding: 10px 0;
  }
`;

export default MacrosModal;
