import React from 'react';

const CircleHelpIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 .5C4.453.5 0 4.992 0 10.5a9.97 9.97 0 0 0 10 10c5.508 0 10-4.453 10-10 0-5.508-4.492-10-10-10Zm0 18.75a8.736 8.736 0 0 1-8.75-8.75c0-4.805 3.906-8.75 8.75-8.75 4.805 0 8.75 3.945 8.75 8.75 0 4.844-3.945 8.75-8.75 8.75Zm-.625-5.313a.925.925 0 0 0-.938.938c0 .547.391.938.938.938a.926.926 0 0 0 .938-.938.95.95 0 0 0-.938-.938ZM11.133 5.5H8.828C7.383 5.5 6.25 6.672 6.25 8.117v.352c0 .351.273.625.625.625a.642.642 0 0 0 .625-.625v-.352c0-.742.586-1.367 1.328-1.367h2.305c.742 0 1.367.625 1.367 1.367 0 .469-.273.938-.703 1.172l-2.344 1.172a1.38 1.38 0 0 0-.703 1.21v.704c0 .352.273.625.625.625a.642.642 0 0 0 .625-.625v-.703c0-.04 0-.078.04-.117l2.343-1.172c.82-.469 1.367-1.328 1.367-2.266A2.617 2.617 0 0 0 11.133 5.5Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default CircleHelpIcon;
