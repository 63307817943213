import React from 'react';
import styled from 'styled-components';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import DiamondIcon from 'app/src/images/DiamondIcon';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { TextSubtitle } from 'shared/react/components/complex/Text';
import SuccessButton from 'shared/react/components/basic/button/button/SuccessButton';
import ArrowTopRightWithLineIcon from 'app/src/images/ArrowTopRightWithLineIcon';
import ProIcon from 'app/src/images/ProIcon';
import { navigateToBilling } from 'app/src/utils/navigation.utils';
import { useHistory } from 'react-router-dom';
import Utils from 'app/src/utils';
import { track } from 'app/src/helpers/Tracker';

const BlockFreeUserPublishMessage = ({ type, ...props }) => {
  const history = useHistory();

  const onFreeTrial = () => {
    const analyticsData = Utils.getAnalyticsLocationObject(`Publish Modal ${type}`);
    track('Upgrade Button Clicked', analyticsData);
    navigateToBilling(history);
  };

  return (
    <LayoutRoot {...props}>
      <ContentContainer>
        <DiamondIcon />
        <TextContainer>
          <HeaderContainer>
            <Header>
              <ProIcon />
              {type}
            </Header>
            <SubHeader>This is a premium feature, click below to unlock it</SubHeader>
          </HeaderContainer>
          <StyledSuccessButton onClick={onFreeTrial}>
            Start free trial
            <ArrowTopRightWithLineIcon />
          </StyledSuccessButton>
        </TextContainer>
      </ContentContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  background: ${({ theme }) => theme.colors.primaryLight};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 8px;
  padding: 24px;
`;

const ContentContainer = styled(HorizontalFlex)`
  gap: 24px;
`;

const TextContainer = styled(VerticalFlex)`
  justify-content: space-between;
`;

const HeaderContainer = styled(Gap8VerticalFlex)``;

const Header = styled(TextSubtitle)`
  font-width: 800;
  display: flex;
  gap: 8px;
  align-items: center;
  color: ${({ theme }) => theme.colors.primaryDark};

  & path {
    fill: ${({ theme }) => theme.colors.black};
  }
`;

const SubHeader = styled(TextSubtitle)`
  font-width: 600;
  color: ${({ theme }) => theme.colors.primaryDark};
`;

const StyledSuccessButton = styled(SuccessButton)`
  gap: 8px;
  align-items: center;
  padding: 12px 24px;
`;

export default BlockFreeUserPublishMessage;
