import { createContainer, createHook, createStore } from 'react-sweet-state';
import { getRequest } from 'app/src/helpers/API';

const initialState = {
  properties: {},
};

const actions = {
  fetchHubspotUserProperties:
    () =>
    async ({ setState }, { appKey }) => {
      const properties = await getRequest('hubspot', `/hubspot/properties/${appKey}`);
      setState({ properties });
    },
  clearStore:
    () =>
    ({ setState }) => {
      setState({ ...initialState });
    },
};

const HubspotStore = createStore({ initialState, actions });

export const useHubspot = createHook(HubspotStore);

export const HubspotContainer = createContainer(HubspotStore);
