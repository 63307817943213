import React, { useEffect, useRef, useState } from 'react';
import { useApps } from 'app/src/context/AppsStore';
import { useProducts } from 'app/src/context/ProductsStore';
import { track } from 'app/src/helpers/Tracker';
import { safeScrollIntoViewIfNeeded } from 'app/src/utils/browser.utils';
import SearchProductsDisplay from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/products/SearchProductsDisplay';

const ReviewShoppablePart = ({ project, step, selectedStore, alreadyReviewed }) => {
  const [products, setProducts] = useState([]);
  const [, { createProducts }] = useProducts();
  const ref = useRef();
  const [, { getAppUsingUrl }] = useApps();

  const updateProducts = products => {
    step.products = products;
    setProducts(products);
  };

  const getSelectedApp = () => {
    return getAppUsingUrl(selectedStore.id);
  };

  const handleRemoveProduct = product => {
    const { id, productId } = product;
    track('Products Section Product Removed', {
      id,
      productId,
    });

    setProducts(products.filter(({ productId }) => productId !== product.productId));
  };

  const handleSearchProductSelect = async ({ productId }) => {
    track('Products Section Product Added', {
      productId,
    });

    if (products.find(product => product.productId === productId) || !selectedStore) {
      return;
    }

    const [createdProduct] = await createProducts([productId], selectedStore.id);

    updateProducts([...products, createdProduct]);
    setTimeout(() => {
      safeScrollIntoViewIfNeeded(ref.current);
    }, 0);
  };

  useEffect(() => {
    if (alreadyReviewed) {
      setProducts(step.products);
    } else {
      step.productsAppUrl = selectedStore.id;
      setProducts([]);
      step.products = [];
    }
  }, [step]);

  return (
    <SearchProductsDisplay
      isLoadingProducts={false}
      showSearchContainer
      products={products}
      getSelectedApp={getSelectedApp}
      handleSearchProductSelect={handleSearchProductSelect}
      handleHasNoPermissions={() => {}}
      handleRemoveProduct={handleRemoveProduct}
      project={project}
      scrollAnchorRef={ref}
      setProductCtaType={() => {}}
    />
  );
};

export default ReviewShoppablePart;
