import React from 'react';
import AnalyticsConversionEmptyState from '../empty/AnalyticsConversionEmptyState';
import AnalyticsOrdersEmptyStateBanner from './AnalyticsOrdersEmptyStateBanner';
import AnalyticsConversionOrdersTable from './AnalyticsConversionOrdersTable';

const AnalyticsOrders = ({ shouldBlur }) => {
  if (shouldBlur) {
    return <AnalyticsConversionEmptyState />;
  }

  return (
    <>
      <AnalyticsOrdersEmptyStateBanner />
      <AnalyticsConversionOrdersTable />
    </>
  );
};

export default AnalyticsOrders;
