import {
  INSTAGRAM,
  INSTAGRAM_GRAPH,
  SYNTHESIA,
  TIKTOK,
  GOOGLE_DRIVE,
  DROPBOX,
  YOUTUBE,
  TAVUS,
} from 'app/src/constants/intergrations.constants';
import { UGC, UGC_SEARCH, UGC_PENDING, UGC_APPROVED } from 'app/src/constants/navigation.constants';
import {
  API_URL,
  RECORDINGS,
  SCREEN_AND_CAMERA,
  SCREEN_RECORDING,
  TRIM,
  UPLOAD,
  VIDEO_RECORDING,
} from 'app/src/constants/recordingTypes.constants';
import {
  IMPORT_FROM_INSTAGRAM,
  IMPORT_FROM_TIKTOK,
  AI_VIDEO,
} from 'app/src/constants/creation_method.constants';
import { UGC_COLLECT } from 'src/pages/dashboard/pages/videos/constants/videos.constants';

const EXTENSION_SOURCES = {
  extensionScreenAndCamera: 'extension-screen&camera',
  extensionScreen: 'extension-screen',
  extensionUndefined: 'extension-undefined',
  extensionVideo: 'extension-video',
  extensionNull: 'extension-null',
  extension: 'extension',
};

const getDuplicateKey = (key: string) => `duplicate_${key}`;

const DUPLICATE_SOURCES = {
  duplicatedUpload: getDuplicateKey(UPLOAD),
  duplicatedVideo: getDuplicateKey(VIDEO_RECORDING),
  duplicatedTrim: getDuplicateKey(TRIM),
  duplicatedExtensionScreenAndCamera: getDuplicateKey(EXTENSION_SOURCES.extensionScreenAndCamera),
  duplicatedExtensionScreen: getDuplicateKey(EXTENSION_SOURCES.extensionScreen),
  duplicateUndefined: getDuplicateKey('undefined'),
  duplicateExtensionUndefined: getDuplicateKey(EXTENSION_SOURCES.extensionUndefined),
  duplicateImportFromInstagram: getDuplicateKey(IMPORT_FROM_INSTAGRAM),
  duplicateTiktok: getDuplicateKey(TIKTOK),
  duplicateInstagram: getDuplicateKey(INSTAGRAM),
  duplicateInstagramGraph: getDuplicateKey(INSTAGRAM_GRAPH),
  duplicateImportFromTiktok: getDuplicateKey(IMPORT_FROM_TIKTOK),
  duplicateAiVideo: getDuplicateKey(AI_VIDEO),
};

const VIDEO_SOURCES = {
  tiktok: TIKTOK,
  importFromTiktok: IMPORT_FROM_TIKTOK,
  instagramGraph: INSTAGRAM_GRAPH,
  instagram: INSTAGRAM,
  importFromInstagram: IMPORT_FROM_INSTAGRAM,
  googleDrive: GOOGLE_DRIVE,
  upload: UPLOAD,
  recordings: RECORDINGS,
  screenRecording: SCREEN_RECORDING,
  videoRecording: VIDEO_RECORDING,
  trim: TRIM,
  screen: SCREEN_RECORDING,
  screenAndCamera: SCREEN_AND_CAMERA,
  synthesia: SYNTHESIA,
  aiVideo: AI_VIDEO,
  apiUrl: API_URL,
  ...EXTENSION_SOURCES,
  ...DUPLICATE_SOURCES,
};

export const VIDEO_SOURCE_NAME_BY_KEY = {
  [VIDEO_SOURCES.tiktok]: 'TikTok',
  [VIDEO_SOURCES.importFromTiktok]: 'TikTok',
  [VIDEO_SOURCES.instagramGraph]: 'Instagram',
  [VIDEO_SOURCES.instagram]: 'Instagram',
  [VIDEO_SOURCES.importFromInstagram]: 'Instagram',
  [VIDEO_SOURCES.googleDrive]: 'Google Drive',
  [VIDEO_SOURCES.upload]: 'Upload',
  [VIDEO_SOURCES.recordings]: 'Recording',
  [VIDEO_SOURCES.screenRecording]: 'Screen Recording',
  [VIDEO_SOURCES.videoRecording]: 'Video Recording',
  [VIDEO_SOURCES.trim]: 'Trimmed Video',
  [VIDEO_SOURCES.extensionScreenAndCamera]: 'Extension Screen & Camera',
  [VIDEO_SOURCES.extensionScreen]: 'Extension Screen Recording',
  [VIDEO_SOURCES.extensionUndefined]: 'Extension Video',
  [VIDEO_SOURCES.screen]: 'Screen Recording',
  [VIDEO_SOURCES.extensionVideo]: 'Extension Video',
  [VIDEO_SOURCES.screenAndCamera]: 'Screen & Camera',
  [VIDEO_SOURCES.extensionNull]: 'Extension Video',
  [VIDEO_SOURCES.extension]: 'Extension Video',
  [VIDEO_SOURCES.synthesia]: 'Synthesia',
  [VIDEO_SOURCES.apiUrl]: 'API',
  [VIDEO_SOURCES.aiVideo]: 'AI Video',
  [VIDEO_SOURCES.duplicatedUpload]: 'Upload',
  [VIDEO_SOURCES.duplicatedVideo]: 'Video Recording',
  [VIDEO_SOURCES.duplicatedTrim]: 'Trimmed Video',
  [VIDEO_SOURCES.duplicatedExtensionScreenAndCamera]: 'Extension Screen & Camera',
  [VIDEO_SOURCES.duplicatedExtensionScreen]: 'Extension Screen Recording',
  [VIDEO_SOURCES.duplicateUndefined]: 'Extension Video',
  [VIDEO_SOURCES.duplicateExtensionUndefined]: 'Extension Video',
  [VIDEO_SOURCES.duplicateImportFromInstagram]: 'Instagram',
  [VIDEO_SOURCES.duplicateTiktok]: 'TikTok',
  [VIDEO_SOURCES.duplicateInstagram]: 'Instagram',
  [VIDEO_SOURCES.duplicateInstagramGraph]: 'Instagram',
  [VIDEO_SOURCES.duplicateImportFromTiktok]: 'TikTok',
};

export const RECORDINGS_SOURCES = [SCREEN_RECORDING, VIDEO_RECORDING, SCREEN_AND_CAMERA];
const extensionSource = RECORDINGS_SOURCES.map(source => `extension-${source}`);
const TIKTOK_WITH_CAPITAL_T = 'tikTok';
const aiSources = [`${AI_VIDEO}_${TAVUS}`, `${AI_VIDEO}_${SYNTHESIA}`];

export const SOURCES_UPLOAD_TYPE = {
  [UPLOAD]: [UPLOAD],
  [INSTAGRAM_GRAPH]: [INSTAGRAM_GRAPH, IMPORT_FROM_INSTAGRAM],
  [GOOGLE_DRIVE]: [GOOGLE_DRIVE],
  [TIKTOK]: [TIKTOK, IMPORT_FROM_TIKTOK, TIKTOK_WITH_CAPITAL_T],
  [RECORDINGS]: [...RECORDINGS_SOURCES, ...extensionSource],
  [SYNTHESIA]: [SYNTHESIA],
  [AI_VIDEO]: [AI_VIDEO, ...aiSources],
  [DROPBOX]: [DROPBOX],
  [YOUTUBE]: [YOUTUBE],
  [UGC]: [UGC],
  [UGC_SEARCH]: [UGC_SEARCH],
  [UGC_PENDING]: [UGC_PENDING],
  [UGC_APPROVED]: [UGC_APPROVED],
  [UGC_COLLECT]: [UGC_COLLECT],
};

export const SOURCES_UPLOAD_TYPE_LIST = Object.keys(SOURCES_UPLOAD_TYPE);

export const getDuplicateSource = source => `duplicate_${source}`;

export const FULL_SOURCES_UPLOAD_TYPE = Object.entries(SOURCES_UPLOAD_TYPE).reduce(
  (obj, [key, value]) => {
    obj[key] = value.flatMap(source => [source, getDuplicateSource(source)]);
    return obj;
  },
  {}
);
