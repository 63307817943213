import React from 'react';
import styled from 'styled-components';
import MediumTolstoyBracesIcon from 'app/src/images/dashboard_v2/MediumTolstoyBracesIcon';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';

const EmptyStateCard = () => {
  return (
    <LayoutRoot>
      <BracesContainer>
        <MediumTolstoyBracesIcon />
      </BracesContainer>
      <LinesContainer>
        <UpperLinerContainer>
          <Square />
          <Line />
        </UpperLinerContainer>
        <Line />
      </LinesContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  width: 100%;
  height: 100%;
`;

const BracesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px 12px 0 0;
  background: ${({ theme }) => theme.colors.gray3};
  width: 100%;
  height: 160px;
`;

const LinesContainer = styled(Gap16VerticalFlex)`
  padding: 35px 24px 24px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 0 0 12px 12px;
  width: 100%;
`;

const Square = styled.div`
  background: ${({ theme }) => theme.colors.gray3};
  width: 32px;
  height: 32px;
  border-radius: 8px;
  flex-shrink: 0;
`;

const Line = styled.div`
  background: ${({ theme }) => theme.colors.gray3};
  height: 16px;
  width: 100%;
  border-radius: 38px;
`;

const UpperLinerContainer = styled(Gap8HorizontalFlexWrap)`
  flex-wrap: nowrap;
  align-items: center;
`;

export default EmptyStateCard;
