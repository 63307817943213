import React from 'react';
import styled from 'styled-components';
import BasicInput from 'app/src/basic_components/BasicInput';

function FormInput({ children, ...props }) {
  return <LayoutRoot {...props}>{children}</LayoutRoot>;
}

export default FormInput;

const LayoutRoot = styled(BasicInput)`
  background: white;
  min-height: 40px;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray3};
  border-radius: 8px;
  width: 336px;
`;
