import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MenuItemInput from 'app/src/basic_components/MenuItemInput';
import WarningIcon from 'app/src/images/WarningIcon';
import { TextTiny } from 'shared/react/components/complex/Text';
import { useDebounce } from 'app/src/hooks/useDebounce';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { DEBOUNCE_TIME } from 'app/src/constants/builder.constants';

type Props = {
  value?: string;
  defaultValue?: string;
  inputPlaceHolder: string;
  error?: boolean;
  errorMessage?: string;
  onAnswerValueChange: (value: string) => void;
};

const MenuItemTextInput = ({
  value,
  defaultValue,
  inputPlaceHolder,
  error,
  errorMessage,
  onAnswerValueChange,
}: Props) => {
  const [inputValue, setInputValue] = useState(value || defaultValue || '');
  const debouncedInputValue = useDebounce(inputValue, DEBOUNCE_TIME);

  const onChange = e => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    setInputValue(value || defaultValue || '');
  }, [value, defaultValue]);

  useEffect(() => {
    onAnswerValueChange(debouncedInputValue);
  }, [debouncedInputValue]);

  return (
    <InputContainer>
      <MenuItemInput
        placeholder={error ? "Input can't be empty" : inputPlaceHolder}
        value={inputValue}
        onChange={onChange}
        error={error}
      />
      {error && (
        <ErrorContainer>
          <WarningIcon />
          <ErrorText>{errorMessage}</ErrorText>
        </ErrorContainer>
      )}
    </InputContainer>
  );
};

const InputContainer = styled(VerticalFlex)`
  padding: 0 20px 8px;
  gap: 2px;
`;

const ErrorContainer = styled(HorizontalFlex)`
  gap: 4px;
  align-items: center;
`;

const ErrorText = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.warningDark};
`;

export default MenuItemTextInput;
