import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { GridContainer } from 'app/src/pages/dashboard/components/page/Page';
import OnsiteCard from '../onsite_card/OnsiteCard';
import OnsiteDuplicateCard from 'app/src/pages/dashboard/pages/onsites/components/onsite_card/OnsiteDuplicateCard';
import OnSiteEmptyState from 'app/src/pages/dashboard/pages/onsites/components/onsite-empty-state/OnSiteEmptyState';
import { project } from 'app/src/types/entities';
import GridLoading from 'app/src/pages/dashboard/components/loading/GridLoading';

type Props = {
  items?: project[];
  selectedItems: string[];
  toggleSelected: (id: string) => void;
  fetchedProjects: boolean;
  isArchiveFolder?: boolean;
};

const OnsiteGrid = forwardRef<HTMLDivElement, Props>(
  ({ items, selectedItems, toggleSelected, fetchedProjects, isArchiveFolder }, ref) => {
    if (!fetchedProjects) {
      return <GridLoading />;
    }

    if (!items.length) {
      return <OnSiteEmptyState isArchiveFolder={isArchiveFolder} />;
    }

    return (
      <GridStyled ref={ref}>
        <OnsiteDuplicateCard />
        {items.map(item => (
          <OnsiteCard
            key={item.id}
            project={item}
            toggleSelected={toggleSelected}
            isSelected={selectedItems.includes(item.id)}
            isSelectionMode={!!selectedItems.length}
          />
        ))}
      </GridStyled>
    );
  }
);
OnsiteGrid.displayName = 'OnsiteGrid';

const GridStyled = styled(GridContainer)`
  grid-auto-rows: 266px;
`;

export default OnsiteGrid;
