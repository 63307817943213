import React, { useEffect, useRef } from 'react';
import { PRODUCT_SEARCH_INPUT_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import styled from 'styled-components';
import InputWithIcon from 'app/src/basic_components/input/InputWithIcon';
import LinkIcon from 'shared/react/images/share/LinkIcon';
import { spinnerCss } from 'shared/react/components/basic/button-v2/BasicButton';
import SearchIcon from '@material-ui/icons/Search';

const ProductSearchInput = ({
  onSelectValueChange,
  onInputValueChange,
  isMenuOpen,
  value,
  isLoading,
  fetchProducts,
  selectedApp,
  iconSrc,
  productsInputPlaceholder = 'Search for a product',
  autoFocus,
}) => {
  const ref = useRef();

  useEffect(() => {
    if (!ref.current || !autoFocus) {
      return;
    }

    setTimeout(() => {
      ref.current.focus();
    }, 100);
  }, [ref]);
  const getIcon = () => {
    if (iconSrc) {
      return <Image src={iconSrc} />;
    }

    return <SearchIcon />;
  };

  if (!selectedApp) {
    return (
      <InputWithIcon
        value={value}
        onChange={onInputValueChange}
        placeholder="Paste Product URL here"
        isLoading={isLoading}
        ref={ref}
        icon={<LinkIcon />}
      />
    );
  }

  return (
    <StyledSearchBar
      open={isMenuOpen}
      placeholder={productsInputPlaceholder}
      value={value}
      onClick={() => fetchProducts(value)}
      onChange={onSelectValueChange}
      isLoading={isLoading}
      data-test-id={PRODUCT_SEARCH_INPUT_TEST_ID}
      icon={getIcon()}
      iconSrc={!!iconSrc}
      ref={ref}
    />
  );
};

const StyledSearchBar = styled(InputWithIcon)`
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  border-radius: ${({ open }) => (open ? '4px 4px 0 0' : '4px')};
  height: 32px;

  & input {
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.neutralLight};
    padding: ${({ iconSrc }) => (iconSrc ? '10px 10px 10px 40px' : '10px 10px 10px  30px')};
  }

  & input::placeholder {
    font-weight: 400;
  }

  &&:after {
    left: unset;
    right: 16px;
  }
  ${spinnerCss}
`;

const Image = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
`;

export default ProductSearchInput;
