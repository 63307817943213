import React from 'react';
import styled from 'styled-components';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import ResetToDefaultInputWrapper from 'app/src/complex_components/ResetToDefaultInputWrapper';
import EditorColorInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorColorInputItem';
import EditorNumericInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorNumericInputItem';
import EditorSliderItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorSliderItem';
import EditorToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorToggleItem';
import EditorSubheader from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorSubheader';
import Utils from 'app/src/utils';

const MIN_BORDER_RADIUS = 0;
const MAX_BORDER_RADIUS = 25;

type BorderSettings = {
  enabled: boolean;
  color?: string;
  thickness?: string | number;
  radius?: number;
};
type Settings = {
  backgroundColor: string;
  textColor: string;
  border: BorderSettings;
};

type Props = {
  title?: string;
  settings: Settings;
  defaultSettings: Settings;
  disabled?: boolean;
  onChange?: any;
};

const ButtonDesignEditor = ({
  title,
  settings,
  defaultSettings,
  disabled = false,
  onChange = () => {},
}: Props) => {
  const {
    border: { radius: borderRadius },
  } = settings;
  const hasRadius = !Utils.isNullOrUndefined(borderRadius);
  const updateSettings = (update: Partial<Settings>) => onChange({ ...settings, ...update });
  const updateBorder = (update: Partial<BorderSettings>) => {
    const border = { ...settings.border, ...update };

    updateSettings({ border });
  };

  const handleBorderRadiusChange = value => {
    const radius = Math.min(Math.max(value, MIN_BORDER_RADIUS), MAX_BORDER_RADIUS);

    updateBorder({ radius });
  };

  // TODO do we need track by property here?
  // const handleResetToDefault = (update: Partial<Settings>) => {
  // track('Branding Reset To Default', { property: type });
  // updateSettings(update);
  // };

  return (
    <LayoutRoot>
      {title && <EditorSubheader title={title} />}
      {hasRadius && (
        <ResetToDefaultInputWrapper
          disabled={disabled}
          onClick={() => updateBorder({ radius: defaultSettings.border.radius })}
        >
          <EditorNumericInputItem
            text="Corners radius"
            suffix="px"
            onChange={handleBorderRadiusChange}
            value={borderRadius}
            disabled={disabled}
          />
        </ResetToDefaultInputWrapper>
      )}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)``;

export default ButtonDesignEditor;
