export const FEATURE_APP_LIMITS = 'app-limits';
export const FEATURE_QUIZ_LIMITS = 'quiz-limits';
export const FEATURE_PRODUCT_RECOMMENDATIONS = 'app-product-recommendation';
export const FEATURE_MACRO_REPLY = 'macro-reply';
export const FEATURE_SHOW_ORIGINAL_VIDEO = 'app-show-original-video';
export const FEATURE_TEAM_ACCOUNTS = 'team-accounts';
export const FEATURE_TEAM_MEMBER_NOTIFICATION_SETTINGS = 'team-member-notification-settings';
export const FEATURE_CONTACTS_THREAD = 'contacts-thread';
export const FEATURE_CONTACTS_FILTERS = 'contacts-filters';
export const FEATURE_CONTACTS_REFRESH = 'contacts-refresh';
export const FEATURE_PRIVATE_RESOURCES = 'private-resources';
export const FEATURE_CREATE_WORKSPACE = 'create-workspace';
export const FEATURE_OWNER_TEAM_MEMBERS_PRIVATE_RESOURCES = 'owner-team-members-private-resources';
export const FEATURE_ACCOUNT_ANALYTICS = 'account-analytics';
export const FEATURE_APP_PRICING = 'app-pricing';
export const FEATURE_IN_APP_NOTIFICATIONS = 'in-app-notifications';
export const FEATURE_LIMIT_MAX_RECORDING_TIME = 'limit-max-recording-time';
export const FEATURE_LIMIT_RESPONSES = 'limit-responses';
export const FEATURE_LIMIT_ANALYTICS = 'limit-analytics';
export const FEATURE_LIMIT_IN_APP_NOTIFICATIONS = 'limit-in-app-notifications';
export const FEATURE_WIDGET_SET_LIVE = 'widget-set-live';
export const FEATURE_TIKTOK_AUTH_ENABLED = 'enable-tiktok-auth';
export const FEATURE_EDIT_GIF_THUMBNAIL = 'edit-gif-thumbnail';
export const FEATURE_DASHBOARD_V2 = 'dashboard-v2';
export const FEATURE_BILLING_ENABLED = 'billing-enabled';
export const FEATURE_ANALYTICS_CALENDAR = 'account-analytics-calendar';
export const FEATURE_DASHBOARD_V2_NEW_FOLDERS = 'dashboard-v2-new-folders';
export const FEATURE_WIDGET_DELAY_TRIGGER = 'widget-delay-trigger';
export const FEATURE_WIDGET_AUTOPLAY = 'widget-autoplay';
export const FEATURE_ECOM_FEED = 'ecom-feed';
export const FEATURE_PRICING_DOWNGRADE = 'pricing-downgrade';
export const FEATURE_PRICING_ADDONS = 'pricing-addons';
export const FEATURE_QUIZ_V2 = 'quiz-v2';
export const FEATURE_REMOVE_FOLDERS_DESIGN = 'remove-folders-design';
export const FEATURE_WIDGET_CAROUSEL_SETTINGS = 'widget-carousel-settings';
export const FEATURE_WIDGET_STORIES_SETTINGS = 'widget-stories-settings';
export const FEATURE_INTERACTION_DATE_VERIFICATION = 'tolstoy-interaction-date-verification';
export const FEATURE_QUIZ_PRODUCTS_RESULT = 'quiz-products-result';
export const FEATURE_EMBED_SET_LIVE = 'embed-set-live';
export const FEATURE_KEEP_CAPTIONS_CHANGED_VIDEO = 'keep-captions-changed-video';
export const FEATURE_PLAYER_BUTTONS = 'player-buttons';
export const FEATURE_EXPORT_ANALYTICS = 'export-analytics';
export const FEATURE_MAGENTO_INTEGRATION = 'magento-integration';
export const FEATURE_INSTAGRAM_INTEGRATION = 'instagram-integration';
export const FEATURE_CUSTOM_CREATION_METHODS = 'custom-creation-methods';
export const FEATURE_FEED_HIDE_SHARE = 'feed-hide-share';
export const FEATURE_FEED_HIDE_CHECKOUT_BUTTON = 'feed-hide-checkout-button';
export const FEATURE_PLAYER_RESOLUTION = 'player-resolution';
export const FEATURE_FEED_USE_MUX = 'feed-use-mux';
export const FEATURE_WIDGET_HEADER_CUSTOMIZATION = 'widget-header-customization';
export const FEATURE_WIDGET_EVENTS = 'widget-events';
export const FEATURE_FETCH_BY_URL = 'fetch-products-by-url';
export const FEATURE_CUSTOM_DOMAIN = 'custom-domain';
export const FEATURE_FEED_HIDE_BUY_NOW_BUTTON = 'feed-hide-buy-now-button';
export const FEATURE_SEMI_AUTO_IMPORT = 'semi-auto-import';
export const FEATURE_CAROUSEL_PLAY_IN_TILE_FIRST = 'carousel-play-in-tile-first';
export const FEATURE_ANALYTICS_OVERVIEW = 'analytics-overview';
export const FEATURE_DYNAMIC_FEED = 'dynamic-feed';
export const FEATURE_TOLSTOY_TV = 'tolstoy-tv';
export const FEATURE_TOLSTOY_V2 = 'tolstoy-v2';
export const FEATURE_FEED_OPEN_PDP_IN_SAME_TAB = 'feed-open-pdp-in-same-tab';
export const FEATURE_FEED_OPEN_DESCRIPTION_CTA_IN_SAME_TAB =
  'feed-open-description-cta-in-same-tab';
export const FEATURE_PLAYER_HIDE_END_SCREEN = 'player-hide-end-screen';
export const FEATURE_RECHARGE_INTEGRATION = 'recharge-integration';
export const FEATURE_LIMIT_MIN_RECORDING_TIME = 'limit-min-recording-time';
export const FEATURE_CUSTOMER_LOGIN = 'tolstoy-customer-login';
export const FEATURE_PRODUCTS_SEARCH_LIMIT = 'products-search-limit';
export const FEATURE_HERO_PROJECT = 'hero-project';
export const FEATURE_SHOPIFY_TAGS = 'shopify-tags';
export const FEATURE_SHOP_APP = 'shop-app';
export const FEATURE_PUBLISH_V2 = 'publish-v2';
export const FEATURE_ACCOUNT_INSTALLATION_TAB = 'account-installation-tab';
export const FEATURE_VIDEO_LABELS = 'video-labels';
export const FEATURE_CUSTOM_SIDE_BAR = 'custom-side-bar';
export const FEATURE_INSTAGRAM_IMPORT_PRODUCTS = 'instagram-import-products';
export const FEATURE_GOOGLE_DRIVE_INTEGRATION = 'google-drive-integration';
export const FEATURE_YOUTUBE_INTEGRATION = 'youtube-integration';
export const FEATURE_ACCOUNT_TRACKING = 'account-tracking';
export const FEATURE_STORIES_CAROUSEL_INSTALLATION_V2 = 'stories-carousel-installation-v2';
// FEATURE_DESCRIPTION_CLOSE_FEED_PDP should be threated as 'should open pdp on feed product click'
export const FEATURE_DESCRIPTION_CLOSE_FEED_PDP = 'description-close-feed-pdp';
export const FEATURE_DYNAMIC_PDP_TABLE = 'dynamic-pdp-table';
export const FEATURE_FEED_DYNAMIC_NEW_DESIGN = 'feed-dynamic-new-design';
export const FEATURE_APP_HOMEPAGE = 'app-homepage';
export const FEATURE_TV_CHANNEL = 'tv-channel';
export const FEATURE_PLAYER_FEED_CUSTOM_STYLE = 'player-feed-custom-style';
export const FEATURE_DYNAMIC_HIDE_VOD_ASSET = 'dynamic-hide-vod-asset';
export const FEATURE_ORDER_FEED_VIDEOS = 'order-feed-videos';
export const FEATURE_SHOW_VIDEO_NAME = 'show-video-name';
export const FEATURE_FEED_PRODUCT_VARIANT_PICKER = 'feed-product-variant-picker';
export const FEATURE_WIDGET_SPOTLIGHT_CAROUSEL = 'widget-spotlight-carousel';
export const FEATURE_FEED_REPORT_BUTTON = 'feed-report-button';
export const FEATURE_APP_PRODUCTS_TAB = 'app-products-tab';
export const FEATURE_AI_SUGGESTIONS = 'ai-suggestions';
export const FEATURE_DEFAULT_CAROUSEL_TYPE = 'default-carousel-type';
export const FEATURE_FEED_VIDEO_SEARCH = 'feed-video-search';
export const FEATURE_DESIGN_ACCOUNT_SETTINGS = 'design-account-settings';
export const FEATURE_WHITE_LABEL = 'white-label';
export const FEATURE_TOLY_CHATBOT = 'toly-chatbot';
export const FEATURE_AI_VIDEOS = 'ai-videos';
export const FEATURE_APP_LOAD_CONNECTIONS_WITH_PAGINATION = 'app-load-connections-with-pagination';
export const FEATURE_APP_USE_CREATOR_PROFILE = 'app-use-creator-profile';
export const FEATURE_PLAYER_FEED_MOBILE_PRICE_TOP_LOCATION =
  'player-feed-mobile-price-top-location';
export const FEATURE_SHOW_PRODUCT_HANDLE = 'show-product-handle';
export const FEATURE_SUGGESTED_PRODUCTS = 'suggested-products';
export const FEAUTRE_PACKAGE_BRANDING = 'package-branding';
export const FEATURE_UGC_IMPORT = 'ugc_import';
