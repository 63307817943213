import React from 'react';
import { memoize, omit } from 'lodash';

import {
  ComparatorEnum,
  ComparisonOperator,
  WhereCondition,
} from 'app/src/pages/dashboard/utils/filterEntities';
import { STATUS_PENDING } from 'app/src/constants/teamMembers.constants';
import { TeamMemberImage } from './components/filter-block/FilterBlock';
import Utils from 'src/utils';
import UserIcon from 'src/images/dashboard_v2/UserIcon';
import TaggedIcon from 'app/src/images/filters/TaggedIcon';
import StatusIcon from 'app/src/images/filters/StatusIcon';
import ShopifySmallIcon from 'app/src/images/integrations/ShopifySmallIcon';
import PlugIcon from 'app/src/images/PlugIcon';
import {
  SOURCE_NAMES,
  SOURCES_ICONS,
  VIDEO_SOURCES,
} from 'app/src/pages/dashboard/pages/videos/constants/videos.constants';
import styled from 'styled-components';
import ProductsSearch from 'app/src/pages/dashboard/components/filters/components/products-search/ProductsSearch';
import { VodConnectionType } from 'app/src/types/entities';
import {
  GOOGLE_DRIVE,
  MULTI_ACCOUNTS_INTEGRATIONS_SOURCES,
} from 'app/src/constants/intergrations.constants';
import { UGC } from 'app/src/constants/navigation.constants';
import {
  FEATURE_GOOGLE_DRIVE_INTEGRATION,
  FEATURE_SHOPIFY_TAGS,
} from 'shared/react/constants/features.constants';
import LightningIcon from 'src/images/dashboard_v2/LightningIcon';
import HammerIcon from 'src/images/dashboard_v2/HammerIcon';
import TypeIcon from 'app/src/images/filters/TypeIcon';
import { getIsDynamic } from 'app/src/context/ProjectsStore';
import MultiTagIcon from 'app/src/images/MultiTagIcon';
import ShoppingBagIcon from 'src/images/sidebar-v2/ShoppingBagIcon';
import DateFilter from 'app/src/pages/dashboard/components/filters/components/date-filter/DateFilter';
import CreatedAtIcon from 'app/src/images/sidebar-v2/CreatedAtIcon';
import { ASSET_TYPE } from 'shared/react/services/assets';

export type FilterOption = {
  id: string;
  name?: string;
  icon?: JSX.Element;
  imageSrc?: string;
  Comp?: JSX.Element;
  onClick?: (actions, entity) => void;
};

export enum FilterKeys {
  name = 'name',
  hasSuggestions = 'hasSuggestions',
  uploadType = 'uploadType',
  owner = 'owner',
  layout = 'layout',
  tagged = 'tagged',
  labels = 'labels',
  tags = 'tags',
  source = 'source',
  products = 'products',
  live = 'live',
  videoLive = 'videoLive',
  dynamic = 'dynamic',
  appExternalId = 'appExternalId',
  date = 'date',
  trending = 'trending',
  type = 'type',
}

export type FilterConfig<T = any> = {
  name: string;
  icon: () => JSX.Element;
  getData?: (actions: any) => FilterOption[];
  customSearch?: (actions: any) => JSX.Element;
  hasSearch?: boolean;
  onClick?: (actions: any) => void;
  onOptionClick?: (actions) => void;
  minHeight?: number;
  getCurrentFilterValueName?: (actions, entity) => string;

  /**
   * comparators are the operators that can be used to compare the value of the filter with the value of the entity.
   * ['in', 'notIn']
   */
  comparators?: (keyof ComparisonOperator)[];
  /**
   * filterSuffix is the word that will be added to the selected filter when multiple options are selected.
   * For example, "filterSuffix: 'users'" will result in "Creator is any of 2 **users**" when 2 users are selected.
   */
  filterSuffix?: string;
  /**
   * N/A
   */
  satisfyFn?: (entity: T, args: any) => boolean;
};

const getTeamMember = ({ userId, user }) => {
  const { logoSettings: logoSettingsString, firstName, lastName, email } = user;
  const logoSettings = Utils.safeParse(logoSettingsString || '{}');
  const userLogo = Utils.getLogoUrl(logoSettings.logo);
  const name = Utils.getUserName(firstName, lastName, email);

  return {
    id: userId,
    name,
    icon: (
      <TeamMemberImage
        userImageSrc={userLogo}
        name={name}
        backgroundColor={userLogo ? 'transparent' : logoSettings.color}
        scale="24px"
      />
    ),
  };
};

const getTeamMembersList = memoize(teamMembers => {
  return teamMembers
    ?.filter(member => member.status !== STATUS_PENDING)
    .map(getTeamMember)
    .sort((a, b) => a.name.localeCompare(b.name));
});

const filtersConfig: { [key: string]: FilterConfig } = {
  [FilterKeys.owner]: {
    name: 'Creator',
    icon: UserIcon,
    comparators: [ComparatorEnum.in, ComparatorEnum.notIn],
    filterSuffix: 'users',
    hasSearch: true,
    getData: ({ getTeamMembers, inputValue }) => {
      const teamMembers = getTeamMembers();
      const list = getTeamMembersList(teamMembers);
      if (!inputValue) {
        return list;
      }

      return list.filter(member => member.name.toLowerCase().includes(inputValue.toLowerCase()));
    },
  },

  [FilterKeys.tagged]: {
    name: 'Tagged',
    icon: TaggedIcon,
    filterSuffix: 'tagged',
    comparators: [ComparatorEnum.satisfies, ComparatorEnum.notSatisfies],
    onOptionClick: ({ setFilters, getHasVodConnectionsTypesByVodAssetId, value }) => {
      setFilters({
        tagged: {
          satisfies: { fn: getHasVodConnectionsTypesByVodAssetId, args: value },
        },
      });
    },
    getData: ({ hasProducts, getFeatureEnabled }) => {
      const options = [];

      if (hasProducts) {
        options.push({ id: VodConnectionType.productId, name: 'Product' });
      }

      if (getFeatureEnabled(FEATURE_SHOPIFY_TAGS)) {
        options.push({ id: VodConnectionType.tag, name: 'Shopify tags' });
      }

      return [...options, { id: VodConnectionType.vodLabelId, name: 'Custom Playlists' }];
    },
  },

  [FilterKeys.videoLive]: {
    name: 'Live',
    icon: StatusIcon,
    filterSuffix: 'videoLive',
    comparators: [ComparatorEnum.satisfies],
    onOptionClick: ({ setFilters, value, isVideoLive }) => {
      const currentValue = value[value.length - 1];
      const parsedValue = Utils.safeParse(currentValue);
      const comparator = parsedValue ? ComparatorEnum.satisfies : ComparatorEnum.notSatisfies;
      setFilters({
        videoLive: {
          [comparator]: { fn: isVideoLive, args: [currentValue] },
        },
      });
    },
    getData: () => {
      return [
        { id: 'true', name: 'Live', icon: <StatusCircle active={true} /> },
        { id: 'false', name: 'Not live', icon: <StatusCircle /> },
      ];
    },
  },

  [FilterKeys.labels]: {
    name: 'Custom Playlists',
    icon: MultiTagIcon,
    comparators: [ComparatorEnum.satisfies, ComparatorEnum.notSatisfies],
    filterSuffix: 'labels',
    hasSearch: true,
    getCurrentFilterValueName: ({ value, getVodLabelById }) => {
      return getVodLabelById(value)?.name;
    },
    onOptionClick: ({ value, setFilters, getHasVodLabelByVodAssetId, comparator }) => {
      setFilters({
        labels: {
          [comparator]: {
            fn: getHasVodLabelByVodAssetId,
            args: value,
          },
        },
      });
    },
    getData: ({ vodLabels, inputValue = '' }) => {
      return vodLabels.filter(member =>
        member.name.toLowerCase().includes(inputValue?.toLowerCase())
      );
    },
  },

  [FilterKeys.tags]: {
    name: 'Shopify tags',
    icon: ShopifySmallIcon,
    comparators: [ComparatorEnum.satisfies, ComparatorEnum.notSatisfies],
    filterSuffix: 'tags',
    hasSearch: true,
    getFilterValueString: value => {
      const name = value.includes(ASSET_TYPE.VIDEO) ? 'Video' : 'Image';
      return name;
    },
    getCurrentFilterValueName: ({ value }) => {
      return value;
    },
    onOptionClick: ({ value, setFilters, getHasTagsByVodAssetId, comparator }) => {
      setFilters({
        tags: {
          [comparator]: {
            fn: getHasTagsByVodAssetId,
            args: value,
          },
        },
      });
    },
    getData: ({ tags, inputValue = '' }) => {
      return tags
        .filter(tag => tag.toLowerCase().includes(inputValue?.toLowerCase()))
        .map(name => {
          return {
            id: name,
            name,
          };
        });
    },
  },

  [FilterKeys.source]: {
    name: 'Source',
    icon: PlugIcon,
    filterSuffix: 'source',
    comparators: [ComparatorEnum.satisfies, ComparatorEnum.notSatisfies],
    onOptionClick: ({ value, setFilters, comparator, filterVideoBySource }) => {
      setFilters({
        source: {
          [comparator]: { fn: filterVideoBySource, args: value },
        },
      });
    },
    getData: ({ getFeatureEnabled }) => {
      return VIDEO_SOURCES.flatMap(source => {
        if (
          (source === GOOGLE_DRIVE && !getFeatureEnabled(FEATURE_GOOGLE_DRIVE_INTEGRATION)) ||
          source === UGC
        ) {
          return [];
        }

        const Icon = SOURCES_ICONS[source];

        return {
          id: source,
          name: SOURCE_NAMES[source],
          icon: (
            <IconWrapper>
              <Icon />
            </IconWrapper>
          ),
        };
      });
    },
  },

  [FilterKeys.appExternalId]: {
    name: 'External account',
    icon: PlugIcon,
    filterSuffix: 'external',
    comparators: [ComparatorEnum.in],
    onOptionClick: ({ value, setFilters }) => {
      setFilters({
        appExternalId: { in: value },
      });
    },
    getData: ({ apps }) => {
      return apps.flatMap(app => {
        if (!MULTI_ACCOUNTS_INTEGRATIONS_SOURCES.includes(app.app)) {
          return [];
        }

        const Icon = SOURCES_ICONS[app.app];
        const data = JSON.parse(app.data || 'null');
        const username = data?.name || data?.userName;

        return {
          id: app.appExternalId,
          name: username,
          icon: (
            <IconWrapper>
              <Icon />
            </IconWrapper>
          ),
        };
      });
    },
  },

  [FilterKeys.products]: {
    name: 'Products',
    icon: ShoppingBagIcon,
    filterSuffix: 'products',
    minHeight: 206,
    customSearch: props => {
      return <ProductsSearch {...props} />;
    },
    onOptionClick: ({
      value,
      setFilters,
      comparator,
      getHasProductByVodAssetId,
      searchProducts,
      setSelectedProducts,
      selectedProducts,
    }) => {
      const newSelectedProducts = value.map(id => {
        return [...selectedProducts, ...searchProducts].find(product => product.productId === id);
      });

      setSelectedProducts(newSelectedProducts);

      setFilters({
        products: {
          [comparator]: { fn: getHasProductByVodAssetId, args: value },
        },
      });
    },
    comparators: [ComparatorEnum.satisfies, ComparatorEnum.notSatisfies],
    getData: ({ searchProducts, selectedProducts }) => {
      const newProducts = searchProducts.filter(({ productId }) => {
        return !selectedProducts.find(
          ({ productId: searchProductId }) => searchProductId === productId
        );
      });

      return [...selectedProducts, ...newProducts]?.map(({ productId, title, imageUrl }) => {
        return { id: productId, name: title, icon: <Image src={imageUrl} /> };
      });
    },
  },

  [FilterKeys.dynamic]: {
    name: 'Type',
    icon: TypeIcon,
    filterSuffix: 'dynamic',
    comparators: [ComparatorEnum.satisfies],
    onOptionClick: ({ setFilters, value }) => {
      const currentValue = value[value.length - 1];
      const parsedValue = Utils.safeParse(currentValue);
      const comparator = parsedValue ? ComparatorEnum.satisfies : ComparatorEnum.notSatisfies;
      setFilters({
        dynamic: {
          [comparator]: { fn: getIsDynamic, args: [currentValue] },
        },
      });
    },
    getData: () => {
      return [
        { id: 'true', name: 'Automated', icon: <LightningIcon /> },
        { id: 'false', name: 'Manual', icon: <HammerIcon /> },
      ];
    },
  },

  [FilterKeys.date]: {
    name: 'Created At',
    icon: CreatedAtIcon,
    filterSuffix: 'date',
    getFilterValueString: value => {
      return `${Utils.getFormattedDate(value[0])} - ${Utils.getFormattedDate(value[1])}`;
    },
    filterComparatorString: comparator => {
      return comparator === ComparatorEnum.satisfies ? 'is' : 'is not';
    },
    customSearch: props => {
      return <DateFilter {...props} />;
    },
    comparators: [ComparatorEnum.satisfies, ComparatorEnum.notSatisfies],
    getData: () => {
      return [];
    },
  },
};

const Image = styled.img`
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
  object-fit: cover;
  border-radius: 4px;
`;

const IconWrapper = styled.div`
  & path {
    fill: ${({ theme }) => theme.colors.neutralGray};
  }
`;

const StatusCircle = styled.div<{ active?: boolean }>`
  height: 16px;
  width: 16px;
  border-radius: 100%;
  background: ${({ theme, active }) => (active ? theme.colors.success : theme.colors.neutralGray)};
`;

export const hasAppliedFilters = (filters: WhereCondition): boolean => {
  const filtersToOmit = [FilterKeys.type, 'uploadType', 'folder'];
  if (filters?.name?.contains === '') {
    filtersToOmit.push(FilterKeys.name);
  }

  const appliedFilters = omit(filters, filtersToOmit);
  return Object.keys(appliedFilters).length > 0;
};

export const defaultFilterKeys = [FilterKeys.owner];

export default filtersConfig;
