import { PUBLISH_METHOD_OPTIONS, PUBLISH_METHODS } from 'shared/react/constants/tolstoy.constants';
import VideoPagePreview from 'app/src/images/create_new_content/publish_options_previews/videoPage.png';
import HeroPreview from 'app/src/images/create_new_content/publish_options_previews/hero.png';
import {
  FEATURE_TOLSTOY_TV,
  FEATURE_TOLSTOY_V2,
  FEATURE_HERO_PROJECT,
} from 'shared/react/constants/features.constants';
import {
  CREATE_NEW_EMBED_DATA_TEST_ID,
  CREATE_NEW_FEED_DATA_TEST_ID,
  CREATE_NEW_STORIES_DATA_TEST_ID,
  CREATE_NEW_TOLSTOY_DATA_TEST_ID,
  CREATE_NEW_WIDGET_DATA_TEST_ID,
} from 'app/src/constants/dataTestIds.constants';
import StoriesPreview from 'app/src/images/create_new_content/publish_options_previews/stories.png';
import CarouselPreview from 'app/src/images/create_new_content/publish_options_previews/carousel.png';
import WidgetPreview from 'app/src/images/create_new_content/publish_options_previews/widget.png';
import EmbedPreview from 'app/src/images/create_new_content/publish_options_previews/embed.png';
import MessagePreview from 'app/src/images/create_new_content/publish_options_previews/message.png';
import {
  FEED_ID,
  TOLSTOY_ID,
  TOLSTOY_TYPES_TEXT_MAP,
} from 'app/src/constants/CreateNewTolstoy.constants';
import InteractivePreview from 'app/src/images/create_new_content/tolstoy_types_options_previews/interactive.png';
import FeedPreview from 'app/src/images/create_new_content/tolstoy_types_options_previews/feed.png';
import TilePreview from 'app/src/images/create_new_content/publish_options_previews/tile.png';

export const PUBLISH_METHODS_ITEMS = [
  {
    id: PUBLISH_METHOD_OPTIONS.stories,
    dataTestId: CREATE_NEW_STORIES_DATA_TEST_ID,
    text: PUBLISH_METHODS[PUBLISH_METHOD_OPTIONS.stories].text,
    previewSrc: StoriesPreview,
    isHidden: ({ apps }) => apps.wix,
  },
  {
    id: PUBLISH_METHOD_OPTIONS.carousel,
    text: PUBLISH_METHODS[PUBLISH_METHOD_OPTIONS.carousel].text,
    previewSrc: CarouselPreview,
    isHidden: ({ apps }) => apps.wix,
  },
  {
    id: PUBLISH_METHOD_OPTIONS.tile,
    text: PUBLISH_METHODS[PUBLISH_METHOD_OPTIONS.tile].text,
    previewSrc: TilePreview,
    isHidden: ({ apps }) => apps.wix,
  },
  {
    id: PUBLISH_METHOD_OPTIONS.bubble,
    dataTestId: CREATE_NEW_WIDGET_DATA_TEST_ID,
    text: PUBLISH_METHODS[PUBLISH_METHOD_OPTIONS.bubble].text,
    previewSrc: WidgetPreview,
  },
  {
    id: PUBLISH_METHOD_OPTIONS.videoPage,
    text: PUBLISH_METHODS[PUBLISH_METHOD_OPTIONS.videoPage].text,
    previewSrc: VideoPagePreview,
    isHidden: ({ apps, getFeatureEnabled }) =>
      !getFeatureEnabled(FEATURE_TOLSTOY_V2) ||
      !apps.shopify ||
      apps.wix ||
      !getFeatureEnabled(FEATURE_TOLSTOY_TV),
  },
  {
    id: PUBLISH_METHOD_OPTIONS.hero,
    text: PUBLISH_METHODS[PUBLISH_METHOD_OPTIONS.hero].text,
    previewSrc: HeroPreview,
    isHidden: ({ getFeatureEnabled }) => !getFeatureEnabled(FEATURE_HERO_PROJECT),
  },
  {
    id: PUBLISH_METHOD_OPTIONS.embed,
    dataTestId: CREATE_NEW_EMBED_DATA_TEST_ID,
    text: PUBLISH_METHODS[PUBLISH_METHOD_OPTIONS.embed].text,
    previewSrc: EmbedPreview,
  },
  {
    id: PUBLISH_METHOD_OPTIONS.landingPage,
    text: PUBLISH_METHODS[PUBLISH_METHOD_OPTIONS.landingPage].text,
    previewSrc: MessagePreview,
    isHidden: ({ getFeatureEnabled }) => getFeatureEnabled(FEATURE_TOLSTOY_V2),
  },
];

export const TOLSTOY_TYPE_ITEMS = [
  {
    id: FEED_ID,
    dataTestId: CREATE_NEW_FEED_DATA_TEST_ID,
    text: TOLSTOY_TYPES_TEXT_MAP[FEED_ID],
    description: 'Connect multiple videos into a single feed',
    previewSrc: FeedPreview,
  },
  {
    id: TOLSTOY_ID,
    dataTestId: CREATE_NEW_TOLSTOY_DATA_TEST_ID,
    text: TOLSTOY_TYPES_TEXT_MAP[TOLSTOY_ID],
    description: 'Connect separate video parts into a workflow',
    previewSrc: InteractivePreview,
  },
  {
    id: PUBLISH_METHOD_OPTIONS.videoCollectionCampaign,
    text: PUBLISH_METHODS[PUBLISH_METHOD_OPTIONS.videoCollectionCampaign].text,
    description: 'Configure a video collection campaign',
  },
];
