import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';
import ModalWrapper from 'shared/react/components/basic/ModalWrapper';
import { useIsMobile } from 'shared/react/hooks/useIsMobile';
import useFeedModal from 'shared/react/components/complex/context/hooks/useFeedModal';
import useWishlist from 'shared/react/hooks/useWishlist';
import FeedTopTabs from '../feed_controls/FeedTopTabs';
import useFeedProducts from '../../context/hooks/useFeedProducts';
import { TOP_TABS } from '../../../../constants/feed.constants';
import FeedWishlistProductGrid from './FeedWishlistProductGrid';
import useOpenProductModal from '../../context/hooks/useOpenProductModal';
import Utils from '../../../../utils/utils';
import { TOGGLE_FEED_CLOSE_BUTTON_MESSAGE } from '../../../../constants/messages.constants';
import CustomerLoginHeader from '../../customer_login/CustomerLoginHeader';
import CustomerLoginBody from '../../customer_login/CustomerLoginBody';
import CustomerLoginFooter from '../../customer_login/CustomerLoginFooter';
import { CUSTOMER_LOGIN_STATE } from '../../../../constants/feature-constants/customerLogin.constants';
import useCustomerLogin from '../../context/hooks/useCustomerLogin';
import useProjectConfig from '../../context/hooks/useProjectConfig';
import useFeedState from '../../context/hooks/useFeedState';
import LoadingIndicator from '../../../basic/LoadingIndicator';
import ComponentWithOverlayLoader from '../../../basic/ComponentWithOverlayLoader';
import ComponentWithLoader from '../../../basic/ComponentWithLoader';

const FeedWishlist = () => {
  const [{ customerId }] = useProjectConfig();
  const [products] = useFeedProducts();
  const [customerWishlist, setCustomerWishlist] = useState();
  const [{}, { getCustomerWishlist }] = useWishlist();
  const [{ currentModal }, { popModal }] = useFeedModal();
  const { onClose, videoRef, currentStepKey } = currentModal.props;
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useIsMobile();
  const { player } = useDesignSettingsContext();
  const { headerColorText, bodyColorText } = player.feedCards.text;
  const { handleOpenProductModal } = useOpenProductModal({ step: {}, currentStepKey });
  const [{ customerLoginState }, { setCustomerLoginState }] = useCustomerLogin();
  const [{ isWishlistRequestInProcess }] = useFeedState();
  const handleProductClick = (e, product) => {
    handleOpenProductModal(e, {
      product,
      externalProductIds: [product.id],
      customProps: {
        customProduct: product,
      },
    });
  };

  const toggleFeedCloseButton = ({ hideCloseButton }) => {
    Utils.postMessageToWindow({
      eventName: TOGGLE_FEED_CLOSE_BUTTON_MESSAGE,
      hideCloseButton,
      hideOnFullScreen: true,
    });
  };

  const handleCloseModal = () => {
    onClose();
    popModal();
  };

  const shouldShowWishlist =
    customerLoginState === CUSTOMER_LOGIN_STATE.LOGGED_IN && products && customerWishlist;

  useEffect(() => {
    if (isWishlistRequestInProcess) {
      return;
    }
    const setWishlist = async () => {
      const wishlist = await getCustomerWishlist();
      setCustomerWishlist(wishlist);
    };
    toggleFeedCloseButton({ hideCloseButton: true });
    setWishlist();
  }, [isWishlistRequestInProcess]);

  useEffect(() => {
    if (customerId) {
      setCustomerLoginState(CUSTOMER_LOGIN_STATE.LOGGED_IN);
    }
  }, [customerId]);

  return (
    <StyledModalWrapper
      onClose={popModal}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      hideBackgroundOverlay={!isMobile}
      bodyColorText={bodyColorText}
      headerColorText={headerColorText}
      onClick={e => e.stopPropagation()}
    >
      <FeedTopTabs
        activeTabKey={TOP_TABS[1].key}
        toggleFeedCloseButton={toggleFeedCloseButton}
        videoRef={videoRef}
        onClose={onClose}
      />
      {shouldShowWishlist && !isWishlistRequestInProcess && (
        <FeedWishlistProductGrid
          products={products}
          customerWishlist={customerWishlist}
          onProductClick={handleProductClick}
        ></FeedWishlistProductGrid>
      )}

      {isWishlistRequestInProcess && (
        <LoadingIndicatorWrapper>
          <LoadingIndicator size={32} />
        </LoadingIndicatorWrapper>
      )}

      {customerLoginState !== CUSTOMER_LOGIN_STATE.LOGGED_IN && (
        <CustomerLoginWrapper>
          <CustomerLoginHeader handleCloseModal={handleCloseModal} />
          <CustomerLoginBody customerLoginState={customerLoginState} currentStep={currentStepKey} />
          <CustomerLoginFooter />
        </CustomerLoginWrapper>
      )}
    </StyledModalWrapper>
  );
};

const StyledModalWrapper = styled(ModalWrapper)`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 13;
  background-color: ${({ theme }) => theme.colors.white};
  max-width: 25vw;
  transition: 0.3s;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    max-width: unset;
  }
`;

const CustomerLoginWrapper = styled.div`
  padding: 16px;
`;
const LoadingIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 48px;
  height: 100%;
`;

export default FeedWishlist;
