import React, { useState } from 'react';
import styled from 'styled-components';
import Stepper from 'app/src/complex_components/Stepper';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import Utils from 'app/src/utils';
import { track } from 'app/src/helpers/Tracker';
import SnackBar from 'app/src/basic_components/SnackBar';
import CopyCodeButtonPricing from 'app/src/complex_components/pricing/CopyCodeButtonPricing';
import CodeContainerPricing from 'app/src/complex_components/pricing/CodeContainerPricing';
import { useBilling } from 'app/src/hooks/useBillings';
import SetLiveToggle from './feed_embed_widgets/SetLiveToggle';
import { PUBLISH_METHODS } from 'shared/react/constants/tolstoy.constants';
import usePublishFeature from './feed_embed_widgets/hooks/usePublishFeature';

function EmbedStepper({ project, getCode }) {
  const { embedSetLiveEnabled } = useBilling();
  const { shouldShowPublishToggle } = usePublishFeature(project);
  const [successBar, setSuccessBar] = useState(false);

  const handleCopyClick = () => {
    Utils.copyToClipboard(getCode());
    setSuccessBar(true);
    track('Copy Embed Code Click');
  };

  const disabledCopyCode = !project || !embedSetLiveEnabled;
  const widgetType = PUBLISH_METHODS[project?.publishMethod]?.widgetType;

  return (
    <>
      <Stepper
        stepComponents={[
          <Step key={1}>
            <FirstStepTop>
              Copy the code
              <CopyCodeContainer>
                <CopyCodeButtonPricing
                  disabled={disabledCopyCode}
                  onClick={handleCopyClick}
                  location="Embed"
                  tooltipHeader="embed"
                />
              </CopyCodeContainer>
            </FirstStepTop>
            <CustomCodeContainer>
              <CodeContainerPricing getCode={getCode} hide={disabledCopyCode} />
            </CustomCodeContainer>
          </Step>,
          <Step key={2}>Paste the code anywhere on your website</Step>,
          <Step key="setLiveToggle" disabled={!shouldShowPublishToggle}>
            <SetLiveToggle project={project} widgetType={widgetType} />,
          </Step>,
        ]}
      />
      <SnackBar
        text="The code copied to your clipboard!"
        open={successBar}
        setOpen={setSuccessBar}
        severity="success"
      />
    </>
  );
}

export default EmbedStepper;

const Step = styled.div`
  font-weight: 800;
  font-size: 16px;
`;

const FirstStepTop = styled(Gap8HorizontalFlexWrap)`
  padding-bottom: 8px;
`;

const CopyCodeContainer = styled.div`
  margin-top: -10px;
`;

const CustomCodeContainer = styled.div`
  & > * {
    width: auto;
  }
`;
