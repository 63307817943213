import { createActionsHook, createContainer, createHook, createStore } from 'react-sweet-state';
import { graphqlRequest } from '../helpers/API';
import { graphqlOperation } from 'aws-amplify';
import { vodLabelByAppKey } from '../graphql/queries';
import Utils from '../utils';
import { vodLabel } from 'app/src/types/entities';
import VodLabelsGraphqlService from 'src/services/vod-labels/VodLabelsGraphqlService';

type State = {
  vodLabels: vodLabel[];
  loading: boolean | null;
  initialized: boolean;
};

export enum SAVED_LABEL_NAME {
  taggedUGC = 'Tagged UGC',
  approvedUGC = 'Approved UGC',
}

export const SAVED_LABEL_NAMES = Object.values(SAVED_LABEL_NAME);

const initialState: State = {
  vodLabels: [],
  loading: null,
  initialized: false,
};

const actions = {
  fetchVodLabels:
    () =>
    async ({ setState, dispatch }, { appKey }) => {
      try {
        setState({ loading: true });
        const vodLabels = [];
        let nextToken = null;
        do {
          const response = await graphqlRequest(
            graphqlOperation(vodLabelByAppKey, {
              appKey,
              limit: 500,
              nextToken,
            })
          );

          vodLabels.push(...response.data.vodLabelByAppKey.items);
          nextToken = response.data.vodLabelByAppKey.nextToken;
        } while (nextToken);

        setState({ vodLabels: vodLabels, loading: false, initialized: true });
      } catch (err) {
        console.log(err);
        dispatch(actions.handleError(err));
      }
    },
  createVodLabel:
    (input, force?: boolean) =>
    async ({ setState, getState, dispatch }, { appKey }) => {
      try {
        if (SAVED_LABEL_NAMES.includes(input.name) && !force) {
          return;
        }

        input.appKey = appKey;
        const response = await VodLabelsGraphqlService.createVodLabel(input);
        const label = response.data.createVodLabel;
        const { vodLabels } = getState();
        setState({ vodLabels: [...vodLabels, label] });
        return label;
      } catch (err) {
        console.log(err);
        dispatch(actions.handleError(err));
      }
    },
  updateVodLabel:
    vodLabel =>
    async ({ setState, getState, dispatch }, { appKey }) => {
      try {
        vodLabel.appKey = appKey;
        const updatedVodLabel = await VodLabelsGraphqlService.updateVodLabel(vodLabel);

        if (!updatedVodLabel) {
          return;
        }

        const { vodLabels } = getState();

        const newVodLabels = vodLabels.map(vodLabel => {
          if (vodLabel.id === updatedVodLabel.id) {
            return updatedVodLabel;
          }

          return vodLabel;
        });

        setState({ vodLabels: newVodLabels });
      } catch (err) {
        console.log(err);
        dispatch(actions.handleError(err));
      }
    },
  deleteVodLabel:
    vodLabelId =>
    async ({ setState, getState, dispatch }) => {
      try {
        const deletedVodLabel = await VodLabelsGraphqlService.deleteVodLabel(vodLabelId);

        if (!deletedVodLabel) {
          return;
        }

        const { vodLabels } = getState();
        const newVodLabels = vodLabels.filter(vodLabel => vodLabel.id !== deletedVodLabel.id);

        setState({ vodLabels: newVodLabels });
      } catch (err) {
        console.log(err);
        dispatch(actions.handleError(err));
      }
    },
  getVodLabelById:
    id =>
    ({ getState }) => {
      const { vodLabels } = getState();
      return vodLabels.find(currentLabel => currentLabel.id === id);
    },
  getVodLabelByName:
    (name: string) =>
    ({ getState }) => {
      const { vodLabels } = getState();
      return vodLabels.find(
        currentLabel => currentLabel.name.toLocaleLowerCase() === name.toLocaleLowerCase()
      );
    },
  handleError:
    error =>
    ({ setState }) => {
      Utils.logError('error on vodLabels', error);
      setState({ loading: false });
    },
  clearVodLabels:
    () =>
    ({ setState }) => {
      setState(initialState);
    },
};

type Actions = typeof actions;

const VodLabelsStore = createStore<State, Actions>({ initialState, actions, name: 'VodLabel' });

const vodLabelsSelector = (state: State, id): vodLabel | null => {
  const { vodLabels } = state;
  const label = vodLabels.find(currentLabel => currentLabel.id === id);

  return label;
};

export const useVodLabelById = createHook<State, Actions, vodLabel | null, string | undefined>(
  VodLabelsStore,
  {
    selector: vodLabelsSelector,
  }
);

export const useVodLabels = createHook<State, Actions>(VodLabelsStore);

export const useVodLabelsActions = createActionsHook(VodLabelsStore);

export const VodLabelsContainer = createContainer(VodLabelsStore, {
  onInit:
    () =>
    ({ dispatch }) => {
      dispatch(actions.fetchVodLabels());
    },
  onCleanup:
    () =>
    ({ dispatch }) => {
      dispatch(actions.clearVodLabels());
    },
});
