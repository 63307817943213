import { getFilteredSearchParams } from '../utils/url.utils.js';
import {
  API_BASE_URL,
  API_LB_BASE_URL,
  API_LB_CDN_BASE_URL,
  CREATE_VIDEO_RESOLUTIONS,
  PAGE_VISIT_EVENT_URL,
  SITE_ACTIVITY_EVENT_URL
} from '../config/widget.config.js';
import { getCurrentPageProductId } from '../utils/script.utils.js';
import { sendWithInitialDelay } from './eventDelay.js';

const getTolstoyDomain = () => {
  return window.tolstoySettings?.shouldUseCache ? API_LB_CDN_BASE_URL : API_LB_BASE_URL;
};

export const getProjectConfig = async (publishId, widgetType) => {
  const productId = getCurrentPageProductId();
  const params = getFilteredSearchParams({ widgetType, productId, publishId });
  const tolstoyDomain = getTolstoyDomain();

  try {
    const res = await fetch(`${tolstoyDomain}/settings/widget/by-publish-id?${params}`);
    return res.json();
  } catch (error) {
    window.tolstoyCaptureError?.(error, 'Error loading config:');
  }
};

export const getConfigByUrl = async params => {
  try {
    const tolstoyDomain = getTolstoyDomain();

    const res = await fetch(
      `${tolstoyDomain}/settings/widget/by-product-url?${new URLSearchParams({
        ...params,
        url: `${window.location.origin}${window.location.pathname}`
      })}`
    );
    return res.json();
  } catch (error) {
    window.tolstoyCaptureError?.(error, 'Error getting product page config:');
  }
};

export const getConfigByProductId = async params => {
  try {
    const tolstoyDomain = getTolstoyDomain();

    const res = await fetch(
      `${tolstoyDomain}/settings/widget/by-product-id?${new URLSearchParams(params)}`
    );
    return res.json();
  } catch (error) {
    window.tolstoyCaptureError?.(error, 'Error getting product page config:');
  }
};

/**
 * Sends an event immediately
 * Always prefer using createPlayerEvent over this function, unless the event must be sent immediately
 * @param {*} params
 * @returns
 */
export const sendEvent = async params => {
  if (window.location.origin === process.env.VITE_BASE_URL) {
    return;
  }

  try {
    const res = await fetch(`${API_BASE_URL}/events/event`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    });
    return res;
  } catch (error) {
    window.tolstoyCaptureError?.(error, 'Error sending page view:');
  }
};

/**
 * Sends an event with a delay
 * Always prefer using this function over sendEvent
 * @param {*} params
 * @returns
 */
export const createPlayerEvent = sendWithInitialDelay(sendEvent);

export const createResolutions = async publishId => {
  try {
    return await fetch(`${CREATE_VIDEO_RESOLUTIONS}?publishId=${publishId}`);
  } catch (error) {
    window.tolstoyCaptureError?.(error);
  }
};

export const createSiteActivityEvent = sendWithInitialDelay(params => {
  try {
    window.navigator.sendBeacon(SITE_ACTIVITY_EVENT_URL, JSON.stringify(params));
  } catch (error) {
    const errorMessage = 'Error occurred in createSiteActivityEvent';
    console.error(errorMessage, error);
    window.tolstoyCaptureError?.(error, errorMessage);
  }
});

export const createPageVisitEvent = sendWithInitialDelay(params => {
  try {
    window.navigator.sendBeacon(PAGE_VISIT_EVENT_URL, JSON.stringify(params));
  } catch (error) {
    const errorMessage = 'Error occurred in createPageVisitEvent';
    console.error(errorMessage, error);
    window.tolstoyCaptureError?.(error, errorMessage);
  }
});
