import React from 'react';
import styled from 'styled-components';
import ExpanderBottomArrow from 'shared/react/images/expander_bottom_arrow.svg';
import { TextTiny } from 'shared/react/components/complex/Text';

function RuleCondition({ children, hasMenu, isNegativeCondition, onClick }) {
  return (
    <LayoutRoot onClick={onClick} hasMenu={hasMenu} isNegativeCondition={isNegativeCondition}>
      <TextTiny>{children}</TextTiny>
      {hasMenu && <img src={ExpanderBottomArrow} alt="Expander arrow" />}
    </LayoutRoot>
  );
}

export default RuleCondition;

const LayoutRoot = styled.div`
  cursor: ${({ hasMenu }) => (hasMenu ? 'pointer' : 'default')};
  width: 164px;
  height: 100%;
  margin-right: 8px;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 8px;
  background: white;
  border: 1px solid
    ${({ theme, isNegativeCondition }) =>
      isNegativeCondition ? theme.colors.danger : theme.colors.gray3};
  border-radius: 8px;
`;
