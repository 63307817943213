import React, { useEffect } from 'react';
import styled from 'styled-components';

import Utils from 'src/utils';
import AnalyticsFilter from 'app/src/complex_components/analytics/filters/AnalyticsFilter';
import { useAnalytics } from 'app/src/context/AnalyticsStore';
import { MenuItem } from '@material-ui/core';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { CHANNEL_FILTER_OPTIONS } from 'app/src/constants/analytics.constants';
import { cases, CaseType } from 'app/src/pages/dashboard/components/cases/cases.constants';
import useSelectAppProjects from 'app/src/hooks/useSelectedAppProjects';

const getTapcartProjects = projects => {
  return projects?.filter(({ useCaseName, integrationSettings }) => {
    const isTapcartUsecase = useCaseName === cases[CaseType.TAPCART_APP].name;
    const { isTapcart } = Utils.safeParse(integrationSettings);

    return isTapcartUsecase || isTapcart;
  });
};

const getFilterOptions = projects => {
  const options = {
    [CHANNEL_FILTER_OPTIONS.all]: { name: CHANNEL_FILTER_OPTIONS.all },
    [CHANNEL_FILTER_OPTIONS.tapcart]: {
      name: CHANNEL_FILTER_OPTIONS.tapcart,
      isHidden: !getTapcartProjects(projects)?.length,
    },
  };

  return Object.keys(options).reduce((acc, key) => {
    if (!options[key].isHidden) {
      acc[key] = options[key];
    }

    return acc;
  }, {});
};

const getChannelProjects = (projects, selectedChannel) => {
  switch (selectedChannel) {
    case CHANNEL_FILTER_OPTIONS.tapcart:
      return getTapcartProjects(projects);
    default:
      return [];
  }
};

const AnalyticsChannelFilter = () => {
  const [{ channel: selectedChannel }, { setChannelFilter, setPublishIds }] = useAnalytics();
  const { selectedAppProjects: projects } = useSelectAppProjects();

  const filterOptions = getFilterOptions(projects);

  const resetChannelFilters = () => {
    setChannelFilter(null);
    setPublishIds(null);
  };

  const onChannelFilterClick = channelFilter => {
    if (channelFilter === selectedChannel) {
      return;
    }

    const channelProjects = getChannelProjects(projects, channelFilter);
    const channelPublishIds = channelProjects.map(({ publishId }) => publishId);

    if (channelFilter === CHANNEL_FILTER_OPTIONS.all) {
      resetChannelFilters();
      return;
    }

    setPublishIds(channelPublishIds);
    setChannelFilter(channelFilter);
  };

  const getSelectedValue = value => {
    return value;
  };

  useEffect(() => {
    if (selectedChannel && !filterOptions[selectedChannel]) {
      resetChannelFilters();
    }
  }, [filterOptions, selectedChannel, setChannelFilter]);

  if (Object.keys(filterOptions)?.length <= 1) {
    return null;
  }

  return (
    <LayoutRoot>
      <AnalyticsFilter
        value={selectedChannel || CHANNEL_FILTER_OPTIONS.all}
        onChange={onChannelFilterClick}
        getSelectedValue={getSelectedValue}
        label="Channel"
      >
        {Object.values(filterOptions).map(({ name: channelName }) => (
          <MenuItem key={channelName} value={channelName}>
            {channelName}
          </MenuItem>
        ))}
      </AnalyticsFilter>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)`
  gap: 24px;
  min-width: 60px;
`;

export default AnalyticsChannelFilter;
