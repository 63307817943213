import { useState, useEffect } from 'react';
import { useSelectedApp } from 'src/context/AppsStore';

const getFontPalette = selectedApp => {
  const { theme } = JSON.parse(selectedApp?.settings || '{}');

  return theme?.palette?.fonts || [];
};

const useAppFontPalette = () => {
  const [selectedApp] = useSelectedApp();
  const [palette, setPalette] = useState(getFontPalette(selectedApp));

  useEffect(() => {
    setPalette(getFontPalette(selectedApp));
  }, [selectedApp]);

  return palette;
};

export default useAppFontPalette;
