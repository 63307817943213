import React from 'react';
import styled, { css } from 'styled-components';
import { TextSmall } from 'shared/react/components/complex/Text';

const ButtonGroup = ({ buttons, defaultBackground, disabled, height, width, ...props }) => {
  if (!buttons.length) {
    return null;
  }

  return (
    <Container {...props}>
      {buttons.map(({ text, onClick, isEnabled, dataTestId, icon }, i) => {
        return (
          <ButtonContainer
            key={`buttonGroup-${i}`}
            isLastButton={i === buttons.length - 1}
            isFirstButton={i === 0}
            disabled={disabled}
            onClick={disabled || isEnabled ? () => {} : onClick}
            isEnabled={isEnabled}
            height={height}
            width={width}
            data-test-id={dataTestId}
            defaultBackground={defaultBackground}
          >
            {icon ? icon : <ButtonText isEnabled={isEnabled}>{text}</ButtonText>}
          </ButtonContainer>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;

const BorderRadiusCss = css`
  border-bottom-right-radius: ${({ isFirstButton }) => (isFirstButton ? 0 : '8px')};
  border-top-right-radius: ${({ isFirstButton }) => (isFirstButton ? 0 : '8px')};
  border-bottom-left-radius: ${({ isFirstButton }) => (isFirstButton ? '8px' : 0)};
  border-top-left-radius: ${({ isFirstButton }) => (isFirstButton ? '8px' : 0)};
`;

const ButtonContainer = styled.div`
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  height: ${({ height }) => (height ? height : 'unset')};
  width: ${({ width }) => (width ? width : 'unset')};
  filter: ${({ disabled }) => (disabled ? 'grayScale(0.8)' : 'unset')};
  cursor: ${({ disabled, isEnabled }) => (disabled || isEnabled ? 'default' : 'pointer')};
  ${({ isFirstButton, isLastButton }) => (isFirstButton || isLastButton) && BorderRadiusCss}

  background: ${({ theme, isEnabled, defaultBackground = theme.colors.gray5 }) =>
    isEnabled ? theme.colors.primaryLight : defaultBackground};

  & path {
    fill: ${({ theme, isEnabled }) => (isEnabled ? theme.colors.primary : '')};
  }

  &:hover {
    opacity: ${({ isEnabled }) => (isEnabled ? 0.9 : '')};
    background: ${({ theme, isEnabled }) => (isEnabled ? '' : theme.colors.gray3)};
  }

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    padding: 12px;
  }
`;

const ButtonText = styled(TextSmall)`
  color: ${({ isEnabled, theme }) => (isEnabled ? theme.colors.blue7 : theme.colors.gray2)};
  white-space: pre;
`;

export default ButtonGroup;
