import React, { useEffect } from 'react';
import { useAnalytics } from 'app/src/context/AnalyticsStore';
import { useBilling } from 'app/src/hooks/useBillings';
import BillingPackageMetric from './BillingPackageMetric';

const getMaxPageViews = activePackage => {
  const { maxPageViews } = activePackage?.details || {};
  return maxPageViews;
};

const BillingPackagePageViewMetric = () => {
  const { currentPackage } = useBilling();
  const [{ currentMonthPageView }, { fetchCurrentMonthPageView }] = useAnalytics();
  const maxPageViews = getMaxPageViews(currentPackage);

  useEffect(() => {
    fetchCurrentMonthPageView();
  }, []);

  return (
    <BillingPackageMetric
      currentValue={currentMonthPageView}
      total={maxPageViews}
      text="Tolstoy views per month"
      showTotal={false}
    />
  );
};

export default BillingPackagePageViewMetric;
