import Utils from 'app/src/utils';
import { GetConversionRatesOvertimeParams } from 'app/src/services/cube/types/getConversionRatesOvertime.types';
import { groupBy } from 'lodash';

export const getConversionRateMetrics = ({
  ordersAovOvertimeData,
  timeOnPageOvertimeData,
  uniqueUsersMetrics,
}: GetConversionRatesOvertimeParams) => {
  const indexedUniqueUsersMetrics = groupBy(uniqueUsersMetrics, 'x');
  const indexedTimeOnPageData = groupBy(timeOnPageOvertimeData, 'x');

  return ordersAovOvertimeData.map(
    ({
      x,
      xValues,
      baselineDailyTotalCount: baselineOrdersCount = 0,
      tolstoyDailyTotalCount: tolstoyOrdersCount = 0,
    }) => {
      const usersMetrics = indexedUniqueUsersMetrics[x]?.[0];
      const timeOnPageData = indexedTimeOnPageData[x]?.[0];

      const pageViews = timeOnPageData?.numOfNonInteractingVisitsUnique || 0;
      const sessionStarts = usersMetrics?.sessionStarts || 0;

      const baselineConversionRate = Utils.getPercentage(baselineOrdersCount, pageViews, false, 1);
      const tolstoyConversionRate = Utils.getPercentage(
        tolstoyOrdersCount,
        sessionStarts,
        false,
        1
      );

      return {
        x,
        xValues,
        baselineConversionRate,
        baselineOrdersCount,
        tolstoyConversionRate,
        tolstoyOrdersCount,
      };
    }
  );
};
