import React from 'react';
import styled from 'styled-components';
import VideoSideBar from 'app/src/images/sideBar/VideoSideBar';
import AudioSideBar from 'app/src/images/sideBar/AudioSideBar';
import TextSideBar from 'app/src/images/sideBar/TextSideBar';

const SideBarButtons = ({ ...props }) => {
  return (
    <LayoutRoot {...props}>
      <ButtonsContainer>
        <SideBarButton>
          <VideoSideBar />
        </SideBarButton>
        <SideBarButton>
          <AudioSideBar />
        </SideBarButton>
        <SideBarButton>
          <TextSideBar />
        </SideBarButton>
      </ButtonsContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  display: grid;
  z-index: 1;
`;

const SideBarButton = styled.div`
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    transform: scale(1.1);
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
`;
export default SideBarButtons;
