import { CONFIRM_MODAL_KEY } from 'app/src/constants/modals.constants';
import { useModalActions } from 'app/src/context/ui_store/ModalStore';
import { useUserActions } from 'src/context/userStore/UserStore';
import { useProjectActions } from 'src/context/ProjectsStore';
import { project as Project } from 'app/src/types/entities';
import { ARCHIVE_FOLDER } from 'src/constants/folders';
import { truncate } from 'src/utils/string.utils';
import useToggleProjectLive from './useToggleProjectLive';

const MAX_PROJECT_NAME_LENGTH = 20;

export const useArchiveProject = () => {
  const { isProjectLive } = useUserActions();
  const { updateProject } = useProjectActions();
  const { setCurrentModal, updateModalProps } = useModalActions();
  const toggleLive = useToggleProjectLive();

  const archiveProject = async (project: Project) => {
    if (!project) {
      return;
    }

    const isLive = isProjectLive(project);
    const archivedProject = {
      ...project,
      folder: ARCHIVE_FOLDER.id,
    };

    if (isLive) {
      return toggleLive(archivedProject, false);
    }

    await updateProject(archivedProject);
  };

  const confirmArchiveProject = (project: Project) => {
    const name = truncate(project?.name, MAX_PROJECT_NAME_LENGTH) || 'tolstoy';

    setCurrentModal(CONFIRM_MODAL_KEY, {
      actionButtonText: 'Archive',
      titleText: `By moving '${name}' to archive it will automatically be paused`,
      actionButtonClicked: () => {
        updateModalProps({ loading: true });

        archiveProject(project).finally(() => {
          setCurrentModal(null);
          updateModalProps({ loading: false });
        });
      },
    });
  };

  return {
    archiveProject,
    confirmArchiveProject,
  };
};

export default useArchiveProject;
