import React from 'react';

const SoundPauseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      d="M3.5625 2C3.86719 2 4.125 2.25781 4.125 2.5625V10.4375C4.125 10.7656 3.86719 11 3.5625 11C3.23438 11 3 10.7656 3 10.4375V2.5625C3 2.25781 3.23438 2 3.5625 2ZM8.4375 2C8.74219 2 9 2.25781 9 2.5625V10.4375C9 10.7656 8.74219 11 8.4375 11C8.10938 11 7.875 10.7656 7.875 10.4375V2.5625C7.875 2.25781 8.10938 2 8.4375 2Z"
      fill="white"
    />
  </svg>
);

export default SoundPauseIcon;
