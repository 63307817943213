import React from 'react';

function AudioResponseButtonIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="#01BE81"
        d="M12 16.5c2.484 0 4.5-1.969 4.5-4.5V4.5C16.5 2.016 14.484 0 12 0a4.471 4.471 0 00-4.5 4.5V12c0 2.531 1.969 4.5 4.5 4.5zM19.125 9A1.11 1.11 0 0018 10.125V12a6 6 0 01-6.422 6C8.484 17.812 6 14.906 6 11.766v-1.641A1.14 1.14 0 004.875 9a1.11 1.11 0 00-1.125 1.125v1.547c0 4.172 2.953 7.922 7.125 8.484v1.594H9c-.89 0-1.547.75-1.5 1.594 0 .375.328.656.75.656h7.5c.375 0 .703-.281.703-.656.047-.844-.61-1.594-1.453-1.594h-1.875v-1.547c3.984-.562 7.125-4.031 7.125-8.203v-1.875A1.14 1.14 0 0019.125 9z"
      ></path>
    </svg>
  );
}

export default AudioResponseButtonIcon;
