import React from 'react';

const ListIcon = ({ fill = '#7D8087' }) => {
  return (
    <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.375.625h-1.5A.385.385 0 0 0 .5 1v1.5c0 .21.164.375.375.375h1.5A.385.385 0 0 0 2.75 2.5V1a.403.403 0 0 0-.375-.375Zm0 3.75h-1.5A.385.385 0 0 0 .5 4.75v1.5c0 .21.164.375.375.375h1.5a.385.385 0 0 0 .375-.375v-1.5a.403.403 0 0 0-.375-.375Zm0 3.75h-1.5A.385.385 0 0 0 .5 8.5V10c0 .21.164.375.375.375h1.5A.385.385 0 0 0 2.75 10V8.5a.403.403 0 0 0-.375-.375ZM5 2.5h6.75c.398 0 .75-.328.75-.75a.771.771 0 0 0-.75-.75H5a.755.755 0 0 0-.75.75c0 .422.328.75.75.75Zm6.75 6H5a.755.755 0 0 0-.75.75c0 .422.328.75.75.75h6.75c.398 0 .75-.328.75-.75a.771.771 0 0 0-.75-.75Zm0-3.75H5a.755.755 0 0 0-.75.75c0 .422.328.75.75.75h6.75c.398 0 .75-.328.75-.75a.771.771 0 0 0-.75-.75Z"
        fill={fill}
      />
    </svg>
  );
};

export default ListIcon;
