import React from 'react';

function KebabMenuDark({ color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="16" fill="none" viewBox="0 0 4 16">
      <path
        fill={color || '#50545E'}
        d="M2 3.5A1.5 1.5 0 0 0 3.5 2C3.5 1.187 2.812.5 2 .5A1.5 1.5 0 0 0 .5 2c0 .844.656 1.5 1.5 1.5zm0 9A1.5 1.5 0 0 0 .5 14c0 .844.656 1.5 1.5 1.5A1.5 1.5 0 0 0 3.5 14c0-.813-.688-1.5-1.5-1.5zm0-6A1.5 1.5 0 0 0 .5 8c0 .844.656 1.5 1.5 1.5A1.5 1.5 0 0 0 3.5 8c0-.813-.688-1.5-1.5-1.5z"
      />
    </svg>
  );
}

export default KebabMenuDark;
