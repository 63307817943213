import React from 'react';

const CampaignsIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.453.734A1.26 1.26 0 0 0 19.75.5c-.234 0-.43.078-.625.195l-17.5 9.961c-.43.274-.664.703-.625 1.172 0 .43.313.86.742 1.055l4.024 1.68 2.421 5.742c.079.156.196.195.274.195.078 0 .156 0 .195-.078l3.36-3.203 4.687 1.953c.195.078.352.078.508.078.234 0 .469-.078.586-.156.351-.157.586-.508.625-.899l2.5-16.21a1.17 1.17 0 0 0-.469-1.25ZM2.25 11.75l14.844-8.477-11.29 10L2.25 11.75Zm6.563 6.836-1.915-4.61 8.946-7.93-6.602 9.102c-.117.157-.156.352-.117.547.078.196.195.352.39.39l1.329.548-2.031 1.953Zm8.437-.742-6.523-2.617 8.789-12.11-2.266 14.727Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default CampaignsIcon;
