import { css } from 'styled-components';

export const FontFamilyCss = css`
  & * {
    font-family: ${({ customFontFamily }) =>
      customFontFamily ? `${customFontFamily}, sans-serif` : ''};

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: ${({ customHeadingFontFamily }) =>
        customHeadingFontFamily ? `${customHeadingFontFamily}, sans-serif` : ''};
    }
  }

  & textarea,
  input {
    font-family: ${({ customFontFamily }) =>
      customFontFamily ? `${customFontFamily}, sans-serif` : ''};
  }
`;
