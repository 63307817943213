import { PRODUCT_CTA_TYPES } from 'shared/react/constants/feed.constants';
import {
  DEFAULT_CTA_BUTTON_TEXT,
  DEFAULT_STORIES_SIZE,
  EMBED_CAROUSEL_MOTION,
  EMBED_STORY_MOTION,
  STORIES_BORDER_TYPES,
} from '../pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import { publishMethodOptions } from 'app/src/types/entities';

export const PROJECT_TYPES = {
  deleted: 'deleted',
};

export const SUPPORTED_TYPE_KEYS = {
  reply: 'reply',
  deleted: 'deleted',
  tolstoy: 'tolstoy',
};

export const STEP_TYPES = {
  productsResult: 'products-result',
};

export const SHOP_APP_TARGET = {
  productPage: 'product-pages',
  homePage: 'home-pages',
};

export const OFFSITE_PUBLISH_METHODS = [
  publishMethodOptions.landingPage,
  publishMethodOptions.email,
  null, // empty publish method
];

export const DEFAULT_WIDGET_SETTINGS = {
  slideEnabled: true,
  slideFrom: 'side',
  carouselShape: '9/16',
  carouselMotion: EMBED_CAROUSEL_MOTION.dynamic,
  storiesBorderType: STORIES_BORDER_TYPES.solid,
  storiesBorderOpacity: 1,
  storiesMotion: EMBED_STORY_MOTION.dynamic,
  storiesCircleSize: DEFAULT_STORIES_SIZE,
};

export const DEFAULT_PLAYER_SETTINGS = {
  productDetailsSubscriptionsEnabled: false,
};

export const TAPCART_PLAYER_SETTINGS = {
  productDetailsSubscriptionsEnabled: false,
  productDetailsCheckoutShoppingBagEnabled: false,
  productDetailsStockEnabled: false,
};

export const DEFAULT_PDP_CONFIG = {
  primaryButton: {
    enabled: true,
    ctaType: PRODUCT_CTA_TYPES.addToCart,
    buttonText: DEFAULT_CTA_BUTTON_TEXT[PRODUCT_CTA_TYPES.addToCart],
    value: '',
    isOpenInANewTab: false,
  },
  secondaryButton: {
    enabled: true,
    ctaType: PRODUCT_CTA_TYPES.buyNow,
    buttonText: DEFAULT_CTA_BUTTON_TEXT[PRODUCT_CTA_TYPES.buyNow],
    value: '',
    isOpenInANewTab: false,
  },
  miniCtaButton: {
    enabled: false,
    text: 'Shop',
  },
};

export const TAPCART_PDP_CONFIG = {
  primaryButton: {
    enabled: true,
    ctaType: PRODUCT_CTA_TYPES.addToCart,
    buttonText: DEFAULT_CTA_BUTTON_TEXT[PRODUCT_CTA_TYPES.addToCart],
    value: '',
    isOpenInANewTab: false,
  },
  secondaryButton: {
    enabled: true,
    ctaType: PRODUCT_CTA_TYPES.buyNow,
    buttonText: DEFAULT_CTA_BUTTON_TEXT[PRODUCT_CTA_TYPES.buyNow],
    value: '',
    isOpenInANewTab: false,
  },
  miniCtaButton: {
    enabled: false,
    text: 'Shop',
  },
};

export const DEFAULT_HOMEPAGE_CONFIG = {
  primaryButton: {
    enabled: true,
    ctaType: PRODUCT_CTA_TYPES.addToCart,
    buttonText: DEFAULT_CTA_BUTTON_TEXT[PRODUCT_CTA_TYPES.addToCart],
    value: '',
    isOpenInANewTab: false,
  },
  secondaryButton: {
    enabled: true,
    ctaType: PRODUCT_CTA_TYPES.openProductPage,
    buttonText: DEFAULT_CTA_BUTTON_TEXT[PRODUCT_CTA_TYPES.openProductPage],
    value: '',
    isOpenInANewTab: false,
  },
  miniCtaButton: {
    enabled: false,
    text: 'Shop',
  },
};

export const DEFAULT_STORIES_CONFIG = {
  themeColor: '#090A0B',
  themeTextColor: '#FFFFFF',
  autoplayEnabled: false,
  bubbleText: 'Chat with me',
  slideEnabled: true,
  slideFrom: 'side',
  carouselShape: '9/16',
  carouselBorderRadius: 0,
  carouselMotion: 'dynamic',
  carouselBorderColor: '#090A0B',
  carouselBorderWidth: 2,
  carouselBorder: false,
  carouselFontSize: 20,
  carouselFontColor: '#000000',
  carouselTitle: true,
  carouselTitleText: 'Shop the feed',
  carouselTitleFontWeight: 700,
  carouselTileNameEnabled: false,
  carouselTileNameLocation: 'under',
  carouselTileNameTextSize: 16,
  carouselTileNameTextColor: '#000000',
  carouselTileNameBackgroundEnabled: false,
  carouselTileNameBackgroundColor: '#090A0B',
  carouselTileNameBackgroundTransparancy: 50,
  carouselPlayInTileFirst: false,
  carouselPlayButtonBackground: false,
  carouselPlayButtonBackgroundColor: '#000000',
  carouselPlayButtonBorder: true,
  carouselPlayButtonBorderColor: '#FFFFFF',
  carouselPlayButtonOpacity: 0.65,
  carouselItemSizeType: 'responsive',
  carouselItemsPerRow: 5,
  carouselItemHeight: 416,
  carouselSpacingHorizontal: 6,
  carouselPaddingHorizontal: 0,
  carouselPaddingVertical: 0,
  isFullscreenFeed: false,
  keepSettingsOnMobile: false,
  widgetHeaderLocation: 'center',
  storiesBorderRadius: 104,
  storiesBorderEnabled: true,
  storiesBorderWidth: 1,
  storiesBorderColor: '#DD6089',
  storiesBorderType: 'solid',
  storiesBorderOpacity: 1,
  storiesTitleEnabled: true,
  storiesTitleText: 'Shop the feed',
  storiesTitleFontSize: 16,
  storiesTitleFontWeight: 700,
  storiesTitleColor: '#000000',
  storiesStoryNameEnabled: false,
  storiesStoryNameFontSize: 12,
  storiesStoryNameColor: '#000000',
  storiesAlignment: 'left',
  storiesMotion: 'dynamic',
  storiesItemsSizeType: 'fixed',
  storiesItemsPerRow: 4,
  storiesCircleSize: 104,
  storiesItemsSpacing: 8,
  storiesItemsShape: 'circle',
  storiesRectangleWidth: 80,
  storiesRectangleHeight: 120,
};

export const DEFAULT_EMAIL_SETTINGS = {
  horizontalSpacing: 16,
  maxTilesCount: 3,
  previewFormat: 'dynamic',
};

export const DEFAULT_VIDEO_COLLECTION_CAMPAIGN_SETTINGS = {
  landingPage: {
    offerText: 'Qualified videos will receive {{offer}}',
    introText:
      'Shoot a short video telling others why you love {{store name}}. All you need is your phone, good lighting, and a smile!',
    howTo: {
      videoInstructions: 'Record a short, vertical video',
      contentInstructions:
        'Tell others why you love {{store_name}} or showcase a recent {{store_name}} purchase.',
      lightsInstructions: 'Make sure the light is behind the camera facing you.',
    },
  },
};

export const DEFAULT_EMAIL_UTM_PARAM = 'tolstoy=';
export const DEFAULT_TV_PAGE_URL = 'pages/tolstoy-tv-channel';

export const OPTIMAL_PRODUCT_VIDEOS = 3;
