export const DEFAULT_PLAY_BUTTON_SIZE = 90;
export const DEFAULT_PLAY_BUTTON_BORDER_COLOR = '';
export const DEFAULT_PLAY_BUTTON_BACKGROUND_OPACITY = 100;
export const DEFAULT_PLAY_BUTTON_BACKGROUND_COLOR = '';
export const DEFAULT_PLAY_BUTTON_START_SCREEN_TEXT = 'Begin an interactive conversation';
export const DEFAULT_CAROUSEL_EMBED_PLAY_BUTTON_BACKGROUND_COLOR = '#000000';
export const DEFAULT_CAROUSEL_EMBED_PLAY_BUTTON_BORDER_COLOR = '#FFFFFF';
export const DEFAULT_CAROUSEL_EMBED_PLAY_BUTTON_OPACITY = 0.65;

export const SIZE_TYPE = 'size';
export const START_SCREEN_TEXT_TYPE = 'startScreenText';
export const BACKGROUND_OPACITY_TYPE = 'backgroundOpacity';
export const BACKGROUND_COLOR_TYPE = 'backgroundColor';
export const BORDER_COLOR_TYPE = 'borderColor';

export const PLAY_BUTTON_DEFAULT_SETTINGS = {
  [START_SCREEN_TEXT_TYPE]: DEFAULT_PLAY_BUTTON_START_SCREEN_TEXT,
  [BACKGROUND_OPACITY_TYPE]: DEFAULT_PLAY_BUTTON_BACKGROUND_OPACITY,
  [BACKGROUND_COLOR_TYPE]: DEFAULT_PLAY_BUTTON_BACKGROUND_COLOR,
  [SIZE_TYPE]: DEFAULT_PLAY_BUTTON_SIZE,
  [BORDER_COLOR_TYPE]: DEFAULT_PLAY_BUTTON_BORDER_COLOR,
};

export const PLAY_BUTTON_SIZES = {
  120: 'Large',
  90: 'Medium',
  60: 'Small',
};
