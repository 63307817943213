import React from 'react';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextSmall } from 'shared/react/components/complex/Text';
import InfoIconWithTooltip from 'app/src/complex_components/InfoIconWithTooltip';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';

const TextSmallWrapper = ({
  header,
  disabled,
  tooltipText,
  children,
  textComponent,
  afterInfoIcon,
  ...props
}) => {
  return (
    <LayoutRoot disabled={disabled} {...props}>
      <HeaderContainer>
        {textComponent || <TextSmall>{header}</TextSmall>}
        <StyledInfoIconWithTooltip title={tooltipText} alignToCenter />
        {afterInfoIcon}
      </HeaderContainer>
      {children}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  gap: 2px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : '')};
  opacity: ${({ disabled }) => (disabled ? 0.4 : '')};
  transition: 0.1s;
`;

const HeaderContainer = styled(HorizontalFlexWrap)`
  gap: 4px;
`;

const StyledInfoIconWithTooltip = styled(InfoIconWithTooltip)`
  & svg {
    width: 12px;
    height: 12px;
  }

  & path {
    fill: ${({ theme }) => theme.colors.black};
  }
`;

export default TextSmallWrapper;
