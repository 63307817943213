import React, { useState } from 'react';
import VerticalVideoCard from 'src/basic_components/vertical-video-card/VerticalVideoCard';
import { ADD_PRODUCT_VIDEOS_MODAL_KEY } from 'src/constants/modals.constants';
import { useApps } from 'src/context/AppsStore';
import { useProductActions } from 'src/context/ProductsStore';
import { useVodConnectionByProductId } from 'src/context/VodConnectionStore';
import { useModalActions } from 'src/context/ui_store/ModalStore';
import { track } from 'src/helpers/Tracker';
import { useOpenVideoPickerModal } from 'src/hooks/modals';
import { useCreateVodConnection } from 'src/hooks/vod-connections';
import { SearchProduct } from 'src/types/common';
import { VodConnectionProvider, VodConnectionType } from 'src/types/entities';
import { AnalyticsData } from 'src/types/utils.types';

type Props = {
  analyticsData: AnalyticsData;
} & SearchProduct;

const ProductVideoTile = ({ imageUrl, productId, title, analyticsData }: Props) => {
  const [vodConnections] = useVodConnectionByProductId({ productId });
  const openVideoPickerModal = useOpenVideoPickerModal();
  const { createVodConnection } = useCreateVodConnection();
  const [{ selectedAppUrl }] = useApps();
  const { createProducts } = useProductActions();
  const { setCurrentModal, setCurrentId, setModalProps } = useModalActions();
  const [isLoading, setIsLoading] = useState(false);

  const onVideoPicked = async video => {
    if (!video?.id) {
      return;
    }

    setIsLoading(true);

    const product = (await createProducts([productId], selectedAppUrl))?.[0];

    track('Vod Connections Created', {
      location: `${analyticsData.location}/Product Empty State`,
    });

    await createVodConnection({
      productId: product.id,
      externalProductId: productId,
      vodAssetId: video.id,
      type: VodConnectionType.productId,
      appUrl: selectedAppUrl,
      provider: VodConnectionProvider.shopify,
    });

    setIsLoading(false);
  };

  const handleEmptyStateClick = () => {
    openVideoPickerModal({
      onVideoPicked,
    });
  };

  const handleClick = () => {
    setCurrentModal(ADD_PRODUCT_VIDEOS_MODAL_KEY);
    setCurrentId(productId);
    setModalProps({
      title,
      imageUrl,
      analyticsData,
    });
  };

  return (
    <VerticalVideoCard
      vodAssetId={vodConnections[0]?.vodAssetId}
      onEmptyStateClick={handleEmptyStateClick}
      product={{ imageUrl, productId, title }}
      numOfVodAssets={vodConnections.length}
      onClick={handleClick}
      isLoading={isLoading}
    />
  );
};

export default ProductVideoTile;
