import React from 'react';
import Menu from 'shared/react/components/basic/Menu';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const MenuWithContainer = ({ children, className = '', ...props }) => {
  return (
    <Menu {...props}>
      <MenuContent className={className}>{children}</MenuContent>
    </Menu>
  );
};

const MenuContent = styled(VerticalFlex)`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(50, 50, 93, 0.1), 0 1px 3px -1px rgba(0, 0, 0, 0.05);
`;

export default MenuWithContainer;
