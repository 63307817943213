export default {
  Primary: 'primary',
  Secondary: 'secondary',
  SecondaryGhost: 'secondaryGhost',
  Warning: 'warning',
  Danger: 'danger',
  Success: 'success',
  Info: 'info',
  Ghost: 'ghost',
  Neutral: 'neutral',
};
