import React from 'react';

const OutlinedVideoIcon = () => {
  return (
    <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.355 1.566a1.016 1.016 0 0 0-1.039-.082L11.5 2.797V2.25c0-.957-.793-1.75-1.75-1.75H1.875C.891.5.125 1.293.125 2.25v7c0 .984.766 1.75 1.75 1.75H9.75c.957 0 1.75-.766 1.75-1.75v-.52l2.816 1.313c.137.055.301.11.465.11.192 0 .41-.083.574-.192.329-.191.52-.547.52-.93V2.496c0-.383-.191-.738-.52-.93ZM10.188 9.25a.45.45 0 0 1-.437.438H1.875a.432.432 0 0 1-.438-.438v-7a.45.45 0 0 1 .438-.438H9.75a.47.47 0 0 1 .438.438v7Zm4.376-.547L11.5 7.31V4.22l3.063-1.395v5.88Z"
        fill="#fff"
      />
    </svg>
  );
};

export default OutlinedVideoIcon;
