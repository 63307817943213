import React, { useEffect } from 'react';
import styled from 'styled-components';
import { PageContainer, PageMain } from '../../components/page/Page';
import AnalyticsViewersPagesTable from 'src/pages/dashboard/pages/audience/AudienceTable';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import TopBar from '../../components/top-bar/TopBar';
import AnalyticsDateFilter from 'app/src/complex_components/analytics/filters/AnalyticsDateFilter';
import { TextBody } from 'shared/react/components/complex/Text';
import AnalyticsFilterBase from 'app/src/complex_components/analytics/filters/AnalyticsFilterBase';
import { useDrawerActions } from 'src/context/ui_store/DrawerStore';

const Audience = () => {
  const { clearDrawerState } = useDrawerActions();

  useEffect(() => {
    return () => {
      clearDrawerState();
    };
  }, []);

  return (
    <PageContainer>
      <PageMain>
        <TopBar leftContent={<></>} rightContent={<StyledAnalyticsDateFilter />} />
        <Content>
          <AnalyticsViewersPagesTable />
        </Content>
      </PageMain>
    </PageContainer>
  );
};

const Content = styled(VerticalFlex)`
  padding: 24px;
`;

const StyledAnalyticsDateFilter = styled(AnalyticsDateFilter)`
  & ${AnalyticsFilterBase} {
    border: 1px solid ${({ theme }) => theme.colors.neutralLight};
    border-radius: 4px;
    padding: 8px 12px;
  }

  & ${TextBody} {
    color: ${({ theme }) => theme.colors.neutralDarker};
  }

  & svg {
    width: 14px;
    height: 14px;

    & > path {
      fill: ${({ theme }) => theme.colors.neutralDarker};
    }
  }
`;

export default Audience;
