import React, { useState } from 'react';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import SearchProducts from 'app/src/complex_components/search-products/SearchProducts';
import ComponentWithLoader from 'src/complex_components/ComponentWithLoader';
import { useProductPageSettings } from 'app/src/context/ProductPageSettingsStore';
import SelectedProductsList from 'app/src/complex_components/global_modal/video/add_products_modal/SelectedProductsList';
import { EMBED_WIDGETS_NAMES } from 'app/src/constants/ui.constants';
import { useBilling } from 'app/src/hooks/useBillings';
import { InputWithIconLayoutRoot } from 'app/src/basic_components/input/InputWithIcon';
import { useRulesContext } from 'app/src/pages/project/pages/rules_and_triggers/RulesContext';
import EmbedPDPByUrl from 'app/src/pages/modals/publish/embed/feed_embed_widgets/EmbedPDPByUrl';
import { SelectedProductsProvider } from 'app/src/complex_components/global_modal/video/add_products_modal/SelectedProductsContext';

const RulesProductPagesEditor = () => {
  const {
    setSelectedProducts,
    selectedProducts,
    project,
    isSaving,
    isLoadingProducts,
    setUnsaved,
    shopifyApps,
  } = useRulesContext();
  const { embedSetLiveEnabled } = useBilling();
  const [_, setIsSelectProductPopupOpened] = useState<boolean>(false);
  const [{ productsSettings }] = useProductPageSettings({
    publishId: project?.publishId,
    widgetType: project?.publishMethod,
  });

  const onSetSelectedProducts = products => {
    setSelectedProducts(products);
    setUnsaved(true);
  };

  const onProductSelected = product => {
    if (!embedSetLiveEnabled || !project) {
      return;
    }

    setSelectedProducts([...selectedProducts, product]);
    setUnsaved(true);
  };

  const onDelete = id => {
    const filteredProducts = selectedProducts.filter(product => product.productId !== id);
    setSelectedProducts(filteredProducts);
    setUnsaved(true);
  };

  const appUrl = project?.appUrl || project?.steps?.items?.[0]?.productsAppUrl;

  const disabledProductIds = productsSettings.flatMap(productSetting => {
    if (
      project.publishId !== productSetting.publishId &&
      project.publishMethod === productSetting.widgetType
    ) {
      return productSetting.productId;
    }

    return [];
  });

  if (!shopifyApps?.length) {
    return <EmbedPDPByUrl project={project} widgetType={project.publishMethod} />;
  }

  return (
    <LayoutRoot>
      <ComponentWithLoader isLoading={isLoadingProducts}>
        <SearchProducts
          selectedProducts={selectedProducts}
          onProductSelected={onProductSelected}
          selectedApp={appUrl ? { appUrl } : null}
          location="Rules and Triggers"
          onHasNoPermissions={undefined}
          getProductComponent={undefined}
          isLoading={undefined}
          isSaving={isSaving}
          analyticsData={undefined}
          disabledProductIds={disabledProductIds}
          productsInputPlaceholder="Find a product..."
          setSelectedProducts={onSetSelectedProducts}
          disabledTooltipText={`A different ${
            EMBED_WIDGETS_NAMES[project?.publishMethod]
          } is already previewed on this page.`}
          setIsSelectProductPopupOpened={setIsSelectProductPopupOpened}
        />
        <SelectedProductsProvider>
          <ProductsList
            selectedProducts={selectedProducts}
            isLoading={isLoadingProducts}
            onRemove={onDelete}
          />
        </SelectedProductsProvider>
      </ComponentWithLoader>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  gap: 16px;

  & ${InputWithIconLayoutRoot} {
    height: 40px;
  }
`;

const ProductsList = styled(SelectedProductsList)`
  min-height: unset;
`;

export default RulesProductPagesEditor;
