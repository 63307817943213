import React, { forwardRef } from 'react';
import styled from 'styled-components';
import FeedOverlay from 'player/src/components/feed/feed_overlay/FeedOverlay';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { ImageRenditionsService } from 'shared/react/services/assets';

const FeedImage = forwardRef(
  (
    {
      step,
      index,
      videoRef,
      numOfSteps,
      nextStepName,

      isPlaying,
      isCurrentVideo,
      isPlayerPreview,
      isStepOutOfView,

      handlePlayPause,
      handleNavigateToStep,
    },
    ref
  ) => {
    const { videoId: id, videoOwner: owner } = step;
    const src = ImageRenditionsService.getRenditionUrl({ id, owner });

    return (
      <LayoutRoot ref={ref} $isStepOutOfView={isStepOutOfView} data-video-index={index}>
        <Image src={src} data-video-index={index} onClick={handlePlayPause} />
        <FeedOverlay
          isCurrentVideo={isCurrentVideo}
          videoRef={videoRef}
          handleNavigateToStep={handleNavigateToStep}
          nextStepName={nextStepName}
          handlePlayPause={handlePlayPause}
          isPlaying={isPlaying}
          step={step}
          numOfSteps={numOfSteps}
          isFeed={true}
          isPlayerPreview={isPlayerPreview}
        />
      </LayoutRoot>
    );
  }
);

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

const LayoutRoot = styled(HorizontalFlexCentered)`
  display: ${({ $isStepOutOfView }) => ($isStepOutOfView ? 'none' : 'flex')};
  height: 100%;
  width: 100%;
  scroll-snap-align: start;
  min-width: 100%;
  min-height: 100%;
  scroll-snap-stop: always;
  position: relative;
  overflow: hidden;
`;

FeedImage.displayName = 'FeedImage';

export default FeedImage;
