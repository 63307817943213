import React from 'react';

const ArrowRight = props => (
  <svg
    width={10}
    height={19}
    viewBox="0 0 10 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.07031 18.0938C0.914062 17.9766 0.835938 17.8203 0.835938 17.6641C0.835938 17.5078 0.914062 17.3516 1.03125 17.2344L8.10156 9.53906L1.03125 1.80469C0.796875 1.57031 0.796875 1.17969 1.07031 0.945312C1.30469 0.710938 1.69531 0.710938 1.92969 0.984375L9.42969 9.10938C9.66406 9.34375 9.66406 9.69531 9.42969 9.92969L1.92969 18.0547C1.69531 18.3281 1.30469 18.3281 1.07031 18.0938Z"
      fill="#52555B"
    />
  </svg>
);

export default ArrowRight;
