import React from 'react';
import styled from 'styled-components';

import { PrimaryButton, WhiteButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

type Props = {
  onSave: () => void;
  onDiscard: () => void;
  isLoading?: boolean;
};

const CaptionsEditorModalButtons = ({ onSave, onDiscard, isLoading }: Props) => {
  return (
    <LayoutRoot>
      <WhiteButton disabled={isLoading} onClick={onDiscard} isLoading={isLoading}>
        Discard
      </WhiteButton>
      <PrimaryButton disabled={isLoading} isLoading={isLoading} onClick={onSave}>
        Save
      </PrimaryButton>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlex)`
  margin-top: auto;
  justify-content: flex-end;
  z-index: 10;
`;

export default CaptionsEditorModalButtons;
