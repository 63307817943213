import React, { useState } from 'react';
import styled from 'styled-components';
import BurgerMenuIcon from 'shared/react/images/BurgerMenuIcon';
import TolstoyTVIcon from 'shared/react/images/TolstoyTVIcon';
import { useVideos } from 'app/src/context/VideosStore';
import DefaultTilePreview from 'app/src/images/default_tile_preview.png';
import EmbedCarouselPreviewPlayButton from '../embed_carousel_preview/EmbedCarouselPreviewPlayButton';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { useDynamicConditionsContext } from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/context/DynamicConditionsContext';

type Props = {
  project: any;
  size?: number;
};

const VideoPagePreview = ({ project, size = 1 }: Props) => {
  const { vodAssetIds } = useDynamicConditionsContext();
  const videoId = project?.dynamic ? vodAssetIds[0] : project?.steps?.[0]?.videoId;

  const [{ video = {} }, { getVideoPreview, handleVideoLoadError }] = useVideos(videoId);
  const [videoPreviewUrl, setVideoPreviewUrl] = useState(getVideoPreview(video));

  const onVideoLoadError = () => {
    handleVideoLoadError(video, videoPreviewUrl, setVideoPreviewUrl, true);
  };

  return (
    <LayoutRoot size={size}>
      <PreviewHeader>
        <BurgerMenuIcon />
        <TolstoyTVIcon />
      </PreviewHeader>
      <PreviewContent>
        <BackgroundImage src={DefaultTilePreview} />
        <Video src={videoPreviewUrl} onError={onVideoLoadError} muted={true} />
        <EmbedCarouselPreviewPlayButton size={size} />
      </PreviewContent>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  overflow: hidden;
  box-shadow:
    0px 15px 35px ${({ theme }) => theme.getHexOpacity(theme.colors.gray12, 10)},
    0px 5px 15px ${({ theme }) => theme.getHexOpacity(theme.colors.black1, 7)};
  margin-bottom: 35px;
  border-radius: 16px;
  height: ${({ size }) => `${580 / size}px`};
`;

const PreviewHeader = styled(HorizontalFlex)`
  justify-content: space-between;
  padding: 18px 24px;
  height: 55px;
`;

const PreviewContent = styled.div`
  transition: 0.3s;
  display: grid;
  pointer-events: auto;
  overflow: hidden;
  aspect-ratio: 9/16;
  height: calc(100% - 55px);
  width: auto;
`;

const BackgroundImage = styled.img`
  object-fit: cover;
  grid-column: 1;
  grid-row: 1;
  height: 100%;
  width: 100%;
`;

const Video = styled.video`
  object-fit: cover;
  grid-column: 1;
  grid-row: 1;
  height: 100%;
  width: 100%;
`;

export default VideoPagePreview;
