import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { LEFT, RIGHT } from './carouselSlider.constants';
import CarouselSliderArrowIcon from 'shared/react/images/CarouselSliderArrowIcon';
import { CAROUSEL_ARROW_DATA_TYPE } from 'shared/react/constants/feedPreviewDataTypes.constants';
import { HorizontalFlexCentered } from '../flex_layouts/HorizontalFlex';

const CarouselSliderArrow = forwardRef(({ direction, onClick }, ref) => {
  return (
    <CarouselSliderArrowLayoutRoot
      direction={direction}
      data-type={CAROUSEL_ARROW_DATA_TYPE}
      ref={ref}
    >
      <IconContainer
        onClick={() => onClick(direction)}
        type="button"
        aria-label={`Slide: ${direction}`}
      >
        <CarouselSliderArrowIcon direction={direction} />
      </IconContainer>
    </CarouselSliderArrowLayoutRoot>
  );
});

CarouselSliderArrow.displayName = 'CarouselSliderArrow';

export const CarouselSliderArrowLayoutRoot = styled.div`
  position: absolute;
  max-height: 100%;
  aspect-ratio: 1;
  top: 50%;
  transform: translateY(-50%);
  left: ${({ direction }) => (direction === LEFT ? '4px' : '')};
  right: ${({ direction }) => (direction === RIGHT ? '4px' : '')};
  z-index: 2;
`;

const IconContainer = styled(HorizontalFlexCentered)`
  cursor: pointer;
  z-index: 10;

  & svg {
    fill: ${({ theme }) => theme.colors.black};
    width: 32px;
    height: 32px;
  }

  & rect {
    display: none;
  }

  &:hover {
    rect {
      fill: ${({ theme }) => theme.colors.gray6};
    }
  }
`;

export default CarouselSliderArrow;
