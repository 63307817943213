import React from 'react';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import EditorMainContainer from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorMainContainer';
import TextEditor from './TextEditor';
import ButtonsDesignEditor from './ButtonsDesignEditor';
import ProductDetailsCardEditor from './ProductDetailsCardEditor';
import BrandingExpander from 'app/src/pages/account/branding/editors/BrandingExpander';

type Props = {
  settings?: any;
  features?: any;
  defaultSettings?: any;
  onChange?: any;
};

const FeedCardsEditor = ({
  settings = {},
  features = {},
  defaultSettings = {},
  onChange = () => {},
}: Props) => {
  const updateSettings = (update, options) => onChange({ ...settings, ...update }, options);
  const onTextChange = (settings, options) => updateSettings({ text: settings }, options);
  const onButtonsDesignChange = (settings, options) =>
    updateSettings({ buttonsDesign: settings }, options);
  const onProductDetailsCardChange = (settings, options) =>
    updateSettings({ productDetailsCard: settings }, options);

  return (
    <LayoutRoot>
      <BrandingExpander title="Feed Behavior">
        <EditorMainContainer>
          <TextEditor
            settings={settings.text}
            features={features}
            defaultSettings={defaultSettings.text}
            onChange={onTextChange}
          />
          <ButtonsDesignEditor
            settings={settings.buttonsDesign}
            features={features}
            defaultSettings={defaultSettings.buttonsDesign}
            onChange={onButtonsDesignChange}
          />
          <ProductDetailsCardEditor
            settings={settings.productDetailsCard}
            features={features}
            defaultSettings={defaultSettings.productDetailsCard}
            onChange={onProductDetailsCardChange}
          />
        </EditorMainContainer>
      </BrandingExpander>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

export default FeedCardsEditor;
