import React from 'react';
import Radio from '@material-ui/core/Radio';
import styled from 'styled-components';

const RadioButton = props => {
  return (
    <CustomRadio
      disableRipple
      color="default"
      checkedIcon={<CheckedSpan />}
      icon={<span />}
      {...props}
    />
  );
};

const CheckedSpan = styled.span`
  background-color: ${({ theme }) => theme.colors.primary};
  background-image: linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0));
  border-radius: 100%;
  
&:before {
  display: block;
  width: 16px;
  height: 16px;
  background-image:  ${({ theme }) =>
    `radial-gradient(${theme.colors.white},${theme.colors.white} 28%,transparent 32%)`} ;
  content: "";
},
  
input:hover ~ & {
  background-color: ${({ theme }) => theme.colors.primaryHover};
},`;

const CustomRadio = styled(Radio)`
  .MuiIconButton-label {
    border-radius: 50%;
    width: 16px;
    height: 16px;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
    background-color: ${({ theme }) => theme.colors.gray6};
    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.8), hsla(0, 0%, 100%, 0));

    .Mui-focusVisible & {
      outline: 2px auto rgba(19, 124, 189, 0.6);
      outline-offset: 2px;
    }

    input:disabled ~ & {
      boxshadow: none;
      background: rgba(206, 217, 224, 0.5);
    }
  }
`;

export default RadioButton;
