import React from 'react';

const BackIcon = () => {
  return (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.453 10.047c0-.656-.469-1.125-1.125-1.125H4.391l6.234-5.953a1.119 1.119 0 0 0 .047-1.594C10.25.906 9.547.906 9.078 1.328L.828 9.203C.594 9.438.5 9.72.5 10.047c0 .281.094.562.328.797l8.25 7.875c.469.422 1.172.422 1.594-.047a1.119 1.119 0 0 0-.047-1.594l-6.234-5.906h15.937a1.11 1.11 0 0 0 1.125-1.125Z"
        fill="#50545E"
      />
    </svg>
  );
};

export default BackIcon;
