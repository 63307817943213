import React from 'react';
import styled from 'styled-components';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import Spinner from 'app/src/basic_components/Spinner';

type Props = {
  isLoading: boolean;
};

const Loading = ({ isLoading }: Props) => {
  if (!isLoading) {
    return null;
  }

  return (
    <LayoutRoot>
      <Spinner />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlexCentered)`
  padding: 50px 0;
`;

export default Loading;
