import React, { useState } from 'react';
import styled from 'styled-components';

import { TextSmall } from 'shared/react/components/basic/text/TextV2';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import ExpanderTopArrowIcon from 'src/images/creation_method_icons/ExpanderTopArrowIcon';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';

type Props = {
  collapsedTitle: string | React.ReactElement;
  expandedTitle: string | React.ReactElement;
  children: string | React.ReactElement;
  startExpanded?: boolean;
};

const ExpanderV2: React.FC<Props> = ({
  collapsedTitle,
  expandedTitle,
  children,
  startExpanded = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(startExpanded);

  const handleToggleCollapse = () => {
    setIsExpanded(isCollapsed => !isCollapsed);
  };

  const title = isExpanded ? expandedTitle : collapsedTitle;

  return (
    <LayoutRoot>
      <ToggleWrapper onClick={handleToggleCollapse}>
        <TextSmallPrimary> {title}</TextSmallPrimary>
        <ExpanderArrowWrapper isExpanded={isExpanded}>
          <ExpanderTopArrowIcon />
        </ExpanderArrowWrapper>
      </ToggleWrapper>
      {isExpanded && children}
    </LayoutRoot>
  );
};

const ToggleWrapper = styled(Gap8HorizontalFlexWrap)`
  cursor: pointer;
  margin-right: auto;
`;

const LayoutRoot = styled(Gap16VerticalFlex)``;

const ExpanderArrowWrapper = styled.div<{ isExpanded: boolean }>`
  margin-left: auto;
  transform: rotate(${({ isExpanded }) => (isExpanded ? 0 : 180)}deg);
  transition: 0.2s ease-out;

  & svg {
    width: 12px;
    height: 12px;
  }

  & path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const TextSmallPrimary = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.primary};
`;

export default ExpanderV2;
