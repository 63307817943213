import React from 'react';
import { useTheme } from 'styled-components';

const PlugIcon = ({ fill }) => {
  const theme = useTheme();

  return (
    <svg
      className="integrations"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 1.5C18 0.703125 17.2969 0 16.5 0C15.6562 0 15 0.703125 15 1.5V6H18V1.5ZM20.25 7.5H3.75C3.32812 7.5 3 7.875 3 8.25V9.75C3 10.1719 3.32812 10.5 3.75 10.5H4.5V12C4.5 15.5625 6.98438 18.6562 10.5 19.3594V24H13.5V19.3594C16.9688 18.6562 19.5 15.5625 19.5 12V10.5H20.25C20.625 10.5 21 10.1719 21 9.75V8.25C21 7.875 20.625 7.5 20.25 7.5ZM9 1.5C9 0.703125 8.29688 0 7.5 0C6.65625 0 6 0.703125 6 1.5V6H9V1.5Z"
        fill={fill || theme.colors.gray2}
      />
    </svg>
  );
};

export default PlugIcon;
