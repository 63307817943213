import React, { useState } from 'react';
import styled from 'styled-components';
import HorizontalFlex, {
  Gap8HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextTiny, TextSmall } from 'shared/react/components/basic/text/TextV2';
import ImageWithFallback from 'shared/react/images/ImageWithFallback';
import FilledTrashIcon from 'app/src/images/sidebar-v2/FilledTrashIcon';
import CrossedEyeIcon from 'app/src/images/CrossedEyeIcon';
import { ADD_PRODUCTS_MODAL_REMOVE_PRODUCT_DATA_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { GhostButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { useSelectedProductsContext } from 'app/src/complex_components/global_modal/video/add_products_modal/SelectedProductsContext';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';
import { DesignSizes } from 'shared/react/theme/DesignSystem';
import PlusIcon from 'app/src/images/dashboard_v2/PlusIcon';
import AddNewVariant from 'app/src/complex_components/global_modal/video/add_products_modal/components/select-variant/AddNewVariant';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragIcon from 'app/src/images/dashboard_v2/DragIcon';
import CircleExclamation from 'src/images/dashboard_v2/CircleExclamation';
import Tooltip from 'src/basic_components/tooltip-v2/Tooltip';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import { FEATURE_SHOW_PRODUCT_HANDLE } from 'app/src/constants/appFeatures.constants';
import { formatHandle } from 'app/src/utils/shopify.utils';

type Props = {
  product: any;
  variantId?: string;
  onRemove: (productId: string, variantId?: string) => void;
  allowSelectVariants: boolean;
};

const SelectedProduct = ({ product, variantId, onRemove, allowSelectVariants }: Props) => {
  const { getFeatureEnabled } = useFeatureActions();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [, { addVariant }] = useSelectedProductsContext();
  const { player } = useDesignSettingsContext();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: `${product.id}_${variantId}`,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: transform ? 'grabbing' : 'grab',
  };
  const isShowProductHandleEnabled = getFeatureEnabled(FEATURE_SHOW_PRODUCT_HANDLE);

  if (!product) {
    return null;
  }

  const isOpen = !!anchorEl;
  const { hideOOSProducts } = player.feedCards.productDetailsCard.stock;
  const {
    productId,
    imageUrl,
    title,
    isOutOfStock: isProductOutOfStock,
    variants,
    isEligibleOnShop,
    handle,
  } = product;
  const variant = allowSelectVariants
    ? variants?.find(variant => `${variant.id}` === `${variantId}`)
    : undefined;
  const isOutOfStock = variant ? !variant.isVariantAvailableForSale : isProductOutOfStock;
  const hideOutOfStockProducts = hideOOSProducts && isOutOfStock;
  const showVariantTitle = allowSelectVariants && !!variant;
  const showAddVariantButton = allowSelectVariants && variants?.length > 1;

  const toggleVariantPopup = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleAddVariant = variantId => {
    addVariant(product.id, `${variantId}`);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <LayoutRoot ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <IconContainer>
        <DragIcon />
      </IconContainer>
      <StyledImageWithFallback
        src={variant?.imageUrl || imageUrl}
        alt="product-image"
        isOutOfStock={isOutOfStock}
      />
      <ProductDetails>
        <ProductTitle className="notranslate" isLightColor={hideOutOfStockProducts}>
          {title}
        </ProductTitle>
        {isShowProductHandleEnabled && <ProductSubtitle>{formatHandle(handle)}</ProductSubtitle>}
        {showVariantTitle && <VariantTitle className="notranslate">{variant?.title}</VariantTitle>}
        {hideOutOfStockProducts && <OutOfStockLabel>Out of stock</OutOfStockLabel>}

        <ActionsContainer>
          {showAddVariantButton && (
            <AddVariantButton size={DesignSizes.XSMALL} onClick={toggleVariantPopup}>
              <PlusIcon />
              Add variant
            </AddVariantButton>
          )}
          <RemoveProductButton
            data-test-id={ADD_PRODUCTS_MODAL_REMOVE_PRODUCT_DATA_TEST_ID}
            onClick={() => onRemove(productId, variantId)}
            iconOnly
          >
            <FilledTrashIcon />
          </RemoveProductButton>
        </ActionsContainer>
      </ProductDetails>
      {hideOutOfStockProducts && (
        <NoDisplayContainer>
          <CrossedEyeIcon />
        </NoDisplayContainer>
      )}
      {allowSelectVariants && (
        <AddNewVariant
          product={product}
          open={isOpen}
          anchorEl={anchorEl}
          onDone={handleAddVariant}
          onClose={handleClose}
        />
      )}
      {isEligibleOnShop === false && (
        <TooltipContainer>
          <Tooltip text="The product is not eligible on Shop app">
            <CircleExclamation />
          </Tooltip>
        </TooltipContainer>
      )}
    </LayoutRoot>
  );
};

const ActionsContainer = styled(HorizontalFlex)`
  opacity: 0;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  flex-grow: 1;
  transition: 0.3s;
`;

const ProductSubtitle = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralDark};
`;

const LayoutRoot = styled(Gap8HorizontalFlex)`
  position: relative;
  padding: 8px 8px 8px 0;
  align-items: center;

  &:hover {
    padding-left: 8px;
    background: ${({ theme }) => theme.colors.neutralLightest};
    transition: 0.3s;

    & ${ActionsContainer} {
      opacity: 1;
    }

    & ${ProductSubtitle} {
      display: none;
    }
  }
`;

const IconContainer = styled(HorizontalFlex)`
  opacity: 0;
  ${LayoutRoot}:hover & {
    opacity: 1;
  }
`;

const StyledImageWithFallback = styled(ImageWithFallback)<{ isOutOfStock: boolean }>`
  width: 64px;
  height: 64px;
  border-radius: 2px;
  object-fit: cover;
  flex-shrink: 0;
  box-shadow: 0 0 3px rgba(50, 50, 93, 0.1);
  opacity: ${({ isOutOfStock }) => (isOutOfStock ? 0.5 : 1)};
`;

const ProductDetails = styled(VerticalFlex)`
  height: 100%;
  flex-grow: 1;
`;

const ProductTitle = styled(TextSmall)<{ isLightColor: boolean }>`
  padding-right: 16px;
  font-weight: 500;
  color: ${({ theme, isLightColor }) =>
    isLightColor ? theme.colors.neutralDark : theme.colors.neutralBlack};
`;

const VariantTitle = styled(TextTiny)`
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.neutralDark};
`;

const OutOfStockLabel = styled(TextTiny)`
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.dangerV2};
`;

const NoDisplayContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;

const AddVariantButton = styled(GhostButton)`
  margin-top: 8px;

  & svg path {
    fill: ${({ theme }) => theme.colors.neutralDarker};
  }
`;

const RemoveProductButton = styled(GhostButton)`
  margin-left: auto;

  &:hover {
    & path {
      fill: ${({ theme }) => theme.colors.dangerV2};
    }
  }
`;

const TooltipContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;

export default SelectedProduct;
