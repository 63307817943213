import React from 'react';
import { SHOPIFY_INSTALLATION_FAILED } from 'app/src/constants/intergrations.constants';
import ShopifyStoreModal from './ShopifyStoreModal';

const ShopifyInstallationFailedModal = () => {
  return (
    <ShopifyStoreModal
      title="Shopify installation failed"
      subtitle="There was an issue with the installation process of your Shopify store. Please try again in a few minutes"
      localStorageKey={SHOPIFY_INSTALLATION_FAILED}
    />
  );
};

export default ShopifyInstallationFailedModal;
