import React from 'react';
import styled from 'styled-components';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { FIELD_KEYS } from 'app/src/pages/modals/hubspot_mapping/hubspot_table/hubspotTable.constants';
import { TextSmall } from 'shared/react/components/complex/Text';

const HubspotHeaders = () => {
  return (
    <LayoutRoot>
      {FIELD_KEYS.map(({ label, width }) => {
        return (
          <Header width={width} key={label}>
            {label}
          </Header>
        );
      })}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)``;

const Header = styled(TextSmall)`
  border-right: 1px solid ${({ theme }) => theme.colors.gray3};
  padding: 16px;
  min-width: ${({ width }) => width}px;
  max-width: ${({ width }) => width}px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.gray31};

  &:last-of-type {
    border: none;
  }
`;

export default HubspotHeaders;
