import React from 'react';
import styled from 'styled-components';
import { useAnalytics } from 'app/src/context/AnalyticsStore';
import { track } from 'app/src/helpers/Tracker';
import AnalyticsFilter from 'app/src/complex_components/analytics/filters/AnalyticsFilter';
import { MenuItem } from '@material-ui/core';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { ATTRIBUTION_WINDOW_OPTIONS } from 'app/src/constants/analytics.constants';

const AnalyticsInteractionFilter = () => {
  const [{ interactionFilter: selectedDateFilter }, { setInteractionFilter }] = useAnalytics();

  const onDateFilterClick = dateFilter => {
    if (dateFilter === selectedDateFilter) {
      return;
    }

    track('Analytics Interaction Date Filter Click', {
      dateFilter,
    });

    setInteractionFilter(dateFilter);
  };

  const getSelectedValue = value => {
    return value;
  };

  return (
    <StyledAnalyticsDateFilter>
      <AnalyticsFilter
        value={selectedDateFilter}
        onChange={onDateFilterClick}
        getSelectedValue={getSelectedValue}
        label="Attribution window"
      >
        {Object.values(ATTRIBUTION_WINDOW_OPTIONS).map(dateFilter => (
          <MenuItem key={dateFilter} value={dateFilter}>
            {dateFilter}
          </MenuItem>
        ))}
      </AnalyticsFilter>
    </StyledAnalyticsDateFilter>
  );
};

const StyledAnalyticsDateFilter = styled(HorizontalFlex)`
  gap: 24px;
`;

export default AnalyticsInteractionFilter;
