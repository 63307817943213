import React from 'react';
import { getShouldContrastColor } from './color';
import AIcon from '../images/letters/AIcon';
import BIcon from '../images/letters/BIcon';
import CIcon from '../images/letters/CIcon';
import DIcon from '../images/letters/DIcon';
import EIcon from '../images/letters/EIcon';
import FIcon from '../images/letters/FIcon';
import GIcon from '../images/letters/GIcon';
import HIcon from '../images/letters/HIcon';
import IIcon from '../images/letters/IIcon';
import AudioIconV2 from '../images/AudioIconV2';
import CalendarIconV2 from '../images/CalendarIconV2';
import PromoCodeIconV2 from '../images/PromoCodeIconV2';
import ChatIconV2 from '../images/ChatIconV2';
import LinkIconV2 from '../images/LinkIconV2';
import TextIconV2 from '../images/TextIconV2';
import VideoIcon from '../images/VideoIconV2';
import ImageIconV2 from '../images/ImageIconV2';
import ContactFormIconV2 from 'shared/react/images/ContactFormIconV2';
import { URL_KEY } from 'shared/react/constants/generalKeys.constants';
import RightArrow from '../images/RightArrow';

const getIconProps = (brandColor, size, index) => {
  const darkBackground = getShouldContrastColor(brandColor);
  return { fill: brandColor, darkBackground, size, key: index };
};

export const getLetterIcon = (index, brandColor, size) => {
  const props = getIconProps(brandColor, size, index);

  switch (index) {
    case 0:
      return <AIcon {...props} />;
    case 1:
      return <BIcon {...props} />;
    case 2:
      return <CIcon {...props} />;
    case 3:
      return <DIcon {...props} />;
    case 4:
      return <EIcon {...props} />;
    case 5:
      return <FIcon {...props} />;
    case 6:
      return <GIcon {...props} />;
    case 7:
      return <HIcon {...props} />;
    case 8:
      return <IIcon {...props} />;
    default:
      return <RightArrow {...props} fill="white" />;
  }
};

const getNextLogoIcon = (next, props) => {
  if (next === URL_KEY) {
    return <LinkIconV2 {...props} />;
  }

  if (next === 'Chat') {
    return <ChatIconV2 {...props} />;
  }
  return '';
};

export const getLogoIcon = (type, brandColor, next) => {
  const props = getIconProps(brandColor);

  switch (type.toLowerCase()) {
    case 'audio':
      return <AudioIconV2 {...props} />;
    case 'calendly':
      return <CalendarIconV2 {...props} />;
    case 'promocode':
      return <PromoCodeIconV2 {...props} />;
    case 'chat':
      return <ChatIconV2 {...props} />;
    case 'link':
      return <LinkIconV2 {...props} />;
    case 'input':
      return <TextIconV2 {...props} />;
    case 'video':
      return <VideoIcon {...props} />;
    case 'image':
      return <ImageIconV2 {...props} />;
    case 'multiinput':
      return <ContactFormIconV2 {...props} />;
    default:
      return getNextLogoIcon(next, props);
  }
};
