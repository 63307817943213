import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { DEFAULT_NUMBER_OF_CARDS_TO_SHOW } from 'src/constants/ui.constants';

export const useInfiniteScroll = (ref, fetchMore) => {
  const isFetching = useRef(false);
  const done = useRef(false);
  const [loading, setLoading] = useState(false);

  useLayoutEffect(() => {
    const fetchMoreItems = async () => {
      if (isFetching.current || done.current) {
        return;
      }

      const scrollTop = ref.current.scrollTop;
      const scrollHeight = ref.current.scrollHeight;
      const offsetHeight = ref.current.offsetHeight;
      const contentHeight = scrollHeight - offsetHeight;
      if (contentHeight - 1 > scrollTop || !contentHeight) {
        return;
      }

      setLoading(true);
      isFetching.current = true;

      try {
        await fetchMore();
      } finally {
        setLoading(false);
        isFetching.current = false;
      }
    };

    if (!ref.current) {
      return;
    }

    ref.current.addEventListener('scroll', fetchMoreItems);
    return () => {
      ref.current?.removeEventListener('scroll', fetchMoreItems);
    };
  }, [ref.current, fetchMore, isFetching.current]);

  const setDone = (doneValue = true) => (done.current = doneValue);

  return {
    loadingInfiniteScroll: loading,
    setDoneScroll: setDone,
    isDone: done.current,
  };
};

export const useVirtualizedList = (containerRef, totalCount, onScrollEndCallback, filters) => {
  const [renderCount, setRenderCount] = useState(DEFAULT_NUMBER_OF_CARDS_TO_SHOW);

  const onScrollEnd = async () => {
    if (onScrollEndCallback) {
      await onScrollEndCallback();
      setRenderCount(value => value + DEFAULT_NUMBER_OF_CARDS_TO_SHOW);
      return;
    }

    if (renderCount >= totalCount) {
      setDoneScroll();
      return;
    }

    setRenderCount(value => value + DEFAULT_NUMBER_OF_CARDS_TO_SHOW);
  };

  const { setDoneScroll, loadingInfiniteScroll, isDone } = useInfiniteScroll(
    containerRef,
    onScrollEnd
  );

  useEffect(() => {
    setDoneScroll(false);
    setRenderCount(DEFAULT_NUMBER_OF_CARDS_TO_SHOW);
    if (containerRef.current) {
      containerRef.current.scrollTo(0, 0);
    }
  }, [filters]);

  return {
    renderCount,
    isLoading: loadingInfiniteScroll,
    isDone,
  };
};
