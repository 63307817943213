import useAnalyticsOverview from 'app/src/hooks/useAnalyticsOverview';
import React from 'react';
import { PrimaryButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { TextH1, TextH6, TextSmall } from 'shared/react/components/basic/text/TextV2';
import VerticalFlex, {
  VerticalFlexCentered,
} from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { DesignSizes } from 'shared/react/theme/DesignSystem';
import { OFFICE_PETS_VIDEO_URLS } from 'src/constants/videoUrls.constants';
import { track } from 'src/helpers/Tracker';
import useNavigation from 'src/hooks/useNavigation';
import styled from 'styled-components';
import VideoSpotlight from '../VideoSpotlight';

const AnalyticsEmptyState = () => {
  const { shouldShowEmptyState } = useAnalyticsOverview();
  const { navigateToHomepage } = useNavigation();

  if (!shouldShowEmptyState) {
    return null;
  }

  const handleGetStartedClick = () => {
    track('Analytics Empty State Get Started Click');
    navigateToHomepage();
  };

  return (
    <LayoutRoot>
      <VideoContainer>
        <VideoSpotlight videoSrc={OFFICE_PETS_VIDEO_URLS.menasheJumping} videoSubheader="Menashe" />
        <ContentContainer>
          <TextsContainer>
            <TextH1>Don't keep him waiting</TextH1>
            <TextH6>
              To start collecting video analytics, you'll first need to publish your first Tolstoy
            </TextH6>
          </TextsContainer>
          <GetStartedButton onClick={handleGetStartedClick}>Get started</GetStartedButton>
        </ContentContainer>
      </VideoContainer>
      <BottomTooltipContainer>
        <StyledTextSmall>
          Pro tip: Don't worry if your first Tolstoy isn't perfect. The main thing is just to get it
          live, so you can start collecting analytics. You can always edit it later.
        </StyledTextSmall>
      </BottomTooltipContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  position: absolute;
  inset: 0;
  z-index: 1;
  backdrop-filter: blur(8px);
  background-color: ${({ theme }) => theme.getHexOpacity(theme.colors.white, 25)};
  gap: 32px;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  animation: fadein 0.5s;
`;

const VideoContainer = styled(VerticalFlexCentered)`
  gap: 12px;
  height: 100%;
`;

const ContentContainer = styled(VerticalFlexCentered)`
  gap: 32px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  padding: 8px 8px 24px 8px;
`;

const TextsContainer = styled(VerticalFlexCentered)`
  gap: 16px;
  max-width: 416px;
  text-align: center;
`;

const BottomTooltipContainer = styled(VerticalFlexCentered)`
  padding: 16px 8px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const StyledTextSmall = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.neutralDark};
`;

const GetStartedButton = styled(PrimaryButton).attrs({
  size: DesignSizes.NORMAL,
})``;

export default AnalyticsEmptyState;
