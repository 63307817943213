import React from 'react';
import styled from 'styled-components';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { DOWN, UP } from 'shared/react/components/complex/carousel/carousel.constants';
import NavigationArrowButton from 'shared/react/components/complex/feed/feed_overlay/NavigationArrowButton';
import useTracker from 'shared/react/components/complex/context/hooks/useTracker';
import useKeyboardNavigation from 'player/src/hooks/useKeyboardNavigation';
import { FEED_NAVIGATION_ARROWS_DATA_TYPE } from 'shared/react/constants/feedPreviewDataTypes.constants';

const FeedNavigationArrows = ({ handleNavigateToStep }) => {
  const [, { track }] = useTracker();
  const { resetRefs } = useKeyboardNavigation();

  const handleArrowClick = (e, direction) => {
    e.stopPropagation();
    track('feedNavigationArrowClick', { direction });
    handleNavigateToStep(direction);
    resetRefs();
  };

  const NAVIGATION_ARROWS = [
    {
      direction: UP,
      onClick: e => handleArrowClick(e, UP),
    },
    {
      direction: DOWN,
      onClick: e => handleArrowClick(e, DOWN),
    },
  ];

  return (
    <LayoutRoot>
      <NavigationArrowsContainer data-type={FEED_NAVIGATION_ARROWS_DATA_TYPE}>
        {NAVIGATION_ARROWS.map(({ direction, onClick }) => (
          <NavigationArrowButton key={direction} onClick={onClick} direction={direction} />
        ))}
      </NavigationArrowsContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  justify-content: center;
  z-index: 12;

  svg {
    width: 18px;
    height: 18px;
  }
`;

const NavigationArrowsContainer = styled(Gap8VerticalFlex)`
  margin: 8px;
  box-sizing: border-box;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    gap: 8px;
  }
`;

export default FeedNavigationArrows;
