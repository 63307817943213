import React from 'react';

const SortIconV2 = props => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.578 9.375 5.29 10.758V2.836c0-.328-.234-.586-.562-.586a.576.576 0 0 0-.563.586v7.922L2.898 9.375a.549.549 0 0 0-.398-.164.636.636 0 0 0-.398.14c-.235.211-.235.563-.024.797l2.25 2.438c.211.234.61.234.82 0l2.25-2.438c.211-.234.211-.585-.023-.796a.58.58 0 0 0-.797.023Zm5.32-4.5-2.25-2.438a.566.566 0 0 0-.82 0l-2.25 2.438c-.21.234-.21.586.024.797.117.094.257.14.398.14.14 0 .281-.046.398-.164l1.266-1.382v7.921c0 .329.258.563.563.563a.542.542 0 0 0 .562-.563V4.266l1.29 1.382a.58.58 0 0 0 .796.024c.234-.211.234-.563.023-.797Z"
      fill="#50545E"
    />
  </svg>
);

export default SortIconV2;
