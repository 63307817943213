export const EMAIL_KEY = 'email';
export const ROLE_KEY = 'role';
export const STATUS_KEY = 'status';
export const DATE_ADDED_KEY = 'createdAt';
export const ACTIONS_KEY = 'actions';
export const PAYMENT_ROLE_KEY = 'paymentRole';

export const STATUS_ACCEPTED = 'accepted';
export const STATUS_PENDING = 'pending';

export const PAYMENT_ROLE_LITE_KEY = 'lite';
export const PAYMENT_ROLE_LITE_TEXT = 'Free';
export const PAYMENT_ROLE_PRO_KEY = 'pro';
export const PAYMENT_ROLE_PREMIUM_KEY = 'Premium';
export const TEAM_MEMBERS_ROLES = { admin: 'admin', owner: 'owner' };
