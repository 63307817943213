import React from 'react';
import styled from 'styled-components';
import { CaseGuide } from './cases.constants';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import { TextBody } from 'shared/react/components/basic/text/TextV2';
import { WhiteButton } from 'shared/react/components/basic/button-v2/BasicButton';
import Utils from 'src/utils';
import GuideIcon from './images/icons/GuideIcon';
import { track } from 'src/helpers/Tracker';

type Props = {
  guide: CaseGuide;
};

const CasesGuide = ({ guide }: Props) => {
  if (!guide) {
    return null;
  }

  const onClick = () => {
    track('Use Case Guide Clicked', { guide: guide.key });
    Utils.openInNewTab(guide.url);
  };

  const { header } = guide;
  return (
    <LayoutRoot>
      <TextBody>{header}</TextBody>
      <WhiteButton onClick={onClick}>
        <GuideIcon />
        Guide
      </WhiteButton>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)`
  align-items: center;
  justify-content: center;
`;

export default CasesGuide;
