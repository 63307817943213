import React from 'react';
import styled from 'styled-components';
import SocialShare from 'app/src/pages/share/link/SocialShare';
import LinkPreview from 'app/src/pages/share/link/LinkPreview';
import ShopifySmsCampaign from 'app/src/pages/share/link/ShopifySmsCampaign';

function ShareViaLink({ project, isShopify, gifUrl }) {
  return (
    <SocialContainer isShopify={isShopify}>
      <CustomSocialShare includeHeader project={project} />
      <LinkPreviewContainer isShopify={isShopify} gifUrl={gifUrl} />
    </SocialContainer>
  );
}

const LinkPreviewContainer = ({ isShopify, gifUrl }) => {
  if (isShopify) {
    return <ShopifySmsCampaign />;
  }

  return <LinkPreview gifUrl={gifUrl} />;
};

export default ShareViaLink;

const SocialContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 44px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 100%;
    gap: 0px;
  }
`;

const CustomSocialShare = styled(SocialShare)`
  padding: 0;
`;
