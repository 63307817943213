import React from 'react';

const KlaviyoIcon = props => (
  <svg
    width={24}
    height={18}
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M24 17.035H0V.965h24L18.974 9 24 17.035Z" fill="#000" />
  </svg>
);

export default KlaviyoIcon;
