import EventSubscriber from '../../common/EventSubscriber.js';
import { generateUUID } from '../../utils/utils.js';

class InternalMessaging extends EventSubscriber {
  constructor() {
    if (InternalMessaging.instance) {
      // eslint-disable-next-line no-constructor-return
      return InternalMessaging.instance;
    }

    super({ shouldArchivePastEvents: true });

    InternalMessaging.instance = this;
  }

  eventHandler = event => {
    const { data = {} } = event;
    const { eventName } = data;

    if (!this.eventCallbacks[eventName]) {
      return;
    }

    for (const callback of this.eventCallbacks[eventName]) {
      callback(event);
    }
  };

  postMessage = message => {
    const transmissionId = message.transmissionId || generateUUID();

    super.postMessage({
      ...message,
      transmissionId
    });

    return transmissionId;
  };

  init = () => {
    this.initListener(this.eventHandler);
  };
}

const instance = new InternalMessaging();
Object.freeze(instance);
export default instance;
