import React from 'react';
import ChartTooltipLineGroup from './ChartTooltipLineGroup';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import styled from 'styled-components';
import { LineGroup } from './ChartTooltip.types';
import { TextSmall } from 'shared/react/components/basic/text/TextV2';

type Props = {
  label: string;
  lineGroups: LineGroup[];
};

const ChartTooltip = ({ label, lineGroups }: Props) => {
  if (!lineGroups?.length) {
    return null;
  }

  const lines = lineGroups.map(({ key, ...rest }) => {
    if (rest.hidden) {
      return null;
    }

    return <ChartTooltipLineGroup key={key} {...rest} />;
  });

  return (
    <LayoutRoot>
      <Label>{label}</Label>
      <Gap16VerticalFlex>{lines}</Gap16VerticalFlex>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)`
  background: ${({ theme }) => theme.colors.neutralBlack};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutralDarker};
  box-shadow:
    0 1px 3px -1px rgba(0, 0, 0, 0.05),
    0 2px 10px 0 rgba(50, 50, 93, 0.1);
  padding: 8px 8px 12px 8px;
  width: 238px;
`;

const Label = styled(TextSmall)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutralDarker};
  color: ${({ theme }) => theme.colors.neutralLight};
  padding: 8px 0;
`;

export default ChartTooltip;
