import React, { useState } from 'react';
import StandardModal from 'app/src/pages/modals/StandardModal';
import { useProjects } from 'app/src/context/ProjectsStore';
import { useHistory } from 'react-router-dom';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { TextH5 } from 'shared/react/components/complex/Text';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';
import Utils from '../../../utils';
import ExportPhases from './ExportPhases';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import ModalContent from './ModalContent';
import styled from 'styled-components';
import NavigationButtons from './NavigationButtons';
import { cloneDeep } from 'lodash';
import { useIsMobile } from 'shared/react/hooks/useIsMobile';
import { track } from 'app/src/helpers/Tracker';

const EXPORT_TO_MODAL_PHASE_COUNT = 3;
const STORE_PLACEHOLDER_TEXT = 'Choose Store';

const ExportToStoreModal = () => {
  const [{ currentProjectId }, { setCurrentModal, clearModalState }] = useModal();
  const [{ project }, { finishExportProjectToStore, updateProject }] = useProjects({
    projectId: currentProjectId,
  });
  const history = useHistory();
  const [, { setSnackbar, setErrorSnackbar }] = useSnackBar();
  const [editableProject] = useState(cloneDeep(project));
  const [lastReviewedStep, setLastReviewedStep] = useState(-1);
  const [activeIndex, setActiveIndex] = useState(1);
  const [stepIndex, setStepIndex] = useState(0);
  const [ruleGroup, setRuleGroup] = useState(null);
  const [selectedStore, setSelectedStore] = useState(STORE_PLACEHOLDER_TEXT);
  const isMobile = useIsMobile();

  const handleModalClose = () => {
    setCurrentModal('');
    clearModalState();
    history.replace({
      pathname: window.location.pathname,
    });
  };

  const onDoneClick = async () => {
    const exportProjectSuccessMessage = 'Export Successfully';
    const exportProjectFailMessage = 'Export Failed';
    let publishId;
    try {
      [publishId] = await Promise.all([
        finishExportProjectToStore(editableProject, ruleGroup),
        updateProject({ ...project, appUrl: selectedStore.id }),
      ]);
    } catch (err) {
      Utils.logError('Failed to save modification of the export process', err);
      setErrorSnackbar(exportProjectFailMessage);
      return;
    }

    if (!publishId) {
      console.log('finish Export Project To Store failed', { publishId, editableProject });
      setErrorSnackbar(exportProjectFailMessage);
      return;
    }

    track('Export to store completed successfully', { publishId });
    setSnackbar(exportProjectSuccessMessage);
  };

  const setStepReviewed = stepIndex => {
    setLastReviewedStep(Math.max(stepIndex, lastReviewedStep));
  };

  const getModalHeader = () => {
    switch (activeIndex) {
      case 1:
        if (isMobile) {
          return 'Export Tolstoy';
        }
        return 'Export Tolstoy to another store';
      case 2:
        return 'Change button links';
      case 3:
        return 'Change rules';
    }
  };

  return (
    <StandardModal
      open={true}
      maxWidth="980px"
      onClose={handleModalClose}
      isFixedHeight
      removeDefaultPadding
    >
      <ModalLayout>
        <TextH5>{getModalHeader()}</TextH5>
        <ExportPhases activeIndex={activeIndex} lastIndex={EXPORT_TO_MODAL_PHASE_COUNT - 1} />
        <ModalContent
          activeIndex={activeIndex}
          stepIndex={stepIndex}
          lastReviewedStep={lastReviewedStep}
          project={editableProject}
          ruleGroup={ruleGroup}
          setRuleGroup={setRuleGroup}
          selectedStore={selectedStore}
          setSelectedStore={setSelectedStore}
        />
      </ModalLayout>
      <NavigationButtons
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        stepIndex={stepIndex}
        setStepIndex={setStepIndex}
        reviewLastStep={
          !editableProject?.steps?.items.length ||
          stepIndex === editableProject?.steps?.items.length - 1
        }
        handleModalClose={handleModalClose}
        setStepReviewed={setStepReviewed}
        maxIndex={EXPORT_TO_MODAL_PHASE_COUNT}
        onSave={onDoneClick}
        isStoreSelected={!!selectedStore}
      />
    </StandardModal>
  );
};

export default ExportToStoreModal;

const ModalLayout = styled(VerticalFlex)`
  margin-top: 40px;
  align-items: center;
  gap: 48px;
  width: 100%;
  height: 100%;
  min-height: 460px;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    gap: 24px;
  }
`;
