import React from 'react';
import { Label, Input } from '@tolstoy/ui-library/core';

import { SpotlightDeviceSettings } from 'src/pages/project/pages/look_and_feel/types';
import { EditorItem } from 'src/pages/project/pages/look_and_feel/editor/editors/shared/ui-library';

import { SpotlightDimensionEditor } from './SpotlightDimensionEditor';

interface FeedSpotlightResponsiveEditorProps {
  config: SpotlightDeviceSettings;
  onUpdate: (value: Partial<SpotlightDeviceSettings>) => void;
}

export const FeedSpotlightResponsiveEditor = ({ config, onUpdate }: FeedSpotlightResponsiveEditorProps) => {
  const { item } = config || {};

  const onPaddingUpdate = (x: number, y: number) => {
    onUpdate({
      item: {
        paddingX: Math.round(x / 2),
        paddingY: Math.round(y / 2),
      },
    });
  };

  const onTilesAmountChange = e => {
    onUpdate({
      tilesAmount: parseInt(e.target.value) || 0,
    });
  };

  const onPeekWidthChange = e => {
    onUpdate({
      peekWidth: parseInt(e.target.value) || 0,
    });
  };

  const onAspectRationWChange = e => {
    onUpdate({
      aspectRatioW: parseFloat(e.target.value) || 0,
    });
  }

  const onAspectRationHChange = e => {
    onUpdate({
      aspectRatioH: parseFloat(e.target.value) || 0,
    });
  }

  return (
    <div className="flex flex-col gap-4">
      <EditorItem label="Items per row" labelClassName="text-gray-700 font-medium">
        <Input
          className="flex-1 max-w-24"
          value={config.tilesAmount}
          onChange={onTilesAmountChange}
        />
      </EditorItem>
      <EditorItem label="Aspect ratio" labelClassName="text-gray-700 font-medium">
        <div className="flex gap-2">
          <Input
            className="flex-1 max-w-11"
            placeholder="Width"
            value={config.aspectRatioW}
            onChange={onAspectRationWChange}
          />
          <Input
            className="flex-1 max-w-11"
            placeholder="Height"
            value={config.aspectRatioH}
            onChange={onAspectRationHChange}
          />
        </div>
      </EditorItem>
      <EditorItem label="Card peek width" labelClassName="text-gray-700 font-medium">
        <Input
          className="flex-1 max-w-24"
          value={config.peekWidth}
          onChange={onPeekWidthChange}
        />
      </EditorItem>
      <Label className="text-sm font-semibold text-gray-900">
        Tile spacing
      </Label>
      <SpotlightDimensionEditor
        x={item.paddingX * 2}
        labelX="Spacing between tiles"
        onUpdate={onPaddingUpdate}
        hideY
      />
    </div>
  );
};
