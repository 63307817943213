import { useProjectConfigContext } from 'shared/react/components/complex/context/ProjectConfigContext';
import { getIsSingleStep } from 'shared/react/components/complex/feed/feed_utils/feed.utils';

const useProjectConfig = () => {
  const projectConfig = useProjectConfigContext();
  if (!projectConfig) {
    return [{}, {}];
  }
  const { steps, stepsMap } = projectConfig;

  const actions = {
    getIsSingleStepFeed: () => getIsSingleStep(steps),
    getCurrentStep: currentStepKey => {
      const { current: currentStep } = stepsMap[currentStepKey] || {};
      return currentStep;
    },
    getProductActionButtonData: currentStepKey => {
      const currentStep = actions.getCurrentStep(currentStepKey);
      return currentStep.feedProductActionButton;
    },
  };

  return [projectConfig, actions];
};

export default useProjectConfig;
