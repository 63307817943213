import React from 'react';
import useAccountOnboardingTemplates from './hooks/useAccountOnboardingTemplates';
import { useOnboarding } from 'src/context/ui_store/OnboardingStore';
import Types from 'shared/react/theme/Types';
import { GhostButton, PrimaryButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { DesignSizes } from 'shared/react/theme/DesignSystem';
import StraightArrowRightIcon from 'src/images/creation_method_icons/StraightArrowRightIcon';

const OnboardingFooterNextButton = () => {
  const [{ isTemplatesNextStepBlocked }] = useOnboarding();
  const { getNextButtonProps, handleNextButtonClick } = useAccountOnboardingTemplates();
  const { text, type } = getNextButtonProps();

  if (type === Types.Ghost) {
    return (
      <GhostButton
        size={DesignSizes.NORMAL}
        onClick={handleNextButtonClick}
        disabled={isTemplatesNextStepBlocked}
      >
        {text}
      </GhostButton>
    );
  }

  return (
    <PrimaryButton
      size={DesignSizes.NORMAL}
      onClick={handleNextButtonClick}
      disabled={isTemplatesNextStepBlocked}
    >
      {text}
      <StraightArrowRightIcon />
    </PrimaryButton>
  );
};

export default OnboardingFooterNextButton;
