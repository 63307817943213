import React, { FC } from 'react';

type Props = {
  className?: string;
  width?: number;
  height?: number;
};

const ShopAppIcon: FC<Props> = props => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.538 13.489C18.7 8.489 29.018 5 40.01 5c29.303 0 50.614 19.83 50.614 46.995 0 25.325-18.315 42.99-43.791 42.99-21.811 0-37.459-14.662-37.459-34.327 0-13.332 8.01-23.165 19.318-26.994l9.155 15.496c-6.158 2.83-8.48 6.999-8.48 12.163 0 8.488 7.161 14.502 17.48 14.502 12.656 0 22.645-10.003 22.645-23.5 0-15.832-12.487-27.825-29.468-27.825A34.03 34.03 0 0 0 21.211 30l-9.673-16.511Z"
      fill="#090A0B"
    />
  </svg>
);

export default ShopAppIcon;
