import {
  JPG_AVATAR_EXTENSION,
  JPG_EXTENSION,
  WEBP_AVATAR_EXTENSION
} from '../../../constants/extensions.js';
import { getAppKey } from '../../../common/appKey.js';
import { getHexOpacity } from '../../../common/color.js';
import { leftArrowSvg } from '../../../common/svgs.js';
import { createElement } from '../../../utils/dom.utils.js';
import { getPublicId } from '../embedWidgets.utils.js';
import style from './story.module.css';
import {
  DEFAULT_STORIES_SIZE,
  EMBED_STORY_MOTION,
  PUBLIC_CLASSES,
  STORIES_ITEMS_SIZE_TYPES
} from './storyConstants.js';

const THRIVE_APPKEY = '56bfe6cb-0926-420a-ac48-4d36a3a2ea07';

export const getBorderCss = (
  {
    storiesBorderRadius,
    storiesBorderEnabled,
    storiesBorderWidth,
    storiesBorderColor,
    storiesBorderType,
    storiesBorderOpacity
  },
  { tileSize: storiesCircleSize = DEFAULT_STORIES_SIZE }
) => {
  const proportionalBorderRadius = storiesBorderRadius * (storiesCircleSize / DEFAULT_STORIES_SIZE);
  const borderRadiusCss = `border-radius: ${proportionalBorderRadius}px;`;

  if (!storiesBorderEnabled) {
    return borderRadiusCss;
  }

  const borderColor = getHexOpacity(storiesBorderColor, storiesBorderOpacity);

  return `
		${borderRadiusCss}
		border: ${storiesBorderWidth}px ${storiesBorderType} ${borderColor};
    padding: 2px;
	`;
};

export const getStoryNameCss = ({
  storiesStoryNameEnabled,
  storiesStoryNameFontSize,
  storiesStoryNameColor
}) => {
  if (!storiesStoryNameEnabled) {
    return 'display: none;';
  }

  return `
		font-size: ${storiesStoryNameFontSize}px;
		color: ${storiesStoryNameColor};
	`;
};

export const getAspectRatio = ({
  storiesRectangleWidth,
  storiesRectangleHeight,
  isCircle,
  isResponsive
}) => {
  if (isCircle) {
    return '1';
  }

  if (isResponsive) {
    return '9/16';
  }

  return `${storiesRectangleWidth}/${storiesRectangleHeight}`;
};

export const getTilesAlignmentCss = ({ storiesAlignment }) => {
  switch (storiesAlignment) {
    case 'left':
      return 'justify-content: flex-start; justify-items: flex-start';

    case 'right':
      return 'justify-content: flex-end; justify-items: flex-end';

    case 'center':
      return 'justify-content: center; justify-items: center';

    default:
      return 'justify-content: flex-start; justify-items: flex-start;';
  }
};

const getTilesGap = (
  { storiesItemsSizeType, storiesItemsSpacing },
  { numberOfTiles, tileSize },
  parentWidth
) => {
  if (!storiesItemsSizeType) {
    return 8;
  }

  if (storiesItemsSizeType === STORIES_ITEMS_SIZE_TYPES.fixed) {
    return storiesItemsSpacing;
  }

  const circlesTotalWidth = numberOfTiles * tileSize;
  const spacingsTotalWidth = (numberOfTiles - 1) * storiesItemsSpacing;
  const totalWidth = circlesTotalWidth + spacingsTotalWidth;

  if (totalWidth <= parentWidth) {
    return storiesItemsSpacing;
  }

  return parentWidth / numberOfTiles - tileSize;
};

export const getTilesContainerCss = (storiesEmbed, tilesData, parentWidth) => {
  const tilesGap = getTilesGap(storiesEmbed, tilesData, parentWidth);
  return `gap: ${tilesGap}px;`;
};

export const getImageHtml = ({
  imageUrl,
  key,
  borderCss,
  publishId,
  storiesCircleSize,
  partName,
  stepIndex,
  storiesRectangleWidth,
  storiesRectangleHeight,
  isCircle,
  isResponsive
}) => {
  const classNames = [style.storyImage, PUBLIC_CLASSES.storyImage];

  const attributes = {
    alt: partName || `Story video ${stepIndex + 1} preview`,
    'aria-label': `Tolstoy stories : ${partName}`,
    'data-tolstoy-element': `${getPublicId(PUBLIC_CLASSES.storyImage, publishId)}_${key}`,
    loading: 'lazy'
  };

  const styleAttributes = getTileSize({
    storiesCircleSize,
    isCircle,
    isResponsive,
    storiesRectangleWidth,
    storiesRectangleHeight
  });

  function onImageError(event) {
    const image = event.currentTarget;
    if (image.src.includes(WEBP_AVATAR_EXTENSION)) {
      image.src = image.src.replace(WEBP_AVATAR_EXTENSION, JPG_AVATAR_EXTENSION);
    } else if (image.src.includes(JPG_AVATAR_EXTENSION)) {
      image.src = image.src.replace(JPG_AVATAR_EXTENSION, JPG_EXTENSION);
    }
  }

  const element = createElement({
    tagName: 'img',
    classNames,
    attributes,
    styleString: borderCss,
    style: styleAttributes,
    src: imageUrl,
    eventListeners: {
      error: onImageError
    }
  });

  return element.outerHTML;
};

const getTileSize = ({
  storiesCircleSize,
  isCircle,
  isResponsive,
  storiesRectangleWidth,
  storiesRectangleHeight
}) => {
  if (isCircle) {
    return { height: `${storiesCircleSize}px`, width: `${storiesCircleSize}px` };
  }

  if (isResponsive) {
    return { 'aspect-ratio': '9/16', height: '100%' };
  }

  return {
    width: `${storiesRectangleWidth}px`,
    height: `${storiesRectangleHeight}px`,
    'aspect-ratio': `${storiesRectangleWidth}/${storiesRectangleHeight}`
  };
};

export const getVideoHtml = ({
  videoUrl,
  posterUrl,
  borderCss,
  publishId,
  storiesCircleSize,
  partName,
  isCircle,
  isResponsive,
  storiesRectangleWidth,
  storiesRectangleHeight
}) => {
  const classNames = [style.storyVideo, PUBLIC_CLASSES.storyVideo];

  const attributes = {
    playsinline: '',
    muted: '',
    poster: posterUrl,
    'aria-label': `Tolstoy stories : ${partName}`,
    'data-tolstoy-element': getPublicId(PUBLIC_CLASSES.storyVideo, publishId)
  };

  const styleAttributes = getTileSize({
    storiesCircleSize,
    isCircle,
    isResponsive,
    storiesRectangleWidth,
    storiesRectangleHeight
  });

  const element = createElement({
    tagName: 'video',
    src: videoUrl,
    classNames,
    attributes,
    styleString: borderCss,
    style: styleAttributes
  });

  return element.outerHTML;
};

export const getPreviousButton = publishId => {
  return `<button
    class="${style.arrowButtonContainer} ${PUBLIC_CLASSES.previousButton}"
    data-tolstoy-element="${getPublicId(PUBLIC_CLASSES.previousButton, publishId)}"
    data-visibility="hidden"
    aria-label="Tolstoy stories: previous button"
    type="button"
    style="left: 0;${getAppKey() === THRIVE_APPKEY ? 'display: flex !important;' : ''}"
  >
    ${leftArrowSvg}
  </button>`;
};

export const getNextButton = publishId => {
  const className = `${style.arrowButtonContainer} ${style.nextArrowButtonContainer} ${PUBLIC_CLASSES.nextButton}`;
  return `<button
    class="${className}"
    data-tolstoy-element="${getPublicId(PUBLIC_CLASSES.nextButton, publishId)}"
    aria-label="Tolstoy stories: next button"
    type="button"
    style="right: 0;${getAppKey() === THRIVE_APPKEY ? 'display: flex !important;' : ''}"
  >
    ${leftArrowSvg}
</button>`;
};

const getDynamicTileHtml = ({
  storyNameHtml,
  publishId,
  key,
  videoHtml,
  maxWidth,
  ariaLabel,
  storiesRectangleWidth,
  storiesRectangleHeight,
  isCircle,
  isResponsive
}) => {
  const classNames = [style.storyTile, PUBLIC_CLASSES.storyTile];

  const attributes = {
    tabindex: 0,
    'aria-label': `Open ${ariaLabel}`,
    'data-tolstoy-element': `${getPublicId(PUBLIC_CLASSES.storyTile, publishId)}_${key}`,
    role: 'button'
  };

  const styleAttributes = {
    'max-width': maxWidth,
    'aspect-ratio': getAspectRatio({
      isCircle,
      isResponsive,
      storiesRectangleWidth,
      storiesRectangleHeight
    })
  };

  const tileElementCss = {
    'max-width': maxWidth
  };

  const tileElement = createElement({
    tagName: 'div',
    classNames,
    attributes,
    style: tileElementCss
  });

  const storyVideoImageElement = createElement({
    tagName: 'div',
    classNames: [style.storyVideoImage],
    style: styleAttributes
  });

  storyVideoImageElement.innerHTML = videoHtml;

  const overElement = createElement({
    tagName: 'div',
    classNames: [style.storyVideo],
    styleString: 'background:transparent;display:flex;width:100%;height:100%;',
    style: styleAttributes
  });

  tileElement.innerHTML = storyVideoImageElement.outerHTML + storyNameHtml + overElement.outerHTML;

  return tileElement.outerHTML;
};

const getMaxWidth = (isCircle, storiesCircleSize, storiesRectangleWidth, isResponsive) => {
  if (!isResponsive && !isCircle) {
    return storiesRectangleWidth;
  }

  return storiesCircleSize;
};

export const getTile = ({
  imageHtml,
  storyNameHtml,
  publishId,
  key,
  skeleton,
  videoHtml,
  storiesMotion,
  storiesCircleSize,
  partName,
  storiesRectangleWidth,
  storiesRectangleHeight,
  isCircle,
  isResponsive
}) => {
  const ariaLabel = `${partName || 'Story video'}`;
  const maxWidth = `${getMaxWidth(
    isCircle,
    storiesCircleSize,
    storiesRectangleWidth,
    isResponsive
  )}px`;

  if (skeleton) {
    return `
		<div class="${style.storyTile} ${PUBLIC_CLASSES.storyTile} ${style.storySkeletonTile}"
			data-tolstoy-element="${getPublicId(PUBLIC_CLASSES.storyTile, publishId)}_${key}"
			style="max-width: ${maxWidth};"
		aria-label="Tolstoy stories skeleton"
		>
		<div class="${style.skeletonContent}"></div>
		</div>
	`;
  }

  if (!storiesMotion || storiesMotion === EMBED_STORY_MOTION.dynamic) {
    return getDynamicTileHtml({
      storyNameHtml,
      publishId,
      key,
      videoHtml,
      storiesCircleSize,
      ariaLabel,
      maxWidth,
      storiesRectangleWidth,
      storiesRectangleHeight,
      isCircle,
      isResponsive
    });
  }

  const classNames = [style.storyTile, PUBLIC_CLASSES.storyTile];

  const attributes = {
    tabindex: 0,
    'aria-label': `Open ${ariaLabel}`,
    'data-tolstoy-element': `${getPublicId(PUBLIC_CLASSES.storyTile, publishId)}_${key}`,
    role: 'button'
  };

  const styleAttributes = {
    'max-width': `${maxWidth}`,
    'aspect-ratio': getAspectRatio({
      isCircle,
      isResponsive,
      storiesRectangleWidth,
      storiesRectangleHeight
    })
  };

  const tileElement = createElement({
    tagName: 'div',
    classNames,
    attributes,
    style: styleAttributes
  });

  tileElement.innerHTML = imageHtml + storyNameHtml;

  return tileElement.outerHTML;
};
