import { FEATURE_ACCOUNT_TRACKING } from 'shared/react/constants/features.constants';
import { useApps } from 'src/context/AppsStore';
import { useFeatureActions } from 'src/context/FeaturesStore';
import Utils from 'src/utils';

const useGoogleAnalytics = (projectGoogleAnalyticsId?: string) => {
  const { getSettingsByKey } = useFeatureActions();
  const [{ selectedApp }] = useApps();

  const accountTrackingSetting = getSettingsByKey(FEATURE_ACCOUNT_TRACKING);
  const accountGoogleAnalyticsId = accountTrackingSetting?.googleAnalyticsID;
  const appGoogleAnalyticsId = selectedApp?.googleAnalyticsID;

  const getGoogleAnalyticsId = () => {
    if (!Utils.isNullOrUndefined(projectGoogleAnalyticsId)) {
      return projectGoogleAnalyticsId;
    }

    if (!Utils.isNullOrUndefined(appGoogleAnalyticsId)) {
      return appGoogleAnalyticsId;
    }

    return accountGoogleAnalyticsId || '';
  };

  const googleAnalyticsId = getGoogleAnalyticsId();

  return { googleAnalyticsId, accountGoogleAnalyticsId, appGoogleAnalyticsId };
};

export default useGoogleAnalytics;
