import FacebookIcon from 'shared/react/images/share/FacebookIcon';
import TwitterIcon from 'shared/react/images/share/TwitterIcon';
import LinkedInIcon from 'shared/react/images/share/LinkedInIcon';
import WhatsappIcon from 'shared/react/images/share/WhatsappIcon';
import { SOCIAL_PLATFORMS_KEYS } from 'shared/react/constants/share.constants';
import LinkIcon from 'shared/react/images/share/LinkIcon';

export default [
  { Icon: FacebookIcon, key: SOCIAL_PLATFORMS_KEYS.facebook },
  { Icon: LinkedInIcon, key: SOCIAL_PLATFORMS_KEYS.linkedin },
  { Icon: WhatsappIcon, key: SOCIAL_PLATFORMS_KEYS.whatsapp },
  { Icon: TwitterIcon, key: SOCIAL_PLATFORMS_KEYS.twitter },
  { Icon: LinkIcon, key: SOCIAL_PLATFORMS_KEYS.link },
];
