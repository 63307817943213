import React from 'react';
import { FEATURE_CREATE_WORKSPACE } from 'shared/react/constants/features.constants';
import useNavigation from 'app/src/hooks/useNavigation';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { CreateMenuItem } from 'app/src/complex_components/menu_items/MenuItemV3';

const WorkspacesCreateItem = () => {
  const [, { getFeatureEnabled }] = useFeatures();
  const { navigateToCreateWorkspaceMessage } = useNavigation();
  const isCreateWorkspaceEnabled = getFeatureEnabled(FEATURE_CREATE_WORKSPACE);

  if (!isCreateWorkspaceEnabled) {
    return null;
  }

  const handleClicked = () => {
    navigateToCreateWorkspaceMessage();
  };

  return <CreateMenuItem onClick={handleClicked}>New workspace</CreateMenuItem>;
};

export default WorkspacesCreateItem;
