import React from 'react';
import { CUSTOM_PLAYLIST_ITEM_DATA_TEST_ID } from 'src/constants/dataTestIds.constants';
import { PlaylistItem } from '../../../types/playlist.types';
import SidebarEditableItem from '../item/SidebarEditableItem';

type Props = {
  playlists: PlaylistItem[];
  selectedPlaylistId?: string;
  withEditMenu?: boolean;
  onClick?: (playlist: PlaylistItem) => void;
  onEdit?: (playlist: PlaylistItem) => void;
  onDelete?: (playlist: PlaylistItem) => void;
};

export const SidebarPlaylistItems = ({
  playlists = [],
  selectedPlaylistId,
  withEditMenu,
  onClick = () => {},
  onEdit = () => {},
  onDelete = () => {},
}: Props) =>
  playlists.map((playlist, index) => (
    <SidebarEditableItem
      key={playlist.id}
      name={playlist.name}
      count={playlist.count}
      isSelected={selectedPlaylistId === playlist.id}
      dataTestId={`${CUSTOM_PLAYLIST_ITEM_DATA_TEST_ID}-${index}`}
      onClick={() => onClick(playlist)}
      onEdit={() => onEdit(playlist)}
      onDelete={() => onDelete(playlist)}
      withEditMenu={withEditMenu}
    />
  ));

export default SidebarPlaylistItems;
