import styled from 'styled-components';

export const CircleButton = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 100%;
  border: none;
  background-color: rgba(9, 10, 11, 0.3);
  transition: 0.2s;
`;

export const IconButton = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  background: ${({ backgroundColor }) => backgroundColor};
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 500px;
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  filter: ${({ disabled }) => (disabled ? 'grayScale(1)' : 'initial')};

  transition: all 0.2s ease-in-out;

  :hover {
    transform: ${({ disabled }) => (disabled ? 'initial' : 'scale(1.06)')};
  }

  :focus,
  :active {
    transform: ${({ disabled }) => (disabled ? 'initial' : 'scale(1.02)')};
  }
`;
