import React, { forwardRef } from 'react';
import styled from 'styled-components';

const HorizontalFlexWrap = forwardRef(({ children, ...props }, ref) => {
  return (
    <LayoutRoot {...props} ref={ref}>
      {children}
    </LayoutRoot>
  );
});

HorizontalFlexWrap.displayName = 'HorizontalFlexWrap';

export default HorizontalFlexWrap;

const LayoutRoot = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
