import React from 'react';

const EmailIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 4c0-1.094.875-2 2-2h12c1.094 0 2 .906 2 2v8c0 1.125-.906 2-2 2H2c-1.125 0-2-.875-2-2zm1.5 0v.719l5.375 4.406a1.744 1.744 0 0 0 2.219 0L14.5 4.719V4c0-.25-.25-.5-.5-.5H2c-.281 0-.5.25-.5.5m0 2.656V12c0 .281.219.5.5.5h12c.25 0 .5-.219.5-.5V6.656l-4.437 3.625c-1.22 1-2.938 1-4.157 0z"
        fill="#2F3137"
      />
    </svg>
  );
};

export default EmailIcon;
