import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import { StyleSheetManager } from 'styled-components';
import * as Sentry from '@sentry/react';
import { Amplify } from 'aws-amplify';
import './index.css';
import 'shared/react/theme/styles/css/scrollbar.css';
import './highlight.css';
import './styles/globals.css';
import './i18n';
import Themes from './themes';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import awsExports from './aws-exports';

import { getCustomHeader } from './helpers/API';
import RootStore from './context/RootStore';
import ErrorBoundary from './complex_components/ErrorBoundary';
import { UserContainer } from './context/userStore/UserStore';
import { initializeBroadcastChannel } from 'app/src/utils/broadcast.utils';
import APP_CONFIG from 'app/src/config/app.config';
import { QueryClientProvider } from '@tanstack/react-query';
import { reactQueryClient } from 'app/src/utils/reactQueryClient.utils';

if (APP_CONFIG.IS_DEV && window.Cypress) {
  const { worker } = require('./mocks/browser');
  worker.start();
}

const config = {
  ...awsExports,
  API: {
    endpoints: [
      {
        name: 'project-actions',
        endpoint: `${APP_CONFIG.API_URL}/projects`,
        custom_header: getCustomHeader,
      },
      {
        name: 'account-actions',
        endpoint: `${APP_CONFIG.API_URL}/accounts`,
        custom_header: getCustomHeader,
      },
      {
        name: 'publish-actions',
        endpoint: `${APP_CONFIG.API_URL}/publish`,
        custom_header: getCustomHeader,
      },
      {
        name: 'response-actions',
        endpoint: `${APP_CONFIG.API_URL}/responses`,
        custom_header: getCustomHeader,
      },
      {
        name: 'reply-email',
        endpoint: `${APP_CONFIG.API_URL}/mailer`,
        custom_header: getCustomHeader,
      },
      {
        name: 'resolve-all',
        endpoint: `${APP_CONFIG.API_URL}/features-resolver`,
        custom_header: getCustomHeader,
      },
      {
        name: 'upload-url',
        endpoint: `${APP_CONFIG.API_URL}/upload`,
        custom_header: getCustomHeader,
      },
      {
        name: 'user-actions',
        endpoint: `${APP_CONFIG.API_URL}/users`,
        custom_header: getCustomHeader,
      },
      {
        name: 'product-actions',
        endpoint: `${APP_CONFIG.API_URL}/products`,
        custom_header: getCustomHeader,
      },
      {
        name: 'ai',
        endpoint: `${APP_CONFIG.API_URL}/ai`,
        custom_header: getCustomHeader,
      },
      {
        name: 'video-actions',
        endpoint: `${APP_CONFIG.API_URL}/videos`,
        custom_header: getCustomHeader,
      },
      {
        name: 'stripe',
        endpoint: `${APP_CONFIG.API_URL}/stripe`,
        custom_header: getCustomHeader,
      },
      {
        name: 'payment',
        endpoint: `${APP_CONFIG.API_URL}/payment`,
        custom_header: getCustomHeader,
      },
      {
        name: 'rates',
        endpoint: `${APP_CONFIG.API_URL}/rates`,
        custom_header: getCustomHeader,
      },
      {
        name: 'events',
        endpoint: `${APP_CONFIG.API_URL}/events`,
        custom_header: getCustomHeader,
      },
      {
        name: 'tiktok',
        endpoint: `${APP_CONFIG.API_URL}/tiktok`,
        custom_header: getCustomHeader,
      },
      {
        name: 'in-app-notifications',
        endpoint: `${APP_CONFIG.API_URL}/notifications/actions/notifications`,
        custom_header: getCustomHeader,
      },
      {
        name: 'firstpromoter',
        endpoint: `${APP_CONFIG.API_URL}/firstpromoter`,
        custom_header: getCustomHeader,
      },
      {
        name: 'klaviyo',
        endpoint: `${APP_CONFIG.API_URL}/klaviyo`,
        custom_header: getCustomHeader,
      },
      {
        name: 'magento',
        endpoint: `${APP_CONFIG.API_URL}/magento`,
        custom_header: getCustomHeader,
      },
      {
        name: 'instagram',
        endpoint: `${APP_CONFIG.API_URL}/instagram`,
        custom_header: getCustomHeader,
      },
      {
        name: 'instagram-graph',
        endpoint: `${APP_CONFIG.API_URL}/instagram-graph`,
        custom_header: getCustomHeader,
      },
      {
        name: 'hubspot',
        endpoint: `${APP_CONFIG.API_URL}/hubspot`,
        custom_header: getCustomHeader,
      },
      {
        name: 'video-converter',
        endpoint: `${APP_CONFIG.API_URL}/video-converter`,
        custom_header: getCustomHeader,
      },
      {
        name: 'shopify',
        endpoint: `${APP_CONFIG.API_URL}/shopify`,
        custom_header: getCustomHeader,
      },
      {
        name: 'video-scraper',
        endpoint: `${APP_CONFIG.API_URL}/video-scraper`,
        custom_header: getCustomHeader,
      },
      {
        name: 'video-integration',
        endpoint: `${APP_CONFIG.API_URL}/video-integration`,
        custom_header: getCustomHeader,
      },
      {
        name: 'shop',
        endpoint: `${APP_CONFIG.API_URL}/shop`,
        custom_header: getCustomHeader,
      },
      {
        name: 'google-drive',
        endpoint: `${APP_CONFIG.API_URL}/google-drive`,
        custom_header: getCustomHeader,
      },
      {
        name: 'youtube',
        endpoint: `${APP_CONFIG.API_URL}/youtube`,
        custom_header: getCustomHeader,
      },
      {
        name: 'products-import',
        endpoint: `${APP_CONFIG.API_URL}/products-import`,
        custom_header: getCustomHeader,
      },
      {
        name: 'open-ai',
        endpoint: `${APP_CONFIG.API_URL}/open-ai`,
        custom_header: getCustomHeader,
      },
      {
        name: 'video-analysis',
        endpoint: `${APP_CONFIG.API_URL}/video-analysis`,
        custom_header: getCustomHeader,
      },
      {
        name: 'vod-asset',
        endpoint: `${APP_CONFIG.API_URL}/vod-asset`,
        custom_header: getCustomHeader,
      },
    ],
  },
};

if (APP_CONFIG.IS_PROD) {
  config.oauth.domain = 'auth.gotolstoy.com';
  config.oauth.redirectSignIn = 'https://app.gotolstoy.com/';
  config.oauth.redirectSignOut = 'https://app.gotolstoy.com/login';
} else {
  config.oauth.redirectSignIn = 'http://localhost:3000/';
  config.oauth.redirectSignOut = 'http://localhost:3000/login';
}

Amplify.configure(config);

if (APP_CONFIG.IS_PROD && APP_CONFIG.SENTRY_RELEASE) {
  Sentry.init({
    dsn: 'https://0f9e87a89e414e45880d5ee978829f04@o456280.ingest.sentry.io/5449140',
    release: APP_CONFIG.SENTRY_RELEASE,
    environment: APP_CONFIG.NODE_ENV,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  });
  window.LogRocket?.getSessionURL(sessionURL => {
    Sentry.configureScope(scope => {
      scope.setExtra('sessionURL', sessionURL);
    });
  });
}

window['__react-beautiful-dnd-disable-dev-warnings'] = true;
const root = ReactDOM.createRoot(document.getElementById('root'));

// This implements the default behavior from styled-components v5
// TODO: remove shouldForwardProp fallback and refactor required props to transient
// https://styled-components.com/docs/api#transient-props
// Proper ones could be found by console errors and warnings (a LOT of them)
function shouldForwardProp(propName, target) {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}

root.render(
  <RootStore>
    <QueryClientProvider client={reactQueryClient}>
      <UserContainer>
        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
          <ThemeProvider theme={Themes.default}>
            <CssBaseline />
            <BrowserRouter>
              <ErrorBoundary>
                <App callbackHref={window.location.href} />
              </ErrorBoundary>
            </BrowserRouter>
          </ThemeProvider>
        </StyleSheetManager>
      </UserContainer>
    </QueryClientProvider>
  </RootStore>
);

// If you want to start measuring performance in your app, pass a function
// to log results ( for example: reportWebVitals(console.log)   )
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

initializeBroadcastChannel();
