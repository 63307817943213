import React from 'react';
import styled from 'styled-components';
import { TextSubtitle } from 'shared/react/components/complex/Text';
import ConversationBox from 'app/src/pages/project/pages/inbox/ConversationBox';
import { CircularProgress } from '@material-ui/core';

const Conversations = ({
  selectedConversation,
  loadingConversations,
  conversationsByDate,
  onConversationClick,
  updateConversation,
  conversationsRef,
}) => {
  return (
    <StyledConversations ref={conversationsRef}>
      <ConversationBoxesContainer>
        {conversationsByDate?.map(({ title, conversations }) => (
          <ConversationsArea key={title}>
            <ConversationsTitle lineHeight="24px">{title}</ConversationsTitle>
            <ConversationsContainer>
              {conversations.map(conversation => (
                <ConversationBox
                  isToday={title === 'Today'}
                  key={conversation.id}
                  {...conversation}
                  onClick={() => onConversationClick(conversation)}
                  selected={selectedConversation?.id === conversation.id}
                  updateConversation={updateConversation}
                />
              ))}
            </ConversationsContainer>
          </ConversationsArea>
        ))}
      </ConversationBoxesContainer>
      {loadingConversations && (
        <ConversationsLoadingContainer>
          <CircularProgress size={24} />
        </ConversationsLoadingContainer>
      )}
    </StyledConversations>
  );
};

export default Conversations;

const StyledConversations = styled.div`
  padding-top: 16px;
  overflow: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  &:after {
    content: '';
    height: 20px;
  }
`;

const ConversationBoxesContainer = styled.div`
  padding: 0px;
`;

const ConversationsArea = styled.div`
  padding-bottom: 12px;
`;

const ConversationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const ConversationsTitle = styled(TextSubtitle)`
  padding-left: 14px;
  padding-bottom: 6px;
  font-weight: 600;
`;

const ConversationsLoadingContainer = styled.div`
  place-self: center;
  padding-bottom: 8px;
`;
