import React from 'react';
import { Search } from 'lucide-react';
import { useRouteMatch } from 'react-router-dom';

import {
  SIDEBAR_HOMEPAGE_TEST_ID,
  SIDEBAR_OFFSITE_TAB_TEST_ID,
  SIDEBAR_ONSITE_TAB_TEST_ID,
  SIDEBAR_SHOP_APP_TAB_TEST_ID,
  SIDEBAR_THREADS_TAB_TEST_ID,
  SIDEBAR_VIDEOS_TAB_TEST_ID,
} from 'app/src/constants/dataTestIds.constants';
import Routes from 'app/src/helpers/Routes';
import { track } from 'app/src/helpers/Tracker';
import useNavigation from 'app/src/hooks/useNavigation';
import AnalyticsIcon from 'app/src/images/sidebar-v2/AnalyticsIcon';
import CampaignsIcon from 'app/src/images/sidebar-v2/CampaingsIcon';
import ThreadsIcon from 'app/src/images/sidebar-v2/ThreadsIcon';
import VideosIcon from 'app/src/images/sidebar-v2/VideosIcon';
import WebsiteIcon from 'app/src/images/sidebar-v2/WebsiteIcon';
import { useHistory } from 'react-router-dom';
import { TextTiny } from 'shared/react/components/complex/Text';
import { TextSmall } from 'shared/react/components/basic/text/TextV2';
import {
  FEATURE_SHOP_APP,
  FEATURE_AI_VIDEOS,
  FEATURE_UGC_IMPORT,
  FEATURE_APP_HOMEPAGE,
  FEATURE_AI_SUGGESTIONS,
  FEATURE_APP_PRODUCTS_TAB,
  FEATURE_CUSTOM_SIDE_BAR,
} from 'shared/react/constants/features.constants';
import { useApps } from 'src/context/AppsStore';
import { useFeatureActions } from 'src/context/FeaturesStore';
import ShopAppIcon from 'src/images/dashboard_v2/ShopAppIcon';
import ProductsIcon from 'src/images/sidebar-v2/ProductsIcon';
import HomePageIcon from 'src/images/sidebar-v2/onsite/HomePageIcon';
import styled from 'styled-components';
import { SIDEBAR_BUTTONS_TEXT, SidebarButtonKey } from './sidebar.constants';
import SparklesIcon from 'src/images/onboarding/templates/SparklesIcon';
import PopcornIcon from 'src/images/sidebar-v2/PopcornIcon';
import { useResponses } from 'app/src/context/ResponsesStore';
import AiIconGradient from 'app/src/images/dashboard_v2/AiIconGradient';
import { STUDIO_DOMAIN } from 'src/config/app.config';
import Utils from 'app/src/utils';
import { GHOST_ACCOUNT_QUERY_PARAM } from 'src/constants/tolstoy.constants';

const useSideBarButtons = () => {
  const history = useHistory();
  const {
    navigateToUgc,
    navigateToDashboardLibrary,
    navigateToDashboardV2Onsite,
    navigateToDashboardV2Offsite,
    navigateToDashboardV2Threads,
    navigateToDashboardV2Analytics,
    navigateToShopStoreFeeds,
    navigateToHomepage,
    navigateToProducts,
    navigateToAiTagging,
    navigateToAudience,
  } = useNavigation();
  const { getFeatureEnabled, getSettingsByKey } = useFeatureActions();
  const [{ hasResponses }] = useResponses();
  const [{ shopify: isShopify }] = useApps();
  const ugcRouteMatch = useRouteMatch(Routes.getDashbaordV2UgcRoute());
  const customSidebarOptions = getSettingsByKey(FEATURE_CUSTOM_SIDE_BAR);

  const trackAndNavigate = (navigateFunction: () => void, tab: SidebarButtonKey) => {
    track('Sidebar Click', { tab });
    navigateFunction();
  };

  const navigateToOnsite = () => {
    navigateToDashboardV2Onsite();
  };

  const navigateToVideos = () => {
    navigateToDashboardLibrary();
  };

  const navigateToShop = () => {
    navigateToShopStoreFeeds();
  };

  const navigateToOffsite = () => {
    navigateToDashboardV2Offsite();
  };

  const navigateToThreads = () => {
    navigateToDashboardV2Threads('');
  };

  const navigateToStudio = () => {
    const isGhostAccount = Utils.isGhostAccount();

    const studioUrl = new URL(STUDIO_DOMAIN);

    if (isGhostAccount) {
      studioUrl.searchParams.append(GHOST_ACCOUNT_QUERY_PARAM, 'true');
    }

    window.open(studioUrl.toString(), '_blank');
  };

  const homeItem = {
    icon: <HomePageIcon />,
    text: SIDEBAR_BUTTONS_TEXT.home,
    onClick: () => trackAndNavigate(navigateToHomepage, SidebarButtonKey.home),
    isSelected: history.location.pathname.startsWith(Routes.getHomepageRoute()),
    dataTestId: SIDEBAR_HOMEPAGE_TEST_ID,
    isHidden: !getFeatureEnabled(FEATURE_APP_HOMEPAGE),
  };

  const aiTaggingItem = {
    icon: <AiTaggingIcon />,
    text: SIDEBAR_BUTTONS_TEXT.aiTagging,
    onClick: () => trackAndNavigate(navigateToAiTagging, SidebarButtonKey.aiTagging),
    isSelected: history.location.pathname.includes(Routes.getAiTaggingRoute()),
    isHidden: !getFeatureEnabled(FEATURE_AI_SUGGESTIONS),
    endIcon: (
      <TagWrapper>
        <TagText>Beta</TagText>
      </TagWrapper>
    ),
  };

  const productsItem = {
    icon: <ProductsIcon />,
    text: SIDEBAR_BUTTONS_TEXT.products,
    onClick: () => trackAndNavigate(navigateToProducts, SidebarButtonKey.products),
    isSelected: history.location.pathname.includes(Routes.getProductsRoute()),
    isHidden: !getFeatureEnabled(FEATURE_APP_PRODUCTS_TAB) || !isShopify,
  };

  const videosItem = {
    icon: <VideosIcon />,
    text: SIDEBAR_BUTTONS_TEXT.videos,
    onClick: () => trackAndNavigate(navigateToVideos, SidebarButtonKey.videos),
    isSelected: Routes.isVideosDashboard(history.location.pathname),
    dataTestId: SIDEBAR_VIDEOS_TAB_TEST_ID,
  };

  const onsiteItem = {
    icon: <WebsiteIcon />,
    text: SIDEBAR_BUTTONS_TEXT.onsite,
    onClick: () => trackAndNavigate(navigateToOnsite, SidebarButtonKey.onsite),
    isSelected: history.location.pathname.includes(Routes.getDashboardV2OnsiteRoute('')),
    dataTestId: SIDEBAR_ONSITE_TAB_TEST_ID,
  };

  const shopAppItem = {
    icon: <ShopAppIcon />,
    text: SIDEBAR_BUTTONS_TEXT.shop,
    onClick: () => trackAndNavigate(navigateToShop, SidebarButtonKey.shop),
    isSelected: history.location.pathname.includes(Routes.getShopRoute()),
    dataTestId: SIDEBAR_SHOP_APP_TAB_TEST_ID,
    isHidden: !isShopify || !getFeatureEnabled(FEATURE_SHOP_APP),
  };

  const offsiteItem = {
    icon: <CampaignsIcon />,
    text: SIDEBAR_BUTTONS_TEXT.offsite,
    onClick: () => trackAndNavigate(navigateToOffsite, SidebarButtonKey.offsite),
    isSelected: history.location.pathname.includes(Routes.getDashboardV2OffsiteRoute('')),
    dataTestId: SIDEBAR_OFFSITE_TAB_TEST_ID,
  };
  const aiStudioItem = {
    icon: <AiIconGradient />,
    text: <AiStudioText>{SIDEBAR_BUTTONS_TEXT.aiStudio}</AiStudioText>,
    onClick: () => trackAndNavigate(navigateToStudio, SidebarButtonKey.aiStudio),
    isSelected: false,
    isHidden: !getFeatureEnabled(FEATURE_AI_VIDEOS) || !isShopify,
  };
  const ugcItem = {
    icon: <Search />,
    text: SIDEBAR_BUTTONS_TEXT.ugc,
    onClick: () => trackAndNavigate(navigateToUgc, SidebarButtonKey.ugc),
    isSelected: ugcRouteMatch !== null,
    isHidden: !getFeatureEnabled(FEATURE_UGC_IMPORT),
  };
  const threadsItem = {
    icon: <ThreadsIcon />,
    text: SIDEBAR_BUTTONS_TEXT.threads,
    onClick: () => trackAndNavigate(navigateToThreads, SidebarButtonKey.threads),
    isSelected: history.location.pathname.includes(Routes.getDashboardV2ThreadsRoute('')),
    dataTestId: SIDEBAR_THREADS_TAB_TEST_ID,
    isHidden: !hasResponses,
  };

  const analyticsItem = {
    icon: <AnalyticsIcon />,
    text: SIDEBAR_BUTTONS_TEXT.analytics,
    onClick: () => trackAndNavigate(navigateToDashboardV2Analytics, SidebarButtonKey.analytics),
    isSelected: history.location.pathname === Routes.getDashboardV2AnalyticsRoute(),
  };

  const audienceItem = {
    icon: <PopcornIcon />,
    text: SIDEBAR_BUTTONS_TEXT.audience,
    onClick: () => trackAndNavigate(navigateToAudience, SidebarButtonKey.audience),
    isSelected: history.location.pathname === Routes.getAudienceRoute(),
  };

  let buttons = [
    homeItem,
    productsItem,
    videosItem,
    onsiteItem,
    shopAppItem,
    offsiteItem,
    aiStudioItem,
    ugcItem,
    threadsItem,
    analyticsItem,
    aiTaggingItem,
    audienceItem,
  ];

  const pages = {
    videos: videosItem,
    onsite: onsiteItem,
    shopApp: shopAppItem,
    offsite: offsiteItem,
    threads: threadsItem,
    analytics: analyticsItem,
  };

  if (customSidebarOptions?.methods) {
    buttons = customSidebarOptions.methods.map(method => pages[method]);
  }

  const pageButtons = buttons.filter((button: typeof shopAppItem) => button && !button.isHidden);

  return { pageButtons };
};

const TagWrapper = styled.div<{ $right?: string }>`
  padding: 3px 8px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.purple4};
  position: absolute;
  right: ${({ $right }) => $right || 0};
`;

const TagText = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.white};
`;

const AiTaggingIcon = styled(SparklesIcon)`
  path {
    fill: ${({ theme }) => theme.colors.neutralBlack};
  }
`;

const AiStudioText = styled(TextSmall)`
  background: linear-gradient(to right, #00ffff, purple);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export default useSideBarButtons;
