import React from 'react';
import styled from 'styled-components';
import Button from 'shared/react/components/complex/Button';
import Types from 'shared/react/theme/Types';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Separator from 'shared/react/components/basic/Separator';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import PublishVideoPageNonShopifyStepper from './PublishVideoPageNonShopifyStepper';

const PublishVideoPageNonShopifyContent = ({
  getCode,
  onClickCopyCode,
  onClickCustomizeAppearance,
  setHeaderId,
}) => {
  return (
    <LayoutRoot>
      <PublishVideoPageNonShopifyStepper
        getCode={getCode}
        onClickCopyCode={onClickCopyCode}
        setHeaderId={setHeaderId}
      />
      <Footer>
        <Separator />
        <CustomizeAppeareance fullWidth onClick={onClickCustomizeAppearance} type={Types.Secondary}>
          Customize appearance
        </CustomizeAppeareance>
      </Footer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  justify-content: space-between;
  height: 100%;
`;

const Footer = styled(Gap16VerticalFlex)``;

const CustomizeAppeareance = styled(Button)`
  width: 192px;
`;

export default PublishVideoPageNonShopifyContent;
