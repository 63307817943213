import React from 'react';
import Utils from '../../../utils';
import TeamMemberText from './TeamMemberText';
import styled from 'styled-components';

const TeamMemberEmail = ({ text, userId }) => {
  const currentUser = Utils.getOwner() === userId;
  return <StyledTeamMemberEmail text={text} currentUser={currentUser} />;
};

export default TeamMemberEmail;

const StyledTeamMemberEmail = styled(TeamMemberText)`
  font-weight: ${({ currentUser }) => (currentUser ? 'bold' : '')};
`;
