const getCollectFieldWithColor = ({
  type = 'email',
  color = '#f00000',
  collect = true,
  required = true,
}) => ({
  backgroundColor: color,
  borderColor: color,
  name: type,
  type,
  collect,
  textColor: color,
  key: type,
  required,
});

export const projectBuilder = ({ name = '', collectFields, verticalOrientation = true } = {}) => {
  const params = { name, verticalOrientation };
  if (collectFields) {
    params.collectFields = collectFields.map(getCollectFieldWithColor);
  }

  return params;
};
