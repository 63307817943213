import React from 'react';

const EyeIcon = () => {
  return (
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 .25c-1.898 0-3.422.867-4.523 1.898C1.375 3.156.648 4.375.297 5.218a.84.84 0 0 0 0 .587c.351.82 1.078 2.039 2.18 3.07C3.578 9.906 5.102 10.75 7 10.75c1.875 0 3.398-.844 4.5-1.875 1.102-1.031 1.828-2.25 2.18-3.07a.84.84 0 0 0 0-.586 9.121 9.121 0 0 0-2.18-3.07C10.398 1.116 8.875.25 7 .25ZM3.625 5.5c0-1.195.633-2.297 1.688-2.906a3.344 3.344 0 0 1 3.375 0c1.03.61 1.687 1.71 1.687 2.906 0 1.219-.656 2.32-1.688 2.93a3.344 3.344 0 0 1-3.374 0A3.356 3.356 0 0 1 3.625 5.5ZM7 4c0 .844-.68 1.5-1.5 1.5-.188 0-.328-.023-.492-.07-.117-.047-.281.047-.258.187 0 .14.023.305.07.469A2.231 2.231 0 0 0 7.562 7.68c1.22-.328 1.922-1.547 1.594-2.742A2.264 2.264 0 0 0 7.094 3.25c-.14 0-.235.164-.188.281.047.164.094.305.094.469Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default EyeIcon;
