import { makeStyles } from '@material-ui/styles';
import Colors from 'shared/react/constants/colors';

export default makeStyles(theme => ({
  tolstoyIcon: {
    width: 150,
    cursor: 'pointer',
  },
  formContainer: {
    width: 300,
    alignSelf: 'center',
    gap: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tab: {
    fontWeight: 400,
    fontSize: 18,
  },
  greeting: {
    fontWeight: 500,
    textAlign: 'center',
    marginTop: theme.spacing(4),
  },
  subGreeting: {
    fontWeight: 500,
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6),
  },
  googleButton: {
    backgroundColor: 'white',
    width: '100%',
    padding: '0px',
    minHeight: '46px',
    textTransform: 'none',
  },
  createAccountButton: {
    height: 40,
    textTransform: 'none',
  },
  formDividerContainer: {
    width: '100%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
  },
  formDividerWord: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formDivider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: theme.palette.text.hint + '40',
  },
  errorMessage: {
    textAlign: 'center',
    color: Colors.pink2,
    lineSpacing: 0,
    fontSize: '14px',
  },
  textFieldUnderline: {
    '&:before': {
      borderBottomColor: theme.palette.primary.light,
    },
    '&:after': {
      borderBottomColor: theme.palette.primary.main,
    },
    '&:hover:before': {
      borderBottomColor: `${theme.palette.primary.light} !important`,
    },
  },
  textField: {
    borderBottomColor: theme.palette.background.light,
    minHeight: '32px',
    paddingLeft: '8px',
  },
  formButtons: {
    width: '100%',
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  forgetButton: {
    textTransform: 'none',
    fontWeight: 400,
  },
  loginLoader: {
    marginLeft: theme.spacing(4),
  },
  copyright: {
    marginTop: theme.spacing(4),
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      bottom: theme.spacing(2),
    },
  },
  modal: {
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
