import React, { useEffect, useRef, useState } from 'react';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { SearchInput } from 'src/basic_components/input/InputWithIcon';
import { useSelectedApp } from 'src/context/AppsStore';
import { useDebounce } from 'src/hooks/useDebounce';
import useGetSearchProducts from 'src/hooks/useGetSearchProducts';
import { useInfiniteScroll } from 'src/hooks/useInfiniteScroll';
import { PageContainer, PageMain } from 'src/pages/dashboard/components/page/Page';
import { SearchProduct, SearchProductsPayload } from 'src/types/common';
import styled from 'styled-components';
import TopBar from '../../components/top-bar/TopBar';
import ProductsProgressBar from './ProductsProgressBar';
import ProductsVideosGrid from './products_videos_grid/ProductsVideosGrid';
import useBestSellingProducts from 'src/hooks/useBestSellingProducts';
import { AnalyticsData } from 'src/types/utils.types';

type FetchProductsParams = {
  body: SearchProductsPayload;
  isEmptyRequest?: boolean;
};

type FetchProductsResponse = Promise<{
  products?: SearchProduct[];
  nextPageFrom?: number;
  isAborted?: boolean;
}>;

type Props = {
  analyticsData: AnalyticsData;
};

const EMPTY_REQUEST_CUSTOM_LIMIT = 30;
const DEFAULT_SEARCH_LIMIT = 10;

const Products = ({ analyticsData }: Props) => {
  const [selectedApp] = useSelectedApp();
  const getSearchProducts = useGetSearchProducts(selectedApp);
  const { bestSellingProductIds = [], isLoading: isLoadingBestSellingProducts } =
    useBestSellingProducts();
  const [searchProducts, setSearchProducts] = useState([]);
  const nextPageFromRef = useRef(0);
  const [searchValue, setSearchValue] = useState('');
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const debouncedSearchValue = useDebounce(searchValue, 600);
  const containerRef = useRef(null);
  const latestRequestIndexRef = useRef(0);

  const setSearchResults = ({ products, nextPageFrom }) => {
    nextPageFromRef.current = nextPageFrom;
    setSearchProducts(prev => [...prev, ...products]);
    setIsInitialLoad(false);
    setIsLoading(false);
  };

  const fetchProducts = async ({
    body,
    isEmptyRequest,
  }: FetchProductsParams): FetchProductsResponse => {
    if (nextPageFromRef.current) {
      body.nextPageFrom = nextPageFromRef.current;
    }

    if (isEmptyRequest) {
      body.customLimit = EMPTY_REQUEST_CUSTOM_LIMIT;
    }

    const requestIndex = latestRequestIndexRef.current + 1;
    latestRequestIndexRef.current = requestIndex;
    const { searchProducts: products, nextPageFrom } = await getSearchProducts(body);

    if (requestIndex !== latestRequestIndexRef.current) {
      return { isAborted: true };
    }

    return { products, nextPageFrom };
  };

  const sortBestSellingProductIds = (products: SearchProduct[]) => {
    products.sort(
      (a, b) =>
        bestSellingProductIds.indexOf(a.productId) - bestSellingProductIds.indexOf(b.productId)
    );
  };

  const fetchProductsByBestSelling = async (isEmptyRequest: boolean | undefined) => {
    const nextPageTo = isEmptyRequest
      ? EMPTY_REQUEST_CUSTOM_LIMIT
      : nextPageFromRef.current + DEFAULT_SEARCH_LIMIT;

    const ids = bestSellingProductIds.slice(nextPageFromRef.current, nextPageTo);

    const body = {
      ids,
      appUrl: selectedApp.appUrl,
      appKey: selectedApp.appKey,
      isSearchByIds: true,
    } as SearchProductsPayload;

    const { products, isAborted } = await fetchProducts({ body, isEmptyRequest });

    if (isAborted) {
      return;
    }

    if (nextPageTo >= bestSellingProductIds.length) {
      setDoneScroll(true);
    }

    sortBestSellingProductIds(products);

    setSearchResults({
      products,
      nextPageFrom: nextPageTo,
    });
  };

  const fetchProductsByTerm = async (isEmptyRequest: boolean | undefined) => {
    const body = {
      term: debouncedSearchValue,
      appUrl: selectedApp.appUrl,
      appKey: selectedApp.appKey,
    } as SearchProductsPayload;

    const { products, nextPageFrom, isAborted } = await fetchProducts({ body, isEmptyRequest });

    if (isAborted) {
      return;
    }

    if (!nextPageFrom) {
      setDoneScroll(true);
    }

    setSearchResults({
      products,
      nextPageFrom,
    });
  };

  const fetchProductsHandler = async (isEmptyRequest: boolean | undefined) => {
    if (debouncedSearchValue || bestSellingProductIds.length < EMPTY_REQUEST_CUSTOM_LIMIT) {
      await fetchProductsByTerm(isEmptyRequest);
      return;
    }

    await fetchProductsByBestSelling(isEmptyRequest);
  };

  const onSearchValueChange = e => {
    setSearchValue(e.target.value);
  };

  const clearSearch = () => {
    nextPageFromRef.current = 0;
    setSearchProducts([]);
    setSearchValue('');
  };

  useEffect(() => {
    if (!selectedApp?.appUrl || isLoadingBestSellingProducts) {
      return;
    }

    setIsLoading(true);
    nextPageFromRef.current = 0;
    setSearchProducts([]);
    setDoneScroll(false);

    fetchProductsHandler(true);
  }, [
    debouncedSearchValue,
    selectedApp?.appUrl,
    isLoadingBestSellingProducts,
    bestSellingProductIds,
  ]);

  const { loadingInfiniteScroll, setDoneScroll } = useInfiniteScroll(
    containerRef,
    fetchProductsHandler
  );

  return (
    <PageContainer>
      <PageMain>
        <TopBar
          leftContent={
            <StyledSearchInput
              onChange={onSearchValueChange}
              value={searchValue}
              placeholder="Search products..."
              disabled={!selectedApp?.appUrl}
            />
          }
        />
        <Content ref={containerRef}>
          <ProductsProgressBar />
          <ProductsVideosGrid
            searchProducts={searchProducts}
            isInitialLoad={isInitialLoad}
            clearSearch={clearSearch}
            isLoadingMore={loadingInfiniteScroll}
            isLoading={isLoading}
            analyticsData={analyticsData}
          />
        </Content>
      </PageMain>
    </PageContainer>
  );
};

const StyledSearchInput = styled(SearchInput)`
  width: 224px;
  height: 32px;
`;

const Content = styled(VerticalFlex)`
  padding-top: 16px;
  overflow-y: auto;
`;

export default Products;
