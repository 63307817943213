import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { SuccessButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { app as AppEntity } from 'src/types/entities';
import { track } from 'src/helpers/Tracker';
import { useApps } from 'src/context/AppsStore';
import { useSnackBarActions } from 'src/context/ui_store/SnackBarStore';
import useAccountInstallation from 'src/hooks/useAccountInstallation';
import Spinner from 'src/basic_components/Spinner';

interface InstallationCompletedButtonProps {
  app: AppEntity;
}

const InstallationCompletedButton: FC<InstallationCompletedButtonProps> = props => {
  const { app } = props;
  const { setSnackbar, setErrorSnackbar } = useSnackBarActions();
  const [isLoading, setIsLoading] = useState(false);
  const [{ shopify: isShopify }] = useApps();
  const [{ isInstallationCompleted, toggleAccountInstallation }] = useAccountInstallation(
    isShopify ? app?.id : ''
  );

  const onButtonClick = async () => {
    track('App Installation Completed Button Click', {
      status: !isInstallationCompleted,
    });

    setIsLoading(true);
    const isSuccess = await toggleAccountInstallation();
    setIsLoading(false);

    if (isSuccess) {
      setSnackbar('Saved successfully');
    } else {
      setErrorSnackbar('Saving failed, please try again later');
    }
  };

  let content: string | JSX.Element = '';
  if (isLoading) {
    content = <Spinner size={16} />;
  } else if (isInstallationCompleted) {
    content = 'Completed';
  } else {
    content = <>Mark as complete</>;
  }

  return (
    <SuccessButtonStyled onClick={onButtonClick} disabled={isLoading || isInstallationCompleted}>
      {content}
    </SuccessButtonStyled>
  );
};

const SuccessButtonStyled = styled(SuccessButton)`
  width: 120px;
`;

export default InstallationCompletedButton;
