import React, { useState } from 'react';
import styled from 'styled-components';

import { TextH6, TextSmall } from 'shared/react/components/basic/text/TextV2';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import useCustomerLogin from '../context/hooks/useCustomerLogin';
import FeedButton from '../feed/feed_buttons/FeedButton';
import SharedUtils from 'shared/react/utils/utils';
import { CUSTOMER_LOGIN_STATE } from 'shared/react/constants/feature-constants/customerLogin.constants';
import { LOGIN_WITH_MULTIPASS_URL_REQUEST_MESSAGE } from 'shared/react/constants/shopifyMessaging.constants';

const CustomerLoginCodeValidation = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [codeInput, setCodeInput] = useState('');
  const [, { setCustomerLoginState, handleSubmitVerificationCode }] = useCustomerLogin();

  const onClickTryAnotherEmail = () => {
    setCustomerLoginState(CUSTOMER_LOGIN_STATE.UNAUTHENTICATED);
  };

  const onCodeSubmit = async () => {
    setIsLoading(true);
    const multipassUrl = await handleSubmitVerificationCode(codeInput);

    if (!multipassUrl) {
      setIsLoading(false);
      setCodeInput('');
      return;
    }

    SharedUtils.postMessageToWindow({
      eventName: LOGIN_WITH_MULTIPASS_URL_REQUEST_MESSAGE,
      multipassUrl,
    });
  };

  return (
    <LayoutRoot>
      {/* TODO: add translations */}
      <StyledText>Verify your identity</StyledText>
      <StyledTextSmall>Check your email</StyledTextSmall>
      <StyledInput
        id="code-input"
        type="text"
        placeholder="6-digit code"
        value={codeInput}
        onChange={e => {
          setCodeInput(e.target.value);
        }}
      />
      <StyledLinkButton onClick={onClickTryAnotherEmail}>Try another email</StyledLinkButton>
      <StyledFeedButton
        onClick={onCodeSubmit}
        isLoading={isLoading}
        buttonText={'Submit'}
        buttonTextColor={'white'}
        isDisabled={codeInput?.length < 6}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  min-height: 198px;
  gap: 12px;
`;

const StyledText = styled(TextH6)`
  width: 100%;
`;

const StyledTextSmall = styled(TextSmall)`
  width: 100%;
  color: ${({ theme }) => theme.colors.neutralGray};
  margin-bottom: 8px;
`;

const StyledLinkButton = styled.div`
  margin: auto;
  color: ${({ theme }) => theme.colors.gray34};
  text-decoration: underline;
  cursor: pointer;
`;

const StyledInput = styled.input`
  height: 40px;
  color: ${({ theme }) => theme.colors.black};
  border-radius: 8px;
  padding: 0 16px;
  border: ${({ theme }) => `1px solid ${theme.colors.gray38}`};
  ::placeholder {
    color: ${({ theme }) => theme.colors.gray36};
  }
`;

const StyledFeedButton = styled(FeedButton)`
  div {
    font-size: 16px;
  }
`;

export default CustomerLoginCodeValidation;
