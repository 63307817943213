import React from 'react';
import NotificationsLockedState from 'app/src/complex_components/notifications/NotificationsLockedState';
import NotificationsList from 'app/src/complex_components/notifications/NotificationsList';

const NotificationsListContainer = ({
  isFeatureDisabled,
  inAppNotifications,
  currentTeamMember,
  onClosePanel,
}) => {
  if (isFeatureDisabled) {
    return <NotificationsLockedState />;
  }

  return (
    <NotificationsList
      inAppNotifications={inAppNotifications}
      currentTeamMember={currentTeamMember}
      onClosePanel={onClosePanel}
    />
  );
};

export default NotificationsListContainer;
