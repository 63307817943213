import React from 'react';
import styled from 'styled-components';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import {
  CAROUSEL_TYPE,
  CAROUSEL_TYPE_PROPERTY_KEY,
  SHOW_CREATOR_PROFILE_LINK_PROPERTY_KEY,
  CAROUSEL_CREATOR_PROFILE_LINK_POSITION,
  CAROUSEL_CREATOR_PROFILE_LINK_POSITION_PROPERTY_KEY,
} from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import EditorButtonGroupItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorButtonGroupItem';
import EditorHeader from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorHeader';
import EditorOnOffToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorOnOffToggleItem';
import { FEATURE_WIDGET_SPOTLIGHT_CAROUSEL } from 'app/src/constants/appFeatures.constants';
import { useFeatures } from 'app/src/context/FeaturesStore';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

import EditorMainContainer from '../shared/EditorMainContainer';
import { FeedSpotlightGeneralSettings } from '../feed_spotlight_editor';
import { FeedDefaultCarouselSettings } from './FeedDefaultCarouselSettings';

const FeedCarouselGeneralEditor = ({ disabled }) => {
  const [, { getFeatureEnabled }] = useFeatures();
  const isSpotlightCarouselEnabled = getFeatureEnabled(FEATURE_WIDGET_SPOTLIGHT_CAROUSEL);

  const {
    customizationSettings: {
      widgetSettings: {
        carouselType,
        showCreatorProfileLink = false,
        carouselCreatorProfileLinkPosition = CAROUSEL_CREATOR_PROFILE_LINK_POSITION.BOTTOM,
      },
    },
    setWidgetSettingsProperty,
  } = useLookAndFeelContext();
  const isSpotlightCarousel =
    isSpotlightCarouselEnabled && carouselType === CAROUSEL_TYPE.SPOTLIGHT;

  const carouselTypeButtons = [
    {
      text: 'Default',
      isEnabled: carouselType === CAROUSEL_TYPE.DEFAULT,
      onClick: () => setWidgetSettingsProperty(CAROUSEL_TYPE_PROPERTY_KEY, CAROUSEL_TYPE.DEFAULT),
    },
    {
      text: 'Spotlight',
      isEnabled: carouselType === CAROUSEL_TYPE.SPOTLIGHT,
      onClick: () => setWidgetSettingsProperty(CAROUSEL_TYPE_PROPERTY_KEY, CAROUSEL_TYPE.SPOTLIGHT),
    },
  ];

  return (
    <LayoutRoot>
      <EditorHeader title="Layout" disabled={disabled} proFeature />
      <EditorMainContainer disabled={disabled}>
        {isSpotlightCarouselEnabled && (
          <EditorButtonGroupItem
            text="Carousel type"
            buttons={carouselTypeButtons}
            disabled={disabled}
          />
        )}
        {isSpotlightCarousel ? (
          <FeedSpotlightGeneralSettings disabled={disabled} />
        ) : (
          <FeedDefaultCarouselSettings disabled={disabled} /> 
        )}
      </EditorMainContainer>
      <EditorHeader title="Behaviour" disabled={disabled} proFeature />
      <EditorMainContainer disabled={disabled}>
        <EditorOnOffToggleItem
          key="show-creator-profile-link"
          text="Display creator handle"
          checked={showCreatorProfileLink}
          onChange={value =>
            setWidgetSettingsProperty(SHOW_CREATOR_PROFILE_LINK_PROPERTY_KEY, value)
          }
        />
        {showCreatorProfileLink && (
          <EditorButtonGroupItem
            text="Creator handle position"
            buttons={[
              {
                text: 'Bottom',
                isEnabled:
                  carouselCreatorProfileLinkPosition ===
                  CAROUSEL_CREATOR_PROFILE_LINK_POSITION.BOTTOM,
                onClick: () =>
                  setWidgetSettingsProperty(
                    CAROUSEL_CREATOR_PROFILE_LINK_POSITION_PROPERTY_KEY,
                    CAROUSEL_CREATOR_PROFILE_LINK_POSITION.BOTTOM
                  ),
              },
              {
                text: 'Top',
                isEnabled:
                  carouselCreatorProfileLinkPosition === CAROUSEL_CREATOR_PROFILE_LINK_POSITION.TOP,
                onClick: () =>
                  setWidgetSettingsProperty(
                    CAROUSEL_CREATOR_PROFILE_LINK_POSITION_PROPERTY_KEY,
                    CAROUSEL_CREATOR_PROFILE_LINK_POSITION.TOP
                  ),
              },
            ]}
            disabled={disabled}
          />
        )}
      </EditorMainContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

export default FeedCarouselGeneralEditor;
