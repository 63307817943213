import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import useEventListener from 'shared/react/hooks/useEventListener';
import BackgroundOverlay from 'shared/react/components/basic/BackgroundOverlay';
import Utils from 'shared/react/utils/utils';

const ModalWrapper = ({
  onClose,
  isOpen,
  setIsOpen,
  hideBackgroundOverlay,
  disableAutoOpen,
  backgroundZIndex,
  disableAnimation,
  children,
  ...props
}) => {
  const containerRef = useRef();
  const shouldShowHBackgroundOverlay = isOpen && !hideBackgroundOverlay && !Utils.getIsAppPreview();

  useEffect(() => {
    if (disableAutoOpen) {
      return;
    }

    requestAnimationFrame(() => {
      setIsOpen(true);
    });
  }, []);

  const onModalClosed = useCallback(() => {
    if (isOpen) {
      return;
    }

    onClose();
  }, [isOpen]);

  useEventListener({ eventName: 'transitionend', ref: containerRef, callback: onModalClosed });

  return (
    <>
      <LayoutRoot disableAnimation={disableAnimation} isOpen={isOpen} ref={containerRef} {...props}>
        {children}
      </LayoutRoot>
      {shouldShowHBackgroundOverlay && (
        <StyledBackgroundOverlay
          isOpen={isOpen}
          backgroundZIndex={backgroundZIndex}
          onClick={() => setIsOpen(false)}
        />
      )}
    </>
  );
};

const LayoutRoot = styled.div`
  transition: 0.3s;
  position: absolute;
  background: ${({ theme }) => theme.colors.white};
  z-index: 13;
  transform: ${({ disableAnimation, isOpen }) =>
    disableAnimation ? '' : `translateY(${isOpen ? 0 : '100vh'})`};
`;

const StyledBackgroundOverlay = styled(BackgroundOverlay)`
  z-index: ${({ backgroundZIndex }) => backgroundZIndex || '12'};
`;

export default ModalWrapper;
