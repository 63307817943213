import { VideosPageVodAsset } from 'app/src/pages/dashboard/pages/videos/types/videos.types';
import { useMemo } from 'react';
import useSuggestionsByVodAssetId from 'app/src/hooks/vod-connection-suggestions/useSuggestionsByVodAssetId';
import { useCurrentStoreApp } from 'app/src/context/helper/useCurrentStoreApp';
import { useQuery } from '@tanstack/react-query';
import useGetSearchProducts from 'app/src/hooks/useGetSearchProducts';

export const useVideoCardSuggestedProducts = (video: VideosPageVodAsset) => {
  const {
    handleSuggestionRejected,
    handleSuggestionAccepted,
    isInitialized: suggestionsInitialized,
    pendingVodConnectionSuggestions,
    shouldShowSuggestions,
  } = useSuggestionsByVodAssetId({
    vodAssetId: video.id,
  });
  const { app: selectedApp } = useCurrentStoreApp();
  const getSearchProducts = useGetSearchProducts(selectedApp, true);

  const { data: productsMap, isLoading } = useQuery({
    queryKey: ['searchSuggestedProducts', selectedApp, pendingVodConnectionSuggestions],
    queryFn: async () => {
      const ids = pendingVodConnectionSuggestions.map(({ externalProductId }) => externalProductId);
      if (!ids.length) {
        return {};
      }

      const body = {
        ids,
        appUrl: selectedApp.appUrl,
        appKey: selectedApp.appKey,
        isSearchByIds: true,
        customLimit: ids.length,
      };

      const { searchProducts } = await getSearchProducts(body);
      searchProducts.sort((a, b) => {
        return ids.indexOf(a.productId) - ids.indexOf(b.productId);
      });

      return searchProducts.reduce((acc, product) => {
        acc[product.productId] = product;
        return acc;
      }, {});
    },
  });

  const { suggestedProduct, suggestedConnection } = useMemo(() => {
    if (!suggestionsInitialized || !shouldShowSuggestions || isLoading) {
      return { suggestedProduct: null, suggestionConnection: null };
    }

    const existingSuggestions = pendingVodConnectionSuggestions.filter(connection => {
      return !!productsMap[connection.externalProductId];
    });

    const suggestedConnection = existingSuggestions[0];
    if (!suggestedConnection) {
      return { suggestedProduct: null, suggestionConnection: null };
    }

    const suggestedProduct = productsMap[suggestedConnection.externalProductId];
    return { suggestedProduct, suggestedConnection };
  }, [
    suggestionsInitialized,
    shouldShowSuggestions,
    isLoading,
    pendingVodConnectionSuggestions,
    productsMap,
  ]);

  return {
    handleSuggestionRejected,
    handleSuggestionAccepted,
    loading: !suggestionsInitialized || isLoading,
    pendingVodConnectionSuggestions,
    suggestedProduct,
    suggestedConnection,
    hasSuggestion: shouldShowSuggestions && !!suggestedProduct,
  };
};
