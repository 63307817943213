import React from 'react';
import styled from 'styled-components';
import { STATUS_ALL } from 'app/src/constants/responsesInbox.constants';
import SpamIcon from 'app/src/images/SpamIcon';
import { useBilling } from 'app/src/hooks/useBillings';

const ConversationEmptyState = props => {
  const { hasReachedResponsesLimit } = useBilling();

  const getTitle = ({ text, status, empty, hasProfanity }) => {
    if (hasReachedResponsesLimit) {
      return 'Upgrade required';
    }
    if (hasProfanity) {
      return 'Profanity detected';
    }

    if (empty) {
      return 'No responses yet';
    } else if (status) {
      return `No ${status.name} responses`;
    } else if (!text) {
      return 'No conversations yet';
    }
    return text;
  };

  const getBodyText = ({ status, empty, hasProfanity }) => {
    if (hasReachedResponsesLimit) {
      return 'Please upgrade to open the chat and inbox feature.';
    }

    if (hasProfanity) {
      return 'Are you sure you want to continue?';
    }

    if (empty) {
      return 'Publish your Tolstoy to collect your first response';
    } else if (status) {
      const checkOutInbox = status.id === STATUS_ALL ? 'Archive' : 'All';
      return `Check out ${checkOutInbox} inbox to see all your responses.`;
    } else if (!status && !empty) {
      return 'Share your Tolstoy to start a new conversation';
    }
    return '';
  };

  const getExtraElements = ({ emptyStateActionButton }) => {
    if (!emptyStateActionButton) {
      return null;
    }

    return emptyStateActionButton;
  };

  const title = getTitle(props);
  const bodyText = getBodyText(props);

  return (
    <StyledConversationEmptyState>
      <ConversationEmptyStateMessage>
        <ConversationEmptyStateTitle>
          {props.hasProfanity && (
            <IconContainer>
              <SpamIcon height={20} width={20} />
            </IconContainer>
          )}
          <TextContainer>{title}</TextContainer>
        </ConversationEmptyStateTitle>
        {bodyText && <ConversationEmptyStateBody>{bodyText}</ConversationEmptyStateBody>}
      </ConversationEmptyStateMessage>
      {getExtraElements(props)}
    </StyledConversationEmptyState>
  );
};

export default ConversationEmptyState;

const StyledConversationEmptyState = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ConversationEmptyStateMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextContainer = styled.div``;

const ConversationEmptyStateTitle = styled.div`
  display: flex;
  font-size: 24px;
  font-weight: 700;
`;

const ConversationEmptyStateBody = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

const IconContainer = styled.div`
  margin: 0 8px;
  display: flex;
  align-items: center;
`;
