import styled from 'styled-components';

export const PageTitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  letter-spacing: -1.5px;
  color: ${({ theme }) => theme.colors.gray12};
  margin-left: 30px;
  margin-right: 30px;
  width: 100%;
  @media (max-width: 768px) {
    font-size: 18px;
  }

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    margin-left: 8px;
    margin-right: 4px;
    font-size: 16px;
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 22px;
  color: ${({ theme }) => theme.colors.gray12};
  margin-bottom: 20px;
`;

export const Label = styled.div`
  font-size: 12px;
  color: rgba(51, 51, 51, 0.87);
`;
