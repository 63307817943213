import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import FeedOverlayLogo from 'player/src/components/feed/feed_overlay/FeedOverlayLogo';
import { DOWN, UP } from 'shared/react/components/complex/carousel/carousel.constants';
import Utils from 'player/src/utils';
import SharedUtils from 'shared/react/utils/utils';
import {
  getAdjacentSteps,
  getOutOfViewSteps,
  getVideoIndexFromTarget,
} from 'shared/react/components/complex/feed/feed_utils/feed.utils';
import FeedAssets from 'player/src/components/feed/feed_player/FeedAssets';
import useFeedWidgetMessaging from 'player/src/components/feed/feed_hooks/useFeedWidgetMessaging';
import useSwipeUpAnimation from 'shared/react/components/complex/context/hooks/useSwipeUpAnimation';
import useTracker from 'shared/react/components/complex/context/hooks/useTracker';
import { INTERSECTION_THRESHOLDS, VIDEO_INDEXES } from 'shared/react/constants/feed.constants';
import useFeedState from 'shared/react/components/complex/context/hooks/useFeedState';
import useFeedPlayerCommands from 'player/src/components/feed/feed_hooks/useFeedPlayerCommands';

const FeedPlayer = ({ currentStepKey, setCurrentStepKey, stepsMap, steps, isSubtitlesEnabled }) => {
  const [, { track }] = useTracker();
  const [, { handleSwipeUpAnimationSeen }] = useSwipeUpAnimation();
  const [{ isPlayerVisible }] = useFeedState();
  const [stepToUpdate, setStepToUpdate] = useState(null);
  const [preStepToUpdate, setPreStepToUpdate] = useState(null);
  const [adjacentSteps, setAdjacentSteps] = useState(
    getAdjacentSteps({ stepsMap, currentStepKey })
  );
  const [outOfViewSteps, setOutOfViewSteps] = useState(getOutOfViewSteps({ steps, adjacentSteps }));
  const containerRef = useRef(null);
  const videoRef = useRef(null);
  const isClosedRef = useRef(false);
  const [videosObserver, setVideosObserver] = useState(null);
  useFeedPlayerCommands({ videoRef, currentStep: stepsMap[currentStepKey].current });
  useFeedWidgetMessaging({ isClosedRef, videoRef, setCurrentStepKey, currentStepKey });

  const shouldLockScrollWhenTransitioning = Utils.isIos() || Utils.isSafari();
  const shouldApplyFauxScrolling = Utils.isChrome();
  const [isTransitioning, setIsTransitioning] = useState(shouldLockScrollWhenTransitioning);
  const [isFauxScrolling, setIsFauxScrolling] = useState(false);
  const [isLoadingVideo, setIsLoadingVideo] = useState(true);

  const getScrollPosition = direction => {
    const { scrollTop, offsetHeight } = containerRef.current;
    if (direction === UP) {
      return scrollTop - offsetHeight || 0;
    }

    return scrollTop + offsetHeight;
  };

  const handleNavigateToStep = direction => {
    const scrollPosition = getScrollPosition(direction);
    return containerRef.current.scrollTo({
      top: scrollPosition,
      behavior: 'smooth',
    });
  };

  const scrollToCurrentVideo = () => {
    containerRef.current.scrollTo({ top: containerRef.current.clientHeight });
  };

  const replaceCurrentVideo = (adjacentSteps, newStepIndex) => {
    const currentVideo = adjacentSteps.find(({ isCurrentVideo }) => isCurrentVideo);
    currentVideo.isCurrentVideo = false;
    adjacentSteps[newStepIndex].isCurrentVideo = true;
  };

  const getNewStepIndex = ({ target, isIntersecting: isScrollingToNewVideo }) => {
    if (isScrollingToNewVideo) {
      return getVideoIndexFromTarget(target);
    }

    return VIDEO_INDEXES.current;
  };

  const handlePreSnap = entry => {
    const { target, isIntersecting } = entry;

    const newStepIndex = getNewStepIndex({ target, isIntersecting });

    track('feedScroll', {
      direction: newStepIndex === VIDEO_INDEXES.prev ? UP : DOWN,
      videoTime: 1,
    });

    setPreStepToUpdate(newStepIndex);
  };

  const handleTransition = value => {
    if (!shouldLockScrollWhenTransitioning) {
      return;
    }

    setIsTransitioning(value);
  };

  const handleSnap = entry => {
    const { target } = entry;

    const newStepIndex = getVideoIndexFromTarget(target);

    if (newStepIndex === VIDEO_INDEXES.current) {
      return;
    }

    handleTransition(true);
    setStepToUpdate(newStepIndex);
    handleSwipeUpAnimationSeen();
  };

  const handleObserver = entries => {
    entries.forEach(entry => {
      const { intersectionRatio, isIntersecting } = entry;

      // This is because sometimes the observer fires events with intersectionRatio < INTERSECTION_THRESHOLDS.preSnap
      // It's unexpected behavior by the observer, so we need to filter it out
      if (!isIntersecting || intersectionRatio < INTERSECTION_THRESHOLDS.preSnap) {
        return;
      }

      if (intersectionRatio === INTERSECTION_THRESHOLDS.snap) {
        return handleSnap(entry);
      }

      handlePreSnap(entry);
    });
  };

  // TODO: remove this once OOS filtration logic moved to server
  useEffect(() => {
    setAdjacentSteps(getAdjacentSteps({ stepsMap, currentStepKey }));
    setOutOfViewSteps(getOutOfViewSteps({ steps, adjacentSteps }));
  }, [steps]);

  useEffect(() => {
    if (!containerRef?.current) {
      return;
    }

    scrollToCurrentVideo();
  }, [containerRef?.current]);

  /*
   * This effect is responsible for applying faux scrolling on Chrome Desktop
   * It listens to the wheel event and if the user is scrolling with the mouse wheel, it will prevent the default behavior
   * and scroll to the next/previous video
   * This is due a bug where scrolling with the mouse wheel on Chrome triggers an extra video scroll
   * this DOES NOT work well when the side panel opens/closes between videos
   */
  useEffect(() => {
    if (!containerRef?.current || !shouldApplyFauxScrolling) {
      return;
    }

    const fauxScroll = direction => {
      if (isFauxScrolling) {
        return;
      }

      setIsFauxScrolling(true);
      handleNavigateToStep(direction);
    };

    const onWheel = e => {
      const isMousepad = e.deltaY === e.wheelDeltaY * -3;
      const isMouseWheel = e.wheelDeltaY % 120 === 0 || e.wheelDeltaY % -120 === 0;

      if (isMouseWheel && !isMousepad) {
        e.preventDefault();

        const direction = e.deltaY > 0 ? DOWN : UP;

        fauxScroll(direction);
        return;
      }

      // removing the event listener to prevent faux scrolling when the user is scrolling with the mousepad
      containerRef.current?.removeEventListener('wheel', onWheel);
    };

    // removing the wheel event listener if the user is scrolling with touch
    const onTouchStart = () => {
      containerRef.current?.removeEventListener('wheel', onWheel);
    };

    containerRef.current?.addEventListener('wheel', onWheel);
    containerRef.current?.addEventListener('touchstart', onTouchStart, { once: true });

    return () => {
      containerRef.current?.removeEventListener('wheel', onWheel);
      containerRef.current?.removeEventListener('touchstart', onTouchStart);
    };
  }, [containerRef?.current, shouldApplyFauxScrolling, isFauxScrolling]);

  useEffect(() => {
    if (!containerRef?.current || !isPlayerVisible) {
      return;
    }

    const observerOptions = {
      root: containerRef.current,
      rootMargin: '0px',
      threshold: [INTERSECTION_THRESHOLDS.preSnap, INTERSECTION_THRESHOLDS.snap],
    };

    const newObserver = new IntersectionObserver(handleObserver, observerOptions);

    setVideosObserver(newObserver);

    return () => {
      videosObserver?.disconnect();
    };
  }, [containerRef?.current, isPlayerVisible]);

  useEffect(() => {
    if (preStepToUpdate === null) {
      return;
    }

    videoRef.current.pause();
    videoRef.current.currentTime = 0;

    const adjacentStepsCopy = SharedUtils.deepCopyObject(adjacentSteps);
    replaceCurrentVideo(adjacentStepsCopy, preStepToUpdate);

    setPreStepToUpdate(null);
    setAdjacentSteps(adjacentStepsCopy);
  }, [preStepToUpdate, adjacentSteps, videoRef.current]);

  useEffect(() => {
    if (stepToUpdate === null || isClosedRef.current) {
      return;
    }

    Utils.scheduleCallbackToNextRender(() => {
      setCurrentStepKey(adjacentSteps[stepToUpdate].key);
    });
  }, [stepToUpdate]);

  useEffect(() => {
    const newAdjacentSteps = getAdjacentSteps({ stepsMap, currentStepKey });
    const newOutOfViewSteps = getOutOfViewSteps({ steps, adjacentSteps: newAdjacentSteps });
    setAdjacentSteps(newAdjacentSteps);
    setOutOfViewSteps(newOutOfViewSteps);
    setStepToUpdate(null);

    if (Utils.isIos() || Utils.isSafari()) {
      scrollToCurrentVideo();
      handleTransition(false);
      return;
    }

    requestAnimationFrame(() => {
      setIsFauxScrolling(false);
      scrollToCurrentVideo();
    });
    handleTransition(false);
  }, [currentStepKey]);

  return (
    <LayoutRoot>
      <FeedOverlayLogo />
      <Content ref={containerRef} isTransitioning={isTransitioning}>
        <FeedAssets
          adjacentSteps={adjacentSteps}
          videoRef={videoRef}
          handleNavigateToStep={handleNavigateToStep}
          stepsMap={stepsMap}
          outOfViewSteps={outOfViewSteps}
          videosObserver={videosObserver}
          isLoadingVideo={isLoadingVideo}
          setIsLoadingVideo={setIsLoadingVideo}
          isSubtitlesEnabled={isSubtitlesEnabled}
        />
      </Content>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  scroll-snap-type: y mandatory;
  overflow-y: ${({ isTransitioning }) => (isTransitioning ? 'hidden' : 'auto')};
  overflow-x: hidden;
  position: relative;
  overscroll-behavior: none;

  ::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;

  @media (max-width: 450px) {
    max-width: 100%;
  }
`;

export default FeedPlayer;
