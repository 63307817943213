import React, { useState } from 'react';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import styled from 'styled-components';
import { useApps } from 'app/src/context/AppsStore';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';
import StandardModal from 'app/src/pages/modals/StandardModal';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Button from 'shared/react/components/complex/Button';
import { BolderTextSmall, TextH5 } from 'shared/react/components/complex/Text';
import { CONNECT_MAGENTO_CONFIRMATION_DATA_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import FormInput from '../../FormInput';

const ConnectToMagentoModal = () => {
  const [, { clearModalState }] = useModal();
  const [, { authenticateMagentoToken }] = useApps();
  const [, { setErrorSnackbar }] = useSnackBar();
  const [appUrl, setAppUrl] = useState('');
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(false);

  const cleanAppUrl = () => {
    const removeHttp = url => {
      return url.replace(/^https?:\/\//, '');
    };

    return removeHttp(appUrl);
  };

  const onClick = async () => {
    setLoading(true);
    const isAuthenticated = await authenticateMagentoToken(token, cleanAppUrl(appUrl));
    setLoading(false);

    if (isAuthenticated) {
      clearModalState();
      return;
    }

    setErrorSnackbar('Failed to connect. Please verify the url and token and try again.');
  };

  const isEnabled = () => {
    return appUrl.length && token.length && !loading;
  };

  const handleAppUrlChange = e => {
    setAppUrl(e.target.value);
  };

  const handleTokenChange = e => {
    setToken(e.target.value);
  };

  return (
    <StyledStandardModal open={true} onClose={clearModalState} width="384px">
      <ModalContainer>
        <TextH5>Connect Magento</TextH5>
        <ConnectDataItem>
          <FieldNameText>App Url</FieldNameText>
          <StyledTextField
            label="App Url"
            value={appUrl}
            inputProps={{ maxLength: 200 }}
            onChange={handleAppUrlChange}
            variant="outlined"
            placeholder="help.gotolstoy.com"
          />
        </ConnectDataItem>
        <ConnectDataItem>
          <FieldNameText>Access Token</FieldNameText>
          <StyledTextField
            label="Access Token"
            value={token}
            inputProps={{ maxLength: 200 }}
            onChange={handleTokenChange}
            variant="outlined"
          />
        </ConnectDataItem>
        <ButtonContainer>
          <ActionButton
            disabled={!isEnabled()}
            loading={loading}
            onClick={onClick}
            data-test-id={CONNECT_MAGENTO_CONFIRMATION_DATA_TEST_ID}
          >
            Connect
          </ActionButton>
        </ButtonContainer>
      </ModalContainer>
    </StyledStandardModal>
  );
};

export default ConnectToMagentoModal;

const StyledStandardModal = styled(StandardModal)`
  padding: 24px;
  border-radius: 12px;
`;

const ModalContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  gap: 40px;
  padding-top: 10px;
`;

const ButtonContainer = styled(HorizontalFlex)`
  align-self: end;
  justify-content: flex-end;
  justify-self: end;
`;

const ActionButton = styled(Button)`
  grid-row: 1;
  grid-column: 1;
  opacity: ${props => (props.loading ? '0.4' : '1.0')};
  margin-left: 7px;
`;

const StyledTextField = styled(FormInput)``;

const FieldNameText = styled(BolderTextSmall)``;

const ConnectDataItem = styled(VerticalFlex)`
  gap: 4px;
`;
