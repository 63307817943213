import React from 'react';

function TeamIcon({ fill = '#3D4FE0' }) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.969 12a3.228 3.228 0 0 0 3.25-3.25 3.248 3.248 0 0 0-3.25-3.25 3.268 3.268 0 0 0-3.25 3.25A3.247 3.247 0 0 0 9.969 12Zm0-5c.969 0 1.75.813 1.75 1.75 0 .969-.781 1.75-1.75 1.75-.938 0-1.75-.781-1.75-1.75 0-.938.812-1.75 1.75-1.75ZM16 7c1.375 0 2.5-1.094 2.5-2.5C18.5 3.125 17.375 2 16 2a2.487 2.487 0 0 0-2.5 2.5C13.5 5.906 14.594 7 16 7Zm-4.469 6H8.437C5.97 13 4 14.875 4 17.188c0 .468.375.812.875.812h10.219c.5 0 .906-.344.906-.813C16 14.876 14 13 11.531 13Zm-5.969 3.5c.344-1.125 1.5-2 2.875-2h3.094c1.375 0 2.531.875 2.875 2H5.562ZM17.22 8h-1.907c-.406 0-.78.125-1.124.281 0 .156.03.313.03.469 0 1.063-.374 2.031-1.03 2.75h6.25a.56.56 0 0 0 .562-.563C20 9.313 18.75 8 17.219 8Zm-11.5.75c0-.156.031-.313.062-.469A2.311 2.311 0 0 0 4.656 8H2.75C1.219 8 0 9.313 0 10.938c0 .312.219.562.531.562h6.25A4.073 4.073 0 0 1 5.72 8.75ZM4 7c1.375 0 2.5-1.094 2.5-2.5C6.5 3.125 5.375 2 4 2a2.487 2.487 0 0 0-2.5 2.5C1.5 5.906 2.594 7 4 7Z"
        fill={fill}
      />
    </svg>
  );
}

export default TeamIcon;
