import React from 'react';

const SendIcon = ({ fill = 'white', background = '#3D4FE0' }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
        fill={background}
      />
      <path
        d="M22 16.5C22 16.2188 21.8125 15.9375 21.5312 15.8203L11.0312 11.3203C10.75 11.2031 10.3984 11.2734 10.1875 11.5078C9.97656 11.7188 9.92969 12.0703 10.0703 12.3516L11.8047 15.7969L17.5 16.5L11.8047 17.2266L10.0703 20.6719C9.92969 20.9531 9.97656 21.3047 10.1875 21.5156C10.3281 21.6797 10.5391 21.75 10.75 21.75C10.8438 21.75 10.9375 21.75 11.0312 21.7031L21.5312 17.2031C21.8125 17.0859 22 16.8047 22 16.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default SendIcon;
