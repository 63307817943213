import React from 'react';
import AnalyticsEmailChartBox from './AnalyticsEmailChartBox';
import AnalyticsEmailRevenueTooltip from './AnalyticsEmailRevenueTooltip';
import ChartHeader from '../chart/ChartHeader';
import ComposedChart from '../chart/ComposedChart';
import styled from 'styled-components';
import Utils from 'app/src/utils';
import { ChartStats } from '../chart/ChartStats';
import { EmailConversionOvertimeRecord } from 'app/src/services/cube/types/getEmailConvertions.types';
import {
  ANALYTICS_EMAIL_CHARTS,
  ANALYTICS_OVERVIEW_TOTAL_REVENUE,
} from 'app/src/constants/analytics.constants';

type Props = {
  currency: string;
  emailConversionsOvertime: EmailConversionOvertimeRecord[] | null;
  isLoadingEmailConversionsOvertime: boolean;
};

const AnalyticsEmailRevenueOvertime = ({
  currency,
  emailConversionsOvertime,
  isLoadingEmailConversionsOvertime,
}: Props) => {
  const { title, bars, lines } = ANALYTICS_EMAIL_CHARTS.EMAIL_REVENUE;

  const directRevenue = Utils.sumBy(emailConversionsOvertime, record => record.directRevenue);
  const indirectRevenue = Utils.sumBy(emailConversionsOvertime, record => record.indirectRevenue);
  const totalRevenue = directRevenue + indirectRevenue;

  const formatMoney = (value: number) => Utils.formatNumber({ currency, value, letter: false });
  const formattedDirectRevenue = formatMoney(directRevenue);
  const formattedIndirectRevenue = formatMoney(indirectRevenue);
  const formattedTotalRevenue = formatMoney(totalRevenue);

  const chartStatsBreakdown = [
    {
      name: ANALYTICS_OVERVIEW_TOTAL_REVENUE.DIRECT,
      formattedValue: formattedDirectRevenue,
      tooltipText: ANALYTICS_OVERVIEW_TOTAL_REVENUE.DIRECT_TOOLTIP,
      value: directRevenue,
    },
    {
      name: ANALYTICS_OVERVIEW_TOTAL_REVENUE.INDIRECT,
      formattedValue: formattedIndirectRevenue,
      tooltipText: ANALYTICS_OVERVIEW_TOTAL_REVENUE.INDIRECT_TOOLTIP,
      value: indirectRevenue,
    },
  ];

  const yTickFormatter = (value: string) => {
    return Utils.formatNumber({ currency, value });
  };

  return (
    <StyledAnalyticsEmailChartBox shouldShowLoader={isLoadingEmailConversionsOvertime}>
      <StyledChartHeader subtitle={formattedTotalRevenue} title={title} />
      <ChartStats rows={chartStatsBreakdown} shouldShowEmptyState={!emailConversionsOvertime} />
      <ComposedChart
        data={emailConversionsOvertime || []}
        bars={bars}
        lines={lines}
        margin={{ left: -25 }}
        renderCustomTooltip={AnalyticsEmailRevenueTooltip}
        shouldShowEmptyState={!emailConversionsOvertime}
        yTickFormatter={yTickFormatter}
      />
    </StyledAnalyticsEmailChartBox>
  );
};

const StyledAnalyticsEmailChartBox = styled(AnalyticsEmailChartBox)`
  gap: 24px;
`;

const StyledChartHeader = styled(ChartHeader)`
  max-height: 26px;
  overflow: visible;

  &:first-child {
    text-align: left;
  }
`;

export default AnalyticsEmailRevenueOvertime;
