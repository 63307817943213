import {
  APP_KEY_LOCAL_STORAGE_KEY,
  CUBE_JS_KEY,
  GHOST_ACCOUNT_LOCAL_STORAGE_KEY,
  GHOST_ACCOUNT_QUERY_PARAM,
} from 'app/src/constants/tolstoy.constants';
import Utils from '../../utils';

export const LOGIN_ERROR = 'Something is wrong with your login or password';
export const INVITE_EXPIRED_TYPE = 'inviteExpired';

export const INVITE_EXPIRED_ERROR =
  'This invite is expired. Please contact your workspace owner for a new invite.';
export const RESET_PASSWORD_ERROR = 'Failed to reset password';
export const USER_ALREADY_EXISTS_ERROR = 'User already exists';
export const DEFAULT_PASSWORD_ERROR_MESSAGE = 'Your password is invalid.';

export const getSignupPasswordError = errorMessage => {
  const message = errorMessage?.toLowerCase();

  if (!message) {
    return DEFAULT_PASSWORD_ERROR_MESSAGE;
  }

  if (message.includes('not long enough')) {
    return 'Minimum password length is 8 characters.';
  }

  if (message.includes('must have uppercase characters')) {
    return 'Your password must have uppercase characters.';
  }

  if (message.includes('must have numeric characters')) {
    return 'Your password must have numeric characters.';
  }

  if (message.includes('must have symbol characters')) {
    return 'Your password must have symbol characters.';
  }

  if (message.includes('must have lowercase characters')) {
    return 'Your password must have lowercase characters.';
  }

  return DEFAULT_PASSWORD_ERROR_MESSAGE;
};

export const getErrorMessage = error => {
  if (error?.toString()?.includes(INVITE_EXPIRED_TYPE)) {
    return INVITE_EXPIRED_ERROR;
  }

  const errorCodes = {
    UsernameExistsException: USER_ALREADY_EXISTS_ERROR,
    InvalidPasswordException: getSignupPasswordError(error?.message),
  };
  return errorCodes[error?.code] || LOGIN_ERROR;
};

export const clearAmplifyLocalStorage = () => {
  const keys = Object.keys(localStorage);
  keys.forEach(key => {
    if (key.includes('CognitoIdentityServiceProvider')) {
      localStorage.removeItem(key);
    }
  });
};

export const clearGhostAccountIfNeeded = () => {
  if (!location?.href?.includes(`${GHOST_ACCOUNT_QUERY_PARAM}=true`)) {
    localStorage.removeItem(GHOST_ACCOUNT_LOCAL_STORAGE_KEY);
  }
};

export const clearLocalStorage = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('app');
  localStorage.removeItem(APP_KEY_LOCAL_STORAGE_KEY);
  localStorage.removeItem(CUBE_JS_KEY);
  clearAmplifyLocalStorage();
  Utils.clearInviteIfNeeded();
  Utils.clearTolstoyGroupIfNeeded();
  clearGhostAccountIfNeeded();
};

export const monthPastUnixTime = startTime => {
  return (Date.now() / 1000 - startTime) / 86400 > 28;
};
