import React from 'react';
import InfiniteLoader from 'react-window-infinite-loader';
import { FixedGrid, FixedGridProps } from 'src/complex_components/grid/FixedGrid';

type InfiniteGridProps = {
  itemCount: number;
  threshold?: number;
  pageSize?: number;
  overscan?: number;
  isItemLoaded?: (index: number) => boolean;
  loadMore?: (startIndex: number, stopIndex: number) => Promise<void> | void;
} & Omit<FixedGridProps, 'rowCount' | 'itemCount'>;

/*
  If itemCount is the real size of existing data
  use 'overscan' to trigger loadMore
*/
export const InfiniteGrid = ({
  pageSize,
  itemCount,
  threshold,
  children,
  overscan = 0,
  isItemLoaded,
  loadMore = () => {},
  ...props
}: InfiniteGridProps) => {
  const defaultIsItemLoaded = (index: number) => overscan + index < itemCount;

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded || defaultIsItemLoaded}
      itemCount={itemCount + overscan}
      loadMoreItems={loadMore}
      minimumBatchSize={pageSize}
      threshold={threshold || pageSize}
    >
      {({ onItemsRendered, ref }) => {
        /*
          assuming that all columns are visible, calculate visible and overscan indexes
        */
        const onGridItemsRendered = gridProps => {
          onItemsRendered({
            overscanStartIndex: (gridProps.overscanRowStartIndex + 1) * gridProps.columnCount,
            overscanStopIndex: (gridProps.overscanRowStopIndex + 1) * gridProps.columnCount,
            visibleStartIndex: (gridProps.visibleRowStartIndex + 1) * gridProps.columnCount,
            visibleStopIndex: (gridProps.visibleRowStopIndex + 1) * gridProps.columnCount,
          });
        };

        return (
          <FixedGrid
            ref={ref}
            itemCount={itemCount}
            onItemsRendered={onGridItemsRendered}
            {...props}
          >
            {children}
          </FixedGrid>
        );
      }}
    </InfiniteLoader>
  );
};

export default InfiniteGrid;
