import React from 'react';
import styled from 'styled-components';
import { useVideos } from 'src/context/VideosStore';
import TrashIcon from 'src/images/sidebar-v2/TrashIcon';
import ImageWithFallback from 'shared/react/images/ImageWithFallback';
import { AssetRenditionsService } from 'shared/react/services/assets';

type Props = {
  isSelected: boolean;
  vodAssetId: string;
  onClick: () => void;
  onDeleteClick: ({ vodAssetId }: { vodAssetId: string }) => void;
};

const VerticalVideoPreviewCard = ({ isSelected, vodAssetId, onClick, onDeleteClick }: Props) => {
  const [{ video }] = useVideos(vodAssetId);

  const handleDeleteClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    onDeleteClick({ vodAssetId });
  };

  const posterUrl = AssetRenditionsService.getAssetPoster(video);
  return (
    <LayoutRoot onClick={onClick} isSelected={isSelected}>
      <Image src={posterUrl} />
      <CardOverlay>
        <StyledTrashIcon onClick={handleDeleteClick} />
      </CardOverlay>
    </LayoutRoot>
  );
};

const CardOverlay = styled.div`
  position: absolute;
  inset: 0;
  background: ${({ theme }) => theme.getHexOpacity(theme.colors.black, 25)};
  border-radius: 6px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
`;

const LayoutRoot = styled.div<{ isSelected: boolean }>`
  position: relative;
  width: 119px;
  height: 156px;
  cursor: pointer;
  border-radius: 6px;
  outline: ${({ isSelected, theme }) => (isSelected ? `2px solid ${theme.colors.primary}` : '')};
  &:hover {
    ${CardOverlay} {
      opacity: 1;
    }
  }
`;

const Image = styled(ImageWithFallback)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
`;

const StyledTrashIcon = styled(TrashIcon)`
  position: absolute;
  top: 6px;
  right: 6px;

  & path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export default VerticalVideoPreviewCard;
