import React from 'react';
import styled from 'styled-components';
import {
  Header,
  HeaderContainer,
  SubHeader,
} from 'app/src/layouts/tolstoy_empty_screen/emptyScreenCss';
import Button from 'shared/react/components/complex/Button';
import Utils from 'shared/react/utils/utils';
import { DOV_CALENDLY_LINK } from 'app/src/constants/url.constants';

const DowngradeAreYouSure = () => {
  const onBookMeeting = () => {
    Utils.openInNewTab(DOV_CALENDLY_LINK);
  };

  return (
    <>
      <CustomHeaderContainer>
        <Header>Are you sure you want to downgrade?</Header>
        <SubHeader>
          Book a meeting with our CEO Dov Kauffman. He would love to have a 1-on-1 call with you to
          hear you out, converse about your issues or just chat about the weather. We are here for
          you even when you go.
        </SubHeader>
        <Button onClick={onBookMeeting}>Book meeting</Button>
      </CustomHeaderContainer>
    </>
  );
};

const CustomHeaderContainer = styled(HeaderContainer)`
  height: 100%;
  align-content: center;
  justify-content: center;
  gap: 24px;
  align-items: center;
  max-width: 465px;
`;

export default DowngradeAreYouSure;
