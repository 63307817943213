import { REACT_APP_VIDEO_OUTPUT } from 'player/src/config/player.config';
import { getUploadUrl, uploadToS3 } from '../helpers/s3/s3-helpers';

const getResponseVideoPublicPath = ({ filePath, fileId }) => {
  let videoPublicPath = `https://${REACT_APP_VIDEO_OUTPUT}/${filePath}`;
  videoPublicPath = videoPublicPath.replace('.webm', `/${fileId}.mp4`);

  return videoPublicPath;
};

export const getThumbnailFromVideoPublicPath = videoPublicPath => {
  return videoPublicPath.replace('.mp4', '.0000000.jpg');
};

export const uploadVideo = async ({
  accountId,
  onProgress,
  recordingType,
  body,
  appKey,
  mirror = false,
}) => {
  const { fileId, filePath, uploadURL } = await getUploadUrl({
    type: 'video',
    accountId,
    recordingType,
    mirror: mirror,
    appKey,
    originalSize: body.size,
  });
  await uploadToS3(uploadURL, body, onProgress);

  const videoUrl = getResponseVideoPublicPath({ filePath, fileId });

  return { fileId, filePath, filePublicPath: videoUrl };
};
