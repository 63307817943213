import React from 'react';
import styled from 'styled-components';
import VideoResponseButtonIcon from '../../../assets/VideoResponseButtonIcon';
import AudioResponseButtonIcon from '../../../assets/AudioResponseButtonIcon';
import TextResponseButtonIcon from '../../../assets/TextResponseButtonIcon';
import {
  AUDIO_RESPONSE_KEY,
  CALENDLY_RESPONSE_KEY,
  FREE_TEXT_RESPONSE_KEY,
  VIDEO_RESPONSE_KEY,
} from '../../../constants/tolstoy.constants';
import CalendlyResponseButtonIcon from '../../../assets/CalendlyResponseButtonIcon';
import { IconButton } from '../../common/commonStyles';

const MAX_ANSWERS_LENGTH_FOR_LARGE_BUTTON = 3;
const LARGE_BUTTON_SIZE = '80px';
const MEDIUM_BUTTON_SIZE = '64px';

function ResponseAnswers({ answers, onClick, setNextRef, disabled, onKeyDown }) {
  function getPriorities() {
    const priorities = {};

    priorities[VIDEO_RESPONSE_KEY] = 1;
    priorities[AUDIO_RESPONSE_KEY] = 2;
    priorities[FREE_TEXT_RESPONSE_KEY] = 3;
    priorities[CALENDLY_RESPONSE_KEY] = 4;

    return priorities;
  }

  function handleClick(e, responseKey = VIDEO_RESPONSE_KEY) {
    e.stopPropagation();
    onClick(
      e.target,
      answers.find(answer => answer.type === responseKey)
    );
  }

  function getResponseButton(type) {
    let config = null;

    switch (type) {
      case VIDEO_RESPONSE_KEY:
        config = {
          icon: <VideoResponseButtonIcon />,
          backgroundColor: '#FEE1E6',
          onClick: e => handleClick(e, VIDEO_RESPONSE_KEY),
        };
        break;
      case AUDIO_RESPONSE_KEY:
        config = {
          icon: <AudioResponseButtonIcon />,
          backgroundColor: '#D3F8EC',
          onClick: e => handleClick(e, AUDIO_RESPONSE_KEY),
        };
        break;
      case FREE_TEXT_RESPONSE_KEY:
        config = {
          icon: <TextResponseButtonIcon />,
          backgroundColor: '#E2EDFE',
          onClick: e => handleClick(e, FREE_TEXT_RESPONSE_KEY),
        };
        break;
      case CALENDLY_RESPONSE_KEY:
        config = {
          icon: <CalendlyResponseButtonIcon />,
          backgroundColor: '#fbecd0',
          onClick: e => handleClick(e, CALENDLY_RESPONSE_KEY),
        };
        break;
      default:
        return null;
    }

    return (
      <IconButton
        size={
          answers.length <= MAX_ANSWERS_LENGTH_FOR_LARGE_BUTTON
            ? LARGE_BUTTON_SIZE
            : MEDIUM_BUTTON_SIZE
        }
        backgroundColor={config.backgroundColor}
        onClick={config.onClick}
        disabled={disabled}
        key={type}
        tabIndex="0"
        onKeyDown={onKeyDown}
        ref={setNextRef}
        aria-label={`${type} Answer button`}
        aria-disabled={disabled}
      >
        {config.icon}
      </IconButton>
    );
  }

  function compareTypes(answerA, answerB) {
    if (priorities[answerA.type] < priorities[answerB.type]) {
      return -1;
    }
    if (priorities[answerA.type] > priorities[answerB.type]) {
      return 1;
    }
    return 0;
  }

  const priorities = getPriorities();

  return (
    <LayoutRoot>
      {answers.sort(compareTypes).map((answer, index) => getResponseButton(answer.type, index))}
    </LayoutRoot>
  );
}

export default ResponseAnswers;

const LayoutRoot = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
  padding-top: 3px;
`;
