import React from 'react';
import styled from 'styled-components';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import EditorColorInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorColorInputItem';
import EditorOnOffToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorOnOffToggleItem';
import EditorTextInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorTextInputItem';
import {
  BUBBLE_TEXT_ENABLED_PROPERTY_KEY,
  BUBBLE_TEXT_PROPERTY_KEY,
  NOTIFICATION_BADGE_PROPERTY_KEY,
  THEME_TEXT_COLOR_PROPERTY_KEY,
} from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import EditorHeader from '../shared/EditorHeader';
import EditorMainContainer from '../shared/EditorMainContainer';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

function AddOnsEditor() {
  const {
    customizationSettings: {
      widgetNotificationBadge,
      widgetSettings: { bubbleTextEnabled, bubbleText, themeTextColor },
    },
    setCustomizationSettingsProperty,
    setWidgetSettingsProperty,
  } = useLookAndFeelContext();

  return (
    <LayoutRoot>
      <EditorHeader title="Add-ons" />
      <EditorMainContainer>
        <EditorOnOffToggleItem
          text="Notification badge"
          checked={!!widgetNotificationBadge}
          onChange={value =>
            setCustomizationSettingsProperty(NOTIFICATION_BADGE_PROPERTY_KEY, value)
          }
        />
        <EditorOnOffToggleItem
          text="Text bubble"
          checked={!!bubbleTextEnabled}
          onChange={value => setWidgetSettingsProperty(BUBBLE_TEXT_ENABLED_PROPERTY_KEY, value)}
        />
        {bubbleTextEnabled && (
          <>
            <EditorTextInputItem
              text="Bubble text"
              value={bubbleText}
              placeholder="Chat with me"
              onChange={value => setWidgetSettingsProperty(BUBBLE_TEXT_PROPERTY_KEY, value)}
            />
            <EditorColorInputItem
              text="Text color"
              color={themeTextColor}
              onChange={value => setWidgetSettingsProperty(THEME_TEXT_COLOR_PROPERTY_KEY, value)}
            />
          </>
        )}
      </EditorMainContainer>
    </LayoutRoot>
  );
}

export default AddOnsEditor;

const LayoutRoot = styled(VerticalFlex)``;
