import { useMemo } from 'react';
import APP_CONFIG from 'app/src/config/app.config';
import useDynamicVideoIdsByProject from 'app/src/hooks/use-dynamic-video-ids-by-project/useDynamicVideoIdsByProject';
import { getProjectEmailSettings } from 'app/src/utils/project.utils';
import { project as Project } from 'app/src/types/entities';
import { useGetNotBubbleRuleGroupByProjectId } from 'app/src/context/RulesStore';
import { useVideoActions } from 'app/src/context/VideosStore';
import { v4 as uuidv4 } from 'uuid';
import useHostProjectParams from 'src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/hooks/useHostProjectParams';
import { ONSITE_TARGET_PAGES } from 'src/pages/dashboard/constants/onsiteTargetPages.constants';
import { getAutomationToolProductIdVariable } from './automation-tool.utils';
import Utils from 'app/src/utils';

export type EmailAsset = {
  actionUrlParams: Record<string, string | number>;
  dynamic: boolean;
  gifUrl: string;
  imageUrl: string;
  videoId: string;
};

type UseEmailAssetsProps = {
  maxAssetsCount?: number;
  productIdVariable?: string;
  project: Project;
};

type GetStaticEmailAssetsProps = UseEmailAssetsProps & {
  videoIds: string[];
  videoActions: ReturnType<typeof useVideoActions>;
};

type GetDynamicEmailAssetsProps = UseEmailAssetsProps & {
  videoIds: string[];
  templateId: string;
};

const withoutNilParams = <T extends Record<string, unknown>>(params: T): T => {
  const copy = { ...params };
  for (const key in copy) {
    if (copy[key] == null) {
      delete copy[key];
    }
  }

  return copy;
};

const getStaticEmailAssets = ({
  maxAssetsCount,
  productIdVariable,
  project,
  videoActions,
  videoIds,
}: GetStaticEmailAssetsProps): EmailAsset[] => {
  const { getVideoGif, getVideoPosterLarge, getVideoById } = videoActions;
  const widgetSettings = Utils.safeParse(project.widgetSettings);
  const emailAssets: EmailAsset[] = [];
  for (const videoId of videoIds) {
    const video = getVideoById(videoId);
    if (!video) {
      continue;
    }

    const gifUrl = getVideoGif(video);
    if (!gifUrl) {
      continue;
    }

    const imageUrl = getVideoPosterLarge(video);

    emailAssets.push({
      dynamic: false,
      gifUrl,
      imageUrl,
      videoId,
      actionUrlParams: withoutNilParams({
        tolstoyAutoOpen: widgetSettings.isFullscreenFeed ? project.publishId : null,
        tolstoyStartVideo: video.id,
        productId: productIdVariable,
        tolstoy: project.publishId,
      }),
    });

    if (emailAssets.length >= maxAssetsCount) {
      return emailAssets;
    }
  }

  return emailAssets;
};

const getDynamicEmailAssets = ({
  maxAssetsCount,
  productIdVariable,
  project,
  templateId,
  videoIds,
}: GetDynamicEmailAssetsProps): EmailAsset[] => {
  const proxyUrl = `${APP_CONFIG.API_URL}/settings/${project.publishId}/email-campaign/tiles/preview`;

  const emailAssets: EmailAsset[] = [];
  const assetsCount = Math.min(videoIds.length, maxAssetsCount);
  const productIdQueryParam = productIdVariable ? `&productId=${productIdVariable}` : '';

  for (let i = 0; i < assetsCount; i++) {
    emailAssets.push({
      dynamic: true,
      gifUrl: `${proxyUrl}?index=${i}&type=dynamic&templateId=${templateId}${productIdQueryParam}`,
      imageUrl: `${proxyUrl}?index=${i}&type=static&templateId=${templateId}${productIdQueryParam}`,
      videoId: videoIds[i],
      actionUrlParams: withoutNilParams({
        index: i,
        templateId,
        productId: productIdVariable,
        tolstoy: project.publishId,
      }),
    });
  }

  return emailAssets;
};

export const useProductIdVariable = (project, automationToolId) => {
  const { targetExternalProductId } = useHostProjectParams();

  if (targetExternalProductId) {
    return targetExternalProductId;
  }

  const isPDP = project?.targetPage === ONSITE_TARGET_PAGES.ProductPages;
  if (!isPDP) {
    return null;
  }

  return getAutomationToolProductIdVariable(automationToolId);
};

export const useEmailAssets = ({
  project,
  maxAssetsCount: maxAssetsCountProp,
  productIdVariable,
}: UseEmailAssetsProps): EmailAsset[] => {
  const [ruleGroup] = useGetNotBubbleRuleGroupByProjectId(project?.id);
  const templateId = useMemo(() => uuidv4(), [project?.id]);
  const videoActions = useVideoActions();
  const emailSettings = getProjectEmailSettings(project);
  const maxAssetsCount = maxAssetsCountProp ?? emailSettings.maxTilesCount;

  const { getVideoIdsByProject } = useDynamicVideoIdsByProject(
    project,
    ruleGroup,
    project?.dynamic
  );
  const videoIds = getVideoIdsByProject(project, ruleGroup);

  if (project?.dynamic) {
    return getDynamicEmailAssets({
      maxAssetsCount,
      productIdVariable,
      project,
      templateId,
      videoIds,
    });
  }

  return getStaticEmailAssets({
    maxAssetsCount,
    productIdVariable,
    project,
    videoActions,
    videoIds,
  });
};
