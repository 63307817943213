import React, { ReactElement } from 'react';
import shopVideosImage from 'app/src/pages/dashboard/components/cases/images/cards/shop-videos.jpg';
import productPageStoriesImage from 'app/src/pages/dashboard/components/cases/images/cards/product-page-stories.jpg';
import homepageSpotlightImage from 'app/src/pages/dashboard/components/cases/images/cards/homepage-spotlight.jpg';
import founderVideoImage from 'app/src/pages/dashboard/components/cases/images/cards/founder-video.jpg';
import forYouImage from 'app/src/pages/dashboard/components/cases/images/cards/for-you.jpg';
import heroVideoImage from 'app/src/pages/dashboard/components/cases/images/cards/hero-video.jpg';
import tapcartAppImage from 'app/src/pages/dashboard/components/cases/images/cards/tapcart-app.jpg';
import tapcartAppPdpImage from 'app/src/pages/dashboard/components/cases/images/cards/tapcart-app-pdp.jpg';
import fuegoPdpImage from 'app/src/pages/dashboard/components/cases/images/cards/fuego-pdp.jpg';
import ForYouIcon from 'app/src/pages/dashboard/components/cases/images/icons/ForYouIcon';
import TapcartIcon from 'app/src/pages/dashboard/components/cases/images/icons/TapcartIcon';
import FuegoIcon from 'app/src/pages/dashboard/components/cases/images/icons/FuegoIcon';
import shopVideosImage1 from 'app/src/pages/dashboard/components/cases/images/modal/shop-videos-1.jpg';
import shopVideosImage2 from 'app/src/pages/dashboard/components/cases/images/modal/shop-videos-2.jpg';
import shopVideosImage3 from 'app/src/pages/dashboard/components/cases/images/modal/shop-videos-3.jpg';
import productPageStoriesImage1 from 'app/src/pages/dashboard/components/cases/images/modal/product-page-stories-1.jpg';
import productPageStoriesImage2 from 'app/src/pages/dashboard/components/cases/images/modal/product-page-stories-2.jpg';
import productPageStoriesImage3 from 'app/src/pages/dashboard/components/cases/images/modal/product-page-stories-3.jpg';
import homepageSpotlightImage1 from 'app/src/pages/dashboard/components/cases/images/modal/homepage-spotlight-1.jpg';
import homepageSpotlightImage2 from 'app/src/pages/dashboard/components/cases/images/modal/homepage-spotlight-2.jpg';
import homepageSpotlightImage3 from 'app/src/pages/dashboard/components/cases/images/modal/homepage-spotlight-3.jpg';
import forYouImage1 from 'app/src/pages/dashboard/components/cases/images/modal/for-you-1.jpg';
import forYouImage2 from 'app/src/pages/dashboard/components/cases/images/modal/for-you-2.jpg';
import forYouImage3 from 'app/src/pages/dashboard/components/cases/images/modal/for-you-3.jpg';
import founderVideoImage1 from 'app/src/pages/dashboard/components/cases/images/modal/founder-video-1.jpg';
import founderVideoImage2 from 'app/src/pages/dashboard/components/cases/images/modal/founder-video-2.jpg';
import heroVideoImage1 from 'app/src/pages/dashboard/components/cases/images/modal/hero-video-1.jpg';
import heroVideoImage2 from 'app/src/pages/dashboard/components/cases/images/modal/hero-video-2.jpg';
import tapcartImage1 from 'app/src/pages/dashboard/components/cases/images/modal/tapcart-image-1.jpg';
import tapcartImage2 from 'app/src/pages/dashboard/components/cases/images/modal/tapcart-image-2.jpg';
import tapcartPdpImage1 from 'app/src/pages/dashboard/components/cases/images/modal/tapcart-pdp-image-1.jpg';
import fuegoPdpImage1 from 'app/src/pages/dashboard/components/cases/images/modal/fuego-pdp-image-1.jpg';
import emailTrendingCardImage from 'app/src/pages/dashboard/components/cases/images/cards/email-trending.jpg';
import emailTrendingModalImage from 'app/src/pages/dashboard/components/cases/images/modal/email-trending.jpg';
import emailAbandonmentRevivalCardImage from 'app/src/pages/dashboard/components/cases/images/cards/email-abandonment-revival.png';
import tilePdpCardImage from 'app/src/pages/dashboard/components/cases/images/cards/tile-pdp.png';
import emailAbandonmentRevivalModalImage from 'app/src/pages/dashboard/components/cases/images/modal/email-abandonment-revival.png';
import emailAbandonmentRevivalFlowModalImage from 'app/src/pages/dashboard/components/cases/images/modal/email-abandonment-revival-flow.png';
import emailProductLaunchCardImage from 'app/src/pages/dashboard/components/cases/images/cards/email-product-launch.png';
import emailProductLaunchModalImage from 'app/src/pages/dashboard/components/cases/images/modal/email-product-launch.png';
import collectUGCPostPurchaseCardImage from 'app/src/pages/dashboard/components/cases/images/cards/collect-ugc-post-purchase.png';
import collectUGCPostPurchaseModalImage from 'app/src/pages/dashboard/components/cases/images/modal/collect-ugc-post-purchase.png';
import collectUGCPastCustomersCardImage from 'app/src/pages/dashboard/components/cases/images/cards/collect-ugc-past-customers.png';
import collectUGCPastCustomersModalImage from 'app/src/pages/dashboard/components/cases/images/modal/collect-ugc-past-customers.png';
import tilePdpModalImage from 'app/src/pages/dashboard/components/cases/images/modal/tile-pdp.png';
import HomeIcon from 'app/src/pages/dashboard/components/cases/images/icons/HomeIcon';
import ProductsIcon from 'app/src/pages/dashboard/components/cases/images/icons/ProductIcon';
import SizingIcon from 'app/src/pages/dashboard/components/cases/images/icons/SizingIcon';
import UGCIcon from 'app/src/pages/dashboard/components/cases/images/icons/UGCIcon';
import SeeItLiveIcon from 'app/src/pages/dashboard/components/cases/images/icons/SeeItLiveIcon';
import HowtoIcon from 'app/src/pages/dashboard/components/cases/images/icons/HowtoIcon';
import BrandedIcon from 'app/src/pages/dashboard/components/cases/images/icons/BrandedIcon';
import InfluencerIcon from 'app/src/pages/dashboard/components/cases/images/icons/InfluencerIcon';
import ReviewsIcon from 'app/src/pages/dashboard/components/cases/images/icons/ReviewsIcon';
import EmailIcon from 'app/src/pages/dashboard/components/cases/images/icons/EmailIcon';

import ScriptedIcon from 'app/src/pages/dashboard/components/cases/images/icons/ScriptedIcon';
import LandingIcon from 'app/src/pages/dashboard/components/cases/images/icons/LandingIcon';
import DiscoverIcon from 'app/src/pages/dashboard/components/cases/images/icons/DiscoverIcon';
import { OnboardingTemplateKey } from 'app/src/pages/dashboard/pages/homepage/onboarding/templates/constants/onboardingTemplates.constants';
import FeaturedCollectionIcon from 'app/src/pages/dashboard/components/cases/images/icons/FeaturedCollectionIcon';
import ShopAppIcon from 'src/images/dashboard_v2/ShopAppIcon';
import { publishMethodOptions } from 'src/types/entities';
import BookmarkIcon from './images/icons/BookmarkIcon';

export enum CaseType {
  SHOP_VIDEOS = 'SHOP_VIDEOS',
  PRODUCT_PAGE_STORIES = 'PRODUCT_PAGE_STORIES',
  HOMEPAGE_SPOTLIGHT = 'HOMEPAGE_SPOTLIGHT',
  FOR_YOU = 'FOR_YOU',
  FOUNDER_VIDEO = 'FOUNDER_VIDEO',
  HERO_VIDEO = 'HERO_VIDEO',
  TAPCART_APP = 'TAPCART_APP',
  TAPCART_APP_PDP_CAROUSEL = 'TAPCART_APP_PDP_CAROUSEL',
  EMAIL_TRENDING = 'EMAIL_TRENDING',
  EMAIL_ABANDONMENT_REVIVAL = 'EMAIL_ABANDONMENT_REVIVAL',
  FUEGO_PDP = 'FUEGO_PDP',
  COLLECT_UGC_POST_PURCHASE = 'COLLECT_UGC_POST_PURCHASE',
  COLLECT_UGC_PAST_CUSTOMERS = 'COLLECT_UGC_PAST_CUSTOMERS',
  EMAIL_PRODUCT_LAUNCH = 'EMAIL_PRODUCT_LAUNCH',
  TILE_PDP = 'TILE_PDP',
}

export enum DeprecatedCaseType {
  EMAIL_POST_PURCHASE = 'EMAIL_POST_PURCHASE',
}

export type CasePageType =
  | 'SHOP_HOME'
  | 'SHOP_STORE'
  | 'SHOP_PDP'
  | 'HOME'
  | 'PRODUCT'
  | 'DEDICATED'
  | 'LANDING'
  | 'TAPCART_APP'
  | 'TAPCART_APP_PDP_CAROUSEL'
  | 'FUEGO_PDP'
  | 'EMAIL_TRENDING'
  | 'EMAIL_ABANDONMENT_REVIVAL';

export type CasePerk =
  | 'PRODUCT_VIDEOS'
  | 'SIZING'
  | 'UGC'
  | 'SEE_IT_LIVE'
  | 'HOW_TO'
  | 'BRANDED_VIDEOS'
  | 'INFLUENCER_VIDEOS'
  | 'FEATURED_PRODUCT_VIDEOS'
  | 'UGC_HQ'
  | 'VIDEO_REVIEWS'
  | 'SCRIPTED_VIDEOS'
  | 'FEATURED_COLLECTION_VIDEOS';

export type CaseButton = 'FAQ' | 'BRAND_ORIGIN_STORY' | 'FOUNDERS_FAVORITES' | 'HOW_TO_USE';

export type CaseEmailPlatform = 'all' | 'klaviyo';

type CaseDefinition = {
  title: string;
  image: string;
  namePrefix?: string;
  subtitle?: string;
  titleIcon?: ReactElement;
  rightContent?: ReactElement;
  name?: string;
  layout?: string;
  isWaitListedCase?: true;
};

type DeprecatedCaseDefinition = {
  name: string;
};

type CasesDefinition = Record<CaseType, CaseDefinition>;

type DeprecatedCasesDefinition = Record<DeprecatedCaseType, DeprecatedCaseDefinition>;

type CaseContent = {
  title: string;
  titleIcon?: ReactElement;
  description: string;
  images: string[];
  pages: CasePageType[];
  perks: CasePerk[];
  perksLayout?: 'twoColumns' | 'oneColumn';
  buttons?: CaseButton[];
  bannerText?: string;
  emailPlatforms?: CaseEmailPlatform[];
  isShopifyOnly?: boolean;
};

type CaseContentByType = Record<CaseType, CaseContent>;

type LabelWithIcon = {
  label: string;
  icon: ReactElement;
};
type Label = {
  label: string;
};

type EmailPlatform = {
  label: string;
  icon: ReactElement;
};

type PageDefinitions = Record<CasePageType, LabelWithIcon>;
type PerkDefinitions = Record<CasePerk, LabelWithIcon>;
type ButtonDefinitions = Record<CaseButton, Label>;
type EmailPlatformDefinitions = Record<CaseEmailPlatform, EmailPlatform>;

type CaseGuideCategory = 'offsite';

export type CaseGuide = {
  header: string;
  url: string;
  key: string;
};

export const PERKS_LAYOUT_ONE_COLUMN = 'oneColumn';
export const PERKS_LAYOUT_TWO_COLUMNS = 'twoColumns';

export const caseTypeToOnboardingTemplateKey: Partial<Record<CaseType, OnboardingTemplateKey>> = {
  PRODUCT_PAGE_STORIES: OnboardingTemplateKey.pdpStories,
  HOMEPAGE_SPOTLIGHT: OnboardingTemplateKey.homepageCarousel,
  SHOP_VIDEOS: OnboardingTemplateKey.shopAppProject,
};

export const cases: CasesDefinition = {
  SHOP_VIDEOS: {
    title: 'videos',
    subtitle: 'Get seen by 100M shoppers',
    image: shopVideosImage,
    name: 'shopVideos',
  },
  PRODUCT_PAGE_STORIES: {
    title: 'Product page stories',
    subtitle: 'Increase conversion with product videos',
    image: productPageStoriesImage,
    name: 'productPageStories',
    layout: publishMethodOptions.stories,
  },
  HOMEPAGE_SPOTLIGHT: {
    title: 'Homepage spotlight',
    subtitle: 'Engage shoppers with your brand',
    image: homepageSpotlightImage,
    name: 'homepageSpotlight',
    layout: publishMethodOptions.carousel,
  },
  FOR_YOU: {
    title: '‘For you’ video feed',
    subtitle: 'For you',
    titleIcon: <ForYouIcon />,
    image: forYouImage,
    name: 'forYou',
    layout: publishMethodOptions.videoPage,
  },
  FOUNDER_VIDEO: {
    title: 'Founder Video',
    subtitle: 'Build a relationship with every customer',
    image: founderVideoImage,
    name: 'founderVideo',
    layout: publishMethodOptions.bubble,
  },
  HERO_VIDEO: {
    title: 'Hero',
    subtitle: 'Drive shoppers to explore new collections',
    image: heroVideoImage,
    name: 'heroVideo',
    layout: publishMethodOptions.hero,
  },
  TAPCART_APP: {
    title: 'Tapcart ’For You’ feed',
    subtitle: 'Personalized video feed, powered by AI',
    titleIcon: <TapcartIcon />,
    image: tapcartAppImage,
    name: 'tapcartApp',
    isShopifyOnly: true,
  },
  TAPCART_APP_PDP_CAROUSEL: {
    title: 'Tapcart PDP stories',
    subtitle: 'Maximize app-revenue with product videos',
    titleIcon: <TapcartIcon />,
    image: tapcartAppPdpImage,
    name: 'tapcartAppPDPCarousel',
    isShopifyOnly: true,
  },
  FUEGO_PDP: {
    title: 'Fuego PDP stories',
    subtitle: 'Maximize app-revenue with product videos',
    titleIcon: <FuegoIcon />,
    image: fuegoPdpImage,
    layout: publishMethodOptions.stories,
    namePrefix: 'Fuego PDP carousel',
    name: 'fuegoPdp',
  },
  EMAIL_TRENDING: {
    title: 'Trending videos',
    subtitle: 'Feature your trending videos in any campaign.',
    image: emailTrendingCardImage,
    name: 'emailTrending',
  },
  EMAIL_ABANDONMENT_REVIVAL: {
    title: 'Abandonment flows',
    subtitle: 'Cart abandonment, browse abandonment...',
    image: emailAbandonmentRevivalCardImage,
    name: 'emailAbandonmentRevival',
  },
  COLLECT_UGC_POST_PURCHASE: {
    title: 'Collect UGC post-purchase',
    subtitle: 'Embed in post-purchase flow to collect videos',
    image: collectUGCPostPurchaseCardImage,
    name: 'collectUGCPostPurchase',
    isWaitListedCase: true,
  },
  COLLECT_UGC_PAST_CUSTOMERS: {
    title: 'Collect UGC from your past customers',
    subtitle: 'Reach out to recent customers to collect videos',
    image: collectUGCPastCustomersCardImage,
    name: 'collectUGCPastCustomers',
    isWaitListedCase: true,
  },
  EMAIL_PRODUCT_LAUNCH: {
    title: 'Product launch campaign',
    subtitle: 'Bring product campaigns to life',
    image: emailProductLaunchCardImage,
    name: 'emailProductLaunch',
  },
  TILE_PDP: {
    title: 'Tile',
    subtitle: 'Gorgeous video embeds on any page',
    image: tilePdpCardImage,
    name: 'tilePdp',
  },
};

export const deprecatedCases: DeprecatedCasesDefinition = {
  EMAIL_POST_PURCHASE: {
    name: 'emailPostPurchase',
  },
};

export const CASE_CONTENT: CaseContentByType = {
  SHOP_VIDEOS: {
    title: 'videos',
    description:
      'Get discovered by millions of shoppers and drive sales from new customers by adding shoppable video feeds to your Shop App Store.',
    images: [shopVideosImage1, shopVideosImage2, shopVideosImage3],
    pages: ['SHOP_HOME', 'SHOP_STORE', 'SHOP_PDP'],
    perks: [
      'PRODUCT_VIDEOS',
      'SEE_IT_LIVE',
      'UGC',
      'VIDEO_REVIEWS',
      'HOW_TO',
      'INFLUENCER_VIDEOS',
      'BRANDED_VIDEOS',
    ],
  },
  PRODUCT_PAGE_STORIES: {
    title: 'Product page stories',
    description:
      'Increase conversions on your product pages by adding a video feed that provides rich context, beyond traditional text and image.',
    images: [productPageStoriesImage1, productPageStoriesImage2, productPageStoriesImage3],
    pages: ['PRODUCT'],
    perks: ['PRODUCT_VIDEOS', 'SIZING', 'UGC', 'SEE_IT_LIVE', 'HOW_TO'],
  },
  HOMEPAGE_SPOTLIGHT: {
    title: 'Homepage spotlight',
    description:
      'Empower your customers to explore new products and collections through a bold homepage spotlight.',
    images: [homepageSpotlightImage1, homepageSpotlightImage2, homepageSpotlightImage3],
    pages: ['HOME'],
    perks: ['BRANDED_VIDEOS', 'INFLUENCER_VIDEOS', 'FEATURED_PRODUCT_VIDEOS', 'UGC_HQ'],
  },
  FOR_YOU: {
    title: '‘For you’ video feed',
    titleIcon: <ForYouIcon />,
    description:
      'Add a personalized video feed powered by Tolstoy’s AI, allowing customers to discover new products tailored to them.',
    images: [forYouImage1, forYouImage2, forYouImage3],
    pages: ['DEDICATED'],
    perks: [
      'PRODUCT_VIDEOS',
      'SEE_IT_LIVE',
      'UGC',
      'VIDEO_REVIEWS',
      'HOW_TO',
      'INFLUENCER_VIDEOS',
      'BRANDED_VIDEOS',
    ],
  },
  FOUNDER_VIDEO: {
    title: 'Founder Video',
    description:
      'Connect with your customers with a personal touch. Founder videos let customers feel a part of your brand’s community and learn more about your products, all through a dynamic video journey.',
    images: [founderVideoImage1, founderVideoImage2],
    pages: ['HOME'],
    perks: ['SCRIPTED_VIDEOS'],
    buttons: ['FAQ', 'BRAND_ORIGIN_STORY', 'FOUNDERS_FAVORITES', 'HOW_TO_USE'],
  },
  HERO_VIDEO: {
    title: 'Hero Video',
    description:
      'Bring your brand to life through an eye-catching video on your homepage, driving customers straight to featured collections and products.',
    images: [heroVideoImage1, heroVideoImage2],
    pages: ['HOME', 'LANDING'],
    perks: ['BRANDED_VIDEOS', 'FEATURED_COLLECTION_VIDEOS', 'FEATURED_PRODUCT_VIDEOS'],
  },
  TAPCART_APP: {
    title: 'Tapcart ’For You’ Feed',
    titleIcon: <TapcartIcon />,
    description:
      'Let your customers explore and shop your products through an AI-powered, personalized video feed, all within your Tapcart app.',
    images: [tapcartImage1, tapcartImage2],
    pages: ['TAPCART_APP'],
    perks: [
      'PRODUCT_VIDEOS',
      'SEE_IT_LIVE',
      'UGC',
      'VIDEO_REVIEWS',
      'HOW_TO',
      'INFLUENCER_VIDEOS',
      'BRANDED_VIDEOS',
    ],
  },
  TAPCART_APP_PDP_CAROUSEL: {
    title: 'Tapcart PDP stories',
    titleIcon: <TapcartIcon />,
    description:
      "Increase conversion by adding engaging video on your app's product pages, giving users a perfect mobile shopping experience.",
    images: [tapcartPdpImage1],
    pages: ['TAPCART_APP'],
    perks: [
      'PRODUCT_VIDEOS',
      'SEE_IT_LIVE',
      'UGC',
      'VIDEO_REVIEWS',
      'HOW_TO',
      'INFLUENCER_VIDEOS',
      'BRANDED_VIDEOS',
    ],
  },
  EMAIL_TRENDING: {
    title: 'Trending videos',
    description:
      "Captivate your audience with your latest, most engaging content! Feature your brand's trending videos, automatically refreshed with your most popular content, selected by AI.",
    images: [emailTrendingModalImage],
    pages: [],
    perks: ['UGC', 'PRODUCT_VIDEOS'],
    perksLayout: PERKS_LAYOUT_ONE_COLUMN,
    emailPlatforms: ['all'],
  },
  EMAIL_ABANDONMENT_REVIVAL: {
    title: 'Abandonment flows',
    description:
      'Automatically display your product’s best videos in any email flow. Dynamically display videos related to the specific product featured in each email.',
    images: [emailAbandonmentRevivalModalImage, emailAbandonmentRevivalFlowModalImage],
    pages: [],
    perks: ['PRODUCT_VIDEOS', 'VIDEO_REVIEWS', 'UGC'],
    perksLayout: PERKS_LAYOUT_ONE_COLUMN,
    emailPlatforms: ['klaviyo'],
  },
  EMAIL_PRODUCT_LAUNCH: {
    title: 'Product launch',
    description:
      'Product videos are the best way to drive excitement for your new product launch. Showcase the product in real life, give social proof, and more.',
    images: [emailProductLaunchModalImage],
    pages: [],
    perks: ['PRODUCT_VIDEOS', 'HOW_TO', 'UGC'],
    perksLayout: PERKS_LAYOUT_ONE_COLUMN,
    emailPlatforms: ['all'],
  },
  FUEGO_PDP: {
    title: 'Fuego PDP stories',
    titleIcon: <FuegoIcon />,
    description:
      "Increase conversion by adding engaging video on your app's product pages, giving users a perfect mobile shopping experience.",
    images: [fuegoPdpImage1],
    pages: ['FUEGO_PDP'],
    perks: [
      'PRODUCT_VIDEOS',
      'SEE_IT_LIVE',
      'UGC',
      'VIDEO_REVIEWS',
      'HOW_TO',
      'INFLUENCER_VIDEOS',
      'BRANDED_VIDEOS',
    ],
  },
  COLLECT_UGC_POST_PURCHASE: {
    title: 'Collect UGC post-purchase',
    description:
      'Grow your content effortlessly by incentivizing your customers to share videos of their recently bought product. Video quality guaranteed through Tolstoy content experts and AI.',
    images: [collectUGCPostPurchaseModalImage],
    pages: [],
    perks: ['PRODUCT_VIDEOS', 'VIDEO_REVIEWS', 'UGC'],
    perksLayout: PERKS_LAYOUT_ONE_COLUMN,
  },
  COLLECT_UGC_PAST_CUSTOMERS: {
    title: 'Collect UGC from past customers',
    description:
      'Grow your content effortlessly by incentivizing your customers to share videos of their recently bought product. Video quality guaranteed through Tolstoy content experts and AI.',
    images: [collectUGCPastCustomersModalImage],
    pages: [],
    perks: ['PRODUCT_VIDEOS', 'VIDEO_REVIEWS', 'UGC'],
    perksLayout: PERKS_LAYOUT_ONE_COLUMN,
  },
  TILE_PDP: {
    title: 'Tile',
    description:
      'Natively embed the right video on every page with ease, immediately enhancing the customer experience',
    images: [tilePdpModalImage],
    pages: ['PRODUCT'],
    perks: ['FEATURED_PRODUCT_VIDEOS', 'SEE_IT_LIVE', 'UGC'],
  },
};

export const pagesByType: PageDefinitions = {
  SHOP_HOME: {
    label: 'Shop home',
    icon: <ShopAppIcon width={20} />,
  },
  SHOP_STORE: {
    label: 'Brand page',
    icon: <HomeIcon />,
  },
  SHOP_PDP: {
    label: 'Product page',
    icon: <ProductsIcon />,
  },
  PRODUCT: {
    label: 'Product pages',
    icon: <ProductsIcon />,
  },
  HOME: {
    label: 'Homepage',
    icon: <HomeIcon />,
  },
  DEDICATED: {
    label: 'Dedicated Page',
    icon: <HomeIcon />,
  },
  LANDING: {
    label: 'Landing page',
    icon: <LandingIcon />,
  },
  TAPCART_APP: {
    label: 'Tapcart',
    icon: <DiscoverIcon />,
  },
  TAPCART_APP_PDP_CAROUSEL: {
    label: 'Tapcart product pages',
    icon: <ProductsIcon />,
  },
  FUEGO_PDP: {
    label: 'Fuego product pages',
    icon: <ProductsIcon />,
  },
  EMAIL_TRENDING: {
    label: 'Trending videos',
    icon: null,
  },
  EMAIL_ABANDONMENT_REVIVAL: {
    label: 'Abandonment revival',
    icon: null,
  },
};

export const perksByType: PerkDefinitions = {
  PRODUCT_VIDEOS: {
    label: 'Product videos',
    icon: <ProductsIcon />,
  },
  SIZING: {
    label: 'Sizing',
    icon: <SizingIcon />,
  },
  UGC: {
    label: 'UGC',
    icon: <UGCIcon />,
  },
  SEE_IT_LIVE: {
    label: 'See it live',
    icon: <SeeItLiveIcon />,
  },
  HOW_TO: {
    label: 'How to',
    icon: <HowtoIcon />,
  },
  BRANDED_VIDEOS: {
    label: 'Branded videos',
    icon: <BrandedIcon />,
  },
  INFLUENCER_VIDEOS: {
    label: 'Influencer videos',
    icon: <InfluencerIcon />,
  },
  FEATURED_PRODUCT_VIDEOS: {
    label: 'Featured product videos',
    icon: <ProductsIcon />,
  },
  UGC_HQ: {
    label: 'High-quality UGC',
    icon: <UGCIcon />,
  },
  VIDEO_REVIEWS: {
    label: 'Video reviews',
    icon: <ReviewsIcon />,
  },
  SCRIPTED_VIDEOS: {
    label: 'Scripted videos',
    icon: <ScriptedIcon />,
  },
  FEATURED_COLLECTION_VIDEOS: {
    label: 'Featured collection videos',
    icon: <FeaturedCollectionIcon />,
  },
};

export const buttonsByType: ButtonDefinitions = {
  FAQ: {
    label: 'FAQs',
  },
  BRAND_ORIGIN_STORY: {
    label: 'Brand origin story',
  },
  FOUNDERS_FAVORITES: {
    label: 'Founder’s favorites',
  },
  HOW_TO_USE: {
    label: 'How to use',
  },
};

export const emailPlatformsByType: EmailPlatformDefinitions = {
  all: {
    label: 'All platforms supported',
    icon: <EmailIcon />,
  },
  klaviyo: {
    label: 'Klaviyo',
    icon: <BookmarkIcon />,
  },
};

export const CASE_TYPES_ONSITE: CaseType[] = [
  CaseType.PRODUCT_PAGE_STORIES,
  CaseType.HOMEPAGE_SPOTLIGHT,
  CaseType.TILE_PDP,
  CaseType.HERO_VIDEO,
  CaseType.FOUNDER_VIDEO,
  CaseType.TAPCART_APP,
  CaseType.TAPCART_APP_PDP_CAROUSEL,
  CaseType.FUEGO_PDP,
  CaseType.FOR_YOU,
];

export const CASE_TYPES_OFFSITE: CaseType[] = [
  CaseType.EMAIL_ABANDONMENT_REVIVAL,
  CaseType.EMAIL_TRENDING,
  CaseType.EMAIL_PRODUCT_LAUNCH,
];

export const CASE_TYPES_HOME_SHOP: CaseType[] = [
  CaseType.PRODUCT_PAGE_STORIES,
  CaseType.HOMEPAGE_SPOTLIGHT,
  CaseType.SHOP_VIDEOS,
];

export const CASE_TYPES_HOME_SHOPIFY_NO_SHOP: CaseType[] = [
  CaseType.PRODUCT_PAGE_STORIES,
  CaseType.HOMEPAGE_SPOTLIGHT,
];

export const CASE_TYPES_HOME_NO_SHOPIFY: CaseType[] = [
  CaseType.PRODUCT_PAGE_STORIES,
  CaseType.HOMEPAGE_SPOTLIGHT,
];

export const CASE_TYPES_COLLECT_UGC: CaseType[] = [
  CaseType.COLLECT_UGC_POST_PURCHASE,
  CaseType.COLLECT_UGC_PAST_CUSTOMERS,
];

export const CASE_TYPE_GUIDES: Record<CaseGuideCategory, CaseGuide> = {
  offsite: {
    header: 'Need some more guidance on the email use cases?',
    url: 'https://help.gotolstoy.com/en/articles/9241457-email-widget-use-cases',
    key: 'Offsite',
  },
};

export const CASES_WAIT_LISTS_JOINED_LOCAL_STORAGE_KEY = 'casesWaitListsJoined';
