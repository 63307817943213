import React from 'react';
import OverlayCaption from './OverlayCaption';
import OverlayMetrics from './OverlayMetrics';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { AnalyticsPlayerDataContext } from './AnalyticsPlayer.types';

const Overlay = (props: AnalyticsPlayerDataContext) => {
  return (
    <LayoutRoot>
      <OverlayMetrics {...props} />
      <OverlayCaption {...props} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  bottom: 0;
  justify-content: space-between;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  width: 100%;
`;

export default Overlay;
