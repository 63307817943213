import { VIDEO_STATUS } from 'shared/react/constants/video.constants';

export const handlePIP = async (ref, options) => {
  const pipElement = document.pictureInPictureElement;

  if (pipElement) {
    document.exitPictureInPicture();
  } else {
    if (document.pictureInPictureEnabled && pipElement?.srcObject) {
      try {
        ref?.current?.requestPictureInPicture();
        ref?.current?.play?.();
      } catch (err) {
        console.log(err);
      }
    }
  }

  if (pipElement?.srcObject || pipElement) {
    return;
  }

  try {
    const video = await getVideoRecording({ video: options?.video || 'default', audio: true });
    ref.current.srcObject = video;
    ref?.current?.play();
    setTimeout(() => {
      try {
        ref?.current?.requestPictureInPicture();
        ref?.current?.play?.();
      } catch (err) {
        console.log(err);
      }
    }, 1000);
  } catch (err) {
    return err;
  }
};

export const closePIP = () => {
  const pipElement = document.pictureInPictureElement;
  if (pipElement) {
    document.exitPictureInPicture();
  }

  if (pipElement?.srcObject) {
    stopStream(pipElement?.srcObject);
  }
};

export const getScreenRecording = async options => {
  const displayStream = await navigator.mediaDevices.getDisplayMedia();
  try {
    const audioStream = await navigator.mediaDevices.getUserMedia({
      audio: { deviceId: options?.audio },
      video: false,
    });
    const [audioTrack] = audioStream.getAudioTracks();
    const [videoTrack] = displayStream.getVideoTracks();
    return new MediaStream([videoTrack, audioTrack]);
  } catch (err) {
    console.log('no mic detected', err);
    return displayStream;
  }
};

export const getVideoRecording = options => {
  return navigator.mediaDevices.getUserMedia({
    audio: options.audio && { deviceId: options.audio },
    video: options.video && { width: 1280, height: 720, deviceId: options.video, frameRate: 24 },
  });
};

export const stopStream = mediaStream => {
  const tracks = mediaStream?.getTracks();
  if (tracks) {
    tracks.forEach(track => {
      track.stop();
    });
  }
};

export const getVideoInfo = url => {
  return new Promise(res => {
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = () => {
      res({ duration: video.duration, isVertical: video.videoWidth < video.videoHeight });
      video.remove();
    };

    video.onerror = function () {
      res(Infinity);
      video.remove();
      video.src = null;
    };

    video.src = url;
  });
};

export const toTimeString = (secs, withFraction = true) => {
  const fullSeconds = parseInt(secs, 10);
  const hours = Math.floor(fullSeconds / 3600);
  const minutes = Math.floor(fullSeconds / 60) % 60;
  const seconds = fullSeconds % 60;
  const fraction = parseFloat((secs % 1).toFixed(1)) * 10;

  const time = [hours, minutes, seconds]
    .map(time => (time < 10 ? '0' + time : time))
    .filter((v, i) => v !== '00' || i > 0)
    .join(':');

  if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
    return '00:00.0';
  }

  if (!withFraction) {
    return time;
  }

  return `${time}.${fraction || 0}`;
};

export const isNotInvalid = video => video && video.status !== VIDEO_STATUS.invalid;
