import React from 'react';
import { RIGHT } from 'shared/react/components/complex/carousel/carousel.constants';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const CarouselArrowICon = ({ direction }) => (
  <LayoutRoot direction={direction}>
    <svg width={25} height={25} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.906 2.469a.368.368 0 0 0-.515 0L5.14 12.594a.368.368 0 0 0 0 .515l11.25 10.125c.14.141.375.141.515 0 .14-.14.14-.422 0-.515L5.938 12.875l10.968-9.89C17 2.937 17 2.843 17 2.702c0-.047 0-.14-.094-.234Z"
        fill="#000"
      />
    </svg>
  </LayoutRoot>
);

const LayoutRoot = styled(VerticalFlex)`
  display: flex;
  align-items: center;
  transform: scale(${({ direction }) => (direction === RIGHT ? '-1' : '1')}, 1);
`;

export default CarouselArrowICon;
