import React from 'react';
import styled from 'styled-components';
import IosSwitch from 'app/src/basic_components/IosSwitch';
import { useRulesContext } from 'app/src/pages/project/pages/rules_and_triggers/RulesContext';

import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import { TextSmall } from 'shared/react/components/complex/Text';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Tooltip from 'app/src/basic_components/Tooltip';
import { HEADLESS } from 'app/src/pages/project/pages/rules_and_triggers/Rule.constants';
import InfoIconWithTooltip from 'app/src/complex_components/InfoIconWithTooltip';

const RulesStoresEditor = () => {
  const { onShowOnDomainChange, showOnDomains, shopifyApps } = useRulesContext();
  const apps = [
    ...shopifyApps,
    {
      id: HEADLESS,
      appUrl: HEADLESS,
      infoText:
        'If you are on Headless, you will need to set up the rules for your widget in the “Pages” dropdown below.',
    },
  ];
  const isHeadless = showOnDomains?.includes(HEADLESS);

  return (
    <Tooltip
      text="Store toggles wont work for headless stores. If your store is headless, keep all on and use page URL rules instead."
      placement="right"
    >
      <LayoutRoot>
        {apps.map(store => {
          const isChecked =
            (!showOnDomains && store.id !== HEADLESS) || showOnDomains?.includes(store.appUrl);
          return (
            <ButtonContainer key={store.id}>
              <IosSwitch
                disabled={isHeadless && !isChecked}
                checked={isChecked}
                onChange={() => onShowOnDomainChange(store.appUrl)}
              />
              <TextSmall>{store.appUrl}</TextSmall>
              {!!store.infoText && <InfoIconWithTooltip title={store.infoText} />}
            </ButtonContainer>
          );
        })}
      </LayoutRoot>
    </Tooltip>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  gap: 38px;

  padding: 8px 0 0;
`;

const ButtonContainer = styled(Gap8HorizontalFlexWrap)`
  align-items: center;
`;

export default RulesStoresEditor;
