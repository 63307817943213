import React, { useState } from 'react';
import styled from 'styled-components';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import { TextTiny } from 'shared/react/components/complex/Text';
import BottomArrowIcon from 'shared/react/images/BottomArrowIcon';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import Utils from 'app/src/utils';
import MenuItem from 'app/src/complex_components/MenuItemV2';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { TRANSFER_OWNERSHIP_MODAL_KEY } from 'app/src/constants/modals.constants';
import { STATUS_ACCEPTED } from 'app/src/constants/teamMembers.constants';
import MenuWithContainer from 'shared/react/components/basic/MenuWithContainer';

const TeamMemberRole = ({ text, teamMember }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [, { setCurrentModal, setModalProps }] = useModal();
  const currentUser = Utils.getOwner() === teamMember.userId;
  const isMemberAccepted = teamMember.status === STATUS_ACCEPTED;
  const shouldOpenMenu = isMemberAccepted && !currentUser;

  const openMenu = e => {
    setAnchorEl(e.target);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onOwnerClick = () => {
    setCurrentModal(TRANSFER_OWNERSHIP_MODAL_KEY);
    setModalProps({ teamMember });
  };

  return (
    <>
      <LayoutRoot shouldOpenMenu={shouldOpenMenu} onClick={openMenu}>
        <Text>{text}</Text>
        {shouldOpenMenu && (
          <IconContainer>
            <BottomArrowIcon />
          </IconContainer>
        )}
      </LayoutRoot>
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={onClose}>
        <MenuItem title="Admin" />
        <MenuItem title="Owner" onClick={onOwnerClick} />
      </Menu>
    </>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlexWrap)`
  width: fit-content;
  height: fit-content;
  align-items: center;
  cursor: pointer;
  pointer-events: ${({ shouldOpenMenu }) => (shouldOpenMenu ? '' : 'none')};
`;

const Text = styled(TextTiny)`
  border-radius: 12px;
  padding: 5px 10px;
  background-color: ${({ theme }) => theme.colors.gray3};
  color: ${({ theme }) => theme.colors.gray2};

  &:first-letter {
    text-transform: uppercase;
  }
`;

const IconContainer = styled(HorizontalFlexWrap)`
  width: 13px;
  height: 16px;

  & path {
    fill: ${({ theme }) => theme.colors.black};
  }
`;

const Menu = styled(MenuWithContainer)`
  min-width: 177px;
  border-radius: 12px;
  position: relative;
  border-radius: 12px;
`;

export default TeamMemberRole;
