const DeprecatedColors = {
  white: '#FFFFFF',
  black: '#090A0B',
  black1: '#000000',
  black2: '#1c1d21',
  black15opacity: '#00000026',
  black20opacity: '#00000033',
  black30opacity: '#0000004D',
  black75opacity: '#090a0bbf',
  black70opacity: '#000000B5',
  blackBoxShadow: '#00000014',
  primary: '#3D4FE0',
  primaryHover: '#4b60e8',
  primaryActive: '#313fb3',
  primaryLight: '#D6EBFF',
  primaryLightHover: '#D6EBFF',
  primaryLightActive: '#E2EDFE',
  primaryDark: '#001366',
  ghostLight: '#DFE0E2',
  ghostDark: '#7D8087',
  warning: '#FD7830',
  warningLight: '#FFE5D7',
  warningDark: '#652401',
  success: '#01BE81',
  successHover: '#1ac58e',
  successActive: '#34cb9a',
  successLight: '#D3F8EC',
  successLightHover: '#dcf9f0',
  successDark: '#016545',
  danger: '#FA536E',
  dangerHover: '#FF005C',
  dangerOpacity: '#FA536E33',
  dangerLight: '#F8D3DC',
  dangerDark: '#66001F',
  info: '#FFC728',
  infoLight: '#FFF2CC',
  infoDark: '#7A5B00',
  notificationBadge: '#f40208',
  gray1: '#fcfcfd',
  gray2: '#50545e',
  gray3: '#EAECF0',
  gray3Hover: '#eef0f3',
  gray4: '#B3B6BC',
  gray5: '#F4F5F7',
  gray6: '#f5f8fa',
  gray6_darker1: '#eef1f3',
  gray7: '#f8f8f8',
  gray8: '#888888',
  gray9: '#d4d4d4',
  gray10: '#DBE4EE',
  gray11: '#e7edf270',
  gray12: '#333333',
  gray13: '#ebfaf5',
  gray14: '#666666',
  gray15: '#e5e5e5',
  gray16: '#F3F5FF',
  gray17: '#4A4A4A',
  gray18: '#6E6E6E',
  gray19: '#B9B9B9',
  gray20: '#E8EAFC',
  gray21: '#B2B2B21A',
  gray22: '#9A9A9A1A',
  gray23: '#EBECF0',
  gray24: '#f7f8f9',
  gray25: '#878787',
  gray26: '#c8c9c9',
  gray27: '#2f3137',
  gray28: '#2021240f',
  gray29: '#f2f5f5cc',
  gray30: '#5A5D62',
  gray31: '#7D8087',
  gray32: '#F8F9FC',
  gray33: '#D9D9D9',
  gray34: '#7d8187',
  gray35: '#EAECEE',
  gray36: '#52555B',
  gray37: '#F1F3F5',
  gray38: '#D2D4D6',
  settingsGray: '#f9fafc',
  neutralGray: '#B3B6BC',
  pink1: '#FF3C78',
  pink2: '#FF005C',
  pink3: '#E25577',
  pink4: '#ee8184',
  pink6: '#FEE1E6',
  pink7: '#FFAAC9',
  pink5: '#fadfe6',
  pink8: '#FFE0EB',
  pink9: '#DD6089',
  blue1: '#4558D0',
  blue2: '#0E1658',
  blue3: '#5662c2',
  blue4: '#47a2da',
  blue5: '#0080FF',
  blue6: '#719ece',
  blue7: '#3d4fe0',
  blue8: '#263238',
  blue9: '#1A9AFF',
  blue10: '#536DFE',
  blue11: '#4558A3B3',
  blue12: '#3D4FE0',
  blue13: '#CFE2FF',
  blue14: '#2A3CEF',
  blue15: '#3C59F6',
  blue16: '#6978f4',
  socialBlue: '#00AAFF',
  socialBlueHover: '#33bbff',
  purple300: '#D8B4FE',
  purple500: '#A855F7',
  purple1: '#C852BE',
  purple2: '#9013FE',
  purple3: '#efecfb',
  purple4: '#684BD8',
  purple5: '#2B1971',
  yellow1: '#FBC276',
  yellow2: '#EEBF41',
  yellow3: '#FFC260',
  yellow4: '#F0C83C',
  yellow5: '#fbecd0',
  yellow6: '#e6ab4a',
  yellow7: '#F8F3E7',
  green1: '#4ca886',
  green2: '#A5D442',
  green3: '#3CD4A0',
  green4: '#6BBC84',
  green5: '#60a977',
  green6: '#56966a',
  green7: '#DCFEF3',
  green8: '#077F5A',
  red1: '#DB5C6E',
  red2: '#ee4238',
  red3: '#990038',
  red4: '#f53f6b',
  red5: '#630312',
  red6: '#FE2C55',
  red7: '#d01545',
  error: '#f44336',
};

const Colors = {
  ...DeprecatedColors,
  dangerV2: '#e2506d',
  dangerV2Hover: '#E3637C',
  dangerV2Focus: '#CD4863',
  neutralLighter: '#EAECF0',
  neutralLight: '#DFE0E2',
  neutralLightest: '#F4F5F7',
  neutralPale: '#FCFCFD',
  neutralDark: '#7D8087',
  neutralDeep: '#1C1D21',
  neutralBlack: '#090A0B',
  neutralDarker: '#50545E',
  neutralDarkest: '#2F3137',
  neutralGrey: '#B0B4BF',
  disabled: '#E8ECF2',
  fruityOrange: '#FD7830',
  powerBlueDark: '#0E1658',
  powerBlueLight: '#D6EBFF',
  hotPinkDark: '#990038',
  fireRed: '#E2506D',
  warning: '#FFA928',
  grapePurple: '#684BD8',
  grapePurpleDark: '#5433EB',
  infoHover: '#F4C233',
  infoFocus: '#E6BB41',
  grayscaleGrey4: '#D8D8D8',
};

export default Colors;

export const ACTIVE_COLOR = Colors.primary;
