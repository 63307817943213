import { Auth, API } from 'aws-amplify';

import Routes from './Routes';
import { v4 as uuidv4 } from 'uuid';
import Utils from '../utils';

const NO_SESSION_ERROR = 'No current user';

const currentRequestPromisesMap = new Map();

export async function getCustomHeader() {
  try {
    const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
    return {
      Authorization: `Bearer ${token}`,
    };
  } catch (err) {
    console.log(err);
    return { Authorization: '' };
  }
}

export function graphqlRequest() {
  return safeRequest(API.graphql, arguments);
}

export function postRequest() {
  return safeRequest(API.post, arguments);
}

export function getRequest() {
  return safeRequest(API.get, arguments);
}

export function deleteRequest() {
  return safeRequest(API.del, arguments);
}

export function cancelRequests() {
  for (const [, promise] of currentRequestPromisesMap.entries()) {
    API.cancel(promise, 'promise got cancelled');
  }
}

async function safeRequest(func, args) {
  const key = uuidv4();
  let newRequest;

  try {
    newRequest = func.apply(API, args);
    currentRequestPromisesMap.set(key, newRequest);

    const result = await newRequest;

    currentRequestPromisesMap.delete(key);

    return result;
  } catch (err) {
    currentRequestPromisesMap.delete(key);
    if (err?.message === NO_SESSION_ERROR) {
      console.log('should login');
      cancelRequests();
      window.location = Routes.getLoginRoute();
      return;
    }

    console.log('Request failed:', err);
    Utils.logErrorMessage(JSON.stringify(err));
    throw err;
  }
}
