import React from 'react';
import PricingTooltip from '../PricingTooltip';
import Utils from '../../utils';
import { useBilling } from '../../hooks/useBillings';

const PublishPricingTooltip = ({ header, hide, children, location }) => {
  const { isPricingEnabled } = useBilling();

  return (
    <PricingTooltip
      header={header}
      text="Hey! I'm a premium feature, click below to unlock."
      placement="right"
      analyticsData={Utils.getAnalyticsLocationObject(location)}
      shouldHide={hide || !isPricingEnabled}
      requestUserUpgrade={false}
    >
      {children}
    </PricingTooltip>
  );
};

export default PublishPricingTooltip;
