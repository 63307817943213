class UrlHelper {
  static isValidUrl(url) {
    if (!url) {
      return false;
    }
    if (url.startsWith('tel:') || url.startsWith('mailto:')) {
      return true;
    }

    const result = url.match(
      /[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi
    );
    return result !== null;
  }

  static isAndroid() {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.indexOf('android') > -1; //&& ua.indexOf("mobile");
  }

  static isInIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  static isIos() {
    return (
      /iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    );
  }

  static getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }

    if (
      navigator.userAgent.match(/Mac/) &&
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2
    ) {
      return 'ipad';
    }

    return null;
  }

  static isMobile() {
    return UrlHelper.getMobileOperatingSystem() != null;
  }

  static isSafari() {
    return window.safari !== undefined;
  }

  static enforceProtocol(url, protocol = 'https') {
    if (
      url.startsWith('http') ||
      url.startsWith('//') ||
      url.startsWith('tel:') ||
      url.startsWith('mailto:')
    ) {
      return url;
    }
    return `${protocol}://${url}`;
  }

  static removeProtocol(url) {
    return url.replace(/(^\w+:|^)\/\//, '');
  }

  static stripUrl(url) {
    return UrlHelper.removeProtocol(url).replace(/^www./, '').replace(/\/$/, '');
  }

  static getCustomParams() {
    const params = new URLSearchParams(window.location.search);
    const customParams = {};
    const keys = [...params.keys()].filter(key => {
      const lowerCaseKey = key.toLowerCase();
      return lowerCaseKey.startsWith('custom_') || lowerCaseKey.startsWith('utm_');
    });

    keys.forEach(key => {
      const value = params.get(key);
      if (key.length < 50 && value.length < 100) {
        customParams[key] = value;
      }
    });

    return customParams;
  }

  static getUtmParams() {
    const customParams = UrlHelper.getCustomParams();

    const utmParams = {};

    Object.entries(customParams).forEach(([key, value]) => {
      if (key.startsWith('utm_') && value) {
        utmParams[key] = value;
      }
    });

    return utmParams;
  }

  static appendSearchParamsToUrl(url, keyValueParams = {}) {
    if (!this.isValidUrl(url)) {
      return '';
    }

    const parsedUrl = new URL(url);

    Object.entries(keyValueParams).forEach(([paramKey, paramValue]) => {
      parsedUrl.searchParams.set(paramKey, paramValue);
    });

    return parsedUrl.toString();
  }
}

export default UrlHelper;
