import * as React from 'react';

const FeedIcon = props => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 32h10v-6.875H19zm10-8.125V17H19v6.875zM17.75 32v-3.125h-2.5V32zm-2.5-4.375h2.5v-2.5h-2.5zm2.5-6.25h-2.5v2.5h2.5zm0-4.375h-2.5v3.125h2.5zm12.5 10.625h2.5v-2.5h-2.5zm0 4.375h2.5v-3.125h-2.5zm0-10.625v2.5h2.5v-2.5zm2.5-1.25V17h-2.5v3.125zM34 15.75v17.5H14v-17.5z"
      fill="#090A0B"
    />
  </svg>
);

export default FeedIcon;
