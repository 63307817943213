import React, { useEffect } from 'react';
import styled from 'styled-components';
import TolstoyLoader from 'app/src/basic_components/TolstoyLoader';
import TikTokAccount from 'app/src/pages/creation_flow/right_section/import_tiktok_link/importFromAccount/TikTokAccount';
import ComponentWithOverlayLoader from 'shared/react/components/basic/ComponentWithOverlayLoader';
import { TextBodyLeadingBold } from 'shared/react/components/complex/Text';
import IntegrationsVideosList from 'app/src/pages/creation_flow/right_section/integrations_common/IntegrationsVideosList';
import { IMPORT_FROM_TIKTOK } from 'app/src/constants/creation_method.constants';
import InstagramAccount from 'app/src/pages/creation_flow/right_section/import_from_instagram/InstagramAccount';

const IntegrationsImportFromAccountVideos = ({
  fetchData,
  accountVideos,
  app,
  initialized,
  integration,
  isLoading,
  isWaitingForResponse,
}) => {
  const { token, owner } = app;

  const getContent = () => {
    if (!initialized) {
      return;
    }

    if (!accountVideos.length) {
      return <TextBodyLeadingBold>There are no videos in this account</TextBodyLeadingBold>;
    }

    return (
      <IntegrationsVideosList
        accountVideos={accountVideos}
        integration={integration}
        isLoading={isLoading}
        isWaitingForResponse={isWaitingForResponse}
      />
    );
  };

  const getAccount = () => {
    if (integration === IMPORT_FROM_TIKTOK) {
      return <TikTokAccount />;
    }

    return <InstagramAccount />;
  };

  useEffect(() => {
    if (!app || accountVideos.length || !token) {
      return;
    }

    fetchData(owner);
  }, [app]);

  return (
    <OverlayLoader customLoader={<TolstoyLoader />} isLoading={!initialized}>
      {getAccount()}
      {getContent()}
    </OverlayLoader>
  );
};

const OverlayLoader = styled(ComponentWithOverlayLoader)`
  gap: 24px;
  height: auto;
`;

export default IntegrationsImportFromAccountVideos;
