import React from 'react';

const ClockIcon = ({ fill }) => {
  return (
    <svg width={12} height={13} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 0.875C2.88281 0.875 0.375 3.40625 0.375 6.5C0.375 9.61719 2.88281 12.125 6 12.125C9.09375 12.125 11.625 9.61719 11.625 6.5C11.625 3.40625 9.09375 0.875 6 0.875ZM6 11C3.51562 11 1.5 8.98438 1.5 6.5C1.5 4.03906 3.51562 2 6 2C8.46094 2 10.5 4.03906 10.5 6.5C10.5 8.98438 8.46094 11 6 11ZM6.5625 6.59375V4.0625C6.5625 3.75781 6.30469 3.5 6 3.5C5.67188 3.5 5.4375 3.75781 5.4375 4.0625V6.875C5.4375 7.0625 5.50781 7.22656 5.64844 7.34375L7.14844 8.46875C7.24219 8.53906 7.35938 8.5625 7.47656 8.5625C7.66406 8.5625 7.82812 8.49219 7.94531 8.35156C8.13281 8.09375 8.08594 7.74219 7.82812 7.55469L6.5625 6.59375Z"
        fill={fill || '#090A0B'}
      />
    </svg>
  );
};

export default ClockIcon;
