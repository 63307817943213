import React from 'react';
import styled, { css } from 'styled-components';
import { Switch } from '@material-ui/core';

const IosSwitch = props => {
  return <StyledIosSwitch {...props} />;
};

export default IosSwitch;

const ToBeStyledSwitch = ({ classes, ...props }) => (
  <Switch disableRipple disableFocusRipple disableTouchRipple {...props} classes={{ ...classes }} />
);

const EnabledCheckedTrackCss = css`
  background: ${({ theme, checked, activeColor }) =>
    checked ? activeColor || theme.colors.blue7 : theme.colors.gray4};
`;

const StyledIosSwitch = styled(ToBeStyledSwitch)`
  width: 48px;
  height: 24px;
  padding: 0;

  .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    ${EnabledCheckedTrackCss};
    opacity: 1;
  }

  .MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track {
    background-color: ${({ theme, checked, activeColor }) =>
      checked ? theme.getHexOpacity(activeColor, 90) || theme.colors.blue6 : theme.colors.gray4};
    opacity: 0.9;
  }

  .MuiSwitch-thumb {
    width: 20px;
    height: 20px;
  }

  .MuiSwitch-track {
    border-radius: 13px;
    ${EnabledCheckedTrackCss};
    opacity: 1;
  }

  .MuiSwitch-switchBase {
    padding: 2px;
    color: ${({ theme, checked }) => (checked ? theme.colors.white : '')};
    transform: ${({ checked }) => (checked ? 'translateX(24px)' : '')};
  }
`;
