import styled from 'styled-components';

const AnswerContainer = styled.div`
  grid-template-columns: 1fr auto;
  gap: 16px;
  display: grid;
  padding: 14px 16px 14px 24px;
  align-items: center;

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    padding: 14px 0px 14px 0;
  }
`;

export default AnswerContainer;
