import React from 'react';
import useTolyChatBotLogic from './useTolyChatBotLogic';
import {
  ButtonContainer,
  ChatModal,
  ChatContainer,
  ChatHeader,
  ChatOutput,
  UserMessage,
  AIMessage,
  InputContainer,
  Input,
  SendButton,
  TolyButton,
  TypingIndicator,
  SuggestionContainer,
  SuggestionButton,
  ImageModalOverlay,
  ImageModalContent,
} from './TolyChatBotStyles';
import TolyLogoOpen from 'src/images/toly/TolyLogoOpen';
import TolyLogoClose from 'src/images/toly/TolyLogoClose';
import TolySendButton from 'src/images/toly/TolySendButton';
import TolyStarsHeading from 'src/images/toly/TolyStarsHeading';
import ReactMarkdown from 'react-markdown';

declare global {
  interface Window {
    Intercom: any;
    intercomSettings: any;
  }
}

const TolyChatBot: React.FC = () => {
  const logic = useTolyChatBotLogic();

  if (!logic.shouldRender) {
    return null;
  }

  const {
    isModalVisible,
    toggleModal,
    userInput,
    handleInputChange,
    sendQuestion,
    isTyping,
    isButtonContainerVisible,
    isLogoOpen,
    state,
    chatOutputRef,
    sendDefaultMessage,
    hasSentDefaultMessage,
    hasUserSentMessage,
    handleSuggestionClick,
    showSuggestionButtons,
    showTalkToSupport,
    isImageModalOpen,
    currentImageSrc,
    openImageModal,
    closeImageModal,
  } = logic;

  const renderers = {
    img: ({ src, alt }) => (
      <img
        src={src}
        alt={alt}
        style={{ maxWidth: '250px', cursor: 'pointer' }}
        onClick={() => openImageModal(src)}
      />
    ),
  };

  const modifiedToggleModal = () => {
    toggleModal();
    if (!isModalVisible && !hasSentDefaultMessage) {
      sendDefaultMessage();
    }
  };

  return (
    <>
      {isButtonContainerVisible && (
        <ButtonContainer>
          <TolyButton id="open-modal" onClick={modifiedToggleModal}>
            {isLogoOpen ? <TolyLogoOpen /> : <TolyLogoClose />}
          </TolyButton>
        </ButtonContainer>
      )}

      <ChatModal id="chat-modal" isVisible={isModalVisible}>
        <ChatContainer>
          <ChatHeader>
            <TolyStarsHeading />
            Ask Toly anything about Tolstoy
            <span className="close-modal" onClick={toggleModal}></span>
          </ChatHeader>

          <ChatOutput id="chat-output" ref={chatOutputRef}>
            {state.conversationHistory.map((message, index) => (
              <React.Fragment key={`exchange-${index}`}>
                {message.role === 'user' ? (
                  <UserMessage>{message.content}</UserMessage>
                ) : (
                  <AIMessage>
                    <ReactMarkdown components={renderers}>{message.content}</ReactMarkdown>
                  </AIMessage>
                )}
              </React.Fragment>
            ))}
            {isTyping && (
              <TypingIndicator>
                <span></span>
                <span></span>
                <span></span>
              </TypingIndicator>
            )}
          </ChatOutput>

          <SuggestionContainer>
            {showTalkToSupport && hasUserSentMessage && (
              <SuggestionButton
                onClick={() => handleSuggestionClick('Talk to Support')}
                disabled={isTyping}
              >
                Talk to support
              </SuggestionButton>
            )}

            {showSuggestionButtons && (
              <>
                <SuggestionButton
                  onClick={() => handleSuggestionClick('Get started with Tolstoy')}
                  disabled={isTyping}
                >
                  Get started with Tolstoy
                </SuggestionButton>
                <SuggestionButton
                  onClick={() => handleSuggestionClick('Provide two brand live examples')}
                  disabled={isTyping}
                >
                  Show live examples
                </SuggestionButton>
                <SuggestionButton
                  onClick={() => handleSuggestionClick('Help me publish')}
                  disabled={isTyping}
                >
                  Help me publish
                </SuggestionButton>
                <SuggestionButton
                  onClick={() => handleSuggestionClick('What are your available pricing plans?')}
                  disabled={isTyping}
                >
                  Pricing plans
                </SuggestionButton>
                <SuggestionButton
                  onClick={() => handleSuggestionClick("I'm experiencing issues. Please help.")}
                  disabled={isTyping}
                >
                  I need help with an issue
                </SuggestionButton>
              </>
            )}
          </SuggestionContainer>

          <InputContainer>
            <Input
              type="text"
              id="user-input"
              placeholder="Ask a question..."
              value={userInput}
              onChange={handleInputChange}
              onKeyDown={event => {
                if (event.key === 'Enter' && !isTyping) {
                  event.preventDefault();
                  sendQuestion();
                }
              }}
            />
            <SendButton id="send-button" onClick={sendQuestion} disabled={isTyping}>
              <TolySendButton />
            </SendButton>
          </InputContainer>
        </ChatContainer>
      </ChatModal>

      {isImageModalOpen && (
        <ImageModalOverlay isVisible={isImageModalOpen} onClick={closeImageModal}>
          <ImageModalContent onClick={e => e.stopPropagation()}>
            <img src={currentImageSrc} alt="Enlarged content" style={{ maxWidth: '100%' }} />
          </ImageModalContent>
        </ImageModalOverlay>
      )}
    </>
  );
};

export default TolyChatBot;
