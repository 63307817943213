import React, { useState } from 'react';
import styled from 'styled-components';
import XIcon from 'app/src/images/dashboard_v2/XIcon';
import ThinVIcon from 'shared/react/images/ThinVIcon';
import { GhostButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { UpdateSuggestionPayload } from 'app/src/hooks/vod-connection-suggestions/useSuggestionsByVodAssetId';
import { vodConnectionSuggestion } from 'app/src/types/entities';
import { Skeleton } from 'ui-library/core';
import { SmallProductImage } from './SmallProductImage';
import Tooltip from 'src/basic_components/tooltip-v2/Tooltip';
import { TextSmall } from 'shared/react/components/basic/text/TextV2';
import ConfettiIcon from 'src/images/ConfettiIcon';

export type VideoCardSuggestedLabelProps = {
  suggestedProduct: any;
  suggestedConnection: vodConnectionSuggestion;
  onSuggestionRejected: ({
    externalProductId,
    vodConnectionSuggestionId,
  }: UpdateSuggestionPayload) => Promise<void>;
  onSuggestionAccepted: ({
    externalProductId,
    vodConnectionSuggestionId,
  }: UpdateSuggestionPayload) => Promise<void[]>;
};

export const VideoCardSuggestedLabel = ({
  suggestedProduct,
  suggestedConnection,
  onSuggestionAccepted,
  onSuggestionRejected,
}: VideoCardSuggestedLabelProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [suggestedMessage, setSuggestedMessage] = useState(
    <TooltipContainer>Suggested product</TooltipContainer>
  );

  const rejectedSuggestion = async (e: Event) => {
    e.stopPropagation();
    setIsLoading(true);

    await onSuggestionRejected({
      externalProductId: suggestedProduct.productId,
      vodConnectionSuggestionId: suggestedConnection.id,
    });

    setIsLoading(false);
  };

  const acceptSuggestion = async (e: Event) => {
    e.stopPropagation();
    setIsLoading(true);
    setSuggestedMessage(
      <TooltipContainer>
        <ConfettiIcon /> Product tagged!
      </TooltipContainer>
    );

    await onSuggestionAccepted({
      externalProductId: suggestedProduct.productId,
      vodConnectionSuggestionId: suggestedConnection.id,
    });

    setIsLoading(false);
  };

  if (isLoading) {
    return <Skeleton className="h-8 rounded border border-dashed border-purple-400"></Skeleton>;
  }

  return (
    <Tooltip placement="top" text={suggestedMessage}>
      <div className="flex gap-1 rounded border border-dashed border-purple-400 bg-gray-50 p-1">
        <div className="flex grow items-center gap-1">
          <SmallProductImage imageFit="cover" src={suggestedProduct?.imageUrl} />
          <div className="max-w-32 truncate text-ellipsis font-medium text-purple-500">
            {suggestedProduct?.title}
          </div>
        </div>
        <div className="flex gap-0.5">
          <ActionButton onClick={rejectedSuggestion} disabled={isLoading} isReject={true}>
            <XIcon />
          </ActionButton>
          <ActionButton onClick={acceptSuggestion} disabled={isLoading}>
            <ThinVIcon />
          </ActionButton>
        </div>
      </div>
    </Tooltip>
  );
};

const ActionButton = styled(GhostButton)`
  padding: 5px;

  svg {
    width: 12px;
    height: 12px;

    path {
      fill: ${({ isReject, theme }) =>
        isReject ? theme.colors.purple300 : theme.colors.purple500};
    }
  }

  &:hover {
    background: ${({ theme }) => theme.colors.purple500};

    svg {
      path {
        fill: ${({ theme }) => theme.colors.white};
      }
    }
  }
`;

const TooltipContainer = styled(TextSmall)`
  display: flex;
  gap: 8px;
  padding: 4px 6px;
  color: #a78bfa;
  align-items: center;
`;
