import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Editor from '@draft-js-plugins/editor';
import Toolbar from './toolbar/Toolbar';
import hyperLinkPlugin from './plugins/hyperLink/hyperLinkPlugin';
import useOutsideClick from 'shared/react/hooks/useOutsideClick';
import { KeyBindingUtil, RichUtils } from 'draft-js';
import { HYPER_LINK } from './constants/plugins.constants';
import SnackBar from 'app/src/basic_components/SnackBar';
import Attachments from './attachments/Attachments';

const plugins = [hyperLinkPlugin];
let isLoaded = false;
const ESCAPE_KEY = 27;
const K_KEY = 75;

const RichTextEditor = ({
  editorState,
  setEditorState,
  buttons,
  setIsTextInputFocused,
  shouldShowToolbar,
  attachments,
  ...props
}) => {
  const [editorRef, setEditorRef] = useState(null);
  const [selectedPlugin, setSelectedPlugin] = useState(null);
  const [selectionState, setSelectionState] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const containerRef = useRef();

  function focusEditor() {
    editorRef?.focus();
  }

  useOutsideClick([containerRef], () => setIsTextInputFocused(false));

  useEffect(() => {
    isLoaded = true;
  }, []);

  const handleHyperLink = () => {
    if (HYPER_LINK === selectedPlugin) {
      setSelectedPlugin(null);
      return;
    }

    setSelectionState(editorState.getSelection());
    setSelectedPlugin(HYPER_LINK);
  };

  const keyBindingFn = event => {
    if (event.which === ESCAPE_KEY) {
      editorRef?.blur();
      setIsTextInputFocused(false);
    }

    if (KeyBindingUtil.hasCommandModifier(event) && event.which === K_KEY) {
      handleHyperLink();
    }
  };

  const handleKeyCommand = command => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  return (
    <>
      <LayoutRoot ref={containerRef} onClick={focusEditor}>
        {shouldShowToolbar && (
          <Toolbar
            buttons={buttons}
            editorState={editorState}
            setEditorState={setEditorState}
            focusEditor={focusEditor}
            selectedPlugin={selectedPlugin}
            setSelectedPlugin={setSelectedPlugin}
            selectionState={selectionState}
            setSelectionState={setSelectionState}
            setErrorText={setErrorMessage}
            containerRef={containerRef}
            attachments={attachments}
            {...props}
          />
        )}
        <EditorContainer shouldShowToolbar={shouldShowToolbar}>
          {isLoaded && (
            <Editor
              editorState={editorState}
              plugins={plugins}
              ref={setEditorRef}
              spellCheck
              keyBindingFn={keyBindingFn}
              handleKeyCommand={handleKeyCommand}
              placeholder={!shouldShowToolbar && 'Reply with text'}
              onFocus={() => setIsTextInputFocused(true)}
              onChange={editorState => setEditorState(editorState)}
            />
          )}
        </EditorContainer>
        {!!attachments.length && <Attachments attachments={attachments} {...props} />}
      </LayoutRoot>
      <SnackBar
        text={errorMessage}
        open={errorMessage}
        setOpen={() => setErrorMessage(null)}
        severity="error"
      />
    </>
  );
};
const LayoutRoot = styled.div`
  background: ${({ theme }) => theme.colors.gray5};
  border-radius: 12px;
  padding: 6px;
  display: grid;
`;

const EditorContainer = styled.div`
  padding-top: ${({ shouldShowToolbar }) => (shouldShowToolbar ? '6px' : '')};
  max-height: 130px;
  min-height: ${({ shouldShowToolbar }) => (shouldShowToolbar ? '60px' : '')};
  overflow: ${({ shouldShowToolbar }) => (shouldShowToolbar ? 'scroll' : '')};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.black};
  padding-left: 4px;

  .public-DraftStyleDefault-block {
    white-space: pre-wrap;
  }

  .public-DraftEditorPlaceholder-root {
    position: absolute;
  }
`;

export default RichTextEditor;
