import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useQuizContext } from 'app/src/pages/project/pages/quiz/QuizContext';
import FlowHeader from 'app/src/pages/project/pages/quiz/quiz_result/flow/FlowHeader';
import QuizRuleList from 'app/src/pages/project/pages/quiz/quiz_result/flow/rule/QuizRuleList';
import { QuizFlowProvider } from 'app/src/pages/project/pages/quiz/quiz_result/flow/QuizFlowContext';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { useProjects } from 'app/src/context/ProjectsStore';
import FlowFooter from 'app/src/pages/project/pages/quiz/quiz_result/flow/FlowFooter';
import flowCardCss from 'app/src/pages/project/pages/quiz/quiz_result/flow/FlowCard';
import { STEP_TYPES } from 'app/src/constants/project.constants';

function QuizFlow({ quizResultId, flow }) {
  const { project } = useQuizContext();
  const [partMenuItems, setPartMenuItems] = useState([]);
  const [, { getStepDescription }] = useProjects();

  useEffect(() => {
    setPartMenuItems(getPartOptions());
  }, [project.steps.items]);

  const getPartOptions = () => {
    const excludedPartKeys = flow.rules.map(rule => rule.stepId).flat(1);

    const filteredSteps = project?.steps?.items?.filter(
      step => !excludedPartKeys.includes(step.name) && step.type !== STEP_TYPES.productsResult
    );

    const partsMenuItems =
      filteredSteps?.map((step, i) => ({
        key: i,
        id: step.name,
        title: getStepDescription(project, step),
        value: step.name,
      })) || [];

    return [...partsMenuItems];
  };

  const getProviderValue = () => {
    return {
      partMenuItems,
      setPartMenuItems,
      getPartOptions,
    };
  };

  const flowId = flow.id;

  return (
    <QuizFlowProvider value={getProviderValue()}>
      <LayoutRoot>
        <FlowHeader quizResultId={quizResultId} flowId={flowId} />
        <QuizRuleList quizResultId={quizResultId} flow={flow} />
        <FlowFooter quizResultId={quizResultId} flowId={flowId} />
      </LayoutRoot>
    </QuizFlowProvider>
  );
}

export default QuizFlow;

const LayoutRoot = styled(VerticalFlex)`
  gap: 24px;
  width: 407px;
  ${flowCardCss};
  padding: 8px 16px 16px;
`;
