import { createActionsHook, createContainer, createHook, createStore } from 'react-sweet-state';
import { v4 as uuidv4 } from 'uuid';
import { getRequest, postRequest } from 'app/src/helpers/API';
import { GOOGLE_DRIVE } from 'app/src/constants/intergrations.constants';
import {
  FEATURE_APP_LIMITS,
  FEATURE_GOOGLE_DRIVE_INTEGRATION,
  FEATURE_LIMIT_MAX_RECORDING_TIME,
  FEATURE_LIMIT_MIN_RECORDING_TIME,
} from 'shared/react/constants/features.constants';
import Utils from 'app/src/utils';
import { VideosPageVodAsset } from 'app/src/pages/dashboard/pages/videos/types/videos.types';

type State = {
  videos: VideosPageVodAsset[];
  loading: boolean | null;
  pickingFolder: boolean;
  initialized: boolean;
  gotAllVideos: boolean;
  cursor: string;
  videoFoldersById: { [key: string]: string[] };
  rootFolderId?: string | null;
};

const initialState: State = {
  videos: [],
  loading: null,
  pickingFolder: false,
  initialized: false,
  gotAllVideos: false,
  cursor: '',
  videoFoldersById: {},
  rootFolderId: undefined,
  currentReqSet: new Set(),
};

const privateActions = {
  pickerCallback:
    data =>
    async ({ setState, dispatch }) => {
      let folderId;
      const action = data[google.picker.Response.ACTION];
      if (action == google.picker.Action.PICKED) {
        const doc = data[google.picker.Response.DOCUMENTS][0];

        folderId =
          doc[google.picker.Document.TYPE] === 'folder'
            ? doc[google.picker.Document.ID]
            : doc[google.picker.Document.PARENT_ID];
      } else if (action === google.picker.Action.CANCEL) {
        folderId = null;
        if (
          !confirm(
            'You cancelled folder picking. Google Drive root folder will be used instead. Press Ok to confirm and Cancel to start over'
          )
        ) {
          dispatch(actions.pickFolder());
          return;
        }
      } else {
        return;
      }

      setState({ pickingFolder: false });
      dispatch(privateActions.saveRootFolder(folderId));
    },
  getFileFoldersNames:
    (videoId, folderId) =>
    async ({ getState, setState }, { appKey }) => {
      const { videoFoldersById } = getState();

      if (!folderId || videoFoldersById[folderId]) {
        return;
      }

      setState({ videoFoldersById: { ...videoFoldersById, [folderId]: [] } });

      try {
        const data = await postRequest('google-drive', `/google-drive/folder-names`, {
          body: {
            folderId,
            appKey,
          },
        });

        const { videoFoldersById: currentVideoFoldersById } = getState();
        currentVideoFoldersById[folderId] = data || [];
        setState({ videoFoldersById: { ...currentVideoFoldersById } });
        return data;
      } catch (err) {
        setState({ videoFoldersById: { ...videoFoldersById, [folderId]: null } });
      }
    },
  checkFile:
    (size: string, durationMillis: string | number) =>
    (_, { getSettingsByKey }) => {
      const MAX_FILE_SIZE = getSettingsByKey(FEATURE_APP_LIMITS).maxFileSizeMB;
      const limitMaxRecordingTimeSettings = getSettingsByKey(FEATURE_LIMIT_MAX_RECORDING_TIME);
      const limitMinRecordingTimeSettings = getSettingsByKey(FEATURE_LIMIT_MIN_RECORDING_TIME);

      const { maxRecordingTime, isUnlimited, globalMaxRecordingTime } =
        limitMaxRecordingTimeSettings || {};
      const { minRecordingTime } = limitMinRecordingTimeSettings || {};
      const maxTime = isUnlimited ? globalMaxRecordingTime : maxRecordingTime;
      const duration = +durationMillis / 1000;
      if (duration > maxTime) {
        return false;
      }

      if (duration < minRecordingTime) {
        return false;
      }

      const fileSize = Utils.sizeInBytesToMb(+size);
      if (MAX_FILE_SIZE <= +fileSize) {
        return false;
      }

      return true;
    },
  saveRootFolder:
    folderId =>
    async ({ setState }, { appKey }) => {
      await postRequest('google-drive', '/google-drive/set-root-folder', {
        body: { appKey, folderId },
      });
      setState({ rootFolderId: folderId, gotAllVideos: false });
    },
};

const actions = {
  fetchSubFoldersVideos:
    ({ folderId }) =>
    async ({ dispatch }, { appKey }) => {
      try {
        const searchParams = new URLSearchParams();
        searchParams.set('appKey', appKey);

        if (folderId) {
          searchParams.set('folderId', folderId);
        }

        const subFolders = await getRequest(
          'google-drive',
          `/google-drive/folder-sub-folders?${searchParams.toString()}`
        );

        const promises = subFolders.map(folderId => dispatch(actions.fetchByFolder({ folderId })));

        return Promise.all(promises);
      } catch (error) {
        console.log('Error getting sub folders', error);
      }
    },
  fetchFolderVideos:
    ({ folderId, cursor = null }) =>
    async ({ getState, setState, dispatch }, { appKey, owner, getSettingsByKey }) => {
      try {
        const { videos, gotAllVideos } = getState();
        if (gotAllVideos) {
          return;
        }

        const searchParams = new URLSearchParams();
        searchParams.set('appKey', appKey);

        if (cursor) {
          searchParams.set('nextCursor', cursor);
        }
        if (folderId) {
          searchParams.set('folderId', folderId);
        }

        const data = await getRequest(
          'google-drive',
          `/google-drive/list?${searchParams.toString()}`
        );

        const filteredNewVideos = data.files.filter(({ size, videoMediaMetadata }) => {
          const { durationMillis = 10000 } = videoMediaMetadata || {};
          return dispatch(privateActions.checkFile(size, durationMillis));
        });

        const newVideos = filteredNewVideos.map(
          ({
            thumbnailLink: posterUrl,
            id: fileId,
            createdTime: createdAt,
            name: title,
            ...data
          }) => {
            const id = uuidv4();
            return {
              ...data,
              name: title,
              title,
              id,
              newId: id,
              fileId,
              stockAsset: { posterUrl },
              externalId: fileId,
              externalCreatedAt: createdAt,
              createdAt,
              owner,
              app: GOOGLE_DRIVE,
              uploadType: GOOGLE_DRIVE,
              duration: (data?.videoMediaMetadata?.durationMillis || 0) / 1000,
            };
          }
        );

        if (getSettingsByKey(FEATURE_GOOGLE_DRIVE_INTEGRATION).importFoldersAsLabels) {
          newVideos.forEach(({ id, parents }) =>
            dispatch(privateActions.getFileFoldersNames(id, parents?.[0]))
          );
        }

        const currentVideos = [...videos, ...newVideos];

        const newState = {
          videos: currentVideos,
        };

        if (!folderId) {
          newState.accountCursor = data.nextPageToken;
        }

        if (!folderId && !data.nextPageToken) {
          newState.gotAllVideos = true;
        }

        setState(newState);

        if (currentVideos.length <= 30 && data.nextPageToken) {
          dispatch(actions.fetchFolderVideos({ folderId, cursor: data.nextPageToken }));
        }
      } catch (e) {
        console.log(e);
        Utils.logError('GoogleDriveStore.fetchByAccount', e);
        setState({ loading: false });
        return e;
      }
    },
  fetchByFolder:
    ({ folderId, cursor }) =>
    async ({ getState, setState, dispatch }, { getSettingsByKey }) => {
      const { currentReqSet } = getState();
      if (currentReqSet.has(folderId) && folderId) {
        return;
      }

      currentReqSet.add(folderId);

      await dispatch(actions.fetchFolderVideos({ folderId, cursor }));
      if (getSettingsByKey(FEATURE_GOOGLE_DRIVE_INTEGRATION)?.importSubFolders) {
        await dispatch(actions.fetchSubFoldersVideos({ folderId }));
      }

      setState({
        loading: false,
        initialized: true,
      });
    },
  fetchByAccount:
    () =>
    async ({ getState, setState, dispatch }) => {
      const { loading, gotAllVideos, accountCursor } = getState();

      if (loading || gotAllVideos) {
        return;
      }

      setState({
        loading: true,
      });

      return await dispatch(actions.fetchByFolder({ folderId: null, cursor: accountCursor }));
    },
  clearGoogleDrive:
    () =>
    ({ setState }) => {
      setState(initialState);
    },
  pickFolder:
    () =>
    async ({ getState, setState, dispatch }, { appKey }) => {
      const { pickingFolder } = getState();
      if (pickingFolder) {
        return;
      }

      setState({ pickingFolder: true });

      try {
        const oauthToken = await getRequest(
          'google-drive',
          `/google-drive/get-access-token?appKey=${appKey}`
        );

        const DisplayView = new google.picker.DocsView()
          // Next option shows hierarchical folder starting from the root but doesn't show shared folders
          // .setParent('root')
          .setIncludeFolders(true)
          .setMimeTypes('application/vnd.google-apps.folder')
          .setSelectFolderEnabled(true);
        const picker = new google.picker.PickerBuilder()
          .addView(DisplayView)
          .setOAuthToken(oauthToken)
          .setCallback(data => dispatch(privateActions.pickerCallback(data)))
          .setTitle('Select root folder')
          .build();

        if (picker.isVisible()) {
          return;
        }

        picker.setVisible(true);
      } catch (e) {
        dispatch(privateActions.saveRootFolder(null));
        setState({ pickingFolder: false });
      }
    },
};

type Actions = typeof actions;

const GoogleDriveStore = createStore<State, Actions>({
  initialState,
  actions,
  name: 'googleDrive',
});

export const useGoogleDrive = createHook(GoogleDriveStore);

export const useGoogleDriveActions = createActionsHook(GoogleDriveStore);

export const GoogleDriveContainer = createContainer(GoogleDriveStore, {
  onCleanup:
    () =>
    ({ dispatch }) => {
      dispatch(actions.clearGoogleDrive());
    },
});
