import { PLAYER_EVENTS } from 'player-events';
import React, { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import Utils from '../../../utils';
import { AUTOPLAY_UNMUTE_DATA_TEST_ID } from '../../../constants/dataTestIds.constants';
import HorizontalCss from 'shared/react/components/complex/feed/feed_products/HorizontalCss';
import LandingPageHorizontalCss from '../../../../../shared/react/components/complex/feed/feed_products/LandingPageHorizontalCss';
import { FIRST_INTERACTION_WITH_MUTED_AUTOPLAY } from '../Player';
import UnmuteAutoplayButtonWrapper from './UnmuteAutoplayButtonWrapper';
import Controls from '../controls/Controls';
import styled, { css } from 'styled-components';
import SubtitlesContainer from './SubtitlesContainer';
import OverlayText from './OverlayText';
import AnswersSection from './AnswersSection';
import { track } from '../../../tracker/Tracker';
import LoadingIndicator from '../../LoadingIndicator';
import StartScreen from '../StartScreen';
import EndScreen from '../../EndScreen';
import { getGridTemplateAreas } from './VideoOverlay.service.js';
import WaterMark from 'player/src/components/player/video-overlay/WaterMark';
import PlayerLoader from 'player/src/components/PlayerLoader';
import { projectsWithQuiz } from 'player/src/customData/customerSettings';
import { useIsMobile } from 'shared/react/hooks/useIsMobile';
import useKeyboardNavigation from 'player/src/hooks/useKeyboardNavigation';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const FeedOverlay = lazy(() =>
  Utils.componentLoader(() =>
    import(/* webpackChunkName: "feed-overlay" */ './feed-overlay/FeedOverlay')
  )
);

const SmallProductWrapper = lazy(() =>
  Utils.componentLoader(() =>
    import(
      /* webpackChunkName: "small-product-wrapper" */ 'shared/react/components/complex/feed/feed_overlay_mobile/SmallProductWrapper'
    )
  )
);

const QuizOverlay = lazy(() =>
  Utils.componentLoader(() => import(/* webpackChunkName: "quiz-container" */ './quiz/Quiz'))
);

const VideoOverlay = props => {
  const {
    isProducts,
    isVertical,
    selectedVideo,
    controlsShown,
    hasSubtitles,
    pause,
    videoTime,
    videoDuration,
    playlist,
    collectInfo,
    isFeedModeActive,
    feedVideo,
    hidePoweredBy,
    showEndScreen,
    isLoading,
    userPause,
    showStartScreen,
    isIntegrationLoading,
    showAutoplayUnmute,
    setShowAutoplayUnmute,
    setQuizAnswersKeys,
    quizAnswersKeys,
    tolstoyType,
    projectId,
    sessionStartReported,
    setSessionStartReported,
    shouldShowChatLandingPage,
    play,
    landingPage,
    handlePlayPause,
    nextStepType,
  } = props;
  const [isPanelExpanded, setPanelExpanded] = useState(false);
  const [clickOrder, setClickOrder] = useState(1);
  const isDefaultResponseEnabled = selectedVideo?.defaultResponsesEnabled;
  const isMobile = useIsMobile();
  const verticalOrientation = isVertical || isMobile;
  const overlayTextColor = selectedVideo.overlayTextColor;
  const overlayText = selectedVideo.overlayText;
  const showOverlayText = controlsShown && overlayText;
  const { onKeyDown, focusOnElement, setNextRef, resetRefs } = useKeyboardNavigation();

  const getVideoTimeParams = () => {
    const time = videoTime();
    let percentWatched = Math.round((time / videoDuration) * 100);
    if (isNaN(percentWatched) || percentWatched === Infinity) {
      percentWatched = 0;
    }
    return { videoTime: time, percentWatched };
  };

  function trackClick(text, next, type, answerKey, formData) {
    if (type === 'Input' || next === 'url') {
      pause();
    }

    const nextVideo = playlist.find(video => {
      return video.key === next;
    });

    const extraPrams = getVideoTimeParams();

    let params = {
      ...extraPrams,
      text,
      next,
      clickOrder,
      answerKey,
      answerType: type,
      nextVideoName: nextVideo?.name,
    };

    setQuizAnswersKeys([...quizAnswersKeys, { text, answerKey }]);

    if (formData) {
      params.formData = JSON.stringify(formData);
    }
    track('clickCta', params);
    if (showAutoplayUnmute && !sessionStartReported) {
      track(PLAYER_EVENTS.sessionStart, {
        ...params,
        interactionType: FIRST_INTERACTION_WITH_MUTED_AUTOPLAY,
      });
      setSessionStartReported(true);
    }
    setClickOrder(clickOrder + 1);
    setShowAutoplayUnmute(false);
  }

  const includesLeadForm = useMemo(() => {
    let hasLeadForm = false;

    playlist.forEach(part => {
      part.answers.forEach(answer => {
        if (answer.type === 'MultiInput') {
          hasLeadForm = true;
        }
      });
    });
    return hasLeadForm;
  }, [playlist]);

  function shouldOpenLeadFormAfterStep(includesAfterStep, afterStep) {
    const isCollectAfterAntResponse =
      collectInfo.collectAfterAnyResponse || collectInfo.collectAfterAnyResponse === undefined;

    const afterResponseAndNoLeadForm =
      !collectInfo.hasEmail &&
      ((!includesLeadForm && afterStep?.[0] === 'afterAnyResponse') || isCollectAfterAntResponse);

    return includesAfterStep || afterResponseAndNoLeadForm;
  }

  const shouldShowFeed = isFeedModeActive || feedVideo;
  const isQuiz = tolstoyType?.includes('quiz') || projectsWithQuiz.includes(projectId);

  useEffect(() => {
    if (!showEndScreen) {
      resetRefs();
    }
  }, [selectedVideo, showEndScreen]);

  useEffect(() => {
    if (nextStepType || Utils.getIsAppPreview()) {
      return;
    }

    const onSpaceClick = e => {
      if (e.code === 'Space') {
        handlePlayPause();
        e.stopPropagation();
      }
    };

    window.addEventListener('keydown', onSpaceClick);
    return () => window.removeEventListener('keydown', onSpaceClick);
  }, [handlePlayPause, nextStepType]);

  return (
    <LayoutRoot
      isVertical={verticalOrientation}
      isPanelExpanded={isPanelExpanded}
      isProducts={isProducts}
      GridTemplateAreas={getGridTemplateAreas(
        isProducts,
        verticalOrientation,
        hasSubtitles,
        isPanelExpanded
      )}
      showOverlayText={showOverlayText}
      hasSubtitles={hasSubtitles}
      shouldShowChatLandingPage={shouldShowChatLandingPage}
      landingPage={landingPage}
      hidePoweredBy={hidePoweredBy}
      aria-label="Video overlay"
      role="group"
    >
      <Controls
        {...props}
        isDefaultResponseEnabled={isDefaultResponseEnabled}
        trackClick={trackClick}
        shouldOpenLeadFormAfterStep={shouldOpenLeadFormAfterStep}
        setNextRef={setNextRef}
        onKeyDown={onKeyDown}
      />
      {!isFeedModeActive && showOverlayText && (
        <OverlayText {...props} overlayText={overlayText} overlayTextColor={overlayTextColor} />
      )}
      {showAutoplayUnmute && (
        <BackdropCenteredAbsoluteContainer>
          <UnmuteAutoplayButtonWrapper data-test-id={AUTOPLAY_UNMUTE_DATA_TEST_ID} />
        </BackdropCenteredAbsoluteContainer>
      )}
      {!isFeedModeActive && !isProducts && controlsShown && (
        <AnswersSection
          {...props}
          isDefaultResponseEnabled={isDefaultResponseEnabled}
          trackClick={trackClick}
          shouldOpenLeadFormAfterStep={shouldOpenLeadFormAfterStep}
          setNextRef={setNextRef}
          onKeyDown={onKeyDown}
          focusOnElement={focusOnElement}
        />
      )}
      {hasSubtitles && <SubtitlesContainer isControlsShown={controlsShown} {...props} />}
      {shouldShowFeed && (
        <Suspense fallback={<PlayerLoader />}>
          <FeedOverlay {...props} getVideoTimeParams={getVideoTimeParams} />
        </Suspense>
      )}
      {isQuiz && (
        <Suspense fallback={<PlayerLoader />}>
          <QuizOverlay {...props} hidePoweredBy={hidePoweredBy} />
        </Suspense>
      )}
      {isProducts && (
        <Suspense fallback={<PlayerLoader />}>
          <ProductsMainContainer
            isVertical={verticalOrientation}
            hidePoweredBy={hidePoweredBy}
            isPanelExpanded={isPanelExpanded}
          >
            <SmallProductWrapper
              pause={pause}
              step={selectedVideo}
              play={play}
              verticalOrientation={verticalOrientation}
              hidePoweredBy={hidePoweredBy}
              isInteractive
              landingPage={landingPage}
              setPanelExpanded={setPanelExpanded}
            />
          </ProductsMainContainer>
        </Suspense>
      )}

      <StartScreen {...props} />
      {((isLoading && !userPause && !showStartScreen) || isIntegrationLoading) && (
        <LoadingIndicatorContainer>
          <LoadingIndicator size={40} />
        </LoadingIndicatorContainer>
      )}
      {showEndScreen && <EndScreen {...props} />}
      <WaterMarkGridArea>
        <WaterMark
          setNextRef={setNextRef}
          onKeyDown={onKeyDown}
          hidePoweredBy={hidePoweredBy}
          isPanelExpanded={isPanelExpanded}
          disableGradient={isProducts}
          {...props}
        />
      </WaterMarkGridArea>
    </LayoutRoot>
  );
};

const LayoutRootMobile = css`
  grid-template-areas: ${({ isProducts, hasSubtitles }) =>
    getGridTemplateAreas(isProducts, true, hasSubtitles, false)};
  position: ${({ shouldShowChatLandingPage }) => (shouldShowChatLandingPage ? 'absolute' : '')};
  inset: ${({ shouldShowChatLandingPage }) => (shouldShowChatLandingPage ? 0 : '')};
`;

const LayoutRootProductsCss = css`
  position: absolute;
  z-index: 1;
  width: 100%;
  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.getHexOpacity(theme.colors.black, 0)} 55%,
    ${({ theme }) => theme.getHexOpacity(theme.colors.black, 45)} 100%
  );
`;

const LayoutRoot = styled.div`
  display: grid;
  height: 100%;
  grid-template-areas: ${({ GridTemplateAreas }) => GridTemplateAreas};
  grid-template-columns: ${({ isProducts, isVertical }) =>
    isProducts && !isVertical ? '6fr 4fr' : ''};
  padding-right: ${({ isProducts, isVertical, isPanelExpanded }) =>
    isProducts && !isVertical && !isPanelExpanded ? '12px' : ''};
  grid-template-rows:
    3fr
    ${({ showOverlayText, hasSubtitles, isProducts }) =>
      showOverlayText || hasSubtitles
        ? `repeat(${+!!showOverlayText + +!!hasSubtitles}, ${isProducts ? '1fr' : 'auto'})`
        : ''};
  grid-row: 1;
  grid-column: 1;
  ${({ isProducts }) => isProducts && LayoutRootProductsCss};

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    ${LayoutRootMobile};
  }

  ${({ isVertical, landingPage }) => {
    if (isVertical) {
      return '';
    }

    if (landingPage) {
      return LandingPageHorizontalCss;
    }

    return HorizontalCss;
  }};
`;

const ProductsMainContainerMobile = css`
  grid-template-columns: auto;
  grid-template-rows: 1fr auto;
  position: relative;
  max-height: 100%;
`;

const ProductsMainContainer = styled.div`
  position: relative;
  width: 100%;
  left: 0;
  height: 100%;
  max-height: 100%;
  grid-area: products;
  display: ${({ isPanelExpanded }) => (isPanelExpanded ? 'none' : 'flex')};
  overflow: hidden;

  ${({ isVertical }) => isVertical && ProductsMainContainerMobile};
  min-height: ${({ isVertical }) => (isVertical ? '' : '132px')};

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    ${ProductsMainContainerMobile};
  }
`;

const LoadingIndicatorContainer = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
`;

const WaterMarkGridArea = styled.div`
  position: ${({ isPanelExpanded }) => (isPanelExpanded ? 'relative' : '')};
  grid-area: watermark;
`;

const BackdropCenteredAbsoluteContainer = styled(VerticalFlex)`
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  height: 100%;
  z-index: 2;
  background: ${({ theme }) => theme.getHexOpacity(theme.colors.black, 40)};

  :empty {
    display: none;
  }
`;

export default VideoOverlay;
