import React, { useState } from 'react';
import styled from 'styled-components';
import { CUSTOM_PLAYLIST_EXTRA_BUTTONS_DATA_TEST_ID } from 'src/constants/dataTestIds.constants';
import ThreeVerticalDotsIcon from 'app/src/images/dashboard_v2/ThreeVerticalDotsIcon';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import MenuWithItems, { MenuItemType } from 'app/src/complex_components/menu_items/MenuWithItems';
import Tooltip from 'app/src/basic_components/tooltip-v2/Tooltip';
import { StyledSidebarBaseItem as SidebarBaseItem } from './SidebarBaseItem';
import SidebarItemCount from './SidebarItemCount';
import SidebarItemName from './SidebarItemName';

type Props = {
  name: React.ReactNode;
  isDisabled?: boolean;
  count?: string | number;
  menuItems?: MenuItemType[];
  dataTestId?: string;
  isSelected?: boolean;
  isTopLevel?: boolean;
  tooltipText?: string;
  withEditMenu?: boolean;
  onClick?: (e: any) => void;
};

export const SidebarItem = ({
  name,
  count,
  menuItems,
  tooltipText = '',
  dataTestId,
  isDisabled = false,
  isSelected = false,
  isTopLevel = false,
  withEditMenu = false,
  onClick = () => {},
}: Props) => {
  const [anchorElement, setAnchorElement] = useState(null);

  const handleOpenMenu = e => {
    if (!withEditMenu) {
      return;
    }

    e.stopPropagation();
    setAnchorElement(e.currentTarget);
  };
  const handleCloseMenu = () => setAnchorElement(null);
  return (
    <Tooltip hidden={!tooltipText || !isDisabled} text={tooltipText as string} placement="top">
      <StyledSidebarBaseItem
        data-test-id={dataTestId}
        onClick={onClick}
        isSelected={isSelected}
        $withEditMenu={withEditMenu}
        isDisabled={isDisabled}
      >
        <SidebarItemName title={tooltipText} isTopLevel={isTopLevel}>
          {name}
        </SidebarItemName>
        {count && !isDisabled && <SidebarItemCount>{count}</SidebarItemCount>}
        {withEditMenu && (
          <>
            <EndIconContainer
              data-test-id={CUSTOM_PLAYLIST_EXTRA_BUTTONS_DATA_TEST_ID}
              onClick={handleOpenMenu}
            >
              <ThreeVerticalDotsIcon />
            </EndIconContainer>
            <MenuWithItems
              menuItems={menuItems}
              anchorElement={anchorElement}
              onClose={handleCloseMenu}
            />
          </>
        )}
      </StyledSidebarBaseItem>
    </Tooltip>
  );
};

const EndIconContainer = styled(HorizontalFlexCentered)`
  opacity: 0;
  min-width: 18px;
  min-height: 18px;
  border-radius: 16px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
  }

  & svg > path {
    fill: ${({ theme }) => theme.colors.neutralDark};
  }

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    opacity: 1;
  }
`;

const StyledSidebarBaseItem = styled(SidebarBaseItem)`
  padding: ${({ $withEditMenu }) => ($withEditMenu ? '8px 0 8px 16px' : '8px 20px 8px 16px')};

  &:hover ${EndIconContainer} {
    opacity: 1;
  }
`;

export default SidebarItem;
