export const DEFAULT_CARD_WIDTH = 202;
export const DEFAULT_CARD_HEIGHT = 342;

export const DEFAULT_CONTAINER_WIDTH = 654;
export const DEFAULT_CONTAINER_HEIGHT = 342;

export const DEFAULT_DESCRIPTION_WIDTH = 170;
export const DEFAULT_DESCRIPTION_HEIGHT = 64;

export const DEFAULT_GAP = 24;
export const DEFAULT_PADDING = 16;
export const DEFAULT_BORDER_RADIUS = 16;
export const DEFAULT_FONT_SIZE = 14;
export const DEFAULT_IMAGE_HEIGHT = 278;
