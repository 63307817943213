import styled from 'styled-components';
import React, { useEffect, useRef, useState } from 'react';
import { VIDEO_CONTAINER_DATA_TEST_ID } from '../constants/dataTestIds.constants';
import { addAnalyticsData } from '../tracker/Tracker';
import Utils from '../utils';
import { getVideoBaseUrl } from './player/player.utils';
import axios from 'axios';
import { PLAYER_RESOLUTIONS } from 'player/src/constants/playerResolutions.constants';
import SharedUtils from 'shared/react/utils/utils';
import { TOLSTOY_PLAYER_VIDEO_METADATA_CHANGE_MESSAGE } from 'shared/react/constants/messages.constants';
import { REACT_APP_VIDEO_OUTPUT } from 'player/src/config/player.config';

const projectIdsWithZoomTop = [
  '041aa859-5660-493c-9988-c0e1330d73e5',
  '90b2ee98-f70f-4cc7-b61e-8cead80ad0ba',
  'd1f41d67-589c-4f4d-a614-052a9f7b7646',
  '1de03e35-398a-4233-b1d0-305236d90203',
  '26eb9c6b-4249-4c1a-8f48-b9ed1bd3fcab',
  'e3e706b3-b864-4a72-adc8-84d697f3ad9a',
  '2807235a-26f8-43f4-b9b4-d0c4bdf40bbd',
  'e5c85040-976b-4aa5-99fa-b5c7ad0c02f9',
  'f7f93d2e-aad1-416f-92f6-a9aa3cab03fc',
  'd410452a-a023-4fb7-bc08-b0af73e07afb',
  '09879bcc-14d5-4bf2-b0fb-cb359b169ef0',
  '9f579237-231e-4c75-8c15-c01271bc50bb',
];

const projectIdsWithCustomRes = {
  ['56cde8c0-34ef-4755-ae11-091048a96e51']: PLAYER_RESOLUTIONS['640'],
  ['830791ae-d196-4d07-99ec-1220e10b26c1']: PLAYER_RESOLUTIONS['640'],
};

let videoUp = false;
let timeout;
let isPageVisible = false;
let errorCount = 0;

function CustomVideo({
  showStartScreen,
  video,
  videoSpeed,
  firstVideoLoaded,
  setFirstVideoLoaded,
  videoContain,
  dontDownload,
  hasSubtitles,
  videoUrl,
  transitioningFromVideoId,
  videoId,
  videoOwner,
  videoKey,
  controlsShown,
  setControlsShown,
  setPlayed,
  isFirstVideo,
  showEndScreen,
  videoRef,
  setVideoDuration,
  videoDuration,
  pause,
  play,
  setElementsCount,
  wide,
  landingPage,
  onLoaded,
  addSubtitlesChangeListener,
  projectId,
  videoResolution,
  goToEnd,
  shouldShowThread,
  handleFullscreenExit,
  userMuted,
  isFullscreen,
  isEmbed,
  playing,
  feedVideo,
  isFeedModeActive,
  onCanPlay,
  isProducts,
  canPlayThroughVideo,
  onCanPlayThrough,
  shouldShowChatLandingPage,
  playerResolution,
}) {
  const minResolution = playerResolution?.minResolution;
  const mobileMinResolution = playerResolution?.mobileMinResolution;

  const getVerticalResolution = () => {
    const isMobile = Utils.isMobile();
    if (
      (window.innerHeight <= PLAYER_RESOLUTIONS['640'] &&
        minResolution <= PLAYER_RESOLUTIONS['640'] &&
        !isMobile) ||
      (window.innerHeight <= PLAYER_RESOLUTIONS['640'] &&
        isMobile &&
        mobileMinResolution <= PLAYER_RESOLUTIONS['640'])
    ) {
      return PLAYER_RESOLUTIONS['640'];
    }

    return '';
  };

  const getHorizontalResolution = () => {
    const isMobile = Utils.isMobile();

    if (
      (window.innerWidth <= PLAYER_RESOLUTIONS['640'] &&
        minResolution <= PLAYER_RESOLUTIONS['640'] &&
        !isMobile) ||
      (window.innerWidth <= PLAYER_RESOLUTIONS['640'] &&
        isMobile &&
        mobileMinResolution <= PLAYER_RESOLUTIONS['640'])
    ) {
      return PLAYER_RESOLUTIONS['640'];
    }

    return '';
  };

  const getDefaultResolutionByScreenSize = () => {
    if (window.innerHeight > window.innerWidth) {
      return getVerticalResolution();
    }

    return getHorizontalResolution();
  };

  const getShowControlsAtTime = () => {
    const { answerTime } = video;

    if (Utils.isNullOrUndefined(answerTime)) {
      return 2;
    }

    if (videoDuration < 1) {
      return 0;
    }

    if (videoDuration < answerTime) {
      return videoDuration - 0.5;
    }

    return answerTime;
  };

  const defaultResolution = getDefaultResolutionByScreenSize();
  const [subtitlesObjectUrl, setSubtitlesObjectUrl] = useState(null);
  const [resolution, setResolution] = useState(
    projectIdsWithCustomRes[projectId] || videoResolution || defaultResolution
  );
  const baseUrl = getVideoBaseUrl(videoOwner);
  const trackRef = useRef(null);
  const [isMetaDataLoaded, setIsMetaDataLoaded] = useState(false);
  const showControlsAtTime = getShowControlsAtTime();

  const getVideoSrc = (res, goToOriginal) => {
    if (Utils.isLightHouse(navigator.userAgent)) {
      res = PLAYER_RESOLUTIONS.preview;
    }
    const videoProviders = ['vimeo', 'synthesia', 'pexels'];

    const videoRes = res ? `_${res}.mp4` : '.mp4';

    if (
      res === PLAYER_RESOLUTIONS.preview_embed &&
      !videoUrl?.includes?.(REACT_APP_VIDEO_OUTPUT) &&
      !video?.stockAsset?.hasOriginal
    ) {
      return `${baseUrl}/${videoId}/${videoId}${videoRes}`;
    }

    if (
      !videoUrl ||
      (goToOriginal && !videoProviders.some(provider => videoUrl.includes(provider)))
    ) {
      if (feedVideo && isFeedModeActive) {
        return `${feedVideo?.videoUrl.split('.mp4')[0]}${videoRes}`;
      }
      return `${baseUrl}/${videoId}/${videoId}${videoRes}`;
    }

    if (feedVideo && isFeedModeActive) {
      return `${feedVideo?.videoUrl.split('.mp4')[0]}${videoRes}`;
    }

    if (videoProviders.some(provider => videoUrl.includes(provider))) {
      return videoUrl;
    }

    if (video?.stockAsset?.shopifyFileId) {
      return videoUrl;
    }

    return `${videoUrl.split('.mp4')[0]}${videoRes}`;
  };

  const onError = async err => {
    const ref = videoRef.current;

    errorCount++;
    if (!isPageVisible || err.target.networkState !== 3 || errorCount >= 2) {
      return;
    }
    setTimeout(async () => {
      setResolution('');
      setFirstVideoLoaded(false);
      ref.src = getVideoSrc('', true);
      await play({ videoRef: ref, mute: !playing, cancelUserPause: true });
    }, 500);
  };

  const startVideo = () => {
    const ref = videoRef.current;

    if (!ref) {
      return;
    }

    const onEnded = async () => {
      if (!isPageVisible || isProducts) {
        return;
      }
      if (!video?.answers?.length && !video.defaultResponsesEnabled) {
        goToEnd();
      }
      if (video?.defaultResponsesEnabled) {
        const isDisabled = video.defaultResponses.every(res => res.disabled);
        if (isDisabled) {
          goToEnd();
        }
      }
    };

    ref.addEventListener('error', onError);
    ref.addEventListener('ended', onEnded);

    const removeListeners = () => {
      ref.removeEventListener('error', onError);
      ref.removeEventListener('ended', onEnded);
    };

    if (isFeedModeActive || ref.src) {
      return removeListeners;
    }

    const resName =
      showStartScreen && !transitioningFromVideoId && isEmbed
        ? PLAYER_RESOLUTIONS.preview_embed
        : resolution;

    ref.src = getVideoSrc(resName);

    if (isFirstVideo && showStartScreen && (isPageVisible || !dontDownload)) {
      play({ videoRef: ref, mute: true });
    }

    return removeListeners;
  };

  function handleChange(currentTime) {
    if (!firstVideoLoaded) {
      setFirstVideoLoaded(true);
    }

    if (
      transitioningFromVideoId ||
      controlsShown ||
      showStartScreen ||
      showEndScreen ||
      videoDuration === 0
    ) {
      return;
    }

    if (currentTime >= showControlsAtTime && !controlsShown) {
      setElementsCount(video.answers.length);
      setControlsShown(true);
    }
  }

  function isActive() {
    return !transitioningFromVideoId || transitioningFromVideoId === videoKey;
  }
  function handleTimeUpdate() {
    SharedUtils.postMessageToWindow({
      name: TOLSTOY_PLAYER_VIDEO_METADATA_CHANGE_MESSAGE,
      duration: videoRef.current.duration,
      currentTime: videoRef.current.currentTime,
    });

    if (isActive() && canPlayThroughVideo) {
      let value = videoRef.current.currentTime / videoDuration;
      if (value > 1) value = 1;
      setPlayed(value);
      handleChange(videoRef.current.currentTime);
    }
  }

  function handleDurationChange(event) {
    if (isActive()) {
      setVideoDuration(event.target.duration);
    }
  }

  function handlePlay() {
    if (showStartScreen) {
      return;
    }
    if (showEndScreen) {
      pause();
    }
  }

  function onTrackLoaded() {
    addSubtitlesChangeListener(videoRef.current);
  }

  function addSubtitlesTrack() {
    trackRef.current.src = subtitlesObjectUrl;
    trackRef.current.onload = onTrackLoaded;
    onTrackLoaded();
  }

  function handleLoad() {
    setIsMetaDataLoaded(true);
    if (!firstVideoLoaded) {
      setFirstVideoLoaded(true);
    }
    if (isActive()) {
      onLoaded();
    }
  }

  const getFullscreenType = () => {
    if (isFeedModeActive) {
      return 'contain';
    }

    if (isFullscreen) {
      return 'contain';
    }

    if (projectIdsWithZoomTop.includes(projectId)) {
      return 'none';
    }

    if (wide) {
      return wide;
    }

    if (videoContain) {
      return 'contain';
    }

    return 'cover';
  };

  useEffect(() => {
    setResolution(videoResolution || defaultResolution);
  }, [videoResolution]);

  useEffect(() => {
    if (dontDownload) {
      return;
    }

    isPageVisible = true;
  }, [dontDownload]);

  useEffect(() => {
    const ref = videoRef.current;
    if (ref?.src || !ref || !isPageVisible) {
      clearTimeout(timeout);
    }
  }, [isPageVisible, videoUrl]);

  useEffect(() => {
    const ref = videoRef.current;
    if (!ref || (firstVideoLoaded && !isFeedModeActive) || !feedVideo) {
      return;
    }

    ref.poster = '';
    if (ref?.src === feedVideo?.videoUrl && isFeedModeActive) {
      play({ videoRef: ref, mute: false, cancelUserPause: true });
      return;
    }

    const resName =
      showStartScreen && !transitioningFromVideoId && isEmbed
        ? PLAYER_RESOLUTIONS.preview_embed
        : videoResolution;
    ref.src = getVideoSrc(resName);
    ref.addEventListener('error', onError);

    setResolution('');
    setFirstVideoLoaded(false);
    play({ videoRef: ref, mute: false, cancelUserPause: null });
    return () => {
      ref.removeEventListener('error', onError);
    };
  }, [feedVideo, isFeedModeActive, videoRef.current]);

  useEffect(startVideo, [
    videoUrl,
    videoId,
    videoRef,
    isFirstVideo,
    showStartScreen,
    feedVideo,
    isFeedModeActive,
  ]);

  useEffect(() => {
    if (!hasSubtitles) {
      return;
    }

    axios
      .get(`${baseUrl}/${videoId}/${videoId}.vtt`, { responseType: 'blob' })
      .then(response => {
        if (response.status === 200) {
          return setSubtitlesObjectUrl(URL.createObjectURL(response.data));
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, [baseUrl, videoId]);

  useEffect(() => {
    errorCount = 0;

    const splitVideoUrl = feedVideo?.videoUrl.split('/') || [];
    const videoId = splitVideoUrl[splitVideoUrl.length - 2];
    const videoName = isFeedModeActive ? feedVideo?.title || 'Discover' : video.videoName;
    addAnalyticsData({
      videoName,
      question: video.question,
      stepName: video.key,
      videoId: videoId || video.videoId,
    });
  }, [video, feedVideo]);

  useEffect(() => {
    addAnalyticsData({
      videoDuration: videoDuration,
    });
  }, [videoDuration]);

  useEffect(() => {
    window.addEventListener('message', event => {
      if (event.data === 'tolstoyModalClose') {
        isPageVisible = false;
      }

      if (event.data === 'tolstoyModalOpen') {
        isPageVisible = true;
      }
    });
  }, []);

  useEffect(() => {
    const visibilityEventNames = Utils.getVisibilityEventNames();

    function handleVisibilityChange() {
      if (videoRef && videoRef.current) {
        if (document[visibilityEventNames.hidden]) {
          pause();
        } else {
          let currentTime = videoRef.current.currentTime;
          videoRef.current.currentTime = currentTime - 0.01;
          pause();
        }
      }
    }

    if (!transitioningFromVideoId && Utils.isIos()) {
      document.addEventListener(
        visibilityEventNames.visibilityChange,
        handleVisibilityChange,
        false
      );
    } else {
      document.removeEventListener(
        visibilityEventNames.visibilityChange,
        handleVisibilityChange,
        false
      );
    }
  }, [transitioningFromVideoId, videoRef, pause]);

  useEffect(() => {
    if (hasSubtitles && subtitlesObjectUrl && isMetaDataLoaded) {
      addSubtitlesTrack();
    }
  }, [subtitlesObjectUrl, isMetaDataLoaded]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = videoSpeed;
    }
  }, [videoSpeed]);

  useEffect(() => {
    const videoContainer = document.getElementById('videoContainer');

    videoContainer?.addEventListener(
      'webkitendfullscreen',

      handleFullscreenExit,
      false
    );
  }, []);

  if (!dontDownload) {
    videoUp = true;
  }

  if (!isPageVisible && !videoUp) {
    return null;
  }
  videoUp = true;

  return (
    <Video
      loop={showStartScreen}
      playsInline
      ref={videoRef}
      autoPlay={false}
      shouldShowThread={shouldShowThread}
      wide={wide}
      zoomTop={projectIdsWithZoomTop.includes(projectId)}
      landingPage={landingPage}
      videoContain={videoContain}
      muted={userMuted || (isFirstVideo && showStartScreen)}
      preload="metadata"
      disablePictureInPicture={true}
      onDurationChange={handleDurationChange}
      onTimeUpdate={handleTimeUpdate}
      onPlay={handlePlay}
      onLoadedMetadata={handleLoad}
      id="videoContainer"
      controlsList="nodownload noplaybackrate"
      isFullscreen={isFullscreen}
      fullscreenType={getFullscreenType}
      data-test-id={VIDEO_CONTAINER_DATA_TEST_ID}
      onCanPlay={onCanPlay}
      onCanPlayThrough={onCanPlayThrough}
      shouldShowChatLandingPage={shouldShowChatLandingPage}
      aria-label={`Video title: ${video?.question}`}
    >
      {hasSubtitles && subtitlesObjectUrl && <track kind="captions" ref={trackRef} />}
    </Video>
  );
}

export default CustomVideo;

const Video = styled.video`
  border-radius: 10px;
  position: ${({ wide, landingPage }) => (wide || landingPage ? 'absolute' : 'fixed')};
  min-height: ${({ wide }) => (wide ? 0 : '100%')};
  object-position: ${({ zoomTop }) => (zoomTop ? '50% 0' : '50% 50%')};
  object-fit: ${({ fullscreenType }) => fullscreenType};
  top: 0;
  left: 0;
  min-width: 100%;
  background: ${({ videoContain }) => (videoContain ? 'black' : 'transparent')};
  width: 100%;

  @media (max-width: 450px) {
    border-radius: 0;
    background: black;
    position: ${({ shouldShowThread, shouldShowChatLandingPage }) =>
      shouldShowThread || shouldShowChatLandingPage ? 'initial' : 'fixed'};
  }

  @media screen and (max-height: 500px) {
    border-radius: 0;
    background: black;
  }

  ${Utils.isMobile()
    ? '@media screen and (orientation: landscape) and (max-height: 500px) {object-fit: contain}'
    : ''}
`;
