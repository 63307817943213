import React from 'react';
import styled from 'styled-components';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { GhostButton, PrimaryButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { DesignSizes } from 'shared/react/theme/DesignSystem';

type Props = {
  onSave: () => void;
  onDiscard: () => void;
  isLoading: boolean;
};

const EndButtons = ({ onSave, onDiscard, isLoading }: Props) => {
  return (
    <LayoutRoot>
      <GhostButton size={DesignSizes.SMALL} onClick={onDiscard}>
        Discard
      </GhostButton>
      <PrimaryButton size={DesignSizes.SMALL} isLoading={isLoading} onClick={onSave}>
        Save
      </PrimaryButton>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlex)`
  justify-content: flex-end;
`;

export default EndButtons;
