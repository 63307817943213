import React from 'react';
import EditorSubheader from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorSubheader';
import EditorToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorToggleItem';

type Props = {
  settings?: any;
  disabled?: boolean;
  onChange?: any;
};

const StockEditor = ({ settings = {}, disabled = false, onChange = () => {} }: Props) => {
  const { hideOOSProducts } = settings;

  const updateSettings = update => onChange({ ...settings, ...update });
  const handleHideOOSProductsUpdate = hideOOSProducts => updateSettings({ hideOOSProducts });

  return (
    <>
      <EditorSubheader title="Stock" />
      <EditorToggleItem
        disabled={disabled}
        text="Hide Out of Stock products"
        checked={hideOOSProducts}
        onChange={handleHideOOSProductsUpdate}
      />
    </>
  );
};

export default StockEditor;
