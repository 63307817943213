import React from 'react';
import styled from 'styled-components';

function RulesLocationEditor() {
  return <LayoutRoot>TODO</LayoutRoot>;
}

export default RulesLocationEditor;

const LayoutRoot = styled.div`
  display: flex;
  flex-direction: column;
`;
