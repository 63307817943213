import React from 'react';
import styled from 'styled-components';
import lineChartBox from './images/lineChartBox.png';
import table from './images/table.png';

const AnalyticsEmailEmptyState = () => {
  return (
    <div>
      <ChartsContainer>
        <Image src={lineChartBox} />
        <Image src={lineChartBox} />
        <Image src={lineChartBox} />
        <Image src={lineChartBox} />
      </ChartsContainer>
      <Image src={table} />
    </div>
  );
};

const ChartsContainer = styled.div`
  display: grid;
  gap: 16px;
  grid-auto-rows: 302px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  max-width: 1600px;
`;

const Image = styled.img`
  width: 100%;
`;

export default AnalyticsEmailEmptyState;
