import React from 'react';

const Ecommerce = () => {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.10156 11.25C5.46875 11.25 5 11.7656 5 12.375C5 13.0078 5.49219 13.5 6.10156 13.5C6.6875 13.5 7.20312 13.0078 7.20312 12.375C7.20312 11.7656 6.73438 11.25 6.10156 11.25ZM12.8516 11.25C12.2188 11.25 11.75 11.7656 11.75 12.375C11.75 13.0078 12.2422 13.5 12.8516 13.5C13.4375 13.5 13.9766 13.0078 13.9766 12.375C13.9766 11.7656 13.4844 11.25 12.8516 11.25ZM15.3359 2.55469C15.1953 2.36719 14.9844 2.25 14.7266 2.25H4.83594L4.78906 1.96875C4.74219 1.71094 4.50781 1.5 4.25 1.5H2.53906C2.23438 1.5 2 1.75781 2 2.0625C2 2.39062 2.23438 2.625 2.53906 2.625H3.75781L5.16406 10.0547C5.23438 10.3125 5.46875 10.5 5.75 10.5H13.4375C13.7422 10.5 14 10.2656 14 9.96094C14 9.63281 13.7422 9.375 13.4375 9.375H6.19531L5.98438 8.25H13.4609C13.7891 8.25 14.0703 8.03906 14.1641 7.71094L15.4531 3.23438C15.5234 3 15.4766 2.74219 15.3359 2.55469ZM13.1797 7.125H5.77344L5.07031 3.375H14.2344L13.1797 7.125ZM1.0625 4.875H2.9375C3.24219 4.875 3.5 4.64062 3.5 4.3125C3.5 4.00781 3.24219 3.75 2.9375 3.75H1.0625C0.734375 3.75 0.5 4.00781 0.5 4.3125C0.5 4.64062 0.734375 4.875 1.0625 4.875ZM1.0625 6.75H3.3125C3.61719 6.75 3.875 6.51562 3.875 6.1875C3.875 5.88281 3.61719 5.625 3.3125 5.625H1.0625C0.734375 5.625 0.5 5.88281 0.5 6.1875C0.5 6.51562 0.734375 6.75 1.0625 6.75ZM3.6875 7.5H1.0625C0.734375 7.5 0.5 7.75781 0.5 8.0625C0.5 8.39062 0.734375 8.625 1.0625 8.625H3.6875C3.99219 8.625 4.25 8.39062 4.25 8.0625C4.25 7.75781 3.99219 7.5 3.6875 7.5Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default Ecommerce;
