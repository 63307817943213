import React from 'react';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import {
  PRODUCT_DESCRIPTION_ALWAYS_SHOW,
  PRODUCT_DESCRIPTION_COLLAPSED_BY_DEFAULT,
} from '../../../LookAndFeelPage.constants';
import EditorOnOffToggleItem from '../shared/EditorOnOffToggleItem';
import { useLookAndFeelContext } from '../../../LookAndFeelContext';
import EditorHeader from '../shared/EditorHeader';
import EditorMainContainer from '../shared/EditorMainContainer';

const ProductDescriptionEditor = () => {
  const {
    customizationSettings: {
      playerSettings: {
        productDescriptionCollapsedByDefault = false,
        productDescriptionAlwaysShow = false,
      },
    },
    setPlayerSettingsProperty,
  } = useLookAndFeelContext();
  const onToggleDescriptionCollapsedByDefault = () => {
    setPlayerSettingsProperty(
      PRODUCT_DESCRIPTION_COLLAPSED_BY_DEFAULT,
      !productDescriptionCollapsedByDefault
    );
  };

  const onToggleDescriptionAlwaysShow = () => {
    setPlayerSettingsProperty(PRODUCT_DESCRIPTION_ALWAYS_SHOW, !productDescriptionAlwaysShow);
  };

  return (
    <LayoutRoot>
      <EditorHeader title="Description" />

      <EditorMainContainer>
        <EditorOnOffToggleItem
          text="Description collapsed by default"
          checked={productDescriptionCollapsedByDefault}
          onChange={onToggleDescriptionCollapsedByDefault}
          disabled={productDescriptionAlwaysShow}
        />
        <EditorOnOffToggleItem
          text="Always Show Full Description"
          checked={productDescriptionAlwaysShow}
          onChange={onToggleDescriptionAlwaysShow}
          disabled={productDescriptionCollapsedByDefault}
        />
      </EditorMainContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

export default ProductDescriptionEditor;
