import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { APPS_SELECT_ALL_STORES_KEY } from 'src/constants/ui.constants';
import { useAnalyticsActions } from 'src/context/AnalyticsStore';

import { useApps } from 'src/context/AppsStore';
import { useProductActions } from 'src/context/ProductsStore';
import { useVodConnection } from 'src/context/VodConnectionStore';
import { useVodConnectionSuggestion } from 'src/context/VodConnectionSuggestionStore';
import { useDashboardActions } from 'src/context/ui_store/DashboardStore';
import useIsCurrentPathName from 'src/hooks/useIsCurrentPathName';
import { getPrimaryApp, getShopifyApps } from 'src/utils/shopify.utils';

const useShopifyAppSelect = () => {
  const [
    { selectedAppUrl, apps, productsImport, previousSelectedApp, loading },
    { setSelectedApp },
  ] = useApps();
  const { clearProductsCount } = useProductActions();
  const { projectId } = useParams();
  const { setAppUrlInFilters } = useDashboardActions();
  const [{ initialized: isVodConnectionsInitialized }, { setSelectedAppUrl }] = useVodConnection();
  const [
    { isInitialized: isVodConnectionSuggestionInitialized },
    { setSelectAppUrl: setVodConnectionSuggestionAppUrl },
  ] = useVodConnectionSuggestion();
  const { setAppFilter } = useAnalyticsActions();
  const { isAnalyticsPath } = useIsCurrentPathName();

  const shopifyApps = getShopifyApps(apps);
  const primaryApp = getPrimaryApp(shopifyApps);
  const initialSelectedShopUrl = primaryApp?.appUrl || shopifyApps?.[0]?.appUrl;

  const handleSelectedAppChange = (shopUrl: string) => {
    if (!shopUrl) {
      return;
    }

    const app = setSelectedApp(shopUrl);

    if (shopUrl !== APPS_SELECT_ALL_STORES_KEY) {
      setAppUrlInFilters(shopUrl);
      setSelectedAppUrl(shopUrl);
      setVodConnectionSuggestionAppUrl(shopUrl);
    }

    clearProductsCount();

    if (isAnalyticsPath) {
      const domain = app ? JSON.parse(app.data)?.domain : APPS_SELECT_ALL_STORES_KEY;
      setAppFilter(shopUrl, domain);
    }
  };

  const getShouldHideAppSelect = () => {
    return !selectedAppUrl || shopifyApps.length === 1 || productsImport;
  };

  const isAppSelectDisabled = !!projectId;
  const shouldHideAppSelect = getShouldHideAppSelect();

  useEffect(() => {
    if (
      selectedAppUrl ||
      !shopifyApps.length ||
      isAnalyticsPath ||
      !isVodConnectionsInitialized ||
      !isVodConnectionSuggestionInitialized
    ) {
      return;
    }

    handleSelectedAppChange(initialSelectedShopUrl);
  }, [selectedAppUrl, initialSelectedShopUrl, shopifyApps, isVodConnectionsInitialized]);

  useEffect(() => {
    if (loading) {
      return;
    }

    if (isAnalyticsPath) {
      handleSelectedAppChange(APPS_SELECT_ALL_STORES_KEY);
      return;
    }

    if (selectedAppUrl === APPS_SELECT_ALL_STORES_KEY) {
      handleSelectedAppChange(previousSelectedApp?.appUrl || initialSelectedShopUrl);
    }
  }, [isAnalyticsPath, loading]);

  return {
    isAppSelectDisabled,
    handleSelectedAppChange,
    selectedAppUrl,
    shopifyApps,
    primaryApp,
    shouldHideAppSelect,
    previousSelectedApp,
    loading,
  };
};

export default useShopifyAppSelect;
