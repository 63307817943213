import styled from 'styled-components';
import FeedVideoHandle from 'shared/react/components/complex/feed/feed_overlay_mobile/FeedVideoHandle';

const FeedHandle = ({ step }) => {
  return (
    <LayoutRoot>
      <FeedVideoHandle step={step} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  position: absolute;
  bottom: 0;
  left: 10px;
  height: 36px;
`;

export default FeedHandle;
