import React from 'react';
import styled from 'styled-components';
import { TextSmall } from 'shared/react/components/complex/Text';
import IosSwitch from 'app/src/basic_components/IosSwitch';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { track } from 'app/src/helpers/Tracker';
import { useRulesContext } from 'app/src/pages/project/pages/rules_and_triggers/RulesContext';
import Tooltip from 'app/src/basic_components/Tooltip';

function ExitIntentToggle({ disabled, tooltipText }) {
  const { exitIntent, ruleGroup, setRuleGroup, setUnsaved } = useRulesContext();

  function handleExitIntentChanged(e) {
    const value = e.target.checked;
    track('Rules Exit Intent Clicked', { value });

    const newRuleGroup = {
      ...ruleGroup,
      exitIntent: value,
    };
    setRuleGroup(newRuleGroup);
    setUnsaved(true);
  }

  return (
    <LayoutRoot>
      <Tooltip placement="right" text={disabled ? tooltipText : ''}>
        <Gap8HorizontalFlex>
          <IosSwitch checked={exitIntent} onChange={handleExitIntentChanged} disabled={disabled} />
          <TextSmall>Exit intent</TextSmall>
        </Gap8HorizontalFlex>
      </Tooltip>
    </LayoutRoot>
  );
}

export default ExitIntentToggle;

const LayoutRoot = styled(Gap8HorizontalFlex)`
  align-items: center;
`;
