import React from 'react';
import styled from 'styled-components';
import { project as Project } from 'app/src/types/entities';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import InstallStepSimple from 'app/src/pages/project/pages/installation/components/shared/InstallStepSimple';
import CopyCode from 'app/src/complex_components/CopyCode';
import InstallationTitle from 'app/src/pages/project/pages/installation/components/shared/InstallationTitle';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import useAccountInstallation from 'src/hooks/useAccountInstallation';
import { useAppActions } from 'src/context/AppsStore';
import { PUBLISH_URL } from 'app/src/config/app.config';

type Props = {
  project: Project;
};
const PublishEmbed = ({ project }: Props) => {
  const { getAppUsingUrl } = useAppActions();
  const currentApp = getAppUsingUrl(project?.appUrl);
  const [{ isInstallationCompleted: isAccountInstallationCompleted }] = useAccountInstallation(
    currentApp?.id
  );
  const url = project ? `${PUBLISH_URL}/${project.publishId}` : '';

  const getHeight = () => {
    if (project?.verticalOrientation) {
      return '711px';
    }

    return '540px';
  };

  const getMaxWidth = () => {
    if (project?.verticalOrientation) {
      return '400px';
    }

    return '960px';
  };

  const getCode = () => {
    return `
      <div id="tolstoy-container" style="line-height:0;overflow:hidden;height:100%;width:100%;text-align:center">
        <iframe id="tolstoy" src="${url}?host"
                style="width:100%;height:${getHeight()};max-width:${getMaxWidth()}"
                scrolling="no" frameborder="0" allow="autoplay *; clipboard-write *;camera *; microphone *; encrypted-media *; fullscreen *; display-capture *;">
        </iframe>
        <script src="https://widget.gotolstoy.com/script.js" defer></script>
      </div>
    `;
  };
  const key = {
    stepHeader: 'Copy code and paste on an empty page',
    text: (
      <HorizontalFlex>
        <CustomCopyCode codeToCopy={getCode()} />
      </HorizontalFlex>
    ),
  };

  return (
    <Gap16VerticalFlex>
      <InstallationTitle />
      <InstallStepSimple isDisabled={!isAccountInstallationCompleted} {...key} startExpanded />
    </Gap16VerticalFlex>
  );
};

const CustomCopyCode = styled(CopyCode)`
  max-width: 480px;
  background: ${({ theme }) => theme.colors.neutralLightest};
`;

export default PublishEmbed;
