import React, { ReactNode } from 'react';
import ComposedChartEmptyState from './ComposedChartEmptyState';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import styled, { useTheme } from 'styled-components';
import { getShortMonthAndDate } from 'app/src/utils/date.utils';
import { type Margin } from 'recharts/src/util/types';
import {
  Bar,
  type BarProps,
  CartesianGrid,
  ComposedChart as RechartsComposedChart,
  Line,
  type LineProps,
  ResponsiveContainer,
  Tooltip,
  type TooltipProps,
  ReferenceLine,
  type ReferenceLineProps,
  XAxis,
  YAxis,
} from 'recharts';

type Props<T> = {
  data: T[];
  bars?: React.PropsWithoutRef<BarProps>[];
  lines?: React.PropsWithoutRef<LineProps>[];
  margin?: Margin;
  referenceLines?: React.PropsWithoutRef<ReferenceLineProps>[];
  renderCustomTooltip?: (props: TooltipProps<string | number | string[], string>) => ReactNode;
  shouldShowEmptyState: boolean;
  tooltipFormatter?: (value: T[keyof T], name: string, data: Record<string, unknown>) => string[];
  yTickFormatter: (value: T[keyof T], index: number) => string;
  yTickValueInterval?: number;
};

const ComposedChart = <T,>({
  data,
  bars = [],
  lines = [],
  margin = {},
  referenceLines = [],
  renderCustomTooltip,
  shouldShowEmptyState,
  tooltipFormatter,
  yTickFormatter,
  yTickValueInterval = 3,
}: Props<T>) => {
  const theme = useTheme();
  const getColor = (color: string) => theme.colors[color] || color;

  if (shouldShowEmptyState) {
    return (
      <AnalyticsOverviewBarChartEmptyStateContainer>
        <ComposedChartEmptyState />
      </AnalyticsOverviewBarChartEmptyStateContainer>
    );
  }

  const customYTickFormatter = (value: T[keyof T], index: number) => {
    if (index % yTickValueInterval === 0) {
      return yTickFormatter(value, index);
    }
    return '';
  };

  return (
    <LayoutRoot>
      <ResponsiveContainer width="100%" height="100%">
        <RechartsComposedChart
          data={data}
          margin={{
            top: 5,
            right: 5,
            left: -10,
            bottom: -10,
            ...margin,
          }}
        >
          <CartesianGrid vertical={false} strokeDasharray="4" />
          <XAxis
            axisLine={{ stroke: theme.colors.neutralGray, strokeWidth: 2 }}
            dataKey="x"
            tickFormatter={getShortMonthAndDate}
            tickLine={false}
          />
          <YAxis
            axisLine={{ stroke: theme.colors.neutralGray, strokeWidth: 2 }}
            tickCount={7}
            tickFormatter={customYTickFormatter}
            tickLine={false}
          />
          <Tooltip
            allowEscapeViewBox={{ y: true }}
            content={renderCustomTooltip}
            formatter={tooltipFormatter}
            labelFormatter={getShortMonthAndDate}
            wrapperStyle={{ outline: 'none', zIndex: 100 }}
          />
          {referenceLines.map(({ fill, stroke, ...rest }, idx) => (
            <ReferenceLine
              key={`reference-line-${idx}`}
              fill={getColor(fill)}
              stroke={getColor(stroke)}
              strokeWidth={1}
              {...rest}
            />
          ))}
          {bars.map(({ fill, stroke, ...rest }, idx) => (
            <Bar key={`bar-${idx}`} fill={getColor(fill)} stroke={getColor(stroke)} {...rest} />
          ))}
          {lines.map(({ fill, stroke, ...rest }, idx) => (
            <Line
              key={`line-${idx}`}
              dot={{ r: 2 }}
              fill={getColor(fill)}
              isAnimationActive={false}
              stroke={getColor(stroke)}
              strokeWidth={2}
              {...rest}
            />
          ))}
        </RechartsComposedChart>
      </ResponsiveContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  height: 238px;
  width: 100%;

  & .recharts-cartesian-axis-tick-value {
    color: ${({ theme }) => theme.colors.neutralDark};
    font-size: 12px;
    font-weight: 500;
  }

  & .recharts-tooltip-cursor {
    stroke: ${({ theme }) => theme.colors.neutralBlack};
  }
`;

const AnalyticsOverviewBarChartEmptyStateContainer = styled(HorizontalFlex)`
  align-items: flex-end;
  overflow: hidden;

  & svg {
    width: 100%;
  }
`;

export default ComposedChart;
