import React from 'react';

const MondayIcon = props => (
  <svg
    width={24}
    height={16}
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.985 15.13a3.006 3.006 0 0 1-2.611-1.51 2.876 2.876 0 0 1 .082-2.97l5.378-8.412A2.994 2.994 0 0 1 8.49.801a3.003 3.003 0 0 1 2.57 1.578c.501.944.439 2.08-.162 2.966l-5.375 8.412a2.994 2.994 0 0 1-2.537 1.373Z"
      fill="#F62B54"
    />
    <path
      d="M12.21 15.13a2.99 2.99 0 0 1-2.607-1.506 2.867 2.867 0 0 1 .082-2.963l5.369-8.392A2.989 2.989 0 0 1 17.71.8a2.992 2.992 0 0 1 2.587 1.587 2.867 2.867 0 0 1-.194 2.98l-5.367 8.392a2.989 2.989 0 0 1-2.527 1.37Z"
      fill="#FC0"
    />
    <path
      d="M21.23 15.2c1.53 0 2.769-1.209 2.769-2.7 0-1.49-1.24-2.7-2.769-2.7-1.53 0-2.77 1.21-2.77 2.7 0 1.491 1.24 2.7 2.77 2.7Z"
      fill="#00CA72"
    />
  </svg>
);

export default MondayIcon;
