import React from 'react';
import ProductTile from 'src/basic_components/ProductTile';
import { SearchProduct } from 'src/types/common';
import styled from 'styled-components';
import OverlaySpinner from '../OverlaySpinner';
import VerticalVideoCardAddVideo from './VerticalVideoCardAddVideo';

type Props = {
  onClick: () => void;
  product: SearchProduct;
  isLoading?: boolean;
};

const ProductVideoTileEmptyState = ({ onClick, product, isLoading }: Props) => {
  return (
    <OverlaySpinner isLoading={isLoading} shouldApplyLoadingOpacity>
      <VerticalVideoCardAddVideo onClick={onClick}>
        <StyledProductTile imageUrl={product.imageUrl} title={product.title} />
      </VerticalVideoCardAddVideo>
    </OverlaySpinner>
  );
};

const StyledProductTile = styled(ProductTile)`
  position: absolute;
  bottom: 8px;
  left: 8px;
  width: calc(100% - 16px);
  z-index: 1;
`;

export default ProductVideoTileEmptyState;
