import React from 'react';

const ThinPlusIcon = props => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.875 7.5a.57.57 0 0 0-.563-.563h-3.75v-3.75A.57.57 0 0 0 7 2.625a.555.555 0 0 0-.563.563v3.75h-3.75a.555.555 0 0 0-.562.562c0 .328.234.563.563.563h3.75v3.75a.54.54 0 0 0 .562.562.555.555 0 0 0 .563-.563v-3.75h3.75a.555.555 0 0 0 .562-.562Z"
      fill="#50545E"
    />
  </svg>
);

export default ThinPlusIcon;
