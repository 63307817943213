import React from 'react';

const BlackTikTokIcon = props => (
  <svg
    width={44}
    height={37}
    viewBox="0 0 44 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M42.2597 22.532L29.1672 0H14.8333L27.9268 22.532H42.2597ZM13.5917 2.13666L0.5 24.6667L7.66715 37L20.7587 14.4695L13.5917 2.13666ZM17.3171 24.6666L10.1509 37H36.3333L43.5 24.6667L17.3171 24.6666Z"
      fill="#2F3137"
    />
  </svg>
);
export default BlackTikTokIcon;
