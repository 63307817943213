import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useVideos } from 'app/src/context/VideosStore';
import WindowActionBarButtons from 'app/src/complex_components/WindowActionBarButtons';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { TextBody } from 'shared/react/components/complex/Text';
import { WIDGET_HEADER_LOCATIONS } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import useDragVideoPosition from 'app/src/pages/project/pages/look_and_feel/preview/widget_preview/useDragVideoPosition';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { FEATURE_WIDGET_HEADER_CUSTOMIZATION } from 'app/src/constants/appFeatures.constants';
import { useFeatures } from 'app/src/context/FeaturesStore';

function WidgetPreview() {
  const {
    setWidgetSettingsProperty,
    isEditingBubblePosition,
    isSaving,
    customizationSettings: {
      widgetPosition,
      verticalOrientation,
      widgetHeader,
      widgetShape,
      widgetSize,
      widgetNotificationBadge,
      widgetSettings: {
        themeColor,
        themeTextColor,
        bubbleTextEnabled,
        bubbleText,
        border,
        widgetHeaderLocation,
        bubbleObjectPosition,
        bubbleMarginBottom,
        bubbleMarginSide,
      },
    },
    project,
  } = useLookAndFeelContext();

  const isVertical = !!verticalOrientation;
  const centeredContent = false;
  const videoRef = useRef();
  const containerRef = useRef();
  const bubbleRef = useRef();
  const [videoUrl, setVideoUrl] = useState('');
  const [containVideo, setContainVideo] = useState(false);
  const [videos, { getVideoUrl }] = useVideos();
  const firstStep = project.steps.items.find(step => step.name === project.stepsOrder[0]) || {};
  const [{ video }, { handleVideoLoadError }] = useVideos(firstStep.videoId);

  const setObjectPosition = value => {
    setWidgetSettingsProperty('bubbleObjectPosition', value);
  };
  const [, { getSettingsByKey }] = useFeatures();
  const widgetTextFontSize = getSettingsByKey(FEATURE_WIDGET_HEADER_CUSTOMIZATION)?.fontSize;

  useDragVideoPosition({
    objectPosition: bubbleObjectPosition,
    setObjectPosition,
    videoRef,
  });

  function getVerticalPosition() {
    switch (widgetPosition) {
      case 'bottomRight':
        return 'flex-end';
      case 'bottomLeft':
        return 'flex-end';
      default:
        return 'flex-end';
    }
  }

  function getHorizontalPosition() {
    switch (widgetPosition) {
      case 'bottomRight':
        return 'flex-end';
      case 'bottomLeft':
        return 'flex-start';
      default:
        return 'flex-end';
    }
  }

  const getCircleSize = () => {
    switch (widgetSize) {
      default:
      case 's':
        return '75';
      case 'm':
        return '150';
      case 'l':
        return '200';
    }
  };

  function getBadgeMarginTopRight() {
    if (widgetShape !== 'circle') {
      return 0;
    }

    switch (widgetSize) {
      default:
      case 's':
        return '16px';
      case 'm':
        return '24px';
      case 'l':
        return '32px';
    }
  }

  function getFooterColumn() {
    return (
      <Gap8VerticalFlex>
        <FooterGrayLine1 />
        <FooterGrayLine2 />
        <FooterGrayLine2 />
        <FooterGrayLine3 />
      </Gap8VerticalFlex>
    );
  }

  const getLocation = () => {
    const locations = {
      [WIDGET_HEADER_LOCATIONS.top]: 'start',
      [WIDGET_HEADER_LOCATIONS.center]: 'center',
      [WIDGET_HEADER_LOCATIONS.bottom]: 'end',
    };

    return locations[widgetHeaderLocation] || 'center';
  };

  useEffect(() => {
    if (!project) {
      return;
    }

    const firstStep = project.steps.items.find(step => step.name === project.stepsOrder[0]);
    if (!firstStep) {
      return;
    }

    const video = videos.data.find(v => v.id === firstStep.videoId);
    setVideoUrl(getVideoUrl(video));
    setContainVideo(firstStep.videoContain);
  }, [project, videos]);

  const horizontalPosition = centeredContent ? 'center' : getHorizontalPosition();

  const setBorderRadius = () => {
    if (widgetSize !== 's' || widgetShape !== 'circle') {
      return '12px';
    }

    switch (widgetPosition) {
      case 'bottomRight':
        return '12px 12px 0 12px';
      case 'bottomLeft':
        return '12px 12px 12px 0';
      default:
        return '12px';
    }
  };

  const getWidgetContainerFlexDirection = () => {
    const isSmallCircle = widgetShape === 'circle' && widgetSize === 's';
    if (!isSmallCircle) {
      return 'column';
    }

    if (widgetPosition === 'bottomLeft') {
      return 'row-reverse';
    }

    return 'row';
  };

  const isSmallCircle = widgetShape === 'circle' && widgetSize === 's';

  const getVideoMargin = () => {
    let side = bubbleMarginSide ?? 35;
    let bottom = bubbleMarginBottom ?? 30;
    const container = containerRef.current;

    if (bottom + bubbleRef.current?.clientHeight >= container?.clientHeight) {
      bottom = container?.clientHeight - bubbleRef.current?.clientHeight - 10;
    }

    if (side + bubbleRef.current?.clientWidth >= container?.clientWidth) {
      side = container?.clientWidth - bubbleRef.current?.clientWidth - 10;
    }

    switch (widgetPosition) {
      case 'bottomRight':
        return `right: ${side}px; bottom:${bottom}px`;
      case 'bottomLeft':
        return `bottom: ${bottom}px;left: ${side}px`;
      default:
        return '8px';
    }
  };

  const forceReRender = () => {
    setContainVideo(!containVideo);
    setContainVideo(!containVideo);
  };

  useEffect(() => {
    forceReRender();
  }, [bubbleMarginSide, bubbleMarginBottom, widgetShape, widgetSize]);

  return (
    <LayoutRoot>
      <CardContainer ref={containerRef} disabled>
        <TopBar>
          <DotsContainer />
          <HeaderGrayLine />
        </TopBar>
        <ContentGrayLine />
        <Footer>
          {getFooterColumn()}
          {getFooterColumn()}
          {getFooterColumn()}
        </Footer>
        <OverlayItemsContainer
          verticalPosition={centeredContent ? 'center' : getVerticalPosition()}
          position={getVideoMargin()}
          ref={bubbleRef}
        >
          <WidgetContainer
            horizontalPosition={horizontalPosition}
            flexDirection={getWidgetContainerFlexDirection()}
            circleSize={getCircleSize()}
            isSmallCircle={isSmallCircle}
          >
            {bubbleTextEnabled && bubbleText && (
              <BubbleTextContainer
                background={themeColor}
                borderRadius={setBorderRadius()}
                isSmallCircle={isSmallCircle}
              >
                <BubbleText color={themeTextColor}>{bubbleText}</BubbleText>
              </BubbleTextContainer>
            )}
            <BubbleContainer shape={widgetShape} borderColor={border && themeColor}>
              {widgetNotificationBadge && (
                <Badge marginTopRight={getBadgeMarginTopRight()} shape={widgetShape}>
                  1
                </Badge>
              )}
              <VideoContainer
                shape={widgetShape}
                circleSize={getCircleSize()}
                videoContain={containVideo}
                isVertical={isVertical}
              >
                <WidgetTextContainer
                  circleSize={getCircleSize()}
                  shape={widgetShape}
                  widgetHeader={widgetHeader}
                  location={getLocation()}
                >
                  <WidgetText
                    circleSize={widgetSize}
                    shape={widgetShape}
                    widgetTextFontSize={widgetTextFontSize}
                  >
                    {widgetHeader}
                  </WidgetText>
                </WidgetTextContainer>
                <Video
                  shape={widgetShape}
                  circleSize={getCircleSize()}
                  src={videoUrl}
                  onError={() => handleVideoLoadError(video, videoUrl, setVideoUrl)}
                  isVertical={isVertical}
                  autoPlay
                  muted
                  ref={videoRef}
                  loop
                  autoplay
                  isEditing={isEditingBubblePosition && !isSaving}
                  objectPosition={`${bubbleObjectPosition.x}% ${bubbleObjectPosition.y}%`}
                  playsInline
                  allowFullScreen={false}
                  disablePictureInPicture={true}
                  controls={false}
                  videoContain={containVideo}
                />
              </VideoContainer>
            </BubbleContainer>
          </WidgetContainer>
        </OverlayItemsContainer>
        {!!isEditingBubblePosition && (
          <Background>
            <BackgroundMessage>Drag to adjust video alignment</BackgroundMessage>
          </Background>
        )}
      </CardContainer>
    </LayoutRoot>
  );
}

export default WidgetPreview;

const LayoutRoot = styled.div`
  display: grid;
  height: 520px;
  width: 100%;
  max-width: 791px;
  gap: 8px;
  overflow: hidden;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 100%;
  }
`;

const BubbleContainer = styled.div`
  position: relative;
  ${({ borderColor }) => (borderColor ? `border: 4px solid ${borderColor}` : '')};
  border-radius: ${({ shape }) => (shape === 'circle' ? '100%' : '14px')};
`;

const CardContainer = styled.div`
  padding: 16px;
  height: 100%;
  width: 100%;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 2px 10px rgba(50, 50, 93, 0.1), 0 1px 3px -1px rgba(0, 0, 0, 0.05);
`;

const Background = styled(HorizontalFlex)`
  position: absolute;
  grid-row: 1;
  grid-column: 1;
  padding: 16px;
  height: 100%;
  width: 100%;
  pointer-events: none;
  z-index: 1;
  background: ${({ theme }) => theme.getHexOpacity(theme.colors.black, 60)};
  border-radius: 16px;
  justify-content: center;
  align-items: center;
`;

const BackgroundMessage = styled(TextBody)`
  color: ${({ theme }) => theme.colors.white};
`;

const GrayLine = styled.div`
  background: ${({ theme }) => theme.colors.gray5};
  border-radius: 8px;
`;

const TopBar = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 8px;
`;

const HeaderGrayLine = styled(GrayLine)`
  height: 8px;
  width: 100%;
`;

const ContentGrayLine = styled(GrayLine)`
  height: 223px;
  margin-top: 24px;
`;

const Footer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 60px;
  margin-top: 24px;
`;

const FooterGrayLine1 = styled(GrayLine)`
  width: 70px;
  height: 42px;
  border-radius: 100px;
  margin-bottom: 24px;
`;

const FooterGrayLine2 = styled(GrayLine)`
  width: 100%;
  height: 16px;
`;

const FooterGrayLine3 = styled(FooterGrayLine2)`
  width: 118px;
`;

const DotsContainer = styled(WindowActionBarButtons)`
  gap: 3px;
  margin-bottom: 0;
`;

const OverlayItemsContainer = styled.div`
  position: absolute;
  pointer-events: none;
  align-self: ${({ verticalPosition }) => (!verticalPosition ? 'center' : verticalPosition)};
  z-index: 2;
  ${({ position }) => position}
`;

const Video = styled.video`
  background-color: black;
  object-fit: cover;
  height: 100%;
  width: 100%;
  object-position: ${({ objectPosition }) => objectPosition};
  border-radius: 3px;
  pointer-events: ${({ isEditing }) => isEditing && 'all'};
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 100px;
    height: 100px;
    border-radius: 100px;
  }
`;

const WidgetTextContainer = styled.div`
  display: grid;
  z-index: 1;
  background: ${({ widgetHeader }) =>
    widgetHeader === '' || widgetHeader === null
      ? 'rgba(0,0,0,0)'
      : 'linear-gradient(180deg, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.3) 100%)'};
  justify-content: center;
  align-self: center;
  align-items: ${({ location }) => location || 'center'};
  text-align: center;
  height: 100%;
  width: 100%;
  position: absolute;
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    border-radius: 100px;
    width: 100px;
    height: 100px;
    overflow: hidden;
    font-size: 13px;
  }
`;

const WidgetText = styled(TextBody)`
  z-index: 2147483651;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  color: white;
  overflow-wrap: anywhere;
  font-size: ${({ widgetTextFontSize }) => widgetTextFontSize}px;
`;

const Badge = styled.div`
  background: ${({ theme }) => theme.colors.notificationBadge};
  position: absolute;
  width: 22px;
  height: 22px;
  font-size: 9px;
  z-index: 1;
  right: 0;
  display: flex;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  justify-self: end;
  color: white;
  border-radius: 100%;
  margin-top: ${({ marginTopRight }) => marginTopRight};
  margin-right: ${({ marginTopRight }) => marginTopRight};

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    top: 0;
    right: 0;
  }
`;

const VideoContainer = styled.div`
  max-width: 100%;
  position: relative;
  object-fit: cover;
  height: ${({ shape, circleSize, isVertical }) =>
    shape === 'circle' ? circleSize : isVertical ? '200' : '152'}px;
  width: ${({ shape, circleSize, isVertical }) =>
    shape === 'circle' ? circleSize : isVertical ? '150' : '242'}px;
  border-radius: ${({ shape, circleSize }) => (shape === 'circle' ? circleSize : '8')}px;
  overflow: hidden;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 100px;
    height: 100px;
    border-radius: 100px;
  }
`;

const WidgetContainer = styled.div`
  position: relative;
  display: flex;
  max-width: 100%;
  justify-self: ${props => (!props.horizontalPosition ? 'center' : props.horizontalPosition)};
  width: fit-content;
  height: fit-content;
  gap: 4px;
  flex-direction: ${({ flexDirection }) => flexDirection};
`;

const BubbleTextContainer = styled.div`
  padding: 3px 8px;
  background: ${({ background }) => background};
  opacity: 0.67;
  max-width: 95px;
  height: fit-content;
  align-self: ${({ isSmallCircle }) => (isSmallCircle ? 'flex-start' : 'center')};
  border-radius: ${({ borderRadius }) => borderRadius};
  box-shadow: 0px 6px 40px -2px rgba(50, 50, 93, 0.3), 0px 3px 10px -3px rgba(0, 0, 0, 0.1);
`;

const BubbleText = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: ${({ color }) => color};
  white-space: pre-line;
  overflow-wrap: break-word;
`;
