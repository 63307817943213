import React from 'react';
import { Filter } from 'lucide-react';
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuGroup,
  DropdownMenuLabel,
  DropdownMenuCheckboxItem,
} from 'ui-library/core';
import { Filters } from '../../../types';
import { updateFilters } from '../../../utils';

type SortProps = {
  filters: Filters;
  onChange: (newFilters: Filters) => void;
};

export const UgcFilter = ({ filters, onChange }: SortProps) => {
  const selectedCount = Object.values(filters)
    .flat()
    .filter(filter => filter.isSelected).length;
  const btnText = selectedCount ? `Filter (${selectedCount})` : 'Filter';

  const handleFilterChange = (group: string, value: string) => {
    const newFilters = updateFilters(filters, group, value);

    onChange(newFilters);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="sm">
          <Filter className="mr-2 h-4 w-4" />
          {btnText}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {Object.entries(filters).map(([group, groupFilters]) => (
          <React.Fragment key={group}>
            <DropdownMenuGroup>
              <DropdownMenuLabel>{group}</DropdownMenuLabel>
              {groupFilters.map(filter => (
                <DropdownMenuCheckboxItem
                  key={filter.value}
                  checked={filter.isSelected}
                  onCheckedChange={() => handleFilterChange(group, filter.value)}
                  onSelect={e => e.preventDefault()}
                >
                  {filter.label}
                </DropdownMenuCheckboxItem>
              ))}
            </DropdownMenuGroup>
          </React.Fragment>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default UgcFilter;
