import React from 'react';
import Tooltip from 'app/src/basic_components/Tooltip';
import { PrimaryButton } from 'shared/react/components/basic/button-v2/BasicButton';

type Props = {
  openInviteButtonClick: () => void;
  inviteDisabled: boolean;
};

const tooltipUndefinedProps = {
  header: undefined,
  hidden: undefined,
  tooltipImage: undefined,
  containerWidth: undefined,
};

const TeamMembersHeaderButtons = ({ openInviteButtonClick, inviteDisabled }: Props) => {
  const tooltipText = inviteDisabled ? 'Team members limit reached' : '';

  return (
    <Tooltip text={tooltipText} placement="left" {...tooltipUndefinedProps}>
      <PrimaryButton onClick={openInviteButtonClick} disabled={inviteDisabled}>
        Invite Member
      </PrimaryButton>
    </Tooltip>
  );
};

export default TeamMembersHeaderButtons;
