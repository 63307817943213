import React from 'react';

const StoriesSettingsIcon = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path stroke="#000" stroke-width="1.5" d="M17.75 14.75h12.5v18.5h-12.5z" />
      <path stroke="#000" d="M33.5 16.5h1v15h-1zm-20 0h1v15h-1z" />
    </svg>
  );
};

export default StoriesSettingsIcon;
