import React from 'react';
import styled from 'styled-components';
import ThinPlusIcon from 'shared/react/images/ThinPlusIcon';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import {
  FEED_QUICK_ADD_BUTTON_CONTENT_DATA_TYPE,
  FEED_QUICK_ADD_BUTTON_PLUS_ICON_DATA_TYPE,
} from 'shared/react/constants/feedPreviewDataTypes.constants';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';

const FeedQuickAddButtonContent = props => {
  const [, { t }] = useTranslation('feed.buyNowButton');

  return (
    <LayoutRoot data-type={FEED_QUICK_ADD_BUTTON_CONTENT_DATA_TYPE}>
      <ThinPlusIcon data-type={FEED_QUICK_ADD_BUTTON_PLUS_ICON_DATA_TYPE} /> {t('quickAdd')}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlexWrap)`
  align-items: center;
  flex-wrap: nowrap;
`;

export default FeedQuickAddButtonContent;
