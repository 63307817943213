import { PLAYER_EVENT_NAMES, PLAYER_EVENTS } from 'player-events';
import { WIDGET_MESSAGES_EVENTS } from '../constants/messages.constants.js';
import { PAGE_VISIT_EVENT_NAME, SITE_ACTIVITY_EVENT_NAME } from '../constants/widgetConstants.js';
import { EVENT_NAMES } from '../constants/events.constants.js';
import { uppercaseFirstChar } from '../utils/utils.js';
import { validateUUID } from '../utils/validator.utils.js';
import { getIsActiveWidgetEvent, getIsInactiveWidgetEvent } from '../utils/event.utils.js';
import { getCurrentPageProductId } from '../utils/script.utils.js';
import { createPageVisitEvent, createSiteActivityEvent } from './requests.js';
import {
  clearTolstoyActivity,
  getAndSetAnonymousId,
  getNotVisibleOpenedTolstoyPublishId,
  getSessionId,
  getSessionUniqueId,
  getTolstoysActivity,
  pushTolstoyActivity,
  setNotVisibileOpenedTolstoyPublishId
} from './cache.js';
import { getAppKey } from './appKey.js';

const ONE_DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;

const getTolstoyEventName = eventName => {
  return `tolstoy${uppercaseFirstChar(eventName)}`;
};

export const sendAnalyticsToExternalSource = event => {
  sendGoogleAnalyticsEvent(event);
  sendFacebookAnalyticsEvent(event);
};

export const sendFacebookAnalyticsEvent = event => {
  const {
    data: { name: eventName, text: value, facebookAnalyticsID: faId, playlist, collectInfoType }
  } = event;
  if (!window.fbq || !faId || !playlist) {
    return;
  }

  const category = `tolstoy-${playlist}`;

  switch (eventName) {
    case PLAYER_EVENT_NAMES.sessionStart:
      sendCustomFacebookEvent(faId, `${category}-click`, { value: 'Start Tolstoy' });
      break;
    case PLAYER_EVENT_NAMES.clickCta:
      sendCustomFacebookEvent(faId, `${category}-click`, { value });
      break;
    case PLAYER_EVENT_NAMES.submitInput:
      sendCustomFacebookEvent(faId, `${category}-input`, { value });
      break;
    case PLAYER_EVENT_NAMES.collectInfo:
      sendCustomFacebookEvent(faId, `${category}-${PLAYER_EVENTS.collectInfo}`, {
        value: collectInfoType
      });
      break;
    case PLAYER_EVENT_NAMES.videoResponse:
      sendCustomFacebookEvent(faId, `${category}-${PLAYER_EVENTS.videoResponse}`);
      break;
    case PLAYER_EVENT_NAMES.imageResponse:
      sendCustomFacebookEvent(faId, `${category}-${PLAYER_EVENTS.imageResponse}`);
      break;
    case PLAYER_EVENT_NAMES.audioResponse:
      sendCustomFacebookEvent(faId, `${category}-${PLAYER_EVENTS.audioResponse}`);
      break;
    case PLAYER_EVENTS.sessionEnd:
      sendCustomFacebookEvent(faId, `${category}-${PLAYER_EVENTS.sessionEnd}`);
      break;
    // No default
  }
};

const sendCustomFacebookEvent = (faId, event, data) => {
  window.fbq('trackSingleCustom', faId, event, data);
};

/* eslint-disable complexity */
export const sendGoogleAnalyticsEvent = event => {
  const {
    data: {
      name: eventName,
      text: value,
      googleAnalyticsID: gaId,
      playlist,
      collectInfoType,
      productNames,
      totalTime,
      videoName,
      type,
      direction
    }
  } = event;

  if ((!window.gtag && !window.dataLayer && !window.ga) || !gaId || !playlist) {
    return;
  }

  const category = `tolstoy-${playlist}`;

  switch (eventName) {
    case EVENT_NAMES.pageView:
      sendCustomGoogleEvent(gaId, category, 'tolstoyPageView');
      break;
    case PLAYER_EVENTS.sessionStart:
    case PLAYER_EVENT_NAMES.sessionStart:
      sendCustomGoogleEvent(gaId, category, PLAYER_EVENT_NAMES.sessionStart);
      sendCustomGoogleEvent(gaId, category, 'click', 'Start Tolstoy');
      break;
    case PLAYER_EVENT_NAMES.clickCta:
      sendCustomGoogleEvent(gaId, category, eventName, value);
      break;
    case PLAYER_EVENT_NAMES.collectInfo:
      sendCustomGoogleEvent(gaId, category, eventName, collectInfoType);
      break;
    case PLAYER_EVENT_NAMES.submitInput:
    case PLAYER_EVENT_NAMES.imageResponse:
    case PLAYER_EVENT_NAMES.audioResponse:
    case PLAYER_EVENT_NAMES.videoResponse:
    case PLAYER_EVENT_NAMES.sessionEnd:
      sendCustomGoogleEvent(gaId, category, eventName);
      break;
    case EVENT_NAMES.tolstoyModalClose:
      sendCustomGoogleEvent(gaId, category, EVENT_NAMES.tolstoyModalClose, totalTime);
      break;
    case EVENT_NAMES.videoLoaded:
      if (productNames) {
        sendCustomGoogleEvent(gaId, category, `tolstoyVideoLoaded-products`, productNames);
      }

      sendCustomGoogleEvent(gaId, category, `tolstoyVideoLoaded-videoName`, videoName);
      break;
    case EVENT_NAMES.feedProductModalOpen:
    case EVENT_NAMES.feedProductModalClose:
    case EVENT_NAMES.clickViewProduct:
    case EVENT_NAMES.openProductPageClick:
      sendCustomGoogleEvent(gaId, category, getTolstoyEventName(eventName), productNames);
      break;
    case EVENT_NAMES.feedPlay:
    case EVENT_NAMES.feedPause:
    case EVENT_NAMES.videoMuted:
    case EVENT_NAMES.videoUnmuted:
    case EVENT_NAMES.videoWatched:
      sendCustomGoogleEvent(gaId, category, getTolstoyEventName(eventName), videoName);
      break;
    case EVENT_NAMES.openShareLink:
      sendCustomGoogleEvent(gaId, category, getTolstoyEventName('openShareLink'), type);
      break;
    case EVENT_NAMES.feedNavigationArrowClick:
    case EVENT_NAMES.feedScroll:
      sendCustomGoogleEvent(gaId, category, getTolstoyEventName(eventName), direction);
      break;
    default:
      sendCustomGoogleEvent(gaId, category, getTolstoyEventName(eventName), value);
  }
};
/* eslint-enable complexity */

const sendCustomGoogleEvent = (gaId, eventCategory, eventAction, eventLabel) => {
  if (!verifyUserConsent()) {
    return;
  }

  if (window.gtag) {
    window.gtag('event', eventAction, {
      event_category: eventCategory,
      event_label: eventLabel,
      send_to: gaId
    });
  } else if (window.dataLayer) {
    window.dataLayer.push({
      event: eventAction,
      event_category: eventCategory,
      event_label: eventLabel,
      send_to: gaId
    });
  }
};

const pagePublishIdsImpressions = {};

export const setImpressionByPublishId = (publishId, impression) => {
  pagePublishIdsImpressions[publishId] = pagePublishIdsImpressions[publishId] || impression;
};

let hasRegisteredSiteActivityListener = false;

export const registerSiteActivityListenerIfNeeded = () => {
  if (hasRegisteredSiteActivityListener) {
    return;
  }

  try {
    const appKey = getAppKey();

    registerSiteActivityListener({ appKey });

    hasRegisteredSiteActivityListener = true;
  } catch (error) {
    console.error(error);
    window.tolstoyCaptureError?.(error);
  }
};

const verifyUserConsent = () => {
  if (window.Shopify?.customerPrivacy?.shouldShowBanner()) {
    return window.Shopify?.customerPrivacy?.currentVisitorConsent().analytic === 'yes';
  }

  return true;
};

const registerSiteActivityListener = ({ appKey } = {}) => {
  let visibleSince = 0;
  let hasSentSiteActivityEvent = false;

  setNotVisibileOpenedTolstoyPublishId(null);

  if (!validateUUID(appKey)) {
    return;
  }

  const sendSiteActivityEvent = ({ now, performanceNow }) => {
    hasSentSiteActivityEvent = false;

    let tolstoyActivity = getTolstoysActivity();
    deactivateOpenedTolstoyForAnalytics(now, tolstoyActivity);
    tolstoyActivity = getTolstoysActivity();

    const timeOnPage = Math.round(performanceNow - visibleSince);
    const sessionUniqueId = getSessionUniqueId();
    const siteActivity = formatTolstoyActivity(tolstoyActivity);
    const pageUrl = window.location.href.split('?')[0];
    const currentPageProductId = getCurrentPageProductId();

    const eventParams = {
      appKey,
      sessionUniqueId,
      timestamp: new Date().toISOString(),
      anonymousId: getAndSetAnonymousId(),
      storeUrl: window.Shopify?.shop,
      siteActivity,
      eventName: SITE_ACTIVITY_EVENT_NAME,
      timeOnPage,
      pageUrl,
      currentPageProductId
    };

    createSiteActivityEvent(eventParams);
    hasSentSiteActivityEvent = true;

    clearTolstoyActivity();
  };

  document.addEventListener('visibilitychange', () => {
    try {
      const now = Date.now();
      const performanceNow = window.performance.now();
      if (document.visibilityState === 'visible') {
        visibleSince = performanceNow;
        hasSentSiteActivityEvent = false;
        reactivateOpenedTolstoyForAnalytics(now);
      }

      if (document.visibilityState === 'hidden' && !hasSentSiteActivityEvent) {
        sendSiteActivityEvent({ now, performanceNow });
      }
    } catch (error) {
      console.error(error);
      window.tolstoyCaptureError?.(error);
    }
  });

  window.addEventListener('beforeunload', () => {
    if (hasSentSiteActivityEvent) {
      return;
    }

    try {
      const now = Date.now();
      const performanceNow = window.performance.now();

      sendSiteActivityEvent({ now, performanceNow });
    } catch (error) {
      console.error(error);
      window.tolstoyCaptureError?.(error);
    }
  });
};

const formatTolstoyActivity = tolstoyActivity => {
  const formattedTolstoyActivity = {};
  for (const [publishId, impression] of Object.entries(pagePublishIdsImpressions)) {
    const activities = tolstoyActivity[publishId];
    if (!activities?.length && !formattedTolstoyActivity[publishId]) {
      formattedTolstoyActivity[publishId] = { activity: {}, totalTime: 0, impression };
    }

    const activity = getPublishIdActivity({ publishId, activities });

    let totalTime = 0;
    for (const time of Object.values(activity)) {
      totalTime += time;
    }

    formattedTolstoyActivity[publishId] = {
      activity,
      totalTime,
      impression
    };
  }

  return formattedTolstoyActivity;
};

const getPublishIdActivity = ({ publishId, activities }) => {
  if (!publishId || !activities?.length) {
    return {};
  }

  const now = Date.now();
  const sessionsLastOpened = {};
  const sessionsTotalDurations = {};

  for (const { timeStamp, type, sessionId } of activities) {
    sessionsTotalDurations[sessionId] = sessionsTotalDurations[sessionId] || 0;

    if (sessionsLastOpened[sessionId] === undefined) {
      sessionsLastOpened[sessionId] = false;
    }

    if (getIsActiveWidgetEvent(type) && !sessionsLastOpened[sessionId]) {
      sessionsLastOpened[sessionId] = true;
      sessionsTotalDurations[sessionId] -= timeStamp;
    }

    if (getIsInactiveWidgetEvent(type) && sessionsLastOpened[sessionId]) {
      sessionsLastOpened[sessionId] = false;
      sessionsTotalDurations[sessionId] += timeStamp;
    }
  }

  for (const [sessionId, lastOpened] of Object.entries(sessionsLastOpened)) {
    if (lastOpened) {
      sessionsTotalDurations[sessionId] += now;
    }

    if (
      sessionsTotalDurations[sessionId] <= 0 ||
      sessionsTotalDurations[sessionId] > ONE_DAY_IN_MILLISECONDS
    ) {
      delete sessionsTotalDurations[sessionId];
    }
  }

  return sessionsTotalDurations;
};

const getOpenedPublishId = tolstoyActivity => {
  return Object.entries(tolstoyActivity).find(([_publishId, activities]) => {
    if (activities.length === 0) {
      return;
    }

    const lastActivityType = activities[activities.length - 1].type;

    return getIsActiveWidgetEvent(lastActivityType);
  })?.[0];
};

const reactivateOpenedTolstoyForAnalytics = now => {
  const cachedOpenedPublishId = getNotVisibleOpenedTolstoyPublishId();
  const sessionId = getSessionId();

  if (!cachedOpenedPublishId || !sessionId) {
    return;
  }

  pushTolstoyActivity({
    publishId: cachedOpenedPublishId,
    timeStamp: now,
    type: WIDGET_MESSAGES_EVENTS.OPEN,
    sessionId
  });
  setNotVisibileOpenedTolstoyPublishId(null);
};

const deactivateOpenedTolstoyForAnalytics = (now, tolstoyActivity) => {
  const openedPublishId = getOpenedPublishId(tolstoyActivity);
  const sessionId = getSessionId();

  if (!openedPublishId || !sessionId) {
    return;
  }

  pushTolstoyActivity({
    publishId: openedPublishId,
    timeStamp: now,
    type: WIDGET_MESSAGES_EVENTS.CLOSE,
    sessionId
  });
  setNotVisibileOpenedTolstoyPublishId(openedPublishId);
};

export const sendPageVisitEvent = () => {
  const appKey = getAppKey();
  if (!appKey) {
    return;
  }

  const parentUrl = window.location.href.split('?')[0];
  const currentPageProductId = getCurrentPageProductId();

  const eventParams = {
    appKey,
    sessionUniqueId: getSessionUniqueId(),
    createdAt: new Date().toISOString(),
    anonymousId: getAndSetAnonymousId(),
    appUrl: window.Shopify?.shop,
    eventName: PAGE_VISIT_EVENT_NAME,
    parentUrl,
    currentPageProductId
  };

  createPageVisitEvent(eventParams);
};
