import CopyInput from 'app/src/complex_components/CopyInput';
import { useUser } from 'app/src/context/userStore/UserStore';
import { track } from 'app/src/helpers/Tracker';
import React, { useState } from 'react';
import styled from 'styled-components';
import Button from 'shared/react/components/complex/Button';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import Types from 'shared/react/theme/Types';
import { nanoid } from 'nanoid';

const ApiKey = () => {
  const [{ account }, { updateAccountSettings }] = useUser();
  const [isLoadingToken, setIsLoadingToken] = useState(false);

  const setUserToken = async () => {
    setIsLoadingToken(true);
    await updateAccountSettings({ token: nanoid(48) });
    setIsLoadingToken(false);
  };

  return (
    <LayoutRoot>
      <StyledCopyInput
        value={account.token || ''}
        onClick={() => {
          track('Copy User Token Click');
        }}
      />
      {!account?.token && (
        <Button loading={isLoadingToken} onClick={setUserToken} type={Types.Secondary}>
          Generate
        </Button>
      )}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8HorizontalFlexWrap)``;

const StyledCopyInput = styled(CopyInput)`
  width: 420px;
`;

export default ApiKey;
