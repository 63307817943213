import Utils from '../utils';
import APP_CONFIG from 'app/src/config/app.config';

const isMobile = Utils.isMobile();

const track = (eventName, params = {}) => {
  if (Utils.isGhostAccount()) {
    return;
  }

  if (!params) {
    params = {};
  }

  params.mobile = isMobile;

  if (APP_CONFIG.IS_DEV) {
    return;
  }

  window.analytics.track(eventName, params, { context: { groupId: Utils.getAppKey() } });
};

const identify = (userId, params) => {
  if (Utils.isGhostAccount()) {
    return;
  }

  if (APP_CONFIG.IS_DEV) {
    return;
  }

  window.analytics.identify(userId, params);
};

const group = (appKey, params) => {
  if (Utils.isGhostAccount()) {
    return;
  }

  if (APP_CONFIG.IS_DEV) {
    return;
  }

  window.analytics.group(appKey, params);
};

export { track, identify, group };
