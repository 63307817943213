import { createActionsHook, createContainer, createHook, createStore } from 'react-sweet-state';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { graphqlRequest, postRequest } from '../../helpers/API';
import { accountSettingsByAppKey, userSettingsByOwner } from '../../graphql/queries';
import { updateUserSettings } from '../../graphql/mutations';
import Utils from '../../utils';
import { onUpdateAccountSettingsByAppKey } from '../../graphql/subscriptions';
import { sendExtensionLogoutEvent } from '../../helpers/Extension';
import { GHOST_ACCOUNT_LOCAL_STORAGE_KEY } from 'app/src/constants/tolstoy.constants';
import queryString from 'query-string';
import { REDIRECT_PATH_SESSION_STORAGE_KEY } from 'app/src/constants/ui.constants';
import { track } from '../../helpers/Tracker';
import {
  clearAmplifyLocalStorage,
  clearGhostAccountIfNeeded,
  clearLocalStorage,
  getErrorMessage,
  getSignupPasswordError,
  LOGIN_ERROR,
  monthPastUnixTime,
  RESET_PASSWORD_ERROR,
} from './userStoreService';
import Routes from '../../helpers/Routes';
import { SIGNUP_REGEX } from 'shared/react/constants/signup.constants';
import { getSearchParamsPath } from 'app/src/utils/url.utils';
import { broadcastReloadMessage } from 'app/src/utils/broadcast.utils';
import APP_CONFIG from 'app/src/config/app.config';

const initialState = {
  user: null,
  storageUser: Utils.getUser(),
  account: null,
  isAuthenticated: null,
  subscription: null,
  loading: false,
  error: null,
  firstLogin: false,
  packageId: '',
};

const USER_ALREADY_EXISTS_CODE = 'UsernameExistsException';
const TWO_MINUTES = 2 * 60 * 1000;
const authRoutes = [...Routes.getAuthRoutes(), '/'];

const actions = {
  resetPasswordSubmit:
    (username, code, new_password, setIsLoading, setError, setSuccessBar) => async () => {
      setIsLoading(true);
      try {
        await Auth.forgotPasswordSubmit(username, code, new_password);
        setSuccessBar(true);
      } catch (error) {
        console.error('error reset password:', error);
        setError(getSignupPasswordError(error?.message));
      }

      setIsLoading(false);
    },
  resetPassword: (email, setIsLoading, setError, setForgotModalOpen, setSuccessBar) => async () => {
    setIsLoading(true);
    try {
      await Auth.forgotPassword(email, { app: APP_CONFIG.APP_TYPE });
      setSuccessBar(true);
    } catch (error) {
      console.error('error reset password:', error);
      setError(RESET_PASSWORD_ERROR);
    }

    setForgotModalOpen(false);
    setIsLoading(false);
  },
  refreshSession:
    attempt =>
    async ({ dispatch }) => {
      const shouldNotShow = authRoutes;
      if (shouldNotShow.includes(location.pathname)) {
        return;
      }

      try {
        const auth = await Auth.currentSession();

        const authenticatedAt = auth.accessToken.payload.auth_time;
        if (monthPastUnixTime(authenticatedAt)) {
          console.log('session timeout');
          dispatch(actions.logout());
        }
      } catch (err) {
        console.log(err);

        attempt = attempt || 0;
        if (attempt > 3 || err === 'No current user') {
          dispatch(actions.logout());
          return;
        }

        setTimeout(() => dispatch(actions.refreshSession(dispatch, attempt + 1), 200));
      }
    },
  googleLogin:
    ({ isSignUp, ...params }) =>
    () => {
      track(`Google ${isSignUp ? 'Signup' : 'Login'} Click`);
      const url = `${APP_CONFIG.API_URL}/auth/google/login`;

      return window.open(`${url}?${getSearchParamsPath(params)}`, '_self');
    },
  signUpUser:
    ({ email, password, setIsLoading, setError, inviteToken, utmSource }) =>
    async ({ dispatch }) => {
      setError('');
      setIsLoading(true);
      try {
        const errorMessage = checkPassword(password);
        if (errorMessage) {
          console.log(errorMessage);
          setError(errorMessage);
          setIsLoading(false);
          return;
        }

        const user = await Auth.signUp({
          username: email,
          password,
          clientMetadata: {
            inviteToken,
            utmSource,
          },
        });
        if (user.userConfirmed) {
          if (inviteToken) {
            track('Team member invite accepted', {
              location: 'signup',
            });
          }

          Utils.clearInvite();
          Utils.clearQueryStrings();

          await dispatch(actions.loginUser(email, password, setIsLoading, setError, true));
        }
      } catch (error) {
        console.error('error signing up:', error);
        if (!error?.code?.includes(USER_ALREADY_EXISTS_CODE)) {
          Utils.logError('signup error', error);
        }

        const errorMessage = getErrorMessage(error);
        setIsLoading(false);
        setError(errorMessage);
        return error;
      }
    },
  loginUser:
    (login, password, setIsLoading, setError, firstLogin) =>
    async ({ setState, dispatch }) => {
      setError('');
      setIsLoading(true);
      try {
        clearAmplifyLocalStorage();
        const user = await Auth.signIn(login, password);
        const userId = user.username;
        const accountOwner = user?.attributes?.['custom:account_owner'];
        const savedUser = { id: userId, email: login, accountOwner };

        localStorage.setItem('user', JSON.stringify(savedUser));
        localStorage.removeItem(GHOST_ACCOUNT_LOCAL_STORAGE_KEY);

        setError('');
        setIsLoading(false);
        startInterval(dispatch);

        broadcastReloadMessage();

        setState({ firstLogin, isAuthenticated: true, storageUser: savedUser });
      } catch (error) {
        console.error('error signing in', error);
        setError(LOGIN_ERROR);
        setIsLoading(false);
      }
    },
  clearUserDataFromCookies: () => async () => {
    Utils.clearCookie('tolstoyAccessToken');
    Utils.clearCookie('tolstoyAppKey');
    Utils.clearCookie('tolstoyUserId');
  },
  getCurrentUser:
    pathname =>
    async ({ dispatch, setState }) => {
      const params = queryString.parse(window.location.search);
      const redirectUri = params.redirectUri;
      delete params.redirectUri;
      if (redirectUri) {
        localStorage.setItem('redirect-uri', redirectUri);
      }

      if (params.userId && params.auth) {
        Utils.setCustomLoginLoading();
        try {
          await dispatch(actions.customAuthSignIn(params.userId, params.auth));
          if (pathname && pathname !== '/') {
            sessionStorage.setItem(REDIRECT_PATH_SESSION_STORAGE_KEY, pathname);
          }
        } catch (error) {
          console.log(error);
        }

        Utils.clearCustomLoginLoading();
      }

      try {
        const userData = await Auth.currentAuthenticatedUser();

        const userId = userData.username;
        const accountOwner = userData?.attributes?.['custom:account_owner'];

        const { email } = userData.attributes;
        localStorage.setItem(
          'user',
          JSON.stringify({
            id: userId,
            email,
            source: params.app,
            accountOwner,
          })
        );
        track('Signin Successful', { email });
        startInterval(dispatch);
        setState({
          isAuthenticated: true,
          storageUser: { id: userId, email, source: params.app, accountOwner },
        });
        return userData;
      } catch (error) {
        console.log('Not signed in ', error);
        if (!authRoutes.includes(pathname)) {
          sessionStorage.setItem(REDIRECT_PATH_SESSION_STORAGE_KEY, pathname);
        }

        clearLocalStorage();
        sendExtensionLogoutEvent();
        dispatch(actions.logout());
        return null;
      }
    },
  customAuthSignIn: (userId, auth) => async () => {
    if (!userId || !auth) {
      return;
    }

    clearLocalStorage();

    const user = await Auth.signIn(userId);
    if (user.challengeName === 'CUSTOM_CHALLENGE' && user.challengeParam.distraction === 'Yes') {
      const response = await Auth.sendCustomChallengeAnswer(user, auth);
      console.log(response);
    }
  },
  fetch:
    () =>
    async ({ getState, setState }) => {
      try {
        const user = (await Auth.currentAuthenticatedUser()).username;
        if (getState().loading || !user) return { user: null, account: null };
        setState({ loading: true });

        const response = await graphqlRequest(
          graphqlOperation(userSettingsByOwner, { owner: user })
        );
        const listedUser = response.data.userSettingsByOwner.items[0];
        if (!listedUser) {
          await Auth.signOut();
          return { user: null, account: null };
        }

        if (!Utils.isGhostAccount()) {
          graphqlRequest(
            graphqlOperation(updateUserSettings, {
              input: {
                id: listedUser.id,
                lastLogin: new Date().toISOString(),
              },
            })
          );
        }

        setState({ user: listedUser, loading: false });
        return { user: listedUser };
      } catch (error) {
        console.error(error);
        setState({ error, loading: false });
      }
    },
  fetchAccount:
    appKey =>
    async ({ setState }) => {
      const accountResponse = await graphqlRequest(
        graphqlOperation(accountSettingsByAppKey, { appKey })
      );

      const account = accountResponse.data.accountSettingsByAppKey.items[0] || {};

      setState({ account, packageId: account.packageId });

      return account;
    },
  updateUser:
    input =>
    async ({ setState }) => {
      setState({ loading: true });
      try {
        delete input.createdAt;
        delete input.updatedAt;
        const response = await graphqlRequest(graphqlOperation(updateUserSettings, { input }));
        const user = response.data.updateUserSettings;

        setState({ user, loading: false });
      } catch (error) {
        console.error(error);
        setState({ error, loading: false });
        return error;
      }
    },
  getUser:
    () =>
    ({ getState }) => {
      let user = getState().user;
      if (user) {
        return user;
      }

      return Utils.getUser();
    },
  updateAccountSettings:
    (data, dontWaitForLoading = false) =>
    async ({ getState, setState, dispatch }) => {
      let { account, loading } = getState();
      const { appKey } = data;

      const isWaitingForLoading = loading && !dontWaitForLoading;
      if (isWaitingForLoading) {
        return;
      }

      if (!account) {
        account = await dispatch(actions.fetchAccount(appKey));
      }

      const body = {
        appKey: account.appKey,
        ...data,
      };

      setState({ loading: true });
      try {
        const res = await postRequest('account-actions', '/actions/accounts', {
          body,
        });

        setState({ loading: dontWaitForLoading, account: { ...account, ...data } });
        return res;
      } catch (error) {
        setState({ error, loading: false });
        return error;
      }
    },
  toggleWidgetIsLive:
    (project, newState) =>
    async ({ getState, setState, dispatch }) => {
      setState({ loading: true });

      let { account } = getState();

      if (!account) {
        account = await dispatch(actions.fetchAccount(project.appKey));
      }

      let liveIds = account.liveIds || [];

      if (Utils.isNullOrUndefined(newState)) {
        newState = !liveIds.includes(project.publishId);
      }

      if (newState) {
        liveIds.push(project.publishId);
        track('Click Set Live', { projectId: project.id, publishId: project.publishId });
      } else {
        liveIds = liveIds.filter(id => id !== project.publishId);
        track('Click Set Not Live', { projectId: project.id, publishId: project.publishId });
      }

      const body = {
        appKey: account.appKey,
        liveIds: [...new Set(liveIds)],
      };

      try {
        const res = await postRequest('account-actions', '/actions/accounts', {
          body,
        });

        setState({ loading: false });
        return res;
      } catch (error) {
        setState({ error, loading: false });
        return error;
      }
    },
  logout:
    () =>
    async ({ setState }) => {
      await Auth.signOut();
      sendExtensionLogoutEvent();
      window.Intercom?.('shutdown');
      clearGhostAccountIfNeeded();
      clearAmplifyLocalStorage();
      clearLocalStorage();
      setState({ ...initialState, storageUser: null, isAuthenticated: false });
    },
  isProjectLive:
    project =>
    ({ getState }) => {
      const publishId = project?.publishId;
      const account = getState().account;
      return account?.liveIds?.includes(publishId) || project?.live;
    },
  getAppKey:
    () =>
    ({ getState }) => {
      const { user } = getState();
      return user?.appKey;
    },
  clearUser:
    () =>
    ({ setState }) => {
      setState({ user: null, account: null });
    },
  subscribeAccount:
    ({ appKey, fetchFeatures, getPlan }) =>
    ({ getState, setState }) => {
      try {
        const subscription = API.graphql(
          graphqlOperation(onUpdateAccountSettingsByAppKey, { appKey })
        ).subscribe({
          next: data => {
            const { packageId } = getState();

            const account = data?.value?.data?.onUpdateAccountSettingsByAppKey;

            console.log('account new data', account);

            const newPackageId = packageId && packageId !== account.packageId;
            if (newPackageId) {
              console.log('Got new packageId', account.packageId);
              fetchFeatures(appKey);
              getPlan();
            }

            setState({ account, packageId: account.packageId });
          },
          error: ({ error: { errors = [] } = {} }) => {
            Utils.logErrorMessage(
              errors[0]?.message || 'Error in onUpdateAccountSettings subscribe'
            );
          },
        });

        setState({ subscription });
      } catch (error) {
        console.log('Error account subscription', error);
        Utils.logError('Cannot subscribe to accountSettings', error);
      }
    },
  unsubscribeAccount:
    () =>
    ({ getState, setState }) => {
      console.log('Closing vod socket');

      const { subscription } = getState();

      subscription?.unsubscribe();

      setState({ subscription: null });
    },
};

const startInterval = dispatch => {
  dispatch(actions.refreshSession());
  setInterval(() => {
    dispatch(actions.refreshSession());
  }, TWO_MINUTES);
};

const checkPassword = pass => {
  let errorMessage = null;
  SIGNUP_REGEX.forEach(({ message, regex }) => {
    const match = pass.match(regex);
    if (!match) {
      errorMessage = message;
    }
  });
  return errorMessage;
};

const userSelector = state => {
  const userLogoSettings = Utils.safeParse(state.user?.logoSettings);
  const accountLogoSettings = Utils.safeParse(state.account?.logoSettings);
  return { ...state, userLogoSettings, accountLogoSettings };
};

const UserStore = createStore({ initialState, actions, name: 'User' });

export const useUser = createHook(UserStore, { selector: userSelector });

export const useUserActions = createActionsHook(UserStore);

export const UserContainer = createContainer(UserStore, {
  onInit:
    () =>
    ({ dispatch }) => {
      const { pathname } = window.location;
      dispatch(actions.fetch());
      dispatch(actions.getCurrentUser(pathname));
      startInterval(dispatch);
    },
});
