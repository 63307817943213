import { useProductActions } from 'src/context/ProductsStore';
import { useVodConnectionSuggestionActions } from 'src/context/VodConnectionSuggestionStore';
import { track } from 'src/helpers/Tracker';
import {
  VodConnectionSuggestionStatus,
  VodConnectionType,
  vodConnectionSuggestion as VodConnectionSuggestion,
} from 'src/types/entities';
import { useCreateVodConnection } from '../vod-connections';
import { useVodConnection } from 'src/context/VodConnectionStore';
import { useMemo } from 'react';
import { usePendingVodConnectionSuggestions } from './PendingVodConnectionsContext';
import { useCurrentStoreApp } from 'app/src/context/helper/useCurrentStoreApp';

type Props = {
  vodAssetId: string;
};

export type UpdateSuggestionPayload = {
  externalProductId: string;
  vodConnectionSuggestionId: string;
};

type ReturnType = {
  isInitialized: boolean;
  pendingVodConnectionSuggestions: VodConnectionSuggestion[];
  shouldShowSuggestions: boolean;
  shouldShowVideoCardButton: boolean;
  handleSuggestionRejected: ({
    externalProductId,
    vodConnectionSuggestionId,
  }: UpdateSuggestionPayload) => Promise<void>;
  handleSuggestionAccepted: ({
    externalProductId,
    vodConnectionSuggestionId,
  }: UpdateSuggestionPayload) => Promise<void[]>;
};

/**
 * This hook is used to get the pending suggestions for a given vodAssetId.
 *
 * It is dependent on `usePendingVodConnectionSuggestions`, which is a part of `PendingVodConnectionsContext`.
 */
const useSuggestionsByVodAssetId = ({ vodAssetId }: Props): ReturnType => {
  const { createProducts } = useProductActions();
  const { createVodConnection } = useCreateVodConnection();
  const { app: selectedApp } = useCurrentStoreApp();
  const { updateSuggestionStatus } = useVodConnectionSuggestionActions();
  const { isInitialized, shouldShowSuggestions, getSuggestionsByVodAssetId } =
    usePendingVodConnectionSuggestions();
  const [{ vodConnectionByVodAssetId }] = useVodConnection();

  const handleSuggestionRejected = ({ externalProductId, vodConnectionSuggestionId }) => {
    track('Vod Connection Suggestion Rejected', {
      externalProductId,
      vodAssetId,
      vodConnectionSuggestionId,
    });

    return updateSuggestionStatus({
      externalProductId,
      vodAssetId,
      status: VodConnectionSuggestionStatus.rejected,
    });
  };

  const handleSuggestionAccepted = async ({ externalProductId, vodConnectionSuggestionId }) => {
    track('Vod Connection Suggestion Accepted', {
      externalProductId,
      vodAssetId,
      vodConnectionSuggestionId,
    });

    const product = (await createProducts([externalProductId], selectedApp.appUrl))?.[0];

    const promises = [];

    promises.push(
      createVodConnection({
        productId: product.id,
        externalProductId,
        vodAssetId,
        type: VodConnectionType.productId,
        appUrl: selectedApp.appUrl,
        provider: selectedApp.app,
      })
    );

    promises.push(
      updateSuggestionStatus({
        externalProductId,
        vodAssetId,
        status: VodConnectionSuggestionStatus.accepted,
      })
    );

    return Promise.all(promises);
  };

  const nonExistingVodConnectionSuggestions = useMemo(() => {
    return getSuggestionsByVodAssetId(vodAssetId);
  }, [getSuggestionsByVodAssetId, vodAssetId]);

  const vodConnections = vodConnectionByVodAssetId[vodAssetId];

  const hasPendingSuggestions = nonExistingVodConnectionSuggestions.length > 0;

  const shouldShowVideoCardButton =
    shouldShowSuggestions && !vodConnections?.length && hasPendingSuggestions;

  return {
    isInitialized,
    pendingVodConnectionSuggestions: nonExistingVodConnectionSuggestions,
    shouldShowSuggestions: shouldShowSuggestions && hasPendingSuggestions,
    shouldShowVideoCardButton,
    handleSuggestionRejected,
    handleSuggestionAccepted,
  };
};

export default useSuggestionsByVodAssetId;
