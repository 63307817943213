import React from 'react'

const TolySendButton = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="4" fill="#ED2455"/>
        <path d="M9.53125 8.875L23.3438 14.875C24.3438 15.3125 24.3438 16.7188 23.3438 17.1562L9.53125 23.1562C8.46875 23.625 7.375 22.4375 7.9375 21.4062L10.125 17.375C10.25 17.125 10.5 16.9375 10.8125 16.9062L16.3125 16.2188C16.4062 16.2188 16.5 16.125 16.5 16C16.5 15.9062 16.4062 15.8125 16.3125 15.8125L10.8125 15.125C10.5 15.0938 10.25 14.9062 10.125 14.6562L7.9375 10.625C7.375 9.59375 8.46875 8.40625 9.53125 8.875Z" fill="white"/>
    </svg>
  )
}

export default TolySendButton