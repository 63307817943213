import React from 'react';
import CollapsingToolbarArrowRight from 'app/src/images/CollapsingToolbarArrowRight';
import { Checkbox } from '@material-ui/core';
import styled from 'styled-components';
import Input from 'app/src/basic_components/Input';
import { TextBody } from 'shared/react/components/basic/text/TextV2';
import InfoIconWithTooltip from 'app/src/complex_components/InfoIconWithTooltip';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import CopyCodeButton from './CopyCodeButton';

export const getNotAutomationTool = (
  setIsOpen,
  isOpen,
  isEmailTrackingEnabled,
  setIsEmailTrackingEnabled,
  email,
  setEmail,
  isEmailPixelTrackingEnabled,
  setIsEmailPixelTrackingEnabled,
  isCopyDisabled,
  onCopyClick,
  selectedTool
) => [
  <ThirdStep key={3}>
    <ThirdStepHeader onClick={() => setIsOpen(!isOpen)}>
      <EmailStepperText>Add tracking</EmailStepperText>
      <CollapsingToolbarArrowRight rotationDegrees={isOpen ? 270 : 90} />
    </ThirdStepHeader>
    {isOpen && (
      <SecondContentContainer>
        <OptionContainer>
          <Checkbox
            checked={isEmailTrackingEnabled}
            onChange={() => setIsEmailTrackingEnabled(!isEmailTrackingEnabled)}
            style={{ padding: '0px' }}
          />
          Add viewer tracking
          <InfoIconWithTooltip title="Type the address of the person you'd like to send your Tolstoy to." />
        </OptionContainer>
        <CustomInput
          type="email"
          value={email}
          onChange={e => {
            setIsEmailTrackingEnabled(true);
            setEmail(e.target.value);
          }}
          placeholder="Viewer’s email"
        />
        <OptionContainer>
          <OptionContainer>
            <Checkbox
              checked={isEmailPixelTrackingEnabled}
              onChange={() => setIsEmailPixelTrackingEnabled(!isEmailPixelTrackingEnabled)}
              style={{ padding: '0px' }}
            />
            Add email tracking pixels
            <InfoIconWithTooltip title="Tolstoy will track when emails you send are opened, so you can see what your click-through rate is." />
          </OptionContainer>
        </OptionContainer>
      </SecondContentContainer>
    )}
  </ThirdStep>,
  <ForthStep key={4}>
    <EmailStepperText key={3}>Paste the code below into your email</EmailStepperText>
    <CopyCodeButton
      disabled={isCopyDisabled}
      onClick={() =>
        onCopyClick({
          email: isEmailTrackingEnabled ? email : '',
          selectedTool: selectedTool.id,
          location: 'Email Automation Tools',
          pixelTracking: isEmailPixelTrackingEnabled,
        })
      }
    >
      Copy code
    </CopyCodeButton>
  </ForthStep>,
];

const ThirdStep = styled(Gap8VerticalFlex)``;

const ThirdStepHeader = styled.div`
  display: flex;
  gap: 8px;
  cursor: pointer;
  align-items: center;
`;

const ForthStep = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const EmailStepperText = styled(TextBody)`
  font-weight: 800;
  color: ${({ theme }) => theme.colors.black};
`;

const OptionContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
`;

const SecondContentContainer = styled.div`
  margin-left: 4px;
  display: grid;
  gap: 8px;

  .MuiOutlinedInput-input {
    padding: 10.5px 14px;
  }
`;

const CustomInput = styled(Input)`
  margin-bottom: 4px;
  width: 65%;
`;
