import React from 'react';
import styled from 'styled-components';
import OnOffToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/OnOffToggleItem';
import EditorHeader from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorHeader';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import {
  STORIES_ALIGNMENT_PROPERTY_KEY,
  STORIES_ALIGNMENTS,
  STORIES_NAME_ALIGNMENTS,
  STORIES_NAME_LOCATION,
  STORIES_STORY_NAME_ALIGNMENT_PROPERTY_KEY,
  STORIES_STORY_NAME_COLOR_PROPERTY_KEY,
  STORIES_STORY_NAME_ENABLED_PROPERTY_KEY,
  STORIES_STORY_NAME_FONT_SIZE_PROPERTY_KEY,
  STORIES_STORY_NAME_FONT_WEIGHT_PROPERTY_KEY,
  STORIES_STORY_NAME_PADDING_PROPERTY_KEY,
  STORIES_STORY_NAME_TEXT_ALIGN_PROPERTY_KEY,
  STORIES_STORY_NAME_TYPE_PROPERTY_KEY,
} from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import EditorColorInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorColorInputItem';
import EditorSelect from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorSelect';
import Utils from 'shared/react/utils/utils';
import EditorMainContainer from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorMainContainer';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import EditorButtonGroupItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorButtonGroupItem';
import EditorSliderItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorSliderItem';
import AlignLeftIcon from 'app/src/images/alignment_icons/AlignLeftIcon';
import AlignCenterIcon from 'app/src/images/alignment_icons/AlignCenterIcon';
import AlignRightIcon from 'app/src/images/alignment_icons/AlignRightIcon';
import EditorItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorItem';
import CenterDirectionIcon from 'app/src/images/directons/CenterDirectionIcon';
import TopDirectionIcon from 'app/src/images/directons/TopDirectionIcon';
import BottomDirectionIcon from 'app/src/images/directons/BottomDirectionIcon';
import EditorNumericInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorNumericInputItem';

const fontSizes = Utils.range(12, 24);

const FeedStoriesStoryNameEditor = () => {
  const {
    customizationSettings: {
      widgetSettings: {
        storiesStoryNameEnabled,
        storiesStoryNameFontSize,
        storiesStoryNameColor,
        storiesStoryNameType,
        storiesStoryNameTextAlign,
        storiesStoryNameAlignment,
        storiesStoryNameFontWeight,
        storiesStoryNamePadding,
      },
    },
    setWidgetSettingsProperty,
  } = useLookAndFeelContext();

  const onEnableStoryName = () => {
    setWidgetSettingsProperty(STORIES_STORY_NAME_ENABLED_PROPERTY_KEY, !storiesStoryNameEnabled);
  };

  const onColorChange = value => {
    setWidgetSettingsProperty(STORIES_STORY_NAME_COLOR_PROPERTY_KEY, value);
  };

  const onFontSizeChange = value => {
    setWidgetSettingsProperty(STORIES_STORY_NAME_FONT_SIZE_PROPERTY_KEY, value.id);
  };

  const onFontWeightChange = (event, value) => {
    setWidgetSettingsProperty(STORIES_STORY_NAME_FONT_WEIGHT_PROPERTY_KEY, value);
  };

  const getStoriesStoryNameTypeButtons = type => {
    return {
      text: Utils.uppercaseFirstChar(type),
      isEnabled: storiesStoryNameType === type,
      onClick: () => {
        setWidgetSettingsProperty(STORIES_STORY_NAME_TYPE_PROPERTY_KEY, type);
      },
    };
  };

  const storiesStoryNameTypeButtons = [
    getStoriesStoryNameTypeButtons(STORIES_NAME_LOCATION.below),
    getStoriesStoryNameTypeButtons(STORIES_NAME_LOCATION.overlay),
  ];

  const options = [
    {
      icon: <TopDirectionIcon />,
      isEnabled: storiesStoryNameAlignment === STORIES_NAME_ALIGNMENTS.baseline,
      onClick: () =>
        setWidgetSettingsProperty(
          STORIES_STORY_NAME_ALIGNMENT_PROPERTY_KEY,
          STORIES_NAME_ALIGNMENTS.baseline
        ),
    },
    {
      icon: <CenterDirectionIcon />,
      isEnabled: storiesStoryNameAlignment === STORIES_NAME_ALIGNMENTS.center,
      onClick: () =>
        setWidgetSettingsProperty(
          STORIES_STORY_NAME_ALIGNMENT_PROPERTY_KEY,
          STORIES_NAME_ALIGNMENTS.center
        ),
    },
    {
      icon: <BottomDirectionIcon />,
      isEnabled: storiesStoryNameAlignment === STORIES_NAME_ALIGNMENTS.end,
      onClick: () =>
        setWidgetSettingsProperty(
          STORIES_STORY_NAME_ALIGNMENT_PROPERTY_KEY,
          STORIES_NAME_ALIGNMENTS.end
        ),
    },
  ];

  const textAlignment = [
    {
      icon: <AlignLeftIcon />,
      isEnabled: storiesStoryNameTextAlign === STORIES_ALIGNMENTS.left,
      onClick: () =>
        setWidgetSettingsProperty(
          STORIES_STORY_NAME_TEXT_ALIGN_PROPERTY_KEY,
          STORIES_ALIGNMENTS.left
        ),
    },
    {
      icon: <AlignCenterIcon />,
      isEnabled: storiesStoryNameTextAlign === STORIES_ALIGNMENTS.center,
      onClick: () =>
        setWidgetSettingsProperty(
          STORIES_STORY_NAME_TEXT_ALIGN_PROPERTY_KEY,
          STORIES_ALIGNMENTS.center
        ),
    },
    {
      icon: <AlignRightIcon />,
      isEnabled: storiesStoryNameTextAlign === STORIES_ALIGNMENTS.right,
      onClick: () =>
        setWidgetSettingsProperty(
          STORIES_STORY_NAME_TEXT_ALIGN_PROPERTY_KEY,
          STORIES_ALIGNMENTS.right
        ),
    },
  ];

  const onPaddingChange = value => {
    const [verticalPadding] = storiesStoryNamePadding.split(' ');
    const newValue = `${verticalPadding} ${value}px`;
    setWidgetSettingsProperty(STORIES_STORY_NAME_PADDING_PROPERTY_KEY, newValue);
  };

  const onVerticalPaddingChange = value => {
    const [, horizontalPadding] = storiesStoryNamePadding.split(' ');
    const newValue = `${value}px ${horizontalPadding}`;

    setWidgetSettingsProperty(STORIES_STORY_NAME_PADDING_PROPERTY_KEY, newValue);
  };

  const [verticalPadding, horizontalPadding] = storiesStoryNamePadding
    .replaceAll('px', '')
    .split(' ');

  return (
    <LayoutRoot>
      <EditorHeader title="Story name" isWhite={!storiesStoryNameEnabled}>
        <OnOffToggleItem checked={storiesStoryNameEnabled} onChange={onEnableStoryName} />
      </EditorHeader>
      <EditorMainContainer shouldHide={!storiesStoryNameEnabled}>
        <EditorButtonGroupItem text="Position" buttons={storiesStoryNameTypeButtons} />
        <EditorSliderItem
          onChange={onFontWeightChange}
          text="Text weight"
          value={storiesStoryNameFontWeight}
          min={300}
          step={100}
          max={900}
        />
        <SmallerEditorSelect
          onChange={onFontSizeChange}
          text="Text size"
          value={storiesStoryNameFontSize}
          items={fontSizes}
        />
        <EditorColorInputItem
          text="Text color"
          color={storiesStoryNameColor}
          onChange={onColorChange}
        />
        {storiesStoryNameType === STORIES_NAME_LOCATION.overlay && (
          <>
            <EditorItem text="Vertical Alignment">
              <EditorButtonGroupItem buttons={options} />
            </EditorItem>
            <EditorItem text="Text alignment">
              <EditorButtonGroupItem buttons={textAlignment} />
            </EditorItem>
            <EditorNumericInputItem
              text="Horizontal padding"
              suffix="px"
              onChange={onPaddingChange}
              value={+horizontalPadding}
              max={50}
            />
            <EditorNumericInputItem
              text="Vertical padding"
              suffix="px"
              onChange={onVerticalPaddingChange}
              max={50}
              value={+verticalPadding}
            />
          </>
        )}
      </EditorMainContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

const SmallerEditorSelect = styled(EditorSelect)`
  max-width: 80px;
`;

export default FeedStoriesStoryNameEditor;
