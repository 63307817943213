import { useState, useEffect } from 'react';
import { GenericObject } from 'src/types/common';
import Utils from 'src/utils';

const getSetttingsFromLocalStorage = (settingsKey: string, defaultSettings: GenericObject) => {
  const localSettings = localStorage.getItem(settingsKey);

  return localSettings ? Utils.safeParse(localSettings) : defaultSettings;
};

const setSettingsToLocalStorage = (settingsKey: string, newSettings: GenericObject) => {
  localStorage.setItem(settingsKey, JSON.stringify(newSettings));
};

export const useSidebarLocalSettings = (
  settingsKey: string,
  defaultSettings: GenericObject = {}
) => {
  const [settings, setSettings] = useState<GenericObject>(defaultSettings);

  useEffect(() => {
    const localSettings = getSetttingsFromLocalStorage(settingsKey, defaultSettings);

    setSettings(localSettings);
  }, []);

  const setLocalSettings = (newSettings: GenericObject) => {
    setSettings(newSettings);
    setSettingsToLocalStorage(settingsKey, newSettings);
  };

  return {
    settings,
    setSettings: setLocalSettings,
  };
};

export default useSidebarLocalSettings;
