const getOpacitySuffix = opacityPercentage => {
  return Math.round(opacityPercentage * 255)
    .toString(16)
    .toUpperCase()
    .padStart(2, '0');
};

export const getHexOpacity = (color, opacityPercentage) => {
  return `${color}${getOpacitySuffix(opacityPercentage)}`;
};
