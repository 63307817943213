import React from 'react';
import styled from 'styled-components';
import EditorHeader from '../shared/EditorHeader';
import EditorMainContainer from '../shared/EditorMainContainer';
import EditorToggleItem from '../shared/EditorToggleItem';
import { useLookAndFeelContext } from '../../../LookAndFeelContext';
import {
  TILE_PLAY_BUTTON_BACKGROUND_COLOR_PROPERTY_KEY,
  TILE_PLAY_BUTTON_BACKGROUND_OPACITY_PROPERTY_KEY,
  TILE_PLAY_BUTTON_BORDER_COLOR_PROPERTY_KEY,
  TILE_PLAY_BUTTON_HAS_BACKGROUND_PROPERTY_KEY,
  TILE_PLAY_BUTTON_HAS_BORDER_PROPERTY_KEY,
} from '../../../LookAndFeelPage.constants';
import EditorColorInputItem from '../shared/EditorColorInputItem';
import Separator from 'shared/react/components/basic/Separator';
import EditorSliderItem from '../shared/EditorSliderItem';
import { DEFAULT_CAROUSEL_EMBED_PLAY_BUTTON_OPACITY } from 'shared/react/constants/feature-constants/playButtonCustomization.constants';
import ResetToDefaultInputWrapper from 'src/complex_components/ResetToDefaultInputWrapper';

const TilePlayButtonEditor = () => {
  const {
    customizationSettings: {
      widgetSettings: {
        tilePlayButtonHasBackground,
        tilePlayButtonBackgroundColor,
        tilePlayButtonBackgroundOpacity,
        tilePlayButtonHasBorder,
        tilePlayButtonBorderColor,
      },
    },
    setWidgetSettingsProperty,
  } = useLookAndFeelContext();

  const handleHasBackgroundChange = (value: boolean) => {
    setWidgetSettingsProperty(TILE_PLAY_BUTTON_HAS_BACKGROUND_PROPERTY_KEY, value);
  };

  const handleBackgroundColorChange = (value: string) => {
    setWidgetSettingsProperty(TILE_PLAY_BUTTON_BACKGROUND_COLOR_PROPERTY_KEY, value);
  };

  const handleBackgroundOpacityChange = (_, value: number) => {
    setWidgetSettingsProperty(TILE_PLAY_BUTTON_BACKGROUND_OPACITY_PROPERTY_KEY, value);
  };

  const handleHasBorderChange = (value: boolean) => {
    setWidgetSettingsProperty(TILE_PLAY_BUTTON_HAS_BORDER_PROPERTY_KEY, value);
  };

  const handleBorderColorChange = (value: string) => {
    setWidgetSettingsProperty(TILE_PLAY_BUTTON_BORDER_COLOR_PROPERTY_KEY, value);
  };

  const onResetOpacity = () => {
    setWidgetSettingsProperty(
      TILE_PLAY_BUTTON_BACKGROUND_OPACITY_PROPERTY_KEY,
      DEFAULT_CAROUSEL_EMBED_PLAY_BUTTON_OPACITY
    );
  };

  return (
    <LayoutRoot>
      <EditorHeader title="Play button" />
      <EditorMainContainer>
        <EditorToggleItem
          text="Background"
          checked={tilePlayButtonHasBackground}
          onChange={handleHasBackgroundChange}
        />
        <EditorColorInputItem
          text="Background color"
          color={tilePlayButtonBackgroundColor}
          onChange={handleBackgroundColorChange}
          disabled={!tilePlayButtonHasBackground}
        />
        <Separator />
        <EditorToggleItem
          text="Border"
          checked={tilePlayButtonHasBorder}
          onChange={handleHasBorderChange}
        />
        <EditorColorInputItem
          text="Border color"
          color={tilePlayButtonBorderColor}
          onChange={handleBorderColorChange}
          disabled={!tilePlayButtonHasBorder}
        />
        <Separator />
        <EditorSliderItem
          onChange={handleBackgroundOpacityChange}
          text={
            <StyledResetToDefaultInputWrapper onClick={onResetOpacity}>
              Transparency
            </StyledResetToDefaultInputWrapper>
          }
          value={tilePlayButtonBackgroundOpacity}
          min={0}
          step={0.05}
          max={1}
          disabled={undefined}
        />
      </EditorMainContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div``;

const StyledResetToDefaultInputWrapper = styled(ResetToDefaultInputWrapper)`
  opacity: 1;
  left: 100%;
  top: 50%;
`;

export default TilePlayButtonEditor;
