import React from 'react';
import { VerticalFlexCentered } from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import HeaderAndSubheader from 'src/basic_components/HeaderAndSubheader';
import styled from 'styled-components';
import VideoSpotlight from './VideoSpotlight';

type Props = {
  header: string | React.ReactNode;
  subheader?: string;
  videoSubheader: string;
  videoSrc: string;
};

const VideoSpotlightWithHeader = ({
  header,
  subheader,
  videoSubheader,
  videoSrc,
  ...props
}: Props) => {
  return (
    <LayoutRoot {...props}>
      <HeaderAndSubheader header={header} subheader={subheader} />
      <VideoSpotlight videoSrc={videoSrc} videoSubheader={videoSubheader} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlexCentered)`
  gap: 80px;
`;

export default VideoSpotlightWithHeader;
