import React from 'react';
import { TextBody } from 'shared/react/components/basic/text/TextV2';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import OnOffToggleItem from 'src/pages/project/pages/look_and_feel/editor/editors/shared/OnOffToggleItem';
import styled from 'styled-components';

type Props = {
  isChecked: boolean;
  onChange: () => void;
};

const SyncTagsSwitcher = ({ isChecked, onChange }: Props) => {
  return (
    <ToggleSyncContainer>
      <ToggleSyncElement>
        <TextBody>Enable sync</TextBody>
        <OnOffToggleItem checked={isChecked} onChange={onChange} checkedText="" uncheckedText="" />
      </ToggleSyncElement>
      <Subtitle>
        Once enabled, all product tagged on videos from the Master store will sync to all Replicas.
      </Subtitle>
    </ToggleSyncContainer>
  );
};

const Subtitle = styled(TextBody)`
  color: ${props => props.theme.colors.neutralDarker};
`;

const ToggleSyncElement = styled(Gap8HorizontalFlex)`
  align-items: center;
`;

const ToggleSyncContainer = styled(Gap8VerticalFlex)``;

export default SyncTagsSwitcher;
