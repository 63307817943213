import React from 'react';
import styled from 'styled-components';
import DeleteIcon from 'app/src/images/DeleteIcon';
import { TRASH_BUTTON_DATA_TYPE } from 'app/src/constants/ui.constants';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const TrashButton = ({ onClick }) => {
  return (
    <LayoutRoot onClick={onClick} data-type={TRASH_BUTTON_DATA_TYPE}>
      <DeleteIcon />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  cursor: pointer;
  opacity: 0;
  transition: 0.15s;
  align-items: center;

  & path {
    fill: ${({ theme }) => theme.colors.danger};
  }

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    position: absolute;
    opacity: 1;
    box-shadow: 0 4px 4px ${({ theme }) => theme.getHexOpacity(theme.colors.black, 10)};
    border-radius: 12px;
    top: 6px;
    right: 4px;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => theme.getHexOpacity(theme.colors.white, 80)};
  }
`;

export default TrashButton;
