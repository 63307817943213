import * as React from 'react';

const FacebookIcon = ({ borderColor, ...props }) => (
  <svg
    width={49}
    height={48}
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m29.148 25.75.547-3.594H26.22v-2.343c0-1.016.468-1.954 2.031-1.954h1.602v-3.086s-1.446-.273-2.813-.273c-2.852 0-4.727 1.758-4.727 4.883v2.773H19.11v3.594h3.203v8.75h3.907v-8.75h2.93Z"
      fill="#3D4FE0"
    />
    <path
      d="M24.5 47c-12.703 0-23-10.297-23-23h-2c0 13.807 11.193 25 25 25v-2Zm23-23c0 12.703-10.297 23-23 23v2c13.807 0 25-11.193 25-25h-2Zm-23-23c12.703 0 23 10.297 23 23h2c0-13.807-11.193-25-25-25v2Zm0-2C10.693-1-.5 10.193-.5 24h2c0-12.703 10.297-23 23-23v-2Z"
      fill={borderColor || '#fff'}
    />
  </svg>
);

export default FacebookIcon;
