import React from 'react';

const SpamIcon = ({ fill, width, height }) => {
  return (
    <svg
      width={width || '20'}
      height={height || '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3145 0L16 4.688V11.3154L11.3145 16H4.68716L0 11.3145V4.68716L4.68716 0H11.3145ZM10.6164 1.68421H5.38442L1.68505 5.38526V10.6173L5.38442 14.3175H10.6164L14.3166 10.6173V5.38442L10.6164 1.68505V1.68421ZM7.15789 10.5263H8.8421V12.2105H7.15789V10.5263ZM7.15789 3.78947H8.8421V8.8421H7.15789V3.78947Z"
        fill={fill || '#50545E'}
      />
    </svg>
  );
};

export default SpamIcon;
