export const INTEGRATION_VOD_ASSET_STATUS_TYPES = {
  Invalid: 'invalid',
  Pending: 'pending',
  Removed: 'removed',
  Imported: 'imported',
};

export const VIDEO_TAB_KEYS = {
  MyLibrary: 'myLibrary',
  VideoInbox: 'videoInbox',
};
