import React from 'react';

const VideoIcon = ({ fill = '#50545E' }) => {
  return (
    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.406 1.219a1.16 1.16 0 0 0-1.187-.094L13 2.625V2c0-1.094-.906-2-2-2H2C.875 0 0 .906 0 2v8c0 1.125.875 2 2 2h9c1.094 0 2-.875 2-2v-.594l3.219 1.5c.156.063.343.125.531.125.219 0 .469-.094.656-.219.375-.218.594-.624.594-1.062V2.281a1.22 1.22 0 0 0-.594-1.062ZM11.5 10c0 .281-.25.5-.5.5H2a.494.494 0 0 1-.5-.5V2c0-.25.219-.5.5-.5h9c.25 0 .5.25.5.5v8Zm5-.625L13 7.781V4.25l3.5-1.594v6.719Z"
        fill={fill}
      />
    </svg>
  );
};

export default VideoIcon;
