import React from 'react';
import styled from 'styled-components';

import {NON_BREAKING_SPACE_UNICODE} from 'shared/react/constants/unicode.constants';
import {SELECT_INPUT_CONTAINER_DATA_TYPE} from 'shared/react/constants/feedPreviewDataTypes.constants';
import {BASIC_SELECT_HEADER_DATA_TYPE} from '../../../constants/ui.constants';
import HorizontalFlex, {Gap8HorizontalFlex, HorizontalFlexCentered,} from '../../complex/flex_layouts/HorizontalFlex';
import ExpanderBottomArrow from 'shared/react/images/expander_bottom_arrow.svg';

const INPUT_MAX_HEIGHT = 20;

function DefaultHeader({
  inputBackground,
  toggleIsOpen,
  value,
  inputPadding,
  itemsLength,
  customHeight,
  isOpen,
  shouldStartFromTop,
  loading,
  error,
  inputBoxSizing,
  borderRadius,
  renderHeader,
  isDisabled,
  shouldHideBorder,
  onKeyDown,
  setNextRef = () => {},
  className
}) {
  const getBorderRadius = () => {
    const maxBorderRadius = Math.min(customHeight || INPUT_MAX_HEIGHT, borderRadius);
    if (!isOpen) {
      return `${maxBorderRadius}px`;
    }

    if (shouldStartFromTop) {
      return `0 0 ${maxBorderRadius}px ${maxBorderRadius}px`;
    }

    return `${maxBorderRadius}px ${maxBorderRadius}px 0 0`;
  };

  const getTitle = () => {
    const name = value?.name || value;
    if (renderHeader) {
      return renderHeader(name);
    }

    return name;
  };

  return (
    <InputContainer
      className={className}
      itemsLength={itemsLength}
      onClick={itemsLength ? toggleIsOpen : null}
      inputPadding={inputPadding}
      inputBackground={inputBackground}
      $loading={loading}
      error={error}
      inputBoxSizing={inputBoxSizing}
      data-type={SELECT_INPUT_CONTAINER_DATA_TYPE}
      customHeight={customHeight}
      borderRadius={getBorderRadius()}
      isOpen={isOpen}
      shouldHideBorder={shouldHideBorder}
      role="menu"
      tabIndex="0"
      aria-label={`Selected menu item: ${value}`}
      onKeyDown={onKeyDown}
      ref={setNextRef}
    >
      <MainContainer customHeight={customHeight} isDisabled={isDisabled}>
        <TextWrapper>
          {value?.icon && <IconWrapper>{value.icon}</IconWrapper>}
          <Title data-type={BASIC_SELECT_HEADER_DATA_TYPE} customHeight={customHeight}>
            {getTitle()}
          </Title>
        </TextWrapper>
        <IconWrapper>
          {!!itemsLength && !isDisabled && <img src={ExpanderBottomArrow} alt="Expander arrow" />}
        </IconWrapper>
      </MainContainer>
    </InputContainer>
  );
}

export default DefaultHeader;

const InputContainer = styled.div`
  border: ${({ theme, error, isOpen, shouldHideBorder }) => {
    if (shouldHideBorder && !isOpen) {
      return `1px solid ${theme.colors.white};`;
    }

    return `1px solid ${error ? theme.colors.warning : theme.colors.neutralLight};`;
  }};

  transition: 0.3s;
  padding: ${({ inputPadding }) => inputPadding || '7px 12px'};
  background: ${({ inputBackground }) => inputBackground || 'transparent'};
  box-sizing: ${({ inputBoxSizing }) => inputBoxSizing};
    cursor: ${({itemsLength, $loading}) => (itemsLength && !$loading ? 'pointer' : 'unset')};
  height: ${({ customHeight }) => (customHeight ? `${customHeight}px` : 'auto')};
    opacity: ${({$loading}) => ($loading ? 0.5 : '')};
  border-radius: ${({ borderRadius }) => borderRadius || '4px'};

  &:hover {
    border: ${({ theme, error, isOpen, shouldHideBorder }) => {
      if (shouldHideBorder && !isOpen) {
        return `1px solid ${theme.colors.white};`;
      }

      return `1px solid ${error ? theme.colors.warning : theme.colors.neutralDark};`;
    }};
  }
`;

const TextWrapper = styled(Gap8HorizontalFlex)`
  overflow: hidden;
`;

const IconWrapper = styled(HorizontalFlexCentered)`
  width: 14px;
`;

const MainContainer = styled(HorizontalFlex)`
  gap: 4px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.colors.gray2};
  height: 100%;

  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.gray4};
  }

  opacity: ${({ disabled }) => (disabled ? 0.4 : 1.0)};
`;

const Title = styled(Gap8HorizontalFlex)`
  max-width: 350px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  max-height: ${({ customHeight }) => (customHeight ? `${customHeight}px` : 'fit-content')};

  &::after {
    content: '${NON_BREAKING_SPACE_UNICODE}';
  }
`;
