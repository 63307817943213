import React from 'react';
import EditorColorInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorColorInputItem';
import EditorNumericInputItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorNumericInputItem';
import EditorOnOffToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorOnOffToggleItem';
import { DesignFeedCss } from 'app/src/types/design';
import EditorFontFamilyAndWeight from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorFontFamilyAndWeight';

type Props = {
  disabled?: boolean;
  values: DesignFeedCss;
  setFunction: (values: DesignFeedCss) => void;
  fields: string[];
  fonts: any;
  maxFontSize?: number;
};

const FeedDesignSettings = ({
  disabled,
  values = {},
  setFunction,
  fields = [],
  fonts,
  maxFontSize,
}: Props) => {
  const {
    fontColor,
    fontSize,
    font,
    fontWeight,
    borderToggle,
    borderThickness,
    borderColor,
    borderRadius,
    backgroundColor,
  } = values;

  const updateSettings = ({ value, key }: { value: string | number; key: string }) => {
    setFunction({ ...values, [key]: value });
  };

  const onUpdateFontAndWeight = (font, fontWeight) => {
    setFunction({ ...values, font, fontWeight });
  };

  const components = {
    ['font']: {
      component: (
        <EditorFontFamilyAndWeight
          onChange={onUpdateFontAndWeight}
          value={font}
          fontWeight={fontWeight}
          fonts={fonts}
        />
      ),
      disabled: true,
    },
    ['fontSize']: {
      component: (
        <EditorNumericInputItem
          text="Font size"
          suffix="px"
          value={fontSize}
          onChange={value => updateSettings({ key: 'fontSize', value })}
          max={maxFontSize || 56}
          disabled={disabled}
          key={'fontSize'}
        />
      ),
    },
    ['fontColor']: {
      component: (
        <EditorColorInputItem
          text="Text color"
          color={fontColor}
          onChange={value => updateSettings({ key: 'fontColor', value })}
          height="50px"
          maxWidth="160px"
          disabled={disabled}
          key={'fontColor'}
        />
      ),
    },
    ['borderToggle']: {
      component: (
        <EditorOnOffToggleItem
          text="Border"
          checked={!!borderToggle}
          onChange={value => updateSettings({ key: 'borderToggle', value })}
          key={'borderToggle'}
        />
      ),
    },
    ['borderThickness']: {
      component: (
        <EditorNumericInputItem
          text="Border thickness"
          suffix="px"
          value={borderThickness}
          onChange={value => updateSettings({ key: 'borderThickness', value })}
          max={20}
          disabled={disabled}
          key={'borderThickness'}
        />
      ),
      hidden: !borderToggle,
    },
    ['borderColor']: {
      component: (
        <EditorColorInputItem
          text="Border color"
          color={borderColor}
          onChange={value => updateSettings({ key: 'borderColor', value })}
          height="50px"
          maxWidth="160px"
          disabled={disabled}
          key={'borderColor'}
        />
      ),
      hidden: !borderToggle,
    },
    ['borderRadius']: {
      component: (
        <EditorNumericInputItem
          text="Border radius"
          suffix="px"
          value={borderRadius}
          onChange={value => updateSettings({ key: 'borderRadius', value })}
          max={56}
          disabled={disabled}
          key={'borderRadius'}
        />
      ),
    },
    ['backgroundColor']: {
      component: (
        <EditorColorInputItem
          text="Background color"
          color={backgroundColor}
          onChange={value => updateSettings({ key: 'backgroundColor', value })}
          height="50px"
          maxWidth="160px"
          disabled={disabled}
          key={'backgroundColor'}
        />
      ),
    },
  };

  return (
    <>
      {fields.map(field => {
        const component = components[field];
        if (!component || component?.hidden) {
          return null;
        }

        return component.component;
      })}
    </>
  );
};

export default FeedDesignSettings;
