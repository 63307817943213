import React from 'react';

const HammerIcon = () => (
  <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.93 6.078a2.18 2.18 0 0 1-1.875-.586l-.89-.914a1.843 1.843 0 0 1-.54-1.312v-.282L4.75 1.954a.314.314 0 0 1-.188-.329c0-.14.07-.281.211-.328L5.875.805A3.587 3.587 0 0 1 7.258.5h.422a3.32 3.32 0 0 1 2.32.938l1.031.984c.563.515.774 1.289.633 2.015l.375.352.188-.187a.551.551 0 0 1 .773 0l.563.562c.234.234.234.586 0 .797L11.5 8.023a.513.513 0 0 1-.773 0l-.563-.562c-.234-.211-.234-.563 0-.797l.188-.187-.422-.399ZM.883 9.36l5.46-4.57c.095.117.188.234.282.328l.89.89c.141.141.305.259.47.376L3.39 11.867A1.78 1.78 0 1 1 .883 9.36Z"
      fill="#B3B6BC"
    />
  </svg>
);

export default HammerIcon;
