import React from 'react';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import styled from 'styled-components';
import AUTOMATION_TOOLS from 'app/src/pages/share/email/emailAutomationToolsList';
import BasicSelect from 'shared/react/components/basic/basic_select/BasicSelect';
import { SELECT_INPUT_CONTAINER_DATA_TYPE } from 'shared/react/constants/feedPreviewDataTypes.constants';
import { BASIC_SELECT_HEADER_DATA_TYPE } from 'shared/react/constants/ui.constants';

type Props = {
  value: (typeof AUTOMATION_TOOLS)[number] | { id: string };
  onChange: (tool: (typeof AUTOMATION_TOOLS)[number]) => void;
  items: typeof AUTOMATION_TOOLS;
  shouldHide?: boolean;
};

const AutomationToolsSelect = ({ onChange, value, items, shouldHide }: Props) => {
  if (shouldHide) {
    return null;
  }

  return (
    <LayoutRoot>
      <Header>Select your Email tool</Header>
      {/* Ignoring the next line because all parameters are being inferred as any in JS file
      @ts-ignore-next-line */}
      <StyledBasicSelect
        customHeight="30"
        inputPadding="0"
        onChange={onChange}
        value={value}
        items={items}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)``;

const Header = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralGrey};
  font-weight: 500;
`;

const StyledBasicSelect = styled(BasicSelect)`
  max-width: 220px;
  width: 100%;

  & [data-type=${SELECT_INPUT_CONTAINER_DATA_TYPE}] {
    border: none;

    & > * {
      justify-content: flex-start;
    }

    &:hover {
      border: none;
    }
  }

  & [data-type=${BASIC_SELECT_HEADER_DATA_TYPE}] {
    max-height: 40px;
    padding-right: 2px;

    & > * {
      max-height: 40px;
      padding-left: 0;
    }
  }

  & *::after {
    display: none;
  }
`;

export default AutomationToolsSelect;
