import { css } from 'styled-components';

const BREAKPOINT = '1350px';
const SECOND_BREAKPOINT = '1160px';

export const embedCarouselPreviewSize = css`
  width: 760px;

  @media (max-width: ${BREAKPOINT}) {
    width: 600px;
  }

  @media (max-width: ${SECOND_BREAKPOINT}) {
    && {
      min-width: 350px;
      width: 450px;
    }
  }
`;
