import React, { useState } from 'react';
import styled from 'styled-components';
import { TextBody } from 'shared/react/components/complex/Text';

import {
  ACTIONS_KEY,
  DATE_ADDED_KEY,
  EMAIL_KEY,
  PAYMENT_ROLE_KEY,
  PAYMENT_ROLE_PRO_KEY,
  ROLE_KEY,
  STATUS_ACCEPTED,
  STATUS_KEY,
} from 'app/src/constants/teamMembers.constants';
import TeamMemberStatus from './team_members_components/TeamMemberStatus';
import TeamMemberDate from './team_members_components/TeamMemberDate';
import TeamMemberText from './team_members_components/TeamMemberText';
import TeamMemberActions from './team_members_components/TeamMemberActions';
import TeamMemberPaymentRole from './team_members_components/TeamMemberPaymentRole';
import TeamMemberEmail from './team_members_components/TeamMemberEmail';
import { useAccount } from 'app/src/context/AccountStore';
import TeamMemberRole from 'app/src/pages/team_members/team_members_components/TeamMemberRole';

const COLUMNS = [
  {
    key: EMAIL_KEY,
    name: 'Email',
  },
  {
    key: ROLE_KEY,
    name: 'Role',
  },
  {
    key: STATUS_KEY,
    name: 'Status',
  },
  {
    key: DATE_ADDED_KEY,
    name: 'Date added',
  },
  {
    key: PAYMENT_ROLE_KEY,
    name: 'Seat',
  },
  {
    key: ACTIONS_KEY,
    name: '',
  },
];

const getColumns = (columnKeys = []) => {
  if (!columnKeys.length) {
    return COLUMNS;
  }
  return COLUMNS.filter(({ key }) => columnKeys.includes(key));
};

const TeamMembersTable = ({
  className,
  teamMembers,
  columnKeys,
  onTeamMemberRemove,
  onTeamMemberPaymentRoleUpdate,
  viewOnly,
}) => {
  const [{ isAccountOwner }] = useAccount();
  const [disabledActions, setDisabledActions] = useState([]);

  const columns = getColumns(columnKeys);

  if (!teamMembers.length) {
    return null;
  }

  const getRemoveTeamMember = async teamMember => {
    setDisabledActions([...disabledActions, teamMember.id]);
    await onTeamMemberRemove(teamMember);
    const newDisabledActions = disabledActions.filter(id => id !== teamMember.id);
    setDisabledActions(newDisabledActions);
  };

  const getContentTextByKey = (teamMember, key) => {
    const content = teamMember[key];

    if (key === ACTIONS_KEY) {
      return (
        <TeamMemberActions
          teamMember={teamMember}
          hidden={teamMember.role === 'owner'}
          onRemove={getRemoveTeamMember}
          loading={disabledActions.includes(teamMember.id)}
        />
      );
    }
    if (key === STATUS_KEY) {
      return <TeamMemberStatus status={content} id={teamMember.id} viewOnly={viewOnly} />;
    }
    if (key === DATE_ADDED_KEY) {
      return <TeamMemberDate date={content} />;
    }

    if (key === ROLE_KEY && isAccountOwner) {
      return <TeamMemberRole text={content} teamMember={teamMember} />;
    }

    if (key === PAYMENT_ROLE_KEY) {
      const disabledUpdatePaymentRole =
        !isAccountOwner || (getNumberOfProSeats() === 1 && getIsProTeamMember(teamMember));

      return (
        <TeamMemberPaymentRole
          disabled={disabledUpdatePaymentRole}
          teamMember={teamMember}
          paymentRole={content}
          onTeamMemberPaymentRoleUpdate={onTeamMemberPaymentRoleUpdate}
        />
      );
    }
    if (key === EMAIL_KEY) {
      return <TeamMemberEmail text={content} userId={teamMember.userId} />;
    }
    return <TeamMemberText text={content} />;
  };

  const getIsProTeamMember = ({ status, paymentRole }) => {
    return status === STATUS_ACCEPTED && paymentRole === PAYMENT_ROLE_PRO_KEY;
  };

  const getNumberOfProSeats = () => {
    return teamMembers.filter(getIsProTeamMember).length;
  };

  return (
    <StyledTeamMembersTable numOfColumns={columns?.length} className={className}>
      <Header>
        {columns.map(({ key, name }) => (
          <HeaderText key={key}>{name}</HeaderText>
        ))}
      </Header>
      {teamMembers?.map(teamMember => (
        <ContentRow key={teamMember.id || teamMember.email}>
          {columns.map(({ key }) => (
            <Content key={key}>{getContentTextByKey(teamMember, key)}</Content>
          ))}
        </ContentRow>
      ))}
    </StyledTeamMembersTable>
  );
};

export default TeamMembersTable;

const StyledTeamMembersTable = styled.div`
  display: grid;
  grid-template-columns: ${({ numOfColumns }) =>
    `repeat(${numOfColumns}, minmax(max-content, auto))`};
  grid-auto-rows: 48px;
  align-items: center;
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow: auto;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.gray3};
`;

const Header = styled.div`
  display: contents;
`;

const HeaderText = styled(TextBody)`
  position: sticky;
  top: 0;
  z-index: 2;
  box-shadow: 0px -8px ${({ theme }) => theme.colors.white},
    0px 4px ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.gray5};
  color: ${({ theme }) => theme.colors.ghostDark};
  line-height: 22px;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  height: 100%;
  padding: 14px 16px;
  :first-of-type {
    border-radius: 10px 0px 0px 10px;
  }
  :last-of-type {
    border-radius: 0px 10px 10px 0px;
  }
`;

const ContentRow = styled.div`
  display: contents;
  & :nth-child(2n-1) > * {
    background-color: ${({ theme }) => theme.colors.gray1};
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 16px;
  :first-of-type {
    border-radius: 10px 0px 0px 10px;
  }
  :last-of-type {
    border-radius: 0px 10px 10px 0px;
  }
`;
