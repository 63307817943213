import React, { useEffect } from 'react';
import { project as Project, publishMethodOptions } from 'src/types/entities';
import PublishEmbed from 'app/src/pages/project/pages/installation/components/embed/PublishEmbed';
import { useBilling } from 'app/src/hooks/useBillings';
import AccountInstallationWrapper from 'app/src/pages/project/pages/installation/components/shared/account/AccountInstallationWrapper';
import HeroInstallation from 'app/src/pages/project/pages/installation/components/hero/HeroInstallation';
import TvInstallation from './components/tv/TvInstallation';
import ProjectInstallationSection from 'app/src/pages/project/pages/installation/components/stories_and_carousel/ProjectInstallationSection';
import PublishFree from 'app/src/pages/project/pages/installation/components/free/PublishFree';
import useSelectPublishMethodModal from 'src/pages/modals/publish/hooks/useSelectPublishMethodModal';
import TvChannelInstallation from './components/tv-channel/TvChannelInstallation';
import { TextBody, TextBodyBold } from 'shared/react/components/basic/text/TextV2';

type Props = {
  project: Project;
};

const InstallationPage = ({ project }: Props) => {
  const { hidePublishContent } = useBilling();
  const { openSelectPublishMethodModalIfNeeded } = useSelectPublishMethodModal(project);

  const publishMethod = project?.publishMethod;
  const isTvChannel = publishMethod === publishMethodOptions.tvChannel;

  useEffect(() => {
    openSelectPublishMethodModalIfNeeded();
  }, []);

  if (hidePublishContent && !isTvChannel) {
    return (
      <AccountInstallationWrapper project={project} hidePublishContent={hidePublishContent}>
        <div>
          <TextBodyBold>Ready to experience the power of video on your store?</TextBodyBold>
          <TextBody>You have 2 weeks to try Tolstoy completely for free.</TextBody>
          <TextBody>
            You can cancel at any point during this period, and you won&apos;t be charged anything.
          </TextBody>
          <br />
          <PublishFree project={project} />
        </div>
      </AccountInstallationWrapper>
    );
  }

  switch (publishMethod) {
    case publishMethodOptions.bubble:
      return <AccountInstallationWrapper project={project} />;
    case publishMethodOptions.embed:
      return (
        <AccountInstallationWrapper project={project}>
          <PublishEmbed project={project} />
        </AccountInstallationWrapper>
      );
    case publishMethodOptions.hero:
      return (
        <AccountInstallationWrapper project={project}>
          <HeroInstallation project={project} />
        </AccountInstallationWrapper>
      );
    case publishMethodOptions.stories:
    case publishMethodOptions.carousel:
      return (
        <AccountInstallationWrapper project={project}>
          <ProjectInstallationSection project={project} />
        </AccountInstallationWrapper>
      );
    case publishMethodOptions.videoPage:
      return (
        <AccountInstallationWrapper project={project}>
          <TvInstallation project={project} />
        </AccountInstallationWrapper>
      );
    case publishMethodOptions.tvChannel:
      return <TvChannelInstallation project={project} />;
    default:
      return null;
  }
};

export default InstallationPage;
