import React from 'react';
import styled from 'styled-components';
import ShareSocialButtons from 'shared/react/components/complex/share/ShareSocialButtons';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';
import useTracker from 'shared/react/components/complex/context/hooks/useTracker';
import Utils from 'shared/react/utils/utils';
import useFeedSnackbar from 'shared/react/components/complex/context/hooks/useFeedSnackbar';
import { FEED_SHARE_SOCIAL_BUTTONS_DATA_TYPE } from 'shared/react/constants/feedPreviewDataTypes.constants';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import useFeedFeatures from 'shared/react/components/complex/context/hooks/useFeedFeatures';
import { FEATURE_FEED_HIDE_SHARE } from 'shared/react/constants/features.constants';
import useTranslation from 'shared/react/components/complex/context/hooks/useTranslation';
import useKeyboardNavigation from 'player/src/hooks/useKeyboardNavigation';
import { SOCIAL_PLATFORMS_KEYS } from '../../../../constants/share.constants';

const FeedShare = ({ iconsFillColor, shouldHideCopyLink, currentStep, ...props }) => {
  const [, { t }] = useTranslation();
  const [, { track }] = useTracker();
  const [{ publishId }] = useProjectConfig();
  const [, { setSnackbarText }] = useFeedSnackbar();
  const [, { getFeatureSettingsByKey }] = useFeedFeatures();
  const { shouldHide } = getFeatureSettingsByKey(FEATURE_FEED_HIDE_SHARE);
  const { onKeyDown, setNextRef } = useKeyboardNavigation();

  const playerUrl = Utils.getPlayerShareUrl(publishId, currentStep.videoId);

  const handleSocialButtonClick = (link, type) => {
    track('Open Share Link', { type });

    if (type === SOCIAL_PLATFORMS_KEYS.link) {
      return handleCopyLinkClick();
    }

    Utils.openInNewTab(link);
  };

  const handleCopyLinkClick = () => {
    setSnackbarText(t('snackbar.copyLinkToClipBoard'));
    Utils.copyToClipboard(playerUrl);
  };

  if (shouldHide) {
    return null;
  }

  return (
    <LayoutRoot {...props}>
      <StyledShareSocialButtons
        publishId={publishId}
        handleButtonClick={handleSocialButtonClick}
        isFeed
        data-type={FEED_SHARE_SOCIAL_BUTTONS_DATA_TYPE}
        iconsFillColor={iconsFillColor}
        shouldHideCopyLink={shouldHideCopyLink}
        onKeyDown={onKeyDown}
        setNextRef={setNextRef}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)``;

const StyledShareSocialButtons = styled(ShareSocialButtons)`
  gap: 12px;
`;

export default FeedShare;
