import React from 'react';
import styled from 'styled-components';
import image from './backgrounds/1.jpg';

const Canvas = () => {
  return <LayoutRoot />;
};

const LayoutRoot = styled.div`
  width: 100%;
  height: 100%;
  background: url(${image}) repeat 0 0;
  background-size: cover;
  background-repeat: round;
`;

export default Canvas;
