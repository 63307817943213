import styled from 'styled-components';
import CustomInput from './Input';

const TextInput = styled(CustomInput)`
  width: 100%;
  .MuiOutlinedInput-input {
    padding: 8px 14px;
  }
`;

export default TextInput;
