export enum SidebarButtonKey {
  home = 'home',
  videos = 'videos',
  onsite = 'onsite',
  shop = 'shop',
  offsite = 'offsite',
  threads = 'threads',
  analytics = 'analytics',
  notifications = 'notifications',
  products = 'products',
  aiTagging = 'aiTagging',
  audience = 'audience',
  aiStudio = 'aiStudio',
  ugc = 'ugc',
}

export const SIDEBAR_BUTTONS_TEXT = {
  [SidebarButtonKey.home]: 'Home',
  [SidebarButtonKey.aiTagging]: 'Inbox',
  [SidebarButtonKey.products]: 'Products',
  [SidebarButtonKey.videos]: 'Library',
  [SidebarButtonKey.onsite]: 'Onsite',
  [SidebarButtonKey.shop]: 'Shop',
  [SidebarButtonKey.offsite]: 'Offsite',
  [SidebarButtonKey.aiStudio]: 'Video Studio',
  [SidebarButtonKey.threads]: 'Threads',
  [SidebarButtonKey.analytics]: 'Analytics',
  [SidebarButtonKey.audience]: 'Audience',
  [SidebarButtonKey.ugc]: 'UGC Search',
};
