import React from 'react';

const CircleCheck = () => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Checked"
    >
      <title>Checked</title>
      <path
        d="M10 1.125C4.805 1.125.625 5.344.625 10.5A9.353 9.353 0 0 0 10 19.875c5.156 0 9.375-4.18 9.375-9.375 0-5.156-4.219-9.375-9.375-9.375Zm4.492 7.656-5 5c-.195.235-.469.313-.742.313-.313 0-.586-.078-.781-.313l-2.5-2.5a1.061 1.061 0 0 1 0-1.523 1.06 1.06 0 0 1 1.523 0l1.758 1.719 4.219-4.22a1.062 1.062 0 0 1 1.523 0c.43.43.43 1.095 0 1.524Z"
        fill="#016545"
      />
    </svg>
  );
};

export default CircleCheck;
