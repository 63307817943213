export const xButtonsSvg = `<svg style="margin-left: 0.5px; width: 11px !important; height: 10px !important;" width="11" height="10" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.0547 12.5938C12.8203 12.8281 12.3906 12.8281 12.1562 12.5938L7 7.39844L1.80469 12.5938C1.57031 12.8281 1.14062 12.8281 0.90625 12.5938C0.671875 12.3594 0.671875 11.9297 0.90625 11.6953L6.10156 6.5L0.90625 1.34375C0.671875 1.10938 0.671875 0.679688 0.90625 0.445312C1.14062 0.210938 1.57031 0.210938 1.80469 0.445312L7 5.64062L12.1562 0.445312C12.3906 0.210938 12.8203 0.210938 13.0547 0.445312C13.2891 0.679688 13.2891 1.10938 13.0547 1.34375L7.85938 6.5L13.0547 11.6953C13.2891 11.9297 13.2891 12.3594 13.0547 12.5938Z" fill="white"/>
</svg>
`;

export const getFeedXButtonSvg = (color = '#FFFFFF') =>
  `<svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.055 16.594a.662.662 0 0 1-.899 0L10 11.398l-5.195 5.196a.662.662 0 0 1-.899 0 .662.662 0 0 1 0-.899L9.102 10.5 3.906 5.344a.662.662 0 0 1 0-.899.662.662 0 0 1 .899 0L10 9.641l5.156-5.196a.662.662 0 0 1 .899 0 .662.662 0 0 1 0 .899L10.859 10.5l5.196 5.195a.662.662 0 0 1 0 .899Z" style="fill: ${color} !important;"
   /></svg>`;

export const getPlayButtonSvg = (color = '#fff', className = '') =>
  `<svg class="${className}" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M4 3.532c0-1.554 1.696-2.514 3.029-1.715l14.113 8.468c1.294.777 1.294 2.653 0 3.43L7.029 22.183c-1.333.8-3.029-.16-3.029-1.715V3.532Z" fill="${color}"/></svg>`;

export const leftArrowSvg = `<svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.797 16.664.648 9.204A1.071 1.071 0 0 1 .375 8.5c0-.195.078-.43.234-.625L7.758.415a.932.932 0 0 1 1.328-.04c.39.352.39.938.039 1.328L2.602 8.5l6.562 6.875a.932.932 0 0 1-.039 1.328.932.932 0 0 1-1.328-.039Z" fill="#090A0B"/></svg>`;

export const rightStraightArrowSvg = `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.804 6.563 6.902 2.661l.619-.619L12.478 7l-4.983 4.983-.619-.619 3.927-3.926H1.96v-.875z" fill="#fff" stroke="#fff"/></svg>`;

export const getPauseSvg = (color = '#fff', className = '') => `
<svg class="${className}" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#pauseIcon)">
    <path
      d="M4.375 2.5c-.633 0-1.125.516-1.125 1.125v6.75A1.11 1.11 0 0 0 4.375 11.5h.75c.61 0 1.125-.492 1.125-1.125v-6.75A1.14 1.14 0 0 0 5.125 2.5h-.75Zm4.5 0c-.633 0-1.125.516-1.125 1.125v6.75A1.11 1.11 0 0 0 8.875 11.5h.75c.61 0 1.125-.492 1.125-1.125v-6.75A1.14 1.14 0 0 0 9.625 2.5h-.75Z"
      fill="#fff"
    />
  </g>
  <defs>
    <clipPath id="pauseIcon">
      <path fill="${color}" d="M3 2.5h8v9H3z" />
    </clipPath>
  </defs>
</svg>
`;
