import React from 'react';

const AllFilterIcon = () => {
  return (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="4.144" cy="8.571" r="3.429" transform="rotate(-180 4.144 8.571)" fill="#FD7830" />
      <circle cx="7" cy="3.429" r="3.429" transform="rotate(-180 7 3.429)" fill="#FA536E" />
      <circle cx="9.858" cy="8.571" r="3.429" transform="rotate(-180 9.858 8.571)" fill="#3D4FE0" />
    </svg>
  );
};

export default AllFilterIcon;
