import React from 'react';

const ListIcon = ({ fill = '#7D8087' }) => {
  return (
    <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.75 4.75h-6A.755.755 0 0 0 5 5.5c0 .422.328.75.75.75h6c.398 0 .75-.328.75-.75a.771.771 0 0 0-.75-.75Zm-6-2.25h6c.398 0 .75-.328.75-.75a.771.771 0 0 0-.75-.75h-6a.755.755 0 0 0-.75.75c0 .422.328.75.75.75Zm6 6h-6a.755.755 0 0 0-.75.75c0 .422.328.75.75.75h6c.398 0 .75-.328.75-.75a.771.771 0 0 0-.75-.75ZM1.437 4.75h1.875a.555.555 0 0 0 .563-.563.57.57 0 0 0-.563-.562h-.374V.812a.539.539 0 0 0-.305-.468.494.494 0 0 0-.563 0l-.75.422a.569.569 0 0 0-.187.773c.14.234.422.352.68.258v1.828h-.375a.555.555 0 0 0-.563.563c0 .328.234.562.563.562Zm2.25 4.898H2.516l.867-.773c.703-.61.773-1.664.187-2.367a1.62 1.62 0 0 0-1.195-.61 1.682 1.682 0 0 0-1.266.399l-.28.258a.584.584 0 0 0-.071.797c.21.234.562.257.797.07l.304-.258a.518.518 0 0 1 .446-.14.46.46 0 0 1 .398.21.543.543 0 0 1-.07.797L.687 9.79c-.187.14-.234.399-.164.61a.585.585 0 0 0 .516.351h2.649a.55.55 0 1 0 0-1.102Z"
        fill={fill}
      />
    </svg>
  );
};

export default ListIcon;
