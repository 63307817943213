import React from 'react';

function NotificationReachedEndIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" viewBox="0 0 40 40">
      <rect width="40" height="40" fill="#C8E6DC" rx="10"></rect>
      <path
        fill="#016545"
        d="M14 27.5V13c0-.531-.469-1-1-1-.563 0-1 .469-1 1v14.5c0 .281.219.5.5.5h1c.25 0 .5-.219.5-.5zM26.875 12c-.219 0-.406.063-.625.156-1.406.656-2.469.875-3.344.875-1.875 0-2.968-1-5.125-1-.781 0-1.687.125-2.781.469v10.969c1-.313 1.844-.438 2.625-.438 2.313 0 3.906.969 6.219.969 1 0 2.125-.188 3.5-.719.406-.156.656-.5.656-.875V12.97c0-.594-.531-.969-1.125-.969z"
      ></path>
    </svg>
  );
}

export default NotificationReachedEndIcon;
