const CUSTOM_FORM_PROJECT_IDS = [
  '7c1e2910-3e12-411d-8763-b85b41278e51',
  'ebc5234a-5e24-4895-a4a8-d75bee73a144',
  '8cb2ae24-65c0-4172-b0f2-a2428ccd2f5e',
  '84492429-fa0a-4015-9b2f-ae444bbe020e',
  '688200c4-3531-471e-9e0e-88c6059814c4',
  'c3e4946a-ee1b-41d3-98b2-e0aa84d6049a',
  '847cada3-1c42-436d-9cd2-1239a58efd8e',
  '39c031b0-f756-4cc4-ab8b-87db6f676b2e',
  '2300d40f-765e-4e83-b3cb-96e9c226dcbb',
];

let customLeadForm;

export const getShouldLoadCustomLeadForm = ({ projectId } = {}) => {
  return CUSTOM_FORM_PROJECT_IDS.includes(projectId);
};

export const loadCustomLeadFormIfNeeded = async ({ projectId }) => {
  if (!getShouldLoadCustomLeadForm({ projectId }) || customLeadForm) {
    return;
  }
  customLeadForm = await import(
    /* webpackChunkName: "custom-form" */ '../customData/customForm.json'
  );
};

export const getCustomFormData = async ({ projectId, clickedAnswerKey }) => {
  if (!projectId || !clickedAnswerKey) {
    return;
  }

  await loadCustomLeadFormIfNeeded({ projectId });

  return customLeadForm?.[projectId]?.[clickedAnswerKey];
};

export const getCustomForm = () => {
  return customLeadForm || {};
};
