import React from 'react';
import styled from 'styled-components';
import IconButton from 'app/src/complex_components/IconButton';
import QuizFlowTrashIcon from 'shared/react/images/quiz/QuizFlowTrashIcon';
import FlowCondition from 'app/src/pages/project/pages/quiz/quiz_result/flow/FlowCondition';
import { useQuizContext } from 'app/src/pages/project/pages/quiz/QuizContext';

function FlowHeader({ quizResultId, flowId }) {
  const { onDeleteFlow } = useQuizContext();

  return (
    <LayoutRoot>
      <FlowCondition />
      <IconButton
        onClick={() => onDeleteFlow(quizResultId, flowId)}
        height="32px"
        width="32px"
        padding="0"
      >
        <QuizFlowTrashIcon />
      </IconButton>
    </LayoutRoot>
  );
}

export default FlowHeader;

const LayoutRoot = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`;
