import React from 'react';
import styled from 'styled-components';

import { TextSmall } from 'shared/react/components/basic/text/TextV2';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import AutomaticInstallationSection from './AutomaticInstallationSection';
import CopyCode from 'src/complex_components/CopyCode';
import { CONVERSION_SCRIPT_HELP_ARTICLE } from 'src/constants/url.constants';
import Utils from 'src/utils';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import ExpanderV2 from 'src/basic_components/ExpanderV2';

type Props = {
  code: string;
  displayCode: string | React.ReactElement;
  automaticSectionDescription: string | React.ReactElement;
  codeSectionDescription: string | React.ReactElement;
  isCodeAddedAutomatically?: boolean;
};

const CodeSection: React.FC<Props> = ({
  code,
  displayCode,
  automaticSectionDescription,
  codeSectionDescription,
  isCodeAddedAutomatically,
}) => {
  const onHelpArticleClick = () => {
    Utils.openInNewTab(CONVERSION_SCRIPT_HELP_ARTICLE);
  };

  return (
    <>
      <AutomaticInstallationSection
        showAutomaticInstallationSection={isCodeAddedAutomatically}
        automaticSectionDescription={automaticSectionDescription}
      />
      <ExpanderV2
        expandedTitle="Hide Code"
        collapsedTitle="Show Code"
        startExpanded={!isCodeAddedAutomatically}
      >
        <Gap16VerticalFlex>
          <Gap8VerticalFlex>
            <TextSmallDark>{codeSectionDescription}</TextSmallDark>
            <CopyCode codeToCopy={code} codeToDisplay={displayCode} />
          </Gap8VerticalFlex>
          <StyledTextSmall>
            We advise you to <LinkSpan onClick={onHelpArticleClick}>read this article</LinkSpan> for
            a thorough explanation.
          </StyledTextSmall>
        </Gap16VerticalFlex>
      </ExpanderV2>
    </>
  );
};

const TextSmallDark = styled(TextSmall)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.neutralDark};
`;

const LinkSpan = styled.span`
  color: ${({ theme }) => theme.colors.blue1};
  cursor: pointer;
`;

const StyledTextSmall = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.neutralDark};
`;

export default CodeSection;
