import React from 'react';

const ExpanderBottomArrow = ({ fill = '#090A0B' }) => {
  return (
    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.875 1.422a.56.56 0 0 0 .023-.797.56.56 0 0 0-.796-.023L5.977 4.539 1.875.602a.56.56 0 0 0-.797.023.56.56 0 0 0 .024.797l4.5 4.312a.62.62 0 0 0 .375.141c.14 0 .28-.047.398-.14l4.5-4.313Z"
        fill={fill}
      />
    </svg>
  );
};

export default ExpanderBottomArrow;
