import React from 'react';
import AnalyticsEmailClicksOvertime from './AnalyticsEmailClicksOvertime';
import AnalyticsEmailOpensOvertime from './AnalyticsEmailOpensOvertime';
import AnalyticsEmailRevenueOvertime from './AnalyticsEmailRevenueOvertime';
import styled from 'styled-components';
import { EmailConversionOvertimeRecord } from 'app/src/services/cube/types/getEmailConvertions.types';
import { EmailEventsOvertimeRecord } from 'app/src/services/cube/types/getEmailEventsOvertimeQuery.types';

type Props = {
  currency: string;
  emailConversionsOvertime: EmailConversionOvertimeRecord[] | null;
  emailEventsOvertime: EmailEventsOvertimeRecord[] | null;
  isLoadingEmailConversionsOvertime: boolean;
  isLoadingEmailEventsOvertime: boolean;
};

const AnalyticsEmailMetricsGrid = ({
  currency,
  emailConversionsOvertime,
  emailEventsOvertime,
  isLoadingEmailConversionsOvertime,
  isLoadingEmailEventsOvertime,
}: Props) => {
  return (
    <LayoutRoot>
      <AnalyticsEmailOpensOvertime
        emailEventsOvertime={emailEventsOvertime}
        isLoadingEmailEventsOvertime={isLoadingEmailEventsOvertime}
      />
      <AnalyticsEmailClicksOvertime
        emailEventsOvertime={emailEventsOvertime}
        isLoadingEmailEventsOvertime={isLoadingEmailEventsOvertime}
      />
      <AnalyticsEmailRevenueOvertime
        currency={currency}
        emailConversionsOvertime={emailConversionsOvertime}
        isLoadingEmailConversionsOvertime={isLoadingEmailConversionsOvertime}
      />
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  display: grid;
  gap: 16px;
  grid-auto-rows: 302px;
  grid-template-columns: repeat(auto-fit, minmax(286px, 1fr));
  max-width: 1600px;
`;

export default AnalyticsEmailMetricsGrid;
