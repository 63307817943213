import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { OutputAssetsService } from 'src/services/assets';
import { useVideoActions, useVideos } from 'app/src/context/VideosStore';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import StandardModalV2 from 'app/src/pages/modals/StandardModalV2';
import { useSnackBarActions } from 'src/context/ui_store/SnackBarStore';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { VIDEO_STATUS } from 'shared/react/constants/video.constants';
import { TextH3Bold, TextSmall } from 'shared/react/components/basic/text/TextV2';
import RadioButtonV2 from 'app/src/basic_components/radio-button/RadioButtonV2';
import EndButtons from 'app/src/complex_components/global_modal/tumbnail-edit/components/EndButtons';
import { EditThumbnailOptions } from 'app/src/complex_components/global_modal/tumbnail-edit/ThumbnaiEdit.constants';
import ThumbnailEditContent from 'app/src/complex_components/global_modal/tumbnail-edit/components/ThumbnailEditContent';
import Utils from 'app/src/utils';
import APP_CONFIG from 'app/src/config/app.config';

const OPTIONS = [
  { key: EditThumbnailOptions.edit, label: 'Select part from video' },
  { key: EditThumbnailOptions.upload, label: 'Upload image' },
];

const ThumbnailEdit = ({ closeModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [thumbnail, setThumbnail] = useState<File | null>(null);
  const [editThumbnailOption, setEditThumbnailOption] = useState(EditThumbnailOptions.edit);
  const { updateVideo, trimVideo } = useVideoActions();
  const { setSnackbar, setErrorSnackbar } = useSnackBarActions();

  const [
    {
      modalProps: { id },
    },
  ] = useModal();
  const [{ video }, { getVideoUrl, getVideoPoster }] = useVideos(id);

  const videoRef = useRef(null);
  const videoSrc = getVideoUrl(video);
  const initialStartTime = JSON.parse(video?.trimSettings || '{}')?.gifStartTime || 0;

  const [startTime, setStartTime] = useState(initialStartTime);

  useEffect(() => {
    if (video?.status !== VIDEO_STATUS.done || !videoRef.current) {
      return;
    }

    videoRef.current.src = getVideoUrl(video);
    videoRef.current?.play().catch(console.log);
  }, [video]);

  const onThumbnailChange = async newStartTime => {
    const trimSettings = JSON.parse(video?.trimSettings || '{}');
    const originalGifStartTime = trimSettings.gifStartTime;

    const isOriginalGifTimeNullAndHasTime =
      newStartTime && Utils.isNullOrUndefined(originalGifStartTime);

    const isTimeDifferent =
      !Utils.isNullOrUndefined(originalGifStartTime) &&
      !Utils.isNullOrUndefined(newStartTime) &&
      originalGifStartTime !== newStartTime;

    if (isOriginalGifTimeNullAndHasTime || isTimeDifferent) {
      const body = { endTime: 10, startTime: newStartTime, key: video.id, type: 'gif' };
      const newVideo = {
        ...video,
        trimSettings: JSON.stringify({ ...trimSettings, gifStartTime: newStartTime }),
      };

      await updateVideo(newVideo);
      await trimVideo(body);
    }

    closeModal();
  };

  const onStartTimeChange = newStartTime => {
    setStartTime(newStartTime);
  };

  const onSave = () => {
    setIsLoading(true);

    if (editThumbnailOption === EditThumbnailOptions.edit) {
      onThumbnailChange(startTime);
      return;
    }

    if (!thumbnail) {
      return;
    }

    const name = `${video.id}_thumbnail`;

    OutputAssetsService.uploadAsset(thumbnail, { id: video.id, name })
      .then(async path => {
        setSnackbar('Thumbnail uploaded successfully');

        await updateVideo({
          id: video.id,
          stockAsset: {
            ...video.stockAsset,
            posterUrl: `${APP_CONFIG.VIDEO_OUTPUT}/${path}`,
          },
        });

        closeModal();
      })
      .catch(() => {
        setIsLoading(false);
        setErrorSnackbar('Error during thumbnail upload');
      });
  };

  return (
    <StyledStandardModal open={true} onClose={closeModal}>
      <LayoutRoot>
        <VideoContainer
          loop
          controls
          muted
          autoPlay
          src={videoSrc}
          ref={videoRef}
          poster={getVideoPoster(video)}
        />
        <RightSide>
          <Header>Edit video thumbnail</Header>
          <Options>
            {OPTIONS.map(({ key, label }) => (
              <Option key={key}>
                <RadioButtonV2
                  onClick={() => setEditThumbnailOption(key)}
                  checked={editThumbnailOption === key}
                />
                <TextSmall>{label}</TextSmall>
              </Option>
            ))}
          </Options>
          <ThumbnailEditContent
            duration={video?.duration}
            editThumbnailOption={editThumbnailOption}
            videoSrc={videoSrc}
            startTime={startTime}
            thumbnail={thumbnail}
            onStartTimeChange={onStartTimeChange}
            onThumbnailUpload={setThumbnail}
          />
          <EndButtons isLoading={isLoading} onSave={onSave} onDiscard={closeModal} />
        </RightSide>
      </LayoutRoot>
    </StyledStandardModal>
  );
};

const StyledStandardModal = styled(StandardModalV2)`
  height: 100%;
`;

const VideoContainer = styled.video`
  width: 300px;
  height: 533px;
  object-fit: contain;
  background: ${({ theme }) => theme.colors.black};

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    display: none;
  }
`;

const LayoutRoot = styled(HorizontalFlex)`
  max-height: 533px;
`;

const RightSide = styled(Gap16VerticalFlex)`
  overflow: hidden;
  padding: 24px 0;
  width: 448px;

  & > * {
    margin: 0 24px;
  }

  &:last-child {
    padding-bottom: 16px;
  }

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 100%;
  }
`;

const Header = styled(TextH3Bold)``;

const Option = styled(HorizontalFlex)`
  align-items: center;
  gap: 12px;
  width: 50%;
`;

const Options = styled(HorizontalFlex)``;

export default ThumbnailEdit;
