import React, { useState, useEffect } from 'react';
import { Grid, CircularProgress, Typography, Button, TextField, Fade } from '@material-ui/core';
import { withRouter, useHistory, useLocation } from 'react-router-dom';

// styles
import useStyles from './styles';

// context
import styled from 'styled-components';
import { navigateToLogin } from '../../utils/navigation.utils';
import { useUser } from 'app/src/context/userStore/UserStore';

function ResetPassword() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [, { resetPasswordSubmit }] = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [passwordValue, setPasswordValue] = useState('');
  const [confirmPasswordValue, setConfirmPasswordValue] = useState('');
  const [successBar, setSuccessBar] = useState(false);
  const [name, setName] = useState(false);
  const [code, setCode] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('confirmation_code') && params.get('user_name')) {
      setCode(params.get('confirmation_code'));
      setName(params.get('user_name'));
    } else {
      navigateToLogin(history);
    }
  }, [location, history]);

  useEffect(() => {
    if (successBar) {
      navigateToLogin(history);
    }
  }, [successBar, history]);

  function changeClick() {
    if (confirmPasswordValue === passwordValue) {
      resetPasswordSubmit(name, code, passwordValue, setIsLoading, setError, setSuccessBar);
    }
  }

  return (
    <Grid container className={classes.formContainer}>
      <div className={classes.formContainer}>
        <Typography variant="h2" className={classes.subGreeting}>
          Change password
        </Typography>
        <TextField
          InputProps={{
            classes: { underline: classes.textFieldUnderline, input: classes.textField },
          }}
          value={passwordValue}
          onChange={e => setPasswordValue(e.target.value)}
          margin="normal"
          placeholder="Password"
          type="password"
          fullWidth
          inputProps={{ maxLength: 50 }}
        />
        <TextField
          InputProps={{
            classes: { underline: classes.textFieldUnderline, input: classes.textField },
          }}
          value={confirmPasswordValue}
          onChange={e => setConfirmPasswordValue(e.target.value)}
          margin="normal"
          placeholder="Confirm Password"
          type="password"
          fullWidth
          inputProps={{ maxLength: 50 }}
        />
        <Fade in={error}>
          <Typography color="secondary" className={classes.errorMessage}>
            {error}
          </Typography>
        </Fade>
        <ActionButtonContainer>
          {isLoading ? (
            <CircularProgress size={26} />
          ) : (
            <Button
              onClick={changeClick}
              disabled={
                confirmPasswordValue.length === 0 ||
                passwordValue.length === 0 ||
                passwordValue.length < 6
              }
              size="large"
              variant="contained"
              color="primary"
              fullWidth
              className={classes.createAccountButton}
            >
              Change Password
            </Button>
          )}
        </ActionButtonContainer>
      </div>
    </Grid>
  );
}

export default withRouter(ResetPassword);

const ActionButtonContainer = styled.div`
  margin-top: 12px;
  width: 100%;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
