import React from 'react';
import styled from 'styled-components';

import useFeedState from 'shared/react/components/complex/context/hooks/useFeedState';
import BackdropButton from 'shared/react/components/complex/BackdropButton';
import ArrowIcon from 'shared/react/images/feed/ArrowIcon';
import useKeyboardNavigation from 'player/src/hooks/useKeyboardNavigation';

const FeedBackButton = ({ className }) => {
  const { onKeyDown, setNextRef } = useKeyboardNavigation();
  const [{ searchControls = {} }] = useFeedState();
  const { isSearchFeedOpen, closeSearchFeed } = searchControls;

  if (!isSearchFeedOpen) {
    return null;
  }

  return (
    <StyledBackdropButton
      onClick={closeSearchFeed}
      className={className}
      role="button"
      aria-label="Back button"
      tabIndex="0"
      onKeyDown={onKeyDown}
      setNextRef={setNextRef}
    >
      <StyledArrowIcon />
    </StyledBackdropButton>
  );
};

const StyledBackdropButton = styled(BackdropButton)`
  position: absolute;
  z-index: 40;
`;

const StyledArrowIcon = styled(ArrowIcon)`
  transform: rotate(-90deg);

  & path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export default FeedBackButton;
