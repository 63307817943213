import React from 'react';
import styled from 'styled-components';
import EditorHeader from '../shared/EditorHeader';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import EditorMainContainer from '../shared/EditorMainContainer';
import EditorButtonGroupItem from '../shared/EditorButtonGroupItem';
import {
  STORIES_ITEMS_SIZE_TYPES,
  STORIES_LAYOUT_ITEMS_SIZE_TYPE_PROPERTY_KEY,
  STORIES_LAYOUT_ITEMS_PER_ROW_PROPERTY_KEY,
  STORIES_LAYOUT_ITEMS_SPACING_PROPERTY_KEY,
  STORIES_RESPONSIVE_TOOLTIP,
  STORIES_MIN_SPACING,
  STORIES_MAX_SPACING,
  STORIES_MIN_ITEMS_PER_ROW,
  STORIES_MAX_ITEMS_PER_ROW,
  MINIMUM_NUMBER_OF_TILES_PROPERTY_KEY,
  STORIES_LAYOUT_ITEMS_SHAPE_PROPERTY_KEY,
  STORIES_ITEMS_SHAPE_TYPES,
  STORIES_BORDER_RADIUS_PROPERTY_KEY,
  STORIES_DEFAULT_CIRCLE_TYPE_BORDER_RADIUS,
  STORIES_DEFAULT_RECTANGULAR_TYPE_BORDER_RADIUS,
} from '../../../LookAndFeelPage.constants';
import Utils from 'shared/react/utils/utils';
import { useLookAndFeelContext } from '../../../LookAndFeelContext';
import EditorSelect from '../shared/EditorSelect';
import EditorNumericInputItem from '../shared/EditorNumericInputItem';
import Separator from 'shared/react/components/basic/Separator';
import InfoIconWithTooltip from 'app/src/complex_components/InfoIconWithTooltip';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import MinimumNumberOfVideos from 'src/pages/project/pages/look_and_feel/editor/editors/shared/MinimumNumberOfVideos';
import FeedStoriesLayoutSizeEditor from 'app/src/pages/project/pages/look_and_feel/editor/editors/feed_stories_editor/FeedStoriesLayoutSizeEditor';

const itemsPerRowRange = Utils.range(STORIES_MIN_ITEMS_PER_ROW, STORIES_MAX_ITEMS_PER_ROW);

const FeedStoriesLayoutEditor = () => {
  const {
    customizationSettings: {
      widgetSettings: {
        storiesItemsSizeType: itemsSizeType,
        storiesItemsPerRow: responsiveItemsPerRow,
        storiesItemsSpacing: itemsSpacing,
        storiesItemsShape: itemsShape,
        minimumNumberOfTiles,
      },
    },
    setWidgetSettingsProperty,
  } = useLookAndFeelContext();

  const isResponsiveSizeType = itemsSizeType === STORIES_ITEMS_SIZE_TYPES.responsive;
  const isFixedSizeType = itemsSizeType === STORIES_ITEMS_SIZE_TYPES.fixed;

  const onItemsPerRowChange = ({ id }) => {
    setWidgetSettingsProperty(STORIES_LAYOUT_ITEMS_PER_ROW_PROPERTY_KEY, id);
  };

  const onMinimumNumberOfTilesChange = value => {
    setWidgetSettingsProperty(MINIMUM_NUMBER_OF_TILES_PROPERTY_KEY, value);
  };

  const onItemsSizeTypeClick = itemsSizeType => {
    setWidgetSettingsProperty(STORIES_LAYOUT_ITEMS_SIZE_TYPE_PROPERTY_KEY, itemsSizeType);
  };

  const onItemShapeClick = itemsShape => {
    if (itemsShape === STORIES_ITEMS_SHAPE_TYPES.circle) {
      setWidgetSettingsProperty(
        STORIES_BORDER_RADIUS_PROPERTY_KEY,
        STORIES_DEFAULT_CIRCLE_TYPE_BORDER_RADIUS
      );
    } else {
      setWidgetSettingsProperty(
        STORIES_BORDER_RADIUS_PROPERTY_KEY,
        STORIES_DEFAULT_RECTANGULAR_TYPE_BORDER_RADIUS
      );
    }

    setWidgetSettingsProperty(STORIES_LAYOUT_ITEMS_SHAPE_PROPERTY_KEY, itemsShape);
  };

  const onSpacingChange = value => {
    setWidgetSettingsProperty(STORIES_LAYOUT_ITEMS_SPACING_PROPERTY_KEY, value);
  };

  const onSpacingBlur = value => {
    const validatedValue = Math.min(Math.max(value, STORIES_MIN_SPACING), STORIES_MAX_SPACING);
    onSpacingChange(validatedValue);
  };

  const borderTypeButtons = [
    {
      text: (
        <InfoTextContainer>
          <CapitalizedText>{STORIES_ITEMS_SIZE_TYPES.responsive}</CapitalizedText>
          <InfoIconWithTooltip title={STORIES_RESPONSIVE_TOOLTIP} tooltipPlacement="top" />
        </InfoTextContainer>
      ),
      isEnabled: isResponsiveSizeType,
      onClick: () => onItemsSizeTypeClick(STORIES_ITEMS_SIZE_TYPES.responsive),
    },
    {
      text: <CapitalizedText>{STORIES_ITEMS_SIZE_TYPES.fixed}</CapitalizedText>,
      isEnabled: isFixedSizeType,
      onClick: () => onItemsSizeTypeClick(STORIES_ITEMS_SIZE_TYPES.fixed),
    },
  ];

  const shapeButtons = [
    {
      text: <CapitalizedText>{STORIES_ITEMS_SHAPE_TYPES.rectangle}</CapitalizedText>,
      onClick: () => onItemShapeClick(STORIES_ITEMS_SHAPE_TYPES.rectangle),
      isEnabled: itemsShape === STORIES_ITEMS_SHAPE_TYPES.rectangle,
    },
    {
      text: <CapitalizedText>{STORIES_ITEMS_SHAPE_TYPES.circle}</CapitalizedText>,
      onClick: () => onItemShapeClick(STORIES_ITEMS_SHAPE_TYPES.circle),
      isEnabled: itemsShape === STORIES_ITEMS_SHAPE_TYPES.circle,
    },
  ];

  return (
    <LayoutRoot>
      <EditorHeader title="Layout" />
      <EditorMainContainer>
        <MinimumNumberOfVideos
          value={minimumNumberOfTiles}
          onChange={onMinimumNumberOfTilesChange}
        />
        <EditorButtonGroupItem text="Items size" buttons={borderTypeButtons} />
        <EditorButtonGroupItem text="Items shape" buttons={shapeButtons} />

        {isResponsiveSizeType && (
          <SmallerEditorSelect
            onChange={onItemsPerRowChange}
            text="Items per row"
            value={responsiveItemsPerRow}
            items={itemsPerRowRange}
          />
        )}
        <FeedStoriesLayoutSizeEditor />
        <Separator />
        <EditorNumericInputItem
          text="Spacing"
          suffix="px"
          onChange={onSpacingChange}
          onBlur={onSpacingBlur}
          value={itemsSpacing}
        />
      </EditorMainContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

const SmallerEditorSelect = styled(EditorSelect)`
  max-width: 80px;
`;

const InfoTextContainer = styled(Gap8HorizontalFlex)`
  align-items: center;
`;

const CapitalizedText = styled.span`
  text-transform: capitalize;
`;

export default FeedStoriesLayoutEditor;
