import React from 'react';
import styled from 'styled-components';
import { useAnalytics } from 'app/src/context/AnalyticsStore';
import AnalyticsTable from '../AnalyticsTable';
import AnalyticsTolstoyLink from '../AnalyticsTolstoyLink';

const numberComparator = (v1, v2) => {
  return +v1 - +v2;
};

const getColumns = () => {
  return [
    {
      field: 'tolstoy',
      headerName: 'Tolstoy',
      flex: 1,
      renderCell: AnalyticsTolstoyLink,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
    },
    {
      field: 'clickCta',
      headerName: 'Click Cta',
      flex: 1,
      sortComparator: numberComparator,
    },
  ];
};

const getRows = resultSet => {
  if (!resultSet) {
    return [];
  }
  const tableData = resultSet?.tablePivot();
  const rows = tableData.map((data, id) => {
    return {
      id,
      tolstoy: data['PlayerUserLogEmails.publishid'],
      email: data['PlayerUserLogEmails.email'],
      date: data['PlayerUserLogEmails.createdat'].split('T')[0],
      clickCta: data['PlayerUserLogEmails.clickCta'],
    };
  });
  return rows;
};

const AnalyticsEngagementEmailCollectedTable = () => {
  const [{ engagementEmailCollected }] = useAnalytics();

  const rows = getRows(engagementEmailCollected);

  if (!rows?.length) {
    return null;
  }

  return (
    <StyledAccountAnalyticsEmailCollectedTable>
      <AnalyticsTable rows={rows} columns={getColumns()} title="Email collected" />
    </StyledAccountAnalyticsEmailCollectedTable>
  );
};

export default AnalyticsEngagementEmailCollectedTable;

const StyledAccountAnalyticsEmailCollectedTable = styled.div`
  grid-area: email;
`;
