import React, { useState } from 'react';
import styled from 'styled-components';
import {
  ANSWER_NEXT_WITH_VALUE,
  ANSWER_TYPES_WITH_VALUE,
  CALENDLY_RESPONSE_KEY,
  CHAT_PLATFORMS,
  CHAT_RESPONSE_KEY,
  END_STEP,
  LINK_NEXT_KEY,
  PROMO_CODE_RESPONSE_KEY,
} from 'app/src/constants/tolstoy.constants';
import Menu from 'shared/react/components/basic/Menu';
import Separator from 'shared/react/components/basic/Separator';
import MenuItem from 'app/src/complex_components/MenuItemV2';
import EndNextStepIcon from 'app/src/images/edit_step_icons/EndNextStepIcon';
import LinkIcon from 'shared/react/images/share/LinkIcon';
import GoToNextAnswerIcon from 'app/src/images/GoToNextAnswerIcon';
import ChatIcon from 'app/src/images/edit_step_icons/ChatIcon';
import OtherMenuItem from 'app/src/pages/project/pages/project-edit/components/project-content/components/builder/edit_step/edit_buttons/OtherMenuItem';
import MenuItemInput from 'app/src/basic_components/MenuItemInput';
import { INPUT_METHODS } from 'app/src/constants/creation_method.constants';
import SearchIcon from 'app/src/images/Search';
import { useUser } from 'app/src/context/userStore/UserStore';
import Utils from 'app/src/utils';
import { NEXT, PRODUCTS_RESULT } from 'app/src/constants/editStep.constants';
import { SAME_TAB_SUFFIX } from 'shared/react/constants/playerSettings.constants';

const ownerIdsThatCanTargetAnswerToItself = [
  'Google_104625588250404172490',
  '2161741d-2af0-4d65-8edf-6fc50bf7fe16',
];

const MENU_ITEM_NAMES = {
  [CALENDLY_RESPONSE_KEY]: 'Calendar',
  [PROMO_CODE_RESPONSE_KEY]: 'Promo code',
};

const addSameTabSuffix = str => `${str}${SAME_TAB_SUFFIX}`;

const NextMenuItems = ({
  anchorEl,
  setAnchorEl,
  onModalPropertyChanged,
  answer,
  project,
  step,
  reply,
  ...props
}) => {
  const [inputState, setInputState] = useState('');
  const [error, setError] = useState(false);
  const [wasValueErrorShown, setWasValueErrorShown] = useState(false);
  const [originalAnswer, setOriginalAnswer] = useState(null);
  const disabled = ANSWER_TYPES_WITH_VALUE.includes(answer?.type);
  const [{ user }] = useUser();

  const handleRollbackToOriginalAnswer = () => {
    if (!ANSWER_NEXT_WITH_VALUE.includes(answer.next)) {
      return;
    }

    onModalPropertyChanged(originalAnswer?.next || END_STEP, NEXT);
    onModalPropertyChanged(originalAnswer?.value || '', 'value');
    setError(false);
    setWasValueErrorShown(false);
  };

  const handleInvalidValue = () => {
    if (answer.next === LINK_NEXT_KEY) {
      setWasValueErrorShown(true);
    }

    setError(true);
    return true;
  };

  const canCloseMenu = () => {
    const cantClose = ANSWER_NEXT_WITH_VALUE.includes(answer.next) && !answer.value;
    const isValidUrl = Utils.isValidUrl(answer.value);

    if (((answer.next === LINK_NEXT_KEY && !isValidUrl) || cantClose) && !wasValueErrorShown) {
      return handleInvalidValue();
    }

    if (wasValueErrorShown) {
      handleRollbackToOriginalAnswer();
    }

    return false;
  };

  const onAnswerTypeClick = (key, closeAfterClick) => {
    if (key === answer.next) {
      return;
    }

    setError(false);
    setOriginalAnswer({ ...answer });
    onModalPropertyChanged(key, NEXT);

    if (closeAfterClick) {
      onClose();
    }
  };

  const onAnswerValueChange = value => {
    setError(false);
    onModalPropertyChanged(value, 'value');
  };

  const onAnswerCheckboxChange = isChecked => {
    const newNext = isChecked
      ? addSameTabSuffix(answer?.next)
      : answer?.next.replace(SAME_TAB_SUFFIX, '');
    onAnswerTypeClick(newNext, false);
  };

  const getSteps = items => {
    project.steps.items.forEach(({ name, description, type }, i) => {
      if (name === step.name && !ownerIdsThatCanTargetAnswerToItself.includes(user.owner)) {
        return;
      }

      if (type === PRODUCTS_RESULT) {
        return;
      }

      const title = `Part ${i + 1}${description ? ` - ${description}` : ''}`;
      if (!title.toLowerCase().includes(inputState.toLowerCase())) {
        return;
      }

      items.push({
        key: name,
        title: title,
        icon: <GoToNextAnswerIcon />,
      });
    });
  };

  const onClose = () => {
    setInputState('');
    setAnchorEl(null);
  };

  if (!anchorEl) {
    return null;
  }

  const shouldShowPartsMenu = project.stepsOrder.length >= 4;

  const addStepsMenuItems = (partItems, items) => {
    if (project.stepsOrder <= 1) {
      return;
    }

    if (shouldShowPartsMenu) {
      getSteps(partItems);
      return;
    }

    getSteps(items);
  };

  const getMenuItems = () => {
    const items = [];
    const partItems = [];

    addStepsMenuItems(partItems, items);

    items.push(
      {
        key: END_STEP,
        title: 'End',
        icon: <EndNextStepIcon />,
      },
      {
        key: LINK_NEXT_KEY,
        title: 'Link',
        icon: <LinkIcon />,
        inputType: INPUT_METHODS.text,
        inputPlaceHolder: 'Link',
        checkBoxTitle: 'Open in the same tab',
        disabled,
        closeAfterClick: false,
        disabledTooltipText: `Chat cannot be used with ${
          MENU_ITEM_NAMES[answer?.type]
        } button action`,
        errorMessage: 'Please enter a valid URL',
      }
    );

    const otherMenuItems = [
      {
        key: CHAT_RESPONSE_KEY,
        title: 'Chat',
        icon: <ChatIcon />,
        inputType: 'select',
        inputPlaceHolder: 'Select chat platform',
        closeAfterClick: false,
        options: CHAT_PLATFORMS,
        disabled,
        disabledTooltipText: `Chat cannot be used with ${
          MENU_ITEM_NAMES[answer?.type]
        } button action`,
      },
    ];

    return { items, partItems, otherMenuItems };
  };

  const { items, partItems, otherMenuItems } = getMenuItems();

  return (
    <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={onClose} canCloseMenu={canCloseMenu}>
      <Content {...props}>
        <Header>Where to?</Header>
        <Separator />
        {shouldShowPartsMenu && (
          <OtherMenuItem
            otherMenuItems={partItems}
            answer={answer}
            anchorEl={anchorEl}
            value={answer.value}
            onAnswerTypeClick={onAnswerTypeClick}
            onAnswerValueChange={onAnswerValueChange}
            inputState={inputState}
            error={error}
            customHeader={
              <InputContainer>
                <Input
                  placeholder="Search"
                  value={inputState}
                  onChange={e => setInputState(e.target.value)}
                />
                <InputIconContainer>
                  <InputIcon>
                    <SearchIcon />
                  </InputIcon>
                </InputIconContainer>
              </InputContainer>
            }
            headerName="Parts"
          />
        )}
        {items.map(({ key, ...props }) => {
          const isSelected = answer.next === key || answer.next === addSameTabSuffix(key);
          return (
            <MenuItem
              onClick={closeAfterClick => onAnswerTypeClick(key, closeAfterClick)}
              key={key}
              isSelected={isSelected}
              value={answer?.value}
              onAnswerValueChange={onAnswerValueChange}
              onAnswerCheckboxChange={onAnswerCheckboxChange}
              error={error}
              checkboxValue={answer?.next.includes(SAME_TAB_SUFFIX)}
              {...props}
            />
          );
        })}
        <OtherMenuItem
          otherMenuItems={otherMenuItems}
          answer={answer}
          anchorEl={anchorEl}
          error={error}
          value={answer.value}
          onAnswerTypeClick={onAnswerTypeClick}
          onAnswerValueChange={onAnswerValueChange}
        />
      </Content>
    </Menu>
  );
};

const Content = styled.div`
  min-width: 256px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  position: relative;
`;

const Header = styled.div`
  color: ${({ theme }) => theme.colors.gray25};
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding: 20px 0 8px 20px;
`;

const InputContainer = styled.div`
  padding: 8px;
  display: grid;
`;

const Input = styled(MenuItemInput)`
  width: 100%;
  grid-column: 1;
  grid-row: 1;
  z-index: 1;
  padding-right: 26px;
`;

const InputIcon = styled.div`
  z-index: 1;
  line-height: 0;
`;

const InputIconContainer = styled.div`
  width: 100%;
  grid-column: 1;
  grid-row: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 14px;
`;

export default NextMenuItems;
