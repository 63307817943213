export const createElement = ({
  tagName,
  src,
  container,
  attributes,
  style,
  styleString,
  classNames,
  eventListeners
}) => {
  const element = document.createElement(tagName);

  if (eventListeners) {
    for (const [event, callback] of Object.entries(eventListeners)) {
      element.addEventListener(event, callback);
    }
  }

  if (src) {
    element.src = src;
  }

  if (attributes) {
    for (const [key, value] of Object.entries(attributes)) {
      element.setAttribute(key, value);
    }
  }

  // Backward compatibility, use style instead of styleString
  if (styleString) {
    element.style.cssText = styleString;
  }

  if (style) {
    for (const [key, value] of Object.entries(style)) {
      element.style[key] = value;
    }
  }

  if (classNames) {
    element.classList.add(...classNames);
  }

  if (container) {
    container.append(element);
  }

  return element;
};

export const createScript = ({ src, container, attributes }) => {
  return createElement({ tagName: 'script', src, container, attributes });
};

export const createIframe = ({ src, container, attributes, style }) => {
  return createElement({ tagName: 'iframe', src, container, attributes, style });
};

const registerMutationObserver = ({ callback, classNames }) => {
  const getIsNodeSubTreeContainClasses = node => {
    return classNames.some(
      // eslint-disable-next-line unicorn/prefer-query-selector
      className => node.getElementsByClassName(className).length > 0
    );
  };

  const getIsNodeContainClass = nodeClass => {
    return classNames.includes(nodeClass);
  };

  const getIsNodeContainClasses = node => {
    const nodeClasses = [...node.classList];
    // eslint-disable-next-line unicorn/no-array-callback-reference
    return nodeClasses.some(getIsNodeContainClass);
  };

  const getIsTolstoyNode = node => {
    if (!node.classList) {
      return false;
    }

    return getIsNodeContainClasses(node) || getIsNodeSubTreeContainClasses(node);
  };

  const getIsMutation = mutation => {
    if (mutation.type !== 'childList' || mutation.addedNodes.length === 0) {
      return false;
    }

    const newNodes = [...mutation.addedNodes];
    // eslint-disable-next-line unicorn/no-array-callback-reference
    return newNodes.some(getIsTolstoyNode);
  };

  const observerCallback = mutations => {
    // eslint-disable-next-line unicorn/no-array-callback-reference
    if (mutations.some(getIsMutation)) {
      callback();
    }
  };

  const observer = new window.MutationObserver(observerCallback);

  observer.observe(document.body, { subtree: true, childList: true });
};

const registerLoadEventsListener = ({ callback }) => {
  window.addEventListener('DOMContentLoaded', callback);
  window.addEventListener('load', callback);
};

export const registerDomUpdatesListener = ({ callback, classNames }) => {
  registerLoadEventsListener({ callback });

  if ('MutationObserver' in window) {
    registerMutationObserver({ callback, classNames });
  }
};

export const safePlayVideo = ({ video, onError }) => {
  return video?.play().catch(onError);
};

export const stringToHTML = htmlString => {
  const div = document.createElement('div');

  div.innerHTML = htmlString.trim();

  return div.firstChild;
};

export const getPublicId = (className, publishId) => {
  return `${className}-${publishId}`;
};

export const getElementSelector = (type, publishId) => {
  return `[data-tolstoy-element="${getPublicId(type, publishId)}"]`;
};

export const getElement = (type, publishId) => {
  return window.document.querySelector(getElementSelector(type, publishId));
};

export const getAllElements = (type, publishId) => {
  return window.document.querySelectorAll(
    `[data-tolstoy-element="${getPublicId(type, publishId)}"]`
  );
};

export const getCustomElementSelector = (key, value) => {
  return `[${key}="${value}"]`;
};

export const getCustomElement = (key, value) => {
  return window.document.querySelector(`[${key}="${value}"]`);
};
