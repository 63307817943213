import React from 'react';
import styled from 'styled-components';

import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { vodAsset } from 'app/src/types/entities';
import { useVideoActions } from 'src/context/VideosStore';
import { STEP_TYPE } from 'src/pages/project/pages/hero/constants';
import DesktopIcon from 'src/images/dashboard_v2/DesktopIcon';
import MobileIcon from 'src/images/dashboard_v2/MobileIcon';
import { TextBodyLeading } from 'shared/react/components/complex/Text';
import CardImage from 'src/pages/dashboard/components/card/CardImage';

export const STEP_CONFIG = {
  [STEP_TYPE.desktop]: {
    stepName: 'Desktop',
    ratio: '16:9',
    icon: <DesktopIcon />,
  },
  [STEP_TYPE.mobile]: {
    stepName: 'Mobile',
    ratio: '3:4',
    icon: <MobileIcon />,
  },
};

type StepConfigType = (typeof STEP_CONFIG)[keyof typeof STEP_CONFIG];

type Props = {
  video: vodAsset;
  stepConfig: StepConfigType;
};

const HeroStepContent = ({ video, stepConfig }: Props) => {
  const { getVideoPoster } = useVideoActions();

  if (!video) {
    return (
      <EmptyStateWrapper>
        {stepConfig.icon}
        <StepName>{stepConfig.stepName}</StepName>
        <RatioText>{stepConfig.ratio}</RatioText>
      </EmptyStateWrapper>
    );
  }

  return (
    <>
      <VideoPoster imageSrc={getVideoPoster(video)} videoStatus={video.status} />
      <VideoName className="notranslate">{video.name}</VideoName>
    </>
  );
};

const VideoPoster = styled(CardImage)`
  position: static;
  width: auto;
  z-index: 1;
`;

const EmptyStateWrapper = styled(Gap8HorizontalFlex)`
  align-items: center;
  z-index: 1;

  & svg {
    width: 32px;
    height: 32px;
    margin-left: 24px;
    margin-right: 8px;
  }

  & path {
    fill: ${({ theme }) => theme.colors.neutralBlack};
  }
`;

const VideoName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 1;
`;

const StepName = styled(TextBodyLeading)`
  font-weight: 500;
`;

const RatioText = styled(StepName)`
  color: ${({ theme }) => theme.colors.neutralGray};
`;

export default HeroStepContent;
