import React from 'react';
import styled from 'styled-components';

import Tooltip from 'app/src/basic_components/tooltip-v2/Tooltip';
import { track } from 'src/helpers/Tracker';
import AiBadgeIcon from 'app/src/images/dashboard_v2/AiBadgeIcon';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import Utils from 'app/src/utils';

type Props = { isHidden: boolean };

const AiVideoTag = ({ isHidden }: Props) => {
  const onAiTagClick = e => {
    e.stopPropagation();

    track('Ai Video Tag Click');

    Utils.navigateToStudio();
  };

  if (isHidden) {
    return null;
  }

  return (
    <TagContainer onClick={onAiTagClick}>
      <Tooltip text="Video created from Studio. Click to create more">
        <AiBadgeIcon />
      </Tooltip>
    </TagContainer>
  );
};

const TagContainer = styled(HorizontalFlex)``;

export default AiVideoTag;
