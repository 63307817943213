import React from 'react';

const BottomDirectionIcon = () => {
  return (
    <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.844 11.563H.656c-.383 0-.656.3-.656.656 0 .383.273.656.656.656h9.188a.648.648 0 0 0 .656-.656c0-.356-.3-.656-.656-.656ZM8.258 5.436 5.906 7.954V1.31c0-.383-.3-.684-.656-.684-.383 0-.656.3-.656.684v6.644l-2.38-2.516a.64.64 0 0 0-.464-.19.742.742 0 0 0-.465.163c-.273.246-.273.656-.027.93l3.5 3.719a.66.66 0 0 0 .957 0l3.5-3.72c.246-.273.246-.683-.027-.929-.274-.246-.684-.246-.93.027Z"
        fill="#50545E"
      />
    </svg>
  );
};

export default BottomDirectionIcon;
