import React from 'react';
import styled from 'styled-components';
import { JourneyDrawerSummaryStep } from '../journey-drawer-content/journey-drawer-step/types/JourneyDrawerStep.types';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import Separator from 'shared/react/components/basic/Separator';
import { JOURNEY_DRAWER_SUMMARY_STEP_SETTINGS } from './constants/journeyDrawerSummary.constants';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import Utils from 'src/utils';

type Props = {
  journeySummary: JourneyDrawerSummaryStep[];
};

const formatValue = (value: number | boolean) => {
  return typeof value === 'number' ? `${value} ` : '';
};

const JourneyDrawerSummary = ({ journeySummary }: Props) => {
  return (
    <LayoutRoot>
      {journeySummary.map(({ key, value }) => {
        if (!value) {
          return null;
        }

        const { Icon, label } = JOURNEY_DRAWER_SUMMARY_STEP_SETTINGS[key];

        return (
          <React.Fragment key={key}>
            <StepContainer>
              <Icon />
              <StyledTextTiny>
                {formatValue(value)}
                {Utils.formatPluralText(label, value)}
              </StyledTextTiny>
            </StepContainer>
            <StyledSeparator />
          </React.Fragment>
        );
      })}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlex)`
  gap: 6px;
`;

const StyledSeparator = styled(Separator)`
  width: 1px;
  height: 100%;

  &:last-child {
    display: none;
  }
`;

const StepContainer = styled(HorizontalFlex)`
  gap: 4px;
  align-items: center;

  & > svg {
    & path {
      fill: ${({ theme }) => theme.colors.neutralDark};
    }
  }
`;

const StyledTextTiny = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralDark};
`;

export default JourneyDrawerSummary;
