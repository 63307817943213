import styled from 'styled-components';
import React from 'react';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextSmall } from 'shared/react/components/complex/Text';

type Button = {
  text?: string;
  onClick: () => void;
  isEnabled: boolean;
  dataTestId?: string;
  icon?: React.ReactNode;
  hidden?: boolean;
};

type Props = {
  buttons: Button[];
  defaultBackground?: string;
  disabled?: boolean;
  height?: string;
  width?: string;
};

export const ButtonGroupV2 = ({
  buttons,
  defaultBackground,
  disabled,
  height,
  width,
  ...props
}: Props) => {
  if (!buttons.length) {
    return null;
  }
  const getContent = (icon, text, isEnabled) => {
    if (icon) {
      return icon;
    }

    return <ButtonText isEnabled={isEnabled}>{text}</ButtonText>;
  };

  return (
    <Container {...props}>
      {buttons.flatMap(({ text, onClick, isEnabled, dataTestId, icon, hidden }, i) => {
        if (hidden) {
          return [];
        }

        return (
          <ButtonContainer
            key={`buttonGroup-${i}`}
            isLastButton={i === buttons.length - 1}
            isFirstButton={i === 0}
            disabled={disabled}
            onClick={disabled || isEnabled ? () => {} : onClick}
            isEnabled={isEnabled}
            height={height}
            width={width}
            data-test-id={dataTestId}
            defaultBackground={defaultBackground}
          >
            {getContent(icon, text, isEnabled)}
          </ButtonContainer>
        );
      })}
    </Container>
  );
};

const Container = styled(HorizontalFlex)`
  background: ${({ theme }) => theme.colors.neutralLighter};
  border-radius: 7px;
  overflow: hidden;
  padding: 1px;
`;

const ButtonContainer = styled(HorizontalFlex)<{
  isEnabled: boolean;
  height: string;
  width: string;
  disabled: boolean;
  defaultBackground: string;
}>`
  padding: 10px 16px;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  height: ${({ height }) => (height ? height : 'unset')};
  width: ${({ width }) => (width ? width : 'unset')};
  filter: ${({ disabled }) => (disabled ? 'grayScale(0.8)' : 'unset')};
  cursor: ${({ disabled, isEnabled }) => (disabled || isEnabled ? 'default' : 'pointer')};

  border-radius: ${({ isEnabled }) => (isEnabled ? '6px' : '0')};
  box-shadow: ${({ isEnabled }) =>
    isEnabled ? '0px 5px 15px rgba(50, 50, 93, 0.1), 0px 2px 5px rgba(0, 0, 0, 0.04);' : ''};

  background: ${({ theme, isEnabled, defaultBackground = theme.colors.neutralLighter }) => {
    return isEnabled ? theme.colors.white : defaultBackground;
  }};

  &:hover {
    background: ${({ theme, isEnabled }) => (isEnabled ? '' : theme.colors.gray3)};
  }

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    padding: 8px;
  }
`;

const ButtonText = styled(TextSmall)<{ isEnabled: boolean }>`
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  white-space: pre;

  color: ${({ theme, isEnabled }) =>
    isEnabled ? theme.colors.neutralBlack : theme.colors.neutralDark};
`;

export default ButtonGroupV2;
