import React from 'react';
import styled from 'styled-components';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import { BADGE_Z_INDEX } from 'app/src/constants/zIndex.constants';

const Badge = ({ children, ...props }) => {
  return (
    <LayoutRoot>
      <BadgeContainer {...props}>{children}</BadgeContainer>
    </LayoutRoot>
  );
};

export default Badge;

const LayoutRoot = styled.div`
  display: flex;
  position: fixed;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 24px;
  z-index: ${BADGE_Z_INDEX};
`;

const BadgeContainer = styled(HorizontalFlexWrap)`
  gap: 8px;
  align-items: center;
  padding: 8px 8px 8px 16px;
  background: ${({ theme }) => theme.colors.black2};
  border-radius: 58px;
`;
