import { PRODUCTS_RESULT } from 'app/src/constants/editStep.constants';
import {
  DEFAULT_EMAIL_SETTINGS,
  DEFAULT_STORIES_CONFIG,
  SHOP_APP_TARGET,
} from 'app/src/constants/project.constants';
import AppUtils from 'app/src/utils';
import Utils from 'app/src/utils';
import { project as Project, publishMethodOptions } from 'app/src/types/entities';
import {
  RawProjectEmailSettings,
  ProjectEmailSettings,
} from 'app/src/types/project-email-settings';
import { ONSITE_TARGET_PAGES } from 'app/src/pages/dashboard/constants/onsiteTargetPages.constants';
import { CAMPAIGN_TILE_ACTIONS } from 'app/src/constants/campaignTileActions.constants';

export const getSortedProjectSteps = (projectSteps, stepsOrder) => {
  return [...projectSteps].sort((a, b) => {
    if (a.emptyState || b.emptyState) {
      return 1;
    }

    return stepsOrder.indexOf(a.name) - stepsOrder.indexOf(b.name);
  });
};

export const getSteps = (project, stepsOrder) => {
  const steps = project?.steps?.items || [];
  const isQuiz = AppUtils.isQuiz(project.tolstoyType, 2);
  const filteredSteps =
    steps.filter(step => {
      if (!isQuiz && step.type === PRODUCTS_RESULT) {
        return false;
      }

      return !step.disabled;
    }) || [];

  return getSortedProjectSteps(filteredSteps, stepsOrder || project.stepsOrder);
};

export const getProjectDefaultSettings = ({ publishMethod }) => {
  if (publishMethod !== 'stories') {
    return {};
  }

  return DEFAULT_STORIES_CONFIG;
};

export const getDefaultCampaignTileAction = (project: Project) => {
  switch (project?.targetPage) {
    case SHOP_APP_TARGET.productPage:
      return CAMPAIGN_TILE_ACTIONS.openProductPage;
    case SHOP_APP_TARGET.homePage:
      return CAMPAIGN_TILE_ACTIONS.openHomePage;
    default:
      return CAMPAIGN_TILE_ACTIONS.openTolstoyTvPage;
  }
};

export const getProjectEmailSettings = (project: Project) => {
  const settings = Utils.safeParse(project?.emailSettings) as RawProjectEmailSettings;

  return {
    tileAction: getDefaultCampaignTileAction(project),
    ...DEFAULT_EMAIL_SETTINGS,
    ...settings,
  } as ProjectEmailSettings;
};

export const getIsDerivedProject = (project: Project) => {
  return Boolean(project?.sourceProjectId && project?.sourceProjectId !== project?.id);
};

export const getLiveDynamicPDPProjects = (projects: Project[]) => {
  if (!projects) {
    return [];
  }

  return projects.filter(
    ({ live, feed, dynamic, targetPage, publishMethod }) =>
      live &&
      feed &&
      dynamic &&
      targetPage === ONSITE_TARGET_PAGES.ProductPages &&
      publishMethod !== publishMethodOptions.email
  );
};
