import React, { useEffect, useRef, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import { TextBodyLeading, TextH3 } from 'shared/react/components/complex/Text';
import { useHistory } from 'react-router-dom';
import { nanoid } from 'nanoid';
import Utils from '../../utils';
import { navigateAndSaveOrigin } from '../../utils/navigation.utils';
import { useVideos } from 'app/src/context/VideosStore';
import { useProjects } from 'app/src/context/ProjectsStore';
import { v4 as uuidv4 } from 'uuid';
import { getSignedUrl } from '../../helpers/S3Upload';
import {
  EXTENSION_EVENT_KEY,
  GET_RECORDING_DATA,
  GET_SIGN_URL,
  UPLOAD_FAIL,
  UPLOAD_FINISH,
} from 'app/src/constants/extension.constants';
import {
  sendTolstoyCreated,
  sendIsUploadFinished,
  sendShouldGetSignUrlEvent,
  sendRecordingDataProcessedEvent,
  sendUploadSignUrlEvent,
} from '../../helpers/Extension';
import { SCREEN_AND_CAMERA, SCREEN_RECORDING } from 'app/src/constants/recordingTypes.constants';
import { identify } from '../../helpers/Widget';
import { identify as analyticsIdentify } from '../../helpers/Tracker';
import { useUser } from 'app/src/context/userStore/UserStore';
import { EDIT_STEP_MODAL_KEY } from 'app/src/constants/modals.constants';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import Routes from 'app/src/helpers/Routes';
import { VIDEO_STATUS } from 'shared/react/constants/video.constants';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';
import { useApps } from 'app/src/context/AppsStore';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { PUBLISH_URL } from 'app/src/config/app.config';
import { publishMethodOptions } from 'app/src/types/entities';

const SCREEN_RECORDING_TYPES = [SCREEN_RECORDING, SCREEN_AND_CAMERA];
let videoId = null;
let gifUrl = null;
let options = null;
let loading = false;

const ExtensionUpload = () => {
  const [{ initialized, data }, { getVideo, createVideo, deleteVideo, getVideoGif }] = useVideos();
  const [showText, setShowText] = useState(false);
  const [{ shopify }] = useApps();
  const [
    { projects, fetchedProjects },
    { createProject, createProjectStep, publishProject, getStepByName },
  ] = useProjects();
  const [{ user, storageUser }] = useUser();
  const [, { setCurrentModal, setModalProps }] = useModal();
  const [, { setErrorSnackbar }] = useSnackBar();
  const timeoutRef = useRef();
  const history = useHistory();

  const onGetSignUrl = async response => {
    if (!response) {
      history.push(Routes.getDashboardBaseRoute());
      return;
    }

    const uuid = uuidv4();
    const ownerId = user?.ownerId || Utils.getOwner();
    const path = `public/${ownerId}/${uuid}.webm`;
    const { uploadURL } = await getSignedUrl('video', path);
    const { recordingType, mirror, verticalOrientation = false, version } = response;

    videoId = uuid;
    const name = `Recorded Video #${data.length + 1}`;
    const video = await createVideo({
      id: uuid,
      name,
      status: VIDEO_STATUS.uploading,
      uploadType: `extension-${recordingType}`,
      mirror,
      recorderVersion: version,
    });

    gifUrl = getVideoGif(video);

    options = { recordingType, mirror, verticalOrientation };

    sendUploadSignUrlEvent(uploadURL);
  };

  const onGetRecordingData = async response => {
    const {
      recordingType,
      mirror,
      videoId: vodAssetId,
      uuid,
      verticalOrientation = false,
    } = response;
    videoId = vodAssetId || uuid;

    const video = await getVideo(videoId);
    gifUrl = getVideoGif(video);

    options = { recordingType, mirror, verticalOrientation };
    sendRecordingDataProcessedEvent();
  };

  const createStep = async (videoId, project, description) => {
    const newStep = {
      name: nanoid(5),
      answers: [],
      projectId: project?.id,
      videoId,
      description,
      videoContain: true,
    };

    const newProject = await createProjectStep(newStep, project);
    const lastStepName = newProject.stepsOrder[newProject.stepsOrder.length - 1];
    const step = getStepByName(lastStepName, newProject);

    return step;
  };

  const createStepAndNewTolstoy = async ({
    newTolstoyName,
    reply,
    recordingType,
    verticalOrientation,
    videoId,
  }) => {
    const vertical =
      SCREEN_RECORDING_TYPES.includes(recordingType || options.recordingType) &&
      JSON.parse(verticalOrientation || options.verticalOrientation || 'false');

    const project = await createProject({
      type: reply ? 'reply' : '',
      vertical,
      newTolstoyName,
      publishMethod: publishMethodOptions.landingPage,
      appUrl: shopify?.appUrl,
    });

    Utils.copyToClipboard(`${PUBLISH_URL}/${project.publishId}`);
    const step = await createStep(videoId, project, newTolstoyName || '');
    await publishProject(project.id);

    sendTolstoyCreated(project.publishId, gifUrl);

    const stepId = step.id;

    if (reply) {
      setModalProps({ step, project, reply });
      setCurrentModal(EDIT_STEP_MODAL_KEY);
    }

    let pathname = Routes.getDashboardV2OffsiteProjectRoute(project.id, 'all');
    pathname += `/${stepId}`;
    navigateAndSaveOrigin(history, pathname, { chromeExtension: true, shouldRetry: true });
  };

  const addStepToExistingTolstoy = async ({
    projectId,
    stepId,
    reply,
    newTolstoyName = null,
    recordingType,
    mirror,
    verticalOrientation,
    videoId,
  }) => {
    const project = projects.find(({ id }) => {
      return id === projectId;
    });

    await createStep(videoId, project, newTolstoyName || '');
    const stepsLength = project.steps?.items?.length;
    stepId = project.steps?.items?.[stepsLength - 1]?.id;

    let pathname = Routes.getDashboardV2OffsiteProjectRoute(project.id, 'all');
    pathname += `/${stepId}`;

    navigateAndSaveOrigin(history, pathname, {
      mirror,
      recordingType,
      verticalOrientation,
      videoId,
      reply,
      chromeExtension: true,
      shouldRetry: true,
      newStepName: newTolstoyName,
    });
  };

  const onUploadFinish = async (state = {}) => {
    if (loading === true) {
      return;
    }

    loading = true;
    const data = { usingChromeExtension: true };
    await identify(storageUser, data);
    await analyticsIdentify(storageUser.id, data);
    if (state.projectId || state.stepId || state.reply) {
      return addStepToExistingTolstoy(state);
    }

    return createStepAndNewTolstoy(state);
  };

  const onUploadFail = async (videoId = videoId) => {
    await deleteVideo({ id: videoId });
    setErrorSnackbar('File upload failed. Video has been downloaded to your computer.');
    history.push(Routes.getDashboardBaseRoute());
  };

  useEffect(() => {
    if (!initialized || !fetchedProjects) {
      return;
    }

    const onMessage = async event => {
      switch (event?.detail?.name) {
        case GET_SIGN_URL:
          await onGetSignUrl(event.detail?.value);
          return;
        case GET_RECORDING_DATA:
          await onGetRecordingData(event.detail?.value);
          return;
        case UPLOAD_FINISH:
          await onUploadFinish(event.detail?.value);
          return;
        case UPLOAD_FAIL:
          await onUploadFail(event.detail?.value);
          return;
      }
    };

    window.addEventListener(EXTENSION_EVENT_KEY, onMessage);

    return () => {
      window.removeEventListener(EXTENSION_EVENT_KEY, onMessage);
    };
  }, [initialized, fetchedProjects]);

  useEffect(() => {
    if (!initialized || !fetchedProjects) {
      return;
    }

    sendIsUploadFinished();
    sendShouldGetSignUrlEvent();
  }, [initialized, fetchedProjects]);

  useEffect(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setShowText(true);
    }, 120000);

    return () => clearTimeout(timeoutRef.current);
  }, []);

  return (
    <>
      <CircularProgress size={96} />
      <HeaderContainer>
        <Header>{`We're uploading your video.`}</Header>
        <SubHeader>
          If the connection is lost or the upload takes longer than a few minutes, the video will be
          available on your {"computer's"} local drive.
        </SubHeader>
        {showText && (
          <Gap8VerticalFlex>
            <SubHeaderText>
              1. Go to this URL {'-->'} {'chrome://downloads/'}
            </SubHeaderText>
            <SubHeaderText>2. Search for {'"tolstoy-recording.webm"'}.</SubHeaderText>
            <SubHeaderText>
              3. Upload the video file ({'"tolstoy-recording.webm"'}) as a new part or as a new
              Tolstoy.
            </SubHeaderText>
          </Gap8VerticalFlex>
        )}
      </HeaderContainer>
    </>
  );
};

const HeaderContainer = styled.div`
  display: grid;
  gap: 16px;
  justify-items: center;
`;

const Header = styled(TextH3)``;

const SubHeader = styled(TextBodyLeading)`
  margin-bottom: 16px;
`;

const SubHeaderText = styled(TextBodyLeading)`
  margin-bottom: 8px;

  color: ${({ theme }) => theme.colors.dangerV2};
`;

export default ExtensionUpload;
