import { css } from 'styled-components';

const listItemStatesCss = css`
  &:hover {
    background: ${({ theme }) => theme.colors.gray6};
  }
  &:active,
  &:focus {
    background: ${({ theme }) => theme.colors.gray6_darker1};
  }
`;

export default listItemStatesCss;
