import { FC } from "preact/compat";
import { HeroSettings } from "../types/config";
import OverlayDescriptionAndButtons from "./OverlayDescriptionAndButtons";
import OverlayMuteButton from "./OverlayMuteButton";

type Props = {
  heroSettings: HeroSettings;
  isMobile: boolean;
  onSendEvent: (eventName: string) => void;
  shouldBePointer: boolean;
  isMuted: boolean;
  setIsMuted: (isMuted: boolean) => void;
};

const Overlay: FC<Props> = ({
  heroSettings,
  isMobile,
  onSendEvent,
  shouldBePointer,
  isMuted,
  setIsMuted,
}) => {
  if (!heroSettings) {
    return null;
  }

  const { includeMuteButton } = heroSettings || {};

  const linkClassName = shouldBePointer ? "link" : "";

  return (
    <div className={`hero-overlay ${linkClassName}`}>
      <OverlayMuteButton
        setIsMuted={setIsMuted}
        isMuted={isMuted}
        includeMuteButton={includeMuteButton}
      />
      <OverlayDescriptionAndButtons
        heroSettings={heroSettings}
        onSendEvent={onSendEvent}
        isMobile={isMobile}
      />
    </div>
  );
};

export default Overlay;
