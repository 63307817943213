import styled from 'styled-components';

export const ImageModalOverlay = styled.div<{ isVisible: boolean }>`
  display: ${props => (props.isVisible ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1010;
  justify-content: center;
  align-items: center;
`;

export const ImageModalContent = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
`;

// Styled components
export const ButtonContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 2;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ChatModal = styled.div<{ isVisible: boolean }>`
  position: fixed;
  z-index: 1000;
  bottom: 90px;
  right: 30px;
  align-items: flex-end;
  justify-content: flex-end;
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
  transform: translateY(${props => (props.isVisible ? '0' : '100%')});
  opacity: ${props => (props.isVisible ? '1' : '0')};
  transition:
    transform 0.3s ease,
    opacity 0.3s ease,
    visibility 0.3s;
  will-change: transform, opacity;
`;

export const ChatContainer = styled.div`
  width: 400px;
  height: 77vh;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ChatHeader = styled.div`
  background-color: #e2506d;
  color: #fff;
  padding: 16px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 50px;
  overflow: hidden;
`;

export const ChatOutput = styled.div`
  flex-grow: 1;
  background-color: #fff;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0;
  overflow-x: hidden;
`;

export const UserMessage = styled.div`
  background-color: #ed2455;
  border-radius: 15px;
  padding: 10px 20px;
  color: white;
  max-width: 80%;
  word-break: break-word;
  align-self: flex-end;
  margin-bottom: 10px;
`;

export const AIMessage = styled.div`
  background-color: #f4f5f7;
  border-radius: 15px;
  padding: 10px 20px;
  color: black;
  max-width: 80%;
  word-break: break-word;
  align-self: flex-start;
  margin-bottom: 10px;

  p {
    margin: 0;
  }

  ol {
    list-style-type: none;
    counter-reset: custom-counter;
    padding-left: 0;
    margin: 0;

    li {
      counter-increment: custom-counter;
      position: relative;
      margin-bottom: 10px;
      padding-left: 30px;

      &::before {
        content: counter(custom-counter);
        position: absolute;
        left: 0;
        top: 0;
        background-color: #e0e0e0;
        color: #333;
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        border-radius: 50%;
        font-weight: bold;
      }

      &::after {
        content: '';
        position: absolute;
        left: 10px;
        top: 20px;
        height: calc(100% - 20px);
        border-left: 2px solid #ccc;
        z-index: -1;
      }

      &:last-child::after {
        content: none;
      }
    }
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    margin: 0;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 30px;

      &::before {
        content: '•';
        position: absolute;
        left: 0;
        top: 0;
        color: #333;
        font-size: 20px;
      }

      &::after {
        content: '';
        position: absolute;
        left: 15px;
        top: calc(50% + 10px);
        bottom: -10px;
        width: 2px;
        background-color: #ccc;
        z-index: -1;
      }

      &:last-child::after {
        content: none;
      }
    }
  }
`;

export const SuggestionButton = styled.button`
  color: black;
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  background-color: #e7ecf8;
  padding: 4px 10px;
  border: none;
  border-radius: 10px;
  margin: 4px;
  cursor: pointer;
  outline: none;
  transition:
    all 0.2s ease,
    opacity 0.2s ease;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  &:hover {
    background-color: #ed2455;
    color: white;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.6;
    cursor: default;
    background-color: #e7ecf8;
    color: black;
  }
`;

export const SuggestionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 6px;
  margin-bottom: 8px;
`;

export const InputContainer = styled.div`
  display: flex;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 -2px 2px -2px grey;
`;

export const TypingIndicator = styled.div`
  color: #555;
  padding: 10px 20px;
  align-self: flex-start;
  font-style: italic;
  display: flex;
  align-items: center;

  span {
    display: inline-block;
    background-color: #555;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-right: 6px;
    animation: typing 1.5s infinite;
  }

  span:nth-child(2) {
    animation-delay: 0.2s;
  }

  span:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes typing {
    0% {
      transform: scale(1);
      opacity: 0.8;
    }
    25% {
      transform: scale(1.5);
      opacity: 1;
    }
    50% {
      transform: scale(1);
      opacity: 0.8;
    }
    100% {
      transform: scale(1);
      opacity: 0.8;
    }
  }
`;

export const Input = styled.input`
  flex-grow: 1;
  padding: 10px;
  border: none;
  outline: none;
  color: inherit;
  background-color: #fff;
`;

export const SendButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 2px;
  margin: 0;
  cursor: pointer;
  outline: none;
  transition:
    transform 0.2s ease,
    opacity 0.2s ease;

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.95);
  }

  &:disabled {
    opacity: 0.6;
    cursor: default;
    transform: none;
  }
`;

export const NewThreadButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 2px;
  margin: 0;
  cursor: pointer;
  outline: none;
  transition: transform 0.2s ease; 
  
  &:hover {
    transform: scale(1.1);

  &:active {
    transform: scale(0.95); 
  }
`;

export const TolyButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
  outline: none;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.1);
  }
`;
