import React, { useState } from 'react';
import { addDays } from 'date-fns';
import Separator from 'shared/react/components/basic/Separator';
import Button from 'shared/react/components/complex/Button';
import Types from 'shared/react/theme/Types';
import styled from 'styled-components';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextSmall } from 'shared/react/components/complex/Text';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import { FILTER_DATE_VALUES } from 'app/src/context/AnalyticsStore';
import { DATE_FILTER_LAST_30_DAYS } from 'app/src/constants/analytics.constants';
import { DateRange } from 'react-date-range';

type Props = {
  value: { startDate: Date; endDate: Date };
  closeMenu: () => void;
  onChange: (value: string) => void;
  maxDateBack: number;
  filters: string[];
};

const CalendarContent = ({ value, closeMenu, onChange, maxDateBack, filters }: Props) => {
  const [date, setDate] = useState([
    {
      ...FILTER_DATE_VALUES[value || DATE_FILTER_LAST_30_DAYS],
      key: 'selection',
      title: value,
      ...value,
    },
  ]);

  const onFilterClick = key => {
    setDate([{ ...FILTER_DATE_VALUES[key], key: 'selection', title: key }]);
  };

  const getDateRangeString = () => {
    return `${date[0].startDate?.toLocaleDateString()} - ${date[0].endDate?.toLocaleDateString()}`;
  };

  const handleChange = () => {
    onChange(date[0]);
    closeMenu();
  };

  return (
    <>
      <DatePickerContainer>
        <TimesContainer>
          {filters.map(filter => {
            return (
              <TextContainer onClick={() => onFilterClick(filter)} key={filter}>
                <Text>{filter}</Text>
              </TextContainer>
            );
          })}
        </TimesContainer>
        <VerticalSeparator />

        <DateRange
          editableDateInputs={false}
          onChange={item => setDate([{ ...item.selection, title: 'Custom' }])}
          moveRangeOnFirstSelection={false}
          ranges={date}
          months={2}
          minDate={addDays(new Date(), -maxDateBack)}
          maxDate={new Date()}
          direction={'horizontal'}
          showMonthAndYearPickers={false}
        />
      </DatePickerContainer>
      <Separator />
      <BottomContainer>
        <DateText>{getDateRangeString()}</DateText>
        <Button onClick={closeMenu} type={Types.Ghost}>
          Cancel
        </Button>
        <Button onClick={handleChange}>Apply</Button>
      </BottomContainer>
    </>
  );
};

const DatePickerContainer = styled(HorizontalFlexWrap)`
  flex-wrap: nowrap;
`;

const TimesContainer = styled(VerticalFlex)`
  width: 174px;
`;

const TextContainer = styled(HorizontalFlexWrap)`
  padding: 14px 20px;
  cursor: pointer;
`;

const Text = styled(TextSmall)``;

const VerticalSeparator = styled(Separator)`
  width: 1px;
  height: inherit;
  margin: 0 16px;
`;

const BottomContainer = styled(Gap8HorizontalFlexWrap)`
  justify-content: flex-end;
  align-items: center;
  padding: 8px;
`;

const DateText = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.ghostDark};
`;

export default CalendarContent;
