import React, { useState } from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import DefaultVideoPlaceholder from 'app/src/images/default_project_placeholder.svg';
import { TextH6 } from 'shared/react/components/complex/Text';

const PreviewSection = ({ previewedVideoSrc, subtitlesBlob }) => {
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);

  const handleVideoLoadError = () => {
    setErrorLoading(true);
  };

  const handleCanPlay = e => {
    setVideoLoaded(true);
    return e.target.play();
  };

  const shouldShowVideo = previewedVideoSrc && !errorLoading;
  return (
    <Container>
      <MiddleContainer>
        {shouldShowVideo ? (
          <>
            {!videoLoaded && (
              <VideoBack>
                <CircularProgress size={20} />
              </VideoBack>
            )}
            <Video
              visible={previewedVideoSrc}
              src={previewedVideoSrc}
              autoplay
              playsInline
              muted
              loop
              controls={true}
              onLoadedMetadata={handleCanPlay}
              onError={handleVideoLoadError}
              disablePictureInPicture={true}
            >
              {subtitlesBlob && (
                <track
                  label="English"
                  kind="subtitles"
                  srcLang="en"
                  default
                  src={URL.createObjectURL(subtitlesBlob)}
                />
              )}
            </Video>
          </>
        ) : (
          <VideoNotFoundContainer>
            <img alt="No video" src={DefaultVideoPlaceholder} />
            <TextH6>Your video wasn&apos;t found.</TextH6>
          </VideoNotFoundContainer>
        )}
      </MiddleContainer>
    </Container>
  );
};

const VideoNotFoundContainer = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  justify-self: center;
  align-self: center;
  gap: 16px;
`;

const Container = styled.div`
  display: grid;
  align-self: start;
  justify-self: start;
  align-items: center;
  align-content: center;
  row-gap: 16px;
  width: 520px;

  @media (${({ theme }) => theme.breakpoints.previewMax}) {
    grid-row: 1;
    grid-column: 1;
    justify-self: center;
    width: 100%;
    row-gap: 8px;
  }
`;

const MiddleContainer = styled.div`
  display: grid;
  margin-top: 0;
  overflow: hidden;
  align-self: end;
  justify-self: center;
  max-width: ${({ isMobilePreview }) => (isMobilePreview ? '350px' : '630px')};
  max-height: 460px;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    margin-top: 0;
    width: 100%;
    max-height: 320px;
  }
`;

const VideoBack = styled.div`
  height: 100%;
  width: 580px;
  display: grid;
  justify-items: center;
  align-items: center;
  background: black;
  border-radius: 10px;
  grid-row: 1;
  grid-column: 1;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 100%;
    height: 100%;
    border-radius: 0;
    max-height: 320px;
  }

  @media (max-height: 568px) {
    width: 100%;
  }
`;

const Video = styled.video`
  height: 100%;
  width: 100%;
  grid-row: 1;
  grid-column: 1;
  border-radius: 10px;
  overflow: hidden;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  transition: opacity 250ms;
  background: ${({ videoContain }) => (videoContain ? 'black' : 'transparent')};
  z-index: 1;
  max-height: 460px;
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    max-height: 320px;
    background: black;
  }

  @media (max-height: 568px) {
    width: 100%;
  }
`;

export default PreviewSection;
