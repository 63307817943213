import React from 'react';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';
import { getFeedActionButtonOnClick } from 'shared/react/components/complex/feed/feed_utils/feedActionButton.utils';
import {
  PRIMARY_DARK_BUTTON_TYPE,
  SECONDARY_BUTTON_TYPE,
  SHOPABLE_SIDE_PANEL_TYPE,
} from 'shared/react/constants/feed.constants';
import useTracker from 'shared/react/components/complex/context/hooks/useTracker';
import FeedBrandedButton from 'shared/react/components/complex/feed/feed_buttons/FeedBrandedButton';
import useFeedFeatures from 'shared/react/components/complex/context/hooks/useFeedFeatures';
import { FEATURE_FEED_HIDE_BUY_NOW_BUTTON } from 'shared/react/constants/features.constants';
import UrlHelper from 'shared/react/utils/urlHelper';

const FeedProductActionButton = ({ currentStepKey, ...props }) => {
  const [, { track }] = useTracker();
  const [, { getProductActionButtonData }] = useProjectConfig();
  const [, { getFeatureSettingsByKey }] = useFeedFeatures();
  const actionButtonData = getProductActionButtonData(currentStepKey);
  const { shouldHide: isFeedBuyNowButtonHidden } = getFeatureSettingsByKey(
    FEATURE_FEED_HIDE_BUY_NOW_BUTTON
  );

  if (!actionButtonData || !actionButtonData?.enabled || !actionButtonData?.value) {
    return null;
  }

  const { text, type, value, isOpenInANewTab } = actionButtonData;

  const onClick = () => {
    const utmParams = UrlHelper.getUtmParams();
    const url = UrlHelper.appendSearchParamsToUrl(value, utmParams);

    track(`${SHOPABLE_SIDE_PANEL_TYPE}ActionButtonClick`, { type });
    getFeedActionButtonOnClick({
      type,
      value: url,
      isOpenInANewTab,
    });
  };

  const buttonType = isFeedBuyNowButtonHidden ? PRIMARY_DARK_BUTTON_TYPE : SECONDARY_BUTTON_TYPE;
  return (
    <FeedBrandedButton
      onClick={onClick}
      type={buttonType}
      buttonText={text || 'See more'}
      {...props}
    />
  );
};

export default FeedProductActionButton;
