import { useEffect, useState, useRef } from 'react';
import { useSelectedApp } from 'src/context/AppsStore';
import { SearchProduct, SearchProductsPayload } from 'src/types/common';
import { useVodConnection } from 'src/context/VodConnectionStore';
import { useProductActions } from 'app/src/context/ProductsStore';
import useGetSearchProducts from 'src/hooks/useGetSearchProducts';
import { sortEntities } from 'app/src/hooks/use-dynamic-video-ids-by-project/checkRules';
import { useDynamicConditionsContext } from '../../components/dynamic-conditions/context/DynamicConditionsContext';
import useGetVideoPayload from '../useGetVideoPayload';
import useDynamicTypeProductsActions, { UseProductActions } from './useDynamicTypeProductsActions';

const MIN_PRODUCTS_AMOUNT = 30;

export type UseDynamicProducts = UseProductActions & {
  isLoading: boolean;
  isSearchDisabled: boolean;
  products: any[];
};

const sortProductPayloadsByVideoCount = (payloadA, payloadB) =>
  payloadB.videos.length - payloadA.videos.length;

export const useDynamicTypeProducts = ({
  project,
  videos = [],
  searchTerm,
  allowNonTaggedVideos = false,
  isProjectProductPage = false,
  isDerivedProject = false,
}): UseDynamicProducts => {
  const [selectedApp] = useSelectedApp();
  const { getProducts } = useProductActions();
  const { videoRules, vodAssetIds } = useDynamicConditionsContext();
  const [{ vodAssetIdsByExternalProductId, productsByVodAssetMap }] = useVodConnection();
  const getSearchProducts = useGetSearchProducts(selectedApp);
  const latestSearchRequestIndexRef = useRef(0);
  const latestProductsUpdateIndexRef = useRef(0);
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [dbProducts, setDbProducts] = useState([]);
  const [fetchedProducts, setFetchedProducts] = useState<any[] | undefined>();
  const isSearchDisabled = !selectedApp?.appUrl;
  const getVideoPayload = useGetVideoPayload({ vodAssetIds, videoRules, project });
  const productActions = useDynamicTypeProductsActions({videoRules, products, setProducts, project });

  const getVideosForProduct = (videoAssetIds, product) => {
    const sortedEntities = sortEntities(videoAssetIds, [videoRules], product.productId);

    return sortedEntities.flatMap(
      (videoId, index) => getVideoPayload(videoId, index, product) || []
    );
  };

  const getVodAssetIdsForProduct = product => {
    if (allowNonTaggedVideos) {
      return videos.map(({ video }) => video.id);
    }

    const externalProductId = product.externalProductId || product.productId;

    const vodAssetIds = vodAssetIdsByExternalProductId?.[externalProductId]?.[project.appUrl] || [];
    return [...vodAssetIds];
  };

  const getProductPayload = product => {
    const videoAssetIds = getVodAssetIdsForProduct(product);
    const videos = getVideosForProduct(videoAssetIds, product);

    return {
      product,
      videos,
    };
  };

  const transformProductsToProductsPayload = products => {
    return products.map(getProductPayload).sort(sortProductPayloadsByVideoCount);
  };

  const searchProducts = async (term = ''): Promise<SearchProduct[]> => {
    if (isSearchDisabled) {
      return;
    }

    const body = {
      term,
      appUrl: selectedApp?.appUrl,
      appKey: selectedApp?.appKey,
      customLimit: MIN_PRODUCTS_AMOUNT,
    } as SearchProductsPayload;

    const requestIndex = latestSearchRequestIndexRef.current + 1;
    latestSearchRequestIndexRef.current = requestIndex;
    const { searchProducts: products } = await getSearchProducts(body);

    if (requestIndex !== latestSearchRequestIndexRef.current) {
      return;
    }

    return products;
  };

  const getProjectProducts = async () => {
    const allProductIds = vodAssetIds.flatMap(
      vodAssetId => productsByVodAssetMap[vodAssetId]?.[project.appUrl] || []
    );
    const productIds = [...new Set(allProductIds)];
    const fetchedProductsPromise = fetchedProducts
      ? Promise.resolve(fetchedProducts)
      : searchProducts();

    const [matchedProductsResult, fetchedProductsResult] = await Promise.allSettled([
      getProducts(productIds),
      fetchedProductsPromise,
    ]);

    const matchedProducts =
      matchedProductsResult.status === 'fulfilled' ? matchedProductsResult.value : [];
    const newFetchedProducts =
      fetchedProductsResult.status === 'fulfilled' ? fetchedProductsResult.value : [];

    const filteredSearchedProducts =
      newFetchedProducts?.filter(
        ({ productId, externalProductId }) =>
          !matchedProducts.some(product => product.productId === (productId || externalProductId))
      ) || [];
    const products = [...matchedProducts, ...filteredSearchedProducts];

    if (!fetchedProducts && !isSearchDisabled) {
      setFetchedProducts(newFetchedProducts);
    }

    return products;
  };

  useEffect(() => {
    if (!isProjectProductPage) {
      return;
    }

    const requestIndex = latestProductsUpdateIndexRef.current + 1;
    latestProductsUpdateIndexRef.current = requestIndex;

    setIsLoading(true);

    const dbProductsPromise = searchTerm ? searchProducts(searchTerm) : getProjectProducts();

    dbProductsPromise
      .then(dbProducts => {
        if (requestIndex !== latestProductsUpdateIndexRef.current) {
          return;
        }

        setDbProducts(dbProducts);
      })
      .finally(() => {
        if (requestIndex !== latestProductsUpdateIndexRef.current) {
          return;
        }

        setIsLoading(false);
      });
  }, [searchTerm, selectedApp, vodAssetIds]);

  useEffect(() => {
    if (!isProjectProductPage) {
      return;
    }

    const productsWithVideos = transformProductsToProductsPayload(dbProducts);

    setProducts(productsWithVideos);
  }, [dbProducts, videos, videoRules, isDerivedProject]);

  return {
    isLoading,
    isSearchDisabled,
    products,
    ...productActions,
  };
};

export default useDynamicTypeProducts;
