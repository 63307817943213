import React from 'react';
import views from './images/views.png';
import plays from './images/plays.png';
import interactions from './images/interactions.png';
import lineChart from './images/lineChart.png';
import table from './images/table.png';
import styled from 'styled-components';

const ENGAGEMENT_METRICS = [
  { image: views, subtitle: 'Total Revenue' },
  { image: plays, subtitle: 'Played Tolstoy' },
  { image: interactions, subtitle: 'Purchased product' },
];

const AnalyticsEngagementEmptyState = () => {
  return (
    <LayoutRoot>
      <MetricsContainer>
        {ENGAGEMENT_METRICS.map(({ subtitle, image }) => (
          <MetricsImage key={subtitle} src={image} />
        ))}
      </MetricsContainer>
      <ChartImage src={lineChart} />
      <ChartImage src={table} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  display: grid;
  gap: 50px;
`;

const MetricsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const MetricsImage = styled.img`
  width: 236px;
`;

const ChartImage = styled.img`
  width: 100%;
`;

export default AnalyticsEngagementEmptyState;
