import React from 'react';
import EditorOnOffToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorOnOffToggleItem';
import EditorTextInputItem from '../shared/EditorTextInputItem';

const FeedMultipleProductDividerEditor = ({
  dividerSettings,
  dividerTitleSettings,
  updateDividerSettings,
  updateDividerTitleSettings,
}) => {
  const { enabled: isDividerEnabled } = dividerSettings || {};
  const { enabled: isDividerTitleEnabled, text: dividerTitle } = dividerTitleSettings || {};

  const handleUpdateDividerSettings = update =>
    updateDividerSettings({ ...dividerSettings, ...update });
  const handleUpdateDividerTitleSettings = update =>
    updateDividerTitleSettings({ ...dividerTitleSettings, ...update });

  const handleDividerEnabledToggle = () =>
    handleUpdateDividerSettings({ enabled: !isDividerEnabled });
  const handleDividerTitleEnabledToggle = () =>
    handleUpdateDividerTitleSettings({ enabled: !isDividerTitleEnabled });
  const handleUpdateDividerTitle = value => handleUpdateDividerTitleSettings({ text: value });

  return (
    <>
      <EditorOnOffToggleItem
        text="Divider"
        checked={isDividerEnabled}
        onChange={handleDividerEnabledToggle}
      />
      <EditorOnOffToggleItem
        text="Divider Title"
        checked={isDividerTitleEnabled}
        onChange={handleDividerTitleEnabledToggle}
      />
      {isDividerTitleEnabled && (
        <EditorTextInputItem
          placeholder="Divider text"
          text="Text"
          value={dividerTitle}
          maxWidth="200px"
          onChange={handleUpdateDividerTitle}
        />
      )}
    </>
  );
};

export default FeedMultipleProductDividerEditor;
