import { useState, useEffect } from 'react';
import { useApps } from 'src/context/AppsStore';
import { useVodAssets } from 'src/context/VideosStore';
import { useVodConnection } from 'src/context/VodConnectionStore';
import { UPLOAD } from 'app/src/constants/recordingTypes.constants';
import { TIKTOK, GOOGLE_DRIVE, INSTAGRAM_GRAPH } from 'app/src/constants/intergrations.constants';
import { AI_VIDEO } from 'app/src/constants/creation_method.constants';
import { getIsFeatureEnabled } from 'app/src/context/FeaturesStore';
import { FEATURE_AI_VIDEOS } from 'shared/react/constants/features.constants';

export type UsePlaylistsAvailabilityReturnType = {
  isProductTaggedAvailable: boolean;
  getIsLabelPlaylistAvailable: (labelId: string) => boolean;
  getIsSourcePlaylistAvailable: (source: string) => boolean;
  getIsSourcePlaylistDisabled: (source: string) => boolean;
};

const usePlaylistsAvailability = (): UsePlaylistsAvailabilityReturnType => {
  const [videos] = useVodAssets();
  const [{ numberOfProductVideos, vodAssetIdsByLabelId }] = useVodConnection();
  const [{ instagramApps, tiktokApps, googleDrive, shopify: isShopify }] = useApps();

  const getIsLabelPlaylistAvailable = labelId => !!vodAssetIdsByLabelId[labelId]?.length;

  const getIsSourcePlaylistAvailable = source => {
    switch (source) {
      case TIKTOK:
        return !!tiktokApps.length;
      case INSTAGRAM_GRAPH:
        return !!instagramApps.length;
      case GOOGLE_DRIVE:
        return !!googleDrive;
      case UPLOAD:
        return videos.some(video => video.uploadType === UPLOAD);
      case AI_VIDEO:
        return videos.some(video => video.uploadType.includes(AI_VIDEO));
      default:
        return false;
    }
  };

  const getIsSourcePlaylistDisabled = source => {
    switch (source) {
      case AI_VIDEO:
        return !getIsFeatureEnabled(FEATURE_AI_VIDEOS) || !isShopify;
      default:
        return false;
    }
  };

  const [playlistAvailability, setPlaylistAvailability] = useState({
    isProductTaggedAvailable: !!numberOfProductVideos,
    getIsSourcePlaylistAvailable,
    getIsLabelPlaylistAvailable,
    getIsSourcePlaylistDisabled,
  });

  useEffect(() => {
    setPlaylistAvailability({
      isProductTaggedAvailable: !!numberOfProductVideos,
      getIsSourcePlaylistAvailable,
      getIsLabelPlaylistAvailable,
      getIsSourcePlaylistDisabled,
    });
  }, [vodAssetIdsByLabelId, tiktokApps, instagramApps, googleDrive, videos]);

  return playlistAvailability;
};

export default usePlaylistsAvailability;
