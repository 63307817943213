import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { PrimaryButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { useSnackBarActions } from 'src/context/ui_store/SnackBarStore';
import Utils from 'src/utils';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { FlexDirection } from 'src/types/common/common';
import CopyToClipboardIcon from 'app/src/images/dashboard_v2/CopyToClipboardIcon';

export const CLIPBOARD_DATA_FORMAT_HTML = 'html';
export const CLIPBOARD_DATA_FORMAT_PLAIN_TEXT = 'plainText';

type Props = {
  codeToCopy: string;
  codeToDisplay?: string | ReactElement;
  clipboardDataFormat?: 'plainText' | 'html';
  buttonText?: ReactElement | string;
  padding?: string;
  direction?: FlexDirection;
  hideCodeBox?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const CopyCode = ({
  codeToCopy,
  codeToDisplay = codeToCopy,
  buttonText = (
    <>
      Copy Code <CopyToClipboardIcon />
    </>
  ),
  direction = FlexDirection.column,
  padding,
  clipboardDataFormat = CLIPBOARD_DATA_FORMAT_PLAIN_TEXT,
  hideCodeBox = false,
  onClick,
  ...props
}: Props) => {
  const { setSnackbar } = useSnackBarActions();

  const copyToClipboard = () => {
    if (clipboardDataFormat === CLIPBOARD_DATA_FORMAT_HTML) {
      Utils.copyHtmlToClipboard(codeToCopy);
      return;
    }
    Utils.copyToClipboard(codeToCopy);
  };

  const handleCopyCode = e => {
    copyToClipboard();
    setSnackbar('Copied to clipboard!');
    onClick?.(e);
  };

  const LayoutRoot = direction === FlexDirection.row ? HorizontalLayoutRoot : VerticalLayoutRoot;

  return (
    <LayoutRoot className="notranslate">
      {!hideCodeBox && (
        <CodeBox {...props} $padding={padding} $direction={direction}>
          <TextTinyDark>{codeToDisplay}</TextTinyDark>
        </CodeBox>
      )}
      <PrimaryButton onClick={handleCopyCode}>{buttonText}</PrimaryButton>
    </LayoutRoot>
  );
};

const VerticalLayoutRoot = styled(Gap8VerticalFlex)`
  height: 100%;
  align-items: flex-end;
  justify-content: center;
`;

const HorizontalLayoutRoot = styled(Gap8HorizontalFlex)``;

const CodeBox = styled.div<{ $padding?: string; $direction: FlexDirection }>`
  width: 100%;
  word-break: ${({ $direction }) => ($direction === FlexDirection.row ? 'break-word' : 'unset')};
  height: auto;
  padding: ${({ $padding }) => $padding || '16px'};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.neutralGray};
  border-radius: 4px;
`;

const TextTinyDark = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralDark};
`;

export default CopyCode;
