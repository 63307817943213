import { GetOverviewEngagementOvertimeMetricsProps } from 'app/src/services/cube/types/getOverviewEngagementOvertimeMetrics.types';

export const getOverviewEngagementOvertimeMetrics = ({
  overviewEngagementOvertime,
}: GetOverviewEngagementOvertimeMetricsProps) => {
  let overallNumOfGenericInteractions = 0;
  let overallNumOfProductInteractions = 0;

  for (const {
    numOfGenericInteractions = 0,
    numOfProductInteractions = 0,
  } of overviewEngagementOvertime) {
    overallNumOfGenericInteractions += numOfGenericInteractions;
    overallNumOfProductInteractions += numOfProductInteractions;
  }

  return {
    overallNumOfGenericInteractions,
    overallNumOfProductInteractions,
    overallNumOfInteractions: overallNumOfGenericInteractions + overallNumOfProductInteractions,
    engagementOvertimeNoData: !overallNumOfGenericInteractions && !overallNumOfProductInteractions,
  };
};
