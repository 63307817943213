import {
  GOOGLE_DRIVE,
  INSTAGRAM_GRAPH,
  SYNTHESIA,
  TIKTOK,
  UGC_INSTAGRAM_GRAPH_PENDING,
  YOUTUBE,
} from 'app/src/constants/intergrations.constants';
import { useApps } from 'app/src/context/AppsStore';
import { useInstagram } from 'app/src/context/InstagramStore';
import { useIntegrationVodAsset } from 'app/src/context/IntegrationVodAssetStore';
import { useSynthesia } from 'app/src/context/SynthesiaStore';
import { useTikTok } from 'app/src/context/TikTokStore';
import { useVideos } from 'app/src/context/VideosStore';
import useIntegrationsVideos from 'app/src/hooks/useIntegrationsVideos';
import useSortEntities from 'app/src/pages/dashboard/hooks/useSortEntities';
import {
  getDuplicateSource,
  SOURCES_UPLOAD_TYPE,
} from 'app/src/constants/videoSources.constants.js';
import { VideosPageVodAsset } from 'app/src/pages/dashboard/pages/videos/types/videos.types';
import filterEntities, { WhereCondition } from 'app/src/pages/dashboard/utils/filterEntities';
import { omit } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGoogleDrive } from 'src/context/GoogleDriveStore';
import usePrepareIntegrationVideos from './usePrepareIntegrationVideos';
import Utils from 'src/utils';
import { useYoutube } from 'app/src/context/YoutubeStore';

type Props = {
  videosFilters: WhereCondition;
  setVideosFilters: (filters: WhereCondition, forceReplace?: boolean) => void;
};

const useVideosFilters = ({ videosFilters, setVideosFilters }: Props) => {
  const [
    {
      videos: googleDriveVideos,
      initialized: googleDriveInitialized,
      gotAllVideos: gotAllGoogleDrive,
      rootFolderId: rootFolderIdFromStore,
    },
    { fetchByAccount: fetchGoogleDrive, pickFolder },
  ] = useGoogleDrive();
  const [{ videos: youtubeVideos, gotAllVideos: gotAllYoutube }, { fetchByAccount: fetchYoutube }] =
    useYoutube();

  const getRootFolderId = () => {
    if (rootFolderIdFromStore !== undefined) {
      return rootFolderIdFromStore;
    }

    const data = Utils.safeParse(googleDrive?.data);
    return data?.rootFolderId;
  };

  const [{ data, trendingVideos, initialized: videosInitialized }] = useVideos();
  const [
    { accountVideos: tiktokVideos, initialized: tiktokInitialized, gotAllVideos: gotAllTiktok },
    { fetchByApps: fetchTikTokVideos, fetchUserInfoByApps },
  ] = useTikTok();
  const [
    {
      accountVideos: instagramVideos,
      initialized: instagramInitialized,
      gotAllVideos: gotAllInstagram,
    },
    { fetchByApps: fetchInstagramAppsVideos },
  ] = useInstagram();
  const [{ initialized: youtubeInitialized }] = useYoutube();
  const [{ assets }] = useIntegrationVodAsset();
  const [
    { tiktokApps, initialized: appsInitialized, instagramGraph, instagramApps, synthesia, youtube },
  ] = useApps();
  const [{ videos: synthesiaVideos, initialized: synthesiaInitialized }, { fetchSynthesia }] =
    useSynthesia();
  const { allTiktokAppsHaveUserInfo, instagramProfile, googleDrive } = useIntegrationsVideos();
  const { prepareIntegrationVideos } = usePrepareIntegrationVideos();
  const { source } = useParams();
  const rootFolderId = getRootFolderId();
  const currentVideoSource = videosFilters.uploadType?.in?.[0] as string;

  const returnByApp = (
    instagramData,
    tiktokData,
    synthesiaData,
    googleDriveData,
    youtubeData,
    defaultData
  ) => {
    let value = defaultData;
    if (SOURCES_UPLOAD_TYPE[TIKTOK].includes(currentVideoSource)) {
      value = tiktokData;
    } else if (SOURCES_UPLOAD_TYPE[INSTAGRAM_GRAPH].includes(currentVideoSource)) {
      value = instagramData;
    } else if (currentVideoSource === SYNTHESIA) {
      value = synthesiaData;
    } else if (currentVideoSource === YOUTUBE) {
      value = youtubeData;
    } else if (currentVideoSource === GOOGLE_DRIVE) {
      if (rootFolderId === undefined) {
        return [];
      }

      value = googleDriveData;
    }

    return typeof value === 'function' ? value() : value;
  };

  const fetchSynthesiaVideos = () => {
    if (!synthesia || synthesiaVideos.length) {
      return;
    }

    fetchSynthesia(synthesia?.token);
  };

  const fetchTiktok = () => {
    if (!tiktokApps.length) {
      return;
    }

    fetchTikTokVideos(tiktokApps);
  };

  const getVideosFromIntegration = videos => {
    const notImportedVideos = videos.filter(
      video => !data.find(({ externalId }) => video.externalId === externalId)
    );
    const currentIntegrationVideos = prepareIntegrationVideos(notImportedVideos);

    return [...currentIntegrationVideos, ...data];
  };

  const videos = useMemo(() => {
    const getTiktokVideo = () => {
      if (!tiktokApps.length) {
        return data;
      }

      if (!allTiktokAppsHaveUserInfo) {
        fetchUserInfoByApps(tiktokApps);
      }

      return getVideosFromIntegration(tiktokVideos);
    };

    const getInstagramVideos = () => {
      if (!instagramGraph) {
        return data;
      }

      return getVideosFromIntegration(instagramVideos);
    };

    const getSynthesiaVideos = () => {
      if (!synthesia) {
        return data;
      }

      return getVideosFromIntegration(synthesiaVideos);
    };

    const getGoogleDriveVideos = () => {
      if (!googleDrive) {
        return data;
      }

      return getVideosFromIntegration(googleDriveVideos);
    };

    const getYoutubeVideos = () => {
      if (!youtube) {
        return data;
      }

      return getVideosFromIntegration(youtubeVideos);
    };

    return returnByApp(
      getInstagramVideos,
      getTiktokVideo,
      getSynthesiaVideos,
      getGoogleDriveVideos,
      getYoutubeVideos,
      data
    );
  }, [
    data,
    tiktokVideos,
    instagramVideos,
    videosFilters,
    youtubeVideos,
    allTiktokAppsHaveUserInfo,
    instagramProfile?.userName,
    synthesiaVideos,
    googleDriveVideos,
    assets,
  ]);

  useEffect(() => {
    if (currentVideoSource === GOOGLE_DRIVE && googleDrive && rootFolderId === undefined) {
      pickFolder();
    }
  }, [rootFolderId, googleDrive, currentVideoSource]);

  useEffect(() => {
    if (!appsInitialized) {
      return;
    }

    const fetchInstagram = () => {
      if (!instagramGraph) {
        return;
      }

      fetchInstagramAppsVideos(instagramApps);
    };

    const fetchGoogleDriveVideos = () => {
      if (!googleDrive) {
        return;
      }

      fetchGoogleDrive();
    };

    const fetchYoutubeVideos = () => {
      if (!youtubeVideos) {
        return;
      }

      fetchYoutube();
    };

    returnByApp(
      fetchInstagram,
      fetchTiktok,
      fetchSynthesiaVideos,
      fetchGoogleDriveVideos,
      fetchYoutubeVideos,
      data
    );
  }, [
    fetchTikTokVideos,
    fetchInstagramAppsVideos,
    fetchGoogleDrive,
    fetchYoutube,
    videosFilters,
    appsInitialized,
    tiktokApps,
    instagramGraph,
    synthesia,
    googleDrive,
    rootFolderId,
    youtube,
  ]);

  useEffect(() => {
    const currentUploadType = SOURCES_UPLOAD_TYPE[source];
    if (!currentUploadType) {
      return;
    }

    const duplicatedUploadTypes = currentUploadType.map(getDuplicateSource);
    const uploadType = [...currentUploadType, ...duplicatedUploadTypes];

    setVideosFilters({ ...videosFilters, folder: null, uploadType: { in: uploadType } });
  }, [source]);

  const initialized = returnByApp(
    !instagramGraph || instagramInitialized,
    !tiktokApps.length || tiktokInitialized,
    !synthesia || synthesiaInitialized,
    !googleDrive || googleDriveInitialized,
    !youtube || youtubeInitialized,
    videosInitialized
  );

  let onScrollEndCallback = null;

  switch (source) {
    case TIKTOK:
      if (gotAllTiktok || !tiktokApps.length) {
        break;
      }

      onScrollEndCallback = fetchTiktok;
      break;
    case YOUTUBE:
      if (gotAllYoutube || !youtube) {
        break;
      }

      onScrollEndCallback = fetchYoutube;
      break;
    case INSTAGRAM_GRAPH:
      if (gotAllInstagram || !instagramGraph) {
        break;
      }

      onScrollEndCallback = () => fetchInstagramAppsVideos(instagramApps);
      break;
    case GOOGLE_DRIVE:
      if (gotAllGoogleDrive || !googleDrive) {
        break;
      }

      onScrollEndCallback = fetchGoogleDrive;
  }

  const defaultFilteredVideos = videos.filter(
    ({ uploadType }) => uploadType !== UGC_INSTAGRAM_GRAPH_PENDING
  );

  const currentVideos = filterEntities<VideosPageVodAsset>(
    defaultFilteredVideos,
    omit(videosFilters, ['app'])
  );
  const sortedVideos = useSortEntities<VideosPageVodAsset>(currentVideos, 'createdAt', true);

  return {
    initialized,
    videos: videosFilters.trending ? trendingVideos : sortedVideos,
    unfilteredVideos: defaultFilteredVideos,
    onScrollEndCallback,
  };
};

export default useVideosFilters;
