import React from 'react';

const InstagramSmallIcon = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 3.406C6 3.406 4.406 5.031 4.406 7 4.406 9 6 10.594 8 10.594A3.594 3.594 0 0 0 11.594 7c0-1.969-1.625-3.594-3.594-3.594Zm0 5.938A2.34 2.34 0 0 1 5.656 7c0-1.281 1.032-2.313 2.344-2.313A2.307 2.307 0 0 1 10.313 7c0 1.313-1.032 2.344-2.313 2.344Zm4.563-6.063a.84.84 0 0 1-.844.844.84.84 0 0 1-.844-.844.84.84 0 0 1 .844-.844.84.84 0 0 1 .844.844Zm2.374.844C14.876 3 14.626 2 13.813 1.187 13 .375 12 .125 10.874.063 9.719 0 6.25 0 5.094.063 3.969.126 3 .376 2.156 1.188 1.344 2 1.094 3 1.031 4.126c-.061 1.155-.061 4.624 0 5.78.063 1.125.313 2.094 1.125 2.938.844.812 1.813 1.062 2.938 1.125 1.156.062 4.625.062 5.781 0 1.125-.063 2.125-.313 2.938-1.125.812-.844 1.062-1.813 1.124-2.938.063-1.156.063-4.625 0-5.781Zm-1.5 7c-.218.625-.718 1.094-1.312 1.344-.938.375-3.125.281-4.125.281-1.031 0-3.219.094-4.125-.281a2.37 2.37 0 0 1-1.344-1.344C2.156 10.219 2.25 8.031 2.25 7c0-1-.094-3.188.281-4.125a2.41 2.41 0 0 1 1.344-1.313c.906-.375 3.094-.28 4.125-.28 1 0 3.188-.095 4.125.28.594.22 1.063.72 1.313 1.313.374.938.28 3.125.28 4.125 0 1.031.095 3.219-.28 4.125Z"
        fill="#fff"
      />
    </svg>
  );
};

export default InstagramSmallIcon;
