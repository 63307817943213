import React from 'react';

const MagnifyingGlassIcon = () => {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M79.531 77.969 56.25 54.688c5.313-5.782 8.594-13.594 8.594-22.188 0-17.813-14.688-32.5-32.5-32.5C14.375 0 0 14.688 0 32.5 0 50.469 14.531 65 32.344 65c8.437 0 16.25-3.281 22.031-8.594l23.281 23.282c.313.312.625.312.938.312.156 0 .625 0 .781-.313.625-.468.625-1.25.156-1.718ZM32.344 62.5c-16.563 0-30-13.438-30-30 0-16.406 13.437-30 30-30 16.406 0 30 13.594 30 30 0 16.563-13.438 30-30 30Z"
        fill="#B3B6BC"
      />
    </svg>
  );
};

export default MagnifyingGlassIcon;
