import { getLocalStorageItem } from 'player/src/actions/localStorage.actions';
import * as Tracker from 'player/src/tracker/Tracker';
import { track } from 'player/src/tracker/Tracker';
import Utils from 'player/src/utils';
import { subscribeEvent, unsubscribeEvent } from 'player/src/utils/events.utils';
import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import TrackerProviderWrapper from 'shared/react/components/complex/TrackerProviderWrapper';
import { AccountProvider } from 'shared/react/components/complex/context/AccountContext';
import { CartProvider } from 'shared/react/components/complex/context/CartContext';
import { CustomerLoginProvider } from 'shared/react/components/complex/context/CustomerLoginContext';
import { DesignSettingsProvider } from 'shared/react/components/complex/context/DesignSettingsContext';
import { FeaturesProvider } from 'shared/react/components/complex/context/FeaturesContext';
import { FeedSnackbarProvider } from 'shared/react/components/complex/context/FeedSnackbarContext';
import { FeedStateProvider } from 'shared/react/components/complex/context/FeedStateContext';
import { ModalProvider } from 'shared/react/components/complex/context/ModalContext';
import { ProductRecommendationsProvider } from 'shared/react/components/complex/context/ProductRecommendationsContext';
import { ProductsProvider } from 'shared/react/components/complex/context/ProductsContext';
import { ProjectConfigProvider } from 'shared/react/components/complex/context/ProjectConfigContext';
import { RechargeProvider } from 'shared/react/components/complex/context/RechargeContext';
import { SwipeUpAnimationProvider } from 'shared/react/components/complex/context/SwipeUpAnimationContext';
import { TranslationProvider } from 'shared/react/components/complex/context/TranslationContext';
import { getCssDirection } from 'shared/react/components/complex/context/hooks/useTranslation';
import {
  constructStepsMapping,
  getIsPlayerVisibleUponStartup,
  getIsSingleStep,
} from 'shared/react/components/complex/feed/feed_utils/feed.utils';
import { DEFAULT_DESIGN_SETTINGS } from 'shared/react/constants/design';
import { CUSTOMER_LOGIN_STATE } from 'shared/react/constants/feature-constants/customerLogin.constants';
import { FEATURE_RECHARGE_INTEGRATION } from 'shared/react/constants/features.constants';
import { SWIPE_UP_ANIMATION_LOCAL_STORAGE_KEY } from 'shared/react/constants/feed.constants';
import {
  FEED_PAUSE_MESSAGE,
  FEED_PLAY_MESSAGE,
  VIDEO_UN_MUTED_MESSAGE,
} from 'shared/react/constants/messages.constants';
import { PLAYER_TYPE } from 'shared/react/constants/tolstoy.constants';
import SharedUtils from 'shared/react/utils/utils';
import {
  IS_SHOPIFY_STORE,
  SHOPIFY_ROOT_ROUTE_PARAM,
  SHOPIFY_STORE_URL_PARAM,
} from '../../constants/urls.constants';
import { PRODUCT_METAFIELDS_EVENTS, EVENTS_NAMES } from 'shared/react/constants/events.constants';
import { setAuthToken, setAuthTokenToLocalStorage } from 'shared/react/utils/wishlist.utils';
import useWishlist from 'shared/react/hooks/useWishlist';

// parcel workaround
const importLangData = ({ language }) => {
  switch (language) {
    case 'arabic':
      return import('shared/react/i18n/local/arabic.json');
    case 'bulgarian':
      return import('shared/react/i18n/local/bulgarian.json');
    case 'czech':
      return import('shared/react/i18n/local/czech.json');
    case 'danish':
      return import('shared/react/i18n/local/danish.json');
    case 'english':
      return import('shared/react/i18n/local/english.json');
    case 'finnish':
      return import('shared/react/i18n/local/finnish.json');
    case 'french':
      return import('shared/react/i18n/local/french.json');
    case 'ukrainian':
      return import('shared/react/i18n/local/ukrainian.json');
    case 'german':
      return import('shared/react/i18n/local/german.json');
    case 'hebrew':
      return import('shared/react/i18n/local/hebrew.json');
    case 'italian':
      return import('shared/react/i18n/local/italian.json');
    case 'japanese':
      return import('shared/react/i18n/local/japanese.json');
    case 'polish':
      return import('shared/react/i18n/local/polish.json');
    case 'portuguese_brazil':
      return import('shared/react/i18n/local/portuguese_brazil.json');
    case 'romanian':
      return import('shared/react/i18n/local/romanian.json');
    case 'slovak':
      return import('shared/react/i18n/local/slovak.json');
    case 'spanish_latin_american':
      return import('shared/react/i18n/local/spanish_latin_american.json');
    case 'lithuanian':
      return import('shared/react/i18n/local/lithuanian.json');
    case 'dutch':
      return import('shared/react/i18n/local/dutch.json');
  }
};

const RootProviders = ({
  config,
  searchControls,
  products,
  isProductsInitialized,
  children,
  isPlayerPreview,
}) => {
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const isFeedMuted = !!config?.design?.player?.controls?.isFeedMuted;
  const isInitialMutedByDefault =
    !!config?.design?.player?.controls?.isInitialMuted || config?.playerSettings?.feedStartOnMute;
  const isMutedByDefault = !Utils.getIsLandingPage() || isPlayerPreview || isInitialMutedByDefault;
  const [modalsStack, setModalsStack] = useState([]);
  const [isUserMute, setIsUserMute] = useState(isMutedByDefault);
  const [isWishlistRequestInProcess, setIsWishlistRequestInProcess] = useState(false);

  const [isInitialMute, setIsInitialMute] = useState(isMutedByDefault);
  const isTapcart = Utils.getIsTapcart();
  const playerType = urlParams.get(PLAYER_TYPE);
  const isShopifyStore = urlParams.get(IS_SHOPIFY_STORE) === 'true';
  const shopifyStoreUrl = urlParams.get(SHOPIFY_STORE_URL_PARAM);
  const shopifyRootRoute = urlParams.get(SHOPIFY_ROOT_ROUTE_PARAM);
  const [isPlayerVisible, setPlayerVisible] = useState(getIsPlayerVisibleUponStartup(playerType));
  const [isSessionStart, setIsSessionStart] = useState(true);
  const [messagingRequestDetails, setMessagingRequestDetails] = useState({});
  const [shopifyResponse, setShopifyResponse] = useState(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isVisibilityChangedPause, setIsVisibilityChangedPause] = useState(false);
  const [langText, setLangText] = useState({});
  const [hasSeenSwipeUpAnimation, setHasSeenSwipeUpAnimation] = useState(
    getLocalStorageItem(SWIPE_UP_ANIMATION_LOCAL_STORAGE_KEY)
  );
  const [cart, setCart] = useState({});
  const [snackbarText, setSnackbarText] = useState('');
  const [productRecommendationsMap, setProductRecommendationsMap] = useState({});
  const [showAutoplayUnmute, setShowAutoplayUnmute] = useState(false);
  const [isRebuyAppInstalled, setIsRebuyAppInstalled] = useState(false);
  const [isAfterpayAppInstalled, setIsAfterpayAppInstalled] = useState(false);
  const [rechargeWidgetData, setRechargeWidgetData] = useState(null);
  const [rechargeCartItem, setRechargeCartItem] = useState(null);
  const [customerLoginState, setCustomerLoginState] = useState(
    CUSTOMER_LOGIN_STATE.UNAUTHENTICATED
  );
  const [customerLoginStatusCode, setCustomerLoginStatusCode] = useState(null);
  const [productsExtraData, setProductsExtraData] = useState({});
  const [isProductModalDisabled, setIsProductModalDisabled] = useState(!!isTapcart);
  const [urlLocale, setUrlLocale] = useState('/');
  const [buttonsRefLength, setButtonsRefLength] = useState(0);

  const [, { setCustomerWishlist }] = useWishlist();
  const appUrl = Object.values(products)[0]?.appUrl;
  const customerId = urlParams.get('customerId');
  const {
    id: projectId,
    featureSettings,
    appKey,
    owner,
    steps,
    feedAutoScroll,
    publishId,
    feedIsAnyStepShoppable,
    playerSettings = {},
    pdpConfig,
    dynamic,
    design,
    isMultipass,
    yotpoAppKey,
  } = config;

  const isSingleStepFeed = getIsSingleStep(steps);
  const stepsMap = constructStepsMapping(steps, isSingleStepFeed);
  const language = config.language || design?.player?.settings?.language;
  const isRechargeEnabled =
    featureSettings?.[FEATURE_RECHARGE_INTEGRATION] &&
    playerSettings?.productDetailsPriceEnabled &&
    playerSettings?.productDetailsSubscriptionsEnabled;
  const isCheckoutShoppingBagEnabled =
    playerSettings?.productDetailsCheckoutShoppingBagEnabled ??
    design?.player?.controls?.checkoutShoppingBagEnabled ??
    true;
  const showDescriptionIfNoProducts =
    dynamic && (playerSettings?.showDescriptionIfNoProducts ?? true);
  const buttonsRef = useRef({});

  const initLanguages = async () => {
    if (!language || language === 'english') {
      return;
    }

    const data = await importLangData({ language });

    if (!data) {
      return;
    }

    const html = document.querySelector('html');
    html.setAttribute('lang', language);
    html.style.direction = getCssDirection(language);

    setLangText(data);
  };

  const handleInitialUnmute = () => {
    if (isInitialMutedByDefault) {
      return;
    }

    setShowAutoplayUnmute(false);
    setIsInitialMute(false);
    setIsUserMute(false);
    track(VIDEO_UN_MUTED_MESSAGE);
  };

  const handlePlayPause = step => {
    if (isPlaying && (isInitialMute || showAutoplayUnmute) && !isInitialMutedByDefault) {
      handleInitialUnmute();
      return;
    }

    const videoName = step?.videoName;
    const message = isPlaying ? FEED_PAUSE_MESSAGE : FEED_PLAY_MESSAGE;
    track(message, { videoName });

    SharedUtils.postMessageToWindow({
      name: message,
    });

    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    initLanguages();
  }, [language]);

  const feedStateContext = {
    isFeedMuted,
    isInitialMutedByDefault,
    isInitialMute,
    isUserMute,
    setIsUserMute,
    setIsInitialMute,
    isPlayerVisible,
    setPlayerVisible,
    isSessionStart,
    setIsSessionStart,
    isShopifyStore,
    shopifyRootRoute,
    messagingRequestDetails,
    setMessagingRequestDetails,
    shopifyResponse,
    setShopifyResponse,
    isPlaying,
    setIsPlaying,
    handlePlayPause,
    showAutoplayUnmute,
    setShowAutoplayUnmute,
    isProductsInitialized,
    isCheckoutShoppingBagEnabled,
    yotpoAppKey,
    isRebuyAppInstalled,
    setIsRebuyAppInstalled,
    isTapcart,
    isAfterpayAppInstalled,
    setIsAfterpayAppInstalled,
    isProductModalDisabled,
    setIsProductModalDisabled,
    searchControls,
    urlLocale,
    setUrlLocale,
    isPlayerPreview,
    currentPageDbProductId: Utils.getCurrentPageDbProductId(),
    buttonsRef,
    buttonsRefLength,
    setButtonsRefLength,
    isWishlistRequestInProcess,
    setIsWishlistRequestInProcess,
  };

  const projectConfigContext = {
    projectId,
    steps,
    stepsMap,
    customerId,
    isFeedAutoScroll: feedAutoScroll,
    publishId,
    appUrl: appUrl || shopifyStoreUrl,
    isShoppable: playerSettings?.isShoppable ?? true,
    feedIsAnyStepShoppable,
    playerSettings,
    showDescriptionIfNoProducts,
    pdpConfig,
    dynamic,
    isMultipass,
    isUseMuxVideo: config?.isUseMuxVideo ?? false,
  };

  const accountContext = { appKey, owner };

  const modalContext = {
    modalsStack,
    setModalsStack,
  };

  const swipeUpAnimationContext = {
    hasSeenSwipeUpAnimation,
    setHasSeenSwipeUpAnimation,
  };

  const cartContext = {
    cart,
    setCart,
  };

  const customerLoginContext = {
    customerLoginState,
    setCustomerLoginState,
    customerLoginStatusCode,
    setCustomerLoginStatusCode,
  };

  const snackbarContext = {
    snackbarText,
    setSnackbarText,
  };

  const productRecommendationsContext = {
    productRecommendationsMap,
    setProductRecommendationsMap,
  };

  const rechargeContext = {
    isRechargeEnabled,
    rechargeWidgetData,
    setRechargeWidgetData,
    rechargeCartItem,
    setRechargeCartItem,
  };

  const translationContext = { translationObj: langText, language };

  const handleVisibilityChange = () => {
    const changedToHiddenAndPlaying = document.visibilityState === 'hidden' && isPlaying;
    const changedToVisibleAndNotPlaying = document.visibilityState === 'visible' && !isPlaying;

    if (changedToHiddenAndPlaying) {
      setIsPlaying(false);
      setIsVisibilityChangedPause(true);
    }

    if (changedToVisibleAndNotPlaying && isVisibilityChangedPause) {
      setIsPlaying(true);
      setIsVisibilityChangedPause(false);
    }
  };

  useEffect(() => {
    subscribeEvent(EVENTS_NAMES.VISIBILITY_CHANGE, handleVisibilityChange);

    return () => {
      unsubscribeEvent(EVENTS_NAMES.VISIBILITY_CHANGE, handleVisibilityChange);
    };
  }, [isPlaying, isVisibilityChangedPause]);

  useEffect(() => {
    if (!Object.keys(products || {}).length) {
      return;
    }

    const onSetProducts = event => {
      if (event.data.eventName === PRODUCT_METAFIELDS_EVENTS.returnProductsMetafields) {
        setProductsExtraData(event.data.productsExtraData);
      }
    };

    window.addEventListener('message', onSetProducts);

    const productIds = Object.values(products).map(({ id }) => {
      return id;
    });

    SharedUtils.postMessageToWindow({
      eventName: PRODUCT_METAFIELDS_EVENTS.getProductsMetafields,
      productIds,
    });

    return () => {
      window.removeEventListener('message', onSetProducts);
    };
  }, [products]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const authToken = params.get('authToken');
    if ((!customerId || !appUrl) && !authToken) {
      return;
    }

    const prepareCustomer = async () => {
      if (authToken) {
        setAuthTokenToLocalStorage(authToken);
        track('customerLoggedInGoogle');
      } else {
        await setAuthToken(customerId, appUrl);
      }
      setCustomerLoginState(CUSTOMER_LOGIN_STATE.LOGGED_IN);
      await setCustomerWishlist();
    };

    prepareCustomer();
  }, [customerId, appUrl]);

  return (
    <TranslationProvider value={translationContext}>
      <TrackerProviderWrapper trackerModule={Tracker}>
        <FeedStateProvider value={feedStateContext}>
          <ProjectConfigProvider value={projectConfigContext}>
            <AccountProvider value={accountContext}>
              <FeaturesProvider value={featureSettings}>
                <ProductsProvider value={{ products, productsExtraData }}>
                  <ModalProvider value={modalContext}>
                    <SwipeUpAnimationProvider value={swipeUpAnimationContext}>
                      <CartProvider value={cartContext}>
                        <FeedSnackbarProvider value={snackbarContext}>
                          <ProductRecommendationsProvider value={productRecommendationsContext}>
                            <CustomerLoginProvider value={customerLoginContext}>
                              <RechargeProvider value={rechargeContext}>
                                <DesignSettingsProvider value={design || DEFAULT_DESIGN_SETTINGS}>
                                  {children}
                                </DesignSettingsProvider>
                              </RechargeProvider>
                            </CustomerLoginProvider>
                          </ProductRecommendationsProvider>
                        </FeedSnackbarProvider>
                      </CartProvider>
                    </SwipeUpAnimationProvider>
                  </ModalProvider>
                </ProductsProvider>
              </FeaturesProvider>
            </AccountProvider>
          </ProjectConfigProvider>
        </FeedStateProvider>
      </TrackerProviderWrapper>
    </TranslationProvider>
  );
};

export default RootProviders;
