import React from 'react';
import { PRODUCT_SEARCH_INPUT_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import styled from 'styled-components';
import SearchBar from 'app/src/complex_components/SearchBar';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import SearchIcon from '@material-ui/icons/Search';
import { CircularProgress } from '@material-ui/core';
import InputWithIcon from 'app/src/basic_components/InputWithIcon';
import LinkIcon from 'shared/react/images/share/LinkIcon';

const ProductSearchInput = ({
  onSelectValueChange,
  onInputValueChange,
  isMenuOpen,
  value,
  isLoading,
  fetchProducts,
  selectedApp,
}) => {
  if (!selectedApp) {
    return (
      <InputWithIcon
        value={value}
        onChange={onInputValueChange}
        placeholder="Paste Product URL here"
        isLoading={isLoading}
        icon={<LinkIcon />}
      />
    );
  }

  return (
    <StyledSearchBar
      open={isMenuOpen}
      placeholder="Search for a product"
      value={value}
      onClick={() => fetchProducts(value)}
      endAdornment={
        <SearchIconContainer>
          {isLoading && <StyledCircularProgress size={20} />}
          <StyledSearchIcon onClick={() => fetchProducts(value)} />
        </SearchIconContainer>
      }
      onChange={onSelectValueChange}
      dataTestId={PRODUCT_SEARCH_INPUT_TEST_ID}
    />
  );
};

const StyledSearchBar = styled(SearchBar)`
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  min-height: 48px;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray3};
  border-radius: ${({ open }) => (open ? '8px 8px 0 0' : '8px')};

  & input::placeholder {
    font-size: 14px;
    font-weight: 400;
  }
`;

const SearchIconContainer = styled(Gap8HorizontalFlexWrap)`
  align-items: center;
`;

const StyledSearchIcon = styled(SearchIcon)`
  cursor: pointer;
`;

const StyledCircularProgress = styled(CircularProgress)`
  margin-left: 8px;
`;

export default ProductSearchInput;
