import { useState } from 'react';
import { useBilling } from 'src/hooks/useBillings';
import useIntegrationsVideos from 'src/hooks/useIntegrationsVideos';

export const useGrantPermissions = () => {
  const { isFreePackage } = useBilling();
  const { handleInstagramConnect } = useIntegrationsVideos();

  const [isUpgradeTooltipOpen, setIsUpgradeTooltipOpen] = useState(false);

  const onGrantPermissions = () => {
    if (isFreePackage) {
      setIsUpgradeTooltipOpen(!isUpgradeTooltipOpen);
      return;
    }

    handleInstagramConnect();
  };

  return { isUpgradeTooltipOpen, onGrantPermissions };
};

export default useGrantPermissions;
