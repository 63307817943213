import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import PlusIcon from 'app/src/images/dashboard_v2/PlusIcon';
import XIcon from 'app/src/images/XIcon';
import { App } from 'app/src/types/app';
import MenuWithItems from 'src/complex_components/menu_items/MenuWithItems';
import ThreeVerticalDotsIcon from 'app/src/images/dashboard_v2/ThreeVerticalDotsIcon';
import IconButton from 'app/src/complex_components/IconButton';
import { DesignSizes } from 'app/src/types/design-system';

type Props = {
  apps?: App[];
  selectedAppId?: string;
  initialized: boolean;
  onConnect?: () => void;
  onDisconnect?: (app?: App) => void;
  onSelect?: (app?: App) => void;
};

const SidebarIntegrationMenu = ({
  apps = [],
  selectedAppId,
  initialized,
  onSelect = () => {},
  onConnect = () => {},
  onDisconnect = () => {},
}: Props) => {
  const theme = useTheme();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const hasApps = !!apps.length;

  const handleConnect = (e: React.MouseEvent) => {
    e.stopPropagation();
    onConnect();
  };

  const onMenuClick = e => {
    e.stopPropagation();
    setMenuAnchor(e.currentTarget);
  };

  if (!initialized || (!hasApps && !onConnect)) {
    return null;
  }

  const getItem = (app: App) => {
    const name = app.data.name;
    const handleSelect = () => onSelect(app);
    const handleDisconnect = e => {
      e.stopPropagation();

      onDisconnect(app);
    };

    return {
      text: `@${name}`,
      color: theme.colors.neutralDarker,
      hidden: !name,
      isSelected: app.id === selectedAppId,
      onClick: handleSelect,
      endIcon: (
        <IconButton onClick={handleDisconnect} width="28px" height="28px">
          <XIcon size={16} fill={theme.colors.black} />
        </IconButton>
      ),
    };
  };

  const menuItems = [
    ...apps.flatMap(getItem),
    { separator: true },
    { onClick: onConnect, text: 'Connect', icon: <PlusIcon /> },
  ];

  if (hasApps) {
    return (
      <SidebarIntegrationMenuLayoutRoot onClick={onMenuClick}>
        <IconContainer>
          <ThreeVerticalDotsIcon />
        </IconContainer>
        <MenuWithItems
          anchorElement={menuAnchor}
          onClose={() => setMenuAnchor(null)}
          menuItems={menuItems}
          size={DesignSizes.NORMAL}
        />
      </SidebarIntegrationMenuLayoutRoot>
    );
  }

  return (
    <DisconnectedLayoutRoot onClick={handleConnect}>
      <PlusIcon />
    </DisconnectedLayoutRoot>
  );
};

const IconContainer = styled(HorizontalFlexCentered)`
  width: 20px;
  height: 20px;
  padding: 3px 4px;
  border-radius: 16px;
  transition: 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
  }

  & > svg {
    width: 12px;
    height: 12px;
  }

  & svg > path {
    fill: ${({ theme }) => theme.colors.black};
  }
`;

export const SidebarIntegrationMenuLayoutRoot = styled(HorizontalFlexCentered)`
  border-radius: 4px;
  padding: 6px;
  width: 24px;
  height: 24px;
  display: none;

  svg {
    width: 12px;
    height: 12px;
  }

  path {
    fill: ${({ theme }) => theme.colors.neutralDarker};
  }

  @media (${({ theme }) => theme.breakpoints.mobileMax}) {
    display: flex;
  }
`;

const DisconnectedLayoutRoot = styled(SidebarIntegrationMenuLayoutRoot)`
  border: 1px solid ${({ theme }) => theme.colors.neutralLight};
  display: flex;
`;

export default SidebarIntegrationMenu;
