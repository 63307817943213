import { useEffect, useRef } from 'react';

const useDragVideoPosition = ({ objectPosition, setObjectPosition, videoRef }) => {
  const locationClicked = useRef(null);
  const currentObjectPosition = useRef(objectPosition);
  const debounceRef = useRef(null);

  const onMouseMove = e => {
    const location = locationClicked.current;
    const currentPosition = currentObjectPosition.current;
    if (!location) {
      return;
    }

    clearTimeout(debounceRef.current);

    const mousePositionX = e.targetTouches ? e.targetTouches[0].pageX : e.clientX;
    const mousePositionY = e.targetTouches ? e.targetTouches[0].pageY : e.clientY;

    let x = Math.round(currentPosition.x - (mousePositionX - location.x));

    if (x > 100) {
      x = 100;
    }

    if (x < 0) {
      x = 0;
    }

    let y = Math.round(currentPosition.y - (mousePositionY - location.y));

    if (y > 100) {
      y = 100;
    }

    if (y < 0) {
      y = 0;
    }

    videoRef.current.style.objectPosition = `${x}% ${y}%`;
    locationClicked.current = { x: mousePositionX, y: mousePositionY };
    currentObjectPosition.current = { x, y };
    debounceRef.current = setTimeout(() => {
      setObjectPosition({ x, y });
    }, 300);
  };

  const onMouseDown = e => {
    const x = 'targetTouches' in e ? e.targetTouches[0].pageX : e.clientX;
    const y = 'targetTouches' in e ? e.targetTouches[0].pageY : e.clientY;
    locationClicked.current = { x, y };
  };

  const onMouseUp = () => {
    locationClicked.current = null;
  };

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    window.addEventListener('mouseup', onMouseUp);
    videoRef.current.addEventListener('mousemove', onMouseMove);
    videoRef.current.addEventListener('mousedown', onMouseDown);
    return () => {
      window.removeEventListener('mouseup', onMouseUp);
      if (!videoRef.current) {
        return;
      }

      videoRef.current.removeEventListener('mousemove', onMouseMove);
      videoRef.current.removeEventListener('mousedown', onMouseDown);
    };
  }, [videoRef, objectPosition, setObjectPosition]);
};

export default useDragVideoPosition;
