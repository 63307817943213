import moveArray from 'array-move';
import {
  useVodProjectIdConnectionActions,
  useCreateVodConnection,
} from 'src/hooks/vod-connections';
import { useSelectedApp } from 'src/context/AppsStore';
import { useVodConnection } from 'src/context/VodConnectionStore';
import { useProductActions } from 'app/src/context/ProductsStore';
import { VodConnectionType } from 'src/types/entities';
import { sortEntities } from 'src/hooks/use-dynamic-video-ids-by-project/checkRules';

export type UseProductActions = {
  movePDPVideo: (params: { index: number; draggingFromIndex: number; productId?: string }) => void;
  removeManualPDPVideo: (params: { videoId: string; externalProductId: string }) => Promise<any>;
  addManualPDPVideo: (params: {
    videoId: string;
    provider?: string;
    productId?: string;
    externalProductId?: string;
  }) => Promise<any>;
};

const useDynamicTypeProductsActions = ({
  project,
  products,
  setProducts,
  videoRules,
}): UseProductActions => {
  const [selectedApp] = useSelectedApp();
  const { createProducts } = useProductActions();
  const [{ productsByVodAssetMap }, { deleteConnectionByProductId }] = useVodConnection();
  const { createVodConnection } = useCreateVodConnection();
  const { createVideoProjectIdConnection, deleteVodProjectIdConnection } =
    useVodProjectIdConnectionActions();

  const movePDPVideo = ({ index, draggingFromIndex, productId }) => {
    const newProducts = products.map(({ product, videos }) => {
      if (product.productId !== productId) {
        return { product, videos };
      }

      const newVideos = moveArray([...videos], draggingFromIndex, index);
      const sortedEntities = sortEntities(newVideos, [videoRules], productId);

      return {
        product,
        videos: sortedEntities,
      };
    });

    setProducts(newProducts);
  };

  const addManualPDPVideo = async ({ videoId, provider, productId, externalProductId }) => {
    if (!videoId || !project.id || !externalProductId) {
      return;
    }

    let productDbId = productId;

    if (!productDbId && selectedApp) {
      const newProduct = (await createProducts([externalProductId], selectedApp.appUrl))?.[0];

      productDbId = newProduct?.id;
    }

    if (!productDbId) {
      return;
    }

    return Promise.all([
      createVideoProjectIdConnection({
        appUrl: project?.appUrl,
        projectId: project.id,
        vodAssetId: videoId,
      }),
      createVodConnection({
        provider,
        productId: productDbId,
        externalProductId,
        vodAssetId: videoId,
        appUrl: project?.appUrl,
        type: VodConnectionType.productId,
      }),
    ]);
  };

  const removeManualPDPVideo = async ({ videoId, externalProductId }) => {
    const shouldRemoveProjectIdConnection =
      productsByVodAssetMap[videoId]?.[project.appUrl]?.length <= 1;

    if (!videoId || !externalProductId) {
      return;
    }

    if (shouldRemoveProjectIdConnection) {
      await deleteVodProjectIdConnection({ vodAssetId: videoId, projectId: project.id });
    }

    return deleteConnectionByProductId({
      externalProductId,
      vodAssetId: videoId,
    });
  };

  return {
    movePDPVideo,
    addManualPDPVideo,
    removeManualPDPVideo,
  };
};

export default useDynamicTypeProductsActions;
