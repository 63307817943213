import styled from 'styled-components';
import { TextSmall } from 'shared/react/components/basic/text/TextV2';

export const SidebarItemName = styled(TextSmall)`
  flex: 1 1 132px;
  font-weight: ${({ isTopLevel }) => (isTopLevel ? '500' : '400')};
  margin-right: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ isTopLevel, theme }) =>
    isTopLevel ? theme.colors.neutralBlack : theme.colors.neutralDarkest};
`;

export default SidebarItemName;
