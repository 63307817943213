import React, { useEffect, useState } from 'react';
import useWishlist from 'shared/react/hooks/useWishlist';
import useFeedProducts from '../../context/hooks/useFeedProducts';
import FeedWishlistProductGrid from './FeedWishlistProductGrid';
import useOpenProductModal from '../../context/hooks/useOpenProductModal';
import Utils from '../../../../utils/utils';
import { TOGGLE_FEED_CLOSE_BUTTON_MESSAGE } from '../../../../constants/messages.constants';

const FeedWishlistSidePanel = ({ currentStepKey }) => {
  const [products] = useFeedProducts();
  const [customerWishlist, setCustomerWishlist] = useState();
  const [{}, { getCustomerWishlist }] = useWishlist();
  const { handleOpenProductModal } = useOpenProductModal({ step: {}, currentStepKey });

  const handleProductClick = (e, product) => {
    handleOpenProductModal(e, {
      product,
      externalProductIds: [product.id],
      customProps: {
        customProduct: product,
      },
    });
  };

  const toggleFeedCloseButton = ({ hideCloseButton }) => {
    Utils.postMessageToWindow({
      eventName: TOGGLE_FEED_CLOSE_BUTTON_MESSAGE,
      hideCloseButton,
      hideOnFullScreen: true,
    });
  };

  useEffect(() => {
    const setWishlist = async () => {
      const wishlist = await getCustomerWishlist();
      setCustomerWishlist(wishlist);
    };
    toggleFeedCloseButton({ hideCloseButton: true });
    setWishlist();
  }, []);

  if (!products || !customerWishlist) {
    return null;
  }

  return (
    <FeedWishlistProductGrid
      products={products}
      customerWishlist={customerWishlist}
      onProductClick={handleProductClick}
      isSidePanel={true}
    ></FeedWishlistProductGrid>
  );
};

export default FeedWishlistSidePanel;
