import React from 'react';
import styled, { css } from 'styled-components';
import { CARD_LAYOUT_TEST_ID } from 'app/src/constants/dataTestIds.constants';

function Card({
  children,
  onClick,
  excludeTopTranslation,
  shadowAnimationsEnabled,
  disabled,
  ...props
}) {
  const handleOnClick = () => {
    if (disabled) {
      return;
    }

    onClick?.();
  };

  return (
    <LayoutRoot
      data-test-id={CARD_LAYOUT_TEST_ID}
      onClick={handleOnClick}
      shadowAnimationsEnabled={shadowAnimationsEnabled}
      excludeTopTranslation={excludeTopTranslation}
      disabled={disabled}
      {...props}
    >
      {children}
    </LayoutRoot>
  );
}

export default Card;

const LayoutRoot = styled.div`
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};
  height: 100%;
  width: 100%;
  display: grid;
  border-radius: 16px;
  background-color: white;
  box-shadow: 0 2px 10px rgba(50, 50, 93, 0.1), 0 1px 3px -1px rgba(0, 0, 0, 0.05);
  transform: translateY(0px);
  transition: all 0.25s;

  ${({ shadowAnimationsEnabled }) =>
    shadowAnimationsEnabled
      ? css`
          &:hover {
            box-shadow: 0 5px 15px rgba(50, 50, 93, 0.1), 0 2px 5px rgba(0, 0, 0, 0.06);
            transform: translateY(
              ${({ disabled, excludeTopTranslation }) =>
                disabled ? '0px' : excludeTopTranslation ? '0px' : '-3px'}
            );
          }

          &:focus,
          &:active {
            box-shadow: 0 5px 15px rgba(50, 50, 93, 0.1), 0 2px 5px rgba(0, 0, 0, 0.1);
            transform: translateY(
              ${({ disabled, excludeTopTranslation }) =>
                disabled ? '0px' : excludeTopTranslation ? '0x' : '-3px'}
            );
          }
        `
      : ''}

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    transform: translateY(0px);

    ${({ shadowAnimationsEnabled }) =>
      shadowAnimationsEnabled
        ? css`
            &:hover {
              transform: translateY(0px);
            }

            &:focus,
            &:active {
              transform: translateY(0px);
            }
          `
        : ''}
  }
`;
