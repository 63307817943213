import React from 'react';
import styled from 'styled-components';

const LineClampWrapper = ({ numberOfLines = 3, className = '', children }) => {
  return (
    <LayoutRoot className={className} numberOfLines={numberOfLines}>
      {children}
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: ${({ numberOfLines }) => numberOfLines};
  -webkit-box-orient: vertical;
  white-space: break-spaces;
  word-break: break-word;
  overflow: hidden;
`;

export default LineClampWrapper;
