import React from 'react';

const TextIcon = ({ fill, darkBackground }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0015 19.7221C15.371 19.7221 19.7238 15.3693 19.7238 9.99984C19.7238 4.63039 15.371 0.277588 10.0015 0.277588C4.6321 0.277588 0.279297 4.63039 0.279297 9.99984C0.279297 15.3693 4.6321 19.7221 10.0015 19.7221Z"
        fill={fill || '#FFFFFF'}
      />
      <path
        d="M14.7389 6.01571C14.7389 5.51441 14.3288 5.10425 13.8275 5.10425H6.17122C5.64713 5.10425 5.25977 5.51441 5.25977 6.01571V7.10946C5.25977 7.63355 5.64713 8.02091 6.17122 8.02091C6.67252 8.02091 7.08268 7.63355 7.08268 7.10946V6.92716H9.08785C9.08785 6.97274 9.08785 6.99552 9.08785 7.01831V13.8542H8.17643C7.76627 13.8542 7.44727 14.1961 7.44727 14.5834C7.44727 14.9936 7.76627 15.3126 8.17643 15.3126H11.8223C12.2096 15.3126 12.5514 14.9936 12.5514 14.5834C12.5514 14.1961 12.2096 13.8542 11.8223 13.8542H10.9108V7.01831C10.9108 6.99552 10.888 6.97274 10.888 6.92716H12.916V7.10946C12.916 7.63355 13.3033 8.02091 13.8275 8.02091C14.3288 8.02091 14.7389 7.63355 14.7389 7.10946V6.01571Z"
        fill={darkBackground ? '#FFFFFF' : '#2F3137'}
      />
    </svg>
  );
};

export default TextIcon;
