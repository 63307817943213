import React from 'react';

const ViewAll = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.25 4.52344L5.875 10.8984C5.78125 11.0156 5.64062 11.0625 5.5 11.0625C5.33594 11.0625 5.19531 11.0156 5.10156 10.8984L1.72656 7.52344C1.49219 7.3125 1.49219 6.96094 1.72656 6.75C1.9375 6.51562 2.28906 6.51562 2.5 6.75L5.5 9.72656L11.4766 3.75C11.6875 3.51562 12.0391 3.51562 12.25 3.75C12.4844 3.96094 12.4844 4.3125 12.25 4.52344Z"
        fill="#0E1658"
      />
    </svg>
  );
};

export default ViewAll;
