import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import FilterButton from 'app/src/pages/dashboard/pages/libraries/project_filters/FilterButton';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import ResetFiltersIcon from 'app/src/images/dashboard_v2/ResetFiltersIcon';
import Tooltip from 'app/src/basic_components/Tooltip';
import TeamMembersMenu from 'app/src/pages/dashboard/pages/libraries/project_filters/TeamMembersMenu';
import {
  CREATED_BY_ME_PROJECT_FILTER,
  CREATED_BY_TEAMMATE_PROJECT_FILTER,
  FOLDER_FILTER,
  PROJECT_FILTERS_TEXT_MAP,
  PUBLISH_METHOD_FILTER,
} from 'app/src/constants/filters.constants';
import { useAccount } from 'app/src/context/AccountStore';
import { track } from 'app/src/helpers/Tracker';
import FoldersMenu from 'app/src/pages/dashboard/pages/libraries/project_filters/FoldersMenu';
import FolderOutlinedIcon from 'app/src/images/dashboard_v2/FolderOulinedIcon';
import { FOLDERS_FILTER_TEST_ID } from 'app/src/constants/dataTestIds.constants';
import PublishMethodFilterMenu from './publish_method_filter/PublishMethodFilterMenu';
import { PUBLISH_METHODS } from 'shared/react/constants/tolstoy.constants';
import Dot from 'app/src/complex_components/Dot';

const ProjectFilters = ({
  selectedFolder,
  folders,
  projectFilters,
  setProjectFilters,
  resetProjectFilters,
  selectedTeamMember,
  setSelectedTeamMember,
  selectedPublishMethod,
}) => {
  const theme = useTheme();
  const [{ isAccountOwner }] = useAccount();
  const [anchorEl, setAnchorEl] = useState(false);
  const [folderAnchorEl, setFolderAnchorEl] = useState(false);
  const [publishMethodAnchorEl, setPublishMethodAnchorEl] = useState(false);

  const handleFilterClicked = (e, filterKey, active) => {
    track('Filter Click', { type: filterKey, active: !active });
    if (filterKey === CREATED_BY_TEAMMATE_PROJECT_FILTER) {
      setAnchorEl(e.currentTarget);
      return;
    }

    if (filterKey === FOLDER_FILTER) {
      setFolderAnchorEl(e.currentTarget);
      return;
    }

    if (filterKey === PUBLISH_METHOD_FILTER) {
      setPublishMethodAnchorEl(e.currentTarget);
      return;
    }

    setProjectFilters({ ...projectFilters, [filterKey]: !active });
  };

  const handleResetFilters = () => {
    track('Reset Filters Click', { location: 'Filters' });
    resetProjectFilters(isAccountOwner);
  };

  const getFolderFilterText = () => {
    if (!selectedFolder) {
      return 'Folder';
    }

    return selectedFolder.name;
  };

  const getPublishMethodIcon = () => {
    if (!PUBLISH_METHODS[selectedPublishMethod]?.color) {
      return null;
    }

    return <Dot color={theme.colors[PUBLISH_METHODS[selectedPublishMethod].color]} />;
  };

  const getIcon = filterKey => {
    if (filterKey === FOLDER_FILTER) {
      return selectedFolder?.id ? <FolderOutlinedIcon /> : null;
    }

    if (filterKey === PUBLISH_METHOD_FILTER) {
      return getPublishMethodIcon();
    }

    return null;
  };

  const getFilterText = filterKey => {
    if (filterKey === FOLDER_FILTER) {
      return getFolderFilterText();
    }

    if (filterKey === PUBLISH_METHOD_FILTER) {
      const selectedText = PUBLISH_METHODS[selectedPublishMethod]?.text;
      return selectedText ? selectedText : PROJECT_FILTERS_TEXT_MAP[PUBLISH_METHOD_FILTER];
    }

    if (filterKey === CREATED_BY_TEAMMATE_PROJECT_FILTER) {
      const { title } = selectedTeamMember || {};
      return `${PROJECT_FILTERS_TEXT_MAP[filterKey]} ${title || ''}`;
    }

    return PROJECT_FILTERS_TEXT_MAP[filterKey];
  };

  const onTeamMemberClick = teamMember => {
    setSelectedTeamMember(teamMember);
  };

  useEffect(() => {
    setProjectFilters({ ...projectFilters, [FOLDER_FILTER]: !!selectedFolder });
  }, [selectedFolder]);

  useEffect(() => {
    setProjectFilters({ ...projectFilters, [PUBLISH_METHOD_FILTER]: !!selectedPublishMethod });
  }, [selectedPublishMethod]);

  useEffect(() => {
    if (!isAccountOwner) {
      return;
    }

    const {
      [CREATED_BY_ME_PROJECT_FILTER]: _createdByMe,
      [CREATED_BY_TEAMMATE_PROJECT_FILTER]: _createdByTeammate,
      ...filtersWithoutCreatedBy
    } = projectFilters;
    setProjectFilters({
      [CREATED_BY_TEAMMATE_PROJECT_FILTER]: !!selectedTeamMember?.owner,
      ...filtersWithoutCreatedBy,
    });
  }, [isAccountOwner, selectedTeamMember]);

  return (
    <LayoutRoot>
      <FiltersContainer>
        {Object.entries(projectFilters).map(([key, active]) => {
          return (
            <FilterButton
              key={key}
              filterKey={key}
              icon={getIcon(key)}
              text={getFilterText(key)}
              dataTestId={key === FOLDER_FILTER ? FOLDERS_FILTER_TEST_ID : ''}
              active={active}
              onClick={handleFilterClicked}
            />
          );
        })}
      </FiltersContainer>
      <TeamMembersMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        selectedTeamMember={selectedTeamMember}
        onTeamMemberClick={onTeamMemberClick}
      />
      <FoldersMenu folders={folders} anchorEl={folderAnchorEl} setAnchorEl={setFolderAnchorEl} />
      <PublishMethodFilterMenu
        anchorEl={publishMethodAnchorEl}
        setAnchorEl={setPublishMethodAnchorEl}
      />
      <Tooltip text="Reset filters" placement="right">
        <ResetFiltersContainer onClick={handleResetFilters}>
          <ResetFiltersIcon />
        </ResetFiltersContainer>
      </Tooltip>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlexWrap)`
  gap: 24px;
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    flex-wrap: nowrap;
    gap: 16px;
    align-items: baseline;
    width: 100%;
  } ;
`;

const FiltersContainer = styled(Gap8HorizontalFlexWrap)`
  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    flex-wrap: nowrap;
    overflow: auto;
    padding-bottom: 8px;
  } ;
`;

const ResetFiltersContainer = styled(HorizontalFlexWrap)`
  gap: 6px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;
  font-weight: 600;
  width: 100%;
  height: 100%;

  &:hover {
    transform: scale(1.1);
  }
`;

export default ProjectFilters;
