import React from 'react';
import { useBrandingContext } from '../../../BrandingContext';
import EditorSubheader from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorSubheader';
import PrimaryButtonEditor from './productDetailsCard/PrimaryButtonEditor';
import ProductImageEditor from './productDetailsCard/ProductImageEditor';
import StockEditor from './productDetailsCard/StockEditor';
import AdvancedSettingsEditor from './productDetailsCard/AdvancedSettingsEditor';
import InvideoProductCardEditor from './productDetailsCard/InvideoProductCardEditor';

type Props = {
  settings?: any;
  features?: any;
  defaultSettings?: any;
  onChange?: any;
};

const ProductDetailsCardEditor = ({
  settings = {},
  features = {},
  defaultSettings = {},
  onChange = () => {},
}: Props) => {
  const { loading } = useBrandingContext();
  const isLoading = !!loading;
  const isDisabled = isLoading;

  const updateSettings = update => onChange({ ...settings, ...update });
  const handlePrimarySettingsUpdate = primaryButton => updateSettings({ primaryButton });
  const handleProductImageUpdate = productImages => updateSettings({ productImages });
  const handleAdvancedSettingsUpdate = advanced => updateSettings({ advanced });
  const handleInvideoProductCardUpdate = invideoProductCard =>
    updateSettings({ invideoProductCard });
  const handleStockSettingsUpdate = stock => updateSettings({ stock });

  return (
    <>
      <EditorSubheader title="Product details card" />

      <PrimaryButtonEditor
        settings={settings.primaryButton}
        disabled={isDisabled}
        onChange={handlePrimarySettingsUpdate}
      />
      <ProductImageEditor
        settings={settings.productImages}
        disabled={isLoading}
        onChange={handleProductImageUpdate}
      />
      <StockEditor
        settings={settings.stock}
        disabled={isLoading}
        onChange={handleStockSettingsUpdate}
      />
      <AdvancedSettingsEditor
        settings={settings.advanced}
        features={features}
        disabled={isLoading}
        onChange={handleAdvancedSettingsUpdate}
      />
      <InvideoProductCardEditor
        settings={settings.invideoProductCard}
        features={features}
        disabled={isLoading}
        onChange={handleInvideoProductCardUpdate}
      />
    </>
  );
};

export default ProductDetailsCardEditor;
