import React from 'react';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import styled from 'styled-components';
import StandardModalV2 from 'app/src/pages/modals/StandardModalV2';
import { INSTAGRAM_GRAPH_ERROR_CODE_Message } from 'app/src/constants/intergrations.constants';
import { TextBodyBold, TextSmall } from 'shared/react/components/basic/text/TextV2';
import Utils from 'shared/react/utils/utils';

const HELP_URL =
  'https://help.gotolstoy.com/en/articles/8094173-how-do-i-connect-my-instagram-account-to-tolstoy';

const InstagramModal = ({ closeModal }) => {
  const openInNewTab = () => {
    Utils.openInNewTab(HELP_URL);
  };
  return (
    <StyledStandardModal disableEnforceFocus onClose={closeModal} open>
      <LayoutRoot>
        <TextBodyBold>Error connecting Instagram</TextBodyBold>
        <TextSmall>{INSTAGRAM_GRAPH_ERROR_CODE_Message}</TextSmall>
        <TextLink onClick={openInNewTab}>Help article</TextLink>
      </LayoutRoot>
    </StyledStandardModal>
  );
};

const StyledStandardModal = styled(StandardModalV2)``;

const LayoutRoot = styled(VerticalFlex)`
  height: 160px;
  padding: 24px;
  width: 500px;
  border-radius: 4px;
  gap: 8px;
`;

const TextLink = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  transition: 0.3s;
  width: fit-content;

  &:hover {
    color: ${({ theme }) => theme.colors.primaryHover};
  }
`;

export default InstagramModal;
