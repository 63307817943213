import React from 'react';
import {
  defaultResponsesBackgroundColors,
  defaultResponsesIcon,
} from 'app/src/constants/defaultResponseData';
import ButtonPreview from 'app/src/pages/account/branding/ButtonPreview';
import styled from 'styled-components';
import { getLogoIcon } from 'shared/react/utils/icons';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';

const MAX_ANSWERS_LENGTH_FOR_LARGE_BUTTON = 3;
const LARGE_BUTTON_SIZE = '80px';
const MEDIUM_BUTTON_SIZE = '64px';

const StepPreviewButton = ({
  type,
  i,
  text,
  answers,
  isDefaultResponseButtonsEnabled,
  onClick,
  project,
}) => {
  const { player: { branching } } = useDesignSettingsContext();
  if (isDefaultResponseButtonsEnabled) {
    const size =
      answers.length <= MAX_ANSWERS_LENGTH_FOR_LARGE_BUTTON
        ? LARGE_BUTTON_SIZE
        : MEDIUM_BUTTON_SIZE;

    return (
      <Icon onClick={onClick} size={size} backgroundColor={defaultResponsesBackgroundColors[type]}>
        {defaultResponsesIcon(type, { width: '25px', height: '25px' })}
      </Icon>
    );
  }

  const hasAnswersWithImages = answers
    .filter(answer => !answer.disabled)
    .find(answer => {
      return getLogoIcon(answer.type || '', null, answer.next);
    });

  return (
    <ButtonPreview
      index={i}
      text={text}
      type={type}
      circle={answers?.length > 1}
      onClick={onClick}
      brandColor={branching.icons.iconColor}
      borderRadius={branching.borderRadius}
      showButtonsIndex={project.showButtonsIndex ?? branching.icons.shouldDisplayIcon}
      stepAnswersCount={answers?.length || 0}
      hasAnswersWithImages={hasAnswersWithImages}
    />
  );
};

const Icon = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  background: ${({ backgroundColor }) => backgroundColor};
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 500px;
  margin: 0 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  :hover {
    transform: scale(1.06);
  }

  :focus,
  :active {
    transform: scale(1.02);
  }
`;

export default StepPreviewButton;
