import React from 'react';
import styled from 'styled-components';
import XIcon from 'app/src/images/XIcon';

function XButton({ onClick, size, fill, ...props }) {
  return (
    <LayoutRoot onClick={onClick} {...props}>
      <XIcon size={size} fill={fill} />
    </LayoutRoot>
  );
}

export default XButton;

const LayoutRoot = styled.span`
  cursor: pointer;
  display: grid;
  justify-items: center;
  align-items: center;
  text-align: center;
  justify-self: end;
  padding: 8px;
  transform: scale(1);
  transition: 0.3s;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  &:hover {
    opacity: 0.75;
  }

  &:focus,
  &:active {
    opacity: 0.5;
  }

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    margin: 8px;
    padding: 8px;
  }
`;
