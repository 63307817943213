import { css } from 'styled-components';

export const PseudoDashedBorderCss = css`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    border: 1px dashed ${({ theme }) => theme.colors.primary};
    inset: -4px;
    border-radius: 6px;
  }
`;
