import React from 'react';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import { VerticalFlexCentered } from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import styled from 'styled-components';

type Props = {
  videoSrc: string;
  videoSubheader: string;
};

const VideoSpotlight = ({ videoSrc, videoSubheader }: Props) => {
  return (
    <LayoutRoot>
      <Video
        muted
        autoPlay
        loop
        controlsList="nofullscreen nodownload noremoteplayback"
        onContextMenu={e => e.preventDefault()}
        src={videoSrc}
      />
      <VideoSubheader>{videoSubheader}</VideoSubheader>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlexCentered)`
  gap: 4px;
`;

const VideoSubheader = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralDark};
  font-style: italic;
`;

const Video = styled.video`
  width: 178px;
  height: 243px;
  border-radius: 16px;
  border: 4px solid ${({ theme }) => theme.colors.white};
  object-fit: cover;
  box-shadow: ${({ theme }) => theme.boxShadows.elevation4};
`;

export default VideoSpotlight;
