
export const customAccountTeamMembersByAppKey = /* GraphQL */ `
    query AccountTeamMembersByAppKey(
        $appKey: String
        $createdAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelaccountTeamMemberFilterInput
        $limit: Int
        $nextToken: String
    ) {
        accountTeamMembersByAppKey(
            appKey: $appKey
            createdAt: $createdAt
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                owner
                appKey
                email
                role
                status
                inviteToken
                createdAt
                userId
                teamMemberId
                unreadInAppNotificationsCounter
                lastLoginAt
                account {
                    items {
                        id
                        name
                        logoSettings
                    }
                }
                user {
                    items {
                        id
                        owner
                        email
                        firstName
                        lastName
                        logoSettings
                    }
                }
                notificationSettingsId
                updatedAt
                paymentRole
            }
            nextToken
        }
    }
`;

export const customAccountTeamMembersByUserId = /* GraphQL */ `
    query AccountTeamMembersByUserId(
        $userId: String
        $lastLoginAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelaccountTeamMemberFilterInput
        $limit: Int
        $nextToken: String
    ) {
        accountTeamMembersByUserId(
            userId: $userId
            lastLoginAt: $lastLoginAt
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                owner
                appKey
                email
                role
                status
                inviteToken
                createdAt
                userId
                teamMemberId
                unreadInAppNotificationsCounter
                lastLoginAt
                account {
                    items {
                        id
                        name
                        logoSettings
                        packageGroup
                        packageId
                        widgetSettings
                    }
                }
                user {
                    items {
                        id
                        owner
                        email
                        firstName
                        lastName
                        logoSettings
                    }
                }
                notificationSettingsId
                updatedAt
                paymentRole
            }
            nextToken
        }
    }
`;

export const videosByAppKey = /* GraphQL */ `query VideosByAppKey(
  $appKey: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelvodAssetFilterInput
  $limit: Int
  $nextToken: String
) {
  videosByAppKey(
    appKey: $appKey
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      owner
      appKey
      appExternalId
      createdAt
      name
      description
      isSoundAllowed
      status
      captionsSettings
      uploadType
      stockAsset {
        videoUrl
        posterUrl
        gifUrl
        hasOriginal
        shopifyFileId
        previewUrl
        previewShopifyFileId
        shopifyPosterUrl
        posterShopifyFileId
        avatarUrl
        audioUrl
      }
      duration
      folder
      width
      height
      isVertical
      isHDR
      trimSettings
      originalVOD
      mirror
      recorderVersion
      originalSize
      externalId
      externalProviderData
      isMigratingToShopify
      shopifyUploadingStatus
      isEverUsed
      referenceIds
      updatedAt
    }
    nextToken
  }
}
`
