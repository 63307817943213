import { VIDEO_OUTPUT } from 'app/src/config/app.config';
import { useVideoActions } from 'app/src/context/VideosStore';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';

const useHeroPreview = () => {
  const { project, customizationSettings } = useLookAndFeelContext();
  const { getVideoById } = useVideoActions();
  const steps = project.steps.items || [];
  const videoMapping = project.steps.items.reduce((acc, step) => {
    if (step.videoId) {
      acc[step.videoId] = getVideoById(step.videoId);
    }

    return acc;
  }, {});

  const getVideoBaseUrl = owner => {
    return `${VIDEO_OUTPUT}/public/${owner}`;
  };

  const getVideoUrl = ({
    id,
    owner,
    stockAsset,
  }: {
    id: string;
    owner: string;
    stockAsset?: { videoUrl: string };
  }) => {
    if (stockAsset?.videoUrl) {
      return stockAsset.videoUrl;
    }

    const baseUrl = getVideoBaseUrl(owner);
    return `${baseUrl}/${id}/${id}.mp4`;
  };

  const getPosterUrl = ({
    id,
    owner,
    stockAsset,
  }: {
    id: string;
    owner: string;
    stockAsset?: { shopifyPosterUrl: string; posterUrl: string };
  }) => {
    const posterUrl = stockAsset?.shopifyPosterUrl || stockAsset?.posterUrl;
    if (posterUrl) {
      return posterUrl;
    }

    const baseUrl = getVideoBaseUrl(owner);
    return `${baseUrl}/${id}/${id}.0000000.jpg`;
  };

  const prepareConfig = ({ project, projectSteps, videoMapping }) => {
    const newSteps = projectSteps.map(step => {
      if (step.description === 'yotube') {
        return {
          key: step.name,
          type: step.description,
          videoUrl: step.videoId,
          posterUrl: step.question,
        };
      }

      const video = videoMapping[step.videoId];
      return {
        key: step.name,
        type: step.description,
        videoUrl: getVideoUrl(video),
        posterUrl: getPosterUrl(video),
      };
    });

    return {
      id: project.id,
      name: project.name,
      appKey: project.appKey,
      baseUrl: `https://${process.env.REACT_APP_VIDEO_OUTPUT}/public/${project.owner}`,
      appUrl: project.appUrl,
      publishId: project.publishId,
      userId: project.owner,
      link: project.description
        ? JSON.parse(project.description)
        : { url: '', isOpenInNewTab: true },
      steps: newSteps,
      heroSettings: customizationSettings.heroSettings,
    };
  };

  const config = prepareConfig({ project, projectSteps: steps, videoMapping });
  return { config };
};

export default useHeroPreview;
