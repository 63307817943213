import React from 'react';

const VideoSideBar = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#a)">
        <path
          d="M25 46c11.598 0 21-9.402 21-21S36.598 4 25 4 4 13.402 4 25a20.9 20.9 0 0 0 2.81 10.5l-1.76 9.45 9.45-1.76A20.905 20.905 0 0 0 25 46Z"
          fill="#000"
          fillOpacity=".2"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45 25c0 11.046-8.954 20-20 20a19.91 19.91 0 0 1-10-2.676L6 44l1.676-9A19.903 19.903 0 0 1 5 25C5 13.954 13.954 5 25 5s20 8.954 20 20Zm-16.227 4.63v-9.258c0-.835-.708-1.543-1.543-1.543h-9.258c-.867 0-1.542.708-1.542 1.543v9.258c0 .867.675 1.542 1.543 1.542h9.257c.835 0 1.543-.675 1.543-1.543Zm6.171-.483v-8.26c0-.804-.964-1.286-1.64-.836l-3.503 2.443v5.046l3.504 2.443c.675.45 1.639-.032 1.639-.836Z"
        fill="#fff"
      />
      <defs>
        <filter
          id="a"
          x="0"
          y="0"
          width="50"
          height="50"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_1707_79679" />
        </filter>
      </defs>
    </svg>
  );
};

export default VideoSideBar;
