import React from 'react';
import styled, { useTheme } from 'styled-components';

function ColorfulSeparator() {
  const theme = useTheme();
  return (
    <LayoutRoot>
      <ColorLine color={theme.colors.warning} />
      <ColorLine color={theme.colors.info} />
      <ColorLine color={theme.colors.success} />
      <ColorLine color={theme.colors.danger} />
      <ColorLine color={theme.colors.blue12} />
      <ColorLine color={theme.colors.warning} />
      <ColorLine color={theme.colors.info} />
      <ColorLine color={theme.colors.success} />
    </LayoutRoot>
  );
}

export default ColorfulSeparator;

const LayoutRoot = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  width: 100%;
  min-height: 1px;
`;

const ColorLine = styled.div`
  background: ${({ color }) => color};
  height: 100%;
`;
