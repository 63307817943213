import React, { useEffect, useRef } from 'react';
import { DROPBOX } from 'app/src/constants/intergrations.constants';
import { v4 as uuidv4 } from 'uuid';
import { useUser } from 'app/src/context/userStore/UserStore';
import { vodAsset } from 'app/src/types/entities';
import styled from 'styled-components';

type Props = {
  onSelectedVideosAction: (videos: vodAsset[]) => void;
};

const DropboxButton = ({ onSelectedVideosAction }: Props) => {
  const ref = useRef();
  const [{ user }] = useUser();

  const success = files => {
    const normalizedFiles = files.map((file, i) => {
      const id = uuidv4();

      return {
        ...file,
        videoUrl: file.link,
        id,
        externalId: file.id,
        newId: id,
        app: DROPBOX,
        createdAt: new Date().toISOString(),
        owner: user.owner,
        stockAsset: { posterUrl: file.thumbnailLink },
        name: file.name || `Dropbox video #${i + 1}`,
        uploadType: DROPBOX,
      };
    });

    onSelectedVideosAction(normalizedFiles);
  };

  const options = {
    success,
    linkType: 'direct',
    multiselect: true,
    extensions: ['.mp4', '.mov', '.webm', '.quicktime', '.mkv', '.avi', '.wmv', '.yuv'],
  };

  useEffect(() => {
    if (ref?.current && !ref?.current?.children?.length) {
      const button = window.Dropbox.createChooseButton(options);
      ref.current.appendChild(button);
    }
  }, [ref]);

  return <LayoutRoot ref={ref} />;
};

const LayoutRoot = styled.div`
  .dropbox-dropin-btn {
    height: 32px;
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.neutralGray};
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 8px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 600;
  }

  .dropin-btn-status {
    top: unset;
  }
`;

export default DropboxButton;
