import React from 'react';

const FilledCommentsIcon = () => {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 6.125C13 8.82 10.305 11 7 11c-.89 0-1.71-.14-2.46-.398a7 7 0 0 1-1.267.703c-.562.258-1.242.445-1.898.445-.164 0-.305-.07-.352-.21-.07-.142-.023-.306.07-.423.024 0 .024-.023.048-.047l.117-.117c.094-.117.21-.305.351-.515.235-.376.446-.891.493-1.477C1.398 8.164 1 7.203 1 6.125 1 3.453 3.672 1.25 7 1.25c3.305 0 6 2.203 6 4.875"
        fill="#090A0B"
      />
    </svg>
  );
};

export default FilledCommentsIcon;
