import React from 'react';

const AudioSideBar = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_f_2098_142128)">
        <path
          d="M25 46C36.5983 46 46 36.5983 46 25C46 13.4017 36.5983 4 25 4C13.4017 4 4 13.4017 4 25C4 28.8241 5.0227 32.413 6.8098 35.5L5.05 44.95L14.5 43.1902C17.691 45.0362 21.3135 46.0056 25 46Z"
          fill="black"
          fillOpacity="0.2"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45 25C45 36.046 36.046 45 25 45C21.489 45.0053 18.0391 44.0821 15 42.324L6 44L7.676 35C5.974 32.06 5 28.642 5 25C5 13.954 13.954 5 25 5C36.046 5 45 13.954 45 25ZM27.7429 25.2286C27.7429 26.9643 26.3607 28.3143 24.6571 28.3143C22.9214 28.3143 21.5714 26.9322 21.5714 25.2286V20.0857C21.5714 18.3821 22.9214 17 24.6571 17C26.3607 17 27.7429 18.3821 27.7429 20.0857V25.2286ZM28.7714 23.9429C28.7714 23.525 29.0929 23.1714 29.5429 23.1714C29.9607 23.1714 30.3143 23.525 30.3143 23.9429V25.2286C30.3143 28.0893 28.1607 30.4678 25.4286 30.8536V31.9143H26.7143C27.2929 31.9143 27.7429 32.4286 27.7107 33.0072C27.7107 33.2643 27.4857 33.4571 27.2286 33.4571H22.0857C21.7964 33.4571 21.5714 33.2643 21.5714 33.0072C21.5393 32.4286 21.9893 31.9143 22.6 31.9143H23.8857V30.7893C21.025 30.4036 19 27.8322 19 24.9714V23.9429C19 23.525 19.3214 23.1714 19.7714 23.1714C20.1893 23.1714 20.5429 23.525 20.5429 23.9429V25.0678C20.5429 27.2214 22.2464 29.2143 24.3678 29.3429C26.7786 29.5036 28.7714 27.6072 28.7714 25.2286V23.9429Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_f_2098_142128"
          x="0"
          y="0"
          width="50"
          height="50"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_2098_142128" />
        </filter>
      </defs>
    </svg>
  );
};

export default AudioSideBar;
