import React from 'react';

const RedirectButton = () => {
  return (
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.563 3.852 10.187.688C9.954.477 9.602.477 9.392.71c-.211.234-.211.586.023.797l2.344 2.203h-3.82A3.239 3.239 0 0 0 5.43 4.719 3.545 3.545 0 0 0 4.375 7.25v2.063c0 .304.234.539.563.539.304 0 .562-.211.562-.54V7.25c0-.656.234-1.266.703-1.734.469-.446 1.031-.727 1.734-.704h3.82L9.415 7.04c-.234.211-.234.563-.023.797.117.094.257.187.398.187.14 0 .281-.07.399-.164l3.374-3.164c.118-.14.188-.28.188-.445 0-.14-.07-.281-.188-.398ZM8.686 9.5a.555.555 0 0 0-.562.563v1.101c0 .094-.094.164-.188.164H1.563c-.117 0-.187-.094-.187-.164V4.813c0-.094.07-.165.188-.165h1.312a.55.55 0 0 0 .563-.539c0-.28-.258-.539-.563-.539H1.562C.837 3.57.25 4.156.25 4.86v6.35c0 .704.586 1.29 1.313 1.29h6.375c.703 0 1.312-.586 1.312-1.29v-1.1c0-.352-.258-.61-.563-.61Z"
        fill="#fff"
      />
    </svg>
  );
};

export default RedirectButton;
