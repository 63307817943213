import React from 'react';
import styled from 'styled-components';
import AddQuizResult from 'app/src/pages/project/pages/quiz/AddQuizResult';
import QuizResultList from 'app/src/pages/project/pages/quiz/quiz_result/QuizResultList';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import { CircularProgress } from '@material-ui/core';

const QuizResultsLayout = ({ isLoading, quizResults }) => {
  if (isLoading) {
    return (
      <LoadingContainer>
        <CircularProgress />
      </LoadingContainer>
    );
  }

  if (!quizResults.length) {
    return (
      <EmptyLayoutRoot>
        <AddQuizResult />
      </EmptyLayoutRoot>
    );
  }

  return (
    <LayoutRoot>
      <AddQuizResult header="Quiz Logic Boxes" />
      <QuizResultList />
    </LayoutRoot>
  );
};

export default QuizResultsLayout;

const LayoutRoot = styled(Gap16VerticalFlex)`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-top: 16px;
  padding-right: 16px;
`;

const EmptyLayoutRoot = styled(LayoutRoot)`
  align-items: center;
  justify-content: center;
`;

const LoadingContainer = styled(HorizontalFlexWrap)`
  justify-content: center;
  align-items: center;
  height: 100%;
`;
