import React from 'react';
import {
  PrimaryButton,
  SecondaryButton,
} from 'shared/react/components/basic/button-v2/BasicButton';
import { HorizontalFlexCentered } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { useOnboarding } from 'src/context/ui_store/OnboardingStore';
import StraightArrowRightIcon from 'src/images/creation_method_icons/StraightArrowRightIcon';
import { DesignSizes } from 'src/types/design-system';
import styled from 'styled-components';
import useAccountOnboardingTemplates from './hooks/useAccountOnboardingTemplates';
import OnboardingFooterNextButton from './OnboardingFooterNextButton';

const OnboardingFooter = () => {
  const [{ activeTemplateKey, isTemplatesNextStepBlocked }] = useOnboarding();
  const {
    getTemplateProgressText,
    handleBackButtonClick,
    handleNextButtonClick,
    getNextButtonText,
  } = useAccountOnboardingTemplates();

  if (!activeTemplateKey) {
    return null;
  }

  const nextButtonText = getNextButtonText();
  return (
    <LayoutRoot>
      <StyledSecondaryButton onClick={handleBackButtonClick}>
        <StraightArrowRightIcon />
        Back
      </StyledSecondaryButton>
      <StepText>Step {getTemplateProgressText()}</StepText>
      <OnboardingFooterNextButton />
    </LayoutRoot>
  );
};

const LayoutRoot = styled(HorizontalFlexCentered)`
  justify-content: space-between;
  padding: 24px 100px;
  position: relative;
`;

const StepText = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const StyledPrimaryButton = styled(PrimaryButton).attrs(() => ({
  size: DesignSizes.NORMAL,
}))``;

const StyledSecondaryButton = styled(SecondaryButton).attrs(() => ({
  size: DesignSizes.NORMAL,
}))`
  & > svg {
    transform: rotate(180deg);
    & > path {
      fill: ${({ theme }) => theme.colors.black};
    }
  }
`;

export default OnboardingFooter;
