import { useFeaturesContext } from 'shared/react/components/complex/context/FeaturesContext';

const useFeedFeatures = () => {
  const featuresSettings = useFeaturesContext();

  const actions = {
    getFeatureEnabled: featureKey => !!featuresSettings[featureKey],
    getFeatureSettingsByKey: featureKey => featuresSettings[featureKey] || {},
  };

  return [featuresSettings, actions];
};

export default useFeedFeatures;
