import React from 'react';
import styled from 'styled-components';
import ExpanderTopArrow from 'app/src/images/expander_top_arrow.svg';
import VerticalFlex from '../flex_layouts/VerticalFlex';
import { BolderTextSmall, TextSmall } from '../Text';
import {
  EXPANDER_HEADER_DATA_TYPE,
  EXPANDER_TITLE_DATA_TYPE,
  EXPANDER_ARROW_DATA_TYPE,
  EXPANDER_HEADER_OUTER_DATA_TYPE,
} from 'shared/react/components/complex/expanders/expander.constants';

function ExpanderHeader({
  onClick,
  icon,
  title,
  subtitle,
  isExpanded,
  headerStyle,
  showHeaderBottomBorder,
  renderHeaderLeftContainer,
  renderHeaderRightContainer,
  showShadow = true,
}) {
  const arrowIcon = (
    <Image
      onClick={onClick}
      src={ExpanderTopArrow}
      isExpanded={isExpanded}
      alt="Expander arrow"
      data-type={EXPANDER_ARROW_DATA_TYPE}
    />
  );

  const getLeftContainer = () => {
    if (renderHeaderLeftContainer) {
      return renderHeaderLeftContainer;
    }
    return (
      <>
        {icon}
        <VerticalFlex>
          <BolderTextSmall data-type={EXPANDER_TITLE_DATA_TYPE}>{title}</BolderTextSmall>
          <Subtitle>{subtitle}</Subtitle>
        </VerticalFlex>
      </>
    );
  };

  return (
    <LayoutRoot
      onClick={onClick}
      headerStyle={headerStyle}
      isExpanded={isExpanded}
      showHeaderBottomBorder={showHeaderBottomBorder}
      showShadow={showShadow}
      data-type={EXPANDER_HEADER_OUTER_DATA_TYPE}
    >
      <ExpanderHeaderInner
        data-type={EXPANDER_HEADER_DATA_TYPE}
        renderHeaderLeftContainer={renderHeaderLeftContainer}
      >
        {getLeftContainer()}
        {renderHeaderRightContainer ? renderHeaderRightContainer(arrowIcon) : arrowIcon}
      </ExpanderHeaderInner>
    </LayoutRoot>
  );
}

export default ExpanderHeader;

const LayoutRoot = styled.div`
  position: ${({ sticky }) => (sticky ? 'sticky' : '')};
  top: ${({ sticky }) => (sticky ? '0' : '')};
  background: ${({ sticky, theme }) => (sticky ? theme.colors.white : '')};
  z-index: ${({ sticky }) => (sticky ? '1' : '')};
  box-shadow: ${({ sticky, showShadow }) =>
    !showShadow
      ? ''
      : sticky
      ? '0 2px 10px rgba(50, 50, 93, 0.1), 0 1px 3px -1px rgba(0, 0, 0, 0.05)'
      : ''};
  border-bottom: ${({ isExpanded, showHeaderBottomBorder }) =>
    isExpanded && showHeaderBottomBorder ? `1px solid ` : ''};
  border-color: ${({ theme }) => theme.colors.gray3};
  cursor: pointer;
  padding: 8px 16px 8px 8px;
  transition: 0.3s;

  :hover {
    opacity: ${({ isExpanded }) => (isExpanded ? '' : '0.8')};
  }

  ${({ headerStyle }) => headerStyle}
`;

export const ExpanderHeaderInner = styled.div`
  display: grid;
  grid-template-columns: ${({ renderHeaderLeftContainer }) =>
    renderHeaderLeftContainer ? '1fr auto' : 'auto 1fr auto'};
  gap: 8px;
  align-items: center;
`;

const Subtitle = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.gray2};
`;

const Image = styled.img`
  transform: rotate(${({ isExpanded }) => (isExpanded ? 0 : 180)}deg);
  transition: 0.2s ease-out;
`;
