import React, { useState } from 'react';
import styled from 'styled-components';
import useFeedModal from '../../context/hooks/useFeedModal';
import { WISHLIST_MODAL_KEY } from 'shared/react/constants/modals.constants';
import Utils from 'shared/react/utils/utils';
import { SHOW_FEED_CART_MOBILE_MESSAGE } from 'shared/react/constants/messages.constants';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TOP_TABS, FEED_TABS } from 'shared/react/constants/feed.constants';
import useIsFenty from 'shared/react/hooks/useIsFenty';
import { topTabsCss } from 'shared/react/components/clientsCss.constants';
import useFeedState from 'shared/react/components/complex/context/hooks/useFeedState';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';
import useIsPrincessPolly from 'shared/react/hooks/useIsPrincessPolly';

const FeedTopTabs = ({
  currentStepKey,
  videoRef,
  audioRef,
  onClose,
  isMobile,
  activeTabKey = TOP_TABS[0].key,
  toggleFeedCloseButton,
}) => {
  const [{ currentModal }, { pushModal, popModal }] = useFeedModal();
  const [{ playerSettings }] = useProjectConfig();
  const [{ isTapcart }] = useFeedState();
  const isPrincessPolly = useIsPrincessPolly();
  const hideWishlist = playerSettings?.hideWishlist || isTapcart ||false;
  const [activeTab, setActiveTab] = useState(activeTabKey || TOP_TABS[0].key);
  const isFenty = useIsFenty();

  const onCloseWishlistModal = () => {
    if (isMobile) {
      videoRef.current.play();
      audioRef?.current?.play();

      Utils.postMessageToWindow({ name: SHOW_FEED_CART_MOBILE_MESSAGE, isOpen: false });
    }
  };

  const handleClickTab = (e, tabKey) => {
    e.stopPropagation();

    if (isMobile) {
      videoRef.current.pause();
      audioRef?.current?.pause();
      Utils.postMessageToWindow({ name: SHOW_FEED_CART_MOBILE_MESSAGE, isOpen: true });
    }

    if (tabKey === FEED_TABS.WISHLIST) {
      pushModal({
        key: WISHLIST_MODAL_KEY,
        props: {
          onClose: onCloseWishlistModal,
          currentStepKey: currentStepKey,
          videoRef,
        },
      });
    }

    if (tabKey === FEED_TABS.VIDEOS) {
      popModal();

      if (audioRef) {
        audioRef.current?.play();
      }

      if (videoRef) {
        videoRef.current.play();
      } else {
        onClose();
      }
      toggleFeedCloseButton({ hideCloseButton: false });
    }
  };

  if (isFenty || isPrincessPolly || hideWishlist) {
    return null;
  }

  return (
    <FeedTabsWrapper inModal={activeTab !== TOP_TABS[0].key}>
      {TOP_TABS.map(tab => (
        <FeedTab
          onClick={e => {
            handleClickTab(e, tab.key);
          }}
          inModal={activeTab !== TOP_TABS[0].key}
          isActive={activeTab === tab.key}
          key={tab.key}
          role="button"
        >
          {tab.text}
        </FeedTab>
      ))}
    </FeedTabsWrapper>
  );
};

const FeedTab = styled.div`
  cursor: pointer;
  padding: 8px;
  font-size: 14px;
  position: relative;

  ${topTabsCss}
`;

const FeedTabsWrapper = styled(HorizontalFlex)`
  justify-content: center;
  margin-top: 8px;
  color: ${({ theme, inModal }) => (inModal ? theme.colors.black : theme.colors.white)};
  z-index: 13;
`;

export default FeedTopTabs;
