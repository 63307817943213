import React from 'react';

const Pending = (props = {}) => {
  return (
    <svg
      {...props}
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.53125 2.30469C2.13281 2.30469 1.78125 2.65625 1.78125 3.05469C1.78125 3.47656 2.13281 3.80469 2.53125 3.80469C2.95312 3.80469 3.28125 3.47656 3.28125 3.05469C3.28125 2.65625 2.95312 2.30469 2.53125 2.30469ZM2.53125 9.21875C2.13281 9.21875 1.78125 9.54688 1.78125 9.96875C1.78125 10.3672 2.13281 10.7188 2.53125 10.7188C2.95312 10.7188 3.28125 10.3672 3.28125 9.96875C3.28125 9.54688 2.95312 9.21875 2.53125 9.21875ZM6 10.625C5.57812 10.625 5.25 10.9766 5.25 11.375C5.25 11.7969 5.57812 12.125 6 12.125C6.39844 12.125 6.75 11.7969 6.75 11.375C6.75 10.9766 6.39844 10.625 6 10.625ZM1.125 5.75C0.703125 5.75 0.375 6.10156 0.375 6.5C0.375 6.92188 0.703125 7.25 1.125 7.25C1.52344 7.25 1.875 6.92188 1.875 6.5C1.875 6.10156 1.52344 5.75 1.125 5.75ZM6 0.875C5.57812 0.875 5.25 1.22656 5.25 1.625C5.25 2.04688 5.57812 2.375 6 2.375C6.39844 2.375 6.75 2.04688 6.75 1.625C6.75 1.22656 6.39844 0.875 6 0.875ZM10.875 5.75C10.4531 5.75 10.125 6.10156 10.125 6.5C10.125 6.92188 10.4531 7.25 10.875 7.25C11.2734 7.25 11.625 6.92188 11.625 6.5C11.625 6.10156 11.2734 5.75 10.875 5.75ZM9.44531 9.21875C9.02344 9.21875 8.69531 9.54688 8.69531 9.96875C8.69531 10.3672 9.02344 10.7188 9.44531 10.7188C9.84375 10.7188 10.1953 10.3672 10.1953 9.96875C10.1953 9.54688 9.84375 9.21875 9.44531 9.21875Z"
        fill="#50545E"
      />
    </svg>
  );
};

export default Pending;
