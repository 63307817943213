import React from 'react';
import styled from 'styled-components';
import Filters from 'app/src/pages/dashboard/components/filters/Filters';
import { PageHeader } from 'app/src/pages/dashboard/components/page/Page';
import NewVideoButton from 'app/src/pages/dashboard/pages/videos/components/new_video/NewVideoButton';
import TopBar from 'src/pages/dashboard/components/top-bar/TopBar';
import { FilterKeys } from 'app/src/pages/dashboard/components/filters/filtersConfig';
import { WhereCondition } from 'app/src/pages/dashboard/utils/filterEntities';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import { FEATURE_SHOPIFY_TAGS } from 'shared/react/constants/features.constants';
import { useApps } from 'app/src/context/AppsStore';
import { vodAsset } from 'app/src/types/entities';
import { ASSET_TYPE } from 'shared/react/services/assets';
import VideosExtraFilters from '../filter-buttons/VideosExtraFilters';
import { UPLOAD } from 'app/src/constants/recordingTypes.constants';
import { INSTAGRAM_GRAPH } from 'app/src/constants/intergrations.constants';
import { DangerButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { IMAGE_LIBRARY_MODAL_KEY } from 'app/src/constants/modals.constants';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { PendingVodConnectionSuggestionsProvider } from 'src/hooks/vod-connection-suggestions/PendingVodConnectionsContext';
import { SortDirection } from 'app/src/pages/dashboard/pages/videos/hooks/search/searchRequests';

const isSourceFilterSelected = (source, filters) => {
  return filters.uploadType?.in?.length && filters.uploadType.in.includes(source);
};

type Props = {
  isModal: boolean;
  videosFilters: WhereCondition;
  setVideosFilters: (filters: WhereCondition) => void;
  onSelectedVideosAction?: (videos?: vodAsset[]) => void;
  canFilterLive?: boolean;
  canSortDate?: boolean;
  dateSortDirection?: SortDirection;
  setDateSortDirection?: (sortDirection: SortDirection) => void;
};

const VideosTopBar = ({
  isModal,
  videosFilters,
  setVideosFilters,
  onSelectedVideosAction,
  canFilterLive,
  canSortDate,
  dateSortDirection,
  setDateSortDirection,
}: Props) => {
  const { getFeatureEnabled } = useFeatureActions();
  const shopifyTagsEnabled = getFeatureEnabled(FEATURE_SHOPIFY_TAGS);
  const [{ shopify }] = useApps();
  const [, { setCurrentModal }] = useModal();

  const isUploadSourceSelected = isSourceFilterSelected(UPLOAD, videosFilters);
  const isInstagramSourceSelected = isSourceFilterSelected(INSTAGRAM_GRAPH, videosFilters);
  const isPlaylistSelected = !!videosFilters.labels;
  const shouldShowAssetTypeButtons =
    isUploadSourceSelected || isInstagramSourceSelected || isPlaylistSelected;
  const isGalleryPage = !!videosFilters?.type?.in?.includes(ASSET_TYPE.GALLERY);

  const getFilters = () => {
    const filters = [
      FilterKeys.source,
      FilterKeys.tagged,
      canFilterLive && FilterKeys.videoLive,
      !!shopify && FilterKeys.products,
      FilterKeys.labels,
      !!shopifyTagsEnabled && FilterKeys.tags,
      FilterKeys.owner,
      FilterKeys.appExternalId,
      FilterKeys.date,
    ].filter(Boolean);

    return filters;
  };

  const topBarLeftContent = (
    <Filters
      key={'filters'}
      availableFilters={getFilters()}
      existingFilters={videosFilters}
      setFilters={setVideosFilters}
      canSortDate={canSortDate}
      dateSortDirection={dateSortDirection}
      setDateSortDirection={setDateSortDirection}
      extraFiltersSlot={
        <PendingVodConnectionSuggestionsProvider>
          <VideosExtraFilters
            shouldShowAssetTypeButtons={shouldShowAssetTypeButtons}
            filters={videosFilters}
            setFilters={setVideosFilters}
          />
        </PendingVodConnectionSuggestionsProvider>
      }
    />
  );

  const topBarRightContent = (
    <NewVideoButton
      key={'top-bar-right-content'}
      onSelectedVideosAction={onSelectedVideosAction}
      isModal={isModal}
      videosFilters={videosFilters}
    />
  );
  const topBarGalleryRightContent = (
    <DangerButton
      onClick={() => {
        setCurrentModal(IMAGE_LIBRARY_MODAL_KEY);
      }}
    >
      New carousel
    </DangerButton>
  );

  const getBarContent = () => {
    if (isGalleryPage) {
      return <TopBar leftContent={topBarLeftContent} rightContent={topBarGalleryRightContent} />;
    }
    return <TopBar leftContent={topBarLeftContent} rightContent={topBarRightContent} />;
  };

  if (isModal) {
    return (
      <StyledPageContainer isModal={isModal}>
        {topBarLeftContent}
        {topBarRightContent}
      </StyledPageContainer>
    );
  }

  return getBarContent();
};

const StyledPageContainer = styled(PageHeader)<{ isModal: boolean }>`
  border-bottom: ${({ isModal }) => (isModal ? 'unset' : '')};
`;

export default VideosTopBar;
