import React from 'react';

const LeadGeneration = props => (
  <svg
    width={44}
    height={44}
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.462 38.923c10.196 0 18.461-8.265 18.461-18.462C38.923 10.267 30.658 2 20.461 2 10.267 2 2 10.265 2 20.462c0 10.196 8.265 18.461 18.462 18.461Z"
      stroke="url(#a)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.508 33.508 42 42"
      stroke="url(#b)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.46 12.77V8.153"
      stroke="url(#c)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.848 25.077c0 2.308 2.061 3.077 4.615 3.077 2.554 0 4.615 0 4.615-3.077 0-4.616-9.23-4.616-9.23-9.23 0-3.078 2.061-3.078 4.615-3.078 2.554 0 4.615 1.17 4.615 3.077"
      stroke="url(#d)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.46 28.154v4.615"
      stroke="url(#e)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="a"
        x1={2}
        y1={38.923}
        x2={38.923}
        y2={38.923}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
      <linearGradient id="b" x1={33.508} y1={42} x2={42} y2={42} gradientUnits="userSpaceOnUse">
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={20.461}
        y1={12.769}
        x2={21.461}
        y2={12.769}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={15.848}
        y1={28.154}
        x2={25.078}
        y2={28.154}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={20.461}
        y1={32.769}
        x2={21.461}
        y2={32.769}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4558D0" />
        <stop offset={0.5} stopColor="#C852BE" />
        <stop offset={1} stopColor="#FBC276" />
      </linearGradient>
    </defs>
  </svg>
);

export default LeadGeneration;
