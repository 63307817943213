import React from 'react';
import { useSelectedProductsContext } from 'app/src/complex_components/global_modal/video/add_products_modal/SelectedProductsContext';
import SelectedProductsList from 'app/src/complex_components/global_modal/video/add_products_modal/SelectedProductsList';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import SuggestedProducts from './components/suggested-products/SuggestedProducts';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { PendingVodConnectionSuggestionsProvider } from 'src/hooks/vod-connection-suggestions/PendingVodConnectionsContext';

type Props = {
  vodAssetId: string;
};

const SelectedProductsWithSuggestions = ({ vodAssetId }: Props) => {
  const [{ selectedProductsWithVariants, isLoading }, { removeProduct, reorderProducts }] =
    useSelectedProductsContext();

  return (
    <LayoutRoot>
      <PendingVodConnectionSuggestionsProvider>
        <SuggestedProducts vodAssetId={vodAssetId} />
      </PendingVodConnectionSuggestionsProvider>
      <VerticalFlex>
        <SelectedProductsSectionTitle>
          Selected products ({selectedProductsWithVariants.length})
        </SelectedProductsSectionTitle>
        <SelectedProductsList
          selectedProductsWithVariants={selectedProductsWithVariants}
          isLoading={isLoading}
          reorder={reorderProducts}
          onRemove={removeProduct}
          allowSelectVariants
        />
      </VerticalFlex>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)`
  width: 100%;
`;

const SelectedProductsSectionTitle = styled(TextTiny)`
  margin-bottom: 4px;
  color: ${({ theme }) => theme.colors.neutralGray};
`;

export default SelectedProductsWithSuggestions;
