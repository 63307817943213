import React from 'react';
import styled, { css } from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import {
  BolderTextSmall,
  LightTextSmall,
  TextSmall,
  TextTiny,
} from 'shared/react/components/complex/Text';
import { useProjects } from 'app/src/context/ProjectsStore';
import NotificationImpressionIcon from 'shared/react/images/notifications/NotificationImpressionIcon';
import NotificationInteractedIcon from 'shared/react/images/notifications/NotificationInteractedIcon';
import NotificationPlayedIcon from 'shared/react/images/notifications/NotificationPlayedIcon';
import NotificationTextResponseIcon from 'shared/react/images/notifications/NotificationTextResponseIcon';
import NotificationCalendarInviteIcon from 'shared/react/images/notifications/NotificationCalendarInviteIcon';
import NotificationConversionIcon from 'shared/react/images/notifications/NotificationConversionIcon';
import NotificationAudioResponseIcon from 'shared/react/images/notifications/NotificationAudioResponseIcon';
import NotificationVideoResponseIcon from 'shared/react/images/notifications/NotificationVideoResponseIcon';
import NotificationReachedEndIcon from 'shared/react/images/notifications/NotificationReachedEndIcon';
import { useAccount } from 'app/src/context/AccountStore';
import { useSessions } from 'app/src/context/SessionsStore';
import { track } from 'app/src/helpers/Tracker';
import listItemStatesCss from 'shared/react/components/complex/css/listItemStatesCss';
import { useInAppNotifications } from 'app/src/context/InAppNotificationsStore';
import Utils from 'app/src/utils';
import {
  formatAMPM,
  getWeekDayOfDate,
  isDateInThisWeek,
  isToday,
  isYesterday,
} from 'app/src/utils/date.utils';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import { EVENTS } from 'app/src/constants/tolstoy.constants';
import useNavigation from 'app/src/hooks/useNavigation';

function NotificationListItem({ notification, teamMemberId, isFeatureDisabled }) {
  const [{ projects }, { getStepByName, getStepDescription }] = useProjects();
  const [, { toggleNotificationListOpen }] = useAccount();
  const [{ sessions }, { setSelectedSession }] = useSessions();
  const [, { setNotificationRead }] = useInAppNotifications();
  const { navigateToProjectResponses } = useNavigation();
  const {
    value,
    type,
    email,
    createdAt: createdAtISOString,
    projectId,
    sessionId,
    read,
  } = notification;
  const project = projects.find(x => projectId?.includes(x.id));

  const createdAt = new Date(createdAtISOString);

  const getIcon = () => {
    const icons = {
      [EVENTS.pageView]: <NotificationImpressionIcon />,
      [EVENTS.sessionStart]: <NotificationPlayedIcon />,
      [EVENTS.clickCta]: <NotificationInteractedIcon />,
      [EVENTS.submitInput]: <NotificationTextResponseIcon />,
      [EVENTS.audioResponse]: <NotificationAudioResponseIcon />,
      [EVENTS.videoResponse]: <NotificationVideoResponseIcon />,
      [EVENTS.calendarInvite]: <NotificationCalendarInviteIcon />,
      [EVENTS.conversion]: <NotificationConversionIcon />,
      [EVENTS.sessionEnd]: <NotificationReachedEndIcon />,
    };

    if (isFeatureDisabled) {
      return Utils.getRandomProperty(icons);
    }

    return icons[type];
  };

  const getTitle = () => {
    const getUserTitle = (email, text) => {
      return `${email || 'A user'} ${text}`;
    };

    const titles = {
      [EVENTS.pageView]: getUserTitle(email, 'landed on'),
      [EVENTS.sessionStart]: getUserTitle(email, 'played'),
      [EVENTS.clickCta]: getUserTitle(email, 'interacted with'),
      [EVENTS.submitInput]: 'New text reply in',
      [EVENTS.audioResponse]: 'New audio reply in',
      [EVENTS.videoResponse]: 'New video reply in',
      [EVENTS.calendarInvite]: 'New calendar invite in',
      [EVENTS.conversion]: 'New conversion in',
      [EVENTS.sessionEnd]: getUserTitle(email, 'finished watching'),
    };

    return titles[type] || 'Nope';
  };

  const shouldShowValueContainer = () => {
    return type === EVENTS.clickCta && value;
  };

  const getSubtitle = () => {
    return project?.name || 'Project already deleted.';
  };

  const handleNotificationClick = () => {
    track('In App Notification Item Click', { type });
    setNotificationRead(notification.id, teamMemberId);
    toggleNotificationListOpen();
    setSelectedSession(sessions.find(({ id }) => id === sessionId));
    navigateToProjectResponses(project.id);
  };

  function getDateTime() {
    if (isToday(createdAt)) {
      return formatAMPM(createdAt);
    }

    if (isYesterday(createdAt)) {
      return 'Yesterday';
    }

    if (isDateInThisWeek(createdAt)) {
      return getWeekDayOfDate(createdAt);
    }

    return `${createdAt.getDate()}-${createdAt.getMonth() + 1}-${createdAt.getFullYear()}`;
  }

  const stepByName = getStepByName(notification.stepName, project);
  const stepDescription = getStepDescription(project, stepByName);

  function isClickDisabled() {
    return isFeatureDisabled || type === EVENTS.pageView;
  }

  return (
    <LayoutRoot isClickDisabled={isClickDisabled()} onClick={handleNotificationClick}>
      {getIcon()}
      <MainContainer>
        <ContentContainer isFeatureDisabled={isFeatureDisabled}>
          <TextContainer isFeatureDisabled={isFeatureDisabled}>
            <EllipsisTextOverflowWrapper>
              <Title>{getTitle()}</Title>
            </EllipsisTextOverflowWrapper>
            <EllipsisTextOverflowWrapper>
              <Subtitle>{getSubtitle()}</Subtitle>
            </EllipsisTextOverflowWrapper>
          </TextContainer>
          <DateContainer isFeatureDisabled={isFeatureDisabled}>
            <DateText read={read}>{getDateTime()}</DateText>
            {!read && <BlueDot />}
          </DateContainer>
        </ContentContainer>
        {shouldShowValueContainer() && (
          <ValueContainer>
            {stepDescription && <LightTextSmall>{stepDescription}</LightTextSmall>}
            <AnswerContainer>
              <LightTextSmall>A.</LightTextSmall>
              <BolderTextSmall>{value}</BolderTextSmall>
            </AnswerContainer>
          </ValueContainer>
        )}
      </MainContainer>
    </LayoutRoot>
  );
}

export default NotificationListItem;

const LayoutRoot = styled.div`
  display: grid;
  cursor: pointer;
  grid-template-columns: auto 1fr;
  justify-items: center;
  padding: 8px 16px;
  gap: 12px;
  ${({ isClickDisabled }) =>
    isClickDisabled
      ? css`
          pointer-events: none;
        `
      : listItemStatesCss};
`;

const MainContainer = styled.div`
  display: grid;
  justify-self: start;
  width: 100%;
`;

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 12px;
  max-width: 100%;
  width: 100%;
`;

const ValueContainer = styled(Gap8VerticalFlex)`
  border: 1px solid ${({ theme }) => theme.colors.gray5};
  border-radius: 10px;
  padding: 8px;
  margin-top: 20px;
  margin-bottom: 8px;
`;

const AnswerContainer = styled(HorizontalFlexWrap)`
  gap: 4px;
  align-items: center;
`;

const featureDisabledStyle = css`
  opacity: 0.4;
  pointer-events: none;
  filter: blur(3px);
`;

const TextContainer = styled(VerticalFlex)`
  display: inline-block;
  width: 100%;
  max-width: 100%;

  ${({ isFeatureDisabled }) => isFeatureDisabled && featureDisabledStyle};
`;

const Title = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.gray2};
`;

const Subtitle = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.black};
`;

const DateContainer = styled(Gap8HorizontalFlexWrap)`
  width: 100%;
  align-items: center;
  ${({ isFeatureDisabled }) => isFeatureDisabled && featureDisabledStyle};
`;

const DateText = styled(TextTiny)`
  font-weight: ${({ read }) => (read ? 600 : 800)};
`;

const BlueDot = styled.div`
  width: 8px;
  height: 8px;
  background: ${({ theme }) => theme.colors.blue7};
  border-radius: 100%;
`;
