import filterEntities from 'app/src/pages/dashboard/utils/filterEntities';
import { project, publishMethodOptions } from 'app/src/types/entities';
import { useDashboard } from 'app/src/context/ui_store/DashboardStore';
import { ONSITE_DEFAULT_PROJECT_TYPES } from 'src/constants/filters';
import { useHistory, useParams } from 'react-router-dom';
import { useProjectActions } from 'app/src/context/ProjectsStore';
import { useEffect } from 'react';
import { useApps } from 'app/src/context/AppsStore';
import useSortEntities from 'app/src/pages/dashboard/hooks/useSortEntities';
import { useFeatureActions } from 'src/context/FeaturesStore';
import { DEFAULT_ONSITE_FILTERS, DEFAULT_ONSITE_FOLDER_FILTER } from 'src/constants/filters';
import { FEATURE_TV_CHANNEL } from 'shared/react/constants/features.constants';
import Routes from 'app/src/helpers/Routes';

const UseOnsiteFilters = () => {
  const { getDisplayedProjects } = useProjectActions();
  const [{ onSiteFilters }, { setOnSiteFilters }] = useDashboard();
  const [{ shopify }] = useApps();
  const { folderId } = useParams();
  const { getFeatureEnabled } = useFeatureActions();
  const history = useHistory();
  const isDiscoverTab = history.location.pathname.includes(Routes.getDashboardV2DiscoverRoute());

  const isTvChannelEnabled = getFeatureEnabled(FEATURE_TV_CHANNEL);

  useEffect(() => {
    setOnSiteFilters({
      ...DEFAULT_ONSITE_FILTERS,
      ...getCurrentProjectTypesFilter(),
    });
  }, [folderId, shopify]);

  const getCurrentProjectTypesFilter = () => {
    const publishMethods = [
      ...(isDiscoverTab ? [] : ONSITE_DEFAULT_PROJECT_TYPES),
      publishMethodOptions.videoPage,
    ];

    if (folderId && folderId !== 'all') {
      return {
        folder: { eq: folderId },
        publishMethod: {
          in: publishMethods,
        },
      };
    }

    if (shopify && isTvChannelEnabled) {
      publishMethods.push(publishMethodOptions.tvChannel);
    }

    return {
      publishMethod: { in: publishMethods },
      folder: DEFAULT_ONSITE_FOLDER_FILTER,
    };
  };

  // do not change filters here
  // If you need to implement custom logic - use `setFilters` to update them in the store

  const sortedProjects = useSortEntities<project>(getDisplayedProjects(), 'updatedAt', true);

  return filterEntities<project>(sortedProjects, onSiteFilters);
};

export default UseOnsiteFilters;
