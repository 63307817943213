import React from 'react';
import styled, { css } from 'styled-components';
import Utils from 'app/src/utils';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import TabText from './TabText';

const Tab = ({ tab, isSelected, isSmall, onTabChanged, badgeText, dataTestId, name }) => {
  const upperCaseTabName = Utils.upperCaseFirstChar(name || tab);
  return (
    <LayoutRoot isSelected={isSelected} onClick={() => onTabChanged(tab)} data-test-id={dataTestId}>
      <TabText isSmall={isSmall} isSelected={isSelected} text={upperCaseTabName} />
      <BorderBottom isSelected={isSelected} />
      {badgeText && (
        <Badge>
          <BadgeText>{badgeText}</BadgeText>
        </Badge>
      )}
    </LayoutRoot>
  );
};

export default Tab;

const BorderBottom = styled.div`
  width: 50%;
  transition: width ease-in-out 0.3s;
  position: relative;
  color: ${({ isSelected, theme }) => (isSelected ? 'unset' : theme.colors.gray2)};
  border-bottom: ${({ isSelected, theme }) =>
    isSelected ? `2px solid ${theme.colors.primaryActive}` : ''};
  bottom: ${({ isSelected }) => (isSelected ? 0 : '')};
  border: ${({ isSelected }) => (isSelected ? '' : 'none')};
  padding-bottom: ${({ isSelected }) => (isSelected ? '' : '2px')};

  ${({ isSelected, theme }) =>
    !isSelected &&
    css`
      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 2px;
        left: 50%;
        bottom: 0;
        background-color: ${theme.colors.gray2};
        transition: all ease-in-out 0.2s;
      }
    `};
`;

const LayoutRoot = styled(VerticalFlex)`
  transition: 0.3s;
  border-radius: 8px;
  max-lines: 1;
  white-space: nowrap;
  gap: 4px;
  align-items: center;
  cursor: pointer;
  position: relative;

  &:hover > ${BorderBottom} {
    &:after {
      width: ${({ isSelected }) => (isSelected ? '' : '100%')};
      left: ${({ isSelected }) => (isSelected ? '' : 0)};
    }
  }
`;

const Badge = styled.div`
  position: absolute;
  top: -4px;
  right: -4px;
  transform: translate(50%, -50%);
  background: ${({ theme }) => theme.colors.info};
  padding: 2px 4px;
  border-radius: 4px;
  opacity: 1;
`;

const BadgeText = styled.div`
  font-size: 10px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
`;
