import { isImageAsset, getStepImageUrl } from 'widget/src/utils/assets.utils.js';
import { IMAGE_SIZE } from 'widget/src/constants/assets.constants.js';
import { VIDEO_OUTPUT } from '../../../config/widget.config.js';
import { getPublicId, getVideoBaseUrl } from '../embedWidgets.utils.js';
import { WEBP_AVATAR_EXTENSION } from '../../../constants/extensions.js';
import { VIDEO_PROVIDERS } from '../../../constants/videoProviders.constants.js';
import style from './story.module.css';
import {
  DEFAULT_STORIES_SIZE,
  EMBED_STORY_MOTION,
  POSTER_SUFFIX,
  PUBLIC_CLASSES,
  STORIES_IMAGE_EXTENSION
} from './storyConstants.js';
import {
  getBorderCss,
  getImageHtml,
  getNextButton,
  getPreviousButton,
  getStoryNameCss,
  getTile,
  getTilesAlignmentCss,
  getTilesContainerCss,
  getVideoHtml
} from './storyHtml.helper.js';

let isCustomFont = false;

const setCustomFontFamilyIfNeeded = element => {
  if (!isCustomFont) {
    return;
  }

  element.style.fontFamily = 'tolstoy-custom-font-family';
};

const getAvatarUrlFromStockAsset = ({ avatarUrl, posterUrl }) => {
  if (avatarUrl) {
    return avatarUrl;
  }

  if (VIDEO_PROVIDERS.some(provider => posterUrl.includes(provider))) {
    return posterUrl;
  }

  return posterUrl.replace(POSTER_SUFFIX, STORIES_IMAGE_EXTENSION);
};

const getImageUrl = ({ videoOwner, videoId, stockAsset }, extension, posterSettings) => {
  const { avatarUrl, posterUrl, hasOriginal, shopifyPosterUrl } = stockAsset || {};
  const isDuplicated = !hasOriginal && (avatarUrl || posterUrl);

  if (shopifyPosterUrl && posterSettings?.useShopifyPoster) {
    const searchParams = new URLSearchParams(posterSettings.shopifyAttributes || {});
    return `${shopifyPosterUrl}&${searchParams.toString()}`;
  }

  if (posterUrl?.endsWith(extension) || avatarUrl?.endsWith(extension) || isDuplicated) {
    return getAvatarUrlFromStockAsset(stockAsset);
  }

  /**
   * This check is needed to use uploaded thumbnail.
   * Currently if you upload a custom thumbnail to a video,
   * the hasOriginal flag is still set to true.
   * Which completely ignores posterUrl
   *
   * @see https://linear.app/tolstoy/issue/SUP-1835/%5Bswank-a-posh%5D-thumbnail-not-working
   */
  if (posterUrl) {
    return posterUrl;
  }

  return `${VIDEO_OUTPUT}/public/${videoOwner}/${videoId}/${videoId}${extension}`;
};

const getTitle = (
  {
    storiesTitleEnabled,
    storiesTitleText,
    storiesTitleFontSize,
    storiesMobileTitleFontSize,
    storiesTitleFontWeight,
    storiesTitleColor,
    storiesAlignment
  },
  publishId
) => {
  if (!storiesTitleEnabled || !storiesTitleText) {
    return '';
  }

  const fontSize = window.innerWidth < 450 ? storiesMobileTitleFontSize : storiesTitleFontSize;
  const titleElement = document.createElement('div');
  titleElement.classList.add(PUBLIC_CLASSES.titleContainer);
  titleElement.tolstoyElement = getPublicId(PUBLIC_CLASSES.titleContainer, publishId);
  titleElement.style.textAlign = storiesAlignment;
  titleElement.style.fontSize = `${fontSize}px`;
  titleElement.style.fontWeight = storiesTitleFontWeight;
  titleElement.style.color = storiesTitleColor;
  titleElement.setAttribute('role', 'heading');
  titleElement.setAttribute('aria-level', '2');
  titleElement.setAttribute('aria-label', `Tolstoy stories title: ${storiesTitleText}`);
  titleElement.append(storiesTitleText);
  setCustomFontFamilyIfNeeded(titleElement);

  return titleElement.outerHTML;
};

const getStoryNameHtml = ({
  storyNameCss,
  partName,
  key,
  publishId,
  isTextOverlay,
  storiesEmbed: {
    storiesBorderRadius,
    storiesStoryNameTextAlign,
    storiesStoryNameAlignment,
    storiesStoryNamePadding,
    storiesStoryNameFontWeight
  },
  storiesCircleSize
}) => {
  const tileNameElement = document.createElement('div');
  const textElement = document.createElement('div');
  tileNameElement.classList.add(style.storyName, PUBLIC_CLASSES.tileName);
  tileNameElement.dataset.tolstoyElement = `${getPublicId(
    PUBLIC_CLASSES.storyName,
    publishId
  )}_${key}`;

  textElement.style = storyNameCss;
  textElement.style.fontWeight = storiesStoryNameFontWeight;
  tileNameElement.setAttribute('aria-label', 'Story name');
  textElement.setAttribute('aria-label', partName);
  textElement.append(partName);
  setCustomFontFamilyIfNeeded(tileNameElement);

  if (isTextOverlay) {
    tileNameElement.classList.add(style.textOverlayContainer);
    textElement.classList.add(style.textOverlay);
    tileNameElement.style.textAlign = storiesStoryNameTextAlign;
    tileNameElement.style.justifyContent = storiesStoryNameTextAlign;
    tileNameElement.style.alignItems = storiesStoryNameAlignment;
    tileNameElement.style.padding = `${storiesStoryNamePadding ?? '8px'}`;
  }

  const proportionalBorderRadius = storiesBorderRadius * (storiesCircleSize / DEFAULT_STORIES_SIZE);

  if (isTextOverlay) {
    tileNameElement.style.borderRadius = `${proportionalBorderRadius}px`;
  }

  tileNameElement.append(textElement);

  return tileNameElement.outerHTML;
};

const getTiles = (storiesEmbed, publishId, tilesData, featureSettings) => {
  let tiles = '';
  const storiesSteps = [...storiesEmbed.storiesSteps];
  const { skeleton, storiesMotion } = storiesEmbed;
  const {
    tileSize: storiesCircleSize = DEFAULT_STORIES_SIZE,
    storiesRectangleWidth,
    storiesRectangleHeight,
    isCircle,
    isResponsive,
    isTextOverlay
  } = tilesData;

  const borderCss = getBorderCss(storiesEmbed, tilesData);
  const storyNameCss = getStoryNameCss(storiesEmbed);
  const posterSettings = featureSettings?.['widget-loading']?.posterSettings;

  let index = 0;
  for (const step of storiesSteps) {
    const { partName, key } = step;
    const isImage = isImageAsset(step);
    const videoUrl = isImage
      ? ''
      : getVideoBaseUrl({
          step,
          embedMotion: EMBED_STORY_MOTION.dynamic,
          isStory: true
        });
    const posterUrl = isImage
      ? getStepImageUrl(step, { size: IMAGE_SIZE.XS })
      : getImageUrl(step, WEBP_AVATAR_EXTENSION, posterSettings);

    const videoHtml = getVideoHtml({
      videoUrl,
      posterUrl,
      key,
      borderCss,
      publishId,
      storiesCircleSize,
      partName,
      storiesRectangleWidth,
      storiesRectangleHeight,
      isCircle,
      isResponsive
    });

    const imageHtml = getImageHtml({
      imageUrl: posterUrl,
      key,
      borderCss,
      publishId,
      storiesCircleSize,
      partName,
      stepIndex: index,
      storiesRectangleWidth,
      storiesRectangleHeight,
      isCircle,
      isResponsive
    });

    const storyNameHtml = getStoryNameHtml({
      partName,
      key,
      storyNameCss,
      publishId,
      isTextOverlay,
      storiesEmbed,
      storiesCircleSize
    });

    tiles += getTile({
      imageHtml,
      storyNameHtml,
      publishId,
      key,
      skeleton,
      videoHtml,
      storiesMotion,
      storiesCircleSize,
      partName,
      storiesRectangleWidth,
      storiesRectangleHeight,
      isCircle,
      isResponsive
    });

    index++;
  }

  return tiles;
};

const getHTML = ({ storiesEmbed, publishId, design, featureSettings }, parentWidth, tilesData) => {
  isCustomFont = Boolean(design?.branding?.typography?.font?.family);
  const title = getTitle(storiesEmbed, publishId);
  const tiles = getTiles(storiesEmbed, publishId, tilesData, featureSettings);
  const tilesAlignmentCss = getTilesAlignmentCss(storiesEmbed);
  const tilesContainerCss = getTilesContainerCss(storiesEmbed, tilesData, parentWidth);

  return `
    <div v-pre class="${style.stories}">
			${title}
		<div class="${style.storiesContainer} ${PUBLIC_CLASSES.storiesContainer}"
		  style= "${tilesAlignmentCss}"
		  data-tolstoy-element="${getPublicId(PUBLIC_CLASSES.storiesContainer, publishId)}"
	 	>
			<div class="${style.storiesVideoContainer}">
			   ${getPreviousButton(publishId)}
	 		<div class="${style.tilesContainer} ${PUBLIC_CLASSES.tilesContainer}"
       style="${tilesContainerCss}"
	 		  data-tolstoy-element="${getPublicId(PUBLIC_CLASSES.tilesContainer, publishId)}"
	 		 role="group"
	 		 aria-label="Tolstoy Stories"
		 	>
        ${tiles}
			</div>
      ${getNextButton(publishId)}
    </div>
		</div>
		</div>
	`;
};

export default getHTML;
