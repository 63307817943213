import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import StandardModalV2 from 'app/src/pages/modals/StandardModalV2';
import useModalVideoFilters from 'app/src/pages/creation_flow/right_section/hooks/useModalVideoFilters';
import { useModal, useModalActions } from 'app/src/context/ui_store/ModalStore';
import { vodAsset } from 'app/src/types/entities';
import { getTypeFilterValue } from 'app/src/utils/filters.utils';
import { ASSET_TYPE } from 'shared/react/services/assets';
import useVideosFilters from 'app/src/pages/dashboard/pages/videos/hooks/useVideosFilters';
import VideosContent from 'app/src/pages/dashboard/pages/videos/components/videos-content/VideosContent';
import { track } from 'app/src/helpers/Tracker';
import { VideosPageVodAsset } from 'app/src/pages/dashboard/pages/videos/types/videos.types';
import { useVirtualizedList } from 'app/src/hooks/useInfiniteScroll';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextH3Bold } from 'shared/react/components/basic/text/TextV2';
import { DangerButton, PrimaryButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { FilterKeys } from 'app/src/pages/dashboard/components/filters/filtersConfig';
import Filters from 'app/src/pages/dashboard/components/filters/Filters';
import {
  CREATION_FLOW_MODAL_KEY,
  GALLERY_MODAL_KEY,
  IMAGE_LIBRARY_MODAL_KEY,
} from 'app/src/constants/modals.constants';

type ModalProps = {
  onChoose: (video: vodAsset) => void;
  selectedImages: vodAsset[];
};

const ImageLibraryModal = () => {
  const containerRef = useRef();
  const [{ modalProps }] = useModal();
  const [selectedVideos, setSelectedVideos] = useState<string[]>([]);

  const { onChoose, selectedImages } = modalProps as ModalProps;
  const { setCurrentModal, updateModalProps } = useModalActions();
  const { videosFilters, setVideosFilters } = useModalVideoFilters({});
  const {
    videos: currentVideos,
    initialized,
    unfilteredVideos,
    onScrollEndCallback,
  } = useVideosFilters({ videosFilters, setVideosFilters });

  const { isLoading, isDone } = useVirtualizedList(
    containerRef,
    currentVideos.length,
    onScrollEndCallback,
    videosFilters
  );

  const selectedIds = selectedImages?.map(image => image.id) || [];

  const images = currentVideos.filter(image => !selectedIds.includes(image.id));

  const toggleSelected = (videoId: string) => {
    if (!selectedVideos.includes(videoId)) {
      track('Image Card Selected');
      setSelectedVideos([...selectedVideos, videoId]);
      return;
    }

    track('Image Card Select Canceled');
    setSelectedVideos(selectedVideos.filter(id => id !== videoId));
  };

  const onVideoChoose = (video: VideosPageVodAsset) => {};
  const numberOfVideosText = `${currentVideos.length} images shown`;

  const onSelectedVideosAction = async (video: VideosPageVodAsset[] | null) => {};

  const onModalClose = () => {
    setCurrentModal(GALLERY_MODAL_KEY);
  };

  const getFilters = () => {
    const filters = [
      FilterKeys.tagged,
      FilterKeys.videoLive,
      FilterKeys.products,
      FilterKeys.labels,
      FilterKeys.owner,
      FilterKeys.appExternalId,
      FilterKeys.date,
    ].filter(Boolean);

    return filters;
  };

  const onAddImagesToGallery = () => {
    setCurrentModal(GALLERY_MODAL_KEY);
    const newSelectedImages = images.filter(video => selectedVideos.includes(video.id));

    const updatedSelectedImages = [...(selectedImages || []), ...newSelectedImages];
    updateModalProps({ selectedImages: updatedSelectedImages });
  };

  const onUploadImage = () => {
    updateModalProps({
      isLeftPanelVisible: false,
      shouldCreateProject: false,
      location: IMAGE_LIBRARY_MODAL_KEY,
    });

    setCurrentModal(CREATION_FLOW_MODAL_KEY);
  };

  const getActionButton = () => {
    if (selectedVideos?.length > 0) {
      return <PrimaryButton onClick={onAddImagesToGallery}>+ Add to carousel</PrimaryButton>;
    }
    return <DangerButton onClick={onUploadImage}>Upload Image</DangerButton>;
  };

  useEffect(() => {
    setVideosFilters(
      {
        app: null,
        uploadType: null,
        folder: null,
        labels: null,
        trending: null,
        type: getTypeFilterValue(ASSET_TYPE.IMAGE),
      },
      true
    );
  }, []);

  return (
    <StyledStandardModal onClose={onModalClose} open>
      <ModalHeader>
        <TextH3Bold>Select Images</TextH3Bold>
      </ModalHeader>
      <ActionsHolder>
        <Filters
          key={'filters'}
          availableFilters={getFilters()}
          existingFilters={videosFilters}
          setFilters={setVideosFilters}
        />
        {getActionButton()}
      </ActionsHolder>
      <VideosContent
        onVideoChoose={onChoose}
        ref={containerRef}
        isTrendingPlaylist={false}
        videos={images}
        selectedVideos={selectedVideos}
        toggleSelected={toggleSelected}
        onSelectedVideosAction={onSelectedVideosAction}
        initialized={initialized}
        videosFilters={videosFilters}
        isLoading={isLoading}
        isDone={isDone}
        numberOfVideosText={numberOfVideosText}
        onScrollEndCallback={onScrollEndCallback}
        isSelectionMode={true}
      />
    </StyledStandardModal>
  );
};

const ModalHeader = styled(HorizontalFlex)`
  padding: 16px 24px;
`;

const ActionsHolder = styled(HorizontalFlex)`
  padding: 16px 24px;
`;

const StyledStandardModal = styled(StandardModalV2)`
  width: 1018px;
  height: 686px;
`;

export default ImageLibraryModal;
