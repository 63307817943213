import React from 'react';
import { useAccount } from 'app/src/context/AccountStore';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { track } from 'app/src/helpers/Tracker';
import { useProjects } from 'app/src/context/ProjectsStore';
import { TOLSTOY_RENAME_MODAL_KEY } from 'app/src/constants/modals.constants';
import CopyToWorkspace from 'app/src/pages/modals/CopyToWorkspaceModal';
import { useVideos } from 'app/src/context/VideosStore';

const CopyToWorkspaceModal = () => {
  const [{ workspaces }] = useAccount();
  const [, { fetch }] = useVideos();
  const [, { cloneProject, fetchProjects }] = useProjects();
  const [
    {
      modalProps: { project },
    },
    { setCurrentModal, setModalProps, setCurrentProjectId },
  ] = useModal();

  const onClose = () => {
    setModalProps({});
    setCurrentModal('');
  };

  async function onCopyToWorkspace(project, privateLibrary) {
    track('Tolstoy Copy To Workspace Click');

    const { projectId } = await cloneProject({
      projectId: project.id,
      privateLibrary: privateLibrary ?? project.privateLibrary,
    });

    if (!projectId) {
      return;
    }

    let projects = await fetchProjects({ withoutLoading: true, withoutChangingLibrary: true });
    setCurrentProjectId(projects[0].id);
    setCurrentModal(TOLSTOY_RENAME_MODAL_KEY);

    await fetch();
  }

  return (
    <CopyToWorkspace
      open={true}
      onClose={onClose}
      workspaces={workspaces}
      project={project}
      duplicateToCurrentWorkspace={onCopyToWorkspace}
    />
  );
};

export default CopyToWorkspaceModal;
