import React from 'react';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import StandardModal from '../StandardModal';
import InviteReferralModalContent from './InviteReferralModalContent';
import { track } from 'app/src/helpers/Tracker';

function InviteReferralModal() {
  const [, { setCurrentModal }] = useModal();

  const handleClose = () => {
    track('Invite Referral Modal Closed');
    setCurrentModal('');
  };

  return (
    <StandardModal
      removeDefaultPadding
      open={open}
      onClose={handleClose}
      closeModalLocation={'24px'}
    >
      <InviteReferralModalContent onClose={handleClose} />
    </StandardModal>
  );
}

export default InviteReferralModal;
