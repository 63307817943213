import {
  IMPORT_FROM_DROPBOX,
  IMPORT_FROM_GOOGLE_DRIVE,
  IMPORT_FROM_INSTAGRAM,
  IMPORT_FROM_SYNTHESIA,
  IMPORT_FROM_TIKTOK,
  IMPORT_FROM_YOUTUBE,
  RECORD_SCREEN,
  RECORD_VIDEO,
  UPLOAD_FILE,
} from 'app/src/constants/creation_method.constants';
import {
  SCREEN_RECORDING,
  UPLOAD,
  VIDEO_RECORDING,
} from 'app/src/constants/recordingTypes.constants';
import {
  DROPBOX,
  GOOGLE_DRIVE,
  INSTAGRAM,
  INSTAGRAM_GRAPH,
  SYNTHESIA,
  TIKTOK,
  UGC_INSTAGRAM_GRAPH,
  UGC_INSTAGRAM_GRAPH_PENDING,
  YOUTUBE,
} from 'app/src/constants/intergrations.constants';

export const RECORDING_TYPES = {
  [RECORD_SCREEN]: SCREEN_RECORDING,
  [UPLOAD_FILE]: UPLOAD,
  [RECORD_VIDEO]: VIDEO_RECORDING,
  [IMPORT_FROM_TIKTOK]: TIKTOK,
  [IMPORT_FROM_INSTAGRAM]: INSTAGRAM_GRAPH,
  [IMPORT_FROM_SYNTHESIA]: SYNTHESIA,
  [IMPORT_FROM_GOOGLE_DRIVE]: GOOGLE_DRIVE,
  [IMPORT_FROM_DROPBOX]: DROPBOX,
  [IMPORT_FROM_YOUTUBE]: YOUTUBE,
};

export const IMPORT_METHODS = {
  [TIKTOK]: IMPORT_FROM_TIKTOK,
  [INSTAGRAM]: IMPORT_FROM_INSTAGRAM,
  [INSTAGRAM_GRAPH]: IMPORT_FROM_INSTAGRAM,
  [SYNTHESIA]: IMPORT_FROM_SYNTHESIA,
  [GOOGLE_DRIVE]: IMPORT_FROM_GOOGLE_DRIVE,
  [DROPBOX]: IMPORT_FROM_DROPBOX,
  [YOUTUBE]: IMPORT_FROM_YOUTUBE,
  [UGC_INSTAGRAM_GRAPH]: IMPORT_FROM_INSTAGRAM,
  [UGC_INSTAGRAM_GRAPH_PENDING]: IMPORT_FROM_INSTAGRAM,
};
