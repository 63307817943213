import React from 'react';
import styled from 'styled-components';
import Expander from 'shared/react/components/complex/expanders/Expander';
import {
  EXPANDER_HEADER_DATA_TYPE,
  EXPANDER_TITLE_DATA_TYPE,
  EXPANDER_ARROW_DATA_TYPE,
} from 'shared/react/components/complex/expanders/expander.constants';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import SidebarExpanderTitle from './SidebarExpanderTitle';

type Props = {
  title: React.ReactNode;
  titleDataTestId?: string;
  isExpanded?: boolean;
  isDefaultExpanded?: boolean;
  withPlustButton?: boolean;
  overflowHidden?: boolean;
  setExpanded?: (value: boolean) => void;
  onPlusClick?: () => void;
  children: React.ReactNode;
};

export const SidebarExpander = ({
  title,
  titleDataTestId,
  isExpanded,
  isDefaultExpanded,
  withPlustButton = false,
  overflowHidden = false,
  setExpanded,
  onPlusClick,
  children,
}: Props) => {
  return (
    <StyledExpander
      title={
        <SidebarExpanderTitle
          title={title}
          dataTestId={titleDataTestId}
          withPlustButton={withPlustButton}
          onPlusClick={onPlusClick}
        />
      }
      isExpanded={isExpanded}
      isDefaultExpanded={isDefaultExpanded}
      setExpanded={setExpanded}
    >
      <Content overflowHidden={overflowHidden}>
        <ItemGap>{children}</ItemGap>
      </Content>
    </StyledExpander>
  );
};

const StyledExpander = styled(Expander)`
  & [data-type=${EXPANDER_TITLE_DATA_TYPE}] {
    width: 100%;
  }

  & [data-type=${EXPANDER_HEADER_DATA_TYPE}] {
    grid-template-columns: 1fr auto;
  }

  & [data-type=${EXPANDER_ARROW_DATA_TYPE}] {
    max-width: 10px;
  }
`;

const ItemGap = styled(VerticalFlex)`
  gap: 4px;
  width: 100%;
`;

const Content = styled(HorizontalFlex)`
  margin-top: 4px;
  gap: 8px;
  height: fit-content;
  ${({ overflowHidden }) => (overflowHidden ? 'overflow: hidden;' : '')};
`;

export default SidebarExpander;
