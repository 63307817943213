import React from 'react';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import styled from 'styled-components';
import WindowActionBarButtons from 'app/src/complex_components/WindowActionBarButtons';
import { useEmailAssets } from 'app/src/pages/share/email/email-assets';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextH4 } from 'shared/react/components/basic/text/TextV2';

function EmailPreview() {
  const { project } = useLookAndFeelContext();
  const emailAssets = useEmailAssets({ project });

  return (
    <LayoutRoot>
      <DotsContainer />
      <TextContainer>See it in action</TextContainer>
      <ImgContainer $centered={emailAssets.length > 1}>
        {emailAssets.map(asset => (
          <Img key={asset.gifUrl} src={asset.gifUrl} />
        ))}
      </ImgContainer>
    </LayoutRoot>
  );
}

const LayoutRoot = styled(Gap16VerticalFlex)`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.gray4};
  border-radius: 24px;
  padding: 32px;
  max-width: 791px;
`;

const DotsContainer = styled(WindowActionBarButtons)`
  margin-bottom: 0;
`;

const TextContainer = styled(TextH4)`
  text-align: center;
`;

const ImgContainer = styled(Gap8HorizontalFlex)`
  align-items: flex-start;
  justify-content: ${({ $centered }) => ($centered ? 'center' : 'flex-start')};
`;

const Img = styled.img`
  flex: 1;
  border-radius: 16px;
  object-fit: cover;
  max-width: 200px;
  max-height: 354px;
`;

export default EmailPreview;
