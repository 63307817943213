import React from 'react';

const TikTokWithNameIcon = props => {
  return (
    <svg
      width="80"
      height="24"
      viewBox="0 0 80 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#tiktokwithname)">
        <path
          d="M15.289 8.663a8.978 8.978 0 0 0 5.34 1.76V6.478a5.26 5.26 0 0 1-1.122-.121v3.104a8.98 8.98 0 0 1-5.34-1.759v8.048c0 4.026-3.171 7.289-7.083 7.289a6.91 6.91 0 0 1-3.943-1.233C4.427 23.161 6.22 24 8.205 24c3.912 0 7.084-3.263 7.084-7.29V8.664Zm1.383-3.978a5.573 5.573 0 0 1-1.383-3.218V.96h-1.063a5.528 5.528 0 0 0 2.446 3.725ZM5.615 18.718a3.384 3.384 0 0 1-.661-2.019c0-1.84 1.45-3.334 3.24-3.334.334 0 .665.053.983.156V9.49a6.945 6.945 0 0 0-1.121-.067v3.138a3.16 3.16 0 0 0-.983-.156c-1.79 0-3.24 1.493-3.24 3.334 0 1.302.725 2.43 1.782 2.979Z"
          fill="#FF004F"
        />
        <path
          d="M14.166 7.703a8.98 8.98 0 0 0 5.34 1.759V6.358a5.328 5.328 0 0 1-2.835-1.673A5.527 5.527 0 0 1 14.225.96h-2.792v15.75c-.006 1.837-1.454 3.324-3.24 3.324a3.205 3.205 0 0 1-2.58-1.316 3.345 3.345 0 0 1-1.781-2.979c0-1.84 1.45-3.334 3.24-3.334.343 0 .673.055.983.156V9.423c-3.843.082-6.934 3.313-6.934 7.288 0 1.984.77 3.782 2.019 5.096a6.91 6.91 0 0 0 3.943 1.233c3.912 0 7.083-3.263 7.083-7.29V7.704Z"
          fill="#000"
        />
        <path
          d="M19.507 6.358v-.84a5.228 5.228 0 0 1-2.835-.833 5.322 5.322 0 0 0 2.835 1.673ZM14.225.96a5.744 5.744 0 0 1-.058-.453V0h-3.855v15.75c-.006 1.837-1.454 3.324-3.24 3.324-.524 0-1.02-.128-1.458-.356a3.205 3.205 0 0 0 2.58 1.316c1.785 0 3.233-1.487 3.24-3.323V.96h2.791Zm-6.17 8.463V8.53a6.953 6.953 0 0 0-.971-.068C3.17 8.462 0 11.725 0 15.751c0 2.524 1.246 4.748 3.14 6.056a7.374 7.374 0 0 1-2.018-5.096c0-3.974 3.09-7.206 6.934-7.288Z"
          fill="#00F2EA"
        />
        <path
          d="M64.17 19.74c2.799 0 5.068-2.318 5.068-5.176S66.97 9.388 64.17 9.388h-.764c2.8 0 5.069 2.318 5.069 5.176s-2.27 5.176-5.069 5.176h.764Z"
          fill="#FF004F"
        />
        <path
          d="M63.338 9.388h-.764c-2.798 0-5.068 2.318-5.068 5.176s2.27 5.176 5.069 5.176h.763c-2.8 0-5.069-2.318-5.069-5.176s2.27-5.176 5.069-5.176Z"
          fill="#00F2EA"
        />
        <path
          d="M24.805 7.048v2.624h2.985v9.997h2.986V9.743h2.43l.832-2.695h-9.233Zm24.438 0v2.624h2.985v9.997h2.986V9.743h2.43l.833-2.695h-9.234Zm-14.649 1.49c0-.823.652-1.49 1.457-1.49.806 0 1.459.667 1.459 1.49 0 .821-.652 1.488-1.459 1.488-.805 0-1.457-.667-1.457-1.489Zm0 2.552h2.916v8.58h-2.916v-8.58Zm4.165-4.042V19.67h2.917v-3.261l.902-.852 2.846 4.184h3.124l-4.096-6.098 3.68-3.687H44.59l-2.915 2.978V7.048h-2.917Zm31.45 0V19.67h2.917v-3.261l.902-.852 2.847 4.184H80l-4.097-6.098 3.68-3.687h-3.54l-2.917 2.978V7.048H70.21ZM63.407 19.74c2.8 0 5.069-2.318 5.069-5.176s-2.27-5.176-5.069-5.176h-.07c-2.798 0-5.068 2.318-5.068 5.176s2.27 5.176 5.069 5.176h.07Zm-2.5-5.176c0-1.39 1.105-2.517 2.466-2.517 1.36 0 2.464 1.127 2.464 2.517s-1.103 2.517-2.464 2.517-2.465-1.127-2.465-2.517Z"
          fill="#000"
        />
      </g>
      <defs>
        <clipPath id="tiktokwithname">
          <path fill="#fff" d="M0 0h80v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TikTokWithNameIcon;
