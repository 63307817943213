import React, { lazy, Suspense } from 'react';
import Utils from 'player/src/utils';

const UnmuteAutoplayButton = lazy(() =>
  Utils.componentLoader(() => import('./UnmuteAutoplayButton'))
);

const UnmuteAutoplayButtonWrapper = props => {
  return (
    <Suspense fallback={null}>
      <UnmuteAutoplayButton {...props} />
    </Suspense>
  );
};

export default UnmuteAutoplayButtonWrapper;
