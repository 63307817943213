import React, { useEffect } from 'react';
import { TextTiny } from 'shared/react/components/complex/Text';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { VerticalFlexCentered } from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import HeaderAndSubheader from 'src/basic_components/HeaderAndSubheader';
import { useApps } from 'src/context/AppsStore';
import { useVideos } from 'src/context/VideosStore';
import { useOnboarding } from 'src/context/ui_store/OnboardingStore';
import styled from 'styled-components';
import { ONBOARDING_TEMPLATE_INTEGRATIONS } from '../../constants/onboardingTemplates.constants';
import OnboardingTemplatesIntegrationBox from './OnboardingTemplatesIntegrationBox';
import { useModalActions } from 'app/src/context/ui_store/ModalStore';
import { UPLOAD_FILES_MODAL_KEY } from 'app/src/constants/modals.constants';
import useAccountOnboardingTemplates from 'app/src/pages/dashboard/pages/homepage/onboarding/hooks/useAccountOnboardingTemplates';
import { GhostButton } from 'shared/react/components/basic/button-v2/BasicButton';

const OnboardingTemplatesIntegrations = () => {
  const [{ data: videos = [] }] = useVideos();
  const [{ instagramGraph, tiktok }] = useApps();
  const [{ activeTemplateKey }, { setIsTemplatesNextStepBlocked }] = useOnboarding();
  const { setCurrentModal, setModalProps } = useModalActions();
  const { handleNextButtonClick } = useAccountOnboardingTemplates();

  const { integrations } = ONBOARDING_TEMPLATE_INTEGRATIONS[activeTemplateKey];

  useEffect(() => {
    const shouldDisableNextStep = !instagramGraph && !tiktok && !videos?.length;
    if (shouldDisableNextStep) {
      setIsTemplatesNextStepBlocked(true);
      return;
    }

    setIsTemplatesNextStepBlocked(false);
  }, [videos?.length, instagramGraph, tiktok]);

  const onUploadFilesClick = () => {
    setModalProps({ onDone: handleNextButtonClick });
    setCurrentModal(UPLOAD_FILES_MODAL_KEY);
  };

  return (
    <LayoutRoot>
      <HeaderAndSubheader
        header="The best content is social"
        subheader="Once connected, your videos will be available to use in Tolstoy."
      />
      <Content>
        <IntegrationsContainer>
          {integrations.map(integration => {
            const { key } = integration;

            return (
              <OnboardingTemplatesIntegrationBox
                key={key}
                integrationKey={key}
                activeTemplateKey={activeTemplateKey}
                {...integration}
              />
            );
          })}
        </IntegrationsContainer>
        <UploadVideosContainer>
          <UploadVideosDescription>
            Have some videos you want to upload directly from your computer?
          </UploadVideosDescription>
          <GhostButton onClick={onUploadFilesClick}>Upload Videos</GhostButton>
        </UploadVideosContainer>
      </Content>
      <TolstoyDisclaimer>
        Tolstoy will not post or edit anything on your social media and you can always disconnect
        your account. <br />
        Please note that Instagram videos that have no music copyrights will not be imported.
      </TolstoyDisclaimer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlexCentered)`
  gap: 50px;
  width: 100%;
`;

const Content = styled(VerticalFlexCentered)`
  gap: 24px;
`;

const IntegrationsContainer = styled(HorizontalFlex)`
  gap: 32px;
  width: 100%;
  max-width: 1160px;
`;

const UploadVideosContainer = styled(VerticalFlexCentered)`
  gap: 8px;
`;

const UploadVideosDescription = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralGray};
`;

const TolstoyDisclaimer = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralGray};
  text-align: center;
`;

export default OnboardingTemplatesIntegrations;
