import React from 'react';
import styled from 'styled-components';
import { TextSmall } from 'shared/react/components/complex/Text';
import BasicSelect from 'shared/react/components/basic/basic_select/BasicSelect';
import { HUBSPOT } from 'app/src/constants/intergrations.constants';
import { useFormContext } from 'react-hook-form';
import InfoIconWithTooltip from 'app/src/complex_components/InfoIconWithTooltip';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { track } from 'app/src/helpers/Tracker';

const RESOLVE_CONFLICTS_KEY = 'resolveConflicts';
const RESOLVE_CONFLICTS_TOOLTIP =
  'For fields already filled in Hubspot, would you like to keep the existing value or replace with the new input from Tolstoy';

const HubspotResolveConflicts = () => {
  const { watch, setValue } = useFormContext();
  const resolveConflicts = watch(RESOLVE_CONFLICTS_KEY);
  const options = [
    { name: 'Hubspot', value: HUBSPOT, id: HUBSPOT },
    { name: 'Tolstoy', value: 'tolstoy', id: 'tolstoy' },
  ];

  const onValueChange = option => {
    setValue(RESOLVE_CONFLICTS_KEY, option.value);
    track(`Hubspot Resolve Conflicts ${option.value}`);
  };

  const selectedOption = options.find(option => option.value === resolveConflicts);

  return (
    <LayoutRoot>
      <HeaderContainer>
        <Header>Resolve conflicts</Header>
        <InfoIconWithTooltip title={RESOLVE_CONFLICTS_TOOLTIP} />
      </HeaderContainer>
      <BasicSelect onChange={onValueChange} value={selectedOption} items={options} />
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  max-width: 336px;
`;

const HeaderContainer = styled(HorizontalFlex)`
  gap: 4px;
  margin-bottom: 4px;
  align-items: center;
`;

const Header = styled(TextSmall)``;

export default HubspotResolveConflicts;
