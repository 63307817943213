import React, { useId, useState } from 'react';
import styled from 'styled-components';
import { FILTER_DATA_TYPE } from 'app/src/constants/analytics.constants';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { TextBody } from 'shared/react/components/complex/Text';
import MenuWithContainer from 'shared/react/components/basic/MenuWithContainer';
import CalendarContent from 'app/src/complex_components/analytics/analytics-calendar/CalendarContent';
import ExpanderTopArrowIcon from 'src/images/creation_method_icons/ExpanderTopArrowIcon';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { Gap8HorizontalFlex } from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import { formatDateRange, formatRelativeDateWindow } from '../date.utils';

const AnalyticsFilterValue = ({ value, onClick }) => {
  return (
    <Gap8HorizontalFlex onClick={onClick} data-type={FILTER_DATA_TYPE}>
      <AnalyticsFilterValueText value={value}>{value}</AnalyticsFilterValueText>
    </Gap8HorizontalFlex>
  );
};

const AnalyticsFilterWithCalendar = ({ onChange, value, filters, maxDateBack }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const menuId = useId();

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const onOpenMenu = e => {
    setAnchorEl(e.target);
  };

  const selectedDateFilterTitle = value?.startDate
    ? formatDateRange([value.startDate, value.endDate])
    : formatRelativeDateWindow(value);

  return (
    <>
      <AnalyticsFilterValueContainer>
        <Label as="label" htmlFor={menuId}>
          Date period
        </Label>
        <Gap8HorizontalFlex onClick={onOpenMenu}>
          <AnalyticsFilterValue value={selectedDateFilterTitle} />
          <Icon $open={!!anchorEl} />
        </Gap8HorizontalFlex>
      </AnalyticsFilterValueContainer>
      <Menu id={menuId} open={!!anchorEl} anchorEl={anchorEl} onClose={closeMenu}>
        <CalendarContent
          value={value}
          onChange={onChange}
          filters={filters}
          maxDateBack={maxDateBack}
          closeMenu={closeMenu}
        />
      </Menu>
    </>
  );
};

const AnalyticsFilterValueContainer = styled(Gap8VerticalFlex)`
  cursor: pointer;
`;

const AnalyticsFilterValueText = styled(TextBody)`
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
`;

const Menu = styled(MenuWithContainer)`
  box-shadow: 0 10px 25px rgba(50, 50, 93, 0.1), 0 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  overflow: hidden;
`;

const Label = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralDark};
`;

const Icon = styled(ExpanderTopArrowIcon)`
  margin-top: 4px;
  transform: ${({ $open }) => ($open ? 'rotate(0)' : 'rotate(180deg)')};
  width: 12px;
`;

export default AnalyticsFilterWithCalendar;
