import React from 'react';
import { TextH6, TextSmall } from 'shared/react/components/complex/Text';
import styled, { css } from 'styled-components';

function TabText({ isSmall, isSelected, text }) {
  if (isSmall) {
    return <StyledTextSmall isSelected={isSelected}>{text}</StyledTextSmall>;
  }
  return <StyledTextH6 isSelected={isSelected}>{text}</StyledTextH6>;
}

export default TabText;

const sharedStyledText = css`
  color: ${({ isSelected, theme }) => (isSelected ? theme.colors.black : theme.colors.gray31)};
`;

const StyledTextH6 = styled(TextH6)`
  ${sharedStyledText}
`;
const StyledTextSmall = styled(TextSmall)`
  ${sharedStyledText}
`;
