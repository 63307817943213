import React from 'react';
import styled, { useTheme } from 'styled-components';
import RightArrow from '../assets/RightArrow';
import LoadingIndicator from './LoadingIndicator';

function ActionButton({ children, onClick, disabled, isLoading, brandColor, textColor }) {
  const theme = useTheme();
  function handleClick(e) {
    e.stopPropagation();
    onClick(e);
  }

  const getBackgroundColor = () => {
    if (!brandColor) {
      return theme.colors.primaryLight;
    }

    return brandColor;
  };

  return (
    <LayoutRoot
      onClick={handleClick}
      disabled={disabled || isLoading}
      textColor={textColor}
      backgroundColor={getBackgroundColor()}
    >
      <MainContainer>
        <RightArrowContainer>
          <RightArrow
            fill={disabled || isLoading ? theme.getHexOpacity(textColor, 80) : textColor}
          />
        </RightArrowContainer>
        {children}
      </MainContainer>
      {isLoading && <StyledLoadingIndicator />}
    </LayoutRoot>
  );
}

export default ActionButton;

const LayoutRoot = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: grid;
  justify-content: center;
  justify-items: center;
  align-items: center;
  padding: 11px 24px;
  font-weight: 500;
  min-height: 28px;
  color: ${({ disabled, theme, textColor }) =>
    disabled ? theme.getHexOpacity(textColor, 80) : textColor};
  background: ${({ disabled, backgroundColor, theme }) =>
    disabled ? theme.getHexOpacity(backgroundColor, 80) : backgroundColor};
  border-radius: 75px;
  z-index: 100000000;
`;

const MainContainer = styled.div`
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 6px;
`;

const RightArrowContainer = styled.div``;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  grid-column: 1 / span 2;
`;
