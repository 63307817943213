import React from 'react';

const New = ({ color }) => {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 8C20 8.4375 19.6562 8.75 19.25 8.75H15.4688L13.6562 12.5938C13.5312 12.875 13.25 13.0312 12.9062 13C12.5938 12.9688 12.3438 12.75 12.25 12.4375L10.0625 4.0625L7.71875 15.4062C7.65625 15.75 7.34375 16 7 16C6.65625 16 6.34375 15.7812 6.25 15.4688L4.40625 8.75H0.71875C0.3125 8.75 0 8.4375 0 8C0 7.59375 0.3125 7.25 0.71875 7.25H5C5.3125 7.25 5.625 7.5 5.71875 7.8125L6.875 12.0625L9.25 0.625C9.3125 0.28125 9.625 0.03125 9.96875 0H10C10.3125 0 10.625 0.25 10.7188 0.5625L13.1875 10.0625L14.3125 7.6875C14.4375 7.4375 14.6875 7.25 15 7.25H19.25C19.6562 7.25 20 7.59375 20 8Z"
        fill={color}
      />
    </svg>
  );
};

export default New;
