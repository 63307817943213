import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { useBilling } from 'app/src/hooks/useBillings';
import Utils from 'app/src/utils';
import { safeScrollIntoViewIfNeeded } from 'app/src/utils/browser.utils';
import { PLAYER_SCROLL_TO_KEY } from 'app/src/pages/account/branding/branding.constants';
import EditorMainContainer from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorMainContainer';
import { useBrandingContext } from '../../../BrandingContext';
import ScrollHereDiv from '../../ScrollHereDiv';
import EditorSubheader from 'src/pages/project/pages/look_and_feel/editor/editors/shared/EditorSubheader';
import ButtonDesignEditor from './ButtonDesignEditor';

type Props = {
  settings?: any;
  features?: any;
  defaultSettings?: any;
  onChange?: any;
};

const ButtonsDesignEditor = ({
  settings = {},
  features = {},
  defaultSettings = {},
  onChange = () => {},
}: Props) => {
  const ref = useRef();
  const { isFreePackage } = useBilling();
  const { loading, scrollTo } = useBrandingContext();

  const updateSettings = (update, options = {}) => onChange({ ...settings, ...update }, options);
  const handlePrimaryUpdate = primary => updateSettings({ primary });

  useEffect(() => {
    if (scrollTo === PLAYER_SCROLL_TO_KEY) {
      setTimeout(() => {
        safeScrollIntoViewIfNeeded(ref.current);
      }, 0);
    }
  }, [scrollTo]);

  const isDisabled = !!loading;
  const shouldHide = isFreePackage;

  return (
    <LayoutRoot>
      <EditorSubheader title="Buttons design" />
      <EditorContainer shouldHide={shouldHide} disabled={isDisabled}>
        <ButtonDesignEditor
          title="Fallback Primary"
          settings={settings.primary}
          defaultSettings={defaultSettings.primary}
          disabled={isDisabled}
          onChange={handlePrimaryUpdate}
        />
        <ScrollHereDiv ref={ref} />
      </EditorContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)``;

const EditorContainer = styled(EditorMainContainer)`
  padding: 16px 0 0;
`;

export default ButtonsDesignEditor;
