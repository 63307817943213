import React, { useState } from 'react';
import styled from 'styled-components';
import FallbackImage from './fallback_image.png';

const ImageWithFallback = ({ src, imageFit, ...props }) => {
  const [isError, setIsError] = useState(false);

  const onError = () => {
    setIsError(true);
  };

  return (
    <LayoutRoot
      src={isError ? FallbackImage : src || FallbackImage}
      onError={onError}
      $imageFit={imageFit}
      {...props}
    />
  );
};

const LayoutRoot = styled.img`
  max-width: 100%;
  flex: none;
  order: 0;
  align-self: stretch;
  object-fit: ${({ $imageFit }) => $imageFit || 'cover'};
  z-index: 10;
  border-radius: 8px;
`;

export default ImageWithFallback;
