import RequestOwnerToUpgradePlanModal from 'app/src/complex_components/billings/modal/RequestOwnerToUpgradePlanModal';
import TeamMemberInviteModal from 'app/src/complex_components/global_modal/account/TeamMemberInviteModal';
import TransferOwnershipModal from 'app/src/complex_components/global_modal/account/TransferOwnershipModal';
import CopyToWorkspaceModal from 'app/src/complex_components/global_modal/copyToWorkspace/CopyToWorkspaceModal';
import CreateTolstoyModal from 'app/src/complex_components/global_modal/create_tolstoy/CreateTolstoyModal';
import EditStepModal from 'app/src/complex_components/global_modal/edit_step/EditStepModal';
import DeleteStepModal from 'app/src/complex_components/global_modal/step/DeleteStepModal';
import ThumbnailEdit from 'app/src/complex_components/global_modal/tumbnail-edit/ThumbnailEdit';
import DeleteVideoModal from 'app/src/complex_components/global_modal/video/DeleteVideoModal';
import ImportVideosToExistingTolstoyModal from 'app/src/complex_components/global_modal/video/ImportVideosToExistingTolstoyModal';
import PreviewVideoModal from 'app/src/complex_components/global_modal/video/PreviewVideoModal';
import RenameVideoModal from 'app/src/complex_components/global_modal/video/RenameVideoModal';
import AddProductsModalWrapper from 'app/src/complex_components/global_modal/video/add_products_modal/AddProductsModalWrapper';
import { SHOW_TRIAL_MODAL } from 'app/src/constants/billings.constants';
import {
  HAS_SHOPIFY_INSTALLED,
  SHOPIFY_INSTALLATION_FAILED,
  STORE_CONNECTED_TO_ANOTHER_ACCOUNT,
} from 'app/src/constants/intergrations.constants';
import {
  ADD_PRODUCTS_MODAL_KEY,
  ADD_PRODUCT_VIDEOS_MODAL_KEY,
  CAPTIONS_EDITOR_MODAL_KEY,
  CONNECT_TO_MAGENTO_MODAL_KEY,
  CREATE_TOLSTOY_MODAL_KEY,
  CREATION_FLOW_MODAL_KEY,
  DELETE_FOLDER_MODAL_KEY,
  DELETE_STEP_MODAL_KEY,
  DELETE_TOLSTOY_MODAL_KEY,
  EDIT_STEP_MODAL_KEY,
  EXPORT_TO_STORE_MODAL_KEY,
  HIJACK_SHOPIFY_STORE_MODAL_KEY,
  HUBSPOT_MAPPING_MODAL_KEY,
  IMPORT_VIDEOS_TO_EXISTING_TOLSTOY_MODAL_KEY,
  INSTALLATION_MODAL_KEY,
  INVITE_REFERRAL_MODAL_KEY,
  MOVE_TOLSTOY_MODAL_KEY,
  PUBLISH_MODAL_KEY,
  REINSTALL_SHOPIFY_MODAL_KEY,
  REMOVE_ADDON_MODAL_KEY,
  RENAME_FOLDER_MODAL_KEY,
  REQUEST_OWNER_TO_UPGRADE_PLAN_KEY,
  RESET_PROJECTS_GOOGLE_ANALYTICS_MODAL_KEY,
  SELECT_PUBLISH_METHOD_MODAL_KEY,
  SET_THUMBNAIL_MODAL_KEY,
  SHARE_MODAL_KEY,
  SHOPIFY_ALREADY_INSTALLED_MODAL_KEY,
  SHOPIFY_INSTALLATION_FAILED_MODAL_KEY,
  TEAM_MEMBER_INVITE_MODAL_KEY,
  TOLSTOY_COPY_TO_WORKSPACE_MODAL_KEY,
  TOLSTOY_RENAME_MODAL_KEY,
  TRANSFER_OWNERSHIP_MODAL_KEY,
  TRIAL_MODAL_KEY,
  UNSAVED_CHANGES_MODAL_KEY,
  UPLOAD_FILES_MODAL_KEY,
  VIDEO_DELETE_MODAL_KEY,
  VIDEO_PREVIEW_MODAL_KEY,
  VIDEO_RENAME_MODAL_KEY,
  CONFIRM_MODAL_KEY,
  VIDEO_ALLOW_SOUND_MODAL_KEY,
  VIDEO_DISALLOWED_SOUND_MODAL_KEY,
  ADD_EDIT_PLAYLIST_MODAL_KEY,
  INSTAGRAM_GRAPH_KEY,
  LIBRARY_MODAL_KEY,
  UGC_REQUEST_MODAL_KEY,
  GALLERY_MODAL_KEY,
  IMAGE_LIBRARY_MODAL_KEY,
  INSTAGRAM_TAG_PRODUCTS_MODAL_KEY,
  SELECT_PRODUCT_MODAL_KEY,
  VIDEO_ADD_CREATOR_PROFILE_MODAL_KEY,
  INSTAGRAM_CONNECT_MODAL_KEY,
} from 'app/src/constants/modals.constants';
import { PUBLISH, SHARE } from 'app/src/constants/searchParams.constants';
import { useProjects } from 'app/src/context/ProjectsStore';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import CreationFlowModal from 'app/src/pages/creation_flow/CreationFlowModal';
import DeleteFolderModal from 'app/src/pages/dashboard/pages/libraries/folders_v2/FolderModals/DeleteFolderModal';
import MoveFolderModal from 'app/src/pages/dashboard/pages/libraries/folders_v2/FolderModals/MoveFolderModal';
import RenameFolderModal from 'app/src/pages/dashboard/pages/libraries/folders_v2/FolderModals/RenameFolderModal';
import DeleteTolstoyModal from 'app/src/pages/modals/DeleteTolstoyModal';
import ReinstallShopifyModal from 'app/src/pages/modals/ReinstallShopifyModal';
import RemoveAddonModal from 'app/src/pages/modals/RemoveAddonModal';
import ShareModal from 'app/src/pages/modals/ShareModal';
import ShopifyAlreadyInstalledModal from 'app/src/pages/modals/ShopifyAlreadyInstalledModal';
import TolstoyRenameModal from 'app/src/pages/modals/TolstoyRenameModal';
import TrialModal from 'app/src/pages/modals/TrialModal';
import HubspotMappingModal from 'app/src/pages/modals/hubspot_mapping/HubspotMappingModal';
import PublishModal from 'app/src/pages/modals/publish/PublishModal';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import InstallationModal from 'src/pages/account/Installation/app_installation/InstallationModal';
import ResetProjectsGoogleAnalyticsModal from 'src/pages/account/tracking/ResetProjectsGoogleAnalyticsModal';
import UnsavedChangesModal from 'src/pages/modals/UnsavedChangesModal';
import SelectPublishMethodModal from 'src/pages/modals/publish/SelectPublishMethodModal';
import ConfirmModal from 'src/pages/modals/common/ConfirmModal';
import VideoAllowDisallowSoundModal from 'app/src/complex_components/global_modal/video/VideoAllowDisallowSoundModal';
import AddEditPlaylistModal from 'src/pages/modals/playlists/AddEditPlaylistModal';
import HijackShopifyStoreModal from '../../pages/modals/HijackShopifyStoreModal';
import ShopifyInstallationFailedModal from '../../pages/modals/ShopifyInstallationFailedModal';
import UploadFilesModal from '../../pages/modals/UploadFilesModal';
import ExportToStoreModal from '../../pages/modals/export_to_store/ExportToStoreModal';
import InviteReferralModal from '../../pages/modals/invite_referral/InviteReferralModal';
import CaptionsEditorModal from '../captions_editor/CaptionsEditorModal';
import ConnectToMagentoModal from './integrations/ConnectToMagentoModal';
import AddProductVideosModal from './products/AddProductVideosModal';
import InstagramModal from 'app/src/complex_components/global_modal/instagram_modal/InstagramModal';
import VideoLibraryModal from 'app/src/complex_components/global_modal/video-library-modal/VideoLibraryModal';
import UGCRequestModal from 'app/src/complex_components/global_modal/ugc-request-modal/UGCRequestModal';
import GalleryModalWrapper from 'app/src/complex_components/global_modal/gallery-modal/GalleryModalWrapper';
import ImageLibraryModal from 'app/src/complex_components/global_modal/image-library-modal/ImageLibraryModal';
import InstagramTagProductsModal from 'app/src/complex_components/global_modal/instagram-tag-products-modal/InstagramTagProductsModal';
import SelectPRoductModal from './products/SelectProductModal';
import AddCreatorProfileModal from 'app/src/pages/modals/AddCreatorProfileModal';
import InstagramConnectModal from 'app/src/complex_components/global_modal/instagram-connect-modal/InstagramConnectModal';

const GlobalModal = () => {
  const [{ currentModal }, { setCurrentModal, setCurrentProjectId, clearModalState }] = useModal();
  const [{ projects }] = useProjects();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  useEffect(() => {
    const shouldShowTrialModal = localStorage.getItem(SHOW_TRIAL_MODAL);
    const shouldShowAlreadyInstalledShopifyModal = localStorage.getItem(HAS_SHOPIFY_INSTALLED);
    const shouldShowStoreIsConnectedToDifferentAccountModal = localStorage.getItem(
      STORE_CONNECTED_TO_ANOTHER_ACCOUNT
    );
    const ShouldShowShopifyInstallationFailed = localStorage.getItem(SHOPIFY_INSTALLATION_FAILED);
    if (shouldShowTrialModal) {
      setCurrentModal(TRIAL_MODAL_KEY);
      return;
    }

    if (shouldShowAlreadyInstalledShopifyModal) {
      setCurrentModal(SHOPIFY_ALREADY_INSTALLED_MODAL_KEY);
      return;
    }

    if (shouldShowStoreIsConnectedToDifferentAccountModal) {
      setCurrentModal(HIJACK_SHOPIFY_STORE_MODAL_KEY);
      return;
    }

    if (ShouldShowShopifyInstallationFailed) {
      setCurrentModal(SHOPIFY_INSTALLATION_FAILED_MODAL_KEY);
      return;
    }

    const shouldShowShareModal = urlParams.get(SHARE) && SHARE_MODAL_KEY;
    const shouldShowPublishModal = urlParams.get(PUBLISH) && PUBLISH_MODAL_KEY;
    const selectedProject = projects?.find(x => location.pathname.includes(x.id));
    if ((!shouldShowShareModal && !shouldShowPublishModal) || !selectedProject) {
      return;
    }

    setCurrentProjectId(selectedProject.id);
    setCurrentModal(shouldShowShareModal || shouldShowPublishModal);
  }, [location, projects]);

  const closeModal = () => {
    clearModalState();
  };

  switch (currentModal) {
    case DELETE_TOLSTOY_MODAL_KEY:
      return <DeleteTolstoyModal />;
    case TOLSTOY_RENAME_MODAL_KEY:
      return <TolstoyRenameModal />;
    case TOLSTOY_COPY_TO_WORKSPACE_MODAL_KEY:
      return <CopyToWorkspaceModal />;
    case INSTALLATION_MODAL_KEY:
      return <InstallationModal closeModal={closeModal} />;
    case SHARE_MODAL_KEY:
      return <ShareModal />;
    case PUBLISH_MODAL_KEY:
      return <PublishModal />;
    case TRIAL_MODAL_KEY:
      return <TrialModal />;
    case RENAME_FOLDER_MODAL_KEY:
      return <RenameFolderModal />;
    case DELETE_FOLDER_MODAL_KEY:
      return <DeleteFolderModal />;
    case VIDEO_ADD_CREATOR_PROFILE_MODAL_KEY:
      return <AddCreatorProfileModal />;
    case VIDEO_RENAME_MODAL_KEY:
      return <RenameVideoModal />;
    case VIDEO_DELETE_MODAL_KEY:
      return <DeleteVideoModal />;
    case VIDEO_ALLOW_SOUND_MODAL_KEY:
      return <VideoAllowDisallowSoundModal shouldAllow={true} />;
    case VIDEO_DISALLOWED_SOUND_MODAL_KEY:
      return <VideoAllowDisallowSoundModal shouldAllow={false} />;
    case VIDEO_PREVIEW_MODAL_KEY:
      return <PreviewVideoModal />;
    case MOVE_TOLSTOY_MODAL_KEY:
      return <MoveFolderModal />;
    case INVITE_REFERRAL_MODAL_KEY:
      return <InviteReferralModal />;
    case SHOPIFY_ALREADY_INSTALLED_MODAL_KEY:
      return <ShopifyAlreadyInstalledModal />;
    case CREATION_FLOW_MODAL_KEY:
      return <CreationFlowModal />;
    case DELETE_STEP_MODAL_KEY:
      return <DeleteStepModal />;
    case REMOVE_ADDON_MODAL_KEY:
      return <RemoveAddonModal />;
    case EDIT_STEP_MODAL_KEY:
      return <EditStepModal closeModal={closeModal} />;
    case TRANSFER_OWNERSHIP_MODAL_KEY:
      return <TransferOwnershipModal />;
    case TEAM_MEMBER_INVITE_MODAL_KEY:
      return <TeamMemberInviteModal />;
    case EXPORT_TO_STORE_MODAL_KEY:
      return <ExportToStoreModal />;
    case CONNECT_TO_MAGENTO_MODAL_KEY:
      return <ConnectToMagentoModal />;
    case REQUEST_OWNER_TO_UPGRADE_PLAN_KEY:
      return <RequestOwnerToUpgradePlanModal closeModal={closeModal} />;
    case HUBSPOT_MAPPING_MODAL_KEY:
      return <HubspotMappingModal />;
    case HIJACK_SHOPIFY_STORE_MODAL_KEY:
      return <HijackShopifyStoreModal />;
    case SHOPIFY_INSTALLATION_FAILED_MODAL_KEY:
      return <ShopifyInstallationFailedModal />;
    case UPLOAD_FILES_MODAL_KEY:
      return <UploadFilesModal />;
    case CREATE_TOLSTOY_MODAL_KEY:
      return <CreateTolstoyModal />;
    case IMPORT_VIDEOS_TO_EXISTING_TOLSTOY_MODAL_KEY:
      return <ImportVideosToExistingTolstoyModal />;
    case ADD_PRODUCTS_MODAL_KEY:
      return <AddProductsModalWrapper closeModal={closeModal} />;
    case REINSTALL_SHOPIFY_MODAL_KEY:
      return <ReinstallShopifyModal />;
    case UNSAVED_CHANGES_MODAL_KEY:
      return <UnsavedChangesModal />;
    case SELECT_PUBLISH_METHOD_MODAL_KEY:
      return <SelectPublishMethodModal closeModal={closeModal} />;
    case RESET_PROJECTS_GOOGLE_ANALYTICS_MODAL_KEY:
      return <ResetProjectsGoogleAnalyticsModal />;
    case SET_THUMBNAIL_MODAL_KEY:
      return <ThumbnailEdit closeModal={closeModal} />;
    case CAPTIONS_EDITOR_MODAL_KEY:
      return <CaptionsEditorModal closeModal={closeModal} />;
    case ADD_PRODUCT_VIDEOS_MODAL_KEY:
      return <AddProductVideosModal closeModal={closeModal} />;
    case ADD_EDIT_PLAYLIST_MODAL_KEY:
      return <AddEditPlaylistModal closeModal={closeModal} />;
    case CONFIRM_MODAL_KEY:
      return <ConfirmModal />;
    case INSTAGRAM_GRAPH_KEY:
      return <InstagramModal closeModal={closeModal} />;
    case LIBRARY_MODAL_KEY:
      return <VideoLibraryModal closeModal={closeModal} />;
    case UGC_REQUEST_MODAL_KEY:
      return <UGCRequestModal closeModal={closeModal} />;
    case GALLERY_MODAL_KEY:
      return <GalleryModalWrapper closeModal={closeModal} />;
    case IMAGE_LIBRARY_MODAL_KEY:
      return <ImageLibraryModal />;
    case INSTAGRAM_TAG_PRODUCTS_MODAL_KEY:
      return <InstagramTagProductsModal closeModal={closeModal} />;
    case SELECT_PRODUCT_MODAL_KEY:
      return <SelectPRoductModal closeModal={closeModal} />;
    case INSTAGRAM_CONNECT_MODAL_KEY:
      return <InstagramConnectModal closeModal={closeModal} />;
    default:
      return null;
  }
};

export default GlobalModal;
