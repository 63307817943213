import React, { useState } from 'react';
import TemplatesList from 'app/src/pages/creation_flow/right_section/templates/TemplatesList';
import TemplateConfirmation from 'app/src/pages/creation_flow/right_section/templates/templateConfirmation/TemplateConfirmation';

const Templates = ({ onCopyTemplate }) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  if (!selectedTemplate) {
    return <TemplatesList setSelectedTemplate={setSelectedTemplate} />;
  }

  return (
    <TemplateConfirmation
      selectedTemplate={selectedTemplate}
      setSelectedTemplate={setSelectedTemplate}
      onCopyTemplate={onCopyTemplate}
    />
  );
};

export default Templates;
