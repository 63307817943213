import React from 'react';
import BasicButton from 'shared/react/components/basic/button/button/BasicButton';
import Gap16VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap16VerticalFlex';
import styled from 'styled-components';
import { TABLE_COLUMN_KEYS } from 'app/src/constants/cube.constants';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import { useAnalyticsActions } from 'app/src/context/AnalyticsStore';
import { VIDEOS_KEY } from 'app/src/constants/analytics.constants';
import HorizontalFlex, {
  Gap16HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

type Props = {
  children: React.ReactNode;
  setSelectedTab: (tab: string) => void;
  title: React.ReactNode;
};

const AnalyticsVideosPanel = ({ children, setSelectedTab, title }: Props) => {
  const { setVideoMetricsSortingModel } = useAnalyticsActions();

  const handleSeeMoreClick = () => {
    // FIXME: Configure grid columns and sorting to be able to accept `totalRevenue`
    // setVideoMetricsSortingModel([{ field: TABLE_COLUMN_KEYS.totalRevenue, sort: 'desc' }]);
    setSelectedTab(VIDEOS_KEY);
  };

  return (
    <LayoutRoot>
      <Header>
        <Title>{title}</Title>
        <NavButton onClick={handleSeeMoreClick}>See more →</NavButton>
      </Header>
      <VideosContainer>{children}</VideosContainer>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap16VerticalFlex)`
  background: ${({ theme }) => theme.colors.white};
  box-shadow:
    0 1px 3px -1px rgba(0, 0, 0, 0.05),
    0 2px 10px 0 rgba(50, 50, 93, 0.1);
  height: 100%;
  padding: 16px 16px 32px 16px;
  width: 100%;
`;

const Header = styled(Gap16HorizontalFlex)`
  align-items: center;
`;

const Title = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralDark};
  line-height: 18px;
  text-transform: uppercase;
`;

const NavButton = styled(BasicButton)`
  background: transparent;
  color: ${({ theme }) => theme.colors.neutralDark};
  font-size: 12px;
  height: 9px;
  line-height: 18px;
  margin-left: auto;
  padding: 0;

  &:hover {
    background: transparent;
  }
`;

const VideosContainer = styled(HorizontalFlex)`
  flex: 1;
  gap: 24px;
  margin: 0 auto;
  max-width: 100%;
  min-height: 376px;
  overflow: auto hidden;
`;

export default AnalyticsVideosPanel;
