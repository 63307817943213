import React from 'react';

const BottomToRightArrowIcon = () => {
  return (
    <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.79 4.82a.583.583 0 0 0 0-.867L7.663.391C7.313.086 6.75.344 6.75.836V2.71C2.977 2.734 0 3.508 0 7.07c0 1.43.914 2.86 1.945 3.61.328.234.774-.07.657-.446-1.055-3.398.492-4.289 4.148-4.336v2.063c0 .469.563.726.914.422L11.79 4.82Z"
        fill="#50545E"
      />
    </svg>
  );
};

export default BottomToRightArrowIcon;
