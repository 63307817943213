import React from 'react';

const CollectionsIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.125 4.8125C3.125 4.30469 3.51562 3.875 4.0625 3.875C4.57031 3.875 5 4.30469 5 4.8125C5 5.35938 4.57031 5.75 4.0625 5.75C3.51562 5.75 3.125 5.35938 3.125 4.8125ZM7.96875 0.75C8.4375 0.75 8.94531 0.984375 9.29688 1.33594L16.0156 8.05469C16.9922 9.03125 16.9922 10.6328 16.0156 11.6094L10.8203 16.8047C9.84375 17.7812 8.24219 17.7812 7.26562 16.8047L0.546875 10.0859C0.195312 9.73438 0 9.22656 0 8.75781V2.625C0 1.60938 0.820312 0.75 1.875 0.75H7.96875ZM1.40625 9.1875L8.16406 15.9453C8.63281 16.4141 9.45312 16.4141 9.92188 15.9453L15.1562 10.7109C15.625 10.2422 15.625 9.42188 15.1562 8.95312L8.39844 2.19531C8.28125 2.07812 8.125 2 7.96875 2H1.875C1.52344 2 1.25 2.3125 1.25 2.625V8.75781C1.25 8.91406 1.28906 9.07031 1.40625 9.1875ZM12.0312 0.945312C12.2656 0.710938 12.6562 0.710938 12.9297 0.945312L18.4375 6.21875C20.5078 8.17188 20.5078 11.4922 18.4375 13.4453L13.5547 18.0938C13.3203 18.3281 12.9297 18.3281 12.6953 18.0938C12.4609 17.8203 12.4609 17.4297 12.6953 17.1953L17.5781 12.5469C19.1016 11.0625 19.1016 8.60156 17.5781 7.11719L12.0312 1.84375C11.7969 1.60938 11.7969 1.21875 12.0312 0.945312Z"
        fill="#090A0B"
      />
    </svg>
  );
};

export default CollectionsIcon;
