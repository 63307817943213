import React from 'react';
import styled from 'styled-components';
import Pencil from 'app/src/images/pencil.svg';
import IconButton from './IconButton';

function PencilButton({ onClickFunction }) {
  return <LayoutRoot src={Pencil} onClick={onClickFunction} />;
}

export default PencilButton;

const LayoutRoot = styled(IconButton)`
  background: transparent;
  transition: 0.3s;

  &:hover,
  &:focus,
  &:active {
    background: ${({ theme }) => theme.colors.gray15};
  }

  margin-left: 8px;
  padding: 14px;
`;
