import { IS_DEV, STUDIO_DOMAIN } from 'src/config/app.config';
import { debounce } from 'lodash';

const STUDIO_IFRAME_ID = 'studio-auth-iframe';
const REMOVE_IFRAME_DELAY = 30 * 1000;

const STUDIO_MESSAGE_TYPES = {
  LOGIN: 'login',
  LOGOUT: 'logout',
  UPDATE_APP_URL: 'update_app_url',
};

const getIframeDomain = () => {
  if (IS_DEV) {
    return STUDIO_DOMAIN;
  }

  const branchDomain = new URLSearchParams(window.location.search).get('studioBranchDomain');
  return branchDomain || STUDIO_DOMAIN;
};

const getCurrentStudioIframe = () => {
  return document.getElementById(STUDIO_IFRAME_ID) as HTMLIFrameElement;
};

const getStudioIframe = () => {
  let iframe = getCurrentStudioIframe();

  if (!iframe) {
    const domain = getIframeDomain();

    iframe = document.createElement('iframe');
    iframe.id = STUDIO_IFRAME_ID;
    iframe.style.display = 'none';
    iframe.src = `${domain}/auth`;
    document.body.appendChild(iframe);
  }

  return iframe;
};

const removeStudioIframe = () => {
  const iframe = document.getElementById(STUDIO_IFRAME_ID);

  if (iframe) {
    iframe.remove();
  }
};

const sendToStudio = message => {
  const domain = getIframeDomain();
  const iframe = getCurrentStudioIframe();
  if (!iframe) {
    return;
  }

  const debouncedSend = debounce(() => {
    iframe.contentWindow.postMessage(message, domain);
  }, 500);
  debouncedSend();
};

const sendToStudioAfterLoad = message => {
  const domain = getIframeDomain();

  const iframe = getStudioIframe();

  iframe.onload = () => {
    setTimeout(() => {
      iframe.contentWindow.postMessage(message, domain);
    }, 500);
  };

  setTimeout(() => {
    removeStudioIframe();
  }, REMOVE_IFRAME_DELAY);
};

export const loginStudio = authInfo => {
  sendToStudioAfterLoad({
    name: STUDIO_MESSAGE_TYPES.LOGIN,
    ...authInfo,
  });
};

export const updateStudioAppUrl = (newAppUrl: string) => {
  sendToStudio({
    name: STUDIO_MESSAGE_TYPES.UPDATE_APP_URL,
    appUrl: newAppUrl,
  });
};

export const logoutStudio = () => {
  sendToStudioAfterLoad({
    name: STUDIO_MESSAGE_TYPES.LOGOUT,
  });
};

export const StudioAuthService = {
  loginStudio,
  logoutStudio,
  updateStudioAppUrl,
};

export default StudioAuthService;
