import MuxVideo from '@mux/mux-video-react';
import useVideoCaptions from 'player/src/hooks/useVideoCaptions';
import FeedOverlay from 'player/src/components/feed/feed_overlay/FeedOverlay';
import {
  getBasicVideoSrc,
  getProviderName,
  resolveVideoSrc,
} from 'player/src/components/player/player.utils';
import { REACT_APP_MUX_DATA_KEY } from 'player/src/config/player.config';
import { addAnalyticsData, getSessionId, trackSessionStart } from 'player/src/tracker/Tracker';
import Utils from 'player/src/utils';
import React, { forwardRef, useEffect, useState } from 'react';
import { DOWN } from 'shared/react/components/complex/carousel/carousel.constants';
import useFeedAccount from 'shared/react/components/complex/context/hooks/useFeedAccount';
import useFeedFeatures from 'shared/react/components/complex/context/hooks/useFeedFeatures';
import useFeedState from 'shared/react/components/complex/context/hooks/useFeedState';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';
import { FEATURE_FEED_USE_MUX } from 'shared/react/constants/features.constants';
import { PLAYER_TYPES } from 'shared/react/constants/tolstoy.constants';
import { useFeedIsMobile } from 'shared/react/hooks/useIsMobile';
import { setViewedStepKey } from 'player/src/helpers/feed-viewed-steps.helpers';
import UrlHelper from 'shared/react/utils/urlHelper';
import SharedUtils from 'shared/react/utils/utils';
import styled from 'styled-components';
import { shouldForceMute } from 'shared/react/utils/sound';
import { FEATURE_WHITE_LABEL } from 'player/src/constants/playerFeatures.constants';
const LOW_VIDEO_RES = 640;

const FeedVideo = forwardRef(
  (
    {
      step,
      numOfSteps,
      handleNavigateToStep,
      nextStepName,
      isStepOutOfView,
      index,
      trackVideoLoop,
      isCurrentVideo,
      isLoadingVideo,
      setIsLoadingVideo,
      isSubtitlesEnabled,
    },
    ref
  ) => {
    const { videoName, key: stepName, videoContain, videoId } = step;
    const { trackRef, trackSrc, currentCaptions } = useVideoCaptions({
      isSubtitlesEnabled,
      currentVideoRef: ref,
      step,
    });

    const isMobile = useFeedIsMobile();
    const [
      {
        isInitialMute,
        setIsInitialMute,
        handlePlayPause,
        isUserMute,
        setIsUserMute,
        isPlayerVisible,
        isSessionStart,
        setIsSessionStart,
        isPlaying,
        setIsPlaying,
        setShowAutoplayUnmute,
        isPlayerPreview,
        isFeedMuted,
      },
    ] = useFeedState();
    const [{ isFeedAutoScroll, publishId, projectId, isUseMuxVideo: isUseMuxVideoOnProjectLevel }] =
      useProjectConfig();
    const [{ appKey }] = useFeedAccount();
    const [, { getFeatureSettingsByKey }] = useFeedFeatures();
    const { hidePoweredBy } = getFeatureSettingsByKey(FEATURE_WHITE_LABEL) || {};
    const [fallbackStep, setFallbackStep] = useState(null);
    const isPreviewLoadingBlocked = Utils.isSafari() || Utils.isMobile();
    const playerType = Utils.getPlayerType();
    const isTvPlayerType = Utils.getIsTv(playerType);
    const isAiVideo = Utils.getIsAiVideo(step?.uploadType);
    const isMuted =
      shouldForceMute(isFeedMuted, step.isSoundAllowed) || isUserMute || isInitialMute;
    const {
      isUseMuxVideo: isUseMuxVideoOnAccountLevel,
      isAbTestEnabled,
      abTestName,
    } = getFeatureSettingsByKey(FEATURE_FEED_USE_MUX);

    const isUseMuxVideo = isUseMuxVideoOnProjectLevel || isUseMuxVideoOnAccountLevel;
    const [fallbackVideoSrc, setFallbackVideoSrc] = useState(null);
    const [lowPowerModeError, setLowPowerModeError] = useState(false);

    const getPreload = () => {
      if (!isPlayerVisible || isStepOutOfView) {
        return 'none';
      }

      if (isCurrentVideo) {
        return 'auto';
      }

      return 'metadata';
    };

    const preload = getPreload();
    const videoSrc = resolveVideoSrc({
      step: fallbackStep || step,
      res: LOW_VIDEO_RES,
      isUseMuxVideo,
      hidePoweredBy,
    });

    const onError = event => {
      console.error(
        `Error code: ${event.target?.error?.code}; Error message: ${
          event.target?.error?.message || 'No message...'
        }`
      );
      Utils.log(`[feed] - cannot load video V2 ${event.target?.src}`);

      if (SharedUtils.getIsShopifyCDN(videoSrc)) {
        setFallbackVideoSrc(getBasicVideoSrc({ step: fallbackStep || step, res: LOW_VIDEO_RES }));
        return;
      }

      if (fallbackStep || !step?.stockAsset?.hasOriginal) {
        return;
      }

      setFallbackStep({ ...step, stockAsset: undefined, externalProviderData: undefined });
    };

    const handleSafeCanPlay = async () => {
      if (!isPlaying) {
        return;
      }

      let error = await tryToPlay();

      if (!error) {
        return;
      }

      if (error.name !== 'NotAllowedError') {
        return;
      }

      setIsUserMute(true);
      setShowAutoplayUnmute(true);
      setIsInitialMute(true);

      if (!(await tryToPlay())) {
        return;
      }

      setLowPowerModeError(true);
    };

    const tryToPlay = async () => {
      try {
        await handleCanPlay();
        setIsPlaying(true);
      } catch (error) {
        console.log('Can not play', error);
        setIsPlaying(false);

        return error;
      }
    };

    const handleCanPlay = () => {
      setIsLoadingVideo(false);
      if (!isCurrentVideo || !isPlayerVisible) {
        return;
      }

      if (isMuted && ref.current.muted !== isMuted) {
        ref.current.muted = isMuted;
      }

      return ref.current.play();
    };

    const handleWaiting = () => {
      if (isLoadingVideo) {
        return;
      }

      setIsLoadingVideo(true);
    };

    const handlePlaying = () => {
      if (!isLoadingVideo) {
        return;
      }

      setIsLoadingVideo(false);
    };

    const handleVideoEnded = () => {
      if (!isFeedAutoScroll) {
        console.log('Auto scroll is disabled');
        ref?.current?.play();
        trackVideoLoop();
        return;
      }

      console.log('Auto scroll is enabled');
      handleNavigateToStep(DOWN);
    };

    const getVideoObjectFit = () => {
      if (!isMobile || videoContain || isAiVideo) {
        return 'contain';
      }

      return 'cover';
    };

    const handlePlayPauseWrapper = async () => {
      if (lowPowerModeError) {
        await tryToPlay();

        setLowPowerModeError(false);
        setIsUserMute(false);
        setShowAutoplayUnmute(false);
        setIsInitialMute(false);
      }

      handlePlayPause(step);
    };

    useEffect(() => {
      if (!isCurrentVideo) {
        return;
      }

      addAnalyticsData({ videoName, stepName, videoId });
    }, [isCurrentVideo, videoName, stepName, videoId]);

    useEffect(() => {
      if (!isSessionStart || !isCurrentVideo || !isPlayerVisible) {
        return;
      }

      trackSessionStart(SharedUtils.getAnalyticsLocationObject(PLAYER_TYPES.feed));
      setIsSessionStart(false);
    }, [isPlayerVisible]);

    useEffect(() => {
      if (!ref?.current || !isCurrentVideo || !isPlayerVisible) {
        return;
      }

      if (ref.current.paused === !isPlaying) {
        return;
      }

      if (isPlaying) {
        ref.current.play().catch(error => {
          console.log('Can not play', error);
        });
        return;
      }

      ref.current.pause();
    }, [isPlaying, isCurrentVideo, isPlayerVisible]);

    useEffect(() => {
      if (!isCurrentVideo || !isPlaying || !isTvPlayerType) {
        return;
      }

      setViewedStepKey(step.key, publishId);
    }, [isCurrentVideo, isPlaying, isTvPlayerType]);

    useEffect(() => {
      if (!isCurrentVideo) {
        try {
          ref?.current?.pause();
        } catch (error) {
          console.error('not current video pause', error);
        }
      }
    }, [isCurrentVideo]);

    const startTime = isPreviewLoadingBlocked ? 0.0001 : 0;
    const src = fallbackVideoSrc || videoSrc;

    return (
      <LayoutRoot
        $isStepOutOfView={isStepOutOfView}
        role="group"
        aria-label={`video: ${videoName}`}
        aria-owns={`feedSidePanel-${videoId}`}
        aria-hidden={isStepOutOfView}
      >
        <StyledMuxVideo
          $isMobile={isMobile}
          crossOrigin="true"
          onError={onError}
          src={src}
          muted={isMuted}
          onCanPlay={handleSafeCanPlay}
          playsInline
          controlsList="nodownload noplaybackrate"
          disablePictureInPicture={true}
          ref={ref}
          onClick={handlePlayPauseWrapper}
          onEnded={handleVideoEnded}
          onWaiting={handleWaiting}
          onPlaying={handlePlaying}
          $objectFit={getVideoObjectFit()}
          $isInIframe={UrlHelper.isInIframe()}
          preload={preload}
          streamType="on-demand"
          $startTime={startTime}
          $envKey={REACT_APP_MUX_DATA_KEY}
          metadata={{
            video_id: step.videoId,
            viewer_user_id: window.anonymousId,
            custom_1: appKey,
            custom_2: publishId,
            custom_3: projectId,
            player_name: getProviderName({ videoSrc: src, isUseMuxVideo }),
            video_title: videoName,
            experiment_name: isAbTestEnabled ? abTestName : null,
            view_session_id: getSessionId(),
          }}
          data-video-index={index}
          data-tolstoy-video-id={step.videoId}
        >
          {trackSrc && isSubtitlesEnabled && (
            <track default kind="captions" src={trackSrc} ref={trackRef} />
          )}
        </StyledMuxVideo>
        <FeedOverlay
          isCurrentVideo={isCurrentVideo}
          videoRef={ref}
          handleNavigateToStep={handleNavigateToStep}
          nextStepName={nextStepName}
          handlePlayPause={handlePlayPauseWrapper}
          isPlaying={isPlaying}
          step={step}
          numOfSteps={numOfSteps}
          isFeed={true}
          isLoadingVideo={isLoadingVideo}
          isPlayerPreview={isPlayerPreview}
          currentCaptions={currentCaptions}
        />
      </LayoutRoot>
    );
  }
);

const LayoutRoot = styled.div`
  display: ${({ $isStepOutOfView }) => ($isStepOutOfView ? 'none' : '')};
  height: 100%;
  width: 100%;
  scroll-snap-align: start;
  min-width: 100%;
  min-height: 100%;
  scroll-snap-stop: always;
  position: relative;
  overflow: hidden;
`;

const StyledMuxVideo = styled(MuxVideo)`
  object-fit: ${({ $objectFit }) => $objectFit};
  background: ${({ $isInIframe }) => ($isInIframe ? 'transparent' : 'black')};
  width: 100%;
  height: 100%;
  outline: none;

  &::cue {
    visibility: hidden;
  }
`;

FeedVideo.displayName = 'FeedVideo';
export default FeedVideo;
