import React, { useState } from 'react';
import styled from 'styled-components';
import { useDrawer } from 'src/context/ui_store/DrawerStore';
import { TextH4, TextSmall, TextTiny } from 'shared/react/components/basic/text/TextV2';
import { PrimaryButton } from 'shared/react/components/basic/button-v2/BasicButton';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';

const UGCSettingsDrawer = () => {
  const [{ drawerProps }] = useDrawer();
  const { message: currentMessage, onSaveSettings } = drawerProps;
  const [isSaving, setIsSaving] = useState(false);
  const [message, setMessage] = useState(currentMessage);
  const disabled = currentMessage === message || isSaving;

  const saveSettings = async () => {
    setIsSaving(true);

    await onSaveSettings({
      message,
    });

    setIsSaving(false);
  };

  const onChange = e => {
    setMessage(e.target.value);
  };

  return (
    <LayoutRoot>
      <Title>UGC Settings</Title>
      <SettingTitle>Approval message</SettingTitle>
      <TextTinyGrey>
        {'Use {{username}} to dynamically pull in the creator`s username in your message'}
      </TextTinyGrey>
      <Textarea value={message} onChange={onChange} />
      <SaveButton onClick={saveSettings} disabled={disabled}>
        Save
      </SaveButton>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(Gap8VerticalFlex)`
  width: 440px;
  height: 100%;
  padding: 8px 16px;
  overflow: hidden;
`;

const Title = styled(TextH4)`
  margin-bottom: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutralDark};
`;

const SaveButton = styled(PrimaryButton)`
  margin-top: 16px;
`;

const TextTinyGrey = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralDark};
`;

const SettingTitle = styled(TextSmall)`
  font-weight: 600;
`;

const Textarea = styled.textarea`
  height: 100px;
  border: 1px solid ${({ theme }) => theme.colors.neutralDark};
  border-radius: 2px;
  padding: 8px;
  resize: none;
`;

export default UGCSettingsDrawer;
