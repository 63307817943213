import React from 'react';
import styled from 'styled-components';
import BasicInput from 'app/src/basic_components/BasicInput';

const NumberInput = ({ error, ...props }) => {
  return (
    <DelayInputContainer error={error}>
      <DelayInput {...props} />
    </DelayInputContainer>
  );
};

const DelayInputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 8px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  border: 1px solid ${({ error, theme }) => (error ? theme.colors.red2 : theme.colors.gray3)};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1.0)};
  width: 51px;
  height: 40px;

  & > input[type='number'] {
    -moz-appearance: textfield;
  }

  & > input::-webkit-outer-spin-button,
  & > input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const DelayInput = styled(BasicInput)`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.gray2};
  text-align: center;
`;

export default NumberInput;
