import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';

const TextButton = ({ children, type, value, isSelected, onClick, setValue, title, ...props }) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef();
  const isInput = type === 'input';

  const onButtonClick = () => {
    if (isInput) {
      setIsFocused(true);
      return;
    }

    onClick(value);
  };

  const getInput = () => {
    if (!type) {
      return '';
    }

    if (isInput) {
      return (
        <Input
          isHidden={!isFocused}
          ref={inputRef}
          value={value}
          onChange={e => setValue(e.target.value)}
          onBlur={() => setIsFocused(false)}
        />
      );
    }
  };

  useEffect(() => {
    if (!isFocused) {
      return;
    }

    inputRef.current?.focus();
  }, [isFocused]);

  return (
    <>
      {getInput()}
      <EllipsisTextOverflowWrapper>
        <LayoutRoot
          isHidden={isInput && isFocused}
          isSelected={isSelected}
          onClick={onButtonClick}
          {...props}
        >
          {value ? value : title}
        </LayoutRoot>
      </EllipsisTextOverflowWrapper>
    </>
  );
};

const LayoutRoot = styled.div`
  border-radius: 12px;
  padding: 12px 16px;
  transition: 0.3s;
  cursor: pointer;
  display: ${({ isHidden }) => (isHidden ? 'none' : '')};
  background: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.primaryLight : theme.colors.white};
  max-width: 30ch;
  max-height: 44px;

  &:hover {
    background: ${({ theme, isSelected }) =>
      isSelected ? theme.colors.primaryLightHover : theme.colors.gray3};
  }
`;

const Input = styled.input`
  border-radius: 12px;
  padding: 12px 16px;
  transition: 0.3s;
  width: 229px;
  max-width: 229px;
  font-size: 16px;
  border: 1px solid transparent;
  cursor: pointer;
  display: ${({ isHidden }) => (isHidden ? 'none' : '')};
  background: ${({ theme }) => theme.colors.white};
  height: auto;
  max-height: 44px;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
`;

export default TextButton;
