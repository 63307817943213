import React, { useState } from 'react';
import styled from 'styled-components';
import HorizontalFlexWrap from './flex_layouts/HorizontalFlexWrap';
import LoadingIndicator from 'shared/react/components/basic/LoadingIndicator';
import DisabledContainer from '../basic/DisabledContainer';

function SocialButton({ withLoading, onClick, children }) {
  const [isLoading, setLoading] = useState(false);

  const handleClick = async () => {
    if (withLoading) {
      setLoading(true);
    }

    await onClick();

    setLoading(false);
  };

  return (
    <LayoutRoot onClick={handleClick}>
      <MainContainer isLoading={isLoading}>{children}</MainContainer>
      {isLoading && <StyledCircularProgress size={20} />}
    </LayoutRoot>
  );
}

export default SocialButton;

const LayoutRoot = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
`;

const MainContainer = styled(HorizontalFlexWrap)`
  grid-row: 1;
  grid-column: 1;
  border-radius: 100%;
  transition: 0.2s;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${({ isLoading }) => isLoading && DisabledContainer}
  &:hover {
    transform: scale(1.05);
  }
`;

const StyledCircularProgress = styled(LoadingIndicator)`
  grid-row: 1;
  grid-column: 1;
`;
