import { INTERNAL_EVENTS } from '../../../constants/internalEvents.constants.js';
import { EXTERNAL_EVENTS_TO_POST } from '../../../constants/externalEvents.constants.js';

export const INTERNAL_MESSAGES_TO_SUBSCRIBE = [
  INTERNAL_EVENTS.rebuyCartShown,
  INTERNAL_EVENTS.rebuyCartHidden,
  INTERNAL_EVENTS.toggleFeedCloseButton,
  INTERNAL_EVENTS.moveToUrl,
  INTERNAL_EVENTS.changeZIndex,
  INTERNAL_EVENTS.closePlayer
];

export const EXTERNAL_MESSAGES_TO_SUBSCRIBE = [EXTERNAL_EVENTS_TO_POST.tolstoyZIndexChange];
