import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Page from 'app/src/images/page.svg';
import OpenIcon from 'app/src/images/open.svg';
import ClosedIcon from 'app/src/images/closed.svg';
import MeatballsMenuDark from 'app/src/images/meatballs_menu_dark.svg';
import MeatballsMenuLight from 'app/src/images/meatballs_menu_light.svg';
import { useFeatures } from 'app/src/context/FeaturesStore';
import { FEATURE_CONTACTS_THREAD } from 'app/src/constants/appFeatures.constants';
import { Menu } from '@material-ui/core';
import MenuItem from 'app/src/complex_components/MenuItem';
import { PUBLISH_URL } from 'app/src/config/app.config';

const getThreadUrl = ({ session, project }) => {
  const threadUrl = `${PUBLISH_URL}/${project?.publishId}?sessionId=${session?.id}&replyPublishId=${project?.publishId}&replyProjectId=${session?.projectId}`;
  return threadUrl;
};

const SessionMenu = ({ session, active, project, onUpdateSessionStatus }) => {
  const { archived } = session;

  const [, { getFeatureEnabled }] = useFeatures();
  const sessionMenuIconRef = useRef();
  const [menuOpen, setMenuOpen] = useState(false);

  const onCloseMenu = e => {
    e.stopPropagation();
    setMenuOpen(false);
  };

  const onOpenMenu = e => {
    e.stopPropagation();
    setMenuOpen(true);
  };

  const threadUrl = getThreadUrl({ session, project });

  const menuItems = [
    {
      title: 'Thread',
      icon: Page,
      onClick: () => window.open(threadUrl, '__blank'),
      hidden: !getFeatureEnabled(FEATURE_CONTACTS_THREAD),
    },
    {
      title: archived ? 'Open' : 'Close',
      icon: archived ? OpenIcon : ClosedIcon,
      onClick: () => onUpdateSessionStatus(session),
    },
  ];

  const filtersMenuItems = menuItems?.filter(({ hidden }) => !hidden);

  if (!filtersMenuItems.length) {
    return null;
  }

  return (
    <>
      <StyledSessionMenu ref={sessionMenuIconRef} onClick={onOpenMenu} active={active}>
        <StyledSessionMenuIcon
          alt="Menu Button"
          src={active ? MeatballsMenuLight : MeatballsMenuDark}
        />
      </StyledSessionMenu>
      <Menu anchorEl={sessionMenuIconRef.current} open={menuOpen} onClose={onCloseMenu}>
        {filtersMenuItems.map(menuItem => (
          <MenuItem key={menuItem.title} menuItem={menuItem} onClose={onCloseMenu} />
        ))}
      </Menu>
    </>
  );
};

export default SessionMenu;

export const StyledSessionMenu = styled.div`
  visibility: ${({ active }) => (active ? 'initial' : 'hidden')};
  display: grid;
  place-content: center;
  position: absolute;
  top: 0px;
  right: -5px;
  height: 100%;
`;

export const StyledSessionMenuIcon = styled.img`
  padding: 0;
  width: 12px;
  height: 12px;
  transform: rotate(90deg);
`;
