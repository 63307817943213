import React from 'react';
import { TextSmall } from 'shared/react/components/complex/Text';
import ThickPlusIcon from 'src/images/ThickPlusIcon';
import styled from 'styled-components';
import VerticalVideoCardEmptyStateBase from './VerticalVideoCardEmptyStateBase';

type Props = {
  onClick: () => void;
  children?: React.ReactNode;
};

const VerticalVideoCardAddVideo = ({ children, ...props }: Props) => {
  return (
    <VerticalVideoCardEmptyStateBase {...props}>
      <StyledPlusIcon />
      <StyledTextSmall>Add video</StyledTextSmall>
      {children}
    </VerticalVideoCardEmptyStateBase>
  );
};

const StyledTextSmall = styled(TextSmall)`
  color: ${({ theme }) => theme.colors.grapePurple};
`;

const StyledPlusIcon = styled(ThickPlusIcon)`
  & path {
    fill: ${({ theme }) => theme.colors.grapePurple};
  }

  width: 16px;
  height: 16px;
`;

export default VerticalVideoCardAddVideo;
