import React from 'react';
import AnalyticsEngagement from './engagement/AnalyticsEngagement';
import AnalyticsOrders from './conversion/AnalyticsOrders';
import AnalyticsOverview from './overview/AnalyticsOverview';
import AnalyticsOnsite from 'app/src/complex_components/analytics/onsite/AnalyticsOnsite';
import AnalyticsVideos from 'app/src/complex_components/analytics/videos/AnalyticsVideos';
import AnalyticsShop from './shop/AnalyticsShop';
import AnalyticsEmail from './email/AnalyticsEmail';
import { TextH6 } from 'shared/react/components/basic/text/TextV2';
import {
  CONVERSION_KEY,
  ENGAGEMENT_KEY,
  ONSITE_KEY,
  OVERVIEW_KEY,
  SHOP_KEY,
  VIDEOS_KEY,
  EMAIL_FLOWS_KEY,
} from 'app/src/constants/analytics.constants';

const AnalyticsContent = ({ selectedTab, setSelectedTab, shouldBlur }) => {
  switch (selectedTab) {
    case OVERVIEW_KEY:
      return <AnalyticsOverview shouldBlur={shouldBlur} setSelectedTab={setSelectedTab} />;
    case ENGAGEMENT_KEY:
      return <AnalyticsEngagement shouldBlur={shouldBlur} />;
    case CONVERSION_KEY:
      return <AnalyticsOrders shouldBlur={shouldBlur} />;
    case ONSITE_KEY:
      return <AnalyticsOnsite shouldBlur={shouldBlur} />;
    case VIDEOS_KEY:
      return <AnalyticsVideos shouldBlur={shouldBlur} />;
    case SHOP_KEY:
      return <AnalyticsShop />;
    case EMAIL_FLOWS_KEY:
      return <AnalyticsEmail shouldBlur={shouldBlur} />;
    default:
      return <TextH6>Page not found</TextH6>;
  }
};

export default AnalyticsContent;
