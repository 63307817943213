import { WIDGET_SCRIPT_PARAMETERS } from '../constants/widgetConstants.js';
import { getParameterFromWidgetScripts } from '../utils/script.utils.js';

export function getAppKey() {
  if (window.tolstoyAppKey) {
    return window.tolstoyAppKey;
  }

  return getParameterFromWidgetScripts(WIDGET_SCRIPT_PARAMETERS.appKey);
}
