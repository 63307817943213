import React, { useLayoutEffect, useState } from 'react';
import ComponentWithOverlayLoader from 'shared/react/components/basic/ComponentWithOverlayLoader';
import ImageWithFallback from 'shared/react/images/ImageWithFallback';
import styled from 'styled-components';

const ImageWithLoader = ({ src, onClick, alt = 'image', imageFit, ...props }) => {
  const [isLoading, setIsLoading] = useState(true);

  useLayoutEffect(() => {
    setIsLoading(true);
  }, [src]);

  return (
    <ComponentWithOverlayLoader isLoading={isLoading} {...props}>
      <Image
        loading="lazy"
        src={src}
        isLoading={isLoading}
        onLoad={() => setIsLoading(false)}
        onClick={onClick}
        alt={alt}
        $imageFit={imageFit}
      />
    </ComponentWithOverlayLoader>
  );
};

const Image = styled(ImageWithFallback)`
  border-radius: 16px;
  max-width: 100%;
  max-height: 100%;
  opacity: ${({ isLoading }) => (isLoading ? 0.8 : 1)};
  transition: 0.2s;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : '')};
  object-fit: ${({ $imageFit }) => $imageFit || 'cover'};
`;

export default ImageWithLoader;
