import React from 'react';
import { Checkbox } from '@material-ui/core';
import styled from 'styled-components';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import { TextSmall } from 'shared/react/components/complex/Text';
import { INPUT_METHODS } from 'app/src/constants/creation_method.constants';
import BasicSelect from 'shared/react/components/basic/basic_select/BasicSelect';
import EllipsisTextOverflowWrapper from 'shared/react/components/basic/EllipsisTextOverflowWrapper';
import Tooltip from 'app/src/basic_components/Tooltip';
import { RadioGroup } from '@material-ui/core';
import RadioButton from 'app/src/basic_components/RadioButton';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import MenuItemTextInput from './MenuItemTextInput';

const MenuItemV2 = ({
  onClick,
  icon,
  title,
  isSelected,
  inputType,
  value,
  onAnswerValueChange,
  onAnswerCheckboxChange,
  defaultValue,
  inputPlaceHolder = '',
  options,
  disabled,
  disabledTooltipText,
  tooltipComponent,
  closeAfterClick = true,
  error,
  errorMessage,
  tooltipText,
  checkBoxTitle,
  checkboxValue,
}) => {
  const getTextInput = () => {
    return (
      <MenuItemTextInput
        value={value}
        defaultValue={defaultValue}
        inputPlaceHolder={inputPlaceHolder}
        error={error}
        errorMessage={errorMessage}
        onAnswerValueChange={onAnswerValueChange}
      />
    );
  };

  const getCheckBox = () => {
    if (!inputType || !isSelected) {
      return null;
    }

    if (checkBoxTitle && isSelected) {
      return (
        <CheckBoxLayout>
          <Checkbox
            checked={checkboxValue}
            onChange={e => {
              onAnswerCheckboxChange(e.target.checked);
            }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <TextSmall>{checkBoxTitle} </TextSmall>
        </CheckBoxLayout>
      );
    }
    return null;
  };

  const getInput = () => {
    if (!inputType || !isSelected) {
      return null;
    }

    if (INPUT_METHODS.text === inputType) {
      return getTextInput();
    }

    if (INPUT_METHODS.select === inputType) {
      return (
        <InputContainer>
          <BasicSelect
            items={options}
            value={value || ''}
            error={error}
            onChange={item => onAnswerValueChange(item.id)}
            inputPlaceHolder={error ? "Input can't be empty" : inputPlaceHolder}
          />
        </InputContainer>
      );
    }

    if (INPUT_METHODS.radio === inputType) {
      return (
        <RadioGroupContainer value={value}>
          {options.map(option => {
            const isSelected = option.value === value;
            const onClick = () => onAnswerValueChange(option.value);
            return (
              <React.Fragment key={option.label}>
                <RadioContainer onClick={onClick}>
                  <RadioButton value={option.value} />
                  <TextContainer>{option.label}</TextContainer>
                  {option.text}
                </RadioContainer>
                {isSelected && option.type === INPUT_METHODS.text && (
                  <RadioInputContainer>{getTextInput()}</RadioInputContainer>
                )}
              </React.Fragment>
            );
          })}
        </RadioGroupContainer>
      );
    }
  };

  const onButtonClick = () => {
    onClick(closeAfterClick);
  };

  const getContent = () => {
    return (
      <>
        <LayoutRoot
          disabled={disabled}
          isSelected={isSelected}
          onClick={disabled ? null : onButtonClick}
        >
          {icon && <IconContainer>{icon}</IconContainer>}
          <EllipsisTextOverflowWrapper>
            <TextContainer disabled={disabled} isSelected={isSelected}>
              {title}
            </TextContainer>
          </EllipsisTextOverflowWrapper>
        </LayoutRoot>
        {getInput()}
        {getCheckBox()}
      </>
    );
  };

  if (tooltipComponent) {
    return tooltipComponent(getContent());
  }

  const getTooltipText = () => {
    if (disabled) {
      return disabledTooltipText;
    }

    if (tooltipText) {
      return tooltipText;
    }

    return '';
  };

  return (
    <Tooltip placement="right" text={getTooltipText()}>
      {getContent()}
    </Tooltip>
  );
};

const LayoutRoot = styled(HorizontalFlexWrap)`
  padding: 15px 20px;
  gap: 12px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  transition: 0.3s;
  flex-wrap: nowrap;
  align-items: center;

  &:hover {
    background: ${({ theme }) => theme.colors.gray32};
  }

  && path {
    transition: 0.3s;
    fill: ${({ theme, isSelected, disabled }) =>
      disabled ? theme.colors.gray4 : isSelected ? theme.colors.primary : ''};
  }

  &:last-child {
    border-radius: 0 0 12px 12px;
  }
`;

const InputContainer = styled.div`
  padding: 0 20px 8px;
`;

const IconContainer = styled.div`
  width: 20px;
  height: 16px;
  display: flex;
`;

const TextContainer = styled(TextSmall)`
  transition: 0.3s;
  color: ${({ theme, isSelected, disabled }) =>
    disabled ? theme.colors.gray4 : isSelected ? theme.colors.primary : theme.colors.black};
`;

const RadioContainer = styled(Gap8HorizontalFlexWrap)`
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  padding: 8px 36px;
  &:hover {
    background: ${({ theme }) => theme.colors.gray32};
  }
`;

const RadioGroupContainer = styled(RadioGroup)`
  display: flex;
  flex-direction: column;

  & .MuiIconButton-root {
    padding: 0;
  }
`;

const RadioInputContainer = styled.div``;

const CheckBoxLayout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: center;
  align-items: center;
  padding: 0 20px 8px;
  width: 100%;
`;

export default MenuItemV2;
