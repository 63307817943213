import {
  JourneyDrawerStepEventName,
  JourneyDrawerSummaryStepKey,
} from './journey-drawer-content/journey-drawer-step/types/JourneyDrawerStep.types';

export const getJourneySummary = ({ journeysSteps }) => {
  let numOfVideosWatched = 0;
  let numOfOrders = 0;

  for (const { eventName } of journeysSteps) {
    if (eventName === JourneyDrawerStepEventName.video) {
      numOfVideosWatched++;
      continue;
    }

    if (eventName === JourneyDrawerStepEventName.purchase) {
      numOfOrders++;
      continue;
    }
  }

  return [
    {
      key: JourneyDrawerSummaryStepKey.isReturningCustomer,
      value: numOfOrders > 1,
    },
    {
      key: JourneyDrawerSummaryStepKey.watchedVideos,
      value: numOfVideosWatched,
    },
    {
      key: JourneyDrawerSummaryStepKey.purchases,
      value: numOfOrders,
    },
  ];
};
