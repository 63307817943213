import React from 'react';

const OutreachTemplateIcon = props => (
  <svg
    width={12}
    height={12}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="a" fill="#fff">
      <path d="M9.79.545a.462.462 0 0 0-.051-.21.553.553 0 0 0-.145-.176.69.69 0 0 0-.217-.118A.792.792 0 0 0 9.122 0H.668a.792.792 0 0 0-.255.04.69.69 0 0 0-.217.119.553.553 0 0 0-.145.177.462.462 0 0 0-.051.21v8.698a.47.47 0 0 0 .05.21.553.553 0 0 0 .146.177.69.69 0 0 0 .217.118.791.791 0 0 0 .255.04H.89V.852h8.9V.545Z" />
    </mask>
    <path
      d="M9.79.545a.462.462 0 0 0-.051-.21.553.553 0 0 0-.145-.176.69.69 0 0 0-.217-.118A.792.792 0 0 0 9.122 0H.668a.792.792 0 0 0-.255.04.69.69 0 0 0-.217.119.553.553 0 0 0-.145.177.462.462 0 0 0-.051.21v8.698a.47.47 0 0 0 .05.21.553.553 0 0 0 .146.177.69.69 0 0 0 .217.118.791.791 0 0 0 .255.04H.89V.852h8.9V.545Z"
      fill="#3D4FE0"
    />
    <path
      d="M9.79.545h-1 1ZM9.122 0v1h.005l-.005-1ZM.668 0 .663 1h.005V0ZM0 .545h1-1Zm0 8.7h1-1Zm.668.545v-1H.663l.005 1Zm.222 0v1h1v-1h-1Zm0-8.94v-1h-1v1h1Zm8.9 0v1h1v-1h-1Zm1-.306c0-.234-.057-.46-.159-.661L8.847.788A.538.538 0 0 1 8.79.545h2Zm-.159-.661a1.55 1.55 0 0 0-.405-.5L8.962.934a.448.448 0 0 1-.115-.146l1.784-.904Zm-.405-.5a1.689 1.689 0 0 0-.53-.292L9.058.99a.312.312 0 0 1-.097-.056l1.264-1.55Zm-.53-.292A1.792 1.792 0 0 0 9.115-1l.01 2A.209.209 0 0 1 9.06.989l.636-1.897ZM9.121-1H.668v2h8.454v-2ZM.674-1a1.792 1.792 0 0 0-.58.092L.732.99A.209.209 0 0 1 .663 1l.01-2Zm-.58.092a1.69 1.69 0 0 0-.53.292L.828.934a.312.312 0 0 1-.097.055L.095-.908Zm-.53.292c-.162.132-.304.3-.405.5L.943.788a.448.448 0 0 1-.115.145L-.436-.617Zm-.405.5A1.462 1.462 0 0 0-1 .545h2a.534.534 0 0 1-.057.243L-.841-.116ZM-1 .545v8.7h2v-8.7h-2Zm0 8.7c0 .234.057.46.159.66l1.784-.903A.538.538 0 0 1 1 9.244h-2Zm.159.66c.101.201.243.369.405.501l1.264-1.55c.038.031.08.079.115.146l-1.784.904Zm.405.501c.161.132.343.228.53.291l.637-1.896c.026.009.06.025.097.056l-1.264 1.55Zm.53.291a1.8 1.8 0 0 0 .58.093l-.011-2c.02 0 .042.003.068.011l-.636 1.896Zm.574.093H.89v-2H.668v2Zm1.222-1V.85h-2v8.94h2Zm-1-7.939h8.9v-2H.89v2Zm9.9-1V.545h-2v.306h2Z"
      fill="#fff"
      mask="url(#a)"
    />
    <path
      d="M2.849 2.71h8.512a.216.216 0 0 1 .139.044v8.702l-.004.004a.215.215 0 0 1-.135.04H2.85a.215.215 0 0 1-.139-.044V2.754a.215.215 0 0 1 .138-.044Zm8.657.05Zm-8.802 0Z"
      stroke="#fff"
    />
  </svg>
);

export default OutreachTemplateIcon;
