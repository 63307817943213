import React, { forwardRef } from 'react';
import styled from 'styled-components';
import VerticalFlex from './VerticalFlex';

const Gap16VerticalFlex = forwardRef(({ children, ...props }, ref) => {
  return <LayoutRoot ref={ref} {...props}>{children}</LayoutRoot>;
});
export default Gap16VerticalFlex;

const LayoutRoot = styled(VerticalFlex)`
  gap: 16px;
`;
