import React, { FC } from 'react';
import styled, { useTheme } from 'styled-components';
import ProIcon from 'app/src/images/ProIcon';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import InfoIconWithTooltip from 'app/src/complex_components/InfoIconWithTooltip';
import { TextBodyBold } from 'shared/react/components/basic/text/TextV2';

type Props = {
  title: string;
  disabled?: boolean;
  proFeature?: boolean;
  isWhite?: boolean;
  tooltipText?: string;
};

const tooltipUndefinedProps = {
  alignToCenter: undefined,
  customIcon: undefined,
  iconFill: undefined,
  margin: undefined,
  tooltipPlacement: undefined,
};

const EditorHeader: FC<Props> = ({
  title,
  disabled,
  proFeature,
  isWhite,
  tooltipText,
  children,
}) => {
  const theme = useTheme();

  return (
    <HeaderContainer disabled={disabled} isWhite={isWhite}>
      <HeaderText>
        {title} {proFeature && <ProIcon fill={theme.colors.black} />}
        {tooltipText && <InfoIconWithTooltip title={tooltipText} {...tooltipUndefinedProps} />}
      </HeaderText>
      {children}
    </HeaderContainer>
  );
};

const HeaderContainer = styled(HorizontalFlex)`
  justify-content: space-between;
  padding: 16px;
  opacity: ${({ disabled }) => (disabled ? '0.4' : '')};
  background: ${({ isWhite, theme }) => (isWhite ? theme.colors.white : theme.colors.gray1)};
`;

const HeaderText = styled(TextBodyBold)`
  align-items: center;
  display: flex;
  gap: 8px;
`;

export default EditorHeader;
