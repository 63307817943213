import React from 'react';
import styled from 'styled-components';
import { useLookAndFeelContext } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelContext';
import { VERTICAL_ORIENTATION_PROPERTY_KEY } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import EditorButtonGroupItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/EditorButtonGroupItem';
import EditorHeader from '../shared/EditorHeader';
import EditorMainContainer from '../shared/EditorMainContainer';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const LayoutEditor = () => {
  const {
    customizationSettings: { verticalOrientation },
    setCustomizationSettingsProperty,
  } = useLookAndFeelContext();

  const orientationButtons = [
    {
      text: 'Vertical',
      isEnabled: verticalOrientation,
      onClick: () => setCustomizationSettingsProperty(VERTICAL_ORIENTATION_PROPERTY_KEY, true),
    },
    {
      text: 'Horizontal',
      isEnabled: !verticalOrientation,
      onClick: () => setCustomizationSettingsProperty(VERTICAL_ORIENTATION_PROPERTY_KEY, false),
    },
  ];

  return (
    <LayoutRoot>
      <EditorHeader title="Layout" />
      <EditorMainContainer>
        <EditorButtonGroupItem text="Orientation" buttons={orientationButtons} />
      </EditorMainContainer>
    </LayoutRoot>
  );
};

export default LayoutEditor;

const LayoutRoot = styled(VerticalFlex)``;
