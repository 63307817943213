import React from 'react';
import { TrackerProvider } from 'shared/react/components/complex/context/TrackerContext';

const MOCK_TRACKER = {
  track: () => {},
  getSessionId: () => {},
};

const TrackerProviderWrapper = ({ trackerModule, children }) => {
  return (
    <TrackerProvider value={{ ...MOCK_TRACKER, ...trackerModule }}>{children}</TrackerProvider>
  );
};

export default TrackerProviderWrapper;
