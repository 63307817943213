import React from 'react';
import FeedNavigationArrows from 'shared/react/components/complex/feed/feed_overlay/FeedNavigationArrows';
import FeedControls from 'shared/react/components/complex/feed/feed_controls/FeedControls';
import MobileFeedNavigation from 'shared/react/components/complex/feed/feed_overlay_mobile/MobileFeedNavigation';
import { useFeedIsMobile } from 'shared/react/hooks/useIsMobile';
import styled from 'styled-components';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';
import HorizontalFlex from '../../flex_layouts/HorizontalFlex';
import CustomerLoginBanner from '../../customer_login/CustomerLoginBanner';
import FeedSearchButton from 'shared/react/components/complex/feed/feed_search/FeedSearchButton';
import FeedBackButton from 'shared/react/components/complex/feed/feed_search/FeedBackButton';
import FeedAiTag from 'shared/react/components/complex/feed/feed_common/FeedAiTag';
import { TextH4 } from 'shared/react/components/basic/text/TextV2';
import useKeyboardNavigation from 'player/src/hooks/useKeyboardNavigation';

const FeedNavigation = ({
  nextStepName,
  videoRef,
  audioRef,
  handleNavigateToStep,
  step,
  handlePlayPause,
  isMobilePreview,
  currentCaptions,
  scrollLeft,
  scrollRight,
}) => {
  const isMobile = useFeedIsMobile();
  const { onKeyDown, setNextRef } = useKeyboardNavigation();
  const [{}, { getIsSingleStepFeed }] = useProjectConfig();

  const isSingleStepFeed = getIsSingleStepFeed();
  if (isMobile || isMobilePreview) {
    return (
      <MobileFeedNavigation
        nextStepName={nextStepName}
        videoRef={videoRef}
        audioRef={audioRef}
        step={step}
        handlePlayPause={handlePlayPause}
        handleNavigateToStep={handleNavigateToStep}
        isMobilePreview={isMobilePreview}
        isSingleStepFeed={isSingleStepFeed}
        currentCaptions={currentCaptions}
        scrollLeft={scrollLeft}
        scrollRight={scrollRight}
      />
    );
  }

  return (
    <>
      <StyledFeedAiTag step={step} />
      <CustomerLoginBanner videoRef={videoRef} />
      <StyledFeedSearchButton
        videoRef={videoRef}
        videoId={step.videoId}
        isMobile={false}
        currentStepKey={step.key}
      />
      <TopContainer
        onClick={handlePlayPause}
        aria-label="Start/Stop Video"
        role="button"
        ref={setNextRef}
        onKeyDown={onKeyDown}
        tabIndex="0"
      >
        <CustomFeedBackButton />
        {!isSingleStepFeed && (
          <FeedNavigationArrows
            handleNavigateToStep={handleNavigateToStep}
            isMobilePreview={isMobilePreview}
          />
        )}
      </TopContainer>
      <StyledCaptions isHidden={!currentCaptions}>{currentCaptions}</StyledCaptions>
      <FeedControls
        videoRef={videoRef}
        step={step}
        scrollLeft={scrollLeft}
        scrollRight={scrollRight}
      />
    </>
  );
};

const TopContainer = styled(HorizontalFlex)`
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
`;

const StyledFeedSearchButton = styled(FeedSearchButton)`
  top: 30px;
`;

const CustomFeedBackButton = styled(FeedBackButton)`
  position: absolute;
  top: -180px;
  left: 16px;
  z-index: 12;
`;

const StyledFeedAiTag = styled(FeedAiTag)`
  top: 75px;
  left: 16px;
`;

const StyledCaptions = styled(TextH4)`
  display: ${({ isHidden }) => (isHidden ? 'none' : 'unset')};
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  width: 60%;
  margin: 0 20px;
`;

export default FeedNavigation;
