import React from 'react';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import Spinner from 'src/basic_components/Spinner';
import ComponentWithLoader from 'src/complex_components/ComponentWithLoader';
import { SearchProduct } from 'src/types/common';
import styled from 'styled-components';
import ProductsTableEmptyState from '../products_table/ProductsTableEmptyState';
import ProductVideoTile from './ProductVideoTile';
import { AnalyticsData } from 'src/types/utils.types';

type Props = {
  searchProducts: SearchProduct[];
  isInitialLoad: boolean;
  clearSearch: () => void;
  isLoadingMore: boolean;
  isLoading: boolean;
  analyticsData: AnalyticsData;
};

const ProductsVideosGrid = ({
  searchProducts,
  isInitialLoad,
  clearSearch,
  isLoadingMore,
  isLoading,
  analyticsData,
}: Props) => {
  if (!searchProducts.length && !isLoading) {
    return <ProductsTableEmptyState isLoading={isInitialLoad} clearSearch={clearSearch} />;
  }

  return (
    <LayoutRoot>
      <ComponentWithLoader isLoading={isInitialLoad || isLoading}>
        <TilesContainer>
          {searchProducts.map(({ imageUrl, productId, title }) => (
            <ProductVideoTile
              key={productId}
              imageUrl={imageUrl}
              productId={productId}
              title={title}
              analyticsData={analyticsData}
            />
          ))}
        </TilesContainer>
      </ComponentWithLoader>
      {isLoadingMore && <StyledSpinner />}
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  gap: 32px;
  padding: 16px;
  height: 100%;
`;

const TilesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-rows: 1fr;
  grid-gap: 32px 16px;
  padding: 12px 64px;
  z-index: 1;
`;

const StyledSpinner = styled(Spinner)`
  align-self: center;
`;

export default ProductsVideosGrid;
