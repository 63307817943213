import { useEffect, useRef } from 'react';
import useTracker from 'shared/react/components/complex/context/hooks/useTracker';
import Utils from 'shared/react/utils/utils';

const useVideoTracking = ({ videoRef, videoId, isDisabled, isCurrentVideo }) => {
  const [, { track }] = useTracker();
  const previousVideoTimeRef = useRef(0);
  const watchedTimeRef = useRef(0);
  const loopCountRef = useRef(0);
  const isSeekedRef = useRef(false);
  const videoDurationRef = useRef(0);
  const hasVideoPlayedRef = useRef(false);

  const trackVideoLoop = () => {
    loopCountRef.current += 1;
  };

  const getElapsedTime = (lastCurrentTime, currentTime = 0) => {
    if (lastCurrentTime > currentTime) {
      return currentTime;
    }

    return currentTime - lastCurrentTime;
  };

  const handleTimeUpdate = () => {
    if (!hasVideoPlayedRef.current) {
      hasVideoPlayedRef.current = true;
    }

    const elapsedTime = getElapsedTime(previousVideoTimeRef.current, videoRef.current?.currentTime);

    watchedTimeRef.current += elapsedTime;

    previousVideoTimeRef.current = videoRef.current?.currentTime || 0;
  };

  const handleSeeking = () => {
    previousVideoTimeRef.current = videoRef.current.currentTime;
    isSeekedRef.current = true;
  };

  const trackVideoData = () => {
    if (!hasVideoPlayedRef.current) {
      return;
    }

    track('videoWatched', {
      videoId,
      type: videoRef.current?.type,
      videoLoopCount: loopCountRef.current,
      videoWatchedTime: Utils.safeRoundNumber(watchedTimeRef.current, 3),
      videoDuration: Utils.safeRoundNumber(videoDurationRef.current, 3),
      isVideoSeeked: isSeekedRef.current,
    });
  };

  const initializeRefs = (isVisibilityChange = false) => {
    if (!isVisibilityChange) {
      previousVideoTimeRef.current = 0;
      videoDurationRef.current = 0;
    }

    watchedTimeRef.current = 0;
    loopCountRef.current = 0;
    isSeekedRef.current = false;
    hasVideoPlayedRef.current = false;
  };

  const handleMetadataLoaded = () => {
    videoDurationRef.current = videoRef.current.duration;
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      return;
    }

    trackVideoData();
    initializeRefs(true);
  };

  useEffect(() => {
    if (!videoRef?.current || isDisabled || !isCurrentVideo) {
      return;
    }

    videoDurationRef.current = videoRef.current.duration;

    videoRef.current.addEventListener('timeupdate', handleTimeUpdate);
    videoRef.current.addEventListener('seeking', handleSeeking);
    videoRef.current.addEventListener('loadedmetadata', handleMetadataLoaded);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      if (videoRef?.current) {
        videoRef.current.removeEventListener('timeupdate', handleTimeUpdate);
        videoRef.current.removeEventListener('seeking', handleSeeking);
        videoRef.current.removeEventListener('loadedmetadata', handleMetadataLoaded);
      }

      document.removeEventListener('visibilitychange', handleVisibilityChange);

      trackVideoData();

      initializeRefs();
    };
  }, [videoRef.current, videoId, isDisabled, isCurrentVideo]);

  return { trackVideoLoop };
};

export default useVideoTracking;
