import React from 'react';
import NotificationListItem from 'app/src/complex_components/notifications/NotificationListItem';
import EmptyStateContainer from 'app/src/complex_components/notifications/EmptyStateContainer';

const NotificationsList = ({ inAppNotifications, currentTeamMember, onClosePanel }) => {
  if (!inAppNotifications?.length) {
    return <EmptyStateContainer onClosePanel={onClosePanel} />;
  }

  return (
    <>
      {inAppNotifications.map(notification => (
        <NotificationListItem
          key={notification.id}
          notification={notification}
          teamMemberId={currentTeamMember?.teamMemberId}
        />
      ))}
    </>
  );
};

export default NotificationsList;
