import React from 'react';
import styled from 'styled-components';
import HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/HorizontalFlexWrap';
import { TextBody, TextSubtitle } from 'shared/react/components/complex/Text';
import AddPartIcon from 'app/src/images/addStepIcons/AddPartIcon';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import FolderCard from 'app/src/pages/dashboard/pages/libraries/folders_v2/FolderCard';
import ComponentWithLoader from 'app/src/complex_components/ComponentWithLoader';
import { useModal } from 'app/src/context/ui_store/ModalStore';
import { MODAL_STATE } from 'app/src/pages/modals/folder_modal/folders.constants';
import { RENAME_FOLDER_MODAL_KEY } from 'app/src/constants/modals.constants';
import { useDashboard } from 'app/src/context/ui_store/DashboardStore';
import { track } from 'app/src/helpers/Tracker';
import { FolderType } from 'app/src/types/entities';

const FoldersV2 = ({ folders, folderMapping, loading, searchValue }) => {
  const [, { setCurrentModal, setCurrentModalType, setModalProps }] = useModal();
  const [, { setSelectedFolder }] = useDashboard();

  const handleCreateNewFolder = () => {
    track('New Folder Click');
    setCurrentModalType(MODAL_STATE.new);
    setModalProps({ type: FolderType.projects });
    setCurrentModal(RENAME_FOLDER_MODAL_KEY);
  };

  const handleFolderClicked = folder => {
    track('Folder Click');
    setSelectedFolder(folder);
  };

  const getContent = folder => {
    if (!folderMapping[folder.id]?.length && searchValue) {
      return;
    }

    return (
      <FolderCard
        folder={folder}
        key={folder.id}
        numberOfTolstoys={folderMapping[folder.id]?.length || 0}
        onClick={handleFolderClicked}
      />
    );
  };

  return (
    <LayoutRoot>
      <StyledTextBody>Folders</StyledTextBody>
      <FoldersBar>
        <ComponentWithLoader isLoading={loading}>
          <FoldersContainer>
            {folders?.map(getContent)}
            <NewFolderButtonContainer onClick={handleCreateNewFolder}>
              <IconContainer>
                <AddPartIcon width={20} height={20} />
              </IconContainer>
              <NewFolderText>New Folder</NewFolderText>
            </NewFolderButtonContainer>
          </FoldersContainer>
        </ComponentWithLoader>
      </FoldersBar>
    </LayoutRoot>
  );
};

export default FoldersV2;

const LayoutRoot = styled(Gap8VerticalFlex)`
  padding-right: 24px;
`;

const FoldersBar = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  overflow: hidden;
  align-items: start;
`;

const FoldersContainer = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  overflow: hidden;
  padding: 4px 0;
`;

const NewFolderButtonContainer = styled(HorizontalFlexWrap)`
  gap: 10px;
  padding: 8px 16px;
  transition: 0.2s;
  cursor: pointer;
  align-items: center;
  width: fit-content;

  &:hover {
    transform: scale(1.05);
  }
`;

const NewFolderText = styled(TextSubtitle)`
  font-weight: 800;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTextBody = styled(TextBody)`
  font-weight: 800;
`;
