import React from 'react';

import { Plus } from 'lucide-react';
import { WhiteButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { DesignSizes } from 'shared/react/theme/DesignSystem';

const PlusButton = ({ onClick }) => {
  return (
    <WhiteButton onClick={onClick} size={DesignSizes.XSMALL}>
      <Plus width={16} height={16} />
    </WhiteButton>
  );
};

export default PlusButton;
