import React from 'react';

const LinkIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="13"
    viewBox="0 0 16 13"
    fill="none"
    {...props}
  >
    <path
      d="M13.672 6.781a3.353 3.353 0 0 0 0-4.781 3.382 3.382 0 0 0-4.36-.375l-.046.023a.776.776 0 0 0-.164 1.055.748.748 0 0 0 1.054.164l.024-.023a1.889 1.889 0 0 1 2.437.21c.75.727.75 1.923 0 2.673L9.992 8.352c-.75.75-1.945.75-2.672 0a1.889 1.889 0 0 1-.21-2.438l.023-.023a.748.748 0 0 0-.164-1.055.76.76 0 0 0-1.055.187l-.023.024a3.382 3.382 0 0 0 .375 4.36 3.353 3.353 0 0 0 4.78 0l2.626-2.626ZM1.508 6.242a3.353 3.353 0 0 0 0 4.781c1.172 1.172 3.023 1.336 4.36.375l.046-.023a.776.776 0 0 0 .164-1.055.748.748 0 0 0-1.055-.164L5 10.18a1.89 1.89 0 0 1-2.438-.211c-.726-.75-.726-1.946 0-2.672l2.626-2.625c.75-.75 1.945-.75 2.671 0 .657.656.75 1.664.211 2.437l-.023.024a.748.748 0 0 0 .164 1.054A.756.756 0 0 0 9.266 8l.023-.023c.961-1.36.82-3.211-.351-4.383a3.424 3.424 0 0 0-4.805 0L1.508 6.242Z"
      fill="#090A0B"
    />
  </svg>
);

export default LinkIcon;
