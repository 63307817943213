import * as React from 'react';

const ImageResponseAnswerIcon = ({ fill, ...props }) => {
  return (
    <svg
      width={16}
      height={14}
      viewBox="0 0 16 14"
      fill={fill || '#7D8087'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.75 2.75C3.90625 2.75 3.25 3.4375 3.25 4.25C3.25 5.09375 3.90625 5.75 4.75 5.75C5.5625 5.75 6.25 5.09375 6.25 4.25C6.25 3.4375 5.5625 2.75 4.75 2.75ZM14 0H2C0.875 0 0 0.90625 0 2V12C0 13.125 0.875 14 2 14H14C15.0938 14 16 13.125 16 12V2C16 0.90625 15.0938 0 14 0ZM14.5 11.8125L10.2188 6C10.0938 5.84375 9.9375 5.75 9.75 5.75C9.5312 5.75 9.375 5.84375 9.25 6L5.9375 10.5312L4.78125 9.09375C4.65625 8.9375 4.5 8.875 4.3125 8.875C4.125 8.875 3.9375 8.9375 3.84375 9.09375L1.5 12.0312C1.5 12.0312 1.5 12.0312 1.5 12V2C1.5 1.75 1.71875 1.5 2 1.5H14C14.25 1.5 14.5 1.75 14.5 2V11.8125Z"
        fill="#7D8087"
      />
    </svg>
  );
};

export default ImageResponseAnswerIcon;
