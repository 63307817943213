import React from 'react';
import AnalyticsFilterWithCalendar from 'app/src/complex_components/analytics/analytics-calendar/AnalyticsFilterWithCalendar';
import {
  DATE_FILTER_LAST_30_DAYS,
  DATE_FILTER_LAST_60_DAYS,
  DATE_FILTER_LAST_7_DAYS,
  DATE_FILTER_LAST_90_DAYS,
  DATE_FILTER_TODAY,
  DATE_FILTER_YESTERDAY,
} from 'app/src/constants/analytics.constants';
import CalendarContent from 'app/src/complex_components/analytics/analytics-calendar/CalendarContent';

const DateFilter = ({ onClose, comparator, setFilters, isVideoOnTime, value }) => {
  const onChange = value => {
    setFilters({
      date: {
        [comparator]: { fn: isVideoOnTime, args: [value.startDate, value.endDate] },
      },
    });
  };

  const filters = [
    DATE_FILTER_TODAY,
    DATE_FILTER_YESTERDAY,
    DATE_FILTER_LAST_7_DAYS,
    DATE_FILTER_LAST_30_DAYS,
    DATE_FILTER_LAST_60_DAYS,
    DATE_FILTER_LAST_90_DAYS,
  ];

  let currentValue = null;
  if (value.length) {
    currentValue = { startDate: value[0], endDate: value[1], key: 'selection', title: 'custom' };
  }

  return (
    <CalendarContent
      onChange={onChange}
      value={currentValue}
      filters={filters}
      maxDateBack={30000}
      closeMenu={onClose}
    />
  );
};

export default DateFilter;
