import { createActionsHook, createContainer, createHook, createStore } from 'react-sweet-state';
import { getRequest, postRequest } from '../helpers/API';
import Utils from 'app/src/utils';

const initialState = {
  data: [],
  loading: false,
  error: null,
  isExpectingDelayedFetch: false,
};

const actions = {
  fetchFeatures:
    appKey =>
    async ({ setState }) => {
      setState({ loading: true });
      try {
        const features = await getRequest('resolve-all', `/features?appKey=${appKey}`);

        setState({ data: features, loading: false });
      } catch (error) {
        console.error(error);
        setState({ error, loading: false });
      }
    },
  getFeatureEnabled:
    featureKey =>
    ({ getState }) => {
      const data = getState().data;
      return data[featureKey]?.enabled;
    },
  getFeatureBeta:
    featureKey =>
    ({ getState }) => {
      const { data } = getState();
      return data[featureKey]?.beta;
    },
  getSettingsByKey:
    featureKey =>
    ({ getState }) => {
      const { data } = getState();
      const featureSettings = getFeaturesSettings(data, [featureKey]);
      return featureSettings[featureKey];
    },
  getSettingsByKeys:
    featureKeys =>
    ({ getState }) => {
      const { data } = getState();
      return getFeaturesSettings(data, featureKeys);
    },
  updateAccountFeatureSettings:
    featuresToUpdate =>
    async ({ setState, getState }) => {
      const appKey = Utils.getAppKey();
      try {
        const updatedFeatures = await postRequest(
          'account-actions',
          '/actions/accounts/update-account-feature-settings',
          {
            body: { featuresToUpdate, appKey },
          }
        );

        const { data: currentFeatures } = getState();

        setState({ data: { ...currentFeatures, ...updatedFeatures } });
      } catch (error) {
        Utils.logError('Update account feature settings error', error);
        return error;
      }
    },
  addAddon:
    id =>
    async ({ getState, setState }) => {
      try {
        const { returnUrl, newFeatures } = await postRequest(
          'account-actions',
          '/actions/accounts/add-addon',
          {
            body: {
              appKey: Utils.getAppKey(),
              addonId: id,
            },
          }
        );

        if (returnUrl) {
          return { returnUrl };
        }

        const { data: currentFeatures } = getState();

        setState({ data: { ...currentFeatures, ...newFeatures } });
      } catch (error) {
        Utils.logError('Add addon error', error);
        return { error };
      }
    },
  removeAddon:
    id =>
    async ({ getState, setState }) => {
      try {
        const { returnUrl, newFeatures } = await postRequest(
          'account-actions',
          '/actions/accounts/remove-addon',
          {
            body: {
              appKey: Utils.getAppKey(),
              addonId: id,
            },
          }
        );
        const { data: currentFeatures } = getState();

        if (returnUrl) {
          return { returnUrl };
        }

        setState({ data: { ...currentFeatures, ...newFeatures } });
      } catch (error) {
        Utils.logError('Remove addon error', error);
        return error;
      }
    },
  setIsExpectingDelayedFetch:
    isExpectingDelayedFetch =>
    ({ setState }) => {
      setState({ isExpectingDelayedFetch });
    },
  clearFeatures:
    () =>
    ({ setState }) => {
      setState(initialState);
    },
};

const getFeaturesSettings = (data, featureKeys = []) => {
  let settings = {};
  featureKeys.forEach(featureKey => {
    const featureSettings = getFeatureSettings({ data }, featureKey);
    settings[featureKey] = featureSettings;
  });
  return settings;
};

export const getFeatureSettings = ({ data } = {}, featureKey = '') =>
  data[featureKey]?.settings || {};

export const getEnabledFeatureSettings = (features, featureKey = '') => {
  if (!getIsFeatureEnabled(features, featureKey)) {
    return {};
  }

  return getFeatureSettings({ data: features }, featureKey);
};

export const getIsFeatureEnabled = (features = {}, featureKey = '') => {
  return features[featureKey]?.enabled;
};

export const getIsFeaturesEnabled = ({ data }, featureKeys = []) =>
  featureKeys.map(featureKey => getIsFeatureEnabled(data, featureKey));

const isFeatureEnabledSelector = ({ data }, featureKey) =>
  getIsFeatureEnabled(data, featureKey) || false;

const FeaturesStore = createStore({ initialState, actions, name: 'Features' });

export const useFeatures = createHook(FeaturesStore);
export const useIsFeatureEnabled = createHook(FeaturesStore, {
  selector: isFeatureEnabledSelector,
});
export const useIsFeaturesEnabled = createHook(FeaturesStore, { selector: getIsFeaturesEnabled });
export const useFeatureSettings = createHook(FeaturesStore, { selector: getFeatureSettings });

export const useFeatureActions = createActionsHook(FeaturesStore);

export const FeaturesContainer = createContainer(FeaturesStore);
