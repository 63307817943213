import BasicMenuV2 from 'app/src/complex_components/BasicMenuV2';
import styled, { useTheme } from 'styled-components';
import { FEATURE_QUIZ_PRODUCTS_RESULT } from 'app/src/constants/appFeatures.constants';
import { LINK_KEY, LINK_RESPONSE_KEY, PRODUCTS_KEY } from 'app/src/constants/tolstoy.constants';
import React from 'react';
import { useFeatures } from 'app/src/context/FeaturesStore';
import LinkIcon from 'shared/react/images/share/LinkIcon';
import ShoppingBag from 'app/src/images/ShoppingBag';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const ResultTypeMenu = ({ setAnchorEl, anchorEl, setChosenType, chosenType }) => {
  const theme = useTheme();
  const [, { getFeatureEnabled }] = useFeatures();

  const onClose = () => {
    setAnchorEl(null);
  };

  const onChoose = key => {
    setChosenType(key);
    onClose();
  };

  const getQuizResultMenuItems = () => {
    const resultTypes = [
      {
        text: LINK_RESPONSE_KEY,
        isEnabled: chosenType === LINK_KEY,
        onClick: () => onChoose(LINK_KEY),
        icon: (
          <IconContainer background={theme.colors.primaryLight}>
            <LinkIcon fill={theme.colors.primary} width={12} />
          </IconContainer>
        ),
      },
    ];

    if (getFeatureEnabled(FEATURE_QUIZ_PRODUCTS_RESULT)) {
      resultTypes.push({
        text: PRODUCTS_KEY,
        isEnabled: chosenType === PRODUCTS_KEY,
        onClick: () => onChoose(PRODUCTS_KEY),
        icon: (
          <IconContainer background={theme.colors.successLight}>
            <ShoppingBag fill={theme.colors.success} width={12} height={12} />
          </IconContainer>
        ),
      });
    }

    return resultTypes;
  };

  return <BasicMenuV2 onClose={onClose} items={getQuizResultMenuItems()} anchorEl={anchorEl} />;
};

export default ResultTypeMenu;

const IconContainer = styled(VerticalFlex)`
  background: ${({ background }) => background};
  border-radius: 100%;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
`;
