import React from 'react';

import { Sparkles } from 'lucide-react';
import { WhiteButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { DesignSizes } from 'shared/react/theme/DesignSystem';
import styled from 'styled-components';

const VideoStudioButton = ({ onClick, label }) => {
  return (
    <StyledWhiteButton onClick={onClick} size={DesignSizes.XSMALL}>
      <Sparkles width={16} height={16} />
      {label}
    </StyledWhiteButton>
  );
};

const StyledWhiteButton = styled(WhiteButton)`
  color: ${({ theme }) => theme.colors.white};
  background-image: linear-gradient(to right, #0ff, #a855f7);
  &:hover {
    background-image: linear-gradient(to right, #0ff, #9333ea);
  }
`;
export default VideoStudioButton;
