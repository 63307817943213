import React, { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { RefreshCw } from 'lucide-react';
import { TextBody, TextSubtitle } from 'shared/react/components/complex/Text';
import ChatWithSupport from 'app/src/complex_components/ChatWithSupport';
import { useProjects } from 'app/src/context/ProjectsStore';
import { useParams } from 'react-router-dom';
import ToggleSetting from 'app/src/complex_components/ToggleSetting';
import Utils from 'app/src/utils';
import { RulesService } from 'src/services/rules';
import FormInput from 'app/src/complex_components/FormInput';
import CopyInput from 'app/src/complex_components/CopyInput';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import { FEATURE_QUIZ_LIMITS } from 'app/src/constants/appFeatures.constants';
import useProjectType from 'app/src/hooks/useProjectType';
import BasicAccordion from 'shared/react/components/BasicAccordion';
import { AccordionTitle, SectionTitle } from 'app/src/basic_components/commonStyles';
import TextSmallWrapper from 'app/src/basic_components/TextSmallWrapper';
import ResetToDefaultInputWrapper from 'app/src/complex_components/ResetToDefaultInputWrapper';
import {
  DEFAULT_PLAYER_LANGUAGE,
  LANGUAGES,
} from 'shared/react/constants/feature-constants/playerLangaugeCustomization.constants';
import BrandingBasicSelectWrapper from 'app/src/pages/account/branding/BrandingBasicSelectWrapper';
import PremiumFeatureTooltip from 'app/src/pages/account/branding/PremiumFeatureTooltip';
import ProIcon from 'app/src/images/ProIcon';
import { track } from 'app/src/helpers/Tracker';
import { usePackageBrandingFeatures } from 'src/hooks/package';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';
import OnOffToggleItem from 'app/src/pages/project/pages/look_and_feel/editor/editors/shared/OnOffToggleItem';
import Gap16HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap16HorizontalFlexWrap';
import Gap8VerticalFlex from 'shared/react/components/complex/flex_layouts/Gap8VerticalFlex';
import { useDynamicConditionsContext } from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/context/DynamicConditionsContext';
import { DynamicRuleTypes } from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/constants/dynamicConditions.constants';
import useSaveRules from 'app/src/pages/project/pages/project-edit/components/project-content/components/dynamic-type-content/components/dynamic-conditions/hooks/useSaveRules';
import FeedTypeToggleButtons from './FeedTypeToggleButtons';
import { getDefaultCampaignTileAction, getIsDerivedProject } from 'app/src/utils/project.utils';
import { publishMethodOptions } from 'app/src/types/entities';
import VideoSourceSelect from 'app/src/pages/project/pages/settings/VideoSourceSelect';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import CampaignTileActionAutocomplete from 'app/src/pages/project/pages/settings/CampaignTileActionAutocomplete';

import { CaseType, cases } from 'src/pages/dashboard/components/cases/cases.constants';
import { CAMPAIGN_TILE_ACTIONS } from 'app/src/constants/campaignTileActions.constants';
import { PrimaryButton } from 'shared/react/components/basic/button-v2/BasicButton';
import { EMAIL_TV_PAGE_HEADER_ID } from 'app/src/pages/project/pages/look_and_feel/LookAndFeelPage.constants';
import { ONSITE_TARGET_PAGES } from 'app/src/pages/dashboard/constants/onsiteTargetPages.constants';
import { useApps } from 'app/src/context/AppsStore';
import InfoIconWithTooltip from 'app/src/complex_components/InfoIconWithTooltip';
import HorizontalFlex from 'shared/react/components/complex/flex_layouts/HorizontalFlex';

function GeneralSettings({ isLoading }) {
  const { projectId } = useParams();
  const [{ project }, { getQuizOptionDisabled, getProjectById, updateProject }] = useProjects({
    projectId,
  });
  const [{ selectedApp }] = useApps();
  const appSettings = JSON.parse(selectedApp?.settings || '{}');

  const [{ project: sourceProject }] = useProjects({ projectId: project?.sourceProjectId });
  const isDerivedProject = getIsDerivedProject(project);
  const isEmail = project.publishMethod === publishMethodOptions.email;
  const isProductLaunchEmail =
    isEmail && project.useCaseName === cases[CaseType.EMAIL_PRODUCT_LAUNCH].name;
  const isTVPage = project.targetPage === ONSITE_TARGET_PAGES.TvPages;
  const emailSettings = Utils.safeParse(project?.emailSettings);
  const isHero = project.publishMethod === publishMethodOptions.hero;
  const [emailSettingsHeaderId, setEmailSettingsHeaderId] = useState(
    emailSettings[EMAIL_TV_PAGE_HEADER_ID]
  );
  const [isHeaderIdSaving, setIsHeaderIdSaving] = useState();

  const { getSettingsByKey } = useFeatureActions();
  const {
    maxNumberOfAnswersInTolstoy = 4,
    maxNumberOfPartsInTolstoy = 5,
    isEnabled = true,
  } = getSettingsByKey(FEATURE_QUIZ_LIMITS);
  const { projectDisplayTypeTitle } = useProjectType();
  const { setValue, watch } = useFormContext();
  const [
    isQuiz,
    tolstoyName,
    language,
    isDynamic = project?.dynamic,
    sourceProjectId = null,
    campaignTileAction = getDefaultCampaignTileAction(sourceProject),
    primaryHeroPublishId,
  ] = watch([
    'isQuiz',
    'tolstoyName',
    'language',
    'dynamic',
    'sourceProjectId',
    'campaignTileAction',
    'primaryHeroPublishId',
  ]);
  const {
    player: { settings: languageSettings },
  } = useDesignSettingsContext();
  const { rules, matchConditions, videoRules, setRules } = useDynamicConditionsContext();
  const { isPlayerLanguageEnabled } = usePackageBrandingFeatures();
  const isAllowNonTaggedVideosEnabled = rules?.some(
    rule => rule?.type === DynamicRuleTypes.allowNonTaggedVideos
  );
  const saveRules = useSaveRules(project);

  function handleTolstoyNameChange(e) {
    setValue('tolstoyName', e.target.value);
  }

  function handleHeaderIdChange(e) {
    setEmailSettingsHeaderId(e.target.value);
  }

  async function onHeaderIdSave() {
    setIsHeaderIdSaving(true);

    const newEmailSettings = {
      ...emailSettings,
      [EMAIL_TV_PAGE_HEADER_ID]: emailSettingsHeaderId,
    };

    await updateProject({ ...project, emailSettings: JSON.stringify(newEmailSettings) });
    setIsHeaderIdSaving(false);
  }

  const setProjectLanguageValue = value => {
    track('Player Language Change', { language: value, location: 'Project Settings' });

    setValue('language', value);
  };

  const onFeedTypeChange = value => setValue('dynamic', value);

  const onHelpArticle = () => {
    Utils.openInNewTab(
      'https://help.gotolstoy.com/en/articles/6456712-how-to-build-a-video-quiz-with-tolstoy'
    );
  };

  const getQuizSubtitle = () => {
    return (
      <HelpTextContainer>
        <HelpText>
          To learn more about creating a quiz with Tolstoy, see our
          <HelpLink onClick={onHelpArticle}>help article.</HelpLink>
        </HelpText>
      </HelpTextContainer>
    );
  };

  const isQuizOptionDisabled = useMemo(
    () =>
      getQuizOptionDisabled(
        project,
        isEnabled,
        maxNumberOfPartsInTolstoy,
        maxNumberOfAnswersInTolstoy
      ),
    [project]
  );

  const NAVIGATION_SETTINGS = [
    {
      type: 'quiz',
      enabled: isQuiz,
      setFunction: value => setValue('isQuiz', value),
      hidden: project.feed,
      disabled: isLoading || isQuizOptionDisabled,
      buttonTooltipText: isQuizOptionDisabled
        ? 'We only support up to 5 parts with no more than 4 answers per part, edit your Tolstoy to use the quiz logic.'
        : '',
      title: 'Turn on quiz',
      tooltipText:
        'Enabling this option will add a tab to your builder where you can set quiz logic in a simple spreadsheet.',
      subtitle: getQuizSubtitle,
    },
  ];

  const setNavigationSettings = (setFunction, enabled) => {
    setFunction(!enabled);
  };

  const toggleAllowNonTaggedVideos = () => {
    let newRules = [];

    if (isAllowNonTaggedVideosEnabled) {
      newRules = rules.filter(rule => rule?.type !== DynamicRuleTypes.allowNonTaggedVideos);
    } else {
      newRules = [...rules, RulesService.getAllowNonTaggedVideosRule()];
    }

    setRules(newRules);
    saveRules({ rules: newRules, matchConditions, videoRules });
  };

  const togglePrimaryHero = () => {
    const isSelected = project.publishId === primaryHeroPublishId;
    if (!isSelected) {
      setValue('primaryHeroPublishId', project.publishId);
      return;
    }

    if (appSettings.primaryHeroPublishId === project.publishId) {
      setValue('primaryHeroPublishId', null);
      return;
    }

    setValue('primaryHeroPublishId', appSettings.primaryHeroPublishId);
  };

  const handleVideoSourceChange = newSourceProjectId => {
    setValue('sourceProjectId', newSourceProjectId);

    if (!newSourceProjectId) {
      setValue('campaignTileAction', CAMPAIGN_TILE_ACTIONS.openTolstoyTvPage);
      return;
    }

    if (Utils.isValidUrl(campaignTileAction)) {
      return;
    }

    const newSourceProject = getProjectById(newSourceProjectId);
    setValue('campaignTileAction', getDefaultCampaignTileAction(newSourceProject));
  };

  const navigationSettings = NAVIGATION_SETTINGS.filter(({ hidden }) => !hidden);
  const navigationSettingsLength = navigationSettings.length;

  const settingsFields = [
    {
      key: 'tolstoyName',
      isHidden: false,
      element: (
        <>
          <TolstoyNameText>{projectDisplayTypeTitle} name</TolstoyNameText>
          <StyledTextField
            label="Name"
            value={tolstoyName || ''}
            inputProps={{ maxLength: 72 }}
            onChange={handleTolstoyNameChange}
            variant="outlined"
          />
        </>
      ),
    },
    {
      key: 'publishId',
      isHidden: isDerivedProject,
      element: (
        <>
          <TolstoyIdText>Publish ID</TolstoyIdText>
          <StyledCopyInput value={project?.publishId} />
        </>
      ),
    },
    {
      key: 'videoSource',
      isHidden: true,
      element: (
        <InputWrapper>
          <VideoSourceText>Video source</VideoSourceText>
          <HelpTextContainer>
            <SmallHelpText>
              Choose from the live video feeds on your site or create a new video feed.
            </SmallHelpText>
          </HelpTextContainer>
          <VideoSourceSelect
            project={project}
            onChange={handleVideoSourceChange}
            value={sourceProjectId}
          />
        </InputWrapper>
      ),
    },
    {
      key: 'campaignTileAction',
      isHidden: true,
      element: (
        <InputWrapper>
          <CampaignTileActionText>GIF action</CampaignTileActionText>
          <HelpTextContainer>
            <SmallHelpText>
              Select GIF redirect page, make sure the source videos are live on the selected page or
              select a new TV page.
            </SmallHelpText>
          </HelpTextContainer>
          <CampaignTileActionAutocomplete
            disabled={isLoading || !sourceProjectId}
            onChange={value => setValue('campaignTileAction', value)}
            value={campaignTileAction}
            project={getProjectById(sourceProjectId)}
          />
        </InputWrapper>
      ),
    },
    {
      key: 'frontFacingLanguage',
      isHidden: isDerivedProject,
      element: (
        <PremiumFeatureTooltip placement="bottom" header={'Languages'}>
          <InputWrapper
            header="Front facing language"
            disabled={!isPlayerLanguageEnabled}
            tooltipText="Select language to apply to all system buttons and place holders in this Tolstoy. Contact support if the language you want does not appear on the list."
            textComponent={<LanguageTitle>Front facing language</LanguageTitle>}
            afterInfoIcon={<ProIcon />}
          >
            <ResetToDefaultInputWrapper
              onClick={() => setProjectLanguageValue(DEFAULT_PLAYER_LANGUAGE)}
            >
              <BasicSelect
                currentValue={language || languageSettings?.language || DEFAULT_PLAYER_LANGUAGE}
                onChange={setProjectLanguageValue}
                options={LANGUAGES}
              />
            </ResetToDefaultInputWrapper>
          </InputWrapper>
          <ChatWithSupport>Want another language?</ChatWithSupport>
        </PremiumFeatureTooltip>
      ),
    },
    {
      key: 'headerId',
      isHidden: !isEmail || !isTVPage,
      element: (
        <InputWrapper>
          <TolstoyNameText>Can't see the store's header</TolstoyNameText>
          <HelpTextContainer>
            <SmallHelpText>
              We might be having trouble indetfying your stores HEADER_ID. Please input the sores
              HEADER_ID and click sync button.
            </SmallHelpText>
          </HelpTextContainer>
          <HeaderIdSyncLine>
            <StyledTextField
              label="HEADER_ID"
              placeholder="HEADER_ID"
              value={emailSettingsHeaderId || ''}
              onChange={handleHeaderIdChange}
              inputProps={{ maxLength: 72 }}
              disabled={isHeaderIdSaving}
              variant="outlined"
            />
            <PrimaryButton iconOnly={true} onClick={onHeaderIdSave} isLoading={isHeaderIdSaving}>
              <RefreshCw />
            </PrimaryButton>
          </HeaderIdSyncLine>
        </InputWrapper>
      ),
    },
    {
      key: 'feedType',
      isHidden: isDerivedProject || isHero,
      element: (
        <FeedTypeSetting>
          <TextSubtitle>Feed type</TextSubtitle>
          <FeedTypeToggleButtons isDynamic={isDynamic} onChange={onFeedTypeChange} />
        </FeedTypeSetting>
      ),
    },
    {
      key: 'isAllowNonTaggedVideosEnabled',
      isHidden: isDerivedProject,
      element: (
        <AllowNonTaggedSettings>
          <TextSubtitle>Allow non tagged videos on PDPs</TextSubtitle>
          <OnOffToggleItem
            checked={isAllowNonTaggedVideosEnabled}
            onChange={toggleAllowNonTaggedVideos}
          />
        </AllowNonTaggedSettings>
      ),
    },
    {
      key: 'heroMain',
      isHidden: !isHero || !selectedApp,
      element: (
        <AllowNonTaggedSettings>
          <HorizontalFlex>
            <TextSubtitle>Set as primary Hero video</TextSubtitle>
            <InfoIconWithTooltip
              title={
                'Each store can have one primary Hero video optimized for performance, recommended for highest traffic pages'
              }
              alignToCenter
            />
          </HorizontalFlex>

          <OnOffToggleItem
            checked={project.publishId === primaryHeroPublishId}
            onChange={togglePrimaryHero}
          />
        </AllowNonTaggedSettings>
      ),
    },
    {
      key: 'quizFunctionality',
      isHidden: !navigationSettingsLength || isDerivedProject,
      element: (
        <>
          <SectionTitle>Quiz functionality</SectionTitle>
          <ToggleSettingsContainer>
            {navigationSettings.map(
              ({
                type,
                enabled,
                setFunction,
                title,
                tooltipText,
                subtitle,
                disabled,
                buttonTooltipText,
              }) => {
                return (
                  <ToggleSetting
                    key={type}
                    enabled={enabled}
                    onEnabledChange={() => setNavigationSettings(setFunction, enabled)}
                    title={title}
                    disabled={disabled}
                    tooltipText={tooltipText}
                    subtitle={subtitle?.()}
                    buttonTooltipText={buttonTooltipText}
                  />
                );
              }
            )}
          </ToggleSettingsContainer>
        </>
      ),
    },
  ];

  return (
    <LayoutRoot>
      <MainContainer>
        <BasicAccordion header={<AccordionTitle>General</AccordionTitle>} startExpanded={true}>
          <ChatWithSupport>Need some help configuring your settings?</ChatWithSupport>
          {settingsFields.map(({ key, isHidden, element }) => {
            if (isHidden) {
              return null;
            }

            return <React.Fragment key={key}>{element}</React.Fragment>;
          })}
        </BasicAccordion>
      </MainContainer>
    </LayoutRoot>
  );
}

const LayoutRoot = styled.div`
  display: grid;
  align-items: start;
  align-content: start;
  width: 100%;
  padding: 0 24px 24px 24px;
  overflow-y: auto;

  @media (${({ theme }) => theme.breakpoints.tabletMax}) {
    padding: 16px;
  }
`;

const MainContainer = styled.div`
  max-width: 630px;
`;

const TolstoyNameText = styled(TextSubtitle)`
  margin-top: 24px;
`;

const StyledCopyInput = styled(CopyInput)`
  margin-top: 8px;
  width: 336px;
`;

const StyledTextField = styled(FormInput)`
  margin-top: 8px;
`;

const ToggleSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  gap: 16px;
`;

const FacebookPixelTitle = styled(TextSubtitle)`
  margin-top: 16px;
`;

const TolstoyIdText = styled(FacebookPixelTitle)``;

const VideoSourceText = styled(TextSubtitle)``;

const CampaignTileActionText = styled(TextSubtitle)``;

const HelpTextContainer = styled.div`
  display: flex;
`;

const HelpText = styled(TextBody)`
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.gray2};
`;

const SmallHelpText = styled(TextTiny)`
  color: ${({ theme }) => theme.colors.neutralDark};
  font-weight: 500;
`;

const HelpLink = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.blue1};
  font-weight: bold;
  margin-left: 4px;
`;

const InputWrapper = styled(TextSmallWrapper)`
  width: 346px;
  margin-top: 16px;
  & path {
    fill: ${({ theme }) => theme.colors.black};
  }

  & ${HelpTextContainer} {
    margin-top: 2px;
    margin-bottom: 6px;
  }
`;

const LanguageTitle = styled(FacebookPixelTitle)`
  margin-top: 0;
`;

const BasicSelect = styled(BrandingBasicSelectWrapper)`
  margin: 8px 0 4px;
`;

const FeedTypeSetting = styled(Gap8VerticalFlex)`
  display: inline-flex;
  margin-top: 16px;
`;

const HeaderIdSyncLine = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  & button {
    margin-top: 8px;
  }
  & path {
    fill: none;
  }
`;

const AllowNonTaggedSettings = styled(Gap16HorizontalFlexWrap)`
  margin-top: 16px;
`;

export default GeneralSettings;
