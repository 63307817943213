import React from 'react';

const EmailBadgeIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={18}
      height={14}
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 0c1.094 0 2 .906 2 2v10c0 1.125-.906 2-2 2H2c-1.125 0-2-.875-2-2V2C0 .906.875 0 2 0zm.5 12V2c0-.25-.25-.5-.5-.5H2c-.281 0-.5.25-.5.5v10c0 .281.219.5.5.5h14c.25 0 .5-.219.5-.5m-2-7.875c.344 0 .625.281.625.625a.61.61 0 0 1-.625.625h-2.125v1H14c.344 0 .625.281.625.625a.61.61 0 0 1-.625.625h-1.625V9.25a.61.61 0 0 1-.625.625c-.375 0-.625-.25-.625-.625v-4.5c0-.344.25-.625.625-.625zm-5 0c.344 0 .625.281.625.625v4.5a.61.61 0 0 1-.625.625c-.375 0-.625-.25-.625-.625v-4.5c0-.344.25-.625.625-.625m-2 2.5c.344 0 .625.281.625.625v1.344c0 .187-.094.344-.187.469-.563.53-1.313.812-2.125.812s-1.563-.281-2.125-.812A2.96 2.96 0 0 1 2.875 7c0-.75.281-1.469.813-2.031 1.093-1.063 2.968-1.063 4.03 0 .25.25.25.656 0 .906-.218.25-.624.25-.874 0-.625-.625-1.657-.625-2.25 0A1.6 1.6 0 0 0 4.125 7c0 .438.156.844.469 1.156.531.563 1.625.625 2.281.156v-.437H5.688a.61.61 0 0 1-.625-.625c0-.344.28-.625.625-.625z"
        fill="#7D8087"
      />
    </svg>
  );
};

export default EmailBadgeIcon;
