import React from 'react';
import styled from 'styled-components';
import { SearchInput } from 'app/src/basic_components/input/InputWithIcon';
import Spinner from 'app/src/basic_components/Spinner';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';

const ProductsSearch = ({ productsValue, onSearchProduct, isProductsLoading }) => {
  return (
    <LayoutRoot>
      <Container>
        <SearchInput placeholder="Search..." value={productsValue} onChange={onSearchProduct} />
        {isProductsLoading && <Loader size={16} />}
      </Container>
    </LayoutRoot>
  );
};

const LayoutRoot = styled(VerticalFlex)`
  gap: 4px;
`;

const Container = styled.div`
  position: relative;

  input {
    padding: 8px 10px 8px 30px;
  }
`;

const Loader = styled(Spinner)`
  position: absolute;
  right: 8px;
  top: 0;
  bottom: 0;
  margin: auto;
`;

export default ProductsSearch;
