import React from 'react';
import styled, { useTheme } from 'styled-components';
import Stepper from 'app/src/complex_components/Stepper';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import HorizontalFlex, {
  Gap8HorizontalFlex,
} from 'shared/react/components/complex/flex_layouts/HorizontalFlex';
import VerticalFlex from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import { TextBody } from 'shared/react/components/complex/Text';
import CopyCodeButtonPricing from 'app/src/complex_components/pricing/CopyCodeButtonPricing';
import Utils from 'app/src/utils';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';

const PublishVideoPageShopifyStepper = ({ publishId }) => {
  const theme = useTheme();
  const [, { setSnackbar }] = useSnackBar();
  const stepTextCustomStyle = { color: theme.colors.gray2 };

  const copyPublishId = () => {
    Utils.copyToClipboard(publishId);
    setSnackbar('Copied to Clipboard!');
  };

  return (
    <Stepper
      optional={[5]}
      stepComponents={[
        <Step key={1} stepTextStyle={stepTextCustomStyle}>
          <StepHeader>Create new page template</StepHeader>
          <StepContent>Go to Shopify themes → Pages → Create template → “Video page”</StepContent>
        </Step>,
        <Step key={2} stepTextStyle={stepTextCustomStyle}>
          <StepHeader>Create new video page</StepHeader>
          <StepContent>
            Go to Sales channels → Online Store → Pages → Add page*
            <ItalicText>* Make sure to assign page to new “Video page” template.</ItalicText>
          </StepContent>
        </Step>,
        <Step key={3} stepTextStyle={stepTextCustomStyle}>
          <StepHeader>Add section to video page</StepHeader>
          <StepContent>
            Go to Shopify themes → Pages → Video page → Add section → “Tolstoy Video Page”
          </StepContent>
        </Step>,
        <Step key={4} stepTextStyle={stepTextCustomStyle}>
          <StepHeader>Paste ID in section</StepHeader>
          <StepContentWithGap>
            Copy the FeedID and paste in the “Tolstoy Video Page” section on Shopify themes.
            <FeedIdContainer>
              <FeedIdValue>{publishId}</FeedIdValue>
              <SmallerCopyCodeButtonPricing
                onClick={copyPublishId}
                location="Embed"
                tooltipHeader="embed"
              />
            </FeedIdContainer>
          </StepContentWithGap>
        </Step>,
        <Step key={5} stepTextStyle={stepTextCustomStyle}>
          <StepHeader>Set menu item</StepHeader>
          <StepContent>
            Go to Shopify → Online Store → Navigation → Menu → Add menu item→ Select video page for
            Link
          </StepContent>
        </Step>,
      ]}
    />
  );
};

const Step = styled(TextBody)`
  font-weight: 800;
`;

const StepHeader = styled(Gap8HorizontalFlexWrap)`
  align-items: center;
`;

const StepContent = styled(TextBody)`
  font-weight: 600;
`;

const StepContentWithGap = styled(VerticalFlex)`
  font-weight: 600;
  gap: 4px;
`;

const ItalicText = styled.div`
  font-style: italic;
`;

const FeedIdContainer = styled(Gap8HorizontalFlex)`
  align-items: center;
`;

const FeedIdValue = styled(HorizontalFlex)`
  height: 40px;
  width: 160px;
  align-items: center;
  padding: 0 16px;
  background: ${({ theme }) => theme.colors.gray5};
  border: 1px solid ${({ theme }) => theme.colors.gray3};
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.gray34};
`;

const SmallerCopyCodeButtonPricing = styled(CopyCodeButtonPricing)`
  width: 32px;
  height: 32px;
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.blue7};

  & svg {
    width: 16px;
    height: 16px;
  }
`;

export default PublishVideoPageShopifyStepper;
