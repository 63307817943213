import React, { useState } from 'react';
import EyeIcon from 'app/src/images/dashboard_v2/EyeIcon';
import CrossedEyeIcon from 'app/src/images/CrossedEyeIcon';
import styled from 'styled-components';
import { TextTiny } from 'shared/react/components/basic/text/TextV2';
import { rule as RuleType, vodAsset } from 'app/src/types/entities';
import {
  getHideVodRuleTypeKey,
  getOrderVodRuleTypeKey,
  useRulesActions,
} from 'app/src/context/RulesStore';
import { useFeatureActions } from 'app/src/context/FeaturesStore';
import { FEATURE_DYNAMIC_HIDE_VOD_ASSET } from 'shared/react/constants/features.constants';
import { HIDE_VOD_ASSET_TYPE, ORDER_FEED_TYPE } from 'app/src/constants/ruleGroups.constants';
import OpenLockIcon from 'app/src/images/OpenLockIcon';
import LockIcon from 'app/src/images/LockIcon';
import { useDesignSettingsContext } from 'shared/react/components/complex/context/DesignSettingsContext';
import Gap8HorizontalFlexWrap from 'shared/react/components/complex/flex_layouts/Gap8HorizontalFlexWrap';
import { VerticalFlexCentered } from 'shared/react/components/complex/flex_layouts/VerticalFlex';
import MinusIcon from 'src/images/MinusIcon';

type Props = {
  videoRules?: RuleType[];
  setVideoRules?: (rules: RuleType[]) => void;
  video: vodAsset;
  productId?: string;
  className?: string;
  index?: number;
  isLocked?: boolean;
  isOutOfStock: boolean;
  canLock?: boolean;
  isDiscover?: boolean;
  canRemove?: boolean;
  onRemove?: () => void;
};

const TableVideoOverlay = ({
  videoRules,
  productId,
  video,
  setVideoRules,
  className,
  index,
  isLocked,
  isOutOfStock,
  canLock,
  isDiscover,
  canRemove,
  onRemove,
}: Props) => {
  const { createHideVideoRule, createFeedOrderRule } = useRulesActions();
  const { getFeatureEnabled } = useFeatureActions();
  const [isHovered, setIsHovered] = useState(false);
  const { player } = useDesignSettingsContext();
  const { hideOOSProducts } = player.feedCards.productDetailsCard.stock;

  const isHidden =
    (isOutOfStock && hideOOSProducts && !isDiscover) ||
    !!videoRules?.find(({ value }) => {
      return value === getHideVodRuleTypeKey(video.id, productId);
    });

  const onToggleHidden = e => {
    e.stopPropagation();

    if (isOutOfStock && !isDiscover) {
      return;
    }

    if (isHidden) {
      setVideoRules(
        videoRules.filter(({ value, type }) => {
          if (type === HIDE_VOD_ASSET_TYPE) {
            return value !== getHideVodRuleTypeKey(video.id, productId);
          }

          if (type === ORDER_FEED_TYPE) {
            return value !== getOrderVodRuleTypeKey(video.id, index, productId);
          }

          return true;
        })
      );
      return;
    }

    const newRule = createHideVideoRule(video.id, productId);
    setVideoRules([...videoRules, newRule] as RuleType[]);
    setIsHovered(true);
  };

  const onLockOrder = e => {
    e.stopPropagation();

    if (isLocked) {
      setVideoRules(
        videoRules.filter(
          ({ value }) => value !== getOrderVodRuleTypeKey(video.id, index, productId)
        )
      );
      return;
    }

    const newRule = createFeedOrderRule(video.id, index, productId);
    setVideoRules([...videoRules, newRule] as RuleType[]);
  };

  const showHideVideoButton = videoRules && getFeatureEnabled(FEATURE_DYNAMIC_HIDE_VOD_ASSET);
  const onRemoveClick = e => {
    e.stopPropagation();
    onRemove();
  };

  if (!canLock && !showHideVideoButton && !canRemove) {
    return null;
  }

  if (isHidden) {
    return (
      <HiddenContainer
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {canLock && (
          <LockVideo isLocked={isLocked} className={className} onClick={onLockOrder}>
            {isLocked ? <LockIcon /> : <OpenLockIcon />}
          </LockVideo>
        )}
        <HiddenContent onClick={onToggleHidden} isHovered={isHovered} isOutOfStock={isOutOfStock}>
          <CrossedEyeIcon />
          {isHovered && !isOutOfStock ? 'Show' : 'Hidden'}
        </HiddenContent>
        {isOutOfStock && <OutOfStockLabel>Out of stock</OutOfStockLabel>}
      </HiddenContainer>
    );
  }

  return (
    <>
      {canLock && (
        <LockVideo isLocked={isLocked} className={className} onClick={onLockOrder}>
          {isLocked ? <LockIcon /> : <OpenLockIcon />}
        </LockVideo>
      )}
      <ControlsButtonContainer className={className}>
        {showHideVideoButton && (
          <ControlButton onClick={onToggleHidden}>
            <EyeIcon />
          </ControlButton>
        )}
        {canRemove && (
          <ControlButton onClick={onRemoveClick}>
            <MinusIcon />
          </ControlButton>
        )}
      </ControlsButtonContainer>
    </>
  );
};

const AbsoluteContainer = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.getHexOpacity(theme.colors.white, 75)};
`;

const ControlsButtonContainer = styled(Gap8HorizontalFlexWrap)`
  position: absolute;
  display: none;
  top: 2px;
  right: 2px;
`;

const ControlButton = styled(VerticalFlexCentered)`
  border-radius: 4px;
  width: 28px;
  height: 28px;
  cursor: pointer;
  align-items: center;
  background: ${({ theme }) => theme.getHexOpacity(theme.colors.white, 75)};

  & svg {
    width: 12px;
    height: 12px;
  }

  & path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const LockVideo = styled(ControlButton)<{ isLocked: boolean }>`
  display: ${({ isLocked }) => (isLocked ? 'flex' : 'none')};
  position: absolute;
  top: 2px;
  left: 2px;
`;

const HiddenContainer = styled(AbsoluteContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 96px;
  border-radius: 2px 2px 0 0;
  top: 0;
`;

const HiddenContent = styled(TextTiny)<{ isHovered: boolean; isOutOfStock: boolean }>`
  background: ${({ theme }) => theme.getHexOpacity(theme.colors.white, 75)};
  padding: 8px;
  border-radius: 4px;
  display: flex;
  gap: 6px;
  cursor: pointer;
  color: ${({ theme, isHovered, isOutOfStock }) =>
    isHovered && !isOutOfStock ? theme.colors.neutralBlack : theme.colors.neutralDark};

  & svg {
    width: 12px;
    height: 12px;
  }
`;

const OutOfStockLabel = styled(AbsoluteContainer)`
  left: 4px;
  bottom: 4px;
  padding: 4px;
  border-radius: 2px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
  color: ${({ theme }) => theme.colors.dangerV2};
  font-size: 12px;
  font-weight: 500;
`;

export default TableVideoOverlay;
