import { COOKIE_POLICIES, TOLSTOY_CACHE_KEYS } from '../constants/cache.constants.js';
import { Keys } from '../widgets/keys.js';

const { TOLSTOY_COOKIE_POLICY } = TOLSTOY_CACHE_KEYS;

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.trunc(Math.random() * 16);
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function wrapStorageAction(action) {
  try {
    return action();
  } catch (error) {
    window.tolstoyCaptureError?.(error);
    return null;
  }
}

export function getFromSessionStorage(name) {
  return wrapStorageAction(() => window.sessionStorage.getItem(name));
}

export function getFromLocalStorage(name) {
  return wrapStorageAction(() => localStorage.getItem(name)) || getFromSessionStorage(name);
}

export function getFromCache(name) {
  return getFromLocalStorage(name) || getFromSessionStorage(name);
}

export function setToSessionStorage(name, value, saveIfRejectedCookies = false) {
  wrapStorageAction(() => {
    const cookiePolicy = getCookiePolicy();

    if (cookiePolicy === COOKIE_POLICIES.rejected && !saveIfRejectedCookies) {
      return;
    }

    return window.sessionStorage.setItem(name, value);
  });
}

export function setLocalStorage(name, value, saveIfRejectedCookies = false) {
  wrapStorageAction(() => {
    const cookiePolicy = getCookiePolicy();

    if (cookiePolicy !== COOKIE_POLICIES.rejected) {
      return localStorage.setItem(name, value);
    }

    if (saveIfRejectedCookies) {
      return window.sessionStorage.setItem(name, value);
    }
  });
}

export function setToCache(name, value, saveIfRejectedCookies = false) {
  wrapStorageAction(() => {
    const cookiePolicy = getCookiePolicy();

    if (cookiePolicy !== COOKIE_POLICIES.rejected) {
      return localStorage.setItem(name, value);
    }

    if (saveIfRejectedCookies) {
      return window.sessionStorage.setItem(name, value);
    }
  });
}

export function removeFromSessionStorage(name) {
  return wrapStorageAction(() => window.sessionStorage.removeItem(name));
}

export const getCookiePolicy = () => {
  return getFromCache(TOLSTOY_COOKIE_POLICY);
};

export const setCookiePolicy = cookiePolicy => {
  setToCache(TOLSTOY_COOKIE_POLICY, cookiePolicy, true);
};

export const invalidateCookie = name => {
  const domain = window.location.host;

  document.cookie = `${name}=; path=/; domain=.${domain}; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
};

const getShouldInvalidateKey = keyName => {
  const cacheKeys = [...Object.values(TOLSTOY_CACHE_KEYS), ...Object.values(Keys)];

  return cacheKeys.some(key => keyName.includes(key));
};

export function removeAllTolstoyKeysFromStorage() {
  const cookies = document.cookie.split('; ');

  for (const cookie of cookies) {
    const [name] = cookie.split('=');
    if (getShouldInvalidateKey(name.trim())) {
      invalidateCookie(name);
    }
  }

  for (const key in localStorage) {
    if (getShouldInvalidateKey(key)) {
      localStorage.removeItem(key);
    }
  }

  for (const key in sessionStorage) {
    if (getShouldInvalidateKey(key)) {
      sessionStorage.removeItem(key);
    }
  }
}

// Cleanup remove all cookies
const removeAllCookies = () => {
  const cookies = document.cookie.split('; ');

  for (const cookie of cookies) {
    const [name] = cookie.split('=');
    if (getShouldInvalidateKey(name.trim())) {
      invalidateCookie(name);
    }
  }
};

removeAllCookies();
