import {
  CreationMethod,
  IMPORT_FROM_GOOGLE_DRIVE,
  IMPORT_FROM_YOUTUBE,
} from 'app/src/constants/creation_method.constants';
import {
  DROPBOX,
  GOOGLE_DRIVE,
  INSTAGRAM,
  INSTAGRAM_GRAPH,
  SYNTHESIA,
  TIKTOK,
  YOUTUBE,
} from 'app/src/constants/intergrations.constants';
import { INTEGRATION_VOD_ASSET_STATUS_TYPES } from 'app/src/constants/video.constants';
import { useApps } from 'app/src/context/AppsStore';
import { useIntegrationVodAsset } from 'app/src/context/IntegrationVodAssetStore';
import { useVideos } from 'app/src/context/VideosStore';
import { useSnackBar } from 'app/src/context/ui_store/SnackBarStore';
import { useUser } from 'app/src/context/userStore/UserStore';
import {
  IMPORT_METHODS,
  RECORDING_TYPES,
} from 'app/src/hooks/use-create-integration-video/useCreateIntegrationVideo.constants';
import useIntegrationsVideos from 'app/src/hooks/useIntegrationsVideos';
import { VIDEO_STATUS } from 'shared/react/constants/video.constants';
import { v4 as uuidv4 } from 'uuid';
import useOnInstagramVideoImported from '../use-on-instagram-video-imported/useOnInstagramVideoImported';
import Utils from 'app/src/utils';
import { ASSET_TYPE, AssetRenditionsService } from 'shared/react/services/assets';

const INSTAGRAM_APPS = [INSTAGRAM, INSTAGRAM_GRAPH];

const useCreateIntegrationVideo = () => {
  const [{ data: videos }, { importMultipleVideos, createVideo, getVideoById }] = useVideos();
  const [{ synthesia }] = useApps();
  const [, { setErrorSnackbar }] = useSnackBar();
  const [{ account }] = useUser();
  const [{ assets }, { updateIntegrationVodAsset, createIntegrationVodAsset }] =
    useIntegrationVodAsset();
  const { instagram, tiktok, googleDrive, youtube } = useIntegrationsVideos();
  const { handleInstagramVideoImported } = useOnInstagramVideoImported();

  const createOrUpdateIntegrationVodAsset = async ({
    owner,
    asset,
    vodId,
    selectedAsset,
    method,
  }) => {
    if (INSTAGRAM_APPS.includes(method)) {
      await handleInstagramVideoImported({ integrationVodAsset: asset, vodAssetId: vodId });
    }

    if (selectedAsset) {
      const newAsset = {
        ...selectedAsset,
        seen: true,
        status: INTEGRATION_VOD_ASSET_STATUS_TYPES.Imported,
        vodId,
      };

      await updateIntegrationVodAsset(newAsset);
      return;
    }

    if (method === TIKTOK) {
      await createIntegrationVodAsset({
        ...asset,
        id: asset.externalId || asset.id,
        appName: TIKTOK,
        owner,
        appId: tiktok.id,
        vodId,
      });
    }

    if (method === SYNTHESIA) {
      await createIntegrationVodAsset({
        ...asset,
        id: asset.externalId || asset.id,
        appName: SYNTHESIA,
        owner,
        appId: synthesia.id,
        externalCreatedAt: asset.createdAt,
        vodId,
      });
    }

    if (method === GOOGLE_DRIVE) {
      await createIntegrationVodAsset({
        ...asset,
        id: asset.externalId || asset.id,
        appName: GOOGLE_DRIVE,
        owner,
        appId: googleDrive.id,
        externalCreatedAt: asset.createdAt,
        vodId,
      });
    }

    if (method === YOUTUBE) {
      await createIntegrationVodAsset({
        ...asset,
        id: asset.externalId || asset.id,
        appName: YOUTUBE,
        owner,
        appId: youtube.id,
        externalCreatedAt: asset.createdAt,
        vodId,
      });
    }

    if (method === DROPBOX) {
      await createIntegrationVodAsset({
        ...asset,
        id: asset.externalId || asset.id,
        appName: DROPBOX,
        owner,
        vodId,
      });
    }

    if (INSTAGRAM_APPS.includes(method)) {
      const { description, timestamp } = asset;
      await createIntegrationVodAsset({
        ...asset,
        id: asset.externalId || asset.id,
        externalCreatedAt: timestamp,
        title: description,
        appName: instagram.app,
        owner,
        appId: instagram.id,
        vodId,
      });
    }
  };

  const getVideoName = (asset, method, index) => {
    if (method === DROPBOX) {
      return asset.name;
    }

    return asset?.title || asset.description || `Recorded Video #${videos.length + 1 + index}`;
  };

  const createVideoHandler = async (asset, index, method, owner, videoFolder, ugcHashtag) => {
    const selectedAsset = assets.find(({ externalId }) => {
      return externalId === asset.id || externalId === asset.externalId;
    });

    if (asset.referenceIds) {
      asset.referenceIds = asset.referenceIds.map(referenceId => {
        const selectedReferenceVideo = videos.find(({ externalId }) => {
          return externalId === referenceId || externalId === referenceId;
        });
        return selectedReferenceVideo?.id;
      });
    }

    const vodAsset = getVideoById(selectedAsset?.vodId);
    if (vodAsset?.status === VIDEO_STATUS.done) {
      return { videoId: selectedAsset.vodId };
    }
    const uuid = asset.newId || uuidv4();
    const name = getVideoName(asset, method, index);

    const uploadType = RECORDING_TYPES[method] || method;
    let videoInput = {
      id: uuid,
      name,
      type: asset.type,
      status: VIDEO_STATUS.uploading,
      description: asset?.description,
      externalId: asset?.externalId,
      appExternalId: asset?.appExternalId,
      referenceIds: asset?.referenceIds,
      uploadType,
      mirror: false,
      owner,
      folder: videoFolder,
      externalProviderData: JSON.stringify({
        provider: asset.uploadType,
        externalCreatedAt: asset?.createdAt,
        likeCount: asset?.likeCount,
        commentsCount: asset?.commentsCount,
        postUrl: asset?.postUrl,
        posterUrl: asset?.stockAsset?.posterUrl,
        username: asset?.username,
        shareCount: asset?.shareCount,
        viewCount: asset?.viewCount,
        ugcHashtag,
      }),
      stockAsset: null,
    };

    if (asset.type === ASSET_TYPE.GALLERY) {
      videoInput = {
        ...videoInput,
        status: VIDEO_STATUS.done,
        stockAsset: {
          posterUrl: AssetRenditionsService.getAssetPosterLarge(
            getVideoById(asset.referenceIds[0])
          ),
        },
      };
    }

    const video = await createVideo(videoInput);

    await createOrUpdateIntegrationVodAsset({
      owner,
      asset,
      vodId: uuid,
      selectedAsset,
      method: uploadType,
    });

    return {
      videoId: video.id,
      type: video.type,
      url: asset.videoUrl || asset.imageUrl,
      externalId: asset.externalId || asset.id,
      appKey: account?.appKey || Utils.getAppKey(),
    };
  };

  const importVideoFromSocial = async (videosData, owner, method) => {
    if (!videosData.length || videosData[0]?.type === ASSET_TYPE.GALLERY) {
      return;
    }

    const body = { videosData, owner, method };

    const response = await importMultipleVideos(body);
    if (response?.errorMessage) {
      setErrorSnackbar(response?.errorMessage);
    }
  };

  const importMultipleFiles = async ({
    videos,
    method,
    videoFolder,
    ugcHashtag,
  }: {
    videos: any[];
    method: CreationMethod | string;
    videoFolder?: string;
    ugcHashtag?: string;
  }) => {
    const owner = account?.owner || Utils.getOwner();
    if (!videos.length || !owner || !method) {
      return;
    }

    const createVideoPromises = videos.map((video, index) =>
      createVideoHandler(video, index, method, owner, videoFolder, ugcHashtag)
    );

    const videosData = await Promise.all(createVideoPromises);
    const currentMethod = IMPORT_METHODS[method] || method;
    const videoWithoutExisting = videosData.filter(
      ({ url }) => !!url || [IMPORT_FROM_GOOGLE_DRIVE, IMPORT_FROM_YOUTUBE].includes(currentMethod)
    );

    await importVideoFromSocial(videoWithoutExisting, owner, currentMethod);

    return videosData;
  };

  return { importMultipleFiles };
};

export default useCreateIntegrationVideo;
