import React from 'react';
import IntegrationsConnectAccountState from 'app/src/pages/creation_flow/right_section/integrations_common/IntegrationsConnectAccountState';
import { IMPORT_FROM_INSTAGRAM } from 'app/src/constants/creation_method.constants';
import useIntegrationsVideos from 'app/src/hooks/useIntegrationsVideos';
import { IMPORT_FROM_INTEGRATION_LOCATIONS } from 'app/src/constants/trackingLocations.constants';
import { useTranslation } from 'react-i18next';
import { INSTAGRAM_BUSINESS_SHOW_TO_LINK } from 'app/src/constants/helpArticleLinks.constants';

const InstagramConnectAccountState = () => {
  const { t } = useTranslation();
  const { handleInstagramConnect, hasOldInstagramIntegration } = useIntegrationsVideos(
    IMPORT_FROM_INTEGRATION_LOCATIONS.creationModal
  );

  return (
    <IntegrationsConnectAccountState
      handleConnectClick={handleInstagramConnect}
      showMeHowLink={INSTAGRAM_BUSINESS_SHOW_TO_LINK}
      badgeText={
        hasOldInstagramIntegration && t(`connectAccount.${IMPORT_FROM_INSTAGRAM}.badgeText`)
      }
      integration={IMPORT_FROM_INSTAGRAM}
    />
  );
};

export default InstagramConnectAccountState;
