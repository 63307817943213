import usePlayerCommands from 'player/src/hooks/usePlayerCommands';
import useFeedState from 'shared/react/components/complex/context/hooks/useFeedState';
import useProjectConfig from 'shared/react/components/complex/context/hooks/useProjectConfig';

const useFeedPlayerCommands = ({ videoRef, currentStep }) => {
  const [{ handlePlayPause, isUserMute, setIsUserMute, setIsInitialMute, isPlayerVisible }] =
    useFeedState();
  const [{ publishId }] = useProjectConfig();

  const handleMuteClick = () => {
    setIsUserMute(!isUserMute);
    setIsInitialMute(false);
  };

  usePlayerCommands({
    videoRef: videoRef.current,
    handleMuteClick,
    handlePlayPause: () => handlePlayPause(currentStep),
    location: 'Feed',
    publishId,
    currentStep,
    started: isPlayerVisible,
  });
};

export default useFeedPlayerCommands;
