import React from 'react';
import { SidebarBaseItem, SidebarItemName } from 'src/pages/dashboard/components/sidebar';
import { LAYOUTS, LAYOUT_LABELS } from '../../constants/layouts';

type Props = {
  selectedLayout?: string;
  onLayoutClick: (layout: string) => void;
};

const Layouts = ({ selectedLayout, onLayoutClick }: Props) =>
  LAYOUTS.map(layout => {
    const handleClick = () => onLayoutClick(layout);

    return (
      <SidebarBaseItem key={layout} isSelected={selectedLayout === layout} onClick={handleClick}>
        <SidebarItemName>{LAYOUT_LABELS[layout]}</SidebarItemName>
      </SidebarBaseItem>
    );
  });

export default Layouts;
